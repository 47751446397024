<template>
  <v-app>
    <!-- <v-card class="ma-3 " tile> -->
    <div>
      <v-card class="pa-3 mb-1" style="" tile>
        <v-layout align-center class="py-2" >
            <v-flex class="text-uppercase font-weight-bold title-clr-prtbs ml-2" xs1>OFR Dashboard</v-flex>
            <v-divider vertical class="mx-4"></v-divider>
            <v-btn-toggle dense mandatory v-model="selected_lob" tile class="" style=""  @change="changeLobData">
                <div xs3 v-for="(lobData, index) in lobListAndCount" :key="index">
                  <v-badge
                    bordered
                    color="error"
                    class="badge-custom"
                    :content="lobData.total || '0'"
                    overlap
                    left
                  >
                    <v-btn
                      color="#FFFFFF"
                      light
                      @click="selected_lob = lobData"
                      :class="{ selected_button: lobData.lob === selected_lob.lob }"
                      class="textTransformCapitalize mastersButtonWidth"
                    >
                      <div class="d-flex">
                        <!-- <div class="icon-wrap pl-custom">
                          <v-icon :class="lobData.icon"></v-icon>
                        </div> -->

                        <div class="mastersDiv"></div>
                      </div>
                      <div class="text">
                        {{ lobData.lob }}
                      </div>
                    </v-btn>
                  </v-badge>
                </div>
            </v-btn-toggle>                                              
        </v-layout>
      </v-card>
    </div>
    <div v-if="showHideHealth">
      <v-layout class="pa-2 ma-3 py-3 text-center">
        <v-flex xs3>
          <div
            style="background-color: #F7F7F7; cursor:pointer;"
            class="pa-2 pr-14 "
            :class="[
              OFRStatus == 'caseUnderOFR'
                ? 'activeRadioclass'
                : '',
            ]"
              @click="changeOFRStatus('caseUnderOFR')"
          >
            <div
              class="font-weight-bold"
              :class="[
                OFRStatus == 'caseUnderOFR'
                  ? 'text-white-insrns'
                  : 'text-dark-insrns',
              ]"
            >
            <!-- OFRStatus -->
              Cases Under OFR
            </div>
          </div>
        </v-flex>
        <v-flex xs3>
          <div
            style="background-color: #F7F7F7;  cursor:pointer"
            class="mr-4 pa-2"
            :class="[
              OFRStatus == 'ofrCompletedCase'
                ? 'activeRadioclass'
                : '',
            ]"
            @click="changeOFRStatus('ofrCompletedCase')"
          >
            <div
              class="font-weight-bold"
              :class="[
                   OFRStatus == 'ofrCompletedCase'
                  ? 'text-white-insrns'
                  : 'text-dark-insrns',
              ]"
            >
              OFR Completed Cases
            </div>
          </div>
        </v-flex>
        <v-flex xs3></v-flex>
        <v-flex xs3></v-flex>
        <v-flex xs3 class="mb-2">
          <!-- <v-layout class=" mx-1">
            <v-select
              :items="itemsOfLOB"
              v-model="selectedLob"
              placeholder="Select LOB"
              @change="changeLob()"
              tile
              dark
              class="text-capitalize px-3 "
              background-color="#1ebbd9"
            >
            </v-select>
          </v-layout> -->

          <!-- <v-select
            :items="itemsOfLOB"
            v-model="selectedLob"
            placeholder="Select LOB"
            @change="changeLob()"
            
            dark
            tile
            class="selectList pt-2 px-2 "
            background-color="#1ebbd9"
          >
          </v-select> -->
        </v-flex>
        <!-- <v-flex xs3>
                    <v-btn
                        tile
                        color="#E46A25"
                        class="text-capitalize text-white px-3 mx-2 my-2"
                        small
                        style="color: #fff;font-size:11px"
                        @click="ViewActivityDashboard()"
                    >
                        <v-icon dark small>visibility</v-icon>
                        <v-divider vertical class="mx-2"></v-divider>
                        <span style="font-size:11px">View Activity Dashboard</span>                     
                    </v-btn>
        </v-flex> -->
      </v-layout>
      <div class="mt-2 mb-2" style="height:1px; background-color:#C1C8CC"></div>

    <v-layout class="pa-2 ma-3 py-3 text-center">
        <v-flex xs3>
          <div
            style="background-color: #F7F7F7; cursor:pointer;"
            class="pa-2 pr-14 "
            :class="[
              ReferedStatus == 'referredFromPreAuth'
                ? 'activeRadioclass'
                : '',
            ]"
              @click="changeReferedStatus('referredFromPreAuth')"
          >
            <div
              class="font-weight-bold"
              :class="[
                ReferedStatus == 'referredFromPreAuth'
                  ? 'text-white-insrns'
                  : 'text-dark-insrns',
              ]"
            >
              Referred From Pre-Auth
            </div>
          </div>
        </v-flex>

        <v-flex xs3>
          <div
            style="background-color: #F7F7F7;  cursor:pointer"
            class="mr-4 pa-2"
            :class="[
              ReferedStatus == 'referredFromClaims'
                ? 'activeRadioclass'
                : '',
            ]"
            @click="changeReferedStatus('referredFromClaims')"
          >
            <div
              class="font-weight-bold"
              :class="[
                   ReferedStatus == 'referredFromClaims'
                  ? 'text-white-insrns'
                  : 'text-dark-insrns',
              ]"
            >
              Referred From Claims
            </div>
          </div>
        </v-flex>

        <v-flex xs3></v-flex>
        <v-flex xs3></v-flex>
                                <!-- v-if="userdata.allRoles=='CG1'" -->
                                <!-- v-if="this.fetchRoleValue() == 'CG1' || this.fetchRoleValue() == 'CG2'" -->
        <v-flex xs3>
            <div >
              <!-- v-if="this.incmdata[0] == 'CG1' || this.incmdata[0] == 'CG2'" -->
                    <v-btn
                        v-if="showactivitybutton"
                        tile
                        color="#E46A25"
                        class="text-capitalize text-white px-3 mx-2 my-2"
                        small
                        style="color: #fff;font-size:11px"
                        @click="ViewActivityDashboard()"
                    >
                        <v-icon dark small>visibility</v-icon>
                        <v-divider vertical class="mx-2"></v-divider>
                        <span style="font-size:11px">View Activity Dashboard</span>                     
                    </v-btn>
            </div>
        </v-flex>
        
    </v-layout>
      
    <div v-if="valStaus==true ">
      <v-data-table
        :headers="headers"
        :items="GetData"
        :page.sync="page"
        :items-per-page="itemsPerPage"
        hide-default-footer
        dense
        @page-count="pageCount = $event"
      >
        <!-- <template v-slot:item.claimIDPRN.itemId="{ item }">
       {{ item.claimIDPRN[0].claimId }}
      </template> -->
        <!-- <template v-slot:item.action="{ item }">
          <v-btn
            tile
            color="#fff"
            class="text-capitalize text-dark px-3 mx-2 my-2"
            small
            >
            <v-icon dark small>visibility</v-icon>
            </v-btn
          > 
        </template> -->

        <!-- <template v-slot:item.TAT="{ item }">
       {{ moment(item.TAT).format("DD/MM/YYYY") }}
      </template> -->

      
      <template v-slot:[`item.coverageName`]="{ item }">
       <p >{{item.coverageName}}</p>
        
      </template> 


        <template v-slot:[`item.action`]="{ item }">

          <p @click="gotoOFRaudit(item)" class="mt-2 " style="text-decoration: underline; cursor: pointer;color: blue;min-width:60px">View OFR</p>
        </template>
         <!-- <template v-slot:item.queryId="{ item }">

          <p @click="gotoQuery(item._id)" style="text-decoration: underline; cursor: pointer;color: blue">{{
           item.queryId
          }}</p>
        </template> -->
      </v-data-table>
    </div>

      <div v-if="valStaus==false">

            <v-data-table
            :headers="headers"
            :items="GetDataComplet"
            :page.sync="page"
            :items-per-page="itemsPerPage"
            hide-default-footer
            dense
            @page-count="pageCount = $event"
          >



            <template v-slot:[`item.action`]="{ item }">

              <p @click="gotoOFRaudit(item)" class="mt-2 " style="text-decoration: underline; cursor: pointer;color: blue;min-width:60px">View OFR</p>
            </template>
          </v-data-table>
      </div>

      <v-divider></v-divider>

      <v-layout row justify-end align-center class="ma-1">
        <v-text
          class="mr-1 font-weight-bold body-1"
          style="color:#3C3D3D; font-size:12px !important"
        >
          <!-- <span class="" style="color:#E46A25;font-size:12px;">Showing 1-10 </span>out of {{total}} records -->
          <span class="" style="color:#E46A25;"
            >Showing {{ fval }}-{{ sval }} </span
          >out of {{ totalLen }} records
        </v-text>
        <v-divider class="mx-2" vertical></v-divider>
        <v-pagination
          class="cust-bnkAcc-pgn"
          v-model="page"
          color="#152F38"
          flat
          :total-visible="9"
          @input="nextPageBtn(page)"
          :length="pagelength"
        ></v-pagination>
      </v-layout>
    </div>

     <v-container
        v-if="showHideOtherThanHealth"
        class="containerStyle"
        style="padding:1.5rem; margin:0; max-width:100%"
      >
        <h3>Open File Reviews</h3>
        <!-- <v-btn-toggle  class="mt-5" v-model="ofr_btnList" multiple>
    
        <v-btn solo outlined value="casesunderOFR" 
        :class="[caseUnderOfr == true ? 'activebg' : 'inactivebg' ]" 
        @click="caseUnderOfr =!caseUnderOfr;callingauxiliarapi()">Cases Under OFR</v-btn>

        OFR Completed Cases
        <v-btn class="ml-5" 
        value="Ofrcompletedcases"
        solo 
        :class="[ofrCompletedCase == true ? 'active' : 'inactivebg' ]"
        @click="ofrCompletedCase=!ofrCompletedCase ; callingauxiliarapi()"
        
        >OFR Completed Cases</v-btn>
      </v-btn-toggle> -->
      <v-layout class="mt-5">
          <v-btn solo outlined 
          :class="[caseUnderOfr == true ? 'activebg' : 'inactivebg' ]" 
          style="padding:25px;width:200px;border-radius:none" 
          class="createbtn px-2" 
          @click="caseUnderOfr =!caseUnderOfr;ofrCompletedCase=!ofrCompletedCase;callingauxiliarapi(1)">Cases Under OFR</v-btn>
          
          
          <v-btn solo outlined 
          :class="[ofrCompletedCase == true ? 'active' : 'inactivebg' ]" 
          style="padding:25px;width:200px;border-radius;:none" 
          class="createbtn ml-5" @click="ofrCompletedCase=!ofrCompletedCase;caseUnderOfr =!caseUnderOfr ; callingauxiliarapi(1)">OFR Completed Cases</v-btn>
      </v-layout>

      <v-layout class="mt-5">
            <div style="display:flex;align-items:center;column-gap:8px;">
              <div>
                  <h4>Open File Review List <span class="orange--text" v-if="count">({{count}})</span></h4>
              </div>
              <v-chip small color="#1EBBD9" text-color="white" label close
                  v-if="showSearchChip==true"
                  @click:close="searchChipButton">
                  Filter
                </v-chip>
          </div>
          <v-spacer></v-spacer>
          <v-btn depressed color="#E46A25" class="white--text mr-3" @click="exportOfr">
            <v-icon>
                mdi-file-excel
            </v-icon>
            <v-divider class="mx-4" vertical color="white"></v-divider>
            Export
          </v-btn>
          <v-btn color="#23b1a9" class="createbtn white--text " solo dense @click="dialog_search=!dialog_search">
              <v-icon >mdi-magnify</v-icon>
              <v-divider></v-divider>
              Search
          </v-btn>
          <v-btn class="mx-2 createbtn white--text" color="#e46a25" solo dense v-if="false">
              <v-icon>mdi-cloud-download</v-icon>
              <v-divider vertical class="mx-2 white--text"></v-divider>
              Download
          </v-btn>
          <v-dialog v-model="dialog_search" width="1000">
          <v-card>
                        <v-card-title
                          class="white--text"
                          style="background-color: #23b1a9"
                        >
              <h3 class="ml-5">Open File Review Search</h3>
              <v-spacer></v-spacer>
              <v-icon @click="dialog_search=!dialog_search" class="white--text">mdi-window-close</v-icon>
          </v-card-title>
          <v-card-text class=" grey lighten-2">
              <v-layout wrap class="pt-4 form-group-container">
                  <v-flex class="form-groups">
                      <v-label class="lableFontSize">
                          Claim No
                      </v-label>
                      <v-text-field
                          label="Enter"
                          solo
                          dense 
                          v-model="claimNumber"         
                          class="forminput"
                          maxlength="50"
                          counter="50"
                        ></v-text-field>
                  </v-flex>
                  <v-flex class="form-groups">
                      <v-label class="lableFontSize">
                          Feature No.
                      </v-label>
                      <v-text-field
                          label="Enter"
                          solo
                          dense
                          v-model="featureNumber"          
                          class="forminput"
                          maxlength="50"
                          
                        ></v-text-field>
                  </v-flex>
                  <v-flex class="form-groups">
                      <v-label class="lableFontSize">
                          Claimant Name
                      </v-label>
                      <v-text-field
                          label="Enter"
                          solo
                          dense 
                          v-model="claimantName"         
                          class="forminput"
                          maxlength="50"
                          
                        ></v-text-field>
                  </v-flex>
                  <v-flex class="form-groups">
                      <v-label class="lableFontSize">
                          Insured Name
                      </v-label>
                      <v-text-field
                          label="Enter"
                          solo
                          dense 
                          v-model="insuredName"         
                          class="forminput"
                          maxlength="50"
                          
                        ></v-text-field>
                  </v-flex>
              </v-layout>

              <v-layout  class="form-group-container">
                  <!-- <v-flex class="form-groups">
                      <v-label class="lableFontSize">
                          OFR status
                      </v-label>
                      <v-text-field
                          label="Enter"
                          solo
                          dense          
                          class="forminput"
                          maxlength="50"
                          
                        ></v-text-field>
                  </v-flex> -->
                  <v-flex class="form-groups">
                      <v-label class="lableFontSize">
                          Examiner Name
                      </v-label>
                      <v-text-field
                          label="Enter"
                          solo
                          dense 
                          v-model="examinerName"         
                          class="forminput"
                          maxlength="50"
                          
                        ></v-text-field>
                  </v-flex>
                  <v-flex class="form-groups">
                      <v-label class="lableFontSize">
                          Date Reported From
                      </v-label>
                      <v-menu
                          v-model="menu"
                          offset-y
                          nudge-top="20"
                          :close-on-content-click="false"
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              :value="computeddateReportedFrom"
                              placeholder="DD/MM/YYYY"
                              
                              dense
                              solo
                              readonly
                              v-on="on"
                              @focus="menu = true"
                              @blur="menu = true"
                              v-bind="attrs"
                              class="forminput"
                              append-icon="mdi-menu-down"
                              prepend-inner-icon="mdi-calendar"
                            >
                            </v-text-field>
                          </template>
                          <v-date-picker
                            no-title
                            :pickerDate.sync="pickerDate"
                            :max=getEndDate
                            v-model="dateReportedFrom"
                            @input="menu = false"
                          >
                          </v-date-picker>
                        </v-menu>
                  </v-flex>
                  <v-flex class="form-groups">
                      <v-label class="lableFontSize">
                          Date Reported To
                      </v-label>
                      <v-menu
                          v-model="menu1"
                          offset-y
                          nudge-top="20"
                          :close-on-content-click="false"
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              :value="computeddateReportedTo"
                              placeholder="DD/MM/YYYY"
                              
                              dense
                              solo
                              readonly
                              v-on="on"
                              @focus="menu1 = true"
                              @blur="menu1 = true"
                              v-bind="attrs"
                              class="forminput"
                              append-icon="mdi-menu-down"
                              prepend-inner-icon="mdi-calendar"
                            >
                            </v-text-field>
                          </template>
                          <v-date-picker
                            no-title
                            :pickerDate1.sync="pickerDate"
                            :max=getEndDate
                            v-model="dateReportedTo"
                            @input="menu1 = false"
                          >
                          </v-date-picker>
                        </v-menu>
                  </v-flex>
                  <v-flex class="form-groups">
                      <v-label class="lableFontSize">
                          Month
                      </v-label>
                      <v-select
                          solo
                          :items="monthList"
                          item-text="name"
                          item-value="value"
                          dense
                          class="form-control"
                          v-model="month"
                          placeholder="Select...">
                      </v-select>
                  </v-flex>
              </v-layout>
          </v-card-text>
          <v-card-actions>
              <v-btn class="reset createbtn" style="background:white" dark @click="clear()">Reset</v-btn>
              <v-spacer></v-spacer>
              <v-btn class="createbtn cancelbtn" @click="dialog_search=!dialog_search">Cancel</v-btn>
              <v-btn color="#23b1a9" class="createbtn white--text" @click="searchOfr(); showSearchChip = true">Search</v-btn>
          </v-card-actions>
          </v-card>
      </v-dialog>
      </v-layout>
      <v-data-table
      :items="ofr_Table"
      hide-default-footer
      :headers="table_headers"
      dense
      class="mt-5 vDataTableStyling">
      <template v-slot:[`item.action`]="{ item }">
                      <a class="tablebtn mr-1" @click="viewOpenFilePage(item)">                            
                          <v-icon class="tableicons">mdi-eye-outline</v-icon><span class="ml-0 viewStyle"> VIEW </span>
                      </a>
                  </template>   
                  <template v-slot:[`item.Date`]="{ item }">
                      {{item.Date?dateConvert(item.Date):"----"}}
                  </template> 
                  <template v-slot:[`item.turnAroundTime`]="{item}">
                      {{turnAroundTime(item.createdAt, item.Date)}}
                  </template>      
                  <template v-slot:[`item.userId`]="{ item }">
                      {{item.userId || "----"}}
                  </template>    
                  <template v-slot:[`item.createdAt`]="{ item }">
                      {{item.createdAt?dateConvert(item.createdAt):"----"}}
                  </template>    
                  <template v-slot:[`item.ofrStatus`]="{ item }">
                      <span :class="checkOfrStatus(item)">{{ item.ofrStatus === true ? "Closed" : 'Open'}}</span> 
                      <!-- <span>
                          {{ item.ofrStatus == true ? "Closed" : 'Open'}}
                      </span> -->
                      
                  </template>
      </v-data-table>
      <div   class="pt-2">
        <v-row class="text-right px-4 align-center">
          <v-col class="mt-5">
            <p>
              <span class="result"
              >Showing {{ pageStartIndex }} - {{ pageEndIndex }}</span
              >
              out of {{ totalListCount }} records
            </p>
          </v-col>
          <v-pagination
            v-model="page"
            :length="pageCount"
            :total-visible="6"
            @input="searchOfr"
            prev-icon="mdi-arrow-left"
            next-icon="mdi-arrow-right"
            color="#152F38"
          >
          </v-pagination>
          <!--  -->
        </v-row>
      </div>

    </v-container>

    <!-- </v-card> -->
 </v-app>
</template>

<script>
// import axios from "axios";
 import moment from "moment";

export default {
  components: {},

  created() {
    this.$store.commit("SAVE_PAGE_HEADER", "OFR Dashboard");

    console.log("Success Token", this.$store.getters.successToken);
    this.token = this.$store.getters.successToken;
     this.getOFRData(this.page,this.OFRStatus);

     this.getviewactivitybutton();
    //  this.userdata= this.$store.state.Auth.user.signInUserSession.idToken.payload
    //  console.log("this.userdata", this.userdata);

    console.log("this.fetchRoleValue-----====>>>", this.fetchRoleValue());
    this.incmdata=this.fetchRoleValue()
    console.log("this.incmdata--------->>>>", this.incmdata);

     //Surendra(04-Oct-2022):LOB List from BE,code starts here
    // this.GET(3, "line-of-business/get-line-of-business-list", (res, error) => {
    //   if (!error) {
    //     console.log("response from api is", res.data.data[0].lineOfBusiness);
    //     this.itemsOfLOB = res.data.data[0].lineOfBusiness;
    //     console.log(this.itemsOfLOB)
    //     this.itemsOfLOB = res.data.data[0].lineOfBusiness;
    //   } else {
    //     console.log("error", error.response.data);
    //   }
    // });

    this.getCountForLOB(); //lobListAndCount
  },

  computed: {
    computeddateReportedFrom(){
      return this.formatDate(this.dateReportedFrom);
    },
    computeddateReportedTo(){
      return this.formatDate(this.dateReportedTo);
    },
    getEndDate() {
     var endDate = new Date();
     return endDate.toISOString().slice(0,10)
    },
    pageEndIndex(){
        if(!this.totalListCount){
            return 0
        }else{
            return ((this.pageStartIndex + (this.itemsPerPage)) <= this.totalListCount) ? this.pageStartIndex + (this.itemsPerPage) - 1 : this.totalListCount;
            
        }
    }
  },

  data() {
    return {
      showactivitybutton:false,

    // userdata:'',
    incmdata:'',
      // pagelength:0,
      investigationStatus: "CasesUnderInvestigation",
      OFRStatus: 'caseUnderOFR',
      ReferedStatus: 'referredFromPreAuth',
      // for pagination AA
      swap_final_count: false,
      fval: 0,
      sval: 0,
      totalLen: "",
      pagelength:'',

      fromvalue: "",
      toValu: "",
      token: null,
      page: 1,
      valStaus:true,
      GetData:[],
      GetDataComplet:[],


      GetData1: [
          {
          claimId: "Claim ID",
          featureNo: "1211111"

        }
      ],
      size: 10,
      itemsPerPage:10,
      
      headers: [
         {
          text: "Claim ID",
          value: "claimId",
          sortable: true,
          divider: true,
          class: "bg-investigation-table-header-color minWidth "
        },
        {
          text: "Feature Number",
          value: "featureNumber",
          sortable: false,
          divider: true,
          class: "bg-investigation-table-header-color minWidth",
        },
        {
          text: "Corporate Group Name",
          value: "corporateGroupName",
          sortable: false,
          divider: true,
          class: "bg-investigation-table-header-color minWidth",
        },
        {
          text: "Insured Name",
          value: "insuredName",
          sortable: false,
          divider: true,
          class: "bg-investigation-table-header-color minWidth",
        },
        {
          text: "Coverage",
          value: "coverageName",
          sortable: false,
          divider: true,
          class: "bg-investigation-table-header-color minWidth",
        },
        {
          text: "Policy Number",
          value: "policyNumber",
          sortable: false,
          divider: true,
          class: "bg-investigation-table-header-color minWidth",
        },
        {
          text: "Product Name",
          value: "productName",
          sortable: false,
          divider: true,
          class: "bg-investigation-table-header-color minWidth",
        },
        {
          text: "Hospital Name",
          value: "hospitalName",
          sortable: false,
          divider: true,
          class: "bg-investigation-table-header-color minWidth",
        },
        {
          text: "Patient Name",
          value: "patientName",
          sortable: false,
          divider: true,
          class: "bg-investigation-table-header-color minWidth",
        },
        {
          text: "Total Amount Approve",
          value: "totalAmountApproved",
          sortable: false,
          divider: true,
          class: "bg-investigation-table-header-color minWidth",
        },
        {
          text: "Claimant Name",
          value: "claimantName",
          sortable: false,
          divider: true,
          class: "bg-investigation-table-header-color minWidth",
        },
        {
          text: "Sum Insured",
          value: "sumInsured",
          sortable: false,
          divider: true,
          class: "bg-investigation-table-header-color minWidth",
        },
        {
          text: "Reserve Amount",
          value: "reserveAmount",
          sortable: false,
          divider: true,
          class: "bg-investigation-table-header-color minWidth",
        },
        {
          text: "Litigation Type",
          value: "litigationType",
          sortable: false,
          divider: true,
          class: "bg-investigation-table-header-color minWidth",
        },
        {
          text: "Reason",
          value: "reason",
          sortable: false,
          divider: true,
          class: "bg-investigation-table-header-color minWidth",
        },
        {
          text: "TAT",
          value: "TAT",
          sortable: false,
          divider: true,
          class: "bg-investigation-table-header-color minWidth",
        },
        {
          text: "Action",
          value: "action",
          sortable: false,
          divider: true,
          class: "bg-investigation-table-header-color minWidth",
        }

        // {text: 'Action', value: 'Action',sortable: false,divider:true, width:"40px",class:"hdr-txt-clr-txt hdr-bg-clr"}
      ],
      selectedLob:"Health",
      itemsOfLOB:[],
      lobListAndCount:[],
      selected_lob:{},
      showHideHealth:false,
      showHideOtherThanHealth:false,

      //For Other than Health LOB
      pageCount:0,
      totalListCount: 0,
      pageStartIndex: 0,
      page: 1,
      // itemsPerPage: 10,
      pickerDate:null,
      pickerDate1:null,
      menu:false,
      menu1:false,
      dateReportedTo:"",
      dateReportedFrom:"",
      claimNumber:"",
      featureNumber:"",
      claimantName:"",
      insuredName:"",
      examinerName:"",
      month: "",
      ofr_btnList:[],
      caseUnderOfr:false,
      ofrCompletedCase:false,
      ofr_Table:[],
      btn2:false,
      count:0,
      dialog_search:false,
      datacaseUnderOfr:[],
      dataofrCompletedCase:[],
      table_headers:[
      {text:"Action", align: "center",width:"100px", sortable: false,value:"action"},
          {text:"Claim No", align: "center",width:"100px", sortable: false,value:"claimNumber"},
          {text:"Feature No", align: "center",width:"100px", sortable: false,value:"featureNumber"},
          {text:"Claim Status", align: "center",width:"100px", sortable: false,value:"claimStatus"},
          {text:"Policy Number", align: "center",width:"100px", sortable: false,value:"policyNumber"},
          {text:"Certificate Number", align: "center",width:"100px", sortable: false,value:"certificateNumber"},
          {text:"Product Name", align: "center",width:"100px", sortable: false,value:"productName"},
          {text:"Coverage", align: "center",width:"200px", sortable: false,value:"coverage[coverName]"},
          {text:"Claimant Name", align: "center",width:"100px", sortable: false,value:"claimantName"},
          {text:"Insured Name", align: "center",width:"200px", sortable: false,value:"insuredName"},
          {text:"Total Approved Amount", align: "center",width:"200px", sortable: false,value:"claimedAmount"},
          {text:"Total Indemnity Paid", align: "center",width:"100px", sortable: false,value:"indemnityPaid"},
          {text:"Total Expense Paid", align: "center",width:"100px", sortable: false,value:"expensePaid"},
          {text:"Examiner Name", align: "center",width:"100px", sortable: false,value:"examiner.employeeName"},
          {text:"Sum Insured", align: "center",width:"100px", sortable: false,value:"sumInsured"},
          {text:"Outstanding Reserve Amount", align: "center",width:"100px", sortable: false,value:"reserveAmount"},
          {text:"Reason", align: "center",width:"200px", sortable: false,value:"reason"},
          {text:"OFR Reviewed Date", align: "center",width:"100px", sortable: false,value:"Date"},
          {text:"OFR Reviewed By", align: "center",width:"100px", sortable: false,value:"userId"},
          {text:"Turn Around Time", align: "center",width:"100px", sortable: false,value:"turnAroundTime"},
          {text:"OFR Status", align: "center",width:"100px", sortable: false,value:"ofrStatus"},
          {text:"Created At", align: "center",width:"150px", sortable: false,value:"createdAt"},
      ],
      showSearchChip: false,
      monthList: [{name: "January", value: 1},{name: "February", value: 2},{name: "March", value: 3},{name: "April", value: 4},{name: "May", value: 5},{name: "June", value: 6},{name: "July", value: 7},{name: "August", value: 8},{name: "September", value: 9},{name: "October", value: 10},{name: "November", value: 11},{name: "December", value: 12}],
    };
  },

  methods: {
    getviewactivitybutton(){
      let apiURL = "permission/get-permissions"
      this.GET(6, apiURL, (response, error) => {
        let Apiresponse = response.data.data

        console.log("permissoin::::>>>",Apiresponse)
        for(let i=0;i<=Apiresponse.length;i++){
          // console.log("screen name::::",Apiresponse[i].screenName)
          if(Apiresponse[i].screenName == 'View Activity Dashboard'){
            console.log("i am inside")
            this.showactivitybutton = true
          }
        }
      })
    },

    //  user_details(){
    //         return this.$store.state.Auth.user.signInUserSession.idToken.payload
    //  },

    // fetchRoleValue(){
    //   console.log(this.user_details(),"===++--===> rolelist before split")
    //   let rolelist=this.user_details().CM ? this.user_details().CM.roleDescription.split('..') : this.user_details().Health.roleDescription.split('..')
    
    //   console.log(rolelist,"===____----> rolelist after split")
    //   let b = {}
    //   let final = []

    //   rolelist.map(e=>{
    //       b = {}
    //            console.log("e -->", e.split(".."))
    //           b['type'] = e.split("..")[0]
    //           b['value'] = e.split("..")[1]

    //           final.push(b)
    //   })

    //    console.log(final[1].type, "<<<<----val finall role list----====+++",final)
    //   // return final[0].value
    //   return final[1].type
    // },


    ViewActivityDashboard(){

      this.$router.push({
          name: "OFRviewActivity",
          params: { from: 'OFRdashboard',screen:'View Activity Dashboard' },
         
        });

    },
      changeOFRStatus(val){
          console.log(val,'<<---OFRStatus value');
           this.OFRStatus = val;
          
          // if (val =="caseUnderOFR") {
          //   this.OFRStatus = true;
          // } else {
          //   this.OFRStatus = false;
          // }
          
          // this.getOFRData(this.page,this.OFRStatus);
                    this.getOFRData(1,this.OFRStatus);

  
      },

      changeReferedStatus(val){
          console.log(val,'ReferedStatus value');
           this.ReferedStatus = val;
          
          // if (val =="fromPreAuth") {
          //   this.ReferedStatus = true;
          // } else {
          //   this.ReferedStatus = false;
          // }
          
          // this.getOFRData(this.page,this.OFRStatus);
          this.getOFRData(1,this.OFRStatus);
  
      },

    gotoOFRaudit(val) {
        console.log('_idiii',val);
    //   if(this.OFRStatus == 'UnderCFR'){
         this.$store.commit("SAVE_PAGE_HEADER", "OFR Audit Sheet");
         this.$router.push({
          name: "OFRauditSheet",
          params: { allData: val, from: 'OFRdashboard',screen:'OFR Audit Sheet' },
         
        });
    //   }else{
    //      this.$store.commit("SAVE_PAGE_HEADER", "External Query Dashboard");
    //      this.$router.push({
    //       name: "ExternalQueryRegistration",
    //       params: { id: value, from: 'dashboard',screen:'External Query Response' },
         
    //     });
    //   }
    },

    async getOFRData(pageNum,ofr) {
      //  this.GetData = [];
      console.log(ofr,"ofrstatus val-----PageNumber",pageNum)
      if (this.OFRStatus=='caseUnderOFR') {
        this.valStaus=true
      } else {
        this.valStaus=false
        
      }
      var apiURL = '';
      // var OFRTypeChange = this.OFRStatus.toLowerCase();
      var OFRTypeChange = this.OFRStatus;


    if(this.ReferedStatus == 'referredFromPreAuth'){
          // apiURL = `ofr/get-ofr-details?perPage=10&pageNo=`+pageNum+`&type=${OFRTypeChange}&referredType=`+this.ReferedStatus;
          apiURL = `ofr/get-ofr-details?perPage=10&pageNo=`+pageNum+`&type=${OFRTypeChange}&claimType=Cashless`;
         
      }else if(this.ReferedStatus == 'referredFromClaims' ){
          apiURL = `ofr/get-ofr-details?perPage=10&pageNo=`+pageNum+`&type=${OFRTypeChange}&claimType=Reimbursement,Cashless Claim File`;
      } else{
              apiURL = `ofr/get-ofr-details?perPage=10&pageNo=`+pageNum+`&type=${OFRTypeChange}`

    }

      // apiURL = `ofr/get-ofr-details?perPage=10&pageNo=`+pageNum+`&type=${OFRTypeChange}`
      await this.GET(20, apiURL, (response, error) => {
        if (!error) {
          let apiData = response.data.data.data;
          console.log('<<< ===OFR api dataaa ===>',apiData);
          this.showToast(response.data.msg, this.TOST().SUCCESS);
          
          // this.GetData = [];
          if (this.OFRStatus=='caseUnderOFR') {
             this.GetData = apiData;
            // this.GetData.push({                      
            //   TAT: apiData.TAT ? moment(apiData.TAT).format("DD-MM-YYYY") : "--",
            // })
            this.GetData.forEach((data, index) => {
            // data.srNo = index + 1;
            data.srNo = 10*(pageNum)-9+index

           });
          } else {
            this.GetDataComplet = apiData
            this.GetDataComplet.forEach((data, index) => {
            // data.srNo = index + 1;
            data.srNo = 10*(pageNum)-9+index

           });
            
          }
          
          this.page=pageNum
          // for pagination

          this.pagelength = response.data.data.total_pages;

          this.totalLen = response.data.data.total;

          if (this.OFRStatus=='caseUnderOFR') {
            let l = this.GetData.total;
          } else {
            let l = this.GetDataComplet.total;
          }

          var less_enough = this.totalLen;
          var checkless_init = false;
          if (less_enough < 10) {
            checkless_init = false;
          } else {
            checkless_init = true;
          }

          // if less than 15 we have second value same as total value as no pagination will occur
          if (checkless_init) {
            // checkinit is true means the final count is more than 15 (- 1)
            var traverse = 10 * this.page;
            this.fval = traverse - 9;
            if (this.swap_final_count) {
              this.sval = this.totalLen;
              this.swap_final_count=false
            } else {
              this.sval = traverse;
            }
          } else if (this.totalLen == 0) {
            this.sval = this.fval = 0;
          } else {
            this.fval = 1;
            this.sval = this.totalLen;
          }
        } else {
          if (error.response.data.message.statusCode == 400) {
            // this.GetData = []
              this.fval= 0,
              this.sval= 0,
              this.totalLen= ""
              this.pagelength=0
            
          }  
          // this.showToast("Unable to fetch the records", this.TOST().ERROR);
          this.showToast(error.response.data.message.msg,this.TOST().ERROR);
        }
      });
    },

    nextPageBtn(indata) {
      console.log("page no value", this.page);
      if (this.pagelength == indata) {
        this.swap_final_count = true;
      } else {
        this.swap_final_count = false;
      }
      this.getOFRData(this.page,this.OFRStatus);
    },

    nextPage(page) {
      this.pageNumber = page;
    },

    getCountForLOB(){
      
      this.lobListAndCount  = []
      let reqData = "ofrStatus=false"
      this.GETM("ofrCount",reqData,(res)=>{
        console.log(res)
        this.lobListAndCount  = res.data.data
      },(err)=>{
        console.log(err)
      },true)
    },
    changeLobData(){
      console.log("selected_lob",this.selected_lob,this.lobListAndCount[this.selected_lob])
      let selectedLob = this.lobListAndCount[this.selected_lob];
      
      if(selectedLob.lob  ==  "Health"){
        this.showHideHealth = true;
        this.showHideOtherThanHealth  = false;
      }else{
        if(!selectedLob.total){
          this.showHideOtherThanHealth  = true;
          this.showHideHealth = false;
          return
        }
        this.page = 1
        let lobOption = selectedLob.lob == "Travel" ? "BTA,LTA" : selectedLob.lob == "PA" ? "PA Group,PA Retail" : selectedLob.lob 
        let getData="ofrStatus=false&perPage=10&pageNo=1&lob="+lobOption
        this.GETM("ofrSearch",getData,(res)=>{
            this.ofr_Table=res.data.data.data
            this.count=res.data.data.total
            this.caseUnderOfr=true
            this.ofrCompletedCase = false
            this.searchData(res.data.data, this.page)        
        },(error) => {
            return error;
        },true)

        this.showHideOtherThanHealth  = true;
        this.showHideHealth = false;
        // this.$router.push("/commonofrsearch")
      
      }
      

    },
    //Other than Health LOB
    noOfDays(date) {
        
      const date1 = new Date(date).setHours(0,0,0,0);
      const date2 = new Date().setHours(0,0,0,0);
      
      const diffDays = parseInt((date2 - date1) / (1000 * 60 * 60 * 24), 10);
      return diffDays + 1;
    },
    turnAroundTime(createdAt, closedOn) {
      let date1 = closedOn ? new moment(closedOn) : new moment();
      let date2 = moment(createdAt);
      let diff = date1.diff(date2, "days");
      return Number(diff) + " Day(s)";
    },
    searchData(data, pageNumber){
      if(!data || (Array.isArray(data) && !data.length)){
        this.pageStartIndex=0
        // this.pageEndIndex=0
        this.totalListCount=0
        this.pageCount=0
        this.totalListCount=0
      }else{
        this.pageStartIndex =  pageNumber == 1 ? 1 : ((pageNumber - 1) * this.itemsPerPage) + 1;// this.pageStartIndex=1
        // this.pageEndIndex=data.total
        this.totalListCount=data.total? data.total : 0
        this.pageCount=data.total_pages ? data.total_pages : 0
        if(!this.totalListCount){
          this.pageStartIndex = 0
        }
      }
        
    },
    formatDate(date) {
      if (!date) return "";

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    dateConvert(a){
        return moment(a).format("DD-MM-YYYY")
    },
    
    checkOfrStatus(item){
        if(item.ofrStatus === true){
            return "closeClr"
        }else{
            return "openClr"
        }     
    },
    callingauxiliarapi(pageNumber){
        if(this.caseUnderOfr || this.ofrCompletedCase){
          if(pageNumber){
            this.page = pageNumber
          }
          let selectedLob = this.lobListAndCount[this.selected_lob];
          let lobOption = selectedLob.lob == "Travel" ? "BTA,LTA" : selectedLob.lob == "PA" ? "PA Group,PA Retail" : selectedLob.lob 
        // let getData="ofrStatus=false&perPage=10&pageNo=1&lob="+lobOption
            let getData=`ofrStatus=${!this.caseUnderOfr}&perPage=10&pageNo=`+this.page+"&lob="+lobOption

            this.GETM("ofrSearch",getData,(res)=>{
              this.ofr_Table=res.data.data.data
              this.count=res.data.data.total
              this.searchData(res.data.data, pageNumber)
            },(error) => {
              return error;
            },
            true)        
        }        
    },

    exportOfr(){
        this.dateReportedFrom=this.dateReportedFrom.length>0?moment(this.dateReportedFrom):""
        this.dateReportedTo=this.dateReportedTo.length>0?moment(this.dateReportedTo):""
        let selectedLob = this.lobListAndCount[this.selected_lob];
        let lobOption = selectedLob.lob == "Travel" ? "BTA,LTA" : selectedLob.lob == "PA" ? "Group,Retail" : selectedLob.lob 
        let reqData = `ofrStatus=${!this.caseUnderOfr}&claimNumber=${this.claimNumber}&featureNumber=${this.featureNumber}&policyNumber&productName&coverage&examiner=${this.examinerName}&memberName&insuredName=${this.insuredName}&claimantName=${this.claimantName}&lob=${lobOption}&dateReportedFrom=${this.dateReportedFrom}&dateReportedTo=${this.dateReportedTo}&month=${this.month}`
        // let reqData=`perPage=10&pageNo=`+pageNumber+"&lob="+lobOption
        // reqData+=`&ofrStatus=${!this.caseUnderOfr}&claimNumber=${this.claimNumber}&featureNumber=${this.featureNumber}&insuredName=${this.insuredName}&claimantName=${this.claimantName}&examiner=${this.examinerName}&dateReportedFrom=${this.dateReportedFrom}&dateReportedTo=${this.dateReportedTo}&month=${this.month}`
        this.GETM("ofrExport",reqData,(res)=>{
          console.log(res)
          this.viewDocument(res.data.data[0]._id)
            },(error) => {
            return error;
        },true)
        // this.dialog_search=false
        // this.clear()
    },
    searchOfr(pageNumber = 1){
        this.dateReportedFrom=this.dateReportedFrom.length>0?moment(this.dateReportedFrom):""
        this.dateReportedTo=this.dateReportedTo.length>0?moment(this.dateReportedTo):""
        let selectedLob = this.lobListAndCount[this.selected_lob];
        let lobOption = selectedLob.lob == "Travel" ? "BTA,LTA" : selectedLob.lob == "PA" ? "PA Group,PA Retail" : selectedLob.lob 
        let reqData=`perPage=10&pageNo=`+pageNumber+"&lob="+lobOption
        reqData+=`&ofrStatus=${!this.caseUnderOfr}&claimNumber=${this.claimNumber}&featureNumber=${this.featureNumber}&insuredName=${this.insuredName}&claimantName=${this.claimantName}&examiner=${this.examinerName}&dateReportedFrom=${this.dateReportedFrom}&dateReportedTo=${this.dateReportedTo}&month=${this.month}`
        this.GETM("ofrSearch",reqData,(res)=>{
        this.ofr_Table=res.data.data.data
        this.count=res.data.data.total
        this.searchData(res.data.data, pageNumber)
        this.dialog_search=false
            },(error) => {
            return error;
        },true)
        // this.dialog_search=false
        // this.clear()
    },
    viewOpenFilePage(item){
        this.$router.push({
        name: "CommonOFRView",
        params: {
            isView: item.ofrStatus,
            item: item,
        },
        });
    },
    clear(){
        this.dateReportedTo="",
        this.dateReportedFrom="",
        this.claimNumber="",
        this.featureNumber="",
        this.claimantName="",
        this.insuredName="",
        this.examinerName="",
        this.month=""
        const a=new Date().toISOString().split('T')[0]
        this.pickerDate=a
        this.pickerDate1=a
    },
    searchChipButton() {
        this.clear();
        this.searchOfr();
        this.showSearchChip = false;
    }
  },

  watch: {},
};
</script>
<style scoped>
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 0.84rem !important;
}

.activeRadioclass {
    background-color: #23b1a9 !important;
    color: #fff !important;
}

.text-white-insrns {
    color: #fff;
    font-size: 12px;
}

.text-dark-insrns{
  font-size: 12px;

}

.minWidth{
min-width: 110px !important;
}

.hdr-txt-clr-txt {
  color: #10242b !important;
  /* padding:0px!important;
    padding: 10px !important;
    padding-left: 14px !important; */
}

.hdr-bg-clr {
  background-color: #f7f7f7;
}
.cust_pr .v-text-field .v-input__slot,
.v-text-field--outline .v-input__slot {
  min-height: 38px !important;
  display: flex !important;
  align-items: center !important;
  /* border:1px red solid; */
}

.pending-work {
  justify-items: start;
}

.lay {
  direction: rtl;
  max-height: 50px;
}
.body-2 {
  font-size: 18px !important;
}
.body-3 {
  font-size: 16px !important;
}

.cstm-clr-pr {
  color: #10242b;
  margin-bottom: 5px;
}
.cust_pr .v-input {
  font-size: 13px !important;
}
.cust_pr .v-input input {
  font-size: 13px !important;
}

.cust_pr .v-input .v-label {
  font-size: 13px !important;
}

.cust-bnkAcc-pgn .v-pagination__navigation {
  box-shadow: unset !important;
  border-radius: unset !important;
  background-color: #ffffff !important;
  border: 1px solid #dae1e6 !important;
}
.cust-bnkAcc-pgn .v-pagination__navigation--disabled {
  background-color: #dae1e6 !important;
}
.cust-bnkAcc-pgn .v-pagination__item {
  box-shadow: unset !important;
  min-width: 2rem !important;
  height: 2rem !important;
  border-radius: unset !important;
  border: 1px solid #dae1e6 !important;
}
.bg-investigation-table-header-color {
  background-color: #23b1a9;
  color: #fff !important;
}
.activeInvestigationClass {
  background-color: #01bbd9 !important;
}
.hide-badge.badge-custom .v-badge__wrapper {
  display: none;
}
.badge-custom span.v-badge__wrapper span.v-badge__badge {
  height: 30px;
  font-size: 14px !important;
  width: 30px;
  border-radius: 50%;
  line-height: 1.6 !important;
}

.mastersButtonWidth .text {
  text-align: left;
  min-width: 290px;
}
.mastersButtonWidth .icon-wrap {
  height: 40px;
  width: 40px;
  display: flex;
  padding: 8px 5px;
  background: #10242b;
  border-radius: 50%;
  margin-top: 0.125em;
}
.text-xsm {
  font: normal normal bold 13px/22px Roboto !important;
}
.mastersButtonWidth i {
  margin: auto;
  color: #fff !important;
  font: normal normal bold 30px/22px Roboto;
}
.mastersButtonWidth.selected_button .icon-wrap,
.mastersButtonWidth:hover .icon-wrap {
  /* background: transparent !important; */
  background: #01bbd9 !important;
  color: #fff !important;
}
.mastersButtonWidth.selected_button,
.mastersButtonWidth:hover {
  background: #01bbd9 !important;
  color: #fff !important;
}
.v-btn--active {
  background: #01bbd9 !important;
}
.mastersButtonWidth {
  display: flex;
  background: #ffffff !important;
  align-content: stretch;
  justify-content: space-between;
  letter-spacing: 0;
  font: normal normal bold 14px/22px Roboto;
  height: 50px !important;
  width: 180px !important;
  margin: 4px;
}
.mastersButtonWidth.selected_button .mastersDiv,
.mastersButtonWidth:hover .mastersDiv {
  border-color: #ffffff !important;
}
.activebg {
  color: white ;
  background-color: #01bbd9 !important;
  
}

.inactivebg {
  color: black !important;
  background-color: white !important;
  
}
.active {
  background-color: #1ebbd9 !important;
  color: #fff !important;
}
.createbtn {
        text-transform: capitalize;
        letter-spacing: 0.4px;
        /* font-weight: 300;
        font-size: 13px !important; */
    }
.tableicons {
        font-size: 19px !important;
        font-weight:bold;
        color:rgb(85, 78, 78);
    }
.form-groups{
    max-width:220px;
    margin:0 0 0 10px
}
.openClr{
        color: #E46A25;
        background: none !important;
        font-weight: bold;
    }
    .closeClr{
        color: #0AB417;
        font-weight: bold;
        background: none !important;
    }
.reset {
        background-color: white;
        border:1px solid #D1121B;
        color:#D1121B;
    }
.cancelbtn {
        border: 1px solid #6e7f7f;
        color: #6e7f7f !important;
    }
</style>
