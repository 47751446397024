<template>
    <v-app>
        <v-container class="containerStyle"
      style="padding:1.5rem; margin:0; max-width:100%">
        <v-layout><h3>Vendor Master Approval</h3></v-layout>
        <v-expansion-panels v-model="panels" multiple>
          <v-expansion-panel>
                <v-expansion-panel-header color="#1EBBD9">
              <span class="page-title-small white--text" >Vendor Information</span>
              <template v-slot:actions>
                <v-icon class="white--text">
                  {{
                    panels.indexOf(0) !== -1 ? "mdi-minus-box" : "mdi-plus-box"
                  }}
                </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content >
                <v-layout wrap class="pt-4 form-group-container">
                  <v-flex class="form-group">
          <label class="form-label">
              LOB 
          </label>
            <v-select
                v-model="vendorObj.lob"
                label="Select"
                :items="lobOptions"
                disabled
                solo
                dense
                multiple
                class="form-control"
                
                clearable
            ></v-select>
        </v-flex>
                  <v-flex class="form-group">
                        <label class="form-label">
                            User ID <span class="red--text">*</span>
                        </label>
                        <v-select solo dense :items="user_Id_Show" item-text="employeenames" item-value="_id" class="form-control" placeholder="Enter..." v-model="userId" :disabled="isView" @change="userid_change()"></v-select>
                    </v-flex>
                  <v-flex class="form-group">
                        <label class="form-label">
                            Vendor ID <span class="red--text">*</span>
                        </label>
                        <v-text-field solo dense  class="form-control" placeholder="Enter..." v-model="vendorObj.vendorId" disabled></v-text-field>
                    </v-flex>
                    <v-flex class="form-group">
                        <label class="form-label">
                            Vendor Type <span class="red--text">*</span>
                        </label>
                        <v-select solo dense  class="form-control" placeholder="Enter..." :items="vendorType" v-model="vendorObj.vendorType" disabled></v-select>
                    </v-flex>
                    <v-flex class="form-group">
                        <label class="form-label">
                            Vendor Name <span class="red--text">*</span>
                        </label>
                        <v-text-field solo dense  class="form-control" placeholder="Enter..." v-model="vendorObj.vendorName" disabled></v-text-field>
                    </v-flex>
                    <v-flex class="form-group">
                        <label class="form-label">
                            Vendor Contact <span class="red--text">*</span>
                        </label>
                        <v-text-field solo dense  class="form-control" placeholder="Enter..." prepend-inner-icon="mdi-phone" v-model="vendorObj.vendorContact" disabled></v-text-field>
                    </v-flex>
                    <v-flex class="form-group">
                        <label class="form-label">
                            Address Line 1 <span class="red--text">*</span>
                        </label>
                        <v-text-field solo dense  class="form-control" disabled placeholder="Enter..." :maxlength="45" v-model="vendorObj.addressLine1"></v-text-field>
                    </v-flex>
                    <v-flex class="form-group">
                        <label class="form-label">
                            Address Line 2  <span class="red--text">*</span>
                        </label>
                        <v-text-field solo dense  class="form-control" disabled placeholder="Enter..." :maxlength="45" v-model="vendorObj.addressLine2"></v-text-field>
                    </v-flex>
                    <v-flex class="form-group">
                        <label class="form-label">
                            Address Line 3 <span class="red--text">*</span>
                        </label>
                        <v-text-field solo dense  class="form-control" disabled placeholder="Enter..." :maxlength="35" v-model="vendorObj.addressLine3"></v-text-field>
                    </v-flex>
                    <v-flex class="form-group">
                        <label class="form-label">Pincode</label>
                        <v-text-field solo dense class="form-control" v-model="vendorObj.pinCode" :disabled="isView"></v-text-field>
                    </v-flex>
                    <v-flex class="form-group">
                        <label class="form-label">
                            Country <span class="red--text">*</span>
                        </label>
                        <v-text-field solo dense disabled class="form-control" placeholder="Enter..." v-model="vendorObj.country"></v-text-field>
                    </v-flex>
                    <v-flex class="form-group">
                        <label class="form-label">
                            City <span class="red--text">*</span>
                        </label>
                        <v-text-field solo dense disabled class="form-control" placeholder="Enter..." v-model="vendorObj.city"></v-text-field>
                    </v-flex>
                    <v-flex class="form-group">
                        <label class="form-label">
                            District <span class="red--text">*</span>
                        </label>
                        <v-text-field solo dense disabled class="form-control" placeholder="Enter..." v-model="vendorObj.district"></v-text-field>
                    </v-flex>
                    <v-flex class="form-group">
                        <label class="form-label">
                            State <span class="red--text">*</span>
                        </label>
                        <v-text-field solo dense disabled class="form-control" placeholder="Enter..." v-model="vendorObj.state"></v-text-field>
                    </v-flex>
                    <!-- <v-flex class="form-group">
                        <v-layout>
                            <v-flex>
                                <label class="form-label">
                                    ISD Code<span class="red--text">*</span>
                                </label>
                                <v-text-field
                                    class="form-control mr-2"
                                    style="max-width:110px"
                                    solo
                                    dense
                                    disabled
                                    :rules="[(v)=>matchRegex(v,'nums')]" 
                                    :maxlength="3"
                                    placeholder="..."
                                    prepend-inner-icon=""
                                    v-model="vendorObj.contactNumber[0].isd"
                                    >
                                    <template v-slot:prepend-inner>
                                        <v-icon class="iconstyle">mdi-phone</v-icon>
                                    </template>
                                </v-text-field>
                            </v-flex>

                            <v-flex >
                                <label class="form-label">
                                    Contact Number<span class="red--text">*</span>
                                </label>
                                
                                <v-text-field
                                    placeholder="Enter..."
                                    solo
                                    dense
                                    disabled
                                    :maxlength="10"
                                    
                                    :rules="[(v)=>requiredField(v),(v)=>matchRegex(v,'nums')]" 
                                    v-model="vendorObj.contactNumber[0].number"        
                                    class="form-control"
                                    
                                >
                                    <template v-slot:append>
                                    <v-icon class="blueiconstyle" @click="mobile_Dialog=!mobile_Dialog" >mdi-phone-plus</v-icon>
                                    </template>
                                </v-text-field>
                            </v-flex>
                        </v-layout>
                        </v-flex> -->
                        <v-flex class="form-group">
                                <label class="form-label">Email Address <span class="red--text">*</span></label>
                                <v-text-field  solo dense class="form-control" placeholder="Enter..." 
                                 :rules="[(v)=>requiredField(v),(v) => validateEmail(v, 'Email ID')]"
                                 v-model="vendorObj.emailAddress" disabled prepend-inner-icon="mdi-email"
                                 >
                                    
                                </v-text-field>
                        </v-flex>
                        <v-flex class="form-group">
                                <label class="form-label">Second Contact-Email Address <span class="red--text">*</span></label>
                                <v-text-field  solo dense class="form-control" placeholder="Enter..." 
                                 :rules="[(v)=>requiredField(v),(v) => validateEmail(v, 'Email ID')]"
                                 v-model="vendorObj.secondContactEmailAddress" disabled prepend-inner-icon="mdi-email"
                                 >
                                    
                                </v-text-field>
                        </v-flex>
                        <v-flex class="form-group">
                          <label class="form-label">Extension</label>
                          <v-text-field solo dense class="form-control" placeholder="Enter..." :maxlength="3" v-model="vendorObj.landlineNumber.isd" :disabled="isView"></v-text-field>
                        </v-flex>
                        <v-flex class="form-group">
                          <label class="form-label">Phone Number</label>
                          <v-text-field solo dense class="form-control" placeholder="Enter..." :maxlength="10" 
                          prepend-inner-icon="mdi-phone" v-model="vendorObj.landlineNumber.number"
                          :rules="[(v)=>matchRegex(v,'nums'),(v) => validateLength(v, { exact: 10 }, '', 'Field requires 10 digits.')]" :disabled="isView"></v-text-field>
                        </v-flex>

                        <v-flex class="form-group">
                          <label class="form-label">Fax Number</label>
                          <v-text-field solo dense class="form-control" placeholder="Enter..." v-model="vendorObj.faxNumber" :disabled="isView"></v-text-field>
                        </v-flex>

                        <v-flex class="form-group">
                          <label class="form-label">Second Contact-Fax Number</label>
                          <v-text-field solo dense class="form-control" placeholder="Enter..." v-model="vendorObj.secondContactFaxNumber" :disabled="isView"></v-text-field>
                        </v-flex>

                        <v-flex class="form-group">
                          <label class="form-label">Preferred Communication Method</label>
                          <v-select solo dense class="form-control" :items="['Email','Fax']" placeholder="Enter..." v-model="vendorObj.preferredCommunicationMethod" :disabled="isView"></v-select>
                        </v-flex>

                        <v-flex class="form-group">
                          <label class="form-label">Cell Phone Number</label>
                          <v-text-field solo dense class="form-control"  placeholder="Enter..." prepend-inner-icon="mdi-phone" v-model="vendorObj.cellNumber"
                          :rules="[(v)=>matchRegex(v,'nums'),(v) => validateLength(v, { exact: 10 }, '', 'Field requires 10 digits.')]" :disabled="isView"></v-text-field>
                        </v-flex>

                        <v-flex class="form-group">
                        <label class="form-label">
                            PAN Number <span class="red--text">*</span>
                        </label>
                        <v-text-field solo dense class="form-control" disabled  v-model="vendorObj.panNumber" :maxlength="10" placeholder="Enter..." ></v-text-field>
                    </v-flex>
                    
                    <v-flex class="form-group">
                        <label class="form-label">
                            GST Number
                        </label>
                        <v-text-field solo dense class="form-control"  placeholder="Enter..."  disabled
                         v-model="vendorObj.gstinNumber"></v-text-field>
                    </v-flex>
                    <v-flex class="form-group">
                        <label class="form-label">GST Frequency</label>
                      <v-select solo dense class="form-control"  label="Select" v-model="vendorObj.gstFrequency" disabled
                      :items="['Monthly','Quarterly','Monthly less than 100 Crore','Monthly more  than 100 Crore but less than 500 Crore','Monthly More than 500 Crore']"></v-select>
                      </v-flex>
                      <v-flex class="form-group">
                        <label class="form-label">TDS Type <span class="red--text">*</span></label>
                      <v-select solo dense class="form-control"  label="Select" :rules="[(v)=>requiredField(v)]" v-model="vendorObj.tdsType" disabled
                      :items="['Contractors','Non Residents','Professional Services - CORP','Professional Services - NON CORP','TDS Not Applicable']"></v-select>
                      </v-flex>
                      <v-flex class="form-group">
                        <v-checkbox label="Differential TDS" class="form-control mt-0" v-model="vendorObj.differentialTds" disabled></v-checkbox>
                      </v-flex>
                      <v-flex class="form-group">
                        <label class="form-label">MSME <span class="red--text">*</span></label>
                      <v-select solo dense class="form-control"  label="Select" :rules="[(v)=>requiredField(v)]" v-model="vendorObj.msme" disabled
                      :items="['Yes','No']"></v-select>
                      </v-flex>
                      <v-flex class="form-group">
                        <label class="form-label">Enterprise Classification </label>
                      <v-select solo dense class="form-control"  label="Select" v-model="vendorObj.enterpriseClassification" disabled
                      :items="['Proprietorship','Association of Persons','Co-operative Society','Partnership','Firm','Company','Individual']"></v-select>
                      </v-flex>
                      <v-flex class="form-group">
                        <label class="form-label">Enterprise Category</label>
                      <v-select solo dense class="form-control"  label="Select" v-model="vendorObj.enterpriseCategory" disabled
                      :items="['Micro','Small','Medium']"></v-select>
                      </v-flex>
                      <v-flex class="form-group">
                        <label class="form-label">UAN</label>
                      <v-text-field solo dense class="form-control"  label="Enter..." disabled v-model="vendorObj.uan"
                      ></v-text-field>
                      </v-flex>
                      <v-flex class="form-group">
                        <v-checkbox label="Active" class="form-control" v-model="vendorObj.status" disabled></v-checkbox>
                    </v-flex>
                </v-layout>
            </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
                <v-expansion-panel-header color="#1EBBD9">
              <span class="page-title-small white--text" >Bank Details</span>
              <template v-slot:actions>
                <v-icon class="white--text">
                  {{
                    panels.indexOf(1) !== -1 ? "mdi-minus-box" : "mdi-plus-box"
                  }}
                </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content >
                <v-layout wrap class="pt-4 form-group-container">
                    <v-flex class="form-group">
                        <v-checkbox label="Bank Details Available" v-model="vendorObj.bankTransfer" class="form-control" disabled></v-checkbox>
                    </v-flex><v-spacer></v-spacer>
                    <div class="me-3 px-2" v-if="showCheque.length > 0">
                                    <label :disabled="!isOCRUploaded " class="form-label mt-2 text-right"
                                        @click="cancelChequeView">
                                        <div style="color:#0AB417; font: normal normal medium 12px/14px Roboto;cursor:pointer">
                                            <v-icon color="#0AB417">
                                                mdi-eye
                                            </v-icon>
                                            <span class="ml-2">View Cancelled Cheque</span>
                                        </div>
                                    </label>
                                </div>

                                <div>
                    <p v-show="vendorObj.bank.bankDetails" class="teal--text mr-8"  style="cursor:pointer" >
                      <v-icon color="#23B1A9" class="mr-3">mdi-tray-arrow-up</v-icon>Upload Cancelled Cheque
                      </p>
                                          
                      </div>
                </v-layout>
                <v-layout wrap class="pt-4 form-group-container">
                    <v-layout class="py-4 cancel-cheque-view-port" v-if="cancelledChequeFileShow && showCheque.length > 0">
                        <v-flex xs12>
                            <div class="close-cancell-cheque" @click="cancelledChequeFileShow = !cancelledChequeFileShow">Close</div>
                            <v-layout justify-center>
                                <object :data="showCheque" width="1000" height="300"></object>
                                <!-- <img height="300" :src="investigatorMasterObj.documents.fileBase" /> -->
                            </v-layout>
                            <!-- <v-layout justify-center class="mt-2">
                                <div class="cancel-cheque-success-btn">OCR was successfully completed.</div></v-layout> -->
                        </v-flex>
                    </v-layout>
                </v-layout>
                <v-layout wrap class="pt-4 form-group-container">
                    <v-flex class="form-group">
                        <v-checkbox label="Overwritten" v-model="vendorObj.overwriteManually" class="form-control" disabled></v-checkbox>
                    </v-flex>
                    <v-flex class="form-group">
                        <label class="form-label">IFSC Code</label>
                        <v-text-field solo dense
                                class="form-control"
                                placeholder="Enter..."
                                v-model="vendorObj.bank.ifscCode"
                                
                                disabled
                                ></v-text-field>
                    </v-flex>
                    <v-flex class="form-group">
                        <label class="form-label">Bank Name</label>
                        <v-text-field solo dense
                                class="form-control"
                                placeholder="Enter..."
                                v-model="vendorObj.bank.bankName"
                                
                                disabled
                                ></v-text-field>
                    </v-flex>
                    <v-flex class="form-group">
                        <label class="form-label">Bank Account Number</label>
                        <v-text-field solo dense
                                class="form-control"
                                placeholder="Enter..."
                                v-model="vendorObj.bank.accountNumber"
                                type="password"
                                disabled
                                ></v-text-field>
                    </v-flex>
                    <v-flex class="form-group">
                        <label class="form-label">Re-Enter Bank Account Number</label>
                        <v-text-field solo dense
                                class="form-control"
                                placeholder="Enter..."
                                v-model="vendorObj.bank.confirmAccountNumber"
                                
                                disabled
                                ></v-text-field>
                    </v-flex>
                    <v-flex class="form-group">
                        <label class="form-label">Branch </label>
                        <v-text-field solo dense
                                class="form-control"
                                placeholder="Enter..."
                                v-model="vendorObj.bank.branch"
                                
                                disabled
                                ></v-text-field>
                    </v-flex>
                    <v-flex class="form-group">
                        <label class="form-label">Bank Address Line 1 </label>
                        <v-text-field solo dense
                                class="form-control"
                                placeholder="Enter..."
                                v-model="vendorObj.bank.bankAddressLine1"
                                
                                disabled
                                >
                                </v-text-field>
                    </v-flex>
                    <v-flex class="form-group">
                        <label class="form-label">Bank Address Line 2 </label>
                        <v-text-field solo dense
                                class="form-control"
                                placeholder="Enter..."
                                v-model="vendorObj.bank.bankAddressLine2"
                                
                                disabled
                                >
                                </v-text-field>
                    </v-flex>
                    <v-flex class="form-group">
                        <label class="form-label">Bank Address Line 3 </label>
                        <v-text-field solo dense
                                class="form-control"
                                placeholder="Enter..."
                                v-model="vendorObj.bank.bankAddressLine3"
                                
                                disabled
                                >
                                </v-text-field>
                    </v-flex>
                    <v-flex class="form-group">
                        <label class="form-label">District </label>
                        <v-text-field solo dense
                                class="form-control"
                                placeholder="Enter..."
                                v-model="vendorObj.bank.district"
                                
                                disabled
                                >
                                </v-text-field>
                    </v-flex>
                    <v-flex class="form-group">
                        <label class="form-label">City </label>
                        <v-text-field solo dense
                                class="form-control"
                                placeholder="Enter..."
                                v-model="vendorObj.bank.city"
                                
                                disabled
                                >
                                </v-text-field>
                    </v-flex>
                    <v-flex class="form-group">
                        <label class="form-label">State </label>
                        <v-text-field solo dense
                                class="form-control"
                                placeholder="Enter..."
                                v-model="vendorObj.bank.state"
                                
                                disabled
                                >
                                </v-text-field>
                    </v-flex>
                    <v-flex class="form-group">
                        <label class="form-label">Pin Code </label>
                        <v-text-field solo dense
                                class="form-control"
                                placeholder="Enter..."
                                v-model="vendorObj.bank.pinCode"
                                
                                disabled
                                >
                                </v-text-field>
                                
                    </v-flex>
                    <v-flex class="form-group">
                        <label class="form-label">Account Holder Name </label>
                        <v-text-field solo dense
                                class="form-control"
                                placeholder="Enter..."
                                v-model="vendorObj.bank.accountHolderName"
                                
                                disabled
                                >
                                </v-text-field>
                    </v-flex>
                    <v-flex class="form-group">
                        <label class="form-label">Account Type </label>
                        <v-select solo dense
                                class="form-control"
                                placeholder="Enter..."
                                :items="accountTypes"
                                v-model="vendorObj.bank.accountType"
                                
                                disabled
                                >
                                </v-select>
                    </v-flex>
                 
                </v-layout>
            </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
                <v-expansion-panel-header color="#1EBBD9">
              <span class="page-title-small white--text" >Upload Documents</span>
              <template v-slot:actions>
                <v-icon class="white--text">
                  {{
                    panels.indexOf(2) !== -1 ? "mdi-minus-box" : "mdi-plus-box"
                  }}
                </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content >
                <v-layout wrap class="pt-4 form-group-container">
                  <v-tabs
                    v-model="tab"
                    hide-slider
                    
                  >
                  <!-- active-class="claimProcessingTabsActive" -->
                    <v-tab
                      v-for="item in tab_items"
                      :key="item"
                      @change="documentTab=item;checkTabChanged()"
                      :class="documentTab == item ? 'claimProcessingTabsActive' :'unActive'"
                    >
                      {{ item }}
                    </v-tab>
                  </v-tabs>
                  <v-layout class="mt-5">
                  <div >
                    <div class="neftStyle"   
                    :class="visualEffect ? 'dragbackground': ''">
                      <v-icon color="#23b1a9" class="iconfont">mdi-tray-arrow-up</v-icon>
                      <span class="ml-2 margincls font-weight-bold" >Drop Files To Upload or <a  ><u > Browse</u></a></span>
                      <p class="margincls dlt_text mb-0">Max 5MB of PDF, Word, Excel, Mail or Image(.jpeg, .jpg, .png)</p>
                      <p class="dlt_text mt-0 ml-4" style="font-size:10px">Multiple documents can be uploaded</p>
                      
                    </div>
                    <span></span>
                            <v-file-input
                                multiple
                                v-model="all_attachments"
                                accept=".doc, .docx, .csv, image/png,image/jpeg,application/msword,application/pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/csv"
                                id="SP_fileupload"
                                disabled
                                style="display: none">
                            </v-file-input>
                            <!-- @change="selectFile" -->
                  </div>
                  <div>
                    <v-data-table
                            :headers="neftHeaders"
                            :items="documentAttachments"                            
                            hide-default-footer
                            no-data-text="No Records for Attachements"
                            class="tabelwidth vDataTableStyling">
                            <template v-slot:[`item.action`] = '{ item}'>            
                                <v-icon class="tableicons mr-4" color="#23B1A9" @click="viewDocument(item)">mdi-eye</v-icon>
                                <v-icon class="tableicons mr-4" color="#23B1A9" @click="downloadDoc(item)">mdi-download</v-icon>
                                <!-- <v-icon class="tableicons" color="#D1121B" @click="deleteDocument(index)" v-if="!(item.documentId )||isView" >mdi-delete</v-icon> -->
                            </template>
                        </v-data-table>
                  </div>
                  </v-layout>

                </v-layout>
            </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
        <v-dialog v-model="email_Dialog" width="500">
                <v-card>
                    <v-card-title
                    class="white--text"
                    style="background-color: #23b1a9"
                  >
                  <h4 class="ml-5">Add Multiple Email ID</h4>
        <v-spacer></v-spacer>
        <v-icon @click="email_Dialog=!email_Dialog" class="white--text">mdi-window-close</v-icon>
                </v-card-title>
                <v-card-text class="grey lighten-2">
                    <v-data-table hide-default-footer
                    :headers="email_Headers"
                    :items="email_Obj"
                    class=" vDataTableStyling"
                    >
                   <template v-slot:[`item.email`]="{item}">
                    <v-text-field
                    solo
                    dense
                    class="form-control mt-5"
                    v-model="item.email"
                    disabled
                    :rules="[(v) => validateEmail(v, 'Email ID')]"
                    >

                    </v-text-field>
                   </template>
                   <template v-slot:[`item.action`]>
                    <v-icon left
                      top color="#d1121b"  disabled>mdi-delete</v-icon>
                   </template>
                </v-data-table>
                <v-layout class="addbtn" justify-center>
                <v-btn
                
                 
                  text
                  elevation="0"
                  disabled
                  class="grey lighten-2 mt-5"
                  style="color: #23B1A9"
                  >+ Add Email ID</v-btn
                >
              </v-layout>
                </v-card-text>
                </v-card>
            </v-dialog>

    <!-- Mobile Dialog -->
    <v-dialog v-model="mobile_Dialog" width="500">
                <v-card>
                    <v-card-title
                    class="white--text"
                    style="background-color: #23b1a9"
                  >
                  <h4 class="ml-5">Add Multiple Contact Number</h4>
        <v-spacer></v-spacer>
        <v-icon @click="mobile_Dialog=!mobile_Dialog" class="white--text">mdi-window-close</v-icon>
                </v-card-title>
                <v-card-text class="grey lighten-2">
                    <v-data-table hide-default-footer
                    :headers="contact_Headers"
                    :items="vendorObj.contactNumber"
                    class=" vDataTableStyling"
                    >
                    <template v-slot:[`item.isd`]="{item}">
                        <v-text-field
                    solo
                    dense
                    :maxlength="3"
                    disabled
                    class="form-control mt-5"
                    v-model="item.isd"
                    :rules="[(v) => matchRegex(v, 'nums')]"
                    >

                    </v-text-field>
                    </template>
                   <template v-slot:[`item.number`]="{item}">
                    <v-text-field
                    solo
                    dense
                    disabled
                    class="form-control mt-5"
                    :maxlength="10"
                    v-model="item.number"
                    :rules="[(v) => matchRegex(v, 'nums')]"
                    >

                    </v-text-field>
                   </template>
                   <template v-slot:[`item.action`]>
                    <v-icon left
                      top color="#d1121b"  disabled>mdi-delete</v-icon>
                   </template>
                </v-data-table>
                <v-layout class="addbtn" justify-center>
                <v-btn
                
                  
                  text
                  elevation="0"
                  disabled
                  class="grey lighten-2 mt-5"
                  style="color: #23B1A9"
                  >+ Add Contact Number</v-btn>
              </v-layout>
                </v-card-text>
                </v-card>
            </v-dialog>
            <v-layout class="mt-5">
                <v-btn outlined color="red" class=" btn" @click="cancel(0)">
                   <v-icon> mdi-backspace </v-icon>
                    <v-divider vertical class="mx-2"></v-divider> Cancel</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                      outlined
                      color="orange"
                      class="btn mr-5"
                      @click="openAuditTrail"
                    >
                      <v-icon> mdi-chart-timeline-variant </v-icon>
                      <v-divider vertical class="mx-2"> </v-divider>Audit Trail</v-btn>
                    <v-btn outlined color="red" class="btn mr-5"   @click="reject_Dialog=!reject_Dialog">
                   <v-icon> mdi-backspace </v-icon>
                    <v-divider vertical class="mx-2">
                      </v-divider> Reject</v-btn>

                <v-btn class="btn" color="teal white--text" dense solo  @click="approve_Dialog=!approve_Dialog"><v-icon>mdi-arrow-right-circle</v-icon>
          <v-divider vertical class="mx-2"></v-divider>
          Approve</v-btn>
            </v-layout>

            <v-dialog v-model="reject_Dialog" width="600">
                <v-card>
                    <v-card-title class="white--text" style="background-color: #23b1a9"
                    ><v-layout style="display:flex;justify-content:center;margin-left: 170px;" >Reject
                       </v-layout>
                       <v-layout style="display:flex;justify-content:end;">
                        <v-icon class="white--text " @click="reject_Dialog=!reject_Dialog;reason=''">mdi-close</v-icon>
                      </v-layout>
                      </v-card-title>
                      <v-form ref="rejectForm" @submit="false">
                        <v-layout class="pt-4 form-group-container" >
                           
                            <v-flex class="mr-2">
                                <label class="form-label">Reason <span><strong class="red--text">*</strong></span></label>
                                <v-text-field  class="form-control" solo dense :rules="reject_Dialog ? [(v)=>requiredField(v)]:[]" v-model="reason" placeholder="Enter..."></v-text-field>
                            </v-flex>
                        </v-layout>
                      </v-form>
                        <v-card-actions style="display:flex;justify-content:center">
                          <v-btn class="btn" color="red white--text mr-0" @click="reject_Dialog=!reject_Dialog;reason=''">
                            No
                        </v-btn>

                        <v-btn class="btn" color="green white--text mr-0" @click.once="onSubmit">
                            Yes
                        </v-btn>
                      </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="approve_Dialog" :width="vendorObj.bank.confirmAccountFlag ? '80%' : '600'">
                <v-card>
                    <v-card-title class="white--text" style="background-color: #23b1a9"
                    ><v-layout style="display:flex;justify-content:center;" >Approve
                       </v-layout>
                       <!-- <v-layout style="display:flex;justify-content:end;">
                        <v-icon class="white--text " @click="approve_Dialog=!approve_Dialog;reason=''">mdi-close</v-icon>
                      </v-layout> -->
                      </v-card-title>
                      <v-card-text v-if="vendorObj.bank.confirmAccountFlag" class="pb-0">
                        <v-layout justify-center class="pb-2" v-if="vendorObj.bank.cancelledCheque.length > 0">
                            <object
                                :data="vendorObj.bank.cancelledCheque[0].fileBase
                          ? vendorObj.bank.cancelledCheque[0].fileBase
                          : showCheque"
                                width="100%" height="300"></object>
                            <!-- <img height="300" :src="investigatorMasterObj.documents.fileBase" /> -->
                        </v-layout>
                        <v-form ref="AccountNumberForm" style="max-width: 450px; margin:auto">
                          <v-flex>
                              <label class="form-label">Bank Account Number<span class="mandatorycolor"> *</span></label>
                              <v-text-field v-model="validateAccNo" class="form-control" solo dense :rules="[(v) => requiredField(v), (v) => shouldBeSame(vendorObj.bank.confirmAccountNumber, v, 'Mismatch Bank Account Number')]" label="Enter ..."></v-text-field>
                          </v-flex>
                        </v-form>
                      </v-card-text>
                      <v-card-text v-else>
                        <v-layout class="pt-4 form-group-container" justify-center>
                            <h4>Are you sure want to Approve ?</h4>
                        </v-layout>
                      </v-card-text>
                        <v-card-actions style="display:flex;justify-content:center">
                          <v-btn class="btn" color="red white--text mr-0" @click="approve_Dialog=!approve_Dialog;reason=''">
                            No
                        </v-btn>

                        <v-btn class="btn" color="teal white--text mr-0" @click.once="onSubmit">
                            Yes, Approve
                        </v-btn>
                      </v-card-actions>
                </v-card>
            </v-dialog>
    <!-- Audit Trail -->
    <audit-trail
      :dialogState="OpenAuditDialog"
      :auditTrailMaster="'vendor'"
      :auditTrailItem="{ uid: auditTrailId }"
      @close-dialog="OpenAuditDialog = false"
    />
    </v-container>
    </v-app>
</template>
<script>
import auditTrail from '../../../../component/MasterAuditTrail.vue'
export default{
    components:{
        auditTrail,
    },
    data(){
        return{
            panels:[0,1,2],
            cancelledChequeFileShow:false,
            tab:null,
            approve_Dialog:false,
            reject_Dialog:false,
            userId:'',
            vendorType:['Client','Delivery Company','Manufacturer','Other','Supplier'],
            tab_items:['NEFT Form','KYC/CKYC','Others'],
            documentTab:"NEFT Form",
            user_Id_Show:[],
            all_attachments:[],
            isOCRUploaded: false,
            neftHeaders:[{text: "Document Name",align: "start",value: "file",sortable: false},
                                {text: "Action",align: "start",value: "action",sortable: false}],
            documentAttachments:[],
            neftAttachment:[],
            otherAttachment:[],
            kycAttachment:[],
            showCheque:[],
            email_Obj:[{email:""}],
            email_Headers:[{text:"Email",value:"email",align:"center"},
            {text:"Action",value:"action",align:"center"}],
        contact_Headers:[{text:"ISD",value:"isd",align:"center"},
        {text:"Contact Number",value:"number",align:"center"},
        {text:"Action",value:"action",align:"center"}],
        //email_dialog 
        email_Dialog:false,
        //mobile_dialog
        mobile_Dialog:false,
        isView:true,
        visualEffect:false,
        accountTypes:['Saving Account', 'Current Account'],
        reason:"",
        lobOptions:[],
        vendor:[],
        vendorObj:{
          lob:[],
                vendorName: "",
                user: {
        ntid: "",
        employeeName: "",
        userId: ""
    },
                
                addressLine1: "",
                addressLine2: "",
                addressLine3: "",
                city: "",
                district: "",
                state: "",
                country:"",
                pinCode: "",
                landlineNumber: {isd: "",number: ""},
                gstFrequency:"",
                tdsType:"",
                differentialTds:false,
                msme:false,
                enterpriseClassification:"",
                enterpriseCategory:"",
                uan:"",
                cellNumber:"",
                panNumber: "",
                emailAddress:'',
                secondContactEmailAddress:"",
                faxNumber:"",
                secondContactFaxNumber:"",
                preferredCommunicationMethod:"",
                vendorType:"",
                
                vendorContact:"",
                gstinNumber: "",
                emailAddress: "",
                bankTransfer:false,
                bank: {
                    
                    ifscCode: "",
                    bankName: "",
                    city: "",
                    bankAddressLine1: "",
                    bankAddressLine2: "",
                    bankAddressLine3: "",
                    branch: "",
                    district: "",
                    state: "",
                    pinCode: "",
                    accountNumber: "",
                    confirmAccountNumber: "",
                    accountType: "",
                    accountHolderName: "",
                    cancelledCheque: []
                },
                
                approveRequest: true,
                approveStatus: "Pending for approval",
                status: false,
                vendorId: "",
                
                overwriteManually:false,
                proceedDuplicate:false
            },
                OpenAuditDialog: false,
                auditTrailId: null,
                validateAccNo: "",
        }
    },
    methods:{
      getLOB() {
        let reqData = '';
        this.showProgress();
        this.GETM("getLOB", reqData, (res) => {
            this.hideProgress();
            if(res.data.statusCode === 200) {
                this.lobOptions = res.data.data[0].lineOfBusiness;
            } else {
                this.lobOptions = [];
                this.showAlert(res.data.msg);
            }
        }, (error) => {
            console.log(error);
            this.hideProgress();
        }, true)
    },
      vendorNames(){
        this.GETM('GET_VENDOR_NAMES',"" + `lob=${this.vendorObj.lob.map((item) => item)}`,
          res=>{this.user_Id=res.data.data
          this.user_Id_Show=res.data.data.map(obj=>{obj.employeenames=`${obj.employeeName} - ${obj.employeeId}`;
          return obj
          })   
      },err=>{console.log(err)},true)
    },
        cancel(data){
                  if(data == 1){
                    this.$router.push({name:'SearchVendorApproval'} );
              }else{
              this.showConfirm("Confirmation","Are you sure you want to leave?","Yes","No",(Yes) => {
                    this.showProgress();
                    this.$router.push({name:'SearchVendorApproval'} );//params:{pageNo:this.pageNo}}
                    this.hideProgress();
                })
              }
            },
            cancelChequeView() {
                this.cancelledChequeFileShow = !this.cancelledChequeFileShow;
        },
            viewDocument(itemData) {
      if (itemData.fileBase) {
        this.viewDocUsingBase(itemData.fileBase);
      } else {
        let requestData = "";
        if (itemData !== null) {
          requestData = "documentId=" + itemData.documentId + "&b64=true";
        }
        this.GETM(
          "VIEW_DOCUMENT",
          requestData,
          (res) => {
            if (res.data.statusCode === 200) {
              this.viewDocUsingBase(res.data.data);
            }
          },
          (error) => {
            console.log("Error", error);
          },
          true
        );
      }
    },
    viewDocUsingBase(base64) {
      const base64ImageData = base64;
      const contentType = base64.split(":")[1].split(";")[0];
      const byteCharacters = window.atob(
        base64ImageData.substr(`data:${contentType};base64,`.length)
      );
      const byteArrays = [];
      for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
        const slice = byteCharacters.slice(offset, offset + 1024);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
      const blob = new Blob(byteArrays, { type: contentType });
      const blobUrl = window.URL.createObjectURL(blob);
      window.open(blobUrl, "_blank");
    },

    downloadDoc(item) {
                if(item.fileBase) {
                    let base64 = item.fileBase;
                    const base64ImageData = base64
                    const contentType = base64.split(':')[1].split(';')[0];
                    const byteCharacters = window.atob(base64ImageData.substr(`data:${contentType};base64,`.length));
                    const byteArrays = [];
                    for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
                        const slice = byteCharacters.slice(offset, offset + 1024);
                        const byteNumbers = new Array(slice.length);
                    for (let i = 0; i < slice.length; i++) {
                        byteNumbers[i] = slice.charCodeAt(i);
                    }
                    const byteArray = new Uint8Array(byteNumbers);
                        byteArrays.push(byteArray);
                    }
                    const blob = new Blob(byteArrays, {type: contentType});
                    const blobUrl =  window.URL.createObjectURL(blob);

                    const element = document.createElement("a");
                    element.href = blobUrl;
                    element.download = item.file;
                    element.click();
                } else {
                    let url = this.getURL("VIEW_DOCUMENT") + "?documentId=" + item.documentId;
                    window.open(url);
                }
            },
//             deleteDocument(index) {
//                 if(this.documentTab=='NEFT Form'){
//         this.neftAttachment.splice(index, 1);
//       }
      
//       if(this.documentTab=='Others'){
//       this.otherAttachment.splice(index, 1);
//       }
//       if(this.documentTab=='KYC/CKYC'){
//       this.kycAttachment.splice(index, 1);
//       }
//       this.checkTabChanged()
// },
checkTabChanged(){
      if(this.documentTab=='NEFT Form'){
        this.documentAttachments=this.neftAttachment
      }
      if(this.documentTab=='Others'){
        this.documentAttachments=this.otherAttachment
      }
      if(this.documentTab=='KYC/CKYC'){
        this.documentAttachments=this.kycAttachment
      }
    },
    viewDocumentCheque(id){
        let requestData = "documentId=" + id + "&b64=true";
        this.GETM(
          "VIEW_DOCUMENT",
          requestData,
          (res) => {
            if (res.data.statusCode === 200) {
              this.showCheque=res.data.data
            }
          },
          (error) => {
            console.log("Error", error);
          },
          true
        );
      },
      get_data(value){
        this.GET_ByURLM('getFrequentApprove',value._id,
        (res)=>{
                this.vendor=res.data.data
                this.vendorObj=res.data.data.operationData[0]
                
                if(res.data.data.operationData[0].msme == true){
                  this.vendorObj.msme = "Yes"
                }else{
                  this.vendorObj.msme = "No"
                }
                
                if(this.vendorObj.attachments.length>=1){
                this.vendorObj.attachments.forEach(e=>{
          if(e.documentType == 'NEFT Form'){
            e.file=e.fileName
           this.neftAttachment.push(e)
          }
          if(e.documentType == 'KYC/CKYC'){
            e.file=e.fileName
            this.kycAttachment.push(e)
          }
          
          if(e.documentType == 'OTHERS'){  
            e.file=e.fileName        
            this.otherAttachment.push(e)
          }
          // if(e.documentType == 'Cancelled Cheque'){ 
          //   e.file=e.fileName      
          //   this.cancelledChequeData.push(e)
          //   this.viewDocumentCheque(e.documentId)
          // }

        })
        this.checkTabChanged()
        this.documentTab='NEFT Form'
      }
      if(this.vendorObj.bank.cancelledCheque.length>=1){
        // this.cancelledChequeData.push(this.vendorObj.bank.cancelledCheque)
        this.viewDocumentCheque(this.vendorObj.bank.cancelledCheque[0].documentId)
      }
      this.popupOCR(this.vendorObj?.overwriteManually)
      this.userId=this.vendorObj.user.userId
      this.vendorNames();
              },() => {},true)
              if(this.$route.params.isView){
          this.heading='View'
          this.isView=true
        }
      },
      ApprovalSubmit(){
        let sendData={approveStatus:this.reject_Dialog ? "Rejected" : "Approved",
          rejectionReason:this.reason}
        //   this.vendorObj.approveStatus=this.reject_Dialog ? "Rejected" : "Approved"
        //   this.vendorObj.rejectionReason=this.reason
        //   this.vendorObj.approveStatus=this.reject_Dialog ? "Rejected" : "Approved"
        this.reject_Dialog=false
        this.approval_Dialog=false
          
        this.PUTM(
          "updateMasterApprove",this.vendor._id,
          sendData,
          (res) => {
            this.hideProgress();
            if (res.data && res.data.statusCode == 200) {
              this.showAlert(res?.data?.msg || "Success", "", "", () => {
                
                // this.onReset();
                this.cancel(1)
                // this.$router.push('/Claim-processing/Child-Eduction/add-assesment-sheet');
                                
              });
              // this.isView=true
            } else {
              this.showAlert(res.data.msg);
              this.hideProgress();
            }
          },
          (err) => {
            console.log(err);
            this.hideProgress();
            let msg =
              err?.response?.data?.msg || "Unable to submit Vendor Master Approval data.";
            this.showAlert(msg);
          },true
        );
      },
      onSubmit(){
        if(this.vendor._id){
          if(!this.$refs?.AccountNumberForm?.validate() && this.vendorObj?.bank?.confirmAccountFlag && !this.reject_Dialog){
              return this.showAlert("Please fill bank account number.");
          }
          if(this.reject_Dialog){
            if(!this.$refs?.rejectForm?.validate()){
              return this.showAlert("Please fill mandatory fields.");
            }
          }
          this.ApprovalSubmit()
          }
      
  },
  openAuditTrail(){
      this.OpenAuditDialog = true
      this.auditTrailId = this.vendorObj.vendorId
    },
    
    },
    created(){
      this.$store.commit("SAVE_PAGE_HEADER", "Vendor Master Approval");
        if(Object.keys(this.$route.params).length != 0){
          this.get_data(this.$route.params.id)
           
        }
        this.getLOB()
        
    }
    
}
</script>
<style scoped>
.iconstyle {
  background: #f7f7f7 0% 0% no-repeat padding-box;
  border: 0.5px solid #c1c8cc !important;
  color: #fff;
  width: 29px;
  margin-left: -0.1em;
  height: 38px;
  color: #6b6b6b;
}
.blueiconstyle {
  width: 38px;
  height: 38px;
  background: #01bbd9 0% 0% no-repeat padding-box;
  margin-right: -0.5em;
  color: #f7f7f7;
}
.claimProcessingTabsActive {
  background-color: #1ebbd9;
  color: #fff !important;
}
.unActive{
  border:1px solid #ccc;
  margin: 0px 5px
}
.margincls{
        font-size:12px;
        margin-left:-12px
}
.dlt_text{
        color: rgb(145, 145, 145);
}
.neftStyle{
        height: 150px;
        width: 350px;
        border: 3px dashed #ccc;
        /* padding-top: 40px;
        padding-left: 16%; */
        display: table-cell;
        vertical-align: middle;
        text-align: center;
}
.tabelwidth{
  width:500px;
  margin:0px 150px
}
</style>