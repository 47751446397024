<template>
  <div>
    <slot name="label">
      <label class="form-label" v-show="label">
        Pin Code <span class="red--text" v-if="isRequired"><strong> * </strong></span>
      </label>
    </slot>
    <v-autocomplete :disabled="isDisabled" maxlength="11" :rules="isRequired ? [(v) => requiredField(v) ,...validationRules] : validationRules" @change="pinCodeChangeHandler" item-text="locpincode" item-value="pinCode" :search-input.sync="search" :items="pincodes" v-model="pincodeVal" label="Enter..." dense solo class="form-control" />
  </div>
</template>

<script>
export default {
  name: "PincodeSearch",
  props: {
    pinCodeRules : {
      default : () => []
    },
    pincodeRequired : {
      default : false
    },
    pinCode: {
      default: "",
    },
    isDisabled : {
      default : false
    },
    index:{
      default:0
    },
    label:{
      default:true
    }
  },
  data() {
    return {
      search: "",
      pincodes: [],
    };
  },

  created() {
    console.log(this.pinCode)
    this.$root.$refs.A = this; // creating refs to access in other component
  },
  computed: {
    validationRules : {
      get() {
        return this.pinCodeRules;
      },
      set() {
        return this.pinCodeRules;
      },
    },
    isRequired : {
      get() {
        return this.pincodeRequired;
      },
      set() {
        return this.pincodeRequired;
      },    
    },
    pincodeVal: {
      get() {
        this.search = this.pinCode ? this.pinCode.toString() : "";
        return this.pinCode ? this.pinCode.toString() : "";
      },
      set() {
        this.search = this.pinCode ? this.pinCode.toString() : "";
        return this.pinCode ? this.pinCode.toString() : "";
      },
    },
  },
  methods: {
    pinCodeChangeHandler(v) {
      const foundData = this.pincodes.find((item) => item.pinCode === v);
      this.$emit("on-change", foundData,this.index);
    },
  },
  watch: {
    search(value) {
      console.log(value)
      if (value && value.length > 2) {
        let reqData = "pageNo=1&perPage=100&pinCode=" + value.split(" - ")[0];
        this.GETM(
          "getPincodes",
          reqData,
          (res) => {
            const responseData = res?.data?.data?.data || [];
            if (responseData.length > 0) {
              this.pincodes = [];
              this.pincodes = responseData.map((item) => {
                return {
                  locpincode: item.pinCode + " - " + item.pinCodeLocality,
                  ...item,
                };
              });
            }
          },
          (err) => {
            console.error("Issue while fetching pin codes.", err);
          }
        );
      }
    },
  },
};
</script>

<style></style>
