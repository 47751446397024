<template>
    <div>
      <v-container fluid>
        <v-row>
          <v-layout column class="mt-3">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">Claim Examiner Remarks</v-text>
            <textarea
              solo
              dense
              v-model="formData.remarks"
              label="Enter..."
              style="height: 120px; padding: 10px; border-style: solid"
              maxlength="2000"
              disabled
              counter="2000"
            >
            </textarea>
          </v-layout>
        </v-row>
        <v-layout row>
            <v-flex md3 sm3 xs6 :align-self-end="true">
                <v-layout column class="cust_usrsrch mx-2 mt-2">
                    <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
                    SIU Zone
                    </v-text>
                    <v-text-field
                    solo
                    label="Enter..."
                    v-model="formData.siuZone"
                    maxlength="20"
                    counter="20"
                    class="rounded-0"
                    disabled="true"
                    aria-required="true"
                    :rules="[alphaNumeric(formData.siuZone, 'SIU Zone')]"
                    >
                    </v-text-field>
                </v-layout>
            </v-flex>
            <v-flex md3 sm3 xs6 :align-self-end="true">
                <v-layout column class="cust_usrsrch mx-2 mt-2">
                    <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
                        Referral Type
                    </v-text>
                    <v-text-field
                    solo
                    label="Enter..."
                    v-model="formData.referralType"
                    maxlength="20"
                    counter="20"
                    class="rounded-0"
                    disabled="true"
                    aria-required="true"
                    :rules="[alphaNumeric(formData.referralType, 'Referral Type')]"
                    >
                    </v-text-field>
                </v-layout>
            </v-flex>
            <v-flex md3 sm3 xs6 :align-self-end="true">
                <v-layout column class="cust_usrsrch mx-2 mt-2">
                    <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
                        Referred From
                    </v-text>
                    <v-text-field
                    solo
                    label="Enter..."
                    v-model="formData.referredFrom"
                    maxlength="20"
                    counter="20"
                    class="rounded-0"
                    disabled="true"
                    aria-required="true"
                    :rules="[alphaNumeric(formData.referredFrom, 'Referred From')]"
                    >
                    </v-text-field>
                </v-layout>
            </v-flex>
            <v-flex md3 sm3 xs6 :align-self-end="true">
                <v-layout column class="cust_usrsrch mx-2 mt-2">
                    <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
                        Referred To
                    </v-text>
                    <v-text-field
                    solo
                    label="Enter..."
                    v-model="formData.referredTo"
                    maxlength="20"
                    counter="20"
                    class="rounded-0"
                    disabled="true"
                    aria-required="true"
                    :rules="[alphaNumeric(formData.referredTo, 'Referred To')]"
                    >
                    </v-text-field>
                </v-layout>
            </v-flex>
            <v-flex md3 sm3 xs6 :align-self-end="true">
                <v-layout column class="cust_usrsrch mx-2 mt-2">
                    <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
                        Referred Date & Time
                    </v-text>
                    <v-text-field
                    solo
                    label="Enter..."
                    v-model="formData.referredTime"
                    maxlength="20"
                    counter="20"
                    class="rounded-0"
                    disabled="true"
                    aria-required="true"
                    :rules="[alphaNumeric(formData.referredTime, 'Reffered Date And Time')]"
                    >
                    </v-text-field>
                </v-layout>
            </v-flex>
            <v-flex md3 sm3 xs6 :align-self-end="true">
                <v-layout column class="cust_usrsrch mx-2 mt-2">
                    <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
                        SIU Case Status
                    </v-text>
                    <v-text-field
                    solo
                    label="Enter..."
                    v-model="formData.siuCaseStatus"
                    maxlength="20"
                    counter="20"
                    class="rounded-0"
                    disabled="true"
                    aria-required="true"
                    :rules="[alphaNumeric(formData.siuCaseStatus, 'Siu Case Status')]"
                    >
                    </v-text-field>
                </v-layout>
            </v-flex>
            <v-flex md3 sm3 xs6 :align-self-end="true">
                <v-layout column class="cust_usrsrch mx-2 mt-2">
                    <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
                        Due date
                    </v-text>
                    <v-text-field
                    solo
                    label="Enter..."
                    v-model="formData.dueDate"
                    maxlength="20"
                    counter="20"
                    class="rounded-0"
                    disabled="true"
                    aria-required="true"
                    :rules="[alphaNumeric(formData.dueDate, 'Due Date')]"
                    >
                    </v-text-field>
                </v-layout>
            </v-flex>
        </v-layout>
      </v-container>
    </div>
  </template>
  
  <script>
  import moment from "moment";
  import { getClaimDetailsId } from '../apiservices/formData';
  import { getWoritemDetailsById } from "../apiservices/Workitem";
 import { getPrePopulatedData } from '../apiservices/Investigation';
  export default {
    data() {
      return {
        validationmessage: "This field is required",
        rules: {
          mandatoryrule: [(v) => !!v || this.validationmessage],
        },
        formData: {
          siuZone: "",
          referralType: "",
          referredFrom: "",
          referredTo: "",
          siuCaseStatus: "",
          dueDate: "",
          referredTime: "",
          remarks: ""
        },
      };
    },
    created(){
      this.getData();
      console.log(this.$route.params, 'LOG1');
      this.formData.siuZone = this.$route.params.allData.zone ?? '';
      this.getInvestigationDetails(this.$route.params.allData.siuInvestigationTaskID);
    this.viewMoreDetails(this.$route.params.allData._id)
    },
    methods: {
      async getData() {
        let data = {};
        try{
          data = await getClaimDetailsId(this.$route.params.claimId);
          let claimDetail = data.data.claimsDetail;
          this.formData = claimDetail;
        } catch(e){
          this.showToast(e.response?.data?.msg || e.response?.data  || e.response || e, this.TOST().ERROR);
        }
      },
      async viewMoreDetails(id) {
      try {
        let res = await getWoritemDetailsById(id);
        let workItemDetail = res.data.data[0];
        this.formData.referralType = workItemDetail.referralType ?? '';
        this.formData.siuCaseStatus = workItemDetail.status ?? '';
        this.formData.referredTime = moment(workItemDetail.referredDateAndTime).format("DD/MM/YYYY") ?? '';
        this.formData.referredTo = workItemDetail.assginDetails.userName ?? this.$route.params.allData?.assginDetails?.userName;
        this.formData.referredFrom = workItemDetail.referedFromNtid ?? this.$route.params.allData?.referedFromNtid;
        this.formData.remarks = workItemDetail.referralRemark ?? this.$route.params.allData?.referralRemark;

      } catch (e) {
        this.showToast(e.response?.data?.msg || e.response?.data  || e.response || e, this.TOST().ERROR);
      }
    },
    async getInvestigationDetails(id){
      let res = await getPrePopulatedData(id);
      this.formData.investigatorName = res.siuUserDetails.investigatorDetails.name ?? '';
      this.formData.investigatorID = res.siuUserDetails.investigatorDetails.ntidUserId ?? '';
    },
      computedDate(name) {
        return this[name] ? moment(this[name]).format("DD/MM/YYYY") : "";
      },
      alphaNumeric(fieldName, name, mandatory = true) {
        if (fieldName.length > 0) {
          return (v) => /^[a-zA-Z0-9]+$/.test(v) || "Enter valid " + name;
        }
      },
    },
  };
  </script>
  