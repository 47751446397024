<template>
    <div class="show-inline">
        <v-btn dark tile color="#23B1A9" class="text-capitalize px-3 mx-2 my-2" small @click="invoicePdf">
            Invoice PDF
        </v-btn>
    </div>
</template>
<script>
import { getInvoicePDFUrl } from '../apiservices/Investigation';
export default {
    data () {
      return {
        isInvoicePdfClick: false,
      }
    },
    props:['siuInvoiceId'],
    methods: {
        async invoicePdf() {
            if (this.siuInvoiceId) {
                let res = await getInvoicePDFUrl(this.siuInvoiceId);
                if (res?.data?.statusCode == 200) {
                    let base64String = res.data.data.base64
                    const binaryString = atob(base64String);
                    const uint8Array = new Uint8Array(binaryString.length);
                    for (let i = 0; i < binaryString.length; i++) {
                        uint8Array[i] = binaryString.charCodeAt(i);
                    }
                    const blob = new Blob([uint8Array], { type: "application/pdf" });
                    const blobUrl = window.URL.createObjectURL(blob);
                    this.isInvoicePdfClick = true
                    window.open(blobUrl, "_blank")
                    return;
                } else {
                    let errorMessage = res.message ? res.message : "No Result Found"
                    this.showToast(errorMessage, this.TOST().ERROR);
                    return;
                }
            }
        },
    }
}
</script>