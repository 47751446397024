<template>
  <v-app>
      <v-card tile class="cntr">
        <v-layout column class="">
          <v-text class="loginView-title pt-3">Login</v-text>
          <v-divider class="mt-3 mb-5 mx-4"></v-divider>
          <v-form v-model="valid"   ref="logform">
            
              <v-flex xs12>
                <v-layout row justify-center class="px-7">
              <div style="background-color:#23B1A9; width: 55px; height: 55px;  display: flex; justify-content:center;align-items:center; box-shadow: 0px 2px 2px #00000051;">
                <img src="../assets/User_83242x.png" style="width: 30px; height: 30px;"></div>
                 <v-text-field
                  outlined class="rounded-0 custom-txt custom-placeholer-color"
                  label="Enter your email id"
                  height="25"
                  required
                  v-model="loginData.email"
                  >

  <!-- :rules="emailRules" -->
                </v-text-field>
                </v-layout>
                 </v-flex>
             <v-flex xs12>
             <v-layout row class="px-7">

              <div style="background-color:#23B1A9; width: 55px; height: 55px;  display: flex; justify-content:center;align-items:center; box-shadow: 0px 2px 2px #00000051;">
                <img src="../assets/Password_83232x.png" style="width: 30px; height: 30px;"></div>
                <v-text-field
                  outlined class="rounded-0 custom-txt custom-placeholer-color"
                  label="Enter your password"
                  height="25"
                  :append-icon="e1 ? 'visibility' : 'visibility_off'"
                  @click:append="() => (e1 = !e1)"
                  :type="e1 ? 'password' : 'text'"
                  required
                  :rules="rules.select"
                   v-model="loginData.password"
                  >

                </v-text-field>
            </v-layout>
            </v-flex>
             <div class="pa-3 pt-1" >
          <v-autocomplete  :rules="rules.select" solo dense
          placeholder="Select Lob" clearable v-model="lobtype"
          :items="['Marine', 'Health']" ></v-autocomplete>
          </div>
            <v-layout justify-space-between align-center class="px-4">
              <v-checkbox  class="loginView-Rme" v-model="rememberMe" color="primary">
                   <span slot="label" class="" color="#8A8A8A">Remember me.</span>
              </v-checkbox>
              <v-text class="font-weight-medium" style="text-decoration: underline; cursor: pointer;" color="#10242B">forgot password?</v-text>
            </v-layout>
            
          </v-form>

          <!-- <div class="mb-2" style="display:flex;justify-content:center;align-items:center" v-if="loginData.password!== '' || loginData.password=== null || loginData.password=== undefined && loginData.email  !== '' || loginData.email  === null || loginData.email  === undefined" > -->
            <!-- <div style="justify-content:center;align-items:center"> -->
            <!-- <vue-recaptcha
                :sitekey="sitekey"
                :loadRecaptchaScript="true"
                @verify="verifyuser"
                @expired ="ExpiredCaptch"
                @error ="ErrorOccured"
               >
            </vue-recaptcha>
            </div> -->
          <!-- </div> -->
         
          <v-card-actions class="px-3 pb-5">
            <!--  -->
            <!-- <v-btn block v-if="verifyuserdetails === false " disabled>Login</v-btn> -->
            <!-- v-else  -->
            <v-btn  block tile color="#E46A25" dark class="" @click="login()">Login.</v-btn>


            <!-- <v-btn rounded @click="tone = !tone" > toggle login {{tone}} </v-btn> -->
          </v-card-actions>

        </v-layout>
          
      </v-card>
  </v-app>
</template>
  

<script>

// import * as config from '../common/API_Config'
// import VueRecaptcha from 'vue-recaptcha';
import axios from 'axios';
import * as config from '../common/API_Config.js';
export default {
  components:{
    // VueRecaptcha
  },
  computed: {


  },
  data() {
    return{
      lobtype:null,
       dialog: true,  
      valid:false,
        rules: {
                
                select: [(v) => !!v || "This Field is Required"],
                select2: [(v) =>  v.length>0 || "This Field is Required"],
                
            },
      loginData: {
                    email: '',
                    // password: 'Iorta@2020',
                    password:'Welcome@2021'
                },


                emailRules: [
                    v => !!v || 'E-mail is required',
                    v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,6})+$/.test(v) || 'E-mail must be valid'
                ],
      e1: true,
      rememberMe: false,
      // sitekey: config.CAPTCHA_ID,
      verifyuserdetails:false,
      tone:false,

    }
  },

  methods:{
    verifyuser(){
      this.verifyuserdetails =  true
    },
    ExpiredCaptch(){
      this.verifyuserdetails =  false
    },
    ErrorOccured(){
      this.verifyuserdetails = false
    },

    catpchaLOGIn(){
      if(this.verifyuserdetails === true ){
          this.login();
      }else{
          this.showToast('Please Validate Captcha','warning',)
      }
    },


    login(){

      let self=this
         if (!self.$refs.logform.validate()){
                self.$refs.logform.validate()
                self.showToast("Please enter E-mail, Password and LOB",self.TOST().WARNING);
                return
          } 
       if(self.loginData.email == '' || self.loginData.password == ''){
          self.showToast("Please enter E-mail and Password",self.TOST().WARNING);
         return
       }

        if(self.lobtype == null || self.lobtype == ''){
          self.showToast("Please Select Line of Business",self.TOST().WARNING);
          self.$refs.logform.validation()
         return
       }
      
     
      // self.showLoader('Loading', true);
      let demodata={
        userName:self.loginData.email,
        password:self.loginData.password,
        lob: self.lobtype,
      }

    // demodata[self.tone ? 'email':'userName'] = self.loginData.email
    // let switchapi = self.tone ? 'login' : 'login-with-ad'
      console.log(config,">>>> from demodata--------->",demodata);
    //  return
      axios({
          method: "post",
          url: config.LOGIN,
          // "https://claimsdrive-commercial.salesdrive.app/common-system-service/users/login-with-ad" ,
          // login-with-ad
          //login 
          // "http://claimsdrive-health.salesdrive.app/user-management/user-management-service/api/users/authenticate",
            // "http://a081ec054c4024a9bb2749fe59c4654e-a48161044fe888c4.elb.ap-south-1.amazonaws.com/user-management-service/api/users/authenticate",
          headers: { "Content-Type": "application/json",},
          data:demodata,
      })
      .then(function (response) {
        console.log(" Login APi Response>>>>>>", response.data);
        if(response.data.statusCode===200){
            self.showToast(response.data.msg,self.TOST().SUCCESS);
          let TYPE = 'Health'
            if(response.data.data.hasOwnProperty('selectedLOB')){
                  if(response.data.data.selectedLOB == 'Health'){
                    TYPE = 'Health'
                  }else{
                    TYPE = 'Marine'
                  }
            }else{
              // TYPE = 'Marine'
                TYPE = 'Health'
            }

          if(response.data.data.email == 'superuser@iorta.com'){
              TYPE = self.lobtype 

          }

            response = {...response, env_type_is: TYPE}
            self.$store.commit('LOGIN_SUCCESS', response);

           if(response.data.data.hasOwnProperty('selectedLOB')){
              if(response.data.data.selectedLOB == 'Health'){
                     self.$router.push('/HomePage');
              }else{
                   //marine landing-page => homepage 
                     self.$router.push("/home");
              }
           }else{

                if(response.data.data.email == 'superuser@iorta.com'){
                      if(self.lobtype  == 'Health'){
                            self.$router.push('/HomePage');
                      }else{
                          //marine landing-page => homepage 
                            self.$router.push("/home");
                      }
                }
                //marine landing-page => homepage 
                // self.$router.push("/home");
           }
            

          
        }
      }).catch(function (error) {
        console.log("Login APi Error>>>>>>", error);
        self.showToast(error.response.data.msg,self.TOST().ERROR);
      });


        

    }
  }
}
</script>
<style >

.cntr {
  margin: auto;
  /* max-width:"345px";
  min-width:"345px"; */
  width: 30%;
  /* border: 3px solid green;
  padding: 10px; */
}

.loginView-title{
        font-family: roboto;
        font-weight: 700;
        font-size:22px;
        text-align: center;
        color: #10242B;
    }
    
  .cust_pd .v-text-field .v-input__slot,
.v-text-field--outline .v-input__slot {
    min-height: 42px !important;
    display: flex !important;
    align-items: center !important;
    /* border:1px red solid; */
}

/* .custom-txt .v-input__slot{
        border-radius: 0px !important;
        border: 0.5px solid #C1C8CC !important;
        border-left: 0px !important;
    } */
    
    .custom-txt .v-input__control .v-input__slot {
        background-color: #F7F7F7 !important;
    }
    /* .custom-txt-pswd .v-input__slot{
        border-radius: 0px !important;
        border: 1px solid #C1C8CC !important;
        border-left: 0px !important;   
    } */
     
    .custom-txt-pswd .v-input__control .v-input__slot {
        background-color: #F7F7F7 !important;
    }

    .custom-placeholer-color .v-label {
        color: #8A8A8A !important;
        font-family: Roboto;
        opacity: 1;
    }



</style>