<template>
  <div>
    <v-layout row class="policyType">
      <p>Policy Type</p>
    </v-layout>
    <v-layout style="margin-left: 15px;">
      <v-radio-group v-model="typeOfPolicy" style="width:500px">
        <v-layout row xs6>
          <!-- <v-flex xs3> -->

          <v-layout
            class=" mx-2 my-2 cust_pr_Ms cust-rmvpd-pr-Ms"
            style="background-color: #F7F7F7; height: 35px; border: solid 0.5px #dae1e6;"
          >
            <v-radio
              color="#23b1a9"
              value="Group"
              class="resizebtn font-weight-bold"
              label="Group"
              @click="typedOfPolicySelected"
            ></v-radio>
          </v-layout>

          <!-- </v-flex> -->

          <!-- <v-flex xs3> -->

          <v-layout
            class=" mx-2 my-2 cust_pr_Ms cust-rmvpd-pr-Ms"
            style="background-color: #F7F7F7; height: 35px ; border: solid 0.5px #dae1e6;"
          >
            <v-radio
              color="#23b1a9"
              value="Retail"
              class="resizebtn font-weight-bold"
              label="Retail"
              @click="typedOfPolicySelected"
            ></v-radio>
          </v-layout>

          <!-- </v-flex> -->
        </v-layout>
      </v-radio-group>

      <!-- <v-flex xs3></v-flex> -->
      <!-- <v-flex xs3></v-flex> -->
      <!-- <v-flex xs3></v-flex> -->
    </v-layout>

    <div v-if="typeOfPolicy === 'Group'">
      <v-form ref="form">
        <v-layout>
          <v-flex xs3>
            <v-layout column class="cust_pr_Ms mx-2  ">
              <v-text class="font-weight-bold cstm-clr-pr-Ms">
                Member ID
              </v-text>

              <v-text-field
                solo
                label="Enter..."
                v-model="memberId"
                class="rounded-0"
                :rules="[alphaNumericMemberId(memberId)]"
                dense
              >
              </v-text-field>
            </v-layout>
          </v-flex>

          <v-flex xs3>
            <v-layout column class="cust_pr_Ms mx-2  ">
              <v-text class="font-weight-bold cstm-clr-pr-Ms">
                Member First Name
              </v-text>

              <v-text-field
                solo
                label="Enter..."
                v-model="memberFN"
                class="rounded-0"
                :rules="[alpha(memberFN)]"
                dense
              >
              </v-text-field>
            </v-layout>
          </v-flex>
          <v-flex xs3>
            <v-layout column class="cust_pr_Ms mx-2  ">
              <v-text class="font-weight-bold cstm-clr-pr-Ms">
                Member Last Name
              </v-text>

              <v-text-field
                solo
                label="Enter..."
                v-model="memberLN"
                class="rounded-0"
                :rules="[alpha(memberLN)]"
                dense
              >
              </v-text-field>
            </v-layout>
          </v-flex>
          <v-flex xs3>
            <v-layout column class="cust_pr_Ms mx-2  ">
              <v-text class="font-weight-bold cstm-clr-pr-Ms">
                Corporate Group Name
              </v-text>

              <v-text-field
                solo
                label="Enter..."
                v-model="corporateGN"
                class="rounded-0"
                :rules="[alphaGN(corporateGN)]"
                dense
              >
              </v-text-field>
            </v-layout>
          </v-flex>
          <v-flex xs3>
            <v-layout column class="cust_pr_Ms mx-2  ">
              <v-text class="font-weight-bold cstm-clr-pr-Ms">
                Policy Number
              </v-text>

              <v-text-field
                solo
                label="Enter..."
                v-model="policyNum"
                class="rounded-0"
                :rules="[alphaNumericPoilcy(policyNum)]"
                dense
              >
              </v-text-field>
            </v-layout>
          </v-flex>
        </v-layout>

        <v-layout>
          <v-flex xs3>
            <v-layout column class="cust_pr_Ms mx-2  ">
              <v-text class="font-weight-bold cstm-clr-pr-Ms">
                Employee ID
              </v-text>

              <v-text-field
                solo
                label="Enter..."
                v-model="employeeId"
                class="rounded-0"
                :rules="[alphaNumeric(employeeId)]"
                dense
              >
              </v-text-field>
            </v-layout>
          </v-flex>

          <v-flex xs3>
            <v-layout column class="cust_pr_Ms mx-2  cust-rmvpd-pr-quick">
              <v-text class="font-weight-bold cstm-clr-pr-Ms">
                Date of Admission
              </v-text>

              <v-menu
                ref="calender"
                :close-on-content-click="false"
                v-model="dateOfAddmissionDate"
                :nudge-right="40"
                lazy
                transition="scale-transition"
                full-width
                max-width="290px"
                min-width="290px"
                class="rounded-0 cstm-clr-pr-Ms"
                dense
              >
                <template dense v-slot:activator="{ on, attrs }">
                  <v-text-field
                    dense
                    v-bind="attrs"
                    v-on="on"
                    label="dd/mm/yyyy"
                    :value="computeddateOfAddmissionDate"
                    solo
                    class="rounded-0"
                    readonly
                  >
                    <v-icon
                      slot="prepend-inner"
                      dense
                      style="background-color:#F7F7F7; height: 37px; width: 40px;"
                    >
                      date_range
                    </v-icon>
                  </v-text-field>
                </template>
                <v-date-picker
                  dense
                  v-model="dateOfAddmissionDateModel"
                  no-title
                  @input="dateOfAddmissionDate = false"
                ></v-date-picker>
              </v-menu>
            </v-layout>
          </v-flex>
          <v-flex xs3>
            <v-layout column class="cust_pr_Ms mx-2  ">
              <v-text class="font-weight-bold cstm-clr-pr-Ms">
                Certificate Number
              </v-text>

              <v-text-field
                solo
                label="Enter..."
                v-model="certificateNum"
                class="rounded-0"
                :rules="[alphaNumericCert(certificateNum)]"
                dense
              >
              </v-text-field>
            </v-layout>
          </v-flex>
          <v-flex xs3>
            <v-layout column class="cust_pr_Ms mx-2  cust-rmvpd-pr-quick">
              <v-text class="font-weight-bold cstm-clr-pr-Ms">
                D.O.B
              </v-text>

              <v-menu
                ref="calender"
                :close-on-content-click="false"
                v-model="dobDate"
                :nudge-right="40"
                lazy
                transition="scale-transition"
                full-width
                max-width="290px"
                min-width="290px"
                class="rounded-0 cstm-clr-pr-Ms"
                dense
              >
                <template dense v-slot:activator="{ on, attrs }">
                  <v-text-field
                    dense
                    v-bind="attrs"
                    v-on="on"
                    label="dd/mm/yyyy"
                    :value="computeddobDate"
                    solo
                    class="rounded-0"
                    readonly
                  >
                    <v-icon
                      slot="prepend-inner"
                      dense
                      style="background-color:#F7F7F7; height: 37px; width: 40px;"
                    >
                      date_range
                    </v-icon>
                  </v-text-field>
                </template>
                <v-date-picker
                  dense
                  v-model="dobDateModel"
                  no-title
                  @input="dobDate = false"
                ></v-date-picker>
              </v-menu>
            </v-layout>
          </v-flex>
          <v-flex xs3>
            <v-layout column class="cust_pr_Ms mx-2  ">
              <v-text class="font-weight-bold cstm-clr-pr-Ms">
                Proposer's Mobile Number
              </v-text>

              <v-text-field
                solo
                label="Enter..."
                v-model="proposerNum"
                class="rounded-0 comboxPadding"
                :rules="[numeric(proposerNum)]"
                dense
                maxlength="10"
              >
                <v-icon
                  slot="prepend-inner"
                  dense
                  style="background-color:#F7F7F7; height: 37px; width: 40px;"
                >
                  call
                </v-icon>
              </v-text-field>
            </v-layout>
          </v-flex>
        </v-layout>

        <v-card
          class=" pa-5 rounded-0"
          style="background-color: #F7F7F7; display:flex;justify-content:space-between;"
        >
          <v-layout style="flex:1" justify-end>
            <v-btn
              dark
              tile
              color="#E46A25"
              class="text-capitalize px-3 ml-3 testing"
              small
              @click="searchMember"
              ><v-icon dark small>search</v-icon>
              <v-divider vertical class="mx-2"></v-divider>Search</v-btn
            >
            <v-btn
              dark
              tile
              color="#E46A25"
              class="text-capitalize px-3 ml-3"
              small
              @click="$router.go(-1)"
              ><v-icon dark small>cancel</v-icon>
              <v-divider vertical class="mx-2"></v-divider>Cancel</v-btn
            >
          </v-layout>
        </v-card>
      </v-form>

      <v-card class="rounded-0" v-if="showTable">
        <v-data-table
          :headers="headers"
          :items="dataItems"
          dense
          class="mt-5"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          hide-default-footer
        >
          <template v-slot:item.select="{ item }">
            <div>
              <v-radio-group v-model="valueOfGRadio">
                <!-- value -->
                <v-radio
                  class="resizebtn"
                  color="#23b1a9"
                  :key="item.id"
                  :id="item.id"
                  :value="item.id"
                  @click="dialogOk(item)"
                ></v-radio>
              </v-radio-group>
              <!-- <input type="radio" id="one" :value="valueOfBtn" ref="valueOfBtnIs" @click="valueOfRadio" /> -->
            </div>
          </template>
        </v-data-table>
        <v-divider></v-divider>
        <v-layout row justify-end align-center class="mx-2">
          <v-text
            class="mr-1 font-weight-bold body-1"
            style="color:#3C3D3D; font-size:12px !important"
          >
            <span class="" style="color:#E46A25;font-size:12px;"
              >Showing {{ fval }}-{{ sval }} </span
            >out of {{ totalLen }} records
          </v-text>
          <v-divider class="mx-2" vertical></v-divider>
          <v-pagination
            class="cust-bnkAcc"
            v-model="page"
            color="#152F38"
            flat
            :total-visible="9"
            @input="nextPageBtn(page)"
            :length="pageLength"
          ></v-pagination>
        </v-layout>
      </v-card>
    </div>

    <div v-if="typeOfPolicy === 'Retail'">
      <v-form ref="form">
        <v-layout>
          <v-flex xs3>
            <v-layout column class="cust_pr_Ms mx-2  ">
              <v-text class="font-weight-bold cstm-clr-pr-Ms">
                Member ID
              </v-text>

              <v-text-field
                solo
                label="Enter..."
                v-model="memberId"
                class="rounded-0"
                :rules="[alphaNumericMemberId(memberId)]"
                dense
              >
              </v-text-field>
            </v-layout>
          </v-flex>

          <v-flex xs3>
            <v-layout column class="cust_pr_Ms mx-2  ">
              <v-text class="font-weight-bold cstm-clr-pr-Ms">
                Member First Name
              </v-text>

              <v-text-field
                solo
                label="Enter..."
                v-model="memberFN"
                class="rounded-0"
                :rules="[alpha(memberFN)]"
                dense
              >
              </v-text-field>
            </v-layout>
          </v-flex>
          <v-flex xs3>
            <v-layout column class="cust_pr_Ms mx-2  ">
              <v-text class="font-weight-bold cstm-clr-pr-Ms">
                Member Last Name
              </v-text>

              <v-text-field
                solo
                label="Enter..."
                v-model="memberLN"
                class="rounded-0"
                :rules="[alpha(memberLN)]"
                dense
              >
              </v-text-field>
            </v-layout>
          </v-flex>
          <v-flex xs3>
            <v-layout column class="cust_pr_Ms mx-2  cust-rmvpd-pr-quick">
              <v-text class="font-weight-bold cstm-clr-pr-Ms">
                Date of Admission
              </v-text>

              <v-menu
                ref="calender"
                :close-on-content-click="false"
                v-model="dateOfAddmissionDate"
                :nudge-right="40"
                lazy
                transition="scale-transition"
                full-width
                max-width="290px"
                min-width="290px"
                class="rounded-0 cstm-clr-pr-Ms"
                dense
              >
                <template dense v-slot:activator="{ on, attrs }">
                  <v-text-field
                    dense
                    v-bind="attrs"
                    v-on="on"
                    label="dd/mm/yyyy"
                    :value="computeddateOfAddmissionDate"
                    solo
                    class="rounded-0"
                    readonly
                  >
                    <v-icon
                      slot="prepend-inner"
                      dense
                      style="background-color:#F7F7F7; height: 38px; width: 40px;"
                    >
                      date_range
                    </v-icon>
                  </v-text-field>
                </template>
                <v-date-picker
                  dense
                  v-model="dateOfAddmissionDateModel"
                  no-title
                  @input="dateOfAddmissionDate = false"
                ></v-date-picker>
              </v-menu>
            </v-layout>
          </v-flex>

          <v-flex xs3>
            <v-layout column class="cust_pr_Ms mx-2  ">
              <v-text class="font-weight-bold cstm-clr-pr-Ms">
                Mobile Number
              </v-text>

              <v-text-field
                solo
                label="Enter..."
                v-model="mobileNumber"
                class="rounded-0 comboxPadding"
                maxlength="10"
                :rules="[numeric(mobileNumber)]"
                dense
              >
                <v-icon
                  slot="prepend-inner"
                  dense
                  style="background-color:#F7F7F7; height: 38px; width: 40px;"
                >
                  call
                </v-icon>
              </v-text-field>
            </v-layout>
          </v-flex>
        </v-layout>

        <v-layout>
          <v-flex xs3>
            <v-layout column class="cust_pr_Ms mx-2  cust-rmvpd-pr-quick">
              <v-text class="font-weight-bold cstm-clr-pr-Ms">
                D.O.B
              </v-text>

              <v-menu
                ref="calender"
                :close-on-content-click="false"
                v-model="dobDate"
                :nudge-right="40"
                lazy
                transition="scale-transition"
                full-width
                max-width="290px"
                min-width="290px"
                class="rounded-0 cstm-clr-pr-Ms"
                dense
              >
                <template dense v-slot:activator="{ on, attrs }">
                  <v-text-field
                    dense
                    v-bind="attrs"
                    v-on="on"
                    label="dd/mm/yyyy"
                    :value="computeddobDate"
                    solo
                    class="rounded-0"
                    readonly
                  >
                    <v-icon
                      slot="prepend-inner"
                      dense
                      style="background-color:#F7F7F7; height: 37px; width: 40px;"
                    >
                      date_range
                    </v-icon>
                  </v-text-field>
                </template>
                <v-date-picker
                  dense
                  v-model="dobDateModel"
                  no-title
                  @input="dobDate = false"
                ></v-date-picker>
              </v-menu>
            </v-layout>
          </v-flex>

          <v-flex xs3>
            <v-layout column class="cust_pr_Ms mx-2  ">
              <v-text class="font-weight-bold cstm-clr-pr-Ms">
                Email
              </v-text>

              <v-text-field
                solo
                label="Enter..."
                v-model="email"
                class="rounded-0 comboxPadding"
                :rules="[emailRules(email)]"
                dense
              >
                <v-icon
                  slot="prepend-inner"
                  dense
                  style="background-color:#F7F7F7; height: 37px; width: 40px;"
                >
                  email
                </v-icon>
              </v-text-field>
            </v-layout>
          </v-flex>

          <v-flex xs3>
            <v-layout column class="cust_pr_Ms mx-2  ">
              <v-text class="font-weight-bold cstm-clr-pr-Ms">
                Policy Number
              </v-text>

              <v-text-field
                solo
                label="Enter..."
                v-model="policyNum"
                class="rounded-0"
                :rules="[alphaNumericPoilcy(policyNum)]"
                dense
              >
              </v-text-field>
            </v-layout>
          </v-flex>

          <v-flex xs3>
            <v-layout column class="cust_pr_Ms mx-2  ">
              <v-text class="font-weight-bold cstm-clr-pr-Ms">
                PAN No.
              </v-text>

              <v-text-field
                solo
                label="Enter..."
                v-model="panNo"
                class="rounded-0"
                :rules="[panRules(panNo)]"
                dense
              >
              </v-text-field>
            </v-layout>
          </v-flex>
          <v-flex xs3>
            <v-layout column class="cust_pr_Ms mx-2  ">
              <v-text class="font-weight-bold cstm-clr-pr-Ms">
                AADHAAR Number
              </v-text>

              <v-text-field
                solo
                label="Enter..."
                v-model="aadharNumber"
                class="rounded-0"
                maxlength="12"
                :rules="[numeric(aadharNumber)]"
                dense
              >
              </v-text-field>
            </v-layout>
          </v-flex>
        </v-layout>

        <v-card
          class=" pa-5 rounded-0"
          style="background-color: #F7F7F7; display:flex;justify-content:space-between;"
        >
          <v-layout style="flex:1" justify-end>
            <v-btn
              dark
              tile
              color="#E46A25"
              class="text-capitalize px-3 ml-3 testing"
              small
              @click="searchMember"
              ><v-icon dark small>search</v-icon>
              <v-divider vertical class="mx-2"></v-divider>Search</v-btn
            >
            <v-btn
              dark
              tile
              color="#E46A25"
              class="text-capitalize px-3 ml-3"
              small
              ><v-icon dark small>cancel</v-icon>
              <v-divider vertical class="mx-2"></v-divider>Cancel</v-btn
            >
          </v-layout>
        </v-card>
      </v-form>

      <v-card class="rounded-0" v-if="showTable">
        <v-data-table
          :headers="rheaders"
          :items="dataItems"
          dense
          class="mt-5"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          hide-default-footer
        >
          <template v-slot:item.select="{ item }">
            <div>
              <v-radio-group v-model="valueOfGRadio">
                <!-- value -->
                <v-radio
                  class="resizebtn"
                  color="#23b1a9"
                  :key="item.id"
                  :id="item.id"
                  :value="item.id"
                  @click="dialogOk(item)"
                ></v-radio>
              </v-radio-group>
              <!-- <input type="radio" id="one" :value="valueOfBtn" ref="valueOfBtnIs" @click="valueOfRadio" /> -->
            </div>
          </template>
        </v-data-table>
        <v-divider></v-divider>
        <v-layout row justify-end align-center class="mx-2">
          <v-text
            class="mr-1 font-weight-bold body-1"
            style="color:#3C3D3D; font-size:12px !important"
          >
            <span class="" style="color:#E46A25;font-size:12px;"
              >Showing {{ fval }}-{{ sval }} </span
            >out of {{ totalLen }} records
          </v-text>
          <v-divider class="mx-2" vertical></v-divider>
          <v-pagination
            class="cust-bnkAcc"
            v-model="page"
            color="#152F38"
            flat
            :total-visible="9"
            @input="nextPageBtn(page)"
            :length="pageLength"
          ></v-pagination>
        </v-layout>
      </v-card>
    </div>

    <template>
      <v-row>
        <v-dialog persistent v-model="dialog" max-width="400">
          <v-card>
            <v-alert class="heading" dense elevation="1" small>
              SELECTION
            </v-alert>

            <v-card-text style="background-color:#f7f7f7; ">
              Do you want to select the member for the claim ?
            </v-card-text>

            <v-card-actions style="justify-content: center;">
              <!-- <v-spacer></v-spacer> -->

              <v-btn
                dark
                tile
                color="#23B1A9"
                class="text-capitalize px-3 ml-3"
                small
                @click="dialogOk(1)"
              >
                <v-icon dark small>check_circle</v-icon>
                <v-divider vertical class="mx-2"></v-divider>
                Okay
              </v-btn>
              <v-btn
                dark
                tile
                color="#E46A25"
                class="text-capitalize px-3 ml-3"
                small
                @click="dialogCancel"
              >
                <v-icon dark small>cancel</v-icon>
                <v-divider vertical class="mx-2"></v-divider>
                Cancel
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
export default {
  created() {
    this.$store.commit("SAVE_PAGE_HEADER", "Member Search");
  },

  data() {
    return {
      params: "",
      headers: [
        {
          text: "Corporate Group Name",
          value: "corporateGroupName",
          sortable: false,
          divider: true,
          class: "hdr-txt-clrPer hdr-bg-clrPer ",
        },
        {
          text: "Employee ID",
          value: "employeeId",
          sortable: false,
          divider: true,
          class: "hdr-txt-clrPer hdr-bg-clrPer ",
        },
        {
          text: "Policy Number",
          value: "policyNumber",
          sortable: false,
          divider: true,
          class: "hdr-txt-clrPer hdr-bg-clrPer ",
        },
        {
          text: "Insured First Name",
          value: "firstName",
          sortable: false,
          divider: true,
          class: "hdr-txt-clrPer hdr-bg-clrPer ",
        },
        {
          text: "Insured Last Name",
          value: "lastName",
          sortable: false,
          divider: true,
          class: "hdr-txt-clrPer hdr-bg-clrPer ",
        },
        {
          text: "Member First Name",
          value: "firstName",
          sortable: false,
          divider: true,
          class: "hdr-txt-clrPer hdr-bg-clrPer ",
        },
        {
          text: "Member Last Name",
          value: "lastName",
          sortable: false,
          divider: true,
          class: "hdr-txt-clrPer hdr-bg-clrPer ",
        },
        {
          text: "Member ID",
          value: "memberId",
          sortable: false,
          divider: true,
          class: "hdr-txt-clrPer hdr-bg-clrPer ",
        },
        {
          text: "Relationship to primary insured",
          value: "relationshipToIsured",
          sortable: false,
          divider: true,
          class: "hdr-txt-clrPer hdr-bg-clrPer ",
        },
        {
          text: "Age",
          value: "age",
          sortable: false,
          divider: true,
          class: "hdr-txt-clrPer hdr-bg-clrPer ",
        },
        {
          text: "Gender",
          value: "gender",
          sortable: false,
          divider: true,
          class: "hdr-txt-clrPer hdr-bg-clrPer ",
        },
        {
          text: "Select",
          value: "select",
          sortable: false,
          divider: true,
          class: "hdr-txt-clrPer hdr-bg-clrPer ",
        },
      ],

      rheaders: [
        {
          text: "Policy Number",
          value: "policyNumber",
          sortable: false,
          divider: true,
          class: "hdr-txt-clrPer hdr-bg-clrPer ",
        },
        {
          text: "Policy Status",
          value: "corporateGroupName",
          sortable: false,
          divider: true,
          class: "hdr-txt-clrPer hdr-bg-clrPer ",
        },
        {
          text: "Insured First Name",
          value: "firstName",
          sortable: false,
          divider: true,
          class: "hdr-txt-clrPer hdr-bg-clrPer ",
        },
        {
          text: "Insured Last Name",
          value: "lastName",
          sortable: false,
          divider: true,
          class: "hdr-txt-clrPer hdr-bg-clrPer ",
        },
        {
          text: "Member First Name",
          value: "firstName",
          sortable: false,
          divider: true,
          class: "hdr-txt-clrPer hdr-bg-clrPer ",
        },
        {
          text: "Member Last Name",
          value: "lastName",
          sortable: false,
          divider: true,
          class: "hdr-txt-clrPer hdr-bg-clrPer ",
        },
        {
          text: "Relationship to primary insured",
          value: "relationshipToIsured",
          sortable: false,
          divider: true,
          class: "hdr-txt-clrPer hdr-bg-clrPer ",
        },
        {
          text: "Member ID",
          value: "memberId",
          sortable: false,
          divider: true,
          class: "hdr-txt-clrPer hdr-bg-clrPer ",
        },
        {
          text: "Sum Insured",
          value: "sumInsured",
          sortable: false,
          divider: true,
          class: "hdr-txt-clrPer hdr-bg-clrPer ",
        },
        {
          text: "Age",
          value: "age",
          sortable: false,
          divider: true,
          class: "hdr-txt-clrPer hdr-bg-clrPer ",
        },
        {
          text: "Gender",
          value: "gender",
          sortable: false,
          divider: true,
          class: "hdr-txt-clrPer hdr-bg-clrPer ",
        },
        {
          text: "Select",
          value: "select",
          sortable: false,
          divider: true,
          class: "hdr-txt-clrPer hdr-bg-clrPer ",
        },
      ],
      dataItems: [],
      typeOfPolicy: "Group",
      memberId: "",
      memberFN: "",
      memberLN: "",
      corporateGN: "",
      policyNum: "",
      employeeId: "",
      certificateNum: "",
      proposerNum: "",
      dobDate: false,
      dobDateModel: "",
      dateOfAddmissionDate: false,
      dateOfAddmissionDateModel: "",

      mobileNumber: "",
      panNo: "",
      aadharNumber: "",
      email: "",

      valueOfBtn: false,
      valueOfGRadio: "",

      // for pagination
      fval: 0,
      sval: 0,
      totalLen: "",
      itemsPerPage: 10,
      page: 1,
      pageLength: "",
      lastPage: false,

      dialog: false,
      itemK: "",

      showTable: false,
    };
  },
  // for claim registartion purpose
  //redirecting for reimbursement and cashless
  mounted() {
    this.params = this.$route.params.page;
    console.log("this is from params", this.params);
  },

  computed: {
    computeddobDate() {
      return this.dobDateModel
        ? moment(this.dobDateModel).format("DD-MM-YYYY")
        : "";
    },
    computeddateOfAddmissionDate() {
      return this.dateOfAddmissionDateModel
        ? moment(this.dateOfAddmissionDateModel).format("DD-MM-YYYY")
        : "";
    },
  },

  methods: {
    typedOfPolicySelected() {
      console.log("type of policy", this.typeOfPolicy);
      // if(this.typeOfPolicy == 'Group'){
      this.memberId = "";
      this.memberFN = "";
      this.memberLN = "";
      this.dateOfAddmissionDateModel = "";
      this.mobileNumber = "";
      this.dobDateModel = "";
      this.email = "";
      this.policyNum = "";
      this.panNumber = "";
      this.aadharNumber = "";
      this.employeeId = "";
      this.certificateNum = "";
      this.proposerNum = "";
      this.corporateGN = "";
      // }if
    },

    alphaNumericPoilcy(v) {
      if (v.length > 0) {
        return (v) => /^[a-zA-Z0-9 ]+$/.test(v) || "Enter valid policy number";
      }
    },

    alphaNumericMemberId(v) {
      if (v.length > 0) {
        return (v) => /^[a-zA-Z0-9 ]+$/.test(v) || "Enter valid member ID";
      }
    },

    alphaNumericCert(v) {
      if (v.length > 0) {
        return (v) =>
          /^[a-zA-Z0-9 ]+$/.test(v) || "Enter valid certificate number";
      }
    },

    alphaNumeric(v) {
      if (v.length > 0) {
        return (v) => /^[a-zA-Z0-9 ]+$/.test(v) || "Enter valid EmployeeID";
      }
    },

    alpha(v) {
      if (v.length > 0) {
        return (v) => /^[a-zA-Z]+$/.test(v) || "Only Alphabets are allowed";
      }
    },

    alphaGN(v) {
      if (v.length > 0) {
        return (v) => /^[a-zA-Z ]+$/.test(v) || "Only Alphabets are allowed";
      }
    },

    numeric(v) {
      if (v.length > 0) {
        return (v) => /^[0-9]+$/.test(v) || "Only Numbers are allowed";
      }
    },

    panRules(v) {
      if (v.length > 0) {
        return (v) =>
          /([A-Z]){5}([0-9]){4}([A-Z]){1}$/.test(v) ||
          "Please Enter Valid PanId";
      }
    },

    emailRules(v) {
      if (v.length > 3) {
        return (v) => /\S+@\S+\.\S+/.test(v) || "Please Enter Valid Email Id";
      }
    },

    nextPageBtn(page) {
      if (this.pageLength === page) {
        this.lastPage = true;
        console.log("this is last page", page);
      } else {
        this.lastPage = false;
        console.log("this is not last page", page);
      }
      this.searchResult(page);
    },

    // getMembersData(){
    //     this.GET(8,'member-details/get-member-list?policyType='+this.typeOfPolicy,(res,error) => {
    //             if(!error){
    //                 console.log("response from member search",res);
    //             }else{
    //                 console.log("their was error",error.response.data);
    //             }
    //     })
    // },

    dialogOk(item) {
      this.dialog = true;

      if (item === 1) {
        console.log("this is from ok");

        this.GET(
          8,
          "/member-details/get-member-details-by-id/" + this.smallData.itemId,
          (res, error) => {
            if (!error) {
              console.log("theiir is error", res);
              //for claimregsitration pages
              if (this.params !== "") {
                if (this.params == "rmbClaim") {
                  console.log("inside rmb");
                  this.$router.push({
                    name: "ClaimsMainScreen",
                    params: { type: "reimbursementClaim", membSearData: res },
                  });
                }
                if (this.params == "cashlsClaim") {
                  console.log("inside cashless");
                  this.$router.push({
                    name: "ClaimsMainScreen",
                    params: { type: "cashlessClaim", membSearData: res },
                  });
                }
              } else {
                if (this.$route.params.fromEdit == true) {
                  console.log("toEdit true");
                  this.$router.push({
                    name: "NewClaimIntimation",
                    params: { membSearData: res, haveDta: true, toEdit: true },
                  });
                } else {
                  console.log("toEdit false");
                  this.$router.push({
                    name: "NewClaimIntimation",
                    params: { membSearData: res, haveDta: true, toEdit: false },
                  });
                }
              }
              // this.$router.push(
              //                     {
              //                         name:'NewClaimIntimation',
              //                         params: {
              //                                 membSearData : res,
              //                                 haveDta: true

              //                             }
              //                     })
            } else {
              console.log(error);
            }
          }
        );

        // this.GET(8,'member-details/get-member-details?memberId='+this.smallData.memberId+'&proposalNo='+this.smallData.proposalNo,(res,error) => {
        //     if(!error){

        //         console.log("this is from member details",res);
        //          this.$router.push(
        //                             {
        //                                 name:'NewClaimIntimation',
        //                                 params: {
        //                                         membSearData : res
        //                                     }
        //                             })
        //     }else{
        //         console.log("this is error",error.response.data.msg);
        //     }
        // })
        this.dialog = false;
      } else {
        console.log("this is the dialog box content", item.id, item);
        this.smallData = {
          itemId: item.id,
          proposalNo: item.proposalNo,
        };
      }
    },

    dialogCancel() {
      this.dialog = false;
    },

    searchMember() {
      console.log("search button working");
      this.$refs.form.validate();
      if (!this.$refs.form.validate()) {
        this.showToast("Please fill all fields", this.TOST().WARNING);
        console.log(
          "%c It is a not a valid Form",
          "color:red",
          this.$refs.form
        );
      } else {
        console.log("valid form");
        if (
          this.memberId == "" &&
          this.memberFN == "" &&
          this.memberLN == "" &&
          this.corporateGN == "" &&
          this.policyNum == "" &&
          this.employeeId == "" &&
          this.certificateNum == "" &&
          this.proposerNum == "" &&
          this.dobDateModel == "" &&
          this.dateOfAddmissionDateModel == "" &&
          this.mobileNumber == "" &&
          this.panNo == "" &&
          this.aadharNumber == "" &&
          this.email == ""
        ) {
          console.log("form is empty");
          this.showToast("Enter search criteria", this.TOST().WARNING);
        } else {
          // if(this.policyNum == '' && this.corporateGN == ''){
          //     this.showToast('Enter corporate group name or policy number',this.TOST().ERROR)
          //     return
          // }

          this.searchResult(this.page);
        }
      }
    },

    searchResult(page) {
      console.log("searching for member in memberSearchApi");
      this.GET(
        8,
        "member-details/get-member-list?perPage=10&pageNo=" +
          page +
          "&policyType=" +
          this.typeOfPolicy +
          "&policyNumber=" +
          this.policyNum +
          "&corporateGroupName=" +
          this.corporateGN +
          "&memberId=" +
          this.memberId +
          "&firstName=" +
          this.memberFN +
          "&lastName=" +
          this.memberLN +
          "&certificateNumber=" +
          this.certificateNum +
          "&dateOfAdmission=" +
          this.dateOfAddmissionDateModel +
          "&proposerMobileNumber=" +
          this.proposerNum +
          "&dateOfBirth=" +
          this.dobDateModel +
          "&employeeId=" +
          this.employeeId +
          "&aadharNumber=" +
          this.aadharNumber +
          "&email=" +
          this.email +
          "&mobileNumber=" +
          this.mobileNumber +
          "&panNumber=" +
          this.panNo,
        (res, error) => {
          if (!error) {
            console.log(res);
            console.log("response from member search", res.data.data);

            this.pageLength = res.data.data.total_pages;
            this.totalLen = res.data.data.total;
            var temp = this.page * 10;
            this.fval = temp - 9;

            if (this.lastPage) {
              this.sval = this.totalLen;
              console.log("value of sval is fo", this.sval);
            } else {
              if (this.totalLen < 10) {
                this.sval = this.totalLen;
                console.log(
                  "this are the pageLength for searchTable",
                  this.totalLen
                );
              } else {
                this.sval = temp;
                console.log("value of sval is", this.sval);
              }
            }
            let memberData = res.data.data.data;
            this.dataItems = memberData.map((member) => {
              return {
                aadharNumber: member.aadharNumber,
                age: member.age,
                corporateGroupName: member.corporateGroupName,
                dateOfAdmission: member.dateOfAdmission,
                dateOfBirth: member.dateOfBirth,
                email: member.email,
                employeeId: member.employeeId,
                firstName: member.firstName,
                gender: member.gender,
                lastName: member.lastName,
                memberId: member.memberId,
                mobileNumber: member.mobileNumber,
                panNumber: member.panNumber,
                policyNumber: member.policyNumber,
                policyType: member.policyType,
                proposalNo: member.proposalNo,
                proposerMobileNumber: member.proposerMobileNumber,
                relationshipToIsured: member.relationshipToIsured,
                sumInsured: "20000",
                id: member._id,
              };
            });
            this.showTable = true;
          } else {
            console.log("their was error");
            this.showToast(error.response.data.msg, this.TOST().ERROR);
          }
        }
      );
    },
  },
};
</script>

<style scoped>
.cust-bnkAcc .v-pagination__navigation {
  box-shadow: unset !important;
  border-radius: unset !important;
  background-color: #ffffff !important;
  border: 1px solid #dae1e6 !important;
}
.cust-bnkAcc .v-pagination__navigation--disabled {
  background-color: #dae1e6 !important;
}
.cust-bnkAcc .v-pagination__item {
  box-shadow: unset !important;
  min-width: 2rem !important;
  height: 2rem !important;
  border-radius: unset !important;
  border: 1px solid #dae1e6 !important;
}

.srWidth {
  width: 70px;
}

.hdr-txt-clrPer {
  color: white !important;
  font-weight: bold;
  /* padding:0px!important;
    padding: 10px !important;
    padding-left: 14px !important; */
}

.hdr-bg-clrPer {
  background-color: #23b1a9;
}

.policyType {
  background-color: #01bbd9;
  height: 35px;
  padding: 5px;
}
.policyType p {
  margin-top: auto;
  margin-left: 20px;
  color: white;
}

.cust_pr_Ms .v-input {
  font-size: 13px !important;
}

.resizebtn >>> i {
  font-size: 15px !important;
  margin-left: 10px;
}

.resizebtn >>> label {
  font-size: 14px;
  margin-left: 10px;
}

.cust-rmvpd-pr-ms
  .v-text-field.v-text-field--enclosed
  > .v-input__control
  > .v-input__slot {
  padding: 0px !important;
}

.cust-rmvpd-psins-Ms
  .v-text-field.v-text-field--enclosed
  > .v-input__control
  > .v-input__slot {
  padding-right: 0px !important;
}

.cust-rmvpd-pr-quick
  .v-text-field.v-text-field--enclosed
  > .v-input__control
  > .v-input__slot {
  padding: 0px !important;
}

.cstm-clr-pr-Ms {
  color: #646666 !important;
  margin-bottom: 5px !important;
  font-size: 13px !important;
}

.comboxPadding >>> .v-input__slot {
  padding-left: 0 !important;
}

.cstm-clr-pr-Ms + .v-text-field >>> label {
  font-size: 13px;
}

.resizebtn >>> .v-input--selection-controls__ripple {
  border-radius: 50%;
  cursor: pointer;
  height: 24px;
  position: absolute;
  transition: inherit;
  width: 24px;
  left: -2px;
  top: calc(50% - 18px);
  margin: 7px;
}

.heading {
  background-color: #f7f7f7;
  margin-bottom: 0px;
  padding-top: 4px;
  padding-bottom: 4px;
  font-weight: bold;
  font-size: 14px;
}
</style>
