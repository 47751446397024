<template>
    <v-app>
        <!-- <v-card style="width:1400px;height:600px" >
            <v-layout align-center justify-center style="width:1400px;height:600px">
                <p class="font-weight-bold">Coming Soon</p>
            </v-layout>
        </v-card> -->
        <v-card class="mb-3 pa-5 pb-6" tile>
            <v-flex xs3 >
                <v-layout column class="cust_pln_cd">
                        <p class="font-weight-bold cstm-clr-pln-cd">
                        Plan
                        </p>
                        <v-select
                            :items="planItems"
                            label="Select"
                            v-model="plan"
                            item-text="planName"
                            item-value="_id"
                            hide-details
                            solo
                            dense
                            disabled
                            class="rounded-0">
                        </v-select>
                </v-layout>
            </v-flex>
        </v-card>

        <!-- <v-expansion-panels class="" tile accordion dense :disabled="inputdsbld"> -->
            <div class="panelis" v-if="showIPHAccor == true" dense>
                <div @click="currentis(nextdrive[0])" expand-icon="mdi-menu-down" class="font-weight-bold panhead" >
  
                    In-patient hospitalization  <span> <v-icon  :style="{border: datais[nextdrive[0].shown == 'y' ? '5px red solid':'none' ] }"  >arrow_drop_down</v-icon> </span> 
                </div>
                <div  class="pa-2"    v-if="datais[nextdrive[0].shown]" >
                    <v-divider></v-divider>
                    <v-form v-model="inPtntHzptnvalid" ref="inPtntHzptnForm">
                        <v-layout class="mt-3" wrap style="align-items:flex-end">
                            <v-flex xs3 >
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Coverage Alternate Name</p>
                                    <v-text-field
                                        :rules="alphaRule"
                                        solo
                                        label="Enter..."
                                        v-model="IPHCoverAltName"
                                        class="rounded-0"
                                        :maxlength="max200"
                                        >
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Coverage Type
                                    </p>
                                    <v-select
                                        :items="cvrgTypItems"
                                        label="Select"
                                        v-model="inPtntHzptnCoverageType"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]"
                                    >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Coverage Basis
                                    </p>
                                    <v-select
                                        :items="cvrgBasisItems"
                                        label="Select"
                                        v-model="inPtntHzptnCoverageBasis"
                                        @change="chngInPtntHzptnCb(1)"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]"
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            
                            <v-flex xs3 v-if="shwInPtntHzptnSlb===true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Sub Limit Basis
                                    </p>
                                    <v-select
                                        :items="IPHSbLmtBasisItems"
                                        label="Select"
                                        v-model="InPtntHzptnSubLimitBasis"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]"
                                        multiple
                                        @change="chngInPtntHzptnSubLmtBasis(InPtntHzptnSubLimitBasis)"
                                        >
                                        <template v-slot:selection="{ item, index }">
                                    
                                        <span v-if="index === 0" >{{ item }}</span>
                                    
                                        <span
                                        v-if="index === 1"
                                        class="grey--text caption"
                                        >
                                        (+{{ InPtntHzptnSubLimitBasis.length - 1 }})
                                        </span>
                                    </template>
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            
                            <v-flex xs3 v-if="showIPHPercentSi == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">% of SI</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="IPHPercentSI"
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),numberRule,decimalRule()]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="showIPHFlatAmnt == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Flat Amount</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="IPHFlatAmountTxtField"
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),alphaNumeric()]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="showIPHPrvPercentSi == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">% of previous yr SI</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="IPHPrvPercentSI"
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),numberRule,decimalRule()]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="showIPHPercClaimAmt == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">% of Claim Amount</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="IPHPercentClaimAmt"
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),numberRule,decimalRule()]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="showIPHOpdSi == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">% of OPD SI</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="IPHOpdSi"
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),numberRule,decimalRule()]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="showIPHAdSi == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">% of AD SI</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="IPHAdSi"
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),numberRule,decimalRule()]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="IPHshowAppli==true">
                                <v-layout column class="cust_pln_cd mx-2">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Applicable Claim Pay-Out
                                    </p>
                                    <v-select
                                        :items="selectApplicableClaimPayOut"
                                        v-model="IPHapplicableClaimPayOut"
                                        label="Select"
                                        
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]"
                                    ></v-select>
                                </v-layout>
                            </v-flex>
                            
                            <v-flex xs3 v-if="shwInPtntHzptnSlb===true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Claim Payment Basis
                                    </p>
                                    <v-select
                                        :items="ClmpymntBasisItems"
                                        label="Select"
                                        v-model="InPtntHzptnClaimPaymentBasis"
                                        solo
                                        @change="chngInPtntHzptnClaimPaymentBasis(InPtntHzptnClaimPaymentBasis)"
                                        class="rounded-0"
                                        :rules="[required()]"
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="showIPHCovrageApplicDur == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Coverage applicable duration (No of years)
                                    </p>
                                    <v-select
                                        :items="hacCadItms"
                                        label="Select"
                                        v-model="IPHCovrageApplicDur"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]"
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Waiting Period</p>
                                    <v-layout>
                                        <v-select
                                            :items="['Days','Months']"
                                            label="Select"
                                            v-model="InPtntHzptnWaitingPeriodDrop"
                                            solo
                                            style="width: 145px"
                                            class="rounded-0"
                                            :rules="[required()]"
                                            >
                                        </v-select>
                                        <v-text-field
                                            label="Enter..."
                                            v-model="InPtntHzptnWaitingPeriod"
                                            solo
                                            class="rounded-0"
                                            :maxlength="max10"
                                            :rules="[Numeric2]"
                                            >
                                        </v-text-field>
                                    </v-layout>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                       Minimum Hospitalization Duration for Eligible Claim(No. of hours)
                                    </p>
                                    <v-text-field
                                        label="Enter..."
                                        v-model="InPtntHzptnMinHospDura"
                                        solo
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[Numeric2]"
                                        >
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                       Co-pay applicable
                                    </p>
                                    <v-select
                                        :items="inPtntHzptnCoPayapplicableItems"
                                        label="Select"
                                        v-model="inPtntHzptnCoPayapplicable"
                                        @change="chngInPtntHzptnCopyAplcbl()"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]"
                                    >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            
                             <v-flex xs3 v-if="shwInPtntHzptnCopayTyp===true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                       Co-Pay Type
                                    </p>
                                    <v-select
                                        :items="inPtntHzptnCoPayTypeItems"
                                        label="Select"
                                        v-model="inPtntHzptnCoPayType"
                                        @change="chngInPtntHzptnCoPayPrcnt()"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]"
                                    >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="shwInPtntHzptnCoPayPrcnt == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                       Co-pay (%)
                                    </p>
                                    <v-text-field
                                        label="Enter..."
                                        v-model="InPtntHzptnCoPayPrcnt"
                                        solo
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[v => v <= 100 || 'Values between 0 and 100 are accepted',Numeric2,required()]"
                                        >
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            
                        </v-layout>
                        
                        <v-divider></v-divider>
                        <div style="display:flex" class="mx-2">
                            <v-flex xs6 v-if="showCoPayPercRoom == true" style="flex-direction:column" class="mr-4">
                                <span class="font-weight-bold mt-3 cstm-clr-pln-cd">Co-Pay Grid</span>
                                <v-divider class="mb-4"></v-divider>
                                <v-card tile >
                                    <div style="" class="gridheader">
                                        <div class="font-weight-bold ml-2" style="flex:1; border-right:1px solid #C1C8CC; color:white; font-size: 13px !important;"><p class="my-2 pa-0 ma-0"> Room Type</p></div>
                                        <div class="font-weight-bold mx-2" style="flex:1;font-size: 13px !important;color:white"> <p class="my-2 pa-0 ma-0">Co-pay (%)</p></div>
                                    </div>
                                    <div style="display:flex;">
                                        <div class="font-weight-bold ml-2" style="flex:1; border-right:1px solid #C1C8CC; font-size: 13px !important;"><p class="my-4 pa-0 ma-0"> Shared Room</p></div>
                                        <div style="flex:1" class="mx-2 cust_pln_cd">
                                            <v-text-field 
                                                solo
                                                placeholder="Enter..."
                                                :maxlength="max10"
                                                v-model="InPtntHzptnCoPayPrcntSr"
                                                :rules="[v => v <= 100 || 'Values between 0 and 100 are accepted',Numeric2,required()]"
                                                class="rounded-0 ">
                                            </v-text-field>
                                        </div>
                                    </div>
                                    
                                    <v-divider></v-divider>
                                    <div style="display:flex">
                                        <div class="font-weight-bold ml-2" style="flex:1; border-right:1px solid #C1C8CC; font-size: 13px !important;"><p class="my-4 pa-0 ma-0">No Restriction</p></div>
                                        <div style="flex:1" class="mx-2 cust_pln_cd">
                                            <v-text-field 
                                                solo
                                                placeholder="Enter..."
                                                :maxlength="max10"
                                                v-model="InPtntHzptnCoPayPrcntNr"
                                                :rules="[v => v <= 100 || 'Values between 0 and 100 are accepted',Numeric2,required()]"
                                                class="rounded-0">
                                            </v-text-field>
                                        </div>
                                    </div>
                                </v-card>
                            </v-flex>
                            <!-- <v-divider v-if="showCoPayPercRoom == true" vertical class="ml-3 mr-3 mt-3"></v-divider>
                            <div style="display:flex; flex-direction:column;flex:1" class="mr-4">
                                <span class="font-weight-bold mt-3 cstm-clr-pln-cd" >Coverage details to be captured same as in-patient cover</span>
                                <div v-for="(hosp, index) in IPCoverCheckArray" :key="index" style="" class="mt-4">
                                    <v-checkbox 
                                        v-if="hosp.text !== null"
                                        color="#23B1A9"  
                                        v-model="IPCoverCheck"  
                                        @change="changeIPCoverage(IPCoverCheck)" 
                                        hide-details
                                        :disabled='hosp.text ? false : true'
                                        :label="hosp.text"
                                        class="ma-0 pa-0 "
                                        :value="hosp.value">
                                        <span slot="label" class="lbl-bgclr font-weight-bold " style="font-size: 11px;word-wrap: break-word; ">{{hosp.text}}</span>
                                    </v-checkbox>
                                </div>
                            </div> -->
                            <!-- <v-divider vertical class="ml-3 mr-3 mt-3"></v-divider>
                            <div style="display:flex; flex-direction:column;flex:1" class="mt-4 pl-4">
                                <div v-for="(data, index) in IPCoverCheck" :key="index">
                                    <v-flex v-if="data == 'BSC'">
                                        <v-layout column class="cust_pln_cd mx-2 ">
                                            <p class="font-weight-bold cstm-clr-pln-cd">
                                                Min age of Member
                                            </p>
                                            <v-text-field
                                                label="Enter..."
                                                v-model="InPtntHzptnMnAgNo"
                                                solo
                                                class="rounded-0"
                                                :maxlength="max05"
                                                :rules="[Numeric2]"
                                                >
                                            </v-text-field>
                                        </v-layout>
                                    </v-flex>
                                    <v-flex v-if="data == 'DT'">
                                        <v-layout column class="cust_pln_cd mx-2 ">
                                            <p class="font-weight-bold cstm-clr-pln-cd">
                                                Min Treatment Duration (No. of Days)
                                            </p>
                                            <v-text-field
                                                label="Enter..."
                                                v-model="InPtntHzptnMnTrtmntDrn"
                                                solo
                                                class="rounded-0"
                                                :maxlength="max05"
                                                :rules="[Numeric2]"
                                                >
                                            </v-text-field>
                                        </v-layout>
                                    </v-flex>
                                </div>
                            </div> -->
                            <!-- Please Dont Delete this Line -->
                            <!-- <div v-if="showCoPayPercRoom == false" style="display:flex; flex-direction:column;flex:1" class="mr-4"></div> -->
                        </div>
                        <!-- <v-divider></v-divider> -->
                        <!-- NEW FIELDS ADDED IPH-->
                        <v-layout class="mt-3" wrap style="align-items:flex-end">
                            <!-- <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Min Age of Member</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="IPHMinAgeMemb"
                                        class="rounded-0"
                                        :maxlength="max05"
                                        :rules="[required(),Numeric2]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Min Treatment Duration(No. of Days)</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="IPHMinTreatDura"
                                        class="rounded-0"
                                        :maxlength="max05"
                                        :rules="[required(),Numeric2]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex> -->
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">IRDA UIN Number</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="IPHIrdaUINNo"
                                        class="rounded-0"
                                        :maxlength="max20"
                                        :rules="spclCharNew"
                                        >
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">External Reference Number</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="IPHExtRefNo"
                                        class="rounded-0"
                                        :maxlength="max30"
                                        :rules="spclCharNew"
                                        >
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Conditions</p>
                                    <v-select
                                        :items="prodConditionArray"
                                        label="Select"
                                        v-model="IPHCondField"
                                        multiple
                                        solo
                                        class="rounded-0"
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">GC Coverage</p>
                                    <v-select
                                        :items="prodGCCoverArray"
                                        label="Select"
                                        v-model="IPHGcCover"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]">
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Remarks</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="IPHRemarks"
                                        class="rounded-0"
                                        :maxlength="max40"
                                        >
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Exclusions</p>
                                    <v-select
                                        :items="prodExcluArray"
                                        :maxlength="max30"
                                        label="Select"
                                        v-model="IPHExclus"
                                        solo
                                        class="rounded-0"
                                        multiple
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                        </v-layout>
                        <v-divider class="mt-4"></v-divider>
                        <v-flex xs3 >
                            <v-layout column class="mx-2 cust_pln_cd" justify-center >
                                <v-text class="font-weight-bold cstm-clr-pln-cd" style="color:#FFFFFF" > </v-text>
                                <div style="background-color: #f7f7f7;border: 1px solid #c1c8cc;" class="pa-2">
                                    <v-checkbox class="ma-0 pa-0" v-model="InPtntHzptnSelectBox" @change="IPHRelCheckSelected()" hide-details >
                                        <span slot="label" class="font-weight-bold text-truncate " style="font-size: 13px;"> Separate Relationship & Dependent Info</span>
                                    </v-checkbox>
                                </div>
                            </v-layout>
                        </v-flex>
                        <!-- <v-divider class="mb-4"></v-divider> -->
                        
                        <div v-if="showIPHRelSection == true">
                            <relationdependent 
                                ref="IPHRel" 
                                @save="saveIPHRel" 
                                :redpndntinfo="{
                                    cirelDataemty:IPHrelCoverData.realdata,
                                    ciRelnshipCoveredEmpty:IPHrelCoverData.dropdowndata,
                                    cisyncArryEmpty:IPHrelCoverData.syncarr,
                                    ciactiveonlyempty:IPHrelCoverData.ddselectedvalues
                                }" 
                                v-if="showIPHRelData == true">
                            </relationdependent>
                        </div>

                        <v-layout style="" justify-end class="my-4">
                        <v-btn tile dark @click="clearIPH()" style="font-size:12px;background-color:#e46a25" class="text-capitalize px-3 mx-2" small><v-icon dark small>close</v-icon> <v-divider vertical class="mx-2"></v-divider> Clear</v-btn>
                        <v-btn tile @click="submitIPH()" dark style="font-size:12px;background-color:#23B1A9" class="text-capitalize px-3 mx-2" small><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider> Save</v-btn>
                            <v-btn @click="nxt(nextdrive[0])" tile  dark style="font-size:12px;background-color:#152F38" 
                            class="text-capitalize px-3 mx-2" small><v-icon dark small>arrow_forward</v-icon>
                            <v-divider vertical class="mx-2"></v-divider>Next</v-btn>
                        </v-layout>
                    </v-form>

                </div>
            </div>
            <!-- ashish start -->
            <!-- 0.mat -->
            <div class="panelis" v-if="showMCAccor == true" dense>
                <div @click="currentis(nextdrive[1])" expand-icon="mdi-menu-down" class="font-weight-bold panhead" >
                    Maternity Coverage   <span> <v-icon  :style="{border: datais[nextdrive[1].shown == 'y' ? '5px red solid':'none' ] }"  >arrow_drop_down</v-icon> </span> 
                </div>
                
                <div class="pa-2" v-if="datais[nextdrive[1].shown]"  >
                    <v-divider></v-divider>
                    <v-form v-model="matvalid" ref="matForm">  
                        <v-layout class="mt-3" style="align-items:flex-end" wrap>
                            <v-flex xs3 >
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Coverage Alternate Name</p>
                                    <v-text-field
                                        :rules="alphaRule"
                                        solo
                                        label="Enter..."
                                        v-model="MCCoverAltName"
                                        class="rounded-0"
                                        :maxlength="max200"
                                        >
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 >
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Coverage Type
                                    </p>
                                    <v-select
                                    :items="cvrgTypItems"
                                    v-model="matcoverageType"
                                    label="Select"
                                    solo
                                    :rules="[required()]"
                                    
                                    class="rounded-0"
                                ></v-select>
                                </v-layout>
                            </v-flex>

                            <v-flex xs3 >
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Coverage Basis
                                    </p>
                                    <v-select
                                        :items="cvrgBasisItems"
                                        v-model="matcoverageBasis"
                                        label="Select"
                                        solo
                                        :rules="[required()]"
                                        class="rounded-0"
                                        @change="matchangeOnover(1)"
                                    ></v-select>
                                </v-layout>
                            </v-flex>
                            
                            <v-flex xs3 v-if="matsubBasisshow==true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Sub Limit Basis
                                    </p>
                                    <v-select
                                        :items="cvrgSbLmtBasisItems"
                                        v-model="matsubLimitBasis"
                                        label="Select"
                                        solo
                                        multiple
                                        @change="matflatchange(matsubLimitBasis)"
                                        :rules="[required()]"
                                        class="rounded-0"
                                    >
                                    <template v-slot:selection="{ item, index }">
                                   
                                    <span v-if="index === 0" >{{ item }}</span>
                                   
                                    <span
                                    v-if="index === 1"
                                    class="grey--text caption"
                                    >
                                    (+{{ matsubLimitBasis.length - 1 }})
                                    </span>
                                </template>
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            
                            <v-flex xs3 v-if="showMCPercentSI == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">% of SI</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="MCPercentSI"
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),numberRule,decimalRule()]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="showMCFlatAmnt == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Flat Amount</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="MCFlatAmountTxtField"
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),Numeric2]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="showMCPrvPercentSI == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">% of previous yr SI</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="MCPrvPercentSI"
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),Numeric2]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="showMCPercClaimAmt == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">% of Claim Amount</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="MCPercentClaimAmt"
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),Numeric2]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="showMCOpdSi == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">% of OPD SI</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="MCOpdSi"
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),numberRule,decimalRule()]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="showMCAdSi == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">% of AD SI</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="MCAdSi"
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),numberRule,decimalRule()]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>

                            <v-flex xs3 v-if="showMCApplicClaimPay == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Applicable Claim Pay-Out</p>
                                    <v-select
                                        :items="hacAplcblClmPyotItms"
                                        label="Select"
                                        v-model="MCApplicClaimPayOut"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]"
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex>

                            <v-flex xs3 v-if="matsubBasisshow==true">
                                <v-layout column class="cust_pln_cd mx-2">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Claim Payment Basis
                                    </p>
                                    <v-select
                                        :items="ClmpymntBasisItems"
                                        v-model="matclaimPaymentBasis"
                                        label="Select"
                                        @change="changeMCClaimPayBas(matclaimPaymentBasis)"
                                        solo
                                        :rules="[required()]"
                                        class="rounded-0"
                                    >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            
                            <v-flex xs3 v-if="showMCCovrageApplicDur == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Coverage applicable duration (No of years)
                                    </p>
                                    <v-select
                                        :items="hacCadItms"
                                        label="Select"
                                        v-model="MCCovrageApplicDur"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]"
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Waiting Period</p>
                                    <v-layout>
                                        <v-select
                                            :items="['Days','Months']"
                                            label="Select"
                                            v-model="MCwaitingPeriodDrop"
                                            solo
                                            style="width: 145px"
                                            class="rounded-0"
                                            :rules="[required()]"
                                            >
                                        </v-select>
                                        <v-text-field
                                            label="Enter..."
                                            v-model="matwaitingPeriod"
                                            solo
                                            class="rounded-0"
                                            :maxlength="max10"
                                            :rules="[Numeric2]"
                                            >
                                        </v-text-field>
                                    </v-layout>
                                </v-layout>
                            </v-flex>

                            <v-flex xs3 >
                                <v-layout column class="cust_pln_cd mx-2">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Limit on number of Deliveries
                                    </p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="matlimitdel"
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[Numeric2]"
                                    >
                                    </v-text-field>
                                </v-layout>
                            </v-flex>

                            <v-flex xs3 >
                                <v-layout column class="cust_pln_cd mx-2">
                                    <p class="font-weight-bold cstm-clr-pln-cd ">
                                        Minimum Hospitalization Duration for Eligible Claim(No. of Hours)
                                    </p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="matminHosDuration"
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[Numeric2]"
                                    >
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                        
                        </v-layout>

                        <v-divider></v-divider>
                        <v-layout class="my-3" style="align-items:flex-start" wrap>
                            <v-flex xs3  class="" v-if="matflatshow==true">
                                <v-layout column class="cust_pln_cd mx-2 my-2">
                                    <v-text class="font-weight-bold cstm-clr-pln-cd">Does flat amount vary based on Gender</v-text>
                                        <v-radio-group
                                            style="background-color: #F7F7F7;border:0.3px solid #C1C8CC;display:flex; align-items:center;justify-content:center; max-height: 40px;"
                                            row
                                            dense
                                            v-model="matvaryongender"
                                            :error-messages="matvaryongender_chk"
                                            @blur="$v.matvaryongender.$touch()"
                                            @change="selctdvaryongender"
                                            solo
                                            :rules="[required()]"
                                            class="rounded-0 mt-0"
                                            :disabled="inputdsbld"
                                            >
                                            <v-radio label="Yes" value="Yes" class="font-weight-bold cstm-clr-pln-cd mr-6 ml-2 mt-3 mb-0" color=#23B1A9></v-radio>
                                            <v-radio label="No" value="No" class="font-weight-bold cstm-clr-pln-cd mt-3 mb-0" color=#23B1A9></v-radio>
                                        </v-radio-group> 
                                
                                </v-layout>
                            </v-flex>
                            
                            <v-flex xs3 v-if="showdata===true">
                                <v-layout column class="cust_pln_cd mx-2 my-2">
                                    <p class=" font-weight-bold cstm-clr-pln-cd">If yes, How much extra amount for Girl child?</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="matyesgirlchild"
                                        class="rounded-0 "
                                        :maxlength="max10"
                                        :rules="[required(),alphaNumeric()]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                        </v-layout>


                        <v-layout class="my-3" style="align-items:flex-start" wrap>
                            <v-flex xs3 class="" v-if="matflatshow==true">
                                <v-layout column class="cust_pln_cd mx-2">
                                    <v-text class="font-weight-bold cstm-clr-pln-cd">
                                        Does flat amount vary based on city of delivery or delivery type?
                                    </v-text>
                                        <v-radio-group
                                            style="background-color: #F7F7F7;border:0.3px solid #C1C8CC;display:flex; align-items:center;justify-content:center; max-height: 40px;"
                                            row
                                            dense
                                            v-model="matvaryoncity"
                                            :error-messages="matvaryoncity_chk"
                                            @blur="$v.matvaryoncity.$touch()"
                                            @change="selctdvaryoncity"
                                            solo
                                            :rules="[required()]"
                                            class="rounded-0 mt-0"
                                            :disabled="inputdsbld">
                                            <v-radio label="Yes" value="Yes" class="font-weight-bold cstm-clr-pln-cd mr-6 ml-2 mt-3 mb-0" color=#23B1A9></v-radio>
                                            <v-radio label="No" value="No" class="font-weight-bold cstm-clr-pln-cd mt-3 mb-0" color=#23B1A9 ></v-radio>
                                        </v-radio-group> 
                                </v-layout>
                            </v-flex>

                            <v-flex  xs8 class="mx-2" v-if="showdatacity===true">
                                <v-layout  style="background-color:#23B1A9;justify-content:space-evenly;" >
                                    <v-divider vertical class=""></v-divider>
                                    <v-flex xs4 class="my-1 mx-1">
                                        <span class="font-weight-bold cstm-clr-pln-cd-white "  style="color:#F5F5F5"
                                        >City of Delivery
                                        </span>
                                    </v-flex>
                                    <v-divider vertical class=""></v-divider>
                                    <v-flex xs4 class="my-1 mx-1">
                                        <span class="font-weight-bold cstm-clr-pln-cd-white "  style="color:#F5F5F5"
                                        >Delivery Type
                                        </span>
                                    </v-flex>

                                    <v-divider vertical class=""></v-divider>
                                    <v-flex xs4 class="my-1 mx-1">
                                        <span class="font-weight-bold cstm-clr-pln-cd-white "  style="color:#F5F5F5"
                                        >Flat Amount
                                        </span>
                                    </v-flex>
                                    <v-divider vertical class=""></v-divider>
                                </v-layout>
                                <v-divider class=""></v-divider>

                                <v-layout class="" style="justify-content:space-evenly;" >
                                    <v-divider vertical class=""></v-divider> 
                                    <v-flex xs4 class="mx-1 cust_pln_cd" >
                                        <v-select
                                            hide-details
                                            :items="selectcityofdel"
                                            item-text="text"
                                            item-value="value"
                                            v-model="matcityofdel1"
                                            readonly
                                            label=""
                                            style=""
                                            class="rounded-0"
                                            :rules="[required()]"
                                        ></v-select>
                                    </v-flex>
                                    <v-divider vertical class=""></v-divider>
                                    <v-flex xs4 class="mx-1 cust_pln_cd">
                                        <v-select
                                            hide-details
                                            :items="selectdeltype"
                                            item-text="text"
                                            item-value="value"
                                            v-model="matdeltype1"
                                            readonly
                                            label=""
                                            style=""
                                            class="rounded-0"
                                            :rules="[required()]"
                                        ></v-select>
                                    </v-flex>
                                    <v-divider vertical class=""></v-divider>
                                    
                                    <v-flex xs4 class="mx-1 cust_pln_cd">
                                        <v-text-field
                                            label=""
                                            v-model="matflatamount1"
                                            class="rounded-0 cstm-clr-pr1"
                                            :maxlength="max10"
                                            style="color:#F5F5F5"
                                            :rules="[Numeric2]">
                                        </v-text-field>
                                    </v-flex>
                                    <v-divider vertical class=""></v-divider>    
                                </v-layout>
                                
                                <v-divider class=""></v-divider>
                                <v-layout class="" style="justify-content:space-evenly;" >
                                    <v-divider vertical class=""></v-divider> 
                                    <v-flex xs4 class="mx-1 cust_pln_cd" >
                                        <v-select
                                            hide-details
                                            :items="selectcityofdel"
                                            item-text="text"
                                            item-value="value"
                                            v-model="matcityofdel2"
                                            readonly
                                            label=""
                                            style=""
                                            class="rounded-0"
                                            :rules="[required()]"
                                        ></v-select>
                                    </v-flex>
                                    <v-divider vertical class=""></v-divider>
                                    <v-flex xs4 class="mx-1 cust_pln_cd">
                                        <v-select
                                            hide-details
                                            :items="selectdeltype"
                                            item-text="text"
                                            item-value="value"
                                            v-model="matdeltype2"
                                            readonly
                                            label=""
                                            style=""
                                            class="rounded-0"
                                            :rules="[required()]"
                                        ></v-select>
                                    </v-flex>
                                    <v-divider vertical class=""></v-divider>
                                    
                                    <v-flex xs4 class="mx-1 cust_pln_cd">
                                        <v-text-field
                                            label=""
                                            v-model="matflatamount2"
                                            class="rounded-0 cstm-clr-pr1"
                                            :maxlength="max10"
                                            style="color:#F5F5F5"
                                            :rules="[Numeric2]">
                                        </v-text-field>
                                    </v-flex>
                                    <v-divider vertical class=""></v-divider>    
                                </v-layout>
                                <v-divider class=""></v-divider>
                                <v-layout class="" style="justify-content:space-evenly;" >
                                    <v-divider vertical class=""></v-divider> 
                                    <v-flex xs4 class="mx-1 cust_pln_cd" >
                                        <v-select
                                            hide-details
                                            :items="selectcityofdel"
                                            item-text="text"
                                            item-value="value"
                                            v-model="matcityofdel3"
                                            readonly
                                            label=""
                                            style=""
                                            class="rounded-0"
                                            :rules="[required()]"
                                        ></v-select>
                                    </v-flex>
                                    <v-divider vertical class=""></v-divider>
                                    <v-flex xs4 class="mx-1 cust_pln_cd">
                                        <v-select
                                            hide-details
                                            :items="selectdeltype"
                                            item-text="text"
                                            item-value="value"
                                            v-model="matdeltype3"
                                            readonly
                                            label=""
                                            style=""
                                            class="rounded-0"
                                            :rules="[required()]"
                                        ></v-select>
                                    </v-flex>
                                    <v-divider vertical class=""></v-divider>
                                    
                                    <v-flex xs4 class="mx-1 cust_pln_cd">
                                        <v-text-field
                                            label=""
                                            v-model="matflatamount3"
                                            class="rounded-0 cstm-clr-pr1"
                                            :maxlength="max10"
                                            style="color:#F5F5F5"
                                            :rules="[Numeric2]">
                                        </v-text-field>
                                    </v-flex>
                                    <v-divider vertical class=""></v-divider>    
                                </v-layout>
                                <v-divider class=""></v-divider>
                                <v-layout class="" style="justify-content:space-evenly;" >
                                    <v-divider vertical class=""></v-divider> 
                                    <v-flex xs4 class="mx-1 cust_pln_cd" >
                                        <v-select
                                            hide-details
                                            :items="selectcityofdel"
                                            item-text="text"
                                            item-value="value"
                                            v-model="matcityofdel4"
                                            readonly
                                            label=""
                                            style=""
                                            class="rounded-0"
                                            :rules="[required()]"
                                        ></v-select>
                                    </v-flex>
                                    <v-divider vertical class=""></v-divider>
                                    <v-flex xs4 class="mx-1 cust_pln_cd">
                                        <v-select
                                            hide-details
                                            :items="selectdeltype"
                                            item-text="text"
                                            item-value="value"
                                            v-model="matdeltype4"
                                            readonly
                                            label=""
                                            style=""
                                            class="rounded-0"
                                            :rules="[required()]"
                                        ></v-select>
                                    </v-flex>
                                    <v-divider vertical class=""></v-divider>
                                    
                                    <v-flex xs4 class="mx-1 cust_pln_cd">
                                        <v-text-field
                                            label=""
                                            v-model="matflatamount4"
                                            class="rounded-0 cstm-clr-pr1"
                                            :maxlength="max10"
                                            style="color:#F5F5F5"
                                            :rules="[Numeric2]">
                                        </v-text-field>
                                    </v-flex>
                                    <v-divider vertical class=""></v-divider>    
                                </v-layout>
                                <v-divider class="mb-2"></v-divider>
                            </v-flex>
            
                        </v-layout>

                        <!-- for pre post natal -->
                       
                        <v-layout class="my-3" style="align-items:flex-start" wrap>
                            <v-flex xs3 class="">
                                <v-layout column class="cust_pln_cd mx-2">
                                    <v-text class="font-weight-bold cstm-clr-pln-cd">Pre-& post-natal applicable</v-text>
                                    <!-- :error-messages="matnatal_chk" -->
                                    <v-radio-group
                                        style="background-color: #F7F7F7;border:0.3px solid #C1C8CC;display:flex; align-items:center;justify-content:center; max-height: 40px;"
                                        row
                                        dense
                                        v-model="matnatal"
                                        @blur="$v.matnatal.$touch()"
                                        @change="selctdnatal"
                                        solo
                                        :rules="[required()]"
                                        class="rounded-0 mt-0"
                                        :disabled="inputdsbld">
                                    <v-radio label="Yes" value="Yes" class="font-weight-bold cstm-clr-pln-cd mr-6 ml-2 mt-3 mb-0" color=#23B1A9></v-radio>
                                    <v-radio label="No" value="No" class="font-weight-bold cstm-clr-pln-cd mt-3 mb-0" color=#23B1A9></v-radio>
                                </v-radio-group> 
                                </v-layout>
                            </v-flex>
                            

                            <v-flex xs8 class="mx-2" v-if="showdatanatal===true">
                                <v-layout class="" style="background-color:#23B1A9;" >
                                    <v-flex xs12 class="pa-1 mx-2">
                                        <span class="font-weight-bold cstm-clr-pln-cd-white"  style="color:#F5F5F5">Duration for covering Pre-natal & Post-natal expenses</span>
                                    </v-flex>
                                </v-layout>

                                <v-layout class="" style="justify-content:space-evenly;" >
                                    <v-divider vertical class=""></v-divider>
                                    <v-flex xs6 class="mx-2 my-2" ></v-flex>

                                    <v-divider vertical class=""></v-divider>
                                    <v-flex xs6 class="mx-2 my-2">
                                        <v-text class="font-weight-bold cstm-clr-pln-cd">No.of Days</v-text>    
                                    </v-flex>
                                    <v-divider vertical class=""></v-divider>
                                </v-layout>
                                <v-divider class=""></v-divider>

                                <v-layout class="" style="justify-content:space-evenly;" >
                                    <v-divider vertical class=""></v-divider>
                                    <v-flex xs6 class="mx-2" >
                                        <v-text class="font-weight-bold cstm-clr-pln-cd">Pre-natal expenses</v-text>
                                    </v-flex>
                                    <v-divider vertical class=""></v-divider>
                                    <v-flex xs6 class="mx-2 cust_pln_cd">
                                        <v-text-field
                                            label=""
                                            v-model="matpreflatamount"
                                            class="rounded-0 cstm-clr-pr1"
                                            :maxlength="max10"
                                            style="color:#F5F5F5"
                                            :rules="[required(),alphaNumeric()]">
                                        </v-text-field>
                                    </v-flex>
                                    <v-divider vertical class=""></v-divider>
                                </v-layout>

                                <v-divider class=""></v-divider>
                                <v-layout class="" style="justify-content:space-evenly;" >
                                    <v-divider vertical class=""></v-divider>
                                    <v-flex xs6 class="mx-2" >
                                        <v-text class="font-weight-bold cstm-clr-pln-cd">Post-natal expenses</v-text>
                                    </v-flex>

                                    <v-divider vertical class=""></v-divider>
                                    <v-flex xs6 class="mx-2 cust_pln_cd">
                                        <v-text-field
                                            label=""
                                            v-model="matpostflatamount"
                                            class="rounded-0 cstm-clr-pr1"
                                            :maxlength="max10"
                                            style="color:#F5F5F5"
                                            :rules="[required(),alphaNumeric()]">
                                        </v-text-field>
                                    </v-flex>
                                    <v-divider vertical class=""></v-divider>
                                </v-layout>
                                <v-divider class="mb-2"></v-divider>
                            </v-flex>
                        </v-layout>

                        <!-- for pre post limit -->
                        <v-flex class="my-3" style="align-items:flex-start">
                            <v-flex xs3 class="" v-if="showdatanatal===true">
                                <v-layout column class="cust_pln_cd mx-2">
                                    <v-text class="font-weight-bold cstm-clr-pln-cd">Over & above Maternity limit</v-text>
                                    <v-radio-group
                                        style="background-color: #F7F7F7;border:0.3px solid #C1C8CC;display:flex; align-items:center;justify-content:center; max-height: 40px;"
                                        row
                                        v-model="matlimit"
                                        @change="selctdlimit"
                                        solo
                                        :rules="[]"
                                        class="rounded-0 mt-0"
                                        :disabled="inputdsbld">
                                        <v-radio label="Yes" value="Yes" class="font-weight-bold cstm-clr-pln-cd mr-6 ml-2 mt-3 mb-0" color=#23B1A9></v-radio>
                                        <v-radio label="No" value="No" class="font-weight-bold cstm-clr-pln-cd mt-3 mb-0" color=#23B1A9 ></v-radio>
                                    </v-radio-group> 
                                </v-layout>
                            </v-flex>

                            <v-flex class="mx-2 mt-3" v-if="showdatalimit===true">
                                    <v-flex xs12 class="mb-2" >
                                        <v-text class="font-weight-bold cstm-clr-pr1 "  >Limits applicable for Pre- & Post-natal </v-text>
                                    </v-flex>

                                    <v-divider class=""></v-divider>
                                    <v-layout class="mt-2" style="" wrap>
                                        <v-flex xs3>
                                            <div class="mx-1" >
                                                <v-text class="font-weight-bold body-2">Pre-natal expenses : </v-text>
                                            </div>
                                        </v-flex>
                                        <v-flex xs3 class="mb-3">
                                            <v-layout column class="cust_pln_cd mx-1 ">
                                                <p class="font-weight-bold cstm-clr-pln-cd">
                                                    Coverage Basis
                                                </p>
                                                <v-select
                                                    :items="cvrgBasisItems"
                                                    v-model="matprecoverbasis"
                                                    label="Select"
                                                    solo
                                                    @change="changeMCPreCoverBasis(1)"
                                                    :rules="[required()]"
                                                    class="rounded-0"
                                                ></v-select>
                                            </v-layout>
                                        </v-flex>
                                        <v-flex v-if="MCPreshowSubLimClaimPay == true" xs3>
                                            <v-layout column class="cust_pln_cd mx-1 ">
                                                <p class="font-weight-bold cstm-clr-pln-cd">
                                                    Sub Limit Basis
                                                </p>
                                                <v-select
                                                    :items="nbbcvrgSbLmtBasisItems"
                                                    v-model="matpresublimit"
                                                    label="Select"
                                                    solo
                                                    multiple
                                                    @change="matPreSubLimChange(matpresublimit)"
                                                    :rules="[required()]"
                                                    class="rounded-0"
                                                >
                                                <template v-slot:selection="{ item, index }">
                                   
                                                    <span v-if="index === 0" >{{ item }}</span>
                                                
                                                    <span
                                                    v-if="index === 1"
                                                    class="grey--text caption"
                                                    >
                                                    (+{{ matpresublimit.length - 1 }})
                                                    </span>
                                                </template>
                                                </v-select>
                                            </v-layout>
                                        </v-flex>
                                        <v-flex xs3 v-if="showMCPrePercentSI == true">
                                            <v-layout column class="cust_pln_cd mx-1 ">
                                                <p class="font-weight-bold cstm-clr-pln-cd">% of SI</p>
                                                <v-text-field
                                                    solo
                                                    label="Enter..."
                                                    v-model="MCPrePercentSI"
                                                    class="rounded-0"
                                                    :maxlength="max10"
                                                    :rules="[required(),numberRule,decimalRule()]">
                                                </v-text-field>
                                            </v-layout>
                                        </v-flex>
                                        <v-flex xs3 v-if="showMCPreFlatAmnt == true">
                                            <v-layout column class="cust_pln_cd mx-1 ">
                                                <p class="font-weight-bold cstm-clr-pln-cd">Flat Amount</p>
                                                <v-text-field
                                                    solo
                                                    label="Enter..."
                                                    v-model="MCPreFlatAmountTxtField"
                                                    class="rounded-0"
                                                    :maxlength="max10"
                                                    :rules="[required(),Numeric2]">
                                                </v-text-field>
                                            </v-layout>
                                        </v-flex>
                                        
                                        <v-flex xs3 v-if="showMCPrePrevPercSI == true">
                                            <v-layout column class="cust_pln_cd mx-2 ">
                                                <p class="font-weight-bold cstm-clr-pln-cd">% of previous yr SI</p>
                                                <v-text-field
                                                    solo
                                                    label="Enter..."
                                                    v-model="MCPrePercentPrevSI"
                                                    class="rounded-0"
                                                    :maxlength="max10"
                                                    :rules="[required(),numberRule,decimalRule()]">
                                                </v-text-field>
                                            </v-layout>
                                        </v-flex>
                                        <v-flex xs3 v-if="showMCPrePercClaimAmt == true">
                                            <v-layout column class="cust_pln_cd mx-1 ">
                                                <p class="font-weight-bold cstm-clr-pln-cd">% of Claim Amount</p>
                                                <v-text-field
                                                    solo
                                                    label="Enter..."
                                                    v-model="MCPrePercentClaimAmt"
                                                    class="rounded-0"
                                                    :maxlength="max10"
                                                    :rules="[required(),Numeric2]">
                                                </v-text-field>
                                            </v-layout>
                                        </v-flex>
                                        <v-flex xs3 v-if="showMCPreApplicClaimPay == true">
                                            <v-layout column class="cust_pln_cd mx-1 ">
                                                <p class="font-weight-bold cstm-clr-pln-cd">Applicable Claim Pay-Out</p>
                                                <v-select
                                                    :items="hacAplcblClmPyotItms"
                                                    label="Select"
                                                    v-model="MCPreApplicClaimPayOut"
                                                    solo
                                                    class="rounded-0"
                                                    :rules="[required()]"
                                                    >
                                                </v-select>
                                            </v-layout>
                                        </v-flex>
                                        <v-flex v-if="MCPreshowSubLimClaimPay == true" xs3 >
                                            <v-layout column class="cust_pln_cd mx-1">
                                                <p class="font-weight-bold cstm-clr-pln-cd">
                                                    Claim Payment Basis
                                                </p>
                                                <v-select
                                                    :items="ClmpymntBasisItems"
                                                    v-model="matpreclaimpay"
                                                    label="Select"
                                                    @change="matPreClaimPayChange(matpreclaimpay)"
                                                    solo
                                                    
                                                    :rules="[required()]"
                                                    class="rounded-0"
                                                >
                                                </v-select>
                                            </v-layout>
                                        </v-flex>
                                        <v-flex xs3 v-if="showMCPreCovrageApplicDur == true">
                                            <v-layout column class="cust_pln_cd mx-1 ">
                                                <p class="font-weight-bold cstm-clr-pln-cd">
                                                    Coverage applicable duration (No of years)
                                                </p>
                                                <v-select
                                                    :items="hacCadItms"
                                                    label="Select"
                                                    v-model="MCPreCovrageApplicDur"
                                                    solo
                                                    class="rounded-0"
                                                    :rules="[required()]"
                                                    >
                                                </v-select>
                                            </v-layout>
                                        </v-flex>
                                    </v-layout>
                                    <v-divider class=""></v-divider>
                                    <!-- <v-flex style="background-color:red;height:1px"></v-flex> -->
                                    
                                    <v-layout class="mt-2" style="" wrap>
                                        <!-- <v-divider vertical class=""></v-divider> -->
                                        <v-flex xs3>
                                        <div class="mx-1" >
                                            <v-text class="font-weight-bold body-2">Post-natal expenses : </v-text>
                                        </div>
                                        </v-flex>

                                        <v-flex xs3>
                                            <v-layout column class="cust_pln_cd mx-1 ">
                                                <p class="font-weight-bold cstm-clr-pln-cd">
                                                    Coverage Basis
                                                </p>
                                                <v-select
                                                    :items="cvrgBasisItems"
                                                    v-model="matpostcoverbasis"
                                                    label="Select"
                                                    solo
                                                    :rules="[required()]"
                                                    @change="changeMCPostCoverBasis(1)"
                                                    class="rounded-0"
                                                ></v-select>
                                            </v-layout>
                                        </v-flex>
                                        <v-flex v-if="MCPostshowSubLimClaimPay == true" xs3>
                                            <v-layout column class="cust_pln_cd mx-1 ">
                                                <p class="font-weight-bold cstm-clr-pln-cd">
                                                    Sub Limit Basis
                                                </p>
                                                <v-select
                                                    :items="nbbcvrgSbLmtBasisItems"
                                                    v-model="matpostsublimit"
                                                    label="Select"
                                                    solo
                                                    multiple
                                                    @change="matPostSubLimChange(matpostsublimit)"
                                                    :rules="[required()]"
                                                    class="rounded-0"
                                                >
                                                <template v-slot:selection="{ item, index }">
                                   
                                                    <span v-if="index === 0" >{{ item }}</span>
                                                
                                                    <span
                                                    v-if="index === 1"
                                                    class="grey--text caption"
                                                    >
                                                    (+{{ matpostsublimit.length - 1 }})
                                                    </span>
                                                </template>
                                                </v-select>
                                            </v-layout>
                                        </v-flex>
                                        
                                        <v-flex xs3 v-if="showMCPostPercentSI == true">
                                            <v-layout column class="cust_pln_cd mx-1 ">
                                                <p class="font-weight-bold cstm-clr-pln-cd">% of SI</p>
                                                <v-text-field
                                                    solo
                                                    label="Enter..."
                                                    v-model="MCPostPercentSI"
                                                    class="rounded-0"
                                                    :maxlength="max10"
                                                    :rules="[required(),numberRule,decimalRule()]">
                                                </v-text-field>
                                            </v-layout>
                                        </v-flex>
                                        <v-flex xs3 v-if="showMCPostFlatAmnt == true">
                                            <v-layout column class="cust_pln_cd mx-1 ">
                                                <p class="font-weight-bold cstm-clr-pln-cd">Flat Amount</p>
                                                <v-text-field
                                                    solo
                                                    label="Enter..."
                                                    v-model="MCPostFlatAmountTxtField"
                                                    class="rounded-0"
                                                    :maxlength="max10"
                                                    :rules="[required(),Numeric2]">
                                                </v-text-field>
                                            </v-layout>
                                        </v-flex>
                                        
                                        <v-flex xs3 v-if="showMCPostPrevPercSI == true">
                                            <v-layout column class="cust_pln_cd mx-2 ">
                                                <p class="font-weight-bold cstm-clr-pln-cd">% of previous yr SI</p>
                                                <v-text-field
                                                    solo
                                                    label="Enter..."
                                                    v-model="MCPostPercentPrevSI"
                                                    class="rounded-0"
                                                    :maxlength="max10"
                                                    :rules="[required(),numberRule,decimalRule()]">
                                                </v-text-field>
                                            </v-layout>
                                        </v-flex>
                                        <v-flex xs3 v-if="showMCPostPercClaimAmt == true">
                                            <v-layout column class="cust_pln_cd mx-1 ">
                                                <p class="font-weight-bold cstm-clr-pln-cd">% of Claim Amount</p>
                                                <v-text-field
                                                    solo
                                                    label="Enter..."
                                                    v-model="MCPostPercentClaimAmt"
                                                    class="rounded-0"
                                                    :maxlength="max10"
                                                    :rules="[required(),Numeric2]">
                                                </v-text-field>
                                            </v-layout>
                                        </v-flex>
                                        <v-flex xs3 v-if="showMCPostApplicClaimPay == true">
                                            <v-layout column class="cust_pln_cd mx-1 ">
                                                <p class="font-weight-bold cstm-clr-pln-cd">Applicable Claim Pay-Out</p>
                                                <v-select
                                                    :items="hacAplcblClmPyotItms"
                                                    label="Select"
                                                    v-model="MCPostApplicClaimPayOut"
                                                    solo
                                                    class="rounded-0"
                                                    :rules="[required()]"
                                                    >
                                                </v-select>
                                            </v-layout>
                                        </v-flex>
                                        <v-flex v-if="MCPostshowSubLimClaimPay == true" xs3 >
                                            <v-layout column class="cust_pln_cd mx-1">
                                                <p class="font-weight-bold cstm-clr-pln-cd">
                                                    Claim Payment Basis
                                                </p>
                                                <v-select
                                                    :items="ClmpymntBasisItems"
                                                    v-model="matpostclaimpay"
                                                    label="Select"
                                                    @change="matPostClaimPayChange(matpostclaimpay)"
                                                    solo
                                                    :rules="[required()]"
                                                    class="rounded-0"
                                                >
                                                </v-select>
                                            </v-layout>
                                        </v-flex>
                                        <v-flex xs3 v-if="showMCPostCovrageApplicDur == true">
                                            <v-layout column class="cust_pln_cd mx-1 ">
                                                <p class="font-weight-bold cstm-clr-pln-cd">
                                                    Coverage applicable duration (No of years)
                                                </p>
                                                <v-select
                                                    :items="hacCadItms"
                                                    label="Select"
                                                    v-model="MCPostCovrageApplicDur"
                                                    solo
                                                    class="rounded-0"
                                                    :rules="[required()]"
                                                    >
                                                </v-select>
                                            </v-layout>
                                        </v-flex>
                                    </v-layout>
                            </v-flex>
                        </v-flex>
                        <v-divider></v-divider>
                        <!-- NEW FIELDS ADDED MC-->
                        <v-layout class="mt-3" wrap style="align-items:flex-end">
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">IRDA UIN Number</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="MCIrdaUINNo"
                                        class="rounded-0"
                                        :maxlength="max20"
                                        :rules="spclCharNew">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">External Reference Number</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="MCExtRefNo"
                                        class="rounded-0"
                                        :maxlength="max30"
                                        :rules="spclCharNew">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Conditions</p>
                                    <v-select
                                        :items="prodConditionArray"
                                        label="Select"
                                        v-model="MCCondField"
                                        solo
                                        multiple
                                        class="rounded-0"
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">GC Coverage</p>
                                    <v-select
                                        :items="prodGCCoverArray"
                                        label="Select"
                                        v-model="MCGcCover"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]">
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Remarks</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="MCRemarks"
                                        class="rounded-0"
                                        :maxlength="max40"
                                        >
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Exclusions</p>
                                    <v-select
                                        :items="prodExcluArray"
                                        :maxlength="max30"
                                        label="Select"
                                        v-model="MCExclus"
                                        solo
                                        class="rounded-0"
                                        multiple
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                        </v-layout>

                        <v-divider></v-divider>
                        <v-layout style="flex:1" justify-end class="my-4">
                            <v-btn tile dark  @click="clearMC()" style="font-size:12px;background-color:#e46a25" class="text-capitalize px-3 mx-2" small><v-icon dark small>close</v-icon> <v-divider vertical class="mx-2"></v-divider> Clear</v-btn>
                            <v-btn tile dark  @click="submitMC()" style="font-size:12px;background-color:#23B1A9" class="text-capitalize px-3 mx-2" small><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider> Save</v-btn>
                            <v-btn tile dark @click="nxt(nextdrive[1])" style="font-size:12px;background-color:#152F38" 
                            class="text-capitalize px-3 mx-2" small><v-icon dark small>arrow_forward</v-icon>
                            <v-divider vertical class="mx-2"></v-divider>Next</v-btn>
                        </v-layout>
                    </v-form>
                </div>
            </div>


            <!-- 1.hcbc -->
            <div class="panelis" v-if="showHCBCAccor == true" dense>
                <div @click="currentis(nextdrive[2])" expand-icon="mdi-menu-down" class="font-weight-bold panhead" >
                    Hospital cash benefit for accompanying an Insured child   <span> <v-icon  :style="{border: datais[nextdrive[2].shown == 'y' ? '5px red solid':'none' ] }"  >arrow_drop_down</v-icon> </span> 
                </div>
                
                <div  class="pa-2"    v-if="datais[nextdrive[2].shown]"  >
                    <v-divider></v-divider>
                    <v-form v-model="hcbcvalid" ref="hcbcForm">  
                        <v-layout class="mt-3" style="align-items:flex-end" wrap>

                            <v-flex xs3 >
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Coverage Type
                                    </p>
                                    <v-select
                                    :items="cvrgTypItems"
                                    v-model="hcbccoverageType"
                                    label="Select"
                                    
                                    solo
                                    :rules="[required()]"
                                    
                                    class="rounded-0"
                                ></v-select>
                                </v-layout>
                            </v-flex>

                            <v-flex xs3 >
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Coverage Basis
                                    </p>
                                    <v-select
                                        :items="cvrgBasisItems"
                                        v-model="hcbccoverageBasis"
                                        label="Select"
                                        
                                        solo
                                        :rules="[required()]"
                                        class="rounded-0"
                                        @change="hcbcchangeOnover(1)"
                                    ></v-select>
                                </v-layout>
                            </v-flex>

                            <v-flex xs3 v-if="hcbcsubBasisshow==true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Sub Limit Basis
                                    </p>
                                    <v-select
                                        :items="cvrgSbLmtBasisItems"
                                        v-model="hcbcsubLimitBasis"
                                        label="Select"
                                        
                                        solo
                                        multiple
                                        @change="hcbcflatchange(hcbcsubLimitBasis)"
                                        :rules="[required()]"
                                        class="rounded-0"
                                    >
                                    <template v-slot:selection="{ item, index }">
                                   
                                    <span v-if="index === 0" >{{ item }}</span>
                                   
                                    <span
                                    v-if="index === 1"
                                    class="grey--text caption"
                                    >
                                    (+{{ hcbcsubLimitBasis.length - 1 }} )
                                    </span>
                                </template>
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            
                            <v-flex xs3 v-if="showHCBACIPercentSi == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">% of SI</p>
                                    <v-text-field
                                        solo
                                        
                                        label="Enter..."
                                        v-model="HCBACIPercentSI"
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),numberRule,decimalRule()]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="showHCBACIFlatAmnt == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Flat Amount</p>
                                    <v-text-field
                                        solo
                                        
                                        label="Enter..."
                                        v-model="HCBACIFlatAmountTxtField"
                                        class="rounded-0"
                                        :maxlength="max10"
                                       :rules="[required(),alphaNumeric()]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="showHCBACIPrvPercentSi == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">% of previous yr SI</p>
                                    <v-text-field
                                        solo
                                        
                                        label="Enter..."
                                        v-model="HCBACIPrvPercentSI"
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),numberRule,decimalRule()]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="showHCBACIPercClaimAmt == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">% of Claim Amount</p>
                                    <v-text-field
                                        solo
                                        
                                        label="Enter..."
                                        v-model="HCBACIPercentClaimAmt"
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),numberRule,decimalRule()]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                                    
                                <!-- <v-divider  class="ma-2" vertical ></v-divider> -->

                            <v-flex xs3 v-if="hcbcsubBasisshow==true">
                                <v-layout column class="cust_pln_cd mx-2">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Claim Payment Basis
                                    </p>
                                    <v-select
                                        :items="ClmpymntBasisItems"
                                        v-model="hcbcclaimPaymentBasis"
                                        label="Select"
                                        @change="changeHCBACIClaimPayBas(hcbcclaimPaymentBasis)"
                                        
                                        solo
                                        :rules="[required()]"
                                        class="rounded-0"
                                    >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="showHCBACICovrageApplicDur == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Coverage applicable duration (No of years)
                                    </p>
                                    <v-select
                                        :items="hacCadItms"
                                        label="Select"
                                        v-model="HCBACICovrageApplicDur"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]"
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex>

                            <v-flex xs3 >
                                <v-layout column class="cust_pln_cd mx-2">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Waiting Period
                                    </p>
                                    <v-text-field
                                        solo
                                        
                                        label="Enter..."
                                        v-model="hcbcwaitingPeriod"
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[Numeric2]"
                                        
                                    >
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            
                            
                            <v-flex xs3 v-if="hcbcshowAppli==true">
                                <v-layout column class="cust_pln_cd mx-2">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Applicable Claim Pay-Out
                                    </p>

                                    <v-select
                                        :items="selectApplicableClaimPayOut"
                                        v-model="hcbcapplicableClaimPayOut"
                                        label="Select"
                                        
                                        solo
                                        :rules="[required()]"
                                        class="rounded-0"
                                    ></v-select>
                                </v-layout>
                            </v-flex>

                            <v-flex xs3 >
                                <v-layout column class="cust_pln_cd mx-2">
                                    <p class="font-weight-bold cstm-clr-pln-cd ">
                                        Minimum Hospitalization Duration for Eligible Claim(No. of Days)
                                    </p>
                                    <v-text-field
                                        solo
                                        
                                        label="Enter..."
                                        v-model="hcbcminHosDuration"
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[Numeric2]"
                                        
                                    >
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                        
                            <v-flex xs3 >
                                <v-layout column class="cust_pln_cd mx-2">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Maximum Age of Insured Child
                                    </p>
                                    <v-text-field
                                        solo
                                        
                                        label="Enter..."
                                        v-model="hcbcMaxAgeChild"
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),alphaNumeric()]"
                                    >
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                        </v-layout>

                        <v-layout class="mx-2" style="justify-content:space-evenly;background-color:#23B1A9;" >
                        
                                    <v-flex xs4 class="pa-1 ">
                                        <span class="font-weight-bold subheading cstm-clr-pln-cd-white pl-1"  style="color:#F5F5F5"
                                        >Cause Type
                                        </span>
                                    </v-flex>
                                    
                                    <v-divider vertical class=""></v-divider>
                                    
                                    <v-flex xs4 class="pa-1">
                                        <span class="font-weight-bold subheading cstm-clr-pln-cd-white pl-1"  style="color:#F5F5F5"
                                        >Room Type
                                        </span>
                                    </v-flex>

                                    <v-divider vertical class=""></v-divider>
                                    
                                    <v-flex xs4 class="pa-1">
                                        <span class="font-weight-bold subheading cstm-clr-pln-cd-white pl-1"  style="color:#F5F5F5"
                                        >Multiplier of daily cash
                                        </span>
                                    </v-flex>
                        
                        </v-layout>

                        <v-layout class="mx-2" style="justify-content:space-evenly;" >
                                 <v-divider vertical class=""></v-divider>
                                <v-flex xs4 class="ml-2 mr-2 cust_pln_cd" >
                                    <v-select
                                            hide-details
                                            readonly
                                            :items="selectcauseType"
                                            item-text="text"
                                            item-value="value"
                                            v-model="hcbccauseType1"
                                            label=""
                                            style=""
                                            class="rounded-0"
                                        ></v-select>
                                </v-flex>

                                <v-divider vertical class=""></v-divider>
                                
                                    <v-flex xs4 class="ml-2 mr-2 cust_pln_cd">
                                        <v-select
                                            hide-details
                                            readonly
                                            :items="selectroomType"
                                            item-text="text"
                                            item-value="value"
                                            v-model="hcbcroomType1"
                                            label=""
                                            style=""
                                            class="rounded-0"
                                            
                                        ></v-select>
                                    </v-flex>
                                <v-divider vertical class=""></v-divider>
                                
                                <v-flex xs4 class="ml-2 mr-2 cust_pln_cd">
                                        <v-text-field
                                            label=""
                                            v-model="hcbcdailyCash1"
                                            class="rounded-0 cstm-clr-pr1"
                                            :maxlength="max10"
                                            style="color:#F5F5F5"
                                            :rules="[v => /^[1-9]\d*(\.\d+)?$/.test(v) || 'Only Numbers are allowed.']"
                                        >
                                        </v-text-field>
                                </v-flex>
                                <v-divider vertical class=""></v-divider>

                        </v-layout>
                        <v-divider class="mx-2"></v-divider>

                        <v-layout class="mx-2" style="justify-content:space-evenly;" >
                            <v-divider vertical class=""></v-divider>
                                <v-flex xs4 class="ml-2 mr-2 cust_pln_cd" >
                                    <v-select
                                            hide-details
                                            readonly
                                            :items="selectcauseType"
                                            item-text="text"
                                            item-value="value"
                                            v-model="hcbccauseType2"
                                            label=""
                                            style=""
                                            class="rounded-0"
                                        ></v-select>
                                </v-flex>

                                <v-divider vertical class=""></v-divider>
                                
                                    <v-flex xs4 class="ml-2 mr-2 cust_pln_cd">
                                        <v-select
                                            hide-details
                                            readonly
                                            :items="selectroomType"
                                            item-text="text"
                                            item-value="value"
                                            v-model="hcbcroomType2"
                                            label=""
                                            style=""
                                            class="rounded-0"
                                            
                                        ></v-select>
                                    </v-flex>
                                <v-divider vertical class=""></v-divider>
                                
                                <v-flex xs4 class="ml-2 mr-2 cust_pln_cd">
                                        <v-text-field
                                            label=""
                                            v-model="hcbcdailyCash2"
                                            class="rounded-0 cstm-clr-pr1"
                                            :maxlength="max10"
                                            style="color:#F5F5F5"
                                            :rules="[v => /^[1-9]\d*(\.\d+)?$/.test(v) || 'Only Numbers are allowed.']"
                                                                
                                        >
                                        </v-text-field>
                                </v-flex>

                                <v-divider vertical class=""></v-divider>
                    

                        </v-layout>
                        <v-divider class="mx-2"></v-divider>
                        <v-layout class="mx-2" style="justify-content:space-evenly;" >
                            <v-divider vertical class=""></v-divider>
                                <v-flex xs4 class="ml-2 mr-2 cust_pln_cd" >
                                    <v-select
                                            hide-details
                                            readonly
                                            :items="selectcauseType"
                                            item-text="text"
                                            item-value="value"
                                            v-model="hcbccauseType3"
                                            label=""
                                            style=""
                                            class="rounded-0"
                                        ></v-select>
                                </v-flex>

                                <v-divider vertical class=""></v-divider>
                                
                                    <v-flex xs4 class="ml-2 mr-2 cust_pln_cd">
                                        <v-select
                                            hide-details
                                            readonly
                                            :items="selectroomType"
                                            item-text="text"
                                            item-value="value"
                                            v-model="hcbcroomType3"
                                            label=""
                                            style=""
                                            class="rounded-0"
                                            
                                        ></v-select>
                                    </v-flex>
                                <v-divider vertical class=""></v-divider>
                                
                                <v-flex xs4 class="ml-2 mr-2 cust_pln_cd">
                                        <v-text-field
                                            label=""
                                            v-model="hcbcdailyCash3"
                                            class="rounded-0 cstm-clr-pr1"
                                            :maxlength="max10"
                                            style="color:#F5F5F5"
                                            :rules="[v => /^[1-9]\d*(\.\d+)?$/.test(v) || 'Only Numbers are allowed.']"
                                                                
                                        >
                                        </v-text-field>
                                </v-flex>
                                <v-divider vertical class=""></v-divider>

                        </v-layout>
                        <v-divider class="mx-2"></v-divider>
                        <v-layout class="mx-2" style="justify-content:space-evenly;" >
                            <v-divider vertical class=""></v-divider>
                                <v-flex xs4 class="ml-2 mr-2 cust_pln_cd" >
                                    <v-select
                                            hide-details
                                            readonly
                                            :items="selectcauseType"
                                            item-text="text"
                                            item-value="value"
                                            v-model="hcbccauseType4"
                                            label=""
                                            style=""
                                            class="rounded-0"
                                        ></v-select>
                                </v-flex>

                                <v-divider vertical class=""></v-divider>
                                
                                    <v-flex xs4 class="ml-2 mr-2 cust_pln_cd">
                                        <v-select
                                            hide-details
                                            readonly
                                            :items="selectroomType"
                                            item-text="text"
                                            item-value="value"
                                            v-model="hcbcroomType4"
                                            label=""
                                            style=""
                                            class="rounded-0"
                                            
                                        ></v-select>
                                    </v-flex>
                                <v-divider vertical class=""></v-divider>
                                
                                <v-flex xs4 class="ml-2 mr-2 cust_pln_cd">
                                        <v-text-field
                                            label=""
                                            v-model="hcbcdailyCash4"
                                            class="rounded-0 cstm-clr-pr1"
                                            :maxlength="max10"
                                            style="color:#F5F5F5"
                                            :rules="[v => /^[1-9]\d*(\.\d+)?$/.test(v) || 'Only Numbers are allowed.']"
                                                                
                                        >
                                        </v-text-field>
                                </v-flex>
                                <v-divider vertical class=""></v-divider>

                        </v-layout>
                    
                        <v-divider class="mx-2"></v-divider>
                        <v-layout style="flex:1" justify-end class="my-4">
                            <v-btn dark tile @click="submitHCBHCBACI('hospCashBenInsuChild')" style="font-size:12px;background-color:#23B1A9" class="text-capitalize px-3 mx-2" small><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider> Save</v-btn>
                            <v-btn @click="nxt(nextdrive[2])" dark tile style="font-size:12px;background-color:#152F38"  
                            class="text-capitalize px-3 mx-2" small><v-icon dark small>arrow_forward</v-icon>
                            <v-divider vertical class="mx-2"></v-divider>Next</v-btn>

                        </v-layout>
                    </v-form>
                    

                </div>
                
            </div>

         <!-- 2.hcb -->

            <div class="panelis" v-if="showHCBAccor == true" dense>
                <div  @click="currentis(nextdrive[3])" expand-icon="mdi-menu-down" class="font-weight-bold panhead" >
                    Hospital Cash Benefit   <span> <v-icon  :style="{border: datais[nextdrive[3].shown == 'y' ? '5px red solid':'none' ] }"  >arrow_drop_down</v-icon> </span> 
                </div>
                
                <div  class="pa-2"    v-if="datais[nextdrive[3].shown]"  >
                    <v-divider></v-divider>
                    <v-form v-model="hcbvalid" ref="hcbForm">  
                        <v-layout class="mt-3" style="align-items:flex-end" wrap>
                            <v-flex xs3 >
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Coverage Alternate Name</p>
                                    <v-text-field
                                        :rules="alphaRule"
                                        solo
                                        label="Enter..."
                                        v-model="HCBCoverAltName"
                                        class="rounded-0"
                                        :maxlength="max200"
                                        >
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 >
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Coverage Type
                                    </p>
                                    <v-select
                                    :items="cvrgTypItems"
                                    v-model="hcbcoverageType"
                                    label="Select"
                                    solo
                                    :rules="[required()]"
                                    
                                    class="rounded-0"
                                ></v-select>
                                </v-layout>
                            </v-flex>

                            <v-flex xs3 >
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Coverage Basis
                                    </p>
                                    <v-select
                                        :items="cvrgBasisItems"
                                        v-model="hcbcoverageBasis"
                                        label="Select"
                                        solo
                                        :rules="[required()]"
                                        class="rounded-0"
                                        @change="hcbchangeOnover(1)"
                                    ></v-select>
                                </v-layout>
                            </v-flex>

                            <v-flex xs3 v-if="hcbsubBasisshow==true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Sub Limit Basis
                                    </p>
                                    <v-select
                                        :items="cvrgSbLmtBasisItems"
                                        v-model="hcbsubLimitBasis"
                                        label="Select"
                                        solo
                                        multiple
                                        @change="hcbflatchange(hcbsubLimitBasis)"
                                        class="rounded-0"
                                        :rules="[required()]"
                                    >
                                    <template v-slot:selection="{ item, index }">
                                   
                                    <span v-if="index === 0" >{{ item }}</span>
                                   
                                    <span
                                    v-if="index === 1"
                                    class="grey--text caption"
                                    >
                                    (+{{ hcbsubLimitBasis.length - 1 }} )
                                    </span>
                                </template>
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            
                            <v-flex xs3 v-if="showHCBPercentSi == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">% of SI</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="HCBPercentSI"
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),numberRule,decimalRule()]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="showHCBFlatAmnt == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Flat Amount</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="HCBFlatAmountTxtField"
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),alphaNumeric()]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="showHCBPrvPercentSi == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd"> % of previous yr SI</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="HCBPrvPercentSI"
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),numberRule,decimalRule()]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="showHCBPercClaimAmt == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">% of Claim Amount</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="HCBPercentClaimAmt"
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),numberRule,decimalRule()]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                             <v-flex xs3 v-if="hcbshowAppli==true">
                                <v-layout column class="cust_pln_cd mx-2">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Applicable Claim Pay-Out
                                    </p>
                                    <v-select
                                        :items="selectApplicableClaimPayOut"
                                        v-model="hcbapplicableClaimPayOut"
                                        label="Select"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]"
                                    ></v-select>
                                </v-layout>
                            </v-flex>

                            <v-flex xs3 v-if="hcbsubBasisshow==true">
                                <v-layout column class="cust_pln_cd mx-2">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Claim Payment Basis
                                    </p>
                                    <v-select
                                        :items="ClmpymntBasisItems"
                                        v-model="hcbclaimPaymentBasis"
                                        label="Select"
                                        solo
                                        @change="changeHCBClaimPayBasis(hcbclaimPaymentBasis)"
                                        :rules="[required()]"
                                        class="rounded-0"
                                    >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="showHCBCovrageApplicDur == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Coverage applicable duration (No of years)
                                    </p>
                                    <v-select
                                        :items="hacCadItms"
                                        label="Select"
                                        v-model="HCBCovrageApplicDur"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]"
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Waiting Period</p>
                                    <v-layout>
                                        <v-select
                                            :items="['Days','Months']"
                                            label="Select"
                                            v-model="hcbwaitingPeriodDrop"
                                            solo
                                            style="width: 145px"
                                            class="rounded-0"
                                            :rules="[required()]"
                                            >
                                        </v-select>
                                        <v-text-field
                                            label="Enter..."
                                            v-model="hcbwaitingPeriod"
                                            solo
                                            class="rounded-0"
                                            :maxlength="max10"
                                            :rules="[Numeric2]"
                                            >
                                        </v-text-field>
                                    </v-layout>
                                </v-layout>
                            </v-flex>

                            <v-flex xs3 >
                                <v-layout column class="cust_pln_cd mx-2">
                                    <p class="font-weight-bold cstm-clr-pln-cd ">
                                        Minimum Hospitalization Duration for Eligible Claim(No. of Hours)
                                    </p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="hcbminHosDuration"
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[Numeric2]"
                                    >
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 >
                                <v-layout column class="cust_pln_cd mx-2">
                                    <p class="font-weight-bold cstm-clr-pln-cd ">Deductible(No. of Days)</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="HCBDeductible"
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[Numeric2]"
                                    >
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                        </v-layout>
                       
                        <v-layout class="mx-2" style="justify-content:space-evenly;background-color:#23B1A9;" >
                            <v-flex xs4 class="pa-1 ">
                                <span class="font-weight-bold cstm-clr-pln-cd-white text-truncate pl-1"  style="color:#F5F5F5">Cause Type</span>
                            </v-flex>
                            <v-divider vertical class=""></v-divider>
                            <v-flex xs4 class="pa-1 ">
                                <span class="font-weight-bold cstm-clr-pln-cd-white text-truncate pl-1"  style="color:#F5F5F5">Room Type</span>
                            </v-flex>
                            <v-divider vertical class=""></v-divider>
                            <v-flex xs4 class="pa-1">
                                <span class="font-weight-bold cstm-clr-pln-cd-white text-truncate pl-1"  style="color:#F5F5F5">Multiplier of daily cash</span>
                            </v-flex>
                        </v-layout>

                        <v-layout class="mx-2" style="justify-content:space-evenly;" >
                            <v-divider vertical class=""></v-divider>
                            <v-flex xs4 class="mx-2 cust_pln_cd" >
                                <v-select
                                    hide-details
                                    readonly
                                    :items="selectcauseType"
                                    item-text="text"
                                    item-value="value"
                                    v-model="hcbcauseType1"
                                    label=""
                                    style=""
                                    class="rounded-0"
                                ></v-select>
                            </v-flex>
                            <v-divider vertical class=""></v-divider>
                                
                            <v-flex xs4 class="mx-2 cust_pln_cd">
                                <v-select
                                    hide-details
                                    readonly
                                    :items="selectroomType"
                                    item-text="text"
                                    item-value="value"
                                    v-model="hcbroomType1"
                                    label=""
                                    style=""
                                    class="rounded-0"
                                ></v-select>
                            </v-flex>
                            <v-divider vertical class=""></v-divider>
                            <v-flex xs4 class="mx-2 cust_pln_cd">
                                <v-text-field
                                    label=""
                                    v-model="hcbdailyCash1"
                                    class="rounded-0 cstm-clr-pr1"
                                    :maxlength="max10"
                                    style="color:#F5F5F5"
                                    :rules="[v => /^[1-9]\d*(\.\d+)?$/.test(v) || 'Only Numbers are allowed.']">
                                </v-text-field>
                            </v-flex>
                            <v-divider vertical class=""></v-divider>
                        </v-layout>
                        <v-divider class="mx-2"></v-divider>
                        <v-layout class="mx-2" style="justify-content:space-evenly;" >
                            <v-divider vertical class=""></v-divider>
                            <v-flex xs4 class="mx-2 cust_pln_cd" >
                                <v-select
                                    hide-details
                                    readonly
                                    :items="selectcauseType"
                                    item-text="text"
                                    item-value="value"
                                    v-model="hcbcauseType2"
                                    label=""
                                    style=""
                                    class="rounded-0"
                                ></v-select>
                            </v-flex>
                            <v-divider vertical class=""></v-divider>
                                
                            <v-flex xs4 class="mx-2 cust_pln_cd">
                                <v-select
                                    hide-details
                                    readonly
                                    :items="selectroomType"
                                    item-text="text"
                                    item-value="value"
                                    v-model="hcbroomType2"
                                    label=""
                                    style=""
                                    class="rounded-0"
                                ></v-select>
                            </v-flex>
                            <v-divider vertical class=""></v-divider>
                                
                            <v-flex xs4 class="mx-2 cust_pln_cd">
                                <v-text-field
                                    label=""
                                    v-model="hcbdailyCash2"
                                    class="rounded-0 cstm-clr-pr1"
                                    :maxlength="max10"
                                    style="color:#F5F5F5"
                                    :rules="[v => /^[1-9]\d*(\.\d+)?$/.test(v) || 'Only Numbers are allowed.']">
                                </v-text-field>
                            </v-flex>
                            <v-divider vertical class=""></v-divider>
                        </v-layout>
                        <v-divider class="mx-2"></v-divider>
                        <v-layout class="mx-2" style="justify-content:space-evenly;" >
                            <v-divider vertical class=""></v-divider>
                            <v-flex xs4 class="mx-2 cust_pln_cd" >
                                <v-select
                                    hide-details
                                    readonly
                                    :items="selectcauseType"
                                    item-text="text"
                                    item-value="value"
                                    v-model="hcbcauseType3"
                                    label=""
                                    style=""
                                    class="rounded-0"
                                ></v-select>
                            </v-flex>
                            <v-divider vertical class=""></v-divider>
                                
                            <v-flex xs4 class="mx-2 cust_pln_cd">
                                <v-select
                                    hide-details
                                    readonly
                                    :items="selectroomType"
                                    item-text="text"
                                    item-value="value"
                                    v-model="hcbroomType3"
                                    label=""
                                    style=""
                                    class="rounded-0"
                                ></v-select>
                            </v-flex>
                            <v-divider vertical class=""></v-divider>
                            <v-flex xs4 class="mx-2 cust_pln_cd">
                                <v-text-field
                                    label=""
                                    v-model="hcbdailyCash3"
                                    class="rounded-0 cstm-clr-pr1"
                                    :maxlength="max10"
                                    style="color:#F5F5F5"
                                    :rules="[v => /^[1-9]\d*(\.\d+)?$/.test(v) || 'Only Numbers are allowed.']" >
                                </v-text-field>
                            </v-flex>
                            <v-divider vertical class=""></v-divider>
                        </v-layout>

                        <v-divider class="mx-2"></v-divider>
                        <v-layout class="mx-2" style="justify-content:space-evenly;" >
                            <v-divider vertical class=""></v-divider>
                            <v-flex xs4 class="mx-2 cust_pln_cd" >
                                <v-select
                                    hide-details
                                    readonly
                                    :items="selectcauseType"
                                    item-text="text"
                                    item-value="value"
                                    v-model="hcbcauseType4"
                                    label=""
                                    style=""
                                    class="rounded-0"
                                ></v-select>
                            </v-flex>

                            <v-divider vertical class=""></v-divider>
                            <v-flex xs4 class="mx-2 cust_pln_cd">
                                <v-select
                                    hide-details
                                    readonly
                                    :items="selectroomType"
                                    item-text="text"
                                    item-value="value"
                                    v-model="hcbroomType4"
                                    label=""
                                    style=""
                                    class="rounded-0"
                                ></v-select>
                            </v-flex>
                            <v-divider vertical class=""></v-divider>
                            <v-flex xs4 class="mx-2 cust_pln_cd">
                                    <v-text-field
                                        label=""
                                        v-model="hcbdailyCash4"
                                        class="rounded-0 cstm-clr-pr1"
                                        :maxlength="max10"
                                        style="color:#F5F5F5"
                                        :rules="[v => /^[1-9]\d*(\.\d+)?$/.test(v) || 'Only Numbers are allowed.']">
                                    </v-text-field>
                            </v-flex>
                            <v-divider vertical class=""></v-divider>
                        </v-layout>

                        <v-divider></v-divider>
                        <!-- NEW FIELDS ADDED HCB -->
                        <v-layout class="mt-3" wrap style="align-items:flex-end">
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">IRDA UIN Number</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="HCBIrdaUINNo"
                                        class="rounded-0"
                                        :maxlength="max20"
                                        :rules="spclCharNew">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">External Reference Number</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="HCBExtRefNo"
                                        class="rounded-0"
                                        :maxlength="max30"
                                        :rules="spclCharNew">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Conditions</p>
                                    <v-select
                                        :items="prodConditionArray"
                                        label="Select"
                                        v-model="HCBCondField"
                                        multiple
                                        solo
                                        class="rounded-0"
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">GC Coverage</p>
                                    <v-select
                                        :items="prodGCCoverArray"
                                        label="Select"
                                        v-model="HCBGcCover"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]">
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Remarks</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="HCBRemarks"
                                        class="rounded-0"
                                        :maxlength="max40"
                                        >
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Exclusions</p>
                                    <v-select
                                        :items="prodExcluArray"
                                        :maxlength="max30"
                                        label="Select"
                                        v-model="HCBExclus"
                                        solo
                                        class="rounded-0"
                                        multiple
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                        </v-layout>
                        <v-divider></v-divider>
                        
                        <v-flex xs3 >
                            <v-layout column class="mx-2 cust_pln_cd" justify-center >
                                <v-text class="font-weight-bold cstm-clr-pln-cd" style="color:#FFFFFF" > </v-text>
                                <div style="background-color: #f7f7f7;border: 1px solid #c1c8cc;" class="pa-2">
                                    <v-checkbox class="ma-0 pa-0" v-model="HCBSelectBox" @change="HCBRelCheckSelected()" hide-details >
                                        <span slot="label" class="font-weight-bold text-truncate " style="font-size: 13px;"> Separate Relationship & Dependent Info</span>
                                    </v-checkbox>
                                </div>
                            </v-layout>
                        </v-flex>
                        <div v-if="showHCBRelSection == true">
                            <relationdependent 
                                ref="HCBRel" 
                                @save="saveHCBHCBACIRel" 
                                :redpndntinfo="{
                                    cirelDataemty:HCBrelCoverData.realdata,
                                    ciRelnshipCoveredEmpty:HCBrelCoverData.dropdowndata,
                                    cisyncArryEmpty:HCBrelCoverData.syncarr,
                                    ciactiveonlyempty:HCBrelCoverData.ddselectedvalues
                                }" 
                                v-if="showHCBRelData == true">
                            </relationdependent>
                        </div>

                        <v-divider class="mx-2"></v-divider>
                        <v-layout style="flex:1" justify-end class="my-4">
                            <v-btn dark tile  @click="clearHCB()" style="font-size:12px;background-color:#e46a25" class="text-capitalize px-3 mx-2" small><v-icon dark small>close</v-icon> <v-divider vertical class="mx-2"></v-divider>Clear</v-btn>
                            <v-btn dark tile  @click="submitHCBHCBACI('hospCashBen')" style="font-size:12px;background-color:#23B1A9" class="text-capitalize px-3 mx-2" small><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider> Save</v-btn>
                            <v-btn   @click="nxt(nextdrive[3])" dark tile  style="font-size:12px;background-color:#152F38" 
                            class="text-capitalize px-3 mx-2" small><v-icon dark small>arrow_forward</v-icon>
                            <v-divider vertical class="mx-2"></v-divider>Next</v-btn>

                        </v-layout>
                    </v-form>
                </div>
            </div>

    <!-- 3.hed -->

            <div class="panelis" dense v-if="showHedAccor === true">
                <div  @click="currentis(nextdrive[4])" expand-icon="mdi-menu-down" class="font-weight-bold panhead" >
                    High End Diagnostics   <span> <v-icon  :style="{border: datais[nextdrive[4].shown == 'y' ? '5px red solid':'none' ] }"  >arrow_drop_down</v-icon> </span> 
                </div>
                <div  class="pa-2"  v v-if="datais[nextdrive[4].shown]"  >
                    <v-divider></v-divider>
                    <v-form v-model="hedvalid" ref="hedForm">  
                    <v-layout class="mt-3" style="align-items:flex-end" wrap>
                        <v-flex xs3 >
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">Coverage Alternate Name</p>
                                <v-text-field
                                    :rules="alphaRule"
                                    solo
                                    label="Enter..."
                                    v-model="HEDCoverAltName"
                                    class="rounded-0"
                                    :maxlength="max200"
                                    >
                                </v-text-field>
                            </v-layout>
                        </v-flex>

                    <v-flex xs3 >
                        <v-layout column class="cust_pln_cd mx-2 ">
                            <p class="font-weight-bold cstm-clr-pln-cd">
                                Coverage Type
                            </p>
                            <v-select
                            :items="cvrgTypItems"
                            v-model="hedcoverageType"
                            label="Select"
                            solo
                            :rules="[required()]"
                            class="rounded-0"
                        ></v-select>
                        </v-layout>
                    </v-flex>

                <v-flex xs3 >
                    <v-layout column class="cust_pln_cd mx-2 ">
                        <p class="font-weight-bold cstm-clr-pln-cd">
                            Coverage Basis
                        </p>
                        <v-select
                            :items="cvrgBasisItems"
                            v-model="hedcoverageBasis"
                            label="Select"
                            
                            solo
                            :rules="[required()]"
                            
                            class="rounded-0"
                            @change="hedchangeOnover(1)"
                        ></v-select>
                    </v-layout>
                </v-flex>

                <v-flex xs3 v-if="hedsubBasisshow==true">
                    <v-layout column class="cust_pln_cd mx-2 ">
                        <p class="font-weight-bold cstm-clr-pln-cd">
                            Sub Limit Basis
                        </p>
                        <v-select
                            :items="cvrgSbLmtBasisItems"
                            v-model="hedsubLimitBasis"
                            label="Select"
                            
                            solo
                            multiple
                            @change="hedflatchange()"
                            :rules="[required()]"
                            class="rounded-0"
                        >
                        <template v-slot:selection="{ item, index }">
                                   
                                    <span v-if="index === 0" >{{ item }}</span>
                                   
                                    <span
                                    v-if="index === 1"
                                    class="grey--text caption"
                                    >
                                    (+{{ hedsubLimitBasis.length - 1 }} )
                                    </span>
                                </template>
                        </v-select>
                    </v-layout>
                </v-flex>
                 <v-flex xs3 v-if="shwhedPerSI===true">
                            <v-layout column class="cust_pln_cd mx-2">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                    % of SI
                                </p>
                                <v-text-field
                                    label="Enter..."
                                    v-model="hedPerSI"
                                    solo
                                    class="rounded-0"
                                    :maxlength="max10"
                                    :rules="[required(),numberRule,decimalRule()]"
                                    >
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                <v-flex xs3 v-if="shwhedFltAmt===true">
                            <v-layout column class="cust_pln_cd mx-2 cust-pln-cd">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                    Flat Amount
                                </p>
                                <v-text-field
                                    label="Enter..."
                                    v-model="hedFlatAmt"
                                    solo
                                    class="rounded-0"
                                    :maxlength="max10"
                                    :rules="[required(),alphaNumeric()]"
                                    >
                                <div slot="prepend-inner" style="background-color:#F7F7F7;padding: 9px !important"><v-icon >mdi-currency-inr </v-icon></div>
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3 v-if="shwhedPrvPerSI===true">
                            <v-layout column class="cust_pln_cd mx-2">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                   % of previous yr SI
                                </p>
                                <v-text-field
                                    label="Enter..."
                                    v-model="hedPrvPerSI"
                                    solo
                                    class="rounded-0"
                                    :maxlength="max10"
                                    :rules="[required(),numberRule,decimalRule()]"
                                    >
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3 v-if="shwhedClmAmt===true">
                            <v-layout column class="cust_pln_cd mx-2">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                   % of claim amount
                                </p>
                                <v-text-field
                                    label="Enter..."
                                    v-model="hedClmAmt"
                                    solo
                                    class="rounded-0"
                                    :maxlength="max10"
                                   :rules="[required(),numberRule,decimalRule()]"
                                >
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3 v-if="showHEDOpdSi == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">% of OPD SI</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="HEDOpdSi"
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),numberRule,decimalRule()]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="showHEDAdSi == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">% of AD SI</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="HEDAdSi"
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),numberRule,decimalRule()]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>

                        <v-flex xs3 v-if="showhedacpo===true">
                            <v-layout column class="cust_pln_cd mx-2">
                                <p class="font-weight-bold cstm-clr-pln-cd">Applicable Claim Pay-Out</p>
                                <v-select
                                    :items="selectApplicableClaimPayOut"
                                    v-model="hedapplicableClaimPayOut"
                                    label="Select"
                                    solo
                                    :rules="[required()]"
                                    class="rounded-0">
                                </v-select>
                            </v-layout>
                        </v-flex>

                <v-flex xs3 v-if="hedsubBasisshow==true">
                    <v-layout column class="cust_pln_cd mx-2">
                        <p class="font-weight-bold cstm-clr-pln-cd">
                           Claim Payment Basis
                        </p>
                        <v-select
                            :items="ClmpymntBasisItems"
                            v-model="hedclaimPaymentBasis"
                            label="Select"
                            solo
                            @change="chnghedClaimPaymentBasis()"
                            :rules="[required()]"
                            class="rounded-0"
                        >
                        </v-select>
                    </v-layout>
                </v-flex>
                        <v-flex xs3 v-if="shwhedCvrgAplcblDrtn===true">
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                   Coverage applicable duration 
                                </p>
                                <v-select
                                    :items="hacCadItms"
                                    label="Select"
                                    v-model="hedCad"
                                    solo
                                    class="rounded-0"
                                    :rules="[required()]"
                                    >
                                </v-select>
                            </v-layout>
                        </v-flex>

                    <v-flex xs3>
                        <v-layout column class="cust_pln_cd mx-2 ">
                            <p class="font-weight-bold cstm-clr-pln-cd">Waiting Period</p>
                            <v-layout>
                                <v-select
                                    :items="['Days','Months']"
                                    label="Select"
                                    v-model="hedwaitingPeriodDrop"
                                    solo
                                    style="width: 145px"
                                    class="rounded-0"
                                    :rules="[required()]"
                                    >
                                </v-select>
                                <v-text-field
                                    label="Enter..."
                                    v-model="hedwaitingPeriod"
                                    solo
                                    class="rounded-0"
                                    :maxlength="max10"
                                    :rules="[Numeric2]"
                                    >
                                </v-text-field>
                            </v-layout>
                        </v-layout>
                    </v-flex>
                <v-flex xs3 >    
                    <v-layout column class=" cust_pln_cd mx-2 ">
                        <p class="font-weight-bold cstm-clr-pln-cd text-truncate">Allowed Diagnostic Tests</p>
                            <v-select
                                :items="selectallowedDiaTests"
                                v-model="hedallowedDiaTests"
                                label="Select"
                                solo
                                multiple
                                class="rounded-0">
                                <template v-slot:selection="{ item, index }">
                                    <span v-if="index === 0" >{{ item }}</span>
                                    <span v-if="index === 1" class="grey--text caption">(+{{ hedallowedDiaTests.length - 1 }})</span>
                                </template>
                        </v-select>
                    </v-layout>
                </v-flex>
                </v-layout>

                <v-divider></v-divider>
                <!-- NEW FIELDS ADDED HED-->
                <v-layout class="mt-3" wrap style="align-items:flex-end">
                    <v-flex xs3>
                        <v-layout column class="cust_pln_cd mx-2 ">
                            <p class="font-weight-bold cstm-clr-pln-cd">IRDA UIN Number</p>
                            <v-text-field
                                solo
                                label="Enter..."
                                v-model="HEDIrdaUINNo"
                                class="rounded-0"
                                :maxlength="max20"
                                :rules="spclCharNew">
                            </v-text-field>
                        </v-layout>
                    </v-flex>
                    <v-flex xs3>
                        <v-layout column class="cust_pln_cd mx-2 ">
                            <p class="font-weight-bold cstm-clr-pln-cd">External Reference Number</p>
                            <v-text-field
                                solo
                                label="Enter..."
                                v-model="HEDExtRefNo"
                                class="rounded-0"
                                :maxlength="max30"
                                :rules="spclCharNew">
                            </v-text-field>
                        </v-layout>
                    </v-flex>
                    <v-flex xs3>
                        <v-layout column class="cust_pln_cd mx-2 ">
                            <p class="font-weight-bold cstm-clr-pln-cd">Conditions</p>
                            <v-select
                                :items="prodConditionArray"
                                label="Select"
                                v-model="HEDCondField"
                                multiple
                                solo
                                class="rounded-0"
                                >
                            </v-select>
                        </v-layout>
                    </v-flex>
                    <v-flex xs3>
                        <v-layout column class="cust_pln_cd mx-2 ">
                            <p class="font-weight-bold cstm-clr-pln-cd">GC Coverage</p>
                            <v-select
                                :items="prodGCCoverArray"
                                label="Select"
                                v-model="HEDGcCover"
                                solo
                                class="rounded-0"
                                :rules="[required()]">
                            </v-select>
                        </v-layout>
                    </v-flex>
                    <v-flex xs3>
                        <v-layout column class="cust_pln_cd mx-2 ">
                            <p class="font-weight-bold cstm-clr-pln-cd">Remarks</p>
                            <v-text-field
                                solo
                                label="Enter..."
                                v-model="HEDRemarks"
                                class="rounded-0"
                                :maxlength="max40"
                                >
                            </v-text-field>
                        </v-layout>
                    </v-flex>
                    <v-flex xs3>
                        <v-layout column class="cust_pln_cd mx-2 ">
                            <p class="font-weight-bold cstm-clr-pln-cd">Exclusions</p>
                            <v-select
                                :items="prodExcluArray"
                                :maxlength="max30"
                                label="Select"
                                v-model="HEDExclus"
                                solo
                                class="rounded-0"
                                multiple
                                >
                            </v-select>
                        </v-layout>
                    </v-flex>
                </v-layout>
                <v-divider></v-divider>
                <v-flex xs3 >
                    <v-layout column class="mx-2 mb-7  cust_pln_cd" justify-center >
                       
                        <span class="font-weight-bold cstm-clr-pln-cd" style="color:#FFFFFF" > </span>
                        <div style="background-color: #f7f7f7;border: 1px solid #c1c8cc;" class="pa-2 mt-4">
                            <v-checkbox class="ma-0 pa-0" v-model="hedisSelectBox" @click="HedRdiSelected" hide-details >
                                <span slot="label" class="lbl-bgclr font-weight-bold" style="font-size: 13px;"> Separate Relationship & Dependent Info</span>
                            </v-checkbox>
                        </div>
                    </v-layout>
                </v-flex> 
                <div v-if="shwHedRdi===true">
                    <relationdependent ref="hedFrmPropUpdted" @save="save_Hed_updated" 
                        :redpndntinfo="{
                            cirelDataemty:tempHeRlcvrd.realdata,
                            ciRelnshipCoveredEmpty:tempHeRlcvrd.dropdowndata,
                            cisyncArryEmpty:tempHeRlcvrd.syncarr,
                            ciactiveonlyempty:tempHeRlcvrd.ddselectedvalues
                        }"  
                        v-if="hereldatashw===true">
                    </relationdependent>
                </div>
                    
                <v-divider></v-divider>
                 <v-layout style="flex:1" justify-end class="my-4">
                    <v-btn dark tile  @click="clearHED()" style="font-size:12px;background-color:#e46a25" class="text-capitalize px-3 mx-2" small><v-icon dark small>close</v-icon> <v-divider vertical class="mx-2"></v-divider>Clear</v-btn>
                    <v-btn dark tile  @click="saveHAHED('hed')" style="font-size:12px;background-color:#23B1A9" class="text-capitalize px-3 mx-2" small><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider> Save</v-btn>
                    <v-btn dark tile @click="nxt(nextdrive[4])" style="font-size:12px;background-color:#152F38"
                    class="text-capitalize px-3 mx-2" small><v-icon dark small>arrow_forward</v-icon>
                    <v-divider vertical class="mx-2"></v-divider>Next</v-btn>

                </v-layout>
                </v-form>
                    

                </div>
                
            </div> 
        
    <!-- 4.ci -->
    <!-- ci99 -->
                <div class="panelis" dense v-if="shwExpnsnCi===true"  >
                <div expand-icon="mdi-menu-down" class="font-weight-bold panhead" @click="currentis(nextdrive[5])" >
                    Critical Illness  <span> <v-icon  :style="{border: datais[nextdrive[5].shown == 'y' ? '5px red solid':'none' ] }"  >arrow_drop_down</v-icon> </span> 
                </div>
                
                <div  class="pa-2" v-if="datais[nextdrive[5].shown]"   >
                    <v-divider></v-divider>
                    <v-form v-model="civalid" ref="ciForm">  
                    <v-layout class="mt-3"  wrap style="align-items:flex-end">
                        <v-flex xs3 >
                        <v-layout column class="cust_pln_cd mx-2 ">
                            <p class="font-weight-bold cstm-clr-pln-cd">
                                Coverage Type
                            </p>
                            <v-select
                            :items="cvrgTypItems"
                            v-model="cicoverageType"
                            label="Select"
                            
                            solo
                            :rules="[required()]"
                            
                            class="rounded-0"
                        ></v-select>
                        </v-layout>
                    </v-flex>

                <v-flex xs3 >
                    <v-layout column class="cust_pln_cd mx-2 ">
                        <p class="font-weight-bold cstm-clr-pln-cd">
                            Coverage Basis
                        </p>
                        <v-select
                            :items="cvrgBasisItems"
                            v-model="cicoverageBasis"
                            label="Select"
                            
                            solo
                            :rules="[required()]"
                            
                            class="rounded-0"
                            @change="cichangeOnover(1)"
                        ></v-select>
                    </v-layout>
                </v-flex>

                <!-- :error-messages="cicoverageBasis_chk"
                        @blur="$v.cicoverageBasis.$touch()" -->

                <v-flex xs3 v-if="cisubBasisshow==true">
                    <v-layout column class="cust_pln_cd mx-2 ">
                        <p class="font-weight-bold cstm-clr-pln-cd">
                            Sub Limit Basis
                        </p>
                        <v-select
                            :items="cvrgSbLmtBasisItems"
                            v-model="cisubLimitBasis"
                            label="Select"
                            
                            solo
                            multiple
                            @change="chngCiSubLmtBasis()"
                            class="rounded-0"
                            :rules="[required()]"
                        >
                         <template v-slot:selection="{ item, index }">
                                   
                                    <span v-if="index === 0" >{{ item }}</span>
                                   
                                    <span
                                    v-if="index === 1"
                                    class="grey--text caption"
                                    >
                                    (+{{ cisubLimitBasis.length - 1 }})
                                    </span>
                                </template>
                        </v-select>
                    </v-layout>
                </v-flex>
                 <v-flex xs3 v-if="shwCiPrcntSi===true">
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                   % of SI
                                </p>
                                 <v-text-field
                                    label="Enter..."
                                    v-model="ciPrcntSi"
                                    solo
                                    class="rounded-0"
                                    :maxlength="max10"
                                    :rules="[required(),numberRule,decimalRule()]"
                                    >
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                <v-flex xs3  v-if="shwciFltAmt===true">
                            <v-layout column class="cust_pln_cd mx-2 cust-pln-cd">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                    Flat Amount
                                </p>
                                <v-text-field
                                    label="Enter..."
                                    v-model="ciFlatAmt"
                                    solo
                                    class="rounded-0"
                                    :maxlength="max10"
                                    :rules="[required(),alphaNumeric()]"
                                    >
                                <div slot="prepend-inner" style="background-color:#F7F7F7;padding: 9px !important"><v-icon >mdi-currency-inr </v-icon></div>
                                </v-text-field>
                            </v-layout>
                </v-flex>
                <v-flex xs3 v-if="shwCiPrcntPrvSi===true">
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                   % of previous yr SI
                                </p>
                                 <v-text-field
                                    label="Enter..."
                                    v-model="ciPrcntPrvSi"
                                    solo
                                    class="rounded-0"
                                    :maxlength="max10"
                                    :rules="[required(),numberRule,decimalRule()]"
                                    >
                                </v-text-field>
                            </v-layout>
                        </v-flex>
               
                        <v-flex xs3 v-if="shwCiPrcntClmAmt===true">
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                  % of claim amount
                                </p>
                                 <v-text-field
                                    label="Enter..."
                                    v-model="ciPrcntClmAmt"
                                    solo
                                    class="rounded-0"
                                    :maxlength="max10"
                                    :rules="[required(),numberRule,decimalRule()]"
                                    >
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3 v-if="shwCiAplcblClmPyot===true">
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                    Applicable claim pay-out
                                </p>
                                <v-select
                                    :items="hacAplcblClmPyotItms"
                                    label="Select"
                                    v-model="ciAplcblClmPyot"
                                    solo
                                    class="rounded-0"
                                    :rules="[required()]"
                                    >
                                </v-select>
                            </v-layout>
                        </v-flex>
                    <!-- <v-divider  class="ma-2" vertical ></v-divider> -->

                <v-flex xs3 v-if="cisubBasisshow==true">
                    <v-layout column class="cust_pln_cd mx-2">
                        <p class="font-weight-bold cstm-clr-pln-cd">
                            Claim Payment Basis
                        </p>
                        <v-select
                            :items="ClmpymntBasisItems"
                            v-model="ciclaimPaymentBasis"
                            @change="chngCiClaimPaymentBasis()"
                            label="Select"
                            
                            solo
                            :rules="[required()]"
                            class="rounded-0"
                        >
                        </v-select>
                    </v-layout>
                </v-flex>
                <v-flex xs3 v-if="shwCiCvrgAplcblDrtn===true">
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                   Coverage applicable duration (No of years)
                                </p>
                                <v-select
                                    :items="hacCadItms"
                                    label="Select"
                                    v-model="ciCad"
                                    solo
                                    class="rounded-0"
                                    :rules="[required()]"
                                    
                                    >
                                </v-select>
                            </v-layout>
                        </v-flex>
                    <!-- <v-divider  class="ma-2" vertical ></v-divider> -->

                <v-flex xs3 >
                    <v-layout column class="cust_pln_cd mx-2">
                        <p class="font-weight-bold cstm-clr-pln-cd">
                            Covered Critical Illness
                        </p>
                        <v-select
                            :items="selectCriticalIllness"
                            v-model="ciCoveredCriticalIllness"
                            label="Select"
                            
                            solo
                            multiple
                            :rules="[required()]"
                           
                            class="rounded-0"
                        >
                         <template v-slot:selection="{ item, index }">
                                   
                                    <span v-if="index === 0" >{{ item }}</span>
                                   
                                    <span
                                    v-if="index === 1"
                                    class="grey--text caption"
                                    >
                                    (+{{ ciCoveredCriticalIllness.length - 1 }} )
                                    </span>
                                </template>
                        </v-select>
                    </v-layout>
                </v-flex>

                
                        
                    
                <v-flex xs3 >
                        <v-layout column class="cust_pln_cd mx-2">
                            <p class="font-weight-bold cstm-clr-pln-cd">
                                Waiting Period
                            </p>
                            <v-text-field
                                solo
                                
                                label="Enter..."
                                v-model="ciwaitingPeriod"
                                class="rounded-0"
                                :maxlength="max10"
                                :rules="[Numeric2]"
                                 
                            >
                            </v-text-field>
                        </v-layout>
                    </v-flex>
                      <!-- :error-messages="ciwaitingPeriod_chk"
                        @blur="$v.ciwaitingPeriod.$touch()"
                                 -->

                    <v-flex xs3 >
                        <v-layout column class="cust_pln_cd mx-2 ">
                            <p class="font-weight-bold cstm-clr-pln-cd">
                                Survival Period(No. of days)
                            </p>
                            <v-text-field
                                solo
                                
                                label="Enter..."
                                v-model="ciSurvivalPeriod"
                                class="rounded-0"
                                :maxlength="max10"
                                :rules="[required(),alphaNumeric()]"
                                 
                            >
                            </v-text-field>
                        </v-layout>
                    </v-flex>
                <v-flex xs3 >
                    <v-layout column class="mx-2 mb-7 cust_pln_cd" justify-center >
                       
                        <span class="font-weight-bold cstm-clr-pln-cd" style="color:#FFFFFF" > </span>
                        <div style="background-color: #f7f7f7;border: 1px solid #c1c8cc;" class="pa-2 mt-5">
                            <v-checkbox class="ma-0 pa-0" v-model="ciisSelectBox" @click="ciRdiSelected" hide-details >
                                <span slot="label" class="lbl-bgclr font-weight-bold" style="font-size: 13px;"> Separate Relationship & Dependent Info</span>
                            </v-checkbox>
                        </div>
                    </v-layout>
                </v-flex>
                                  
                   
                    </v-layout>
                    <div v-if="shwCiRdi===true">
                    <relationdependent ref="ciFrmPropUpdted" @save="save_CI_updated" 
                        :redpndntinfo="{
                            cirelDataemty:tempCiRlcvrd.realdata,
                            ciRelnshipCoveredEmpty:tempCiRlcvrd.dropdowndata,
                            cisyncArryEmpty:tempCiRlcvrd.syncarr,
                            ciactiveonlyempty:tempCiRlcvrd.ddselectedvalues
                        }"  
                        v-if="cireldatashw===true"
                    >
                    </relationdependent>
                </div> 
                    
                <!-- <v-divider></v-divider> -->
                 <v-layout style="flex:1" justify-end class="my-4">
                   <v-btn dark tile  @click="save_GcCi('CI')" style="font-size:12px;background-color:#23B1A9" class="text-capitalize px-3 mx-2" small><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider> Save</v-btn>
                    <v-btn dark tile @click="nxt(nextdrive[5])" style="font-size:12px;background-color:#152F38"
                    class="text-capitalize px-3 mx-2" small><v-icon dark small>arrow_forward</v-icon>
                    <v-divider vertical class="mx-2"></v-divider>Next</v-btn>

                </v-layout>
                </v-form>
                </div>
            </div>
         
    <!-- 5.plh -->
    
        <div class="panelis" v-if="showPHAccor == true" dense>
                <div expand-icon="mdi-menu-down" class="font-weight-bold panhead" @click="currentis(nextdrive[6])" >
                    Prolonged Hospitalization  <span> <v-icon  :style="{border: datais[nextdrive[6].shown == 'y' ? '5px red solid':'none' ] }"  >arrow_drop_down</v-icon> </span> 
                </div>
                
                <div  class="pa-2"   v-if="datais[nextdrive[6].shown]" >
                    <v-divider></v-divider>
                    <v-form v-model="plhvalid" ref="plhForm">  
                        <v-layout class="mt-3" style="align-items:flex-end" wrap>
                            <v-flex xs3 >
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Coverage Alternate Name</p>
                                    <v-text-field
                                        :rules="alphaRule"
                                        solo
                                        label="Enter..."
                                        v-model="PHCoverAltName"
                                        class="rounded-0"
                                        :maxlength="max200"
                                        >
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 >
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Coverage Type
                                    </p>
                                    <v-select
                                    :items="cvrgTypItems"
                                    v-model="plhcoverageType"
                                    label="Select"
                                    
                                    solo
                                    :rules="[required()]"
                                    
                                    class="rounded-0"
                                ></v-select>
                                </v-layout>
                            </v-flex>

                            <v-flex xs3 >
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Coverage Basis
                                    </p>
                                    <v-select
                                        :items="cvrgBasisItems"
                                        v-model="plhcoverageBasis"
                                        label="Select"
                                        
                                        solo
                                        :rules="[required()]"
                                        
                                        class="rounded-0"
                                        @change="plhchangeOnover(1)"
                                    ></v-select>
                                </v-layout>
                            </v-flex>

                            <v-flex xs3 v-if="plhsubBasisshow==true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Sub Limit Basis
                                    </p>
                                    <v-select
                                        :items="cvrgSbLmtBasisItems"
                                        v-model="plhsubLimitBasis"
                                        label="Select"
                                        
                                        solo
                                        multiple
                                        @change="plhSIchange(plhsubLimitBasis)"
                                        class="rounded-0"
                                        :rules="[required()]"
                                    >
                                     <template v-slot:selection="{ item, index }">
                                   
                                    <span v-if="index === 0" >{{ item }}</span>
                                   
                                    <span
                                    v-if="index === 1"
                                    class="grey--text caption"
                                    >
                                    (+{{ plhsubLimitBasis.length - 1 }})
                                    </span>
                                </template>
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="plhSIshow == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">% of SI</p>
                                    <v-text-field
                                        solo
                                        
                                        label="Enter..."
                                        v-model="plhperSI"
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),numberRule,decimalRule()]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="showPHFlatAmnt == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Flat Amount</p>
                                    <v-text-field
                                        solo
                                        
                                        label="Enter..."
                                        v-model="PHFlatAmountTxtField"
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),alphaNumeric()]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="plhPrvSIshow == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">% of previous yr SI</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="plhPrvperSI"
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),numberRule,decimalRule()]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="showPHPercClaimAmt == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">% of Claim Amount</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="PHPercentClaimAmt"
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),numberRule,decimalRule()]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="showPHOpdSi == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">% of OPD SI</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="PHOpdSi"
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),numberRule,decimalRule()]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="showPHAdSi == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">% of AD SI</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="PHAdSi"
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),numberRule,decimalRule()]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="showPHApplicClaimPay == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Applicable Claim Pay-Out</p>
                                    <v-select
                                        :items="hacAplcblClmPyotItms"
                                        label="Select"
                                        v-model="PHApplicClaimPayOut"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]"
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                        
                            <v-flex xs3 v-if="plhsubBasisshow==true">
                                <v-layout column class="cust_pln_cd mx-2">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Claim Payment Basis
                                    </p>
                                    <v-select
                                        :items="ClmpymntBasisItems"
                                        v-model="plhclaimPaymentBasis"
                                        label="Select"
                                        solo
                                        @change="changePHClaimPayBasis(plhclaimPaymentBasis)"
                                        :rules="[required()]"
                                        class="rounded-0"
                                    >
                                    </v-select>
                                </v-layout>
                            </v-flex>

                            <v-flex xs3 v-if="showCovrageApplicDur == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Coverage applicable duration (No of years)
                                    </p>
                                    <v-select
                                        :items="hacCadItms"
                                        label="Select"
                                        v-model="PHCovrageApplicDur"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]"
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                        
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Waiting Period</p>
                                    <v-layout>
                                        <v-select
                                            :items="['Days','Months']"
                                            label="Select"
                                            v-model="plhwaitingPeriodDrop"
                                            solo
                                            style="width: 145px"
                                            class="rounded-0"
                                            :rules="[required()]"
                                            >
                                        </v-select>
                                        <v-text-field
                                            label="Enter..."
                                            v-model="plhwaitingPeriod"
                                            solo
                                            class="rounded-0"
                                            :maxlength="max10"
                                            :rules="[Numeric2]"
                                            >
                                        </v-text-field>
                                    </v-layout>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 >
                                <v-layout column class="cust_pln_cd mx-2">
                                    <p class="font-weight-bold cstm-clr-pln-cd ">
                                        Minimum Hospitalization Duration for Eligible Claim(No. of Days)
                                    </p>
                                    <v-text-field
                                        solo
                                        
                                        label="Enter..."
                                        v-model="plhminHosDuration"
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[Numeric2]"
                                        
                                    >
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                        </v-layout>
                        <v-divider></v-divider>
                        <!-- NEW FIELDS ADDED PH-->
                        <v-layout class="mt-3" wrap style="align-items:flex-end">
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">IRDA UIN Number</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="PHIrdaUINNo"
                                        class="rounded-0"
                                        :maxlength="max20"
                                        :rules="spclCharNew">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">External Reference Number</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="PHExtRefNo"
                                        class="rounded-0"
                                        :maxlength="max30"
                                        :rules="spclCharNew">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Conditions</p>
                                    <v-select
                                        :items="prodConditionArray"
                                        label="Select"
                                        v-model="PHCondField"
                                        solo
                                        multiple
                                        class="rounded-0"
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">GC Coverage</p>
                                    <v-select
                                        :items="prodGCCoverArray"
                                        label="Select"
                                        v-model="PHGcCover"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]">
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Remarks</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="PHRemarks"
                                        class="rounded-0"
                                        :maxlength="max40"
                                        >
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Exclusions</p>
                                    <v-select
                                        :items="prodExcluArray"
                                        :maxlength="max30"
                                        label="Select"
                                        v-model="PHExclus"
                                        solo
                                        class="rounded-0"
                                        multiple
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                        </v-layout>

                        <v-divider></v-divider>
                        <v-flex xs3 >
                                <v-layout column class="mx-2 mb-6 cust_pln_cd" justify-center >
                                    <v-text class="font-weight-bold cstm-clr-pln-cd" style="color:#FFFFFF" > </v-text>
                                    <div style="background-color: #f7f7f7;border: 1px solid #c1c8cc;" class="pa-2 mt-5">
                                        <v-checkbox class="ma-0 pa-0" v-model="plhisSelectBox" @change="PHRelCheckSelected" hide-details >
                                            <span slot="label" class="lbl-bgclr font-weight-bold" style="font-size: 13px;"> Separate Relationship & Dependent Info</span>
                                        </v-checkbox>
                                    </div>
                                </v-layout>
                            </v-flex> 
                        <div v-if="showRelSection == true">
                            <relationdependent 
                                ref="PHRel" 
                                @save="saveDCPHRel" 
                                :redpndntinfo="{
                                    cirelDataemty:PHrelCoverData.realdata,
                                    ciRelnshipCoveredEmpty:PHrelCoverData.dropdowndata,
                                    cisyncArryEmpty:PHrelCoverData.syncarr,
                                    ciactiveonlyempty:PHrelCoverData.ddselectedvalues
                                }" 
                                v-if="showPHRelData == true">
                            </relationdependent>
                        </div>
                        
                        <v-divider></v-divider>
                        <v-layout style="flex:1" justify-end class="my-4">
                            <v-btn dark tile  @click="clearPH()" style="font-size:12px;background-color:#e46a25" class="text-capitalize px-3 mx-2" small><v-icon dark small>close</v-icon> <v-divider vertical class="mx-2"></v-divider>Clear</v-btn>
                            <v-btn dark tile  @click="submitDCPH('prolongHospital')" style="font-size:12px;background-color:#23B1A9" class="text-capitalize px-3 mx-2" small><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider> Save</v-btn>
                            <v-btn dark tile @click="nxt(nextdrive[6])"  style="font-size:12px;background-color:#152F38"
                            class="text-capitalize px-3 mx-2" small><v-icon dark small>arrow_forward</v-icon>
                            <v-divider vertical class="mx-2"></v-divider>Next</v-btn>
                        </v-layout>
                    </v-form>
                </div>
                
            </div>

    <!-- 6.odt -->
     <div class="panelis" dense v-if="shwExpnsnOpdDT===true">
                <div  @click="currentis(nextdrive[7])" expand-icon="mdi-menu-down" class="font-weight-bold panhead" >
                    OPD Dental Treatment   <span> <v-icon  :style="{border: datais[nextdrive[7].shown == 'y' ? '5px red solid':'none' ] }"  >arrow_drop_down</v-icon> </span> 
                </div>
                
                <div  class="pa-2"  v v-if="datais[nextdrive[7].shown]" >
                    <v-divider></v-divider>
                    <v-form v-model="odtvalid" ref="odtForm">  
                        <v-layout class="mt-3" wrap style="align-items:flex-end" >
                            <v-flex xs3 >
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Coverage Alternate Name</p>
                                    <v-text-field
                                        :rules="alphaRule"
                                        solo
                                        label="Enter..."
                                        v-model="ODTCoverAltName"
                                        class="rounded-0"
                                        :maxlength="max200"
                                        >
                                    </v-text-field>
                                </v-layout>
                            </v-flex>

                            <v-flex xs3 >
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Coverage Type
                                    </p>
                                    <v-select
                                    :items="cvrgTypItems"
                                    v-model="odtcoverageType"
                                    label="Select"
                                    solo
                                    :rules="[required()]"
                                    class="rounded-0"
                                ></v-select>
                                </v-layout>
                            </v-flex>

                            <v-flex xs3 >
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Coverage Basis
                                    </p>
                                    <v-select
                                        :items="cvrgBasisItems"
                                        v-model="odtcoverageBasis"
                                        label="Select"
                                        solo
                                        :rules="[required()]"
                                        class="rounded-0"
                                        @change="odtchangeOnover(1)"
                                    ></v-select>
                                </v-layout>
                            </v-flex>

                            <v-flex xs3 v-if="odtsubBasisshow==true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Sub Limit Basis
                                    </p>
                                    <v-select
                                        :items="cvrgSbLmtBasisItems"
                                        v-model="odtsubLimitBasis"
                                        label="Select"
                                        solo
                                        multiple
                                        @change="chngOdtSubLmtBasis()"
                                        class="rounded-0"
                                        :rules="[required()]">
                                        <template v-slot:selection="{ item, index }">
                                            <span v-if="index === 0" >{{ item }}</span>
                                            <span
                                            v-if="index === 1"
                                            class="grey--text caption"
                                            >
                                            (+{{ odtsubLimitBasis.length - 1 }} )
                                            </span>
                                        </template>
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="shwodtPrcntSi===true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                    % of SI
                                    </p>
                                    <v-text-field
                                        label="Enter..."
                                        v-model="odtPrcntSi"
                                        solo
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),numberRule,decimalRule()]"
                                        >
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="odtflatshow==true">
                                <v-layout column class="cust-pln-cd mx-2 cust_pln_cd">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Flat Amount
                                    </p>
                                    <v-text-field
                                            solo
                                            label="for Eligible Claim(No.of Hours)"
                                            v-model="odtflatAmount"
                                            class="rounded-0"
                                            :maxlength="max10"
                                            :rules="[required(),alphaNumeric()]">
                                        <div slot="prepend-inner" style="background-color:#F7F7F7;padding: 9px !important"><v-icon >mdi-currency-inr </v-icon></div>
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="shwodtPrcntPrvSi===true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                    % of previous yr SI
                                    </p>
                                    <v-text-field
                                        label="Enter..."
                                        v-model="odtPrcntPrvSi"
                                        solo
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),numberRule,decimalRule()]"
                                        >
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="shwodtPrcntClmAmt===true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                    % of claim amount
                                    </p>
                                    <v-text-field
                                        label="Enter..."
                                        v-model="odtPrcntClmAmt"
                                        solo
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),numberRule,decimalRule()]"
                                        >
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="shwodtAplcblClmPyot===true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Applicable claim pay-out
                                    </p>
                                    <v-select
                                        :items="hacAplcblClmPyotItms"
                                        label="Select"
                                        v-model="odtAplcblClmPyot"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]"
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex>

                            <v-flex xs3 v-if="odtsubBasisshow==true">
                                <v-layout column class="cust_pln_cd mx-2">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Claim Payment Basis
                                    </p>
                                    <v-select
                                        :items="ClmpymntBasisItems"
                                        v-model="odtclaimPaymentBasis"
                                        label="Select"
                                        @change="chngOdtClaimPaymentBasis()"
                                        solo
                                        :rules="[required()]"
                                        class="rounded-0"
                                    >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                        
                            <v-flex xs3 v-if="shwodtCvrgAplcblDrtn===true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                    Coverage applicable duration (No of years)
                                    </p>
                                    <v-select
                                        :items="hacCadItms"
                                        label="Select"
                                        v-model="odtCad"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]"
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Waiting Period</p>
                                    <v-layout>
                                        <v-select
                                            :items="['Days','Months']"
                                            label="Select"
                                            v-model="odtwaitingPeriodDrop"
                                            solo
                                            style="width: 145px"
                                            class="rounded-0"
                                            :rules="[required()]"
                                            >
                                        </v-select>
                                        <v-text-field
                                            label="Enter..."
                                            v-model="odtwaitingPeriod"
                                            solo
                                            class="rounded-0"
                                            :maxlength="max10"
                                            :rules="[Numeric2]"
                                            >
                                        </v-text-field>
                                    </v-layout>
                                </v-layout>
                            </v-flex>
                        </v-layout>

                        <v-divider></v-divider>
                        <!-- NEW FIELDS ADDED OPD Dental Treatment-->
                        <v-layout class="mt-3" wrap style="align-items:flex-end">
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">IRDA UIN Number</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="ODTIrdaUINNo"
                                        class="rounded-0"
                                        :maxlength="max20"
                                        :rules="spclCharNew">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">External Reference Number</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="ODTExtRefNo"
                                        class="rounded-0"
                                        :maxlength="max30"
                                        :rules="spclCharNew">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Conditions</p>
                                    <v-select
                                        :items="prodConditionArray"
                                        label="Select"
                                        v-model="ODTCondField"
                                        solo
                                        multiple
                                        class="rounded-0"
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">GC Coverage</p>
                                    <v-select
                                        :items="prodGCCoverArray"
                                        label="Select"
                                        v-model="ODTGcCover"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]">
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Remarks</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="ODTRemarks"
                                        class="rounded-0"
                                        :maxlength="max40"
                                        >
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Exclusions</p>
                                    <v-select
                                        :items="prodExcluArray"
                                        :maxlength="max30"
                                        label="Select"
                                        v-model="ODTExclus"
                                        solo
                                        class="rounded-0"
                                        multiple
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                        </v-layout>
                        
                        <v-divider></v-divider>
                        <v-flex xs3 >
                            <v-layout column class="mx-2 mb-6 cust_pln_cd" justify-center >
                                <v-text class="font-weight-bold cstm-clr-pln-cd" style="color:#FFFFFF" > </v-text>
                                <div style="background-color: #f7f7f7;border: 1px solid #c1c8cc;" class="pa-2 mt-5">
                                    <v-checkbox class="ma-0 pa-0" v-model="ODTSelectBox" @change="ODTRelCheckSelected" hide-details >
                                        <span slot="label" class="lbl-bgclr font-weight-bold" style="font-size: 13px;"> Separate Relationship & Dependent Info</span>
                                    </v-checkbox>
                                </div>
                            </v-layout>
                        </v-flex> 
                        
                        <div v-if="showODTRelSection == true">
                            <relationdependent 
                                ref="ODTRel" 
                                @save="saveOthcotdea" 
                                :redpndntinfo="{
                                    cirelDataemty:ODTrelCoverData.realdata,
                                    ciRelnshipCoveredEmpty:ODTrelCoverData.dropdowndata,
                                    cisyncArryEmpty:ODTrelCoverData.syncarr,
                                    ciactiveonlyempty:ODTrelCoverData.ddselectedvalues
                                }" 
                                v-if="showODTRelData == true">
                            </relationdependent>
                        </div>
                        <v-divider></v-divider>
                        <v-layout style="flex:1" justify-end class="my-4">
                            <v-btn dark tile @click="clearOPDDent()" style="font-size:12px;background-color:#e46a25" class="text-capitalize px-3 mx-2" small><v-icon dark small>close</v-icon> <v-divider vertical class="mx-2"></v-divider> Clear</v-btn>
                            <v-btn dark tile @click="submitOthcotdea('OPDDT')" style="font-size:12px;background-color:#23B1A9" class="text-capitalize px-3 mx-2" small><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider> Save</v-btn>
                            <v-btn dark tile @click="nxt(nextdrive[7])"  style="font-size:12px;background-color:#152F38"
                            class="text-capitalize px-3 mx-2" small><v-icon dark small>arrow_forward</v-icon>
                            <v-divider vertical class="mx-2"></v-divider>Next</v-btn>
                        </v-layout>
                    </v-form>
                    

                </div>
                
            </div>
       
    <!-- 7.eaa -->
    <div class="panelis" dense v-if="shwExpnsnEaa===true">
                <div @click="currentis(nextdrive[8])"   expand-icon="mdi-menu-down" class="font-weight-bold panhead" >
                    Emergency Air Ambulance  <span> <v-icon  :style="{border: datais[nextdrive[8].shown == 'y' ? '5px red solid':'none' ] }"  >arrow_drop_down</v-icon> </span> 
                </div>  
                
                <div  class="pa-2"  v v-if="datais[nextdrive[8].shown]" >
                    <v-divider></v-divider>
                    <v-form v-model="eaavalid" ref="eaaForm">  
                    <v-layout class="mt-3" wrap style="align-items:flex-end" >
                        <v-flex xs3 >
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">Coverage Alternate Name</p>
                                <v-text-field
                                    :rules="alphaRule"
                                    solo
                                    label="Enter..."
                                    v-model="EAACoverAltName"
                                    class="rounded-0"
                                    :maxlength="max200"
                                    >
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3 >
                        <v-layout column class="cust_pln_cd mx-2">
                            <p class="font-weight-bold cstm-clr-pln-cd">
                                Coverage Type
                            </p>
                            <v-select
                            :items="cvrgTypItems"
                            v-model="eaacoverageType"
                            label="Select"
                            solo
                            :rules="[required()]"
                            class="rounded-0"
                        ></v-select>
                        </v-layout>
                    </v-flex>
                   
                <v-flex xs3 >
                    <v-layout column class="cust_pln_cd mx-2 ">
                        <p class="font-weight-bold cstm-clr-pln-cd">
                            Coverage Basis
                        </p>
                        <v-select
                            :items="cvrgBasisItems"
                            v-model="eaacoverageBasis"
                            label="Select"
                            solo
                            :rules="[required()]"
                            class="rounded-0"
                            @change="eaachangeOnover(1)"
                        ></v-select>
                    </v-layout>
                </v-flex>
                <v-flex xs3 v-if="eaasubBasisshow==true">
                    <v-layout column class="cust_pln_cd mx-2 ">
                        <p class="font-weight-bold cstm-clr-pln-cd">
                            Sub Limit Basis
                        </p>
                        <v-select
                            :items="cvrgSbLmtBasisItems"
                            v-model="eaasubLimitBasis"
                            label="Select"
                            solo
                            multiple
                            @change="chngEaaSubLmtBasis()"
                            class="rounded-0"
                            :rules="[required()]"
                        >
                        <template v-slot:selection="{ item, index }">
                                    <span v-if="index === 0" >{{ item }}</span>
                                    <span
                                    v-if="index === 1"
                                    class="grey--text caption"
                                    >
                                    (+{{ eaasubLimitBasis.length - 1 }} )
                                    </span>
                                </template>
                        </v-select>
                    </v-layout>
                </v-flex>
                <v-flex xs3 v-if="shweaaPrcntSi===true">
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                   % of SI
                                </p>
                                 <v-text-field
                                    label="Enter..."
                                    v-model="eaaPrcntSi"
                                    solo
                                    class="rounded-0"
                                    :maxlength="max10"
                                    :rules="[required(),numberRule,decimalRule()]"
                                    >
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                 <v-flex xs3 v-if="eaaflatshow==true">
                    <v-layout column class="mx-2 cust_pln_cd cust-pln-cd">
                        <p class="font-weight-bold cstm-clr-pln-cd">
                            Flat Amount
                        </p>
                        
                        <v-text-field
                                solo
                                label="for Eligible Claim(No.of Hours)"
                                v-model="eaaflatAmount"
                                class="rounded-0"
                                :maxlength="max10"
                                :rules="[required(),alphaNumeric()]"
                            >
                        <div slot="prepend-inner" style="background-color:#F7F7F7;padding: 9px !important"><v-icon >mdi-currency-inr </v-icon></div>
                            </v-text-field>
                    </v-layout>
                </v-flex>
                 <v-flex xs3 v-if="shweaaPrcntPrvSi===true">
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                   % of previous yr SI
                                </p>
                                 <v-text-field
                                    label="Enter..."
                                    v-model="eaaPrcntPrvSi"
                                    solo
                                    class="rounded-0"
                                    :maxlength="max10"
                                    :rules="[required(),numberRule,decimalRule()]"
                                    >
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                
                        <v-flex xs3 v-if="shweaaPrcntClmAmt===true">
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                  % of claim amount
                                </p>
                                 <v-text-field
                                    label="Enter..."
                                    v-model="eaaPrcntClmAmt"
                                    solo
                                    class="rounded-0"
                                    :maxlength="max10"
                                    :rules="[required(),numberRule,decimalRule()]"
                                    >
                                </v-text-field>
                            </v-layout>
                    </v-flex>
                     <v-flex xs3 v-if="shweaaAplcblClmPyot===true">
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                    Applicable claim pay-out
                                </p>
                                <v-select
                                    :items="hacAplcblClmPyotItms"
                                    label="Select"
                                    v-model="eaaAplcblClmPyot"
                                    solo
                                    class="rounded-0"
                                    :rules="[required()]"
                                    >
                                </v-select>
                            </v-layout>
                        </v-flex>
                <v-flex xs3 v-if="eaasubBasisshow==true">
                    <v-layout column class="cust_pln_cd mx-2">
                        <p class="font-weight-bold cstm-clr-pln-cd">
                            Claim Payment Basis
                        </p>
                        <v-select
                            :items="ClmpymntBasisItems"
                            v-model="eaaclaimPaymentBasis"
                             @change="chngEaaClaimPaymentBasis()"
                            label="Select"
                            solo
                            :rules="[required()]"
                            class="rounded-0"
                        >
                        </v-select>
                    </v-layout>
                </v-flex>
                
                        <v-flex xs3 v-if="shweaaCvrgAplcblDrtn===true">
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                   Coverage applicable duration (No of years)
                                </p>
                                <v-select
                                    :items="hacCadItms"
                                    label="Select"
                                    v-model="eaaCad"
                                    solo
                                    class="rounded-0"
                                    :rules="[required()]"
                                    >
                                </v-select>
                            </v-layout>
                        </v-flex>
                    <v-flex xs3>
                        <v-layout column class="cust_pln_cd mx-2 ">
                            <p class="font-weight-bold cstm-clr-pln-cd">Waiting Period</p>
                            <v-layout>
                                <v-select
                                    :items="['Days','Months']"
                                    label="Select"
                                    v-model="EAAwaitingPeriodDrop"
                                    solo
                                    style="width: 145px"
                                    class="rounded-0"
                                    :rules="[required()]"
                                    >
                                </v-select>
                                <v-text-field
                                    label="Enter..."
                                    v-model="eaawaitingPeriod"
                                    solo
                                    class="rounded-0"
                                    :maxlength="max10"
                                    :rules="[Numeric2]"
                                    >
                                </v-text-field>
                            </v-layout>
                        </v-layout>
                    </v-flex>
                 </v-layout>
                    
                <v-divider></v-divider>
                <!-- NEW FIELDS ADDED EAA -->
                <v-layout class="mt-3" wrap style="align-items:flex-end">
                    <v-flex xs3>
                        <v-layout column class="cust_pln_cd mx-2 ">
                            <p class="font-weight-bold cstm-clr-pln-cd">IRDA UIN Number</p>
                            <v-text-field
                                solo
                                label="Enter..."
                                v-model="EAAIrdaUINNo"
                                class="rounded-0"
                                :maxlength="max20"
                                :rules="spclCharNew">
                            </v-text-field>
                        </v-layout>
                    </v-flex>
                    <v-flex xs3>
                        <v-layout column class="cust_pln_cd mx-2 ">
                            <p class="font-weight-bold cstm-clr-pln-cd">External Reference Number</p>
                            <v-text-field
                                solo
                                label="Enter..."
                                v-model="EAAExtRefNo"
                                class="rounded-0"
                                :maxlength="max30"
                                :rules="spclCharNew">
                            </v-text-field>
                        </v-layout>
                    </v-flex>
                    <v-flex xs3>
                        <v-layout column class="cust_pln_cd mx-2 ">
                            <p class="font-weight-bold cstm-clr-pln-cd">Conditions</p>
                            <v-select
                                :items="prodConditionArray"
                                label="Select"
                                v-model="EAACondField"
                                multiple
                                solo
                                class="rounded-0"
                                >
                            </v-select>
                        </v-layout>
                    </v-flex>
                    <v-flex xs3>
                        <v-layout column class="cust_pln_cd mx-2 ">
                            <p class="font-weight-bold cstm-clr-pln-cd">GC Coverage</p>
                            <v-select
                                :items="prodGCCoverArray"
                                label="Select"
                                v-model="EAAGcCover"
                                solo
                                class="rounded-0"
                                :rules="[required()]">
                            </v-select>
                        </v-layout>
                    </v-flex>
                    <v-flex xs3>
                        <v-layout column class="cust_pln_cd mx-2 ">
                            <p class="font-weight-bold cstm-clr-pln-cd">Remarks</p>
                            <v-text-field
                                solo
                                label="Enter..."
                                v-model="EAARemarks"
                                class="rounded-0"
                                :maxlength="max40"
                                >
                            </v-text-field>
                        </v-layout>
                    </v-flex>
                    <v-flex xs3>
                        <v-layout column class="cust_pln_cd mx-2 ">
                            <p class="font-weight-bold cstm-clr-pln-cd">Exclusions</p>
                            <v-select
                                :items="prodExcluArray"
                                :maxlength="max30"
                                label="Select"
                                v-model="EAAExclus"
                                solo
                                class="rounded-0"
                                multiple
                                >
                            </v-select>
                        </v-layout>
                    </v-flex>
                </v-layout>
                <v-divider></v-divider>
                <v-flex xs3>
                    <v-layout column class="mx-2 mb-7 cust_pln_cd" justify-center >
                        <p class="font-weight-bold cstm-clr-pln-cd" style="color:#FFFFFF" ></p>
                        <div style="background-color: #f7f7f7;border: 1px solid #c1c8cc;" class="pa-2 mt-5">
                            <v-checkbox class="ma-0 pa-0" v-model="EAASelectBox" @change="EAARelCheckSelected()" hide-details>
                                <span slot="label" class="cust_lbl-bgclr font-weight-bold" style="font-size: 13px">Separate Relationship & Dependent Info</span>
                            </v-checkbox>
                        </div>
                    </v-layout>
                </v-flex>
                    
                <div v-if="showEAARelSection == true">
                    <relationdependent 
                        ref="EAARel" 
                        @save="saveOthcotdea" 
                        :redpndntinfo="{
                            cirelDataemty:EAArelCoverData.realdata,
                            ciRelnshipCoveredEmpty:EAArelCoverData.dropdowndata,
                            cisyncArryEmpty:EAArelCoverData.syncarr,
                            ciactiveonlyempty:EAArelCoverData.ddselectedvalues
                        }" 
                        v-if="showEAARelData == true">
                    </relationdependent>
                </div>
                <v-divider></v-divider>
                 <v-layout style="flex:1" justify-end class="my-4">
                    <v-btn dark tile  @click="clearEAA()" style="font-size:12px;background-color:#e46a25" class="text-capitalize px-3 mx-2" small><v-icon dark small>close</v-icon> <v-divider vertical class="mx-2"></v-divider> Clear</v-btn>
                    <v-btn dark tile  @click="submitOthcotdea('EAA')" style="font-size:12px;background-color:#23B1A9" class="text-capitalize px-3 mx-2" small><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider> Save</v-btn>
                    <v-btn dark tile  @click="nxt(nextdrive[8])" style="font-size:12px;background-color:#152F38"
                    class="text-capitalize px-3 mx-2" small><v-icon dark small>arrow_forward</v-icon>
                    <v-divider vertical class="mx-2"></v-divider>Next</v-btn>
                </v-layout>
                </v-form>
                    

                </div>
                
            </div>

    <!-- 8.bdoc -->   
    <div class="panelis" dense v-if="shwExpnsnBdoc===true">
                <div @click="currentis(nextdrive[9])" expand-icon="mdi-menu-down" class="font-weight-bold panhead" >
                    Baby Day One Care   <span> <v-icon  :style="{border: datais[nextdrive[9].shown == 'y' ? '5px red solid':'none' ] }"  >arrow_drop_down</v-icon> </span> 
                </div>
                
                <div  class="pa-2"  v v-if="datais[nextdrive[9].shown]">
                    <v-divider></v-divider>
                    <v-form v-model="bdocvalid" ref="bdocForm">  
                    <v-layout class="mt-3" style="align-items:flex-end" wrap>
                        <v-flex xs3 >
                        <v-layout column class="cust_pln_cd mx-2 ">
                            <p class="font-weight-bold cstm-clr-pln-cd">
                                Coverage Type
                            </p>
                            <v-select
                            :items="cvrgTypItems"
                            v-model="bdoccoverageType"
                            label="Select"
                            
                            solo
                            :rules="[required()]"
                            
                            class="rounded-0"
                        ></v-select>
                        </v-layout>
                    </v-flex>
                    <!-- :error-messages="bdoccoverageType_chk"
                        @blur="$v.bdoccoverageType.$touch()"
                             -->

                <v-flex xs3 >
                    <v-layout column class="cust_pln_cd mx-2 ">
                        <p class="font-weight-bold cstm-clr-pln-cd">
                            Coverage Basis
                        </p>
                        <v-select
                            :items="cvrgBasisItems"
                            v-model="bdoccoverageBasis"
                            label="Select"
                            
                            solo
                            :rules="[required()]"
                            
                            class="rounded-0"
                            @change="bdocchangeOnover(1)"
                        ></v-select>
                    </v-layout>
                </v-flex>
                <!-- :error-messages="bdoccoverageBasis_chk"
                        @blur="$v.bdoccoverageBasis.$touch()" -->

                <v-flex xs3 v-if="bdocsubBasisshow===true">
                    <v-layout column class="cust_pln_cd mx-2 ">
                        <p class="font-weight-bold cstm-clr-pln-cd">
                            Sub Limit Basis
                        </p>
                        <v-select
                            :items="cvrgSbLmtBasisItems"
                            v-model="bdocsubLimitBasis"
                            label="Select"
                            
                            solo
                            multiple
                            @change="bdocflatchange()"
                            class="rounded-0"
                            :rules="[required()]"
                            
                        >
                        <template v-slot:selection="{ item, index }">
                                   
                                    <span v-if="index === 0" >{{ item }}</span>
                                   
                                    <span
                                    v-if="index === 1"
                                    class="grey--text caption"
                                    >
                                    (+{{ bdocsubLimitBasis.length - 1 }})
                                    </span>
                                </template>
                        </v-select>
                    </v-layout>
                </v-flex>
                 <!-- % of SI -->
                    <v-flex xs3 v-if="shSiAmtForBDC===true">
                        <v-layout column class="cust_pln_cd mx-2 ">
                            <p class="font-weight-bold cstm-clr-pln-cd">
                                % of SI
                            </p>
                                <v-text-field
                                label="Enter..."
                                v-model="siAmtForBDC"
                                solo
                                class="rounded-0"
                                :maxlength="max10"
                                :rules="[required(),numberRule,decimalRule()]"
                                >
                            </v-text-field>
                        </v-layout>
                    </v-flex>
                     <!-- Flat Amount  -->
                    <v-flex xs3 v-if="shFlatAmtForBDC===true">
                        <v-layout column class="cust_pln_cd mx-2  cust-pln-cd">
                            <p class="font-weight-bold cstm-clr-pln-cd">
                                Flat Amount
                            </p>
                            <v-text-field
                                label="Enter..."
                                v-model="flatAmtForBDC"
                                solo
                                class="rounded-0"
                                :maxlength="max10"
                                :rules="[required(),alphaNumeric()]"
                                >
                            <div slot="prepend-inner" style="background-color:#F7F7F7;padding: 9px !important"><v-icon >mdi-currency-inr </v-icon></div>
                            </v-text-field>
                        </v-layout>
                    </v-flex>
                     <v-flex xs3 v-if="shSiAmtForPrvBDC===true">
                        <v-layout column class="cust_pln_cd mx-2 ">
                            <p class="font-weight-bold cstm-clr-pln-cd">
                                % of previous yr SI
                            </p>
                                <v-text-field
                                label="Enter..."
                                v-model="siAmtForPrvBDC"
                                solo
                                class="rounded-0"
                                :maxlength="max10"
                               :rules="[required(),numberRule,decimalRule()]"
                                >
                            </v-text-field>
                        </v-layout>
                    </v-flex>
                     <!-- % of claim amount -->
                    <v-flex xs3 v-if="shClaimAmtForBDC===true">
                        <v-layout column class="cust_pln_cd mx-2 ">
                            <p class="font-weight-bold cstm-clr-pln-cd">
                                % of claim amount
                            </p>
                                <v-text-field
                                label="Enter..."
                                v-model="claimAmtForBDC"
                                solo
                                class="rounded-0"
                                :maxlength="max10"
                                :rules="[required(),numberRule,decimalRule()]"
                                >
                            </v-text-field>
                        </v-layout>
                    </v-flex>

                 <!-- Applicable claim pay-out -->
                    <v-flex xs3 v-if="shwAppCliamPayForBDC===true">
                    <v-layout column class="cust_pln_cd mx-2 ">
                        <p class="font-weight-bold cstm-clr-pln-cd">
                            Applicable claim pay-out
                        </p>
                        <v-select
                            :items="hacAplcblClmPyotItms"
                            label="Select"
                            v-model="AppCliamPayBDC"
                            solo
                            class="rounded-0"
                            :rules="[required()]"
                            >
                        </v-select>
                    </v-layout>
                </v-flex>

                <v-flex xs3 v-if="bdocsubBasisshow===true">
                    <v-layout column class="cust_pln_cd mx-2">
                        <p class="font-weight-bold cstm-clr-pln-cd">
                            Claim Payment Basis
                        </p>
                        <v-select
                            :items="ClmpymntBasisItems"
                            v-model="bdocclaimPaymentBasis"
                            label="Select"
                            @change="chngBDCClaimPaymentBasis()"
                            
                            solo
                            class="rounded-0"
                            :rules="[required()]"
                            
                        >
                        </v-select>
                    </v-layout>
                </v-flex>

                    <!-- Coverage applicable duration (No of years) -->
                         <v-flex xs3 v-if="shwCoveAppDuratBDC===true">
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                   Coverage applicable duration (No of years)
                                </p>
                                <v-select
                                    :items="hacCadItms"
                                    label="Select"
                                    v-model="CoveAppDuratBDC"
                                    solo
                                    class="rounded-0"
                                    :rules="[required()]"
                                    >
                                </v-select>
                            </v-layout>
                        </v-flex>
                <!-- :error-messages="bdocclaimPaymentBasis_chk"
                        @blur="$v.bdocclaimPaymentBasis.$touch()" -->
                 
            </v-layout>
                        <!-- <v-flex xs4> </v-flex>
                        <v-flex xs4> </v-flex>
                        <v-flex xs4> </v-flex> -->

                    
                    <v-divider></v-divider>
                 <v-layout style="flex:1" justify-end class="my-4">
                    <v-btn dark tile  @click="babyCareSave('bdc')" style="font-size:12px;background-color:#23B1A9" class="text-capitalize px-3 mx-2" small><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider> Save</v-btn>
                    <v-btn dark tile @click="nxt(nextdrive[9])" style="font-size:12px;background-color:#152F38"
                     class="text-capitalize px-3 mx-2" small><v-icon dark small>arrow_forward</v-icon>
                     <v-divider vertical class="mx-2"></v-divider>Next</v-btn>
                </v-layout>
                </v-form>
                    

                </div>
                
            </div>


    <!--9.nbb  -->         
    <div class="panelis" dense v-if="shwExpnsnNbbc===true">
                <div @click="currentis(nextdrive[10])" expand-icon="mdi-menu-down" class="font-weight-bold panhead" >
                    New Born Baby    <span> <v-icon  :style="{border: datais[nextdrive[10].shown == 'y' ? '5px red solid':'none' ] }"  >arrow_drop_down</v-icon> </span> 
                </div>
                
                <div  class="pa-2"   v-if="datais[nextdrive[10].shown]" >
                    <v-divider></v-divider  >
                    <v-form v-model="nbbvalid" ref="nbbForm">  
                    <v-layout class="mt-3" style="align-items:flex-end" wrap>
                        <v-flex xs3 >
                        <v-layout column class="cust_pln_cd mx-2 ">
                            <p class="font-weight-bold cstm-clr-pln-cd">
                                Coverage Type
                            </p>
                            <v-select
                            :items="cvrgTypItems"
                            v-model="nbbcoverageType"
                            label="Select"
                            
                            solo
                            :rules="[required()]"
                            
                            class="rounded-0"
                        ></v-select>
                        </v-layout>
                    </v-flex>

                            <!-- :error-messages="nbbcoverageType_chk"
                        @blur="$v.nbbcoverageType.$touch()" -->

                <v-flex xs3 >
                    <v-layout column class="cust_pln_cd mx-2 ">
                        <p class="font-weight-bold cstm-clr-pln-cd">
                            Coverage Basis
                        </p>
                        <v-select
                            :items="cvrgBasisItems"
                            v-model="nbbcoverageBasis"
                            label="Select"
                            
                            solo
                            :rules="[required()]"
                            
                            class="rounded-0"
                            @change="nbbchangeOnover()"
                        ></v-select>
                    </v-layout>
                </v-flex>

                <!-- :error-messages="nbbcoverageBasis_chk"
                        @blur="$v.nbbcoverageBasis.$touch()" -->

                <v-flex xs3 v-if="nbbsubBasisshow===true">
                    <v-layout column class="cust_pln_cd mx-2 ">
                        <p class="font-weight-bold cstm-clr-pln-cd">
                            Sub Limit Basis
                        </p>
                        <v-select
                            :items="cvrgSbLmtBasisItems"
                            v-model="nbbsubLimitBasis"
                            label="Select"
                            
                            solo
                            multiple
                            @change="nbbflatchange()"
                            class="rounded-0"
                            :rules="[required()]"
                        >
                        <template v-slot:selection="{ item, index }">
                                   
                                    <span v-if="index === 0" >{{ item }}</span>
                                   
                                    <span
                                    v-if="index === 1"
                                    class="grey--text caption"
                                    >
                                    (+{{ nbbsubLimitBasis.length - 1 }} )
                                    </span>
                                </template>
                        </v-select>
                    </v-layout>
                </v-flex>
                <v-flex xs3 v-if="shSiAmtForNBB===true">
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                   % of SI
                                </p>
                                 <v-text-field
                                    label="Enter..."
                                    v-model="siAmtForNBB"
                                    solo
                                    class="rounded-0"
                                    :maxlength="max10"
                                    :rules="[required(),numberRule,decimalRule()]"
                                    >
                                </v-text-field>
                            </v-layout>
                </v-flex>
                 <v-flex xs3 v-if="shFlatAmtForNBB===true">
                            <v-layout column class="cust_pln_cd mx-2  cust-pln-cd">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                    Flat Amount
                                </p>
                                <v-text-field
                                    label="Enter..."
                                    v-model="flatAmtForNBB"
                                    solo
                                    class="rounded-0"
                                    :maxlength="max10"
                                    :rules="[required(),alphaNumeric()]"
                                    >
                                <div slot="prepend-inner" style="background-color:#F7F7F7;padding: 9px !important"><v-icon >mdi-currency-inr </v-icon></div>
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                         <v-flex xs3 v-if="shSiAmtPrvForNBB===true">
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                   % of previous yr SI
                                </p>
                                 <v-text-field
                                    label="Enter..."
                                    v-model="siAmtPrvForNBB"
                                    solo
                                    class="rounded-0"
                                    :maxlength="max10"
                                    :rules="[required(),numberRule,decimalRule()]"
                                    >
                                </v-text-field>
                            </v-layout>
                </v-flex>
                        <v-flex xs3 v-if="shClaimAmtForNBB===true">
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                  % of claim amount
                                </p>
                                 <v-text-field
                                    label="Enter..."
                                    v-model="claimAmtForNBB"
                                    solo
                                    class="rounded-0"
                                    :maxlength="max10"
                                    :rules="[required(),numberRule,decimalRule()]"
                                    >
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3 v-if="shwAppCliamPayForNBB===true">
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                   Applicable claim pay-out
                                </p>
                                <v-select
                                    :items="hacAplcblClmPyotItms"
                                    label="Select"
                                    v-model="AppCliamPayNBB"
                                    solo
                                    class="rounded-0"
                                    :rules="[required()]"
                                    >
                                </v-select>
                            </v-layout>
                        </v-flex>
                    <!-- <v-divider  class="ma-2" vertical ></v-divider> -->

                        <v-flex xs3 v-if="nbbsubBasisshow===true">
                        <v-layout column class="cust_pln_cd mx-2">
                            <p class="font-weight-bold cstm-clr-pln-cd">
                                Claim Payment Basis
                            </p>
                            <v-select
                                :items="ClmpymntBasisItems"
                                v-model="nbbclaimPaymentBasis"
                                label="Select"
                                @change="chngNBBClaimPaymentBasis()"
                                
                                solo
                                
                                class="rounded-0"
                                :rules="[required()]"
                            >
                            </v-select>
                        </v-layout>
                        </v-flex>

                        <!-- Applicable claim pay-out -->
                         

                

                        <!-- Coverage applicable duration (No of years) -->
                         <v-flex xs3 v-if="shwCoveAppDuratNBB===true">
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                   Coverage applicable duration (No of years)
                                </p>
                                <v-select
                                    :items="hacCadItms"
                                    label="Select"
                                    v-model="CoveAppDuratNBB"
                                    solo
                                    class="rounded-0"
                                    :rules="[required()]"
                                    >
                                </v-select>
                            </v-layout>
                        </v-flex>


                    <!-- <v-divider  class="ma-2" vertical ></v-divider> -->
                    <v-flex xs3 >
                        <v-layout column class="cust_pln_cd mx-2 ">
                            <p class="font-weight-bold cstm-clr-pln-cd">
                                Waiting Period
                            </p>
                            <v-text-field
                                solo
                                
                                label="Enter..."
                                v-model="nbbwaitingPeriod"
                                class="rounded-0"
                                :maxlength="max10"
                                :rules="[Numeric2]"

                                 
                            >
                            </v-text-field>
                        </v-layout>
                    </v-flex>

                    <!-- :error-messages="nbbwaitingPeriod_chk"
                        @blur="$v.nbbwaitingPeriod.$touch()"
                                 -->

                <!-- </v-layout>
            
             <v-flex xs12 >
                <v-layout color="#FFFFFF" class=" mt-1"> -->

                    <!-- <v-flex xs3 v-if="nbbflatshow==true">
                    <v-layout column class="  mx-2 cust_pln_cd">
                        <p class="font-weight-bold cstm-clr-pln-cd">
                            Flat Amount
                        </p>
                        
                        <v-text-field
                                solo
                                
                                label="for Eligible Claim(No.of Hours)"
                                v-model="nbbflatAmount"
                                class="rounded-0"
                                :maxlength="max20"
                                :rules="[required(),alphaNumeric()]"
                                
                            >
                            </v-text-field>
                    </v-layout>
                </v-flex> -->


                        <!-- Flat Amount  -->
                       


                         <!-- % of SI -->
                        
                        
                        <!-- % of claim amount -->
                        


                        <!-- :error-messages="nbbflatAmount_chk"
                        @blur="$v.nbbflatAmount.$touch()" -->
                        
                    
                 </v-layout>
        <!-- </v-flex> -->
                        <!-- <v-flex xs4> </v-flex>
                        <v-flex xs4> </v-flex>
                        <v-flex xs4> </v-flex> -->

                    <v-divider></v-divider>
                 <v-layout style="flex:1" justify-end class="my-4">
                    <v-btn dark tile  @click="babyCareSave('nbbc')" style="font-size:12px;background-color:#23B1A9" class="text-capitalize px-3 mx-2" small><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider> Save</v-btn>
                    <v-btn dark tile @click="nxt(nextdrive[10])" style="font-size:12px;background-color:#152F38"
                    class="text-capitalize px-3 mx-2" small><v-icon dark small>arrow_forward</v-icon>
                    <v-divider vertical class="mx-2"></v-divider>Next</v-btn>
                </v-layout>
                </v-form>
                    

                </div>
                
            </div>
            

    <!-- 10.rb -->

          <div class="panelis" dense v-if="showRbAccor === true">
                <div @click="currentis(nextdrive[11])" expand-icon="mdi-menu-down" class="font-weight-bold panhead" >
                    Restore Benefit   <span> <v-icon  :style="{border: datais[nextdrive[11].shown == 'y' ? '5px red solid':'none' ] }"  >arrow_drop_down</v-icon> </span> 
                </div>
                
                <div  class="pa-2"   v-if="datais[nextdrive[11].shown]" >
                    <v-divider></v-divider>
                    <v-form v-model="rbvalid" ref="rbForm">  
                        <v-layout class="mt-3" style="align-items:flex-end" wrap>
                            <v-flex xs3 >
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Coverage Alternate Name</p>
                                    <v-text-field
                                        :rules="alphaRule"
                                        solo
                                        label="Enter..."
                                        v-model="RBCCoverAltName"
                                        class="rounded-0"
                                        :maxlength="max200"
                                        >
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 >
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Coverage Type
                                    </p>
                                    <v-select
                                    :items="cvrgTypItems"
                                    v-model="rbcoverageType"
                                    label="Select"
                                    solo
                                    :rules="[required()]"
                                    class="rounded-0"
                                ></v-select>
                                </v-layout>
                            </v-flex>

                            <v-flex xs3 >
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Coverage Basis
                                    </p>
                                    <v-select
                                        :items="cvrgBasisItems"
                                        v-model="rbcoverageBasis"
                                        label="Select"
                                        solo
                                        :rules="[required()]"
                                        class="rounded-0"
                                        @change="rbchangeOnover(1)"
                                    ></v-select>
                                </v-layout>
                            </v-flex>

                            <v-flex xs3 v-if="rbsubBasisshow==true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Sub Limit Basis
                                    </p>
                                    <v-select
                                        :items="cvrgSbLmtBasisItems"
                                        v-model="rbsubLimitBasis"
                                        label="Select"
                                        solo
                                        multiple
                                        @change="chngrbSubLmtBasis()"
                                        :rules="[required()]"
                                        class="rounded-0"
                                    >
                                        <template v-slot:selection="{ item, index }">
                                            <span v-if="index === 0" >{{ item }}</span>
                                            <span
                                            v-if="index === 1"
                                            class="grey--text caption"
                                            >
                                            (+{{ rbsubLimitBasis.length - 1 }})
                                            </span>
                                        </template>
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="shwrbPerSI===true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        % of SI
                                    </p>
                                    <v-text-field
                                        label="Enter..."
                                        v-model="rbPerSI"
                                        solo
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),numberRule,decimalRule()]"
                                        >
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="shwrbFltAmt===true">
                                <v-layout column class="cust_pln_cd mx-2 cust-pln-cd">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Flat Amount
                                    </p>
                                    <v-text-field
                                        label="Enter..."
                                        v-model="rbFlatAmt"
                                        solo
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),alphaNumeric()]"
                                        >
                                    <div slot="prepend-inner" style="background-color:#F7F7F7;padding: 9px !important"><v-icon >mdi-currency-inr </v-icon></div>
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="shwrbPrvPerSI===true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                    % of previous yr SI
                                    </p>
                                    <v-text-field
                                        label="Enter..."
                                        v-model="rbPrvPerSI"
                                        solo
                                        
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),numberRule,decimalRule()]"
                                        >
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="shwrbClmAmt===true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                    % of claim amount
                                    </p>
                                    <v-text-field
                                        label="Enter..."
                                        v-model="rbClmAmt"
                                        solo
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),numberRule,decimalRule()]"
                                    >
                                    </v-text-field>
                                </v-layout>
                            </v-flex>

                            <v-flex xs3 v-if="showrbacpo===true">
                                <v-layout column class="cust_pln_cd mx-2">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Applicable Claim Pay-Out
                                    </p>
                                    <v-select
                                        :items="selectApplicableClaimPayOut"
                                        v-model="rbapplicableClaimPayOut"
                                        label="Select"
                                        solo
                                        :rules="[required()]"
                                        class="rounded-0"
                                    ></v-select>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="rbsubBasisshow==true">
                                <v-layout column class="cust_pln_cd mx-2">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Claim Payment Basis
                                    </p>
                                    <v-select
                                        :items="ClmpymntBasisItems"
                                        v-model="rbclaimPaymentBasis"
                                        label="Select"
                                        solo
                                        @change="chngrbClaimPaymentBasis()"
                                        :rules="[required()]"
                                        class="rounded-0">
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="shwrbCvrgAplcblDrtn===true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Coverage applicable duration 
                                    </p>
                                    <v-select
                                        :items="hacCadItms"
                                        label="Select"
                                        v-model="rbCad"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]"
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex>

                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Restore Waiting Period</p>
                                    <v-layout>
                                        <v-select
                                            :items="['Days','Months']"
                                            label="Select"
                                            v-model="rbrestoreWaitingPeriodDrop"
                                            solo
                                            style="width: 145px"
                                            class="rounded-0"
                                            :rules="[required()]"
                                            >
                                        </v-select>
                                        <v-text-field
                                            label="Enter..."
                                            v-model="rbrestoreWaitingPeriod"
                                            solo
                                            class="rounded-0"
                                            :maxlength="max10"
                                            :rules="[Numeric2]"
                                            >
                                        </v-text-field>
                                    </v-layout>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 >
                                <v-layout column class="cust_pln_cd mx-2">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Number of reinstatements Permitted
                                    </p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="rbNoReinstatementsPermitted"
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[Numeric2]"
                                    >
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                        </v-layout>
                        <v-divider></v-divider>
                        <!-- NEW FIELDS ADDED RBC -->
                        <v-layout class="mt-3" wrap style="align-items:flex-end">
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">IRDA UIN Number</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="RBCIrdaUINNo"
                                        class="rounded-0"
                                        :maxlength="max20"
                                        :rules="spclCharNew">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">External Reference Number</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="RBCExtRefNo"
                                        class="rounded-0"
                                        :maxlength="max30"
                                        :rules="spclCharNew">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Conditions</p>
                                    <v-select
                                        :items="prodConditionArray"
                                        label="Select"
                                        v-model="RBCCondField"
                                        multiple
                                        solo
                                        class="rounded-0"
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">GC Coverage</p>
                                    <v-select
                                        :items="prodGCCoverArray"
                                        label="Select"
                                        v-model="RBCGcCover"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]">
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Remarks</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="RBCRemarks"
                                        class="rounded-0"
                                        :maxlength="max40"
                                        >
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Exclusions</p>
                                    <v-select
                                        :items="prodExcluArray"
                                        :maxlength="max30"
                                        label="Select"
                                        v-model="RBCExclus"
                                        solo
                                        class="rounded-0"
                                        multiple
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                        </v-layout>
                        <v-divider></v-divider>
                        <v-flex xs3>
                            <v-layout column class="mx-2 mb-7 cust_pln_cd" justify-center >
                                <p class="font-weight-bold cstm-clr-pln-cd" style="color:#FFFFFF" ></p>
                                <div style="background-color: #f7f7f7;border: 1px solid #c1c8cc;" class="pa-2 mt-5">
                                    <v-checkbox class="ma-0 pa-0" v-model="RBCSelectBox" @change="RBCRelCheckSelected()" hide-details>
                                        <span slot="label" class="cust_lbl-bgclr font-weight-bold" style="font-size: 13px">Separate Relationship & Dependent Info</span>
                                    </v-checkbox>
                                </div>
                            </v-layout>
                        </v-flex>
                            
                        <div v-if="showRBCRelSection == true">
                            <relationdependent 
                                ref="RBCRel" 
                                @save="saveRBRel" 
                                :redpndntinfo="{
                                    cirelDataemty:RBCrelCoverData.realdata,
                                    ciRelnshipCoveredEmpty:RBCrelCoverData.dropdowndata,
                                    cisyncArryEmpty:RBCrelCoverData.syncarr,
                                    ciactiveonlyempty:RBCrelCoverData.ddselectedvalues
                                }" 
                                v-if="showRBCRelData == true">
                            </relationdependent>
                        </div>
                        <v-divider></v-divider>
                        <v-layout style="flex:1" justify-end class="my-4">
                            <v-btn dark tile  @click="clearRBC()" style="font-size:12px;background-color:#e46a25" class="text-capitalize px-3 mx-2" small><v-icon dark small>close</v-icon> <v-divider vertical class="mx-2"></v-divider> Clear</v-btn>
                            <v-btn dark tile  @click="submitRB()" style="font-size:12px;background-color:#23B1A9" class="text-capitalize px-3 mx-2" small><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider> Save</v-btn>
                            <v-btn dark tile @click="nxt(nextdrive[11])" style="font-size:12px;background-color:#152F38"
                            class="text-capitalize px-3 mx-2" small><v-icon dark small>arrow_forward</v-icon>
                            <v-divider vertical class="mx-2"></v-divider>Next</v-btn>
                        </v-layout>
                    </v-form>
                </div>
            </div>
            <!-- 11.ambc -->

            <!-- <v-expansion-panel dense>
                        <v-expansion-panel-header expand-icon="mdi-menu-down" class="font-weight-bold" >
                            Ambulance Cover
                        </v-expansion-panel-header>
                        
                        <v-expansion-panel-content>
                            <v-divider></v-divider>
                            <v-form v-model="ambcvalid" ref="ambcForm">  
                            <v-layout class="mt-3" style="align-items:flex-end" wrap>
                                <v-flex xs3 >
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Coverage Type
                                    </p>
                                    <v-select
                                    :items="cvrgTypItems"
                                    v-model="ambccoverageType"
                                    label="Select"
                                    dense
                                    solo
                                    :rules="[required()]"
                                    
                                    class="rounded-0"
                                ></v-select>
                                </v-layout>
                            </v-flex>

                                
                        <v-flex xs3 >
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                    Coverage Basis
                                </p>
                                <v-select
                                    :items="cvrgBasisItems"
                                    v-model="ambccoverageBasis"
                                    label="Select"
                                    dense
                                    solo
                                    :rules="[required()]"
                                
                                    class="rounded-0"
                                    @change="ambcchangeOnover(1)"
                                ></v-select>
                            </v-layout>
                        </v-flex>

                            

                        <v-flex xs3 v-if="ambcsubBasisshow==true">
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                    Sub Limit Basis
                                </p>
                                <v-select
                                    :items="cvrgSbLmtBasisItems"
                                    v-model="ambcsubLimitBasis"
                                    label="Select"
                                    dense
                                    solo
                                    multiple
                                    @change="ambcsubLimitBasischange()"
                                    class="rounded-0"
                                    :rules="[required()]"
                                >
                                <template v-slot:selection="{ item, index }">
                                    <v-chip v-if="index === 0">
                                    <span>{{ item }}</span>
                                    </v-chip>
                                    <span
                                    v-if="index === 1"
                                    class="grey--text caption"
                                    >
                                    (+{{ ambcsubLimitBasis.length - 1 }} )
                                    </span>
                                </template>
                                </v-select>
                            </v-layout>
                        </v-flex>
                            

                        <v-flex xs3 v-if="ambcsubBasisshow==true">
                            <v-layout column class="cust_pln_cd mx-2">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                    Claim Payment Basis
                                </p>
                                <v-select
                                    :items="ClmpymntBasisItems"
                                    v-model="ambcclaimPaymentBasis"
                                    label="Select"
                                    @change="chngambcClaimPaymentBasis()"
                                    dense
                                    solo
                                    multiple
                                    class="rounded-0"
                                    :rules="[required()]"
                                >
                                <template v-slot:selection="{ item, index }">
                                    <v-chip v-if="index === 0">
                                    <span>{{ item }}</span>
                                    </v-chip>
                                    <span
                                    v-if="index === 1"
                                    class="grey--text caption"
                                    >
                                    (+{{ ambcclaimPaymentBasis.length - 1 }} )
                                    </span>
                                </template>
                                </v-select>
                            </v-layout>
                        </v-flex>
                            
                            <v-flex xs3 v-if="shwAppCliamPayambc==true">
                                <v-layout column class="cust_pln_cd mx-2">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Applicable Claim Pay-Out
                                    </p>

                                    <v-select
                                    :items="selectApplicableClaimPayOut"
                                    v-model="rbapplicableClaimPayOut"
                                    label="Select"
                                    dense
                                    solo
                                    :rules="[required()]"
                                
                                    class="rounded-0"
                                ></v-select>
                                    
                                </v-layout>
                            </v-flex>

                            <v-flex xs3 v-if="shwrbCvrgAplcblDrtn===true">
                                    <v-layout column class="cust_pln_cd mx-2 ">
                                        <p class="font-weight-bold cstm-clr-pln-cd">
                                        Coverage applicable duration 
                                        </p>
                                        <v-select
                                            :items="hacCadItms"
                                            label="Select"
                                            v-model="rbCad"
                                            solo
                                            dense
                                            class="rounded-0"
                                            :rules="[required()]"
                                            >
                                        </v-select>
                                    </v-layout>
                                </v-flex>
                        

                        <v-flex xs3 >
                            <v-layout column class="cust_pln_cd mx-2">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                    Restore Waiting Period
                                </p>
                                
                                <v-text-field
                                        solo
                                        dense
                                        label="Enter..."
                                        v-model="rbrestoreWaitingPeriod"
                                        class="rounded-0"
                                        :maxlength="max20"
                                        :rules="[required(),alphaNumeric()]"
                                        
                                        
                                    >
                                    </v-text-field>
                            </v-layout>
                        </v-flex>
                        
                                

                            
                            <v-flex xs3 v-if="shSiAmtForambc==true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        % of SI
                                    </p>
                                    <v-text-field
                                        solo
                                        dense
                                        label="Enter..."
                                        v-model="siAmtForambc"
                                        class="rounded-0"
                                        :maxlength="max20"
                                        :rules="[required(),numberRule]"
                                        
                                    >
                                    </v-text-field>
                                </v-layout>
                            </v-flex>

                        
                            <v-flex xs3 v-if="shClaimAmtForambc===true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        % of claim amount
                                    </p>
                                        <v-text-field
                                        label="Enter..."
                                        v-model="claimAmtForambc"
                                        solo
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),alphaNumeric()]"
                                        >
                                    </v-text-field>
                                </v-layout>
                            </v-flex>

                        
                            <v-flex xs3 >
                                <v-layout column class="cust_pln_cd mx-2">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Number of reinstatements Permitted
                                    </p>
                                    <v-text-field
                                        solo
                                        dense
                                        label="Enter..."
                                        v-model="rbNoReinstatementsPermitted"
                                        class="rounded-0"
                                        :maxlength="max20"
                                        :rules="[required(),alphaNumeric()]"
                                        
                                    >
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            
                            
                        </v-layout>
                            

                            
                            <v-divider></v-divider>
                        <v-layout style="flex:1" justify-end class="my-4">
                        <v-btn dark tile  @click="RestoreBenefit_save()" style="font-size:12px;background-color:#23B1A9" class="text-capitalize px-3 mx-2" small><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider> Save</v-btn>
                            <v-btn dark tile  style="font-size:12px;background-color:#152F38"  class="text-capitalize px-3 mx-2" small><v-icon dark small>arrow_forward</v-icon> <v-divider vertical class="mx-2"></v-divider>Next</v-btn>

                        </v-layout>
                        </v-form>
                            

                        </v-expansion-panel-content>
                        
                    </v-expansion-panel>
                -->

            <!-- 11.ambc -->

        <div class="panelis" dense v-if="shwExpnsnAmbc===true">
                <div @click="currentis(nextdrive[12])" expand-icon="mdi-menu-down" class="font-weight-bold panhead" >
                    Ambulance Cover    <span> <v-icon  :style="{border: datais[nextdrive[12].shown == 'y' ? '5px red solid':'none' ] }"  >arrow_drop_down</v-icon> </span> 
                </div>
                
                <div  class="pa-2"   v-if="datais[nextdrive[12].shown]" >
                    <v-divider></v-divider>
                    <v-form v-model="ambcvalid" ref="ambcForm">  
                        <v-layout class="mt-3" style="align-items:flex-end" wrap>
                            <v-flex xs3 >
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Coverage Alternate Name</p>
                                    <v-text-field
                                        :rules="alphaRule"
                                        solo
                                        label="Enter..."
                                        v-model="ACCoverAltName"
                                        class="rounded-0"
                                        :maxlength="max200"
                                        >
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 >
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Coverage Type
                                    </p>
                                    <v-select
                                    :items="cvrgTypItems"
                                    v-model="ambccoverageType"
                                    label="Select"
                                    solo
                                    :rules="[required()]"
                                    class="rounded-0"
                                ></v-select>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 >
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Coverage Basis
                                    </p>
                                    <v-select
                                        :items="cvrgBasisItems"
                                        v-model="ambccoverageBasis"
                                        label="Select"
                                        solo
                                        :rules="[required()]"
                                        class="rounded-0"
                                        @change="ambcchangeOnover()"
                                    ></v-select>
                                </v-layout>
                            </v-flex>

                            <v-flex xs3 v-if="ambcsubBasisshow==true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Sub Limit Basis
                                    </p>
                                    <v-select
                                        :items="cvrgSbLmtBasisItems"
                                        v-model="ambcsubLimitBasis"
                                        label="Select"
                                        solo
                                        multiple
                                        @change="ambcsubLimitBasischange()"
                                        class="rounded-0"
                                        :rules="[required()]"
                                    >
                                    <template v-slot:selection="{ item, index }">
                                        <span v-if="index === 0" >{{ item }}</span>
                                        <span
                                        v-if="index === 1"
                                        class="grey--text caption"
                                        >
                                        (+{{ ambcsubLimitBasis.length - 1 }} )
                                        </span>
                                    </template>
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="shSiAmtForambc==true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        % of SI
                                    </p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="siAmtForambc"
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),numberRule,decimalRule()]"
                                    >
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                    
                            <v-flex xs3 v-if="shFlatAmtForambc==true">
                                <v-layout column class="  mx-2 cust_pln_cd cust-pln-cd">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Flat Amount
                                    </p>
                                    <v-text-field
                                        solo
                                        label="for Eligible Claim(No.of Hours)"
                                        v-model="flatAmtForambc"
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),alphaNumeric()]">
                                        <div slot="prepend-inner" style="background-color:#F7F7F7;padding: 9px !important"><v-icon >mdi-currency-inr </v-icon></div>
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="shSiAmtPrvForambc==true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        % of previous yr SI
                                    </p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="siAmtPrvForambc"
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),numberRule,decimalRule()]"
                                    >
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                
                            <v-flex xs3 v-if="shClaimAmtForambc===true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        % of claim amount
                                    </p>
                                        <v-text-field
                                        label="Enter..."
                                        v-model="claimAmtForambc"
                                        solo
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),numberRule,decimalRule()]"
                                        >
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="shwAppCliamPayambc==true">
                                <v-layout column class="cust_pln_cd mx-2">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Applicable Claim Pay-Out
                                    </p>
                                    <v-select
                                    :items="selectApplicableClaimPayOut"
                                    v-model="ambcapplicableClaimPayOut"
                                    label="Select"
                                    solo
                                    :rules="[required()]"
                                    class="rounded-0"
                                ></v-select>
                                </v-layout>
                            </v-flex> 
                            <v-flex xs3 v-if="ambcsubBasisshow==true">
                                <v-layout column class="cust_pln_cd mx-2">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Claim Payment Basis
                                    </p>
                                    <v-select
                                        :items="ClmpymntBasisItems"
                                        v-model="ambcclaimPaymentBasis"
                                        label="Select"
                                        @change="chngambcClaimPaymentBasis()"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]"
                                    >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            <!-- Coverage applicable duration  -->
                            <v-flex xs3 v-if="shwCoveAppDuratambc===true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                    Coverage applicable duration 
                                    </p>
                                    <v-select
                                        :items="hacCadItms"
                                        label="Select"
                                        v-model="CoveAppDuratambc"
                                        solo
                                        
                                        class="rounded-0"
                                        :rules="[required()]"
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Waiting Period</p>
                                    <v-layout>
                                        <v-select
                                            :items="['Days','Months']"
                                            label="Select"
                                            v-model="ACwaitingPeriodDrop"
                                            solo
                                            style="width: 145px"
                                            class="rounded-0"
                                            :rules="[required()]"
                                            >
                                        </v-select>
                                        <v-text-field
                                            label="Enter..."
                                            v-model="ambcwaitingPeriod"
                                            solo
                                            class="rounded-0"
                                            :maxlength="max10"
                                            :rules="[Numeric2]"
                                            >
                                        </v-text-field>
                                    </v-layout>
                                </v-layout>
                            </v-flex>
                        </v-layout>
                        <v-divider></v-divider>
                        <!-- NEW FIELDS ADDED AC -->
                        <v-layout class="mt-3" wrap style="align-items:flex-end">
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">IRDA UIN Number</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="ACIrdaUINNo"
                                        class="rounded-0"
                                        :maxlength="max20"
                                        :rules="spclCharNew">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">External Reference Number</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="ACExtRefNo"
                                        class="rounded-0"
                                        :maxlength="max30"
                                        :rules="spclCharNew">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Conditions</p>
                                    <v-select
                                        :items="prodConditionArray"
                                        label="Select"
                                        v-model="ACCondField"
                                        multiple
                                        solo
                                        class="rounded-0"
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">GC Coverage</p>
                                    <v-select
                                        :items="prodGCCoverArray"
                                        label="Select"
                                        v-model="ACGcCover"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]">
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Remarks</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="ACRemarks"
                                        class="rounded-0"
                                        :maxlength="max40"
                                        >
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Exclusions</p>
                                    <v-select
                                        :items="prodExcluArray"
                                        :maxlength="max30"
                                        label="Select"
                                        v-model="ACExclus"
                                        solo
                                        class="rounded-0"
                                        multiple
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                        </v-layout> 
                        <v-divider></v-divider>
                        <v-flex xs3>
                            <v-layout column class="mx-2 mb-7 cust_pln_cd" justify-center >
                                <p class="font-weight-bold cstm-clr-pln-cd" style="color:#FFFFFF" ></p>
                                <div style="background-color: #f7f7f7;border: 1px solid #c1c8cc;" class="pa-2 mt-5">
                                    <v-checkbox class="ma-0 pa-0" v-model="ACSelectBox" @change="ACRelCheckSelected()" hide-details>
                                        <span slot="label" class="cust_lbl-bgclr font-weight-bold" style="font-size: 13px">Separate Relationship & Dependent Info</span>
                                    </v-checkbox>
                                </div>
                            </v-layout>
                        </v-flex>
                            
                        <div v-if="showACRelSection == true">
                            <relationdependent 
                                ref="ACRel" 
                                @save="saveACRel" 
                                :redpndntinfo="{
                                    cirelDataemty:ACrelCoverData.realdata,
                                    ciRelnshipCoveredEmpty:ACrelCoverData.dropdowndata,
                                    cisyncArryEmpty:ACrelCoverData.syncarr,
                                    ciactiveonlyempty:ACrelCoverData.ddselectedvalues
                                }" 
                                v-if="showACRelData == true">
                            </relationdependent>
                        </div>
                        <v-divider></v-divider>
                        <v-layout style="flex:1" justify-end class="my-4">
                            <v-btn dark tile  @click="clearAmbC()" style="font-size:12px;background-color:#e46a25" class="text-capitalize px-3 mx-2" small><v-icon dark small>close</v-icon> <v-divider vertical class="mx-2"></v-divider> Clear</v-btn>
                            <v-btn dark tile  @click="ambc_save()" style="font-size:12px;background-color:#23B1A9" class="text-capitalize px-3 mx-2" small><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider> Save</v-btn>
                            <v-btn dark tile @click="nxt(nextdrive[12])" style="font-size:12px;background-color:#152F38"
                            class="text-capitalize px-3 mx-2" small><v-icon dark small>arrow_forward</v-icon>
                            <v-divider vertical class="mx-2"></v-divider>Next</v-btn>
                        </v-layout>
                    </v-form>
                </div>
        </div>

            <!-- 12.dcc -->

            <div class="panelis" v-if="showDCAccor == true" dense>
                <div @click="currentis(nextdrive[13])" expand-icon="mdi-menu-down" class="font-weight-bold panhead" >
                    Day Care Coverage   <span> <v-icon  :style="{border: datais[nextdrive[13].shown == 'y' ? '5px red solid':'none' ] }"  >arrow_drop_down</v-icon> </span> 
                </div>
                
                <div  class="pa-2"   v-if="datais[nextdrive[13].shown]">
                    <v-divider></v-divider>
                    <v-form v-model="dccvalid" ref="dccForm">  
                        <v-layout class="mt-3" style="align-items:flex-end" wrap>
                            <v-flex xs3 >
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Coverage Alternate Name</p>
                                    <v-text-field
                                        :rules="alphaRule"
                                        solo
                                        label="Enter..."
                                        v-model="DCCCoverAltName"
                                        class="rounded-0"
                                        :maxlength="max200"
                                        >
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 >
                                <v-layout column class="cust_pln_cd  mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Coverage Type
                                    </p>
                                    <v-select
                                    :items="cvrgTypItems"
                                    v-model="dcccoverageType"
                                    label="Select"
                                    solo
                                    :rules="[required()]"
                                    class="rounded-0"
                                ></v-select>
                                </v-layout>
                            </v-flex>

                            <v-flex xs3 >
                                <v-layout column class="cust_pln_cd  mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Coverage Basis
                                    </p>
                                    <v-select
                                        :items="cvrgBasisItems"
                                        v-model="dcccoverageBasis"
                                        label="Select"
                                        solo
                                        :rules="[required()]"
                                        @change="dccchangeOnover(1)"
                                        class="rounded-0"
                                    ></v-select>
                                </v-layout>
                            </v-flex>

                            <v-flex xs3 v-if="dccsubBasisshow==true">
                                <v-layout column class="cust_pln_cd  mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Sub Limit Basis
                                    </p>
                                    <v-select
                                        :items="cvrgSbLmtBasisItems"
                                        v-model="dccsubLimitBasis"
                                        label="Select"
                                        solo
                                        multiple
                                        @change="subLimitBasisValChange(dccsubLimitBasis)"
                                        class="rounded-0"
                                        :rules="[required()]"
                                    >
                                    <template v-slot:selection="{ item, index }">
                                        <span v-if="index === 0" >{{ item }}</span>
                                        <span v-if="index === 1" class="grey--text caption">(+{{ dccsubLimitBasis.length - 1 }})</span>
                                    </template>
                                    </v-select>
                                </v-layout>
                            </v-flex>

                            <v-flex xs3 v-if="showPercentSi == true">
                                <v-layout column class="cust_pln_cd  mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">% of SI</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="percentSI"
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),numberRule,decimalRule()]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="showFlatAmnt == true">
                                <v-layout column class="cust_pln_cd  mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Flat Amount</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="flatAmountTxtField"
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),alphaNumeric()]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="showPrvPercentSi == true">
                                <v-layout column class="cust_pln_cd  mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">% of previous yr SI</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="PrvpercentSI"
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),numberRule,decimalRule()]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="showPercClaimAmt == true">
                                <v-layout column class="cust_pln_cd  mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">% of Claim Amount</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="percentClaimAmt"
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),numberRule,decimalRule()]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="showDCCOpdSi == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">% of OPD SI</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="DCCOpdSi"
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),numberRule,decimalRule()]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="showDCCAdSi == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">% of AD SI</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="DCCAdSi"
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),numberRule,decimalRule()]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            
                            <v-flex xs3 v-if="showDccApplicClaimPay == true">
                                <v-layout column class="cust_pln_cd  mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Applicable Claim Pay-Out</p>
                                    <v-select
                                        :items="hacAplcblClmPyotItms"
                                        label="Select"
                                        v-model="DccApplicClaimPayOut"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]"
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex>

                            <v-flex xs3 v-if="dccsubBasisshow==true">
                                <v-layout column class="cust_pln_cd  mx-2">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Claim Payment Basis
                                    </p>
                                    <v-select
                                        :items="ClmpymntBasisItems"
                                        v-model="dccclaimPaymentBasis"
                                        label="Select"
                                        solo
                                        @change="changeDccClaimPayBasis(dccclaimPaymentBasis)"
                                        class="rounded-0"
                                        :rules="[required()]">
                                    </v-select>
                                </v-layout>
                            </v-flex>
                
                            <v-flex xs3 v-if="showDccCovrageApplicDur == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Coverage applicable duration (No of years)
                                    </p>
                                    <v-select
                                        :items="hacCadItms"
                                        label="Select"
                                        v-model="dccCovrageApplicDur"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]"
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex>

                            <v-flex xs3 >
                                <v-layout column class="cust_pln_cd  mx-2">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Applicable Day Care Procedures
                                    </p>
                                    <v-select
                                        :items="selectAppliDayCarePro"
                                        v-model="dccappliDayCarePro"
                                        label="Select"
                                        solo
                                        multiple
                                        :rules="[required()]"
                                        class="rounded-0"
                                    >
                                    <template v-slot:selection="{ item, index }">
                                    <span v-if="index === 0" >{{ item }}</span>
                                    <span v-if="index === 1" class="grey--text caption">(+{{ dccappliDayCarePro.length - 1 }})</span>
                                </template>
                                    </v-select>
                                </v-layout>
                            </v-flex>

                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Waiting Period</p>
                                    <v-layout>
                                        <v-select
                                            :items="['Days','Months']"
                                            label="Select"
                                            v-model="dccwaitingPeriodDrop"
                                            solo
                                            style="width: 145px"
                                            class="rounded-0"
                                            :rules="[required()]"
                                            >
                                        </v-select>
                                        <v-text-field
                                            label="Enter..."
                                            v-model="dccwaitingPeriod"
                                            solo
                                            class="rounded-0"
                                            :maxlength="max10"
                                            :rules="[Numeric2]"
                                            >
                                        </v-text-field>
                                    </v-layout>
                                </v-layout>
                            </v-flex>

                            <v-flex xs3 >
                                <v-layout column class="cust_pln_cd  mx-2 mr-2">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Minimum Hospitalization Duration for Eligible Claim(No. of Hours)
                                    </p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="dccminHosDuration"
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[Numeric2]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                        </v-layout>

                        <v-divider></v-divider>
                        <!-- NEW FIELDS ADDED DCC -->
                        <v-layout class="mt-3" wrap style="align-items:flex-end">
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">IRDA UIN Number</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="DCCIrdaUINNo"
                                        class="rounded-0"
                                        :maxlength="max20"
                                        :rules="spclCharNew">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">External Reference Number</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="DCCExtRefNo"
                                        class="rounded-0"
                                        :maxlength="max30"
                                        :rules="spclCharNew">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Conditions</p>
                                    <v-select
                                        :items="prodConditionArray"
                                        label="Select"
                                        v-model="DCCCondField"
                                        multiple
                                        solo
                                        class="rounded-0"
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">GC Coverage</p>
                                    <v-select
                                        :items="prodGCCoverArray"
                                        label="Select"
                                        v-model="DCCGcCover"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]">
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Remarks</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="DCCRemarks"
                                        class="rounded-0"
                                        :maxlength="max40"
                                        >
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Exclusions</p>
                                    <v-select
                                        :items="prodExcluArray"
                                        :maxlength="max30"
                                        label="Select"
                                        v-model="DCCExclus"
                                        solo
                                        class="rounded-0"
                                        multiple
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                        </v-layout>

                        <v-divider></v-divider>

                        <v-flex xs3 >
                            <v-layout column class="mx-2 mb-7 cust_pln_cd" justify-center >
                            <!-- {{indtype.type}} -->
                                <v-text class="font-weight-bold cstm-clr-pln-cd" style="color:#FFFFFF" > </v-text>
                                <div style="background-color: #f7f7f7;border: 1px solid #c1c8cc;" class="pa-2 mt-5">
                                    <v-checkbox class="ma-0 pa-0" v-model="dccisSelectBox" @change="DCRelCheckSelected()" hide-details >
                                        <span slot="label" class="lbl-bgclr font-weight-bold text-truncate " style="font-size: 13px;"> Separate Relationship & Dependent Info</span>
                                    </v-checkbox>
                                </div>
                            </v-layout>
                        </v-flex>
                       
                        <div v-if="showDCRelSection == true">
                            <relationdependent 
                                ref="DCRel" 
                                @save="saveDCPHRel" 
                                :redpndntinfo="{
                                    cirelDataemty:DCrelCoverData.realdata,
                                    ciRelnshipCoveredEmpty:DCrelCoverData.dropdowndata,
                                    cisyncArryEmpty:DCrelCoverData.syncarr,
                                    ciactiveonlyempty:DCrelCoverData.ddselectedvalues
                                }" 
                                v-if="showDCRelData == true">
                            </relationdependent>
                        </div>
            
                        <v-divider></v-divider>
                        <v-layout style="flex:1" justify-end class="my-4">
                            <v-btn dark tile  @click="clearDCC()" style="font-size:12px;background-color:#e46a25" class="text-capitalize px-3 mx-2" small><v-icon dark small>close</v-icon> <v-divider vertical class="mx-2"></v-divider>Clear</v-btn>
                            <v-btn dark tile  @click="submitDCPH('dayCareCover')" style="font-size:12px;background-color:#23B1A9" class="text-capitalize px-3 mx-2" small><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider> Save</v-btn>
                            <v-btn dark tile @click="nxt(nextdrive[13])" style="font-size:12px;background-color:#152F38"
                             class="text-capitalize px-3 mx-2" small><v-icon dark small>arrow_forward</v-icon>
                             <v-divider vertical class="mx-2"></v-divider>Next</v-btn>
                        </v-layout>
                    </v-form>
                </div>
            </div>

    <!-- 13.poh -->

            
    <div class="panelis" dense v-if="shwExpnsnPostHosp===true">
                <div @click="currentis(nextdrive[14])" expand-icon="mdi-menu-down" class="font-weight-bold panhead" >
                    Post-Hospitalization    <span> <v-icon  :style="{border: datais[nextdrive[14].shown == 'y' ? '5px red solid':'none' ] }"  >arrow_drop_down</v-icon> </span> 
                </div>
                
                <div  class="pa-2"   v-if="datais[nextdrive[14].shown]" >
                    <v-divider></v-divider>
                    <v-form v-model="pohvalid" ref="pohForm">  
                        <v-layout class="mt-3" style="align-items:flex-end" wrap>
                                <v-flex xs3 >
                                        <v-layout column class="cust_pln_cd mx-2 ">
                                            <p class="font-weight-bold cstm-clr-pln-cd">Coverage Alternate Name</p>
                                            <v-text-field
                                                :rules="alphaRule"
                                                solo
                                                label="Enter..."
                                                v-model="PostHospCoverAltName"
                                                class="rounded-0"
                                                :maxlength="max200"
                                                >
                                            </v-text-field>
                                        </v-layout>
                                    </v-flex>
                                <v-flex xs3 >
                                    <v-layout column class="cust_pln_cd mx-3 ">
                                        <p class="font-weight-bold cstm-clr-pln-cd">
                                            Coverage Type
                                        </p>
                                        <v-select
                                        :items="cvrgTypItems"
                                        v-model="pohcoverageType"
                                        label="Select"
                                        solo
                                        :rules="[required()]"
                                        class="rounded-0"
                                    ></v-select>
                                    </v-layout>
                                </v-flex>

                        <v-flex xs3 >
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                    Coverage Basis
                                </p>
                                <v-select
                                    :items="cvrgBasisItems"
                                    v-model="pohcoverageBasis"
                                    label="Select"
                                    solo
                                    :rules="[required()]"
                                    @change="pohchangeOnover(1)"
                                    class="rounded-0"
                                ></v-select>
                            </v-layout>
                        </v-flex>

                        <v-flex xs3 v-if="pohsubBasisshow==true">
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                    Sub Limit Basis
                                </p>
                                <v-select
                                    :items="cvrgSbLmtBasisItems"
                                    v-model="pohsubLimitBasis"
                                    @change="chngeInPOHSubLimit()"
                                    label="Select"
                                    solo
                                    multiple
                                    :rules="[required()]"
                                    class="rounded-0"
                                >
                                <template v-slot:selection="{ item, index }">
                                            <span v-if="index === 0" >{{ item }}</span>
                                            <span
                                            v-if="index === 1"
                                            class="grey--text caption"
                                            >
                                            (+{{ pohsubLimitBasis.length - 1 }})
                                            </span>
                                        </template>
                                </v-select>
                            </v-layout>
                        </v-flex>
                        <!-- % of SI -->
                                <v-flex xs3 v-if="shSiAmtForPoh===true">
                                    <v-layout column class="cust_pln_cd mx-2 ">
                                        <p class="font-weight-bold cstm-clr-pln-cd">
                                        % of SI
                                        </p>
                                        <v-text-field
                                            label="Enter..."
                                            v-model="siAmtForPoh"
                                            solo
                                            class="rounded-0"
                                            :maxlength="max10"
                                            :rules="[required(),numberRule,decimalRule()]"
                                            >
                                        </v-text-field>
                                    </v-layout>
                                </v-flex>
                                <!-- Flat Amount  -->
                                <v-flex xs3 v-if="shFlatAmtForPoh===true">
                                    <v-layout column class="cust_pln_cd mx-2  cust-pln-cd">
                                        <p class="font-weight-bold cstm-clr-pln-cd">
                                            Flat Amount
                                        </p>
                                        <v-text-field
                                            label="Enter..."
                                            v-model="flatAmtForPoh"
                                            solo
                                            class="rounded-0"
                                            :maxlength="max10"
                                            :rules="[required(),alphaNumeric()]"
                                            >
                                        <div slot="prepend-inner" style="background-color:#F7F7F7;padding: 9px !important"><v-icon >mdi-currency-inr </v-icon></div>
                                        </v-text-field>
                                    </v-layout>
                                </v-flex>
                                <v-flex xs3 v-if="shSiAmtForPrvPoh===true">
                                    <v-layout column class="cust_pln_cd mx-2 ">
                                        <p class="font-weight-bold cstm-clr-pln-cd">
                                        % of previous yr SI
                                        </p>
                                        <v-text-field
                                            label="Enter..."
                                            v-model="siAmtForPrvPoh"
                                            solo
                                            class="rounded-0"
                                            :maxlength="max10"
                                            :rules="[required(),numberRule,decimalRule()]"
                                            >
                                        </v-text-field>
                                    </v-layout>
                                </v-flex>
                                <!-- % of claim amount -->
                                <v-flex xs3 v-if="shClaimAmtForPoh===true">
                                    <v-layout column class="cust_pln_cd mx-2 ">
                                        <p class="font-weight-bold cstm-clr-pln-cd">
                                        % of claim amount
                                        </p>
                                        <v-text-field
                                            label="Enter..."
                                            v-model="claimAmtForPoh"
                                            solo
                                            class="rounded-0"
                                            :maxlength="max10"
                                            :rules="[required(),numberRule,decimalRule()]"
                                            >
                                        </v-text-field>
                                    </v-layout>
                                </v-flex>
                                <!-- Applicable claim pay-out -->
                                <v-flex xs3 v-if="shwAppCliamPayPostHosp===true">
                                    <v-layout column class="cust_pln_cd mx-2 ">
                                        <p class="font-weight-bold cstm-clr-pln-cd">
                                        Applicable claim pay-out
                                        </p>
                                        <v-select
                                            :items="hacAplcblClmPyotItms"
                                            label="Select"
                                            v-model="AppCliamPayPostHosp"
                                            solo
                                            class="rounded-0"
                                            :rules="[required()]"
                                            >
                                        </v-select>
                                    </v-layout>
                                </v-flex>
                        <v-flex xs3 v-if="pohsubBasisshow==true">
                            <v-layout column class="cust_pln_cd mx-2">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                    Claim Payment Basis
                                </p>
                                <v-select
                                    :items="ClmpymntBasisItems"
                                    v-model="pohclaimPaymentBasis"
                                    label="Select"
                                    @change="chngPOHClaimPaymentBasis()"
                                    solo
                                    :rules="[required()]"
                                    class="rounded-0"
                                >
                                </v-select>
                            </v-layout>
                        </v-flex>
                        <!-- Coverage applicable duration (No of years) -->
                                <v-flex xs3 v-if="shwCoveAppDuratPOH===true">
                                    <v-layout column class="cust_pln_cd mx-2 ">
                                        <p class="font-weight-bold cstm-clr-pln-cd">
                                        Coverage applicable duration (No of years)
                                        </p>
                                        <v-select
                                            :items="hacCadItms"
                                            label="Select"
                                            v-model="CoveAppDuratPOH"
                                            solo
                                            class="rounded-0"
                                            :rules="[required()]"
                                            >
                                        </v-select>
                                    </v-layout>
                                </v-flex>

                        <v-flex xs3 >
                            <v-layout column class="  mx-3 cust_pln_cd">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                    Duration(No. of Days)
                                </p>
                                <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="pohduration"
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),alphaNumeric()]">
                                    </v-text-field>
                            </v-layout>
                        </v-flex>
                               
                                <v-flex xs3>
                                    <v-layout column class="cust_pln_cd mx-2 ">
                                        <p class="font-weight-bold cstm-clr-pln-cd">Waiting Period</p>
                                        <v-layout>
                                            <v-select
                                                :items="['Days','Months']"
                                                label="Select"
                                                v-model="pohwaitingPeriodDrop"
                                                solo
                                                style="width: 145px"
                                                class="rounded-0"
                                                :rules="[required()]"
                                                >
                                            </v-select>
                                            <v-text-field
                                                label="Enter..."
                                                v-model="pohwaitingPeriod"
                                                solo
                                                class="rounded-0"
                                                :maxlength="max10"
                                                :rules="[Numeric2]"
                                                >
                                            </v-text-field>
                                        </v-layout>
                                    </v-layout>
                                </v-flex>
                        </v-layout>

                        <v-divider></v-divider>
                        <!-- NEW FIELDS ADDED POST-HOSPITALISATION -->
                        <v-layout class="mt-3" wrap style="align-items:flex-end">
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">IRDA UIN Number</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="PostHospIrdaUINNo"
                                        class="rounded-0"
                                        :maxlength="max20"
                                        :rules="spclCharNew">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">External Reference Number</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="PostHospExtRefNo"
                                        class="rounded-0"
                                        :maxlength="max30"
                                        :rules="spclCharNew">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Conditions</p>
                                    <v-select
                                        :items="prodConditionArray"
                                        label="Select"
                                        v-model="PostHospCondField"
                                        multiple
                                        solo
                                        class="rounded-0"
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">GC Coverage</p>
                                    <v-select
                                        :items="prodGCCoverArray"
                                        label="Select"
                                        v-model="PostHospGcCover"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]">
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Remarks</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="PostHospRemarks"
                                        class="rounded-0"
                                        :maxlength="max40"
                                        >
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Exclusions</p>
                                    <v-select
                                        :items="prodExcluArray"
                                        :maxlength="max30"
                                        label="Select"
                                        v-model="PostHospExclus"
                                        solo
                                        class="rounded-0"
                                        multiple
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                        </v-layout>
                        <v-divider></v-divider>
                        <v-flex xs3 >
                            <v-layout column class="mx-2 mb-6  cust_pln_cd" justify-center >
                            <!-- {{indtype.type}} -->
                                <v-text class="font-weight-bold cstm-clr-pln-cd" style="color:#FFFFFF" > </v-text>
                                <div style="background-color: #f7f7f7;border: 1px solid #c1c8cc;" class="pa-2 mt-5">
                                    <v-checkbox class="ma-0 pa-0" v-model="pohisSelectBox"  @click="poRdiSelected" hide-details >
                                        <span slot="label" class="lbl-bgclr font-weight-bold " style="font-size: 13px;"> Separate Relationship & Dependent Info</span>
                                    </v-checkbox>
                                </div>
                            </v-layout>
                        </v-flex>
                        <div v-if="shwPoRdi===true" class="mt-3">
                            <relationdependent ref="pofrmprop" @save="save_po_updated" 
                                :redpndntinfo="{
                                    cirelDataemty:tempPoRlcvrd.realdata,
                                    ciRelnshipCoveredEmpty:tempPoRlcvrd.dropdowndata,
                                    cisyncArryEmpty:tempPoRlcvrd.syncarr,
                                    ciactiveonlyempty:tempPoRlcvrd.ddselectedvalues
                                }" 
                                    v-if="poreldatashw===true">
                            </relationdependent>
                        </div>
                            
                        <v-divider></v-divider>
                        <v-layout style="flex:1" justify-end class="my-4">
                        <v-btn dark tile  @click="clearPOH()" style="font-size:12px;background-color:#e46a25" class="text-capitalize px-3 mx-2" small><v-icon dark small>clear</v-icon> <v-divider vertical class="mx-2"></v-divider> Close</v-btn>
                        <v-btn dark tile  @click="Poh_save('POH')" style="font-size:12px;background-color:#23B1A9" class="text-capitalize px-3 mx-2" small><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider> Save</v-btn>
                            <v-btn dark tile @click="nxt(nextdrive[14])"  style="font-size:12px;background-color:#152F38"
                            class="text-capitalize px-3 mx-2" small><v-icon dark small>arrow_forward</v-icon>
                            <v-divider vertical class="mx-2"></v-divider>Next</v-btn>
                        </v-layout>
                    </v-form>
                </div>
            </div>


    <!-- 14.ph -->       
        <div class="panelis" dense v-if="shwExpnsnPreHosp===true">
                <div @click="currentis(nextdrive[15])"  expand-icon="mdi-menu-down" class="font-weight-bold panhead" >
                    Pre-Hospitalization    <span> <v-icon  :style="{border: datais[nextdrive[15].shown == 'y' ? '5px red solid':'none' ] }"  >arrow_drop_down</v-icon> </span> 
                </div>
                
                <div  class="pa-2"   v-if="datais[nextdrive[15].shown]">
                    <v-divider></v-divider>
                    <v-form v-model="phvalid" ref="phForm">  
                    <v-layout class="mt-3" style="align-items:flex-end" wrap>
                            <v-flex xs3 >
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Coverage Alternate Name</p>
                                    <v-text-field
                                        :rules="alphaRule"
                                        solo
                                        label="Enter..."
                                        v-model="PreHospCoverAltName"
                                        class="rounded-0"
                                        :maxlength="max200"
                                        >
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 >
                                <v-layout column class="cust_pln_cd mx-3 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Coverage Type
                                    </p>
                                    <v-select
                                        :items="cvrgTypItems"
                                        v-model="phcoverageType"
                                        label="Select"
                                        solo
                                        :rules="[required()]"
                                        class="rounded-0">
                                    </v-select>
                                </v-layout>
                            </v-flex>
                
                <v-flex xs3 >
                    <v-layout column class="cust_pln_cd mx-2 ">
                        <p class="font-weight-bold cstm-clr-pln-cd">
                            Coverage Basis
                        </p>
                        <v-select
                            :items="cvrgBasisItems"
                            v-model="phcoverageBasis"
                            label="Select"
                            
                            solo
                            :rules="[required()]"
                            
                        @change="phchangeOnover(1)"
                            class="rounded-0"
                        >
                        </v-select>
                    </v-layout>
                </v-flex>

                <v-flex xs3 v-if="phsubBasisshow==true">
                    <v-layout column class="cust_pln_cd mx-2 ">
                        <p class="font-weight-bold cstm-clr-pln-cd">
                            Sub Limit Basis
                        </p>
                        <v-select
                            :items="cvrgSbLmtBasisItems"
                            v-model="phsubLimitBasis"
                            label="Select"
                            @change="chngeInPREHospSubLimit()"
                            solo
                            multiple
                            :rules="[required()]"
                            class="rounded-0"
                        >
                        <template v-slot:selection="{ item, index }">
                                   
                                    <span v-if="index === 0" >{{ item }}</span>
                                   
                                    <span
                                    v-if="index === 1"
                                    class="grey--text caption"
                                    >
                                    (+{{ phsubLimitBasis.length - 1 }})
                                    </span>
                                </template>
                        </v-select>
                    </v-layout>
                </v-flex>
                        <v-flex xs3 v-if="shSiAmtForPRE===true">
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                   % of SI
                                </p>
                                 <v-text-field
                                    label="Enter..."
                                    v-model="siAmtForPRE"
                                    solo
                                    class="rounded-0"
                                    :maxlength="max10"
                                    :rules="[required(),numberRule,decimalRule()]"
                                    >
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                         <!-- Flat Amount  -->
                        <v-flex xs3 v-if="shFlatAmtForPRE===true">
                            <v-layout column class="cust_pln_cd mx-2  cust-pln-cd">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                    Flat Amount
                                </p>
                                <v-text-field
                                    label="Enter..."
                                    v-model="flatAmtForPRE"
                                    solo
                                    class="rounded-0"
                                    :maxlength="max10"
                                    :rules="[required(),alphaNumeric()]"
                                    >
                                <div slot="prepend-inner" style="background-color:#F7F7F7;padding: 9px !important"><v-icon >mdi-currency-inr </v-icon></div>
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3 v-if="shSiAmtForPrvPRE===true">
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                  % of previous yr SI
                                </p>
                                 <v-text-field
                                    label="Enter..."
                                    v-model="siAmtForPrvPRE"
                                    solo
                                    class="rounded-0"
                                    :maxlength="max10"
                                    :rules="[required(),numberRule,decimalRule()]"
                                    >
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                         <!-- % of claim amount -->
                        <v-flex xs3 v-if="shClaimAmtForPRE===true">
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                  % of claim amount
                                </p>
                                 <v-text-field
                                    label="Enter..."
                                    v-model="claimAmtForPRE"
                                    solo
                                    class="rounded-0"
                                    :maxlength="max10"
                                    :rules="[required(),numberRule,decimalRule()]"
                                    >
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                        <!-- Applicable claim pay-out -->
                         <v-flex xs3 v-if="shwAppCliamPayPreHosp===true">
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                   Applicable claim pay-out
                                </p>
                                <v-select
                                    :items="hacAplcblClmPyotItms"
                                    label="Select"
                                    v-model="AppCliamPayPreHosp"
                                    solo
                                    class="rounded-0"
                                    :rules="[required()]"
                                    >
                                </v-select>
                            </v-layout>
                        </v-flex>
                <v-flex xs3 v-if="phsubBasisshow==true">
                    <v-layout column class="cust_pln_cd mx-2">
                        <p class="font-weight-bold cstm-clr-pln-cd">
                            Claim Payment Basis
                        </p>
                        <v-select
                            :items="ClmpymntBasisItems"
                            v-model="phclaimPaymentBasis"
                            label="Select"
                            @change="chngPREHospClaimPaymentBasis()"
                            solo
                            :rules="[required()]"
                            class="rounded-0"
                        >
                        </v-select>
                    </v-layout>
                </v-flex>
                    <!-- <v-divider  class="ma-2" vertical ></v-divider> -->
                 <!-- Coverage applicable duration (No of years) -->
                         <v-flex xs3 v-if="shwCoveAppDuratPreHosp===true">
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                   Coverage applicable duration (No of years)
                                </p>
                                <v-select
                                    :items="hacCadItms"
                                    label="Select"
                                    v-model="CoveAppDuratPreHosp"
                                    solo
                                    class="rounded-0"
                                    :rules="[required()]"
                                    >
                                </v-select>
                            </v-layout>
                        </v-flex>

                 <v-flex xs3 >
                    <v-layout column class="  mx-3 cust_pln_cd">
                        <p class="font-weight-bold cstm-clr-pln-cd">
                            Duration(No. of Days)
                        </p>
                        <v-text-field
                                solo
                                label="Enter..."
                                v-model="phduration"
                                class="rounded-0"
                                :maxlength="max10"
                                :rules="[required(),alphaNumeric()]"
                            >
                            </v-text-field>
                    </v-layout>
                </v-flex>
                    <v-flex xs3>
                        <v-layout column class="cust_pln_cd mx-2 ">
                            <p class="font-weight-bold cstm-clr-pln-cd">Waiting Period</p>
                            <v-layout>
                                <v-select
                                    :items="['Days','Months']"
                                    label="Select"
                                    v-model="phwaitingPeriodDrop"
                                    solo
                                    style="width: 145px"
                                    class="rounded-0"
                                    :rules="[required()]"
                                    >
                                </v-select>
                                <v-text-field
                                    label="Enter..."
                                    v-model="phwaitingPeriod"
                                    solo
                                    class="rounded-0"
                                    :maxlength="max10"
                                    :rules="[Numeric2]"
                                    >
                                </v-text-field>
                            </v-layout>
                        </v-layout>
                    </v-flex>
                </v-layout>
                <v-divider></v-divider>
                <!-- NEW FIELDS ADDED PRE-HOSPITALISATION -->
                <v-layout class="mt-3" wrap style="align-items:flex-end">
                    <v-flex xs3>
                        <v-layout column class="cust_pln_cd mx-2 ">
                            <p class="font-weight-bold cstm-clr-pln-cd">IRDA UIN Number</p>
                            <v-text-field
                                solo
                                label="Enter..."
                                v-model="PreHospIrdaUINNo"
                                class="rounded-0"
                                :maxlength="max20"
                                :rules="spclCharNew">
                            </v-text-field>
                        </v-layout>
                    </v-flex>
                    <v-flex xs3>
                        <v-layout column class="cust_pln_cd mx-2 ">
                            <p class="font-weight-bold cstm-clr-pln-cd">External Reference Number</p>
                            <v-text-field
                                solo
                                label="Enter..."
                                v-model="PreHospExtRefNo"
                                class="rounded-0"
                                :maxlength="max30"
                                :rules="spclCharNew">
                            </v-text-field>
                        </v-layout>
                    </v-flex>
                    <v-flex xs3>
                        <v-layout column class="cust_pln_cd mx-2 ">
                            <p class="font-weight-bold cstm-clr-pln-cd">Conditions</p>
                            <v-select
                                :items="prodConditionArray"
                                label="Select"
                                v-model="PreHospCondField"
                                multiple
                                solo
                                class="rounded-0"
                                >
                            </v-select>
                        </v-layout>
                    </v-flex>
                    <v-flex xs3>
                        <v-layout column class="cust_pln_cd mx-2 ">
                            <p class="font-weight-bold cstm-clr-pln-cd">GC Coverage</p>
                            <v-select
                                :items="prodGCCoverArray"
                                label="Select"
                                v-model="PreHospGcCover"
                                solo
                                class="rounded-0"
                                :rules="[required()]">
                            </v-select>
                        </v-layout>
                    </v-flex>
                    <v-flex xs3>
                        <v-layout column class="cust_pln_cd mx-2 ">
                            <p class="font-weight-bold cstm-clr-pln-cd">Remarks</p>
                            <v-text-field
                                solo
                                label="Enter..."
                                v-model="PreHospRemarks"
                                class="rounded-0"
                                :maxlength="max40"
                                >
                            </v-text-field>
                        </v-layout>
                    </v-flex>
                    <v-flex xs3>
                        <v-layout column class="cust_pln_cd mx-2 ">
                            <p class="font-weight-bold cstm-clr-pln-cd">Exclusions</p>
                            <v-select
                                :items="prodExcluArray"
                                :maxlength="max30"
                                label="Select"
                                v-model="PreHospExclus"
                                solo
                                class="rounded-0"
                                multiple
                                >
                            </v-select>
                        </v-layout>
                    </v-flex>
                </v-layout>

                <v-divider></v-divider>

                <v-flex xs3 class="">
                    <v-layout column class="mx-2 mb-7 cust_pln_cd" justify-center >
                       <!-- {{indtype.type}} -->
                        <v-text class="font-weight-bold cstm-clr-pln-cd" style="color:#FFFFFF" > </v-text>
                        <div  style="background-color: #f7f7f7;border: 1px solid #c1c8cc;" class="pa-2 mt-5">
                            <v-checkbox class="ma-0 pa-0"  v-model="phisSelectBox" @click="phRdiSelected" hide-details >
                                <span slot="label" class="lbl-bgclr font-weight-bold " style="font-size: 13px;"> Separate Relationship & Dependent Info</span>
                            </v-checkbox>
                        </div>
                    </v-layout>
                </v-flex>
                <div v-if="shwPhRdi===true" class="mt-3">
                    <relationdependent ref="phfrmprop" @save="save_ph_updated" 
                        :redpndntinfo="{
                            cirelDataemty:tempPhRlcvrd.realdata,
                            ciRelnshipCoveredEmpty:tempPhRlcvrd.dropdowndata,
                            cisyncArryEmpty:tempPhRlcvrd.syncarr,
                            ciactiveonlyempty:tempPhRlcvrd.ddselectedvalues
                        }" 
                            v-if="phreldatashw===true">
                    </relationdependent>
                </div>
                    <v-divider></v-divider>
                 <v-layout style="flex:1" justify-end class="my-4">
                  <v-btn dark tile  @click="clearPreHosp()" style="font-size:12px;background-color:#e46a25" class="text-capitalize px-3 mx-2" small><v-icon dark small>close</v-icon> <v-divider vertical class="mx-2"></v-divider> Clear</v-btn>
                  <v-btn dark tile  @click="ph_save('PH')" style="font-size:12px;background-color:#23B1A9" class="text-capitalize px-3 mx-2" small><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider> Save</v-btn>
                    <v-btn dark tile @click="nxt(nextdrive[15])"  style="font-size:12px;background-color:#152F38"
                    class="text-capitalize px-3 mx-2" small><v-icon dark small>arrow_forward</v-icon>
                    <v-divider vertical class="mx-2"></v-divider>Next</v-btn>
                </v-layout>
                </v-form>
                </div>
            </div>
        

<!-- Ashish end -->

          <!-- Family Transportation Benefit   -->
            <div class="panelis" dense v-if="shwExpnsnFtb===true">
                <div @click="currentis(nextdrive[16])" expand-icon="mdi-menu-down" class="font-weight-bold panhead" >
                    Family Transportation Benefit    <span> <v-icon  :style="{border: datais[nextdrive[16].shown == 'y' ? '5px red solid':'none' ] }"  >arrow_drop_down</v-icon> </span> 
                </div>
                
                <div  class="pa-2"   v-if="datais[nextdrive[16].shown]" >
                    <v-divider></v-divider>
                    <v-form v-model="ftbvalid" ref="ftbForm">  
                    <v-layout class="mt-3" style="align-items:flex-end" wrap>
                        <v-flex xs3 >
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">Coverage Alternate Name</p>
                                <v-text-field
                                    :rules="alphaRule"
                                    solo
                                    label="Enter..."
                                    v-model="FTBCoverAltName"
                                    class="rounded-0"
                                    :maxlength="max200">
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3>
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                    Coverage Type
                                </p>
                                <v-select
                                    label="Select"
                                    :items="cvrgTypItems"
                                    v-model="coverageType"
                                    solo
                                    class="rounded-0"
                                    :rules="[required()]"
                                    >
                                </v-select>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3>
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                    Coverage Basis
                                </p>
                                <v-select
                                    label="Select"
                                    :items="cvrgBasisItems"
                                    v-model="coverageBasis"
                                    @change="chngFtbCb(1)"
                                    solo
                                    class="rounded-0"
                                    :rules="[required()]"
                                    >
                                </v-select>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3 v-if="shwFtbSlb===true">
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                    Sub Limit Basis
                                </p>
                                <v-select
                                    label="Select"
                                    multiple
                                    :items="cvrgSbLmtBasisItems"
                                    v-model="subLimitBasis" 
                                    @change="chngeInFTBSubLimit()"
                                    solo
                                    class="rounded-0"
                                    :rules="[required()]"
                                    >
                                    <template v-slot:selection="{ item, index }">
                                   
                                    <span v-if="index === 0" >{{ item }}</span>
                                   
                                    <span
                                    v-if="index === 1"
                                    class="grey--text caption"
                                    >
                                    (+{{ subLimitBasis.length - 1 }} )
                                    </span>
                             </template>
                                </v-select>
                            </v-layout>
                        </v-flex>
                        <!-- % of SI -->
                         <v-flex xs3 v-if="shSiAmtForFTB===true">
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                   % of SI
                                </p>
                                 <v-text-field
                                    label="Enter..."
                                    v-model="siAmtForFTB"
                                    solo
                                    class="rounded-0"
                                    :maxlength="max10"
                                    :rules="[required(),numberRule,decimalRule()]"
                                    >
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                        <!-- Flat Amount  -->
                        <v-flex xs3 v-if="shFlatAmtForFTB===true">
                            <v-layout column class="cust_pln_cd mx-2  cust-pln-cd">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                    Flat Amount
                                </p>
                                <v-text-field
                                    label="Enter..."
                                    v-model="flatAmtForFTB"
                                    solo
                                    class="rounded-0"
                                    :maxlength="max10"
                                    :rules="[required(),alphaNumeric()]"
                                    >
                                <div slot="prepend-inner" style="background-color:#F7F7F7;padding: 9px !important"><v-icon >mdi-currency-inr </v-icon></div>
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3 v-if="shSiAmtPrvForFTB===true">
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                   % of previous yr SI
                                </p>
                                 <v-text-field
                                    label="Enter..."
                                    v-model="siAmtPrvForFTB"
                                    solo
                                    class="rounded-0"
                                    :maxlength="max10"
                                    :rules="[required(),numberRule,decimalRule()]"
                                    >
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                        <!-- % of claim amount -->
                        <v-flex xs3 v-if="shClaimAmtForFTB===true">
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                  % of claim amount
                                </p>
                                 <v-text-field
                                    label="Enter..."
                                    v-model="claimAmtForFTB"
                                    solo
                                    class="rounded-0"
                                    :maxlength="max10"
                                    :rules="[required(),numberRule,decimalRule()]"
                                    >
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                        <!-- Applicable claim pay-out -->
                         <v-flex xs3 v-if="shwAppCliamPay===true">
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                   Applicable claim pay-out
                                </p>
                                <v-select
                                    :items="hacAplcblClmPyotItms"
                                    label="Select"
                                    v-model="AppCliamPay"
                                    solo
                                    class="rounded-0"
                                    :rules="[required()]"
                                    >
                                </v-select>
                            </v-layout>
                        </v-flex>
                         <v-flex xs3 v-if="shwFtbSlb===true">
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                    Claim Payment Basis
                                </p>
                                <v-select
                                    label="Select"
                                    :items="ClmpymntBasisItems"
                                    
                                    v-model="claimPaymentBasis"
                                    @change="chngFTBClaimPaymentBasis()"
                                    solo
                                    class="rounded-0"
                                    :rules="[required()]"
                                    >
                               
                                </v-select>
                            </v-layout>
                        </v-flex>


                        <!-- Coverage applicable duration (No of years) -->
                         <v-flex xs3 v-if="shwCoveAppDurat===true">
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                   Coverage applicable duration (No of years)
                                </p>
                                <v-select
                                    :items="hacCadItms"
                                    label="Select"
                                    v-model="CoveAppDurat"
                                    solo
                                    class="rounded-0"
                                    :rules="[required()]"
                                    >
                                </v-select>
                            </v-layout>
                        </v-flex>


                        <v-flex xs3>
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                    Min Eligible Distance From Insured Residence
                                </p>
                                <v-text-field
                                    label="Enter..."
                                    v-model="MnElgblDstnceFrmInsrdRsdnc"
                                    solo
                                    class="rounded-0"
                                    :maxlength="max10"
                                    :rules="[required(),alphaNumeric()]"
                                    >
                                </v-text-field>
                            </v-layout>
                        </v-flex>

                        <v-flex xs3>
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">Waiting Period</p>
                                <v-layout>
                                    <v-select
                                        :items="['Days','Months']"
                                        label="Select"
                                        v-model="FTBwaitingPeriodDrop"
                                        solo
                                        style="width: 145px"
                                        class="rounded-0"
                                        :rules="[required()]"
                                        >
                                    </v-select>
                                    <v-text-field
                                        label="Enter..."
                                        v-model="FTBwaitingPeriod"
                                        solo
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[Numeric2]"
                                        >
                                    </v-text-field>
                                </v-layout>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3>
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                   Allowed Count of Immediate Family Member
                                </p>
                                <v-text-field
                                    label="Enter..."
                                    v-model="AlwdCntofImdteFmlyMmbr"
                                    solo
                                    class="rounded-0"
                                    :maxlength="max10"
                                    :rules="[required(),alphaNumeric()]"
                                    >
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                    </v-layout>
                    <v-divider></v-divider>
                    <!-- NEW FIELDS ADDED FTB -->
                        <v-layout class="mt-3" wrap style="align-items:flex-end">
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">IRDA UIN Number</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="FTBIrdaUINNo"
                                        class="rounded-0"
                                        :maxlength="max20"
                                        :rules="spclCharNew">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">External Reference Number</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="FTBExtRefNo"
                                        class="rounded-0"
                                        :maxlength="max30"
                                        :rules="spclCharNew">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Conditions</p>
                                    <v-select
                                        :items="prodConditionArray"
                                        label="Select"
                                        v-model="FTBCondField"
                                        multiple
                                        solo
                                        class="rounded-0">
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">GC Coverage</p>
                                    <v-select
                                        :items="prodGCCoverArray"
                                        label="Select"
                                        v-model="FTBGcCover"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]">
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Remarks</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="FTBRemarks"
                                        class="rounded-0"
                                        :maxlength="max54">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Exclusions</p>
                                    <v-select
                                        :items="prodExcluArray"
                                        :maxlength="max30"
                                        label="Select"
                                        v-model="FTBExclus"
                                        solo
                                        class="rounded-0"
                                        multiple>
                                    </v-select>
                                </v-layout>
                            </v-flex>
                        </v-layout> 
                    <v-divider></v-divider>
                    <v-flex xs3>
                        <v-layout column class="mx-2 mb-7 cust_pln_cd" justify-center >
                            <p class="font-weight-bold cstm-clr-pln-cd" style="color:#FFFFFF" ></p>
                            <div style="background-color: #f7f7f7;border: 1px solid #c1c8cc;" class="pa-2 mt-5">
                                <v-checkbox class="ma-0 pa-0" v-model="FTBSelectBox" @change="FTBRelCheckSelected()" hide-details>
                                    <span slot="label" class="cust_lbl-bgclr font-weight-bold" style="font-size: 13px">Separate Relationship & Dependent Info</span>
                                </v-checkbox>
                            </div>
                        </v-layout>
                    </v-flex>
                        
                    <div v-if="showFTBRelSection == true">
                        <relationdependent 
                            ref="FTBRel" 
                            @save="saveFTBRel" 
                            :redpndntinfo="{
                                cirelDataemty:FTBrelCoverData.realdata,
                                ciRelnshipCoveredEmpty:FTBrelCoverData.dropdowndata,
                                cisyncArryEmpty:FTBrelCoverData.syncarr,
                                ciactiveonlyempty:FTBrelCoverData.ddselectedvalues
                            }" 
                            v-if="showFTBRelData == true">
                        </relationdependent>
                    </div>
                    <v-divider></v-divider>
                    <v-layout style="flex:1" justify-end class="my-4">
                        <v-btn dark tile  style="font-size:12px;background-color:#e46a25"  class="text-capitalize px-3 mx-2" small @click="clearFTB()"><v-icon dark small>close</v-icon> <v-divider vertical class="mx-2"></v-divider>Clear</v-btn>
                        <v-btn dark tile  style="font-size:12px;background-color:#23B1A9"  class="text-capitalize px-3 mx-2" small @click="submitFTB()"><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider>Save</v-btn>
                        <v-btn dark tile @click="nxt(nextdrive[16])" style="font-size:12px;background-color:#152F38"
                        class="text-capitalize px-3 ml-2 mr-6" small><v-icon dark small>arrow_forward</v-icon>
                        <v-divider vertical class="mx-2"></v-divider> Next</v-btn>
                    </v-layout>
                </v-form>

                </div>
                
            </div>
            
      

        
            <div class="panelis" dense v-if="shwExpnsnCtc===true">
                <div @click="currentis(nextdrive[17])" expand-icon="mdi-menu-down" class="font-weight-bold panhead" >
                    Compassionate Travel Coverage   <span> <v-icon  :style="{border: datais[nextdrive[17].shown == 'y' ? '5px red solid':'none' ] }"  >arrow_drop_down</v-icon> </span> 
                </div>
             
                <div  class="pa-2"   v-if="datais[nextdrive[17].shown]">
                    <v-divider></v-divider>
                    <v-form v-model="ctvalid" ref="ctForm">
                    <v-layout class="mt-3" wrap style="align-items:flex-end">
                        <v-flex xs3 >
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">Coverage Alternate Name</p>
                                <v-text-field
                                    :rules="alphaRule"
                                    solo
                                    label="Enter..."
                                    v-model="CTCCoverAltName"
                                    class="rounded-0"
                                    :maxlength="max200"
                                    >
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3>
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                    Coverage Type
                                </p>
                                <v-select
                                    label="Select"
                                    :items="cvrgTypItems"
                                    v-model="ctCoverageType"
                                    solo
                                    class="rounded-0"
                                    :rules="[required()]"
                                    
                                    >
                                </v-select>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3>
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                    Coverage Basis
                                </p>
                                <v-select
                                    label="Select"
                                    :items="cvrgBasisItems"
                                    v-model="ctCoverageBasis"
                                    @change="chngCtCb(1)"
                                    solo
                                    class="rounded-0"
                                    :rules="[required()]"
                                    
                                    >
                                </v-select>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3 v-if="shwCtSlb===true">
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                    Sub Limit Basis
                                </p>
                                <v-select
                                    label="Select"
                                    :items="cvrgSbLmtBasisItems"
                                    v-model="ctSubLimitBasis"
                                    @change="chngCtSubLmtBasis()"
                                    solo
                                    multiple
                                    class="rounded-0"
                                    :rules="[required()]"
                                    
                                    >
                                    <template v-slot:selection="{ item, index }">
                                   
                                    <span v-if="index === 0" >{{ item }}</span>
                                   
                                    <span
                                    v-if="index === 1"
                                    class="grey--text caption"
                                    >
                                    (+{{ ctSubLimitBasis.length - 1 }})
                                    </span>
                             </template>
                                </v-select>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3 v-if="shwCtPrcntSi===true">
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                   % of SI
                                </p>
                                 <v-text-field
                                    label="Enter..."
                                    v-model="ctPrcntSi"
                                    solo
                                    class="rounded-0"
                                    :maxlength="max10"
                                    :rules="[required(),numberRule,decimalRule()]"
                                    >
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3 v-if="shwCtFltAmt===true">
                            <v-layout column class="cust_pln_cd mx-2  cust-pln-cd">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                    Flat Amount
                                </p>
                                <v-text-field
                                    label="Enter..."
                                    v-model="ctFlatAmt"
                                    solo
                                    class="rounded-0"
                                    :maxlength="max10"
                                    :rules="[required(),alphaNumeric()]"
                                    >
                                <div slot="prepend-inner" style="background-color:#F7F7F7;padding: 9px !important"><v-icon >mdi-currency-inr </v-icon></div>
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3 v-if="shwCtPrcntPrvSi===true">
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                   % of previous yr SI
                                </p>
                                 <v-text-field
                                    label="Enter..."
                                    v-model="ctPrcntPrvSi"
                                    solo
                                    class="rounded-0"
                                    :maxlength="max10"
                                    :rules="[required(),numberRule,decimalRule()]"
                                    >
                                </v-text-field>
                            </v-layout>
                        </v-flex>

                        
                        <v-flex xs3 v-if="shwCtPrcntClmAmt===true">
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                  % of claim amount
                                </p>
                                 <v-text-field
                                    label="Enter..."
                                    v-model="ctPrcntClmAmt"
                                    solo
                                    class="rounded-0"
                                    :maxlength="max10"
                                    :rules="[required(),numberRule,decimalRule()]"
                                    >
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3 v-if="showCTCOpdSi == true">
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">% of OPD SI</p>
                                <v-text-field
                                    solo
                                    label="Enter..."
                                    v-model="CTCOpdSi"
                                    class="rounded-0"
                                    :maxlength="max10"
                                    :rules="[required(),numberRule,decimalRule()]">
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3 v-if="showCTCAdSi == true">
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">% of AD SI</p>
                                <v-text-field
                                    solo
                                    label="Enter..."
                                    v-model="CTCAdSi"
                                    class="rounded-0"
                                    :maxlength="max10"
                                    :rules="[required(),numberRule,decimalRule()]">
                                </v-text-field>
                            </v-layout>
                        </v-flex>

                        <v-flex xs3 v-if="shwCtctAplcblClmPyot===true">
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                    Applicable claim pay-out
                                </p>
                                <v-select
                                    :items="hacAplcblClmPyotItms"
                                    label="Select"
                                    v-model="ctAplcblClmPyot"
                                    solo
                                    class="rounded-0"
                                    :rules="[required()]"
                                     >
                                </v-select>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3 v-if="shwCtSlb===true">
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                    Claim Payment Basis
                                </p>
                                <v-select
                                    label="Select"
                                    :items="ClmpymntBasisItems"
                                    
                                    v-model="ctClaimPaymentBasis"
                                    @change="chngCtClaimPaymentBasis()"
                                    solo
                                    class="rounded-0"
                                    :rules="[required()]"
                                    
                                    >
                               
                                </v-select>
                            </v-layout>
                        </v-flex>
                        

                         <v-flex xs3 v-if="shwCtCvrgAplcblDrtn===true">
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                   Coverage applicable duration (No of years)
                                </p>
                                <v-select
                                    :items="hacCadItms"
                                    label="Select"
                                    v-model="CtCad"
                                    solo
                                    class="rounded-0"
                                    :rules="[required()]"
                                    
                                    >
                                </v-select>
                            </v-layout>
                        </v-flex>
                 
                        <v-flex xs3>
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">Waiting Period</p>
                                <v-layout>
                                    <v-select
                                        :items="['Days','Months']"
                                        label="Select"
                                        v-model="CTCwaitingPeriodDrop"
                                        solo
                                        style="width: 145px"
                                        class="rounded-0"
                                        :rules="[required()]"
                                        >
                                    </v-select>
                                    <v-text-field
                                        label="Enter..."
                                        v-model="ctWaitingPeriod"
                                        solo
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[Numeric2]"
                                        >
                                    </v-text-field>
                                </v-layout>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3>
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                   Minimum Hospitalization Duration for Eligible Claim(No. of Hours)
                                </p>
                                <v-text-field
                                    label="Enter..."
                                    v-model="ctMnmHspitlztnDrtn"
                                    solo
                                    class="rounded-0"
                                    :maxlength="max10"
                                    :rules="[Numeric2]"
                                    >
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                        <!-- <v-flex xs3> </v-flex>
                        <v-flex xs3> </v-flex>
                        <v-flex xs3> </v-flex> -->

                    </v-layout>
                    <v-divider></v-divider>
                    <!-- NEW FIELDS ADDED CTC-->
                    <v-layout class="mt-3" wrap style="align-items:flex-end">
                        <v-flex xs3>
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">IRDA UIN Number</p>
                                <v-text-field
                                    solo
                                    label="Enter..."
                                    v-model="CTCIrdaUINNo"
                                    class="rounded-0"
                                    :maxlength="max20"
                                    :rules="spclCharNew">
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3>
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">External Reference Number</p>
                                <v-text-field
                                    solo
                                    label="Enter..."
                                    v-model="CTCExtRefNo"
                                    class="rounded-0"
                                    :maxlength="max30"
                                    :rules="spclCharNew">
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3>
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">Conditions</p>
                                <v-select
                                    :items="prodConditionArray"
                                    label="Select"
                                    v-model="CTCCondField"
                                    multiple
                                    solo
                                    class="rounded-0"
                                    >
                                </v-select>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3>
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">GC Coverage</p>
                                <v-select
                                    :items="prodGCCoverArray"
                                    label="Select"
                                    v-model="CTCGcCover"
                                    solo
                                    class="rounded-0"
                                    :rules="[required()]">
                                </v-select>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3>
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">Remarks</p>
                                <v-text-field
                                    solo
                                    label="Enter..."
                                    v-model="CTCRemarks"
                                    class="rounded-0"
                                    :maxlength="max40"
                                    >
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3>
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">Exclusions</p>
                                <v-select
                                    :items="prodExcluArray"
                                    :maxlength="max30"
                                    label="Select"
                                    v-model="CTCExclus"
                                    solo
                                    class="rounded-0"
                                    multiple
                                    >
                                </v-select>
                            </v-layout>
                        </v-flex>
                    </v-layout>
                    <v-divider></v-divider>

                    <v-flex xs3>
                        <v-layout column class="mx-2 mb-7 cust_pln_cd" justify-center >
                            <p class="font-weight-bold cstm-clr-pln-cd" style="color:#FFFFFF" ></p>
                            <div style="background-color: #f7f7f7;border: 1px solid #c1c8cc;" class="pa-2 mt-5">
                                <v-checkbox class="ma-0 pa-0" v-model="CTCSelectBox" @change="CTCRelCheckSelected()" hide-details>
                                    <span slot="label" class="cust_lbl-bgclr font-weight-bold" style="font-size: 13px">Separate Relationship & Dependent Info</span>
                                </v-checkbox>
                            </div>
                        </v-layout>
                    </v-flex>
                        
                    <div v-if="showCTCRelSection == true">
                        <relationdependent 
                            ref="CTCRel" 
                            @save="saveCTCVC" 
                            :redpndntinfo="{
                                cirelDataemty:CTCrelCoverData.realdata,
                                ciRelnshipCoveredEmpty:CTCrelCoverData.dropdowndata,
                                cisyncArryEmpty:CTCrelCoverData.syncarr,
                                ciactiveonlyempty:CTCrelCoverData.ddselectedvalues
                            }" 
                            v-if="showCTCRelData == true">
                        </relationdependent>
                    </div>
                    
                    <v-divider></v-divider>
                 <v-layout style="flex:1" justify-end class="my-4">
                    <v-btn dark tile  style="font-size:12px;background-color:#e46a25" class="text-capitalize px-3 mx-2" small @click="clearCTC()"><v-icon dark small>close</v-icon> <v-divider vertical class="mx-2"></v-divider>Clear</v-btn>
                    <v-btn dark tile  style="font-size:12px;background-color:#23B1A9" class="text-capitalize px-3 mx-2" small @click="submitCTCVC('ct')"><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider>Save</v-btn>
                    <v-btn dark style="font-size:12px;background-color:#152F38"
                    @click="nxt(nextdrive[17])" tile  class="text-capitalize px-3 ml-2 mr-6" small>
                    <v-icon dark small>arrow_forward</v-icon> <v-divider vertical class="mx-2"></v-divider> Next</v-btn>
                </v-layout>
                </v-form>
                    

                </div>
                
            </div>
            
        

            <div class="panelis" dense v-if="shwExpnsnGc===true">
                <div @click="currentis(nextdrive[18])" expand-icon="mdi-menu-down" class="font-weight-bold panhead" >
                    Global Cover   <span> <v-icon  :style="{border: datais[nextdrive[18].shown == 'y' ? '5px red solid':'none' ] }"  >arrow_drop_down</v-icon> </span> 
                </div>
                
                <div  class="pa-2"   v-if="datais[nextdrive[18].shown]">
                    <v-divider></v-divider>
                    <v-form v-model="gcvalid" ref="gcForm">
                    <v-layout class="mt-3" wrap style="align-items:flex-end">
                        <v-flex xs3 >
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">Coverage Alternate Name</p>
                                <v-text-field
                                    :rules="alphaRule"
                                    solo
                                    label="Enter..."
                                    v-model="GCCoverAltName"
                                    class="rounded-0"
                                    :maxlength="max200"
                                    >
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3>
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                    Coverage Type
                                </p>
                                <v-select
                                    label="Select"
                                    :items="cvrgTypItems"
                                    v-model="gcCoverageType"
                                    solo
                                    class="rounded-0"
                                    :rules="[required()]"
                                    >
                                </v-select>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3>
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                    Coverage Basis
                                </p>
                                <v-select
                                    label="Select"
                                    :items="cvrgBasisItems"
                                    v-model="gcCoverageBasis"
                                    @change="chngGcCb(1)"
                                    solo
                                    class="rounded-0"
                                    :rules="[required()]"
                                    >
                                </v-select>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3 v-if="shwGcSlb===true">
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                    Sub Limit Basis
                                </p>
                                <v-select
                                    label="Select"
                                    :items="cvrgSbLmtBasisItems"
                                    v-model="gcSubLimitBasis"
                                    @change="chngGcSubLmtBasis()"
                                    solo
                                    class="rounded-0"
                                    multiple
                                    :rules="[required()]"
                                    >
                                     <template v-slot:selection="{ item, index }">
                                   
                                    <span v-if="index === 0" >{{ item }}</span>
                                   
                                    <span
                                    v-if="index === 1"
                                    class="grey--text caption"
                                    >
                                    (+{{ gcSubLimitBasis.length - 1 }} )
                                    </span>
                             </template>
                                </v-select>
                            </v-layout>
                        </v-flex>
                        
                        <v-flex xs3 v-if="shwGcPrcntSi===true">
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                   % of SI
                                </p>
                                 <v-text-field
                                    label="Enter..."
                                    v-model="gcPrcntSi"
                                    solo
                                    class="rounded-0"
                                    :maxlength="max10"
                                    :rules="[required(),numberRule,decimalRule()]"
                                    >
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                         <v-flex xs3 v-if="shwGcFltAmt===true">
                            <v-layout column class="cust_pln_cd mx-2  cust-pln-cd">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                    Flat Amount
                                </p>
                                <v-text-field
                                    label="Enter..."
                                    v-model="gcFlatAmt"
                                    solo
                                    class="rounded-0"
                                    :maxlength="max10"
                                    :rules="[required(),alphaNumeric()]"
                                    >
                                <div slot="prepend-inner" style="background-color:#F7F7F7;padding: 9px !important"><v-icon >mdi-currency-inr </v-icon></div>
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3 v-if="shwGcPrcntPrvSi===true">
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                   % of previous yr SI
                                </p>
                                 <v-text-field
                                    label="Enter..."
                                    v-model="gcPrcntPrvSi"
                                    solo
                                    class="rounded-0"
                                    :maxlength="max10"
                                    :rules="[required(),numberRule,decimalRule()]"
                                    >
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                        
                        <v-flex xs3 v-if="shwGcPrcntClmAmt===true">
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                  % of claim amount
                                </p>
                                 <v-text-field
                                    label="Enter..."
                                    v-model="gcPrcntClmAmt"
                                    solo
                                    class="rounded-0"
                                    :maxlength="max10"
                                    :rules="[required(),numberRule,decimalRule()]"
                                    >
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3 v-if="shwGcAplcblClmPyot===true">
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                    Applicable claim pay-out
                                </p>
                                <v-select
                                    :items="hacAplcblClmPyotItms"
                                    label="Select"
                                    v-model="gcAplcblClmPyot"
                                    solo
                                    class="rounded-0"
                                    :rules="[required()]"
                                    
                                    >
                                </v-select>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3 v-if="shwGcSlb===true">
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                    Claim Payment Basis
                                </p>
                                <v-select
                                    label="Select"
                                    :items="ClmpymntBasisItems"
                                    
                                    v-model="gcClaimPaymentBasis"
                                    @change="chngGcClaimPaymentBasis()"
                                    solo
                                    class="rounded-0"
                                    :rules="[required()]"
                                    >
                                </v-select>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3 v-if="shwgcCvrgAplcblDrtn===true">
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                   Coverage applicable duration (No of years)
                                </p>
                                <v-select
                                    :items="hacCadItms"
                                    label="Select"
                                    v-model="gcCad"
                                    solo
                                    class="rounded-0"
                                    :rules="[required()]"
                                    
                                    >
                                </v-select>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3>
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                    Country of Disease Detection
                                </p>
                                <v-select
                                    label="Select"
                                    :items="['India']"
                                    v-model="gcCntryofDseDtctn"
                                    solo
                                    class="rounded-0"
                                    :rules="[required()]"
                                    >
                                </v-select>
                            </v-layout>
                        </v-flex>
                    
                        <v-flex xs3>
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">Waiting Period</p>
                                <v-layout>
                                    <v-select
                                        :items="['Days','Months']"
                                        label="Select"
                                        v-model="gcwaitingPeriodDrop"
                                        solo
                                        style="width: 145px"
                                        class="rounded-0"
                                        :rules="[required()]"
                                        >
                                    </v-select>
                                    <v-text-field
                                        label="Enter..."
                                        v-model="gcWaitingPeriod"
                                        solo
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[Numeric2]"
                                        >
                                    </v-text-field>
                                </v-layout>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3>
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                   Minimum Hospitalization Duration for Eligible Claim(No. of Days)
                                </p>
                                <v-text-field
                                    label="Enter..."
                                    v-model="gcMnmHspitlztnDrtn"
                                    solo
                                    class="rounded-0"
                                    :maxlength="max10"
                                     :rules="[required(),alphaNumeric()]"
                                    >
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                       
                    </v-layout>
                    
                     <v-divider></v-divider>
                    <!-- NEW FIELDS ADDED GC-->
                    <v-layout class="mt-3" wrap style="align-items:flex-end">
                        <v-flex xs3>
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">IRDA UIN Number</p>
                                <v-text-field
                                    solo
                                    label="Enter..."
                                    v-model="GCIrdaUINNo"
                                    class="rounded-0"
                                    :maxlength="max20"
                                    :rules="spclCharNew">
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3>
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">External Reference Number</p>
                                <v-text-field
                                    solo
                                    label="Enter..."
                                    v-model="GCExtRefNo"
                                    class="rounded-0"
                                    :maxlength="max30"
                                    :rules="spclCharNew">
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3>
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">Conditions</p>
                                <v-select
                                    :items="prodConditionArray"
                                    label="Select"
                                    v-model="GCCondField"
                                    multiple
                                    solo
                                    class="rounded-0"
                                    >
                                </v-select>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3>
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">GC Coverage</p>
                                <v-select
                                    :items="prodGCCoverArray"
                                    label="Select"
                                    v-model="GCGcCover"
                                    solo
                                    class="rounded-0"
                                    :rules="[required()]">
                                </v-select>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3>
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">Remarks</p>
                                <v-text-field
                                    solo
                                    label="Enter..."
                                    v-model="GCRemarks"
                                    class="rounded-0"
                                    :maxlength="max40"
                                    >
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3>
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">Exclusions</p>
                                <v-select
                                    :items="prodExcluArray"
                                    :maxlength="max30"
                                    label="Select"
                                    v-model="GCExclus"
                                    solo
                                    class="rounded-0"
                                    multiple
                                    >
                                </v-select>
                            </v-layout>
                        </v-flex>
                    </v-layout>
                    <v-divider></v-divider>
                     <v-flex xs3 >
                        <v-layout column class="mx-2 mb-7 cust_pln_cd" justify-center >
                            <span class="font-weight-bold cstm-clr-pln-cd" style="color:#FFFFFF" ></span>
                            <div style="background-color: #f7f7f7;border: 1px solid #c1c8cc;" class="pa-2 mt-5">
                                <v-checkbox class="ma-0 pa-0" v-model="gccheckbox"  @click="gcRdiSelected" hide-details>
                                    <span slot="label" class="cust_lbl-bgclr font-weight-bold" style="font-size: 13px">Separate Relationship & Dependent Info</span>
                                </v-checkbox>
                            </div>
                        </v-layout>
                    </v-flex>
                    <div v-if="shwGcRdi===true" class="mt-3">
                        <relationdependent ref="gcfrmprop" @save="save_CI_updated" 
                            :redpndntinfo="{
                                        cirelDataemty:tempGcRlcvrd.realdata,
                                        ciRelnshipCoveredEmpty:tempGcRlcvrd.dropdowndata,
                                        cisyncArryEmpty:tempGcRlcvrd.syncarr,
                                        ciactiveonlyempty:tempGcRlcvrd.ddselectedvalues
                                    }" 
                            v-if="gcreldatashw===true">
                        </relationdependent>
                    </div>
                    <v-divider></v-divider>
                 <v-layout style="flex:1" justify-end class="my-4">
                    <v-btn dark tile style="font-size:12px;background-color:#e46a25" class="text-capitalize px-3 mx-2" small @click="clearGC()"><v-icon dark small>close</v-icon> <v-divider vertical class="mx-2"></v-divider>Clear</v-btn>
                    <v-btn dark tile style="font-size:12px;background-color:#23B1A9" class="text-capitalize px-3 mx-2" small @click="save_GcCi('GC')"><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider>Save</v-btn>
                    <v-btn dark @click="nxt(nextdrive[18])" style="font-size:12px;background-color:#152F38"  tile 
                    class="text-capitalize px-3 ml-2 mr-6" small><v-icon dark small>arrow_forward</v-icon> <v-divider vertical class="mx-2"></v-divider> Next</v-btn>
                </v-layout>
                </v-form>
                    
                </div>
                
            </div>

            <div class="panelis" v-if="showNAAccor == true" dense>
                <div @click="currentis(nextdrive[19])" expand-icon="mdi-menu-down" class="font-weight-bold panhead" >
                    Nursing Allowance   <span> <v-icon  :style="{border: datais[nextdrive[19].shown == 'y' ? '5px red solid':'none' ] }"  >arrow_drop_down</v-icon> </span> 
                </div>
                
                <div class="pa-2" v-if="datais[nextdrive[19].shown]">
                    <v-divider></v-divider>
                    <v-form v-model="navalid" ref="naForm">
                        <v-layout class="mt-3" wrap>
                            <v-flex xs3 >
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Coverage Alternate Name</p>
                                    <v-text-field
                                        :rules="alphaRule"
                                        solo
                                        label="Enter..."
                                        v-model="NACoverAltName"
                                        class="rounded-0"
                                        :maxlength="max200"
                                        >
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Coverage Type
                                    </p>
                                    <v-select
                                        label="Select"
                                        :items="cvrgTypItems"
                                        v-model="naCoverageType"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]"
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Coverage Basis
                                    </p>
                                    <v-select
                                        label="Select"
                                        :items="cvrgBasisItems"
                                        v-model="naCoverageBasis"
                                        @change="chngNaCb(1)"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]"
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            
                            <v-flex xs3 v-if="shwNaSlb === true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Sub Limit Basis
                                    </p>
                                    <v-select
                                        :items="cvrgSbLmtBasisItems"
                                        label="Select"
                                        v-model="naSubLimitBasis"
                                        solo
                                        multiple
                                        class="rounded-0"
                                        @change="chngNaSubLmtBasis(naSubLimitBasis)"
                                        :rules="[required()]">
                                        <template v-slot:selection="{ item, index }">
                                            <span v-if="index === 0" >{{ item }}</span>
                                            <span
                                            v-if="index === 1"
                                            class="grey--text caption"
                                            >
                                            (+{{ naSubLimitBasis.length - 1 }} )
                                            </span>
                                        </template>
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            
                            <v-flex xs3 v-if="showNAPercentSi == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">% of SI</p>
                                    <v-text-field
                                        solo
                                        dense
                                        label="Enter..."
                                        v-model="NAPercentSI"
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),numberRule,decimalRule()]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="showNAFlatAmnt == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Flat Amount</p>
                                    <v-text-field
                                        solo
                                        dense
                                        label="Enter..."
                                        v-model="NAFlatAmountTxtField"
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),alphaNumeric()]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="showNAPrvPercentSi == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">% of previous yr SI</p>
                                    <v-text-field
                                        solo
                                        dense
                                        label="Enter..."
                                        v-model="NAPrvPercentSI"
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),numberRule,decimalRule()]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="showNAPercClaimAmt == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">% of Claim Amount</p>
                                    <v-text-field
                                        solo
                                        dense
                                        label="Enter..."
                                        v-model="NAPercentClaimAmt"
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),numberRule,decimalRule()]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="showNAOpdSi == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">% of OPD SI</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="NAOpdSi"
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),numberRule,decimalRule()]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="showNAAdSi == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">% of AD SI</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="NAAdSi"
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),numberRule,decimalRule()]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>

                            
                            <v-flex xs3 v-if="showNAApplicClaimPay == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Applicable Claim Pay-Out</p>
                                    <v-select
                                        :items="hacAplcblClmPyotItms"
                                        label="Select"
                                        v-model="NAApplicClaimPayOut"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]"
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Claim Payment Basis
                                    </p>
                                    <v-select
                                        label="Select"
                                    :items="ClmpymntBasisItems"
                                    
                                        v-model="naClaimPaymentBasis"
                                        solo
                                        @change="changeNAClaimPayBasis(naClaimPaymentBasis)"
                                        class="rounded-0"
                                        :rules="[required()]"
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            
                            <v-flex xs3 v-if="showNACovrageApplicDur == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Coverage applicable duration (No of years)
                                    </p>
                                    <v-select
                                        :items="hacCadItms"
                                        label="Select"
                                        v-model="NACovrageApplicDur"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]"
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Waiting Period</p>
                                    <v-layout>
                                        <v-select
                                            :items="['Days','Months']"
                                            label="Select"
                                            v-model="NAwaitingPeriodDrop"
                                            solo
                                            style="width: 145px"
                                            class="rounded-0"
                                            :rules="[required()]"
                                            >
                                        </v-select>
                                        <v-text-field
                                            label="Enter..."
                                            v-model="naWtngPrd"
                                            solo
                                            class="rounded-0"
                                            :maxlength="max10"
                                            :rules="[Numeric2]"
                                            >
                                        </v-text-field>
                                    </v-layout>
                                </v-layout>
                            </v-flex>
                            
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                    Max Payable Duration (no. of days)
                                    </p>
                                    <v-text-field
                                        label="Enter..."
                                        v-model="naMxPyblDrtn"
                                        solo
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),alphaNumeric()]"
                                        
                                        >
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                    Deductible (no. of days)
                                    </p>
                                    <v-text-field
                                        label="Enter..."
                                        v-model="naDeductible"
                                        solo
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),alphaNumeric()]"
                                        >
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                        </v-layout>
                        <v-divider></v-divider>

                        <!-- NEW FIELDS ADDED NA-->
                        <v-layout class="mt-3" wrap style="align-items:flex-end">
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">IRDA UIN Number</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="NAIrdaUINNo"
                                        class="rounded-0"
                                        :maxlength="max20"
                                        :rules="spclCharNew">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">External Reference Number</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="NAExtRefNo"
                                        class="rounded-0"
                                        :maxlength="max30"
                                        :rules="spclCharNew">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Conditions</p>
                                    <v-select
                                        :items="prodConditionArray"
                                        label="Select"
                                        v-model="NACondField"
                                        multiple
                                        solo
                                        class="rounded-0"
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">GC Coverage</p>
                                    <v-select
                                        :items="prodGCCoverArray"
                                        label="Select"
                                        v-model="NAGcCover"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]">
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Remarks</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="NARemarks"
                                        class="rounded-0"
                                        :maxlength="max40"
                                        >
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Exclusions</p>
                                    <v-select
                                        :items="prodExcluArray"
                                        :maxlength="max30"
                                        label="Select"
                                        v-model="NAExclus"
                                        solo
                                        class="rounded-0"
                                        multiple
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                        </v-layout>

                        <v-divider></v-divider>

                        <v-flex xs3>
                            <v-layout column class="mx-2 mb-7 cust_pln_cd" justify-center >
                                <p class="font-weight-bold cstm-clr-pln-cd" style="color:#FFFFFF" ></p>
                                <div style="background-color: #f7f7f7;border: 1px solid #c1c8cc;" class="pa-2 mt-5">
                                    <v-checkbox class="ma-0 pa-0" v-model="NASelectBox" @change="NARelCheckSelected()" hide-details>
                                        <span slot="label" class="cust_lbl-bgclr font-weight-bold" style="font-size: 13px">Separate Relationship & Dependent Info</span>
                                    </v-checkbox>
                                </div>
                            </v-layout>
                        </v-flex>
                         
                        <div v-if="showNARelSection == true">
                            <relationdependent 
                                ref="NARel" 
                                @save="saveEBNARel" 
                                :redpndntinfo="{
                                    cirelDataemty:NArelCoverData.realdata,
                                    ciRelnshipCoveredEmpty:NArelCoverData.dropdowndata,
                                    cisyncArryEmpty:NArelCoverData.syncarr,
                                    ciactiveonlyempty:NArelCoverData.ddselectedvalues
                                }" 
                                v-if="showNARelData == true">
                            </relationdependent>
                        </div>
                        <v-divider></v-divider>
                        <v-layout style="flex:1" justify-end class="my-4">
                            <v-btn dark tile  style="font-size:12px;background-color:#e46a25" class="text-capitalize px-3 mx-2" small @click="clearNA()"><v-icon dark small>close</v-icon> <v-divider vertical class="mx-2"></v-divider>Clear</v-btn>
                            <v-btn dark tile  style="font-size:12px;background-color:#23B1A9" class="text-capitalize px-3 mx-2" small @click="submitEBNA('nurseAllowance')"><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider>Save</v-btn>
                            <v-btn dark style="font-size:12px;background-color:#152F38" @click="nxt(nextdrive[19])" tile
                            class="text-capitalize px-3 ml-2 mr-6" small><v-icon dark small>arrow_forward</v-icon> <v-divider vertical class="mx-2"></v-divider> Next</v-btn>
                        </v-layout>
                    </v-form>
                </div>
            </div>

            <div class="panelis" dense v-if="shwExpnsnOpdT===true">
                <div @click="currentis(nextdrive[20])" expand-icon="mdi-menu-down" class="font-weight-bold panhead" >
                    OPD Treatment   <span> <v-icon  :style="{border: datais[nextdrive[20].shown == 'y' ? '5px red solid':'none' ] }"  >arrow_drop_down</v-icon> </span> 
                </div>
                
                <div  class="pa-2"   v-if="datais[nextdrive[20].shown]" >
                    <v-divider></v-divider>
                    <v-form v-model="opdTvalid" ref="opdTForm">
                        <v-layout class="mt-3" wrap style="align-items:flex-end">
                            <v-flex xs3 >
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Coverage Alternate Name</p>
                                    <v-text-field
                                        :rules="alphaRule"
                                        solo
                                        label="Enter..."
                                        v-model="OPDTCoverAltName"
                                        class="rounded-0"
                                        :maxlength="max200"
                                        >
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Coverage Type
                                    </p>
                                    <v-select
                                        label="Select"
                                        :items="cvrgTypItems"
                                        v-model="opdTCoverageType"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]"
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Coverage Basis
                                    </p>
                                    <v-select
                                        label="Select"
                                        :items="cvrgBasisItems"
                                        v-model="opdTCoverageBasis"
                                        @change="chngOpdTCb(1)"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]"
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="shwOpdTSlb===true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Sub Limit Basis
                                    </p>
                                    <v-select
                                        label="Select"
                                        :items="cvrgSbLmtBasisItems"
                                        v-model="opdTSubLimitBasis"
                                        @change="chngopdTSubLmtBasis()"
                                        solo
                                        class="rounded-0"
                                        multiple
                                        :rules="[required()]"
                                        >
                                        <template v-slot:selection="{ item, index }">
                                    
                                        <span v-if="index === 0" >{{ item }}</span>
                                    
                                        <span
                                        v-if="index === 1"
                                        class="grey--text caption"
                                        >
                                        (+{{ opdTSubLimitBasis.length - 1 }} )
                                        </span>
                                    </template>
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            
                            <v-flex xs3 v-if="shwopdTPrcntSi===true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                    % of SI
                                    </p>
                                    <v-text-field
                                        label="Enter..."
                                        v-model="opdTPrcntSi"
                                        solo
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),numberRule,decimalRule()]"
                                        >
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="shwopdTFltAmt===true">
                                <v-layout column class="cust_pln_cd mx-2  cust-pln-cd">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Flat Amount
                                    </p>
                                    <v-text-field
                                        label="Enter..."
                                        v-model="opdTFlatAmt"
                                        solo
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),alphaNumeric()]"
                                        >
                                    <div slot="prepend-inner" style="background-color:#F7F7F7;padding: 9px !important"><v-icon >mdi-currency-inr </v-icon></div>
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="shwopdTPrcntPrvSi===true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                    % of previous yr SI
                                    </p>
                                    <v-text-field
                                        label="Enter..."
                                        v-model="opdTPrcntPrvSi"
                                        solo
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),numberRule,decimalRule()]"
                                        >
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            
                            <v-flex xs3 v-if="shwopdTPrcntClmAmt===true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                    % of claim amount
                                    </p>
                                    <v-text-field
                                        label="Enter..."
                                        v-model="opdTPrcntClmAmt"
                                        solo
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),numberRule,decimalRule()]"
                                        >
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="shwopdTAplcblClmPyot===true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Applicable claim pay-out
                                    </p>
                                    <v-select
                                        :items="hacAplcblClmPyotItms"
                                        label="Select"
                                        v-model="opdTAplcblClmPyot"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]"
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="shwOpdTSlb===true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Claim Payment Basis
                                    </p>
                                    <v-select
                                        label="Select"
                                        :items="ClmpymntBasisItems"
                                        v-model="opdTClaimPaymentBasis"
                                        @change="chngOpdTClaimPaymentBasis()"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]"
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="shwopdTCvrgAplcblDrtn===true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                    Coverage applicable duration (No of years)
                                    </p>
                                    <v-select
                                        :items="hacCadItms"
                                        label="Select"
                                        v-model="opdTCad"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]"
                                        
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Waiting Period</p>
                                    <v-layout>
                                        <v-select
                                            :items="['Days','Months']"
                                            label="Select"
                                            v-model="OPDTwaitingPeriodDrop"
                                            solo
                                            style="width: 145px"
                                            class="rounded-0"
                                            :rules="[required()]"
                                            >
                                        </v-select>
                                        <v-text-field
                                            label="Enter..."
                                            v-model="opdTWtngPrd"
                                            solo
                                            class="rounded-0"
                                            :maxlength="max10"
                                            :rules="[Numeric2]"
                                            >
                                        </v-text-field>
                                    </v-layout>
                                </v-layout>
                            </v-flex>
                        </v-layout>
                        <v-divider></v-divider>
                        <!-- NEW FIELDS ADDED OPDT-->
                        <v-layout class="mt-3" wrap style="align-items:flex-end">
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">IRDA UIN Number</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="OPDTIrdaUINNo"
                                        class="rounded-0"
                                        :maxlength="max20"
                                        :rules="spclCharNew">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">External Reference Number</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="OPDTExtRefNo"
                                        class="rounded-0"
                                        :maxlength="max30"
                                        :rules="spclCharNew">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Conditions</p>
                                    <v-select
                                        :items="prodConditionArray"
                                        label="Select"
                                        v-model="OPDTCondField"
                                        multiple
                                        solo
                                        class="rounded-0"
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">GC Coverage</p>
                                    <v-select
                                        :items="prodGCCoverArray"
                                        label="Select"
                                        v-model="OPDTGcCover"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]">
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Remarks</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="OPDTRemarks"
                                        class="rounded-0"
                                        :maxlength="max40"
                                        >
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Exclusions</p>
                                    <v-select
                                        :items="prodExcluArray"
                                        :maxlength="max30"
                                        label="Select"
                                        v-model="OPDTExclus"
                                        solo
                                        class="rounded-0"
                                        multiple
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                        </v-layout>

                        <v-divider></v-divider>

                        <v-flex xs3>
                            <v-layout column class="mx-2 mb-7 cust_pln_cd" justify-center >
                                <p class="font-weight-bold cstm-clr-pln-cd" style="color:#FFFFFF" ></p>
                                <div style="background-color: #f7f7f7;border: 1px solid #c1c8cc;" class="pa-2 mt-5">
                                    <v-checkbox class="ma-0 pa-0" v-model="OPDTSelectBox" @change="OPDTRelCheckSelected()" hide-details>
                                        <span slot="label" class="cust_lbl-bgclr font-weight-bold" style="font-size: 13px">Separate Relationship & Dependent Info</span>
                                    </v-checkbox>
                                </div>
                            </v-layout>
                        </v-flex>
                            
                        <div v-if="showOPDTRelSection == true">
                            <relationdependent 
                                ref="OPDTRel" 
                                @save="saveOthcotdea" 
                                :redpndntinfo="{
                                    cirelDataemty:OPDTrelCoverData.realdata,
                                    ciRelnshipCoveredEmpty:OPDTrelCoverData.dropdowndata,
                                    cisyncArryEmpty:OPDTrelCoverData.syncarr,
                                    ciactiveonlyempty:OPDTrelCoverData.ddselectedvalues
                                }" 
                                v-if="showOPDTRelData == true">
                            </relationdependent>
                        </div>
                        <v-divider></v-divider>
                        <v-layout style="flex:1" justify-end class="my-4">
                            <v-btn dark tile style="font-size:12px;background-color:#e46a25" class="text-capitalize px-3 mx-2" small @click="clearOPDT()"><v-icon dark small>close</v-icon> <v-divider vertical class="mx-2"></v-divider>Clear</v-btn>
                            <v-btn dark tile style="font-size:12px;background-color:#23B1A9" class="text-capitalize px-3 mx-2" small @click="submitOthcotdea('OPDT')"><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider>Save</v-btn>
                            <v-btn @click="nxt(nextdrive[20])" dark style="font-size:12px;background-color:#152F38" tile
                            class="text-capitalize px-3 ml-2 mr-6" small><v-icon dark small>arrow_forward</v-icon>
                            <v-divider vertical class="mx-2"></v-divider> Next</v-btn>
                        </v-layout>
                    </v-form>
                </div>
            </div>

            <div class="panelis" v-if="showVCAccor == true" dense>
                <div @click="currentis(nextdrive[21])" expand-icon="mdi-menu-down" class="font-weight-bold panhead" >
                   Vision Care   <span> <v-icon  :style="{border: datais[nextdrive[21].shown == 'y' ? '5px red solid':'none' ] }"  >arrow_drop_down</v-icon> </span> 
                </div>
                
                <div  class="pa-2"   v-if="datais[nextdrive[21].shown]">
                    <v-divider></v-divider>
                    <v-form v-model="vcvalid" ref="vcForm">
                        <v-layout class="mt-3" wrap>
                            <v-flex xs3 >
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Coverage Alternate Name</p>
                                    <v-text-field
                                        :rules="alphaRule"
                                        solo
                                        label="Enter..."
                                        v-model="VCCoverAltName"
                                        class="rounded-0"
                                        :maxlength="max200">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Coverage Type
                                    </p>
                                    <v-select
                                        :items="cvrgTypItems"
                                        label="Select"
                                        v-model="vcCoverageType"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]"
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Coverage Basis
                                    </p>
                                    <v-select
                                        :items="cvrgBasisItems"
                                        label="Select"
                                        v-model="vcCoverageBasis"
                                        @change="chngVcCb(1)"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]"
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="shwVcSlb===true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Sub Limit Basis
                                    </p>
                                    <v-select
                                        :items="cvrgSbLmtBasisItems"
                                        label="Select"
                                        v-model="vcSubLimitBasis"
                                        solo
                                        class="rounded-0"
                                        multiple
                                        @change="changeVcSubLimBasis(vcSubLimitBasis)"
                                        :rules="[required()]">
                                        <template v-slot:selection="{ item, index }">
                                    
                                        <span v-if="index === 0" >{{ item }}</span>
                                    
                                        <span
                                        v-if="index === 1"
                                        class="grey--text caption"
                                        >
                                        (+{{ vcSubLimitBasis.length - 1 }} )
                                        </span>
                                    </template>
                                    </v-select>
                                </v-layout>
                            </v-flex>

                            <v-flex xs3 v-if="showVcPercentSi == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">% of SI</p>
                                    <v-text-field
                                        solo
                                        
                                        label="Enter..."
                                        v-model="vcPercentSI"
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),numberRule,decimalRule()]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="showVcFlatAmnt == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Flat Amount</p>
                                    <v-text-field
                                        solo
                                        
                                        label="Enter..."
                                        v-model="vcFlatAmountTxtField"
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),alphaNumeric()]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="showVcPrvPercentSi == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">% of previous yr SI</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="vcPrvPercentSI"
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),numberRule,decimalRule()]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="showVcPercClaimAmt == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">% of Claim Amount</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="vcPercentClaimAmt"
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),numberRule,decimalRule()]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="showVCOpdSi == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">% of OPD SI</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="VCOpdSi"
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),numberRule,decimalRule()]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="showVCAdSi == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">% of AD SI</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="VCAdSi"
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),numberRule,decimalRule()]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            
                            <v-flex xs3 v-if="showVcApplicClaimPay == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Applicable Claim Pay-Out</p>
                                    <v-select
                                        :items="hacAplcblClmPyotItms"
                                        label="Select"
                                        v-model="vcApplicClaimPayOut"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]"
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex>

                            <v-flex xs3 v-if="shwVcSlb===true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Claim Payment Basis
                                    </p>
                                    <v-select
                                        :items="ClmpymntBasisItems"
                                        label="Select"
                                        v-model="vcClaimPaymentBasis"
                                        solo
                                        @change="changeVcClaimPayBasis(vcClaimPaymentBasis)"
                                        class="rounded-0"
                                        :rules="[required()]"
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            
                            <v-flex xs3 v-if="showVcCovrageApplicDur == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Coverage applicable duration (No of years)
                                    </p>
                                    <v-select
                                        :items="hacCadItms"
                                        label="Select"
                                        v-model="vcCovrageApplicDur"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]"
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Vision Care Exclusions</p>
                                    <v-select
                                        label="Select"
                                        v-model="vcExclusions"
                                        :items="vcExclusionsItems"
                                        solo
                                        class="rounded-0"
                                        multiple
                                        attach
                                        :rules="[required()]">
                                        <template v-slot:selection="{ item, index }">
                                            <span v-if="index === 0" >{{ item }}</span>
                                            <span v-if="index === 1" class="grey--text caption">(+{{ vcExclusions.length - 1 }} )</span>
                                        </template>
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Waiting Period</p>
                                    <v-layout>
                                        <v-select
                                            :items="['Days','Months']"
                                            label="Select"
                                            v-model="VCwaitingPeriodDrop"
                                            solo
                                            style="width: 145px"
                                            class="rounded-0"
                                            :rules="[required()]"
                                            >
                                        </v-select>
                                        <v-text-field
                                            label="Enter..."
                                            v-model="vcWtngPrd"
                                            solo
                                            class="rounded-0"
                                            :maxlength="max10"
                                            :rules="[Numeric2]"
                                            >
                                        </v-text-field>
                                    </v-layout>
                                </v-layout>
                            </v-flex>

                        </v-layout>
                        <v-divider></v-divider>
                        <!-- NEW FIELDS ADDED VC -->
                        <v-layout class="mt-3" wrap style="align-items:flex-end">
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">IRDA UIN Number</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="VCIrdaUINNo"
                                        class="rounded-0"
                                        :maxlength="max20"
                                        :rules="spclCharNew">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">External Reference Number</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="VCExtRefNo"
                                        class="rounded-0"
                                        :maxlength="max30"
                                        :rules="spclCharNew">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Conditions</p>
                                    <v-select
                                        :items="prodConditionArray"
                                        label="Select"
                                        v-model="VCCondField"
                                        multiple
                                        solo
                                        class="rounded-0">
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">GC Coverage</p>
                                    <v-select
                                        :items="prodGCCoverArray"
                                        label="Select"
                                        v-model="VCGcCover"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]">
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Remarks</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="VCRemarks"
                                        class="rounded-0"
                                        :maxlength="max54">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Exclusions</p>
                                    <v-select
                                        :items="prodExcluArray"
                                        :maxlength="max30"
                                        label="Select"
                                        v-model="VCExclus"
                                        solo
                                        class="rounded-0"
                                        multiple>
                                    </v-select>
                                </v-layout>
                            </v-flex>
                        </v-layout> 
                        <v-divider></v-divider>
                        <v-flex xs3>
                            <v-layout column class="mx-2 mb-7 cust_pln_cd" justify-center >
                                <p class="font-weight-bold cstm-clr-pln-cd" style="color:#FFFFFF" ></p>
                                <div style="background-color: #f7f7f7;border: 1px solid #c1c8cc;" class="pa-2 mt-5">
                                    <v-checkbox class="ma-0 pa-0" v-model="VCSelectBox" @change="VCRelCheckSelected()" hide-details>
                                        <span slot="label" class="cust_lbl-bgclr font-weight-bold" style="font-size: 13px">Separate Relationship & Dependent Info</span>
                                    </v-checkbox>
                                </div>
                            </v-layout>
                        </v-flex>
                            
                        <div v-if="showVCRelSection == true">
                            <relationdependent 
                                ref="VCRel" 
                                @save="saveVCITRel" 
                                :redpndntinfo="{
                                    cirelDataemty:VCrelCoverData.realdata,
                                    ciRelnshipCoveredEmpty:VCrelCoverData.dropdowndata,
                                    cisyncArryEmpty:VCrelCoverData.syncarr,
                                    ciactiveonlyempty:VCrelCoverData.ddselectedvalues
                                }" 
                                v-if="showVCRelData == true">
                            </relationdependent>
                        </div>

                        <v-divider></v-divider>
                        <v-layout style="flex:1" justify-end class="my-4">
                            <v-btn dark tile style="font-size:12px;background-color:#e46a25" class="text-capitalize px-3 mx-2" small @click="clearVC()"><v-icon dark small>close</v-icon> <v-divider vertical class="mx-2"></v-divider>Clear</v-btn>
                            <v-btn dark tile style="font-size:12px;background-color:#23B1A9" class="text-capitalize px-3 mx-2" small @click="submitVCIT('visionCare')"><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider>Save</v-btn>
                            <v-btn dark style="font-size:12px;background-color:#152F38" @click="nxt(nextdrive[21])" tile
                            class="text-capitalize px-3 ml-2 mr-6" small><v-icon dark small>arrow_forward</v-icon>
                            <v-divider vertical class="mx-2"></v-divider> Next</v-btn>
                        </v-layout>
                    </v-form>
                    

                </div>
                
            </div>


             <div class="panelis" dense v-if="shwExpnsnHcuc===true">
                <div @click="currentis(nextdrive[22])" expand-icon="mdi-menu-down" class="font-weight-bold panhead" >
                    Health Check-up Coverage   <span> <v-icon  :style="{border: datais[nextdrive[22].shown == 'y' ? '5px red solid':'none' ] }"  >arrow_drop_down</v-icon> </span> 
                </div>
                
                <divt  class="pa-2"   v-if="datais[nextdrive[22].shown]">
                    <v-divider></v-divider>
                    <v-form v-model="hckvalid" ref="hckForm">
                        <v-layout class="mt-3" wrap style="align-items:flex-end">
                            <v-flex xs3 >
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Coverage Alternate Name</p>
                                    <v-text-field
                                        :rules="alphaRule"
                                        solo
                                        label="Enter..."
                                        v-model="HCUCCoverAltName"
                                        class="rounded-0"
                                        :maxlength="max200"
                                        >
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Coverage Type
                                    </p>
                                    <v-select
                                        :items="cvrgTypItems"
                                        label="Select"
                                        v-model="hckCoverageType"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]"
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Coverage Basis
                                    </p>
                                    <v-select
                                        :items="cvrgBasisItems"
                                        label="Select"
                                        v-model="hckCoverageBasis"
                                        @change="chngHckCb(1)"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]"
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="shwHckSlb===true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Sub Limit Basis
                                    </p>
                                    <v-select
                                        :items="cvrgSbLmtBasisItems"
                                        label="Select"
                                        v-model="hckSubLimitBasis"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]"
                                        multiple
                                        @change="chngHckSubLmtBasis()"
                                        >
                                        <template v-slot:selection="{ item, index }">
                                    
                                        <span v-if="index === 0" >{{ item }}</span>
                                    
                                        <span
                                        v-if="index === 1"
                                        class="grey--text caption"
                                        >
                                        (+{{ hckSubLimitBasis.length - 1 }} )
                                        </span>
                                    </template>
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            
                            <v-flex xs3 v-if="shwhckPrcntSi===true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                    % of SI
                                    </p>
                                    <v-text-field
                                        label="Enter..."
                                        v-model="hckPrcntSi"
                                        solo
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),numberRule,decimalRule()]"
                                        >
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3  v-if="shwHckFltAmt===true">
                                <v-layout column class="cust_pln_cd mx-2  cust-pln-cd">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Flat Amount
                                    </p>
                                    <v-text-field
                                        label="Enter..."
                                        v-model="hckFlatAmt"
                                        solo
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),alphaNumeric()]"
                                        >
                                    <div slot="prepend-inner" style="background-color:#F7F7F7;padding: 9px !important"><v-icon >mdi-currency-inr </v-icon></div>
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="shwhckPrcntPrvSi===true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                    % of previous yr SI
                                    </p>
                                    <v-text-field
                                        label="Enter..."
                                        v-model="hckPrcntPrvSi"
                                        solo
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),numberRule,decimalRule()]"
                                        >
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            
                            <v-flex xs3 v-if="shwhckPrcntClmAmt===true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                    % of claim amount
                                    </p>
                                    <v-text-field
                                        label="Enter..."
                                        v-model="hckPrcntClmAmt"
                                        solo
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),numberRule,decimalRule()]"
                                        >
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="shwhckAplcblClmPyot===true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Applicable claim pay-out
                                    </p>
                                    <v-select
                                        :items="hacAplcblClmPyotItms"
                                        label="Select"
                                        v-model="hckAplcblClmPyot"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]"
                                        
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="shwHckSlb===true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Claim Payment Basis
                                    </p>
                                    <v-select
                                        :items="ClmpymntBasisItems"
                                        label="Select"
                                        v-model="hckClaimPaymentBasis"
                                        @change="chngHckClaimPaymentBasis()"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]"
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="shwhckCvrgAplcblDrtn===true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                    Coverage applicable duration (No of years)
                                    </p>
                                    <v-select
                                        :items="hacCadItms"
                                        label="Select"
                                        v-model="hckCad"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]"
                                        
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                        
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Waiting Period</p>
                                    <v-layout>
                                        <v-select
                                            :items="['Days','Months']"
                                            label="Select"
                                            v-model="HCUCwaitingPeriodDrop"
                                            solo
                                            style="width: 145px"
                                            class="rounded-0"
                                            :rules="[required()]"
                                            >
                                        </v-select>
                                        <v-text-field
                                            label="Enter..."
                                            v-model="hckWaitingPeriod"
                                            solo
                                            class="rounded-0"
                                            :maxlength="max10"
                                            :rules="[Numeric2]"
                                            >
                                        </v-text-field>
                                    </v-layout>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 >
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">No. of claim free years</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="HCUCNoClaimFreeYrs"
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[Numeric2]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                        </v-layout>
                        <v-divider></v-divider>

                        <!-- NEW FIELDS ADDED HCUC-->
                        
                        <v-layout class="mt-3" wrap style="align-items:flex-end">
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">IRDA UIN Number</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="HCUCIrdaUINNo"
                                        class="rounded-0"
                                        :maxlength="max20"
                                        :rules="spclCharNew">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">External Reference Number</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="HCUCExtRefNo"
                                        class="rounded-0"
                                        :maxlength="max30"
                                        :rules="spclCharNew">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Conditions</p>
                                    <v-select
                                        :items="prodConditionArray"
                                        label="Select"
                                        v-model="HCUCCondField"
                                        multiple
                                        solo
                                        class="rounded-0"
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">GC Coverage</p>
                                    <v-select
                                        :items="prodGCCoverArray"
                                        label="Select"
                                        v-model="HCUCGcCover"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]">
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Remarks</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="HCUCRemarks"
                                        class="rounded-0"
                                        :maxlength="max40"
                                        >
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Exclusions</p>
                                    <v-select
                                        :items="prodExcluArray"
                                        :maxlength="max30"
                                        label="Select"
                                        v-model="HCUCExclus"
                                        solo
                                        class="rounded-0"
                                        multiple
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                        </v-layout>
                        <v-divider></v-divider>
                        <v-flex xs3>
                            <v-layout column class="mx-2 mb-7 cust_pln_cd" justify-center >
                                <p class="font-weight-bold cstm-clr-pln-cd" style="color:#FFFFFF" ></p>
                                <div style="background-color: #f7f7f7;border: 1px solid #c1c8cc;" class="pa-2 mt-5">
                                    <v-checkbox class="ma-0 pa-0" v-model="HCUCSelectBox" @change="HCUCRelCheckSelected()" hide-details>
                                        <span slot="label" class="cust_lbl-bgclr font-weight-bold" style="font-size: 13px">Separate Relationship & Dependent Info</span>
                                    </v-checkbox>
                                </div>
                            </v-layout>
                        </v-flex>

                        <div v-if="showHCUCRelSection == true">
                            <relationdependent 
                                ref="HCUCRel" 
                                @save="saveOthcotdea" 
                                :redpndntinfo="{
                                    cirelDataemty:HCUCrelCoverData.realdata,
                                    ciRelnshipCoveredEmpty:HCUCrelCoverData.dropdowndata,
                                    cisyncArryEmpty:HCUCrelCoverData.syncarr,
                                    ciactiveonlyempty:HCUCrelCoverData.ddselectedvalues
                                }" 
                                v-if="showHCUCRelData == true">
                            </relationdependent>
                        </div>
                        <v-divider></v-divider>
                        <v-layout style="flex:1" justify-end class="my-4">
                            <v-btn dark tile style="font-size:12px;background-color:#e46a25" class="text-capitalize px-3 mx-2" small  @click="clearHCUC()"><v-icon dark small>close</v-icon> <v-divider vertical class="mx-2"></v-divider>Clear</v-btn>
                            <v-btn dark tile style="font-size:12px;background-color:#23B1A9" class="text-capitalize px-3 mx-2" small  @click="submitOthcotdea('HCUC')"><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider>Save</v-btn>
                            <v-btn dark style="font-size:12px;background-color:#152F38" @click="nxt(nextdrive[22])" tile
                            class="text-capitalize px-3 ml-2 mr-6" small><v-icon dark small>arrow_forward</v-icon>
                            <v-divider vertical class="mx-2"></v-divider> Next</v-btn>
                        </v-layout>
                    </v-form>
                    

                </divt>
                
            </div>
                

            <div class="panelis" v-if="showADBAccor == true" dense>
                <div @click="currentis(nextdrive[23])" expand-icon="mdi-menu-down" class="font-weight-bold panhead" >
                   Accidental Death Benefit   <span> <v-icon  :style="{border: datais[nextdrive[23].shown == 'y' ? '5px red solid':'none' ] }"  >arrow_drop_down</v-icon> </span> 
                </div>
                
                <div  class="pa-2"   v-if="datais[nextdrive[23].shown]">
                    <v-divider></v-divider>
                    <v-form v-model="ADBValid" ref="adbForm">
                        <v-layout class="mt-3" wrap style="align-items:flex-end">
                            <v-flex xs3 >
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Coverage Alternate Name</p>
                                    <v-text-field
                                        :rules="alphaRule"
                                        solo
                                        label="Enter..."
                                        v-model="ADBCoverAltName"
                                        class="rounded-0"
                                        :maxlength="max200"
                                        >
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Coverage Type
                                    </p>
                                    <v-select
                                        :items="cvrgTypItems"
                                        label="Select"
                                        v-model="adbCoverageType"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]"
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Coverage Basis
                                    </p>
                                    <v-select
                                        :items="cvrgBasisItems"
                                        label="Select"
                                        v-model="adbCoverageBasis"
                                        @change="chngAbdCb(1)"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]"
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="shwAbdSlb===true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Sub Limit Basis
                                    </p>
                                    <v-select
                                        :items="cvrgSbLmtBasisItems"
                                        label="Select"
                                        v-model="adbSubLimitBasis"
                                        solo
                                        multiple
                                        class="rounded-0"
                                        @change="chngAbdSubLmtBasis(adbSubLimitBasis)"
                                        :rules="[required()]">
                                        <template v-slot:selection="{ item, index }">
                                            <span v-if="index === 0" >{{ item }}</span>
                                        
                                            <span
                                            v-if="index === 1"
                                            class="grey--text caption"
                                            >
                                            (+{{ adbSubLimitBasis.length - 1 }})
                                            </span>
                                        </template>
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            
                            <v-flex xs3 v-if="showAccDBPercentSi == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">% of SI</p>
                                    <v-text-field
                                        solo
                                        dense
                                        label="Enter..."
                                        v-model="AccDBpercentSI"
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),numberRule,decimalRule()]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="showAccDBFlatAmnt == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Flat Amount</p>
                                    <v-text-field
                                        solo
                                        dense
                                        label="Enter..."
                                        v-model="AccDBFlatAmountTxtField"
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),alphaNumeric()]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="showAccDBPrvPercentSi == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd"> % of previous yr SI</p>
                                    <v-text-field
                                        solo
                                        dense
                                        label="Enter..."
                                        v-model="AccDBPrvpercentSI"
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),numberRule,decimalRule()]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="showAccDBPercClaimAmt == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">% of Claim Amount</p>
                                    <v-text-field
                                        solo
                                        dense
                                        label="Enter..."
                                        v-model="AccDBPercentClaimAmt"
                                        class="rounded-0"
                                        :maxlength="max10"
                                         :rules="[required(),numberRule,decimalRule()]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="showADBOpdSi == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">% of OPD SI</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="ADBOpdSi"
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),numberRule,decimalRule()]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="showADBAdSi == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">% of AD SI</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="ADBAdSi"
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),numberRule,decimalRule()]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="showAccDBApplicClaimPay == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Applicable Claim Pay-Out</p>
                                    <v-select
                                        :items="hacAplcblClmPyotItms"
                                        label="Select"
                                        v-model="AccDBApplicClaimPayOut"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]"
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Claim Payment Basis
                                    </p>
                                    <v-select
                                        :items="ClmpymntBasisItems"
                                        label="Select"
                                        v-model="adbClaimPaymentBasis"
                                        @change="changeADBClaimPayBasis(adbClaimPaymentBasis)"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]"
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            
                            <v-flex xs3 v-if="showADBCovrageApplicDur == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Coverage applicable duration (No of years)
                                    </p>
                                    <v-select
                                        :items="hacCadItms"
                                        label="Select"
                                        v-model="ADBCovrageApplicDur"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]"
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Allowed duration from Date of Accident (Days)</p>
                                    <v-text-field
                                        solo
                                        dense
                                        label="Enter..."
                                        v-model="ADBAllowdDurFrmDOAcc"
                                        class="rounded-0"
                                        :maxlength="max10"
                                         :rules="[required(),numberRule]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Waiting Period</p>
                                    <v-layout>
                                        <v-select
                                            :items="['Days','Months']"
                                            label="Select"
                                            v-model="ADBwaitingPeriodDrop"
                                            solo
                                            style="width: 145px"
                                            class="rounded-0"
                                            :rules="[required()]"
                                            >
                                        </v-select>
                                        <v-text-field
                                            label="Enter..."
                                            v-model="ADBwaitingPeriod"
                                            solo
                                            class="rounded-0"
                                            :maxlength="max10"
                                            :rules="[Numeric2]"
                                            >
                                        </v-text-field>
                                    </v-layout>
                                </v-layout>
                            </v-flex>
                            
                            <v-flex xs3>
                                <v-layout column class="mx-2 mb-7 cust_pln_cd" justify-center >
                                    <p class="font-weight-bold cstm-clr-pln-cd" style="color:#FFFFFF" ></p>
                                    <div style="background-color: #f7f7f7;border: 1px solid #c1c8cc;" class="pa-2 mt-4">
                                        <v-checkbox class="ma-0 pa-0" v-model="abdDsaprncAplcbl" hide-details @click="abdDBox">
                                            <span slot="label" class="cust_lbl-bgclr font-weight-bold" style="font-size: 13px">Disappearance Applicable</span>
                                        </v-checkbox>
                                    </div>
                                </v-layout>
                            </v-flex>
                            <!-- <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                    Max. No. of Days from Date of Accident
                                    </p>
                                    <v-text-field
                                        label="Enter..."
                                        v-model="abdmxdysAccdnt"
                                        solo
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),Numeric2]"
                                        >
                                    </v-text-field>
                                </v-layout>
                            </v-flex> -->
                            <!-- <v-flex xs3 v-if="shwabdmxdysDsaprnc===true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                    Max. No. of Days from Date of Disappearance
                                    </p>
                                    <v-text-field
                                        label="Enter..."
                                        v-model="abdmxdysDsaprnc"
                                        solo
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),Numeric2]"
                                        >
                                    </v-text-field>
                                </v-layout>
                            </v-flex> -->

                            <v-flex xs3 v-if="shwabdmxdysDsaprnc===true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Waiting Period To be Declared as Disappeared (months)</p>
                                    <v-text-field
                                        label="Enter..."
                                        v-model="ADBWaitingPrdToDeclare"
                                        solo
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),Numeric2]"
                                        >
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 >
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Benefit Payable</p>
                                    <v-select
                                        :items="['Per policy year','Per Accident']"
                                        label="Select"
                                        v-model="ADBBenifPayable"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]"
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex> 
                        </v-layout>

                        <v-divider></v-divider>
                        <!-- NEW FIELDS ADDED ADB-->
                        <v-layout class="mt-3" wrap style="align-items:flex-end">
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">IRDA UIN Number</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="ADBIrdaUINNo"
                                        class="rounded-0"
                                        :maxlength="max20"
                                        :rules="spclCharNew">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">External Reference Number</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="ADBExtRefNo"
                                        class="rounded-0"
                                        :maxlength="max30"
                                        :rules="spclCharNew">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Conditions</p>
                                    <v-select
                                        :items="prodConditionArray"
                                        label="Select"
                                        v-model="ADBCondField"
                                        multiple
                                        solo
                                        class="rounded-0"
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">GC Coverage</p>
                                    <v-select
                                        :items="prodGCCoverArray"
                                        label="Select"
                                        v-model="ADBGcCover"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]">
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Remarks</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="ADBRemarks"
                                        class="rounded-0"
                                        :maxlength="max40"
                                        >
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Exclusions</p>
                                    <v-select
                                        :items="prodExcluArray"
                                        :maxlength="max30"
                                        label="Select"
                                        v-model="ADBExclus"
                                        solo
                                        class="rounded-0"
                                        multiple
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                        </v-layout>

                        <v-divider></v-divider>

                        <v-flex xs3>
                            <v-layout column class="mx-2 mb-7 cust_pln_cd" justify-center >
                                <p class="font-weight-bold cstm-clr-pln-cd" style="color:#FFFFFF" ></p>
                                <div style="background-color: #f7f7f7;border: 1px solid #c1c8cc;" class="pa-2 mt-5">
                                    <v-checkbox class="ma-0 pa-0" v-model="abdcheckbox" @change="ADBRelCheckSelected()" hide-details>
                                        <span slot="label" class="cust_lbl-bgclr font-weight-bold" style="font-size: 13px">Separate Relationship & Dependent Info</span>
                                    </v-checkbox>
                                </div>
                            </v-layout>
                        </v-flex>
                         
                        <div v-if="showABDRelSection == true">
                            <relationdependent 
                                ref="ADBRel" 
                                @save="saveADBPTDRel" 
                                :redpndntinfo="{
                                    cirelDataemty:ABDrelCoverData.realdata,
                                    ciRelnshipCoveredEmpty:ABDrelCoverData.dropdowndata,
                                    cisyncArryEmpty:ABDrelCoverData.syncarr,
                                    ciactiveonlyempty:ABDrelCoverData.ddselectedvalues
                                }" 
                                v-if="showABDRelData == true">
                            </relationdependent>
                        </div>
                    </v-form>
                    <!-- <v-divider class="mt-2"></v-divider> -->
                 <v-layout style="flex:1" justify-end class="my-4">
                    <v-btn dark @click="clearADB()" tile style="font-size:12px;background-color:#e46a25" class="text-capitalize px-3 mx-2" small><v-icon dark small>close</v-icon> <v-divider vertical class="mx-2"></v-divider>Clear</v-btn>
                    <v-btn dark @click="submitADBPTD('accDeathBenefit')" tile style="font-size:12px;background-color:#23B1A9" class="text-capitalize px-3 mx-2" small><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider>Save</v-btn>
                    <v-btn @click="nxt(nextdrive[23])" dark style="font-size:12px;background-color:#152F38" tile
                    class="text-capitalize px-3 ml-2 mr-6" small><v-icon dark small>arrow_forward</v-icon>
                    <v-divider vertical class="mx-2"></v-divider> Next</v-btn>
                </v-layout>
                    

                </div>
                
            </div>


            <div class="panelis" v-if="showPTDAccor == true" dense>
                <div @click="currentis(nextdrive[24])" expand-icon="mdi-menu-down" class="font-weight-bold panhead" >
                    Permanent Total Disability   <span> <v-icon  :style="{border: datais[nextdrive[24].shown == 'y' ? '5px red solid':'none' ] }"  >arrow_drop_down</v-icon> </span> 
                </div>
                
                <div  class="pa-2"   v-if="datais[nextdrive[24].shown]">
                    <v-divider></v-divider>
                    <v-form v-model="PTDValid" ref="ptdForm">
                        <v-layout style="align-items:center" class="mt-3" wrap>
                            <v-flex xs3 >
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Coverage Alternate Name</p>
                                    <v-text-field
                                        :rules="alphaRule"
                                        solo
                                        label="Enter..."
                                        v-model="PTDCoverAltName"
                                        class="rounded-0"
                                        :maxlength="max200">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Coverage Type
                                    </p>
                                    <v-select
                                        :items="cvrgTypItems"
                                        label="Select"
                                        v-model="ptdCoverageType"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]"
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Coverage Basis
                                    </p>
                                    <v-select
                                        :items="cvrgBasisItems"
                                        label="Select"
                                        v-model="ptdCoverageBasis"
                                        @change="chngPtdCb(1)"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]"
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="shwPtdSlb===true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Sub Limit Basis
                                    </p>
                                    <v-select
                                        :items="cvrgSbLmtBasisItems"
                                        label="Select"
                                        v-model="ptdSubLimitBasis"
                                        solo
                                        class="rounded-0"
                                        multiple
                                        @change="chngPtdSubLmtBasis(ptdSubLimitBasis)"
                                        :rules="[required()]"
                                        >
                                        <template v-slot:selection="{ item, index }">
                                            <span v-if="index === 0" >{{ item }}</span>
                                            <span v-if="index === 1" class="grey--text caption">(+{{ ptdSubLimitBasis.length - 1 }} )</span>
                                        </template>
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            
                            <v-flex xs3 v-if="showPTDPercentSi == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">% of SI</p>
                                    <v-text-field
                                        solo
                                        dense
                                        label="Enter..."
                                        v-model="PTDpercentSI"
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),numberRule,decimalRule()]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="showPTDFlatAmnt == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Flat Amount</p>
                                    <v-text-field
                                        solo
                                        dense
                                        label="Enter..."
                                        v-model="PTDFlatAmountTxtField"
                                        class="rounded-0"
                                        :maxlength="max10"
                                         :rules="[required(),alphaNumeric()]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="showPTDPrvPercentSi == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">% of previous yr SI</p>
                                    <v-text-field
                                        solo
                                        dense
                                        label="Enter..."
                                        v-model="PTDPrvpercentSI"
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),numberRule,decimalRule()]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="showPTDPercClaimAmt == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">% of Claim Amount</p>
                                    <v-text-field
                                        solo
                                        dense
                                        label="Enter..."
                                        v-model="PTDPercentClaimAmt"
                                        class="rounded-0"
                                        :maxlength="max10"
                                       :rules="[required(),numberRule,decimalRule()]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="showPTDOpdSi == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">% of OPD SI</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="PTDOpdSi"
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),numberRule,decimalRule()]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="showPTDAdSi == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">% of AD SI</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="PTDAdSi"
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),numberRule,decimalRule()]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="showPTDApplicClaimPay == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Applicable Claim Pay-Out</p>
                                    <v-select
                                        :items="hacAplcblClmPyotItms"
                                        label="Select"
                                        v-model="PTDApplicClaimPayOut"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]"
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Claim Payment Basis
                                    </p>
                                    <v-select
                                        :items="ClmpymntBasisItems"
                                        label="Select"
                                        v-model="ptdClaimPaymentBasis"
                                        solo
                                        @change="changePTDClaimPayBasis(ptdClaimPaymentBasis)"
                                        class="rounded-0"
                                        :rules="[required()]"
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            
                            <v-flex xs3 v-if="showPTDCovrageApplicDur == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Coverage applicable duration (No of years)
                                    </p>
                                    <v-select
                                        :items="hacCadItms"
                                        label="Select"
                                        v-model="PTDCovrageApplicDur"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]"
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Nature of Loss
                                    </p>
                                    <v-select
                                        :items="ptdNatureofLossItems"
                                        label="Select"
                                        v-model="ptdNatureofLoss"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]"
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Waiting Period</p>
                                    <v-layout>
                                        <v-select
                                            :items="['Days','Months']"
                                            label="Select"
                                            v-model="PTDwaitingPeriodDrop"
                                            solo
                                            style="width: 145px"
                                            class="rounded-0"
                                            :rules="[required()]"
                                            >
                                        </v-select>
                                        <v-text-field
                                            label="Enter..."
                                            v-model="PTDwaitingPeriod"
                                            solo
                                            class="rounded-0"
                                            :maxlength="max10"
                                            :rules="[Numeric2]"
                                            >
                                        </v-text-field>
                                    </v-layout>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 >
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Allowed duration from Date of Accident (Days)</p>
                                    <v-text-field
                                        solo
                                        dense
                                        label="Enter..."
                                        v-model="PTDDurFromAccDate"
                                        class="rounded-0"
                                        :maxlength="max10"
                                       :rules="[required()]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 >
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Disability continuity period (Months)</p>
                                    <v-text-field
                                        solo
                                        dense
                                        label="Enter..."
                                        v-model="PTDDisbleContPer"
                                        class="rounded-0"
                                        :maxlength="max10"
                                       :rules="[required()]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="mx-2 mb-7 cust_pln_cd" justify-center >
                                    <p class="font-weight-bold cstm-clr-pln-cd" style="color:#FFFFFF" ></p>
                                    <div class="pa-2 mt-5 elevation-3">
                                        <v-checkbox class="ma-0 pa-0" v-model="PTDfeatureClaim" @change="PTDFeatureClaimChange(PTDfeatureClaim)" hide-details>
                                            <span slot="label" class="cust_lbl-bgclr font-weight-bold" style="font-size: 13px">Feature Claim</span>
                                        </v-checkbox>
                                    </div>
                                </v-layout>
                            </v-flex>
        
                            <v-flex v-if="showPTDPrimCoverage == true" xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Primary Coverages</p>
                                    <v-select
                                        :items="PTDprimCovrgArray"
                                        label="Select"
                                        v-model="PTDPrimCover"
                                        multiple
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]">
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Benefit Payable</p>
                                    <v-select
                                        :items="EBBenpayArray"
                                        label="Select"
                                        v-model="PTDBenpay"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]">
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            
                        </v-layout>
                        <v-divider></v-divider>
                        <!-- NEW FIELDS ADDED PTD -->
                        <v-layout class="mt-3" wrap style="align-items:flex-end">
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">IRDA UIN Number</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="PTDIrdaUINNo"
                                        class="rounded-0"
                                        :maxlength="max20"
                                        :rules="spclCharNew">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">External Reference Number</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="PTDExtRefNo"
                                        class="rounded-0"
                                        :maxlength="max30"
                                        :rules="spclCharNew">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Conditions</p>
                                    <v-select
                                        :items="prodConditionArray"
                                        label="Select"
                                        v-model="PTDCondField"
                                        multiple
                                        solo
                                        class="rounded-0">
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">GC Coverage</p>
                                    <v-select
                                        :items="prodGCCoverArray"
                                        label="Select"
                                        v-model="PTDGcCover"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]">
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Remarks</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="PTDRemarks"
                                        class="rounded-0"
                                        :maxlength="max14">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Exclusions</p>
                                    <v-select
                                        :items="prodExcluArray"
                                        :maxlength="max30"
                                        label="Select"
                                        v-model="PTDExclus"
                                        solo
                                        class="rounded-0"
                                        multiple>
                                    </v-select>
                                </v-layout>
                            </v-flex>
                        </v-layout> 
                        <v-divider></v-divider>
                        <v-flex xs3>
                            <v-layout column class="mx-2 mb-7 cust_pln_cd" justify-center >
                                <p class="font-weight-bold cstm-clr-pln-cd" style="color:#FFFFFF" ></p>
                                <div style="background-color: #f7f7f7;border: 1px solid #c1c8cc;" class="pa-2 mt-5">
                                    <v-checkbox class="ma-0 pa-0" v-model="ptdCheckbox" @change="PTDRelCheckSelected()" hide-details>
                                        <span slot="label" class="cust_lbl-bgclr font-weight-bold" style="font-size: 13px">Separate Relationship & Dependent Info</span>
                                    </v-checkbox>
                                </div>
                            </v-layout>
                        </v-flex>
                        <div v-if="showPTDRelSection == true">
                            <relationdependent 
                                ref="PTDRel" 
                                @save="saveADBPTDRel" 
                                :redpndntinfo="{
                                    cirelDataemty:PTDrelCoverData.realdata,
                                    ciRelnshipCoveredEmpty:PTDrelCoverData.dropdowndata,
                                    cisyncArryEmpty:PTDrelCoverData.syncarr,
                                    ciactiveonlyempty:PTDrelCoverData.ddselectedvalues
                                }" 
                                v-if="showPTDRelData == true">
                            </relationdependent>
                        </div>
                    </v-form>
                    <v-divider class="mt-2"></v-divider>
                    <v-layout style="flex:1" justify-end class="my-4">
                        <v-btn dark @click="clearPTD()" tile style="font-size:12px;background-color:#e46a25" class="text-capitalize px-3 mx-2" small><v-icon dark small>close</v-icon> <v-divider vertical class="mx-2"></v-divider>Clear</v-btn>
                        <v-btn dark @click="submitADBPTD('permTtlDisable')" tile style="font-size:12px;background-color:#23B1A9" class="text-capitalize px-3 mx-2" small><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider>Save</v-btn>
                        <v-btn @click="nxt(nextdrive[24])" dark style="font-size:12px;background-color:#152F38" 
                        tile class="text-capitalize px-3 ml-2 mr-6" small><v-icon dark small>arrow_forward</v-icon>
                        <v-divider vertical class="mx-2"></v-divider> Next</v-btn>
                    </v-layout>
                    

                </div>
                
            </div>


            <div class="panelis" dense v-if="shwExpnsnTmr===true">
                <div @click="currentis(nextdrive[25])" expand-icon="mdi-menu-down" class="font-weight-bold panhead" >
                    Transportation of mortal remains   <span> <v-icon  :style="{border: datais[nextdrive[25].shown == 'y' ? '5px red solid':'none' ] }"  >arrow_drop_down</v-icon> </span> 
                </div>
                
                <div  class="pa-2"   v-if="datais[nextdrive[25].shown]">
                    <v-divider></v-divider>
                    <v-form v-model="tmrvalid" ref="tmrForm">
                    <v-layout class="mt-3" wrap style="align-items:flex-end">
                        <v-flex xs3 >
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">Coverage Alternate Name</p>
                                <v-text-field
                                    :rules="alphaRule"
                                    solo
                                    label="Enter..."
                                    v-model="TMRCoverAltName"
                                    class="rounded-0"
                                    :maxlength="max200"
                                    >
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3>
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                    Coverage Type
                                </p>
                                <v-select
                                    :items="cvrgTypItems"
                                    label="Select"
                                    v-model="tmrCoverageType"
                                    solo
                                    class="rounded-0"
                                    :rules="[required()]"
                                    >
                                </v-select>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3>
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                    Coverage Basis
                                </p>
                                <v-select
                                    :items="cvrgBasisItems"
                                    label="Select"
                                    v-model="tmrCoverageBasis"
                                    @change="chngTmrCb(1)"
                                    solo
                                    class="rounded-0"
                                    :rules="[required()]"
                                    >
                                </v-select>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3 v-if="shwTmrSlb===true">
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                    Sub Limit Basis
                                </p>
                                <v-select
                                    :items="cvrgSbLmtBasisItems"
                                    label="Select"
                                    v-model="tmrSubLimitBasis"
                                    solo
                                    @change="chngTmrSubLmtBasis()"
                                    class="rounded-0"
                                    :rules="[required()]"
                                    multiple
                                    >
                                    <template v-slot:selection="{ item, index }">
                                   
                                    <span v-if="index === 0" >{{ item }}</span>
                                   
                                    <span
                                    v-if="index === 1"
                                    class="grey--text caption"
                                    >
                                    (+{{ tmrSubLimitBasis.length - 1 }} )
                                    </span>
                                </template>
                                </v-select>
                            </v-layout>
                        </v-flex>
                        
                        <v-flex xs3 v-if="shwTmrPrcntSi===true">
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                   % of SI
                                </p>
                                 <v-text-field
                                    label="Enter..."
                                    v-model="tmrPrcntSi"
                                    solo
                                    class="rounded-0"
                                    :maxlength="max10"
                                    :rules="[required(),numberRule,decimalRule()]"
                                    >
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3  v-if="shwTmrFltAmt===true">
                            <v-layout column class="cust_pln_cd mx-2  cust-pln-cd">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                    Flat Amount
                                </p>
                                <v-text-field
                                    label="Enter..."
                                    v-model="tmrFlatAmt"
                                    solo
                                    class="rounded-0"
                                    :maxlength="max10"
                                    :rules="[required(),alphaNumeric()]"
                                    >
                                <div slot="prepend-inner" style="background-color:#F7F7F7;padding: 9px !important"><v-icon >mdi-currency-inr </v-icon></div>
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3 v-if="shwTmrPrcntPrvSi===true">
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                   % of previous yr SI
                                </p>
                                 <v-text-field
                                    label="Enter..."
                                    v-model="tmrPrcntPrvSi"
                                    solo
                                    class="rounded-0"
                                    :maxlength="max10"
                                    :rules="[required(),numberRule,decimalRule()]"
                                    >
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                         
                        <v-flex xs3 v-if="shwTmrPrcntClmAmt===true">
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                  % of claim amount
                                </p>
                                 <v-text-field
                                    label="Enter..."
                                    v-model="tmrPrcntClmAmt"
                                    solo
                                    class="rounded-0"
                                    :maxlength="max10"
                                    :rules="[required(),numberRule,decimalRule()]"
                                    >
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3 v-if="shwTmrAplcblClmPyot===true">
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                    Applicable claim pay-out
                                </p>
                                <v-select
                                    :items="hacAplcblClmPyotItms"
                                    label="Select"
                                    v-model="tmrAplcblClmPyot"
                                    solo
                                    class="rounded-0"
                                    :rules="[required()]"
                                    
                                    >
                                </v-select>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3 v-if="shwTmrSlb===true">
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                    Claim Payment Basis
                                </p>
                                <v-select
                                    :items="ClmpymntBasisItems"
                                    label="Select"
                                    v-model="tmrClaimPaymentBasis"
                                     @change="chngTmrClaimPaymentBasis()"
                                    solo
                                    class="rounded-0"
                                    :rules="[required()]"
                                    >
                                </v-select>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3 v-if="shwTmrCvrgAplcblDrtn===true">
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                   Coverage applicable duration (No of years)
                                </p>
                                <v-select
                                    :items="hacCadItms"
                                    label="Select"
                                    v-model="tmrCad"
                                    solo
                                    class="rounded-0"
                                    :rules="[required()]"
                                    
                                    >
                                </v-select>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3>
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">Waiting Period</p>
                                <v-layout>
                                    <v-select
                                        :items="['Days','Months']"
                                        label="Select"
                                        v-model="TMRwaitingPeriodDrop"
                                        solo
                                        style="width: 145px"
                                        class="rounded-0"
                                        :rules="[required()]"
                                        >
                                    </v-select>
                                    <v-text-field
                                        label="Enter..."
                                        v-model="TMRwaitingPeriod"
                                        solo
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[Numeric2]"
                                        >
                                    </v-text-field>
                                </v-layout>
                            </v-layout>
                        </v-flex>

                    </v-layout>
                    <v-divider></v-divider>
                    <!-- NEW FIELDS ADDED TMR -->
                    <v-layout class="mt-3" wrap style="align-items:flex-end">
                        <v-flex xs3>
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">IRDA UIN Number</p>
                                <v-text-field
                                    solo
                                    label="Enter..."
                                    v-model="TMRIrdaUINNo"
                                    class="rounded-0"
                                    :maxlength="max20"
                                    :rules="spclCharNew">
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3>
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">External Reference Number</p>
                                <v-text-field
                                    solo
                                    label="Enter..."
                                    v-model="TMRExtRefNo"
                                    class="rounded-0"
                                    :maxlength="max30"
                                    :rules="spclCharNew">
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3>
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">Conditions</p>
                                <v-select
                                    :items="prodConditionArray"
                                    label="Select"
                                    v-model="TMRCondField"
                                    multiple
                                    solo
                                    class="rounded-0"
                                    >
                                </v-select>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3>
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">GC Coverage</p>
                                <v-select
                                    :items="prodGCCoverArray"
                                    label="Select"
                                    v-model="TMRGcCover"
                                    solo
                                    class="rounded-0"
                                    :rules="[required()]">
                                </v-select>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3>
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">Remarks</p>
                                <v-text-field
                                    solo
                                    label="Enter..."
                                    v-model="TMRRemarks"
                                    class="rounded-0"
                                    :maxlength="max40"
                                    >
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3>
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">Exclusions</p>
                                <v-select
                                    :items="prodExcluArray"
                                    :maxlength="max30"
                                    label="Select"
                                    v-model="TMRExclus"
                                    solo
                                    class="rounded-0"
                                    multiple
                                    >
                                </v-select>
                            </v-layout>
                        </v-flex>
                    </v-layout>
                    <v-divider></v-divider>
                    <v-flex xs3>
                        <v-layout column class="mx-2 mb-7 cust_pln_cd" justify-center >
                            <p class="font-weight-bold cstm-clr-pln-cd" style="color:#FFFFFF" ></p>
                            <div style="background-color: #f7f7f7;border: 1px solid #c1c8cc;" class="pa-2 mt-5">
                                <v-checkbox class="ma-0 pa-0" v-model="TMRSelectBox" @change="TMRRelCheckSelected()" hide-details>
                                    <span slot="label" class="cust_lbl-bgclr font-weight-bold" style="font-size: 13px">Separate Relationship & Dependent Info</span>
                                </v-checkbox>
                            </div>
                        </v-layout>
                    </v-flex>
                        
                    <div v-if="showTMRRelSection == true">
                        <relationdependent 
                            ref="TMRRel" 
                            @save="saveTMRFERel" 
                            :redpndntinfo="{
                                cirelDataemty:TMRrelCoverData.realdata,
                                ciRelnshipCoveredEmpty:TMRrelCoverData.dropdowndata,
                                cisyncArryEmpty:TMRrelCoverData.syncarr,
                                ciactiveonlyempty:TMRrelCoverData.ddselectedvalues
                            }" 
                            v-if="showTMRRelData == true">
                        </relationdependent>
                    </div>
                    <v-divider></v-divider>

                    <v-layout style="flex:1" justify-end class="my-4">
                        <v-btn dark tile style="font-size:12px;background-color:#e46a25" class="text-capitalize px-3 mx-2" small  @click="clearTMR()"><v-icon dark small>close</v-icon> <v-divider vertical class="mx-2"></v-divider>Clear</v-btn>
                        <v-btn dark tile style="font-size:12px;background-color:#23B1A9" class="text-capitalize px-3 mx-2" small  @click="submitTMRFE('TMR')"><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider>Save</v-btn>
                        <v-btn @click="nxt(nextdrive[25])" dark style="font-size:12px;background-color:#152F38" tile  class="text-capitalize px-3 ml-2 mr-6"
                        small><v-icon dark small>arrow_forward</v-icon> <v-divider vertical class="mx-2"></v-divider> Next</v-btn>
                    </v-layout>
                </v-form>
                    

                </div>
                
            </div>
            

             <div class="panelis" dense v-if="shwExpnsnFe===true">
                <div @click="currentis(nextdrive[26])" expand-icon="mdi-menu-down" class="font-weight-bold panhead" >
                    Funeral Expenses   <span> <v-icon  :style="{border: datais[nextdrive[26].shown == 'y' ? '5px red solid':'none' ] }"  >arrow_drop_down</v-icon> </span> 
                </div>
                
                <div  class="pa-2"   v-if="datais[nextdrive[26].shown]" >
                    <v-divider></v-divider>
                    <v-form v-model="fevalid" ref="feForm">
                    <v-layout class="mt-3" wrap style="align-items:flex-end">
                        <v-flex xs3 >
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">Coverage Alternate Name</p>
                                <v-text-field
                                    :rules="alphaRule"
                                    solo
                                    label="Enter..."
                                    v-model="FECoverAltName"
                                    class="rounded-0"
                                    :maxlength="max200"
                                    >
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3>
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                    Coverage Type
                                </p>
                                <v-select
                                    :items="cvrgTypItems"
                                    label="Select"
                                    v-model="feCoverageType"
                                    solo
                                    class="rounded-0"
                                    :rules="[required()]"
                                    >
                                </v-select>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3>
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                    Coverage Basis
                                </p>
                                <v-select
                                    :items="cvrgBasisItems"
                                    label="Select"
                                    v-model="feCoverageBasis"
                                    @change="chngFeCb(1)"
                                    solo
                                    class="rounded-0"
                                    :rules="[required()]"
                                    >
                                </v-select>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3 v-if="shwFeSlb===true">
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                    Sub Limit Basis
                                </p>
                                <v-select
                                    :items="cvrgSbLmtBasisItems"
                                    label="Select"
                                    v-model="feSubLimitBasis"
                                     @change="chngFeSubLmtBasis()"
                                    solo
                                    class="rounded-0"
                                    :rules="[required()]"
                                    multiple
                                    >
                                    <template v-slot:selection="{ item, index }">
                                   
                                    <span v-if="index === 0" >{{ item }}</span>
                                   
                                    <span
                                    v-if="index === 1"
                                    class="grey--text caption"
                                    >
                                    (+{{ feSubLimitBasis.length - 1 }} )
                                    </span>
                                </template>
                                </v-select>
                            </v-layout>
                        </v-flex>
                       
                         <v-flex xs3 v-if="shwfePrcntSi===true">
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                   % of SI
                                </p>
                                 <v-text-field
                                    label="Enter..."
                                    v-model="fePrcntSi"
                                    solo
                                    class="rounded-0"
                                    :maxlength="max10"
                                    :rules="[required(),numberRule,decimalRule()]"
                                    >
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3  v-if="shwFeFltAmt===true">
                            <v-layout column class="cust_pln_cd mx-2  cust-pln-cd">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                    Flat Amount
                                </p>
                                <v-text-field
                                    label="Enter..."
                                    v-model="feFlatAmt"
                                    solo
                                    class="rounded-0"
                                    :maxlength="max10"
                                    :rules="[required(),alphaNumeric()]"
                                    >
                                <div slot="prepend-inner" style="background-color:#F7F7F7;padding: 9px !important"><v-icon >mdi-currency-inr </v-icon></div>
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3 v-if="shwfePrcntPrvSi===true">
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                  % of previous yr SI
                                </p>
                                 <v-text-field
                                    label="Enter..."
                                    v-model="fePrcntPrvSi"
                                    solo
                                    class="rounded-0"
                                    :maxlength="max10"
                                    :rules="[required(),numberRule,decimalRule()]"
                                    >
                                </v-text-field>
                            </v-layout>
                        </v-flex>

                       
                        <v-flex xs3 v-if="shwFePrcntClmAmt===true">
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                  % of claim amount
                                </p>
                                 <v-text-field
                                    label="Enter..."
                                    v-model="fePrcntClmAmt"
                                    solo
                                    class="rounded-0"
                                    :maxlength="max10"
                                    :rules="[required(),numberRule,decimalRule()]"
                                    >
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3 v-if="shwFeAplcblClmPyot===true">
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                    Applicable claim pay-out
                                </p>
                                <v-select
                                    :items="hacAplcblClmPyotItms"
                                    label="Select"
                                    v-model="feAplcblClmPyot"
                                    solo
                                    class="rounded-0"
                                    :rules="[required()]"
                                    
                                    >
                                </v-select>
                            </v-layout>
                        </v-flex>
                         <v-flex xs3 v-if="shwFeSlb===true">
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                    Claim Payment Basis
                                </p>
                                <v-select
                                    :items="ClmpymntBasisItems"
                                    label="Select"
                                    v-model="feClaimPaymentBasis"
                                    @change="chngFeClaimPaymentBasis()"
                                    solo
                                    class="rounded-0"
                                    :rules="[required()]"
                                    >
                                </v-select>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3 v-if="shwFeCvrgAplcblDrtn===true">
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                   Coverage applicable duration (No of years)
                                </p>
                                <v-select
                                    :items="hacCadItms"
                                    label="Select"
                                    v-model="FeCad"
                                    solo
                                    class="rounded-0"
                                    :rules="[required()]"
                                    
                                    >
                                </v-select>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3>
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">Waiting Period</p>
                                <v-layout>
                                    <v-select
                                        :items="['Days','Months']"
                                        label="Select"
                                        v-model="FEwaitingPeriodDrop"
                                        solo
                                        style="width: 145px"
                                        class="rounded-0"
                                        :rules="[required()]"
                                        >
                                    </v-select>
                                    <v-text-field
                                        label="Enter..."
                                        v-model="FEwaitingPeriod"
                                        solo
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[Numeric2]"
                                        >
                                    </v-text-field>
                                </v-layout>
                            </v-layout>
                        </v-flex>

                    </v-layout>
                    <v-divider></v-divider>
                    <!-- NEW FIELDS ADDED FE -->
                    <v-layout class="mt-3" wrap style="align-items:flex-end">
                        <v-flex xs3>
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">IRDA UIN Number</p>
                                <v-text-field
                                    solo
                                    label="Enter..."
                                    v-model="FEIrdaUINNo"
                                    class="rounded-0"
                                    :maxlength="max20"
                                    :rules="spclCharNew">
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3>
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">External Reference Number</p>
                                <v-text-field
                                    solo
                                    label="Enter..."
                                    v-model="FEExtRefNo"
                                    class="rounded-0"
                                    :maxlength="max30"
                                    :rules="spclCharNew">
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3>
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">Conditions</p>
                                <v-select
                                    :items="prodConditionArray"
                                    label="Select"
                                    v-model="FECondField"
                                    multiple
                                    solo
                                    class="rounded-0"
                                    >
                                </v-select>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3>
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">GC Coverage</p>
                                <v-select
                                    :items="prodGCCoverArray"
                                    label="Select"
                                    v-model="FEGcCover"
                                    solo
                                    class="rounded-0"
                                    :rules="[required()]">
                                </v-select>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3>
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">Remarks</p>
                                <v-text-field
                                    solo
                                    label="Enter..."
                                    v-model="FERemarks"
                                    class="rounded-0"
                                    :maxlength="max40"
                                    >
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3>
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">Exclusions</p>
                                <v-select
                                    :items="prodExcluArray"
                                    :maxlength="max30"
                                    label="Select"
                                    v-model="FEExclus"
                                    solo
                                    class="rounded-0"
                                    multiple
                                    >
                                </v-select>
                            </v-layout>
                        </v-flex>
                    </v-layout>
                    <v-divider></v-divider>
                    <v-flex xs3>
                        <v-layout column class="mx-2 mb-7 cust_pln_cd" justify-center >
                            <p class="font-weight-bold cstm-clr-pln-cd" style="color:#FFFFFF" ></p>
                            <div style="background-color: #f7f7f7;border: 1px solid #c1c8cc;" class="pa-2 mt-5">
                                <v-checkbox class="ma-0 pa-0" v-model="FESelectBox" @change="FERelCheckSelected()" hide-details>
                                    <span slot="label" class="cust_lbl-bgclr font-weight-bold" style="font-size: 13px">Separate Relationship & Dependent Info</span>
                                </v-checkbox>
                            </div>
                        </v-layout>
                    </v-flex>
                        
                    <div v-if="showFERelSection == true">
                        <relationdependent 
                            ref="FERel" 
                            @save="saveTMRFERel" 
                            :redpndntinfo="{
                                cirelDataemty:FErelCoverData.realdata,
                                ciRelnshipCoveredEmpty:FErelCoverData.dropdowndata,
                                cisyncArryEmpty:FErelCoverData.syncarr,
                                ciactiveonlyempty:FErelCoverData.ddselectedvalues
                            }" 
                            v-if="showFERelData == true">
                        </relationdependent>
                    </div>
                    <v-divider></v-divider>
                    <v-layout style="flex:1" justify-end class="my-4">
                        <v-btn dark tile style="font-size:12px;background-color:#e46a25" class="text-capitalize px-3 mx-2" small @click=" clearFE()"><v-icon dark small>close</v-icon> <v-divider vertical class="mx-2"></v-divider>Clear</v-btn>
                        <v-btn dark tile style="font-size:12px;background-color:#23B1A9" class="text-capitalize px-3 mx-2" small @click=" submitTMRFE('FE')"><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider>Save</v-btn>
                        <v-btn @click="nxt(nextdrive[26])" dark style="font-size:12px;background-color:#152F38"
                        tile  class="text-capitalize px-3 ml-2 mr-6" small><v-icon dark small>arrow_forward</v-icon>
                        <v-divider vertical class="mx-2"></v-divider> Next</v-btn>
                    </v-layout>
                </v-form>
                    

                </div>
                
            </div>


            <div class="panelis" v-if="showEBAccor == true" dense>
                <div @click="currentis(nextdrive[27])" expand-icon="mdi-menu-down" class="font-weight-bold panhead" >
                    Education Benefits   <span> <v-icon  :style="{border: datais[nextdrive[27].shown == 'y' ? '5px red solid':'none' ] }"  >arrow_drop_down</v-icon> </span> 
                </div>
                
                <div  class="pa-2"   v-if="datais[nextdrive[27].shown]" >
                    <v-divider></v-divider>
                    <v-form v-model="ebvalid" ref="ebForm">
                    <v-layout class="mt-3" wrap>
                        <v-flex xs3 >
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">Coverage Alternate Name</p>
                                <v-text-field
                                    :rules="alphaRule"
                                    solo
                                    label="Enter..."
                                    v-model="EBCoverAltName"
                                    class="rounded-0"
                                    :maxlength="max200"
                                    >
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3>
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                    Coverage Type
                                </p>
                                <v-select
                                    :items="cvrgTypItems"
                                    label="Select"
                                    v-model="ebCoverageType"
                                    solo
                                    class="rounded-0"
                                    :rules="[required()]"
                                    >
                                </v-select>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3>
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                    Coverage Basis
                                </p>
                                <v-select
                                    :items="cvrgBasisItems"
                                    label="Select"
                                    v-model="ebCoverageBasis"
                                    @change="chngEbCb(1)"
                                    solo
                                    class="rounded-0"
                                    :rules="[required()]"
                                    >
                                </v-select>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3 v-if="shwEbSlb===true">
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                    Sub Limit Basis
                                </p>
                                <v-select
                                    :items="cvrgSbLmtBasisItems"
                                    label="Select"
                                    v-model="ebSubLimitBasis"
                                    @change="chngEbSubLmtBasis(ebSubLimitBasis)"
                                    solo
                                    class="rounded-0"
                                    :rules="[required()]"
                                    multiple
                                    >
                                    <template v-slot:selection="{ item, index }">
                                        <span v-if="index === 0" >{{ item }}</span>
                                        <span v-if="index === 1" class="grey--text caption">(+{{ ebSubLimitBasis.length - 1 }} )</span>
                                    </template>
                                </v-select>
                            </v-layout>
                        </v-flex>
                        
                        <v-flex xs3 v-if="showEBPercentSi == true">
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">% of SI</p>
                                <v-text-field
                                    solo
                                    label="Enter..."
                                    v-model="EBPercentSI"
                                    class="rounded-0"
                                    :maxlength="max10"
                                    :rules="[required(),numberRule,decimalRule()]">
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3 v-if="showEBFlatAmnt == true">
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">Flat Amount</p>
                                <v-text-field
                                    solo
                                    label="Enter..."
                                    v-model="EBFlatAmountTxtField"
                                    class="rounded-0"
                                    :maxlength="max10"
                                     :rules="[required(),alphaNumeric()]">
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3 v-if="showEBPrvPercentSi == true">
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">% of previous yr SI</p>
                                <v-text-field
                                    solo
                                    label="Enter..."
                                    v-model="EBPrvPercentSI"
                                    class="rounded-0"
                                    :maxlength="max10"
                                    :rules="[required(),numberRule,decimalRule()]">
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3 v-if="showEBPercClaimAmt == true">
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">% of Claim Amount</p>
                                <v-text-field
                                    solo
                                    label="Enter..."
                                    v-model="EBPercentClaimAmt"
                                    class="rounded-0"
                                    :maxlength="max10"
                                    :rules="[required(),numberRule,decimalRule()]">
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3 v-if="showEBOpdSi == true">
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">% of OPD SI</p>
                                <v-text-field
                                    solo
                                    label="Enter..."
                                    v-model="EBOpdSi"
                                    class="rounded-0"
                                    :maxlength="max10"
                                    :rules="[required(),numberRule,decimalRule()]">
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3 v-if="showEBAdSi == true">
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">% of AD SI</p>
                                <v-text-field
                                    solo
                                    label="Enter..."
                                    v-model="EBAdSi"
                                    class="rounded-0"
                                    :maxlength="max10"
                                    :rules="[required(),numberRule,decimalRule()]">
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                        
                        <v-flex xs3 v-if="showEBApplicClaimPay == true">
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">Applicable Claim Pay-Out</p>
                                <v-select
                                    :items="hacAplcblClmPyotItms"
                                    label="Select"
                                    v-model="EBApplicClaimPayOut"
                                    solo
                                    class="rounded-0"
                                    :rules="[required()]"
                                    >
                                </v-select>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3>
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                    Claim Payment Basis
                                </p>
                                <v-select
                                    :items="ClmpymntBasisItems"
                                    label="Select"
                                    v-model="ebClaimPaymentBasis"
                                    solo
                                    @change="changeEBClaimPayBasis(ebClaimPaymentBasis)"
                                    class="rounded-0"
                                    :rules="[required()]"
                                    >
                                </v-select>
                            </v-layout>
                        </v-flex>
                        
                        <v-flex xs3 v-if="showEBCovrageApplicDur == true">
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                    Coverage applicable duration (No of years)
                                </p>
                                <v-select
                                    :items="hacCadItms"
                                    label="Select"
                                    v-model="EBCovrageApplicDur"
                                    solo
                                    class="rounded-0"
                                    :rules="[required()]"
                                    >
                                </v-select>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3>
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">Waiting Period</p>
                                <v-layout>
                                    <v-select
                                        :items="['Days','Months']"
                                        label="Select"
                                        v-model="EBwaitingPeriodDrop"
                                        solo
                                        style="width: 145px"
                                        class="rounded-0"
                                        :rules="[required()]"
                                        >
                                    </v-select>
                                    <v-text-field
                                        label="Enter..."
                                        v-model="EBwaitingPeriod"
                                        solo
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[Numeric2]"
                                        >
                                    </v-text-field>
                                </v-layout>
                            </v-layout>
                        </v-flex>
                    </v-layout>
                    <v-divider></v-divider>
                    <!-- NEW FIELDS ADDED EB ELIGIBILITY CRITERIA -->
                    <v-flex class="ml-2 mt-3">
                        <p class="font-weight-bold body-1">Eligibility Criteria</p>
                    </v-flex>
                    <v-layout class="mt-3" wrap style="align-items:flex-end">
                        <v-flex xs3>
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">Max Child Age(in years)</p>
                                <v-text-field
                                    label="Enter..."
                                    v-model="ebMxChldAge"
                                    solo
                                    class="rounded-0"
                                    :maxlength="max10"
                                     :rules="[required(),alphaNumeric()]"
                                    >
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3>
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">Max No. of Years,fees to be Paid </p>
                                <v-text-field
                                    label="Enter..."
                                    v-model="EBMaxYearsFeesPaid"
                                    solo
                                    class="rounded-0"
                                    :maxlength="max10"
                                     :rules="[required(),alphaNumeric()]"
                                    >
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3>
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">Eligibility</p>
                                <v-select
                                    :items="EBEligibleArray"
                                    label="Select"
                                    v-model="EBEligibility"
                                    solo
                                    class="rounded-0"
                                    :rules="[required()]">
                                </v-select>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3>
                            <v-layout column class="mx-2 mb-7 cust_pln_cd" justify-center >
                                <p class="font-weight-bold cstm-clr-pln-cd" style="color:#FFFFFF" ></p>
                                <div class="pa-2 mt-5 elevation-3">
                                    <v-checkbox class="ma-0 pa-0" v-model="EBfeatureClaim" @change="EBFeatureClaimChange(EBfeatureClaim)" hide-details>
                                        <span slot="label" class="cust_lbl-bgclr font-weight-bold" style="font-size: 13px">Feature Claim</span>
                                    </v-checkbox>
                                </div>
                            </v-layout>
                        </v-flex>
                        <v-flex v-if="showEBPrimCoverage == true" xs3>
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">Primary Coverages</p>
                                <v-select
                                    :items="EBprimCovrgArray"
                                    label="Select"
                                    v-model="EBPrimCover"
                                    multiple
                                    solo
                                    class="rounded-0"
                                    :rules="[required()]">
                                </v-select>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3>
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">Benefit Payable</p>
                                <v-select
                                    :items="EBBenpayArray"
                                    label="Select"
                                    v-model="EBBenpay"
                                    solo
                                    class="rounded-0"
                                    :rules="[required()]">
                                </v-select>
                            </v-layout>
                        </v-flex>
                    </v-layout>
                    <v-divider></v-divider>
                    <!-- NEW FIELDS ADDED EB -->
                    <v-layout class="mt-3" wrap style="align-items:flex-end">
                        <v-flex xs3>
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">IRDA UIN Number</p>
                                <v-text-field
                                    solo
                                    label="Enter..."
                                    v-model="EBIrdaUINNo"
                                    class="rounded-0"
                                    :maxlength="max20"
                                    :rules="spclCharNew">
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3>
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">External Reference Number</p>
                                <v-text-field
                                    solo
                                    label="Enter..."
                                    v-model="EBExtRefNo"
                                    class="rounded-0"
                                    :maxlength="max30"
                                    :rules="spclCharNew">
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3>
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">Conditions</p>
                                <v-select
                                    :items="prodConditionArray"
                                    label="Select"
                                    v-model="EBCondField"
                                    multiple
                                    solo
                                    class="rounded-0"
                                    >
                                </v-select>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3>
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">GC Coverage</p>
                                <v-select
                                    :items="prodGCCoverArray"
                                    label="Select"
                                    v-model="EBGcCover"
                                    solo
                                    class="rounded-0"
                                    :rules="[required()]">
                                </v-select>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3>
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">Remarks</p>
                                <v-text-field
                                    solo
                                    label="Enter..."
                                    v-model="EBRemarks"
                                    class="rounded-0"
                                    :maxlength="max40"
                                    >
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3>
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">Exclusions</p>
                                <v-select
                                    :items="prodExcluArray"
                                    :maxlength="max30"
                                    label="Select"
                                    v-model="EBExclus"
                                    solo
                                    class="rounded-0"
                                    multiple
                                    >
                                </v-select>
                            </v-layout>
                        </v-flex>
                    </v-layout>
                    <v-divider></v-divider>
                    <v-flex xs3>
                        <v-layout column class="mx-2 mb-7 cust_pln_cd" justify-center >
                            <p class="font-weight-bold cstm-clr-pln-cd" style="color:#FFFFFF" ></p>
                            <div style="background-color: #f7f7f7;border: 1px solid #c1c8cc;" class="pa-2 mt-5">
                                <v-checkbox class="ma-0 pa-0" v-model="EBSelectBox" @change="EBRelCheckSelected()" hide-details>
                                    <span slot="label" class="cust_lbl-bgclr font-weight-bold" style="font-size: 13px">Separate Relationship & Dependent Info</span>
                                </v-checkbox>
                            </div>
                        </v-layout>
                    </v-flex>
                        
                    <div v-if="showEBRelSection == true">
                        <relationdependent 
                            ref="EBRel" 
                            @save="saveEBNARel" 
                            :redpndntinfo="{
                                cirelDataemty:EBrelCoverData.realdata,
                                ciRelnshipCoveredEmpty:EBrelCoverData.dropdowndata,
                                cisyncArryEmpty:EBrelCoverData.syncarr,
                                ciactiveonlyempty:EBrelCoverData.ddselectedvalues
                            }" 
                            v-if="showEBRelData == true">
                        </relationdependent>
                    </div>
                    <v-divider></v-divider>
                    <v-layout style="flex:1" justify-end class="my-4">
                        <v-btn dark tile style="font-size:12px;background-color:#e46a25" class="text-capitalize px-3 mx-2" small @click="clearEB()"><v-icon dark small>close</v-icon> <v-divider vertical class="mx-2"></v-divider>Clear</v-btn>
                        <v-btn dark tile style="font-size:12px;background-color:#23B1A9" class="text-capitalize px-3 mx-2" small @click="submitEBNA('eduBenefit')"><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider>Save</v-btn>
                        <v-btn @click="nxt(nextdrive[27])" dark style="font-size:12px;background-color:#152F38" tile
                        class="text-capitalize px-3 ml-2 mr-6" small><v-icon dark small>arrow_forward</v-icon>
                        <v-divider vertical class="mx-2"></v-divider> Next</v-btn>
                    </v-layout>
                </v-form>
                    

                </div>
                
            </div>


            <div class="panelis" v-if="showITAccor == true" dense>
                <div @click="currentis(nextdrive[28])" expand-icon="mdi-menu-down" class="font-weight-bold panhead" >
                    Infertility Treatment   <span> <v-icon  :style="{border: datais[nextdrive[28].shown == 'y' ? '5px red solid':'none' ] }"  >arrow_drop_down</v-icon> </span> 
                </div>
                
                <div  class="pa-2"   v-if="datais[nextdrive[28].shown]">
                    <v-divider></v-divider>
                    <v-form v-model="ifvalid" ref="ifForm">
                        <v-layout class="mt-3" wrap>
                            <v-flex xs3 >
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Coverage Alternate Name</p>
                                    <v-text-field
                                        :rules="alphaRule"
                                        solo
                                        label="Enter..."
                                        v-model="ITCoverAltName"
                                        class="rounded-0"
                                        :maxlength="max200">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Coverage Type
                                    </p>
                                    <v-select
                                        :items="cvrgTypItems"
                                        label="Select"
                                        v-model="iftCoverageType"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]"
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Coverage Basis
                                    </p>
                                    <v-select
                                        :items="cvrgBasisItems"
                                        label="Select"
                                        v-model="iftCoverageBasis"                                  
                                        @change="chngIftCb(1)"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]"
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="shwIftSlb===true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Sub Limit Basis
                                    </p>
                                    <v-select
                                        :items="cvrgSbLmtBasisItems"
                                        label="Select"
                                        v-model="iftSubLimitBasis"
                                        @change="chngIfSubLmtBasis(iftSubLimitBasis)"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]"
                                        multiple
                                        >
                                        <template v-slot:selection="{ item, index }">
                                    
                                        <span v-if="index === 0" >{{ item }}</span>
                                    
                                        <span
                                        v-if="index === 1"
                                        class="grey--text caption"
                                        >
                                        (+{{ iftSubLimitBasis.length - 1 }} )
                                        </span>
                                    </template>
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            
                            <v-flex xs3 v-if="showItPercentSi == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">% of SI</p>
                                    <v-text-field
                                        solo
                                        
                                        label="Enter..."
                                        v-model="ItPercentSI"
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),numberRule,decimalRule()]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="showItFlatAmnt == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Flat Amount</p>
                                    <v-text-field
                                        solo
                                        
                                        label="Enter..."
                                        v-model="ItFlatAmountTxtField"
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),alphaNumeric()]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="showItPrvPercentSi == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">% of previous yr SI</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="ItPrvPercentSI"
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),numberRule,decimalRule()]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="showItPercClaimAmt == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">% of Claim Amount</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="ItPercentClaimAmt"
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),numberRule,decimalRule()]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="showITOpdSi == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">% of OPD SI</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="ITOpdSi"
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),numberRule,decimalRule()]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="showITAdSi == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">% of AD SI</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="ITAdSi"
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),numberRule,decimalRule()]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            
                            <v-flex xs3 v-if="showItApplicClaimPay == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Applicable Claim Pay-Out</p>
                                    <v-select
                                        :items="hacAplcblClmPyotItms"
                                        label="Select"
                                        v-model="ItApplicClaimPayOut"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]"
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Claim Payment Basis
                                    </p>
                                    <v-select
                                        :items="ClmpymntBasisItems"
                                        label="Select"
                                        v-model="iftClaimPaymentBasis"
                                        @change="changeItClaimPayBasis(iftClaimPaymentBasis)"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]"
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            
                            <v-flex xs3 v-if="showItCovrageApplicDur == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Coverage applicable duration (No of years)
                                    </p>
                                    <v-select
                                        :items="hacCadItms"
                                        label="Select"
                                        v-model="ItCovrageApplicDur"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]"
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                        
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Waiting Period</p>
                                    <v-layout>
                                        <v-select
                                            :items="['Days','Months']"
                                            label="Select"
                                            v-model="ITwaitingPeriodDrop"
                                            solo
                                            style="width: 145px"
                                            class="rounded-0"
                                            :rules="[required()]"
                                            >
                                        </v-select>
                                        <v-text-field
                                            label="Enter..."
                                            v-model="iftWaitingPeriod"
                                            solo
                                            class="rounded-0"
                                            :maxlength="max10"
                                            :rules="[Numeric2]"
                                            >
                                        </v-text-field>
                                    </v-layout>
                                </v-layout>
                            </v-flex>
                            
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Treatment Exclusions</p>
                                    <v-select
                                        label="Select"
                                        :items="iftExclsnsItems"
                                        v-model="iftExclsns"
                                        multiple
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]"
                                        attach
                                        >
                                        <template v-slot:selection="{ item, index }">
                                            <span v-if="index === 0" >{{ item }}</span>
                                            <span v-if="index === 1" class="grey--text caption">+{{ iftExclsns.length - 1 }} )</span>
                                        </template>
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                    Type of Medical Expense
                                    </p>
                                    <v-select
                                        label="Select"
                                        :items="iftTypMdclExpnseItems"
                                        v-model="iftMdclExpnse"
                                        multiple
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]"
                                        >
                                        <template v-slot:selection="{ item, index }">
                                            <span v-if="index === 0" >{{ item }}</span>
                                            <span v-if="index === 1" class="grey--text caption">(+{{ iftMdclExpnse.length - 1 }} )</span>
                                        </template>
                                    </v-select>
                                </v-layout>
                            </v-flex>

                        </v-layout>
                        <v-divider></v-divider>
                        <!-- NEW FIELDS ADDED IT -->
                        <v-layout class="mt-3" wrap style="align-items:flex-end">
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">IRDA UIN Number</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="ITIrdaUINNo"
                                        class="rounded-0"
                                        :maxlength="max20"
                                        :rules="spclCharNew">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">External Reference Number</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="ITExtRefNo"
                                        class="rounded-0"
                                        :maxlength="max30"
                                        :rules="spclCharNew">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Conditions</p>
                                    <v-select
                                        :items="prodConditionArray"
                                        label="Select"
                                        v-model="ITCondField"
                                        multiple
                                        solo
                                        class="rounded-0">
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">GC Coverage</p>
                                    <v-select
                                        :items="prodGCCoverArray"
                                        label="Select"
                                        v-model="ITGcCover"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]">
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Remarks</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="ITRemarks"
                                        class="rounded-0"
                                        :maxlength="max54">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Exclusions</p>
                                    <v-select
                                        :items="prodExcluArray"
                                        :maxlength="max30"
                                        label="Select"
                                        v-model="ITExclus"
                                        solo
                                        class="rounded-0"
                                        multiple>
                                    </v-select>
                                </v-layout>
                            </v-flex>
                        </v-layout> 
                        <v-divider></v-divider>

                        <v-flex xs3>
                            <v-layout column class="mx-2 mb-7 cust_pln_cd" justify-center >
                                <p class="font-weight-bold cstm-clr-pln-cd" style="color:#FFFFFF" ></p>
                                <div style="background-color: #f7f7f7;border: 1px solid #c1c8cc;" class="pa-2 mt-5">
                                    <v-checkbox class="ma-0 pa-0" v-model="ITSelectBox" @change="ITRelCheckSelected()" hide-details>
                                        <span slot="label" class="cust_lbl-bgclr font-weight-bold" style="font-size: 13px">Separate Relationship & Dependent Info</span>
                                    </v-checkbox>
                                </div>
                            </v-layout>
                        </v-flex>
                            
                        <div v-if="showITRelSection == true">
                            <relationdependent 
                                ref="ITRel" 
                                @save="saveVCITRel" 
                                :redpndntinfo="{
                                    cirelDataemty:ITrelCoverData.realdata,
                                    ciRelnshipCoveredEmpty:ITrelCoverData.dropdowndata,
                                    cisyncArryEmpty:ITrelCoverData.syncarr,
                                    ciactiveonlyempty:ITrelCoverData.ddselectedvalues
                                }" 
                                v-if="showITRelData == true">
                            </relationdependent>
                        </div>
                        <v-divider></v-divider>
                        <v-layout style="flex:1" justify-end class="my-4">
                            <v-btn dark tile style="font-size:12px;background-color:#e46a25"  class="text-capitalize px-3 mx-2" small @click="clearIT()"><v-icon dark small>close</v-icon> <v-divider vertical class="mx-2"></v-divider>Clear</v-btn>
                            <v-btn dark tile style="font-size:12px;background-color:#23B1A9"  class="text-capitalize px-3 mx-2" small @click="submitVCIT('infertTreat')()"><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider>Save</v-btn>
                            <v-btn dark style="font-size:12px;background-color:#152F38" @click="nxt(nextdrive[28])" tile
                            class="text-capitalize px-3 ml-2 mr-6" small><v-icon dark small>arrow_forward</v-icon> <v-divider vertical class="mx-2"></v-divider> Next</v-btn>
                        </v-layout>
                    </v-form>
                    

                </div>
                
            </div>

            <div class="panelis" dense v-if="shwExpnsnVctn===true">
                <div @click="currentis(nextdrive[29])" expand-icon="mdi-menu-down" class="font-weight-bold panhead" >
                    Vaccination   <span> <v-icon  :style="{border: datais[nextdrive[29].shown == 'y' ? '5px red solid':'none' ] }"  >arrow_drop_down</v-icon> </span> 
                </div>
                
                <div  class="pa-2"   v-if="datais[nextdrive[29].shown]">
                    <v-divider></v-divider>
                    <v-form v-model="vcntnvalid" ref="vcntnForm">
                    <v-layout class="mt-3" wrap style="align-items:flex-end">
                        <v-flex xs3 >
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">Coverage Alternate Name</p>
                                <v-text-field
                                    :rules="alphaRule"
                                    solo
                                    label="Enter..."
                                    v-model="vaccCoverAltName"
                                    class="rounded-0"
                                    :maxlength="max200"
                                    >
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3>
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                    Coverage Type
                                </p>
                                <v-select
                                    :items="cvrgTypItems"
                                    label="Select"
                                    v-model="vcntnCoverageType"
                                    solo
                                    class="rounded-0"
                                    :rules="[required()]"
                                    
                                    >
                                </v-select>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3>
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                    Coverage Basis
                                </p>
                                <v-select
                                    :items="cvrgBasisItems"
                                    label="Select"
                                    v-model="vcntnCoverageBasis"
                                    @change="chngVcntnCb(1)"
                                    solo
                                    class="rounded-0"
                                    :rules="[required()]"
                                    
                                    >
                                </v-select>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3 v-if="shwVcntnSlb===true">
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                    Sub Limit Basis
                                </p>
                                <v-select
                                    :items="cvrgSbLmtBasisItems"
                                    label="Select"
                                    v-model="vcntnSubLimitBasis"
                                     @change="chngVcntnSubLmtBasis()"
                                    solo
                                    class="rounded-0"
                                    :rules="[required()]"
                                    multiple
                                    
                                    >
                                    <template v-slot:selection="{ item, index }">
                                   
                                    <span v-if="index === 0" >{{ item }}</span>
                                   
                                    <span
                                    v-if="index === 1"
                                    class="grey--text caption"
                                    >
                                    (+{{ vcntnSubLimitBasis.length - 1 }} )
                                    </span>
                                </template>
                                </v-select>
                            </v-layout>
                        </v-flex>
                         <v-flex xs3 v-if="shwVcntnPrcntSi===true">
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                   % of SI
                                </p>
                                 <v-text-field
                                    label="Enter..."
                                    v-model="vcntnPrcntSi"
                                    solo
                                    class="rounded-0"
                                    :maxlength="max10"
                                    :rules="[required(),numberRule,decimalRule()]"
                                    >
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3 v-if="shwVcntnFltAmt===true">
                            <v-layout column class="cust_pln_cd mx-2  cust-pln-cd">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                    Flat Amount
                                </p>
                                <v-text-field
                                    label="Enter..."
                                    v-model="vcntnFlatAmt"
                                    solo
                                    class="rounded-0"
                                    :maxlength="max10"
                                    :rules="[required(),alphaNumeric()]"
                                    >
                                <div slot="prepend-inner" style="background-color:#F7F7F7;padding: 9px !important"><v-icon >mdi-currency-inr </v-icon></div>
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3 v-if="shwVcntnPrcntPrvSi===true">
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                   % of previous yr SI
                                </p>
                                 <v-text-field
                                    label="Enter..."
                                    v-model="vcntnPrcntPrvSi"
                                    solo
                                    class="rounded-0"
                                    :maxlength="max10"
                                    :rules="[required(),numberRule,decimalRule()]"
                                    >
                                </v-text-field>
                            </v-layout>
                        </v-flex>

                        <v-flex xs3 v-if="shwVcntnPrcntClmAmt===true">
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                  % of claim amount
                                </p>
                                 <v-text-field
                                    label="Enter..."
                                    v-model="vcntnPrcntClmAmt"
                                    solo
                                    class="rounded-0"
                                    :maxlength="max10"
                                    :rules="[required(),numberRule,decimalRule()]"
                                    >
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3 v-if="showvaccOpdSi == true">
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">% of OPD SI</p>
                                <v-text-field
                                    solo
                                    label="Enter..."
                                    v-model="vaccOpdSi"
                                    class="rounded-0"
                                    :maxlength="max10"
                                    :rules="[required(),numberRule,decimalRule()]">
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3 v-if="showvaccAdSi == true">
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">% of AD SI</p>
                                <v-text-field
                                    solo
                                    label="Enter..."
                                    v-model="vaccAdSi"
                                    class="rounded-0"
                                    :maxlength="max10"
                                    :rules="[required(),numberRule,decimalRule()]">
                                </v-text-field>
                            </v-layout>
                        </v-flex>

                        <v-flex xs3 v-if="shwvcntnAplcblClmPyot===true">
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                    Applicable claim pay-out
                                </p>
                                <v-select
                                    :items="hacAplcblClmPyotItms"
                                    label="Select"
                                    v-model="vcntnAplcblClmPyot"
                                    solo
                                    class="rounded-0"
                                    :rules="[required()]"
                                    
                                    >
                                </v-select>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3 v-if="shwVcntnSlb===true">
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                    Claim Payment Basis
                                </p>
                                <v-select
                                    :items="ClmpymntBasisItems"
                                    label="Select"
                                    v-model="vcntnClaimPaymentBasis"
                                    @change="chngVcntnClaimPaymentBasis()"
                                    solo
                                    class="rounded-0"
                                    :rules="[required()]"
                                    >
                                </v-select>
                            </v-layout>
                        </v-flex>

                        <v-flex xs3 v-if="shwVcntnCvrgAplcblDrtn===true">
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                   Coverage applicable duration (No of years)
                                </p>
                                <v-select
                                    :items="hacCadItms"
                                    label="Select"
                                    v-model="VcntnCad"
                                    solo
                                    class="rounded-0"
                                    :rules="[required()]"
                                    
                                    >
                                </v-select>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3>
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">Waiting Period</p>
                                <v-layout>
                                    <v-select
                                        :items="['Days','Months']"
                                        label="Select"
                                        v-model="vaccwaitingPeriodDrop"
                                        solo
                                        style="width: 145px"
                                        class="rounded-0"
                                        :rules="[required()]"
                                        >
                                    </v-select>
                                    <v-text-field
                                        label="Enter..."
                                        v-model="vcntnWaitingPeriod"
                                        solo
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[Numeric2]"
                                        >
                                    </v-text-field>
                                </v-layout>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3>
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                   Eligible Duration(in years)
                                </p>
                                 <v-text-field
                                    label="Enter..."
                                    v-model="vcntnElglblDrtn"
                                    solo
                                    class="rounded-0"
                                    :maxlength="max10"
                                    :rules="[required(),alphaNumeric()]"
                                    >
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                    </v-layout>

                    <v-divider></v-divider>
                    <!-- NEW FIELDS ADDED VACCINE-->
                    <v-layout class="mt-3" wrap style="align-items:flex-end">
                        <v-flex xs3>
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">IRDA UIN Number</p>
                                <v-text-field
                                    solo
                                    label="Enter..."
                                    v-model="vaccIrdaUINNo"
                                    class="rounded-0"
                                    :maxlength="max20"
                                    :rules="spclCharNew">
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3>
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">External Reference Number</p>
                                <v-text-field
                                    solo
                                    label="Enter..."
                                    v-model="vaccExtRefNo"
                                    class="rounded-0"
                                    :maxlength="max30"
                                    :rules="spclCharNew">
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3>
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">Conditions</p>
                                <v-select
                                    :items="prodConditionArray"
                                    label="Select"
                                    v-model="vaccCondField"
                                    multiple
                                    solo
                                    class="rounded-0"
                                    >
                                </v-select>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3>
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">GC Coverage</p>
                                <v-select
                                    :items="prodGCCoverArray"
                                    label="Select"
                                    v-model="vaccGcCover"
                                    solo
                                    class="rounded-0"
                                    :rules="[required()]">
                                </v-select>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3>
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">Remarks</p>
                                <v-text-field
                                    solo
                                    label="Enter..."
                                    v-model="vaccRemarks"
                                    class="rounded-0"
                                    :maxlength="max40"
                                    >
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3>
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">Exclusions</p>
                                <v-select
                                    :items="prodExcluArray"
                                    :maxlength="max30"
                                    label="Select"
                                    v-model="vaccExclus"
                                    solo
                                    class="rounded-0"
                                    multiple
                                    >
                                </v-select>
                            </v-layout>
                        </v-flex>
                    </v-layout>
                    <!-- saveVcntn() -->
                    <v-divider></v-divider>
                    <v-flex xs3>
                        <v-layout column class="mx-2 mb-7 cust_pln_cd" justify-center >
                            <p class="font-weight-bold cstm-clr-pln-cd" style="color:#FFFFFF" ></p>
                            <div style="background-color: #f7f7f7;border: 1px solid #c1c8cc;" class="pa-2 mt-5">
                                <v-checkbox class="ma-0 pa-0" v-model="vaccSelectBox" @change="vaccRelCheckSelected()" hide-details>
                                    <span slot="label" class="cust_lbl-bgclr font-weight-bold" style="font-size: 13px">Separate Relationship & Dependent Info</span>
                                </v-checkbox>
                            </div>
                        </v-layout>
                    </v-flex>
                        
                    <div v-if="showvaccRelSection == true">
                        <relationdependent 
                            ref="vaccRel" 
                            @save="saveCTCVC" 
                            :redpndntinfo="{
                                cirelDataemty:vaccrelCoverData.realdata,
                                ciRelnshipCoveredEmpty:vaccrelCoverData.dropdowndata,
                                cisyncArryEmpty:vaccrelCoverData.syncarr,
                                ciactiveonlyempty:vaccrelCoverData.ddselectedvalues
                            }" 
                            v-if="showvaccRelData == true">
                        </relationdependent>
                    </div>
                    <v-divider></v-divider>
                 <v-layout style="flex:1" justify-end class="my-4">
                    <v-btn dark tile style="font-size:12px;background-color:#e46a25"  class="text-capitalize px-3 mx-2" small @click="clearVaccine()"><v-icon dark small>close</v-icon> <v-divider vertical class="mx-2"></v-divider>Clear</v-btn>
                    <v-btn dark tile style="font-size:12px;background-color:#23B1A9"  class="text-capitalize px-3 mx-2" small @click="submitCTCVC('vctn')"><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider>Save</v-btn>
                    <v-btn @click="nxt(nextdrive[29])" dark style="font-size:12px;background-color:#152F38" tile
                    class="text-capitalize px-3 ml-2 mr-6" small><v-icon dark small>arrow_forward</v-icon>
                    <v-divider vertical class="mx-2"></v-divider> Next</v-btn>
                </v-layout>
                </v-form>
                    

                </div>
                
            </div>

            <div class="panelis" dense v-if="showHacAccor === true">
                <div @click="currentis(nextdrive[30])" expand-icon="mdi-menu-down" class="font-weight-bold panhead" >
                    Hearing Aid Cover   <span> <v-icon  :style="{border: datais[nextdrive[30].shown == 'y' ? '5px red solid':'none' ] }"  >arrow_drop_down</v-icon> </span> 
                </div>
                
                <div  class="pa-2"   v-if="datais[nextdrive[30].shown]">
                    <v-divider></v-divider>
                    <v-form v-model="hacvalid" ref="hacForm">
                    <v-layout class="mt-3" style="align-items:flex-end" wrap>
                        <v-flex xs3>
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                    Coverage Type
                                </p>
                                <v-select
                                    :items="cvrgTypItems"
                                    label="Select"
                                    v-model="hacCoverageType"
                                    solo
                                    class="rounded-0"
                                    :rules="[required()]"
                                    >
                                </v-select>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3>
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                    Coverage Basis
                                </p>
                                <v-select
                                    :items="cvrgBasisItems"
                                    label="Select"
                                    v-model="hacCoverageBasis"
                                    @change="chngHacCb(1)"
                                    solo
                                    class="rounded-0"
                                    :rules="[required()]"
                                    >
                                </v-select>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3 v-if="shwHacSlb===true">
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                    Sub Limit Basis
                                </p>
                                <v-select
                                    :items="cvrgSbLmtBasisItems"
                                    label="Select"
                                    v-model="hacSubLimitBasis"
                                     @change="chngHacSubLmtBasis()"
                                    solo
                                    class="rounded-0"
                                    :rules="[required()]"
                                    multiple
                                    >
                                    <template v-slot:selection="{ item, index }">
                                   
                                    <span v-if="index === 0" >{{ item }}</span>
                                   
                                    <span
                                    v-if="index === 1"
                                    class="grey--text caption"
                                    >
                                    (+{{ hacSubLimitBasis.length - 1 }} )
                                    </span>
                                </template>
                                </v-select>
                            </v-layout>
                        </v-flex>
                        
                        
                        <v-flex xs3 v-if="shwHAcPerSI===true">
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                    % of SI
                                </p>
                                <v-text-field
                                    label="Enter..."
                                    v-model="hacPerSI"
                                    solo
                                    class="rounded-0"
                                    :maxlength="max10"
                                    :rules="[required(),numberRule,decimalRule()]"
                                    >
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3 v-if="shwHAcFltAmt===true">
                            <v-layout column class="cust_pln_cd mx-2  cust-pln-cd">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                    Flat Amount
                                </p>
                                <v-text-field
                                    label="Enter..."
                                    v-model="hacFlatAmt"
                                    solo
                                    class="rounded-0"
                                    :maxlength="max10"
                                    :rules="[required(),alphaNumeric()]"
                                    >
                                <div slot="prepend-inner" style="background-color:#F7F7F7;padding: 9px !important"><v-icon >mdi-currency-inr </v-icon></div>
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3 v-if="shwHAcPrvPerSI===true">
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                    % of previous yr SI
                                </p>
                                <v-text-field
                                    label="Enter..."
                                    v-model="hacPrvPerSI"
                                    solo
                                    class="rounded-0"
                                    :maxlength="max10"
                                    :rules="[required(),numberRule,decimalRule()]"
                                    >
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3 v-if="shwHAcClmAmt===true">
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                   % of claim amount
                                </p>
                                <v-text-field
                                    label="Enter..."
                                    v-model="hacClmAmt"
                                    solo
                                    class="rounded-0"
                                    :maxlength="max10"
                                   :rules="[required(),numberRule,decimalRule()]"
                                >
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3 v-if="showhacacpo===true">
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                    Applicable claim pay-out
                                </p>
                                <v-select
                                    :items="hacAplcblClmPyotItms"
                                    label="Select"
                                    v-model="hacAplcblClmPyot"
                                    solo
                                    class="rounded-0"
                                    :rules="[required()]"
                                    >
                                </v-select>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3 v-if="shwHacSlb===true">
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                    Claim Payment Basis
                                </p>
                                <v-select
                                    :items="ClmpymntBasisItems"
                                    label="Select"
                                    v-model="hacClaimPaymentBasis"
                                    @change="chngHacClaimPaymentBasis()"
                                    solo
                                    class="rounded-0"
                                    :rules="[required()]"
                                    >
                                </v-select>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3 v-if="shwHAcCvrgAplcblDrtn===true">
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                   Coverage applicable duration (No of years)
                                </p>
                                <v-select
                                    :items="hacCadItms"
                                    label="Select"
                                    v-model="hacCad"
                                    solo
                                    class="rounded-0"
                                    :rules="[required()]"
                                    >
                                </v-select>
                            </v-layout>
                        </v-flex>
                    
                        <v-flex xs3>
                            <v-layout column class="cust_pln_cd mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-cd">
                                   Waiting Period
                                </p>
                                <v-text-field
                                    label="Enter..."
                                    v-model="hacWaitingPeriod"
                                    solo
                                    class="rounded-0"
                                    :maxlength="max10"
                                    :rules="[Numeric2]"
                                    >
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                       
                        <!-- <v-flex xs3> </v-flex>
                        <v-flex xs3> </v-flex>
                        <v-flex xs3> </v-flex> -->
                    </v-layout>
                    <v-divider></v-divider>
                 <v-layout style="flex:1" justify-end class="my-4">
                    <v-btn dark tile style="font-size:12px;background-color:#23B1A9" class="text-capitalize px-3 mx-2" small @click="saveHAHED('hac')"><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider>Save</v-btn>
                    <v-btn @click="nxt(nextdrive[30])" dark style="font-size:12px;background-color:#152F38"
                     tile  class="text-capitalize px-3 ml-2 mr-6" small><v-icon dark small>arrow_forward</v-icon>
                     <v-divider vertical class="mx-2"></v-divider> Next</v-btn>
                </v-layout>
                </v-form>
                    
                </div>
                
            </div>

            <!-- IN-PATIENT DENTAL COVERAGE -->
            <div class="panelis" v-if="showIPDAccor == true" dense>
                <div @click="currentis(nextdrive[31])" expand-icon="mdi-menu-down" class="font-weight-bold panhead" >
  
                    In-patient Dental  <span> <v-icon  :style="{border: datais[nextdrive[31].shown == 'y' ? '5px red solid':'none' ] }"  >arrow_drop_down</v-icon> </span> 
                </div>
                <div  class="pa-2"    v-if="datais[nextdrive[31].shown]" >
                    <v-divider></v-divider>
                    <v-form v-model="IPDvalid" ref="IPDForm">
                        <v-layout class="mt-3" wrap style="align-items:flex-end">
                            <v-flex xs3 >
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Coverage Alternate Name</p>
                                    <v-text-field
                                        :rules="alphaRule"
                                        solo
                                        label="Enter..."
                                        v-model="IPDCoverAltName"
                                        class="rounded-0"
                                        :maxlength="max200"
                                        >
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Coverage Type
                                    </p>
                                    <v-select
                                        :items="cvrgTypItems"
                                        label="Select"
                                        v-model="IPDCoverageType"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]"
                                    >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Coverage Basis
                                    </p>
                                    <v-select
                                        :items="cvrgBasisItems"
                                        label="Select"
                                        v-model="IPDCoverageBasis"
                                        @change="changeIPDCoverBasis(1)"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]"
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            
                            <v-flex xs3 v-if="showIPDsubLimBasis == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Sub Limit Basis
                                    </p>
                                    <v-select
                                        :items="IPHSbLmtBasisItems"
                                        label="Select"
                                        v-model="IPDSubLimitBasis"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]"
                                        multiple
                                        @change="changeIPDSubLmtBasis(IPDSubLimitBasis)"
                                        >
                                        <template v-slot:selection="{ item, index }">
                                            <span v-if="index === 0" >{{ item }}</span>
                                            <span v-if="index === 1" class="grey--text caption">(+{{ IPDSubLimitBasis.length - 1 }})</span>
                                        </template>
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            
                            <v-flex xs3 v-if="showIPDPercentSi == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">% of SI</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="IPDPercentSI"
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),numberRule,decimalRule()]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="showIPDFlatAmnt == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Flat Amount</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="IPDFlatAmountTxtField"
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),alphaNumeric()]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="showIPDPrvPercentSi == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">% of previous yr SI</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="IPDPrvPercentSI"
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),numberRule,decimalRule()]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="showIPDPercClaimAmt == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">% of Claim Amount</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="IPDPercentClaimAmt"
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),numberRule,decimalRule()]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="showIPDOpdSi == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">% of OPD SI</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="IPDOpdSi"
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),numberRule,decimalRule()]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="showIPDAdSi == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">% of AD SI</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="IPDAdSi"
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),numberRule,decimalRule()]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="showIPDAppli == true">
                                <v-layout column class="cust_pln_cd mx-2">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Applicable Claim Pay-Out
                                    </p>
                                    <v-select
                                        :items="selectApplicableClaimPayOut"
                                        v-model="IPDapplicableClaimPayOut"
                                        label="Select"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]"
                                    ></v-select>
                                </v-layout>
                            </v-flex>
                            
                            <v-flex xs3 v-if="showIPDsubLimBasis == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Claim Payment Basis
                                    </p>
                                    <v-select
                                        :items="ClmpymntBasisItems"
                                        label="Select"
                                        v-model="IPDClaimPaymentBasis"
                                        solo
                                        @change="changeIPDClaimPaymentBasis(IPDClaimPaymentBasis)"
                                        class="rounded-0"
                                        :rules="[required()]"
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="showIPDCovrageApplicDur == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Coverage applicable duration (No of years)
                                    </p>
                                    <v-select
                                        :items="hacCadItms"
                                        label="Select"
                                        v-model="IPDCovrageApplicDur"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]"
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Waiting Period</p>
                                    <v-layout>
                                        <v-select
                                            :items="['Days','Months']"
                                            label="Select"
                                            v-model="IPDWaitingPeriodDrop"
                                            solo
                                            style="width: 145px"
                                            class="rounded-0"
                                            :rules="[required()]"
                                            >
                                        </v-select>
                                        <v-text-field
                                            label="Enter..."
                                            v-model="IPDWaitingPeriod"
                                            solo
                                            class="rounded-0"
                                            :maxlength="max10"
                                            :rules="[Numeric2]"
                                            >
                                        </v-text-field>
                                    </v-layout>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                       Minimum Hospitalization Duration for Eligible Claim(No. of hours)
                                    </p>
                                    <v-text-field
                                        label="Enter..."
                                        v-model="IPDMinHospDura"
                                        solo
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[Numeric2]"
                                        >
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                       Co-pay applicable
                                    </p>
                                    <v-select
                                        :items="inPtntHzptnCoPayapplicableItems"
                                        label="Select"
                                        v-model="IPDCoPayapplicable"
                                        @change="changeIPDCopyAplcbl()"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]"
                                    >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            
                             <v-flex xs3 v-if="showIPDcoPayType == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                       Co-Pay Type
                                    </p>
                                    <v-select
                                        :items="inPtntHzptnCoPayTypeItems"
                                        label="Select"
                                        v-model="IPDCoPayType"
                                        @change="changeIPDCoPayPrcnt()"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]"
                                    >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="showIPDCoPayPrcnt == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                       Co-pay (%)
                                    </p>
                                    <v-text-field
                                        label="Enter..."
                                        v-model="IPDCoPayPrcnt"
                                        solo
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[v => v <= 100 || 'Values between 0 and 100 are accepted',Numeric2,required()]"
                                        >
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            
                        </v-layout>
                        
                        <v-divider></v-divider>
                        <div style="display:flex" class="mx-2">
                            <v-flex xs6 v-if="showIPDCoPayPercRoom == true" style="flex-direction:column" class="mr-4">
                                <span class="font-weight-bold mt-3 cstm-clr-pln-cd">Co-Pay Grid</span>
                                <v-divider class="mb-4"></v-divider>
                                <v-card tile >
                                    <div style="" class="gridheader">
                                        <div class="font-weight-bold ml-2" style="flex:1; border-right:1px solid #C1C8CC; color:white; font-size: 13px !important;"><p class="my-2 pa-0 ma-0"> Room Type</p></div>
                                        <div class="font-weight-bold mx-2" style="flex:1;font-size: 13px !important;color:white"> <p class="my-2 pa-0 ma-0">Co-pay (%)</p></div>
                                    </div>
                                    <div style="display:flex;">
                                        <div class="font-weight-bold ml-2" style="flex:1; border-right:1px solid #C1C8CC; font-size: 13px !important;"><p class="my-4 pa-0 ma-0"> Shared Room</p></div>
                                        <div style="flex:1" class="mx-2 cust_pln_cd">
                                            <v-text-field 
                                                solo
                                                placeholder="Enter..."
                                                :maxlength="max10"
                                                v-model="IPDCoPayPrcntSR"
                                                :rules="[v => v <= 100 || 'Values between 0 and 100 are accepted',Numeric2,required()]"
                                                class="rounded-0 ">
                                            </v-text-field>
                                        </div>
                                    </div>
                                    
                                    <v-divider></v-divider>
                                    <div style="display:flex">
                                        <div class="font-weight-bold ml-2" style="flex:1; border-right:1px solid #C1C8CC; font-size: 13px !important;"><p class="my-4 pa-0 ma-0">No Restriction</p></div>
                                        <div style="flex:1" class="mx-2 cust_pln_cd">
                                            <v-text-field 
                                                solo
                                                placeholder="Enter..."
                                                :maxlength="max10"
                                                v-model="IPDCoPayPrcntNR"
                                                :rules="[v => v <= 100 || 'Values between 0 and 100 are accepted',Numeric2,required()]"
                                                class="rounded-0">
                                            </v-text-field>
                                        </div>
                                    </div>
                                </v-card>
                            </v-flex>
                        </div>
                        <!-- <v-divider></v-divider> -->
                        <!-- NEW FIELDS ADDED IPD-->
                        <v-layout class="mt-3" wrap style="align-items:flex-end">
                            <!-- <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Min Age of Member</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="IPDMinAgeMemb"
                                        class="rounded-0"
                                        :maxlength="max05"
                                        :rules="[required(),Numeric2]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Min Treatment Duration(No. of Days)</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="IPDMinTreatDura"
                                        class="rounded-0"
                                        :maxlength="max05"
                                        :rules="[required(),Numeric2]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex> -->
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">IRDA UIN Number</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="IPDIrdaUINNo"
                                        class="rounded-0"
                                        :maxlength="max20"
                                        :rules="spclCharNew">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">External Reference Number</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="IPDExtRefNo"
                                        class="rounded-0"
                                        :maxlength="max30"
                                        :rules="spclCharNew">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Conditions</p>
                                    <v-select
                                        :items="prodConditionArray"
                                        label="Select"
                                        v-model="IPDCondField"
                                        multiple
                                        solo
                                        class="rounded-0"
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">GC Coverage</p>
                                    <v-select
                                        :items="prodGCCoverArray"
                                        label="Select"
                                        v-model="IPDGcCover"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]">
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Remarks</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="IPDRemarks"
                                        class="rounded-0"
                                        :maxlength="max40"
                                        >
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Exclusions</p>
                                    <v-select
                                        :items="prodExcluArray"
                                        :maxlength="max30"
                                        label="Select"
                                        v-model="IPDExclus"
                                        solo
                                        class="rounded-0"
                                        multiple
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                        </v-layout>
                        <v-divider class="mt-4 mb-2"></v-divider>
                        <v-flex xs3 >
                            <v-layout column class="mx-2 cust_pln_cd" justify-center >
                                <v-text class="font-weight-bold cstm-clr-pln-cd" style="color:#FFFFFF" > </v-text>
                                <div style="background-color: #f7f7f7;border: 1px solid #c1c8cc;" class="pa-2">
                                    <v-checkbox class="ma-0 pa-0" v-model="IPDSelectBox" @change="IPDRelCheckSelected()" hide-details >
                                        <span slot="label" class="font-weight-bold text-truncate " style="font-size: 13px;"> Separate Relationship & Dependent Info</span>
                                    </v-checkbox>
                                </div>
                            </v-layout>
                        </v-flex>
                        <!-- <v-divider class="mb-4"></v-divider> -->
                        
                        <div v-if="showIPDRelSection == true">
                            <relationdependent 
                                ref="IPDRel" 
                                @save="saveIPDRel" 
                                :redpndntinfo="{
                                    cirelDataemty:IPDrelCoverData.realdata,
                                    ciRelnshipCoveredEmpty:IPDrelCoverData.dropdowndata,
                                    cisyncArryEmpty:IPDrelCoverData.syncarr,
                                    ciactiveonlyempty:IPDrelCoverData.ddselectedvalues
                                }" 
                                v-if="showIPDRelData == true">
                            </relationdependent>
                        </div>
                        <v-divider class="mt-3"></v-divider>

                        <v-layout style="" justify-end class="my-4">
                        <v-btn tile dark @click="clearIPD()" style="font-size:12px;background-color:#e46a25" class="text-capitalize px-3 mx-2" small><v-icon dark small>close</v-icon> <v-divider vertical class="mx-2"></v-divider> Clear</v-btn>
                        <v-btn tile @click="submitIPD()" dark style="font-size:12px;background-color:#23B1A9" class="text-capitalize px-3 mx-2" small><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider> Save</v-btn>
                            <v-btn @click="nxt(nextdrive[31])" tile  dark style="font-size:12px;background-color:#152F38" 
                            class="text-capitalize px-3 mx-2" small><v-icon dark small>arrow_forward</v-icon>
                            <v-divider vertical class="mx-2"></v-divider>Next</v-btn>
                        </v-layout>
                    </v-form>

                </div>
            </div>

            <!-- ORGAN DONOR COVERAGE -->
            <div class="panelis" v-if="showODAccor == true" dense>
                <div @click="currentis(nextdrive[32])" expand-icon="mdi-menu-down" class="font-weight-bold panhead" >
  
                    Organ Donor  <span> <v-icon  :style="{border: datais[nextdrive[32].shown == 'y' ? '5px red solid':'none' ] }"  >arrow_drop_down</v-icon> </span> 
                </div>
                <div  class="pa-2"    v-if="datais[nextdrive[32].shown]" >
                    <v-divider></v-divider>
                    <v-form v-model="ODvalid" ref="ODForm">
                        <v-layout class="mt-3" wrap style="align-items:flex-end">
                            <v-flex xs3 >
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Coverage Alternate Name</p>
                                    <v-text-field
                                        :rules="alphaRule"
                                        solo
                                        label="Enter..."
                                        v-model="ODCoverAltName"
                                        class="rounded-0"
                                        :maxlength="max200"
                                        >
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Coverage Type
                                    </p>
                                    <v-select
                                        :items="cvrgTypItems"
                                        label="Select"
                                        v-model="ODCoverageType"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]"
                                    >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Coverage Basis
                                    </p>
                                    <v-select
                                        :items="cvrgBasisItems"
                                        label="Select"
                                        v-model="ODCoverageBasis"
                                        @change="changeODCoverBasis(1)"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]"
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            
                            <v-flex xs3 v-if="showODsubLimBasis == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Sub Limit Basis
                                    </p>
                                    <v-select
                                        :items="IPHSbLmtBasisItems"
                                        label="Select"
                                        v-model="ODSubLimitBasis"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]"
                                        multiple
                                        @change="changeODSubLmtBasis(ODSubLimitBasis)"
                                        >
                                        <template v-slot:selection="{ item, index }">
                                            <span v-if="index === 0" >{{ item }}</span>
                                            <span v-if="index === 1" class="grey--text caption">(+{{ ODSubLimitBasis.length - 1 }})</span>
                                        </template>
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            
                            <v-flex xs3 v-if="showODPercentSi == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">% of SI</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="ODPercentSI"
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),numberRule,decimalRule()]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="showODFlatAmnt == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Flat Amount</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="ODFlatAmountTxtField"
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),alphaNumeric()]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="showODPrvPercentSi == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">% of previous yr SI</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="ODPrvPercentSI"
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),numberRule,decimalRule()]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="showODPercClaimAmt == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">% of Claim Amount</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="ODPercentClaimAmt"
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),numberRule,decimalRule()]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="showODOpdSi == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">% of OPD SI</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="ODOpdSi"
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),numberRule,decimalRule()]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="showODAdSi == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">% of AD SI</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="ODAdSi"
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),numberRule,decimalRule()]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="showODAppli == true">
                                <v-layout column class="cust_pln_cd mx-2">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Applicable Claim Pay-Out
                                    </p>
                                    <v-select
                                        :items="selectApplicableClaimPayOut"
                                        v-model="ODapplicableClaimPayOut"
                                        label="Select"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]"
                                    ></v-select>
                                </v-layout>
                            </v-flex>
                            
                            <v-flex xs3 v-if="showODsubLimBasis == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Claim Payment Basis
                                    </p>
                                    <v-select
                                        :items="ClmpymntBasisItems"
                                        label="Select"
                                        v-model="ODClaimPaymentBasis"
                                        solo
                                        @change="changeODClaimPaymentBasis(ODClaimPaymentBasis)"
                                        class="rounded-0"
                                        :rules="[required()]"
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            
                            <v-flex xs3 v-if="showODCovrageApplicDur == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Coverage applicable duration (No of years)
                                    </p>
                                    <v-select
                                        :items="hacCadItms"
                                        label="Select"
                                        v-model="ODCovrageApplicDur"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]"
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Waiting Period</p>
                                    <v-layout>
                                        <v-select
                                            :items="['Days','Months']"
                                            label="Select"
                                            v-model="ODWaitingPeriodDrop"
                                            solo
                                            style="width: 145px"
                                            class="rounded-0"
                                            :rules="[required()]"
                                            >
                                        </v-select>
                                        <v-text-field
                                            label="Enter..."
                                            v-model="ODWaitingPeriod"
                                            solo
                                            class="rounded-0"
                                            :maxlength="max10"
                                            :rules="[Numeric2]"
                                            >
                                        </v-text-field>
                                    </v-layout>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                       Minimum Hospitalization Duration for Eligible Claim(No. of hours)
                                    </p>
                                    <v-text-field
                                        label="Enter..."
                                        v-model="ODMinHospDura"
                                        solo
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[Numeric2]"
                                        >
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                       Co-pay applicable
                                    </p>
                                    <v-select
                                        :items="inPtntHzptnCoPayapplicableItems"
                                        label="Select"
                                        v-model="ODCoPayapplicable"
                                        @change="changeODCopyAplcbl()"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]"
                                    >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            
                             <v-flex xs3 v-if="showODcoPayType == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                       Co-Pay Type
                                    </p>
                                    <v-select
                                        :items="inPtntHzptnCoPayTypeItems"
                                        label="Select"
                                        v-model="ODCoPayType"
                                        @change="changeODCoPayPrcnt()"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]"
                                    >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="showODCoPayPrcnt == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                       Co-pay (%)
                                    </p>
                                    <v-text-field
                                        label="Enter..."
                                        v-model="ODCoPayPrcnt"
                                        solo
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[v => v <= 100 || 'Values between 0 and 100 are accepted',Numeric2,required()]"
                                        >
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            
                        </v-layout>
                        
                        <v-divider></v-divider>
                        <div style="display:flex" class="mx-2">
                            <v-flex xs6 v-if="showODCoPayPercRoom == true" style="flex-direction:column" class="mr-4">
                                <span class="font-weight-bold mt-3 cstm-clr-pln-cd">Co-Pay Grid</span>
                                <v-divider class="mb-4"></v-divider>
                                <v-card tile >
                                    <div style="" class="gridheader">
                                        <div class="font-weight-bold ml-2" style="flex:1; border-right:1px solid #C1C8CC; color:white; font-size: 13px !important;"><p class="my-2 pa-0 ma-0"> Room Type</p></div>
                                        <div class="font-weight-bold mx-2" style="flex:1;font-size: 13px !important;color:white"> <p class="my-2 pa-0 ma-0">Co-pay (%)</p></div>
                                    </div>
                                    <div style="display:flex;">
                                        <div class="font-weight-bold ml-2" style="flex:1; border-right:1px solid #C1C8CC; font-size: 13px !important;"><p class="my-4 pa-0 ma-0"> Shared Room</p></div>
                                        <div style="flex:1" class="mx-2 cust_pln_cd">
                                            <v-text-field 
                                                solo
                                                placeholder="Enter..."
                                                :maxlength="max10"
                                                v-model="ODCoPayPrcntSR"
                                                :rules="[v => v <= 100 || 'Values between 0 and 100 are accepted',Numeric2,required()]"
                                                class="rounded-0 ">
                                            </v-text-field>
                                        </div>
                                    </div>
                                    
                                    <v-divider></v-divider>
                                    <div style="display:flex">
                                        <div class="font-weight-bold ml-2" style="flex:1; border-right:1px solid #C1C8CC; font-size: 13px !important;"><p class="my-4 pa-0 ma-0">No Restriction</p></div>
                                        <div style="flex:1" class="mx-2 cust_pln_cd">
                                            <v-text-field 
                                                solo
                                                placeholder="Enter..."
                                                :maxlength="max10"
                                                v-model="ODCoPayPrcntNR"
                                                :rules="[v => v <= 100 || 'Values between 0 and 100 are accepted',Numeric2,required()]"
                                                class="rounded-0">
                                            </v-text-field>
                                        </div>
                                    </div>
                                </v-card>
                            </v-flex>
                        </div>
                        <!-- <v-divider></v-divider> -->
                        <!-- NEW FIELDS ADDED IPH-->
                        <v-layout class="mt-3" wrap style="align-items:flex-end">
                            <!-- <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Min Age of Member</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="ODMinAgeMemb"
                                        class="rounded-0"
                                        :maxlength="max05"
                                        :rules="[required(),Numeric2]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Min Treatment Duration(No. of Days)</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="ODMinTreatDura"
                                        class="rounded-0"
                                        :maxlength="max05"
                                        :rules="[required(),Numeric2]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex> -->
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">IRDA UIN Number</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="ODIrdaUINNo"
                                        class="rounded-0"
                                        :maxlength="max20"
                                        :rules="spclCharNew">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">External Reference Number</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="ODExtRefNo"
                                        class="rounded-0"
                                        :maxlength="max30"
                                        :rules="spclCharNew">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Conditions</p>
                                    <v-select
                                        :items="prodConditionArray"
                                        label="Select"
                                        v-model="ODCondField"
                                        multiple
                                        solo
                                        class="rounded-0"
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">GC Coverage</p>
                                    <v-select
                                        :items="prodGCCoverArray"
                                        label="Select"
                                        v-model="ODGcCover"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]">
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Remarks</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="ODRemarks"
                                        class="rounded-0"
                                        :maxlength="max40"
                                        >
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Exclusions</p>
                                    <v-select
                                        :items="prodExcluArray"
                                        :maxlength="max30"
                                        label="Select"
                                        v-model="ODExclus"
                                        solo
                                        class="rounded-0"
                                        multiple
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                        </v-layout>
                        <v-divider class="mt-4 mb-2"></v-divider>
                        <v-flex xs3 >
                            <v-layout column class="mx-2 cust_pln_cd" justify-center >
                                <v-text class="font-weight-bold cstm-clr-pln-cd" style="color:#FFFFFF" > </v-text>
                                <div style="background-color: #f7f7f7;border: 1px solid #c1c8cc;" class="pa-2">
                                    <v-checkbox class="ma-0 pa-0" v-model="ODSelectBox" @change="ODRelCheckSelected()" hide-details >
                                        <span slot="label" class="font-weight-bold text-truncate " style="font-size: 13px;"> Separate Relationship & Dependent Info</span>
                                    </v-checkbox>
                                </div>
                            </v-layout>
                        </v-flex>
                        <!-- <v-divider class="mb-4"></v-divider> -->
                        
                        <div v-if="showODRelSection == true">
                            <relationdependent 
                                ref="ODRel" 
                                @save="saveODRel" 
                                :redpndntinfo="{
                                    cirelDataemty:ODrelCoverData.realdata,
                                    ciRelnshipCoveredEmpty:ODrelCoverData.dropdowndata,
                                    cisyncArryEmpty:ODrelCoverData.syncarr,
                                    ciactiveonlyempty:ODrelCoverData.ddselectedvalues
                                }" 
                                v-if="showODRelData == true">
                            </relationdependent>
                        </div>
                        <v-divider class="mt-3"></v-divider>

                        <v-layout style="" justify-end class="my-4">
                        <v-btn tile dark @click="clearOD()" style="font-size:12px;background-color:#e46a25" class="text-capitalize px-3 mx-2" small><v-icon dark small>close</v-icon> <v-divider vertical class="mx-2"></v-divider> Clear</v-btn>
                        <v-btn tile @click="submitOD()" dark style="font-size:12px;background-color:#23B1A9" class="text-capitalize px-3 mx-2" small><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider> Save</v-btn>
                            <v-btn @click="nxt(nextdrive[32])" tile  dark style="font-size:12px;background-color:#152F38" 
                            class="text-capitalize px-3 mx-2" small><v-icon dark small>arrow_forward</v-icon>
                            <v-divider vertical class="mx-2"></v-divider>Next</v-btn>
                        </v-layout>
                    </v-form>

                </div>
            </div>

            <!-- BARIATRIC SURGERY COVER -->
            <div class="panelis" v-if="showBSCAccor == true" dense>
                <div @click="currentis(nextdrive[33])" expand-icon="mdi-menu-down" class="font-weight-bold panhead" >
  
                    Bariatric Surgery Cover  <span> <v-icon  :style="{border: datais[nextdrive[33].shown == 'y' ? '5px red solid':'none' ] }"  >arrow_drop_down</v-icon> </span> 
                </div>
                <div  class="pa-2"    v-if="datais[nextdrive[33].shown]" >
                    <v-divider></v-divider>
                    <v-form v-model="BSCvalid" ref="BSCForm">
                        <v-layout class="mt-3" wrap style="align-items:flex-end">
                            <v-flex xs3 >
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Coverage Alternate Name</p>
                                    <v-text-field
                                        :rules="alphaRule"
                                        solo
                                        label="Enter..."
                                        v-model="BSCCoverAltName"
                                        class="rounded-0"
                                        :maxlength="max200"
                                        >
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Coverage Type
                                    </p>
                                    <v-select
                                        :items="cvrgTypItems"
                                        label="Select"
                                        v-model="BSCCoverageType"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]"
                                    >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Coverage Basis
                                    </p>
                                    <v-select
                                        :items="cvrgBasisItems"
                                        label="Select"
                                        v-model="BSCCoverageBasis"
                                        @change="changeBSCCoverBasis(1)"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]"
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            
                            <v-flex xs3 v-if="showBSCsubLimBasis == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Sub Limit Basis
                                    </p>
                                    <v-select
                                        :items="IPHSbLmtBasisItems"
                                        label="Select"
                                        v-model="BSCSubLimitBasis"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]"
                                        multiple
                                        @change="changeBSCSubLmtBasis(BSCSubLimitBasis)"
                                        >
                                        <template v-slot:selection="{ item, index }">
                                            <span v-if="index === 0" >{{ item }}</span>
                                            <span v-if="index === 1" class="grey--text caption">(+{{ BSCSubLimitBasis.length - 1 }})</span>
                                        </template>
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            
                            <v-flex xs3 v-if="showBSCPercentSi == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">% of SI</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="BSCPercentSI"
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),numberRule,decimalRule()]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="showBSCFlatAmnt == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Flat Amount</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="BSCFlatAmountTxtField"
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),alphaNumeric()]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="showBSCPrvPercentSi == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">% of previous yr SI</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="BSCPrvPercentSI"
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),numberRule,decimalRule()]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="showBSCPercClaimAmt == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">% of Claim Amount</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="BSCPercentClaimAmt"
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),numberRule,decimalRule()]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="showBSCOpdSi == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">% of OPD SI</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="BSCOpdSi"
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),numberRule,decimalRule()]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="showBSCAdSi == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">% of AD SI</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="BSCAdSi"
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),numberRule,decimalRule()]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="showBSCAppli == true">
                                <v-layout column class="cust_pln_cd mx-2">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Applicable Claim Pay-Out
                                    </p>
                                    <v-select
                                        :items="selectApplicableClaimPayOut"
                                        v-model="BSCapplicableClaimPayOut"
                                        label="Select"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]"
                                    ></v-select>
                                </v-layout>
                            </v-flex>
                            
                            <v-flex xs3 v-if="showBSCsubLimBasis == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Claim Payment Basis
                                    </p>
                                    <v-select
                                        :items="ClmpymntBasisItems"
                                        label="Select"
                                        v-model="BSCClaimPaymentBasis"
                                        solo
                                        @change="changeBSCClaimPaymentBasis(BSCClaimPaymentBasis)"
                                        class="rounded-0"
                                        :rules="[required()]"
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            
                            <v-flex xs3 v-if="showBSCCovrageApplicDur == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Coverage applicable duration (No of years)
                                    </p>
                                    <v-select
                                        :items="hacCadItms"
                                        label="Select"
                                        v-model="BSCCovrageApplicDur"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]"
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Waiting Period</p>
                                    <v-layout>
                                        <v-select
                                            :items="['Days','Months']"
                                            label="Select"
                                            v-model="BSCWaitingPeriodDrop"
                                            solo
                                            style="width: 145px"
                                            class="rounded-0"
                                            :rules="[required()]"
                                            >
                                        </v-select>
                                        <v-text-field
                                            label="Enter..."
                                            v-model="BSCWaitingPeriod"
                                            solo
                                            class="rounded-0"
                                            :maxlength="max10"
                                            :rules="[Numeric2]"
                                            >
                                        </v-text-field>
                                    </v-layout>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                       Minimum Hospitalization Duration for Eligible Claim(No. of hours)
                                    </p>
                                    <v-text-field
                                        label="Enter..."
                                        v-model="BSCMinHospDura"
                                        solo
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[Numeric2]"
                                        >
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                       Co-pay applicable
                                    </p>
                                    <v-select
                                        :items="inPtntHzptnCoPayapplicableItems"
                                        label="Select"
                                        v-model="BSCCoPayapplicable"
                                        @change="changeBSCCopyAplcbl()"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]"
                                    >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            
                             <v-flex xs3 v-if="showBSCcoPayType == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                       Co-Pay Type
                                    </p>
                                    <v-select
                                        :items="inPtntHzptnCoPayTypeItems"
                                        label="Select"
                                        v-model="BSCCoPayType"
                                        @change="changeBSCCoPayPrcnt()"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]"
                                    >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="showBSCCoPayPrcnt == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                       Co-pay (%)
                                    </p>
                                    <v-text-field
                                        label="Enter..."
                                        v-model="BSCCoPayPrcnt"
                                        solo
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[v => v <= 100 || 'Values between 0 and 100 are accepted',Numeric2,required()]"
                                        >
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            
                        </v-layout>
                        
                        <v-divider></v-divider>
                        <div style="display:flex" class="mx-2">
                            <v-flex xs6 v-if="showBSCCoPayPercRoom == true" style="flex-direction:column" class="mr-4">
                                <span class="font-weight-bold mt-3 cstm-clr-pln-cd">Co-Pay Grid</span>
                                <v-divider class="mb-4"></v-divider>
                                <v-card tile >
                                    <div style="" class="gridheader">
                                        <div class="font-weight-bold ml-2" style="flex:1; border-right:1px solid #C1C8CC; color:white; font-size: 13px !important;"><p class="my-2 pa-0 ma-0"> Room Type</p></div>
                                        <div class="font-weight-bold mx-2" style="flex:1;font-size: 13px !important;color:white"> <p class="my-2 pa-0 ma-0">Co-pay (%)</p></div>
                                    </div>
                                    <div style="display:flex;">
                                        <div class="font-weight-bold ml-2" style="flex:1; border-right:1px solid #C1C8CC; font-size: 13px !important;"><p class="my-4 pa-0 ma-0"> Shared Room</p></div>
                                        <div style="flex:1" class="mx-2 cust_pln_cd">
                                            <v-text-field 
                                                solo
                                                placeholder="Enter..."
                                                :maxlength="max10"
                                                v-model="BSCCoPayPrcntSR"
                                                :rules="[v => v <= 100 || 'Values between 0 and 100 are accepted',Numeric2,required()]"
                                                class="rounded-0 ">
                                            </v-text-field>
                                        </div>
                                    </div>
                                    
                                    <v-divider></v-divider>
                                    <div style="display:flex">
                                        <div class="font-weight-bold ml-2" style="flex:1; border-right:1px solid #C1C8CC; font-size: 13px !important;"><p class="my-4 pa-0 ma-0">No Restriction</p></div>
                                        <div style="flex:1" class="mx-2 cust_pln_cd">
                                            <v-text-field 
                                                solo
                                                placeholder="Enter..."
                                                :maxlength="max10"
                                                v-model="BSCCoPayPrcntNR"
                                                :rules="[v => v <= 100 || 'Values between 0 and 100 are accepted',Numeric2,required()]"
                                                class="rounded-0">
                                            </v-text-field>
                                        </div>
                                    </div>
                                </v-card>
                            </v-flex>
                        </div>
                        <!-- <v-divider></v-divider> -->
                        <!-- NEW FIELDS ADDED IPH-->
                        <v-layout class="mt-3" wrap style="align-items:flex-end">
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Min Age of Member</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="BSCMinAgeMemb"
                                        class="rounded-0"
                                        :maxlength="max05"
                                        :rules="[required(),Numeric2]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <!-- <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Min Treatment Duration(No. of Days)</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="BSCMinTreatDura"
                                        class="rounded-0"
                                        :maxlength="max05"
                                        :rules="[required(),Numeric2]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex> -->
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">IRDA UIN Number</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="BSCIrdaUINNo"
                                        class="rounded-0"
                                        :maxlength="max20"
                                        :rules="spclCharNew">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">External Reference Number</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="BSCExtRefNo"
                                        class="rounded-0"
                                        :maxlength="max30"
                                        :rules="spclCharNew">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Conditions</p>
                                    <v-select
                                        :items="prodConditionArray"
                                        label="Select"
                                        v-model="BSCCondField"
                                        multiple
                                        solo
                                        class="rounded-0"
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">GC Coverage</p>
                                    <v-select
                                        :items="prodGCCoverArray"
                                        label="Select"
                                        v-model="BSCGcCover"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]">
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Remarks</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="BSCRemarks"
                                        class="rounded-0"
                                        :maxlength="max40"
                                        >
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Exclusions</p>
                                    <v-select
                                        :items="prodExcluArray"
                                        :maxlength="max30"
                                        label="Select"
                                        v-model="BSCExclus"
                                        solo
                                        class="rounded-0"
                                        multiple
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                        </v-layout>
                        <v-divider class="mt-4 mb-2"></v-divider>
                        <v-flex xs3 >
                            <v-layout column class="mx-2 cust_pln_cd" justify-center >
                                <v-text class="font-weight-bold cstm-clr-pln-cd" style="color:#FFFFFF" > </v-text>
                                <div style="background-color: #f7f7f7;border: 1px solid #c1c8cc;" class="pa-2">
                                    <v-checkbox class="ma-0 pa-0" v-model="BSCSelectBox" @change="BSCRelCheckSelected()" hide-details >
                                        <span slot="label" class="font-weight-bold text-truncate " style="font-size: 13px;"> Separate Relationship & Dependent Info</span>
                                    </v-checkbox>
                                </div>
                            </v-layout>
                        </v-flex>
                        <div v-if="showBSCRelSection == true">
                            <relationdependent 
                                ref="BSCRel" 
                                @save="saveBSCRel" 
                                :redpndntinfo="{
                                    cirelDataemty:BSCrelCoverData.realdata,
                                    ciRelnshipCoveredEmpty:BSCrelCoverData.dropdowndata,
                                    cisyncArryEmpty:BSCrelCoverData.syncarr,
                                    ciactiveonlyempty:BSCrelCoverData.ddselectedvalues
                                }" 
                                v-if="showBSCRelData == true">
                            </relationdependent>
                        </div>
                        <v-divider class="mt-3"></v-divider>

                        <v-layout style="" justify-end class="my-4">
                        <v-btn tile dark @click="clearBSC()" style="font-size:12px;background-color:#e46a25" class="text-capitalize px-3 mx-2" small><v-icon dark small>close</v-icon> <v-divider vertical class="mx-2"></v-divider> Clear</v-btn>
                        <v-btn tile @click="submitBSC()" dark style="font-size:12px;background-color:#23B1A9" class="text-capitalize px-3 mx-2" small><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider> Save</v-btn>
                            <v-btn @click="nxt(nextdrive[33])" tile  dark style="font-size:12px;background-color:#152F38" 
                            class="text-capitalize px-3 mx-2" small><v-icon dark small>arrow_forward</v-icon>
                            <v-divider vertical class="mx-2"></v-divider>Next</v-btn>
                        </v-layout>
                    </v-form>

                </div>
            </div>

            <!-- DOMICILIARY TREATMENT -->
            <div class="panelis" v-if="showDTAccor == true" dense>
                <div @click="currentis(nextdrive[34])" expand-icon="mdi-menu-down" class="font-weight-bold panhead" >
  
                    Domiciliary Treatment  <span> <v-icon  :style="{border: datais[nextdrive[34].shown == 'y' ? '5px red solid':'none' ] }"  >arrow_drop_down</v-icon> </span> 
                </div>
                <div  class="pa-2"    v-if="datais[nextdrive[34].shown]" >
                    <v-divider></v-divider>
                    <v-form v-model="DTvalid" ref="DTForm">
                        <v-layout class="mt-3" wrap style="align-items:flex-end">
                            <v-flex xs3 >
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Coverage Alternate Name</p>
                                    <v-text-field
                                        :rules="alphaRule"
                                        solo
                                        label="Enter..."
                                        v-model="DTCoverAltName"
                                        class="rounded-0"
                                        :maxlength="max200"
                                        >
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Coverage Type
                                    </p>
                                    <v-select
                                        :items="cvrgTypItems"
                                        label="Select"
                                        v-model="DTCoverageType"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]"
                                    >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Coverage Basis
                                    </p>
                                    <v-select
                                        :items="cvrgBasisItems"
                                        label="Select"
                                        v-model="DTCoverageBasis"
                                        @change="changeDTCoverBasis(1)"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]"
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            
                            <v-flex xs3 v-if="showDTsubLimBasis == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Sub Limit Basis
                                    </p>
                                    <v-select
                                        :items="IPHSbLmtBasisItems"
                                        label="Select"
                                        v-model="DTSubLimitBasis"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]"
                                        multiple
                                        @change="changeDTSubLmtBasis(DTSubLimitBasis)"
                                        >
                                        <template v-slot:selection="{ item, index }">
                                            <span v-if="index === 0" >{{ item }}</span>
                                            <span v-if="index === 1" class="grey--text caption">(+{{ DTSubLimitBasis.length - 1 }})</span>
                                        </template>
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            
                            <v-flex xs3 v-if="showDTPercentSi == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">% of SI</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="DTPercentSI"
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),numberRule,decimalRule()]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="showDTFlatAmnt == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Flat Amount</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="DTFlatAmountTxtField"
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),alphaNumeric()]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="showDTPrvPercentSi == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">% of previous yr SI</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="DTPrvPercentSI"
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),numberRule,decimalRule()]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="showDTPercClaimAmt == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">% of Claim Amount</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="DTPercentClaimAmt"
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),numberRule,decimalRule()]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="showDTOpdSi == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">% of OPD SI</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="DTOpdSi"
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),numberRule,decimalRule()]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="showDTAdSi == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">% of AD SI</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="DTAdSi"
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),numberRule,decimalRule()]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="showDTAppli == true">
                                <v-layout column class="cust_pln_cd mx-2">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Applicable Claim Pay-Out
                                    </p>
                                    <v-select
                                        :items="selectApplicableClaimPayOut"
                                        v-model="DTapplicableClaimPayOut"
                                        label="Select"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]"
                                    ></v-select>
                                </v-layout>
                            </v-flex>
                            
                            <v-flex xs3 v-if="showDTsubLimBasis == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Claim Payment Basis
                                    </p>
                                    <v-select
                                        :items="ClmpymntBasisItems"
                                        label="Select"
                                        v-model="DTClaimPaymentBasis"
                                        solo
                                        @change="changeDTClaimPaymentBasis(DTClaimPaymentBasis)"
                                        class="rounded-0"
                                        :rules="[required()]"
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            
                            <v-flex xs3 v-if="showDTCovrageApplicDur == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Coverage applicable duration (No of years)
                                    </p>
                                    <v-select
                                        :items="hacCadItms"
                                        label="Select"
                                        v-model="DTCovrageApplicDur"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]"
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Waiting Period</p>
                                    <v-layout>
                                        <v-select
                                            :items="['Days','Months']"
                                            label="Select"
                                            v-model="DTWaitingPeriodDrop"
                                            solo
                                            style="width: 145px"
                                            class="rounded-0"
                                            :rules="[required()]"
                                            >
                                        </v-select>
                                        <v-text-field
                                            label="Enter..."
                                            v-model="DTWaitingPeriod"
                                            solo
                                            class="rounded-0"
                                            :maxlength="max10"
                                            :rules="[Numeric2]"
                                            >
                                        </v-text-field>
                                    </v-layout>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                       Minimum Hospitalization Duration for Eligible Claim(No. of hours)
                                    </p>
                                    <v-text-field
                                        label="Enter..."
                                        v-model="DTMinHospDura"
                                        solo
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[Numeric2]"
                                        >
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                       Co-pay applicable
                                    </p>
                                    <v-select
                                        :items="inPtntHzptnCoPayapplicableItems"
                                        label="Select"
                                        v-model="DTCoPayapplicable"
                                        @change="changeDTCopyAplcbl()"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]"
                                    >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            
                             <v-flex xs3 v-if="showDTcoPayType == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                       Co-Pay Type
                                    </p>
                                    <v-select
                                        :items="inPtntHzptnCoPayTypeItems"
                                        label="Select"
                                        v-model="DTCoPayType"
                                        @change="changeDTCoPayPrcnt()"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]"
                                    >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="showDTCoPayPrcnt == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                       Co-pay (%)
                                    </p>
                                    <v-text-field
                                        label="Enter..."
                                        v-model="DTCoPayPrcnt"
                                        solo
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[v => v <= 100 || 'Values between 0 and 100 are accepted',Numeric2,required()]"
                                        >
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            
                        </v-layout>
                        
                        <v-divider></v-divider>
                        <div style="display:flex" class="mx-2">
                            <v-flex xs6 v-if="showDTCoPayPercRoom == true" style="flex-direction:column" class="mr-4">
                                <span class="font-weight-bold mt-3 cstm-clr-pln-cd">Co-Pay Grid</span>
                                <v-divider class="mb-4"></v-divider>
                                <v-card tile >
                                    <div style="" class="gridheader">
                                        <div class="font-weight-bold ml-2" style="flex:1; border-right:1px solid #C1C8CC; color:white; font-size: 13px !important;"><p class="my-2 pa-0 ma-0"> Room Type</p></div>
                                        <div class="font-weight-bold mx-2" style="flex:1;font-size: 13px !important;color:white"> <p class="my-2 pa-0 ma-0">Co-pay (%)</p></div>
                                    </div>
                                    <div style="display:flex;">
                                        <div class="font-weight-bold ml-2" style="flex:1; border-right:1px solid #C1C8CC; font-size: 13px !important;"><p class="my-4 pa-0 ma-0"> Shared Room</p></div>
                                        <div style="flex:1" class="mx-2 cust_pln_cd">
                                            <v-text-field 
                                                solo
                                                placeholder="Enter..."
                                                :maxlength="max10"
                                                v-model="DTCoPayPrcntSR"
                                                :rules="[v => v <= 100 || 'Values between 0 and 100 are accepted',Numeric2,required()]"
                                                class="rounded-0 ">
                                            </v-text-field>
                                        </div>
                                    </div>
                                    
                                    <v-divider></v-divider>
                                    <div style="display:flex">
                                        <div class="font-weight-bold ml-2" style="flex:1; border-right:1px solid #C1C8CC; font-size: 13px !important;"><p class="my-4 pa-0 ma-0">No Restriction</p></div>
                                        <div style="flex:1" class="mx-2 cust_pln_cd">
                                            <v-text-field 
                                                solo
                                                placeholder="Enter..."
                                                :maxlength="max10"
                                                v-model="DTCoPayPrcntNR"
                                                :rules="[v => v <= 100 || 'Values between 0 and 100 are accepted',Numeric2,required()]"
                                                class="rounded-0">
                                            </v-text-field>
                                        </div>
                                    </div>
                                </v-card>
                            </v-flex>
                        </div>
                        <!-- <v-divider></v-divider> -->
                        <!-- NEW FIELDS ADDED IPH-->
                        <v-layout class="mt-3" wrap style="align-items:flex-end">
                            <!-- <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Min Age of Member</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="DTMinAgeMemb"
                                        class="rounded-0"
                                        :maxlength="max05"
                                        :rules="[required(),Numeric2]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex> -->
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Min Treatment Duration(No. of Days)</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="DTMinTreatDura"
                                        class="rounded-0"
                                        :maxlength="max05"
                                        :rules="[required(),Numeric2]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">IRDA UIN Number</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="DTIrdaUINNo"
                                        class="rounded-0"
                                        :maxlength="max20"
                                        :rules="spclCharNew">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">External Reference Number</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="DTExtRefNo"
                                        class="rounded-0"
                                        :maxlength="max30"
                                        :rules="spclCharNew">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Conditions</p>
                                    <v-select
                                        :items="prodConditionArray"
                                        label="Select"
                                        v-model="DTCondField"
                                        multiple
                                        solo
                                        class="rounded-0"
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">GC Coverage</p>
                                    <v-select
                                        :items="prodGCCoverArray"
                                        label="Select"
                                        v-model="DTGcCover"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]">
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Remarks</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="DTRemarks"
                                        class="rounded-0"
                                        :maxlength="max40"
                                        >
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Exclusions</p>
                                    <v-select
                                        :items="prodExcluArray"
                                        :maxlength="max30"
                                        label="Select"
                                        v-model="DTExclus"
                                        solo
                                        class="rounded-0"
                                        multiple
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                        </v-layout>
                        <v-divider class="mt-4 mb-2"></v-divider>
                        <v-flex xs3 >
                            <v-layout column class="mx-2 cust_pln_cd" justify-center >
                                <v-text class="font-weight-bold cstm-clr-pln-cd" style="color:#FFFFFF" > </v-text>
                                <div style="background-color: #f7f7f7;border: 1px solid #c1c8cc;" class="pa-2">
                                    <v-checkbox class="ma-0 pa-0" v-model="DTSelectBox" @change="DTRelCheckSelected()" hide-details >
                                        <span slot="label" class="font-weight-bold text-truncate " style="font-size: 13px;"> Separate Relationship & Dependent Info</span>
                                    </v-checkbox>
                                </div>
                            </v-layout>
                        </v-flex>
                        <div v-if="showDTRelSection == true">
                            <relationdependent 
                                ref="DTRel" 
                                @save="saveDTRel" 
                                :redpndntinfo="{
                                    cirelDataemty:DTrelCoverData.realdata,
                                    ciRelnshipCoveredEmpty:DTrelCoverData.dropdowndata,
                                    cisyncArryEmpty:DTrelCoverData.syncarr,
                                    ciactiveonlyempty:DTrelCoverData.ddselectedvalues
                                }" 
                                v-if="showDTRelData == true">
                            </relationdependent>
                        </div>
                        <v-divider class="mt-3"></v-divider>
                        <v-layout style="" justify-end class="my-4">
                        <v-btn tile dark @click="clearDT()" style="font-size:12px;background-color:#e46a25" class="text-capitalize px-3 mx-2" small><v-icon dark small>close</v-icon> <v-divider vertical class="mx-2"></v-divider> Clear</v-btn>
                        <v-btn tile @click="submitDT()" dark style="font-size:12px;background-color:#23B1A9" class="text-capitalize px-3 mx-2" small><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider> Save</v-btn>
                            <v-btn @click="nxt(nextdrive[34])" tile  dark style="font-size:12px;background-color:#152F38" 
                            class="text-capitalize px-3 mx-2" small><v-icon dark small>arrow_forward</v-icon>
                            <v-divider vertical class="mx-2"></v-divider>Next</v-btn>
                        </v-layout>
                    </v-form>

                </div>
            </div>

            <!-- AYUSH BENEFIT -->
            <div class="panelis" v-if="showABAccor == true" dense>
                <div @click="currentis(nextdrive[35])" expand-icon="mdi-menu-down" class="font-weight-bold panhead" >
  
                    Ayush Benefit  <span> <v-icon  :style="{border: datais[nextdrive[35].shown == 'y' ? '5px red solid':'none' ] }"  >arrow_drop_down</v-icon> </span> 
                </div>
                <div  class="pa-2"    v-if="datais[nextdrive[35].shown]" >
                    <v-divider></v-divider>
                    <v-form v-model="ABvalid" ref="ABForm">
                        <v-layout class="mt-3" wrap style="align-items:flex-end">
                            <v-flex xs3 >
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Coverage Alternate Name</p>
                                    <v-text-field
                                        :rules="alphaRule"
                                        solo
                                        label="Enter..."
                                        v-model="ABCoverAltName"
                                        class="rounded-0"
                                        :maxlength="max200"
                                        >
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Coverage Type
                                    </p>
                                    <v-select
                                        :items="cvrgTypItems"
                                        label="Select"
                                        v-model="ABCoverageType"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]"
                                    >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Coverage Basis
                                    </p>
                                    <v-select
                                        :items="cvrgBasisItems"
                                        label="Select"
                                        v-model="ABCoverageBasis"
                                        @change="changeABCoverBasis(1)"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]"
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            
                            <v-flex xs3 v-if="showABsubLimBasis == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Sub Limit Basis
                                    </p>
                                    <v-select
                                        :items="IPHSbLmtBasisItems"
                                        label="Select"
                                        v-model="ABSubLimitBasis"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]"
                                        multiple
                                        @change="changeABSubLmtBasis(ABSubLimitBasis)"
                                        >
                                        <template v-slot:selection="{ item, index }">
                                            <span v-if="index === 0" >{{ item }}</span>
                                            <span v-if="index === 1" class="grey--text caption">(+{{ ABSubLimitBasis.length - 1 }})</span>
                                        </template>
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            
                            <v-flex xs3 v-if="showABPercentSi == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">% of SI</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="ABPercentSI"
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),numberRule,decimalRule()]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="showABFlatAmnt == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Flat Amount</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="ABFlatAmountTxtField"
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),alphaNumeric()]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="showABPrvPercentSi == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">% of previous yr SI</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="ABPrvPercentSI"
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),numberRule,decimalRule()]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="showABPercClaimAmt == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">% of Claim Amount</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="ABPercentClaimAmt"
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),numberRule,decimalRule()]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="showABOpdSi == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">% of OPD SI</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="ABOpdSi"
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),numberRule,decimalRule()]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="showABAdSi == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">% of AD SI</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="ABAdSi"
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[required(),numberRule,decimalRule()]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="showABAppli == true">
                                <v-layout column class="cust_pln_cd mx-2">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Applicable Claim Pay-Out
                                    </p>
                                    <v-select
                                        :items="selectApplicableClaimPayOut"
                                        v-model="ABapplicableClaimPayOut"
                                        label="Select"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]"
                                    ></v-select>
                                </v-layout>
                            </v-flex>
                            
                            <v-flex xs3 v-if="showABsubLimBasis == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Claim Payment Basis
                                    </p>
                                    <v-select
                                        :items="ClmpymntBasisItems"
                                        label="Select"
                                        v-model="ABClaimPaymentBasis"
                                        solo
                                        @change="changeABClaimPaymentBasis(ABClaimPaymentBasis)"
                                        class="rounded-0"
                                        :rules="[required()]"
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            
                            <v-flex xs3 v-if="showABCovrageApplicDur == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                        Coverage applicable duration (No of years)
                                    </p>
                                    <v-select
                                        :items="hacCadItms"
                                        label="Select"
                                        v-model="ABCovrageApplicDur"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]"
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Waiting Period</p>
                                    <v-layout>
                                        <v-select
                                            :items="['Days','Months']"
                                            label="Select"
                                            v-model="ABWaitingPeriodDrop"
                                            solo
                                            style="width: 145px"
                                            class="rounded-0"
                                            :rules="[required()]"
                                            >
                                        </v-select>
                                        <v-text-field
                                            label="Enter..."
                                            v-model="ABWaitingPeriod"
                                            solo
                                            class="rounded-0"
                                            :maxlength="max10"
                                            :rules="[Numeric2]"
                                            >
                                        </v-text-field>
                                    </v-layout>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                       Minimum Hospitalization Duration for Eligible Claim(No. of hours)
                                    </p>
                                    <v-text-field
                                        label="Enter..."
                                        v-model="ABMinHospDura"
                                        solo
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[Numeric2]"
                                        >
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                       Co-pay applicable
                                    </p>
                                    <v-select
                                        :items="inPtntHzptnCoPayapplicableItems"
                                        label="Select"
                                        v-model="ABCoPayapplicable"
                                        @change="changeABCopyAplcbl()"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]"
                                    >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            
                             <v-flex xs3 v-if="showABcoPayType == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                       Co-Pay Type
                                    </p>
                                    <v-select
                                        :items="inPtntHzptnCoPayTypeItems"
                                        label="Select"
                                        v-model="ABCoPayType"
                                        @change="changeABCoPayPrcnt()"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]"
                                    >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3 v-if="showABCoPayPrcnt == true">
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">
                                       Co-pay (%)
                                    </p>
                                    <v-text-field
                                        label="Enter..."
                                        v-model="ABCoPayPrcnt"
                                        solo
                                        class="rounded-0"
                                        :maxlength="max10"
                                        :rules="[v => v <= 100 || 'Values between 0 and 100 are accepted',Numeric2,required()]"
                                        >
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            
                        </v-layout>
                        
                        <v-divider></v-divider>
                        <div style="display:flex" class="mx-2">
                            <v-flex xs6 v-if="showABCoPayPercRoom == true" style="flex-direction:column" class="mr-4">
                                <span class="font-weight-bold mt-3 cstm-clr-pln-cd">Co-Pay Grid</span>
                                <v-divider class="mb-4"></v-divider>
                                <v-card tile >
                                    <div style="" class="gridheader">
                                        <div class="font-weight-bold ml-2" style="flex:1; border-right:1px solid #C1C8CC; color:white; font-size: 13px !important;"><p class="my-2 pa-0 ma-0"> Room Type</p></div>
                                        <div class="font-weight-bold mx-2" style="flex:1;font-size: 13px !important;color:white"> <p class="my-2 pa-0 ma-0">Co-pay (%)</p></div>
                                    </div>
                                    <div style="display:flex;">
                                        <div class="font-weight-bold ml-2" style="flex:1; border-right:1px solid #C1C8CC; font-size: 13px !important;"><p class="my-4 pa-0 ma-0"> Shared Room</p></div>
                                        <div style="flex:1" class="mx-2 cust_pln_cd">
                                            <v-text-field 
                                                solo
                                                placeholder="Enter..."
                                                :maxlength="max10"
                                                v-model="ABCoPayPrcntSR"
                                                :rules="[v => v <= 100 || 'Values between 0 and 100 are accepted',Numeric2,required()]"
                                                class="rounded-0 ">
                                            </v-text-field>
                                        </div>
                                    </div>
                                    
                                    <v-divider></v-divider>
                                    <div style="display:flex">
                                        <div class="font-weight-bold ml-2" style="flex:1; border-right:1px solid #C1C8CC; font-size: 13px !important;"><p class="my-4 pa-0 ma-0">No Restriction</p></div>
                                        <div style="flex:1" class="mx-2 cust_pln_cd">
                                            <v-text-field 
                                                solo
                                                placeholder="Enter..."
                                                :maxlength="max10"
                                                v-model="ABCoPayPrcntNR"
                                                :rules="[v => v <= 100 || 'Values between 0 and 100 are accepted',Numeric2,required()]"
                                                class="rounded-0">
                                            </v-text-field>
                                        </div>
                                    </div>
                                </v-card>
                            </v-flex>
                        </div>
                        <!-- <v-divider></v-divider> -->
                        <!-- NEW FIELDS ADDED AB-->
                        <v-layout class="mt-3" wrap style="align-items:flex-end">
                            <!-- <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Min Age of Member</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="ABMinAgeMemb"
                                        class="rounded-0"
                                        :maxlength="max05"
                                        :rules="[required(),Numeric2]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Min Treatment Duration(No. of Days)</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="ABMinTreatDura"
                                        class="rounded-0"
                                        :maxlength="max05"
                                        :rules="[required(),Numeric2]">
                                    </v-text-field>
                                </v-layout>
                            </v-flex> -->
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">IRDA UIN Number</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="ABIrdaUINNo"
                                        class="rounded-0"
                                        :maxlength="max20"
                                        :rules="spclCharNew">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">External Reference Number</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="ABExtRefNo"
                                        class="rounded-0"
                                        :maxlength="max30"
                                        :rules="spclCharNew">
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Conditions</p>
                                    <v-select
                                        :items="prodConditionArray"
                                        label="Select"
                                        v-model="ABCondField"
                                        multiple
                                        solo
                                        class="rounded-0"
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">GC Coverage</p>
                                    <v-select
                                        :items="prodGCCoverArray"
                                        label="Select"
                                        v-model="ABGcCover"
                                        solo
                                        class="rounded-0"
                                        :rules="[required()]">
                                    </v-select>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Remarks</p>
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="ABRemarks"
                                        class="rounded-0"
                                        :maxlength="max40"
                                        >
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <v-layout column class="cust_pln_cd mx-2 ">
                                    <p class="font-weight-bold cstm-clr-pln-cd">Exclusions</p>
                                    <v-select
                                        :items="prodExcluArray"
                                        :maxlength="max30"
                                        label="Select"
                                        v-model="ABExclus"
                                        solo
                                        class="rounded-0"
                                        multiple
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex>
                        </v-layout>
                        <v-divider class="mt-4 mb-2"></v-divider>
                        <v-flex xs3 >
                            <v-layout column class="mx-2 cust_pln_cd" justify-center >
                                <v-text class="font-weight-bold cstm-clr-pln-cd" style="color:#FFFFFF" > </v-text>
                                <div style="background-color: #f7f7f7;border: 1px solid #c1c8cc;" class="pa-2">
                                    <v-checkbox class="ma-0 pa-0" v-model="ABSelectBox" @change="ABRelCheckSelected()" hide-details >
                                        <span slot="label" class="font-weight-bold text-truncate " style="font-size: 13px;"> Separate Relationship & Dependent Info</span>
                                    </v-checkbox>
                                </div>
                            </v-layout>
                        </v-flex>
                        <div v-if="showABRelSection == true">
                            <relationdependent 
                                ref="ABRel" 
                                @save="saveABRel" 
                                :redpndntinfo="{
                                    cirelDataemty:ABrelCoverData.realdata,
                                    ciRelnshipCoveredEmpty:ABrelCoverData.dropdowndata,
                                    cisyncArryEmpty:ABrelCoverData.syncarr,
                                    ciactiveonlyempty:ABrelCoverData.ddselectedvalues
                                }" 
                                v-if="showABRelData == true">
                            </relationdependent>
                        </div>
                        <v-divider class="mt-3"></v-divider>
                        <v-layout style="" justify-end class="my-4">
                        <v-btn tile dark @click="clearAB()" style="font-size:12px;background-color:#e46a25" class="text-capitalize px-3 mx-2" small><v-icon dark small>close</v-icon> <v-divider vertical class="mx-2"></v-divider> Clear</v-btn>
                        <v-btn tile @click="submitAB()" dark style="font-size:12px;background-color:#23B1A9" class="text-capitalize px-3 mx-2" small><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider> Save</v-btn>
                            <v-btn @click="nxt(nextdrive[35])" tile  dark style="font-size:12px;background-color:#152F38" 
                            class="text-capitalize px-3 mx-2" small><v-icon dark small>arrow_forward</v-icon>
                            <v-divider vertical class="mx-2"></v-divider>Next</v-btn>
                        </v-layout>
                    </v-form>

                </div>
            </div>


  
           
            
        <!-- </v-expansion-panels> -->

         <v-layout style="flex:1" justify-end class="my-4">
            <v-btn dark  tile style="background-color:#E46A25" class="text-capitalize px-3 mx-2" small @click="returnPlanPge()"><v-icon dark small>arrow_back</v-icon> <v-divider vertical class="mx-2"></v-divider>Return</v-btn>
            <v-btn tile dark  @click="submitCnfrm()" style="font-size:12px;background-color:#23B1A9" class="text-capitalize px-3 mx-2" small><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider> Submit</v-btn>
                            
        </v-layout>
        <v-dialog v-model="confirmDialogCancel" persistent max-width="400px">
                 
                         <v-card tile >
                            <v-card tile>
                                <v-layout justify-space-between  class="" style="flex:1;background-ox-shadow: 0px 3px 5px #00000029;">
                             
                                    <v-card-title class="pa-0 pl-3 text-capitalize font-weight-bold" style="color:#10242B;font-size:15px"> 
                                       CONFIRMATION
                                    </v-card-title>
                                    <div style="border-left:1px solid #C1C8CC;" @click="close_cancelDialog()">
                                        <v-icon style="cursor: pointer;" class="pa-2" >close</v-icon>
                                    </div>
                                </v-layout>
                            </v-card>
                        <v-card-text class="font-weight-regular pa-0 ma-0 pl-3 py-3" style="color:#10242B;font-size:15px">
                                "Are you sure relevant information has been entered"
                        </v-card-text>
                    </v-card>
                    <v-card tile color="#F7F7F7">
                        <v-card-actions justify-start  >
                            <v-btn dark  tile color="black" @click="close_cancelDialog()" class="text-capitalize px-3 mx-2" small><v-icon dark small>cancel</v-icon> <v-divider vertical class="mx-2"></v-divider>No</v-btn>
                            <v-btn @click="submitAllCvrgs()" dark  tile color="#23B1A9" class="text-capitalize px-3 ml-2" small><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider> Yes</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
        

    </v-app>
</template>
<script>
import axios from "axios";
import Vuelidate from 'vuelidate'
import { required,numeric} from 'vuelidate/lib/validators'
import relationdependent from '../../component/Reltnshp_DpndntInfo.vue'

export default {
    // AA
    validations: {
    },

    components:{
        relationdependent
      
    },

    created() {
        this.$store.commit("SAVE_PAGE_HEADER", "Coverage Details");

        for(let i=0; i<36;i++){
            this.nextdrive.push({ ident:"n"+i.toString(),status:false,shown:'n'})
        } 
        //     this.token=this.$store.getters.successToken
        this.getAppliDayCareData()
        console.warn(this.nextdrive ,"nextdrive created")
        
        this.token=this.$store.getters.successToken
        console.log(this.$store.getters.fetchPlcyIdForPlnData, "fetchPlcyIdForPlnData")
       this.btnName=this.$store.getters.fetchPlcyIdForPlnData.text
       if(this.btnName==="EditPlnFrPlcy"||this.btnName==="ConfgrPlnFrPlcy"){
            // this.nextbtn=false
            this.getPlanbyId(this.$store.getters.fetchPlcyIdForPlnData.plan_No)
        }

         if(this.btnName==="EditNwRtlPln"||this.btnName==="CnfgrNwRtlPln"){
            this.nextbtn=false
            this.getPlanbyId(this.$store.getters.fetchPlcyIdForPlnData.retailPlan_Id)
        }

        if(this.btnName==="EditPlnFrPlcy"||this.btnName==="CrteNwPlnFrPlcy"||this.btnName==="EditNwRtlPln"||this.btnName==="CrteNwRtlPln"){
            this.inputdsbld=true
        }
    },

    computed: {
       
    },
    data() {
        return {
            // dd next btn akrazr
            onlyt:[],
            datais:{ y:true,n:false},
            nextdrive:[],
            shwExpnsnGc:false,
            shwExpnsnCtc:false,
            shwExpnsnCi:false,
            shwExpnsnOpdT:false,
            shwExpnsnHcuc:false,
            shwExpnsnOpdDT:false,
            shwExpnsnEaa:false,
            shwExpnsnTmr:false,
            shwExpnsnFe:false,
            shwExpnsnVctn:false,
            showMCAccor:false,
            showNAAccor:false,
            showDCAccor:false,
            showHCBAccor:false,
            showIPHAccor:false,
            showIPDAccor:false,
            showODAccor:false,
            showBSCAccor:false,
            showDTAccor:false,
            showABAccor:false,
            showVCAccor:false,
            showADBAccor:false,
            showPTDAccor:false,
            showEBAccor:false,
            showITAccor:false,
            showHCBCAccor:false,
            showPHAccor:false,
            shwExpnsnFtb:false,
            shwExpnsnAmbc:false,
            shwExpnsnPreHosp:false,
            shwExpnsnPostHosp:false,
            shwExpnsnNbbc:false,
            shwExpnsnBdoc:false,
            showHedAccor:false,
            showHacAccor:false,
            showRbAccor:false,

            IPCoverCheck:[],
            IPCoverCheckArray:[
                // {text:'In-Patient Treatment',value:'IP'},
                // {text:'Organ Donor',value:'OD'},
                // {text:'Domiciliary Treatment',value:'DT'},
                // {text:'Ayush Benefit',value:'AB'},
                // {text:'Bariatric Surgery Cover',value:'BSC'},
                // {text:'In-Patient Dental Treatment',value:'IPDT'},
            ],
            syncarr:[],
            reldatais:[],

            activeonly:[],
            selectRelationshipsCoverd:[],
            showPercentSi:false,
            showPrvPercentSi:false,
            showFlatAmnt:false,
            showPercClaimAmt:false,
            showDCCOpdSi:false,
            showDCCAdSi:false,
            DCCOpdSi:'',
            DCCAdSi:'',
            showDccApplicClaimPay:false,
            showDccCovrageApplicDur:false,
            dccCovrageApplicDur:'',
            DccApplicClaimPayOut:'',
            percentClaimAmt:'',
            percentSI:'',
            PrvpercentSI:'',
            flatAmountTxtField:'',
            DCCCoverAltName:'',
            DCCIrdaUINNo:'',
            DCCExtRefNo:'',
            DCCCondField:[],
            DCCGcCover:'',
            DCCRemarks:'',
            DCCExclus:[],
            plan:"",
            planItems:[],
            f1:[],
            f2:[],
            fhac:[],
            fhed:[],
            tmrData:[],
            feData:[],
            gcData:[],
            ciData:[],
            OpdtDta:[], 
            HcucDta:[], 
            opddtDta:[],
            eaaDta:[],
            fhac:[],
            fhed:[],
            inputdsbld:false,
            tempGcRlcvrd:{},
            tempCiRlcvrd:{},
            tempHeRlcvrd:{},
            tempPhRlcvrd:{},
            tempPoRlcvrd:{},
            showPHRelData:false,
            showRelSection:false,
            PHrelCoverData:{},
            showDCRelSection:false,
            showDCRelData:false,
            DCrelCoverData:{},
            showABDRelSection:false,
            ABDrelCoverData:{},
            showABDRelData:false,
            showPTDRelSection:false,
            PTDrelCoverData:{},
            showPTDRelData:false,
            IPHrelCoverData:{},
            showIPHRelSection:false,
            inPtntHzptnvalid:false,
            showIPHRelData:false,
        // AA start

            selectcauseType:['Accident', 'Non-Accident' ,'NA'],
            selectroomType:['ICU','Normal','NA'],
            selectApplicableClaimPayOut:['Lower of value', 'Higher of value'],

        // 0.mat
        matvalid:false,
        nbbvalid:false,
        rbvalid:false,
            matcoverageType:"",
            matcoverageBasis:'',
            matsubLimitBasis:[],
            showMCPrePercentSI:false,
            showMCPreFlatAmnt:false,
            showMCPrePercClaimAmt:false,
            showMCPreApplicClaimPay:false,
            MCPrePercentSI:'',
            MCPreFlatAmountTxtField:'',
            MCPrePercentClaimAmt:'',
            MCPreApplicClaimPayOut:'',
            matclaimPaymentBasis:'',
            showMCPostCovrageApplicDur:false,
            MCPostCovrageApplicDur:'',
            showMCPreCovrageApplicDur:false,
            MCPreCovrageApplicDur:'',
            showMCCovrageApplicDur:false,
            showMCPercentSI:false,
            showMCPrvPercentSI:false,
            showMCFlatAmnt:false,
            showMCPercClaimAmt:false,
            showMCApplicClaimPay:false,
            showMCOpdSi:false,
            showMCAdSi:false,
            MCOpdSi:'',
            MCAdSi:'',
            MCCovrageApplicDur:'',
            MCPercentSI:'',
            MCPrvPercentSI:'',
            MCFlatAmountTxtField:'',
            MCPercentClaimAmt:'',
            MCApplicClaimPayOut:'',
            MCCoverAltName:'',
            MCIrdaUINNo:'',
            MCExtRefNo:'',
            MCCondField:[],
            MCGcCover:'',
            MCRemarks:'',
            MCExclus:[],
            prodConditionArray:['cn1','cn2','cn3'],
            prodGCCoverArray:['gc1','gc2','gc3'],
            prodExcluArray:['ex1','ex2','ex3'],
            
            matwaitingPeriod:'',
            matlimitdel:'',
            matminHosDuration:'',
            MCwaitingPeriodDrop:'Days',
            matvaryongender:'No',
            selectmatvaryongender:['Yes','No'],
            showdata:false,

            matvaryoncity:'No',
            selectmatvaryoncity:['Yes','No'],
            showdatacity:false,

            // matperSI:'',
            // matMaxAgeChild:'',
            // matcityofdel1:'Metro',
            // matdeltype1:'Normal',
            // matflatamount1:'',
            // matcityofdel2:'',
            // matdeltype2:'',
            // matflatamount2:'',
            // matcityofdel3:'',
            // matdeltype3:'',
            // matflatamount3:'',
            // matcityofdel4:'',
            // matdeltype4:'',
            // matflatamount4:'',

            selectcityofdel:['Metro','Non-Metro'],
            selectdeltype:['Normal','C-Section'],
            matcityofdel1:'Metro',
            matdeltype1:'Normal',
            matflatamount1:'',
            matcityofdel2:'Metro',
            matdeltype2:'C-Section',
            matflatamount2:'',
            matcityofdel3:'Non-Metro',
            matdeltype3:'Normal',
            matflatamount3:'',
            matcityofdel4:'Non-Metro',
            matdeltype4:'C-Section',
            matflatamount4:'',

            selectmatnatal:['Yes','No'],
            matnatal:'No', 
            showdatanatal:false,
            matpreflatamount:'',
            matpostflatamount:'',

            matlimit:'No',
            selectmatlimit:['Yes','No'],
            showdatalimit:false,
            matprecoverbasis:'Upto SI',
            matpresublimit:[],
            matpreclaimpay:'',
            matpostcoverbasis:'Upto SI',
            MCPreshowSubLimClaimPay:false,
            MCPostshowSubLimClaimPay:false,
            matpostsublimit:[],
            matpostclaimpay:'',
            showMCPostPercentSI:false,
            showMCPostFlatAmnt:false,
            showMCPostPercClaimAmt:false,
            showMCPostApplicClaimPay:false,
            MCPostPercentSI:'',
            MCPostFlatAmountTxtField:'',
            MCPostPercentClaimAmt:'',
            MCPostApplicClaimPayOut:'',
            showMCPrePrevPercSI:false,
            showMCPostPrevPercSI:false,
            MCPrePercentPrevSI:'',
            MCPostPercentPrevSI:'',

            matflatshow:false,
            matSIshow:false,
            matsubBasisshow:false,
            matshowAppli:false,
            // max20:10,

        
        //1. hcbc

            hcbcvalid:false,
            hcbccoverageType:"",
            hcbccoverageBasis:'',
            hcbcsubLimitBasis:[],
            hcbcclaimPaymentBasis:'',
            showHCBACIPercentSi:false,
            showHCBACIPrvPercentSi:false,
            showHCBACIFlatAmnt:false,
            showHCBACIPercClaimAmt:false,
            HCBACIPercentSI:'',
            HCBACIPrvPercentSI:'',
            HCBACIFlatAmountTxtField:'',
            HCBACIPercentClaimAmt:'',
            showHCBACICovrageApplicDur:false,
            HCBACICovrageApplicDur:'',
            
            hcbcwaitingPeriod:'',
            hcbcapplicableClaimPayOut:'',
            hcbcminHosDuration:'',
            hcbcflatAmount:'',
            hcbcperSI:'',
            hcbcMaxAgeChild:'',

            hcbccauseType1:'Accident',
            hcbcroomType1:'ICU',
            hcbcdailyCash1:'',

            hcbccauseType2:'Accident',
            hcbcroomType2:'Normal',
            hcbcdailyCash2:'',

            hcbccauseType3:'Non-Accident',
            hcbcroomType3:'ICU',
            hcbcdailyCash3:'',

            hcbccauseType4:'Non-Accident',
            hcbcroomType4:'Normal',
            hcbcdailyCash4:'',
            
            hcbcflatshow:false,
            hcbcSIshow:false,
            hcbcsubBasisshow:false,
            hcbcshowAppli:false,
            // max20:10,

        // 2.hcb
            
            hcbvalid:false,
            hcbcoverageType:"",
            hcbcoverageBasis:'',
            hcbsubLimitBasis:[],
            showHCBPercentSi:false,
            showHCBPrvPercentSi:false,
            showHCBFlatAmnt:false,
            showHCBPercClaimAmt:false,
            HCBPercentSI:'',
            HCBPrvPercentSI:'',
            HCBFlatAmountTxtField:'',
            HCBPercentClaimAmt:'',
            hcbclaimPaymentBasis:'',
            showHCBCovrageApplicDur:false,
            HCBCovrageApplicDur:'',
            HCBCoverAltName:'',
            HCBIrdaUINNo:'',
            HCBExtRefNo:'',
            HCBCondField:[],
            HCBGcCover:'',
            HCBRemarks:'',
            HCBDeductible:'',
            HCBExclus:[],
            HCBSelectBox:false,
            showHCBRelSection:false,
            showHCBRelData:false,
            HCBrelCoverData:{},
            
            hcbwaitingPeriod:'',
            hcbwaitingPeriodDrop:'Days',
            hcbapplicableClaimPayOut:'',
            hcbminHosDuration:'',
            hcbflatAmount:'',
            hcbperSI:'',
            
            hcbcauseType1:'Accident',
            hcbroomType1:'ICU',
            hcbdailyCash1:'',

            hcbcauseType2:'Accident',
            hcbroomType2:'Normal',
            hcbdailyCash2:'',

            hcbcauseType3:'Non-Accident',
            hcbroomType3:'ICU',
            hcbdailyCash3:'',

            hcbcauseType4:'Non-Accident',
            hcbroomType4:'Normal',
            hcbdailyCash4:'',
            
            hcbflatshow:false,
            hcbSIshow:false,
            hcbsubBasisshow:false,
            hcbshowAppli:false,
            // max20:10,

            // 3.hed

            hedvalid:false,
            hedcoverageType:"",
            hedcoverageBasis:'',
            hedsubLimitBasis:'',
            hedclaimPaymentBasis:'',
            
            hedwaitingPeriod:'',
            hedwaitingPeriodDrop:'Days',
            hedapplicableClaimPayOut:'',
            hedCad:'',
            HEDCoverAltName:'',
            HEDIrdaUINNo:'',
            HEDExtRefNo:'',
            HEDCondField:[],
            HEDGcCover:'',
            HEDRemarks:'',
            HEDExclus:[],
            selectallowedDiaTests:[
                'Brain Perfusion imaging',
                'Computed Tomography (CT) guided Biopsy',
                'Computed Tomography (CT) Urography',
                'Digital Subtraction Angiography (DSA)',
                'Liver Biopsy',
                'Magnetic Resonance Cholangiography Scan',
                'Positron Emission Tomography– Computed Tomography (PET/CT)',
                'Positron emission tomography – Magnetic Resonance Imaging (PET/MRI)',
                'Renogram'
            ],
            hedallowedDiaTests:[],
            hedSeparateRshipDepInfo:'',
            // hedflatAmount:'',
            hedisSelectBox:false,

            hedFlatAmt:'',
            hedClmAmt:'',
            hedPerSI:'',
            hedPrvPerSI:'',
            
            shwhedFltAmt:false,
            shwhedPerSI:false,
            shwhedPrvPerSI:false,
            shwhedClmAmt:false,
            showHEDOpdSi:false,
            showHEDAdSi:false,
            HEDOpdSi:'',
            HEDAdSi:'',
        
            hedflatshow:false,
            hedsubBasisshow:false,
            shwhedCvrgAplcblDrtn:false,
            showhedacpo:false,
            // max20:10,

            hedrelDataemty:[],
            hedrelData:[
            {"active":false,"coverType":"Self","checkbox":false,"siSubLimit":"","coPay":"","roomRentLimit":[],"flatAmount":"","siPercent":"","applicableValue":"","waitingPeriod":"","fa":false,"si":false,"en_fa":false,"en_app":false,"en_si":false,"app":false},
            {"roomRentLimit":["% of SI","Flat amount"],"_id":"5fedbf46b6c598adaf41023f","active":true,"applicableValue":"Higher","checkbox":false,"coPay":"88","coverType":"Spouse","flatAmount":"500000","siPercent":"20","siSubLimit":"1223","waitingPeriod":"5","en_app":true,"app":false,"en_fa":true,"fa":false,"en_si":true,"si":false},
            {"coverType":"Children","active":false,"checkbox":false,"siSubLimit":"","coPay":"","roomRentLimit":[],"flatAmount":"","siPercent":"","applicableValue":"","waitingPeriod":"","fa":false,"si":false,"en_fa":false,"en_app":false,"en_si":false,"app":false},
            {"roomRentLimit":["Flat amount","% of SI"],"_id":"5fedbf46b6c5985e02410240","active":true,"applicableValue":"Higher","checkbox":false,"coPay":"70","coverType":"Parent","flatAmount":"50000","siPercent":"50","siSubLimit":"12","waitingPeriod":"5","en_app":true,"app":false,"en_fa":true,"fa":false,"en_si":true,"si":false}],
            hedRelnshipCovered:[
                {"display":"Self","checked":false,"aftercheck":false},
                {"display":"Spouse","checked":true,"aftercheck":true},
                {"display":"Children","checked":false,"aftercheck":false},
                {"display":"Parent","checked":true,"aftercheck":true}
            ],
            hedRelnshipCoveredEmpty:[],
            hedsyncArryEmpty:[],
            hedsyncArry:[
                {"display":"Spouse","checked":true},
                {"display":"Parent","checked":true}
            ],
            hedactiveonly:["Spouse","Parent"],
            hedactiveonlyempty:[],
            hedreldatashw:false,

            // 4.ci
            
           civalid:false,
            selectCriticalIllness:["Blindness","Cancer","Open Chest CABG","Creutzfeldt Jakob Disease","Kidney Failure Requiring Regular Dialysis","Open Heart Replacement or Repair of Heart Valves","Major organ Transplant","Motor Neutron Disease with permanent symptoms","Multiple Sclerosis with persisting symptoms","Myocardial Infarction (First Heart Attack of specific severity)","Permanent Paralysis of Limbs","Primary Pulmonary Hypertension","Progressive Scleroderma","Stroke resulting in permanent symptoms","Third Degree Burns"],
            cicoverageType:"",
            ciFlatAmt:"",
            ciPrcntSi:"",
            ciPrcntPrvSi:"",
            ciPrcntClmAmt:"",
            cicoverageBasis:'',
            ciCad:'',
            ciAplcblClmPyot:"",
            cisubLimitBasis:[],
            ciclaimPaymentBasis:'',
            ciCoveredCriticalIllness:'',
            cirelDataemty:[],
            cirelData:[
            {"active":false,"coverType":"Self","checkbox":false,"siSubLimit":"","coPay":"","roomRentLimit":[],"flatAmount":"","siPercent":"","applicableValue":"","waitingPeriod":"","fa":false,"si":false,"en_fa":false,"en_app":false,"en_si":false,"app":false},
            {"roomRentLimit":["% of SI","Flat amount"],"_id":"5fedbf46b6c598adaf41023f","active":true,"applicableValue":"Higher","checkbox":false,"coPay":"88","coverType":"Spouse","flatAmount":"500000","siPercent":"20","siSubLimit":"1223","waitingPeriod":"5","en_app":true,"app":false,"en_fa":true,"fa":false,"en_si":true,"si":false},
            {"coverType":"Children","active":false,"checkbox":false,"siSubLimit":"","coPay":"","roomRentLimit":[],"flatAmount":"","siPercent":"","applicableValue":"","waitingPeriod":"","fa":false,"si":false,"en_fa":false,"en_app":false,"en_si":false,"app":false},
            {"roomRentLimit":["Flat amount","% of SI"],"_id":"5fedbf46b6c5985e02410240","active":true,"applicableValue":"Higher","checkbox":false,"coPay":"70","coverType":"Parent","flatAmount":"50000","siPercent":"50","siSubLimit":"12","waitingPeriod":"5","en_app":true,"app":false,"en_fa":true,"fa":false,"en_si":true,"si":false}],
            ciRelnshipCovered:[
                {"display":"Self","checked":false,"aftercheck":false},
                {"display":"Spouse","checked":true,"aftercheck":true},
                {"display":"Children","checked":false,"aftercheck":false},
                {"display":"Parent","checked":true,"aftercheck":true}
            ],
            ciRelnshipCoveredEmpty:[],
            cisyncArryEmpty:[],
            cisyncArry:[
                {"display":"Spouse","checked":true},
                {"display":"Parent","checked":true}
            ],
            ciactiveonly:["Spouse","Parent"],
            ciactiveonlyempty:[],
            cireldatashw:false,
            hereldatashw:false,
            phreldatashw:false,
            gcreldatashw:false,
            poreldatashw:false,
            ciwaitingPeriod:'',
            ciSurvivalPeriod:'',
            ciSeparateRshipDepInfo:'',
            ciisSelectBox:false,
            
            ciSIshow:false,
            cisubBasisshow:false,
            // max20:10,

            // 5.plh
            
            plhvalid:false,
            plhcoverageType:"",
            plhcoverageBasis:'',
            plhsubLimitBasis:[],
            plhclaimPaymentBasis:'',
            
            plhwaitingPeriod:'',
            plhwaitingPeriodDrop:'Days',
            plhminHosDuration:'',
            plhSeparateRshipDepInfo:'',
            plhisSelectBox:false,
            plhperSI:'',
            plhPrvperSI:'',
            plhSIshow:false,
            plhPrvSIshow:false,
            showPHFlatAmnt:false,
            showPHPercClaimAmt:false,
            showPHOpdSi:false,
            showPHAdSi:false,
            PHOpdSi:'',
            PHAdSi:'',
            showPHApplicClaimPay:false,
            PHFlatAmountTxtField:'',
            PHPercentClaimAmt:'',
            PHApplicClaimPayOut:'',
            showCovrageApplicDur:false,
            PHCovrageApplicDur:'',
            plhsubBasisshow:false,
            PHCoverAltName:'',
            PHIrdaUINNo:'',
            PHExtRefNo:'',
            PHCondField:[],
            PHGcCover:'',
            PHRemarks:'',
            PHExclus:[],
            // max20:10,

            // 6.odt
            
            odtvalid:false,
            odtcoverageType:"",
            odtcoverageBasis:'',
            odtsubLimitBasis:[],
            odtclaimPaymentBasis:'',
            odtflatAmount:"",
            odtPrcntSi:'',
            odtPrcntPrvSi:'',
            odtPrcntClmAmt:'',
            odtAplcblClmPyot:'',
            odtCad:'',
            ODTCoverAltName:'',
            ODTIrdaUINNo:'',
            ODTExtRefNo:'',
            ODTCondField:[],
            ODTGcCover:'',
            ODTRemarks:'',
            ODTExclus:[],
            ODTSelectBox:false,
            showODTRelSection:false,
            showODTRelData:false,
            ODTrelCoverData:{},

            odtwaitingPeriod:'',
            odtwaitingPeriodDrop:'Days',
            odtflatshow:false,
            odtsubBasisshow:false,
            // max20:10,

            // 7.eaa
            
            eaavalid:false,    
            eaacoverageType:"",
            eaacoverageBasis:'',
            eaasubLimitBasis:[],
            eaaclaimPaymentBasis:'',
            eaaflatAmount:'',
            eaaPrcntSi:'',
            eaaPrcntPrvSi:'',
            eaaPrcntClmAmt:'',
            eaaAplcblClmPyot:'',
            eaaCad:'',

            eaawaitingPeriod:'',
            eaaflatshow:false,
            eaasubBasisshow:false,
            EAACoverAltName:'',
            EAAIrdaUINNo:'',
            EAAExtRefNo:'',
            EAACondField:[],
            EAAGcCover:'',
            EAARemarks:'',
            EAAExclus:[],
            EAAwaitingPeriodDrop:'Days',
            EAASelectBox:false,
            showEAARelSection:false,
            showEAARelData:false,
            EAArelCoverData:{},
            // max20:10,

            // 8.bdoc
            bdocvalid:false,

            bdoccoverageType:"",
            bdoccoverageBasis:'',
            bdocsubLimitBasis:'',
            bdocclaimPaymentBasis:'',
            bdocapplicableClaimPayOut:'',
            // bdocwaitingPeriod:'',
            
            bdocsubBasisshow:false,
            shwCoveAppDuratBDC: false,

            shFlatAmtForBDC : false,
            shSiAmtForBDC : false,
            shSiAmtForPrvBDC:false,
            shClaimAmtForBDC : false,

            shwAppCliamPayForBDC: false,
            AppCliamPayBDC: '',

            flatAmtForBDC : '',
            siAmtForBDC : '',
            siAmtForPrvBDC:'',
            claimAmtForBDC : '',
            CoveAppDuratBDC: '',


            // bdocperSI:'',
            // bdocflatshow:false,
            // bdocSIshow:false,
            
            // 9.nbb
            nbbcvalid:false,

            nbbcoverageType:"",
            nbbcoverageBasis:'',
            nbbsubLimitBasis:'',
            nbbclaimPaymentBasis:'',
            

            nbbwaitingPeriod:'',
            
            
            nbbsubBasisshow:false,

            shFlatAmtForNBB : false,
            shSiAmtForNBB : false,
            shSiAmtPrvForNBB:false,
            shClaimAmtForNBB : false,

            shwAppCliamPayForNBB: false,
            AppCliamPayNBB: '',

            shwCoveAppDuratNBB: false,
            CoveAppDuratNBB: '',


            flatAmtForNBB : '',
            siAmtForNBB : '',
            siAmtPrvForNBB:'',
            claimAmtForNBB : '',
            // nbbflatshow:false,
            // nbbflatAmount:'',
            // max20:10,

            bdcArr:[],
            dbbcArr:[],
            

            // 10.rb
            rbcvalid:false,
            rbcoverageType:"",
            rbcoverageBasis:'',
            rbsubLimitBasis:'',
            rbclaimPaymentBasis:'',
            rbrestoreWaitingPeriod:'',
            rbrestoreWaitingPeriodDrop:'Days',
            rbNoReinstatementsPermitted:'',
            rbapplicableClaimPayOut:'',
            rbCad:'',

            rbFlatAmt:'',
            rbClmAmt:'',
            rbPerSI:'',
            rbPrvPerSI:'',
            RBCCoverAltName:'',
            RBCIrdaUINNo:'',
            RBCExtRefNo:'',
            RBCCondField:[],
            RBCGcCover:'',
            RBCRemarks:'',
            RBCExclus:[],
            RBCSelectBox:false,
            showRBCRelSection:false,
            showRBCRelData:false,
            RBCrelCoverData:{},

            
            shwrbFltAmt:false,
            shwrbPerSI:false,
            shwrbPrvPerSI:false,
            shwrbClmAmt:false,
            shwrbCvrgAplcblDrtn:false,
            showrbacpo:false,

            rbsubBasisshow:false,
            // max20:10,

            // 11.ambc
            ambcvalid:false,
            ambccoverageType:"",
            ambccoverageBasis:'',
            ambcsubLimitBasis:'',
            ambcclaimPaymentBasis:'',
            ambcwaitingPeriod:'',
            ACCoverAltName:'',
            ACIrdaUINNo:'',
            ACExtRefNo:'',
            ACCondField:[],
            ACGcCover:'',
            ACRemarks:'',
            ACExclus:[],
            ACwaitingPeriodDrop:'Days',
            ACwaitingPeriod:'',
            ACSelectBox:false,
            showACRelSection:false,
            showACRelData:false,
            ACrelCoverData:{},

            
            shFlatAmtForambc:false,
            shSiAmtPrvForambc:false,
            flatAmtForambc:'',
            siAmtPrvForambc:'',
            shSiAmtForambc:false,
            siAmtForambc:'',
            shClaimAmtForambc:false,
            claimAmtForambc:'',

            ambcsubBasisshow:false,

            ambcapplicableClaimPayOut:'',
            shwAppCliamPayambc:false,

            shwCoveAppDuratambc:false,
            CoveAppDuratambc:'',

            // max20:10,
            // 12.dcc

            selectAppliDayCarePro:[],
            appDayCareArray:[],

            dccvalid:false,
            dcccoverageType:"",
            dcccoverageBasis:'',
            dccsubLimitBasis:[],
            dccclaimPaymentBasis:'',
            dccappliDayCarePro:[],
            dccwaitingPeriod:'',
            dccwaitingPeriodDrop:'Days',
            dccminHosDuration:'',
            dccisSelectBox:false,
            dccsubBasisshow:false,
            // max20:10,

            // 13.poh

           
            pohvalid:false,
            pohcoverageType:"",
            pohcoverageBasis:'',
            pohsubLimitBasis:'',
            pohclaimPaymentBasis:'',
            pohduration:'',
            pohwaitingPeriod:'',
            pohwaitingPeriodDrop:'Days',
            pohisSelectBox:false,
            pohsubBasisshow:false,
            PostHospCoverAltName:'',
            PostHospIrdaUINNo:'',
            PostHospExtRefNo:'',
            PostHospCondField:[],
            PostHospGcCover:'',
            PostHospRemarks:'',
            PostHospExclus:[],
            // max20:10,

            // 14.ph

            phvalid:false,
            phcoverageType:"",
            phcoverageBasis:'',
            phsubLimitBasis:'',
            phclaimPaymentBasis:'',
            phduration:'',
            phwaitingPeriod:'',
            phwaitingPeriodDrop:'Days',
            phisSelectBox:false,
            phsubBasisshow:false,
            PreHospCoverAltName:'',
            PreHospIrdaUINNo:'',
            PreHospExtRefNo:'',
            PreHospCondField:[],
            PreHospGcCover:'',
            PreHospRemarks:'',
            PreHospExclus:[],
            // max20:10,
            


        // AA end
            // Post Hospital
                shwAppCliamPayPostHosp:false,
                AppCliamPayPostHosp:'',
                shFlatAmtForPoh : false,
                shSiAmtForPoh : false,
                shSiAmtForPrvPoh:false,
                shClaimAmtForPoh : false,

                flatAmtForPoh : '',
                siAmtForPoh : '',
                siAmtForPrvPoh:'',
                claimAmtForPoh : '',
            // Post Hospital End

            // Pre Hospital 

                shwAppCliamPayPreHosp:false,
                AppCliamPayPreHosp:'',

                shFlatAmtForPRE : false,
                shSiAmtForPRE : false,
                shSiAmtForPrvPRE:false,
                shweaaPrcntPrvSi:false,
                shClaimAmtForPRE : false,

                flatAmtForPRE : '',
                siAmtForPRE : '',
                siAmtForPrvPRE:'',
                siAmtForPrvPRE:'',
                claimAmtForPRE : '',

                shwCoveAppDuratPreHosp: false,
                CoveAppDuratPreHosp: '',
            // Pre Hospital End



            // FTB 
                shFlatAmtForFTB : false,
                shSiAmtForFTB : false,
                shSiAmtPrvForFTB:false,
                shClaimAmtForFTB : false,

                flatAmtForFTB : '',
                siAmtForFTB : '',
                siAmtPrvForFTB:'',
                claimAmtForFTB : '',

                shwCoveAppDurat : false,
                CoveAppDurat: '',

                shwAppCliamPay : false,
                AppCliamPay : '',
            // FTB end for si,claim,flat

            nbbcvrgTypItems:["Indemnity","Benefit"],
            nbbcvrgBasisItems:["Upto SI","Over & above SI","Upto SI with sub limit"],
            nbbcvrgSbLmtBasisItems:["% of SI","Flat amount", "% of previous yr SI","% of claimed amount"],
            nbbClmpymntBasisItems:["Per hospitalization","Per day","Per member","Per policy","Duration basis","Per member lifetime", "Policy lifetime","NA"],


            cvrgTypItems:["Indemnity","Benefit"],
            cvrgBasisItems:["Upto SI","Over & above SI","Upto SI with sub limit"],
            cvrgSbLmtBasisItems:["% of SI","Flat amount", "% of previous yr SI","% of claimed amount", "% of OPD SI", "% of AD SI"],
            IPHSbLmtBasisItems:["% of SI","Flat amount", "% of previous yr SI","% of claimed amount" , "% of OPD SI", "% of AD SI"],
            ClmpymntBasisItems:["Per hospitalization","Per day","Per member","Per policy","Duration basis","Per member lifetime", "Policy lifetime","NA"],
            shwFtbSlb:false,
            shwCtSlb:false,
            shwGcSlb:false,
            shwNaSlb:false,
            shwOpdTSlb:false,
            shwVcSlb:false,
            shwHckSlb:false,
            shwAbdSlb:false,
            shwPtdSlb:false,
            shwTmrSlb:false,
            shwFeSlb:false,
            shwEbSlb:false,
            shwIftSlb:false,
            shwVcntnSlb:false,
            shwCtFltAmt:false,
            shwNaFltAmt:false,
            shwopdTFltAmt:false,
            shwHckFltAmt:false,
            shwTmrFltAmt:false,
            shwFeFltAmt:false,
            shwEbFltAmt:false,
            shwIfFltAmt:false,
            shwVcntnFltAmt:false,
            shwVcntnPrcntSi:false,
            shwVcntnPrcntPrvSi:false,
            shwVcntnPrcntClmAmt:false,
            showvaccOpdSi:false,
            showvaccAdSi:false,
            vaccOpdSi:'',
            vaccAdSi:'',
            shwVcntnCvrgAplcblDrtn:false,
            shwAbdSi:false,
            shwabdmxdysDsaprnc:false,
            shwCtPrcntSi:false,
            shwCtPrcntPrvSi:false,
            shwCtPrcntClmAmt:false,
            showCTCOpdSi:false,
            showCTCAdSi:false,
            CTCOpdSi:'',
            CTCAdSi:'',
            shwCtCvrgAplcblDrtn:false,
            shwCtctAplcblClmPyot:false,
            shwvcntnAplcblClmPyot:false,
            shwfePrcntSi:false,
            shwfePrcntPrvSi:false,
            shwFePrcntClmAmt:false,
            shwFeCvrgAplcblDrtn:false,
            shwFeAplcblClmPyot:false,
            shwTmrPrcntSi:false,
            shwTmrPrcntPrvSi:false,
            shwTmrPrcntClmAmt:false,
            shwTmrAplcblClmPyot:false,
            shwTmrCvrgAplcblDrtn:false,
            shwCiPrcntSi:false,
            shwCiPrcntPrvSi:false,
            shwCiPrcntClmAmt:false,
            shwCiAplcblClmPyot:false,
            shwCiCvrgAplcblDrtn:false,
            shwciFltAmt:false,
            shwCiRdi:false,
            shwHedRdi:false,
            shwGcFltAmt:false,
            shwGcPrcntSi:false,
            shwGcPrcntPrvSi:false,
            shwGcPrcntClmAmt:false,
            shwGcAplcblClmPyot:false,
            shwgcCvrgAplcblDrtn:false,
            shwGcRdi:false,
            shwopdTPrcntSi:false,
            shwopdTPrcntPrvSi:false,
            shwopdTPrcntClmAmt:false,
            shwopdTAplcblClmPyot:false,
            shwopdTCvrgAplcblDrtn:false,
            shwhckPrcntSi:false,
            shwhckPrcntPrvSi:false,
            shwhckPrcntClmAmt:false,
            shwhckAplcblClmPyot:false,
            shwhckCvrgAplcblDrtn:false,
            shwodtPrcntSi:false,
            shwodtPrcntPrvSi:false,
            shwodtPrcntClmAmt:false,
            shwodtAplcblClmPyot:false,
            shwodtCvrgAplcblDrtn:false,
            shweaaPrcntSi:false,
            shweaaPrcntPrvSi:false,
            shweaaPrcntClmAmt:false,
            shweaaAplcblClmPyot:false,
            shweaaCvrgAplcblDrtn:false,
            shwCoveAppDuratPOH:false,
            shwPoRdi:false,
            shwPhRdi:false,

            ftbvalid:false,
            ctvalid:false,
            gcvalid:false,
            navalid:false,
            opdTvalid:false,
            vcvalid:false,
            hckvalid:false,
            tmrvalid:false,
            fevalid:false,
            ebvalid:false,
            ifvalid:false,
            vcntnvalid:false,
            hacvalid:false,
            ADBValid:false,
            PTDValid:false,
            

            coverageType:'',
            coverageBasis:'',
            subLimitBasis:[],
            claimPaymentBasis:'',
            MnElgblDstnceFrmInsrdRsdnc:'',
            FTBwaitingPeriod:'',
            AlwdCntofImdteFmlyMmbr:'',
            FTBCoverAltName:'',
            FTBIrdaUINNo:'',
            FTBExtRefNo:'',
            FTBCondField:[],
            FTBGcCover:'',
            FTBRemarks:'',
            FTBExclus:[],
            FTBwaitingPeriodDrop:'Days',
            FTBwaitingPeriod:'',
            FTBSelectBox:false,
            showFTBRelSection:false,
            showFTBRelData:false,
            FTBrelCoverData:{},
            
            ctCoverageType:'',
            ctCoverageBasis:'',
            ctSubLimitBasis:[],
            ctClaimPaymentBasis:'',
            ctFlatAmt:'',
            ctWaitingPeriod:'',
            CTCwaitingPeriodDrop:'Days',
            ctMnmHspitlztnDrtn:'',
            max05:5,
            max10:10,
            max20:20,
            max30:30,
            max40:40,
            max50:50,
            max100:100,
            max200:200,
            CtcVacGrp:[],
            CtCad:'',
            ctPrcntSi:'',
            ctPrcntPrvSi:'',
            ctPrcntClmAmt:'',
            ctAplcblClmPyot:'',
            CTCCoverAltName:'',
            CTCIrdaUINNo:'',
            CTCExtRefNo:'',
            CTCCondField:[],
            CTCGcCover:'',
            CTCRemarks:'',
            CTCExclus:[],
            CTCSelectBox:false,
            showCTCRelSection:false,
            showCTCRelData:false,
            CTCrelCoverData:{},

            gcCoverageType:'',
            gcCoverageBasis:'',
            gcSubLimitBasis:[],
            gcClaimPaymentBasis:'',
            gcFlatAmt:'',
            gcPrcntSi:'',
            gcPrcntPrvSi:'',
            gcPrcntClmAmt:'',
            gcAplcblClmPyot:'',
            gcCad:'',
            gcCntryofDseDtctn:'',
            gcWaitingPeriod:'',
            gcwaitingPeriodDrop:'Days',
            gcMnmHspitlztnDrtn:'',
            gccheckbox: false,
            GCCoverAltName:'',
            GCIrdaUINNo:'',
            GCExtRefNo:'',
            GCCondField:[],
            GCGcCover:'',
            GCRemarks:'',
            GCExclus:[],

            opdTCoverageType:'',
            opdTCoverageBasis:'',
            opdTSubLimitBasis:[],
            opdTClaimPaymentBasis:'',
            opdTFlatAmt:'',
            opdTFlatAmt:'',
            opdTPrcntSi:'',
            opdTPrcntPrvSi:'',
            opdTPrcntClmAmt:'',
            opdTAplcblClmPyot:'',
            opdTCad:'',
            opdTWtngPrd:'',
            OPDTCoverAltName:'',
            OPDTIrdaUINNo:'',
            OPDTExtRefNo:'',
            OPDTCondField:[],
            OPDTGcCover:'',
            OPDTRemarks:'',
            OPDTExclus:[],
            OPDTwaitingPeriodDrop:'Days',
            OPDTSelectBox:false,
            showOPDTRelSection:false,
            showOPDTRelData:false,
            OPDTrelCoverData:{},

            naCoverageType:'',
            naCoverageBasis:'',
            naClaimPaymentBasis:'',
            showNACovrageApplicDur:false,
            NACovrageApplicDur:'',
            naSubLimitBasis:[],
            showNAPercentSi:false,
            showNAPrvPercentSi:false,
            showNAFlatAmnt:false,
            showNAPercClaimAmt:false,
            showNAOpdSi:false,
            showNAAdSi:false,
            NAOpdSi:'',
            NAAdSi:'',
            showNAApplicClaimPay:false,
            NAPercentSI:'',
            NAPrvPercentSI:'',
            NAFlatAmountTxtField:'',
            NAPercentClaimAmt:'',
            NAApplicClaimPayOut:'',
            naFlatAmt:'',
            naMxPyblDrtn:'',
            naDeductible:'',
            naWtngPrd:'',
            NACoverAltName:'',
            NAIrdaUINNo:'',
            NAExtRefNo:'',
            NACondField:[],
            NAGcCover:'',
            NARemarks:'',
            NAExclus:[],
            NAwaitingPeriodDrop:'Days',
            NASelectBox:false,
            showNARelSection:false,
            showNARelData:false,
            NArelCoverData:{},

            
            vcCoverageType:'',
            vcCoverageBasis:'',
            vcSubLimitBasis:[],
            showVcPercentSi:false,
            showVcPrvPercentSi:false,
            showVcFlatAmnt:false,
            showVcPercClaimAmt:false,
            showVCOpdSi:false,
            showVCAdSi:false,
            VCOpdSi:'',
            VCAdSi:'',
            showVcApplicClaimPay:false,
            vcPercentSI:'',
            vcPrvPercentSI:'',
            vcFlatAmountTxtField:'',
            vcPercentClaimAmt:'',
            vcApplicClaimPayOut:'',
            vcClaimPaymentBasis:'',
            vcExclusions:[],
            vcWtngPrd:'',
            VCCoverAltName:'',
            VCIrdaUINNo:'',
            VCExtRefNo:'',
            VCCondField:[],
            VCGcCover:'',
            VCRemarks:'',
            VCExclus:[],
            VCwaitingPeriodDrop:'Days',
            VCwaitingPeriod:'',
            VCSelectBox:false,
            showVCRelSection:false,
            showVCRelData:false,
            VCrelCoverData:{},
            vcExclusionsItems:["Cost of frames for prescribed lenses","Sunglasses, unless medically prescribed by a Medical Practitioner","Medical or surgical Treatment of the eye","Lenses which are not medical necessary and are not prescribed by an optometrist or ophthalmologist","NA"],


            hckCoverageType:'',
            hckCoverageBasis:'',
            hckSubLimitBasis:[],
            hckClaimPaymentBasis:'',
            hckFlatAmt:'',
            hckPrcntSi:'',
            hckPrcntPrvSi:'',
            hckPrcntClmAmt:'',
            hckAplcblClmPyot:'',
            hckWaitingPeriod:'',
            hckCad:'',
            HCUCCoverAltName:'',
            HCUCNoClaimFreeYrs:'',
            HCUCwaitingPeriodDrop:'Days',
            HCUCIrdaUINNo:'',
            HCUCExtRefNo:'',
            HCUCCondField:[],
            HCUCGcCover:'',
            HCUCRemarks:'',
            HCUCExclus:[],
            HCUCSelectBox:false,
            showHCUCRelSection:false,
            showHCUCRelData:false,
            HCUCrelCoverData:{},

            adbCoverageType:'',
            adbCoverageBasis:'',
            adbSubLimitBasis:[],
            showAccDBPercentSi:false,
            showAccDBPrvPercentSi:false,
            showAccDBFlatAmnt:false,
            showAccDBPercClaimAmt:false,
            showADBOpdSi:false,
            showADBAdSi:false,
            ADBOpdSi:'',
            ADBAdSi:'',
            showAccDBApplicClaimPay:false,
            AccDBpercentSI:'',
            AccDBPrvpercentSI:'',
            AccDBFlatAmountTxtField:'',
            AccDBPercentClaimAmt:'',
            AccDBApplicClaimPayOut:'',
            adbClaimPaymentBasis:'',
            adbSi:'',
            abdDsaprncAplcbl:false,
            abdmxdysAccdnt:'',
            abdmxdysDsaprnc:'',
            abdcheckbox:false,
            ADBCoverAltName:'',
            ADBIrdaUINNo:'',
            ADBExtRefNo:'',
            ADBCondField:[],
            ADBGcCover:'',
            ADBRemarks:'',
            ADBAllowdDurFrmDOAcc:'',
            ADBWaitingPrdToDeclare:'',
            ADBWaitPeriod:'',
            ADBBenifPayable:'',
            ADBwaitingPeriodDrop:'Days',
            ADBwaitingPeriod:'',
            ADBExclus:[],

            
            ptdCoverageType:'',
            ptdCoverageBasis:'',
            ptdSubLimitBasis:[],
            showPTDPercentSi:false,
            showPTDPrvPercentSi:false,
            showPTDFlatAmnt:false,
            showPTDPercClaimAmt:false,
            showPTDOpdSi:false,
            showPTDAdSi:false,
            PTDOpdSi:'',
            PTDAdSi:'',
            showPTDApplicClaimPay:false,
            PTDpercentSI:'',
            PTDPrvpercentSI:'',
            PTDFlatAmountTxtField:'',
            PTDPercentClaimAmt:'',
            PTDApplicClaimPayOut:'',
            ptdClaimPaymentBasis:'',
            showADBCovrageApplicDur:false,
            ADBCovrageApplicDur:'',
            showPTDCovrageApplicDur:false,
            PTDCovrageApplicDur:'',
            ptdNatureofLoss:'',
            PTDCoverAltName:'',
            PTDIrdaUINNo:'',
            PTDExtRefNo:'',
            PTDCondField:[],
            PTDGcCover:'',
            PTDRemarks:'',
            PTDExclus:[],
            PTDwaitingPeriodDrop:'Days',
            PTDwaitingPeriod:'',
            PTDfeatureClaim:false,
            showPTDPrimCoverage:false,
            PTDprimCovrgArray:[],
            PTDPrimCover:[],
            PTDBenpay:'',
            PTDDurFromAccDate:'',
            PTDDisbleContPer:'',
            ptdSi:'',
            ptdCheckbox:false,

            ptdNatureofLossItems:["Irrecoverable Loss of sight of both eyes",
                                    "Physical Separation of or the irrecoverable loss of ability to use both hands or both feet",
                                    "Physical Separation of or the irrecoverable loss of ability to use one hand and one foot",
                                    "Irrecoverable Loss of sight of one eye and the physical separation of or the irrecoverable loss of ability to use either one hand or one foot"
                                    ],

            tmrCoverageType:'',
            tmrCoverageBasis:'',
            tmrSubLimitBasis:[],
            tmrClaimPaymentBasis:'',
            tmrFlatAmt:'',
            tmrPrcntSi:'',
            tmrPrcntPrvSi:'',
            tmrPrcntClmAmt:'',
            tmrAplcblClmPyot:'',
            tmrCad:'',
            TMRCoverAltName:'',
            TMRIrdaUINNo:'',
            TMRExtRefNo:'',
            TMRCondField:[],
            TMRGcCover:'',
            TMRRemarks:'',
            TMRExclus:[],
            TMRwaitingPeriodDrop:'Days',
            TMRwaitingPeriod:'',
            TMRSelectBox:false,
            showTMRRelSection:false,
            showTMRRelData:false,
            TMRrelCoverData:{},


            feCoverageType:'',
            feCoverageBasis:'',
            feSubLimitBasis:[],
            feClaimPaymentBasis:'',
            feFlatAmt:'',
            FeCad:'',
            fePrcntSi:'',
            fePrcntPrvSi:'',
            fePrcntClmAmt:'',
            feAplcblClmPyot:'',
            FECoverAltName:'',
            FEIrdaUINNo:'',
            FEExtRefNo:'',
            FECondField:[],
            FEGcCover:'',
            FERemarks:'',
            FEExclus:[],
            FEwaitingPeriodDrop:'Days',
            FEwaitingPeriod:'',
            FESelectBox:false,
            showFERelSection:false,
            showFERelData:false,
            FErelCoverData:{},

            
            ebCoverageType:'',
            ebCoverageBasis:'',
            ebSubLimitBasis:[],
            showEBPercentSi:false,
            showEBPrvPercentSi:false,
            showEBFlatAmnt:false,
            showEBPercClaimAmt:false,
            showEBOpdSi:false,
            showEBAdSi:false,
            EBOpdSi:'',
            EBAdSi:'',
            showEBApplicClaimPay:false,
            EBPercentSI:'',
            EBPrvPercentSI:'',
            EBFlatAmountTxtField:'',
            EBPercentClaimAmt:'',
            EBApplicClaimPayOut:'',
            ebClaimPaymentBasis:'',
            showEBCovrageApplicDur:false,
            EBCovrageApplicDur:'',
            ebMxChldAge:'',
            EBCoverAltName:'',
            EBIrdaUINNo:'',
            EBExtRefNo:'',
            EBCondField:[],
            EBGcCover:'',
            EBRemarks:'',
            EBExclus:[],
            EBwaitingPeriodDrop:'Days',
            EBwaitingPeriod:'',
            EBMaxYearsFeesPaid:'',
            EBEligibility:'',
            EBBenpay:'',
            EBPrimCover:[],
            EBEligibleArray:[],
            EBfeatureClaim:false,
            showEBPrimCoverage:false,
            EBSelectBox:false,
            showEBRelSection:false,
            showEBRelData:false,
            EBrelCoverData:{},
            EBprimCovrgArray:[],
            EBBenpayArray:['Per policy year','Per Accident'],


            iftCoverageType:'',
            iftCoverageBasis:'',
            showItCovrageApplicDur:false,
            ItCovrageApplicDur:'',
            showItPercentSi:false,
            showItPrvPercentSi:false,
            showItFlatAmnt:false,
            showItPercClaimAmt:false,
            showITOpdSi:false,
            showITAdSi:false,
            ITOpdSi:'',
            ITAdSi:'',
            showItApplicClaimPay:false,
            ItPercentSI:'',
            ItPrvPercentSI:'',
            ItFlatAmountTxtField:'',
            ItPercentClaimAmt:'',
            ItApplicClaimPayOut:'',
            iftSubLimitBasis:[],
            iftClaimPaymentBasis:'',
            iftFlatAmt:'',
            iftWaitingPeriod:'',
            iftExclsns:[],
            iftMdclExpnse:[],
            ITCoverAltName:'',
            ITIrdaUINNo:'',
            ITExtRefNo:'',
            ITCondField:[],
            ITGcCover:'',
            ITRemarks:'',
            ITExclus:[],
            ITwaitingPeriodDrop:'Days',
            ITwaitingPeriod:'',
            ITSelectBox:false,
            showITRelSection:false,
            showITRelData:false,
            ITrelCoverData:{},
            iftExclsnsItems:["Surrogate or vicarious pregnancy","Treatments related to infertility caused due to contraceptives or birth control methods/its complications/voluntary sterilization procedures"],
            iftTypMdclExpnseItems:["Fertility hormones","Artificial insemination","Surgery","Assisted reproductive technology (ART)"],


            vcntnCoverageType:'',
            vcntnCoverageBasis:'',
            vcntnSubLimitBasis:[],
            vcntnClaimPaymentBasis:'',
            vcntnFlatAmt:'',
            vcntnPrcntSi:'',
            vcntnPrcntPrvSi:'',
            vcntnPrcntClmAmt:'',
            VcntnCad:'',
            vcntnWaitingPeriod:'',
            vaccwaitingPeriodDrop:'Days',
            vcntnElglblDrtn:'',
            vcntnAplcblClmPyot:'',
            vaccCoverAltName:'',
            vaccIrdaUINNo:'',
            vaccExtRefNo:'',
            vaccCondField:[],
            vaccGcCover:'',
            vaccRemarks:'',
            vaccExclus:[],
            vaccSelectBox:false,
            showvaccRelSection:false,
            showvaccRelData:false,
            vaccrelCoverData:{},


            hacAplcblClmPyotItms:['Lower of value','Higher of value'],
            hacCadItms:['Every year','Every second year','Every third year','Every fourth year'],
            hacCoverageType:'',
            hacCoverageBasis:'',
            hacSubLimitBasis:[],
            hacClaimPaymentBasis:'',
            hacFlatAmt:'',
            hacClmAmt:'',
            hacPerSI:'',
            hacPrvPerSI:'',
            hacAplcblClmPyot:'',
            hacWaitingPeriod:'',
            hacCad:[],
            shwHacSlb:false,
            shwHAcFltAmt:false,
            shwHAcPerSI:false,
            shwHAcPrvPerSI:false,
            shwHAcClmAmt:false,
            shwHAcCvrgAplcblDrtn:false,
            showhacacpo:false,

            inPtntHzptnCoverageType:'',
            inPtntHzptnCoverageBasis:'',
            InPtntHzptnSubLimitBasis:[],
            showIPHPercentSi:false,
            showIPHPrvPercentSi:false,
            showIPHFlatAmnt:false,
            showIPHPercClaimAmt:false,
            showIPHOpdSi:false,
            showIPHAdSi:false,
            IPHshowAppli:false,
            IPHPercentSI:'',
            IPHPrvPercentSI:'',
            IPHFlatAmountTxtField:'',
            IPHPercentClaimAmt:'',
            IPHapplicableClaimPayOut:'',
            IPHOpdSi:'',
            IPHAdSi:'',
            IPHCoverAltName:'',
            IPHMinAgeMemb:'',
            IPHMinTreatDura:'',
            IPHIrdaUINNo:'',
            IPHExtRefNo:'',
            IPHCondField:[],
            IPHGcCover:'',
            IPHRemarks:'',
            IPHExclus:[],
            showIPHCovrageApplicDur:false,
            IPHCovrageApplicDur:'',
            shwInPtntHzptnSlb:false,
            InPtntHzptnClaimPaymentBasis:'',
            InPtntHzptnWaitingPeriod:'',
            InPtntHzptnWaitingPeriodDrop:'Days',
            InPtntHzptnMinHospDura:'',
            inPtntHzptnCoPayapplicable:'No',
            inPtntHzptnCoPayapplicableItems:["Yes","No"],
            InPtntHzptnCoPayPrcnt:'',
            inPtntHzptnCoPayTypeItems:["Basis room category","Percentage"],
            inPtntHzptnCoPayType:'',
            InPtntHzptnCoPayPrcntSr:'',
            InPtntHzptnCoPayPrcntNr:'',
            shwInPtntHzptnCopayTyp:false,
            shwInPtntHzptnCoPayPrcnt:false,
            showCoPayPercRoom:false,
            InPtntHzptnOrgnDonr:false,
            InPtntHzptnDT:false,
            InPtntHzptnAB:false,
            InPtntHzptnBsc:false,
            InPtntHzptnIPDT:false,
            InPtntHzptnMnAgNo:'',
            InPtntHzptnMnTrtmntDrn:'',
            InPtntHzptnSelectBox:false,

            // In-Patient Dental
            IPDvalid:false,
            showIPDsubLimBasis:false,
            showIPDPercentSi:false,
            showIPDFlatAmnt:false,
            showIPDPrvPercentSi:false,
            showIPDPercClaimAmt:false,
            showIPDOpdSi:false,
            showIPDAdSi:false,
            showIPDAppli:false,
            showIPDclaimPayBasis:false,
            showIPDCovrageApplicDur:false,
            showIPDcoPayType:false,
            showIPDCoPayPrcnt:false,
            IPDCoverAltName:'',
            IPDCoverageType:'',
            IPDCoverageBasis:'',
            IPDSubLimitBasis:[],
            IPDPercentSI:'',
            IPDFlatAmountTxtField:'',
            IPDPrvPercentSI:'',
            IPDPercentClaimAmt:'',
            IPDOpdSi:'',
            IPDAdSi:'',
            IPDapplicableClaimPayOut:'',
            IPDClaimPaymentBasis:'',
            IPDCovrageApplicDur:'',
            IPDWaitingPeriodDrop:'Days',
            IPDWaitingPeriod:'',
            IPDMinHospDura:'',
            IPDCoPayapplicable:'No',
            IPDCoPayType:'',
            IPDCoPayPrcnt:'',
            IPDCoPayPrcntSR:'',
            IPDCoPayPrcntNR:'',
            IPDMinAgeMemb:'',
            IPDMinTreatDura:'',
            IPDIrdaUINNo:'',
            IPDExtRefNo:'',
            IPDCondField:[],
            IPDGcCover:'',
            IPDRemarks:'',
            IPDExclus:[],
            showIPDCoPayPercRoom:false,
            IPDSelectBox:false,
            showIPDRelSection:false,
            showIPDRelData:false,
            IPDrelCoverData:{},

            // ORGAN DONOR
            ODvalid:false,
            showODsubLimBasis:false,
            showODPercentSi:false,
            showODFlatAmnt:false,
            showODPrvPercentSi:false,
            showODPercClaimAmt:false,
            showODOpdSi:false,
            showODAdSi:false,
            showODAppli:false,
            showODclaimPayBasis:false,
            showODCovrageApplicDur:false,
            showODcoPayType:false,
            showODCoPayPrcnt:false,
            ODCoverAltName:'',
            ODCoverageType:'',
            ODCoverageBasis:'',
            ODSubLimitBasis:[],
            ODPercentSI:'',
            ODFlatAmountTxtField:'',
            ODPrvPercentSI:'',
            ODPercentClaimAmt:'',
            ODOpdSi:'',
            ODAdSi:'',
            ODapplicableClaimPayOut:'',
            ODClaimPaymentBasis:'',
            ODCovrageApplicDur:'',
            ODWaitingPeriodDrop:'Days',
            ODWaitingPeriod:'',
            ODMinHospDura:'',
            ODCoPayapplicable:'No',
            ODCoPayType:'',
            ODCoPayPrcnt:'',
            ODCoPayPrcntSR:'',
            ODCoPayPrcntNR:'',
            ODMinAgeMemb:'',
            ODMinTreatDura:'',
            ODIrdaUINNo:'',
            ODExtRefNo:'',
            ODCondField:[],
            ODGcCover:'',
            ODRemarks:'',
            ODExclus:[],
            showODCoPayPercRoom:false,
            ODSelectBox:false,
            showODRelSection:false,
            showODRelData:false,
            ODrelCoverData:{},

            // BARIATRIC SURGERY COVER
            BSCvalid:false,
            showBSCsubLimBasis:false,
            showBSCPercentSi:false,
            showBSCFlatAmnt:false,
            showBSCPrvPercentSi:false,
            showBSCPercClaimAmt:false,
            showBSCOpdSi:false,
            showBSCAdSi:false,
            showBSCAppli:false,
            showBSCclaimPayBasis:false,
            showBSCCovrageApplicDur:false,
            showBSCcoPayType:false,
            showBSCCoPayPrcnt:false,
            BSCCoverAltName:'',
            BSCCoverageType:'',
            BSCCoverageBasis:'',
            BSCSubLimitBasis:[],
            BSCPercentSI:'',
            BSCFlatAmountTxtField:'',
            BSCPrvPercentSI:'',
            BSCPercentClaimAmt:'',
            BSCOpdSi:'',
            BSCAdSi:'',
            BSCapplicableClaimPayOut:'',
            BSCClaimPaymentBasis:'',
            BSCCovrageApplicDur:'',
            BSCWaitingPeriodDrop:'Days',
            BSCWaitingPeriod:'',
            BSCMinHospDura:'',
            BSCCoPayapplicable:'No',
            BSCCoPayType:'',
            BSCCoPayPrcnt:'',
            BSCCoPayPrcntSR:'',
            BSCCoPayPrcntNR:'',
            BSCMinAgeMemb:'',
            BSCMinTreatDura:'',
            BSCIrdaUINNo:'',
            BSCExtRefNo:'',
            BSCCondField:[],
            BSCGcCover:'',
            BSCRemarks:'',
            BSCExclus:[],
            showBSCCoPayPercRoom:false,
            BSCSelectBox:false,
            showBSCRelSection:false,
            showBSCRelData:false,
            BSCrelCoverData:{},

            // DOMICILIARY TREATMENT
            DTvalid:false,
            showDTsubLimBasis:false,
            showDTPercentSi:false,
            showDTFlatAmnt:false,
            showDTPrvPercentSi:false,
            showDTPercClaimAmt:false,
            showDTOpdSi:false,
            showDTAdSi:false,
            showDTAppli:false,
            showDTclaimPayBasis:false,
            showDTCovrageApplicDur:false,
            showDTcoPayType:false,
            showDTCoPayPrcnt:false,
            DTCoverAltName:'',
            DTCoverageType:'',
            DTCoverageBasis:'',
            DTSubLimitBasis:[],
            DTPercentSI:'',
            DTFlatAmountTxtField:'',
            DTPrvPercentSI:'',
            DTPercentClaimAmt:'',
            DTOpdSi:'',
            DTAdSi:'',
            DTapplicableClaimPayOut:'',
            DTClaimPaymentBasis:'',
            DTCovrageApplicDur:'',
            DTWaitingPeriodDrop:'Days',
            DTWaitingPeriod:'',
            DTMinHospDura:'',
            DTCoPayapplicable:'No',
            DTCoPayType:'',
            DTCoPayPrcnt:'',
            DTCoPayPrcntSR:'',
            DTCoPayPrcntNR:'',
            DTMinAgeMemb:'',
            DTMinTreatDura:'',
            DTIrdaUINNo:'',
            DTExtRefNo:'',
            DTCondField:[],
            DTGcCover:'',
            DTRemarks:'',
            DTExclus:[],
            showDTCoPayPercRoom:false,
            DTSelectBox:false,
            showDTRelSection:false,
            showDTRelData:false,
            DTrelCoverData:{},

            // AYUSH BENEFIT
            ABvalid:false,
            showABsubLimBasis:false,
            showABPercentSi:false,
            showABFlatAmnt:false,
            showABPrvPercentSi:false,
            showABPercClaimAmt:false,
            showABOpdSi:false,
            showABAdSi:false,
            showABAppli:false,
            showABclaimPayBasis:false,
            showABCovrageApplicDur:false,
            showABcoPayType:false,
            showABCoPayPrcnt:false,
            ABCoverAltName:'',
            ABCoverageType:'',
            ABCoverageBasis:'',
            ABSubLimitBasis:[],
            ABPercentSI:'',
            ABFlatAmountTxtField:'',
            ABPrvPercentSI:'',
            ABPercentClaimAmt:'',
            ABOpdSi:'',
            ABAdSi:'',
            ABapplicableClaimPayOut:'',
            ABClaimPaymentBasis:'',
            ABCovrageApplicDur:'',
            ABWaitingPeriodDrop:'Days',
            ABWaitingPeriod:'',
            ABMinHospDura:'',
            ABCoPayapplicable:'No',
            ABCoPayType:'',
            ABCoPayPrcnt:'',
            ABCoPayPrcntSR:'',
            ABCoPayPrcntNR:'',
            ABMinAgeMemb:'',
            ABMinTreatDura:'',
            ABIrdaUINNo:'',
            ABExtRefNo:'',
            ABCondField:'',
            ABGcCover:'',
            ABRemarks:'',
            ABExclus:[],
            showABCoPayPercRoom:false,
            ABSelectBox:false,
            showABRelSection:false,
            showABRelData:false,
            ABrelCoverData:{},

            plan:"",
            planItems:[],
            formDataDC:[],
            formDataPH:[],
            formDataVC:[],
            formDataIT:[],
            formDataADB:[],
            formDataPTD:[],
            formDataEB:[],
            formDataNA:[],
            formDataHCB:[],
            formDataHCBACI:[],
            inPhDta:[],
            IPDData:[],
            ODData:[],
            BSCData:[],
            DTData:[],
            ABData:[],
            mcDta:[],
            rbDta:[],
            ambcDta:[],
            poDta:[],
            prehDta:[],
            ftbDta:[],

            showVcCovrageApplicDur:false,
            vcCovrageApplicDur:'',

            dccRelationCoverd:[],
            showDccRelationCover:false,
            selectDccRelationCoverd:['Self','Spouse','Children','Parent'],
            confirmDialogCancel:false,

            required(){
                return v=>v && v.length>0 || "This Field is Required"
            },
            minLength(minLength){
                return v=>v && v.length>=minLength || `Must be atleast ${minLength} Characters`
            },
            maxLength(maxLength){
                return v=>v && v.length<=maxLength || `Must be less than ${maxLength} Characters`
            },
            spclChar(){
                return v=>/^[A-Za-z0-9 ]+$/.test(v) || `Special Characters Not Allowed`
            },
            spclCharNew :[               
                v => /^[a-zA-Z0-9]*$/.test(v) || 'Special Characters Not Allowed'
            ],
            alphaRule :[               
                v => /^[a-zA-Z ]*$/.test(v) || 'Only Alphabets are allowed.'
            ],
            alphaNumeric(){
                return v=>/^[0-9 ]+$/.test(v) || `Only Numbers are allowed.`
            },
            numberRule: v  => {
                if (!v.trim()) return true;
                if (!isNaN(parseFloat(v)) && v >= 0 && v <=100) return true;
                return 'Values between 0 and 100 are accepted';
            },
            decimalRule(){
                return v=>/^\d+(\.\d{1,2})?$/.test(v) || `Numeric upto two decimals.`
            }

        }
    },    

    methods: {
        EBFeatureClaimChange(ev){
            ev == true ? this.showEBPrimCoverage = true : this.showEBPrimCoverage = false
        },
        PTDFeatureClaimChange(ev){
            ev == true ? this.showPTDPrimCoverage = true : this.showPTDPrimCoverage = false
        },

        currentis(indis){
            // alert("current triggerd ?")
             var poss = this.onlytrue.findIndex(e=> e.ident == indis.ident)
            console.log("poss is ", poss);
                   console.log("current only true are ",this.onlytrue)
            this.onlytrue[poss].shown == 'y' ?  this.onlytrue[poss].shown = 'n' : this.onlytrue[poss].shown = 'y' ;
        },

        nxt(indata){
            // nx99
            console.log(" indata ", indata);
            var poss = this.onlytrue.findIndex(e=> e.ident == indata.ident)
            console.log("poss is ", poss);
                   console.log("before only true are ",this.onlytrue)
            this.onlytrue[poss].shown = 'n';
            this.onlytrue[poss+1].shown = 'y';

             console.log("after only true are ",this.onlytrue)
     
    
        },

        async getAppliDayCareData(){
            let respData = await axios({
                        method: "get",
                        url:this.API_Service_MASTER() + "plan/get-day-care-procedures", 
                        headers: {
                            "Content-Type": "application/json", 
                            "x-access-token": this.$store.getters.successToken,
                        }
                    }).then(function(response) {
                        let _resp = response.data.data
                        return _resp
                    }).catch(function(error) {});

            for(let i = 0;i < respData.length ; i++){
                this.selectAppliDayCarePro.push(respData[i].name)
            }
            if(this.planItems[0].hasOwnProperty("dcph")){
                this.planItems[0].dcph.length == 0 ? this.dccappliDayCarePro = this.selectAppliDayCarePro : ''
            }
        },


            returnPlanPge(){

                if(this.btnName==="EditPlnFrPlcy"||this.btnName==="ConfgrPlnFrPlcy"){
            
                  this.$router.push('/product/createditplanforpolicy')
            
                }

                if(this.btnName==="EditNwRtlPln"||this.btnName==="CnfgrNwRtlPln"){
                    
                    this.$router.push('/product/createditplan')
                }

            },
            close_cancelDialog(){
                this.confirmDialogCancel=false
            },
            submitCnfrm(){
                // console.log(this.showIPHAccor,"this.showIPHAccor",this.inPhDta,"this.inPhDta")
                // console.log(this.showMCAccor,"this.showMCAccor",this.mcDta,"this.mcDta")
                // console.log(this.shwExpnsnFtb,"this.shwExpnsnFtb",this.ftbDta,"this.ftbDta")

                if(this.showIPDAccor===true && this.IPDData.length===0 ){
                    this.showToast("Few Coverages Details Missing",this.TOST().WARNING);
                    return
                }
                if(this.showODAccor===true && this.ODData.length===0 ){
                    this.showToast("Few Coverages Details Missing",this.TOST().WARNING);
                    return
                }
                if(this.showBSCAccor===true && this.BSCData.length===0 ){
                    this.showToast("Few Coverages Details Missing",this.TOST().WARNING);
                    return
                }
                if(this.showDTAccor===true && this.DTData.length===0 ){
                    this.showToast("Few Coverages Details Missing",this.TOST().WARNING);
                    return
                }
                if(this.showABAccor===true && this.ABData.length===0 ){
                    this.showToast("Few Coverages Details Missing",this.TOST().WARNING);
                    return
                }
                
                if(this.showIPHAccor===true && this.inPhDta.length===0 ){
                    this.showToast("Few Coverages Details Missing",this.TOST().WARNING);
                    return
                }

                if(this.showMCAccor===true && this.mcDta.length===0 ){
                    this.showToast("Few Coverages Details Missing",this.TOST().WARNING);
                    return
                }

                if(this.showHCBCAccor===true && this.formDataHCBACI.length===0 ){
                    this.showToast("Few Coverages Details Missing",this.TOST().WARNING);
                    console.log("Toast from Hospital cash benefit for accompanying an Insured child if")
                    return
                }
                if(this.showHCBAccor===true && this.formDataHCB.length===0 ){
                    this.showToast("Few Coverages Details Missing",this.TOST().WARNING);
                    console.log("Toast from Hospital Cash Benefit  if")
                    return
                }

                if(this.showHedAccor===true && this.fhed.length===0 ){
                    this.showToast("Few Coverages Details Missing",this.TOST().WARNING);
                    console.log("Toast from HED  if")
                    return
                }
                if(this.shwExpnsnCi===true && this.ciData.length===0 ){
                    this.showToast("Few Coverages Details Missing",this.TOST().WARNING);
                    console.log("Toast from Critical Illness if")
                    return
                }

                if(this.showPHAccor===true && this.formDataPH.length===0 ){
                    this.showToast("Few Coverages Details Missing",this.TOST().WARNING);
                    console.log("Toast from Prolonged Hospitalizations if")
                    return
                }

                if(this.shwExpnsnOpdDT===true && this.opddtDta.length===0 ){
                    this.showToast("Few Coverages Details Missing",this.TOST().WARNING);
                    console.log("Toast from OPD Dental Treatment if")
                    return
                }

                if(this.shwExpnsnEaa===true && this.eaaDta.length===0){
                    this.showToast("Few Coverages Details Missing",this.TOST().WARNING);
                    console.log("Toast from  Emergency Air Ambulance if")
                    return
                }

                if(this.shwExpnsnBdoc===true && this.bdcArr.length===0){
                    this.showToast("Few Coverages Details Missing",this.TOST().WARNING);
                    console.log("Toast from  Baby Day One Care   if")
                    return
                }

                if(this.shwExpnsnNbbc===true && this.dbbcArr.length===0){
                    this.showToast("Few Coverages Details Missing",this.TOST().WARNING);
                    console.log("Toast from New Born Baby if")
                    return
                }

                if(this.showRbAccor===true && this.rbDta.length===0){
                    this.showToast("Few Coverages Details Missing",this.TOST().WARNING);
                    console.log("Toast from Restore Benefit if")
                    return
                }

                if(this.shwExpnsnAmbc===true && this.ambcDta.length===0){
                    this.showToast("Few Coverages Details Missing",this.TOST().WARNING);
                    console.log("Toast from Ambulance Cover if")
                    return
                }

                if(this.showDCAccor===true && this.formDataDC.length===0){
                    this.showToast("Few Coverages Details Missing",this.TOST().WARNING);
                    console.log("Toast from Day Care Coverage if")
                    return
                }

                if(this.shwExpnsnPostHosp===true && this.poDta.length===0){
                    this.showToast("Few Coverages Details Missing",this.TOST().WARNING);
                    console.log("Toast from Post-Hospitalization if")
                    return
                }

                if(this.shwExpnsnPreHosp===true && this.prehDta.length===0){
                    this.showToast("Few Coverages Details Missing",this.TOST().WARNING);
                    console.log("Toast from Pre-Hospitalization if")
                    return
                }

                if(this.shwExpnsnFtb===true && this.ftbDta.length===0){
                    this.showToast("Few Coverages Details Missing",this.TOST().WARNING);
                    console.log("Toast from Family Transportation Benefit if")
                    return
                }

                if(this.shwExpnsnCtc===true && this.f1.length===0){
                    this.showToast("Few Coverages Details Missing",this.TOST().WARNING);
                    console.log("Toast from Compassionate Travel Coverage if")
                    return
                }


                if(this.shwExpnsnGc===true && this.gcData.length===0 ){
                    this.showToast("Few Coverages Details Missing",this.TOST().WARNING);
                    console.log("Toast from Global Cover if")
                    return
                }

                if(this.showNAAccor===true && this.formDataNA.length===0 ){
                    this.showToast("Few Coverages Details Missing",this.TOST().WARNING);
                    console.log("Toast from Nursing Allowance if")
                    return
                }

                if(this.shwExpnsnOpdT===true && this.OpdtDta.length===0 ){
                    this.showToast("Few Coverages Details Missing",this.TOST().WARNING);
                    console.log("Toast from OPD Treatment if")
                    return
                }

                if(this.showVCAccor===true && this.formDataVC.length===0 ){
                    this.showToast("Few Coverages Details Missing",this.TOST().WARNING);
                    console.log("Toast from Vision Care if")
                    return
                }

                if(this.shwExpnsnHcuc===true && this.HcucDta.length===0 ){
                    this.showToast("Few Coverages Details Missing",this.TOST().WARNING);
                    console.log("Toast from Health Check-up Coverage  if")
                    return
                }

                if(this.showADBAccor===true && this.formDataADB.length===0 ){
                    this.showToast("Few Coverages Details Missing",this.TOST().WARNING);
                    console.log("Toast from Accidental Death Benefit  if")
                    return
                }

                if(this.showPTDAccor===true && this.formDataPTD.length===0 ){
                    this.showToast("Few Coverages Details Missing",this.TOST().WARNING);
                    console.log("Toast from Permanent Total Disability if")
                    return
                }

                if(this.shwExpnsnTmr===true && this.tmrData.length===0 ){
                    this.showToast("Few Coverages Details Missing",this.TOST().WARNING);
                    console.log("Toast from Transportation of mortal remains if")
                    return
                }

                if(this.shwExpnsnFe===true && this.feData.length===0 ){
                    this.showToast("Few Coverages Details Missing",this.TOST().WARNING);
                    console.log("Toast from Funeral Expenses if")
                    return
                }

                if(this.showEBAccor===true && this.formDataEB.length===0 ){
                    this.showToast("Few Coverages Details Missing",this.TOST().WARNING);
                    console.log("Toast from Education Benefits if")
                    return
                }

                if(this.showITAccor===true && this.formDataIT.length===0 ){
                    this.showToast("Few Coverages Details Missing",this.TOST().WARNING);
                    console.log("Toast from Infertility Treatment if")
                    return
                }

                if(this.shwExpnsnVctn===true && this.f2.length===0 ){
                    this.showToast("Few Coverages Details Missing",this.TOST().WARNING);
                    console.log("Toast from Vaccination if")
                    return
                }
                
                if(this.showHacAccor===true && this.fhac.length===0 ){
                    this.showToast("Few Coverages Details Missing",this.TOST().WARNING);
                    console.log("Toast from  Hearing Aid Cover if")
                    return
                }

                this.confirmDialogCancel=true

            },
        submitAllCvrgs(){
            this.confirmDialogCancel=false
            
            let submitdata =[
                ...this.f2,
                ...this.f1,
                ...this.tmrData,
                ...this.feData,
                ...this.OpdtDta,
                ...this.HcucDta,
                ...this.opddtDta,
                ...this.eaaDta,
                ...this.formDataDC,
                ...this.formDataPH,
                ...this.formDataVC,
                ...this.formDataIT,
                ...this.formDataADB,
                ...this.formDataPTD,
                ...this.formDataEB,
                ...this.formDataNA,
                ...this.formDataHCB,
                ...this.formDataHCBACI,
                ...this.fhed,
                ...this.fhac,
                ...this.bdcArr,
                ...this.dbbcArr,
                ...this.gcData,
                ...this.ciData,
                ...this.inPhDta,
                ...this.IPDData,
                ...this.ODData,
                ...this.BSCData,
                ...this.DTData,
                ...this.ABData,
                ...this.mcDta,
                ...this.rbDta,
                ...this.ambcDta,
                ...this.poDta,
                ...this.prehDta,
                ...this.ftbDta      
            ]

            // for(let i=0;i<submitdata.length;i++){
      	
            //     if(submitdata[i].hasOwnProperty('relationShipsCoveredDetails')){
            //                 submitdata[i].relationShipsCoveredDetails=submitdata[i].relationShipsCoveredDetails.map(e=> {
            //                     return{
            //                             active: e.active,
            //                             applicableValue: e.applicableValue,
            //                             checkbox: e.checkbox,
            //                             coPay: e.coPay,
            //                             coverType: e.coverType,
            //                             flatAmount:e.flatAmount,
            //                             roomRentLimit: e.roomRentLimit,
            //                             siPercent: e.siPercent,
            //                             siSubLimit:  e.siSubLimit,
            //                             waitingPeriod: e.waitingPeriod,
            //                     }
            //                 }) 
            //     }
            // }


            let ProcessFrmData=new Promise((resolve,reject) =>{
                let counter=0
                for(let i=0;i<submitdata.length;i++){
                    if(submitdata[i].hasOwnProperty('relationShipsCoveredDetails')){
                            submitdata[i].relationShipsCoveredDetails=submitdata[i].relationShipsCoveredDetails.map(e=>                {
                                    return{
                                            active: e.active,
                                            applicableValue: e.applicableValue,
                                            checkbox: e.checkbox,
                                            coPay: e.coPay,
                                            coverType: e.coverType,
                                            flatAmount:e.flatAmount,
                                            roomRentLimit: e.roomRentLimit,
                                            siPercent: e.siPercent,
                                            siSubLimit:  e.siSubLimit,
                                            waitingPeriod: e.waitingPeriod,
                                    }
                                }) 
                    }
                    counter++
                    if(counter===submitdata.length){
                        resolve()
                    }
                }
                        
            })
            ProcessFrmData.then(()=>{
                    console.log("success")
                    console.log(submitdata,"submitdata")
                    let apiURL=''
                        if(this.btnName==="ConfgrPlnFrPlcy"){
                            apiURL="group-plan/submit/"+this.plan._id
                        }else if(this.btnName==="CnfgrNwRtlPln"){
                            apiURL="retail-plan/submit/"+this.plan._id
                        }
                    // let apiURL="group-plan/submit/"+this.plan._id
                    this.POST(1,apiURL,submitdata,(res,error)=>{
                        if(!error){
                            console.log("PUT APi response|-> ctcvac ", res)
                            this.showToast(res.data.msg,this.TOST().SUCCESS)
                        }else{
                            console.log("PUT APi error response|->ctcvac", error)
                            this.showToast(error.response.data.msg ,this.TOST().ERROR);
                        }    
                    })
            }).catch((e)=>{
                console.log(e,"====>Error")
            })


        },
        chngGcCb(cmgfrm){
            this.shwGcFltAmt=false
            this.shwGcPrcntSi=false
            this.shwGcPrcntClmAmt=false
            this.shwGcPrcntPrvSi=false,
            this.gcPrcntPrvSi=""
            this.gcFlatAmt=""
            this.gcPrcntSi=""
            this.gcPrcntClmAmt=""
            this.gcClaimPaymentBasis=''
            this.shwgcCvrgAplcblDrtn=false
            this.gcCad=''
            this.gcSubLimitBasis=[]
            this.shwGcAplcblClmPyot=false
            this.gcAplcblClmPyot=''
            if(cmgfrm===1){
                this.$refs.gcForm.resetValidation()
            }
            console.log(this.gcCoverageBasis,"coverageBasis")
            if(this.gcCoverageBasis==="Over & above SI"||this.gcCoverageBasis==="Upto SI with sub limit"){
                this.shwGcSlb=true
            }else{
                this.shwGcSlb=false
            }
        },
        chngGcSubLmtBasis(){
            this.shwGcFltAmt=false
            this.shwGcPrcntSi=false
             this.shwGcPrcntClmAmt=false
              this.shwGcAplcblClmPyot=false
              this.shwGcPrcntPrvSi=false
            console.log(this.gcSubLimitBasis,"gcSubLimitBasis selected")
            for(let i=0;i<=this.gcSubLimitBasis.length;i++){
                if(this.gcSubLimitBasis[i]==="Flat amount"){
                    this.shwGcFltAmt=true
                }
                if(this.gcSubLimitBasis[i]==="% of SI"){
                    this.shwGcPrcntSi=true
                }
                if(this.gcSubLimitBasis[i]==="% of claimed amount"){
                    this.shwGcPrcntClmAmt=true
                }
                if(this.gcSubLimitBasis[i]==="% of previous yr SI"){
                    this.shwGcPrcntPrvSi=true
                }
            }
            if(this.gcSubLimitBasis.length>1){
                this.shwGcAplcblClmPyot=true
            }
            if (this.shwGcAplcblClmPyot===false){
                this.gcAplcblClmPyot=""
            }
            if (this.shwGcFltAmt===false){
                this.gcFlatAmt=""
            }
            if (this.shwGcPrcntSi===false){
                this.gcPrcntSi=""
            }
            if (this.shwGcPrcntClmAmt===false){
                this.gcPrcntClmAmt=""
            }
            if (this.shwGcPrcntPrvSi===false){
                this.gcPrcntPrvSi=""
            }
        },
        chngGcClaimPaymentBasis(){
            console.log(this.gcClaimPaymentBasis,"gcClaimPaymentBasis")
            this.shwgcCvrgAplcblDrtn=false
            console.log(this.gcClaimPaymentBasis,"gcClaimPaymentBasis selected")
            // for(let i=0;i<=this.gcClaimPaymentBasis.length;i++){
                if(this.gcClaimPaymentBasis==="Duration basis"){
                    this.shwgcCvrgAplcblDrtn=true
                }
            // }
            if(this.shwgcCvrgAplcblDrtn===false){
                this.gcCad=''
            }
        },
        cichangeOnover(cmgfrm){
            this.shwciFltAmt=false
            this.shwCiPrcntSi=false
            this.shwCiPrcntClmAmt=false
            this.shwCiPrcntPrvSi=false
            this.ciPrcntPrvSi=""
            this.ciFlatAmt=""
            this.ciPrcntSi=""
            this.ciPrcntClmAmt=""
            this.ciclaimPaymentBasis=''
            this.shwCiCvrgAplcblDrtn=false
            this.ciCad=''
            this.cisubLimitBasis=[]
            this.shwCiAplcblClmPyot=false
            this.ciAplcblClmPyot=''
            if(cmgfrm===1){
                this.$refs.ciForm.resetValidation()
            }
            console.log("flat---->",this.cicoverageBasis)
            if(this.cicoverageBasis== 'Over & above SI' || this.cicoverageBasis== 'Upto SI with sub limit'){
                this.cisubBasisshow=true
            }else{
                this.cisubBasisshow=false
            }
        },
        chngCiSubLmtBasis(){
            this.shwciFltAmt=false
            this.shwCiPrcntSi=false
             this.shwCiPrcntClmAmt=false
              this.shwCiAplcblClmPyot=false
              this.shwCiPrcntPrvSi=false
            console.log(this.cisubLimitBasis,"cisubLimitBasis selected")
            for(let i=0;i<=this.cisubLimitBasis.length;i++){
                if(this.cisubLimitBasis[i]==="Flat amount"){
                    this.shwciFltAmt=true
                }
                if(this.cisubLimitBasis[i]==="% of SI"){
                    this.shwCiPrcntSi=true
                }
                if(this.cisubLimitBasis[i]==="% of claimed amount"){
                    this.shwCiPrcntClmAmt=true
                }
                if(this.cisubLimitBasis[i]==="% of previous yr SI"){
                    this.shwCiPrcntPrvSi=true
                }
            }
            if(this.cisubLimitBasis.length>1){
                this.shwCiAplcblClmPyot=true
            }
            if (this.shwCiAplcblClmPyot===false){
                this.ciAplcblClmPyot=""
            }
            if (this.shwciFltAmt===false){
                this.ciFlatAmt=""
            }
            if (this.shwCiPrcntSi===false){
                this.ciPrcntSi=""
            }
            if (this.shwCiPrcntClmAmt===false){
                this.ciPrcntClmAmt=""
            }
            if (this.shwCiPrcntPrvSi===false){
                this.ciPrcntPrvSi=""
            }
        },
        chngCiClaimPaymentBasis(){
             console.log(this.ciclaimPaymentBasis,"ciclaimPaymentBasis")
            this.shwCiCvrgAplcblDrtn=false
            console.log(this.ciclaimPaymentBasis,"ciclaimPaymentBasis selected")
            // for(let i=0;i<=this.ciclaimPaymentBasis.length;i++){
                if(this.ciclaimPaymentBasis==="Duration basis"){
                    this.shwCiCvrgAplcblDrtn=true
                }
            // }
            if(this.shwCiCvrgAplcblDrtn===false){
                this.ciCad=''
            }
        },
        changeIPCoverage(event){
            // console.log(" event ++++++++++======>>",event)
            // this.IPCoverCheck.push(event)
            console.log(" changeIPCoverage ++++++++++======>>",this.IPCoverCheck)
            // showMinAgeField
        },
        changeVcSubLimBasis(event){ 
            // chngIfSubLmtBasis
            this.showVcPercentSi = false
            this.showVcFlatAmnt = false
            this.showVcPercClaimAmt = false
            this.showVcApplicClaimPay = false 
            this.showVcPrvPercentSi=false
            this.showVCOpdSi = false
            this.showVCAdSi = false

            for(let i = 0; i<= event.length; i++){
                if(event[i] !== undefined){
                    event[i] == '% of SI' ? this.showVcPercentSi = true : ''
                    event[i] == 'Flat amount' ? this.showVcFlatAmnt = true : ''
                    event[i] == '% of claimed amount' ? this.showVcPercClaimAmt = true : ''
                    event[i] == '% of previous yr SI' ? this.showVcPrvPercentSi = true : ''
                    event[i] == '% of OPD SI' ? this.showVCOpdSi = true : ''
                    event[i] == '% of AD SI' ? this.showVCAdSi = true : ''
                }
            }

            event.length > 1 ? this.showVcApplicClaimPay = true : ''
            this.showVcPercentSi == false ? this.vcPercentSI = '' : ''
            this.showVcFlatAmnt == false ? this.vcFlatAmountTxtField = '' : ''
            this.showVcPercClaimAmt == false ? this.vcPercentClaimAmt = '' : ''
            this.showVcPrvPercentSi == false ? this.vcPrvPercentSI = '' : ''
            this.showVCOpdSi == false ? this.VCOpdSi = '' : ''
            this.showVCAdSi == false ? this.VCAdSi = '' : ''
        },

        changeADBClaimPayBasis(event){
            this.showADBCovrageApplicDur = false
            // for(let i = 0; i<= event.length; i++){
                event !== undefined ? event == 'Duration basis' ? this.showADBCovrageApplicDur = true : '' : ''
            // } 
            this.showADBCovrageApplicDur === false ? this.ADBCovrageApplicDur = '' : ''
        },
        changePTDClaimPayBasis(event){
            this.showPTDCovrageApplicDur = false
            // for(let i = 0; i<= event.length; i++){
                event !== undefined ? event== 'Duration basis' ? this.showPTDCovrageApplicDur = true : '' : ''
            // } 
            this.showPTDCovrageApplicDur === false ? this.PTDCovrageApplicDur = '' : ''
        },
        
        changeNAClaimPayBasis(event){
            this.showNACovrageApplicDur = false
            // for(let i = 0; i<= event.length; i++){
                event !== undefined ? event == 'Duration basis' ? this.showNACovrageApplicDur = true : '' : ''
            // } 
            this.showNACovrageApplicDur === false ? this.NACovrageApplicDur = '' : ''
        },
        
        changeEBClaimPayBasis(event){
            // console.log(" changeADBClaimPayBasis ++++++++++======>>",event)
            this.showEBCovrageApplicDur = false
            // for(let i = 0; i<= event.length; i++){
                event !== undefined ? event== 'Duration basis' ? this.showEBCovrageApplicDur = true : '' : ''
            // } 
            this.showEBCovrageApplicDur === false ? this.EBCovrageApplicDur = '' : ''
        },
        
        changeVcClaimPayBasis(event){
            // console.log(" changeDccClaimPayBasis ++++++++++======>>",event)
            this.showVcCovrageApplicDur = false
            // for(let i = 0; i<= event.length; i++){
                event !== undefined ? event == 'Duration basis' ? this.showVcCovrageApplicDur = true : '' : ''
            // } 
            this.showVcCovrageApplicDur === false ? this.vcCovrageApplicDur = '' : ''
        },
            
       save_CI_updated({btntext,dta}){
            if(btntext==='GC'){
                    if (!this.$refs.gcForm.validate()){
                        this.$refs.gcForm.validate()
                        this.showToast("Please Fill Required Fields",this.TOST().WARNING);
                        return
                    }
                }else if(btntext==='CI'){
                   if (!this.$refs.ciForm.validate()){
                        this.$refs.ciForm.validate()
                        this.showToast("Please Fill Required Fields",this.TOST().WARNING);
                        return
                    } 
                }
                let formData={
                    // coverageCode: "GC",
                    coverageType:this.gcCoverageType,
                    coverageBasis:this.gcCoverageBasis,
                    subLimitBasis:this.gcSubLimitBasis,
                    claimPaymentBasis:this.gcClaimPaymentBasis,
                    flatAmount:this.gcFlatAmt,
                    perOfSi: this.gcPrcntSi,
                    perOfPreviousSi:this.gcPrcntPrvSi,
                    perOfClaimAmount: this.gcPrcntClmAmt,
                    applicableClaimPayout: this.gcAplcblClmPyot,
                    coverageApplicableDuration: this.gcCad,
                    waitingPeriod:this.gcWaitingPeriod,
                    waitingPeroidType:this.gcwaitingPeriodDrop,
                    relationship:this.gccheckbox,
                    minHospitalization:this.gcMnmHspitlztnDrtn,
                    countryOfDiseaseDetection:this.gcCntryofDseDtctn,
                    irdaUanNo: this.GCIrdaUINNo,
                    externalRefNo: this.GCExtRefNo,
                    alternateName: this.GCCoverAltName,
                    gcCoverage: this.GCGcCover,
                    exclusions: this.GCExclus,
                    remarks: this.GCRemarks,
                    conditions: this.GCCondField,
                }
                if(this.gccheckbox){
                    formData.relationShipsCovered=dta.relationShipsCovered
                    let activedataGc=dta.relationShipsCoveredDetails.map(e=> {
                        return{
                                active: e.active,
                                applicableValue: e.applicableValue,
                                checkbox: e.checkbox,
                                coPay: e.coPay,
                                coverType: e.coverType,
                                flatAmount:e.flatAmount,
                                roomRentLimit: e.roomRentLimit,
                                siPercent: e.siPercent,
                                siSubLimit:  e.siSubLimit,
                                waitingPeriod: e.waitingPeriod,
                        }
                    }) 
                    console.log('%c activedataGC=>  ',"color: yellow",activedataGc);
                    formData.relationShipsCoveredDetails=activedataGc
                    // alert("GC Form data")
                }
                console.log(formData,"=========GC Form Data")

                let formData2={
                    // coverageCode: "CI",
                    coverageType:this.cicoverageType,
                    coverageBasis:this.cicoverageBasis,
                    subLimitBasis:this.cisubLimitBasis,
                    claimPaymentBasis:this.ciclaimPaymentBasis,
                    flatAmount:this.ciFlatAmt,
                    perOfSi: this.ciPrcntSi,
                    perOfPreviousSi:this.ciPrcntPrvSi,
                    perOfClaimAmount: this.ciPrcntClmAmt,
                    applicableClaimPayout: this.ciAplcblClmPyot,
                    coverageApplicableDuration: this.ciCad,
                    waitingPeriod:this.ciwaitingPeriod,
                    relationship:this.ciisSelectBox,
                    // relationship:false,
                    survivalPeriod:this.ciSurvivalPeriod,
                    coveredCriticalIllness:this.ciCoveredCriticalIllness,
                    // relationShipsCovered:"test",
                    // relationShipsCoveredDetails:"test"
                }
                if(this.ciisSelectBox){
                    formData2.relationShipsCovered=dta.relationShipsCovered
                    let activedataCi=dta.relationShipsCoveredDetails.map(e=> {
                        return{
                                active: e.active,
                                applicableValue: e.applicableValue,
                                checkbox: e.checkbox,
                                coPay: e.coPay,
                                coverType: e.coverType,
                                flatAmount:e.flatAmount,
                                roomRentLimit: e.roomRentLimit,
                                siPercent: e.siPercent,
                                siSubLimit:  e.siSubLimit,
                                waitingPeriod: e.waitingPeriod,
                        }
                    }) 
                    console.log('%c activedataCI=>  ',"color: Red",activedataCi);
                    formData2.relationShipsCoveredDetails=activedataCi
                    // alert("CI Form data")
                }
                console.log(formData2,"=========CI Form Data")

                this.grpCtcVctn(formData,response=>{
                    console.log('GC arr push is ? ',response)
                    if(btntext==='GC'){
                        this.gcData = []
                        if(response){
                            console.log(formData,"Before GC Push")
                           formData.coverageCode="GC"
                            this.gcData.push(formData)
                        }
                    }else{
                        
                        if(this.gccheckbox){
                                // alert("GC Data blank")
                                console.log(this.gcData,"GC Data")
                                this.gcData[0].relationShipsCoveredDetails= this.mapRelFrmDta(this.gcData[0].relationShipsCoveredDetails)
                               console.log('this.gcData[0].relationShipsCoveredDetails ? ',this.gcData[0].relationShipsCoveredDetails)  
                            }
                    }
                    console.log("fd for api is ", formData)
                    this.grpCtcVctn(formData2,res=>{
                            console.log('CI arr push is ? ',res)   
                        if(btntext==='CI'){
                            this.ciData = []
                            if(res){
                                console.log(formData2,"Before CI Push")
                                formData2.coverageCode="CI"
                                this.ciData.push(formData2)
                            }
                        }else{
                           if(this.ciisSelectBox){
                                // alert("CI Data blank")
                                this.ciData[0].relationShipsCoveredDetails= this.mapRelFrmDta(this.ciData[0].relationShipsCoveredDetails)
                            }
                        }
                        var finalData = [
                            ...this.gcData, ...this.ciData
                        ]
                        console.log(finalData,"finalData")
                        
                        let apiURL=''
                            if(this.btnName==="ConfgrPlnFrPlcy"){
                                apiURL="group-plan/update-group-gcci/"+this.plan._id
                            }else if(this.btnName==="CnfgrNwRtlPln"){
                                apiURL="retail-plan/update-retail-gcci/"+this.plan._id
                            }

                        this.PUT(1,apiURL,finalData,(res,error)=>{
                            if(!error){
                                console.log("PUT APi response|-> ctcvac ", res)
                               if(btntext==='GC'){
                                    this.showToast("Global Cover Coverage details has been updated successfully",this.TOST().SUCCESS)
                                }else if(btntext==='CI'){
                                    this.showToast("Critical Illness Coverage details has been updated successfully",this.TOST().SUCCESS)
                                }
                            }else{
                                console.log("PUT APi error response|->ctcvac", error)
                                this.showToast(error.response.data.msg ,this.TOST().ERROR);
                            }    
                        })
                    })
                })
        },
        mapRelFrmDta(incmgArry){
            // alert("Map Function")
            console.log(incmgArry,"++++++incmgArry")
            return incmgArry.map(e=> {
                return{
                    active: e.active,
                    applicableValue: e.applicableValue,
                    checkbox: e.checkbox,
                    coPay: e.coPay,
                    coverType: e.coverType,
                    flatAmount:e.flatAmount,
                    roomRentLimit: e.roomRentLimit,
                    siPercent: e.siPercent,
                    siSubLimit:  e.siSubLimit,
                    waitingPeriod: e.waitingPeriod,
                }
            }) 
        },
        changeDccClaimPayBasis(event){
            // console.log(" changeDccClaimPayBasis ++++++++++======>>",event)
            this.showDccCovrageApplicDur = false
            // for(let i = 0; i<= event.length; i++){
                event !== undefined ? event == 'Duration basis' ? this.showDccCovrageApplicDur = true : '' : ''
            // } 
            this.showDccCovrageApplicDur === false ? this.dccCovrageApplicDur = '' : ''
        },
        changePHClaimPayBasis(event){
            // console.log(" changePHClaimPayBasis ++++++++++======>>",event)
            this.showCovrageApplicDur = false
            // for(let i = 0; i<= event.length; i++){
                event !== undefined ? event == 'Duration basis' ? this.showCovrageApplicDur = true : '' : ''
            // }
            this.showCovrageApplicDur === false ? this.PHCovrageApplicDur = '' : ''
        },

        subLimitBasisValChange(event){
            this.showPercentSi = false
            this.showFlatAmnt = false
            this.showPercClaimAmt = false
            this.showDccApplicClaimPay = false
            this.showPrvPercentSi = false
            this.showDCCOpdSi = false
            this.showDCCAdSi = false

            for(let i = 0; i<= event.length; i++){
                if(event[i] !== undefined){
                    event[i] == '% of SI' ? this.showPercentSi = true : ''
                    event[i] == 'Flat amount' ? this.showFlatAmnt = true : ''
                    event[i] == '% of claimed amount' ? this.showPercClaimAmt = true : ''
                    event[i] == '% of previous yr SI' ? this.showPrvPercentSi = true : ''
                    event[i] == '% of OPD SI' ? this.showDCCOpdSi = true : ''
                    event[i] == '% of AD SI' ? this.showDCCAdSi = true : ''
                }
            }

            event.length > 1 ? this.showDccApplicClaimPay = true : ''
            this.showPercentSi == false ? this.percentSI = '' : ''
            this.showFlatAmnt == false ? this.flatAmountTxtField = '' : ''
            this.showPercClaimAmt == false ? this.percentClaimAmt = '' : ''
            this.showPrvPercentSi == false ? this.PrvpercentSI = '' : ''
            this.showDCCOpdSi == false ? this.DCCOpdSi = '' : ''
            this.showDCCAdSi == false ? this.DCCAdSi = '' : ''
        },

        chngInPtntHzptnSubLmtBasis(event){
            this.showIPHPercentSi = false
            this.showIPHFlatAmnt = false
            this.showIPHPercClaimAmt = false
            this.IPHshowAppli = false
            this.showIPHPrvPercentSi = false
            // New Fields Added 
            this.showIPHOpdSi = false
            this.showIPHAdSi = false

            for(let i = 0; i<= event.length; i++){
                if(event[i] !== undefined){
                    event[i] == '% of SI' ? this.showIPHPercentSi = true : ''
                    event[i] == 'Flat amount' ? this.showIPHFlatAmnt = true : ''
                    event[i] == '% of claimed amount' ? this.showIPHPercClaimAmt = true : ''
                    event[i] == '% of previous yr SI' ? this.showIPHPrvPercentSi = true : ''
                    // New Fields Added 
                    event[i] == '% of OPD SI' ? this.showIPHOpdSi = true : ''
                    event[i] == '% of AD SI' ? this.showIPHAdSi = true : ''
                }
            }

            event.length > 1 ? this.IPHshowAppli = true : ''
            this.showIPHPercentSi == false ? this.IPHPercentSI = '' : ''
            this.showIPHFlatAmnt == false ? this.IPHFlatAmountTxtField = '' : ''
            this.showIPHPercClaimAmt == false ? this.IPHPercentClaimAmt = '' : ''
            this.showIPHPrvPercentSi == false ? this.IPHPrvPercentSI = '' : ''
            this.showIPHOpdSi == false ? this.IPHOpdSi = '' : ''
            this.showIPHAdSi == false ? this.IPHAdSi = '' : ''
        },

        changeIPDSubLmtBasis(event){
            this.showIPDPercentSi = false
            this.showIPDFlatAmnt = false
            this.showIPDPercClaimAmt = false
            this.showIPDAppli = false
            this.showIPDPrvPercentSi = false
            // New Fields Added 
            this.showIPDOpdSi = false
            this.showIPDAdSi = false

            for(let i = 0; i<= event.length; i++){
                if(event[i] !== undefined){
                    event[i] == '% of SI' ? this.showIPDPercentSi = true : ''
                    event[i] == 'Flat amount' ? this.showIPDFlatAmnt = true : ''
                    event[i] == '% of claimed amount' ? this.showIPDPercClaimAmt = true : ''
                    event[i] == '% of previous yr SI' ? this.showIPDPrvPercentSi = true : ''
                    // New Fields Added 
                    event[i] == '% of OPD SI' ? this.showIPDOpdSi = true : ''
                    event[i] == '% of AD SI' ? this.showIPDAdSi = true : ''
                }
            }

            event.length > 1 ? this.showIPDAppli = true : ''
            this.showIPDPercentSi == false ? this.IPDPercentSI = '' : ''
            this.showIPDFlatAmnt == false ? this.IPDFlatAmountTxtField = '' : ''
            this.showIPDPercClaimAmt == false ? this.IPDPercentClaimAmt = '' : ''
            this.showIPDPrvPercentSi == false ? this.IPDPrvPercentSI = '' : ''
            this.showIPDOpdSi == false ? this.IPDOpdSi = '' : ''
            this.showIPDAdSi == false ? this.IPDAdSi = '' : ''
        },

        chngInPtntHzptnClaimPaymentBasis(event){
            this.showIPHCovrageApplicDur = false
            // for(let i = 0; i<= event.length; i++){
                event !== undefined ? event == 'Duration basis' ? this.showIPHCovrageApplicDur = true : '' : ''
            // }
            this.showIPHCovrageApplicDur === false ? this.IPHCovrageApplicDur = '' : ''
        },
        changeIPDClaimPaymentBasis(event){
            this.showIPDCovrageApplicDur = false
            event !== undefined ? event == 'Duration basis' ? this.showIPDCovrageApplicDur = true : '' : ''
            this.showIPDCovrageApplicDur === false ? this.IPDCovrageApplicDur = '' : ''
        },

    //     
    getPlanbyId(planId){
        console.log(planId," Incoming PlanId for grp coverage")
        let apiURL=''
            if(this.btnName==="EditPlnFrPlcy"||this.btnName==="ConfgrPlnFrPlcy"){
                apiURL="group-plan/get-group-plan/"+planId
            }else if( this.btnName==="EditNwRtlPln"||this.btnName==="CnfgrNwRtlPln"){
                apiURL="retail-plan/get-retail-plan/"+planId
            }

            this.GET(1,apiURL,(res,error)=>{
                    try{
                        if(!error){
                            console.log(`GET APi response|->plan-details-by-id ${this.$store.getters.fetchPlcyIdForPlnData.plan_Typ}`, res.data.data)
                            let pdtDta={}
                            pdtDta=res.data.data
                            this.planItems.push(pdtDta)
                            this.plan=this.planItems[0]
                            console.log(this.plan,"get-group-plan details ")
                            console.log("this is data for pdtDta",pdtDta);
                            if(pdtDta.hasOwnProperty("applicableCoverages")){
                                console.log("shwExpnsnPnl function")
                                this.shwExpnsnPnl(pdtDta.applicableCoverages)
                            }

                            if(pdtDta.hasOwnProperty("dcph")){
                                if(pdtDta.dcph.length !== 0){
                                    this.getDCPHData(pdtDta.dcph)
                                }
                            }

                            if(pdtDta.hasOwnProperty("vcit")){
                                if(pdtDta.vcit.length!=0){
                                    this.getVCITData(pdtDta.vcit)
                                }       
                            }

                            if(pdtDta.hasOwnProperty("adbptd")){
                                if(pdtDta.adbptd.length!=0){
                                    this.getADBPTDData(pdtDta.adbptd)
                                }  
                            }

                            if(pdtDta.hasOwnProperty("ebna")){
                                if(pdtDta.ebna.length!=0){
                                    this.getEBNAData(pdtDta.ebna)
                                }   
                            }

                            if(pdtDta.hasOwnProperty("hcbhcbaci")){
                                if(pdtDta.hcbhcbaci.length!=0){
                                    this.getHCBHCBACIData(pdtDta.hcbhcbaci)
                                }
                            }

                            if(pdtDta.hasOwnProperty("iodabi")){
                                console.log(Object.keys(pdtDta.iodabi).length!=0,"Object.keys(iodabi).length!=0")
                                if(Object.keys(pdtDta.iodabi).length!=0){
                                    this.getIPHData(pdtDta.iodabi)
                                }
                                
                            }
                            if(pdtDta.hasOwnProperty("ipdt")){
                                console.log(Object.keys(pdtDta.ipdt).length!=0,"Object.keys(ipdt).length!=0")
                                if(Object.keys(pdtDta.ipdt).length!=0){
                                    this.getIPDData(pdtDta.ipdt)
                                }
                                
                            }
                            if(pdtDta.hasOwnProperty("organDonar")){
                                console.log(Object.keys(pdtDta.organDonar).length!=0,"Object.keys(organDonar).length!=0")
                                if(Object.keys(pdtDta.organDonar).length!=0){
                                    this.getODData(pdtDta.organDonar)
                                }
                            }
                            if(pdtDta.hasOwnProperty("bsc")){
                                console.log(Object.keys(pdtDta.bsc).length!=0,"Object.keys(bsc).length!=0")
                                if(Object.keys(pdtDta.bsc).length!=0){
                                    this.getBSCData(pdtDta.bsc)
                                }
                            }
                            if(pdtDta.hasOwnProperty("domiciliaryTreatment")){
                                console.log(Object.keys(pdtDta.domiciliaryTreatment).length!=0,"Object.keys(domiciliaryTreatment).length!=0")
                                if(Object.keys(pdtDta.domiciliaryTreatment).length!=0){
                                    this.getDTData(pdtDta.domiciliaryTreatment)
                                }
                            }
                            if(pdtDta.hasOwnProperty("ayushBenefit")){
                                console.log(Object.keys(pdtDta.ayushBenefit).length!=0,"Object.keys(ayushBenefit).length!=0")
                                if(Object.keys(pdtDta.ayushBenefit).length!=0){
                                    this.getABData(pdtDta.ayushBenefit)
                                }
                            }
                            

                            if(pdtDta.hasOwnProperty("maternityCoverage")){
                                if(Object.keys(pdtDta.maternityCoverage).length!=0){
                                    this.getMCData(pdtDta.maternityCoverage)
                                }
                            }

                            if(pdtDta.hasOwnProperty("postHospitalization")){
                                if(Object.keys(pdtDta.postHospitalization).length!=0){
                                    this.postHospitalizationPopulate(pdtDta.postHospitalization)
                                }
                            }
                           
                            if(pdtDta.hasOwnProperty("preHospitalization")){
                                if(Object.keys(pdtDta.preHospitalization).length!=0){
                                    this.preHospitalizationPopulate(pdtDta.preHospitalization)
                                }
                            }

                            if(pdtDta.hasOwnProperty("ftb")){
                                if(Object.keys(pdtDta.ftb).length!=0){
                                    this.ftbPopulate(pdtDta.ftb);
                                }
                            }

                            
                            if(pdtDta.hasOwnProperty("bdcnbbc")){
                                if(pdtDta.bdcnbbc.length!=0){
                                    this.bdcnbbcPopulate(pdtDta.bdcnbbc)
                                }
                            }

                            if(pdtDta.hasOwnProperty("ambulanceCoverage")){
                                if(Object.keys(pdtDta.ambulanceCoverage).length!=0){
                                    this.ambulanceCoveragePopulate(pdtDta.ambulanceCoverage)
                                }
                            }

                            if(pdtDta.hasOwnProperty("ctcvac")){
                                if(pdtDta.ctcvac.length!=0){
                                    this.rebindctcvac(pdtDta.ctcvac)
                                }
                            }

                            if(pdtDta.hasOwnProperty("tmrfe")){
                                if(pdtDta.tmrfe.length!=0){
                                    this.rebindTmrFe(pdtDta.tmrfe)
                                }
                            }
                            
                            if(pdtDta.hasOwnProperty("othcotdea")){
                                if(pdtDta.othcotdea.length!=0){
                                    this.rbndOthcotdea(pdtDta.othcotdea)
                                }
                            }
                             if(pdtDta.hasOwnProperty("gcci")){
                                if(pdtDta.gcci.length!=0){
                                    this.rbndGcci(pdtDta.gcci)
                                }
                            }
                            console.log('for rb ------',pdtDta.restorebenfitCoverage)
                            if(pdtDta.hasOwnProperty("restorebenfitCoverage")){
                                if(Object.keys(pdtDta.restorebenfitCoverage).length!=0){
                                    this.rebindrb(pdtDta.restorebenfitCoverage)
                                }
                            }
                            if(pdtDta.hasOwnProperty("hahed")){
                                if(pdtDta.hahed.length!=0){
                                    this.rebindhahed(pdtDta.hahed)
                                }
                            }
                        }else{
                            console.log("GET APi error response|->plan-details-by-id", error)
                        } 
                    }catch(e){
                         console.log("%c GET APi  errorresponse|-> plan-details-by-id From Catch","color:blue", e)
                    }
                        
                })
    },
    shwExpnsnPnl(arryRecieved){
        console.log(arryRecieved,"arryRecieved Coverages")
        this.shwExpnsnGc=false
        this.shwExpnsnCtc=false
        this.shwExpnsnCi=false
        this.shwExpnsnOpdT=false
        this.shwExpnsnHcuc=false
        this.shwExpnsnOpdDT=false
        this.shwExpnsnEaa=false
        this.shwExpnsnTmr=false
        this.shwExpnsnFe=false
        this.shwExpnsnVctn=false

        this.showMCAccor=false
        this.showNAAccor=false
        this.showDCAccor=false
        this.showHCBAccor=false
        this.showIPHAccor=false
        this.showIPDAccor = false
        this.showODAccor = false
        this.showBSCAccor = false
        this.showDTAccor = false
        this.showABAccor = false
        this.showVCAccor=false
        this.showADBAccor=false
        this.showPTDAccor=false
        this.showEBAccor=false
        this.showITAccor=false
        this.showHCBCAccor=false
        this.showPHAccor=false
        this.shwExpnsnFtb=false
        this.shwExpnsnAmbc=false
        this.shwExpnsnPreHosp=false
        this.shwExpnsnPostHosp=false
        this.shwExpnsnNbbc=false
        this.shwExpnsnBdoc=false
        this.showHacAccor=false
        this.showHedAccor=false
        this.showRbAccor=false

        try{
            for(let i=0; i<arryRecieved.length;i++){
                // console.log(i,'arryRecieved itration', arryRecieved[i])
                arryRecieved[i] !== 'Education Benefit' ? this.EBprimCovrgArray.push(arryRecieved[i]) : ''
                arryRecieved[i] !== 'Permanent Total Disability' ? this.PTDprimCovrgArray.push(arryRecieved[i]) : ''
                if(arryRecieved[i]==="Global Cover"){
                    this.shwExpnsnGc=true
                    this.nextdrive[18].status = true
                }
                if(arryRecieved[i]==="Compassionate Travel Coverage"){
                    this.shwExpnsnCtc=true
                    this.nextdrive[17].status = true
                }
                if(arryRecieved[i]==="Critical Illness"){
                    this.shwExpnsnCi=true
                    this.nextdrive[5].status = true
                }
                if(arryRecieved[i]==="OPD Treatment"){
                    this.shwExpnsnOpdT=true
                    this.nextdrive[20].status = true
                }
                if(arryRecieved[i]==="Health Check-up Coverage"){
                    this.shwExpnsnHcuc=true
                    this.nextdrive[22].status = true
                }
                if(arryRecieved[i]==="OPD Dental Treatment"){
                    this.shwExpnsnOpdDT=true
                    this.nextdrive[7].status = true
                }
                if(arryRecieved[i]==="Emergency Air Ambulance"){
                    this.shwExpnsnEaa=true
                    this.nextdrive[8].status = true
                }
                if(arryRecieved[i]==="Transportation of mortal remains"){
                    this.shwExpnsnTmr=true
                    this.nextdrive[25].status = true
                }
                if(arryRecieved[i]==="Funeral Expenses"){
                    this.shwExpnsnFe=true
                    this.nextdrive[26].status = true
                }
                if(arryRecieved[i]==="Vaccination"){
                    this.shwExpnsnVctn=true
                    this.nextdrive[29].status = true
                }

                if(arryRecieved[i]=== "Maternity Coverage"){
                    this.showMCAccor = true
                        this.nextdrive[1].status = true
                }
                if(arryRecieved[i] == "Nursing Allowance"){
                    this.showNAAccor=true
                    this.nextdrive[19].status = true
                }
                if(arryRecieved[i] == "Day care Coverage"){
                    this.showDCAccor=true
                    this.nextdrive[13].status = true
                }
                if(arryRecieved[i] == "Hospital Cash Benefit"){
                    this.showHCBAccor=true
                        this.nextdrive[3].status = true
                }
                if(arryRecieved[i] == "In-patient hospitalization"){
                    this.showIPHAccor = true
                    this.nextdrive[0].status = true
                }
                //    var _cover = arryRecieved[i]
                //    if(_cover == "In-Patient Treatment" || _cover == "Organ Donor" || _cover == "Bariatric Surgery Cover" || _cover == "In-patient hospitalization"
                //     || _cover == "Domiciliary Treatment" || _cover == "Ayush Benefit"  || _cover == "In-patient Dental"){
                //         let _data = {
                //             text:_cover !== 'In-patient hospitalization' ? _cover : null ,
                //             value:_cover == "In-Patient Treatment" ? 'IP' : _cover == "Organ Donor" ? 'OD' : _cover == "Bariatric Surgery Cover" ? 'BSC' :
                //                 _cover == "Domiciliary Treatment" ? 'DT' :_cover == "Ayush Benefit" ? 'AB' :_cover == "In-patient Dental" ? 'IPDT' : '' ,
                //         }
                //         this.IPCoverCheckArray.push(_data);
                //         // console.log("(((((((( IPCoverCheckArray ))))))))", this.IPCoverCheckArray)

                //        this.showIPHAccor=true
                //        this.nextdrive[0].status = true
                //    }
                
                if(arryRecieved[i] == "Vision Care"){
                    this.showVCAccor=true
                        this.nextdrive[21].status = true
                }
                if(arryRecieved[i] == "Accidental Death Benefit"){
                    this.showADBAccor=true
                    this.nextdrive[23].status = true
                }
                if(arryRecieved[i] == "Permanent Total Disability"){
                    this.showPTDAccor=true
                        this.nextdrive[24].status = true
                }
                if(arryRecieved[i] == "Education Benefit"){
                    this.showEBAccor=true
                        this.nextdrive[27].status = true
                }
                if(arryRecieved[i] == "Infertility Treatment"){
                    this.showITAccor=true
                        this.nextdrive[28].status = true
                }
                if(arryRecieved[i] == "Hospital cash benefit for accompanying an Insured child"){
                    this.showHCBCAccor=true
                    this.nextdrive[2].status = true
                }
                if(arryRecieved[i] == "Prolonged Hospitalization"){
                    this.showPHAccor=true
                        this.nextdrive[6].status = true
                }
                if(arryRecieved[i]==="Family Transportation Benefit"){         
                    this.shwExpnsnFtb=true
                    this.nextdrive[16].status = true
                }
                if(arryRecieved[i]==="Ambulance Coverage"){
                    this.shwExpnsnAmbc=true
                        this.nextdrive[12].status = true
                }
                if(arryRecieved[i]==="Pre-Hospitalization"){
                    this.shwExpnsnPreHosp=true
                        this.nextdrive[15].status = true
                }
                if(arryRecieved[i]==="Post – Hospitalization"){
                        this.shwExpnsnPostHosp=true
                        this.nextdrive[14].status = true
                }
                if(arryRecieved[i]==="New Born baby cover"){
                    this.shwExpnsnNbbc=true
                        this.nextdrive[10].status = true
                }
                if(arryRecieved[i]==="Baby day one cover"){
                    this.shwExpnsnBdoc=true
                        this.nextdrive[9].status = true
                }
                if(arryRecieved[i]==="High End Diagnostics"){
                    this.showHedAccor=true
                        this.nextdrive[4].status = true
                }
                if(arryRecieved[i]==="Hearing Aid"){
                    this.showHacAccor=true
                    this.nextdrive[30].status = true
                }
                if(arryRecieved[i]==="Restore Benefit Coverage"){
                    this.showRbAccor=true
                        this.nextdrive[11].status = true
                }
                if(arryRecieved[i] == "In-patient Dental"){
                    this.showIPDAccor = true
                    this.nextdrive[31].status = true
                }
                if(arryRecieved[i] == "Organ Donor"){
                    this.showODAccor = true
                    this.nextdrive[32].status = true
                }
                if(arryRecieved[i] == "Bariatric Surgery Cover"){
                    this.showBSCAccor = true
                    this.nextdrive[33].status = true
                }
                if(arryRecieved[i] == "Domiciliary Treatment"){
                    this.showDTAccor = true
                    this.nextdrive[34].status = true
                }
                if(arryRecieved[i] == "Ayush Benefit"){
                    this.showABAccor = true
                    this.nextdrive[35].status = true
                }

                

            }
        }catch(e){}
        var arris = this.nextdrive.filter(e=>{
            if(e.status == true){
                return e
            }
        })
        this.onlytrue = arris;
        this.onlytrue[0].shown = 'y'

    },
    rebindctcvac(arryRecieved){
        console.log(arryRecieved,"CCTVAC array recieved")
        for(let i=0; i<arryRecieved.length;i++){
            // console.log(arryRecieved[i].hasOwnProperty('coverageCode') && arryRecieved[i].coverageCode==="VAC")
            if(arryRecieved[i].hasOwnProperty('coverageCode') && arryRecieved[i].coverageCode==="VAC"){
                //  alert("reached VAC ")
                this.vcntnCoverageType=arryRecieved[i].hasOwnProperty('coverageType')?this.chkplndata(arryRecieved[i].coverageType):''
                this.vcntnCoverageBasis=arryRecieved[i].hasOwnProperty('coverageBasis')?this.chkplndata(arryRecieved[i].coverageBasis):''
                this.chngVcntnCb(2)
                this.vcntnSubLimitBasis=arryRecieved[i].hasOwnProperty('subLimitBasis')?this.chkplndataarry(arryRecieved[i].subLimitBasis):[]
                this.chngVcntnSubLmtBasis()
                this.vcntnClaimPaymentBasis=arryRecieved[i].hasOwnProperty('claimPaymentBasis')?this.chkplndata(arryRecieved[i].claimPaymentBasis):''
                this.chngVcntnClaimPaymentBasis() 
                this.vcntnWaitingPeriod=arryRecieved[i].hasOwnProperty('waitingPeriod')?this.chkplndata(arryRecieved[i].waitingPeriod):''
                this.vaccwaitingPeriodDrop=arryRecieved[i].hasOwnProperty('waitingPeroidType')?this.chkplndata(arryRecieved[i].waitingPeroidType):'Days'
                this.vcntnFlatAmt=arryRecieved[i].hasOwnProperty('flatAmount')?this.chkplndata(arryRecieved[i].flatAmount):''
                this.vcntnPrcntSi=arryRecieved[i].hasOwnProperty('perOfSi')?this.chkplndata(arryRecieved[i].perOfSi):''
                this.vcntnPrcntPrvSi=arryRecieved[i].hasOwnProperty('perOfPreviousSi')?this.chkplndata(arryRecieved[i].perOfPreviousSi):''
                this.vaccOpdSi = arryRecieved[i].hasOwnProperty('perOfOpdSi')?this.chkplndata(arryRecieved[i].perOfOpdSi):'' 
                this.vaccAdSi = arryRecieved[i].hasOwnProperty('perOfAdSi')?this.chkplndata(arryRecieved[i].perOfAdSi):''
                this.vcntnPrcntClmAmt=arryRecieved[i].hasOwnProperty('perOfClaimAmount')?this.chkplndata(arryRecieved[i].perOfClaimAmount):''
                this.vcntnAplcblClmPyot=arryRecieved[i].hasOwnProperty('applicableClaimPayout')?this.chkplndata(arryRecieved[i].applicableClaimPayout):''
                this.VcntnCad=arryRecieved[i].hasOwnProperty('coverageApplicableDuration')?this.chkplndata(arryRecieved[i].coverageApplicableDuration):''
                this.vcntnElglblDrtn=arryRecieved[i].hasOwnProperty('eligibleDuration')?this.chkplndata(arryRecieved[i].eligibleDuration):''
                this.vaccCoverAltName = arryRecieved[i].hasOwnProperty('alternateName')?this.chkplndata(arryRecieved[i].alternateName):'' 
                this.vaccExtRefNo = arryRecieved[i].hasOwnProperty('externalRefNo')?this.chkplndata(arryRecieved[i].externalRefNo):'' 
                this.vaccGcCover = arryRecieved[i].hasOwnProperty('gcCoverage')?this.chkplndata(arryRecieved[i].gcCoverage):'' 
                this.vaccIrdaUINNo = arryRecieved[i].hasOwnProperty('irdaUanNo')?this.chkplndata(arryRecieved[i].irdaUanNo):'' 
                this.vaccRemarks = arryRecieved[i].hasOwnProperty('remarks')?this.chkplndata(arryRecieved[i].remarks):'' 
                this.vaccCondField = arryRecieved[i].hasOwnProperty('conditions')?this.chkplndataarry(arryRecieved[i].conditions):[] 
                this.vaccExclus = arryRecieved[i].hasOwnProperty('exclusions')?this.chkplndataarry(arryRecieved[i].exclusions):[] 
                this.vaccSelectBox = arryRecieved[i].hasOwnProperty('relationship')?this.chkplndata(arryRecieved[i].relationship):false 

                if(this.vaccSelectBox){
                    let _relCoverDet = arryRecieved[i].hasOwnProperty('relationShipsCoveredDetails')?this.chkplndataarry(arryRecieved[i].relationShipsCoveredDetails):[]
                    this.process_relationship_dependent(_relCoverDet,res=>{
                        this.vaccrelCoverData = {}
                        this.vaccrelCoverData = res
                        this.showvaccRelData = true
                    })
                }
                this.vaccRelCheckSelected()
                this.f2.push(arryRecieved[i])

            }else if(arryRecieved[i].hasOwnProperty('coverageCode') && arryRecieved[i].coverageCode==="CTC"){
                // alert("reached CTC ")
                this.ctCoverageType=arryRecieved[i].hasOwnProperty('coverageType')?this.chkplndata(arryRecieved[i].coverageType):''
                this.ctCoverageBasis=arryRecieved[i].hasOwnProperty('coverageBasis')?this.chkplndata(arryRecieved[i].coverageBasis):''
                this.chngCtCb(2)
                this.ctSubLimitBasis=arryRecieved[i].hasOwnProperty('subLimitBasis')?this.chkplndataarry(arryRecieved[i].subLimitBasis):[]
                this.chngCtSubLmtBasis()
                this.ctClaimPaymentBasis=arryRecieved[i].hasOwnProperty('claimPaymentBasis')?this.chkplndata(arryRecieved[i].claimPaymentBasis):''
                this.chngCtClaimPaymentBasis() 
                this.ctWaitingPeriod=arryRecieved[i].hasOwnProperty('waitingPeriod')?this.chkplndata(arryRecieved[i].waitingPeriod):''
                this.CTCwaitingPeriodDrop=arryRecieved[i].hasOwnProperty('waitingPeroidType')?this.chkplndata(arryRecieved[i].waitingPeroidType):'Days'
                this.ctFlatAmt=arryRecieved[i].hasOwnProperty('flatAmount')?this.chkplndata(arryRecieved[i].flatAmount):''
                this.ctPrcntSi=arryRecieved[i].hasOwnProperty('perOfSi')?this.chkplndata(arryRecieved[i].perOfSi):''
                this.ctPrcntPrvSi=arryRecieved[i].hasOwnProperty('perOfPreviousSi')?this.chkplndata(arryRecieved[i].perOfPreviousSi):''
                this.CTCOpdSi = arryRecieved[i].hasOwnProperty('perOfOpdSi')?this.chkplndata(arryRecieved[i].perOfOpdSi):'' 
                this.CTCAdSi = arryRecieved[i].hasOwnProperty('perOfAdSi')?this.chkplndata(arryRecieved[i].perOfAdSi):''
                this.ctPrcntClmAmt=arryRecieved[i].hasOwnProperty('perOfClaimAmount')?this.chkplndata(arryRecieved[i].perOfClaimAmount):''
                this.ctAplcblClmPyot=arryRecieved[i].hasOwnProperty('applicableClaimPayout')?this.chkplndata(arryRecieved[i].applicableClaimPayout):''
                this.CtCad=arryRecieved[i].hasOwnProperty('coverageApplicableDuration')?this.chkplndata(arryRecieved[i].coverageApplicableDuration):''
                this.ctMnmHspitlztnDrtn=arryRecieved[i].hasOwnProperty('minHospitalization')?this.chkplndata(arryRecieved[i].minHospitalization):''
                this.CTCCoverAltName = arryRecieved[i].hasOwnProperty('alternateName')?this.chkplndata(arryRecieved[i].alternateName):'' 
                this.CTCExtRefNo = arryRecieved[i].hasOwnProperty('externalRefNo')?this.chkplndata(arryRecieved[i].externalRefNo):'' 
                this.CTCGcCover = arryRecieved[i].hasOwnProperty('gcCoverage')?this.chkplndata(arryRecieved[i].gcCoverage):'' 
                this.CTCIrdaUINNo = arryRecieved[i].hasOwnProperty('irdaUanNo')?this.chkplndata(arryRecieved[i].irdaUanNo):'' 
                this.CTCRemarks = arryRecieved[i].hasOwnProperty('remarks')?this.chkplndata(arryRecieved[i].remarks):'' 
                this.CTCCondField = arryRecieved[i].hasOwnProperty('conditions')?this.chkplndataarry(arryRecieved[i].conditions):[] 
                this.CTCExclus = arryRecieved[i].hasOwnProperty('exclusions')?this.chkplndataarry(arryRecieved[i].exclusions):[] 
                this.CTCSelectBox = arryRecieved[i].hasOwnProperty('relationship')?this.chkplndata(arryRecieved[i].relationship):false 
                
                if(this.CTCSelectBox){
                    let _relCoverDet = arryRecieved[i].hasOwnProperty('relationShipsCoveredDetails')?this.chkplndataarry(arryRecieved[i].relationShipsCoveredDetails):[]
                    this.process_relationship_dependent(_relCoverDet,res=>{
                        this.CTCrelCoverData = {}
                        this.CTCrelCoverData = res
                        this.showCTCRelData = true
                    })
                }
                this.CTCRelCheckSelected()
                this.f1.push(arryRecieved[i])
            }
        }
    },

    CTCRelCheckSelected(){
        this.showCTCRelSection=false
        this.showCTCRelData = false
        if (this.CTCSelectBox){
            this.showCTCRelSection=true
            this.showCTCRelData = true
        }
    },
    vaccRelCheckSelected(){
        this.showvaccRelSection=false
        this.showvaccRelData = false
        if (this.vaccSelectBox){
            this.showvaccRelSection=true
            this.showvaccRelData = true
        }
    },

    rebindTmrFe(arryRecieved){
        for(let i=0; i<arryRecieved.length;i++){
            if(arryRecieved[i].hasOwnProperty('coverageCode') && arryRecieved[i].coverageCode==="TMR"){
                this.tmrCoverageType=arryRecieved[i].hasOwnProperty('coverageType')?this.chkplndata(arryRecieved[i].coverageType):''
                this.tmrCoverageBasis=arryRecieved[i].hasOwnProperty('coverageBasis')?this.chkplndata(arryRecieved[i].coverageBasis):''
                this.chngTmrCb(2)
                this.tmrSubLimitBasis=arryRecieved[i].hasOwnProperty('subLimitBasis')?this.chkplndataarry(arryRecieved[i].subLimitBasis):[]
                this.chngTmrSubLmtBasis()
                this.tmrClaimPaymentBasis=arryRecieved[i].hasOwnProperty('claimPaymentBasis')?this.chkplndata(arryRecieved[i].claimPaymentBasis):''
                this.chngTmrClaimPaymentBasis()
                this.tmrFlatAmt=arryRecieved[i].hasOwnProperty('flatAmount')?this.chkplndata(arryRecieved[i].flatAmount):''
                this.tmrPrcntSi=arryRecieved[i].hasOwnProperty('perOfSi')?this.chkplndata(arryRecieved[i].perOfSi):''
                this.tmrPrcntPrvSi=arryRecieved[i].hasOwnProperty('perOfPreviousSi')?this.chkplndata(arryRecieved[i].perOfPreviousSi):''
                this.tmrPrcntClmAmt=arryRecieved[i].hasOwnProperty('perOfClaimAmount')?this.chkplndata(arryRecieved[i].perOfClaimAmount):''
                this.tmrAplcblClmPyot=arryRecieved[i].hasOwnProperty('applicableClaimPayout')?this.chkplndata(arryRecieved[i].applicableClaimPayout):''
                this.tmrCad=arryRecieved[i].hasOwnProperty('coverageApplicableDuration')?this.chkplndata(arryRecieved[i].coverageApplicableDuration):''
                this.TMRwaitingPeriod = arryRecieved[i].hasOwnProperty('waitingPeriod')?this.chkplndata(arryRecieved[i].waitingPeriod):''
                this.TMRwaitingPeriodDrop = arryRecieved[i].hasOwnProperty('waitingPeroidType')?this.chkplndata(arryRecieved[i].waitingPeroidType):'Days'
                this.TMRCoverAltName = arryRecieved[i].hasOwnProperty('alternateName')?this.chkplndata(arryRecieved[i].alternateName):'' 
                this.TMRExtRefNo = arryRecieved[i].hasOwnProperty('externalRefNo')?this.chkplndata(arryRecieved[i].externalRefNo):'' 
                this.TMRGcCover = arryRecieved[i].hasOwnProperty('gcCoverage')?this.chkplndata(arryRecieved[i].gcCoverage):'' 
                this.TMRIrdaUINNo = arryRecieved[i].hasOwnProperty('irdaUanNo')?this.chkplndata(arryRecieved[i].irdaUanNo):'' 
                this.TMRRemarks = arryRecieved[i].hasOwnProperty('remarks')?this.chkplndata(arryRecieved[i].remarks):'' 
                this.TMRCondField = arryRecieved[i].hasOwnProperty('conditions')?this.chkplndataarry(arryRecieved[i].conditions):[] 
                this.TMRExclus = arryRecieved[i].hasOwnProperty('exclusions')?this.chkplndataarry(arryRecieved[i].exclusions):[]
                this.TMRSelectBox = arryRecieved[i].hasOwnProperty('relationship')?this.chkplndata(arryRecieved[i].relationship):false 
                
                if(this.TMRSelectBox){
                    let _relCoverDet = arryRecieved[i].hasOwnProperty('relationShipsCoveredDetails')?this.chkplndataarry(arryRecieved[i].relationShipsCoveredDetails):[]
                    this.process_relationship_dependent(_relCoverDet,res=>{
                        this.TMRrelCoverData = {}
                        this.TMRrelCoverData = res
                        this.showTMRRelData = true
                    })
                }
                this.TMRRelCheckSelected()
                this.tmrData=[]
                this.tmrData.push(arryRecieved[i])


            }else if(arryRecieved[i].hasOwnProperty('coverageCode') && arryRecieved[i].coverageCode==="FE"){
                this.feCoverageType=arryRecieved[i].hasOwnProperty('coverageType')?this.chkplndata(arryRecieved[i].coverageType):''
                this.feCoverageBasis=arryRecieved[i].hasOwnProperty('coverageBasis')?this.chkplndata(arryRecieved[i].coverageBasis):''
                this.chngFeCb(2)
                this.feSubLimitBasis=arryRecieved[i].hasOwnProperty('subLimitBasis')?this.chkplndataarry(arryRecieved[i].subLimitBasis):[]
                this.chngFeSubLmtBasis()
                this.feClaimPaymentBasis=arryRecieved[i].hasOwnProperty('claimPaymentBasis')?this.chkplndata(arryRecieved[i].claimPaymentBasis):''
                this.chngFeClaimPaymentBasis()
                this.feFlatAmt=arryRecieved[i].hasOwnProperty('flatAmount')?this.chkplndata(arryRecieved[i].flatAmount):''
                this.fePrcntSi=arryRecieved[i].hasOwnProperty('perOfSi')?this.chkplndata(arryRecieved[i].perOfSi):''
                this.fePrcntPrvSi=arryRecieved[i].hasOwnProperty('perOfPreviousSi')?this.chkplndata(arryRecieved[i].perOfPreviousSi):''
                this.fePrcntClmAmt=arryRecieved[i].hasOwnProperty('perOfClaimAmount')?this.chkplndata(arryRecieved[i].perOfClaimAmount):''
                this.feAplcblClmPyot=arryRecieved[i].hasOwnProperty('applicableClaimPayout')?this.chkplndata(arryRecieved[i].applicableClaimPayout):''
                this.FeCad=arryRecieved[i].hasOwnProperty('coverageApplicableDuration')?this.chkplndata(arryRecieved[i].coverageApplicableDuration):''
                this.FEwaitingPeriod = arryRecieved[i].hasOwnProperty('waitingPeriod')?this.chkplndata(arryRecieved[i].waitingPeriod):''
                this.FEwaitingPeriodDrop = arryRecieved[i].hasOwnProperty('waitingPeroidType')?this.chkplndata(arryRecieved[i].waitingPeroidType):'Days'
                this.FECoverAltName = arryRecieved[i].hasOwnProperty('alternateName')?this.chkplndata(arryRecieved[i].alternateName):'' 
                this.FEExtRefNo = arryRecieved[i].hasOwnProperty('externalRefNo')?this.chkplndata(arryRecieved[i].externalRefNo):'' 
                this.FEGcCover = arryRecieved[i].hasOwnProperty('gcCoverage')?this.chkplndata(arryRecieved[i].gcCoverage):'' 
                this.FEIrdaUINNo = arryRecieved[i].hasOwnProperty('irdaUanNo')?this.chkplndata(arryRecieved[i].irdaUanNo):'' 
                this.FERemarks = arryRecieved[i].hasOwnProperty('remarks')?this.chkplndata(arryRecieved[i].remarks):'' 
                this.FECondField = arryRecieved[i].hasOwnProperty('conditions')?this.chkplndataarry(arryRecieved[i].conditions):[] 
                this.FEExclus = arryRecieved[i].hasOwnProperty('exclusions')?this.chkplndataarry(arryRecieved[i].exclusions):[]
                this.FESelectBox = arryRecieved[i].hasOwnProperty('relationship')?this.chkplndata(arryRecieved[i].relationship):false 
                
                if(this.FESelectBox){
                    let _relCoverDet = arryRecieved[i].hasOwnProperty('relationShipsCoveredDetails')?this.chkplndataarry(arryRecieved[i].relationShipsCoveredDetails):[]
                    this.process_relationship_dependent(_relCoverDet,res=>{
                        this.FErelCoverData = {}
                        this.FErelCoverData = res
                        this.showFERelData = true
                    })
                }
                this.FERelCheckSelected()
                this.feData=[]
                this.feData.push(arryRecieved[i])
            }
        }

    },
    FERelCheckSelected(){
        this.showFERelSection=false
        this.showFERelData = false
        if (this.FESelectBox){
            this.showFERelSection=true
            this.showFERelData = true
        }
    },
    TMRRelCheckSelected(){
        this.showTMRRelSection=false
        this.showTMRRelData = false
        if (this.TMRSelectBox){
            this.showTMRRelSection=true
            this.showTMRRelData = true
        }
    },
    rbndOthcotdea(arryRecieved){
        for(let i=0; i<arryRecieved.length;i++){
            if(arryRecieved[i].hasOwnProperty('coverageCode') && arryRecieved[i].coverageCode==="OPDT"){
                this.opdTCoverageType=arryRecieved[i].hasOwnProperty('coverageType')?this.chkplndata(arryRecieved[i].coverageType):''
                this.opdTCoverageBasis=arryRecieved[i].hasOwnProperty('coverageBasis')?this.chkplndata(arryRecieved[i].coverageBasis):''
                this.chngOpdTCb(2)
                this.opdTSubLimitBasis=arryRecieved[i].hasOwnProperty('subLimitBasis')?this.chkplndataarry(arryRecieved[i].subLimitBasis):[]
                this.chngopdTSubLmtBasis()
                this.opdTClaimPaymentBasis=arryRecieved[i].hasOwnProperty('claimPaymentBasis')?this.chkplndata(arryRecieved[i].claimPaymentBasis):''
                this.chngOpdTClaimPaymentBasis()
                this.opdTFlatAmt=arryRecieved[i].hasOwnProperty('flatAmount')?this.chkplndata(arryRecieved[i].flatAmount):''
                this.opdTPrcntSi=arryRecieved[i].hasOwnProperty('perOfSi')?this.chkplndata(arryRecieved[i].perOfSi):''
                this.opdTPrcntPrvSi=arryRecieved[i].hasOwnProperty('perOfPreviousSi')?this.chkplndata(arryRecieved[i].perOfPreviousSi):''
                this.opdTPrcntClmAmt=arryRecieved[i].hasOwnProperty('perOfClaimAmount')?this.chkplndata(arryRecieved[i].perOfClaimAmount):''
                this.opdTAplcblClmPyot=arryRecieved[i].hasOwnProperty('applicableClaimPayout')?this.chkplndata(arryRecieved[i].applicableClaimPayout):''
                this.opdTCad=arryRecieved[i].hasOwnProperty('coverageApplicableDuration')?this.chkplndata(arryRecieved[i].coverageApplicableDuration):''
                this.opdTWtngPrd=arryRecieved[i].hasOwnProperty('waitingPeriod')?this.chkplndata(arryRecieved[i].waitingPeriod):''
                this.OPDTwaitingPeriodDrop=arryRecieved[i].hasOwnProperty('waitingPeroidType')?this.chkplndata(arryRecieved[i].waitingPeroidType):'Days'
                this.OPDTCoverAltName = arryRecieved[i].hasOwnProperty('alternateName')?this.chkplndata(arryRecieved[i].alternateName):'' 
                this.OPDTExtRefNo = arryRecieved[i].hasOwnProperty('externalRefNo')?this.chkplndata(arryRecieved[i].externalRefNo):'' 
                this.OPDTGcCover = arryRecieved[i].hasOwnProperty('gcCoverage')?this.chkplndata(arryRecieved[i].gcCoverage):'' 
                this.OPDTIrdaUINNo = arryRecieved[i].hasOwnProperty('irdaUanNo')?this.chkplndata(arryRecieved[i].irdaUanNo):'' 
                this.OPDTRemarks = arryRecieved[i].hasOwnProperty('remarks')?this.chkplndata(arryRecieved[i].remarks):'' 
                this.OPDTCondField = arryRecieved[i].hasOwnProperty('conditions')?this.chkplndataarry(arryRecieved[i].conditions):[] 
                this.OPDTExclus = arryRecieved[i].hasOwnProperty('exclusions')?this.chkplndataarry(arryRecieved[i].exclusions):[]
                this.OPDTSelectBox = arryRecieved[i].hasOwnProperty('relationship')?this.chkplndata(arryRecieved[i].relationship):false 
                
                if(this.OPDTSelectBox){
                    let _relCoverDet = arryRecieved[i].hasOwnProperty('relationShipsCoveredDetails')?this.chkplndataarry(arryRecieved[i].relationShipsCoveredDetails):[]
                    this.process_relationship_dependent(_relCoverDet,res=>{
                        this.OPDTrelCoverData = {}
                        this.OPDTrelCoverData = res
                        this.showOPDTRelData = true
                    })
                }
                this.OPDTRelCheckSelected()
                this.OpdtDta=[]
                this.OpdtDta.push(arryRecieved[i])

            }else if(arryRecieved[i].hasOwnProperty('coverageCode') && arryRecieved[i].coverageCode==="HCUC"){
                this.hckCoverageType=arryRecieved[i].hasOwnProperty('coverageType')?this.chkplndata(arryRecieved[i].coverageType):''
                this.hckCoverageBasis=arryRecieved[i].hasOwnProperty('coverageBasis')?this.chkplndata(arryRecieved[i].coverageBasis):''
                this.chngHckCb(2)
                this.hckSubLimitBasis=arryRecieved[i].hasOwnProperty('subLimitBasis')?this.chkplndataarry(arryRecieved[i].subLimitBasis):[]
                this.chngHckSubLmtBasis()
                this.hckClaimPaymentBasis=arryRecieved[i].hasOwnProperty('claimPaymentBasis')?this.chkplndata(arryRecieved[i].claimPaymentBasis):''
                this.chngHckClaimPaymentBasis()
                this.hckFlatAmt=arryRecieved[i].hasOwnProperty('flatAmount')?this.chkplndata(arryRecieved[i].flatAmount):''
                this.hckPrcntSi=arryRecieved[i].hasOwnProperty('perOfSi')?this.chkplndata(arryRecieved[i].perOfSi):''
                this.hckPrcntPrvSi=arryRecieved[i].hasOwnProperty('perOfPreviousSi')?this.chkplndata(arryRecieved[i].perOfPreviousSi):''
                this.hckPrcntClmAmt=arryRecieved[i].hasOwnProperty('perOfClaimAmount')?this.chkplndata(arryRecieved[i].perOfClaimAmount):''
                this.hckAplcblClmPyot=arryRecieved[i].hasOwnProperty('applicableClaimPayout')?this.chkplndata(arryRecieved[i].applicableClaimPayout):''
                this.hckCad=arryRecieved[i].hasOwnProperty('coverageApplicableDuration')?this.chkplndata(arryRecieved[i].coverageApplicableDuration):''
                this.hckWaitingPeriod=arryRecieved[i].hasOwnProperty('waitingPeriod')?this.chkplndata(arryRecieved[i].waitingPeriod):''
                this.HCUCwaitingPeriodDrop=arryRecieved[i].hasOwnProperty('waitingPeroidType')?this.chkplndata(arryRecieved[i].waitingPeroidType):'Days'
                this.HCUCNoClaimFreeYrs=arryRecieved[i].hasOwnProperty('noOfClaimFreeYears')?this.chkplndata(arryRecieved[i].noOfClaimFreeYears):''
                this.HCUCCoverAltName = arryRecieved[i].hasOwnProperty('alternateName')?this.chkplndata(arryRecieved[i].alternateName):'' 
                this.HCUCExtRefNo = arryRecieved[i].hasOwnProperty('externalRefNo')?this.chkplndata(arryRecieved[i].externalRefNo):'' 
                this.HCUCGcCover = arryRecieved[i].hasOwnProperty('gcCoverage')?this.chkplndata(arryRecieved[i].gcCoverage):'' 
                this.HCUCIrdaUINNo = arryRecieved[i].hasOwnProperty('irdaUanNo')?this.chkplndata(arryRecieved[i].irdaUanNo):'' 
                this.HCUCRemarks = arryRecieved[i].hasOwnProperty('remarks')?this.chkplndata(arryRecieved[i].remarks):'' 
                this.HCUCCondField = arryRecieved[i].hasOwnProperty('conditions')?this.chkplndataarry(arryRecieved[i].conditions):[] 
                this.HCUCExclus = arryRecieved[i].hasOwnProperty('exclusions')?this.chkplndataarry(arryRecieved[i].exclusions):[] 
                this.HCUCSelectBox = arryRecieved[i].hasOwnProperty('relationship')?this.chkplndata(arryRecieved[i].relationship):false 
                
                if(this.HCUCSelectBox){
                    let _relCoverDet = arryRecieved[i].hasOwnProperty('relationShipsCoveredDetails')?this.chkplndataarry(arryRecieved[i].relationShipsCoveredDetails):[]
                    this.process_relationship_dependent(_relCoverDet,res=>{
                        this.HCUCrelCoverData = {}
                        this.HCUCrelCoverData = res
                        this.showHCUCRelData = true
                    })
                }
                this.HCUCRelCheckSelected()
                this.HcucDta=[]
                this.HcucDta.push(arryRecieved[i])

            }else if(arryRecieved[i].hasOwnProperty('coverageCode') && arryRecieved[i].coverageCode==="OPDDT"){
                this.odtcoverageType=arryRecieved[i].hasOwnProperty('coverageType')?this.chkplndata(arryRecieved[i].coverageType):''
                this.odtcoverageBasis=arryRecieved[i].hasOwnProperty('coverageBasis')?this.chkplndata(arryRecieved[i].coverageBasis):''
                this.odtchangeOnover(2)
                this.odtsubLimitBasis=arryRecieved[i].hasOwnProperty('subLimitBasis')?this.chkplndataarry(arryRecieved[i].subLimitBasis):[]
                this.chngOdtSubLmtBasis()
                this.odtclaimPaymentBasis=arryRecieved[i].hasOwnProperty('claimPaymentBasis')?this.chkplndata(arryRecieved[i].claimPaymentBasis):''
                this.chngOdtClaimPaymentBasis()
                this.odtflatAmount=arryRecieved[i].hasOwnProperty('flatAmount')?this.chkplndata(arryRecieved[i].flatAmount):''
                this.odtPrcntSi=arryRecieved[i].hasOwnProperty('perOfSi')?this.chkplndata(arryRecieved[i].perOfSi):''
                this.odtPrcntPrvSi=arryRecieved[i].hasOwnProperty('perOfPreviousSi')?this.chkplndata(arryRecieved[i].perOfPreviousSi):''
                this.odtPrcntClmAmt=arryRecieved[i].hasOwnProperty('perOfClaimAmount')?this.chkplndata(arryRecieved[i].perOfClaimAmount):''
                this.odtAplcblClmPyot=arryRecieved[i].hasOwnProperty('applicableClaimPayout')?this.chkplndata(arryRecieved[i].applicableClaimPayout):''
                this.odtCad=arryRecieved[i].hasOwnProperty('coverageApplicableDuration')?this.chkplndata(arryRecieved[i].coverageApplicableDuration):''
                this.odtwaitingPeriod=arryRecieved[i].hasOwnProperty('waitingPeriod')?this.chkplndata(arryRecieved[i].waitingPeriod):''
                this.odtwaitingPeriodDrop=arryRecieved[i].hasOwnProperty('waitingPeroidType')?this.chkplndata(arryRecieved[i].waitingPeroidType):'Days'
                this.ODTCoverAltName = arryRecieved[i].hasOwnProperty('alternateName')?this.chkplndata(arryRecieved[i].alternateName):'' 
                this.ODTExtRefNo = arryRecieved[i].hasOwnProperty('externalRefNo')?this.chkplndata(arryRecieved[i].externalRefNo):'' 
                this.ODTGcCover = arryRecieved[i].hasOwnProperty('gcCoverage')?this.chkplndata(arryRecieved[i].gcCoverage):'' 
                this.ODTIrdaUINNo = arryRecieved[i].hasOwnProperty('irdaUanNo')?this.chkplndata(arryRecieved[i].irdaUanNo):'' 
                this.ODTRemarks = arryRecieved[i].hasOwnProperty('remarks')?this.chkplndata(arryRecieved[i].remarks):'' 
                this.ODTCondField = arryRecieved[i].hasOwnProperty('conditions')?this.chkplndataarry(arryRecieved[i].conditions):[] 
                this.ODTExclus = arryRecieved[i].hasOwnProperty('exclusions')?this.chkplndataarry(arryRecieved[i].exclusions):[] 
                this.ODTSelectBox = arryRecieved[i].hasOwnProperty('relationship')?this.chkplndata(arryRecieved[i].relationship):false 
                
                if(this.ODTSelectBox){
                    let _relCoverDet = arryRecieved[i].hasOwnProperty('relationShipsCoveredDetails')?this.chkplndataarry(arryRecieved[i].relationShipsCoveredDetails):[]
                    this.process_relationship_dependent(_relCoverDet,res=>{
                        this.ODTrelCoverData = {}
                        this.ODTrelCoverData = res
                        this.showODTRelData = true
                    })
                }
                this.ODTRelCheckSelected()
                this.opddtDta=[]
                this.opddtDta.push(arryRecieved[i])

            }else if(arryRecieved[i].hasOwnProperty('coverageCode') && arryRecieved[i].coverageCode==="EAA"){
                this.eaacoverageType=arryRecieved[i].hasOwnProperty('coverageType')?this.chkplndata(arryRecieved[i].coverageType):''
                this.eaacoverageBasis=arryRecieved[i].hasOwnProperty('coverageBasis')?this.chkplndata(arryRecieved[i].coverageBasis):''
                this.eaachangeOnover(2)
                this.eaasubLimitBasis=arryRecieved[i].hasOwnProperty('subLimitBasis')?this.chkplndataarry(arryRecieved[i].subLimitBasis):[]
                this.chngEaaSubLmtBasis()
                this.eaaclaimPaymentBasis=arryRecieved[i].hasOwnProperty('claimPaymentBasis')?this.chkplndata(arryRecieved[i].claimPaymentBasis):''
                this.chngEaaClaimPaymentBasis()
                this.eaaflatAmount=arryRecieved[i].hasOwnProperty('flatAmount')?this.chkplndata(arryRecieved[i].flatAmount):''
                this.eaaPrcntSi=arryRecieved[i].hasOwnProperty('perOfSi')?this.chkplndata(arryRecieved[i].perOfSi):''
                this.eaaPrcntPrvSi=arryRecieved[i].hasOwnProperty('perOfPreviousSi')?this.chkplndata(arryRecieved[i].perOfPreviousSi):''
                this.eaaPrcntClmAmt=arryRecieved[i].hasOwnProperty('perOfClaimAmount')?this.chkplndata(arryRecieved[i].perOfClaimAmount):''
                this.eaaAplcblClmPyot=arryRecieved[i].hasOwnProperty('applicableClaimPayout')?this.chkplndata(arryRecieved[i].applicableClaimPayout):''
                this.eaaCad=arryRecieved[i].hasOwnProperty('coverageApplicableDuration')?this.chkplndata(arryRecieved[i].coverageApplicableDuration):''
                this.eaawaitingPeriod=arryRecieved[i].hasOwnProperty('waitingPeriod')?this.chkplndata(arryRecieved[i].waitingPeriod):''
                this.EAAwaitingPeriodDrop=arryRecieved[i].hasOwnProperty('waitingPeroidType')?this.chkplndata(arryRecieved[i].waitingPeroidType):'Days'
                this.EAACoverAltName = arryRecieved[i].hasOwnProperty('alternateName')?this.chkplndata(arryRecieved[i].alternateName):'' 
                this.EAAExtRefNo = arryRecieved[i].hasOwnProperty('externalRefNo')?this.chkplndata(arryRecieved[i].externalRefNo):'' 
                this.EAAGcCover = arryRecieved[i].hasOwnProperty('gcCoverage')?this.chkplndata(arryRecieved[i].gcCoverage):'' 
                this.EAAIrdaUINNo = arryRecieved[i].hasOwnProperty('irdaUanNo')?this.chkplndata(arryRecieved[i].irdaUanNo):'' 
                this.EAARemarks = arryRecieved[i].hasOwnProperty('remarks')?this.chkplndata(arryRecieved[i].remarks):'' 
                this.EAACondField = arryRecieved[i].hasOwnProperty('conditions')?this.chkplndataarry(arryRecieved[i].conditions):[] 
                this.EAAExclus = arryRecieved[i].hasOwnProperty('exclusions')?this.chkplndataarry(arryRecieved[i].exclusions):[]
                this.EAASelectBox = arryRecieved[i].hasOwnProperty('relationship')?this.chkplndata(arryRecieved[i].relationship):false 
                
                if(this.EAASelectBox){
                    let _relCoverDet = arryRecieved[i].hasOwnProperty('relationShipsCoveredDetails')?this.chkplndataarry(arryRecieved[i].relationShipsCoveredDetails):[]
                    this.process_relationship_dependent(_relCoverDet,res=>{
                        this.EAArelCoverData = {}
                        this.EAArelCoverData = res
                        this.showEAARelData = true
                    })
                }
                this.EAARelCheckSelected()
                this.eaaDta=[]
                this.eaaDta.push(arryRecieved[i])
            }
        }

    },
    OPDTRelCheckSelected(){
        this.showOPDTRelSection=false
        this.showOPDTRelData = false
        if (this.OPDTSelectBox){
            this.showOPDTRelSection=true
            this.showOPDTRelData = true
        }
    },
    EAARelCheckSelected(){
        this.showEAARelSection=false
        this.showEAARelData = false
        if (this.EAASelectBox){
            this.showEAARelSection=true
            this.showEAARelData = true
        }
    },
    ODTRelCheckSelected(){
        this.showODTRelSection=false
        this.showODTRelData = false
        if (this.ODTSelectBox){
            this.showODTRelSection=true
            this.showODTRelData = true
        }
    },
    HCUCRelCheckSelected(){
        this.showHCUCRelSection=false
        this.showHCUCRelData = false
        if (this.HCUCSelectBox){
            this.showHCUCRelSection=true
            this.showHCUCRelData = true
        }
    },
   

    getDCPHData(event){
         for(let i=0; i < event.length; i++){
            if(event[i].hasOwnProperty('coverageCode') && event[i].coverageCode == "DC"){
                this.dcccoverageType = event[i].hasOwnProperty('coverageType')?this.chkplndata(event[i].coverageType):''
                this.dcccoverageBasis = event[i].hasOwnProperty('coverageBasis')?this.chkplndata(event[i].coverageBasis):''
                this.dccchangeOnover()
                this.dccsubLimitBasis = event[i].hasOwnProperty('subLimitBasis')?this.chkplndataarry(event[i].subLimitBasis):[] 
                this.subLimitBasisValChange(this.dccsubLimitBasis)
                this.dccclaimPaymentBasis = event[i].hasOwnProperty('claimPaymentBasis')?this.chkplndata(event[i].claimPaymentBasis):''
                this.changeDccClaimPayBasis(this.dccclaimPaymentBasis) 
                this.dccwaitingPeriod = event[i].hasOwnProperty('waitingPeriod')?this.chkplndata(event[i].waitingPeriod):'' 
                this.dccwaitingPeriodDrop = event[i].hasOwnProperty('waitingPeroidType')?this.chkplndata(event[i].waitingPeroidType):'Days' 
                this.flatAmountTxtField = event[i].hasOwnProperty('flatAmount')?this.chkplndata(event[i].flatAmount):'' 
                this.percentSI = event[i].hasOwnProperty('perOfSi')?this.chkplndata(event[i].perOfSi):'' 
                this.PrvpercentSI = event[i].hasOwnProperty('perOfPreviousSi')?this.chkplndata(event[i].perOfPreviousSi):''
                this.DCCOpdSi = event[i].hasOwnProperty('perOfOpdSi')?this.chkplndata(event[i].perOfOpdSi):'' 
                this.DCCAdSi = event[i].hasOwnProperty('perOfAdSi')?this.chkplndata(event[i].perOfAdSi):''
                this.percentClaimAmt = event[i].hasOwnProperty('perOfClaimAmount')?this.chkplndata(event[i].perOfClaimAmount):'' 
                this.DccApplicClaimPayOut = event[i].hasOwnProperty('applicableClaimPayout')?this.chkplndata(event[i].applicableClaimPayout):'' 
                this.dccminHosDuration = event[i].hasOwnProperty('minHospitalization')?this.chkplndata(event[i].minHospitalization):'' 
                this.dccisSelectBox = event[i].hasOwnProperty('relationship')?this.chkplndata(event[i].relationship):false 
                this.dccappliDayCarePro = event[i].hasOwnProperty('applicableDayCareProcedures')?this.chkplndataarry(event[i].applicableDayCareProcedures):[] 
                this.dccCovrageApplicDur = event[i].hasOwnProperty('coverageApplicableDuration')?this.chkplndata(event[i].coverageApplicableDuration):'' 
                this.DCCCoverAltName = event[i].hasOwnProperty('alternateName')?this.chkplndata(event[i].alternateName):'' 
                this.DCCExtRefNo = event[i].hasOwnProperty('externalRefNo')?this.chkplndata(event[i].externalRefNo):'' 
                this.DCCGcCover = event[i].hasOwnProperty('gcCoverage')?this.chkplndata(event[i].gcCoverage):'' 
                this.DCCIrdaUINNo = event[i].hasOwnProperty('irdaUanNo')?this.chkplndata(event[i].irdaUanNo):'' 
                this.DCCRemarks = event[i].hasOwnProperty('remarks')?this.chkplndata(event[i].remarks):'' 
                this.DCCCondField = event[i].hasOwnProperty('conditions')?this.chkplndataarry(event[i].conditions):[] 
                this.DCCExclus = event[i].hasOwnProperty('exclusions')?this.chkplndataarry(event[i].exclusions):[] 

                if(this.dccisSelectBox){
                    let _relCoverDet = event[i].hasOwnProperty('relationShipsCoveredDetails')?this.chkplndataarry(event[i].relationShipsCoveredDetails):[]
                    this.process_relationship_dependent(_relCoverDet,res=>{
                        this.DCrelCoverData = {}
                        this.DCrelCoverData = res
                        this.showDCRelData = true
                    })
                }
                this.DCRelCheckSelected()
                
                this.formDataDC.push(event[i])
             
            }else if(event[i].hasOwnProperty('coverageCode') && event[i].coverageCode == "PH"){
                this.plhcoverageType = event[i].hasOwnProperty('coverageType')?this.chkplndata(event[i].coverageType):''
                this.plhcoverageBasis = event[i].hasOwnProperty('coverageBasis')?this.chkplndata(event[i].coverageBasis):''
                this.plhchangeOnover()
                this.plhsubLimitBasis = event[i].hasOwnProperty('subLimitBasis')?this.chkplndataarry(event[i].subLimitBasis):[]
                this.plhSIchange(this.plhsubLimitBasis) 
                this.plhclaimPaymentBasis = event[i].hasOwnProperty('claimPaymentBasis')?this.chkplndata(event[i].claimPaymentBasis):''
                this.changePHClaimPayBasis(this.plhclaimPaymentBasis)  
                this.plhwaitingPeriod = event[i].hasOwnProperty('waitingPeriod')?this.chkplndata(event[i].waitingPeriod):'' 
                this.plhwaitingPeriodDrop = event[i].hasOwnProperty('waitingPeroidType')?this.chkplndata(event[i].waitingPeroidType):'Days' 
                this.PHFlatAmountTxtField = event[i].hasOwnProperty('flatAmount')?this.chkplndata(event[i].flatAmount):'' 
                this.plhperSI = event[i].hasOwnProperty('perOfSi')?this.chkplndata(event[i].perOfSi):'' 
                this.plhPrvperSI = event[i].hasOwnProperty('perOfPreviousSi')?this.chkplndata(event[i].perOfPreviousSi):''
                this.PHOpdSi = event[i].hasOwnProperty('perOfOpdSi')?this.chkplndata(event[i].perOfOpdSi):'' 
                this.PHAdSi = event[i].hasOwnProperty('perOfAdSi')?this.chkplndata(event[i].perOfAdSi):''
                this.PHPercentClaimAmt = event[i].hasOwnProperty('perOfClaimAmount')?this.chkplndata(event[i].perOfClaimAmount):'' 
                this.PHApplicClaimPayOut = event[i].hasOwnProperty('applicableClaimPayout')?this.chkplndata(event[i].applicableClaimPayout):'' 
                this.plhminHosDuration = event[i].hasOwnProperty('minHospitalization')?this.chkplndata(event[i].minHospitalization):'' 
                this.plhisSelectBox = event[i].hasOwnProperty('relationship')?this.chkplndata(event[i].relationship):false 
                // this.dccappliDayCarePro = event[i].hasOwnProperty('applicableDayCareProcedures')?this.chkplndataarry(event[i].coverageType):'' 
                this.PHCovrageApplicDur = event[i].hasOwnProperty('coverageApplicableDuration')?this.chkplndata(event[i].coverageApplicableDuration):'' 
                this.PHCoverAltName = event[i].hasOwnProperty('alternateName')?this.chkplndata(event[i].alternateName):'' 
                this.PHExtRefNo = event[i].hasOwnProperty('externalRefNo')?this.chkplndata(event[i].externalRefNo):'' 
                this.PHGcCover = event[i].hasOwnProperty('gcCoverage')?this.chkplndata(event[i].gcCoverage):'' 
                this.PHIrdaUINNo = event[i].hasOwnProperty('irdaUanNo')?this.chkplndata(event[i].irdaUanNo):'' 
                this.PHRemarks = event[i].hasOwnProperty('remarks')?this.chkplndata(event[i].remarks):'' 
                this.PHCondField = event[i].hasOwnProperty('conditions')?this.chkplndataarry(event[i].conditions):[] 
                this.PHExclus = event[i].hasOwnProperty('exclusions')?this.chkplndataarry(event[i].exclusions):[] 
                if(this.plhisSelectBox){
                    let _relCoverDet = event[i].hasOwnProperty('relationShipsCoveredDetails')?this.chkplndataarry(event[i].relationShipsCoveredDetails):[]
                    this.process_relationship_dependent(_relCoverDet,res=>{
                        this.PHrelCoverData = {}
                        this.PHrelCoverData = res
                        this.showPHRelData = true
                    })
                }
                this.PHRelCheckSelected()

                this.formDataPH.push(event[i])
            }
        }
    },
    DCRelCheckSelected(){
        this.showDCRelSection=false
        this.showDCRelData = false
        if (this.dccisSelectBox){
            this.showDCRelSection=true
            this.showDCRelData = true
        }
    },
    PHRelCheckSelected(){
        this.showRelSection=false
        this.showPHRelData = false
        if (this.plhisSelectBox){
            this.showRelSection=true
            this.showPHRelData = true
        }
    },
    getVCITData(event){
         for(let i=0; i < event.length; i++){
            if(event[i].hasOwnProperty('coverageCode') && event[i].coverageCode == "VC"){
                this.vcCoverageType = event[i].hasOwnProperty('coverageType')?this.chkplndata(event[i].coverageType):''
                this.vcCoverageBasis = event[i].hasOwnProperty('coverageBasis')?this.chkplndata(event[i].coverageBasis):''
                this.chngVcCb()
                this.vcSubLimitBasis = event[i].hasOwnProperty('subLimitBasis')?this.chkplndataarry(event[i].subLimitBasis):[] 
                this.changeVcSubLimBasis(this.vcSubLimitBasis)
                this.vcClaimPaymentBasis = event[i].hasOwnProperty('claimPaymentBasis')?this.chkplndata(event[i].claimPaymentBasis):''
                this.changeVcClaimPayBasis(this.vcClaimPaymentBasis)
                this.vcWtngPrd = event[i].hasOwnProperty('waitingPeriod')?this.chkplndata(event[i].waitingPeriod):'' 
                this.VCwaitingPeriodDrop = event[i].hasOwnProperty('waitingPeroidType')?this.chkplndata(event[i].waitingPeroidType):'Days'
                this.vcFlatAmountTxtField = event[i].hasOwnProperty('flatAmount')?this.chkplndata(event[i].flatAmount):'' 
                this.vcPercentSI = event[i].hasOwnProperty('perOfSi')?this.chkplndata(event[i].perOfSi):'' 
                this.vcPrvPercentSI = event[i].hasOwnProperty('perOfPreviousSi')?this.chkplndata(event[i].perOfPreviousSi):''
                this.VCOpdSi = event[i].hasOwnProperty('perOfOpdSi')?this.chkplndata(event[i].perOfOpdSi):'' 
                this.VCAdSi = event[i].hasOwnProperty('perOfAdSi')?this.chkplndata(event[i].perOfAdSi):''
                this.vcPercentClaimAmt = event[i].hasOwnProperty('perOfClaimAmount')?this.chkplndata(event[i].perOfClaimAmount):'' 
                this.vcApplicClaimPayOut = event[i].hasOwnProperty('applicableClaimPayout')?this.chkplndata(event[i].applicableClaimPayout):'' 
                this.vcExclusions = event[i].hasOwnProperty('exclusions')?this.chkplndataarry(event[i].exclusions):[] 
                this.vcCovrageApplicDur = event[i].hasOwnProperty('coverageApplicableDuration')?this.chkplndata(event[i].coverageApplicableDuration):'' 
                this.VCSelectBox = event[i].hasOwnProperty('relationship')?this.chkplndata(event[i].relationship):false

                this.VCCoverAltName = event[i].hasOwnProperty('alternateName')?this.chkplndata(event[i].alternateName):'' 
                this.VCExtRefNo = event[i].hasOwnProperty('externalRefNo')?this.chkplndata(event[i].externalRefNo):'' 
                this.VCGcCover = event[i].hasOwnProperty('gcCoverage')?this.chkplndata(event[i].gcCoverage):'' 
                this.VCIrdaUINNo = event[i].hasOwnProperty('irdaUanNo')?this.chkplndata(event[i].irdaUanNo):'' 
                this.VCRemarks = event[i].hasOwnProperty('remarks')?this.chkplndata(event[i].remarks):'' 
                this.VCCondField = event[i].hasOwnProperty('conditions')?this.chkplndataarry(event[i].conditions):[] 
                this.VCExclus = event[i].hasOwnProperty('exclusions')?this.chkplndataarry(event[i].exclusions):[]
                this.vcExclusions = event[i].hasOwnProperty('visionAndTreatmentExclusion')?this.chkplndataarry(event[i].visionAndTreatmentExclusion):[]
                // this.iftMdclExpnse = event[i].hasOwnProperty('coverageApplicableDuration')?this.chkplndata(event[i].coverageApplicableDuration):'' 

                if(this.VCSelectBox){
                    let _relCoverDet = event[i].hasOwnProperty('relationShipsCoveredDetails')?this.chkplndataarry(event[i].relationShipsCoveredDetails):[]
                    this.process_relationship_dependent(_relCoverDet,res=>{
                        this.VCrelCoverData = {}
                        this.VCrelCoverData = res
                        this.showVCRelData = true
                    })
                }
                this.VCRelCheckSelected()
                this.formDataVC.push(event[i])
             
            }else if(event[i].hasOwnProperty('coverageCode') && event[i].coverageCode == "IT"){
                this.iftCoverageType = event[i].hasOwnProperty('coverageType')?this.chkplndata(event[i].coverageType):''
                this.iftCoverageBasis = event[i].hasOwnProperty('coverageBasis')?this.chkplndata(event[i].coverageBasis):''
                this.chngIftCb()
                this.iftSubLimitBasis = event[i].hasOwnProperty('subLimitBasis')?this.chkplndataarry(event[i].subLimitBasis):[] 
                this.chngIfSubLmtBasis(this.iftSubLimitBasis)
                this.iftClaimPaymentBasis = event[i].hasOwnProperty('claimPaymentBasis')?this.chkplndata(event[i].claimPaymentBasis):''
                this.changeItClaimPayBasis(this.iftClaimPaymentBasis)
                this.iftWaitingPeriod = event[i].hasOwnProperty('waitingPeriod')?this.chkplndata(event[i].waitingPeriod):'' 
                this.ITwaitingPeriodDrop = event[i].hasOwnProperty('waitingPeroidType')?this.chkplndata(event[i].waitingPeroidType):'Days' 
                this.ItFlatAmountTxtField = event[i].hasOwnProperty('flatAmount')?this.chkplndata(event[i].flatAmount):'' 
                this.ItPercentSI = event[i].hasOwnProperty('perOfSi')?this.chkplndata(event[i].perOfSi):'' 
                this.ItPrvPercentSI = event[i].hasOwnProperty('perOfPreviousSi')?this.chkplndata(event[i].perOfPreviousSi):''
                this.ITOpdSi = event[i].hasOwnProperty('perOfOpdSi')?this.chkplndata(event[i].perOfOpdSi):'' 
                this.ITAdSi = event[i].hasOwnProperty('perOfAdSi')?this.chkplndata(event[i].perOfAdSi):''
                this.ItPercentClaimAmt = event[i].hasOwnProperty('perOfClaimAmount')?this.chkplndata(event[i].perOfClaimAmount):'' 
                this.ItApplicClaimPayOut = event[i].hasOwnProperty('applicableClaimPayout')?this.chkplndata(event[i].applicableClaimPayout):'' 
                this.iftExclsns = event[i].hasOwnProperty('exclusions')?this.chkplndataarry(event[i].exclusions):[] 
                this.ItCovrageApplicDur = event[i].hasOwnProperty('coverageApplicableDuration')?this.chkplndata(event[i].coverageApplicableDuration):'' 
                this.iftMdclExpnse = event[i].hasOwnProperty('typeOfMedicalExpense')?this.chkplndataarry(event[i].typeOfMedicalExpense):''
                this.ITCoverAltName = event[i].hasOwnProperty('alternateName')?this.chkplndata(event[i].alternateName):'' 
                this.ITExtRefNo = event[i].hasOwnProperty('externalRefNo')?this.chkplndata(event[i].externalRefNo):'' 
                this.ITGcCover = event[i].hasOwnProperty('gcCoverage')?this.chkplndata(event[i].gcCoverage):'' 
                this.ITIrdaUINNo = event[i].hasOwnProperty('irdaUanNo')?this.chkplndata(event[i].irdaUanNo):'' 
                this.ITRemarks = event[i].hasOwnProperty('remarks')?this.chkplndata(event[i].remarks):'' 
                this.ITCondField = event[i].hasOwnProperty('conditions')?this.chkplndataarry(event[i].conditions):[] 
                this.ITExclus = event[i].hasOwnProperty('exclusions')?this.chkplndataarry(event[i].exclusions):[]
                this.ITSelectBox = event[i].hasOwnProperty('relationship')?this.chkplndata(event[i].relationship):false
                this.iftExclsns = event[i].hasOwnProperty('visionAndTreatmentExclusion')?this.chkplndataarry(event[i].visionAndTreatmentExclusion):[]
                
                if(this.ITSelectBox){
                    let _relCoverDet = event[i].hasOwnProperty('relationShipsCoveredDetails')?this.chkplndataarry(event[i].relationShipsCoveredDetails):[]
                    this.process_relationship_dependent(_relCoverDet,res=>{
                        this.ITrelCoverData = {}
                        this.ITrelCoverData = res
                        this.showITRelData = true
                    })
                }
                this.ITRelCheckSelected()
                this.formDataIT.push(event[i])
            }
        }
    },

    VCRelCheckSelected(){
        this.showVCRelSection=false
        this.showVCRelData = false
        if (this.VCSelectBox){
            this.showVCRelSection=true
            this.showVCRelData = true
        }
    },
    ITRelCheckSelected(){
        this.showITRelSection=false
        this.showITRelData = false
        if (this.ITSelectBox){
            this.showITRelSection=true
            this.showITRelData = true
        }
    },
     
    getADBPTDData(event){
        for(let i=0; i < event.length; i++){
            if(event[i].hasOwnProperty('coverageCode') && event[i].coverageCode == "ADB"){
                this.adbCoverageType = event[i].hasOwnProperty('coverageType')?this.chkplndata(event[i].coverageType):''
                this.adbCoverageBasis = event[i].hasOwnProperty('coverageBasis')?this.chkplndata(event[i].coverageBasis):''
                this.chngAbdCb()
                this.adbSubLimitBasis = event[i].hasOwnProperty('subLimitBasis')?this.chkplndataarry(event[i].subLimitBasis):[] 
                this.chngAbdSubLmtBasis(this.adbSubLimitBasis)
                this.adbClaimPaymentBasis = event[i].hasOwnProperty('claimPaymentBasis')?this.chkplndata(event[i].claimPaymentBasis):'' 
                this.changeADBClaimPayBasis(this.adbClaimPaymentBasis)
                this.ADBwaitingPeriod = event[i].hasOwnProperty('waitingPeriod')?this.chkplndata(event[i].waitingPeriod):'' 
                this.ADBwaitingPeriodDrop = event[i].hasOwnProperty('waitingPeroidType')?this.chkplndata(event[i].waitingPeroidType):'Days' 
                this.AccDBFlatAmountTxtField = event[i].hasOwnProperty('flatAmount')?this.chkplndata(event[i].flatAmount):'' 
                this.AccDBpercentSI = event[i].hasOwnProperty('perOfSi')?this.chkplndata(event[i].perOfSi):''
                this.AccDBPrvpercentSI = event[i].hasOwnProperty('perOfPreviousSi')?this.chkplndata(event[i].perOfPreviousSi):'' 
                this.ADBOpdSi = event[i].hasOwnProperty('perOfOpdSi')?this.chkplndata(event[i].perOfOpdSi):'' 
                this.ADBAdSi = event[i].hasOwnProperty('perOfAdSi')?this.chkplndata(event[i].perOfAdSi):'' 
                this.AccDBPercentClaimAmt = event[i].hasOwnProperty('perOfClaimAmount')?this.chkplndata(event[i].perOfClaimAmount):'' 
                this.AccDBApplicClaimPayOut = event[i].hasOwnProperty('applicableClaimPayout')?this.chkplndata(event[i].applicableClaimPayout):'' 
                this.ADBCovrageApplicDur = event[i].hasOwnProperty('coverageApplicableDuration')?this.chkplndata(event[i].coverageApplicableDuration):'' 
                this.abdcheckbox = event[i].hasOwnProperty('relationship')?this.chkplndata(event[i].relationship):false 
                this.abdmxdysAccdnt = event[i].hasOwnProperty('maxNoOfDaysFromDateOfAccident')?this.chkplndata(event[i].maxNoOfDaysFromDateOfAccident):'' 
                this.abdDsaprncAplcbl = event[i].hasOwnProperty('disappearanceApplicable')?this.chkplndata(event[i].disappearanceApplicable):'' 
                this.abdmxdysDsaprnc = event[i].hasOwnProperty('maxNoOfDaysFromDateOfDisappearance')?this.chkplndata(event[i].maxNoOfDaysFromDateOfDisappearance):'' 
                this.ADBCoverAltName = event[i].hasOwnProperty('alternateName')?this.chkplndata(event[i].alternateName):'' 
                this.ADBExtRefNo = event[i].hasOwnProperty('externalRefNo')?this.chkplndata(event[i].externalRefNo):'' 
                this.ADBGcCover = event[i].hasOwnProperty('gcCoverage')?this.chkplndata(event[i].gcCoverage):'' 
                this.ADBIrdaUINNo = event[i].hasOwnProperty('irdaUanNo')?this.chkplndata(event[i].irdaUanNo):'' 
                this.ADBRemarks = event[i].hasOwnProperty('remarks')?this.chkplndata(event[i].remarks):'' 
                this.ADBCondField = event[i].hasOwnProperty('conditions')?this.chkplndataarry(event[i].conditions):[] 
                this.ADBExclus = event[i].hasOwnProperty('exclusions')?this.chkplndataarry(event[i].exclusions):[]
                this.ADBAllowdDurFrmDOAcc = event[i].hasOwnProperty('allowedDurationFromDateOfAccident')?this.chkplndata(event[i].allowedDurationFromDateOfAccident):''

                if(this.abdcheckbox){
                    let _relCoverDet = event[i].hasOwnProperty('relationShipsCoveredDetails')?this.chkplndataarry(event[i].relationShipsCoveredDetails):[]
                    this.process_relationship_dependent(_relCoverDet,res=>{
                        this.ABDrelCoverData = {}
                        this.ABDrelCoverData = res
                        this.showABDRelData = true
                    })
                }
                this.ADBRelCheckSelected()
                this.formDataADB.push(event[i])
             
            }else if(event[i].hasOwnProperty('coverageCode') && event[i].coverageCode == "PTD"){
                this.ptdCoverageType = event[i].hasOwnProperty('coverageType')?this.chkplndata(event[i].coverageType):''
                this.ptdCoverageBasis = event[i].hasOwnProperty('coverageBasis')?this.chkplndata(event[i].coverageBasis):''
                this.chngPtdCb()
                this.ptdSubLimitBasis = event[i].hasOwnProperty('subLimitBasis')?this.chkplndataarry(event[i].subLimitBasis):[] 
                this.chngPtdSubLmtBasis(this.ptdSubLimitBasis)
                this.ptdClaimPaymentBasis = event[i].hasOwnProperty('claimPaymentBasis')?this.chkplndata(event[i].claimPaymentBasis):''
                this.changePTDClaimPayBasis(this.ptdClaimPaymentBasis)
                this.PTDFlatAmountTxtField = event[i].hasOwnProperty('flatAmount')?this.chkplndata(event[i].flatAmount):'' 
                this.PTDpercentSI = event[i].hasOwnProperty('perOfSi')?this.chkplndata(event[i].perOfSi):'' 
                this.PTDPrvpercentSI = event[i].hasOwnProperty('perOfPreviousSi')?this.chkplndata(event[i].perOfPreviousSi):'' 
                this.PTDOpdSi = event[i].hasOwnProperty('perOfOpdSi')?this.chkplndata(event[i].perOfOpdSi):'' 
                this.PTDAdSi = event[i].hasOwnProperty('perOfAdSi')?this.chkplndata(event[i].perOfAdSi):''
                this.PTDPercentClaimAmt = event[i].hasOwnProperty('perOfClaimAmount')?this.chkplndata(event[i].perOfClaimAmount):'' 
                this.PTDApplicClaimPayOut = event[i].hasOwnProperty('applicableClaimPayout')?this.chkplndata(event[i].applicableClaimPayout):'' 
                this.PTDCovrageApplicDur = event[i].hasOwnProperty('coverageApplicableDuration')?this.chkplndata(event[i].coverageApplicableDuration):'' 
                this.ptdCheckbox = event[i].hasOwnProperty('relationship')?this.chkplndata(event[i].relationship):false 
                this.ptdNatureofLoss = event[i].hasOwnProperty('natureOfLoss')?this.chkplndata(event[i].natureOfLoss):'' 
                this.PTDCoverAltName = event[i].hasOwnProperty('alternateName')?this.chkplndata(event[i].alternateName):'' 
                this.PTDExtRefNo = event[i].hasOwnProperty('externalRefNo')?this.chkplndata(event[i].externalRefNo):'' 
                this.PTDGcCover = event[i].hasOwnProperty('gcCoverage')?this.chkplndata(event[i].gcCoverage):'' 
                this.PTDIrdaUINNo = event[i].hasOwnProperty('irdaUanNo')?this.chkplndata(event[i].irdaUanNo):'' 
                this.PTDRemarks = event[i].hasOwnProperty('remarks')?this.chkplndata(event[i].remarks):'' 
                this.PTDCondField = event[i].hasOwnProperty('conditions')?this.chkplndataarry(event[i].conditions):[] 
                this.PTDExclus = event[i].hasOwnProperty('exclusions')?this.chkplndataarry(event[i].exclusions):[]
                this.PTDwaitingPeriod = event[i].hasOwnProperty('waitingPeriod')?this.chkplndata(event[i].waitingPeriod):'' 
                this.PTDwaitingPeriodDrop = event[i].hasOwnProperty('waitingPeroidType')?this.chkplndata(event[i].waitingPeroidType):'Days'
                this.PTDDurFromAccDate = event[i].hasOwnProperty('allowedDurationFromDateOfAccident')?this.chkplndata(event[i].allowedDurationFromDateOfAccident):''
                this.PTDDisbleContPer = event[i].hasOwnProperty('disabilityContinuityPeriod')?this.chkplndata(event[i].disabilityContinuityPeriod):''
                this.PTDfeatureClaim = event[i].hasOwnProperty('featureClaim')?this.chkplndata(event[i].featureClaim):false
                this.PTDBenpay = event[i].hasOwnProperty('benefitPayable')?this.chkplndata(event[i].benefitPayable):''
                this.PTDPrimCover = event[i].hasOwnProperty('primaryCoverages')?this.chkplndata(event[i].primaryCoverages):[]

                if(this.ptdCheckbox){
                    let _relCoverDet = event[i].hasOwnProperty('relationShipsCoveredDetails')?this.chkplndataarry(event[i].relationShipsCoveredDetails):[]
                    this.process_relationship_dependent(_relCoverDet,res=>{
                        // alert("res Gc Relationship covered")
                        console.log(res,"res Gc Relationship covered")
                        this.PTDrelCoverData = {}
                        this.PTDrelCoverData = res
                        this.showPTDRelData = true
                    })
                }
                this.PTDRelCheckSelected()
                this.formDataPTD.push(event[i])
            }
        }
    },
    ADBRelCheckSelected(){
        this.showABDRelSection=false
        this.showABDRelData = false
        if (this.abdcheckbox){
            this.showABDRelSection=true
            this.showABDRelData = true
        }
    },
    PTDRelCheckSelected(){
        this.showPTDRelSection=false
        this.showPTDRelData = false
        if (this.ptdCheckbox){
            this.showPTDRelSection=true
            this.showPTDRelData = true
        }
    },
    getEBNAData(event){
         for(let i=0; i < event.length; i++){
            if(event[i].hasOwnProperty('coverageCode') && event[i].coverageCode == "EB"){
                this.ebCoverageType = event[i].hasOwnProperty('coverageType')?this.chkplndata(event[i].coverageType):''
                this.ebCoverageBasis = event[i].hasOwnProperty('coverageBasis')?this.chkplndata(event[i].coverageBasis):''
                this.chngEbCb()
                this.ebSubLimitBasis = event[i].hasOwnProperty('subLimitBasis')?this.chkplndataarry(event[i].subLimitBasis):[] 
                this.chngEbSubLmtBasis(this.ebSubLimitBasis)
                this.ebClaimPaymentBasis = event[i].hasOwnProperty('claimPaymentBasis')?this.chkplndata(event[i].claimPaymentBasis):''
                this.changeEBClaimPayBasis(this.ebClaimPaymentBasis)
                this.EBwaitingPeriod = event[i].hasOwnProperty('waitingPeriod')?this.chkplndata(event[i].waitingPeriod):'' 
                this.EBwaitingPeriodDrop = event[i].hasOwnProperty('waitingPeroidType')?this.chkplndata(event[i].waitingPeroidType):'Days'
                this.EBFlatAmountTxtField = event[i].hasOwnProperty('flatAmount')?this.chkplndata(event[i].flatAmount):'' 
                this.EBPercentSI = event[i].hasOwnProperty('perOfSi')?this.chkplndata(event[i].perOfSi):'' 
                this.EBOpdSi = event[i].hasOwnProperty('perOfOpdSi')?this.chkplndata(event[i].perOfOpdSi):'' 
                this.EBAdSi = event[i].hasOwnProperty('perOfAdSi')?this.chkplndata(event[i].perOfAdSi):''
                this.EBPrvPercentSI = event[i].hasOwnProperty('perOfPreviousSi')?this.chkplndata(event[i].perOfPreviousSi):'' 
                this.EBPercentClaimAmt = event[i].hasOwnProperty('perOfClaimAmount')?this.chkplndata(event[i].perOfClaimAmount):'' 
                this.EBApplicClaimPayOut = event[i].hasOwnProperty('applicableClaimPayout')?this.chkplndata(event[i].applicableClaimPayout):'' 
                this.EBCovrageApplicDur = event[i].hasOwnProperty('coverageApplicableDuration')?this.chkplndata(event[i].coverageApplicableDuration):'' 
                this.EBCoverAltName = event[i].hasOwnProperty('alternateName')?this.chkplndata(event[i].alternateName):'' 
                this.EBExtRefNo = event[i].hasOwnProperty('externalRefNo')?this.chkplndata(event[i].externalRefNo):'' 
                this.EBGcCover = event[i].hasOwnProperty('gcCoverage')?this.chkplndata(event[i].gcCoverage):'' 
                this.EBIrdaUINNo = event[i].hasOwnProperty('irdaUanNo')?this.chkplndata(event[i].irdaUanNo):'' 
                this.EBRemarks = event[i].hasOwnProperty('remarks')?this.chkplndata(event[i].remarks):'' 
                this.EBCondField = event[i].hasOwnProperty('conditions')?this.chkplndataarry(event[i].conditions):[] 
                this.EBExclus = event[i].hasOwnProperty('exclusions')?this.chkplndataarry(event[i].exclusions):[]
                this.ebMxChldAge = event[i].hasOwnProperty('maxChildAge')?this.chkplndata(event[i].maxChildAge):'' 
                this.EBSelectBox = event[i].hasOwnProperty('relationship')?this.chkplndata(event[i].relationship):false 
                this.EBPrimCover = event[i].hasOwnProperty('primaryCoverages')?this.chkplndata(event[i].primaryCoverages):[]
                this.EBBenpay = event[i].hasOwnProperty('benefitPayable')?this.chkplndata(event[i].benefitPayable):''
                

                if(this.EBSelectBox){
                    let _relCoverDet = event[i].hasOwnProperty('relationShipsCoveredDetails')?this.chkplndataarry(event[i].relationShipsCoveredDetails):[]
                    this.process_relationship_dependent(_relCoverDet,res=>{
                        this.EBrelCoverData = {}
                        this.EBrelCoverData = res
                        this.showEBRelData = true
                    })
                }
                this.EBRelCheckSelected()
                this.formDataEB.push(event[i])
             
            }else if(event[i].hasOwnProperty('coverageCode') && event[i].coverageCode == "NA"){
                this.naCoverageType = event[i].hasOwnProperty('coverageType')?this.chkplndata(event[i].coverageType):''
                this.naCoverageBasis = event[i].hasOwnProperty('coverageBasis')?this.chkplndata(event[i].coverageBasis):''
                this.chngNaCb()
                this.naSubLimitBasis = event[i].hasOwnProperty('subLimitBasis')?this.chkplndataarry(event[i].subLimitBasis):[] 
                this.chngNaSubLmtBasis(this.naSubLimitBasis)
                this.naClaimPaymentBasis = event[i].hasOwnProperty('claimPaymentBasis')?this.chkplndata(event[i].claimPaymentBasis):''
                this.changeNAClaimPayBasis(this.naClaimPaymentBasis)
                this.naWtngPrd = event[i].hasOwnProperty('waitingPeriod')?this.chkplndata(event[i].waitingPeriod):'' 
                this.NAwaitingPeriodDrop = event[i].hasOwnProperty('waitingPeroidType')?this.chkplndata(event[i].waitingPeroidType):'Days' 
                this.NAFlatAmountTxtField = event[i].hasOwnProperty('flatAmount')?this.chkplndata(event[i].flatAmount):'' 
                this.NAPercentSI = event[i].hasOwnProperty('perOfSi')?this.chkplndata(event[i].perOfSi):'' 
                this.NAPrvPercentSI = event[i].hasOwnProperty('perOfPreviousSi')?this.chkplndata(event[i].perOfPreviousSi):''
                this.NAOpdSi = event[i].hasOwnProperty('perOfOpdSi')?this.chkplndata(event[i].perOfOpdSi):'' 
                this.NAAdSi = event[i].hasOwnProperty('perOfAdSi')?this.chkplndata(event[i].perOfAdSi):''
                this.NAPercentClaimAmt = event[i].hasOwnProperty('perOfClaimAmount')?this.chkplndata(event[i].perOfClaimAmount):'' 
                this.NAApplicClaimPayOut = event[i].hasOwnProperty('applicableClaimPayout')?this.chkplndata(event[i].applicableClaimPayout):'' 
                this.NACovrageApplicDur = event[i].hasOwnProperty('coverageApplicableDuration')?this.chkplndata(event[i].coverageApplicableDuration):'' 
                this.naMxPyblDrtn = event[i].hasOwnProperty('maxPayableDuration')?this.chkplndata(event[i].maxPayableDuration.toString()):''
                this.naDeductible = event[i].hasOwnProperty('deductible')?this.chkplndata(event[i].deductible.toString()):''

                this.NACoverAltName = event[i].hasOwnProperty('alternateName')?this.chkplndata(event[i].alternateName):'' 
                this.NAExtRefNo = event[i].hasOwnProperty('externalRefNo')?this.chkplndata(event[i].externalRefNo):'' 
                this.NAGcCover = event[i].hasOwnProperty('gcCoverage')?this.chkplndata(event[i].gcCoverage):'' 
                this.NAIrdaUINNo = event[i].hasOwnProperty('irdaUanNo')?this.chkplndata(event[i].irdaUanNo):'' 
                this.NARemarks = event[i].hasOwnProperty('remarks')?this.chkplndata(event[i].remarks):'' 
                this.NACondField = event[i].hasOwnProperty('conditions')?this.chkplndataarry(event[i].conditions):[] 
                this.NAExclus = event[i].hasOwnProperty('exclusions')?this.chkplndataarry(event[i].exclusions):[]
                this.NASelectBox = event[i].hasOwnProperty('relationship')?this.chkplndata(event[i].relationship):false 
                
                if(this.NASelectBox){
                    let _relCoverDet = event[i].hasOwnProperty('relationShipsCoveredDetails')?this.chkplndataarry(event[i].relationShipsCoveredDetails):[]
                    this.process_relationship_dependent(_relCoverDet,res=>{
                        this.NArelCoverData = {}
                        this.NArelCoverData = res
                        this.showNARelData = true
                    })
                }
                this.NARelCheckSelected()
                this.formDataNA.push(event[i])
            }
        }
    },
    EBRelCheckSelected(){
        this.showEBRelSection=false
        this.showEBRelData = false
        if (this.EBSelectBox){
            this.showEBRelSection=true
            this.showEBRelData = true
        }
    },
    NARelCheckSelected(){
        this.showNARelSection=false
        this.showNARelData = false
        if (this.NASelectBox){
            this.showNARelSection=true
            this.showNARelData = true
        }
    },
    getHCBHCBACIData(event){
        for(let i=0; i < event.length; i++){
            if(event[i].hasOwnProperty('coverageCode') && event[i].coverageCode == "HCB"){
                this.hcbcoverageType = event[i].hasOwnProperty('coverageType')?this.chkplndata(event[i].coverageType):''
                this.hcbcoverageBasis = event[i].hasOwnProperty('coverageBasis')?this.chkplndata(event[i].coverageBasis):''
                this.hcbchangeOnover()
                this.hcbsubLimitBasis = event[i].hasOwnProperty('subLimitBasis')?this.chkplndataarry(event[i].subLimitBasis):[] 
                this.hcbflatchange(this.hcbsubLimitBasis)
                this.hcbclaimPaymentBasis = event[i].hasOwnProperty('claimPaymentBasis')?this.chkplndata(event[i].claimPaymentBasis):'' 
                this.changeHCBClaimPayBasis(this.hcbclaimPaymentBasis)
                this.hcbwaitingPeriod = event[i].hasOwnProperty('waitingPeriod')?this.chkplndata(event[i].waitingPeriod):'' 
                this.hcbwaitingPeriodDrop = event[i].hasOwnProperty('waitingPeroidType')?this.chkplndata(event[i].waitingPeroidType):'Days' 
                this.HCBFlatAmountTxtField = event[i].hasOwnProperty('flatAmount')?this.chkplndata(event[i].flatAmount):'' 
                this.HCBPercentSI = event[i].hasOwnProperty('perOfSi')?this.chkplndata(event[i].perOfSi):'' 
                this.HCBPrvPercentSI = event[i].hasOwnProperty('perOfPreviousSi')?this.chkplndata(event[i].perOfPreviousSi):'' 
                this.HCBPercentClaimAmt = event[i].hasOwnProperty('perOfClaimAmount')?this.chkplndata(event[i].perOfClaimAmount):'' 
                this.hcbapplicableClaimPayOut = event[i].hasOwnProperty('applicableClaimPayout')?this.chkplndata(event[i].applicableClaimPayout):'' 
                this.HCBCovrageApplicDur = event[i].hasOwnProperty('coverageApplicableDuration')?this.chkplndata(event[i].coverageApplicableDuration):'' 
                this.hcbminHosDuration = event[i].hasOwnProperty('minHospitalization')?this.chkplndata(event[i].minHospitalization):'' 
                this.HCBCoverAltName = event[i].hasOwnProperty('alternateName')?this.chkplndata(event[i].alternateName):'' 
                this.HCBExtRefNo = event[i].hasOwnProperty('externalRefNo')?this.chkplndata(event[i].externalRefNo):'' 
                this.HCBGcCover = event[i].hasOwnProperty('gcCoverage')?this.chkplndata(event[i].gcCoverage):'' 
                this.HCBIrdaUINNo = event[i].hasOwnProperty('irdaUanNo')?this.chkplndata(event[i].irdaUanNo):'' 
                this.HCBRemarks = event[i].hasOwnProperty('remarks')?this.chkplndata(event[i].remarks):'' 
                this.HCBCondField = event[i].hasOwnProperty('conditions')?this.chkplndataarry(event[i].conditions):[] 
                this.HCBExclus = event[i].hasOwnProperty('exclusions')?this.chkplndataarry(event[i].exclusions):[] 
                this.HCBSelectBox = event[i].hasOwnProperty('relationship')?this.chkplndata(event[i].relationship):false 
                if(this.HCBSelectBox){
                    let _relCoverDet = event[i].hasOwnProperty('relationShipsCoveredDetails')?this.chkplndataarry(event[i].relationShipsCoveredDetails):[]
                    this.process_relationship_dependent(_relCoverDet,res=>{
                        this.HCBrelCoverData = {}
                        this.HCBrelCoverData = res
                        this.showHCBRelData = true
                    })
                }
                this.HCBRelCheckSelected()

                if(event[i].hasOwnProperty('accommodationDetails') == true){
                    this.hcbcauseType1 = event[i].accommodationDetails[0].causeType
                    this.hcbroomType1 = event[i].accommodationDetails[0].roomType
                    this.hcbdailyCash1 = event[i].accommodationDetails[0].multiplierOfDailyCash

                    this.hcbcauseType2 = event[i].accommodationDetails[1].causeType
                    this.hcbroomType2 = event[i].accommodationDetails[1].roomType
                    this.hcbdailyCash2 = event[i].accommodationDetails[1].multiplierOfDailyCash

                    this.hcbcauseType3 = event[i].accommodationDetails[2].causeType
                    this.hcbroomType3 = event[i].accommodationDetails[2].roomType
                    this.hcbdailyCash3 = event[i].accommodationDetails[2].multiplierOfDailyCash

                    this.hcbcauseType4 = event[i].accommodationDetails[3].causeType
                    this.hcbroomType4 = event[i].accommodationDetails[3].roomType
                    this.hcbdailyCash4 = event[i].accommodationDetails[3].multiplierOfDailyCash
                }
                // delete event[i]._id
                delete event[i].accommodationDetails[0]._id
                delete event[i].accommodationDetails[1]._id
                delete event[i].accommodationDetails[2]._id
                delete event[i].accommodationDetails[3]._id
                this.formDataHCB.push(event[i])
             
            }else if(event[i].hasOwnProperty('coverageCode') && event[i].coverageCode == "HCBACI"){
                this.hcbccoverageType = event[i].hasOwnProperty('coverageType')?this.chkplndata(event[i].coverageType):''
                this.hcbccoverageBasis = event[i].hasOwnProperty('coverageBasis')?this.chkplndata(event[i].coverageBasis):''
                this.hcbcchangeOnover()
                this.hcbcsubLimitBasis = event[i].hasOwnProperty('subLimitBasis')?this.chkplndataarry(event[i].subLimitBasis):[] 
                this.hcbcflatchange(this.hcbcsubLimitBasis)
                this.hcbcclaimPaymentBasis = event[i].hasOwnProperty('claimPaymentBasis')?this.chkplndata(event[i].claimPaymentBasis):'' 
                this.changeHCBACIClaimPayBas(this.hcbcclaimPaymentBasis)
                this.hcbcwaitingPeriod = event[i].hasOwnProperty('waitingPeriod')?this.chkplndata(event[i].waitingPeriod):'' 
                this.HCBACIFlatAmountTxtField = event[i].hasOwnProperty('flatAmount')?this.chkplndata(event[i].flatAmount):'' 
                this.HCBACIPercentSI = event[i].hasOwnProperty('perOfSi')?this.chkplndata(event[i].perOfSi):'' 
                this.HCBACIPrvPercentSI = event[i].hasOwnProperty('perOfPreviousSi')?this.chkplndata(event[i].perOfPreviousSi):'' 
                this.HCBACIPercentClaimAmt = event[i].hasOwnProperty('perOfClaimAmount')?this.chkplndata(event[i].perOfClaimAmount):'' 
                this.hcbcapplicableClaimPayOut = event[i].hasOwnProperty('applicableClaimPayout')?this.chkplndata(event[i].applicableClaimPayout):'' 
                this.HCBACICovrageApplicDur = event[i].hasOwnProperty('coverageApplicableDuration')?this.chkplndata(event[i].coverageApplicableDuration):'' 
                this.hcbcminHosDuration = event[i].hasOwnProperty('minHospitalization')?this.chkplndata(event[i].minHospitalization):'' 
                this.hcbcMaxAgeChild = event[i].hasOwnProperty('maximumAgeOfInsuredChild')?this.chkplndata(event[i].maximumAgeOfInsuredChild):'' 
                if(event[i].hasOwnProperty('accommodationDetails') == true){
                    this.hcbccauseType1 = event[i].accommodationDetails[0].causeType
                    this.hcbcroomType1 = event[i].accommodationDetails[0].roomType
                    this.hcbcdailyCash1 = event[i].accommodationDetails[0].multiplierOfDailyCash

                    this.hcbccauseType2 = event[i].accommodationDetails[1].causeType
                    this.hcbcroomType2 = event[i].accommodationDetails[1].roomType
                    this.hcbcdailyCash2 = event[i].accommodationDetails[1].multiplierOfDailyCash

                    this.hcbccauseType3 = event[i].accommodationDetails[2].causeType
                    this.hcbcroomType3 = event[i].accommodationDetails[2].roomType
                    this.hcbcdailyCash3 = event[i].accommodationDetails[2].multiplierOfDailyCash

                    this.hcbccauseType4 = event[i].accommodationDetails[3].causeType
                    this.hcbcroomType4 = event[i].accommodationDetails[3].roomType
                    this.hcbcdailyCash4 = event[i].accommodationDetails[3].multiplierOfDailyCash
                }
                // delete event[i]._id
                delete event[i].accommodationDetails[0]._id
                delete event[i].accommodationDetails[1]._id
                delete event[i].accommodationDetails[2]._id
                delete event[i].accommodationDetails[3]._id
                this.formDataHCBACI.push(event[i])
            }
        }
    },
    HCBRelCheckSelected(){
        this.showHCBRelSection=false
        this.showHCBRelData = false
        if (this.HCBSelectBox){
            this.showHCBRelSection=true
            this.showHCBRelData = true
        }
    },

    getIPHData(event){
        this.IPCoverCheck = event.hasOwnProperty('coverageCode')?this.chkplndataarry(event.coverageCode):[]
        this.inPtntHzptnCoverageType = event.hasOwnProperty('coverageType')?this.chkplndata(event.coverageType):''
        this.inPtntHzptnCoverageBasis = event.hasOwnProperty('coverageBasis')?this.chkplndata(event.coverageBasis):''
        this.chngInPtntHzptnCb()
        this.InPtntHzptnSubLimitBasis = event.hasOwnProperty('subLimitBasis')?this.chkplndataarry(event.subLimitBasis):[] 
        this.chngInPtntHzptnSubLmtBasis(this.InPtntHzptnSubLimitBasis)
        this.InPtntHzptnClaimPaymentBasis = event.hasOwnProperty('claimPaymentBasis')?this.chkplndata(event.claimPaymentBasis):'' 
        this.chngInPtntHzptnClaimPaymentBasis(this.InPtntHzptnClaimPaymentBasis)
        this.IPHFlatAmountTxtField = event.hasOwnProperty('flatAmount')?this.chkplndata(event.flatAmount):'' 
        this.IPHPercentSI = event.hasOwnProperty('perOfSi')?this.chkplndata(event.perOfSi):'' 
        this.IPHPrvPercentSI = event.hasOwnProperty('perOfPreviousSi')?this.chkplndata(event.perOfPreviousSi):'' 
        this.IPHPercentClaimAmt = event.hasOwnProperty('perOfClaimAmount')?this.chkplndata(event.perOfClaimAmount):'' 
        this.IPHapplicableClaimPayOut = event.hasOwnProperty('applicableClaimPayout')?this.chkplndata(event.applicableClaimPayout):'' 
        this.IPHCovrageApplicDur = event.hasOwnProperty('coverageApplicableDuration')?this.chkplndata(event.coverageApplicableDuration):'' 
        this.InPtntHzptnWaitingPeriod = event.hasOwnProperty('waitingPeriod')?this.chkplndata(event.waitingPeriod):'' 
        this.InPtntHzptnWaitingPeriodDrop = event.hasOwnProperty('waitingPeroidType')?this.chkplndata(event.waitingPeroidType):'Days' 
        this.InPtntHzptnMinHospDura = event.hasOwnProperty('minHospitalization')?this.chkplndata(event.minHospitalization):'' 
        this.inPtntHzptnCoPayapplicable = event.hasOwnProperty('coPayApplicable')?this.chkplndata(event.coPayApplicable):'No' 
        this.chngInPtntHzptnCopyAplcbl()
        this.IPHOpdSi = event.hasOwnProperty('perOfOpdSi')?this.chkplndata(event.perOfOpdSi):'' 
        this.IPHAdSi = event.hasOwnProperty('perOfAdSi')?this.chkplndata(event.perOfAdSi):'' 
        this.inPtntHzptnCoPayType = event.hasOwnProperty('coPayType')?this.chkplndata(event.coPayType):'' 
        this.InPtntHzptnCoPayPrcnt = event.hasOwnProperty('coPayPercent')?this.chkplndata(event.coPayPercent):'' 
        this.InPtntHzptnCoPayPrcntSr = event.hasOwnProperty('sharedRoomCopay')?this.chkplndata(event.sharedRoomCopay):'' 
        this.InPtntHzptnCoPayPrcntNr = event.hasOwnProperty('noRestriction')?this.chkplndata(event.noRestriction):'' 
        this.IPHMinAgeMemb = event.hasOwnProperty('minAgeOfNumber')?this.chkplndata(event.minAgeOfNumber):'' 
        this.IPHMinTreatDura = event.hasOwnProperty('minTreatmentDuration')?this.chkplndata(event.minTreatmentDuration):'' 
        this.InPtntHzptnSelectBox = event.hasOwnProperty('relationship')?this.chkplndata(event.relationship):false 
        this.IPHCoverAltName = event.hasOwnProperty('alternateName')?this.chkplndata(event.alternateName):'' 
        this.IPHExtRefNo = event.hasOwnProperty('externalRefNo')?this.chkplndata(event.externalRefNo):'' 
        this.IPHGcCover = event.hasOwnProperty('gcCoverage')?this.chkplndata(event.gcCoverage):'' 
        this.IPHIrdaUINNo = event.hasOwnProperty('irdaUanNo')?this.chkplndata(event.irdaUanNo):'' 
        this.IPHRemarks = event.hasOwnProperty('remarks')?this.chkplndata(event.remarks):'' 
        this.IPHCondField = event.hasOwnProperty('conditions')?this.chkplndataarry(event.conditions):[] 
        this.IPHExclus = event.hasOwnProperty('exclusions')?this.chkplndataarry(event.exclusions):[]
        
        if(this.InPtntHzptnSelectBox){
            let _relCoverDet = event.hasOwnProperty('relationShipsCoveredDetails')?this.chkplndataarry(event.relationShipsCoveredDetails):[]
            this.process_relationship_dependent(_relCoverDet,res=>{
                this.IPHrelCoverData = {}
                this.IPHrelCoverData = res
                this.showIPHRelData = true
            })
        }
        this.IPHRelCheckSelected()
        this.inPhDta=[]  
        this.inPhDta.push(event)
    },
    IPHRelCheckSelected(){
        this.showIPHRelSection=false
        this.showIPHRelData = false
        if (this.InPtntHzptnSelectBox){
            this.showIPHRelSection=true
            this.showIPHRelData = true
        }
    },

    getIPDData(event){
        this.IPDCoverageType = event.hasOwnProperty('coverageType')?this.chkplndata(event.coverageType):''
        this.IPDCoverageBasis = event.hasOwnProperty('coverageBasis')?this.chkplndata(event.coverageBasis):''
        this.changeIPDCoverBasis()
        this.IPDSubLimitBasis = event.hasOwnProperty('subLimitBasis')?this.chkplndataarry(event.subLimitBasis):[] 
        this.changeIPDSubLmtBasis(this.IPDSubLimitBasis)
        this.IPDClaimPaymentBasis = event.hasOwnProperty('claimPaymentBasis')?this.chkplndata(event.claimPaymentBasis):'' 
        this.changeIPDClaimPaymentBasis(this.IPDClaimPaymentBasis)
        this.IPDFlatAmountTxtField = event.hasOwnProperty('flatAmount')?this.chkplndata(event.flatAmount):'' 
        this.IPDPercentSI = event.hasOwnProperty('perOfSi')?this.chkplndata(event.perOfSi):'' 
        this.IPDPrvPercentSI = event.hasOwnProperty('perOfPreviousSi')?this.chkplndata(event.perOfPreviousSi):'' 
        this.IPDPercentClaimAmt = event.hasOwnProperty('perOfClaimAmount')?this.chkplndata(event.perOfClaimAmount):'' 
        this.IPDapplicableClaimPayOut = event.hasOwnProperty('applicableClaimPayout')?this.chkplndata(event.applicableClaimPayout):'' 
        this.IPDCovrageApplicDur = event.hasOwnProperty('coverageApplicableDuration')?this.chkplndata(event.coverageApplicableDuration):'' 
        this.IPDWaitingPeriod = event.hasOwnProperty('waitingPeriod')?this.chkplndata(event.waitingPeriod):'' 
        this.IPDWaitingPeriodDrop = event.hasOwnProperty('waitingPeroidType')?this.chkplndata(event.waitingPeroidType):'Days' 
        this.IPDMinHospDura = event.hasOwnProperty('minHospitalization')?this.chkplndata(event.minHospitalization):'' 
        this.IPDCoPayapplicable = event.hasOwnProperty('coPayApplicable')?this.chkplndata(event.coPayApplicable):'No' 
        this.changeIPDCopyAplcbl()
        this.IPDOpdSi = event.hasOwnProperty('perOfOpdSi')?this.chkplndata(event.perOfOpdSi):'' 
        this.IPDAdSi = event.hasOwnProperty('perOfAdSi')?this.chkplndata(event.perOfAdSi):'' 
        this.IPDMinAgeMemb = event.hasOwnProperty('minAgeOfNumber')?this.chkplndata(event.minAgeOfNumber):'' 
        this.IPDMinTreatDura = event.hasOwnProperty('minTreatmentDuration')?this.chkplndata(event.minTreatmentDuration):''
        this.IPDCoPayType = event.hasOwnProperty('coPayType')?this.chkplndata(event.coPayType):'' 
        this.IPDCoPayPrcnt = event.hasOwnProperty('coPayPercent')?this.chkplndata(event.coPayPercent):'' 
        this.IPDCoPayPrcntSr = event.hasOwnProperty('sharedRoomCopay')?this.chkplndata(event.sharedRoomCopay):'' 
        this.IPDCoPayPrcntNr = event.hasOwnProperty('noRestriction')?this.chkplndata(event.noRestriction):'' 
        this.IPDMnAgNo = event.hasOwnProperty('minAgeOfNumber')?this.chkplndata(event.minAgeOfNumber):'' 
        this.IPDMnTrtmntDrn = event.hasOwnProperty('minTreatmentDuration')?this.chkplndata(event.minTreatmentDuration):'' 
        this.IPDSelectBox = event.hasOwnProperty('relationship')?this.chkplndata(event.relationship):false 
        this.IPDCoverAltName = event.hasOwnProperty('alternateName')?this.chkplndata(event.alternateName):'' 
        this.IPDExtRefNo = event.hasOwnProperty('externalRefNo')?this.chkplndata(event.externalRefNo):'' 
        this.IPDGcCover = event.hasOwnProperty('gcCoverage')?this.chkplndata(event.gcCoverage):'' 
        this.IPDIrdaUINNo = event.hasOwnProperty('irdaUanNo')?this.chkplndata(event.irdaUanNo):'' 
        this.IPDRemarks = event.hasOwnProperty('remarks')?this.chkplndata(event.remarks):'' 
        this.IPDCondField = event.hasOwnProperty('conditions')?this.chkplndataarry(event.conditions):[] 
        this.IPDExclus = event.hasOwnProperty('exclusions')?this.chkplndataarry(event.exclusions):[]
        
        if(this.IPDSelectBox){
            let _relCoverDet = event.hasOwnProperty('relationShipsCoveredDetails')?this.chkplndataarry(event.relationShipsCoveredDetails):[]
            this.process_relationship_dependent(_relCoverDet,res=>{
                this.IPDrelCoverData = {}
                this.IPDrelCoverData = res
                this.showIPDRelData = true
            })
        }
        this.IPDRelCheckSelected()
        this.IPDData = []  
        this.IPDData.push(event)
    },
    IPDRelCheckSelected(){
        this.showIPDRelSection=false
        this.showIPDRelData = false
        if (this.IPDSelectBox){
            this.showIPDRelSection=true
            this.showIPDRelData = true
        }
    },

    getODData(event){
        //this.IPCoverCheck = event.hasOwnProperty('coverageCode')?this.chkplndataarry(event.coverageCode):[]
        this.ODCoverageType = event.hasOwnProperty('coverageType')?this.chkplndata(event.coverageType):''
        this.ODCoverageBasis = event.hasOwnProperty('coverageBasis')?this.chkplndata(event.coverageBasis):''
        this.changeODCoverBasis()
        this.ODSubLimitBasis = event.hasOwnProperty('subLimitBasis')?this.chkplndataarry(event.subLimitBasis):[] 
        this.changeODSubLmtBasis(this.ODSubLimitBasis)
        this.ODClaimPaymentBasis = event.hasOwnProperty('claimPaymentBasis')?this.chkplndata(event.claimPaymentBasis):'' 
        this.changeODClaimPaymentBasis(this.ODClaimPaymentBasis)
        this.ODFlatAmountTxtField = event.hasOwnProperty('flatAmount')?this.chkplndata(event.flatAmount):'' 
        this.ODPercentSI = event.hasOwnProperty('perOfSi')?this.chkplndata(event.perOfSi):'' 
        this.ODPrvPercentSI = event.hasOwnProperty('perOfPreviousSi')?this.chkplndata(event.perOfPreviousSi):'' 
        this.ODPercentClaimAmt = event.hasOwnProperty('perOfClaimAmount')?this.chkplndata(event.perOfClaimAmount):'' 
        this.ODapplicableClaimPayOut = event.hasOwnProperty('applicableClaimPayout')?this.chkplndata(event.applicableClaimPayout):'' 
        this.ODCovrageApplicDur = event.hasOwnProperty('coverageApplicableDuration')?this.chkplndata(event.coverageApplicableDuration):'' 
        this.ODWaitingPeriod = event.hasOwnProperty('waitingPeriod')?this.chkplndata(event.waitingPeriod):'' 
        this.ODWaitingPeriodDrop = event.hasOwnProperty('waitingPeroidType')?this.chkplndata(event.waitingPeroidType):'Days' 
        this.ODMinHospDura = event.hasOwnProperty('minHospitalization')?this.chkplndata(event.minHospitalization):'' 
        this.ODCoPayapplicable = event.hasOwnProperty('coPayApplicable')?this.chkplndata(event.coPayApplicable):'No' 
        this.changeODCopyAplcbl()
        this.ODOpdSi = event.hasOwnProperty('perOfOpdSi')?this.chkplndata(event.perOfOpdSi):'' 
        this.ODAdSi = event.hasOwnProperty('perOfAdSi')?this.chkplndata(event.perOfAdSi):'' 
        this.ODMinAgeMemb = event.hasOwnProperty('minAgeOfNumber')?this.chkplndata(event.minAgeOfNumber):'' 
        this.ODMinTreatDura = event.hasOwnProperty('minTreatmentDuration')?this.chkplndata(event.minTreatmentDuration):''
        this.ODCoPayType = event.hasOwnProperty('coPayType')?this.chkplndata(event.coPayType):'' 
        this.ODCoPayPrcnt = event.hasOwnProperty('coPayPercent')?this.chkplndata(event.coPayPercent):'' 
        this.ODCoPayPrcntSr = event.hasOwnProperty('sharedRoomCopay')?this.chkplndata(event.sharedRoomCopay):'' 
        this.ODCoPayPrcntNr = event.hasOwnProperty('noRestriction')?this.chkplndata(event.noRestriction):'' 
        this.ODMnAgNo = event.hasOwnProperty('minAgeOfNumber')?this.chkplndata(event.minAgeOfNumber):'' 
        this.ODMnTrtmntDrn = event.hasOwnProperty('minTreatmentDuration')?this.chkplndata(event.minTreatmentDuration):'' 
        this.ODSelectBox = event.hasOwnProperty('relationship')?this.chkplndata(event.relationship):false 
        this.ODCoverAltName = event.hasOwnProperty('alternateName')?this.chkplndata(event.alternateName):'' 
        this.ODExtRefNo = event.hasOwnProperty('externalRefNo')?this.chkplndata(event.externalRefNo):'' 
        this.ODGcCover = event.hasOwnProperty('gcCoverage')?this.chkplndata(event.gcCoverage):'' 
        this.ODIrdaUINNo = event.hasOwnProperty('irdaUanNo')?this.chkplndata(event.irdaUanNo):'' 
        this.ODRemarks = event.hasOwnProperty('remarks')?this.chkplndata(event.remarks):'' 
        this.ODCondField = event.hasOwnProperty('conditions')?this.chkplndataarry(event.conditions):[] 
        this.ODExclus = event.hasOwnProperty('exclusions')?this.chkplndataarry(event.exclusions):[]
        
        if(this.ODSelectBox){
            let _relCoverDet = event.hasOwnProperty('relationShipsCoveredDetails')?this.chkplndataarry(event.relationShipsCoveredDetails):[]
            this.process_relationship_dependent(_relCoverDet,res=>{
                this.ODrelCoverData = {}
                this.ODrelCoverData = res
                this.showODRelData = true
            })
        }
        this.ODRelCheckSelected()
        this.ODData = []  
        this.ODData.push(event)
    },
    ODRelCheckSelected(){
        this.showODRelSection=false
        this.showODRelData = false
        if (this.ODSelectBox){
            this.showODRelSection=true
            this.showODRelData = true
        }
    },

    getBSCData(event){
        //this.IPCoverCheck = event.hasOwnProperty('coverageCode')?this.chkplndataarry(event.coverageCode):[]
        this.BSCCoverageType = event.hasOwnProperty('coverageType')?this.chkplndata(event.coverageType):''
        this.BSCCoverageBasis = event.hasOwnProperty('coverageBasis')?this.chkplndata(event.coverageBasis):''
        this.changeBSCCoverBasis()
        this.BSCSubLimitBasis = event.hasOwnProperty('subLimitBasis')?this.chkplndataarry(event.subLimitBasis):[] 
        this.changeBSCSubLmtBasis(this.BSCSubLimitBasis)
        this.BSCClaimPaymentBasis = event.hasOwnProperty('claimPaymentBasis')?this.chkplndata(event.claimPaymentBasis):'' 
        this.changeBSCClaimPaymentBasis(this.BSCClaimPaymentBasis)
        this.BSCFlatAmountTxtField = event.hasOwnProperty('flatAmount')?this.chkplndata(event.flatAmount):'' 
        this.BSCPercentSI = event.hasOwnProperty('perOfSi')?this.chkplndata(event.perOfSi):'' 
        this.BSCPrvPercentSI = event.hasOwnProperty('perOfPreviousSi')?this.chkplndata(event.perOfPreviousSi):'' 
        this.BSCPercentClaimAmt = event.hasOwnProperty('perOfClaimAmount')?this.chkplndata(event.perOfClaimAmount):'' 
        this.BSCapplicableClaimPayOut = event.hasOwnProperty('applicableClaimPayout')?this.chkplndata(event.applicableClaimPayout):'' 
        this.BSCCovrageApplicDur = event.hasOwnProperty('coverageApplicableDuration')?this.chkplndata(event.coverageApplicableDuration):'' 
        this.BSCWaitingPeriod = event.hasOwnProperty('waitingPeriod')?this.chkplndata(event.waitingPeriod):'' 
        this.BSCWaitingPeriodDrop = event.hasOwnProperty('waitingPeroidType')?this.chkplndata(event.waitingPeroidType):'Days' 
        this.BSCMinHospDura = event.hasOwnProperty('minHospitalization')?this.chkplndata(event.minHospitalization):'' 
        this.BSCCoPayapplicable = event.hasOwnProperty('coPayApplicable')?this.chkplndata(event.coPayApplicable):'No' 
        this.changeBSCCopyAplcbl()
        this.BSCOpdSi = event.hasOwnProperty('perOfOpdSi')?this.chkplndata(event.perOfOpdSi):'' 
        this.BSCAdSi = event.hasOwnProperty('perOfAdSi')?this.chkplndata(event.perOfAdSi):'' 
        this.BSCMinAgeMemb = event.hasOwnProperty('minAgeOfNumber')?this.chkplndata(event.minAgeOfNumber):'' 
        this.BSCMinTreatDura = event.hasOwnProperty('minTreatmentDuration')?this.chkplndata(event.minTreatmentDuration):''
        this.BSCCoPayType = event.hasOwnProperty('coPayType')?this.chkplndata(event.coPayType):'' 
        this.BSCCoPayPrcnt = event.hasOwnProperty('coPayPercent')?this.chkplndata(event.coPayPercent):'' 
        this.BSCCoPayPrcntSr = event.hasOwnProperty('sharedRoomCopay')?this.chkplndata(event.sharedRoomCopay):'' 
        this.BSCCoPayPrcntNr = event.hasOwnProperty('noRestriction')?this.chkplndata(event.noRestriction):'' 
        this.BSCMnAgNo = event.hasOwnProperty('minAgeOfNumber')?this.chkplndata(event.minAgeOfNumber):'' 
        this.BSCMnTrtmntDrn = event.hasOwnProperty('minTreatmentDuration')?this.chkplndata(event.minTreatmentDuration):'' 
        this.BSCSelectBox = event.hasOwnProperty('relationship')?this.chkplndata(event.relationship):false 
        this.BSCCoverAltName = event.hasOwnProperty('alternateName')?this.chkplndata(event.alternateName):'' 
        this.BSCExtRefNo = event.hasOwnProperty('externalRefNo')?this.chkplndata(event.externalRefNo):'' 
        this.BSCGcCover = event.hasOwnProperty('gcCoverage')?this.chkplndata(event.gcCoverage):'' 
        this.BSCIrdaUINNo = event.hasOwnProperty('irdaUanNo')?this.chkplndata(event.irdaUanNo):'' 
        this.BSCRemarks = event.hasOwnProperty('remarks')?this.chkplndata(event.remarks):'' 
        this.BSCCondField = event.hasOwnProperty('conditions')?this.chkplndataarry(event.conditions):[] 
        this.BSCExclus = event.hasOwnProperty('exclusions')?this.chkplndataarry(event.exclusions):[]
        
        if(this.BSCSelectBox){
            let _relCoverDet = event.hasOwnProperty('relationShipsCoveredDetails')?this.chkplndataarry(event.relationShipsCoveredDetails):[]
            this.process_relationship_dependent(_relCoverDet,res=>{
                this.BSCrelCoverData = {}
                this.BSCrelCoverData = res
                this.showBSCRelData = true
            })
        }
        this.BSCRelCheckSelected()
        this.BSCData = []  
        this.BSCData.push(event)
    },
    BSCRelCheckSelected(){
        this.showBSCRelSection=false
        this.showBSCRelData = false
        if (this.BSCSelectBox){
            this.showBSCRelSection=true
            this.showBSCRelData = true
        }
    },

    getDTData(event){
        //this.IPCoverCheck = event.hasOwnProperty('coverageCode')?this.chkplndataarry(event.coverageCode):[]
        this.DTCoverageType = event.hasOwnProperty('coverageType')?this.chkplndata(event.coverageType):''
        this.DTCoverageBasis = event.hasOwnProperty('coverageBasis')?this.chkplndata(event.coverageBasis):''
        this.changeDTCoverBasis()
        this.DTSubLimitBasis = event.hasOwnProperty('subLimitBasis')?this.chkplndataarry(event.subLimitBasis):[] 
        this.changeDTSubLmtBasis(this.DTSubLimitBasis)
        this.DTClaimPaymentBasis = event.hasOwnProperty('claimPaymentBasis')?this.chkplndata(event.claimPaymentBasis):'' 
        this.changeDTClaimPaymentBasis(this.DTClaimPaymentBasis)
        this.DTFlatAmountTxtField = event.hasOwnProperty('flatAmount')?this.chkplndata(event.flatAmount):'' 
        this.DTPercentSI = event.hasOwnProperty('perOfSi')?this.chkplndata(event.perOfSi):'' 
        this.DTPrvPercentSI = event.hasOwnProperty('perOfPreviousSi')?this.chkplndata(event.perOfPreviousSi):'' 
        this.DTPercentClaimAmt = event.hasOwnProperty('perOfClaimAmount')?this.chkplndata(event.perOfClaimAmount):'' 
        this.DTapplicableClaimPayOut = event.hasOwnProperty('applicableClaimPayout')?this.chkplndata(event.applicableClaimPayout):'' 
        this.DTCovrageApplicDur = event.hasOwnProperty('coverageApplicableDuration')?this.chkplndata(event.coverageApplicableDuration):'' 
        this.DTWaitingPeriod = event.hasOwnProperty('waitingPeriod')?this.chkplndata(event.waitingPeriod):'' 
        this.DTWaitingPeriodDrop = event.hasOwnProperty('waitingPeroidType')?this.chkplndata(event.waitingPeroidType):'Days' 
        this.DTMinHospDura = event.hasOwnProperty('minHospitalization')?this.chkplndata(event.minHospitalization):'' 
        this.DTCoPayapplicable = event.hasOwnProperty('coPayApplicable')?this.chkplndata(event.coPayApplicable):'No' 
        this.changeDTCopyAplcbl()
        this.DTOpdSi = event.hasOwnProperty('perOfOpdSi')?this.chkplndata(event.perOfOpdSi):'' 
        this.DTAdSi = event.hasOwnProperty('perOfAdSi')?this.chkplndata(event.perOfAdSi):'' 
        this.DTMinAgeMemb = event.hasOwnProperty('minAgeOfNumber')?this.chkplndata(event.minAgeOfNumber):'' 
        this.DTMinTreatDura = event.hasOwnProperty('minTreatmentDuration')?this.chkplndata(event.minTreatmentDuration):''
        this.DTCoPayType = event.hasOwnProperty('coPayType')?this.chkplndata(event.coPayType):'' 
        this.DTCoPayPrcnt = event.hasOwnProperty('coPayPercent')?this.chkplndata(event.coPayPercent):'' 
        this.DTCoPayPrcntSr = event.hasOwnProperty('sharedRoomCopay')?this.chkplndata(event.sharedRoomCopay):'' 
        this.DTCoPayPrcntNr = event.hasOwnProperty('noRestriction')?this.chkplndata(event.noRestriction):'' 
        this.DTMnAgNo = event.hasOwnProperty('minAgeOfNumber')?this.chkplndata(event.minAgeOfNumber):'' 
        this.DTMnTrtmntDrn = event.hasOwnProperty('minTreatmentDuration')?this.chkplndata(event.minTreatmentDuration):'' 
        this.DTSelectBox = event.hasOwnProperty('relationship')?this.chkplndata(event.relationship):false 
        this.DTCoverAltName = event.hasOwnProperty('alternateName')?this.chkplndata(event.alternateName):'' 
        this.DTExtRefNo = event.hasOwnProperty('externalRefNo')?this.chkplndata(event.externalRefNo):'' 
        this.DTGcCover = event.hasOwnProperty('gcCoverage')?this.chkplndata(event.gcCoverage):'' 
        this.DTIrdaUINNo = event.hasOwnProperty('irdaUanNo')?this.chkplndata(event.irdaUanNo):'' 
        this.DTRemarks = event.hasOwnProperty('remarks')?this.chkplndata(event.remarks):'' 
        this.DTCondField = event.hasOwnProperty('conditions')?this.chkplndataarry(event.conditions):[] 
        this.DTExclus = event.hasOwnProperty('exclusions')?this.chkplndataarry(event.exclusions):[]
        
        if(this.DTSelectBox){
            let _relCoverDet = event.hasOwnProperty('relationShipsCoveredDetails')?this.chkplndataarry(event.relationShipsCoveredDetails):[]
            this.process_relationship_dependent(_relCoverDet,res=>{
                this.DTrelCoverData = {}
                this.DTrelCoverData = res
                this.showDTRelData = true
            })
        }
        this.DTRelCheckSelected()
        this.DTData = []  
        this.DTData.push(event)
    },
    DTRelCheckSelected(){
        this.showDTRelSection=false
        this.showDTRelData = false
        if (this.DTSelectBox){
            this.showDTRelSection=true
            this.showDTRelData = true
        }
    },

    getABData(event){
        //this.IPCoverCheck = event.hasOwnProperty('coverageCode')?this.chkplndataarry(event.coverageCode):[]
        this.ABCoverageType = event.hasOwnProperty('coverageType')?this.chkplndata(event.coverageType):''
        this.ABCoverageBasis = event.hasOwnProperty('coverageBasis')?this.chkplndata(event.coverageBasis):''
        this.changeABCoverBasis()
        this.ABSubLimitBasis = event.hasOwnProperty('subLimitBasis')?this.chkplndataarry(event.subLimitBasis):[] 
        this.changeABSubLmtBasis(this.ABSubLimitBasis)
        this.ABClaimPaymentBasis = event.hasOwnProperty('claimPaymentBasis')?this.chkplndata(event.claimPaymentBasis):'' 
        this.changeABClaimPaymentBasis(this.ABClaimPaymentBasis)
        this.ABFlatAmountTxtField = event.hasOwnProperty('flatAmount')?this.chkplndata(event.flatAmount):'' 
        this.ABPercentSI = event.hasOwnProperty('perOfSi')?this.chkplndata(event.perOfSi):'' 
        this.ABPrvPercentSI = event.hasOwnProperty('perOfPreviousSi')?this.chkplndata(event.perOfPreviousSi):'' 
        this.ABOpdSi = event.hasOwnProperty('perOfOpdSi')?this.chkplndata(event.perOfOpdSi):'' 
        this.ABAdSi = event.hasOwnProperty('perOfAdSi')?this.chkplndata(event.perOfAdSi):'' 
        this.ABMinAgeMemb = event.hasOwnProperty('minAgeOfNumber')?this.chkplndata(event.minAgeOfNumber):'' 
        this.ABMinTreatDura = event.hasOwnProperty('minTreatmentDuration')?this.chkplndata(event.minTreatmentDuration):'' 
        this.ABPercentClaimAmt = event.hasOwnProperty('perOfClaimAmount')?this.chkplndata(event.perOfClaimAmount):'' 
        this.ABapplicableClaimPayOut = event.hasOwnProperty('applicableClaimPayout')?this.chkplndata(event.applicableClaimPayout):'' 
        this.ABCovrageApplicDur = event.hasOwnProperty('coverageApplicableDuration')?this.chkplndata(event.coverageApplicableDuration):'' 
        this.ABWaitingPeriod = event.hasOwnProperty('waitingPeriod')?this.chkplndata(event.waitingPeriod):'' 
        this.ABWaitingPeriodDrop = event.hasOwnProperty('waitingPeroidType')?this.chkplndata(event.waitingPeroidType):'Days' 
        this.ABMinHospDura = event.hasOwnProperty('minHospitalization')?this.chkplndata(event.minHospitalization):'' 
        this.ABCoPayapplicable = event.hasOwnProperty('coPayApplicable')?this.chkplndata(event.coPayApplicable):'No' 
        this.changeABCopyAplcbl()
        this.ABCoPayType = event.hasOwnProperty('coPayType')?this.chkplndata(event.coPayType):'' 
        this.ABCoPayPrcnt = event.hasOwnProperty('coPayPercent')?this.chkplndata(event.coPayPercent):'' 
        this.ABCoPayPrcntSr = event.hasOwnProperty('sharedRoomCopay')?this.chkplndata(event.sharedRoomCopay):'' 
        this.ABCoPayPrcntNr = event.hasOwnProperty('noRestriction')?this.chkplndata(event.noRestriction):'' 
        this.ABMnAgNo = event.hasOwnProperty('minAgeOfNumber')?this.chkplndata(event.minAgeOfNumber):'' 
        this.ABMnTrtmntDrn = event.hasOwnProperty('minTreatmentDuration')?this.chkplndata(event.minTreatmentDuration):'' 
        this.ABSelectBox = event.hasOwnProperty('relationship')?this.chkplndata(event.relationship):false 
        this.ABCoverAltName = event.hasOwnProperty('alternateName')?this.chkplndata(event.alternateName):'' 
        this.ABExtRefNo = event.hasOwnProperty('externalRefNo')?this.chkplndata(event.externalRefNo):'' 
        this.ABGcCover = event.hasOwnProperty('gcCoverage')?this.chkplndata(event.gcCoverage):'' 
        this.ABIrdaUINNo = event.hasOwnProperty('irdaUanNo')?this.chkplndata(event.irdaUanNo):'' 
        this.ABRemarks = event.hasOwnProperty('remarks')?this.chkplndata(event.remarks):'' 
        this.ABCondField = event.hasOwnProperty('conditions')?this.chkplndataarry(event.conditions):[] 
        this.ABExclus = event.hasOwnProperty('exclusions')?this.chkplndataarry(event.exclusions):[]
        
        if(this.ABSelectBox){
            let _relCoverDet = event.hasOwnProperty('relationShipsCoveredDetails')?this.chkplndataarry(event.relationShipsCoveredDetails):[]
            this.process_relationship_dependent(_relCoverDet,res=>{
                this.ABrelCoverData = {}
                this.ABrelCoverData = res
                this.showABRelData = true
            })
        }
        this.ABRelCheckSelected()
        this.ABData = []  
        this.ABData.push(event)
    },
    ABRelCheckSelected(){
        this.showABRelSection=false
        this.showABRelData = false
        if (this.ABSelectBox){
            this.showABRelSection=true
            this.showABRelData = true
        }
    },

    getMCData(event){
        this.matcoverageType = event.hasOwnProperty('coverageType')?this.chkplndata(event.coverageType):''
        this.matcoverageBasis = event.hasOwnProperty('coverageBasis')?this.chkplndata(event.coverageBasis):''
        this.matchangeOnover()
        this.matsubLimitBasis = event.hasOwnProperty('subLimitBasis')?this.chkplndataarry(event.subLimitBasis):[] 
        this.matflatchange(this.matsubLimitBasis)
        this.matclaimPaymentBasis = event.hasOwnProperty('claimPaymentBasis')?this.chkplndata(event.claimPaymentBasis):'' 
        this.changeMCClaimPayBas(this.matclaimPaymentBasis)
        this.MCFlatAmountTxtField = event.hasOwnProperty('flatAmount')?this.chkplndata(event.flatAmount):'' 
        this.MCPercentSI = event.hasOwnProperty('perOfSi')?this.chkplndata(event.perOfSi):'' 
        this.MCPrvPercentSI = event.hasOwnProperty('perOfPreviousSi')?this.chkplndata(event.perOfPreviousSi):''
        this.MCOpdSi = event.hasOwnProperty('perOfOpdSi')?this.chkplndata(event.perOfOpdSi):'' 
        this.MCAdSi = event.hasOwnProperty('perOfAdSi')?this.chkplndata(event.perOfAdSi):''  
        this.MCPercentClaimAmt = event.hasOwnProperty('perOfClaimAmount')?this.chkplndata(event.perOfClaimAmount):'' 
        this.MCApplicClaimPayOut=event.hasOwnProperty('applicableClaimPayout')?this.chkplndata(event.applicableClaimPayout):'' 
        this.MCCovrageApplicDur = event.hasOwnProperty('coverageApplicableDuration')?this.chkplndata(event.coverageApplicableDuration):'' 
        this.matwaitingPeriod = event.hasOwnProperty('waitingPeriod')?this.chkplndata(event.waitingPeriod):'' 
        this.MCwaitingPeriodDrop = event.hasOwnProperty('waitingPeroidType')?this.chkplndata(event.waitingPeroidType):'Days' 
        this.MCCoverAltName = event.hasOwnProperty('alternateName')?this.chkplndata(event.alternateName):'' 
        this.MCExtRefNo = event.hasOwnProperty('externalRefNo')?this.chkplndata(event.externalRefNo):'' 
        this.MCGcCover = event.hasOwnProperty('gcCoverage')?this.chkplndata(event.gcCoverage):'' 
        this.MCIrdaUINNo = event.hasOwnProperty('irdaUanNo')?this.chkplndata(event.irdaUanNo):'' 
        this.MCRemarks = event.hasOwnProperty('remarks')?this.chkplndata(event.remarks):'' 
        this.MCCondField = event.hasOwnProperty('conditions')?this.chkplndataarry(event.conditions):[] 
        this.MCExclus = event.hasOwnProperty('exclusions')?this.chkplndataarry(event.exclusions):[] 

        // this.matcityofdel1 = event.hasOwnProperty('cityOfDelivery')?this.chkplndata(event.cityOfDelivery):'Metro' 
        // this.matflatamount1 = event.hasOwnProperty('deliveryFlatAmount')?this.chkplndata(event.deliveryFlatAmount):'' 
        // this.matdeltype1 = event.hasOwnProperty('deliveryType')?this.chkplndata(event.deliveryType):'Normal' 
        if(event.hasOwnProperty('flatAmountVaryBasedOnCityOfDeliverOrDeliveryDetails') == true){
            this.matcityofdel1 = event.flatAmountVaryBasedOnCityOfDeliverOrDeliveryDetails[0].cityOfDelivery
            this.matflatamount1 = event.flatAmountVaryBasedOnCityOfDeliverOrDeliveryDetails[0].deliveryFlatAmount
            this.matdeltype1 = event.flatAmountVaryBasedOnCityOfDeliverOrDeliveryDetails[0].deliveryType

            this.matcityofdel2 = event.flatAmountVaryBasedOnCityOfDeliverOrDeliveryDetails[1].cityOfDelivery
            this.matflatamount2 = event.flatAmountVaryBasedOnCityOfDeliverOrDeliveryDetails[1].deliveryFlatAmount
            this.matdeltype2 = event.flatAmountVaryBasedOnCityOfDeliverOrDeliveryDetails[1].deliveryType

            this.matcityofdel3 = event.flatAmountVaryBasedOnCityOfDeliverOrDeliveryDetails[2].cityOfDelivery
            this.matflatamount3 = event.flatAmountVaryBasedOnCityOfDeliverOrDeliveryDetails[2].deliveryFlatAmount
            this.matdeltype3 = event.flatAmountVaryBasedOnCityOfDeliverOrDeliveryDetails[2].deliveryType

            this.matcityofdel4 = event.flatAmountVaryBasedOnCityOfDeliverOrDeliveryDetails[3].cityOfDelivery
            this.matflatamount4 = event.flatAmountVaryBasedOnCityOfDeliverOrDeliveryDetails[3].deliveryFlatAmount
            this.matdeltype4 = event.flatAmountVaryBasedOnCityOfDeliverOrDeliveryDetails[3].deliveryType
        }

        this.matpostflatamount = event.hasOwnProperty('durationForCoveringPostNatalExpenses')?this.chkplndata(event.durationForCoveringPostNatalExpenses):'' 
        this.matpreflatamount = event.hasOwnProperty('durationForCoveringPreNatalExpenses')?this.chkplndata(event.durationForCoveringPreNatalExpenses):'' 
        this.matyesgirlchild = event.hasOwnProperty('extraAmountForGirlChild')?this.chkplndata(event.extraAmountForGirlChild):'' 
        this.matvaryongender = event.hasOwnProperty('flatAmountVaryBasedOnGender')?this.chkplndata(event.flatAmountVaryBasedOnGender):'No' 
        this.selctdvaryongender()
        this.matvaryoncity = event.hasOwnProperty('flatAmountVaryBasedOnCityOfDeliverOrDelivery')?this.chkplndata(event.flatAmountVaryBasedOnCityOfDeliverOrDelivery):'No' 
        this.selctdvaryoncity()
        this.matlimitdel = event.hasOwnProperty('limitOnNumberOfDeliveries')?this.chkplndata(event.limitOnNumberOfDeliveries):'' 
        // this.matwaitingPeriod = event.hasOwnProperty('limitsApplicableForPreAndPostNatal')?this.chkplndata(event.limitsApplicableForPreAndPostNatal):'' 
        this.matminHosDuration = event.hasOwnProperty('minHospitalization')?this.chkplndata(event.minHospitalization):'' 
        this.matlimit = event.hasOwnProperty('overAndAboveMaternityLimit')?this.chkplndata(event.overAndAboveMaternityLimit):'No' 
        this.selctdlimit()
        this.matnatal = event.hasOwnProperty('preAndPostNatalApplicable')?this.chkplndata(event.preAndPostNatalApplicable):'No' 
        this.selctdnatal()
        // this.matwaitingPeriod = event.hasOwnProperty('limitDetails')?this.chkplndata(event.limitDetails):'' 
        if(event.hasOwnProperty('limitDetails') == true){
            this.matprecoverbasis = this.chkplndata(event.limitDetails.preNatal.coverageBasis) 
            this.changeMCPreCoverBasis(1)
            this.matpresublimit = this.chkplndataarry(event.limitDetails.preNatal.subLimitBasis) 
            this.matPreSubLimChange(this.matpresublimit)
            this.matpreclaimpay = this.chkplndata(event.limitDetails.preNatal.claimPaymentBasis) 
            this.matPreClaimPayChange(this.matpreclaimpay)
            this.MCPreFlatAmountTxtField = this.chkplndata(event.limitDetails.preNatal.flatAmount) 
            this.MCPrePercentSI = this.chkplndata(event.limitDetails.preNatal.perOfSi) 
            this.MCPrePercentClaimAmt = this.chkplndata(event.limitDetails.preNatal.perOfClaimAmount) 
            this.MCPrePercentPrevSI=this.chkplndata(event.limitDetails.preNatal.perOfPreviousSi)
            this.MCPreApplicClaimPayOut=this.chkplndata(event.limitDetails.preNatal.applicableClaimPayout)
            this.MCPreCovrageApplicDur=this.chkplndata(event.limitDetails.preNatal.coverageApplicableDuration)

            this.matpostcoverbasis = this.chkplndata(event.limitDetails.postNatal.coverageBasis) 
            this.changeMCPostCoverBasis(1)
            this.matpostsublimit = this.chkplndataarry(event.limitDetails.postNatal.subLimitBasis) 
            this.matPostSubLimChange(this.matpostsublimit)
            this.matpostclaimpay = this.chkplndata(event.limitDetails.postNatal.claimPaymentBasis) 
            this.matPostClaimPayChange(this.matpostclaimpay)
            this.MCPostFlatAmountTxtField = this.chkplndata(event.limitDetails.postNatal.flatAmount) 
            this.MCPostPercentSI = this.chkplndata(event.limitDetails.postNatal.perOfSi) 
            this.MCPostPercentClaimAmt = this.chkplndata(event.limitDetails.postNatal.perOfClaimAmount) 
            this.MCPostPercentPrevSI=this.chkplndata(event.limitDetails.postNatal.perOfPreviousSi)
            this.MCPostApplicClaimPayOut=this.chkplndata(event.limitDetails.postNatal.applicableClaimPayout)
            this.MCPostCovrageApplicDur=this.chkplndata(event.limitDetails.postNatal.coverageApplicableDuration)
        }
        this.mcDta=[]  
        this.mcDta.push(event)

    },
  
    rebindrb(arryRecieved){
        this.rbcoverageType=arryRecieved.hasOwnProperty('coverageType')?this.chkplndata(arryRecieved.coverageType):''
        this.rbcoverageBasis=arryRecieved.hasOwnProperty('coverageBasis')?this.chkplndata(arryRecieved.coverageBasis):''
        this.rbchangeOnover(2)
        this.rbsubLimitBasis=arryRecieved.hasOwnProperty('subLimitBasis')?this.chkplndataarry(arryRecieved.subLimitBasis):[]
        this.chngrbSubLmtBasis()
        this.rbclaimPaymentBasis=arryRecieved.hasOwnProperty('claimPaymentBasis')?this.chkplndata(arryRecieved.claimPaymentBasis):''
        this.chngrbClaimPaymentBasis()
        this.rbrestoreWaitingPeriod=arryRecieved.hasOwnProperty('restoreWaitingPeriod')?this.chkplndata(arryRecieved.restoreWaitingPeriod):''
        this.rbrestoreWaitingPeriodDrop=arryRecieved.hasOwnProperty('waitingPeroidType')?this.chkplndata(arryRecieved.waitingPeroidType):'Days'
        this.rbNoReinstatementsPermitted=arryRecieved.hasOwnProperty('noOfReinstatementsPermitted')?this.chkplndata(arryRecieved.noOfReinstatementsPermitted):''
        this.rbFlatAmt=arryRecieved.hasOwnProperty('flatAmount')?this.chkplndata(arryRecieved.flatAmount):''
        this.rbPerSI=arryRecieved.hasOwnProperty('perOfSi')?this.chkplndata(arryRecieved.perOfSi):''
        this.rbPrvPerSI=arryRecieved.hasOwnProperty('perOfPreviousSi')?this.chkplndata(arryRecieved.perOfPreviousSi):''
        this.rbClmAmt=arryRecieved.hasOwnProperty('perOfClaimAmount')?this.chkplndata(arryRecieved.perOfClaimAmount):''
        this.rbapplicableClaimPayOut=arryRecieved.hasOwnProperty('applicableClaimPayout')?this.chkplndata(arryRecieved.applicableClaimPayout):''
        this.rbCad=arryRecieved.hasOwnProperty('coverageApplicableDuration')?this.chkplndata(arryRecieved.coverageApplicableDuration):''
        this.RBCCoverAltName = arryRecieved.hasOwnProperty('alternateName')?this.chkplndata(arryRecieved.alternateName):'' 
        this.RBCExtRefNo = arryRecieved.hasOwnProperty('externalRefNo')?this.chkplndata(arryRecieved.externalRefNo):'' 
        this.RBCGcCover = arryRecieved.hasOwnProperty('gcCoverage')?this.chkplndata(arryRecieved.gcCoverage):'' 
        this.RBCIrdaUINNo = arryRecieved.hasOwnProperty('irdaUanNo')?this.chkplndata(arryRecieved.irdaUanNo):'' 
        this.RBCRemarks = arryRecieved.hasOwnProperty('remarks')?this.chkplndata(arryRecieved.remarks):'' 
        this.RBCCondField = arryRecieved.hasOwnProperty('conditions')?this.chkplndataarry(arryRecieved.conditions):[] 
        this.RBCExclus = arryRecieved.hasOwnProperty('exclusions')?this.chkplndataarry(arryRecieved.exclusions):[] 
        this.RBCSelectBox = event[i].hasOwnProperty('relationship')?this.chkplndata(event[i].relationship):false 
        
        if(this.RBCSelectBox){
            let _relCoverDet = arryRecieved.hasOwnProperty('relationShipsCoveredDetails')?this.chkplndataarry(arryRecieved.relationShipsCoveredDetails):[]
            this.process_relationship_dependent(_relCoverDet,res=>{
                this.RBCrelCoverData = {}
                this.RBCrelCoverData = res
                this.showRBCRelData = true
            })
        }
        this.RBCRelCheckSelected()
        this.rbDta=[]  
        this.rbDta.push(arryRecieved)
    },
    RBCRelCheckSelected(){
        this.showRBCRelSection=false
        this.showRBCRelData = false
        if (this.RBCSelectBox){
            this.showRBCRelSection=true
            this.showRBCRelData = true
        }
    },
    rebindhahed(arryRecieved){
        for(let i=0; i<arryRecieved.length;i++){
            if(arryRecieved[i].hasOwnProperty('coverageCode') && arryRecieved[i].coverageCode==="HED"){
                //  alert("reached VAC ")
                this.hedcoverageType=arryRecieved[i].hasOwnProperty('coverageType')?this.chkplndata(arryRecieved[i].coverageType):''
                this.hedcoverageBasis=arryRecieved[i].hasOwnProperty('coverageBasis')?this.chkplndata(arryRecieved[i].coverageBasis):''
                this.hedchangeOnover(2)
                this.hedsubLimitBasis=arryRecieved[i].hasOwnProperty('subLimitBasis')?this.chkplndataarry(arryRecieved[i].subLimitBasis):[]
                this.hedflatchange()
                this.hedclaimPaymentBasis=arryRecieved[i].hasOwnProperty('claimPaymentBasis')?this.chkplndata(arryRecieved[i].claimPaymentBasis):''
                this.chnghedClaimPaymentBasis()
                this.hedwaitingPeriod=arryRecieved[i].hasOwnProperty('waitingPeriod')?this.chkplndata(arryRecieved[i].waitingPeriod):''
                this.hedwaitingPeriodDrop=arryRecieved[i].hasOwnProperty('waitingPeroidType')?this.chkplndata(arryRecieved[i].waitingPeroidType):'Days'
                this.hedFlatAmt=arryRecieved[i].hasOwnProperty('flatAmount')?this.chkplndata(arryRecieved[i].flatAmount):''
                this.hedPerSI=arryRecieved[i].hasOwnProperty('perOfSi')?this.chkplndata(arryRecieved[i].perOfSi):''
                this.hedPrvPerSI=arryRecieved[i].hasOwnProperty('perOfPreviousSi')?this.chkplndata(arryRecieved[i].perOfPreviousSi):''
                this.HEDOpdSi = arryRecieved[i].hasOwnProperty('perOfOpdSi')?this.chkplndata(arryRecieved[i].perOfOpdSi):'' 
                this.HEDAdSi = arryRecieved[i].hasOwnProperty('perOfAdSi')?this.chkplndata(arryRecieved[i].perOfAdSi):''
                this.hedClmAmt=arryRecieved[i].hasOwnProperty('perOfClaimAmount')?this.chkplndata(arryRecieved[i].perOfClaimAmount):''
                this.hedapplicableClaimPayOut=arryRecieved[i].hasOwnProperty('applicableClaimPayout')?this.chkplndata(arryRecieved[i].applicableClaimPayout):''
                this.hedallowedDiaTests=arryRecieved[i].hasOwnProperty('allowedDiagnosticsTest')?this.chkplndata(arryRecieved[i].allowedDiagnosticsTest):''
                this.hedCad=arryRecieved[i].hasOwnProperty('coverageApplicableDuration')?this.chkplndata(arryRecieved[i].coverageApplicableDuration):''
                this.hedisSelectBox=arryRecieved[i].hasOwnProperty('relationship')?this.chkplndata(arryRecieved[i].relationship):''
                this.HEDCoverAltName = arryRecieved[i].hasOwnProperty('alternateName')?this.chkplndata(arryRecieved[i].alternateName):'' 
                this.HEDExtRefNo = arryRecieved[i].hasOwnProperty('externalRefNo')?this.chkplndata(arryRecieved[i].externalRefNo):'' 
                this.HEDGcCover = arryRecieved[i].hasOwnProperty('gcCoverage')?this.chkplndata(arryRecieved[i].gcCoverage):'' 
                this.HEDIrdaUINNo = arryRecieved[i].hasOwnProperty('irdaUanNo')?this.chkplndata(arryRecieved[i].irdaUanNo):'' 
                this.HEDRemarks = arryRecieved[i].hasOwnProperty('remarks')?this.chkplndata(arryRecieved[i].remarks):'' 
                this.HEDCondField = arryRecieved[i].hasOwnProperty('conditions')?this.chkplndataarry(arryRecieved[i].conditions):[] 
                this.HEDExclus = arryRecieved[i].hasOwnProperty('exclusions')?this.chkplndataarry(arryRecieved[i].exclusions):[]
                if(this.hedisSelectBox){
                    // this.tempGcRlcvrd={}
                    // this.gcSubLimitBasis=arryRecieved[i].hasOwnProperty('relationShipsCovered')?this.chkplndataarry(arryRecieved[i].relationShipsCovered):[]
                    let tempGlblCvrRlcvrd=arryRecieved[i].hasOwnProperty('relationShipsCoveredDetails')?this.chkplndataarry(arryRecieved[i].relationShipsCoveredDetails):[]
                    this.process_relationship_dependent(tempGlblCvrRlcvrd,res=>{
                        this.tempHeRlcvrd={}
                        this.tempHeRlcvrd=res
                        this.hereldatashw=true
                    })
                }
                this.HedRdiSelected()
                // this.hedSeparateRshipDepInfo=arryRecieved[i].hasOwnProperty('SeparateRshipDepInfo')?this.chkplndata(arryRecieved[i].SeparateRshipDepInfo):''
                this.fhed.push(arryRecieved[i])
            }else if(arryRecieved[i].hasOwnProperty('coverageCode') && arryRecieved[i].coverageCode==="HA"){
                this.hacCoverageType=arryRecieved[i].hasOwnProperty('coverageType')?this.chkplndata(arryRecieved[i].coverageType):''
                this.hacCoverageBasis=arryRecieved[i].hasOwnProperty('coverageBasis')?this.chkplndata(arryRecieved[i].coverageBasis):''
                this.chngHacCb(2)
                this.hacSubLimitBasis=arryRecieved[i].hasOwnProperty('subLimitBasis')?this.chkplndataarry(arryRecieved[i].subLimitBasis):[]
                this.chngHacSubLmtBasis()
                this.hacClaimPaymentBasis=arryRecieved[i].hasOwnProperty('claimPaymentBasis')?this.chkplndata(arryRecieved[i].claimPaymentBasis):''
                this.chngHacClaimPaymentBasis()
                this.hacWaitingPeriod=arryRecieved[i].hasOwnProperty('waitingPeriod')?this.chkplndata(arryRecieved[i].waitingPeriod):''
                // this.haNoReinstatementsPermitted[i].hasOwnProperty('NoReinstatementsPermitted')?this.chkplndata(arryRecieved[i].NoReinstatementsPermitted):''
                this.hacFlatAmt=arryRecieved[i].hasOwnProperty('flatAmount')?this.chkplndata(arryRecieved[i].flatAmount):''
                this.hacPerSI=arryRecieved[i].hasOwnProperty('perOfSi')?this.chkplndata(arryRecieved[i].perOfSi):''
                this.hacPrvPerSI=arryRecieved[i].hasOwnProperty('perOfPreviousSi')?this.chkplndata(arryRecieved[i].perOfPreviousSi):''
                this.hacClmAmt=arryRecieved[i].hasOwnProperty('perOfClaimAmount')?this.chkplndata(arryRecieved[i].perOfClaimAmount):''
                this.hacAplcblClmPyot=arryRecieved[i].hasOwnProperty('applicableClaimPayout')?this.chkplndata(arryRecieved[i].applicableClaimPayout):''
                this.hacCad=arryRecieved[i].hasOwnProperty('coverageApplicableDuration')?this.chkplndata(arryRecieved[i].coverageApplicableDuration):''
                this.fhac.push(arryRecieved[i])
            }
        }
    },


    preHospitalizationPopulate(objectPreHosp){ 
        this.phcoverageType= objectPreHosp.hasOwnProperty('coverageType')?this.chkplndata(objectPreHosp.coverageType):''
        this.phcoverageBasis=objectPreHosp.hasOwnProperty('coverageBasis')?this.chkplndata(objectPreHosp.coverageBasis):''
        this.phchangeOnover(2)
        this.phsubLimitBasis=objectPreHosp.hasOwnProperty('subLimitBasis')?this.chkplndataarry(objectPreHosp.subLimitBasis):[]
        this.chngeInPREHospSubLimit()
        this.phclaimPaymentBasis=objectPreHosp.hasOwnProperty('claimPaymentBasis')?this.chkplndata(objectPreHosp.claimPaymentBasis):''
        this.chngPREHospClaimPaymentBasis() 
        this.phwaitingPeriod=objectPreHosp.hasOwnProperty('waitingPeriod')?this.chkplndata(objectPreHosp.waitingPeriod):''
        this.phwaitingPeriodDrop=objectPreHosp.hasOwnProperty('waitingPeroidType')?this.chkplndata(objectPreHosp.waitingPeroidType):'Days'
        this.flatAmtForPRE=objectPreHosp.hasOwnProperty('flatAmount')?this.chkplndata(objectPreHosp.flatAmount):''
        this.siAmtForPRE=objectPreHosp.hasOwnProperty('perOfSi')?this.chkplndata(objectPreHosp.perOfSi):''
        this.siAmtForPrvPRE=objectPreHosp.hasOwnProperty('perOfPreviousSi')?this.chkplndata(objectPreHosp.perOfPreviousSi):''
        this.claimAmtForPRE=objectPreHosp.hasOwnProperty('perOfClaimAmount')?this.chkplndata(objectPreHosp.perOfClaimAmount):''
        this.CoveAppDuratPreHosp=objectPreHosp.hasOwnProperty('coverageApplicableDuration')?this.chkplndata(objectPreHosp.coverageApplicableDuration):''
        this.phduration=objectPreHosp.hasOwnProperty('duration')?this.chkplndata(objectPreHosp.duration):''
        this.phisSelectBox=objectPreHosp.hasOwnProperty('relationship')?this.chkplnBooleandata(objectPreHosp.relationship):false
        this.AppCliamPayPreHosp = objectPreHosp.hasOwnProperty('applicableClaimPayout')?this.chkplndata(objectPreHosp.applicableClaimPayout):''
        this.PreHospCoverAltName = objectPreHosp.hasOwnProperty('alternateName')?this.chkplndata(objectPreHosp.alternateName):'' 
        this.PreHospExtRefNo = objectPreHosp.hasOwnProperty('externalRefNo')?this.chkplndata(objectPreHosp.externalRefNo):'' 
        this.PreHospGcCover = objectPreHosp.hasOwnProperty('gcCoverage')?this.chkplndata(objectPreHosp.gcCoverage):'' 
        this.PreHospIrdaUINNo = objectPreHosp.hasOwnProperty('irdaUanNo')?this.chkplndata(objectPreHosp.irdaUanNo):'' 
        this.PreHospRemarks = objectPreHosp.hasOwnProperty('remarks')?this.chkplndata(objectPreHosp.remarks):'' 
        this.PreHospCondField = objectPreHosp.hasOwnProperty('conditions')?this.chkplndataarry(objectPreHosp.conditions):[] 
        this.PreHospExclus = objectPreHosp.hasOwnProperty('exclusions')?this.chkplndataarry(objectPreHosp.exclusions):[]
        if(this.phisSelectBox){
            let tempprehzRlcvrd=objectPreHosp.hasOwnProperty('relationShipsCoveredDetails')?this.chkplndataarry(objectPreHosp.relationShipsCoveredDetails):[]
            this.process_relationship_dependent(tempprehzRlcvrd,res=>{
                this.tempPhRlcvrd={}
                this.tempPhRlcvrd=res
                this.phreldatashw=true
            })
        }
        this.phRdiSelected()
        this.prehDta=[]  
        this.prehDta.push(objectPreHosp)
    },

    phRdiSelected(){
        this.shwPhRdi=false
        this.phreldatashw=false
        if (this.phisSelectBox){
            this.shwPhRdi=true
            this.phreldatashw=true
        }
    },

    postHospitalizationPopulate(objectPostHosp){

        this.pohcoverageType  = objectPostHosp.hasOwnProperty('coverageType')?this.chkplndata(objectPostHosp.coverageType):''
        this.pohcoverageBasis = objectPostHosp.hasOwnProperty('coverageBasis')?this.chkplndata(objectPostHosp.coverageBasis):''
        this.pohchangeOnover(2)
        this.pohsubLimitBasis = objectPostHosp.hasOwnProperty('subLimitBasis')?this.chkplndataarry(objectPostHosp.subLimitBasis):[]
        this.chngeInPOHSubLimit()
        this.pohclaimPaymentBasis = objectPostHosp.hasOwnProperty('claimPaymentBasis')?this.chkplndata(objectPostHosp.claimPaymentBasis):''  
        this.chngPOHClaimPaymentBasis() 
        this.pohwaitingPeriod = objectPostHosp.hasOwnProperty('waitingPeriod')?this.chkplndata(objectPostHosp.waitingPeriod):''
        this.pohwaitingPeriodDrop = objectPostHosp.hasOwnProperty('waitingPeroidType')?this.chkplndata(objectPostHosp.waitingPeroidType):'Days'
        this.flatAmtForPoh =    objectPostHosp.hasOwnProperty('flatAmount')?this.chkplndata(objectPostHosp.flatAmount):''
        this.siAmtForPoh =      objectPostHosp.hasOwnProperty('perOfSi')?this.chkplndata(objectPostHosp.perOfSi):''
        this.siAmtForPrvPoh =      objectPostHosp.hasOwnProperty('perOfPreviousSi')?this.chkplndata(objectPostHosp.perOfPreviousSi):''
        this.claimAmtForPoh =   objectPostHosp.hasOwnProperty('perOfClaimAmount')?this.chkplndata(objectPostHosp.perOfClaimAmount):''
        this.CoveAppDuratPOH =  objectPostHosp.hasOwnProperty('coverageApplicableDuration')?this.chkplndata(objectPostHosp.coverageApplicableDuration):''
        this.pohduration =      objectPostHosp.hasOwnProperty('duration')?this.chkplndata(objectPostHosp.duration):''
        this.pohisSelectBox= objectPostHosp.hasOwnProperty('relationship')?this.chkplnBooleandata(objectPostHosp.relationship):false
        this.AppCliamPayPostHosp = objectPostHosp.hasOwnProperty('applicableClaimPayout')?this.chkplndata(objectPostHosp.applicableClaimPayout):''
        this.PostHospCoverAltName = objectPostHosp.hasOwnProperty('alternateName')?this.chkplndata(objectPostHosp.alternateName):'' 
        this.PostHospExtRefNo = objectPostHosp.hasOwnProperty('externalRefNo')?this.chkplndata(objectPostHosp.externalRefNo):'' 
        this.PostHospGcCover = objectPostHosp.hasOwnProperty('gcCoverage')?this.chkplndata(objectPostHosp.gcCoverage):'' 
        this.PostHospIrdaUINNo = objectPostHosp.hasOwnProperty('irdaUanNo')?this.chkplndata(objectPostHosp.irdaUanNo):'' 
        this.PostHospRemarks = objectPostHosp.hasOwnProperty('remarks')?this.chkplndata(objectPostHosp.remarks):'' 
        this.PostHospCondField = objectPostHosp.hasOwnProperty('conditions')?this.chkplndataarry(objectPostHosp.conditions):[] 
        this.PostHospExclus = objectPostHosp.hasOwnProperty('exclusions')?this.chkplndataarry(objectPostHosp.exclusions):[] 
        if(this.pohisSelectBox){
            let temppostRlcvrd=objectPostHosp.hasOwnProperty('relationShipsCoveredDetails')?this.chkplndataarry(objectPostHosp.relationShipsCoveredDetails):[]
            this.process_relationship_dependent(temppostRlcvrd,res=>{
                this.tempPoRlcvrd={}
                this.tempPoRlcvrd=res
                this.poreldatashw=true
            })
        }
        this.poRdiSelected()
        this.poDta=[]  
        this.poDta.push(objectPostHosp)
    },

    bdcnbbcPopulate(arryRecieved){
        for(let i=0; i<arryRecieved.length;i++){
            if(arryRecieved[i].hasOwnProperty('coverageCode') && arryRecieved[i].coverageCode==="BDC"){
                this.bdoccoverageType=arryRecieved[i].hasOwnProperty('coverageType')?this.chkplndata(arryRecieved[i].coverageType):''
                this.bdoccoverageBasis=arryRecieved[i].hasOwnProperty('coverageBasis')?this.chkplndata(arryRecieved[i].coverageBasis):''
                this.bdocchangeOnover(2)
                this.bdocsubLimitBasis=arryRecieved[i].hasOwnProperty('subLimitBasis')?this.chkplndataarry(arryRecieved[i].subLimitBasis):[]
                this.bdocflatchange()
                this.bdocclaimPaymentBasis=arryRecieved[i].hasOwnProperty('claimPaymentBasis')?this.chkplndata(arryRecieved[i].claimPaymentBasis):''
                this.chngBDCClaimPaymentBasis()
                this.CoveAppDuratBDC=arryRecieved[i].hasOwnProperty('coverageApplicableDuration')?this.chkplndata(arryRecieved[i].coverageApplicableDuration):''
                this.flatAmtForBDC=arryRecieved[i].hasOwnProperty('flatAmount')?this.chkplndata(arryRecieved[i].flatAmount):''
                this.siAmtForBDC=arryRecieved[i].hasOwnProperty('perOfSi')?this.chkplndata(arryRecieved[i].perOfSi):''
                this.siAmtForPrvBDC=arryRecieved[i].hasOwnProperty('perOfPreviousSi')?this.chkplndata(arryRecieved[i].perOfPreviousSi):''
                this.claimAmtForBDC=arryRecieved[i].hasOwnProperty('perOfClaimAmount')?this.chkplndata(arryRecieved[i].perOfClaimAmount):''
                this.AppCliamPayBDC=arryRecieved[i].hasOwnProperty('applicableClaimPayout')?this.chkplndata(arryRecieved[i].applicableClaimPayout):''
                // this.bdocwaitingPeriod = arryRecieved[i].hasOwnProperty('waitingPeriod')?this.chkplndata(arryRecieved[i].waitingPeriod):''
                this.bdcArr.push(arryRecieved[i])

            }else if(arryRecieved[i].hasOwnProperty('coverageCode') && arryRecieved[i].coverageCode==="NBBC"){
                // alert("reached CTC ")
                this.nbbcoverageType=arryRecieved[i].hasOwnProperty('coverageType')?this.chkplndata(arryRecieved[i].coverageType):''
                this.nbbcoverageBasis=arryRecieved[i].hasOwnProperty('coverageBasis')?this.chkplndata(arryRecieved[i].coverageBasis):''
                this.nbbchangeOnover(2)
                this.nbbsubLimitBasis=arryRecieved[i].hasOwnProperty('subLimitBasis')?this.chkplndataarry(arryRecieved[i].subLimitBasis):[]
                this.nbbflatchange()
                this.nbbclaimPaymentBasis=arryRecieved[i].hasOwnProperty('claimPaymentBasis')?this.chkplndata(arryRecieved[i].claimPaymentBasis):''
                this.chngNBBClaimPaymentBasis()
                this.CoveAppDuratNBB=arryRecieved[i].hasOwnProperty('coverageApplicableDuration')?this.chkplndata(arryRecieved[i].coverageApplicableDuration):''
                this.flatAmtForNBB=arryRecieved[i].hasOwnProperty('flatAmount')?this.chkplndata(arryRecieved[i].flatAmount):''
                this.siAmtForNBB=arryRecieved[i].hasOwnProperty('perOfSi')?this.chkplndata(arryRecieved[i].perOfSi):''
                this.siAmtPrvForNBB=arryRecieved[i].hasOwnProperty('perOfPreviousSi')?this.chkplndata(arryRecieved[i].perOfPreviousSi):''
                this.claimAmtForNBB=arryRecieved[i].hasOwnProperty('perOfClaimAmount')?this.chkplndata(arryRecieved[i].perOfClaimAmount):''
                this.AppCliamPayNBB=arryRecieved[i].hasOwnProperty('applicableClaimPayout')?this.chkplndata(arryRecieved[i].applicableClaimPayout):''
                this.nbbwaitingPeriod = arryRecieved[i].hasOwnProperty('waitingPeriod')?this.chkplndata(arryRecieved[i].waitingPeriod):''
                this.dbbcArr.push(arryRecieved[i])
            }
        }

    },

    ambulanceCoveragePopulate(objectambc){
        this.ambccoverageType = objectambc.hasOwnProperty('coverageType')?this.chkplndata(objectambc.coverageType):''
        this.ambccoverageBasis = objectambc.hasOwnProperty('coverageBasis')?this.chkplndata(objectambc.coverageBasis):''
        this.ambcchangeOnover(2)
        this.ambcsubLimitBasis = objectambc.hasOwnProperty('subLimitBasis')?this.chkplndataarry(objectambc.subLimitBasis):[]
        this.ambcsubLimitBasischange()
        this.ambcclaimPaymentBasis = objectambc.hasOwnProperty('claimPaymentBasis')?this.chkplndata(objectambc.claimPaymentBasis):''
        this.chngambcClaimPaymentBasis()
        this.CoveAppDuratambc = objectambc.hasOwnProperty('coverageApplicableDuration')?this.chkplndata(objectambc.coverageApplicableDuration):''  
        this.flatAmtForambc = objectambc.hasOwnProperty('flatAmount')?this.chkplndata(objectambc.flatAmount):''  
        this.siAmtForambc = objectambc.hasOwnProperty('perOfSi')?this.chkplndata(objectambc.perOfSi):''  
        this.siAmtPrvForambc = objectambc.hasOwnProperty('perOfPreviousSi')?this.chkplndata(objectambc.perOfPreviousSi):'' 
        this.claimAmtForambc = objectambc.hasOwnProperty('perOfClaimAmount')?this.chkplndata(objectambc.perOfClaimAmount):''  
        this.ambcapplicableClaimPayOut = objectambc.hasOwnProperty('applicableClaimPayout')?this.chkplndata(objectambc.applicableClaimPayout):''  
        this.ambcwaitingPeriod = objectambc.hasOwnProperty('waitingPeriod')?this.chkplndata(objectambc.waitingPeriod):''
        this.ACwaitingPeriodDrop = objectambc.hasOwnProperty('waitingPeroidType')?this.chkplndata(objectambc.waitingPeroidType):''
        this.ACCoverAltName = objectambc.hasOwnProperty('alternateName')?this.chkplndata(objectambc.alternateName):'' 
        this.ACExtRefNo = objectambc.hasOwnProperty('externalRefNo')?this.chkplndata(objectambc.externalRefNo):'' 
        this.ACGcCover = objectambc.hasOwnProperty('gcCoverage')?this.chkplndata(objectambc.gcCoverage):'' 
        this.ACIrdaUINNo = objectambc.hasOwnProperty('irdaUanNo')?this.chkplndata(objectambc.irdaUanNo):'' 
        this.ACRemarks = objectambc.hasOwnProperty('remarks')?this.chkplndata(objectambc.remarks):'' 
        this.ACCondField = objectambc.hasOwnProperty('conditions')?this.chkplndataarry(objectambc.conditions):[] 
        this.ACExclus = objectambc.hasOwnProperty('exclusions')?this.chkplndataarry(objectambc.exclusions):[]  
        this.ACSelectBox= objectambc.hasOwnProperty('relationship')?this.chkplnBooleandata(objectambc.relationship):false

        if(this.ACSelectBox){
            let _relCoverDet = objectambc.hasOwnProperty('relationShipsCoveredDetails')?this.chkplndataarry(objectambc.relationShipsCoveredDetails):[]
            this.process_relationship_dependent(_relCoverDet,res=>{
                this.ACrelCoverData = {}
                this.ACrelCoverData = res
                this.showACRelData = true
            })
        }
        this.ACRelCheckSelected()
        this.ambcDta=[]  
        this.ambcDta.push(objectambc)
    },
    ACRelCheckSelected(){
        this.showACRelSection=false
        this.showACRelData = false
        if (this.ACSelectBox){
            this.showACRelSection=true
            this.showACRelData = true
        }
    },

    ftbPopulate(objectftb){
            this.coverageType  = objectftb.hasOwnProperty('coverageType')?this.chkplndata(objectftb.coverageType):''
            this.coverageBasis = objectftb.hasOwnProperty('coverageBasis')?this.chkplndata(objectftb.coverageBasis):''
            this.chngFtbCb(2)
            this.subLimitBasis = objectftb.hasOwnProperty('subLimitBasis')?this.chkplndataarry(objectftb.subLimitBasis):[]
            this.chngeInFTBSubLimit()
            this.claimPaymentBasis = objectftb.hasOwnProperty('claimPaymentBasis')?this.chkplndata(objectftb.claimPaymentBasis):''       
            this.chngFTBClaimPaymentBasis()
            this.AppCliamPay = objectftb.hasOwnProperty('applicableClaimPayout')?this.chkplndata(objectftb.applicableClaimPayout):''
            this.FTBwaitingPeriod = objectftb.hasOwnProperty('waitingPeriod')?this.chkplndata(objectftb.waitingPeriod):''
            this.FTBwaitingPeriodDrop = objectftb.hasOwnProperty('waitingPeroidType')?this.chkplndata(objectftb.waitingPeroidType):'Days'
            this.flatAmtForFTB = objectftb.hasOwnProperty('flatAmount')?this.chkplndata(objectftb.flatAmount):''
            this.siAmtForFTB = objectftb.hasOwnProperty('perOfSi')?this.chkplndata(objectftb.perOfSi):''
            this.siAmtPrvForFTB = objectftb.hasOwnProperty('perOfPreviousSi')?this.chkplndata(objectftb.perOfPreviousSi):''
            this.claimAmtForFTB = objectftb.hasOwnProperty('perOfClaimAmount')?this.chkplndata(objectftb.perOfClaimAmount):'' 
            this.CoveAppDurat = objectftb.hasOwnProperty('coverageApplicableDuration')?this.chkplndata(objectftb.coverageApplicableDuration):''
            this.MnElgblDstnceFrmInsrdRsdnc = objectftb.hasOwnProperty('minEligibleDistanceFromInsuredResidence')?this.chkplndata(objectftb.minEligibleDistanceFromInsuredResidence):''               
            this.AlwdCntofImdteFmlyMmbr = objectftb.hasOwnProperty('AllowedCountOfImmediateFamilyMember')?this.chkplndata(objectftb.AllowedCountOfImmediateFamilyMember):''
            this.FTBCoverAltName = objectftb.hasOwnProperty('alternateName')?this.chkplndata(objectftb.alternateName):'' 
            this.FTBExtRefNo = objectftb.hasOwnProperty('externalRefNo')?this.chkplndata(objectftb.externalRefNo):'' 
            this.FTBGcCover = objectftb.hasOwnProperty('gcCoverage')?this.chkplndata(objectftb.gcCoverage):'' 
            this.FTBIrdaUINNo = objectftb.hasOwnProperty('irdaUanNo')?this.chkplndata(objectftb.irdaUanNo):'' 
            this.FTBRemarks = objectftb.hasOwnProperty('remarks')?this.chkplndata(objectftb.remarks):'' 
            this.FTBCondField = objectftb.hasOwnProperty('conditions')?this.chkplndataarry(objectftb.conditions):[] 
            this.FTBExclus = objectftb.hasOwnProperty('exclusions')?this.chkplndataarry(objectftb.exclusions):[]
            this.FTBSelectBox = objectftb.hasOwnProperty('relationship')?this.chkplnBooleandata(objectftb.relationship):false
              
            if(this.FTBSelectBox){
                let _relCoverDet = objectftb.hasOwnProperty('relationShipsCoveredDetails')?this.chkplndataarry(objectftb.relationShipsCoveredDetails):[]
                this.process_relationship_dependent(_relCoverDet,res=>{
                    this.FTBrelCoverData = {}
                    this.FTBrelCoverData = res
                    this.showFTBRelData = true
                })
            }
            this.FTBRelCheckSelected()
            this.ftbDta=[]  
            this.ftbDta.push(objectftb)  
    },
    FTBRelCheckSelected(){
        this.showFTBRelSection=false
        this.showFTBRelData = false
        if (this.FTBSelectBox){
            this.showFTBRelSection=true
            this.showFTBRelData = true
        }
    },
    rbndGcci(arryRecieved){
        for(let i=0; i<arryRecieved.length;i++){
            if(arryRecieved[i].hasOwnProperty('coverageCode') && arryRecieved[i].coverageCode==="GC"){
                this.gcCoverageType=arryRecieved[i].hasOwnProperty('coverageType')?this.chkplndata(arryRecieved[i].coverageType):''
                this.gcCoverageBasis=arryRecieved[i].hasOwnProperty('coverageBasis')?this.chkplndata(arryRecieved[i].coverageBasis):''
                this.chngGcCb(2)
                this.gcSubLimitBasis=arryRecieved[i].hasOwnProperty('subLimitBasis')?this.chkplndataarry(arryRecieved[i].subLimitBasis):[]
                this.chngGcSubLmtBasis()
                this.gcClaimPaymentBasis=arryRecieved[i].hasOwnProperty('claimPaymentBasis')?this.chkplndata(arryRecieved[i].claimPaymentBasis):''
                this.chngGcClaimPaymentBasis()
                this.gcWaitingPeriod=arryRecieved[i].hasOwnProperty('waitingPeriod')?this.chkplndata(arryRecieved[i].waitingPeriod):''
                this.gcwaitingPeriodDrop=arryRecieved[i].hasOwnProperty('waitingPeroidType')?this.chkplndata(arryRecieved[i].waitingPeroidType):'Days'
                this.gcFlatAmt=arryRecieved[i].hasOwnProperty('flatAmount')?this.chkplndata(arryRecieved[i].flatAmount):''
                this.gcPrcntSi=arryRecieved[i].hasOwnProperty('perOfSi')?this.chkplndata(arryRecieved[i].perOfSi):''
                this.gcPrcntPrvSi=arryRecieved[i].hasOwnProperty('perOfPreviousSi')?this.chkplndata(arryRecieved[i].perOfPreviousSi):''
                this.gcPrcntClmAmt=arryRecieved[i].hasOwnProperty('perOfClaimAmount')?this.chkplndata(arryRecieved[i].perOfClaimAmount):''
                this.gcAplcblClmPyot=arryRecieved[i].hasOwnProperty('applicableClaimPayout')?this.chkplndata(arryRecieved[i].applicableClaimPayout):''
                this.gcCad=arryRecieved[i].hasOwnProperty('coverageApplicableDuration')?this.chkplndata(arryRecieved[i].coverageApplicableDuration):''
                this.gcMnmHspitlztnDrtn=arryRecieved[i].hasOwnProperty('minHospitalization')?this.chkplndata(arryRecieved[i].minHospitalization):''
                this.gcCntryofDseDtctn=arryRecieved[i].hasOwnProperty('countryOfDiseaseDetection')?this.chkplndata(arryRecieved[i].countryOfDiseaseDetection):''
                this.gccheckbox=arryRecieved[i].hasOwnProperty('relationship')?this.chkplnBooleandata(arryRecieved[i].relationship):false
                this.GCCoverAltName = arryRecieved[i].hasOwnProperty('alternateName')?this.chkplndata(arryRecieved[i].alternateName):'' 
                this.GCExtRefNo = arryRecieved[i].hasOwnProperty('externalRefNo')?this.chkplndata(arryRecieved[i].externalRefNo):'' 
                this.GCGcCover = arryRecieved[i].hasOwnProperty('gcCoverage')?this.chkplndata(arryRecieved[i].gcCoverage):'' 
                this.GCIrdaUINNo = arryRecieved[i].hasOwnProperty('irdaUanNo')?this.chkplndata(arryRecieved[i].irdaUanNo):'' 
                this.GCRemarks = arryRecieved[i].hasOwnProperty('remarks')?this.chkplndata(arryRecieved[i].remarks):'' 
                this.GCCondField = arryRecieved[i].hasOwnProperty('conditions')?this.chkplndataarry(arryRecieved[i].conditions):[] 
                this.GCExclus = arryRecieved[i].hasOwnProperty('exclusions')?this.chkplndataarry(arryRecieved[i].exclusions):[]
                if(this.gccheckbox){
                    let tempGlblCvrRlcvrd=arryRecieved[i].hasOwnProperty('relationShipsCoveredDetails')?this.chkplndataarry(arryRecieved[i].relationShipsCoveredDetails):[]
                    this.process_relationship_dependent(tempGlblCvrRlcvrd,res=>{
                        this.tempGcRlcvrd={}
                        this.tempGcRlcvrd=res
                        this.gcreldatashw=true
                    })
                }
                this.gcRdiSelected()
                this.gcData.push(arryRecieved[i])
            }else if(arryRecieved[i].hasOwnProperty('coverageCode') && arryRecieved[i].coverageCode==="CI"){
                this.cicoverageType=arryRecieved[i].hasOwnProperty('coverageType')?this.chkplndata(arryRecieved[i].coverageType):''
                this.cicoverageBasis=arryRecieved[i].hasOwnProperty('coverageBasis')?this.chkplndata(arryRecieved[i].coverageBasis):''
                this.cichangeOnover(2)
                this.cisubLimitBasis=arryRecieved[i].hasOwnProperty('subLimitBasis')?this.chkplndataarry(arryRecieved[i].subLimitBasis):[]
                this.chngCiSubLmtBasis()
                this.ciclaimPaymentBasis=arryRecieved[i].hasOwnProperty('claimPaymentBasis')?this.chkplndata(arryRecieved[i].claimPaymentBasis):''
                this.chngCiClaimPaymentBasis()
                this.ciwaitingPeriod=arryRecieved[i].hasOwnProperty('waitingPeriod')?this.chkplndata(arryRecieved[i].waitingPeriod):''
                this.ciFlatAmt=arryRecieved[i].hasOwnProperty('flatAmount')?this.chkplndata(arryRecieved[i].flatAmount):''
                this.ciPrcntSi=arryRecieved[i].hasOwnProperty('perOfSi')?this.chkplndata(arryRecieved[i].perOfSi):''
                this.ciPrcntPrvSi=arryRecieved[i].hasOwnProperty('perOfPreviousSi')?this.chkplndata(arryRecieved[i].perOfPreviousSi):''
                this.ciPrcntClmAmt=arryRecieved[i].hasOwnProperty('perOfClaimAmount')?this.chkplndata(arryRecieved[i].perOfClaimAmount):''
                this.ciAplcblClmPyot=arryRecieved[i].hasOwnProperty('applicableClaimPayout')?this.chkplndata(arryRecieved[i].applicableClaimPayout):''
                this.ciCad=arryRecieved[i].hasOwnProperty('coverageApplicableDuration')?this.chkplndata(arryRecieved[i].coverageApplicableDuration):''
                this.ciSurvivalPeriod=arryRecieved[i].hasOwnProperty('survivalPeriod')?this.chkplndata(arryRecieved[i].survivalPeriod):''
                this.ciisSelectBox=arryRecieved[i].hasOwnProperty('relationship')?this.chkplnBooleandata(arryRecieved[i].relationship):false
                this.ciCoveredCriticalIllness=arryRecieved[i].hasOwnProperty('coveredCriticalIllness')?this.chkplndataarry(arryRecieved[i].coveredCriticalIllness):[]
                if(this.ciisSelectBox){
                    let tempDta=arryRecieved[i].hasOwnProperty('relationShipsCoveredDetails')?this.chkplndataarry(arryRecieved[i].relationShipsCoveredDetails):[]
                    this.process_relationship_dependent(tempDta,res=>{
                        this.tempCiRlcvrd={}
                        this.tempCiRlcvrd=res
                        this.cireldatashw=true
                    })
                }
                this.ciRdiSelected()
                this.ciData.push(arryRecieved[i])
            }
        }
    },
    gcRdiSelected(){
        this.shwGcRdi=false
        this.gcreldatashw=false
        if (this.gccheckbox){
            this.shwGcRdi=true
            this.gcreldatashw=true
        }
    },
    ciRdiSelected(){
        this.shwCiRdi=false
        this.cireldatashw=false
        if (this.ciisSelectBox){
            this.shwCiRdi=true
            this.cireldatashw=true
        }
    },
    HedRdiSelected(){
        this.shwHedRdi=false
        this.hereldatashw=false
        if (this.hedisSelectBox){
            this.shwHedRdi=true
            this.hereldatashw=true
        }

    },

     chkplndataarry(data){
            console.log("chkplndataarry",data); 
            try{
                if (data ==="" || data === "undefined" || data === null) {
                    return []
                } else {
                    return data;
                }

             }catch(err) {
                console.log(err)
            }
            
        },
        chkplnBooleandata(data){
            console.log("chkplndataarry",data); 
            try{
                if (data ==="" || data === "undefined" || data === null) {
                    return false
                } else {
                    return data;
                }

             }catch(err) {
                console.log(err)
            }
        },

        chkplndata(data){
            console.log("chkplndata",data); 
            try{
                if (data ==="" || data === "undefined" || data === null) {
                    return ""
                } else {
                    return data;
                }

             }catch(err) {
                console.log(err)
            }
            
        },
    // AA start
    // 0.mat

        matchangeOnover(ev){
            this.showMCFlatAmnt=false
            this.showMCPercentSI=false
            this.showMCPercClaimAmt=false
            this.showMCApplicClaimPay = false
            this.showMCCovrageApplicDur = false
            this.showMCPrvPercentSI=false
            this.showMCOpdSi = false
            this.showMCAdSi = false
            this.MCFlatAmountTxtField=""
            this.MCPercentSI=""
            this.MCPrvPercentSI=""
            this.MCPercentClaimAmt=""
            this.MCApplicClaimPayOut=''
            this.MCCovrageApplicDur=''
            this.matclaimPaymentBasis=''
            this.matsubLimitBasis=[]
            this.MCOpdSi = ''
            this.MCAdSi = ''
            
            ev == 1 ? this.$refs.matForm.resetValidation() : ''

            console.log("flat---->",this.matcoverageBasis)
            if(this.matcoverageBasis== 'Over & above SI' || this.matcoverageBasis== 'Upto SI with sub limit'){
                this.matsubBasisshow=true
            }else {
                this.cvrgBasisItems== []
                this.matsubBasisshow=false
                
            }
        },
        changeMCPreCoverBasis(ev){
            this.showMCPreFlatAmnt=false
            this.showMCPrePercentSI=false
            this.showMCPrePercClaimAmt=false
            this.showMCPreApplicClaimPay = false
            this.showMCPreCovrageApplicDur = false
            this.showMCPrePrevPercSI = false
            this.MCPreFlatAmountTxtField=""
            this.MCPrePercentSI=""
            this.MCPrePercentPrevSI = ''
            this.MCPrePercentClaimAmt=""
            this.MCPreApplicClaimPayOut=''
            this.MCPreCovrageApplicDur=''
            this.matpreclaimpay=''
            this.matpresublimit=[]
            // ev == 1 ? this.$refs.matForm.resetValidation() : ''

            if(this.matprecoverbasis == 'Over & above SI' || this.matprecoverbasis == 'Upto SI with sub limit'){
                this.MCPreshowSubLimClaimPay = true
            }else {
                this.cvrgBasisItems == []
                this.MCPreshowSubLimClaimPay = false
                
            }
        },
        changeMCPostCoverBasis(ev){
            this.showMCPostFlatAmnt=false
            this.showMCPostPercentSI=false
            this.showMCPostPercClaimAmt=false
            this.showMCPostApplicClaimPay = false
            this.showMCPostCovrageApplicDur = false
            this.showMCPostPrevPercSI = false
            this.MCPostFlatAmountTxtField=""
            this.MCPostPercentSI=""
            this.MCPostPercentPrevSI = ''
            this.MCPostPercentClaimAmt=""
            this.MCPostApplicClaimPayOut=''
            this.MCPostCovrageApplicDur=''
            this.matpostclaimpay=''
            this.matpostsublimit=[]
            
            // ev == 1 ? this.$refs.matForm.resetValidation() : ''

            if(this.matpostcoverbasis == 'Over & above SI' || this.matpostcoverbasis == 'Upto SI with sub limit'){
                this.MCPostshowSubLimClaimPay = true
            }else {
                this.cvrgBasisItems == []
                this.MCPostshowSubLimClaimPay = false
                
            }
        },

        matflatchange(event){
            this.showMCPercentSI = false
            this.showMCFlatAmnt = false
            this.showMCPercClaimAmt = false
            this.showMCApplicClaimPay = false
            this.matflatshow = false
            this.showMCPrvPercentSI=false
            // New Fields Added 
            this.showMCOpdSi = false
            this.showMCAdSi = false

            for(let i = 0; i<= event.length; i++){
                if(event[i] !== undefined){
                    event[i] == '% of SI' ? this.showMCPercentSI = true : ''
                    if(event[i] == 'Flat amount'){
                        this.showMCFlatAmnt = true
                        this.matflatshow = true
                    }
                    event[i] == '% of claimed amount' ? this.showMCPercClaimAmt = true : ''
                    event[i] == '% of previous yr SI' ? this.showMCPrvPercentSI = true : ''
                    // New Fields Added 
                    event[i] == '% of OPD SI' ? this.showMCOpdSi = true : ''
                    event[i] == '% of AD SI' ? this.showMCAdSi = true : ''
                }
            }

            event.length > 1 ? this.showMCApplicClaimPay = true : ''
            this.showMCPercentSI == false ? this.MCPercentSI = '' : ''
            // this.showMCFlatAmnt == false ? this.MCFlatAmountTxtField = '' : ''
            if(this.showMCFlatAmnt == false){
                this.MCFlatAmountTxtField = ''
                this.matvaryongender = 'No'
                this.matyesgirlchild = ''
                this.showdata = false
            }
            this.showMCPercClaimAmt == false ? this.MCPercentClaimAmt = '' : ''
            this.showMCPrvPercentSI == false ? this.MCPrvPercentSI = '' : ''
            this.showMCOpdSi == false ? this.MCOpdSi = '' : ''
            this.showMCAdSi == false ? this.MCAdSi = '' : ''
            // this.matflatshow == false ? this.matvaryongender = 'No' : '''
        },
        matPreSubLimChange(event){
            this.showMCPrePercentSI = false
            this.showMCPreFlatAmnt = false
            this.showMCPrePrevPercSI = false
            this.showMCPrePercClaimAmt = false
            this.showMCPreApplicClaimPay = false

            for(let i = 0; i<= event.length; i++){
                if(event[i] !== undefined){
                    event[i] == '% of SI' ? this.showMCPrePercentSI = true : ''
                    event[i] == 'Flat amount' ? this.showMCPreFlatAmnt = true : ''
                    event[i] == '% of previous yr SI' ? this.showMCPrePrevPercSI = true : ''
                    event[i] == '% of claimed amount' ? this.showMCPrePercClaimAmt = true : ''
                }
            }

            event.length > 1 ? this.showMCPreApplicClaimPay = true : ''
            this.showMCPrePercentSI == false ? this.MCPrePercentSI = '' : ''
            this.showMCPreFlatAmnt == false ? this.MCPreFlatAmountTxtField = '' : ''
            this.showMCPrePrevPercSI == false ? this.MCPrePercentPrevSI = '' : ''
            this.showMCPrePercClaimAmt == false ? this.MCPrePercentClaimAmt = '' : ''
        },
        matPostSubLimChange(event){
            this.showMCPostPercentSI = false
            this.showMCPostFlatAmnt = false
            this.showMCPostPrevPercSI = false
            this.showMCPostPercClaimAmt = false
            this.showMCPostApplicClaimPay = false

            for(let i = 0; i<= event.length; i++){
                if(event[i] !== undefined){
                    event[i] == '% of SI' ? this.showMCPostPercentSI = true : ''
                    event[i] == 'Flat amount' ? this.showMCPostFlatAmnt = true : ''
                    event[i] == '% of previous yr SI' ? this.showMCPostPrevPercSI = true : ''
                    event[i] == '% of claimed amount' ? this.showMCPostPercClaimAmt = true : ''
                }
            }

            event.length > 1 ? this.showMCPostApplicClaimPay = true : ''
            this.showMCPostPercentSI == false ? this.MCPostPercentSI = '' : ''
            this.showMCPostFlatAmnt == false ? this.MCPostFlatAmountTxtField = '' : ''
            this.showMCPostPrevPercSI == false ? this.MCPostPercentPrevSI = '' : ''
            this.showMCPostPercClaimAmt == false ? this.MCPostPercentClaimAmt = '' : ''
        },
        changeMCClaimPayBas(event){
            this.showMCCovrageApplicDur = false
            // for(let i = 0; i<= event.length; i++){
                event !== undefined ? event== 'Duration basis' ? this.showMCCovrageApplicDur = true : '' : ''
            // } 
            this.showMCCovrageApplicDur === false ? this.MCCovrageApplicDur = '' : ''
        },
        matPostClaimPayChange(event){
            this.showMCPostCovrageApplicDur = false
            // for(let i = 0; i<= event.length; i++){
                event !== undefined ? event == 'Duration basis' ? this.showMCPostCovrageApplicDur = true : '' : ''
            // } 
            this.showMCPostCovrageApplicDur === false ? this.MCPostCovrageApplicDur = '' : ''
        },
        matPreClaimPayChange(event){
            this.showMCPreCovrageApplicDur = false
            // for(let i = 0; i<= event.length; i++){
                event !== undefined ? event == 'Duration basis' ? this.showMCPreCovrageApplicDur = true : '' : ''
            // } 
            this.showMCPreCovrageApplicDur === false ? this.MCPreCovrageApplicDur = '' : ''
        },

    selctdvaryongender(){
            console.log("matvaryongender---->",this.matvaryongender)
            
            if(this.matvaryongender==="No"){
                this.showdata=false
            }
            if(this.matvaryongender==="Yes"){
                this.showdata=true
            }
        },

        selctdvaryoncity(){
            console.log("matvaryoncity---->",this.matvaryoncity)
            
            if(this.matvaryoncity==="No"){
                this.showdatacity=false
            }
            if(this.matvaryoncity==="Yes"){
                this.showdatacity=true
            }
        },

        selctdnatal(){
            console.log("matnatal---->",this.matnatal)
            
            if(this.matnatal==="No"){
                this.showdatanatal=false
                this.showdatalimit=false
                this.matpreflatamount = ''
                this.matpostflatamount = ''
                this.matlimit='No'
            }
            
            
            if(this.matnatal==="Yes"){
                this.showdatanatal=true
            }
            
        },

        selctdlimit(){
            console.log("matlimit---->",this.matlimit)
            
            if(this.matlimit==="No"){
                this.showdatalimit=false

                this.matprecoverbasis = 'Upto SI'
                this.showMCPreFlatAmnt=false
                this.showMCPrePercentSI=false
                this.showMCPrePercClaimAmt=false
                this.showMCPreApplicClaimPay = false
                this.showMCPreCovrageApplicDur = false
                this.MCPreshowSubLimClaimPay = false
                this.MCPreFlatAmountTxtField=""
                this.MCPrePercentSI=""
                this.MCPrePercentClaimAmt=""
                this.MCPreApplicClaimPayOut=''
                this.MCPreCovrageApplicDur=''
                this.matpreclaimpay=''
                this.matpresublimit=[]

                this.matpostcoverbasis = 'Upto SI'
                this.showMCPostFlatAmnt=false
                this.showMCPostPercentSI=false
                this.showMCPostPercClaimAmt=false
                this.showMCPostApplicClaimPay = false
                this.showMCPostCovrageApplicDur = false
                this.MCPostshowSubLimClaimPay = false
                this.MCPostFlatAmountTxtField=""
                this.MCPostPercentSI=""
                this.MCPostPercentClaimAmt=""
                this.MCPostApplicClaimPayOut=''
                this.MCPostCovrageApplicDur=''
                this.matpostclaimpay=''
                this.matpostsublimit=[]
                // this.getMCData()
            }
            if(this.matlimit==="Yes"){
                this.showdatalimit=true
                this.matprecoverbasis = ''
                this.matpostcoverbasis = ''
                // this.getMCData()
            }
        },

        //1. hcbc
        
        hcbcchangeOnover(ev){
            this.showHCBACIFlatAmnt = false
            this.showHCBACIPercentSi = false
            this.showHCBACIPercClaimAmt = false
            this.hcbcshowAppli = false
            this.showHCBACICovrageApplicDur = false
            this.showHCBACIPrvPercentSi = false

            this.HCBACIFlatAmountTxtField = ''
            this.HCBACIPercentSI = ''
            this.HCBACIPrvPercentSI = ''
            this.HCBACIPercentClaimAmt = ''
            this.hcbcapplicableClaimPayOut = ''
            this.HCBACICovrageApplicDur = ''
            this.hcbcclaimPaymentBasis = ''
            this.hcbcsubLimitBasis = []
            ev == 1 ? this.$refs.hcbcForm.resetValidation() : ''

            console.log("flat---->",this.hcbccoverageBasis)
            if(this.hcbccoverageBasis== 'Over & above SI' || this.hcbccoverageBasis== 'Upto SI with sub limit'){
                this.hcbcsubBasisshow=true
            }else {
                this.cvrgBasisItems== []
                this.hcbcsubBasisshow=false
                
            }
        },

        hcbcflatchange(event){
            this.showHCBACIPercentSi = false
            this.showHCBACIFlatAmnt = false
            this.showHCBACIPercClaimAmt = false
            this.hcbcshowAppli = false
            this.showHCBACIPrvPercentSi=false 

            for(let i = 0; i<= event.length; i++){
                if(event[i] !== undefined){
                    event[i] == '% of SI' ? this.showHCBACIPercentSi = true : ''
                    event[i] == 'Flat amount' ? this.showHCBACIFlatAmnt = true : ''
                    event[i] == '% of claimed amount' ? this.showHCBACIPercClaimAmt = true : ''
                    event[i] == '% of previous yr SI' ? this.showHCBACIPrvPercentSi = true : ''
                }
            }

            event.length > 1 ? this.hcbcshowAppli = true : ''
            this.showHCBACIPercentSi == false ? this.HCBACIPercentSI = '' : ''
            this.showHCBACIFlatAmnt == false ? this.HCBACIFlatAmountTxtField = '' : ''
            this.showHCBACIPercClaimAmt == false ? this.HCBACIPercentClaimAmt = '' : ''
            this.showHCBACIPrvPercentSi == false ? this.HCBACIPrvPercentSI = '' : ''
        },

        // 2.hcb

        hcbchangeOnover(ev){
            this.showHCBFlatAmnt = false
            this.showHCBPercentSi = false
            this.showHCBPercClaimAmt = false
            this.hcbshowAppli = false
            this.showHCBCovrageApplicDur = false
            this.showHCBPrvPercentSi = false

            this.HCBFlatAmountTxtField = ''
            this.HCBPercentSI = ''
            this.HCBPrvPercentSI = ''
            this.HCBPercentClaimAmt = ''
            this.hcbapplicableClaimPayOut = ''
            this.HCBCovrageApplicDur = ''
            this.hcbclaimPaymentBasis = ''
            this.hcbsubLimitBasis = []

            ev == 1 ? this.$refs.hcbForm.resetValidation() : ''

            console.log("flat---->",this.hcbcoverageBasis)
            if(this.hcbcoverageBasis== 'Over & above SI' || this.hcbcoverageBasis== 'Upto SI with sub limit'){
                this.hcbsubBasisshow=true
            }else {
                this.cvrgBasisItems== []
                this.hcbsubBasisshow=false   
            }
        },
        changeHCBClaimPayBasis(event){
            this.showHCBCovrageApplicDur = false
            // for(let i = 0; i<= event.length; i++){
                event !== undefined ? event == 'Duration basis' ? this.showHCBCovrageApplicDur = true : '' : ''
            // } 
            this.showHCBCovrageApplicDur === false ? this.HCBCovrageApplicDur = '' : ''
        },

        changeHCBACIClaimPayBas(event){
            console.log("this.hcbcclaimPaymentBasis",this.hcbcclaimPaymentBasis)
            this.showHCBACICovrageApplicDur = false
            // for(let i = 0; i<= event.length; i++){
                event !== undefined ? event == 'Duration basis' ? this.showHCBACICovrageApplicDur = true : '' : ''
            // } 
            this.showHCBACICovrageApplicDur === false ? this.HCBACICovrageApplicDur = '' : ''
        },

        hcbflatchange(event){
            this.showHCBPercentSi = false
            this.showHCBFlatAmnt = false
            this.showHCBPercClaimAmt = false
            this.hcbshowAppli = false 
            this.showHCBPrvPercentSi=false
            for(let i = 0; i<= event.length; i++){
                if(event[i] !== undefined){
                    event[i] == '% of SI' ? this.showHCBPercentSi = true : ''
                    event[i] == 'Flat amount' ? this.showHCBFlatAmnt = true : ''
                    event[i] == '% of claimed amount' ? this.showHCBPercClaimAmt = true : ''
                    event[i] == '% of previous yr SI' ? this.showHCBPrvPercentSi = true : ''
                }
            }

            event.length > 1 ? this.hcbshowAppli = true : ''
            this.showHCBPercentSi == false ? this.HCBPercentSI = '' : ''
            this.showHCBFlatAmnt == false ? this.HCBFlatAmountTxtField = '' : ''
            this.showHCBPercClaimAmt == false ? this.HCBPercentClaimAmt = '' : ''
            this.showHCBPrvPercentSi == false ? this.HCBPrvPercentSI = '' : ''
        },

        // 3.hed

        hedchangeOnover(cmgfrm){
            this.shwhedFltAmt=false
            this.shwhedPerSI=false
            this.shwhedClmAmt=false
            this.showHEDOpdSi = false
            this.showHEDAdSi = false
            this.HEDOpdSi = ''
            this.HEDAdSi = ''

            this.hedFlatAmt=""
            this.hedClmAmt=""
            this.hedPerSI=""
            this.hedisSelectBox=false
            this.hedwaitingPeriod=""
            this.hedclaimPaymentBasis=''
            this.shwhedCvrgAplcblDrtn=false
            this.hedCad=''
            this.hedapplicableClaimPayOut=''
            this.hedallowedDiaTests=[]
            this.hedsubLimitBasis=[]

              this.shwhedPrvPerSI=false
            this.hedPrvPerSI=''
            console.log(this.hedcoverageBasis,"coverageBasis")
            // alert("reached chngVcntnCb ")
            if(cmgfrm===1){
                this.$refs.hedForm.resetValidation()
            }
            console.log("flat---->",this.hedcoverageBasis)
            if(this.hedcoverageBasis== 'Over & above SI' || this.hedcoverageBasis== 'Upto SI with sub limit'){
                this.hedsubBasisshow=true
            }else{
                // this.cvrgBasisItems== []
                this.hedsubBasisshow=false
            }
        },

       hedflatchange(){
            this.shwhedFltAmt=false
            this.shwhedClmAmt=false
            this.shwhedPerSI=false
            this.showhedacpo=false
            this.shwhedPrvPerSI=false
            // New Fields Added 
            this.showHEDOpdSi = false
            this.showHEDAdSi = false
            console.log(this.hedsubLimitBasis,"hedsubLimitBasis selected")
            for(let i=0;i<=this.hedsubLimitBasis.length;i++){
                if(this.hedsubLimitBasis[i]==="Flat amount"){
                    this.shwhedFltAmt=true
                }
            }
            for(let i=0;i<=this.hedsubLimitBasis.length;i++){
                if(this.hedsubLimitBasis[i]==="% of SI"){
                    this.shwhedPerSI=true
                }
            }
            for(let i=0;i<=this.hedsubLimitBasis.length;i++){
                if(this.hedsubLimitBasis[i]==="% of claimed amount"){
                    this.shwhedClmAmt=true
                }
            }
            for(let i=0;i<=this.hedsubLimitBasis.length;i++){
                if(this.hedsubLimitBasis[i]==="% of previous yr SI"){
                    this.shwhedPrvPerSI=true
                }
            }
            for(let i=0;i<=this.hedsubLimitBasis.length;i++){
                if(this.hedsubLimitBasis[i]==="% of OPD SI"){
                    this.showHEDOpdSi=true
                }
            }
            for(let i=0;i<=this.hedsubLimitBasis.length;i++){
                if(this.hedsubLimitBasis[i]==="% of AD SI"){
                    this.showHEDAdSi=true
                }
            }
             for(let i=0;i<=this.hedsubLimitBasis.length;i++){
                if(this.hedsubLimitBasis.length > 1){
                    this.showhedacpo=true
                }
             }

             if (this.shwhedPrvPerSI===false){
                this.hedPrvPerSI=""
            }
            this.showHEDOpdSi == false ? this.HEDOpdSi = '' : ''
            this.showHEDAdSi == false ? this.HEDAdSi = '' : ''

             
        },

        chnghedClaimPaymentBasis(){
            console.log(this.hedclaimPaymentBasis,"hedclaimPaymentBasis")
            this.shwhedCvrgAplcblDrtn=false
            console.log(this.hedclaimPaymentBasis,"hedclaimPaymentBasis selected")
            // for(let i=0;i<=this.hedclaimPaymentBasis.length;i++){
                if(this.hedclaimPaymentBasis==="Duration basis"){
                    this.shwhedCvrgAplcblDrtn=true
                }
            // }
        },
        save_Hed_updated({btntext,dta}){
            console.log(dta,"dta")
            // alert("now check dta")
             if(btntext==='hac'){
                    if (!this.$refs.hacForm.validate()){
                        this.$refs.hacForm.validate()
                        this.showToast("Please Fill Required Fields",this.TOST().WARNING);
                        return
                    }
                }else if(btntext==='hed'){
                   if (!this.$refs.hedForm.validate()){
                        this.$refs.hedForm.validate()
                        this.showToast("Please Fill Required Fields",this.TOST().WARNING);
                        return
                    } 
                }

              let formData={
                    //   coverageCode: "HED",
                     coverageType:this.hedcoverageType,
                    coverageBasis:this.hedcoverageBasis,
                    subLimitBasis:this.hedsubLimitBasis,
                    claimPaymentBasis:this.hedclaimPaymentBasis,
                    waitingPeriod:this.hedwaitingPeriod,
                    waitingPeroidType:this.hedwaitingPeriodDrop,
                    relationship:this.hedisSelectBox,
                    perOfSi: this.hedPerSI,
                    perOfPreviousSi:this.hedPrvPerSI,
                    perOfOpdSi: this.HEDOpdSi,
                    perOfAdSi: this.HEDAdSi,
                    perOfClaimAmount: this.hedClmAmt,
                    coverageApplicableDuration: this.hedCad,
                    applicableClaimPayout : this.hedapplicableClaimPayOut,
                    flatAmount: this.hedFlatAmt,
                    allowedDiagnosticsTest:this.hedallowedDiaTests,
                    relationship:this.hedisSelectBox,
                    irdaUanNo: this.HEDIrdaUINNo,
                    externalRefNo: this.HEDExtRefNo,
                    alternateName: this.HEDCoverAltName,
                    gcCoverage: this.HEDGcCover,
                    exclusions: this.HEDExclus,
                    remarks: this.HEDRemarks,
                    conditions: this.HEDCondField,
                    // relationShipsCovered:[],
                    // relationShipsCoveredDetails:[{}]
                }
                if(dta != null && this.hedisSelectBox===true){
                    formData.relationShipsCovered=dta.relationShipsCovered
                    let activedataGc=dta.relationShipsCoveredDetails.map(e=> {
                        return{
                                active: e.active,
                                applicableValue: e.applicableValue,
                                checkbox: e.checkbox,
                                coPay: e.coPay,
                                coverType: e.coverType,
                                flatAmount:e.flatAmount,
                                roomRentLimit: e.roomRentLimit,
                                siPercent: e.siPercent,
                                siSubLimit:  e.siSubLimit,
                                waitingPeriod: e.waitingPeriod,
                        }
                    }) 
                    console.log('%c activedataGC=>  ',"color: yellow",activedataGc);
                    formData.relationShipsCoveredDetails=activedataGc
                    // alert("GC Form data")
                }
                console.log(formData,"=========hedForm Data")
                let formData2={
                    //   coverageCode: "HA",
                     coverageType: this.hacCoverageType,
                    coverageBasis: this.hacCoverageBasis,
                    subLimitBasis: this.hacSubLimitBasis,
                    claimPaymentBasis: this.hacClaimPaymentBasis,
                     flatAmount: this.hacFlatAmt,
                     perOfSi: this.hacPerSI,
                     perOfPreviousSi:this.hacPrvPerSI,
                     perOfClaimAmount: this.hacClmAmt,
                    waitingPeriod: this.hacWaitingPeriod,
                    applicableClaimPayout: this.hacAplcblClmPyot,
                    coverageApplicableDuration: this.hacCad,
                }
                console.log(formData2,"=========hacForm Data")
                this.grpCtcVctn(formData,response=>{
                    console.log('fhed arr push is ? ',response)
                    if(btntext==='hed'){
                        this.fhed = []
                        if(response){
                            console.log(formData,"Before HED Push")
                           formData.coverageCode="HED"
                            this.fhed.push(formData)
                            console.log(this.fhed,"after HED Push")
                        }
                    }else{
                        
                        if(this.hedisSelectBox){
                                // alert("GC Data blank")
                                console.log(this.fhed,"GC Data")
                                this.fhed[0].relationShipsCoveredDetails= this.mapRelFrmDta(this.fhed[0].relationShipsCoveredDetails)
                               console.log('this.fhed[0].relationShipsCoveredDetails ? ',this.fhed[0].relationShipsCoveredDetails)  
                            }
                    }
                    console.log("fd for api is ", formData)
                        this.grpCtcVctn(formData2,res=>{
                            console.log('fhac arr push is ?', res)   
                        if(btntext==='hac'){
                            this.fhac = []
                            if(res){
                                console.log(formData2,"Before HA Push")
                                formData2.coverageCode="HA"
                                this.fhac.push(formData2)
                                console.log(this.fhac,"after HA Push")
                            }
                        }
                        var finalDatahahed = [
                            ...this.fhed, ...this.fhac
                        ]
                        console.log(finalDatahahed,"finalDatahahed")
                         let apiURL=''
                            if(this.btnName==="ConfgrPlnFrPlcy"){
                                apiURL="group-plan/update-group-hahed/"+this.plan._id
                            }else if(this.btnName==="CnfgrNwRtlPln"){
                                apiURL="retail-plan/update-retail-hahed/"+this.plan._id
                            }
                            this.PUT(1,apiURL,finalDatahahed,(res,error)=>{
                                    if(!error){
                                        console.log("PUT APi response|-> hahed ", res)
                                        if(btntext==='hac'){
                                            this.showToast("Hearing Aid Cover Coverage details has been updated successfully",this.TOST().SUCCESS)
                                        }else if(btntext==='hed'){
                                            this.showToast("High End Diagnostics Coverage details has been updated successfully",this.TOST().SUCCESS)
                                        }
                                            
                                    }else{
                                        console.log("PUT APi error response|->hahed", error)
                                         this.showToast(error.response.data.msg,this.TOST().ERROR);
                                    }    
                                })
                                })
                            })

        },
         saveHAHED(txt){

            if(txt==='hac'){
               this.save_Hed_updated({btntext:txt,dta:null})
            }else if(txt==='hed'){
                if(this.hedisSelectBox){
                    this.$refs.hedFrmPropUpdted.save_click(txt)
                }else{
                   this.save_Hed_updated({btntext:txt,dta:null})
                }
            }

               
                // alert("txt")
              
        },

        // hedflatchange(){
        //     console.log("flat---->",this.hedsubLimitBasis)
        //     // for(let i=0;i<=this.hedsubLimitBasis.length;i++){
        //     if(this.hedsubLimitBasis== 'Flat amount'){
        //         this.hedflatshow=true
        //     }else{
        //         this.hedflatshow=false
                
        //     }
        //     // }

        // },

hed_save(){

        if(this.btnName==="EditPlnFrPlcy" ||this.btnName==="CrteNwPlnFrPlcy"){
            console.log("if")
            this.hededitGrpPlanById() 
         }else if(this.btnName==="CrteNwRtlPln" || this.btnName==="CnfgrNwRtlPln" ||this.btnName==="EditNwRtlPln"){
            this.hedUpdteRtlPlnById()
        }
        
     },
        //     hededitGrpPlanById(){

        //              if (this.$refs.hedForm.validate()){
        //         this.showToast("Success",this.TOST().SUCCESS);
        //         let formData={
        //             coverageType:this.hedcoverageType,
        //             coverageBasis:this.hedcoverageBasis,
        //             subLimitBasis:this.hedsubLimitBasis,
        //             claimPaymentBasis:this.hedclaimPaymentBasis,
        //             waitingPeriod:this.hedwaitingPeriod,
        //             relationship:this.hedisSelectBox,
        //             perOfSi: this.hedPerSI,
        //             perOfClaimAmount: this.hedClmAmt,
                   
        //             flatAmount: this.hedFlatAmt,
        //             allowedDiagnosticsTest:this.hedallowedDiaTests,
        //             // relationShipsCovered:[],
        //             // relationShipsCoveredDetails:[{}]
        //         }
        //         console.log(formData,"=========hedForm Data")

        //     //      let apiURL=''
        //     // if(self.btnName==="ConfgrPlnFrPlcy" || self.btnName==="EditPlnFrPlcy" ||self.btnName==="CrteNwPlnFrPlcy"){
        //     //     apiURL=self.API_Service_admin()+"group-plan/update-retail-hahed/"+self.$store.getters.fetchPlcyIdForPlnData.plan_No
        //     // }else if(self.btnName==="CnfgrNwRtlPln" || self.btnName==="CrteNwRtlPln" || self.btnName==="EditNwRtlPln"){
        //     //     apiURL=self.API_Service_admin()+"retail-plan/update-retail-hahed/"+self.$store.getters.fetchPlcyIdForPlnData.retailPlan._Id
        //     // }

        //     // console.log("Stopped as simulating");
            
        //     // this.nextbtn=false
        //     // console.log(' hed form saved data',formData)
        //     axios({
        //         method: "put",
        //         url: self.API_Service_admin()+"group-plan/update-retail-hahed/"+self.$store.getters.fetchPlcyIdForPlnData.plan_No,
        //         headers: {
        //             "Content-Type": "application/json",
        //             "x-access-token": self.token
        //             // "x-access-token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI1ZmJlMDg4ZGM0NmRhODNlZTdmODcxYmIiLCJyb2xlIjoiQWRtaW5Vc2VyIiwiaWF0IjoxNjA2MzY5MzI0LCJleHAiOjE2Mzc5MDUzMjR9.iS5hZs0uImNFz-rxNaQ7CTCc6qS_hG4H0FsePZ5Sgqc",
        //         },
        //         data:formData,
        //         })
        //     .then(function (response) {
        //         console.log(" hed for product API Response>>>>>>", response);
        //         self.showToast(response.data.msg , self.TOST().SUCCESS);

        //     })
        //     .catch(function (error) {
        //         console.log(" hed Error APi Response>>>>>>", error);
        //         self.showToast(error.response.data.msg, self.TOST().ERROR);
        //     });
           

        //     }else{
        //         this.$refs.hedForm.validate()
        //         this.showToast("Please Fill Required Fields",this.TOST().WARNING);
        //     }
            
        //     //     this.$v.$touch();
        //     //     if(this.$v.$invalid){
        //     //         // console.log("Form has errors",this.$v);  
        //     //         this.showToast('Please fill all required fields', this.TOST().WARNING);
        //     //         return
        //     //     }
        //     //     console.log('proceed')
                
        //     //     let self=this
        //     // var formData ={

        //     //     coverageType:this.hedcoverageType,
        //     //         coverageBasis:this.hedcoverageBasis,
        //     //         subLimitBasis:this.hedsubLimitBasis,
        //     //         claimPaymentBasis:this.hedclaimPaymentBasis,
        //     //         waitingPeriod:this.hedwaitingPeriod,
        //     //         relationship:this.hedisSelectBox,
                   
        //     //         flatAmount: this.hedflatAmount,
        //     //         allowedDiagnosticsTest:this.hedallowedDiaTests,
        //     //         // relationShipsCovered:[],
        //     //         // relationShipsCoveredDetails:[{}]
        //     // }

        //     // console.log(' form saved data',formData)
            
        // },

        hededitGrpPlanById(){

            if (this.$refs.hedForm.validate()){
                let self=this
                self.showToast("Success",self.TOST().SUCCESS);
                let formData={
                    coverageType:this.hedcoverageType,
                    coverageBasis:this.hedcoverageBasis,
                    subLimitBasis:this.hedsubLimitBasis,
                    claimPaymentBasis:this.hedclaimPaymentBasis,
                    
                    waitingPeriod:this.hedwaitingPeriod,
                    relationship:this.hedisSelectBox,
                    perOfSi: this.hedPerSI,
                    perOfClaimAmount: this.hedClmAmt,
                    coverageApplicableDuration: self.hedCad,
                    applicableClaimPayout : self.hedapplicableClaimPayOut,
                   
                    flatAmount: this.hedFlatAmt,
                    allowedDiagnosticsTest:this.hedallowedDiaTests,
                    // relationShipsCovered:[],
                    // relationShipsCoveredDetails:[{}]
                }
                console.log(formData,"=========hedForm Data")
                let hed_id=self.$store.getters.fetchPlcyIdForPlnData.plan_No
                console.log(hed_id,"=========hed id")
                
                axios({
                method: "put",
                url:
                    // self.API_Service_admin()+"group-plan/update-group-hahed/"+self.$store.getters.fetchPlcyIdForPlnData.plan_No,
                    self.API_Service_admin()+"group-plan/update-group-hahed/"+self.$store.getters.fetchPlcyIdForPlnData.plan_No,
                headers: {
                    "Content-Type": "application/json",
                    "x-access-token": self.token
                    // "x-access-token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI1ZmJlMDg4ZGM0NmRhODNlZTdmODcxYmIiLCJyb2xlIjoiQWRtaW5Vc2VyIiwiaWF0IjoxNjA2MzY5MzI0LCJleHAiOjE2Mzc5MDUzMjR9.iS5hZs0uImNFz-rxNaQ7CTCc6qS_hG4H0FsePZ5Sgqc",
                },
                data:formData,
                })
                .then(function (response) {
                    console.log("hed grp----> Create Policy for product API Response>>>>>>", response);
                    self.showToast(response.data.msg , self.TOST().SUCCESS)

                })
                .catch(function (error) {
                    console.log("hed grp---> Create Policy for product Error APi Response>>>>>>", error);
                    self.showToast(error.response.data.msg,self.TOST().ERROR);
                });

            }else{
                self.$refs.hedForm.validate()
                self.showToast("Please Fill Required Fields",self.TOST().WARNING);
            }
        
        },

        hedUpdteRtlPlnById(){

            if (this.$refs.hedForm.validate()){
                let self=this
                self.showToast("Success",self.TOST().SUCCESS);
                

                let formData={
                    coverageType:this.hedcoverageType,
                    coverageBasis:this.hedcoverageBasis,
                    subLimitBasis:this.hedsubLimitBasis,
                    claimPaymentBasis:this.hedclaimPaymentBasis,
                    
                    waitingPeriod:this.hedwaitingPeriod,
                    relationship:this.hedisSelectBox,
                    perOfSi: this.hedPerSI,
                    perOfClaimAmount: this.hedClmAmt,
                    coverageApplicableDuration: self.hedCad,
                    applicableClaimPayout : self.hedapplicableClaimPayOut,
                   
                   
                    flatAmount: this.hedFlatAmt,
                    allowedDiagnosticsTest:this.hedallowedDiaTests,
                    // relationShipsCovered:[],
                    // relationShipsCoveredDetails:[{}]
                }
                console.log(formData,"=========hedForm Data")

                axios({
                method: "put",
                url:
                     self.API_Service_admin()+"retail-plan/retail-plan/update-retail-hahed/"+self.$store.getters.fetchPlcyIdForPlnData.retailPlan_Id,
                        //  self.API_Service_admin()+"retail-plan/retail-plan/update-retail-hahed/5fdb418013c81ae13f4c526e",
                    // self.API_Service_admin()+"retail-plan/retail-plan/update-retail-hahed/"+self.$store.getters.fetchProductCodeData,

                headers: {
                    "Content-Type": "application/json",
                    "x-access-token": self.token
                // "x-access-token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI1ZmJlMDg4ZGM0NmRhODNlZTdmODcxYmIiLCJyb2xlIjoiQWRtaW5Vc2VyIiwiaWF0IjoxNjA2MzY5MzI0LCJleHAiOjE2Mzc5MDUzMjR9.iS5hZs0uImNFz-rxNaQ7CTCc6qS_hG4H0FsePZ5Sgqc",
                },
                data:formData,
                })
                .then(function (response) {
                    console.log("hed retail----> Create Policy for product API Response>>>>>>", response);
                    self.showToast(response.data.msg ,self.TOST().SUCCESS)

                })
                .catch(function (error) {
                    console.log("hed retail----> Create Policy for product Error APi Response>>>>>>", error);
                    self.showToast(error.response.data.msg,self.TOST().ERROR);
                });

            }else{
                self.$refs.hedForm.validate()
                self.showToast("Please Fill Required Fields",self.TOST().WARNING);
            }
        
        
        },

            Ci_save(){

                     if (this.$refs.ciForm.validate()){
                this.showToast("Success",this.TOST().SUCCESS);
                let formData={
                coverageType:this.cicoverageType,
                    coverageBasis:this.cicoverageBasis,
                    subLimitBasis:this.cisubLimitBasis,
                    claimPaymentBasis:this.ciclaimPaymentBasis,
                    waitingPeriod:this.ciwaitingPeriod,
                    relationship:this.ciisSelectBox,
                   
                    coveredCriticalIllness: this.ciCoveredCriticalIllness,
                    survivalPeriod:this.ciSurvivalPeriod,
                    // relationShipsCovered:[],
                    // relationShipsCoveredDetails:[{}]

            }
                console.log(formData,"=========ciForm Data")

            }else{
                this.$refs.ciForm.validate()
                this.showToast("Please Fill Required Fields",this.TOST().WARNING);
            }


            // CriticalIll_save(){

            //     this.$refs.cifrmprop.save_click()
            
            //     this.$v.$touch();
                
            //     if(this.$v.$invalid){
            //         // console.log("Form has errors",this.$v);  
            //         this.showToast('Please fill all required fields', this.TOST().WARNING);
            //         return

            //     }
            //     console.log(formData,"=========ciForm Data")

            // }else{
            //     this.$refs.ciForm.validate()
            //     this.showToast("Please Fill Required Fields",this.TOST().WARNING);
            // }
            
            
            //     this.$v.$touch();
            //     if(this.$v.$invalid){
            //         // console.log("Form has errors",this.$v);  
            //         this.showToast('Please fill all required fields', this.TOST().WARNING);
            //         return
            //     }
            //     console.log('proceed')
                
            //     let self=this
            // var formData ={
            //         coverageType:this.cicoverageType,
            //         coverageBasis:this.cicoverageBasis,
            //         subLimitBasis:this.cisubLimitBasis,
            //         claimPaymentBasis:this.ciclaimPaymentBasis,
            //         waitingPeriod:this.ciwaitingPeriod,
            //         relationship:this.ciisSelectBox,
                   
            //         coveredCriticalIllness: this.ciCoveredCriticalIllness,
            //         survivalPeriod:this.ciSurvivalPeriod,
            //         // relationShipsCovered:[],
            //         // relationShipsCoveredDetails:[{}]

            // }
            // // this.nextbtn=false
            // console.log('form saved data',formData)
            
        },
        

        // 5.plh

        plhchangeOnover(ev){
            this.showPHFlatAmnt = false
            this.plhSIshow = false
            this.showPHPercClaimAmt = false
            this.showPHApplicClaimPay = false
            this.showCovrageApplicDur = false
            this.plhPrvSIshow = false
            this.showPHOpdSi = false
            this.showPHAdSi = false

            this.PHOpdSi = ''
            this.PHAdSi = ''
            this.PHFlatAmountTxtField = ''
            this.plhperSI = ''
            this.plhPrvperSI = ''
            this.PHPercentClaimAmt = ''
            this.PHApplicClaimPayOut = ''
            this.PHCovrageApplicDur = ''
            this.plhclaimPaymentBasis = ''
            this.plhsubLimitBasis = []

            ev == 1 ? this.$refs.plhForm.resetValidation() : ''

            console.log("flat---->",this.plhcoverageBasis)
            if(this.plhcoverageBasis== 'Over & above SI' || this.plhcoverageBasis== 'Upto SI with sub limit'){
                this.plhsubBasisshow=true
            }else{
                this.plhsubBasisshow=false
                
            }
        },

        plhSIchange(event){
            this.plhSIshow = false
            this.showPHFlatAmnt = false
            this.showPHPercClaimAmt = false
            this.showPHApplicClaimPay = false
            this.plhPrvSIshow = false
            this.showPHOpdSi = false
            this.showPHAdSi = false

            for(let i = 0; i<= event.length; i++){
                if(event[i] !== undefined){
                    event[i] == '% of SI' ? this.plhSIshow = true : ''
                    event[i] == 'Flat amount' ? this.showPHFlatAmnt = true : ''
                    event[i] == '% of claimed amount' ? this.showPHPercClaimAmt = true : ''
                    event[i] == '% of previous yr SI' ? this.plhPrvSIshow = true : ''
                    event[i] == '% of OPD SI' ? this.showPHOpdSi = true : ''
                    event[i] == '% of AD SI' ? this.showPHAdSi = true : ''
                }
            }

            event.length > 1 ? this.showPHApplicClaimPay = true : ''
            this.plhSIshow == false ? this.plhperSI = '' : ''
            this.showPHFlatAmnt == false ? this.PHFlatAmountTxtField = '' : ''
            this.showPHPercClaimAmt == false ? this.PHPercentClaimAmt = '' : ''
            this.plhPrvSIshow == false ? this.plhPrvperSI = '' : ''
            this.showPHOpdSi == false ? this.PHOpdSi = '' : ''
            this.showPHAdSi == false ? this.PHAdSi = '' : ''

        },

        saveDCPHRel(event){
            if(event.btntext == 'dayCareCover'){
                if (!this.$refs.dccForm.validate()){
                    this.$refs.dccForm.validate()
                    this.showToast("Please Fill Required Fields",this.TOST().WARNING);
                    return
                } 
            }else if(event.btntext == 'prolongHospital'){
                if (!this.$refs.plhForm.validate()){
                    this.$refs.plhForm.validate()
                    this.showToast("Please Fill Required Fields",this.TOST().WARNING);
                    return
                }
            }
            let formData = {
                coverageType: this.dcccoverageType,
                coverageBasis: this.dcccoverageBasis,
                subLimitBasis:this.dccsubLimitBasis,
                claimPaymentBasis:this.dccclaimPaymentBasis,
                waitingPeriod: this.dccwaitingPeriod,
                waitingPeroidType: this.dccwaitingPeriodDrop,
                flatAmount: this.flatAmountTxtField,
                perOfSi: this.percentSI,
                perOfPreviousSi:this.PrvpercentSI,
                perOfOpdSi: this.DCCOpdSi,
                perOfAdSi: this.DCCAdSi,
                perOfClaimAmount: this.percentClaimAmt,
                applicableClaimPayout: this.DccApplicClaimPayOut,
                minHospitalization: this.dccminHosDuration,
                relationship:this.dccisSelectBox,
                applicableDayCareProcedures: this.dccappliDayCarePro,
                coverageApplicableDuration: this.dccCovrageApplicDur,
                irdaUanNo: this.DCCIrdaUINNo,
                externalRefNo: this.DCCExtRefNo,
                alternateName: this.DCCCoverAltName,
                gcCoverage: this.DCCGcCover,
                exclusions: this.DCCExclus,
                remarks: this.DCCRemarks,
                conditions: this.DCCCondField,
                // relationShipsCovered: [],
                // relationShipsCoveredDetails: [{}]
            }
            if(this.dccisSelectBox == true){
                formData.relationShipsCovered = event.dta.relationShipsCovered
                formData.relationShipsCoveredDetails = this.relDataProcess(event.dta)
            }
            // console.log("=========((  formData  ))======>>>>",formData)
            let formData2 = {
                // coverageCode: "PH",
                coverageType: this.plhcoverageType,
                coverageBasis: this.plhcoverageBasis,
                subLimitBasis:this.plhsubLimitBasis,
                claimPaymentBasis:this.plhclaimPaymentBasis,
                waitingPeriod: this.plhwaitingPeriod,
                waitingPeroidType: this.plhwaitingPeriodDrop,
                flatAmount: this.PHFlatAmountTxtField,
                perOfSi: this.plhperSI,
                perOfPreviousSi:this.plhPrvperSI,
                perOfOpdSi: this.PHOpdSi,
                perOfAdSi: this.PHAdSi,
                perOfClaimAmount: this.PHPercentClaimAmt,
                applicableClaimPayout: this.PHApplicClaimPayOut,
                coverageApplicableDuration: this.PHCovrageApplicDur,
                minHospitalization: this.plhminHosDuration,
                relationship:this.plhisSelectBox,
                applicableDayCareProcedures: [],
                irdaUanNo: this.PHIrdaUINNo,
                externalRefNo: this.PHExtRefNo,
                alternateName: this.PHCoverAltName,
                gcCoverage: this.PHGcCover,
                exclusions: this.PHExclus,
                remarks: this.PHRemarks,
                conditions: this.PHCondField,
                // relationShipsCovered: [],
                // relationShipsCoveredDetails: [{}]
            }
            if(this.plhisSelectBox == true){
                formData2.relationShipsCovered = event.dta.relationShipsCovered
                formData2.relationShipsCoveredDetails = this.relDataProcess(event.dta)
            }
            
            // console.log("=========((formData2))======>>>>",formData2)
            this.grpCtcVctn(formData,response=>{
                if(event.btntext == 'dayCareCover'){
                    this.formDataDC = []
                    if(response){
                        formData.coverageCode = "DC"
                        this.formDataDC.push(formData)
                    }
                }else{
                    if(this.dccisSelectBox){
                        this.formDataDC[0].relationShipsCoveredDetails = this.mapRelFrmDta(this.formDataDC[0].relationShipsCoveredDetails)
                    }
                }
                this.grpCtcVctn(formData2,res=>{
                    if(event.btntext == 'prolongHospital'){
                        this.formDataPH = []
                        if(res){
                            formData2.coverageCode = "PH"
                            this.formDataPH.push(formData2)
                        }
                    }else{
                        if(this.plhisSelectBox){
                            this.formDataPH[0].relationShipsCoveredDetails= this.mapRelFrmDta(this.formDataPH[0].relationShipsCoveredDetails)
                        }
                    }
                    var finalData = [
                        ...this.formDataDC, ...this.formDataPH
                    ]
                    let apiURL = ''
                    if(this.btnName == "ConfgrPlnFrPlcy"){
                        apiURL="group-plan/update-group-dcph/" + this.plan._id

                    }else if(this.btnName == "CnfgrNwRtlPln"){

                        apiURL="retail-plan/update-retail-dcph/" + this.plan._id
                    }

                    this.PUT(1,apiURL,finalData,(res,error)=>{
                        if(!error){
                            if(event.btntext == 'dayCareCover'){
                                this.showToast("Day care Coverage details has been updated successfully",this.TOST().SUCCESS)
                            }else if(event.btntext == 'prolongHospital'){
                                this.showToast("Prolonged Hospitalization Coverage details has been updated successfully",this.TOST().SUCCESS)
                            }
                        }else{
                            this.showToast(error.response.data.msg ,this.TOST().ERROR);
                        }    
                    })
                })
            })
        },
        relDataProcess(dta){
            // formData.relationShipsCovered = dta.relationShipsCovered
            let relData = dta.relationShipsCoveredDetails.map(e=> {
                return{
                    active: e.active,
                    applicableValue: e.applicableValue,
                    checkbox: e.checkbox,
                    coPay: e.coPay,
                    coverType: e.coverType,
                    flatAmount:e.flatAmount,
                    roomRentLimit: e.roomRentLimit,
                    siPercent: e.siPercent,
                    siSubLimit:  e.siSubLimit,
                    waitingPeriod: e.waitingPeriod,
                }
            }) 
            return relData
        },
        submitDCPH(event){
            if(event == 'dayCareCover'){
                this.dccisSelectBox == true ? this.$refs.DCRel.save_click(event) : this.saveDCPHRel({btntext:event,dta:null})
            }else if(event == 'prolongHospital'){
                this.plhisSelectBox == true ? this.$refs.PHRel.save_click(event) : this.saveDCPHRel({btntext:event,dta:null})
            }
        },
        submitVCIT(event){
            if(event == 'visionCare'){
                this.VCSelectBox == true ? this.$refs.VCRel.save_click(event) : this.saveVCITRel({btntext:event,dta:null})
            }else if(event == 'infertTreat'){
                this.ITSelectBox == true ? this.$refs.ITRel.save_click(event) : this.saveVCITRel({btntext:event,dta:null})
            }
        },
        saveVCITRel(event){
            if(event.btntext == 'visionCare'){
                if (!this.$refs.vcForm.validate()){
                    this.$refs.vcForm.validate()
                    this.showToast("Please Fill Required Fields",this.TOST().WARNING);
                    return
                } 
            }else if(event.btntext == 'infertTreat'){
                if (!this.$refs.ifForm.validate()){
                    this.$refs.ifForm.validate()
                    this.showToast("Please Fill Required Fields",this.TOST().WARNING);
                    return
                }
            }
            let formData = {
                coverageType: this.vcCoverageType,
                coverageBasis: this.vcCoverageBasis,
                subLimitBasis: this.vcSubLimitBasis,
                claimPaymentBasis: this.vcClaimPaymentBasis,
                coverageApplicableDuration: this.vcCovrageApplicDur,
                flatAmount: this.vcFlatAmountTxtField,
                perOfSi: this.vcPercentSI,
                perOfPreviousSi:this.vcPrvPercentSI,
                perOfOpdSi: this.VCOpdSi,
                perOfAdSi: this.VCAdSi,
                perOfClaimAmount: this.vcPercentClaimAmt,
                applicableClaimPayout: this.vcApplicClaimPayOut,
                waitingPeriod: this.vcWtngPrd,
                waitingPeroidType:this.VCwaitingPeriodDrop,
                typeOfMedicalExpense: [],
                irdaUanNo: this.VCIrdaUINNo,
                externalRefNo: this.VCExtRefNo,
                alternateName: this.VCCoverAltName,
                gcCoverage: this.VCGcCover,
                exclusions: this.VCExclus,
                remarks: this.VCRemarks,
                conditions: this.VCCondField,
                relationship: this.VCSelectBox,
                visionAndTreatmentExclusion:this.vcExclusions,

            }
            if(this.VCSelectBox == true){
                formData.relationShipsCovered = event.dta.relationShipsCovered
                formData.relationShipsCoveredDetails = this.relDataProcess(event.dta)
            }
            
            let formData2 = {
                coverageType: this.iftCoverageType,
                coverageBasis: this.iftCoverageBasis,
                subLimitBasis: this.iftSubLimitBasis,
                claimPaymentBasis: this.iftClaimPaymentBasis,
                coverageApplicableDuration: this.ItCovrageApplicDur,
                flatAmount: this.ItFlatAmountTxtField,
                perOfSi: this.ItPercentSI,
                perOfPreviousSi:this.ItPrvPercentSI,
                perOfOpdSi: this.ITOpdSi,
                perOfAdSi: this.ITAdSi,
                perOfClaimAmount: this.ItPercentClaimAmt,
                applicableClaimPayout: this.ItApplicClaimPayOut,
                waitingPeriod: this.iftWaitingPeriod,
                waitingPeroidType:this.ITwaitingPeriodDrop,
                typeOfMedicalExpense: this.iftMdclExpnse,
                irdaUanNo: this.ITIrdaUINNo,
                externalRefNo: this.ITExtRefNo,
                alternateName: this.ITCoverAltName,
                gcCoverage: this.ITGcCover,
                exclusions: this.ITExclus,
                remarks: this.ITRemarks,
                conditions: this.ITCondField,
                relationship: this.ITSelectBox,
                visionAndTreatmentExclusion:this.iftExclsns,
            }
            if(this.ITSelectBox == true){
                formData2.relationShipsCovered = event.dta.relationShipsCovered
                formData2.relationShipsCoveredDetails = this.relDataProcess(event.dta)
            }
            this.grpCtcVctn(formData,response=>{
                if(event.btntext == 'visionCare'){
                    this.formDataVC = []
                    if(response){
                        formData.coverageCode = "VC"
                        this.formDataVC.push(formData)
                    }
                }else{
                    if(this.VCSelectBox){
                        this.formDataVC[0].relationShipsCoveredDetails = this.mapRelFrmDta(this.formDataVC[0].relationShipsCoveredDetails)
                    }
                }

                this.grpCtcVctn(formData2,res=>{
                    if(event.btntext == 'infertTreat'){
                        this.formDataIT = []
                        if(res){
                            formData2.coverageCode = "IT"
                            this.formDataIT.push(formData2)
                        }
                    }else{
                        if(this.ITSelectBox){
                            this.formDataIT[0].relationShipsCoveredDetails = this.mapRelFrmDta(this.formDataIT[0].relationShipsCoveredDetails)
                        }
                    }
                    var finalData = [
                        ...this.formDataVC, ...this.formDataIT
                    ]
                    let apiURL = ''
                    if(this.btnName == "ConfgrPlnFrPlcy"){
                        apiURL="group-plan/update-group-vcit/" + this.plan._id

                    }else if(this.btnName == "CnfgrNwRtlPln"){

                        apiURL="retail-plan/update-retail-vcit/" + this.plan._id
                    }

                    this.PUT(1,apiURL,finalData,(res,error)=>{
                        if(!error){
                            if(event.btntext === 'visionCare'){
                                this.showToast("Vision Care Coverage details has been updated successfully",this.TOST().SUCCESS)
                            }else if(event.btntext === 'infertTreat'){
                                this.showToast("Infertility Treatment Coverage details has been updated successfully",this.TOST().SUCCESS)
                            }
                        }else{
                            this.showToast(error.response.data.msg ,this.TOST().ERROR);
                        }    
                    })
                })
            })
        },
        saveADBPTDRel(event){
            if(event.btntext == 'accDeathBenefit'){
                if (!this.$refs.adbForm.validate()){
                    this.$refs.adbForm.validate()
                    this.showToast("Please Fill Required Fields",this.TOST().WARNING);
                    return
                } 
            }else if(event.btntext == 'permTtlDisable'){
                if (!this.$refs.ptdForm.validate()){
                    this.$refs.ptdForm.validate()
                    this.showToast("Please Fill Required Fields",this.TOST().WARNING);
                    return
                }
            }
            let formData = {
                coverageType: this.adbCoverageType,
                externalRefNo:this.ADBExtRefNo,
                gcCoverage: this.ADBGcCover,
                irdaUanNo: this.ADBIrdaUINNo,
                exclusions: this.ADBExclus,
                remarks: this.ADBRemarks,
                waitingPeroidType: this.ADBwaitingPeriodDrop,
                waitingPeriod: this.ADBwaitingPeriod,
                alternateName: this.ADBCoverAltName,
                conditions: this.ADBCondField,
                coverageBasis: this.adbCoverageBasis,
                subLimitBasis: this.adbSubLimitBasis,
                claimPaymentBasis: this.adbClaimPaymentBasis,
                // coverageApplicableDuration: this.ADBCovrageApplicDur,
                flatAmount: this.AccDBFlatAmountTxtField,
                perOfSi: this.AccDBpercentSI,
                perOfPreviousSi: this.AccDBPrvpercentSI,
                perOfOpdSi: this.ADBOpdSi,
                perOfAdSi: this.ADBAdSi,
                perOfClaimAmount: this.AccDBPercentClaimAmt,
                applicableClaimPayout: this.AccDBApplicClaimPayOut,
                relationship: this.abdcheckbox,
                natureOfLoss: "",
                // maxNoOfDaysFromDateOfAccident: this.abdmxdysAccdnt,
                disappearanceApplicable: this.abdDsaprncAplcbl,
                // maxNoOfDaysFromDateOfDisappearance: this.abdmxdysDsaprnc,
                allowedDurationFromDateOfAccident: this.ADBAllowdDurFrmDOAcc,
                disabilityContinuityPeriod: '',
                featureClaim: false,
                benefitPayable: this.ADBBenifPayable,
                primaryCoverages: []
            }
            if(this.abdcheckbox == true){
                formData.relationShipsCovered = event.dta.relationShipsCovered
                formData.relationShipsCoveredDetails = this.relDataProcess(event.dta)
            }
            // console.log("ptdCheckbox ******************---->",this.ptdCheckbox)
            let formData2 = {
                coverageType: this.ptdCoverageType,
                externalRefNo:this.PTDExtRefNo,
                gcCoverage: this.PTDGcCover,
                irdaUanNo: this.PTDIrdaUINNo,
                exclusions: this.PTDExclus,
                remarks: this.PTDRemarks,
                waitingPeroidType: this.PTDwaitingPeriodDrop,
                waitingPeriod: this.PTDwaitingPeriod,
                alternateName: this.PTDCoverAltName,
                conditions: this.PTDCondField,
                coverageBasis: this.ptdCoverageBasis,
                subLimitBasis: this.ptdSubLimitBasis,
                claimPaymentBasis: this.ptdClaimPaymentBasis,
                coverageApplicableDuration: this.PTDCovrageApplicDur,
                flatAmount: this.PTDFlatAmountTxtField,
                perOfSi: this.PTDpercentSI,
                perOfPreviousSi: this.PTDPrvpercentSI,
                perOfOpdSi: this.PTDOpdSi,
                perOfAdSi: this.PTDAdSi,
                perOfClaimAmount: this.PTDPercentClaimAmt,
                applicableClaimPayout: this.PTDApplicClaimPayOut,
                relationship: this.ptdCheckbox,
                natureOfLoss: this.ptdNatureofLoss,
                // maxNoOfDaysFromDateOfAccident: this.abdmxdysAccdnt,
                disappearanceApplicable: false,
                // maxNoOfDaysFromDateOfDisappearance: this.abdmxdysDsaprnc,
                allowedDurationFromDateOfAccident: this.PTDDurFromAccDate,
                disabilityContinuityPeriod: this.PTDDisbleContPer,
                featureClaim: this.PTDfeatureClaim,
                benefitPayable: this.PTDBenpay,
                primaryCoverages: this.PTDPrimCover
            }

            if(this.ptdCheckbox == true){
                formData2.relationShipsCovered = event.dta.relationShipsCovered
                formData2.relationShipsCoveredDetails = this.relDataProcess(event.dta)
            }
            this.grpCtcVctn(formData,response=>{
                if(event.btntext == 'accDeathBenefit'){
                    this.formDataADB = []
                    if(response){
                        formData.coverageCode = "ADB"
                        this.formDataADB.push(formData)
                    }
                }else{
                    if(this.abdcheckbox){
                        this.formDataADB[0].relationShipsCoveredDetails = this.mapRelFrmDta(this.formDataADB[0].relationShipsCoveredDetails)
                    }
                }
                this.grpCtcVctn(formData2,res=>{
                    if(event.btntext == 'permTtlDisable'){
                        this.formDataPTD = []
                        if(res){
                            formData2.coverageCode = "PTD"
                            this.formDataPTD.push(formData2)
                        }
                    }else{
                        if(this.ptdCheckbox){
                            this.formDataPTD[0].relationShipsCoveredDetails= this.mapRelFrmDta(this.formDataPTD[0].relationShipsCoveredDetails)
                        }
                    }
                    var finalData = [
                        ...this.formDataADB, ...this.formDataPTD
                    ]
                    let apiURL = ''
                    if(this.btnName == "ConfgrPlnFrPlcy"){
                        apiURL = "group-plan/update-group-adbptd/" + this.plan._id
                        
                    }else if(this.btnName == "CnfgrNwRtlPln"){

                        apiURL = decodeURI("retail-plan/update-retail-adbptd/" + this.plan._id)
                    }
                    this.PUT(1,apiURL,finalData,(res,error)=>{
                        if(!error){
                            // console.log("RESPPPP ******************---->",res)
                            if(event.btntext == 'accDeathBenefit'){
                                this.showToast("Accidental Death Benefit Coverage details has been updated successfully",this.TOST().SUCCESS)
                            }else if(event.btntext == 'permTtlDisable'){
                                this.showToast("Permanent Total Disability Coverage details has been updated successfully",this.TOST().SUCCESS)
                            }
                        }else{
                            // console.log("ERROR__MSGGG ******************---->",error.response)
                            this.showToast(error.response.data.msg ,this.TOST().ERROR);
                        }    
                    })
                })
            })
        },
        submitADBPTD(event){
            if(event == 'accDeathBenefit'){
                this.abdcheckbox == true ? this.$refs.ADBRel.save_click(event) : this.saveADBPTDRel({btntext:event,dta:null})
                
            }else if(event == 'permTtlDisable'){
                this.ptdCheckbox == true ? this.$refs.PTDRel.save_click(event) : this.saveADBPTDRel({btntext:event,dta:null})
                
            }
        },
        saveEBNARel(event){
            if(event.btntext == 'eduBenefit'){
                if (!this.$refs.ebForm.validate()){
                    this.$refs.ebForm.validate()
                    this.showToast("Please Fill Required Fields",this.TOST().WARNING);
                    return
                } 
            }else if(event.btntext == 'nurseAllowance'){
                if (!this.$refs.naForm.validate()){
                    this.$refs.naForm.validate()
                    this.showToast("Please Fill Required Fields",this.TOST().WARNING);
                    return
                }
            }

            let formData = {
                coverageType: this.ebCoverageType,
                coverageBasis: this.ebCoverageBasis,
                subLimitBasis: this.ebSubLimitBasis,
                claimPaymentBasis: this.ebClaimPaymentBasis,
                coverageApplicableDuration: this.EBCovrageApplicDur,
                flatAmount: this.EBFlatAmountTxtField,
                perOfSi: this.EBPercentSI,
                perOfClaimAmount: this.EBPercentClaimAmt,
                applicableClaimPayout: this.EBApplicClaimPayOut,
                waitingPeriod: this.EBwaitingPeriod,
                waitingPeroidType:this.EBwaitingPeriodDrop,
                perOfPreviousSi:this.EBPrvPercentSI,
                perOfOpdSi: this.EBOpdSi,
                perOfAdSi: this.EBAdSi,
                waitingPeroidType: this.EBwaitingPeriodDrop,
                maxChildAge: this.ebMxChldAge,
                maxPayableDuration: "",
                deductible: "",
                irdaUanNo: this.EBIrdaUINNo,
                externalRefNo: this.EBExtRefNo,
                alternateName: this.EBCoverAltName,
                gcCoverage: this.EBGcCover,
                exclusions: this.EBExclus,
                remarks: this.EBRemarks,
                conditions: this.EBCondField,
                relationship: this.EBSelectBox,
                featureClaim: this.EBfeatureClaim,
                benefitPayable: this.EBBenpay,
                primaryCoverages: this.EBPrimCover
            }
            if(this.EBSelectBox == true){
                formData.relationShipsCovered = event.dta.relationShipsCovered
                formData.relationShipsCoveredDetails = this.relDataProcess(event.dta)
            }
            let formData2 = {
                coverageType: this.naCoverageType,
                coverageBasis: this.naCoverageBasis,
                subLimitBasis: this.naSubLimitBasis,
                claimPaymentBasis: this.naClaimPaymentBasis,
                coverageApplicableDuration: this.NACovrageApplicDur,
                flatAmount: this.NAFlatAmountTxtField,
                perOfSi: this.NAPercentSI,
                perOfClaimAmount: this.NAPercentClaimAmt,
                applicableClaimPayout: this.NAApplicClaimPayOut,
                waitingPeriod: this.naWtngPrd,
                perOfPreviousSi:this.NAPrvPercentSI,
                perOfOpdSi: this.NAOpdSi,
                perOfAdSi: this.NAAdSi,
                waitingPeroidType: this.NAwaitingPeriodDrop,
                maxChildAge: this.ebMxChldAge,
                maxPayableDuration: this.naMxPyblDrtn,
                deductible: this.naDeductible,
                irdaUanNo: this.NAIrdaUINNo,
                externalRefNo: this.NAExtRefNo,
                alternateName: this.NACoverAltName,
                gcCoverage: this.NAGcCover,
                exclusions: this.NAExclus,
                remarks: this.NARemarks,
                conditions: this.NACondField,
                relationship: this.NASelectBox,
                featureClaim: '',
                benefitPayable: '',
                primaryCoverages: []
            }
            if(this.NASelectBox == true){
                formData2.relationShipsCovered = event.dta.relationShipsCovered
                formData2.relationShipsCoveredDetails = this.relDataProcess(event.dta)
            }
            
            
            this.grpCtcVctn(formData,response=>{
                if(event.btntext == 'eduBenefit'){
                    this.formDataEB = []
                    if(response){
                        formData.coverageCode = "EB"
                        this.formDataEB.push(formData)
                    }
                }else{
                    if(this.EBSelectBox){
                        this.formDataEB[0].relationShipsCoveredDetails = this.mapRelFrmDta(this.formDataEB[0].relationShipsCoveredDetails)
                    }
                }

                this.grpCtcVctn(formData2,res=>{
                    if(event.btntext == 'nurseAllowance'){
                        this.formDataNA = []
                        if(res){
                            formData2.coverageCode = "NA"
                            this.formDataNA.push(formData2)
                        }
                    }else{
                        if(this.NASelectBox){
                            this.formDataNA[0].relationShipsCoveredDetails = this.mapRelFrmDta(this.formDataNA[0].relationShipsCoveredDetails)
                        }
                    }
                    var finalData = [ ...this.formDataEB, ...this.formDataNA ]
                    let apiURL = ''
                    if(this.btnName == "ConfgrPlnFrPlcy"){
                        apiURL = "group-plan/update-group-ebna/" + this.plan._id
                        
                    }else if(this.btnName == "CnfgrNwRtlPln"){

                        apiURL = "retail-plan/update-retail-ebna/" + this.plan._id
                    }
                    this.PUT(1,apiURL,finalData,(res,error)=>{
                        if(!error){
                            // console.log("RESPPPP ******************---->",res)
                            if(event.btntext === 'eduBenefit'){
                                this.showToast("Education Benefit Coverage details has been updated successfully",this.TOST().SUCCESS)
                            }else if(event.btntext === 'nurseAllowance'){
                                this.showToast("Nursing Allowance Coverage details has been updated successfully",this.TOST().SUCCESS)
                            }
                        }else{
                            // console.log("ERROR__MSGGG ******************---->",error.response)
                            this.showToast(error.response.data.msg ,this.TOST().ERROR);
                        }    
                    })
                })
            })
        },
        submitEBNA(event){
            if(event == 'eduBenefit'){
                this.EBSelectBox == true ? this.$refs.EBRel.save_click(event) : this.saveEBNARel({btntext:event,dta:null})
                
            }else if(event == 'nurseAllowance'){
                this.NASelectBox == true ? this.$refs.NARel.save_click(event) : this.saveEBNARel({btntext:event,dta:null})
                
            }
        },
        saveHCBHCBACIRel(event){
            if(event.btntext == 'hospCashBen'){
                if (!this.$refs.hcbForm.validate()){
                    this.$refs.hcbForm.validate()
                    this.showToast("Please Fill Required Fields",this.TOST().WARNING);
                    return
                } 
            }else if(event.btntext == 'hospCashBenInsuChild'){
                if (!this.$refs.hcbcForm.validate()){
                    this.$refs.hcbcForm.validate()
                    this.showToast("Please Fill Required Fields",this.TOST().WARNING);
                    return
                }
            }
            let formData = {
                coverageType: this.hcbcoverageType,
                coverageBasis: this.hcbcoverageBasis,
                subLimitBasis: this.hcbsubLimitBasis,
                claimPaymentBasis: this.hcbclaimPaymentBasis,
                coverageApplicableDuration: this.HCBCovrageApplicDur,
                flatAmount: this.HCBFlatAmountTxtField,
                perOfSi: this.HCBPercentSI,
                perOfPreviousSi:this.HCBPrvPercentSI,
                perOfClaimAmount: this.HCBPercentClaimAmt,
                waitingPeriod: this.hcbwaitingPeriod,
                waitingPeroidType: this.hcbwaitingPeriodDrop,
                minHospitalization: this.hcbminHosDuration,
                applicableClaimPayout: this.hcbapplicableClaimPayOut,
                accommodationDetails:[
                    {causeType: this.hcbcauseType1 ,roomType: this.hcbroomType1 , multiplierOfDailyCash: this.hcbdailyCash1},
                    {causeType: this.hcbcauseType2 ,roomType: this.hcbroomType2 , multiplierOfDailyCash: this.hcbdailyCash2},
                    {causeType: this.hcbcauseType3 ,roomType: this.hcbroomType3 , multiplierOfDailyCash: this.hcbdailyCash3},
                    {causeType: this.hcbcauseType4 ,roomType: this.hcbroomType4 , multiplierOfDailyCash: this.hcbdailyCash4}
                ],
                maximumAgeOfInsuredChild: "",
                irdaUanNo: this.HCBIrdaUINNo,
                externalRefNo: this.HCBExtRefNo,
                alternateName: this.HCBCoverAltName,
                gcCoverage: this.HCBGcCover,
                exclusions: this.HCBExclus,
                remarks: this.HCBRemarks,
                conditions: this.HCBCondField,
                relationship: this.HCBSelectBox,
                minHospitalizationAndDurationForEligibleClaim:this.hcbminHosDuration,
                // parseInt(this.hcbminHosDuration),
            }
            if(this.HCBSelectBox == true){
                formData.relationShipsCovered = event.dta.relationShipsCovered
                formData.relationShipsCoveredDetails = this.relDataProcess(event.dta)
            }
            let formData2 = {
                coverageType: this.hcbccoverageType,
                coverageBasis: this.hcbccoverageBasis,
                subLimitBasis: this.hcbcsubLimitBasis,
                claimPaymentBasis: this.hcbcclaimPaymentBasis,
                coverageApplicableDuration: this.HCBACICovrageApplicDur,
                flatAmount: this.HCBACIFlatAmountTxtField,
                perOfSi: this.HCBACIPercentSI,
                perOfPreviousSi:this.HCBACIPrvPercentSI,
                perOfClaimAmount: this.HCBACIPercentClaimAmt,
                waitingPeriod: this.hcbcwaitingPeriod,
                minHospitalization: this.hcbcminHosDuration,
                applicableClaimPayout: this.hcbcapplicableClaimPayOut,
                accommodationDetails:[
                    {causeType: this.hcbccauseType1 ,roomType: this.hcbcroomType1 , multiplierOfDailyCash: this.hcbcdailyCash1},
                    {causeType: this.hcbccauseType2 ,roomType: this.hcbcroomType2 , multiplierOfDailyCash: this.hcbcdailyCash2},
                    {causeType: this.hcbccauseType3 ,roomType: this.hcbcroomType3 , multiplierOfDailyCash: this.hcbcdailyCash3},
                    {causeType: this.hcbccauseType4 ,roomType: this.hcbcroomType4 , multiplierOfDailyCash: this.hcbcdailyCash4}
                ],
                maximumAgeOfInsuredChild: this.hcbcMaxAgeChild
            }
            
            this.grpCtcVctn(formData,response=>{
                if(event.btntext == 'hospCashBen'){
                    this.formDataHCB = []
                    if(response){
                        formData.coverageCode = "HCB"
                        this.formDataHCB.push(formData)
                    }
                }else{
                    if(this.HCBSelectBox){
                        this.formDataHCB[0].relationShipsCoveredDetails = this.mapRelFrmDta(this.formDataHCB[0].relationShipsCoveredDetails)
                    }
                }
                this.grpCtcVctn(formData2,res=>{
                    if(event.btntext == 'hospCashBenInsuChild'){
                        this.formDataHCBACI = []
                        if(res){
                            formData2.coverageCode = "HCBACI"
                            this.formDataHCBACI.push(formData2)
                        }
                    }
                    var finalData = [ ...this.formDataHCB, ...this.formDataHCBACI ]
                    let apiURL = ''
                    if(this.btnName == "ConfgrPlnFrPlcy"){
                        apiURL = "group-plan/update-group-hcbhcbaci/" + this.plan._id
                        
                    }else if(this.btnName == "CnfgrNwRtlPln"){

                        apiURL = "retail-plan/update-retail-hcbhcbaci/" + this.plan._id
                    }
                    this.PUT(1,apiURL,finalData,(res,error)=>{
                        if(!error){
                            // console.log("RESPPPP ******************---->",res)
                            if(event.btntext === 'hospCashBen'){
                                this.showToast("Hospital cash Benefit Coverage details has been updated successfully",this.TOST().SUCCESS)
                            }else if(event.btntext ==='hospCashBenInsuChild'){
                                this.showToast("Hospital cash Benefit for accompanying an Insured child Coverage details has been updated successfully",this.TOST().SUCCESS)
                            }
                            
                        }else{
                            // console.log("ERROR__MSGGG ******************---->",error.response)
                            this.showToast(error.response.data.msg ,this.TOST().ERROR);
                        }    
                    })
                })
            })
        },
        saveIPHRel(event){
            if (!this.$refs.inPtntHzptnForm.validate()){
                this.$refs.inPtntHzptnForm.validate()
                this.showToast("Please Fill Required Fields",this.TOST().WARNING);
                return
            } 

            let formData = {
                coverageCode: ['IP'],
                // coverageCode: this.IPCoverCheck,
                coverageType: this.inPtntHzptnCoverageType,
                coverageBasis: this.inPtntHzptnCoverageBasis,
                subLimitBasis: this.InPtntHzptnSubLimitBasis,
                claimPaymentBasis: this.InPtntHzptnClaimPaymentBasis,
                coverageApplicableDuration: this.IPHCovrageApplicDur,
                waitingPeriod: this.InPtntHzptnWaitingPeriod,
                waitingPeroidType: this.InPtntHzptnWaitingPeriodDrop,
                flatAmount: this.IPHFlatAmountTxtField,
                perOfSi: this.IPHPercentSI,
                perOfOpdSi: this.IPHOpdSi,
                perOfAdSi: this.IPHAdSi,
                perOfPreviousSi:this.IPHPrvPercentSI,
                perOfClaimAmount: this.IPHPercentClaimAmt,
                applicableClaimPayout: this.IPHapplicableClaimPayOut,
                minHospitalization: this.InPtntHzptnMinHospDura,
                coPayApplicable: this.inPtntHzptnCoPayapplicable,
                coPayType: this.inPtntHzptnCoPayType,
                coPayPercent: this.InPtntHzptnCoPayPrcnt,
                sharedRoomCopay: this.InPtntHzptnCoPayPrcntSr,
                noRestriction: this.InPtntHzptnCoPayPrcntNr,
                // minAgeOfNumber: this.IPHMinAgeMemb,
                // minTreatmentDuration: this.IPHMinTreatDura,
                relationship: this.InPtntHzptnSelectBox,
                irdaUanNo: this.IPHIrdaUINNo,
                externalRefNo: this.IPHExtRefNo,
                alternateName: this.IPHCoverAltName,
                gcCoverage: this.IPHGcCover,
                exclusions: this.IPHExclus,
                remarks: this.IPHRemarks,
                conditions: this.IPHCondField,
            }

            if(this.InPtntHzptnSelectBox == true){
                formData.relationShipsCovered = event.dta.relationShipsCovered
                formData.relationShipsCoveredDetails = this.relDataProcess(event.dta)
            }

            // var finalData = [ ...this.formDataHCB, ...this.formDataHCBACI ]
            let apiURL = ''
            if(this.btnName == "ConfgrPlnFrPlcy"){
                apiURL = "group-plan/update-group-iodabi/" + this.plan._id
                
            }else if(this.btnName == "CnfgrNwRtlPln"){

                apiURL = "retail-plan/update-retail-iodabi/" + this.plan._id
            }
            this.PUT(1,apiURL,formData,(res,error)=>{
                if(!error){
                    this.showToast("In-patient hospitalization Coverage details has been updated successfully",this.TOST().SUCCESS)
                    this.inPhDta=[]
                    this.inPhDta.push(formData)
                }else{
                    this.showToast(error.response.data.msg ,this.TOST().ERROR);
                }    
            })
        },
        saveIPDRel(event){
            if (!this.$refs.IPDForm.validate()){
                this.$refs.IPDForm.validate()
                this.showToast("Please Fill Required Fields",this.TOST().WARNING);
                return
            } 
            // this.IPDWaitingPeriodDrop = 'Days'
            let formData = {
                coverageCode: 'IPDT',
                coverageType: this.IPDCoverageType,
                coverageBasis: this.IPDCoverageBasis,
                subLimitBasis: this.IPDSubLimitBasis,
                claimPaymentBasis: this.IPDClaimPaymentBasis,
                coverageApplicableDuration: this.IPDCovrageApplicDur,
                waitingPeriod: this.IPDWaitingPeriod,
                waitingPeroidType: this.IPDWaitingPeriodDrop,
                flatAmount: this.IPDFlatAmountTxtField,
                alternateName: this.IPDCoverAltName,
                perOfOpdSi: this.IPDOpdSi,
                perOfAdSi: this.IPDAdSi,
                externalRefNo: this.IPDExtRefNo,
                gcCoverage: this.IPDGcCover,
                remarks: this.IPDRemarks,
                irdaUanNo: this.IPDIrdaUINNo,
                conditions: this.IPDCondField,
                exclusions: this.IPDExclus,
                perOfSi: this.IPDPercentSI,
                perOfPreviousSi:this.IPDPrvPercentSI,
                perOfClaimAmount: this.IPDPercentClaimAmt,
                applicableClaimPayout: this.IPDapplicableClaimPayOut,
                minHospitalization: this.IPDMinHospDura,
                coPayApplicable: this.IPDCoPayapplicable,
                coPayType: this.IPDCoPayType,
                coPayPercent: this.IPDCoPayPrcnt,
                sharedRoomCopay: this.IPDCoPayPrcntSR,
                noRestriction: this.IPDCoPayPrcntNR,
                // minAgeOfNumber: this.IPDMinAgeMemb,
                // minTreatmentDuration: this.IPDMinTreatDura,
                relationship: this.IPDSelectBox,
            }

            if(this.IPDSelectBox == true){
                formData.relationShipsCovered = event.dta.relationShipsCovered
                formData.relationShipsCoveredDetails = this.relDataProcess(event.dta)
            }

            // var finalData = [ ...this.formDataHCB, ...this.formDataHCBACI ]
            let apiURL = ''
            // /retail-plan/update-retail-ipdt/{id}
            if(this.btnName == "ConfgrPlnFrPlcy"){
                apiURL = "group-plan/update-group-ipdt/" + this.plan._id
                
            }else if(this.btnName == "CnfgrNwRtlPln"){

                apiURL = "retail-plan/update-retail-ipdt/" + this.plan._id
            }
            this.PUT(1,apiURL,formData,(res,error)=>{
                if(!error){
                    this.showToast("In-patient Dental Coverage details has been updated successfully",this.TOST().SUCCESS)
                    this.IPDData = []
                    this.IPDData.push(formData)
                }else{
                    this.showToast(error.response.data.msg ,this.TOST().ERROR);
                }    
            })
        },
        saveODRel(event){
            if (!this.$refs.ODForm.validate()){
                this.$refs.ODForm.validate()
                this.showToast("Please Fill Required Fields",this.TOST().WARNING);
                return
            } 
            let formData = {
                coverageCode: 'OD',
                coverageType: this.ODCoverageType,
                coverageBasis: this.ODCoverageBasis,
                subLimitBasis: this.ODSubLimitBasis,
                claimPaymentBasis: this.ODClaimPaymentBasis,
                coverageApplicableDuration: this.ODCovrageApplicDur,
                waitingPeriod: this.ODWaitingPeriod,
                waitingPeroidType: this.ODWaitingPeriodDrop,
                flatAmount: this.ODFlatAmountTxtField,
                alternateName: this.ODCoverAltName,
                perOfOpdSi: this.ODOpdSi,
                perOfAdSi: this.ODAdSi,
                externalRefNo: this.ODExtRefNo,
                gcCoverage: this.ODGcCover,
                remarks: this.ODRemarks,
                irdaUanNo: this.ODIrdaUINNo,
                conditions: this.ODCondField,
                exclusions: this.ODExclus,
                perOfSi: this.ODPercentSI,
                perOfPreviousSi:this.ODPrvPercentSI,
                perOfClaimAmount: this.ODPercentClaimAmt,
                applicableClaimPayout: this.ODapplicableClaimPayOut,
                minHospitalization: this.ODMinHospDura,
                coPayApplicable: this.ODCoPayapplicable,
                coPayType: this.ODCoPayType,
                coPayPercent: this.ODCoPayPrcnt,
                sharedRoomCopay: this.ODCoPayPrcntSR,
                noRestriction: this.ODCoPayPrcntNR,
                // minAgeOfNumber: this.ODMinAgeMemb,
                // minTreatmentDuration: this.ODMinTreatDura,
                relationship: this.ODSelectBox,
            }

            if(this.ODSelectBox == true){
                formData.relationShipsCovered = event.dta.relationShipsCovered
                formData.relationShipsCoveredDetails = this.relDataProcess(event.dta)
            }

            // var finalData = [ ...this.formDataHCB, ...this.formDataHCBACI ]
            let apiURL = ''
            // /retail-plan/update-retail-organ-donor/{id}
            if(this.btnName == "ConfgrPlnFrPlcy"){
                apiURL = "group-plan/update-group-organ-donor/" + this.plan._id
                
            }else if(this.btnName == "CnfgrNwRtlPln"){

                apiURL = "retail-plan/update-retail-organ-donor/" + this.plan._id
            }
            this.PUT(1,apiURL,formData,(res,error)=>{
                if(!error){
                    this.showToast("Organ Donor Coverage details has been updated successfully",this.TOST().SUCCESS)
                    this.ODData=[]
                    this.ODData.push(formData)
                }else{
                    this.showToast(error.response.data.msg ,this.TOST().ERROR);
                }    
            })
        },
        saveBSCRel(event){
            if (!this.$refs.BSCForm.validate()){
                this.$refs.BSCForm.validate()
                this.showToast("Please Fill Required Fields",this.TOST().WARNING);
                return
            } 
            let formData = {
                coverageCode: 'BSC',
                coverageType: this.BSCCoverageType,
                coverageBasis: this.BSCCoverageBasis,
                subLimitBasis: this.BSCSubLimitBasis,
                claimPaymentBasis: this.BSCClaimPaymentBasis,
                coverageApplicableDuration: this.BSCCovrageApplicDur,
                waitingPeriod: this.BSCWaitingPeriod,
                waitingPeroidType: this.BSCWaitingPeriodDrop,
                flatAmount: this.BSCFlatAmountTxtField,
                alternateName: this.BSCCoverAltName,
                perOfOpdSi: this.BSCOpdSi,
                perOfAdSi: this.BSCAdSi,
                externalRefNo: this.BSCExtRefNo,
                gcCoverage: this.BSCGcCover,
                remarks: this.BSCRemarks,
                irdaUanNo: this.BSCIrdaUINNo,
                conditions: this.BSCCondField,
                exclusions: this.BSCExclus,
                perOfSi: this.BSCPercentSI,
                perOfPreviousSi:this.BSCPrvPercentSI,
                perOfClaimAmount: this.BSCPercentClaimAmt,
                applicableClaimPayout: this.BSCapplicableClaimPayOut,
                minHospitalization: this.BSCMinHospDura,
                coPayApplicable: this.BSCCoPayapplicable,
                coPayType: this.BSCCoPayType,
                coPayPercent: this.BSCCoPayPrcnt,
                sharedRoomCopay: this.BSCCoPayPrcntSR,
                noRestriction: this.BSCCoPayPrcntNR,
                minAgeOfNumber: this.BSCMinAgeMemb,
                // minTreatmentDuration: this.BSCMinTreatDura,
                relationship: this.BSCSelectBox,
            }

            if(this.BSCSelectBox == true){
                formData.relationShipsCovered = event.dta.relationShipsCovered
                formData.relationShipsCoveredDetails = this.relDataProcess(event.dta)
            }

            // var finalData = [ ...this.formDataHCB, ...this.formDataHCBACI ]
            let apiURL = ''
            if(this.btnName == "ConfgrPlnFrPlcy"){
                apiURL = "group-plan/update-group-bariatric-surgery-cover/" + this.plan._id
                
            }else if(this.btnName == "CnfgrNwRtlPln"){

                apiURL = "retail-plan/update-retail-bariatric-surgery-cover/" + this.plan._id
            }
            this.PUT(1,apiURL,formData,(res,error)=>{
                if(!error){
                    this.showToast("Bariatric Surgery Cover Coverage details has been updated successfully",this.TOST().SUCCESS)
                    this.BSCData=[]
                    this.BSCData.push(formData)
                }else{
                    this.showToast(error.response.data.msg ,this.TOST().ERROR);
                }    
            })
        },
        saveDTRel(event){
            if (!this.$refs.DTForm.validate()){
                this.$refs.DTForm.validate()
                this.showToast("Please Fill Required Fields",this.TOST().WARNING);
                return
            } 
            let formData = {
                coverageCode: 'DT',
                coverageType: this.DTCoverageType,
                coverageBasis: this.DTCoverageBasis,
                subLimitBasis: this.DTSubLimitBasis,
                claimPaymentBasis: this.DTClaimPaymentBasis,
                coverageApplicableDuration: this.DTCovrageApplicDur,
                waitingPeriod: this.DTWaitingPeriod,
                waitingPeroidType: this.DTWaitingPeriodDrop,
                flatAmount: this.DTFlatAmountTxtField,
                alternateName: this.DTCoverAltName,
                perOfOpdSi: this.DTOpdSi,
                perOfAdSi: this.DTAdSi,
                externalRefNo: this.DTExtRefNo,
                gcCoverage: this.DTGcCover,
                remarks: this.DTRemarks,
                irdaUanNo: this.DTIrdaUINNo,
                conditions: this.DTCondField,
                exclusions: this.DTExclus,
                perOfSi: this.DTPercentSI,
                perOfPreviousSi:this.DTPrvPercentSI,
                perOfClaimAmount: this.DTPercentClaimAmt,
                applicableClaimPayout: this.DTapplicableClaimPayOut,
                minHospitalization: this.DTMinHospDura,
                coPayApplicable: this.DTCoPayapplicable,
                coPayType: this.DTCoPayType,
                coPayPercent: this.DTCoPayPrcnt,
                sharedRoomCopay: this.DTCoPayPrcntSR,
                noRestriction: this.DTCoPayPrcntNR,
                // minAgeOfNumber: this.DTMinAgeMemb,
                minTreatmentDuration: this.DTMinTreatDura,
                relationship: this.DTSelectBox,
            }

            if(this.DTSelectBox == true){
                formData.relationShipsCovered = event.dta.relationShipsCovered
                formData.relationShipsCoveredDetails = this.relDataProcess(event.dta)
            }

            // var finalData = [ ...this.formDataHCB, ...this.formDataHCBACI ]
            let apiURL = ''
            if(this.btnName == "ConfgrPlnFrPlcy"){
                apiURL = "group-plan/update-group-domiciliary-treatment/" + this.plan._id
                
            }else if(this.btnName == "CnfgrNwRtlPln"){

                apiURL = "retail-plan/update-retail-domiciliary-treatment/" + this.plan._id
            }
            this.PUT(1,apiURL,formData,(res,error)=>{
                if(!error){
                    this.showToast("Domiciliary Treatment Coverage details has been updated successfully",this.TOST().SUCCESS)
                    this.DTData=[]
                    this.DTData.push(formData)
                }else{
                    this.showToast(error.response.data.msg ,this.TOST().ERROR);
                }    
            })
        },
        saveABRel(event){
            if (!this.$refs.ABForm.validate()){
                this.$refs.ABForm.validate()
                this.showToast("Please Fill Required Fields",this.TOST().WARNING);
                return
            } 
            let formData = {
                coverageCode: 'AB',
                coverageType: this.ABCoverageType,
                coverageBasis: this.ABCoverageBasis,
                subLimitBasis: this.ABSubLimitBasis,
                claimPaymentBasis: this.ABClaimPaymentBasis,
                coverageApplicableDuration: this.ABCovrageApplicDur,
                waitingPeriod: this.ABWaitingPeriod,
                waitingPeroidType: this.ABWaitingPeriodDrop,
                flatAmount: this.ABFlatAmountTxtField,
                alternateName: this.ABCoverAltName,
                perOfOpdSi: this.ABOpdSi,
                perOfAdSi: this.ABAdSi,
                externalRefNo: this.ABExtRefNo,
                gcCoverage: this.ABGcCover,
                remarks: this.ABRemarks,
                irdaUanNo: this.ABIrdaUINNo,
                conditions: this.ABCondField,
                exclusions: this.ABExclus,
                perOfSi: this.ABPercentSI,
                perOfPreviousSi:this.ABPrvPercentSI,
                perOfClaimAmount: this.ABPercentClaimAmt,
                applicableClaimPayout: this.ABapplicableClaimPayOut,
                minHospitalization: this.ABMinHospDura,
                coPayApplicable: this.ABCoPayapplicable,
                coPayType: this.ABCoPayType,
                coPayPercent: this.ABCoPayPrcnt,
                sharedRoomCopay: this.ABCoPayPrcntSR,
                noRestriction: this.ABCoPayPrcntNR,
                // minAgeOfNumber: this.ABMinAgeMemb,
                // minTreatmentDuration: this.ABMinTreatDura,
                relationship: this.ABSelectBox,
            }

            if(this.ABSelectBox == true){
                formData.relationShipsCovered = event.dta.relationShipsCovered
                formData.relationShipsCoveredDetails = this.relDataProcess(event.dta)
            }

            // var finalData = [ ...this.formDataHCB, ...this.formDataHCBACI ]
            let apiURL = ''
            if(this.btnName == "ConfgrPlnFrPlcy"){
                apiURL = "group-plan/update-group-ayush-benefit/" + this.plan._id
                
            }else if(this.btnName == "CnfgrNwRtlPln"){

                apiURL = "retail-plan/update-retail-ayush-benefit/" + this.plan._id
            }
            this.PUT(1,apiURL,formData,(res,error)=>{
                if(!error){
                    this.showToast("Ayush Benefit Coverage details has been updated successfully",this.TOST().SUCCESS)
                    this.ABData=[]
                    this.ABData.push(formData)
                }else{
                    this.showToast(error.response.data.msg ,this.TOST().ERROR);
                }    
            })
        },
        
        submitMC(event){
            if (!this.$refs.matForm.validate()){
                this.$refs.matForm.validate()
                this.showToast("Please Fill Required Fields",this.TOST().WARNING);
                return
            } 
            let formData = {
                coverageCode: "MC",
                coverageType: this.matcoverageType,
                coverageBasis: this.matcoverageBasis,
                subLimitBasis: this.matsubLimitBasis,
                claimPaymentBasis: this.matclaimPaymentBasis,
                coverageApplicableDuration: this.MCCovrageApplicDur,
                flatAmount: this.MCFlatAmountTxtField,
                perOfSi: this.MCPercentSI,
                perOfPreviousSi:this.MCPrvPercentSI,
                applicableClaimPayout:this.MCApplicClaimPayOut,
                perOfClaimAmount: this.MCPercentClaimAmt,
                waitingPeriod: this.matwaitingPeriod,
                waitingPeroidType: this.MCwaitingPeriodDrop,
                limitOnNumberOfDeliveries: this.matlimitdel,
                minHospitalization: this.matminHosDuration,
                flatAmountVaryBasedOnGender: this.matvaryongender,
                extraAmountForGirlChild: this.matyesgirlchild,
                flatAmountVaryBasedOnCityOfDeliverOrDelivery: this.matvaryoncity,
                perOfOpdSi: this.MCOpdSi,
                perOfAdSi: this.MCAdSi,
                // cityOfDelivery: this.matcityofdel1,
                // deliveryType: this.matdeltype1,
                // deliveryFlatAmount: this.matflatamount1,
                preAndPostNatalApplicable: this.matnatal,
                durationForCoveringPreNatalExpenses: this.matpreflatamount,
                durationForCoveringPostNatalExpenses: this.matpostflatamount,
                overAndAboveMaternityLimit: this.matlimit,
                limitsApplicableForPreAndPostNatal: this.matlimit,
                flatAmountVaryBasedOnCityOfDeliverOrDeliveryDetails:[
                    {cityOfDelivery: this.matcityofdel1 ,deliveryType: this.matdeltype2 , deliveryFlatAmount: this.matflatamount1},
                    {cityOfDelivery: this.matcityofdel2 ,deliveryType: this.matdeltype2 , deliveryFlatAmount: this.matflatamount2},
                    {cityOfDelivery: this.matcityofdel3 ,deliveryType: this.matdeltype3 , deliveryFlatAmount: this.matflatamount3},
                    {cityOfDelivery: this.matcityofdel4 ,deliveryType: this.matdeltype4 , deliveryFlatAmount: this.matflatamount4}
                ],
                
                limitDetails: {
                    preNatal: {
                        coverageBasis: this.matprecoverbasis,
                        subLimitBasis: this.matpresublimit,
                        claimPaymentBasis: this.matpreclaimpay,
                        flatAmount: this.MCPreFlatAmountTxtField,
                        perOfSi: this.MCPrePercentSI,
                        perOfClaimAmount: this.MCPrePercentClaimAmt,
                        perOfPreviousSi:this.MCPrePercentPrevSI,
                        applicableClaimPayout:this.MCPreApplicClaimPayOut,
                        coverageApplicableDuration: this.MCPreCovrageApplicDur

                    },
                    postNatal: {
                        coverageBasis: this.matpostcoverbasis,
                        subLimitBasis: this.matpostsublimit,
                        claimPaymentBasis: this.matpostclaimpay,
                        flatAmount: this.MCPostFlatAmountTxtField,
                        perOfSi: this.MCPostPercentSI,
                        perOfClaimAmount: this.MCPostPercentClaimAmt,
                        perOfPreviousSi:this.MCPostPercentPrevSI,
                        applicableClaimPayout:this.MCPostApplicClaimPayOut,
                        coverageApplicableDuration: this.MCPostCovrageApplicDur,
                    }
                },
                irdaUanNo: this.MCIrdaUINNo,
                externalRefNo: this.MCExtRefNo,
                alternateName: this.MCCoverAltName,
                gcCoverage: this.MCGcCover,
                exclusions: this.MCExclus,
                remarks: this.MCRemarks,
                conditions: this.MCCondField,
                
            }
            // console.log("(((((((((((((formData)))))))))))))---->",formData)

            let apiURL = ''
            if(this.btnName == "ConfgrPlnFrPlcy"){
                apiURL = "group-plan/update-group-maternity-coverage/" + this.plan._id
                
            }else if(this.btnName == "CnfgrNwRtlPln"){

                apiURL = "retail-plan/update-retail-maternity-coverage/" + this.plan._id
            }
            this.PUT(1,apiURL,formData,(res,error)=>{
                if(!error){
                    this.showToast("Maternity Coverage has been updated successfully",this.TOST().SUCCESS)
                    this.mcDta=[]
                    this.mcDta.push(formData)
                }else{
                    this.showToast(error.response.data.msg ,this.TOST().ERROR);
                }    
            })
        },
        
        submitHCBHCBACI(event){
            // this.HCBSelectBox == true ? this.$refs.HCBRel.save_click(event) : this.saveHCBHCBACIRel({btntext:event,dta:null})
            if(event == 'hospCashBen'){
                this.HCBSelectBox == true ? this.$refs.HCBRel.save_click(event) : this.saveHCBHCBACIRel({btntext:event,dta:null})
                
            }else if(event == 'hospCashBenInsuChild'){
                this.HCBSelectBox == true ? this.$refs.HCBRel.save_click(event) : this.saveHCBHCBACIRel({btntext:event,dta:null})
                
            }
        },
        submitIPH(){
            this.InPtntHzptnSelectBox == true ? this.$refs.IPHRel.save_click(event) : this.saveIPHRel({btntext:event,dta:null})
        },
        submitIPD(){
            this.IPDSelectBox == true ? this.$refs.IPDRel.save_click(event) : this.saveIPDRel({btntext:event,dta:null})
        },
        submitOD(){
            this.ODSelectBox == true ? this.$refs.ODRel.save_click(event) : this.saveODRel({btntext:event,dta:null})
        },
        submitBSC(){
            this.BSCSelectBox == true ? this.$refs.BSCRel.save_click(event) : this.saveBSCRel({btntext:event,dta:null})
        },
        submitDT(){
            this.DTSelectBox == true ? this.$refs.DTRel.save_click(event) : this.saveDTRel({btntext:event,dta:null})
        },
        submitAB(){
            this.ABSelectBox == true ? this.$refs.ABRel.save_click(event) : this.saveABRel({btntext:event,dta:null})
        },
        clearIPH(){
            this.IPHOpdSi = ''
            this.IPHAdSi = ''
            this.IPHCoverAltName = ''
            this.IPHMinAgeMemb = ''
            this.IPHMinTreatDura = ''
            this.IPHIrdaUINNo = ''
            this.IPHExtRefNo = ''
            this.IPHCondField = []
            this.IPHGcCover = ''
            this.IPHRemarks = ''
            this.IPHExclus = []
            this.InPtntHzptnWaitingPeriodDrop = 'Days'

            this.IPCoverCheck = ''
            this.inPtntHzptnCoverageType = ''
            this.inPtntHzptnCoverageBasis = ''
            this.InPtntHzptnSubLimitBasis = []
            this.InPtntHzptnClaimPaymentBasis = ''
            this.IPHCovrageApplicDur = ''
            this.InPtntHzptnWaitingPeriod = ''
            this.IPHFlatAmountTxtField = ''
            this.IPHPercentSI = ''
            this.IPHPrvPercentSI = ''
            this.IPHPercentClaimAmt = ''
            this.IPHapplicableClaimPayOut = ''
            this.InPtntHzptnMinHospDura = ''
            this.inPtntHzptnCoPayapplicable = 'No'
            this.inPtntHzptnCoPayType = ''
            this.InPtntHzptnCoPayPrcnt = ''
            this.InPtntHzptnCoPayPrcntSr = ''
            this.InPtntHzptnCoPayPrcntNr = ''
            this.InPtntHzptnMnAgNo = ''
            this.InPtntHzptnMnTrtmntDrn = ''
            this.InPtntHzptnSelectBox = false
            this.shwInPtntHzptnCopayTyp = false
            this.IPHRelCheckSelected()

            this.showIPHFlatAmnt = false
            this.showIPHPercentSi = false
            this.showIPHPercClaimAmt = false
            this.IPHshowAppli = false
            this.showIPHCovrageApplicDur = false
            this.showIPHPrvPercentSi = false
            this.showIPHOpdSi = false
            this.showIPHAdSi = false
            this.$refs.inPtntHzptnForm.resetValidation()
        },
        clearMC(){
            this.matcoverageType = ''
            this.matcoverageBasis = ''
            this.matsubLimitBasis = [],
            this.showMCPrePercentSI = false
            this.showMCPreFlatAmnt = false
            this.showMCPrePercClaimAmt = false
            this.showMCPreApplicClaimPay = false
            this.MCPrePercentSI = ''
            this.MCPreFlatAmountTxtField = ''
            this.MCPrePercentClaimAmt = ''
            this.MCPreApplicClaimPayOut = ''
            this.matclaimPaymentBasis = ''
            this.showMCPostCovrageApplicDur = false
            this.MCPostCovrageApplicDur = ''
            this.showMCPreCovrageApplicDur = false
            this.MCPreCovrageApplicDur = ''
            this.showMCCovrageApplicDur = false
            this.showMCPercentSI = false
            this.showMCPrvPercentSI = false
            this.showMCFlatAmnt = false
            this.showMCPercClaimAmt = false
            this.showMCApplicClaimPay = false
            this.showMCOpdSi = false
            this.showMCAdSi = false
            this.MCCovrageApplicDur = ''
            this.MCPercentSI = ''
            this.MCPrvPercentSI = ''
            this.MCFlatAmountTxtField = ''
            this.MCPercentClaimAmt = ''
            this.MCApplicClaimPayOut = ''
            this.MCCoverAltName = ''
            this.MCIrdaUINNo = ''
            this.MCExtRefNo = ''
            this.MCCondField = []
            this.MCGcCover = ''
            this.MCRemarks = ''
            this.MCExclus = []
            this.matwaitingPeriod = ''
            this.matlimitdel = ''
            this.matminHosDuration = ''
            this.matvaryongender = 'No'
            this.showdata = false
            this.matvaryongender = 'No'
            this.matyesgirlchild = ''
            this.matvaryoncity = 'No'
            this.showdatacity = false
            this.matnatal = 'No'
            this.showdatanatal = false
            this.matflatamount1 = ''
            this.matflatamount2 = ''
            this.matflatamount3 = ''
            this.matflatamount4 = ''
            this.matpreflatamount = ''
            this.matpostflatamount = ''
            this.matlimit = 'No'
            this.matflatshow = false
            this.MCwaitingPeriodDrop = 'Days'
            this.showdatalimit = false
            this.matprecoverbasis = 'Upto SI'
            this.matpresublimit = []
            this.matpreclaimpay = ''
            this.matpostcoverbasis = 'Upto SI'
            this.MCPreshowSubLimClaimPay = false
            this.MCPostshowSubLimClaimPay = false
            this.matpostsublimit = []
            this.matpostclaimpay = ''
            this.showMCPostPercentSI = false
            this.showMCPostFlatAmnt = false
            this.showMCPostPercClaimAmt = false
            this.showMCPostApplicClaimPay = false
            this.MCPostPercentSI = ''
            this.MCPostFlatAmountTxtField = ''
            this.MCPostPercentClaimAmt = ''
            this.MCPostApplicClaimPayOut = ''
            this.showMCPrePrevPercSI = false
            this.showMCPostPrevPercSI = false
            this.MCPrePercentPrevSI = ''
            this.MCPostPercentPrevSI = ''
            this.$refs.matForm.resetValidation()
        },
        clearVaccine(){
            this.vcntnCoverageType = ''
            this.vcntnCoverageBasis = ''
            this.vcntnSubLimitBasis = []
            this.vcntnClaimPaymentBasis = ''
            this.vcntnFlatAmt = ''
            this.vcntnPrcntSi = ''
            this.vcntnPrcntPrvSi = ''
            this.vcntnPrcntClmAmt = ''
            this.VcntnCad = ''
            this.vcntnWaitingPeriod = ''
            this.vaccwaitingPeriodDrop = 'Days'
            this.vcntnElglblDrtn = ''
            this.vcntnAplcblClmPyot = ''
            this.vaccCoverAltName = ''
            this.vaccIrdaUINNo = ''
            this.vaccExtRefNo = ''
            this.vaccCondField = []
            this.vaccGcCover = ''
            this.vaccRemarks = ''
            this.vaccExclus = []
            this.shwVcntnFltAmt = false
            this.shwVcntnPrcntSi = false
            this.shwVcntnPrcntPrvSi = false
            this.shwVcntnPrcntClmAmt = false
            this.showvaccOpdSi = false
            this.showvaccAdSi = false
            this.shwVcntnCvrgAplcblDrtn = false
            this.vaccSelectBox = false
            this.vaccRelCheckSelected()
            this.showvaccRelSection = false
            this.showvaccRelData = false
            this.$refs.vcntnForm.resetValidation()

        },
        clearHED(){
            this.hedcoverageType = ""
            this.hedcoverageBasis = ''
            this.hedsubLimitBasis = ''
            this.hedclaimPaymentBasis = ''
            this.hedwaitingPeriod = ''
            this.hedwaitingPeriodDrop = 'Days'
            this.hedapplicableClaimPayOut = ''
            this.hedCad = ''
            this.HEDCoverAltName = ''
            this.HEDIrdaUINNo = ''
            this.HEDExtRefNo = ''
            this.HEDCondField = []
            this.HEDGcCover = ''
            this.HEDRemarks = ''
            this.HEDExclus = []
            this.hedallowedDiaTests = []
            this.hedSeparateRshipDepInfo = ''
            this.hedisSelectBox = false
            this.HedRdiSelected()
            this.hedFlatAmt = ''
            this.hedClmAmt = ''
            this.hedPerSI = ''
            this.hedPrvPerSI = ''
            this.shwhedFltAmt = false
            this.shwhedPerSI = false
            this.shwhedPrvPerSI = false
            this.shwhedClmAmt = false
            this.showHEDOpdSi = false
            this.showHEDAdSi = false
            this.hedflatshow = false
            this.hedsubBasisshow = false
            this.shwhedCvrgAplcblDrtn = false
            this.showhedacpo = false
            this.$refs.hedForm.resetValidation()

        },
        clearHCUC(){
            this.hckCoverageType = ''
            this.hckCoverageBasis = ''
            this.hckSubLimitBasis = []
            this.hckClaimPaymentBasis = ''
            this.hckFlatAmt = ''
            this.hckPrcntSi = ''
            this.hckPrcntPrvSi = ''
            this.hckPrcntClmAmt = ''
            this.hckAplcblClmPyot = ''
            this.hckWaitingPeriod = ''
            this.HCUCwaitingPeriodDrop = 'Days'
            this.hckCad = ''
            this.HCUCCoverAltName = ''
            this.HCUCNoClaimFreeYrs = ''
            this.HCUCIrdaUINNo = ''
            this.HCUCExtRefNo = ''
            this.HCUCCondField = []
            this.HCUCGcCover = ''
            this.HCUCRemarks = ''
            this.HCUCExclus = []
            this.shwhckPrcntSi = false
            this.shwhckPrcntPrvSi = false
            this.shwhckPrcntClmAmt = false
            this.shwhckAplcblClmPyot = false
            this.shwhckCvrgAplcblDrtn = false
            this.HCUCSelectBox = false
            this.HCUCRelCheckSelected()
            this.$refs.hckForm.resetValidation()

        },
        clearGC(){
            this.gcCoverageType = ''
            this.gcCoverageBasis = ''
            this.gcSubLimitBasis = []
            this.gcClaimPaymentBasis = ''
            this.gcFlatAmt = ''
            this.gcPrcntSi = ''
            this.gcPrcntPrvSi = ''
            this.gcPrcntClmAmt = ''
            this.gcAplcblClmPyot = ''
            this.gcCad = ''
            this.gcCntryofDseDtctn = ''
            this.gcWaitingPeriod = ''
            this.gcwaitingPeriodDrop = 'Days'
            this.gcMnmHspitlztnDrtn = ''
            this.gccheckbox =  false
            this.gcRdiSelected()
            this.GCCoverAltName = ''
            this.GCIrdaUINNo = ''
            this.GCExtRefNo = ''
            this.GCCondField = []
            this.GCGcCover = ''
            this.GCRemarks = ''
            this.GCExclus = []
            this.shwGcFltAmt = false
            this.shwGcPrcntSi = false
            this.shwGcPrcntPrvSi = false
            this.shwGcPrcntClmAmt = false
            this.shwGcAplcblClmPyot = false
            this.shwgcCvrgAplcblDrtn = false
            this.shwGcRdi = false
            this.$refs.gcForm.resetValidation()

        },
        clearCTC(){
            this.ctCoverageType = ''
            this.ctCoverageBasis = ''
            this.ctSubLimitBasis = []
            this.ctClaimPaymentBasis = ''
            this.ctFlatAmt = ''
            this.ctWaitingPeriod = ''
            this.CTCwaitingPeriodDrop = 'Days'
            this.ctMnmHspitlztnDrtn = ''
            this.CtcVacGrp = []
            this.CtCad = ''
            this.ctPrcntSi = ''
            this.ctPrcntPrvSi = ''
            this.ctPrcntClmAmt = ''
            this.ctAplcblClmPyot = ''
            this.CTCCoverAltName = ''
            this.CTCIrdaUINNo = ''
            this.CTCExtRefNo = ''
            this.CTCCondField = []
            this.CTCGcCover = ''
            this.CTCRemarks = ''
            this.CTCExclus = []
            this.CTCSelectBox = false
            this.CTCRelCheckSelected()
            this.CTCrelCoverData = {}
            this.shwCtPrcntSi = false
            this.shwCtPrcntPrvSi = false
            this.shwCtPrcntClmAmt = false
            this.showCTCOpdSi = false
            this.showCTCAdSi = false
            this.shwCtCvrgAplcblDrtn = false
            this.shwCtctAplcblClmPyot = false
            this.shwCtFltAmt = false
            this.$refs.ctForm.resetValidation()
        },
        clearPH(){
            this.showPHFlatAmnt = false
            this.showPHPercClaimAmt = false
            this.showPHOpdSi = false
            this.showPHAdSi = false
            this.showPHApplicClaimPay = false
            this.PHFlatAmountTxtField = ''
            this.PHPercentClaimAmt = ''
            this.PHApplicClaimPayOut = ''
            this.showCovrageApplicDur = false
            this.PHCovrageApplicDur = ''
            this.plhsubBasisshow = false
            this.PHCoverAltName = ''
            this.PHIrdaUINNo = ''
            this.PHExtRefNo = ''
            this.PHCondField = []
            this.PHGcCover = ''
            this.PHRemarks = ''
            this.PHExclus = []
            this.plhcoverageType = ""
            this.plhcoverageBasis = ''
            this.plhsubLimitBasis = []
            this.plhclaimPaymentBasis = ''
            this.plhwaitingPeriod = ''
            this.plhwaitingPeriodDrop = 'Days'
            this.plhminHosDuration = ''
            this.plhSeparateRshipDepInfo = ''
            this.plhisSelectBox = false
            this.PHRelCheckSelected()
            this.plhperSI = ''
            this.plhPrvperSI = ''
            this.plhSIshow = false
            this.plhPrvSIshow = false
            this.$refs.plhForm.resetValidation()

        },
        clearADB(){
            this.adbCoverageType = ''
            this.adbCoverageBasis = ''
            this.adbSubLimitBasis = []
            this.showAccDBPercentSi = false
            this.showAccDBPrvPercentSi = false
            this.showAccDBFlatAmnt = false
            this.showAccDBPercClaimAmt = false
            this.showADBOpdSi = false
            this.showADBAdSi = false
            this.showAccDBApplicClaimPay = false
            this.AccDBpercentSI = ''
            this.AccDBPrvpercentSI = ''
            this.AccDBFlatAmountTxtField = ''
            this.AccDBPercentClaimAmt = ''
            this.AccDBApplicClaimPayOut = ''
            this.adbClaimPaymentBasis = ''
            this.adbSi = ''
            this.abdDsaprncAplcbl = false
            this.abdmxdysAccdnt = ''
            this.abdmxdysDsaprnc = ''
            this.abdcheckbox = false
            this.ADBRelCheckSelected()
            this.ADBCoverAltName = ''
            this.ADBIrdaUINNo = ''
            this.ADBExtRefNo = ''
            this.ADBCondField = []
            this.ADBGcCover = ''
            this.ADBRemarks = ''
            this.ADBAllowdDurFrmDOAcc = ''
            this.ADBWaitingPrdToDeclare = ''
            this.ADBBenifPayable = ''
            this.ADBwaitingPeriodDrop = 'Days'
            this.ADBwaitingPeriod = ''
            this.ADBWaitPeriod = ''
            this.ADBExclus = []
            this.$refs.adbForm.resetValidation()
        },
        clearPTD(){
            this.ptdCoverageType = ''
            this.ptdCoverageBasis = ''
            this.ptdSubLimitBasis = []
            this.showPTDPercentSi = false
            this.showPTDPrvPercentSi = false
            this.showPTDFlatAmnt = false
            this.showPTDPercClaimAmt = false
            this.showPTDOpdSi = false
            this.showPTDAdSi = false
            this.showPTDApplicClaimPay = false
            this.PTDpercentSI = ''
            this.PTDPrvpercentSI = ''
            this.PTDFlatAmountTxtField = ''
            this.PTDPercentClaimAmt = ''
            this.PTDApplicClaimPayOut = ''
            this.ptdClaimPaymentBasis = ''
            this.showADBCovrageApplicDur = false
            this.ADBCovrageApplicDur = ''
            this.showPTDCovrageApplicDur = false
            this.PTDCovrageApplicDur = ''
            this.ptdNatureofLoss = ''
            this.PTDCoverAltName = ''
            this.PTDIrdaUINNo = ''
            this.PTDExtRefNo = ''
            this.PTDCondField = []
            this.PTDGcCover = ''
            this.PTDRemarks = ''
            this.PTDExclus = []
            this.PTDwaitingPeriodDrop = 'Days'
            this.PTDwaitingPeriod = ''
            this.PTDfeatureClaim = false
            this.showPTDPrimCoverage = false
            this.PTDPrimCover = []
            this.PTDBenpay = ''
            this.PTDDurFromAccDate = ''
            this.PTDDisbleContPer = ''
            this.ptdSi = ''
            this.ptdCheckbox = false
            this.PTDRelCheckSelected()
            this.$refs.ptdForm.resetValidation()
        },
        clearHCB(){
            this.hcbcoverageType = ""
            this.hcbcoverageBasis = ''
            this.hcbsubLimitBasis = []
            this.showHCBPercentSi = false
            this.showHCBPrvPercentSi = false
            this.showHCBFlatAmnt = false
            this.showHCBPercClaimAmt = false
            this.HCBPercentSI = ''
            this.HCBPrvPercentSI = ''
            this.HCBFlatAmountTxtField = ''
            this.HCBPercentClaimAmt = ''
            this.hcbclaimPaymentBasis = ''
            this.showHCBCovrageApplicDur = false
            this.HCBCovrageApplicDur = ''
            this.HCBCoverAltName = ''
            this.HCBIrdaUINNo = ''
            this.HCBExtRefNo = ''
            this.HCBCondField = []
            this.HCBGcCover = ''
            this.HCBRemarks = ''
            this.HCBDeductible = ''
            this.HCBExclus = []
            this.HCBSelectBox = false
            this.HCBRelCheckSelected()
            // this.showHCBRelSection = false
            // this.showHCBRelData = false
            this.hcbwaitingPeriod = ''
            this.hcbwaitingPeriodDrop = 'Days'
            this.hcbapplicableClaimPayOut = ''
            this.hcbminHosDuration = ''
            this.hcbflatAmount = ''
            this.hcbperSI = ''
            this.hcbcauseType1 = 'Accident'
            this.hcbroomType1 = 'ICU'
            this.hcbdailyCash1 = ''
            this.hcbcauseType2 = 'Accident'
            this.hcbroomType2 = 'Normal'
            this.hcbdailyCash2 = ''
            this.hcbcauseType3 = 'Non-Accident'
            this.hcbroomType3 = 'ICU'
            this.hcbdailyCash3 = ''
            this.hcbcauseType4 = 'Non-Accident'
            this.hcbroomType4 = 'Normal'
            this.hcbdailyCash4 = ''
            this.hcbflatshow = false
            this.hcbSIshow = false
            this.hcbsubBasisshow = false
            this.hcbshowAppli = false
            this.$refs.hcbForm.resetValidation()
        },
        clearPreHosp(){
            this.phcoverageType = ""
            this.phcoverageBasis = ''
            this.phsubLimitBasis = ''
            this.phclaimPaymentBasis = ''
            this.phduration = ''
            this.phwaitingPeriod = ''
            this.phwaitingPeriodDrop = 'Days'
            this.phisSelectBox = false
            this.phRdiSelected()
            this.phsubBasisshow = false
            this.PreHospCoverAltName = ''
            this.PreHospIrdaUINNo = ''
            this.PreHospExtRefNo = ''
            this.PreHospCondField = []
            this.PreHospGcCover = ''
            this.PreHospRemarks = ''
            this.PreHospExclus = []
            this.shwAppCliamPayPreHosp = false
            this.AppCliamPayPreHosp = ''
            this.shFlatAmtForPRE  =  false
            this.shSiAmtForPRE  =  false
            this.shSiAmtForPrvPRE = false
            this.shweaaPrcntPrvSi = false
            this.shClaimAmtForPRE  =  false
            this.flatAmtForPRE  = ''
            this.siAmtForPRE  = ''
            this.siAmtForPrvPRE =''
            this.siAmtForPrvPRE =''
            this.claimAmtForPRE  = ''
            this.shwCoveAppDuratPreHosp = false
            this.CoveAppDuratPreHosp = ''
            this.$refs.phForm.resetValidation()
        },
        clearPOH(){
            this.pohcoverageType = ""
            this.pohcoverageBasis = ''
            this.pohsubLimitBasis = ''
            this.pohclaimPaymentBasis = ''
            this.pohduration = ''
            this.CoveAppDuratPOH = ''
            this.pohwaitingPeriod = ''
            this.pohwaitingPeriodDrop = 'Days'
            this.pohisSelectBox = false
            this.poRdiSelected()
            this.pohsubBasisshow = false
            this.PostHospCoverAltName = ''
            this.PostHospIrdaUINNo = ''
            this.PostHospExtRefNo = ''
            this.PostHospCondField = []
            this.PostHospGcCover = ''
            this.PostHospRemarks = ''
            this.PostHospExclus = []
            this.shwAppCliamPayPostHosp = false
            this.AppCliamPayPostHosp = ''
            this.shFlatAmtForPoh = false
            this.shSiAmtForPoh = false
            this.shSiAmtForPrvPoh = false
            this.shClaimAmtForPoh = false
            this.flatAmtForPoh = ''
            this.siAmtForPoh = ''
            this.siAmtForPrvPoh = ''
            this.claimAmtForPoh = ''
            this.$refs.pohForm.resetValidation()
                
        },
        clearDCC(){
            this.showPercentSi = false
            this.showPrvPercentSi = false
            this.showFlatAmnt = false
            this.showPercClaimAmt = false
            this.showDCCOpdSi = false
            this.showDCCAdSi = false
            this.showDccApplicClaimPay = false
            this.showDccCovrageApplicDur = false
            this.dccCovrageApplicDur = ''
            this.DccApplicClaimPayOut = ''
            this.percentClaimAmt = ''
            this.percentSI = ''
            this.PrvpercentSI = ''
            this.flatAmountTxtField = ''
            this.DCCCoverAltName = ''
            this.DCCIrdaUINNo = ''
            this.DCCExtRefNo = ''
            this.DCCCondField = []
            this.DCCGcCover = ''
            this.DCCRemarks = ''
            this.DCCExclus = []
            this.dcccoverageType = ""
            this.dcccoverageBasis = ''
            this.dccsubLimitBasis = []
            this.dccclaimPaymentBasis = ''
            this.dccappliDayCarePro = []
            this.dccwaitingPeriod = ''
            this.dccwaitingPeriodDrop = 'Days'
            this.dccminHosDuration = ''
            this.dccisSelectBox = false
            this.DCRelCheckSelected()
            this.dccsubBasisshow = false
            this.dccwaitingPeriodDrop = 'Days'
            this.$refs.dccForm.resetValidation()
        },
        clearRBC(){
            this.rbcoverageType = ""
            this.rbcoverageBasis = ''
            this.rbsubLimitBasis = ''
            this.rbclaimPaymentBasis = ''
            this.rbrestoreWaitingPeriod = ''
            this.rbrestoreWaitingPeriodDrop = 'Days'
            this.rbNoReinstatementsPermitted = ''
            this.rbapplicableClaimPayOut = ''
            this.rbCad = ''
            this.rbFlatAmt = ''
            this.rbClmAmt = ''
            this.rbPerSI = ''
            this.rbPrvPerSI = ''
            this.RBCCoverAltName = ''
            this.RBCIrdaUINNo = ''
            this.RBCExtRefNo = ''
            this.RBCCondField = []
            this.RBCGcCover = ''
            this.RBCRemarks = ''
            this.RBCExclus = []
            this.RBCSelectBox = false
            this.RBCRelCheckSelected()
            this.shwrbFltAmt = false
            this.shwrbPerSI = false
            this.shwrbPrvPerSI = false
            this.shwrbClmAmt = false
            this.shwrbCvrgAplcblDrtn = false
            this.showrbacpo = false
            this.rbsubBasisshow = false
            this.$refs.rbForm.resetValidation()
        },
        clearIPD(){
            this.showIPDsubLimBasis = false
            this.showIPDPercentSi = false
            this.showIPDFlatAmnt = false
            this.showIPDPrvPercentSi = false
            this.showIPDPercClaimAmt = false
            this.showIPDOpdSi = false
            this.showIPDAdSi = false
            this.showIPDAppli = false
            this.showIPDclaimPayBasis = false
            this.showIPDCovrageApplicDur = false
            this.showIPDcoPayType = false
            this.showIPDCoPayPrcnt = false
            this.IPDCoverAltName = ''
            this.IPDCoverageType = ''
            this.IPDCoverageBasis = ''
            this.IPDSubLimitBasis = []
            this.IPDPercentSI = ''
            this.IPDFlatAmountTxtField = ''
            this.IPDPrvPercentSI = ''
            this.IPDPercentClaimAmt = ''
            this.IPDOpdSi = ''
            this.IPDAdSi = ''
            this.IPDapplicableClaimPayOut = ''
            this.IPDClaimPaymentBasis = ''
            this.IPDCovrageApplicDur = ''
            this.IPDWaitingPeriodDrop = 'Days'
            this.IPDWaitingPeriod = ''
            this.IPDMinHospDura = ''
            this.IPDCoPayapplicable = 'No'
            this.IPDCoPayType = ''
            this.IPDCoPayPrcnt = ''
            this.IPDCoPayPrcntSR = ''
            this.IPDCoPayPrcntNR = ''
            this.IPDMinAgeMemb = ''
            this.IPDMinTreatDura = ''
            this.IPDIrdaUINNo = ''
            this.IPDExtRefNo = ''
            this.IPDCondField = []
            this.IPDGcCover = ''
            this.IPDRemarks = ''
            this.IPDExclus = []
            this.showIPDCoPayPercRoom = false
            this.IPDSelectBox = false
            this.showIPDRelSection = false
            this.showIPDRelData = false
            this.$refs.IPDForm.resetValidation()
        },
        clearOD(){
            this.showODsubLimBasis = false
            this.showODPercentSi = false
            this.showODFlatAmnt = false
            this.showODPrvPercentSi = false
            this.showODPercClaimAmt = false
            this.showODOpdSi = false
            this.showODAdSi = false
            this.showODAppli = false
            this.showODclaimPayBasis = false
            this.showODCovrageApplicDur = false
            this.showODcoPayType = false
            this.showODCoPayPrcnt = false
            this.ODCoverAltName = ''
            this.ODCoverageType = ''
            this.ODCoverageBasis = ''
            this.ODSubLimitBasis = []
            this.ODPercentSI = ''
            this.ODFlatAmountTxtField = ''
            this.ODPrvPercentSI = ''
            this.ODPercentClaimAmt = ''
            this.ODOpdSi = ''
            this.ODAdSi = ''
            this.ODapplicableClaimPayOut = ''
            this.ODClaimPaymentBasis = ''
            this.ODCovrageApplicDur = ''
            this.ODWaitingPeriodDrop = 'Days'
            this.ODWaitingPeriod = ''
            this.ODMinHospDura = ''
            this.ODCoPayapplicable = 'No'
            this.ODCoPayType = ''
            this.ODCoPayPrcnt = ''
            this.ODCoPayPrcntSR = ''
            this.ODCoPayPrcntNR = ''
            this.ODMinAgeMemb = ''
            this.ODMinTreatDura = ''
            this.ODIrdaUINNo = ''
            this.ODExtRefNo = ''
            this.ODCondField = []
            this.ODGcCover = ''
            this.ODRemarks = ''
            this.ODExclus = []
            this.showODCoPayPercRoom = false
            this.ODSelectBox = false
            this.showODRelSection = false
            this.showODRelData = false
            this.$refs.ODForm.resetValidation()
        },
        clearBSC(){
            this.showBSCsubLimBasis = false
            this.showBSCPercentSi = false
            this.showBSCFlatAmnt = false
            this.showBSCPrvPercentSi = false
            this.showBSCPercClaimAmt = false
            this.showBSCOpdSi = false
            this.showBSCAdSi = false
            this.showBSCAppli = false
            this.showBSCclaimPayBasis = false
            this.showBSCCovrageApplicDur = false
            this.showBSCcoPayType = false
            this.showBSCCoPayPrcnt = false
            this.BSCCoverAltName = ''
            this.BSCCoverageType = ''
            this.BSCCoverageBasis = ''
            this.BSCSubLimitBasis = []
            this.BSCPercentSI = ''
            this.BSCFlatAmountTxtField = ''
            this.BSCPrvPercentSI = ''
            this.BSCPercentClaimAmt = ''
            this.BSCOpdSi = ''
            this.BSCAdSi = ''
            this.BSCapplicableClaimPayOut = ''
            this.BSCClaimPaymentBasis = ''
            this.BSCCovrageApplicDur = ''
            this.BSCWaitingPeriodDrop = 'Days'
            this.BSCWaitingPeriod = ''
            this.BSCMinHospDura = ''
            this.BSCCoPayapplicable = 'No'
            this.BSCCoPayType = ''
            this.BSCCoPayPrcnt = ''
            this.BSCCoPayPrcntSR = ''
            this.BSCCoPayPrcntNR = ''
            this.BSCMinAgeMemb = ''
            this.BSCMinTreatDura = ''
            this.BSCIrdaUINNo = ''
            this.BSCExtRefNo = ''
            this.BSCCondField = []
            this.BSCGcCover = ''
            this.BSCRemarks = ''
            this.BSCExclus = []
            this.showBSCCoPayPercRoom = false
            this.BSCSelectBox = false
            this.showBSCRelSection = false
            this.showBSCRelData = false
            this.$refs.BSCForm.resetValidation()
        },
        clearDT(){
            this.showDTsubLimBasis = false
            this.showDTPercentSi = false
            this.showDTFlatAmnt = false
            this.showDTPrvPercentSi = false
            this.showDTPercClaimAmt = false
            this.showDTOpdSi = false
            this.showDTAdSi = false
            this.showDTAppli = false
            this.showDTclaimPayBasis = false
            this.showDTCovrageApplicDur = false
            this.showDTcoPayType = false
            this.showDTCoPayPrcnt = false
            this.DTCoverAltName = ''
            this.DTCoverageType = ''
            this.DTCoverageBasis = ''
            this.DTSubLimitBasis = []
            this.DTPercentSI = ''
            this.DTFlatAmountTxtField = ''
            this.DTPrvPercentSI = ''
            this.DTPercentClaimAmt = ''
            this.DTOpdSi = ''
            this.DTAdSi = ''
            this.DTapplicableClaimPayOut = ''
            this.DTClaimPaymentBasis = ''
            this.DTCovrageApplicDur = ''
            this.DTWaitingPeriodDrop = 'Days'
            this.DTWaitingPeriod = ''
            this.DTMinHospDura = ''
            this.DTCoPayapplicable = 'No'
            this.DTCoPayType = ''
            this.DTCoPayPrcnt = ''
            this.DTCoPayPrcntSR = ''
            this.DTCoPayPrcntNR = ''
            this.DTMinAgeMemb = ''
            this.DTMinTreatDura = ''
            this.DTIrdaUINNo = ''
            this.DTExtRefNo = ''
            this.DTCondField = []
            this.DTGcCover = ''
            this.DTRemarks = ''
            this.DTExclus = []
            this.showDTCoPayPercRoom = false
            this.DTSelectBox = false
            this.showDTRelSection = false
            this.showDTRelData = false
            this.$refs.DTForm.resetValidation()
        },
        clearFTB(){
            this.coverageType = ''
            this.coverageBasis = ''
            this.subLimitBasis = []
            this.claimPaymentBasis = ''
            this.MnElgblDstnceFrmInsrdRsdnc = ''
            this.FTBwaitingPeriod = ''
            this.AlwdCntofImdteFmlyMmbr = ''
            this.FTBCoverAltName = ''
            this.FTBIrdaUINNo = ''
            this.FTBExtRefNo = ''
            this.FTBCondField = []
            this.FTBGcCover = ''
            this.FTBRemarks = ''
            this.FTBExclus = []
            this.FTBwaitingPeriodDrop = 'Days'
            this.FTBwaitingPeriod = ''
            this.FTBSelectBox = false
            this.showFTBRelSection = false
            this.showFTBRelData = false
            this.shFlatAmtForFTB  =  false
            this.shSiAmtForFTB  =  false
            this.shSiAmtPrvForFTB = false
            this.shClaimAmtForFTB  =  false
            this.flatAmtForFTB  =  ''
            this.siAmtForFTB  =  ''
            this.siAmtPrvForFTB = ''
            this.claimAmtForFTB  =  ''
            this.shwCoveAppDurat  =  false
            this.shwFtbSlb = false
            this.CoveAppDurat =  ''
            this.shwAppCliamPay  =  false
            this.AppCliamPay  =  ''
            this.$refs.ftbForm.resetValidation()
        },
        clearVC(){
            this.vcCoverageType = ''
            this.vcCoverageBasis = ''
            this.vcSubLimitBasis = []
            this.showVcPercentSi = false
            this.showVcPrvPercentSi = false
            this.showVcFlatAmnt = false
            this.showVcPercClaimAmt = false
            this.showVCOpdSi = false
            this.showVCAdSi = false
            this.showVcApplicClaimPay = false
            this.vcPercentSI = ''
            this.vcPrvPercentSI = ''
            this.vcFlatAmountTxtField = ''
            this.vcPercentClaimAmt = ''
            this.vcApplicClaimPayOut = ''
            this.vcClaimPaymentBasis = ''
            this.vcExclusions = []
            this.vcWtngPrd = ''
            this.VCCoverAltName = ''
            this.VCIrdaUINNo = ''
            this.VCExtRefNo = ''
            this.VCCondField = []
            this.VCGcCover = ''
            this.VCRemarks = ''
            this.VCExclus = []
            this.VCwaitingPeriodDrop = 'Days'
            this.VCwaitingPeriod = ''
            this.VCSelectBox = false
            this.showVCRelSection = false
            this.showVCRelData = false
            this.$refs.vcForm.resetValidation()
        },
        clearIT(){
            this.iftCoverageType = ''
            this.iftCoverageBasis = ''
            this.showItCovrageApplicDur = false
            this.ItCovrageApplicDur = ''
            this.showItPercentSi = false
            this.showItPrvPercentSi = false
            this.showItFlatAmnt = false
            this.showItPercClaimAmt = false
            this.showITOpdSi = false
            this.showITAdSi = false
            this.showItApplicClaimPay = false
            this.ItPercentSI = ''
            this.ItPrvPercentSI = ''
            this.ItFlatAmountTxtField = ''
            this.ItPercentClaimAmt = ''
            this.ItApplicClaimPayOut = ''
            this.iftSubLimitBasis = []
            this.iftClaimPaymentBasis = ''
            this.iftFlatAmt = ''
            this.iftWaitingPeriod = ''
            this.iftExclsns = []
            this.iftMdclExpnse = []
            this.ITCoverAltName = ''
            this.ITIrdaUINNo = ''
            this.ITExtRefNo = ''
            this.ITCondField = []
            this.ITGcCover = ''
            this.ITRemarks = ''
            this.ITExclus = []
            this.ITwaitingPeriodDrop = 'Days'
            this.ITwaitingPeriod = ''
            this.ITSelectBox = false
            this.showITRelSection = false
            this.showITRelData = false
            this.$refs.ifForm.resetValidation()
        },
        clearAB(){
            this.showABsubLimBasis = false
            this.showABPercentSi = false
            this.showABFlatAmnt = false
            this.showABPrvPercentSi = false
            this.showABPercClaimAmt = false
            this.showABOpdSi = false
            this.showABAdSi = false
            this.showABAppli = false
            this.showABclaimPayBasis = false
            this.showABCovrageApplicDur = false
            this.showABcoPayType = false
            this.showABCoPayPrcnt = false
            this.ABCoverAltName = ''
            this.ABCoverageType = ''
            this.ABCoverageBasis = ''
            this.ABSubLimitBasis = []
            this.ABPercentSI = ''
            this.ABFlatAmountTxtField = ''
            this.ABPrvPercentSI = ''
            this.ABPercentClaimAmt = ''
            this.ABOpdSi = ''
            this.ABAdSi = ''
            this.ABapplicableClaimPayOut = ''
            this.ABClaimPaymentBasis = ''
            this.ABCovrageApplicDur = ''
            this.ABWaitingPeriodDrop = 'Days'
            this.ABWaitingPeriod = ''
            this.ABMinHospDura = ''
            this.ABCoPayapplicable = 'No'
            this.ABCoPayType = ''
            this.ABCoPayPrcnt = ''
            this.ABCoPayPrcntSR = ''
            this.ABCoPayPrcntNR = ''
            this.ABMinAgeMemb = ''
            this.ABMinTreatDura = ''
            this.ABIrdaUINNo = ''
            this.ABExtRefNo = ''
            this.ABCondField = ''
            this.ABGcCover = ''
            this.ABRemarks = ''
            this.ABExclus = []
            this.showABCoPayPercRoom = false
            this.ABSelectBox = false
            this.showABRelSection = false
            this.showABRelData = false

            this.$refs.ABForm.resetValidation()
        },
        clearNA(){
            this.naCoverageType = ''
            this.naCoverageBasis = ''
            this.naClaimPaymentBasis = ''
            this.showNACovrageApplicDur = false
            this.NACovrageApplicDur = ''
            this.naSubLimitBasis = []
            this.showNAPercentSi = false
            this.showNAPrvPercentSi = false
            this.showNAFlatAmnt = false
            this.showNAPercClaimAmt = false
            this.showNAOpdSi = false
            this.showNAAdSi = false
            this.showNAApplicClaimPay = false
            this.NAPercentSI = ''
            this.NAPrvPercentSI = ''
            this.NAFlatAmountTxtField = ''
            this.NAPercentClaimAmt = ''
            this.NAApplicClaimPayOut = ''
            this.naFlatAmt = ''
            this.naMxPyblDrtn = ''
            this.naDeductible = ''
            this.naWtngPrd = ''
            this.NAwaitingPeriodDrop = 'Days'
            this.NACoverAltName = ''
            this.NAIrdaUINNo = ''
            this.NAExtRefNo = ''
            this.NACondField = []
            this.NAGcCover = ''
            this.NARemarks = ''
            this.NAExclus = []
            this.NASelectBox = false
            this.NARelCheckSelected()
            // this.showNARelSection = false
            // this.showNARelData = false
            this.$refs.naForm.resetValidation()
        },
        clearOPDT(){
            this.opdTCoverageType = ''
            this.opdTCoverageBasis = ''
            this.opdTSubLimitBasis = []
            this.opdTClaimPaymentBasis = ''
            this.opdTFlatAmt = ''
            this.opdTFlatAmt = ''
            this.opdTPrcntSi = ''
            this.opdTPrcntPrvSi = ''
            this.opdTPrcntClmAmt = ''
            this.opdTAplcblClmPyot = ''
            this.opdTCad = ''
            this.opdTWtngPrd = ''
            this.OPDTCoverAltName = ''
            this.OPDTIrdaUINNo = ''
            this.OPDTExtRefNo = ''
            this.OPDTCondField = []
            this.OPDTGcCover = ''
            this.OPDTRemarks = ''
            this.OPDTExclus = []
            this.OPDTwaitingPeriodDrop = 'Days'
            this.OPDTSelectBox = false
            this.showOPDTRelSection = false
            this.showOPDTRelData = false
            this.shwopdTPrcntSi = false
            this.shwopdTPrcntPrvSi = false
            this.shwopdTPrcntClmAmt = false
            this.shwopdTAplcblClmPyot = false
            this.shwopdTCvrgAplcblDrtn = false
            this.$refs.opdTForm.resetValidation()
        },
        clearOPDDent(){
            this.shwodtPrcntSi = false
            this.shwodtPrcntPrvSi = false
            this.shwodtPrcntClmAmt = false
            this.shwodtAplcblClmPyot = false
            this.shwodtCvrgAplcblDrtn = false
            this.odtvalid = false
            this.odtcoverageType = ""
            this.odtcoverageBasis = ''
            this.odtsubLimitBasis = []
            this.odtclaimPaymentBasis = ''
            this.odtflatAmount = ""
            this.odtPrcntSi = ''
            this.odtPrcntPrvSi = ''
            this.odtPrcntClmAmt = ''
            this.odtAplcblClmPyot = ''
            this.odtCad = ''
            this.ODTCoverAltName = ''
            this.ODTIrdaUINNo = ''
            this.ODTExtRefNo = ''
            this.ODTCondField = []
            this.ODTGcCover = ''
            this.ODTRemarks = ''
            this.ODTExclus = []
            this.odtwaitingPeriod = ''
            this.odtwaitingPeriodDrop = 'Days'
            this.odtflatshow = false
            this.odtsubBasisshow = false
            this.$refs.odtForm.resetValidation()
        },
        clearEAA(){
            // this.eaavalid = false   
            this.eaacoverageType = ""
            this.eaacoverageBasis = ''
            this.eaasubLimitBasis = []
            this.eaaclaimPaymentBasis = ''
            this.eaaflatAmount = ''
            this.eaaPrcntSi = ''
            this.eaaPrcntPrvSi = ''
            this.eaaPrcntClmAmt = ''
            this.eaaAplcblClmPyot = ''
            this.eaaCad = ''
            this.eaawaitingPeriod = ''
            this.EAAwaitingPeriodDrop = 'Days'
            this.eaaflatshow = false
            this.eaasubBasisshow = false
            this.EAACoverAltName = ''
            this.EAAIrdaUINNo = ''
            this.EAAExtRefNo = ''
            this.EAACondField = []
            this.EAAGcCover = ''
            this.EAARemarks = ''
            this.EAAExclus = []
            this.EAASelectBox = false
            this.EARelCheckSelected()
            this.EAAwaitingPeriodDrop = 'Days'
            this.shweaaPrcntSi = false
            this.shweaaPrcntPrvSi = false
            this.shweaaPrcntClmAmt = false
            this.shweaaAplcblClmPyot = false
            this.shweaaCvrgAplcblDrtn = false
            this.$refs.eaaForm.resetValidation()

        },
        clearTMR(){
            this.tmrCoverageType = ''
            this.tmrCoverageBasis = ''
            this.tmrSubLimitBasis = []
            this.tmrClaimPaymentBasis = ''
            this.tmrFlatAmt = ''
            this.tmrPrcntSi = ''
            this.tmrPrcntPrvSi = ''
            this.tmrPrcntClmAmt = ''
            this.tmrAplcblClmPyot = ''
            this.tmrCad = ''
            this.TMRCoverAltName = ''
            this.TMRIrdaUINNo = ''
            this.TMRExtRefNo = ''
            this.TMRCondField = []
            this.TMRGcCover = ''
            this.TMRRemarks = ''
            this.TMRExclus = []
            this.TMRwaitingPeriodDrop = 'Days'
            this.TMRwaitingPeriod = ''
            this.TMRSelectBox = false
            this.shwTmrPrcntSi = false
            this.shwTmrPrcntPrvSi = false
            this.shwTmrPrcntClmAmt = false
            this.shwTmrAplcblClmPyot = false
            this.shwTmrCvrgAplcblDrtn = false
            this.shwTmrSlb = false
            this.TMRRelCheckSelected()
            this.$refs.tmrForm.resetValidation()

        },
        clearFE(){
            this.feCoverageType = ''
            this.feCoverageBasis = ''
            this.feSubLimitBasis = []
            this.feClaimPaymentBasis = ''
            this.feFlatAmt = ''
            this.FeCad = ''
            this.fePrcntSi = ''
            this.fePrcntPrvSi = ''
            this.fePrcntClmAmt = ''
            this.feAplcblClmPyot = ''
            this.FECoverAltName = ''
            this.FEIrdaUINNo = ''
            this.FEExtRefNo = ''
            this.FECondField = []
            this.FEGcCover = ''
            this.FERemarks = ''
            this.FEExclus = []
            this.FEwaitingPeriodDrop = 'Days'
            this.FEwaitingPeriod = ''
            this.FESelectBox = false
            this.shwfePrcntSi = false
            this.shwfePrcntPrvSi = false
            this.shwFePrcntClmAmt = false
            this.shwFeCvrgAplcblDrtn = false
            this.shwFeAplcblClmPyot = false
            this.shwFeSlb = false
            this.FERelCheckSelected()
            this.$refs.feForm.resetValidation()
        },
        clearEB(){
            this.ebCoverageType = '',
            this.ebCoverageBasis = '',
            this.ebSubLimitBasis = [],
            this.showEBPercentSi = false,
            this.showEBPrvPercentSi = false,
            this.showEBFlatAmnt = false,
            this.showEBPercClaimAmt = false,
            this.showEBOpdSi = false
            this.showEBAdSi = false
            this.showEBApplicClaimPay = false,
            this.EBPercentSI = '',
            this.EBPrvPercentSI = '',
            this.EBFlatAmountTxtField = '',
            this.EBPercentClaimAmt = '',
            this.EBApplicClaimPayOut = '',
            this.ebClaimPaymentBasis = '',
            this.showEBCovrageApplicDur = false,
            this.EBCovrageApplicDur = '',
            this.ebMxChldAge = '',
            this.EBCoverAltName = '',
            this.EBIrdaUINNo = '',
            this.EBExtRefNo = '',
            this.EBCondField = [],
            this.EBGcCover = '',
            this.EBRemarks = '',
            this.EBExclus = [],
            this.EBwaitingPeriodDrop = 'Days',
            this.EBwaitingPeriod = '',
            this.EBMaxYearsFeesPaid = '',
            this.EBEligibility = '',
            this.EBBenpay = '',
            this.EBPrimCover = [],
            this.EBfeatureClaim = false,
            this.showEBPrimCoverage = false,
            this.EBSelectBox = false,
            this.EBRelCheckSelected()
            this.$refs.ebForm.resetValidation()
        },
        clearAmbC(){
            this.ambccoverageType = ""
            this.ambccoverageBasis = ''
            this.ambcsubLimitBasis = ''
            this.ambcclaimPaymentBasis = ''
            this.ambcwaitingPeriod = ''
            this.ACCoverAltName = ''
            this.ACIrdaUINNo = ''
            this.ACExtRefNo = ''
            this.ACCondField = []
            this.ACGcCover = ''
            this.ACRemarks = ''
            this.ACExclus = []
            this.ACwaitingPeriodDrop = 'Days'
            this.ACwaitingPeriod = ''
            this.ACSelectBox = false
            this.showACRelSection = false
            this.showACRelData = false
            this.shFlatAmtForambc = false
            this.shSiAmtPrvForambc = false
            this.flatAmtForambc = ''
            this.siAmtPrvForambc = ''
            this.shSiAmtForambc = false
            this.siAmtForambc = ''
            this.shClaimAmtForambc = false
            this.claimAmtForambc = ''
            this.ambcsubBasisshow = false
            this.ambcapplicableClaimPayOut = ''
            this.shwAppCliamPayambc = false
            this.shwCoveAppDuratambc = false
            this.CoveAppDuratambc = ''

            this.$refs.ambcForm.resetValidation()

        },
        // 6.odt

        changeABCopyAplcbl(){
            // console.log(this.ABCoPayapplicable,"ABCoPayapplicable")
            if(this.ABCoPayapplicable === "Yes"){
                this.showABcoPayType = true
              
            }else{
                this.showABcoPayType = false
                this.showABCoPayPrcnt = false
                this.showABCoPayPercRoom = false
                this.ABCoPayType = ''
                this.ABCoPayPrcntSR = ''
                this.ABCoPayPrcntNR = ''
            }

        },

        changeABClaimPaymentBasis(event){
            this.showABCovrageApplicDur = false
            event !== undefined ? event == 'Duration basis' ? this.showABCovrageApplicDur = true : '' : ''
            this.showABCovrageApplicDur === false ? this.ABCovrageApplicDur = '' : ''
        },

        changeABSubLmtBasis(event){
            this.showABPercentSi = false
            this.showABFlatAmnt = false
            this.showABPercClaimAmt = false
            this.showABAppli = false
            this.showABPrvPercentSi = false
            // New Fields Added 
            this.showABOpdSi = false
            this.showABAdSi = false

            for(let i = 0; i<= event.length; i++){
                if(event[i] !== undefined){
                    event[i] == '% of SI' ? this.showABPercentSi = true : ''
                    event[i] == 'Flat amount' ? this.showABFlatAmnt = true : ''
                    event[i] == '% of claimed amount' ? this.showABPercClaimAmt = true : ''
                    event[i] == '% of previous yr SI' ? this.showABPrvPercentSi = true : ''
                    // New Fields Added 
                    event[i] == '% of OPD SI' ? this.showABOpdSi = true : ''
                    event[i] == '% of AD SI' ? this.showABAdSi = true : ''
                }
            }

            event.length > 1 ? this.showABAppli = true : ''
            this.showABPercentSi == false ? this.ABPercentSI = '' : ''
            this.showABFlatAmnt == false ? this.ABFlatAmountTxtField = '' : ''
            this.showABPercClaimAmt == false ? this.ABPercentClaimAmt = '' : ''
            this.showABPrvPercentSi == false ? this.ABPrvPercentSI = '' : ''
            this.showABOpdSi == false ? this.ABOpdSi = '' : ''
            this.showABAdSi == false ? this.ABAdSi = '' : ''
        },

        changeABCoverBasis(ev){
            this.showABFlatAmnt = false
            this.showABPercentSi = false
            this.showABPercClaimAmt = false
            this.showABAppli = false
            this.showABCovrageApplicDur = false
            this.showABPrvPercentSi = false
            this.showABOpdSi = false
            this.showABAdSi = false

            this.ABFlatAmountTxtField = ''
            this.ABPercentSI = ''
            this.ABPrvPercentSI = ''
            this.ABPercentClaimAmt = ''
            this.ABapplicableClaimPayOut = ''
            this.ABCovrageApplicDur = ''
            this.ABClaimPaymentBasis = ''
            this.ABOpdSi = ''
            this.ABAdSi = ''
            this.ABSubLimitBasis = []
            ev == 1 ? this.$refs.ABForm.resetValidation() : ''

            // console.log(this.ABCoverageBasis,"ABCoverageBasis")
            if(this.ABCoverageBasis == "Over & above SI" || this.ABCoverageBasis == "Upto SI with sub limit"){
                this.showABsubLimBasis = true
            }else{
                this.showABsubLimBasis = false
            }
        },
        changeABCoPayPrcnt(){
            console.log(this.showABcoPayType ," howABcoPayType ")             
            // showCoPayPercRoom
            // Basis room category
            if(this.ABCoPayType == "Percentage"){
                this.showABCoPayPrcnt = true
                this.showABCoPayPercRoom = false
                this.ABCoPayPrcntSR = ''
                this.ABCoPayPrcntNR = ''
            }else{
                this.showABCoPayPrcnt = false
                this.showABCoPayPercRoom = true
            }
        },
        

        changeDTCopyAplcbl(){
            // console.log(this.DTCoPayapplicable,"DTCoPayapplicable")
            if(this.DTCoPayapplicable === "Yes"){
                this.showDTcoPayType = true
              
            }else{
                this.showDTcoPayType = false
                this.showDTCoPayPrcnt = false
                this.showDTCoPayPercRoom = false
                this.DTCoPayType = ''
                this.DTCoPayPrcntSR = ''
                this.DTCoPayPrcntNR = ''
            }
        },

        changeDTClaimPaymentBasis(event){
            this.showDTCovrageApplicDur = false
            event !== undefined ? event == 'Duration basis' ? this.showDTCovrageApplicDur = true : '' : ''
            this.showDTCovrageApplicDur === false ? this.DTCovrageApplicDur = '' : ''
        },

        changeDTSubLmtBasis(event){
            this.showDTPercentSi = false
            this.showDTFlatAmnt = false
            this.showDTPercClaimAmt = false
            this.showDTAppli = false
            this.showDTPrvPercentSi = false
            // New Fields Added 
            this.showDTOpdSi = false
            this.showDTAdSi = false

            for(let i = 0; i<= event.length; i++){
                if(event[i] !== undefined){
                    event[i] == '% of SI' ? this.showDTPercentSi = true : ''
                    event[i] == 'Flat amount' ? this.showDTFlatAmnt = true : ''
                    event[i] == '% of claimed amount' ? this.showDTPercClaimAmt = true : ''
                    event[i] == '% of previous yr SI' ? this.showDTPrvPercentSi = true : ''
                    // New Fields Added 
                    event[i] == '% of OPD SI' ? this.showDTOpdSi = true : ''
                    event[i] == '% of AD SI' ? this.showDTAdSi = true : ''
                }
            }

            event.length > 1 ? this.showDTAppli = true : ''
            this.showDTPercentSi == false ? this.DTPercentSI = '' : ''
            this.showDTFlatAmnt == false ? this.DTFlatAmountTxtField = '' : ''
            this.showDTPercClaimAmt == false ? this.DTPercentClaimAmt = '' : ''
            this.showDTPrvPercentSi == false ? this.DTPrvPercentSI = '' : ''
            this.showDTOpdSi == false ? this.DTOpdSi = '' : ''
            this.showDTAdSi == false ? this.DTAdSi = '' : ''
        },

        changeDTCoverBasis(ev){
            this.showDTFlatAmnt = false
            this.showDTPercentSi = false
            this.showDTPercClaimAmt = false
            this.showDTAppli = false
            this.showDTCovrageApplicDur = false
            this.showDTPrvPercentSi = false
            this.showDTOpdSi = false
            this.showDTAdSi = false

            this.DTFlatAmountTxtField = ''
            this.DTPercentSI = ''
            this.DTPrvPercentSI = ''
            this.DTPercentClaimAmt = ''
            this.DTapplicableClaimPayOut = ''
            this.DTCovrageApplicDur = ''
            this.DTClaimPaymentBasis = ''
            this.DTOpdSi = ''
            this.DTAdSi = ''
            this.DTSubLimitBasis = []
            ev == 1 ? this.$refs.DTForm.resetValidation() : ''

            // console.log(this.DTCoverageBasis,"DTCoverageBasis")
            if(this.DTCoverageBasis == "Over & above SI" || this.DTCoverageBasis == "Upto SI with sub limit"){
                this.showDTsubLimBasis = true
            }else{
                this.showDTsubLimBasis = false
            }
        },
        changeDTCoPayPrcnt(){
            if(this.DTCoPayType == "Percentage"){
                this.showDTCoPayPrcnt = true
                this.showDTCoPayPercRoom = false
                this.DTCoPayPrcntSR = ''
                this.DTCoPayPrcntNR = ''
            }else{
                this.showDTCoPayPrcnt = false
                this.showDTCoPayPercRoom = true
            }
        },

        odtchangeOnover(cmgfrm){
            this.odtflatshow=false
            this.shwodtPrcntSi=false
            this.shwodtPrcntClmAmt=false
            this.odtflatAmount=""
            this.odtPrcntSi=""
            this.odtPrcntClmAmt=""
            this.odtclaimPaymentBasis=''
            this.shwodtCvrgAplcblDrtn=false
            this.odtCad=''
            this.odtsubLimitBasis=[]
            this.shwodtAplcblClmPyot=false
            this.odtAplcblClmPyot=''
            this.shwodtPrcntPrvSi=false
            this.odtPrcntPrvSi=''

             if(cmgfrm===1){
                this.$refs.odtForm.resetValidation()
            }
            console.log("flat---->",this.odtcoverageBasis)
            if(this.odtcoverageBasis== 'Over & above SI' || this.odtcoverageBasis== 'Upto SI with sub limit'){
                this.odtsubBasisshow=true
            }else{
                this.odtsubBasisshow=false 
            }
        },

        chngOdtSubLmtBasis(){
            
            this.odtflatshow=false
            this.shwodtPrcntSi=false
            this.shwodtPrcntClmAmt=false
            this.shwodtAplcblClmPyot=false
            this.shwodtPrcntPrvSi=false
            console.log(this.odtsubLimitBasis,"odtsubLimitBasis selected")
            for(let i=0;i<=this.odtsubLimitBasis.length;i++){
                if(this.odtsubLimitBasis[i]==="Flat amount"){
                    this.odtflatshow=true
                }
                 if(this.odtsubLimitBasis[i]==="% of SI"){
                    this.shwodtPrcntSi=true
                }

                if(this.odtsubLimitBasis[i]==="% of claimed amount"){
                    this.shwodtPrcntClmAmt=true
                }
                if(this.odtsubLimitBasis[i]==="% of previous yr SI"){
                    this.shwodtPrcntPrvSi=true
                }
            }
            if(this.odtsubLimitBasis.length>1){
                this.shwodtAplcblClmPyot=true
            }
            if (this.shwodtAplcblClmPyot===false){
                this.odtAplcblClmPyot=""

            }

            if (this.odtflatshow===false){
                this.odtflatAmount=""

            }
            if (this.shwodtPrcntSi===false){
                this.odtPrcntSi=""

            }
            if (this.shwodtPrcntClmAmt===false){
                this.odtPrcntClmAmt=""

            }
             if (this.shwodtPrcntPrvSi===false){
                this.odtPrcntPrvSi=""

            }
        },
        chngOdtClaimPaymentBasis(){
            console.log(this.odtclaimPaymentBasis,"odtclaimPaymentBasis")
            this.shwodtCvrgAplcblDrtn=false
            console.log(this.odtclaimPaymentBasis,"odtclaimPaymentBasis selected")
            // for(let i=0;i<=this.odtclaimPaymentBasis.length;i++){
                if(this.odtclaimPaymentBasis==="Duration basis"){
                    this.shwodtCvrgAplcblDrtn=true
                }
            // }
            if(this.shwodtCvrgAplcblDrtn===false){
                this.odtCad=''
            }

        },


            odt_save(){
            
               if (this.$refs.odtForm.validate()){
                this.showToast("Success",this.TOST().SUCCESS);
                let formData={
                coverageType: this.odtcoverageType,
                coverageBasis: this.odtcoverageBasis,
                subLimitBasis: this.odtsubLimitBasis,
                claimPaymentBasis: this.odtclaimPaymentBasis,
                flatAmount: this.odtflatAmount,
                waitingPeriod: this.odtwaitingPeriod, 
                
                // perOfSi: this.
                // perOfClaimAmount:this.
                }
                console.log(formData,"=========odtForm Data")

            }else{
                this.$refs.odtForm.validate()
                this.showToast("Please Fill Required Fields",this.TOST().WARNING);
            }
            //     this.$v.$touch();   
            //     if(this.$v.$invalid){
            //         // console.log("Form has errors",this.$v);  
            //         this.showToast('Please fill all required fields', this.TOST().WARNING);
            //         return
            //     }
            //     console.log('proceed')
                
            //     let self=this
            // var formData ={

            //     coverageType: this.odtcoverageType,
            //     coverageBasis: this.odtcoverageBasis,
            //     subLimitBasis: this.odtsubLimitBasis,
            //     claimPaymentBasis: this.odtclaimPaymentBasis,
            //     flatAmount: this.odtflatAmount,
            //     waitingPeriod: this.odtwaitingPeriod, 
                
            //     // perOfSi: this.
            //     // perOfClaimAmount:this.
            // }
            // // this.nextbtn=false
            // console.log('New born baby form saved data',formData)
            
        },

        // 7.

        eaachangeOnover(cmgfrm){
            this.eaaflatshow=false
            this.shweaaPrcntSi=false
            this.shweaaPrcntClmAmt=false
            this.eaaflatAmount=""
            this.eaaPrcntSi=""
            this.eaaPrcntClmAmt=""
            this.eaaclaimPaymentBasis=''
            this.shweaaCvrgAplcblDrtn=false
            this.eaaCad=''
            this.eaasubLimitBasis=[]
            this.shweaaAplcblClmPyot=false
            this.eaaAplcblClmPyot=''
            this.shweaaPrcntPrvSi=false
            this.eaaPrcntPrvSi=''

             if(cmgfrm===1){
                this.$refs.eaaForm.resetValidation()
            }
            console.log("flat---->",this.eaacoverageBasis)
            if(this.eaacoverageBasis== 'Over & above SI' || this.eaacoverageBasis== 'Upto SI with sub limit'){
                this.eaasubBasisshow=true
            }else{
                this.eaasubBasisshow=false    
            }
        },

        chngEaaSubLmtBasis(){
           this.eaaflatshow=false
            this.shweaaPrcntSi=false
            this.shweaaPrcntClmAmt=false
            this.shweaaAplcblClmPyot=false
             this.shweaaPrcntPrvSi=false
            console.log(this.eaasubLimitBasis,"eaasubLimitBasis selected")
            for(let i=0;i<=this.eaasubLimitBasis.length;i++){
                if(this.eaasubLimitBasis[i]==="Flat amount"){
                    this.eaaflatshow=true
                }
                 if(this.eaasubLimitBasis[i]==="% of SI"){
                    this.shweaaPrcntSi=true
                }

                if(this.eaasubLimitBasis[i]==="% of claimed amount"){
                    this.shweaaPrcntClmAmt=true
                }
                if(this.eaasubLimitBasis[i]==="% of previous yr SI"){
                    this.shweaaPrcntPrvSi=true
                }
            }
            if(this.eaasubLimitBasis.length>1){
                this.shweaaAplcblClmPyot=true
            }
            if (this.shweaaAplcblClmPyot===false){
                this.eaaAplcblClmPyot=""

            }

            if (this.eaaflatAmount===false){
                this.eaaflatAmount=""

            }
            if (this.shweaaPrcntSi===false){
                this.eaaPrcntSi=""

            }
            if (this.shweaaPrcntClmAmt===false){
                this.eaaPrcntClmAmt=""

            }
            if (this.shweaaPrcntPrvSi===false){
                this.eaaPrcntPrvSi=""

            }
        },
        chngEaaClaimPaymentBasis(){
            console.log(this.eaaclaimPaymentBasis,"eaaclaimPaymentBasis")
            this.shweaaCvrgAplcblDrtn=false
            console.log(this.eaaclaimPaymentBasis,"eaaclaimPaymentBasis selected")
            // for(let i=0;i<=this.eaaclaimPaymentBasis.length;i++){
                if(this.eaaclaimPaymentBasis==="Duration basis"){
                    this.shweaaCvrgAplcblDrtn=true
                }
            // }
            if(this.shweaaCvrgAplcblDrtn===false){
                this.eaaCad=''
            }

        },

            EAA_save(){

                   if (this.$refs.eaaForm.validate()){
                this.showToast("Success",this.TOST().SUCCESS);
                let formData={
                //    coverageCode: this.,
                coverageType: this.eaacoverageType,
                coverageBasis: this.eaacoverageBasis,
                subLimitBasis: this.eaasubLimitBasis,
                claimPaymentBasis: this.eaaclaimPaymentBasis,
                flatAmount: this.eaaflatAmount,
                waitingPeriod: this.eaawaitingPeriod, 
                
                // perOfSi: this.
                // perOfClaimAmount:this.
                }
                console.log(formData,"=========eaaForm Data")

            }else{
                this.$refs.eaaForm.validate()
                this.showToast("Please Fill Required Fields",this.TOST().WARNING);
            }
            
            //     this.$v.$touch();
                
            //     if(this.$v.$invalid){
            //         // console.log("Form has errors",this.$v);  
            //         this.showToast('Please fill all required fields', this.TOST().WARNING);
            //         return
            //     }
            //     console.log('proceed')
                
            //     let self=this
            // var formData ={
            //     // coverageCode: this.,
            //     coverageType: this.eaacoverageType,
            //     coverageBasis: this.eaacoverageBasis,
            //     subLimitBasis: this.eaasubLimitBasis,
            //     claimPaymentBasis: this.eaaclaimPaymentBasis,
            //     flatAmount: this.eaaflatAmount,
            //     waitingPeriod: this.eaawaitingPeriod, 
                
            //     // perOfSi: this.
            //     // perOfClaimAmount:this.
            // }

            // console.log(' form saved data',formData)  
        
        },

        // 8.bdoc
        bdocchangeOnover(cmgfrmbdoc){
                this.bdocsubLimitBasis = []
                this.bdocclaimPaymentBasis = ''
                this.shFlatAmtForBDC = false
                this.flatAmtForBDC=""
                this.shSiAmtForBDC = false
                this.siAmtForBDC=""
                this.shClaimAmtForBDC = false
                this.claimAmtForBDC=""
                this.shwAppCliamPayForBDC = false
                this.AppCliamPayBDC=""
                this.shwCoveAppDuratBDC = false
                this.CoveAppDuratBDC = ''

                this.shSiAmtForPrvBDC = false
                this.siAmtForPrvBDC = ''

                if(cmgfrmbdoc===1){
                    this.$refs.bdocForm.resetValidation()
                }

            console.log("flat---->",this.bdoccoverageBasis)
            if(this.bdoccoverageBasis== 'Over & above SI' || this.bdoccoverageBasis=='Upto SI with sub limit'){
                this.bdocsubBasisshow=true
            }else{
                console.log("%c i was in false of coverages","color:red",this.shFlatAmtForBDC,this.shSiAmtForBDC,this.shClaimAmtForBDC );
                this.bdocsubBasisshow=false   
            }
        },

        bdocflatchange(){

            this.shFlatAmtForBDC=false
            this.shSiAmtForBDC=false
            this.shClaimAmtForBDC=false
            this.shSiAmtForPrvBDC=false
            
            console.log(this.bdocsubLimitBasis,"bdocsubLimitBasis selected")
            

            if(this.bdocsubLimitBasis.length > 1){
                    this.shwAppCliamPayForBDC = true
            }else{
                this.shwAppCliamPayForBDC = false
            }

            if (this.shwAppCliamPayForBDC===false){
                this.AppCliamPayBDC=""
            }

            for(let i=0;i<=this.bdocsubLimitBasis.length;i++){
                if(this.bdocsubLimitBasis[i]==="Flat amount"){
                    this.shFlatAmtForBDC=true
                }
                if(this.bdocsubLimitBasis[i]==="% of SI"){
                    this.shSiAmtForBDC=true
                }

                if(this.bdocsubLimitBasis[i]==="% of claimed amount"){
                    this.shClaimAmtForBDC=true
                }
                if(this.bdocsubLimitBasis[i]==="% of previous yr SI"){
                    this.shSiAmtForPrvBDC=true
                }  
            }

            if (this.shFlatAmtForBDC===false){
                this.flatAmtForBDC=""
            }
            if (this.shSiAmtForBDC===false){
                this.siAmtForBDC=""

            }
            if (this.shClaimAmtForBDC===false){
                this.claimAmtForBDC=""

            }
            if (this.shSiAmtForPrvBDC===false){
                this.siAmtForPrvBDC=""

            }

        },


        chngBDCClaimPaymentBasis(){
            console.log(this.bdocclaimPaymentBasis,"bdocclaimPaymentBasis")
            this.shwCoveAppDuratBDC = false
            console.log(this.bdocclaimPaymentBasis,"bdocclaimPaymentBasis selected")
            // for(let i=0;i<=this.bdocclaimPaymentBasis.length;i++){
                if(this.bdocclaimPaymentBasis === "Duration basis"){
                    this.shwCoveAppDuratBDC = true
                }
            // }
            if(this.shwCoveAppDuratBDC === false){
                this.CoveAppDuratBDC = ''
            }
        },

        //     bdoc_save(){
            
        //      if (this.$refs.bdocForm.validate()){
        //         this.showToast("Success",this.TOST().SUCCESS);
        //         let formData={
        //         coverageCode: 'BDC',
        //         coverageType: this.bdoccoverageType,
        //         coverageBasis: this.bdoccoverageBasis,
        //         subLimitBasis: this.bdocsubLimitBasis,
        //         claimPaymentBasis: this.bdocclaimPaymentBasis,
        //         coverageApplicableDuration: this.CoveAppDuratBDC,
        //         flatAmount: this.flatAmtForBDC,
        //         perOfSi: this.siAmtForBDC,
        //         perOfClaimAmount:this.claimAmtForBDC,
        //         applicableClaimPayout: this.AppCliamPayBDC,
        //         waitingPeriod: this.bdocwaitingPeriod,
                
        //         }
        //         console.log(formData,"=========bdocForm Data")

        //     }else{
        //         this.$refs.bdocForm.validate()
        //         this.showToast("Please Fill Required Fields",this.TOST().WARNING);
        //     }
        // },


        // code to bind both NBB & BDC Array Of Objects

           babyCareSave(txt){

                if(txt==='bdc'){
                    if (!this.$refs.bdocForm.validate()){
                        this.$refs.bdocForm.validate()
                        this.showToast("Please Fill Required Fields",this.TOST().WARNING);
                        return
                    }
                }else if(txt==='nbbc'){
                   if (!this.$refs.nbbForm.validate()){
                        this.$refs.nbbForm.validate()
                        this.showToast("Please Fill Required Fields",this.TOST().WARNING);
                        return
                    } 
                }
                
                // alert("txt")
                let formData={
                    // coverageCode: 'BDC',
                    coverageType: this.bdoccoverageType,
                    coverageBasis: this.bdoccoverageBasis,
                    subLimitBasis: this.bdocsubLimitBasis,
                    claimPaymentBasis: this.bdocclaimPaymentBasis,
                    coverageApplicableDuration: this.CoveAppDuratBDC,
                    flatAmount: this.flatAmtForBDC,
                    perOfSi: this.siAmtForBDC,
                    perOfPreviousSi:this.siAmtForPrvBDC,
                    perOfClaimAmount:this.claimAmtForBDC,
                    applicableClaimPayout: this.AppCliamPayBDC,
                    // waitingPeriod: this.bdocwaitingPeriod,
                }
                console.log(formData,"=========BDCorm Data")

                let formData2={
                        // coverageCode:'NBBC',
                        coverageType: this.nbbcoverageType,
                        coverageBasis: this.nbbcoverageBasis,
                        subLimitBasis: this.nbbsubLimitBasis,
                        claimPaymentBasis: this.nbbclaimPaymentBasis,
                        coverageApplicableDuration: this.CoveAppDuratNBB,
                        flatAmount: this.flatAmtForNBB, 
                        perOfSi: this.siAmtForNBB,
                        perOfPreviousSi:this.siAmtPrvForNBB,
                        perOfClaimAmount: this.claimAmtForNBB,
                        applicableClaimPayout: this.AppCliamPayNBB,
                        waitingPeriod: this.nbbwaitingPeriod,
                }

                console.log(formData2,"=========NBBCForm Data")
                this.grpCtcVctn(formData,response=>{
                    console.log('bdc arr push is ? ',response)
                    if(txt==='bdc'){
                        this.bdcArr = []
                        if(response){
                            console.log(formData,"Before bdcArr Push")
                           formData.coverageCode="BDC"
                            this.bdcArr.push(formData)
                        }
                    }
                    console.log("fd for api is ", formData)
                        this.grpCtcVctn(formData2,res=>{
                            console.log(res)   
                        if(txt==='nbbc'){
                            this.dbbcArr = []
                            if(res){
                                console.log(formData2,"Before dbbcArr Push")
                                formData2.coverageCode="NBBC"
                                this.dbbcArr.push(formData2)
                            }
                        }
                        var finalData = [
                            ...this.bdcArr, ...this.dbbcArr
                        ]
                        console.log(finalData,"finalData")
                         let apiURL=''
                            if(this.btnName==="ConfgrPlnFrPlcy"){
                                apiURL="group-plan/update-group-bdcnbdc/"+this.plan._id
                            }else if(this.btnName==="CnfgrNwRtlPln"){
                                apiURL="retail-plan/update-retail-bdcnbdc/"+this.plan._id
                            }

                            this.PUT(1,apiURL,finalData,(res,error)=>{
                                    if(!error){
                                        console.log("PUT APi response|-> bdcnbdc ", res)
                                        if(txt==='bdc'){
                                            this.showToast("Baby Day One Care Coverage details has been updated successfully",this.TOST().SUCCESS)
                                        }else if(txt==='nbbc'){
                                            this.showToast("New Born Baby Coverage details has been updated successfully",this.TOST().SUCCESS)
                                        }
                                    }else{
                                        console.log("PUT APi error response|->bdcnbdc", error)
                                        this.showToast(error.response.data.msg ,this.TOST().ERROR);
                                    }    
                                })
                                })
                            })

        },

        // code to bind both NBB & BDC Array Of Objects ends


        // 9.nbb

        nbbchangeOnover(cmgfrmbdoc){
            this.shFlatAmtForNBB = false
            this.flatAmtForNBB=""
            this.shSiAmtForNBB = false
            this.siAmtForNBB=""
            this.shClaimAmtForNBB = false
            this.claimAmtForNBB=""
            this.shwAppCliamPayForNBB = false
            this.AppCliamPayNBB=""
            this.nbbclaimPaymentBasis = ''
            this.shwCoveAppDuratNBB=false
            this.CoveAppDuratNBB=''
            this.nbbsubLimitBasis = []
            this.shSiAmtPrvForNBB=false
            this.siAmtPrvForNBB=''

            if(cmgfrmbdoc===1){
                this.$refs.nbbForm.resetValidation()
                }
            console.log("flat---->",this.nbbcoverageBasis)
            if(this.nbbcoverageBasis==="Over & above SI"||this.nbbcoverageBasis==="Upto SI with sub limit"){
                this.nbbsubBasisshow=true
            }else{
                this.nbbsubBasisshow=false
            }
            // console.log("flat---->",this.nbbcoverageBasis)
            // if(this.nbbcoverageBasis === 'Over & above SI' || this.nbbcoverageBasis === 'Upto SI with sub limit'){
            //     this.nbbsubBasisshow=true
            // }else{
            //     this.nbbsubBasisshow=false
            // }
        },

        nbbflatchange(){
 
            this.shFlatAmtForNBB = false
            this.shSiAmtForNBB = false
            this.shClaimAmtForNBB = false
             this.shSiAmtPrvForNBB = false
            
            console.log(this.nbbsubLimitBasis,"nbbsubLimitBasis selected")
            
            if(this.nbbsubLimitBasis.length > 1){
                    this.shwAppCliamPayForNBB = true
            }else{
                this.shwAppCliamPayForNBB = false
            }

            if (this.shwAppCliamPayForNBB===false){
                this.AppCliamPayNBB=""
            }

            for(let i=0;i<=this.nbbsubLimitBasis.length;i++){
                if(this.nbbsubLimitBasis[i]==="Flat amount"){
                    this.shFlatAmtForNBB=true  
                }
                if(this.nbbsubLimitBasis[i]==="% of SI"){
                    this.shSiAmtForNBB=true
                }

                if(this.nbbsubLimitBasis[i]==="% of claimed amount"){
                    this.shClaimAmtForNBB=true
                }
                if(this.nbbsubLimitBasis[i]==="% of previous yr SI"){
                    this.shSiAmtPrvForNBB=true
                }

                
            }


            if (this.shFlatAmtForNBB===false){
                this.flatAmtForNBB=""
            }
            if (this.shSiAmtForNBB===false){
                this.siAmtForNBB=""
            }
            if (this.shClaimAmtForNBB===false){
                this.claimAmtForNBB=""
            }
             if (this.shSiAmtPrvForNBB===false){
                this.siAmtPrvForNBB=""
            }

        },

         chngNBBClaimPaymentBasis(){
            console.log(this.nbbclaimPaymentBasis,"nbbclaimPaymentBasis")
            this.shwCoveAppDuratNBB=false
            console.log(this.nbbclaimPaymentBasis,"nbbclaimPaymentBasis selected")
            // for(let i=0;i<=this.nbbclaimPaymentBasis.length;i++){
                if(this.nbbclaimPaymentBasis==="Duration basis"){
                    this.shwCoveAppDuratNBB=true
                }
            // }
            if(this.shwCoveAppDuratNBB===false){
                this.CoveAppDuratNBB=''
            }
        },

        // babyCareSave(){

        //         if (this.$refs.nbbForm.validate()){
        //         this.showToast("Success",this.TOST().SUCCESS);
        //         let formData={
        //                 coverageCode:'NBBC',
        //                 coverageType: this.nbbcoverageType,
        //                 coverageBasis: this.nbbcoverageBasis,
        //                 subLimitBasis: this.nbbsubLimitBasis,
        //                 claimPaymentBasis: this.nbbclaimPaymentBasis,
        //                 coverageApplicableDuration: this.CoveAppDuratNBB,
        //                 flatAmount: this.flatAmtForNBB, 
        //                 perOfSi: this.siAmtForNBB,
        //                 perOfClaimAmount: this.claimAmtForNBB,
        //                 applicableClaimPayout: this.AppCliamPayNBB,
        //                 waitingPeriod: this.nbbwaitingPeriod,
        //         }
        //         console.log(formData,"=========nbbForm Data")

        //     }else{
        //         this.$refs.nbbForm.validate()
        //         this.showToast("Please Fill Required Fields",this.TOST().WARNING);
        //     }
        
            
        //     //      this.$v.$touch();
                
        //     //     if(this.$v.$invalid){
        //     //          console.log("Form nbb has errors",this.$v);  
        //     //         this.showToast('Please fill all required fields', this.TOST().WARNING);
        //     //         return
        //     //     }
            
        //     //     console.log('proceed')
                
        //     //     let self=this
        //     // var formDatanbb ={
                
        //     //     coverageType: this.nbbcoverageType,
        //     //     coverageBasis: this.nbbcoverageBasis,
        //     //     subLimitBasis: this.nbbsubLimitBasis,
        //     //     claimPaymentBasis: this.nbbclaimPaymentBasis,
        //     //     flatAmount: this.nbbflatAmount,
        //     //     waitingPeriod: this.nbbwaitingPeriod,
        //     //     // perOfSi: ,
        //     //     // perOfClaimAmount: ,
                
                
        //     // }
        //     // console.log('New born baby form saved data',formDatanbb)
        // },

    //     rb_save(){

    //     if(this.btnName==="EditPlnFrPlcy" ||this.btnName==="CrteNwPlnFrPlcy"){
    //         console.log("if")
    //         this.rbeditGrpPlanById()
    //     // }else if(this.btnName==="CrteNwPlnFrPlcy"){
    //     //     console.log("else")
    //     //     this.crteGrpPlan();
    //      }else if(this.btnName==="CrteNwRtlPln" || this.btnName==="CnfgrNwRtlPln" ||this.btnName==="EditNwRtlPln"){
    //         // }else if(this.btnName==="CnfgrNwRtlPln"){
            
    //         // this.createRetailPlan()
    //                     this.rbUpdteRtlPlnById()

    //     }
    //     // else if(this.btnName==="EditNwRtlPln"){
    //     //     this.rbUpdteRtlPlnById()
    //     // }

    // },

    chngrbClaimPaymentBasis(){
            console.log(this.rbclaimPaymentBasis,"rbclaimPaymentBasis")
            this.shwrbCvrgAplcblDrtn=false
            console.log(this.rbclaimPaymentBasis,"rbclaimPaymentBasis selected")
            // for(let i=0;i<=this.rbclaimPaymentBasis.length;i++){
                if(this.rbclaimPaymentBasis==="Duration basis"){
                    this.shwrbCvrgAplcblDrtn=true
                }
            // }
        },

    chngrbSubLmtBasis(){
            this.shwrbFltAmt=false
            this.shwrbClmAmt=false
            this.shwrbPerSI=false
            this.showrbacpo=false
            this.shwrbPrvPerSI=false
            console.log(this.rbsubLimitBasis,"rbsubLimitBasis selected")
            for(let i=0;i<=this.rbsubLimitBasis.length;i++){
                if(this.rbsubLimitBasis[i]==="Flat amount"){
                    this.shwrbFltAmt=true
                }
            }
            for(let i=0;i<=this.rbsubLimitBasis.length;i++){
                if(this.rbsubLimitBasis[i]==="% of SI"){
                    this.shwrbPerSI=true
                }
            }
            for(let i=0;i<=this.rbsubLimitBasis.length;i++){
                if(this.rbsubLimitBasis[i]==="% of claimed amount"){
                    this.shwrbClmAmt=true
                }
            }
            for(let i=0;i<=this.rbsubLimitBasis.length;i++){
                if(this.rbsubLimitBasis[i]==="% of previous yr SI"){
                    this.shwrbPrvPerSI=true
                }
            }
            for(let i=0;i<=this.rbsubLimitBasis.length;i++){
                if(this.rbsubLimitBasis.length > 1){
                    this.showrbacpo=true
                }
            }
            if (this.shwrbPrvPerSI===false){
                this.rbPrvPerSI=""
            }
        },

         rbchangeOnover(cmgfrm){
            this.shwrbFltAmt=false
            this.shwrbPerSI=false
            this.shwrbClmAmt=false
            this.rbFlatAmt=""
            this.rbPerSI=""
            this.rbClmAmt=""
            this.rbclaimPaymentBasis=''
            this.shwrbCvrgAplcblDrtn=false
            this.rbCad=''
            this.rbsubLimitBasis=[]
            this.rbNoReinstatementsPermitted=''

            this.shwrbPrvPerSI=false
            this.rbPrvPerSI=''
            console.log(this.rbcoverageBasis,"coverageBasis")
            // alert("reached chngVcntnCb ")
            if(cmgfrm===1){
                this.$refs.rbForm.resetValidation()
            }
            console.log("flat---->",this.rbcoverageBasis)
            if(this.rbcoverageBasis== 'Over & above SI' || this.rbcoverageBasis== 'Upto SI with sub limit'){
                this.rbsubBasisshow=true
            }else{
                this.rbsubBasisshow=false
            }
        },
        submitRB(){
            this.RBCSelectBox == true ? this.$refs.RBCRel.save_click(event) : this.saveRBRel({btntext:event,dta:null})
        },
        saveRBRel(event){
            if (!this.$refs.rbForm.validate()){
                this.$refs.rbForm.validate()
                this.showToast("Please Fill Required Fields",this.TOST().WARNING);
                return
            }
            let formData={
                coverageCode: 'RBC',
                coverageType: this.rbcoverageType,
                coverageBasis: this.rbcoverageBasis,
                subLimitBasis: this.rbsubLimitBasis,
                claimPaymentBasis: this.rbclaimPaymentBasis,
                perOfSi: this.rbPerSI,
                perOfPreviousSi:this.rbPrvPerSI,
                perOfClaimAmount: this.rbClmAmt,   
                flatAmount: this.rbFlatAmt,
                applicableClaimPayout : this.rbapplicableClaimPayOut,
                coverageApplicableDuration: this.rbCad,
                restoreWaitingPeriod: this.rbrestoreWaitingPeriod,
                waitingPeroidType: this.rbrestoreWaitingPeriodDrop,
                noOfReinstatementsPermitted: this.rbNoReinstatementsPermitted,
                irdaUanNo: this.RBCIrdaUINNo,
                externalRefNo: this.RBCExtRefNo,
                alternateName: this.RBCCoverAltName,
                gcCoverage: this.RBCGcCover,
                exclusions: this.RBCExclus,
                remarks: this.RBCRemarks,
                conditions: this.RBCCondField,
                relationship: this.RBCSelectBox,
            }
            console.log(formData,"=========rbForm Data")
            if(this.RBCSelectBox == true){
                formData.relationShipsCovered = event.dta.relationShipsCovered
                formData.relationShipsCoveredDetails = this.relDataProcess(event.dta)
            }
            let apiURL=''
            if(this.btnName==="ConfgrPlnFrPlcy"){
                apiURL="group-plan/update-group-restore-benfit-coverage/"+this.plan._id
            }else if(this.btnName==="CnfgrNwRtlPln"){
                apiURL="retail-plan/update-retail-restore-benfit-coverage/"+this.plan._id
            }
            this.PUT(1,apiURL,formData,(res,error)=>{
                if(!error){
                    console.log("PUT APi response|-> rb ", res)
                        this.showToast("Restore Benfit Coverage has been updated successfully",this.TOST().SUCCESS)
                        this.rbDta=[]  
                        this.rbDta.push(formData)
                }else{
                    console.log("PUT APi error response|->rb", error)
                        this.showToast(error.response.data.msg,this.TOST().ERROR);
                }    
            })
        },
        
        ambcchangeOnover(cmgfrmambc){
            this.shFlatAmtForambc=false
            this.shSiAmtForambc=false
            this.shClaimAmtForambc=false
            this.flatAmtForambc=""
            this.siAmtForambc=""
            this.claimAmtForambc=""

            this.shwAppCliamPayambc=false
            this.ambcapplicableClaimPayOut=""
            this.ambcsubLimitBasis = []

            this.ambcclaimPaymentBasis = ''
            this.shwCoveAppDuratambc=false
            this.CoveAppDuratambc='' 

            this.shSiAmtPrvForambc=false
            this.siAmtPrvForambc=''

            if(cmgfrmambc===1){
                this.$refs.ambcForm.resetValidation()
              }

            console.log("flat---->",this.ambccoverageBasis)
            if(this.ambccoverageBasis== 'Over & above SI' ||this.ambccoverageBasis== 'Upto SI with sub limit'){
                this.ambcsubBasisshow=true
            }else{
                this.ambcsubBasisshow=false
                
            }
        },

        ambcsubLimitBasischange(){
            this.shFlatAmtForambc=false
            this.shSiAmtForambc=false
            this.shClaimAmtForambc=false
            this.shSiAmtPrvForambc=false
            this.shwAppCliamPayambc=false
            console.log(this.ambcsubLimitBasis,"ambcsubLimitBasis selected")
            
            
            console.log(this.ambcsubLimitBasis,"ambcsubLimitBasis selected")
            for(let i=0;i<=this.ambcsubLimitBasis.length;i++){

                if(this.ambcsubLimitBasis[i]==="Flat amount"){
                    this.shFlatAmtForambc=true  
                }

                if(this.ambcsubLimitBasis[i]==="% of SI"){
                    this.shSiAmtForambc=true
                }

                if(this.ambcsubLimitBasis[i]==="% of claimed amount"){
                    this.shClaimAmtForambc=true
                } 
                
                if(this.ambcsubLimitBasis[i]==="% of previous yr SI"){
                    this.shSiAmtPrvForambc=true
                }
            }

            if(this.ambcsubLimitBasis.length > 1){
                    this.shwAppCliamPayambc = true
            }

            if (this.shwAppCliamPayambc===false){
                this.ambcapplicableClaimPayOut=""

            }

            if (this.shFlatAmtForambc===false){
                this.flatAmtForambc=""
                

            }
            if (this.shSiAmtForambc===false){
                this.siAmtForambc=""


            }
            if (this.shClaimAmtForambc===false){
                this.claimAmtForambc=""

            }
            if (this.shSiAmtPrvForambc===false){
                this.siAmtPrvForambc=""

            }

        },


        chngambcClaimPaymentBasis(){
            console.log(this.ambcclaimPaymentBasis,"ambcclaimPaymentBasis")
            this.shwCoveAppDuratambc=false
            console.log(this.ambcclaimPaymentBasis,"ambcclaimPaymentBasis selected")
            // for(let i=0;i<=this.ambcclaimPaymentBasis.length;i++){
                if(this.ambcclaimPaymentBasis==="Duration basis"){
                    this.shwCoveAppDuratambc=true
                }
            // }
            if(this.shwCoveAppDuratambc===false){
                this.CoveAppDuratambc='' 
                
            }
        },

            ambc_save(){
                this.ACSelectBox == true ? this.$refs.ACRel.save_click(event) : this.saveACRel({btntext:event,dta:null})
            },

            saveACRel(event){

                if (this.$refs.ambcForm.validate()){
                // this.showToast("Success",this.TOST().SUCCESS);
                let formData={
                    coverageCode: "AC",
                    coverageType: this.ambccoverageType,
                    coverageBasis: this.ambccoverageBasis,
                    subLimitBasis: this.ambcsubLimitBasis,
                    claimPaymentBasis: this.ambcclaimPaymentBasis,
                    coverageApplicableDuration:this.CoveAppDuratambc,
                    flatAmount: this.flatAmtForambc,
                    perOfSi:this.siAmtForambc,
                    perOfPreviousSi:this.siAmtPrvForambc,
                    perOfClaimAmount:this.claimAmtForambc,
                    applicableClaimPayout:this.ambcapplicableClaimPayOut,
                    waitingPeriod: this.ambcwaitingPeriod,
                    waitingPeroidType:this.ACwaitingPeriodDrop,
                    irdaUanNo: this.ACIrdaUINNo,
                    externalRefNo: this.ACExtRefNo,
                    alternateName: this.ACCoverAltName,
                    gcCoverage: this.ACGcCover,
                    exclusions: this.ACExclus,
                    remarks: this.ACRemarks,
                    conditions: this.ACCondField,
                    relationship:this.ACSelectBox,
                }
                if(this.ACSelectBox == true){
                    formData.relationShipsCovered = event.dta.relationShipsCovered
                    formData.relationShipsCoveredDetails = this.relDataProcess(event.dta)
                }
                let apiURL=''
                if(this.btnName==="ConfgrPlnFrPlcy"){
                    apiURL="group-plan/update-group-ambulance-coverage/"+this.plan._id
                }else if(this.btnName==="CnfgrNwRtlPln"){
                    apiURL="retail-plan/update-retail-ambulance-coverage/"+this.plan._id
                }

                this.PUT(1,apiURL,formData,
                                ( res, error) => {
                             if(!error){
                                    console.log(res.data);
                                    this.showToast("Ambulance Coverage has been updated successfully" ,this.TOST().SUCCESS);
                                    this.ambcDta=[]  
                                          this.ambcDta.push(formData)
                            }else{
                                    console.log("when status is not 200", error.response.data.msg);
                                    this.showToast(error.response.data.msg ,this.TOST().ERROR);
                            }
                                    console.log("APi response from |-> provider/group-plan data ", res);
                                });
                console.log(formData,"=========pohForm Data")
               

            }else{
                this.$refs.ambcForm.validate()
                this.showToast("Please Fill Required Fields",this.TOST().WARNING);
            }

        },


        // 12.dcc
        dccchangeOnover(ev){
            this.showFlatAmnt = false
            this.showPercentSi = false
            this.showPercClaimAmt = false
            this.showDccApplicClaimPay = false
            this.showDccCovrageApplicDur = false
            this.showPrvPercentSi = false
            this.showDCCOpdSi = false
            this.showDCCAdSi = false
            this.DCCOpdSi = ''
            this.DCCAdSi = ''
            this.flatAmountTxtField = ''
            this.percentSI = ''
            this.PrvpercentSI = ''
            this.percentClaimAmt = ''
            this.DccApplicClaimPayOut = ''
            this.dccCovrageApplicDur = ''
            this.dccclaimPaymentBasis = ''
            this.dccsubLimitBasis = []

            ev == 1 ? this.$refs.dccForm.resetValidation() : ''

            console.log("flat---->",this.dcccoverageBasis)
            if(this.dcccoverageBasis== 'Over & above SI' ||this.dcccoverageBasis== 'Upto SI with sub limit'){
                this.dccsubBasisshow=true
            }else{
                this.dccsubBasisshow=false
                
            }
        },

        // 13.poh

        pohchangeOnover(cmgfrmpoh){
            this.shFlatAmtForPoh=false
            this.shSiAmtForPoh=false
            this.shClaimAmtForPoh=false
            this.siAmtForPoh="" 
            this.flatAmtForPoh=""
            this.claimAmtForPoh=""
            this.pohsubLimitBasis = []
            this.pohclaimPaymentBasis = ''
            this.CoveAppDuratPOH='' 
            this.shwCoveAppDuratPOH=false
            this.shwAppCliamPayPostHosp=false
            this.AppCliamPayPostHosp=""
             this.shSiAmtForPrvPoh=false
            this.siAmtForPrvPoh=""

            if(cmgfrmpoh===1){
                this.$refs.pohForm.resetValidation()
              }


            console.log("flat---->",this.pohcoverageBasis)
            if(this.pohcoverageBasis== 'Over & above SI' ||this.pohcoverageBasis== 'Upto SI with sub limit'){
                this.pohsubBasisshow=true
            }else{
                this.pohsubBasisshow=false
                
            }
        },

        chngeInPOHSubLimit(){
            this.shFlatAmtForPoh=false
            this.shSiAmtForPoh=false
            this.shClaimAmtForPoh=false
            this.shSiAmtForPrvPoh=false

             if(this.pohsubLimitBasis.length > 1){
                    this.shwAppCliamPayPostHosp = true
            }else{
                this.shwAppCliamPayPostHosp = false
            }

            if (this.shwAppCliamPayPostHosp===false){
                this.AppCliamPayPostHosp=""

            }
            
            console.log(this.pohsubLimitBasis,"pohsubLimitBasis selected")
            for(let i=0;i<=this.pohsubLimitBasis.length;i++){
                if(this.pohsubLimitBasis[i]==="Flat amount"){
                    this.shFlatAmtForPoh=true
                    
                }
                if(this.pohsubLimitBasis[i]==="% of SI"){
                    this.shSiAmtForPoh=true
                }

                if(this.pohsubLimitBasis[i]==="% of claimed amount"){
                    this.shClaimAmtForPoh=true
                }
                if(this.pohsubLimitBasis[i]==="% of previous yr SI"){
                    this.shSiAmtForPrvPoh=true
                }

                
            }
            if (this.shFlatAmtForPoh===false){
                this.flatAmtForPoh=""

            }
            if (this.shSiAmtForPoh===false){
                this.siAmtForPoh=""

            }
            if (this.shClaimAmtForPoh===false){
                this.claimAmtForPoh=""

            }
            if (this.shSiAmtForPrvPoh===false){
                this.siAmtForPrvPoh=""

            }

        },

        chngPOHClaimPaymentBasis(){
            console.log(this.pohclaimPaymentBasis,"pohclaimPaymentBasis")
            this.shwCoveAppDuratPOH=false
            console.log(this.pohclaimPaymentBasis,"pohclaimPaymentBasis selected")
            // for(let i=0;i<=this.pohclaimPaymentBasis.length;i++){
                if(this.pohclaimPaymentBasis==="Duration basis"){
                    this.shwCoveAppDuratPOH=true
                }
            // }
            if(this.shwCoveAppDuratPOH===false){
                this.CoveAppDuratPOH='' 
                
            }
        },
        poRdiSelected(){

            this.shwPoRdi=false
            this.poreldatashw=false
            if (this.pohisSelectBox){
                this.shwPoRdi=true
                this.poreldatashw=true
            }
        },


        save_po_updated({btntext,dta}){

             console.log(" save_po_updated emitted ++++++++++======>>",btntext, dta)
            if (!this.$refs.pohForm.validate()){
                console.log(" save_po_updated ++++++++++======>>",btntext, dta)
                this.$refs.pohForm.validate()
                this.showToast("Please Fill Required Fields",this.TOST().WARNING);
                return
            }

            let formData={
                    coverageCode: 'POSH',
                    coverageType:this.pohcoverageType,
                    coverageBasis:this.pohcoverageBasis,
                    subLimitBasis:this.pohsubLimitBasis,
                    claimPaymentBasis:this.pohclaimPaymentBasis,
                    coverageApplicableDuration: this.CoveAppDuratPOH,
                    waitingPeriod:this.pohwaitingPeriod,
                    waitingPeroidType:this.pohwaitingPeriodDrop,
                    flatAmount: this.flatAmtForPoh,
                    perOfSi: this.siAmtForPoh,
                    perOfPreviousSi:this.siAmtForPrvPoh,
                    perOfClaimAmount: this.claimAmtForPoh,
                    duration: this.pohduration,
                    relationship:this.pohisSelectBox,
                    applicableClaimPayout:this.AppCliamPayPostHosp,
                    irdaUanNo: this.PostHospIrdaUINNo,
                    externalRefNo: this.PostHospExtRefNo,
                    alternateName: this.PostHospCoverAltName,
                    gcCoverage: this.PostHospGcCover,
                    exclusions: this.PostHospExclus,
                    remarks: this.PostHospRemarks,
                    conditions: this.PostHospCondField,
                }
                if(this.pohisSelectBox){
                formData.relationShipsCovered=dta.relationShipsCovered
                let activedataPoh=dta.relationShipsCoveredDetails.map(e=> {
                    return{
                        active: e.active,
                        applicableValue: e.applicableValue,
                        checkbox: e.checkbox,
                        coPay: e.coPay,
                        coverType: e.coverType,
                        flatAmount:e.flatAmount,
                        roomRentLimit: e.roomRentLimit,
                        siPercent: e.siPercent,
                        siSubLimit:  e.siSubLimit,
                        waitingPeriod: e.waitingPeriod,
                    }
                })
                console.log('%c activedataPOH=>  ',"color: yellow",activedataPoh);
                formData.relationShipsCoveredDetails=activedataPoh
                    
                }
                console.log(formData,"=========Poh Form Data")
                let apiURL=''
                if(this.btnName==="ConfgrPlnFrPlcy"){
                    apiURL="group-plan/update-group-post-hospitalization/"+this.plan._id
                }else if(this.btnName==="CnfgrNwRtlPln"){
                    apiURL="retail-plan/update-retail-post-hospitalization/"+this.plan._id
                }

                this.PUT(1,apiURL,formData,
                                ( res, error) => {
                             if(!error){
                                    console.log(res.data);
                                    this.showToast("Post-Hospitalization Coverage has been updated successfully" ,this.TOST().SUCCESS);
                                    this.poDta=[]  
                                    this.poDta.push(formData)
                            }else{
                                    console.log("when status is not 200", error.response.data.msg);
                                    this.showToast(error.response.data.msg ,this.TOST().ERROR);
                            }
                                    console.log("APi response from |-> provider/group-plan data ", res);
                });

        },

        Poh_save(txt){
                if(this.pohisSelectBox){
                this.$refs.pofrmprop.save_click(txt)
            }else{
                this.save_po_updated({btntext:txt,dta:null})
            }

        },

        // 14.ph

        phchangeOnover(cmgfrm){
            this.phsubLimitBasis = []
            this.shFlatAmtForPRE=false
            this.shSiAmtForPRE=false
            this.shClaimAmtForPRE=false
            this.claimAmtForPRE="" 
            this.siAmtForPRE=""  
            this.flatAmtForPRE=""
            this.phclaimPaymentBasis = ''
            this.CoveAppDuratPreHosp=''   
            this.shwCoveAppDuratPreHosp=false
            this.shwAppCliamPayPreHosp=false
            this.AppCliamPayPreHosp=""
            this.shSiAmtForPrvPRE=false
            this.siAmtForPrvPRE=''
            if(cmgfrm===1){
                this.$refs.phForm.resetValidation()
              }
            console.log("flat---->",this.phcoverageBasis)
            if(this.phcoverageBasis== 'Over & above SI' || this.phcoverageBasis== 'Upto SI with sub limit'){
                this.phsubBasisshow=true
            }else{
                this.phsubBasisshow=false
                
            }

        },

        ph_save(txt){
            if(this.phisSelectBox){
                this.$refs.phfrmprop.save_click(txt)
            }else{
                this.save_ph_updated({btntext:txt,dta:null})
            }
        },

        save_ph_updated({btntext,dta}){
            console.log(" save_ph_updated emitted ++++++++++======>>",btntext, dta)
            if (!this.$refs.phForm.validate()){
                console.log(" save_CI_updated ++++++++++======>>",btntext, dta)
                this.$refs.phForm.validate()
                this.showToast("Please Fill Required Fields",this.TOST().WARNING);
                return
            } 
            let formData={
                coverageCode: 'PREH',
                coverageType:this.phcoverageType,
                coverageBasis:this.phcoverageBasis,
                subLimitBasis:this.phsubLimitBasis,
                claimPaymentBasis:this.phclaimPaymentBasis,
                waitingPeriod:this.phwaitingPeriod,
                waitingPeroidType:this.phwaitingPeriodDrop,
                flatAmount: this.flatAmtForPRE,            
                coverageApplicableDuration: this.CoveAppDuratPreHosp,
                perOfClaimAmount: this.claimAmtForPRE,
                perOfSi: this.siAmtForPRE,
                perOfPreviousSi:this.siAmtForPrvPRE,
                duration: this.phduration,
                relationship:this.phisSelectBox,
                applicableClaimPayout : this.AppCliamPayPreHosp,
                irdaUanNo: this.PreHospIrdaUINNo,
                externalRefNo: this.PreHospExtRefNo,
                alternateName: this.PreHospCoverAltName,
                gcCoverage: this.PreHospGcCover,
                exclusions: this.PreHospExclus,
                remarks: this.PreHospRemarks,
                conditions: this.PreHospCondField,
            }
            if(this.phisSelectBox){
                formData.relationShipsCovered=dta.relationShipsCovered
                let activedataPh=dta.relationShipsCoveredDetails.map(e=> {
                    return{
                        active: e.active,
                        applicableValue: e.applicableValue,
                        checkbox: e.checkbox,
                        coPay: e.coPay,
                        coverType: e.coverType,
                        flatAmount:e.flatAmount,
                        roomRentLimit: e.roomRentLimit,
                        siPercent: e.siPercent,
                        siSubLimit:  e.siSubLimit,
                        waitingPeriod: e.waitingPeriod,
                    }
                })
                console.log('%c activedataPH=>  ',"color: yellow",activedataPh);
                formData.relationShipsCoveredDetails=activedataPh
                }
                console.log(formData,"=========Ph Form Data")
                 let apiURL=''
                if(this.btnName==="ConfgrPlnFrPlcy"){
                    apiURL="group-plan/update-group-pre-hospitalization/"+this.plan._id
                }else if(this.btnName==="CnfgrNwRtlPln"){
                    apiURL="retail-plan/update-retail-pre-hospitalization/"+this.plan._id
                }
                this.PUT(1,apiURL,formData,
                        ( res, error) => {
                             if(!error){
                                    console.log(res.data);
                                    this.showToast("Pre-Hospitalization Coverage has been updated successfully" ,this.TOST().SUCCESS);
                                    this.prehDta=[]  
                                    this.prehDta.push(formData)
                            }else{
                                    console.log("when status is not 200", error.response.data.msg);
                                    this.showToast(error.response.data.msg ,this.TOST().ERROR);
                            }
                                    console.log("APi response from |-> provider/group-plan data ", res);
                });
        },

        chngeInPREHospSubLimit(){
            this.shFlatAmtForPRE=false
            this.shSiAmtForPRE=false
            this.shClaimAmtForPRE=false
            this.shSiAmtForPrvPRE=false
            
            console.log(this.phsubLimitBasis,"phsubLimitBasis selected")
            if(this.phsubLimitBasis.length > 1){
                    this.shwAppCliamPayPreHosp = true
            }else{
                this.shwAppCliamPayPreHosp = false
            }

            if (this.shwAppCliamPayPreHosp===false){
                this.AppCliamPayPreHosp=""

            }

            for(let i=0;i<=this.phsubLimitBasis.length;i++){
                if(this.phsubLimitBasis[i]==="Flat amount"){
                    this.shFlatAmtForPRE=true
                    
                }
                if(this.phsubLimitBasis[i]==="% of SI"){
                    this.shSiAmtForPRE=true
                }

                if(this.phsubLimitBasis[i]==="% of claimed amount"){
                    this.shClaimAmtForPRE=true
                }
                if(this.phsubLimitBasis[i]==="% of previous yr SI"){
                    this.shSiAmtForPrvPRE=true
                }

                
            }
            if (this.shFlatAmtForPRE===false){
                this.flatAmtForPRE=""

            }
            if (this.shSiAmtForPRE===false){
                this.siAmtForPRE=""

            }
            if (this.shClaimAmtForPRE===false){
                this.claimAmtForPRE=""

            }
            if (this.shSiAmtForPrvPRE===false){
                this.siAmtForPrvPRE=""

            }

        },

        chngPREHospClaimPaymentBasis(){
            console.log(this.phclaimPaymentBasis,"phclaimPaymentBasis")
            this.shwCoveAppDuratPreHosp=false
            console.log(this.phclaimPaymentBasis,"phclaimPaymentBasis selected")
            // for(let i=0;i<=this.phclaimPaymentBasis.length;i++){
                if(this.phclaimPaymentBasis==="Duration basis"){
                    this.shwCoveAppDuratPreHosp=true 
                }
            // }
            if(this.shwCoveAppDuratPreHosp===false){
                this.CoveAppDuratPreHosp=''

            }
        },

        // 15.hac

        saveHac(){

        if(this.btnName==="EditPlnFrPlcy" ||this.btnName==="CrteNwPlnFrPlcy"){
            console.log("if")
            this.haceditGrpPlanById() 
         }else if(this.btnName==="CrteNwRtlPln" || this.btnName==="CnfgrNwRtlPln" ||this.btnName==="EditNwRtlPln"){
            this.hacUpdteRtlPlnById()
        }
        
    },

        hacchangeOnover(){
            console.log("flat---->",this.haccoverageBasis)
            if(this.haccoverageBasis== 'Over & above SI' || this.haccoverageBasis== 'Upto SI with sub limit'){
                this.hacsubBasisshow=true
            }else{
                this.hacsubBasisshow=false
                
            }
        },

        Numeric2(v){

            if (v.length !== 0) {
                console.log("testing",/^[0-9 ]+$/.test(v))
                if(!/^[0-9 ]+$/.test(v)){
                    return "Only Numbers are allowed."
                }else{
                console.log("else none")
                
                }
            }
        },


        chngFtbCb(cmgfrmftb){
            
            this.shFlatAmtForFTB=false
            this.shSiAmtForFTB=false
            this.shClaimAmtForFTB=false
            this.flatAmtForFTB=""
            this.siAmtForFTB=""
            this.claimAmtForFTB=""
            this.claimPaymentBasis = ''
            this.shwCoveAppDurat=false
            this.CoveAppDurat=''
            this.subLimitBasis=[]
            this.shwAppCliamPay=false
            this.AppCliamPay=""

            this.shSiAmtPrvForFTB=false
            this.siAmtPrvForFTB=""

             if(cmgfrmftb===1){
                this.$refs.ftbForm.resetValidation()
              }


            console.log(this.coverageBasis,"coverageBasis")
            if(this.coverageBasis==="Over & above SI"||this.coverageBasis==="Upto SI with sub limit"){
                this.shwFtbSlb=true
            }else{
                this.shwFtbSlb=false
            }
        },

        chngFTBClaimPaymentBasis(){
            console.log(this.claimPaymentBasis,"claimPaymentBasis")
            this.shwCoveAppDurat=false
            console.log(this.claimPaymentBasis,"claimPaymentBasis selected")
            // for(let i=0;i<=this.claimPaymentBasis.length;i++){
                if(this.claimPaymentBasis==="Duration basis"){
                    this.shwCoveAppDurat=true
                }
            // }
            if(this.shwCoveAppDurat===false){
                this.CoveAppDurat=''
            }
        },

        chngeInFTBSubLimit(){
            this.shFlatAmtForFTB=false
            this.shSiAmtForFTB=false
            this.shClaimAmtForFTB=false
            this.shSiAmtPrvForFTB=false
            
            console.log(this.subLimitBasis,"subLimitBasis selected")
            if(this.subLimitBasis.length > 1){
                    this.shwAppCliamPay = true
            }else{
                this.shwAppCliamPay = false
            }

            if (this.shwAppCliamPay===false){
                this.AppCliamPay=""

            }

            for(let i=0;i<=this.subLimitBasis.length;i++){
                if(this.subLimitBasis[i]==="Flat amount"){
                    this.shFlatAmtForFTB=true
                    
                }
                if(this.subLimitBasis[i]==="% of SI"){
                    this.shSiAmtForFTB=true
                }

                if(this.subLimitBasis[i]==="% of claimed amount"){
                    this.shClaimAmtForFTB=true
                }
                if(this.subLimitBasis[i]==="% of previous yr SI"){
                    this.shSiAmtPrvForFTB=true
                }

                
            }
            if (this.shFlatAmtForFTB===false){
                this.flatAmtForFTB=""

            }
            if (this.shSiAmtForFTB===false){
                this.siAmtForFTB=""

            }
            if (this.shClaimAmtForFTB===false){
                this.claimAmtForFTB=""

            }
            if (this.shSiAmtPrvForFTB===false){
                this.siAmtPrvForFTB=""

            }

        },


        chngCtCb(cmgfrm){

             this.shwCtFltAmt=false
            this.shwCtPrcntSi=false
             this.shwCtPrcntClmAmt=false
             this.showCTCOpdSi = false
            this.showCTCAdSi = false
            this.CTCOpdSi = ''
            this.CTCAdSi = ''
             this.ctFlatAmt=""
             this.ctPrcntSi=""
             this.ctPrcntClmAmt=""
             this.ctClaimPaymentBasis=''
             this.shwCtCvrgAplcblDrtn=false
             this.CtCad=''
             this.ctSubLimitBasis=[]
             this.shwCtctAplcblClmPyot=false
             this.ctAplcblClmPyot=''
             this.shwCtPrcntPrvSi=false
             this.ctPrcntPrvSi=''
              if(cmgfrm===1){
                this.$refs.ctForm.resetValidation()
              }
            console.log(this.ctCoverageBasis,"coverageBasis")
            if(this.ctCoverageBasis==="Over & above SI"||this.ctCoverageBasis==="Upto SI with sub limit"){
                this.shwCtSlb=true
            }else{
                this.shwCtSlb=false
            }

        },
        chngCtSubLmtBasis(){
            this.shwCtFltAmt=false
            this.shwCtPrcntSi=false
             this.shwCtPrcntClmAmt=false
              this.shwCtctAplcblClmPyot=false
              this.shwCtPrcntPrvSi=false
              this.showCTCOpdSi = false
            this.showCTCAdSi = false
            console.log(this.ctSubLimitBasis,"ctSubLimitBasis selected")
            for(let i=0;i<=this.ctSubLimitBasis.length;i++){
                if(this.ctSubLimitBasis[i]==="Flat amount"){
                    this.shwCtFltAmt=true
                }

                if(this.ctSubLimitBasis[i]==="% of SI"){
                    this.shwCtPrcntSi=true
                }

                if(this.ctSubLimitBasis[i]==="% of claimed amount"){
                    this.shwCtPrcntClmAmt=true
                }
                if(this.ctSubLimitBasis[i]==="% of previous yr SI"){
                    this.shwCtPrcntPrvSi=true
                }
                this.ctSubLimitBasis[i] == '% of OPD SI' ? this.showCTCOpdSi = true : ''
                this.ctSubLimitBasis[i] == '% of AD SI' ? this.showCTCAdSi = true : ''
            }

            if(this.ctSubLimitBasis.length>1){
                this.shwCtctAplcblClmPyot=true
            }
            if (this.shwCtctAplcblClmPyot===false){
                this.ctAplcblClmPyot=""

            }

            if (this.shwCtFltAmt===false){
                this.ctFlatAmt=""

            }
            if (this.shwCtPrcntSi===false){
                this.ctPrcntSi=""

            }
            if (this.shwCtPrcntClmAmt===false){
                this.ctPrcntClmAmt=""

            }
             if (this.shwCtPrcntPrvSi===false){
                this.ctPrcntPrvSi=""

            }
            this.showCTCOpdSi == false ? this.CTCOpdSi = '' : ''
            this.showCTCAdSi == false ? this.CTCAdSi = '' : ''
            
                
        },
        chngCtClaimPaymentBasis(){
              console.log(this.ctClaimPaymentBasis,"ctClaimPaymentBasis")
            this.shwCtCvrgAplcblDrtn=false
            console.log(this.ctClaimPaymentBasis,"ctClaimPaymentBasis selected")
            // for(let i=0;i<=this.ctClaimPaymentBasis.length;i++){
                if(this.ctClaimPaymentBasis==="Duration basis"){
                    this.shwCtCvrgAplcblDrtn=true
                }
            // }
            if(this.shwCtCvrgAplcblDrtn===false){
                this.CtCad=''
            }
        },
        chngGcCb(){
            console.log(this.gcCoverageBasis,"coverageBasis")
            if(this.gcCoverageBasis==="Over & above SI"||this.gcCoverageBasis==="Upto SI with sub limit"){
                this.shwGcSlb=true
            }else{
                this.shwGcSlb=false
            }

        },
        chngNaSubLmtBasis(event){

            this.showNAPercentSi = false
            this.showNAFlatAmnt = false
            this.showNAPercClaimAmt = false
            this.showNAApplicClaimPay = false 
            this.showNAPrvPercentSi=false
            this.showNAOpdSi = false
            this.showNAAdSi = false

            for(let i = 0; i<= event.length; i++){
                if(event[i] !== undefined){
                    event[i] == '% of SI' ? this.showNAPercentSi = true : ''
                    event[i] == 'Flat amount' ? this.showNAFlatAmnt = true : ''
                    event[i] == '% of claimed amount' ? this.showNAPercClaimAmt = true : ''
                    event[i] == '% of previous yr SI' ? this.showNAPrvPercentSi = true : ''
                    event[i] == '% of OPD SI' ? this.showNAOpdSi = true : ''
                    event[i] == '% of AD SI' ? this.showNAAdSi = true : ''
                }
            }

            event.length > 1 ? this.showNAApplicClaimPay = true : ''
            this.showNAPercentSi == false ? this.NAPercentSI = '' : ''
            this.showNAFlatAmnt == false ? this.NAFlatAmountTxtField = '' : ''
            this.showNAPercClaimAmt == false ? this.NAPercentClaimAmt = '' : ''
            this.showNAPrvPercentSi == false ? this.NAPrvPercentSI = '' : ''
            this.showNAOpdSi == false ? this.NAOpdSi = '' : ''
            this.showNAAdSi == false ? this.NAAdSi = '' : ''
        },
        chngNaCb(ev){
            this.showNAFlatAmnt = false
            this.showNAPercentSi = false
            this.showNAPercClaimAmt = false
            this.showNAApplicClaimPay = false
            this.showNACovrageApplicDur = false
            this.showNAPrvPercentSi = false
            this.showNAOpdSi = false
            this.showNAAdSi = false
            this.NAOpdSi = ''
            this.NAAdSi = ''
            this.NAFlatAmountTxtField = ''
            this.NAPercentSI = ''
            this.NAPrvPercentSI = ''
            this.NAPercentClaimAmt = ''
            this.NAApplicClaimPayOut = ''
            this.NACovrageApplicDur = ''
            this.naClaimPaymentBasis = ''
            this.naSubLimitBasis = []
            ev == 1 ? this.$refs.naForm.resetValidation() : ''

            console.log(this.naCoverageBasis,"coverageBasis")
            if(this.naCoverageBasis==="Over & above SI"||this.naCoverageBasis==="Upto SI with sub limit"){
                this.shwNaSlb=true
            }else{
                this.shwNaSlb=false
            }
        },
        chngOpdTCb(cmgfrm){

            this.shwopdTFltAmt=false
            this.shwopdTPrcntSi=false
            this.shwopdTPrcntClmAmt=false
            this.opdTFlatAmt=""
            this.opdTPrcntSi=""
            this.opdTPrcntClmAmt=""
            this.opdTClaimPaymentBasis=''
            this.shwopdTCvrgAplcblDrtn=false
            this.opdTCad=''
            this.opdTSubLimitBasis=[]
            this.shwopdTAplcblClmPyot=false
            this.opdTAplcblClmPyot=''
            this.shwopdTPrcntPrvSi=false
            this.opdTPrcntPrvSi=''

             if(cmgfrm===1){
                this.$refs.opdTForm.resetValidation()
            }
            console.log(this.opdTCoverageBasis,"coverageBasis")
            if(this.opdTCoverageBasis==="Over & above SI"||this.opdTCoverageBasis==="Upto SI with sub limit"){
                this.shwOpdTSlb=true
            }else{
                this.shwOpdTSlb=false
            }
        },
        chngopdTSubLmtBasis(){

             this.shwopdTFltAmt=false
             this.shwopdTPrcntSi=false
             this.shwopdTPrcntClmAmt=false
              this.shwopdTAplcblClmPyot=false
              this.shwopdTPrcntPrvSi=false
            
            console.log(this.opdTSubLimitBasis,"opdTSubLimitBasis selected")
            for(let i=0;i<=this.opdTSubLimitBasis.length;i++){
                if(this.opdTSubLimitBasis[i]==="Flat amount"){
                    this.shwopdTFltAmt=true
                }
                 if(this.opdTSubLimitBasis[i]==="% of SI"){
                    this.shwopdTPrcntSi=true
                }

                if(this.opdTSubLimitBasis[i]==="% of claimed amount"){
                    this.shwopdTPrcntClmAmt=true
                }
                if(this.opdTSubLimitBasis[i]==="% of previous yr SI"){
                    this.shwopdTPrcntPrvSi=true
                }
            }
            if(this.opdTSubLimitBasis.length>1){
                this.shwopdTAplcblClmPyot=true
            }
            if (this.shwopdTAplcblClmPyot===false){
                this.opdTAplcblClmPyot=""

            }

            if (this.shwGcFltAmt===false){
                this.opdTFlatAmt=""

            }
            if (this.shwopdTPrcntSi===false){
                this.opdTPrcntSi=""

            }
            if (this.shwopdTPrcntClmAmt===false){
                this.opdTPrcntClmAmt=""

            }
            if (this.shwopdTPrcntPrvSi===false){
                this.opdTPrcntPrvSi=''

            }

        },
        chngOpdTClaimPaymentBasis(){
            console.log(this.opdTClaimPaymentBasis,"opdTClaimPaymentBasis")
            this.shwopdTCvrgAplcblDrtn=false
            console.log(this.opdTClaimPaymentBasis,"opdTClaimPaymentBasis selected")
            // for(let i=0;i<=this.opdTClaimPaymentBasis.length;i++){
                if(this.opdTClaimPaymentBasis==="Duration basis"){
                    this.shwopdTCvrgAplcblDrtn=true
                }
            // }
            if(this.shwopdTCvrgAplcblDrtn===false){
                this.opdTCad=''
            }

        },

        chngVcCb(ev){
            this.showVcFlatAmnt = false
            this.showVcPercentSi = false
            this.showVcPercClaimAmt = false
            this.showVcApplicClaimPay = false
            this.showVcCovrageApplicDur = false
            this.showVcPrvPercentSi = false
            this.showVCOpdSi = false
            this.showVCAdSi = false
            this.VCOpdSi = ''
            this.VCAdSi = ''

            this.vcFlatAmountTxtField = ''
            this.vcPercentSI = ''
            this.vcPrvPercentSI = ''
            this.vcPercentClaimAmt = ''
            this.vcApplicClaimPayOut = ''
            this.vcCovrageApplicDur = ''
            this.vcClaimPaymentBasis = ''
            this.vcSubLimitBasis = []

            ev == 1 ? this.$refs.vcForm.resetValidation() : ''
            console.log(this.vcCoverageBasis,"coverageBasis")
            if(this.vcCoverageBasis==="Over & above SI"||this.vcCoverageBasis==="Upto SI with sub limit"){
                this.shwVcSlb=true
            }else{
                this.shwVcSlb=false
            }
        },

        chngHckCb(cmgfrm){

            this.shwHckFltAmt=false
            this.shwhckPrcntSi=false
            this.shwhckPrcntClmAmt=false
            this.shwhckPrcntPrvSi=false
            this.hckPrcntPrvSi=""
            this.hckFlatAmt=""
            this.hckPrcntSi=""
            this.hckPrcntClmAmt=""
            this.hckClaimPaymentBasis=''
            this.shwhckCvrgAplcblDrtn=false
            this.hckCad=''
            this.hckSubLimitBasis=[]
            this.shwhckAplcblClmPyot=false
            this.hckAplcblClmPyot=''

            if(cmgfrm===1){
                this.$refs.hckForm.resetValidation()
            }
            console.log(this.hckCoverageBasis,"coverageBasis")
            if(this.hckCoverageBasis==="Over & above SI"||this.hckCoverageBasis==="Upto SI with sub limit"){
                this.shwHckSlb=true
            }else{
                this.shwHckSlb=false
            }
        },
        chngHckSubLmtBasis(){

            this.shwHckFltAmt=false
            this.shwhckPrcntSi=false
            this.shwhckPrcntClmAmt=false
            this.shwhckAplcblClmPyot=false
            this.shwhckPrcntPrvSi=false
            console.log(this.hckSubLimitBasis,"hckSubLimitBasis selected")
            for(let i=0;i<=this.hckSubLimitBasis.length;i++){
                if(this.hckSubLimitBasis[i]==="Flat amount"){
                    this.shwHckFltAmt=true
                }
                 if(this.hckSubLimitBasis[i]==="% of SI"){
                    this.shwhckPrcntSi=true
                }

                if(this.hckSubLimitBasis[i]==="% of claimed amount"){
                    this.shwhckPrcntClmAmt=true
                }
                if(this.hckSubLimitBasis[i]==="% of previous yr SI"){
                    this.shwhckPrcntPrvSi=true
                }
            }
            if(this.hckSubLimitBasis.length>1){
                this.shwhckAplcblClmPyot=true
            }
            if (this.shwhckAplcblClmPyot===false){
                this.hckAplcblClmPyot=""

            }

            if (this.shwHckFltAmt===false){
                this.hckFlatAmt=""

            }
            if (this.shwhckPrcntSi===false){
                this.hckPrcntSi=""

            }
            if (this.shwhckPrcntClmAmt===false){
                this.hckPrcntClmAmt=""

            }
            if (this.shwhckPrcntPrvSi===false){
                this.hckPrcntPrvSi=""

            }

        },
        chngHckClaimPaymentBasis(){
             console.log(this.hckClaimPaymentBasis,"hckClaimPaymentBasis")
            this.shwhckCvrgAplcblDrtn=false
            console.log(this.hckClaimPaymentBasis,"hckClaimPaymentBasis selected")
            // for(let i=0;i<=this.hckClaimPaymentBasis.length;i++){
                if(this.hckClaimPaymentBasis==="Duration basis"){
                    this.shwhckCvrgAplcblDrtn=true
                }
            // }
            if(this.shwhckCvrgAplcblDrtn===false){
                this.hckCad=''
            }

        },
        chngAbdCb(ev){
            this.showAccDBFlatAmnt = false
            this.showAccDBPercentSi = false
            this.showAccDBPercClaimAmt = false
            this.showAccDBApplicClaimPay = false
            this.showADBCovrageApplicDur = false
            this.showAccDBPrvPercentSi = false
            this.showADBOpdSi = false
            this.showADBAdSi = false
            this.ADBOpdSi = ''
            this.ADBAdSi = ''

            this.AccDBFlatAmountTxtField = ''
            this.AccDBpercentSI = ''
            this.AccDBPrvpercentSI = ''
            this.AccDBPercentClaimAmt = ''
            this.AccDBApplicClaimPayOut = ''
            this.ADBCovrageApplicDur = ''
            this.adbClaimPaymentBasis = ''
            this.adbSubLimitBasis = []

            ev == 1 ? this.$refs.adbForm.resetValidation() : ''
            console.log(this.adbCoverageBasis,"coverageBasis")
            if(this.adbCoverageBasis==="Over & above SI"||this.adbCoverageBasis==="Upto SI with sub limit"){
                this.shwAbdSlb=true
            }else{
                this.shwAbdSlb=false
            }
        },
        chngAbdSubLmtBasis(event){
            this.showAccDBPercentSi = false
            this.showAccDBFlatAmnt = false
            this.showAccDBPercClaimAmt = false
            this.showAccDBApplicClaimPay = false 
            this.showAccDBPrvPercentSi=false
            this.showADBOpdSi = false
            this.showADBAdSi = false

            for(let i = 0; i<= event.length; i++){
                if(event[i] !== undefined){
                    event[i] == '% of SI' ? this.showAccDBPercentSi = true : ''
                    event[i] == 'Flat amount' ? this.showAccDBFlatAmnt = true : ''
                    event[i] == '% of claimed amount' ? this.showAccDBPercClaimAmt = true : ''
                    event[i] == '% of previous yr SI' ? this.showAccDBPrvPercentSi = true : ''
                    event[i] == '% of OPD SI' ? this.showADBOpdSi = true : ''
                    event[i] == '% of AD SI' ? this.showADBAdSi = true : ''
                }
            }

            event.length > 1 ? this.showAccDBApplicClaimPay = true : ''
            this.showAccDBPercentSi == false ? this.AccDBpercentSI = '' : ''
            this.showAccDBFlatAmnt == false ? this.vcFlatAmountTxtField = '' : ''
            this.showAccDBPercClaimAmt == false ? this.vcPercentClaimAmt = '' : ''
            this.showAccDBPrvPercentSi == false ? this.AccDBPrvpercentSI = '' : ''
            this.showADBOpdSi == false ? this.ADBOpdSi = '' : ''
            this.showADBAdSi == false ? this.ADBAdSi = '' : ''

        },
        abdDBox(){
            console.log(this.abdDsaprncAplcbl,"this.abdDsaprncAplcbl selected")
            this.shwabdmxdysDsaprnc=false
            if(this.abdDsaprncAplcbl){
                this.shwabdmxdysDsaprnc=true
            }else{
                this.shwabdmxdysDsaprnc=false
            }

        },
        chngPtdCb(ev){
            this.showPTDFlatAmnt = false
            this.showPTDPercentSi = false
            this.showPTDPercClaimAmt = false
            this.showPTDApplicClaimPay = false
            this.showPTDCovrageApplicDur = false
            this.showPTDPrvPercentSi = false
            this.showPTDOpdSi = false
            this.showPTDAdSi = false

            this.PTDOpdSi = ''
            this.PTDAdSi = ''
            this.PTDFlatAmountTxtField = ''
            this.PTDpercentSI = ''
            this.PTDPrvpercentSI = ''
            this.PTDPercentClaimAmt = ''
            this.PTDApplicClaimPayOut = ''
            this.PTDCovrageApplicDur = ''
            this.ptdClaimPaymentBasis = ''
            this.ptdSubLimitBasis = []

            ev == 1 ? this.$refs.ptdForm.resetValidation() : ''

            console.log(this.ptdCoverageBasis,"coverageBasis")
            if(this.ptdCoverageBasis==="Over & above SI"||this.ptdCoverageBasis==="Upto SI with sub limit"){
                this.shwPtdSlb=true
            }else{
                this.shwPtdSlb=false
            }
        },
        chngPtdSubLmtBasis(event){
            this.showPTDPercentSi = false
            this.showPTDFlatAmnt = false
            this.showPTDPercClaimAmt = false
            this.showPTDApplicClaimPay = false 
            this.showPTDPrvPercentSi=false
            this.showPTDOpdSi = false
            this.showPTDAdSi = false

            for(let i = 0; i<= event.length; i++){
                if(event[i] !== undefined){
                    event[i] == '% of SI' ? this.showPTDPercentSi = true : ''
                    event[i] == 'Flat amount' ? this.showPTDFlatAmnt = true : ''
                    event[i] == '% of claimed amount' ? this.showPTDPercClaimAmt = true : ''
                    event[i] == '% of previous yr SI' ? this.showPTDPrvPercentSi = true : ''
                    event[i] == '% of OPD SI' ? this.showPTDOpdSi = true : ''
                    event[i] == '% of AD SI' ? this.showPTDAdSi = true : ''
                }
            }

            event.length > 1 ? this.showPTDApplicClaimPay = true : ''
            this.showPTDPercentSi == false ? this.PTDpercentSI = '' : ''
            this.showPTDFlatAmnt == false ? this.PTDFlatAmountTxtField = '' : ''
            this.showPTDPercClaimAmt == false ? this.PTDPercentClaimAmt = '' : ''
            this.showPTDPrvPercentSi == false ? this.PTDPrvpercentSI = '' : ''
            this.showPTDOpdSi == false ? this.PTDOpdSi = '' : ''
            this.showPTDAdSi == false ? this.PTDAdSi = '' : ''

        },
        chngTmrCb(cmgfrm){
            this.shwTmrFltAmt=false
            this.shwTmrPrcntSi=false
             this.shwTmrPrcntClmAmt=false
             this.shwTmrPrcntPrvSi=false
             this.tmrPrcntPrvSi=""
             this.tmrFlatAmt=""
             this.tmrPrcntSi=""
             this.tmrPrcntClmAmt=""
             this.tmrClaimPaymentBasis=''
             this.shwTmrCvrgAplcblDrtn=false
             this.tmrCad=''
             this.tmrSubLimitBasis=[]
             this.shwTmrAplcblClmPyot=false
             this.tmrAplcblClmPyot=''
            console.log(this.tmrCoverageBasis,"coverageBasis")
            if(cmgfrm===1){
                this.$refs.tmrForm.resetValidation()
              }
            if(this.tmrCoverageBasis==="Over & above SI"||this.tmrCoverageBasis==="Upto SI with sub limit"){
                this.shwTmrSlb=true
            }else{
                this.shwTmrSlb=false
            }

        },
        chngTmrSubLmtBasis(){
             this.shwTmrFltAmt=false
             this.shwTmrPrcntPrvSi=false
            console.log(this.tmrSubLimitBasis,"tmrSubLimitBasis selected")
            for(let i=0;i<=this.tmrSubLimitBasis.length;i++){
                if(this.tmrSubLimitBasis[i]==="Flat amount"){
                    this.shwTmrFltAmt=true
                }
                 if(this.tmrSubLimitBasis[i]==="% of SI"){
                    this.shwTmrPrcntSi=true
                }

                if(this.tmrSubLimitBasis[i]==="% of claimed amount"){
                    this.shwTmrPrcntClmAmt=true
                }
                if(this.tmrSubLimitBasis[i]==="% of previous yr SI"){
                    this.shwTmrPrcntPrvSi=true
                }
            }

            if(this.tmrSubLimitBasis.length>1){
                this.shwTmrAplcblClmPyot=true
            }
            if (this.shwTmrAplcblClmPyot===false){
                this.tmrAplcblClmPyot=""

            }

            if (this.shwTmrFltAmt===false){
                this.tmrFlatAmt=""

            }
            if (this.shwTmrPrcntSi===false){
                this.tmrPrcntSi=""

            }
            if (this.shwTmrPrcntClmAmt===false){
                this.tmrPrcntClmAmt=""

            }
            if (this.shwTmrPrcntPrvSi===false){
                this.tmrPrcntPrvSi=""

            }

        },
        chngTmrClaimPaymentBasis(){
            console.log(this.tmrClaimPaymentBasis,"tmrClaimPaymentBasis")
            this.shwTmrCvrgAplcblDrtn=false
            console.log(this.tmrClaimPaymentBasis,"tmrClaimPaymentBasis selected")
            // for(let i=0;i<=this.tmrClaimPaymentBasis.length;i++){
                if(this.tmrClaimPaymentBasis==="Duration basis"){
                    this.shwTmrCvrgAplcblDrtn=true
                }
            // }
            if(this.shwTmrCvrgAplcblDrtn===false){
                this.tmrCad=''
            }

        },
        chngFeCb(cmgfrm){

            this.shwFeFltAmt=false
            this.shwfePrcntSi=false
            this.shwFePrcntClmAmt=false
            this.shwfePrcntPrvSi=false
            this.fePrcntPrvSi=""
            this.feFlatAmt=""
            this.fePrcntSi=""
            this.fePrcntClmAmt=""
            this.feClaimPaymentBasis=''
            this.shwFeCvrgAplcblDrtn=false
            this.FeCad=''
            this.feSubLimitBasis=[]
            this.shwFeAplcblClmPyot=false
            this.feAplcblClmPyot=''

            if(cmgfrm===1){
                this.$refs.feForm.resetValidation()
              }
            console.log(this.feCoverageBasis,"coverageBasis")
            if(this.feCoverageBasis==="Over & above SI"||this.feCoverageBasis==="Upto SI with sub limit"){
                this.shwFeSlb=true
            }else{
                this.shwFeSlb=false
            }

        },
        chngFeSubLmtBasis(){
             this.shwFeFltAmt=false
             this.shwfePrcntSi=false
             this.shwFePrcntClmAmt=false
             this.shwFeAplcblClmPyot=false
             this.shwfePrcntPrvSi=false
            console.log(this.feSubLimitBasis,"feSubLimitBasis selected")
            for(let i=0;i<=this.feSubLimitBasis.length;i++){
                if(this.feSubLimitBasis[i]==="Flat amount"){
                    this.shwFeFltAmt=true
                }
                if(this.feSubLimitBasis[i]==="% of SI"){
                    this.shwfePrcntSi=true
                }
                 if(this.feSubLimitBasis[i]==="% of claimed amount"){
                    this.shwFePrcntClmAmt=true
                }
                if(this.feSubLimitBasis[i]==="% of previous yr SI"){
                    this.shwfePrcntPrvSi=true
                }
            }

            if(this.feSubLimitBasis.length>1){
                this.shwFeAplcblClmPyot=true
            }
            if (this.shwFeAplcblClmPyot===false){
                this.feAplcblClmPyot=""

            }

            if (this.shwFeFltAmt===false){
                this.feFlatAmt=""

            }
            if (this.shwfePrcntSi===false){
                this.fePrcntSi=""

            }
            if (this.shwFePrcntClmAmt===false){
                this.fePrcntClmAmt=""

            }
            if (this.shwfePrcntPrvSi===false){
                this.fePrcntPrvSi=""

            }


        },
        chngFeClaimPaymentBasis(){
            console.log(this.feClaimPaymentBasis,"feClaimPaymentBasis")
            this.shwFeCvrgAplcblDrtn=false
            console.log(this.feClaimPaymentBasis,"feClaimPaymentBasis selected")
            // for(let i=0;i<=this.feClaimPaymentBasis.length;i++){
                if(this.feClaimPaymentBasis==="Duration basis"){
                    this.shwFeCvrgAplcblDrtn=true
                }
            // }
            if(this.shwFeCvrgAplcblDrtn===false){
                this.FeCad=''
            }

        },
        chngEbCb(ev){
            this.showEBFlatAmnt = false
            this.showEBPercentSi = false
            this.showEBPercClaimAmt = false
            this.showEBApplicClaimPay = false
            this.showEBCovrageApplicDur = false
            this.showEBPrvPercentSi = false
            this.showEBOpdSi = false
            this.showEBAdSi = false
            this.EBOpdSi = ''
            this.EBAdSi = ''
            this.EBFlatAmountTxtField = ''
            this.EBPercentSI = ''
            this.EBPrvPercentSI = ''
            this.EBPercentClaimAmt = ''
            this.EBApplicClaimPayOut = ''
            this.EBCovrageApplicDur = ''
            this.ebClaimPaymentBasis = ''
            this.ebSubLimitBasis = []

            ev == 1 ? this.$refs.ebForm.resetValidation() : ''

            console.log(this.ebCoverageBasis,"coverageBasis")
            if(this.ebCoverageBasis==="Over & above SI"||this.ebCoverageBasis==="Upto SI with sub limit"){
                this.shwEbSlb=true
            }else{
                this.shwEbSlb=false
            }

        },
        chngEbSubLmtBasis(event){
            this.showEBPercentSi = false
            this.showEBFlatAmnt = false
            this.showEBPercClaimAmt = false
            this.showEBApplicClaimPay = false 
            this.showEBPrvPercentSi=false
            this.showEBOpdSi = false
            this.showEBAdSi = false

            for(let i = 0; i<= event.length; i++){
                if(event[i] !== undefined){
                    event[i] == '% of SI' ? this.showEBPercentSi = true : ''
                    event[i] == 'Flat amount' ? this.showEBFlatAmnt = true : ''
                    event[i] == '% of claimed amount' ? this.showEBPercClaimAmt = true : ''
                    event[i] == '% of previous yr SI' ? this.showEBPrvPercentSi = true : ''
                    event[i] == '% of OPD SI' ? this.showEBOpdSi = true : ''
                    event[i] == '% of AD SI' ? this.showEBAdSi = true : ''
                }
            }

            event.length > 1 ? this.showEBApplicClaimPay = true : ''
            this.showEBPercentSi == false ? this.EBPercentSI = '' : ''
            this.showEBFlatAmnt == false ? this.EBFlatAmountTxtField = '' : ''
            this.showEBPercClaimAmt == false ? this.EBPercentClaimAmt = '' : ''
            this.showEBPrvPercentSi == false ? this.EBPrvPercentSI = '' : ''
            this.showEBOpdSi == false ? this.EBOpdSi = '' : ''
            this.showEBAdSi == false ? this.EBAdSi = '' : ''

        },
        chngIftCb(ev){
            this.showItFlatAmnt = false
            this.showItPercentSi = false
            this.showItPercClaimAmt = false
            this.showItApplicClaimPay = false
            this.showItCovrageApplicDur = false
            this.showItPrvPercentSi = false
            this.showITOpdSi = false
            this.showITAdSi = false

            this.ItFlatAmountTxtField = ''
            this.ItPercentSI = ''
            this.ItPrvPercentSI = ''
            this.ItPercentClaimAmt = ''
            this.ItApplicClaimPayOut = ''
            this.ItCovrageApplicDur = ''
            this.iftClaimPaymentBasis = ''
            this.iftSubLimitBasis = []
            this.ITOpdSi = ''
            this.ITAdSi = ''

            ev == 1 ? this.$refs.ifForm.resetValidation() : ''
            console.log(this.iftCoverageBasis,"coverageBasis")
            if(this.iftCoverageBasis==="Over & above SI"||this.iftCoverageBasis==="Upto SI with sub limit"){
                this.shwIftSlb=true
            }else{
                this.shwIftSlb=false
            }

        },
        chngIfSubLmtBasis(event){
            this.showItPercentSi = false
            this.showItFlatAmnt = false
            this.showItPercClaimAmt = false
            this.showItApplicClaimPay = false 
            this.showItPrvPercentSi=false
            this.showITOpdSi = false
            this.showITAdSi = false

            for(let i = 0; i<= event.length; i++){
                if(event[i] !== undefined){
                    event[i] == '% of SI' ? this.showItPercentSi = true : ''
                    event[i] == 'Flat amount' ? this.showItFlatAmnt = true : ''
                    event[i] == '% of claimed amount' ? this.showItPercClaimAmt = true : ''
                    event[i] == '% of previous yr SI' ? this.showItPrvPercentSi = true : ''
                    event[i] == '% of OPD SI' ? this.showITOpdSi = true : ''
                    event[i] == '% of AD SI' ? this.showITAdSi = true : ''
                }
            }

            event.length > 1 ? this.showItApplicClaimPay = true : ''
            this.showItPercentSi == false ? this.ItPercentSI = '' : ''
            this.showItFlatAmnt == false ? this.ItFlatAmountTxtField = '' : ''
            this.showItPercClaimAmt == false ? this.ItPercentClaimAmt = '' : ''
            this.showItPrvPercentSi == false ? this.ItPrvPercentSI = '' : ''
            this.showITOpdSi == false ? this.ITOpdSi = '' : ''
            this.showITAdSi == false ? this.ITAdSi = '' : ''

        },
        changeItClaimPayBasis(event){
            // console.log(" changeDccClaimPayBasis ++++++++++======>>",event)
            this.showItCovrageApplicDur = false
            // for(let i = 0; i<= event.length; i++){
                event !== undefined ? event == 'Duration basis' ? this.showItCovrageApplicDur = true : '' : ''
            // } 
            this.showItCovrageApplicDur === false ? this.ItCovrageApplicDur = '' : ''
        },
        chngVcntnCb(cmgfrm){
             
            this.shwVcntnFltAmt=false
            this.shwVcntnPrcntSi=false
            this.shwVcntnPrcntClmAmt=false
            this.showvaccOpdSi = false
            this.showvaccAdSi = false
            this.vaccOpdSi = ''
            this.vaccAdSi = ''
            this.vcntnFlatAmt=""
            this.vcntnPrcntSi=""
            this.vcntnPrcntClmAmt=""
            this.vcntnClaimPaymentBasis=''
            this.shwVcntnCvrgAplcblDrtn=false
            this.VcntnCad=''
             this.shwVcntnPrcntPrvSi=false
            this.vcntnPrcntPrvSi=''
            this.vcntnSubLimitBasis=[]
            console.log(this.vcntnCoverageBasis,"coverageBasis")
            // alert("reached chngVcntnCb ")
            if(cmgfrm===1){
                this.$refs.vcntnForm.resetValidation()
            }
            
            
            if(this.vcntnCoverageBasis==="Over & above SI"||this.vcntnCoverageBasis==="Upto SI with sub limit"){
                // alert("from if Over & above SI ")
                this.shwVcntnSlb=true
            }else{
                //  alert("from else Over & above SI ")
                this.shwVcntnSlb=false
            }

        },
        chngVcntnSubLmtBasis(){
            this.shwVcntnFltAmt=false
            this.shwVcntnPrcntSi=false
            this.shwVcntnPrcntClmAmt=false
            this.shwvcntnAplcblClmPyot=false
            this.shwVcntnPrcntPrvSi=false
            this.showvaccOpdSi = false
            this.showvaccAdSi = false
            
            console.log(this.vcntnSubLimitBasis,"vcntnSubLimitBasis selected")
            for(let i=0;i<=this.vcntnSubLimitBasis.length;i++){
                if(this.vcntnSubLimitBasis[i]==="Flat amount"){
                    this.shwVcntnFltAmt=true
                }
                if(this.vcntnSubLimitBasis[i]==="% of SI"){
                    this.shwVcntnPrcntSi=true
                }
                if(this.vcntnSubLimitBasis[i]==="% of claimed amount"){
                    this.shwVcntnPrcntClmAmt=true
                }
                if(this.vcntnSubLimitBasis[i]==="% of previous yr SI"){
                    this.shwVcntnPrcntPrvSi=true
                }
                if(this.vcntnSubLimitBasis[i]==="% of OPD SI"){
                    this.showvaccOpdSi=true
                }
                if(this.vcntnSubLimitBasis[i]==="% of AD SI"){
                    this.showvaccAdSi=true
                }
            }

            if(this.vcntnSubLimitBasis.length>1){
                this.shwvcntnAplcblClmPyot=true
            }
            if (this.shwCtctAplcblClmPyot===false){
                this.vcntnAplcblClmPyot=""
            }
            if (this.shwVcntnFltAmt===false){
                this.vcntnFlatAmt=""
            }
            if (this.shwVcntnPrcntSi===false){
                this.vcntnPrcntSi=""
            }
            if (this.shwVcntnPrcntClmAmt===false){
                this.vcntnPrcntClmAmt=""
            }
            if (this.shwVcntnPrcntPrvSi===false){
                this.vcntnPrcntPrvSi=""
            }
            this.showvaccOpdSi == false ? this.vaccOpdSi = '' : ''
            this.showvaccAdSi == false ? this.vaccAdSi = '' : ''

        },
        chngHacCb(cmgfrm){
            this.shwHAcFltAmt=false
            this.shwHAcPerSI=false
            this.shwHAcClmAmt=false
            this.hacFlatAmt=""
            this.hacClmAmt=""
            this.hacPerSI=""
            this.hacAplcblClmPyot=""
            this.hacWaitingPeriod=""
            this.hacClaimPaymentBasis=''
            this.shwHAcCvrgAplcblDrtn=false
            this.hacCad=''
            this.shwHAcPrvPerSI=false
            this.hacPrvPerSI=''
            // this.hacCoverageType=''
            this.hacSubLimitBasis=[]
            console.log(this.hacCoverageBasis,"coverageBasis")
            // alert("reached chngVcntnCb ")
            if(cmgfrm===1){
                this.$refs.hacForm.resetValidation()
            }
            console.log(this.hacCoverageBasis,"hacCoverageBasis")
            if(this.hacCoverageBasis==="Over & above SI"||this.hacCoverageBasis==="Upto SI with sub limit"){
                this.shwHacSlb=true
            }else{
                this.shwHacSlb=false
            }
        },
        chngHacSubLmtBasis(){
            this.shwHAcFltAmt=false
            this.shwHAcClmAmt=false
            this.shwHAcPerSI=false
            this.showhacacpo=false  
            this.shwHAcPrvPerSI=false
            console.log(this.hacSubLimitBasis,"hacSubLimitBasis selected")
            for(let i=0;i<=this.hacSubLimitBasis.length;i++){
                if(this.hacSubLimitBasis[i]==="Flat amount"){
                    this.shwHAcFltAmt=true
                }
            }
            for(let i=0;i<=this.hacSubLimitBasis.length;i++){
                if(this.hacSubLimitBasis[i]==="% of SI"){
                    this.shwHAcPerSI=true
                }
            }
            for(let i=0;i<=this.hacSubLimitBasis.length;i++){
                if(this.hacSubLimitBasis[i]==="% of claimed amount"){
                    this.shwHAcClmAmt=true
                }
            }
            for(let i=0;i<=this.hacSubLimitBasis.length;i++){
                if(this.hacSubLimitBasis[i]==="% of previous yr SI"){
                    this.shwHAcPrvPerSI=true
                }
            }
            for(let i=0;i<=this.hacSubLimitBasis.length;i++){
                if(this.hacSubLimitBasis.length > 1){
                    this.showhacacpo=true
                }
            }
            if (this.shwHAcPrvPerSI===false){
                this.hacPrvPerSI=""
            }
            
        },
       chngHacClaimPaymentBasis(){
            console.log(this.hacClaimPaymentBasis,"hacClaimPaymentBasis")
            this.shwHAcCvrgAplcblDrtn=false
            console.log(this.hacClaimPaymentBasis,"hacClaimPaymentBasis selected")
            // for(let i=0;i<=this.hacClaimPaymentBasis.length;i++){
                if(this.hacClaimPaymentBasis==="Duration basis"){
                    this.shwHAcCvrgAplcblDrtn=true
                }
            // }
        },
        chngVcntnClaimPaymentBasis(){
            console.log(this.vcntnClaimPaymentBasis,"vcntnClaimPaymentBasis")
            this.shwVcntnCvrgAplcblDrtn=false
            console.log(this.vcntnClaimPaymentBasis,"vcntnClaimPaymentBasis selected")
            // for(let i=0;i<=this.vcntnClaimPaymentBasis.length;i++){
                if(this.vcntnClaimPaymentBasis==="Duration basis"){
                    this.shwVcntnCvrgAplcblDrtn=true
                }
            // }
            if(this.shwVcntnCvrgAplcblDrtn===false){
                this.VcntnCad=''
            }
        },
        chngInPtntHzptnCb(ev){
            this.showIPHFlatAmnt = false
            this.showIPHPercentSi = false
            this.showIPHPercClaimAmt = false
            this.IPHshowAppli = false
            this.showIPHCovrageApplicDur = false
            this.showIPHPrvPercentSi = false
            this.showIPHOpdSi = false
            this.showIPHAdSi = false

            this.IPHFlatAmountTxtField = ''
            this.IPHPercentSI = ''
            this.IPHPrvPercentSI = ''
            this.IPHPercentClaimAmt = ''
            this.IPHapplicableClaimPayOut = ''
            this.IPHCovrageApplicDur = ''
            this.InPtntHzptnClaimPaymentBasis = ''
            this.IPHOpdSi = ''
            this.IPHAdSi = '' 
            this.InPtntHzptnSubLimitBasis = []
            ev == 1 ? this.$refs.inPtntHzptnForm.resetValidation() : ''

            console.log(this.inPtntHzptnCoverageBasis,"inPtntHzptnCoverageBasis")
            if(this.inPtntHzptnCoverageBasis==="Over & above SI"||this.inPtntHzptnCoverageBasis==="Upto SI with sub limit"){
                this.shwInPtntHzptnSlb = true
            }else{
                this.shwInPtntHzptnSlb = false
            }
        },
        changeIPDCoverBasis(ev){
            this.showIPDFlatAmnt = false
            this.showIPDPercentSi = false
            this.showIPDPercClaimAmt = false
            this.showIPDAppli = false
            this.showIPDCovrageApplicDur = false
            this.showIPDPrvPercentSi = false
            this.showIPDOpdSi = false
            this.showIPDAdSi = false

            this.IPDFlatAmountTxtField = ''
            this.IPDPercentSI = ''
            this.IPDPrvPercentSI = ''
            this.IPDPercentClaimAmt = ''
            this.IPDapplicableClaimPayOut = ''
            this.IPDCovrageApplicDur = ''
            this.IPDClaimPaymentBasis = ''
            this.IPDOpdSi = ''
            this.IPDAdSi = ''
            this.IPDSubLimitBasis = []
            ev == 1 ? this.$refs.IPDForm.resetValidation() : ''
            console.log("((((((((       IPDCoverageBasis))))))))",this.IPDCoverageBasis)
            if(this.IPDCoverageBasis == "Over & above SI" || this.IPDCoverageBasis == "Upto SI with sub limit"){
                this.showIPDsubLimBasis = true
            }else{
                this.showIPDsubLimBasis = false
            }
        },
        changeODCopyAplcbl(){
            // console.log(this.ODCoPayapplicable,"ODCoPayapplicable")
            if(this.ODCoPayapplicable === "Yes"){
                this.showODcoPayType = true
              
            }else{
                this.showODcoPayType = false
                this.showODCoPayPrcnt = false
                this.showODCoPayPercRoom = false
                this.ODCoPayType = ''
                this.ODCoPayPrcntSR = ''
                this.ODCoPayPrcntNR = ''
            }

        },

        changeODClaimPaymentBasis(event){
            this.showODCovrageApplicDur = false
            event !== undefined ? event == 'Duration basis' ? this.showODCovrageApplicDur = true : '' : ''
            this.showODCovrageApplicDur === false ? this.ODCovrageApplicDur = '' : ''
        },

        changeODSubLmtBasis(event){
            this.showODPercentSi = false
            this.showODFlatAmnt = false
            this.showODPercClaimAmt = false
            this.showODAppli = false
            this.showODPrvPercentSi = false
            // New Fields Added 
            this.showODOpdSi = false
            this.showODAdSi = false

            for(let i = 0; i<= event.length; i++){
                if(event[i] !== undefined){
                    event[i] == '% of SI' ? this.showODPercentSi = true : ''
                    event[i] == 'Flat amount' ? this.showODFlatAmnt = true : ''
                    event[i] == '% of claimed amount' ? this.showODPercClaimAmt = true : ''
                    event[i] == '% of previous yr SI' ? this.showODPrvPercentSi = true : ''
                    // New Fields Added 
                    event[i] == '% of OPD SI' ? this.showODOpdSi = true : ''
                    event[i] == '% of AD SI' ? this.showODAdSi = true : ''
                }
            }

            event.length > 1 ? this.showODAppli = true : ''
            this.showODPercentSi == false ? this.ODPercentSI = '' : ''
            this.showODFlatAmnt == false ? this.ODFlatAmountTxtField = '' : ''
            this.showODPercClaimAmt == false ? this.ODPercentClaimAmt = '' : ''
            this.showODPrvPercentSi == false ? this.ODPrvPercentSI = '' : ''
            this.showODOpdSi == false ? this.ODOpdSi = '' : ''
            this.showODAdSi == false ? this.ODAdSi = '' : ''
        },

        changeODCoverBasis(ev){
            this.showODFlatAmnt = false
            this.showODPercentSi = false
            this.showODPercClaimAmt = false
            this.showODAppli = false
            this.showODCovrageApplicDur = false
            this.showODPrvPercentSi = false
            this.showODOpdSi = false
            this.showODAdSi = false

            this.ODFlatAmountTxtField = ''
            this.ODPercentSI = ''
            this.ODPrvPercentSI = ''
            this.ODPercentClaimAmt = ''
            this.ODapplicableClaimPayOut = ''
            this.ODCovrageApplicDur = ''
            this.ODClaimPaymentBasis = ''
            this.ODOpdSi = ''
            this.ODAdSi = ''
            this.ODSubLimitBasis = []
            ev == 1 ? this.$refs.ODForm.resetValidation() : ''

            // console.log(this.ODCoverageBasis,"ODCoverageBasis")
            if(this.ODCoverageBasis == "Over & above SI" || this.ODCoverageBasis == "Upto SI with sub limit"){
                this.showODsubLimBasis = true
            }else{
                this.showODsubLimBasis = false
            }
        },
        chngInPtntHzptnCopyAplcbl(){
            console.log(this.inPtntHzptnCoPayapplicable,"inPtntHzptnCoPayapplicable")

            if(this.inPtntHzptnCoPayapplicable === "Yes"){
                this.shwInPtntHzptnCopayTyp=true
              
            }else{
                this.shwInPtntHzptnCopayTyp=false
                this.shwInPtntHzptnCoPayPrcnt = false
                this.showCoPayPercRoom = false
                this.inPtntHzptnCoPayType = ''
                this.InPtntHzptnCoPayPrcntSr = ''
                this.InPtntHzptnCoPayPrcntNr = ''
            }

        },
        changeIPDCopyAplcbl(){
            // console.log(this.IPDCoPayapplicable,"IPDCoPayapplicable")
            if(this.IPDCoPayapplicable === "Yes"){
                this.showIPDcoPayType = true
              
            }else{
                this.showIPDcoPayType = false
                this.showIPDCoPayPrcnt = false
                this.showIPDCoPayPercRoom = false
                this.IPDCoPayType = ''
                this.IPDCoPayPrcntSR = ''
                this.IPDCoPayPrcntNR = ''
            }

        },
        changeIPDCoPayPrcnt(ev){
            if(this.IPDCoPayType == "Percentage"){
                this.showIPDCoPayPrcnt = true
                this.showIPDCoPayPercRoom = false
                this.IPDCoPayPrcntSR = ''
                this.IPDCoPayPrcntNR = ''
            }else{
                this.showIPDCoPayPrcnt = false
                this.showIPDCoPayPercRoom = true
            }
        },
        changeODCoPayPrcnt(){
            console.log(this.showODcoPayType ," howODcoPayType ")             
            // showCoPayPercRoom
            // Basis room category
            if(this.ODCoPayType == "Percentage"){
                this.showODCoPayPrcnt = true
                this.showODCoPayPercRoom = false
                this.ODCoPayPrcntSR = ''
                this.ODCoPayPrcntNR = ''
            }else{
                this.showODCoPayPrcnt = false
                this.showODCoPayPercRoom = true
            }
        },
        chngInPtntHzptnCoPayPrcnt(){
            console.log(this.inPtntHzptnCoPayType,"inPtntHzptnCoPayType")
            // showCoPayPercRoom
            // Basis room category
            if(this.inPtntHzptnCoPayType==="Percentage"){
                this.shwInPtntHzptnCoPayPrcnt = true
                this.showCoPayPercRoom = false
                this.InPtntHzptnCoPayPrcntSr = ''
                this.InPtntHzptnCoPayPrcntNr = ''
            }else{
                this.shwInPtntHzptnCoPayPrcnt = false
                this.showCoPayPercRoom = true
            }


        },

        changeBSCCopyAplcbl(){
            // console.log(this.BSCCoPayapplicable,"BSCCoPayapplicable")
            if(this.BSCCoPayapplicable === "Yes"){
                this.showBSCcoPayType = true
              
            }else{
                this.showBSCcoPayType = false
                this.showBSCCoPayPrcnt = false
                this.showBSCCoPayPercRoom = false
                this.BSCCoPayType = ''
                this.BSCCoPayPrcntSR = ''
                this.BSCCoPayPrcntNR = ''
            }

        },

        changeBSCClaimPaymentBasis(event){
            this.showBSCCovrageApplicDur = false
            event !== undefined ? event == 'Duration basis' ? this.showBSCCovrageApplicDur = true : '' : ''
            this.showBSCCovrageApplicDur === false ? this.BSCCovrageApplicDur = '' : ''
        },

        changeBSCSubLmtBasis(event){
            this.showBSCPercentSi = false
            this.showBSCFlatAmnt = false
            this.showBSCPercClaimAmt = false
            this.showBSCAppli = false
            this.showBSCPrvPercentSi = false
            // New Fields Added 
            this.showBSCOpdSi = false
            this.showBSCAdSi = false

            for(let i = 0; i<= event.length; i++){
                if(event[i] !== undefined){
                    event[i] == '% of SI' ? this.showBSCPercentSi = true : ''
                    event[i] == 'Flat amount' ? this.showBSCFlatAmnt = true : ''
                    event[i] == '% of claimed amount' ? this.showBSCPercClaimAmt = true : ''
                    event[i] == '% of previous yr SI' ? this.showBSCPrvPercentSi = true : ''
                    // New Fields Added 
                    event[i] == '% of OPD SI' ? this.showBSCOpdSi = true : ''
                    event[i] == '% of AD SI' ? this.showBSCAdSi = true : ''
                }
            }

            event.length > 1 ? this.showBSCAppli = true : ''
            this.showBSCPercentSi == false ? this.BSCPercentSI = '' : ''
            this.showBSCFlatAmnt == false ? this.BSCFlatAmountTxtField = '' : ''
            this.showBSCPercClaimAmt == false ? this.BSCPercentClaimAmt = '' : ''
            this.showBSCPrvPercentSi == false ? this.BSCPrvPercentSI = '' : ''
            this.showBSCOpdSi == false ? this.BSCOpdSi = '' : ''
            this.showBSCAdSi == false ? this.BSCAdSi = '' : ''
        },

        changeBSCCoverBasis(ev){
            this.showBSCFlatAmnt = false
            this.showBSCPercentSi = false
            this.showBSCPercClaimAmt = false
            this.showBSCAppli = false
            this.showBSCCovrageApplicDur = false
            this.showBSCPrvPercentSi = false
            this.showBSCOpdSi = false
            this.showBSCAdSi = false

            this.BSCFlatAmountTxtField = ''
            this.BSCPercentSI = ''
            this.BSCPrvPercentSI = ''
            this.BSCPercentClaimAmt = ''
            this.BSCapplicableClaimPayOut = ''
            this.BSCCovrageApplicDur = ''
            this.BSCClaimPaymentBasis = ''
            this.BSCOpdSi = ''
            this.BSCAdSi = ''
            this.BSCSubLimitBasis = []
            ev == 1 ? this.$refs.BSCForm.resetValidation() : ''

            // console.log(this.BSCCoverageBasis,"BSCCoverageBasis")
            if(this.BSCCoverageBasis == "Over & above SI" || this.BSCCoverageBasis == "Upto SI with sub limit"){
                this.showBSCsubLimBasis = true
            }else{
                this.showBSCsubLimBasis = false
            }
        },
        changeBSCCoPayPrcnt(){
            if(this.BSCCoPayType == "Percentage"){
                this.showBSCCoPayPrcnt = true
                this.showBSCCoPayPercRoom = false
                this.BSCCoPayPrcntSR = ''
                this.BSCCoPayPrcntNR = ''
            }else{
                this.showBSCCoPayPrcnt = false
                this.showBSCCoPayPercRoom = true
            }
        },
        submitFTB(){
            this.FTBSelectBox == true ? this.$refs.FTBRel.save_click(event) : this.saveFTBRel({btntext:event,dta:null})
        },

        saveFTBRel(event){
            if (!this.$refs.ftbForm.validate()){
                this.$refs.ftbForm.validate()
                this.showToast("Please Fill Required Fields",this.TOST().WARNING);
                return
            } 
            let formData={
                coverageCode: 'FTB',
                coverageType:this.coverageType,
                coverageBasis:this.coverageBasis,
                subLimitBasis:this.subLimitBasis,
                claimPaymentBasis:this.claimPaymentBasis,
                applicableClaimPayout: this.AppCliamPay,
                coverageApplicableDuration: this.CoveAppDurat,
                waitingPeriod:this.FTBwaitingPeriod,
                waitingPeroidType:this.FTBwaitingPeriodDrop,
                minEligibleDistanceFromInsuredResidence:this.MnElgblDstnceFrmInsrdRsdnc,
                AllowedCountOfImmediateFamilyMember:this.AlwdCntofImdteFmlyMmbr,
                flatAmount: this.flatAmtForFTB,
                perOfSi: this.siAmtForFTB,
                perOfPreviousSi:this.siAmtPrvForFTB,
                perOfClaimAmount: this.claimAmtForFTB,
                relationship: this.FTBSelectBox,
                irdaUanNo: this.FTBIrdaUINNo,
                externalRefNo: this.FTBExtRefNo,
                alternateName: this.FTBCoverAltName,
                gcCoverage: this.FTBGcCover,
                exclusions: this.FTBExclus,
                remarks: this.FTBRemarks,
                conditions: this.FTBCondField,

            }

            if(this.FTBSelectBox == true){
                formData.relationShipsCovered = event.dta.relationShipsCovered
                formData.relationShipsCoveredDetails = this.relDataProcess(event.dta)
            }

            let apiURL = ''
            if(this.btnName == "ConfgrPlnFrPlcy"){
                apiURL = "group-plan/update-group-ftb/" + this.plan._id
                
            }else if(this.btnName == "CnfgrNwRtlPln"){

                apiURL = "retail-plan/update-retail-ftb/" + this.plan._id
            }
            this.PUT(1,apiURL,formData,(res,error)=>{
                if(!error){
                    this.showToast("Family Transportation Benefit Coverage details has been updated successfully",this.TOST().SUCCESS)
                    this.ftbDta=[]
                    this.ftbDta.push(formData)
                }else{
                    this.showToast(error.response.data.msg ,this.TOST().ERROR);
                }    
            })
        },
        saveTMRFERel(event ){
            if(event.btntext ==='FE'){
                if (!this.$refs.feForm.validate()){
                    this.$refs.feForm.validate()
                    this.showToast("Please Fill Required Fields",this.TOST().WARNING);
                    return
                }
            }else if(event.btntext ==='TMR'){
                if (!this.$refs.tmrForm.validate()){
                    this.$refs.tmrForm.validate()
                    this.showToast("Please Fill Required Fields",this.TOST().WARNING);
                    return
                } 
            }
            
            let formData={
                // coverageCode: "TMR",
                coverageType:this.tmrCoverageType,
                coverageBasis:this.tmrCoverageBasis,
                subLimitBasis:this.tmrSubLimitBasis,
                claimPaymentBasis:this.tmrClaimPaymentBasis,
                flatAmount:this.tmrFlatAmt,
                perOfSi: this.tmrPrcntSi,
                perOfPreviousSi:this.tmrPrcntPrvSi,
                perOfClaimAmount: this.tmrPrcntClmAmt,
                applicableClaimPayout: this.tmrAplcblClmPyot,
                coverageApplicableDuration: this.tmrCad,
                irdaUanNo: this.TMRIrdaUINNo,
                externalRefNo: this.TMRExtRefNo,
                alternateName: this.TMRCoverAltName,
                gcCoverage: this.TMRGcCover,
                exclusions: this.TMRExclus,
                remarks: this.TMRRemarks,
                conditions: this.TMRCondField,
                relationship: this.TMRSelectBox,
                waitingPeriod:this.TMRwaitingPeriod,
                waitingPeroidType:this.TMRwaitingPeriodDrop,
            }
            if(this.TMRSelectBox == true){
                formData.relationShipsCovered = event.dta.relationShipsCovered
                formData.relationShipsCoveredDetails = this.relDataProcess(event.dta)
            }

            let formData2={
                // coverageCode: "FE",
                coverageType:this.feCoverageType,
                coverageBasis:this.feCoverageBasis,
                subLimitBasis:this.feSubLimitBasis,
                claimPaymentBasis:this.feClaimPaymentBasis,
                flatAmount:this.feFlatAmt,
                perOfSi:this.fePrcntSi,
                perOfPreviousSi:this.fePrcntPrvSi,
                perOfClaimAmount:this.fePrcntClmAmt,
                applicableClaimPayout:this.feAplcblClmPyot,
                coverageApplicableDuration: this.FeCad,
                irdaUanNo: this.FEIrdaUINNo,
                externalRefNo: this.FEExtRefNo,
                alternateName: this.FECoverAltName,
                gcCoverage: this.FEGcCover,
                exclusions: this.FEExclus,
                remarks: this.FERemarks,
                conditions: this.FECondField,
                waitingPeriod:this.FEwaitingPeriod,
                waitingPeroidType:this.FEwaitingPeriodDrop,
                relationship: this.FESelectBox,
                
            }
            if(this.FESelectBox == true){
                formData2.relationShipsCovered = event.dta.relationShipsCovered
                formData2.relationShipsCoveredDetails = this.relDataProcess(event.dta)
            }
            this.grpCtcVctn(formData,response=>{
                if(event.btntext == 'TMR'){
                    this.tmrData = []
                    if(response){
                        formData.coverageCode = "TMR"
                        this.tmrData.push(formData)
                    }
                }else{
                    if(this.TMRSelectBox){
                        this.tmrData[0].relationShipsCoveredDetails = this.mapRelFrmDta(this.tmrData[0].relationShipsCoveredDetails)
                    }
                }

                this.grpCtcVctn(formData2,res=>{
                    if(event.btntext == 'FE'){
                        this.feData = []
                        if(res){
                            formData2.coverageCode = "FE"
                            this.feData.push(formData2)
                        }
                    }else{
                        if(this.FESelectBox){
                            this.feData[0].relationShipsCoveredDetails = this.mapRelFrmDta(this.feData[0].relationShipsCoveredDetails)
                        }
                    }
                    var finalData = [
                        ...this.tmrData, ...this.feData
                    ]
                    let apiURL=''
                    if(this.btnName==="ConfgrPlnFrPlcy"){
                        apiURL="group-plan/update-group-tmrfe/"+this.plan._id
                    }else if(this.btnName==="CnfgrNwRtlPln"){
                        apiURL="retail-plan/update-retail-tmrfe/"+this.plan._id
                    }

                    this.PUT(1,apiURL,finalData,(res,error)=>{
                        if(!error){
                            if(event.btntext ==='FE'){
                                this.showToast("Funeral Expenses Coverage details has been updated successfully",this.TOST().SUCCESS)
                            }else if(event.btntext ==='TMR'){
                                this.showToast("Transportation of Mortal Remains Coverage details has been updated successfully",this.TOST().SUCCESS)
                            }
                        }else{
                            this.showToast(error.response.data.msg ,this.TOST().ERROR);
                        }    
                    })
                })
            })

        },
        submitTMRFE(event){
            if(event == 'TMR'){
                this.TMRSelectBox == true ? this.$refs.TMRRel.save_click(event) : this.saveTMRFERel({btntext:event,dta:null})
                
            }else if(event == 'FE'){
                this.FESelectBox == true ? this.$refs.FERel.save_click(event) : this.saveTMRFERel({btntext:event,dta:null})
                
            }
        },
        save_GcCi(txt){
            if(txt==='CI'){

                if(this.ciisSelectBox){
                    this.$refs.ciFrmPropUpdted.save_click(txt)
                }else{
                   this.save_CI_updated({btntext:txt,dta:null})
                }
            }else if(txt==='GC'){

                if(this.gccheckbox){
                    this.$refs.gcfrmprop.save_click(txt)
                }else{
                   this.save_CI_updated({btntext:txt,dta:null})
                }
            }
             
        },

        submitOthcotdea(event){
            if(event == 'OPDT'){
                this.OPDTSelectBox == true ? this.$refs.OPDTRel.save_click(event) : this.saveOthcotdea({btntext:event,dta:null})
                
            }else if(event == 'HCUC'){
                this.HCUCSelectBox == true ? this.$refs.HCUCRel.save_click(event) : this.saveOthcotdea({btntext:event,dta:null})
                
            }else if(event == 'OPDDT'){
                this.ODTSelectBox == true ? this.$refs.ODTRel.save_click(event) : this.saveOthcotdea({btntext:event,dta:null})
                
            }else if(event == 'EAA'){
                this.EAASelectBox == true ? this.$refs.EAARel.save_click(event) : this.saveOthcotdea({btntext:event,dta:null})
                
            }
        },
        saveOthcotdea(event){
            let self = this
            if(event.btntext==='OPDT'){
                if (!this.$refs.opdTForm.validate()){
                    this.$refs.opdTForm.validate()
                    this.showToast("Please Fill Required Fields",this.TOST().WARNING);
                    return
                }
            }else if(event.btntext==='HCUC'){
                if (!this.$refs.hckForm.validate()){
                    this.$refs.hckForm.validate()
                    this.showToast("Please Fill Required Fields",this.TOST().WARNING);
                    return
                } 
            }else if(event.btntext==='OPDDT'){
                if (!this.$refs.odtForm.validate()){
                    this.$refs.odtForm.validate()
                    this.showToast("Please Fill Required Fields",this.TOST().WARNING);
                    return
                } 
            }else if(event.btntext==='EAA'){
                if (!this.$refs.eaaForm.validate()){
                    this.$refs.eaaForm.validate()
                    this.showToast("Please Fill Required Fields",this.TOST().WARNING);
                    return
                } 
            }

            let formData={
                // coverageCode: "OPDT",
                coverageType:this.opdTCoverageType,
                coverageBasis:this.opdTCoverageBasis,
                subLimitBasis:this.opdTSubLimitBasis,
                claimPaymentBasis:this.opdTClaimPaymentBasis,
                coverageApplicableDuration: this.opdTCad,
                flatAmount:this.opdTFlatAmt,
                perOfSi: this.opdTPrcntSi,
                perOfPreviousSi:this.opdTPrcntPrvSi,
                perOfClaimAmount: this.opdTPrcntClmAmt,
                applicableClaimPayout: this.opdTAplcblClmPyot,
                waitingPeriod:this.opdTWtngPrd,
                waitingPeroidType:this.OPDTwaitingPeriodDrop,
                irdaUanNo: this.OPDTIrdaUINNo,
                externalRefNo: this.OPDTExtRefNo,
                alternateName: this.OPDTCoverAltName == undefined ? '' : this.OPDTCoverAltName,
                gcCoverage: this.OPDTGcCover,
                exclusions: this.OPDTExclus,
                remarks: this.OPDTRemarks,
                conditions: this.OPDTCondField,
                relationship: this.OPDTSelectBox,
                noOfClaimFreeYears: '',
                
            }
            if(this.OPDTSelectBox == true){
                formData.relationShipsCovered = event.dta.relationShipsCovered
                formData.relationShipsCoveredDetails = this.relDataProcess(event.dta)
            }

            console.log(formData,"=========OPDT Form Data")

            let formData2={
                // coverageCode: "HCUC",
                coverageType:this.hckCoverageType,
                coverageBasis:this.hckCoverageBasis,
                subLimitBasis:this.hckSubLimitBasis,
                claimPaymentBasis:this.hckClaimPaymentBasis,
                coverageApplicableDuration: this.hckCad,
                flatAmount:this.hckFlatAmt,
                perOfSi: this.hckPrcntSi,
                perOfPreviousSi:this.hckPrcntPrvSi,
                perOfClaimAmount: this.hckPrcntClmAmt,
                applicableClaimPayout: this.hckAplcblClmPyot,
                waitingPeriod:this.hckWaitingPeriod,
                waitingPeroidType:this.HCUCwaitingPeriodDrop,
                irdaUanNo: this.HCUCIrdaUINNo,
                externalRefNo: this.HCUCExtRefNo,
                alternateName: this.HCUCCoverAltName == undefined ? '' : this.HCUCCoverAltName,
                gcCoverage: this.HCUCGcCover,
                exclusions: this.HCUCExclus,
                remarks: this.HCUCRemarks,
                conditions: this.HCUCCondField,
                relationship: this.HCUCSelectBox,
                noOfClaimFreeYears: this.HCUCNoClaimFreeYrs,
                
            }
            if(this.HCUCSelectBox == true){
                formData2.relationShipsCovered = event.dta.relationShipsCovered
                formData2.relationShipsCoveredDetails = this.relDataProcess(event.dta)
            }
            console.log(formData2,"=========HCUC Form Data")

            let formData3={
                // coverageCode: "OPDDT",
                coverageType:this.odtcoverageType,
                coverageBasis:this.odtcoverageBasis,
                subLimitBasis:this.odtsubLimitBasis,
                claimPaymentBasis:this.odtclaimPaymentBasis,
                coverageApplicableDuration: this.odtCad,
                flatAmount:this.odtflatAmount,
                perOfSi: this.odtPrcntSi,
                perOfPreviousSi:this.odtPrcntPrvSi,
                perOfClaimAmount: this.odtPrcntClmAmt,
                applicableClaimPayout: this.odtAplcblClmPyot,
                waitingPeriod:this.odtwaitingPeriod,
                waitingPeroidType:this.odtwaitingPeriodDrop,
                irdaUanNo: this.ODTIrdaUINNo,
                externalRefNo: this.ODTExtRefNo,
                alternateName: this.ODTCoverAltName == undefined ? '' : this.ODTCoverAltName,
                gcCoverage: this.ODTGcCover,
                exclusions: this.ODTExclus,
                remarks: this.ODTRemarks,
                conditions: this.ODTCondField,
                relationship: this.ODTSelectBox,
                noOfClaimFreeYears: '',

                
            }
            if(this.ODTSelectBox == true){
                formData3.relationShipsCovered = event.dta.relationShipsCovered
                formData3.relationShipsCoveredDetails = this.relDataProcess(event.dta)
            }
            console.log(formData3,"=========OPDDT Form Data")
            let formData4={
                // coverageCode: "EAA",
                coverageType:this.eaacoverageType,
                coverageBasis:this.eaacoverageBasis,
                subLimitBasis:this.eaasubLimitBasis,
                claimPaymentBasis:this.eaaclaimPaymentBasis,
                coverageApplicableDuration: this.eaaCad,
                flatAmount:this.eaaflatAmount,
                perOfSi: this.eaaPrcntSi,
                perOfPreviousSi:this.eaaPrcntPrvSi,
                perOfClaimAmount: this.eaaPrcntClmAmt,
                applicableClaimPayout: this.eaaAplcblClmPyot,
                waitingPeriod:this.eaawaitingPeriod,
                waitingPeroidType:this.EAAwaitingPeriodDrop,
                irdaUanNo: this.EAAIrdaUINNo,
                externalRefNo: this.EAAExtRefNo,
                alternateName: this.EAACoverAltName == undefined ? '' : this.EAACoverAltName,
                gcCoverage: this.EAAGcCover,
                exclusions: this.EAAExclus,
                remarks: this.EAARemarks,
                conditions: this.EAACondField,
                relationship: this.EAASelectBox,
                noOfClaimFreeYears: '',

                
            }
            if(this.EAASelectBox == true){
                formData4.relationShipsCovered = event.dta.relationShipsCovered
                formData4.relationShipsCoveredDetails = this.relDataProcess(event.dta)
            }
            console.log(formData4,"=========EAA Form Data")
            self.grpCtcVctn(formData,response=>{
                if(event.btntext == 'OPDT'){
                    self.OpdtDta = []
                    if(response){
                        formData.coverageCode="OPDT"
                        self.OpdtDta.push(formData)
                    }
                }else{
                    if(self.OPDTSelectBox){
                        self.OpdtDta[0].relationShipsCoveredDetails = self.mapRelFrmDta(self.OpdtDta[0].relationShipsCoveredDetails)
                    }
                }
                self.grpCtcVctn(formData2,res2=>{
                    if(event.btntext == 'HCUC'){
                        self.HcucDta=[]
                        if(res2){
                            formData2.coverageCode="HCUC"
                            self.HcucDta.push(formData2)
                        }
                    }else{
                        if(self.HCUCSelectBox){
                            self.HcucDta[0].relationShipsCoveredDetails = self.mapRelFrmDta(self.HcucDta[0].relationShipsCoveredDetails)
                        }
                    }
                    self.grpCtcVctn(formData3,res3=>{
                        if(event.btntext == 'OPDDT'){
                            self.opddtDta=[]
                            if(res3){
                                formData3.coverageCode="OPDDT"
                                self.opddtDta.push(formData3)
                            }
                        }else{
                            if(self.ODTSelectBox){
                                self.opddtDta[0].relationShipsCoveredDetails = self.mapRelFrmDta(self.opddtDta[0].relationShipsCoveredDetails)
                            }
                        }
                        self.grpCtcVctn(formData4,res4=>{
                            if(event.btntext == 'EAA'){
                                self.eaaDta=[]
                                if(res4){
                                    formData4.coverageCode="EAA"
                                    self.eaaDta.push(formData4)
                                }
                            }else{
                                if(self.ODTSelectBox){
                                    self.eaaDta[0].relationShipsCoveredDetails = self.mapRelFrmDta(self.eaaDta[0].relationShipsCoveredDetails)
                                }
                            }
                            var finalData = [
                                ...self.OpdtDta, ...self.HcucDta, ...self.opddtDta,...self.eaaDta
                            ]
                            console.log(finalData,"finalData")
    
                            let apiURL=''
                            if(self.btnName==="ConfgrPlnFrPlcy"){
                                apiURL="group-plan/update-group-othcotdea/"+self.plan._id
                            }else if(self.btnName==="CnfgrNwRtlPln"){
                                apiURL="retail-plan/update-retail-othcotdea/"+self.plan._id
                            }
                            self.PUT(1,apiURL,finalData,(res,error)=>{
                                if(!error){
                                    console.log("PUT APi response|-> ctcvac ", res)
                                    if(event.btntext==='OPDT'){
                                        self.showToast("OPD Treatment Coverage details has been updated successfully",self.TOST().SUCCESS)
                                    }else if(event.btntext==='HCUC'){
                                        self.showToast("Health Check-up Coverage Coverage details has been updated successfully",self.TOST().SUCCESS)
                                    }else if(event.btntext==='OPDDT'){
                                        self.showToast("OPD Dental Treatment Coverage details has been updated successfully",self.TOST().SUCCESS)
                                    }else if(event.btntext==='EAA'){
                                        self.showToast("Emergency Air Ambulance Coverage details has been updated successfully",self.TOST().SUCCESS)
                                    }

                                }else{
                                    console.log("PUT APi error response|->ctcvac", error)
                                    self.showToast(error.response.data.msg ,self.TOST().ERROR);
                                }    
                            })
                        })
                    })

                })
            })
        },


        saveCTCVC(event){
            if(event.btntext == 'ct'){
                if (!this.$refs.ctForm.validate()){
                    this.$refs.ctForm.validate()
                    this.showToast("Please Fill Required Fields",this.TOST().WARNING);
                    return
                }
            }else if(event.btntext == 'vctn'){
                if (!this.$refs.vcntnForm.validate()){
                    this.$refs.vcntnForm.validate()
                    this.showToast("Please Fill Required Fields",this.TOST().WARNING);
                    return
                } 
            }
            
            let formData={
                // coverageCode: "CTC",
                coverageType:this.ctCoverageType,
                coverageBasis:this.ctCoverageBasis,
                subLimitBasis:this.ctSubLimitBasis,
                claimPaymentBasis:this.ctClaimPaymentBasis,
                waitingPeriod:this.ctWaitingPeriod,
                waitingPeroidType:this.CTCwaitingPeriodDrop,
                flatAmount:this.ctFlatAmt,
                perOfSi: this.ctPrcntSi,
                perOfPreviousSi:this.ctPrcntPrvSi,
                perOfOpdSi: this.CTCOpdSi,
                perOfAdSi: this.CTCAdSi,
                perOfClaimAmount: this.ctPrcntClmAmt,
                applicableClaimPayout: this.ctAplcblClmPyot,
                coverageApplicableDuration: this.CtCad,
                minHospitalization:this.ctMnmHspitlztnDrtn,
                irdaUanNo: this.CTCIrdaUINNo,
                externalRefNo: this.CTCExtRefNo,
                alternateName: this.CTCCoverAltName,
                gcCoverage: this.CTCGcCover,
                exclusions: this.CTCExclus,
                remarks: this.CTCRemarks,
                conditions: this.CTCCondField,
                relationship: this.CTCSelectBox,
            }
            if(this.CTCSelectBox == true){
                formData.relationShipsCovered = event.dta.relationShipsCovered
                formData.relationShipsCoveredDetails = this.relDataProcess(event.dta)
            }
            console.log(formData,"=========CTForm Data")

            let formData2={
                // coverageCode: "VAC",
                coverageType:this.vcntnCoverageType,
                coverageBasis:this.vcntnCoverageBasis,
                subLimitBasis:this.vcntnSubLimitBasis,
                claimPaymentBasis:this.vcntnClaimPaymentBasis,
                waitingPeriod:this.vcntnWaitingPeriod,
                waitingPeroidType:this.vaccwaitingPeriodDrop,
                flatAmount:this.vcntnFlatAmt,
                perOfSi:this.vcntnPrcntSi,
                perOfPreviousSi:this.vcntnPrcntPrvSi,
                perOfOpdSi: this.vaccOpdSi,
                perOfAdSi: this.vaccAdSi,
                perOfClaimAmount:this.vcntnPrcntClmAmt,
                applicableClaimPayout:this.vcntnAplcblClmPyot,
                coverageApplicableDuration: this.VcntnCad,
                // minHospitalization:'',
                eligibleDuration:this.vcntnElglblDrtn,
                irdaUanNo: this.vaccIrdaUINNo,
                externalRefNo: this.vaccExtRefNo,
                alternateName: this.vaccCoverAltName,
                gcCoverage: this.vaccGcCover,
                exclusions: this.vaccExclus,
                remarks: this.vaccRemarks,
                conditions: this.vaccCondField,
                relationship: this.vaccSelectBox,
                
            }
          
            if(this.vaccSelectBox == true){
                formData2.relationShipsCovered = event.dta.relationShipsCovered
                formData2.relationShipsCoveredDetails = this.relDataProcess(event.dta)
            }
            console.log(formData2,"=========vcntnForm Data")
            this.grpCtcVctn(formData,response=>{
                console.log('f1 arr push is ? ',response)
                if(event.btntext == 'ct'){
                    this.f1 = []
                    if(response){
                        formData.coverageCode = "CTC"
                        this.f1.push(formData)
                    }
                }else{
                    if(this.CTCSelectBox) this.f1[0].relationShipsCoveredDetails = this.mapRelFrmDta(this.f1[0].relationShipsCoveredDetails)
                }

                console.log("fd for api is ", formData)
                this.grpCtcVctn(formData2,res=>{
                    if(event.btntext == 'vctn'){
                        this.f2 = []
                        if(response){
                            formData2.coverageCode = "VAC"
                            this.f2.push(formData2)
                        }
                    }else{
                        if(this.vaccSelectBox) this.f2[0].relationShipsCoveredDetails = this.mapRelFrmDta(this.f2[0].relationShipsCoveredDetails)
                    }
                    var finalData = [
                        ...this.f1, ...this.f2
                    ]
                    console.log(finalData,"finalData")
                    let apiURL=''
                    if(this.btnName==="ConfgrPlnFrPlcy"){
                        apiURL="group-plan/update-group-ctcvac/"+this.plan._id
                    }else if(this.btnName==="CnfgrNwRtlPln"){
                        apiURL="retail-plan/update-retail-ctcvac/"+this.plan._id
                    }

                    this.PUT(1,apiURL,finalData,(res,error)=>{
                        if(!error){
                            console.log("PUT APi response|-> ctcvac ", res)
                            if(event.btntext == 'ct'){
                                this.showToast("Compassionate Travel Coverage details has been updated successfully",this.TOST().SUCCESS)
                            }else if(event.btntext == 'vctn'){
                                this.showToast("Vaccination Coverage details has been updated successfully",this.TOST().SUCCESS)
                            }
                        }else{
                            console.log("PUT APi error response|->ctcvac", error)
                            this.showToast(error.response.data.msg ,this.TOST().ERROR);
                        }    
                    })
                })
            })

        },
        submitCTCVC(event){
            if(event == 'ct'){
                this.CTCSelectBox == true ? this.$refs.CTCRel.save_click(event) : this.saveCTCVC({btntext:event,dta:null})
                
            }else if(event == 'vctn'){
                this.vaccSelectBox == true ? this.$refs.vaccRel.save_click(event) : this.saveCTCVC({btntext:event,dta:null})
                
            }
        },
        grpCtcVctn(fdone, callback){
             var addtoarr = false
             var counter=0
             var fdlength=Object.keys(fdone).length
             console.log(fdlength,"fdlength")
            for(let d in fdone){
                console.log(d)
                fdone[d] = this.checkdata(fdone[d])
                if(typeof fdone[d] == 'object'){
                    if(Object.keys(fdone[d]).length != 0){
                        addtoarr = true  
                    }
                }else if(Array.isArray(fdone[d])){
                    if(fdone[d].length != 0){
                        addtoarr = true
                    }
                }else if(typeof fdone[d] == 'string'){
                    if(fdone[d].length != 0){
                        addtoarr = true
                    }    
                }
                counter++
                if(counter===fdlength){
                    callback(addtoarr)
                }

            }

        },

            checkdata(str){ 
                console.log(typeof str, "->", str)
                 if(typeof str=='boolean'){
                     return str
                 }
                if(typeof str=='string'){
                    // alert("from string")
                    if(str == null || str == '' || str == undefined){
                        console.log('reached if wid txt', str)
                            return ''
                        }else{
                            return str
                        }
                }

                if(Array.isArray(str)){
                    if(str.length===0){
                        console.log('reached if wid array', str)
                            return []
                        }else{
                            return str
                    }
                }
                
          
                var len =  Object.keys(str).length
                console.log('typeof str == object ',typeof str == 'object')
                var objis = false
                if(typeof str == 'object'){
                    objis = true
                }
                console.log('objis ', objis)
                if(objis){
                    if(Object.keys(str).length == 0){
                        console.log("reached till here for 0 ?")
                        return ''
                    }else{
                        return str
                    }
                }
                
            },

        process_relationship_dependent(indata,callback){
            console.log("***************((((((((indata)))))))) --->",  indata);
            var self = this
            self.selectRelationshipsCoverd=[]
           self.selectRelationshipsCoverd=[
                {display:'Self', checked:false, aftercheck:false,},
                {display:'Spouse',checked:false,aftercheck:false,},
                {display:'Children',checked:false, aftercheck:false,},
                {display:'Parent',checked:false, aftercheck:false}
            ],
            self.reldatais=[]
            self.reldatais=[ 
                {
                    active:false,
                    coverType:'Self',
                    checkbox:false,
                    siSubLimit:'',
                    coPay:'',
                    roomRentLimit:[],
                    flatAmount:'',
                    siPercent:'',
                    applicableValue:'',
                    waitingPeriod:'',
                        fa:false,
                        si:false,
                        en_fa:false,
                        en_app:false,
                        en_si:false,
                        app:false,
                    
                },

                {   active:false,
                    coverType:'Spouse',
                    checkbox:false,
                    siSubLimit:'',
                    coPay:'',
                    roomRentLimit:[],
                    flatAmount:'',
                    siPercent:'',
                    applicableValue:'',
                    waitingPeriod:'',
                        fa:false,
                        si:false,
                        en_fa:false,
                        en_app:false,
                        en_si:false,
                        app:false,
                },

                {   coverType:'Children',
                    active:false,
                    checkbox:false,
                    siSubLimit:'',
                    coPay:'',
                    roomRentLimit:[],
                    flatAmount:'',
                    siPercent:'',
                    applicableValue:'',
                    waitingPeriod:'',
                    fa:false,
                    si:false,
                        fa:false,
                        si:false,
                        en_fa:false,
                        en_app:false,
                        en_si:false,
                        app:false,
                },
                
                {   coverType:'Parent',
                    active:false,
                    checkbox:false,
                    siSubLimit:'',
                    coPay:'',
                    roomRentLimit:[],
                    flatAmount:'',
                    siPercent:'',
                    applicableValue:'',
                    waitingPeriod:'',
                    fa:false,
                    si:false,
                        fa:false,
                        si:false,
                        en_fa:false,
                        en_app:false,
                        en_si:false,
                        app:false,
                },
            ]
                for(let j=0;j<indata.length;j++){
                    for(let i=0;i<self.reldatais.length;i++){
                        console.log("temp",indata[j])
                        console.log("real",self.reldatais[i])
                        if(self.reldatais[i].coverType===indata[j].coverType){
                            console.log("***************reldatais__coverType --->",  self.reldatais[i].coverType);
                            console.log("***************indata __coverType --->",  indata[j].coverType);
                            self.reldatais[i]=indata[j]
                            self.reldatais[i].checkbox = false
                        }
                    }
                }
            console.log(indata,"indata====>")
            self.syncarr = []
            // self.reldatais = []
            // self.reldatais=indata
            self.activeonly = []
            // self.selectRelationshipsCoverd = []
              for(let i=0; i<self.reldatais.length; i++){
                        // console.log("inside reldata");
                if(self.reldatais[i].roomRentLimit.length == 0){
                     self.reldatais[i].en_fa = false;
                      self.reldatais[i].en_si = false;
                }else{
                    self.reldatais[i].roomRentLimit.map(e=>{
                          console.log("***************E is --->",  e);
                        var faval = false
                        var sival = false
                        self.reldatais[i].roomRentLimit.map(e=>{
                            if(e == 'Flat amount'){
                             faval = true
                            }
                        }) 
                        self.reldatais[i].roomRentLimit.map(e=>{
                            if(e == '% of SI'){
                                sival = true
                            }
                        })
                        // if(faval || sival){
                        //      if(self.reldatais[i].applicableValue == ''){
                        //             self.reldatais[i].en_app = true
                        //      }else{
                        //             self.reldatais[i].en_app = false;
                        //             self.reldatais[i].applicableValue = ''
                        //      }
                        // }else{
                        //     self.reldatais[i].en_app = false
                        //       self.reldatais[i].applicableValue = ''
                        // }
                      if(faval && sival){
                        console.log("this.reldatais[indis] ",self.reldatais[i]);
                            console.log(faval,"inside en_app change",sival)
                            console.log("appVal -> ", self.reldatais[i].applicableValue);
                        self.reldatais[i].en_app = true
                        if(self.reldatais[i].applicableValue == '' || self.reldatais[i].applicableValue == undefined){
                        self.reldatais[i].app = true
                        console.log(" -> inside IF of appVal true")
                            self.$forceUpdate()
                        }else{
                        self.reldatais[i].app = false 
                            console.log(" -> inside ELSE of appVal false and clearing")
                            self.$forceUpdate();
                        }
                    }else{
                        self.reldatais[i].en_app = false
                        self.reldatais[i].app = false 
                        self.reldatais[i].applicableValue = ''
                            console.log(" -> OUTTER ELSE of appVal false and clearing")
                            self.$forceUpdate()
                    }
                    if(faval){
                         self.reldatais[i].en_fa = true
                        if(self.reldatais[i].flatAmount == ''){
                            self.reldatais[i].fa = true
                        }else{
                            console.log('NO ERROR')
                             self.reldatais[i].fa = false
                        }
                    }else{
                        console.log("else of flat and e is ", e)
                         self.reldatais[i].en_fa = false
                    }
                    if(sival){
                        //   alert("% of SI");
                         self.reldatais[i].en_si = true
                        if(self.reldatais[i].siPercent == ''){
                            self.reldatais[i].si = true
                        }else{
                            console.log('NO ERROR')
                             self.reldatais[i].si = false
                        }
                    }else{
                         console.log("else of SI and e is ", e)
                         self.reldatais[i].en_si = false
                    }
                })
                }
            }
            //     for(let i=0; i<self.reldatais.length; i++){
            //             console.log("inside reldata");
            //     if(self.reldatais[i].roomRentLimit.length == 0){
            //          self.reldatais[i].en_fa = false;
            //           self.reldatais[i].en_si = false;
            //     }else{
            //          self.reldatais[i].roomRentLimit.map(e=>{
            //         if(e == 'Flat amount'){
            //              self.reldatais[i].en_fa = true
            //             if(self.reldatais[i].flatAmount == ''){
            //                 self.reldatais[i].fa = true
            //             }else{
            //                 console.log('NO ERROR')
            //                  self.reldatais[i].fa = false
            //             }
            //         }else{
            //              self.reldatais[i].en_fa = false
            //         }
            //          if(e == '% of SI'){
            //              self.reldatais[i].en_si = true
            //             if(self.reldatais[i].siPercent == ''){
            //                 self.reldatais[i].si = true
            //             }else{
            //                 console.log('NO ERROR')
            //                  self.reldatais[i].si = false
            //             }
            //         }else{
            //              self.reldatais[i].en_si = false
            //         }
            //     })
            //     }
            // }
            //  for(let i=0; i<self.reldatais.length; i++){
            //             console.log("inside reldata",  self.reldatais[i]);
            //     if(self.reldatais[i].roomRentLimit.length == 0){
            //          self.reldatais[i].en_fa = false;
            //           self.reldatais[i].en_si = false;
            //     }else{
            //          self.reldatais[i].roomRentLimit.map(e=>{
            //              console.log("E is --->",  e);
            //         if(e == '% of SI'){
            //             alert("from % of SI")
            //              self.reldatais[i].en_si = true
            //             if(self.reldatais[i].siPercent == ''){
            //                 self.reldatais[i].si = true
            //             }else{
            //                 console.log('NO ERROR')
            //                  self.reldatais[i].si = false
            //             }
            //         }else{
            //              self.reldatais[i].en_si = false
            //         }
            //         if(e == 'Flat amount'){
            //                alert("Flat amount")
            //              self.reldatais[i].en_fa = true
            //             if(self.reldatais[i].flatAmount == ''){
            //                 self.reldatais[i].fa = true
            //             }else{
            //                 console.log('NO ERROR')
            //                  self.reldatais[i].fa = false
            //             }
            //         }else{
            //              self.reldatais[i].en_fa = false
            //         }
            //     })
            //     }
            // }
                self.reldatais.map(indata=>{
                    //   var dind = self.syncarr.findIndex(e =>e.display == indata.coverType )
                        if(indata.active == true){
                    //         self.syncarr[dind].checked = indata.checked;
                    //     }else{
                            // self.selectRelationshipsCoverd
                            self.syncarr.push({display:indata.coverType, checked:indata.active});
                        }
                })
                self.RelationshipsCoverd = []
                self.reldatais.map(indata=>{
                    //   var dind = self.syncarr.findIndex(e =>e.display == indata.coverType )
                    if(indata.active == true){
                     self.selectRelationshipsCoverd.map(sel=>{
                            if(indata.coverType == sel.display){
                                    sel.checked = true;
                                    sel.aftercheck = true;
                                self.RelationshipsCoverd.push(indata.coverType)
                            }
                     })
                    }
                })
                // self.setddnow()
                // self.selectAdd()
            //    for(let i=0; i<self.reldatais.length; i++){
            //     if(self.reldatais[i].roomRentLimit.length == 0){
            //          self.reldatais[i].en_fa = false;
            //           self.reldatais[i].en_si = false;
            //     }else{
            //         self.reldatais[i].roomRentLimit.map(e=>{
            //         // if(e == 'Flat amount'){
            //         //      self.reldatais[i].en_fa = true
            //         //     if(self.reldatais[i].flatAmount == ''){
            //         //         self.reldatais[i].fa = true
            //         //     }else{
            //         //         console.log('NO ERROR')
            //         //          self.reldatais[i].fa = false
            //         //     }
            //         // }else{
            //         //      self.reldatais[i].en_fa = false
            //         // }
            //         if(e == '% of SI'){
            //              self.reldatais[i].en_si = true
            //             if(self.reldatais[i].siPercent == ''){
            //                 self.reldatais[i].si = true
            //             }else{
            //                 console.log('NO ERROR')
            //                  self.reldatais[i].si = false
            //             }
            //         }else{
            //              self.reldatais[i].en_si = false
            //         }
            //     })
            //     }
            // }
                console.log("processed Reldata is",self.reldatais);
                console.log("processed Checkedbox data is",self.selectRelationshipsCoverd);
                   console.log("processed Syncarr data is",self.syncarr);
                    self.reldatais.map(e=>{
                        if(e.active == true){
                            self.activeonly.push(e.coverType)
                        }
                    })
           var uniq = [...new Set(self.activeonly)];
             self.activeonly = uniq
                callback({realdata:self.reldatais,
                          syncarr:self.syncarr,
                          dropdowndata:self.selectRelationshipsCoverd,
                          ddselectedvalues:self.activeonly })
        },
    },


    
}
</script>

<style>

.panelis{
  
    padding:2px 10px;
    max-width: 100%;
    position: relative;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}



.panhead{
        display: flex;
    justify-content: space-between;
   transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    cursor: pointer;
    margin: 0px 5px;
    border: 1px #e4e9ed solid;
    min-height: 50px;
    text-align: left;
    padding: 16px
}

.cust_pln_cd .v-text-field .v-input__slot,
.v-text-field--outline .v-input__slot {
    min-height: 41px !important;
    display: flex !important;
    align-items: center !important;
    /* border:1px red solid; */
}

.cust_pln_cd .v-input .v-label {
    font-size: 13px !important;
}
.cust_pln_cd .v-input {
    font-size: 13px !important;
}
.cust_pln_cd .v-input input {
    font-size: 13px !important;
}

.cstm-clr-pln-cd{
    color:#646666 !important;
    margin-bottom: 5px !important;
    font-size: 13px !important;
}
.cstm-clr-pln-cd-white{
    /* color:#646666 !important; */
    margin-bottom: 5px !important;
    font-size: 13px !important;
}
.cust-pln-cd .v-text-field.v-text-field--enclosed>.v-input__control>.v-input__slot {
    padding: 0px !important;
}

.cust_lbl-bgclr{
    color:#152F38

}

.gridheader{
    background-color: #01B4BB;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>