<template>
<div>
        <!-- <div v-if="docen">
            <docup 
            @closedoc="docen = false"    
            @getdocslist="doc_for_creation"
            @deletenow="deletelocal" 
            :setdoc="{docen:docen,provider_data:{_id: empId, prn:prnis },
            type:'edit', updocs:[],from:'tds'}" >
            </docup>
        </div> -->

        
   <div style="background-color: #FFFFFF;" column class="py-3" >
        <div column class="py-3">

        <v-form ref="form">
               
                    <v-layout class=" mt-2" >
                        <v-flex xs3>
                            <v-layout column class="mx-2 my-2">
                                <v-text class="text-uppercase font-weight-bold title-clr-pr-ACD">
                                    TDS DETAILS
                                </v-text>
                                <v-divider></v-divider>
                            </v-layout>
                        </v-flex>

                        <!-- Provider Name -->
                        <v-flex xs3>
                            <v-layout column class="cust_pdTDS mx-2 my-2 ">
                                <v-text class="font-weight-bold cstm-clr-pd">
                                    Provider Name
                                </v-text>

                                <div class="d-flex">
                                    <v-autocomplete
                                        v-model="providerDetails"
                                        :items="searchedItems"
                                        :loading="isLoading"
                                        :search-input.sync="searchApi"
                                        color="white"
                                        item-text="name"
                                        item-value="_id"
                                        label="Provider Name..."
                                        cache-items
                                        return-object
                                        :rules="[v => !!v || 'This field is required']"
                                        solo
                                        dense
                                        class="rounded-0 comboxPadding deleIconTDS"
                                        required
                                        :disabled="comingFromWI">
                                    </v-autocomplete>
                                    <v-card 
                                    slot="append" style="  
                                    background-color: rgb(247, 247, 247);
                                    padding: 9px !important;
                                    cursor: pointer;
                                    height: 38px;
                                    z-index: 1;" 
                                    @click="getProviderDetails"
                                    class="rounded-0">
                                    <v-icon elevation="1">search</v-icon></v-card>
                               
                                </div>
                            </v-layout>
                        </v-flex>

                        <!-- PAN Number -->
                         <v-flex xs3>
                            <v-layout column class="cust_pdTDS mx-2 my-2 ">
                                <v-text class="font-weight-bold cstm-clr-pd">
                                    PAN Number
                                </v-text>
                            
                                <v-text-field
                                    solo
                                    disabled
                                    :label="tdsPanLabel"
                                    v-model="tdsPan"
                                    class="rounded-0"
                                    maxlength="10"
                                    dense
                                    >
                                </v-text-field>
                            </v-layout>
                        </v-flex>

                        <!-- Hospital TDS Category -->
                         <v-flex xs3>
                            <v-layout column class="cust_pdTDS mx-2 my-2 ">
                                <v-text class="font-weight-bold cstm-clr-pd">
                                    Hospital TDS Category
                                </v-text>
                            
                                <v-text-field
                                    solo
                                    disabled
                                    label="Enter..."
                                    v-model="tdsHosp"
                                    class="rounded-0"
                                    dense
                                    >
                                </v-text-field>
                            </v-layout>
                        </v-flex>

                        <!-- Base TDS percentage -->
                        <v-flex xs3>
                            <v-layout column class="cust_pdTDS mx-2 my-2 testingStatus">
                                <v-text class="font-weight-bold cstm-clr-pd">
                                   Base TDS %
                                </v-text>
                            
                                <v-select
                                    :items="tdsBaseItem"
                                    solo
                                    dense
                                    disabled
                                    label="Enter..."
                                    v-model="tdsBase"
                                    class="rounded-0"
                                    maxlength="3"
                                    >
                                </v-select>
                            </v-layout>
                        </v-flex>


                   
                    
                                                    
                    </v-layout>

                    <v-layout >

                        <v-flex xs3 >
                            <v-layout column class="mx-2 mb-4" justify-center >
                            
                                <span class="font-weight-bold cstm-clr-pr" style="color:#FFFFFF" > </span>
                                <div style="background-color: #f7f7f7;border: 1px solid #c1c8cc; padding:7px;margin-top: 28px !important;">
                                    <v-checkbox class="ma-0 pa-0" v-model="tdsConcessional"  hide-details  :disabled="comingFromWI">
                                        <span slot="label" class="lbl-bgclrTDS font-weight-bold" style="font-size: 13px;">Concessional TDS details available</span>
                                    </v-checkbox>
                                </div>
                            </v-layout>
                        </v-flex>


                            
                                <!-- <v-card class="xs3 pa-2"  style="height: 50px; display: flex;align-items: center;">
                                    <v-layout   dense >
                                        <v-checkbox
                                                dense
                                                color = "#23b1a9" 
                                               label="Concessional TDS details available"
                                                v-model="tdsConcessional" ></v-checkbox>
                                               
                                    </v-layout>
                                </v-card> -->

                                

                                <!-- Only values between 0 to 100 are accepted  *Concessional TDS %*-->
                                <v-flex xs3 v-if="!comingFromWI">
                                    <v-layout column class="cust_pdTDS mx-2 my-2 ">
                                        <v-text class="font-weight-bold cstm-clr-pd">
                                            Concessional TDS %
                                        </v-text>
                                    
                                        <v-text-field
                                          
                                            :disabled="onConcessionalDisable"
                                            solo
                                            dense
                                            label="Enter..."
                                            v-model="concessionalTds"
                                            class="rounded-0"
                                            maxlength="5"
                                            :rules="[concessionalTdsRules(concessionalTds),numberRulesCon(concessionalTds),requiredField(concessionalTds)]"
                                            >
                                        </v-text-field>
                                    </v-layout>
                                </v-flex>

                                <v-flex xs3 v-if="!comingFromWI">
                                    <v-layout column class=" mx-2 mt-2 cust_pr_tds cust-rmvpd-pr-quick">
                                        <p class="font-weight-bold cstm-clr-pr-quick">
                                        Concessional TDS effective date
                                        </p>
                                        
                                        <v-menu
                                            ref="calender"
                                            :close-on-content-click="false"
                                            v-model="concessionaEffDAte"
                                            :nudge-right="40"
                                            lazy
                                            transition="scale-transition"
                                            full-width
                                            max-width="290px"
                                            min-width="290px" 
                                            class="rounded-0"
                                            >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                    disabled
                                                    dense
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    label="dd/mm/yyyy"
                                                    :value="computedConcessionaEffDate"
                                                    solo
                                                    class="rounded-0"
                                                    readonly
                                                >
                                                <v-icon slot="prepend-inner" 
                                                        dense  
                                                        style="background-color:#F7F7F7; height:37px; width:40px;">
                                                            date_range
                                                    </v-icon>
                                                
                                                </v-text-field>
                                            </template>                        
                                                    <v-date-picker
                                                        dense
                                                        v-model="concessionaEffDateModel"
                                                        no-title
                                                        @input="concessionaEffDAte = false"
                                                        
                                                    ></v-date-picker>
                                        </v-menu>


                                    </v-layout>
                                </v-flex>

                                <v-flex xs3 v-if="!comingFromWI">
                                    <v-layout column class=" mx-2 mt-2 cust_pr_tds cust-rmvpd-pr-quick">
                                        <p class="font-weight-bold cstm-clr-pr-quick">
                                            Financial Year
                                        </p>
                                        
                                        <v-menu
                                            ref="calender"
                                            :close-on-content-click="false"
                                            v-model="financialYearDate"
                                            :nudge-right="40"
                                            lazy
                                            transition="scale-transition"
                                            full-width
                                            max-width="290px"
                                            min-width="290px" 
                                            class="rounded-0"
                                            dense
                                            >
                                            <template dense v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                    :disabled="onConcessionalDisable"
                                                    dense
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    label="dd/mm/yyyy"
                                                    :value="computedFinancialYearDate"
                                                    solo
                                                    class="rounded-0"
                                                    readonly
                                                    :rules="[financialRules(financialYearDateModel)]"
                                                >
                                                <v-icon 
                                                    slot="prepend-inner" 
                                                    dense  
                                                    style="background-color:#F7F7F7; height: 37px; width: 40px;">
                                                        date_range
                                                    </v-icon>
                                                
                                                </v-text-field>
                                            </template>                        
                                                    <v-date-picker
                                                        dense
                                                        v-model="financialYearDateModel"
                                                        no-title
                                                        @input="financialYearDate = false"
                                                        
                                                    ></v-date-picker>
                                        </v-menu>


                                    </v-layout>
                                </v-flex>

                                <v-flex xs3 v-if="!comingFromWI" >
                                    <v-layout column class="cust_pdTDS mx-2 my-2 ">
                                        <v-text class="font-weight-bold cstm-clr-pd">
                                        Threshold Limit
                                        </v-text>
                                    
                                        <v-text-field
                                            :disabled="onConcessionalDisable"
                                            solo
                                            label="Enter..."
                                            v-model="thresholLimit"
                                            :rules="[numberRules(thresholLimit),requiredField(thresholLimit)]"
                                            class="rounded-0"
                                            maxlength="100"
                                            dense
                                            >
                                        </v-text-field>
                                    </v-layout>
                                </v-flex>

                                <v-flex xs3 v-if="comingFromWI">
                                    <v-layout column class="cust_pdTDS mx-2 my-2 ">
                                        <v-text class="font-weight-bold cstm-clr-pd">
                                            Concessional TDS %
                                        </v-text>
                                    
                                        <v-text-field
                                          
                                            disabled
                                            solo
                                            dense
                                            label="Enter..."
                                            v-model="routeConcessionalTds"
                                            class="rounded-0"
                                            >
                                        </v-text-field>
                                    </v-layout>
                                </v-flex>

                                 <v-flex xs3 v-if="comingFromWI">
                                    <v-layout column class=" mx-2 mt-2 cust_pr_tds cust-rmvpd-pr-quick">
                                        <p class="font-weight-bold cstm-clr-pr-quick">
                                        Concessional TDS effective date
                                        </p>
                                        
                                        <v-menu
                                            ref="calender"
                                            :close-on-content-click="false"
                                            v-model="routeconcessionaEffDAte"
                                            :nudge-right="40"
                                            lazy
                                            transition="scale-transition"
                                            full-width
                                            max-width="290px"
                                            min-width="290px" 
                                            class="rounded-0"
                                            >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                    disabled
                                                    dense
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    label="dd/mm/yyyy"
                                                    :value="computedrouteConcessionaEffDate"
                                                    solo
                                                    class="rounded-0"
                                                    readonly
                                                >
                                                <v-icon slot="prepend-inner" 
                                                        dense  
                                                        style="background-color:#F7F7F7; height:37px; width:40px;">
                                                            date_range
                                                    </v-icon>
                                                
                                                </v-text-field>
                                            </template>                        
                                                    <v-date-picker
                                                        dense
                                                        v-model="routeconcessionaEffDateModel"
                                                        no-title
                                                        @input="routeconcessionaEffDAte = false"
                                                        
                                                    ></v-date-picker>
                                        </v-menu>


                                    </v-layout>
                                </v-flex>

                                <v-flex xs3 v-if="comingFromWI">
                                    <v-layout column class=" mx-2 mt-2 cust_pr_tds cust-rmvpd-pr-quick">
                                        <p class="font-weight-bold cstm-clr-pr-quick">
                                            Financial Year
                                        </p>
                                        
                                        <v-menu
                                            ref="calender"
                                            :close-on-content-click="false"
                                            v-model="routeFinanYearDate"
                                            :nudge-right="40"
                                            lazy
                                            transition="scale-transition"
                                            full-width
                                            max-width="290px"
                                            min-width="290px" 
                                            class="rounded-0"
                                            >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                    disabled
                                                    dense
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    label="dd/mm/yyyy"
                                                    :value="computedrouteFinanYearDate"
                                                    solo
                                                    class="rounded-0"
                                                    readonly
                                                >
                                                <v-icon slot="prepend-inner" 
                                                        dense  
                                                        style="background-color:#F7F7F7; height:37px; width:40px;">
                                                            date_range
                                                    </v-icon>
                                                
                                                </v-text-field>
                                            </template>                        
                                                    <v-date-picker
                                                        dense
                                                        v-model="routeFinanYearDateModel"
                                                        no-title
                                                        @input="routeFinanYearDate = false"
                                                        
                                                    ></v-date-picker>
                                        </v-menu>


                                    </v-layout>
                                </v-flex>

                                <v-flex xs3 v-if="comingFromWI" >
                                    <v-layout column class="cust_pdTDS mx-2 my-2 ">
                                        <v-text class="font-weight-bold cstm-clr-pd">
                                        Threshold Limit
                                        </v-text>
                                    
                                        <v-text-field
                                            disabled
                                            solo
                                            label="Enter..."
                                            v-model="routeThresholLimit"
                                            class="rounded-0"
                                            dense
                                            >
                                        </v-text-field>
                                    </v-layout>
                                </v-flex>



                    </v-layout>

                    <v-layout  >

                        <v-flex xs6 v-if="!comingFromWI" >
                            <v-layout column class="cust_pdTDS mx-2 my-2 ">
                                <v-text class="font-weight-bold cstm-clr-pd">
                                Remarks
                                </v-text>
                            
                                <v-text-field
                                   :disabled="onConcessionalDisable" 
                                    solo
                                    label="Enter..."
                                    v-model="remarksTds"
                                    :rules="[alphaRules(remarksTds)]"
                                    class="rounded-0"
                                    maxlength="200"
                                    dense
                                    >
                                </v-text-field>
                            </v-layout>
                        </v-flex>

                        <v-flex xs6 v-if="comingFromWI" >
                            <v-layout column class="cust_pdTDS mx-2 my-2 ">
                                <v-text class="font-weight-bold cstm-clr-pd">
                                Remarks
                                </v-text>
                            
                                <v-text-field
                                   disabled 
                                    solo
                                    label="Enter..."
                                    v-model="routeRemarksTds"
                                    class="rounded-0"
                                    dense
                                    >
                                </v-text-field>
                            </v-layout>
                        </v-flex>


                        <v-flex xs3>
                            <v-layout column class="cust_pdTDS mx-2 my-2 ">
                                <v-text class="font-weight-bold cstm-clr-pd">
                                    Status
                                </v-text>
                            
                                <v-text-field
                                    solo
                                    disabled
                                    label="Enter..."
                                    v-model="reviewStatus"
                                    class="rounded-0"
                                    dense
                                    >
                                </v-text-field>
                            </v-layout>
                        </v-flex>

                        <v-flex xs6>
                            
                        </v-flex>
                       



                    </v-layout>
                   
        <!-- <v-dialog v-model="confirmDialogCancel" persistent max-width="450px">
                 
                         <v-card tile color="#F7F7F7" >
                            <v-card tile>
                                <v-layout justify-space-between  class="" style="flex:1;background-ox-shadow: 0px 3px 5px #00000029;">
                             
                                    <v-card-title class="pa-0 pl-3 text-capitalize font-weight-regular" style="color:black"> 
                                       CONFIRMATION
                                    </v-card-title>
                                    <div style="border-left:1px solid #C1C8CC;" @click="close_cancelDialog()">
                                        <v-icon style="cursor: pointer;" class="pa-2" >close</v-icon>
                                    </div>
                                </v-layout>
                            </v-card>
                        <v-flex >
                           
                       
                        </v-flex>
                        <v-card-text >
                                Details saved on screen will be lost
                        </v-card-text>
                        <v-card-actions justify-start>
                          
                           
                           
                            <v-btn dark  tile color="black" @click="confirmDialogCancel = false" class="text-capitalize px-3 mx-2" small><v-icon dark small>cancel</v-icon> <v-divider vertical class="mx-2"></v-divider>Cancel</v-btn>

                            <v-btn @click="okClick" dark  tile color="#23B1A9" class="text-capitalize px-3 ml-2" small><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider> OK</v-btn>

                        </v-card-actions>
                    </v-card>
                </v-dialog> -->

                
            <div class="mx-2 pa-5" style="background-color: #F7F7F7; display:flex;justify-content:space-between;"  >
                <v-layout style="flex:1" justify-end > 
                    <v-btn @click="uploadcert" dark tile color="#E46A25" class="text-capitalize px-3 ml-3" small><v-icon dark small>cloud_upload</v-icon> <v-divider vertical class="mx-2"></v-divider>Upload Certificate</v-btn>
                    <v-btn dark tile color="#23B1A9" class="text-capitalize px-3 ml-3 testing" small @click="submitDetails"><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider>Save</v-btn>
                    <v-btn dark tile color="#D1121B" class="text-capitalize px-3 ml-3" small  @click="clearData"><v-icon dark small>mdi-trash-can-outline</v-icon> <v-divider vertical class="mx-2"></v-divider>Clear</v-btn>
                    <v-btn dark tile color="black" class="text-capitalize px-3 ml-3" small @click="$router.go(-1)" ><v-icon dark small >mdi-arrow-left-circle</v-icon> <v-divider vertical class="mx-2"></v-divider>Return</v-btn>   
                </v-layout>
            </div>

        </v-form>
            
        </div>

        <div  v-if="tdsType">
            <v-form ref="reviewForm">
           

                <v-layout class=" mt-2" >
                        <v-flex xs3>
                            <v-layout column class="mx-2 my-2">
                                <v-text class="text-uppercase font-weight-bold title-clr-pr-ACD">
                                    REVIEW
                                </v-text>
                                <v-divider></v-divider>
                            </v-layout>
                        </v-flex>

                       
                         <v-flex xs6>
                            <v-layout column class="cust_pdTDS mx-2 my-2 ">
                                <v-text class="font-weight-bold cstm-clr-pd">
                                    Reason*
                                </v-text>
                            
                                <v-text-field
                                    solo
                                    label="Enter..."
                                    v-model="reason"
                                    class="rounded-0"
                                    maxlength="200"
                                    dense
                                    >
                                </v-text-field>
                            </v-layout>
                        </v-flex>

                        <v-flex xs6>
                            
                        </v-flex>


                </v-layout>

                 <div class="mx-2 pa-5" style="background-color: #F7F7F7; display:flex;justify-content:space-between;"  >
                    <v-layout style="flex:1" justify-end > 
                        <v-btn @click="viewdocs" dark tile color="black" class="text-capitalize px-3 ml-3" small><v-icon dark small>visibility</v-icon> <v-divider vertical class="mx-2"></v-divider>View Documents</v-btn>
                        <v-btn dark tile color="#23B1A9" class="text-capitalize px-3 ml-3 testing" small @click="acceptTds"><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider>Accept</v-btn>
                        <v-btn dark tile color="#D1121B" class="text-capitalize px-3 ml-3" small   @click="rejectTds"><v-icon dark small>cancel</v-icon> <v-divider vertical class="mx-2"></v-divider>Reject</v-btn>
                        <v-btn @click="$router.go(-1)" dark tile color="black" class="text-capitalize px-3 ml-3" small ><v-icon dark small >mdi-arrow-left-circle</v-icon> <v-divider vertical class="mx-2"></v-divider>Return</v-btn>   
                    </v-layout>
                </div>
            </v-form>
        </div>
       
         <div v-if="docenView">
                <viewdoc 
                    @closedoc="docenView = false"    
                    :setdoc="{ docen:docenView,provider_data:{_id: empId, prn:prnis },
                    type:'edit', updocs:[],from:'tds'}" >
                 </viewdoc>
              
         </div>

         <div v-if="docen">
                 <docup 
                    @closedoc="docen = false"    
                    @getdocslist="doc_for_creation"
                    @deletenow="deletelocal" 
                    :setdoc="{docen:docen,provider_data:{_id: empId, prn:prnis },
                    type:'edit', updocs:[],from:'tds'}" >
                 </docup>
         </div>

        
   </div>
        
        
       
</div>


</template>

<script>
import moment from 'moment';
import docup from './ProviderRegistrationClaims/docup.vue'
import viewdoc from '../Page/ProviderEmpanelment/viewdocument.vue'
export default {

    components:{

        docup,
        viewdoc

    },
    created(){
        this.concessionaEffDateModel = Date();
        // this.tdsConcessional = false
        console.log(this.$route.params.fromis, "%c this is the type of workItem coming from route","background-color:#ffff20;color:black",this.$route.params);
        this.tdsType = this.$route.params.type;

        this.$store.commit("SAVE_PAGE_HEADER", "TDS Details Capture");
        
        if(this.$route.params.fromis == 'WI'){
            this.comingFromWI = true
            this.initiate_service_provider(this.$route.params.name);
            this.tds_id = this.$route.params.userId
            this.getDataForProvider();
           
        }
    //    this.tds_id = this.$route.params.userId
       
    },
    data(){
        return{
            reviewStatus:'',
            tds_id:'',
            docen:false,
            docenView:false,
            pre_routeis:null,
            entries: [],
            tdsType:'',
            isLoading: false,
            providerDetails: '',
            searchApi: null,
            tdsPanLabel:'Enter...',
            tdsPan:'',
            tdsHosp:'',
            tdsBase:0,
            tdsBaseItem:['10','20'],
            tdsConcessional:false,
            concessionalTds:'',
            thresholLimit:'',
            remarksTds:'',
            concessionaEffDateModel: '',
            concessionaEffDAte:false, 
            financialYearDateModel:'',
            financialYearDate:false,
            onConcessionalDisable:true,
            reason:'',
            empId:'',
            prnis:'',

            
            routeConcessionalTds:'',
            routeconcessionaEffDAte:false,
            routeconcessionaEffDateModel:'',
            routeFinanYearDate:false,
            routeFinanYearDateModel:'',
            routeThresholLimit:'',

            createdFinancialYear:false,
            comingFromWI:false

        }
    },

    computed:{
        searchedItems () {
            return this.entries;
        },
        computedConcessionaEffDate() {
           
            return this.concessionaEffDateModel ? moment(this.concessionaEffDateModel).format("DD-MM-YYYY"): ''
         },
         computedFinancialYearDate(){
             return this.financialYearDateModel ? moment(this.financialYearDateModel).format("DD-MM-YYYY"): ''
         },

         computedrouteConcessionaEffDate(){
             return this.routeconcessionaEffDateModel ? moment(this.routeconcessionaEffDateModel).format("DD-MM-YYYY"): ''         
         },

         computedrouteFinanYearDate(){
            return this.routeFinanYearDateModel ? moment(this.routeFinanYearDateModel).format("DD-MM-YYYY"): ''         
         }
    },

    methods:{
        chkplndata(data){
                console.log("chkplndata",data); 
                    try{
                        if(data === "" || data === "undefined" || data === null){
                            return ""
                        }else{
                            return data;
                        }

                    }catch(err) {
                        console.log(err)
                    }
            
        },

        getDataForProvider(){
            this.GET(2,'empanelment/empanelment-provider-by-id/'+this.tds_id,(res,err) => {
                    if(!err){
                        console.log("%c this is the data from tdsGET for reviewStatus","background-color:#ffff20;color:black",res.data.data.empanelment.tdsDetails);

                        let tdsDetialFromDB = res.data.data.empanelment.tdsDetails
                        console.log("%c this concessionalTdsDetails","background-color:#dddd20;color:black",tdsDetialFromDB.financialYear);
                        this.reviewStatus = tdsDetialFromDB.reviewStatus
                        this.tdsConcessional = this.chkplndata(tdsDetialFromDB.concessionalTdsDetails)
                        this.routeConcessionalTds = this.chkplndata(tdsDetialFromDB.concessionalTdspercent)
                        this.routeconcessionaEffDateModel = tdsDetialFromDB.concessionalTdsEffectiveDate
                        this.routeFinanYearDateModel = tdsDetialFromDB.financialYear
                        this.routeThresholLimit = this.chkplndata(tdsDetialFromDB.thresholdLimit)
                        this.routeRemarksTds = this.chkplndata(tdsDetialFromDB.remarks)
                    }else{
                        console.log(err.response.data);
                    }
                })
               
        },

        viewdocs(){
            this.docenView = true
            this.tdsType = true
        },

        acceptTds(){
            console.log("accept was clicked");
            let formData = {
                reviewStatus : 'Accept',
                reason : this.reason

            }
           
             this.PUT(2,'empanelment/create-tds-review/'+this.tds_id,formData,
                   ( res,err) => {
                    if(!err){
                        console.log(res.data.msg);
                        this.showToast(res.data.msg,this.TOST().SUCCESS)
                    }else{
                        console.log(err.response.data);
                        this.showToast(err.response.data.msg,this.TOST().ERROR)
                    }
                    })
        },

        rejectTds(){
                console.log("reject was clicked");
                 let formData = {
                reviewStatus : 'Reject',
                reason : this.reason

            }
                this.PUT(2,'empanelment/create-tds-review/'+this.tds_id,formData,
                   ( res,err) => {
                    if(!err){
                        console.log(res.data.msg);
                        this.showToast(res.data.msg,this.TOST().SUCCESS)
                    }else{
                        console.log(err.response.data);
                        this.showToast(err.response.data.msg,this.TOST().ERROR)
                    }
                    })
        },

        initiate_service_provider(val){
                    console.log("incoming val is ", val )
                  this.GET(2,'provider/search-providers-by-status/'+val+'/Empaneled',
                   ( res,err) => {
                    if(!err){

                        console.log("this is response",res);
                        const providers = []
                        for(let provider of res.data.data.data){
                                console.log(provider.empanelmentProvider);
                                if(provider.registeredEntity === "Institution"){
                                    providers.push(provider.entityName)
                                }else if(provider.registeredEntity === "Individual"){
                                        providers.push(provider.firstName)
                                }
                                
                        }
                        console.log(providers)
                        this.entries = providers
                        this.providerDetails = val
                            console.log("data from route",res.data.data.data[0]);
                            this.tdsPan = res.data.data.data[0].panNumber;
                            this.empId = res.data.data.data[0]._id;
                            this.prnis =  res.data.data.data[0].prn;
                            if(this.tdsPan === ''){
                                this.tdsPanLabel = '---'
                            }

                            console.log(this.tdsPan);
                    }else{
                        this.showToast(err.response.data.msg ,this.TOST().ERROR);
                    }
                    });

        },

        requiredField(v){
            if(this.tdsConcessional){
                return v => !!v || "This field is required!"
            }
        },

        financialRules(v){
            if(!moment(v).isAfter(this.concessionaEffDateModel)){
                    this.createdFinancialYear = true
                    return v => !v || "Financial year should not be less than TDS effective date"
            }else{
                    this.createdFinancialYear = false
                    return
                }
        },
        concessionalTdsRules(v){
            
                if(parseFloat(v)>100){
                    return v => !v || 'Only values between 0 to 100 are accepted'
                }
        },

        numberRules(v){
            if(v.length>0){
               return v => /^[0-9]+$/.test(v) || 'Only Numbers are allowed'
            }
        },

        numberRulesCon(v){
            if(v.length>0){
               return v => /^[0-9,]*\.?[0-9,]*$/.test(v) || 'Only Numbers are allowed'
            }
        },

        alphaRules(v){
            if(v.length>0){
               return v => /^[a-zA-Z ]+$/.test(v) || 'Only alphabets are allowed'
            }
        },
        submitDetails(){
            this.$refs.form.validate()
            if(!this.$refs.form.validate()){
               this.showToast("Please fill all fields",this.TOST().WARNING)
                console.log("%c It is a not a valid Form","color:red");
            }else{
                console.log("%c It is a valid Form","color:green",this.providerDetails.name);
                
                // let id = this.$store.state.empanelProviderData.empanelment._id
                // console.log("this is id for searched provider",this.$store.state.empanelProviderData.empanelment._id);
                let formData = {
                    providerName: this.providerDetails.name,                    
                    panNumber: this.tdsPan,                       
                    hospitalTdscategory: this.tdsHosp,              
                    baseTdspercentage:this.tdsBase,                
                    concessionalTdsDetails:this.tdsConcessional,           
                    concessionalTdspercent: this.concessionalTds,          
                    concessionalTdsEffectiveDate:  this.concessionaEffDateModel,     
                    thresholdLimit: this.thresholLimit,                  
                    financialYear: this.financialYearDateModel,                   
                    remarks: this.remarksTds                        
                }
                 this.PUT(2,'empanelment/create-tds-details/'+this.empId,formData,(res,error) => {
                            if(!error){
                                console.log("%c Response from tds for work details","color:green");
                                 this.showToast(res.data.msg ,this.TOST().SUCCESS);
                            }else{
                                console.log("%c Error Response from tds for work details","color:red",error);
                                 this.showToast(error.response.data.msg ,this.TOST().ERROR);
                            }
                 })

            }
        },

        uploadcert(){
            this.docen = true;
        },

        getProviderDetails(){
            
            // console.log("searching for provider");
            if(this.providerDetails === ''){
                return   this.showToast("Please Select Valid Provider" ,this.TOST().WARNING);
            }
           
            this.GET(2,'provider/search-providers-by-status/'+this.providerDetails.name+'/Empaneled',
            
                    (res,err) => {
                        if(!err){
                            console.log(this.providerDetails);
                            console.log("response from getProviderDetails",res.data.data.data[0]);
    
                            this.tdsPan = res.data.data.data[0].panNumber;
                            this.empId = res.data.data.data[0]._id;
                            this.prnis =  res.data.data.data[0].prn;
                            if(this.tdsPan === ''){
                                this.tdsPanLabel = '---'
                            }
                            console.log(this.tdsPan);
                            
                           
                        }else{
                                this.showToast(err.response.data.msg ,this.TOST().ERROR);
                        }   

                    });

           

        },


        clearData(){
                        this.providerDetails = ''
                        this.tdsPan = ''
                        this.tdsHosp = ''
                        this.tdsBase = ''
                        this.tdsBaseItem = ''
                        this.tdsConcessional = ''
                        this.concessionalTds = ''
                        this.thresholLimit = ''
                        this.remarksTds = ''
                        this.concessionaEffDateModel = ''
                        this.financialYearDateModel = ''
        },


    }, //methods end

    watch:{
       tdsConcessional(conValue){
           if(conValue === true){
               this.onConcessionalDisable = false
           }else{
               this.onConcessionalDisable = true
           }
       }, 

        searchApi(val) {
                // Items have already been loaded

        // if(val.length > 2){

            // if (this.items.length > 0) return

            // Items have already been requested
            // if (this.isLoading) return

            // this.isLoading = true
            console.log(val);
            // let provider = this.providerDetails;
            this.GET(2,'provider/search-providers-by-status/'+val+'/Empaneled',
                   ( res,err) => {
                    if(!err){

                        console.log("this is response",res);
                        const providers = []
                        for(let provider of res.data.data.data){
                                console.log(provider.empanelmentProvider);
                                if(provider.registeredEntity === "Institution"){
                                    let providerData = {
                                        name:provider.entityName,
                                        _id:provider._id,
                                    }
                                    providers.push(providerData)
                                }else if(provider.registeredEntity === "Individual"){
                                        let providerData = {
                                        name:provider.firstName+' '+ provider.lastName,
                                        _id:provider._id,
                                    }
                                        providers.push(providerData)
                                }
                                
                        }
                        console.log(providers)
                        this.entries = providers
                        
                    }else{
                        this.showToast(err.response.data.msg ,this.TOST().ERROR);
                    }
                    });
                    this.isLoading = false

             },

        tdsPan(value){
            if(value === '' || value === undefined){
                this.tdsBase = '20'
              return  this.tdsHosp = '----'
            }
            if(value.length > 5){
                this.tdsBase = '10'
            }
            console.log("%c this is the value for pan","background-color:#efa112",value);
            switch (value[3]) {
                case 'C':
                    this.tdsHosp = 'company'
                    break;
                case 'P':
                    this.tdsHosp = 'person'
                    break;
                case 'H':
                    this.tdsHosp = 'HUF'
                    break;
                case 'F':
                    this.tdsHosp = 'firm'
                    break;
                case 'A':
                    this.tdsHosp = 'AOP'
                    break;
                case 'T':
                    this.tdsHosp = 'trust'
                    break;
                case 'B':
                    this.tdsHosp = 'BOI'
                    break;
                case 'L':
                    this.tdsHosp = 'Local Authority'
                    break;
                case 'J':
                    this.tdsHosp = 'Artificial Judicial Person'
                    break;
                case 'G':
                    this.tdsHosp = 'Government '
                    break;

                default:
                    this.tdsHosp = "----"
                    break;
            }
        },
        concessionaEffDateModel(value){
            console.log("this is date for concessionaEffDateModel",moment(value).format("DD-MM-YYYY").substr(6));
            let year = moment(value).format("DD-MM-YYYY").substr(6)
            if(this.createdFinancialYear){
                year = parseInt(year) + 1
                console.log("now the year is",year);
            }else{
                console.log("year is",year);
            }
            let newdate =  new Date(year+'-03-31T10:20:30Z')
            let date = newdate.toUTCString()
            let ndate =  moment(date).format("YYYY-MM-DD")
            this.financialYearDateModel = ndate
            console.log(ndate);
             
        }
    }


}
</script>

<style>
.cust_pdTDS .v-input .v-label {
    font-size: 13px !important;
}
.cust_pdTDS .v-input {
    font-size: 13px !important;
}
.cust_pdTDS .v-input input {
    font-size: 13px !important;
}


.cust_pr_tds .v-input .v-label {
    font-size: 13px !important;
    
}


.cust_pr_tds .v-input{
    font-size: 13px !important;
}

.borderBox{
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 
    0px 2px 2px 0px rgb(0 0 0 / 14%), 
    0px 1px 5px 0px rgb(0 0 0 / 12%);
}

.comboxPadding .v-input__control .v-input__slot{
    padding-right: 0 !important
}

.deleIconTDS .v-input__control .v-input__slot .v-select__slot .v-input__append-inner .v-input__icon--append{
    display: none;
}

.lbl-bgclrTDS{
    color:#152F38
}

</style>