<template>
    <v-app>
        <v-form ref="form">
            <v-layout style="background-color: #F7F7F7;border:0.5px solid #C1C8CC;" class=" px-4 py-2">
                <v-flex xs4 >
                    <v-card tile class=" mx-3 my-2 py-1" style="background-color:#F0FDFF ;border:0.5px solid #C1C8CC;">
                        <v-flex row class=" px-2" style="justify-content:space-between;">   
                            <v-text class="ml-3  font-weight-bold cstm-clr-pd" style="align-self: center;">
                                TOTAL WORKING ITEM
                            </v-text>
                            <v-text class="font-weight-bold cstm-clr-pd " style="font-size: 18px !important;color:#018FA6 !important;align-self: center;">
                                    {{totalwrkitm}}
                            </v-text>
                        </v-flex>
                    </v-card>
                </v-flex>

                <v-flex xs4 >
                    <div @click="TATFILTERS(1)">
                    <!-- set_table_data(set_route_for_page,'filter',
                    {CT:claimType,CSS:claimSubStatus,CS:claimStatus,FL:flag,NTAT:true,ETAT:exceedTATfltr})  -->
                        <v-card  tile class=" mx-3 my-2 py-1" style="background-color: #FEF9F6;border:2px solid #E46A25;">
                        <v-flex row class=" px-2" style="justify-content:space-between;">   
                            <v-text class="ml-3 font-weight-bold cstm-clr-pd" style="align-self: center;">
                                NEARING TAT
                            </v-text>

                            <v-text class="font-weight-bold cstm-clr-pd" style="font-size: 18px !important;color:#E46A25 !important;align-self: center;">
                                    {{nearTAT}}
                            </v-text>
                        </v-flex>
                    </v-card>
                    </div>
                  
                </v-flex>

                <v-flex xs4>
                    <div @click="TATFILTERS(2)" >
                        <v-card tile class=" mx-3 my-2 py-1" style="background-color:#FFFFFF ;border:0.5px solid #C1C8CC;">
                            <v-flex row class=" px-2" style="justify-content:space-between;">   
                                <v-text class="ml-3 font-weight-bold cstm-clr-pd" style="align-self: center;">
                                    EXCEEDED TAT
                                </v-text>

                                <v-text class="font-weight-bold cstm-clr-pd " style="font-size: 18px !important;color:#1E9690 !important;align-self: center;">
                                        {{exceededTAT}}
                                </v-text>
                            </v-flex>
                        </v-card>
                    </div>
                </v-flex>
                <!-- <v-flex xs4 >
                    <v-btn dark  tile @click="clearAllbtn()"  color="#E46A25" class="text-capitalize px-3 mx-2 mt-5" small><v-icon dark small>cancel</v-icon> <v-divider vertical class="mx-2"></v-divider>Clear All</v-btn>
                </v-flex> -->
            </v-layout>
            <v-layout>
                <v-flex xs3 class="ml-4">
                    <v-layout column class="cust_pd mx-3 my-2 ">
                        <v-text class="font-weight-bold cstm-clr-pd">
                            Claim Type
                        </v-text>
                        <v-select
                            :items="claimTypeList"
                            label="Select"
                            v-model="claimType"
                            solo
                            dense
                            class="rounded-0"
                            :rules="[]"
                            @change="set_table_data(set_route_for_page,'filter',
                    {CT:claimType,CSS:claimSubStatus,CS:claimStatus,FL:flag,NTAT:nearTATfltr,ETAT:exceedTATfltr})"
                            >
                        </v-select>
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class=" mx-3 my-2 cust_pd">
                        <v-text class="font-weight-bold cstm-clr-pd">
                            Claim Status
                        </v-text>
                        <v-select
                            :items="claimStatusList"
                            label="Select"
                            v-model="claimStatus"
                            solo
                            dense
                            class="rounded-0"
                            :rules="[]"
                             @change="set_table_data(set_route_for_page,'filter',
                    {CT:claimType,CSS:claimSubStatus,CS:claimStatus,FL:flag,NTAT:nearTATfltr,ETAT:exceedTATfltr})"
                            >
                        </v-select>
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class=" mx-3 my-2 cust_pd">
                        <v-text class="font-weight-bold cstm-clr-pd">
                            Claim Sub-Status
                        </v-text>
                        <v-select
                            :items="claimSubStatusList"
                            label="Select"
                            v-model="claimSubStatus"
                            solo dense
                            class="rounded-0"
                            :rules="[]"
                            @change="set_table_data(set_route_for_page,'filter',
                            {CT:claimType,CSS:claimSubStatus,CS:claimStatus,FL:flag,NTAT:nearTATfltr,ETAT:exceedTATfltr})"
                            >
                        </v-select>
                    </v-layout>
                </v-flex>
                <v-flex xs3 class="mr-4">
                    <v-layout column class=" mx-3 my-2  cust_pd">
                        <v-text class="font-weight-bold cstm-clr-pd">Flag</v-text>
                        <v-select
                            :items="flaglist"
                            label="Select"
                            v-model="flag"
                            solo
                            dense
                            class="rounded-0"
                            :rules="[]"
                            @change="set_table_data(set_route_for_page,'filter',
                            {CT:claimType,CSS:claimSubStatus,CS:claimStatus,FL:flag,NTAT:nearTATfltr,ETAT:exceedTATfltr})"
                        >
                        </v-select>
                    </v-layout>
                </v-flex>
            </v-layout>
            
            <v-layout class="mt-2 pa-3 pt-4" style="flex:1 ; height:74px;background-color: #F7F7F7;border:0.5px solid #C1C8CC;" justify-end > 
                   
                    <v-layout >
                         <v-checkbox
                            style="margin:0px"
                            class="mt-1"
                            @change="restore_edit(1)"
                            v-model="bulkcheck"
                            label="Bulk Assign Roles"
                         ></v-checkbox>
                         <v-layout v-if="bulkcheck"  class="ml-3">

                          <v-flex class="xs3 mx-2" >
                            <v-select
                                label="Select a Role"
                                v-model="bulk_role_id"
                                :items="role_data" 
                                @change="bulkassignnow"
                                return-object
                                item-text="employeeName"
                                item-value="_id" solo dense
                                class="rounded-0 "
                            ></v-select>
                          </v-flex>
                         

                          <v-btn @click="confirm_bulk_assign" dark  tile  color="#23B1A9"
                          class="text-capitalize px-3 mx-2 mt-1"
                            small><v-icon dark small>done</v-icon>
                          <v-divider vertical class="mx-2"></v-divider>Submit Bulk Assignment</v-btn>

 <v-divider vertical class="mx-2"></v-divider>
                         </v-layout>

                    </v-layout>
                    
                    <!-- <v-btn @click="set_table_data(set_route_for_page,'filter',
                    {CT:claimType,CSS:claimSubStatus,CS:claimStatus,FL:flag,NTAT:nearTATfltr,ETAT:exceedTATfltr})"
                    dark  tile  color="#23B1A9" class="text-capitalize px-3 mx-2 mt-1"
                    small><v-icon dark small>tune</v-icon>
                    <v-divider vertical class="mx-2"></v-divider>Filter</v-btn> -->

<!-- @click="filter_status = false" -->
                    <v-btn @click="clearFilter()"  dark  tile  color="#E46A25" class="mt-1 text-capitalize px-3 mx-2"
                    small><v-icon dark small>cancel</v-icon>
                    <v-divider vertical class="mx-2"></v-divider>Clear</v-btn>
            </v-layout>
            
        </v-form>
    <v-card class="rounded-0">

     <v-data-table        
        :headers="headers"
        :items="table_data"
        hide-default-header
        hide-default-footer
        class="elevation-1"
      >  
   <template #header="{ props: { headers } }">
    <thead  class="v-data-table-header makepointer">
      <tr>
           <!-- class="text-uppercase" -->
        <th
          v-for="header in headers"
          :key="header.value"
          class="hdr-txt-clr-WrkItemQue hdr-bg-clr-WrkItemQue"
          color="red"
          scope="col"
          @click="headercontrol(header)"
        >
          {{ header.text  }} 
          <!-- {{ header.stype }} -->
            <v-icon style="background-color:#ccc; border-radius:55px;" v-if="header.stype != ' '" >
                {{ header.stype == 'asc' ? 'arrow_upward':'arrow_downward' }}  </v-icon>
         
        </th>
        
      </tr>
    </thead>
  </template>
   <template v-slot:item.action="{item}" >
        <v-layout class=" pa-0 ma-0 py-1" justify-space-between style="flex:1">
            <v-btn :dark="!bulkcheck" :disabled="bulkcheck"  v-show="item.assignIs" tile  style="font-size:12px;background-color:#152F38;flex:1" @click="assign_reassign(item, 1)"  small class="text-capitalize mr-2 "><v-icon dark small >person</v-icon > <v-divider vertical class="mx-2"></v-divider>Assign</v-btn>
            <v-btn :dark="!bulkcheck" :disabled="bulkcheck" v-show="item.reassignIs" tile  style="font-size:12px;background-color:#E46A25;flex:1" @click="assign_reassign(item, 1)"  small  class="text-capitalize"><v-icon dark small >person</v-icon > <v-divider vertical class="mx-2"></v-divider>Reassign</v-btn> 
        </v-layout>
    </template> 
    <template v-slot:item.assignToUser="{item}" >
        <v-layout class=" pa-0 ma-0 mt-4" justify-space-between style="flex:1">
        <v-select
            label="Select User"
            v-model="item.assignToId"
            :items="role_data" 
            return-object
            item-text="employeeName"
            item-value="_id"
            solo
            dense
            class="rounded-0"
        ></v-select>
        </v-layout>
    </template>
      </v-data-table>

      <v-divider></v-divider>
                
                <v-layout row justify-end align-center class="ma-2" >
                    <v-text class="mr-1 font-weight-bold" style="color:#3C3D3D; font-size:13px;">
                        <span class="" style="color:#E46A25;font-size:12px;">Showing {{fval}}-{{sval}} </span>out of {{totalLen}} records
                    </v-text>
                    <!-- <v-divider  class="mx-2" vertical ></v-divider> -->
                    <v-pagination
                        class="pdct-stUp-bnkAcc-pgn"
                        v-model="page"
                        color="#152F38"
                        flat
                        @input="pagination_start(page)"
                        :total-visible="9"
                        :length="pagelength"
                    ></v-pagination>
                </v-layout>
        </v-card>

    </v-app>  
</template>

<script>

import axios from "axios";
export default {

    created(){
        console.log("Success Token", this.$store.getters.successToken)
         this.token=this.$store.getters.successToken
         
        console.log(this.$router.history.current.fullPath,"Route name");
        this.set_custom_headers();
        this.change_header_name(this.$router.history.current.fullPath)
        this.set_route_for_page = this.$router.history.current.fullPath
        this.set_table_data(this.$router.history.current.fullPath, 'normal')
        this.GetTATcount(this.$router.history.current.fullPath)
        console.log("screen Role name is--->>>", this.claims_role)
        this.$store.commit("SAVE_PAGE_HEADER", this.claims_role);
    },
    computed: {},
    watch: {
        $route(to, from){
            // react to route change
            console.log(to,"Route name",from)
            this.set_route_for_page = to.fullPath
            this.GetTATcount(to.fullPath)
            this.set_table_data(to.fullPath, 'normal');
            this.change_header_name(to.fullPath)
            this.clear_filtermodels();
        }
    },
    data(){
        return{
            nearTATfltr:false,
            exceedTATfltr:false,
            copied_data:false,
            copy_table:[],
            bulk_role_id:null,
            bulkcheck:false,
            reassignbtn:false,
            assignbtn:true,
            filter_status:false,
            sortable_params:{}, 
            type_for_pagination:'',
            set_route_for_page:'',
            pagenum:1,
            role_data:[],
            headers:[
                {text: 'Claim ID', value: 'claimId',sortable:false,divider:true,class:"hdr-txt-clr-WrkItemQue hdr-bg-clr-WrkItemQue srNoWidth1"},
                {text: 'Inward ID', value: 'inwardId',sortable:false,divider:true,class:"hdr-txt-clr-WrkItemQue  hdr-bg-clr-WrkItemQue"},
                {text: 'Claim Type', value: 'claimType',sortable:false,divider:true,class:"hdr-txt-clr-WrkItemQue hdr-bg-clr-WrkItemQue"},
                {text: 'Claim Sub Type', value: 'claimSubType',sortable:false,divider:true,class:"hdr-txt-clr-WrkItemQue hdr-bg-clr-WrkItemQue"},
                {text: 'Claim Status', value: 'claimStatus',sortable:false,divider:true,class:"hdr-txt-clr-WrkItemQue hdr-bg-clr-WrkItemQue"},
                {text: 'Claim Sub-Status', value: 'claimSubStatus',sortable:false,divider:true,class:"hdr-txt-clr-WrkItemQue hdr-bg-clr-WrkItemQue"},
                {text: 'Flags', value: 'flags',sortable:false,divider:true,class:"hdr-txt-clr-WrkItemQue hdr-bg-clr-WrkItemQue"},
                {text: 'Ageing', value: 'ageing',sortable:false,divider:true,class:"hdr-txt-clr-WrkItemQue hdr-bg-clr-WrkItemQue"},
                {text: 'Assign to User', value: 'assignToUser',sortable:false,divider:true,class:"hdr-txt-clr-WrkItemQue hdr-bg-clr-WrkItemQue"},
                {text: 'Action', value: 'action',sortable:false,divider:true,class:"hdr-txt-clr-WrkItemQue hdr-bg-clr-WrkItemQue"},
            ],
            store_header_index:null,
            totalwrkitm:'',
            nearTAT:'',
            exceededTAT:'',
            claimTypeList:['Cashless','Reimbursement'],
            claimType:'',
            claimStatusList:['Open','Closed','Cancelled','Suspended'],
            claimStatus:'',
            claimSubStatusList:['Queried','Inactive Policy','Invalid Policy','NIDB','RI Missing','Reimbursement Registered',
                                    'Medical Approved','Queried','Rejected','No query response','Partially Paid','Paid',
                                    'Re-opened','Cashless Registered','Commercial Approved','Junk'],
            claimSubStatus:'',
            flaglist:['Co-Issurance','VIP','Senior Citizen','Suspected','Social Media Complaint','64VB Compliance','NEFT',
                        'CKYC','Death','Employee'],
            flag:'',
            table_data:[],

            swap_final_count:false,
            fval:0,
            sval:0,
            totalLen:0,
            showrsltnotfound:false,
            page: 1,
            pagelength:0,
            itemsPerPage: 10,
            claims_role:'',
        }
    },
    methods:{

        TATFILTERS(indata){
            if(indata == 1){
                    this.nearTATfltr = true
                    this.exceedTATfltr = false
            }else{
                this.nearTATfltr = false
                this.exceedTATfltr = true
            }
                
            this.set_table_data(this.set_route_for_page,'filter',
                    {CT: this.claimType,CSS: this.claimSubStatus,
                    CS: this.claimStatus,FL: this.flag,
                    NTAT:this.nearTATfltr,ETAT:this.exceedTATfltr})
        },
        
        restore_edit(ident){
                if(ident == 1){
                    if(!this.bulkcheck){
                        if(!this.copied_data) return
                        this.table_data = this.copy_table
                        this.copy_table = []
                        this.copied_data = false
                        this.bulk_role_id = null
                    }else{
                        this.copy_table = JSON.parse(JSON.stringify(this.table_data));
                        this.copied_data = true
                    } 
                }else{
                        this.copy_table = []
                        this.copied_data = false
                        this.bulk_role_id = null
                }
        },

        bulkassignnow(){

            console.log('this.bulk_role_id ', this.bulk_role_id._id)
         
            console.log("restore to old state -->",this.bulk_role_id  );
            for(let i=0; i<this.table_data.length; i++){
                 this.table_data[i].assignToId = this.bulk_role_id._id
                 this.table_data[i].assignToName = this.bulk_role_id.employeeName
            }

            console.log("table_data ", this.table_data);
            console.log('this.copy table data ', this.copy_table)
        },

        confirm_bulk_assign(){
               this.assign_reassign({}, 2)
        },

        change_header_name(set_route_for_page){

            if(set_route_for_page == '/supervisordataentryqueue'){
                
                this.claims_role = 'Supervisor Data Entry Queue'
                console.log("screen data entry Role name is--->>>", this.claims_role)
            }else if(set_route_for_page == '/supervisorcodingqueue'){
                
                this.claims_role = 'Supervisor Coding Queue'
                console.log("screen coding queue Role name is--->>>", this.claims_role)
            }else if(set_route_for_page == '/supervisormedicalscrutinyqueue'){
                this.claims_role = 'Supervisor Medical Scrutiny Queue'
            }else if(set_route_for_page == '/supervisorcommercialscrutinyqueue'){
                this.claims_role = 'Supervisor Commercial Scrutiny Queue'
            }else if(set_route_for_page == '/supervisorpaymentqueue'){
                this.claims_role = 'Supervisor Payment Queue'
            }else if(set_route_for_page == '/internalinvestigation'){
                this.claims_role = 'Supervisor Internal Investigation'
            }else if(set_route_for_page == '/ofr'){
                this.claims_role = 'Supervisor OFR'
            }else if(set_route_for_page == '/supervisorquickempanelmentqueue'){
                this.claims_role = 'Supervisor Quick Empanelment Queue'
            }else if(set_route_for_page == '/negotiation'){
                this.claims_role = 'Supervisor Negotiation'
            }else if(set_route_for_page == '/cfr'){
                this.claims_role = 'Supervisor CFR'
            }

            console.log("screen Role name is--->>>", this.claims_role)
        this.$store.commit("SAVE_PAGE_HEADER", this.claims_role);


        },

        GetTATcount(indata){
            
                
                let self=this      
                

                 let claims_role_is = '';

            if(indata == '/supervisordataentryqueue'){
                claims_role_is = 'Data entry Queue'
            }else if(indata == '/supervisorcodingqueue'){
                claims_role_is = 'Coding Queue'
            }else if(indata == '/supervisormedicalscrutinyqueue'){
                claims_role_is = 'Medical Scrutiny Queue'
            }else if(indata == '/supervisorcommercialscrutinyqueue'){
                claims_role_is = 'Commercial Scrutiny Queue'
            }else if(indata == '/supervisorpaymentqueue'){
                claims_role_is = 'Payment Queue'
            }else if(indata == '/internalinvestigation'){
                claims_role_is = 'Internal investigation'
            }else if(indata == '/ofr'){
                claims_role_is = 'OFR'
            }else if(indata == '/supervisorquickempanelmentqueue'){
                claims_role_is = 'Quick empanelment Queue'
            }else if(indata == '/negotiation'){
                claims_role_is = 'Negotiation'
            }else if(indata == '/cfr'){
                claims_role_is = 'CFR'
            }
                
                axios({
                    method: "get",
                    
                      url: self.API_Service_ClaimsManagement()+'claim-management/get-tat-count-by-role/'+claims_role_is,
                    //   url:"http://claimsdrive-health.salesdrive.app/claim-management/claim-management-service/api/claim-management/get-tat-count-by-role/"+claims_role_is,
                    
                    
                    headers: {
                        "Content-Type": "application/json",
                        "x-access-token":self.token,
                    },
                
                })
                .then(function (response) {
                    let apiData = response.data.data

                    console.log("TAT API RESPONSE for ALL Network:::::",apiData);
                  
                    self.GetTATData = []
                    self.GetTATData= apiData
                    console.log(" TAT api responce---->>>>>>", self.GetTATData);
                    self.totalwrkitm= apiData.totalWorkItems
                    self.exceededTAT= apiData.exceedTAT
                    self.nearTAT= apiData.nearTAT

                    // self.showToast(response.data.msg,self.TOST().SUCCESS)
                    console.log("TAT api success msg--->>>>>>", response.data.msg);
                                       
                })
                .catch(function (error) {
                    console.log("TAT list Error>>>>>>", error);
                    self.showToast(error.response.data.msg,self.TOST().ERROR);
                });
            
        },

        clearFilter(){

            if((this.claimType===''||this.claimType===undefined||this.claimType===null) 
            && (this.claimSubStatus===''||this.claimSubStatus===undefined||this.claimSubStatus===null) 
            && (this.claimStatus===''||this.claimStatus===undefined||this.claimStatus===null)
            && (this.flag===''||this.flag===undefined||this.flag===null) 
            ){
                return
            }else{
                
                this.clear_filtermodels();
                
                if(this.sort_status){
                    this.set_table_data(this.set_route_for_page, 'sort', this.sortable_params)
                }else{
                    this.set_table_data(this.set_route_for_page, 'normal')
                }
                // this.set_table_data(this.$router.history.current.fullPath, 'normal')
                this.page=1;
                console.log('cancel search data',)
                this.$refs.form.resetValidation()
                this.filter_status = false   
            }
        },

        clear_filtermodels(){
                this.claimType=''
                this.claimSubStatus=''
                this.claimStatus=''
                this.flag=''
                this.nearTATfltr = false
                this.exceedTATfltr = false
        },

        actionNEW(indata){
            console.log(indata)
        },

        assign_reassign(item, ident){
            console.log('assign_reassign data',item)  
            console.log(item.hasOwnProperty('assignToId'),"assign hasown prop")
            
            if(ident == 1){
                if(!item.hasOwnProperty('assignToId')){
                    this.showToast("Select user to assign the work item",this.TOST().ERROR);
                    return
                }

                if(item.hasOwnProperty('assignToId')){
                    if(!item.assignToId.hasOwnProperty('employeeName')){
                    this.showToast("Select user to assign the work item",this.TOST().ERROR);
                    return
                    }
                }
            }

            let demodata = [];

            if(ident == 1){
               demodata.push({
                    id: item._id,
                    assignToId: item.assignToId._id,
                    assignToName: item.assignToId.employeeName
                })
            }else{
                for(let i=0; i<this.table_data.length; i++){
                    demodata.push({
                        id: this.table_data[i]._id,
                        assignToId: this.table_data[i].assignToId,
                        assignToName: this.table_data[i].assignToName
                    })
                }
            }
                
                if(this.bulk_role_id == null && ident == 2){
                    this.showToast("Please Select a Role to Continue" ,this.TOST().ERROR);
                    return
                }
                if(demodata.length == 0){
                    console.log(item,'bulk data for API is ', demodata, " ident is ", ident)
                    this.showToast("Something went wrong, No data found for Bulk Assignment" ,this.TOST().ERROR);
                    return
                }
                console.log(item, "from demodata--------->",demodata);

                // return
                var self=this
                axios({
                    method: "put",
                    url: self.API_Service_ClaimsManagement()+'claim-management/assign-user',
                    // url:'http://claimsdrive-health.salesdrive.app/claim-management/claim-management-service/api/claim-management/assign-user',
                    headers: {
                        "Content-Type": "application/json",
                        "x-access-token":self.token,
                    },
                    data:demodata,
                })
                .then(function (response) {
                    console.log(" assign_reassign responce>>>>>>", response);
                    // self.resetfrm();
                    self.showToast(response.data.msg,self.TOST().SUCCESS)
                    console.log(" assign_reassign responce success msg--->>>>>>", response.data.msg);
                    // self.page=1
                    // self.wrkitmdta(self.page)
                    console.log(" assign_reassign responce --->>>>>>", response.data.msg);
                    // self.assignbtn=false
                    // self.reassignbtn=true

                    self.set_table_data(self.$router.history.current.fullPath, 'normal')
                    self.set_custom_headers()
                    

                })
                .catch(function (error) {
                    console.log("assign_reassign product Error>>>>>>", error);
                    self.showToast(error.response.data.msg,self.TOST().ERROR);
                });

        },


        pagination_start(indata){
            this.pagelength == indata ?  this.swap_final_count = true : this.swap_final_count = false
            if(this.type_for_pagination == 'filter'){
                this.set_table_data(this.set_route_for_page,'filter',{CT:this.claimType,
                CSS:this.claimSubStatus,CS:this.claimStatus,FL:this.flag, NTAT:this.nearTATfltr,
                ETAT:this.exceedTATfltr})
            }else if(this.type_for_pagination == 'sort'){
                this.set_table_data(this.set_route_for_page,'sort', this.sortable_params)
            }else if(this.type_for_pagination == 'normal'){
                this.set_table_data(this.set_route_for_page,'normal','')
            }
        },

        get_roles(){
            try {
                    // /users/get-user-list-by-rolename/{role}
            } catch (error) {
                
            }
        },

        set_custom_headers(){
            //ssetting new key for tracking sorting order for a particular column, along with index mapping to new key
             this.headers = this.headers.map((e, index)=>{
                 return {...e,index: index, stype:' ' }
             }) 
        },

        headercontrol(indata){
            console.log("getting header click now ?", indata)

                    if(indata.stype == ' ' &&  this.store_header_index != indata.index && indata.index!=7 && indata.index!=8 && indata.index!=9){
                            // ASC - from none
                            this.headers[indata.index].stype = 'asc'
                            if(this.store_header_index != null){
                                this.headers[this.store_header_index].stype = ' '
                            }
                            this.store_header_index = indata.index

                    }else if(indata.stype == 'dsc'){
                            // ASC - from DSC
                            this.headers[indata.index].stype = ' '
                            this.store_header_index = null

                    }else if(indata.stype == 'asc'){
                            //DSC - from ASC
                            this.headers[indata.index].stype = 'dsc'
                            this.store_header_index = indata.index
                    }

                    console.log(this.headers[indata.index].stype);
                    // return
                  let srtby = this.headers[indata.index].stype == 'asc' ? '1' : '-1'
                  

                  if(this.headers[indata.index].stype == ' '){
                      this.sort_status = false;
                      let setting_type_outter = {type:'normal', params:''}
                      if(this.filter_status){
                          setting_type_outter.type = 'filter'
                          setting_type_outter.params = {CT:this.claimType,
                          CSS:this.claimSubStatus,CS:this.claimStatus,FL:this.flag,
                          NTAT:this.nearTATfltr,
                          ETAT:this.exceedTATfltr}
                      }
                        
                      this.set_table_data(this.set_route_for_page, setting_type_outter.type, setting_type_outter.params)
                      return
                  }
                    var sortable_parameters = {
                        sortBy_data:'&sortBy='+indata.value+'&sortdirection='+srtby
                    }

                    console.log("sortable_parameters ---> ", sortable_parameters);
                    this.sortable_params = sortable_parameters
                    this.set_table_data(this.set_route_for_page, 'sort', sortable_parameters)
                    this.headers.map(e=> {
                        if(e.stype == ' '){
                            e.stype = ' '
                        }
                    }) 
        //this.headers[indata.index].stype=earlier == 'asc' ? 'dsc' : 'asc'
      },

        set_table_data(indata, type, custom_params){
            var claims_role_is = '';

            if(indata == '/supervisordataentryqueue'){
                claims_role_is = 'Data entry Queue'
            }else if(indata == '/supervisorcodingqueue'){
                claims_role_is = 'Coding Queue'
            }else if(indata == '/supervisormedicalscrutinyqueue'){
                claims_role_is = 'Medical Scrutiny Queue'
            }else if(indata == '/supervisorcommercialscrutinyqueue'){
                claims_role_is = 'Commercial Scrutiny Queue'
            }else if(indata == '/supervisorpaymentqueue'){
                claims_role_is = 'Payment Queue'
            }else if(indata == '/internalinvestigation'){
                claims_role_is = 'Internal investigation'
            }else if(indata == '/ofr'){
                claims_role_is = 'OFR'
            }else if(indata == '/supervisorquickempanelmentqueue'){
                claims_role_is = 'Quick empanelment Queue'
            }else if(indata == '/negotiation'){
                claims_role_is = 'Negotiation'
            }else if(indata == '/cfr'){
                claims_role_is = 'CFR'
            }
       
        let build_api = 'claim-management/get-claim-list-by-role?role='+claims_role_is;
        console.log("type is ", type);
        if(type == 'filter'){
            this.filter_status = true
            build_api = build_api+'&claimType='+custom_params.CT+'&claimSubStatus='+custom_params.CSS+'&claimStatus='+custom_params.CS+'&flag='+custom_params.FL+'&nearingTAT='+custom_params.NTAT+'&exceedTAT='+custom_params.ETAT
            if(this.sort_status){
                  build_api = build_api+this.sortable_params.sortBy_data
            }
            console.log(custom_params,'<- from filter ', build_api);
        }

        if(type == 'sort'){
            this.sort_status = true
        
            build_api = build_api+custom_params.sortBy_data;
             console.log(custom_params,'from sort ', build_api);
             if(this.filter_status){
                
                  let custp = {CT:this.claimType,
                               CSS:this.claimSubStatus,
                               CS:this.claimStatus,
                               FL:this.flag,
                               NTAT:this.nearTATfltr,
                               ETAT:this.exceedTATfltr}   
                  build_api = build_api+'&claimType='+custp.CT+'&claimSubStatus='+custp.CSS+'&claimStatus='+custp.CS+'&flag='+custp.FL+'&nearingTAT='+custp.NTAT+'&exceedTAT='+custp.ETAT
             }
        }


        this.type_for_pagination = type

        build_api = build_api+'&perPage=20&pageNo='+this.page

        console.log(this.type_for_pagination,'build_api -> ', build_api);
        
            try{
                this.GET(7, build_api, (res,error)=>{
                    console.log("reponse of api is ", res.data)
                    if(res != null){

                        if(!res.data.hasOwnProperty('data')){
                            this.showToast(res.data.msg ,this.TOST().ERROR);
                            this.table_data = [];
                            this.fval = 0
                            this.sval = 0 
                            this.totalLen = 0
                            return
                        }
                       
                        this.table_data = res.data.data.data;                       
                        this.table_data = this.table_data.map((e,index)=>{
                            let myObject={...e,
                                            assignIs : e.hasOwnProperty('assignToId') ? e.assignToId.length !=0 ? false : true : true,
                                            reassignIs : e.hasOwnProperty('assignToId') ? e.assignToId.length !=0 ? true : false : false}
                            console.log("reponse from map myobject data api is =======", myObject)
                            return myObject
                                    
                        })


                   this.bulkcheck = false
                   this.restore_edit(2)
                //pagination 
                    this.pagelength=res.data.data.total_pages
                    console.log(this.page,' <-- pageindu | next btn, pg length is ----->',this.pagelength)
                     this.totalLen=res.data.data.total
                     let l = this.table_data.length
                    // console.log( 20*this.page,'skip simulation ');

                        var less_enough = this.totalLen
                        var checkless_init = false
                        if(less_enough < 20){
                            checkless_init = false
                        }else{
                            checkless_init = true
                        }

                        // if less than 15 we have second value same as total value as no pagination will occur
                        if(checkless_init){
                                // checkinit is true means the final count is more than 15 (- 1)
                                var traverse =  20*this.page
                                this.fval = traverse-19
                                if(this.swap_final_count){
                                    this.sval =  this.totalLen
                                }else{
                                    this.sval = traverse
                                }
                        }else if(this.totalLen == 0){
                          this.sval = this.fval = 0
                       }else{
                             this.fval = 1
                             this.sval = this.totalLen
                        }
						console.log(" table DATA is---->>::",this.table_data);
                    }else{
                        this.showToast(res.data.msg ,this.TOST().ERROR);
                    }
                })

                //         console.log("reponse from map tble data api is =======", this.table_data)
                //     }else{
                //         this.showToast(res.data.msg ,this.TOST().ERROR);
                //     }
                // })
            }catch(error){
               console.log("error -> ", error)
            }
           this.get_roles(claims_role_is);
        },

        get_roles(claims_role_is){
            let build_api = 'users/get-user-list-by-rolename/'+claims_role_is
            try{
                
                 this.GET(6,build_api, (res,error)=>{
                    if(res != null){
                         console.log("reponse of api is ", res.data.data)
                         this.role_data = res.data.data;

                       

                         console.log("rolename reponse  ", this.role_data)
                    }else{
                         this.showToast(res.data.msg ,this.TOST().ERROR);
                    }
                })
            }catch(error){
               console.log("error -> ", error)
            }
        }


    }
    
}
</script>
<style>
.hdr-txt-clr-WrkItemQue {
    color: white !important;
    font-weight: bold;
    
}
.hdr-bg-clr-WrkItemQue{
  background-color: #23B1A9 !important;
  color: #ffffff !important;
}

</style>