<template >
    <v-app class="mainapp"  id="print">
         <v-form ref="transhistoryForm"  @submit.prevent="searchTransactions" style="font: normal normal bold 16px/19px Roboto;">
                <v-row class="row_line">
                    <v-flex xs2 class="mrright" >
                        <v-layout column class="mx-2 my-2">
                            <p class="font-weight-bold cstm-clr-pr-quick">Claim Number</p>
                            <v-text-field
                                v-model="transHistoryForm.mainClaimNumber"
                                placeholder="Enter.."
                                class="rounded-0"                  
                                solo 
                                dense            
                                :disabled="true"
                            ></v-text-field>
                        </v-layout>
                    </v-flex>
                    <v-flex xs2 class="mrright" >
                        <v-layout column class="mx-2 my-2">
                            <p class="font-weight-bold cstm-clr-pr-quick">Feature</p>
                            <v-select
                                v-model="transHistoryForm.featureNumber"
                                :items="featureNumberOptions"
                                placeholder="Select"
                                solo
                                dense
                                @change="filterFeature"
                            ></v-select>
                        </v-layout>
                    </v-flex>
                    <v-flex xs2 class="mrright" >
                        <v-layout column class="mx-2 my-2">
                            <p class="font-weight-bold cstm-clr-pr-quick">Currency</p>
                            <v-select
                                v-model="transHistoryForm.currency"
                                :items="currencyOptions"
                                item-text="name"
                                placeholder="Select"
                                solo
                                dense
                                @change="goToNextTransPage(1)"
                            ></v-select>
                        </v-layout>
                    </v-flex>
                    <v-flex xs2 class="mrright" >
                        <v-layout column class="mx-2 my-2">
                            <p class="font-weight-bold cstm-clr-pr-quick">Account Line</p>
                            <v-row >
                                <v-flex >
                                    <v-checkbox
                                        v-model="transHistoryForm.accountLine"
                                        label="Indemnity"
                                        value="Indemnity"
                                        @change="goToNextTransPage(1)"
                                    ></v-checkbox>
                                </v-flex>
                                <v-flex >
                                    <v-checkbox
                                        v-model="transHistoryForm.accountLine"
                                        label="Expense"
                                        value="Expense"
                                        @change="goToNextTransPage(1)"
                                    ></v-checkbox>
                                </v-flex>
                            </v-row>
                        </v-layout>
                    </v-flex>
                    <v-flex xs3 class="mrright" >
                        <v-layout column class="mx-2 my-2">
                            <p class="font-weight-bold cstm-clr-pr-quick">Order By</p>
                            <v-row >
                                 <v-flex>
                                    <v-radio-group 
                                        row
                                        v-model="transHistoryForm.orderBy"
                                        @change="goToNextTransPage(1)">                                                                        
                                        <v-radio
                                            label="Account Line"
                                            value="accountLine"
                                        ></v-radio>                                    
                                        <v-radio
                                            label="Date of Transaction"
                                            value="transactionDate"
                                        ></v-radio>                                        
                                    </v-radio-group>
                                </v-flex>
                            </v-row>
                        </v-layout>
                    </v-flex>
                </v-row>
         </v-form>
         <v-divider></v-divider>
         <!-- <v-row > -->
            <v-flex xs12>
                <v-layout column class="mt-2 cust_pr_quick cust-rmvpd-pr-quick font-weight-bold graybackground" style="padding:10px;color:#000000;">
                    Main Transaction 
                </v-layout>
            </v-flex>
        <!-- </v-row> -->
        <v-flex id="mainTransTable" xs12>
            <v-data-table
                hide-default-footer
                :headers="mainTransHeaders"
                :items="mainTransList"
                class="vDataTableStyling"
                style="cursor:pointer"
               >
                <template v-for="header in mainTransHeaders.filter((header) => header.hasOwnProperty('formatter'))" v-slot:[`item.${header.value}`]="{ header, value }">
                    {{ header.formatter(value) }}
                    
                </template>
                <template v-slot:item="{ item }">
                <tr>
                <td class="hyperLink"  @click="showRIDetails(item)" >{{ item.featureNumber }} </td>
                <td >{{ item.accountLine  }}</td>
                <td >{{ item.transactionDate ? formatDate(item.transactionDate) : "" }}</td>
                <td >{{ item.paymentNumber }}</td>
                <td >{{ item.transactionType }}</td>
                <td >{{ item.fixReInsurance }}</td>
                <td >{{item.tagicCoInsuranceShare }}</td>
                <td >{{item.transactionAmount}} </td>
                <td >{{ item.gst }}</td>
                <td >{{ item.withholdingTax }}</td>
                <td >{{ item.chequeNo }}</td>
                <td >{{ item.userId }}</td>
                <td >{{ item.approverId }}</td>
                <td >{{ item.reason }}</td>
                <td >{{ item.paymentDate }}</td>
                <td >{{ item.baseAmount }}</td>
                <td >{{ item.currency }}</td>
                <td >{{ item.exchangeRate }}</td>
                <td >{{ item.amountInForeignCurrency }}</td>
                <td >{{ item.pushedToGc }}</td>
                <td >{{ item.pushedToGcDateAndTime ? new Date(item.pushedToGcDateAndTime).toLocaleString("en-IN").toUpperCase() : "" }}</td>
                <td >  
                    <v-btn
                    v-if="item.transactionInput"
                    color="#152f38"
                    class="buttonscolor mr-5"
                    dark
                    @click="showMoreTransactionInOut(1,item)"
                    max-height="25">
                    <v-icon class="mr-2 font-weight-bold fontClass">mdi-eye</v-icon>
                    <div class="vertical_lineBlack"></div> View
                    </v-btn>
                </td>
                 <td >
                    <v-btn
                    color="#152f38"
                    class="buttonscolor mr-5"
                    dark
                    @click="showMoreTransactionInOut(2,item)"
                    v-if="item.transactionOutput"
                    max-height="25">
                    <v-icon class="mr-2 font-weight-bold fontClass">mdi-eye</v-icon>
                    <div class="vertical_lineBlack"></div> View
                    </v-btn>
                </td>
            </tr>
          </template>
            </v-data-table>
        </v-flex>
        <div class="text-center graybackground" >
            <v-row>
                <v-col cols="6"></v-col>
                <v-col cols="6">
                    <v-row class="pagerow">
                        <v-spacer></v-spacer>
                    <div class="mrright"> 
                        <span class="orangetext"> Showing {{transPageStartIndex}}-{{transPageEndIndex}} </span>                         
                        <span> out of {{totalTransListCount}} records </span>
                    </div>                               
                    <v-pagination
                        v-model="transPage"
                        :length="getNoOfPagesTransHist"
                        prev-icon="mdi-arrow-left"
                        next-icon="mdi-arrow-right"
                        color="#152F38"                    
                        @input="goToNextTransPage"
                    ></v-pagination>
                    </v-row>
                </v-col>
            </v-row>
        </div>
        <!-- Transaction Table end -->
        <v-flex xs8>
            <v-layout column class="  font-weight-bold graybackground RItransaction" >
                RI Transaction 
            </v-layout>
        </v-flex>
        <v-flex xs8>
            <v-data-table
                hide-default-footer
                :headers="riTransHeaders"
                :items="riTransList"
               class="vDataTableStyling"
            >
                <template v-for="header in riTransHeaders.filter((header) => header.hasOwnProperty('formatter'))" v-slot:[`item.${header.value}`]="{ header, value }">
                    {{ header.formatter(value) }}
                </template>
            </v-data-table>
        </v-flex>
        <v-flex class="text-center graybackground" xs8>
            <v-row>
                <v-col cols="6"></v-col>
                <v-col cols="6">
                    <v-row class="pagerow" >
                        <v-spacer></v-spacer>
                    <div class="mrright"> 
                        <span class="orangetext"> Showing {{riDistPageStartIndex}}-{{riDistPageEndIndex}} </span>                         
                        <span> out of {{totalRIDistListCount}} records </span>
                    </div>                               
                    <v-pagination
                        v-model="riDistPage"
                        :length="getNoOfPagesRI"
                        prev-icon="mdi-arrow-left"
                        next-icon="mdi-arrow-right"
                        color="#152F38"
                    ></v-pagination>
                    </v-row>
                </v-col>
            </v-row>
        </v-flex>

        <v-flex class="graybackground mt-10 mb-5 printer" >
            <v-row>
               
                <v-col align="right" class="mt-5">
                    <v-btn
                        
                        color="#152F38"
                        @click.prevent="printPage()"
                        class="buttonscolor mr-5">
                        <v-icon class="mr-2 btnfont">mdi-printer</v-icon>
                        <div class="vertical_line"></div>Print
                    </v-btn>
                    <v-btn
                        color="#E46A25"
                        @click.prevent="closePage()"
                        class="buttonscolor mr-5">
                        <v-icon class="mr-2 btnfont" >mdi-close-circle</v-icon>
                        <div class="vertical_line"></div>cancel
                    </v-btn>
                </v-col>
            </v-row>
            
        </v-flex>  
        <div> 
		<v-row justify="center">
			<v-dialog v-model="opentransactionShowMenu" persistent max-width="50%" >
				<v-card height="50vh">
					<v-card-title class="specialComponetDailogboxVcardText pl-0 px-0 py-0">
						<v-row> <span class="specialComponetdescrptionSpan mb-5 ml-8">{{transactionInOutHeader}}</span>
							<v-icon @click="opentransactionShowMenu= false" class="ml-7 mt-0">mdi-window-close</v-icon>
						</v-row>
						<v-card-text class="specialComponetpopupBackGround">
							<div>{{transactionInOutValue}}</div>
						</v-card-text>
					</v-card-title>
				</v-card>
			</v-dialog>
		</v-row>
	</div>
    </v-app>
</template>

<script>
import moment from 'moment';
export default {
    data(){
        return {
            totalTransListCount     :   0,            
            transPage               :   1,           
            transPageStartIndex     :   1,
            pageRowCount            :   10,
            transHistoryForm        :   {
                mainClaimNumber     :   "",
                featureNumber   :   "All",
                currency        :   "",
                accountLine     :   ["Indemnity"],
                orderBy         :   "accountLine"
            },
            featureNumberOptions    :   ["All","A","B","C","D"],
            currencyOptions         :   [],
            mainTransList           :   [],
            mainTransHeaders        :   [
                {
                    text: 'Feature Number',
                    align: 'start',
                    sortable: true,
                    value: 'featureNumber'
                },   
                {
                    text: 'Account Line',
                    align: 'start',
                    sortable: false,
                    value: 'accountLine',
                },
                {
                    text: 'Transaction Date',
                    align: 'start',
                    sortable: true,
                    value: 'transactionDate',
                    formatter: this.formatDate
                },
                {
                    text: 'Payment Number',
                    align: 'start',
                    sortable: false,
                    value: 'paymentNumber',
                },
                {
                    text: 'Transaction Type',
                    align: 'start',
                    sortable: false,
                    value: 'transactionType',
                },
                {
                    text: 'Fix Re-Insurance',
                    align: 'start',
                    sortable: false,
                    value: 'fixReInsurance'
                },
                {
                    text: 'Tagic Co-Insurance Share',
                    align: 'start',
                    sortable: false,
                    value: 'tagicCoInsuranceShare',
                },    
                {
                    text: 'Transaction Amount',
                    align: 'start',
                    sortable: true,
                    value: 'transactionAmount'
                },
                {
                    text: 'GST',
                    align: 'start',
                    sortable: false,
                    value: 'gst'
                },
                {
                    text: 'With Holding Tax(TDS)',
                    align: 'start',
                    sortable: false,
                    value: 'withholdingTax'
                },
                {
                    text: 'Cheque Number',
                    align: 'start',
                    sortable: false,
                    value: 'chequeNo',
                },
                {
                    text: 'User Id',
                    align: 'start',
                    sortable: false,
                    value: 'userId',
                }, 
                {
                    text: 'Approver Id',
                    align: 'start',
                    sortable: false,
                    value: 'approverId',
                },
                {
                    text: 'Reason',
                    align: 'start',
                    sortable: false,
                    value:  'reason',
                },
                {
                    text: 'Payment Date',
                    align: 'start',
                    sortable: false,
                    value:  'paymentDate',
                },
                {
                    text: 'Base Amount',
                    align: 'start',
                    sortable: false,
                    value:  'baseAmount',
                }
                ,
                {
                    text: 'Currency Name',
                    align: 'start',
                    sortable: false,
                    value:  'currency',
                }
                ,
                {
                    text: 'Exchange Rate',
                    align: 'start',
                    sortable: false,
                    value:  'exchangeRate',
                }
                ,
                {
                    text: 'Amount in Foreign Currency',
                    align: 'start',
                    sortable: false,
                    value:  'amountInForeignCurrency',
                }
                ,
                {
                    text: 'Pushed to GC',
                    align: 'start',
                    sortable: false,
                    value:  'pushedToGc',
                }
                ,
                {
                    text: 'Pushed Date & Time',
                    align: 'start',
                    sortable: false,
                    value:  'pushedToGcDateAndTime',
                }
                ,
                {
                    text: 'Transaction Input',
                    align: 'start',
                    sortable: false,
                    value:  'transactionInput',
                }
                ,
                {
                    text: 'Transaction Output',
                    align: 'start',
                    sortable: false,
                    value:  'transactionOutput'
                }
            ],
            riTransHeaders          :   [
                {
                    text: 'Layer',
                    align: 'start',
                    sortable: false,
                    value: 'layer'
                },  
                {
                    text: 'Reinsurance Type',
                    align: 'start',
                    sortable: false,
                    value: 'reInsuranceType'
                }, 
                {
                    text: 'Reinsurance Co./Treaty',
                    align: 'start',
                    sortable: false,
                    value: 'reInsuranceCoTreaty'
                }, 
                {
                    text: 'Reinsurance Amount',
                    align: 'start',
                    sortable: true,
                    value: 'reInsuranceAmount'
                }, 
                {
                    text: 'Policy Distribution',
                    align: 'start',
                    sortable: false,
                    value: 'policyDistributionPerc'
                }
            ],
            riTransList             :   [],
            totalRIDistListCount    :   0,
            riDistPage              :   1,
            riDistPageStartIndex    :   0,
            transactionInOutHeader:'',
             transactionInOutValue:'',
             opentransactionShowMenu:false,
             IsFeaturelist:false
        }
    },
    
    methods:{
        showMoreTransactionInOut(text,data){
          if(text===1){
             this.transactionInOutHeader="Transaction Input";
             this.transactionInOutValue= data.transactionInput;
          }else{
            this.transactionInOutHeader="Transaction Output";
            this.transactionInOutValue=data.transactionOutput;
          }
          if(this.transactionInOutValue){
               this.opentransactionShowMenu=true;
           }else{
               this.showAlert("No Data Found.");
           }
        },
        printPage(){
            // console.log('mainTransList');
            // let prtHtml = document.getElementById('print').innerHTML;
            // let prtHtml = document.body;

            // let WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
            // WinPrint.document.write(`<!DOCTYPE html>
            //     <html>
            //         <head>
            //         </head>
            //         <body>
            //             ${prtHtml}
            //         </body>
            //     </html>`);
            window.print()
            // if(this.mainTransList.length > 0 || this.riTransList.length > 0) window.print();
        },

        closePage(){
            this.$router.go(-1);
        },

        searchTransactions(){
        },

        goToNextTransPage(page){
            //Surendra(05-04-2021):If both Accounline options are deselected, select Indeminity by default, starts here
            if(!this.transHistoryForm.accountLine.length){
                this.transHistoryForm.accountLine   =   ["Indemnity"]
            }
            //Surendra(05-04-2021):If both Accounline options are deselected, select Indeminity by default, ends here
            //Surendra(25-03-2021):Dummy data commented for service integration,code starts here
        /*    this.mainTransList      =   [{"featureNumber":"1234","accountLine":"Indemnity","transactionDate":"13/02/2021","paymentNumber":52302,"transaction":52340,"fixReinsurance":9022,"tagicCoInsuranceShare":"PandaMan","transactionAmount":20021,"gst":902,"withHoldingTax":890,"chequeNumber":13240,"userId":1234,"approverId":"A2123","reason":"NA"},
                                        {"featureNumber":"2345","accountLine":"Indemnity","transactionDate":"18/02/2021","paymentNumber":52302,"transaction":52340,"fixReinsurance":9022,"tagicCoInsuranceShare":"PandaMan","transactionAmount":20021,"gst":902,"withHoldingTax":890,"chequeNumber":13240,"userId":1234,"approverId":"A2123","reason":"NA"},
                                        {"featureNumber":"3456","accountLine":"Indemnity","transactionDate":"12/02/2021","paymentNumber":52302,"transaction":52340,"fixReinsurance":9022,"tagicCoInsuranceShare":"PandaMan","transactionAmount":20021,"gst":902,"withHoldingTax":890,"chequeNumber":13240,"userId":1234,"approverId":"A2123","reason":"NA"},
                                        {"featureNumber":"4567","accountLine":"Indemnity","transactionDate":"18/02/2021","paymentNumber":52302,"transaction":52340,"fixReinsurance":9022,"tagicCoInsuranceShare":"PandaMan","transactionAmount":20021,"gst":902,"withHoldingTax":890,"chequeNumber":13240,"userId":1234,"approverId":"A2123","reason":"NA"},
                                        {"featureNumber":"5678"},{"featureNumber":"6789"},{"featureNumber":"7890"},{"featureNumber":"8901"},
                                        {"featureNumber":"2321"},{"featureNumber":"1122"}];
            this.totalTransListCount    =     this.mainTransList.length;
            console.log("page - ",page)
            this.riTransList        =   [{"layer":"First","reinsuranceType":"Lorem Ipsum","reinsuranceTreaty":"Lorem Ipsum","reinsuranceAmount":"9022","policyDistribution":"Lorem Ipsum"},
            {"layer":"Second","reinsuranceType":"Lorem Ipsum","reinsuranceTreaty":"Lorem Ipsum","reinsuranceAmount":"9022","policyDistribution":"Lorem Ipsum"},
            {"layer":"Third","reinsuranceType":"Lorem Ipsum","reinsuranceTreaty":"Lorem Ipsum","reinsuranceAmount":"9022","policyDistribution":"Lorem Ipsum"},
            {"layer":"Fourth","reinsuranceType":"Lorem Ipsum","reinsuranceTreaty":"Lorem Ipsum","reinsuranceAmount":"9022","policyDistribution":"Lorem Ipsum"},
            {"layer":"Fifth","reinsuranceType":"Lorem Ipsum","reinsuranceTreaty":"Lorem Ipsum","reinsuranceAmount":"9022","policyDistribution":"Lorem Ipsum"},
            {"layer":"Sixth","reinsuranceType":"Lorem Ipsum","reinsuranceTreaty":"Lorem Ipsum","reinsuranceAmount":"9022","policyDistribution":"Lorem Ipsum"},
            {"layer":"Seventh","reinsuranceType":"Lorem Ipsum","reinsuranceTreaty":"Lorem Ipsum","reinsuranceAmount":"9022","policyDistribution":"Lorem Ipsum"},
            {"layer":"Eight","reinsuranceType":"Lorem Ipsum","reinsuranceTreaty":"Lorem Ipsum","reinsuranceAmount":"9022","policyDistribution":"Lorem Ipsum"},
            {"layer":"Nineth","reinsuranceType":"Lorem Ipsum","reinsuranceTreaty":"Lorem Ipsum","reinsuranceAmount":"9022","policyDistribution":"Lorem Ipsum"},
            {"layer":"Tenth","reinsuranceType":"Lorem Ipsum","reinsuranceTreaty":"Lorem Ipsum","reinsuranceAmount":"9022","policyDistribution":"Lorem Ipsum"}]
            */
            //Surendra(25-03-2021):Dummy data commented for service integration,code ends here
            var self    =   this;
            var reqData =   "";
            reqData =   reqData +   "perPage="+this.pageRowCount+"&pageNo="+this.transPage
            if(this.transHistoryForm.mainClaimNumber){
                reqData =   reqData +   "&mainClaimNumber="+this.transHistoryForm.mainClaimNumber;
            }            
            if(reqData && this.transHistoryForm.featureNumber && this.transHistoryForm.featureNumber !== "All"){
                reqData =   reqData +   "&featureNumber="+this.transHistoryForm.featureNumber
            }
            if(reqData && this.transHistoryForm.currency){
                reqData =   reqData +   "&currency="+this.transHistoryForm.currency
            }
            if(reqData && this.transHistoryForm.accountLine.length){
                reqData =   reqData +   "&accountLine="+JSON.stringify(this.transHistoryForm.accountLine)//+"]"
            }
            if(reqData && this.transHistoryForm.orderBy){
                reqData =   reqData +   "&orderBy="+this.transHistoryForm.orderBy
            }
            
                
            
            this.showProgress();
            this.GETM("GetTransactionHistory",reqData,(res)=>{
                console.log("GetTransactionHistory",res);
                if(res.data && res.data.data && res.data.data.data && res.data.data.data.length >0){
                    self.mainTransList          =   res.data.data.data;
                    self.totalTransListCount    =   res.data.data.total;
                    self.transPageStartIndex    =   page == 1 ? 1 : ((page - 1) * this.pageRowCount) + 1;

                    self.riTransList            =   [];//res.data.data.data[0].riDetails;
                    self.riDistPageStartIndex   =   0;//page == 1 ? 1 : ((page - 1) * this.pageRowCount) + 1;
                    self.totalRIDistListCount   =   0;//res.data.data.total;

                    self.currencyOptions        =   res.data.data.currencyList
                    if(this.IsFeaturelist==false){
                       self.featureNumberOptions   =   res.data.data.featureList
                    }
                   
                    self.featureNumberOptions.unshift("All")

                    self.hideProgress()
                }else{
                    self.hideProgress()
                    self.showAlert("No data available.");
                    self.mainTransList          =   [];
                    self.totalTransListCount    =   0;
                    self.transPageStartIndex    =   0;
                    self.riTransList            =   [];
                    self.totalRIDistListCount   =   0;
                    self.riDistPageStartIndex   =   0;
                }
            },(err)=>{
                self.hideProgress()
                console.log("GetTransactionHistory",err.response);
                 self.mainTransList          =   [];
                self.totalTransListCount    =   0;
                self.transPageStartIndex    =   0;
                self.riTransList            =   [];
                self.totalRIDistListCount   =   0;
                self.riDistPageStartIndex   =   0;
            })
        },
        filterFeature(){
          this.IsFeaturelist=true
          this.goToNextTransPage(1)
        },
       

        formatCurrency (value) {
            console.log(this.transHistoryForm.currency);
            if(value && this.transHistoryForm.currency){
                var self        =   this;
                var selectedCurrency    =   this.currencyOptions.find((elem)=>{
                    return elem.name    ==  self.transHistoryForm.currency
                });
                return selectedCurrency.sign + " " + value;
            }else{
                return value;
            }
            
        },
        formatDate(date){
            return date ? moment(date).format("DD/MM/YYYY") : "";
        },
        showRIDetails(selectedRow){
            console.log("selectedRow - ",selectedRow,typeof selectedRow.riDetails)
            if(!selectedRow.riDetails.length ){
                this.showAlert("No data for this feature.");
            }
            this.riTransList    =   selectedRow.riDetails;
            this.riDistPageStartIndex   =   (selectedRow.riDetails && selectedRow.riDetails.length > 0) ? 1 : 0;
            this.totalRIDistListCount   =   selectedRow.riDetails.length;
        }

    },

    computed:{ 
        // getNoOfPages(){
        //     return Math.ceil(this.totalTransListCount / this.pageRowCount);
        // },
        // pageEndIndex(){
        //     return ((this.transPageStartIndex + (this.pageRowCount)) <= this.totalTransListCount) ? this.transPageStartIndex + (this.pageRowCount) : this.totalTransListCount;
        // }
        getNoOfPagesTransHist(){
            if(!this.totalTransListCount){
                return 0;
            }else{
                return Math.ceil(this.totalTransListCount / this.pageRowCount);
            }
        },
        getNoOfPagesRI(){
            if(!this.totalRIDistListCount){
                return 0;
            }else{
                return Math.ceil(this.totalRIDistListCount / this.pageRowCount);
            }
        },
        transPageEndIndex(){
            if(!this.mainTransList){
                return 0
            }else{
                return ((this.transPageStartIndex + (this.pageRowCount)) <= this.totalTransListCount) ? this.transPageStartIndex + (this.pageRowCount) - 1 : this.totalTransListCount;
                
            }
        },
        riDistPageEndIndex(){
            if(!this.riTransList){
                return 0
            }else{
                return ((this.riDistPageStartIndex + (this.pageRowCount)) <= this.totalRIDistListCount) ? this.riDistPageStartIndex + (this.pageRowCount) - 1 : this.totalRIDistListCount;
                
            }
        },
        // mainTransListComputed() {
		// 	return this.mainTransList.map((item) => (
        //      {...item,
        //       pushedToGcDateAndTime : item.pushedToGcDateAndTime ? new Date(item.pushedToGcDateAndTime).toLocaleString("en-IN").toUpperCase(): "",
		// 	})
        //  );
		// },
    },
    watch:{
      accountLine(val){

      } 
    },
    mounted(){
        console.log("this.$route.params",this.$store.getters.fetchMainClaimNumberObj);
        this.transHistoryForm.mainClaimNumber = this.$store.getters.fetchMainClaimNumberObj;
         this.transPageStartIndex    =   0
        this.riDistPageStartIndex   =   0
        this.goToNextTransPage(1);
    }
}
</script>

<style scoped>
.hyperLink{
 color:blue  !important;
 text-decoration: underline  !important;
}
    .row_line {
        margin:10px;
    }

    .graybackground {
        background-color:  #F7F7F7;
    }
    .orangetext {
        color: #E46A25;
    }

    .vDataTableStyling {
        white-space: nowrap;
    }

    .buttonscolor {
        border-radius: 0 !important;
        height: 31px !important;
        color: #f5f6fa !important;
        text-transform: capitalize;
    }
    .mainapp{
      margin-top:20px;
    }
    .mrright{
        margin-right: 10px;
    }
    .pagerow{
      margin:10px;
      margin-top:10px;
      align-items: center;
    }
    .RItransaction{
       margin-top:20px;
       padding:10px;
       color:#000000;
    }
    .printer{
      width:862px;
      height:60px;
    }
    .btnfont{
      font-size: 18px
    }
.specialComponetpopupBackGround{
  background-color: #f7f7f7;
}
.specialComponetDailogboxVcardText {
  /* background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 7px #00000029;
  opacity: 1; */
  font-size: 14px;
}
.specialComponetdescrptionSpan {
  width: 85%;
  margin-left: 53px;
  margin-top: 21px;
  margin-bottom: 14px !important;
  font-weight: bold;
}

</style>