import axios from 'axios';
import * as URL from "../common/API_Config.js";

// function getConfig(token) {
//     axios.interceptors.request.use(function (config) {
//         config.headers.Authorization =  token;
//         return config;
//     });
// }
export const saveInvestigationFormDetail = async(tabFormDataId, payload, formName) => {
    const res = await axios.put(URL.commonModuleBaseUrl +`common-auxiliary/hos-and-acc-details/update-investigation-details/${tabFormDataId}?tabName=`+formName,
        payload,
        {headers:{
            "Content-Type": "application/json",
            "system": "Health"
        }}
    );
    return res;
}

export const getHospitalDetails = async(token) => {
    let res = await axios.get(URL.claimsModuleBaseURL+'claims-registration/claims-details/get-empanelment-detail/6412c50e91d7abf045b43cba',
        {headers:{
            "Content-Type": "application/json",
        }}
    )
    return res.data.data;
}

export const getPrePopulatedData = async(workitemId) => {
    const res = await axios.get(URL.commonModuleBaseUrl + 'common-auxiliary/hos-and-acc-details/get-investigation-details/'+workitemId,
        {headers:{
            "Content-Type": "application/json",
            "system": "Health"
        }}
    );
    return res.data.data[0];
}
export const getTabsData = async(reqData) => {
    const res = await axios.get(URL.commonModuleBaseUrl + 'common-auxiliary/hos-and-acc-details/get-investigation-details?'+reqData,
        {headers:{
            "Content-Type": "application/json",
            "system": "Health"
        }}
    );
    return res.data;
}
export const getPoliceStationDetails = async(pincode) => {
    const res = await axios.get(URL.commonModuleBaseUrl+'common-masterdata/policeStationMaster/get-police-station-data?pinCode=' + pincode,
        {headers:{
            "Content-Type": "application/json",
            "system": "Health"
        }}
    );
    return res.data.data[0];
}
export const getPoliceStationInfo = async(policeStationName) => {
    const res = await axios.get(URL.commonModuleBaseUrl+'common-masterdata/policeStationMaster/get-police-station-data?page=1&pageLimit=10&policeStationName=' + policeStationName,
        {headers:{
            "Content-Type": "application/json",
            "system": "Health"
        }}
    );
    return res.data.data.data;
}
export const getPolicyDetails = async(params) => {
    const res = await axios.get(`${URL.claimsModuleBaseURL}claims-registration/claimsResonDetails/get-policy-details?memberId=${params.memberId}&claimNumber=${params.claimNumber}&featureNumber=${params.featureNumber}&policyNumber=${params.policyNumber}`,
        {headers:{
            "Content-Type": "application/json"
        }}
    );
    return res.data.data[0];
}
export const getClaimDetails = async(params) => {
    const res = await axios.get(`${URL.claimsModuleBaseURL}claims-registration/claimsResonDetails/get-claims-details?memberId=${params.memberId}&claimNumber=${params.claimNumber}&featureNumber=${params.featureNumber}&policyNumber=${params.policyNumber}`,
        {headers:{
            "Content-Type": "application/json",
        }}
    );
    return res.data.data[0];
}

export const getIllnessDetails = async(token, params) => {
    const res = await axios.get(`${URL.claimsModuleBaseURL}claims-registration/claimsResonDetails/get-illness-details?memberId=${params.memberId}&claimNumber=${params.claimNumber}&featureNumber=${params.featureNumber}&policyNumber=${params.policyNumber}`,
        {headers:{
            "Content-Type": "application/json",
            "Authorization": token,
        }}
    );
    return res.data.data;
}
export const getInvestigationOutcome = async() => {
    const res = await axios.get(`${URL.commonModuleBaseUrl}common-masterdata/siu/get-inv-outcome`,
        {headers:{
            "Content-Type": "application/json"
        }}
    );
    return res;
}
export const getGroundOfDefence = async(investigationOutcome) => {
    const res = await axios.get(`${URL.commonModuleBaseUrl}common-masterdata/siu/get-groundOfDefence-data?investigationOutcome=${investigationOutcome}`,
        {headers:{
            "Content-Type": "application/json"
        }}
    );
    return res;
}
export const getAllPinCodes = async(params) => {
    const res = await axios.get(`${URL.getPincodes}?pageNo=1&perPage=100&pinCode=${params}`,
        {headers:{
            "Content-Type": "application/json"
        }}
    );
    let responseData = res?.data?.data?.data || [];
    let pincodes = []
        if (responseData.length > 0) {
              pincodes = [];
              pincodes = responseData.map((item) => {
                return {
                  locpincode: item.pinCode + " - " + item.pinCodeLocality,
                  ...item,
                };
              });
            }
    return pincodes;
}
export const getNotesData = async(token, endpoint) => {
    const res = await axios.get(`${URL.commonModuleBaseUrl}${endpoint}`,
    {
        headers: {
        "Content-Type": "application/json",
        "Authorization": token,
        system: "Health",
    }
}
);
return res.data.data;
}

export const postNotesData = async (payload) => {
  const res = await axios
    .post(
      `${URL.commonModuleBaseUrl}common-auxiliary/hos-and-acc-details/insert-notes`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          system: "Health",
        },
      });
      return res;
};

export const getInvoicePDFUrl = async (siuId) =>{
    const res = await axios.get(`${URL.commonModuleBaseUrl}common-auxiliary/investigtion/get-invoice-pdf-details-by-id/${siuId}`,
    {
        headers: {
            system: "Health",
        }
    })
    return res;
}