import { render, staticRenderFns } from "./viewdocument.vue?vue&type=template&id=07cc8180"
import script from "./viewdocument.vue?vue&type=script&lang=js"
export * from "./viewdocument.vue?vue&type=script&lang=js"
import style0 from "./viewdocument.vue?vue&type=style&index=0&id=07cc8180&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports