 <template>
    <v-app class="cust-prvdrTarrifSelct-Wrapper">
        <v-card class="mt-3 mb-3 ml-3 mr-3" tile>
            <v-divider></v-divider>
            <div class="disp-flex pa-3" style="background-color:#23B1A9">
                <v-text style="color:#fff" class="font-weight-bold">PROVIDER LIST</v-text>
            </div>
            <v-divider></v-divider>
            <v-data-table
                :headers="headers"
                :items="finalProvData"
                hide-default-footer
                v-model="selectTableData"
                :single-select="false"
                item-key="provName"
                disable-pagination>
                <template  v-slot:item.tariff={item}>
                    <td>
                        <!-- <img class="drp-arrow-icon drop-arrow-pos cursor-pointer-globle" src="https://www.materialui.co/materialIcons/navigation/arrow_drop_down_grey_192x192.png"></img>
                        <select class="custom-drpdown" v-model="item.tariff"  @change="tarrifDataFunc()">
                            <option v-for="(dat, li) in item.tarrifTable" :key="li" :value="dat.value">{{dat.text}}</option>
                        </select> -->
                         <v-select
                            v-model="item.tariff" 
                            :items="item.tarrifTable" 
                            @change="tarrifDataFunc(item.tariff,item.providerId,'new')"
                            return-object
                            label="Select"
                            item-text="value"
                            item-value="value"
                            solo
                            maxlength="50"
                            class="rounded-0 custom-drpdown" >
                        </v-select>
                    </td>
                </template>
            </v-data-table>
            <v-divider></v-divider>
            <!-- <v-layout row justify-end align-center class="pa-2">
                <span class="mr-1 font-weight-bold body-1" style="color:#3C3D3D; font-size:12px !important">
                <span class="" style="color:#E46A25;font-size:12px;">Showing {{fromvalue}}-{{toValu}} </span>out of {{totalCount}} records</span>
                <v-divider  class="ma-2" vertical ></v-divider>
                <v-pagination
                    class="pdct-stUp-bnkAcc-pgn"
                    v-model="page"
                    color="#152F38"
                    flat
                    @input="nextPageData(page)"
                    :length="pageCount"
                ></v-pagination>
            </v-layout> -->
        </v-card>

        <div class="mt-3 mb-5 ml-3 mr-3 pa-4" style="background-color:#F7F7F7;border:thin solid rgba(0, 0, 0, 0.12);">
            <v-layout style="justify-content: flex-end;" >
                <v-btn dark @click="returnToAddProvPage()" tile color="#10242b" class="text-capitalize px-3 mx-3" small><v-icon dark small>arrow_back</v-icon> <v-divider vertical class="mx-2"></v-divider>Return</v-btn>
                <v-btn dark  @click="submitData()" tile color="#23B1A9" class="text-capitalize px-3 mx-3" small><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider>Submit</v-btn>
            </v-layout>
        </div>

        <!-- </v-flex> -->
    </v-app>
</template>
<script>
import moment from 'moment';

export default {

    components:{
      
    },

    created() {
        this.$store.commit("SAVE_PAGE_HEADER", "Providers Tariff Selection");
        // console.log("=========(((((((((PARAMMMMSSSSSSS)))))))))=======>>",this.$route.params.providerData)
        
        this.getTableData()
        // this.getTarrifData()

    },

    computed: {
        computedTarrEffectDate(){
            return this.createTarrEffectDate ? moment(this.createTarrEffectDate).format("DD-MM-YYYY"): ''
        },
        computedTarrExpireDate(){
            return this.createTarrExpireDate ? moment(this.createTarrExpireDate).format("DD-MM-YYYY"): ''
        },
    },

    data() {
        return {
            newDataArray:[],
            provDataArrayAPI:[],
            oldProvData:'',
            newProvData:'',
            tarrifDropdwnData:'select',
            selectTableData: [],
            headers:[
                {text: 'PRN', value: 'providerPRN'},
                {text: 'Provider Name', value: 'providerName'},
                {text: 'Provider Type', value: 'providerType'},
                {text: 'Tariff Id', value: 'tariff'},
                {text: 'Tariff Name', value: 'tariffName'},
                {text: 'Tariff Status', value: 'status'},//tarrifStatus
                {text: 'Tariff Effective Date', value: 'tariffEffectiveDate'},
                {text: 'Tariff Expiration Date', value: 'tariffExpirationDate'},
            ],
            provTableData:[],
            finalProvData:[],
            provTblTarrifData:[
                {text:'Select',value:'select'},
                {text:'Volvo',value:'volvo'},
                {text:'Fiat',value:'fiat'},
                {text:'Audi',value:'audi'},
                {text:'BMW',value:'bmw'},
            ],
            rules: {
                required: value => !!value || 'This Field is Required.',
                minExtRefNo: value => {
                    // const pattern = /^([0-9])+$/
                    const pattern = /^[a-zA-Z0-9]+$/
                    return pattern.test(value) || 'This Field is Required.'
                },
            },
        }
    },

    methods: {
        tarrifDataFunc(ev,provID,type){
            // console.log("=========(((((((((tarrifDataFunc____)))))))))=======>>",ev)
            let self = this
            if(Object.keys(ev).length !== 0){
                self.finalProvData.map(function(el) { 
                    // console.log("=========(((((((((el)))))))))=======>>",el)
                    if(provID == el.providerId){ 
                        el.tariff = ev
                        el.tariffName = ev.text
                        el.status = ev.tarrifStatus
                        el.tariffId = ev.value
                        el.tariffEffectiveDate = new Date(ev.tariffEffectiveDate).toLocaleDateString()
                        el.tariffExpirationDate = new Date(ev.tariffExpirationDate).toLocaleDateString()
                        if(type == 'new'){
                            if(self.newDataArray.length !== 0){
                                let ItemIndex = self.newDataArray.findIndex(event => event.providerId == el.providerId);
                                if(ItemIndex !== -1){
                                    self.newDataArray.splice(ItemIndex, 1)
                                    self.newDataArray.push(el)
                                }else{
                                    self.newDataArray.push(el)
                                }
                            }else{
                                self.newDataArray.push(el)
                            }
                        }
                        // console.log("=========(((((((((newDataArray)))))))))=======>>",self.newDataArray)
                        // self.newDataArray.push(el)
                    }
                })
            }
        },
        getTableData(){
            let self = this
            let _table = this.$route.params.providerData
            this.provDataArrayAPI = []
            // This section is for the store data selected from the Poviders Table from Last Page
            if(_table !== undefined){
                let selectTariffData = {}
                for(let _prop of _table){
                    if(_prop.tarrifData.length !== 0){
                        if( _prop.tariffId !== '-'){
                            _prop.tarrifData.map(function(el) { 
                                if(_prop.tariffId == el.tariffId){
                                    let data = { 
                                        text:el.tariffName,
                                        value:el.tariffId,
                                        tarrifStatus:el.status,
                                        tariffEffectiveDate:el.tariffEffectiveDate,
                                        tariffExpirationDate:el.tariffExpirationDate,
                                    }
                                    selectTariffData = data
                                }
                            })
                        }else{
                            selectTariffData = {}
                        }
                    }
                    let _data = {
                        providerId: _prop.providerId,
                        providerRohiniId: _prop.rohini,
                        providerName: _prop.provName,
                        providerStatus: _prop.status,
                        providerRegisteredEntity: _prop.providerRegisteredEntity,
                        providerType: _prop.type,
                        providerPRN: _prop.prn,
                        providerRegistrationNo: _prop.providerRegistrationNo,
                        providerMobileNumber: _prop.providerMobileNumber,
                        providerPAN: _prop.providerPAN,
                        providerGSTIN: _prop.providerGSTIN,
                        providerAddress: _prop.address,
                        state: _prop.state,
                        city: _prop.city,
                        pincode: _prop.pincode,
                        district: _prop.district,
                        tariff:selectTariffData,
                        tariffName:'',
                        status:'',
                        tariffEffectiveDate:'',
                        tariffExpirationDate:'',
                        tarrifTable: _prop.tarrifData.length == 0 ? [] : _prop.tarrifData.map(function(el) { 
                                                                                let _data = { 
                                                                                    text:el.tariffName,
                                                                                    value:el.tariffId,
                                                                                    tarrifStatus:el.status,
                                                                                    tariffEffectiveDate:el.tariffEffectiveDate,
                                                                                    tariffExpirationDate:el.tariffExpirationDate,
                                                                                }
                                                                                return _data
                                                                            }),
                    }
                    this.provTableData.push(_data)
                }
            }
            
            // This section is for the Poviders data already present in the Network
            let netwrkIDStore = this.$store.state.createNetworkData._id
            this.GET(4,'network-management/get-network-by-id/' + netwrkIDStore,(res,error)=>{
                if(res !== null){
                    if(res.data.data.hasOwnProperty('providerDetails') == true){
                        let _resp = res.data.data.providerDetails
                        let tarrif_data = {}
                        if(_resp.length !== 0){
                            for(let _prop of _resp){
                                if(_prop.hasOwnProperty('tariffId') == true){
                                    if(_prop.tariffId !== ''){
                                        _prop.tariffDetails.map(function(el) { 
                                            if(_prop.tariffId == el.tariffId){
                                                let data = { 
                                                    text:el.tariffName,
                                                    value:el.tariffId,
                                                    tarrifStatus:el.status,
                                                    tariffEffectiveDate:el.tariffEffectiveDate,
                                                    tariffExpirationDate:el.tariffExpirationDate,
                                                }
                                                tarrif_data = data
                                            }
                                        })
                                    }else{
                                        tarrif_data = {}
                                    }
                                }else{
                                    tarrif_data = {}
                                }
                                
                                let _data = {
                                    providerId: _prop.providerId,
                                    providerRohiniId: _prop.providerRohiniId,
                                    providerName: _prop.providerName,
                                    providerStatus: _prop.providerStatus,
                                    providerRegisteredEntity: _prop.providerRegisteredEntity,
                                    providerType: _prop.providerType,
                                    providerPRN: _prop.providerPRN,
                                    providerRegistrationNo: _prop.providerRegistrationNo,
                                    providerMobileNumber: _prop.providerMobileNumber,
                                    providerPAN: _prop.providerPAN,
                                    providerGSTIN: _prop.providerGSTIN,
                                    providerAddress: _prop.providerAddress,
                                    state: _prop.state,
                                    city: _prop.city,
                                    pincode: _prop.pincode,
                                    district: _prop.district,
                                    tariff:tarrif_data,
                                    tariffName:'',
                                    status:'',
                                    tariffEffectiveDate:'',
                                    tariffExpirationDate:'',
                                    tarrifTable: _prop.tariffDetails.length == 0 ? [] : _prop.tariffDetails.map(function(el) { 
                                                                                            let _data = { 
                                                                                                text:el.tariffName,
                                                                                                value:el.tariffId,
                                                                                                tarrifStatus:el.status,
                                                                                                tariffEffectiveDate:el.tariffEffectiveDate,
                                                                                                tariffExpirationDate:el.tariffExpirationDate,
                                                                                            }
                                                                                            return _data
                                                                                        }),
                                }
                                this.provDataArrayAPI.push(_data)
                            }
                        }
                        this.finalProvData = this.provTableData.concat(this.provDataArrayAPI)
                        this.finalProvData.map(function(el) { 
                            let _data = { 
                                providerName: el.providerName, 
                                providerPRN: el.providerPRN,
                                providerId: el.providerId,
                                providerRohiniId: el.providerRohiniId,
                                providerStatus: el.providerStatus,
                                providerRegisteredEntity: el.providerRegisteredEntity,
                                providerType: el.providerType,
                                providerRegistrationNo: el.providerRegistrationNo,
                                providerMobileNumber: el.providerMobileNumber,
                                providerPAN: el.providerPAN,
                                providerGSTIN: el.providerGSTIN,
                                providerAddress: el.providerAddress,
                                state: el.state,
                                city: el.city,
                                pincode: el.pincode,
                                district: el.district,
                                tariffId: Object.keys(el.tariff).length !== 0 ? el.tariff.value : '',
                                tariffName: Object.keys(el.tariff).length !== 0 ? el.tariff.text : '',
                            }
                            self.tarrifDataFunc(el.tariff,el.providerId,'selected')
                            return _data
                        })
                        // this.provTableData.forEach(function(ev) { ev.tarrifData = [] });
                    }else{
                        this.finalProvData = this.provTableData
                    }
                    // console.log("=========(((((((((     finalProvData)))))))))=======>>",this.finalProvData)
                }
            })
        },
        providerTableData(ev){
            // console.log("=========(((((((((providerTableData)))))))))=======>>",ev)
        },
        
        returnToAddProvPage(){
            this.$router.push('/add-provider')
        },
        clearData(){
            this.addProvRohiniId = ''
            this.addProvEntityName = ''
            this.addProvEntityNameFilt = ''
            this.addProvPRN = ''
            this.addProvRegNum = ''
            this.addProvProvidrType = ''
            this.addProvPan = ''
            this.addProvGstIn = ''
            this.addProvProvidrStatus = ''
            this.addProvAddressCont = ''
            this.addProvState = ''
            this.addProvCityTown = ''
            this.addProvPincode = ''
            this.addProvDistrict = ''
            this.addProvClinicSpec = ''
            this.addProvOwnerType = ''
        },
        submitData(){
            let self = this
            let netwrkStore = self.$store.state.createNetworkData

            if(self.newDataArray.length !== 0){
                // let _finalArray = self.provTableData.filter(prov => self.newDataArray.some(tarr => tarr.providerId !== prov.providerId));
                let _finalArray = self.provTableData.filter( function(n) { return !this.has(n) }, new Set(self.newDataArray) );
                self.newDataArray = self.newDataArray.concat(_finalArray)
            }else{
                self.newDataArray = self.provTableData
            }
            // this.updateProvAPI(self.newDataArray ,netwrkStore._id)
            // return
            let formData = {
                networkName: netwrkStore.networkName,
                networkId: netwrkStore.networkId,
                networkWorkItemAssignDate: new Date(),
                empaneledProviders: self.newDataArray
                // empaneledProviders: this.finalProvData
            }
            // console.log("=========(((((((((provDataArrayAPI)))))))))=======>>",self.newDataArray)
            // return
            if(self.newDataArray.length !== 0){
                self.POST(4,'network-management/add-providers-to-work-item',formData,(res,error)=>{
                    if(!error){
                        if(res.data.statusCode === 200){
                            self.showToast(res.data.msg, self.TOST().SUCCESS);
                            // self.$router.push('/tarrif-approval')
                            let _provDataArr = []
                            for(let _data of self.provDataArrayAPI){
                                let _prov = {
                                    providerId: _data.providerId,
                                    providerRohiniId: _data.providerRohiniId,
                                    providerName: _data.providerName,
                                    providerStatus: _data.providerStatus,
                                    providerRegisteredEntity: _data.providerRegisteredEntity,
                                    providerType: _data.providerType,
                                    providerPRN: _data.providerPRN,
                                    providerRegistrationNo: _data.providerRegistrationNo,
                                    providerMobileNumber: _data.providerMobileNumber,
                                    providerPAN: _data.providerPAN,
                                    providerGSTIN: _data.providerGSTIN,
                                    providerAddress: _data.providerAddress,
                                    state: _data.state,
                                    city: _data.city,
                                    pincode: _data.pincode,
                                    district: _data.district,
                                    tariffId: Object.keys(_data.tariff).length !== 0 ? _data.tariff.value : '',
                                    tariffName: Object.keys(_data.tariff).length !== 0 ? _data.tariff.text : '',
                                }
                                _provDataArr.push(_prov)
                            }
                            self.$router.push({name: 'TarrifApproval',params: {prevProvData: _provDataArr , selectedTariffData : self.newDataArray }});
                        }else{
                            self.showToast(res.data.msg ,self.TOST().ERROR); 
                        }
                    }else{
                        self.showToast(error.response.data.msg, self.TOST().WARNING);
                    }
                })
            }else{
                self.showToast('Please Add New Providers to the Network', self.TOST().WARNING);
            }
            
        },

    },

    watch: {
       
    },
    
    
}

</script>
<style >
.cust-prvdrTarrifSelct-Wrapper .activeredbackground{
  color:white;
}

.cust-prvdrTarrifSelct-Wrapper .v-btn__content { width: 100% !important; white-space: normal !important; font-size:13px !important}

.cust-prvdrTarrifSelct-Wrapper .srSelected{
  background-color: #23B1A9 !important;
  color:#FFFFFF !important;
}
.cust-prvdrTarrifSelct-Wrapper .disable-field-style{
    border:1px solid #0014001f;
    padding:6px;
    background-color:#f7f7f7
}
.cust-prvdrTarrifSelct-Wrapper .disp-flex{
    display: flex;
}
.cust-prvdrTarrifSelct-Wrapper .activebackground {
    background-color: #23B1A9 !important;
    color:rgb(247 247 247) !important;
}

.cust-prvdrTarrifSelct-Wrapper .inactivewhitebackground {
    background-color: #ffffff !important;
    color:#10242B !important;
}
.cust-prvdrTarrifSelct-Wrapper .theme--light
.v-data-table > 
.v-data-table__wrapper > table > tbody > tr > td, 
.theme--light.v-data-table > 
.v-data-table__wrapper > table > tbody > tr:not(:last-child) > th:not(.v-data-table__mobile-row) {
    border-bottom: thin solid rgba(0, 0, 0, 0.12);
    border-right: thin solid rgba(0, 0, 0, 0.12);
}
.cust-prvdrTarrifSelct-Wrapper .theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
    border-bottom: thin solid rgba(0, 0, 0, 0.12);
    border-right: thin solid rgba(0, 0, 0, 0.12);
}

.cust-prvdrTarrifSelct-Wrapper .v-text-field.v-text-field--enclosed .v-text-field__details {
    margin-bottom: -15px;
}
.cust-prvdrTarrifSelct-Wrapper .v-label {
    font-size: 14px;
    line-height: 1;
    min-height: 8px;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.cust-prvdrTarrifSelct-Wrapper .v-text-field.v-text-field--solo .v-input__control {
    min-height: 35px;
    padding: 0;
}
.cust-prvdrTarrifSelct-Wrapper .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot {
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
    margin-top: 6px;
}

.cust-prvdrTarrifSelct-Wrapper .custom-drpdown {
    font-size: 13px;
    width: 180px;
}

.cust-prvdrTarrifSelct-Wrapper .drop-arrow-pos {
    /* position: absolute; */
    /* top: 54px;
    right: 380px; */
    position: relative;
    top: 8px;
    left: 140px;

}
.cust-prvdrTarrifSelct-Wrapper .drp-arrow-icon {
    width: 25px;
    height: 25px;
}

</style>