<template>
    <v-app>
        <!-- inst -->
        <inst :indtype="{type:'edit',data:{indata:this.instutionData}}" @toggle-summary="toggleInstSummary" ></inst>
        <!-- <inst :indtype="{type:'validate',data:{indata:this.instutionData}}"  ></inst> -->

        <v-layout v-if="false" style="background-color: #FFFFFF;" column class="">
            <v-layout color="#FFFFFF" class="mx-2 mt-3" style="background-color: #FFFFFF;">
                <v-flex xs3 >
                    <v-layout column class="mx-2 my-1 cust_pr" justify-center >
                        <p class="font-weight-bold cstm-clr-pr" style="color:#FFFFFF" ></p>
                        <div style="background-color: #f7f7f7;border: 1px solid #c1c8cc;" class="pa-2 mt-4">
                            <v-checkbox class="ma-0 pa-0" v-model="checkbox" hide-details>
                                <span slot="label" class="lbl-bgclr font-weight-bold" style="font-size: 13px">Is Non-Service Provider</span>
                            </v-checkbox>
                        </div>
                    </v-layout>
                </v-flex>

                <v-flex xs3 >
                    <v-layout column class=" mx-2  cust_pr">
                        <p class="font-weight-bold cstm-clr-pr">
                            Entity Name *
                        </p>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="entityNme"
                            :rules="[required()]"
                             class="rounded-0" >
                        </v-text-field>
                    </v-layout>
                </v-flex>

                

                <v-flex xs3 >
                    <v-layout column class="cust_pr mx-2 ">
                        <p class="font-weight-bold cstm-clr-pr">
                            Alias Name *
                        </p>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="alsNme"
                             :rules="[required()]"
                            class="rounded-0">
                        </v-text-field>
                    </v-layout>
                </v-flex>

                 <v-flex xs3>
                    <v-layout column class="cust_pr mx-2  ">
                        <p class="font-weight-bold cstm-clr-pr">
                            Provider Type 
                        </p>
                        <v-autocomplete
                            :items="prvdrtyeitems"
                            label="Select"
                            v-model="providerType"
                             :rules="[required()]"
                            solo
                            dense
                             class="rounded-0">
                        </v-autocomplete>
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class="cust_pr mx-2  ">
                        <p class="font-weight-bold cstm-clr-pr">
                            Other 
                        </p>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="other"
                             class="rounded-0">

                        </v-text-field>
                    </v-layout>
                </v-flex>
                

            </v-layout>
            <v-layout style="" class="mx-2" >
                
                <v-flex xs3>
                    <v-layout column class="cust_pr mx-2  ">
                        <p class="font-weight-bold cstm-clr-pr">
                           Provider Flag
                        </p>
                         <v-text-field
                            solo
                            label="Enter..."
                            v-model="prvdrFlgInstn"
                             class="rounded-0">
                        </v-text-field>
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class="cust_pr mx-2  ">
                        <p class="font-weight-bold cstm-clr-pr">
                        Registration Number 
                        </p>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="rgstNo"
                             class="rounded-0">

                        </v-text-field>
                    </v-layout>
                </v-flex>

                <!-- <v-flex xs3>
                    <v-layout column class="mx-1 ">
                        <v-text class="font-weight-bold cstm-clr-pr" >
                        </v-text> -->
                        <!-- <v-btn color="#23B1A9" dark class="mt-4 text-capitalize"><v-icon small left>arrow_upward</v-icon>Update</v-btn> -->
<!-- 
                        <v-btn color="#23B1A9" dark class="mt-4 text-capitalize">
                            <div class="mt-1">
                                <img src="../../assets/update.png" style="width: 16px;height: 16px"/>
                            </div>
                            <v-divider vertical class="mx-2"></v-divider>
                        Update</v-btn>
                    </v-layout>
                </v-flex> -->

                <v-flex xs3>
                    <v-layout column class="cust_pr mx-2  ">
                        <p class="font-weight-bold cstm-clr-pr">
                            Registration Valid Upto
                        </p>
                        <!-- <v-text-field
                            solo
                            label="Enter..."
                            v-model="Rgstvlduptops"
                             class="rounded-0">
                        </v-text-field> -->

                        <v-menu
                            ref="calender"
                            :close-on-content-click="true"
                            v-model="RgstrnVldUptoInstncalender"
                            :nudge-right="40"
                            transition="scale-transition"
                            max-width="290px"
                            min-width="290px" 
                              class="rounded-0"
                            >
                                <!-- <v-text-field
                                    slot="activator"
                                    label="Select.."
                                    :value="cmputdRgstrnVldUptoInstdteMmntjs"
                                    solo
                                     class="rounded-0"
                                    readonly 
                                ></v-text-field> -->
                                
                                  <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-bind="attrs"
                                    v-on="on"
                                    label="Select.."
                                    :value="cmputdRgstrnVldUptoInstdteMmntjs"
                                    solo
                                     class="rounded-0"
                                    readonly 
                                ></v-text-field>
                            </template>                      
                                    <v-date-picker
                                        v-model="RgstrnVldUptoInstdate"
                                        no-title
                                        
                                    ></v-date-picker>
                        </v-menu>


                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class="cust_pr mx-2  ">
                        <p class="font-weight-bold cstm-clr-pr">
                            Services Provided
                        </p>
                        <v-autocomplete
                            :items="SrvcsPrvdeditems"
                            label="Select"
                            v-model="SrvcsPrvded"
                            solo
                            dense
                             class="rounded-0">
                        </v-autocomplete>
                    </v-layout>
                </v-flex>
                <v-flex xs3>
                    <v-layout column class="cust_pr mx-2  ">
                        <p class="font-weight-bold cstm-clr-pr">
                            Other 
                        </p>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="other"
                             class="rounded-0">
                        </v-text-field>
                    </v-layout>
                </v-flex>
            </v-layout>

            

            <v-divider class="mb-4"></v-divider>

            <v-layout color="#FFFFFF" class="mx-2"  >
               
                <v-flex xs3>
                    <v-layout column class="cust_pr  cust-rmvpd-pr mx-2  ">
                        <p class="font-weight-bold cstm-clr-pr">
                            Telephone Number 
                        </p>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="tlphnNo"
                             class="rounded-0">
                            <div slot="prepend-inner" style="background-color:#F7F7F7;;padding: 9px !important" class="pa-2"><v-icon>local_phone</v-icon></div>
                        </v-text-field>
                    </v-layout>
                </v-flex>

                

                <v-flex xs3>
                    <v-layout column class="cust_pr mx-2  ">
                        <p class="font-weight-bold cstm-clr-pr">
                            PRN
                        </p>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="prn"
                             class="rounded-0">
                        </v-text-field>
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class="cust_pr mx-2  ">
                        <p class="font-weight-bold cstm-clr-pr">
                            PAN
                        </p>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="pan"
                             class="rounded-0">
                        </v-text-field>
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class="cust_pr mx-2  ">
                        <p class="font-weight-bold cstm-clr-pr">
                            ROHINI ID
                        </p>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="rohiniId"
                             class="rounded-0">
                        </v-text-field>
                    </v-layout>
                </v-flex>
                <v-flex xs3>
                    <v-layout column class="cust_pr mx-2  ">
                        <p class="font-weight-bold cstm-clr-pr">
                            Registered Body
                        </p>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="RgstrdBdy"
                             class="rounded-0">
                        </v-text-field>
                    </v-layout>
                </v-flex>
            </v-layout>

             


            <v-layout style="" class="mx-2" >

                <v-flex xs3>
                    <v-layout column class="cust_pr mx-2  ">
                        <p class="font-weight-bold cstm-clr-pr">
                            Status
                        </p>
                        <v-autocomplete
                            :items="statusItems"
                            label="Select"
                            v-model="status"
                            solo
                            dense
                             class="rounded-0">
                        </v-autocomplete>
                    </v-layout>
                </v-flex>
                
                <v-flex xs3>
                    <v-layout column class="cust_pr cust-rmvpd-pr mx-2  ">
                        <p class="font-weight-bold cstm-clr-pr">
                            E-mail
                        </p>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="email"
                            
                            class="pa-0 rounded-0">
                             <div slot="prepend-inner" style="background-color:#F7F7F7;padding: 9px !important" class="pa-2"><v-icon>mail</v-icon></div>
                        </v-text-field>
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class="cust_pr mx-2  mb-3">
                        <p class="font-weight-bold cstm-clr-pr">
                            Fax Number
                        </p>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="fxNo"
                             class="rounded-0">
                        </v-text-field>
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class="cust_pr mx-2  mb-3">
                        <p class="font-weight-bold cstm-clr-pr">
                            Child Provider
                        </p>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="childProvider"
                             class="rounded-0">
                        </v-text-field>
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class="cust_pr cust-rmvpd-psins mx-2  ">
                        <p class="font-weight-bold cstm-clr-pr">
                            Parent Provider
                        </p>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="parentProvider"
                             class="rounded-0">
                            <div slot="append" style="background-color:#F7F7F7;padding: 9px !important" class=""><v-icon>search</v-icon></div>
                        </v-text-field>
                    </v-layout>
                </v-flex>

                
            </v-layout>

            <v-layout style="" class="mx-2">

                <v-flex xs3>
                    <v-layout column class="cust_pr mx-2  ">
                        <p class="font-weight-bold cstm-clr-pr">
                            Mapping Type
                        </p>
                        <v-autocomplete
                            :items="mappingTypeItems"
                            label="Select"
                            v-model="mappingType"
                            solo
                            dense
                             class="rounded-0">
                        </v-autocomplete>
                    </v-layout>
                </v-flex>

                <v-flex xs3></v-flex>
                <v-flex xs3></v-flex>
                <v-flex xs3></v-flex>
                <v-flex xs3></v-flex>

            </v-layout>

            <v-divider class="mb-4"></v-divider>
            
            <v-layout color="#FFFFFF" class="mx-2"  >
                <v-flex xs3>
                <v-layout column class="mx-2 ">
                    <p class="text-uppercase font-weight-bold title-clr-pr">
                        Address
                    </p>
                    <v-divider></v-divider>
                </v-layout>
                </v-flex>
                <v-flex xs3>
                <v-layout column class="cust_pr mx-2  ">
                    <p class="font-weight-bold cstm-clr-pr">
                        Address Line 1 *
                    </p>
                    <v-text-field
                        solo
                        label="Enter..."
                        v-model="adrsLne1"
                         class="rounded-0">
                    </v-text-field>
                </v-layout>
                </v-flex>
                <v-flex xs3>
                <v-layout column class="cust_pr mx-2  ">
                    <p class="font-weight-bold cstm-clr-pr">
                        Address Line 2
                    </p>
                    <v-text-field
                        solo
                        label="Enter..."
                        v-model="adrsLne2"
                         class="rounded-0">
                    </v-text-field>
                </v-layout>
                </v-flex>
                <v-flex xs3>
                <v-layout column class="cust_pr mx-2  ">
                    <p class="font-weight-bold cstm-clr-pr">
                        Address Line 3
                    </p>
                    <v-text-field
                        solo
                        label="Enter..."
                        v-model="adrsLne3"
                         class="rounded-0">
                    </v-text-field>
                </v-layout>
                </v-flex>
                
                <v-flex xs3>
                <v-layout column class="cust_pr mx-2  ">
                    <p class="font-weight-bold cstm-clr-pr">
                        Pin Code/Postal Code *
                    </p>
                    <v-autocomplete
                            :items="pnCdeItems"
                            label="Select"
                            v-model="pnCde"
                            solo
                            dense
                            class="rounded-0">
                        </v-autocomplete>
                </v-layout>
                </v-flex>
            </v-layout>
            <v-layout style="" class="mx-2" >
                <v-flex xs3>
                    <v-layout column class="cust_pr mx-2  ">
                        <p class="font-weight-bold cstm-clr-pr">
                            Country
                        </p>
                        <v-autocomplete
                            :items="cntryItems"
                            label="Select"
                            v-model="country"
                            solo
                            dense
                            class="rounded-0">
                        </v-autocomplete>
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                <v-layout column class="cust_pr mx-2  ">
                    <p class="font-weight-bold cstm-clr-pr">
                        State *
                    </p>
                    <v-autocomplete
                        :items="stateItems"
                        label="Select"
                        v-model="state"
                        solo
                        dense
                         class="rounded-0">
                    </v-autocomplete>
                </v-layout>
                </v-flex>
                <v-flex xs3>
                <v-layout column class="cust_pr mx-2  ">
                    <p class="font-weight-bold cstm-clr-pr">
                        District *
                    </p>
                    <v-autocomplete
                        :items="districtItems"
                        label="Select"
                        v-model="district"
                        solo
                        dense
                         class="rounded-0">
                    </v-autocomplete>
                </v-layout>
                </v-flex>
                <v-flex xs3>
                <v-layout column class="cust_pr mx-2  ">
                    <p class="font-weight-bold cstm-clr-pr">
                        City/Town/Village *
                    </p>
                    <v-autocomplete
                        :items="ctvItems"
                        label="Select"
                        v-model="ctv"
                        solo
                        dense
                         class="rounded-0">
                    </v-autocomplete>
                </v-layout>
                </v-flex>
                <v-flex xs3>
                <v-layout column class="cust_pr mx-2  mb-3">
                    <p class="font-weight-bold cstm-clr-pr">
                       Other
                    </p>
                    <v-text-field
                        solo
                        label="Enter..."
                        v-model="othrAdrs"
                         class="rounded-0">
                    </v-text-field>
                </v-layout>
                </v-flex>
                
            </v-layout>
             <v-divider class="mb-4"  ></v-divider>

            <v-layout color="#FFFFFF" class="mx-2"  style="" >
                <v-flex xs3>
                    <v-layout column class="mx-2 ">
                        <p class="text-uppercase font-weight-bold title-clr-pr">
                            Validation Details
                        </p>
                        <v-divider></v-divider>
                    </v-layout>
                </v-flex>
                <v-flex xs3>
                    <v-layout column class="cust_pr mx-2  ">
                        <p class="font-weight-bold cstm-clr-pr">
                            No. of Beds
                        </p>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="NoofBeds"
                             class="rounded-0">
                        </v-text-field>
                    </v-layout>
                </v-flex>
                <v-flex xs3>
                    <v-layout column class="cust_pr mx-2  ">
                        <p class="font-weight-bold cstm-clr-pr">
                            Daily Medical Records
                        </p>
                        <v-autocomplete
                            :items="dlyMdclRcrdsItems"
                            label="Select"
                            v-model="dlyMdclRcrds"
                            solo
                            dense
                             class="rounded-0">
                        </v-autocomplete>
                    </v-layout>
                </v-flex>
                <v-flex xs3>
                    <v-layout column class="cust_pr mx-2  ">
                        <p class="font-weight-bold cstm-clr-pr">
                            Has Operation Theatre
                        </p>
                        <v-autocomplete
                            :items="hsOtItems"
                            label="Select"
                            v-model="hsOt"
                            solo
                            dense
                             class="rounded-0">
                        </v-autocomplete>
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class="cust_pr mx-2  ">
                    </v-layout>
                </v-flex>
            </v-layout>
            <v-layout class="mx-2"  >
                <v-flex xs3>
                    <v-layout column class="cust_pr mx-2  ">
                    </v-layout>
                </v-flex>
                <v-flex xs3>
                    <v-layout column class="cust_pr mx-2  ">
                        <p class="font-weight-bold cstm-clr-pr" style="font-size: 13px">
                            Medical Practitioner Round The Clock
                        </p>
                        <v-autocomplete
                            :items="mprtcItems"
                            label="Select"
                            v-model="mprtc"
                            solo
                            dense
                             class="rounded-0">
                        </v-autocomplete>
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class="cust_pr mx-2  ">
                        <p class="font-weight-bold cstm-clr-pr">
                            Nursing Round The Clock
                        </p>
                    
                        <v-autocomplete
                            :items="nrtcItems"
                            label="Select"
                            v-model="nrtc"
                            solo
                            dense
                             class="rounded-0">
                        </v-autocomplete>
                        
                    </v-layout>
                </v-flex>
                <v-flex xs3>
                    <v-layout column class="cust_pr mx-2  ">
                    </v-layout>
                </v-flex>
                <v-flex xs3>
                    <v-layout column class="cust_pr mx-2  ">
                    </v-layout>
                </v-flex>
            </v-layout>

            <v-divider class="mb-4"  ></v-divider >
            <v-layout color="#FFFFFF" class="mx-2"  style="" >
                <v-flex xs3>
                    <v-layout column class="cust_pr mx-2  ">
                        <p class="font-weight-bold cstm-clr-pr">
                            Registration Date Effective From
                        </p>
                    
                        <!-- <v-select
                            :items="RgstnDtefectveFrmItems"
                            label="Select"
                            v-model="RgstnDtefectveFrm"
                            solo
                            dense
                             class="rounded-0">
                        </v-select> -->

                        <v-menu
                            ref="calender"
                            :close-on-content-click="true"
                            v-model="RgstrnEfctvFrmcalender"
                            :nudge-right="40"
                            transition="scale-transition"
                            max-width="290px"
                            min-width="290px" 
                              class="rounded-0"
                            >
                                <!-- <v-text-field
                                    slot="activator"
                                    label="Select.."
                                    :value="cmputdRgstrnEfctvFrmdteMmntjs"
                                    solo
                                     class="rounded-0"
                                    readonly 
                                ></v-text-field> -->

                                <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-bind="attrs"
                                    v-on="on"
                                    label="Select.."
                                    :value="cmputdRgstrnEfctvFrmdteMmntjs"
                                    solo
                                     class="rounded-0"
                                    readonly 
                                ></v-text-field>
                            </template>
                                                        
                                    <v-date-picker
                                        v-model="RgstrnEfctvFrmdate"
                                        no-title
                                        
                                    ></v-date-picker>
                        </v-menu>
                        
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class="cust_pr mx-2  ">
                        <p class="font-weight-bold cstm-clr-pr">
                            Registration Effective Date To
                        </p>
                    
                        <!-- <v-select
                            :items="RgstnDtefectveToItems"
                            label="Select"
                            v-model="RgstnDtefectveTo"
                            solo
                            dense
                             class="rounded-0">
                        </v-select> -->

                        <v-menu
                            ref="calender"
                            :close-on-content-click="true"
                            v-model="RgstrnEfctvTocalender"
                            :nudge-right="40"
                            transition="scale-transition"
                            max-width="290px"
                            min-width="290px" 
                              class="rounded-0"
                            >
                                <!-- <v-text-field
                                    slot="activator"
                                    label="Select.."
                                    :value="cmputdRgstrnEfctvTodteMmntjs"
                                    solo
                                     class="rounded-0"
                                    readonly 
                                ></v-text-field> -->
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-bind="attrs"
                                    v-on="on"
                                    label="Select.."
                                    :value="cmputdRgstrnEfctvTodteMmntjs"
                                    solo
                                     class="rounded-0"
                                    readonly 
                                ></v-text-field>
                            </template>    
                                    <v-date-picker
                                        v-model="RgstrnEfctvTodate"
                                        no-title
                                        
                                    ></v-date-picker>
                        </v-menu>
                        
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class="cust_pr mx-2  ">
                        <p class="font-weight-bold cstm-clr-pr">
                            Reviewer Remarks
                        </p>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="rvwrRmrks"
                             class="rounded-0">
                        </v-text-field>
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class="cust_pr mx-2  ">
                        <p class="font-weight-bold cstm-clr-pr">
                            Registration Date
                        </p>
<!--                     
                        <v-select
                            :items="regstiondateItems"
                            label="Select"
                            v-model="regstiondate"
                            solo
                            dense
                             class="rounded-0">
                        </v-select> -->

                        <v-menu
                            ref="calender"
                            :close-on-content-click="true"
                            v-model="RgstrToInstncalender"
                            :nudge-right="40"
                            transition="scale-transition"
                            max-width="290px"
                            min-width="290px" 
                              class="rounded-0"
                            >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-bind="attrs"
                                    v-on="on"
                                    label="Select.."
                                    :value="cmputdRgstrToInstndteMmntjs"
                                    solo
                                     class="rounded-0"
                                    readonly 
                                ></v-text-field>
                            </template> 
                                                        
                                    <v-date-picker
                                        v-model="RgstrToInstndate"
                                        no-title
                                        
                                    ></v-date-picker>
                        </v-menu>
                        
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <!-- <v-layout column class="cust_pr mx-2  ">
                        <v-text class="font-weight-bold cstm-clr-pr">
                            List of Treating Doctors
                        </v-text>
                    
                        <v-select
                            :items="lstDrItems"
                            label="Select"
                            v-model="lstDr"
                            solo
                            dense
                             class="rounded-0">
                        </v-select>
                        
                    </v-layout> -->
                </v-flex>
            </v-layout>
            
            <v-layout color="#FFFFFF" class="mx-2"  style="" >
                 <v-flex xs6>
                   
                        <v-layout column class="cust_pr mx-2  ">
                            <p class="font-weight-bold cstm-clr-pr">
                                List of treating doctors
                            </p>
                            <v-card tile>
                                <v-layout justify-space-between align-center style="border:1px solid #C1C8CC; background-color:#FFFFFF" class="pa-1 px-2">
                                    <p class=" mb-0" style="color:#8A8A8A;font-size:13px">
                                    Select
                                    </p>
                                    <v-btn tile dark small color="#23B1A9" class="text-capitalize" @click="open_drLst()"><v-icon  small dark> edit</v-icon> <v-divider class="mx-2" vertical></v-divider>Edit Doctors List</v-btn>
                                </v-layout>
                                <div style="background-color: #f7f7f7;border: 1px solid #c1c8cc max-height:200px; overflow-y:auto" class="">
                                    <div style="border-top: 1px solid #c1c8cc" class="pa-1">
                                        <v-checkbox v-model="selectedDR"  value="Doctor 1" dense   hide-details class="pa-0 ma-0">
                                          <span slot="label" class="lbl-bgclr font-weight-bold" style="font-size: 13px">Doctor 1</span>
                                       </v-checkbox>
                                    </div>
                                    <div style="border-top: 1px solid #c1c8cc" class="pa-1">
                                        <v-checkbox v-model="selectedDR" value="Doctor 2"  dense  hide-details class="pa-0 ma-0">
                                          <span slot="label" class="lbl-bgclr font-weight-bold" style="font-size: 13px">Doctor 2</span>
                                       </v-checkbox>
                                    </div>
                                    <div style="border-top: 1px solid #c1c8cc" class="pa-1">
                                        <v-checkbox v-model="selectedDR" value="Doctor 3"  dense hide-details class="pa-0 ma-0">
                                          <span slot="label" class="lbl-bgclr font-weight-bold" style="font-size: 13px">Doctor 3</span>
                                       </v-checkbox>
                                    </div>
                                    <div style="border-top: 1px solid #c1c8cc" class="pa-1">
                                        <v-checkbox v-model="selectedDR" value="Doctor 4"  dense hide-details class="pa-0 ma-0">
                                          <span slot="label" class="lbl-bgclr font-weight-bold" style="font-size: 13px">Doctor 4</span>
                                       </v-checkbox>
                                    </div>
                                    <div style="border-top: 1px solid #c1c8cc" class="pa-1">
                                        <v-checkbox v-model="selectedDR" value="Doctor 5"  dense hide-details class="pa-0 ma-0">
                                          <span slot="label" class="lbl-bgclr font-weight-bold" style="font-size: 13px">Doctor 5</span>
                                       </v-checkbox>
                                    </div>
                                    <div style="border-top: 1px solid #c1c8cc" class="pa-1">
                                        <v-checkbox v-model="selectedDR" value="Doctor 6"  dense hide-details class="pa-0 ma-0">
                                          <span slot="label" class="lbl-bgclr font-weight-bold" style="font-size: 13px">Doctor 6</span>
                                       </v-checkbox>
                                    </div>
                                </div>
                            </v-card>
                        </v-layout>
                    
                </v-flex>
            </v-layout>
            

            <v-layout class="ma-3 pa-2 mt-10" justify-space-between style="background-color: #F7F7F7" >
                <v-layout  justify-end>
                    <v-btn tile dark color="#23B1A9" class="text-capitalize px-3 mx-2" small><v-icon dark small>cloud_upload</v-icon> <v-divider vertical class="mx-2"></v-divider> Upload</v-btn>
                    <v-btn tile dark color="#23B1A9" class="text-capitalize px-3 mx-2" small><v-icon dark small>cloud_upload</v-icon> <v-divider vertical class="mx-2"></v-divider> Update Flag</v-btn>
                    <!-- <v-btn dark small color="#23B1A9" class="text-capitalize px-3" @click="open_drLst()" ><v-icon  small dark>  edit</v-icon> <v-divider class="mx-2" vertical></v-divider>Edit Doctors List</v-btn> -->
                    <!-- <v-btn dark color="#E46A25" class="text-capitalize"><v-icon dark >arrow_back</v-icon> <v-divider class="mx-2" vertical></v-divider>Return</v-btn> -->
                    <v-btn  tile dark  small color="#E46A25" class="text-capitalize px-3 mx-2" style="font-size:12px">
                        <div class="mt-1">
                            <img src="../../assets/return.png" style="width: 16px;height: 16px"/>
                        </div> <v-divider vertical class="mx-2"></v-divider>
                        Return
                    </v-btn>
                    <!-- <v-btn tile dark small color="#23B1A9" class="text-capitalize px-3 mx-2"><v-icon dark small >edit</v-icon> <v-divider class="mx-2" vertical></v-divider>Edit Details</v-btn> -->
                    <v-btn tile dark small color="#152F38" class="text-capitalize px-3 mx-2" @click="open_statushstry()"><v-icon dark small >find_in_page</v-icon> <v-divider class="mx-2" vertical></v-divider>Status History</v-btn>
                    <v-btn  tile dark color="#23B1A9" class="text-capitalize px-3 ml-2" small><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider> Save</v-btn>
                </v-layout>

            </v-layout>

            
            <v-layout class="mb-5"></v-layout>
                <!-- status history Dialog -->
            <v-layout>
                    <v-dialog v-model="prvdrStsHstryDilg" persistent max-width="720px">
                        <v-card color="#F7F7F7" class="pb-2" tile>
                            <v-card tile>
                                <v-layout justify-space-between  class="" style="flex:1;background-ox-shadow: 0px 3px 5px #00000029;">
                                    <v-layout align-center>
                                        <v-card-title class="pa-0 pl-3 text-capitalize font-weight-bold" style="color:#10242B;font-size:13px">Provider Status History</v-card-title>
                                        <v-divider vertical inset class="mx-2"></v-divider>
                                        <span class=" text-capitalize font-weight-bold" style="font-size:13px"><span style="color:#909699">Imran Shaikh </span>- Status History</span>
                                    </v-layout>
                                    <div style="border-left:1px solid #C1C8CC;" @click="close_hstryFrm()">
                                        <v-icon style="cursor: pointer;" class="pa-2" >close</v-icon>
                                    </div>
                                </v-layout>
                            </v-card>

                            <v-card class="mx-3 mt-3" tile>
                                <v-layout style="background-color:#23B1A9" >
                                    <span class="font-weight-bold pa-2 dlg-tbl-brdr" style="color:#FFFFFF;flex:1;">Status</span>
                                    <span class="font-weight-bold pa-2 dlg-tbl-brdr" style="color:#FFFFFF;flex:1;">Date</span>
                                    <span class="font-weight-bold pa-2 dlg-tbl-brdr" style="color:#FFFFFF;flex:1;">Time</span>
                                    <span class="font-weight-bold pa-2 dlg-tbl-brdr" style="color:#FFFFFF;flex:1.5;">User</span>
                                    <span class="font-weight-bold pa-2 dlg-tbl-brdr" style="color:#FFFFFF;flex:1.5;">Remarks</span>
                                </v-layout>
                                <v-layout  v-for="(item,index) in 5" :key="index">
                                    <span style="color:#10242B;flex:1;" class="font-weight-bold pa-2 dlg-tbl-brdr">PRN Generated</span>
                                    <span style="color:#10242B;flex:1;" class="pa-2 dlg-tbl-brdr">10/02/2020</span>
                                    <span style="color:#10242B;flex:1;" class="pa-2 dlg-tbl-brdr">12.05 PM</span>
                                    <span style="color:#10242B;flex:1.5;" class="pa-2 dlg-tbl-brdr">ABC __ __ __ __ __ __</span>
                                    <span style="color:#10242B;flex:1.5;" class="pa-2 dlg-tbl-brdr">2 fraud claims identified</span>
                                </v-layout>

                            </v-card>
                            <v-card class="mx-3 py-2 pr-2 mb-2" color="#F7F7F7" tile>
                            <v-layout row justify-end align-center  >
                                <span class="mr-1 font-weight-bold" style="color:#3C3D3D; font-size:13px;">
                                <span class="" style="color:#E46A25;">Showing 1-5 </span>out of 75 records
                                </span>
                                <v-divider  class="mx-2" vertical ></v-divider>
                                <v-pagination 
                                    class="cust-bnkAcc-pgn"
                                    v-model="pageNumber"
                                    color="#152F38"
                                    flat
                                    @input="nextPage"
                                    :length="6"></v-pagination>
                            </v-layout>
                            </v-card>
                            
                            <v-layout class="mr-2" row justify-center style="">
                                <v-btn tile dark color="#152F38" class=" px-3 text-capitalize" small @click="close_hstryFrm()">
                                    <v-icon color="" small >cancel</v-icon>
                                    <v-divider vertical class="ml-2 mr-2 text-capitalize"></v-divider>Return
                                </v-btn>
                            </v-layout>
                            
                        </v-card>
                    </v-dialog>
                </v-layout>

                 <!-- Doctor List history Dialog -->
                <v-layout >
                    <v-dialog v-model="drLstDilg" persistent width="700px">
                        <v-card color="#F7F7F7" class="pb-2" tile >
                            <v-card tile>
                                <v-layout justify-space-between  class="" style="flex:1;background-ox-shadow: 0px 3px 5px #00000029;">
                             
                                    <v-card-title class="pa-0 pl-3 text-capitalize font-weight-bold" style="color:#10242B;font-size:13px">Hospital Doctors List</v-card-title>
                                    <div style="border-left:1px solid #C1C8CC;" @click="close_drLstFrm()">
                                        <v-icon style="cursor: pointer;" class="pa-2" >close</v-icon>
                                    </div>
                                </v-layout>
                            </v-card>

                            <v-layout justify-end align-center class="mt-3 mx-4">
                                    <v-btn tile dark color="#23B1A9" class="text-capitalize px-3" small>
                                        <div class="mt-1">
                                            <img src="../../assets/update.png" style="width: 16px;height: 16px"/>
                                        </div>
                                        <v-divider vertical class="ml-2 mr-2"></v-divider>Upload Excel
                                    </v-btn>   
                            </v-layout>

                            <v-layout column class="cust_pr mx-4 mb-2">
                                <span class="font-weight-bold cstm-clr-pr">
                                    List of treating doctors
                                </span>
                                <v-card tile>
                                    <v-layout justify-space-between align-center style="border:1px solid #C1C8CC; background-color:#FFFFFF" class="ma-0 pa-0">
                                        <p class="mx-2 mt-3" style="color:#8A8A8A;font-size:13px">
                                        Select
                                        </p>
                                    </v-layout>
                                    <div style="background-color: #f7f7f7;border: 1px solid #c1c8cc max-height:200px; overflow-y:auto" class="">
                                        <div style="border-top: 1px solid #c1c8cc" class="pa-1">
                                            <v-checkbox v-model="sltdDrdlglst" value="Doctor 1" dense hide-details class="pa-0 ma-0">
                                            <span slot="label" class="lbl-bgclr font-weight-bold " style="font-size: 13px">Doctor 1</span>
                                        </v-checkbox>
                                        </div>
                                        <div style="border-top: 1px solid #c1c8cc" class="pa-1">
                                            <v-checkbox v-model="sltdDrdlglst" value="Doctor 2" dense hide-details class="pa-0 ma-0">
                                            <span slot="label" class="lbl-bgclr font-weight-bold " style="font-size: 13px">Doctor 2</span>
                                        </v-checkbox>
                                        </div>
                                        <div style="border-top: 1px solid #c1c8cc" class="pa-1">
                                            <v-checkbox v-model="sltdDrdlglst" value="Doctor 3" dense hide-details class="pa-0 ma-0">
                                            <span slot="label" class="lbl-bgclr font-weight-bold " style="font-size: 13px">Doctor 3</span>
                                        </v-checkbox>
                                        </div>
                                        <div style="border-top: 1px solid #c1c8cc" class="pa-1">
                                            <v-checkbox v-model="sltdDrdlglst" value="Doctor 4" dense hide-details class="pa-0 ma-0">
                                            <span slot="label" class="lbl-bgclr font-weight-bold " style="font-size: 13px">Doctor 4</span>
                                        </v-checkbox>
                                        </div>
                                        <div style="border-top: 1px solid #c1c8cc" class="pa-1">
                                            <v-checkbox v-model="sltdDrdlglst" value="Doctor 5" dense hide-details class="pa-0 ma-0">
                                            <span slot="label" class="lbl-bgclr font-weight-bold " style="font-size: 13px">Doctor 5</span>
                                        </v-checkbox>
                                        </div>
                                        <div style="border-top: 1px solid #c1c8cc" class="pa-1">
                                            <v-checkbox v-model="sltdDrdlglst" value="Doctor 6" dense hide-details class="pa-0 ma-0">
                                            <span slot="label" class="lbl-bgclr font-weight-bold " style="font-size: 13px">Doctor 6</span>
                                        </v-checkbox>
                                        </div>
                                    </div>
                                </v-card>
                            </v-layout>
                            <v-layout class="mt-3" row justify-center style="">
                                <v-btn tile dark color="#E46A25" class="text-capitalize px-3 mx-2" small @click="close_drLstFrm()">
                                    <v-icon color="" small >cancel</v-icon>
                                    <v-divider vertical class="ml-2 mr-2"></v-divider>Close
                                </v-btn>
                                <v-btn tile dark color="#D1121B" class="text-capitalize px-3 mx-2" small @click="clr_sltdDrdlglst()">
                                    <v-icon color="" small >delete</v-icon>
                                    <v-divider vertical class="ml-2 mr-2"></v-divider>Clear
                                </v-btn>
                                <v-btn tile dark color="#23B1A9" class="text-capitalize px-3 mx-2" small @click="close_drLstFrm()">
                                    <v-icon color="" small >check_circle</v-icon>
                                    <v-divider vertical class="ml-2 mr-2"></v-divider>Save
                                </v-btn>
                            </v-layout>
                            
                        </v-card>
                    </v-dialog>
                </v-layout>
            
        </v-layout>

        
    </v-app>
</template>

<script>
import moment from 'moment';
import axios from "axios";

import inst from '../ProviderRegistrationClaims/Institution.vue'

export default {

    components:{
        inst
    },

    created() {

        // this.$store.commit("SET_PAGE_TITLE", "Provider Summary");
        console.log(this.$store.getters.fetchPrvdrData,"fetchPrvdrData")
        this.instutionData=this.$store.getters.fetchPrvdrData
        console.log("------->"+ this.instutionData);
        // this.getpincode(res=>{
        //     console.log(res, "Response")
        //     this.cntry(rescntry=>{
        //         console.log(rescntry, "rescntry")
                
        //         this.setfrmdata()
        //     })
            

        // })
       
    },

    computed: {
        cmputdRgstrnVldUptoInstdteMmntjs(){
            return this.RgstrnVldUptoInstdate ? moment(this.RgstrnVldUptoInstdate).format("DD-MM-YYYY"): ''
        },

        cmputdRgstrnEfctvFrmdteMmntjs(){
            return this.RgstrnEfctvFrmdate ? moment(this.RgstrnEfctvFrmdate).format("DD-MM-YYYY"): ''
        },
        cmputdRgstrnEfctvTodteMmntjs(){
            return this.RgstrnEfctvTodate ? moment(this.RgstrnEfctvTodate).format("DD-MM-YYYY"): ''
        },
        cmputdRgstrToInstndteMmntjs(){
            return this.RgstrToInstndate ? moment(this.RgstrToInstndate).format("DD-MM-YYYY"): ''
        }

    },

    data() {
        return {
            checkbox: false,
            pageNumber: 1,
            prvdrStsHstryDilg:false,
            drLstDilg:false,
            items:['Institution', 'Individual'],
            rgstrdEntity:'Institution',
            entityNme:'',
            alsNme:'',
            prvdrtyeitems:['Others',"Hospital"],
            SrvcsPrvdeditems:["Dental"],
            other:"",
            rgstNo:"",
            RgstrtnVldUpto:"",
            RgstrnVldUptoInstncalender: false,
            RgstrnVldUptoInstdate: "",
            RgstrnEfctvFrmcalender:false,
            RgstrnEfctvFrmdate:'',
            RgstrnEfctvTocalender:false,
            RgstrnEfctvTodate:'',
            RgstrToInstncalender:false,
            RgstrToInstndate:'',
            SrvcsPrvded:"",
            tlphnNo:"",
            pan:"",
            prn:"",
            status:"",
            statusItems:["Active", "Inactive","Registered"],
            rohiniId:"",
            RgstrdBdy:"",
            email:"",
            fxNo:"",
            adrsLne1:"",
            adrsLne2:"",
            adrsLne3:"",
            country:"",
            cntryItems:["India","Others"],
            stateItems:["Maharashtra","Gujarat","Karnataka","Telangana"],
            state:"",
            district:"",
            districtItems:["Thane", "Palghar","Raigad","RangaReddy"],
            pnCde:"",
            pnCdeItems:[500084],
            ctvItems:["City","Town","Village", "Others","Hyd"],
            ctv:"",
            othrAdrs:"",
            prvdrFlgInstn:"",
            NoofBeds:"",
            dlyMdclRcrdsItems:[],
            dlyMdclRcrds:"",
            hsOtItems:[],
            hsOt:"",
            mprtcItems:[],
            mprtc:"",
            nrtcItems:[],
            nrtc:"",
            childProvider:'',
            parentProvider:'',
            mappingTypeItems:["Others"],
            mappingType:'',
            RgstnDtefectveFrmItems:["Others"],
            RgstnDtefectveFrm:'',
            RgstnDtefectveToItems:["Others"],
            RgstnDtefectveTo:'',
            rvwrRmrks:'',
            regstiondateItems:["Others"],
            regstiondate:'',
            lstDrItems:["Others"],
            lstDr:'',
            selectedDr: [''],
            shwInstitutionps:true,
            shwIndividualps:false,
            sltnIndPsItems:["Mr.","Mrs"],
            salutationindps:'',
            frstnmeIndps:'',
            mdlnmeIndps:'',
            lstnmeIndps:'',
            gndrIndps:'',
            emailindps:'',
            mbNoIndps:'',
            extrnlRfNoIndps:'',
            prnIndps:'',
            statusIndps:"",
            statusIndpsItems:["Active", "Inactive"],
            prvdrTypeIndps:'',            
            otherIndps:'',
            qualificationIndps:'',
            otherIndps2:'',
            RgstNopsItems:['Others'],
            RgstNops:'',
            Rgstvlduptops:'',
            RgstrdCncilIndps:'',
            OtherIndps3:'',
            QualificationIndps2:'',
            remarksIndps:'',
            prvdrFlgindps:'',
            RgstrdCncilIndpsItems:["Others"],
            genderindpsItems:["Male","Female"],
            genderindps:'',
            sltdDrdlglst:[],
            selectedDR:[],
            instutionData:{},
            required(){
            return v=>v && v.length>0 || "This Field is Required"
            },
            blank(){
               return v => /^\s+$/.test(v) || 'Value cannot be blank'
            },
            minLength(minLength){
                return v=>v && v.length>=minLength || `Must be atleast ${minLength} Characters`
            },
            maxLength(maxLength){
                return v=>v && v.length<=maxLength || `Must be less than ${maxLength} Characters`
            },
            spclChar(){
                return v=>/^[A-Za-z0-9 ]+$/.test(v) || `Special Characters Not Allowed`
            },
            noOnly(){
                return v => /^[0-9 ]+$/.test(v) || 'Numbers Only Allowed'
            },

            emailRules: [
                v => !!v || 'E-mail is required',
                v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,6})+$/.test(v) || 'E-mail must be valid'
            ],

        };



    },

    methods: {

        toggleInstSummary(id){
            console.log("%c this is form institution Summary","color:red");
            this.$emit("toggle-instSummary", id);
        },

        rgstrdEntySlctn(value){
            console.log(value)
            if(value==="Institution"){
                this.shwInstitutionps=true
                this.shwIndividualps=false
            }else if(value==="Individual"){
                this.shwInstitutionps=false
                this.shwIndividualps=true
            }

        },

        close_hstryFrm(){
            this.prvdrStsHstryDilg=false
        },

        open_statushstry(){
            this.prvdrStsHstryDilg=true
        },

        nextPage(page) {
            this.pageNumber = page;
        },

        open_drLst(){
            this.drLstDilg=true
        },
        close_drLstFrm(){
            this.drLstDilg=false
        },
        clr_sltdDrdlglst(){
            this.sltdDrdlglst=[]
        },
        //  setfrmdata(){
        //      this.entityNme=this.instutionData.entityName
        //      this.alsNme=this.instutionData.aliasName
        //      this.providerType=this.instutionData.providerType
        //      this.rgstNo=this.instutionData.registrationNumber
        //      this.SrvcsPrvded=this.instutionData.servicesProvided
        //      this.tlphnNo=this.instutionData.telephoneNumber
        //      this.prn=this.instutionData.prn
        //      this.pan=this.instutionData.panNumber
        //      this.rohiniId=this.instutionData.rohiniId
        //      this.RgstrdBdy=this.instutionData.registeredBody
        //      this.status=this.instutionData.status
        //      this.email=this.instutionData.email
        //      this.faxNumber=this.instutionData.fxNo
        //      this.adrsLne1=this.instutionData.addressLine1
        //      this.adrsLne2=this.instutionData.addressLine2
        //      this.adrsLne3=this.instutionData.addressLine3
        //      this.pnCde=this.instutionData.pinPostalCode
        //      this.country=this.instutionData.country
        //      this.state=this.instutionData.state
        //      this.district=this.instutionData.district
        //      this.ctv=this.instutionData.cityTownVillage

        //  },
        getpincode(callback){
             
        axios({
            method: "get",
            url: "http://claimsdrive-health.salesdrive.app/master-data/master-data-service/api/city/pincodes",
            headers: {
                "Content-Type": "application/json"
            },
          
        })
        .then( response=> {
            console.log("Search reporting>>>>>>", response);
            callback(response)
            }
        )
        .catch(function (error) {
            console.log("Search reporting Error>>>>>>", error);
            callback(error)
        });
         },

        cntry(callback){
             
        axios({
            method: "get",
            url: "http://claimsdrive-health.salesdrive.app/master-data/master-data-service/api/country/get-country",
            headers: {
                "Content-Type": "application/json"
            },
          
        })
        .then( response=> {
            console.log("Search reporting>>>>>>", response);
            callback(response)
            }
        )
        .catch(function (error) {
            console.log("Search reporting Error>>>>>>", error);
            callback(error)
        });
         }

    },

    watch: {
    
    },
    
    
}

</script>
<style >
.cust_pr .v-text-field .v-input__slot,
.v-text-field--outline .v-input__slot {
    min-height: 42px !important;
    display: flex !important;
    align-items: center !important;
    /* border:1px red solid; */
}

.cust-rmvpd-pr .v-text-field.v-text-field--enclosed>.v-input__control>.v-input__slot {
    padding: 0px !important;
}
/* .cust_pr .v-input__slot{
    margin-bottom: unset !important;
} */
.cstm-clr-pr{
    color:#646666 !important;
    margin-bottom: 5px !important;
    font-size: 13px !important;
}
.cust_pr .v-input{
    font-size: 13px !important;
}
.cust_pr .v-input input {
    font-size: 13px !important;
}

.cust_pr .v-input .v-label {
    font-size: 13px !important;
}

.cust-rmvpd-psins .v-text-field.v-text-field--enclosed>.v-input__control>.v-input__slot {
    padding-right: 0px !important;;
}

.title-clr-pr{
    color:#10242B;
    margin-bottom: 5px;
}

.lbl-bgclr{
    color:#152F38

}


/* width */
/* ::-webkit-scrollbar {
  width: 6px;
} */

/* Track */
/* ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #FFFFFF; 
  border-radius: 10px;
} */
 
/* Handle */
/* ::-webkit-scrollbar-thumb {
  background:#C9C9C9; 
  border-radius: 5px;
} */

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
  background: #b30000; 
} */

.dlg-tbl-brdr{
    border-right:1px solid #C1C8CC;
    border-top:1px solid #C1C8CC;
    font-size:12px
    
}

.cust-bnkAcc-pgn .v-pagination__navigation{
        box-shadow:unset !important;
        border-radius: unset !important;
        background-color:#FFFFFF !important;
        border:1px solid #DAE1E6 !important
    }
    .cust-bnkAcc-pgn .v-pagination__navigation--disabled{
        background-color:#DAE1E6 !important;
    }
    .cust-bnkAcc-pgn .v-pagination__item{
        box-shadow:unset !important;
        min-width:1.8rem !important;
        height:1.8rem !important;
        border-radius: unset !important;
        border:1px solid #DAE1E6 !important
    }

 .v-dialog{
    overflow-y: unset !important;
}

.cust-rmvpd-pr .v-text-field.v-text-field--enclosed>.v-input__control>.v-input__slot {
    padding: 0px !important;
}

</style>