<template>
    <div>
        <v-card tile style="max-height: 150px" class="ma-1 mt-4 rounded-0">
            <div
                class="mx-2 pa-2 custom"
            >
                <v-layout style="flex: 1" justify-end>
                <v-btn
                    v-if="sno > 1"
                    dark
                    tile
                    color="#23B1A9"
                    class="text-capitalize px-3 mx-2 my-2"
                    small
                    @click="goToPreviousTab()"
                >
                <!-- <v-icon dark small>arrow_backward</v-icon> -->
                    <!-- <v-divider vertical class="mx-2"></v-divider> -->
                    Previous
                </v-btn>
                <v-btn
                    v-if="sno == 22 && isSiuUser && showSendBackWaiveOffAppoint"
                    dark
                    tile
                    color="#E46A25"
                    class="text-capitalize px-3 mx-2 my-2"
                    small
                    @click="reassignToInvestigator()"
                    ><v-icon dark small>arrow_forward</v-icon>
                    <v-divider vertical class="mx-2"></v-divider>Appoint to Investigator
                </v-btn>
                <v-btn
                    v-if="sno == 22 && !isSiuUser && showAcceptRejectButton"
                    dark
                    tile
                    color="#23B1A9"
                    class="text-capitalize px-3 mx-2 my-2"
                    small
                    @click="waiveOffSendBack('accept')"
                    ><v-icon dark small>check_circle</v-icon>
                    <v-divider vertical class="mx-2"></v-divider>Accept
                </v-btn>
                <v-btn
                    v-if="sno == 22 && !isSiuUser && showAcceptRejectButton"
                    dark
                    tile
                    color="red"
                    class="text-capitalize px-3 mx-2 my-2"
                    small
                    @click="waiveOffSendBack('reject')"
                    ><v-icon dark small>mdi-cancel</v-icon>
                    <v-divider vertical class="mx-2"></v-divider>Reject
                </v-btn>
                <v-btn
                    v-if="sno == 22 && isSiuUser && showSendBackWaiveOffAppoint"
                    dark
                    tile
                    color="red"
                    class="text-capitalize px-3 mx-2 my-2"
                    small
                    @click="waiveOffSendBack('waiveoff')"
                    ><v-icon dark small>mdi-cancel</v-icon>
                    <v-divider vertical class="mx-2"></v-divider>Waive Off
                </v-btn>
                <v-btn
                    v-if="sno == 22 && isSiuUser && showSendBackWaiveOffAppoint"
                    dark
                    tile
                    color="red"
                    class="text-capitalize px-3 mx-2 my-2"
                    small
                    @click="waiveOffSendBack('')"
                    ><v-icon dark small>mdi-cancel</v-icon>
                    <v-divider vertical class="mx-2"></v-divider>Send Back
                </v-btn>
                <v-btn
                    v-if="sno == 22 && isSiuUser && showWithdrawButton"
                    dark
                    tile
                    color="#E46A25"
                    class="text-capitalize px-3 mx-2 my-2"
                    small
                    @click="waiveOffSendBack('withdraw')"
                    ><v-icon dark small>mdi-cancel</v-icon>
                    <v-divider vertical class="mx-2"></v-divider>Withdraw
                </v-btn>
                <!-- <v-btn
                    v-if="sno > 6 && sno < 19"
                    dark
                    tile
                    color="#E46A25"
                    class="text-capitalize px-3 mx-2 my-2"
                    small
                    @click="clearFormData(formdata)"
                >
                    <v-icon dark small>mdi-cancel</v-icon>
                    <v-divider vertical class="mx-2"></v-divider>Clear
                </v-btn> -->
                <v-btn
                    v-if="sno < 19 && showSaveButton && !removeSaveButton"
                    dark
                    tile
                    color="#23B1A9"
                    class="text-capitalize px-3 mx-2 my-2"
                    @click="save_tab_details"
                    small
                    >
                    <!-- <v-icon dark small>arrow_forward</v-icon> -->
                    <!-- <v-divider vertical class="mx-2"></v-divider> -->
                    Save
                </v-btn>
                <!-- <v-btn
                v-if="sno == 22 || sno == 19"
                    dark
                    tile
                    color="#E46A25"
                    class="text-capitalize px-3 mx-2 my-2"
                    small
                    ><v-icon dark small>folder_open</v-icon>
                    <v-divider vertical class="mx-2"></v-divider>Preview Report
                </v-btn> -->
                <v-btn
                    v-if="sno == 22"
                    dark
                    tile
                    color="#23B1A9"
                    class="text-capitalize px-3 mx-2 my-2"
                    small
                    @click="onSubmit()"
                    ><v-icon dark small>arrow_forward</v-icon>
                    <v-divider vertical class="mx-2"></v-divider>Submit
                </v-btn>
                <v-btn
                    v-if="sno < 22"
                    dark
                    tile
                    color="#23B1A9"
                    class="text-capitalize px-3 mx-2 my-2"
                    small
                    @click="goToNextTab()"
                >
                <!-- <v-icon dark small>arrow_forward</v-icon>
                    <v-divider vertical class="mx-2"></v-divider> -->
                    Next
                </v-btn>
                </v-layout>
            </div>
        </v-card>
    <v-dialog v-model="isAlertView" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2">
          Do you want to proceed ?
        </v-card-title>
        <v-card-text>Accept </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            dark
            tile
            color="#23B1A9"
            class="text-capitalize px-3 mx-2 my-2"
            small
            @click="saveData()"
          >
            yes
          </v-btn>
          <v-btn
            dark
            tile
            color="#152F38"
            class="text-capitalize px-3 mx-2 my-2"
            small
            @click="isAlertView = false"
          >
            No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="sendBackView" width="500">
      <v-card>
        <v-layout row class="ml-3">
          <v-flex md4 sm4 xs4 >
            <v-layout column class="cust_usrsrch mx-2 mt-2">
              <p class="font-weight-bold cstm-clr-pln-usrsrch">
                Send Back Reason
              </p>
              <v-select
                v-model="sendBackReason"
                label="Select"
                :items="sendBackReasonItems"
                solo
                dense
                class="form-control"
            ></v-select>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            dark
            tile
            color="#23B1A9"
            class="text-capitalize px-3 mx-2 my-2"
            small
            @click="saveData()"
          >
            Submit
          </v-btn>
          <v-btn
            dark
            tile
            color="#152F38"
            class="text-capitalize px-3 mx-2 my-2"
            small
            @click="sendBackView = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="enterRemarksMode"
      transition="dialog-bottom-transition"
      max-width="600"
      style="justify-content: center"
    >
      <template v-slot:default="dialog">
        <v-card>
          <v-card-text class="text-center">
            <v-row>
              <v-flex
                xs12
                style="border-bottom:2px solid #f3ecec;margin-bottom: 10px;padding-bottom: 8px: font-weight: 600"
              >
                <span>Remarks</span>
              </v-flex>
            </v-row>
            <v-row>
        <v-col>
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <p class="font-weight-bold cstm-clr-pln-usrsrch">
              Remarks
            </p>
            <v-textarea
              solo
              label="Enter..."
              v-model="enterRemarks"
              rows="1"
              maxlength="500"
              counter="500"
              class="rounded-0"
              aria-required="true"
              dense
            >
            </v-textarea>
          </v-layout>
        </v-col>
        <v-col class="btn-position">
          <v-layout column class="cust_usrsrch mx-2 mt-2 align-center">
            <v-btn
            dark
            tile
            color="#23B1A9"
            class="text-capitalize px-3 mx-2 my-2"
            small
            @click="submitRemarks()"
            ><v-icon dark small>arrow_forward</v-icon>
            <v-divider vertical class="mx-2"></v-divider>Submit
          </v-btn>
          </v-layout>
        </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn
              small
              dark
              tile
              color="rgb(228, 106, 37)"
              @click="dialog.value = false"
              >Close</v-btn
            >
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
    </div>
</template>
<script>
import { saveInvestigationFormDetail, getPrePopulatedData } from "../apiservices/Investigation";
import { acceptWorkitemAuditTrail, acceptRejectWorkitem, sendBackWaiveOffWidhdraw } from "../apiservices/Workitem";
 export default {
    name: 'InvestigationFooterCard',
    data() {
        return {
          acceptMode: false,
          rejectMode: false,
          showWithdrawButton: true,
          showSendBackWaiveOffAppoint: false,
          showAcceptRejectButton: true,
          params: {},
          investigationStatus: "",
          workitemId: "",
          investigatorId: "",
          withdrawnMode: false,
          showSaveButton: true,
          enterRemarksMode: false,
          enterRemarks: "",
          waiveOffMode: false,
          sendBackMode: false,
          currentRole : "",
          isSiuUser : false,
          siuRoles: ["CG77", "CG78","CG79","CG80","CG81","CG82"],
            validationmessage: "This field is required",
            claimTypeList: ["Health-Cashless", "Health-Reimbursement", "Travel"],
            admissionList: ['c'],
            rules: {
              mandatoryrule: [(v) => !!v || this.validationmessage],
            },
            dropDownCommonData: ["Yes", "No"],
            isAlertView: false,
            tab: 1,
            temp: new Date(Date.now() - new Date().getTimezoneOffset()),
            sno: '',
            tabName: "",
            listOfAdmittedHospital: ['India', 'America'],
            policyNumber: "",
            sendBackView: false,
            sendBackReason: '',
            sendBackReasonItems: ["Documents not available", "Wrong Allocation"]
        }
    },
    props: [ 'tabno', 'formdata', 'nonMandatoryFields', 'inputFieldCorrectlyFilled', 'enableFieldObject', 'formElement', 'componentName', "siuWorkItemId", "tabFormDataId","removeSaveButton"],
    created() {
      this.currentRole = this.fetchRoleValue();
      this.isSiuUser = this.siuRoles.some(r=> this.currentRole.indexOf(r) >= 0);
      this.policyNumber = this.$route.params.policyNumber;
        this.sno = this.tabno;
        console.log("this is SNO -->", this.sno);
        this.params = this.$route.params;
        this.workitemId = this.$route.params.workitemId;
        this.investigatorId = this.$route.params.investigationWorkitemId || this.$route.params.allData.siuInvestigationTaskID;
        if(!this.investigatorId) {
          this.showSendBackWaiveOffAppoint = true;
          this.showWithdrawButton = false;
        }
        if(this.isSiuUser) {
          if(this.params.allData.subStatus == "Case Send Back, Pending for referral" || this.params.allData.subStatus == "Case waived-off") {
            this.showSendBackWaiveOffAppoint = false;
          }
            this.showSaveButton = false;
        } else {
          if(this.$route.params.tabData.status == 'initiated'){
            this.showSaveButton = false;
          }
          if(this.params.tabData.status != 'Accepted') {
            this.showSaveButton = false;
          }
          if(this.$route.params.tabData.status == 'Accepted' || this.$route.params.tabData.status == "Rejected" || this.$route.params.tabData.status == "Submited") {
            this.showAcceptRejectButton = false;
          }
        }
        this.getInvestigationData();
    },
  methods: {
    async getInvestigationData() {
      let res = await getPrePopulatedData(this.investigatorId);
      this.investigationStatus = res.status;
      if(this.investigationStatus == 'Rejected' || this.investigationStatus == 'Withdraw') {
        this.showSendBackWaiveOffAppoint = true;
      }
      if(this.investigationStatus == 'Withdraw' || this.investigationStatus == 'Rejected' || this.investigationStatus =='Submited' || this.investigationStatus == 'Accepted') {
        this.showWithdrawButton = false;
      }
    },
    async interimSave() {
      let obj = {siuWorkItemId: this.siuWorkItemId, ...this.formdata};
        await saveInvestigationFormDetail(this.tabFormDataId, obj, this.componentName)
        .then(data=> {
          this.showToast(data.data.msg, this.TOST().SUCCESS);
          this.$store.commit('SET_INVESTIGATION_FORMDATA', this.formdata);
        }).catch(err=> {
          console.log(err);
          this.showToast(err.response?.data?.msg || err.response?.data  || err.response || err, this.TOST().ERROR);
        })
    },
    save_tab_details(){
      this.$emit('validateTabForms')
    },
    checkForMandatoryFields() {
      return this.checkForAllMandatoryFields();
    },
    checkForMandatoryFieldRemark(){
      if(this.formdata.documentVerificationDetails.remarks.trim() !== ""){
          return true;
        }else{
          this.$emit("showErrorForMandatoryFields")
          this.showToast("Please fill all mandatory fields", this.TOST().ERROR);
          return;
        }
    },
    checkForAllMandatoryFields() {
      if (this.validateInvestigationTab(this.formdata)) {
        return true;
      }
      else{
        this.$emit("showErrorForMandatoryFields")
        this.showToast("Please fill all mandatory fields", this.TOST().ERROR);
        return;
      }
    },
    clearFormData(data) {
      let formDataKeyArr = Object.keys(data);
      formDataKeyArr.forEach((key) => {
        if(typeof(!Array.isArray(data[key]) && data[key]) === 'object' && key !== 'rules') {
          this.clearFormData(data[key])
        } else if(!Array.isArray(data[key])) {
          data[key] = "";
        }
      });
    },
    async goToPreviousTab() {
        this.sno--;
        this.changeTabFunction('prev');
        return;
    },
    validateInvestigationTab(formdata) {
      let mandatoryFields = Object.keys(formdata).filter(item => !this.nonMandatoryFields.includes(item));
      let isValid = true;
      mandatoryFields.forEach((key)=>{
        if(!Array.isArray(formdata[key]) && typeof(formdata[key]) === 'object' && formdata[key] !== null) {
          isValid = this.validateInvestigationTab(formdata[key]);
        } else if(formdata[key] === '' && !Array.isArray(formdata[key])  ){
          isValid = false;
        }
      })
      return isValid ? true : false; 
    },
    async goToNextTab() {
        this.sno++;
        this.changeTabFunction();
        return;
    },
    changeTabFunction(dir = null){
      let btnNumber = "myBtn" + this.sno;
      let elem = document.getElementById(btnNumber);
      if(elem == undefined){
        if(dir=='prev'){
          btnNumber = "myBtn" + (this.sno-1);
        }else{
          btnNumber = "myBtn" + (this.sno+1);
        }
        elem = document.getElementById(btnNumber);
      }
      elem.click();
    },
    switchTabs(sno, name) {
      console.log('sno', sno, name);
      this.sno = sno;
      this.tabName = name;
    },
    submit() {
      this.sendBackView = true;
      console.log('on submit', this.$store.state.investigationFormData)
    },
    async waiveOffConfirm() {
      await this.showConfirm(
          "Confirmation",
          "Do you want to proceed?",
          "Yes",
          "No",
          async (Yes) => {
            try{
              let payload = this.getPayload();
              payload.status = "Closed";
              payload.subStatus = "Case waived-off";
              payload.activity = "Case waived-off";
              let res = await acceptWorkitemAuditTrail(payload);
              this.showToast(res.data?.msg || 'Case waived off', this.TOST().SUCCESS);
              this.enterRemarksMode = false;
              this.$router.push('investigation-workitem');
            }
            catch(e){
              this.showToast(e.response?.data?.msg || e.response?.data  || e.response || e, this.TOST().ERROR);
            }
            try {
              let payload = {};
              payload.status = "Closed";
              payload.subStatus = "Case waived-off";
              payload.remarks = this.enterRemarks;
              let res = sendBackWaiveOffWidhdraw(this.workitemId, payload);
              this.showToast(res.data?.msg || 'Case waived off', this.TOST().SUCCESS);
            } catch(e) {
              this.showToast(e.response?.data?.msg || e.response?.data  || e.response || e, this.TOST().ERROR);
            }
          },
          () => {});
    },
    async waiveOffSendBack(mode) {
      this.enterRemarksMode = true;
      switch(mode) {
        case 'withdraw':
          this.withdrawnMode = true;
          this.waiveOffMode = false;
          this.sendBackMode = false;
          this.acceptMode = false;
          this.rejectMode = false;
          break;
        case 'waiveoff':
          this.waiveOffMode = true;
          this.sendBackMode = false;
          this.withdrawnMode = false;
          this.acceptMode = false;
          this.rejectMode = false;
          break;
        case '':
          this.sendBackMode = true;
          this.waiveOffMode = false;
          this.withdrawnMode = false;
          this.acceptMode = false;
          this.rejectMode = false;
          break;
        case 'accept':
          this.acceptMode = true;
          this.rejectMode = false;
          this.waiveOffMode = false;
          this.withdrawnMode = false;
          this.sendBackMode = false;
          break;
        case 'reject':
          this.rejectMode = true;
          this.acceptMode = false;
          this.waiveOffMode = false;
          this.withdrawnMode = false;
          this.sendBackMode = false;
          break;
      }
    },
    submitRemarks() {
      if(!this.enterRemarks){
        this.showToast('Enter remarks', this.TOST().WARNING);
        return;
      }
      if(this.acceptMode){
        this.accept();
        return;
      }
      if(this.rejectMode) {
        this.reject();
        return;
      }
      if(this.withdrawnMode) {
        this.widhdrawn();
        return;
      }
      if(this.waiveOffMode) {
        this.waiveOffConfirm();
      } else {
        this.sendBackConfirm();
      }
    },
    async sendBackConfirm() {
      await this.showConfirm(
          "Confirmation",
          "Do you want to proceed?",
          "Yes",
          "No",
          async (Yes) => {
            this.enterRemarksMode = true;
            this.sendBackMode = true;
            this.waiveOffMode = false;
            try{
              let payload = this.getPayload();
              payload.status = "Closed";
              payload.subStatus = "Case Send Back, Pending for referral";
              payload.activity = "Case Send Back, Pending for referral";
              let res = await acceptWorkitemAuditTrail(payload);
              this.showToast(res.data?.msg || 'Case sent back', this.TOST().SUCCESS);
              this.enterRemarksMode = false;
              this.$router.push('investigation-workitem');
            }
            catch(e){
              this.showToast(e.response?.data?.msg || e.response?.data  || e.response || e, this.TOST().ERROR);
            }
            try {
              let payload = {};
              payload.status = "Closed";
              payload.subStatus = "Case Send Back, Pending for referral";
              payload.remarks = this.enterRemarks;
              let res = await sendBackWaiveOffWidhdraw(this.workitemId, payload);
              this.showToast(res.data?.msg || 'Case Sent Back', this.TOST().SUCCESS);
            } catch(e) {
              this.showToast(e.response?.data?.msg || e.response?.data  || e.response || e, this.TOST().ERROR);
            }
          },
          () => {});
    },
    async widhdrawn() {
      await this.showConfirm(
          "Confirmation",
          "Do you want to proceed?",
          "Yes",
          "No",
          async (Yes) => {
            try{
              let payload = this.getPayload();
              payload.status = "Open";
              payload.subStatus = "Case withdrawn, Pending for re-appointment";
              payload.activity = "Case withdrawn, Pending for re-appointment";
              let res = await acceptWorkitemAuditTrail(payload);
              this.showToast(res.data?.msg || 'Case withdrawn', this.TOST().SUCCESS);
              this.enterRemarksMode = false;
              this.$router.push('investigation-workitem');
            }
            catch(e){
              this.showToast(e.response?.data?.msg || e.response?.data  || e.response || e, this.TOST().ERROR);
            }
            try{
              let payload = {};
              payload.status = "Open";
              payload.subStatus = "Case withdrawn, Pending for re-appointment";
              payload.remarks = this.enterRemarks;
              let res = await sendBackWaiveOffWidhdraw(this.workitemId, payload);
              await acceptRejectWorkitem(this.investigatorId, {status: "Withdraw"});
              this.showToast(res.data?.msg || 'Case Withdrawn', this.TOST().SUCCESS);
              this.enterRemarksMode = false;
              this.$router.push('investigation-workitem');
            }
            catch(e){
              this.showToast(e.response?.data?.msg || e.response?.data  || e.response || e, this.TOST().ERROR);
            }
          },
          () => {});
    },
    async accept() {
      await this.showConfirm(
          "Confirmation",
          "Do you want to proceed?",
          "Yes",
          "No",
          async (Yes) => {
            try{
              let payload = this.getPayload();
              payload.status = "Open";
              payload.subStatus = "Case Appointed, Accepted, Pending for Final Report Submission";
              payload.activity = "Case Appointed, Accepted, Pending for Final Report Submission";
              payload.investigationClaimId = this.$route.params.claimNumber+this.$route.params.featureNumber+'-SIU-1-INV-1';
              let res = await acceptWorkitemAuditTrail(payload);
              this.showToast('Case Accepted' || res.data.msg, this.TOST().SUCCESS);
              await acceptRejectWorkitem(this.$route.params.tabData?._id, {status: "Accepted", siuWorkItemId : this.$route.params.workitemId});
              this.$router.push('investigation-workitem');
            }
            catch(e){
              this.showToast(e.response?.data?.msg || e.response?.data  || e.response || e, this.TOST().ERROR);
            }
          },
          () => {});
    },
    async reject() {
      await this.showConfirm(
          "Confirmation",
          "Do you want to proceed?",
          "Yes",
          "No",
          async (Yes) => {
            try{
              let payload = this.getPayload();
              payload.status = "Open";
              payload.subStatus = "Case Rejected by Investigator";
              payload.activity = "Case Rejected by Investigator";
              payload.investigationClaimId = this.$route.params.claimNumber+this.$route.params.featureNumber+'-SIU-1-INV-1';
              let res = await acceptWorkitemAuditTrail(payload);
              this.showToast('Case Rejected' || res.data.msg, this.TOST().WARNING);
              await acceptRejectWorkitem(this.$route.params.tabData?._id, {status: "Rejected", siuWorkItemId : this.$route.params.workitemId});
              this.$router.push('investigation-workitem');
            }
            catch(e){
              this.showToast(e.response?.data?.msg || e.response?.data  || e.response || e, this.TOST().ERROR);
            }
          },
          () => {});
    },
    getPayload() {
      let payload = {};
      payload.siuWorkItemId = this.$route.params.workitemId;
      payload.investigationWorkItemId = this.$route.params.tabData?._id || this.$route.params.investigationWorkitemId;
      payload.toUserNtid = this.$route.params.tabData?.ntidUserId || this.$route.params.allData.assginDetails.ntID;
      payload.remarks = this.enterRemarks;
      return payload;
    },
    reassignToInvestigator() {
      this.$router.push({name : 'InvestigationAppointment', params: {claimId : this.$route.params.claimId,claimNumber: this.$route.params.claimNumber ,policyNumber: this.policyNumber, assignmentDone: this.$route.params.assignmentDone, siuWorkItemId: this.$route.params.workitemId, featureNumber: this.$route.params.featureNumber}});
    },
    saveData() {},
    alphaNumeric(fieldName, name, mandatory=true) {
      console.log('fieldName', fieldName, name);
      if (fieldName.length > 0) {
        return (v) => /^[a-zA-Z0-9]+$/.test(v) || "Enter valid " + name;
      }
    },
    numericOnly() {
      return (v) => /^[0-9 ]+$/.test(v) || `Only Numbers are allowed.`;
    },
    async onSubmit() {
      let token = this.$store.state.Auth && this.$store.state.Auth.user ? this.$store.state.Auth.user.signInUserSession.idToken.jwtToken : ''//this.$store.state.Auth.user.signInUserSession.idToken.jwtToken
      await saveInvestigationFormDetail(token, this.formdata, this.componentName)
        .then(data=> {
          this.$store.commit('SET_INVESTIGATION_FORMDATA', {...this.$store.state.investigationFormData});
        }).catch(err=> {
          console.log(err);
        })
    },
    browse() {},
    selectFile() {},
  },
 } 
</script>
<style>
.custom{
  background-color: #eee;
  display: flex;
  justify-content: space-between;
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.btn-position {
  align-self: center;
}
.align-center {
  align-items: center;
}
</style>