<template>
  <v-app>
    <!-- <v-card class="ma-3 " tile> -->
    <div>
      <v-layout class="pa-2 ma-3 py-3 text-center">
        <v-flex xs3>
          <div
            style="background-color: #F7F7F7; cursor:pointer;"
            class="pa-2 pr-14 "
            :class="[
              OFRStatus == 'caseUnderOFR'
                ? 'activeRadioclass'
                : '',
            ]"
              @click="changeOFRStatus('caseUnderOFR')"
          >
            <div
              class="font-weight-bold"
              :class="[
                OFRStatus == 'caseUnderOFR'
                  ? 'text-white-insrns'
                  : 'text-dark-insrns',
              ]"
            >
            <!-- OFRStatus -->
              Cases Under OFR
            </div>
          </div>
        </v-flex>
        <v-flex xs3>
          <div
            style="background-color: #F7F7F7;  cursor:pointer"
            class="mr-4 pa-2"
            :class="[
              OFRStatus == 'ofrCompletedCase'
                ? 'activeRadioclass'
                : '',
            ]"
            @click="changeOFRStatus('ofrCompletedCase')"
          >
            <div
              class="font-weight-bold"
              :class="[
                   OFRStatus == 'ofrCompletedCase'
                  ? 'text-white-insrns'
                  : 'text-dark-insrns',
              ]"
            >
              OFR Completed Cases
            </div>
          </div>
        </v-flex>
        <v-flex xs3></v-flex>
        <v-flex xs3></v-flex>
        <v-flex xs3></v-flex>
        <!-- <v-flex xs3>
                    <v-btn
                        tile
                        color="#E46A25"
                        class="text-capitalize text-white px-3 mx-2 my-2"
                        small
                        style="color: #fff;font-size:11px"
                        @click="ViewActivityDashboard()"
                    >
                        <v-icon dark small>visibility</v-icon>
                        <v-divider vertical class="mx-2"></v-divider>
                        <span style="font-size:11px">View Activity Dashboard</span>                     
                    </v-btn>
        </v-flex> -->
      </v-layout>

      
    <div v-if="valStaus==true ">
      <v-data-table
        :headers="headers"
        :items="GetData"
        :page.sync="page"
        :items-per-page="itemsPerPage"
        hide-default-footer
        dense
        @page-count="pageCount = $event"
      >


      
      <template v-slot:item.coverageName="{ item ,value}">
       {{item.coverageName}} 
      </template> 

      <!-- <template v-slot:item.TAT="{ item ,value}">
       {{item.TAT.split("GMT+0000 (Coordinated Universal Time)")}}
      </template>  -->


        <template v-slot:item.action="{ item }">

          <p @click="gotoOFRaudit(item)" class="mt-2 " style="text-decoration: underline; cursor: pointer;color: blue;min-width:60px">View OFR</p>
        </template>

      </v-data-table>
    </div>

      <div v-if="valStaus==false">

            <v-data-table
            :headers="headers"
            :items="GetDataComplet"
            :page.sync="page"
            :items-per-page="itemsPerPage"
            hide-default-footer
            dense
            @page-count="pageCount = $event"
          >



            <template v-slot:item.action="{ item }">

              <p @click="gotoOFRaudit(item)" class="mt-2 " style="text-decoration: underline; cursor: pointer;color: blue;min-width:60px">View OFR</p>
            </template>
          </v-data-table>
      </div>

      <v-divider></v-divider>

      <v-layout row justify-end align-center class="ma-1">
        <v-text
          class="mr-1 font-weight-bold body-1"
          style="color:#3C3D3D; font-size:12px !important"
        >
          <!-- <span class="" style="color:#E46A25;font-size:12px;">Showing 1-10 </span>out of {{total}} records -->
          <span class="" style="color:#E46A25;"
            >Showing {{ fval }}-{{ sval }} </span
          >out of {{ totalLen }} records
        </v-text>
        <v-divider class="mx-2" vertical></v-divider>
        <v-pagination
          class="cust-bnkAcc-pgn"
          v-model="page"
          color="#152F38"
          flat
          :total-visible="9"
          @input="nextPageBtn(page)"
          :length="pagelength"
        ></v-pagination>
      </v-layout>
    </div>

    <!-- </v-card> -->
  </v-app>
</template>

<script>
// import axios from "axios";
 import moment from "moment";

export default {
  components: {},

  created() {
    this.$store.commit("SAVE_PAGE_HEADER", "View Activity Dashboard");

    console.log("Success Token", this.$store.getters.successToken);
    this.token = this.$store.getters.successToken;
     this.getOFRData(this.page,this.OFRStatus);
  },

  computed: {},

  data() {
    return {

      investigationStatus: "CasesUnderInvestigation",
      OFRStatus: 'caseUnderOFR',

      // for pagination AA
      swap_final_count: false,
      fval: 0,
      sval: 0,
      totalLen: "",
      pagelength:'',

      fromvalue: "",
      toValu: "",
      token: null,
      page: 1,
      valStaus:true,
      GetData:[],
      GetDataComplet:[],


      GetData1: [
          {
          claimId: "Claim ID",
          featureNo: "1211111"

        }
      ],
      size: 10,

      
      headers: [
         {
          text: "Claim ID",
          value: "claimId",
          sortable: true,
          divider: true,
          class: "bg-investigation-table-header-color minWidth"
        },
        {
          text: "Feature Number",
          value: "featureNumber",
          sortable: false,
          divider: true,
          class: "bg-investigation-table-header-color minWidth",
        },
        {
          text: "Corporate Group Name",
          value: "corporateGroupName",
          sortable: false,
          divider: true,
          class: "bg-investigation-table-header-color minWidth",
        },
        {
          text: "Insured Name",
          value: "insuredName",
          sortable: false,
          divider: true,
          class: "bg-investigation-table-header-color minWidth",
        },
        {
          text: "Coverage",
          value: "coverageName",
          sortable: false,
          divider: true,
          class: "bg-investigation-table-header-color minWidth",
        },
        {
          text: "Policy Number",
          value: "policyNumber",
          sortable: false,
          divider: true,
          class: "bg-investigation-table-header-color minWidth",
        },
        {
          text: "Product Name",
          value: "productName",
          sortable: false,
          divider: true,
          class: "bg-investigation-table-header-color minWidth",
        },
        {
          text: "Hospital Name",
          value: "hospitalName",
          sortable: false,
          divider: true,
          class: "bg-investigation-table-header-color minWidth",
        },
        {
          text: "Patient Name",
          value: "patientName",
          sortable: false,
          divider: true,
          class: "bg-investigation-table-header-color minWidth",
        },
        {
          text: "Total Amount Approve",
          value: "totalAmountAppr",
          sortable: false,
          divider: true,
          class: "bg-investigation-table-header-color minWidth",
        },
        {
          text: "Claimant Name",
          value: "claimantName",
          sortable: false,
          divider: true,
          class: "bg-investigation-table-header-color minWidth",
        },
        {
          text: "Sum Insured",
          value: "sumInsured",
          sortable: false,
          divider: true,
          class: "bg-investigation-table-header-color minWidth",
        },
        {
          text: "Reserve Amount",
          value: "reserveAmount",
          sortable: false,
          divider: true,
          class: "bg-investigation-table-header-color minWidth",
        },
        {
          text: "Litigation Type",
          value: "litigationType",
          sortable: false,
          divider: true,
          class: "bg-investigation-table-header-color minWidth",
        },
        {
          text: "Reason",
          value: "reason",
          sortable: false,
          divider: true,
          class: "bg-investigation-table-header-color minWidth",
        },
        {
          text: "TAT",
          value: "TAT",
          sortable: false,
          divider: true,
          class: "bg-investigation-table-header-color minWidth",
        },
        // {
        //   text: "Action",
        //   value: "action",
        //   sortable: false,
        //   divider: true,
        //   class: "bg-investigation-table-header-color minWidth",
        // }

        // {text: 'Action', value: 'Action',sortable: false,divider:true, width:"40px",class:"hdr-txt-clr-txt hdr-bg-clr"}
      ],

    };
  },

  methods: {
    // ViewActivityDashboard(){


    // },
      changeOFRStatus(val){
          console.log(val,'<<---OFRStatus value');
           this.OFRStatus = val;
          
          // if (val =="caseUnderOFR") {
          //   this.OFRStatus = true;
          // } else {
          //   this.OFRStatus = false;
          // }
          
          this.getOFRData(1,this.OFRStatus);
  
      },

      changeReferedStatus(val){
          console.log(val,'ReferedStatus value');
           this.ReferedStatus = val;
          
          // if (val =="fromPreAuth") {
          //   this.ReferedStatus = true;
          // } else {
          //   this.ReferedStatus = false;
          // }
          
          this.getOFRData(1,this.OFRStatus);
  
      },

    gotoOFRaudit(val) {
        console.log('_idiii',val);
    //   if(this.OFRStatus == 'UnderCFR'){
         this.$store.commit("SAVE_PAGE_HEADER", "OFR Audit Sheet");
         this.$router.push({
          name: "OFRauditSheet",
          params: { allData: val, from: 'OFRdashboard',screen:'OFR Audit Sheet' },
         
        });
    },




    async getOFRData(pageNum,ofr) {
      //  this.GetData = [];
      console.log(ofr,"ofrstatus val-----PageNumber",pageNum)
      if (this.OFRStatus=='caseUnderOFR') {
        this.valStaus=true
      } else {
        this.valStaus=false
        
      }
      var apiURL = '';
      // var OFRTypeChange = this.OFRStatus.toLowerCase();
      var OFRTypeChange = this.OFRStatus;
      
      apiURL = `ofr/get-ofr-details?perPage=10&pageNo=`+pageNum+`&type=${OFRTypeChange}`
      
      await this.GET(20, apiURL, (response, error) => {
        if (!error) {
          let apiData = response.data.data.data;
          console.log('<<< === api dataaa ===>',apiData);
          this.showToast(response.data.msg, this.TOST().SUCCESS);
          
          
          // this.GetData = [];

          if (this.OFRStatus=='caseUnderOFR') {
            this.GetData = apiData;
            // this.GetData.push({                      
            //   TAT: apiData.TAT ? moment(apiData.TAT).format("DD-MM-YYYY") : "--",
            // })


            this.GetData.forEach((data, index) => {
            // data.srNo = index + 1;
            data.srNo = 10*(pageNum)-9+index

           });
          } else {
            this.GetDataComplet = apiData
            this.GetDataComplet.forEach((data, index) => {
            // data.srNo = index + 1;
            data.srNo = 10*(pageNum)-9+index

           });
            
          }
          this.page=pageNum
          // for pagination

          this.pagelength = response.data.data.total_pages;
          this.totalLen = response.data.data.total;

          if (this.OFRStatus=='caseUnderOFR') {
            let l = this.GetData.total;
          } else {
            let l = this.GetDataComplet.total;
            
          }

          var less_enough = this.totalLen;
          var checkless_init = false;
          if (less_enough < 10) {
            checkless_init = false;
          } else {
            checkless_init = true;
          }

          // if less than 15 we have second value same as total value as no pagination will occur
          if (checkless_init) {
            // checkinit is true means the final count is more than 15 (- 1)
            var traverse = 10 * this.page;
            this.fval = traverse - 9;
            if (this.swap_final_count) {
              this.sval = this.totalLen;
              console.log('<<< === swap_final_count dataaa ===>',this.swap_final_count);
              this.swap_final_count=false
            } else {
              this.sval = traverse;
            }
          } else if (this.totalLen == 0) {
            this.sval = this.fval = 0;
          } else {
            this.fval = 1;
            this.sval = this.totalLen;
          }
        } else {
          if (error.response.data.message.statusCode == 400) {
            // this.GetData = []
              this.fval= 0,
              this.sval= 0,
              this.totalLen= ""
              this.pagelength=0
            
          }
             
          // this.showToast("Unable to fetch the records", this.TOST().ERROR);
          this.showToast(error.response.data.message.msg,this.TOST().ERROR);
        }
      });
    },

    nextPageBtn(indata) {
      console.log("page no value", this.page);
      if (this.pagelength == indata) {
        this.swap_final_count = true;
      } else {
        this.swap_final_count = false;
      }
      this.getOFRData(this.page);
    },


    nextPage(page) {
      this.pageNumber = page;
    },
    
  },

  watch: {},
};
</script>
<style>
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 0.84rem !important;
}

.minWidth{
min-width: 110px !important;
}

.activeRadioclass {
    background-color: #23b1a9 !important;
    color: #fff !important;
}

.text-white-insrns {
    color: #fff;
    font-size: 12px;
}

.text-dark-insrns{
  font-size: 12px;

}

.hdr-txt-clr-txt {
  color: #10242b !important;
  /* padding:0px!important;
    padding: 10px !important;
    padding-left: 14px !important; */
}

.hdr-bg-clr {
  background-color: #f7f7f7;
}
.cust_pr .v-text-field .v-input__slot,
.v-text-field--outline .v-input__slot {
  min-height: 38px !important;
  display: flex !important;
  align-items: center !important;
  /* border:1px red solid; */
}

.pending-work {
  justify-items: start;
}

.lay {
  direction: rtl;
  max-height: 50px;
}
.body-2 {
  font-size: 18px !important;
}
.body-3 {
  font-size: 16px !important;
}

.cstm-clr-pr {
  color: #10242b;
  margin-bottom: 5px;
}
.cust_pr .v-input {
  font-size: 13px !important;
}
.cust_pr .v-input input {
  font-size: 13px !important;
}

.cust_pr .v-input .v-label {
  font-size: 13px !important;
}

.cust-bnkAcc-pgn .v-pagination__navigation {
  box-shadow: unset !important;
  border-radius: unset !important;
  background-color: #ffffff !important;
  border: 1px solid #dae1e6 !important;
}
.cust-bnkAcc-pgn .v-pagination__navigation--disabled {
  background-color: #dae1e6 !important;
}
.cust-bnkAcc-pgn .v-pagination__item {
  box-shadow: unset !important;
  min-width: 2rem !important;
  height: 2rem !important;
  border-radius: unset !important;
  border: 1px solid #dae1e6 !important;
}
.bg-investigation-table-header-color {
  background-color: #23b1a9;
  color: #fff !important;
}
.activeInvestigationClass {
  background-color: #01bbd9 !important;
}
</style>
