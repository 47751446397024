<template>
  <div>
    <v-card color="#01BBD9" class=" pa-2 pl-5" tile>
      <p
        class="text-uppercase  font-weight-medium ma-0 pa-0"
        style="color:#FFFFFF; font-size:13px"
      >
        Address
      </p>
    </v-card>
    <v-card tile class="pa-3">
      <v-layout>
        <v-flex xs6>
          <v-layout column class="cust_pln_em mx-2 ">
            <p class="font-weight-bold cstm-clr-pln-iphd_claims">
              Address
            </p>
            <v-text-field
              solo
              label="Enter..."
              v-model="address"
              class="rounded-0"
              :maxlength="max30"
              :rules="[required()]"
              disabled
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex xs3>
          <v-layout column class="cust_pln_em mx-2 ">
            <p class="font-weight-bold cstm-clr-pln-iphd_claims">
              Landmark
            </p>
            <v-text-field
              solo
              label="Enter..."
              v-model="landmark"
              class="rounded-0"
              :maxlength="max30"
              :rules="[required()]"
              disabled
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex xs3>
          <v-layout column class="cust_pln_em mx-2 ">
            <p class="font-weight-bold cstm-clr-pln-iphd_claims">
              Area
            </p>
            <v-text-field
              solo
              label="Enter..."
              v-model="area"
              class="rounded-0"
              :maxlength="max30"
              :rules="[required()]"
              disabled
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex xs3>
          <v-layout column class="cust_pln_em mx-2">
            <p class="font-weight-bold cstm-clr-pln-iphd_claims">
              State
            </p>
            <!-- item-text="planName"
                                item-value="_id" -->
            <v-select
              :items="stateItems"
              label="Select"
              v-model="state"
              :rules="[required()]"
              solo
              dense
              class="rounded-0"
              disabled
            >
            </v-select>
          </v-layout>
        </v-flex>
      </v-layout>
      <v-layout>
        <v-flex xs3>
          <v-layout column class="cust_pln_em mx-2">
            <p class="font-weight-bold cstm-clr-pln-iphd_claims">
              City/Town/Village
            </p>
            <v-select
              :items="cityTownVillageItems"
              label="Select"
              v-model="cityTownVillage"
              :rules="[required()]"
              solo
              dense
              class="rounded-0"
              disabled
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex xs3>
          <v-layout column class="cust_pln_em mx-2 ">
            <p class="font-weight-bold cstm-clr-pln-iphd_claims">
              District
            </p>
            <v-text-field
              solo
              label="Enter..."
              v-model="District"
              class="rounded-0"
              :maxlength="max30"
              :rules="[required()]"
              disabled
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex xs3>
          <v-layout column class="cust_pln_em mx-2 ">
            <p class="font-weight-bold cstm-clr-pln-iphd_claims">
              Pincode
            </p>

            <v-text-field
              solo
              label="Enter..."
              v-model="pincode"
              class="rounded-0"
              :maxlength="max30"
              :rules="[required()]"
              disabled
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex xs3></v-flex>
        <v-flex xs3></v-flex>
      </v-layout>
    </v-card>
    <v-card color="#01BBD9" class=" pa-2 pl-5" tile>
      <p
        class="text-uppercase  font-weight-medium ma-0 pa-0"
        style="color:#FFFFFF; font-size:13px"
      >
        Contact Details
      </p>
    </v-card>
    <v-card tile class="pa-3">
      <v-layout>
        <v-flex xs3>
          <v-layout column class="cust_pln_em cust-rmvpd-iphd_claims mx-2 ">
            <p class="font-weight-bold cstm-clr-pln-iphd_claims">
              E-mail
            </p>
            <v-text-field
              solo
              label="Enter..."
              v-model="email"
              class="rounded-0"
              :rules="[required(), emailRules2]"
              disabled
            >
              <div
                slot="prepend-inner"
                style="background-color:#F7F7F7;padding: 11px !important"
              >
                <v-icon dense>mail</v-icon>
              </div>
            </v-text-field>
          </v-layout>
        </v-flex>

        <v-flex xs3>
          <v-layout column class="cust_pln_em cust-rmvpd-iphd_claims mx-2 ">
            <p class="font-weight-bold  cstm-clr-pln-iphd_claims">
              Mobile Number
            </p>
            <v-text-field
              solo
              label="Enter..."
              v-model="mbNo"
              :maxlength="max10"
              class="rounded-0"
              :rules="[required(), alphaNumeric()]"
              disabled
            >
              <div
                slot="prepend-inner"
                style="background-color:#F7F7F7;padding:11px !important"
              >
                <v-icon dense>local_phone</v-icon>
              </div>
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex xs3>
          <v-layout column class="cust_pln_em cust-rmvpd-iphd_claims mx-2 ">
            <p class="font-weight-bold  cstm-clr-pln-iphd_claims">
              Contact Number of Attending Relative
            </p>
            <v-text-field
              solo
              label="Enter..."
              v-model="CntctNoOFAttendingRltv"
              :maxlength="max10"
              class="rounded-0"
              :rules="[required(), alphaNumeric()]"
              disabled
            >
              <div
                slot="prepend-inner"
                style="background-color:#F7F7F7;padding:11px !important"
              >
                <v-icon dense>local_phone</v-icon>
              </div>
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex xs3></v-flex>
        <v-flex xs3></v-flex>
      </v-layout>
    </v-card>
    <v-card color="#01BBD9" class=" pa-2 pl-5" tile>
      <p
        class="text-uppercase  font-weight-medium ma-0 pa-0"
        style="color:#FFFFFF; font-size:13px"
      >
        Alternate Address
      </p>
    </v-card>
    <v-card tile class="pa-3">
      <v-layout>
        <v-flex xs3>
          <v-layout column class="cust_pln_em mx-2">
            <p class="font-weight-bold cstm-clr-pln-iphd_claims">
              Is address in Claim from Different ?
            </p>
            <div
              class=" elevation-1"
              style="background-color:#F7F7F7;padding-top: 8px;padding-bottom: 8px; "
            >
              <v-radio-group
                v-model="addDiffClm"
                mandatory
                row
                dense
                class="ma-0 pa-0"
                hide-details
                @change="setChangeAddress"
              >
                <v-radio
                  color="#23B1A9"
                  dense
                  value="Yes"
                  class="ma-0 pa-0 pl-1"
                >
                  <template v-slot:label>
                    <div
                      class="font-weight-bold"
                      style="color:#10242B;font-size:12px"
                    >
                      Yes
                    </div>
                  </template>
                </v-radio>
                <v-radio
                  color="#23B1A9"
                  dense
                  value="No"
                  class="ma-0 pa-0 pl-2"
                >
                  <template v-slot:label>
                    <div
                      class="font-weight-bold"
                      style="color:#10242B;font-size:12px"
                    >
                      No
                    </div>
                  </template>
                </v-radio>
              </v-radio-group>
            </div>
          </v-layout>
        </v-flex>
        <v-flex xs6>
          <v-layout column class="cust_pln_em mx-2">
            <p class="font-weight-bold cstm-clr-pln-iphd_claims">
              Address
            </p>
            <v-text-field
              solo
              label="Enter..."
              v-model="addDiffClmaddress"
              class="rounded-0"
              :maxlength="max30"
              placeholder="Enter..."
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex xs3>
          <v-layout column class="cust_pln_em mx-2 ">
            <p class="font-weight-bold cstm-clr-pln-iphd_claims">
              Landmark
            </p>
            <v-text-field
              solo
              label="Enter..."
              v-model="addDiffClmlandmark"
              class="rounded-0"
              :maxlength="max30"
              placeholder="Enter..."
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex xs3>
          <v-layout column class="cust_pln_em mx-2 ">
            <p class="font-weight-bold cstm-clr-pln-iphd_claims">
              Area
            </p>
            <v-text-field
              solo
              label="Enter..."
              v-model="addDiffClmarea"
              class="rounded-0"
              :maxlength="max30"
              placeholder="Enter..."
            >
            </v-text-field>
          </v-layout>
        </v-flex>
      </v-layout>
      <v-layout>
        <v-flex xs3>
          <v-layout column class="cust_pln_em mx-2">
            <p class="font-weight-bold cstm-clr-pln-iphd_claims">
              State
            </p>
            <!-- item-text="planName"
                                item-value="_id" -->
            <v-select
              :items="addDiffstateItems"
              label="Select"
              v-model="addDiffstate"
              solo
              item-text="state"
              item-value="state"
              dense
              class="rounded-0"
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex xs3>
          <v-layout column class="cust_pln_em mx-2">
            <p class="font-weight-bold cstm-clr-pln-iphd_claims">
              City/Town/Village
            </p>
            <v-select
              :items="addDiffcityTownVillageItems"
              label="Select"
              v-model="addDiffcityTownVillage"
              solo
              dense
              class="rounded-0"
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex xs3>
          <v-layout column class="cust_pln_em mx-2 ">
            <p class="font-weight-bold cstm-clr-pln-iphd_claims">
              District
            </p>

            <v-select
              :items="addDiffdistrictItems"
              label="Select"
              v-model="addDiffdistrict"
              solo
              dense
              class="rounded-0"
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex xs3>
          <v-layout column class="cust_pln_em mx-2 ">
            <p class="font-weight-bold cstm-clr-pln-iphd_claims">
              Pincode
            </p>

            <v-autocomplete
              v-model="pnCde"
              :items="itemspin"
              :search-input.sync="search"
              color="white"
              hide-no-data
              cache-items
              hide-selected
              item-text="pin"
              item-value="pin"
              placeholder="Start typing to Search"
              @change="grab_pincode(pnCde, 'from change')"
              return-object
              maxlength="6"
              counter="6"
              solo
            ></v-autocomplete>
          </v-layout>
        </v-flex>
        <v-flex xs3> </v-flex>
      </v-layout>
    </v-card>

    <v-card color="#01BBD9" class=" pa-2 pl-5" tile>
      <p
        class="text-uppercase  font-weight-medium ma-0 pa-0"
        style="color:#FFFFFF; font-size:13px"
      >
        Alternate Contact Details
      </p>
    </v-card>
    <v-card tile class="pa-3">
      <v-layout>
        <v-flex xs3>
          <v-layout column class="cust_pln_em mx-2">
            <p class="font-weight-bold cstm-clr-pln-iphd_claims">
              Is address in Claim from Different ?
            </p>
            <div
              class=" elevation-1"
              style="background-color:#F7F7F7;padding-top: 8px;padding-bottom: 8px; "
            >
              <v-radio-group
                v-model="altDiffClm"
                mandatory
                row
                dense
                class="ma-0 pa-0"
                hide-details
                @change="setAlternateEmailMob"
              >
                <v-radio
                  color="#23B1A9"
                  dense
                  value="Yes"
                  class="ma-0 pa-0 pl-1"
                >
                  <template v-slot:label>
                    <div
                      class="font-weight-bold"
                      style="color:#10242B;font-size:12px"
                    >
                      Yes
                    </div>
                  </template>
                </v-radio>
                <v-radio
                  color="#23B1A9"
                  dense
                  value="No"
                  class="ma-0 pa-0 pl-2"
                >
                  <template v-slot:label>
                    <div
                      class="font-weight-bold"
                      style="color:#10242B;font-size:12px"
                    >
                      No
                    </div>
                  </template>
                </v-radio>
              </v-radio-group>
            </div>
          </v-layout>
        </v-flex>

        <v-flex xs3>
          <v-layout column class="cust_pln_em cust-rmvpd-iphd_claims mx-2 ">
            <p class="font-weight-bold cstm-clr-pln-iphd_claims">
              Alternate Email 1
            </p>
            <v-text-field
              solo
              label="Enter..."
              v-model="altemail1"
              class="rounded-0"
              :rules="[required(), emailRules2]"
              placeholder="Enter..."
            >
              <div
                slot="prepend-inner"
                style="background-color:#F7F7F7;padding: 11px !important"
              >
                <v-icon dense>mail</v-icon>
              </div>
            </v-text-field>
          </v-layout>
        </v-flex>

        <v-flex xs3>
          <v-layout column class="cust_pln_em cust-rmvpd-iphd_claims mx-2 ">
            <p class="font-weight-bold cstm-clr-pln-iphd_claims">
              Alternate Email 2
            </p>
            <v-text-field
              solo
              label="Enter..."
              v-model="altemail2"
              class="rounded-0"
              :rules="[required(), emailRules2]"
              placeholder="Enter..."
            >
              <div
                slot="prepend-inner"
                style="background-color:#F7F7F7;padding: 11px !important"
              >
                <v-icon dense>mail</v-icon>
              </div>
            </v-text-field>
          </v-layout>
        </v-flex>

        <v-flex xs3>
          <v-layout column class="cust_pln_em cust-rmvpd-iphd_claims mx-2 ">
            <p class="font-weight-bold  cstm-clr-pln-iphd_claims">
              Alternate Mobile Number 1
            </p>
            <v-text-field
              solo
              label="Enter..."
              v-model="altmbNo1"
              :maxlength="max10"
              class="rounded-0"
              :rules="[required(), alphaNumeric()]"
              placeholder="Enter..."
            >
              <div
                slot="prepend-inner"
                style="background-color:#F7F7F7;padding:11px !important"
              >
                <v-icon dense>local_phone</v-icon>
              </div>
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex xs3>
          <v-layout column class="cust_pln_em cust-rmvpd-iphd_claims mx-2 ">
            <p class="font-weight-bold  cstm-clr-pln-iphd_claims">
              Alternate Mobile Number 2
            </p>
            <v-text-field
              solo
              label="Enter..."
              v-model="altmbNo2"
              :maxlength="max10"
              class="rounded-0"
              :rules="[required(), alphaNumeric()]"
              placeholder="Enter..."
            >
              <div
                slot="prepend-inner"
                style="background-color:#F7F7F7;padding:11px !important"
              >
                <v-icon dense>local_phone</v-icon>
              </div>
            </v-text-field>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      forIsNonServiceProvider: false,
      updateFlagStatus: false,
      updatePRN: "",
      updateProviderName: "",
      updateProStatus: "",
      platinum: Boolean,
      gold: Boolean,
      silver: Boolean,
      verifiedFlag: Boolean,
      observationFlag: Boolean,
      updateFlagNonService: Boolean,
      updateRemarks: "",
      dupPage: 1,
      vldDsbled: false,
      // maintains selcted doctors list for list of treating doctors
      docson_are: [],
      disablePP: false,
      disableMt: false,
      create_doc_arr: [],
      // pagination variables
      providerDupliEntityName: "",
      providerDupliAliasName: "",
      proiderDupliErrorId: "",
      panErrorDialog: false,
      panEntityName: "",
      panDupliErrorId: "",
      panNumberErr: "",
      errDialog: false,
      isOverrideForError: false,

      entries: [],
      isLoading: false,
      searchApi: null,
      // pagination variables
      pageLength: 0,
      page: 1,
      setdata: {},
      // pageCount:0,
      pageCount: 0,
      statusItemsPerPage: 5,
      totalRecords: null,
      recordFrom: null,
      recordTo: null,
      statusHisData: [],
      // totalRecords: null,

      // itemsPerPage:0,
      // pageCount:0,
      // page:0,
      updated_doc_list: [],
      tableItems: [],
      prvdrStsHstryDilg: false,
      headers: [
        {
          text: "Status",
          value: "status",
          sortable: false,
          divider: true,
          class: "hdr-txt-clrnew ",
        },
        {
          text: "Date",
          value: "Date",
          sortable: true,
          divider: true,
          class: "hdr-txt-clrnew",
        },
        {
          text: "Time",
          value: "Time",
          sortable: false,
          divider: true,
          class: "hdr-txt-clrnew",
        },
        {
          text: "User",
          value: "userName",
          sortable: false,
          divider: true,
          class: "hdr-txt-clrnew",
        },
        {
          text: "Remarks",
          value: "remarks",
          sortable: false,
          divider: true,
          class: "hdr-txt-clrnew",
        },
      ],

      doclistedit: false,
      childprovider: false,
      parentprovider: "",
      mappingtype: ["Owned", "Managed", "Franchise"],
      mappingtypeis: "",

      docspool: [],
      enabledocslist: false,
      effectivefrom: "",
      effectiveto: "",
      R: "",
      RegDate: false,
      RegDateTo: false,
      regDatefrom: false,
      medicalPractionerRoundTheClock: "",
      dailyMedicalRecords: "",
      nursingRoundTheClock: "",
      hasOperationTheatre: "",

      citydis: false,
      doclistedit: false,
      ptype_: false,
      sp_other: false,
      setstateofrequired: true,
      mydatais: "",
      s_c_d_nm: false,
      drLstDilg: false,
      docen: false,
      docten: false,
      otherservicesprovided: "",
      other_pt: "",
      search_area_pageno: 1,
      area_page_no: 1,
      nextis: 1,
      foundis: false,
      search: "",
      citysearch: "",
      pitems: [],
      isNonSrvcPrvdr: false,
      items: ["Institution", "Individual"],
      rgstrdEntity: "Institution",
      entityNme: "",
      alsNme: "",
      ptypeis: [
        "Hospital",
        "Daycare Center",
        "Dental",
        "Nursing Home",
        "Clinic",
        "Rehabilitation Center",
        "Pharmacy",
        "Diagnostic Centre",
        "Home Care provider",
        "Hospice",
        "Ambulance",
        "Air Ambulance",
        "Telehealth Provider",
        "Psychiatric Facility",
        "Other",
      ],
      prvdrtyeitems: [
        "Internal Medicine",
        "General Surgery",
        "Obstetrics & Gynecology",
        "Paediatrics",
        "Orthopaedic",
        "Gastroenterology & Hepatology",
        "Nephrology",
        "Urology",
        "Endocrinology",
        "Rheumatology",
        "Pulmonology",
        "Ophthalmology",
        "Otorhinolaryngology",
        "Cardiology",
        "Cardiothoracic Vascular Surgery (CTVS)",
        "Interventional Radiology",
        "Thoracic Surgery",
        "Neurology",
        "Neurosurgery",
        "Neuropsychiatry",
        "Medical Oncology",
        "Surgical Oncology",
        "Radiation Oncology",
        "Gynaecological Oncology",
        "GI Surgery",
        "Colorectal Surgery/Proctology",
        "Transplant Surgery",
        "Hand Surgery",
        "Plastic and Reconstructive Surgery",
        "Paediatric Surgery",
        "Neonatology",
        "Psychiatry",
        "Dermatology",
        "Anaesthesiology",
        "Dental - Orthodontics",
        "Dental - Endodontics",
        "Dental - Oral and Maxillofacial Surgery",
        "Dental - Periodontics",
        "Dental - Prosthodontics",
        "Ayurveda",
        "Unani",
        "Siddha",
        "Homeopathy",
        "Other",
      ],
      providerType: "",
      provider_Id: "",
      reviewRemarks: "",
      other: "",
      rgstNo: "",
      RgstrtnVldUpto: "",
      RgstrnVldUptoInstncalender: false,
      RgstrnVldUptoInstdate: "",
      SrvcsPrvded: "",
      tlphnNo: "",
      isNonSrvcPrvdrtlphnNo: "",
      pan: "",
      prn: "",
      isNonSrvcPrvdrprn: "",
      status: "",
      isNonSrvcPrvdrstatus: "",
      rohiniId: "",
      RgstrdBdy: "",
      isNonSrvcPrvdremail: "",
      fxNo: "",
      isNonSrvcPrvdrfxNo: "",
      adrsLne1: "",
      adrsLne2: "",
      adrsLne3: "",
      othrAdrs: "",
      noOfBeds: "",
      dlyMdclRcrdsItems: ["Yes", "No"],
      dlyMdclRcrds: "",
      hsOtItems: ["Yes", "No"],
      hsOt: "",
      mprtcItems: ["Yes", "No"],
      mprtc: "",
      nrtcItems: ["Yes", "No"],
      nrtc: "",
      shwInstitution: true,
      shwIndividual: false,
      salutationind: "",
      sltnIndItems: ["Mr.", "Mrs"],
      frstnmeInd: "",
      mdlnmeInd: "",
      lstnmeInd: "",
      gndrInd: "",
      mbNoInd: "",
      extrnlRfNoInd: "",
      prnInd: "",
      statusInd: "",
      statusIndItems: ["Active", "Inactive"],
      prvdrTypeInd: "",
      otherInd: "",
      qualificationInd: "",
      otherInd2: "",
      RgstNoItems: ["Others"],
      // RgstNo:'',
      RgstCnlInd: "",
      OtherInd3: "",
      QualificationInd2: "",
      remarksInd: "",
      counter: 0,
      max05: 5,
      max10: 10,
      max20: 20,
      max30: 30,
      max40: 40,
      max50: 50,
      max100: 100,
      max200: 200,
      vldExtDilg: false,
      pid: "",
      duplicateData: [],
      duplicatePanData: [],
      // numberRule: v  => {
      //     if (!v.trim()) return true;
      //     if (!isNaN(parseFloat(v)) && v >= 0 && v >=15) return true;
      //     return 'Provider does not meet minimum bed criteria';
      // },
      address: "Andheri East, Mumbai, Maharashtra 400093",
      landmark: " Ackruti Trade Center",
      area: "Andheri",
      stateItems: ["Maharashtra"],
      stateList: ["Maharashtra"],
      State: "Maharashtra",
      state: "Maharashtra",
      cityTownVillageItems: ["Mumbai"],
      cityTownVillage: "Mumbai",
      district: "Mumbai",
      pincode: "400093",
      email: "",
      mbNo: "",
      addDiffClm: "No",
      addDiffClmaddress: "Andheri East, Mumbai, Maharashtra 400093",
      addDiffClmlandmark: " Ackruti Trade Center",
      addDiffClmarea: "Andheri",
      addDiffstateItems: ["Maharashtra"],
      addDiffstate: "Maharashtra",
      addDiffcityTownVillageItems: ["Mumbai"],
      addDiffcityTownVillage: "Mumbai",
      addDiffdistrict: "Mumbai",
      addDiffdistrictItems: ["Mumbai"],
      addDiffpincode: "400093",
      altDiffClm: "No",
      altemail1: "Testmail2@Iorta.com",
      altemail2: "Testmail2@Iorta.com",
      altmbNo1: "0123456798",
      altmbNo2: "0123456789",
      max30: 30,
      max10: 10,
      CntctNoOFAttendingRltv: "",
      required() {
        return (v) => (v && v.length > 0) || "This Field is Required";
      },
      alphaNumeric() {
        return (v) => /^[0-9 ]+$/.test(v) || `Only Numbers are allowed.`;
      },
      cntryItems: ["Others"],
      country: "India",
      districtItems: [],
      district: "",
      District: "Mumbai",
      ctvItems: [],
      ctv: "",
      addDifferentAddress: true,
      pnCde: "",
    };
  },
  mounted() {
    // getting membersearch data
    if (this.$store.state.didMembersearch) {
      let memberdata = this.$store.getters.fetchClaimMembersearchData;
      if (Object.keys(memberdata) !== 0) {
        //populating data
        this.email = memberdata.email;
        this.mbNo = memberdata.mobileNumber;
        this.CntctNoOFAttendingRltv = memberdata.proposerMobileNumber;
      } else {
        console.log("member data is null");
      }
    }
  },
  watch: {
    page(newPage) {
      this.nextPageBtn(newPage);
    },

    citysearch(val) {
      if (val == this.ctv) {
        console.log("matches v model");
        return;
      }

      // return

      console.log("CITY VAL is ", val);
      if (val == null) {
        console.log("returned as val foun to be null");
        return;
      }

      if (val.length > 3) {
        var vlen = val.length;
        console.log(
          "vlen is incoming string length to be compared inside the array of objects--->",
          vlen
        );
        var ctvitemlen = this.ctvItems.length;
        var my_counter = 0;
        for (let i = 0; i < this.ctvItems.length; i++) {
          var strlen = this.ctvItems[i].area.slice(0, vlen);
          console.log(
            val.length,
            "<- searched length |&&| strlen length is",
            strlen.length
          );
          console.log(val, "<- searched |&&| str is", strlen);

          // if(vlen > strlen.length){
          //     console.log("data not available to search in CITY")
          //     return
          // }
          my_counter++;
          if (strlen == val) {
            console.log(strlen.length, "CITY found as ", val.length);
            //   this.foundis = false
            return;
          }

          if (my_counter == ctvitemlen) {
            console.log(
              my_counter == ctvitemlen,
              " < ??????????????????- searched entired array value not found, now calling API with ident 1 from watch"
            );
            this.search_area_pageno++;
            this.get_areas(this.district, 1);
          }

          // if(strlen != val){
          //     console.log(strlen.length,"CITY not found as ",val.length)
          //     //   this.foundis = false
          //     this.search_area_pageno++
          //     this.get_areas(this.district,1)
          //     return
          // }else{
          //     console.log(this.ctvItems.length,"CITY found true", this.ctvItems[i].area)
          //     // this.foundis = true
          //     return
          // }
        }
      } else {
        console.log("citysearch not 3");
      }
    },

    search(val) {
      // Items have already been loaded
      console.log(this.itemspin.length, " val is ", val);
      if (val == null) {
        console.log("returned as val foun to be null");
        return;
      }

      if (val.length > 3) {
        var vlen = val.length;
        console.log("vlen", vlen);
        //  var arrlen = this.entries.length
        //  console.log(arrlen,'arrlen')
        //  var newlen =this.entries[515]
        //  console.log("newlen is from watcher",newlen)
        if (this.itemspin.length == 0 && val.length >= 3) {
          this.callapi(val, 0);
          return;
        } else {
          console.log("itens len");
        }

        for (let i = 0; i < this.itemspin.length; i++) {
          var strlen = this.itemspin[i].pin.slice(0, vlen);
          console.log(vlen > strlen.length, "strlen is", strlen);

          if (vlen > strlen.length) {
            console.log("data not available to search");
            return;
          }

          if (strlen != val) {
            console.log(strlen.length, "not found as ", val.length);
            //   this.foundis = false
            this.callapi(val, 0);
            return;
          } else {
            console.log(
              this.itemspin.length,
              "found true",
              this.itemspin[i].pin
            );
            // this.foundis = true
            return;
          }
        }
      } else {
        console.log("not 3");
      }
    },
  },
  methods: {
    clearFields() {},
    grab_pincode(indata, from) {
      // return
      // gp99
      console.log("GRABBED PIN-CODE CALLED FROM", indata);
      this.addDiffClmarea = indata.all.area;
      this.addDiffstateItems = [indata.all.state];
      this.addDiffstate = indata.all.state;
      this.addDiffdistrict = indata.all.district;
      this.addDiffdistrictItems = [indata.all.district];
      this.addDiffpincode = indata.all.pincode;

      this.cntryItems = [];
      this.cntryItems.push({
        country: indata.all.country,
        all: indata.all.country,
      });
      this.country = indata.all.country;
      this.districtItems = [];
      this.districtItems.push({
        district: indata.all.district,
        all: indata.all.district,
      });
      this.district = indata.all.district;

      this.stateItems = [];
      this.stateItems.push({ state: indata.all.state, all: indata.all.state });
      this.state = indata.all.state;

      this.ctvItems = [];

      this.ctvItems.push(
        { area: indata.all.area, all: indata.all.area },
        { area: "Other" }
      );
      this.ctv = indata.all.area;

      console.log(this.ctv, "area is", this.ctvItems);
      // alert("grabbed pin-code called")

      return;
      console.log("grabbed pincode is --->", indata);
      console.log("grabbed pincode WILL CALL DISTRICT --->", indata);
      this.GET(3, "district/get-all?state=" + indata.all.state, (res) => {
        console.log("res of disctruct is ", res);
        if (res.status == 200) {
          this.districtItems = res.data.data.data;
          console.log(
            "districtItems from response is --->",
            this.districtItems
          );
          this.districtItems.map((e, index) => {
            console.log(e.district, " -> ");
            if (e.district == indata.all.district) {
              //  alert("matched disssss")
              this.district = this.districtItems[index].district;
              this.getstates(
                indata.all.country,
                this.districtItems[index].state
              );
              this.country = indata.all.country;
              console.log(
                this.districtItems[index],
                "<<< --selected dis ",
                this.district
              );
            }
          });
        } else {
          console.log("something went wrong, pincode", res);
        }
      });
    },
    callapi(indata, ident) {
      // return
      console.log("ident is ", ident);
      console.log("call api is called", indata);
      this.GET(3, "city/search-by-pincode/" + indata, (res) => {
        console.log("response of pincode api is  -> ", res.data.data);

        if (this.counter == 2) {
          this.pitems = [];
          this.counter = 0;
        }
        var pin_not_found = false;
        if (res.data.data.length == 0) {
          // for making state city and district non-mandatory assign pin code response is zero
          this.s_c_d_nm = true;
          pin_not_found = true;
          this.counter = "";
          this.state = "";
          this.district = "";
          this.ctv = "";

          this.showToast("Pin Code not found", this.TOST().ERROR);
        } else {
          this.s_c_d_nm = false;
        }

        var processed_pin = res.data.data.map((e) => {
          return { pin: e.pincode.toString(), all: e };
        });
        this.pitems.push(...processed_pin);

        console.log("|--==>>> ", this.pitems);
        if (pin_not_found) {
          this.pitems.push({ pin: indata.toString(), all: "" });
        }

        if (ident == 1) {
          console.log("FROM IDENT ONE -> 1", this.indtype.data.indata);
          this.pitems.map((e) => {
            // console.log("e is ",e);
            // && e.all.area == this.indtype.data.indata.cityTownVillage
            if (
              e.pin == indata &&
              e.all.district == this.indtype.data.indata.district
            ) {
              console.log(indata, "matched pin code is ", e);
              this.pnCde = e;
            }
          });

          console.log("what we have after pin code is found ->", this.pnCde);
          //    {pin:indata.toString(),all:{}};

          this.grab_pincode(this.pnCde, "Frm CALLED API");
        }
        this.counter++;
        console.log("call api pitems in new call api", this.pitems);
        // this.pitems = res.data.data.data.map
        // console.log("pitems is -> ", this.pitems);
      });
    },

    getstates(countryincoming, stateincoming) {
      // alert("get sstates called ??????")
      console.log("coiuntry is", countryincoming);
      console.log("incoming state is ", stateincoming);
      var self = this;

      this.GET(3, "state/get-state-byCountry/" + countryincoming, (res) => {
        //    if(res.status == 200){
        self.stateItems = res.data.data;
        console.log(
          self.stateItems.length,
          " RESPONSE of state api is  -> ",
          self.stateItems
        );
        self.stateItems.map((e) => {
          console.log(stateincoming, "matching state in map", e.state);
          if (e.state == stateincoming) {
            console.log(e, "MATCHED STATE FOUND", stateincoming);
            self.state = stateincoming;
            console.log("vmodel of state is   -> ", self.state);
            self.get_areas(self.district, 0);
          }
          // return {state: e.state, all:e}
        });

        //    }else{
        //        console.log(" something went wrong, state ", res);
        //    }
      });
    },

    get_areas(indata_district, ident) {
      console.log("IDENT is -> ", ident);

      console.log(ident, "get areas called from state", indata_district);
      // aa99
      //when recalling send this.district into it on page number incrementation
      console.log(
        this.pnCde.pin,
        " <--  pincode | Search area page no is -> ",
        this.search_area_pageno
      );
      console.log("area page no is -> ", this.area_page_no);
      var pageno = ident == 1 ? this.search_area_pageno : this.area_page_no;
      console.log("pageno -> ", pageno);

      // '/city/search-by-pincode/{pincode}'
      // +'&district='+indata_district+'&perPage=1000&pageNo='+pageno
      this.GET(3, "city/search-by-pincode/" + this.pnCde.pin, (res, error) => {
        try {
          console.log("response of areas api is  -> ", res.data);

          if (res.status == 200) {
            if (res.data.data.length == 0) {
              console.log(
                "City, Town, Area not found, limits reached or API reahed last page ?"
              );
              return;
            }
            this.ctvItems = [];
            this.ctvItems = res.data.data.map((e) => {
              return { area: e.area, all: e };
            });
            this.ctvItems.push({ area: "Other" });

            if (ident == 1) {
              console.log(
                " stopped area's auto increment as ident from user search ",
                ident
              );

              return;
            }
            console.log(" ctvItemss ", this.ctvItems.length);
            var ctvitemlen = this.ctvItems.length;
            var my_counter = 0;
            var findareapromise = new Promise((resolve, reject) => {
              // fix99
              if (
                this.indtype.type == "validate" ||
                this.indtype.type == "edit"
              ) {
                //   alert("as not from new")
                if (this.indtype.data.indata.cityTownVillage != "") {
                  this.ctvItems.unshift({
                    area: this.indtype.data.indata.cityTownVillage,
                  });
                }

                this.ctv = this.indtype.data.indata.cityTownVillage;
                if (this.indtype.data.indata.cityTownVillage == "Other") {
                  this.citydis = true;
                } else {
                  this.citydis = false;
                }
                resolve("value found, dont call API, FROM EDIT OR VALIDATE");
                return;
              }
              for (let i = 0; i < this.ctvItems.length; i++) {
                my_counter++;
                if (this.pnCde.all.area == this.ctvItems[i].area) {
                  console.log(
                    my_counter,
                    "no need to recall API with Page update",
                    this.ctvItems[i].area
                  );
                  this.ctv = this.pnCde.all.area;
                  resolve("value found, dont call API");
                  return;
                }

                if (ctvitemlen == my_counter) {
                  console.log(
                    this.pnCde.all.area,
                    "< rejected as ?",
                    my_counter
                  );
                  console.log("i is ", i);
                  reject("value not found, call API");
                }
              }
            });

            findareapromise
              .then((responseis) => {
                console.log(
                  this.ctv,
                  "do not call api area value found",
                  this.ctv == this.pnCde.all.area
                );
                this.ctvItems.push({ area: "Other" });
              })
              .catch((eis) => {
                console.log(
                  eis,
                  "area value not found call next records, increment page",
                  this.ctv == this.pnCde.all.area
                );
                // call same api function here with page number increment and also pass district to it
                this.ctv = "";
                this.area_page_no++;
                // alert("from CATCH,value not found for AREA");
                this.get_areas(this.district, 0);
              });
          } else {
            console.log(" something went wrong, areas ", res);
          }
        } catch (error) {
          console.log("from catch -> ", error);
          return;
        }
      });
    },
    setChangeAddress() {
      if (this.addDiffClm == "No") {
        addDifferentAddress: false;
        console.log("city town", this.cityTownVillage);
        //  adding dummy info of local state
        this.addDiffClmaddress = this.address;
        this.addDiffClmlandmark = this.landmark;
        this.addDiffClmarea = this.area;
        this.addDiffstateItems = this.stateItems;
        this.addDiffstate = this.state;
        this.addDiffcityTownVillageItems = this.cityTownVillageItems;
        this.addDiffcityTownVillage = this.cityTownVillage;
        this.addDiffdistrict = this.district;
        this.addDiffpincode = this.pincode;
      } else {
        addDifferentAddress: true;
        this.addDiffClmaddress = "";
        this.addDiffClmlandmark = "";
        this.addDiffClmarea = "";
        this.addDiffstateItems = this.stateItems;
        this.addDiffstate = "";
        this.addDiffcityTownVillageItems = this.cityTownVillageItems;
        this.addDiffcityTownVillage = "";
        this.addDiffdistrict = "";
        this.addDiffpincode = "";
      }
    },
    // setting alternate emails and mobilenumbers
    setAlternateEmailMob() {
      if (this.altDiffClm == "No") {
        console.log("inside no", this.altDiffClm);
        // adding dummy info
        this.altemail1 = "Testmail1@Iorta.com";
        this.altemail2 = "Testmail2@Iorta.com";
        this.altmbNo1 = "0123456798";
        this.altmbNo2 = "0123456789";
      } else {
        console.log("inside no", this.altDiffClm);
        this.altemail1 = "";
        this.altemail2 = "";
        this.altmbNo1 = "";
        this.altmbNo2 = "";
      }
    },
    emailRules2(v) {
      if (v.length !== 0) {
        console.log("testing", /^[0-9 ]+$/.test(v));
        if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,6})+$/.test(v)) {
          return "E-mail must be valid";
        } else {
          console.log("else none");
        }
      }
    },

    claimAddress() {
      let obj = {
        address: this.address,
        landmark: this.landmark,
        area: this.area,
        stateItems: this.stateItems,
        state: this.state,
        // cityTownVillageItems
        // cityTownVillage
        // district
        // pincode
        // email
        // mbNo
        // addDiffClm
        // addDiffClmaddress
        // addDiffClmlandmark
        // addDiffClmarea
        // addDiffstateItems
        // addDiffstate
        // addDiffcityTownVillageItems
        // addDiffcityTownVillage
        // addDiffdistrict
        // addDiffpincode
        // altDiffClm
        // altemail1
        // altemail2
        // altmbNo1
        // altmbNo2
        // CntctNoOFAttendingRltv
      };
      this.$emit("claimAddress_emit", obj);
    },
  },
  computed: {
    itemsSearch() {
      return this.entries;
    },

    isreq_othercity() {
      if (this.ctv == "Other") {
        // this.citydis = true
        return true;
      } else {
        return false;
        // this.citydis = false
      }
    },

    isRequired_parent() {
      console.log("from computed childprovider", this.childprovider);
      if (this.childprovider) {
        console.log("true childprovider", this.childprovider);
        // this.disablePP=false
        return true;
      } else {
        console.log("false childprovider", this.childprovider);
        // this.disablePP=true
        return false;
      }
    },

    isRequired_mapping() {
      if (this.parentprovider.length == 0) {
        // this.disableMt=false
        return false;
      } else {
        // this.disableMt=true
        return true;
      }
    },

    isRequired() {
      console.log("from computed s_c_d_nm", this.s_c_d_nm);
      if (this.s_c_d_nm) {
        console.log("true s_c_d_nm", this.s_c_d_nm);
        return false;
      } else {
        console.log("false s_c_d_nm", this.s_c_d_nm);
        return true;
      }
    },

    itemspin() {
      return this.pitems;
    },

    effto() {
      var fromis = moment(this.effectivefrom, "YYYY-MM-DD")
        .toDate()
        .getTime();
      var tois = moment(this.effectiveto, "YYYY-MM-DD")
        .toDate()
        .getTime();
      // console.log(fromis,"<--from is | to is -> ",tois);
      console.log(fromis > tois, "condition ");
      if (fromis > tois) {
        console.log("condotion found true should show toast");
        this.showToast(
          "Registration Effective To’ date must be after ‘Registration Effective From’ date",
          this.TOST().WARNING
        );

        return;
      }

      return this.effectiveto
        ? moment(this.effectiveto).format("DD-MM-YYYY")
        : "";
    },

    efffrom() {
      return this.effectivefrom
        ? moment(this.effectivefrom).format("DD-MM-YYYY")
        : "";
    },

    cmputdRgstrnVldUptoInstdteMmntjs() {
      return this.RgstrnVldUptoInstdate
        ? moment(this.RgstrnVldUptoInstdate).format("DD-MM-YYYY")
        : "";
    },
    cmputdRjs() {
      return this.R ? moment(this.R).format("DD-MM-YYYY") : "";
    },
    fxNoErrors() {
      const errors = [];
      if (!this.$v.fxNo.$dirty) return errors;
      !this.$v.fxNo.numeric && errors.push("Only Numbers are Allowed");
      return errors;
    },

    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("E-mail must be valid");
      return errors;
    },

    tlphnNoErrors() {
      const errors = [];
      // const value = this.$v.tlphnNo.$model
      if (!this.$v.tlphnNo.$dirty) return errors;
      !this.$v.tlphnNo.numeric && errors.push("Only Numbers are Allowed");
      return errors;
    },

    rohiniIDErrors() {
      const errors = [];
      const value = this.$v.rohiniId.$model;

      // errors.push('Invalid ROHINI ID')
      if (!this.$v.rohiniId.$dirty) return errors;
      !this.$v.rohiniId.numeric && errors.push("Only Numbers are Allowed");
      if (value.length > 1) {
        !this.$v.rohiniId.rohiniIdValue && errors.push("Invalid ROHINI ID");
      }
      return errors;
    },

    isNonSrvcPrvdrtlphnNoError() {
      const errors = [];
      if (!this.$v.isNonSrvcPrvdrtlphnNo.$dirty) return errors;
      !this.$v.isNonSrvcPrvdrtlphnNo.numeric &&
        errors.push("Only Numbers are Allowed");
      return errors;
    },

    prtreq() {
      const errors = [];
      if (!this.$v.parentprovider.$dirty) return errors;
      !this.$v.parentprovider.required &&
        errors.push("This field is required", this.$v.parentprovider.required);
      return errors;
    },

    mapreq() {
      console.log("mapping req of maytype required ");
      const errors = [];
      if (!this.$v.mappingtypeis.$dirty) return errors;
      !this.$v.mappingtypeis.required && errors.push("This field is required");
      return errors;
    },

    countryreq() {
      const errors = [];
      if (!this.$v.country.$dirty) return errors;
      !this.$v.country.required && errors.push("This field is required");
      return errors;
    },
    statereq() {
      const errors = [];
      if (!this.$v.state.$dirty) return errors;
      !this.$v.state.required && errors.push("This field is required");
      return errors;
    },
    disreq() {
      const errors = [];
      if (!this.$v.district.$dirty) return errors;
      !this.$v.district.required && errors.push("This field is required");
      return errors;
    },
    enreq() {
      const errors = [];
      if (!this.$v.entityNme.$dirty) return errors;
      !this.$v.entityNme.required && errors.push("This field is required");
      !this.$v.entityNme.alphaRegex &&
        errors.push("Only alphabets are allowed");
      return errors;
    },
    pinreq() {
      const errors = [];
      if (!this.$v.pnCde.$dirty) return errors;
      !this.$v.pnCde.required && errors.push("This field is required");
      return errors;
    },
    alreq() {
      const errors = [];
      if (!this.$v.alsNme.$dirty) return errors;
      // !this.$v.alsNme.required && errors.push('This field is required')
      !this.$v.alsNme.alphaRegex && errors.push("Only alphabets are allowed");
      return errors;
    },
    adonereq() {
      const errors = [];
      if (!this.$v.adrsLne1.$dirty) return errors;
      !this.$v.adrsLne1.required && errors.push("This field is required");
      return errors;
    },
    adtworeq() {
      const errors = [];
      if (!this.$v.adrsLne2.$dirty) return errors;
      !this.$v.adrsLne2.required && errors.push("This field is required");
      return errors;
    },
    nonservicereq() {
      const errors = [];
      if (!this.$v.isNonSrvcPrvdremail.$dirty) return errors;
      // !this.$v.isNonSrvcPrvdremail.required && errors.push('This field is required')
      !this.$v.isNonSrvcPrvdremail.email && errors.push("E-mail must be valid");
      return errors;
    },

    providerTypereq() {
      const errors = [];
      if (!this.$v.providerType.$dirty) return errors;
      !this.$v.providerType.required && errors.push("This field is required");
      return errors;
    },

    isNonSrvcPrvdrfxNoError() {
      const errors = [];
      if (!this.$v.isNonSrvcPrvdrfxNo.$dirty) return errors;
      !this.$v.isNonSrvcPrvdrfxNo.numeric &&
        errors.push("Only Numbers are Allowed");
      return errors;
    },

    panreq() {
      const errors = [];
      // if (!this.$v.pan.$dirty) return errors
      !this.$v.pan.numberRegex && errors.push("Pan format is Invalid");
      return errors;
    },

    ctvErrors() {
      const errors = [];
      if (!this.$v.pan.$dirty) return errors;
      !this.$v.ctv.required && errors.push("This field is required");
      return errors;
    },

    othercity() {
      console.log(this.$v.othrAdrs);
      const errors = [];
      if (!this.$v.othrAdrs.$dirty) return errors;
      !this.$v.othrAdrs.required && errors.push("This field is required");
      return errors;
    },

    noOfBeds_chk() {
      if (this.noOfBeds.length > 0) {
        console.log("from if ");
        if (/^[0-9 ]+$/g.test(this.noOfBeds) != true) {
          // this.ttl_err = true
          return "Only numbers are allowed";
        } else {
          // this.ttl_err = false
          return [];
        }
      } else {
        console.log(
          "from else diurecty check on title ",
          !this.$v.noOfBeds.$dirty
        );
        const errors = [];
        if (!this.$v.noOfBeds.$dirty) return errors;
        !this.$v.noOfBeds.required && errors.push("This field is required");
        return errors;
      }
    },

    isVldtnDtls() {
      console.log("inside isVldtnDtls function");
      if (this.indtype.type === "validate") {
        if (
          this.providerType === "Hospital" ||
          this.providerType === "Daycare Center" ||
          this.providerType === "Nursing Home"
        ) {
          console.log("inside if isVldtnDtls function");
          console.log(this.RgstrdBdy, this.rgstNo);
          // if((this.RgstrdBdy==="" && this.rgstNo==="")||(this.RgstrdBdy===undefined  && this.rgstNo===undefined)||(this.RgstrdBdy===null  && this.rgstNo===null)){
          if (
            this.RgstrdBdy === "" ||
            this.rgstNo === "" ||
            this.RgstrdBdy === undefined ||
            this.rgstNo === undefined ||
            this.RgstrdBdy === null ||
            this.rgstNo === null
          ) {
            console.log(
              "inside if isVldtnDtls this.RgstrdBdy==='' this.rgstNo==='' cehck function"
            );
            return true;
          } else {
            console.log(
              "inside else isVldtnDtls this.RgstrdBdy==='' this.rgstNo==='' cehck function"
            );
            return false;
          }
        }
      } else {
        console.log("inside else isVldtnDtls function");
        return false;
      }
    },
    dailyMedicalRecords_chk() {
      const errors = [];
      if (!this.$v.dailyMedicalRecords.$dirty) return errors;
      !this.$v.dailyMedicalRecords.required &&
        errors.push("This field is required");
      return errors;
    },
    hasOperationTheatre_chk() {
      const errors = [];
      if (!this.$v.hasOperationTheatre.$dirty) return errors;
      !this.$v.hasOperationTheatre.required &&
        errors.push("This field is required");
      return errors;
    },
    medicalPractionerRoundTheClock_chk() {
      const errors = [];
      if (!this.$v.medicalPractionerRoundTheClock.$dirty) return errors;
      !this.$v.medicalPractionerRoundTheClock.required &&
        errors.push("This field is required");
      return errors;
    },
    nursingRoundTheClock_chk() {
      const errors = [];
      if (!this.$v.nursingRoundTheClock.$dirty) return errors;
      !this.$v.nursingRoundTheClock.required &&
        errors.push("This field is required");
      return errors;
    },

    numberRule() {
      if (
        this.providerType === "Hospital" ||
        this.providerType === "Daycare Center" ||
        this.providerType === "Nursing Home"
      ) {
        console.log("inside if isVldtnDtls function");
        console.log(this.RgstrdBdy, this.rgstNo);
        // if((this.RgstrdBdy==="" && this.rgstNo==="")||(this.RgstrdBdy===undefined  && this.rgstNo===undefined)||(this.RgstrdBdy===null  && this.rgstNo===null)){
        if (
          this.RgstrdBdy === "" ||
          this.rgstNo === "" ||
          this.RgstrdBdy === undefined ||
          this.rgstNo === undefined ||
          this.RgstrdBdy === null ||
          this.rgstNo === null
        ) {
          // if (!v.trim()) return true;
          if (parseInt(this.noOfBeds) < 15) {
            // this.showToast('Provider does not meet minimum bed criteria', this.TOST().WARNING);
            return "Provider does not meet minimum bed criteria";
          }
        }
      }
    },
  },
};
</script>
