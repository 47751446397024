<template>
    <v-app>
        <!-- <div v-if="claimType===!'Reimbursement'">
            <v-card class="mt-2 mainCard rounded-0" elevation='2' style="z-index:1">
                <v-layout class="cardLayout mx-4"  >
                    <v-btn color="#ffffff" width="300px" height="42px" elevation="1" class="mx-2 mt-4 font-weight-bold " >Claims Flags</v-btn>
                    <v-btn color="#ffffff" width="300px" height="42px" elevation="1" class="mx-2 mt-4 font-weight-bold">Claims History</v-btn>
                    <v-btn color="#ffffff" width="300px" height="42px" elevation="1" class="mx-2 mt-4 font-weight-bold">Policy Details</v-btn>
                    <v-btn color="#ffffff" width="150px" height="42px" elevation="1" class="mx-2 mt-4 font-weight-bold">Notes</v-btn>
                    <v-btn color="#ffffff" width="150px" height="42px" elevation="1" class="mx-2 mt-4 font-weight-bold">View BSI</v-btn>
                </v-layout>
                <v-layout class="mt-4 mx-4 cardLayout" >
                    
                    <v-btn color="#ffffff" width="300px" height="42px" elevation="1" class="mx-2 font-weight-bold">Inward Details/Consignment</v-btn>
                    <v-btn color="#ffffff" width="300px" height="42px" elevation="1" class="mx-2 font-weight-bold">Communication Sent</v-btn>
                    <v-btn color="#ffffff" width="300px" height="42px" elevation="1" class="mx-2 font-weight-bold">View Documents</v-btn>
                    <v-btn color="#ffffff" width="318px" height="42px" elevation="1" class="mx-2 font-weight-bold">View Provider Details</v-btn>

                </v-layout>
            </v-card>
            <v-card class="rounded-0 " elevation='1'>
                <v-layout class="pt-3">
                    <v-flex xs2>
                        <v-layout column class=" px-4 py-4 " >
                            <p class="font-weight-black cstm-clr-mainScreen">
                                Policy Number
                            </p>
                            <p class="font-weight-medium fnt-sz--mainScreen">
                                {{claimData.policyNumber | checkData}}
                            </p>
                        </v-layout>
                    </v-flex>

                    <v-flex xs2>
                        <v-layout column class=" px-4 py-4 " >
                            <p class="font-weight-black cstm-clr-mainScreen">
                                Policy Status
                            </p>
                            <p class="font-weight-medium fnt-sz--mainScreen">
                                {{claimData.policyStatus | checkData}}
                            </p>
                        </v-layout>
                    </v-flex>


                    <v-flex xs2>
                        <v-layout column class=" px-4 py-4 " >
                            <p class="font-weight-black cstm-clr-mainScreen">
                                Member ID
                            </p>
                            <p class="font-weight-medium fnt-sz--mainScreen">
                                {{claimData.memberId | checkData}}
                            </p>
                        </v-layout>
                    </v-flex>


                    <v-flex xs2>
                        <v-layout column class=" px-4 py-4 " >
                            <p class="font-weight-black cstm-clr-mainScreen">
                                Policy Start Date
                            </p>
                            <p class="font-weight-medium fnt-sz--mainScreen">
                            {{claimData.policyStartDate | checkDate | checkdata}}
                            </p>
                        </v-layout>
                    </v-flex>


                    <v-flex xs2>
                        <v-layout column class=" px-4 py-4 " >
                            <p class="font-weight-black cstm-clr-mainScreen">
                                Policy End Date
                            </p>
                            <p class="font-weight-medium fnt-sz--mainScreen">
                            {{(claimData.policyEndDate) | checkDate | checkdata}}
                            </p>
                        </v-layout>
                    </v-flex>

                    <v-flex xs2>
                        <v-layout column class=" px-4 py-4 " >
                            <p class="font-weight-black cstm-clr-mainScreen">
                                Product Name
                            </p>
                            <p class="font-weight-medium fnt-sz--mainScreen">
                                {{(claimData.productName)  | checkdata}}
                            </p>
                        </v-layout>
                    </v-flex>

                    <v-flex xs2>
                        <v-layout column class=" px-4 py-4 " >
                            <p class="font-weight-black cstm-clr-mainScreen">
                                Policy Inception date
                            </p>
                            <p class="font-weight-medium fnt-sz--mainScreen">
                                {{(claimData.policyInceptionDate) | checkDate | checkdata}}
                            </p>
                        </v-layout>
                    </v-flex>

                    <v-flex xs2>
                        <v-layout column class=" px-4 py-4 " >
                            <p class="font-weight-black cstm-clr-mainScreen">
                                Restore S
                            </p>
                            <p class="font-weight-medium fnt-sz--mainScreen">
                                {{(claimData.restoreS)  | checkdata}}
                            </p>
                        </v-layout>
                    </v-flex>
                    <v-flex xs2>
                        <v-layout column class=" px-4 py-4 " >
                            <p class="font-weight-black cstm-clr-mainScreen">
                                Claim ID
                            </p>
                            <p class="font-weight-medium fnt-sz--mainScreen">
                                {{(claimData.claimId)  | checkdata}}
                            </p>
                        </v-layout>
                    </v-flex>

                </v-layout>

                <v-divider></v-divider>

                <v-layout class="pt-3">
                    <v-flex xs2>
                        <v-layout column class=" px-4 py-4 " >
                            <p class="font-weight-black cstm-clr-mainScreen">
                                Claim Status
                            </p>
                            <p class="font-weight-medium fnt-sz--mainScreen">
                                {{(claimData.claimStatus)  | checkdata}}
                            </p>
                            </v-layout>
                    </v-flex>

                    <v-flex xs2>
                        <v-layout column class=" px-4 py-4 " >
                            <p class="font-weight-black cstm-clr-mainScreen">
                                Claim Sub-Status
                            </p>
                            <p class="font-weight-medium fnt-sz--mainScreen">
                                {{(claimData.claimSubType)  | checkdata}}
                            </p>
                        </v-layout>
                    </v-flex>

                    <v-flex xs2>
                        <v-layout column class=" px-4 py-4 " >
                            <p class="font-weight-black cstm-clr-mainScreen">
                                Sum Insured
                            </p>
                            <p class="font-weight-medium fnt-sz--mainScreen">
                                <v-icon class="material-icons rupeeIcon" small>mdi-currency-inr</v-icon> {{(claimData.sumInsured)  | checkdata}}
                            </p>
                            </v-layout>
                    </v-flex>

                    <v-flex xs2>
                        <v-layout column class=" px-4 py-4 " >
                            <p class="font-weight-black cstm-clr-mainScreen">
                                Cumulative Bonus
                            </p>
                            <p class="font-weight-medium fnt-sz--mainScreen">
                               <v-icon class="material-icons rupeeIcon" small>mdi-currency-inr</v-icon> {{ (claimData.cumulativeBonus)  | checkdata}}
                            </p>
                        </v-layout>
                    </v-flex>

                    <v-flex xs2>
                        <v-layout column class=" px-4 py-4 " >
                            <p class="font-weight-black cstm-clr-mainScreen">
                                Total SI
                            </p>
                            <p class="font-weight-medium fnt-sz--mainScreen">
                                <v-icon class="material-icons rupeeIcon" small>mdi-currency-inr</v-icon> {{(claimData.totalSI)  | checkdata}}
                            </p>
                            </v-layout>
                    </v-flex>

                    <v-flex xs2>
                        <v-layout column class=" px-4 py-4 " >
                            <p class="font-weight-black cstm-clr-mainScreen">
                                Reserved Amount
                            </p>
                            <p class="font-weight-medium fnt-sz--mainScreen">
                                <v-icon class="material-icons rupeeIcon" small>mdi-currency-inr</v-icon> {{(claimData.reserved)  | checkdata}}
                            </p>
                        </v-layout>
                    </v-flex>

                    <v-flex xs2>
                        <v-layout column class=" px-4 py-4 " >
                            <p class="font-weight-black cstm-clr-mainScreen">
                                Balance SI
                            </p>
                            <p class="font-weight-medium fnt-sz--mainScreen">
                                <v-icon class="material-icons rupeeIcon" small>mdi-currency-inr</v-icon> {{(claimData.balanceSI)  | checkdata}}
                            </p>
                            </v-layout>
                    </v-flex>

                    <v-flex xs2>
                        <v-layout column class=" px-4 py-4 " >
                            <p class="font-weight-black cstm-clr-mainScreen">
                                Corporate Buffer
                            </p>
                            <p class="font-weight-medium fnt-sz--mainScreen">
                                {{(claimData.corporateBuffer)  | checkdata}}
                            </p>
                            </v-layout>
                    </v-flex>
                    <v-flex xs2>
                        
                    </v-flex>

                </v-layout>
            </v-card>
            <v-card class="rounded-0">
                <v-layout>
                    <v-btn-toggle v-model="text" class="toggleBtn" dense mandatory style="background-color:#F7F7F7;opacity:100%;display:flex" >
                        <v-btn class="rounded-0 font-weight-bold text-capitalize" flat>Details of Insured Person Hospitalized</v-btn>
                        <v-btn class="rounded-0 font-weight-bold text-capitalize">Insurance History Details</v-btn>
                        <v-btn class="rounded-0 font-weight-bold text-capitalize">Provider Details</v-btn>
                        <v-btn class="rounded-0 font-weight-bold text-capitalize">Hospitalisation Details</v-btn>
                        <v-btn class="rounded-0 font-weight-bold text-capitalize">Bank Details</v-btn>
                        <v-btn class="rounded-0 font-weight-bold text-capitalize">Invoice Addition Screen</v-btn>
                        <v-btn class="rounded-0 font-weight-bold text-capitalize">Billing Screen</v-btn>
                        <v-btn class="rounded-0 font-weight-bold text-capitalize">Benefit Wise Screen</v-btn>
                    </v-btn-toggle>
                </v-layout>
            </v-card>
        </div> -->
        
        <!-- v-if="claimType==='Cashless'" -->
        <div >
            <v-card class="mt-2 mainCard rounded-0" elevation='2' style="z-index:1">
                <v-layout class="cardLayout mx-4">
                    <v-btn color="#ffffff" width="300px" height="42px" elevation="1" class="mx-2 mt-4 font-weight-bold " >Claim Flags</v-btn>
                    <v-btn color="#ffffff" width="300px" height="42px" elevation="1" class="mx-2 mt-4 font-weight-bold">Claim History</v-btn>
                    <v-btn color="#ffffff" width="300px" height="42px" elevation="1" class="mx-2 mt-4 font-weight-bold" @click="goToSearchPolicy">Policy Details</v-btn>
                    <v-btn color="#ffffff" width="150px" height="42px" elevation="1" class="mx-2 mt-4 font-weight-bold">Notes</v-btn>
                    <v-btn color="#ffffff" width="150px" height="42px" elevation="1" class="mx-2 mt-4 font-weight-bold">View BSI</v-btn>
                </v-layout>
                <v-layout class="mt-4 mx-4 cardLayout" >
                    
                    <v-btn color="#ffffff" width="300px" height="42px" elevation="1" class="mx-2 font-weight-bold">Inward Details/Consignment Details</v-btn>
                    <v-btn color="#ffffff" width="300px" height="42px" elevation="1" class="mx-2 font-weight-bold">Communications Sent</v-btn>
                    <v-btn color="#ffffff" width="300px" height="42px" elevation="1" class="mx-2 font-weight-bold">View Documents</v-btn>
                    <v-btn color="#ffffff" width="318px" height="42px" elevation="1" class="mx-2 font-weight-bold">View Provider Details</v-btn>

                </v-layout>
            </v-card>
            <v-card class="rounded-0 " elevation='1'>
                <v-layout class="pt-3">
                    <v-flex xs3>
                        <v-layout column class=" px-4 py-4 " >
                            <p class="font-weight-black cstm-clr-mainScreen">
                                Policy Number
                            </p>
                            <p class="font-weight-medium fnt-sz--mainScreen">
                                {{claimData.policyNumber | checkData}}
                            </p>
                        </v-layout>
                    </v-flex>

                    <v-flex xs3>
                        <v-layout column class=" px-4 py-4 " >
                            <p class="font-weight-black cstm-clr-mainScreen">
                                Policy Status
                            </p>
                            <p class="font-weight-medium fnt-sz--mainScreen">
                                {{claimData.policyStatus | checkData}}
                            </p>
                        </v-layout>
                    </v-flex>


                    <v-flex xs3>
                        <v-layout column class=" px-4 py-4 " >
                            <p class="font-weight-black cstm-clr-mainScreen">
                                Member ID
                            </p>
                            <p class="font-weight-medium fnt-sz--mainScreen">
                                {{claimData.memberId | checkData}}
                            </p>
                        </v-layout>
                    </v-flex>

                    <v-flex xs3>
                        <v-layout column class=" px-4 py-4 " >
                            <p class="font-weight-black cstm-clr-mainScreen">
                                Member Status
                            </p>
                            <p class="font-weight-medium fnt-sz--mainScreen">
                                {{claimData.memberStatus | checkData}}
                            </p>
                        </v-layout>
                    </v-flex>

                     <v-flex xs3>
                        <v-layout column class=" px-4 py-4 " >
                            <p class="font-weight-black cstm-clr-mainScreen">
                                Policy Inception date
                            </p>
                            <p class="font-weight-medium fnt-sz--mainScreen">
                                {{(claimData.policyInceptionDate) | checkDate | checkdata}}
                            </p>
                        </v-layout>
                    </v-flex>


                    <v-flex xs3>
                        <v-layout column class=" px-4 py-4 " >
                            <p class="font-weight-black cstm-clr-mainScreen">
                               Present Policy Start Date
                            </p>
                            <p class="font-weight-medium fnt-sz--mainScreen">
                            {{claimData.policyStartDate | checkDate | checkdata}}
                            </p>
                        </v-layout>
                    </v-flex>


                    <v-flex xs3>
                        <v-layout column class=" px-4 py-4 " >
                            <p class="font-weight-black cstm-clr-mainScreen">
                              Present Policy End Date
                            </p>
                            <p class="font-weight-medium fnt-sz--mainScreen">
                            {{(claimData.policyEndDate) | checkDate | checkdata}}
                            </p>
                        </v-layout>
                    </v-flex>

                    <v-flex xs3>
                        <v-layout column class=" px-4 py-4 " >
                            <p class="font-weight-black cstm-clr-mainScreen">
                                Product Name
                            </p>
                            <p class="font-weight-medium fnt-sz--mainScreen">
                                {{(claimData.productName)  | checkdata}}
                            </p>
                        </v-layout>
                    </v-flex>

                      <v-flex xs3>
                        <v-layout column class=" px-4 py-4 " >
                            <p class="font-weight-black cstm-clr-mainScreen">
                                Claim Status
                            </p>
                            <p class="font-weight-medium fnt-sz--mainScreen">
                                {{(claimData.claimStatus)  | checkdata}}
                            </p>
                            </v-layout>
                    </v-flex>

                    <v-flex xs3>
                        <v-layout column class=" px-4 py-4 " >
                            <p class="font-weight-black cstm-clr-mainScreen">
                                Claim Sub-Status
                            </p>
                            <p class="font-weight-medium fnt-sz--mainScreen">
                                {{(claimData.claimSubType)  | checkdata}}
                            </p>
                        </v-layout>
                    </v-flex>


                </v-layout>

                <v-divider></v-divider>

                <v-layout class="pt-3">
                  
                    <v-flex xs3>
                        <v-layout column class=" px-4 py-4 " >
                            <p class="font-weight-black cstm-clr-mainScreen">
                                Claim ID
                            </p>
                            <p class="font-weight-medium fnt-sz--mainScreen">
                                {{(claimData.claimId)  | checkdata}}
                            </p>
                        </v-layout>
                    </v-flex>

                    <v-flex xs3>
                        <v-layout column class=" px-4 py-4 " >
                            <p class="font-weight-black cstm-clr-mainScreen">
                                Claim Type
                            </p>
                            <p class="font-weight-medium fnt-sz--mainScreen">
                                {{(claimData.claimType)  | checkdata}}
                            </p>
                        </v-layout>
                    </v-flex>

                    <v-flex xs3>
                        <v-layout column class=" px-4 py-4 " >
                            <p class="font-weight-black cstm-clr-mainScreen">
                                Sum Insured
                            </p>
                            <p class="font-weight-medium fnt-sz--mainScreen">
                                <v-icon class="material-icons rupeeIcon" small>mdi-currency-inr</v-icon> {{(claimData.sumInsured)  | checkdata}}
                            </p>
                            </v-layout>
                    </v-flex>

                    <v-flex xs3>
                        <v-layout column class=" px-4 py-4 " >
                            <p class="font-weight-black cstm-clr-mainScreen">
                                Cumulative Bonus
                            </p>
                            <p class="font-weight-medium fnt-sz--mainScreen">
                               <v-icon class="material-icons rupeeIcon" small>mdi-currency-inr</v-icon> {{ (claimData.cumulativeBonus)  | checkdata}}
                            </p>
                        </v-layout>
                    </v-flex>

                    <v-flex xs3>
                        <v-layout column class=" px-4 py-4 " >
                            <p class="font-weight-black cstm-clr-mainScreen">
                                Total SI
                            </p>
                            <p class="font-weight-medium fnt-sz--mainScreen">
                                <v-icon class="material-icons rupeeIcon" small>mdi-currency-inr</v-icon> {{(claimData.totalSI)  | checkdata}}
                            </p>
                            </v-layout>
                    </v-flex>

                    <v-flex xs3>
                        <v-layout column class=" px-4 py-4 " >
                            <p class="font-weight-black cstm-clr-mainScreen">
                                Restore SI
                            </p>
                            <p class="font-weight-medium fnt-sz--mainScreen">
                                {{(claimData.restoreS)  | checkdata}}
                            </p>
                        </v-layout>
                    </v-flex>

                    <v-flex xs3>
                        <v-layout column class=" px-4 py-4 " >
                            <p class="font-weight-black cstm-clr-mainScreen">
                                Reserved Amount
                            </p>
                            <p class="font-weight-medium fnt-sz--mainScreen">
                                <v-icon class="material-icons rupeeIcon" small>mdi-currency-inr</v-icon> {{(claimData.reserved)  | checkdata}}
                            </p>
                        </v-layout>
                    </v-flex>

                    <v-flex xs3>
                        <v-layout column class=" px-4 py-4 " >
                            <p class="font-weight-black cstm-clr-mainScreen">
                                Balance SI
                            </p>
                            <p class="font-weight-medium fnt-sz--mainScreen">
                                <v-icon class="material-icons rupeeIcon" small>mdi-currency-inr</v-icon> {{(claimData.balanceSI)  | checkdata}}
                            </p>
                            </v-layout>
                    </v-flex>

                    <v-flex xs3>
                        <v-layout column class=" px-4 py-4 " >
                            <p class="font-weight-black cstm-clr-mainScreen">
                                Corporate Buffer
                            </p>
                            <p class="font-weight-medium fnt-sz--mainScreen">
                                {{(claimData.corporateBuffer)  | checkdata}}
                            </p>
                            </v-layout>
                    </v-flex>
                    <v-flex xs3>
                        
                    </v-flex>

                </v-layout>
            </v-card>
            <!-- <v-card class="rounded-0">
                <v-layout>
                    <v-btn-toggle v-model="text" class="toggleBtn2" dense mandatory style="background-color:#F7F7F7;opacity:100%;display:flex" >
                        <v-btn class="rounded-0 font-weight-bold text-capitalize" flat>Member Details</v-btn>
                        <v-btn class="rounded-0 font-weight-bold text-capitalize">Provider Details</v-btn>
                        <v-btn class="rounded-0 font-weight-bold text-capitalize">Patient Details From Hospital</v-btn>
                        <v-btn class="rounded-0 font-weight-bold text-capitalize">Patient Admission Details</v-btn>
                        <v-btn class="rounded-0 font-weight-bold text-capitalize">Invoice Addition Screen</v-btn>
                        <v-btn class="rounded-0 font-weight-bold text-capitalize">Billing Screen</v-btn>
                    </v-btn-toggle>
                </v-layout>
            </v-card> -->
        </div>
        <CashLessTabs v-if="claimType==='Cashless'"></CashLessTabs>
        <ClaimReimbursementTabs v-if="claimType==='Reimbursement'"></ClaimReimbursementTabs>

     </v-app>

</template>

<script>
import moment from 'moment'
import ClaimReimbursementTabs from '../PreAuth/ClaimReimbursementTabs'
import CashLessTabs from '../PreAuth/CashLessTabs'
export default {
    components: {
        CashLessTabs,
        ClaimReimbursementTabs
    },
    created(){
        console.log(this.$route.params);
        
        if(this.$route.params){
            console.log("from route");
            this.claimIdIs = this.$route.params.claimId
            this.claimTypeIs = this.$route.params.claimType
            this.claimType = this.$route.params.claimType
            console.log("id is",this.claimIdIs);
        }

        // setting the selected claim type from store 
        this.claimType =   this.$store.state.currentClaimType ;
        console.log('claim type from store',this.claimType)
        
        console.log(this.$route.params,"this.$route.params" )
         // check for membersearch data
        if (this.$route.params.membSearData) {
            console.log('member search data claims screen',this.$route.params.membSearData)
          //   data which is obtained through membersearch
          let memberSearchData = this.$route.params.membSearData.data.data;
          this.$store.commit("SAVE_CLAIM_MEMBER_DETAILS", memberSearchData);
        }
        if(this.$route.params.type==="reimbursementClaim"){
            this.claimType='Reimbursement'
            this.$store.commit("SAVE_PAGE_HEADER", "Reimbursement Claim registration");

        }else if(this.$route.params.type==="cashlessClaim"){
            this.claimType='Cashless'
            
            this.$store.commit("SAVE_PAGE_HEADER", "Pre Auth new registration");
        }else{
            this.$store.commit("SAVE_PAGE_HEADER", "Claims Main Screen");
        }

         if(this.$route.params.claimType  === 'Reimbursement'){
             console.log("claiamType is reimbusement");
                this.$store.commit("SAVE_PAGE_HEADER", "Claims Main Screen");
            }else{
                console.log("Pre-Auth Main Screen");
                this.$store.commit("SAVE_PAGE_HEADER", "Pre-Auth Main Screen");
            }
        
        this.getCliamData();
        
    },

    data(){
        return{
            claimType : "Cashless",
            claimData:'',
            claimIdIs:'',
            claimTypeIs:''
        }
    },

    methods:{
        getCliamData(){
            console.log("id from route is",this.claimIdIs);
            this.GET(8,'claims-details/get-claim-details?claimId='+this.claimIdIs+'&claimType='+this.claimTypeIs,(res,error) => {
                if(!error){
                    console.log("data from response",res.data.data);
                    this.claimData = res.data.data
                }else{
                    console.log(error);
                }
            });
        },

        goToSearchPolicy(){
            this.$router.push({
                name:'SearchPolicyDetails',
                params:{
                    memberId: this.claimData.memberId,
                    allData: this.claimData
                }
            })
        }
    },

     filters:{

    //   truncate: function (text, length, suffix) {
    //         if (text.length > length) {
    //             return text.substring(0, length) + suffix;
    //         } else {
    //             return text;
    //         }
    //     },
        checkdata(data){// eslint-disable-line no-mixed-spaces-and-tabs
            // console.log('Validity Data', data);
            if (data == "" || data == "undefined" || data == null || data == '' || data == 'Invalid Date') {
                return "-"
            } else {
                return data;
            }
        },
        checkDate(data){
            if (data == "" || data == "undefined" || data == null || data == '' || data == 'Invalid Date') {
                return "-"
            } else {
                return moment(data, 'YYYY-MM-DD').format("DD-MM-YYYY");
            }
        }
    },
}


</script>


<style scoped>
.mainCard{

    background-color: #01BBD9;
    height: 140px;
    width: auto;
}

.cardLayout .v-btn{
    font-size: 1px;
   text-transform:none
}

.cstm-clr-mainScreen{
    color:#10242B !important;
    margin-bottom: 5px !important;
    font-size: 13px !important;
}
.fnt-sz--mainScreen{
    font-size: 13px !important;
}

.toggleBtn .v-btn{
    flex: 1;
    letter-spacing: unset;
    color:black !important;
    font-size: 1px;
    text-transform:none;
    padding: 25px;
    width: 100%;
    height: 80px;
}

.toggleBtn .v-btn--active{
    background-color: #23B1A9 !important;
  color:#FFFFFF !important;
}

.toggleBtn2 .v-btn{
    flex: 1;
    letter-spacing: unset;
    color:black !important;
    font-size: 1px;
    text-transform:none;
    padding: 40px 57px;
    width: 100%;
    /* height: 60px; */

}

.toggleBtn2 .v-btn--active{
    background-color: #23B1A9 !important;
  color:#FFFFFF !important;
}

.rupeeIcon{
    height: 10px;width: 10px;
}
</style>