 <template>
    <v-app>
        
<!-- Basic Plan Details -->

        
            <!-- <v-card  style="border:0.5px solid #C1C8CC;" tile class="mx-3 mt-3 " >
                                
                            <v-layout row class="my-2">
                                <v-layout column justify-start class="ml-5 mx-2 pa-1">
                                    <v-text class=" " style="color:#3C3D3D; font-size:10px !important">Plan Name</v-text>                        
                                    <v-text class="lbl-bgclr font-weight-bold" style="color:#3C3D3D; font-size:14px !important">MEDCA</v-text>
                                        
                                </v-layout>
                                <v-divider  class="pa-0 ma-2" vertical ></v-divider>
                                <v-layout column justify-start class="mx-2 pa-1">                        
                                    <v-text class="" style="color:#3C3D3D; font-size:10px !important">Plan Description</v-text>
                                    <v-text class="lbl-bgclr font-weight-bold" style="color:#3C3D3D; font-size:14px !important">Medicate-Plan A</v-text>
                                        
                                </v-layout>
                                <v-divider  class="ma-2" vertical ></v-divider>
                                <v-layout column justify-start class="mx-2 pa-1">                        
                                    <v-text class="" style="color:#3C3D3D; font-size:10px !important">Plan Effective Date</v-text>    
                                    <v-text class="lbl-bgclr font-weight-bold" style="color:#3C3D3D; font-size:14px !important">1/1/2020</v-text>
                                        
                                </v-layout>
                                <v-divider  class="ma-2" vertical ></v-divider>
                                <v-layout column justify-start class="mx-2 pa-1">                        
                                    <v-text class="" style="color:#3C3D3D; font-size:10px !important">Plan Expiry Date</v-text>    
                                    <v-text class="lbl-bgclr font-weight-bold" style="color:#3C3D3D; font-size:14px !important">1/1/2020</v-text>
                                        
                                </v-layout>
                                <v-divider  class="ma-2" vertical ></v-divider>
                                <v-layout column justify-start class="mx-2 pa-1">                        
                                    <v-text class="" style="color:#3C3D3D; font-size:10px !important">Plan Status</v-text>    
                                    <v-text class="lbl-bgclr font-weight-bold" style="color:#3C3D3D; font-size:14px !important">Active</v-text>
                                        
                                </v-layout>
                                <v-divider  class="ma-2" vertical ></v-divider>
                                <v-layout column justify-start class="mx-2 pa-1">                        
                                    <v-text class="" style="color:#3C3D3D; font-size:10px !important">Policy Type</v-text>
                                    <v-text class="lbl-bgclr font-weight-bold" style="color:#3C3D3D; font-size:14px !important">Retail Health</v-text>       
                                </v-layout>
                                <v-divider  class="ma-2" vertical ></v-divider>
                                <v-layout column justify-start class="mx-2 pa-1">                        
                                    <v-text class="" style="color:#3C3D3D; font-size:10px !important">External Reference No.</v-text>
                                    <v-text class="lbl-bgclr font-weight-bold" style="color:#3C3D3D; font-size:14px !important">__ __ __ __ __ __</v-text>
                                </v-layout>
                            </v-layout>
                        </v-card> -->

                
        




            <v-card tile class="pt-2 mt-4 ma-3">
                <v-form v-model="valid" ref="form">

                <v-layout color="#FFFFFF" class=" mt-1" style="background-color: #FFFFFF;">
                <v-flex xs3 >
                    <v-layout column class="cust_pr mx-2 ">
                        <p class="font-weight-bold cstm-clr-pr">
                            Plan Name
                        </p>
                        <v-text-field
                            solo
                            dense
                            label="Enter..."
                            v-model="basicPlnData.planName"
                            class="rounded-0"
                            :maxlength="max20"
                            counter
                            :rules="[rules.required]"
                            :disabled="inputfldDsbld"
                        >
                        </v-text-field>
                    </v-layout>
                </v-flex>
                    <!-- <v-divider  class="ma-2" vertical ></v-divider> -->

                <v-flex xs3 >
                    <v-layout column class="cust_pr mx-2">
                        <p class="font-weight-bold cstm-clr-pr">
                            Plan Description
                        </p>
                        <v-text-field
                            solo
                            dense
                            label="Enter..."
                            v-model="basicPlnData.planDescription"
                            class="rounded-0"
                            :maxlength="max50"
                            counter
                            :rules="[required(),spclChar(),minLength(3)]"
                            :disabled="inputfldDsbld"
                        >
                        </v-text-field>
                    </v-layout>
                </v-flex>
                    <!-- <v-divider  class="ma-2" vertical ></v-divider> -->

                <v-flex xs3 >
                    <v-layout column class=" mx-2 cust_pr">
                        <p class="font-weight-bold cstm-clr-pr">
                            Plan Effective Date
                        </p>
                        <v-menu
                            ref="calender"
                            :close-on-content-click="true"
                            v-model="basicPlnData.planEffectiveDatecalender"
                            :nudge-right="40"
                           
                            transition="scale-transition"
                            
                            max-width="290px"
                            min-width="290px" 
                              class="rounded-0"
                              :disabled="inputfldDsbld"
                            >
                            <template v-slot:activator="{ on, attrs }">
                                <!-- :rules="[rules.required]" -->
                                <v-text-field
                                    slot="activator"
                                    label="Select.."
                                    :value="cmputdpdtEfftvDteFrmMmntjs"
                                    solo
                                     class="rounded-0"
                                    
                                    :rules="[required(),syncdateCheck()]"
                                    v-bind="attrs"
                                    v-on="on"
                                    readonly
                                ></v-text-field>
                            </template>
                                                        
                                    <v-date-picker
                                        v-model="basicPlnData.planEffectiveDate"
                                        no-title
                                        
                                        
                                    ></v-date-picker>
                        </v-menu>
                    </v-layout>
                </v-flex>
                <v-flex xs3 >
                    <v-layout column class=" mx-2 cust_pr">
                        <p class="font-weight-bold cstm-clr-pr">
                            Plan Expiry Date
                        </p>
                        <v-menu
                            ref="calender"
                            :close-on-content-click="true"
                            v-model="basicPlnData.planExpireDatecalender"
                            :nudge-right="40"
                           
                            transition="scale-transition"
                            
                            max-width="290px"
                            min-width="290px" 
                              class="rounded-0"
                              :disabled="inputfldDsbld"
                            >
                            <!-- :rules="[rules.required]" -->
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    slot="activator"
                                    label="Select.."
                                    :value="cmputdpdtEfftvDteToMmntjs"
                                    solo
                                     class="rounded-0"
                                    
                                     :rules="[required(),dateCheck()]"

                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                                                        
                                    <v-date-picker
                                        v-model="basicPlnData.planExpireDate"
                                        no-title
                                        @change="validDateChk" 
                                    ></v-date-picker>
                        </v-menu>
                    </v-layout>
                </v-flex>

                 <v-flex xs3 >
                    <v-layout column class=" custBscsPln mx-2 cust_pr">
                        <p class="font-weight-bold cstm-clr-pr">
                            Plan Status
                        </p>
                        
                        <v-select
                            :items="selectPlanStatus"
                            v-model="basicPlnData.planStatus"
                            label="Select"
                            dense
                            solo
                            disabled
                            class="rounded-0"
                        ></v-select>
                    </v-layout>
                </v-flex>
                <v-flex xs3>
                    <v-layout column class=" mx-2  cust_pr">
                        <p class="font-weight-bold cstm-clr-pr">
                            Policy Type
                        </p>
                        <!-- @change="selectdPolicyType" -->
                        <v-select
                            :items="selectPolicyType"
                            item-text="productType"
                            item-value="_id"
                            v-model="basicPlnData.policyType"
                            label="Select"
                            :disabled="inputfldDsbld"
                            return-object
                            dense
                            solo
                            :rules="[rules.required]"
                            class="rounded-0"
                        ></v-select>
                        
                    </v-layout>
                </v-flex>
                <v-flex xs3>
                    <v-layout column class=" mx-2  cust_pr">
                        <p class="font-weight-bold cstm-clr-pr">
                            External Reference No.
                        </p>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="basicPlnData.externalRefNo"
                             class="rounded-0"
                              :maxlength="max30"
                            counter
                             :rules="[required(),spclChar()]"
                             :disabled="inputfldDsbld"
                             >
                        </v-text-field>
                    </v-layout>
                </v-flex>
                </v-layout>
                </v-form>
            </v-card>

            <v-card class="mb-2 mx-3 mt-3 cust-bnkAcc-tbl" style="border:0.5px solid #C1C8CC;" tile>
				<v-layout  style="padding:15px;background-color:#F7F7F7;align-items:center; justify-center; justify-content: flex-end;">
                   <v-btn :dark="!inputfldDsbld" @click="clear()" :disabled="inputfldDsbld" tile style="background-color:#D1121B" class="text-capitalize px-3 mr-3 mx-2" small><v-icon dark small>delete</v-icon> <v-divider vertical class="mx-2"></v-divider>Clear</v-btn>
                    <v-btn :dark="!inputfldDsbld" @click="savePlan()" :disabled="inputfldDsbld" tile style="background-color:#23B1A9"  class="text-capitalize px-3 mx-2" small><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider> Save</v-btn>
                    <v-btn  :dark="!nextbtn" tile style="background-color:#152F38"  @click="nextTab()"  :disabled="nextbtn" class="text-capitalize px-3 mx-2" small><v-icon dark small>arrow_forward</v-icon> <v-divider white vertical class="mx-2"></v-divider>Next</v-btn>

				</v-layout>
            </v-card>
        
                    <!-- <RelationshipDependentInfo v-if="rdiShw===true"></RelationshipDependentInfo> -->


    </v-app>
</template>
<script>
import axios from "axios";
import moment from 'moment';
import Vuelidate from 'vuelidate'
// import RelationshipDependentInfo from './RelationshipDependentInfo.vue'



export default {

    components:{
        // RelationshipDependentInfo,
      
        // PrvdrEmpnlmntFacilities,
    },

    created() {

        this.$store.commit("SAVE_PAGE_HEADER", "Basic Plan Details");
        this.token=this.$store.getters.successToken
        
        console.log(this.$store.getters.fetchProductCodeData,"Product Id")
        console.log(this.$store.getters.fetchPdtDate,"fetchPdtDate")
        console.log("Policy ID flow comng from",this.$store.getters.fetchPlcyIdForPlnData)
        this.btnName=this.$store.getters.fetchPlcyIdForPlnData.text
        console.log(this.btnName,"this.btnName")
        if(this.btnName==="EditPlnFrPlcy"||this.btnName==="ConfgrPlnFrPlcy"){
            this.nextbtn=false
            this.getGroupPlanbyId(this.$store.getters.fetchPlcyIdForPlnData.plan_No)
        }

        if(this.btnName==="EditNwRtlPln"||this.btnName==="CnfgrNwRtlPln"){
            this.nextbtn=false
            this.getRetailPlan(this.$store.getters.fetchPlcyIdForPlnData.retailPlan_Id)
        }

        if(this.btnName==="ConfgrPlnFrPlcy"||this.btnName==="CnfgrNwRtlPln"){
            this.inputfldDsbld=true
        }

    },

    computed: {
         cmputdpdtEfftvDteFrmMmntjs(){
            return this.basicPlnData.planEffectiveDate ? moment(this.basicPlnData.planEffectiveDate).format("DD-MM-YYYY"): ''
        },
         cmputdpdtEfftvDteToMmntjs(){
            return this.basicPlnData.planExpireDate ? moment(this.basicPlnData.planExpireDate).format("DD-MM-YYYY"): ''
        },
        validDateChk(){
            
            // let fromeDate = moment(this.productEffectFromdate,'YYYY-MM-DD').format('DD-MM-YYYY')
            // let todayDate = Date.now();
            // let Todate= moment(this.basicPlnData.planExpireDate,'YYYY-MM-DD ').format('DD-MM-YYYY')
            // let CurrentDate= moment(todayDate).format("DD-MM-YYYY");
            // console.log(Todate,CurrentDate,"Todate,CurrentDate")

            //  let fromeDate = moment(this.crtEdtPdt.pdtEfftvDteFrm,'YYYY-MM-DD').toDate().getTime()
            let todayDate = Date.now();
            let Todate= moment(this.basicPlnData.planExpireDate,'YYYY-MM-DD ').toDate().getTime()
            console.log(todayDate,"todayDate")
            let CurrentDate= todayDate

            if(Todate < CurrentDate){
                return this.basicPlnData.planStatus="Inactive"
            }else{
                return this.basicPlnData.planStatus="Active"
            }
        }

    },

    data() {
        return {
          btnName:'',
          inputfldDsbld:false,
          nextbtn:true,
          valid:false,
          token:null,
          basicPlnData:{
                planName:"",
                planDescription:"",
                planEffectiveDate:"",
                planEffectiveDatecalender:false,
                planExpireDate:"",
                planExpireDatecalender:false,
                
                policyType:"",
                planStatus:"Active",
                externalRefNo:""
            },

            selectPlanStatus:['Active','Inactive'],
            selectPolicyType:['Floater','Non-Floater'],

            rules: {
                required: value => !!value || 'This Field is Required.',
                // minRegNo: v =>v.length <= 3|| v.length == 5  || 'Enter valid Registration Number',
                minExtRefNo: value => {
                            // const pattern = /^([0-9])+$/
                        const pattern = /^[a-zA-Z0-9]+$/
                        return pattern.test(value) || 'This Field is Required.'
                },

                minROHINI: v =>v.length <= 3|| v.length == 13 || 'Invalid ROHINI ID',
                   
            },
            required(){
            return v=>v && v.length>0 || "This Field is Required"
            },
            minLength(minLength){
                return v=>v && v.length>=minLength || `Must be atleast ${minLength} Characters`
            },
            maxLength(maxLength){
                return v=>v && v.length<=maxLength || `Must be less than ${maxLength} Characters`
            },
            spclChar(){
                    return v=>/^[A-Za-z0-9 ]+$/.test(v) || `Special Characters Not Allowed`
            },
            dateCheck(){
                // let todayDate = Date.now();
                let fromDate=moment(this.basicPlnData.planEffectiveDate,'YYYY-MM-DD ').toDate().getTime()
                let Todate= moment(this.basicPlnData.planExpireDate,'YYYY-MM-DD ').toDate().getTime()
                // console.log(todayDate,"todayDate")
                // let CurrentDate= todayDate
                if(Todate < fromDate){
                    // this.showToast("The Product 'Effective date From' can not be greater than Product 'Effective date To'.",this.TOST().WARNING)
                    return "Cannot be less than 'Plan Effective From'"
                }
                
            },
            syncdateCheck(){
                let fromDate=moment(this.basicPlnData.planEffectiveDate,'YYYY-MM-DD ').toDate().getTime()
                let pdtFromDate=""
                if(this.btnName==="EditPlnFrPlcy"||this.btnName==="CrteNwPlnFrPlcy"){
                    console.log("if bracket")
                    pdtFromDate=moment(this.$store.getters.fetchPdtDate.policyFromDate,'YYYY-MM-DD ').toDate().getTime()
                }else if(this.btnName==="CrteNwRtlPln"||this.btnName==="EditNwRtlPln"){
                    console.log("else if bracket")
                    pdtFromDate=moment(this.$store.getters.fetchPdtDate.fromDate,'YYYY-MM-DD ').toDate().getTime()
                }
                
                if(pdtFromDate > fromDate){
                    if(this.btnName==="EditPlnFrPlcy"||this.btnName==="CrteNwPlnFrPlcy"){
                        return "'Plan Effective' and 'Policy Effective' date are not in sync"
                    }else if(this.btnName==="CrteNwRtlPln"||this.btnName==="EditNwRtlPln"){
                        return "'Plan Effective' and 'Product Effective From' date are not in sync"
                    }
                }

            },
            max10:10,
            max20:20,
            max30:30,
            max40:40,
            max50:50,
            


        }
    },

    methods: {

        nextTab(){
            console.log("emitted")
             this.$emit('next');
        },
       clear(){
            if((this.basicPlnData.planName===''||this.basicPlnData.planName===undefined||this.basicPlnData.planName===null) 
            && (this.basicPlnData.planDescription===''||this.basicPlnData.planDescription===undefined||this.basicPlnData.planDescription===null) 
            && (this.basicPlnData.planEffectiveDate===''||this.basicPlnData.planEffectiveDate===undefined||this.basicPlnData.planEffectiveDate===null) 
            && (this.basicPlnData.planExpireDate===''||this.basicPlnData.planExpireDate===undefined||this.basicPlnData.planExpireDate===null)
            && (this.basicPlnData.policyType===''||this.basicPlnData.policyType===undefined||this.basicPlnData.policyType===null) 
            && (this.basicPlnData.planStatus===''||this.basicPlnData.planStatus===undefined||this.basicPlnData.planStatus===null)
            && (this.basicPlnData.externalRefNo===''||this.basicPlnData.externalRefNo===undefined||this.basicPlnData.externalRefNo===null)
            && (this.basicPlnData.planEffectiveDatecalender===''||this.basicPlnData.planEffectiveDatecalender===undefined||this.basicPlnData.planEffectiveDatecalender===null)
            && (this.basicPlnData.planExpireDatecalender===''||this.basicPlnData.planExpireDatecalender===undefined||this.basicPlnData.planExpireDatecalender===null) 
            
            ){
                
                return
            }else{
                console.log('cancel basic plan data',)
                this.basicPlnData.planName=''
                this.basicPlnData.planDescription=''
                this.basicPlnData.planEffectiveDate=''
                this.basicPlnData.planExpireDate=''
                this.basicPlnData.policyType=''
                this.basicPlnData.planStatus=''
                this.basicPlnData.externalRefNo=''
                this.basicPlnData.planEffectiveDatecalender=''
                this.basicPlnData.planExpireDatecalender=''

                this.$refs.form.resetValidation()
            }
        },
    
    savePlan(){

                let fromDate=moment(this.basicPlnData.planEffectiveDate,'YYYY-MM-DD ').toDate().getTime()
                let pdtFromDate=""
                if(this.btnName==="EditPlnFrPlcy"||this.btnName==="CrteNwPlnFrPlcy"){
                    console.log("if bracket")
                    pdtFromDate=moment(this.$store.getters.fetchPdtDate.policyFromDate,'YYYY-MM-DD ').toDate().getTime()
                }else if(this.btnName==="CrteNwRtlPln"||this.btnName==="EditNwRtlPln"){
                    console.log("else if bracket")
                    pdtFromDate=moment(this.$store.getters.fetchPdtDate.fromDate,'YYYY-MM-DD ').toDate().getTime()
                }
                
                if(pdtFromDate > fromDate){
                    if(this.btnName==="EditPlnFrPlcy"||this.btnName==="CrteNwPlnFrPlcy"){
                        return this.showToast("'Plan Effective' and 'Policy Effective' date are not in sync",this.TOST().WARNING);
                    }else if(this.btnName==="CrteNwRtlPln"||this.btnName==="EditNwRtlPln"){
                        return this.showToast("'Plan Effective' and 'Product Effective From' date are not in sync",this.TOST().WARNING);
                    }
                }

        if(this.btnName==="EditPlnFrPlcy"){
            console.log("if")
            this.editGrpPlanById()
        }else if(this.btnName==="CrteNwPlnFrPlcy"){
            console.log("else")
            this.crteGrpPlan();
        }else if(this.btnName==="CrteNwRtlPln"){
            this.createRetailPlan()
        }else if(this.btnName==="EditNwRtlPln"){
            this.UpdteRtlPlnById()
        }

    },


    crteGrpPlan(){
            
        if (this.$refs.form.validate()){
             let self=this
            var formData ={
                planName:self.basicPlnData.planName,
                planDescription:self.basicPlnData.planDescription,
                planEffectiveDate:self.basicPlnData.planEffectiveDate,
                planExpiryDate:self.basicPlnData.planExpireDate,
                planstatus:self.basicPlnData.planStatus,
                policyType:self.basicPlnData.policyType,
                policyNumber:self.$store.getters.fetchPlcyIdForPlnData.plcy_No,
                policyId:self.$store.getters.fetchPlcyIdForPlnData.plcy_id,
                externalRefNo:self.basicPlnData.externalRefNo
            }

            // this.nextbtn=false
            console.log('Basic Plan form saved data',formData)
            axios({
                method: "post",
                url:
                    self.API_Service_admin()+"group-plan/create-group-plan",
                headers: {
                    "Content-Type": "application/json",
                    "x-access-token": self.token
                    // "x-access-token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI1ZmJlMDg4ZGM0NmRhODNlZTdmODcxYmIiLCJyb2xlIjoiQWRtaW5Vc2VyIiwiaWF0IjoxNjA2MzY5MzI0LCJleHAiOjE2Mzc5MDUzMjR9.iS5hZs0uImNFz-rxNaQ7CTCc6qS_hG4H0FsePZ5Sgqc",
                },
                data:formData,
                })
                .then(function (response) {
                    console.log(" Create Policy for product API Response>>>>>>", response);
                    self.showToast(response.data.msg ,self.TOST().SUCCESS)
                    self.nextbtn=false
                    self.btnName==="EditPlnFrPlcy"

                })
                .catch(function (error) {
                    console.log(" Create Policy for product Error APi Response>>>>>>", error.response.data);
                    self.showToast(error.response.data.msg,self.TOST().ERROR);
                    self.nextbtn=true
                });
        }else{
            this.$refs.form.validate()
            this.showToast("Please Fill Required Fields",this.TOST().WARNING);
        }
    },

    createRetailPlan(){
            
        if (this.$refs.form.validate()){
             let self=this
            var formData ={
                planName:self.basicPlnData.planName,
                planDescription:self.basicPlnData.planDescription,
                planEffectiveDate:self.basicPlnData.planEffectiveDate,
                planExpiryDate:self.basicPlnData.planExpireDate,
                planstatus:self.basicPlnData.planStatus,
                policyType:self.basicPlnData.policyType,
                productCode:self.$store.getters.fetchPlcyIdForPlnData.retailPlan_productCode,
                productDescription:self.$store.getters.fetchPlcyIdForPlnData.retailPlan_productDescription,
                productId:self.$store.getters.fetchPlcyIdForPlnData.retailPlan_productId,
                externalRefNo:self.basicPlnData.externalRefNo
            }

            // this.nextbtn=false
            console.log('Basic Plan form saved data',formData)
            axios({
                method: "post",
                url:
                    self.API_Service_admin()+"retail-plan/create-retail-plan",
                headers: {
                    "Content-Type": "application/json",
                    "x-access-token": self.token
                    // "x-access-token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI1ZmJlMDg4ZGM0NmRhODNlZTdmODcxYmIiLCJyb2xlIjoiQWRtaW5Vc2VyIiwiaWF0IjoxNjA2MzY5MzI0LCJleHAiOjE2Mzc5MDUzMjR9.iS5hZs0uImNFz-rxNaQ7CTCc6qS_hG4H0FsePZ5Sgqc",
                },
                data:formData,
                })
                .then(function (response) {
                    console.log(" Create Policy for product API Response>>>>>>", response);
                    self.showToast(response.data.msg ,self.TOST().SUCCESS)
                    self.nextbtn=false
                    self.btnName==="EditNwRtlPln"

                })
                .catch(function (error) {
                    console.log(" Create Policy for product Error APi Response>>>>>>", error.response.data);
                    self.showToast(error.response.data.msg,self.TOST().ERROR);
                    self.nextbtn=true
                });
        }else{
            this.$refs.form.validate()
            this.showToast("Please Fill Required Fields",this.TOST().WARNING);
        }
    },

    editGrpPlanById(){
            
        if (this.$refs.form.validate()){
             let self=this
            var formData ={
                planName:self.basicPlnData.planName,
                planDescription:self.basicPlnData.planDescription,
                planEffectiveDate:self.basicPlnData.planEffectiveDate,
                planExpiryDate:self.basicPlnData.planExpireDate,
                planstatus:self.basicPlnData.planStatus,
                policyType:self.basicPlnData.policyType,
                policyNumber:self.$store.getters.fetchPlcyIdForPlnData.plcy_No,
                policyId:self.$store.getters.fetchPlcyIdForPlnData.plcy_id,
                externalRefNo:self.basicPlnData.externalRefNo
            }

            // this.nextbtn=false
            console.log('Basic Plan form saved data',formData)
            axios({
                method: "put",
                url:
                    self.API_Service_admin()+"group-plan/update-group-plan/"+self.$store.getters.fetchPlcyIdForPlnData.plan_No,
                headers: {
                    "Content-Type": "application/json",
                    "x-access-token": self.token
                    // "x-access-token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI1ZmJlMDg4ZGM0NmRhODNlZTdmODcxYmIiLCJyb2xlIjoiQWRtaW5Vc2VyIiwiaWF0IjoxNjA2MzY5MzI0LCJleHAiOjE2Mzc5MDUzMjR9.iS5hZs0uImNFz-rxNaQ7CTCc6qS_hG4H0FsePZ5Sgqc",
                },
                data:formData,
                })
                .then(function (response) {
                    console.log(" Create Policy for product API Response>>>>>>", response);
                    self.showToast(response.data.msg ,self.TOST().SUCCESS)

                })
                .catch(function (error) {
                    console.log(" Create Policy for product Error APi Response>>>>>>", error);
                    self.showToast(error.response.data.msg,self.TOST().ERROR);
                });
        }else{
            this.$refs.form.validate()
            this.showToast("Please Fill Required Fields",this.TOST().WARNING);
        }
    },

    UpdteRtlPlnById(){
            
        if (this.$refs.form.validate()){
             let self=this
            var formData ={
                planName:self.basicPlnData.planName,
                planDescription:self.basicPlnData.planDescription,
                planEffectiveDate:self.basicPlnData.planEffectiveDate,
                planExpiryDate:self.basicPlnData.planExpireDate,
                planstatus:self.basicPlnData.planStatus,
                policyType:self.basicPlnData.policyType,
                productCode:self.$store.getters.fetchPlcyIdForPlnData.retailPlan_productCode,
                productDescription:self.$store.getters.fetchPlcyIdForPlnData.retailPlan_productDescription,
                productId:self.$store.getters.fetchPlcyIdForPlnData.retailPlan_productId,
                externalRefNo:self.basicPlnData.externalRefNo
            }

            // this.nextbtn=false
            console.log('Basic Plan form saved data',formData)
            axios({
                method: "put",
                url:
                    self.API_Service_admin()+"retail-plan/update-retail-plan/"+self.$store.getters.fetchPlcyIdForPlnData.retailPlan_Id,
                headers: {
                    "Content-Type": "application/json",
                    "x-access-token": self.token
                    // "x-access-token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI1ZmJlMDg4ZGM0NmRhODNlZTdmODcxYmIiLCJyb2xlIjoiQWRtaW5Vc2VyIiwiaWF0IjoxNjA2MzY5MzI0LCJleHAiOjE2Mzc5MDUzMjR9.iS5hZs0uImNFz-rxNaQ7CTCc6qS_hG4H0FsePZ5Sgqc",
                },
                data:formData,
                })
                .then(function (response) {
                    console.log(" Create Policy for product API Response>>>>>>", response);
                    self.showToast(response.data.msg ,self.TOST().SUCCESS)

                })
                .catch(function (error) {
                    console.log(" Create Policy for product Error APi Response>>>>>>", error);
                    self.showToast(error.response.data.msg,self.TOST().ERROR);
                });
        }else{
            this.$refs.form.validate()
            this.showToast("Please Fill Required Fields",this.TOST().WARNING);
        }
    },

    getGroupPlanbyId(planId){
        console.log(planId," Incoming PlanId")
        let self = this;   
        axios({
            method: "get",
            url:self.API_Service_admin()+"group-plan/get-group-plan/"+planId, 
            headers: {
                "Content-Type": "application/json", 
                "x-access-token": self.token,
            }
        })
        .then(function(response) {
            console.log('group-plan/get-group-plan Response------->>>',response.data.data);
            let pdtDta={}
            pdtDta=response.data.data
          
            self.basicPlnData.planName=pdtDta.hasOwnProperty('planName')?self.chkplcydata(pdtDta.planName):""
            self.basicPlnData.planDescription=pdtDta.hasOwnProperty('planDescription')?self.chkplcydata(pdtDta.planDescription):""
            self.basicPlnData.planEffectiveDate=pdtDta.hasOwnProperty('planEffectiveDate')?self.chkplcydata(moment(pdtDta.planEffectiveDate,'YYYY-MM-DD').format('YYYY-MM-DD')):""
            self.basicPlnData.planExpireDate=pdtDta.hasOwnProperty('planExpiryDate')?self.chkplcydata(moment(pdtDta.planExpiryDate,'YYYY-MM-DD').format('YYYY-MM-DD')):""
            self.basicPlnData.policyType=pdtDta.hasOwnProperty('policyType')?self.chkplcydata(pdtDta.policyType):""
            self.basicPlnData.externalRefNo=pdtDta.hasOwnProperty('externalRefNo')?self.chkplcydata(pdtDta.externalRefNo):""
            // self.crtEdtPdt.pdtDsc=pdtDta.hasOwnProperty('productDescription')?self.chkplcydata(pdtDta.productDescription):""
            // self.crtEdtPdt.plcyEfftvDte= pdtDta.hasOwnProperty('productEffectiveFrom')?self.chkplcydata(pdtDta.productEffectiveFrom):""
            // self.crtEdtPdt.plcyExpryDte=pdtDta.hasOwnProperty('productEffectiveTo')?self.chkplcydata(pdtDta.productEffectiveTo):""
        })
        .catch(function(error) {
            console.log(error);
            // self.showToast(error.response.data.msg,self.TOST().ERROR);
        });
    },

    getRetailPlan(planId){
        console.log(planId," Incoming PlanId")
        let self = this;   
        axios({
            method: "get",
            url:self.API_Service_admin()+"retail-plan/get-retail-plan/"+planId, 
            headers: {
                "Content-Type": "application/json", 
                "x-access-token": self.token,
            }
        })
        .then(function(response) {
            console.log('group-plan/get-group-plan Response------->>>',response.data.data);
            let pdtDta={}
            pdtDta=response.data.data
          
            self.basicPlnData.planName=pdtDta.hasOwnProperty('planName')?self.chkplcydata(pdtDta.planName):""
            self.basicPlnData.planDescription=pdtDta.hasOwnProperty('planDescription')?self.chkplcydata(pdtDta.planDescription):""
            self.basicPlnData.planEffectiveDate=pdtDta.hasOwnProperty('planEffectiveDate')?self.chkplcydata(pdtDta.planEffectiveDate):""
            self.basicPlnData.planExpireDate=pdtDta.hasOwnProperty('planExpiryDate')?self.chkplcydata(pdtDta.planExpiryDate):""
            self.basicPlnData.policyType=pdtDta.hasOwnProperty('policyType')?self.chkplcydata(pdtDta.policyType):""
            self.basicPlnData.externalRefNo=pdtDta.hasOwnProperty('externalRefNo')?self.chkplcydata(pdtDta.externalRefNo):""
            // self.crtEdtPdt.pdtDsc=pdtDta.hasOwnProperty('productDescription')?self.chkplcydata(pdtDta.productDescription):""
            // self.crtEdtPdt.plcyEfftvDte= pdtDta.hasOwnProperty('productEffectiveFrom')?self.chkplcydata(pdtDta.productEffectiveFrom):""
            // self.crtEdtPdt.plcyExpryDte=pdtDta.hasOwnProperty('productEffectiveTo')?self.chkplcydata(pdtDta.productEffectiveTo):""
        })
        .catch(function(error) {
            console.log(error);
            // self.showToast(error.response.data.msg,self.TOST().ERROR);
        });
    },

    chkplcydata(data){
            console.log("Policy sara",data); 
            try{
                if (data ==="" || data === "undefined" || data === null) {
                    return ""
                } else {
                    return data;
                }

             }catch(err) {
                console.log(err)
            }
            
        },


    },

    watch: {
    
    },
    
    
}

</script>
<style >



.activeredbackground{
  color:white;
}

.v-btn__content { width: 100% !important; white-space: normal !important; font-size:13px !important}

.srSelected{
  background-color: #23B1A9 !important;
  color:#FFFFFF !important;
}

.custBscsPln .theme--light.v-text-field--solo > .v-input__control > .v-input__slot {
    background: #F7F7F7;
}


</style>