<template>
    
    <div>
        <!-- <pre>
            headers
            {{headersNew}}
        </pre>
        <hr>
         <pre>
            rowarray
            {{rowarray}}
        </pre> -->
        
               <v-card tile style="overflow-x: scroll;">
                    <fieldset style="border:none !important" :disabled="fromview">
                    <div v-if="tabledata.req" style="display:flex">
                        <p  style="border-left:1px solid #C1C8CC; min-width:67px" class="backColorRn pa-0 ma-0 px-2 py-1">Sr. No.</p>
                        <p  :style="{minWidth: index+1 == headersNew.length  ? '100px' : '250px',borderLeft:'1px solid #C1C8CC',}"  style="flex:1" class="backColorRn pa-0 ma-0 px-3 py-1" row v-for="(j,index) in headersNew" :key="index" >{{j.displayval}}</p>
                    </div>

                    <div v-else style="display:flex">
                        <p style="border-left:1px solid #C1C8CC; min-width:67px" class="backColorRn pa-0 ma-0 px-2 py-1">Sr. No.</p>
                        <p :style="{minWidth: index+1 == headersNew.length ? '250px' : '250px', borderLeft:'1px solid #C1C8CC',}"  class="backColorRn pa-0 ma-0 px-3 py-1" row v-for="(j,index) in headersNew" :key="index" >{{j.displayval}}</p>
                    </div>

                    <tbody>
                        <tr style="border:1px #01b4bb solid;"  v-for="(h,index) in rowarray" :key="index">
                            <td v-for="(j,hindx) in headersNew" :key="hindx">
                            <div class="normalrowis">
                                  
                           <div class="normalrowis"  >
                                   <p  v-if="hindx == 0"
                                    :style="{minWidth: tabledata.radio ? '0px':'67px' , textAlign:'center',borderLeft:'1px solid #C1C8CC',fontSize:'13px',borderBottom:'1px solid #C1C8CC'}"
                                    class="pa-0 ma-0 px-2 pt-5 font-weight-bold" >{{index+1}}</p>

                                    <div  @click="rbtn(index, h)" style="border-bottom:1px solid #C1C8CC;"  v-if="tabledata.radio && hindx == 0" >
                                        <!-- <p 
                                    style="font-size:13px;"
                                    class=" font-weight-bold" >  {{index+1}}</p> -->
                                    <v-radio-group
                                    v-model="radiodata"
                                    :style="{ visibility: h.hasOwnProperty('_id') ? 'visible':'hidden'  }"
                                    style="padding: 0px 5px;"
                                    :disabled="fromview"
                                    >
                                        <v-radio
                                        
                                        :key="index"
                                        :value="h"
                                        ></v-radio>
                                    </v-radio-group>
                                    </div>
                           </div>
                            
                            <div v-if="hindx <1" @click="setlevelfour(h,index, j)" >
                                <!-- <pre> -->
                                    <!-- {{rowarray[index][j.text]}} -->
                                    <!-- {{h[j.text]}} {{j.text}} -->
                                <!-- </pre> -->
                                <!-- v-model="rowarray[index][j.text]" -->
                                <!-- 
                                <v-text-field   
                                label="Enter..."
                                class="rounded-0"
                                solo
                                v-model="h[j.text]"
                                hide-details="auto"
                                :maxlength="max10"
                                :rules="[required()]">
                            </v-text-field> -->
                            <!-- <span>{{hindx}}</span> -->
                            <!-- drop_down_vals -->
                              <!-- :items="[{_id:'121121',level4:'myvalueone'},{_id:'121343',level4:'myvalue2'}]" -->
                              <!-- hide-details="auto" -->
                                <!-- <v-select
                                <v-select
                                   style="font-size: 14px"
                                   :items="drop_down_vals"
                                    label="Select"
                                    class="rounded-0 "
                                    solo
                                    v-model="h[j.text]"
                                    item-value="_id"
                                    item-text="level4"
                                    return-object
                                   :rules="rules.select">
                                </v-select> -->
                                <!-- :rules="[required(),]"> -->

                              <v-tooltip left :disabled="h[j.text].length ===0">
                             <template v-slot:activator="{ on, attrs }">
                            <div v-if="hindx<1" 
                                v-bind="attrs"
                                v-on="on"
                                style="min-width:250px; max-width:250px; 
                                       border-left:1px solid #C1C8CC;
                                       border-bottom:1px solid #C1C8CC;
                                       border-right:1px solid #C1C8CC;" 
                                class="cust_pln_RmNrsng px-2 pt-2 "
                            >
                                <!-- <pre> -->
                                    <!-- {{rowarray[index][j.text]}} -->
                                    <!-- {{h[j.text]}} {{j.text}} -->
                                <!-- </pre> -->
                                <!-- v-model="rowarray[index][j.text]" -->
                                <!-- 
                                <v-text-field   
                                label="Enter..."
                                class="rounded-0"
                                solo
                                v-model="h[j.text]"
                                hide-details="auto"
                                :maxlength="max10"
                                :rules="[required()]">
                            </v-text-field> -->
                            <!-- <span>{{hindx}}</span> -->
                            <!-- drop_down_vals -->
                              <!-- :items="[{_id:'121121',level4:'myvalueone'},{_id:'121343',level4:'myvalue2'}]" -->
                              <!-- hide-details="auto" -->
<!--                             
                                    <v-select
                                    :items="drop_down_vals"
                                        label="Select"
                                        class="rounded-0 "
                                        solo
                                        v-model="h[j.text]"
                                        item-value="_id"
                                        item-text="level4"
                                        return-object
                                    :rules="rules.select"   
                                    >
                                     :style="{cursor: fromview===true ? 'none !important' : 'pointer !important'}" -->
                            <v-text-field   
                                label="Enter..."
                                class="rounded-0"
                                solo
                                v-model="h[j.text].level4"
                                :rules="rules.select"
                                :disabled="fromview"
                            >
                            <div slot="append"
                            style="background-color:#F7F7F7;padding:7.2px;cursor:pointer;position:absolute;top:0px;right:0px"
                           
                            ><v-icon :disabled="fromview">search</v-icon></div>
                            </v-text-field>
                                   <!-- <template v-slot:item="{ active, item, attrs, on }">
                                        <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                                            <v-list-item-action>
                                            <v-checkbox :input-value="active"></v-checkbox>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    <v-tooltip right >
                                                        <template v-slot:activator="{ on }">
                                                            <div v-on="on" >
                                                                {{ item.level4}}
                                                            </div>
                                                        </template>
                                                        <span>{{item.level4 }}</span>
                                                    </v-tooltip>
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template> -->
                                    <!-- </v-select> -->
                               
                            </div>
                                  
                             </template>
                                    <span>{{h[j.text].level4}}</span>
                            </v-tooltip>
                            </div>
                         </div>

                            <div v-if="hindx > 0 && hindx+1 < headersNew.length " 
                                style="min-width:250.4px;border-bottom:1px solid #C1C8CC;
                                    border-right:1px solid #C1C8CC;" 
                                class="cust_pln_RmNrsng px-2 pt-2">
                               <!-- <span>{{hindx}} {{headersNew.length}} </span> -->
                                 <!-- <span style="color:red" >{{hindx}}</span> -->
                                  <!-- hide-details="auto" -->
                            <v-text-field   
                                label="Enter..."
                                class="rounded-0"
                                solo
                                :disabled="fromview"
                                v-model.trim="h[myfun(j.text)]"
                                :maxlength="maxtxt(j.text)"
                                :rules="[required(h[myfun(j.text)], myfun(j.text)),decimalRule(h[myfun(j.text)],
                                myfun(j.text)),numberRule(h[myfun(j.text)],myfun(j.text))]"
                            >
                            </v-text-field>
                              
                            </div>

                            <div v-if="hindx+1 == headersNew.length && !tabledata.req" 
                                style="min-width:250.4px;border-bottom:1px solid #C1C8CC;
                                        border-right:1px solid #C1C8CC;" 
                                class="cust_pln_RmNrsng px-2 pt-2 ">
                               <!-- <span>{{hindx}} {{headersNew.length}} </span> -->
                                 <!-- <span style="color:red" >{{hindx}}</span> -->
                                  <!-- hide-details="auto" -->
                            <v-text-field   
                                label="Enter..."
                                class="rounded-0"
                                solo
                                :disabled="fromview"
                                v-model="h[j.text]"
                                :maxlength="maxtxt(j.text)"
                                >
                            </v-text-field>
                              
                            </div>

                               <div v-if="hindx+1 == headersNew.length && tabledata.req" 
                                    style="text-align:center;min-width:100px;border-bottom:1px solid #C1C8CC; padding:25.4px" 
                                    class="cust_pln_RmNrsng"
                                    >
                               <!-- <span>{{hindx}} {{headersNew.length}} </span> -->
                               <v-icon  @click="delrow(index,h)"  :disabled="fromview">delete</v-icon>
                            <!-- <v-text-field   
                                label="Enter..."
                                class="rounded-0"
                                solo
                                v-model="h[j.text]"
                                hide-details="auto"
                                :maxlength="max10"
                                :rules="[required(h[j.text], j.text)]">
                            </v-text-field> -->
                              
                              </div>

                        <!-- v-model="IPHPercentSI" -->
                        <!-- <div style="min-width:250px;border-left:1px solid #C1C8CC;border-top:1px solid #C1C8CC" class="cust_pln_RmNrsng px-2 pt-2">
                            <v-text-field   
                                label="Enter..."
                                class="rounded-0"
                                solo
                                v-model="h[j].hsl5"
                                hide-details="auto"
                                :maxlength="max10"
                                :rules="[required()]">
                            </v-text-field>
                        </div>
                        <div style="min-width:250px;border-left:1px solid #C1C8CC; border-top:1px solid #C1C8CC" class="cust_pln_RmNrsng px-2 pt-2" v-if="tabledata.tariffshw===true">
                            <v-text-field
                                label="Enter..."
                                class="rounded-0"
                                solo
                                v-model="h[j].tarrif"
                                hide-details="auto"
                                :maxlength="max10"
                                :rules="[required()]">
                            </v-text-field>
                        </div>
                        <div style="min-width:250px;border-left:1px solid #C1C8CC; border-top:1px solid #C1C8CC" class="cust_pln_RmNrsng px-2 pt-2" v-for="(i,indx) in h[j].noOfRooms" :key="indx">
                            <v-text-field
                                label="Enter..."
                                class="rounded-0"
                                solo
                                v-model="i.inputValue"
                                hide-details="auto"
                                :maxlength="max10"
                                :rules="[required()]">
                            </v-text-field>
                        </div>
                        <div style="min-width:250px;border-left:1px solid #C1C8CC; border-top:1px solid #C1C8CC" class="cust_pln_RmNrsng px-2 pt-2">
                            <v-text-field
                                label="Enter..."
                                class="rounded-0"
                                solo
                                v-model="h[j].inclusions"
                                hide-details="auto"
                                :maxlength="max10"
                                :rules="[required()]">
                            </v-text-field>
                        </div>
                        <div style="min-width:250px;border-left:1px solid #C1C8CC; border-top:1px solid #C1C8CC" class="cust_pln_RmNrsng px-2 pt-2">
                            <v-text-field
                                label="Enter..."
                                class="rounded-0"
                                solo
                                v-model="h[j].exclusions"
                                hide-details="auto"
                                :maxlength="max10"
                                :rules="[required()]">
                            </v-text-field>
                        </div> -->       
                            </td>
                        </tr>
                    </tbody>
                      </fieldset>  
               </v-card>
       
               <v-dialog v-model="pcsDilg" persistent max-width="650px">
                        <v-card color="#F7F7F7" class="pb-2" tile>
                            <v-card tile>
                                <v-layout justify-space-between  class="" style="flex:1;background-ox-shadow: 0px 3px 5px #00000029;">
                                    <v-layout align-center>
                                        <v-card-title class="pa-0 pl-3 text-capitalize font-weight-bold"
                                        style="color:#10242B;font-size:13px">Service Category (Level 4) Search and Select</v-card-title>   
                                    </v-layout>
                                    <div style="border-left:1px solid #C1C8CC;" @click="pcsDilg=false">
                                        <v-icon style="cursor: pointer;" class="pa-2" >close</v-icon>
                                    </div>
                                </v-layout>
                            </v-card>
                            <v-card tile  class="ma-4 pt-3 pb-3">
                                <v-layout color="" class="mx-2 mb-5" style="">
                                    <v-flex xs6>
                                        <v-layout column class="cust_pr mx-2 ">
                                            <p class="font-weight-bold cstm-clr-pr">
                                                Search Service Category Level 4
                                            </p>
                                            <!-- :rules="[minLength(4)]" -->
                                            <v-text-field
                                            @input="psearch(pcslongDesc)"
                                                solo
                                                label="Type set of keywords"
                                                v-model="pcslongDesc"
                                                class="rounded-0"
                                                :rules="[minLength(pcslongDesc,4)]"
                                            >
                                            </v-text-field>
                                        </v-layout>
                                    </v-flex>
                                        <!-- <v-layout align-center>
                                            <v-btn tile dark  
                                                 style="font-size:12px;background-color:#E46A25" small  class="text-capitalize mt-6 mx-2 px-3"
                                                @click="pcsSrch_clck()">
                                                <v-icon dark small >search</v-icon > 
                                                <v-divider vertical class="mx-2"></v-divider>
                                                Search
                                            </v-btn>
                                        </v-layout> -->
                                       

                                   
                                </v-layout>
                                <v-card tile class="mx-4" v-if="shwpcstbl===true">
                                    <v-layout v-if="false" justify-space-between align-center style="border:1px solid #C1C8CC; background-color:#f7f7f7" class="ma-0 pa-0">
                                        <!-- <p class="mx-2 mt-3 font-weight-bold" style="color:#8A8A8A;font-size:13px">
                                        PCS Codes
                                        </p> -->
                                    </v-layout>
                                    <div style="background-color: #ffffff;border: 1px solid #c1c8cc; max-height:320px; overflow-y:auto" class="">
                                       <v-radio-group v-model="pcs_radioGroup" dense class="ma-0 pa-0" hide-details>
                                            <div style="border-top: 1px solid #c1c8cc;" v-for="codes in pcsCodes" :key="codes._id" class="pa-3">
                                                <v-radio
                                                    @click="LVLselectionis(codes)"
                                                    :value="codes"
                                                >
                                                <div slot="label" style="display: flex; justify-content:space-between;">
                                                    <div class="" style="width: 120px">{{codes.level4Code}}</div>
                                                    <div class="pl-5">

                                                         <v-tooltip left >
                                                            <template v-slot:activator="{ on }">
                                                                <div v-on="on">
                                                                    {{codes.level4|truncate(45, '...')}}</div>
                                                            </template>
                                                            <span>{{codes.level4}}</span>
                                                            
                                                        </v-tooltip>
                                                      </div>  
                                                        
                                                </div>
                                                </v-radio>
                                                
                                            </div>
                                        </v-radio-group>  
                                    </div>
                                    <v-pagination
                                        class="pdct-stUp-bnkAcc-pgn"
                                        v-model="page"
                                        @input="nextPageData(page)"
                                        color="#152F38"
                                        flat
                                        :length="pagelength"
                                        :total-visible="6"
                                    ></v-pagination>
                                </v-card>


                            </v-card>

                            
                            <v-layout class="mr-2" row justify-center style="">
                                <v-btn tile dark style="font-size:12px;background-color:#D1121B" class=" px-3 mr-3 text-capitalize" small @click="clear_pcsFrm()">
                                    <v-icon color="" small >delete</v-icon>
                                    <v-divider vertical class="ml-2 mr-2 text-capitalize"></v-divider>Clear
                                </v-btn>
                                 <v-btn tile dark style="font-size:12px;background-color:#23B1A9" class=" px-3 text-capitalize" small @click="close_pcsFrm()">
                                    <v-icon color="" small >check_circle</v-icon>
                                    <v-divider vertical class="ml-2 mr-2 text-capitalize"></v-divider>Submit
                                </v-btn>
                            </v-layout>
                            
                            
                        </v-card>
                    </v-dialog>
    </div>

</template>

<script>
export default {
    props:{tabledata:{
        default:{
            radio:false,
            inc:true,
        }
    }},

    created(){
        this.fromview = this.$store.state.tariff_view;
    },

    mounted() {
        console.log(this.tabledata,"this.tabledata");
        console.log(this.radiodata);

    },
    filters:{
          truncate: function (text, length, suffix) {
                if (text.length > length) {
                    return text.substring(0, length) + suffix;
                } else {
                    return text;
                }
            },
    },

    data(){
        return{
            fromview: false,
            redisdata:'',
            level_fourindex:null,
            level4data:[],
            radiodata:{},
            // radioProcess: '',
            radioref:false,
            vmis:"",
             rowarray:this.tabledata.tabledata,
             drop_down_vals:this.tabledata.dd,
              max05:5,
            max10:10,
            max20:20,
            max30:30,
            max40:40,
            max50:50,
            rules: {
                
                select: [(v) => !!v || "This Field is Required"],
                select2: [(v) =>  v.length>0 || "This Field is Required"],
                
            },
            required(v, typeis){
                //   console.log(v, "<- testing ->",typeis);
                  if(typeis == 'Inclusions') return
                  if(typeis == 'Exclusions') return
                return v=>v && v.length>0 || "This Field is Required"
            },
            minLength(v,minLength){
                console.log("incoming v is  ", v)
                 if(v.length == 0) return
                return v=>v && v.length>=minLength || `Must be atleast ${minLength} Characters`
            },
            maxLength(maxLength){
                return v=>v && v.length<=maxLength || `Must be less than ${maxLength} Characters`
            },
            spclChar(){
                return v=>/^[A-Za-z0-9 ]+$/.test(v) || `Special Characters Not Allowed`
            },
            alphaNumeric(v, typeis){
                // console.log(v, "<- testing ->",typeis);
                 if(typeis == 'hsl5') return
                if(typeis == 'Inclusions') return
                if(typeis == 'Exclusions') return
                return v=>/^[0-9 ]+$/.test(v) || `Only Numbers are allowed.`
            },
            numberRule(v, typeis) {
                // console.log(v, "<- testing ->",typeis);
                if(typeis == 'hsl5') return
                if(typeis == 'Inclusions') return
                if(typeis == 'Exclusions') return
                // if (!v.trim()) return true;
                if (!isNaN(parseFloat(v)) && v >0) return true;
                return ' Value must be greater than zero';
            },
            decimalRule(v, typeis){
                if(typeis == 'hsl5') return
                if(typeis == 'Inclusions') return
                if(typeis == 'Exclusions') return
                return v=>/^\d+(\.\d{1,2})?$/.test(v) || `Numeric upto two decimals.`
            },
            headers:[
                {text: 'Sr. No.',value:'status',sortable: false,divider:true,class:"hdr-txt-clr-Rn backColorRn"}, 
                {text: 'Service Category(Level 4)',value:'status',sortable: false,divider:true,class:"hdr-txt-clr-Rn backColorRn"},
                {text: 'Hospital Service(Level 5)', value: 'Date',sortable: false,divider:true,class:"hdr-txt-clr-Rn backColorRn"},
                {text: 'Tariff', value: 'tariff',sortable: false,divider:true,class:"hdr-txt-clr-Rn backColorRn"},
                {text: 'Inclusions',value: 'remarks',sortable: false,divider:true,class:"hdr-txt-clr-Rn backColorRn"}, 
                {text: 'Exclusions',value: 'remarks',sortable: false,divider:true,class:"hdr-txt-clr-Rn backColorRn"},   
            ],
             headersNew: this.tabledata.headersdata,
            addCategory:[],
            headers2:[
                {text: 'Sr. No.',value:'srNo',sortable: false,divider:true,class:"hdr-txt-clr-Rn backColorRn"}, 
                {text: 'Service Category(Level 4)',value:'status',sortable: false,divider:true,class:"hdr-txt-clr-Rn backColorRn"},
                {text: 'Hospital Service(Level 5)', value: 'Date',sortable: false,divider:true,class:"hdr-txt-clr-Rn backColorRn"},
                // {text: 'Room Type 1', value: 'Time',sortable: false,divider:true,class:"hdr-txt-clr-Rn backColorRn"},
                // {text: 'Room Type 2', value: 'userName',sortable: false,divider:true,class:"hdr-txt-clr-Rn backColorRn"},
                // {text: 'Room Type 3',value: 'remarks',sortable: false,divider:true,class:"hdr-txt-clr-Rn backColorRn"},  
                // {text: 'Room Type 4',value: 'remarks',sortable: false,divider:true,class:"hdr-txt-clr-Rn backColorRn"},
                {text: 'Inclusions',value: 'remarks',sortable: false,divider:true,class:"hdr-txt-clr-Rn backColorRn"}, 
                {text: 'Exclusions',value: 'remarks',sortable: false,divider:true,class:"hdr-txt-clr-Rn backColorRn"},   
            ],
            roomconfigis:[  {"roomTyp":"fjcnf"},{"roomTyp":"jhedc"},{"roomTyp":"jhexb"},{"roomTyp":"ejnxd"},{"roomTyp":"hdbxd"},{"roomTyp":"yedbv"},{"roomTyp":"uyuyu"},{"roomTyp":"acaca"},{"roomTyp":"bcbvc"}],
            noOfRooms:[
                // { text:'General',value:"" },
                // { text:'Delux' ,value:""},
                // { text:'SuperDelux',value:""},
                // { text:'VIP',value:""},
              
            ],
            statusData:[{
                srNo:'1',
                status:"Active",
                Date:"TESt",
                General:"General",
                Delux:"General",
                SuperDelux:"General",
                VIP:"General"
            }],

            pcsDilg:false,
            pcslongDesc:"",
            shwpcstbl:false,
            pcs_radioGroup:'',
            pcsCodes:[],
            page: 1,
            pagelength:0,


        }
    },
    // created(){

    // },
    methods:{

        psearch(indatais){
            if(this.pcslongDesc.length < 4){
                // console.log("empty")
                // this.showToast('Must be atleast 4 Characters.', this.TOST().WARNING);
                return  
            }

            if(this.pcslongDesc.length % 2 === 0){
                 this.pcsSrch(indatais)
            }
           

        },

        LVLselectionis(selected_data){
            
            this.rowarray[this.level_fourindex].scl4 = selected_data;
             console.log(this.rowarray,'after adding in row is | selected_data -> ', selected_data);
            
        },

        setlevelfour(h,rowind, j){
            if(this.fromview) return
            this.pcsDilg = true
            this.level_fourindex = rowind;
            console.log(j, "header | rowdata", h);
            console.log(rowind,"whats the index of the aray object ", this.rowarray[rowind])

        },

        myfun(indata){
            // console.log(typeof indata," < | indata HOP ")
            
            if(typeof indata == 'string'){
                return indata
            }else{
                // console.log(" return Object.keys(indata.text) ",Object.keys(indata));
                 return Object.keys(indata)

            }
           
            //  if(indata.hasOwnProperty(Object.keys(indata))){

                   
                    
            //     }else{
                    
            //     return  [...Object.keys(indata)]
                
            //     }
            // if(Object.keys(indata.text) == undefined || Object.keys(indata.text) == null){

            //     return indata

            // }else{
            //     console.log("continue");
            // }
            // console.log("my func is ", indata)
            // return  Object.keys(indata.text)
            // if()

        },
        maxtxt(e){
            // console.log("maxtxt===> ",e );
            if(e==='hsl5'){
                return 100
            }else if(e==='Inclusions'||e==='Exclusions'){
                return 200
            }else if(e==='Tariff'){
                return 20
            }else{
                return 20
            }
        },

        callemit(){
             console.log("call emit called " );
        },

        rbtn(indexis, rowdatais){
              if(this.fromview) return

            if(!this.radiodata.hasOwnProperty('_id')){
                //   this.showToast('Please first Save the Package to add inclusions', this.TOST().WARNING);
                return
            }
            console.log(this.radiodata,"rowdata is ", rowdatais);
            this.radioref = !this.radioref
             
            if(indexis == this.storeindex){
                if(!this.radioref){
                   this.radiodata = {}
                }
              
                // alert("from if as index matching")
            }
            
            this.$emit("radiodataUnCheck", this.radiodata)
            console.log("this.radiodata ", this.radiodata);

            if(Object.keys(this.radiodata).length != 0){
                 this.$emit('radioIncData' ,{inCdta:this.radiodata})
            }
            
                  this.storeindex = indexis
              

            

          
            // console.log("incoming radio data",  this.radiodata );
           

        },
        clear_pcsFrm(){
            // this.pcslongDesc=""
            // this.pcs_radioGroup=""
            // this.pcsCodes=[]
            // this.shwpcstbl=false
            this.pcs_radioGroup = []
            this.pcslongDesc = ''
            

        },
        close_pcsFrm(){
                if(this.pcs_radioGroup.length == 0){
                    // this.pcsDilg=false
                      this.showToast('Please Select a Service Category Level 4', this.TOST().WARNING);
                }else{
                    this.pcsDilg=false
                }
            
            
            // this.clear_pcsFrm()
        },
        pcsSrch_clck(indata){
            this.page=1
            this.pcsSrch(this.page)
        },

        nextPageData(indata){

            this.get_level_four_searchdata(this.redisdata, indata);

        },

       

        get_level_four_searchdata(incomingdata, pageis){

            try{
                    let that = this
                    var build_level_fourapi = this.tabledata.url
                    console.log("built api is -> ",build_level_fourapi+'?searchString='+incomingdata);
                    // return
                    this.GET(3, build_level_fourapi+'?searchString='+incomingdata+'&pageNo='+pageis+'&perPage=200' ,(response ,error)=>{
                            if(!error){
                                // if(res.data.statusCode === 200){
                                // this.level4data = res.data.data.data
                               
                                that.pcsCodes = response.data.data.data
                                console.log( that.pcsCodes,"this.level4data")
                                that.pagelength=response.data.data.total_pages
                                if(!that.pcsCodes || that.pcsCodes.length ===0){
                                    that.shwpcstbl=false
                                    that.showToast('No Results Found', that.TOST().WARNING);
                                }else{
                                    that.shwpcstbl=true
                                }
                                   
                                // }else{
                                //     this.showToast(res.data.msg ,this.TOST().ERROR); 
                                // }
                            }else{
                                this.showToast(error.response.data.msg, this.TOST().WARNING);
                            }
                    })
                    // ?searchString='+

                }catch (error) {
                    
                }

        },

        pcsSrch(indata){

            if((this.pcslongDesc==="")){
                console.log("empty")
                this.showToast('Please enter search criteria.', this.TOST().WARNING);
                return  
            }

            if(this.pcslongDesc.length<4){
                console.log("empty")
                this.showToast('Must be atleast 4 Characters.', this.TOST().WARNING);
                return  
            }

            //    return
            this.redisdata = this.pcslongDesc
            this.get_level_four_searchdata(indata, 1);
            // let self = this;   
              


            return
                axios({
                    method: "get",
                    url:self.API_Service_MASTER()+"plan/get-pcs-codes?pageNo="+pgNo+"&perPage=10&level4="+this.pcslongDesc, 
                        headers: {
                            "Content-Type": "application/json", 
                            // "x-access-token": self.token,
                        }
                })
                .then(function(response) {
                    console.log('Get icd codes------->>>',response.data.data);
                    self.pcsCodes=response.data.data.data
                    self.pagelength=response.data.data.total_pages
                    if(!self.pcsCodes || self.pcsCodes.length ===0){
                        self.shwpcstbl=false
                        self.showToast('No Results Found', self.TOST().WARNING);
                    }else{
                        self.shwpcstbl=true
                    }
                    
                   
                })
                .catch(function(error) {
                    console.log(error);
                    // self.showToast(error.response.data.msg,self.TOST().ERROR);
                });

        },



         delrow(ind,data){
              if(this.fromview) return
             console.log(data,data._id,this.tabledata.del_endpoint,"Row data");
            if (data._id===undefined ||data._id===null||data._id===''){
                
                this.rowarray.splice(ind, 1)
                // 
                if(this.tabledata.del_endpoint==='nursing charges'){
                    this.showToast("Nursing Charges has been successfully deleted" ,this.TOST().SUCCESS);

                }else if(this.tabledata.del_endpoint==='Icu charges'){
                    this.showToast("ICU Charges has been successfully deleted" ,this.TOST().SUCCESS);

                }else if(this.tabledata.del_endpoint==='Ot charges'){
                    this.showToast("OT Charges has been successfully deleted" ,this.TOST().SUCCESS);

                }else if(this.tabledata.del_endpoint==='Miscellaneous charges'){
                    this.showToast("Nursing Charges has been successfully deleted" ,this.TOST().SUCCESS);

                }else if(this.tabledata.del_endpoint==='Med & Consumables Charges'){
                    this.showToast("Med & Consumables Charges has been successfully deleted" ,this.TOST().SUCCESS);

                }else if(this.tabledata.del_endpoint==='Ambulance Charges'){
                    this.showToast("Ambulance Charges has been successfully deleted" ,this.TOST().SUCCESS);

                }else if(this.tabledata.del_endpoint==='Investigation Charges'){
                    this.showToast("Investigation Charges has been successfully deleted" ,this.TOST().SUCCESS);

                }else if(this.tabledata.del_endpoint==='Professional Fee Charges'){
                   this.showToast("Professional Fee Charges has been successfully deleted" ,this.TOST().SUCCESS);

                }else if(this.tabledata.del_endpoint==='Packages Charges'){
                   this.showToast("Package Charges has been successfully deleted" ,this.TOST().SUCCESS);
                }
                
            }else{
                let api_Url=''
                let formData={
                    tariffId:this.$store.state.createtarrifDetails._id,
                }
                if(this.tabledata.del_endpoint==='nursing charges'){
                    api_Url="tariff/remove-nursing-charges"
                    formData.nursingId=data._id

                }else if(this.tabledata.del_endpoint==='Icu charges'){
                    api_Url="tariff/remove-icu-charges"
                    formData.icuId=data._id

                }else if(this.tabledata.del_endpoint==='Ot charges'){
                    api_Url="tariff/remove-ot-charges"
                    formData.otId=data._id

                }else if(this.tabledata.del_endpoint==='Miscellaneous charges'){
                    api_Url="tariff/remove-miscellaneous-charges"
                    formData.miscellaneousId=data._id

                }else if(this.tabledata.del_endpoint==='Med & Consumables Charges'){
                    api_Url="tariff/remove-med-and-consumables-charges"
                    formData.medConsumablesId=data._id

                }else if(this.tabledata.del_endpoint==='Ambulance Charges'){
                    api_Url="tariff/remove-ambulance-charges"
                    formData.ambulanceId=data._id
                }else if(this.tabledata.del_endpoint==='Investigation Charges'){
                    api_Url="tariff/remove-investigations-charges"
                    formData.investigationsId=data._id

                }else if(this.tabledata.del_endpoint==='Professional Fee Charges'){
                    api_Url="tariff/remove-professional-fee-charges"
                    formData.professionalFeeId=data._id

                }else if(this.tabledata.del_endpoint==='Packages Charges'){
                    api_Url="tariff/remove-package-charges"
                    formData.packageId=data._id
                }
                
                console.log(formData,"formData")

                this.PUT(2, api_Url,formData,
                    ( res, error) => {
                        console.log(res);
                    if(!error){
                        console.log(res.data);
                        this.showToast(res.data.msg ,this.TOST().SUCCESS);
                        this.rowarray.splice(ind, 1)
                    }else{
                        console.log("when status is not 200", error.res);
                        this.showToast(error.res.data.msg ,this.TOST().ERROR);
                    }
        
                });

            }
              
            this.$emit("radiodataDel", ind)

         },

         required2(v, typeis){
           



            if (!!v && v.length>0) {
                // console.log("testing",v)
                return "This Field is Required"
             
            }

        },

        save_click(){
            console.log("this.tabledata.roomconfig ", this.tabledata.roomconfig);
            // this.$store.state.createtarrifDetails.roomConfiguration.roomConfiguration
             console.log(this.rowarray, "<- ROWARRAY | BEFORE emitted this.tabledata.roomconfig | this.headersNew -> ", this.headersNew); 
          
             
                var for_ip_headers = []
                for(let j=0; j<this.headersNew.length; j++){
                    var indata = this.headersNew[j].text
                        if(typeof indata == 'string'){

                            
                        }else{
                            console.log(" return Object.keys(indata.text) ",Object.keys(indata));
                        for_ip_headers.push(this.headersNew[j])

                        }
                }

            console.log('for_ip_headers -> ', for_ip_headers);

              for(let i=0; i<this.rowarray.length; i++){
                  this.rowarray[i].noOfRooms = [];
                            
                     for(let j=0; j<for_ip_headers.length; j++){

                            
                                var inner_keycheck  = Object.keys(for_ip_headers[j].text)
                               
                              if(this.rowarray[i].hasOwnProperty(inner_keycheck[0])){
                                   console.log('keys - >',inner_keycheck[0])
                                      this.rowarray[i].noOfRooms.push({text:for_ip_headers[j].value, 
                                            value:for_ip_headers[j].value,
                                            inputValue: this.rowarray[i][Object.keys(for_ip_headers[j].text)]
                              })              

                              }else{
                                      this.rowarray[i].noOfRooms.push({text:for_ip_headers[j].value, 
                                            value:for_ip_headers[j].value,
                                            inputValue:''
                              })              

                              }
                     }

              }



            console.log("final data is -> ", this.rowarray)
             this.$emit('save',{dta:this.rowarray})
          return
          // Legacy code wid bug same colname issue present earlier now fixed above - akshay mhatre (akrazr)
              for(let i=0; i<this.rowarray.length; i++){ 
                  this.rowarray[i].noOfRooms = [];
                       

                       
                     for(let j=0; j<this.headersNew.length; j++){

                              if(this.rowarray[i].hasOwnProperty(this.headersNew[j].text)){

                              }
                        // //   console.log(this.rowarray[i][this.tabledata.roomconfig[j].roomType])
                        //       console.log(this.rowarray[i], " rowarray iteration |  ", this.tabledata.roomconfig[j].roomType)
                        //  if(this.rowarray[i].hasOwnProperty(this.tabledata.roomconfig[j].roomType)){

                        //      console.log(' matched |==> ', this.tabledata.roomconfig[j].roomType);
                        //      this.rowarray[i].noOfRooms.push({text:this.tabledata.roomconfig[j].roomType, 
                        //                     value:this.tabledata.roomconfig[j].roomType,
                        //                     inputValue: this.rowarray[i][this.tabledata.roomconfig[j].roomType]})

                        //  }else{
                        //      console.log(' Not matched |==> ', this.tabledata.roomconfig[j].roomType);
                        //        this.rowarray[i].noOfRooms.push({text:this.tabledata.roomconfig[j].roomType, 
                        //                     value:this.tabledata.roomconfig[j].roomType,
                        //                     inputValue: ''})

                        //  }


                     }
              }


            return
             for(let i=0; i<this.rowarray.length; i++){
                  this.rowarray[i].noOfRooms = [];
                       

                       
                     for(let j=0; j<this.tabledata.roomconfig.length; j++){
                        //   console.log(this.rowarray[i][this.tabledata.roomconfig[j].roomType])
                              console.log(this.rowarray[i], " rowarray iteration |  ", this.tabledata.roomconfig[j].roomType)
                         if(this.rowarray[i].hasOwnProperty(this.tabledata.roomconfig[j].roomType)){

                             console.log(' matched |==> ', this.tabledata.roomconfig[j].roomType);
                             this.rowarray[i].noOfRooms.push({text:this.tabledata.roomconfig[j].roomType, 
                                            value:this.tabledata.roomconfig[j].roomType,
                                            inputValue: this.rowarray[i][this.tabledata.roomconfig[j].roomType]})

                         }else{
                             console.log(' Not matched |==> ', this.tabledata.roomconfig[j].roomType);
                               this.rowarray[i].noOfRooms.push({text:this.tabledata.roomconfig[j].roomType, 
                                            value:this.tabledata.roomconfig[j].roomType,
                                            inputValue: ''})

                         }

                        //   if(this.rowarray[i].hasOwnProperty(this.tabledata.roomconfig[j].roomType)){
                        //         this.rowarray[i].noOfRooms.push({text:this.tabledata.roomconfig[j].roomType, 
                        //                     value:this.tabledata.roomconfig[j].roomType,
                        //                     inputValue: this.rowarray[i][this.tabledata.roomconfig[j].roomType]})
                        //     }else{
                        //         this.rowarray[i].noOfRooms.push({text:this.tabledata.roomconfig[j].roomType, 
                        //                     value:this.tabledata.roomconfig[j].roomType,
                        //                     inputValue: this.rowarray[i][this.tabledata.roomconfig[j].roomType]})
                        //     }
                     }
                
                //  for(let room in this.tabledata.roomconfig){
                //      if(this.rowarray[i].hasOwnProperty(this.tabledata.roomconfig[room])){
                //          this.rowarray[i].noOfRooms.push({text:this.tabledata.roomconfig[room], 
                //                             value:this.tabledata.roomconfig[room],
                //                             inputValue: this.rowarray[i][this.tabledata.roomconfig[room]]})
                //      }else{
                //            this.rowarray[i].noOfRooms.push({text:this.tabledata.roomconfig[room], 
                //                             value:this.tabledata.roomconfig[room],
                //                             inputValue:''})
                //      }
                //  }

             }

             console.log(this.rowarray," AFTER this.tabledata.roomconfig ", this.tabledata.roomconfig); 
            return
            this.$emit('save',{dta:this.rowarray})
        }
         
    }
}
</script>
<style>

.normalrowis{
    display:flex; flex-direction:row;
}

.cust_pln_RmNrsng .v-text-field .v-input__slot,
.v-text-field--outline .v-input__slot {
    min-height: 38px !important;
    display: flex !important;
    align-items: center !important;
    /* border:1px red solid; */
}

.cust_pln_RmNrsng .v-input .v-label {
    font-size: 13px !important;
}
.cust_pln_RmNrsng.v-input {
    font-size: 13px !important;
}
.cust_pln_RmNrsng .v-input input {
    font-size: 13px !important;
}

</style>