<template>
  <div class="mx-8 my-6">
    <v-form
      ref="createForm"
      lazy-validation
      @submit.prevent="courtOnSubmitHandler"
    >
    <v-row>
      <h3>Create GST Master</h3>
    </v-row>
    <v-row>
      <v-col>
        <label class="form-label"
          >Tax Rate
          <span class="red--text"><strong> * </strong></span>
        </label>
        <v-select
          class="form-control"
          v-model="formData.taxRate"
          :disabled="isView"
          :rules="[(v) => requiredField(v)]"
          rule
          placeholder="Select"
          :items="taxRateOption"
          dense
          solo
          required
        ></v-select>
      </v-col>
      <v-col>
        <label class="form-label"
          >SGST/UGST(%)
          <span class="red--text"><strong> * </strong></span>
        </label>
        <v-text-field
          placeholder="Enter..."
          class="form-control"
          :disabled="isView"
          :rules="[(v) => matchRegex(v, 'nums'), (v) => requiredField(v)]"
          maxlength="100"
          solo
          dense
          v-model="formData.sgstUgst"
          depressed
        />
      </v-col>
      <v-col>
        <label class="form-label"
          >CGST(%)
          <span class="red--text"><strong> * </strong></span>
        </label>
        <v-text-field
          placeholder="Enter..."
          :disabled="isView"
          class="form-control"
          :rules="[(v) => matchRegex(v, 'nums'), (v) => requiredField(v)]"
          maxlength="100"
          solo
          dense
          v-model="formData.cgst"
          depressed
        />
      </v-col>
      <v-col>
        <label class="form-label"
          >IGST(%)
          <span class="red--text"><strong> * </strong></span>
        </label>
        <v-text-field
          placeholder="Enter..."
          class="form-control"
          :disabled="isView"
          :rules="[(v) => matchRegex(v, 'nums'), (v) => requiredField(v)]"
          maxlength="100"
          solo
          dense
          v-model="formData.igst"
          depressed
        />
      </v-col>
      <v-col>
        <v-checkbox
          class="form-control"
          v-model="formData.active"
          dense
          label="Active"
          :disabled="isView"
        >
        </v-checkbox>
      </v-col>
    </v-row>
    <v-row style="max-width: 40.6%">
      <v-col>
        <label class="form-label">
          Effective Start Date
          <span class="red--text"><strong> * </strong></span>
        </label>
        <v-menu
          v-model="menu"
          offset-y
          :close-on-content-click="false"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :disabled="isView"
              :value="computedStartDate"
              placeholder="DD/MM/YYYY"
              dense
              solo
              readonly
              v-on="on"
              @focus="menu = true"
              @blur="menu = true"
              v-bind="attrs"
              class="form-control"
              append-icon="mdi-menu-down"
              prepend-inner-icon="mdi-calendar"
            >
            </v-text-field>
          </template>
          <v-date-picker
            no-title
            :max="today"
            v-model="formData.effectiveStartDate"
            @input="menu = false"
          >
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col>
        <label class="form-label">
          Effective End Date
          <span class="red--text"><strong> * </strong></span>
        </label>
        <v-menu
          v-model="menu2"
          offset-y
          :close-on-content-click="false"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :disabled="isView"
              :value="computedStartDate2"
              placeholder="DD/MM/YYYY"
              dense
              solo
              readonly
              v-on="on"
              @focus="menu2 = true"
              @blur="menu2 = true"
              v-bind="attrs"
              class="form-control"
              append-icon="mdi-menu-down"
              prepend-inner-icon="mdi-calendar"
            >
            </v-text-field>
          </template>
          <v-date-picker
            no-title
            :min="formData.effectiveStartDate"
            v-model="formData.effectiveEndDate"
            :pickerDate.sync="pickerDate"
            @input="menu2 = false"
          >
          </v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    </v-form>
    <v-row>
      <v-btn
        type="button"
        color="red"
        outlined
        class="ml-3 mx-2"
        @click="onCancel(true)"
      >
        <v-icon> mdi-backspace </v-icon>

        <v-divider class="mx-2 ml-5" vertical></v-divider>
        <span class="subheading">Cancel</span>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        v-if="isView === false"
        type="button"
        color="red"
        outlined
        class="mr-3"
        @click="onReset"
      >
        <v-icon> mdi-backspace </v-icon>

        <v-divider class="mx-2 ml-5" vertical></v-divider>
        <span class="subheading">Reset</span>
      </v-btn>
      <v-btn
        v-if="isView === false"
        type="submit"
        color="teal white--text"
        class=""
        @click="onSubmit"
      >
        <v-icon> mdi-arrow-right-circle </v-icon>

        <v-divider class="mx-2" vertical color="white--text"></v-divider>
        <span class="subheading">Submit</span>
      </v-btn>
    </v-row>
  </div>
</template>

<script>
import moment from "moment"
export default {
  data() {
    return {
      initialFormData: {
        taxRate: "",
        active: false,
        sgstUgst: 50,
        cgst: 50,
        igst: 100,
        effectiveStartDate: null,
        effectiveEndDate: null,
      },
      formData: {},
      menu: false,
      menu2: false,
      taxRateOption: ["0", "5", "6", "12", "18", "28"],
      isView: false,
      today: new Date().toISOString(),
      pickerDate: null,
    }
  },
  computed: {
    computedStartDate() {
      return this.formData.effectiveStartDate == null
        ? ""
        : moment(this.formData.effectiveStartDate).format("DD-MM-YYYY")
    },
    computedStartDate2() {
      return this.formData.effectiveEndDate == null
        ? "31/12/2099"
        : moment(this.formData.effectiveEndDate).format("DD-MM-YYYY")
    },
  },
  methods: {
    onReset() {
      this.showConfirm(
        "Confirmation",
        "Are you sure you want to Reset?",
        "Yes",
        "No",
        (Yes) => {
          this.formData = { ...this.initialFormData }
        },
        (No) => {}
      )
    },
    onCancel() {
      this.$router.push("/master/search-gst")
    },
    createGST(payload) {
      this.POSTM(
        "POST_GST_MASTER",
        payload,
        (res) => {
          if (res.data.statusCode === 200) {
            this.showAlert(res?.data?.msg || "Success", "", "", () => {
              this.$router.push("/master/search-gst")
            })
            console.log("999", res.data)
           
          } else {
            this.showAlert(res.data.msg)
          }
        },
        (error) => {
          console.log("Error", JSON.stringify(error.response.data))

          this.showAlert(
            (error.response.data.msg)?error.response.data.msg:error.response.data.message.msg
            )
        },
        true
      )
    },
    updateGST(payload) {
      let updateId = this.$route.params.item._id
      this.PUT_ByURLM(
        "PUT_GST_MASTER",
        payload,
        (res) => {
          this.showAlert(res?.data?.msg || "Success", "", "", () => {
            this.onCancel()
          })
          if (res.data.statusCode === 200) {
            console.log("999", res.data)
          }
        },
        (error) => {
          console.log("Error", error)
          // this.showAlert(res.data.msg)
        },
        updateId,
        true
      )
    },
    checkPercentageValidation(){
        if(this.formData.sgstUgst>100 || this.formData.cgst>100 || this.formData.igst>100){
          return true;
        }
        return false;
    },
    onSubmit() {
      console.log("123", this.formData)
      if (!this.$refs.createForm.validate()) {
        return this.showAlert("Please fill the mandatory fields")
      } else {
        if(this.checkPercentageValidation()){
          this.showAlert(" SGST /UGST, CGST, IGST fields should be allow only 100% or less than 100% .");
          return;
        }
        if (Object.keys(this.$route.params).length != 0) {
          this.updateGST(this.formData)
        } else {
          this.createGST(this.formData)
        }
      }
    },
  },
  created() {
    this.$store.commit("SAVE_PAGE_HEADER", "GST Master");
    this.formData = { ...this.initialFormData }
    if (Object.keys(this.$route.params).length != 0) {
      //isView
      this.isView = this.$route.params.isView
      let result = this.$route.params.item
      if(!result.taxRate.includes(this.taxRateOption))  this.taxRateOption.push(result.taxRate) 
      this.formData = { ...result }
    }
  },
}
</script>

<style lang="scss" scoped></style>
