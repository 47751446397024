<template>
    <v-dialog v-model="showAuditTrail" max-width="1200" persistent>
        <v-card>
            <v-container>
                <v-row>
                    <v-col class="d-flex align-left pl-1 justify-start">
                        <v-card-title>
                          <div>Audit Trail</div>
                        <v-spacer></v-spacer>
                      </v-card-title>
                    </v-col>
                <v-col class="d-flex align-right pl-1 justify-end">
                  <v-btn
                    dark
                    class="text-capitalize ml-2"
                    color="#E46A25"
                    depressed
                    @click="handleClose()"
                  >
                    <v-icon>close</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-data-table
              class="padding-0 vDataTableStyling"
              :headers="headers"
              :items="auditTrailList"
              :page.sync="page"
              :items-per-page="itemsPerPage"
              :hide-default-footer="true"
            >
            <template v-slot:item.createdAt="{ item }">
            {{ formatDateTime(item.createdAt) }}
            </template>
            
            <template v-slot:item.remarks="{ item}">
            <v-tooltip top> 
            <template v-slot:activator="{ on, attrs }">
              <v-layout v-on="on" v-bind="attrs">{{item.remarks | truncate(20, '...')}}</v-layout>
            </template>
              <span v-on="on" v-bind="attrs">{{item.remarks}}</span>
            </v-tooltip>
            </template>
            </v-data-table>
            <v-layout
        justify-end
        align-center
        style="flex: 0"
        class="py-2"
      >
        <v-spacer></v-spacer>
        <div>
          <span style="color: orange"
            >Showing {{ pageStartIndex }}-{{ pageEndIndex }}</span
          >
          out of {{ totalListCount }} records
        </div>
        <div>
          <v-pagination
            v-model="page"
            :length="pageCount"
            :total-visible="5"
            @input="getTabsDataList"
            color="#152F38"
          ></v-pagination>
        </div>
      </v-layout>
        </v-container>
          </v-card>
    </v-dialog>
</template>
<script>
import moment from "moment";
import { getAuditTrailList } from "../../apiservices/Workitem";
export default {
  name: "AuditTrail",
  data() {
    return {
        showAuditTrail: true,
        totalListCount: 0,
        itemsPerPage: 10,
        pageStartIndex: 0,
        page: 1,
        auditTrailList: [],
        headers: [
        {
          text: "Investigator Task Id",
          value: "investigationClaimId",
          sortable: false,
          width: 100,
          align: "center",
        },
        {
          text: "SIU Status",
          value: "status",
          sortable: false,
          width: 100,
          align: "center",
        },
        {
          text: "SIU Sub Status",
          value: "subStatus",
          sortable: false,
          width: 100,
          align: "center",
        },
        {
          text: "From",
          value: "fromUserNtid",
          sortable: false,
          width: 100,
          align: "center",
        },
        {
          text: "To",
          value: "toUserNtid",
          sortable: false,
          width: 100,
          align: "center",
        },
        {
          text: "Date & Time",
          value: "createdAt",
          sortable: false,
          width: 100,
          align: "center",
        },
        {
          text: "Remarks",
          value: "remarks",
          sortable: false,
          width: 100,
          align: "center",
        },
    ],
    }
},
props: ["siuWorkItemId"],
created() {},
async mounted(){
    let newPageNum = this.page
      let reqData = "?" + "siuWorkItemId=" + this.siuWorkItemId + "&page=" + this.page + "&pageLimit=" + this.itemsPerPage;
      try{
          let res = await getAuditTrailList(reqData);
          if(res.data.statusCode == 200) {
          this.showToast(res.data.msg, this.TOST().SUCCESS);
        } else {
          this.showToast(res.data.msg, this.TOST().WARNING);
        }
              this.auditTrailList = res.data.data.data;
              if(res?.data?.data?.data?.length > 0) {
                this.totalListCount = res.data.data.total
                this.pageStartIndex = (newPageNum - 1) * this.itemsPerPage + 1
              } else {
                    this.totalListCount = 0;
                    this.pageStartIndex = 0;
              }
      } catch(e) {
        this.showToast(e.response?.data?.msg || e.response?.data  || e.response || e, this.TOST().ERROR);
      }
},
methods: {
    formatDateTime(dateTime) {
      return dateTime ? moment(dateTime).format("DD-MM-YYYY hh:mm A") : "";
    },
  handleClose() {
    this.$emit('showHide', false);
  }
},
filters:{
        truncate: function (text, length, suffix) {
            if (text == null) return "";
            if (text.length > length) {
                return text.substring(0, length) + suffix;
            } else {
                return text;
            }
        }
      },
computed: {
    pageCount() {
      if (!this.totalListCount) {
        return 0
      } else {
        return Math.ceil(this.totalListCount / this.itemsPerPage);
      }
    },
    pageEndIndex() {
      if (!this.auditTrailList.length) {
        return 0
      } else {
        return this.pageStartIndex + this.itemsPerPage <= this.totalListCount
          ? this.pageStartIndex + this.itemsPerPage - 1
          : this.totalListCount
      }
    },
  },
unmounted() {
    this.handleClose();
}
}
</script>
<style>
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>