/**
 * Validation Rules Mixin
 * Note : Do not modify anything inside the file without asking senior dev.
 * Note : Can only be modifed by author or mentioned modifiers
 * @author Ghanshyam Dutt
 * @modifier Surendra Waso, Sneha Narvekar
 */
import moment from 'moment';
//standard/default messages
const standardMessages = {
    zeroNotAllowed: 'This field should be non zero and required',
    empty: "This Field is required.",
    invalid: "This Field is invalid.",
    invalidEmail: "Email is invalid",
    invalidPAN: "PAN Number is invalid",
    invalidGSTNumber: "GST Number is invalid.",
    invalidEmails: "Emails are invalid",
  };
  
  //standard/default regular expressions
  const standardRegex = {
    alphanum: new RegExp(/^[a-zA-Z0-9 ]*$/),
    alpha: new RegExp(/^[a-zA-Z ]*$/),
    nums: new RegExp(/^[0-9 ]*$/),
    decimal: new RegExp(/^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/),
    emailValidation: new RegExp(/.+@.+\..+/),
    panValidation: new RegExp(/[A-Z]{5}[0-9]{4}[A-Z]{1}$/),
    gstinValidation: new RegExp(
      /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/
    ), // Only Valid For India (GST number),
    multiEmailValidation: new RegExp(/^(([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)(\s*,\s*|\s*$))*$/)
  };
  
  export default {
    methods: {
      /**
       * check for empty fields
       * @param {*} value
       * @param {*} field
       * @param {*} message
       * @returns
       */
      requiredField(value, field = null, message = null) {
        if(value === null) return true;
        if(value !== null && (typeof value  === 'string' || typeof value  === 'number')) {
          value  = value.toString();
        }
        if (field != null && (!value && value.length == 0)) return `${field} is required.`;
        return (value != null && value.length > 0) || message || standardMessages.empty;
      },
      requiredArrayField(value, field = "", message = "") {
        if (field != "" && !value.length) return `${field} is required.`;
        return !!value.length || message || standardMessages.empty;
      },
  
      requiredNumberField(value, field = null, message = null) {
        if(value === null) return true;
        if(value !== null && (typeof value  === 'string' || typeof value  === 'number')) {
          value  = value.toString();
        }
        if (field != null && (!value && value.length == 0)) return `${field} is required.`;
        return (value && value != 0) || message || standardMessages.zeroNotAllowed;
      },
      /**
       * check for any regular expression or some specific regular expression
       * where regex === 'alpha' , 'alphanum', 'nums'
       * @param {*} value
       * @param {*} field
       * @param {*} message
       * @returns
       */
      matchRegex(value, regex = "", field = "", message = "") {
        if (value) {
          // default regex : alphanum ,nums, alpha
          if (!!standardRegex[regex] && !standardRegex[regex].test(value)) {
            if (field != "") return `${field} is invalid.`;
            return message || standardMessages.invalid;
          }
  
          //custom regular expression
          if (!standardRegex[regex] && !regex.test(value)) {
            if (field != "") return `${field} is invalid.`;
            return message || standardMessages.invalid;
          }
        }
        return true;
      },
      /**
       * validate string length
       * @param {*} value
       * @param {*} { min, max, exact}
       * @param {*} message
       * @returns
       */
      validateLength(value, argument, field = "", message) {
        if (value) {
          let valueLength = value.toString().length;
          if (argument.min && valueLength < parseInt(argument.min)) {
            if (message) return message; //custom message
            if (field != "")
              return `${field} requires minimum ${argument.min} characters.`; //field name in message
            return `Minimum ${argument.min} characters required.`; //default message
          }
          if (argument.max && valueLength > parseInt(argument.max)) {
            if (message) return message; //custom message
            if (field != "")
              return `${field} requires maximum ${argument.max} characters.`; //field name in message
            return `Maximum ${argument.max} characters required.`; //default message
          }
          if (argument.exact && valueLength !== parseInt(argument.exact)) {
            if (message) return message; //custom message
            if (field != "")
              return `${field} requires ${argument.exact} characters.`; //field name in message
            return `Field requires ${argument.exact} characters.`; //default message
          }
        }
        return true;
      },
      /**
       * validate email address
       * @param {*} value
       * @param {*} field
       * @param {*} message
       * @returns
       */
      validateEmail(value, field = "", message = "") {
        if (value && !standardRegex.emailValidation.test(value)) {
          if (field != "") return `${field} is invalid.`;
          return message || standardMessages.invalidEmail;
        }
        return true;
      },
      /**
       * validate multi email address
       * @param {*} value
       * @param {*} field
       * @param {*} message
       * @returns
       */
      validateMultiEmail(value, field = "", message = "") {
        if (value && !standardRegex.multiEmailValidation.test(value)) {
          if (field != "") return `${field} is invalid.`;
          return message || standardMessages.invalidEmails;
        }
        return true;
      },
      /**
       * validate pan Number
       * @param {*} value
       * @param {*} field
       * @param {*} message
       * @returns
       */
      validatePanNumber(value, field = "", message = "") {
        if (value && !standardRegex.panValidation.test(value)) {
          if (field != "")  return `${field} is invalid.`;
          return message || standardMessages.invalidPAN;
        }
        return true;
      },
      /**
       * Validate GST Number Format (with PAN or without PAN)
       * @param {*} value
       * @param {*} field
       * @param {*} message
       * @param {*} panNumber
       * @returns
       */
      validateGSTNumber(value, field = "", message = "", panNumber = "") {
        const returnMessage = () => {
          if (field != "") return `${field} is invalid.`;
          return message || standardMessages.invalidGSTNumber;
        };
        // avoiding code redundancy using helper function
        if (value && !standardRegex.gstinValidation.test(value)) {
          if (panNumber && value.match(panNumber)) {
            return returnMessage();
          }
          return returnMessage();
        }
        return true;
      },
      /**
       * To match two values (should be same)
       * @param {*} value
       * @param {*} value2
       * @param {*} field1
       * @param {*} field2
       * @param {*} message
       */
      shouldBeSame(value1, value2, message = "") {
        if (value1 && value2) {
          return value1 === value2 || message || "Both values must match.";
        }
        return true;
      },
      /**
       * To match two values (should not be same)
       * @param {*} value
       * @param {*} value2
       * @param {*} field1
       * @param {*} field2
       * @param {*} message
       */
      shouldNotBeSame(value1, value2, message = "") {
        if (value1 && value2) {
          return value1 !== value2 || message || "Both values can't be same.";
        }
        return true;
      },
  
      // To allow only number 
      isNumber(evt) {
          evt = (evt) ? evt : window.event;
          var charCode = (evt.which) ? evt.which : evt.keyCode;
          if (charCode > 31 && (charCode < 48 || charCode > 57)) {
              evt.preventDefault();
          }
          return true;
      },
      // To allow only alphanumeric 
      onlyAlphaNumeric(e){
          var code = ('charCode' in e) ? e.charCode : e.keyCode;
          if (
              !(code == 32 || code == 0) && // whitespaces
              !(code > 47 && code < 58) && // numeric (0-9)
              !(code > 64 && code < 91) && // upper alpha (A-Z)
              !(code > 96 && code < 123)) { // lower alpha (a-z)
              e.preventDefault();
          }
          return true;
      },
  
      // To allow only apha 
      onlyAlpha(e){
          var code = ('charCode' in e) ? e.charCode : e.keyCode;
          if (
              !(code == 32 || code == 0) && // whitespaces
              // !(code > 47 && code < 58) && // numeric (0-9)
              !(code > 64 && code < 91) && // upper alpha (A-Z)
              !(code > 96 && code < 123)) { // lower alpha (a-z)
              e.preventDefault();
          }
          return true;
      },
  
      onlyForCurrency ($event, value, percentField = false) {
        // console.log($event, value); //keyCodes value
        let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
        // only allow number and one dot
        if ((keyCode < 48 || keyCode > 57) && (keyCode !== 46 || value.indexOf('.') != -1)) { // 46 is dot
            $event.preventDefault();
        }
        // restrict to 2 decimal places
        if(value!=null && value.indexOf('.')>-1 && (value.split('.')[1].length > 1)){
            $event.preventDefault();
        }
      },
  
      // helper function to round off
      roundOffValue(value){
          if(value && !isNaN(parseFloat(value))) {
              return parseFloat(parseFloat(value).toFixed(2))
          }
          return 0
      },
      addressValidation(v) {
        if (v && /[,;:|~"-]/g.test(v)) {
          return "This field is not valid";
        } else {
          return true;
        }
      },
      dateFormat(date) {
        return date ? moment.utc(date).format("DD/MM/YYYY HH:mm") : "";
      },
      limitTextLength(name, len) {
        return name && name.length > len ? name.substring(0, len + 1).concat("...") : name;
      },
      matchPanAndGst(pan, gst, message = "") {
        if (pan && gst) {
          let slice = gst.substr(2, 10);
          return pan === slice || message || "PAN number should be part of GST number.";
        }
        return true;
      },
    },
  };