import { render, staticRenderFns } from "./MemberDetailsTab.vue?vue&type=template&id=667be702"
import script from "./MemberDetailsTab.vue?vue&type=script&lang=js"
export * from "./MemberDetailsTab.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports