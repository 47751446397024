<template>
  <div>
    <v-container fluid>
      <v-form ref="form">
      <v-layout row>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              External Radiology Lab Details Available
            </v-text>
            <v-select
              :items="['Yes', 'No']"
              label="Select"
              v-model="externalRadiologyLabDetails.isRadiologyDetailsAvelable"
              :rules="rules.mandatoryrule"
              solo
              item-text="state"
              item-value="state"
              class="rounded-0"
              @change="toggleAllFields(externalRadiologyLabDetails.isRadiologyDetailsAvelable)"
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Radiology Lab Name
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="externalRadiologyLabDetails.radioLogyDetails.radiologyLabName"
              maxlength="100"
              counter="100"
              class="rounded-0"
              aria-required="true"
              :disabled="disableAllFields"
              :rules="!disableAllFields && [required()]"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Radiology Address
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="externalRadiologyLabDetails.radioLogyDetails.radiologyLabAddress"
              maxlength="200"
              counter="200"
              class="rounded-0"
              :rules="!disableAllFields && [required()]"
              :disabled="disableAllFields"
              aria-required="true"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Radiology State
            </v-text>
            <v-text-field
              label="Enter..."
              v-model="externalRadiologyLabDetails.radioLogyDetails.state"
              :rules="!disableAllFields && rules.mandatoryrule"
              solo
              item-text="state"
              :disabled="disableAllFields"
              item-value="state"
              class="rounded-0"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Radiology City
            </v-text>
            <v-text-field
              label="Enter..."
              v-model="externalRadiologyLabDetails.radioLogyDetails.city"
              :rules="!disableAllFields && rules.mandatoryrule"
              solo
              item-text="state"
              :disabled="disableAllFields"
              item-value="state"
              class="rounded-0"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Radiology PIN Code
            </v-text>
            <v-autocomplete
              :items="pincodes"
              label="Enter to search..."
              v-model="pinNumber"
              :rules="!disableAllFields && rules.mandatoryrule"
              solo
              maxlength="6"
              :search-input.sync="searchPinCodes"
              item-text="locpincode"
              :disabled="disableAllFields"
              item-value="locpincode"
              class="rounded-0"
              @change="pinCodeChangeHandler"
            >
            </v-autocomplete>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Radiologist Name
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="externalRadiologyLabDetails.radioLogyDetails.radiologyName"
              maxlength="100"
              :disabled="disableAllFields"
              counter="100"
              class="rounded-0"
              aria-required="true"
              :rules="!disableAllFields && [required()]"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Radiologist State Registered
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="externalRadiologyLabDetails.radioLogyDetails.registerdState"
              maxlength="20"
              :disabled="disableAllFields"
              counter="20"
              class="rounded-0"
              aria-required="true"
              :rules="!disableAllFields && [required()]"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Radiologist Registration No
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="externalRadiologyLabDetails.radioLogyDetails.registrationNumber"
              maxlength="20"
              counter="20"
              class="rounded-0"
              :disabled="disableAllFields"
              aria-required="true"
              :rules="!disableAllFields && [required()]"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md4 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Remarks
            </v-text>
            <v-textarea
              solo
              label="Enter..."
              v-model="externalRadiologyLabDetails.remarks"
              maxlength="5000"
              counter="5000"
              class="rounded-0"
              rows="2"
              aria-required="true"
              :rules="[required()]"
            >
            </v-textarea>
          </v-layout>
        </v-flex>
      </v-layout>
      </v-form>
    </v-container>
    <InvestigationFooterCard 
      :tabno="sno" :formdata="getFormData" 
      ref="footerCardRef"
      @validateTabForms="validateTabForms"
      :nonMandatoryFields="nonMandatoryFields" 
      :inputFieldCorrectlyFilled="true"
      :enableFieldObject="getEnableFieldObject"
      :componentName="'externalRadiologyLabDetails'"
      :siuWorkItemId="siuWorkItemId"
      :tabFormDataId="tabFormDataId"
    />
  </div>
</template>
<script>
  import InvestigationFooterCard from './InvestigationFooterCard.vue';
  import {getPrePopulatedData,getAllPinCodes} from '../apiservices/Investigation';
  export default {
    name: 'ExternalRadiology',
    data() {
        return {
            pinNumber:null,
            disableAllFields:false,
            searchPinCodes:'',
            pincodes: [],
            sno: 8,
            lossStatesList: ['A'],
            validationmessage: "This field is required",
            nonMandatoryFields: [],
            externalRadiologyLabDetails: {
              isTabSelected: true,
              isRadiologyDetailsAvelable: "",
              radioLogyDetails: {
                radiologyLabName: '',
                radiologyLabAddress: '',
                registerdState:'',
                state: '',
                city: '',
                pinCode: '',
                radiologyName: '',
                registrationNumber: '',
                registerdState: '',
              },
              remarks: "",
            },
            enableFieldsArr: [],
            rules: {
                mandatoryrule: [(v) => !!v || this.validationmessage],
            },
            required(){
              return (v) => (v && v.length > 0) || "This Field is Required";
            }
        }
    },
    components: {
      InvestigationFooterCard 
    },
    props: ['prepoulatedData', "siuWorkItemId", "tabFormDataId"],
  created() {
    let allKeys = Object.keys(this.prepoulatedData);
    if (this.prepoulatedData?.radioLogyDetails) {
      let internalKeys = Object.keys(this.prepoulatedData.radioLogyDetails);
      internalKeys.forEach(key => {allKeys.push(key)});
      this.externalRadiologyLabDetails.isRadiologyDetailsAvelable = this.prepoulatedData.isRadiologyDetailsAvelable;
      this.disableAllFields = this.prepoulatedData.isRadiologyDetailsAvelable === 'No' ? true : false;
      this.externalRadiologyLabDetails.remarks = this.prepoulatedData.remarks;
      allKeys.forEach(key => {
        if(key == 'pinCode'){
          this.pincodes = [this.prepoulatedData.radioLogyDetails[key]]
          this.pinNumber = this.prepoulatedData.radioLogyDetails[key]
        }
        this.externalRadiologyLabDetails.radioLogyDetails[key] = this.prepoulatedData.radioLogyDetails[key];
      });
    }
  },
    watch:{
      async searchPinCodes(value) {
        if(value === null || value == undefined || value == '' || value.length < 3){
            this.pincodes = []
        }
      if (value && value.length > 2) {
        this.pincodes = await getAllPinCodes(value.split(" - ")[0])
      }
    },
    },
  computed: {
    emailRules2(v) {
      if (v.length !== 0) {
        console.log("testing", /^[0-9 ]+$/.test(v));
        if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,6})+$/.test(v)) {
          return "E-mail must be valid";
        }
      }
    },
    getFormData() {
      return {externalRadiologyLabDetails: this.$data.externalRadiologyLabDetails};
    },
    getEnableFieldObject() {
      return this.enableFieldsArr = this.isRadiologyDetailsAvelable === 'Yes' ? {} : {remarks: this.remarks};
    }
  },
//   mounted() {
//     let found = this.items.find(el => el.sno == 20);
//     if(!found){
//       this.items.push({sno: 20, name: "View / Upload Documents"});
//     }
//     console.log("INDEX NUMBER 20", found);
    
//   },
  methods: {
    validateTabForms(){
      if(!this.$refs.form.validate()){
        this.$refs.form.validate() 
        this.showToast("Please fill all mandatory fields", this.TOST().WARNING);
        return
      }else{
        this.$refs.footerCardRef.interimSave();
      }
    },
    pinCodeChangeHandler(v) {
      const foundData = this.pincodes.find((item) => item.pinCode === v.split(" - ")[0]);
      this.externalRadiologyLabDetails.radioLogyDetails.state = foundData.stateName;
      this.externalRadiologyLabDetails.radioLogyDetails.city = foundData.cityDistrictName;
      this.externalRadiologyLabDetails.radioLogyDetails.pinCode = this.pinNumber ? Number(this.pinNumber.split(' - ')[0]) : null;
    },
    toggleAllFields(val){
      if(val == 'No'){
        this.disableAllFields = true;
        this.clearAllFields();
      }else{    
        this.disableAllFields = false  
      }
    },
    clearAllFields(){
      this.externalRadiologyLabDetails.radioLogyDetails.radiologyLabName = '';
      this.externalRadiologyLabDetails.radioLogyDetails.radiologyLabAddress = '';
      this.externalRadiologyLabDetails.radioLogyDetails.state = '';
      this.externalRadiologyLabDetails.radioLogyDetails.city = '';
      this.externalRadiologyLabDetails.radioLogyDetails.pinCode = null;
      this.externalRadiologyLabDetails.radioLogyDetails.radiologyName = '';
      this.externalRadiologyLabDetails.radioLogyDetails.registerdState = '';
      this.externalRadiologyLabDetails.radioLogyDetails.registrationNumber = '';
      this.pinNumber = null;
    },
    dragleave(event) {
      this.visualEffect = false
    },
    browseCancel() {
      //to select attachments
    //   document.getElementById("cancelchequeupload").click()
    },
    dragover(event) {
      event.preventDefault()
      this.visualEffect = true
    },
    drop(event) {
      event.preventDefault()
    //   this.selectFile() // Trigger the onChange event manually
      this.visualEffect = false
    },
    computedDate(name) {
      return this[name] ? moment(this[name]).format("DD/MM/YYYY") : "";
    },
    goToPreviousTab() {
      this.sno--;
      let btnNumber = "myBtn" + this.sno;
      const elem = this.$refs[btnNumber];
      elem[0].$el.click();
    },
    goToNextTab() {
      this.sno++;
      console.log('this', this.$children.$options);
      let btnNumber = "myBtn" + this.sno;
      const elem = this.$refs[btnNumber];
      elem[0].$el.click();
    },
    switchTabs(sno, name) {
      this.sno = sno;
      this.tabName = name;
    },
    submit() {
      this.isAlertView = true;
    },
    accept() {
      this.isAlertView = true;
    },
    saveData() {},
    alphaNumeric(fieldName, name, mandatory=true) {
      console.log('fieldName', fieldName, name);
      if (fieldName?.length > 0) {
        return (v) => /^[a-zA-Z0-9]+$/.test(v) || "Enter valid " + name;
      }
    },
    numericOnly() {
      return (v) => /^[0-9 ]+$/.test(v) || `Only Numbers are allowed.`;
    },
    onSubmit() {},
    browse() {},
    selectFile() {},
  },
} 
</script>