<template>
  <v-app >
    <v-form
      ref="officeForm"
      @submit.prevent="submitDetails"
    >
      <v-row class="row_line">
        <v-flex xs2 class="mr-2" v-if="aigMasterForm.aigCode">
          <v-layout column class="mx-2 my-2">
            <p class="font-weight-bold cstm-clr-pr-quick">
              AIG Code <span class="mandatory_asterisk"> * </span>
            </p>
            <v-text-field
              v-model="aigMasterForm.aigCode"
              placeholder="Enter.."
              class="rounded-0"
              solo
              dense
              disabled
            ></v-text-field>
          </v-layout>
        </v-flex>

        <v-flex xs2 class="mr-2">
          <v-layout column class="mx-2 my-2">
            <p class="font-weight-bold cstm-clr-pr-quick">
              Name of AIG Office <span class="mandatory_asterisk"> * </span>
            </p>
            <v-text-field
              v-model="aigMasterForm.nameOfAigOffice"
              placeholder="Enter.."
              class="rounded-0"
              solo
              dense
              :rules="[officeNameRulesFunc(aigMasterForm.nameOfAigOffice)]"
              :maxlength="max100"
            ></v-text-field>
          </v-layout>
        </v-flex>
        <v-flex xs3>
        </v-flex>
        <v-flex xs3>
        </v-flex>
        <v-flex xs3>
        </v-flex>
      </v-row>
      <v-divider></v-divider>

      <v-row  class="mb-2">
        <v-flex xs12>
          <v-layout
            column
            class="mx-2 mt-2 cust_pr_quick cust-rmvpd-pr-quick font-weight-bold aigOfflicText">
            AIG OFFICE: UNIVERSAL
          </v-layout>
        </v-flex>
      </v-row>

      <v-row class="row_line addcounteyRow">
        <v-btn
          medium
          class="addcountryButton"
          @click="showCountryOpt = !showCountryOpt"
          v-if="!showCountryOpt"
        >
          <v-icon class="mr-2"> mdi-plus </v-icon>
          Add Country
        </v-btn>
        <v-flex xs2 v-if="showCountryOpt">
          <v-layout column class="mx-2 my-2 positionRelative">
            <!-- <p class="font-weight-bold cstm-clr-pr-quick">
                            
                        </p> -->

              <v-autocomplete
                            v-model="selectedCountryList"
                            :items="countryList"
                            item-text="countryName"
                            class="rounded-0"    
                            label="Country"
                            solo 
                            dense 
                            allow-overflow:false
                            @change="updateSelectedCountryList($event)"
                            @blur="showCountryOpt = false"
                        ></v-autocomplete>     
          </v-layout>

            <!--Start Vlist change, 22 Feb 2021 
            <v-btn
              medium
              style="
                margin: 10px;
                padding: 5px;
                background-color: #e46a25;
                color: #ffffff;
                text-transformation: none;
              "
              @click="hideCountryList"
            >
              Hide Country List
            </v-btn>

            <v-text-field
              label="Country"
              class="mx-3 rounded-0"
              depressed
              solo
              @click.stop
              v-model="search"
              clearable
              @click:clear="clearSearch"
              xs2
            >
            </v-text-field>
          </v-layout>
          <v-card
            class="mx-auto"
            max-width="300"
            style="
              max-width: 272px;
              position: absolute;
              z-index: 156;
              top: 300px;
            "
            xs2
          >
            <v-list max-width="300"  max-height="200" style="overflow-y: scroll">
              <v-list-item-group  v-model="selectedCountryList" multiple>
                <template v-for="(item, i) in filteredItems">
                  <v-list-item
                    :key="`item-${i}`"
                    :value="item.countryName"                   
                    @click="updateSelectedCountryList(item.countryName)"
                  >
                    <template v-slot:default="{ active }">
                      <v-list-item-action>
                        <v-checkbox
                          :input-value="active"
                          color="deep-purple accent-4"
                        ></v-checkbox>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title
                         style="font-size: 14px !important"
                          v-text="item.countryName"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-list-item>
                </template>
              </v-list-item-group>
            </v-list>
          </v-card>
          End Vlist change, 22 Feb 2021 -->
        </v-flex>

        <v-flex
          xs10
          class="countryListFlex">
          <!-- <v-flex style="overflow:auto;"> -->
          <v-btn-toggle v-model="toggledCountry" active-class="active_btn">
            <v-btn
              medium
              dense
              class="countrylistAllbuttons"
              v-for="(item, index) in aigMasterForm.originDetails"
              :key="item.countryName"
              @click="toggleToCountry(item.countryName, index)"
              :class="isBtnActive(index)"
            >
              {{ item.countryName }}
            </v-btn>
          </v-btn-toggle>
        </v-flex>
      </v-row>
      <v-divider></v-divider>
      <!-- Start of Countrywise data -->
      <div
        v-for="(officeDetails, index) in aigMasterForm.originDetails"
        :key="officeDetails.countryName"
      >
        <div v-if="toggledCountry == index">
          <v-row class="row_line">
            <!-- Only for testing start, Surendra(22/02/2021) -->
            <!-- <v-flex xs2 class="mr-2">
              Selected Country - {{ officeDetails.countryName }} toggledCountry
              - {{ toggledCountry }} Index - {{ index }} vif -
              {{ toggledCountry == index }}
            </v-flex> -->
            <!-- Only for testing end, Surendra(22/02/2021) -->
            <v-flex xs2  class="mr-2">
              <v-layout column class="mx-2 my-2">
                <p class="font-weight-bold cstm-clr-pr-quick">
                  Address Line 1 <span class="mandatory_asterisk"> * </span>
                </p>
                <v-text-field
                  v-model="officeDetails.addressLine1"
                  placeholder="Enter.."
                  class="rounded-0"
                  solo
                  dense
                  :maxlength="max50"
                  :rules="[address1RulesFunc(officeDetails.addressLine1)]"
                ></v-text-field>
              </v-layout>
            </v-flex>

            <v-flex xs2 class="mr-2">
              <v-layout column class="mx-2 my-2">
                <p class="font-weight-bold cstm-clr-pr-quick">
                  Address Line 2 <span class="mandatory_asterisk"> * </span>
                </p>
                <v-text-field
                  v-model="officeDetails.addressLine2"
                  placeholder="Enter.."
                  class="rounded-0"
                  solo
                  dense
                  :maxlength="max50"
                  :rules="[address2Rules(officeDetails.addressLine2)]"
                ></v-text-field>
              </v-layout>
            </v-flex>

            <v-flex xs2 class="mr-2">
              <v-layout column class="mx-2 my-2">
                <p class="font-weight-bold cstm-clr-pr-quick">
                  Address Line 3 <span class="mandatory_asterisk"> * </span>
                </p>
                <v-text-field
                  v-model="officeDetails.addressLine3"
                  placeholder="Enter.."
                  class="rounded-0"
                  solo
                  dense
                  :maxlength="max50"
                  :rules="[address3Rules(officeDetails.addressLine3)]"
                ></v-text-field>
              </v-layout>
            </v-flex>
            <v-flex xs2 class="mr-2">
              <v-layout column class="mx-2 my-2"> </v-layout
            ></v-flex>
          </v-row>
          <v-divider></v-divider>

          <v-row class="row_line">
            <v-flex xs2 class="mr-2">
              <v-layout column class="mx-2 my-2">
                <div class="text-uppercase font-weight-bold title-clr-pr">
                  Contact Person 1
                </div>
                <v-divider></v-divider>
              </v-layout>
            </v-flex>

            <v-flex xs2 class="mr-2">
              <v-layout column class="mx-2 my-2">
                <p class="font-weight-bold cstm-clr-pr-quick">
                  Name <span class="mandatory_asterisk"> * </span>
                </p>
                <v-text-field
                  v-model="officeDetails.contactPerson1.name"
                  placeholder="Enter.."
                  class="rounded-0"
                  solo
                  dense
                  :maxlength="max50"
                  :rules="[nameRules(officeDetails.contactPerson1.name)]"
                ></v-text-field>
              </v-layout>
            </v-flex>

       <v-flex xs1>
            <v-layout column class="mx-2 my-2">
              <p class="font-weight-bold cstm-clr-pr-quick">ISD Code</p>
              <v-text-field
                placeholder="Enter..."
                :maxLength="5"
                solo
                dense
                v-model="officeDetails.contactPerson1.officeNum.isd"
                :rules="[validateIsd(officeDetails.contactPerson1.officeNum.isd)]"
                class="forminput">
                <template v-slot:prepend-inner class="mx-0 my-0">
                  <v-icon class="iconstyle">mdi-phone</v-icon>
                </template>
              </v-text-field>
            </v-layout>
          </v-flex>

             <v-flex xs1 class="mr-9">
              <v-layout column class="mx-2 my-2">
                <p class="font-weight-bold cstm-clr-pr-quick">
                  Office No. <span class="mandatory_asterisk"> * </span>
                </p>
                <v-text-field
                  v-model="officeDetails.contactPerson1.officeNum.number"
                  placeholder="Enter.."
                  class="rounded-0"
                  solo
                  dense
                  :maxlength="max20"
                  :rules="[
                    officeNumRules(officeDetails.contactPerson1.officeNum.number),
                  ]"
                ></v-text-field>
              </v-layout>
            </v-flex>
           <v-flex xs1>
            <v-layout column class="mx-2 my-2">
              <p class="font-weight-bold cstm-clr-pr-quick">ISD Code</p>
              <v-text-field
                placeholder="Enter..."
                :maxLength="5"
                solo
                dense
                v-model="officeDetails.contactPerson1.mobileNum.isd"
                :rules="[validateIsd(officeDetails.contactPerson1.mobileNum.isd)]"
                class="forminput">
                <template v-slot:prepend-inner class="mx-0 my-0">
                  <v-icon class="iconstyle">mdi-phone</v-icon>
                </template>
              </v-text-field>
            </v-layout>
          </v-flex>
            <v-flex xs class="mr-2">
              <v-layout column class="mx-2 my-2" >
                <p class="font-weight-bold cstm-clr-pr-quick">Mobile No.</p>
                <v-text-field
                  v-model="officeDetails.contactPerson1.mobileNum.number"
                  placeholder="Enter.."
                  class="rounded-0"
                  solo
                  dense
                  :maxlength="max20"
                  :rules="[
                    mobileNumRules(officeDetails.contactPerson1.mobileNum.number),
                  ]"
                >
                  <template v-slot:prepend-inner>
                    <!-- <img width="20" height="20" src="../../assets/images/phone.png" > class="preicon_background"-->
                    <v-icon class="iconstyle"> mdi-phone </v-icon>
                  </template>
                </v-text-field>
              </v-layout>
            </v-flex>

            <v-flex xs2 class="mr-2">
              <v-layout column class="mx-2 my-2">
                <p class="font-weight-bold cstm-clr-pr-quick">
                  Email <span class="mandatory_asterisk"> * </span>
                </p>
                <v-text-field
                  v-model="officeDetails.contactPerson1.email"
                  placeholder="Enter.."
                  class="rounded-0"
                  solo
                  dense
                  :maxlength="max50"
                  :rules="[emailRules(officeDetails.contactPerson1.email)]"
                >
                  <template v-slot:prepend-inner class="preicon_background">
                    <!-- <img width="20" height="20" src="../../assets/images/mail.png" > -->
                    <v-icon class="iconstyle"> mdi-email </v-icon>
                  </template></v-text-field
                >
              </v-layout>
            </v-flex>
          </v-row>
          <v-divider></v-divider>

          <v-row class="row_line">
            <v-flex xs2 class="mr-2">
              <v-layout column class="mx-2 my-2">
                <div class="text-uppercase font-weight-bold title-clr-pr">
                  Contact Person 2
                </div>
                <v-divider></v-divider>
              </v-layout>
            </v-flex>

            <v-flex xs2 class="mr-2">
              <v-layout column class="mx-2 my-2">
                <p class="font-weight-bold cstm-clr-pr-quick">Name</p>
                <v-text-field
                  v-model="officeDetails.contactPerson2.name"
                  placeholder="Enter.."
                  class="rounded-0"
                  solo
                  dense
                  :maxlength="max50"
                  :rules="[name2RuleFunc(officeDetails.contactPerson2.name)]"
                ></v-text-field>
              </v-layout>
            </v-flex>

             <v-flex xs1>
            <v-layout column class="mx-2 my-2">
              <p class="font-weight-bold cstm-clr-pr-quick">ISD Code</p>
              <v-text-field
                placeholder="Enter..."
                :maxLength="5"
                solo
                dense
                v-model="officeDetails.contactPerson2.officeNum.isd"
                :rules="[validateIsd(officeDetails.contactPerson2.officeNum.isd)]"
                class="forminput">
                <template v-slot:prepend-inner class="mx-0 my-0">
                  <v-icon class="iconstyle">mdi-phone</v-icon>
                </template>
              </v-text-field>
            </v-layout>
          </v-flex>

            <v-flex xs1 class="mr-9">
              <v-layout column class="mx-2 my-2">
                <p class="font-weight-bold cstm-clr-pr-quick">Office No.</p>
                <v-text-field
                  v-model="officeDetails.contactPerson2.officeNum.number"
                  placeholder="Enter.."
                  class="rounded-0"
                  solo
                  dense
                  :maxlength="max20"
                  :rules="[
                    office2NumRules(officeDetails.contactPerson2.officeNum.number),
                  ]"
                ></v-text-field>
              </v-layout>
            </v-flex>
              
              <v-flex xs1>
            <v-layout column class="mx-2 my-2">
              <p class="font-weight-bold cstm-clr-pr-quick">ISD Code</p>
              <v-text-field
                placeholder="Enter..."
                :maxLength="5"
                solo
                dense
                 v-model="officeDetails.contactPerson2.mobileNum.isd"
                 :rules="[validateIsd(officeDetails.contactPerson2.mobileNum.isd)]"
                class="forminput">
                <template v-slot:prepend-inner class="mx-0 my-0">
                  <v-icon class="iconstyle">mdi-phone</v-icon>
                </template>
              </v-text-field>
            </v-layout>
          </v-flex>

            <v-flex xs2 class="mr-2">
              <v-layout column class="mx-2 my-2">
                <p class="font-weight-bold cstm-clr-pr-quick">Mobile No.</p>
                <v-text-field
                  v-model="officeDetails.contactPerson2.mobileNum.number"
                  placeholder="Enter.."
                  class="rounded-0"
                  solo
                  dense
                  :maxlength="max20"
                  :rules="[
                    mobile2NumRules(officeDetails.contactPerson2.mobileNum.number),
                  ]"
                >
                  <template v-slot:prepend-inner class="preicon_background">
                    <!-- <img width="20" height="20" src="../../assets/images/phone.png" > -->
                    <v-icon class="iconstyle"> mdi-phone </v-icon>
                  </template></v-text-field
                >
              </v-layout>
            </v-flex>

            <v-flex xs2 class="mr-2">
              <v-layout column class="mx-2 my-2">
                <p class="font-weight-bold cstm-clr-pr-quick">Email</p>
                <v-text-field
                  v-model="officeDetails.contactPerson2.email"
                  placeholder="Enter.."
                  class="rounded-0"
                  solo
                  dense
                  :maxlength="max50"
                  :rules="[email2Rules(officeDetails.contactPerson2.email)]"
                >
                  <template v-slot:prepend-inner class="preicon_background">
                    <!-- <img width="20" height="20" src="../../assets/images/mail.png" > width="20" height="20"  -->
                    <v-icon class="iconstyle"> mdi-email </v-icon>
                  </template>
                </v-text-field>
              </v-layout>
            </v-flex>
          </v-row>
          <v-divider></v-divider>

          <v-row class="row_line">
            <!-- Validity From  -->
            <v-flex xs2>
              <v-layout
                column
                class="mx-2 mt-2 cust_pr_quick cust-rmvpd-pr-quick"
              >
                <p class="font-weight-bold cstm-clr-pr-quick">
                  PE Validity date
                </p>

                <v-menu
                  ref="calender"
                  :close-on-content-click="false"
                  v-model="bankCalenderDateFrom"
                  :nudge-right="40"
                  transition="scale-transition"
                  max-width="290px"
                  min-width="290px"
                  class="rounded-0"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-bind="attrs"
                      v-on="on"
                      v-on:focus='onPEFocus'
                      label="dd/mm/yyyy"
                      readonly

                      :value="computedBankDateForm"
                      solo
                      class="rounded-0"
                    >
                      <template v-slot:prepend-inner>
                        <v-icon
                          @click="bankCalenderDateFrom = true"
                          class="iconstyle"
                        >
                          mdi-calendar
                        </v-icon>
                      </template>
                      <!-- <div  slot="append-inner" style="background-color:#F7F7F7;  padding: 8px !important"><img src="../assets" /></div>     -->
                      <template v-slot:append>
                        <!-- <img @click="bankCalenderDateFrom = true"  width="20" height="20" src="../../assets/images/Group10706.png" > -->
                        <v-icon @click="officeDetails.peValidityDate = ''"
                          >mdi-close</v-icon
                        >
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="officeDetails.peValidityDate"
                    @input="bankCalenderDateFrom = false"
                    no-title
                  ></v-date-picker>
                </v-menu>
              </v-layout>
            </v-flex>

            <v-flex xs2>
              <p
                class="font-weight-bold cstm-clr-pr-quick pa-2"
              ></p>
              <v-layout
                column
                class="mx-2 mt-2 cust_pr_quick cust-rmvpd-pr-quick trcappicableLayout">
                <v-checkbox
                  v-model="officeDetails.trcApplicable"
                  @change="checkTrcApplicable"
                  color="#23B1A9"
                >
                </v-checkbox>
                <v-flex> TRC Applicable </v-flex>
              </v-layout>
            </v-flex>
            <!-- Validity To   -->
            <v-flex xs2>
              <v-layout
                column
                class="mx-2 mt-2 cust_pr_quick cust-rmvpd-pr-quick"
              >
                <p class="font-weight-bold cstm-clr-pr-quick">
                  TRC Validity date
                </p>

                <v-menu
                  ref="calender"
                  :close-on-content-click="false"
                  v-model="bankCalenderDateTo"
                  :nudge-right="40"
                  transition="scale-transition"
                  max-width="290px"
                  min-width="290px"
                  class="rounded-0"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-bind="attrs"
                      v-on="on"
                      v-on:focus="onTRCFocus"
                      label="dd/mm/yyyy"
                      :value="computedBankDateTo"
                      solo
                      class="rounded-0"
                      readonly
                    >
                      <div slot="prepend-inner">
                        <v-icon
                          @click="bankCalenderDateTo = true"
                          class="iconstyle"
                        >
                          mdi-calendar
                        </v-icon>
                      </div>
                      <!-- <div  slot="append-inner" style="background-color:#F7F7F7;  padding: 8px !important"><img src="../assets" /></div>     -->
                      <template v-slot:append>
                        <!-- <img @click="bankCalenderDateTo = true" width="20" height="20" src="../../assets/images/Group10706.png" > -->
                        <v-icon @click="officeDetails.trcValidityDate = ''"
                          >mdi-close</v-icon
                        >
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="officeDetails.trcValidityDate"
                    no-title
                    @input="bankCalenderDateTo = false"
                  ></v-date-picker>
                </v-menu>
              </v-layout>
            </v-flex>
          </v-row>

          <v-row class="mb-2">
            <v-flex xs12>
              <v-layout
                column
                class="mx-2 mt-2 cust_pr_quick cust-rmvpd-pr-quick font-weight-bold bankDeatilsLayout">
                Bank Details
              </v-layout>
            </v-flex>
          </v-row>

          <v-row class="row_line">
            <v-flex xs2 class="mr-2">
              <v-layout column class="mx-2 my-2">
                <div class="text-uppercase font-weight-bold title-clr-pr">
                  Bank Details(Payee Bank)
                </div>
                <v-divider></v-divider>
              </v-layout>
            </v-flex>

            <v-flex xs2 class="mr-2">
              <v-layout column class="mx-2 my-2">
                <p class="font-weight-bold cstm-clr-pr-quick">
                  Bank Name <span class="mandatory_asterisk"> * </span>
                </p>
                <v-text-field
                  v-model="officeDetails.payeeBank.bankName"
                  placeholder="Enter.."
                  class="rounded-0"
                  solo
                  dense
                  :maxlength="max100"
                  :rules="[bankNameRules(officeDetails.payeeBank.bankName)]"
                ></v-text-field>
              </v-layout>
            </v-flex>

            <v-flex xs2 class="mr-2">
              <v-layout column class="mx-2 my-2">
                <p class="font-weight-bold cstm-clr-pr-quick">
                  Bank Address Line 1
                  <span class="mandatory_asterisk"> * </span>
                </p>
                <v-text-field
                  v-model="officeDetails.payeeBank.bankAddressLine1"
                  placeholder="Enter.."
                  class="rounded-0"
                  solo
                  dense
                  :maxlength="max50"
                  :rules="[
                    bankAddressRules(officeDetails.payeeBank.bankAddressLine1),
                  ]"
                ></v-text-field>
              </v-layout>
            </v-flex>

            <v-flex xs2 class="mr-2">
              <v-layout column class="mx-2 my-2">
                <p class="font-weight-bold cstm-clr-pr-quick">
                  Bank Address Line 2
                  <span class="mandatory_asterisk"> * </span>
                </p>
                <v-text-field
                  v-model="officeDetails.payeeBank.bankAddressLine2"
                  placeholder="Enter.."
                  class="rounded-0"
                  solo
                  dense
                  :maxlength="max50"
                  :rules="[
                    bankAddressRules(officeDetails.payeeBank.bankAddressLine2),
                  ]"
                ></v-text-field>
              </v-layout>
            </v-flex>

            <v-flex xs2 class="mr-2">
              <v-layout column class="mx-2 my-2">
                <p class="font-weight-bold cstm-clr-pr-quick">
                  Bank Address Line 3
                  <span class="mandatory_asterisk"> * </span>
                </p>
                <v-text-field
                  v-model="officeDetails.payeeBank.bankAddressLine3"
                  placeholder="Enter.."
                  class="rounded-0"
                  solo
                  dense
                  :maxlength="max50"
                  :rules="[
                    bankAddressRules(officeDetails.payeeBank.bankAddressLine3),
                  ]"
                ></v-text-field>
              </v-layout>
            </v-flex>
          </v-row>
          <!--------------->
          <v-row class="row_line">
            <v-flex xs2 class="mr-2">
              <v-layout column class="mx-2 my-2">
                <p class="font-weight-bold cstm-clr-pr-quick">Account Number</p>
                <v-text-field
                  v-model="officeDetails.payeeBank.accountNumber"
                  placeholder="Enter.."
                  class="rounded-0"
                  solo
                  dense
                  :maxlength="max50"
                  type="password"
                  @change="compareAccNum"
                  :rules="[
                    bankAccNumRule(officeDetails.payeeBank.accountNumber),
                  ]"
                ></v-text-field>
              </v-layout>
            </v-flex>

            <v-flex xs2 class="mr-2">
              <v-layout column class="mx-2 my-2">
                <p class="font-weight-bold cstm-clr-pr-quick">
                  Re-Enter Account Number
                </p>
                <v-text-field
                  v-model="officeDetails.payeeBank.confirmAccountNumber"
                  placeholder="Enter.."
                  class="rounded-0"
                  solo
                  dense
                  :maxlength="max50"
                  @change="confirmPayeeAccNumMatch"
                  :rules="[
                    bankReAccNumRule(
                      officeDetails.payeeBank.confirmAccountNumber
                    ),
                  ]"
                ></v-text-field>
              </v-layout>
            </v-flex>

            <v-flex xs2 class="mr-2">
              <v-layout column class="mx-2 my-2">
                <p class="font-weight-bold cstm-clr-pr-quick">Swift Code</p>
                <v-text-field
                  v-model="officeDetails.payeeBank.swiftCode"
                  placeholder="Enter.."
                  class="rounded-0"
                  solo
                  dense
                  :maxlength="max50"
                  :rules="[swiftCodeRule(officeDetails.payeeBank.swiftCode)]"
                ></v-text-field>
              </v-layout>
            </v-flex>

            <v-flex xs2 class="mr-2">
              <v-layout column class="mx-2 my-2">
                <p class="font-weight-bold cstm-clr-pr-quick">IBAN Number</p>
                <v-text-field
                  v-model="officeDetails.payeeBank.ibanNumber"
                  placeholder="Enter.."
                  class="rounded-0"
                  solo
                  dense
                  :maxlength="max50"
                  :rules="[ibanCodeRule(officeDetails.payeeBank.ibanNumber)]"
                ></v-text-field>
              </v-layout>
            </v-flex>

            <v-flex xs2 class="mr-2">
              <v-layout column class="mx-2 my-2">
                <p class="font-weight-bold cstm-clr-pr-quick">Sort Code</p>
                <v-text-field
                  v-model="officeDetails.payeeBank.sortCode"
                  placeholder="Enter.."
                  class="rounded-0"
                  solo
                  dense
                  :maxlength="max50"
                  :rules="[sortCodeRule(officeDetails.payeeBank.sortCode)]"
                ></v-text-field>
              </v-layout>
            </v-flex>
          </v-row>
          <!--------------->
          <v-divider></v-divider>
          <v-row class="row_line">
            <v-flex xs2 class="mr-2">
              <v-layout column class="mx-2 my-2">
                <div class="text-uppercase font-weight-bold title-clr-pr">
                  Bank Details(CORR. Bank)
                </div>
                <v-divider></v-divider>
              </v-layout>
            </v-flex>

            <v-flex xs2 class="mr-2">
              <v-layout column class="mx-2 my-2">
                <p class="font-weight-bold cstm-clr-pr-quick">Bank Name</p>
                <v-text-field
                  v-model="officeDetails.corrBank.bankName"
                  placeholder="Enter.."
                  class="rounded-0"
                  solo
                  dense
                  :maxlength="max100"
                  :rules="[corrBankNameRules(officeDetails.corrBank.bankName)]"
                ></v-text-field>
              </v-layout>
            </v-flex>

            <v-flex xs2 class="mr-2">
              <v-layout column class="mx-2 my-2">
                <p class="font-weight-bold cstm-clr-pr-quick">
                  Bank Address Line 1
                </p>
                <v-text-field
                  v-model="officeDetails.corrBank.bankAddressLine1"
                  placeholder="Enter.."
                  class="rounded-0"
                  solo
                  dense
                  :maxlength="max50"
                  :rules="[
                    corrBankAddressRules(
                      officeDetails.corrBank.bankAddressLine1
                    ),
                  ]"
                ></v-text-field>
              </v-layout>
            </v-flex>

            <v-flex xs2 class="mr-2">
              <v-layout column class="mx-2 my-2">
                <p class="font-weight-bold cstm-clr-pr-quick">
                  Bank Address Line 2
                </p>
                <v-text-field
                  v-model="officeDetails.corrBank.bankAddressLine2"
                  placeholder="Enter.."
                  class="rounded-0"
                  solo
                  dense
                  :maxlength="max50"
                  :rules="[
                    corrBankAddressRules(
                      officeDetails.corrBank.bankAddressLine2
                    ),
                  ]"
                ></v-text-field>
              </v-layout>
            </v-flex>

            <v-flex xs2 class="mr-2">
              <v-layout column class="mx-2 my-2">
                <p class="font-weight-bold cstm-clr-pr-quick">
                  Bank Address Line 3
                </p>
                <v-text-field
                  v-model="officeDetails.corrBank.bankAddressLine3"
                  placeholder="Enter.."
                  class="rounded-0"
                  solo
                  dense
                  :maxlength="max50"
                  :rules="[
                    corrBankAddressRules(
                      officeDetails.corrBank.bankAddressLine3
                    ),
                  ]"
                ></v-text-field>
              </v-layout>
            </v-flex>
          </v-row>

          <v-row class="row_line">
            <v-flex xs2 class="mr-2">
              <v-layout column class="mx-2 my-2">
                <p class="font-weight-bold cstm-clr-pr-quick">Account Number</p>
                <v-text-field
                  v-model="officeDetails.corrBank.accountNumber"
                  placeholder="Enter.."
                  class="rounded-0"
                  solo
                  dense
                  type="password"
                  :maxlength="max50"
                  @change="compareAccNum('corrBank')"
                  :rules="[
                    corrBankAccNumRule(officeDetails.corrBank.accountNumber),
                  ]"
                ></v-text-field>
              </v-layout>
            </v-flex>

            <v-flex xs2 class="mr-2">
              <v-layout column class="mx-2 my-2">
                <p class="font-weight-bold cstm-clr-pr-quick">
                  Re-Enter Account Number
                </p>
                <v-text-field
                  v-model="officeDetails.corrBank.confirmAccountNumber"
                  placeholder="Enter.."
                  class="rounded-0"
                  solo
                  dense
                  :maxlength="max50"
                  @change="confirmCorrAccNumMatch"
                  :rules="[
                    corrBankReAccNumRule(
                      officeDetails.corrBank.confirmAccountNumber
                    ),
                  ]"
                ></v-text-field>
              </v-layout>
            </v-flex>

            <v-flex xs2 class="mr-2">
              <v-layout column class="mx-2 my-2">
                <p class="font-weight-bold cstm-clr-pr-quick">Swift Code</p>
                <v-text-field
                  v-model="officeDetails.corrBank.swiftCode"
                  placeholder="Enter.."
                  class="rounded-0"
                  solo
                  dense
                  :maxlength="max50"
                  :rules="[corrSwiftCodeRule(officeDetails.corrBank.swiftCode)]"
                ></v-text-field>
              </v-layout>
            </v-flex>

            <v-flex xs2 class="mr-2">
              <v-layout column class="mx-2 my-2">
                <p class="font-weight-bold cstm-clr-pr-quick">ABA Number</p>
                <v-text-field
                  v-model="officeDetails.corrBank.abaCode"
                  placeholder="Enter.."
                  class="rounded-0"
                  solo
                  dense
                  :maxlength="max50"
                  :rules="[corrAbaCodeRule(officeDetails.corrBank.abaCode)]"
                ></v-text-field>
              </v-layout>
            </v-flex>
          </v-row>
        </div>
      </div>
      <!-- Start Empty -->
      <!-- v-if="selectedCountryList.length == 0"  commented by gopikrishna on 22feb2021 for hide show-->
      <div v-if="showHideEmptyData">
        <v-row class="row_line">
          <v-flex xs2 class="mr-2">
            <v-layout column class="mx-2 my-2">
              <p class="font-weight-bold cstm-clr-pr-quick">
                Address Line 1 <span class="mandatory_asterisk"> * </span>
              </p>
              <v-text-field
                v-model="emptyData.addressLine1"
                placeholder="Enter.."
                class="rounded-0"
                solo
                dense
                disabled
                :rules="allRules.address1Rules"
              ></v-text-field>
            </v-layout>
          </v-flex>

          <v-flex xs2 class="mr-2">
            <v-layout column class="mx-2 my-2">
              <p class="font-weight-bold cstm-clr-pr-quick">
                Address Line 2 <span class="mandatory_asterisk"> * </span>
              </p>
              <v-text-field
                v-model="emptyData.addressLine2"
                placeholder="Enter.."
                class="rounded-0"
                solo
                dense
                disabled
                :rules="allRules.address2Rules"
              ></v-text-field>
            </v-layout>
          </v-flex>

          <v-flex xs2 class="mr-2">
            <v-layout column class="mx-2 my-2">
              <p class="font-weight-bold cstm-clr-pr-quick">
                Address Line 3 <span class="mandatory_asterisk"> * </span>
              </p>
              <v-text-field
                v-model="emptyData.addressLine3"
                placeholder="Enter.."
                class="rounded-0"
                solo
                dense
                disabled
                :rules="allRules.address3Rules"
              ></v-text-field>
            </v-layout>
          </v-flex>
          <v-flex xs2 class="mr-2">
            <v-layout column class="mx-2 my-2"> </v-layout
          ></v-flex>
        </v-row>
        <v-divider></v-divider>

        <v-row class="row_line">
          <v-flex xs2 class="mr-2">
            <v-layout column class="mx-2 my-2">
              <div class="text-uppercase font-weight-bold title-clr-pr">
                Contact Person 1
              </div>
              <v-divider></v-divider>
            </v-layout>
          </v-flex>

          <v-flex xs2 class="mr-2">
            <v-layout column class="mx-2 my-2">
              <p class="font-weight-bold cstm-clr-pr-quick">
                Name <span class="mandatory_asterisk"> * </span>
              </p>
              <v-text-field
                v-model="emptyData.contactPerson1.name"
                placeholder="Enter.."
                class="rounded-0"
                solo
                dense
                disabled
                :rules="allRules.nameRules"
              ></v-text-field>
            </v-layout>
          </v-flex>
        <v-flex xs1>
            <v-layout column class="mx-2 my-2">
              <p class="font-weight-bold cstm-clr-pr-quick">ISD Code</p>
              <v-text-field
                placeholder="Enter..."
                :maxLength="5"
                solo
                dense
                 v-model="emptyData.contactPerson1.officeNum.isd"
                  :rules="[validateIsd(emptyData.contactPerson1.officeNum.isd)]"
                class="forminput">
                <template v-slot:prepend-inner class="mx-0 my-0">
                  <v-icon class="iconstyle">mdi-phone</v-icon>
                </template>
              </v-text-field>
            </v-layout>
          </v-flex> 

          <v-flex xs1 class="mr-9">
            <v-layout column class="mx-2 my-2" >
              <p class="font-weight-bold cstm-clr-pr-quick">
                Office No. <span class="mandatory_asterisk"> * </span>
              </p>
              <v-text-field
                v-model="emptyData.contactPerson1.officeNum.number"
                placeholder="Enter.."
                class="rounded-0"
                solo
                dense
                disabled
                :rules="allRules.officeNumRules"
              ></v-text-field>
            </v-layout>
          </v-flex>
  <v-flex xs1>
            <v-layout column class="mx-2 my-2">
              <p class="font-weight-bold cstm-clr-pr-quick">ISD Code</p>
              <v-text-field
                placeholder="Enter..."
                :maxLength="5"
                solo
                dense
                v-model="emptyData.contactPerson1.mobileNum.isd"
                 :rules="[validateIsd(emptyData.contactPerson1.mobileNum.isd)]"
                class="forminput">
                <template v-slot:prepend-inner class="mx-0 my-0">
                  <v-icon class="iconstyle">mdi-phone</v-icon>
                </template>
              </v-text-field>
            </v-layout>
          </v-flex>
          <v-flex xs2 class="mr-2">
          
            <v-layout column class="mx-2 my-2">
              <p class="font-weight-bold cstm-clr-pr-quick">Mobile No.</p>
              <v-text-field
                v-model="emptyData.contactPerson1.mobileNum.number"
                placeholder="Enter.."
                class="rounded-0"
                solo
                dense
                disabled
                :rules="allRules.mobileNumRules"
              >
                <template v-slot:prepend-inner class="preicon_background">
                  <!-- <img width="20" height="20" src="../../assets/images/phone.png" > -->
                  <v-icon class="iconstyle"> mdi-phone </v-icon>
                </template>
              </v-text-field>
            </v-layout>
          </v-flex>

          <v-flex xs2 class="mr-2">
            <v-layout column class="mx-2 my-2">
              <p class="font-weight-bold cstm-clr-pr-quick">
                Email <span class="mandatory_asterisk"> * </span>
              </p>
              <v-text-field
                v-model="emptyData.contactPerson1.email"
                placeholder="Enter.."
                class="rounded-0"
                solo
                dense
                disabled
                :rules="allRules.emailRules"
              >
                <template v-slot:prepend-inner class="preicon_background">
                  <!-- <img width="20" height="20" src="../../assets/images/mail.png" > -->
                  <v-icon class="iconstyle"> mdi-email </v-icon>
                </template></v-text-field
              >
            </v-layout>
          </v-flex>
        </v-row>
        <v-divider></v-divider>

        <v-row class="row_line">
          <v-flex xs2 class="mr-2">
            <v-layout column class="mx-2 my-2">
              <div class="text-uppercase font-weight-bold title-clr-pr">
                Contact Person 2
              </div>
              <v-divider></v-divider>
            </v-layout>
          </v-flex>

          <v-flex xs2 class="mr-2">
            <v-layout column class="mx-2 my-2">
              <p class="font-weight-bold cstm-clr-pr-quick">Name</p>
              <v-text-field
                v-model="emptyData.contactPerson2.name"
                placeholder="Enter.."
                class="rounded-0"
                solo
                dense
                disabled
                :rules="allRules.name2Rules"
              ></v-text-field>
            </v-layout>
          </v-flex>
 
          <v-flex xs1>
            <v-layout column class="mx-2 my-2">
              <p class="font-weight-bold cstm-clr-pr-quick">ISD Code</p>
              <v-text-field
                placeholder="Enter..."
                :maxLength="5"
                solo
                dense
                 v-model="emptyData.contactPerson2.officeNum.isd"
                  :rules="[validateIsd(emptyData.contactPerson2.officeNum.isd)]"
                class="forminput">
                <template v-slot:prepend-inner class="mx-0 my-0">
                  <v-icon class="iconstyle">mdi-phone</v-icon>
                </template>
              </v-text-field>
            </v-layout>
          </v-flex>         
          <v-flex xs1 class="mr-9">
            <v-layout column class="mx-2 my-2">
              <p class="font-weight-bold cstm-clr-pr-quick">Office No.</p>
              <v-text-field
                v-model="emptyData.contactPerson2.officeNum.number"
                placeholder="Enter.."
                class="rounded-0"
                solo
                dense
                disabled
                :rules="allRules.officeNum2Rules"
              ></v-text-field>
            </v-layout>
          </v-flex>
 
        <v-flex xs1>
            <v-layout column class="mx-2 my-2">
              <p class="font-weight-bold cstm-clr-pr-quick">ISD Code</p>
              <v-text-field
                placeholder="Enter..."
                :maxLength="5"
                solo
                dense
                 v-model="emptyData.contactPerson2.mobileNum.isd"
                  :rules="[validateIsd(emptyData.contactPerson2.mobileNum.isd)]"
                class="forminput">
                <template v-slot:prepend-inner class="mx-0 my-0">
                  <v-icon class="iconstyle">mdi-phone</v-icon>
                </template>
              </v-text-field>
            </v-layout>
          </v-flex>

          <v-flex xs2 class="mr-2">
            <v-layout column class="mx-2 my-2">
              <p class="font-weight-bold cstm-clr-pr-quick">Mobile No.</p>
              <v-text-field
                v-model="emptyData.contactPerson2.mobileNum.number"
                placeholder="Enter.."
                class="rounded-0"
                solo
                dense
                disabled
                :rules="allRules.mobileNum2Rules"
              >
                <template v-slot:prepend-inner class="preicon_background">
                  <!-- <img width="20" height="20" src="../../assets/images/phone.png" > -->
                  <v-icon class="iconstyle"> mdi-phone </v-icon>
                </template></v-text-field
              >
            </v-layout>
          </v-flex>

          <v-flex xs2 class="mr-2">
            <v-layout column class="mx-2 my-2">
              <p class="font-weight-bold cstm-clr-pr-quick">Email</p>
              <v-text-field
                v-model="emptyData.contactPerson2.email"
                placeholder="Enter.."
                class="rounded-0"
                solo
                dense
                disabled
                :rules="allRules.email2Rules"
              >
                <template v-slot:prepend-inner class="preicon_background">
                  <!-- <img width="20" height="20" src="../../assets/images/mail.png" > width="20" height="20"  -->
                  <v-icon class="iconstyle"> mdi-email </v-icon>
                </template>
              </v-text-field>
            </v-layout>
          </v-flex>
        </v-row>
        <v-divider></v-divider>

        <v-row class="row_line">
          <!-- Validity From  -->
          <v-flex xs2>
            <v-layout
              column
              class="mx-2 mt-2 cust_pr_quick cust-rmvpd-pr-quick"
            >
              <p class="font-weight-bold cstm-clr-pr-quick">PE Validity date</p>

              <v-menu
                ref="calender"
                :close-on-content-click="false"
                disabled
                :nudge-right="40"
                transition="scale-transition"
                max-width="290px"
                min-width="290px"
                class="rounded-0"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-bind="attrs"
                    v-on="on"
                    
                    label="dd/mm/yyyy"
                    readonly
                    disabled
                    solo
                    class="rounded-0"
                  >
                    <div slot="prepend-inner">
                      <v-icon class="iconstyle"> mdi-calendar </v-icon>
                    </div>
                    <!-- <div  slot="append-inner" style="background-color:#F7F7F7;  padding: 8px !important"><img src="../assets" /></div>     -->
                    <template v-slot:append>
                      <!-- <img  width="20" height="20" src="../../assets/images/Group10706.png" > -->
                      <v-icon>mdi-close</v-icon>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker no-title></v-date-picker>
              </v-menu>
            </v-layout>
          </v-flex>

          <v-flex xs2>
            <p class="font-weight-bold cstm-clr-pr-quick pa-2"></p>
            <v-layout
              column
              class="mx-2 mt-2 cust_pr_quick cust-rmvpd-pr-quick trcappicableLayout2"
            >
              <v-checkbox v-model="emptyData.trcApplicable" disabled>
              </v-checkbox>
              <v-flex> TRC Applicable </v-flex>
            </v-layout>
          </v-flex>
          <!-- Validity To   -->
          <v-flex xs2>
            <v-layout
              column
              class="mx-2 mt-2 cust_pr_quick cust-rmvpd-pr-quick"
            >
              <p class="font-weight-bold cstm-clr-pr-quick">
                TRC Validity date
              </p>

              <v-menu
                ref="calender"
                :close-on-content-click="false"
                disabled
                :nudge-right="40"
                transition="scale-transition"
                max-width="290px"
                min-width="290px"
                class="rounded-0"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-bind="attrs"
                    v-on="on"
                    label="dd/mm/yyyy"
                    disabled
                    solo
                    class="rounded-0"
                    readonly
                  >
                    <div slot="prepend-inner">
                      <v-icon class="iconstyle"> mdi-calendar </v-icon>
                    </div>
                    <!-- <div  slot="append-inner" style="background-color:#F7F7F7;  padding: 8px !important"><img src="../assets" /></div>     -->
                    <template v-slot:append>
                      <!-- <img  width="20" height="20" src="../../assets/images/Group10706.png" > -->
                      <v-icon>mdi-close</v-icon>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  disabled
                  no-title
                  @input="bankCalenderDateTo = false"
                ></v-date-picker>
              </v-menu>
            </v-layout>
          </v-flex>
        </v-row>

        <v-row class="mb-2">
          <v-flex xs12>
            <v-layout
              column
              class="mx-2 mt-2 cust_pr_quick cust-rmvpd-pr-quick font-weight-bold bankDeatilsLayout">
              Bank Details
            </v-layout>
          </v-flex>
        </v-row>

        <v-row class="row_line">
          <v-flex xs2 class="mr-2">
            <v-layout column class="mx-2 my-2">
              <div class="text-uppercase font-weight-bold title-clr-pr">
                Bank Details(Payee Bank)
              </div>
              <v-divider></v-divider>
            </v-layout>
          </v-flex>

          <v-flex xs2 class="mr-2">
            <v-layout column class="mx-2 my-2">
              <p class="font-weight-bold cstm-clr-pr-quick">
                Bank Name <span class="mandatory_asterisk"> * </span>
              </p>
              <v-text-field
                v-model="emptyData.payeeBank.bankName"
                placeholder="Enter.."
                class="rounded-0"
                solo
                dense
                disabled
                :rules="allRules.bankNameRules"
              ></v-text-field>
            </v-layout>
          </v-flex>

          <v-flex xs2 class="mr-2">
            <v-layout column class="mx-2 my-2">
              <p class="font-weight-bold cstm-clr-pr-quick">
                Bank Address Line 1 <span class="mandatory_asterisk"> * </span>
              </p>
              <v-text-field
                v-model="emptyData.payeeBank.bankAddressLine1"
                placeholder="Enter.."
                class="rounded-0"
                solo
                dense
                disabled
                :rules="allRules.bankAddressRules"
              ></v-text-field>
            </v-layout>
          </v-flex>

          <v-flex xs2 class="mr-2">
            <v-layout column class="mx-2 my-2">
              <p class="font-weight-bold cstm-clr-pr-quick">
                Bank Address Line 2 <span class="mandatory_asterisk"> * </span>
              </p>
              <v-text-field
                v-model="emptyData.payeeBank.bankAddressLine2"
                placeholder="Enter.."
                class="rounded-0"
                solo
                dense
                disabled
                :rules="allRules.bankAddressRules"
              ></v-text-field>
            </v-layout>
          </v-flex>

          <v-flex xs2 class="mr-2">
            <v-layout column class="mx-2 my-2">
              <p class="font-weight-bold cstm-clr-pr-quick">
                Bank Address Line 3 <span class="mandatory_asterisk"> * </span>
              </p>
              <v-text-field
                v-model="emptyData.payeeBank.bankAddressLine3"
                placeholder="Enter.."
                class="rounded-0"
                solo
                dense
                disabled
                :rules="allRules.bankAddressRules"
              ></v-text-field>
            </v-layout>
          </v-flex>
        </v-row>
        <!--------------->
        <v-row class="row_line">
          <v-flex xs2 class="mr-2">
            <v-layout column class="mx-2 my-2">
              <p class="font-weight-bold cstm-clr-pr-quick">Account Number</p>
              <v-text-field
                v-model="emptyData.payeeBank.accountNumber"
                placeholder="Enter.."
                class="rounded-0"
                solo
                dense
                disabled
                :rules="allRules.bankAccNumRule"
              ></v-text-field>
            </v-layout>
          </v-flex>

          <v-flex xs2 class="mr-2">
            <v-layout column class="mx-2 my-2">
              <p class="font-weight-bold cstm-clr-pr-quick">
                Re-Enter Account Number
              </p>
              <v-text-field
                v-model="emptyData.payeeBank.confirmAccountNumber"
                placeholder="Enter.."
                class="rounded-0"
                solo
                dense
                disabled
                :rules="allRules.bankReAccNumRule"
              ></v-text-field>
            </v-layout>
          </v-flex>

          <v-flex xs2 class="mr-2">
            <v-layout column class="mx-2 my-2">
              <p class="font-weight-bold cstm-clr-pr-quick">Swift Code</p>
              <v-text-field
                v-model="emptyData.payeeBank.swiftCode"
                placeholder="Enter.."
                class="rounded-0"
                solo
                dense
                disabled
                :rules="allRules.swiftCodeRule"
              ></v-text-field>
            </v-layout>
          </v-flex>

          <v-flex xs2 class="mr-2">
            <v-layout column class="mx-2 my-2">
              <p class="font-weight-bold cstm-clr-pr-quick">IBAN Number</p>
              <v-text-field
                v-model="emptyData.payeeBank.ibanNumber"
                placeholder="Enter.."
                class="rounded-0"
                solo
                dense
                disabled
                :rules="allRules.ibanCodeRule"
              ></v-text-field>
            </v-layout>
          </v-flex>

          <v-flex xs2 class="mr-2">
            <v-layout column class="mx-2 my-2">
              <p class="font-weight-bold cstm-clr-pr-quick">Sort Code</p>
              <v-text-field
                v-model="emptyData.payeeBank.sortCode"
                placeholder="Enter.."
                class="rounded-0"
                solo
                dense
                disabled
                :rules="allRules.sortCodeRule"
              ></v-text-field>
            </v-layout>
          </v-flex>
        </v-row>
        <!--------------->
        <v-divider></v-divider>
        <v-row class="row_line">
          <v-flex xs2 class="mr-2">
            <v-layout column class="mx-2 my-2">
              <div class="text-uppercase font-weight-bold title-clr-pr">
                Bank Details(CORR. Bank)
              </div>
              <v-divider></v-divider>
            </v-layout>
          </v-flex>

          <v-flex xs2 class="mr-2">
            <v-layout column class="mx-2 my-2">
              <p class="font-weight-bold cstm-clr-pr-quick">Bank Name</p>
              <v-text-field
                v-model="emptyData.corrBank.bankName"
                placeholder="Enter.."
                class="rounded-0"
                solo
                dense
                disabled
                :rules="allRules.corrBankNameRules"
              ></v-text-field>
            </v-layout>
          </v-flex>

          <v-flex xs2 class="mr-2">
            <v-layout column class="mx-2 my-2">
              <p class="font-weight-bold cstm-clr-pr-quick">
                Bank Address Line 1
              </p>
              <v-text-field
                v-model="emptyData.corrBank.bankAddressLine1"
                placeholder="Enter.."
                class="rounded-0"
                solo
                dense
                disabled
                :rules="allRules.corrBankAddressRules"
              ></v-text-field>
            </v-layout>
          </v-flex>

          <v-flex xs2 class="mr-2">
            <v-layout column class="mx-2 my-2">
              <p class="font-weight-bold cstm-clr-pr-quick">
                Bank Address Line 2
              </p>
              <v-text-field
                v-model="emptyData.corrBank.bankAddressLine2"
                placeholder="Enter.."
                class="rounded-0"
                solo
                dense
                disabled
                :rules="allRules.corrBankAddressRules"
              ></v-text-field>
            </v-layout>
          </v-flex>

          <v-flex xs2 class="mr-2">
            <v-layout column class="mx-2 my-2">
              <p class="font-weight-bold cstm-clr-pr-quick">
                Bank Address Line 3
              </p>
              <v-text-field
                v-model="emptyData.corrBank.bankAddressLine3"
                placeholder="Enter.."
                class="rounded-0"
                solo
                dense
                disabled
                :rules="allRules.corrBankAddressRules"
              ></v-text-field>
            </v-layout>
          </v-flex>
        </v-row>

        <v-row class="row_line">
          <v-flex xs2 class="mr-2">
            <v-layout column class="mx-2 my-2">
              <p class="font-weight-bold cstm-clr-pr-quick">Account Number</p>
              <v-text-field
                v-model="emptyData.corrBank.accountNumber"
                placeholder="Enter.."
                class="rounded-0"
                solo
                dense
                disabled
                :rules="allRules.corrBankAccNumRule"
              ></v-text-field>
            </v-layout>
          </v-flex>

          <v-flex xs2 class="mr-2">
            <v-layout column class="mx-2 my-2">
              <p class="font-weight-bold cstm-clr-pr-quick">
                Re-Enter Account Number
              </p>
              <v-text-field
                v-model="emptyData.corrBank.confirmAccountNumber"
                placeholder="Enter.."
                class="rounded-0"
                solo
                dense
                disabled
                :rules="allRules.corrBankReAccNumRule"
              ></v-text-field>
            </v-layout>
          </v-flex>

          <v-flex xs2 class="mr-2">
            <v-layout column class="mx-2 my-2">
              <p class="font-weight-bold cstm-clr-pr-quick">Swift Code</p>
              <v-text-field
                v-model="emptyData.corrBank.swiftCode"
                placeholder="Enter.."
                class="rounded-0"
                solo
                dense
                disabled
                :rules="allRules.corrSwiftCodeRule"
              ></v-text-field>
            </v-layout>
          </v-flex>

          <v-flex xs2 class="mr-2">
            <v-layout column class="mx-2 my-2">
              <p class="font-weight-bold cstm-clr-pr-quick">ABA Number</p>
              <v-text-field
                v-model="emptyData.corrBank.abaCode"
                placeholder="Enter.."
                class="rounded-0"
                solo
                dense
                disabled
                :rules="allRules.corrAbaCodeRule"
              ></v-text-field>
            </v-layout>
          </v-flex>
        </v-row>
      </div>
      <!-- End Empty -->
      <!-- End of Countrywise data --> 

      <v-row  class="row_line backgroundButton">
        <v-flex>
          <v-layout
          class="vlayoutAlignment"
          >
            <v-btn
              medium
              right
              type="submit"
              class="rounded-0 ma-2"
              dark
              color="#23b1a9"
            >
              <v-icon class="mr-2"> mdi-check-circle </v-icon>
              <div class="vertical_line"></div>
              Save
            </v-btn>

            <v-btn
              medium
              right
              class="rounded-0 ma-2"
               dark
              color="#d1121b"
              @click="deleteCountry"
            >
              <v-icon class="mr-2">
                mdi-trash-can-outline
              </v-icon>
              <div class="vertical_line"></div>
              Delete
            </v-btn>

            <v-btn
              medium
              right
              class="rounded-0 ma-2"
              color="#23b1a9"
              dark
              @click="openDownloadDialog"
            >
              <v-icon class="mr-2"> mdi-cloud-download </v-icon>
              <div class="vertical_line"></div>
              Download
            </v-btn>

            <v-btn
              medium
              right
              class="rounded-0 ma-2"
              color="#e46a25"
              dark
              @click="showDocUploadDialog"
            >
              <v-icon class="mr-2"> mdi-cloud-upload </v-icon>
              <div class="vertical_line"></div>
              Upload Document
            </v-btn>
          </v-layout>
        </v-flex>
      </v-row>
    </v-form>
    <v-dialog
      v-model="docUploadDialog"
      persistent
      width="600px"
      height="700px"
      max-width="800px"
      max-height="800px"
      class="rounded-0 overflowNone"
    >
      <v-card
        color="#F7F7F7"
        class="pb-2 overflowNone"
        tile
      >
        <v-card tile class="boxshadowUploadDoc">
          <v-layout
            justify-space-between
            class="boxshadowone"
          >
            <v-layout align-center>
              <v-card-title
                class="pa-0 pl-3 text-capitalize font-weight-bold downloadDocTitle"
                >Upload Document</v-card-title
              >
            </v-layout>
            <div @click="docUploadDialog = false">
              <v-icon  class="pa-2 cursorPoniter">mdi-close</v-icon>
            </div>
          </v-layout>
        </v-card>
        <div class="pa-2">
          <v-card>
            <v-btn-toggle
              v-model="toggledDocType"
              active-class="active_doc_btn"
              class="pa-5"
              @change="updateFileType"
            >
              <v-row>
                <v-btn medium rounded class="ma-2">
                  Bank details
                </v-btn>
                <v-btn medium rounded class="ma-2">
                  Permanent Establishment (PE) Certificate
                </v-btn>
                <!-- </v-row> -->
                <!-- <v-row> -->
                <v-btn medium rounded class="ma-2">
                  Tax Residency Certificate (TRC)
                </v-btn>
                <v-btn rounded medium class="ma-2"> Others </v-btn>
              </v-row>
            </v-btn-toggle>
          </v-card>

          <v-card class="pa-5 mb-2" >
            <v-flex class="mb-2">
              Multiple documents can be uploaded
            </v-flex>
            <v-flex
              xs12
              class="multipleDoc">
              <v-flex XS4></v-flex>
              <v-flex xs2>
                <!-- <v-img src="../../assets/images/bigupload.png" style="width: 50px;height: 50px;"></v-img> -->
              </v-flex>
              <v-flex xs4 class="addyourfile"
                >Add your files here</v-flex
              >
              <v-flex> </v-flex>
            </v-flex>

            <v-btn
              color="#01BBD9"
              block
              dark
              @click="uploadBtnClicked"
            >
              <v-icon class="mr-1">mdi-cloud-upload</v-icon>
              <div class="vertical_line"></div>
              Upload
            </v-btn>
            <v-file-input
              multiple
              v-model="bankFiles"
              id="bankfileupload"
              ref="fileupload"
              class="displayNone"
              @change="filesSelected"
              accept="image/png,image/jpeg,application/msword,application/pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            >
            </v-file-input>
            <v-file-input
              multiple
              v-model="peFiles"
              id="pefileupload"
              ref="fileupload"
              class="displayNone"
              @change="filesSelected"
              accept="image/png,image/jpeg,application/msword,application/pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            >
            </v-file-input>
            <v-file-input
              multiple
              v-model="trcFiles"
              id="trcfileupload"
              ref="fileupload"
              class="displayNone"
              @change="filesSelected"
              accept="image/png,image/jpeg,application/msword,application/pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            >
            </v-file-input>
            <v-file-input
              multiple
              v-model="othersFiles"
              id="otherfileupload"
              ref="fileupload"
              class="displayNone"
              @change="filesSelected"
              accept="image/png,image/jpeg,application/msword,application/pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            >
            </v-file-input>
            <!-- Bank File List -->
            <v-simple-table v-if="toggledDocType == 0">
              <template v-slot:default>
                <tbody>
                  <tr
                    v-for="(file, index) in aigMasterForm.originDetails[
                      toggledCountry
                    ].documents.bank"
                    :key="index"
                  >
                    <td class="pl-0">
                      <v-icon>{{ getIcon(file.type) }}</v-icon>
                    </td>
                    <td>{{ file.fileName }}</td>

                    <td  class="pl-0">
                      <v-icon>mdi-check</v-icon>
                    </td>
                    <td>
                      <v-btn
                        small
                        rounded
                        class="ma-2"
                        color="#d1121b"
                        dark
                        @click="deleteThisFile(index)"
                      >
                        <v-icon> mdi-trash-can-outline </v-icon>
                        <div class="vertical_line"></div>
                        Delete
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <!-- pe File List -->
            <v-simple-table v-if="toggledDocType == 1">
              <template v-slot:default>
                <tbody>
                  <tr
                    v-for="(file, index) in aigMasterForm.originDetails[
                      toggledCountry
                    ].documents.pe"
                    :key="index"
                  >
                    <td class="pl-0">
                      <v-icon>{{ getIcon(file.type) }}</v-icon>
                    </td>
                    <td>{{ file.fileName }}</td>

                    <td  class="pl-0">
                      <v-icon>mdi-check</v-icon>
                    </td>
                    <td>
                      <v-btn
                        small
                        rounded
                        class="ma-2"
                        color="#d1121b"
                        dark
                        @click="deleteThisFile(index)"
                      >
                        <v-icon> mdi-trash-can-outline </v-icon>
                        <div class="vertical_line"></div>
                        Delete
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <!-- trc File List -->
            <v-simple-table v-if="toggledDocType == 2">
              <template v-slot:default>
                <tbody>
                  <tr
                    v-for="(file, index) in aigMasterForm.originDetails[
                      toggledCountry
                    ].documents.trc"
                    :key="index"
                  >
                    <td  class="pl-0" >
                      <v-icon>{{ getIcon(file.type) }}</v-icon>
                    </td>
                    <td>{{ file.fileName }}</td>

                    <td class="pl-0">
                      <v-icon>mdi-check</v-icon>
                    </td>
                    <td>
                      <v-btn
                        small
                        rounded
                        class="ma-2"
                        color="#d1121b"
                        dark
                        @click="deleteThisFile(index)"
                      >
                        <v-icon> mdi-trash-can-outline </v-icon>
                        <div class="vertical_line"></div>
                        Delete
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <!-- others File List -->
            <v-simple-table v-if="toggledDocType == 3">
              <template v-slot:default>
                <tbody>
                  <tr
                    v-for="(file, index) in aigMasterForm.originDetails[
                      toggledCountry
                    ].documents.others"
                    :key="index"
                  >
                    <td class="pl-0">
                      <v-icon>{{ getIcon(file.type) }}</v-icon>
                    </td>
                    <td>{{ file.fileName }}</td>

                    <td class="pl-0">
                      <v-icon>mdi-check</v-icon>
                    </td>
                    <td>
                      <v-btn
                        small
                        rounded
                        class="ma-0"
                        color="#d1121b"
                        dark
                        @click="deleteThisFile(index)"
                      >
                        <v-icon> mdi-trash-can-outline </v-icon>
                        <div class="vertical_line"></div>
                        Delete
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
          <v-row>
            <v-flex xs4></v-flex>
            <v-flex xs2 class="pa-2">
              <v-layout>
                <v-btn
                  class="rounded-0 mt-1"
                  color="#23b1a9"
                  dark
                  @click="submitUploadFiles"
                >
                  <v-icon>mdi-arrow-right-circle</v-icon>
                  <div class="vertical_line"></div>
                  Submit
                </v-btn>
              </v-layout>
            </v-flex>
            <v-flex xs4></v-flex>
          </v-row>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="docDownloadDialog"
      persistent
      width="600px"
      height="700px"
      max-width="800px"
      max-height="800px"
      class="rounded-0 overflowNone"

    >
      <v-card
        color="#F7F7F7"
        class="pb-2 overflowNone"
        tile
      >
        <v-card tile class="boxshadowUploadDoc">
          <v-layout
            justify-space-between
            class="boxshadowone"
          >
            <v-layout align-center>
              <v-card-title
                class="pa-0 pl-3 text-capitalize font-weight-bold downloadDocTitle"
                >Download Document</v-card-title
              >
            </v-layout>
            <div @click="closeDownloadDialog">
              <v-icon  class="pa-2 cursorPoniter">mdi-close</v-icon>
            </div>
          </v-layout>
        </v-card>
        <div class="pa-2">
          <v-card>
            <v-btn-toggle
              v-model="toggledDownloadDocType"
              active-class="active_doc_btn"
              class="pa-5"
            >
              <v-row>
                <v-btn medium rounded class="ma-2">
                  Bank details
                </v-btn>
                <v-btn medium rounded class="ma-2">
                  Permanent Establishment (PE) Certificate
                </v-btn>
                <!-- </v-row> -->
                <!-- <v-row> -->
                <v-btn medium rounded class="ma-2">
                  Tax Residency Certificate (TRC)
                </v-btn>
                <v-btn rounded medium class="ma-2"> Others </v-btn>
              </v-row>
            </v-btn-toggle>
          </v-card>

          <v-card  class="pa-5 mb-2">
            <!-- Bank File List -->
            <v-simple-table v-if="toggledDownloadDocType == 0">
              <template v-slot:default>
                <tbody>
                  <tr
                    v-for="(file, index) in aigMasterForm.originDetails[
                      toggledCountry
                    ].documents.bank"
                    :key="index"
                  >
                    <td class="pl-0">
                      <v-icon>{{ getIcon(file.type) }}</v-icon>
                    </td>
                    <td>{{ file.fileName }}</td>

                    <td>
                      <v-btn
                        small
                        rounded
                        color="#d1121b"
                        class="ma-2"
                        dark
                        @click="downloadThisFile(file)"
                      >
                        <v-icon> mdi-download</v-icon>
                        <div class="vertical_line"></div>
                        Download
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <!-- pe File List -->
            <v-simple-table v-if="toggledDownloadDocType == 1">
              <template v-slot:default>
                <tbody>
                  <tr
                    v-for="(file, index) in aigMasterForm.originDetails[
                      toggledCountry
                    ].documents.pe"
                    :key="index"
                  >
                    <td class="pl-0">
                      <v-icon>{{ getIcon(file.type) }}</v-icon>
                    </td>
                    <td>{{ file.fileName }}</td>

                    <td>
                      <v-btn
                        small
                        rounded
                        color="#d1121b"
                        class="ma-2"
                        dark
                        @click="downloadThisFile(file)"
                      >
                        <v-icon> mdi-download</v-icon>
                        <div class="vertical_line"></div>
                        Download
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <!-- trc File List -->
            <v-simple-table v-if="toggledDownloadDocType == 2">
              <template v-slot:default>
                <tbody>
                  <tr
                    v-for="(file, index) in aigMasterForm.originDetails[
                      toggledCountry
                    ].documents.trc"
                    :key="index"
                  >
                    <td class="pl-0">
                      <v-icon>{{ getIcon(file.type) }}</v-icon>
                    </td>
                    <td>{{ file.fileName }}</td>

                    <td>
                      <v-btn
                        small
                        rounded
                        color="#d1121b"
                        class="ma-2"
                        dark
                        @click="downloadThisFile(file)"
                      >
                        <v-icon> mdi-download</v-icon>
                        <div class="vertical_line"></div>
                        Download
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <!-- others File List -->
            <v-simple-table v-if="toggledDownloadDocType == 3">
              <template v-slot:default>
                <tbody>
                  <tr
                    v-for="(file, index) in aigMasterForm.originDetails[
                      toggledCountry
                    ].documents.others"
                    :key="index"
                  >
                    <td class="pl-0">
                      <v-icon>{{ getIcon(file.type) }}</v-icon>
                    </td>
                    <td>{{ file.fileName }}</td>
                    <td>
                      <v-btn
                        small
                        rounded
                        color="#d1121b"
                        class="ma-2"
                        dark
                        @click="downloadThisFile(file)"
                      >
                        <v-icon> mdi-download</v-icon>
                        <div class="vertical_line"></div>
                        Download
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
          <v-row>
            <v-flex xs4></v-flex>
            <v-flex xs2 class="pa-2" >
              <v-layout>
                <v-btn
                color="#23b1a9"
                dark
                class="mt-1 rounded-0"
                  @click="closeDownloadDialog"
                >
                  <v-icon>mdi-arrow-right-circle</v-icon>
                  <div class="vertical_line ml-1"></div>
                  Close
                </v-btn>
              </v-layout>
            </v-flex>
            <v-flex xs4></v-flex>
          </v-row>
        </div>
      </v-card>
    </v-dialog>
    <v-overlay :absolute="absolute" :value="overlay">
      <v-progress-circular
        :size="70"
        color="#01BBD9"
        indeterminate
      ></v-progress-circular>
    </v-overlay>
  </v-app>
</template>

<script>
import moment from "moment";

export default {
  data() {
    return {
      search: "",
      toggledDownloadDocType: "",
      docDownloadDialog: false,
      otheFieldsEnteredForPAcc: false,
      overlay: false,
      absolute: true,
      aigMasterForm: {
        nameOfAigOffice: "",
        aigCode: "",
        originDetails: [],
      },
      countryList: [],
      bankCalenderDateFrom: false,
      bankCalenderDateTo: false,
      validationMessages: {
        mandatory: "This field is required.",
      },
      allRules: {
        officeNameRules: [
          (v) => !!v || this.validationMessages.mandatory,
          (v) =>
            (v && v.length <= 100) ||
            "AIG Office Name should be less than 100 characters.",
          (v) =>
            (v && v.length > 5) ||
            "Name of AIG Office should be more than 5 digits.",
          (v) => /[a-zA-Z0-9\s]+$/.test(v) || "AIG Office Name is not valid.",
        ],
        countryRules: [
          (v) => !!v || this.validationMessages.mandatory,
          (v) =>
            (v && v.length <= 100) ||
            "AIG Office Name should be less than 100 characters.",
          (v) => /[a-zA-Z0-9\s]+$/.test(v) || "AIG Office Name is not valid.",
        ],
        address1Rules: [
          (v) => !!v || this.validationMessages.mandatory,
          (v) =>
            (v && v.length <= 50) ||
            "Address should be less than 50 characters.",
          (v) => /[a-zA-Z0-9\s.-:;,/#]+$/.test(v) || "Address is not valid.",
        ],
        address2Rules: [
          (v) => !!v || this.validationMessages.mandatory,
          (v) =>
            (v && v.length <= 50) ||
            "Address should be less than 50 characters.",
          (v) => /[a-zA-Z0-9\s.-:;,/#]+$/.test(v) || "Address is not valid.",
        ],
        address3Rules: [
          (v) => !!v || this.validationMessages.mandatory,
          (v) =>
            (v && v.length <= 50) ||
            "Address should be less than 50 characters.",
          (v) => /[a-zA-Z0-9\s.-:;,/#]+$/.test(v) || "Address is not valid.",
        ],
        nameRules: [
          (v) => !!v || this.validationMessages.mandatory,
          (v) =>
            (v && v.length <= 50) || "Name should be less than 50 characters.",
          (v) => /[a-zA-Z0-9\s]+$/.test(v) || "Name is not valid.",
        ],
        officeNumRules: [
          (v) => !!v || this.validationMessages.mandatory,
          (v) =>
            (v && v.length > 5) ||
            "Office number should be more than 5 digits.",
          (v) =>
            (v && v.length <= 20) ||
            "Office number should be less than 20 digits.",
          (v) => /[0-9]+$/.test(v) || "Office number is not valid.",
        ],
        mobileNumRules: [
          (v) =>
            (v && v.toString().length <= 20) ||
            "Mobile number should be less than 20 digits.",
          (v) =>
            (v && v.length > 5) ||
            "Mobile number should be more than 5 digits.",
          //     v => /(6|7|8|9)\d{9}/.test(v) || 'Mobile number is not valid.'
        ],
        emailRules: [
          (v) => !!v || this.validationMessages.mandatory,
          (v) =>
            (v && v.length <= 50) || "Email should be less than 50 characters.",
          (v) => /.+@.+\..+/.test(v) || "E-mail is not valid.",
        ],
        name2Rules: [
          (v) =>
            (v.length < 0 && v.length <= 50) ||
            "Name should be less than 50 characters.",
          (v) => /[a-zA-Z0-9\s]+$/.test(v) || "Name is not valid.",
        ],
        office2NumRules: [
          (v) =>
            (v && v.length <= 20) ||
            "Office number should be less than 20 digits.",
          (v) =>
            (v && v.length > 5) ||
            "Office number should be more than 5 digits.",
          (v) => /[0-9]+$/.test(v) || "Office number is not valid.",
        ],
        mobile2NumRules: [
          (v) =>
            (v && v.toString().length <= 20) ||
            "Mobile number should be less than 20 digits.",
          (v) =>
            (v && v.length > 5) ||
            "Mobile number should be more than 5 digits.",
          //   v => /(6|7|8|9)\d{9}/.test(v) || 'Mobile number is not valid.'
        ],
        email2Rules: [
          (v) =>
            (v && v.length <= 50) || "Email should be less than 50 characters.",
          (v) => /.+@.+\..+/.test(v) || "E-mail is not valid.",
        ],
        bankNameRules: [
          (v) => !!v || this.validationMessages.mandatory,
          (v) =>
            (v && v.length <= 100) ||
            "Bank Name should be less than 100 characters.",
          (v) => /[a-zA-Z0-9\s]+$/.test(v) || "Bank Name is not valid.",
        ],
        bankAddressRules: [
          (v) => !!v || this.validationMessages.mandatory,
          (v) =>
            (v && v.length <= 50) ||
            "Address should be less than 50 characters.",
          (v) => /[a-zA-Z0-9\s.-:;,/#]+$/.test(v) || "Address is not valid.",
        ],
        bankAccNumRule: [
          (v) =>
            (v && v.length <= 50) ||
            "Account Number should be less than 50 characters.",
          (v) => /[a-zA-Z0-9]+$/.test(v) || "Account Number is not valid.",
        ],
        bankReAccNumRule: [
          (v) =>
            (v && v.length <= 50) ||
            "Account Number should be less than 50 characters.",
          (v) => /[a-zA-Z0-9]+$/.test(v) || "Account Number is not valid.",
          //v => (v && v !== this.aigMasterForm.payeeBankDetails.bankAccNum) || 'Account Number does not match.'
        ],
        swiftCodeRule: [
          (v) =>
            (v && v.length <= 50) ||
            "Swift Code should be less than 50 characters.",
          (v) => /[a-zA-Z0-9]+$/.test(v) || "Swift Code is not valid.",
        ],
        ibanCodeRule: [
          (v) =>
            (v && v.length <= 50) ||
            "IBAN Code should be less than 50 characters.",
          (v) => /[a-zA-Z0-9]+$/.test(v) || "IBAN Code is not valid.",
        ],
        sortCodeRule: [
          (v) =>
            (v && v.length <= 50) ||
            "Sort Code should be less than 50 characters.",
          (v) => /[a-zA-Z0-9]+$/.test(v) || "Sort Code is not valid.",
        ],
        //
        corrBankNameRules: [
          (v) =>
            (v && v.length <= 100) ||
            "Bank Name should be less than 100 characters.",
          (v) => /[a-zA-Z0-9\s]+$/.test(v) || "Bank Name is not valid.",
        ],
        corrBankAddressRules: [
          (v) =>
            (v && v.length <= 50) ||
            "Address should be less than 50 characters.",
          (v) => /[a-zA-Z0-9\s.-:;,/#]+$/.test(v) || "Address is not valid.",
        ], 
        corrBankAccNumRule: [
          (v) =>
            (v && v.length <= 50) ||
            "Account Number should be less than 50 characters.",
          (v) => /[a-zA-Z0-9]+$/.test(v) || "Account Number is not valid.",
        ],
        corrBankReAccNumRule: [
          (v) =>
            (v && v.length <= 50) ||
            "Account Number should be less than 50 characters.",
          (v) => /[a-zA-Z0-9]+$/.test(v) || "Account Number is not valid.",
          // v => (v && v !== this.aigMasterForm.corrBankDetails.bankAccNum) || 'Account Number does not match.'
        ],
        corrSwiftCodeRule: [
          (v) =>
            (v && v.length <= 50) ||
            "Swift Code should be less than 50 characters.",
          (v) => /[a-zA-Z0-9]+$/.test(v) || "Swift Code is not valid.",
        ],
        corrAbaCodeRule: [
          (v) =>
            (v && v.length <= 50) ||
            "ABA Code should be less than 50 characters.",
          (v) => /[a-zA-Z0-9]+$/.test(v) || "ABA Code is not valid.",
        ],
        corrSortCodeRule: [
          (v) =>
            (v && v.length <= 50) ||
            "Sort Code should be less than 50 characters.",
          (v) => /[a-zA-Z0-9]+$/.test(v) || "Sort Code is not valid.",
        ],
      },
      toggledCountry: "",
      toggledCountryName: "",
      selectedCountryList: "",
      showCountryOpt: false,
      docUploadDialog: false,
      toggledDocType: "",
      bankFiles: [],
      peFiles: [],
      trcFiles: [],
      othersFiles: [],
      files: [],
      max20: 20,
      max50: 50,
      max100: 100,
      emptyData: {
        countryName: "",
        addressLine1: "",
        addressLine2: "",
        addressLine3: "",
        contactPerson1: {
          name: "",
          officeNum: {
            isd:'91',
            number:''
          },
           
          mobileNum: {
            isd:'91',
            number:''
           },
          email: "",
        },
        contactPerson2: {
          name: "",
          officeNum: {
            isd:'91',
            number:''
           },
           mobileNum: {
            isd:'91',
            number:''
           },
          email: "",
        },
        peValidityDate: "",
        trcApplicable: false,
        trcValidityDate: "",
        payeeBank: {
          bankName: "",
          bankAddressLine1: "",
          bankAddressLine2: "",
          bankAddressLine3: "",
          accountNumber: "",
          confirmAccountNumber: "",
          swiftCode: "",
          ibanNumber: "",
          sortCode: "",
        },
        corrBank: {
          bankName: "",
          bankAddressLine1: "",
          bankAddressLine2: "",
          bankAddressLine3: "",
          accountNumber: "",
          confirmAccountNumber: "",
          swiftCode: "",
          abaCode: "",
        },
        documents: {
          bank: [
            {
              fileName: "",
              fileUrl: "",
            },
          ],
          pe: [
            {
              fileName: "",
              fileUrl: "",
            },
          ],
          trc: [
            {
              fileName: "",
              fileUrl: "",
            },
          ],
          others: [
            {
              fileName: "",
              fileUrl: "",
            },
          ],
        },
      },
    };
  },
  methods: {
    //to open calendermenu on tab

    // v-on:focus="onTRCFocus"
    onPEFocus(){
      this.bankCalenderDateFrom = true;
    },

    onTRCFocus(){
      this.bankCalenderDateTo = true;
    },

    submitDetails() {
      console.log("aigMasterForm - ", this.aigMasterForm);
      if (
        this.aigMasterForm.originDetails.length > 0 &&
        this.$refs.officeForm.validate()
      ) {
       
        var isDataValid = true;
        for (var i in this.aigMasterForm.originDetails) {
         console.log('hgkjh',this.officeNameRulesFunc(this.aigMasterForm.nameOfAigOffice))
          if (
            this.officeNameRulesFunc(this.aigMasterForm.nameOfAigOffice) !==
            true
          ) {
            isDataValid = false;
            this.showAlert(this.officeNameRulesFunc(this.aigMasterForm.nameOfAigOffice));
          } 
          else if (
            this.address1RulesFunc(
              this.aigMasterForm.originDetails[i].addressLine1
            ) !== true
          ) {
            isDataValid = false;
            this.showAlert(
              " For " +
                this.aigMasterForm.originDetails[i].countryName +
                " - Address Line 1 - " +
                this.address1RulesFunc(
                  this.aigMasterForm.originDetails[i].addressLine1
                )
            );
          }
           else if (!this.aigMasterForm.originDetails[i].addressLine1) {
            isDataValid = false;
            this.showAlert(
              "Address Line 1 for " +
                this.aigMasterForm.originDetails[i].countryName +
                " is required"
            );
          } else if (!this.aigMasterForm.originDetails[i].addressLine2) {
            isDataValid = false;
            this.showAlert(
              "Address Line 2 for " +
                this.aigMasterForm.originDetails[i].countryName +
                " is required"
            );
          } else if (
            this.address2Rules(
              this.aigMasterForm.originDetails[i].addressLine2
            ) !== true
          ) {
            isDataValid = false;
            this.showAlert(
              " For " +
                this.aigMasterForm.originDetails[i].countryName +
                " - Address Line 2 - " +
                this.address2Rules(
                  this.aigMasterForm.originDetails[i].addressLine2
                )
            );
          } else if (!this.aigMasterForm.originDetails[i].addressLine3) {
            isDataValid = false;
            this.showAlert(
              "Address Line 3 for " +
                this.aigMasterForm.originDetails[i].countryName +
                " is required"
            );
          } else if (
            this.address3Rules(
              this.aigMasterForm.originDetails[i].addressLine3
            ) !== true
          ) {
            isDataValid = false;
            this.showAlert(
              " For " +
                this.aigMasterForm.originDetails[i].countryName +
                " - Address Line 3 - " +
                this.address3Rules(
                  this.aigMasterForm.originDetails[i].addressLine3
                )
            );
          } else if (!this.aigMasterForm.originDetails[i].contactPerson1.name) {
            isDataValid = false;
            this.showAlert(
              "Contact Person 1 - Name for " +
                this.aigMasterForm.originDetails[i].countryName +
                " is required"
            );
          } else if (
            this.nameRules(
              this.aigMasterForm.originDetails[i].contactPerson1.name
            ) !== true
          ) {
            isDataValid = false;
            this.showAlert(
              " For " +
                this.aigMasterForm.originDetails[i].countryName +
                " - Contact Person 1 Name - " +
                this.nameRules(
                  this.aigMasterForm.originDetails[i].contactPerson1.name
                )
            );
          } else if (
            !this.aigMasterForm.originDetails[i].contactPerson1.officeNum.number
          ) {
            isDataValid = false;
            this.showAlert(
              "Contact Person 1 - Office Number for " +
                this.aigMasterForm.originDetails[i].countryName +
                " is required"
            );
          } else if (
            this.officeNumRules(
              this.aigMasterForm.originDetails[i].contactPerson1.officeNum.number
            ) !== true
          ) {
            isDataValid = false;
            this.showAlert(
              " For " +
                this.aigMasterForm.originDetails[i].countryName +
                " -  Contact Person 1 Office Number - " +
                this.officeNumRules(
                  this.aigMasterForm.originDetails[i].contactPerson1.officeNum.number
                )
            );
          } else if (
            !this.aigMasterForm.originDetails[i].contactPerson1.email
          ) {
            isDataValid = false;
            this.showAlert(
              "Contact Person 1 -Email for " +
                this.aigMasterForm.originDetails[i].countryName +
                " is required"
            );
          } else if (
            this.mobileNumRules(
              this.aigMasterForm.originDetails[i].contactPerson1.mobileNum.number
            ) !== true
          ) {
            isDataValid = false;
            this.showAlert(
              " For " +
                this.aigMasterForm.originDetails[i].countryName +
                " -  Contact Person 1 Mobile Number - " +
                this.mobileNumRules(
                  this.aigMasterForm.originDetails[i].contactPerson1.mobileNum.number
                )
            );
          } else if (
            this.emailRules(
              this.aigMasterForm.originDetails[i].contactPerson1.email
            ) !== true
          ) {
            isDataValid = false;
            this.showAlert(
              " For " +
                this.aigMasterForm.originDetails[i].countryName +
                " - Contact Person 1 Email -  " +
                this.emailRules(
                  this.aigMasterForm.originDetails[i].contactPerson1.email
                )
            );
          } else if (!this.aigMasterForm.originDetails[i].payeeBank.bankName) {
            isDataValid = false;
            this.showAlert(
              "Payee Bank - Bank Name for " +
                this.aigMasterForm.originDetails[i].countryName +
                " is required"
            );
          } else if (
            this.name2RuleFunc(
              this.aigMasterForm.originDetails[i].contactPerson2.name
            ) !== true
          ) {
            isDataValid = false;
            this.showAlert(
              " For " +
                this.aigMasterForm.originDetails[i].countryName +
                " -  Contact Person 2 Name - " +
                this.name2RuleFunc(
                  this.aigMasterForm.originDetails[i].contactPerson2.name
                )
            );
          } else if (
            this.office2NumRules(
              this.aigMasterForm.originDetails[i].contactPerson2.officeNum.number
            ) !== true
          ) {
            isDataValid = false;
            this.showAlert(
              " For " +
                this.aigMasterForm.originDetails[i].countryName +
                " -  Contact Person 2 Office Number - " +
                this.office2NumRules(
                  this.aigMasterForm.originDetails[i].contactPerson2.officeNum.number
                )
            );
          } else if (
            this.mobile2NumRules(
              this.aigMasterForm.originDetails[i].contactPerson2.mobileNum.number
            ) !== true
          ) {
            isDataValid = false;
            this.showAlert(
              " For " +
                this.aigMasterForm.originDetails[i].countryName +
                " - Contact Person 2 Mobile Number - " +
                this.mobile2NumRules(
                  this.aigMasterForm.originDetails[i].contactPerson2.mobileNum.number
                )
            );
          } else if (
            this.email2Rules(
              this.aigMasterForm.originDetails[i].contactPerson2.email
            ) !== true
          ) {
            isDataValid = false;
            this.showAlert(
              " For " +
                this.aigMasterForm.originDetails[i].countryName +
                " - Contact Person 2 Email - " +
                this.email2Rules(
                  this.aigMasterForm.originDetails[i].contactPerson2.email
                )
            );
          } else if (
            !this.aigMasterForm.originDetails[i].payeeBank.bankAddressLine1
          ) {
            isDataValid = false;
            this.showAlert(
              "Payee Bank - Bank Address Line 1 for " +
                this.aigMasterForm.originDetails[i].countryName +
                " is required"
            );
          } else if (
            this.bankNameRules(
              this.aigMasterForm.originDetails[i].payeeBank.bankName
            ) !== true
          ) {
            isDataValid = false;
            this.showAlert(
              " For " +
                this.aigMasterForm.originDetails[i].countryName +
                " - Payee Bank - Bank Name - " +
                this.bankNameRules(
                  this.aigMasterForm.originDetails[i].payeeBank.bankName
                )
            );
          } else if (
            this.bankAddressRules(
              this.aigMasterForm.originDetails[i].payeeBank.bankAddressLine1
            ) !== true
          ) {
            isDataValid = false;
            this.showAlert(
              " For " +
                this.aigMasterForm.originDetails[i].countryName +
                " -  Payee Bank - Bank Address Line 1 - " +
                this.bankAddressRules(
                  this.aigMasterForm.originDetails[i].payeeBank.bankAddressLine1
                )
            );
          } else if (
            !this.aigMasterForm.originDetails[i].payeeBank.bankAddressLine2
          ) {
            isDataValid = false;
            this.showAlert(
              "Payee Bank - Bank Address Line 2 for " +
                this.aigMasterForm.originDetails[i].countryName +
                " is required"
            );
          } else if (
            this.bankAddressRules(
              this.aigMasterForm.originDetails[i].payeeBank.bankAddressLine2
            ) !== true
          ) {
            isDataValid = false;
            this.showAlert(
              " For " +
                this.aigMasterForm.originDetails[i].countryName +
                " -  Payee Bank - Bank Address Line 2 - " +
                this.bankAddressRules(
                  this.aigMasterForm.originDetails[i].payeeBank.bankAddressLine2
                )
            );
          } else if (
            this.bankAddressRules(
              this.aigMasterForm.originDetails[i].payeeBank.bankAddressLine3
            ) !== true
          ) {
            isDataValid = false;
            this.showAlert(
              " For " +
                this.aigMasterForm.originDetails[i].countryName +
                " - Payee Bank - Bank Address Line 3 - " +
                this.bankAddressRules(
                  this.aigMasterForm.originDetails[i].payeeBank.bankAddressLine3
                )
            );
          } else if (
            !this.aigMasterForm.originDetails[i].payeeBank.bankAddressLine3
          ) {
            isDataValid = false;
            this.showAlert(
              "Payee Bank - Bank Address Line 3 for " +
                this.aigMasterForm.originDetails[i].countryName +
                " is required"
            );
          } else if (
            this.bankAccNumRule(
              this.aigMasterForm.originDetails[i].payeeBank.accountNumber
            ) !== true
          ) {
            isDataValid = false;
            this.showAlert(
              " For " +
                this.aigMasterForm.originDetails[i].countryName +
                " - Payee Bank - Account Number" +
                this.bankAccNumRule(
                  this.aigMasterForm.originDetails[i].payeeBank.accountNumber
                )
            );
          } else if (
            this.bankReAccNumRule(
              this.aigMasterForm.originDetails[i].payeeBank.confirmAccountNumber
            ) !== true
          ) {
            isDataValid = false;
            this.showAlert(
              " For " +
                this.aigMasterForm.originDetails[i].countryName +
                " - " +
                this.bankReAccNumRule(
                  this.aigMasterForm.originDetails[i].payeeBank
                    .confirmAccountNumber
                )
            );
          } else if (
            this.swiftCodeRule(
              this.aigMasterForm.originDetails[i].payeeBank.swiftCode
            ) !== true
          ) {
            isDataValid = false;
            this.showAlert(
              " For " +
                this.aigMasterForm.originDetails[i].countryName +
                " - Payee Bank - Swift Code - " +
                this.swiftCodeRule(
                  this.aigMasterForm.originDetails[i].payeeBank.swiftCode
                )
            );
          } else if (
            this.ibanCodeRule(
              this.aigMasterForm.originDetails[i].payeeBank.ibanNumber
            ) !== true
          ) {
            isDataValid = false;
            this.showAlert(
              " For " +
                this.aigMasterForm.originDetails[i].countryName +
                " - Payee Bank - IBAN Number - " +
                this.ibanCodeRule(
                  this.aigMasterForm.originDetails[i].payeeBank.ibanNumber
                )
            );
          } else if (
            this.sortCodeRule(
              this.aigMasterForm.originDetails[i].payeeBank.sortCode
            ) !== true
          ) {
            isDataValid = false;
            this.showAlert(
              " For " +
                this.aigMasterForm.originDetails[i].countryName +
                " - Payee Bank - Sort Code - " +
                this.sortCodeRule(
                  this.aigMasterForm.originDetails[i].payeeBank.sortCode
                )
            );
          } else if (
            this.corrBankNameRules(
              this.aigMasterForm.originDetails[i].corrBank.bankName
            ) !== true
          ) {
            isDataValid = false;
            this.showAlert(
              " For " +
                this.aigMasterForm.originDetails[i].countryName +
                " - Corr. Bank - Bank Name - " +
                this.corrBankNameRules(
                  this.aigMasterForm.originDetails[i].corrBank.bankName
                )
            );
          } else if (
            this.corrBankAddressRules(
              this.aigMasterForm.originDetails[i].corrBank.bankAddressLine1
            ) !== true
          ) {
            isDataValid = false;
            this.showAlert(
              " For " +
                this.aigMasterForm.originDetails[i].countryName +
                " -  Corr. Bank - Bank Address Line 1 - " +
                this.corrBankAddressRules(
                  this.aigMasterForm.originDetails[i].corrBank.bankAddressLine1
                )
            );
          } else if (
            this.corrBankAddressRules(
              this.aigMasterForm.originDetails[i].corrBank.bankAddressLine2
            ) !== true
          ) {
            isDataValid = false;
            this.showAlert(
              " For " +
                this.aigMasterForm.originDetails[i].countryName +
                " -  Corr. Bank - Bank Address Line 2 - " +
                this.corrBankAddressRules(
                  this.aigMasterForm.originDetails[i].corrBank.bankAddressLine2
                )
            );
          } else if (
            this.corrBankAddressRules(
              this.aigMasterForm.originDetails[i].corrBank.bankAddressLine3
            ) !== true
          ) {
            isDataValid = false;
            this.showAlert(
              " For " +
                this.aigMasterForm.originDetails[i].countryName +
                " -  Corr. Bank - Bank Address Line 3 - " +
                this.corrBankAddressRules(
                  this.aigMasterForm.originDetails[i].corrBank.bankAddressLine3
                )
            );
          } else if (
            this.corrBankAccNumRule(
              this.aigMasterForm.originDetails[i].corrBank.bankName
            ) !== true
          ) {
            isDataValid = false;
            this.showAlert(
              " For " +
                this.aigMasterForm.originDetails[i].countryName +
                " -  Corr. Bank - Account Number -  " +
                this.corrBankAccNumRule(
                  this.aigMasterForm.originDetails[i].corrBank.accountNumber
                )
            );
          } else if (
            this.corrBankReAccNumRule(
              this.aigMasterForm.originDetails[i].corrBank.confirmAccountNumber
            ) !== true
          ) {
            isDataValid = false;
            this.showAlert(
              " For " +
                this.aigMasterForm.originDetails[i].countryName +
                " -  Corr. Bank - Confirm Account Number - " +
                this.corrBankReAccNumRule(
                  this.aigMasterForm.originDetails[i].corrBank.confirmAccountNumber
                )
            );
          } else if (
            this.corrSwiftCodeRule(
              this.aigMasterForm.originDetails[i].corrBank.swiftCode
            ) !== true
          ) {
            isDataValid = false;
            this.showAlert(
              " For " +
                this.aigMasterForm.originDetails[i].countryName +
                " -  Corr. Bank - Swift Code - " +
                this.corrSwiftCodeRule(
                  this.aigMasterForm.originDetails[i].corrBank.swiftCode
                )
            );
          } else if (
            this.corrAbaCodeRule(
              this.aigMasterForm.originDetails[i].corrBank.abaCode
            ) !== true
          ) {
            isDataValid = false;
            this.showAlert(
              " For " +
                this.aigMasterForm.originDetails[i].countryName +
                " -  Corr. Bank - ABA Code - " +
                this.corrAbaCodeRule(
                  this.aigMasterForm.originDetails[i].corrBank.abaCode
                )
            );
          } else if (
            !this.aigMasterForm.originDetails[i].payeeBank.accountNumber &&
            !this.aigMasterForm.originDetails[i].payeeBank.swiftCode &&
            !this.aigMasterForm.originDetails[i].payeeBank.ibanNumber
          ) {
            isDataValid = false;
            this.showAlert(
              "Payee Bank - Please add atleast 2 values from Account number, Swift code, IBAN number for " +
                this.aigMasterForm.originDetails[i].countryName +
                ""
            );
          } else if (
            this.aigMasterForm.originDetails[i].payeeBank.accountNumber &&
            !this.aigMasterForm.originDetails[i].payeeBank.swiftCode &&
            !this.aigMasterForm.originDetails[i].payeeBank.ibanNumber
          ) {
            isDataValid = false;
            this.showAlert(
              "Payee Bank - Please add atleast 2 values from Account number, Swift code, IBAN number for " +
                this.aigMasterForm.originDetails[i].countryName +
                " is required"
            );
          } else if (
            !this.aigMasterForm.originDetails[i].payeeBank.accountNumber &&
            this.aigMasterForm.originDetails[i].payeeBank.swiftCode &&
            !this.aigMasterForm.originDetails[i].payeeBank.ibanNumber
          ) {
            isDataValid = false;
            this.showAlert(
              "Payee Bank - Please add atleast 2 values from Account number, Swift code, IBAN number for " +
                this.aigMasterForm.originDetails[i].countryName +
                ""
            );
          } else if (
            !this.aigMasterForm.originDetails[i].payeeBank.accountNumber &&
            !this.aigMasterForm.originDetails[i].payeeBank.swiftCode &&
            this.aigMasterForm.originDetails[i].payeeBank.ibanNumber
          ) {
            isDataValid = false;
            this.showAlert(
              "Payee Bank - Please add atleast 2 values from Account number, Swift code, IBAN number for " +
                this.aigMasterForm.originDetails[i].countryName +
                ""
            );
          } else if (
            this.aigMasterForm.originDetails[i].trcApplicable &&
            this.aigMasterForm.originDetails[i].documents.trc.length == 0
          ) {
            isDataValid = false;
            this.showAlert(
              "Please add atleast 1 document for TRC or deselect TRC Applicable for " +
                this.aigMasterForm.originDetails[i].countryName +
                ""
            );
          }
          if (!isDataValid) {
            break;
          }
        }
        if (isDataValid) {
          this.showProgress();
          let self = this;
          //Change date format start
          for (var i in this.aigMasterForm.originDetails) {
            if (this.aigMasterForm.originDetails[i].peValidityDate) {
              this.aigMasterForm.originDetails[i].peValidityDate = new Date(
                this.aigMasterForm.originDetails[i].peValidityDate
              ).toISOString();
            } else {
              this.aigMasterForm.originDetails[i].peValidityDate = "";
            }

            if (this.aigMasterForm.originDetails[i].trcValidityDate) {
              this.aigMasterForm.originDetails[i].trcValidityDate = new Date(
                this.aigMasterForm.originDetails[i].trcValidityDate
              ).toISOString();
            } else {
              this.aigMasterForm.originDetails[i].trcValidityDate = "";
            }
          }
          //Change date format end
          if (this.aigMasterForm.aigCode) {
            //Delete createdBy,createdById,createdAt,updatedAt,__v, file - _id
            delete this.aigMasterForm.createdBy;
            delete this.aigMasterForm.createdById;
            delete this.aigMasterForm.createdAt;
            delete this.aigMasterForm.updatedAt;
            delete this.aigMasterForm.__v;

            for (var i in this.aigMasterForm.originDetails) {
              for (var j in this.aigMasterForm.originDetails[i].documents
                .bank) {
                delete this.aigMasterForm.originDetails[i].documents.bank[j]
                  ._id;
              }

              for (var k in this.aigMasterForm.originDetails[i].documents.pe) {
                delete this.aigMasterForm.originDetails[k].documents.pe[j]._id;
              }

              for (var l in this.aigMasterForm.originDetails[i].documents.trc) {
                delete this.aigMasterForm.originDetails[i].documents.trc[l]._id;
              }

              for (var m in this.aigMasterForm.originDetails[i].documents
                .others) {
                delete this.aigMasterForm.originDetails[i].documents.others[m]
                  ._id;
              }
            }

            this.PUT_ByURLM(
              "UPDATE_OFFICE_DETAILS",
              this.aigMasterForm,
              function (res) {
                console.log(res);
                self.hideProgress();
                if (res.data.status && res.data.data) {
                  self.showAlert("AIG Office updated successfully.");
                  self.$router.push("/searchaigoffice");
                } else {
                  self.showAlert(res.data.msg);
                }
              },
              function (err) {
                console.log(err.response);
                self.hideProgress();
                let errorMsg = err.response.data.msg;
                self.showAlert("Something went wrong, please try again." + errorMsg);
              },
              this.aigMasterForm._id
            );
          } else {
            this.POSTM(
              "CREATE_AIG_OFFICE",
              this.aigMasterForm,
              function (res) {
                console.log(res);
                self.hideProgress();
                if (res.data.status && res.data.data.aigCode) {
                  self.showAlert("AIG Office added successfully with code - " + res.data.data.aigCode);
                  self.$router.push("/searchaigoffice");
                } else {
                  self.showAlert(res.data.msg);
                }
              },
              function (err) {
                console.log(err);
                self.hideProgress();
                let errorMsg = err.response.data.msg;
                self.showAlert("Something went wrong, please try again." + errorMsg);
              }
            );
          }
        }
      }else if(this.aigMasterForm.originDetails.length <= 0 && this.aigMasterForm.aigCode){
        if(confirm("Are you sure to save office without any country?")){
          if (this.aigMasterForm.aigCode) {
            //Delete createdBy,createdById,createdAt,updatedAt,__v, file - _id
            delete this.aigMasterForm.createdBy;
            delete this.aigMasterForm.createdById;
            delete this.aigMasterForm.createdAt;
            delete this.aigMasterForm.updatedAt;
            delete this.aigMasterForm.__v;
          }
          var self = this;
          this.PUT_ByURLM(
              "UPDATE_OFFICE_DETAILS",
              this.aigMasterForm,
              function (res) {
                console.log(res);
                self.hideProgress();
                if (res.data.status && res.data.data) {
                  self.showAlert("AIG Office updated successfully.");
                  self.$router.push("/searchaigoffice");
                } else {
                  self.showAlert(res.data.msg);
                }
              },
              function (err) {
                console.log(err.response);
                self.hideProgress();
                let errorMsg = err.response.data.msg;
                self.showAlert("Something went wrong, please try again." + errorMsg);
              },
              this.aigMasterForm._id
            );
        }
      }
    },
    confirmPayeeAccNumMatch() {
      if (
        this.aigMasterForm.originDetails[this.toggledCountry].payeeBank
          .confirmAccountNumber !==
        this.aigMasterForm.originDetails[this.toggledCountry].payeeBank
          .accountNumber
      ) {
        this.showAlert(
          // "Payee account number is not matching Payee confirm account number."//Old
        //   "Payee Account number is not matching with Confirm Payee Account number."
        "Payee Account number and Payee Re-enter Account number do not match."
        );
        this.aigMasterForm.originDetails[
          this.toggledCountry
        ].payeeBank.confirmAccountNumber = "";
      }
    },
    confirmCorrAccNumMatch() {
      if (
        this.aigMasterForm.originDetails[this.toggledCountry].corrBank
          .confirmAccountNumber !==
        this.aigMasterForm.originDetails[this.toggledCountry].corrBank
          .accountNumber
      ) {
        this.showAlert(
          // "Corr. Bank account number is not matching Corr. Bank confirm account number."//Old
        //   "Corr. Bank Account number is not matching with  Confirm Corr. Bank Account number"
        "Corr. Account number and Corr. Re-enter Account number do not match."
        );
        this.aigMasterForm.originDetails[
          this.toggledCountry
        ].corrBank.confirmAccountNumber = "";
      }
    },
    filesSelected() {
      let files = [];
      if (this.toggledDocType == 0) {
        files = this.bankFiles;
        // this.aigMasterForm.originDetails[this.toggledCountry].documents.bank    =   [];
      } else if (this.toggledDocType == 1) {
        files = this.peFiles;
        this.aigMasterForm.originDetails[this.toggledCountry].documents.pe = [];
      } else if (this.toggledDocType == 2) {
        files = this.trcFiles;
        this.aigMasterForm.originDetails[
          this.toggledCountry
        ].documents.trc = [];
      } else if (this.toggledDocType == 3) {
        files = this.othersFiles;
        this.aigMasterForm.originDetails[
          this.toggledCountry
        ].documents.others = [];
      }
      for (var i in files) {
        var fileName = files[i].name;
        // var base64String    =   ""
        // const fr = new FileReader ()
        // fr.readAsDataURL(files[i])
        var that = this;
        // fr.addEventListener('load', () => {
        console.log("Loaded");
        // base64String = fr.result;
        this.readImageM(fileName, files[i], (res) => {
          if (that.toggledDocType == 0) {
            that.aigMasterForm.originDetails[
              that.toggledCountry
            ].documents.bank.push(res);
            console.log(
              "this.aigMasterForm.originDetails - bank",
              that.aigMasterForm.originDetails
            );
          } else if (that.toggledDocType == 1) {
            that.aigMasterForm.originDetails[
              that.toggledCountry
            ].documents.pe.push(res);
            console.log(
              "this.aigMasterForm.originDetails - pe -",
              that.aigMasterForm.originDetails
            );
          } else if (that.toggledDocType == 2) {
            that.aigMasterForm.originDetails[
              that.toggledCountry
            ].documents.trc.push(res);
            console.log(
              "this.aigMasterForm.originDetails - trc -",
              that.aigMasterForm.originDetails
            );
          } else {
            that.aigMasterForm.originDetails[
              that.toggledCountry
            ].documents.others.push(res);
            console.log(
              "this.aigMasterForm.originDetails - others -",
              that.aigMasterForm.originDetails
            );
          }
        });
        // if(that.toggledDocType == 0){
        //     that.aigMasterForm.originDetails[that.toggledCountry].documents.bank.push({
        //         "fileName"  :   fileName,
        //         "base"      :   base64String
        //     });
        //     console.log("this.aigMasterForm.originDetails",that.aigMasterForm.originDetails)
        // }else if(that.toggledDocType == 1){
        //     that.aigMasterForm.originDetails[that.toggledCountry].documents.pe.push({
        //         "fileName"  :   fileName,
        //         "base"      :   base64String
        //     });
        //     console.log("this.aigMasterForm.originDetails",that.aigMasterForm.originDetails)
        // }else if(that.toggledDocType == 2){
        //     that.aigMasterForm.originDetails[that.toggledCountry].documents.trc.push({
        //         "fileName"  :   fileName,
        //         "base"      :   base64String
        //     });
        //     console.log("this.aigMasterForm.originDetails",that.aigMasterForm.originDetails)
        // }else{
        //     that.aigMasterForm.originDetails[that.toggledCountry].documents.others.push({
        //         "fileName"  :   fileName,
        //         "base"      :   base64String
        //     });
        //     console.log("this.aigMasterForm.originDetails",that.aigMasterForm.originDetails)

        // }
        // });
      }

      console.log("this.files ", files);
    },
    deleteThisFile(index) {
      this.showConfirm("Confirmation","Are you sure to delete this file ?","Yes","No",(Yes)=>{
                     if (this.toggledDocType == 0) {
          this.bankFiles.splice(index, 1);
          this.aigMasterForm.originDetails[
            this.toggledCountry
          ].documents.bank.splice(index, 1);
        } else if (this.toggledDocType == 1) {
          this.peFiles.splice(index, 1);
          this.aigMasterForm.originDetails[
            this.toggledCountry
          ].documents.pe.splice(index, 1);
        } else if (this.toggledDocType == 2) {
          this.trcFiles.splice(index, 1);
          this.aigMasterForm.originDetails[
            this.toggledCountry
          ].documents.trc.splice(index, 1);
        } else if (this.toggledDocType == 3) {
          this.othersFiles.splice(index, 1);
          this.aigMasterForm.originDetails[
            this.toggledCountry
          ].documents.others.splice(index, 1);
        }
        this.files.splice(index, 1);
                  },(No)=>{})
    },
    getIcon(fileType) {
      if (fileType == "image/png" || fileType == "image/jpeg") {
        return "mdi-file-image-outline";
      } else if (
        fileType ==
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        fileType == "application/vnd.ms-excel"
      ) {
        return "mdi-microsoft-excel";
      } else if (fileType == "application/pdf") {
        return "mdi-file-pdf";
      }
    },
    submitUploadFiles() {
      this.docUploadDialog = false;
    },
    uploadBtnClicked() {
      // this.$refs.fileupload.click()
      if (this.toggledDocType == 0) {
        document.getElementById("bankfileupload").click();
      } else if (this.toggledDocType == 1) {
        document.getElementById("pefileupload").click();
      } else if (this.toggledDocType == 2) {
        document.getElementById("trcfileupload").click();
      } else if (this.toggledDocType == 3) {
        document.getElementById("otherfileupload").click();
      }
    },
    showProgress() {
      this.overlay = true;
    },
    hideProgress() {
      this.overlay = false;
    },
    checkTrcApplicable() {
      if (this.aigMasterForm.originDetails[this.toggledCountry].trcApplicable) {
        this.showDocUploadDialog(true);
      }
    },
    showDocUploadDialog(isForTRC) {
      this.files = [];
      if (
        this.aigMasterForm.originDetails.length !== 0 &&
        (this.toggledCountry || this.toggledCountry == 0)
      ) {
        this.docUploadDialog = true;
        this.toggledDocType = isForTRC == true ? 2 : 0;
      } else {
        this.showAlert("Please select a country to upload the document.");
      }
    },
    updateFileType() {
      if (this.toggledDocType == 0) {
        this.files = this.bankFiles;
      } else if (this.toggledDocType == 1) {
        this.files = this.peFiles;
      } else if (this.toggledDocType == 2) {
        this.files = this.trcFiles;
      } else if (this.toggledDocType == 3) {
        this.files = this.othersFiles;
      }
    },
    validateDate(dateElem) {
      console.log(
        this.aigMasterForm.originDetails[this.toggledCountry][dateElem]
      );
      if (dateElem == "trcValidityDate") {
        if (
          new Date(
            this.aigMasterForm.originDetails[
              this.toggledCountry
            ].trcValidityDate
          ) < new Date()
        ) {
          this.showAlert("TRC Validity date should be more than today.");
          this.aigMasterForm.originDetails[
            this.toggledCountry
          ].trcValidityDate = "";
        }
      } else if (dateElem == "peValidityDate") {
        if (
          new Date(
            this.aigMasterForm.originDetails[this.toggledCountry].peValidityDate
          ) < new Date()
        ) {
          this.showAlert("PE Validity date should be more than today.");
          this.aigMasterForm.originDetails[this.toggledCountry].peValidityDate =
            "";
        }
      }
    },
    addCountry() {
      console.log("Event - ", event);
    },
    toggleToCountry(countryName, index) {
      this.bankCalenderDateFrom = false;
      this.bankCalenderDateTo = false;
      this.toggledCountryName = countryName;
      if (this.toggledCountry == index) {
        console.log("going for undefined.");
        var self = this;
        setTimeout(() => {
          console.log("self.toggledCountry - ", self.toggledCountry);
          if (self.toggledCountry !== index) {
            console.log(
              "self.toggledCountry inside - ",
              self.toggledCountry,
              "index",
              index
            );
            self.toggledCountry = index;
          }
        }, 1);
      }
    },
    checkToggleCondition() {
      console.log(event);
    },
    updateSelectedCountryList(clickedCountry) {
      this.showCountryOpt = false;
      if (this.toggledCountry == "") {
        this.toggledCountry = 0;
        this.toggledCountryName = this.selectedCountryList;
        console.log(
          "toggledCountry",
          this.toggledCountry,
          "selectedCountryList",
          this.selectedCountryList,
          "clickedCountry",
          clickedCountry
        );
      }

      //ForList,start
      // if (
      //   clickedCountry &&
      //   this.toggledCountry == undefined &&
      //   this.selectedCountryList.length === 0
      // ) {
      //   this.toggledCountry = 0;
      // }
      //ForList,end

      let newCountry = clickedCountry; //event.target.textContent;//this.selectedCountryList[this.selectedCountryList.length -1];
      console.log(
        "selectedCountryList - click ",
        newCountry,
        "clickedCountry",
        clickedCountry
      );

      // if(!this.selectedCountryList.length){
      //     console.log("Empty - ",this.aigMasterForm.originDetails);
      //     this.toggledCountry = "";
      //     this.toggledCountryName =   ""
      //     this.aigMasterForm.originDetails    =   [];

      // }else
      // if (!clickedCountry) {
      //   //event.target.textContent){
      //   var deleteIndex = "";
      //   for (var i in this.aigMasterForm.originDetails) {
      //     var self = this;
      //     if (
      //       !this.selectedCountryList.find((elem) => {
      //         return elem == self.aigMasterForm.originDetails[i].countryName;
      //       })
      //     ) {
      //       deleteIndex = i;
      //       console.log("before Delete", this.aigMasterForm.originDetails[i]);
      //       break;
      //     }
      //   }

      //   this.aigMasterForm.originDetails.splice(deleteIndex, 1);
      //   console.log(
      //     "after Delete",
      //     this.aigMasterForm.originDetails,
      //     this.toggledCountry
      //   );

      //   //Test for toggle issue
      //   if (this.toggledCountry === undefined) {
      //     if (
      //       this.selectedCountryList.length > 0 &&
      //       this.aigMasterForm.originDetails.length > 0
      //     ) {
      //       this.toggledCountry = 0;
      //     }
      //   }
      //   if (deleteIndex == this.toggledCountry) {
      //     let newToggleIndex = deleteIndex - 1 >= 0 ? deleteIndex - 1 : 0;
      //     this.toggledCountry = undefined;
      //     setTimeout(function () {
      //       this.toggledCountry = newToggleIndex;
      //     }, 300);
      //   }
      // } else
       if (
        !this.aigMasterForm.originDetails.find(function (elem) {
          return elem.countryName == newCountry;
        })
      ) {
        this.aigMasterForm.originDetails.push({
          countryName: newCountry,
          addressLine1: "",
          addressLine2: "",
          addressLine3: "",
          contactPerson1: {
            name: "",
            officeNum: {
            isd:'91',
            number:''
           },
            mobileNum: {
            isd:'91',
            number:''
           },
            email: "",
          },
          contactPerson2: {
            name: "",
            officeNum: {
            isd:'91',
            number:''
           },
            
            mobileNum: {
            isd:'91',
            number:''
           },
            email: "",
          },
          peValidityDate: "",
          trcApplicable: false,
          trcValidityDate: "",
          payeeBank: {
            bankName: "",
            bankAddressLine1: "",
            bankAddressLine2: "",
            bankAddressLine3: "",
            accountNumber: "",
            confirmAccountNumber: "",
            swiftCode: "",
            ibanNumber: "",
            sortCode: "",
          },
          corrBank: {
            bankName: "",
            bankAddressLine1: "",
            bankAddressLine2: "",
            bankAddressLine3: "",
            accountNumber: "",
            confirmAccountNumber: "",
            swiftCode: "",
            abaCode: "",
          },
          documents: {
            bank: [],
            pe: [],
            trc: [],
            others: [],
          },
        });
        console.log("Add - ", this.aigMasterForm.originDetails);
      } 
      // else {
      //   var deleteIndex = this.aigMasterForm.originDetails.findIndex(function (
      //     elem
      //   ) {
      //     return elem.countryName == newCountry;
      //   });
      //   if (
      //     this.aigMasterForm.originDetails[this.toggledCountry].countryName ==
      //     newCountry
      //   ) {
      //     let newToggleIndex = deleteIndex - 1 >= 0 ? deleteIndex - 1 : 0;
      //     this.toggledCountry = undefined;
      //     setTimeout(function () {
      //       this.toggledCountry = newToggleIndex;
      //     }, 300);

      //     this.toggledCountryName = this.selectedCountryList[newToggleIndex];
      //   }
      //   this.aigMasterForm.originDetails.splice(deleteIndex, 1);

      //   console.log("Delete - ", this.aigMasterForm.originDetails);
      // }
      //Test for toggle issue
      if (this.toggledCountry === undefined) {
        if (
          // this.selectedCountryList.length > 0 &&
          this.aigMasterForm.originDetails.length > 0
        ) {
          this.toggledCountry = 0;
        }
      }
    },
    deleteCountry() {
      if (this.toggledCountry === undefined ||(this.toggledCountry === "" && this.toggledCountry !== 0)) {
         this.showAlert("Please select a country to delete.");
      } else{
          this.showConfirm("Confirmation","Are you sure to delete this country - "+this.aigMasterForm.originDetails[this.toggledCountry].countryName+"?","Yes","No",(Yes)=>{
          var deleteIndex = this.toggledCountry;
          let newToggleIndex = deleteIndex - 1 >= 0 ? deleteIndex - 1 : 0;
          this.toggledCountry = undefined;
          // setTimeout(function(){
          //     console.log("this.toggledCountry in delete 2819 - ",this.toggledCountry)
          //     if(this.toggledCountry ==   newToggleIndex){
          //         this.toggledCountry     =   undefined;
          //     }
          //         this.toggledCountry     =   newToggleIndex;
          // },1)
          this.toggledCountryName = this.selectedCountryList;//[newToggleIndex];
          this.aigMasterForm.originDetails.splice(deleteIndex, 1);
          if(this.aigMasterForm.originDetails.length <= 0){
          this.selectedCountryList  = "";
          }
          // this.selectedCountryList.splice(deleteIndex, 1);
          console.log("Delete - ", this.aigMasterForm.originDetails);
          //  //Test for toggle issue
          // if(this.toggledCountry  ==  undefined){
          //     if(this.selectedCountryList.length > 0 && this.aigMasterForm.originDetails.length > 0){
          //         setTimeout(function(){
          //             if(this.toggledCountry !== 0 && this.toggledCountry !== "0" && this.toggledCountry  ==  undefined){
          //                 console.log("this.toggledCountry in delete 2831 - ",this.toggledCountry)
          //                 this.toggledCountry = 0;
          //             }
          //         },3)
          //     }
          // }
          },(No)=>{});
      }
    },
    compareAccNum(accType) {
      if (
        this.aigMasterForm.originDetails[this.toggledCountry].payeeBank
          .accountNumber &&
        this.aigMasterForm.originDetails[this.toggledCountry].corrBank
          .accountNumber &&
        this.aigMasterForm.originDetails[this.toggledCountry].payeeBank
          .accountNumber ==
          this.aigMasterForm.originDetails[this.toggledCountry].corrBank
            .accountNumber
      ) {
        if (accType == "corrBank") {
          this.showAlert(
            "Corr. Bank account number cannot be same as Payee Bank account number."
          );
          this.aigMasterForm.originDetails[
            this.toggledCountry
          ].corrBank.accountNumber = "";
          this.aigMasterForm.originDetails[
            this.toggledCountry
          ].corrBank.confirmAccountNumber = "";
        } else {
          this.showAlert(
            "Payee Bank account number cannot be same as Corr. Bank account number."
          );
          this.aigMasterForm.originDetails[
            this.toggledCountry
          ].payeeBank.accountNumber = "";
          this.aigMasterForm.originDetails[
            this.toggledCountry
          ].payeeBank.confirmAccountNumber = "";
        }
      }
    },
    openDownloadDialog() {
      if (
        this.aigMasterForm.originDetails.length !== 0 &&
        (this.toggledCountry || this.toggledCountry == 0)
      ) {
        this.docDownloadDialog = true;
        this.toggledDownloadDocType = 0;
      } else {
        this.showAlert("Please select a country to download the document.");
      }
    },
    closeDownloadDialog() {
      this.docDownloadDialog = false;
    },
    downloadThisFile(file) {
      console.log(file);
      if (file.fileUrl && file.base) {
        window.open(file.base);
      } else {
        this.showAlert("Download failed. This file is not uploaded.");
      }
    },
    officeNameRulesFunc(v) {
      if (!v) {
      return  this.validationMessages.mandatory;
      }
      if(v && !v.trim()){
        return "Only spaces are not allowed"
      }

      if (!(/^[a-zA-Z0-9\s]+$/.test(v))) {
        return "AIG Office Name is not valid.";
      }

      if (v && v.length < 5) {
        return "Name of AIG Office should be more than 5 digits.";
      }
      if (v && v.length > 100) {
        return "AIG Office Name should be less than 100 characters.";
      } else {
        return true;
      }
    },
    countryRulesFunc(v) {
      if (!v) {
        return this.validationMessages.mandatory;
      }

      if (/^[a-zA-Z0-9\s]+$/.test(v)) {
        return "AIG Office Name is not valid.";
      }

      if (v && v.length > 100) {
        return "AIG Office Name should be less than 100 characters.";
      } else {
        return true;
      }
    },
    address1RulesFunc(v) {
      if (!v) {
        return this.validationMessages.mandatory;
      }
      if(v && !v.trim()){
        return "Only spaces are not allowed"
      }
      if (!/^[a-zA-Z0-9\s.\-:;,/#()*%]+$/.test(v)) {
        return "Address is not valid.";
      }
      if (v && v.length > 50) {
        return "Address should be less than 50 characters.";
      } else {
        return true;
      }
    },
    address2Rules(v) {
      if (!v) {
        return this.validationMessages.mandatory;
      }
      if(v && !v.trim()){
        return "Only spaces are not allowed"
      }
      if (!/^[a-zA-Z0-9\s.\-:;}/#()*%]+$/.test(v)) {
        return "Address is not valid.";
      }
      if (v && v.length > 50) {
        return "Address should be less than 50 characters.";
      } else {
        return true;
      }
    },
    address3Rules(v) {
      if (!v) {
        return this.validationMessages.mandatory;
      }
      if(v && !v.trim()){
        return "Only spaces are not allowed"
      }
      if (!/^[a-zA-Z0-9\s.\-:;}/#()*%]+$/.test(v)) {
        return "Address is not valid.";
      }
      if (v && v.length > 50) {
        return "Address should be less than 50 characters.";
      } else {
        return true;
      }
    },
    nameRules(v) {
      if (!v) {
        return this.validationMessages.mandatory;
      }
      if(v && !v.trim()){
        return "Only spaces are not allowed"
      }
      if (!/^[a-zA-Z0-9\s]+$/.test(v)) {
        return "Name is not valid.";
      }
      if (v && v.length > 50) {
        return "Name should be less than 50 characters.";
      } else {
        return true;
      }
    },
    officeNumRules(v) {
      if (!v) {
        return this.validationMessages.mandatory;
      }
      if (!/^[0-9]+$/.test(v)) {
        return "Office number is not valid.";
      }
      if (v && v.length < 5) {
        return "Office number should be more than 5 digits.";
      }
      if (v && v.length > 20) {
        return "Office number should be less than 20 digits.";
      } else {
        return true;
      }
    },
    mobileNumRules(v) {
      if (v && v.length !== 0) {
        if (!/^[0-9]+$/.test(v)) {
          return "Mobile number is not valid.";
        }
        if (v && v.length < 5) {
          return "Mobile number should be more than 5 digits.";
        }
        if (v && v.toString().length > 20) {
          return "Mobile number should be less than 20 digits.";
        } else {
          return true;
        }
      } else {
        return true;
      }
      //     if( /(6|7|8|9)\d{9}/.test(v)){ return 'Mobile number is not valid.'
    },
    emailRules(v) {
      if (!v) {
        return this.validationMessages.mandatory;
      }
      if (!/^.+@.+\..+/.test(v)) {
        return "E-mail is not valid.";
      }
      if (v && v.length > 50) {
        return "Email should be less than 50 characters.";
      } else {
        return true;
      }
    },
    name2RuleFunc(v) {
      if (v && v.length !== 0) {
        //v => ( v.length < 0 && v.length <= 50) || 'Name should be less than 50 characters.',
        //      v => /[a-zA-Z0-9\s]+$/.test(v) || 'Name is not valid.'
        if (!/^[a-zA-Z0-9\s]+$/.test(v)) {
          return "Name is not valid.";
        }
        if (v.length > 50) {
          return "Name should be less than 50 characters.";
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    office2NumRules(v) {
      if (v && v.length !== 0) {
        if (!/^[0-9]+$/.test(v)) {
          return "Office number is not valid.";
        }
        if (v && v.length < 5) {
          return "Office number should be more than 5 digits.";
        }
        if (v && v.length > 20) {
          return "Office number should be less than 20 digits.";
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    mobile2NumRules(v) {
      if (v && v.length !== 0) {
        if (v && v.length < 5) {
          return "Mobile number should be more than 5 digits.";
        }
        if (v && v.toString().length > 20) {
          return "Mobile number should be less than 20 digits.";
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    email2Rules(v) {
      if (v && v.length !== 0) {
        if (!/^.+@.+\..+/.test(v)) {
          return "E-mail is not valid.";
        }
        if (v && v.length > 50) {
          return "Email should be less than 50 characters.";
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    bankNameRules(v) {
      if (!v) {
        return this.validationMessages.mandatory;
      }
      if(v && !v.trim()){
        return "Only spaces are not allowed"
      }
      if (!/^[a-zA-Z0-9\s]+$/.test(v)) {
        return "Bank Name is not valid.";
      }
      if (v && v.length > 100) {
        return "Bank Name should be less than 100 characters.";
      } else {
        return true;
      }
    },
    bankAddressRules(v) {
      if (!v) {
        return this.validationMessages.mandatory;
      }
      if(v && !v.trim()){
        return "Only spaces are not allowed"
      }
      if (!/^[a-zA-Z0-9\s.\-:;,/#()*%]+$/.test(v)) {
        return "Address is not valid.";
      }
      if (v && v.length > 50) {
        return "Address should be less than 50 characters.";
      } else {
        return true;
      }
    },
    bankAccNumRule(v) {
      if (v && v.length > 0 && !/^[a-zA-Z0-9]+$/.test(v)) {
        return "Account Number is not valid.";
      }
      if (v && v.length > 50) {
        return "Account Number should be less than 50 characters.";
      } else {
        return true;
      }
    },
    bankReAccNumRule(v) {
      if (v && v.length > 0 && !/^[a-zA-Z0-9]+$/.test(v)) {
        return "Account Number is not valid.";
      }
      if (v && v.length > 50) {
        return "Account Number should be less than 50 characters.";
      } else {
        return true;
      }
      //if( (v && v !== this.aigMasterForm.payeeBankDetails.bankAccNum)){ return 'Account Number does not match.'
    },
    swiftCodeRule(v) {
      if (v && v.length !== 0) {
        if (!/^[a-zA-Z0-9]+$/.test(v)) {
          return "Swift Code is not valid.";
        }
        if (v && v.length > 50) {
          return "Swift Code should be less than 50 characters.";
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    ibanCodeRule(v) {
      if (v && v.length !== 0) {
        if (!/^[a-zA-Z0-9]+$/.test(v)) {
          return "IBAN Code is not valid.";
        }
        if (v && v.length > 50) {
          return "IBAN Code should be less than 50 characters.";
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    sortCodeRule(v) {
      if (v && v.length !== 0) {
        if (!/^[a-zA-Z0-9]+$/.test(v)) {
          return "Sort Code is not valid.";
        }
        if (v && v.length > 50) {
          return "Sort Code should be less than 50 characters.";
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    corrBankNameRules(v) {
      if (v && v.length !== 0) {
        if (!/^[a-zA-Z0-9\s]+$/.test(v)) {
          return "Bank Name is not valid.";
        }
        if (v && v.length > 100) {
          return "Bank Name should be less than 100 characters.";
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    corrBankAddressRules(v) {
      if (v && v.length !== 0) {
        if (!/^[a-zA-Z0-9\s.\-:,;}/#()*%]+$/.test(v)) {
          return "Address is not valid.";
        }
        if (v && v.length > 50) {
          return "Address should be less than 50 characters.";
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    corrBankAccNumRule(v) {
      if (v && v.length !== 0) {
        if (!/^[a-zA-Z0-9]+$/.test(v)) {
          return "Account Number is not valid.";
        }
        if (v && v.length > 50) {
          return "Account Number should be less than 50 characters.";
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    corrBankReAccNumRule(v) {
      if (v && v.length !== 0) {
        if (!/^[a-zA-Z0-9]+$/.test(v)) {
          return "Account Number is not valid.";
        }
        if (v && v.length > 50) {
          return "Account Number should be less than 50 characters.";
        } else {
          return true;
        }
      } else {
        return true;
      }
      // if( (v && v !== this.aigMasterForm.corrBankDetails.bankAccNum)){ return 'Account Number does not match.'
    },
    corrSwiftCodeRule(v) {
      if (v && v.length !== 0) {
        if (!/^[a-zA-Z0-9]+$/.test(v)) {
          return "Swift Code is not valid.";
        }
        if (v && v.length > 50) {
          return "Swift Code should be less than 50 characters.";
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    corrAbaCodeRule(v) {
      if (v && v.length !== 0) {
        if (!/^[a-zA-Z0-9]+$/.test(v)) {
          return "ABA Code is not valid.";
        }
        if (v && v.length > 50) {
          return "ABA Code should be less than 50 characters.";
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    corrSortCodeRule(v) {
      if (v && v.length !== 0) {
        if (!/^[a-zA-Z0-9]+$/.test(v)) {
          return "Sort Code is not valid.";
        }
        if (v && v.length > 50) {
          return "Sort Code should be less than 50 characters.";
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    clearSearch() {
      this.search = "";
    },
    isBtnActive(index) {
      if (index == this.toggledCountry) {
        return "active_btn";
      } else {
        return "";
      }
    },
    hideCountryList(){
        this.showCountryOpt = !this.showCountryOpt;
        this.clearSearch();
    },
     validateIsd(v){
       console.log("validateIsd,",v)
              let isdExp = /^[0-9]+$/;
              if (v && !(isdExp.test(v))) {
                return 'Invalid ISD code'
              } else {
                return true;
              }
            },
  },
  computed: {
    showHideEmptyData(){
      return this.aigMasterForm.originDetails.length > 0 ? false : true;
    },
    computedType() {
      return this.otheFieldsEnteredForPAcc ? "text" : "password";
    },
    computedBankDateForm() {
      return this.aigMasterForm.originDetails[this.toggledCountry].peValidityDate  ? moment(this.aigMasterForm.originDetails[this.toggledCountry].peValidityDate).format("DD-MM-YYYY")  : "";
    },
    computedBankDateTo() {
      return this.aigMasterForm.originDetails[this.toggledCountry].trcValidityDate ? moment(this.aigMasterForm.originDetails[this.toggledCountry].trcValidityDate).format("DD-MM-YYYY") : "";
    },
    filteredItems() {
      return this.countryList.filter((item) => {
        if (!this.search) return this.countryList;
        return item.countryName
          .toLowerCase()
          .includes(this.search.toLowerCase());
      });
    },
  },
  watch: {
    toggledCountry(val) {
      console.log("val - ", val);
      if (
        this.aigMasterForm.originDetails.length > 0 &&
        val === undefined &&
        this.toggledCountry !== 0
      ) {
        this.toggledCountry = 0;
      }
    }
  },
  created() {
    this.$store.commit("SAVE_PAGE_HEADER", "AIG Office Master");
    //----------Get Country List--------------------//
    this.showProgress();
    let self = this;
    this.GETM(
      "COUNTRY_LIST",
      {},
      (res) => {
        let data = res.data.data.data;
        self.countryList = data;
        this.hideProgress();
      },
      (err) => {
        console.log(err.response);
        this.hideProgress();
        this.showAlert(
          "Something went wrong, please try again." + err.response.data.msg
        );
      }
    );
    //Check and get office details for update
    console.log("this.$route.params", this.$route.params);
    this.showProgress();
    if (this.$route && this.$route.params && this.$route.params.aigCode) {
      // if(true){
      let selectedAigOffice = this.$route.params;
      let _id = selectedAigOffice._id; //"601cde9c7f9d2d02a3c62137";//
      //  let _idCountry         =   selectedAigOffice._idCountry;
      this.GET_ByURLM(
        "GET_OFFICE_DETAILS",
        _id,
        (res) => {
          let data = res.data.data;
          this.aigMasterForm = data;
          // for (var i in this.aigMasterForm.originDetails) {
          //   this.selectedCountryList.push(
          //     this.aigMasterForm.originDetails[i].countryName
          //   );
          // }
          this.toggledCountry = 0;
          console.log(data);
          this.hideProgress();
        },
        (err) => {
          console.log(err.response);
          this.hideProgress();
          this.showAlert(
            "Something went wrong, please try again." + err.response.data.msg
          );
        }
      );
    }
  },
};
</script>

<style scoped>
.overflowNone{
  overflow: hidden !important;
}
.displayNone{
  display: none !important;
}
.positionRelative{
 position: relative !important;
}
.backgroundButton{
  background-color: #f7f7f7 !important; 
  padding: 10px !important;
}
.vlayoutAlignment{
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: flex-end !important;
}

.addyourfile{
  align-self: center !important;
}

.cursorPoniter{
  cursor: pointer !important;
}
.downloadDocTitle{
  color: #10242b !important;
  font-size: 13px !important; 
  padding: 5px !important;
}

.boxshadowUploadDoc{
  box-shadow: 0px 5px 7px #00000029 !important;
}

.boxshadowone{
flex: 1 !important; 
/* background-ox-shadow: 0px 3px 5px #00000029; */
}
.multipleDoc{
	border: 3px dashed #c1c8cc !important;
	border-bottom: none !important;
	display: flex !important;
	flex-direction: row !important; 
}
.trcappicableLayout2{
	background-color: #f7f7f7 !important;
	display: flex !important;
	flex-direction: row !important;
	justify-content: center !important;
	align-items: center !important;
	padding-left: 10px !important;
}
.bankDeatilsLayout{
  text-transform: uppercase !important;
  background-color: #01bbd9 !important;
  padding: 10px !important;
  color: #ffffff!important;
  margin-left: 10px !important;
}
.trcappicableLayout{
	background-color: #f7f7f7 !important;
	display: flex !important;
	flex-direction: row !important;
	justify-content: center !important;
	align-items: center !important;
	padding-left: 10px !important;
}
.countryListFlex{
	margin-right: 10px !important;
	overflow: auto !important;
	display: flex !important;
	flex-direction: column-start !important;

}
.countrylistAllbuttons{
	width: 200px !important;
	height: 40px !important;
	margin: 10px !important;
  text-transform:none !important;
	background-color: #ffffff !important;
	border: 1px solid #c1c8cc !important;
	color: black !important;
}
.aigOfflicText{
text-transform: uppercase  !important;
background-color: #01bbd9  !important;
padding: 10px  !important;
color: #ffffff  !important;
margin-left: 10px  !important;
}
.addcounteyRow{
margin-bottom: 0px  !important; 
overflow: hidden  !important;
}
.addcountryButton{
margin: 10px  !important;
background-color: #e46a25  !important;
color: #ffffff  !important;
 text-transform:none !important;
}
.active_btn {
  background-color: #01bbd9 !important;
}

.active_doc_btn {
  background-color: #e46a25 !important;
  color: #ffffff;
}

.row_line {
  margin: 10px;
}

.preicon_background {
  background-color: #c1c8cc;
  margin-right: 2px;
}
.v-btn {
  text-transform: none;
  cursor: pointer;
}

.iconstyle {
  margin-left: -12px;
  background-color: rgb(247, 247, 247);
  width: 45px;
  height: 38px;
  font-size: 20px !important;
  border: 0.3px solid rgb(232, 235, 236);
  opacity: 1;
  /* box-shadow: rgba(0, 0, 0, 0.318) 0px 2px 2px; */
}

.mandatory_asterisk {
  color: #d1121b;
}
</style>