<template>
    <div>
         <v-dialog
            v-model="dialog"
            width="300"
            >
        <v-card class="pa-4" >
            
        <center>
            <p class="txtsession colrnew mt-1" >Warning</p>
            <hr>
            <p class="txtsession mt-2" >{{popupmsg}}</p>
        </center>
        </v-card>
         </v-dialog>

    </div>
</template>

<script>


export default {
    name: 'sessiontracker',

    data:function() {
        return{
            warntime:null,
            logouttime:null,
            dialog:false,
            trackerpoints:['keypress','scroll','mousedown'],
            popupmsg:'Session will end soon!',
            warningTime:    29,
            autoLogoutTime: 30
        }
    },
   
    methods:{

        starttimer(){
            // console.log("timer is started !")

              this.warntime = setTimeout(this.warntimer,this.warningTime * 60 * 1000)
              this.logouttime = setTimeout(this.logoutuser,this.autoLogoutTime * 60 * 1000)
              this.dialog =false
        },

        warntimer(){
            this.popupmsg = "Session will end soon!"

            this.dialog =true

        },

        resetsession(){
            // console.log("timer is reset")
            clearTimeout(this.warntime);
            clearTimeout(this.logouttime);
            this.starttimer()
        },

        logoutuser(){
            this.popupmsg = "Now You will be logged Out"
             this.dialog =true
            console.log("Logging out from Session timeout")
            this.log_out_function()
        }
    },
    mounted(){
        
        this.trackerpoints.forEach(function(val,index){
            window.addEventListener(val, this.resetsession)
        },this)

        this.resetsession()

    },

    destroyed(){

        this.trackerpoints.forEach(function(val,index){
            window.removeEventListener(val, this.resetsession)
        },this)

        this.resetsession()

    },
    created(){
    


    },
    
}
</script>

<style>

.txtsession{
    font-size:15px;
    font-weight: 700;
}

.colrnew{color:orangered}

</style>
