<template>
    <v-app>
        <v-card class="mx-auto mt-10" flat>
            <v-row justify="center" align="center">
                <!-- <v-col cols="12" md="12"> -->
                    <v-btn-toggle dense mandatory tile class="elevation-2" background-colo="#FFFFFF" @change="tabseleted(text)" v-model="text">
                        <v-btn value="search" class="px-16 text-capitalize font-weight-bold" color="#FFFFFF" active-class="srSelected">Search</v-btn>
                        <v-btn value="create" :disabled="disCreate" class="px-16 text-capitalize font-weight-bold" color="#FFFFFF"  active-class="srSelected">Create</v-btn>
                    </v-btn-toggle>
                <!-- </v-col> -->
            </v-row>
        </v-card>
        <v-card class="mx-auto my-5"  tile width="355">
            <v-form v-model="valid"  @submit.prevent="saveAndCreate()" ref="form">
            <v-row justify="center" >
                <v-col
                cols="12"
                sm="6"
                md="11"
                class="cust_pr pa-0 ma-0 px-3 pt-5">
                    <p class="font-weight-bold cstm-clr-pr">
                        Reporting Class Code    
                    </p>
                    <!-- :disabled="disRprtngClsCode"-->
                    <v-text-field
                        solo
                        label="Enter..."
                        v-model="RprtngClsCode"
                        :rules="[required('Reporting Class Description'),spclChar(),minLength(3)]"
                        counter="10"
                        :maxlength="maxCount"
                        class="rounded-0"
                        >
                    </v-text-field>
                </v-col>

                <v-col v-if="shwmandtry===false"
                cols="12"
                sm="6"
                md="11"
                class="cust_pr pa-0 ma-0 px-3  mt-4">
                    <p class="font-weight-bold cstm-clr-pr">
                        Reporting Class Description  
                    </p>
                    <!-- :rules="[required('Reporting Class Description'),minLength(5),maxLength(40)]" -->
                    <v-text-field
                        solo
                        label="Enter..."
                        counter="40"
                        v-model="RprtngClsDscrptn"
                        :maxlength="max"
                        class="rounded-0">
                    </v-text-field>
                    
                </v-col>

                <v-col v-if="shwmandtry===true"
                cols="12"
                sm="6"
                md="11"
                class="cust_pr pa-0 ma-0 px-3  mt-4">
                    <p class="font-weight-bold cstm-clr-pr">
                        Reporting Class Description  
                    </p>
                    <!-- :rules="[required('Reporting Class Description'),minLength(5),maxLength(40)]" -->
                    <v-text-field
                        solo
                        label="Enter..."
                        counter="40"
                        v-model="RprtngClsDscrptnmndtry"
                        :rules="[required('Reporting Class Description')]"
                        :maxlength="max"
                        class="rounded-0">
                    </v-text-field>
                    
                </v-col>

            </v-row>
            <v-divider class="mt-4" ></v-divider>
            <v-row justify="center" align="center" class="py-5" v-if="shwcreate===false">
                <v-btn dark small tile  class="text-capitalize px-3 mr-3" style="font-size:12px;background-color:#E46A25" @click="saveAndCreate('search')">
                    <v-icon dark small >search</v-icon >
                    <v-divider vertical class="mx-2"></v-divider>Search
                </v-btn>
                <v-btn dark  tile style="font-size:12px;background-color:#D1121B"  small class="text-capitalize px-3 mr-1" @click="clrDta()"><v-icon dark small >delete</v-icon > <v-divider vertical class="mx-2"></v-divider>Clear</v-btn>
            </v-row>

             <v-row justify="center" align="center" class="py-5" v-if="shwcreate===true">
                <v-btn dark tile style="font-size:12px;background-color:#23B1A9"  small  class="text-capitalize px-3 mr-3" @click="saveAndCreate('create')"><v-icon dark small >check_circle</v-icon > <v-divider vertical class="mx-2"></v-divider>Submit</v-btn>
                <v-btn  dark tile style="font-size:12px;background-color:#152F38" small  class="text-capitalize px-3 " @click="$router.push('/homePage')"><v-icon dark small >cancel </v-icon> <v-divider vertical class="mx-2"></v-divider>Cancel</v-btn>
            </v-row>
            </v-form>
        </v-card>
        

        <v-card v-if="rsltfound===true" class="mx-auto mb-3"  tile width="355">
            <div  style="background-color:#23B1A9" class="pa-2 pl-3"><v-text class="font-weight-bold body-2"  style="color:#F5F5F5">Search Results</v-text></div>
                <v-data-table
                    :headers="headers"
                    :items="rsltData"
                    :page.sync="page"
                    :items-per-page="itemsPerPage"
                    hide-default-footer
                    dense
                    @page-count="pageCount = $event"
                >
                </v-data-table>
                <v-divider></v-divider>
                <v-pagination
                    class="crte-srch-Rprtng-Clss py-1"
                    v-model="page"
                    color="#152F38"
                    flat
                    :length="pageCount"
                ></v-pagination>
        </v-card>
        <v-card  v-if="rsltnotfound===true" class="mx-auto mt-1"  tile  flat width="335">
            <v-layout align-center row style="" class="brdr pa-3 " v-if="shwcreate===false">
                             <!-- <div flat class="item3 mt-2 pt-2 px-4 brdr" > -->
                                <v-text class="font-weight-bold cstm-clr-pr">
                                    No Search Results Found
                                </v-text>
                            <!-- </div> -->
                        </v-layout>
        </v-card>
    </v-app>
</template>

<script>
import axios from 'axios';

export default {

     filters:{

	    checkdata(data){// eslint-disable-line no-mixed-spaces-and-tabs
            // console.log('Validity Data', data);
            if (data == "" || data == "undefined" || data == null || data == '' || data == 'Invalid Date') {
                return "-"
            } else {
                return data;
            }
        },
     },

    components:{
      
        
    },

    created() {
        console.log("Success Token", this.$store.getters.successToken)
        this.token=this.$store.getters.successToken
        this.$store.commit("SAVE_PAGE_HEADER", "Create/Search Reporting Class");

    },
    
    data() {
        return {
            valid:false,
           RprtngClsCode:'',
           RprtngClsDscrptn:'',
           RprtngClsDscrptnmndtry:'',
            max: 40,
            page: 1,
            pageCount: 0,
            itemsPerPage: 5,    

            maxCount:10,
            token:null,
           shwcreate:false,
           RprtngClsDscrptnErr:false,
            text: 'search',
            clrbtndis:false,
            rsltfound:false,
            rsltnotfound:false,
            disCreate:true,
            rsltData:[],
            shwmandtry:false,
            disRprtngClsCode:false,
          required(){
            return v=>v && v.length>0 || "This Field is Required"
          },
          minLength(minLength){
            return v=>v && v.length>=minLength || `Must be atleast ${minLength} Characters`
          },
          maxLength(maxLength){
            return v=>v && v.length<=maxLength || `Must be less than ${maxLength} Characters`
          },
          spclChar(){
                return v=>/^[A-Za-z0-9 ]+$/.test(v) || `Special Characters Not Allowed`
            },
            headers:[
                
                {text: 'Reporting Class Code', value: 'code',sortable: false,divider:true,class:"hdr-txt-clr", width:"50%"},
                {text: 'Reporting Class Description', value: 'description',sortable: false,divider:true,class:"hdr-txt-clr",width:"50%"},
             

            ],
        }
    },

    computed: {

    },
    
    methods: {

        clrDta(){
            this.resetfrm();
            this.rsltfound=false
            this.rsltnotfound=false
        },

        createCSR(){
            // this.shwcreate=false

        },
         tabseleted(itm){
            console.log("Selected tab",itm);
            if(itm==="search"){
                this.text=itm
               this.shwcreate=false
               this.shwmandtry=false
               this.disRprtngClsCode=false
            }else if(itm==="create"){
                this.text=itm
                this.shwcreate=true
                this.shwmandtry=true
                this.disRprtngClsCode=true
                this.$refs.form.resetValidation()
                this.RprtngClsDscrptnmndtry=this.RprtngClsDscrptn===""?"":this.RprtngClsDscrptn
            }
        },
        limit( event, dataProp, limit ) {
            if ( this[dataProp].length >= limit ) {
               event.preventDefault();
            }
        },

        saveAndCreate(btnm){// eslint-disable-line no-mixed-spaces-and-tabs
	        if(btnm==="search" ){ // eslint-disable-line no-mixed-spaces-and-tabs
	            this.search() // eslint-disable-line no-mixed-spaces-and-tabs
	        }else if(btnm==="create"){// eslint-disable-line no-mixed-spaces-and-tabs
	            this.createPt()// eslint-disable-line no-mixed-spaces-and-tabs
	        }// eslint-disable-line no-mixed-spaces-and-tabs
        },

        search(){
            if (this.$refs.form.validate()){
                // console.log(this.$refs.form.validate())   
                // alert("success") 

                let self=this
                self.rsltData=[]
                // self.showLoader('Loading', true);
                let apiURL=''
                if(self.RprtngClsDscrptn===''||self.RprtngClsDscrptn===undefined||self.RprtngClsDscrptn===null){
                    apiURL=self.API_Service_admin()+"reporting-class/search?code="+self.RprtngClsCode
                }else{
                    apiURL=self.API_Service_admin()+"reporting-class/search?code="+self.RprtngClsCode+"&description="+self.RprtngClsDscrptn
                }
            
                axios({
                    method: "get",
                    url:apiURL,
                    headers: {
                        "Content-Type": "application/json",
                        "x-access-token":self.token,
                    },
                  
                })
                .then(function (response) {
                    console.log("Search reporting>>>>>>", response);
                    if(!response.data.data || response.data.data.length == 0){
                        console.log("Array is either empty or does not exist")
                        self.rsltfound=false,
                        self.rsltnotfound=true
                        self.disCreate=false
                    }else{
                        self.rsltData=response.data.data
                        self.rsltfound=true,
                        self.rsltnotfound=false
                        self.disCreate=true

                    }
                })
                .catch(function (error) {
                    console.log("Search reporting Error>>>>>>", error.response);
                        self.rsltfound=false,
                        self.rsltnotfound=true
                        self.disCreate=false
                        // self.showToast(error.response.data.msg,self.TOST().ERROR);
                });
            }else{
                this.$refs.form.validate()
                console.log(this.$refs.form.validate())
                this.showToast("Please Fill Required Fields",this.TOST().WARNING);
            }
        },

        createPt(){
            if (this.$refs.form.validate()){

                let self=this
                // self.showLoader('Loading', true);
                let demodata={
                    code:self.RprtngClsCode,
                    description:self.RprtngClsDscrptnmndtry
                }
                console.log("from demodata--------->",demodata);
                axios({
                    method: "post",
                    url:
                        this.API_Service_admin()+"reporting-class/create",
                    headers: {
                        "Content-Type": "application/json",
                        "x-access-token":self.token,
                    },
                    data:demodata,
                })
                .then(function (response) {
                    console.log("Create reporting>>>>>>", response);
                    self.resetfrm();
                    self.showToast(response.data.msg,self.TOST().SUCCESS)
                })
                .catch(function (error) {
                    console.log("Create reporting Error>>>>>>", error);
                    self.showToast(error.response.data.msg,self.TOST().ERROR);
                });
            }else{
                this.$refs.form.validate()
                this.showToast("Please Fill Required Fields",this.TOST().WARNING);
            }
        },

        resetfrm(){
            this.$refs.form.reset();
            this.tabseleted("search")
            this.disCreate=true
            this.rsltnotfound=false
            this.RprtngClsDscrptn=""
        },
        

    },

    watch: {
    
    },
}
</script>
<style>

.srSelected{
  background-color: #23B1A9 !important;
  color: #FFFFFF !important;
}

.resultContainer {
  height: 350px;
}

.item {
  height: 250px;
  width: 360px;
  margin: 10px;
}

.item2 {
  height: 60px;
  width: 360px;
  margin: 10px;
}

.item3 {
  height: 40px;
  width: 360px;
  margin: 10px;
}

.item4 {
  height: 35px;
  width: 360px;
  margin: 10px;
}

.cust_pr .v-text-field .v-input__slot,
.cust_pr .v-text-field--outline .v-input__slot {
    min-height: 42px !important;
    display: flex !important;
    align-items: center !important;
    /* border:1px red solid; */
}

/* .cust-rmvpd-pr .v-text-field.v-text-field--enclosed>.v-input__control>.v-input__slot {
    padding: 0px !important;
} */
/* .cust_pr .v-input__slot{
    margin-bottom: unset !important;
} */
.cstm-clr-pr{
    color:#646666 !important;
    margin-bottom: 5px !important;
    font-size: 13px !important;
}

.cstm-clr-csr{
    color:#646666 !important;
    /* margin-bottom: px !important; */
    font-size: 11px !important;
}
.cust_pr .v-input{
    font-size: 13px !important;
}
.cust_pr .v-input input {
    font-size: 13px !important;
}

.cust_pr .v-input .v-label {
    font-size: 13px !important;
}

.brdr{
    border: thin solid #C1C8CC;
    /* border-top-width: thin;
    border-right-width: thin;
    border-bottom-width: thin;
    border-left-width: thin;
    transition: inherit;
    border-top-color: rgba(255, 255, 255, 0.26);
    border-right-color: rgba(255, 255, 255, 0.26);
    border-bottom-color: rgba(255, 255, 255, 0.26);
    border-left-color: rgba(193, 200, 204, 0.26); */
}

.btn_pr_tabs{
  color:#10242B;
  opacity:100% !important;  
  font-size:13px !important;
  font-weight: 700;
  /* border-left:0.5px solid #C1C8CC; */

    border: solid;
    border-top-width: thin;
    border-right-width: thin;
    border-bottom-width: thin;
    border-left-width: thin;
    transition: inherit;
    border-top-color: rgba(255, 255, 255, 0.26);
    border-right-color: rgba(255, 255, 255, 0.26);
    border-bottom-color: rgba(255, 255, 255, 0.26);
    border-left-color: rgba(193, 200, 204, 0.26);
}

 .crte-srch-Rprtng-Clss .v-pagination__navigation{
        box-shadow:unset !important;
        border-radius: unset !important;
        background-color:#FFFFFF !important;
        border:1px solid #DAE1E6 !important;
        height: 30px !important;
        width: 30px !important;
    }
    .crte-srch-Rprtng-Clss .v-pagination__navigation--disabled{
        background-color:#DAE1E6 !important;
    }
    .crte-srch-Rprtng-Clss .v-pagination__item{
        box-shadow:unset !important;
        min-width:1.8rem !important;
        height:1.8rem !important;
        border-radius: unset !important;
        border:1px solid #DAE1E6 !important
    } 

    .v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
    font-size: 0.84rem !important;
}


</style>