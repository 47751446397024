<template>
    <div class="px-6 py-6">
        <v-row class="mb-2 ml-0 mr-0">
            <h3>Bulk Upload</h3>
            <v-spacer></v-spacer>
            <v-btn class="btn-toggle pull-right" outlined elevation="0" @click="switchToTdsUpload">
                <v-icon>mdi-repeat</v-icon>
                <v-divider class="mx-2 ml-2 white" vertical></v-divider>
                <span>{{
                    `Switch to ${componentNameMapping[componentName]} TDS Master`
                }}</span>
            </v-btn>
        </v-row>
        <v-data-table hide-default-footer :headers="bulkUploadTableHeader" class="mt-6 vDataTableStyling"
            :items="bulkUploadTableItems" :page.sync="page" :items-per-page="itemsPerPage">
            <template v-slot:[`item.uploadedAt`]="{ item }">
                <div>{{ formatDateTime(item.uploadedAt) }}</div>
            </template>
            <template v-slot:[`item.makerDocument`]="{ item }">
                <div @click="downloadDoc(item.makerDocument)">
                    <v-icon class="icon-color">mdi-cloud-download</v-icon>
                    <span class="icon-text">Download File</span>
                </div>
            </template>
            <template v-slot:[`item.uploadDecisionFile`]="{ item }">
                <div v-if="item.status == 'Complete'" class="download-text" @click="downloadDoc(item.checkerDocument)">
                    DOWNLOAD REPORT</div>
                <div v-else>
                    <v-icon class="icon-color">mdi-tray-arrow-up</v-icon>
                    <button @click="browse(item.uid)" :disabled="!hasEditAccess" class="icon-text">Upload Document</button>
                </div>
                <v-file-input single v-model="attachedFile" @change="selectFile(item)"
                    accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    :id="item.uid" style="display: none" @click="$event.target.value=''">
                </v-file-input>
            </template>
            <template v-slot:[`item.errorDocument`]="{ item }">
                <div v-if="item.status == 'Error'" class="download-text error-color"
                    @click="downloadDoc(item.errorDocument)">DOWNLOAD ERROR REPORT</div>
            </template>
        </v-data-table>
        <v-layout justify-end align-center class="py-2">
            <div>
                <span style="color: orange">Showing {{ pageStartIndex }}-{{ pageEndIndex }}</span>
                out of {{ totalListCount }} records
            </div>
            <div>
                <v-pagination v-model="page" :length="pageCount" :total-visible="5" @input="fetchTableData"
                    color="#152F38"></v-pagination>
            </div>
        </v-layout>
        <Validations ref="popup"></Validations>
    </div>
</template>
<script>
import moment from 'moment'
import Validations from "../../../TDS-Master/ValidationPopup.vue"
export default {
    components : { Validations },
    data() {
        return {
            componentName: "",
            componentNameMapping: {
                "concessional-penal": "Concessional and Penal",
                "base-rate": "Base Rate",
            },
            attachedFile: {},
            pageStartIndex: 0,
            page: 1,
            itemsPerPage: 10,
            totalListCount: 0,
            bulkUploadTableHeader: [
                {
                    text: "File Name",
                    value: "fileName",
                    sortable: false,
                    align: "left",
                },
                {
                    text: "Uploaded At",
                    value: "uploadedAt",
                    sortable: false,
                    align: "left",
                },
                {
                    text: "Uploaded By",
                    value: "uploadedBy",
                    sortable: false,
                    align: "left",
                },
                {
                    text: "Uploaded Status",
                    value: "status",
                    sortable: false,
                    align: "left",
                },
                {
                    text: "Download File",
                    value: "makerDocument",
                    sortable: false,
                    align: "left",
                },
                {
                    text: "Upload Decision File",
                    value: "uploadDecisionFile",
                    sortable: false,
                    align: "left",
                },
                {
                    text: "Error Upload",
                    value: "errorDocument",
                    sortable: false,
                    align: "left",
                },
            ],
            bulkUploadTableItems: [],
            baseRateExcelHeaders: [
                "Type of Vendor*",
                "PAN Code*",
                "TDS Rate With PAN*",
                "TDS Rate Without/invalid PAN*",
                "Remarks",
                "Checker decision",
            ],
            concessionalPenalExcelHeaders: [
                'Vendor Type',
                'Vendor Name',
                'PAN Number',
                'Penal TDS Applicable',
                'Penal TDS Code',
                'Concessional TDS Applicable',
                'Concessional TDS Rate',
                'Certificate reference number',
                'Certificate issue date',
                'Concessional TDS effective date',
                'Concessional TDS end effective date',
                'Certificate received date',
                'Threshold Limit',
                'Financial Year',
                'Remarks',
                'Flag',
                'Base TDS Rate',
                'Penal TDS Rate',
                "Checker decision",
                "Rejection Remarks"
            ],
        };
    },
    created() {
        this.componentName = this.$route.params?.id;
        this.$store.commit(
            "SAVE_PAGE_HEADER",
            `${this.componentNameMapping[this.componentName]} Bulk Upload`
        );
        this.fetchTableData();
    },
    methods: {
        switchToTdsUpload() {
            this.$router.push(`/tds-master-approval/${this.componentName}`);
        },
        browse(uid) {
            document.getElementById(uid).click();
        },
        fetchTableData() {
            this.showProgress();
            let newPageNum = this.page
            let masterType = this.componentName == 'base-rate' ? 'baseTds' : 'concessionalTds';
            let reqData = `pageNumber=${this.page}&pageSize=10&&masterType=${masterType}`;
            this.GETM(
                "GET_TDS_BULK_UPLOAD_CHECKER",
                reqData,
                (res) => {
                    if (
                        res &&
                        res.data &&
                        res.data.data &&
                        res.data.data.length > 0
                    ) {
                        let result = res.data.data;
                        this.totalListCount = res.data.totalCount;
                        this.bulkUploadTableItems = result
                        this.pageStartIndex = (newPageNum - 1) * this.itemsPerPage + 1
                    } else {
                        this.showAlert("No Data Found.", "", "", () => {
                            this.bulkUploadTableItems = [];
                            this.totalListCount = 0;
                            this.pageStartIndex = 0
                        });
                    }
                    this.hideProgress();
                },
                (err) => {
                    this.hideProgress();
                },
                true
            );
        },
        baseHeadersWithStatus() {
            const headers = [...this.baseRateExcelHeaders];
            headers.splice(5, 0, 'Upload Status');
            return headers;
        },
        concessionalPenalExcelHeadersWithStatus() {
            const headers = [...this.concessionalPenalExcelHeaders];
            headers.splice(18, 0, 'Upload Status');
            return headers;
        },
        applicableHeaders () {
            return this.componentName == 'base-rate' ? this.baseHeadersWithStatus() : this.concessionalPenalExcelHeadersWithStatus();
        },
        async validateHeadersSequence () {
            const fileHeaders = await this.validateExcelHeadersSequence(this.attachedFile);

            if (JSON.stringify(fileHeaders) !== JSON.stringify(this.applicableHeaders())) {
                return false;
            }

            return true;
        },
        async selectFile(item) {
            if (this.attachedFile) {
                try{
                    this.showProgress();
                const fsize = this.attachedFile.size;
                const file = Math.round(fsize / 1024);
                let self = this;
                let masterType = this.componentName == 'base-rate' ? 'baseTds' : 'concessionalTds';
                if (file > 10240) {
                    self.showAlert("File is too big, please select a file less than 10MB");
                } else {
                    const validationMessages = await this.validateHeadersWithFieldValidation();
                    if (validationMessages.length) {
                        const messages = Array.from(new Set(validationMessages));
                        this.$refs.popup.showAlert(messages);
                        this.attachedFile = {};
                        return this.hideProgress();
                    }
                    /**
                     * Validating headers sequence in uploaded file
                     */
                    const ifHeadersInSequence = await this.validateHeadersSequence();
                    if (!ifHeadersInSequence) {

                        // Showing message in dialog.
                        this.$refs.popup.showAlert(['Please enter headers in correct sequence.']);

                        /**
                         * Clear the input.
                         */
                        this.attachedFile = {};

                        return this.hideProgress();
                    }
                    self.readImageM(
                        self.attachedFile.name,
                        self.attachedFile,
                        (res) => {
                            const filePayload = {
                                fileName: res.fileName,
                                fileBase: res.base.split(',')[1],
                            }
                            const requestPayload = {file : filePayload, 'uid' : item.uid, 'masterType' :  masterType}
                            this.POSTM(
                                'POST_TDS_BULK_UPLOAD_DOCUMNENT_CHECKER',
                                requestPayload,
                                (res) => {
                                    if(res.data.statusCode == 200){
                                        this.showToast(
                                        res?.data?.msg || "File Uploaded Successfully",
                                        this.TOST().SUCCESS
                                    );
                                    }else {
                                        this.showAlert(res?.data?.message)
                                    }
                                    this.fetchTableData();
                                },
                                (error) => {
                                    const messages = error?.response?.data?.message?.errors || ['Something went wrong, Please try again'];
                                    this.$refs.popup.showAlert(messages);
                                },
                                "CM"
                            );
                            this.attachedFile = {};
                            return this.hideProgress()
                        }
                    );
                }
                }
                catch(error){
                    this.hideProgress();
	                this.showToast(error.message || 'Something went wrong, Please try again.', this.TOST().ERROR);
                }
            }
        },
        validateHeadersWithFieldValidation() {
            return new Promise((resolve, reject) => {
                this.excelToJSON(
                    this.attachedFile,
                    this.componentName == 'base-rate' ? this.validateBaseTDSExcel(resolve) : this.validateConcessionalTDSExcel(resolve),
                    (error) => reject({ ...error, message: 'Please upload correct excel file.' }),
                )
            });
        },
        validateBaseTDSExcel(resolve) {
            return (data) => {
                const validationMessages = this.validateBaseTDSBulkExcelData(data, this.baseRateExcelHeaders)
                resolve(validationMessages);
            }
        },
        validateConcessionalTDSExcel(resolve) {
            return (data) => {
                const validationMessages = this.validateConcessionalTDSBulkExcelData(data, this.concessionalPenalExcelHeaders)
                resolve(validationMessages);
            }
        },
        downloadDoc(item = []) {
            if (item[0]?.documentId && item[0]?.file) {
                const url = this.getURL("GET_DOCUMENT_BY_ID") + '?documentId=' + item[0].documentId;
                this.downloadFileByURL(url, item[0].file);
            }
        },
        formatDateTime(date) {
            return date ? moment(date).format("DD/MM/YYYY hh:mm a") : "";
        },
    },
    computed: {
        pageCount() {
            if (!this.totalListCount) {
                return 0
            } else {
                return Math.ceil(this.totalListCount / this.itemsPerPage)
            }
        },
        pageEndIndex() {
            if (!this.bulkUploadTableItems.length) {
                return 0
            } else {
                return this.pageStartIndex + this.itemsPerPage <= this.totalListCount
                    ? this.pageStartIndex + this.itemsPerPage - 1
                    : this.totalListCount
            }
        },
        hasEditAccess () {
            const routeName = this?.$router?.history?.current?.name;

            return this?.$store?.state?.screens_obj?.[routeName]?.isEdit;
        }
    }
};
</script>

<style scoped>
.btn-toggle {
    text-transform: none;
    color: #fff;
    background-color: #1ebbd9;
}

.icon-color {
    color: #1ebbd9;
}

.icon-text {
    color: #1ebbd9;
    margin-left: 10px;
    font-size: 0.84rem;
    font-weight: 500;
    text-decoration: none;
    cursor: pointer;
}

.icon-text:disabled {
    color: grey;
    cursor: not-allowed;
}

.download-text {
    font-size: 0.86rem;
    font-weight: 500;
    color: #23b1a9;
    cursor: pointer;
}

.error-color {
    color: #F44336;
}
</style>