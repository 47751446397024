<template>
  <v-container fluid>
      <v-layout class=" mb-2">
      
            <h3> {{main_heading}} AIG Master</h3>
    </v-layout>
    <v-form
      ref="officeForm"
      @submit.prevent="submitDetails"
    >
     
      <v-layout class="ml-1">
        <v-flex xs2 class="mr-2" v-if="aigMasterForm.aigCode">
          <v-layout column class="">
             <p class="mb-2 lableFontSize">
              AIG Code <span class="mandatory_asterisk"> * </span>
            </p>
            <v-text-field
              v-model="aigMasterForm.aigCode"
              placeholder="Enter.."
              class="rounded-0"
              solo
              dense
              disabled
            ></v-text-field>
          </v-layout>
        </v-flex>

        <v-flex xs2 class="mr-2">
          <v-layout column class="">
            <p class="mb-2 lableFontSize">
              Name of AIG Office <span class="mandatory_asterisk"> * </span>
            </p>
            <!-- <p class="font-weight-bold cstm-clr-pr-quick">
              Name of AIG Office <span class="mandatory_asterisk"> * </span>
            </p> -->
            <v-text-field
              v-model="aigMasterForm.nameOfAigOffice"
              placeholder="Enter.."
              class="rounded-0"
              solo
              dense
              :rules="[officeNameRulesFunc(aigMasterForm.nameOfAigOffice)]"
              :maxlength="max100"
              :disabled="$route.params.isView"
            ></v-text-field>
          </v-layout>
        </v-flex>
        <v-flex xs3>
        </v-flex>
        <v-flex xs3>
        </v-flex>
        <v-flex xs3>
        </v-flex>
      </v-layout>
      <v-divider></v-divider>

      
      <div
        v-for="(officeDetails, idx) in aigMasterForm.originDetails"
        :key="officeDetails.countryName"
      >
        
    <div > 
        <v-row  class="mb-2">
        <v-flex xs12>
          <v-layout
          @click="openRowOnEdit(idx)"
            column
            class="mx-2 mt-2 cust_pr_quick cust-rmvpd-pr-quick font-weight-bold"
            :class="[ officeDetails.finalSubmit == true ? 'aigOfflicText': 'aigOfflicTexttrue']"
            >
          <v-layout>
            <v-flex xs3 class="ml-2">
            <v-autocomplete
                            v-model="officeDetails.countryName"
                            :items="countryList"
                            item-text="countryName"
                            class="countryAuto lighten-2"    
                            dense
                            dark
                            allow-overflow:false
                            hide-details
                            color="white"
                            placeholder="Select Country"
                            @click="toggleToCountry(officeDetails.countryName, idx)"
                            v-if="!officeDetails.countryName"
                            flat
                            solo-inverted
                            ></v-autocomplete>  
                           <p class="mb-2 mt-2" v-if="officeDetails.countryName"> {{officeDetails.countryName}} </p>
            </v-flex>
            <v-flex xs3></v-flex>
            <v-flex xs3></v-flex>
            <v-flex xs4></v-flex>
            <v-flex xs2>
              <v-layout>
                 <v-btn color="transparent" elevation="0" class="text-blue" dark @click="resetItem(officeDetails,idx)" v-if="officeDetails.finalSubmit && !$route.params.isView">
                  <span>RESET</span>
                 </v-btn>
                 <v-btn color="transparent" elevation="0" class="text-blue" dark @click="deleteCountry(idx)" :class="[ officeDetails.finalSubmit == true ? 'text-blue': 'text-red margintwxt']" v-if="!$route.params.isView">
                  <span>DELETE</span>
                 </v-btn>
              </v-layout>
            </v-flex>
          </v-layout>
          </v-layout>

        </v-flex>
      </v-row>

     
      <!-- Start of Countrywise data -->
          <div v-if="officeDetails.finalSubmit">  
            <v-slider
              v-if="officeDetails.countryName"
              v-model="aigSlider"
              :tick-labels="ticksLabels"
              color="green"
              :max="3"
              step="1"
              ticks="always"
              tick-size="4"
             
            ></v-slider>
          


              <div v-if="aigSlider == 0 && officeDetails.countryName"> 
                <form ref="contactDetailsForm">
                <v-layout>
                
                  <v-flex xs2  class="mr-2">
                    <v-layout column class="ml-3">
                      
                      <p class="mb-2 lableFontSize">
                        Address Line 1<span class="mandatory_asterisk"> * </span>
                      </p>
                      <v-text-field
                        v-model="officeDetails.addressLine1"
                        placeholder="Enter.."
                        class="rounded-0"
                        solo
                        dense
                        :maxlength="address12Length"
                        :rules="[address1RulesFunc(officeDetails.addressLine1)]"
                        :disabled="$route.params.isView"
                      ></v-text-field>
                    </v-layout>
                  </v-flex>

                

                  <v-flex xs2 class="mr-2">
                    <v-layout column class="ml-3">
                      <p class="mb-2 lableFontSize">
                        Address Line 2<span class="mandatory_asterisk"> * </span>
                      </p>
                      <v-text-field
                        v-model="officeDetails.addressLine2"
                        placeholder="Enter.."
                        class="rounded-0"
                        solo
                        dense
                        :maxlength="address12Length"
                        :rules="[address2Rules(officeDetails.addressLine2)]"
                        :disabled="$route.params.isView"
                      ></v-text-field>
                    </v-layout>
                  </v-flex>

                  <v-flex xs2 class="mr-2">
                    <v-layout column class="ml-3">
                      <p class="mb-2 lableFontSize">
                        Address Line 3 <span class="mandatory_asterisk"> * </span>
                      </p>
                      <v-text-field
                        v-model="officeDetails.addressLine3"
                        placeholder="Enter.."
                        class="rounded-0"
                        solo
                        dense
                        :maxlength="address3Length"
                        :rules="[address3Rules(officeDetails.addressLine3)]"
                        :disabled="$route.params.isView"
                      ></v-text-field>
                    </v-layout>
                  </v-flex>
                  <v-flex xs2 class="mr-2">
                    <v-layout column class="mx-2 my-2"> </v-layout
                  ></v-flex>
                </v-layout>
                <v-divider></v-divider>

                <v-layout class="m-2">
                  <v-flex xs2 class="mr-2">
                    <v-layout column class="mx-2 my-2">
                      <div class="text-uppercase font-weight-bold title-clr-pr">
                        Contact Person 1
                      </div>
                      <v-divider></v-divider>
                    </v-layout>
                  </v-flex>

                  <v-flex xs2 class="mr-2">
                    <v-layout column class="mx-2 my-2">
                        <p class="mb-2 lableFontSize">
                        Name <span class="mandatory_asterisk"> * </span>
                      </p>
                      <v-text-field
                        v-model="officeDetails.contactPerson1.name"
                        placeholder="Enter.."
                        class="rounded-0"
                        solo
                        dense
                        maxlength="138"
                        :rules="[nameRules(officeDetails.contactPerson1.name)]"
                        :disabled="$route.params.isView"
                      ></v-text-field>
                    </v-layout>
                  </v-flex>

                <v-flex xs1>
                  <v-layout column class="mx-2 my-2">
                    <p class="mb-2 lableFontSize">ISD Code</p>
                    <v-text-field
                      placeholder="Enter..."
                      :maxLength="5"
                      solo
                      dense
                      v-model="officeDetails.contactPerson1.officeNum.isd"
                      :rules="[validateIsd(officeDetails.contactPerson1.officeNum.isd)]"
                      :disabled="$route.params.isView"
                      class="forminput">
                      <template v-slot:prepend-inner class="mx-0 my-0">
                        <v-icon class="iconstyle">mdi-phone</v-icon>
                      </template>
                    </v-text-field>
                  </v-layout>
                </v-flex>

                  <v-flex xs2 class="">
                    <v-layout column class="mx-2 my-2">
                      <p class="mb-2 lableFontSize">
                        Office No. <span class="mandatory_asterisk"> * </span>
                      </p>
                      <v-text-field
                        v-model="officeDetails.contactPerson1.officeNum.number"
                        placeholder="Enter.."
                        class="rounded-0"
                        solo
                        dense
                        :maxlength="max20"
                        :disabled="$route.params.isView"
                        :rules="[
                          officeNumRules(officeDetails.contactPerson1.officeNum.number),
                        ]"
                      ></v-text-field>
                    </v-layout>
                  </v-flex>
                <v-flex xs1>
                  <v-layout column class="mx-2 my-2">
                    <p class="mb-2 lableFontSize">ISD Code</p>
                    <v-text-field
                      placeholder="Enter..."
                      :maxLength="5"
                      solo
                      dense
                      v-model="officeDetails.contactPerson1.mobileNum.isd"
                      :disabled="$route.params.isView"
                      :rules="[validateIsd(officeDetails.contactPerson1.mobileNum.isd)]"
                      class="forminput">
                      <template v-slot:prepend-inner class="mx-0 my-0">
                        <v-icon class="iconstyle">mdi-phone</v-icon>
                      </template>
                    </v-text-field>
                  </v-layout>
                </v-flex>
                  <v-flex xs2 class="mr-2">
                    <v-layout column class="mx-2 my-2" >
                      <p class="mb-2 lableFontSize">Mobile No.</p>
                      <v-text-field
                        v-model="officeDetails.contactPerson1.mobileNum.number"
                        placeholder="Enter.."
                        class="rounded-0"
                        solo
                        dense
                        :maxlength="max20"
                        :disabled="$route.params.isView"
                        :rules="[
                          mobileNumRules(officeDetails.contactPerson1.mobileNum.number),
                        ]"
                      >
                        <template v-slot:prepend-inner>
                          <!-- <img width="20" height="20" src="../../assets/images/phone.png" > class="preicon_background"-->
                          <v-icon class="iconstyle"> mdi-phone </v-icon>
                        </template>
                      </v-text-field>
                    </v-layout>
                  </v-flex>

                  <v-flex xs2 class="mr-2">
                    <v-layout column class="mx-2 my-2">
                      <p class="mb-2 lableFontSize">
                        Email <span class="mandatory_asterisk"> * </span>
                      </p>
                      <v-text-field
                        v-model="officeDetails.contactPerson1.email"
                        placeholder="Enter.."
                        class="rounded-0"
                        solo
                        dense
                        :maxlength="max50"
                        :disabled="$route.params.isView"
                        :rules="[emailRules(officeDetails.contactPerson1.email)]"
                      >
                        <template v-slot:prepend-inner class="preicon_background">
                          <!-- <img width="20" height="20" src="../../assets/images/mail.png" > -->
                          <v-icon class="iconstyle"> mdi-email </v-icon>
                        </template></v-text-field
                      >
                    </v-layout>
                  </v-flex>
                </v-layout>
                <v-divider></v-divider>

                <v-layout class="mt-2">
                  <v-flex xs2 class="mr-2">
                    <v-layout column class="mx-2 my-2">
                      <div class="text-uppercase font-weight-bold title-clr-pr">
                        Contact Person 2
                      </div>
                      <v-divider></v-divider>
                    </v-layout>
                  </v-flex>

                  <v-flex xs2 class="mr-2">
                    <v-layout column class="mx-2 my-2">
                      <p class="mb-2 lableFontSize">Name</p>
                      <v-text-field
                        v-model="officeDetails.contactPerson2.name"
                        placeholder="Enter.."
                        class="rounded-0"
                        solo
                        dense
                        maxlength="138"
                        :disabled="$route.params.isView"
                        :rules="[name2RuleFunc(officeDetails.contactPerson2.name)]"
                      ></v-text-field>
                    </v-layout>
                  </v-flex>

                  <v-flex xs1>
                  <v-layout column class="mx-2 my-2">
                    <p class="mb-2 lableFontSize">ISD Code</p>
                    <v-text-field
                      placeholder="Enter..."
                      :maxLength="5"
                      solo
                      dense
                      v-model="officeDetails.contactPerson2.officeNum.isd"
                      :disabled="$route.params.isView"
                      :rules="[validateIsd(officeDetails.contactPerson2.officeNum.isd)]"
                      class="forminput">
                      <template v-slot:prepend-inner class="mx-0 my-0">
                        <v-icon class="iconstyle">mdi-phone</v-icon>
                      </template>
                    </v-text-field>
                  </v-layout>
                </v-flex>

                  <v-flex xs2 class="">
                    <v-layout column class="mx-2 my-2">
                      <p class="mb-2 lableFontSize">Office No.</p>
                      <v-text-field
                        v-model="officeDetails.contactPerson2.officeNum.number"
                        placeholder="Enter.."
                        class="rounded-0"
                        solo
                        dense
                        :maxlength="max20"
                        :disabled="$route.params.isView"
                        :rules="[
                          office2NumRules(officeDetails.contactPerson2.officeNum.number),
                        ]"
                      ></v-text-field>
                    </v-layout>
                  </v-flex>
                    
                  <v-flex xs1>
                  <v-layout column class="mx-2 my-2">
                    <p class="mb-2 lableFontSize">ISD Code</p>
                    <v-text-field
                      placeholder="Enter..."
                      :maxLength="5"
                      solo
                      dense
                      v-model="officeDetails.contactPerson2.mobileNum.isd"
                      :disabled="$route.params.isView"
                      :rules="[validateIsd(officeDetails.contactPerson2.mobileNum.isd)]"
                      class="forminput">
                      <template v-slot:prepend-inner class="mx-0 my-0">
                        <v-icon class="iconstyle">mdi-phone</v-icon>
                      </template>
                    </v-text-field>
                  </v-layout>
                </v-flex>

                  <v-flex xs2 class="mr-2">
                    <v-layout column class="mx-2 my-2">
                      <p class="mb-2 lableFontSize">Mobile No.</p>
                      <v-text-field
                        v-model="officeDetails.contactPerson2.mobileNum.number"
                        placeholder="Enter.."
                        class="rounded-0"
                        solo
                        dense
                        :maxlength="max20"
                        :disabled="$route.params.isView"
                        :rules="[
                          mobile2NumRules(officeDetails.contactPerson2.mobileNum.number),
                        ]"
                      >
                        <template v-slot:prepend-inner class="preicon_background">
                          <!-- <img width="20" height="20" src="../../assets/images/phone.png" > -->
                          <v-icon class="iconstyle"> mdi-phone </v-icon>
                        </template></v-text-field
                      >
                    </v-layout>
                  </v-flex>

                  <v-flex xs2 class="mr-2">
                    <v-layout column class="mx-2 my-2">
                      <p class="mb-2 lableFontSize">Email</p>
                      <v-text-field
                        v-model="officeDetails.contactPerson2.email"
                        placeholder="Enter.."
                        class="rounded-0"
                        solo
                        dense
                        :maxlength="max50"
                        :disabled="$route.params.isView"
                        :rules="[email2Rules(officeDetails.contactPerson2.email)]"
                      >
                        <template v-slot:prepend-inner class="preicon_background">
                          <!-- <img width="20" height="20" src="../../assets/images/mail.png" > width="20" height="20"  -->
                          <v-icon class="iconstyle"> mdi-email </v-icon>
                        </template>
                      </v-text-field>
                    </v-layout>
                  </v-flex>
                </v-layout>
                </form>
              </div>
                
              <div v-if="aigSlider == 1"> 
                <v-layout class="mt-2">
                  <!-- Validity From  -->
                  <v-flex xs3 class="ml-3">
                    <v-layout
                      column
                      class="mx-2 mt-2 cust_pr_quick cust-rmvpd-pr-quick"
                    >
                      <p class="mb-2 lableFontSize">
                        PE Validity date
                      </p>

                      <v-menu
                        ref="calender"
                        :close-on-content-click="false"
                        v-model="bankCalenderDateFrom"
                        :nudge-right="40"
                        transition="scale-transition"
                        :disabled="$route.params.isView"
                        max-width="290px"
                        min-width="290px"
                        class="rounded-0"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-bind="attrs"
                            v-on="on"
                            v-on:focus='onPEFocus'
                            label="dd/mm/yyyy"
                            readonly
                            :disabled="$route.params.isView"
                            :value="computedBankDateForm"
                            solo
                            class="rounded-0"
                            clearable
                          >
                            <template v-slot:prepend-inner>
                              <v-icon
                                @click="bankCalenderDateFrom = true"
                                class="iconstyle"
                              >
                                mdi-calendar
                              </v-icon>
                            </template>
                            <!-- <div  slot="append-inner" style="background-color:#F7F7F7;  padding: 8px !important"><img src="../assets" /></div>     -->
                            <!-- <template v-slot:append>
                              <v-icon @click="officeDetails.peValidityDate = ''"
                                >mdi-close</v-icon
                              >
                            </template> -->
                          </v-text-field>
                        </template>
                        <v-date-picker
                          v-model="officeDetails.peValidityDate"
                          @input="bankCalenderDateFrom = false"
                          no-title
                          :disabled="$route.params.isView"
                        ></v-date-picker>
                      </v-menu>
                    </v-layout>
                  </v-flex>

                  <v-flex xs3>
                    <!-- <p
                      class="font-weight-bold cstm-clr-pr-quick pa-2"
                    ></p>
                    <v-layout
                      column
                      class="mx-2 mt-2 cust_pr_quick cust-rmvpd-pr-quick trcappicableLayout">
                      <v-checkbox
                        v-model="officeDetails.trcApplicable"
                        @change="checkTrcApplicable"
                        color="#23B1A9"
                      >
                      </v-checkbox>
                      <v-flex> TRC Applicable </v-flex>
                    </v-layout> -->

                    <v-layout column class="ml-3 mt-3">
                              <!-- {{'0'+checkSurveyorCondition }}{{'1'+ isRole  }}{{'2'+isView}} -->
                              <p class="mb-2 claimlabel"></p>
                              <v-checkbox label="TRC Applicable"
                                  v-model="officeDetails.trcApplicable"
                                  @change="checkTrcApplicable" 
                                  :disabled="$route.params.isView" 
                                  class="mb-0 claim">
                              </v-checkbox>
                      </v-layout>
                  </v-flex>
                  <!-- Validity To   -->
                  <v-flex xs3>
                    <v-layout
                      column
                      class="mx-2 mt-2 cust_pr_quick cust-rmvpd-pr-quick"
                    >
                      <p class="mb-2 lableFontSize">
                        TRC Validity date
                      </p>

                      <v-menu
                        ref="calender"
                        :close-on-content-click="false"
                        v-model="bankCalenderDateTo"
                        :nudge-right="40"
                        transition="scale-transition"
                        max-width="290px"
                        :disabled="$route.params.isView"
                        min-width="290px"
                        class="rounded-0"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-bind="attrs"
                            v-on="on"
                            v-on:focus="onTRCFocus"
                            label="dd/mm/yyyy"
                            :value="computedBankDateTo"
                            solo
                            class="rounded-0"
                            :disabled="$route.params.isView"
                            readonly
                            clearable
                          >
                            <div slot="prepend-inner">
                              <v-icon
                                @click="bankCalenderDateTo = true"
                                class="iconstyle"
                              >
                                mdi-calendar
                              </v-icon>
                            </div>
                            <!-- <div  slot="append-inner" style="background-color:#F7F7F7;  padding: 8px !important"><img src="../assets" /></div>     -->
                            <!-- <template v-slot:append>
                              <v-icon @click="officeDetails.trcValidityDate = ''"
                                >mdi-close</v-icon
                              >
                            </template> -->
                          </v-text-field>
                        </template>
                        <v-date-picker
                          v-model="officeDetails.trcValidityDate"
                          no-title
                          @input="bankCalenderDateTo = false"
                          :disabled="$route.params.isView"
                        ></v-date-picker>
                      </v-menu>
                    </v-layout>
                  </v-flex>
                </v-layout>

              </div>

            <div v-if="aigSlider == 2"> 
                <v-layout class="mt-2">
                  <v-flex xs3 class="mr-2">
                    <v-layout column class="mx-2 my-2">
                      <div class="text-uppercase font-weight-bold title-clr-pr">
                        Bank Details(Payee Bank)
                      </div>
                      <v-divider></v-divider>
                    </v-layout>
                  </v-flex>

                  <v-flex xs3 class="mr-2">
                    <v-layout column class="mx-2 my-2">
                      <p class="mb-2 lableFontSize">
                        Bank Name <span class="mandatory_asterisk"> * </span>
                      </p>
                      <v-text-field
                        v-model="officeDetails.payeeBank.bankName"
                        placeholder="Enter.."
                        class="rounded-0"
                        :disabled="$route.params.isView"
                        solo
                        dense
                        :maxlength="max100"
                        :rules="[bankNameRules(officeDetails.payeeBank.bankName)]"
                      ></v-text-field>
                    </v-layout>
                  </v-flex>

                  <v-flex xs3 class="mr-2">
                    <v-layout column class="mx-2 my-2">
                      <p class="mb-2 lableFontSize">
                        Bank Address Line 1
                        <span class="mandatory_asterisk"> * </span>
                      </p>
                      <v-text-field
                        v-model="officeDetails.payeeBank.bankAddressLine1"
                        placeholder="Enter.."
                        class="rounded-0"
                        solo
                        dense
                        :maxlength="address12Length"
                        :disabled="$route.params.isView"
                        :rules="[
                          bankAddressRules(officeDetails.payeeBank.bankAddressLine1),
                        ]"
                      ></v-text-field>
                    </v-layout>
                  </v-flex>

                  <v-flex xs3 class="mr-2">
                    <v-layout column class="mx-2 my-2">
                      <p class="mb-2 lableFontSize">
                        Bank Address Line 2
                        <span class="mandatory_asterisk"> * </span>
                      </p>
                      <v-text-field
                        v-model="officeDetails.payeeBank.bankAddressLine2"
                        placeholder="Enter.."
                        class="rounded-0"
                        solo
                        dense
                        :maxlength="address12Length"
                        :disabled="$route.params.isView"
                        :rules="[
                          bankAddressRules(officeDetails.payeeBank.bankAddressLine2),
                        ]"
                      ></v-text-field>
                    </v-layout>
                  </v-flex>

                  <v-flex xs3 class="mr-2">
                    <v-layout column class="mx-2 my-2">
                      <p class="mb-2 lableFontSize">
                        Bank Address Line 3
                        <span class="mandatory_asterisk"> * </span>
                      </p>
                      <v-text-field
                        v-model="officeDetails.payeeBank.bankAddressLine3"
                        placeholder="Enter.."
                        class="rounded-0"
                        solo
                        dense
                        :maxlength="address3Length"
                        :disabled="$route.params.isView"
                        :rules="[
                          bankAddressRules(officeDetails.payeeBank.bankAddressLine3),
                        ]"
                      ></v-text-field>
                    </v-layout>
                  </v-flex>
                </v-layout>
                <!--------------->
                <v-layout class="mt-2">
                  <v-flex xs3 class="mr-2">
                    <v-layout column class="mx-2 my-2">
                      <p class="mb-2 lableFontSize">Account Number <span class="mandatory_asterisk"> * </span></p>
                      <v-text-field
                        v-model="officeDetails.payeeBank.accountNumber"
                        placeholder="Enter.."
                        class="rounded-0"
                        solo
                        dense
                        :maxlength="max50"
                        :disabled="$route.params.isView"
                        type="password"
                        @keyup="uppercase('payeeBank.accountNumber',idx)"
                        @copy.prevent 
                        @paste.prevent 
                        @click.right.prevent
                        @change="compareAccNum"
                        :rules="[
                          bankAccNumRule(officeDetails.payeeBank.accountNumber), mandatoryRules(officeDetails.payeeBank.accountNumber)
                        ]"
                      ></v-text-field>
                    </v-layout>
                  </v-flex>

                  <v-flex xs3 class="mr-2">
                    <v-layout column class="mx-2 my-2">
                      <p class="mb-2 lableFontSize">
                        Re-Enter Account Number <span class="mandatory_asterisk"> * </span>
                      </p>
                      <v-text-field
                        v-model="officeDetails.payeeBank.confirmAccountNumber"
                        placeholder="Enter.."
                        class="rounded-0"
                        solo
                        dense
                        :maxlength="max50"
                        :disabled="$route.params.isView"
                        @keyup="uppercase('payeeBank.confirmAccountNumber',idx)"
                        @copy.prevent 
                        @paste.prevent 
                        @click.right.prevent
                        @change="confirmPayeeAccNumMatch"
                        :rules="[
                          bankReAccNumRule(
                            officeDetails.payeeBank.confirmAccountNumber
                          ), mandatoryRules(officeDetails.payeeBank.confirmAccountNumber)
                        ]"
                      ></v-text-field>
                    </v-layout>
                  </v-flex>

                  <v-flex xs3 class="mr-2">
                    <v-layout column class="mx-2 my-2">
                      <p class="mb-2 lableFontSize">Swift Code <span class="mandatory_asterisk"> * </span></p>
                      <v-text-field
                        v-model="officeDetails.payeeBank.swiftCode"
                        placeholder="Enter.."
                        class="rounded-0"
                        :disabled="$route.params.isView"
                        solo
                        dense
                        :maxlength="max50"
                        :rules="[swiftCodeRule(officeDetails.payeeBank.swiftCode), mandatoryRules(officeDetails.payeeBank.swiftCode)]"
                      ></v-text-field>
                    </v-layout>
                  </v-flex>

                  <v-flex xs3 class="mr-2">
                    <v-layout column class="mx-2 my-2">
                      <p class="mb-2 lableFontSize">IBAN Number <span class="mandatory_asterisk"> * </span></p>
                      <v-text-field
                        v-model="officeDetails.payeeBank.ibanNumber"
                        placeholder="Enter.."
                        class="rounded-0"
                        :disabled="$route.params.isView"
                        solo
                        dense
                        :maxlength="max50"
                        :rules="[ibanCodeRule(officeDetails.payeeBank.ibanNumber), mandatoryRules(officeDetails.payeeBank.ibanNumber)]"
                      ></v-text-field>
                    </v-layout>
                  </v-flex>

                  <v-flex xs3 class="mr-2">
                    <v-layout column class="mx-2 my-2">
                      <p class="mb-2 lableFontSize">Sort Code <span class="mandatory_asterisk"> * </span></p>
                      <v-text-field
                        v-model="officeDetails.payeeBank.sortCode"
                        placeholder="Enter.."
                        class="rounded-0"
                        :disabled="$route.params.isView"
                        solo
                        dense
                        :maxlength="max50"
                        :rules="[sortCodeRule(officeDetails.payeeBank.sortCode)]"
                      ></v-text-field>
                    </v-layout>
                  </v-flex>
                </v-layout>
                <!--------------->
                <v-divider></v-divider>
                <v-layout class="mt-2">
                  <v-flex xs3 class="mr-2">
                    <v-layout column class="mx-2 my-2">
                      <div class="text-uppercase font-weight-bold title-clr-pr">
                        Bank Details(CORR. Bank)
                      </div>
                      <v-divider></v-divider>
                    </v-layout>
                  </v-flex>

                  <v-flex xs3 class="mr-2">
                    <v-layout column class="mx-2 my-2">
                    <p class="mb-2 lableFontSize">Bank Name</p>
                      <v-text-field
                        v-model="officeDetails.corrBank.bankName"
                        placeholder="Enter.."
                        class="rounded-0"
                        :disabled="$route.params.isView"
                        solo
                        dense
                        :maxlength="max100"
                        :rules="[corrBankNameRules(officeDetails.corrBank.bankName)]"
                      ></v-text-field>
                    </v-layout>
                  </v-flex>

                  <v-flex xs3 class="mr-2">
                    <v-layout column class="mx-2 my-2">
                      <p class="mb-2 lableFontSize">
                        Bank Address Line 1
                      </p>
                      <v-text-field
                        v-model="officeDetails.corrBank.bankAddressLine1"
                        placeholder="Enter.."
                        class="rounded-0"
                        solo
                        dense
                        :maxlength="address12Length"
                        :disabled="$route.params.isView"
                        :rules="[
                          corrBankAddressRules(
                            officeDetails.corrBank.bankAddressLine1
                          ),
                        ]"
                      ></v-text-field>
                    </v-layout>
                  </v-flex>

                  <v-flex xs3 class="mr-2">
                    <v-layout column class="mx-2 my-2">
                      <p class="mb-2 lableFontSize">
                        Bank Address Line 2
                      </p>
                      <v-text-field
                        v-model="officeDetails.corrBank.bankAddressLine2"
                        placeholder="Enter.."
                        class="rounded-0"
                        solo
                        dense
                        :maxlength="address12Length"
                        :disabled="$route.params.isView"
                        :rules="[
                          corrBankAddressRules(
                            officeDetails.corrBank.bankAddressLine2
                          ),
                        ]"
                      ></v-text-field>
                    </v-layout>
                  </v-flex>

                  <v-flex xs3 class="mr-2">
                    <v-layout column class="mx-2 my-2">
                      <p class="mb-2 lableFontSize">
                        Bank Address Line 3
                      </p>
                      <v-text-field
                        v-model="officeDetails.corrBank.bankAddressLine3"
                        placeholder="Enter.."
                        class="rounded-0"
                        solo
                        dense
                        :maxlength="address3Length"
                        :disabled="$route.params.isView"
                        :rules="[
                          corrBankAddressRules(
                            officeDetails.corrBank.bankAddressLine3
                          ),
                        ]"
                      ></v-text-field>
                    </v-layout>
                  </v-flex>
                </v-layout>

                <v-layout class="mt-2">
                  <v-flex xs3 class="mr-2">
                    <v-layout column class="mx-2 my-2">
                      <p class="mb-2 lableFontSize">Account Number</p>
                      <v-text-field
                        v-model="officeDetails.corrBank.accountNumber"
                        placeholder="Enter.."
                        class="rounded-0"
                        solo
                        dense
                        type="password"
                        :maxlength="max50"
                        :disabled="$route.params.isView"
                        @keyup="uppercase('corrBank.accountNumber',idx)"
                        @copy.prevent 
                        @paste.prevent 
                        @click.right.prevent
                        @change="compareAccNum('corrBank')"
                        :rules="[
                          corrBankAccNumRule(officeDetails.corrBank.accountNumber),
                        ]"
                      ></v-text-field>
                    </v-layout>
                  </v-flex>

                  <v-flex xs3 class="mr-2">
                    <v-layout column class="mx-2 my-2">
                      <p class="mb-2 lableFontSize">
                        Re-Enter Account Number
                      </p>
                      <v-text-field
                        v-model="officeDetails.corrBank.confirmAccountNumber"
                        placeholder="Enter.."
                        class="rounded-0"
                        solo
                        dense
                        :maxlength="accountNumberLength"
                        :disabled="$route.params.isView"
                        @keyup="uppercase('corrBank.confirmAccountNumber',idx)"
                        @copy.prevent 
                        @paste.prevent 
                        @click.right.prevent
                        @change="confirmCorrAccNumMatch"
                        :rules="[
                          corrBankReAccNumRule(
                            officeDetails.corrBank.confirmAccountNumber
                          ),
                        ]"
                      ></v-text-field>
                    </v-layout>
                  </v-flex>

                  <v-flex xs3 class="mr-2">
                    <v-layout column class="mx-2 my-2">
                      <p class="mb-2 lableFontSize">Swift Code</p>
                      <v-text-field
                        v-model="officeDetails.corrBank.swiftCode"
                        placeholder="Enter.."
                        class="rounded-0"
                        solo
                        dense
                        :maxlength="accountNumberLength"
                        :disabled="$route.params.isView"
                        :rules="[corrSwiftCodeRule(officeDetails.corrBank.swiftCode)]"
                      ></v-text-field>
                    </v-layout>
                  </v-flex>

                  <v-flex xs3 class="mr-2">
                    <v-layout column class="mx-2 my-2">
                      <p class="mb-2 lableFontSize">ABA Number</p>
                      <v-text-field
                        v-model="officeDetails.corrBank.abaCode"
                        placeholder="Enter.."
                        class="rounded-0"
                        solo
                        dense
                        :maxlength="max50"
                        :disabled="$route.params.isView"
                        :rules="[corrAbaCodeRule(officeDetails.corrBank.abaCode)]"
                      ></v-text-field>
                    </v-layout>
                  </v-flex>
                <v-flex xs3 class="mr-2">
                      <v-checkbox
                          class="checkpadding"
                            v-model="aigMasterForm.isActive"
                            :disabled="$route.params.isView"
                            label="IsActive"
                            v-if="aigMasterForm._id"
                          ></v-checkbox>
                </v-flex>            



                </v-layout>
                
            </div>

          <div v-if="aigSlider == 3">
             <v-layout>
                  <v-btn-toggle tile mandatory class="ml-4 mt-3" v-model="docType" @change="checkDocType(docType)">
                      <v-btn value="BANkdocument" class="text-capitalize font-weight-bold pr-3 mr-1 claimbtnstyle"
                              style="height:30px" :class="[ BANk == true ? 'activebg': 'inactivebg']">BANK DETAILS 
                      </v-btn>
                      <v-btn value="PEdocument" class="text-capitalize font-weight-bold pr-3 mr-1 claimbtnstyle"
                              style="height:30px" :class="[ PE == true ? 'activebg': 'inactivebg']">PERMANANT ESTABLISHMENT (PE) CERTIFICATE
                      </v-btn>
                      <v-btn value="TRCducument" class="text-capitalize font-weight-bold pr-3 mr-1 claimbtnstyle"
                              style="height:30px" :class="[ TRC == true ? 'activebg': 'inactivebg']">TAX RESIDENCY CERTIFICATE (TRC)
                      </v-btn>
                      <v-btn value="OTHERducument" class="text-capitalize font-weight-bold pr-3 mr-1 claimbtnstyle"
                              style="height:30px" :class="[ OTHER == true ? 'activebg': 'inactivebg']">OTHERS
                      </v-btn>
                      
                  </v-btn-toggle>
           </v-layout>
            <v-layout class="mr-7 ml-7 mt-1">
              
            <v-flex class="xs6">
           <div class="dragDropDiv flex w-full h-screen items-center justify-center text-center" id="app" @dragover="dragover" @dragleave="dragleave" @drop="drop">
            <div class="mt-8">
            <v-icon color="#23b1a9" class="iconfont">mdi-tray-arrow-up</v-icon><span class="ml-2 browsetext font-weight-bold">Drop Files To Upload or <a @click="browsedocs(idx)"><u> Browse</u></a></span>
                    <p class="browsetext margincls dlt_text mb-0">Max 5MB of PDF or Image(.jpeg, .jpg, .png)</p>
                    <p class="dlt_text mt-0 ml-4" style="font-size:10px">Multiple documents can be uploaded</p>
                    <span></span>
                        <v-file-input
                            multiple
                            v-model="dummyDataList"
                            @change="onChange()"
                            accept=".doc, .docx, .csv, image/png,image/jpeg,application/msword,application/pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/csv"
                            id="assetsFieldHandle"
                            :disabled="$route.params.isView"
                            style="display: none">
                        </v-file-input>
                      </div>
                </div>
                <!-- <v-flex class="docborderStyle" @dragover="dragover" @dragleave="dragleave" @drop="drop">   
                    <v-icon color="#23b1a9" class="iconfont">mdi-tray-arrow-up</v-icon><span class="ml-2 browsetext font-weight-bold">Drop Files To Upload or <a @click="browsedocs(idx)"><u> Browse</u></a></span>
                    <p class="browsetext margincls dlt_text mb-0">Max 5MB of PDF or Image(.jpeg, .jpg, .png)</p>
                    <p class="dlt_text mt-0 ml-4" style="font-size:10px">Multiple documents can be uploaded</p>
                    <span></span>
                        <v-file-input
                            multiple
                            v-model="dummyDataList"
                            @change="onChange()"
                            accept=".doc, .docx, .csv, image/png,image/jpeg,application/msword,application/pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/csv"
                            id="assetsFieldHandle"
                            style="display: none">
                        </v-file-input>
                </v-flex> -->
            </v-flex>
            <v-flex class="xs6">
                <v-data-table v-if="docType == 'BANkdocument'" disable-pagination :headers="FileUploadHeader" :items="officeDetails.documents.bank" hide-default-footer class="attach sptable" >
                    <template v-slot:[`item`]="{ item ,index}">
                        <tr>
                            <td>{{ item.fileName || item.file }}</td>
                            <!-- <td>{{ item.documentType }}</td> -->
                            <td>
                                <!-- <v-btn v-if="item" color="#E46A25" class="buttonscolor mr-5" dark @click="viewDocument(item)">
                                    <v-icon class="mr-2 fontClass"> mdi-eye </v-icon>
                                    <div class="vertical_line"></div>
                                    View
                                </v-btn> -->
                                 <v-icon class="tableicons mr-2" color="#23B1A9" @click="viewDocument(item)" :disabled="$route.params.isView">mdi-eye</v-icon>
                                  <v-icon class="tableicons mr-2" color="#23B1A9" @click="downloadDoc(item)" :disabled="$route.params.isView" >mdi-tray-arrow-down</v-icon>
                                  <v-icon class="tableicons" color="#D1121B" @click="remove(idx,item, index)" :disabled="$route.params.isView">mdi-delete</v-icon> 
                            </td>
                            <!-- <td>
                                <button class="ml-2" type="button" v-if="!item._id" @click="remove(idx,item)" title="Remove file">
                                    <v-icon class="mr-2 createfont">mdi-trash-can-outline</v-icon>
                                </button>
                            </td> -->
                        </tr>
                    </template>
                </v-data-table>

                <v-data-table v-if="docType == 'PEdocument'" disable-pagination :headers="FileUploadHeader" :items="officeDetails.documents.pe" hide-default-footer class="attach sptable">
                    <template v-slot:[`item`]="{ item ,index}">
                        <tr>
                            <td>{{ item.fileName || item.file }}</td>
                            <!-- <td>{{ item.documentType }}</td> -->
                            <td>
                                <!-- <v-btn v-if="item" color="#E46A25" class="buttonscolor mr-5" dark @click="viewDocument(item)">
                                    <v-icon class="mr-2 fontClass"> mdi-eye </v-icon>
                                    <div class="vertical_line"></div>
                                    View
                                </v-btn> -->
                                 <v-icon class="tableicons mr-2" color="#23B1A9" @click="viewDocument(item)" :disabled="$route.params.isView">mdi-eye</v-icon>
                                  <v-icon class="tableicons mr-2" color="#23B1A9" @click="downloadDoc(item)" :disabled="$route.params.isView">mdi-tray-arrow-down</v-icon>
                                  <v-icon class="tableicons" color="#D1121B" @click="remove(idx,item, index)" :disabled="$route.params.isView">mdi-delete</v-icon> 
                            </td>
                            <!-- <td>
                                <button class="ml-2" type="button" v-if="!item._id" @click="remove(idx,item)" title="Remove file">
                                    <v-icon class="mr-2 createfont">mdi-trash-can-outline</v-icon>
                                </button>
                            </td> -->
                        </tr>
                    </template>
                </v-data-table>

                 <v-data-table v-if="docType == 'TRCducument'" disable-pagination :headers="FileUploadHeader" :items="officeDetails.documents.trc" hide-default-footer class="attach sptable">
                    <template v-slot:[`item`]="{ item ,index}">
                        <tr>
                            <td>{{ item.fileName || item.file }}</td>
                            <!-- <td>{{ item.documentType }}</td> -->
                            <td>
                                <!-- <v-btn v-if="item" color="#E46A25" class="buttonscolor mr-5" dark @click="viewDocument(item)">
                                    <v-icon class="mr-2 fontClass"> mdi-eye </v-icon>
                                    <div class="vertical_line"></div>
                                    View
                                </v-btn> -->
                                 <v-icon class="tableicons mr-2" color="#23B1A9" @click="viewDocument(item)" :disabled="$route.params.isView">mdi-eye</v-icon>
                                  <v-icon class="tableicons mr-2" color="#23B1A9" @click="downloadDoc(item)" :disabled="$route.params.isView">mdi-tray-arrow-down</v-icon>
                                  <v-icon class="tableicons" color="#D1121B" @click="remove(idx,item, index)" :disabled="$route.params.isView">mdi-delete</v-icon> 
                            </td>
                            <!-- <td>
                                <button class="ml-2" type="button" v-if="!item._id" @click="remove(idx,item)" title="Remove file">
                                    <v-icon class="mr-2 createfont">mdi-trash-can-outline</v-icon>
                                </button>
                            </td> -->
                        </tr>
                    </template>
                </v-data-table>

                 <v-data-table v-if="docType == 'OTHERducument'" disable-pagination :headers="FileUploadHeader" :items="officeDetails.documents.others" hide-default-footer class="attach sptable">
                    <template v-slot:[`item`]="{ item ,index}">
                        <tr>
                            <td>{{ item.fileName || item.file }}</td>
                            <!-- <td>{{ item.documentType }}</td> -->
                            <td>
                                <!-- <v-btn v-if="item" color="#E46A25" class="buttonscolor mr-5" dark @click="viewDocument(item)">
                                    <v-icon class="mr-2 fontClass"> mdi-eye </v-icon>
                                    <div class="vertical_line"></div>
                                    View
                                </v-btn> -->
                                 <v-icon class="tableicons mr-2" color="#23B1A9" @click="viewDocument(item)" :disabled="$route.params.isView">mdi-eye</v-icon>
                                  <v-icon class="tableicons mr-2" color="#23B1A9" @click="downloadDoc(item)" :disabled="$route.params.isView">mdi-tray-arrow-down</v-icon>
                                  <v-icon class="tableicons" color="#D1121B" @click="remove(idx,item, index)" :disabled="$route.params.isView">mdi-delete</v-icon> 
                            </td>
                            <!-- <td>
                                <button class="ml-2" type="button" v-if="!item._id" @click="remove(idx,item)" title="Remove file">
                                    <v-icon class="mr-2 createfont">mdi-trash-can-outline</v-icon>
                                </button>
                            </td> -->
                        </tr>
                    </template>
                </v-data-table>
            </v-flex>
        </v-layout>

    </div>


              <v-row class="mt-4 mx-1 mb-4" v-if="officeDetails.countryName">
                  <v-col align="left" class="px-1">
                    
                      <v-btn color="#23B1A9" dark class="btnstyle mr-2" @click="previousTotheSLider"  v-if="aigSlider != 0" :disabled="$route.params.isView">
                          <v-icon class="mr-2 fontStyle">mdi-arrow-left-circle</v-icon>
                          <div class="vertical_line"></div>Previous
                      </v-btn>               
                  </v-col>
                  <v-col align="right" >
                      <v-btn color="#23B1A9"  dark class="btnstyle mr-2" @click="nextTotheSLider" v-if="aigSlider != 3" :disabled="$route.params.isView">
                          <v-icon class="mr-2 fontStyle">mdi-arrow-right-circle</v-icon>
                          <div class="vertical_line"></div>Next
                      </v-btn>

                      <v-btn dark  class="btnstyle mr-2" @click="saveRow(officeDetails)" v-if="aigSlider == 3" :disabled="$route.params.isView">
                          <v-icon class="mr-2 fontStyle">mdi-arrow-right-circle</v-icon>
                          <div class="vertical_line"></div>Save
                      </v-btn>
                  </v-col>
              </v-row>
              <v-divider></v-divider>
          </div>
        </div>
      </div>

      <!-- v-if="IssaveRow" -->
          <p class="text-center mt-2 textstyle" @click="addaigRow()" v-if="!$route.params.isView" ><v-icon class="textstyle mb-1">mdi-plus</v-icon> Add Country</p>

      <!-- Start Empty -->
      <!-- v-if="selectedCountryList.length == 0"  commented by gopikrishna on 22feb2021 for hide show-->
      
      <!-- End Empty -->
      <!-- End of Countrywise data --> 

       <v-row class="mt-4 mx-1">
            <v-col align="left" class="px-1">
               
                <v-btn color="#D1121B" outlined dark class="btnstyle reset ml-3" @click="CancelFormAig">
                    <v-icon class="mr-2 fontStyle">mdi-backspace</v-icon>
                    <div class="red_vertical"></div>Cancel
                </v-btn>                
            </v-col>
            <v-col align="right" >
                <v-btn color="#23B1A9"  dark class="btnstyle mr-2" type="submit"  :disabled="$route.params.isView || !this.IssaveRow">
                    <v-icon class="mr-2 fontStyle">mdi-arrow-right-circle</v-icon>
                    <div class="vertical_line"></div>Submit
                </v-btn>
            </v-col>
            <v-col align="right" v-if="$route.params.isApproval">
              <v-btn
                color="#D1121B"
                outlined
                dark
                class="btnstyle reset mr-3"
                @click="reject_Dialog = true"
              >
                <v-icon class="mr-2 fontStyle">mdi-backspace</v-icon>
                <div class="red_vertical"></div>
                Reject
              </v-btn>
              <v-btn
                color="#23B1A9"
                dark
                class="btnstyle mr-2"
                @click="approveAig"
              >
                <v-icon class="mr-2 fontStyle">mdi-arrow-right-circle</v-icon>
                <div class="vertical_line"></div>
                Approve
              </v-btn>
            </v-col>
        </v-row>

      <!-- <v-row  class="row_line backgroundButton">
        <v-flex>
          <v-layout
          class="vlayoutAlignment"
          >
            <v-btn
              medium
              right
              type="submit"
              class="rounded-0 ma-2"
              dark
              color="#23b1a9"
               :disabled="$route.params.isView"
            >
              <v-icon class="mr-2"> mdi-check-circle </v-icon>
              <div class="vertical_line"></div>
              Save
            </v-btn>

            <v-btn
              medium
              right
              class="rounded-0 ma-2"
               dark
              color="#d1121b"
              @click="deleteCountry"
               :disabled="$route.params.isView"
            >
              <v-icon class="mr-2">
                mdi-trash-can-outline
              </v-icon>
              <div class="vertical_line"></div>
              Delete
            </v-btn>

            <v-btn
              medium
              right
              class="rounded-0 ma-2"
              color="#23b1a9"
              dark
              v-if="aigMasterForm._id"
              @click="openDownloadDialog"
               :disabled="$route.params.isView"
            >
              <v-icon class="mr-2"> mdi-cloud-download </v-icon>
              <div class="vertical_line"></div>
              Download
            </v-btn>

            <v-btn
              medium
              right
              class="rounded-0 ma-2"
              color="#e46a25"
              dark
              @click="showDocUploadDialog"
               :disabled="$route.params.isView"
            >
              <v-icon class="mr-2"> mdi-cloud-upload </v-icon>
              <div class="vertical_line"></div>
              Upload Document
            </v-btn>
          </v-layout>
        </v-flex>
      </v-row> -->
    </v-form>
    <!-- <v-dialog
      v-model="docUploadDialog"
      persistent
      width="600px"
      height="700px"
      max-width="800px"
      max-height="800px"
      class="rounded-0 overflowNone"
    >
      <v-card
        color="#F7F7F7"
        class="pb-2 overflowNone"
        tile
      >
        <v-card tile class="boxshadowUploadDoc">
          <v-layout
            justify-space-between
            class="boxshadowone"
          >
            <v-layout align-center>
              <v-card-title
                class="pa-0 pl-3 text-capitalize font-weight-bold downloadDocTitle"
                >Upload Document</v-card-title
              >
            </v-layout>
            <div @click="docUploadDialog = false">
              <v-icon  class="pa-2 cursorPoniter">mdi-close</v-icon>
            </div>
          </v-layout>
        </v-card>
        <div class="pa-2">
          <v-card>
            <v-btn-toggle
              v-model="toggledDocType"
              active-class="active_doc_btn"
              class="pa-5"
              @change="updateFileType"
            >
              <v-row>
                <v-btn medium rounded class="ma-2">
                  Bank details
                </v-btn>
                <v-btn medium rounded class="ma-2">
                  Permanent Establishment (PE) Certificate
                </v-btn>
                
                <v-btn medium rounded class="ma-2">
                  Tax Residency Certificate (TRC)
                </v-btn>
                <v-btn rounded medium class="ma-2"> Others </v-btn>
              </v-row>
            </v-btn-toggle>
          </v-card>

          <v-card class="pa-5 mb-2" >
            <v-flex class="mb-2">
              Multiple documents can be uploaded
            </v-flex>
            <v-flex
              xs12
              class="multipleDoc">
              <v-flex XS4></v-flex>
              <v-flex xs2>
                
              </v-flex>
              <v-flex xs4 class="addyourfile"
                >Add your files here</v-flex
              >
              <v-flex> </v-flex>
            </v-flex>

            <v-btn
              color="#01BBD9"
              block
              dark
              @click="uploadBtnClicked"
            >
              <v-icon class="mr-1">mdi-cloud-upload</v-icon>
              <div class="vertical_line"></div>
              Upload
            </v-btn>
            <v-file-input
              multiple
              v-model="bankFiles"
              id="bankfileupload"
              ref="fileupload"
              class="displayNone"
              @change="filesSelected"
              accept="image/png,image/jpeg,application/msword,application/pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            >
            </v-file-input>
            <v-file-input
              multiple
              v-model="peFiles"
              id="pefileupload"
              ref="fileupload"
              class="displayNone"
              @change="filesSelected"
              accept="image/png,image/jpeg,application/msword,application/pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            >
            </v-file-input>
            <v-file-input
              multiple
              v-model="trcFiles"
              id="trcfileupload"
              ref="fileupload"
              class="displayNone"
              @change="filesSelected"
              accept="image/png,image/jpeg,application/msword,application/pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            >
            </v-file-input>
            <v-file-input
              multiple
              v-model="othersFiles"
              id="otherfileupload"
              ref="fileupload"
              class="displayNone"
              @change="filesSelected"
              accept="image/png,image/jpeg,application/msword,application/pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            >
            </v-file-input>
        
            <v-simple-table v-if="toggledDocType == 0">
              <template v-slot:default>
                <tbody>
                  <tr
                    v-for="(file, index) in aigMasterForm.originDetails[
                      toggledCountry
                    ].documents.bank"
                    :key="index"
                  >
                    <td class="pl-0">
                      <v-icon>{{ getIcon(file.type) }}</v-icon>
                    </td>
                    <td>{{ file.file ? file.file :  file.fileName }}</td>

                    <td  class="pl-0">
                      <v-icon>mdi-check</v-icon>
                    </td>
                    
                    <td>
                      <v-btn
                        small
                        rounded
                        class="ma-2"
                        color="#d1121b"
                        dark
                        @click="deleteThisFile(index)"
                      >
                        <v-icon> mdi-trash-can-outline </v-icon>
                        <div class="vertical_line"></div>
                        Delete
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <v-simple-table v-if="toggledDocType == 1">
              <template v-slot:default>
                <tbody>
                  <tr
                    v-for="(file, index) in aigMasterForm.originDetails[
                      toggledCountry
                    ].documents.pe"
                    :key="index"
                  >
                    <td class="pl-0">
                      <v-icon>{{ getIcon(file.type) }}</v-icon>
                    </td>
                    <td>{{ file.file ? file.file :  file.fileName }}</td>

                    <td  class="pl-0">
                      <v-icon>mdi-check</v-icon>
                    </td>
                    
                    <td>
                      <v-btn
                        small
                        rounded
                        class="ma-2"
                        color="#d1121b"
                        dark
                        @click="deleteThisFile(index)"
                      >
                        <v-icon> mdi-trash-can-outline </v-icon>
                        <div class="vertical_line"></div>
                        Delete
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <v-simple-table v-if="toggledDocType == 2">
              <template v-slot:default>
                <tbody>
                  <tr
                    v-for="(file, index) in aigMasterForm.originDetails[
                      toggledCountry
                    ].documents.trc"
                    :key="index"
                  >
                    <td  class="pl-0" >
                      <v-icon>{{ getIcon(file.type) }}</v-icon>
                    </td>
                    <td>{{ file.file ? file.file :  file.fileName }}</td>

                    <td class="pl-0">
                      <v-icon>mdi-check</v-icon>
                    </td>
                     
                    <td>
                      <v-btn
                        small
                        rounded
                        class="ma-2"
                        color="#d1121b"
                        dark
                        @click="deleteThisFile(index)"
                      >
                        <v-icon> mdi-trash-can-outline </v-icon>
                        <div class="vertical_line"></div>
                        Delete
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <v-simple-table v-if="toggledDocType == 3">
              <template v-slot:default>
                <tbody>
                  <tr
                    v-for="(file, index) in aigMasterForm.originDetails[
                      toggledCountry
                    ].documents.others"
                    :key="index"
                  >
                    <td class="pl-0">
                      <v-icon>{{ getIcon(file.type) }}</v-icon>
                    </td>
                    <td>{{ file.file ? file.file :  file.fileName }}</td>

                    <td class="pl-0">
                      <v-icon>mdi-check</v-icon>
                    </td>
                    
                    <td>
                      <v-btn
                        small
                        rounded
                        class="ma-0"
                        color="#d1121b"
                        dark
                        @click="deleteThisFile(index)"
                      >
                        <v-icon> mdi-trash-can-outline </v-icon>
                        <div class="vertical_line"></div>
                        Delete
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
          <v-row>
            <v-flex xs4></v-flex>
            <v-flex xs2 class="pa-2">
              <v-layout>
                <v-btn
                  class="rounded-0 mt-1"
                  color="#23b1a9"
                  dark
                  @click="submitUploadFiles"
                >
                  <v-icon>mdi-arrow-right-circle</v-icon>
                  <div class="vertical_line"></div>
                  Submit
                </v-btn>
              </v-layout>
            </v-flex>
            <v-flex xs4></v-flex>
          </v-row>
        </div>
      </v-card>
    </v-dialog> -->
    <v-dialog
      v-model="docDownloadDialog"
      persistent
      width="600px"
      height="700px"
      max-width="800px"
      max-height="800px"
      class="rounded-0 overflowNone"

    >
      <v-card
        color="#F7F7F7"
        class="pb-2 overflowNone"
        tile
      >
        <v-card tile class="boxshadowUploadDoc">
          <v-layout
            justify-space-between
            class="boxshadowone"
          >
            <v-layout align-center>
              <v-card-title
                class="pa-0 pl-3 text-capitalize font-weight-bold downloadDocTitle"
                >Download Document</v-card-title
              >
            </v-layout>
            <div @click="closeDownloadDialog">
              <v-icon  class="pa-2 cursorPoniter">mdi-close</v-icon>
            </div>
          </v-layout>
        </v-card>
        <div class="pa-2">
          <v-card>
            <v-btn-toggle
              v-model="toggledDownloadDocType"
              active-class="active_doc_btn"
              class="pa-5"
            >
              <v-row>
                <v-btn medium rounded class="ma-2">
                  Bank details
                </v-btn>
                <v-btn medium rounded class="ma-2">
                  Permanent Establishment (PE) Certificate
                </v-btn>
                <!-- </v-row> -->
                <!-- <v-row> -->
                <v-btn medium rounded class="ma-2">
                  Tax Residency Certificate (TRC)
                </v-btn>
                <v-btn rounded medium class="ma-2"> Others </v-btn>
              </v-row>
            </v-btn-toggle>
          </v-card>

          <v-card  class="pa-5 mb-2">
            <!-- Bank File List -->
            <v-simple-table v-if="toggledDownloadDocType == 0">
              <template v-slot:default>
                <tbody>
                  <tr
                    v-for="(file, index) in aigMasterForm.originDetails[
                      toggledCountry
                    ].documents.bank"
                    :key="index"
                  >
                    <td class="pl-0">
                      <v-icon>{{ getIcon(file.type) }}</v-icon>
                    </td>
                    <td>{{ file.file ? file.file :  file.fileName }}</td>
                       <!-- Split Screen Code -->
                    <td >
                        <v-btn
                          small
                          rounded
                          color="#E46A25"
                          class="ma-2"
                          dark
                          @click="
                            viewFile(file, index)
                          "
                        >
                          <v-icon class="mr-3"> mdi-eye</v-icon>
                          <div class="vertical_line"></div>
                          View
                        </v-btn>
                      </td>
                      <!-- Split Screen Code -->
                    <td>
                      <v-btn
                        small
                        rounded
                        color="#d1121b"
                        class="ma-2"
                        dark
                        @click="downloadThisFile(file)"
                      >
                        <v-icon> mdi-download</v-icon>
                        <div class="vertical_line"></div>
                        Download
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <!-- pe File List -->
            <v-simple-table v-if="toggledDownloadDocType == 1">
              <template v-slot:default>
                <tbody>
                  <tr
                    v-for="(file, index) in aigMasterForm.originDetails[
                      toggledCountry
                    ].documents.pe"
                    :key="index"
                  >
                    <td class="pl-0">
                      <v-icon>{{ getIcon(file.type) }}</v-icon>
                    </td>
                    <td>{{ file.file ? file.file :  file.fileName }}</td>
                     <!-- Split Screen Code -->
                    <td >
                        <v-btn
                          small
                          rounded
                          color="#E46A25"
                          class="ma-2"
                          dark
                          @click="
                            viewFile(file, index)
                          "
                        >
                          <v-icon class="mr-3"> mdi-eye</v-icon>
                          <div class="vertical_line"></div>
                          View
                        </v-btn>
                      </td>
                      <!-- Split Screen Code -->
                    <td>
                      <v-btn
                        small
                        rounded
                        color="#d1121b"
                        class="ma-2"
                        dark
                        @click="downloadThisFile(file)"
                      >
                        <v-icon> mdi-download</v-icon>
                        <div class="vertical_line"></div>
                        Download
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <!-- trc File List -->
            <v-simple-table v-if="toggledDownloadDocType == 2">
              <template v-slot:default>
                <tbody>
                  <tr
                    v-for="(file, index) in aigMasterForm.originDetails[
                      toggledCountry
                    ].documents.trc"
                    :key="index"
                  >
                    <td class="pl-0">
                      <v-icon>{{ getIcon(file.type) }}</v-icon>
                    </td>
                    <td>{{ file.file ? file.file :  file.fileName }}</td>
                     <!-- Split Screen Code -->
                    <td >
                        <v-btn
                          small
                          rounded
                          color="#E46A25"
                          class="ma-2"
                          dark
                          @click="
                            viewFile(file, index)
                          "
                        >
                          <v-icon class="mr-3"> mdi-eye</v-icon>
                          <div class="vertical_line"></div>
                          View
                        </v-btn>
                      </td>
                      <!-- Split Screen Code -->
                    <td>
                      <v-btn
                        small
                        rounded
                        color="#d1121b"
                        class="ma-2"
                        dark
                        @click="downloadThisFile(file)"
                      >
                        <v-icon> mdi-download</v-icon>
                        <div class="vertical_line"></div>
                        Download
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <!-- others File List -->
            <v-simple-table v-if="toggledDownloadDocType == 3">
              <template v-slot:default>
                <tbody>
                  <tr
                    v-for="(file, index) in aigMasterForm.originDetails[
                      toggledCountry
                    ].documents.others"
                    :key="index"
                  >
                    <td class="pl-0">
                      <v-icon>{{ getIcon(file.type) }}</v-icon>
                    </td>
                    <td>{{ file.file ? file.file :  file.fileName }}</td>
                    <!-- Split Screen Code -->
                    <td >
                        <v-btn
                          small
                          rounded
                          color="#E46A25"
                          class="ma-2"
                          dark
                          @click="
                            viewFile(file, index)
                          "
                        >
                          <v-icon class="mr-3"> mdi-eye</v-icon>
                          <div class="vertical_line"></div>
                          View
                        </v-btn>
                      </td>
                      <!-- Split Screen Code -->
                    <td>
                    
                      <v-btn
                        small
                        rounded
                        color="#d1121b"
                        class="ma-2"
                        dark
                        @click="downloadThisFile(file)"
                      >
                        <v-icon> mdi-download</v-icon>
                        <div class="vertical_line"></div>
                        Download
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
          <v-row>
            <v-flex xs4></v-flex>
            <v-flex xs2 class="pa-2" >
              <v-layout>
                <v-btn
                color="#23b1a9"
                dark
                class="mt-1 rounded-0"
                  @click="closeDownloadDialog"
                >
                  <v-icon>mdi-arrow-right-circle</v-icon>
                  <div class="vertical_line ml-1"></div>
                  Close
                </v-btn>
              </v-layout>
            </v-flex>
            <v-flex xs4></v-flex>
          </v-row>
        </div>
      </v-card>
    </v-dialog>
    <v-overlay :absolute="absolute" :value="overlay">
      <v-progress-circular
        :size="70"
        color="#01BBD9"
        indeterminate
      ></v-progress-circular>
    </v-overlay>

     <v-row justify="center">
        <v-dialog v-model="ConfirmationModal" persistent max-width="30%" style="border-radius: 0 ;">
            <v-card>
                <v-card-title class="pl-0 px-0 py-0 dailogboxAllvcardTableBackground">
                    <v-row class="colorbg">
                        <span class="descrptionSpan mb-2 ml-8 mr-1">{{confirmationHeader}}</span>
                        <v-icon @click="ConfirmationModal = false" class="mt-2" color="#fff">mdi-window-close</v-icon>
                    </v-row>
                    <v-card-text class="vcardTextTableBackground pl-2 mt-3 ml-3">
                       <p>{{confirmationMessage}}</p>

                        <v-col align="right" class="mt-4">
                                    <v-btn class="btncommonstyle cancelbtn mr-3" @click="ConfirmationModal = false">Cancel
                                    </v-btn>
                                    <v-btn color="#D1121B" dark class="btncommonstyle mr-2" @click="finalsubmitconfirm(btnNameconfirm)">{{btnNameconfirm}}
                                    </v-btn>
                        </v-col>
                    </v-card-text>
                </v-card-title>
            </v-card>
        </v-dialog>
    </v-row>
    <!-- Reject Dialog  -->
    <v-row>
        <v-dialog v-model="reject_Dialog" width="600">
            <v-card>
                <v-card-title class="white--text" style="background-color: #23b1a9"
                ><v-layout style="display:flex;justify-content:center;margin-left: 170px;" >Reject
                    </v-layout>
                    <v-layout style="display:flex;justify-content:end;">
                    <v-icon class="white--text " @click="reject_Dialog=false">mdi-close</v-icon>
                  </v-layout>
                  </v-card-title>
                  <v-form ref="rejectForm" @submit="false">
                    <v-layout class="pt-4 form-group-container" >
                      
                        <v-flex class="mr-2">
                            <label class="form-label">Reason <span><strong class="red--text">*</strong></span></label>
                            <v-text-field  class="form-control" solo dense :rules="reject_Dialog ? [(v)=>requiredField(v)]:[]" v-model="rejectionReason" placeholder="Enter..." clearable></v-text-field>
                        </v-flex>
                      
                    </v-layout>
                  </v-form>
                    <v-card-actions style="display:flex;justify-content:center">
                      <v-btn class="btn" color="red white--text mr-0" @click="reject_Dialog=false">
                        No
                    </v-btn>

                    <v-btn class="btn" color="green white--text mr-0" @click="rejectAig">
                        Yes
                    </v-btn>
                  </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>

  </v-container>

</template>

<script>
import moment from "moment";


export default {
  data() {
    return {
      docType:'',
      BANk:true,
      PE:false,
      TRC:false,
      OTHER:false,
      filelist: [],
      documentTabsData:[],
      dummyDataList:[],
      FileUploadHeader: [{
                    text: "Document Name",
                    value: "file"
                },
                
                {
                    text: "Action"
                },
                
            ],
      ConfirmationModal:false,
      confirmationHeader: "",
      confirmationMessage: "",
      btnNameconfirm: "",
      IssaveRow:false,
      ticksLabels: [
          'CONTACT DETAILS',
          'PE AND TRC DETAILS',
          'BANK DETAILS',
          'UPLOAD DOCUMENTS',
        ],
      search: "",
      toggledDownloadDocType: "",
      docDownloadDialog: false,
      otheFieldsEnteredForPAcc: false,
      overlay: false,
      absolute: true,
      aigMasterForm: {
        nameOfAigOffice: "",
        aigCode: "",
        originDetails: [],
      },
      countryList: [],
      bankCalenderDateFrom: false,
      bankCalenderDateTo: false,
      validationMessages: {
        mandatory: "This field is required.",
      },
      allRules: {
        officeNameRules: [
          (v) => !!v || this.validationMessages.mandatory,
          (v) =>
            (v && v.length <= 100) ||
            "AIG Office Name should be less than 100 characters.",
          (v) =>
            (v && v.length > 5) ||
            "Name of AIG Office should be more than 5 digits.",
          (v) => /[a-zA-Z0-9\s]+$/.test(v) || "AIG Office Name is not valid.",
        ],
        countryRules: [
          (v) => !!v || this.validationMessages.mandatory,
          (v) =>
            (v && v.length <= 100) ||
            "AIG Office Name should be less than 100 characters.",
          (v) => /[a-zA-Z0-9\s]+$/.test(v) || "AIG Office Name is not valid.",
        ],
        address1Rules: [
          (v) => !!v || this.validationMessages.mandatory,
          (v) =>
            (v && v.length <= 50) ||
            "Address should be less than 50 characters.",
          (v) => /[a-zA-Z0-9\s.-:;,/#]+$/.test(v) || "Address is not valid.",
        ],
        address2Rules: [
          (v) => !!v || this.validationMessages.mandatory,
          (v) =>
            (v && v.length <= 50) ||
            "Address should be less than 50 characters.",
          (v) => /[a-zA-Z0-9\s.-:;,/#]+$/.test(v) || "Address is not valid.",
        ],
        address3Rules: [
          (v) => !!v || this.validationMessages.mandatory,
          (v) =>
            (v && v.length <= 50) ||
            "Address should be less than 50 characters.",
          (v) => /[a-zA-Z0-9\s.-:;,/#]+$/.test(v) || "Address is not valid.",
        ],
        nameRules: [
          (v) => !!v || this.validationMessages.mandatory,
          (v) =>
            (v && v.length <= 50) || "Name should be less than 50 characters.",
          (v) => /[a-zA-Z0-9\s]+$/.test(v) || "Name is not valid.",
        ],
        officeNumRules: [
          (v) => !!v || this.validationMessages.mandatory,
          (v) =>
            (v && v.length > 5) ||
            "Office number should be more than 5 digits.",
          (v) =>
            (v && v.length <= 20) ||
            "Office number should be less than 20 digits.",
          (v) => /[0-9]+$/.test(v) || "Office number is not valid.",
        ],
        mobileNumRules: [
          (v) =>
            (v && v.toString().length <= 20) ||
            "Mobile number should be less than 20 digits.",
          (v) =>
            (v && v.length > 5) ||
            "Mobile number should be more than 5 digits.",
          //     v => /(6|7|8|9)\d{9}/.test(v) || 'Mobile number is not valid.'
        ],
        emailRules: [
          (v) => !!v || this.validationMessages.mandatory,
          (v) =>
            (v && v.length <= 50) || "Email should be less than 50 characters.",
          (v) => /.+@.+\..+/.test(v) || "E-mail is not valid.",
        ],
        name2Rules: [
          (v) =>
            (v.length < 0 && v.length <= 50) ||
            "Name should be less than 50 characters.",
          (v) => /[a-zA-Z0-9\s]+$/.test(v) || "Name is not valid.",
        ],
        office2NumRules: [
          (v) =>
            (v && v.length <= 20) ||
            "Office number should be less than 20 digits.",
          (v) =>
            (v && v.length > 5) ||
            "Office number should be more than 5 digits.",
          (v) => /[0-9]+$/.test(v) || "Office number is not valid.",
        ],
        mobile2NumRules: [
          (v) =>
            (v && v.toString().length <= 20) ||
            "Mobile number should be less than 20 digits.",
          (v) =>
            (v && v.length > 5) ||
            "Mobile number should be more than 5 digits.",
          //   v => /(6|7|8|9)\d{9}/.test(v) || 'Mobile number is not valid.'
        ],
        email2Rules: [
          (v) =>
            (v && v.length <= 50) || "Email should be less than 50 characters.",
          (v) => /.+@.+\..+/.test(v) || "E-mail is not valid.",
        ],
        bankNameRules: [
          (v) => !!v || this.validationMessages.mandatory,
          (v) =>
            (v && v.length <= 100) ||
            "Bank Name should be less than 100 characters.",
          (v) => /[a-zA-Z0-9\s]+$/.test(v) || "Bank Name is not valid.",
        ],
        bankAddressRules: [
          (v) => !!v || this.validationMessages.mandatory,
          (v) =>
            (v && v.length <= 50) ||
            "Address should be less than 50 characters.",
          (v) => /[a-zA-Z0-9\s.,;:|-~"]+$/.test(v) || "Address is not valid.",
        ],
        bankAccNumRule: [
          (v) =>
            (v && v.length <= 50) ||
            "Account Number should be less than 50 characters.",
          (v) => /[A-Z0-9]+$/.test(v) || "Account Number is not valid.",
        ],
        bankReAccNumRule: [
          (v) =>
            (v && v.length <= 50) ||
            "Account Number should be less than 50 characters.",
          (v) => /[A-Z0-9]+$/.test(v) || "Account Number is not valid.",
          //v => (v && v !== this.aigMasterForm.payeeBankDetails.bankAccNum) || 'Account Number does not match.'
        ],
        swiftCodeRule: [
          (v) =>
            (v && v.length <= 50) ||
            "Swift Code should be less than 50 characters.",
          (v) => /[a-zA-Z0-9]+$/.test(v) || "Swift Code is not valid.",
        ],
        ibanCodeRule: [
          (v) =>
            (v && v.length <= 50) ||
            "IBAN Code should be less than 50 characters.",
          (v) => /[a-zA-Z0-9]+$/.test(v) || "IBAN Code is not valid.",
        ],
        sortCodeRule: [
          (v) =>
            (v && v.length <= 50) ||
            "Sort Code should be less than 50 characters.",
          (v) => /[a-zA-Z0-9]+$/.test(v) || "Sort Code is not valid.",
        ],
        //
        corrBankNameRules: [
          (v) =>
            (v && v.length <= 100) ||
            "Bank Name should be less than 100 characters.",
          (v) => /[a-zA-Z0-9\s]+$/.test(v) || "Bank Name is not valid.",
        ],
        corrBankAddressRules: [
          (v) =>
            (v && v.length <= 45) ||
            "Address should be less than 45 characters.",
          (v) => /[a-zA-Z0-9\s,;:|-~"]+$/.test(v) || "Address is not valid.",
        ], 
        corrBankAccNumRule: [
          (v) =>
            (v && v.length <= 50) ||
            "Account Number should be less than 50 characters.",
          (v) => /[A-Z0-9]+$/.test(v) || "Account Number is not valid.",
        ],
        corrBankReAccNumRule: [
          (v) =>
            (v && v.length <= 50) ||
            "Account Number should be less than 50 characters.",
          (v) => /[A-Z0-9]+$/.test(v) || "Account Number is not valid.",
          // v => (v && v !== this.aigMasterForm.corrBankDetails.bankAccNum) || 'Account Number does not match.'
        ],
        corrSwiftCodeRule: [
          (v) =>
            (v && v.length <= 50) ||
            "Swift Code should be less than 50 characters.",
          (v) => /[a-zA-Z0-9]+$/.test(v) || "Swift Code is not valid.",
        ],
        corrAbaCodeRule: [
          (v) =>
            (v && v.length <= 50) ||
            "ABA Code should be less than 50 characters.",
          (v) => /[a-zA-Z0-9]+$/.test(v) || "ABA Code is not valid.",
        ],
        corrSortCodeRule: [
          (v) =>
            (v && v.length <= 50) ||
            "Sort Code should be less than 50 characters.",
          (v) => /[a-zA-Z0-9]+$/.test(v) || "Sort Code is not valid.",
        ],
      },
      toggledCountry: "",
      toggledCountryName: "",
      selectedCountryList: "",
      showCountryOpt: false,
      docUploadDialog: false,
      toggledDocType: "",
      bankFiles: [],
      peFiles: [],
      trcFiles: [],
      othersFiles: [],
      files: [],
      max20: 20,
      max50: 50,
      max100: 138,
      emptyData: {
        countryName: "",
        addressLine1: "",
        addressLine2: "",
        addressLine3: "",
        isActive:true,
        contactPerson1: {
          name: "",
          officeNum: {
            isd:'91',
            number:''
          },
           
          mobileNum: {
            isd:'91',
            number:''
           },
          email: "",
        },
        contactPerson2: {
          name: "",
          officeNum: {
            isd:'91',
            number:''
           },
           mobileNum: {
            isd:'91',
            number:''
           },
          email: "",
        },
        peValidityDate: "",
        trcApplicable: false,
        trcValidityDate: "",
        payeeBank: {
          bankName: "",
          bankAddressLine1: "",
          bankAddressLine2: "",
          bankAddressLine3: "",
          accountNumber: "",
          confirmAccountNumber: "",
          swiftCode: "",
          ibanNumber: "",
          sortCode: "",
        },
        corrBank: {
          bankName: "",
          bankAddressLine1: "",
          bankAddressLine2: "",
          bankAddressLine3: "",
          accountNumber: "",
          confirmAccountNumber: "",
          swiftCode: "",
          abaCode: "",
        },
        documents: {
          bank: [
            {
              fileName: "",
              fileUrl: "",
            },
          ],
          pe: [
            {
              fileName: "",
              fileUrl: "",
            },
          ],
          trc: [
            {
              fileName: "",
              fileUrl: "",
            },
          ],
          others: [
            {
              fileName: "",
              fileUrl: "",
            },
          ],
        },
      },
      address12Length:45,
      address3Length:35,
      accountNumberLength:35,
      main_heading:"Create",
      aigSlider:0,
      reject_Dialog: false,
      rejectItem: {},
      rejectionReason: '',
    };
  },
  methods: {
    rejectAig() {
      let reqData = {
        approveStatus: 'Rejected',
        rejectionReason: this.rejectionReason,
      }
      this.rejectUpdateHandler(reqData);
    },

    approveAig() {
      this.showConfirm("Confirmation", `Are you sure you want to approve?`, "Yes", "No", () => {
        let reqData = {
            approveStatus: 'Approved',
            payeeName: this.$route.params.aig_Data.payeeName,
            nameOfAigOffice: this.$route.params.aig_Data.nameOfAigOffice,
            uid: this.$route.params.aig_Data.uid,
        }
        this.rejectUpdateHandler(reqData);
      }, () => {})
    },

    rejectUpdateHandler(reqData) {
      this.PUTM("updateMasterApprove", this.$route.params._id, reqData, (res) => {
          if(res?.data?.statusCode == 200) {
              this.showAlert(res.data.msg);
              this.rejectionReason = '';
              this.reject_Dialog = false;
              this.$router.push({
                name: 'SearchAigApprove'
              });
          } else {
              this.showAlert(res.data.msg);
          }
      }, (err) => {
          console.log(err);
      }, true);
    },
    finalsubmitconfirm(btntext){
      // alert(btntext)
      if(btntext == "Yes,Delete"){
          var deleteIndex = this.toggledCountry;
          let newToggleIndex = deleteIndex - 1 >= 0 ? deleteIndex - 1 : 0;
          // this.toggledCountry = undefined;
          
          this.toggledCountryName = this.selectedCountryList;//[newToggleIndex];
          this.aigMasterForm.originDetails.splice(deleteIndex, 1);
          if(this.aigMasterForm.originDetails.length <= 0){
          this.selectedCountryList  = "";

          }
          console.log("Delete - ", this.aigMasterForm.originDetails);
          this.ConfirmationModal = false
      }
      if(btntext == "Yes,Reset"){
        // alert(this.toggledCountry)
    
     this.aigMasterForm.originDetails[this.toggledCountry].addressLine1 = "";
       this.aigMasterForm.originDetails[this.toggledCountry].addressLine1= "";
      this.aigMasterForm.originDetails[this.toggledCountry].addressLine2= "";
      this.aigMasterForm.originDetails[this.toggledCountry].addressLine3= "";
      this.aigMasterForm.originDetails[this.toggledCountry].contactPerson1 = {
            name: "",
            officeNum: {
            isd:'91',
            number:''
           },
          mobileNum:{
            isd:'91',
            number:''
           },
            email: "",
          };
      this.aigMasterForm.originDetails[this.toggledCountry].contactPerson2= {
            name: "",
            officeNum: {
            isd:'91',
            number:''
           },
            
            mobileNum: {
            isd:'91',
            number:''
           },
            email: "",
          };
          this.aigMasterForm.originDetails[this.toggledCountry].peValidityDate= null,
          this.aigMasterForm.originDetails[this.toggledCountry].trcApplicable= false,
          this.aigMasterForm.originDetails[this.toggledCountry].trcValidityDate=null,
          this.aigMasterForm.originDetails[this.toggledCountry].payeeBank= {
            bankName: "",
            bankAddressLine1: "",
            bankAddressLine2: "",
            bankAddressLine3: "",
            accountNumber: "",
            confirmAccountNumber: "",
            swiftCode: "",
            ibanNumber: "",
            sortCode: "",
          },
          this.aigMasterForm.originDetails[this.toggledCountry].corrBank= {
            bankName: "",
            bankAddressLine1: "",
            bankAddressLine2: "",
            bankAddressLine3: "",
            accountNumber: "",
            confirmAccountNumber: "",
            swiftCode: "",
            abaCode: "",
          },
          this.aigMasterForm.originDetails[this.toggledCountry].documents= {
            bank: [],
            pe: [],
            trc: [],
            others: [],
          }
          
      this.ConfirmationModal = false
        
      }

      if(btntext == "Yes,Cancel"){
         this.$router.go(-1)
      }


    },
    CancelFormAig(){
       this.ConfirmationModal = true
          this.confirmationHeader = "Cancel master"
          this.confirmationMessage = "Are you sure to you want to “Cancel” AIG Master Creation?"
          this.btnNameconfirm =  "Yes,Cancel"
    },
    nextTotheSLider(){
      if(this.aigSlider == 0 && this.$refs.officeForm.validate()){
        this.aigSlider = 1
      }else if(this.aigSlider == 1 && this.$refs.officeForm.validate()){
        this.aigSlider = 2
      }else if(this.aigSlider == 2 && this.$refs.officeForm.validate()){
        this.aigSlider = 3
      }
    },
    saveRow(item){
       item.finalSubmit = false
       this.IssaveRow = true
    },
    previousTotheSLider(){
      if(this.aigSlider == 3){
        this.aigSlider = 2
      }else if(this.aigSlider == 2){
        this.aigSlider = 1
      }else if(this.aigSlider == 1){
        this.aigSlider = 0
      }
    },
    addaigRow(){
         this.IssaveRow = false
         this.aigSlider = 0
         this.aigMasterForm.originDetails.push({
          finalSubmit: true,
          countryName: "",
          addressLine1: "",
          addressLine2: "",
          addressLine3: "",
          contactPerson1: {
            name: "",
            officeNum: {
            isd:'91',
            number:''
           },
            mobileNum: {
            isd:'91',
            number:''
           },
            email: "",
          },
          contactPerson2: {
            name: "",
            officeNum: {
            isd:'91',
            number:''
           },
            
            mobileNum: {
            isd:'91',
            number:''
           },
            email: "",
          },
          peValidityDate: "",
          trcApplicable: false,
          trcValidityDate: "",
          payeeBank: {
            bankName: "",
            bankAddressLine1: "",
            bankAddressLine2: "",
            bankAddressLine3: "",
            accountNumber: "",
            confirmAccountNumber: "",
            swiftCode: "",
            ibanNumber: "",
            sortCode: "",
          },
          corrBank: {
            bankName: "",
            bankAddressLine1: "",
            bankAddressLine2: "",
            bankAddressLine3: "",
            accountNumber: "",
            confirmAccountNumber: "",
            swiftCode: "",
            abaCode: "",
          },
          documents: {
            bank: [],
            pe: [],
            trc: [],
            others: [],
          },
        });
   
      },
       dragover(event) {
            event.preventDefault();
            // Add some visual fluff to show the user can drop its files
            if (!event.currentTarget.classList.contains("bg-green-300")) {
                event.currentTarget.classList.remove("bg-gray-100");
                event.currentTarget.classList.add("bg-green-300");
            }
        },
        dragleave(event) {
            // Clean up
            event.currentTarget.classList.add("bg-gray-100");
            event.currentTarget.classList.remove("bg-green-300");
        },
        drop(event) {
            event.preventDefault();
            this.$refs.file.files = event.dataTransfer.files;
            this.onChange(); // Trigger the onChange event manually
            // Clean up
            event.currentTarget.classList.add("bg-gray-100");
            event.currentTarget.classList.remove("bg-green-300");
        },
        browsedocs(index){
            console.log('index',index)
            this.toggledCountry = index
            document.getElementById("assetsFieldHandle").click()
        },
        onChange() {
            if (!this.$route.params.isView) {
                //let dummyDataList = [];
                // this.dummyDataList = [...this.$refs.file[0].files];
                // console.log(this.$refs.file.files);
                this.dummyDataList.forEach((obj) => {
                    this.readImageM(obj.name, obj, (res) => {
                        console.log(res);
                        obj.fileBase = res.base;
                        obj.file=res.fileName;
                         
                         if (this.docType=="BANkdocument") {
                            obj.documentType = "BANkdocument";
                            this.aigMasterForm.originDetails[this.toggledCountry].documents.bank.push(obj)
                            console.log("aigcuplod",this.aigMasterForm.originDetails[this.toggledCountry].documents)
                        }
                        if (this.docType =="PEdocument") {
                            obj.documentType = "PEdocument";
                            this.aigMasterForm.originDetails[this.toggledCountry].documents.pe.push(obj)
                        }
                        if (this.docType =="TRCducument") {
                            obj.documentType = "TRCducument";
                            this.aigMasterForm.originDetails[this.toggledCountry].documents.trc.push(obj)
                        }
                        if (this.docType =="OTHERducument") {
                            obj.documentType = "OTHERducument";
                            this.aigMasterForm.originDetails[this.toggledCountry].documents.others.push(obj)
                        }
                        this.filelist.push(obj);
                        // this.documentTabsData=this.filelist.filter(ele => ele.documentType == this.docType);
                        // this.documentTabsData=[...this.documentTabsData];
                    });
                });
                console.log(this.filelist);
            } else {
                this.showAlert("AIG Master is view only.");
            }
        },
        checkDocType(name){
            this.BANk = false
            this.PE = false
            this.TRC = false
            this.OTHER =false
            if(name == 'BANkdocument'){
                this.BANk = true
            }else if(name == 'PEdocument'){
                this.PE = true
            }else if(name == 'TRCducument'){
                this.TRC = true
            }else if(name == 'OTHERducument'){
                this.OTHER = true
            }
      },
      openRowOnEdit(index){
       this.aigMasterForm.originDetails[index].finalSubmit = true
          // item.finalSubmit = true
        // alert(this.aigMasterForm.originDetails[index].finalSubmit)
         this.aigSlider = 0
      },
     viewFile(singleFile, index) {
      console.log("viewFile", singleFile, index);
      const documentId = singleFile.doucumentId || singleFile.doucumentId;
      if(documentId){
        let request = "documentId=" + documentId + "&b64=true";
        this.GETM(
          "GET_DOWNLOAD_FILE",
          request,
          (response) => {
            let base64 = response.data.data;
            this.openSplitScreen(base64,singleFile.fileName || singleFile.file);
          },
          (error) => {
            this.showAlert(error.response.data.msg);
          },
          "remove"
        );
      }else{
        this.openSplitScreen(singleFile.fileBase ,singleFile.fileName || singleFile.file);
      }
     
    },
     viewDocument(item) {
            let documentID = item.doucumentId;
            if (documentID) {
                this.showProgress();
                let request = "documentId=" + documentID + "&b64=true";
                this.GETM(
                    "GET_DOWNLOAD_FILE",
                    request,
                    (response) => {
                        let base64 = response.data.data;
                        // this.OCRImageShow = base64;
                        this.openBase64File(base64);
                        this.hideProgress();
                    },
                    (error) => {
                        this.base64ImageShow = "";
                        this.hideProgress();
                        let errorMsg =
                            error.response && error.response.data && error.response.data.msg ?
                            error.response.data.msg :
                            error.response.data.message ?
                            error.response.data.message :
                            "Something went wrong!";
                        this.showAlert(errorMsg);
                    },
                    "remove"
                );
            } else if (item.fileBase) {
                this.openBase64File(item.fileBase);
                // this.OCRImageShow = this.OCRCancelledDocument[0].fileBase;
            } else {
                this.showAlert("Somthing went wrong!");
            }
        },
     downloadDoc(item) {
         if(item.doucumentId) {        
            item.doucumentId = item.doucumentId ? item.doucumentId : item.doucumentId
            let url = this.getURL("GET_DOWNLOAD_FILE") + "?documentId=" + item.doucumentId;
            window.open(url);
         }else{
           this.openBase64File(item.fileBase);
         }
                
          },
       remove( index,item, index1) {
        console.log("check index in delete doc", index, index1, this.aigMasterForm.originDetails);
        this.showConfirm("Confirmation", `Are you sure you want to delete?`, "Yes", "No", () => {
            this.toggledCountry = index;
            console.log("to check function" ,index,item)
            if(this.docType=="BANkdocument"){
              console.log("to check bank",this.aigMasterForm.originDetails)
              this.aigMasterForm.originDetails[index].documents.bank.splice(index1,1);
              this.dummyDataList=null;
            }
            if(this.docType == "PEdocument"){
              console.log("to check bank",this.aigMasterForm.originDetails)
              this.aigMasterForm.originDetails[index].documents.pe.splice(index1,1);
              this.dummyDataList=null;
            }
            if(this.docType=="TRCducument"){
              console.log("to check bank",this.aigMasterForm.originDetails)
              this.aigMasterForm.originDetails[index].documents.trc.splice(index1,1);
              this.dummyDataList=null;
            }
            if(this.docType=="OTHERducument"){
              console.log("to check bank",this.aigMasterForm.originDetails)
              this.aigMasterForm.originDetails[index].documents.others.splice(index1,1);
              this.dummyDataList=null;
            }
              // let index=this.filelist.indexOf(item);
              // this.filelist.splice(index, 1);
              // this.documentTabsData.splice(i, 1);
          }, () => {})
        },
    //to open calendermenu on tab

    // v-on:focus="onTRCFocus"
    onPEFocus(){
      this.bankCalenderDateFrom = true;
    },

    onTRCFocus(){
      this.bankCalenderDateTo = true;
    },

    submitDetails() {
      console.log("aigMasterForm - ", this.aigMasterForm);
      this.aigMasterForm.approveRequest = true; 
      this.aigMasterForm.isActive = false; 
      if (
        this.aigMasterForm.originDetails.length > 0 &&
        this.$refs.officeForm.validate()
      ) {
       
        var isDataValid = true;
        for (var i in this.aigMasterForm.originDetails) {
          delete this.aigMasterForm.originDetails[i].finalSubmit
         console.log('hgkjh',this.officeNameRulesFunc(this.aigMasterForm.nameOfAigOffice))
          if (
            this.officeNameRulesFunc(this.aigMasterForm.nameOfAigOffice) !==
            true
          ) {
            isDataValid = false;
            this.showAlert(this.officeNameRulesFunc(this.aigMasterForm.nameOfAigOffice));
          } 
          else if (
            this.address1RulesFunc(
              this.aigMasterForm.originDetails[i].addressLine1
            ) !== true
          ) {
            isDataValid = false;
            this.showAlert(
              " For " +
                this.aigMasterForm.originDetails[i].countryName +
                " - Address Line 1 - " +
                this.address1RulesFunc(
                  this.aigMasterForm.originDetails[i].addressLine1
                )
            );
          }
           else if (!this.aigMasterForm.originDetails[i].addressLine1) {
            isDataValid = false;
            this.showAlert(
              "Address Line 1 for " +
                this.aigMasterForm.originDetails[i].countryName +
                " is required"
            );
          } else if (!this.aigMasterForm.originDetails[i].addressLine2) {
            isDataValid = false;
            this.showAlert(
              "Address Line 2 for " +
                this.aigMasterForm.originDetails[i].countryName +
                " is required"
            );
          } else if (
            this.address2Rules(
              this.aigMasterForm.originDetails[i].addressLine2
            ) !== true
          ) {
            isDataValid = false;
            this.showAlert(
              " For " +
                this.aigMasterForm.originDetails[i].countryName +
                " - Address Line 2 - " +
                this.address2Rules(
                  this.aigMasterForm.originDetails[i].addressLine2
                )
            );
          } else if (!this.aigMasterForm.originDetails[i].addressLine3) {
            isDataValid = false;
            this.showAlert(
              "Address Line 3 for " +
                this.aigMasterForm.originDetails[i].countryName +
                " is required"
            );
          } else if (
            this.address3Rules(
              this.aigMasterForm.originDetails[i].addressLine3
            ) !== true
          ) {
            isDataValid = false;
            this.showAlert(
              " For " +
                this.aigMasterForm.originDetails[i].countryName +
                " - Address Line 3 - " +
                this.address3Rules(
                  this.aigMasterForm.originDetails[i].addressLine3
                )
            );
          } else if (!this.aigMasterForm.originDetails[i].contactPerson1.name) {
            isDataValid = false;
            this.showAlert(
              "Contact Person 1 - Name for " +
                this.aigMasterForm.originDetails[i].countryName +
                " is required"
            );
          } else if (
            this.nameRules(
              this.aigMasterForm.originDetails[i].contactPerson1.name
            ) !== true
          ) {
            isDataValid = false;
            this.showAlert(
              " For " +
                this.aigMasterForm.originDetails[i].countryName +
                " - Contact Person 1 Name - " +
                this.nameRules(
                  this.aigMasterForm.originDetails[i].contactPerson1.name
                )
            );
          } else if (
            !this.aigMasterForm.originDetails[i].contactPerson1.officeNum.number
          ) {
            isDataValid = false;
            this.showAlert(
              "Contact Person 1 - Office Number for " +
                this.aigMasterForm.originDetails[i].countryName +
                " is required"
            );
          } else if (
            this.officeNumRules(
              this.aigMasterForm.originDetails[i].contactPerson1.officeNum.number
            ) !== true
          ) {
            isDataValid = false;
            this.showAlert(
              " For " +
                this.aigMasterForm.originDetails[i].countryName +
                " -  Contact Person 1 Office Number - " +
                this.officeNumRules(
                  this.aigMasterForm.originDetails[i].contactPerson1.officeNum.number
                )
            );
          } else if (
            !this.aigMasterForm.originDetails[i].contactPerson1.email
          ) {
            isDataValid = false;
            this.showAlert(
              "Contact Person 1 -Email for " +
                this.aigMasterForm.originDetails[i].countryName +
                " is required"
            );
          } else if (
            this.mobileNumRules(
              this.aigMasterForm.originDetails[i].contactPerson1.mobileNum.number
            ) !== true
          ) {
            isDataValid = false;
            this.showAlert(
              " For " +
                this.aigMasterForm.originDetails[i].countryName +
                " -  Contact Person 1 Mobile Number - " +
                this.mobileNumRules(
                  this.aigMasterForm.originDetails[i].contactPerson1.mobileNum.number
                )
            );
          } else if (
            this.emailRules(
              this.aigMasterForm.originDetails[i].contactPerson1.email
            ) !== true
          ) {
            isDataValid = false;
            this.showAlert(
              " For " +
                this.aigMasterForm.originDetails[i].countryName +
                " - Contact Person 1 Email -  " +
                this.emailRules(
                  this.aigMasterForm.originDetails[i].contactPerson1.email
                )
            );
          } else if (!this.aigMasterForm.originDetails[i].payeeBank.bankName) {
            isDataValid = false;
            this.showAlert(
              "Payee Bank - Bank Name for " +
                this.aigMasterForm.originDetails[i].countryName +
                " is required"
            );
          } else if (
            this.name2RuleFunc(
              this.aigMasterForm.originDetails[i].contactPerson2.name
            ) !== true
          ) {
            isDataValid = false;
            this.showAlert(
              " For " +
                this.aigMasterForm.originDetails[i].countryName +
                " -  Contact Person 2 Name - " +
                this.name2RuleFunc(
                  this.aigMasterForm.originDetails[i].contactPerson2.name
                )
            );
          } else if (
            this.office2NumRules(
              this.aigMasterForm.originDetails[i].contactPerson2.officeNum.number
            ) !== true
          ) {
            isDataValid = false;
            this.showAlert(
              " For " +
                this.aigMasterForm.originDetails[i].countryName +
                " -  Contact Person 2 Office Number - " +
                this.office2NumRules(
                  this.aigMasterForm.originDetails[i].contactPerson2.officeNum.number
                )
            );
          } else if (
            this.mobile2NumRules(
              this.aigMasterForm.originDetails[i].contactPerson2.mobileNum.number
            ) !== true
          ) {
            isDataValid = false;
            this.showAlert(
              " For " +
                this.aigMasterForm.originDetails[i].countryName +
                " - Contact Person 2 Mobile Number - " +
                this.mobile2NumRules(
                  this.aigMasterForm.originDetails[i].contactPerson2.mobileNum.number
                )
            );
          } else if (
            this.email2Rules(
              this.aigMasterForm.originDetails[i].contactPerson2.email
            ) !== true
          ) {
            isDataValid = false;
            this.showAlert(
              " For " +
                this.aigMasterForm.originDetails[i].countryName +
                " - Contact Person 2 Email - " +
                this.email2Rules(
                  this.aigMasterForm.originDetails[i].contactPerson2.email
                )
            );
          } else if (
            !this.aigMasterForm.originDetails[i].payeeBank.bankAddressLine1
          ) {
            isDataValid = false;
            this.showAlert(
              "Payee Bank - Bank Address Line 1 for " +
                this.aigMasterForm.originDetails[i].countryName +
                " is required"
            );
          } else if (
            this.bankNameRules(
              this.aigMasterForm.originDetails[i].payeeBank.bankName
            ) !== true
          ) {
            isDataValid = false;
            this.showAlert(
              " For " +
                this.aigMasterForm.originDetails[i].countryName +
                " - Payee Bank - Bank Name - " +
                this.bankNameRules(
                  this.aigMasterForm.originDetails[i].payeeBank.bankName
                )
            );
          } else if (
            this.bankAddressRules(
              this.aigMasterForm.originDetails[i].payeeBank.bankAddressLine1
            ) !== true
          ) {
            isDataValid = false;
            this.showAlert(
              " For " +
                this.aigMasterForm.originDetails[i].countryName +
                " -  Payee Bank - Bank Address Line 1 - " +
                this.bankAddressRules(
                  this.aigMasterForm.originDetails[i].payeeBank.bankAddressLine1
                )
            );
          } else if (
            !this.aigMasterForm.originDetails[i].payeeBank.bankAddressLine2
          ) {
            isDataValid = false;
            this.showAlert(
              "Payee Bank - Bank Address Line 2 for " +
                this.aigMasterForm.originDetails[i].countryName +
                " is required"
            );
          } else if (
            this.bankAddressRules(
              this.aigMasterForm.originDetails[i].payeeBank.bankAddressLine2
            ) !== true
          ) {
            isDataValid = false;
            this.showAlert(
              " For " +
                this.aigMasterForm.originDetails[i].countryName +
                " -  Payee Bank - Bank Address Line 2 - " +
                this.bankAddressRules(
                  this.aigMasterForm.originDetails[i].payeeBank.bankAddressLine2
                )
            );
          } else if (
            this.bankAddressRules(
              this.aigMasterForm.originDetails[i].payeeBank.bankAddressLine3
            ) !== true
          ) {
            isDataValid = false;
            this.showAlert(
              " For " +
                this.aigMasterForm.originDetails[i].countryName +
                " - Payee Bank - Bank Address Line 3 - " +
                this.bankAddressRules(
                  this.aigMasterForm.originDetails[i].payeeBank.bankAddressLine3
                )
            );
          } else if (
            !this.aigMasterForm.originDetails[i].payeeBank.bankAddressLine3
          ) {
            isDataValid = false;
            this.showAlert(
              "Payee Bank - Bank Address Line 3 for " +
                this.aigMasterForm.originDetails[i].countryName +
                " is required"
            );
          } else if (
            this.bankAccNumRule(
              this.aigMasterForm.originDetails[i].payeeBank.accountNumber
            ) !== true
          ) {
            isDataValid = false;
            this.showAlert(
              " For " +
                this.aigMasterForm.originDetails[i].countryName +
                " - Payee Bank - Account Number" +
                this.bankAccNumRule(
                  this.aigMasterForm.originDetails[i].payeeBank.accountNumber
                )
            );
          } else if (
            this.bankReAccNumRule(
              this.aigMasterForm.originDetails[i].payeeBank.confirmAccountNumber
            ) !== true
          ) {
            isDataValid = false;
            this.showAlert(
              " For " +
                this.aigMasterForm.originDetails[i].countryName +
                " - " +
                this.bankReAccNumRule(
                  this.aigMasterForm.originDetails[i].payeeBank
                    .confirmAccountNumber
                )
            );
          } else if (
            this.swiftCodeRule(
              this.aigMasterForm.originDetails[i].payeeBank.swiftCode
            ) !== true
          ) {
            isDataValid = false;
            this.showAlert(
              " For " +
                this.aigMasterForm.originDetails[i].countryName +
                " - Payee Bank - Swift Code - " +
                this.swiftCodeRule(
                  this.aigMasterForm.originDetails[i].payeeBank.swiftCode
                )
            );
          } else if (
            this.ibanCodeRule(
              this.aigMasterForm.originDetails[i].payeeBank.ibanNumber
            ) !== true
          ) {
            isDataValid = false;
            this.showAlert(
              " For " +
                this.aigMasterForm.originDetails[i].countryName +
                " - Payee Bank - IBAN Number - " +
                this.ibanCodeRule(
                  this.aigMasterForm.originDetails[i].payeeBank.ibanNumber
                )
            );
          } else if (
            this.sortCodeRule(
              this.aigMasterForm.originDetails[i].payeeBank.sortCode
            ) !== true
          ) {
            isDataValid = false;
            this.showAlert(
              " For " +
                this.aigMasterForm.originDetails[i].countryName +
                " - Payee Bank - Sort Code - " +
                this.sortCodeRule(
                  this.aigMasterForm.originDetails[i].payeeBank.sortCode
                )
            );
          } else if (
            this.corrBankNameRules(
              this.aigMasterForm.originDetails[i].corrBank.bankName
            ) !== true
          ) {
            isDataValid = false;
            this.showAlert(
              " For " +
                this.aigMasterForm.originDetails[i].countryName +
                " - Corr. Bank - Bank Name - " +
                this.corrBankNameRules(
                  this.aigMasterForm.originDetails[i].corrBank.bankName
                )
            );
          } else if (
            this.corrBankAddressRules(
              this.aigMasterForm.originDetails[i].corrBank.bankAddressLine1
            ) !== true
          ) {
            isDataValid = false;
            this.showAlert(
              " For " +
                this.aigMasterForm.originDetails[i].countryName +
                " -  Corr. Bank - Bank Address Line 1 - " +
                this.corrBankAddressRules(
                  this.aigMasterForm.originDetails[i].corrBank.bankAddressLine1
                )
            );
          } else if (
            this.corrBankAddressRules(
              this.aigMasterForm.originDetails[i].corrBank.bankAddressLine2
            ) !== true
          ) {
            isDataValid = false;
            this.showAlert(
              " For " +
                this.aigMasterForm.originDetails[i].countryName +
                " -  Corr. Bank - Bank Address Line 2 - " +
                this.corrBankAddressRules(
                  this.aigMasterForm.originDetails[i].corrBank.bankAddressLine2
                )
            );
          } else if (
            this.corrBankAddressRules(
              this.aigMasterForm.originDetails[i].corrBank.bankAddressLine3
            ) !== true
          ) {
            isDataValid = false;
            this.showAlert(
              " For " +
                this.aigMasterForm.originDetails[i].countryName +
                " -  Corr. Bank - Bank Address Line 3 - " +
                this.corrBankAddressRules(
                  this.aigMasterForm.originDetails[i].corrBank.bankAddressLine3
                )
            );
          } else if (
            this.corrBankAccNumRule(
              this.aigMasterForm.originDetails[i].corrBank.accountNumber
            ) !== true
          ) {
            isDataValid = false;
            this.showAlert(
              " For " +
                this.aigMasterForm.originDetails[i].countryName +
                " -  Corr. Bank - Account Number -  " +
                this.corrBankAccNumRule(
                  this.aigMasterForm.originDetails[i].corrBank.accountNumber
                )
            );
          } else if (
            this.corrBankReAccNumRule(
              this.aigMasterForm.originDetails[i].corrBank.confirmAccountNumber
            ) !== true
          ) {
            isDataValid = false;
            this.showAlert(
              " For " +
                this.aigMasterForm.originDetails[i].countryName +
                " -  Corr. Bank - Confirm Account Number - " +
                this.corrBankReAccNumRule(
                  this.aigMasterForm.originDetails[i].corrBank.confirmAccountNumber
                )
            );
          } else if (
            this.corrSwiftCodeRule(
              this.aigMasterForm.originDetails[i].corrBank.swiftCode
            ) !== true
          ) {
            isDataValid = false;
            this.showAlert(
              " For " +
                this.aigMasterForm.originDetails[i].countryName +
                " -  Corr. Bank - Swift Code - " +
                this.corrSwiftCodeRule(
                  this.aigMasterForm.originDetails[i].corrBank.swiftCode
                )
            );
          } else if (
            this.corrAbaCodeRule(
              this.aigMasterForm.originDetails[i].corrBank.abaCode
            ) !== true
          ) {
            isDataValid = false;
            this.showAlert(
              " For " +
                this.aigMasterForm.originDetails[i].countryName +
                " -  Corr. Bank - ABA Code - " +
                this.corrAbaCodeRule(
                  this.aigMasterForm.originDetails[i].corrBank.abaCode
                )
            );
          } else if (
            !this.aigMasterForm.originDetails[i].payeeBank.accountNumber &&
            !this.aigMasterForm.originDetails[i].payeeBank.swiftCode &&
            !this.aigMasterForm.originDetails[i].payeeBank.ibanNumber
          ) {
            isDataValid = false;
            this.showAlert(
              "Payee Bank - Please add atleast 2 values from Account number, Swift code, IBAN number for " +
                this.aigMasterForm.originDetails[i].countryName +
                ""
            );
          } else if (
            this.aigMasterForm.originDetails[i].payeeBank.accountNumber &&
            !this.aigMasterForm.originDetails[i].payeeBank.swiftCode &&
            !this.aigMasterForm.originDetails[i].payeeBank.ibanNumber
          ) {
            isDataValid = false;
            this.showAlert(
              "Payee Bank - Please add atleast 2 values from Account number, Swift code, IBAN number for " +
                this.aigMasterForm.originDetails[i].countryName +
                " is required"
            );
          } else if (
            !this.aigMasterForm.originDetails[i].payeeBank.accountNumber &&
            this.aigMasterForm.originDetails[i].payeeBank.swiftCode &&
            !this.aigMasterForm.originDetails[i].payeeBank.ibanNumber
          ) {
            isDataValid = false;
            this.showAlert(
              "Payee Bank - Please add atleast 2 values from Account number, Swift code, IBAN number for " +
                this.aigMasterForm.originDetails[i].countryName +
                ""
            );
          } else if (
            !this.aigMasterForm.originDetails[i].payeeBank.accountNumber &&
            !this.aigMasterForm.originDetails[i].payeeBank.swiftCode &&
            this.aigMasterForm.originDetails[i].payeeBank.ibanNumber
          ) {
            isDataValid = false;
            this.showAlert(
              "Payee Bank - Please add atleast 2 values from Account number, Swift code, IBAN number for " +
                this.aigMasterForm.originDetails[i].countryName +
                ""
            );
          } else if (
            this.aigMasterForm.originDetails[i].trcApplicable &&
            this.aigMasterForm.originDetails[i].documents.trc.length == 0
          ) {
            isDataValid = false;
            this.showAlert(
              "Please add atleast 1 document for TRC or deselect TRC Applicable for " +
                this.aigMasterForm.originDetails[i].countryName +
                ""
            );
          }
          if (!isDataValid) {
            break;
          }
        }
        if (isDataValid) {
          this.showProgress();
          let self = this;
          //Change date format start
          for (var i in this.aigMasterForm.originDetails) {
            if (this.aigMasterForm.originDetails[i].peValidityDate) {
              this.aigMasterForm.originDetails[i].peValidityDate = new Date(
                this.aigMasterForm.originDetails[i].peValidityDate
              ).toISOString();
            } else {
              this.aigMasterForm.originDetails[i].peValidityDate = "";
            }

            if (this.aigMasterForm.originDetails[i].trcValidityDate) {
              this.aigMasterForm.originDetails[i].trcValidityDate = new Date(
                this.aigMasterForm.originDetails[i].trcValidityDate
              ).toISOString();
            } else {
              this.aigMasterForm.originDetails[i].trcValidityDate = "";
            }
          }
          //Change date format end
          if (this.aigMasterForm.aigCode) {
            //Delete createdBy,createdById,createdAt,updatedAt,__v, file - _id
            delete this.aigMasterForm.createdBy;
            delete this.aigMasterForm.createdById;
            delete this.aigMasterForm.createdAt;
            delete this.aigMasterForm.updatedAt;
            delete this.aigMasterForm.__v;

            for (var i in this.aigMasterForm.originDetails) {
              for (var j in this.aigMasterForm.originDetails[i].documents
                .bank) {
                delete this.aigMasterForm.originDetails[i].documents.bank[j]
                  ._id;
              }

              for (var k in this.aigMasterForm.originDetails[i].documents.pe) {
                delete this.aigMasterForm.originDetails[i].documents.pe[k]._id;
              }

              for (var l in this.aigMasterForm.originDetails[i].documents.trc) {
                delete this.aigMasterForm.originDetails[i].documents.trc[l]._id;
              }

              for (var m in this.aigMasterForm.originDetails[i].documents
                .others) {
                delete this.aigMasterForm.originDetails[i].documents.others[m]
                  ._id;
              }

              this.aigMasterForm.originDetails[i].documents = [this.aigMasterForm.originDetails[i].documents]
            }
            delete this.aigMasterForm.uid;
            this.aigMasterForm.lob = ["Travel"]
            this.PUT_ByURLM(
              "updateaig",
              this.aigMasterForm,
              function (res) {
                console.log(res);
                self.hideProgress();
                if (res.data.status && res.data.data) {
                  self.showAlert(res.data.msg || "AIG Office updated successfully.");
                  self.$router.push({
                    name: 'SearchAIGMaster'
                  });
                } else {
                  self.showAlert(res.data.msg);
                  self.$router.push({
                    name: 'SearchAIGMaster'
                  });
                }
              },
              function (err) {
                console.log(err.response);
                self.hideProgress();
                let errorMsg = err.response.data.msg;
                self.showAlert("Something went wrong, please try again." + errorMsg);
              },
              this.aigMasterForm._id
            );
          } else {
            for (var i in this.aigMasterForm.originDetails) {
              this.aigMasterForm.originDetails[i].documents = [this.aigMasterForm.originDetails[i].documents]
            }
            this.aigMasterForm.lob = ["Travel"]
            this.POSTM(
              "createaig",
              this.aigMasterForm,
              function (res) {
                console.log(res);
                self.hideProgress();
                if (res.data.status && res.data.data.aigCode) {
                  self.showAlert(res.data.msg || "AIG Office updated successfully.");
                  //self.showAlert("AIG Office added successfully with code - " + res.data.data.aigCode);
                  self.$router.push({
                    name: 'SearchAIGMaster'
                  });
                } else {
                  self.showAlert(res.data.msg);
                  self.$router.push({
                    name: 'SearchAIGMaster'
                  });
                }
              },
              function (err) {
                console.log(err);
                self.hideProgress();
                let errorMsg = err.response.data.msg;
                self.showAlert("Something went wrong, please try again." + errorMsg);
              }
            );
          }
        }
      }else if(this.aigMasterForm.originDetails.length <= 0 && this.aigMasterForm.aigCode){
        if(confirm("Are you sure to save office without any country?")){
          if (this.aigMasterForm.aigCode) {
            //Delete createdBy,createdById,createdAt,updatedAt,__v, file - _id
            delete this.aigMasterForm.createdBy;
            delete this.aigMasterForm.createdById;
            delete this.aigMasterForm.createdAt;
            delete this.aigMasterForm.updatedAt;
            delete this.aigMasterForm.__v;
          }
          var self = this;
          this.aigMasterForm.lob = ["Travel"]
          this.PUT_ByURLM(
              "updateaig",
              this.aigMasterForm,
              function (res) {
                console.log(res);
                self.hideProgress();
                if (res.data.status && res.data.data) {
                  self.showAlert("AIG Office updated successfully.");
                  self.$router.push({
                    name: 'SearchAIGMaster'
                  });
                } else {
                  self.showAlert(res.data.msg);
                  self.$router.push({
                    name: 'SearchAIGMaster'
                  });
                }
              },
              function (err) {
                console.log(err.response);
                self.hideProgress();
                let errorMsg = err.response.data.msg;
                self.showAlert("Something went wrong, please try again." + errorMsg);
              },
              this.aigMasterForm._id
            );
        }
      }else{
        this.showAlert("Please fill/check all mandatory information.");
      }
    },
    confirmPayeeAccNumMatch() {
      if (
        this.aigMasterForm.originDetails[this.toggledCountry].payeeBank
          .confirmAccountNumber !==
        this.aigMasterForm.originDetails[this.toggledCountry].payeeBank
          .accountNumber
      ) {
        this.showAlert(
          // "Payee account number is not matching Payee confirm account number."//Old
        //   "Payee Account number is not matching with Confirm Payee Account number."
        "Payee Account number and Payee Re-enter Account number do not match."
        );
        this.aigMasterForm.originDetails[
          this.toggledCountry
        ].payeeBank.confirmAccountNumber = "";
      }
    },
    confirmCorrAccNumMatch() {
      if (
        this.aigMasterForm.originDetails[this.toggledCountry].corrBank
          .confirmAccountNumber !==
        this.aigMasterForm.originDetails[this.toggledCountry].corrBank
          .accountNumber
      ) {
        this.showAlert(
          // "Corr. Bank account number is not matching Corr. Bank confirm account number."//Old
        //   "Corr. Bank Account number is not matching with  Confirm Corr. Bank Account number"
        "Corr. Account number and Corr. Re-enter Account number do not match."
        );
        this.aigMasterForm.originDetails[
          this.toggledCountry
        ].corrBank.confirmAccountNumber = "";
      }
    },
    filesSelected() {
      let files = [];
      if (this.toggledDocType == 0) {
        files = this.bankFiles;
        // this.aigMasterForm.originDetails[this.toggledCountry].documents.bank    =   [];
      } else if (this.toggledDocType == 1) {
        files = this.peFiles;
        this.aigMasterForm.originDetails[this.toggledCountry].documents.pe = [];
      } else if (this.toggledDocType == 2) {
        files = this.trcFiles;
        this.aigMasterForm.originDetails[
          this.toggledCountry
        ].documents.trc = [];
      } else if (this.toggledDocType == 3) {
        files = this.othersFiles;
        this.aigMasterForm.originDetails[
          this.toggledCountry
        ].documents.others = [];
      }
      for (var i in files) {
        var fileName = files[i].name;
        // var base64String    =   ""
        // const fr = new FileReader ()
        // fr.readAsDataURL(files[i])
        var that = this;
        // fr.addEventListener('load', () => {
        console.log("Loaded");
        // base64String = fr.result;
        this.readImageM(fileName, files[i], (res) => {
          if (that.toggledDocType == 0) {
            that.aigMasterForm.originDetails[
              that.toggledCountry
            ].documents.bank.push({//Surendra(01-07-2021):Change in BE service for doc upload
            "file"          : res.fileName,
            "documentType"  : "international-surveyor",
            "fileBase"      : res.base         
          });//res);
            
          } else if (that.toggledDocType == 1) {
            that.aigMasterForm.originDetails[
              that.toggledCountry
            ].documents.pe.push({//Surendra(01-07-2021):Change in BE service for doc upload
            "file"          : res.fileName,
            "documentType"  : "international-surveyor",
            "fileBase"      : res.base         
          });//res);
          } else if (that.toggledDocType == 2) {
            that.aigMasterForm.originDetails[
              that.toggledCountry
            ].documents.trc.push({//Surendra(01-07-2021):Change in BE service for doc upload
            "file"          : res.fileName,
            "documentType"  : "international-surveyor",
            "fileBase"      : res.base         
          });//res);
          } else {
            that.aigMasterForm.originDetails[
              that.toggledCountry
            ].documents.others.push({//Surendra(01-07-2021):Change in BE service for doc upload
            "file"          : res.fileName,
            "documentType"  : "international-surveyor",
            "fileBase"      : res.base         
          });//res);
          }
        });
        // if(that.toggledDocType == 0){
        //     that.aigMasterForm.originDetails[that.toggledCountry].documents.bank.push({
        //         "fileName"  :   fileName,
        //         "base"      :   base64String
        //     });
        //     console.log("this.aigMasterForm.originDetails",that.aigMasterForm.originDetails)
        // }else if(that.toggledDocType == 1){
        //     that.aigMasterForm.originDetails[that.toggledCountry].documents.pe.push({
        //         "fileName"  :   fileName,
        //         "base"      :   base64String
        //     });
        //     console.log("this.aigMasterForm.originDetails",that.aigMasterForm.originDetails)
        // }else if(that.toggledDocType == 2){
        //     that.aigMasterForm.originDetails[that.toggledCountry].documents.trc.push({
        //         "fileName"  :   fileName,
        //         "base"      :   base64String
        //     });
        //     console.log("this.aigMasterForm.originDetails",that.aigMasterForm.originDetails)
        // }else{
        //     that.aigMasterForm.originDetails[that.toggledCountry].documents.others.push({
        //         "fileName"  :   fileName,
        //         "base"      :   base64String
        //     });
        //     console.log("this.aigMasterForm.originDetails",that.aigMasterForm.originDetails)

        // }
        // });
      }

      console.log("this.files ", files);
    },
    deleteThisFile(index) {
      this.showConfirm("Confirmation","Are you sure to delete this file ?","Yes","No",(Yes)=>{
                     if (this.toggledDocType == 0) {
          this.bankFiles.splice(index, 1);
          this.aigMasterForm.originDetails[
            this.toggledCountry
          ].documents.bank.splice(index, 1);
        } else if (this.toggledDocType == 1) {
          this.peFiles.splice(index, 1);
          this.aigMasterForm.originDetails[
            this.toggledCountry
          ].documents.pe.splice(index, 1);
        } else if (this.toggledDocType == 2) {
          this.trcFiles.splice(index, 1);
          this.aigMasterForm.originDetails[
            this.toggledCountry
          ].documents.trc.splice(index, 1);
        } else if (this.toggledDocType == 3) {
          this.othersFiles.splice(index, 1);
          this.aigMasterForm.originDetails[
            this.toggledCountry
          ].documents.others.splice(index, 1);
        }
        this.files.splice(index, 1);
                  },(No)=>{})
    },
    getIcon(fileType) {
      if (fileType == "image/png" || fileType == "image/jpeg") {
        return "mdi-file-image-outline";
      } else if (
        fileType ==
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        fileType == "application/vnd.ms-excel"
      ) {
        return "mdi-microsoft-excel";
      } else if (fileType == "application/pdf") {
        return "mdi-file-pdf";
      }
    },
    submitUploadFiles() {
      this.docUploadDialog = false;
    },
    uploadBtnClicked() {
      // this.$refs.fileupload.click()
      if (this.toggledDocType == 0) {
        document.getElementById("bankfileupload").click();
      } else if (this.toggledDocType == 1) {
        document.getElementById("pefileupload").click();
      } else if (this.toggledDocType == 2) {
        document.getElementById("trcfileupload").click();
      } else if (this.toggledDocType == 3) {
        document.getElementById("otherfileupload").click();
      }
    },
    showProgress() {
      this.overlay = true;
    },
    hideProgress() {
      this.overlay = false;
    },
    checkTrcApplicable() {
      if (this.aigMasterForm.originDetails[this.toggledCountry].trcApplicable) {
        this.showDocUploadDialog(true);
      }
    },
    resetItem(item,index){
      this.ConfirmationModal = true
      this.confirmationHeader = "Reset Data"
      this.confirmationMessage = "Are you sure to “RESET” details for country - "+ item .countryName +"?"
      this.btnNameconfirm =  "Yes,Reset"
      this.toggledCountry=index;
     
     
    
    },
    showDocUploadDialog(isForTRC) {
      this.files = [];
      if (
        this.aigMasterForm.originDetails.length !== 0 &&
        (this.toggledCountry || this.toggledCountry == 0)
      ) {
        this.docUploadDialog = true;
        this.toggledDocType = isForTRC == true ? 2 : 0;
      } else {
        this.showAlert("Please select a country to upload the document.");
      }
    },
    updateFileType() {
      if (this.toggledDocType == 0) {
        this.files = this.bankFiles;
      } else if (this.toggledDocType == 1) {
        this.files = this.peFiles;
      } else if (this.toggledDocType == 2) {
        this.files = this.trcFiles;
      } else if (this.toggledDocType == 3) {
        this.files = this.othersFiles;
      }
    },
    validateDate(dateElem) {
      console.log(
        this.aigMasterForm.originDetails[this.toggledCountry][dateElem]
      );
      if (dateElem == "trcValidityDate") {
        if (
          new Date(
            this.aigMasterForm.originDetails[
              this.toggledCountry
            ].trcValidityDate
          ) < new Date()
        ) {
          this.showAlert("TRC Validity date should be more than today.");
          this.aigMasterForm.originDetails[
            this.toggledCountry
          ].trcValidityDate = "";
        }
      } else if (dateElem == "peValidityDate") {
        if (
          new Date(
            this.aigMasterForm.originDetails[this.toggledCountry].peValidityDate
          ) < new Date()
        ) {
          this.showAlert("PE Validity date should be more than today.");
          this.aigMasterForm.originDetails[this.toggledCountry].peValidityDate =
            "";
        }
      }
    },
    addCountry() {
      console.log("Event - ", event);
    },
    toggleToCountry(countryName, index) {
      this.bankCalenderDateFrom = false;
      this.bankCalenderDateTo = false;
      this.toggledCountryName = countryName;
      if (this.toggledCountry == index) {
        console.log("going for undefined.");
        var self = this;
        setTimeout(() => {
          console.log("self.toggledCountry - ", self.toggledCountry);
          if (self.toggledCountry !== index) {
            console.log(
              "self.toggledCountry inside - ",
              self.toggledCountry,
              "index",
              index
            );
            self.toggledCountry = index;
          }
        }, 1);
      }
    },
    checkToggleCondition() {
      console.log(event);
    },
    updateSelectedCountryList(clickedCountry) {
      this.showCountryOpt = false;
      if (this.toggledCountry == "") {
        this.toggledCountry = 0;
        this.toggledCountryName = this.selectedCountryList;
        console.log(
          "toggledCountry",
          this.toggledCountry,
          "selectedCountryList",
          this.selectedCountryList,
          "clickedCountry",
          clickedCountry
        );
      }

      //ForList,start
      // if (
      //   clickedCountry &&
      //   this.toggledCountry == undefined &&
      //   this.selectedCountryList.length === 0
      // ) {
      //   this.toggledCountry = 0;
      // }
      //ForList,end

      let newCountry = clickedCountry; //event.target.textContent;//this.selectedCountryList[this.selectedCountryList.length -1];
      console.log(
        "selectedCountryList - click ",
        newCountry,
        "clickedCountry",
        clickedCountry
      );

      // if(!this.selectedCountryList.length){
      //     console.log("Empty - ",this.aigMasterForm.originDetails);
      //     this.toggledCountry = "";
      //     this.toggledCountryName =   ""
      //     this.aigMasterForm.originDetails    =   [];

      // }else
      // if (!clickedCountry) {
      //   //event.target.textContent){
      //   var deleteIndex = "";
      //   for (var i in this.aigMasterForm.originDetails) {
      //     var self = this;
      //     if (
      //       !this.selectedCountryList.find((elem) => {
      //         return elem == self.aigMasterForm.originDetails[i].countryName;
      //       })
      //     ) {
      //       deleteIndex = i;
      //       console.log("before Delete", this.aigMasterForm.originDetails[i]);
      //       break;
      //     }
      //   }

      //   this.aigMasterForm.originDetails.splice(deleteIndex, 1);
      //   console.log(
      //     "after Delete",
      //     this.aigMasterForm.originDetails,
      //     this.toggledCountry
      //   );

      //   //Test for toggle issue
      //   if (this.toggledCountry === undefined) {
      //     if (
      //       this.selectedCountryList.length > 0 &&
      //       this.aigMasterForm.originDetails.length > 0
      //     ) {
      //       this.toggledCountry = 0;
      //     }
      //   }
      //   if (deleteIndex == this.toggledCountry) {
      //     let newToggleIndex = deleteIndex - 1 >= 0 ? deleteIndex - 1 : 0;
      //     this.toggledCountry = undefined;
      //     setTimeout(function () {
      //       this.toggledCountry = newToggleIndex;
      //     }, 300);
      //   }
      // } else
       if (
        !this.aigMasterForm.originDetails.find(function (elem) {
          return elem.countryName == newCountry;
        })
      ) {
        this.aigMasterForm.originDetails.push({
          countryName: newCountry,
          addressLine1: "",
          addressLine2: "",
          addressLine3: "",
          contactPerson1: {
            name: "",
            officeNum: {
            isd:'91',
            number:''
           },
            mobileNum: {
            isd:'91',
            number:''
           },
            email: "",
          },
          contactPerson2: {
            name: "",
            officeNum: {
            isd:'91',
            number:''
           },
            
            mobileNum: {
            isd:'91',
            number:''
           },
            email: "",
          },
          peValidityDate: "",
          trcApplicable: false,
          trcValidityDate: "",
          payeeBank: {
            bankName: "",
            bankAddressLine1: "",
            bankAddressLine2: "",
            bankAddressLine3: "",
            accountNumber: "",
            confirmAccountNumber: "",
            swiftCode: "",
            ibanNumber: "",
            sortCode: "",
          },
          corrBank: {
            bankName: "",
            bankAddressLine1: "",
            bankAddressLine2: "",
            bankAddressLine3: "",
            accountNumber: "",
            confirmAccountNumber: "",
            swiftCode: "",
            abaCode: "",
          },
          documents: {
            bank: [],
            pe: [],
            trc: [],
            others: [],
          },
        });
        console.log("Add - ", this.aigMasterForm.originDetails);
      } 
      // else {
      //   var deleteIndex = this.aigMasterForm.originDetails.findIndex(function (
      //     elem
      //   ) {
      //     return elem.countryName == newCountry;
      //   });
      //   if (
      //     this.aigMasterForm.originDetails[this.toggledCountry].countryName ==
      //     newCountry
      //   ) {
      //     let newToggleIndex = deleteIndex - 1 >= 0 ? deleteIndex - 1 : 0;
      //     this.toggledCountry = undefined;
      //     setTimeout(function () {
      //       this.toggledCountry = newToggleIndex;
      //     }, 300);

      //     this.toggledCountryName = this.selectedCountryList[newToggleIndex];
      //   }
      //   this.aigMasterForm.originDetails.splice(deleteIndex, 1);

      //   console.log("Delete - ", this.aigMasterForm.originDetails);
      // }
      //Test for toggle issue
      if (this.toggledCountry === undefined) {
        if (
          // this.selectedCountryList.length > 0 &&
          this.aigMasterForm.originDetails.length > 0
        ) {
          this.toggledCountry = 0;
        }
      }deleteCountry
    },
    deleteCountry(index) {
      // console.log("check index", index)
      // alert("check index",index)
      if (index=== undefined ||(index === "" && index !== 0)) {
         this.showAlert("Please select a country to delete.");
      } else{
          // this.showConfirm("Confirmation","Are you sure to delete this country - "+this.aigMasterForm.originDetails[this.toggledCountry].countryName+"?","Yes","No",(Yes)=>{
          // var deleteIndex = this.toggledCountry;
          // let newToggleIndex = deleteIndex - 1 >= 0 ? deleteIndex - 1 : 0;
          // this.toggledCountry = undefined;
          
          // this.toggledCountryName = this.selectedCountryList;//[newToggleIndex];
          // this.aigMasterForm.originDetails.splice(deleteIndex, 1);
          // if(this.aigMasterForm.originDetails.length <= 0){
          // this.selectedCountryList  = "";
          // }
          // console.log("Delete - ", this.aigMasterForm.originDetails);
          // },(No)=>{});
          this.toggledCountry=index;
          this.ConfirmationModal = true
          this.confirmationHeader = "Confirm Deletion"
          this.confirmationMessage = "Are you sure to delete this country - "+this.aigMasterForm.originDetails[index].countryName+"?"
          this.btnNameconfirm =  "Yes,Delete"
      }
    },
    compareAccNum(accType) {
      if (
        this.aigMasterForm.originDetails[this.toggledCountry].payeeBank
          .accountNumber &&
        this.aigMasterForm.originDetails[this.toggledCountry].corrBank
          .accountNumber &&
        this.aigMasterForm.originDetails[this.toggledCountry].payeeBank
          .accountNumber ==
          this.aigMasterForm.originDetails[this.toggledCountry].corrBank
            .accountNumber
      ) {
        if (accType == "corrBank") {
          this.showAlert(
            "Corr. Bank account number cannot be same as Payee Bank account number."
          );
          this.aigMasterForm.originDetails[
            this.toggledCountry
          ].corrBank.accountNumber = "";
          this.aigMasterForm.originDetails[
            this.toggledCountry
          ].corrBank.confirmAccountNumber = "";
        } else {
          this.showAlert(
            "Payee Bank account number cannot be same as Corr. Bank account number."
          );
          this.aigMasterForm.originDetails[
            this.toggledCountry
          ].payeeBank.accountNumber = "";
          this.aigMasterForm.originDetails[
            this.toggledCountry
          ].payeeBank.confirmAccountNumber = "";
        }
      }else{
        // For issue MC-3263
        if(this.aigMasterForm.originDetails[this.toggledCountry].payeeBank.confirmAccountNumber){
          if(!this.aigMasterForm.originDetails[this.toggledCountry].payeeBank.accountNumber){
            this.aigMasterForm.originDetails[this.toggledCountry].payeeBank.confirmAccountNumber = "";
            this.showAlert("Please fill Payee Bank account number.");
            return false;
          }else if(this.aigMasterForm.originDetails[this.toggledCountry].payeeBank.confirmAccountNumber !== this.aigMasterForm.originDetails[this.toggledCountry].payeeBank.accountNumber){
            this.aigMasterForm.originDetails[this.toggledCountry].payeeBank.accountNumber = "";
            this.aigMasterForm.originDetails[this.toggledCountry].payeeBank.confirmAccountNumber = "";
            this.showAlert("Payee Bank account number and re-enter account number must be same.");
            return false;
          }
        }
        if(this.aigMasterForm.originDetails[this.toggledCountry].corrBank.confirmAccountNumber){
          if(!this.aigMasterForm.originDetails[this.toggledCountry].corrBank.accountNumber){
            this.aigMasterForm.originDetails[this.toggledCountry].corrBank.confirmAccountNumber = "";
            this.showAlert("Please fill Corr Bank account number.");
            return false;
          }else if(this.aigMasterForm.originDetails[this.toggledCountry].corrBank.confirmAccountNumber !== this.aigMasterForm.originDetails[this.toggledCountry].corrBank.accountNumber){
            this.aigMasterForm.originDetails[this.toggledCountry].corrBank.accountNumber = "";
            this.aigMasterForm.originDetails[this.toggledCountry].corrBank.confirmAccountNumber = "";
            this.showAlert("Corr Bank account number and re-enter account number must be same.");
          }
        }
        // For issue MC-3263

      }
    },
    openDownloadDialog() {
      if (
        this.aigMasterForm.originDetails.length !== 0 &&
        (this.toggledCountry || this.toggledCountry == 0)
      ) {
        this.docDownloadDialog = true;
        this.toggledDownloadDocType = 0;
      } else {
        this.showAlert("Please select a country to download the document.");
      }
    },
    closeDownloadDialog() {
      this.docDownloadDialog = false;
    },
    
    downloadThisFile(file) {
      console.log(file);
      if (file.fileUrl) {
        window.open(file.fileUrl);
      } else {
        this.showAlert("Download failed. This file is not uploaded.");
      }
    },
    mandatoryRules(v) {
      if (!v) {
        return this.validationMessages.mandatory;
      }
    },
    officeNameRulesFunc(v) {
      if (!v) {
      return  this.validationMessages.mandatory;
      }
      if(v && !v.trim()){
        return "Only spaces are not allowed"
      }

      if (!(/^[a-zA-Z0-9.,()_\s]+$/.test(v))) {
        return "AIG Office Name is not valid.";
      }

      if (v && v.length < 5) {
        return "Name of AIG Office should be more than 5 characters.";
      }
      if (v && v.length > 100) {
        return "AIG Office Name should be less than 100 characters.";
      } else {
        return true;
      }
    },
    countryRulesFunc(v) {
      if (!v) {
        return this.validationMessages.mandatory;
      }

      if (/^[a-zA-Z0-9\s]+$/.test(v)) {
        return "AIG Office Name is not valid.";
      }

      if (v && v.length > 100) {
        return "AIG Office Name should be less than 100 characters.";
      } else {
        return true;
      }
    },
    address1RulesFunc(v) {
      if (!v) {
        return this.validationMessages.mandatory;
      }
      if(v && !v.trim()){
        return "Only spaces are not allowed"
      }
      // if (v && !(/^\S[a-zA-Z0-9\s.\-,;:|-~"]+$/.test(v))) {
      if (/[;:|~"-]/g.test(v)) {
        return "Address is not valid.";
      }
      if (v && v.length > 50) {
        return "Address should be less than 50 characters.";
      } else {
        return true;
      }
    },
    address2Rules(v) {
      if (!v) {
        return this.validationMessages.mandatory;
      }
      if(v && !v.trim()){
        return "Only spaces are not allowed"
      }
      // if (v && !(/^\S[a-zA-Z0-9\s.\-,;:|-~"]+$/.test(v))) {
      if (/[;:|~"-]/g.test(v)) {
        return "Address is not valid.";
      }
      if (v && v.length > 50) {
        return "Address should be less than 50 characters.";
      } else {
        return true;
      }
    },
    address3Rules(v) {
      if (!v) {
        return this.validationMessages.mandatory;
      }
      if(v && !v.trim()){
        return "Only spaces are not allowed"
      }
      // if (v && !(/^\S[a-zA-Z0-9\s.\-,;:|-~"]+$/.test(v))) {
      if (/[;:|~"-]/g.test(v)) {
        return "Address is not valid.";
      }
      if (v && v.length > 50) {
        return "Address should be less than 50 characters.";
      } else {
        return true;
      }
    },
    nameRules(v) {
      if (!v) {
        return this.validationMessages.mandatory;
      }
      if(v && !v.trim()){
        return "Only spaces are not allowed"
      }
      if (!/^[a-zA-Z0-9\s]+$/.test(v)) {
        return "Name is not valid.";
      }
      if (v && v.length > 50) {
        return "Name should be less than 50 characters.";
      } else {
        return true;
      }
    },
    officeNumRules(v) {
      if (!v) {
        return this.validationMessages.mandatory;
      }
      if (!/^[0-9]+$/.test(v)) {
        return "Office number is not valid.";
      }
      if (v && v.length < 5) {
        return "Office number should be more than 5 digits.";
      }
      if (v && v.length > 20) {
        return "Office number should be less than 20 digits.";
      } else {
        return true;
      }
    },
    mobileNumRules(v) {
      if (v && v.length !== 0) {
        if (!/^[0-9]+$/.test(v)) {
          return "Mobile number is not valid.";
        }
        if (v && v.length < 5) {
          return "Mobile number should be more than 5 digits.";
        }
        if (v && v.toString().length > 20) {
          return "Mobile number should be less than 20 digits.";
        } else {
          return true;
        }
      } else {
        return true;
      }
      //     if( /(6|7|8|9)\d{9}/.test(v)){ return 'Mobile number is not valid.'
    },
    emailRules(v) {
      if (!v) {
        return this.validationMessages.mandatory;
      }
      if (!/^.+@.+\..+/.test(v)) {
        return "E-mail is not valid.";
      }
      if (v && v.length > 50) {
        return "Email should be less than 50 characters.";
      } else {
        return true;
      }
    },
    name2RuleFunc(v) {
      if (v && v.length !== 0) {
        //v => ( v.length < 0 && v.length <= 50) || 'Name should be less than 50 characters.',
        //      v => /[a-zA-Z0-9\s]+$/.test(v) || 'Name is not valid.'
        if (!/^[a-zA-Z0-9\s]+$/.test(v)) {
          return "Name is not valid.";
        }
        if (v.length > 50) {
          return "Name should be less than 50 characters.";
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    office2NumRules(v) {
      if (v && v.length !== 0) {
        if (!/^[0-9]+$/.test(v)) {
          return "Office number is not valid.";
        }
        if (v && v.length < 5) {
          return "Office number should be more than 5 digits.";
        }
        if (v && v.length > 20) {
          return "Office number should be less than 20 digits.";
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    mobile2NumRules(v) {
      if (v && v.length !== 0) {
        if (v && v.length < 5) {
          return "Mobile number should be more than 5 digits.";
        }
        if (v && v.toString().length > 20) {
          return "Mobile number should be less than 20 digits.";
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    email2Rules(v) {
      if (v && v.length !== 0) {
        if (!/^.+@.+\..+/.test(v)) {
          return "E-mail is not valid.";
        }
        if (v && v.length > 50) {
          return "Email should be less than 50 characters.";
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    bankNameRules(v) {
      if (!v) {
        return this.validationMessages.mandatory;
      }
      if(v && !v.trim()){
        return "Only spaces are not allowed"
      }
      if (!/^[a-zA-Z0-9\s]+$/.test(v)) {
        return "Bank Name is not valid.";
      }
      if (v && v.length > 100) {
        return "Bank Name should be less than 100 characters.";
      } else {
        return true;
      }
    },
    bankAddressRules(v) {
      if (!v) {
        return this.validationMessages.mandatory;
      }
      if(v && !v.trim()){
        return "Only spaces are not allowed"
      }
      if (!/^[a-zA-Z0-9\s.\-,;:|-~"]+$/.test(v)) {
        return "Address is not valid.";
      }
      if (v && v.length > 50) {
        return "Address should be less than 50 characters.";
      } else {
        return true;
      }
    },
    bankAccNumRule(v) {
      if (v && v.length > 0 && !/^[A-Z0-9]+$/.test(v)) {
        return "Account Number is not valid.";
      }
      if (v && v.length > 50) {
        return "Account Number should be less than 50 characters.";
      } else {
        return true;
      }
    },
    bankReAccNumRule(v) {
      if (v && v.length > 0 && !/^[A-Z0-9]+$/.test(v)) {
        return "Account Number is not valid.";
      }
      if (v && v.length > 50) {
        return "Account Number should be less than 50 characters.";
      } else {
        return true;
      }
      //if( (v && v !== this.aigMasterForm.payeeBankDetails.bankAccNum)){ return 'Account Number does not match.'
    },
    swiftCodeRule(v) {
      if (v && v.length !== 0) {
        if (!/^[a-zA-Z0-9]+$/.test(v)) {
          return "Swift Code is not valid.";
        }
        if (v && v.length > 50) {
          return "Swift Code should be less than 50 characters.";
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    ibanCodeRule(v) {
      if (v && v.length !== 0) {
        if (!/^[a-zA-Z0-9]+$/.test(v)) {
          return "IBAN Code is not valid.";
        }
        if (v && v.length > 50) {
          return "IBAN Code should be less than 50 characters.";
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    sortCodeRule(v) {
      if (v && v.length !== 0) {
        if (!/^[a-zA-Z0-9]+$/.test(v)) {
          return "Sort Code is not valid.";
        }
        if (v && v.length > 50) {
          return "Sort Code should be less than 50 characters.";
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    corrBankNameRules(v) {
      if (v && v.length !== 0) {
        if (!/^[a-zA-Z0-9\s]+$/.test(v)) {
          return "Bank Name is not valid.";
        }
        if (v && v.length > 100) {
          return "Bank Name should be less than 100 characters.";
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    corrBankAddressRules(v) {
      if (v && v.length !== 0) {
        if (!/^[a-zA-Z0-9\s.\-,;:|-~"]+$/.test(v)) {
          return "Address is not valid.";
        }
        if (v && v.length > 50) {
          return "Address should be less than 50 characters.";
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    corrBankAccNumRule(v) {
      if (v && v.length !== 0) {
        if (!/^[A-Z0-9]+$/.test(v)) {
          return "Account Number is not valid.";
        }
        if (v && v.length > 50) {
          return "Account Number should be less than 50 characters.";
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    corrBankReAccNumRule(v) {
      if (v && v.length !== 0) {
        if (!/^[A-Z0-9]+$/.test(v)) {
          return "Account Number is not valid.";
        }
        if (v && v.length > 50) {
          return "Account Number should be less than 50 characters.";
        } else {
          return true;
        }
      } else {
        return true;
      }
      // if( (v && v !== this.aigMasterForm.corrBankDetails.bankAccNum)){ return 'Account Number does not match.'
    },
    corrSwiftCodeRule(v) {
      if (v && v.length !== 0) {
        if (!/^[a-zA-Z0-9]+$/.test(v)) {
          return "Swift Code is not valid.";
        }
        if (v && v.length > 50) {
          return "Swift Code should be less than 50 characters.";
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    corrAbaCodeRule(v) {
      if (v && v.length !== 0) {
        if (!/^[a-zA-Z0-9]+$/.test(v)) {
          return "ABA Code is not valid.";
        }
        if (v && v.length > 50) {
          return "ABA Code should be less than 50 characters.";
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    corrSortCodeRule(v) {
      if (v && v.length !== 0) {
        if (!/^[a-zA-Z0-9]+$/.test(v)) {
          return "Sort Code is not valid.";
        }
        if (v && v.length > 50) {
          return "Sort Code should be less than 50 characters.";
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    clearSearch() {
      this.search = "";
    },
    isBtnActive(index) {
      if (index == this.toggledCountry) {
        return "active_btn";
      } else {
        return "";
      }
    },
    hideCountryList(){
        this.showCountryOpt = !this.showCountryOpt;
        this.clearSearch();
    },
     validateIsd(v){
      //  console.log("validateIsd,",v)
      let isdExp = /^[0-9]+$/;
      if (v && !(isdExp.test(v))) {
        return 'Invalid ISD code'
      } else {
        return true;
      }
    },
    uppercase(param,index) {
      console.log(param)
      if(param && param.includes(".")){
        param = param.split(".")    
       // this.aigMasterForm.originDetails[index][param[0]][param[1]] = this.aigMasterForm.originDetails[index][param[0]][param[1]].toUpperCase();    
      }else{
        this.aigMasterForm[param] = this.aigMasterForm[param].toUpperCase();
      }
      
    }, 
    addressValidation(v){
      if(v && !(/^\S[a-zA-Z0-9\s.\-,;:|-~"]+$/.test(v))){
        return "This field is not valid"
      }else{
        return true;
      }
      
    },      
  },
  computed: {
    showHideEmptyData(){
      return this.aigMasterForm.originDetails.length > 0 ? false : true;
    },
    computedType() {
      return this.otheFieldsEnteredForPAcc ? "text" : "password";
    },
    computedBankDateForm() {
      return this.aigMasterForm?.originDetails[this.toggledCountry]?.peValidityDate  ? moment(this.aigMasterForm.originDetails[this.toggledCountry].peValidityDate).format("DD-MM-YYYY")  : "";
    },
    computedBankDateTo() {
      return this.aigMasterForm?.originDetails[this.toggledCountry]?.trcValidityDate ? moment(this.aigMasterForm.originDetails[this.toggledCountry].trcValidityDate).format("DD-MM-YYYY") : "";
    },
    filteredItems() {
      return this.countryList.filter((item) => {
        if (!this.search) return this.countryList;
        return item.country//countryName
          .toLowerCase()
          .includes(this.search.toLowerCase());
      });
    },
  },
  watch: {
    toggledCountry(val) {
      console.log("val - ", val);
      if (
        this.aigMasterForm.originDetails.length > 0 &&
        val === undefined &&
        this.toggledCountry !== 0
      ) {
        this.toggledCountry = 0;
      }
    }
  },
  created() {
    this.$store.commit("SAVE_PAGE_HEADER", "AIG Office Master");
    //----------Get Country List--------------------//
    this.showProgress();
    let self = this;
    this.GETM(
      "COUNTRY_LIST",
      '',
      (res) => {
        let data = res.data.data.data;
        self.countryList = data;
        this.hideProgress();
      },
      (err) => {
        console.log(err.response);
        this.hideProgress();
        this.showAlert(
          "Something went wrong, please try again." + err.response.data.msg
        );
      }
    );
    //Check and get office details for update
    console.log("this.$route.params", this.$route.params);
    this.showProgress();
    if(this.$route.params.isView){
      this.main_heading="View"
    }
    if(this.$route.params.isView && this.$route.params.isApproval){
      this.main_heading="Approve/Reject"
    }
    if(!this.$route.params.isView && this.$route.params.aig_Data){
      this.main_heading="Modify"
    }
    if (this.$route.params && this.$route.params.aig_Data && this.$route.params.aig_Data.aigCode) {
      // if(true){
      let selectedAigOffice = this.$route.params.aig_Data;
      let _id = selectedAigOffice._id; //"601cde9c7f9d2d02a3c62137";//
      //  let _idCountry         =   selectedAigOffice._idCountry;
      this.GET_ByURLM(
        "getSingleaig",
        _id,
        (res) => {
          let data = res.data.data;
          this.aigMasterForm = data;
          // for (var i in this.aigMasterForm.originDetails) {
          //   this.selectedCountryList.push(
          //     this.aigMasterForm.originDetails[i].countryName
          //   );
          // }
          this.hideProgress();
          for (var i in data.originDetails) {
            this.aigMasterForm.originDetails[i].finalSubmit = true
            this.aigMasterForm.originDetails[i].documents = data.originDetails[i].documents[0];
          }

          this.toggledCountry = 0;
          console.log(data);
          this.hideProgress();
        },
        (err) => {
          console.log(err.response);
          this.hideProgress();
          this.showAlert(
            "Something went wrong, please try again." + err.response.data.msg
          );
        }
      );
    }else{
      this.IssaveRow = false
       this.aigMasterForm.originDetails.push({
          finalSubmit: true,
          countryName: "",
          addressLine1: "",
          addressLine2: "",
          addressLine3: "",
          contactPerson1: {
            name: "",
            officeNum: {
            isd:'91',
            number:''
           },
            mobileNum: {
            isd:'91',
            number:''
           },
            email: "",
          },
          contactPerson2: {
            name: "",
            officeNum: {
            isd:'91',
            number:''
           },
            
            mobileNum: {
            isd:'91',
            number:''
           },
            email: "",
          },
          peValidityDate: "",
          trcApplicable: false,
          trcValidityDate: "",
          payeeBank: {
            bankName: "",
            bankAddressLine1: "",
            bankAddressLine2: "",
            bankAddressLine3: "",
            accountNumber: "",
            confirmAccountNumber: "",
            swiftCode: "",
            ibanNumber: "",
            sortCode: "",
          },
          corrBank: {
            bankName: "",
            bankAddressLine1: "",
            bankAddressLine2: "",
            bankAddressLine3: "",
            accountNumber: "",
            confirmAccountNumber: "",
            swiftCode: "",
            abaCode: "",
          },
          documents: {
            bank: [],
            pe: [],
            trc: [],
            others: [],
          },
        });
    }
  },
};
</script>

<style scoped>
.overflowNone{
  overflow: hidden !important;
}
.displayNone{
  display: none !important;
}
.positionRelative{
 position: relative !important;
}
.backgroundButton{
  background-color: #f7f7f7 !important; 
  padding: 10px !important;
}
.vlayoutAlignment{
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: flex-end !important;
}

.addyourfile{
  align-self: center !important;
}

.cursorPoniter{
  cursor: pointer !important;
}
.downloadDocTitle{
  color: #10242b !important;
  font-size: 13px !important; 
  padding: 5px !important;
}

.boxshadowUploadDoc{
  box-shadow: 0px 5px 7px #00000029 !important;
}

.boxshadowone{
flex: 1 !important; 
/* background-ox-shadow: 0px 3px 5px #00000029; */
}
.multipleDoc{
	border: 3px dashed #c1c8cc !important;
	border-bottom: none !important;
	display: flex !important;
	flex-direction: row !important; 
}
.trcappicableLayout2{
	background-color: #f7f7f7 !important;
	display: flex !important;
	flex-direction: row !important;
	justify-content: center !important;
	align-items: center !important;
	padding-left: 10px !important;
}
.bankDeatilsLayout{
  text-transform: uppercase !important;
  background-color: #01bbd9 !important;
  padding: 10px !important;
  color: #ffffff!important;
  margin-left: 10px !important;
}
.trcappicableLayout{
	background-color: #f7f7f7 !important;
	display: flex !important;
	flex-direction: row !important;
	justify-content: center !important;
	align-items: center !important;
	padding-left: 10px !important;
}
.countryListFlex{
	margin-right: 10px !important;
	overflow: auto !important;
	display: flex !important;
	flex-direction: column-start !important;

}
.countrylistAllbuttons{
	width: 200px !important;
	height: 40px !important;
	margin: 10px !important;
  text-transform:none !important;
	background-color: #ffffff !important;
	border: 1px solid #c1c8cc !important;
	color: black !important;
}
.aigOfflicText{
  text-transform: uppercase  !important;
  background-color: #01bbd9  !important;
  padding-left: 10px  !important;
  color: #ffffff  !important;
  margin-left: 10px  !important;
}
.aigOfflicTexttrue{
  text-transform: uppercase  !important;
  background-color: white  !important;
  padding-left: 10px  !important;
  color: #01bbd9  !important;
  margin-left: 10px  !important;
  box-shadow: 2px 7px 9px #00000029;
}
.text-red{
  color: red;
}
.addcounteyRow{
margin-bottom: 0px  !important; 
overflow: hidden  !important;
}
.addcountryButton{
margin: 10px  !important;
background-color: #e46a25  !important;
color: #ffffff  !important;
 text-transform:none !important;
}
.active_btn {
  background-color: #01bbd9 !important;
}

.active_doc_btn {
  background-color: #e46a25 !important;
  color: #ffffff;
}

.row_line {
  margin: 10px;
}

.preicon_background {
  background-color: #c1c8cc;
  margin-right: 2px;
}
.v-btn {
  text-transform: none;
  cursor: pointer;
}

.iconstyle {
  margin-left: -12px;
  background-color: rgb(247, 247, 247);
  width: 45px;
  height: 38px;
  font-size: 20px !important;
  border: 0.3px solid rgb(232, 235, 236);
  opacity: 1;
  /* box-shadow: rgba(0, 0, 0, 0.318) 0px 2px 2px; */
}

.mandatory_asterisk {
  color: #d1121b;
}
.checkpadding{
    margin-top: 53px;
}
.countryAuto, .theme--light.v-input input, .theme--light.v-input textarea {
    color:white !important;
}   
.countryAuto input::placeholder {
  color: red!important;
  opacity: 1;
}
.v-slider__tick-label {
    transform: translateX(0%);
    color: #0073Be!important;
    font-size: 12px;
    font-weight: 600;
}
.v-slider__tick--filled {
    background-color: #c1c8cc;
    width: 8px;
    height: 8px;
    left: calc(33.3333% - 2px);
    top: calc(50% - 2px);
}
.v-slider__tick {
    position: absolute;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.5);
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    border-radius: 50% !important;
    width: 8px !important;
    height: 8px !important;
}
   .claim {
        /* background-color: #f7f7f7; */
        background-color: #EDEDED;
        border: 1px solid #c1c8cc;
        font-weight: bold;
        width: 253px;
        height: 39px;
        padding: 7px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
  .fontStyle{
        font-size: 18px !important;
    }
  .textstyle{
        color: #26B1A9;
        font-size: 16px;
        font-weight: 500;
        cursor: pointer;
  }
  .dailogboxAllvcardTableBackground {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 7px #00000029;
    opacity: 1;
    font-size: 14px;
}
.colorbg {
    background-color: #23b1a9;
    color: #fff;
}
.descrptionSpan {
    width: 80%;
    margin-top: 21px;
    font-weight: 500;
    font-size: 17px;
    letter-spacing: 0.4px;
}
.cancelbtn {
    border: 1px solid #4c4c4c;
    height: 32px !important;
}
.btncommonstyle{
   height: 32px !important;
}
.dragDropDiv {
    top: 1092px;
    left: 62px;
    width: 660px;
    height: 149px;
    /* UI Properties */
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    border: 1px dashed var(--unnamed-color-c1c8cc);
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px dashed #c1c8cc;
    opacity: 1;
}


.marginDrag {
    margin-top: 67px;
}

.underline {
    color: blue;
    text-decoration: underline;
}

.spantext {
    color: var(--unnamed-color-10242b);
    text-align: center;
    letter-spacing: NaNpx;
    color: #10242b;
    opacity: 0.5;
    font-size: 13px;
}

.documentUploadBtn {
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    background: #ffffff 0% 0% no-repeat padding-box !important;
    border: 1px solid #b5c1c4;
    border-radius: 4px 4px 0px 0px;
    opacity: 1;
    color: rgb(35, 177, 169);
    height: 32px !important;
}

.activeredbackground {
    background-color: rgb(35, 177, 169);
    color: white;
}

.overflow-hidden {
    display: none;
}
.tableicons{
    font-size: 20px;
}
 .claimbtnstyle{
        border-top-right-radius: 10% !important;
        border-top-left-radius: 10% !important;
        letter-spacing: unset;
        color: white;
        border-left:1px solid #23B1A9 !important;
        
    }
    .activebg{
        color:white !important;
        background-color: #23B1A9 !important;
        border: 1px solid #23B1A9;
    }

    .inactivebg{
        color:#23B1A9;
        background-color: white !important;
    }
    

.v-application--is-ltr .v-slider--horizontal .v-slider__tick:first-child .v-slider__tick-label {
    transform: translateX(0%);
    color: blue !important;
}
.margintwxt{
  margin-left: 74px;
}
</style>