<template>
  <div>
    <v-container fluid>
      <v-layout row>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">Claim Number</v-text>
            <v-text-field
              solo
              label="Enter..."
              maxlength="20"
              counter="20"
              class="rounded-0"
              aria-required="true"
              v-model="formData.claimNumber"
              disabled
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">Claim Type</v-text>
            <v-text-field
              label="Select"
              v-model="formData.claimType"
              solo
              class="rounded-0"
              disabled
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">Certificate Number</v-text>
            <v-text-field
              v-model="formData.certificateNumber"
              solo
              item-text="state"
              item-value="state"
              class="rounded-0"
              :disabled="true"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">Policy Number</v-text>
            <v-text-field
              v-model="formData.policyNumber"
              solo
              item-text="state"
              item-value="state"
              class="rounded-0"
              :disabled="true"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Claim Registration Date
            </v-text>
            <v-menu
              ref="calender"
              :close-on-content-click="true"
              :nudge-right="40"
              transition="scale-transition"
              max-width="290px"
              min-width="290px"
              class="rounded-0"
              disabled
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-bind="attrs"
                  label="Select.."
                  :value="computedDate('claimRegisteredDate')"
                  solo
                  class="rounded-0"
                  disabled
                  v-on="on"
                >
                  <div
                    slot="prepend-inner"
                    style="background-color: #f7f7f7; padding: 9px !important"
                  >
                    <v-icon>event</v-icon>
                  </div>
                </v-text-field>
              </template>
              <v-date-picker v-model="formData.claimRegisteredDate" no-title>
              </v-date-picker>
            </v-menu>
          </v-layout>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">Claimed Amount</v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.reservedAmount"
              maxlength="20"
              counter="20"
              class="rounded-0"
              disabled
              aria-required="true"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Date of Admission
            </v-text>
                <v-text-field
                  v-bind="attrs"
                  label="Select.."
                  :value="computedDate('dateOfAdmission')"
                  solo
                  class="rounded-0"
                  v-on="on"
                  disabled
                >
                  <div
                    slot="prepend-inner"
                    style="background-color: #f7f7f7; padding: 9px !important"
                  >
                    <v-icon>event</v-icon>
                  </div>
                </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Date of Discharge
            </v-text>
                <v-text-field
                  label="Select.."
                  :value="computedDate('dateOfDischarge')"
                  solo
                  class="rounded-0"
                  v-on="on"
                  disabled
                >
                  <div
                    slot="prepend-inner"
                    style="background-color: #f7f7f7; padding: 9px !important"
                  >
                    <v-icon>event</v-icon>
                  </div>
                </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Hospital Name
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.hospitalName"
              maxlength="100"
              counter="100"
              class="rounded-0"
              aria-required="true"
              disabled
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Hospital State
            </v-text>
            <v-text-field
              label="Enter..."
              maxlength="100"
              counter="100"
              v-model="formData.hospitalState"
              solo
              disabled
              class="rounded-0"
            >
            </v-text-field>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Hospital City
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.hospitalCity"
              maxlength="100"
              counter="100"
              class="rounded-0"
              aria-required="true"
              disabled
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Hospital Pin Code
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.hospitalPinCode"
              maxlength="100"
              counter="100"
              class="rounded-0"
              aria-required="true"
              disabled
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Hospital Address
            </v-text>
            <v-tooltip top> 
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                solo
                label="Enter..."
                v-on="on" 
                v-bind="attrs"
                v-model="formData.hospitalAddress"
                maxlength="100"
                counter="100"
                class="rounded-0"
                aria-required="true"
                disabled
                >
              </v-text-field>
            </template>
            <span>{{formData.hospitalAddress}}</span>
        </v-tooltip>
        </v-layout>
      </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Hospital Contact Details
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.hospitalContactDetails"
              maxlength="100"
              counter="100"
              class="rounded-0"
              aria-required="true"
              disabled
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Hospital Type
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.hospitalType"
              maxlength="100"
              counter="100"
              class="rounded-0"
              aria-required="true"
              disabled
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Benefit Name
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.coverageName"
              maxlength="100"
              counter="100"
              class="rounded-0"
              aria-required="true"
              disabled
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Claim Status
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.claimStatus"
              maxlength="100"
              counter="100"
              class="rounded-0"
              aria-required="true"
              disabled
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Claim Sub Status
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.claimSubStatus"
              maxlength="100"
              counter="100"
              class="rounded-0"
              aria-required="true"
              disabled
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Treating Doctor Name
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.treatingDoctorName"
              maxlength="100"
              counter="100"
              class="rounded-0"
              aria-required="true"
              disabled
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Treating Doctor Mobile Number
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.treatingDoctorMobileNumber"
              maxlength="10"
              counter="10"
              class="rounded-0"
              aria-required="true"
              disabled
            >
            </v-text-field>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import moment from "moment";
import { getClaimDetails } from "../apiservices/Investigation.js";
import { getClaimDetailsId } from "../apiservices/formData";
import { getAllPinCodes } from '../apiservices/Investigation';
export default {
  data() {
    return {
      validationmessage: "This field is required",
      rules: {
        mandatoryrule: [(v) => !!v || this.validationmessage],
      },
      unifiedClaimNumber: "",
      formData: {
        claimNumber: "",
        featureNumber: "",
        claimRegisteredDate: "",
        claimType: "",
        reservedAmount: "",
        claimedAmount: "",
        dateOfAdmission: "",
        dateOfDischarge: "",
        hospitalPinCode: "",
        hospitalAddress: "",
        hospitalStatus: "",
        hospitalContactDetails: "",
        hospitalType: "",
        coverageName: "",
        claimStatus: "",
        claimSubStatus: "",
        reservedAmount: "",
        treatingDoctorName: "",
        treatingDoctorMobileNumber: "",
        certificateNumber: "",
        policyNumber: "",
        hospitalState: "",
        hospitalCity: "",
      },
    };
  },
  props: ['otherInfo'],
  async mounted() {
    let res =  await getClaimDetails(this.otherInfo);
    Object.keys(this.formData).forEach(item => {
      this.formData[item] = res[item];
    });
    try{
      this.formData.claimNumber = res.claimNumber + res.featureNumber;
      this.unifiedClaimNumber = this.formData.claimNumber + ' ' + this.formData.featureNumber;
    } catch(e){
      this.showToast(e.response?.data?.msg || e.response?.data  || e.response || e, this.TOST().ERROR);
    }
    let data = {};
    try{
      data = await getClaimDetailsId(this.$route.params.claimId);
      let claimDetail = data.data.claimsDetail;
        let allDetail = data.data;
        this.formData.dateOfDischarge = allDetail?.hospitalizationDetailsModel?.dateOfDischarge;
        this.formData.hospitalPinCode = allDetail?.providerDetails?.pinPostalCode ?? null;
        this.formData.hospitalName = allDetail?.providerDetails?.hospitalDetails?.providerName;
        this.formData.hospitalAddress = allDetail?.providerDetails?.hospitalDetails?.providerAddress;
        this.formData.hospitalContactDetails = allDetail?.providerDetails?.hospitalDetails?.telephoneNumber;
        this.formData.hospitalType = allDetail?.providerDetails?.hospitalDetails?.providerType;
        this.formData.treatingDoctorName = allDetail?.providerDetails?.treatingDoctor?.firstName + " " + allDetail?.providerDetails?.treatingDoctor?.middleName + " " + allDetail?.providerDetails?.treatingDoctor?.lastName;
        this.formData.treatingDoctorMobileNumber = allDetail?.providerDetails?.treatingDoctor?.phoneNumber;
        if(claimDetail.hasOwnProperty("claimRegisteredDate")){
          this.formData.claimRegisteredDate = claimDetail.claimRegisteredDate;
        }else{
          this.formData.claimRegisteredDate = allDetail.patientAdmissionDetails.claimRegisteredDate;
        }
        this.getAddressData(this.formData.hospitalPinCode);
    } catch(e) {
      this.showToast(e.response?.data?.msg || e.response?.data  || e.response || e, this.TOST().ERROR);
    }
  },
  methods: {
    async getAddressData(val){
      if(val){
      let res = await getAllPinCodes(val);
     this.formData.hospitalCity = res[0].cityDistrictName ?? '';
     this.formData.hospitalState = res[0].stateName ?? '';
      }
    },
    computedDate(name) {
      return this.formData[name] ? moment(this.formData[name]).format("DD/MM/YYYY") : "";
    },
    alphaNumeric(fieldName, name, mandatory = true) {
      if (fieldName.length > 0) {
        return (v) => /^[a-zA-Z0-9]+$/.test(v) || "Enter valid " + name;
      }
    },
  },
};
</script>
