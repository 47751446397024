<template>
  <v-dialog v-model="show" max-width="450px">
    <v-card tile color="#F7F7F7" class="pb-2">
      
      <v-card tile >
        <v-layout justify-space-between  class="" style="flex:1">
          <v-card-title class="pa-0 pl-3 text-capitalize font-weight-bold" style="color:#10242B;font-size:15px">Errors And Warnings</v-card-title>   
            <div style="border-left:1px solid #C1C8CC;" @click.stop="show=false"> 
              <v-icon style="cursor: pointer;" class="pa-2">close</v-icon>
            </div>
        </v-layout>
      </v-card>

      <v-card  class="ma-3" style="border-left:5px solid #F28D21">
        <v-layout align-center class="pa-2 pl-4">
          <v-icon color="#F28D21" >info</v-icon>
          <v-layout column class="pl-3">
            <p class="pa-0 ma-0 font-weight-bold text-uppercase text-body-2">Warning</p>
            <p class="pa-0 ma-0 text-caption">{{data.Warnings}}</p>
          </v-layout>
        </v-layout>  
      </v-card>

      <v-card  class="mx-3 mt-3" style="border-left:5px solid #D1121B">
        <v-layout align-center class="pa-2 pl-4">
          <v-icon color="#D1121B" >cancel</v-icon>
          <v-layout column class="pl-3">
            <p class="pa-0 ma-0 font-weight-bold text-uppercase text-body-2">Error</p>
            <p class="pa-0 ma-0 text-caption">{{data.error}}</p>
          </v-layout>
        </v-layout>  
      </v-card>

      <v-card  class="mx-3 mt-3" style="border-left:5px solid #D1121B">
        <v-layout align-center class="pa-2 pl-4">
          <v-icon color="#D1121B" >cancel</v-icon>
          <v-layout column class="pl-3">
            <p class="pa-0 ma-0 font-weight-bold text-uppercase text-body-2">Error</p>
            <p class="pa-0 ma-0 text-caption">{{data.error}}</p>
          </v-layout>
        </v-layout>  
      </v-card>

    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props:{
    data: {
      type: Object,
      default: () => ({})
    },
    visible:{
      type:Boolean,
      default:false
    }
  },
  mounted () { 
         console.log(this.data,"testing") // {x:1}
         console.log(this.visible,"testing") // {x:1}
     },
  
  computed: {
    show: {
      get () {
        return this.visible
      },
      set (value) {
        if (!value) {
          this.$emit('close')
        }
      }
    }
  }
}
</script>