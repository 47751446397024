import vue from 'vue'



vue.filter('moneyformat', function moneyfilter (input) {
    console.log("mobeyfilter", input)
    if(isNaN(input)){
        return "-"
    }
    if(typeof input == 'number'){          
            input = input.toLocaleString('en-IN', {
            maximumFractionDigits:0,
            style: 'currency',
            currency: 'INR'
        });
        console.log("is NAN ?",input)
        return  input
    }else{
        return "-"
    }
})

