<template>
  <div class="pa-5">
    <v-form ref="catastropheref" @submit.prevent="searchCatastrophe" class="mb-7">
      <v-row>
        <v-col style="max-width:17.5%">
                    <p class="claimlabel mb-2">LOB</p>
                    <v-select
                        label="Select"
                        v-model="searchObj.lob"
                        :items="catastropheLobOptions"
                        solo
                        dense
                    ></v-select>
        </v-col>
        <v-col class="mt-6">
          <v-text-field
            placeholder="Enter Description"
            v-model="searchObj.catastropheDescription"
            solo
            dense
            class="forminput"
            clearable
          ></v-text-field>
        </v-col>
        <v-col class="mt-6">
          <v-btn
            color="#23B1A9"
            dark
            class="mr-3"
            @click="searchCatastrophe(false)"
          >
            <v-icon class="mr-2 iconsize">mdi-magnify</v-icon>
            <div class="vertical_line"></div>
            Search
          </v-btn>
          <v-btn outlined color="#D1121B" class="mr-3" @click="reset()">
            <v-icon class="mr-2 iconsize">mdi-close-box-outline</v-icon>
            <div class="vertical_line"></div>
            Reset
          </v-btn>
        </v-col>
        <v-col align="right" class="mt-6">
          <v-btn color="#23B1A9" dark class="mr-3" @click="goToCreatePage()">
            <v-icon class="mr-2 iconsize">mdi-plus-box-outline</v-icon>
            <div class="vertical_line"></div>
            Create Catastrophe Master
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <template>
      <v-data-table
        :headers="catastropheHeaders"
        no-data-text="No Records for Catastrophe Master"
        fixed-header
        :items="catastropheData"
        hide-default-footer
        class="vDataTableStyling tableborder my-3"
      >
        <template v-slot:[`item.action`]="{ item }">
          <div class="text-center">
            <v-menu bottom offset-y rounded="0" nudge-left="0">
              <template v-slot:activator="{ on, attrs }">
                <v-icon class="mr-2 menusize" v-bind="attrs" v-on="on"
                  >mdi-dots-vertical</v-icon
                >
              </template>

              <v-list>
                <v-list-item
                  style="min-height: 28px !important"
                  v-for="(item1, index) in menuItems"
                  :key="index"
                  dense
                  :color="item1.color"
                  class="ma-0 pa-0"
                  @click="modify(item, index)"
                >
                  <v-icon
                    small
                    class="pa-2 pt-2 iconsize"
                    :color="item1.color"
                    >{{ item1.icon }}</v-icon
                  >
                  <v-divider vertical class="mx-1"></v-divider>
                  <v-list-item-title
                    class="pa-1 pr-3 ma-0 mr-0"
                    :color="item1.color"
                    :class="item1.name"
                    style="font-size: 12px !important"
                    >{{ item1.title }}</v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </template>
        <template v-slot:[`item.startDate`]="{ item }">
          <span>
            {{ formatDate(item.startDate) }}
          </span>
        </template>
        <template v-slot:[`item.endDate`]="{ item }">
          <span>
            {{ formatDate(item.endDate) }}
          </span>
        </template>
      </v-data-table>
    </template>
    <!-- pagination -->
    <div
      class="text-center graybackground pdct-stUp-bnkAcc-pgn mt-3 mx-4"
      v-if="catastropheData.length > 0"
    >
      <v-layout justify-end>
        <div style="margin-top: 15px">
          <span class="orangetext paginationText">
            Showing {{ totalListCount ? pageStartIndex + 1 : 0 }} -
            {{ pageEndIndex }}
          </span>
          <span class="mr-2 paginationText">
            out of {{ totalListCount }} records
          </span>
        </div>
        <!-- @input="goNextPagePagination" -->
        <v-pagination
          v-model="pageNumber"
          :length="totalPages"
          :total-visible="pageRowCount"
          @input="searchCatastrophe"
          color="#152F38"
          class="navpagenumber pt-1"
        ></v-pagination>
      </v-layout>
      <br />
    </div>
    <v-row>
      <v-col>
        <v-btn outlined color="red" class="btn" @click="cancel">
          <v-icon> mdi-backspace </v-icon>
          <v-divider vertical class="mx-2"></v-divider>
          Cancel
        </v-btn>
      </v-col>
    </v-row>
    <!-- For Audit Trail -->

    <v-dialog v-model="audit_dialog" width="720">
      <v-card style="max-width:100%">
        <v-card-title class="white--text" style="background-color: #23b1a9">
          Audit Trail
          <v-spacer></v-spacer>
          <v-icon @click="audit_dialog = !audit_dialog" class="white--text"
            >mdi-window-close</v-icon
          >
        </v-card-title>
        <v-layout class="pt-4 form-group-container">
          <v-data-table
            :headers="audit_Header"
            hide-default-footer
            :items="audit_Data"
            class="vDataTableStyling"
          >
            <template v-slot:[`item.srno`]="{ index }">
              {{ 1 + index }}
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-btn
                v-if="item.action === 'Modified'"
                color="#FFFFFF"
                class="text-capitalize tablebtn"
                @click="seeModify(item)"
              >
                {{ item.action }}
                <v-icon class="tableicons">mdi-arrow-right-circle</v-icon>
              </v-btn>
              {{ item.action !== "Modified" ? item.action : null }}
            </template>
            <template v-slot:[`item.createdAt`]="{ item }">
              {{ formatDateTime(item.createdAt) }}
            </template>
          </v-data-table>
        </v-layout>
        <v-layout justify-end align-center class="pb-2 px-0 mr-4">
          <div>
            <span style="color: orange"
              >Showing {{ AuditPageStartIndex }}-{{ AuditPageEndIndex }}</span
            >
            out of {{ AuditTotalListCount }} records
          </div>
          <div>
            <v-pagination
              class="small-pagination"
              v-model="AuditPage"
              :length="AuditPageCount"
              :total-visible="5"
              @input="getAuditData(AuditPage, '', true)"
              color="#152F38"
            ></v-pagination>
          </div>
        </v-layout>
      </v-card>
    </v-dialog>
    <!-- Modify dialogue -->
    <v-dialog v-model="seeModifyDialog" width="600">
      <v-card>
        <v-card-title class="white--text" style="background-color: #23b1a9">
          <v-layout justify-space-between>
            <div>Modified Field Details</div>
          </v-layout>
          <v-btn
            color="transparent"
            small
            :ripple="false"
            depressed
            @click="seeModifyDialog = false"
          >
            <v-icon color="white">mdi-close </v-icon>
          </v-btn>
        </v-card-title>
        <div class="scrollable-table">
          <v-data-table
            disable-pagination
            :headers="modifyHeaders"
            :items="modifyItems"
            hide-default-footer
            class="pa-4 vDataTableStyling"
          >
            <template v-slot:[`item.srNo`]="{ index }">
              {{ 1 + index }}
            </template>

            <template v-slot:[`item.oldValue`]="{ item }">
              {{
                (item.fieldName.includes("Date"))?
                formatDateTime(item.oldValue):item.oldValue
              }}
            </template>
            <template v-slot:[`item.newValue`]="{ item }">
              {{
                (item.fieldName.includes("Date"))?
                formatDateTime(item.newValue):item.newValue
              }}
            </template>
          </v-data-table>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment"
export default {
  data() {
    return {
      searchObj: {
        catastropheDescription: "",
      },
      catastropheHeaders: [
        { text: "Action", align: "center", value: "action", sortable: false },
        {
          text: "Master ID",
          align: "center",
          value: "uid",
          sortable: false,
        },
        {
          text: "LOB",
          align: "center",
          value: "lob",
          width: "100px",
          sortable: false,
        },
        {
          text: "Catastrophe ID",
          align: "center",
          value: "catastropheId",
          sortable: false,
        },
        {
          text: "Catastrophe Description",
          align: "center",
          value: "catastropheDescription",
          sortable: false,
        },
        {
          text: "Catastrophe Detail",
          align: "center",
          value: "catastropheDetail",
          sortable: false,
        },
        {
          text: "Catastrophe Type",
          align: "center",
          value: "catastropheType",
          sortable: false,
        },
        {
          text: "Start Date",
          align: "center",
          value: "startDate",
          sortable: false,
        },
        {
          text: "End Date",
          align: "center",
          value: "endDate",
          sortable: false,
        },
      ],
      catastropheData: [],

      pageNumber: 1,
      pageStartIndex: 0,
      pageRowCount: 10,
      totalListCount: 0,
      totalPages: null,
      menuItems: [
        {
          title: "VIEW",
          icon: "mdi-eye",
          color: "color:#000000",
          name: "view",
        },
        {
          title: "MODIFY",
          icon: "mdi-pencil",
          color: "color:#1EBBD9",
          name: "modify",
        },
        {
          title: "AUDIT TRAIL",
          icon: "mdi-chart-timeline-variant",
          color: "color:#e46a25",
          name: "audit",
        },
      ],
      audit_dialog:false, 
      seeModifyDialog:false,
      audit_Data: [],
      catastropheLobOptions:[],
      AuditPage: 1,
      AuditTotalListCount: 0,
      AuditPageCount: 0,
      AuditPageStartIndex: 0,
      AuditItemsPerPage: 10,
      modifyItems: [],
      audit_Header: [
        { text: "Sr. No.", value: "srno", sortable: false, align: "center" },
        {
          text: "Master ID",
          value: "masterId",
          sortable: false,
          align: "center",
        },
        {
          text: "Action Taken",
          value: "action",
          sortable: false,
          align: "center",
        },
        { text: "User ID", value: "userId", sortable: false, align: "center" },
        {
          text: "User Name",
          value: "userName",
          sortable: false,
          align: "center",
        },
        {
          text: "Time Stamp",
          value: "createdAt",
          sortable: false,
          align: "center",
        },
      ],
      modifyHeaders: [
        {
          text: "Sr. No.",
          value: "srNo",
          align: "center",
          sortable: false,
        },
        {
          text: "Field Name",
          value: "fieldName",
          align: "center",
          sortable: false,
        },
        {
          text: "Old Value",
          value: "oldValue",
          align: "center",
          sortable: false,
        },
        {
          text: "New Value",
          value: "newValue",
          align: "center",
          sortable: false,
        },
      ],
    }
  },

  async created() {
    this.$store.commit("SAVE_PAGE_HEADER", "Catastrophe Master Search")
    this.searchCatastrophe()
    const lob = await this.getLOB()
    this.catastropheLobOptions = lob
  },

  computed: {
    pageEndIndex() {
      return this.pageStartIndex + this.pageRowCount <= this.totalListCount
        ? this.pageStartIndex + this.pageRowCount
        : this.totalListCount
    },
    AuditPageEndIndex() {
      if (!this.audit_Data.length) {
        return 0
      } else {
        return this.AuditPageStartIndex + this.AuditItemsPerPage <=
          this.AuditTotalListCount
          ? this.AuditPageStartIndex + this.AuditItemsPerPage - 1
          : this.AuditTotalListCount
      }
    },
  },

  methods: {
    cancel(data) {
      this.showConfirm(
        "Confirmation",
        "Are you sure you want to leave?",
        "Yes",
        "No",
        (Yes) => {
          this.$router.push({ name: "Master" }) //params:{pageNo:this.pageNo}}
        }
      )
    },
    getAuditData(newPageNum = 1, id, bool) {
      let reqData = ""
      if (bool) {
        reqData =
          "perPage=10" +
          "&pageNo=" +
          newPageNum +
          "&masterType=catastrophe" + "&masterId=" + this.audit_Data[0].masterId
      } else {
        reqData =
          "perPage=10" +
          "&pageNo=" +
          newPageNum +
          "&masterType=catastrophe"  + "&masterId=" + id.uid
      }

      this.GETM(
        "getAudits",
        reqData,
        (res) => {
          if (res.data.data.data.length > 0) {
            console.log("AUDIT", res.data)
            this.audit_Data = res.data.data.data
            this.AuditTotalListCount = res.data.data.total
            this.AuditPageCount = res.data.data.total_pages
            this.AuditPageStartIndex =
              newPageNum == 1
                ? 1
                : (newPageNum - 1) * this.AuditItemsPerPage + 1
          } else {
            this.AuditPageStartIndex = 0
          }
        },
        () => {},
        true
      )
    },
    formatDateTime(dateTime) {
      return dateTime ? moment(dateTime).format("DD-MM-YYYY LT") : ""
    },
    formatDate(date) {
      return date == null ? "" : moment(date).format("DD/MM/YYYY")
    },
    goToCreatePage() {
      if(this.checkPermission()){
        return this.showAlert("You don't have permission to create or modify record.")
      }
      this.$router.push({
        name: "CreateCatastropheMaster",
      })
    },
    seeModify(item) {
      console.log(item.modification)
      this.seeModifyDialog = true
      this.modifyItems = item.modification.map((m, index) => {
        let object = { ...m, srNo: index + 1 }
        return object
      })
    },

    searchCatastrophe(bool = true) {
      // if(this.$refs.catastropheref.validate()) {
      let reqData = `page=${this.pageNumber}&pageLimit=10`
      if (this.searchObj?.catastropheDescription) {
        reqData += `&smartSearch=${this.searchObj.catastropheDescription}`
        // if (this.searchObj.catastropheDescription && bool) {
        //   reqData += `&smartSearch=${this.searchObj.catastropheDescription}&page=${this.pageNumber}&pageLimit=10`
        // } else {
        //   reqData += `&smartSearch=${this.searchObj.catastropheDescription}`
        //   this.pageNumber = 1
        // }
      } 
      if(this.searchObj?.lob){
        reqData += `&lob=${this.searchObj.lob}`
      }
      this.showProgress()
      this.GETM(
        "searchCatastrophe",
        reqData,
        (res) => {
          this.hideProgress()
          if (res.data.statusCode === 200) {
            this.catastropheData = res.data.data.data
            this.pageRowCount = 10
            this.totalListCount = res.data.data.total

            this.totalPages = Math.ceil(res.data.data.total / this.pageRowCount)
            this.pageStartIndex =
              this.pageNumber == 1
                ? 0
                : (this.pageNumber - 1) * this.pageRowCount
          } else {
            this.catastropheData = []
            this.pageRowCount = 0
            this.totalListCount = 0
            this.totalPages = 0
            this.pageNumber = 1
            this.pageStartIndex = 0
            this.showAlert(res.data.msg)
          }
        },
        (error) => {
          console.log(error)
          this.hideProgress()
        }
      )
      // } else {
      //     this.showAlert('Please enter mandatory field');
      // }
    },
    reset() {
      this.showConfirm(
        "Confirmation",
        `Are you sure you want to reset?`,
        "Yes",
        "No",
        () => {
          this.searchObj.catastropheDescription = ""
          this.searchObj.lob = ""
          this.catastropheData = []
          this.pageNumber = 1
          this.searchCatastrophe()
        },
        () => {}
      )
    },

    modify(item, index) {
      if (index === 0) {
        this.$router.push({
          name: "CreateCatastropheMaster",
          params: {
            catastropheDetails: item,
            viewOnly: true,
          },
        })
      }
      if (index === 1) {
        if(this.checkPermission()){
          return this.showAlert("You don't have permission to create or modify record.")
        }
        this.$router.push({
          name: "CreateCatastropheMaster",
          params: {
            catastropheDetails: item,
            viewOnly: false,
          },
        })
      }
      if (index === 2) {
        this.audit_Data=[]
        this.AuditPage=1;
        this.getAuditData(1, item)
        this.audit_dialog = true
      }
    },
  },
}
</script>

<style scoped>
.iconsize {
  font-size: 16px !important;
  font-weight: bold;
}
.btnstyle {
  height: 28px !important;
  text-transform: capitalize;
  font-size: 11px !important;
  letter-spacing: 0.5px;
}
.tablebtn {
  background: #ffffff 0% 0% no-repeat padding-box !important;
  box-shadow: inset 0px 2px 4px #00000052 !important;
  border: 0.5px solid #c1c8cc;
  border-radius: 18px !important;
  height: 30px !important;
  text-transform: capitalize !important;
  width: 93%;
  justify-content: space-between;
  color: black !important;
  font-weight: 400 !important;
}
.tableicons {
  font-size: 18px !important;
  color: #23b1a9 !important;
}
</style>
