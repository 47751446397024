<template>
    <v-app>
        <v-layout style="background-color:#F7F7F7" column class="" >
            <v-form v-model="valid" @submit.prevent="srchWrkItms()" ref="form">
            
            <v-card class="mx-3 mb-3 mt-3" color="#F7F7F7" style="background-color: #F7F7F7;" tile>
                
                <v-layout color="#F7F7F7" class="ma-2" >
                    
                    <v-flex xs3>
                        <v-layout column class=" mx-2 mt-3 cust_pd">
                            <p class="font-weight-bold cstm-clr-psap">
                                Work Item Id *
                            </p>
                            <v-text-field
                                solo
                                label="Enter..."
                                v-model="workItemId"
                                class="rounded-0">
                            </v-text-field>
                        </v-layout>
                    </v-flex>
                    <v-flex xs3>
                        <v-layout column class="cust_pd mx-2 mt-3">
                            <p class="font-weight-bold cstm-clr-psap">
                                Product Code *
                            </p>
                            <v-text-field
                                solo
                                label="Enter..."
                                v-model="productCode"
                                class="rounded-0">
                            </v-text-field>
                        </v-layout>
                    </v-flex>
                    <v-flex xs3>
                        <v-layout column class="cust_pd mx-2 mt-3">
                            <p class="font-weight-bold cstm-clr-psap">
                                Policy Number *
                            </p>
                            <v-text-field
                                solo
                                label="Enter..."
                                v-model="policyNumber"
                                class="rounded-0">
                            </v-text-field>
                        </v-layout>
                    </v-flex>
                </v-layout>
            
            
            </v-card>

            <div color="" class="mx-3 mb-3 pa-2 py-4" style="border:0.5px solid #C1C8CC; display:flex; justify-content:flex-end;background-color:#F7F7F7;">
                <v-btn tile dark  style="font-size:12px;background-color:#E46A25" small @click="srchWrkItms()" class="text-capitalize px-3 mr-1"><v-icon dark small >search </v-icon> <v-divider vertical class="mx-2"></v-divider>Search</v-btn> 
                <v-btn tile dark  style="font-size:12px;background-color:#E46A25" small @click="cnclSrchWrkItms()" class="text-capitalize px-3 mx-2"><v-icon dark small >cancel</v-icon > <v-divider vertical class="mx-2"></v-divider>Cancel</v-btn>

            </div>

            <v-card class="mx-3 mb-3" tile>
                    <v-data-table
                    :headers="headers"
                    :items="wrkItmDtaLst"
                    :page.sync="page"
                    :items-per-page="itemsPerPage"
                    hide-default-footer
                    dense

                    @page-count="pageCount = $event"
                >
            

                <template v-slot:item.itemId="{ item }">
                    <!-- to="/CreateEditProduct" -->
                    <div @click="wrkitmidClick(item.docId)" class="text-decoration-underline" style="color:#1976d2;cursor:pointer">{{ item.itemId | checkdata }}</div>
                </template>
                <template v-slot:item.code="{ item }">
                    <div >
                        <v-tooltip right >
                            <template v-slot:activator="{ on }">
                                <div v-on="on">
                                    {{ item.code| checkdata |truncate(16, '...')}}
                                </div>
                            </template>
                                <span>{{item.code}}</span>
                            
                        </v-tooltip>
                    </div>
                </template>
                <!-- <template v-slot:item.name="{ item }">
                    <div >{{ item.name | checkdata}}</div>
                </template> -->
               <template v-slot:item.description="{ item }">
                    <!-- <div >{{ item.description| checkdata|truncate(16, '...') }}</div> -->
                    <div >
                        <v-tooltip right >
                            <template v-slot:activator="{ on }">
                                <div v-on="on">
                                    {{ item.description| checkdata |truncate(16, '...')}}
                                </div>
                            </template>
                                <span>{{item.description}}</span>
                            
                        </v-tooltip>
                    </div>
                </template>
                 <template v-slot:item.policyNumber="{ item }">
                    <div >{{ item.policyNumber | checkdata}}</div>
                </template>
                <template v-slot:item.code="{ item }">
                    <div >{{ item.code | checkdata}}</div>
                </template>
                <template v-slot:item.assignedOn="{ item }">
                    <div >{{ item.assignedOn |formatDate }}</div>
                </template>
                <!-- <template v-slot:item.assignToName="{ item }">
                    <div >{{ item.assignToName| checkdata }}</div>
                </template> -->
                <template v-slot:item.status="{ item }">
                    <div >{{ item.status| checkdata }}</div>
                </template>
                
                    <template v-slot:item.ApproverDecision="{ item }" >
                        <v-layout class=" pa-0 ma-0 py-1" justify-space-between style="flex:1">
                            <v-btn tile dark style="font-size:12px;background-color:#23B1A9;flex:1" @click="approveProduct(item)" small class="text-capitalize mr-2 "><v-icon dark small >check_circle</v-icon > <v-divider vertical class="mx-2"></v-divider>Accept</v-btn>
                            <v-btn tile dark style="font-size:12px;background-color:#D1121B;flex:1" @click=" editRmrk(item,item.remarks)" small  class="text-capitalize"><v-icon dark small >cancel</v-icon > <v-divider vertical class="mx-2"></v-divider>Reject</v-btn>
                        </v-layout>
                    </template>
                    <template v-slot:item.remarks = {item,value} >
                    <!-- <template v-slot:item.Remarks = { item,value} > -->
                            <v-layout justify-space-between align-center>
                                <!-- {{item.Remarks|truncate(10, '...')}}  -->
                            <v-btn tile  style="background-color:#1E9690" @click="editRmrk(item,item.remarks)" icon small dark  >
                                <div class="mt-1">
                                    <img src="../../assets/edtRmrk.png" style="width: 22px;height: 22px"/>
                                </div>
                            </v-btn>   
                            </v-layout>
                    </template>
                </v-data-table>
                <v-divider></v-divider>
                
                <v-layout row justify-end align-center class="ma-2" v-if="wrkItmDtaLst.length!=0">
                    <v-text class="mr-1 font-weight-bold" style="color:#3C3D3D; font-size:13px;">
                        <!-- <span class="" style="color:#E46A25;">Showing {{fromvalue}}-{{toValu}} </span>out of {{totalCount}} records -->
                        <span class="" style="color:#E46A25;font-size:12px;">Showing {{fval}}-{{sval}} </span>out of {{totalLen}} records
                    </v-text>
                    <v-divider  class="mx-2" vertical ></v-divider>
                    <v-pagination
                        class="pdct-stUp-bnkAcc-pgn"
                                v-model="page"
                                color="#152F38"
                                flat
                                @input="nextPageData(page)"
                                
                                :total-visible="9"
                                :length="pagelength"
                    ></v-pagination>
                </v-layout>
            </v-card>

            <v-layout>
                    <v-dialog v-model="RmrkDilg" persistent max-width="480px" tile>
                        <v-card color="#F7F7F7" class="pb-2" tile>
                            <v-card tile>
                                <v-layout justify-space-between  class="" style="flex:1;background-ox-shadow: 0px 3px 5px #00000029;">
                                    
                                        <v-card-title class="pa-0 pl-3 text-capitalize font-weight-bold" style="color:#10242B;font-size:13px">Remarks</v-card-title>
                                       
                                    <div style="border-left:1px solid #C1C8CC;" @click="close_dwnld_dlg()">
                                        <v-icon style="cursor: pointer;" class="pa-2" >close</v-icon>
                                    </div>
                                </v-layout>
                            </v-card> 
                                <!-- <v-card class="ma-3 pa-2"> -->
                                      <v-textarea
                                        class="mx-3 mt-3"
                                        solo
                                        auto-grow
                                        v-model="rjctRmk"
                                        ></v-textarea>
                                <!-- </v-card> -->
                                <div color="" class="px-2" style="display:flex; justify-content:flex-end">
                                    <v-btn tile dark  style="font-size:12px;background-color:#E46A25" small @click="close_dwnld_dlg()"  class="text-capitalize px-3 "><v-icon dark small >cancel</v-icon > <v-divider vertical class="mx-2"></v-divider>Close</v-btn>
                                    <v-btn tile dark  style="font-size:12px;background-color:#D1121B"  @click="clear_Rmrks()"  small  class="text-capitalize px-3 mx-2"><v-icon dark small >delete </v-icon> <v-divider vertical class="mx-2"></v-divider>Clear</v-btn>
                                    <v-btn tile dark  style="font-size:12px;background-color:#23B1A9"  @click="rejectProduct()" small  class="text-capitalize px-3 mr-2"><v-icon dark small >check_circle</v-icon > <v-divider vertical class="mx-2"></v-divider>Save</v-btn>
                                </div>
                            
                        </v-card>
                    </v-dialog>
                </v-layout>
           
            
         </v-form>
        </v-layout>
    </v-app>
</template>

<script>
import axios from "axios";
import moment from 'moment';

export default {

    components:{
        
    },

    created() {

        this.$store.commit("SAVE_PAGE_HEADER", "Product Setup");
        this.token=this.$store.getters.successToken

        this.wrkitmdta(this.page)
        // this.getCreateProduct(this.page);

    },
    filters:{

      truncate: function (text, length, suffix) {
          if (text == "" || text == "undefined" || text == null || text == '' || text == 'Invalid Date') {
                return "-"
        
            }else if (text.length > length) {
                return text.substring(0, length) + suffix;
            } else {
                return text;
            }
        },
        checkdata(data){// eslint-disable-line no-mixed-spaces-and-tabs
            // console.log('Validity Data', data);
            if (data == "" || data == "undefined" || data == null || data == '' || data == 'Invalid Date') {
                return "-"
            } else {
                return data;
            }
        },
        formatDate(data){// eslint-disable-line no-mixed-spaces-and-tabs
            // console.log('Validity Data', data);

             if (data == "" || data == "undefined" || data == null || data == '' || data == 'Invalid Date') {
                return "-"
            } else {
                // var n = new Date(data);
                // return n.toLocaleString("en-IN")
                return moment(data,'YYYY-MM-DD').format('DD-MM-YYYY')
            }
        },
    

      truncate: function (text, length, suffix) {
            if (text.length > length) {
                return text.substring(0, length) + suffix;
            } else {
                return text;
            }
        }
    },

    computed: {


    },

    data() {
        return {
            tablerowdata:{},
            workItemId:"",
            productCode:"",
            policyNumber:"",
            swap_final_count:false,
            fval:0,
            sval:0,
            totalLen:0,
            
            headers:[
                
                {text: 'Work Item ID', value: 'itemId',sortable: false,divider:true, class:"hdr-txt-clr"},
                {text: 'Product Code', value: 'productCode',sortable: false,divider:true,class:"hdr-txt-clr"},
                {text: 'Product Type', value: 'type',sortable: false,divider:true,class:"hdr-txt-clr"},
                {text: 'Product Description', value: 'planDescription',sortable: false,divider:true,class:"hdr-txt-clr"},
                {text: 'Policy Number', value: 'policyNumber',sortable: false,divider:true,class:"hdr-txt-clr"},
                {text: 'Work Item Assigned Date', value: 'assignedOn',sortable: false,divider:true,class:"hdr-txt-clr"},
                {text: 'Status', value: 'status',sortable: false,divider:true,class:"hdr-txt-clr"},
                {text: 'Approver Decision', value: 'ApproverDecision',sortable: false,divider:true,class:"hdr-txt-clr",},
                 {text: 'Remarks', value: 'remarks',sortable: false,divider:true,class:"hdr-txt-clr"}

             ],

            // {text: 'Work Item ID', value: 'itemId',sortable: false,divider:true, class:"hdr-txt-clr"},
            //     {text: 'Product Code', value: 'code',sortable: false,divider:true,class:"hdr-txt-clr"},
            //     {text: 'Plan Name', value: 'name',sortable: false,divider:true,class:"hdr-txt-clr"},
            //     {text: 'Product Description', value: 'description',sortable: false,divider:true,class:"hdr-txt-clr"},
            //     {text: 'External Ref No.', value: 'externalRefNo',sortable: false,divider:true,class:"hdr-txt-clr"},
            //     {text: 'Work Item Assigned Date', value: 'assignedOn',sortable: false,divider:true,class:"hdr-txt-clr"},
            //     {text: 'Assigned to', value: 'assignToName',sortable: false,divider:true,class:"hdr-txt-clr"},
            //     {text: 'Status', value: 'status',sortable: false,divider:true,class:"hdr-txt-clr"},
            //      {text: 'Approver Decision', value: 'ApproverDecision',sortable: false,divider:true,class:"hdr-txt-clr",},
            //      {text: 'Remarks', value: 'Remarks',sortable: false,divider:true,class:"hdr-txt-clr"}

            // ],  

            wrkItmDtaLst:[],
            // GetData:[],
            // GetsetupData:[],
            GetsetupData1:[
            
                {
                   WorkItemID:"1223",
                    ProductCode:"GRPMEDC",
                    ProductType:"Group Health",
                    ProductDescription:"Group Medicare",
                    PolicyNumber:"10029876",
                    WorkItemAssignedDate:"19-12-2019",
                    Status:"Pending for Approval ",
                    Remarks:"RejectedRejected"
                },{
                   WorkItemID:"1223",
                    ProductCode:"GRPMEDC",
                    ProductType:"Group Health",
                    ProductDescription:"Group Medicare",
                    PolicyNumber:"10029876",
                    WorkItemAssignedDate:"19-12-2019",
                    Status:"Pending for Approval ",
                    Remarks:"Rejected"
                },{
                   WorkItemID:"1223",
                    ProductCode:"GRPMEDC",
                    ProductType:"Group Health",
                    ProductDescription:"Group Medicare",
                    PolicyNumber:"10029876",
                    WorkItemAssignedDate:"19-12-2019",
                    Status:"Pending for Approval ",
                    Remarks:"Rejected"
                }
            ],

            token:null,
            valid:false,
            srchApicall:false,
            selected:[],
            
            pageNumber: 1,
            pagelength: 0,
            typesearch:false,
            
            page:1,
            itemsPerPage:10,
            size: 10,
            RmrkDilg:false,
            rjctRmk:"",

            
        };



    },

    methods: {

        wrkitmdta(pageNum){ 
            
            console.log(" page no ---->",pageNum)
              let self=this
              self.wrkItmDtaLst=[]
              axios({
                    method: "get",
                    url:
                    this.API_Service_TASK_MGMNT()+"work-items/search?perPage=10&pageNo="+pageNum+"&itemId="+this.workItemId+"&productCode="+this.productCode+"&policyNumber="+this.policyNumber+"&product=product"+"&approvalStatus=no",
                    
                    headers: {
                        "Content-Type": "application/json",
                        "x-access-token":self.token,
                    }
                })
                .then(function (response) {
                    console.log("Create reporting>>>>>>", response);
                    if(response.data.statusCode===200){
                        self.wrkItmDtaLst= response.data.data.data
                         console.log("self.wrkItmDtaLst>>>>>>", self.wrkItmDtaLst);
                        //  self.showToast(response.data.msg,self.TOST().SUCCESS)
                    }else{
                        self.showToast("Something Went Wrong",self.TOST().ERROR);
                    }
                    // self.resetfrm();

                    // for pagination
                self.pagelength=response.data.data.total_pages
                    console.log(self.page,' <-- page search | search next btn, pg length is ----->',self.pagelength)

                     self.totalLen=response.data.data.total

                      let l = self.wrkItmDtaLst.length
                
                    console.log( 10*self.page,'search skip simulation ');

                      var less_enough = self.totalLen
                        var checkless_init = false
                        if(less_enough < 10){
                            checkless_init = false
                        }else{
                            checkless_init = true
                        }

                        // if less than 15 we have second value same as total value as no pagination will occur
                        if(checkless_init){
                            
                                // checkinit is true means the final count is more than 15 (- 1)
                                var traverse =  10*self.page
                                self.fval = traverse-9
                                if(self.swap_final_count){
                                    self.sval =  self.totalLen
                                }else{
                                    self.sval = traverse
                                }

                        }else if(self.totalLen == 0){
                            
                          self.sval = self.fval = 0
                        
                       }else{
                             self.fval = 1
                             self.sval = self.totalLen
                        }

                    console.log('total search----->',self.totalLen)

                    
                })
                .catch(function (error) {
                    console.log("Create reporting Error>>>>>>", error);
                    self.showToast(error.response.data.msg,self.TOST().ERROR);
                });

        },

        srchWrkItms(){
            // if(!this.$refs.form.validate()){
            //     this.$refs.form.validate()
            //     console.log(this.$refs.form.validate())
            //     // alert("Failed")
            //     this.showToast("Please Fill Required Field",this.TOST().WARNING);
            //     return
            // }
             if((this.productCode===''||this.productCode===undefined||this.productCode===null) && (this.policyNumber===''||this.policyNumber===undefined||this.policyNumber===null) && (this.workItemId===''||this.workItemId===undefined||this.workItemId===null)){
                 this.showToast('Please enter search criteria.', this.TOST().WARNING);
                 return
             }
            console.log("product code",this.productCode)
            this.page=1
            
            let apiURL=''
            // if((this.productCode===''||this.productCode===undefined||this.productCode===null) && (this.policyNumber===''||this.policyNumber===undefined||this.policyNumber===null) && (this.workItemId===''||this.workItemId===undefined||this.workItemId===null)){
            //     apiURL=this.API_Service_TASK_MGMNT()+"work-items/search?perPage=10&pageNo=1&product=product"
            // }else if((this.productCode===''||this.productCode===undefined||this.productCode===null) && (this.policyNumber===''||this.policyNumber===undefined||this.policyNumber===null) && (this.workItemId!==''||this.workItemId!==undefined||this.workItemId!==null)){
            //     apiURL=this.API_Service_TASK_MGMNT()+"work-items/search?perPage=10&pageNo=1&itemId="+this.workItemId+"&product=product"
            // }else if((this.productCode!==''||this.productCode!==undefined||this.productCode!==null) && (this.policyNumber===''||this.policyNumber===undefined||this.policyNumber===null) && (this.workItemId===''||this.workItemId===undefined||this.workItemId===null)){
            //     apiURL=this.API_Service_TASK_MGMNT()+"work-items/search?perPage=10&pageNo=1productCode="+this.productCode+"&product=product"
            // }else if((this.productCode===''||this.productCode===undefined||this.productCode===null) && (this.policyNumber!==''||this.policyNumber!==undefined||this.policyNumber!==null) && (this.workItemId===''||this.workItemId===undefined||this.workItemId===null)){
            //     apiURL=this.API_Service_TASK_MGMNT()+"work-items/search?perPage=10&pageNo=1&policyNumber="+this.policyNumber+"&product=product"
            // }else{
                apiURL=this.API_Service_TASK_MGMNT()+"work-items/search?perPage=10&pageNo=1&itemId="+this.workItemId+"&productCode="+this.productCode+"&policyNumber="+this.policyNumber+"&product=product"+"&approvalStatus=no"
            // }
            // http://claimsdrive-health.salesdrive.app/task-management/task-management-service/api/work-items/search?perPage=10&pageNo=1&itemId=WI100456&productCode=testnwrtl&policyNumber=1000041&product=product

            let self=this
              self.wrkItmDtaLst=[]
              axios({
                    method: "get",
                    url:apiURL,
                    headers: {
                        "Content-Type": "application/json",
                        "x-access-token":self.token,
                    }
                })
                .then(function (response) {
                    console.log("Create search reporting>>>>>>", response.data.data.data);
                    if(response.data.statusCode===200){
                        self.wrkItmDtaLst= response.data.data.data

                        console.log("self.wrkItmDtaLst for search---->>>>>>", self.wrkItmDtaLst);
                        self.showToast(response.data.msg,self.TOST().SUCCESS)

                 // for pagination
                self.pagelength=response.data.data.total_pages
                    console.log(self.page,' <-- page search | search next btn, pg length is ----->',self.pagelength)

                     self.totalLen=response.data.data.total

                      let l = self.wrkItmDtaLst.length
                
                    console.log( 10*self.page,'search skip simulation ');

                      var less_enough = self.totalLen
                        var checkless_init = false
                        if(less_enough < 10){
                            checkless_init = false
                        }else{
                            checkless_init = true
                        }

                        // if less than 15 we have second value same as total value as no pagination will occur
                        if(checkless_init){
                            
                                // checkinit is true means the final count is more than 15 (- 1)
                                var traverse =  10*self.page
                                self.fval = traverse-9
                                if(self.swap_final_count){
                                    self.sval =  self.totalLen
                                }else{
                                    self.sval = traverse
                                }

                        }else if(self.totalLen == 0){
                            
                          self.sval = self.fval = 0
                        
                       }else{
                             self.fval = 1
                             self.sval = self.totalLen
                        }

                    console.log('total search----->',self.totalLen)

                    }else{
                        self.showToast("Result not Found.",self.TOST().ERROR);
                    }
                    // self.resetfrm();
                    self.pagelength=response.data.data.total_pages
                    console.log(self.page,' <-- page search | search next btn, pg length is ----->',self.pagelength)

                     self.totalLen=response.data.data.total

                      let l = self.wrkItmDtaLst.length
                
                    console.log( 10*self.page,'search skip simulation ');

                      var less_enough = self.totalLen
                        var checkless_init = false
                        if(less_enough < 10){
                            checkless_init = false
                        }else{
                            checkless_init = true
                        }

                        // if less than 15 we have second value same as total value as no pagination will occur
                        if(checkless_init){
                            
                                // checkinit is true means the final count is more than 15 (- 1)
                                var traverse =  10*self.page
                                self.fval = traverse-9
                                if(self.swap_final_count){
                                    self.sval =  self.totalLen
                                }else{
                                    self.sval = traverse
                                }

                        }else if(self.totalLen == 0){
                            
                          self.sval = self.fval = 0
                        
                       }else{
                             self.fval = 1
                             self.sval = self.totalLen
                        }

                    console.log('total search----->',self.totalLen)
                    
                })
                .catch(function (error) {
                    console.log("Create reporting Error>>>>>>", error);
                    self.showToast(error.response.data.msg,self.TOST().ERROR);
                });

        },

        cnclSrchWrkItms(){
            // if((this.productCode===''||this.productCode===undefined||this.productCode===null) && (this.policyNumber===''||this.policyNumber===undefined||this.policyNumber===null) && (this.workItemId===''||this.workItemId===undefined||this.workItemId===null)){
            //     return
            // }else{
                this.page=1
                
                this.productCode=''
                this.workItemId=''
                this.policyNumber=''
                
                this.$refs.form.resetValidation()
                this.wrkitmdta(this.page)

            // }
        },

        wrkitmidClick(id){

            console.log(id," doc id")
            
           
            if(id===""|| id=== null|| id===undefined){
                 console.log(id," doc id")
                return
            }

            this.$store.commit('SAVE_PRODUCT_NAME', id)
            let btnName={
                            text:'View Work Item',
                            visible:false
                        }
            this.$store.commit('SAVE_PRODUCT_BTN_NAME', btnName)
            this.$router.push('/product/createEditProduct')
            

        },


        approveProduct(item){
            console.log('approve data',item)
            // return
            // if (this.$refs.form.validate()){

                let self=this
                // self.showLoader('Loading', true);
                let demodata={
                    _id: item._id,
                    status: item.status,

                }
                console.log("from demodata--------->",demodata);
                axios({
                    method: "post",
                    url:
                        this.API_Service_admin()+"product/approve-product-setup",
                    headers: {
                        "Content-Type": "application/json",
                        "x-access-token":self.token,
                    },
                    data:demodata,
                })
                .then(function (response) {
                    console.log(" approve product>>>>>>", response);
                    // self.resetfrm();
                    self.showToast(response.data.msg,self.TOST().SUCCESS)
                    console.log(" approve product success msg--->>>>>>", response.data.msg);
                    self.page=1
                    self.wrkitmdta(self.page)
                    console.log(" approve product --->>>>>>", response.data.msg);


                })
                .catch(function (error) {
                    console.log("approve product Error>>>>>>", error);
                    self.showToast(error.response.data.msg,self.TOST().ERROR);
                });
            // }else{
            //     this.$refs.form.validate()
            //     this.showToast("Please Fill Required Fields",this.TOST().WARNING);
            // }
        },
        clear_Rmrks(){
            this.rjctRmk=''
        },

        rejectProduct(){
                         console.log('reject Product data rmk',this.tablerowdata)

            if( this.rjctRmk===''|| this.rjctRmk===null|| this.rjctRmk===undefined){
                 this.showToast('Please enter remarks to Reject a Product', this.TOST().WARNING);
                 return
            }
            // if (this.$refs.form.validate()){

                let self=this
                // self.showLoader('Loading', true);
                let demodata={
                    _id: self.tablerowdata._id,
                    status: self.tablerowdata.status,
                    // remarks: item.rmk,
                     remarks: self.rjctRmk,
                    

                }
                console.log("from demodata--------->",demodata);
                
                axios({
                    method: "post",
                    url:
                        this.API_Service_admin()+"product/reject-product-setup",
                    headers: {
                        "Content-Type": "application/json",
                        "x-access-token":self.token,
                    },
                    data:demodata,
                })
                // self.RmrkDilg=false

                .then(function (response) {
                    console.log(" reject product>>>>>>", response);
                    // self.resetfrm();
                    self.showToast(response.data.msg,self.TOST().SUCCESS)
                    console.log(" reject product success msg--->>>>>>", response.data.msg);
                    self.page=1
                    self.wrkitmdta(self.page)
                     self.RmrkDilg=false
                    

                })
                .catch(function (error) {
                    console.log("reject product Error>>>>>>", error);
                    self.showToast(error.response.data.msg,self.TOST().ERROR);

                    
                });
            // }else{
            //     this.$refs.form.validate()
            //     this.showToast("Please Fill Required Fields",this.TOST().WARNING);
            // }
        },

        nextPageData(indata){

            console.log("isSearchIndiv => ", this.typesearch);

            if(this.pagelength == indata){
                this.swap_final_count = true
            }else{
                this.swap_final_count = false
            }

            
            console.log('indiv page no value',this.page)
            
            // this.getProviderALLIndividual(this.indiviSortBy,this.page)

            // this.SearchProviderIndividual(this.page)

            if(this.typesearch==false){
                this.wrkitmdta(this.page)
                  console.log("from if ",this.typesearch)
            }else{
                console.log("from else ",this.typesearch)
                 this.srchWrkItms()
            }

        },

        
        editRmrk(item,rmkTxt){
            console.log('remark----->',rmkTxt)
            console.log('remark item',item)
            this.tablerowdata=item
            this.RmrkDilg=true
            this.rjctRmk=rmkTxt
        },

         close_dwnld_dlg(){
            this.RmrkDilg=false

        },
        


    },

    watch: {
    
    },
    
    
}

</script>
<style >
.cust_pd .v-text-field .v-input__slot,
.v-text-field--outline .v-input__slot {
    min-height: 42px !important;
    display: flex !important;
    align-items: center !important;
    /* border:1px red solid; */
}

.cust-rmvpd-pd .v-text-field.v-text-field--enclosed>.v-input__control>.v-input__slot {
    padding: 0px !important;;
}

.cstm-clr-psap{
    color:#646666 !important;
    margin-bottom: 5px !important;
    font-size: 13px !important;
}

.cust_pd .v-input .v-label {
    font-size: 13px !important;
}
.cust_pd .v-input {
    font-size: 13px !important;
}
.cust_pd .v-input input {
    font-size: 13px !important;
}

.title-clr-pr{
    color:#10242B;
    margin-bottom: 5px;
}

.lbl-bgclr{
    color:#152F38 !important;

}
.hdr-txt-clr{
    color:#10242B !important;
    padding:0px!important;
    padding: 9px !important;
    padding-left: 14px !important;
}

/* .pdct-stUp-tbl table.v-table thead th{
    border-right:1px solid  #C1C8CC !important;
    background-color:#F3FBFB !important;
    color:#10242B !important;
    font-size:13px !important;
    font-family:Roboto !important;
    font-weight:700 !important;
    padding: 0px!important;
    padding-left: 9px !important;
} */
/* .pdct-stUp-tbl table.v-table thead th:last-child{
    border-right: unset !important;
    background-color:#F7F7F7 !important;
} */
/* .pdct-stUp-tbl table.v-table tbody td,
.pdct-stUp-tbl table.v-table thead tr{
    height: 40px !important;
    padding-left: 12px !important;
}
.pdct-stUp-tbl table.v-table thead tr:last-child{
    height: unset !important;
    
} */

.pdct-stUp-tbl-brdr{
    border:1px solid  #C1C8CC;
}

.pdct-stUp-td-bdrgt {
    border-right:1px solid #C1C8CC;
 }

 .pdct-stUp-bnkAcc-pgn .v-pagination__navigation{
        box-shadow:unset !important;
        border-radius: unset !important;
        background-color:#FFFFFF !important;
        border:1px solid #DAE1E6 !important;
        height: 30px !important;
        width: 30px !important;
    }
    .pdct-stUp-bnkAcc-pgn .v-pagination__navigation--disabled{
        background-color:#DAE1E6 !important;
    }
    .pdct-stUp-bnkAcc-pgn .v-pagination__item{
        box-shadow:unset !important;
        min-width:1.8rem !important;
        height:1.8rem !important;
        border-radius: unset !important;
        border:1px solid #DAE1E6 !important
    }

    .pdct-stUp-tbl .v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
    border: 1px solid  #C1C8CC !important;
}

.pdct-stUp-tbl .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
    border: 1px solid #C1C8CC !important;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
    font-size: 0.84rem !important;
}

 .v-dialog{
    overflow-y: unset !important;
}


</style>