<template>
  <div>
    <ClaimDetailsVue :claimData="claimDetails"></ClaimDetailsVue>
    <v-form
      class="mx-5"
      ref="recoveryform"
      lazy-validation
      @submit.prevent="submitRecovery"
    >
      <!-- <v-layout class="container__toggle" column>
        <v-flex xs12 class="px-4 py-1">
          <v-layout justify-space-between align-center>
            <div class="nav__container__1">
              <h4
                :style="{ color: toggleDetails ? '#23a1b9' : 'black' }"
                class="text__claim__number"
              >
                Claim Number - {{ claimDetails.claimNumber || '' }}
              </h4>
              <h4 class="text__claim__type red--text">
                New Claim
              </h4>
            </div>
            <div class="nav__container__2">
              <v-btn
                tile
                text
                :ripple="false"
                color="transparent"
                @click="toggleDetails = !toggleDetails"
                class="btn__toggler"
                >{{ toggleDetails ? "HIDE" : "SHOW" }} Details</v-btn
              >
            </div>
          </v-layout>
        </v-flex>
        <v-slide-y-transition>
          <v-flex xs12 v-if="toggleDetails" class="bottom__container">
            <v-row>
              <v-col md="2">
                <div>
                  <div class="term">Policy Number</div>
                  <div class="value">{{ claimDetails.policyNumber || '' }}</div>
                </div>
              </v-col>
              <v-col md="2">
                <div>
                  <div class="term">Certificate Number</div>
                  <div class="value">
                    {{
                      claimDetails && claimDetails.certificateNo
                        ? claimDetails.certificateNo
                        : "------"
                    }}
                  </div>
                </div>
              </v-col>
              <v-col md="2">
                <div>
                  <div class="term">LOB</div>
                  <div class="value">{{ claimDetails.lobType || '' }}</div>
                </div>
              </v-col>
            </v-row>
          </v-flex>
        </v-slide-y-transition>
      </v-layout> -->
      <v-expansion-panels v-model="panels" multiple>
        <!-- Basic Details -->
        <v-expansion-panel>
          <v-expansion-panel-header color="#1EBBD9">
            <span class="page-title-small white--text">Claim details</span>
            <template v-slot:actions>
              <v-icon class="white--text">
                {{
                  panels.indexOf(0) !== -1 ? "mdi-minus-box" : "mdi-plus-box"
                }}
              </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-layout wrap class="pt-4 form-group-container">
              <v-flex class="form-group">
                <label class="form-label">
                  LOB
                  <span class="red--text"><strong> * </strong></span>
                </label>
                <v-text-field
                  :rules="[(v) => requiredField(v)]"
                  placeholder="Enter"
                  disabled
                  solo
                  v-model="paymentRecoveryObj.lobType"
                  dense
                  class="form-control"
                ></v-text-field>
              </v-flex>
              <v-flex class="form-group">
                <label class="form-label">
                  Claim Number
                  <span class="red--text"><strong> * </strong></span>
                </label>
                <v-text-field
                  :rules="[
                    (v) => requiredField(v),
                    (v) => matchRegex(v, 'alphanum'),
                  ]"
                  placeholder="Enter"
                  disabled
                  solo
                  v-model="paymentRecoveryObj.claimNumber"
                  dense
                  class="form-control"
                ></v-text-field>
              </v-flex>
              <v-flex class="form-group">
                <label class="form-label">
                  Feature Number
                  <span class="red--text"><strong> * </strong></span>
                </label>
                <v-text-field
                  placeholder="Enter"
                  disabled
                  :rules="[(v) => requiredField(v)]"
                  solo
                  dense
                  v-model="paymentRecoveryObj.featureNumber"
                  class="form-control"
                ></v-text-field>
              </v-flex>
              <v-flex class="form-group">
                <label class="form-label"> Policy Number </label>
                <v-text-field
                  placeholder="Enter"
                  :rules="[(v) => matchRegex(v, 'alphanum')]"
                  solo
                  dense
                  v-model="paymentRecoveryObj.policyNo"
                  class="form-control"
                  disabled
                ></v-text-field>
              </v-flex>
              <v-flex class="form-group">
                <label class="form-label"> Insured Name </label>
                <v-text-field
                  placeholder="Enter"
                  v-model="paymentRecoveryObj.insuredName"
                  solo
                  dense
                  class="form-control"
                  disabled
                ></v-text-field>
              </v-flex>
              <v-flex class="form-group">
                <label class="form-label"> Product Name </label>
                <v-text-field
                  :rules="[(v) => matchRegex(v, 'alphanum')]"
                  placeholder="Enter"
                  solo
                  dense
                  class="form-control"
                  v-model="paymentRecoveryObj.product"
                  disabled
                ></v-text-field>
              </v-flex>
              <v-flex class="form-group">
                <label class="form-label"> Coverage </label>
                <v-text-field
                  placeholder="Enter"
                  solo
                  dense
                  v-model="paymentRecoveryObj.coverage"
                  class="form-control"
                  disabled
                ></v-text-field>
              </v-flex>
              <v-flex class="form-group">
                <label class="form-label"> Loss Date </label>
                <v-menu
                  v-model="menu1"
                  offset-y
                  nudge-top="20"
                  :close-on-content-click="false"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="computedLossDate"
                      placeholder="DD/MM/YYYY"
                      dense
                      solo
                      readonly
                      v-on="on"
                      @focus="menu1 = true"
                      @blur="menu1 = true"
                      v-bind="attrs"
                      class="form-control"
                      append-icon="mdi-menu-down"
                      prepend-inner-icon="mdi-calendar"
                      disabled
                    >
                    </v-text-field>
                  </template>
                  <v-date-picker
                    no-title
                    v-model="paymentRecoveryObj.lossDate"
                    @input="menu1 = false"
                  >
                  </v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex class="form-group">
                <label class="form-label">
                  Type Of Recovery
                  <span class="red--text"><strong> * </strong></span>
                </label>
                <v-select
                  v-model="paymentRecoveryObj.typeofRecovery"
                  :items="getTypeOfRecoveryItems"
                  label="Select"
                  solo
                  dense
                  :rules="[(v) => requiredField(v)]"
                  :disabled="viewMode"
                  @change="checkType"
                  class="form-control"
                ></v-select>
              </v-flex>
              <v-flex class="form-group">
                <label class="form-label">
                  Recovery Head
                  <span class="red--text"><strong> * </strong></span>
                </label>
                <v-select
                  v-model="paymentRecoveryObj.recoveryHead"
                  :items="recoveryHead_Array"
                  placeholder="Enter"
                  solo
                  dense
                  :disabled="viewMode"
                  class="form-control"
                  :rules="[(v) => requiredField(v)]"
                ></v-select>
              </v-flex>
              <v-flex class="form-group">
                <label class="form-label"> Recovery Agent </label>
                <v-text-field
                  :rules="[(v) => matchRegex(v, 'alphanum')]"
                  solo
                  dense
                  v-model="paymentRecoveryObj.recoveryAgent"
                  class="form-control"
                  @click="openAgent"
                  :disabled="viewMode"
                  readonly
                >
                  <template v-slot:append class="">
                    <v-icon
                      class="mr-2 fontStyle"
                      color="#23B1A9"
                      @click="openAgent"
                      :disabled="viewMode"
                      >mdi-magnify</v-icon
                    >
                  </template>
                </v-text-field>

                <v-dialog v-model="recoveryAgent_dialog" width="700">
                  <v-card>
                    <v-card-title
                      class="white--text"
                      style="background-color: #23b1a9"
                    >
                      <h3 class="ml-5">
                        Service Provider - Recovery Agent List
                      </h3>
                      <v-spacer></v-spacer>
                      <v-icon
                        @click="recoveryAgent_dialog = !recoveryAgent_dialog"
                        class="white--text"
                        >mdi-window-close</v-icon
                      >
                    </v-card-title>
                    <v-card-text class="grey lighten-2">
                      <v-form ref="searchSPForm" @submit.prevent="searchSPM(1)">
                        <v-layout wrap class="pt-4 form-group-container">
                          <v-flex class="form-group">
                            <label class="form-label"> Search By PAN </label>
                            <v-text-field
                              placeholder="Enter"
                              solo
                              dense
                              v-model="panNumber"
                              @keyup="getRecoveryAgentList"
                              :disabled="viewMode"
                              :background-color="viewMode ? '#f5f5f5' : ''"
                              :class="
                                viewMode ? 'textDisable font-weight-bold' : ''
                              "
                              clearable
                              @click:clear="
                                ;(panNumber = ''), getRecoveryAgentList()
                              "
                              class="form-control"
                            ></v-text-field>
                          </v-flex>
                          <v-flex class="form-group">
                            <label class="form-label"> Search By Name </label>
                            <v-text-field
                              placeholder="Enter"
                              solo
                              dense
                              v-model="spName"
                              @keyup="getRecoveryAgentList"
                              :disabled="viewMode"
                              :background-color="viewMode ? '#f5f5f5' : ''"
                              :class="
                                viewMode ? 'textDisable font-weight-bold' : ''
                              "
                              clearable
                              @click:clear="
                                ;(spName = ''), getRecoveryAgentList()
                              "
                              class="form-control"
                            ></v-text-field>
                          </v-flex>
                          <v-flex class="form-group">
                            <label class="form-label"> Search By City </label>
                            <v-text-field
                              placeholder="Enter"
                              solo
                              dense
                              v-model="spCity"
                              @keyup="getRecoveryAgentList"
                              :disabled="viewMode"
                              :background-color="viewMode ? '#f5f5f5' : ''"
                              :class="
                                viewMode ? 'textDisable font-weight-bold' : ''
                              "
                              clearable
                              @click:clear="
                                ;(spCity = ''), getRecoveryAgentList()
                              "
                              class="form-control"
                            ></v-text-field>
                          </v-flex>
                          <v-flex class="form-group">
                            <label class="form-label">
                              Search By Pincode
                            </label>
                            <v-text-field
                              placeholder="Enter"
                              solo
                              dense
                              v-model="spPincode"
                              @keyup="getRecoveryAgentList"
                              :disabled="viewMode"
                              :background-color="viewMode ? '#f5f5f5' : ''"
                              :class="
                                viewMode ? 'textDisable font-weight-bold' : ''
                              "
                              clearable
                              @click:clear="
                                ;(spPincode = ''), getRecoveryAgentList()
                              "
                              class="form-control"
                            ></v-text-field>
                          </v-flex>
                        </v-layout>
                        <v-data-table
                          :headers="recovery_AgentList"
                          :items="agent_Data"
                          hide-default-footer
                          @click:row="recoveryAgentClick"
                          class="vDataTableStyling mx-3 mb-2"
                          fixed-header
                          height="250"
                          disable-pagination
                          no-data-text="No Records for Recovery Agent"
                        >
                        </v-data-table>
                      </v-form>
                    </v-card-text>
                  </v-card>
                </v-dialog>
              </v-flex>
              <v-flex class="form-group" v-if="paymentRecoveryObj.typeofRecovery == 'Salvage'">
                <label class="form-label">
                  TAGIC GST Number
                  <span
                    class="red--text"
                    
                    ><strong> * </strong></span
                  >
                </label>
                <v-autocomplete
                  label="Select"
                  solo
                  dense
                  :items="GSTDATA"
                  item-text="gstRegistrationNumber"
                  :disabled="viewMode"
                  :rules="
                    paymentRecoveryObj.typeofRecovery == 'Salvage'
                      ? [(v) => requiredField(v)]
                      : []
                  "
                  class="form-control"
                  v-model="paymentRecoveryObj.TAGICGSTNo"
                  @change="
                    () => (
                      calculateGSTTaxes(paymentRecoveryObj.TAGICGSTNo),
                      getGSTState(paymentRecoveryObj.TAGICGSTNo)
                    )
                  "
                >
                </v-autocomplete>
              </v-flex>
              <v-flex class="form-group" v-if="paymentRecoveryObj.typeofRecovery == 'Salvage'">
                <label class="form-label">
                  TAGIC GST State
                  <span
                    class="red--text"
                    ><strong> * </strong></span
                  >
                </label>
                <v-text-field
                  placeholder="Enter"
                  solo
                  dense
                  v-model="paymentRecoveryObj.TAGICGSTState"
                  background-color="#f5f5f5"
                  class="form-control"
                  disabled
                  clearable
                  :rules="
                    paymentRecoveryObj.typeofRecovery == 'Salvage'
                      ? [(v) => requiredField(v)]
                      : []
                  "
                ></v-text-field>
              </v-flex>
              <v-flex class="form-group">
                <label class="form-label">
                  Salvage Buyer GST Number
                  <span
                    class="red--text"
                    v-if="paymentRecoveryObj.typeofRecovery == 'Salvage'"
                    ><strong> * </strong></span
                  >
                </label>
                <v-text-field
                  placeholder="Enter"
                  solo
                  dense
                  :disabled="viewMode"
                  v-model="paymentRecoveryObj.salvageBuyerGSTNumber"
                  class="form-control"
                  @change="
                    () => (
                      calculateGSTTaxes(
                        paymentRecoveryObj.salvageBuyerGSTNumber
                      ),
                      checkGstNo()
                    )
                  "
                  maxlength="15"
                  @keyup="
                    () =>
                      (paymentRecoveryObj.salvageBuyerGSTNumber =
                        paymentRecoveryObj.salvageBuyerGSTNumber.toUpperCase())
                  "
                  :rules="
                    paymentRecoveryObj.typeofRecovery == 'Salvage'
                      ? [
                          (v) => validateGSTNumber(v),
                          (v) => requiredField(v),
                          checkGstRule(),
                        ]
                      : [(v) => validateGSTNumber(v), checkGstRule()]
                  "
                  clearable
                ></v-text-field>
              </v-flex>
              <v-flex class="form-group">
                <label class="form-label">
                  Salvage Buyer State
                  <span class="red--text"><strong> * </strong></span>
                </label>
                <v-select
                  label="Select"
                  solo
                  dense
                  :items="GSTDATA"
                  item-text="state"
                  :disabled="viewMode"
                  v-model="paymentRecoveryObj.salvageBuyerState"
                  class="form-control"
                  clearable
                  :rules="[(v) => requiredField(v)]"
                ></v-select>
              </v-flex>
            </v-layout>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header color="#1EBBD9">
            <span class="page-title-small white--text">Commodity Grid</span>
            <template v-slot:actions>
              <v-icon class="white--text">
                {{
                  panels.indexOf(0) !== -1 ? "mdi-minus-box" : "mdi-plus-box"
                }}
              </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-layout wrap class="pt-4 form-group-container">
              <div class="" style="width: 1230px">
                <v-data-table
                  :items="paymentRecoveryObj.recoveryInformation"
                  :headers="headers"
                  hide-default-footer
                  class="padding-0 vDataTableStyling"
                  :items-per-page="50"
                >
                  <template v-slot:[`header.commodityName`]="{ header }">
                    {{ header.text }}
                    <span
                      v-if="paymentRecoveryObj.typeofRecovery == 'Salvage'"
                      :class="viewMode ? '' : 'mandatorycolor'"
                    >
                      *</span
                    >
                  </template>
                  <template v-slot:[`header.amount`]="{ header }">
                    {{ header.text }}
                    <span
                      v-if="paymentRecoveryObj.typeofRecovery == 'Salvage'"
                      :class="viewMode ? '' : 'mandatorycolor'"
                    >
                      *</span
                    >
                  </template>
                  <template v-slot:[`header.taxRate`]="{ header }">
                    {{ header.text }}
                    <span
                      v-if="paymentRecoveryObj.typeofRecovery == 'Salvage'"
                      :class="viewMode ? '' : 'mandatorycolor'"
                    >
                      *</span
                    >
                  </template>
                  <template v-slot:[`header.CGST`]="{ header }">
                    {{ header.text }}
                    <span
                      :class="viewMode ? '' : 'mandatorycolor'"
                      v-if="
                        paymentRecoveryObj.typeofRecovery === 'Salvage' &&
                        paymentRecoveryObj.TAGICGSTNo ==
                          paymentRecoveryObj.salvageBuyerGSTNumber
                      "
                    >
                      *</span
                    >
                  </template>

                  <template v-slot:[`header.SGST`]="{ header }">
                    {{ header.text }}
                    <span
                      :class="viewMode ? '' : 'mandatorycolor'"
                      v-if="
                        paymentRecoveryObj.typeofRecovery === 'Salvage' &&
                        paymentRecoveryObj.TAGICGSTNo ==
                          paymentRecoveryObj.salvageBuyerGSTNumber
                      "
                    >
                      *</span
                    >
                  </template>

                  <template v-slot:[`header.IGST`]="{ header }">
                    {{ header.text }}
                    <span
                      :class="viewMode ? '' : 'mandatorycolor'"
                      v-if="
                        paymentRecoveryObj.typeofRecovery === 'Salvage' &&
                        paymentRecoveryObj.TAGICGSTNo !==
                          paymentRecoveryObj.salvageBuyerGSTNumber
                      "
                    >
                      *</span
                    >
                  </template>
                  <template v-slot:[`item.commodityName`]="{ item }">
                    <v-text-field
                      v-model="item.commodityName"
                      class="form-control"
                      label=""
                      :disabled="viewMode"
                      :rules="[(v) => requiredField(v)]"
                      solo
                      dense
                    ></v-text-field>
                  </template>

                  <template v-slot:[`item.HSNSACCode`]="{ item }">
                    <v-text-field
                      v-model="item.HSNSACCode"
                      class="form-control"
                      label=""
                      :disabled="viewMode"
                      :rules="[(v) => matchRegex(v, 'nums')]"
                      v-on:keypress="isNumber"
                      solo
                      dense
                    ></v-text-field>
                  </template>
                  <template v-slot:[`item.amount`]="{ item }">
                    <v-text-field
                      v-model="item.amount"
                      class="form-control"
                      label=""
                      :disabled="viewMode"
                      :rules="[(v) => requiredField(v)]"
                      @keypress="(e) => onlyForCurrency(e, item.amount)"
                      solo
                      dense
                      @keyup="calculateRecoveryAmount(item)"
                    ></v-text-field>
                  </template>
                  <template v-slot:[`item.taxRate`]="{ item, index }">
                    <v-select
                      v-model="item.taxRate"
                      class="form-control"
                      label=""
                      :disabled="viewMode"
                      :rules="[(v) => requiredField(v)]"
                      item-text="taxRate"
                      @change="getGSTDetail(item, index)"
                      :items="taxrates"
                      solo
                      dense
                    ></v-select>
                    <!-- <v-text-field
                      v-model="item.taxRate"
                      placeholder="Enter"
                      solo
                      v-if="viewMode"
                      dense
                      disabled
                      class="form-control"
                    ></v-text-field> -->
                  </template>
                  <template v-slot:[`item.CGST`]="{ item }">
                    <v-text-field
                      v-model="item.CGST"
                      class="form-control"
                      label=""
                      disabled
                      solo
                      dense
                    ></v-text-field>
                  </template>
                  <template v-slot:[`item.SGST`]="{ item }">
                    <v-text-field
                      v-model="item.SGST"
                      class="form-control"
                      label=""
                      disabled
                      solo
                      dense
                    ></v-text-field>
                  </template>
                  <template v-slot:[`item.IGST`]="{ item }">
                    <v-text-field
                      v-model="item.IGST"
                      class="form-control"
                      label=""
                      disabled
                      solo
                      dense
                    ></v-text-field>
                  </template>
                  <template v-slot:[`item.delete`]="{ item }">
                    <v-icon
                      left
                      color="#d1121b"
                      :disabled="viewMode"
                      class="mt-n4"
                      @click="
                        delData(
                          paymentRecoveryObj.recoveryInformation.indexOf(item)
                        )
                      "
                      >mdi-delete-outline</v-icon
                    >
                  </template>
                </v-data-table>
              </div>
              <!-- Approve Dialog -->
              <v-dialog v-model="approve_dialog" width="500">
                <v-card>
                  <v-card-title
                    class="text-h5 white text-gray"
                    style="color: gray"
                  >
                    <p class="text-center" style="width: 100%">Confirmation</p>
                  </v-card-title>

                  <v-card-text
                    class="pt-5 text-h6 lighten-2 text-center mt-3"
                    style="background-color: #ebebeb; margin: auto; width: 90%"
                  >
                    Are you sure You want to Delete ?
                  </v-card-text>

                  <v-card-actions class="mt-3">
                    <v-spacer></v-spacer>
                    <v-btn
                      color="red"
                      @click="approve_dialog = false"
                      depressed
                      large
                      style="color: #fff"
                    >
                      NO
                    </v-btn>
                    <v-btn
                      style="color: #fff"
                      depressed
                      large
                      color="primary"
                      @click="
                        deleteConfirm(
                          paymentRecoveryObj.recoveryInformation.indexOf(item)
                        )
                      "
                    >
                      YES
                    </v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <!-- End Approve Dialog -->
            </v-layout>
            <v-layout class="addbtn" justify-center>
              <v-btn
                @click="addRow"
                :disabled="!isMandatory"
                text
                elevation="0"
                style="color: #23b1a9; background-color: #fff"
                >+ Add Row</v-btn
              >
            </v-layout>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header color="#1EBBD9">
            <span class="page-title-small white--text">Recovery Details</span>
            <template v-slot:actions>
              <v-icon class="white--text">
                {{
                  panels.indexOf(0) !== -1 ? "mdi-minus-box" : "mdi-plus-box"
                }}
              </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-layout wrap class="pt-4 form-group-container">
              <v-flex class="form-group">
                <label class="form-label">
                  Recovery Amount
                  <span class="red--text"><strong> * </strong></span>
                </label>
                <v-text-field
                  :disabled="disableOnsalvage || viewMode"
                  v-model="paymentRecoveryObj.recoveryAmount"
                  placeholder="Enter"
                  solo
                  dense
                  :rules="[(v) => requiredField(v)]"
                  @keypress="
                    (e) => onlyForCurrency(e, paymentRecoveryObj.recoveryAmount)
                  "
                  class="form-control"
                ></v-text-field>
              </v-flex>
              <v-flex class="form-group">
                <label class="form-label">
                  Net Amount
                  <span class="red--text"><strong> * </strong></span>
                </label>
                <v-text-field
                  v-model="paymentRecoveryObj.netAmount"
                  :rules="[(v) => requiredField(v)]"
                  @keypress="
                    (e) => onlyForCurrency(e, paymentRecoveryObj.netAmount)
                  "
                  placeholder="Enter"
                  solo
                  dense
                  class="form-control"
                  :disabled="disableOnsalvage || viewMode"
                ></v-text-field>
              </v-flex>
              <v-flex class="form-group">
                <label class="form-label">
                  Receipt Type
                  <span class="red--text"><strong> * </strong></span>
                </label>
                <v-select
                  v-model="paymentRecoveryObj.receiptType"
                  :items="receiptTypes"
                  :rules="[(v) => requiredField(v)]"
                  placeholder="Enter"
                  :disabled="viewMode"
                  solo
                  dense
                  class="form-control"
                ></v-select>
              </v-flex>
              <v-flex class="form-group">
                <label class="form-label"> Receipt Status </label>
                <v-text-field
                  v-model="paymentRecoveryObj.receiptStatus"
                  placeholder="Enter"
                  solo
                  dense
                  class="form-control"
                  disabled
                ></v-text-field>
              </v-flex>
              <v-flex class="form-group">
                <label class="form-label"> Receipt Number </label>
                <v-text-field
                  v-model="paymentRecoveryObj.receiptNumber"
                  :rules="[(v) => matchRegex(v, 'alphanum')]"
                  placeholder="Enter"
                  solo
                  dense
                  class="form-control"
                  disabled
                ></v-text-field>
              </v-flex>
              <v-flex class="form-group" v-if="paymentRecoveryObj._id">
                <label class="form-label"> GST Invoice </label>
                <v-btn
                  class="contactbtn form-control mb-6 w-100"
                  @click="convertHTMLToPDF()"
                >
                  <u style="margin-right: 57px">GST Invoice</u>
                  <div class="ml-8"></div>
                  <v-icon class="iconsize" color="#23B1A9"
                    >mdi-checkbox-marked-outline</v-icon
                  >
                </v-btn>
              </v-flex>
              <v-flex class="form-group">
                <label class="form-label">
                  Transaction Type
                  <span class="red--text"><strong> * </strong></span>
                </label>
                <v-select
                  v-model="paymentRecoveryObj.transactionType"
                  :items="transaction_types"
                  @change="getHouseDetails()"
                  :rules="[(v) => requiredField(v)]"
                  :disabled="viewMode"
                  placeholder="Enter"
                  solo
                  dense
                  class="form-control"
                ></v-select>
              </v-flex>
              <v-flex class="form-group">
                <label class="form-label">
                  Instrument/Reference Number
                  <span class="red--text"><strong> * </strong></span>
                </label>
                <v-text-field
                  :rules="[(v) => requiredField(v)]"
                  :disabled="viewMode"
                  v-model="paymentRecoveryObj.InstrumentReferenceNumber"
                  @keypress="
                    (e) =>
                      onlyForCurrency(
                        e,
                        paymentRecoveryObj.InstrumentReferenceNumber
                      )
                  "
                  maxLength="12"
                  placeholder="Enter"
                  solo
                  dense
                  class="form-control"
                ></v-text-field>
              </v-flex>
              <v-flex class="form-group">
                <label class="form-label"
                  >Instrument Date
                  <span class="red--text"><strong> * </strong></span>
                </label>
                <v-menu
                  v-model="menu2"
                  offset-y
                  nudge-top="20"
                  :close-on-content-click="false"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="computedInstrumentDate"
                      placeholder="DD/MM/YYYY"
                      dense
                      solo
                      :disabled="viewMode"
                      :rules="[(v) => requiredField(v)]"
                      readonly
                      v-on="on"
                      @focus="menu2 = true"
                      @blur="menu2 = true"
                      v-bind="attrs"
                      class="form-control"
                      append-icon="mdi-menu-down"
                      prepend-inner-icon="mdi-calendar"
                    >
                    </v-text-field>
                  </template>
                  <v-date-picker
                    no-title
                    v-model="paymentRecoveryObj.instrumentDate"
                    @input="menu2 = false"
                  >
                  </v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex class="form-group">
                <label class="form-label">
                  Instrument Amount
                  <span class="red--text"><strong> * </strong></span>
                </label>
                <v-text-field
                  v-model="paymentRecoveryObj.instrumentAmount"
                  :rules="[(v) => requiredField(v)]"
                  @keypress="
                    (e) =>
                      onlyForCurrency(e, paymentRecoveryObj.instrumentAmount)
                  "
                  :disabled="viewMode"
                  placeholder="Enter"
                  solo
                  dense
                  class="form-control"
                ></v-text-field>
              </v-flex>
              <v-flex class="form-group">
                <label class="form-label">
                  Payment Currency
                  <span class="red--text"><strong> * </strong></span>
                </label>
                <v-text-field
                  v-model="paymentRecoveryObj.paymentCurrency"
                  :rules="[(v) => requiredField(v)]"
                  placeholder="Enter"
                  solo
                  dense
                  :disabled="viewMode"
                  class="form-control"
                ></v-text-field>
              </v-flex>
              <v-flex class="form-group">
                <label class="form-label">
                  Payer Name
                  <span class="red--text"><strong> * </strong></span>
                </label>
                <v-text-field
                  v-model="paymentRecoveryObj.payerName"
                  solo
                  dense
                  :disabled="viewMode"
                  class="form-control"
                  @click="openSalvageBuyer"
                  readonly
                >
                  <template v-slot:append class="">
                    <v-icon
                      class="mr-2 fontStyle"
                      color="#23B1A9"
                      @click="openSalvageBuyer"
                      :disabled="viewMode"
                      >mdi-magnify</v-icon
                    >
                  </template>
                </v-text-field>
              </v-flex>
              <v-flex class="form-group">
                <label class="form-label">
                  Payee Bank Details
                  <span class="red--text"><strong> * </strong></span>
                </label>
                <v-autocomplete
                  :rules="[(v) => requiredField(v)]"
                  v-model="paymentRecoveryObj.payeeBankDetails"
                  :search-input.sync="searchIFSCCode"
                  item-text="ifscCode"
                  :maxLength="11"
                  :disabled="viewMode"
                  @change="
                    bindpayeeBankdetails(paymentRecoveryObj.payeeBankDetails)
                  "
                  :items="payeeDetails"
                  clearable
                  placeholder="Enter"
                  solo
                  dense
                  class="form-control"
                ></v-autocomplete>
              </v-flex>
              <v-flex class="form-group">
                <label class="form-label">
                  Payee Bank Branch
                  <span class="red--text"><strong> * </strong></span>
                </label>
                <v-text-field
                  v-model="paymentRecoveryObj.payeeBankBranch"
                  placeholder="Enter"
                  solo
                  dense
                  class="form-control"
                  disabled
                  :rules="[(v) => requiredField(v)]"
                ></v-text-field>
              </v-flex>
              <v-flex class="form-group">
                <label class="form-label">
                  Payee Bank Name
                  <span class="red--text"><strong> * </strong></span>
                </label>
                <v-text-field
                  v-model="paymentRecoveryObj.payeeBankName"
                  placeholder="Enter"
                  solo
                  dense
                  class="form-control"
                  disabled
                  :rules="[(v) => requiredField(v)]"
                ></v-text-field>
              </v-flex>
              <v-flex class="form-group">
                <label class="form-label">
                  House Bank Branch
                  <span class="red--text"><strong> * </strong></span>
                </label>
                <v-text-field
                  :rules="[
                    (v) => requiredField(v),
                    (v) => matchRegex(v, 'alphanum'),
                  ]"
                  v-model="paymentRecoveryObj.houseBankBranch"
                  placeholder="Enter"
                  solo
                  dense
                  class="form-control"
                  disabled
                ></v-text-field>
              </v-flex>
              <v-flex class="form-group">
                <label class="form-label">
                  House Bank Name
                  <span class="red--text"><strong> * </strong></span>
                </label>
                <v-text-field
                  :rules="[
                    (v) => requiredField(v),
                    (v) => matchRegex(v, 'alphanum'),
                  ]"
                  v-model="paymentRecoveryObj.houseBankName"
                  placeholder="Enter"
                  solo
                  dense
                  class="form-control"
                  disabled
                ></v-text-field>
              </v-flex>
              <v-flex class="form-group">
                <label class="form-label">
                  Remark <span class="red--text"><strong> * </strong></span>
                </label>
                <v-text-field
                  :rules="[
                    (v) => requiredField(v),
                    (v) => matchRegex(v, 'alphanum'),
                  ]"
                  placeholder="Enter"
                  solo
                  dense
                  :disabled="viewMode"
                  class="form-control"
                  v-model="paymentRecoveryObj.remarks"
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-dialog v-model="salvageBuyer_dialog" width="700">
              <v-card>
                <v-card-title
                  class="white--text"
                  style="background-color: #23b1a9"
                >
                  <h3 class="ml-5">
                    {{
                      paymentRecoveryObj.typeofRecovery === "Salvage"
                        ? "Salvage"
                        : "Vendor"
                    }}
                    Details
                  </h3>
                  <v-spacer></v-spacer>
                  <v-icon
                    @click="salvageBuyer_dialog = !salvageBuyer_dialog"
                    class="white--text"
                    >mdi-window-close</v-icon
                  >
                </v-card-title>
                <v-card-text class="grey lighten-2">
                  <v-form ref="searchSPForm" @submit.prevent="searchSPM(1)">
                    <v-layout wrap class="pt-4 form-group-container">
                      <v-flex class="form-group">
                        <label class="form-label"> Search By Name </label>
                        <v-text-field
                          placeholder="Enter"
                          v-model="serchByBuyer"
                          @keyup="getSalvage"
                          solo
                          dense
                          @click:clear=";(serchByBuyer = ''), getSalvage()"
                          class="form-control"
                        ></v-text-field>
                      </v-flex>
                    </v-layout>
                    <v-data-table
                      :headers="
                        paymentRecoveryObj.typeofRecovery === 'Salvage'
                          ? salvageHeader
                          : notSalvageHeader
                      "
                      :items="salvageDetails"
                      hide-default-footer
                      class="vDataTableStyling mx-3 mb-2"
                      fixed-header
                      @click:row="salvagerowClick"
                      height="250"
                      disable-pagination
                      no-data-text="No Records for Recovery Agent"
                    >
                    </v-data-table>
                    <label class="form-label red--text">
                      Note : Please select Details by clicking on desired row
                    </label>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel v-if="viewMode">
          <v-expansion-panel-header color="#1EBBD9">
            <span class="page-title-small white--text">Recovery Receipt</span>
            <template v-slot:actions>
              <v-icon class="white--text">
                {{
                  panels.indexOf(3) !== -1 ? "mdi-minus-box" : "mdi-plus-box"
                }}
              </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-layout wrap class="pt-4 form-group-container">
              <v-flex v-if="recovery_Receipt" class="borderStyle pt-3">
                <template>
                  <v-data-table
                    :headers="receiptsHeaders"
                    :items="receipts_details"
                    hide-default-footer
                    class="vDataTableStyling tablewidth tableborder mx-3 mb-2"
                    no-data-text="No Records for Recovery Receipts"
                  >
                    <template v-slot:[`item.receiptNumber`]="{ item }">
                      <span>{{
                        item.receiptNumber !== ""
                          ? item.receiptNumber
                          : "------"
                      }}</span>
                    </template>
                    <template v-slot:[`item.receiptStatus`]="{ item }">
                      <span>{{
                        item.receiptStatus !== ""
                          ? item.receiptStatus
                          : "------"
                      }}</span>
                    </template>
                  </v-data-table>
                </template>
              </v-flex>
            </v-layout>
          </v-expansion-panel-content>
        </v-expansion-panel>
      
      </v-expansion-panels>

      <v-layout wrap>
        <v-layout class="mt-3">
          <v-btn
            type="button"
            color="red"
            outlined
            class=""
            @click="reset"
            v-if="!viewMode || isModify"
          >
            <v-icon> mdi-backspace </v-icon>

            <v-divider class="mx-2 ml-5" vertical></v-divider>
            <span class="subheading">Reset</span>
          </v-btn>

          <v-btn
            type="button"
            color="red"
            outlined
            class="ml-3"
            @click="goBack"
          >
            <v-icon> mdi-backspace </v-icon>

            <v-divider class="mx-2 ml-5" vertical></v-divider>
            <span class="subheading">Cancel</span>
          </v-btn>

          <v-spacer></v-spacer>
          <v-btn
            type="submit"
            color="teal white--text"
            class=""
            v-if="!viewMode || isModify"
          >
            <v-icon> mdi-arrow-right-circle </v-icon>

            <v-divider class="mx-2" vertical color="white--text"></v-divider>
            <span class="subheading">Submit</span>
          </v-btn>
          <v-btn
            width="100"
            dark
            class="btnstyle reset mr-2"
            @click="openVoid()"
            v-if="
              !createRecovery &&
              ['Active'].includes(
                paymentRecoveryObj.receiptStatus
              ) && !isModify
            "
          >
            <v-icon class="mr-2 iconsize">mdi-close-circle-outline</v-icon>
            <div class="vertical_line"></div>
            Void
          </v-btn>
        </v-layout>
      </v-layout>
    </v-form>
    <gstInvoice
      v-show="false"
      :tabledata="tabledata"
      :paymentRecoveryObj="paymentRecoveryObj"
      :panNumberForInvoice="panNumberForInvoice"
      :ReceiptAddress="ReceiptAddress"
      :contactDetail="contactDetail"
      :supplierAddress="supplierAddress"
      :email="email"
      ref="pdfForm"
    >
    </gstInvoice>

    <!-- Void Dialog -->
    <v-row justify="center">
      <v-dialog
        v-model="void_dialog"
        persistent
        max-width="40%"
        style="border-radius: 0"
      >
        <v-card>
          <v-card-title class="pl-0 px-0 py-0 dailogboxAllvcardTableBackground">
            <v-row class="colorbg">
              <span class="descrptionSpan mb-2 ml-8 mr-0">Void Recovery</span>
              <v-icon
                @click="void_dialog = false"
                class="ml-0 mt-2"
                color="#fff"
                >mdi-window-close</v-icon
              >
            </v-row>
            <v-card-text class="vcardTextTableBackground pl-2">
              <v-form ref="voidForm" @submit.prevent="submitVoid()">
                <v-layout>
                  <v-flex>
                    <v-layout column class="ml-3 mt-4">
                      <p class="mb-2 claimlabel">
                        Void Reason <span class="mandatorycolor">*</span>
                      </p>
                      <v-select
                        label="Select"
                        solo
                        dense
                        :items="reasonArray"
                        v-model="voidReason"
                        class="forminput"
                      ></v-select>
                    </v-layout>
                  </v-flex>
                  <!-- <v-flex></v-flex> -->
                </v-layout>
                <v-row style="background-color: #fff">
                  <v-col align="right">
                    <v-btn
                      class="btnstyle cancelbtn mr-3"
                      @click="void_dialog = false"
                    >
                      Cancel
                    </v-btn>

                    <v-btn
                      color="#D1121B"
                      dark
                      class="btnstyle mr-2"
                      type="submit"
                    >
                      Yes, Void Payment
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card-title>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import moment from "moment";
import gstInvoice from "./gstInvoicePDF.vue";
import ClaimDetailsVue from "../../component/ClaimDetails.vue";
import FALimitMasterSearchVue from '../UserManagement/FinancialAuthority/FALimitMasterSearch.vue';
export default {
  components: {
    gstInvoice,
    ClaimDetailsVue,
  },

  data() {
    return {
      isModify:false,
      panels: [0, 1, 2, 3, 4],
      toggleDetails: false,
      approve_dialog: false,
      claimDetails: {},
      menu1: false,
      menu2: false,
      recoveryAgent_dialog: false,
      salvageBuyer_dialog: false,
      recovery_Receipt: true,
      recovery_History: true,
      void_dialog: false,
      voidReason: "",
      transaction_types: [
        "Cash",
        "Cheque",
        "Debit Authorization",
        "Debit/Credit Card",
        "Demand Draft",
        "RTGS/NEFT",
      ],
      receiptTypes: ["Interim", "Final"],
      reasonArray: [
        "Error in payment",
        "NEFT Return",
        "Stale Cheque",
        "Cancel rewrite of policy",
      ],
      recoveryTransHistoryHeaders: [
        {
          text: "Transaction Type",
          align: "start",
          value: "type",
          sortable: false,
          width: "200px",
        },
        {
          text: "Recovery Date",
          align: "start",
          value: "createdAt",
          sortable: false,
          width: "200px",
        },
        // {
        //   text: "Transaction Input",
        //   align: "start",
        //   value: "req",
        //   sortable: false,
        //   width: "200px",
        // },
        // {
        //   text: "Transaction Output",
        //   align: "start",
        //   value: "response",
        //   sortable: false,
        //   width: "200px",
        // },
      ],
      recoveryTransHistoryDetails: [],
      receiptsHeaders: [
        {
          text: "Receipt Number",
          align: "start",
          value: "receiptNumber",
          sortable: false,
        },
        {
          text: "Receipt Status",
          align: "start",
          value: "receiptStatus",
          sortable: false,
        },
      ],
      receipts_details: [],
      tabledata: [],
      salvageHeader: [
        {
          text: "Salvage Buyer Name",
          align: "start",
          value: "buyerName",
          sortable: false,
        },
      ],
      notSalvageHeader: [
        {
          text: "Vendor Name",
          align: "start",
          value: "vendorName",
          sortable: false,
        },
      ],
      salvageDetails: [],
      recovery_AgentList: [
        {
          text: "ID",
          align: "start",
          value: "serviceProviderId",
          sortable: false,
        },
        {
          text: "Name",
          align: "start",
          value: "serviceProviderDetails.employeeName",
          sortable: false,
        },
      ],
      agent_Data: [],
      headers: [
        { text: "Commodity Name", value: "commodityName", sortable: false },
        { text: "HSN/SAC code", value: "HSNSACCode", sortable: false },
        { text: "Amount", value: "amount", sortable: false },
        { text: "Tax Rate", value: "taxRate", sortable: false },
        { text: "CGST", value: "CGST", sortable: false },
        { text: "SGST", value: "SGST", sortable: false },
        { text: "IGST", value: "IGST", sortable: false },
        { text: "Action", value: "delete", sortable: false },
      ],
      recoveryHead_Array: [
        "General Average Recovery",
        "Salvage Recovery",
        "Recovery from Railway",
        "Recovery from Motor Transport Co.",
        "Recovery from Steamer Co.",
        "Recovery from Port Trust",
        "Recovery from Airways Authorities",
        "Recovery from Postal Authorities",
        "Recovery from Third Party",
        "Recovery of Erroneous Excess Payment",
        "Recovery from Police Authorities",
        "Recovery from Contractual Obligations",
        "Contribution from other Party",
        "Recovery – Others",
        "Contribution from other policy",
        "Any other",
      ],
      recoveryTypes: ["Salvage", "Subrogation", "Excess/Cash Collection"],
      recoveryTypesPA: ["Excess/Cash Collection"],
      taxrates: [],
      serchByBuyer: "",

      spCity: "",
      panNumber: "",
      spPincode: "",
      spName: "",

      payeeDetails: [],
      houseDetails: [],
      GSTDatalist: [],
      GSTDATA: [],
      IFSCDetails: [],
      States: [],
      searchIFSCCode: "",
      isMandatory: false,
      proposalNo: "",
      paymentRecoveryObj: {
        // dateOfLoss: null,

        claimNumber: "",
        featureNumber: "",
        insuredName: "",
        policyNo: "",
        product: "",
        coverage: "",
        lossDate: null,

        typeofRecovery: "",
        recoveryHead: "",
        recoveryAgent: "",
        TAGICGSTNo: "",
        TAGICGSTState: "",
        recoveryInformation: [],
        lobType: "",
        isSubmitted:false,
        salvageBuyerGSTNumber: "",
        salvageBuyerState: "",
        recoveryAmount: 0,
        netAmount: 0,
        receiptType: "",
        receiptStatus: "",
        receiptNumber: "",
        GSTInvoice: "",
        transactionType: "",
        InstrumentReferenceNumber: "",
        instrumentDate: null,
        instrumentAmount: "0",
        paymentCurrency: "INDIA RUPEE(INR)",
        payerName: "",
        payeeBankDetails: "",
        payeeBankBranch: "",
        payeeBankName: "",
        payeeBankBranchCode: "",
        houseBankCode: "",
        houseBankBranchCode: "",
        houseBankBranch: "",
        houseBankName: "",
        remarks: "",
        payeeId: "",
      },
      recoveryId: "",
      referenceId: "",
      createRecovery: false,
      viewMode: false,
      panNumberForInvoice: "",
      contactDetail: "",
      email:"",
      ReceiptAddress: "",
      supplierAddress: "",
    }
  },
  created() {
    this.getAllTheTaxRates();
    this.$store.commit("SAVE_PAGE_HEADER", "Recovery")
    // let prevPageData = JSON.parse(window.sessionStorage.getItem('SET_CURRENT_PAGE_DATA')) || {};
    let prevPageData = this.$store.getters.fetchCurrentPageData || {};
    if(Object.keys(prevPageData).length != 0 || Object.keys(this.$route.params).length != 0) {
      let item = this.$route.params.item  || prevPageData.data;
      this.claimDetails = item;
      this.viewMode = item.viewMode || prevPageData.viewMode;
      this.isModify=item?.isModify;
      if(item?.isModify) this.viewMode=false
      this.getSingleRecovery(item);
      this.createRecovery = false;
    } else {
      this.createRecovery = true
      this.claimDetails = this.$store.getters.fetchDataFromOtherLOB
      console.log(this.claimDetails,this.isModify, "claim Details isModify=")
      this.paymentRecoveryObj.claimNumber =
        this.$store.getters.fetchDataFromOtherLOB.claimNumber
      this.paymentRecoveryObj.featureNumber =
        this.$store.getters.fetchDataFromOtherLOB.featureNumber
      this.paymentRecoveryObj.policyNo =
        this.$store.getters.fetchDataFromOtherLOB.policyNumber
      this.paymentRecoveryObj.insuredName =
        this.$store.getters.fetchDataFromOtherLOB.insuredName
      this.paymentRecoveryObj.product =
        this.$store.getters.fetchDataFromOtherLOB.productName
      this.paymentRecoveryObj.coverage =
        this.$store.getters.fetchDataFromOtherLOB.cover?.coverGroupName
      this.paymentRecoveryObj.lobType =
        this.$store.getters.fetchDataFromOtherLOB.lobType
      this.paymentRecoveryObj.lossDate = this.dateFormat(
        this.$store.getters.fetchDataFromOtherLOB.dateOfLoss
      )
      this.proposalNo = this.$store.getters.fetchDataFromOtherLOB.proposalNo
      this.searchBanks(this.paymentRecoveryObj.payeeBankDetails)
    }
  },
  mounted() {
    this.getGSTData()
    this.getStates()
  },
  computed: {
    getTypeOfRecoveryItems() {
      return this.paymentRecoveryObj.lobType === "Travel" ||
        this.paymentRecoveryObj.lobType === "Home"
        ? this.recoveryTypes
        : this.recoveryTypesPA
    },
    isHomeLob(){
      return this.paymentRecoveryObj?.lobType==="Home"
    },
    disableOnsalvage() {
      return this.paymentRecoveryObj.typeofRecovery == "Salvage" ? true : false
    },
    computedLossDate() {
      return this.paymentRecoveryObj.lossDate == null
        ? ""
        : moment(this.paymentRecoveryObj.lossDate).format("DD-MM-YYYY")
    },
    computedInstrumentDate() {
      return this.paymentRecoveryObj.instrumentDate == null
        ? ""
        : moment(this.paymentRecoveryObj.instrumentDate).format("DD-MM-YYYY")
    },
  },
  methods: {
    dateFormat(date) {
      return date ? moment(date).format("YYYY-MM-DD") : ""
    },
    reset() {
      this.showConfirm(
        "Confirmation",
        `Are you sure want to clear?`,
        "Yes",
        "No",
        () => {
          // this.paymentRecoveryObj.claimNumber= "",
          // this.paymentRecoveryObj.dateOfLoss= null,
          // this.paymentRecoveryObj.featureNumber= "",
          // this.paymentRecoveryObj.insuredName= "",
          // this.paymentRecoveryObj.policyNo= "",
          // this.paymentRecoveryObj.product="",
          // this.paymentRecoveryObj.coverage= "",
          // this.paymentRecoveryObj.lossDate= "",
          ;(this.paymentRecoveryObj.typeofRecovery = ""),
            (this.paymentRecoveryObj.recoveryHead = ""),
            (this.paymentRecoveryObj.recoveryAgent = ""),
            (this.paymentRecoveryObj.TAGICGSTNo = ""),
            (this.paymentRecoveryObj.TAGICGSTState = ""),
            (this.paymentRecoveryObj.recoveryInformation = []),
            (this.paymentRecoveryObj.salvageBuyerGSTNumber = ""),
            (this.paymentRecoveryObj.salvageBuyerState = ""),
            (this.paymentRecoveryObj.recoveryAmount = 0),
            (this.paymentRecoveryObj.netAmount = 0),
            (this.paymentRecoveryObj.receiptType = ""),
            (this.paymentRecoveryObj.receiptStatus = ""),
            (this.paymentRecoveryObj.receiptNumber = ""),
            (this.paymentRecoveryObj.GSTInvoice = ""),
            (this.paymentRecoveryObj.transactionType = ""),
            (this.paymentRecoveryObj.InstrumentReferenceNumber = ""),
            (this.paymentRecoveryObj.instrumentDate = null),
            (this.paymentRecoveryObj.instrumentAmount = 0),
            (this.paymentRecoveryObj.paymentCurrency = "INDIA RUPEE(INR)"),
            (this.paymentRecoveryObj.payerName = ""),
            (this.paymentRecoveryObj.payeeBankDetails = ""),
            (this.paymentRecoveryObj.payeeBankBranch = ""),
            (this.paymentRecoveryObj.payeeBankName = ""),
            (this.paymentRecoveryObj.payeeBankBranchCode = ""),
            (this.paymentRecoveryObj.houseBankCode = ""),
            (this.paymentRecoveryObj.houseBankBranchCode = ""),
            (this.paymentRecoveryObj.houseBankBranch = ""),
            (this.paymentRecoveryObj.houseBankName = ""),
            (this.paymentRecoveryObj.remarks = "")
          this.paymentRecoveryObj.payeeId = ""
        },
        () => {}
      )
    },
    submitVoid() {
      let requestData = {
        recoveryId: this.recoveryId,
        referenceNumber: this.referenceId,
        remarks: this.voidReason,
        lobType: this.claimDetails.lobType,
      }
      if (this.$refs.voidForm.validate()) {
        this.PUTM(
          "voidRecovery",
          "",
          requestData,
          (res) => {
            this.void_dialog = false
            this.showAlert(res.data.msg)
          },
          (error) => {
            this.hideProgress()
            if(error.response.data.msg) {
              this.showAlert(error.response.data.msg)
            } else if (error.response.data.message.msg) {
              this.showAlert(error.response.data.message.msg)
            } else if (error.response.data.message) {
              this.showAlert(error.response.data.message)
            } else {
              this.showAlert("Something went wrong")
            }
          },
          true
        )
      } else {
        this.showAlert("Please enter void reason")
      }
    },
    recoveryAgentClick(item) {
      console.log(item)
      this.paymentRecoveryObj.recoveryAgent =
        item.serviceProviderDetails.employeeName
      this.recoveryAgent_dialog = false
    },
    checkType() {
      if (this.paymentRecoveryObj.typeofRecovery == "Salvage") {
        this.isMandatory = true
      } else {
        this.isMandatory = false
        this.paymentRecoveryObj.recoveryInformation = []
        this.paymentRecoveryObj.netAmount = 0
        this.paymentRecoveryObj.recoveryAmount = 0
      }
    },
    getGSTData() {
      this.GETM(
        "GET_GST_NUMBER",
        "",
        (res) => {
          if (
            res.data.statusCode == 200 &&
            res.data.data.data &&
            res.data.data.data.length > 0
          ) {
            let result = res.data.data.data
            this.GSTDATA=result.map((ele)=>({...ele,state:ele.state.toUpperCase()}))
          } else {
            this.GSTDATA = []
          }
        },
        (error) => {
          this.hideProgress()
          this.GSTDATA = []
          if (error.response.data.msg) {
            this.showAlert(error.response.data.msg)
          } else if (error.response.data.message.msg) {
            this.showAlert(error.response.data.message.msg)
          } else if (error.response.data.message) {
            this.showAlert(error.response.data.message)
          } else {
            this.showAlert("Something went wrong")
          }
        }
      )
    },
    getGSTState(number) {
      var a = this.GSTDATA.filter((x) => x.gstRegistrationNumber == number)
      //console.log("a",a)
      if (a.length > 0) {
        this.paymentRecoveryObj.TAGICGSTState = a[0].state.toUpperCase()
      } else {
        this.paymentRecoveryObj.TAGICGSTState = ""
      }
    },
    calculateRecoveryAmount(item) {
      if (this.paymentRecoveryObj.recoveryInformation.length !== 0) {
        this.paymentRecoveryObj.recoveryAmount = 0
        this.paymentRecoveryObj.recoveryInformation.forEach((obj) => {
          this.paymentRecoveryObj.recoveryAmount += this.roundOffValue(
            obj.amount
          )
        })
        this.cgstSgstCalculation(item)
      }
    },
    salvagerowClick(item) {
      this.paymentRecoveryObj.payerName = item.buyerName || item.vendorName
      this.paymentRecoveryObj.payeeId = item.buyerId || item.vendorId
      this.salvageBuyer_dialog = false
    },

    calculateNetAmount() {
      if (this.paymentRecoveryObj.recoveryInformation.length !== 0) {
        this.paymentRecoveryObj.netAmount = 0
        let amt = 0
        let cgst = 0
        let igst = 0
        let sgst = 0
        this.paymentRecoveryObj.recoveryInformation.forEach((obj) => {
          amt += this.roundOffValue(obj.amount)
          if (obj.CGST) {
            cgst += this.roundOffValue(obj.CGST)
          }
          if (obj.SGST) {
            sgst += this.roundOffValue(obj.SGST)
          }
          if (obj.IGST) {
            igst += this.roundOffValue(obj.IGST)
          }
        })
        this.paymentRecoveryObj.netAmount = this.roundOffValue(
          amt + cgst + sgst + igst
        )
      }
    },

    calculateGSTTaxes(v) {
      if (v) {
        this.paymentRecoveryObj.recoveryInformation.forEach((obj, objKey) => {
          this.getGSTDetail(obj, objKey)
        })
      }
    },

    getGSTDetail(item, index) {
      console.log("--", item)
      if (!item.amount) {
        this.showAlert("Please enter amount")
        this.paymentRecoveryObj.recoveryInformation[index].taxRate = null
      } else {
        let lob = this.$store.getters.fetchDataFromOtherLOB.lobType
        this.showProgress("Please wait!")
        let req = "perPage=10" + "&pageNo=1" + "&taxRate=" + item.taxRate
        let url = "GET_TAX_RATES"
        let isCommonModuleUrl = false
        if (lob == "Home") {
          url = "GET_HOME_TAX_RATES"
          req = "perPage=10" + "&pageNo=1&active=true&approveStatus=Approved" + "&taxRate=" + item.taxRate
          isCommonModuleUrl = true
        }

        this.GETM(
          url,
          req,
          (res) => {
            if (res.data.statusCode == 200 && res.data.data.data.length !== 0) {
              this.GSTDatalist = res.data.data.data[0]
              this.cgstSgstCalculation(item)
            } else {
              // this.showAlert(res.data.msg);
              this.GSTDatalist = []
            }
            this.hideProgress()
          },
          (error) => {
            this.hideProgress()
            if (error.response.data.msg) {
              this.showAlert(error.response.data.msg)
            } else if (error.response.data.message.msg) {
              this.showAlert(error.response.data.message.msg)
            } else if (error.response.data.message) {
              this.showAlert(error.response.data.message)
            } else {
              this.showAlert("Something went wrong")
            }
          },
          isCommonModuleUrl
        )
      }
    },

    cgstSgstCalculation(item) {
      if (item && item.amount !== 0 && item.amount !== "" && item.taxRate) {
        if (this.paymentRecoveryObj.TAGICGSTNo === null) {
          this.paymentRecoveryObj.TAGICGSTNo = ""
        }
        if (this.paymentRecoveryObj.salvageBuyerGSTNumber === null) {
          this.paymentRecoveryObj.salvageBuyerGSTNumber = ""
        }
        if (
          (this.paymentRecoveryObj.TAGICGSTNo !== "" ||
            this.paymentRecoveryObj.salvageBuyerGSTNumber !== "") &&
          this.paymentRecoveryObj.TAGICGSTNo.slice(0, 2) ==
            this.paymentRecoveryObj.salvageBuyerGSTNumber.slice(0, 2)
        ) {
          item.SGST = this.roundOffValue(
            (item.amount *
              this.GSTDatalist.sgstUgst *
              this.GSTDatalist.taxRate) /
              10000
          )
          item.CGST = this.roundOffValue(
            (item.amount * this.GSTDatalist.cgst * this.GSTDatalist.taxRate) /
              10000
          )
          item.IGST = 0
        } else {
          item.IGST = this.roundOffValue(
            (item.amount * this.GSTDatalist.igst * this.GSTDatalist.taxRate) /
              10000
          )
          item.SGST = 0
          item.CGST = 0
        }
        this.calculateNetAmount()
      }
      // }else{
      //     this.calculateNetAmount();
      // }
    },
    getHouseDetails() {
      if (this.paymentRecoveryObj.transactionType) {
        let paymentMode = ""
        switch (this.paymentRecoveryObj.transactionType) {
          case "Cash":
            paymentMode = "S"
            break
          case "Cheque":
            paymentMode = "C"
            break
          case "Debit Authorization":
            paymentMode = "DA"
            break
          case "Debit/Credit Card":
            paymentMode = "CA"
            break
          case "Demand Draft":
            paymentMode = "D"
            break
          case "RTGS/NEFT":
            paymentMode = "RT"
            break
        }
        let req =
          "proposalNo=" + this.proposalNo + "&paymentMode=" + paymentMode
        this.GETM(
          "getHouseBankDetails",
          req,
          (res) => {
            if (
              res.data.statusCode == 200 &&
              res.data.data &&
              res.data.data.length > 0
            ) {
              // this.houseDetails = res.data.data[0]
              this.paymentRecoveryObj.houseBankBranch =
                res.data.data[0].houseBankBranch
              this.paymentRecoveryObj.houseBankName =
                res.data.data[0].houseBankName
              this.paymentRecoveryObj.houseBankCode =
                res.data.data[0].houseBankCode
              this.paymentRecoveryObj.houseBankBranchCode =
                res.data.data[0].houseBankBranchCode
            } else {
              this.showAlert(res.data.msg)
              this.paymentRecoveryObj.houseBankBranch = ""
              this.paymentRecoveryObj.houseBankName = ""
              this.paymentRecoveryObj.houseBankCode = ""
              this.paymentRecoveryObj.houseBankBranchCode = ""
            }
          },
          (error) => {
            this.hideProgress()
            if (error.response.data.msg) {
              this.showAlert(error.response.data.msg)
            } else if (error.response.data.message.msg) {
              this.showAlert(error.response.data.message.msg)
            } else if (error.response.data.message) {
              this.showAlert(error.response.data.message)
            } else {
              this.showAlert("Something went wrong")
            }
          },
          true
        )
      } else {
        this.paymentRecoveryObj.houseBankBranch = ""
        this.paymentRecoveryObj.houseBankName = ""
        this.paymentRecoveryObj.houseBankCode = ""
        this.paymentRecoveryObj.houseBankBranchCode = ""
      }
    },
    searchBanks(value) {
      let request = value
      this.GET_ByURLM(
        "GET_GC_BANK",
        request,
        (res) => {
          this.payeeDetails = []
          if (res.data.statusCode == 200 && res.data.data) {
            if (Array.isArray(res.data.data)) {
              this.payeeDetails = res.data.data
            } else {
              this.payeeDetails = [res.data.data]
            }
            // this.bindpayeeBankdetails(value);
          }
        },
        (error) => {
          this.hideProgress()
          this.payeeDetails = []
          if (error.response.data.msg) {
            this.showAlert(error.response.data.msg)
          } else if (error.response.data.message.msg) {
            this.showAlert(error.response.data.message.msg)
          } else if (error.response.data.message) {
            this.showAlert(error.response.data.message)
          } else {
            this.showAlert("Something went wrong")
          }
        },
        true
      )
    },
    bindpayeeBankdetails(IFSC) {
      let indexItem = {}
      if (IFSC) {
        if (Array.isArray(this.payeeDetails)) {
          indexItem = this.payeeDetails.find((elem) => {
            return elem.ifscCode == IFSC
          })
        } else {
          indexItem = this.payeeDetails
        }
        if (indexItem) {
          this.paymentRecoveryObj.payeeBankBranch = indexItem.branchName
          this.paymentRecoveryObj.payeeBankName = indexItem.financierName
          this.paymentRecoveryObj.payeeBankBranchCode = indexItem.branchCode
          console.log('------', indexItem)
        }
      } else {
        this.paymentRecoveryObj.payeeBankBranch = ""
        this.paymentRecoveryObj.payeeBankName = ""
        this.paymentRecoveryObj.payeeBankBranchCode = ""
        this.paymentRecoveryObj.houseBankBranch =""
          this.paymentRecoveryObj.houseBankName=''
      }
    },
    getReceiptHistory(item) {
      //console.log("Itemfromrecere",item)
      this.GET_ByURLM(
        "getReceiptHistory",
        item.referenceNumber,
        (res) => {
          if (res.data.statusCode == 200 && res.data.data) {
            this.recoveryTransHistoryDetails = res.data.data
            // this.receipts_details = []
            // this.receipts_details.push({"receiptStatus":item.receiptStatus, "receiptNumber":item.receiptNumber})
          } else {
            this.showAlert(data.res.msg)
          }
        },
        true,
        (error) => {
          this.hideProgress()
          if (error.response.data.msg) {
            this.showAlert(error.response.data.msg)
          } else if (error.response.data.message.msg) {
            this.showAlert(error.response.data.message.msg)
          } else if (error.response.data.message) {
            this.showAlert(error.response.data.message)
          } else {
            this.showAlert("Something went wrong")
          }
        },
        false,
        "",
        {
          claimnumber: this.$store.getters.fetchDataFromOtherLOB.claimNumber,
          featurenumber:
            this.$store.getters.fetchDataFromOtherLOB.featureNumber,
          policynumber: this.$store.getters.fetchDataFromOtherLOB.policyNumber,
        }
      )
    },
    CheckReceiptStatus(){
      let status=this.paymentRecoveryObj.receiptStatus || ''
      let options = ['ACCEPTED',"DEPOSITED","NOT DEPOSITED"]
      console.log("ReceiptStatus Change",status,options.includes(status))
      if(options.includes(status)){
        this.paymentRecoveryObj.receiptStatus='Collected'
        return "Collected"
      }
      return status
    
    },
    getSingleRecovery(item) {
      // this.viewMode = item.viewMode;
      this.paymentRecoveryObj = item
      this.referenceId = item.referenceNumber
      this.recoveryId = item.recoveryId
      this.gcVendorId = item.gcVendorId
      let request = item.referenceNumber
      this.getReceiptHistory(item)
      this.GET_ByURLM(
        "getSingleRecovery",
        request,
        (res) => {
          if (res.data.statusCode == 200 && res.data.data) {
            let lob=res.data.data.lobType
            this.paymentRecoveryObj = res.data.data
            this.claimDetails.lobType = lob
            if(lob=="Home"){
              item.receiptStatus=this.CheckReceiptStatus()
              let optnObj=  {text: "Receipt Status",align: "start",value: "receiptStatus",sortable: false,width: "200px",}
              this.recoveryTransHistoryHeaders.push(optnObj)
            } 
            this.getStates()
            this.getGSTData()
            this.searchBanks(this.paymentRecoveryObj.payeeBankDetails)
            // this.checkType();
            this.getSalvageForGST()
            // this.openPolicyData();
            this.receipts_details = []
            this.receipts_details.push({
              receiptStatus: item.receiptStatus,
              receiptNumber: item.receiptNumber,
            })
          } else {
            this.showAlert(data.res.msg)
          }
        },
        (error) => {
          this.hideProgress()
          if (error.response.data.msg) {
            this.showAlert(error.response.data.msg)
          } else if (error.response.data.message.msg) {
            this.showAlert(error.response.data.message.msg)
          } else if (error.response.data.message) {
            this.showAlert(error.response.data.message)
          } else {
            this.showAlert("Something went wrong")
          }
        },
        true
      )
    },

    openVoid() {
      this.void_dialog = true
      this.voidReason = ""
    },

    convertHTMLToPDF() {
      //on gst Invoice button
      console.log(this.paymentRecoveryObj.recoveryInformation)
      this.$refs.pdfForm.convertHTMLToPDF()
    },
    goBack() {
      //on cancel button
      this.showConfirm(
        "Confirmation",
        `Are you sure you want to leave?`,
        "Yes",
        "No",
        () => {
          this.$router.push({
            name: "RecoverySearch",
          })
        },
        () => {}
      )
    },

    getStates() {
      this.GETM(
        "getStates",
        "",
        (res) => {
          this.States = res.data.data.data
        },
        (error) => {
          return error
        }
      )
    },
    getAllTheTaxRates() {
      let lob = this.$store.getters.fetchDataFromOtherLOB.lobType
      this.showProgress("Please wait!")
      let req = "active=true&effectiveDate=true"
      let url = "GET_TAX_RATES"
      let isCommonModuleUrl = false
      if (lob == "Home") {
        url = "GET_HOME_TAX_RATES"
        req = "active=true&approveStatus=Approved"
        isCommonModuleUrl = true
      }
      this.GETM(
        url,
        req,
        (res) => {
          if (res.data.statusCode === 200 && res.data.data) {
            console.log("taxRate res.data.data=", res.data.data)
            this.taxrates = res.data.data.data.sort((a, b) => {
              return (
                this.roundOffValue(a.taxRate) - this.roundOffValue(b.taxRate)
              )
            })
            // console.log('-sdsdf', this.taxrates)
          } else {
            this.taxrates = []
          }
          this.hideProgress()
        },
        (error) => {
          this.hideProgress()
          if (error.response.data.msg) {
            this.showAlert(error.response.data.msg)
          } else if (error.response.data.message.msg) {
            this.showAlert(error.response.data.message.msg)
          } else if (error.response.data.message) {
            this.showAlert(error.response.data.message)
          } else {
            this.showAlert("Something went wrong")
          }
        },
        isCommonModuleUrl
      )
    },
    openAgent() {
      //on recovery agent
      this.recoveryAgent_dialog = true
      this.spName = ""
      this.panNumber = ""
      this.spCity = ""
      this.spPincode = ""
      this.getRecoveryAgentList()
    },
    getRecoveryAgentList() {
      let requestData =
        `serviceProviderType=Recovery Agent` + `&approveStatus=Approved` +`&lob=${this.paymentRecoveryObj.lobType}`
      if (this.spCity) {
        requestData += `&spCity=${this.spCity}`
      }
      if (this.spPincode) {
        requestData += `&spPincode=${this.spPincode}`
      }
      if (this.panNumber) {
        requestData += `&panNumber=${this.panNumber}`
      }

      if (this.spName) {
        requestData += `&serviceProviderName=${this.spName}`;
      }
      this.GETM(
        "searchServiceProvider",
        requestData,
        (res) => {
          if (res.data.data && res.data.data.data) {
            this.agent_Data = res.data.data.data
            // this.recoveryAgentMenu = true
          } else {
            this.agent_Data = []
            this.showAlert(res.data.msg)
          }
        },
        (error) => {
          this.hideProgress()
          this.agent_Data = []
          if (error.response.data.msg) {
            this.showAlert(error.response.data.msg)
          } else if (error.response.data.message.msg) {
            this.showAlert(error.response.data.message.msg)
          } else if (error.response.data.message) {
            this.showAlert(error.response.data.message)
          } else {
            this.showAlert("Something went wrong")
          }
        },
        false,
        ""
      )
    },
    getSalvage() {
      let reqURL = ""
      let reqData = ""
      if (this.paymentRecoveryObj.typeofRecovery === "Salvage") {
        reqData = `status=true`
        if (this.serchByBuyer) {
          reqData += `&buyerName=${this.serchByBuyer}`
        }
        reqURL = "SALVAGE_BUYER_SEARCH"
      } else {
        reqData += `vendorName=${this.serchByBuyer}`
        reqURL = "GET_VENDORS"
      }
      this.GETM(
        reqURL,
        reqData,
        (res) => {
          this.hideProgress()
          if (
            res.data.statusCode == 200 &&
            res.data.data.data &&
            res.data.data.data.length > 0
          ) {
            this.salvageDetails = res.data.data.data
          } else {
            this.salvageDetails = []
          }
          console.log("------", this.salvageDetails)
        },
        (error) => {
          this.hideProgress()
          this.salvageDetails = []
          if (error.response.data.msg) {
            this.showAlert(error.response.data.msg)
          } else if (error.response.data.message.msg) {
            this.showAlert(error.response.data.message.msg)
          } else if (error.response.data.message) {
            this.showAlert(error.response.data.message)
          } else {
            this.showAlert("Something went wrong")
          }
        },
        false,
        ""
      )
    },
    validatetable() {
      let status = false
      this.paymentRecoveryObj.recoveryInformation.forEach((d) => {
        if (
          d.commodityName !== "" &&
          d.amount !== "" &&
          d.amount !== 0 &&
          d.taxRate !== "" &&
          d.taxRate !== null
        ) {
          status = true
        } else {
          status = false
        }
      })
      return status
    },

    checkGstNo() {
      if (this.paymentRecoveryObj.salvageBuyerGSTNumber) {
        // this.paymentRecoveryObj.salvageBuyerState = ''
        let gstno = this.paymentRecoveryObj.salvageBuyerGSTNumber.slice(0, 2)
        let value = this.GSTDATA.filter((ele) => {
          return ele.gstRegistrationNumber.slice(0, 2) == gstno
        })
        // console.log('-----val',value)
        if (value.length) {
          this.paymentRecoveryObj.salvageBuyerState = value[0].state
          if (value[0].state != this.paymentRecoveryObj.salvageBuyerState) {
            return "GST No not matching to the Selected State";
          }
        }
      } else {
        return true
      }
    },
    checkGstRule() {
      if (
        this.paymentRecoveryObj.TAGICGSTState &&
        this.paymentRecoveryObj.salvageBuyerState &&
        this.paymentRecoveryObj.TAGICGSTState ==
          this.paymentRecoveryObj.salvageBuyerState &&
        this.paymentRecoveryObj.TAGICGSTNo.slice(0, 2) !=
          this.paymentRecoveryObj.salvageBuyerGSTNumber.slice(0, 2)
      ) {
        return "GST No not matching to the Selected State"
      } else {
        return true
      }
    },
    updateRecovery(){
      console.log("update123")
      if (
        this.paymentRecoveryObj?.typeofRecovery === "Salvage" &&
        this.paymentRecoveryObj?.recoveryInformation.length === 0
      ) {
        this.showAlert("Atleast one commodity is Mandatory")
        return false
      }
      if (this.paymentRecoveryObj.recoveryInformation.length > 0) {
        for (
          let i = 0;
          i < this.paymentRecoveryObj.recoveryInformation.length;
          i++
        ) {
          if (
            !this.paymentRecoveryObj.recoveryInformation[i].commodityName &&
            !this.paymentRecoveryObj.recoveryInformation[i].HSNSACCode &&
            !this.paymentRecoveryObj.recoveryInformation[i].amount &&
            !this.paymentRecoveryObj.recoveryInformation[i].taxRate
          ) {
            this.paymentRecoveryObj.recoveryInformation.splice(i, 1)
          }
        }
      }
      let updateId=this.claimDetails?.referenceNumber
      this.showProgress()
      this.PUT_ByURLM(
        "updateRecovery",
        this.paymentRecoveryObj,
        (res) => {
          this.hideProgress()
          if (res.data.statusCode === 200) {
            this.$router.push({
              name: "RecoverySearch",
            })
          }
          // this.showAlert(res.data.msg);
          this.showToast(res.data.msg, this.TOST().SUCCESS)
        },
        (error) => {
          this.hideProgress()
          if (error.response.data.msg) {
            this.showAlert(error.response.data.msg)
          } else if (error.response.data.message.msg) {
            this.showAlert(error.response.data.message.msg)
          } else if (error.response.data.message) {
            this.showAlert(error.response.data.message)
          } else {
            this.showAlert("Something went wrong")
          }
        },
        updateId,
        "Home"
      )
    },
    submitRecovery() {
      //onsubmit
      if (!this.$refs.recoveryform.validate()) {
        return this.showAlert("Please fill mandatory fields.")
      } else {
        this.showConfirm(
          "Confirmation",
          `Are you sure you want to submit?`,
          "Yes",
          "No",
          (Yes) => {


            if(this.isModify) this.updateRecovery()
            else  this.create_Recovery()
          }
        )
      }
    },

    create_Recovery() {
      if (
        this.paymentRecoveryObj?.typeofRecovery === "Salvage" &&
        this.paymentRecoveryObj?.recoveryInformation.length === 0
      ) {
        this.showAlert("Atleast one commodity is Mandatory")
        return false
      }
      if (this.paymentRecoveryObj.recoveryInformation.length > 0) {
        for (
          let i = 0;
          i < this.paymentRecoveryObj.recoveryInformation.length;
          i++
        ) {
          if (
            !this.paymentRecoveryObj.recoveryInformation[i].commodityName &&
            !this.paymentRecoveryObj.recoveryInformation[i].HSNSACCode &&
            !this.paymentRecoveryObj.recoveryInformation[i].amount &&
            !this.paymentRecoveryObj.recoveryInformation[i].taxRate
          ) {
            this.paymentRecoveryObj.recoveryInformation.splice(i, 1)
          }
        }
      }
      this.showProgress()
      let reqData={...this.paymentRecoveryObj,isSubmitted:true}
      this.POSTM(
        "createRecovery",
        reqData,
        (res) => {
          this.hideProgress()
          if (res.data.statusCode === 200) {
            this.$router.push({
              name: "RecoverySearch",
            })
          }
          // this.showAlert(res.data.msg);
          this.showToast(res.data.msg, this.TOST().SUCCESS)
        },
        true,
        (error) => {
          this.hideProgress()
          if (error.response.data.msg) {
            this.showAlert(error.response.data.msg)
          } else if (error.response.data.message.msg) {
            this.showAlert(error.response.data.message.msg)
          } else if (error.response.data.message) {
            this.showAlert(error.response.data.message)
          } else {
            this.showAlert("Something went wrong");
          }
        },
        false,
        this.phaseFlag
      )
    },

    openSalvageBuyer() {
      //on payer name
      this.salvageBuyer_dialog = true
      this.serchByBuyer = ""
      this.getSalvage()
    },

    delData(index) {
      this.deleteRecord = index
      this.approve_dialog = true
    },
    deleteConfirm() {
      this.paymentRecoveryObj.recoveryInformation.splice(this.deleteRecord, 1)
      this.approve_dialog = false
    },
    addRow() {
      if(this.paymentRecoveryObj.recoveryInformation.length > 10){
        return this.showAlert("only 10 rows are allowed")
      }
      let data = {
        commodityName: "",
        HSNSACCode: "",
        amount: 0,
        taxRate: "",
        CGST: 0,
        SGST: 0,
        IGST: 0,
      }
      if (this.paymentRecoveryObj.recoveryInformation.length < 1 || this.isHomeLob) {
        this.paymentRecoveryObj.recoveryInformation.push(data)
      } else {
        this.showAlert("only 1 row to be added")
      }
    },
    getSalvageForGST() {
      this.showProgress()
      let reqURL = ""
      let reqData = ""
      if (this.paymentRecoveryObj.typeofRecovery === "Salvage") {
        reqData = `status=true`
        if (this.paymentRecoveryObj.payerName) {
          reqData += `&buyerName=${this.paymentRecoveryObj.payerName}`
        }
        reqURL = "SALVAGE_BUYER_SEARCH"
      } else {
        reqData += `vendorName=${this.paymentRecoveryObj.payerName}`
        reqURL = "GET_VENDORS"
      }
      this.GETM(
        reqURL,
        reqData,
        (res) => {
          this.hideProgress()
          if (res.data && res.data.data.data && res.data.data.data.length > 0) {
            this.salvageDetails = res.data.data.data.filter((elem) => {
              return (
                elem.buyerName === this.paymentRecoveryObj.payerName ||
                elem.vendorName === this.paymentRecoveryObj.payerName
              )
            })
            this.panNumberForInvoice = this.salvageDetails[0]?.panNumber || ""
            this.contactDetail =
              this.salvageDetails[0]?.landlineNumber[0]?.number || ""
            this.email =
              this.salvageDetails[0]?.emailAddress[0] || ""
            this.ReceiptAddress =
              this.salvageDetails[0]?.addressLine1 +
                " " +
                this.salvageDetails[0]?.addressLine2 +
                " " +
                this.salvageDetails[0]?.addressLine3 || ""
            this.hideProgress()
          } else {
            this.showAlert("No data available.")
          }
        },
        (err) => {
          this.hideProgress()

          this.showAlert(err.response.data.msg)
        }
      )
    },
    // openPolicyData(){
    //         // policy search request date of loss change to DD/MM/YYYY
    //         let requestData = { //WC-1686
    //           certificateNumber: "",
    //           timeOfLoss: "",
    //           dateOfLoss: this.paymentRecoveryObj.dateOfLoss,
    //           policyNumber: this.paymentRecoveryObj.policyNumber,
    //           proceedSearch: true,
    //         };
    //         this.POSTM('GET_POLICYSEARCH', requestData, (res) => {
    //             // console.log("GET_POLICYSEARCH res",res)
    //             this.hideProgress();
    //             console.log(res.data)
    //             // if (this.$store.getters.fetchclaimMainscreen_id.lob === "AIGC") {
    //             //     if (res.data && res.data.data && res.data.data[0].policyDetailsResponse) {
    //             //         let finalResponse = res.data.data[0].policyDetailsResponse.producerDetails[0];
    //             //         this.supplierAddress = finalResponse.policyIssuanceOffice + " - " + finalResponse.pincode
    //             //     }
    //             // }else{
    //             //     if (res.data && res.data.data && res.data.data.data && res.data.data.data[0].proposalDtls) {
    //             //         let finalResponse = res.data.data.data[0].proposalDtls[0];
    //             //         this.supplierAddress = finalResponse.policyIssuanceOffice + " - " + finalResponse.pincode
    //             //     }
    //             // }
    //             // console.log('policyDATA',finalResponse)
    //             this.hideProgress();
    //         }, (error) => {
    //             this.hideProgress();
    //             if (error.response.data.msg) {
    //                 this.showAlert(error.response.data.msg);
    //             } else if (error.response.data.message) {
    //                 this.showAlert(error.response.data.message);
    //             } else {
    //                 this.showAlert("Some thing went wrong");
    //             }
    //             console.log(error);
    //         });

    // },
  },
  watch: {
    searchIFSCCode(value) {
      if (value && value.length >= 7) {
        this.searchBanks(value)
      }
    }
  },  
}
</script>

<style scoped>
.nav__container__1 h4 {
  display: inline-block;
  font-weight: 600;
}

.text__claim__type {
  text-transform: uppercase;
  border-left: #ccc solid 1px;
  margin-left: 0.5em;
  padding-left: 0.5em;
}
.btn__toggler {
  font-size: small !important;
  font-weight: 500;
  background: transparent !important;
  color: #2fb5ad !important;
}
.container__toggle {
  flex: 0;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25) !important;
  border: rgba(0, 0, 0, 0.125) solid 1px;
  mix-blend-mode: multiply;
  border: 1px solid #d3d3d3;
  background-color: #f5f5f5;
  border-radius: 0;
  font-weight: 500;
}
.bottom__container {
  padding: 8px 15px;
  border-top: 1px solid #d3d3d3;
}
.bottom__container .term {
  font-weight: 500;
  font-size: 14px;
}
.bottom__container .value {
  font-weight: normal;
  font-size: 14px;
}

.blueiconstyle {
  margin-right: -12px;
  background-color: #01bbd9;
  width: 45px;
  height: 38px;
  font-size: 26px !important;
  opacity: 1;
  color: #fff !important;
  cursor: pointer !important;
}
.aligncenter {
  position: fixed;
  left: 41%;
  top: 50%;
}
.contactbtn {
  border-radius: 0;
  border: 0.5px solid #d9d9d9;
  text-transform: capitalize;
  letter-spacing: normal;
}
.iconsize {
  font-size: 17px !important;
}
.tablewidth {
  max-width: 40%;
}
.void {
  color: #d1121b;
}
.btnstyle {
  height: 35px !important;
  text-transform: capitalize;
  font-size: 11px !important;
  letter-spacing: 0.5px;
}
.reset {
  background-color: white !important;
  border: 1px solid red;
  color: red !important;
}
.descrptionSpan {
  width: 85%;
  margin-top: 21px;
  font-weight: 500;
  font-size: 17px;
  letter-spacing: 0.4px;
}
</style>
