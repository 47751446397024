<template>
  <div>
    <v-container fluid>
      <v-layout row>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Policy Number
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.policyNumber"
              maxlength="30"
              counter="30"
              class="rounded-0"
              aria-required="true"
              :rules="[alphaNumeric(policyNumber, 'Policy Number')]"
              disabled
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Product Name
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.productName"
              maxlength="100"
              counter="100"
              class="rounded-0"
              aria-required="true"
              :rules="[alphaNumeric(productName)]"
              :disable="productName ? false : true"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Policy Status
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.policyStatus"
              maxlength="100"
              counter="100"
              class="rounded-0"
              aria-required="true"
              :rules="[alphaNumeric(policyStatus, 'Policy Status')]"
              disabled
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Policy Type
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.policyType"
              maxlength="100"
              counter="100"
              class="rounded-0"
              aria-required="true"
              :rules="[alphaNumeric(policyType, 'Policy Type')]"
              disabled
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              First Policy Inception Date
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.firstPolicyInceptionDate"
              maxlength="10"
              counter="10"
              class="rounded-0"
              aria-required="true"
              disabled
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Policy Start Date
            </v-text>
            <v-menu
              ref="calender"
              :close-on-content-click="true"
              :nudge-right="40"
              transition="scale-transition"
              max-width="290px"
              min-width="290px"
              class="rounded-0"
            >
              <!-- :rules="typeOfEmployee !== 'Service ID'?[required()]:[()]" -->
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-bind="attrs"
                  label="Select.."
                  :value="computedDate('policyStartDate')"
                  disabled
                  solo
                  class="rounded-0"
                  v-on="on"
                >
                  <div
                    slot="prepend-inner"
                    style="background-color: #f7f7f7; padding: 9px !important"
                  >
                    <v-icon>event</v-icon>
                  </div>
                </v-text-field>
              </template>
              <v-date-picker v-model="formData.policyStartDate" no-title>
              </v-date-picker>
            </v-menu>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Policy End Date
            </v-text>
            <v-menu
              ref="calender"
              :close-on-content-click="true"
              :nudge-right="40"
              transition="scale-transition"
              max-width="290px"
              min-width="290px"
              class="rounded-0"
              
            >
              <!-- :rules="typeOfEmployee !== 'Service ID'?[required()]:[()]" -->
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-bind="attrs"
                  label="Select.."
                  :value="computedDate('policyEndDate')"
                  solo
                  class="rounded-0"
                  disabled
                  v-on="on"
                >
                  <div
                    slot="prepend-inner"
                    style="background-color: #f7f7f7; padding: 9px !important"
                  >
                    <v-icon>event</v-icon>
                  </div>
                </v-text-field>
              </template>
              <v-date-picker v-model="formData.policyEndDate" no-title> </v-date-picker>
            </v-menu>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Policy Effective Date
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.policyEffectiveDate"
              maxlength="30"
              counter="30"
              class="rounded-0"
              aria-required="true"
              :rules="[alphaNumeric(policyEffectiveDate, 'Previous Policy Number')]"
              disabled
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Portability Flag
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.portabilityFlag"
              maxlength="10"
              counter="10"
              class="rounded-0"
              aria-required="true"
              :rules="[alphaNumeric(portabilityFlag, 'Portability Flag')]"
              disabled
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Previous Policy Number
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.previousPolicyNumber"
              maxlength="30"
              counter="30"
              class="rounded-0"
              aria-required="true"
              :rules="[alphaNumeric(previousPolicyNumber, 'Previous Policy Number')]"
              disabled
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Previous Insurer
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.previousInsurer"
              maxlength="100"
              counter="100"
              class="rounded-0"
              aria-required="true"
              :rules="[alphaNumeric(previousInsurer, 'Previous Insurer')]"
              disabled
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Previous Sum Insured
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.previousSumInsured"
              maxlength="20"
              counter="20"
              class="rounded-0"
              aria-required="true"
              :rules="[
                alphaNumeric(previousSumInsured, 'Previous Sum Insured'),
              ]"
              disabled
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Previous Effective Start Date
            </v-text>
            <v-menu
              ref="calender"
              :close-on-content-click="true"
              :nudge-right="40"
              transition="scale-transition"
              max-width="290px"
              min-width="290px"
              class="rounded-0"
            >
              <!-- :rules="typeOfEmployee !== 'Service ID'?[required()]:[()]" -->
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-bind="attrs"
                  label="Select.."
                  :value="computedDate('previousEffectiveStartDate')"
                  solo
                  class="rounded-0"
                  disabled
                  v-on="on"
                >
                  <div
                    slot="prepend-inner"
                    style="background-color: #f7f7f7; padding: 9px !important"
                  >
                    <v-icon>event</v-icon>
                  </div>
                </v-text-field>
              </template>
              <v-date-picker v-model="formData.previousEffectiveStartDate" no-title>
              </v-date-picker>
            </v-menu>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Previous Expiry Date
            </v-text>
            <v-menu
              ref="calender"
              :close-on-content-click="true"
              :nudge-right="40"
              transition="scale-transition"
              max-width="290px"
              min-width="290px"
              class="rounded-0"
            >
              <!-- :rules="typeOfEmployee !== 'Service ID'?[required()]:[()]" -->
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-bind="attrs"
                  label="Select.."
                  :value="computedDate('previousExpiryDate')"
                  solo
                  class="rounded-0"
                  disabled
                  v-on="on"
                >
                  <div
                    slot="prepend-inner"
                    style="background-color: #f7f7f7; padding: 9px !important"
                  >
                    <v-icon>event</v-icon>
                  </div>
                </v-text-field>
              </template>
              <v-date-picker v-model="formData.previousExpiryDate" no-title>
              </v-date-picker>
            </v-menu>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Previously covered by any other Mediclaim/Health Insurance?
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.previousCovered"
              maxlength="20"
              counter="20"
              class="rounded-0"
              aria-required="true"
              disabled
              :rules="[
                alphaNumeric(
                  previousCovered,
                  'Previously covered by any other Mediclaim/Health Insurance?'
                ),
              ]"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Member Inception date Portability
            </v-text>
            <v-menu
              ref="calender"
              :close-on-content-click="true"
              :nudge-right="40"
              transition="scale-transition"
              max-width="290px"
              min-width="290px"
              class="rounded-0"
            >
              <!-- :rules="typeOfEmployee !== 'Service ID'?[required()]:[()]" -->
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-bind="attrs"
                  label="Select.."
                  :value="computedDate('memberInceptionDatePortability')"
                  solo
                  class="rounded-0"
                  disabled
                  v-on="on"
                >
                  <div
                    slot="prepend-inner"
                    style="background-color: #f7f7f7; padding: 9px !important"
                  >
                    <v-icon>event</v-icon>
                  </div>
                </v-text-field>
              </template>
              <v-date-picker v-model="formData.memberInceptionDatePortability" no-title>
              </v-date-picker>
            </v-menu>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Name Of Corporate
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.nameOfCorporate"
              maxlength="100"
              counter="100"
              class="rounded-0"
              aria-required="true"
              :rules="[alphaNumeric(nameOfCorporate, 'Name Of Corporate')]"
              disabled
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Employee ID
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.employeeID"
              maxlength="50"
              counter="50"
              class="rounded-0"
              aria-required="true"
              :rules="[alphaNumeric(employeeID, 'Employee ID')]"
              disabled
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Member ID
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.memberId"
              maxlength="50"
              counter="50"
              class="rounded-0"
              aria-required="true"
              :rules="[alphaNumeric(memberId, 'Member ID')]"
              disabled
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Certificate Number
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.certificateNumber"
              maxlength="50"
              counter="50"
              class="rounded-0"
              aria-required="true"
              :rules="[alphaNumeric(certificateNumber, 'Certificate Number')]"
              disabled
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Member Inception Date Tata Aig
            </v-text>
            <v-menu
              ref="calender"
              :close-on-content-click="true"
              :nudge-right="40"
              transition="scale-transition"
              max-width="290px"
              min-width="290px"
              
              class="rounded-0"
            >
              <!-- :rules="typeOfEmployee !== 'Service ID'?[required()]:[()]" -->
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-bind="attrs"
                  label="Select.."
                  :value="computedDate('memberInceptionDateTataAig')"
                  solo
                  class="rounded-0"
                  disabled
                  v-on="on"
                >
                  <div
                    slot="prepend-inner"
                    style="background-color: #f7f7f7; padding: 9px !important"
                  >
                    <v-icon>event</v-icon>
                  </div>
                </v-text-field>
              </template>
              <v-date-picker v-model="formData.memberInceptionDateTataAig" no-title>
              </v-date-picker>
            </v-menu>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Insured Name
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.insuredName"
              maxlength="100"
              counter="100"
              class="rounded-0"
              aria-required="true"
              disabled
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Member Status
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.memberStatus"
              maxlength="50"
              counter="50"
              class="rounded-0"
              aria-required="true"
              :rules="[alphaNumeric(memberStatus, 'Menber Status')]"
              disabled
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Member First Name
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.memberFirstName"
              maxlength="20"
              counter="20"
              class="rounded-0"
              aria-required="true"
              :rules="[alphaNumeric(memberFirstName, 'Member First Name')]"
              disabled
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Member Middle Name
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.memberMiddleName"
              maxlength="20"
              counter="20"
              class="rounded-0"
              aria-required="true"
              disabled
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Member Last Name
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.memberLastName"
              maxlength="20"
              counter="20"
              class="rounded-0"
              aria-required="true"
              disabled
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Member PAN Number
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.memberPanNumber"
              maxlength="10"
              counter="10"
              class="rounded-0"
              aria-required="true"
              disabled
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Member Aadhaar Number
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.memberAadharNumber"
              maxlength="4"
              counter="4"
              class="rounded-0"
              aria-required="true"
              disabled
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Member date Of Birth
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.memberDateOfBirth"
              maxlength="10"
              counter="10"
              class="rounded-0"
              aria-required="true"
              disabled
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Member Age
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.memberAge"
              maxlength="3"
              counter="3"
              class="rounded-0"
              aria-required="true"
              disabled
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Member Gender
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.memberGender"
              maxlength="11"
              counter="11"
              class="rounded-0"
              aria-required="true"
              disabled
              :rules="[alphaNumeric(memberGender, 'Member Gender')]"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Relationship to Primary Insured
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.relationshipToPrimaryInsured"
              maxlength="20"
              counter="20"
              class="rounded-0"
              aria-required="true"
              disabled
              :rules="[
                alphaNumeric(
                  relationshipToPrimaryInsured,
                  'Relationship to Primary Insured'
                ),
              ]"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Member Occupation
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.memberOccupation"
              maxlength="20"
              counter="20"
              class="rounded-0"
              aria-required="true"
              :rules="[alphaNumeric(memberOccupation, 'Member Occupation')]"
              disabled
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Member Joining Date
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.memberJoiningDate"
              class="rounded-0"
              aria-required="true"
              disabled
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Member Leaving Date
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.memberLeavingDate"
              class="rounded-0"
              aria-required="true"
              disabled
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Tata AIG Employee
            </v-text>
            <v-text-field
              label="Enter..."
              v-model="formData.tataAigEmployee"
              :rules="rules.mandatoryrule"
              solo
              class="rounded-0"
              disabled
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Benefit Claimed
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.benefitClaimed"
              maxlength="30"
              counter="30"
              class="rounded-0"
              aria-required="true"
              disabled
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Catastrophe
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.catastrophe"
              maxlength="20"
              counter="20"
              class="rounded-0"
              aria-required="true"
              :rules="[alphaNumeric(catastrophe, 'Catastrophe')]"
              disabled
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Catastrophe Type
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.catastropheType"
              maxlength="20"
              counter="20"
              class="rounded-0"
              aria-required="true"
              :rules="[alphaNumeric(catastropheType, 'Catastrophe Type')]"
              disabled
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <!-- <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Catastrophe Code
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.catastropheCode"
              maxlength="20"
              counter="20"
              class="rounded-0"
              aria-required="true"
              :rules="[alphaNumeric(catastropheCode, 'Catastrophe Code')]"
              disabled
            >
            </v-text-field>
          </v-layout>
        </v-flex> -->
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Insured Address
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.insuredAddress"
              maxlength="100"
              counter="100"
              class="rounded-0"
              aria-required="true"
              disabled
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Insured Landmark
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.insuredLandmark"
              maxlength="100"
              counter="100"
              class="rounded-0"
              aria-required="true"
              :rules="[alphaNumeric(insuredLandmark, 'Insured Landmark')]"
              disabled
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Insured Area
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.insuredArea"
              maxlength="100"
              counter="100"
              class="rounded-0"
              aria-required="true"
              :rules="[alphaNumeric(insuredArea, 'Insured Area')]"
              disabled
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Insured State
            </v-text>
            <v-text-field
              label="Enter..."
              v-model="formData.insuredState"
              solo
              aria-required="true"
              class="rounded-0"
              disabled
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Insured City
            </v-text>
            <v-text-field
              label="Enter..."
              v-model="formData.insuredCity"
              solo
              aria-required="true"
              class="rounded-0"
              disabled
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Insured District
            </v-text>
            <v-text-field
              label="Enter..."
              v-model="formData.insuredDistrict"
              solo
              aria-required="true"
              class="rounded-0"
              disabled
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Insured PIN Code
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.insuredPincode"
              maxlength="6"
              counter="6"
              class="rounded-0"
              aria-required="true"
              :rules="[numericOnly()]"
              disabled
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Insured Mobile Number
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.insuredMobilebNumber"
              maxlength="10"
              counter="10"
              class="rounded-0"
              aria-required="true"
              :rules="[numericOnly()]"
              disabled
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Insured Email ID
            </v-text>
            <v-text-field
              solo
              disabled
              label="Enter..."
              v-model="formData.insuredEmailId"
              maxlength="50"
              counter="50"
              class="rounded-0"
              aria-required="true"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Insured Alternate Address
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.insuredAlternateAddress"
              maxlength="100"
              counter="100"
              class="rounded-0"
              aria-required="true"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Insured Alternate Landmark
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.insuredAlternateLandmark"
              maxlength="100"
              counter="100"
              class="rounded-0"
              aria-required="true"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Insured Alternate Area
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.insuredAlternateArea"
              maxlength="100"
              counter="100"
              class="rounded-0"
              aria-required="true"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Insured Alternate State
            </v-text>
            <v-text-field
              label="Enter..."
              v-model="formData.insuredAlternateState"
              solo
              aria-required="true"
              class="rounded-0"
              disabled
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Insured Alternate City
            </v-text>
            <v-text-field
              label="Enter..."
              v-model="formData.insuredAlternateCity"
              solo
              aria-required="true"
              class="rounded-0"
              disabled
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Insured Alternate District
            </v-text>
            <v-text-field
              label="Enter..."
              v-model="formData.insuredAlternateDistrict"
              solo
              aria-required="true"
              class="rounded-0"
              disabled
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Insured Alternate PIN Code
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.insuredAlternatePincode"
              maxlength="6"
              counter="6"
              class="rounded-0"
              aria-required="true"
              :rules="[numericOnly(formData.insuredAlternatePincode)]"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Insured Alternate Email 1
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.insuredAlternateEmail1"
              maxlength="50"
              counter="50"
              class="rounded-0"
              aria-required="true"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Insured Alternate Email 2
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.insuredAlternateEmail2"
              maxlength="50"
              counter="50"
              class="rounded-0"
              aria-required="true"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Insured Alternate Mobile Number 1
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.insuredAlternateMobileNumber1"
              maxlength="10"
              counter="10"
              class="rounded-0"
              aria-required="true"
              :rules="[numericOnly()]"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Insured Alternate Mobile Number 2
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.insuredAlternateMobileNumber2"
              maxlength="10"
              counter="10"
              class="rounded-0"
              aria-required="true"
              :rules="[numericOnly()]"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Nominee Name
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.nomineeName"
              maxlength="30"
              counter="30"
              class="rounded-0"
              aria-required="true"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Loading Reason
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.loadingReason"
              maxlength="200"
              counter="200"
              class="rounded-0"
              aria-required="true"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Coverages
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.coverages"
              maxlength="50"
              counter="50"
              class="rounded-0"
              aria-required="true"
              disabled
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Producer Code
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.producerCode"
              maxlength="50"
              counter="50"
              class="rounded-0"
              aria-required="true"
              disabled
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Producer Name
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.producerName"
              maxlength="50"
              counter="50"
              class="rounded-0"
              aria-required="true"
              disabled
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              No. of years of Renewal
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.yearsRenewalCount"
              maxlength="3"
              counter="3"
              class="rounded-0"
              disabled
              aria-required="true"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Sum Insured
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.sumInsured"
              maxlength="20"
              counter="20"
              class="rounded-0"
              aria-required="true"
              disabled
            >
            </v-text-field>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-container>
    <InvestigationFooterCard :tabno="sno" :formdata="getFormData" :removeSaveButton="true"/>
  </div>
</template>
<script>
import InvestigationFooterCard from './InvestigationFooterCard.vue';
import { getPolicyDetails, getClaimDetails, getClaimDetailsId } from '../apiservices/formData';
import moment from "moment";

export default {
  name: 'PolicyDetails', 
  data() {
    return {
      sno: 2,
      rules: {
          mandatoryrule: [(v) => !!v || this.validationmessage],
      },
      lossStatesList: ["A"],
      validationmessage: "This field is required",
      formData: {
        productName: "",
        policyNumber: "",
        lossState: "",
        policyStatus: "",
        policyType: "",
        policyStartDate: "",
        policyEndDate: "",
        policyEffectiveDate: "", // Anurag
        portabilityFlag: "",// Anurag
        previousPolicyNumber: "",// Anurag
        previousInsurer: "",
        previousSumInsured: "",
        previousEffectiveStartDate: "",
        previousExpiryDate: "",
        previousCovered: "",
        memberInceptionDatePortability: "",
        nameOfCorporate: "",
        employeeID: "",
        memberId: "",
        proposerName: "",
        premiumRecieptDate: "",
        premiumCollectionMode: "",
        policyDateOfRealization: "",
        certificateNumber: "",
        memberInceptionDateTataAig: "",
        insuredName: "",
        memberStatus: "",
        memberFirstName: "",
        memberMiddleName: "",
        memberLastName: "",
        memberPanNumber: "",
        memberAadharNumber: "",
        memberDateOfBirth: "",
        memberAge: "",
        memberGender: "",
        relationshipToPrimaryInsured: "",
        memberOccupation: "",
        memberJoiningDate: "",
        memberLeavingDate: "",
        tataAigEmployee: "",
        benefitClaimed: "",
        catastrophe: "",
        catastropheType: "",
        catastropheCode: "",
        insuredAddress: "",
        insuredLandmark: "",
        insuredArea: "",
        insuredState: "",
        insuredCity: "",
        insuredDistrict: "",
        insuredPincode: "",
        insuredMobilebNumber: "",
        insuredEmailId: "",
        insuredAlternateAddress: "",
        insuredAlternateLandmark: "",
        insuredAlternateArea: "",
        insuredAlternateState: "",
        insuredAlternateCity: "",
        insuredAlternateDistrict: "",
        insuredAlternatePincode: "",
        insuredAlternateEmail1: "",
        insuredAlternateEmail2: "",
        insuredAlternateMobileNumber1: "",
        insuredAlternateMobileNumber2: "",
        nomineeName: "",
        loadingReason: "",
        coverages: "",
        portabilityFlag: '',
        producerCode: "",
        producerName: "",
        yearsRenewalCount: "",
        sumInsured: "",
      },
    };
  },
  components: {
    InvestigationFooterCard 
  },
  created() {
  },
  computed: {
    getFormData() {
      return this.$data;
    },
  },
  async mounted() {
    try {
      let params = this.$route.params.allData;
      let res = await getPolicyDetails(this.$route.params.claimNumber, this.$route.params.memberId, this.$route.params.policyNumber, this.$route.params.featureNumber);
      res =  res.data[0];
      Object.keys(this.$data.formData).forEach(item => {
        this.formData[item] = res[item];
      })
      let data = await getClaimDetails(this.$route.params.claimNumber, this.$route.params.memberId, this.$route.params.policyNumber, this.$route.params.featureNumber);
      data = data.data[0];
      Object.keys(this.$data.formData).forEach(item => {
        this.formData[item] = data[item];
      })
      let res2 = await getClaimDetailsId(this.$route.params.claimId);
      this.formData.policyStartDate = res2.data.claimsDetail.policyStartDate;
      this.formData.coverages = res.planType ? res.planType : '';
      this.formData.insuredName = res2.data.claimsDetail.proposerName ?? '';
      this.formData.memberFirstName = res2.data.claimsDetail.firstName ?? '';
      this.formData.memberMiddleName = res2.data.claimsDetail.middleName ?? '';
      this.formData.memberLastName = res2.data.claimsDetail.surname ?? '';
      this.formData.memberPanNumber = res2.data.claimsDetail.panNumber ?? '';
      this.formData.memberStatus = res2.data.claimsDetail.memberStatus ?? '';
      this.formData.memberGender = res2.data.claimsDetail.gender ?? '';
      this.formData.memberOccupation = res2.data.claimsDetail.occupation ?? '';
      this.formData.benefitClaimed = res2.data.patientAdmissionDetails?.benefitClaimed ?? '';
      this.formData.catastrophe = res2.data.patientAdmissionDetails?.catestrophe ?? '';
      this.formData.insuredAddress = res2.data.claimsDetail.address ?? '';
      this.formData.insuredLandmark = res2.data.claimsDetail.landmark ?? '';
      this.formData.insuredArea = res2.data.claimsDetail.area ?? '';
      this.formData.insuredState = res2.data.claimsDetail.state ?? '';
      this.formData.insuredCity = res2.data.claimsDetail.cityTownVillage ?? '';
      this.formData.insuredDistrict = res2.data.claimsDetail.district ?? '';
      this.formData.insuredPincode = res2.data.claimsDetail.pinCode ?? '';
      this.formData.insuredMobilebNumber = res2.data.claimsDetail.mobileNumber ?? '';
      this.formData.insuredEmailId = res2.data.claimsDetail.email ?? '';
      this.formData.insuredAlternateAddress = res2.data.claimsDetail.alternateAddress ?? '';
      this.formData.insuredAlternateArea = res2.data.claimsDetail.alternateArea ?? '';
      this.formData.insuredAlternateLandmark = res2.data.claimsDetail.alternateLandmark ?? '';
      this.formData.insuredAlternateState = res2.data.claimsDetail.alternateState ?? '';
      this.formData.insuredAlternateCity = res2.data.claimsDetail.alternateCityTownVillage ?? '';
      this.formData.insuredAlternateDistrict = res2.data.claimsDetail.alternateDistrict ?? '';
      this.formData.insuredAlternateEmail1 = res2.data.claimsDetail.alternateEmailOne ?? '';
      this.formData.insuredAlternateEmail2 = res2.data.claimsDetail.alternateEmailTwo ?? '';
      this.formData.insuredAlternateMobileNumber1 = res2.data.claimsDetail.alternateMobileNumberOne ?? '';
      this.formData.insuredAlternateMobileNumber2 = res2.data.claimsDetail.alternateMobileNumberTwo ?? '';
      this.formData.coverages = res2.data.claimsDetail.coverageName ?? '';
      this.formData.producerCode = res2.data.claimsDetail.producerCode ?? '';
      this.formData.producerName = res2.data.claimsDetail.producerName ?? '';
      this.formData.yearsRenewalCount = res2.data.claimsDetail.years ?? '';
      this.formData.portabilityFlag = res2.data.claimsDetail.portableFlag ? 'Yes' : 'No';
      this.formData.memberJoiningDate = res2.data.claimsDetail.joiningDate ? moment(res2.data.claimsDetail.joiningDate).format("DD/MM/YYYY") : '';
      this.formData.memberLeavingDate = res2.data.claimsDetail.leavingDate ? moment(res2.data.claimsDetail.leavingDate).format("DD/MM/YYYY") : '';
      this.formData.memberDateOfBirth = res2.data.claimsDetail.dateOfBirth ? moment(res2.data.claimsDetail.dateOfBirth).format("DD/MM/YYYY") : '';
      this.formData.firstPolicyInceptionDate = res2.data.claimsDetail.policyInceptionDate ? moment(res2.data.claimsDetail.policyInceptionDate).format("DD/MM/YYYY") : '';
      this.formData.policyEffectiveDate = res2.data.claimsDetail.policyStartDate ? moment(res2.data.claimsDetail.policyStartDate).format("DD/MM/YYYY") : '';
      // const claimDetails = await getFormData(`${CLAIMS_INTIMATION}claims-details/get-claim-registration-detail/${params.claimId}`);
      // this.claimDetails = claimDetails;
      // this.formData.benefitClaimed = claimDetails.data.data.patientAdmissionDetails.benefitClaimed ?  claimDetails.data.data.patientAdmissionDetails.benefitClaimed : '';
      // this.formData.producerName = claimDetails.data.data.claimsDetail.producerName ? claimDetails.data.data.claimsDetail.producerName : '';
    } catch(e) {
      this.showToast(e.response?.data?.msg || e.response?.data  || e.response || e, this.TOST().ERROR);
    }
  },
  methods: {
    computedDate(name) {
      return this.formData[name] ? moment(this.formData[name]).format("DD/MM/YYYY") : "";
    },
    goToPreviousTab() {
      this.sno--;
      let btnNumber = "myBtn" + this.sno;
      const elem = this.$refs[btnNumber];
      elem[0].$el.click();
    },
    goToNextTab() {
      this.sno++;
      let btnNumber = "myBtn" + this.sno;
      const elem = this.$refs[btnNumber];
      elem[0].$el.click();
    },
    switchTabs(sno, name) {
      console.log('sno', sno, name);
      this.sno = sno;
      this.tabName = name;
    },
    submit() {
      this.isAlertView = true;
    },
    accept() {
      this.isAlertView = true;
    },
    saveData() {},
    alphaNumeric(fieldName, name, mandatory=true) {
      if (fieldName?.length > 0) {
        let x = /^[a-zA-Z0-9]+$/.test(v);
        console.log('test is:', x);
        return (v) => /^[a-zA-Z0-9]+$/.test(v) || "Enter valid " + name;
      }
    },
    numericOnly(val) {
      if(val){
        return (v) => /^[0-9 ]+$/.test(v) || `Only Numbers are allowed.`;
      }
    },
    onSubmit() {},
    browse() {},
    selectFile() {},
  },
};
</script>
