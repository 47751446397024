import Vue from 'vue'
import Vuex from 'vuex'
import state from './state'
import * as actions from './actions'
import * as mutations from './mutation'
import getters from './getters'

Vue.use(Vuex);


const loadState = () => {
  try {
  
    const serializedState = localStorage.getItem('vue_state');

    if (serializedState === null) {
      return undefined;
    }

   let checksession = check_session()
   let localdata = JSON.parse(serializedState);
    if(checksession.is_session){
     let sessiondata = JSON.parse(checksession.sessiondata);
     localdata = {...localdata, ...sessiondata }
    }else{
      // need to be implemented on else
      console.log(" Session data was not found ")
    }
    console.log("final data (localdata|sessiondata) --->",localdata);
    return localdata
  } catch (err) {
    return undefined;
  }
};

const check_session = function(){

  try{
    const sessiontoken = sessionStorage.getItem('vue_state');
      if(sessiontoken != null || sessiontoken != undefined){
        return { sessiondata:sessiontoken, is_session:true }
      }
  } catch (error) {
    console.log("An Error has occurred while accessing Session Token ", error);
    return { sessiondata:sessiontoken, is_session:false }    
  }

}

const saveState = state => {
  try {

    var sessiontoken = { token: state.token }
    sessiontoken =  JSON.stringify(sessiontoken);

      console.log(' checking ST after STFY ', sessiontoken);

    sessionStorage.setItem('vue_state', sessiontoken);

    const serializedState = JSON.stringify(state);
    console.log(' checking STATE after STFY ', serializedState);
    localStorage.setItem('vue_state', serializedState);
 
  

  } catch (err) {
    console.log(`Something went wrong: ${err}`);
    //localStorage.setItem('vue_state', serializedState);
  }
}

const store = new Vuex.Store({
  state: loadState() || state,
  mutations: mutations,
  actions: actions,
  getters: getters
})
// .watch(state => {

//   saveState(state);
// });

store.watch(
  (state) => {
      console.log('from store watcher', state);
      if(state.links.fullPath !== '/login'){
          saveState(state);
      }else{
        saveState(state);
      }
     }
 )    

// store.watch(

  // shifted to else as no conditions are asked for saving state | marine will use from else | health will use from if block

//   (state) => {
//       console.log("Watch Store Data=====>>>>>>>>", state);
//       saveState(state);
//   }
// )

export default store