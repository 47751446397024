<template>
  <div>
    <v-container fluid>
    <v-form ref="form">
      <v-layout row>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Type of Admission <span>*</span>
            </v-text>
            <v-text-field
              :items="admissionList"
              label="Select"
              v-model="formData.typeofAdmission"
              solo
              readonly
              item-text="state"
              item-value="state"
              dense
              class="rounded-0"
              :disabled = true
            />
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              In-Patient Registration Number <span>*</span>
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.inPatientRegNumber"
              maxlength="50"
              counter="50"
              class="rounded-0"
              aria-required="true"
              :disabled = true 
            >
            <!--:rules="[
                alphaNumeric(
                  formData.inPatientRegNumber,
                  'In-Patient Registration Number'
                ),
              ]" -->
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Whether Accident Claim<span>*</span>
            </v-text>
            <v-select
              :items="isAccidentClaimOptions"
              label="Select"
              v-model="formData.isAccidentClaim"
              solo
              item-text="text"
              item-value="value"
              dense
              class="rounded-0"
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Date of Admission<span>*</span>
            </v-text>
            <v-menu
              ref="calender"
              :close-on-content-click="true"
              :nudge-right="40"
              transition="scale-transition"
              max-width="290px"
              min-width="290px"
              class="rounded-0"
            >
              <!-- :rules="typeOfEmployee !== 'Service ID'?[required()]:[()]" -->
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-bind="attrs"
                  label="Select.."
                  :value="computedDate('dateofAdmission')"
                  solo
                  class="rounded-0"
                  v-on="on"
                  :disabled = true
                >
                  <div
                    slot="prepend-inner"
                    style="background-color: #f7f7f7; padding: 9px !important"
                  >
                    <v-icon>event</v-icon>
                  </div>
                </v-text-field>
              </template>
              <v-date-picker v-model="formData.dateofAdmission" no-title>
              </v-date-picker>
            </v-menu>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Time of Admission<span>*</span>
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.timeofAdmission"
              maxlength="20"
              readonly
              counter="20"
              class="rounded-0"
              aria-required="true"
              :disabled = true
              type="time"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Date of Discharge<span>*</span>
            </v-text>
            <v-menu
              ref="calender"
              :close-on-content-click="true"
              :nudge-right="40"
              transition="scale-transition"
              max-width="290px"
              min-width="290px"
              class="rounded-0"
            >
              <!-- :rules="typeOfEmployee !== 'Service ID'?[required()]:[()]" -->
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-bind="attrs"
                  label="Select.."
                  :value="computedDate('dateofDischarge')"
                  solo
                  class="rounded-0"
                  v-on="on"
                  :disabled = true
                >
                  <div
                    slot="prepend-inner"
                    style="background-color: #f7f7f7; padding: 9px !important"
                  >
                    <v-icon>event</v-icon>
                  </div>
                </v-text-field>
              </template>
              <v-date-picker v-model="formData.dateofDischarge" no-title>
              </v-date-picker>
            </v-menu>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Time of Discharge<span>*</span>
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.timeofDischarge"
              maxlength="20"
              counter="20"
              class="rounded-0"
              aria-required="true"
              :disabled = true
              type="time"
            >
            <!-- :rules="[alphaNumeric(formData.timeofDischarge, 'Time of Discharge')]" -->
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Length of Stay<span>*</span>
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.lengthofStay"
              maxlength="5"
              counter="5"
              class="rounded-0"
              aria-required="true"
              :disabled = true
            >
            <!-- :rules="[numericOnly()]" -->
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              No of Days in Normal<span>*</span>
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.noofDaysinNormal"
              maxlength="5"
              counter="5"
              class="rounded-0"
              aria-required="true"  
              :disabled =true      
            >
            <!-- :rules="[numericOnly()]" -->
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              No of Days in ICU<span>*</span>
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.noofDaysinICU"
              maxlength="5"
              counter="5"
              class="rounded-0"
              aria-required="true"
              :disabled = true 
            >
            <!-- :rules="[numericOnly()]" -->
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Date of Death<span>*</span>
            </v-text>
            <v-menu
              ref="calender"
              :close-on-content-click="true"
              :nudge-right="40"
              transition="scale-transition"
              max-width="290px"
              min-width="290px"
              class="rounded-0"
            >
              <!-- :rules="typeOfEmployee !== 'Service ID'?[required()]:[()]" -->
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-bind="attrs"
                  label="Select.."
                  :value="computedDate('dateOfDeath')"
                  solo
                  class="rounded-0"
                  v-on="on"
                  :disabled = true 
                >
                  <div
                    slot="prepend-inner"
                    style="background-color: #f7f7f7; padding: 9px !important"
                  >
                    <v-icon>event</v-icon>
                  </div>
                </v-text-field>
              </template>
              <v-date-picker v-model="formData.dateOfDeath" no-title> </v-date-picker>
            </v-menu>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Hospital Admission Status<span>*</span>
            </v-text>
            <v-select
            :items="['Admitted','Not admitted','Planned admission']"
              solo
              label="Select"
              v-model="formData.hospitalAdmissionStatus"
              maxlength="50"
              counter="50"
              class="rounded-0"
              aria-required="true"
              :rules="rules.mandatoryrule"
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Status at Discharge<span>*</span>
            </v-text>
            <v-text-field
              :items="statusOfDischargeOption"
              label="Select"
              v-model="formData.statusAtDischarge"
              readonly
              solo
              item-text="state"
              item-value="state"
              dense
              class="rounded-0"
              :disabled = true 
            />
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Room Category as per Claim Form<span>*</span>
            </v-text>
       
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.roomCategoryAsPerClaimForm"
              maxlength="50"
              counter="50"
              class="rounded-0"
              aria-required="true"
              disabled= true
            >
            </v-text-field>
            <!--      :rules="[
                alphaNumeric(
                  formData.roomCategoryAsPerClaimForm,
                  'Room Category as per Claim Form'
                ),
              ]" -->
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Room Category is same as per Claim Form <span>*</span>
            </v-text>
            <v-select
              :items="['Yes', 'No']"
              label="Select"
              v-model="formData.roomCategoryisSameAsperClaimForm"
              :rules="rules.mandatoryrule"
              solo
              item-text="state"
              item-value="state"
              dense
              class="rounded-0"
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Treating Doctor Name<span>*</span>
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.treatingDoctorDetails.treatingDoctorName"
              maxlength="100"
              counter="100"
              class="rounded-0"
              aria-required="true"
              disabled= true
            >
            <!--:rules="[
                alphaNumeric(formData.treatingDoctor.firstName, 'Treating Doctor Name'),
              ]" -->
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Treating Doctor PRN
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.treatingDoctorDetails.treatingDoctorPRN"
              maxlength="10"
              counter="10"
              class="rounded-0"
              aria-required="true"
              disabled= true
            >
            <!--  :rules="[alphaNumeric(formData.prnDoctor, 'Treating Doctor PRN', false)]" -->
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Treating Doctor Registration Number<span>*</span>
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.treatingDoctorDetails.treatingDoctorRegistrationNo"
              maxlength="50"
              counter="50"
              class="rounded-0"
              aria-required="true"
              disabled= true
            >
            <!--:rules="[
                alphaNumeric(
                  formData.treatingDoctor.registrationNumber,
                  'Treating Doctor Registration Number'
                ),
              ]" -->
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Treating Doctor Qualification
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.treatingDoctorDetails.treatingDoctorQualification"
              maxlength="20"
              counter="20"
              class="rounded-0"
              aria-required="true"
              disabled= true
            >
            <!--:rules="[
                alphaNumeric(
                  formData.qualification,
                  'Treating Doctor Qualification',
                  false
                ),
              ]" -->
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Treating Doctor Registered Council
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.treatingDoctorDetails.treatingDoctorRegisteredCouncil"
              maxlength="20"
              counter="20"
              class="rounded-0"
              aria-required="true"
              disabled= true
            >
            <!--:rules="[
                alphaNumeric(
                  formData.registeredCouncil,
                  'Treating Doctor Registered Council',
                  false
                ),
              ]" -->
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Treating Doctor Mobile Number<span>*</span>
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.treatingDoctorDetails.treatingDoctorMbNumber"
              maxlength="20"
              counter="20"
              class="rounded-0"
              aria-required="true"
              disabled= true
            >
            <!--:rules="[numericOnly()]" -->
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Treating Doctor Statement Findings<span>*</span>
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.treatingDoctorStatementFindings"
              maxlength="2000"
              counter="2000"
              class="rounded-0"
              aria-required="true"
              :rules="rules.mandatoryrule"
            >
                :rules="[
                alphaNumeric(
                  formData.treatingDoctorStatementFindings,
                  'Treating Doctor Statement Findings'
                ),
              ]" remove validation rule, it is free text as per sheet 
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Hospitalization Due to (Injury/Illiness/Maternity)<span>*</span>
            </v-text>
            <v-select
              :items="['Injury','Illiness','Maternity']"
              solo
              label="Enter..."
              v-model="formData.hospitalizationDueto"
              maxlength="20"
              counter="20"
              class="rounded-0"
              aria-required="true"
              disabled = true
            >
            <!-- :rules="[
                alphaNumeric(
                  formData.hospitalizationDueto,
                  'Hospitalization Due to (Injury/Illiness/Maternity)'
                ),
              ]" -->
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Illness<span>*</span>
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.ilness"
              maxlength="20"
              counter="20"
              class="rounded-0"
              aria-required="true"
              disabled = true
            >
            <!--    :rules="[alphaNumeric(formData.illness, 'Illness')]" -->
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Illness Description<span>*</span>
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.illnessDescription"
              maxlength="20"
              counter="20"
              class="rounded-0"
              aria-required="true"
              disabled = true
              
            >
            <!-- :rules="[alphaNumeric(formData.illnessDescription, 'Illness Description')]" -->
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              System Of Medicine<span>*</span>
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.systemofMedicine"
              maxlength="20"
              counter="20"
              class="rounded-0"
              aria-required="true"
              disabled = true
            >
            <!-- :rules="[alphaNumeric(formData.systemofMedicine, 'System Of Medicine')]" -->
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              ICD Code<span>*</span>
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.iCDCode"
              maxlength="20"
              counter="20"
              class="rounded-0"
              aria-required="true"
              disabled = true
            >
            <!-- :rules="[alphaNumeric(formData.iCDCode, 'ICD Code')]" -->
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Diagnosis<span>*</span>
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.diagnosis"
              maxlength="100"
              counter="100"
              class="rounded-0"
              aria-required="true"
              disabled = true
            >
            <!-- :rules="[alphaNumeric(formData.diagnosis, 'Diagnosis')]" -->
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Route of Drug Administration<span>*</span>
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.routeofDrugAdministration"
              maxlength="20"
              counter="20"
              class="rounded-0"
              aria-required="true"
              disabled = true
            >
            <!--   :rules="[
                alphaNumeric(formData.
                  routeForDrugAdministration,
                  'Route of Drug Administration'
                ),
              ]" -->
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Details Of Procedure<span>*</span>
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.detailsofProcedure"
              maxlength="200"
              counter="200"
              class="rounded-0"
              aria-required="true"
              disabled = true
            >
            <!--  :rules="[
                alphaNumeric(formData.detailsofProcedure, 'Details Of Procedure'),
              ]" -->
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Line of Treatment as per claim form<span>*</span>
            </v-text>
            <v-select
              solo
              label="Select"
              :items="['Medical','Surgical','Both']"
              v-model="formData.lineofTreatmentasperClaimform"
              maxlength="50"
              counter="50"
              class="rounded-0"
              aria-required="true"
              disabled = true
            >
            <!--    :rules="[
                alphaNumeric(formData.
                  lineOfTreatmentAsPerClaimForm,
                  'Line of Treatment as per claim form'
                ),
              ]" -->
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Line of Treatment as per actual<span>*</span>
            </v-text>
            <v-select
              solo
              label="Select"
              :items="lineOfTreatmentAsPerActualOptions"
              v-model="formData.lineofTreatmentasperActual"
              class="rounded-0"
              aria-required="true"
              :rules="rules.mandatoryrule"
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Line of treatment is same as per claim form<span>*</span>
            </v-text>
            <v-select
              :items="['Yes', 'No']"
              label="Select"
              v-model="formData.lineofTreatmentisSameAsperClaimform"
              solo
              item-text="state"
              item-value="state"
              dense
              class="rounded-0"
              :rules="rules.mandatoryrule"
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              First Consulation/Date of Accident<span>*</span>
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.firstConsulationOrDateofAccident"
              maxlength="20"
              counter="20"
              class="rounded-0"
              aria-required="true"
              disabled= true
            >
            <!-- :rules="[
                alphaNumeric(formData.
                  firstConsulation,
                  'First Consulation/Date of Accident'
                ),
              ]" -->
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Date of Disease first detected<span>*</span>
            </v-text>
            <v-menu
              ref="calender"
              :close-on-content-click="true"
              :nudge-right="40"
              transition="scale-transition"
              max-width="290px"
              min-width="290px"
              class="rounded-0"
            >
              <!-- :rules="typeOfEmployee !== 'Service ID'?[required()]:[()]" -->
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-bind="attrs"
                  label="Select.."
                  :value="computedDate('dateofDiseaseFirstDetected')"
                  solo
                  class="rounded-0"
                  v-on="on"
                  disabled= true
                >
                  <div
                    slot="prepend-inner"
                    style="background-color: #f7f7f7; padding: 9px !important"
                  >
                    <v-icon>event</v-icon>
                  </div>
                </v-text-field>
              </template>
              <v-date-picker v-model="formData.dateofDiseaseFirstDetected" no-title> </v-date-picker>
            </v-menu>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Ailment<span>*</span>
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.ailment"
              maxlength="200"
              counter="200"
              class="rounded-0"
              aria-required="true"
              disabled= true
            >
            <!-- :rules="[alphaNumeric(formData.ailment, 'Ailment')]" -->
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Pre-Existing Details as per claim form<span>*</span>
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.preExistingDetailsasperClaimform"
              maxlength="500"
              counter="500"
              class="rounded-0"
              aria-required="true"
              disabled= true
            >
            <!--   :rules="[
                alphaNumeric(formData.
                  preExistingDetailsAsPerClaimForm,
                  'Pre-Existing Details as per claim form'
                ),
              ]" -->
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Pre-Existing Disease as per actual<span>*</span>
            </v-text>
            <v-select
              :items="['Yes', 'No']"
              label="Select"
              v-model="formData.preExistingDiseaseasperActual"
              solo
              item-text="state"
              item-value="state"
              dense
              class="rounded-0"
              :rules="rules.mandatoryrule"
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Pre-Existing Details as per actual<span>*</span>
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.preExistingDetailsAsPerActual"
              maxlength="500"
              counter="500"
              class="rounded-0"
              aria-required="true"
              :rules="rules.mandatoryrule"
            >
               :rules="[
                alphaNumeric(formData.
                  preExistingDetailsAsPerActual,
                  'Pre-Existing Details as per actual'
                ),
              ]"
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Date of Injury<span>*</span>
            </v-text>
            <v-menu
              ref="calender"
              :close-on-content-click="true"
              :nudge-right="40"
              transition="scale-transition"
              max-width="290px"
              min-width="290px"
              class="rounded-0"
            >
              <!-- :rules="typeOfEmployee !== 'Service ID'?[required()]:[()]" -->
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-bind="attrs"
                  label="Select.."
                  :value="computedDate('dateofInjury')"
                  solo
                  class="rounded-0"
                  v-on="on"
                  disabled= true
                >
                  <div
                    slot="prepend-inner"
                    style="background-color: #f7f7f7; padding: 9px !important"
                  >
                    <v-icon>event</v-icon>
                  </div>
                </v-text-field>
              </template>
              <v-date-picker v-model="formData.dateofInjury" no-title> </v-date-picker>
            </v-menu>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Injury Details<span>*</span>
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.injuryDetails"
              maxlength="200"
              counter="200"
              class="rounded-0"
              aria-required="true"
              disabled= true
            >
            <!--  :rules="[alphaNumeric(formData.injuryDetails, 'Injury Details')]" -->
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Cause Of Injury<span>*</span>
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.causeofInjury"
              maxlength="20"
              counter="20"
              class="rounded-0"
              aria-required="true"
              disabled= true
            >
            <!-- :rules="[alphaNumeric(formData.causeofInjury, 'Cause Of Injury')]" -->
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Is Medico Legal done<span>*</span>
            </v-text>
            <v-select
              :items="['Yes', 'No']"
              label="Select"
              v-model="formData.isMedicoLegaldone"
              solo
              item-text="state"
              item-value="state"
              dense
              class="rounded-0"
              disabled= true
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Reported to police<span>*</span>
            </v-text>
            <v-select
              :items="['Yes', 'No']"
              label="Select"
              v-model="formData.reportedtoPolice"
              solo
              item-text="state"
              item-value="state"
              dense
              class="rounded-0"
              disabled= true
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              FIR Number as per claim form<span>*</span>
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.firNumberasperClaimform"
              maxlength="50"
              counter="50"
              class="rounded-0"
              aria-required="true"
              disabled= true
            >
            <!-- :rules="[
                alphaNumeric(formData.
                  firNumberasperClaimform,
                  'FIR Number as per claim form'
                ),
              ]" -->
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Reason, if not reported to police<span>*</span>
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.reasonifNotReportedtoPolice"
              maxlength="100"
              counter="100"
              class="rounded-0"
              aria-required="true"
              disabled= true
            >
            <!-- :rules="[
                alphaNumeric(formData.
                  reasonifNotReportedtoPolice,
                  'Reason, if not reported to police'
                ),
              ]" -->
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              MLC Report & Police FIR Attached<span>*</span>
            </v-text>
            <v-select
              :items="['Yes', 'No']"
              label="Select"
              v-model="formData.mlcDetails.mlcReportAndPoliceFIRAttached"
              solo
              item-text="state"
              item-value="state"
              dense
              class="rounded-0"
              disabled= true
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Whether the Patient was admitted in the hospital
            </v-text>
            <v-select
              :items="['Yes', 'No']"
              label="Select"
              v-model="formData.patientwasadmittedinHospital"
              solo
              item-text="state"
              item-value="state"
              dense
              class="rounded-0"
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Whether the Patient was admitted in more than 1 hospital
            </v-text>
            <v-select
              :items="['Yes', 'No']"
              label="Select"
              v-model="formData.patientwasAdmittedinMoreThanOnehospital"
              solo
              item-text="state"
              item-value="state"
              dense
              class="rounded-0"
              :disabled="formData.patientwasadmittedinHospital === 'No' ? true : false"
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Name of the hospital where the Patient was taken immediately after
              the accident
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.nameoftheHospitalWherethePatientwasTaken"
              maxlength="100"
              counter="100"
              aria-required="true"
              :disabled="formData.patientwasadmittedinHospital === 'No' ?true :false"
            ></v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Whether the Patient was brought dead in that hospital
            </v-text>
            <v-select
              :items="['Yes', 'No']"
              label="Select"
              v-model="formData.isPatientwasBroughtdeadinThatHospital"
              solo
              item-text="state"
              item-value="state"
              dense
              class="rounded-0"
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Name of Doctor as per PMR
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.nameofDoctorAsPerPMR"
              maxlength="100"
              counter="100"
              class="rounded-0"
              aria-required="true"
            >
            <!--  :rules="[
                alphaNumeric(formData.nameofDoctorAsPerPMR, 'Name of Doctor as per PMR'),
                false
              ]" -->
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Doctor Registration no as per PMR
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.doctorRegistrationNoAsPerPMR"
              maxlength="100"
              counter="100"
              class="rounded-0"
              aria-required="true"
            >
            <!--  :rules="[
                alphaNumeric(formData.doctorRegistrationNoAsPerPMR, 'Doctor Registration no as per PMR'),
                false
              ]" -->
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Was MLC prepared immediately on visit of victim
            </v-text>
            <v-select
              :items="['Yes', 'No']"
              label="Select"
              v-model="formData.mlcDetails.wasMLCPreparedImmediatelyOnVisitofVictim"
              solo
              item-text="state"
              item-value="state"
              maxlength="100"
              counter="100"
              dense
              class="rounded-0"
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              MLC Number as per claim form
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.mlcDetails.mlcNumberAsperClaimform"
              maxlength="20"
              counter="20"
              class="rounded-0"
              aria-required="true"
              :disabled="formData.mlcDetails.wasMLCPreparedImmediatelyOnVisitofVictim === 'No' ?true :false" 
            >
            <!-- :rules="[
                alphaNumeric(formData.
                  mlcDetails.mlcNumberAsperClaimform,
                  'If Yes, MLC Number as per claim form',
                  false
                ),
              ]" -->
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Date Of MLC
            </v-text>
            <v-menu
              ref="calender"
              :close-on-content-click="true"
              :nudge-right="40"
              transition="scale-transition"
              max-width="290px"
              min-width="290px"
              class="rounded-0"
            >
              <!-- :rules="typeOfEmployee !== 'Service ID'?[required()]:[()]" -->
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-bind="attrs"
                  label="Select.."
                  :value="computedDate('dateofMLC','mlcDetails')"
                  solo
                  class="rounded-0"
                  v-on="on"
                  :disabled="formData.mlcDetails.wasMLCPreparedImmediatelyOnVisitofVictim === 'No' || formData.mlcDetails.mlcNumberAsperClaimform === 'No' ?true :false"
                >
                  <div
                    slot="prepend-inner"
                    style="background-color: #f7f7f7; padding: 9px !important"
                  >
                    <v-icon>event</v-icon>
                  </div>
                </v-text-field>
              </template>
              <v-date-picker v-model="formData.mlcDetails.dateofMLC" no-title> </v-date-picker>
            </v-menu>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Time of MLC
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              type="time"
              v-model="formData.mlcDetails.timeofMLC"
              maxlength="20"
              counter="20"
              class="rounded-0"
              aria-required="true"
              :disabled="formData.mlcDetails.wasMLCPreparedImmediatelyOnVisitofVictim === 'No' || formData.mlcDetails.mlcNumberAsperClaimform === 'No' ?true :false"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Name of Doctor as per MLC
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.mlcDetails.nameofDoctorAsperMLC"
              maxlength="200"
              counter="200"
              class="rounded-0"
              aria-required="true"
              :disabled="formData.mlcDetails.wasMLCPreparedImmediatelyOnVisitofVictim === 'No' || formData.mlcDetails.mlcNumberAsperClaimform === 'No' ?true :false"
            >
            <!--               :rules="[
                alphaNumeric('nameOfDoctorAsPerMlc', 'Name of Doctor as per MLC'),
                false
              ]" -->
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Doctor registration no as per MLC
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.mlcDetails.doctorRegistrationNoAsperMLC"
              maxlength="200"
              counter="200"
              class="rounded-0"
              aria-required="true"
              :disabled="formData.mlcDetails.wasMLCPreparedImmediatelyOnVisitofVictim === 'No' || formData.mlcDetails.mlcNumberAsperClaimform === 'No' ?true :false"
            >
            <!--  :rules="[
                alphaNumeric('nameOfDoctorAsPerMlc', 'Name of Doctor as per MLC'),
                false
              ]" -->
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Who brought the victim to the hospital
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.whoBroughtVictimHospital"
              maxlength="20"
              counter="20"
              class="rounded-0"
              aria-required="true"
            >
            <!--:rules="[
                alphaNumeric(formData.
                  whoBroughtVictimHospital,
                  'Who brought the victim to the hospital',
                  false
                ),
              ]" -->
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Relationship of the brought person with the victim
            </v-text>
            <v-select
              :items="relationshipOfBroughtPersonWithVictimOption"
              label="Select"
              v-model="formData.relationshipofBroughtPersonWithVictim"
              solo
              item-text="state"
              item-value="state"
              class="rounded-0"
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              How the victim was brought to the hospital
            </v-text>
            <v-select
              :items="howVictimBrougthToHospitalOptions"
              label="Select"
              v-model="formData.howVictimWasBroughtToHospital"
              solo
              item-text="state"
              item-value="state"
              class="rounded-0"
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Cause of accident as per MLC
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.mlcDetails.causeOfAccidentAsPerMLC"
              maxlength="500"
              counter="500"
              class="rounded-0"
              aria-required="true"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Is Alcohol found in MLC<span>*</span>
            </v-text>
            <v-select
              :items="['Yes', 'No']"
              label="Select"
              v-model="formData.mlcDetails.isAlcoholFoundInMLC"
              solo
              item-text="state"
              item-value="state"
              dense
              class="rounded-0"
              :rules="rules.mandatoryrule"
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Injury/Poisoning Self Inflicted<span>*</span>
            </v-text>
            <v-select
              :items="['Yes', 'No']"
              label="Select"
              v-model="formData.injuryOrPoisoningSelfInflicted"
              solo
              item-text="state"
              item-value="state"
              dense
              class="rounded-0"
              :rules="rules.mandatoryrule"
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Date of Post Mortem Examination
            </v-text>
            <v-menu
              ref="calender"
              :close-on-content-click="true"
              :nudge-right="40"
              transition="scale-transition"
              max-width="290px"
              min-width="290px"
              class="rounded-0"
            >
              <!-- :rules="typeOfEmployee !== 'Service ID'?[required()]:[()]" -->
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-bind="attrs"
                  label="Select.."
                  :value="computedDate('dateofPostMortemExamination')"
                  solo
                  class="rounded-0"
                  v-on="on"
                >
                  <div
                    slot="prepend-inner"
                    style="background-color: #f7f7f7; padding: 9px !important"
                  >
                    <v-icon>event</v-icon>
                  </div>
                </v-text-field>
              </template>
              <v-date-picker v-model="formData.dateofPostMortemExamination" no-title>
              </v-date-picker>
            </v-menu>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Hospital where Post Mortem was conducted
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.hospitalWherePostMortemConducted"
              maxlength="100"
              counter="100"
              class="rounded-0"
              aria-required="true"
            ></v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Cause of Death as per Post Mortem
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.causOfDeathAsPerPostMortem"
              maxlength="500"
              counter="500"
              class="rounded-0"
              aria-required="true"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Post Mortem (PM) conducted by
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.postMortemConductedBy"
              maxlength="500"
              counter="500"
              class="rounded-0"
              aria-required="true"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Viscera preserved
            </v-text>
            <v-select
              :items="['Yes', 'No']"
              solo
              label="Select"
              v-model="formData.visceraPreserved"
              class="rounded-0"
              aria-required="true"
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Viscera Result
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.visceraResult"
              maxlength="500"
              counter="500"
              class="rounded-0"
              aria-required="true"
            >
            <!--:rules="[alphaNumeric(formData.visceraResult, 'Viscera Result', false)]" -->
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md4 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Remarks<span>*</span>
            </v-text>
            <v-textarea
              solo
              label="Enter..."
              v-model="formData.remarks"
              maxlength="5000"
              counter="5000"
              rows="2"
              class="rounded-0 "
              aria-required="true"
              :rules="rules.mandatoryrule"
            >
            </v-textarea>
          </v-layout>
        </v-flex>
      </v-layout>
      </v-form>
    </v-container>
    <InvestigationFooterCard :tabno="sno" :formdata="getFormData" 
    ref="footerCardRef"
      @validateTabForms="validateTabForms"
      :componentName="'hospitalizationAndAccidentDetails'"
      :siuWorkItemId="siuWorkItemId"
      :tabFormDataId="tabFormDataId"/>
  </div>
</template>
<script>
import InvestigationFooterCard from './InvestigationFooterCard.vue';
import moment from "moment";
import {getPrePopulatedData} from '../apiservices/Investigation';
import { getClaimDetails, getClaimDetailsId } from '../apiservices/formData';
import axios from 'axios';

export default {
  name: 'hospitalizationAndAccidentDetails', 
  data() {
    return {
      sno: 5,
      numberOfErrors: 0,
      admissionList: ['Planned','Emergency'],
      lineOfTreatmentAsPerActualOptions: ['Surgical','Conservative Medical','Investigation/Diagnosis','Ayurvedic','Homeopathy','Naturopathy','Unani','Physiotherapy','Radiotherapy','Chemotherapy','Dialysis','Other'],
      relationshipOfBroughtPersonWithVictimOption: ['Son','Daughter','Spouse','Father','Mother','Brother','Sister','Mother-in-law','Father-in-law','Grandfather','Grandmother','Unknown','Friend'],
      howVictimBrougthToHospitalOptions:['Self','108','Ambulance','Police','Any Third Person'],
      statusOfDischargeOption:['Discharge to home','Discharge to another hospital','Deceased'],
      validationmessage: "This field is required",
      isAccidentClaimOptions: [{text: "Yes", value: true}, {text: "No", value: false}],
      rules: {
        mandatoryrule: [(v) => !!v || this.validationmessage],
      },
      formData:{
        isTabSelected: true,
        typeofAdmission: "",
        inPatientRegNumber: "",
        isAccidentClaim: false,
        dateofAdmission: "",
        timeofAdmission: "",
        dateofDischarge: "",
        timeofDischarge: "",
        lengthofStay: "",
        noofDaysinNormal: "", // Anurag
        noofDaysinICU: "", // Anurag
        dateOfDeath: "", // Anurag
        hospitalAdmissionStatus: "", // M
        statusAtDischarge: "",
        roomCategoryAsPerClaimForm: "",
        roomCategoryisSameAsperClaimForm: "", //M
        treatingDoctorDetails: {
          treatingDoctorName: "",
          treatingDoctorPRN: "",
          treatingDoctorRegistrationNo: "",
          treatingDoctorQualification: "",
          treatingDoctorRegisteredCouncil: "",
          treatingDoctorMbNumber: ""
        },
        treatingDoctorStatementFindings: "",//M
        hospitalizationDueto: "",
        ilness: "",
        illnessDescription: "",//Anurag
        systemofMedicine: "",//Anurag
        iCDCode: "",//Anurag
        diagnosis: "",//Anurag
        routeofDrugAdministration: "",//Anurag
        detailsofProcedure: "",//Anurag
        lineofTreatmentasperClaimform: "",//Anurag
        lineofTreatmentasperActual: "",// M
        lineofTreatmentisSameAsperClaimform: "",//M
        firstConsulationOrDateofAccident: "",
        dateofDiseaseFirstDetected: "",
        ailment: "",
        preExistingDetailsasperClaimform: "",
        preExistingDiseaseasperActual: "",
        preExistingDetailsAsPerActual: "",
        dateofInjury: "",
        injuryDetails: "",
        causeofInjury: "",
        isMedicoLegaldone: "",
        reportedtoPolice: "",
        firNumberasperClaimform: "",
        reasonifNotReportedtoPolice: "",
        patientwasadmittedinHospital: "",
        patientwasAdmittedinMoreThanOnehospital: "",
        nameoftheHospitalWherethePatientwasTaken: "",
        isPatientwasBroughtdeadinThatHospital: "",
        nameofDoctorAsPerPMR: "",
        doctorRegistrationNoAsPerPMR:"",
        mlcDetails:{
          wasMLCPreparedImmediatelyOnVisitofVictim: '',
          mlcNumberAsperClaimform:'',
          dateofMLC: '',
          timeofMLC:'',
          nameofDoctorAsperMLC:'',
          doctorRegistrationNoAsperMLC:'',
          causeOfAccidentAsPerMLC:'',
          isAlcoholFoundInMLC:'',
          mlcReportAndPoliceFIRAttached:'',
        },
        whoBroughtVictimHospital: "",
        relationshipofBroughtPersonWithVictim: "",//
        howVictimWasBroughtToHospital: "",//
        injuryOrPoisoningSelfInflicted: "",
        dateofPostMortemExamination: "",
        hospitalWherePostMortemConducted: "",
        causOfDeathAsPerPostMortem: "",
        postMortemConductedBy: "",
        visceraPreserved: "",
        visceraResult: "",
        remarks: "",
      },
      required() {
        return (v) => (v && v.length > 0) || "This Field is Required";
      },
    };
  },
  props: ['prepoulatedData', "siuWorkItemId", "tabFormDataId"],
  // async created() {
  //   try{
  //         let res = await getClaimDetails(this.$route.params.claimNumber, this.$route.params.memberId, this.$route.params.policyNumber, this.$route.params.featureNumber);
  //         res = res.data[0];
  //           Object.keys(this.$data.hospitalDetails).forEach(item => {
  //             this.hospitalDetails[item] = res[item];
  //           });
  //       } catch(e){
  //         this.showToast(e.response?.data?.msg || e.response?.data  || e.response || e, this.TOST().ERROR);
  //       }
  // },
  components: {
      InvestigationFooterCard 
  },
    created() {
      this.getDetails();
      // let allKeys = Object.keys(this.prepoulatedData);
      // allKeys.forEach(key => {
      //   this[key] = this.prepoulatedData[key]
      // })
    },
  computed: {
    // computedDate(name) {
    //   return this[name] ? moment(this[name]).format("DD/MM/YYYY") : "";
    // },
    emailRules2(v) {
      if (v.length !== 0) {
        console.log("testing", /^[0-9 ]+$/.test(v));
        if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,6})+$/.test(v)) {
          return "E-mail must be valid";
        }
      }
    },
    getFormData() {
      let payload = {}
      payload = {'hospitalizationAndAccidentDetails' : {...this.formData}}
      payload['siuWorkItemId'] = this.siuWorkItemId
      payload.hospitalizationAndAccidentDetails.timeofAdmission = payload.hospitalizationAndAccidentDetails.timeofAdmission ? this.convertHoursIntoMilliseconds(payload.hospitalizationAndAccidentDetails.timeofAdmission) : ''
      payload.hospitalizationAndAccidentDetails.timeofDischarge = payload.hospitalizationAndAccidentDetails.timeofDischarge ? this.convertHoursIntoMilliseconds(payload.hospitalizationAndAccidentDetails.timeofDischarge) : ''
      payload.hospitalizationAndAccidentDetails.mlcDetails.timeofMLC =  payload.hospitalizationAndAccidentDetails.mlcDetails.timeofMLC ? this.convertHoursIntoMilliseconds( payload.hospitalizationAndAccidentDetails.mlcDetails.timeofMLC) : ''
      return payload;
    },
  },
  mounted() {
  },
  methods: {
    async getDetails() {
      try{
        let res = await getClaimDetailsId(this.$route.params.claimId);
        
         this.formData.hospitalizationDueto = res?.data?.hospitalizationDetailsModel?.hospitalizationDueTo;
         this.formData.lengthofStay = res?.data?.hospitalizationDetailsModel?.lengthOfStay;
         this.formData.inPatientRegNumber = res?.data?.hospitalizationDetailsModel?.ipRegistrationNumber;
         this.formData.typeofAdmission = res?.data?.hospitalizationDetailsModel?.typeOfAdmission;
         this.formData.dateofAdmission = res?.data?.hospitalizationDetailsModel?.createdAt;
         this.formData.timeofAdmission = res?.data?.hospitalizationDetailsModel?.dateOfAdmissionLossTime;
         this.formData.dateofDischarge = res?.data?.hospitalizationDetailsModel?.dateOfDischarge;
         this.formData.timeofDischarge = res?.data?.hospitalizationDetailsModel?.dateOfDischargeTime;
         this.formData.statusAtDischarge = res?.data?.hospitalizationDetailsModel?.statusOfDischarge;
         this.formData.roomCategoryAsPerClaimForm = res?.data?.hospitalizationDetailsModel?.roomCategoryOccupied;
         this.formData.ilness = res?.data.hospitalizationDetailsModel?.illnessDetails.alignment;
         this.formData.systemofMedicine = res?.data.hospitalizationDetailsModel?.systemOfMedicine;
         this.formData.iCDCode = res?.data?.diseaseProcedureClasification?.primaryDiagnosis?.icdCode;
         this.formData.diagnosis = res?.data?.diseaseProcedureClasification?.primaryDiagnosis?.diagnosisName;
         this.formData.lineofTreatmentasperActual = res?.data?.diseaseProcedureClasification?.primaryDiagnosis?.lineOfTreatment;
         this.formData.detailsofProcedure = res?.data?.diseaseProcedureClasification?.detailsOfProcedure;
         this.formData.routeofDrugAdministration = res?.data?.diseaseProcedureClasification?.primaryDiagnosis?.routeOfDrugAdministration;
         this.formData.dateofInjury = res?.data?.hospitalizationDetailsModel?.injuryDetails.dateOfInjury;
         this.formData.injuryDetails = res?.data?.hospitalizationDetailsModel?.injuryDetails?.injuryDetails;
         this.formData.causeofInjury = res?.data?.hospitalizationDetailsModel?.injuryDetails?.injuryCause;
         this.formData.isMedicoLegaldone = res?.data?.hospitalizationDetailsModel?.injuryDetails?.roadTrafficAccidentDetails?.isMedicaLegal;
         this.formData.reportedtoPolice = res?.data?.hospitalizationDetailsModel?.injuryDetails?.roadTrafficAccidentDetails?.reportedToPolice;
         this.formData.firNumberasperClaimform = res?.data?.hospitalizationDetailsModel?.injuryDetails?.roadTrafficAccidentDetails?.firNumber;
         this.formData.mlcDetails.mlcReportAndPoliceFIRAttached = res?.data?.hospitalizationDetailsModel?.injuryDetails?.roadTrafficAccidentDetails?.mlcReportAndPoliceFIRAttached;
         this.formData.treatingDoctorDetails.treatingDoctorName = res?.data?.providerDetails?.treatingDoctor?.firstName + ' ' + res?.data?.providerDetails?.treatingDoctor?.middleName + ' ' + res?.data?.providerDetails?.treatingDoctor?.lastName;
         this.formData.treatingDoctorDetails.treatingDoctorRegistrationNo = res?.data?.providerDetails?.treatingDoctor?.registrationNumber;
         this.formData.treatingDoctorDetails.treatingDoctorPRN = res?.data?.providerDetails?.treatingDoctor?.prnDoctor;
         this.formData.treatingDoctorDetails.treatingDoctorQualification = res?.data?.providerDetails?.treatingDoctor?.qualification || res?.data?.providerDetails?.treatingDoctor?.qulificationOther;
         this.formData.treatingDoctorDetails.treatingDoctorRegisteredCouncil = res?.data?.providerDetails?.treatingDoctor?.registeredCouncil;
         this.formData.treatingDoctorDetails.treatingDoctorMbNumber = res?.data?.providerDetails?.treatingDoctor?.phoneNumber;
         
         let allKeys = Object.keys(this.prepoulatedData);
      allKeys.forEach(key => {
        this.formData[key] = this.prepoulatedData[key]
      });
      } catch(e){
        this.showToast(e.response?.data?.msg || e.response?.data  || e.response || e, this.TOST().ERROR);
      }
    },
    convertHoursIntoMilliseconds(time) {
      let timeParts = time.split(":");
      return (+timeParts[0] * (60000 * 60)) + (+timeParts[1] * 60000);
    },
    computedDate(name,nestedObject) {
      if(nestedObject){
        //exception having only one nested object key - static values
        return this.formData.mlcDetails.dateofMLC ? moment(this.formData.mlcDetails.dateofMLC).format("DD/MM/YYYY") : "";
      }else{
        return this.formData[name] ? moment(this.formData[name]).format("DD/MM/YYYY") : "";
      }
    },
    validateTabForms(){
      if(!this.$refs.form.validate()){
        this.$refs.form.validate() 
        this.showToast("Please fill all mandatory fields", this.TOST().WARNING);
        return
      }else{
        this.$refs.footerCardRef.interimSave();
      }
    },
    formatDate(date) {
      return date ? moment(date).format("DD/MM/YYYY") : "";
    },
    goToPreviousTab() {
      this.sno--;
      let btnNumber = "myBtn" + this.sno;
      const elem = this.$refs[btnNumber];
      elem[0].$el.click();
    },
    goToNextTab() {
      this.sno++;
      let btnNumber = "myBtn" + this.sno;
      const elem = this.$refs[btnNumber];
      elem[0].$el.click();
    },
    switchTabs(sno, name) {
      console.log('sno', sno, name);
      this.sno = sno;
      this.tabName = name;
    },
    submit() {
      this.isAlertView = true;
    },
    accept() {
      this.isAlertView = true;
    },
    saveData() {},
    alphaNumeric(fieldName, name, mandatory=true) {
      console.log('fieldName', fieldName, name);
      if (fieldName?.length > 0 ) {
        return (v) => /^[a-zA-Z0-9]+$/.test(v) || "Enter valid " + name;
      }
    },
    numericOnly() {
      return (v) => /^[0-9 ]+$/.test(v) || `Only Numbers are allowed.`;
    },
    onSubmit() {},
    browse() {},
    selectFile() {},
  },
};
</script>

<style scoped>
.mainCard {
  background-color: #01bbd9;
  height: auto;
  width: 100vw;
}

.activeTab {
  /* background-color: black !important; */
  color: #01bbd9 !important;
}

.dragbackground {
  background-color: #e7e7e7;
}

.textClass {
  color: white !important;
  font-weight: bold;
  font-size: 12px !important;
}
.bgColor {
  background-color: #01bbd9;
}
</style>
