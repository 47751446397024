<template>
  <div class="">
    <v-card tile class="pa-3 d-flex justify-end" >
      <h4 class="mr-9 pr-9" >Provider Name :<span style="color:#1E9690" class="text-capitalize pl-1">{{ $store.state.createtarrifDetails.providerName}} </span></h4>
      <h4 class="mr-9 pr-2" >Tariff ID :<span style="color:#1E9690" class="text-capitalize pl-1">{{ $store.state.createtarrifDetails.tariffId}}</span>  </h4>
    </v-card>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>