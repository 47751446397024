<template>
    <v-app>
        
       
            <v-card class="ma-3 " tile >
                    <v-layout class="pa-2" align-center justify-space-between style="background-color:#23B1A9;" >
                    
                            <div class="">
                                <v-text class="font-weight-bold "  style="color:#F5F5F5"
                                >Tariff Approval Queue
                                </v-text>
                            </div>
                            <v-flex xs3>
                                        <div class="ml-15" >
                                            <v-text class=" font-weight-regular"  style="color:#F5F5F5; ">Pending Work Items : <span class="font-weight-bold ">{{pendingWork}}</span>
                                            </v-text>
                                        </div>
                            </v-flex>
                            <v-flex xs2 class="text-capitalize "  >

                                            <!-- <v-select
                                                hide-details
                                                :items="WorkItem"
                                                item-text="text"
                                                item-value="value"
                                                v-model="workItem"
                                                label="Work Item Bucket"
                                                style=""
                                                dark
                                                solo  
                                                class="rounded-0"
                                                dense 
                                            ></v-select> -->
                                </v-flex>
                    
                    </v-layout>
               


                
                <!-- :page.sync="page"
                :items-per-page="itemsPerPage"
                @page-count="pageCount = $event" -->
                <v-data-table
                    :headers="headers"
                    :items="GetData"
                    hide-default-footer
                    dense
                    >

                    <template v-slot:item.createdAt="{ item }">
                    <div >{{ item.createdAt | formatDate}}</div>
                </template>
                <template v-slot:item.itemId="{ item }">
                    <div >{{ item.itemId| checkdata }}</div>
                </template>
                <template v-slot:item.name="{ item }">
                    <div >{{ item.name | checkdata}}</div>
                </template>
               
                  <template v-slot:item.tariffStatus="{ item }">
                    <div >{{ item.tariffStatus | checkdata}}</div>
                </template>

                 <template v-slot:item.providerName="{ item }">
                    <div >{{ item.providerName | checkdata}}</div>
                </template>

                <template v-slot:item.dueDate="{ item }">
                    <div >{{ item.dueDate | formatDate}}</div>
                </template>
                <template v-slot:item.pendingFrom="{ item }">
                    <div >{{ item.pendingFrom | checkdata}}</div>
                </template>

                <!-- <template v-slot:item.all.AssignedTo = {item,value} >
                        <tr >
                            <td style=" !important;"
                                @click="onClickItem(item, value)"> 
                        
                                <div class="d-flex flex-row justify-space-around"  v-if="item.assignToName === undefined">
                                    
                                    <p style="width: 120px; color: #8a9aa5 " class="mt-2" >
                                        Do you want to select work item?
                                    </p>

                                    <v-radio-group
                                        dense
                                        style="background-color: ;display:flex; align-items:center;justify-content:center"
                                        row
                                        v-model="value"
                                        >
                                        <v-radio
                                            label="Yes"
                                            value="Yes"
                                            class="black--text"
                                            color= #23B1A9
                                        ></v-radio>
                                        <v-radio
                                            label="No"
                                            value="No"
                                            class="black--text"
                                            color=#23B1A9
                                        ></v-radio>
                                    </v-radio-group>
                                </div>
                                
                                <div class="d-flex justify-space-between"  style="width: 265px;"   v-else>
                                    <p class="mt-3 assignedName">{{item.all.assignToName}}</p>
                                    <v-icon class="assignedNameclear" small>mdi-close-circle-outline</v-icon>
                                    
                                </div>
                            
                            </td>
                        </tr>
                </template> -->

                <template v-slot:item.Action = {item,value} >
                        
                        <tr >
                            <td style=" !important;">
                        
                            <v-btn color="#1E9690" class="" style="width: 25px; height: 25px" @click="editBnkAcc(item)" fab dark small>
                                    <v-icon  small>arrow_forward</v-icon>
                            </v-btn>
                        
                        </td>
                        </tr>
                </template>
                            
                            
                </v-data-table>
                    <v-divider></v-divider>
                    <v-layout row justify-end align-center class="mx-2">
                        <v-text class="mr-1 font-weight-bold body-1" style="color:#3C3D3D; font-size:12px !important">
                                <span class="" style="color:#E46A25;font-size:12px;">Showing {{fromvalue}}-{{toValu}} </span>out of {{totalCount }} records

                        </v-text>
                        <v-divider  class="mx-2" vertical ></v-divider>
                        <v-pagination 
                            class="cust-bnkAcc-pgn"
                            v-model="page"
                            color="#152F38"
                            flat
                            :total-visible="9"
                            @input="nextPageBtn(page)"
                            :length="pagelength"
                        ></v-pagination>
                    </v-layout>
            
            </v-card>
    </v-app>
</template>

<script>

import axios from "axios";
export default {

    components:{
        
    },

    created() {
        this.$store.commit("SAVE_PAGE_HEADER", "Tariff Approval Queue");
        
        console.log("Success Token", this.$store.getters.successToken)
        this.token=this.$store.getters.successToken
        // this.getProviderApp(this.page)
        this.getTariffWrkItm(this.page)

    },

   

    

     computed: {


     },
      filters:{
    
        checkdata(data){
            // console.log('Validity Data', data);
            if (data ==='' || data ===undefined || data === null || data=== 0 || data === 'Invalid Date' ) {
                return '-'
            } else {
                return data;
            }
        },
        formatDate(data){// eslint-disable-line no-mixed-spaces-and-tabs
            console.log('Validity Data', data);

             if (data === "" || data === "undefined" || data === null || data ==='Invalid Date') {
                return "-"
            } else {
                var n = new Date(data);
                return n.toLocaleString("en-IN")
            }
        },
    
    },

    data() {
        return {

            // for pagination AA
            swap_final_count:false,
            fval:0,
            sval:0,
            totalLen:'',
            
            fromvalue:"",
            toValu:"",
            token:null,
            page: 1,
            pendingWork: 10,
            assignToRadio:'',
            assignedUser: '',
            userKey: '',
            GetData:[],
            itemsPerPage: 10,
            totalCount:0,
            fromvalue:'',
            toValu:'',

            GetData1:[
                {
                    ReceivedDate:"13/Mar/2019",
                    WorkItem:"Provider  Registration Validation",
                    ProviderName:"------",
                    TariffName:"------",
                    DueDate:"14/Mar/2019",
                    PendingFrom:"2 Days"
                },{
                    ReceivedDate:"13/Mar/2019",
                    WorkItem:"Provider  Registration Validation",
                    ProviderName:"------",
                    TariffName:"------",
                    DueDate:"14/Mar/2019",
                    PendingFrom:"2 Days"
                },{
                    ReceivedDate:"13/Mar/2019",
                    WorkItem:"Provider  Registration Validation",
                    ProviderName:"------",
                    TariffName:"------",
                    DueDate:"14/Mar/2019",
                    PendingFrom:"2 Days"
                },{
                    ReceivedDate:"13/Mar/2019",
                    WorkItem:"Provider  Registration Validation",
                    ProviderName:"------",
                    TariffName:"------",
                    DueDate:"14/Mar/2019",
                    PendingFrom:"2 Days"
                },{
                    ReceivedDate:"13/Mar/2019",
                    WorkItem:"Provider  Registration Validation",
                    ProviderName:"------",
                    TariffName:"------",
                    DueDate:"14/Mar/2019",
                    PendingFrom:"2 Days"
                },{
                    ReceivedDate:"13/Mar/2019",
                    WorkItem:"Provider  Registration Validation",
                    ProviderName:"------",
                    TariffName:"------",
                    DueDate:"14/Mar/2019",
                    PendingFrom:"2 Days"
                },{
                    ReceivedDate:"13/Mar/2019",
                    WorkItem:"Provider  Registration Validation",
                    ProviderName:"------",
                    TariffName:"------",
                    DueDate:"14/Mar/2019",
                    PendingFrom:"2 Days"
                },{
                    ReceivedDate:"13/Mar/2019",
                    WorkItem:"Provider  Registration Validation",
                    ProviderName:"------",
                    TariffName:"------",
                    DueDate:"14/Mar/2019",
                    PendingFrom:"2 Days"
                },{
                    ReceivedDate:"13/Mar/2019",
                    WorkItem:"Provider  Registration Validation",
                    ProviderName:"------",
                    TariffName:"------",
                    DueDate:"14/Mar/2019",
                    PendingFrom:"2 Days"
                },{
                    ReceivedDate:"13/Mar/2019",
                    WorkItem:"Provider  Registration Validation",
                    ProviderName:"------",
                    TariffName:"------",
                    DueDate:"14/Mar/2019",
                    PendingFrom:"2 Days"
                },{
                    ReceivedDate:"13/Mar/2019",
                    WorkItem:"Provider  Registration Validation",
                    ProviderName:"------",
                    TariffName:"------",
                    DueDate:"14/Mar/2019",
                    PendingFrom:"2 Days"
                },{
                    ReceivedDate:"13/Mar/2019",
                    WorkItem:"Provider  Registration Validation",
                    ProviderName:"------",
                    TariffName:"------",
                    DueDate:"14/Mar/2019",
                    PendingFrom:"2 Days"
                }
            ],
            size: 10,
            headers:[
                
                {text: 'Received Date', value: 'createdAt',sortable: true,divider:true,class:"hdr-txt-clr backColor"},
                {text: 'Work Item', value: 'itemId',sortable: true,divider:true,class:"hdr-txt-clr backColor"},
                
                {text: 'Tariff Name', value: 'name',sortable: false,divider:true,class:"hdr-txt-clr backColor"},
                {text: 'Tariff Status', value: 'tariffStatus',sortable: false,divider:true,class:"hdr-txt-clr backColor"},
                {text: 'Provider Name', value: 'providerName',sortable: false,divider:true,class:"hdr-txt-clr backColor"},
                {text: 'Due Date', value: 'dueDate',sortable: false,divider:true,class:"hdr-txt-clr backColor"},
                {text: 'Pending From', value: 'pendingFrom',sortable: false,divider:true,class:"hdr-txt-clr backColor"},
                // {text: 'Assigned To', value: 'all.AssignedTo',sortable: false,divider:true,class:"hdr-txt-clr assignWidth backColor"},

                {text: 'Action', value: 'Action',sortable: false,divider:true, width:"40px",class:"hdr-txt-clr hdr-bg-clr"}

            ],
            WorkItem:[ 
                    {text:'select'},
                    {text:'xyz'}
            ],

        };



    },

    methods: {

        nextPageBtn(pgNo){
            console.log('page no value',pgNo)
            this.getTariffWrkItm(pgNo)
        },

        // nextPage(page) {
        //     this.pageNumber = page;
        // },
        editBnkAcc(item){
            console.log("item", item)
            
            let tarrifData={
                _id:item.docId,
                providerName:item.providerName,
                tariffId:item.tariffId,
                tariffName:item.name

            }
            console.log(tarrifData,"tarrifData")
            
            this.$store.commit("SAVE_DETAILS", tarrifData);
            this.$router.push({name: 'ReviewCharges', params: {showApprove:true}})
        },

        getTariffWrkItm(pageNum){
            console.log("praq====> page no ---->",pageNum)
            // let api_Is = 'tariff/get-tariff-by-id/'+id
           let api_Is= 'work-items/get-tariff-management-work-item?perPage='+this.itemsPerPage+'&pageNo='+pageNum
            this.GET(5,api_Is,(res,error)=>{
                try{
                    if(!error){
                        console.log("GET APi response|-> work-items/get-tariff-management-work-item", res.data.data,res.data.data.total)
                        this.GetData = []
                        this.GetData =res.data.data.list
                        this.pagelength=res.data.data.total_pages
                         this.totalCount=res.data.data.total
                         const start = pageNum * this.itemsPerPage - this.itemsPerPage,
                        end = start + this.itemsPerPage;
                        console.log(start,end,"start End")
                        if(this.totalCount===0){
                                this.fromvalue=0
                            }else{
                                this.fromvalue=start+1;
                            }
                            
                            if(this.totalCount <this.itemsPerPage){
                                this.toValu=this.totalCount
                            }else if(this.totalCount<end){
                                this.toValu=this.totalCount
                            }else{
                                this.toValu=end
                        }
                       
                    }else{
                        console.log("GET APi error response|->work-items/get-tariff-management-work-item", error)
                    } 
                }catch(error){
                    console.log("GET APi  errorresponse|-> work-items/get-tariff-management-work-item", error)
                }       
            })
        },


        getProviderApp(pageNum) {
        // console.log("individual sort by-------",sortBy1)
                console.log("praq====> page no ---->",pageNum)
                
                    let self = this;   
                    // console.log('next btn',self.pageIndu)
                    

                //     let apiURL=''
                // if(sortBy1===''||sortBy1===undefined||sortBy1===null){
                //     apiURL=self.API_Service_provider()+"work-items/get-for-provider-registration?"
                // }else{
                //     apiURL=self.API_Service_provider()+"work-items/get-for-provider-registration?"
                // }
                    
                    // http://claimsdrive-health.salesdrive.app/task-management/task-management-service/api/work-items/get-for-provider-registration?perPage=10&pageNo=1

                    axios({
                    method: "get",
                    
                    // url:apiURL,
                      url:self.API_Service_TASK_MGMNT()+"work-items/get-for-provider-registration?perPage=10&pageNo="+pageNum,            
                            
                            headers: {
                                "Content-Type": "application/json", 
                                "x-access-token": self.token,
                            },
                            
                        })
                        .then(function(response) {
                                       
                    let apiData = response.data.data.list

                    console.log("API RESPONSE for ALL Provider registration APP:::::",apiData);
                  

                 self.GetData = []
                    self.GetData = apiData.map(e=>{
                        
                        return { 
                                assignToId:e._id,
                                assignToName:e.assignToName ,
                                newdate: e.createdAt.split('T')[0],
                                newduedate:e.dueDate.split('T')[0], 
                                all:e,
                             }

                        })

                        // for pagination
                    
                    self.pagelength=response.data.data.total_pages
                    console.log(self.page,' <-- page  |  next btn, pg length is ----->',self.pagelength)
                     self.totalLen=response.data.data.total
                      let l = self.GetData.total
                    console.log( 10*self.page,'search skip simulation ');

                      var less_enough = self.totalLen
                        var checkless_init = false
                        if(less_enough < 10){
                            checkless_init = false
                        }else{
                            checkless_init = true
                        }

                        // if less than 15 we have second value same as total value as no pagination will occur
                        if(checkless_init){
                            
                                // checkinit is true means the final count is more than 15 (- 1)
                                var traverse =  10*self.page
                                self.fval = traverse-9
                                if(self.swap_final_count){
                                    self.sval =  self.totalLen
                                }else{
                                    self.sval = traverse
                                }
                                console.log('fval------>',self.fval)
                                console.log('sval------>',self.sval)

                        }else if(self.totalLen == 0){
                            
                          self.sval = self.fval = 0
                        
                       }else{
                             self.fval = 1
                             self.sval = self.totalLen
                        }
                    console.log('total data  ----->',self.totalLen)
    
                        
						console.log(" table DATA is---->>::",self.GetData);
			
                        })
                        .catch(function(error) {
                            console.log(error);
                            self.showToast(error.response.data.msg,self.TOST().ERROR);
                        });

                        
                    },

        onClickItem( item, radioValue) {
            console.log(radioValue);
            console.log(item);
            
            console.log(item.all._id);
            if(radioValue === "Yes"){
                let id = item.all._id;
                let self = this;   
                     
                axios({
                    method: "put",
                    url:self.API_Service_TASK_MGMNT()+"work-items/assign/"+id,      
                     headers: {
                                "Content-Type": "application/json", 
                                "x-access-token": self.token,
                            },        
                        })
                        .then(function(response) {
                            console.log(response);
                            let data = response.data.data;
                            self.getProviderApp();
                            self.page = 1
                            
                         }) .catch(function(error) {
                                console.log(error);
                            });   
                    //     axios({
                    //         method: "get",
                    //         url:"http://claimsdrive-health.salesdrive.app/task-management/task-management-service/api/work-items/get-for-provider-registration?perPage=10&pageNo=1",            
                                
                    //         headers: {
                    //             "Content-Type": "application/json", 
                    //             "x-access-token": this.token,
                    //         },
                    
                    //         }).then(function(response){
                    //             console.log(response);
                    //             let proList = response.data.data.list
                    //             const validName =   proList.find((obj) => obj._id === id);
                    //             console.log(validName.assignToName);
                    //             self.assignedUser = validName.assignToName
                    //             self.userKey = validName.assignToId
                    //             self.$router.go();
                    //             }).catch(function(error) {
                    //     console.log(error);
                    // })           
            
                }else{
                    return
                }

            console.log(this.assignedUser);
        }
},

watch: {
    
},
    
    
}

</script>
<style >
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
    font-size: 0.84rem !important;
}

.assignWidth{
    width: 300px;  
}

.assignedName{
    color: #1E9690; 
    font-weight: bold;
}

.assignedNameclear{
    cursor: pointer;
}

.backColor{
    background-color: #1E9690;
    
}

.hdr-txt-clr{
    color:#10242B !important;
    /* padding:0px!important;
    padding: 10px !important;
    padding-left: 14px !important; */
}

.hdr-bg-clr{
    background-color: #f7f7f7;
}
.cust_pr .v-text-field .v-input__slot,
.v-text-field--outline .v-input__slot {
    
    min-height: 38px !important;
    display: flex !important;
    align-items: center !important;
    /* border:1px red solid; */
}

.pending-work{
    justify-items: start;
}

.lay{
        direction: rtl;
        max-height: 50px;

}
.body-2{
        font-size:18px !important;

}
.body-3{
        font-size:16px !important;

}



.cstm-clr-pr{
    color:#10242B;
    margin-bottom: 5px;
}
.cust_pr .v-input{
    font-size: 13px !important;
}
.cust_pr .v-input input {
    font-size: 13px !important;
}

.cust_pr .v-input .v-label {
    font-size: 13px !important;
}


 .cust-bnkAcc-pgn .v-pagination__navigation{
        box-shadow:unset !important;
        border-radius: unset !important;
        background-color:#FFFFFF !important;
        border:1px solid #DAE1E6 !important
    }
    .cust-bnkAcc-pgn .v-pagination__navigation--disabled{
        background-color:#DAE1E6 !important;
    }
    .cust-bnkAcc-pgn .v-pagination__item{
        box-shadow:unset !important;
        min-width:2rem !important;
        height:2rem !important;
        border-radius: unset !important;
        border:1px solid #DAE1E6 !important
    }   
</style>