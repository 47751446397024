 <template>
    <v-app>
        
        <div >
            <v-form v-model="valid" ref="form">
            <v-card tile class="pa-1">
                <v-layout row class="mt-4 ml-2">
                 <v-flex xs3>
                 <v-layout column class="cust_pr mx-3">
                        
                          <v-text class="font-weight-bold cstm-clr-pr">
                                Plan
                            </v-text>
                            <v-select
                                :items="planItems"
                                label="Select"
                                v-model="plan"
                                item-text="planName"
                                item-value="_id"        
                                solo
                                dense
                                disabled
                                class="rounded-0"
                                >
                            </v-select>
                          
                    </v-layout>
                </v-flex>
                 <v-flex xs3>
                 <v-layout column class="cust_pr mx-3">
                        <!-- v-model="relationshipsCoverd" -->
                          <v-text class="font-weight-bold cstm-clr-pr">
                                Relationships Covered
                            </v-text>
                            <!-- <v-select
                                :items="selectRelationshipsCoverd"
                                label="Select"
                                
                                v-model="RelationshipsCoverd"
                                multiple
                                item-value="display"
                                item-text="display"
                             
                                solo
                                dense
                                :rules="[rules.required]"
                                class="rounded-0"
                                > -->
                 
                   
                        <v-text-field
                            readonly
                            solo
                            dense
                            v-model="activeonly"
                            @click="tgdd = !tgdd"
                            :disabled="inputdsbld"
                            :rules="[required()]"
                            
                          
                        >

                        </v-text-field>
                        
                        <v-card 
                            v-click-outside="onClickOutside"
                            v-if="tgdd"  class="pa-2 ripple"  
                            style="position:absolute; top:45px; z-index:10 !important; width:230px;" >
                            <div  v-for="(data, index) in selectRelationshipsCoverd" :key="index" >
                                          <!-- <template > -->
                                              <!-- v-slot:item='data' -->
                         <!-- :input-value="active" -->
                                <v-layout style="align-items:center;" >

                                <v-checkbox
                                    :disabled="data.aftercheck"
                                    @change="setddnow(data)"
                                    v-model="data.checked"
                                    color="primary"
                                ></v-checkbox>
                                <v-list-tile-content>
                                <v-list-tile-title>
                                    <!-- {{data.display}} |aftercheck => {{data.aftercheck}} | checked=> {{data.checked}} -->
                                    {{data.display}}
                                </v-list-tile-title>
                                </v-list-tile-content>
                         </v-layout>
                       
                   
                    <!-- </template> -->
                            </div>
                          </v-card>
                          
 <!-- :disabled="inputdsbld" -->
                    <!-- <template v-slot:item='data'> -->
                         <!-- :input-value="active" -->
                          <!-- <v-checkbox
                            :disabled="data.item.aftercheck"
                            @change="setddnow(data.item)"
                            v-model="data.item.checked"
                            color="primary"
                        ></v-checkbox>
                        <v-list-tile-content>
                        <v-list-tile-title> -->
                            <!-- {{data.item.display}} |aftercheck => {{data.item.aftercheck}} | checked=> {{data.item.checked}} -->
                            <!-- {{data.item.display}}
                        </v-list-tile-title>
                        </v-list-tile-content>
                    </template> -->
                            <!-- <template v-slot:selection='data'>
                                <v-chip
                                    :key="JSON.stringify(data.item)"
                                    close
                                    class="chip--select-multi"
                                    @input="data.parent.selectItem(data.item)"
                                >
                                    {{ data.item.firstName }} {{ data.item.lastName }}
                                    </v-chip>
                                </template> -->

     
    <!-- <v-list 
      flat
    >
      <v-list-item-group
        v-model="settings"
        multiple
      >
        <v-list-item>
          <template v-slot:default="{ active, }">
            <v-list-item-action>
              <v-checkbox
                :input-value="active"
                color="primary"
              ></v-checkbox>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>Notifications</v-list-item-title>
              <v-list-item-subtitle>Allow notifications</v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-list-item>
      </v-list-item-group>
    </v-list > -->

                            <!-- </v-select> -->
                          
                    </v-layout>
                </v-flex>
                    <v-btn :dark="!inputdsbld" :disabled="inputdsbld" @click="selectAdd"    tile style="background-color:#23B1A9" 
                            class="text-capitalize mt-6 py-4 ml-3" small>
                            <v-icon dark small>check_circle</v-icon>
                    <v-divider vertical class="mx-2"></v-divider> Add</v-btn>
                    <v-btn  :dark="!inputdsbld" :disabled="inputdsbld" @click="delnow"   tile style="background-color:#D1121B"  class="text-capitalize mt-6 py-4 ml-3" small><v-icon dark small>delete</v-icon> <v-divider vertical class="mx-2"></v-divider> Delete</v-btn>

                </v-layout>
            </v-card>
<!-- Self -->
        <div v-for="(rd, index) in reldatais"  :key="index">
                 <v-card tile  class="pt-2 mt-4" v-if="rd.active"  >
                <v-layout color="#FFFFFF" class=" mt-1" style="background-color: #FFFFFF;">

                <v-flex xs2 >
                    <v-layout column class="cust_pr  ">
                      
                        <v-checkbox
                            :disabled="inputdsbld"
                            dense
                            v-model="rd.checkbox"
                            class="ml-4"
                            color="blue"
                            
                            @change="del(rd.coverType, rd.checkbox)"
                            hide-details
                        ><span slot="label" class="font-weight-bold" style="font-size: 14px">{{rd.coverType}}</span>
                       
                        </v-checkbox>
                    </v-layout>
                </v-flex>
                <v-flex xs3 >
                    <v-layout column class="cust_pr mx-2 ">
                        <p class="font-weight-bold cstm-clr-pr">
                            SI Sub Limit
                        </p>
                        <v-text-field
                        :disabled="inputdsbld"
                            solo
                            label="Enter..."
                            v-model="rd.siSubLimit"
                            maxlength="10"
                            :rules="[Numeric2]"
                            counter="10"
                             class="rounded-0"
                             >
                            <!-- required(), -->
                        </v-text-field>
                    </v-layout>
                </v-flex>
                <v-flex xs3 >
                    <v-layout column class="cust_pr mx-2">
                        <p class="font-weight-bold cstm-clr-pr">
                            Co-Pay(%)
                        </p>
                        <v-text-field
                            solo
                            :disabled="inputdsbld"
                            maxlength="10"
                            label="Enter..."
                            v-model="rd.coPay"
                            :rules="[Numeric2,loadMax]"
                             class="rounded-0"
                             counter="10"
                             >
                             <!-- required(), -->
                        </v-text-field>
                    </v-layout>
                </v-flex>
                <v-flex xs3 >
                    <v-layout column class=" mx-2 cust_pr">
                        <p class="font-weight-bold cstm-clr-pr">
                            Room Rent Limit
                        </p>
                        <v-select
                            :items="selectRoomRentLimit"
                            label="Select"
                            :disabled="inputdsbld"
                            v-model="rd.roomRentLimit"
                            multiple
                          
                            solo
                            dense
                            @change="checkflatSI(rd,rd.roomRentLimit)"
                            class="rounded-0"
                            
                            >
                            <!-- counter="10" -->
                            <template v-slot:selection="{ item, index }">
                                   
                                    <span v-if="index === 0" >{{ item }}</span>
                                   
                                    <span
                                    v-if="index === 1"
                                    class="grey--text caption"
                                    >
                                    (+{{ rd.roomRentLimit.length - 1 }})
                                    </span>
                                </template>
                            <!-- required() -->
                        </v-select>
                    </v-layout>
                </v-flex>
                <v-flex xs3 >
                    <v-layout column class=" mx-2 cust_pr">
                        <p class="font-weight-bold cstm-clr-pr">
                            Flat Amount
                        </p>
                        <v-text-field
                            maxlength="10"
                            counter="10"
                            solo
                            :disabled="!rd.en_fa"
                            label="Enter..."
                            v-model="rd.flatAmount"
                            :rules="[Numeric2]"
                            @input="checkflatSI(rd,rd.roomRentLimit)"
                            class="rounded-0">
                        </v-text-field>
                        <span v-if="rd.fa" 
                           style="font-size: 10.8px;color: #ff5252 !important;font-family: 'Roboto';margin-top: -24px !important;"
                        >This Field is </span><br>
                         <span v-if="rd.fa" 
                           style="font-size: 10.8px;color: #ff5252 !important;font-family: 'Roboto';margin-top: -24px !important;"
                        >Required</span>
                    </v-layout>
                </v-flex>

                 <v-flex xs3 >
                    <v-layout column class=" mx-2 cust_pr">
                        <p class="font-weight-bold cstm-clr-pr">
                            % of SI
                        </p>
                        <v-text-field
                            maxlength="10"
                            counter="10"
                            solo
                            :disabled="!rd.en_si"
                            label="Enter..."
                            v-model="rd.siPercent"
                            :rules="[Numeric2]"
                            @input="checkflatSI(rd,rd.roomRentLimit)"
                            class="rounded-0"
                        >
                        </v-text-field>
                          <!-- :rules="[rules.mantwo]" -->
                    <span  v-if="rd.si"
                    style="font-size: 10.8px;color: #ff5252 !important;font-family: 'Roboto';margin-top: -24px !important;" 
                    >This Field is </span> <br>
                   <span  v-if="rd.si"
                    style="font-size: 10.8px;color: #ff5252 !important;font-family: 'Roboto';margin-top: -24px !important;" 
                    >Required</span>
                    </v-layout>
                </v-flex>
                <v-flex xs3>
                    <v-layout column class=" mx-2  cust_pr">
                        <p class="font-weight-bold cstm-clr-pr">
                            Applicable Value
                        </p>
                        <v-select
                         
                            
                            :items="selectApplicableValue"
                           :disabled="!rd.en_app"
                            label="Select"
                            v-model="rd.applicableValue"
                            solo
                            dense
                            clearable
                            class="rounded-0"
                            @change="checkflatSI(rd,rd.roomRentLimit)"
                            >
                            <!-- counter="10"-->
                            <!-- required()-->
                        </v-select>

                         <span  v-if="rd.app"
                            style="font-size: 10.8px;color: #ff5252 !important;font-family: 'Roboto';margin-top: -24px !important;" 
                            >This Field is </span> <br>
                        <span  v-if="rd.app"
                            style="font-size: 10.8px;color: #ff5252 !important;font-family: 'Roboto';margin-top: -24px !important;" 
                            >Required</span>
                        
                    </v-layout>
                </v-flex>
                <v-flex xs3>
                    <v-layout column class=" mx-2  cust_pr">
                        <p class="font-weight-bold cstm-clr-pr">
                            Waiting Period
                        </p>
                        <v-text-field
                             counter="10"
                             maxlength="10"
                             solo
                             :disabled="inputdsbld"
                             label="Enter..."
                             v-model="rd.waitingPeriod"
                             class="rounded-0"
                             :rules="[Numeric2]"
                             >
                             <!-- required() -->
                        </v-text-field>
                    </v-layout>
                </v-flex>
            </v-layout>
        </v-card>
        </div>
       

<!-- Spouse -->
        <!-- <v-card   v-if="sp" class="pt-2 mt-4" tile>
                <v-layout color="#FFFFFF" class=" " style="background-color: #FFFFFF;">

                <v-flex xs3 >
                    <v-layout column class="cust_pr  ">
                        <v-checkbox
                            dense
                            v-model="spouse.checkbox"
                            class="ml-4"
                            color="blue"
                           
                               @change="del('Spouse')"
                            hide-details
                        ><span slot="label" class="font-weight-bold" style="font-size: 14px">Spouse</span>
                        </v-checkbox>
                    </v-layout>
                </v-flex>
                <v-flex xs3 >
                    <v-layout column class="cust_pr mx-2 ">
                        <p class="font-weight-bold cstm-clr-pr">
                            SI Sub Limit
                        </p>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="spouse.siSubLimit"
                            :rules="[rules.minNo]"
                             class="rounded-0"
                             >
                        </v-text-field>
                    </v-layout>
                </v-flex>
                <v-flex xs3 >
                    <v-layout column class="cust_pr mx-2">
                        <p class="font-weight-bold cstm-clr-pr">
                            Co-Pay(%)
                        </p>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="spouse.coPay"
                            :rules="[rules.minCoPay]"
                             class="rounded-0"
                             >
                        </v-text-field>
                    </v-layout>
                </v-flex>
                <v-flex xs3 >
                    <v-layout column class=" mx-2 cust_pr">
                        <p class="font-weight-bold cstm-clr-pr">
                            Room Rent Limit
                        </p>
                        <v-select
                            :items="selectRoomRentLimit"
                            label="Select"
                            v-model="spouse.roomRentLimit"
                            multiple
                            solo
                            dense
                             class="rounded-0"
                            >
                        </v-select>
                    </v-layout>
                </v-flex>
                <v-flex xs3 >
                    <v-layout column class=" mx-2 cust_pr">
                        <p class="font-weight-bold cstm-clr-pr">
                            Flat Amount
                        </p>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="spouse.flatAmount"
                            :rules="[rules.minNo]"
                             class="rounded-0">
                        </v-text-field>
                    </v-layout>
                </v-flex>

                 <v-flex xs3 >
                    <v-layout column class=" mx-2 cust_pr">
                        <p class="font-weight-bold cstm-clr-pr">
                            % of SI
                        </p>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="spouse.percentOfsi"
                            :rules="[rules.minNo]"
                             class="rounded-0"
                             >
                        </v-text-field>
                    </v-layout>
                </v-flex>
                <v-flex xs3>
                    <v-layout column class=" mx-2  cust_pr">
                        <p class="font-weight-bold cstm-clr-pr">
                            Applicable Value
                        </p>
                        <v-select
                            :items="selectApplicableValue"
                            label="Select"
                            v-model="spouse.applicableValue"
                            solo
                            dense
                             class="rounded-0"
                            >
                        </v-select>
                        
                    </v-layout>
                </v-flex>
                <v-flex xs3>
                    <v-layout column class=" mx-2  cust_pr">
                        <p class="font-weight-bold cstm-clr-pr">
                            Waiting Period
                        </p>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="spouse.waitingPeriod"
                             class="rounded-0"
                             :rules="[rules.minNo]"
                             >
                        </v-text-field>
                    </v-layout>
                </v-flex>

            </v-layout>
        </v-card> -->

<!-- Children -->
            <!-- <v-card v-if="ch"  class="pt-2 mt-4" tile>
                <v-layout color="#FFFFFF" class="" style="background-color: #FFFFFF;">

                <v-flex xs3 >
                    <v-layout column class="cust_pr  ">
                        <v-checkbox
                            dense
                            v-model="children.checkbox"
                            class="ml-4"
                            color="blue"
                          
                               @change="del('Children')"
                            hide-details
                        ><span slot="label" class="font-weight-bold" style="font-size: 14px">Children</span>
                        </v-checkbox>
                    </v-layout>
                </v-flex>
                <v-flex xs3 >
                    <v-layout column class="cust_pr mx-2 ">
                        <p class="font-weight-bold cstm-clr-pr">
                            SI Sub Limit
                        </p>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="children.siSubLimit"
                            :rules="[rules.minNo]"
                             class="rounded-0"
                             >
                        </v-text-field>
                    </v-layout>
                </v-flex>
                <v-flex xs3 >
                    <v-layout column class="cust_pr mx-2">
                        <p class="font-weight-bold cstm-clr-pr">
                            Co-Pay(%)
                        </p>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="children.coPay"
                            :rules="[rules.minCoPay]"
                             class="rounded-0"
                             >
                        </v-text-field>
                    </v-layout>
                </v-flex>
                <v-flex xs3 >
                    <v-layout column class=" mx-2 cust_pr">
                        <p class="font-weight-bold cstm-clr-pr">
                            Room Rent Limit
                        </p>
                        <v-select
                            :items="selectRoomRentLimit"
                            label="Select"
                            v-model="children.roomRentLimit"
                            multiple
                            solo
                            dense
                             class="rounded-0"
                            >
                        </v-select>
                    </v-layout>
                </v-flex>
                <v-flex xs3 >
                    <v-layout column class=" mx-2 cust_pr">
                        <p class="font-weight-bold cstm-clr-pr">
                            Flat Amount
                        </p>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="children.flatAmount"
                            :rules="[rules.minNo]"
                             class="rounded-0">
                        </v-text-field>
                    </v-layout>
                </v-flex>

                 <v-flex xs3 >
                    <v-layout column class=" mx-2 cust_pr">
                        <p class="font-weight-bold cstm-clr-pr">
                            % of SI
                        </p>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="children.percentOfsi"
                            :rules="[rules.minNo]"
                             class="rounded-0"
                             >
                        </v-text-field>
                    </v-layout>
                </v-flex>
                <v-flex xs3>
                    <v-layout column class=" mx-2  cust_pr">
                        <p class="font-weight-bold cstm-clr-pr">
                            Applicable Value
                        </p>
                        <v-select
                            :items="selectApplicableValue"
                            label="Select"
                            v-model="children.applicableValue"
                            solo
                            dense
                             class="rounded-0"
                            >
                        </v-select>
                        
                    </v-layout>
                </v-flex>
                <v-flex xs3>
                    <v-layout column class=" mx-2  cust_pr">
                        <p class="font-weight-bold cstm-clr-pr">
                            Waiting Period
                        </p>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="children.waitingPeriod"
                             class="rounded-0"
                             :rules="[rules.minNo]"
                             >
                        </v-text-field>
                    </v-layout>
                </v-flex>

            </v-layout>
        </v-card> -->

<!-- Parent -->
        <!-- <v-card v-if="pt" class="pt-2 mt-4" tile>
                <v-layout color="#FFFFFF" class="pa-0 ma-0" style="background-color: #FFFFFF;">

                <v-flex xs3 >
                    <v-layout column class="cust_pr  ">
                        <v-checkbox
                            dense
                            v-model="parent.checkbox"
                            class="ml-4"
                            color="blue"
                          
                               @change="del('Parent')"
                            hide-details
                        ><span slot="label" class="font-weight-bold" style="font-size: 14px">Parent</span>
                        </v-checkbox>
                    </v-layout>
                </v-flex>
                <v-flex xs3 >
                    <v-layout column class="cust_pr mx-2 ">
                        <p class="font-weight-bold cstm-clr-pr">
                            SI Sub Limit
                        </p>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="parent.siSubLimit"
                            :rules="[rules.minNo]"
                             class="rounded-0"
                             >
                        </v-text-field>
                    </v-layout>
                </v-flex>
                <v-flex xs3 >
                    <v-layout column class="cust_pr mx-2">
                        <p class="font-weight-bold cstm-clr-pr">
                            Co-Pay(%)
                        </p>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="parent.coPay"
                            :rules="[rules.minCoPay]"
                             class="rounded-0"
                             >
                        </v-text-field>
                    </v-layout>
                </v-flex>
                <v-flex xs3 >
                    <v-layout column class=" mx-2 cust_pr">
                        <p class="font-weight-bold cstm-clr-pr">
                            Room Rent Limit
                        </p>
                        <v-select
                            :items="selectRoomRentLimit"
                            label="Select"
                            v-model="parent.roomRentLimit"
                            multiple
                            solo
                            dense
                             class="rounded-0"
                            >
                        </v-select>
                    </v-layout>
                </v-flex>
                <v-flex xs3 >
                    <v-layout column class=" mx-2 cust_pr">
                        <p class="font-weight-bold cstm-clr-pr">
                            Flat Amount
                        </p>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="parent.flatAmount"
                            :rules="[rules.minNo]"
                             class="rounded-0">
                        </v-text-field>
                    </v-layout>
                </v-flex>

                 <v-flex xs3 >
                    <v-layout column class=" mx-2 cust_pr">
                        <p class="font-weight-bold cstm-clr-pr">
                            % of SI
                        </p>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="parent.percentOfsi"
                            :rules="[rules.minNo]"
                             class="rounded-0"
                             >
                        </v-text-field>
                    </v-layout>
                </v-flex>
                <v-flex xs3>
                    <v-layout column class=" mx-2  cust_pr">
                        <p class="font-weight-bold cstm-clr-pr">
                            Applicable Value
                        </p>
                        <v-select
                            :items="selectApplicableValue"
                            label="Select"
                            v-model="parent.applicableValue"
                            solo
                            dense
                             class="rounded-0"
                            >
                        </v-select>
                        
                    </v-layout>
                </v-flex>
                <v-flex xs3>
                    <v-layout column class=" mx-2  cust_pr">
                        <p class="font-weight-bold cstm-clr-pr">
                            Waiting Period
                        </p>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="parent.waitingPeriod"
                             class="rounded-0"
                             :rules="[rules.minNo]"
                             >
                        </v-text-field>
                    </v-layout>
                </v-flex>

            </v-layout>
        </v-card>   -->
        </v-form>

            <v-card class="mb-2 mt-3 cust-bnkAcc-tbl" style="border:0.5px solid #C1C8CC;" tile>
				<v-layout  style="padding:15px;background-color:#F7F7F7;align-items:center; justify-center; justify-content: flex-end;">
                     <!-- @click.native="jump('/basicplandetails')"  -->
                    <v-btn dark  tile style="background-color:#E46A25" @click="returnTab()"  class="text-capitalize px-3 mx-3" small><v-icon dark small>arrow_back</v-icon> <v-divider vertical class="mx-2"></v-divider>Return</v-btn>
                    <v-btn :dark="!inputdsbld" :disabled="inputdsbld"  tile style="background-color:#23B1A9"  @click="relDep_save()" class="text-capitalize px-3 mx-3" small><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider> Save</v-btn>
                    <v-btn dark tile style="background-color:#152F38" @click="nextTab()" class="text-capitalize px-3 mx-2" small><v-icon dark small>arrow_forward</v-icon> <v-divider vertical class="mx-2"></v-divider>Next</v-btn>
				</v-layout>
            </v-card>         
        </div>
        
    </v-app>
</template>
<script>

import axios from "axios";
import moment from 'moment';
export default {

    components:{
      
    },

    created() {

        this.$store.commit("SAVE_PAGE_HEADER", "Relationship & Dependent Info");
        this.token=this.$store.getters.successToken
        console.log(this.$store.getters.fetchPlcyIdForPlnData, "fetchPlcyIdForPlnData")
       this.btnName=this.$store.getters.fetchPlcyIdForPlnData.text
       if(this.btnName==="EditPlnFrPlcy"||this.btnName==="ConfgrPlnFrPlcy"){
            // this.nextbtn=false
            this.getGroupPlanbyId(this.$store.getters.fetchPlcyIdForPlnData.plan_No)
        }

         if(this.btnName==="EditNwRtlPln"||this.btnName==="CnfgrNwRtlPln"){
            this.nextbtn=false
            this.getRetailPlanbyId(this.$store.getters.fetchPlcyIdForPlnData.retailPlan_Id)
        }

        if(this.btnName==="EditPlnFrPlcy"||this.btnName==="CrteNwPlnFrPlcy"||this.btnName==="EditNwRtlPln"||this.btnName==="CrteNwRtlPln"){
            this.inputdsbld=true
        }

    },

    computed: {


        checkdata(){

                console.log("|-->", this.reldatais);
            return this.reldatais
        }

    },

    data() {
        return {
            activeonly:[],
            tgdd:false,
        selectPlan:[],
        settings:null,
        inputdsbld:false,
         btnName:'',
         token:null,
        delarr:[],
        sp:false,
        ch:false,
        pt:false,
        nextbtn:true,
        plan:'',
        relationshipsCoverd:'',
        planItems:[],

        
      reldatais:[
        {
            active:false,
            coverType:'Self',
            checkbox:false,
            siSubLimit:'',
            coPay:'',
            roomRentLimit:[],
            flatAmount:'',
            siPercent:'',
            applicableValue:'',
            waitingPeriod:'',
                fa:false,
                si:false,
                en_fa:false,
                en_app:false,
                en_si:false,
                app:false,
            
        },

        {   active:false,
            coverType:'Spouse',
            checkbox:false,
            siSubLimit:'',
            coPay:'',
            roomRentLimit:[],
            flatAmount:'',
            siPercent:'',
            applicableValue:'',
            waitingPeriod:'',
                fa:false,
                si:false,
                en_fa:false,
                en_app:false,
                en_si:false,
                app:false,
        },

        {   coverType:'Children',
            active:false,
            checkbox:false,
            siSubLimit:'',
            coPay:'',
            roomRentLimit:[],
            flatAmount:'',
            siPercent:'',
            applicableValue:'',
            waitingPeriod:'',
            fa:false,
            si:false,
                fa:false,
                si:false,
                en_fa:false,
                en_app:false,
                en_si:false,
                app:false,
        },
        
        {   coverType:'Parent',
            active:false,
            checkbox:false,
            siSubLimit:'',
            coPay:'',
            roomRentLimit:[],
            flatAmount:'',
            siPercent:'',
            applicableValue:'',
            waitingPeriod:'',
            fa:false,
            si:false,
                fa:false,
                si:false,
                en_fa:false,
                en_app:false,
                en_si:false,
                app:false,
        },
        ],
        
        syncarr:[],
        selectRelationshipsCoverd:[
            {display:'Self', checked:false, aftercheck:false,},
            {display:'Spouse',checked:false,aftercheck:false,},
            {display:'Children',checked:false, aftercheck:false,},
            {display:'Parent',checked:false, aftercheck:false}
        ],
        RelationshipsCoverd:[],

        selectApplicableValue:["Lower of value","Higher of value"],

        selectRoomRentLimit:['Flat amount','% of SI'],
        
            required(){
            return v=>v && v.length>0 || "This Field is Required"
            },
            minLength(minLength){
                return v=>v && v.length>=minLength || `Must be atleast ${minLength} Characters`
            },
            maxLength(maxLength){
                return v=>v && v.length<=maxLength || `Must be less than ${maxLength} Characters`
            },
            spclChar(){
                    return v=>/^[A-Za-z0-9 ]+$/.test(v) || `Special Characters Not Allowed`
                },
            alphaNumeric(){
                return v=>/^[0-9 ]+$/.test(v) || `Only Numbers are allowed.`
            },

            // loadMax(maxValue){
            //     return value => parseInt(value)<=100  || `Must be less than ${maxValue}`

            // },
            dateCheck(){
                let todayDate = Date.now();
                let fromDate=moment(this.productEffectFromdate,'YYYY-MM-DD ').toDate().getTime()
                let Todate= moment(this.productEffectTodate,'YYYY-MM-DD ').toDate().getTime()
                console.log(todayDate,"todayDate")
                let CurrentDate= todayDate
                if(Todate < fromDate){
                    // this.showToast("The Product 'Effective date From' can not be greater than Product 'Effective date To'.",this.TOST().WARNING)
                    return "Cannot be less than 'Product Effective From'"
                }
            },

          man:(all, amt) =>{

                        this.functionis(all, amt)
                        // all.map(e=>{
                        //         if(e == 'Flat amount'){
                        //             console.log("fl amt");
                        //             return 'aaaaa'  
                        //         }
                        // })
                        // console.log("from man", all)

                        // if(all == 0){
                        //      return 'This Field is Required ???'
                        // }
                       
                        // console.log(value,"value | all",all);
                        // all.roomRentLimit.map(e=>{
                        //         if(e == 'Flat amount'){
                        //             console.log(" e-->",e);
                        //             return ['This Field is Required']
                        //         }else{
                        //             console.log(" -else- ");
                        //         }
                        // })
                        // for(let i=0; i<this.reldatais.length;i++){
                        //             console.log("  this.reldatais[i].roomRentLimit ",  this.reldatais[i].roomRentLimit);
                        //         this.reldatais[i].roomRentLimit.map(e=>{
                        //                 console.log("e is in roomlimit ", e)
                        //                 if(e == 'Flat amount'){
                        //                     console.log( this.reldatais[i].flatAmount,"FA condition", this.reldatais[i].flatAmount == '')
                        //                     if(this.reldatais[i].flatAmount == '' || this.reldatais[i].flatAmount.length == 0){
                        //                         return 'This Field is Required'
                        //                     }
                        //                 }
                        //         })
                        },

        rules: {
                required: value => !!value || 'This Field is Required.',
                // minRegNo: v =>v.length <= 3|| v.length == 5  || 'Enter valid Registration Number',
                minNo: value => {
                            // const pattern = /^([0-9])+$/
                        const pattern = /^[0-9 ]+$/g
                        return pattern.test(value) || 'Only Numbers are allowed.'
                },

                minCoPay: value => {
                            // const pattern = /^([0-9])+$/
                        const pattern = /^[0-9 ]+$/g
                        return value.length<0 || pattern.test(value) || 'Only Numbers are allowed.'
                },

                mantwo: value =>{

                        
                        //  for(let i=0; i<this.reldatais.length;i++){
                                
                        //         this.reldatais[i].roomRentLimit.map(e=>{

                        //                 if(e == '% of SI'){
                        //                     if(this.reldatais[i].siPercent == '' || this.reldatais[i].siPercent.length == 0){
                        //                         return 'This Field is Required'
                        //                     }
                        //                 }

                        //         })
                               

                        // }

                },

              

                
                   
            },


        };



    },

    methods:{

        loadMax(v){
            console.log( parseInt(v),"parseInt(v)<=100 ", parseInt(v)<=100);
                // return value => parseInt(value)<=100  || `Must be less than ${maxValue}`
                  if (v.length !== 0){
                        // console.log("testing",/^[0-9 ]+$/.test(v))
                        if(parseInt(v)<=100){
                             console.log("if none")
                        }else{
                            console.log("else none")
                             return "Must be less than 100"
                            
                        }
    
                        // return v=>/^[0-9 ]+$/.test(v) || `Only Numbers are allowed.`
                    }

        },

        Numeric2(v){

        if (v.length !== 0) {
            console.log("testing",/^[0-9 ]+$/.test(v))
            if(!/^[0-9 ]+$/.test(v)){
                return "Only Numbers are allowed."
            }else{
            console.log("else none")
             
            }
    
            // return v=>/^[0-9 ]+$/.test(v) || `Only Numbers are allowed.`
        }

        },

        onClickOutside(){
            this.tgdd = false;
        },

        functionis(all, amt){
            var val = false;
             for(let i=0; i<all.length; i++){
                    console.log(amt,"i is ",typeof amt)

                     if(all[i] == 'Flat amount'){
                         console.log("ffaa ",amt.length == 0)
                        if(amt.length == 0){
 console.log("fl amt");
                            val = true
                        }
                        
                     }
                        if(val){
                             console.log("reaching");
                        return  "we got something"
                        break;
                        }
                    
             }
            
        },

        checkflatSI(indata,dval){

            // dval.replace(/[^0-9]+/g, '');

            console.log("indata is ->",indata);
            var indis =  this.reldatais.findIndex(e=> e.coverType == indata.coverType);

            // console.log('running regex',this.reldatais[indis].flatAmount.replace(/[^0-9]+/g, ''));
            //  this.reldatais[indis].flatAmount = this.reldatais[indis].flatAmount.replace(/[^0-9]+/g, '')
            //  this.reldatais[indis].siPercent = this.reldatais[indis].siPercent.replace(/[^0-9]+/g, '');

            console.log("index is ->",indis);
            // console.log("the array object us ", ) 
            var faval = false
            var sival = false

                this.reldatais[indis].roomRentLimit.map(e=>{
                    if(e == 'Flat amount'){
                        faval = true
                    }
                }) 

                this.reldatais[indis].roomRentLimit.map(e=>{
                    if(e == '% of SI'){
                        sival = true
                    }
                })

            if(faval && sival){
                console.log("this.reldatais[indis] ",this.reldatais[indis]);
                    console.log(faval,"inside en_app change",sival)
                    console.log("appVal -> ", this.reldatais[indis].applicableValue);


                this.reldatais[indis].en_app = true

                if(this.reldatais[indis].applicableValue == '' || this.reldatais[indis].applicableValue == undefined){
                  this.reldatais[indis].app = true
                  console.log(" -> inside IF of appVal true")
                    this.$forceUpdate()
                }else{

                  this.reldatais[indis].app = false 
                   
                     console.log(" -> inside ELSE of appVal false and clearing")
                     this.$forceUpdate();
                }

            }else{
                  this.reldatais[indis].en_app = false
                  this.reldatais[indis].app = false 
                  this.reldatais[indis].applicableValue = ''
                    console.log(" -> OUTTER ELSE of appVal false and clearing")
                      this.$forceUpdate()
            }


            if(this.reldatais[indis].roomRentLimit.length == 0){
                     this.reldatais[indis].en_fa = false;
                     this.reldatais[indis].en_si = false;
                     this.reldatais[indis].si = false;
                     this.reldatais[indis].fa = false;
                     this.reldatais[indis].siPercent = ''
                     this.reldatais[indis].flatAmount = ''
                     this.reldatais[indis].en_app = false
                     this.reldatais[indis].app = false 
                     this.reldatais[indis].applicableValue = ''
            }


            this.reldatais[indis].roomRentLimit.map(e=>{
                    if(faval){

                        this.reldatais[indis].en_fa = true
                        if(this.reldatais[indis].flatAmount == ''){
                            this.reldatais[indis].fa = true
                        }else{
                            // console.log('NO ERROR')
                             this.reldatais[indis].fa = false
                        }
                         
                    }else{
                        
                         this.reldatais[indis].en_fa = false 
                         this.reldatais[indis].fa = false
                          
                     this.reldatais[indis].flatAmount = ''
                         
                    }

                    if(sival){

                         this.reldatais[indis].en_si = true

                        if(this.reldatais[indis].siPercent == ''){
                            this.reldatais[indis].si = true
                        }else{
                            // console.log('NO ERROR')
                             this.reldatais[indis].si = false
                        }

                    }else{
                        // console.log("BEFORE reached final else",  this.reldatais[indis])
                          this.reldatais[indis].en_si = false
                          this.reldatais[indis].si = false
                          this.reldatais[indis].siPercent = ''
                          
                        // console.log("AFTER reached final else",  this.reldatais[indis])
                    }
            })

            //   if(this.reldatais[indis].roomRentLimit.length == 0){
            //          this.reldatais[indis].en_fa = false;
            //           this.reldatais[indis].en_si = false;
            // }
            // this.reldatais[indis].roomRentLimit.map(e=>{
                
            //         if(e == '% of SI'){
            //              this.reldatais[indis].en_si = true

            //             if(this.reldatais[indis].siPercent == ''){
            //                 this.reldatais[indis].si = true
            //             }else{
            //                 console.log('NO ERROR')
            //                  this.reldatais[indis].si = false
            //             }

            //         }else{
            //               this.reldatais[indis].en_si = false
            //         }

            //         if(e == 'Flat amount'){
            //             this.reldatais[indis].en_fa = true
            //             if(this.reldatais[indis].flatAmount == ''){
            //                 this.reldatais[indis].fa = true
            //             }else{
            //                 console.log('NO ERROR')
            //                  this.reldatais[indis].fa = false
            //             }
                         
            //         }else{
            //              this.reldatais[indis].en_fa = false 
            //         }
            // })



            // coverType
            

        },

        nextTab(){
            console.log("emitted")
            this.$emit('next');
        },
        returnTab(){
            console.log("emitted")
            this.$emit('return');
        },

        setddnow(indata){

            console.log(this.relationshipsCoverd,"addnow indata", indata);

            var dind = this.syncarr.findIndex(e =>e.display == indata.display )

            if(dind != -1){
                this.syncarr[dind].checked = indata.checked;
            }else{
                this.syncarr.push({display:indata.display, checked:indata.checked});
            }


            console.log("SYNC ARR NOW -> ",this.syncarr);

        },

        del(indata){
            console.log("individual indata in del is ", indata)
            console.log(this.relationshipsCoverd, "<- relationshipsCoverd | val is  | this.syncarr ->",  this.syncarr);
            var possis = this.syncarr.findIndex(e=> e.display == indata)
            console.log("poss is -> ", this.syncarr[possis]);
            this.syncarr[possis].checked = !this.syncarr[possis].checked 
        //    this.syncarr.map(e=>{
        //         // console.log('selecetd values',e)
        //         // if(e == 'Self'){
        //         //     this.ss = true
        //         // }
        //             this.reldatais.map(rel=>{
        //                     console.log(e," <- Rel is -> ", rel.coverType);
        //                 if(rel.coverType == e.display){
        //                     rel.active = false
        //                 }
        //             })                     
        //    });

            // return
            
        //     console.log("indata is ",indata);
        //      var ad_ss = false , ad_sp = false, ad_ch = false , ad_pt = false
        //             if(this.self.checkbox){
        //                     ad_ss = true
        //             }else{

        //             }
                
        //             if(this.spouse.checkbox){
        //                     ad_sp = true
        //             }

        //             if(this.parent.checkbox){
        //                     ad_pt = true
        //             }

        //             if(this.children.checkbox){
        //                     ad_ch = true
        //             }
        //       var selarr = []
        //         if(indata == 'Self'){
        //         if(ad_ss){
                  
        //                 selarr.push(indata)
                        
                
        //          }else{
        //             var pos = this.delarr.indexOf(indata)
        //             this.delarr.splice(pos, 1);
        //             // this.self.siSubLimit=''
        //             // this.delarr=[]
                    
                    
        //          }
        //         }
              
        //         if(indata == 'Children'){
        //           if(ad_ch){
                  
        //                 selarr.push(indata)
                
        //          }else{
        //              var pos = this.delarr.indexOf(indata)
        //             this.delarr.splice(pos, 1);
                    
        //          }
        //         }

        //         if(indata == 'Parent'){
        //           if(ad_pt){
                    
        //                 selarr.push(indata)
                    
        //          }else{
        //              var pos = this.delarr.indexOf(indata)
        //             this.delarr.splice(pos, 1);
        //          }
        //         }


        //           if(indata == 'Spouse'){
        //           if(ad_sp){
                    
        //                 selarr.push(indata)
                    
        //          }else{
        //             var pos = this.delarr.indexOf(indata)
        //             this.delarr.splice(pos, 1);
        //             // this.relationshipsCoverd.splice(pos,1);
        //          }
        //           }
            
            

        //    this.delarr.push(...selarr);

        //     console.log("collection inside del arr is ->",this.delarr)
        },

        nextStep(){
            this.nextbtn=false
        },

        delnow(){
            console.log(this.syncarr,'delnow ', this.syncarr.length)
            // if(this.syncarr.length == 0){
            //        this.reldatais.map(rel=> rel.active = false)
            // }
          
            var toberemoved = []
            this.syncarr.map((e,index)=>{
                console.log('sync value ',e)
                // if(e == 'Self'){
                //     this.ss = true
                // }
              
                        if(e.checked == false){
                           
                              this.reldatais.map(rel=>{

                                  if(e.display == rel.coverType){
                                        rel.active = false
                                        rel.checkbox = false
                                       
                                        rel.app = false
                                        rel.applicableValue = ''
                                       
                                        rel.coPay = ''
                                
                                        rel.en_app = false
                                        rel.en_fa = false
                                        rel.en_si = false
                                        rel.fa = false
                                        rel.flatAmount = ''
                                        rel.roomRentLimit = []
                                        rel.si = false
                                        rel.siPercent = ''
                                        rel.siSubLimit = ''
                                       rel.waitingPeriod = ''
                                        // rel.
                                       
                                       this.selectRelationshipsCoverd.map(sel=>{
                                            if(e.display == sel.display){
                                              
                                                toberemoved.push(index)
                                                console.log(e.display, "<- e.display | sel display is ->", sel.display)
                                                sel.aftercheck =false
                                                sel.checked = false

                                             
                                            }
                                       })
                                  }

                              })
                              


                        }                    
           });

            console.log(toberemoved," < - toberemoved - > ", toberemoved.length);

            if(toberemoved.length == 0){
                  this.showToast('Please select an item to delete', this.TOST().WARNING);
                return
            }

            // this.syncarr.map((e,index)=>{
            //     console.log('sync value ',e)
            //     // if(e == 'Self'){
            //     //     this.ss = true
            //     // }
                    // if(e.checked == false){
                           for(var i = toberemoved.length -1; i >= 0; i--){
                                this.syncarr.splice(toberemoved[i],1);
                           }

                        var onlyactiveis = []
                           this.activeonly.map((ac,ind)=>{
                               this.reldatais.map(e=>{
                                    if(e.coverType == ac){
                                        if(!e.active){
                                            console.log("ac and ind", ind)
                                            onlyactiveis.push(ind)
                                        }
                                    }
                               })
                           })

                        for(var i = onlyactiveis.length -1; i >= 0; i--){
                                this.activeonly.splice(onlyactiveis[i],1);
                           }
                                
                    // }
            // });
                           

            return
            //   this.delarr.map(e=>{

            //     console.log('selecetd values',e)

            //     if(e == 'Self'){
                    
            //         this.ss = false
            //         var pos = this.selectRelationshipsCoverd.indexOf()
            //     //    this.selectRelationshipsCoverd.splice(pos, 1);
            //     //    this.self.siSubLimit=''

            
            // this.self.checkbox=false,
            // this.self.siSubLimit='',
            // this.self.coPay='',
            // this.self.roomRentLimit='',
            // this.self.flatAmount='',
            // this.self.percentOfsi='',
            // this.self.applicableValue='',
            // this.self.waitingPeriod=''
        
            //     }

            //     if(e == 'Spouse'){
            //         this.sp = false
            //         var pos = this.selectRelationshipsCoverd.indexOf()
            //     //    this.selectRelationshipsCoverd.splice(pos, 1);
            //     }

            //     if(e == 'Children'){
            //         this.ch = false
            //         var pos = this.selectRelationshipsCoverd.indexOf()
            //     //    this.selectRelationshipsCoverd.splice(pos, 1);
            //     }

            //     if(e == 'Parent'){
            //         this.pt = false
            //         var pos = this.selectRelationshipsCoverd.indexOf()
            //     //    this.selectRelationshipsCoverd.splice(pos, 1);
            //     }
            //     if(e==''){
            //         this.relationshipsCoverd
            //     }
                
            // })
              
        },

        selectAdd(){
            
            console.log(this.syncarr ," sync arr | relationshipsCoverd---->",this.selectRelationshipsCoverd)

            this.syncarr.map(e=>{
                console.log('SYNC arr e value is ---->',e)
                // if(e == 'Self'){
                //     this.ss = true
                // }
                if(e.checked){
                     this.selectRelationshipsCoverd.map(sel=>{
                           console.log('selectRelationshipsCoverd  sel value is ---->',sel);
                        if(sel.checked == true && sel.display == e.display){
                            console.log(e.display, "<- e.display | sel display is ->", sel)
                            sel.aftercheck =true
                        }
                    })


                    this.reldatais.map(rel=>{
                            console.log(e," <- Rel is -> ", rel.coverType);
                        if(rel.coverType == e.display){
                            rel.active = true
                        }
                    })
                }else{
                    console.log("as SYNC arr element is false? ",e)
                }
                           

                // if(e == 'Spouse'){
                //     this.sp = true
                // }

                // if(e == 'Children'){
                //     this.ch = true
                // }

                // if(e == 'Parent'){
                //     this.pt = true
                // }

            })

            this.reldatais.map(e=>{
                if(e.active == true){
                    this.activeonly.push(e.coverType)
                }
            })

           var uniq = [...new Set(this.activeonly)];
             this.activeonly = uniq
            // if(this.relationshipsCoverd==="No"){
            //     this.showSelf=false
            // }
            // if(this.relationshipsCoverd==="Self"){
            //     this.showSelf=true
            // }
        },


    relDep_save(){

        console.log("what we have here for validate ", this.$refs.form.validate)
        // fd99
        var fa_arr = []
        var si_arr = []

           for(let i=0; i<this.reldatais.length; i++){
                        console.log("inside reldata");
                if(this.reldatais[i].roomRentLimit.length == 0){
                    //  this.reldatais[i].en_fa = false;
                    //   this.reldatais[i].en_si = false;
                }else{
                     this.reldatais[i].roomRentLimit.map(e=>{
                    if(e == 'Flat amount'){

                        if(this.reldatais[i].en_fa){
                            if(this.reldatais[i].fa){
                                fa_arr.push("1")
                            }
                        }

                        if(this.reldatais[i].en_app){
                            if(this.reldatais[i].app){
                                fa_arr.push("1")
                            }
                        }


                
                        //  this.reldatais[i].en_fa = true
                        // if(this.reldatais[i].flatAmount == ''){
                        //     this.reldatais[i].fa = true
                        // }else{
                        //     console.log('NO ERROR')
                        //      this.reldatais[i].fa = false
                        // }
                         
                    }else{
                        //  this.reldatais[i].en_fa = false
                    }

                     if(e == '% of SI'){
                          if(this.reldatais[i].en_si){
                            if(this.reldatais[i].si){
                                si_arr.push("1")
                            }
                        }

                        if(this.reldatais[i].en_app){
                            if(this.reldatais[i].app){
                                si_arr.push("1")
                            }
                        }
                        //  this.reldatais[i].en_si = true
                        // if(this.reldatais[i].siPercent == ''){
                        //     this.reldatais[i].si = true
                        // }else{
                        //     console.log('NO ERROR')
                        //      this.reldatais[i].si = false
                        // }
                    }else{
                        //  this.reldatais[i].en_si = false
                    }

                  
                })
                }
            }

            if(si_arr.length != 0 || fa_arr.length != 0){
                    console.log(si_arr,"from si and fa arr",fa_arr);
                   this.showToast('Please fill all required fields', this.TOST().WARNING);
                return
            }else{
                console.log("proceed ahead ")
                // return
            }
        


        if(this.$refs.form.validate()){

            


            //  this.$v.$touch();
            //  if(this.$v.DductbleAmntPrClm.$invalid ||  this.$v.DductbleAmntAnulAgg.$invalid||this.$v.WtngPrdAplble.$invalid||this.$v.WtngPrdNofDys.$invalid){
                //    if(this.$v.$invalid){
                //    console.log("Form has errors");
                console.log('proceed',this.reldatais)
                console.log("RelationshipsCoverd",this.RelationshipsCoverd)

                

                this.nextbtn=false;
                var onlyactivedata = []
                this.reldatais.map(e=>{
                        if(e.active == true){
                              onlyactivedata.push(e)
                        }
                })

                var onlytypes = onlyactivedata.map(e=> {
                    return e.coverType
                }) 
             console.log(' relationShipsCoveredDetails=>  ',onlytypes);
             onlyactivedata=onlyactivedata.map(e=> {
                    return{
                            active: e.active,
                            applicableValue: e.applicableValue,
                            checkbox: e.checkbox,
                            coPay: e.coPay,
                            coverType: e.coverType,
                            flatAmount:e.flatAmount,
                            roomRentLimit: e.roomRentLimit,
                            siPercent: e.siPercent,
                            siSubLimit:  e.siSubLimit,
                            waitingPeriod: e.waitingPeriod,
                    }
                }) 
                console.log(' onlyactivedata=>  ',onlyactivedata);
             let self=this
             var formData ={
                relationShipsCovered: onlytypes,
                relationShipsCoveredDetails:onlyactivedata
             }

            let apiURL=''
            if(self.btnName==="ConfgrPlnFrPlcy"){
                apiURL=self.API_Service_admin()+"group-plan/update-group-relation-dependent/"+self.plan._id
            }else if(self.btnName==="CnfgrNwRtlPln"){
                apiURL=self.API_Service_admin()+"retail-plan/update-retail-relation-dependent/"+self.plan._id
            }

            console.log("Stopped as simulating");
            
            // this.nextbtn=false
            console.log('relation-dependent Plan form saved data',formData)
            axios({
                method: "put",
                url:apiURL,
                headers: {
                    "Content-Type": "application/json",
                    "x-access-token": self.token,
                    // "x-access-token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI1ZmJlMDg4ZGM0NmRhODNlZTdmODcxYmIiLCJyb2xlIjoiQWRtaW5Vc2VyIiwiaWF0IjoxNjA2MzY5MzI0LCJleHAiOjE2Mzc5MDUzMjR9.iS5hZs0uImNFz-rxNaQ7CTCc6qS_hG4H0FsePZ5Sgqc",
                },
                data:formData,
                })
            .then(function (response) {
                console.log(" relation-dependent for product API Response>>>>>>", response);
                self.showToast(response.data.msg ,self.TOST().SUCCESS)

            })
            .catch(function (error) {
                console.log(" relation-dependent Error APi Response>>>>>>", error);
                self.showToast(error.response.data.msg,self.TOST().ERROR);
            });
            
        }else{

            console.log("from else");
                this.$refs.form.validate()
                this.showToast("Please Fill Required Fields",this.TOST().WARNING);
        } 

    },

    getGroupPlanbyId(planId){
        // gp
            console.log(planId," Incoming PlanId")
            let self = this;   
            axios({
                method: "get",
                url:self.API_Service_admin()+"group-plan/get-group-plan/"+planId, 
                headers: {
                    "Content-Type": "application/json", 
                    "x-access-token": self.token,
                }
            })
            .then(function(response) {
                console.log('group-plan/get-group-plan Response------->>>',response.data.data);
                let pdtDta={}
                pdtDta=response.data.data
                self.planItems.push(pdtDta)
                self.plan=self.planItems[0]
                self.RelationshipsCoverd=pdtDta.hasOwnProperty('relationShipDependentInfo')?pdtDta.relationShipDependentInfo.hasOwnProperty('relationShipsCovered')?self.chkplndataarry(pdtDta.relationShipDependentInfo.relationShipsCovered):[]:[]
                // self.selectAdd()
                let temprelationShipsCoveredDetails=pdtDta.hasOwnProperty('relationShipDependentInfo')?pdtDta.relationShipDependentInfo.hasOwnProperty('relationShipsCoveredDetails')?self.chkplndataarry(pdtDta.relationShipDependentInfo.relationShipsCoveredDetails):[]:[]

                console.log(temprelationShipsCoveredDetails,"temprelationShipsCoveredDetails")
                console.log(self.reldatais,"reldatais")

                 for(let j=0;j<temprelationShipsCoveredDetails.length;j++){
                    for(let i=0;i<self.reldatais.length;i++){
                        console.log("temp",temprelationShipsCoveredDetails[i])
                        console.log(" <-real",self.reldatais[i])
                        if(self.reldatais[i].coverType===temprelationShipsCoveredDetails[j].coverType){
                            self.reldatais[i]=temprelationShipsCoveredDetails[j]
                            self.reldatais[i].checkbox = false
                            // self.reldatais[i]
                    }
                    
                    }
                }


            for(let i=0; i<self.reldatais.length; i++){
                        // console.log("inside reldata");
                if(self.reldatais[i].roomRentLimit.length == 0){
                     self.reldatais[i].en_fa = false;
                      self.reldatais[i].en_si = false;
                }else{


                    self.reldatais[i].roomRentLimit.map(e=>{
                          console.log("***************E is --->",  e);

                        var faval = false
                        var sival = false

                        self.reldatais[i].roomRentLimit.map(e=>{
                            if(e == 'Flat amount'){
                             faval = true
                            }
                        }) 

                        self.reldatais[i].roomRentLimit.map(e=>{
                            if(e == '% of SI'){
                                sival = true
                            }
                        })

                        // if(faval || sival){
                        //      if(self.reldatais[i].applicableValue == ''){
                        //             self.reldatais[i].en_app = true
                        //      }else{
                        //             self.reldatais[i].en_app = false
                        //             self.reldatais[i].applicableValue = ''
                        //      }
                        // }else{
                        //     self.reldatais[i].en_app = false
                        //     self.reldatais[i].applicableValue = ''
                        // }
                // grp
                if(faval && sival){
                        console.log("this.reldatais[indis] ",self.reldatais[i]);
                            console.log(faval,"inside en_app change",sival)
                            console.log("appVal -> ", self.reldatais[i].applicableValue);


                        self.reldatais[i].en_app = true

                        if(self.reldatais[i].applicableValue == '' || self.reldatais[i].applicableValue == undefined){
                        self.reldatais[i].app = true
                        console.log(" -> inside IF of appVal true")
                            self.$forceUpdate()
                        }else{

                        self.reldatais[i].app = false 
                        
                            console.log(" -> inside ELSE of appVal false and clearing")
                            self.$forceUpdate();
                        }

                    }else{
                        self.reldatais[i].en_app = false
                        self.reldatais[i].app = false 
                        self.reldatais[i].applicableValue = ''
                            console.log(" -> OUTTER ELSE of appVal false and clearing")
                            self.$forceUpdate()
                    }


                    if(faval){
                        
                         self.reldatais[i].en_fa = true
                        if(self.reldatais[i].flatAmount == ''){
                            self.reldatais[i].fa = true
                        }else{
                            console.log('NO ERROR')
                             self.reldatais[i].fa = false
                        }
                         
                    }else{
                        console.log("else of flat and e is ", e)
                         self.reldatais[i].en_fa = false
                    }

                    if(sival){
                        //   alert("% of SI");
                         self.reldatais[i].en_si = true
                        if(self.reldatais[i].siPercent == ''){
                            self.reldatais[i].si = true
                        }else{
                            console.log('NO ERROR')
                             self.reldatais[i].si = false
                        }
                    }else{
                         console.log("else of SI and e is ", e)
                         self.reldatais[i].en_si = false
                    }

                  
                })
                }
            }


            

            //  for(let i=0; i<self.reldatais.length; i++){
            //             console.log("inside reldata");
            //     if(self.reldatais[i].roomRentLimit.length == 0){
            //          self.reldatais[i].en_fa = false;
            //           self.reldatais[i].en_si = false;
            //     }else{
            //          self.reldatais[i].roomRentLimit.map(e=>{
                  

            //         if(e == '% of SI'){
            //              self.reldatais[i].en_si = true
            //             if(self.reldatais[i].siPercent == ''){
            //                 self.reldatais[i].si = true
            //             }else{
            //                 console.log('NO ERROR')
            //                  self.reldatais[i].si = false
            //             }
            //         }else{
            //              self.reldatais[i].en_si = false
            //         }

            //         if(e == 'Flat amount'){
            //              self.reldatais[i].en_fa = true
            //             if(self.reldatais[i].flatAmount == ''){
            //                 self.reldatais[i].fa = true
            //             }else{
            //                 console.log('NO ERROR')
            //                  self.reldatais[i].fa = false
            //             }
                         
            //         }else{
            //              self.reldatais[i].en_fa = false
            //         }

                  
            //     })
            //     }
            // }



                self.reldatais.map(indata=>{

                    //   var dind = self.syncarr.findIndex(e =>e.display == indata.coverType )

                        if(indata.active == true){
                    //         self.syncarr[dind].checked = indata.checked;
                    //     }else{
                            // self.selectRelationshipsCoverd
                            self.syncarr.push({display:indata.coverType, checked:indata.active});
                        }


                })

                self.RelationshipsCoverd = []

                self.reldatais.map(indata=>{

                    //   var dind = self.syncarr.findIndex(e =>e.display == indata.coverType )
                    if(indata.active == true){
                     self.selectRelationshipsCoverd.map(sel=>{

                            if(indata.coverType == sel.display){
                                    sel.checked = true;
                                    sel.aftercheck = true;
                                self.RelationshipsCoverd.push(indata.coverType)
                            }


                     })
                    }
                })


                // self.setddnow()

                // self.selectAdd()


            //    for(let i=0; i<self.reldatais.length; i++){

            //     if(self.reldatais[i].roomRentLimit.length == 0){
            //          self.reldatais[i].en_fa = false;
            //           self.reldatais[i].en_si = false;

            //     }else{
            //         self.reldatais[i].roomRentLimit.map(e=>{
            //         // if(e == 'Flat amount'){
            //         //      self.reldatais[i].en_fa = true
            //         //     if(self.reldatais[i].flatAmount == ''){
            //         //         self.reldatais[i].fa = true
            //         //     }else{
            //         //         console.log('NO ERROR')
            //         //          self.reldatais[i].fa = false
            //         //     }
                         
            //         // }else{
            //         //      self.reldatais[i].en_fa = false
            //         // }

            //         if(e == '% of SI'){
            //              self.reldatais[i].en_si = true
            //             if(self.reldatais[i].siPercent == ''){
            //                 self.reldatais[i].si = true
            //             }else{
            //                 console.log('NO ERROR')
            //                  self.reldatais[i].si = false
            //             }
            //         }else{
            //              self.reldatais[i].en_si = false
            //         }
            //     })
            //     }

               

            // }

                console.log("processed Reldata is",self.reldatais);
                console.log("processed Checkedbox data is",self.selectRelationshipsCoverd);
                   console.log("processed Syncarr data is",self.syncarr);

            self.reldatais.map(e=>{
                if(e.active == true){
                    self.activeonly.push(e.coverType)
                }
            })

           var uniq = [...new Set(self.activeonly)];
             self.activeonly = uniq

            })
            .catch(function(error) {
                console.log(error);
                // self.showToast(error.response.data.msg,self.TOST().ERROR);
            });
        },

        chkplndata(data){
            console.log("chkplndata",data); 
            try{
                if (data ==="" || data === "undefined" || data === null) {
                    return ""
                } else {
                    return data;
                }

             }catch(err) {
                console.log(err)
            }
            
        },

        chkplndataarry(data){
            console.log("chkplndataarry",data); 
            try{
                if (data ==="" || data === "undefined" || data === null) {
                    return []
                } else {
                    return data;
                }

             }catch(err) {
                console.log(err)
            }
            
        },

        getRetailPlanbyId(planId){
            // rb99
            console.log(planId," Incoming PlanId")
            let self = this;   
            axios({
                method: "get",
                url:self.API_Service_admin()+"retail-plan/get-retail-plan/"+planId, 
                headers: {
                    "Content-Type": "application/json", 
                    "x-access-token": self.token,
                }
            })
            .then(function(response) {
                console.log('retail-plan/get-retail-plan/------->>>',response.data.data);
                let pdtDta={}
                pdtDta=response.data.data
                self.planItems.push(pdtDta)
                self.plan=self.planItems[0]
                self.RelationshipsCoverd=pdtDta.hasOwnProperty('relationShipDependentInfo')?pdtDta.relationShipDependentInfo.hasOwnProperty('relationShipsCovered')?self.chkplndataarry(pdtDta.relationShipDependentInfo.relationShipsCovered):[]:[]
                // self.selectAdd()
                let temprelationShipsCoveredDetails=pdtDta.hasOwnProperty('relationShipDependentInfo')?pdtDta.relationShipDependentInfo.hasOwnProperty('relationShipsCoveredDetails')?self.chkplndataarry(pdtDta.relationShipDependentInfo.relationShipsCoveredDetails):[]:[]

                console.log(temprelationShipsCoveredDetails,"temprelationShipsCoveredDetails")
                console.log(self.reldatais,"reldatais")

                 for(let j=0;j<temprelationShipsCoveredDetails.length;j++){
                    for(let i=0;i<self.reldatais.length;i++){
                        console.log("temp",temprelationShipsCoveredDetails[i])
                        console.log("real",self.reldatais[i])
                        if(self.reldatais[i].coverType===temprelationShipsCoveredDetails[j].coverType){
                            self.reldatais[i]=temprelationShipsCoveredDetails[j]
                    }
                    
                    }
                }



              for(let i=0; i<self.reldatais.length; i++){
                        // console.log("inside reldata");
                if(self.reldatais[i].roomRentLimit.length == 0){
                     self.reldatais[i].en_fa = false;
                      self.reldatais[i].en_si = false;
                }else{

                    self.reldatais[i].roomRentLimit.map(e=>{
                          console.log("***************E is --->",  e);

                        var faval = false
                        var sival = false

                        self.reldatais[i].roomRentLimit.map(e=>{
                            if(e == 'Flat amount'){
                             faval = true
                            }
                        }) 

                        self.reldatais[i].roomRentLimit.map(e=>{
                            if(e == '% of SI'){
                                sival = true
                            }
                        })

                        // if(faval || sival){
                            
                        //      if(self.reldatais[i].applicableValue == ''){
                        //             self.reldatais[i].en_app = true
                        //      }else{
                        //             self.reldatais[i].en_app = false;
                        //             self.reldatais[i].applicableValue = ''
                        //      }
                        // }else{
                        //     self.reldatais[i].en_app = false
                        //       self.reldatais[i].applicableValue = ''
                        // }

                      if(faval && sival){
                        console.log("this.reldatais[indis] ",self.reldatais[i]);
                            console.log(faval,"inside en_app change",sival)
                            console.log("appVal -> ", self.reldatais[i].applicableValue);


                        self.reldatais[i].en_app = true

                        if(self.reldatais[i].applicableValue == '' || self.reldatais[i].applicableValue == undefined){
                        self.reldatais[i].app = true
                        console.log(" -> inside IF of appVal true")
                            self.$forceUpdate()
                        }else{

                        self.reldatais[i].app = false 
                        
                            console.log(" -> inside ELSE of appVal false and clearing")
                            self.$forceUpdate();
                        }

                    }else{
                        self.reldatais[i].en_app = false
                        self.reldatais[i].app = false 
                        self.reldatais[i].applicableValue = ''
                            console.log(" -> OUTTER ELSE of appVal false and clearing")
                            self.$forceUpdate()
                    }



                    if(faval){
                        
                         self.reldatais[i].en_fa = true
                        if(self.reldatais[i].flatAmount == ''){
                            self.reldatais[i].fa = true
                        }else{
                            console.log('NO ERROR')
                             self.reldatais[i].fa = false
                        }
                         
                    }else{
                        console.log("else of flat and e is ", e)
                         self.reldatais[i].en_fa = false
                    }

                    if(sival){
                        //   alert("% of SI");
                         self.reldatais[i].en_si = true
                        if(self.reldatais[i].siPercent == ''){
                            self.reldatais[i].si = true
                        }else{
                            console.log('NO ERROR')
                             self.reldatais[i].si = false
                        }
                    }else{
                         console.log("else of SI and e is ", e)
                         self.reldatais[i].en_si = false
                    }
                })
                }
            }


            //     for(let i=0; i<self.reldatais.length; i++){
            //             console.log("inside reldata");
            //     if(self.reldatais[i].roomRentLimit.length == 0){
            //          self.reldatais[i].en_fa = false;
            //           self.reldatais[i].en_si = false;
            //     }else{
            //          self.reldatais[i].roomRentLimit.map(e=>{
            //         if(e == 'Flat amount'){
            //              self.reldatais[i].en_fa = true
            //             if(self.reldatais[i].flatAmount == ''){
            //                 self.reldatais[i].fa = true
            //             }else{
            //                 console.log('NO ERROR')
            //                  self.reldatais[i].fa = false
            //             }
                         
            //         }else{
            //              self.reldatais[i].en_fa = false
            //         }

            //          if(e == '% of SI'){
            //              self.reldatais[i].en_si = true
            //             if(self.reldatais[i].siPercent == ''){
            //                 self.reldatais[i].si = true
            //             }else{
            //                 console.log('NO ERROR')
            //                  self.reldatais[i].si = false
            //             }
            //         }else{
            //              self.reldatais[i].en_si = false
            //         }

                  
            //     })
            //     }
            // }

            //  for(let i=0; i<self.reldatais.length; i++){
            //             console.log("inside reldata",  self.reldatais[i]);
            //     if(self.reldatais[i].roomRentLimit.length == 0){
            //          self.reldatais[i].en_fa = false;
            //           self.reldatais[i].en_si = false;
            //     }else{
            //          self.reldatais[i].roomRentLimit.map(e=>{
                  
            //              console.log("E is --->",  e);
            //         if(e == '% of SI'){
            //             alert("from % of SI")
            //              self.reldatais[i].en_si = true
            //             if(self.reldatais[i].siPercent == ''){
            //                 self.reldatais[i].si = true
            //             }else{
            //                 console.log('NO ERROR')
            //                  self.reldatais[i].si = false
            //             }
            //         }else{
            //              self.reldatais[i].en_si = false
            //         }

            //         if(e == 'Flat amount'){
            //                alert("Flat amount")
            //              self.reldatais[i].en_fa = true
            //             if(self.reldatais[i].flatAmount == ''){
            //                 self.reldatais[i].fa = true
            //             }else{
            //                 console.log('NO ERROR')
            //                  self.reldatais[i].fa = false
            //             }
                         
            //         }else{
            //              self.reldatais[i].en_fa = false
            //         }

                  
            //     })
            //     }
            // }



                self.reldatais.map(indata=>{

                    //   var dind = self.syncarr.findIndex(e =>e.display == indata.coverType )

                        if(indata.active == true){
                    //         self.syncarr[dind].checked = indata.checked;
                    //     }else{
                            // self.selectRelationshipsCoverd
                            self.syncarr.push({display:indata.coverType, checked:indata.active});
                        }


                })

                self.RelationshipsCoverd = []

                self.reldatais.map(indata=>{

                    //   var dind = self.syncarr.findIndex(e =>e.display == indata.coverType )
                    if(indata.active == true){
                     self.selectRelationshipsCoverd.map(sel=>{

                            if(indata.coverType == sel.display){
                                    sel.checked = true;
                                    sel.aftercheck = true;
                                self.RelationshipsCoverd.push(indata.coverType)
                            }


                     })
                    }
                })


                // self.setddnow()

                // self.selectAdd()


            //    for(let i=0; i<self.reldatais.length; i++){

            //     if(self.reldatais[i].roomRentLimit.length == 0){
            //          self.reldatais[i].en_fa = false;
            //           self.reldatais[i].en_si = false;

            //     }else{
            //         self.reldatais[i].roomRentLimit.map(e=>{
            //         // if(e == 'Flat amount'){
            //         //      self.reldatais[i].en_fa = true
            //         //     if(self.reldatais[i].flatAmount == ''){
            //         //         self.reldatais[i].fa = true
            //         //     }else{
            //         //         console.log('NO ERROR')
            //         //          self.reldatais[i].fa = false
            //         //     }
                         
            //         // }else{
            //         //      self.reldatais[i].en_fa = false
            //         // }

            //         if(e == '% of SI'){
            //              self.reldatais[i].en_si = true
            //             if(self.reldatais[i].siPercent == ''){
            //                 self.reldatais[i].si = true
            //             }else{
            //                 console.log('NO ERROR')
            //                  self.reldatais[i].si = false
            //             }
            //         }else{
            //              self.reldatais[i].en_si = false
            //         }
            //     })
            //     }

               

            // }

                console.log("processed Reldata is",self.reldatais);
                console.log("processed Checkedbox data is",self.selectRelationshipsCoverd);
                   console.log("processed Syncarr data is",self.syncarr);

                
                     self.reldatais.map(e=>{
                if(e.active == true){
                    self.activeonly.push(e.coverType)
                }
            })

           var uniq = [...new Set(self.activeonly)];
             self.activeonly = uniq
                


            })
            .catch(function(error) {
                console.log(error);
                // self.showToast(error.response.data.msg,self.TOST().ERROR);
            });
        },


        //nowsave(){
        //   if (this.$refs.form.validate()){

            //     if(this.parent.checkbox=="" ){
            //            console.log("Form has errors");  
            //         this.showToast('Please fill all required fields', this.TOST().WARNING);
            //         return
            //  }else{
            //         console.log('proceed')
            //  }  

            // var formData =
            //     {

            //    parent_checkbox:this.parent.checkbox,         
            // parent_siSubLimit:this.parent.siSubLimit,
            // parent_coPay:this.parent.coPay,
            // parent_roomRentLimit:this.parent.roomRentLimit,
            // parent_flatAmount:this.parent.flatAmount,
            // parent_percentOfsi:this.parent.percentOfsi,
            // parent_applicableValue:this.parent.applicableValue,
            // parent_waitingPeriod:this.parent.waitingPeriod,
        
            

                
            //     override: false
            //     }
            

            // this.nextbtn=false
            // console.log('Info form saved data',formData)
        //}
        //  else{
        //       this.$refs.form.validate()
        //       this.showToast("Please Fill Required Fields",this.TOST().WARNING);
        //       }
        //},


        

    },

    watch: {
    
    },
    
    
}

</script>
<style >

.v-btn__content { width: 100% !important; white-space: normal !important; font-size:13px !important}

</style>