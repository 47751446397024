<template>
  <div class="ma-6">
    <v-form class="mt-5 ml-2" @submit.prevent="" ref="searchForm">
        <v-row>
          <v-col cols="2">
            <label class="form-label">Invoice Number</label>
            <v-text-field
              placeholder="Enter..."
              class="form-control"
              v-model="searchData.invoiceNumber"
              maxlength="100"
              solo
              dense
              depressed
            />
          </v-col>
          <v-col cols="2">
            <label class="form-label">Claim/Feature Number</label>
            <v-text-field
              readonly
              placeholder="Enter..."
              class="form-control"
              :rules="[(v) => matchRegex(v, 'alphanum')]"
              v-model="searchData.claimNumber"
              maxlength="100"
              solo
              dense
              depressed
            />
          </v-col>
          <v-col cols="2">
            <label class="form-label">Investigator Name</label>
            <v-text-field
              readonly
              placeholder="Enter..."
              class="form-control"
              :rules="[(v) => matchRegex(v, 'alphanum')]"
              v-model="searchData.investigatorName"
              maxlength="100"
              solo
              dense
              depressed
            />
          </v-col>
          <v-col cols="2">
            <label class="form-label">Email ID</label>
            <v-text-field
              readonly
              placeholder="Enter..."
              class="form-control"
              v-model="searchData.emailId"
              maxlength="100"
              solo
              dense
              depressed
              type="email"
            />
          </v-col>
          <v-col cols="2">
            <label class="form-label">Mobile Number</label>
            <v-text-field
              readonly
              placeholder="Enter..."
              class="form-control"
              :rules="[(v) => matchRegex(v, 'alphanum')]"
              v-model="searchData.mobileNumber"
              maxlength="100"
              solo
              dense
              depressed
            />
          </v-col>
          <v-col cols="2">
            <label class="form-label">Date</label>
            <v-text-field
              readonly
              placeholder="Enter..."
              class="form-control"
              v-model="searchData.dateTime"
              maxlength="100"
              solo
              dense
              depressed
            />
          </v-col>
        </v-row>
        <v-row>
        </v-row>
        <v-row>
          <v-col cols="2">
            <label class="form-label">Policy Number</label>
            <v-text-field
              readonly
              placeholder="Enter..."
              class="form-control"
              :rules="[(v) => matchRegex(v, 'alphanum')]"
              v-model="searchData.policyNumber"
              maxlength="100"
              solo
              dense
              depressed
            />
          </v-col>
          <v-col cols="2">
            <label class="form-label">Claim Type</label>
            <v-text-field
              readonly
              placeholder="Enter..."
              class="form-control"
              :rules="[(v) => matchRegex(v, 'alphanum')]"
              v-model="searchData.claimType"
              maxlength="100"
              solo
              dense
              depressed
            />
          </v-col>
          <v-col cols="2">
            <label class="form-label">GST number</label>
            <v-text-field
              readonly
              placeholder="Enter..."
              class="form-control"
              :rules="[(v) => matchRegex(v, 'alphanum')]"
              v-model="searchData.gstNumber"
              maxlength="100"
              solo
              dense
              depressed
            />
          </v-col>
          <v-col cols="2">
            <label class="form-label">Loss State</label>
            <v-text-field
              readonly
              placeholder="Enter..."
              class="form-control"
              :rules="[(v) => matchRegex(v, 'alphanum')]"
              v-model="searchData.state"
              maxlength="100"
              solo
              dense
              depressed
            />
          </v-col>
          <v-col cols="2">
            <label class="form-label">Loss City</label>
            <v-text-field
              readonly
              placeholder="Enter..."
              class="form-control"
              :rules="[(v) => matchRegex(v, 'alphanum')]"
              v-model="searchData.city"
              maxlength="100"
              solo
              dense
              depressed
            />
          </v-col>
        </v-row>
      </v-form>
    <v-card elevation="0" width="100%">
      <v-form v-model="gridForm">
      <v-data-table
        class="padding-0 vDataTableStyling"
        :headers="isSiuUser ? headers : headers2"
        :items="invoiceDetailsList"
        :hide-default-footer="true"
      >
        <template v-slot:top>
          <v-layout
            justify-space-between
            align-center
            style="width: 100%"
            class="py-2"
          >
          </v-layout>
        </template>
        <template v-slot:item.enterBillAmount="{ item }">
          <v-text-field
            v-if="item.sno  && item.sno != 9"
            v-model="item.enterBillAmount"
            placeholder="Enter..."
            class="form-control"
            :readonly="isSiuUser"
            solo
            dense
            depressed
          >
          </v-text-field>
        </template>
        <template v-slot:item.numberOfUnits="{ item }">
          <v-text-field
            v-if="item.sno && item.sno != 9"
            v-model="item.numberOfUnits"
            class="form-control"
            placeholder="Enter..."
            :readonly="isSiuUser"
            solo
            dense
            depressed
          >
          </v-text-field>
        </template>
        <template v-slot:item.requestedAmount="{ item }">
            <div style="display:none;">
              {{ (item.requestedAmount =
              item.numberOfUnits * item.enterBillAmount
                ? item.numberOfUnits * item.enterBillAmount
                : null) }}
            </div>
          <v-text-field
          v-if="item.sno && item.sno != 9" 
            readonly
            v-model="item.requestedAmount"
            class="form-control"
            solo
            dense
            depressed
          ></v-text-field>
          <v-text-field
          v-if="item.sno && item.sno == 9"
            readonly
            v-model="totalRequestedAmount"
            class="form-control"
            solo
            dense
            depressed
          ></v-text-field>
          <!-- {{ invoiceDetailsList.forEach((el)=> {el.}) }} -->
        </template>
        <template v-slot:item.investigatorRemarks="{ item }">
          <v-text-field
          v-if="item.sno && item.sno != 9"
            v-model="item.investigatorRemarks"
            class="form-control"
            solo
            :readonly="isSiuUser"
            dense
            depressed
          >
          </v-text-field>
        </template>
        <template v-slot:item.approvedAmount="{ item }">
          <v-text-field
          v-if="item.sno && item.sno != 9"
            placeholder="Enter..."
            v-model="item.approvedAmount"
            class="form-control"
            solo
            dense
            depressed
            @input="changeApprovedAmount(item)"
          >
          </v-text-field>
        </template>
        <template v-slot:item.disAllowedAmount="{ item }">
          <v-text-field
          v-if="item.sno && item.sno != 9"
            v-model="item.disAllowedAmount"
            readonly
            class="form-control"
            solo
            dense
          ></v-text-field>
        </template>
        <template template v-slot:item.finalApprovedAmount="{ item }">         
          <v-text-field
          v-if="item.sno && item.sno != 9"
            v-model="item.finalApprovedAmount"
            readonly
            class="form-control"
            solo
            dense
          ></v-text-field>
          <v-text-field
          v-if="item.sno == 9"
            v-model="totalApprovedAmount"
            readonly
            class="form-control"
            solo
            dense
          ></v-text-field>
        </template>
        <template template v-slot:item.qcManagerRemarks="{ item }">
          <v-text-field
          v-if="item.sno && item.sno != 9"
            v-model="item.qcManagerRemarks"
            class="form-control"
            solo
            dense
            depressed
          >
          </v-text-field>
        </template>
        <template v-slot:item.more="{ item }">
          <a @click="viewMore = true">View more</a>
        </template>
      </v-data-table>
    </v-form>
      <v-data-table
        class="padding-0 vDataTableStyling"
        :headers="isSiuUser ? headersTax : headersTax2"
        :items="invoiceDetailsListTax"
        :hide-default-footer="true"
      >
        <template v-slot:top>
          <v-layout
            justify-space-between
            align-center
            style="width: 100%"
            class="py-2"
          >
          </v-layout>
        </template>
        <template v-slot:item.rate="{ item }">
          {{item.rate}}%
        </template>
        <template v-slot:item.reqTaxAmt="{ item }">
          <div style="display: none;">
          {{ item.reqTaxAmt = (totalRequestedAmount * item.rate)/100 }}</div>
          <div v-if="item.taxParticulars != 'TDS'">
            {{ (totalRequestedAmount * item.rate)/100 }}
          </div>
        </template>
        <template v-slot:item.totalRequestedAmount="{ item }">
          {{ totalRequestedAmountAfterTax | decimal}}
        </template>
        <template v-slot:item.approvedApprovedAmount="{ item }">
          <div style="display: none;">
          {{ item.approvedApprovedAmount = (totalApprovedAmount * item.rate)/100 }}
        </div>
          <div>
            {{ (totalApprovedAmount * item.rate)/100 | decimal}}
          </div>
        </template>
        <template v-slot:item.finalApprovedAmount="{ item }">
          {{ totalApprovedAmountAfterTax | decimal}}
        </template>
        <template v-slot:item.more="{ item }">
          <a @click="viewMore = true">View more</a>
        </template>
      </v-data-table>
      <v-btn
            v-if="showSaveButton"
            dark
            tile
            color="#23B1A9"
            class="text-capitalize px-3 mx-2 my-2"
            small
            @click="saveData()"
          >Save
          </v-btn>
          <InvoicePdfPopUpVue v-if="showInvoicePDF" :siuInvoiceId="siuInvoiceId"></InvoicePdfPopUpVue>
    </v-card>
    <InvestigationFooterCard
      :tabno="sno"
    />
  </div>
</template>
<script>
import moment from "moment";
import InvestigationFooterCard from "./InvestigationFooterCard.vue";
import { getInvoiceDetails, createInvoiceDetails, updateInvoiceDetails } from "../apiservices/invoiceService";
import { getPrePopulatedData } from '../apiservices/Investigation';
import InvoicePdfPopUpVue from './InvoicePdfPopUp.vue';
export default {
  components: {InvestigationFooterCard, InvoicePdfPopUpVue},
  created() {
    this.getRoleValues();
    this.getInvestigationReportStatus();
  },
  mounted() {
    this.getInvoiceDetails();
  },
  methods: {
    changeApprovedAmount(item){
      item.disAllowedAmount = item.requestedAmount - item.approvedAmount ? item.requestedAmount - item.approvedAmount : ""
      item.finalApprovedAmount = item.approvedAmount
    },
    async getInvestigationReportStatus() {
      if(this.isSiuUser){
        let res = await getPrePopulatedData(this.$route.params.investigationWorkitemId);
        if (res.status == 'Submited') {
          this.showSaveButton = true;
        } else {
          this.showSaveButton = false;
        }
      } else {
        this.showSaveButton = true;
      }
    },
    async getRoleValues(){
      this.currentRole = this.fetchRoleValue();
    this.isSiuUser = this.siuRoles.some(
      (r) => this.currentRole.indexOf(r) >= 0
      );
      this.searchData = this.$route.params.allData;
    if(this.$route.params.allData.state == ("MAHARASHTRA" || "maharashtra")) {
      this.invoiceDetailsListTax = [{taxParticulars: "SGST + CGST",rate: 18, reqTaxAmt: 0, approvedApprovedAmount: 0, totalRequestedAmount: 0}];
    } else {
      this.invoiceDetailsListTax = [{taxParticulars: "IGST",rate: 18,  reqTaxAmt: 0, totalRequestedAmount: 0, approvedApprovedAmount: 0}];
    }
    if(this.isSiuUser) {
      let gstNumber = await this.getInvestigationData(this.$route.params.allData.siuInvestigationTaskID);
      this.searchData.gstNumber = gstNumber;
      if(!gstNumber) {
        this.invoiceDetailsListTax[0].rate = 0;
      }
    } else {
      this.searchData.gstNumber = this.$route.params.tabData.siuUserDetails.investigatorDetails.gstNumber;
      this.searchData.investigatorName = this.$route.params.tabData.siuUserDetails.investigatorDetails.name;
      this.searchData.mobileNumber = this.$route.params.tabData.siuUserDetails.investigatorDetails.mobile;
      this.searchData.emailId = this.$route.params.tabData.siuUserDetails.investigatorDetails.email;
      if(!this.$route.params.tabData.siuUserDetails.investigatorDetails.gstNumber) {
        this.invoiceDetailsListTax[0].rate = 0;
      }
    }
    },
    async getInvestigationData(id) {
      try{
        let res = await getPrePopulatedData(id);
        this.searchData.investigatorName = res.siuUserDetails.investigatorDetails.name;
        this.searchData.mobileNumber = res.siuUserDetails.investigatorDetails.mobile;
        this.searchData.emailId = res.siuUserDetails.investigatorDetails.email;
        return res.siuUserDetails.investigatorDetails.gstNumber;
      } catch(e){
        this.showToast(e.response?.data?.msg || e.response?.data  || e.response || e, this.TOST().ERROR);
      }
    },
    async saveData() {
      let url = "";
      console.log(this.invoiceDetailsList, this.$route.params);
      let arr = [];
      this.invoiceDetailsList.forEach((el, i) => {
        if(el.enterBillAmount || el.numberOfUnits) {
          arr.push(this.invoiceDetailsList[i]);
        }
      });
      let flag = false;
      if(arr.length) {
        // let validationList = [];
        if(this.isSiuUser){
          // validationList = ['enterBillAmount', 'numberOfUnits', 'investigatorRemarks'];
          arr.forEach(el => {if(!el.approvedAmount || !el.qcManagerRemarks){
            flag = true;
          }});
        } else {
          // validationList = ['approvedAmount', 'qcManagerRemarks'];
          arr.forEach(el => {if(!el.enterBillAmount || !el.numberOfUnits ||! el.investigatorRemarks){
            flag = true;
          }});
        }
      }
      if(flag) {
        this.showToast("Fill row data", this.TOST().ERROR);
        return;
      }
      let arrCopy = JSON.parse(JSON.stringify(arr));
      arrCopy.map(el => delete el.sno);
      arrCopy.map(el => el.billCategory = "Fee");
      let obj = {};
      obj.invoice = arrCopy;
      obj.invoiceNumber = this.searchData.invoiceNumber;
      obj.siuWorkitemId = this.$route.params.workitemId;
      if(!this.isSiuUser){
        obj.invWorkitemId= this.$route.params.tabData._id;
      } else {
        obj.invWorkitemId  = this.invWorkitemId;
        obj.totalApprovedAmountAfterTax = this.totalApprovedAmountAfterTax;
      }
      console.log("SSSS",obj, this.$route.params);
      obj.totalApprovedAmount = this.totalApprovedAmount;
      let res;
      try{
        if (this.updateMode){
          url = "update-siu-invoice/"+this.invoiceId;
          res = await updateInvoiceDetails(this.invoiceId, obj);
        } else {
          res = await createInvoiceDetails(obj);
        }
        if(res.data.statusCode == 200) {
          this.showToast(res.data.msg, this.TOST().SUCCESS);
          if(!this.isSiuUser) {
            setTimeout(async () => {
              this.getRoleValues();
              await this.getInvoiceDetails();
              this.$forceUpdate();
            }, 1000);
          }
        } else {
          this.showToast(res.data.msg, this.TOST().WARNING);
        }
      } catch(e){
        this.showToast(e.response?.data?.msg || e.response?.data  || e.response || e, this.TOST().ERROR);
      }
    },
    setTaxValue() {
      this.totalRequestedAmountAfterTax = 0;
      this.totalApprovedAmountAfterTax = 0;
      this.invoiceDetailsListTax.forEach(item => {
      this.totalRequestedAmountAfterTax = this.totalRequestedAmount + Number(item.reqTaxAmt);
      this.totalApprovedAmountAfterTax = this.totalApprovedAmount + Number(item.approvedApprovedAmount);
      });
      this.$forceUpdate();
    },
    setTotalAmount(){
        this.totalApprovedAmount = 0;
        this.totalRequestedAmount = 0;
        this.invoiceDetailsList.forEach(item => {
        if(item.finalApprovedAmount) {
          this.totalApprovedAmount = this.totalApprovedAmount + Number(item.finalApprovedAmount);
        }
        if(item.requestedAmount) {
          this.totalRequestedAmount = this.totalRequestedAmount + Number(item.requestedAmount);
        }
      });
      this.$forceUpdate();
    },
    async getInvoiceDetails() {
      let id = this.$route.params.workitemId;
      try{
        let res = await getInvoiceDetails(id);
        if(res.data.statusCode == 200) {
          this.showToast(res.data.msg, this.TOST().SUCCESS);
        } else {
          this.showToast(res.data.msg, this.TOST().WARNING);
          return;
        }
        if(res.data?.data[0]?.invoiceNumber) {
          this.invWorkitemId = res.data.data[0].invWorkitemId;
          this.searchData.invoiceNumber = res.data.data[0].invoiceNumber;
          this.showInvoicePDF = true;
          this.siuInvoiceId = res.data.data[0]._id;
        }
        if(res.data?.data[0]?.invoice?.length){
          this.invoiceId = res.data?.data[0]._id;
          this.searchData.dateTime = moment(res.data?.data[0]?.createdAt).format('DD-MM-YYYY') || '';
          this.updateMode = true;
          res.data.data[0].invoice.forEach((b)=>{
          let index = this.invoiceDetailsList.findIndex(a=>{return a.particulars == b.particulars});
          this.invoiceDetailsList[index].enterBillAmount = Number(b.enterBillAmount);
          this.invoiceDetailsList[index].numberOfUnits = Number(b.numberOfUnits);
          this.invoiceDetailsList[index].approvedAmount = Number(b.approvedAmount) || 0;
          this.invoiceDetailsList[index].numberOfUnits = Number(b.numberOfUnits);
          this.invoiceDetailsList[index].finalApprovedAmount = Number(b.finalApprovedAmount) || 0;
          this.invoiceDetailsList[index].billCategory = "Fee"
          this.invoiceDetailsList[index].investigatorRemarks = b.investigatorRemarks;
          this.invoiceDetailsList[index].investigatorRemarks = b.investigatorRemarks;
          this.invoiceDetailsList[index].qcManagerRemarks = b.qcManagerRemarks;
          this.invoiceDetailsList[index].disAllowedAmount = Number(b.disAllowedAmount) || ((Number(b.enterBillAmount)*Number(b.numberOfUnits)) - (b.approvedAmount || 0));
          setTimeout(() => {
            this.setTaxValue();
          }, 3000);
        });
        }
  this.$forceUpdate();
  setTimeout(() => {
    this.setTotalAmount();
    this.setTaxValue();
    this.$forceUpdate();
  }, 1000);
      } catch(e){
        this.showToast(e.response?.data?.msg || e.response?.data  || e.response || e, this.TOST().ERROR);
      }
      if(!this.isSiuUser) {
        setTimeout(() => {
          this.updateGridData();
        }, 4000);
      } else {
        this.updateGridData();
      }
    },
    updateGridData(){
      let arrCopy = JSON.parse(JSON.stringify(this.invoiceDetailsList));
      this.invoiceDetailsList = null;
      this.invoiceDetailsList = JSON.parse(JSON.stringify(arrCopy));
    }
  },
  data() {
    return {
      showSaveButton: true,
      invWorkitemId: "",
      invoiceId: "",
      currentRole: "",
      updateMode: false,
      isSiuUser: false,
      showInvoicePDF: false,
      siuInvoiceId: "",
      siuRoles: ["CG77", "CG78", "CG79", "CG80", "CG81", "CG82"],
      sno: 21,
      totalApprovedAmount: 0,
      totalRequestedAmount: 0,
      totalRequestedAmountAfterTax: 0,
      totalApprovedAmountAfterTax: 0,
      gridForm: "",
      searchData: {
        invoiceNumber: "",
        investigatorName: "",
        gstNumber: "",
        mobileNumber: "",
        emailId: "",
        dateTime: "",
        claimNumber: "",
        policyNumber: "",
        claimType: "",
        city: "",
        state: ""
      },
      headers2: [{
          text: "S.NO",
          value: "sno",
          width: 50,
          sortable: false,
          align: "center",
        },
        {
          text: "Particulars",
          value: "particulars",
          sortable: false,
          width: 200,
          align: "center",
        },
        {
          text: "Standard Rates (INR)",
          value: "standardRates",
          sortable: false,
          width: "150px",
          align: "center",
        },
        {
          text: "Enter Bill Amount (INR)",
          value: "enterBillAmount",
          sortable: false,
          width: "150px",
          align: "center",
        },
        {
          text: "Number Of Units",
          value: "numberOfUnits",
          sortable: false,
          width: 150,
          align: "center",
        },
        {
          text: "Requested Amount (INR)",
          sortable: false,
          value: "requestedAmount",
          width: 150,
          align: "center",
        },
        {
          text: "Investigator Remarks",
          sortable: false,
          value: "investigatorRemarks",
          width: 200,
          align: "center",
        }],
      headers: [
        {
          text: "S.NO",
          value: "sno",
          width: 50,
          sortable: false,
          align: "center",
        },
        {
          text: "Particulars",
          value: "particulars",
          sortable: false,
          width: 200,
          align: "center",
        },
        {
          text: "Standard Rates (INR)",
          value: "standardRates",
          sortable: false,
          width: "150px",
          align: "center",
        },
        {
          text: "Enter Bill Amount (INR)",
          value: "enterBillAmount",
          sortable: false,
          width: "150px",
          align: "center",
        },
        {
          text: "Number Of Units",
          value: "numberOfUnits",
          sortable: false,
          width: 150,
          align: "center",
        },
        {
          text: "Requested Amount (INR)",
          sortable: false,
          value: "requestedAmount",
          width: 150,
          align: "center",
        },
        {
          text: "Investigator Remarks",
          sortable: false,
          value: "investigatorRemarks",
          width: 200,
          align: "center",
        },
        {
          text: "Approved Amount",
          sortable: false,
          value: "approvedAmount",
          width: 150,
          align: "center",
        },
        {
          text: "Disallowed Amount",
          sortable: false,
          value: "disAllowedAmount",
          width: 150,
          align: "center",
        },
        {
          text: "Final Approved Amount",
          sortable: false,
          value: "finalApprovedAmount",
          width: 150,
          align: "center",
        },
        {
          text: "QC Manager Remarks",
          sortable: false,
          value: "qcManagerRemarks",
          width: 200,
          align: "center",
        },
      ],
      headersTax: [
        {
          text: "Tax Particulars",
          value: "taxParticulars",
          sortable: false,
          width: 200,
          align: "center",
        },
        {
          text: "Rate %",
          value: "rate",
          width: 50,
          sortable: false,
          align: "center",
        },
        {
          text: "Requested Tax Amount",
          value: "reqTaxAmt",
          width: 50,
          sortable: false,
          align: "center",
        },
        {
          text: "Total Requested Amount",
          value: "totalRequestedAmount",
          width: 50,
          sortable: false,
          align: "center",
        },
        {
          text: "Approved Tax Amount",
          value: "approvedApprovedAmount",
          width: 50,
          sortable: false,
          align: "center",
        },
        {
          text: "Final Approved Amount",
          value: "finalApprovedAmount",
          width: 50,
          sortable: false,
          align: "center",
        },
      ],
      headersTax2: [
      {
          text: "Tax Particulars",
          value: "taxParticulars",
          sortable: false,
          width: 200,
          align: "center",
        },
        {
          text: "Rate %",
          value: "rate",
          width: 50,
          sortable: false,
          align: "center",
        },
        {
          text: "Requested Tax Amount",
          value: "reqTaxAmt",
          width: 50,
          sortable: false,
          align: "center",
        },
        {
          text: "Total Requested Amount",
          value: "totalRequestedAmount",
          width: 50,
          sortable: false,
          align: "center",
        },
      ],
      invoiceDetailsListTax: [],
      invoiceDetailsList: [
        { sno: 1, particulars: "Professional Fee", standardRates: "2500 - Reimbursement / 1200 - Cashless" },
        {
          sno: 2,
          particulars: "Medical Record Verification (MRD)",
          standardRates: "Actual",
        },
        {
          sno: 3,
          particulars: "Hospital Charges if any",
          standardRates: "Actual",
        },
        { sno: 4, particulars: "TAT Incentive", standardRates: "500 - Not applicable for Cashless" },
        { sno: 5, particulars: "Local Conveyance", standardRates: "Actual" },
        {
          sno: 6,
          particulars:
            "Fraud Incentive including Additional documents extraction and verification proving fraud",
          standardRates: "Actual",
        },
        {
          sno: 7,
          particulars: "Misc expenses if any",
          standardRates: "Actual",
        },
        { sno: 8, particulars: "Any Others", standardRates: "Actual" },
        {sno: 9, particulars: "TOTAL AMOUNT"}
      ],
    };
  },
watch: {
  invoiceDetailsList: {
      handler: function (val, oldVal) {
        setTimeout(() => {
          this.setTotalAmount();
          this.setTaxValue();
        }, 1000);
      },
      deep: true
    },
    totalApprovedAmount(newVal, oldVal) {
      setTimeout(() => {
        this.setTotalAmount();
        this.setTaxValue();
      }, 1000);
    },
    totalRequestedAmount(newVal, oldVal){
        setTimeout(() => {
          this.setTotalAmount();
          this.setTaxValue();
        }, 1000);
      }
    },
    filters: {
      decimal: function (value) {
      if (!value) return 0;
      return (Math.round(value * 100) / 100).toFixed(2);
  }
    },
};
</script>
<style>
.col-2 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
</style>
