import axios from "axios";
import * as URL from "../common/API_Config.js";

export const getInvoiceDetails = async function (woritemId) {
  const res = await axios.get(
    URL.commonModuleBaseUrl +
      "auxiliary/investigtion/get-invoice-details-by-memberid/" +
      woritemId,
    {
      headers: {
        "Content-Type": "application/json",
        system: "Health",
      },
    }
  );
  return res;
};
export const createInvoiceDetails = async function (payload) {
  const res = await axios.post(
    URL.commonModuleBaseUrl +
      `auxiliary/investigtion/create-siu-invoice`,
    payload,
    {
      headers: {
        "Content-Type": "application/json",
        system: "Health",
      },
    }
  );
  return res;
};

export const updateInvoiceDetails = async function (id, payload) {
    const res = await axios.put(
      URL.commonModuleBaseUrl +
        `auxiliary/investigtion/update-siu-invoice/${id}`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          system: "Health",
        },
      }
    );
    return res;
  };
