<template>
    <v-app>
		<v-card class="">
            <div class="ma-7" >
                <!-- <v-card class=""> -->
                <v-form v-model="valid" ref="netform">

                    <v-layout row class>
                        <v-flex xs3 >
                        <v-layout column class=" mx-3 cust_pr">
                                <!-- <v-flex style="flex: 1;" class="cust_pr mx-3 "> -->
                                <label class="font-weight-bold cstm-clr-pr" style="color: #646666;">Network Name Filter</label>
                                <v-flex class="">
                                    <v-select
                                        :items="selectNetworkNameFilter"
                                        item-text="text"
                                        item-value="value"
                                        v-model="networkNameFilter"
                                        label="Contains"
                                        dense
                                        solo
                                        class="rounded-0"
                                    ></v-select>
                                </v-flex>
                        </v-layout>
                        </v-flex>

                        <v-flex xs3 >
                        <v-layout column class=" mx-3 cust_pr">
                            <label class="font-weight-bold cstm-clr-pr" style=" color: #646666; ">Network Name</label>
                                <v-flex class=" ">
                                    <v-text-field
                                        v-model="networkName"
                                        label="Enter..."
                                        solo
                                        dense
                                        class="rounded-0"
                                    ></v-text-field>
                                </v-flex>
                            </v-layout>
                        </v-flex> 
                        
                        <v-flex xs3 >
                            <v-layout column class="cust_pr mx-3 ">
                                <p class="font-weight-bold cstm-clr-pr">
                                    Network ID
                                </p>
                                <v-text-field
                                    solo
                                    dense
                                    label="Enter..."
                                    v-model="networkId"
                                    class="rounded-0"
                                    :rules="[rules.minId]"
                                >
                                </v-text-field>
                            </v-layout>
                        </v-flex>

                        <v-flex xs3 >
                            <v-layout column class="cust_pr mx-3 ">
                                <p class="font-weight-bold cstm-clr-pr">
                                    Network status
                                </p>
                                <v-select
                                    :items="selectStatus"
                                    item-text="state"
                                    item-value="state"
                                    v-model="networkStatus"
                                    label="Select"
                                    dense
                                    solo
                                    hide-details 
                                    class="rounded-0"
                                ></v-select>
                                
                            </v-layout>
                        </v-flex>
                    </v-layout>
                
                    <v-layout row class="my-3">
                        <v-flex xs3 >
                            <v-layout column class=" mx-3  cust_pr cust-rmvpd-pr">
                                <p class="font-weight-bold cstm-clr-pr">Network Effective Start Date - From</p>
                                <v-menu
                                    ref="calender"
                                    :close-on-content-click="false"
                                    v-model="netendDatecalender"
                                    :nudge-right="40"                           
                                    transition="scale-transition"                            
                                    max-width="290px"
                                    min-width="290px" 
                                    class="rounded-0"                             
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            slot="activator"
                                            label="dd/mm/yyyy"
                                            :value="netstartDateFromcalmoment"
                                            solo
                                            class="rounded-0"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                        <div  slot="prepend-inner" style="background-color:#F7F7F7;  padding: 8px !important"><span class="material-icons">date_range</span></div>
                                        </v-text-field>
                                    </template>                                
                                    <v-date-picker @input="netendDatecalender = false" v-model="netstartDateFrom" no-title ></v-date-picker>
                                </v-menu>
                            </v-layout>
                        </v-flex>

                        <v-flex xs3 >
                            <v-layout column class=" mx-3 cust_pr cust-rmvpd-pr">
                                <p class="font-weight-bold cstm-clr-pr">Network Effective Start Date - To</p>
                                <v-menu
                                    ref="calender"
                                    :close-on-content-click="false"
                                    v-model="netstartDateTocal"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    max-width="290px"
                                    min-width="290px" 
                                    class="rounded-0" >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            slot="activator"
                                            label="dd/mm/yyyy"
                                            :value="netstartDateTocalmoment"
                                            solo
                                            class="rounded-0"
                                            :rules="[startdateCheck()]"
                                            v-bind="attrs"
                                            v-on="on"
                                            readonly>
                                        <div slot="prepend-inner" style="background-color:#F7F7F7;  padding: 8px !important"><span class="material-icons">date_range</span></div>
                                        </v-text-field>
                                    </template>
                                                                
                                    <v-date-picker @input="netstartDateTocal = false" v-model="netstartDateTo" no-title></v-date-picker>
                                </v-menu>
                            </v-layout>
                        </v-flex>
                            
                        <v-flex xs3 >
                            <v-layout column class=" mx-3 cust_pr cust-rmvpd-pr">
                                <p class="font-weight-bold cstm-clr-pr">Network Effective End Date - From</p>
                                <v-menu
                                    ref="calender"
                                    :close-on-content-click="false"
                                    v-model="netEndDateFromcal"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    max-width="290px"
                                    min-width="290px" 
                                    class="rounded-0">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            slot="activator"
                                            label="dd/mm/yyyy"
                                            :value="netEndDateFromcalmoment"
                                            solo
                                            class="rounded-0"
                                            v-bind="attrs"
                                            v-on="on"
                                            readonly>
                                            <div slot="prepend-inner" style="background-color:#F7F7F7;  padding: 8px !important"><span class="material-icons">date_range</span></div>
                                        </v-text-field>
                                    </template>
                                                                
                                    <v-date-picker @input="netEndDateFromcal = false" v-model="netEndDateFrom" no-title></v-date-picker>
                                </v-menu>
                            </v-layout>
                        </v-flex> 

                        <v-flex xs3 >
                            <v-layout column class=" mx-3 cust_pr cust-rmvpd-pr">
                                <p class="font-weight-bold cstm-clr-pr">Network Effective End Date - To</p>
                                <v-menu
                                    ref="calender"
                                    :close-on-content-click="false"
                                    v-model="netEndDateTocal"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    max-width="290px"
                                    min-width="290px" 
                                    class="rounded-0">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            slot="activator"
                                            label="dd/mm/yyyy"
                                            :value="netEndDateTocalmoment"
                                            solo
                                            class="rounded-0"
                                            :rules="[enddateCheck()]"
                                            v-bind="attrs"
                                            v-on="on"
                                            readonly>
                                            <div slot="prepend-inner" style="background-color:#F7F7F7;  padding: 8px !important"><span class="material-icons">date_range</span></div>
                                        </v-text-field>
                                    </template>
                                    <v-date-picker @input="netEndDateTocal = false" v-model="netEndDateTo" no-title></v-date-picker>
                                </v-menu>
                            </v-layout>
                        </v-flex>
                    </v-layout>
                    
                    <v-divider class="mt-3"></v-divider>
                        
                    <v-layout class="my-3">
                        <v-flex xs6>
                            <v-layout row class>
                                <v-flex style="flex: 1;" class="cust_pr mx-3 mr-3">
                                    <label 
                                        class="font-weight-bold cstm-clr-pr"
                                        style="color: #646666;">Product
                                    </label>
                                    <v-flex class="">
                                        <v-select
                                            :items="selectProduct"
                                            item-text="text"
                                            item-value="value"
                                            return-object
                                            v-model="product"
                                            label="Select"
                                            dense
                                            solo
                                            multiple
                                            hide-details 
                                            class="rounded-0"
                                            @change="addProdctChange(product)"
                                        >
                                        <template v-slot:selection="{ item, index }">
                                            <span v-if="index === 0">{{ item }}</span>
                                            <!-- <v-chip v-if="index === 0">
                                            <span>{{ item }}</span>
                                            </v-chip> -->
                                            <span
                                            v-if="index === 1"
                                            class="grey--text caption"
                                            >
                                            (+{{ product.length - 1 }} others)
                                            </span>
                                        </template>
                                        </v-select>
                                    </v-flex>
                                </v-flex>

                                <v-flex style="flex: 1;" class="cust_pr mx-3 mr-3">
                                    <label 
                                        class="font-weight-bold cstm-clr-pr"
                                        style="color: #646666;">Product Benefits
                                    </label>
                                    
                                    <v-flex class="" style="max-height:20px">
                                        <v-select
                                            :items="selectProductBenefit"
                                            item-text="type"
                                            item-value="_id"
                                            return-object
                                        maxlength="30"
                                            v-model="productBenefit"
                                            label="Select"
                                            dense
                                            solo
                                            multiple
                                            
                                            class="rounded-0"
                                        >
                                        <!-- v-scroll.self="onScroll" -->
                                        <template v-slot:selection="{ item, index }">
                                            <span v-if="index === 0">{{ item }}</span>
                                            <!-- <v-chip v-if="index === 0">
                                            <span>{{ item }}</span>
                                            </v-chip> -->
                                            <span
                                            v-if="index === 1"
                                            class="grey--text caption"
                                            >
                                            (+{{ productBenefit.length - 1 }} others)
                                            </span>
                                        </template>
                                        </v-select>
                                    </v-flex>
                                </v-flex>
                            </v-layout>
                        </v-flex>

                        <v-flex xs6 class="ml-7">
                            <v-layout row class>
                                
                        <v-flex style="flex: 1;" class="cust_pr mx-3 ">
                            
                                <label 
                                    class="font-weight-bold cstm-clr-pr"
                                    style="color: #646666;">Select Provider
                                </label>  
                                <!-- <pre>{{selectprovider}}</pre> -->
                                <v-flex class=" ">
                                    <v-autocomplete
                                        v-model="selectprovider"
                                        :items="searchedItems"
                                        :loading="isLoading"
                                        :search-input.sync="searchProviderName"
                                        color="white"
                                        cache-items
                                        label="Provider Name..."
                                        class="rounded-0"
                                        return-object
                                        solo
                                        >
                                        <div slot="append" style="background-color:#F7F7F7;padding:7px;cursor:pointer;position:absolute;top:-3px;right:-12px"><v-icon>search</v-icon></div>
                                        <!-- @click="getProviderDetails" -->
                                    </v-autocomplete>

                                    

                                </v-flex>
                                    
                        </v-flex>
                                <v-flex style="flex: 1;" class="cust_pr mx-3 mr-3">
                                    <label 
                                        class="font-weight-bold cstm-clr-pr"
                                        style="color: #646666;">Clinical Specialties
                                    </label>
                                    <!-- <pre>{{clinicalSpecial}}</pre> -->
                                    <v-flex class="">
                                        <v-select
                                            :items="selectclinicalspl"
                                            item-text="type"
                                            item-value="_id"
                                            return-object
                                            solo
                                            dense
                                            
                                            v-model="clinicalSpecial"
                                            label="Select"
                                            class="rounded-0"
                                        >
                                        <template v-slot:selection="{ item, index }">
                                            <span v-if="index === 0">{{ item }}</span>
                                            <!-- <v-chip v-if="index === 0">
                                            <span>{{ item }}</span>
                                            </v-chip> -->
                                            <span
                                            v-if="index === 1"
                                            class="grey--text caption"
                                            >
                                            (+{{ clinicalSpecial.length - 1 }} others)
                                            </span>
                                        </template>
                                        </v-select>
                                    </v-flex>
                                </v-flex>
                            </v-layout>
                        </v-flex>
                    </v-layout>

                    <v-layout>
                        <v-flex xs3 class="mr-3">
                            <v-layout row class>
                                <v-flex style="flex: 1;" class="cust_pr mx-3 mr-8">
                                    <label 
                                        class="font-weight-bold cstm-clr-pr"
                                        style="color: #646666;">Group Policy
                                    </label>
                                    
                                    <v-flex class="">
                                        <v-autocomplete
                                            :items="mapAddGrpPoliArray"
                                            v-model="groupPolicy"
                                            color="#000000"
                                            item-text="policyNo"
                                            item-value="_id"
                                            class="rounded-0"
                                            label="Enter"
                                            return-object
                                            dense
                                            hide-selected
                                            solo
                                            >
                                            <!-- @change="addGrpPolicyChange(groupPolicy)" -->
                                            <!-- @change="getGroupPolicyData(groupPolicy)" -->
                                            <div slot="append" style="background-color:#F7F7F7;padding:7px;cursor:pointer;position:absolute;top:-6px;right:-12px;"><v-icon>search</v-icon></div>
                                        </v-autocomplete>
                                    </v-flex>
                                </v-flex>
                            </v-layout>
                        </v-flex>

                        <v-flex >
                            <v-layout row class="mt-8">
                                <v-btn tile @click="clearbtn()" style="font-size:12px;background-color:#f7f7f7" small text color="black" class="clearbtn text-capitalize px-3 py-4 mx-2"><v-icon dark small >clear</v-icon > <v-divider vertical class="mx-2"></v-divider>Clear All</v-btn>
                                <v-btn tile @click="seekSearchData(1)" dark style="font-size:12px;background-color:#E46A25" small color="" class="searchbtn text-capitalize px-3 py-4 mx-2"><v-icon dark small >search</v-icon > <v-divider vertical class="mx-2"></v-divider>Search</v-btn>
                                <v-btn tile @click="cancelbtn()" dark  style="font-size:12px" small color="" class="cancelbtn text-capitalize px-3 py-4 mx-2"><v-icon dark small >clear</v-icon > <v-divider vertical class="mx-2"></v-divider>Cancel</v-btn>
                                <v-btn tile @click="createNewbtn()" style="font-size:12px;color:white"  small color="#23B1A9" class="newbtn text-capitalize px-3 py-4 mx-2"><v-icon dark small >add</v-icon > <v-divider vertical class="mx-2"></v-divider>Create New Network</v-btn>

                            </v-layout>
                        </v-flex>

                    </v-layout>
                </v-form>
        
                <div v-if="showSearchdata===true">
                    <v-card tile class="my-3 " style="background-color:#F7F7F7;border:1px solid #C1C8CC;flex:1;">  
                        <v-layout row justify-start align-center justify-space-between class="mx-">
                            <v-text class="mr-1 mx-5 pa-3 font-weight-bold body-1" style="color:#3C3D3D; font-size:16px !important">
                                Search Results 
                            </v-text>
                        </v-layout>
                    </v-card>


                    <v-card class="mx- mb-3 mt-3" tile >
                        <v-data-table
                            :headers="headers"
                            :items="GetAllData"
                            :page.sync="page"
                            :items-per-page="itemsPerPage"
                            hide-default-footer
                            dense
                            @page-count="pageCount = $event">
                    
                            <template v-slot:item.networkId="{ item }">
                                <div @click="NetworkClick(item)" class="text-decoration-underline" style="color:#E46A25; cursor:pointer; font-size:12px !important">{{ item.networkId | checkdata }}</div>
                            </template>
                            <template v-slot:item.networkName="{ item }">
                                <div >
                                    <v-tooltip right >
                                        <template v-slot:activator="{ on }">
                                            <div v-on="on">
                                                {{ item.networkName| checkdata |truncate(16, '...')}}
                                            </div>
                                        </template>
                                            <span>{{item.networkName}}</span>
                                        
                                    </v-tooltip>
                                </div>
                            </template>
                        
                            <template v-slot:item.networkEffectiveStartDate="{ item }">
                                <div >
                                    <v-tooltip right >
                                        <template v-slot:activator="{ on }">
                                            <div v-on="on">
                                                {{ item.networkEffectiveStartDate| formatDate |truncate(16, '...')}}
                                            </div>
                                        </template>
                                            <span>{{item.networkEffectiveStartDate}}</span>
                                        
                                    </v-tooltip>
                                </div>
                            </template>
                            <template v-slot:item.networkEffectiveEndDate="{ item }">
                                <div >{{ item.networkEffectiveEndDate | formatDate}}</div>
                            </template>
                    
                            <template v-slot:item.productBenefits="{ item }">
                                <div >{{ item.productBenefits.toString() |checkdata}}</div>
                            </template>

                            <template v-slot:item.networkStatus="{ item }">
                                <div >{{ item.networkStatus| checkdata }}</div>
                            </template>
                            
                        </v-data-table>
                        <v-divider></v-divider>
                        
                        <v-layout row justify-end align-center class="ma-2" >
                            <v-text class="mr-1 font-weight-bold" style="color:#3C3D3D; font-size:13px;">
                                <span class="" style="color:#E46A25;font-size:12px;">Showing {{fval}}-{{sval}} </span>out of {{totalLen}} records
                            </v-text>
                            <v-divider  class="mx-2" vertical ></v-divider>
                            <v-pagination
                                class="pdct-stUp-bnkAcc-pgn"
                                        v-model="page"
                                        color="#152F38"
                                        flat
                                        @input="nextPageData(page)"
                                        :total-visible="9"
                                        :length="pagelength"
                            ></v-pagination>
                        </v-layout>
                    </v-card>
                </div>
                <v-card tile v-if="showrsltnotfound===true" class="my-3 " style="background-color:#F7F7F7;border:1px solid #C1C8CC;flex:1;">
                    <v-layout row justify-start align-center justify-space-between class="mx-">
                            <v-text class="mr-1 mx-5 pa-3 font-weight-bold body-1" style="color:#3C3D3D; font-size:16px !important">
                                NO RESULTS FOUND
                            </v-text>
                    </v-layout>
                </v-card>
            </div>
		</v-card>
    </v-app>    
</template>
            
<script>
import axios from "axios";
 import moment from 'moment';

export default {
        

    created() {
            //  console.log('user access for create new provider',this.$store.getters.fetchRole)
            //  this.userValue=this.$store.getters.fetchRole

         console.log("Success Token", this.$store.getters.successToken)
         this.token=this.$store.getters.successToken
         this.$store.commit("SAVE_PAGE_HEADER", "Network Search");

         this.getallclinicalspecialities()
        
         this.getGroupPolicyData()

         this.getProviderDetails()
        //  this.getproviderData()
      
        
                    // console.log("token is -> ", this.$store.getters.successToken); 
    },

    mounted(){

        if(this.$store.state.NetworkSearch.searched){
            var net_cache = this.$store.state.NetworkSearch
                    this.networkNameFilter = net_cache.networkNameFilter
                    this.networkName = net_cache.networkName
                    this.networkId = net_cache.networkId
                    this.networkStatus   = net_cache.networkStatus
                    this.netendDatecalender  = net_cache.netendDatecalender
                    this.netstartDateFrom   = net_cache.netstartDateFrom
                    this.netstartDateTo  = net_cache.netstartDateTo
                    this.netEndDateFrom   = net_cache.netEndDateFrom
                    this.netEndDateTo   = net_cache.netEndDateTo
                    this.product  = net_cache.product
                 
                    this.clinicalSpecial  = net_cache.clinicalSpecial
                    this.groupPolicy = net_cache.groupPolicy

                if(net_cache.product.length !=0 && net_cache.selectprovider.length != 0){
                    var resolvingtwo = new Promise((resolve, reject)=>{

                        if(net_cache.product.length !=0){
                         this.getProductsData(1, cbis=>{
                            this.product = net_cache.product
                            this.finalstring(net_cache.product, fs=>{
                                this.getProductsBenefitsData(fs, 1, datais=>{
                                    this.productBenefit  = net_cache.productBenefit;
                                    resolve()
                                })
                            })
                         })
                        }
                    })

                    resolvingtwo.then(res=>{
                         if(net_cache.selectprovider.length != 0){
                         
                            this.get_search_provider(net_cache.selectprovider, 1, cb=>{
                                this.seekSearchData(1)
                            })
                                //    this.selectprovider  = net_cache.selectprovider
                            
                        }else{
                            this.seekSearchData(1)
                        }
                    })

                   
                }else{

                     if(net_cache.selectprovider.length != 0){
                         
                            this.get_search_provider(net_cache.selectprovider, 1, cb=>{
                                this.seekSearchData(1)
                            })
                                //    this.selectprovider  = net_cache.selectprovider
                            
                     }else{
                            this.seekSearchData(1)
                     }
            

                    if(net_cache.product.length !=0){
                           if(net_cache.product.length !=0){
                            this.getProductsData(1, cbis=>{
                                this.product = net_cache.product
                                this.finalstring(net_cache.product, fs=>{
                                    this.getProductsBenefitsData(fs, 1, datais=>{
                                        this.productBenefit  = net_cache.productBenefit;
                                         this.seekSearchData(1)
                                    })
                                })
                            })
                            }  
                    }else{
                         this.getProductsData(0)
                    }
                }
                 
        }else{
             this.getProductsData(0)
            this.getAllNetwork(null,this.page)
        }
    },

     computed: {

        netstartDateFromcalmoment(){
            return this.netstartDateFrom ? moment(this.netstartDateFrom).format("DD-MM-YYYY"): ''
        },
         netstartDateTocalmoment(){
            return this.netstartDateTo ? moment(this.netstartDateTo).format("DD-MM-YYYY"): ''
        },
         netEndDateFromcalmoment(){
            return this.netEndDateFrom ? moment(this.netEndDateFrom).format("DD-MM-YYYY"): ''
        },
         netEndDateTocalmoment(){
            return this.netEndDateTo ? moment(this.netEndDateTo).format("DD-MM-YYYY"): ''
        },
        validDateChk(){
            
            // let fromeDate = moment(this.productEffectFromdate,'YYYY-MM-DD').format('DD-MM-YYYY')
            // let todayDate = Date.now();
            // let Todate= moment(this.netenddate,'YYYY-MM-DD ').format('DD-MM-YYYY')
            // let CurrentDate= moment(todayDate).format("DD-MM-YYYY");
            // console.log(Todate,CurrentDate,"Todate,CurrentDate")

            //  let fromeDate = moment(this.crtEdtPdt.pdtEfftvDteFrm,'YYYY-MM-DD').toDate().getTime()
            let todayDate = Date.now();
            let Todate= moment(this.netstartDateFrom,'YYYY-MM-DD ').toDate().getTime()
            console.log(todayDate,"todayDate")
            let CurrentDate= todayDate

            // if(Todate < CurrentDate){
            //     return this.planStatus="Inactive"
            // }else{
            //     return this.planStatus="Active"
            // }
        }, 

        searchedItems () {
            return this.searchEntries;
        },

        
     },

     filters:{

      truncate: function (text, length, suffix) {
          if (text == "" || text == "undefined" || text == null || text == '' || text == 'Invalid Date') {
                return "-"
        
            }else if (text.length > length) {
                return text.substring(0, length) + suffix;
            } else {
                return text;
            }
        },
        checkdata(data){// eslint-disable-line no-mixed-spaces-and-tabs
            // console.log('Validity Data', data);
            if (data == "" || data == "undefined" || data == null || data == '' || data == 'Invalid Date') {
                return "-"
            } else {
                return data;
            }
        },
        formatDate(data){// eslint-disable-line no-mixed-spaces-and-tabs
            // console.log('Validity Data', data);

             if (data == "" || data == "undefined" || data == null || data == '' || data == 'Invalid Date') {
                return "-"
            } else {
                // var n = new Date(data);
                // return n.toLocaleString("en-IN")
                return moment(data,'YYYY-MM-DD').format('DD-MM-YYYY')
            }
        },
    

      truncate: function (text, length, suffix) {
            if (text.length > length) {
                return text.substring(0, length) + suffix;
            } else {
                return text;
            }
        }
    },

    components:{
        
    },

    data(){
        return {
            showSearchdata:false,
            searched:false,
            // userId:'',
            valid:'',
            selectNetworkNameFilter:['Contains','Start With' ,'End With' ,'Equals'],
            networkNameFilter:'',
            networkName:'',
            networkId:'',
            networkStatus:'',
            selectStatus:['Active','Inactive'],

            netstartDateFrom:'',
            netstartDateTo:'',
            netEndDateFrom:'',
            netEndDateTo:'',

             
            netstartDateFromcal:false,
            netendDatecalender:false,
            netstartDateTocal:false,
            netEndDateFromcal:false,
            netEndDateTocal:false,

            product:[],
            selectProduct:[],
            productBenefit:[],
            selectProductBenefit:[],
            selectprovider:'',
            clinicalSpecial:[],
            selectclinicalspl:[],
            selectClinicalSpecialties:['abc','xyz','pqr'],

            finalProductData:[],

            groupPolicy:'',
            selectGroupPolicy:['abc','xyz','pqr'],
            mapAddGrpPoliArray:[],
            finalGrpPolicyData:[],
            groupPolicyValid:[],


            searchEntries: [],
            isLoading: false,
            searchProviderName: null,
            searchDataArray:[],

            selectProductbene:[],

            GetAllData:[],

            headers:[
                
                {text: 'Network ID', value: 'networkId',sortable: true,divider:true, class:"hdr-txt-clr1 text-truncate"},
                {text: 'Network Name', value: 'networkName',sortable: true,divider:true,class:"hdr-txt-clr1 text-truncate"},
                {text: 'Network Effective Start Date', value: 'networkEffectiveStartDate',sortable: true,divider:true,class:"hdr-txt-clr1 text-truncate"},
                {text: 'Network Effective End Date', value: 'networkEffectiveEndDate',sortable: true,divider:true,class:"hdr-txt-clr1 text-truncate"},
                {text: 'Network Status', value: 'networkStatus',sortable: true,divider:true,class:"hdr-txt-clr1 text-truncate"},
                {text: 'Product', value: 'productnewname',sortable: true,divider:true,class:"hdr-txt-clr1 text-truncate"},
                {text: 'Product Benefits', value: 'productBenefits',sortable: true,divider:true,class:"hdr-txt-clr1 text-truncate"},
                {text: 'Group Policies', value: 'groupnewPolicy',sortable: true,divider:true,class:"hdr-txt-clr1 text-truncate",}
                

             ],
             wrkItmDtaLst:[
                 {
                   NetworkID:"122313",
                    NetworkName:"Ashish",
                    NetworkEffectiveStartDate:"01/01/2021",
                    NetworkEffectiveEndDate:"11/01/2021",
                    Status:"ACTIVE",
                    Product:"Medicare",
                    ProductBenefits:"xyzaa",
                    GroupPolicies:"Group"
                },{
                   NetworkID:"122113",
                    NetworkName:"b",
                    NetworkEffectiveStartDate:"01/01/2021",
                    NetworkEffectiveEndDate:"11/01/2021",
                    Status:"ACTIVE",
                    Product:"Medicare",
                    ProductBenefits:"xyzbn",
                    GroupPolicies:"Group"
                }
             ],

                newproduct:[],
            typesearch:false,

            swap_final_count:false,
            fval:0,
            sval:0,
            totalLen:0,
            token:null,
            
            showrsltnotfound:false,
            page: 1,
            pagelength:0,

           itemsPerPage: 20,
           abc:true,
            
            rules: {
                required: value => !!value || 'This Field is Required.',
                minId: value => {
                            // const pattern = /^([0-9])+$/
                        const pattern = /^([0-9]){6}?$/
                        return value.length <= 0  || pattern.test(value) || 'Enter valid Network ID.'
                },
                grppolicyNo: value => {
                            // const pattern = /^([0-9])+$/
                        const pattern = /^([0-9])+?$/
                        return value.length <= 0  || pattern.test(value) || 'Enter valid policy number.'
                },
                // minId: v=>v.length < 0 || v.length == 6 || 'Enter valid Network ID',
                 },

            startdateCheck(){
                let todayDate = Date.now();
                let fromDate=moment(this.netstartDateFrom,'YYYY-MM-DD ').toDate().getTime()
                let Todate= moment(this.netstartDateTo,'YYYY-MM-DD ').toDate().getTime()
                console.log(todayDate,"today start date")
                let CurrentDate= todayDate
                if(Todate < fromDate){
                    // this.showToast("The Product 'Effective date From' can not be greater than Product 'Effective date To'.",this.TOST().WARNING)
                    return "Enter valid date range."
                }
            },
            enddateCheck(){
                let todayDate = Date.now();
                let fromDate=moment(this.netEndDateFrom,'YYYY-MM-DD ').toDate().getTime()
                let Todate= moment(this.netEndDateTo,'YYYY-MM-DD ').toDate().getTime()
                console.log(todayDate,"today end Date")
                let CurrentDate= todayDate
                if(Todate < fromDate){
                    // this.showToast("The Product 'Effective date From' can not be greater than Product 'Effective date To'.",this.TOST().WARNING)
                    return "Enter valid date range."
                }
            }

        }
        
        },

    watch: {
        searchProviderName(val, ident) {
            if (this.searchedItems.length > 0) return
            // Items have already been requested
            if (this.isLoading) return

            this.isLoading = true
            // let provider = this.searchTarrProvName;
            this.get_search_provider(val, 0)
            // this.GET(2,'provider/search-providers-by-name/'+val,( res,err) => {
            //     if(!err){
            //         this.searchDataArray = res.data.data.data
            //         console.log(res.data.data.data);
            //         const providers = []
            //         for(let provider of res.data.data.data){
            //             // console.log(provider.empanelmentProvider);
            //             if(provider.registeredEntity === "Institution"){
            //                 providers.push(provider.entityName)
            //             }else if(provider.registeredEntity === "Individual"){
            //                 providers.push(provider.firstName)
            //             }
                            
            //         }
            //         this.searchEntries = providers
            //         ident == 1 ? this.selectprovider  = val : ''
            //     }else{
            //         this.showToast(err.response.data.msg ,this.TOST().ERROR);
            //     }
            // });
            this.isLoading = false

        },
    },
        

    methods:{

        checkdatanew(data){// eslint-disable-line no-mixed-spaces-and-tabs
            // console.log('Validity Data', data);
            if (data == "" || data == "undefined" || data == null || data == '' || data == 'Invalid Date') {
                return ""
            } else {
                return data;
            }
        },

        get_search_provider(val, ident, cbis){
            this.GET(2,'provider/search-providers-by-name/'+val,( res,err) => {
                if(!err){
                    this.searchDataArray = res.data.data.data
                    console.log(res.data.data.data);
                    const providers = []
                    for(let provider of res.data.data.data){
                        // console.log(provider.empanelmentProvider);
                        if(provider.registeredEntity === "Institution"){
                            providers.push(provider.entityName)
                        }else if(provider.registeredEntity === "Individual"){
                            providers.push(provider.firstName)
                        }
                            
                    }
                    this.searchEntries = providers
                    if(ident == 1){
                           this.selectprovider  = val
                           cbis(true)
                    } 
                }else{
                    this.showToast(err.response.data.msg ,this.TOST().ERROR);
                }
            });
        },

        addGrpPolicyChange(data){
            this.groupPolicy=data.policyNo

            console.log('Grp Policy Data     ---->>>>',data)

            // this.finalGrpPolicyData = []
            // this.groupPolicyValid=[]
            // for(let i = 0;i < data.length ; i++){
            //     let _product = {
            //         policyNo: data[i].policyNo,
            //         policyName: data[i].corporateGroupName,
            //     }
            //     this.finalGrpPolicyData.push(_product)
            //     console.log('finalGrpPolicyData     ---->>>>',this.finalGrpPolicyData)
                
            //     for(let j = 0;j < this.finalGrpPolicyData.length ; j++){
            //             console.log('finalGrpPolicyData     ---->>>>',this.finalGrpPolicyData)

            //         // (data[i].policyNo.localeCompare(this.groupPolicy[j]))

            //          (data[i].policyNo.localeCompare(this.groupPolicyValid[j]) == 0) ? this.groupPolicyValid.push('Policy is already mapped to network') : this.groupPolicyValid.push()
            //     }
            // }
                        console.log('Grp  policyNo  v-model   ---->>>>',this.groupPolicy)

            

        },

        

// for provider

        getProviderDetails(){
            let _providerData = {}
           
            for(let _data of this.searchDataArray){
                if(this.selectprovider == _data.entityName || this.selectprovider == _data.firstName){
                    _providerData = _data
                }
            }
            
            this.GET(2,'empanelment/empanelment-provider-by-id/' + _providerData._id,(res,err) => {
                if(!err){
                    let _resp = res.data.data.provider
                    let _empanel = res.data.data.empanelment
                    this.$store.commit('EMPANEL_PROVIDER_DATA',res.data.data)
                    
                    // this.searchEntries.push(this.provName)
                    this.selectprovider = this.provName
                    let empanelData = this.$store.state.empanelProviderData.empanelment
                    // this.empanelStoreData = empanelData !== null ? empanelData : ''
                    
                }else{
                    // this.showToast(res.data.msg ,this.TOST().ERROR);
                }   
            });
            
        },
        //end for provider

        
        getGroupPolicyData(data){
            this.GET(1,'group-policy/group-policy-all',(res,error)=>{
                if(!error){
                    if(res.data.statusCode === 200){
                        let _resp = res.data.data.data
                        for(let i = 0;i < _resp.length ; i++){
                            let _data = {
                                corporateGroupName : _resp[i].corporateGroupName,
                                policyNo : _resp[i].policyNumber,
                                productCode : _resp[i].productCode,
                                _id : _resp[i]._id,
                                productId:_resp[i].productId,
                            }
                            this.mapAddGrpPoliArray.push(_data)
                            //  console.log('grp policy data---->>',this.mapAddGrpPoliArray)
                        }
                    }else{
                        this.showToast(res.data.msg ,this.TOST().ERROR); 
                    }
                }else{
                    this.showToast(error.response.data.msg, this.TOST().WARNING);
                }
            })
        },

        finalstring(data, cb){

             let _data = ''
            console.log("incoming product data--->>",data)
            this.finalProductData = []
            for(let i = 0;i < data.length ; i++){
                _data += "products=" + data[i] + "&";
                let _product = {
                    productId: data[i],
                    productName: data[i]
                }
                this.finalProductData.push(_product)
                console.log("incoming finalProductData data--->>",this.finalProductData)
            }
            this.finalProductData = this.finalProductData.concat(this.prevProdctData);
            let finalString = _data.slice(0, -1)

                console.log("final string ", finalString);
            cb(finalString)
        },

        addProdctChange(data){
            console.log(data, ' << -- (((((({{{{{[[[[[[   prevProdctData   ]]]]]]}}}}})))))) ------',this.prevProdctData)
           
            // let _data = ''
            // console.log("incoming product data--->>",data)
            // this.finalProductData = []
            // for(let i = 0;i < data.length ; i++){
            //     _data += "products=" + data[i] + "&";
            //     let _product = {
            //         productId: data[i],
            //         productName: data[i]
            //     }
            //     this.finalProductData.push(_product)
            //     console.log("incoming finalProductData data--->>",this.finalProductData)
            // }
            // this.finalProductData = this.finalProductData.concat(this.prevProdctData);
            // let finalString = _data.slice(0, -1)

            //     console.log("final string ", finalString);
            // fs99
            this.finalstring(data, finalString=>{
                    console.log("The finalString",finalString);
                 this.getProductsBenefitsData(finalString, 0);
            })
           
        },

        getProductsBenefitsData(data, ident, cbis){
            console.log("what is input finalstring ", data);
            this.GET(3,'product-and-benefits/search-product-benefits?' + data,(res,error)=>{
                if(!error){
                    if(res.data.statusCode === 200){
                        let _resp = res.data.data
                        this.selectProductBenefit = _resp
                        if(ident==1){
                            cbis(true)
                        }

                    }else{
                        // this.showToast(res.data.msg ,this.TOST().ERROR); 
                    }
                }else{
                    // this.showToast(error.response.data.msg, this.TOST().WARNING);
                }
            })
        },

       

        getProductsData(ident, cbis){
            this.GET(3,'product-and-benefits/get-product-and-benefits',(res)=>{
                
                // for(let i = 0;i < res.data.data.length ; i++){
                //     // this.Productbenefits =res.data.data
                //     // console.log('Productbenefits---->',this.Productbenefits)
                //     // for(let k = 0;k < this.Productbenefits ; k++){
                // this.selectProductbene =res.data.data[i].benefits[0]
                // console.log('selectProductbenefits---->',this.selectProductbene)
                //     // }
                // }

                let _resp = res.data.data
               console.log('select responce----->    products---->',_resp)
                    for(let j = 0;j < _resp.length ; j++){         
                        let _data = _resp[j].product;
                        this.selectProduct.push(_data);
                        // console.log('products---->',this.selectProduct)
                        // let _benefitData = _resp[j].benefits
                        // this.selectProductBenefit.push(_benefitData)
                        // console.log('benifits---->',this.selectProductBenefit)
                //         for(let i = 0;i < _resp.benefits.length ; i++){
                //     // this.Productbenefits =res.data.data
                //     // console.log('Productbenefits---->',this.Productbenefits)
                //     // for(let k = 0;k < this.Productbenefits ; k++){
                // this.selectProductbene =_resp[j].benefits[i]
                // console.log('selectProductbenefits---->',this.selectProductbene)
                //     // }
                // }
                    }

                    cbis(true)
                
             })
        },

            getallclinicalspecialities(){
                
                let self = this; 
                // self.showLoader('Loading', true);  
                axios({
                    method: "get",
                    url:"http://claimsdrive-health.salesdrive.app/master-data/master-data-service/api/clinical-specialities/get-all-clinical-specialities",
 
                    headers: {
                            "Content-Type": "application/json", 
                            "x-access-token": self.token,
                        }
                })
                .then(function(response) {
                    console.log('all clinical-specialities Response------->>>',response.data.data);
                    
                        self.selectclinicalspl=response.data.data[0].clinicalSpecialities
                             
                })
                .catch(function(error) {
                    self.showLoader('Loading', false);
                    console.log(error);
                    // self.showToast(error.response.data.msg,self.TOST().ERROR);
                });

            },

        createNewbtn(){

            // this.$router.push('/NetworkSetup')
            this.$router.push({name: 'NetworkSetup', params:{value:true}})

        },

        cancelbtn(){
            this.$router.push('/HomePage')

        },

        NetworkClick(item){

             console.log(item,"search result item ")
            // cs99
            this.$store.commit("NETWORK_DATA",item)
            if(this.searched){
                var cache_state = {
                    searched: true,
                    networkNameFilter: this.networkNameFilter,
                    networkName:this.networkName,
                    networkId: this.networkId,
                    networkStatus: this.networkStatus  ,
                    // netendDatecalender: this.netendDatecalender ,
                    netstartDateFrom: this.netstartDateFrom  ,
                    netstartDateTo: this.netstartDateTo ,
                    netEndDateFrom: this.netEndDateFrom  ,
                    netEndDateTo: this.netEndDateTo  ,
                    product: this.product ,
                    productBenefit: this.productBenefit ,
                    selectprovider: this.selectprovider ,
                    clinicalSpecial: this.clinicalSpecial ,
                    groupPolicy: this.groupPolicy,
                }
                console.log(this.searched,"CACHE STATE is if", cache_state);
                this.$store.commit('NS_store', cache_state);
            }else{
                 var cache_state = {
                    searched: false,
                 }
                console.log(this.searched,"CACHE STATE is else", cache_state);
                this.$store.commit('NS_store', cache_state);
            }

            // return
            this.$router.push('/NetworkManagement')
        },

        // seekinsti(){
        //     this.inPage = 1
        //     this.SearchProviderInstitution(this.inPage);
        // },

        seekSearchData(indata){
            this.page=indata
            this.SearchNetwork(this.page);
        },
       
        nextPageData(indata){

            console.log("isSearchIndiv => ", this.typesearch);

            if(this.pagelength == indata){
                this.swap_final_count = true
            }else{
                this.swap_final_count = false
            }

            console.log('indiv sort value',this.indiviSortBy)
            console.log('indiv page no value',this.page)
            
            //  this.getAllNetwork(this.indiviSortBy,this.page)

            //  this.SearchNetwork(this.page)

            if(this.typesearch==false){
                this.getAllNetwork(null,this.page)
                  console.log("from if ",this.typesearch)
            }else{
                console.log("from else ",this.typesearch)
                this.SearchNetwork(this.page)
        
            }

        },

        
        clearbtn(){


            if((this.networkNameFilter===''||this.networkNameFilter===undefined||this.networkNameFilter===null) 
            && (this.networkName===''||this.networkName===undefined||this.networkName===null) 
            && (this.networkId===''||this.networkId===undefined||this.networkId===null)
            && (this.networkStatus===''||this.networkStatus===undefined||this.networkStatus===null) 
            && (this.netstartDateFrom===''||this.netstartDateFrom===undefined||this.netstartDateFrom===null)
            && (this.netstartDateTo===''||this.netstartDateTo===undefined||this.netstartDateTo===null) 
            && (this.netEndDateFrom===''||this.netEndDateFrom===undefined||this.netEndDateFrom===null)
            && (this.netEndDateTo===''||this.netEndDateTo===undefined||this.netEndDateTo===null) 
            && (this.product===''||this.product===undefined||this.product===null)
            && (this.productBenefit===''||this.productBenefit===undefined||this.productBenefit===null) 
            && (this.selectprovider===''||this.selectprovider===undefined||this.selectprovider===null)
            && (this.clinicalSpecial===''||this.clinicalSpecial===undefined||this.clinicalSpecial===null) 
            && (this.groupPolicy===''||this.groupPolicy===undefined||this.groupPolicy===null)
            
            ){
                
                return
            }else{
                
                
                this.networkNameFilter=''
                this.networkName=''
                this.networkId=''
                this.networkStatus=''
                this.netstartDateFrom=''
                this.netstartDateTo=''
                this.netEndDateFrom=''
                this.netEndDateTo=''
                this.product=''

                this.productBenefit=''
                this.selectprovider=''
                this.clinicalSpecial=''
                this.groupPolicy=''
                this.getAllNetwork(null,1);
                this.typesearch = false
                this.page=1
                console.log('cancel network search data',)
                this.$refs.form.resetValidation()
                 this.showSearchdata=true,
                     this.showrsltnotfound=false
                    
            }
        },


    getAllNetwork(sortBy1,pageNum) {
        console.log("network sort by-------",sortBy1)
                console.log("network page no ---->",pageNum)
                
                    let self = this;   
                    console.log('next btn',self.page)
                    self.showSearchdata =true
                    self.showrsltnotfound=false
                    

                    let apiURL=''
                if(sortBy1===''||sortBy1===undefined||sortBy1===null){
                    apiURL=self.API_Service_NETWORK_MGMNT()+"network-management/get-all-Network-Details?perPage=20&pageNo="+pageNum
                }else{
                    apiURL=self.API_Service_NETWORK_MGMNT()+"network-management/get-all-Network-Details?perPage=20&pageNo="+pageNum+"&sortBy="+sortBy1
                }
                    
                    axios({
                    method: "get",
                    
                    url:apiURL,
                    // self.API_Service_NETWORK_MGMNT()+"network-management/get-all-Network-Details?perPage=20&pageNo="+pageNum,
       
                            headers: {
                                "Content-Type": "application/json", 
                                "x-access-token": self.token,                                
                            },
                        })
                        .then(function(response) {
                                       
                    let apiData = response.data.data.data

                    console.log("API RESPONSE for ALL Network:::::",apiData);
                  
                    // self.newproduct=response.data.data.data.products.productName
                    // console.log('newproduct----->',self.newproduct)
                    self.GetAllData = []
                    self.GetAllData= apiData.map(data => {
                                
                                return{
                                networkId:data.networkId,
                                networkName:data.networkName,
                                networkEffectiveStartDate:data.networkEffectiveStartDate,
                                networkEffectiveEndDate:data.networkEffectiveEndDate,
                                networkStatus:data.networkStatus,
                                productBenefits:data.productBenefits,
                                //   groupPolicy:data.groupPolicy,
                                _id:data._id,
                                
                                groupnewPolicy:data.groupPolicy.map(grpdata => {
                                    return grpdata.policyNo
                                }),
                                
                                productnewname: data.products.map(prodata => {
                                    return prodata.productName
                                })

                                }
                                
                        
                        // console.log("newprduct data",data.products)

                        
                    })
                     console.log("GetAllData RESPONSE ------->>>>>>>:::::",self.GetAllData);

                    // this.groupPolicy = GetAllData.hasOwnProperty('groupnewPolicy') == true ? this.chkplndata(GetAllData.groupnewPolicy) : ''
                    self.searched = false;
                     var cache_state = {
                        searched: false,
                    }
                   self.$store.commit('NS_store', cache_state);
                    self.pagelength=response.data.data.total_pages
                    // console.log(self.page,' <-- pageindu | next btn, pg length is ----->',self.pagelength)

                     self.totalLen=response.data.data.total
                      let l = self.GetAllData.length
                
                
                    console.log( 20*self.page,'skip simulation ');

                      var less_enough = self.totalLen
                        var checkless_init = false
                        if(less_enough < 20){
                            checkless_init = false
                        }else{
                            checkless_init = true
                        }

                        // if less than 15 we have second value same as total value as no pagination will occur
                        if(checkless_init){
                            
                                // checkinit is true means the final count is more than 15 (- 1)
                                var traverse =  20*self.page
                                self.fval = traverse-19
                                if(self.swap_final_count){
                                    self.sval =  self.totalLen
                                }else{
                                    self.sval = traverse
                                }

                        }else if(self.totalLen == 0){
                            
                          self.sval = self.fval = 0
                        
                       }else{
                             self.fval = 1
                             self.sval = self.totalLen
                        }

                    console.log('total ----->',self.totalLen)

                    // if(self.indiviSortBy != ''){
                    //     self.selectSorting_indi()
                    // }
					
						console.log("network table DATA is---->>::",self.GetAllData);
			
                        })
                        .catch(function(error) {
                            console.log(error);
                            self.showToast(error.response.data.msg,self.TOST().ERROR);
                        });
             
            },
            

             SearchNetwork(pageNum) {

                 console.log("incoming search result page no ---->",pageNum)

                    var self = this; 
                    if(self.networkName==""&&self.networkId==""&&self.networkStatus==""&&self.netstartDateFrom==""&&self.netstartDateTo==""
                    &&self.netEndDateFrom==""&&self.netEndDateTo==""&&self.product==""&&self.productBenefit==""&&self.selectprovider==""&&self.clinicalSpecial==""
                    &&self.groupPolicy==""&&self.networkNameFilter==""){
                        self.showToast("Please enter search criteria.",self.TOST().WARNING);
                    return
                    }
                    
                    // let demodata={
                    //         networkName:self.networkName,
                    //         networkId :self.networkId,
                    //         networkStatus:self.networkStatus,
                    //         netstartDateFrom:self.netstartDateFrom,
                    //         netstartDateTo:self.netstartDateTo,
                    //         netEndDateFrom:self.netEndDateFrom,
                    //         netEndDateTo:self.netEndDateTo,
                    //         product:self.product,
                    //         productBenefit:self.productBenefit,
                    //         providerName:self.selectprovider,
                    //         clinicalSpecial:self.clinicalSpecial,
                    //         groupPolicy:self.groupPolicy,
                    // }
                
                if(this.$refs.netform.validate()){
                    axios({
                    method: "get",
                    
                    url:self.API_Service_NETWORK_MGMNT()+"network-management/get-network-by-search?perPage="+20+"&pageNo="+pageNum+"&networkNameFilter="+self.networkNameFilter+"&networkName="+self.networkName+"&networkId="+self.networkId+"&networkStatus="+self.networkStatus
                    +"&networkEffectiveStartDateFrom="+self.netstartDateFrom+"&networkEffectiveStartDateTo="+self.netstartDateTo+"&networkEffectiveEndDateFrom="+self.netEndDateFrom
                    +"&networkEffectiveEndDateTo="+self.netEndDateTo+"&products="+self.product+"&benefits="+self.productBenefit+"&clinicalSpecialities="+self.clinicalSpecial+"&groupPolicy="+self.checkdatanew(self.groupPolicy.policyNo)+"&providerName="+self.selectprovider,
     
                            headers: {
                                "Content-Type": "application/json",
                                "x-access-token": self.token,    
                            },
                            // data:demodata,

                        })
                        .then(function(response) {
                                console.log("Search network reporting>>>>>>", response);

                    if(!response.data.data || response.data.data.length == 0){
                        console.log("Array is either empty or does not exist")
                        self.showSearchdata =false,
                        self.showrsltnotfound=true
                        
                    }else{
                        self.rsltData=response.data.data.data
                        console.log("self.rsltData",self.rsltData)
                        self.showSearchdata =true,
                        self.showrsltnotfound=false
                        
                    }
                                
                        let apiData=response.data.data.data
                        let status=response.status
                        
                        console.log("API provider individual search RESPONSE -->:::::",apiData);
                        console.log("API status RESPONSE -->:::::",status);
                        
                        self.GetAllData = []
                        // self.GetAllData =apiData

                        self.GetAllData= apiData.map(data => {
                                 console.log('api data',data)
                                return{
                                networkId:data.networkId,
                                networkName:data.networkName,
                                 networkEffectiveStartDate:data.networkEffectiveStartDate,
                                 networkEffectiveEndDate:data.networkEffectiveEndDate,
                                networkStatus:data.networkStatus,
                                productBenefits:data.productBenefits,
                                    // groupPolicy:data.groupPolicy,
                                _id:data._id,
                                groupnewPolicy: data.hasOwnProperty('groupPolicy') ? data.groupPolicy.length !=0 ? data.groupPolicy.map(grpdata => {
                                    return grpdata.policyNo
                                }) : [] : '' ,
                               
                                productnewname:data.hasOwnProperty('products') ?  data.products.length !=0 ? data.products.map(prodata => {
                                     
                                    return prodata.productName 
                                }) : [] : ''
                                }  
                                // console.log("newprduct data",data.products)  
                        })
                        // for state-managed
                        self.searched = true
                        self.typesearch = true
                        self.isSearchindiv = true
                        console.log('isSearchindiv in SearchNetwork function ', self.isSearchindiv)
                                  

                // for network search pagination
                    
                    self.pagelength=response.data.data.total_pages
                    // console.log(self.page,' <-- page search | search next btn, pg length is ----->',self.pagelength)

                     self.totalLen=response.data.data.total

                      let l = self.GetAllData.length
                
                    console.log( 20*self.page,'search skip simulation ');

                      var less_enough = self.totalLen
                        var checkless_init = false
                        if(less_enough < 20){
                            checkless_init = false
                        }else{
                            checkless_init = true
                        }

                        // if less than 15 we have second value same as total value as no pagination will occur
                        if(checkless_init){
                            
                                // checkinit is true means the final count is more than 15 (- 1)
                                var traverse =  20*self.page
                                self.fval = traverse-19
                                if(self.swap_final_count){
                                    self.sval =  self.totalLen
                                }else{
                                    self.sval = traverse
                                }

                        }else if(self.totalLen == 0){
                            
                          self.sval = self.fval = 0
                        
                       }else{
                             self.fval = 1
                             self.sval = self.totalLen
                        }

                    console.log('total search----->',self.totalLen)
                                    // self.prnNumber=""
                                    // console.log("prn clear status RESPONSE -->",self.prnNumber);

            
                        })
                        .catch(function (error) {
                    console.log("Search individual Error>>>>>>", error);
                    // return
                        // self.showToast(error.response.data.msg,self.TOST().ERROR);
                        self.showToast('No matching network found.',self.TOST().ERROR);
                        self.showSearchdata =false
                        self.showrsltnotfound=true
        
        
                    // if(self.userValue=="SuperUser" ||self.userValue=="DEO" || self.userValue=="Reviewer" || self.userValue=="ProviderManagementTeam"){
                    //             self.btndisCreateindu =false
                    // }else{
                    //     self.btndisCreateindu =true
                    // }

                });
                }else{
                this.$refs.netform.validate()
                this.showToast("Enter valid values in all the required fields.",this.TOST().WARNING);
            }
          }
                    
         },
    
        
    // }
    
}
</script>

<style>

.cstm-clr-pr{
    color:#646666 !important;
    margin-bottom: 5px !important;
    font-size: 13px !important;
}
.cust_pr .v-input{
    font-size: 13px !important;
}
.cust_pr .v-input input {
    font-size: 13px !important;
}

.cust_pr .v-input .v-label {
    font-size: 13px !important;
}
.hdr-txt-clr1 {
    color: #10242B !important;
}


</style>