<template>
    <v-app>
        <v-card class="pa-5 mb-2" tile>
         <v-flex xs3 >
            <v-layout column class="cust_pr">
                    <p class="font-weight-bold cstm-clr-pr">
                    Plan
                    </p>
                    <v-select
                        :items="planItems"
                        label="Select"
                        v-model="plan"
                        item-text="planName"
                        item-value="_id"
                        solo
                        dense
                        disabled
                        class="rounded-0">
                    </v-select>
            </v-layout>
        </v-flex>
        </v-card>

         <v-expansion-panels class="" tile accordion :disabled="viewonly">
            <v-expansion-panel>
                <v-expansion-panel-header expand-icon="mdi-menu-down" class="font-weight-bold">
                    Medical Exclusions
                </v-expansion-panel-header>
                <v-divider></v-divider>
                <v-expansion-panel-content>
                    <v-card tile>

                        <v-checkbox
                            dense
                            hide-details
                            class="pa-2 ma-0"
                            v-model="allMdclExclnsSelctd"
                             @click="selectAllMdclExclns"
                            color="blue">
                            <span slot="label" class="font-weight-bold" style="font-size: 14px">Select All</span>
                        </v-checkbox>
                        <v-divider></v-divider>

                        <v-checkbox v-for="(lst,index) in mdclExclns" :key="index"
                            v-model="lstmdclExclns"
                             @click="slctMdclExclns"
                            color="blue"
                            :value="lst._id"
                            dense
                            hide-details
                            :disabled="viewonly"
                            style="border-bottom:1px solid #C1C8CC"
                            class="pa-2 ma-0"
                        >
                        <span slot="label" class="font-weight-medium " style="font-size: 14px;word-wrap: break-word; ">{{lst.medicalExclusion}}</span>

                        </v-checkbox>
                     </v-card>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
                <v-expansion-panel-header expand-icon="mdi-menu-down" class="font-weight-bold">
                    No Medical Exclusions
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                     <v-card tile>
                        <v-checkbox
                            dense
                            hide-details
                            class="pa-2 ma-0"
                            v-model="allnonMdclExclnsSelctd"
                             @click="selectAllnonMdclExclns"
                            color="blue">
                            <span slot="label" class="font-weight-bold" style="font-size: 14px">Select All</span>
                        </v-checkbox>
                        <v-divider></v-divider>
                        <v-checkbox v-for="(lst,index) in nonMdclExclns" :key="index"
                            v-model="lstnonMdclExclns"
                            color="blue"
                            :value="lst._id"
                            @click="slctnonMdclExclns"
                            dense
                            hide-details
                            :disabled="viewonly"
                            style="border-bottom:1px solid #C1C8CC"
                            class="pa-2 ma-0"
                        >
                        <span slot="label" class="font-weight-medium " style="font-size: 14px;word-wrap: break-word; ">{{lst.nonMedicalExclusion}}</span>
                        </v-checkbox>
                     </v-card>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>

        <v-card tile class="mx-3 pa-3 my-4" style="background-color: #F7F7F7; display:flex;justify-content:space-between"  >

                <v-layout style="flex:1" justify-end>
                    <v-btn dark  tile style="background-color:#E46A25" class="text-capitalize px-3 mx-2" small @click="returnTab()"><v-icon dark small>arrow_back</v-icon> <v-divider vertical class="mx-2"></v-divider>Return</v-btn>
                    <v-btn :dark="!viewonly"  :disabled="viewonly" tile style="background-color:#23B1A9" class="text-capitalize px-3 mx-2" small @click="geExls_save()"><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider>Save</v-btn>
                    <v-btn  dark @click="nextTab()" tile  style="background-color:#152F38" class="text-capitalize px-3 ml-2" small ><v-icon dark small>arrow_forward</v-icon> <v-divider vertical class="mx-2"></v-divider> Next</v-btn>
                </v-layout>

        </v-card>

    </v-app>
</template>

<script>
import axios from "axios";
import moment from 'moment';

export default {
    


    components:{
        
    },

    created() {


        this.$store.commit("SAVE_PAGE_HEADER", "General Exclusions");
         this.token=this.$store.getters.successToken
        this.getMedicalExclusions()
        this.getNonMedicalExclusions()
        // console.log(this.$store.getters.fetchPlcyIdForPlnData.plan_No,this.$store.getters.fetchPlcyIdForPlnData,"Plan No")
        // if(this.$store.getters.fetchPlcyIdForPlnData.text==="ConfgrPlnFrPlcy"){
        //     this.getGroupPlanbyId(this.$store.getters.fetchPlcyIdForPlnData.plan_No)
        //     this.viewonly=false
        // }

        this.btnName=this.$store.getters.fetchPlcyIdForPlnData.text
       if(this.btnName==="EditPlnFrPlcy"||this.btnName==="ConfgrPlnFrPlcy"){
            // this.nextbtn=false
            this.getGroupPlanbyId(this.$store.getters.fetchPlcyIdForPlnData.plan_No)
        }

         if(this.btnName==="EditNwRtlPln"||this.btnName==="CnfgrNwRtlPln"){
            // this.nextbtn=false
            this.getRetailPlanbyId(this.$store.getters.fetchPlcyIdForPlnData.retailPlan_Id)
        }
        
        if(this.btnName==="EditPlnFrPlcy"||this.btnName==="CrteNwPlnFrPlcy"||this.btnName==="EditNwRtlPln"||this.btnName==="CrteNwRtlPln"){
            this.viewonly=true
        }
            

    },
    computed: {

    },

    data() {
        return {
            token:null,
             btnName:'',
            plan:"",
            planItems:[],
            mdclExclns:[],
            lstmdclExclns:[],
            nonMdclExclns:[],
            lstnonMdclExclns:[],
            viewonly:false,
            allnonMdclExclnsSelctd:false,
            allMdclExclnsSelctd:false,

        }

    },

    methods: {
        nextTab(){
            console.log("emitted")
             this.$emit('next');
        },
        returnTab(){
            console.log("emitted")
            this.$emit('return');
        },
         selectAllMdclExclns(){
            this.lstmdclExclns = [];
            if (this.allMdclExclnsSelctd) {
                for (let i in this.mdclExclns) {
                    this.lstmdclExclns.push(this.mdclExclns[i]._id.toString());
                }
            }
        },
        selectAllnonMdclExclns(){
            this.lstnonMdclExclns = [];
            if (this.allnonMdclExclnsSelctd) {
                for (let i in this.nonMdclExclns) {
                    this.lstnonMdclExclns.push(this.nonMdclExclns[i]._id.toString());
                }
            }
        },

        slctnonMdclExclns(){
            this.allnonMdclExclnsSelctd = false;
            
            if(this.lstnonMdclExclns.length===this.nonMdclExclns.length){
                console.log(this.allnonMdclExclnsSelctd,"if")
                this.allMdclExclnsSelctd= true
            }else{
                this.allnonMdclExclnsSelctd= false
                console.log(this.allnonMdclExclnsSelctd,"else")
            }
        },
        slctMdclExclns(){
            this.allMdclExclnsSelctd = false;
            
            if(this.lstmdclExclns.length===this.mdclExclns.length){
                console.log(this.allMdclExclnsSelctd,"if")
                this.allMdclExclnsSelctd= true
            }else{
                this.allMdclExclnsSelctd= false
                console.log(this.allMdclExclnsSelctd,"else")
            }
        },

        getMedicalExclusions(){
            console.log("getMedicalExclusions")
            let self = this;   
                axios({
                    method: "get",
                    url: self.API_Service_MASTER()+"plan/get-medical-exclusions", 
                        headers: {
                            "Content-Type": "application/json", 
                            // "x-access-token": self.token,
                        }
                })
                .then(function(response) {
                    console.log('get-medical-exclusions------->>>',response.data.data);
                    self.mdclExclns=[]
                    self.mdclExclns=response.data.data
                   
                })
                .catch(function(error) {
                    console.log(error);
                    // self.showToast(error.response.data.msg,self.TOST().ERROR);
                });
        },
        getNonMedicalExclusions(){
            console.log("getNonMedicalExclusions")
                let self = this;   
                axios({
                    method: "get",
                    url:self.API_Service_MASTER()+"plan/get-non-medical-exclusions", 
                        headers: {
                            "Content-Type": "application/json", 
                            // "x-access-token": self.token,
                        }
                })
                .then(function(response) {
                    console.log('get-Non-Medical-exclusions------->>>',response.data.data);
                    self.nonMdclExclns=[]
                    self.nonMdclExclns=response.data.data
                   
                })
                .catch(function(error) {
                    console.log(error);
                    // self.showToast(error.response.data.msg,self.TOST().ERROR);
                });
        },
        getGroupPlanbyId(planId){
            console.log(planId," Incoming PlanId")
            let self = this;   
            axios({
                method: "get",
                url:self.API_Service_admin()+"group-plan/get-group-plan/"+planId, 
                headers: {
                    "Content-Type": "application/json", 
                    "x-access-token": self.token,
                }
            })
            .then(function(response) {
                console.log('Product Details Response------->>>',response.data.data);
                let pdtDta={}
                pdtDta=response.data.data
                self.lstmdclExclns=pdtDta.hasOwnProperty('generalExclusions')?self.chkplndata(pdtDta.generalExclusions.medicalExcliusions):[]
                self.lstnonMdclExclns=pdtDta.hasOwnProperty('generalExclusions')?self.chkplndata(pdtDta.generalExclusions.nonMedicalExclusions):[]
                
                 self.planItems.push(pdtDta)
                self.plan=self.planItems[0]
                if(self.lstnonMdclExclns.length===self.nonMdclExclns.length){
                        console.log(self.allnonMdclExclnsSelctd,"if",self.lstnonMdclExclns.length)
                        self.allnonMdclExclnsSelctd= true
                }else{
                    self.allnonMdclExclnsSelctd= false
                    console.log(self.allnonMdclExclnsSelctd,"else",self.lstnonMdclExclns.length)
                }

                if(self.lstmdclExclns.length===self.mdclExclns.length){
                    console.log(self.allMdclExclnsSelctd,"if")
                    self.allMdclExclnsSelctd= true
                }else{
                    self.allMdclExclnsSelctd= false
                    console.log(self.allMdclExclnsSelctd,"else")
                }

               
            })
            .catch(function(error) {
                console.log(error);
                // self.showToast(error.response.data.msg,self.TOST().ERROR);
            });
        },
    chkplndata(data){
            console.log("Policy sara",data); 
            try{
                if (data ==="" || data === "undefined" || data === null) {
                    return []
                } else {
                    return data;
                }

             }catch(err) {
                console.log(err)
            } 
        },
        getRetailPlanbyId(planId){
            console.log(planId," Incoming PlanId")
            let self = this;   
            axios({
                method: "get",
                url:self.API_Service_admin()+"retail-plan/get-retail-plan/"+planId, 
                headers: {
                    "Content-Type": "application/json", 
                    "x-access-token": self.token,
                }
            })
            .then(function(response) {
                console.log('Product Details Response------->>>',response.data.data);
                let pdtDta={}
                pdtDta=response.data.data
                self.lstmdclExclns=pdtDta.hasOwnProperty('generalExclusions')?self.chkplndata(pdtDta.generalExclusions.medicalExcliusions):[]
                self.lstnonMdclExclns=pdtDta.hasOwnProperty('generalExclusions')?self.chkplndata(pdtDta.generalExclusions.nonMedicalExclusions):[]
                self.planItems.push(pdtDta)
                self.plan=self.planItems[0]

                 if(self.lstnonMdclExclns.length===self.nonMdclExclns.length){
                        console.log(self.allnonMdclExclnsSelctd,"if",self.lstnonMdclExclns.length)
                        self.allnonMdclExclnsSelctd= true
                }else{
                    self.allnonMdclExclnsSelctd= false
                    console.log(self.allnonMdclExclnsSelctd,"else",self.lstnonMdclExclns.length)
                }

                
                if(self.lstmdclExclns.length===self.mdclExclns.length){
                    console.log(self.allMdclExclnsSelctd,"if")
                    self.allMdclExclnsSelctd= true
                }else{
                    self.allMdclExclnsSelctd= false
                    console.log(self.allMdclExclnsSelctd,"else",self.lstmdclExclns.length)
                }

                
            })
            .catch(function(error) {
                console.log(error);
                // self.showToast(error.response.data.msg,self.TOST().ERROR);
            });
        },

    geExls_save(){
        console.log(this.lstmdclExclns,this.lstnonMdclExclns,"self.lstmdclExclns,self.lstnonMdclExclns")
        console.log(this.plan,"self.plan")
        let self=this
            var formData ={
               medicalExcliusions:self.lstmdclExclns,
               nonMedicalExclusions:self.lstnonMdclExclns
            }

            // this.nextbtn=false
            console.log('Basic Plan form saved data',formData)

            let apiURL=''
            if(self.btnName==="ConfgrPlnFrPlcy"){
                apiURL=self.API_Service_admin()+"group-plan/update-group-general-exclusions/"+self.plan._id
            }else if(self.btnName==="CnfgrNwRtlPln"){
                apiURL=self.API_Service_admin()+"retail-plan/update-retail-general-exclusions/"+self.plan._id
            }
        axios({
            method: "put",
            url:apiURL,
            headers: {
                "Content-Type": "application/json",
                "x-access-token": self.token,
                // "x-access-token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI1ZmJlMDg4ZGM0NmRhODNlZTdmODcxYmIiLCJyb2xlIjoiQWRtaW5Vc2VyIiwiaWF0IjoxNjA2MzY5MzI0LCJleHAiOjE2Mzc5MDUzMjR9.iS5hZs0uImNFz-rxNaQ7CTCc6qS_hG4H0FsePZ5Sgqc",
            },
            data:formData,
            })
        .then(function (response) {
            console.log(" Create Policy for product API Response>>>>>>", response);
            self.showToast(response.data.msg ,self.TOST().SUCCESS)

        })
        .catch(function (error) {
            console.log(" Create Policy for product Error APi Response>>>>>>", error);
            // self.showToast(error.response.data.msg,self.TOST().ERROR);
        });

    },

    watch: {
    
    },
} 
    
}

</script>
<style >

.bdrbtm{
   
    border:0.5px #C1C8CC solid;
     border-left: 0px;
      border-right: 0px;
}

.apart{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.hdr-txt-clr{
    color:#10242B !important;
}
.cust_pr .v-text-field .v-input__slot,
.v-text-field--outline .v-input__slot {
    min-height: 42px !important;
    display: flex !important;
    align-items: center !important;
    /* border:1px red solid; */
}

.pending-work{
    justify-items: start;
}

.lay{
        direction: rtl;
        max-height: 50px;

}
.body-2{
        font-size:20px !important;

}
.body-3{
        font-size:18px !important;

}
.rowit{ display:flex; flex-direction: column; }

 .exp_pan{padding:15px;}
.exp_pan .v-expansion-panel{margin:10px 0px !important;} 
.exp_pan .ttl{ font-weight: 500; margin-bottom: 0px !important; }
/* .v-expansion-panel::before {
   box-shadow: none !important;
} */
.insidetable{display:flex; flex-direction:row; align-items: center ;background-color: #fff; padding:5px; border:1px #ccc solid; border-bottom:0px;}
.insidetable:last-child{ border-bottom:1px #ccc solid}
/* .v-expansion-panels--accordion > .v-expansion-panel */


.verticalline{
        height: 16px !important;
        width: 1px !important;
        margin-right: 8px !important;
        margin-left: 0px;
        background-color: #C1C8CC !important;


}
.cstm-clr-pr{
    color:#10242B;
    margin-bottom: 5px;
}
.cust-bnkAcc-tbl table.v-table thead th{
    border-right:1px solid  #C1C8CC !important;
    /* background-color:#F3FBFB !important; */
    color:#10242B !important;
    font-size:13px !important;
    font-family:Roboto !important;
    font-weight:700 !important;

}
.cust-bnkAcc-tbl table.v-table thead th:last-child{
    border-right: unset !important;
    
}
.cust-bnkAcc-tbl table.v-table tbody td,
.cust-bnkAcc-tbl table.v-table thead tr{
    height: 40px !important;
}
.cust-bnkAcc-tbl table.v-table thead tr:last-child{
    height: unset !important;
    
}


.bnkAcc-tbl-brdr{
    border:1px solid  #C1C8CC;
}
 .bnkAcc-td-bdrgt {
    border-right:1px solid #C1C8CC;
 }

 .cust-bnkAcc-pgn .v-pagination__navigation{
        box-shadow:unset !important;
        border-radius: unset !important;
        background-color:#FFFFFF !important;
        border:1px solid #DAE1E6 !important
    }
    .cust-bnkAcc-pgn .v-pagination__navigation--disabled{
        background-color:#DAE1E6 !important;
    }
    .cust-bnkAcc-pgn .v-pagination__item{
        box-shadow:unset !important;
        min-width:2rem !important;
        height:2rem !important;
        border-radius: unset !important;
        border:1px solid #DAE1E6 !important
    }   

    .cust-checkbox{
        color: #000;
    }
</style>