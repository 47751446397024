<template>
  <v-app>
    <v-container
      class="containerStyle"
      style="padding: 1.5rem; margin: 0; max-width: 100%"
    >
      <v-layout>
        <h3>Search Service Provider Master Approval</h3>
        <!-- <v-spacer></v-spacer>
              <v-btn class="btnToggle"
              @click="$router.push({name:'CreateServiceProvider'})"
                      outlined
                      elevation="0">
                      <v-icon>mdi-plus-box-outline</v-icon>
                      <v-divider></v-divider>
                      Create Service Provider
              </v-btn> -->
      </v-layout>
      <v-layout class="mt-8">
        <h4 class="mr-3">
          <strong
            >Service provider Master
            <span class="orange--text">({{ count }})</span></strong
          >
        </h4>
        <v-chip
          small
          color="#1EBBD9"
          text-color="white"
          label
          close
          v-if="showSearchChip == true"
          @click:close="
            clear()
            searchServiceProviderData(1)
          "
          >Search</v-chip
        >
        <!-- v-if="showSearchChip==true" 
                   -->
        <v-spacer></v-spacer>
        <!-- <v-btn depressed
              color="#E46A25"
              class="white--text mr-3"><v-icon>
                  mdi-file-excel
              </v-icon>
              <v-divider class="mx-4"
              vertical
              color="white"></v-divider>Export to Excel</v-btn> -->

        <!-- <v-btn depressed
              color="teal white--text" @click="search_Dialog=!search_Dialog"> 
              <v-icon>mdi-arrow-right-circle</v-icon>
              <v-divider class="mx-4"
              vertical
              color="white" ></v-divider>
                Search</v-btn> -->
      </v-layout>
      <v-form v-model="search_Dialog" width="1150">
        <v-card>
          <!-- <v-card-title class="white--text"
                  style="background-color: #23b1a9">
                  <h4>Search Service Provider</h4>
                  <v-spacer></v-spacer>
                  <v-icon @click="search_Dialog=!search_Dialog" class="white--text">mdi-window-close</v-icon>
              </v-card-title> -->
          <v-card-text class="white lighten-2">
            <v-layout class="pt-4 form-groups-container">
              <v-flex class="form-group">
                <label class="form-label">LOB</label>
                <v-select
                  solo
                  :items="lob_Items"
                  dense
                  class="form-control"
                  v-model="lob"
                  placeholder="Select..."
                >
                </v-select>
              </v-flex>
              <v-flex class="form-group">
                <label class="form-label">Service Provider Code</label>
                <!-- <v-text-field solo dense class="form-control" v-model="serviceProviderCode" :rules="[(v)=>matchRegex(v,'alphanum')]" placeholder="Enter..."></v-text-field> -->

                <v-autocomplete
                  :search-input.sync="searchserviceProviderId"
                  :maxlength="138"
                  selection
                  class="forminput"
                  placeholder="Start typing to Search"
                  solo
                  dense
                  :items="serviceProviderIdData"
                  item-text="serviceProviderId"
                  item-value="serviceProviderId"
                  deletable-chips
                  v-model="serviceProviderCode"
                  :rules="[(v) => matchRegex(v, 'alphanum')]"
                  @keyup="smartSearch('serviceProviderCode',searchserviceProviderId)"
                >
                </v-autocomplete>
              </v-flex>
              <v-flex class="form-group">
                <label class="form-label">Service Provider Name</label>
                <!-- <v-text-field solo dense class="form-control" :rules="[(v)=>matchRegex(v,'alphanum')]" v-model="serviceProviderName" placeholder="Enter..."></v-text-field> -->

                <v-autocomplete
                  :search-input.sync="searchreassignAllExaminer"
                  :maxlength="138"
                  class="forminput"
                  placeholder="Start typing to Search"
                  solo
                  dense
                  :items="Examineritems"
                  item-text="serviceProviderDetail"
                  item-value="serviceProviderDetails.employeeName"
                  v-model="serviceProviderName"
                  clearable
                  @click:clear="$nextTick(() => clearData())"
                  @keyup="smartSearch('serviceProviderName',searchreassignAllExaminer)"
                >
                </v-autocomplete>
              </v-flex>
              <v-flex class="form-group">
                <label class="form-label">Bank Name</label>
                <!-- <v-text-field solo dense class="form-control" :rules="[(v)=>matchRegex(v,'alpha')]" v-model="bankName" placeholder="Enter..."></v-text-field> -->

                <v-autocomplete
                  :search-input.sync="searchserviceProviderbankName"
                  :maxlength="138"
                  selection
                  class="forminput"
                  placeholder="Start typing to Search"
                  solo
                  dense
                  :items="serviceProviderbankData"
                  item-text="bankDetails.bankName"
                  item-value="bankDetails.bankName"
                  deletable-chips
                  v-model="bankName"
                  :rules="[(v) => matchRegex(v, 'alphanum')]"
                  clearable
                  @click:clear="$nextTick(() => (bankName = ''))"
                  @keyup="smartSearch('bankName',searchserviceProviderbankName)"
                ></v-autocomplete>
              </v-flex>
              <v-flex class="form-group">
                <label class="form-label">PAN</label>
                <!-- <v-text-field solo dense class="form-control" :rules="[(v) => validatePanNumber(v, 'PAN Number')]" :value="computedPAN" v-model="panNumber" placeholder="Enter..."></v-text-field> -->

                <v-autocomplete
                  :search-input.sync="searchserviceProviderpanNumber"
                  :maxlength="138"
                  selection
                  class="forminput"
                  placeholder="Start typing to Search"
                  solo
                  dense
                  :items="serviceProviderpanData"
                  item-text="panNumber"
                  item-value="panNumber"
                  deletable-chips
                  v-model="panNumber"
                  clearable
                  @click:clear="$nextTick(() => (panNumber = ''))"
                  @keyup="smartSearch('panNumber',searchserviceProviderpanNumber)"
                >
                </v-autocomplete>
              </v-flex>
            </v-layout>

            <v-layout class="pt-4 form-groups-container">
              <v-flex class="form-group">
                <label class="form-label">GST Number</label>
                <!-- <v-text-field solo dense class="form-control" :rules="[(v)=>matchRegex(v,'alphanum')]" v-model="gstinNumber" placeholder="Enter..."></v-text-field> -->

                <v-autocomplete
                  :search-input.sync="searchserviceProvidergstinNumber"
                  :maxlength="138"
                  selection
                  class="forminput"
                  placeholder="Start typing to Search"
                  solo
                  dense
                  :items="serviceProvidergstData"
                  item-text="gstinNumber"
                  item-value="gstinNumber"
                  deletable-chips
                  v-model="gstinNumber"
                  :rules="[(v) => matchRegex(v, 'alphanum')]"
                  clearable
                  @click:clear="$nextTick(() => (gstinNumber = ''))"
                  @keyup="smartSearch('gstinNumber',searchserviceProvidergstinNumber)"
                ></v-autocomplete>
              </v-flex>
              <v-flex class="form-group">
                <label class="form-label">City</label>
                <!-- <v-text-field solo dense class="form-control" :rules="[(v)=>matchRegex(v,'alpha')]" v-model="spCity" placeholder="Enter..."></v-text-field> -->
                <v-autocomplete
                  :search-input.sync="searchserviceProvidercity"
                  :maxlength="138"
                  class="forminput"
                  placeholder="Start typing to Search"
                  solo
                  dense
                  :items="serviceProvidercityData"
                  item-text="spAddress[0].spCity"
                  item-value="spAddress[0].spCity"
                  deletable-chips
                  v-model="spCity"
                  clearable
                  @click:clear="$nextTick(() => (spCity = ''))"
                  @keyup="smartSearch('spCity',searchserviceProvidercity)"
                ></v-autocomplete>
              </v-flex>
              <v-flex class="form-group">
                <label class="form-label">PinCode</label>
                <!-- <v-text-field solo dense class="form-control" :rules="[(v)=>matchRegex(v,'nums')]" v-model="spPincode" placeholder="Enter..."></v-text-field> -->

                <v-autocomplete
                  :search-input.sync="searchserviceProviderpincode"
                  :maxlength="138"
                  selection
                  class="forminput"
                  placeholder="Start typing to Search"
                  solo
                  dense
                  :items="serviceProviderpincodeData"
                  item-text="spAddress[0].spPincode"
                  item-value="spAddress[0].spPincode"
                  deletable-chips
                  v-model="spPincode"
                  :rules="[(v) => matchRegex(v, 'nums')]"
                  clearable
                  @keyup="smartSearch('spPincode',searchserviceProviderpincode)"
                >
                </v-autocomplete>
              </v-flex>
              <v-flex class="form-group">
                <label class="form-label">Service Provider Type</label>
                <v-select
                  solo
                  dense
                  :items="serviceProviderItems"
                  class="form-control"
                  v-model="serviceProviderType"
                  placeholder="select"
                ></v-select>
              </v-flex>
              <v-flex class="form-group">
                <label class="form-label">Licence Expiry Date</label>
                <v-menu
                  v-model="menu1"
                  offset-y
                  nudge-top="20"
                  :close-on-content-click="false"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="computedlicenseExpiryDate"
                      placeholder="DD/MM/YYYY"
                      dense
                      solo
                      readonly
                      v-on="on"
                      @focus="menu1 = true"
                      @blur="menu1 = true"
                      v-bind="attrs"
                      class="form-control"
                      append-icon="mdi-menu-down"
                      prepend-inner-icon="mdi-calendar"
                    >
                    </v-text-field>
                  </template>
                  <v-date-picker
                    no-title
                    :pickerDate.sync="pickerDate"
                    v-model="licenseExpiryDate"
                    @input="menu1 = false"
                  >
                  </v-date-picker>
                </v-menu>
              </v-flex>
            </v-layout>
            <v-layout wrap class="pt-4 form-groups-container">
              <!-- <v-flex class="form-group">
                          <label class="form-label">Status</label>
                          <v-select solo dense class="form-control" :items="status_Items" v-model="approveStatus" placeholder="select"></v-select>
                      </v-flex> -->
              <v-flex class="form-group">
                <label class="form-label">Mobile Number</label>
                <v-text-field
                  solo
                  dense
                  class="form-control"
                  :maxlength="10"
                  v-model="mobileNumber"
                  :rules="[
                    (v) => matchRegex(v, 'nums'),
                    (v) =>
                      validateLength(
                        v,
                        { exact: 10 },
                        '',
                        'Field requires 10 digits.'
                      ),
                  ]"
                  placeholder="select"
                ></v-text-field>
              </v-flex>
              <v-flex class="form-group">
                <label class="form-label">Is External Service Provider</label>
                <v-radio-group
                  row
                  solo
                  dense
                  class="form-control mt-0"
                  v-model="isExternalServiceProvider"
                >
                  <v-radio label="YES" value="Yes">YES</v-radio>
                  <v-radio label="NO" value="No">NO</v-radio>
                </v-radio-group>
              </v-flex>
              <v-flex class="form-groupss">
                <label class="form-label">Active/Inactive Status</label>
                <v-radio-group
                  row
                  solo
                  dense
                  class="form-control mt-0"
                  v-model="active"
                >
                  <v-radio label="Active" value="true">YES</v-radio>
                  <v-radio label="Inactive" value="false">NO</v-radio>
                </v-radio-group>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-card-actions>
            <!-- <v-btn class="reset createbtn" style="background:white" dark @click="clear()">Reset</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn class="createbtn cancelbtn" @click="search_Dialog=!search_Dialog;clear()">Cancel</v-btn>
                  <v-btn color="#23b1a9" class="createbtn white--text" @click="searchServiceProviderData(1)">Search</v-btn> -->
          </v-card-actions>
        </v-card>
      </v-form>

      <v-row class="mt-2 mb-4">
        <v-flex justify="end">
          <v-row class="btnDiv">
            <v-col align="right">
              <v-btn
                class="create-roe-resetbtn text-capitalize ml-8 mr-3 btnStyle"
                color="white"
                @click="clear()"
              >
                <v-icon class="" color="">mdi-backspace</v-icon>
                <v-divider class="mx-2" vertical color=""></v-divider>
                Reset
              </v-btn>

              <v-btn
                elevation=""
                color="#23B1A9"
                dark
                class="btnStyle mr-6 text-capitalize"
                type="submit"
                @click="searchServiceProviderData(1)"
              >
                <v-icon class="mr-2 createfont">mdi-magnify</v-icon>
                <div class="vertical_line"></div>
                Search
              </v-btn>
            </v-col>
          </v-row>
        </v-flex>
      </v-row>

      <!-- Main Table -->
      <v-data-table
        hide-default-footer
        :headers="main_Table_Header"
        :items="main_Table_Data"
        class="mt-5 vDataTableStyling"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-menu offset-y open-on-hover>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                elevation=""
                icon
                v-bind="attrs"
                v-on="on"
                style="text-align: center"
              >
                <v-icon>mdi mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list class="item-actions">
              <template>
                <v-list-item
                  v-for="(actions, index) in actionOptions"
                  :key="index"
                  @click="
                    editmaster(
                      actions.link,
                      index,
                      item,
                      item._id,
                      actions.name
                    )
                  "
                >
                  <!-- :disabled="actions.name === 'MODIFY' && !['', 'Saved'].includes(item.paymentStatus)" -->
                  <v-list-item-title :style="actions.color"
                    ><v-icon small>{{ actions.icon }}</v-icon>
                    {{ actions.name }}</v-list-item-title
                  >
                </v-list-item>
              </template>
            </v-list>
          </v-menu>
        </template>
        <template v-slot:[`item.licenseExpiryDate`]="{ item }">
          {{ dateFormat(item.licenseExpiryDate) }}
        </template>
        <template v-slot:[`item.active`]="{ item }">
          {{ item.active ? "Active" : "InActive" }}
        </template>
        <template v-slot:[`item.mobileNumber`]="{ item }">
          <template v-for="number in item.mobileNumber">
            {{ number.number }}</template
          >
        </template>
      </v-data-table>
      <!-- Pagination -->
      <v-layout justify-end align-center class="pb-2 px-0 mr-4">
        <div>
          <span style="color: orange"
            >Showing {{ pageStartIndex_Provider }}-{{
              pageEndIndex_Provider
            }}</span
          >
          out of {{ totalListCount_Provider }} records
        </div>
        <div>
          <v-pagination
            v-model="page_Provider"
            :length="pageCount_Provider"
            :total-visible="5"
            @input="searchServiceProviderData(page_Provider)"
            color="#152F38"
          ></v-pagination>
        </div>
      </v-layout>
      <!-- For Audit Trail -->
      <v-dialog v-model="audit_dialog" width="800">
        <v-card>
          <v-card-title class="white--text" style="background-color: #23b1a9">
            Audit Trail
            <v-spacer></v-spacer>
            <v-icon @click="audit_dialog = !audit_dialog" class="white--text"
              >mdi-window-close</v-icon
            >
          </v-card-title>
          <v-layout class="pt-4 form-group-container pr-4">
            <v-data-table
              :headers="audit_Header"
              hide-default-footer
              :items="audit_Data"
              class="vDataTableStyling w-100"
            >
              <template v-slot:[`item.srno`]="{ index }">
                {{ 1 + index }}
              </template>
              <template v-slot:[`item.action`]="{ item }">
                <v-btn
                  v-if="item.action === 'Modified'"
                  color="#FFFFFF"
                  class="text-capitalize tablebtn"
                  @click="seeModify(item)"
                >
                  {{ item.action }}
                  <v-icon class="tableicons">mdi-arrow-right-circle</v-icon>
                </v-btn>
                {{ item.action !== "Modified" ? item.action : null }}
              </template>
              <template v-slot:[`item.createdAt`]="{ item }">
                {{ formatDateTime(item.createdAt) }}
              </template>
            </v-data-table>
          </v-layout>
          <v-layout justify-end align-center class="pb-2 px-0 mr-4">
            <div>
              <span style="color: orange"
                >Showing {{ AuditPageStartIndex }}-{{ AuditPageEndIndex }}</span
              >
              out of {{ AuditTotalListCount }} records
            </div>
            <div>
              <v-pagination
                class="small-pagination"
                v-model="AuditPage"
                :length="AuditPageCount"
                :total-visible="5"
                @input="getAuditData(AuditPage, '', true)"
                color="#152F38"
              ></v-pagination>
            </div>
          </v-layout>
        </v-card>
      </v-dialog>
      <!-- Modify dialogue -->
      <v-dialog v-model="seeModifyDialog" width="600">
        <v-card>
          <v-card-title class="white--text" style="background-color: #23b1a9">
            <v-layout justify-space-between>
              <div>Modified Field Details</div>
            </v-layout>
            <v-btn
              color="transparent"
              small
              :ripple="false"
              depressed
              @click="seeModifyDialog = false"
            >
              <v-icon color="white">mdi-close </v-icon>
            </v-btn>
          </v-card-title>
          <div class="scrollable-table">
            <v-data-table
              disable-pagination
              :headers="modifyHeaders"
              :items="modifyItems"
              hide-default-footer
              class="pa-4 vDataTableStyling"
            >
              <template v-slot:[`item.srNo`]="{ index }">
                {{ 1 + index }}
              </template>

              <template v-slot:[`item.oldValue`]="{ item }">
                {{
                  item.fieldName == "License Expiry Date"
                    ? dateFormat(item.oldValue)
                    : item.oldValue
                }}
              </template>
              <template v-slot:[`item.newValue`]="{ item }">
                {{
                  item.fieldName == "License Expiry Date"
                    ? dateFormat(item.newValue)
                    : item.newValue
                }}
              </template>
            </v-data-table>
          </div>
        </v-card>
      </v-dialog>
      <v-btn
        class="create-roe-resetbtn text-capitalize ml-8 mr-3 btnStyle"
        color="white"
        @click="$router.push('/master-approval')"
      >
        <v-icon class="" color="">mdi-backspace</v-icon>
        <v-divider class="mx-2" vertical color=""></v-divider>
        Cancel
      </v-btn>
    </v-container>
    <v-form ref="approvalForm" @submit.prevent>
      <v-dialog v-model="reject_Dialog" width="600">
        <v-card>
          <v-card-title class="white--text" style="background-color: #23b1a9"
            ><v-layout
              style="display: flex; justify-content: center; margin-left: 170px"
              >Reject
            </v-layout>
            <v-layout style="display: flex; justify-content: end">
              <v-icon
                class="white--text"
                @click="
                  reject_Dialog = !reject_Dialog
                  reason = ''
                "
                >mdi-close</v-icon
              >
            </v-layout>
          </v-card-title>
          <v-layout class="pt-4 form-group-container">
            <v-flex class="mr-2">
              <label class="form-label"
                >Reason <span><strong class="red--text">*</strong></span></label
              >
              <v-text-field
                class="form-control"
                solo
                dense
                :rules="reject_Dialog ? [(v) => requiredField(v)] : []"
                v-model="reason"
                placeholder="Enter..."
              ></v-text-field>
            </v-flex>
          </v-layout>
          <v-card-actions style="display: flex; justify-content: center">
            <v-btn
              class="btn"
              color="red white--text mr-0"
              @click="
                reject_Dialog = !reject_Dialog
                reason = ''
              "
            >
              No
            </v-btn>

            <v-btn class="btn" color="green white--text mr-0" @click="onSubmit">
              Yes
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="approve_Dialog" width="600">
        <v-card>
          <v-card-title class="white--text" style="background-color: #23b1a9"
            ><v-layout
              style="display: flex; justify-content: center; margin-left: 170px"
              >Approve
            </v-layout>
            <v-layout style="display: flex; justify-content: end">
              <v-icon
                class="white--text"
                @click="
                  approve_Dialog = !approve_Dialog
                  reason = ''
                "
                >mdi-close</v-icon
              >
            </v-layout>
          </v-card-title>
          <v-layout class="pt-4 form-group-container" justify-center>
            <h4>Are you sure want to Approve ?</h4>
          </v-layout>
          <v-card-actions style="display: flex; justify-content: center">
            <v-btn
              class="btn"
              color="red white--text mr-0"
              @click="
                approve_Dialog = !approve_Dialog
                reason = ''
              "
            >
              No
            </v-btn>

            <v-btn class="btn" color="green white--text mr-0" @click="onSubmit">
              Yes
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>
    <!-- Audit Trail -->
    <audit-trail
      :dialogState="OpenAuditDialog"
      :auditTrailMaster="'serviceProvider'"
      :auditTrailItem="{ uid: auditTrailId }"
      @close-dialog="OpenAuditDialog = false"
    />
  </v-app>
</template>
<script>
import moment from "moment"
import auditTrail from "../../../../component/MasterAuditTrail.vue"
export default {
  components: {
    auditTrail,
  },

  data() {
    return {
      search_Dialog: false,
      allTableData:[],
      //Table_Header
      main_Table_Header: [
        { text: "Action", value: "actions", sortable: false, align: "center" },
        {
          text: "Master ID",
          value: "serviceProviderId",
          sortable: false,
          align: "center",
        },
        {
          text: "Name",
          value: "serviceProviderDetails.employeeName",
          sortable: false,
          align: "center",
        },
        {
          text: "LOB",
          value: "lob",
          sortable: false,
          align: "center",
        },
        {
          text: "Is External Service Provider",
          value: "isExternalServiceProvider",
          sortable: false,
          align: "center",
        },
        {
          text: "Service Provider Type",
          value: "serviceProviderType",
          sortable: false,
          align: "center",
        },
        {
          text: "PAN No",
          value: "panNumber",
          sortable: false,
          align: "center",
        },
        {
          text: "GST No",
          value: "gstinNumber",
          sortable: false,
          align: "center",
        },
        {
          text: "Bank Name",
          value: "bankDetails.bankName",
          sortable: false,
          align: "center",
          width: "250px",
        },
        // {text:"License Expiry Date",value:"operationData[0].licenseExpiryDate",sortable:false,align:"center" ,width:"110px"},
        {
          text: "Mobile Number",
          value: "mobileNumber[0].number",
          sortable: false,
          align: "center",
        },
        // {text:"Is External Service Provider",value:"isExternalServiceProvider",sortable:false,align:"center"},
        {
          text: "Active status",
          value: "active",
          sortable: false,
          align: "center",
        },
        {
          text: "City",
          value: "spAddress[0].spCity",
          sortable: false,
          align: "center",
        },
        {
          text: "State",
          value: "spAddress[0].spState",
          sortable: false,
          align: "center",
        },
        {
          text: "District",
          value: "spAddress[0].spDistrict",
          sortable: false,
          align: "center",
        },
        {
          text: "Pincode",
          value: "spAddress[0].spPincode",
          sortable: false,
          align: "center",
        },
        {
          text: "Approved/Pending",
          value: "approveStatus",
          sortable: false,
          align: "center",
          width: "160px",
        },
        {
          text: "Initiated By",
          value: "createdBy",
          sortable: false,
          align: "center",
          width: "180px",
        },
        {
          text: "Date and Time",
          value: "createdAt",
          sortable: false,
          align: "center",
          width: "170px",
        },
      ],
      main_Table_Data: [],
      audit_Data: [],
      modifyItems: [],
      audit_Header: [
        { text: "Sr. No.", value: "srno", sortable: false, align: "center" },
        {
          text: "Master ID",
          value: "masterId",
          sortable: false,
          align: "center",
        },
        {
          text: "Action Taken",
          value: "action",
          sortable: false,
          align: "center",
        },
        { text: "User ID", value: "userId", sortable: false, align: "center" },
        {
          text: "User Name",
          value: "userName",
          sortable: false,
          align: "center",
        },
        {
          text: "Time Stamp",
          value: "createdAt",
          sortable: false,
          align: "center",
        },
      ],
      modifyHeaders: [
        {
          text: "Sr. No.",
          value: "srNo",
          align: "center",
          sortable: false,
        },
        {
          text: "Field Name",
          value: "fieldName",
          align: "center",
          sortable: false,
        },
        {
          text: "Old Value",
          value: "oldValue",
          align: "center",
          sortable: false,
        },
        {
          text: "New Value",
          value: "newValue",
          align: "center",
          sortable: false,
        },
      ],
      seeModifyDialog: false,
      itemsPerPage: 10,
      totalListCount: 0,
      pageStartIndex: 1,
      pageCount: 0,
      pageNo: 1,
      actionOptions: [
        {
          name: "VIEW",
          icon: "mdi-eye-outline",
          // link: "/childrenEducationCoverAddAssessment",
          color: "color:#23b1a9",
          iconColor: "color:#10242B",
        },
        // {
        //   name: "Approve",
        //   icon: "mdi-check-circle-outline",
        //   color: "color:#23b1a9",
        //   iconColor: "color:green",
        // },
        // {
        //   name: "Reject",
        //   icon: "mdi-cancel",
        //   color: "color:#23b1a9",
        //   iconColor: "color:red",
        // },
        {
          name: "AUDIT TRAIL",
          icon: "mdi-chart-timeline-variant",
          // link: "/modifypage",
          color: "color:orange",
          iconColor: "color:orange",
        },
      ],
      editMaster_Data: [],
      audit_dialog: false,
      count: 0,
      panNumber: "",
      spCity: "",
      serviceProviderName: "",
      spPincode: "",
      // approveStatus:"",
      serviceProviderType: "",
      lob: "",
      active: "",
      isExternalServiceProvider: "",
      gstinNumber: "",
      bankName: "",
      mobileNumber: "",
      licenseExpiryDate: "",
      serviceProviderCode: "",
      pickerDate: null,
      serviceProviderItems: [
        "External Surveyor",
        "Investigator",
        "Lawyer/Legal Expert",
        "Doctor",
        "Frequent Payee",
        "Recovery Agent",
        "In - House with License",
        "In - House without  License",
        "SIU",
        "Structural Engineer/expert",
        "Drying, Cleaning experts",
        "Chartered Engineers",
        "Chartered Accountants/Tax",
        "Experts",
        "Forensic Science experts",
        "Forensic accounting experts",
        "Drone Operators",
        "Other Expert",
      ],
      menu1: false,
      showSearchChip: false,
      lob_Items: ["Health", "Marine", "AIGC", "EW", "Travel", "PA", "Home"],
      status_Items: ["Approved", "Rejected", "Pending for approval"],
      //pagination
      itemsPerPage_Provider: 10,
      totalListCount_Provider: 0,
      pageStartIndex_Provider: 1,
      page_Provider: 1,

      searchserviceProviderId: "",
      serviceProviderIdData: [],
      searchreassignAllExaminer: "",
      Examineritems: [],
      searchserviceProviderbankName: "",
      serviceProviderbankData: [],
      searchserviceProviderpanNumber: "",
      serviceProviderpanData: [],
      searchserviceProvidergstinNumber: "",
      serviceProvidergstData: [],
      searchserviceProvidercity: "",
      serviceProvidercityData: [],
      searchserviceProviderpincode: "",
      serviceProviderpincodeData: [],
      AuditPage: 1,
      AuditTotalListCount: 0,
      AuditPageCount: 0,
      AuditPageStartIndex: 0,
      AuditItemsPerPage: 10,
      approveStatus: "",

      reject_Dialog: false,
      approve_Dialog: false,
      reason: "",
      selectedItem: null,
      OpenAuditDialog: false,
      auditTrailId: null,
    }
  },
  methods: {
    clearData() {
      this.panNumber = ""
      this.gstinNumber = ""
      this.spCity = ""
    },

    getServiceCode(code) {
      // if(serviceProviderName){
      //  let indexTtem = this.Examineritems.find((elem) => {
      //     return elem.serviceProviderDetails.employeeName == (serviceProviderName.split("-")[0]).trim();
      //   });
      //   console.log(indexTtem)
      //    //this.serviceProviderbankData.push(indexTtem)
      //    this.serviceProviderpanData.push(indexTtem)
      //    this.serviceProvidergstData.push(indexTtem)
      //    this.serviceProvidercityData.push(indexTtem)
      //    //this.serviceProviderpincodeData.push(indexTtem)
      //   if(indexTtem){
      //     this.serviceProviderObj.gstinNumber=indexTtem.gstinNumber;
      //     this.serviceProviderObj.panNumber=indexTtem.panNumber;
      //    // this.serviceProviderObj.spCity=indexTtem.bankDetails.spCity;
      //     if(indexTtem.spAddress.length>0){
      //       this.serviceProviderObj.spCity = indexTtem.spAddress[0].spCity

      //     }
      //     // this.serviceProviderObj.bankName=indexTtem.bankDetails.bankName;
      //     // this.serviceProviderObj.spPincode=indexTtem.bankDetails.spPincode
      //   }
      // }
      if (code.length > 2) {
        this.serviceProviderIdData = this.allTableData
      } else {
        this.serviceProviderIdData = []
      }
    },

    //   searchserviceProviderId(value){
    //    if (value) {
    //     if (value.length > 2) {
    //       let requestData = "&pageNo=1"+"&perPage=50&"+"&serviceProviderId="+value;
    //       this.GET(
    //         "SERVICE_PROVIDER_MASTER_SEARCH",
    //         requestData,
    //         (res) => {
    //           this.serviceProviderIdData = [];
    //           if (res.data.data && res.data.data.data) {
    //             this.serviceProviderIdData = res.data.data.data;
    //           }
    //         },
    //         (error) => {
    //           this.serviceProviderIdData = [];
    //         },
    //       );
    //     }
    //   }
    // },

    getServiceName(value) {
      // if (value) {
      //   let  val =  (value.split("-")[0]).trim()
      //   if (val.length > 2) {
      //     let requestData = "&pageNo=1"+"&perPage=50&"+"&serviceProviderName="+val;
      //     this.GET(
      //       "SERVICE_PROVIDER_MASTER_SEARCH",
      //       requestData,
      //       (res) => {
      //         this.Examineritems = [];
      //         if (res.data.data && res.data.data.data) {
      //           this.Examineritems = res.data.data.data;
      //           var processed_pin = res.data.data.data.map((e) => {
      // 				if (e.serviceProviderDetails.employeeName && e.serviceProviderDetails.employeeName !== "") {
      // 					return {
      // 						locpincode: e.serviceProviderDetails.employeeName + " - " + (e.spAddress[0].spPincode.split("-")[0]).trim(),
      //             //  e.bankDetails.spPincode,
      // 						...e,
      // 					};
      // 				}
      //         //  else {
      // 				// 	return {
      // 				// 		locpincode: e.bankDetails.spPincode,
      // 				// 		all: e,
      // 				// 	};
      // 				// }
      // 			});
      //       console.log(processed_pin)

      //         	this.Examineritems = processed_pin.filter(x=>x.locpincode)
      //           console.log(" ddddddddddddddd ", this.Examineritems)

      //         }
      //       },
      //       (error) => {
      //         this.Examineritems = [];
      //       },
      //     );
      //   }
      // }else{
      //   this.Examineritems = [];
      // }
      if (value.length > 2) {
        console.log("this.main_Table_Data", [...this.main_Table_Data])
        let name = [...this.main_Table_Data]
        this.Examineritems = name.map((n) => {
          let firstName =
            n.operationData[0].serviceProviderDetails.employeeName +
            "-" +
            n.operationData[0].spAddress[0].spCity +
            "-" +
            n.operationData[0].panNumber
          n.serviceProviderDetail = firstName
          return n
        })
        // this.Examineritems = this.main_Table_Data
      } else {
        this.Examineritems = []
      }
    },

    getBank(value) {
      //   if (value) {
      //   if (value.length > 2) {
      //     let requestData = "&pageNo=1"+"&perPage=50&"+"&bankName="+value;
      //     this.GET(
      //       "SERVICE_PROVIDER_MASTER_SEARCH",
      //       requestData,
      //       (res) => {
      //         this.serviceProviderbankData = [];
      //         if (res.data.data && res.data.data.data) {
      //           this.serviceProviderbankData = res.data.data.data;
      //         }
      //       },
      //       (error) => {
      //         this.serviceProviderbankData = [];
      //       },
      //     );
      //   }
      // }

      if (value.length > 2) {
        this.serviceProviderbankData = this.main_Table_Data
      } else {
        this.serviceProviderbankData = []
      }
    },

    getPanNumber(value) {
      // if (value) {
      //   if (value.length > 2) {
      //     let requestData = "&pageNo=1"+"&perPage=50&"+"&panNumber="+value+"&approveStatus=Pending for approval&modelName=serviceProvider";
      //     this.GET(
      //       "searchFrequentApprove",
      //       requestData,
      //       (res) => {
      //         this.serviceProviderpanData = [];
      //         if (res.data.data && res.data.data.data) {
      //           this.serviceProviderpanData = res.data.data.data;
      //         }
      //       },
      //       (error) => {
      //         this.serviceProviderpanData = [];
      //       },
      //     );
      //   }
      // }
      if (value.length > 2) {
        this.serviceProviderpanData = this.main_Table_Data
        console.log("12123",this.main_Table_Data)
      } else {
        this.serviceProviderpanData = []
      }
    },
    // panNumberRule(v) {
    //     if (v && !this.panNumberPattern.test(v)) {
    //       return "Enter valid PAN number";
    //     } else {
    //       return true;
    //     }
    //   },

    getGstNumber(value) {
      // if (value) {
      //   if (value.length > 2) {
      //     let requestData = "&pageNo=1"+"&perPage=50&"+"&gstinNumber="+value;
      //     this.GET(
      //       "SERVICE_PROVIDER_MASTER_SEARCH",
      //       requestData,
      //       (res) => {
      //         this.serviceProvidergstData = [];
      //         if (res.data.data && res.data.data.data) {
      //           this.serviceProvidergstData = res.data.data.data;
      //         }
      //       },
      //       (error) => {
      //         this.serviceProvidergstData = [];
      //       },
      //     );
      //   }
      // }
      if (value.length > 2) {
        this.serviceProvidergstData = this.main_Table_Data
      } else {
        this.serviceProvidergstData = []
      }
    },

    getCity(value) {
      // if (value) {
      //   if (value.length > 2) {
      //     let requestData = "&pageNo=1"+"&perPage=50&"+"&spCity="+value;
      //     this.GET(
      //       "SERVICE_PROVIDER_MASTER_SEARCH",
      //       requestData,
      //       (res) => {
      //         this.serviceProvidercityData = [];
      //         if (res.data.data && res.data.data.data) {
      //           this.serviceProvidercityData = res.data.data.data;
      //         }
      //       },
      //       (error) => {
      //         this.serviceProvidercityData = [];
      //       },
      //     );
      //   }
      // }
      if (value.length > 2) {
        console.log("this.main_Table_Data.spAddress", this.main_Table_Data)
        this.serviceProvidercityData = this.main_Table_Data
      } else {
        this.serviceProvidercityData = []
      }
    },
    setSmartSearchValue(data,field){
      if(field=="panNumber")  this.serviceProviderpanData = data
      else if(field=='spCity') this.serviceProvidercityData = data
      else if(field=='serviceProviderName'){
        this.Examineritems = data.map((n) => {
            let firstName = n.serviceProviderDetails.employeeName +"-" + n.spAddress[0].spCity + "-" + n.panNumber
            n.serviceProviderDetail = firstName 
            return n
          })
      } 
      else if(field=='spPincode') this.serviceProviderpincodeData = data
      else if(field=='gstinNumber') this.serviceProvidergstData = data
      else if(field=='bankName') this.serviceProviderbankData = data
      else this.serviceProviderIdData = data
    },

    smartSearch(field,value) {
      console.log("get Pinc",value)
      let reqData =
        "page=1&pageLimit=10&approveStatus=Pending for approval&modelName=serviceProvider"+ `&${field}=${value}`
      if (value) {
        if (value.length > 2) {
          this.GETM(
            "searchFrequentApprove",
            reqData,
            (res) => {
              this.serviceProviderpincodeData = [];
              if (res?.data?.data && res?.data?.data?.data) {
                let result=res.data.data.data
                console.log("skdjf",result.map(ele=>ele.operationData[0]))
                let data = result.map(ele=>ele.operationData[0])
                this.setSmartSearchValue(data,field)
              }
            },
            (error) => {
              this.serviceProviderpincodeData = [];
            },true
          );
        }
      }
      // if (value.length > 2) {
      //   this.serviceProviderpincodeData = this.main_Table_Data
      // } else {
      //   this.serviceProviderpincodeData = []
      // }
    },

    dateFormat(date) {
      return date ? moment(date).format("DD-MM-YYYY") : ""
    },
    editmaster(link, index, item, id, name, a) {
      if (index == 0) {
        this.$router.push({
          name: "CreateServiceProviderApprove",
          params: {
            isView: true,
            item: item,
            id:item.objId
          },
        })
        // console.log("ITEM_ID", record)
      }
      //  if(index == 1){
      //   this.selectedItem = [...record];
      //   this.approve_Dialog = true;
      //  }
      //  if(index == 2){
      //   this.selectedItem = [...record];
      //   this.reject_Dialog = true;
      //  }
      if (index == 1) {
        this.OpenAuditDialog = true
        this.auditTrailId = item.serviceProviderId
        // this.audit_dialog=true
        // this.getAuditData(1,item.serviceProviderId,false)
      }
      //  console.log(a,name,id,link,item)
    },
    getAuditData(newPageNum, id, bool) {
      let reqData = ""
      if (bool) {
        reqData =
          "perPage=10" +
          "&pageNo=" +
          newPageNum +
          "&masterType=serviceProvider" +
          "&masterId=" +
          this.audit_Data[0].masterId
      } else {
        reqData =
          "perPage=10" +
          "&pageNo=" +
          newPageNum +
          "&masterType=serviceProvider" +
          "&masterId=" +
          id
      }
      //"&masterId=" + this.auditTrailItem.uid +
      this.GETM(
        "getAudits",
        reqData,
        (res) => {
          this.audit_Data = res.data.data.data
          this.AuditTotalListCount = res.data.data.total
          this.AuditPageCount = res.data.data.total_pages
          this.AuditPageStartIndex =
            newPageNum == 1 ? 1 : (newPageNum - 1) * this.AuditItemsPerPage + 1
        },
        () => {},
        true
      )
    },
    formatDateTime(dateTime) {
      return dateTime ? moment(dateTime).format("DD-MM-YYYY LT") : ""
    },
    seeModify(item) {
      console.log(item.modification)
      this.seeModifyDialog = true
      this.modifyItems = item.modification
      //  .map((m, index) => {
      //    let object  = { ...m, srNo: index + 1 };
      //    if(this.dateFields.includes(m.fieldName)){
      //      object.newValue = this.formatDateOnly(m.newValue)
      //      object.oldValue = this.formatDateOnly(m.oldValue)
      //    }
      //    return object;
      //  });
    },
    formatDate(date) {
      if (!date) return ""

      const [year, month, day] = date.split("-")
      return `${day}/${month}/${year}`
    },
    searchServiceProviderData(num) {
      // let reqData=""
      // if(this.panNumber.length>0||this.spCity.length>0||this.serviceProviderName.length>0||this.spPincode.length>0||
      // this.serviceProviderType.length>0||this.lob.length>0||this.active.length>0
      // ||this.isExternalServiceProvider.length>0||this.gstinNumber.length>0||this.bankName.length>0||
      // this.mobileNumber.length>0||this.licenseExpiryDate.length>0||this.serviceProviderCode.length>0){
      //     reqData=`panNumber=${this.panNumber}&spCity=${this.spCity}
      // &serviceProviderName=${this.serviceProviderName}&spPincode=${this.spPincode}
      // &serviceProviderType=${this.serviceProviderType}&lob=${this.lob}&active=${this.active}
      // &isExternalServiceProvider=${this.isExternalServiceProvider}&gstinNumber=${this.gstinNumber}
      // &bankName=${this.bankName}&mobileNumber=${this.mobileNumber}&licenseExpiryDate=${this.licenseExpiryDate}
      // &serviceProviderCode=${this.serviceProviderCode}&pageNo=${num}&perPage=${this.itemsPerPage_Provider}
      // &approveStatus=Pending for approval&modelName=serviceProvider`
      // this.showSearchChip=true
      // }else{
      //     reqData=`pageNo=${num}&perPage=${this.itemsPerPage_Provider}&approveStatus=Pending for approval&modelName=serviceProvider`
      //     this.showSearchChip=false
      // }

      let reqData =
        "pageNo=" +
        num +
        "&perPage=" +
        this.itemsPerPage_Provider +
        " &approveStatus=Pending for approval&modelName=serviceProvider"

      if (
        this.panNumber ||
        this.spCity ||
        this.serviceProviderName ||
        this.spPincode ||
        this.approveStatus ||
        this.serviceProviderType ||
        this.lob ||
        this.active ||
        this.isExternalServiceProvider ||
        this.gstinNumber ||
        this.bankName ||
        this.mobileNumber ||
        this.licenseExpiryDate ||
        this.serviceProviderCode
      ) {
        if (this.panNumber) {
          reqData = reqData + "&panNumber=" + this.panNumber
        }
        if (this.spCity) {
          reqData = reqData + "&spCity=" + this.spCity
        }
        if (this.serviceProviderName) {
          reqData = reqData + "&serviceProviderName=" + this.serviceProviderName
        }
        if (this.spPincode) {
          reqData = reqData + "&spPincode=" + this.spPincode
        }
        if (this.approveStatus) {
          reqData = reqData + "&approveStatus=" + this.approveStatus
        }
        if (this.serviceProviderType) {
          reqData = reqData + "&serviceProviderType=" + this.serviceProviderType
        }
        if (this.lob) {
          reqData = reqData + "&lob=" + this.lob
        }
        if (this.active) {
          reqData = reqData + "&active=" + this.active
        }
        if (this.isExternalServiceProvider) {
          reqData =
            reqData +
            "&isExternalServiceProvider=" +
            this.isExternalServiceProvider
        }
        if (this.gstinNumber) {
          reqData = reqData + "&gstinNumber=" + this.gstinNumber
        }
        if (this.bankName) {
          reqData = reqData + "&bankName=" + this.bankName
        }
        if (this.mobileNumber) {
          reqData = reqData + "&mobileNumber=" + this.mobileNumber
        }
        if (this.licenseExpiryDate) {
          reqData = reqData + "&licenseExpiryDate=" + this.licenseExpiryDate
        }
        if (this.serviceProviderCode) {
          reqData = reqData + "&serviceProviderCode=" + this.serviceProviderCode
        }
        this.showSearchChip = true
      } else {
        let reqData =
          "pageNo=" +
          num +
          "&perPage=" +
          this.itemsPerPage_Provider +
          " &approveStatus=Pending for approval&modelName=serviceProvider"
        this.showSearchChip = false
      }

      this.GETM(
        "searchFrequentApprove",
        reqData,
        (res) => {
          if (res.data?.statusCode === 200) {
            this.editMaster_Data = res.data.data.data
            this.main_Table_Data = res.data.data.data.map((item) => ({
              ...item.operationData[0],
              objId:item._id,
              createdAt: this.formatDateTime(item.createdAt),
              //  initiatedBy:item.initiatedBy
            }))
            this.count = res.data.data.total
            this.totalListCount_Provider = res.data.data.total
            this.pageStartIndex_Provider =
              num == 1 ? 1 : (num - 1) * this.itemsPerPage_Provider + 1
          } else {
            this.main_Table_Data = []
            this.pageStartIndex_Provider = 0
            this.count = 0
            this.totalListCount_Provider = 0
          }
        },
        (error) => {
          return error
          this.pageStartIndex_Provider = 0
        },
        true
      )
      this.search_Dialog = false
    },
    clear() {
      this.panNumber = ""
      this.spCity = ""
      this.serviceProviderName = ""
      this.spPincode = ""

      this.serviceProviderType = ""
      this.lob = ""
      this.active = ""
      this.isExternalServiceProvider = ""
      this.gstinNumber = ""
      this.bankName = ""
      this.mobileNumber = ""
      this.licenseExpiryDate = ""
      this.serviceProviderCode = ""
      const a = new Date().toISOString().split("T")[0]
      this.pickerDate = a
    },
    getServiceProviderIdData() {
      let reqData = `pageNo=1&perPage=100&approveStatus=Pending for approval&modelName=serviceProvider`
      this.GETM(
        "searchFrequentApprove",
        reqData,
        (res) => {
          this.allTableData = res.data.data.data
        },
        (error) => {
          return error
        },
        true
      )
    },
    onSubmit() {
      if (!this.$refs.approvalForm.validate()) {
        return this.showAlert("Please fill mandatory fields.")
      } else {
        if (this.selectedItem[0]?._id) {
          let sendData = {
            approveStatus: this.reject_Dialog ? "Rejected" : "Approved",
            rejectionReason: this.reason,
          }
          this.PUTM(
            "updateMasterApprove",
            this.selectedItem[0]._id,
            sendData,
            (res) => {
              this.hideProgress()
              this.approve_Dialog = false
              this.reject_Dialog = false
              if (res.data && res.data.statusCode == 200) {
                this.showAlert(res?.data?.msg || "Success", "", "", () => {
                  this.searchServiceProviderData(1)
                })
                // this.isView=true
              } else {
                this.showAlert(res.data.msg)
                this.hideProgress()
              }
            },
            (err) => {
              console.log(err)
              this.hideProgress()
              let msg =
                err?.response?.data?.msg ||
                "Unable to submit Service Provider Approval."
              this.showAlert(msg)
            },
            true
          )
        }
      }
    },
  },
  computed: {
    pageEndIndex() {
      if (!this.audit_Data.length) {
        return 0
      } else {
        return this.pageStartIndex + this.itemsPerPage <= this.totalListCount
          ? this.pageStartIndex + this.itemsPerPage - 1
          : this.totalListCount
      }
    },
    computedlicenseExpiryDate() {
      return this.formatDate(this.licenseExpiryDate)
    },
    computedPAN() {
      this.panNumber = this.panNumber ? this.panNumber.toUpperCase() : ""
      return this.panNumber
    },
    getEndDate() {
      var endDate = new Date()
      return endDate.toISOString().slice(0, 10)
    },
    pageCount_Provider() {
      if (!this.totalListCount_Provider) {
        return 0
      } else {
        return Math.ceil(
          this.totalListCount_Provider / this.itemsPerPage_Provider
        )
      }
    },
    pageEndIndex_Provider() {
      if (!this.main_Table_Data.length) {
        return 0
      } else {
        return this.pageStartIndex_Provider + this.itemsPerPage_Provider <=
          this.totalListCount_Provider
          ? this.pageStartIndex_Provider + this.itemsPerPage_Provider - 1
          : this.totalListCount_Provider
      }
    },
    AuditPageEndIndex() {
      if (!this.audit_Data.length) {
        return 0
      } else {
        return this.AuditPageStartIndex + this.AuditItemsPerPage <=
          this.AuditTotalListCount
          ? this.AuditPageStartIndex + this.AuditItemsPerPage - 1
          : this.AuditTotalListCount
      }
    },
  },
  created() {
    this.$store.commit(
      "SAVE_PAGE_HEADER",
      "Service Provider Master Approval Search"
    )
    let num = 1
    let reqData = `pageNo=1&perPage=10&approveStatus=Pending for approval&modelName=serviceProvider`
    this.GETM(
      "searchFrequentApprove",
      reqData,
      (res) => {
        let result=res?.data?.data?.data
        this.editMaster_Data = result
        this.main_Table_Data = result.map((item) => ({
          ...item.operationData[0],
          objId:item._id,
          createdAt: this.formatDateTime(item.createdAt),
          // initiatedBy:item.initiatedBy,
        }))
        this.count = res.data.data.total
        this.count = res.data.data.total
        this.totalListCount_Provider = res.data.data.total
        this.pageStartIndex_Provider =
          num == 1 ? 1 : (num - 1) * this.itemsPerPage_Provider + 1
      },
      (error) => {
        return error
      },
      true
    )

    this.getServiceProviderIdData()
  },
}
</script>

<style scoped>
.create-roe-resetbtn {
  color: #d1121b;
  border: 1px solid #d1121b !important;
}
.btnToggle {
  text-transform: none;
  color: #fff;
  background-color: #1ebbd9;
}
.form-groupss {
  max-width: 200px;
  margin: 0px 10px 0px 0px;
}
.reset {
  background-color: white;
  border: 1px solid #d1121b;
  color: #d1121b;
}
.createbtn {
  text-transform: capitalize;
  letter-spacing: 0.4px;
  /* font-weight: 300;
      font-size: 13px !important; */
}
.cancelbtn {
  background-color: white;
  border: 1px solid #d1121b;
  color: #d1121b;
}
.tableicons {
  color: #23b1a9 !important;
}
.tablebtn {
  background: #ffffff 0% 0% no-repeat padding-box !important;
  box-shadow: inset 0px 2px 4px #00000052 !important;
  border: 0.5px solid #c1c8cc;
  border-radius: 18px !important;
  height: 30px !important;
  text-transform: capitalize !important;
  width: 100%;
  justify-content: space-between;
  font-size: small;
}
</style>
