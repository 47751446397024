<template>
  <v-app>
    <v-form v-model="claimRegistration" ref="claimRegistration">
      <v-card tile class="px-3 pt-3">
        <v-radio-group
          v-model="claimType"
          mandatory
          row
          dense
          hide-details
          class="ma-0 pa-0 py-3"
          @change="slctClmTyp"
        >
          <div style="background-color: #F7F7F7; width:263px" class="mr-4 pa-2">
            <v-radio
              value="reimbursementClaim"
              color="#23B1A9"
              class="ma-0 pa-0 pl-1"
            >
              <template v-slot:label>
                <div
                  class="font-weight-bold"
                  style="color:#10242B;font-size:12px"
                >
                  Reimbursement Claim
                </div>
              </template>
            </v-radio>
          </div>
          <div
            style="background-color: #F7F7F7; width:263px"
            class="pa-2 pr-14"
          >
            <v-radio
              value="cashlessClaim"
              color="#23B1A9"
              class="ma-0 pa-0 pl-1"
            >
              <template v-slot:label>
                <div
                  class="font-weight-bold"
                  style="color:#10242B;font-size:12px"
                >
                  Cashless Claim
                </div>
              </template>
            </v-radio>
          </div>
        </v-radio-group>
        <v-layout v-if="shwCashlessClaim">
          <v-flex xs3 class="">
            <v-layout column class="cust_pln_cr my-2 mr-2">
              <v-text class="font-weight-bold cstm-clr-pln-cr">
                Pre-Auth Number
              </v-text>
              <v-autocomplete
                v-model="preAuthNumber"
                :items="searchedItems"
                :loading="isLoading"
                :search-input.sync="searchCity"
                color="white"
                cache-items
                label="Search ..."
                class="rounded-0 delpad_CR"
                return-object
                solo
              >
                <div
                  slot="append"
                  style="background-color:#F7F7F7;padding:9px !important;cursor:pointer;"
                >
                  <v-icon>search</v-icon>
                </div>
              </v-autocomplete>
            </v-layout>
          </v-flex>
          <v-flex xs4>
            <v-layout column class="mx-2 cust_pln_cr" justify-center>
              <span
                class="font-weight-bold cstm-clr-pln-cr"
                style="color:#FFFFFF"
              >
              </span>
              <div style="" class="pa-2 mt-7 elevation-1">
                <v-checkbox
                  class="ma-0 pa-0"
                  v-model="admissionDate"
                  hide-details
                  color="#23B1A9"
                >
                  <span
                    slot="label"
                    class="font-weight-bold"
                    style="color:#10242B;font-size:12px"
                    >Is Date of Admission beyond authorized date?</span
                  >
                </v-checkbox>
              </div>
            </v-layout>
          </v-flex>
          <v-flex xs2></v-flex>
          <v-flex xs2></v-flex>
          <v-flex xs3></v-flex>
        </v-layout>
      </v-card>
      <v-card tile style="max-height:150px" class="mx-3 mt-4 rounded-0">
        <div
          class="mx-2 pa-2"
          style="background-color:#eee; display:flex;justify-content:space-between;margin-left:0px!important;margin-right:0px!important;"
        >
          <v-layout style="flex:1" justify-end>
            <!-- <v-btn dark   tile color="#E46A25" class="text-capitalize px-3 mx-2 my-2" small><v-icon dark small>search</v-icon> <v-divider vertical class="mx-2"></v-divider>Search</v-btn> -->
            <v-btn
              dark
              tile
              color="#E46A25"
              class="text-capitalize px-3 mx-2 my-2"
              small
              ><v-icon dark small>cancel</v-icon>
              <v-divider vertical class="mx-2"></v-divider>Cancel</v-btn
            >
            <v-btn
              dark
              tile
              color="#152F38"
              @click="claimNext"
              class="text-capitalize px-3 mx-2 my-2"
              small
              ><v-icon dark small>arrow_forward</v-icon>
              <v-divider vertical class="mx-2"></v-divider> Next</v-btn
            >
          </v-layout>
        </div>
      </v-card>
    </v-form>
  </v-app>
</template>
<script>
export default {
  created() {
    this.$store.commit("SAVE_PAGE_HEADER", "Claim Registration");
  },
  data() {
    return {
      claimRegistration: false,
      claimType: null,
      preAuthNumber: "",
      isLoading: false,
      admissionDate: false,
      searchCity: null,
      shwCashlessClaim: false,
      searchEntries: [],
    };
  },
  mounted(){
    this.$store.state.claimMembersearchdata = {};
    // this.$store.state('VALIDATE_MEMBER_SEARCH',false);
    this.$store.state.submittedClaimId = '';
    this.$store.state.registeredClaimId = '';
    this.$store.state.didMembersearch= false;
  },
  watch :{
     searchCity(val) {
            // if (this.searchedItems.length > 0) return
            // Items have already been requested
            // if (this.isLoading) return
            // this.isLoading = true

            if(val == null) return
            let regxr = /^\d+\.\d{0,1}$/
            
            let valstring = val.toString()
        //    console.log('test for decimal', valstring.test(/[^\w\s]/gi))


             if(regxr.test(valstring)){
                 this.showToast('Decimal is not allowed, Only numbers are allowed',this.TOST().WARNING);
                 return
             }

            // if(this.spclCharNot(val) != 'valid'){
            //       this.showToast('Special Characters are not allowed',this.TOST().WARNING);
            //         console.log("specual chaeacters found", val)
            //     return
            // }

            if(val.length<3){
                return this.cityTown=''
            }

            let provider = [];
            let self=this;   
            let apiURL = `claims-details/search-pre-auth/${val}`; 
            this.GET(8, apiURL, (res, error) => {
            if (!error) {
              console.log(" pre auth response", res.data.data.data);
              let result = []
              for (let i of res.data.data.data) {
                result.push(i.preAuth);
              }
              self.searchEntries = result;
             
            } else {
              console.log("POst APi error response|->create-user", error);
              this.showToast(error.response.data.msg, this.TOST().ERROR);
            }
          });

            this.isLoading = false;

        },

  },
   computed: {
        searchedItems () {
            return this.searchEntries;
        },
   },
  methods: {
    slctClmTyp() {
      console.log(this.claimType);
      if (this.claimType === "cashlessClaim") {
        this.shwCashlessClaim = true;
      } else {
        this.shwCashlessClaim = false;
        this.preAuthNumber = "";
        this.admissionDate = false;
      }
    },
    claimNext() {
      console.log("Clicked", this.claimType);
      if (this.claimType === "cashlessClaim") {
        if(!this.preAuthNumber){
          this.showToast('Enter valid pre-auth ID', this.TOST().ERROR);
        }else{
          this.$store.commit("SAVE_SUBMITTED_CLAIM_TYPE", "Cashless");
        let formData = {
          reimbursementClaim: "No",
          cashlessClaim: "Yes",
           // using dummy preauth number
          preAuthNumber: "4200000000",
        };
        // saving selected claim type
        let apiURL = "claims-details/create-claim-registration";
        this.POST(8, apiURL, formData, (res, error) => {
          if (!error) {
            console.log("POST APi response|-> create-user ", res);
            this.$store.commit('SAVE_REGISTERED_CLAIM_ID',res.data.data._id);
            this.showToast(res.data.msg, this.TOST().SUCCESS);
            this.$router.push({
              name: "ClaimsMainScreen",
              params: { type: "cashlessClaim",claimdata: res },
            });
            console.log("POST APi response|-> Generate object id ");
          } else {
            console.log("POst APi error response|->create-user", error);
            this.showToast(error.response.data.msg, this.TOST().ERROR);
          }
        });
        }
        
      } else if (this.claimType === "reimbursementClaim") {
        this.$store.commit("SAVE_SUBMITTED_CLAIM_TYPE", "Reimbursement");
        let formData = {
          reimbursementClaim: "Yes",
          cashlessClaim: "No",
          // using dummy preauth number
          preAuthNumber: "4200000000",
        };
        let apiURL = "claims-details/create-claim-registration";
        this.POST(8, apiURL, formData, (res, error) => {
          if (!error) {
            console.log("POST APi response|-> create-user ID ", res.data.data._id);
            this.$store.commit('SAVE_REGISTERED_CLAIM_ID',res.data.data._id);
            this.showToast(res.data.msg, this.TOST().SUCCESS);
            this.$router.push({
              name: "ClaimsMainScreen",
              params: { type: "reimbursementClaim",claimdata: res },
            });
            console.log("POST APi response|-> Generate object id ");
          } else {
            console.log("POst APi error response|->create-user", error);
            this.showToast(error.response.data.msg, this.TOST().ERROR);
          }
        });
      }
    },
  }
};
</script>
<style>
.delpad_CR .v-input__slot {
  padding-right: 0px !important;
}

.cust_pln_cr .v-text-field .v-input__slot,
.v-text-field--outline .v-input__slot {
  min-height: 41px !important;
  display: flex !important;
  align-items: center !important;
  /* border:1px red solid; */
}

.cust_pln_cr .v-input .v-label {
  font-size: 13px !important;
}
.cust_pln_cr .v-input {
  font-size: 13px !important;
}
.cust_pln_cr .v-input input {
  font-size: 13px !important;
}

.cstm-clr-pln-cr {
  color: #646666 !important;
  margin-bottom: 5px !important;
  font-size: 13px !important;
}
</style>
