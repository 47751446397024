<template>
    <v-form ref="openFileForm" @submit.prevent="openFileForm">
        <v-flex class="mt-5 ml-5 mr-5">
            <v-flex >
                <v-flex class="ml-5 mr-8" :class="openFile_Menu ? 'mb-3' : 'mb-5'">
                    <v-row class="groupRowHeadNewLable mt-0">
                        <v-col align="left" class="mt-0 pt-2">
                            <v-flex class="ml-0 GroupNameNewLable">Open File Details</v-flex>
                        </v-col>
                        <v-col align="right" class="pt-1 mt-0">
                            <v-icon class="mr-2 viconfont" dark @click="openFile_Menu = !openFile_Menu">
                                {{ openFile_Menu == true ? "mdi-minus-box" : "mdi-plus-box" }}
                            </v-icon>
                        </v-col>
                    </v-row>
                </v-flex>

               <v-layout wrap class="form-group-container borderStyle pa-3 mb-3" v-if="openFile_Menu">
                <v-flex class="form-groups">
                  <label class="lableFontSize">
                    Claim Number 
                  </label>
                  <v-text-field
                    label="Enter"
                    solo
                    dense    
                    disabled   
                    v-model="viewOpenFileObj.claimNumber"            
                    class="forminput"
                     maxlength="50"
                     ></v-text-field>
                </v-flex>
                <v-flex class="form-groups">
                  <label class="lableFontSize">
                    Feature Number 
                  </label>
                  <v-text-field
                    label="Enter"
                    solo
                    dense    
                    disabled 
                    v-model="viewOpenFileObj.featureNumber"              
                    class="forminput"
                     maxlength="50"
                     ></v-text-field>
                </v-flex>
                <v-flex class="form-groups">
                  <label class="lableFontSize">
                    Claimant Name 
                  </label>
                  <v-text-field
                    label="Enter"
                    solo
                    dense 
                    disabled 
                    v-model="viewOpenFileObj.claimantName"                 
                    class="forminput"
                     maxlength="50"
                     ></v-text-field>
                </v-flex>
                <v-flex class="form-groups">
                  <label class="lableFontSize">
                    Insured Name 
                  </label>
                  <v-text-field
                    label="Enter"
                    solo
                    dense 
                    v-model="viewOpenFileObj.insuredName" 
                    disabled                 
                    class="forminput"
                     maxlength="50"
                     ></v-text-field>
                </v-flex>
                <v-flex class="form-groups">
                  <label class="lableFontSize">
                    Claimed Amount 
                  </label>
                  <v-text-field
                    label="Enter"
                    solo
                    dense  
                    v-model="viewOpenFileObj.claimedAmount" 
                    disabled                
                    class="forminput"
                     maxlength="50"
                     ></v-text-field>
                </v-flex>
                
                <v-layout>
                    <v-flex class="form-groups">
                  <label class="lableFontSize">
                    Product Name 
                  </label>
                  <v-text-field
                    label="Enter"
                    solo
                    dense 
                    v-model="viewOpenFileObj.productName"
                    disabled                  
                    class="forminput"
                     maxlength="50"
                     ></v-text-field>
                </v-flex>
                <v-flex class="form-groups">
                  <label class="lableFontSize">
                    Examiner Name 
                  </label>
                  <v-text-field
                    label="Enter"
                    solo
                    dense  
                    disabled 
                    v-model="viewOpenFileObj.examiner.employeeName"                
                    class="forminput"
                     maxlength="50"
                     ></v-text-field>
                </v-flex>
                <v-flex class="form-groups">
                  <label class="lableFontSize">
                    Date Of Loss 
                  </label>
                  <v-text-field
                    label="Enter"
                    solo
                    dense  
                    disabled  
                    v-model="viewOpenFileObj.dateOfLoss"               
                    class="forminput"
                     maxlength="50"
                     ></v-text-field>
                </v-flex>
                <v-flex class="form-groups">
                  <label class="lableFontSize">
                    Date Reported 
                  </label>
                  <v-text-field
                    label="Enter"
                    solo
                    dense  
                    disabled    
                    v-model="viewOpenFileObj.dateReported"             
                    class="forminput"
                     maxlength="50"
                     ></v-text-field>
                </v-flex>
                <v-flex class="form-groups">
                  <label class="lableFontSize">
                    Date Created 
                  </label>
                  <v-text-field
                    label="Enter"
                    solo
                    dense 
                    disabled  
                    v-model="viewOpenFileObj.dateCreated"                
                    class="forminput"
                     maxlength="50"
                     ></v-text-field>
                </v-flex>
                
               </v-layout>
               <v-layout>
                <v-flex class="form-groups">
                  <label class="lableFontSize">
                    Claim Type 
                  </label>
                  <v-text-field
                    label="Enter"
                    solo
                    dense   
                    disabled  
                    v-model="viewOpenFileObj.claimType"              
                    class="forminput"
                     maxlength="50"
                     ></v-text-field>
                </v-flex>
                <v-flex class="form-groups">
                  <label class="lableFontSize">
                    Coverage Name 
                  </label>
                  <v-text-field
                    label="Enter"
                    solo
                    dense 
                    disabled 
                    v-model="viewOpenFileObj.coverage.coverName"                 
                    class="forminput"
                     maxlength="50"
                     ></v-text-field>
                </v-flex>
                <!-- <v-flex class="form-groups">
                  <label class="lableFontSize">
                    CP Claim 
                  </label>
                  <v-text-field
                    label="Enter"
                    solo
                    dense 
                    disabled                  
                    class="forminput"
                     maxlength="50"
                     ></v-text-field>
                </v-flex> -->
                <v-flex class="form-groups">
                  <label class="lableFontSize">
                    Red Flag Score 
                  </label>
                  <v-text-field
                    label="Enter"
                    solo
                    dense  
                    disabled 
                    v-model="redFlagScore"                
                    class="forminput"
                     maxlength="50"
                     ></v-text-field>
                </v-flex>
                <v-flex class="form-groups">
                  <label class="lableFontSize">
                    Litigation Type 
                  </label>
                  <v-text-field
                    label="Enter"
                    solo
                    dense 
                    disabled  
                    v-model="viewOpenFileObj.litigationType"               
                    class="forminput"
                     maxlength="50"
                     ></v-text-field>
                </v-flex>
                <v-flex class="form-groups">
                  <label class="lableFontSize">
                    OFR Status
                  </label>
                  <v-text-field
                    label="Enter"
                    solo
                    dense   
                    v-model="viewOpenFileObj.ofrStatus"                
                    class="forminput"
                     maxlength="50"
                     disabled
                     ></v-text-field>
                </v-flex>
               </v-layout>
               <v-layout>
                <v-flex class="form-groups">
                  <label class="lableFontSize">
                    Review By
                  </label>
                  <v-text-field
                    label="Enter"
                    solo
                    dense  
                    v-model="viewOpenFileObj.userId"                 
                    class="forminput"
                     maxlength="50"
                     disabled
                     ></v-text-field>
                </v-flex>
                <v-flex class="form-groups">
                  <label class="lableFontSize">
                    Reviewed Date
                  </label>
                  <v-text-field
                    label="Enter"
                    solo
                    dense  
                    v-model="viewOpenFileObj.Date"                 
                    class="forminput"
                     maxlength="50"
                     disabled
                     ></v-text-field>
                </v-flex>
                <v-flex class="form-groups">
                  <label class="lableFontSize">
                    Policy Number
                  </label>
                  <v-text-field
                    label="Enter"
                    solo
                    dense  
                    v-model="viewOpenFileObj.policyNumber"                 
                    class="forminput"
                     maxlength="50"
                     disabled
                     ></v-text-field>
                </v-flex>
                <v-flex class="form-groups">
                  <label class="lableFontSize">
                    Certificate Number
                  </label>
                  <v-text-field
                    label="Enter"
                    solo
                    dense  
                    v-model="viewOpenFileObj.certificateNumber"                 
                    class="forminput"
                     maxlength="50"
                     disabled
                     ></v-text-field>
                </v-flex>
                <v-flex class="form-groups">
                  <label class="lableFontSize">
                    Claim status
                  </label>
                  <v-text-field
                    label="Enter"
                    solo
                    dense  
                    v-model="viewOpenFileObj.ofrStatus"                 
                    class="forminput"
                     maxlength="50"
                     disabled
                     ></v-text-field>
                </v-flex>
                </v-layout>
                <v-layout>
                <v-flex class="form-groups">
                  <label class="lableFontSize">
                    Total Indemnity Paid
                  </label>
                  <v-text-field
                    label="Enter"
                    solo
                    dense  
                    v-model="viewOpenFileObj.claimedAmount"                 
                    class="forminput"
                     maxlength="50"
                     disabled
                     ></v-text-field>
                </v-flex>
                <v-flex class="form-groups">
                  <label class="lableFontSize">
                    Total Expense Paid
                  </label>
                  <v-text-field
                    label="Enter"
                    solo
                    dense  
                    v-model="viewOpenFileObj.expensePaid"                 
                    class="forminput"
                     maxlength="50"
                     disabled
                     ></v-text-field>
                </v-flex>
                <v-flex class="form-groups">
                  <label class="lableFontSize">
                    Reserve Amount
                  </label>
                  <v-text-field
                    label="Enter"
                    solo
                    dense  
                    v-model="viewOpenFileObj.reserveAmount"                 
                    class="forminput"
                     maxlength="50"
                     disabled
                     ></v-text-field>
                </v-flex>
                <v-flex class="form-groups">
                  <label class="lableFontSize">
                    Sum Insured
                  </label>
                  <v-text-field
                    label="Enter"
                    solo
                    dense  
                    v-model="viewOpenFileObj.sumInsured"                 
                    class="forminput"
                     maxlength="50"
                     disabled
                     ></v-text-field>
                </v-flex>
                <v-flex class="form-groups">
                    <label class="lableFontSize">
                        Assign To
                    </label>
                    <v-text-field label="Enter" solo dense v-model="viewOpenFileObj.assignTo && viewOpenFileObj.assignTo.employeeName"
                        :readonly="true" @click="examinerDialog = true" class="forminput" maxlength="50"
                        :disabled="viewOpenFileObj.ofrStatus!=='Open'"></v-text-field>
                </v-flex>
               </v-layout>
               </v-layout>
               

                <v-flex class="ml-5 mr-8" :class="adhrencemenu ? 'mb-3' : 'mb-5'">
                    <v-row class="groupRowHeadNewLable mt-0">
                        <v-col align="left" class="mt-0 pt-2">
                            <v-flex class="ml-0 GroupNameNewLable">Adherence to TAT</v-flex>
                        </v-col>
                        <v-col align="right" class="pt-1 mt-0">
                            <v-icon class="mr-2 viconfont" dark @click="adhrencemenu = !adhrencemenu">
                                {{ adhrencemenu == true ? "mdi-minus-box" : "mdi-plus-box" }}
                            </v-icon>
                        </v-col>
                    </v-row>
                </v-flex>

                <v-flex v-if="adhrencemenu" class="pa-3 borderStyle">
                    <template>
                        <v-simple-table fixed-header class="vDataTableStyling mb-8">
                        <template >
                        <thead >                
                            <tr>                       
                                <th class="text-center" width="14%"> <span class="leftstyle">Parameters</span></th>
                                <th class="text-center" width="10%"><span class="leftstyle ml-4">Evaluation</span> </th>                            
                            </tr>
                        </thead>
                        <tbody>                       
                            <tr>
                            <td ><span class="leftstyle"> First contact ? <span class="mandatorycolor">*</span></span></td>
                                <td class="pr-1">
                                    <v-select 
                                        dense  
                                        solo 
                                        class="forminput reservefield selectstyle mt-1 ml-6"
                                        v-model="viewOpenFileObj.adherenceToTat.firstContact"
                                        :items = "firstContactArr"
                                        :placeholder="viewMode? '' : 'Enter'"
                                        :background-color="viewMode? '#f5f5f5' : ''"
                                        :class="viewMode? 'forminput textDisable font-weight-bold' : 'forminput'"
                                        :disabled="viewMode"
                                        :rules="rules.mandatoryrule">
                                    </v-select>
                                </td>                                                       
                            </tr>
                            <tr>
                            <td><span class="leftstyle">Follow up reminders ? <span class="mandatorycolor">*</span></span></td>
                                <td class="pr-1">
                                    <v-select  
                                        dense  
                                        solo 
                                        class="forminput reservefield selectstyle mt-1 ml-6"
                                        v-model="viewOpenFileObj.adherenceToTat.followUpReminders"
                                        :items="followUpRemindersArr"
                                        :placeholder="viewMode? '' : 'Enter'"
                                        :background-color="viewMode? '#f5f5f5' : ''"
                                        :class="viewMode? 'forminput textDisable font-weight-bold' : 'forminput'"
                                        :disabled="viewMode"
                                        :rules="rules.mandatoryrule">
                                    </v-select>
                                </td>                      
                            </tr>
                            <tr>
                            <td><span class="leftstyle">Reason for pendency ? <span class="mandatorycolor">*</span></span></td>
                                <td class="pr-1">
                                    <v-select 
                                        dense  
                                        solo 
                                        class="forminput reservefield selectstyle mt-1 ml-6"
                                        :items="reasonForArr"
                                        v-model="viewOpenFileObj.adherenceToTat.reasonForPendency"
                                        :placeholder="viewMode? '' : 'Enter'"
                                        :background-color="viewMode? '#f5f5f5' : ''"
                                        :class="viewMode? 'forminput textDisable font-weight-bold' : 'forminput'"
                                        :disabled="viewMode"
                                        :rules="rules.mandatoryrule">
                                    </v-select>
                                </td>
                        </tr>
                            <!-- <tr>
                            <td><span class="leftstyle">Reserve ?</span></td>
                                <td class="pr-1">
                                    <v-text-field  
                                        dense 
                                        solo 
                                        class="forminput reservefield selectstyle mt-1 ml-6"
                                        v-model="viewOpenFileObj.reserve"
                                        :placeholder="viewMode? '' : 'Enter'"
                                        :background-color="viewMode? '#f5f5f5' : ''"
                                        :class="viewMode? 'forminput textDisable font-weight-bold' : 'forminput'"
                                        :disabled="viewMode">
                                    </v-text-field>
                                </td>                      
                            </tr> -->
                            <tr>
                            <td><span class="leftstyle">Adequacy of reserve ? <span class="mandatorycolor">*</span></span></td>
                                <td class="pr-1">
                                    <v-select  
                                        dense 
                                        solo 
                                        class="forminput reservefield selectstyle mt-1 ml-6"
                                        v-model="viewOpenFileObj.adherenceToTat.adequacyOfReserve"
                                        :items="adequecyOfReserveArr"
                                        :placeholder="viewMode? '' : 'Enter'"
                                        :background-color="viewMode? '#f5f5f5' : ''"
                                        :class="viewMode? 'forminput textDisable font-weight-bold' : 'forminput'"
                                        :disabled="viewMode"
                                        :rules="rules.mandatoryrule">
                                    </v-select>
                                </td>                     
                            </tr>
                            <!-- <tr class="v-data-table__empty-wrapper" v-if="adherenceDetails.length === 0">
                                <td colspan="12">No Records For Adherence</td>
                            </tr> -->
                        </tbody>
                    </template>    
                        </v-simple-table>
                    </template>
                     <v-layout>
                        <v-flex>
                            <p class="ml-10 mb-1 claimlabel">Comments: <span class="mandatorycolor">*</span></p>
                            <v-textarea
                                v-model="viewOpenFileObj.adherenceToTat.comments"
                                color="black"
                                solo
                                dense
                                class="px-10 forminput"
                                rows="3"
                                :maxLength="500"
                                :placeholder="viewMode? '' : 'Enter'"
                                :background-color="viewMode? '#f5f5f5' : ''"
                                :class="viewMode? 'forminput textDisable font-weight-bold' : 'forminput'"
                                :disabled="viewMode"
                                :rules="rules.mandatoryrule">
                            </v-textarea>
                        </v-flex>
                    </v-layout>
    <!-- 
                    <div class="text-center graybackground pdct-stUp-bnkAcc-pgn mt-3" v-if="this.adherenceDetails.length !== 0">
                        <v-row>
                            <v-col cols="6"></v-col>
                            <v-col cols="6">
                                <v-row class="paginationrow">
                                    <v-spacer></v-spacer>
                                    <div style="" class="mt-4">
                                        <span class="orangetext">Showing {{ pageStartIndex }} - {{ pageEndIndex }}</span>
                                        <span> out of {{ totalListCount }} records </span>
                                    </div>
                                    <v-pagination
                                        class="mr-4 mt-1 navpagenumber"
                                        v-model="page"
                                        :length="getNoOfPages"
                                        :total-visible="5"
                                        color="#152F38"
                                        @input="NextPage"
                                    ></v-pagination>
                                </v-row>
                            </v-col>
                        </v-row>
                        <br />
                    </div> -->               
                </v-flex>
                <v-flex class="ml-5 mr-8" :class="triggerDetails ? 'mb-3' : 'mb-5'">
                    <v-row class="groupRowHeadNewLable mt-0">
                        <v-col align="left" class="mt-0 pt-2">
                            <v-flex class="ml-0 GroupNameNewLable">Trigger Details</v-flex>
                        </v-col>
                        <v-col align="right" class="pt-1 mt-0">
                            <v-icon class="mr-2 viconfont" dark @click="triggerDetails = !triggerDetails">
                                {{ triggerDetails == true ? "mdi-minus-box" : "mdi-plus-box" }}
                            </v-icon>
                        </v-col>
                    </v-row>
                </v-flex>
                <v-flex v-if="triggerDetails" class="pa-3 borderStyle">
                <v-data-table
                :items="viewOpenFileObj.triggerPoints"
                :headers="triggerDetail"
                hide-default-footer
                class="mx-5 mb-5 vDataTableStyling">
                    <template v-slot:[`item.date`]="{ item }">
                        <div>
                            {{ formatDateTime(item.date) }}
                        </div>
                    </template>
                </v-data-table>
                </v-flex>
          <!-- cancel and submit  code-->
            <v-row class="mt-9 mx-1">
                <v-col align="left" class="px-1">
                    <v-btn width="100" class="btnstyle ml-3 reset" v-if="this.viewOpenFileObj.ofrStatus === 'Open' || this.viewOpenFileObj.ofrStatus === false "  @click="clear()">
                        <v-icon class="mr-2 iconsize">mdi-backspace-outline</v-icon>
                        <div class="vertical_line"></div>Reset
                    </v-btn>
                    <v-btn  width="100" class="btnstyle ml-3 mr-3 reset" @click="cancel()">
                        <v-icon class="mr-2 iconsize">mdi-backspace-outline</v-icon>
                        <div class="vertical_line"></div>Cancel
                    </v-btn>
                </v-col>
                <v-col align="right">
                    <v-btn color="#23B1A9" width="100"  dark class="btnstyle mr-2" @click="submit(false)" :disabled="viewMode">
                        <v-icon class="mr-2 iconsize">mdi-content-save</v-icon>
                        <div class="vertical_line"></div>Save
                    </v-btn>
                    <v-btn color="#23B1A9" width="100"  dark class="btnstyle mr-2" @click="submit(true)" :disabled="viewMode">
                        <v-icon class="mr-2 iconsize">mdi-arrow-right-circle</v-icon>
                        <div class="vertical_line"></div>Submit
                    </v-btn>
                    
                </v-col>
            </v-row>
            </v-flex>
            
        </v-flex>  
        
    <v-row justify="center">
      <v-dialog v-model="examinerDialog" persistent max-width="40%" style="border-radius:0;max-height:80%">
        <v-card>
          <v-card-title class="pl-0 px-0 py-0 dailogboxAllvcardTableBackground vcardTextTableBackground">
            <v-row class="colorbg">
              <span class="descrptionSpan mb-2 ml-8">Re-assign To</span>
              <v-icon @click="examinerDialog = false" class="ml-4 mt-2" color="#fff">mdi-window-close</v-icon>
            </v-row>
            <v-card-text class="vcardTextTableBackground">
              <v-form ref="examinerassignref">
                <v-autocomplete  
                  label="Select" 
                  v-model="examiner" 
                  :search-input.sync="searchExaminer" 
                  :items="examiners" 
                  item-value="employeeName" 
                  item-text="employeeName" 
                  :rules="[(v) => requiredField(v)]" 
                  @change="changeExaminer"
                  dense 
                  solo
                  class="form-control" 
                  clearable
                ></v-autocomplete>
                <v-layout justify-end>
                  <v-btn small color="red" outlined @click="examinerDialog=false" class="mr-3">
                    <v-icon> mdi-close-circle </v-icon>
                    <v-divider class="mx-2" vertical></v-divider>
                    <span class="subheading">Close</span>
                  </v-btn>
                  <v-btn small color="red" outlined @click="resetExaminer()" class="mr-3">
                    <v-icon> mdi-refresh </v-icon>
                    <v-divider class="mx-2" vertical></v-divider>
                    <span class="subheading">Reset</span>
                  </v-btn>
                  <v-btn @click="assignExaminer()" small color="teal white--text" class="mr-3">
                    <v-icon> mdi-plus </v-icon>
                    <v-divider class="mx-2" vertical color="white--text"></v-divider>
                    <span class="subheading">Re-Assign</span>
                  </v-btn>
                </v-layout>
              </v-form>
            </v-card-text>
          </v-card-title>

        </v-card>
      </v-dialog>
    </v-row>
    </v-form>
</template>

<script>
import moment from "moment";
    export default {
        data(){ 
            return{
                triggerDetails:true,
                pageNo:'',
                validationmessage: "This field is required",
                pratice:[],
                triggerDetail:[
                    {text:"Trigger Code",value:"code"},
                    {text:"Trigger Description",value:"description"},
                    {text:"Triggered At",value:"date"},
                ],
                redFlagScore:"----------",
                rules: {
                    mandatoryrule: [v => !!v || this.validationmessage]
                },
                claimOpenedMenu1:false,
                

                viewOpenFileObj:{
                    claimNumber : "",
                    featureNumber : "",
                    claimantName : "",
                    insuredName : "",
                    claimedAmount : "",
                    productName : "",
                    dateOfLoss : "",
                    dateReported:"",
                    dateCreated:"",
                    claimType:"",
                    assignTo: {"ntidUserId":"","employeeName":"","userId":"","type":""},
                    litigationType:"",
                    ofrStatus:"",
                    adherenceToTat: {
                        firstContact:"" ,
                        adequacyOfReserve:"",
                        followUpReminders: "",
                        reasonForPendency: "",
                        comments:"",
                    },
                //   coverage : "",
                    coverage: {
                        coverEndDate: "",
                        coverGroupIndex: "",
                        coverStartDate: "",
                        nolCode: "",
                        perilName: "",
                        period: "",
                        riskCoverGroup: "",
                        riskSerialNumber: "",
                        riskSiComponent: "",
                        si: ""
                            
                    },
                    examiner : 
                    {
                        employeeName: "",
                        ntid: "",
                        type: "",
                        userId: "",
                    },
                    
                    
                    totalIndemnityPaid : 0,
                    totalExpensePaid : 0,
                    litigationType : "",
                    reserveAmount : "",
                    sumInsured : "",
                    ofrStatus : "",
                    // reviewedBy: "",
                    // firstContact : "",
                    // followUpReminders : "",
                    // reasonForPendency : "",
                    // reserve : "",
                    // adequecyOfReserve : "",
                    // dateOfLossmenu:false,
                    // dateOfLoss:null,
                    // disableDateTime: false,

                    // paidDateMenu:false,
                    // paidDate:null,
                    // disablePaidDate:false,

                    // markOfDateMenu:false,
                    // markOffDate:null,
                    // disableMarkOffDate:false,

                    // ofrDateMenu:false,
                
                    // ofrDate: moment().format("YYYY-MM-DD"),

                    // claimOpenedDateMenu:false,
                    // claimOpenedDate:null,
                    // disableClaimOpened:null,

                },

                firstContactArr:["Within TAT","Beyond TAT"],
                followUpRemindersArr:["Within TAT","Beyond TAT"],
                adequecyOfReserveArr:["Yes","No"],
            
                viewMode:false, //for view mode
                modify:false,

                openFile_Menu:true,
                adhrencemenu:true,

            
                todaydate:moment().format('YYYY-MM-DD'),  
                
                adherenceDetails:[],
                reasonForArr:["Documents not provided by Customer",
                              "Insured not provided time",
                              "Part Availability Issue",
                              "NIL Documents Submitted",
                              "U/w/ OPS/IT Resolution",
                              "LPR Awaited",
                              "LPR payability given & ROE awaited",
                              "Legal Case",
                              "Indemnity closed expense open",
                              "ROE Awaited",
                              "CKYC documents Awaited",
                              "Investigation Report/Medical Opinion",
                              "Partial Documents Received",
                              "Others"],
                examinerDialog: false,
                searchExaminer: '',
                examiners: [],
                examiner: '',
                examinerObj: {
                    id:"",
                    assignTo: {
                    ntidUserId: "",
                    employeeName: "",
                    userId: "",
                    type: ""
                    }
                },
            }
        },

        created(){
            this.$store.commit("SAVE_PAGE_HEADER", "OFR Details");
            if(Object.keys(this.$route.params).length !== 0){
                this.viewMode = this.$route.params.isView  
                this.pratice=Object.assign({},this.$route.params.item)
                console.log(this.pratice)
                console.log(this.$route.params.item)  
                
                this.viewOpenFileObj = this.$route.params.item  
                // this.viewOpenFileObj =  {...this.viewOpenFileObj , ...this.$route.params.viewOpenFileObj}   
                let a=this.$route.params.item
                this.viewOpenFileObj.dateOfLoss=this.getFormattedDate(a.dateOfLoss)
                this.viewOpenFileObj.dateReported=this.getFormattedDate(a.dateReported)
                this.viewOpenFileObj.dateCreated=this.getFormattedDate(a.dateCreated)
                this.viewOpenFileObj.Date=this.getFormattedDate(a.Date)
                this.viewOpenFileObj.ofrStatus=a.ofrStatus?"Closed":"Open"
                this.getExaminerList();
            }

            
        },

      methods:{
        getFormattedDate(date) {
            if(date === "" || date === null) return "";
            return moment(date).format("DD-MM-YYYY");
        },
        isNumber(evt) {
                evt = (evt) ? evt : window.event;
                var charCode = (evt.which) ? evt.which : evt.keyCode;
                if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                    evt.preventDefault();
                }
            return true;
        }, 

        onlyAlphaNumeric(e){
            var code = ('charCode' in e) ? e.charCode : e.keyCode;
            if (
                !(code > 47 && code < 58) && // numeric (0-9)
                !(code > 64 && code < 91) && // upper alpha (A-Z)
                !(code > 96 && code < 123)) { // lower alpha (a-z)
                e.preventDefault();
            }
        },
        formatDateTime(date) {
            return date && date !== ""
                ? moment.utc(date).local().format("DD-MM-YYYY hh:mm A")
                : "-";
        },
        cancel(){
            this.showConfirm("Confirmation","Are you sure you want to leave?","Yes","No",(Yes) => {
                this.showProgress();
                this.$router.go(-1);//params:{pageNo:this.pageNo}}
                this.hideProgress();
            })
        },

        clear(){
                this.showConfirm("Confirmation","Are you sure you want to clear?","Yes","No",(Yes) => {
                    this.viewOpenFileObj.adherenceToTat.firstContact = "",
                    this.viewOpenFileObj.adherenceToTat.followUpReminders = "",
                    this.viewOpenFileObj.adherenceToTat.reasonForPendency = "",
                    this.viewOpenFileObj.adherenceToTat.adequacyOfReserve = "",
                    this.viewOpenFileObj.adherenceToTat.comments = ""
            })
        },

        submit(submit){
            if (this.$refs.openFileForm.validate()) {
                this.showConfirm("Confirmation",`Are you sure you want to ${submit ? 'submit' : 'save'}?`,"Yes","No",(Yes) => {
                    this.viewOpenFileObj.dateOfLoss=this.pratice.dateOfLoss
                    this.viewOpenFileObj.dateCreated=this.pratice.dateCreated
                    this.viewOpenFileObj.dateReported=this.pratice.dateReported
                    this.viewOpenFileObj.ofrStatus=submit
                    this.viewOpenFileObj.Date=this.pratice.Date
                    // if(!this.pratice.ofrStatus){
                    //             this.viewOpenFileObj.ofrStatus=true
                    //         }
                    let requestData = this.viewOpenFileObj
                    this.PUTM("updateOfr",this.$route.params.item._id,requestData, (res) => {
                        if(res.data.statusCode == 200){
                            this.showAlert(res.data.msg, "Ok", "", () => {
                            
                            
                            this.$router.go(-1)//push({name:"CommonOFRSearch"}) 
                            
                                        
                        });
                        
                        }else{
                            this.showAlert(res.data.msg)
                        }
                        this.hideProgress();
                    },(error) => {
                        this.hideProgress()
                        if(error.response.data.msg){
                            this.showAlert(error.response.data.msg);
                        }else if(error.response.data.message.msg){
                            this.showAlert(error.response.data.message.msg);
                        }else if(error.response.data.message){
                            this.showAlert(error.response.data.message);
                        }else{
                            this.showAlert("Something went wrong");
                        }
                    }, true)
                }) 
            } else {
                this.showAlert("Please enter mandatory fields");
            }                 
        },

        openDetailedClaim(item){
            this.$router.push({name: "Assessment",
                params: {
                    //...this.$route.params
                    tabName: 'claim',
                    pageName: 'claimDetails',
                    claimNumber: item.claimNumber,
                    featureNumber: item.featureNumber,
                    invoiceCertificateNumber: item.invoiceCertificateNumber,
                    isClaimEvent : true,
                    backPage : 'viewOpenFile',
                    view:this.viewMode,
                    viewOpenFileObj:this.viewOpenFileObj,
                }
            });
        },

        getExaminerList() {
            let request = "pageNo=1&perPage=50&active=true&role=Examiner";
            this.GETM(
                "SERVICE_PROVIDER_EXAMINER_LIST",
                request,
                (res) => {
                if (res.data && res.data.data && res.data.data.data) {
                    this.examiners = res.data.data.data;
                }
                },
                (error) => {
                console.log(error?.response)
                },
                true
            );
        },

        assignExaminer() {
            if(this.$refs.examinerassignref.validate()) {
                this.POSTM(
                "POST_REASSIGN_EXAMINER_OFR",
                this.examinerObj,
                (res) => {
                    this.hideProgress();
                    if (res.data && res.data.statusCode == 200) {
                    this.showAlert(res?.data?.msg || "Success", "", "", () => {
                        this.examinerDialog = false;
                        this.viewOpenFileObj.assignTo = this.examinerObj.assignTo
                    });
                    } else {
                    this.showAlert(res.data.msg);
                    }
                },
                (err) => {
                    this.hideProgress();
                    let msg = err?.response?.data?.msg || "Something went wrong.";
                    this.showAlert(msg);
                }, 'Travel'
                );
            } else {
                this.showAlert('Please select examiner');
            }
        },

        changeExaminer(value) {
            let newObj = this.examiners.find((d) => d.employeeName == value);
            this.examinerObj.id = this.viewOpenFileObj._id
            this.examinerObj.assignTo.ntidUserId = newObj.ntidUserId
            this.examinerObj.assignTo.employeeName = newObj.employeeName
            this.examinerObj.assignTo.userId = newObj._id
            this.examinerObj.assignTo.type = newObj.typeOfEmployee
        },

        resetExaminer() {
            this.examiner = '';
            this.$refs.examinerassignref.resetValidation();
        },
    },

     
}
</script>

<style scoped>
    .viewClr{
        background-color: white;
        border:1px solid f5f5f5;
        color:f5f5f5;
    }
    .graybackground {
        /* background-color: #e7e7e7; */
        height: 45px;
        font-size:12px
    }

    .orangetext {
        color: #E46A25;
    }

    .colorbg{
        background-color: #23b1a9;
        color:#fff;
    }

    .descrptionSpan {
        width: 85%;
        margin-top: 21px;
        font-weight: bold;
        font-size: 18px;
        letter-spacing: 0.4px;
    }
    
    .dailogboxAllvcardTableBackground {
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 5px 7px #00000029;
        opacity: 1;
        font-size: 14px;
    }

    .vcardTextTableBackground {
        background-color: #b4b4b440 !important;
        /* box-shadow: inset 0px 3px 4px 0px #00000047; */
    }
 

    .borderStyle{
        border:0.5px solid #d7d7d7;
        margin:0 21px 3px 8px;
    }

    .btnstyle{
        height: 33px !important;
        text-transform: capitalize;
        font-size: 11px !important;
        letter-spacing: 0.5px;
    }

    .reset{
        background-color: white;
        border:1px solid #D1121B;
        color:#D1121B;
    }

    .paginationrow{
        margin-right: 10px;
        /* margin-top: 10px;  */
        align-items: center
    }

    /* .borderStyle{
        border:0.5px solid #d7d7d7;
    } */

    .theadstyle{
        width: 100%;
        padding-left: 40px;
        padding-right: 40px;
        
    }
    .selectstyle{
        width: 50%;
        /* position:static; */
        width: 276px;
        
    }
    .reservefield.v-text-field.v-text-field--enclosed .v-text-field__details{
        display: none !important;
    }
    .leftstyle{
        float:left;
        padding-left: 20px;
    }
    .iconsize{
        font-size: 18px !important;
    }
     .contactbtn{
        border-radius: 0;
        border: 0.5px solid #d9d9d9;
        text-transform: capitalize;
        letter-spacing: normal;
    }
.form-groups{
    max-width:290px;
    margin-right: 10px;
}
.groupRowHeadNewLable {
    margin-top: 16px;
    background-color: #01bbd9;
    height: 35px;
  }
  .GroupNameNewLable{
    font-size: 12px;
    color: white;
    font-weight: bold;
	letter-spacing: 0.2px;
  }
</style>