<template>
    <div class="ma-5">
        <p class="font-weight-bold">Search Litigation</p>
        <v-form ref="searchlitigationref" @submit.prevent="searchLitigation()">
            <v-row no-gutters class="mt-2">
                <v-col class="ml-3 mr-3">
                    <p class="claimlabel mb-2">LOB <span class="mandatorycolor">*</span></p>
                    <v-select
                        v-model="searchObj.lobType"
                        label="Select"
                        :items="lobOptions"
                        :rules="[mandatoryrule()]"
                        solo
                        dense
                        class="forminput"
                        clearable
                        @change="tataAigAttorneyOptions = []"
                    ></v-select>
                </v-col>
                <v-col class="mr-3">
                    <p class="claimlabel mb-2">Claim Number </p>
                    <v-text-field
                        v-model="searchObj.claimNumber"
                        placeholder="Enter"
                        solo
                        dense
                        class="forminput"
                        clearable
                    ></v-text-field>
                </v-col>
                <v-col class="mr-3">
                    <p class="claimlabel mb-2">Feature Number </p>
                    <v-text-field
                        v-model="searchObj.featureNumber"
                        placeholder="Enter"
                        solo
                        dense
                        class="forminput"
                        clearable
                    ></v-text-field>
                </v-col>
                <v-col class="mr-3">
                    <p class="claimlabel mb-2">Litigation Status </p>
                    <v-select
                        v-model="searchObj.litigationStatus"
                        :items="litigationStatusOptions"
                        placeholder="Select"
                        solo
                        dense
                        class="forminput"
                        clearable
                    ></v-select>
                </v-col>
                <v-col class="mr-3">
                    <p class="claimlabel mb-2">TATA AIG Attorney </p>
                    <v-select
                        v-model="searchObj.tataAigAttorney"
                        :items="tataAigAttorneyOptions"
                        placeholder="Select"
                        solo
                        dense
                        class="forminput"
                        clearable
                        @click.self="fetchTataAigAttorneyList"
                    ></v-select>
                </v-col>
                <v-col class="mr-3">
                    <p class="claimlabel mb-2">Case Number </p>
                    <v-text-field
                        v-model="searchObj.caseNumber"
                        placeholder="Enter"
                        solo
                        dense
                        class="forminput"
                        clearable
                    ></v-text-field>
                </v-col>
                
                <v-col class="mr-3">
                    <p class="claimlabel mb-2">Court Name </p>
                    <v-text-field
                        v-model="searchObj.courtName"
                        placeholder="Enter"
                        solo
                        dense
                        class="forminput"
                        clearable
                    ></v-text-field>
                </v-col>
            </v-row>
            <!-- buttons  -->
            <v-row class="mt-2">
                <v-col>
                    <v-btn outlined color="#D1121B" class="btnstyle mr-3" @click="cancel()">
                        <v-icon class="mr-2 iconsize">mdi-close-box-outline</v-icon>
                        <div class="vertical_line"></div>Cancel
                    </v-btn>
                    <v-btn outlined color="#D1121B" class="btnstyle mr-3" @click="reset()">
                        <v-icon class="mr-2 iconsize">mdi-close-box-outline</v-icon>
                        <div class="vertical_line"></div>Reset
                    </v-btn>
                </v-col>
                <v-col align="right">
                    <v-btn color="#23B1A9"  dark class="btnstyle" type="submit">
                        <v-icon class="mr-2 iconsize">mdi-magnify</v-icon>
                        <div class="vertical_line"></div>search
                    </v-btn>
                </v-col>
            </v-row>
            <template>
                <v-data-table
                    :headers="litigationHeaders" 
                    no-data-text="No Records for Litigation" 
                    fixed-header 
                    :items="searchLitigationData" 
                    hide-default-footer 
                    class="vDataTableStyling tableborder my-3">
                    <template  v-slot:[`item.action`]="{ item }">
                        <a class="textcolor font-weight-bold" @click="goToLitigationDetail(item)">VIEW</a>
                    </template>
                    <template  v-slot:[`item.caseNumber`]="{ item }">
                        <span>
                            {{ item.petitionDetails.length > 0 ? item.petitionDetails[0].caseNumber : '' }}
                        </span>
                    </template>
                    <template  v-slot:[`item.courtName`]="{ item }">
                        <span>
                            {{ item.petitionDetails.length > 0 ? item.petitionDetails[0].courtName : '' }}
                        </span>
                    </template>
                    <template  v-slot:[`item.noticeReceivedOn`]="{ item }">
                        <span>
                            {{formatDate(item.noticeReceivedOn)}}
                        </span>
                    </template>
                </v-data-table>
            </template>
            <!-- pagination -->
            <div class="text-center graybackground pdct-stUp-bnkAcc-pgn mt-3 mx-4" v-if="searchLitigationData.length > 0">
                <v-layout justify-end>
                        <div style="margin-top: 15px">
                        <span class="orangetext paginationText">
                            Showing {{ totalListCount ? pageStartIndex + 1 : 0 }} - {{ pageEndIndex }}
                        </span>
                        <span class=" mr-2 paginationText"> out of {{ totalListCount }} records </span>
                        </div>
                        <!-- @input="goNextPagePagination" -->
                        <v-pagination
                            v-model="pageNumber"
                            :length="totalPages"
                            :total-visible="pageRowCount" 
                            @input="searchLitigation"
                            color="#152F38"
                            class="navpagenumber pt-1"
                        ></v-pagination>
                </v-layout>
                <br />
            </div>
        </v-form>
    </div>
</template>

<script>
import moment from 'moment';
export default {
    data() {
        return {
            todayDate:  moment(new Date()).format("YYYY-MM-DD"),
            // RULES
            validationmessage: "This field is required",

            mandatoryrule() {
                return (v) => !!v || this.validationmessage;
            },
            searchLitigationData: [],
            litigationHeaders: [
                {text: 'Action',align: 'center',value: 'action',sortable: false},
                {text: 'LOB',align: 'center',value: 'lobType',sortable: false},
                {text: 'Claim Number',align: 'center',value: 'claimNumber',sortable: false},
                {text: 'Feature Number',align: 'center',value: 'featureNumber',sortable: false},
                {text: 'Case Number',align: 'center',value: 'caseNumber',sortable: false},
                {text: 'Court Name',align: 'center',value: 'courtName',sortable: false},
                // {text: 'Notice Received On',align: 'center',value: 'noticeReceivedOn',sortable: false},
                // {text: 'Plaintiff Name',align: 'center',value: 'plaintiffName',sortable: false},
                // {text: 'Plaintiff Attorney Name',align: 'center',value: 'plaintiffAttorneyName',sortable: false},
                // {text: 'Defendant Name',align: 'center',value: 'defendantName',sortable: false},
            ],
            // table related
            pageNumber: 1,
            pageStartIndex: 0,
            pageRowCount: 10,
            totalListCount: 0,
            totalPages:null,
            lobOptions: [],
            litigationStatusOptions:['Open','Closed'],
            searchObj: {
                lobType: '',
                claimNumber: '',
                featureNumber: '',
                caseNumber: '',
                courtName: '',
                litigationStatus:'',
                tataAigAttorney:''
            },
            tataAigAttorneyOptions:[]
        }
    },

    computed: {
        pageEndIndex() {
            return this.pageStartIndex + this.pageRowCount <= this.totalListCount? this.pageStartIndex + this.pageRowCount: this.totalListCount;           
        },
    },

    created() {
        this.getLOB();
        this.$store.commit("SAVE_PAGE_HEADER", "Litigation");
    },

    methods: {
        formatDate(date) {
            return date == null ? "" : moment(date).format('DD/MM/YYYY');
        },
        searchLitigation() {
            if(this.$refs.searchlitigationref.validate()) {
                let reqData = `page=${this.pageNumber}&pageLimit=10&lobType=${this.searchObj.lobType}`;
                if(this.searchObj.claimNumber) {
                    reqData += `&claimNumber=${this.searchObj.claimNumber}`;
                }
                if(this.searchObj.featureNumber) {
                    reqData += `&featureNumber=${this.searchObj.featureNumber}`;
                }
                if(this.searchObj.caseNumber) {
                    reqData += `&caseNumber=${this.searchObj.caseNumber}`;
                }
                if(this.searchObj.litigationStatus) {
                    reqData += `&litigationStatus=${this.searchObj.litigationStatus}`;
                }
                if(this.searchObj.courtName) {
                    reqData += `&courtName=${this.searchObj.courtName}`;
                }
                if(this.searchObj.tataAigAttorney){
                    reqData += `&tataAigAttorney=${this.searchObj.tataAigAttorney}`;
                }
                this.showProgress();
                this.GETM("searchLitigation", reqData, (res) => {
                    this.hideProgress();
                    if(res.data.statusCode === 200) {
                        this.searchLitigationData = res.data.data.data;
                        this.pageRowCount = 10;
                        this.totalListCount = res.data.data.total;
                        this.totalPages = Math.ceil(res.data.data.total / this.pageRowCount);
                        this.pageStartIndex = this.pageNumber == 1 ? 0 : (this.pageNumber - 1) * this.pageRowCount;
                    } else {
                        this.pageRowCount = 0;
                        this.totalListCount = 0;
                        this.totalPages = 0;
                        this.pageStartIndex =0;
                        this.showAlert(res.data.msg);
                    }
                }, (error) => {
                    console.log(error);
                    this.hideProgress();
                }, true)
            } else {
                this.showAlert('Please enter mandatory field');
            }
        },

        getLOB() {
            let reqData = '';
            this.showProgress();
            this.GETM("getLOB", reqData, (res) => {
                this.hideProgress();
                if(res.data.statusCode === 200) {
                    this.lobOptions = res.data.data[0].lineOfBusiness;
                } else {
                    this.lobOptions = [];
                    this.showAlert(res.data.msg);
                }
            }, (error) => {
                console.log(error);
                this.hideProgress();
            }, true)
        },

        goToLitigationDetail(item) {
            this.$router.push({
                name: 'litigationDetails',
                params: {
                    claimDetails: item,
                }
            });
        },

        cancel() {
            this.showConfirm("Confirmation", `Are you sure you want to leave?`, "Yes", "No", () => {
                this.$router.push({
                    name: 'Home',
                });
            }, () => {})
        },

        reset() {
                this.showConfirm("Confirmation", `Are you sure you want to reset?`, "Yes", "No", () => {
                    this.searchObj = {
                        lobType: '',
                        claimNumber: '',
                        featureNumber: '',
                        caseNumber: '',
                        courtName: '',
                        litigationStatus:'',
                        tataAigAttorney:''
                    }
                    this.searchLitigationData = [];
                    this.$refs.searchlitigationref.resetValidation()
                }, () => {})
            },

        fetchTataAigAttorneyList(){
            if(this.searchObj.lobType){
                let reqData = `lobType=${this.searchObj.lobType}`;
                this.showProgress();
                this.GETM("getTataAigAttorneyList", reqData, (res) => {
                    this.hideProgress();
                    if(res.data.statusCode == 200) {
                        this.tataAigAttorneyOptions = res.data.advocateNames;
                    } else {
                        this.tataAigAttorneyOptions = [];
                        this.showAlert(res.data.msg);
                    }
                }, (error) => {
                    console.log(error);
                    this.hideProgress();
                }, true)
            } else{
                this.showAlert('Kindly enter the LOB');
            }
        }

    }
}
</script>

<style scoped>
    .iconsize {
        font-size: 16px !important;
        font-weight: bold;
    }
    .btnstyle{
        height: 28px !important;
        text-transform: capitalize;
        font-size: 11px !important;
        letter-spacing: 0.5px;
    }
    .textcolor {
        color: #1a0dab;
        font-weight: 400;
    }
    .orangetext {
        color: #e46a25;
    }
</style>