<template>
  <v-overlay :absolute="absolute" :value="overlay">
       <p class="title">{{ title }}</p>
    <v-progress-circular :size="200" color="#01BBD9" indeterminate>
    </v-progress-circular>
  </v-overlay>
</template>

<script>
export default {
  data() {
    return {
      absolute: true,
    };
  },
  computed: {
    title() {
      return this.$store.getters.fetchShowProgressMessage;
    },
    overlay() {
      return this.$store.getters.fetchShowProgress;
    },
  },
};
</script>

<style scoped>
/* .title {
  margin-left: 100px;
} */
</style>



