<template>
<v-app>
        <div style="background-color: #FFFFFF;" column class="py-3">
            <v-form ref="form">
                <v-layout class=" mt-2 mb-2" style="height:80px;" >
                    <v-flex xs3>
                        <v-layout column class="mx-2 my-2">
                            <v-text class="text-uppercase font-weight-bold title-clr-pr">
                                Quality and Teaching Details
                            </v-text>
                            <v-divider></v-divider>
                        </v-layout>
                    </v-flex>
 
                    <v-flex xs2>
                            <v-layout column class=" mx-2 my-2 cust_pr_quick cust-rmvpd-pr-quick" >
                                <v-text class="font-weight-bold cstm-clr-pr-quick">
                                        NABH - Full Accreditation
                                </v-text>
                                <v-layout   class="d-flex justify-start " style="background-color: #F7F7F7; height: 40px ; border: solid 0.5px #dae1e6;"  >
                                    <v-radio-group    class="ml-2 pa-0 align-self-center" dense  v-model="fullAccreditation" row  >
                                        <v-radio color = "#23b1a9" @click="fullAccredRadio(fullAccreditation)"  style="height:5px width:5px" label="Yes" value="Yes" ></v-radio>
                                        <v-radio color = "#23b1a9" @click="fullAccredRadio(fullAccreditation)" label="No" value="No"></v-radio>
                                    </v-radio-group> 

                                </v-layout>
                            </v-layout>
                    </v-flex>
    
                    <v-flex xs2>
                            <v-layout column class=" mx-2 my-2 cust_pr_quick cust-rmvpd-pr-quick">
                                <v-text class="font-weight-bold cstm-clr-pr-quick">
                                        NABH - Progressive Level
                                </v-text>
                                <v-layout   class="d-flex justify-start " style="background-color: #F7F7F7; height: 40px ; border: solid 0.5px #dae1e6;"  >
                                    <v-radio-group    class="ml-2 pa-0 align-self-center" dense  v-model="progressLevel" row  >
                                        <v-radio color = "#23b1a9"   @click="progressLevelRadio(progressLevel)" style="height:5px width:5px" label="Yes" value="Yes" ></v-radio>
                                        <v-radio color = "#23b1a9"  @click="progressLevelRadio(progressLevel)" label="No" value="No"></v-radio>
                                    </v-radio-group> 

                                </v-layout>
                            </v-layout>
                    </v-flex>

                    <v-flex xs4>
                            <v-layout column class=" mx-2 my-2 cust_pr_quick cust-rmvpd-pr-quick" style="width:360px">
                                <v-text class="font-weight-bold cstm-clr-pr-quick">
                                        NABH - Pre-Accreditation Entry Level Certification Standard
                                </v-text>
                                <v-layout   class="d-flex justify-start " style="background-color: #F7F7F7; height: 40px ; border: solid 0.5px #dae1e6;"  >
                                    <v-radio-group    class="ml-2 pa-0 align-self-center" dense  v-model="preAccredEntryLevel" row  >
                                        <v-radio color = "#23b1a9"   @click="preAccredEntryLevelRadio(preAccredEntryLevel)"  style="height:5px width:5px" label="Yes" value="Yes" ></v-radio>
                                        <v-radio color = "#23b1a9"   @click="preAccredEntryLevelRadio(preAccredEntryLevel)" label="No" value="No"></v-radio>
                                    </v-radio-group> 

                                </v-layout>
                            </v-layout>
                    </v-flex>
                </v-layout>
                <v-divider class="mb-3"></v-divider>

                <v-layout style="height:100px">
                    <v-flex xs3>
                        <v-layout column class=" mx-2 my-2 cust_pd">
                            <v-text class="font-weight-bold cstm-clr-pd">
                                Accreditation Number
                            </v-text>
                            <v-text-field
                                solo
                                label="Enter..."
                                v-model="qualityteachingNumber"
                                :background-color="enableNabhFullAccre == true ? '#f7f7f7' : '' "
                                :disabled="enableNabhFullAccre"
                                maxlength="5"
                                hide-details
                                class="rounded-0">
                            </v-text-field>
                        </v-layout>
                    </v-flex>

                    <v-flex xs3>
                        <v-layout column class=" mx-2 my-2 cust_pd">
                            <v-text class="font-weight-bold cstm-clr-pd">
                                Name
                            </v-text>
                            <v-text-field
                                solo
                                label="Enter..."
                                v-model="qualityteachingName"
                                :background-color="enableNabhFullAccre == true ? '#f7f7f7' : '' "
                                :disabled="enableNabhFullAccre"
                                hide-details
                                maxlength="30"
                                class="rounded-0">
                            </v-text-field>
                        </v-layout>
                    </v-flex>                
                    
                    <v-flex xs3>
                        <v-layout column class=" mx-2 my-2 cust_pd cust-rmvpd-pr">
                            <v-text class="font-weight-bold cstm-clr-pd">
                                Valid From *
                            </v-text>
                        
                            <v-menu
                                ref="calender"
                                :close-on-content-click="true"
                                v-model="qualityteachingvalidfrom"
                                :nudge-right="40"
                                transition="scale-transition"
                                max-width="290px"
                                min-width="290px" 
                                class="rounded-0"
                                >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        slot="activator"
                                        label="Select.."
                                        :value="cmputdqualityteachingvalidfromjs"
                                        solo
                                        class="rounded-0"
                                        readonly 
                                        :background-color="enableNabhFullAccre == true ? '#f7f7f7' : '' "
                                        :disabled="enableNabhFullAccre"
                                        v-bind="attrs"
                                        v-on="on"
                                        :error-messages="nabhValidFrom"
                                        @input="$v.qualityteachingregvalidfrom.$touch()"
                                        @blur="$v.qualityteachingregvalidfrom.$touch()"
                                    >
                             <!-- :rules="nabhValidFromRules" -->

                                    <div  slot="prepend-inner" style="background-color:#F7F7F7;  padding: 9px !important;"><span class="material-icons">date_range</span></div></v-text-field>
                                </template>
                                                            
                                <v-date-picker v-model="qualityteachingregvalidfrom" no-title></v-date-picker>
                            </v-menu>
                        </v-layout>
                        </v-flex>

                        <v-flex xs3>
                        <v-layout column class=" mx-2 my-2 cust_pd cust-rmvpd-pr">
                            <v-text class="font-weight-bold cstm-clr-pd">
                                Valid Upto *
                            </v-text>
                        
                            <v-menu
                                ref="calender"
                                :close-on-content-click="true"
                                v-model="qualityteachingvalidupto"
                                :nudge-right="40"
                                transition="scale-transition"
                                max-width="290px"
                                min-width="290px" 
                                class="rounded-0">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        slot="activator"
                                        label="Select.."
                                        :value="cmputdqualityteachingvaliduptojs"
                                        solo
                                        class="rounded-0"
                                        readonly 
                                        :background-color="enableNabhFullAccre == true ? '#f7f7f7' : '' "
                                        :disabled="enableNabhFullAccre"
                                        v-bind="attrs"
                                        v-on="on"
                                        :error-messages="nabhValidUpto"
                                        @input="$v.qualityteachingregvalidupto.$touch()"
                                        @blur="$v.qualityteachingregvalidupto.$touch()"
                                    >
                                        <!-- :rules="nabhValidFromRules" -->

                                    <div  slot="prepend-inner" style="background-color:#F7F7F7;  padding: 9px !important;"><span class="material-icons">date_range</span></div></v-text-field>
                                </template>
                                                            
                                        <v-date-picker
                                            v-model="qualityteachingregvalidupto"
                                            no-title
                                            
                                        ></v-date-picker>
                            </v-menu>
                        </v-layout>
                        </v-flex>

                        <v-flex xs3></v-flex>
                            
                </v-layout>
                <v-divider></v-divider>

                <v-layout class="mt-3" style="height:80px;">
                    
                    <v-flex xs3>
                        <v-layout column class="mx-2 my-2">
                            <v-text class="text-uppercase font-weight-bold title-clr-pr">
                                NABL Accreditation
                            </v-text>
                            <v-divider></v-divider>
                        </v-layout>
                    </v-flex>

                    <v-flex xs3>
                            <v-layout column class=" mx-2 my-2 cust_pr_quick cust-rmvpd-pr-quick">
                                <v-text class="font-weight-bold cstm-clr-pr-quick">
                                        NABL - Full Accreditation
                                </v-text>
                                <v-layout   class="d-flex justify-start " style="background-color: #F7F7F7; height: 40px ; border: solid 0.5px #dae1e6;"  >
                                    <v-radio-group    class="ml-2 pa-0 align-self-center" dense  v-model="nablfullAccreditation" row  >
                                        <v-radio color = "#23b1a9"  @click="nablfullAccreditationRadio(nablfullAccreditation)"  style="height:5px width:5px" label="Yes" value="Yes" ></v-radio>
                                        <v-radio color = "#23b1a9"  @click="nablfullAccreditationRadio(nablfullAccreditation)" label="No" value="No"></v-radio>
                                    </v-radio-group> 

                                </v-layout>
                            </v-layout>
                    </v-flex>

                    <v-flex xs3>
                        <v-layout column class=" mx-2 my-2 cust_pd">
                            <v-text class="font-weight-bold cstm-clr-pd">
                                Accreditation Number
                            </v-text>
                            <v-text-field
                                solo
                                label="Enter..."
                                v-model="nablaccredNumber"
                                maxlength="5"
                                hide-details
                                :background-color="enableNablFullAccre == true ? '#f7f7f7' : '' "
                                :disabled="enableNablFullAccre"
                                class="rounded-0">
                            </v-text-field>
                        </v-layout>
                    </v-flex>

                    <v-flex xs3>
                        <v-layout column class=" mx-2 my-2 cust_pd">
                            <v-text class="font-weight-bold cstm-clr-pd">
                                Name
                            </v-text>
                            <v-text-field
                                solo
                                label="Enter..."
                                v-model="nablName"
                                hide-details
                                :background-color="enableNablFullAccre == true ? '#f7f7f7' : '' "
                                :disabled="enableNablFullAccre"
                                maxlength="30"
                                class="rounded-0">
                            </v-text-field>
                        </v-layout>
                    </v-flex>                
                    
                    <v-flex xs3>
                        <v-layout column class=" mx-2 my-2 cust_pd cust-rmvpd-pr">
                            <v-text class="font-weight-bold cstm-clr-pd">
                                Valid From *
                            </v-text>
                        
                            <v-menu
                                ref="calender"
                                :close-on-content-click="true"
                                v-model="Nablvalidfrom"
                                :nudge-right="40"
                                transition="scale-transition"
                                max-width="290px"
                                min-width="290px" 
                                class="rounded-0"
                                >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        slot="activator"
                                        label="Select.."
                                        :value="cmputdNablvalidfromjs"
                                        solo
                                        class="rounded-0"
                                        readonly 
                                        :background-color="enableNablFullAccre == true ? '#f7f7f7' : '' "
                                        :disabled="enableNablFullAccre"
                                        v-bind="attrs"
                                        v-on="on"
                                        :error-messages="nablValidFrom"
                                        @input="$v.Nablregvalidfrom.$touch()"
                                        @blur="$v.Nablregvalidfrom.$touch()"
                                    >
                                        <!-- :rules="nablValidFromRules" -->

                                    <div  slot="prepend-inner" style="background-color:#F7F7F7;  padding: 9px !important;"><span class="material-icons">date_range</span></div></v-text-field>
                                </template>
                                                            
                                <v-date-picker v-model="Nablregvalidfrom" no-title></v-date-picker>
                            </v-menu>
                        </v-layout>
                    </v-flex>
                </v-layout>

                <v-layout >
                    <v-flex xs3>
                        <v-layout column class=" mx-2 my-2 cust_pd cust-rmvpd-pr">
                            <v-text class="font-weight-bold cstm-clr-pd">
                                Valid Upto *
                            </v-text>
                        
                            <v-menu
                                ref="calender"
                                :close-on-content-click="true"
                                v-model="Nablvalidupto"
                                :nudge-right="40"
                                transition="scale-transition"
                                max-width="290px"
                                min-width="290px" 
                                class="rounded-0"
                                >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        slot="activator"
                                        label="Select.."
                                        :value="cmputdNablvaliduptojs"
                                        solo
                                        class="rounded-0"
                                        readonly 
                                        :background-color="enableNablFullAccre == true ? '#f7f7f7' : '' "
                                        :disabled="enableNablFullAccre"
                                        v-bind="attrs"
                                        v-on="on"
                                        :error-messages="nablValidUpto"
                                        @input="$v.Nablregvalidupto.$touch()"
                                        @blur="$v.Nablregvalidupto.$touch()"
                                    >
                                        <!-- :rules="nablValidUptoRules" -->

                                    <div  slot="prepend-inner" style="background-color:#F7F7F7;  padding: 9px !important;"><span class="material-icons">date_range</span></div></v-text-field>
                                </template>
                                                            
                                    <v-date-picker v-model="Nablregvalidupto" no-title></v-date-picker>
                            </v-menu>
                        </v-layout>
                        </v-flex>

                    <v-flex xs3></v-flex>
                    <v-flex xs3></v-flex>
                    <v-flex xs3></v-flex>
                    <v-flex xs3></v-flex>
                </v-layout>
                <v-divider ></v-divider>

                <v-layout class="mt-3" style="height:80px;">
                        
                    <v-flex xs3>
                        <v-layout column class="mx-2 my-2" >
                            <v-text class="text-uppercase font-weight-bold title-clr-pr">
                                JCI Accreditation
                            </v-text>
                            <v-divider></v-divider>
                        </v-layout>
                    </v-flex>

                    <v-flex xs3>
                            <v-layout column class=" mx-2 my-2 mt-3 cust_pr_quick cust-rmvpd-pr-quick">
                                <v-text class="font-weight-bold cstm-clr-pr-quick">
                                        JCI Accreditation
                                </v-text>
                                <v-layout   class="d-flex justify-start " style="background-color: #F7F7F7; height: 40px ; border: solid 0.5px #dae1e6;"  >
                                    <v-radio-group    class="ml-2 pa-0 align-self-center" dense  v-model="jciAccreditation" row  >
                                        <v-radio color = "#23b1a9" @click="jciAccreditationRadio(jciAccreditation)"  style="height:5px width:5px" label="Yes" value="Yes" ></v-radio>
                                        <v-radio color = "#23b1a9" @click="jciAccreditationRadio(jciAccreditation)" label="No" value="No"></v-radio>
                                    </v-radio-group> 

                                </v-layout>
                            </v-layout>
                    </v-flex>

                    <v-flex xs3>
                        <v-layout column class=" mx-2 my-2 cust_pd">
                            <v-text class="font-weight-bold cstm-clr-pd">
                                Accreditation Number
                            </v-text>
                            <v-text-field
                                solo
                                label="Enter..."
                                v-model="jciaccredNumber"
                                hide-details
                                :background-color="enableJciAccred == true ? '#f7f7f7' : '' "
                                :disabled="enableJciAccred"
                                maxlength="5"
                                class="rounded-0">
                            </v-text-field>
                        </v-layout>
                    </v-flex>

                    <v-flex xs3>
                        <v-layout column class=" mx-2 my-2 cust_pd">
                            <v-text class="font-weight-bold cstm-clr-pd">
                                Name
                            </v-text>
                            <v-text-field
                                solo
                                label="Enter..."
                                v-model="jciName"
                                hide-details
                                :background-color="enableJciAccred == true ? '#f7f7f7' : '' "
                                :disabled="enableJciAccred"
                                maxlength="30"
                                class="rounded-0">
                            </v-text-field>
                        </v-layout>
                    </v-flex>                
                    
                    <v-flex xs3>
                        <v-layout column class=" mx-2 my-2 cust_pd cust-rmvpd-pr">
                            <v-text class="font-weight-bold cstm-clr-pd">
                                Valid From *
                            </v-text>
                        
                            <v-menu
                                ref="calender"
                                :close-on-content-click="true"
                                v-model="Jcivalidfrom"
                                :nudge-right="40"
                                transition="scale-transition"
                                max-width="290px"
                                min-width="290px" 
                                class="rounded-0"
                                >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        slot="activator"
                                        label="Select.."
                                        :value="cmputdJcivalidfromjs"
                                        solo
                                        class="rounded-0"
                                        readonly 
                                        :background-color="enableJciAccred == true ? '#f7f7f7' : '' "
                                        :disabled="enableJciAccred"
                                        v-bind="attrs"
                                        v-on="on"
                                        :error-messages="jciValidFrom"
                                        @input="$v.Jciregvalidfrom.$touch()"
                                        @blur="$v.Jciregvalidfrom.$touch()"
                                        
                                    >
                                        <!-- :rules="jciValidFromRules" -->

                                    <div  slot="prepend-inner" style="background-color:#F7F7F7;  padding: 9px !important;"><span class="material-icons">date_range</span></div></v-text-field>
                                </template>
                                
                                    <v-date-picker v-model="Jciregvalidfrom" no-title></v-date-picker>
                            </v-menu>
                        </v-layout>
                    </v-flex>

                </v-layout>

                <v-layout >
                    <v-flex xs3>
                        <v-layout column class=" mx-2 my-2 cust_pd cust-rmvpd-pr">
                            <v-text class="font-weight-bold cstm-clr-pd">
                                Valid Upto *
                            </v-text>
                        
                            <v-menu
                                ref="calender"
                                :close-on-content-click="true"
                                v-model="Jcivalidupto"
                                :nudge-right="40"
                                transition="scale-transition"
                                max-width="290px"
                                min-width="290px" 
                                class="rounded-0"
                                >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        slot="activator"
                                        label="Select.."
                                        :value="cmputdJcivaliduptojs"
                                        solo
                                        :background-color="enableJciAccred == true ? '#f7f7f7' : '' "
                                        :disabled="enableJciAccred"
                                        class="rounded-0"
                                        readonly 
                                        v-bind="attrs"
                                        v-on="on"
                                        :error-messages="jciValidUpto"
                                        @input="$v.Jciregvalidupto.$touch()"
                                        @blur="$v.Jciregvalidupto.$touch()">
                                        <!-- :rules="jciValidUptoRules" -->

                                    <div  slot="prepend-inner" style="background-color:#F7F7F7;  padding: 9px !important;"><span class="material-icons">date_range</span></div></v-text-field>
                                </template>
                                                            
                                <v-date-picker v-model="Jciregvalidupto" no-title></v-date-picker>
                            </v-menu>
                        </v-layout>
                        </v-flex>

                    <v-flex xs3></v-flex>
                    <v-flex xs3></v-flex>
                    <v-flex xs3></v-flex>
                    <v-flex xs3></v-flex>
                </v-layout>
                <v-divider ></v-divider>

                <v-layout class="mt-3">
                    <v-flex xs3>
                            <v-layout column class=" mx-2 my-2 cust_pr_quick cust-rmvpd-pr-quick">
                                <v-text class="font-weight-bold cstm-clr-pr-quick">
                                        Medical College
                                </v-text>
                                <v-layout   class="d-flex justify-start " style="background-color: #F7F7F7; height: 40px ; border: solid 0.5px #dae1e6;"  >
                                    <v-radio-group    class="ml-2 pa-0 align-self-center" dense  v-model="medicalCollege" row  >
                                        <v-radio color = "#23b1a9"  @click="medicalCollegeRadio(medicalCollege)"  style="height:5px width:5px" label="Yes" value="Yes" ></v-radio>
                                        <v-radio color = "#23b1a9"  @click="medicalCollegeRadio(medicalCollege)" label="No" value="No"></v-radio>
                                    </v-radio-group> 

                                </v-layout>
                            </v-layout>
                    </v-flex>

                    <v-flex xs3>
                        <v-layout column class=" mx-2 my-2 cust_pd">
                            <v-text class="font-weight-bold cstm-clr-pd">
                                No. of seats - Graduation
                            </v-text>
                            <v-text-field
                                :error-messages="noOfSeatGrad"
                                solo
                                label="Enter..."
                                v-model="noofseatsGrad"
                                :background-color="enableMedicalCollg == true ? '#f7f7f7' : '' "
                                :disabled="enableMedicalCollg"
                                maxlength="5"
                                class="rounded-0"
                                @input="$v.noofseatsGrad.$touch()"
                                @blur="$v.noofseatsGrad.$touch()">
                            </v-text-field>
                        </v-layout>
                    </v-flex>

                    <v-flex xs3>
                        <v-layout column class=" mx-2 my-2 cust_pd">
                            <v-text class="font-weight-bold cstm-clr-pd">
                                No. of seats - PG
                            </v-text>
                            <v-text-field
                                :error-messages="noOfSeatPG"
                                solo
                                label="Enter..."
                                v-model="noofseatsPg"
                                :background-color="enableMedicalCollg == true ? '#f7f7f7' : '' "
                                :disabled="enableMedicalCollg"
                                maxlength="5"
                                class="rounded-0"
                                @input="$v.noofseatsPg.$touch()"
                                @blur="$v.noofseatsPg.$touch()">
                            </v-text-field>
                        </v-layout>
                    </v-flex>

                    <v-flex xs3>
                        <v-layout column class=" mx-2 my-2 cust_pr_quick cust-rmvpd-pr-quick">
                            <v-radio-group style="width:200px"  class="ml-2 pa-0 align-self-center" dense  v-model="trainingInst" row  >
                                <v-text class="font-weight-bold cstm-clr-pr-quick">Teaching Institute (DNB) : </v-text>
                                <v-radio color = "#23b1a9"   @click="trainingInstRadio(trainingInst)"  label="Yes" value="Yes" dense ></v-radio>
                                <v-radio color = "#23b1a9"   @click="trainingInstRadio(trainingInst)" label="No" value="No" dense></v-radio>
                            </v-radio-group> 
                        </v-layout>
                    </v-flex>

                    <v-flex xs3>
                        <v-layout column class=" mx-2 my-2 cust_pd">
                            <v-text class="font-weight-bold cstm-clr-pd">
                                No. of seats
                            </v-text>
                            <v-text-field
                                :error-messages="dnbNoOfSeats"
                                solo
                                label="Enter..."
                                v-model="noofseats"
                                :background-color="enableTrainInst == true ? '#f7f7f7' : '' "
                                :disabled="enableTrainInst"
                                maxlength="5"
                                class="rounded-0"
                                @input="$v.noofseats.$touch()"
                                @blur="$v.noofseats.$touch()">
                            </v-text-field>
                        </v-layout>
                    </v-flex>

                </v-layout>
            </v-form>

            <!-- <v-dialog v-model="confirmDialog" persistent max-width="500px">
                <v-card>
                    <v-flex style="background-color:rgb(16, 36, 43)">
                        <v-card-title class="body-2 font-weight-regular" style="color:white">CONFIRM</v-card-title>
                    </v-flex>
                    <v-card-text style="display:flex;justify-content:center">
                            Details saved on screen will be lost
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn depressed small color="grey lighten-3" @click="confirmDialog = false">Cancel
                            <v-icon right dark>highlight_off</v-icon>
                        </v-btn>
                        <v-btn depressed small color="secondary"  @click="confirmDialog = false">OK
                            <v-icon dark right>check_circle</v-icon>
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog> -->

            <v-dialog v-model="confirmDialogCancel" persistent max-width="450px">
                <v-card tile color="#F7F7F7" >
                    <v-card tile>
                        <v-layout justify-space-between  class="" style="flex:1;background-ox-shadow: 0px 3px 5px #00000029;">
                        
                            <v-card-title class="pa-0 pl-3 text-capitalize font-weight-regular" style="color:black"> 
                                CONFIRMATION
                            </v-card-title>
                            <div style="border-left:1px solid #C1C8CC;" @click="close_cancelDialog()">
                                <v-icon style="cursor: pointer;" class="pa-2" >close</v-icon>
                            </div>
                        </v-layout>
                    </v-card>
                    <v-flex ></v-flex>
                    <v-card-text >Details saved on screen will be lost</v-card-text>
                    <v-card-actions justify-start>
                        <v-btn dark  tile color="black" @click="confirmDialogCancel = false" class="text-capitalize px-3 mx-2" small><v-icon dark small>cancel</v-icon> <v-divider vertical class="mx-2"></v-divider>Cancel</v-btn>
                        <v-btn @click="selectedCompFromPage(arrToNavCancel[1])" dark  tile color="#23B1A9" class="text-capitalize px-3 ml-2" small><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider> OK</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="confirmDialogNext" persistent max-width="450px">
                
                        <v-card tile color="#F7F7F7" >
                        <v-card tile>
                            <v-layout justify-space-between  class="" style="flex:1;background-ox-shadow: 0px 3px 5px #00000029;">
                            
                                <v-card-title class="pa-0 pl-3 text-capitalize font-weight-regular" style="color:black"> 
                                    CONFIRMATION
                                </v-card-title>
                                <div style="border-left:1px solid #C1C8CC;" @click="close_cancelDialog()">
                                    <v-icon style="cursor: pointer;" class="pa-2" >close</v-icon>
                                </div>
                            </v-layout>
                        </v-card>
                    <v-flex >
                        
                    
                    </v-flex>
                    <v-card-text >
                             Details entered on the page will be lost if not saved
                    </v-card-text>
                    <v-card-actions justify-start>
                        
                        
                        
                        <v-btn dark  tile color="black" @click="confirmDialogNext = false" class="text-capitalize px-3 mx-2" small><v-icon dark small>cancel</v-icon> <v-divider vertical class="mx-2"></v-divider>Cancel</v-btn>

                        <!-- <v-btn depressed small color="secondary" class="rounded-0"  >
                            <v-icon dark right>check_circle</v-icon>
                        </v-btn> -->
                        <v-btn @click="selectedCompFromPage(nextTabsName)" dark  tile color="#23B1A9" class="text-capitalize px-3 ml-2" small><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider> OK</v-btn>

                    </v-card-actions>
                </v-card>
            </v-dialog>

            <div v-if="docen">
                 <docup 
                    @closedoc="docen = false"    
                    @getdocslist="doc_for_creation"
                    @deletenow="deletelocal" 
                    :setdoc="{docen:docen,provider_data:$store.state.empanelProviderData.empanelment,
                    type:'edit', updocs:this.create_doc_arr,from:'emp' }" >
                 </docup>
            </div>

        <div class="mx-2 pa-5" style="background-color: #F7F7F7; display:flex;justify-content:space-between"  >
                <v-layout style="flex:1" justify-end >
                    <!-- <v-btn dark @click="uploadBtn()" tile color="#E46A25" class="text-capitalize px-3 mx-2" small><v-icon dark small>cloud_upload</v-icon> <v-divider vertical class="mx-2"></v-divider>Upload Documents</v-btn> -->
                       <v-btn  @click="uploadBtn()" dark  tile color="#152F38"   class="text-capitalize px-3 mx-2" small><v-icon dark small>cloud_upload</v-icon> <v-divider vertical class="mx-2"></v-divider> Upload Documents</v-btn>
                    <v-btn dark @click="cancelData()" tile color="#E46A25" class="text-capitalize px-3 mx-2" small><v-icon dark small>cancel</v-icon> <v-divider vertical class="mx-2"></v-divider>Cancel</v-btn>
                    <v-btn dark @click="clearData()" tile color="#D1121B" class="text-capitalize px-3 mx-2" small><v-icon dark small>delete</v-icon> <v-divider vertical class="mx-2"></v-divider>Clear</v-btn>
                    <v-btn dark @click="saveData()" tile color="#23B1A9" class="text-capitalize px-3 ml-2" small><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider> Save</v-btn>
                    <v-btn dark  @click="nextBtn()" tile color="black" class="text-capitalize px-3 ml-4" small><v-icon dark small>arrow_forward</v-icon> <v-divider vertical class="mx-2"></v-divider> Next</v-btn>
                </v-layout>
            </div>
        </div>
        
    </v-app>
</template>

<script>
import moment from 'moment';
import docup from '../ProviderRegistrationClaims/docup'
const { required, minLength, alpha, email,helpers } = require('vuelidate/lib/validators/')

export default {
    inject : ['selectedCompFromPage', 'arrToNavCancel'],
    components:{
        docup
    },

    computed: {
        cmputdqualityteachingvalidfromjs(){
            return this.qualityteachingregvalidfrom ? moment(this.qualityteachingregvalidfrom).format("DD-MM-YYYY"): ''
        },
        cmputdqualityteachingvaliduptojs(){
            return this.qualityteachingregvalidupto ? moment(this.qualityteachingregvalidupto).format("DD-MM-YYYY"): ''
        },
        cmputdNablvalidfromjs(){
            return this.Nablregvalidfrom ? moment(this.Nablregvalidfrom).format("DD-MM-YYYY"): ''
        },
        cmputdNablvaliduptojs(){
            return this.Nablregvalidupto ? moment(this.Nablregvalidupto).format("DD-MM-YYYY"): ''
        },
        cmputdJcivalidfromjs(){
            return this.Jciregvalidfrom ? moment(this.Jciregvalidfrom).format("DD-MM-YYYY"): ''
        },
        cmputdJcivaliduptojs(){
            return this.Jciregvalidupto ? moment(this.Jciregvalidupto).format("DD-MM-YYYY"): ''
        },

        nabhValidFrom(){
            const errors = []
            if(this.enableNabhFullAccre)  return errors
            if (!this.$v.qualityteachingregvalidfrom.$dirty) return errors
            !this.$v.qualityteachingregvalidfrom.required && errors.push('This field is required')
            return errors
        },

        nabhValidUpto(){
            const errors = []
            if(this.enableNabhFullAccre)  return errors
            if (!this.$v.qualityteachingregvalidupto.$dirty) return errors
            !this.$v.qualityteachingregvalidupto.required && errors.push('This field is required')
            return errors
        },

        nablValidFrom(){
            const errors = []
            if(this.enableNablFullAccre)  return errors
            if (!this.$v.Nablregvalidfrom.$dirty) return errors
            !this.$v.Nablregvalidfrom.required && errors.push('This field is required')
            return errors
        },

        nablValidUpto(){
            const errors = []
            if(this.enableNablFullAccre)  return errors
            if (!this.$v.Nablregvalidupto.$dirty) return errors
            !this.$v.Nablregvalidupto.required && errors.push('This field is required')
            return errors
        },

        jciValidFrom(){
            const errors = []
            if(this.enableJciAccred)  return errors
            if (!this.$v.Jciregvalidfrom.$dirty) return errors
            !this.$v.Jciregvalidfrom.required && errors.push('This field is required')
            return errors
        },

        jciValidUpto(){
            const errors = []
            if(this.enableJciAccred)  return errors
            if (!this.$v.Jciregvalidupto.$dirty) return errors
            !this.$v.Jciregvalidupto.required && errors.push('This field is required')
            return errors
        },

        dnbNoOfSeats(){
            const errors = []
            if (!this.$v.noofseats.$dirty) return errors
            !this.$v.noofseats.required && errors.push('This field is required')
            return errors
        },

        noOfSeatGrad(){
            const errors = []
            // if(this.enableMedicalCollg)  return errors
            if (!this.$v.noofseatsGrad.$dirty) return errors
            !this.$v.noofseatsGrad.required && errors.push('This field is required')
            return errors
        },

        noOfSeatPG(){
            const errors = []
            if (!this.$v.noofseatsPg.$dirty) return errors
            !this.$v.noofseatsPg.required && errors.push('This field is required')
            return errors
        },
        
                                                            

    },
    validations: {
        qualityteachingregvalidfrom: { required },
        qualityteachingregvalidupto: { required},
        Nablregvalidfrom: { required},
        Nablregvalidupto: { required},
        Jciregvalidfrom: { required },
        Jciregvalidupto: { required},
        noofseats: { required },
        noofseatsGrad: { required },
        noofseatsPg: { required }
    },

    created(){
        let empanelment = this.$store.state.empanelProviderData.empanelment
        let provider = this.$store.state.empanelProviderData.provider
        // console.log('(((((((((( QUALITY__STOREE ))))))))))',this.$store.state.empanelProviderData)
        this.getEmpDetails(empanelment._id)
    },

    data() {
        return {
            create_doc_arr:[],
            docen:false,
            nextTabsName: '',
            confirmDialogCancel:false,
            confirmDialogNext:false,
            
            enableNablFullAccre:true,
            enableNabhFullAccre:true,
            enableJciAccred:true,
            enableMedicalCollg:true,
            enableTrainInst:true,
            fullAccreditation:'No',
            progressLevel:'No',
            preAccredEntryLevel:'No',
            qualityteachingNumber:'',
            qualityteachingName:'',
            qualityteachingvalidfrom:'',
            qualityteachingregvalidfrom:'',
            qualityteachingvalidupto:'',
            qualityteachingregvalidupto:'',
            nablfullAccreditation:'No',
            nablaccredNumber:'',
            nablName:'',
            Nablvalidfrom:'',
            Nablregvalidfrom:'',
            Nablvalidupto:'',
            Nablregvalidupto:'',
            jciAccreditation:'No',
            jciaccredNumber:'',
            jciName:'',
            Jcivalidfrom:'',
            Jciregvalidfrom:'',
            Jcivalidupto:'',
            Jciregvalidupto:'',
            medicalCollege:'No',
            noofseatsGrad:0,
            noofseatsPg:0,
            trainingInst:'No',
            noofseats:0,
        }
    },

    methods:{
        uploadBtn(){
            if(Object.keys(this.$store.state.empanelProviderData).length == 0){
                this.showToast('Please, select a provider to Upload documents ', this.TOST().WARNING);
            }else{
                this.docen = true
            }
        },
        getEmpDetails(userId){
            this.GET(2,'empanelment/empanelment-details-by-id/' + userId,(res,error)=>{
                // console.log('(((((((((( EMPPPP   RESPPPP ))))))))))',res.data.data)
                try{
                    if(!error){
                        let _resp = res.data.data
                        if(res.data.data.providerType === 'Dental'){
                                this.nextTabsName = 'PrvdrEmpnlmntFacilities'
                        }else if(res.data.data.providerType !== 'Dental'){
                            this.nextTabsName = 'ProviderEmpanelmentLabRadiology'
                        }

                        // console.log('(((((((((( EMPPPP   RESPPPP ))))))))))',_resp)
                        // this.$store.commit('EMPDOCS', res.data.data.documents);
                        this.fullAccreditation = _resp.hasOwnProperty('nabh') == true ?  this.checkValid(_resp.nabh.fullAccreditation) : 'No'
                        this.progressLevel = _resp.hasOwnProperty('nabh') == true ?  this.checkValid(_resp.nabh.progressiveLevel) : 'No'
                        this.preAccredEntryLevel = _resp.hasOwnProperty('nabh') == true ?  this.checkValid(_resp.nabh.preAccEntryLevelCertStanderd) : 'No'
                        this.nablfullAccreditation = _resp.hasOwnProperty('nabl') == true ?  this.checkValid(_resp.nabl.fullAccreditation) : 'No'
                        this.jciAccreditation = _resp.hasOwnProperty('jcl') == true ?  this.checkValid(_resp.jcl.fullAccreditation) : 'No'
                        this.medicalCollege = _resp.hasOwnProperty('medicalCollege') == true ?  this.checkValid(_resp.medicalCollege) : 'No'
                        this.trainingInst = _resp.hasOwnProperty('trainingInstitute') == true ?  this.checkValid(_resp.trainingInstitute) : 'No'
                        this.fullAccredRadio(this.fullAccreditation)
                        this.progressLevelRadio(this.progressLevel)
                        this.preAccredEntryLevelRadio(this.preAccredEntryLevel)
                        this.medicalCollegeRadio(this.medicalCollege)
                        this.trainingInstRadio(this.trainingInst)
                        this.jciAccreditationRadio(this.jciAccreditation)
                        this.nablfullAccreditationRadio(this.nablfullAccreditation)

                        this.qualityteachingNumber = _resp.hasOwnProperty('nabh') == true ?  this.checkValid(_resp.nabh.accreditationNum) : ''
                        this.qualityteachingName = _resp.hasOwnProperty('nabh') == true ?  this.checkValid(_resp.nabh.name) : ''
                        this.qualityteachingregvalidfrom = _resp.hasOwnProperty('nabh') == true ?  this.checkValid(_resp.nabh.validFrom) : ''
                        this.qualityteachingregvalidupto = _resp.hasOwnProperty('nabh') == true ?  this.checkValid(_resp.nabh.validTo) : ''

                        this.nablaccredNumber = _resp.hasOwnProperty('nabl') == true ?  this.checkValid(_resp.nabl.accreditationNum) : ''
                        this.nablName = _resp.hasOwnProperty('nabl') == true ?  this.checkValid(_resp.nabl.name) : ''
                        this.Nablregvalidfrom = _resp.hasOwnProperty('nabl') == true ?  this.checkValid(_resp.nabl.validFrom) : ''
                        this.Nablregvalidupto = _resp.hasOwnProperty('nabl') == true ?  this.checkValid(_resp.nabl.validTo) : ''

                        this.jciaccredNumber = _resp.hasOwnProperty('jcl') == true ?  this.checkValid(_resp.jcl.accreditationNum) : ''
                        this.jciName = _resp.hasOwnProperty('jcl') == true ?  this.checkValid(_resp.jcl.name) : ''
                        this.Jciregvalidfrom = _resp.hasOwnProperty('jcl') == true ?  this.checkValid(_resp.jcl.validFrom) : ''
                        this.Jciregvalidupto = _resp.hasOwnProperty('jcl') == true ?  this.checkValid(_resp.jcl.validTo) : ''

                        this.noofseatsGrad = _resp.hasOwnProperty('noOfSeatsGraduation') == true ?  this.checkValidNo(_resp.noOfSeatsGraduation) : 0
                        this.noofseatsPg = _resp.hasOwnProperty('noOfSeatsPG') == true ?  this.checkValidNo(_resp.noOfSeatsPG) : 0
                        this.noofseats = _resp.hasOwnProperty('noOfSeatsTrainingInstitute') == true ?  this.checkValidNo(_resp.noOfSeatsTrainingInstitute) : 0

                        this.$store.commit("EMPANEL_TAB_STATUS", _resp);   
                    }else{
                        // this.showToast(err.response.data.msg ,this.TOST().ERROR);
                    } 
                }catch{}
            })
        },
        
        checkValid(data){
            try{
                if (data == "" || data === "undefined" || data === null || data === "undefined" || data === undefined) {
                    return ""
                } else {
                    return data;
                }
            }catch(err) {}
        },
        checkValidNo(data){
            try{
                if (data == "" || data === "undefined" || data === null || data === "undefined" || data === undefined) {
                    return 0
                } else {
                    return data;
                }
            }catch(err) {}
        },

        fullAccredRadio(event){
            if(event === 'Yes'){
                this.fullAccreditation = 'Yes';
                this.progressLevel = 'No';
                this.preAccredEntryLevel = 'No';
                this.enableNabhFullAccre = false
                    
            }else{
                this.fullAccreditation = 'No';
                this.enableNabhFullAccre = true
                // this.progressLevel = 'Yes';
                // this.preAccredEntryLevel = 'Yes';
            }
        },

        progressLevelRadio(event){
            if(event === 'Yes'){
                this.fullAccreditation = 'No';
                this.progressLevel = 'Yes';
                this.preAccredEntryLevel = 'No';
                this.enableNabhFullAccre = false
                    
            }else{
                this.enableNabhFullAccre = true
                // this.fullAccreditation = 'Yes';
                this.progressLevel = 'No';
                // this.preAccredEntryLevel = 'Yes';
            }
        },

        preAccredEntryLevelRadio(event){
            if(event === 'Yes'){
                this.fullAccreditation = 'No';
                this.progressLevel = 'No';
                this.preAccredEntryLevel = 'Yes';
                this.enableNabhFullAccre = false
                    
            }else{
                this.enableNabhFullAccre = true
                // this.fullAccreditation = 'Yes';
                // this.progressLevel = 'Yes';
                this.preAccredEntryLevel = 'No';
            }
        },

        nablfullAccreditationRadio(event){
            // event === 'Yes' ? this.enableNablFullAccre = false : this.enableNablFullAccre = true
            if(event === 'Yes'){
                this.enableNablFullAccre = false
                    
            }else{
                this.enableNablFullAccre = true
                this.nablaccredNumber = ''
                this.nablName = ''
                this.Nablregvalidfrom = ''
                this.Nablregvalidupto = ''
            }

        },

        jciAccreditationRadio(event){
            // event === 'Yes' ? this.enableJciAccred = false : this.enableJciAccred = true
            if(event === 'Yes'){
                this.enableJciAccred = false
                    
            }else{
                this.enableJciAccred = true
                this.jciaccredNumber = ''
                this.jciName = ''
                this.Jciregvalidfrom = ''
                this.Jciregvalidupto = ''
            }

        },

        medicalCollegeRadio(event){
            // event === 'Yes' ? this.enableMedicalCollg = false : this.enableMedicalCollg = true
            if(event === 'Yes'){
                this.enableMedicalCollg = false
                    
            }else{
                this.enableMedicalCollg = true
                this.noofseatsGrad = 0
                this.noofseatsPg = 0
            }

        },

        trainingInstRadio(event){
            // event === 'Yes' ? this.enableTrainInst = false : this.enableTrainInst = true
            if(event === 'Yes'){
                this.enableTrainInst = false
                    
            }else{
                this.enableTrainInst = true
                this.noofseats = 0
            }

        },

        
         nextBtn(){
                    console.log("%c this is data for next btn on OwnershipTab","color:green",this.$store.state.empanelmentTabStatus);
                    if(this.$store.state.empanelmentTabStatus.qualityAndTeachingStatus === false){
                        this.confirmDialogNext = true
                    }else{
                         this.selectedCompFromPage(this.nextTabsName)
                    }
                },

        cancelData(){
            this.confirmDialogCancel = true
        },
        close_cancelDialog(){
            this.confirmDialogCancel = false
            this.confirmDialogNext = false
        },
        clearData(){
            this.fullAccreditation = 'No'
            this.progressLevel = 'No'
            this.preAccredEntryLevel = 'No'
            this.nablfullAccreditation = 'No'
            this.jciAccreditation = 'No'
            this.medicalCollege = 'No'
            this.trainingInst = 'No'
            this.qualityteachingNumber = ''
            this.qualityteachingName = ''
            this.qualityteachingregvalidfrom = ''
            this.qualityteachingregvalidupto = ''
            this.nablaccredNumber = ''
            this.nablName = ''
            this.Nablregvalidfrom = ''
            this.Nablregvalidupto = ''
            this.jciaccredNumber = ''
            this.jciName = ''
            this.Jciregvalidfrom = ''
            this.Jciregvalidupto = ''
            this.noofseatsGrad = 0
            this.noofseatsPg = 0
            this.noofseats = 0

        },

        saveData(){
            let userID = this.$store.state.empanelProviderData.empanelment._id
            let _proviType = this.$store.state.empanelProviderData.empanelment.providerType

            let formData = {
                _id: userID,
                providerType: _proviType,
                nabh: {
                    fullAccreditation: this.fullAccreditation,
                    progressiveLevel: this.progressLevel,
                    preAccEntryLevelCertStanderd: this.preAccredEntryLevel,
                    accreditationNum: this.qualityteachingNumber,
                    name: this.qualityteachingName,
                    validFrom: this.qualityteachingregvalidfrom,
                    validTo: this.qualityteachingregvalidupto
                },
                nabl: {
                    fullAccreditation: this.nablfullAccreditation,
                    accreditationNum: this.nablaccredNumber,
                    name: this.nablName,
                    validFrom: this.Nablregvalidfrom,
                    validTo: this.Nablregvalidupto
                },
                jcl: {
                    fullAccreditation: this.jciAccreditation,
                    accreditationNum: this.jciaccredNumber,
                    name: this.jciName,
                    validFrom: this.Jciregvalidfrom,
                    validTo: this.Jciregvalidupto
                },
                medicalCollege: this.medicalCollege,
                noOfSeatsGraduation:parseInt(this.noofseatsGrad),
                noOfSeatsPG:parseInt(this.noofseatsPg),
                trainingInstitute: this.trainingInst,
                noOfSeatsTrainingInstitute:parseInt(this.noofseats)
            }
            
            this.$v.$touch();

            if(this.medicalCollege == 'Yes'){
                if(this.noofseatsGrad == '' && this.noofseatsPg == ''){
                    this.showToast('Enter seats in medical college', this.TOST().WARNING);
                    return
                }
                if(this.noofseatsGrad + this.noofseatsPg == 0){
                    this.showToast('Total of both Graduation and PG seats cannot be zero', this.TOST().WARNING);
                    return
                }
                
                if(this.$v.noofseatsGrad.$invalid || this.$v.noofseatsPg.$invalid){
                    this.showToast('Please fill all required fields', this.TOST().WARNING);
                    return
                }
            }
            if(this.trainingInst == 'Yes'){
                if(this.noofseats == 0){
                    this.showToast('Enter seats in Training Institute (DNB)', this.TOST().WARNING);
                    return
                }
                if(this.noofseats == 0){
                    this.showToast('Seats cannot be zero', this.TOST().WARNING);
                    return
                }
            }

            if(this.enableNabhFullAccre == false){
                if(this.$v.qualityteachingregvalidfrom.$invalid || this.$v.qualityteachingregvalidupto.$invalid){
                    this.showToast('Please fill all required fields', this.TOST().WARNING);
                    return
                }
            }
            if(this.enableNablFullAccre == false){
                if(this.$v.Nablregvalidfrom.$invalid || this.$v.Nablregvalidupto.$invalid){
                    this.showToast('Please fill all required fields', this.TOST().WARNING);
                    return
                }
            }
            if(this.enableJciAccred == false){
                if(this.$v.Jciregvalidfrom.$invalid || this.$v.Jciregvalidupto.$invalid){
                    this.showToast('Please fill all required fields', this.TOST().WARNING);
                    return
                }
            }

            this.PUT(2,'empanelment/save-quality-and-teaching',formData ,  ( res, error) => {
                if(!error){
                    if(res.data.statusCode === 200){
                        this.showToast(res.data.msg, this.TOST().SUCCESS);
                        this.getEmpDetails(userID)
                    }else{
                        this.showToast(res.data.msg ,this.TOST().ERROR); 
                    }
                }else{
                    this.showToast(error.response.data.msg, this.TOST().WARNING);
                }
                // console.log('RESPONSE___________',res)
                // console.log('error___________',error.response)
                // this.showToast(res.msg, this.TOST().WARNING);
            })
        },

    },


}
</script>

<style>

.cust-rmvpd-psins3 .v-text-field.v-text-field--enclosed>.v-input__control>.v-input__slot {
    padding-right: 0px !important;
}

.cust_pr2 .v-text-field .v-input__slot,
.v-text-field--outline .v-input__slot {
    min-height: 42px !important;
    display: flex !important;
    align-items: center !important;
    /* border:1px red solid; */
}

.cstm-clr-pr3{
    color:#646666 !important;
    margin-bottom: 5px !important;
    font-size: 13px !important;
}

.cust_pd1 .v-text-field .v-input__slot,
.v-text-field--outline .v-input__slot {
    min-height: 42px !important;
    display: flex !important;
    align-items: center !important;
    /* border:1px red solid; */
}

.cust-rmvpd-pr1 .v-text-field.v-text-field--enclosed>.v-input__control>.v-input__slot {
    padding: 0px !important;
}

.cust-rmvpd-psins_quick .v-text-field.v-text-field--enclosed>.v-input__control>.v-input__slot {
    padding-right: 0px !important;
}

.cust_pr_quick .v-text-field .v-input__slot,
.v-text-field--outline .v-input__slot {
    min-height: 42px !important;
    display: flex !important;
    align-items: center !important;
    /* border:1px red solid; */
}

.cust_pr_quick .v-input .v-label {
    font-size: 13px !important;
    
}


.cust_pr_quick .v-input{
    font-size: 13px !important;
}


.cstm-clr-pr-quick{
    color:#646666 !important;
    margin-bottom: 3px !important;
    font-size: 13px !important;
    
}

.cust_pd_quick .v-text-field .v-input__slot,
.v-text-field--outline .v-input__slot {
    min-height: 42px !important;
    display: flex !important;
    align-items: center !important;
    /* border:1px red solid; */
}

.cust-rmvpd-pr-quick .v-text-field.v-text-field--enclosed>.v-input__control>.v-input__slot {
    padding: 0px !important;
}

</style>