<template>
    <v-app>
        <v-container class="containerStyle" style="padding:1.5rem; margin:0; max-width:100%">
            <h3>{{top_Heading}} Service Provider Master</h3>
            <v-form ref="create_Service_Provider" lazy-validation @submit.prevent="onSubmitHandler">
                <v-expansion-panels v-model="panels" multiple>
                    <v-expansion-panel>
                        <v-expansion-panel-header color="#1EBBD9">
                             <span class="page-title-small white--text" >Service Provider Details</span>
                                <template v-slot:actions>
                                    <v-icon class="white--text">
                                    {{panels.indexOf(0) !== -1 ? "mdi-minus-box" : "mdi-plus-box"}}
                                    </v-icon>
                                </template>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-layout wrap class="pt-4 form-groups-container">
                                <v-flex class="form-group">
                                    <label class="form-label">
                                        Service Provider Code
                                    </label>
                                    <v-text-field solo dense class="form-control" disabled placeholder="Enter..." v-model="serviceProvideObj.serviceProviderId"></v-text-field>
                                </v-flex>
                                <v-flex class="form-group">
                                    <label class="form-label">Line Of Business <span class="red--text">*</span></label>
                                    <v-select solo dense class="form-control" multiple disabled placeholder="Select" :rules="[(v)=>requiredField(v)]" :items="lob_items" v-model="serviceProvideObj.lob"></v-select>
                                </v-flex>
                                <v-flex class="form-group">
                                    <label class="form-label">Is External Service Provider <span class="red--text">*</span></label>
                                    <v-radio-group row  class="form-control" disabled v-model="serviceProvideObj.isExternalServiceProvider" :rules="[(v)=>requiredField(v)]" @change="changeServiceProvideValues">
                                        <v-radio label="YES" value="Yes"></v-radio>
                                        <v-radio label="No" value="No"></v-radio>
                                    </v-radio-group>
                                </v-flex>
                                <v-flex class="form-group">
                                    <label class="form-label">Name <span class="red--text">*</span></label>
                                    <v-text-field solo dense disabled v-model="serviceProvideObj.serviceProviderDetails.employeeName" placeholder="Enter..." class="form-control" :rules="[(v)=>requiredField(v)]"></v-text-field>
                                </v-flex>
                                <v-flex class="form-group">
                                    <label class="form-label">
                                        Login User Id 
                                    </label>
                                    <v-text-field solo dense class="form-control" disabled v-model="serviceProvideObj.serviceProviderDetails.userId" placeholder="Enter..."></v-text-field>
                                </v-flex>
                                <v-flex class="form-group">
                                    <label class="form-label"> Service Provider Type <span class="red--text">*</span></label>
                                    <v-select solo dense class="form-control" disabled placeholder="Select" :rules="[(v)=>requiredField(v)]" :items="serviceProvider_Items" v-model="serviceProvideObj.serviceProviderType"></v-select>
                                </v-flex>
                                <v-flex class="form-group">
                                    <label class="form-label">Availability At Multiple Location <span class="red--text">*</span></label>
                                    <v-select class="form-control"  disabled placeholder="Select" :rules="[(v)=>requiredField(v)]" solo dense :items="['Yes','No']" v-model="serviceProvideObj.availablityAtMultipleLocation">
                                        <template v-slot:append>
                                            <v-icon class="blueiconstyle" @click="multipleAddress">mdi-plus</v-icon>
                                        </template>
                                    </v-select>
                                </v-flex>
                                <v-flex class="form-group">
                        <label class="form-label">
                            Address Line 1 <span><strong class="red--text">*</strong></span>
                        </label>
                        <v-text-field
                        solo
                        dense
                        :rules="[(v)=>requiredField(v)]"
                        disabled
                        v-model="serviceProvideObj.spAddress[0].spAddressLineOne"
                        class="form-control"
                        maxlength="50"
                        
                        placeholder="Enter..."
                        >
                        </v-text-field>
                    </v-flex>

                    <v-flex class="form-group">
                        <label class="form-label">
                            Address Line 2 <span><strong class="red--text">*</strong></span>
                        </label>
                        <v-text-field
                        solo
                        dense
                        v-model="serviceProvideObj.spAddress[0].spAddressLineTwo"
                        class="form-control"
                        :rules="[(v)=>requiredField(v)]"
                        maxlength="50"
                        placeholder="Enter..."
                        disabled

                        >
                        </v-text-field>
                    </v-flex>

                    <v-flex class="form-group">
                        <label class="form-label">
                            Address Line 3 <span><strong class="red--text">*</strong></span>
                        </label>
                        <v-text-field
                        solo
                        dense
                        v-model="serviceProvideObj.spAddress[0].spAddressLineThree"
                        class="form-control"
                        :rules="[(v)=>requiredField(v)]"
                        maxlength="50"
                        placeholder="Enter..."
                        disabled
                        >
                        </v-text-field>
                    </v-flex>
                                <v-flex class="form-group">
                                    <label class="form-label">Pincode <span class="red--text">*</span></label>
                                    <v-text-field solo dense class="form-control" :rules="[(v)=>requiredField(v),(v)=>matchRegex(v,'nums')]" placeholder="Enter..." v-model="serviceProvideObj.spAddress[0].spPincode"  :maxlength="6" 
                                     disabled></v-text-field>
                                </v-flex>
                                <v-flex class="form-group">
                                    <label class="form-label">State <span class="red--text">*</span></label>
                                    <v-text-field solo dense class="form-control" disabled :rules="[(v)=>requiredField(v)]" placeholder="Enter..."  v-model="serviceProvideObj.spAddress[0].spState"></v-text-field>
                                </v-flex>
                                <v-flex class="form-group">
                                    <label class="form-label">District <span class="red--text">*</span></label>
                                    <v-text-field solo dense class="form-control" disabled :rules="[(v)=>requiredField(v)]"  placeholder="Enter..."  v-model="serviceProvideObj.spAddress[0].spDistrict"></v-text-field>
                                </v-flex>
                                <v-flex class="form-group">
                                    <label class="form-label">City <span class="red--text">*</span></label>
                                    <v-text-field solo dense class="form-control" disabled :rules="[(v)=>requiredField(v)]"  placeholder="Enter..."  v-model="serviceProvideObj.spAddress[0].spCity"></v-text-field>
                                </v-flex>
                                <v-flex class="form-group">
                                    <label class="form-label">PAN Number </label>
                                    <v-text-field solo dense class="form-control" disabled v-model="serviceProvideObj.panNumber" :maxlength="10" placeholder="Enter..." :value="computedPAN"></v-text-field>
                                </v-flex>
                                <v-flex class="form-group">
                                    <label class="form-label">Aadhar ID (Last 4 Digits)</label>
                                    <v-text-field solo dense class="form-control" disabled v-model="serviceProvideObj.aadharId" :rules="[ (v) => matchRegex(v, 'nums')]" :maxlength="4"
                        placeholder="Enter..."></v-text-field>
                                </v-flex>
                                
                                <v-flex class="form-group">
                        <label class="form-label">
                            Geo Location Code
                        </label>
                        <v-text-field
                        solo
                        dense
                        :rules="[(v)=>matchRegex(v,'nums')]"
                        v-model="serviceProvideObj.geoLocationCode"

                        class="form-control"
                        maxlength="50"
                        placeholder="Enter..."
                        disabled
                        >
                        </v-text-field>
                    </v-flex>
                                <v-flex class="form-group">
                        <v-layout>
                            <v-flex>
                                <label class="form-label">
                                    STD<span class="red--text">*</span>
                                </label>
                                <v-text-field
                                    class="form-control mr-2"
                                    style="max-width:110px"
                                    solo
                                    dense
                                    disabled
                                    v-model="serviceProvideObj.landlineNumber.std"
                                    :rules="[(v)=>matchRegex(v,'nums')]" 
                                    :maxlength="5"
                                    placeholder="..."
                                    prepend-inner-icon=""
                                    
                                    >
                                    <template v-slot:prepend-inner>
                                        <v-icon class="iconstyle">mdi-phone</v-icon>
                                    </template>
                                </v-text-field>
                            </v-flex>

                            <v-flex >
                                <label class="form-label">
                                LandLine Number<span class="red--text">*</span>
                                </label>
                                <!-- <template v-slot:activator="{ on, attrs }">  -->
                                <v-text-field
                                    placeholder="Enter..."
                                    solo
                                    dense
                                    v-model="serviceProvideObj.landlineNumber.number"
                                    :maxlength="10"
                                    disabled
                                    :rules="[(v)=>requiredField(v),(v)=>matchRegex(v,'nums')]" 
                                              
                                    class="form-control"
                                    
                                >
                                    <!-- <template v-slot:append>
                                    <v-icon class="blueiconstyle" >mdi-phone-plus</v-icon>
                                    </template> -->
                                </v-text-field>
                            </v-flex>
                        </v-layout>
                    </v-flex>
                    <v-flex class="form-group">
                        <label class="form-label"> GST Frequency Flag <span class="red--text">*</span></label>
                        <v-select solo :items="gstFrequencyFlag_items" disabled dense class="form-control" placeholder="Select" v-model="serviceProvideObj.gstFrequencyFlag"></v-select>
                    </v-flex>
                    <v-flex class="form-group">
                                    <label class="form-label">GST Number <span class="red--text">*</span></label>
                                    <v-text-field solo dense class="form-control" disabled placeholder="Enter..." :rules="[(v)=>matchRegex(v,'alphanum')]" :maxlength="15" v-model="serviceProvideObj.gstinNumber"></v-text-field>
                                </v-flex>
                    </v-layout>
                    <v-layout wrap class="pt-4 form-groups-container">
                    <v-flex class="form-group">
                        <v-layout>
                            <v-flex>
                                <label class="form-label">
                                    ISD<span class="red--text">*</span>
                                </label>
                                <v-text-field
                                    class="form-control mr-2"
                                    style="max-width:110px"
                                    solo
                                    dense
                                    disabled
                                    v-model="serviceProvideObj.mobileNumber[0].isd"
                                    :rules="[(v)=>matchRegex(v,'nums')]" 
                                    :maxlength="5"
                                    placeholder="..."
                                    prepend-inner-icon=""
                                    
                                    >
                                    <template v-slot:prepend-inner>
                                        <v-icon class="iconstyle">mdi-phone</v-icon>
                                    </template>
                                </v-text-field>
                            </v-flex>

                            <v-flex >
                                <label class="form-label">
                                Mobile Number<span class="red--text">*</span>
                                </label>
                                <!-- <template v-slot:activator="{ on, attrs }">  -->
                                <v-text-field
                                    placeholder="Enter..."
                                    solo
                                    dense
                                    v-model="serviceProvideObj.mobileNumber[0].number"
                                    :maxlength="10"
                                   disabled
                                    :rules="[(v)=>requiredField(v),(v)=>matchRegex(v,'nums')]" 
                                              
                                    class="form-control"
                                    
                                >
                                    <template v-slot:append>
                                    <v-icon class="blueiconstyle" @click="mobile_Dialog=!mobile_Dialog" >mdi-phone-plus</v-icon>
                                    </template>
                                </v-text-field>
                            </v-flex>
                        </v-layout>
                        </v-flex>
                            <v-flex class="form-group">
                                <label class="form-label">Email-ID <span class="red--text">*</span></label>
                                <v-text-field disabled solo dense class="form-control" placeholder="Enter..." v-model="email_Obj[0].email" :rules="[(v)=>requiredField(v),(v) => validateEmail(v, 'Email ID')]">
                                    <template v-slot:append>
                                        <v-icon class="blueiconstyle" @click="email_Dialog=!email_Dialog">mdi-email-plus</v-icon>
                                    </template>
                                </v-text-field>
                            </v-flex>
                            <v-flex class="form-group">
                                <label class="form-label">Contact Person Name</label>
                                <v-text-field solo dense disabled class="form-control" :maxlength="50" placeholder="Enter..." v-model="serviceProvideObj.contactPerson.name"></v-text-field>
                            </v-flex>
                            <v-flex class="form-group">
                                <label class="form-label">Contact Person Number</label>
                                <v-text-field solo dense disabled class="form-control" :maxlength="50" placeholder="Enter..." v-model="serviceProvideObj.contactPerson.number"></v-text-field>
                            </v-flex>
                            <v-flex class="form-group">
                                <label class="form-label">TDS Type <span class="red--text">*</span></label>
                                <v-select solo disabled dense class="form-control" placeholder="Select" :rules="[(v)=>requiredField(v)]" :items="tds_items" v-model="serviceProvideObj.tdsType"></v-select>
                            </v-flex>

                            <!-- <v-flex class="form-group">
                                    <label class="form-label">IRDA LOB</label>
                                    <v-text-field solo dense class="form-control" :disabled="isView" v-model="serviceProvideObj.irdaLob" :rules="[ (v) => matchRegex(v, 'alphanum')]" 
                        placeholder="Enter..."></v-text-field>
                                    </v-flex> -->


                            <v-flex class="form-group">
                                <v-checkbox label="Differentiate TDS" class="form-control" disabled v-model="serviceProvideObj.diffTDS"></v-checkbox>
                            </v-flex>
                            <v-flex class="form-group" v-show="serviceProvideObj.serviceProviderType=='External Surveyor'||serviceProvideObj.serviceProviderType=='In - House with License'">
                                <label class="form-label">License number <span class="red--text" v-if="serviceProvideObj.serviceProviderType=='External Surveyor'||serviceProvideObj.serviceProviderType=='In - House with License'">*</span></label>
                                <v-text-field disabled class="form-control" solo dense 
                                :rules="serviceProvideObj.serviceProviderType=='External Surveyor'||serviceProvideObj.serviceProviderType=='In - House with License'?[(v)=>matchRegex(v,'alphanum'),(v)=>requiredField(v)]:[]" 
                                v-model="serviceProvideObj.licenseNumber" placeholder="Enter..."></v-text-field>
                            </v-flex>
                            <v-flex class="form-group" v-show="serviceProvideObj.serviceProviderType=='External Surveyor'||serviceProvideObj.serviceProviderType=='In - House with License'">
                  <label class="form-label"
                    >License Registration date <span class="red--text" v-if="serviceProvideObj.serviceProviderType=='External Surveyor'||serviceProvideObj.serviceProviderType=='In - House with License'">*</span>
                   </label>
                  <v-menu
                    v-model="menu"
                    offset-y
                    nudge-top="20"
                    :close-on-content-click="false"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="computedLicenseRegistrationdate"
                        placeholder="DD/MM/YYYY"
                        disabled
                        dense
                        solo
                        readonly
                        v-on="on"
                        @focus="menu = true"
                        @blur="menu = true"
                        v-bind="attrs"
                        class="form-control"
                        append-icon="mdi-menu-down"
                        prepend-inner-icon="mdi-calendar"
                      >
                      </v-text-field>
                    </template>
                    <v-date-picker
                      no-title
                      :pickerDate.sync="pickerDate"
                      :max=getEndDate
                      v-model="serviceProvideObj.licenselicenseDate"
                      @input="menu = false"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-flex>
                <v-flex class="form-group" v-show="serviceProvideObj.serviceProviderType=='External Surveyor'||serviceProvideObj.serviceProviderType=='In - House with License'">
                  <label class="form-label"
                    >License Expiry date
                   <span class="red--text" v-if="serviceProvideObj.serviceProviderType=='External Surveyor'||serviceProvideObj.serviceProviderType=='In - House with License'">*</span>
                  </label>
                  <v-menu
                    v-model="menu1"
                    offset-y
                    nudge-top="20"
                    :close-on-content-click="false"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="computedLicenseExpirydate"
                        placeholder="DD/MM/YYYY"
                        disabled
                        dense
                        solo
                        readonly
                        v-on="on"
                        @focus="menu1 = true"
                        @blur="menu1 = true"
                        v-bind="attrs"
                        class="form-control"
                        append-icon="mdi-menu-down"
                        prepend-inner-icon="mdi-calendar"
                      >
                      </v-text-field>
                    </template>
                    <v-date-picker
                      no-title
                      :pickerDate.sync="pickerDate1"
                      :max=getEndDate
                      v-model="serviceProvideObj.licenseExpiryDate"
                      @input="menu1 = false"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-flex>
                        
                   
                            </v-layout>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                        <v-expansion-panel-header color="#1EBBD9">
                             <span class="page-title-small white--text" >Bank Details</span>
                                <template v-slot:actions>
                                    <v-icon class="white--text">
                                    {{panels.indexOf(1) !== -1 ? "mdi-minus-box" : "mdi-plus-box"}}
                                    </v-icon>
                                </template>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-layout wrap class="pt-4 form-groups-container"> 
                                <v-flex class="form-group">
                                    <v-checkbox label="Bank Details Available" disabled @change="checkBankDetails" v-model="serviceProvideObj.bankDetailsAvailable" class="form-control"></v-checkbox>
                                </v-flex>
                                <v-spacer></v-spacer>
                    <div class="me-3 px-2" v-if="cancelledChequeData.length > 0">
                                    <label :disabled="!isOCRUploaded " class="form-label mt-2 text-right"
                                        @click="cancelChequeView">
                                        <div style="color:#0AB417; font: normal normal medium 12px/14px Roboto;cursor:pointer">
                                            <v-icon color="#0AB417">
                                                mdi-eye
                                            </v-icon>
                                            <span class="ml-2">View Cancelled Cheque</span>
                                        </div>
                                    </label>
                                </div>

                                <div>
                    <p v-show="serviceProvideObj.bankDetailsAvailable" class="teal--text mr-8"  style="cursor:pointer" @dragover="dragoverCancel"  @drop="dropCancel" @click="browseCancel" >
                      <v-icon color="#23B1A9" class="mr-3">mdi-tray-arrow-up</v-icon>Upload Cancelled Cheque
                      <v-file-input style="display:none;" v-model="cancelledCheque"
                        id="cancelchequeupload" label="" dense solo @change="OCRUpload"  disabled
                        accept="image/png,image/jpeg,application/msword,application/pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        /></p>
                                          
                      </div>
                    </v-layout>
                     <v-layout wrap class="pt-4 form-group-container">
                        <v-layout class="py-4 cancel-cheque-view-port" v-if="cancelledChequeFileShow && cancelledChequeData.length > 0">
                                    <v-flex xs12>
                                        <div class="close-cancell-cheque"
                                            @click="cancelledChequeFileShow = !cancelledChequeFileShow">
                                            <v-icon  color="#23B1A9"
            dark style="cursor: pointer;">mdi-close</v-icon>
                                        </div>
                                        <v-layout justify-center>
                                            <object :data="cancelledChequeData[0].fileBase ? cancelledChequeData[0].fileBase:showCheque" width="1000"
                                                height="300"></object>
                                            <!-- <img height="300" :src="investigatorMasterObj.documents.fileBase" /> -->
                                        </v-layout>
                                        <!-- <v-layout justify-center class="mt-2">
                                            <div class="cancel-cheque-success-btn">OCR was successfully completed.</div>
                                        </v-layout> -->
                                    </v-flex>
                                </v-layout>
                    </v-layout>
                            
                        
                        
                    <v-layout wrap class="pt-4 form-group-container">
                      <v-flex class="form-group" v-show="serviceProvideObj.bankDetailsAvailable">
                        <label class="form-label mt-0">&nbsp;</label>
                        <v-checkbox
                        class="form-control"
                        label="Over Written"
                        v-model="serviceProvideObj.overwritten[0].status"
                        :disabled="!cancelledChequeData.length>0 || true"
                        type="checkbox"
                        
                        >
                          </v-checkbox>
                    </v-flex>
                            <v-flex class="form-group">
                                <label class="form-label">IFSC Code 
                                  <span v-if="serviceProvideObj.bankDetailsAvailable"><strong class="red--text">*</strong></span></label>
                                <v-text-field solo dense
                                class="form-control"
                                placeholder="Enter..."
                                :rules="serviceProvideObj.bankDetails==true ? [(v)=>requiredField(v)]:[]"
                                v-model="serviceProvideObj.bankDetails.ifscCode"
                                disabled
                                
                                >
                                </v-text-field>
                            </v-flex>

                            <v-flex class="form-group">
                                <label class="form-label">Bank Name <span v-if="serviceProvideObj.bankDetailsAvailable"><strong class="red--text">*</strong></span></label>
                                <v-text-field solo dense
                                class="form-control"
                                placeholder="Enter..."
                                :rules="serviceProvideObj.bankDetails==true ? [(v)=>requiredField(v)]:[]"
                                v-model="serviceProvideObj.bankDetails.bankName"
                                disabled
                                >
                                </v-text-field>
                            </v-flex>
                            <v-flex class="form-group">
                                <label class="form-label">Account Holder Name<span v-if="serviceProvideObj.bankDetailsAvailable"><strong class="red--text">*</strong></span></label>
                                <v-text-field solo dense
                                class="form-control"
                                placeholder="Enter..."
                                :rules="serviceProvideObj.bankDetails==true ? [(v)=>requiredField(v)]:[]"
                                v-model="serviceProvideObj.bankDetails.accountHolderName"
                                disabled
                                >
                                </v-text-field>
                            </v-flex>
                            <v-flex class="form-group">
                                <label class="form-label">City <span v-if="serviceProvideObj.bankDetailsAvailable"><strong class="red--text">*</strong></span></label>
                                <v-text-field solo dense
                                class="form-control"
                                placeholder="Enter..."
                                v-model="serviceProvideObj.bankDetails.city"
                                :rules="serviceProvideObj.bankDetails==true ? [(v)=>requiredField(v)]:[]"
                                disabled
                                >
                                </v-text-field>
                            </v-flex>

                            <v-flex class="form-group">
                                <label class="form-label">Bank Address1 <span v-if="serviceProvideObj.bankDetailsAvailable"><strong class="red--text">*</strong></span></label>
                                <v-text-field solo dense
                                class="form-control"
                                placeholder="Enter..."
                                v-model="serviceProvideObj.bankDetails.addressLineOne"
                                :rules="serviceProvideObj.bankDetails==true ? [(v)=>requiredField(v)]:[]"
                                disabled
                                >
                                </v-text-field>
                            </v-flex>

                            <v-flex class="form-group">
                                <label class="form-label">Bank Address2 <span v-if="serviceProvideObj.bankDetailsAvailable"><strong class="red--text">*</strong></span></label>
                                <v-text-field solo dense
                                class="form-control"
                                placeholder="Enter..."
                                v-model="serviceProvideObj.bankDetails.addressLineTwo"
                                :rules="serviceProvideObj.bankDetails==true ? [(v)=>requiredField(v)]:[]"
                                disabled
                                >
                                </v-text-field>
                            </v-flex>

                            <v-flex class="form-group">
                                <label class="form-label">Bank Address3 <span v-if="serviceProvideObj.bankDetailsAvailable"><strong class="red--text">*</strong></span></label>
                                <v-text-field solo dense
                                class="form-control"
                                placeholder="Enter..."
                                v-model="serviceProvideObj.bankDetails.addressLineThree"
                                :rules="serviceProvideObj.bankDetails==true ? [(v)=>requiredField(v)]:[]"
                                disabled
                                >
                                </v-text-field>
                            </v-flex>

                            <v-flex class="form-group">
                                <label class="form-label">Branch <span v-if="serviceProvideObj.bankDetailsAvailable"><strong class="red--text">*</strong></span></label>
                                <v-text-field solo dense
                                class="form-control"
                                placeholder="Enter..."
                                v-model="serviceProvideObj.bankDetails.branch"
                                :rules="serviceProvideObj.bankDetails==true ? [(v)=>requiredField(v)]:[]"
                                disabled
                                >
                                </v-text-field>
                            </v-flex>

                            <v-flex class="form-group">
                                <label class="form-label">District <span v-if="serviceProvideObj.bankDetailsAvailable"><strong class="red--text">*</strong></span></label>
                                <v-text-field solo dense
                                class="form-control"
                                placeholder="Enter..."
                                v-model="serviceProvideObj.bankDetails.district"
                                :rules="serviceProvideObj.bankDetails==true ? [(v)=>requiredField(v)]:[]"
                                disabled
                                >
                                </v-text-field>
                            </v-flex>

                            <v-flex class="form-group">
                                <label class="form-label">State <span v-if="serviceProvideObj.bankDetailsAvailable"><strong class="red--text">*</strong></span></label>
                                <v-text-field solo dense
                                class="form-control"
                                placeholder="Enter..."
                                v-model="serviceProvideObj.bankDetails.state"
                                :rules="serviceProvideObj.bankDetails==true ? [(v)=>requiredField(v)]:[]"
                                disabled
                                >
                                </v-text-field>
                            </v-flex>

                            <v-flex class="form-group">
                                <label class="form-label">Pincode <span v-if="serviceProvideObj.bankDetailsAvailable"><strong class="red--text">*</strong></span></label>
                                <v-text-field solo dense
                                class="form-control"
                                placeholder="Enter..."
                                v-model="serviceProvideObj.bankDetails.pincode"
                                :rules="serviceProvideObj.bankDetails==true ? [(v)=>requiredField(v)]:[]"
                                disabled
                                >
                                </v-text-field>
                            </v-flex>

                            <v-flex class="form-group">
                                <label class="form-label">Bank Account Number <span v-if="serviceProvideObj.bankDetailsAvailable"><strong class="red--text">*</strong></span></label>
                                <v-text-field solo dense
                                class="form-control"
                                placeholder="Enter..."
                                @change="checkBankAccount"
                                v-model="serviceProvideObj.bankDetails.accountNumber"
                                :rules="serviceProvideObj.bankDetails==true ? [(v)=>requiredField(v)]:[]"
                                disabled
                                >
                                </v-text-field>
                            </v-flex>

                            <v-flex class="form-group">
                                <label class="form-label">Re-Enter Bank Account Number <span v-if="serviceProvideObj.bankDetailsAvailable"><strong class="red--text">*</strong></span></label>
                                <v-text-field solo dense
                                class="form-control"
                                placeholder="Enter..."
                                :rules="serviceProvideObj.bankDetails==true ? [(v) => requiredField(v),(v) => matchRegex(v, 'alphanum'), (v) => shouldBeSame(bankAccount, v, 'Mismatch Bank Account Number')]:[]"
                                v-model="serviceProvideObj.bankDetails.reEnterAccountNumber"
                                
                                disabled
                                @paste.prevent
                                >
                                </v-text-field>
                            </v-flex>
                            <v-flex class="form-group">
                                <v-checkbox label="Active" class="form-control" v-model="serviceProvideObj.active" disabled></v-checkbox>
                            </v-flex>
                            <v-flex class="form-group">
                                <v-checkbox label="WatchList" class="form-control" v-model="serviceProvideObj.isWatchlist" disabled></v-checkbox>
                            </v-flex>
                            
                            <v-flex class="form-group">
                                <label class="form-label">Account Type <span v-if="serviceProvideObj.bankDetailsAvailable"><strong class="red--text">*</strong></span></label>
                                <v-select solo dense
                                class="form-control"
                                placeholder="Enter..."
                                :items="accountTypes"
                                disabled
                                v-model="serviceProvideObj.bankDetails.accountType"
                                :rules="serviceProvideObj.bankDetails==true ? [(v)=>requiredField(v)]:[]"
                                
                                >
                                </v-select>
                            </v-flex>
                            <v-flex class="form-group">
                                <label class="form-label">IRDA Category</label>
                                <v-select class="form-control" disabled solo dense :items="irda_items" v-model="serviceProvideObj.irdaCategory" placeholder="Select"></v-select>
                            </v-flex>
                            <v-flex class="form-group">
                                <label class="form-label">Surveyor  Category <span v-if="serviceProvideObj.serviceProviderType == 'External Surveyor'" class="red--text">*</span></label>
                                <v-select class="form-control"
                                 disabled 
                                 solo 
                                 dense
                                  :items="supervisor_items"
                                   v-model="serviceProvideObj.surveyorCategory"
                                    placeholder="Select"
                                    :rules="serviceProvideObj.serviceProviderType == 'External Surveyor' ? [(v)=>requiredField(v)]: []"
                                    ></v-select>
                            </v-flex>

                            <v-flex class="form-group">
                                <label class="form-label">MSME <span><strong class="red--text">*</strong></span></label>
                                <v-select solo dense
                                class="form-control"
                                placeholder="Enter..."
                                :items="msmes"
                                v-model="serviceProvideObj.msmeAvailable"
                                :rules="[(v) => (!!v || 'This field is required')]"
                                disabled
                                >
                                </v-select>
                            </v-flex>

                            <v-flex class="form-group" v-show="serviceProvideObj.msmeAvailable=='Yes'">
                                <label class="form-label">Enterprise classification
                                   <span v-if="serviceProvideObj.msmeAvailable=='Yes'"><strong class="red--text">*</strong></span></label>
                                <v-select solo dense
                                class="form-control"
                                placeholder="Enter..."
                                v-model="serviceProvideObj.msmeDetails.enterpriseClassification"
                                :items="enterpriseclassifications"
                                :rules="serviceProvideObj.msmeAvailable=='Yes' ? [(v)=>requiredField(v)]:[]"
                                disabled
                                >
                                </v-select>
                            </v-flex>

                            <v-flex class="form-group" v-show="serviceProvideObj.msmeAvailable=='Yes'">
                                <label class="form-label">Enterprise category <span v-if="serviceProvideObj.msmeAvailable=='Yes'"><strong class="red--text">*</strong></span></label>
                                <v-select solo dense
                                class="form-control"
                                placeholder="Enter..."
                                v-model="serviceProvideObj.msmeDetails.enterpriseCategory"
                                :items="enterprisecategorys"
                                :rules="serviceProvideObj.msmeAvailable=='Yes' ? [(v)=>requiredField(v)]:[]"
                                disabled
                                >
                                </v-select>
                            </v-flex>

                            <v-flex class="form-group" v-show="serviceProvideObj.msmeAvailable=='Yes'">
                                <label class="form-label">UAN <span v-if="serviceProvideObj.msmeAvailable=='Yes'"><strong class="red--text">*</strong></span></label>
                                <v-text-field solo dense
                                class="form-control"
                                v-model="serviceProvideObj.msmeDetails.uan"
                                placeholder="Enter..."
                                :rules="serviceProvideObj.msmeAvailable=='Yes' ? [(v)=>requiredField(v),(v)=>matchRegex(v,'alphanum')]:[]"
                                disabled
                                >
                                </v-text-field>
                            </v-flex>

                            
                        </v-layout>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                        <v-expansion-panel-header color="#1EBBD9">
                             <span class="page-title-small white--text" >Upload Documents</span>
                                <template v-slot:actions>
                                    <v-icon class="white--text">
                                    {{panels.indexOf(2) !== -1 ? "mdi-minus-box" : "mdi-plus-box"}}
                                    </v-icon>
                                </template>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-layout wrap class="pt-4 form-group-container">
                  <v-tabs
                    v-model="tab"
                    hide-slider
                    
                  >
                  <!-- active-class="claimProcessingTabsActive" -->
                    <v-tab
                      v-for="item in tab_items"
                      :key="item"
                      @change="documentTab=item;checkTabChanged()"
                      :class="documentTab == item ? 'claimProcessingTabsActive' :'unActive'"
                    >
                      {{ item }}
                    </v-tab>
                  </v-tabs>
                  <v-layout class="mt-5">
                  <div >
                    <div class="neftStyle"   @dragover="dragover" @dragleave="dragleave" @drop="drop" 
                    :class="visualEffect ? 'dragbackground': ''">
                      <v-icon color="#23b1a9" class="iconfont">mdi-tray-arrow-up</v-icon>
                      <span class="ml-2 margincls font-weight-bold" >Drop Files To Upload or <a @click="browse" ><u > Browse</u></a></span>
                      <p class="margincls dlt_text mb-0">Max 5MB of PDF, Word, Excel, Mail or Image(.jpeg, .jpg, .png)</p>
                      <p class="dlt_text mt-0 ml-4" style="font-size:10px">Multiple documents can be uploaded</p>
                      
                    </div>
                    <span></span>
                            <v-file-input
                                multiple
                                v-model="all_attachments"
                                @change="selectFile"
                                accept=".doc, .docx, .csv, image/png,image/jpeg,application/msword,application/pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/csv"
                                id="SP_fileupload"
                                disabled
                                style="display: none">
                            </v-file-input>
                            <!-- @change="selectFile" -->
                  </div>
                  <div>
                    <v-data-table
                            :headers="neftHeaders"
                            :items="documentAttachments"                            
                            hide-default-footer
                            no-data-text="No Records for Attachements"
                            class="tabelwidth vDataTableStyling">
                            <template v-slot:[`item.action`] = '{ item }'>            
                                <v-icon class="tableicons mr-4" color="#23B1A9" @click="viewDocument(item)">mdi-eye</v-icon>
                                <v-icon class="tableicons mr-4" color="#23B1A9" @click="downloadDoc(item)">mdi-download</v-icon>
                                
                            </template>
                        </v-data-table>
                  </div>
                  </v-layout>

                </v-layout>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
                
                <v-dialog v-model="reject_Dialog" width="600">
                        <v-card>
                            <v-card-title class="white--text" style="background-color: #23b1a9"
                            ><v-layout style="display:flex;justify-content:center;margin-left: 170px;" >Reject
                               </v-layout>
                               <v-layout style="display:flex;justify-content:end;">
                                <v-icon class="white--text " @click="reject_Dialog=!reject_Dialog;reason=''">mdi-close</v-icon>
                              </v-layout>
                              </v-card-title>
                                <v-layout class="pt-4 form-group-container" >
                                   
                                    <v-flex class="mr-2">
                                        <label class="form-label">Reason <span><strong class="red--text">*</strong></span></label>
                                        <v-text-field  class="form-control" solo dense :rules="reject_Dialog ? [(v)=>requiredField(v)]:[]" v-model="reason" placeholder="Enter..."></v-text-field>
                                    </v-flex>
                                </v-layout>
                                <v-card-actions style="display:flex;justify-content:center">
                                  <v-btn class="btn" color="red white--text mr-0" @click="reject_Dialog=!reject_Dialog;reason=''">
                                    No
                                </v-btn>
        
                                <v-btn class="btn" color="green white--text mr-0" @click="onSubmit">
                                    Yes
                                </v-btn>
                              </v-card-actions>
                        </v-card>
                    </v-dialog>
            </v-form>
            <v-layout class="mt-5">
                
                <v-btn outlined color="red" class="ml-5 btn" @click="cancel">
                   <v-icon> mdi-backspace </v-icon>
                    <v-divider vertical class="mx-2"></v-divider> Cancel</v-btn>
                <v-spacer></v-spacer>
                <div v-if="showPDScoreDetails"  class="font-weight-bold hyperLinkColor spaceBetweenElements" @click="openPennyDropPaymentPopup">PennyDrop Payment Verification</div>
                <v-btn v-if="showPDScoreDetails" @click="showPdDetails" dark tile color="black" class="text-capitalize px-3 mx-3" small><v-icon dark small>visibility</v-icon> <v-divider vertical class="mx-2"></v-divider>PD Details</v-btn>
                <v-btn
                  outlined
                  color="orange"
                  class="btn mr-5"
                  @click="openAuditTrail"
                >
                  <v-icon> mdi-chart-timeline-variant </v-icon>
                  <v-divider vertical class="mx-2"> </v-divider>Audit Trail</v-btn>
                <v-btn outlined color="red" class="btn mr-5"   @click="reject_Dialog=!reject_Dialog">
                   <v-icon> mdi-backspace </v-icon>
                    <v-divider vertical class="mx-2">
                      </v-divider> Reject</v-btn>

                <v-btn class="btn" color="teal white--text" dense solo  @click="confirmationForApproval" :class="{ 'approveButtonDisabled' : checkIsPdScoreVerify}"><v-icon>mdi-arrow-right-circle</v-icon>
          <v-divider vertical class="mx-2"></v-divider>
          Approve</v-btn>
            </v-layout>
        <!-- Dialog box -->

            <v-dialog v-model="approve_Dialog" :width="serviceProvideObj.bankDetails.confirmAccountFlag ? '80%' : '600'">
                <v-card>
                    <v-card-title class="white--text" style="background-color: #23b1a9"
                    ><v-layout style="display:flex;justify-content:center;" >Approve
                       </v-layout>
                       <!-- <v-layout style="display:flex;justify-content:end;">
                        <v-icon class="white--text " @click="approve_Dialog=!approve_Dialog;reason=''">mdi-close</v-icon>
                      </v-layout> -->
                      </v-card-title>
                      <v-card-text v-if="serviceProvideObj.bankDetails.confirmAccountFlag" class="pb-0">
                        <v-layout justify-center class="pb-2" v-if="cancelledChequeData.length > 0">
                            <object
                                :data="cancelledChequeData[0].fileBase
                          ? cancelledChequeData[0].fileBase
                          : showCheque"
                                width="100%" height="300"></object>
                            <!-- <img height="300" :src="investigatorMasterObj.documents.fileBase" /> -->
                        </v-layout>
                        <v-form ref="AccountNumberForm" style="max-width: 450px; margin:auto">
                          <v-flex>
                              <label class="form-label">Bank Account Number<span class="mandatorycolor"> *</span></label>
                              <v-text-field v-model="validateAccNo" class="form-control" solo dense :rules="[(v) => requiredField(v), (v) => shouldBeSame(serviceProvideObj.bankDetails.reEnterAccountNumber, v, 'Mismatch Bank Account Number')]" label="Enter ..."></v-text-field>
                          </v-flex>
                        </v-form>
                      </v-card-text>
                      <v-card-text v-else>
                          <v-layout class="pt-4 form-group-container" justify-center>
                              <h4>Are you sure want to Approve ?</h4>
                          </v-layout>
                      </v-card-text>
                        <v-card-actions style="display:flex;justify-content:center">
                          <v-btn class="btn" color="red white--text mr-0" @click="approve_Dialog=!approve_Dialog;reason=''">
                            No
                        </v-btn>

                        <v-btn class="btn" color="teal white--text mr-0" @click="onSubmit">
                            Yes, Approve
                        </v-btn>
                      </v-card-actions>
                </v-card>
            </v-dialog>

        <v-dialog v-model="address_Dialog" width="1100">
            <v-card>
                <v-card-title class="white--text"
                    style="background-color: #23b1a9">
                    <h4>Add Multiple Address</h4><v-spacer></v-spacer><v-icon class="white--text" @click="address_Dialog=!address_Dialog">mdi-window-close</v-icon>
                </v-card-title>
                <v-card-text>
                    <v-data-table
                    hide-default-footer
                    class="mt-5 vDataTableStyling"
                    :headers="multipleAddress_headers"
                    :items="serviceProvideObj.spAddress"
                    >
                    <template v-slot:[`item.spAddressLineOne`]={item}>
                        <v-text-field solo dense class="form-control" disabled v-model="item.spAddressLineOne" placeholder="Enter..."></v-text-field>
                    </template>
                    <template v-slot:[`item.spAddressLineTwo`]={item}>
                        <v-text-field solo dense class="form-control" disabled v-model="item.spAddressLineTwo" placeholder="Enter..."></v-text-field>
                    </template>
                    <template v-slot:[`item.spAddressLineThree`]={item}>
                        <v-text-field solo dense class="form-control" disabled v-model="item.spAddressLineThree" placeholder="Enter..."></v-text-field>
                    </template>
                    <template v-slot:[`item.spDistrict`]={item}>
                        <v-text-field solo dense class="form-control" disabled v-model="item.spDistrict" placeholder="Enter..." ></v-text-field>
                    </template>
                    <template v-slot:[`item.spCity`]={item}>
                        <v-text-field solo dense class="form-control" disabled v-model="item.spCity" placeholder="Enter..." ></v-text-field>
                    </template>
                    <template v-slot:[`item.spState`]={item}>
                        <v-text-field solo dense class="form-control" disabled v-model="item.spState" placeholder="Enter..." ></v-text-field>
                    </template>
                    <template v-slot:[`item.spCountry`]={item}>
                        <v-text-field solo dense class="form-control" disabled v-model="item.spCountry" placeholder="Enter..." ></v-text-field>
                    </template>
                    <template v-slot:[`item.spPincode`]={item}>
                        <PincodeSearch
                        :isDisabled="true" 
                        :pinCode="item.spPincode"
                        :index="index"
                        :label="false"
                        @on-change="change_Pincode" />
                    </template>
                </v-data-table>
                    <v-layout class="addbtn" justify-center>
                <v-btn
                  @click="add_Address"
                  text
                  disabled
                  elevation="0"
                  class="mt-5"
                  style="color: #23B1A9"
                  >+ Add Address</v-btn
                >
              </v-layout>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-model="email_Dialog" width="500">
                <v-card>
                    <v-card-title
                    class="white--text"
                    style="background-color: #23b1a9"
                  >
                  <h4 class="ml-5">Add Multiple Email ID</h4>
        <v-spacer></v-spacer>
        <v-icon @click="email_Dialog=!email_Dialog" class="white--text">mdi-window-close</v-icon>
                </v-card-title>
                <v-card-text class="grey lighten-2">
                    <v-data-table hide-default-footer
                    :headers="email_Headers"
                    :items="email_Obj"
                    class="vDataTableStyling"
                    >
                   <template v-slot:[`item.email`]="{item}">
                    <v-text-field
                    solo
                    dense
                    class="form-control mt-5"
                    v-model="item.email"
                    disabled
                    :rules="[(v) => validateEmail(v, 'Email ID')]"
                    >

                    </v-text-field>
                   </template>
                   <template v-slot:[`item.action`]="{item}">
                    <v-icon left
                      top color="#d1121b" @click="email_Delete(email_Obj.indexOf(item))" disabled>mdi-delete</v-icon>
                   </template>
                </v-data-table>
                <v-layout class="addbtn" justify-center>
                <v-btn
                
                  @click="email_Add_Row"
                  text
                  elevation="0"
                  disabled
                  class="grey lighten-2 mt-5"
                  style="color: #23B1A9"
                  >+ Add Email ID</v-btn
                >
              </v-layout>
                </v-card-text>
                </v-card>
            </v-dialog>

    <!-- Mobile Dialog -->
    <v-dialog v-model="mobile_Dialog" width="500">
                <v-card>
                    <v-card-title
                    class="white--text"
                    style="background-color: #23b1a9"
                  >
                  <h4 class="ml-5">Add Multiple Contact Number</h4>
        <v-spacer></v-spacer>
        <v-icon @click="mobile_Dialog=!mobile_Dialog" class="white--text">mdi-window-close</v-icon>
                </v-card-title>
                <v-card-text class="grey lighten-2">
                    <v-data-table hide-default-footer
                    :headers="contact_Headers"
                    :items="serviceProvideObj.mobileNumber"
                    class="vDataTableStyling"
                    >
                    <template v-slot:[`item.isd`]="{item}">
                        <v-text-field
                    solo
                    dense
                    :maxlength="3"
                    disabled
                    class="form-control mt-5"
                    v-model="item.isd"
                    :rules="[(v) => matchRegex(v, 'nums')]"
                    >

                    </v-text-field>
                    </template>
                   <template v-slot:[`item.number`]="{item}">
                    <v-text-field
                    solo
                    dense
                    disabled
                    class="form-control mt-5"
                    :maxlength="10"
                    v-model="item.number"
                    :rules="[(v) => matchRegex(v, 'nums')]"
                    >

                    </v-text-field>
                   </template>
                   <template v-slot:[`item.action`]="{item}">
                    <v-icon left
                      top color="#d1121b" @click="contact_Delete(serviceProvideObj.mobileNumber.indexOf(item))" disabled>mdi-delete</v-icon>
                   </template>
                </v-data-table>
                <v-layout class="addbtn" justify-center>
                <v-btn
                
                  @click="contact_Add_Row"
                  text
                  elevation="0"
                  disabled
                  class="grey lighten-2 mt-5"
                  style="color: #23B1A9"
                  >+ Add Contact Number</v-btn>
              </v-layout>
                </v-card-text>
                </v-card>
            </v-dialog>
    <!-- Audit Trail -->
    <audit-trail
      :dialogState="OpenAuditDialog"
      :auditTrailMaster="'serviceProvider'"
      :auditTrailItem="{ uid: auditTrailId }"
      @close-dialog="OpenAuditDialog = false"
    />
    <penny-drop-popup ref="openPennyDropPopup" :showVerifyButton="true" @verifyPdScore="updateVerifiedPdScore"></penny-drop-popup>
    <v-row justify="center">
        <v-dialog transition="scale-transition" width="200px" v-model="openPdDetailsPopup">
            <v-card class="overflow-hidden">
                <v-card tile>
                    <v-layout align-center>
                        <v-card-title class="pa-0 pl-3 text-capitalize font-weight-bold" style="color:#10242B;font-size:13px">PD Details</v-card-title>
                    </v-layout>
                </v-card>
                <v-card-text class="px-4 pt-4">
                    <div class="py-6 bg-gray rounded">
                        <ul class="list-style-none w-400">
                            <li v-for="(value, key) in pdDetails">
                                <span class="font-weight-bold"> {{ key }} </span>: {{ pdDetails[key] }}
                            </li>
                        </ul>
                    </div>
                </v-card-text>
                <v-card-actions class="justify-center pb-5">
                    <v-btn tile  dark color="#152F38" class="px-3 text-capitalize" small @click="openPdDetailsPopup = false">
                    <v-icon color="" small >cancel</v-icon>
                    <v-divider vertical class="ml-2 mr-2 text-capitalize"></v-divider>Close
                </v-btn>
                </v-card-actions>
            </v-card>                 
        </v-dialog>
    </v-row>
        </v-container>
    </v-app>
</template>
<script>
import PincodeSearch from '../../../../component/PincodeSearch.vue';
import auditTrail from '../../../../component/MasterAuditTrail.vue';
import pennyDropPopup from '../../../../components/PennyDropPopup.vue'
import moment from 'moment'
export default {
    components:{
        PincodeSearch,
        auditTrail,
        pennyDropPopup
    },
    data(){
        return{
          masterId:'',
          isPdScoreVerify: false,
          isBankDetailsAvailable: false,
          openPdDetailsPopup: false,
          pdDetails: {
            "PdScore" : "-",
            "Remark": "-",
            "Status": "-",
          },
            reject_Dialog:false,
          approve_Dialog:false,
            top_Heading:"Created",
            panels:[0,1,2],
            isView:false,
            //service provider details
            serviceProvider_Items:['External Surveyor', 'Investigator', 'Lawyer/Legal Expert', 'Doctor', 'Frequent Payee', 'Recovery Agent', 'In - House with License', 'In - House without  License', 'SIU', 'Structural Engineer/expert', 'Drying, Cleaning experts', 'Chartered Engineers', 'Chartered Accountants/Tax', 'Experts', 'Forensic Science experts', 'Forensic accounting experts', 'Drone Operators', 'Other Expert'],
            availablityAtMultipleLocation_Items:['Yes', 'No'],
            address_Dialog:false,
            multipleAddress_headers:[{text:"Address line 1",value:"spAddressLineOne"},
        {text:"Address Line 2",value:"spAddressLineTwo"},
        {text:"Address Line 3",value:"spAddressLineThree"},{text:"Pincode",value:"spPincode"},
        {text:"District",value:"spDistrict"},{text:"City",value:"spCity"},{text:"State",value:"spState"},{text:"Country",value:"spCountry"}],
        searchPincode:[],
        pincode:null,
        mobile_Dialog:false,
        email_Headers:[{text:"Email",value:"email",align:"center"},{text:"Action",value:"action",align:"center"}],
        contact_Headers:[{text:"ISD",value:"isd",align:"center"},{text:"Contact Number",value:"number",align:"center"},{text:"Action",value:"action",align:"center"}],
        email_Obj:[{email:""}],
        email_Dialog:false,
        gstFrequencyFlag_items:['Monthly', 'Quarterly', 'NA', 'Monthly less than 100cr', 'Monthly more than 100cr but less than 500cr', 'Monthly more than 500cr'],
        menu:false,
        menu1:false,
        pickerDate:"",
        pickerDate1:"",
        lob_items:["Health", "Marine", "AIGC", "EW", "Travel", "PA","Home"],
        tds_items:["Association of persons (AOP)", "Body of individuals (BOI)", "Company", "Firm", "Government", "HUF (Hindu undivided family)", "Local authority", "Artificial juridical person", "Individual or Person", "Trust (AOP)", "TDS not Applicable",],
            
            //Bank Details
            cancelledChequeFileShow: false,
            cancelledCheque:[],
            cancelledChequeData: [],
            isOCRUploaded: false,
            irdaCategory_Items:['Category A', 'Category B', 'Category C'],
            IFSCCodes:[],
            ifsc:null,
            bankAccount:0,
            accountTypes:['Saving Account','Current Account','Cash Credit'],
            pincode1:null,
            msmes:['Yes','No'],
            irda_items:['Category A', 'Category B', 'Category C'],
            supervisor_items:['License', 'Associate', 'Fellow'],
            enterpriseclassifications:['Proprietorship', 'Hindu undivided family', 'Association of persons', 'Co-operative society', 'Partnership firm', 'Company', 'Individual'],
            enterprisecategorys:['Micro', 'Small', 'Medium'],

            //upload Documents
            tab:null,
            tab_items:['NEFT Form','KYC/CKYC','Others'],
            documentTab:"NEFT Form",
            visualEffect: false,
            all_attachments:[],
            neftHeaders:[{text: "Document Name",align: "start",value: "fileName",sortable: false},
                                {text: "Action",align: "start",value: "action",sortable: false}],
            documentAttachments:[],
            neftAttachment:[],
            otherAttachment:[],
            kycAttachment:[],
            reason:"",
            serviceProviderDetails:"",
            showCheque:"",


            serviceProvideObj:{
                lob:[],
                isExternalServiceProvider:"",
                serviceProviderType:"",
                bankDetailsAvailable:false,
                availablityAtMultipleLocation:"",
                spAddress:[{spAddressLineOne:"",spAddressLineTwo:"",spAddressLineThree:"",spCity:"",spDistrict:"",spState:"",spCountry:"",spPincode:""}],
                panNumber:"",
                aadharId:"",
                gstinNumber:"",
                geoLocationCode:"",
                tdsType:"",
                diffTDS:false,
                emailID:[],
                mobileNumber:[{isd:"",number:""}],
                landlineNumber:{std:"",number:""},
                contactPerson:{name:"",number:""},
                licenselicenseDate:null,
                licenseExpiryDate:null,
                licenseNumber:"",
                
                serviceProviderDetails:{
                    employeeName:"",
                    userId:""
                },
                bankDetails:{
                    ifscCode:"",
                    bankName:"",
                    accountNumber:"",
                    accountType:"",
                    addressLineOne:"",
                    addressLineTwo:"",
                    addressLineThree:"",
                    branch:"",
                    city:"",
                    district:"",
                    state:"",
                    pincode:"",
                    accountHolderName:"",
                    reEnterAccountNumber:""
                },
                documents:[],
                msmeAvailable:'No',
                msmeDetails:{
                    enterpriseClassification:"",
                    enterpriseCategory:"",
                    uan:""
                },
                active:false,
                approveRequest:true,
                approveStatus:"",
                isDeleted:false,
                isWatchlist:false,
                irdaCategory:"",
                surveyorCategory:"",
                gstFrequencyFlag:"",
                overwritten:[{status:false}],
            },
                OpenAuditDialog: false,
                auditTrailId: null,
                validateAccNo: "",
        }
    },
    methods:{
        updateVerifiedPdScore(pdDetails) {
            this.isPdScoreVerify = true;
            this.pdDetails = {...this.pdDetails, "PdScore": pdDetails.pdScore, "Remark" : pdDetails.remark, "Status": pdDetails.status};
            this.showAlert("PD Score Verified Successfully");
        },
        openPennyDropPaymentPopup() {
            let requestData = "lob=CM&masterId=" + this.masterId + "&masterType=Service Provider";
            this.$refs.openPennyDropPopup.openPopup(requestData);
        },
        showPdDetails(){
            let requestData = "lob=CM&masterId=" + this.masterId + "&masterType=Service Provider";
            this.GETM("GET_PENNYDROP_SCORE", requestData, (response) => {
                let pennyDropDetails = response?.data?.data ? response?.data?.data : [];
                if(pennyDropDetails.length && pennyDropDetails[0].remark){
                    this.pdDetails = {...this.pdDetails, "PdScore": pennyDropDetails[0].pdScore, "Remark" : pennyDropDetails[0].remark, "Status": pennyDropDetails[0].status};
                }
                this.openPdDetailsPopup = true;
            }, (error) => {
                let errorMsg = error.response && error.response.data && error.response.data.msg ? error.response.data.msg : error.response.data.message ? error.response.data.message : "Something went wrong!";
                this.showAlert(errorMsg);
            },true);
        },
        confirmationForApproval() {
            if (this.checkIsPdScoreVerify) {
                this.showAlert("Please do PennyDrop Payment Verification First");
                return;
            }
            this.approve_Dialog = !this.approve_Dialog;
        },
        change_Pincode(data,index){
            this.serviceProvideObj.spAddress[index].spPincode=data.pinCode
            this.serviceProvideObj.spAddress[index].spCity=data.cityDistrictName
            this.serviceProvideObj.spAddress[index].spDistrict=data.cityDistrictName
            this.serviceProvideObj.spAddress[index].spState=data.stateName
            this.serviceProvideObj.spAddress[index].spCountry=data.countryName
            
        },
        cancel(data){
                  if(data == 1){
                    this.$router.push({name:'SearchServiceProviderApprove'} );
              }else{
              this.showConfirm("Confirmation","Are you sure you want to leave?","Yes","No",(Yes) => {
                    this.showProgress();
                    this.$router.push({name:'SearchServiceProviderApprove'} );//params:{pageNo:this.pageNo}}
                    this.hideProgress();
                })
              }
            },
            checkBankDetails(){
                if( this.serviceProvideObj.bankDetailsAvailable==false){
                    this.serviceProvideObj.bankDetails={
                    ifscCode:"",
                    bankName:"",
                    accountNumber:"",
                    accountType:"",
                    addressLineOne:"",
                    addressLineTwo:"",
                    addressLineThree:"",
                    branch:"",
                    city:"",
                    district:"",
                    state:"",
                    pincode:"",
                    accountHolderName:"",
                    reEnterAccountNumber:""
                }
                this.serviceProvideObj.msmeAvailable='No',
                this.serviceProvideObj.msmeDetails={
                    enterpriseClassification:"",
                    enterpriseCategory:"",
                    uan:""
                }
                this.serviceProvideObj.irdaCategory="",
                this.serviceProvideObj.surveyorCategory="",
                this.serviceProvideObj.overwritten=[{status:false}]
                this.pincode1=null,
            this.IFSCCodes=[],
            this.ifsc=null,
            this.bankAccount=0,
            this.cancelledChequeFileShow= false,
            this.cancelledCheque=[],
            this.cancelledChequeData= [],
            this.isOCRUploaded= false
                }
               
  },
        onReset(){
            this.serviceProvideObj.serviceProviderDetails={
                    employeeName:"",
                    userId:""
                },
            this.searchPincode=[],
        this.pincode=null,
            this.pincode1=null,
            this.IFSCCodes=[],
            this.ifsc=null,
            this.bankAccount=0,
            this.cancelledChequeFileShow= false,
            this.cancelledCheque=[],
            this.cancelledChequeData= [],
            this.isOCRUploaded= false,
            this.documentAttachments=[],
            this.neftAttachment=[],
            this.otherAttachment=[],
            this.kycAttachment=[],
            this.all_attachments=[],
            this.documentTab="NEFT Form",
            this.email_Obj=[{email:""}],
            this.serviceProvideObj.lob="",
            this.serviceProvideObj.isExternalServiceProvider="",
            this.serviceProvideObj.serviceProviderType="",
            this.serviceProvideObj.bankDetailsAvailable=false,
            this.serviceProvideObj.availablityAtMultipleLocation="",
            this.serviceProvideObj.spAddress=[{spAddressLineOne:"",spAddressLineTwo:"",spAddressLineThree:"",spCity:"",spDistrict:"",spState:"",spCountry:"",spPincode:""}],
            this.serviceProvideObj.panNumber="",
            this.serviceProvideObj.aadharId="",
            this.serviceProvideObj.gstinNumber="",
            this.serviceProvideObj.geoLocationCode="",
            this.serviceProvideObj.tdsType="",
            this.serviceProvideObj.diffTDS=false,
            this.serviceProvideObj.emailID=[],
            this.serviceProvideObj.mobileNumber=[{isd:"",number:""}],
            this.serviceProvideObj.landlineNumber={std:"",number:""},
            this.serviceProvideObj.contactPerson={name:"",number:""}
            this.serviceProvideObj.licenselicenseDate="",
            this.serviceProvideObj.licenseExpiryDate="",
            this.serviceProvideObj.licenseNumber="",
            this.serviceProvideObj.tagicLOB="",
            this.serviceProvideObj.bankDetails={
                    ifscCode:"",
                    bankName:"",
                    accountNumber:"",
                    accountType:"",
                    addressLineOne:"",
                    addressLineTwo:"",
                    addressLineThree:"",
                    branch:"",
                    city:"",
                    district:"",
                    state:"",
                    pincode:"",
                    accountHolderName:"",
                    reEnterAccountNumber:""
                }
                this.serviceProvideObj.documents=[],
                this.serviceProvideObj.msmeAvailable='No',
                this.serviceProvideObj.msmeDetails={
                    enterpriseClassification:"",
                    enterpriseCategory:"",
                    uan:""
                }
                this.serviceProvideObj.active=false,
                this.serviceProvideObj.approveRequest=false,
                this.serviceProvideObj.approveStatus="",
                this.serviceProvideObj.isDeleted=false,
                this.serviceProvideObj.isWatchlist=false,
                this.serviceProvideObj.irdaCategory="",
                this.serviceProvideObj.surveyorCategory="",
                this.serviceProvideObj.gstFrequencyFlag="",
                this.serviceProvideObj.overwritten=[{status:false}]
                const a=new Date().toISOString().split('T')[0]
   this.pickerDate=a
   this.pickerDate1=a
        },
        contact_Add_Row(){
            this.serviceProvideObj.mobileNumber.push({isd:"",number:""})
        },
        contact_Delete(index){
            this.serviceProvideObj.mobileNumber.splice(index,1)
        },
        email_Add_Row(){
            this.email_Obj.push({email:""})
        },
        email_Delete(index){
            this.email_Obj.splice(index,1)
        },
        pincodeSearch(){
            
            if(this.pincode.length>3){
                let reqData = "pageNo=1&perPage=100&pinCode=" + this.pincode
        this.GETM(
          "getPincodes",
          reqData,
          (res) => {
            let a=res.data.data.data
            this.searchPincode=a
            
          },(err) => {
            console.error("Issue while fetching pin codes.", err);
          }, true)
            }
        },
       
        change_Pincodes(num=0){
            
            let data=this.searchPincode.filter(j=>{
                if(j.pinCode == this.serviceProvideObj.spAddress[0].spPincode){
                    return j
                }
            })
            console.log(this.serviceProvideObj.spAddress[num])
            this.serviceProvideObj.spAddress[0].spCity=data[0].cityDistrictName
            this.serviceProvideObj.spAddress[0].spDistrict=data[0].cityDistrictName
            this.serviceProvideObj.spAddress[0].spState=data[0].stateName
            this.serviceProvideObj.spAddress[0].spCountry=data[0].countryName
            this.pincode=null
            // this.searchPincode=[]
        },
        add_Address(){
            this.serviceProvideObj.spAddress.push({spAddressLineOne:"",spAddressLineTwo:"",spAddressLineThree:"",spCity:"",spDistrict:"",spState:"",spCountry:"",spPincode:""})
        },
        multipleAddress(){
            if(this.serviceProvideObj.availablityAtMultipleLocation == 'Yes'){
                this.address_Dialog=true
            }else{
                this.address_Dialog=false
            }
        },
        changeServiceProvideValues(){
            if(this.serviceProvideObj.isExternalServiceProvider=="Yes"){
               this.serviceProvider_Items=['External Surveyor', 'Investigator', 'Lawyer/Legal Expert', 'Doctor', 'Frequent Payee',
                 'Recovery Agent',  'Structural Engineer/expert',
                  'Drying, Cleaning experts', 'Chartered Engineers', 'Chartered Accountants/Tax',
                 'Experts', 'Forensic Science experts', 'Forensic accounting experts', 'Drone Operators', 'Other Expert']
            }else{
                this.serviceProvider_Items=[ 'Lawyer/Legal Expert', 'Doctor',
                 'Recovery Agent', 'In - House with License', 'In - House without  License', 'SIU', 'Structural Engineer/expert',
                  'Drying, Cleaning experts', 'Chartered Engineers','Chartered Accountants/Tax', 'Experts', 'Forensic Science experts',
                   'Forensic accounting experts', 'Drone Operators', 'Other Expert']
            }
        },
        dragover(event) {
                event.preventDefault();
                this.visualEffect = true;
            },
    dragleave(event) {
                this.visualEffect = false;
            },
    browse(){ //to select attachments
                document.getElementById("SP_fileupload").click()
            },

    drop(event) {
                event.preventDefault();
                this.all_attachments = event.dataTransfer.files;
                // console.log("all", this.all_attachments);
                 this.selectFile(); // Trigger the onChange event manually
                this.visualEffect = false;
            },
            selectFile(){
                if(this.all_attachments.length !== 0){
                    const fsize = this.all_attachments[0].size;
                    const file = Math.round(fsize / 1024);
                    let self = this
                    if (file > 5120) {
                        self.showAlert("File is too big, please select a file less than 5MB");
                    } else {
                        self.readImageM(self.all_attachments[0].name, self.all_attachments[0], (res) => { 
                          console.log(res)
                        var type = [];
                        type = self.all_attachments[0].type
                        console.log('--',self.documentTab)
                        if(self.documentTab == 'NEFT Form'){
                            if(self.neftAttachment.length < 5){
                                self.neftAttachment.push({ 
                                    fileName: res.file,
                                    fileBase: res.fileBase,
                                    documentType: 'NEFT Form'
                                })
                                self.neftAttachment = self.neftAttachment.reduce((unique, o) => {
                                    if(!unique.some(obj => obj.file === o.file)) {
                                        unique.push(o);                                        
                                    }
                                    return unique;
                                },[]);
                            }else{
                                self.neftAttachment.splice(0,1)
                                self.neftAttachment.push({ 
                                    fileName: res.file,
                                    fileBase: res.fileBase,
                                    documentType: 'NEFT Form'
                                })
                                self.neftAttachment = self.neftAttachment.reduce((unique, o) => {
                                    if(!unique.some(obj => obj.file === o.file)) {
                                        unique.push(o);                                        
                                    }
                                    return unique;
                                },[]);
                            }
                            console.log('---neft', self.neftAttachment)
                        }else if(self.documentTab == 'Others'){
                            if(self.otherAttachment.length < 5){
                                self.otherAttachment.push({ 
                                    fileName: res.file,
                                    fileBase: res.fileBase,
                                    documentType: 'OTHERS'
                                })
                                self.otherAttachment = self.otherAttachment.reduce((unique, o) => {
                                    if(!unique.some(obj => obj.file === o.file)) {
                                        unique.push(o);                                        
                                    }
                                    return unique;
                                },[]);
                            }else{
                                self.otherAttachment.splice(0,1)
                                self.otherAttachment.push({ 
                                    fileName: res.file,
                                    fileBase: res.fileBase,
                                    documentType: 'OTHERS'
                                })
                                self.otherAttachment = self.otherAttachment.reduce((unique, o) => {
                                    if(!unique.some(obj => obj.file === o.file)) {
                                        unique.push(o);                                        
                                    }
                                    return unique;
                                },[]);
                            }
                        }else{
                            if(self.kycAttachment.length < 5){
                                self.kycAttachment.push({ 
                                    fileName: res.file,
                                    fileBase: res.fileBase,
                                    documentType: 'KYC/CKYC'
                                })
                                self.kycAttachment = self.kycAttachment.reduce((unique, o) => {
                                    if(!unique.some(obj => obj.file === o.file)) {
                                        unique.push(o);                                        
                                    }
                                    return unique;
                                },[]);
                            }else{
                                self.kycAttachment.splice(0,1)
                                self.kycAttachment.push({ 
                                    fileName: res.file,
                                    fileBase: res.fileBase,
                                    documentType: 'KYC/CKYC'
                                })
                                self.kycAttachment = self.kycAttachment.reduce((unique, o) => {
                                    if(!unique.some(obj => obj.file === o.file)) {
                                        unique.push(o);
                                        
                                    }
                                    return unique;
                                },[]);
                            }
                        }
                        // // Phase-2 function call starts
                        // this.getCancelledChequeData(res.base);
                        // this.manualOverwrite = false;
                        // // Phase-2 function call ends
                        });
                    }
                }
                this.checkTabChanged()
            },
            viewDocument(itemData) {
      if (itemData.fileBase) {
        this.viewDocUsingBase(itemData.fileBase);
      } else {
        let requestData = "";
        if (itemData !== null) {
          requestData = "documentId=" + itemData.documentId + "&b64=true";
        }
        this.GETM(
          "VIEW_DOCUMENT",
          requestData,
          (res) => {
            if (res.data.statusCode === 200) {
              this.viewDocUsingBase(res.data.data);
            }
          },
          (error) => {
            console.log("Error", error);
          },
          true
        );
      }
    },
    viewDocUsingBase(base64) {
      const base64ImageData = base64;
      const contentType = base64.split(":")[1].split(";")[0];
      const byteCharacters = window.atob(
        base64ImageData.substr(`data:${contentType};base64,`.length)
      );
      const byteArrays = [];
      for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
        const slice = byteCharacters.slice(offset, offset + 1024);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
      const blob = new Blob(byteArrays, { type: contentType });
      const blobUrl = window.URL.createObjectURL(blob);
      window.open(blobUrl, "_blank");
    },

    downloadDoc(item) {
                if(item.fileBase) {
                    let base64 = item.fileBase;
                    const base64ImageData = base64
                    const contentType = base64.split(':')[1].split(';')[0];
                    const byteCharacters = window.atob(base64ImageData.substr(`data:${contentType};base64,`.length));
                    const byteArrays = [];
                    for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
                        const slice = byteCharacters.slice(offset, offset + 1024);
                        const byteNumbers = new Array(slice.length);
                    for (let i = 0; i < slice.length; i++) {
                        byteNumbers[i] = slice.charCodeAt(i);
                    }
                    const byteArray = new Uint8Array(byteNumbers);
                        byteArrays.push(byteArray);
                    }
                    const blob = new Blob(byteArrays, {type: contentType});
                    const blobUrl =  window.URL.createObjectURL(blob);

                    const element = document.createElement("a");
                    element.href = blobUrl;
                    element.download = item.file;
                    element.click();
                } else {
                    let url = this.getURL("VIEW_DOCUMENT") + "?documentId=" + item.documentId;
                    window.open(url);
                }
            },
            deleteDocument(index) {
                if(this.documentTab=='NEFT Form'){
        this.neftAttachment.splice(index, 1);
      }
      
      if(this.documentTab=='Others'){
      this.otherAttachment.splice(index, 1);
      }
      if(this.documentTab=='KYC/CKYC'){
      this.kycAttachment.splice(index, 1);
      }
      this.checkTabChanged()
},
ifscData(){
      if(this.ifsc.length>3){
        let request =  this.ifsc+ "?lob=Marine";
        this.GET_ByURLM("GET_GC_BANK",request,
        (res) => {
            this.IFSCCodes = [];
            this.hideProgress();
            console.log(res.data.data)
            if (res.data.data && res.data.data.length > 0) {
                let ifscCodeValue = res.data.data[0];
                this.IFSCCodes=res.data.data
                this.serviceProvideObj.bankDetails.branch =ifscCodeValue.branchName
                this.serviceProvideObj.bankDetails.bankName = ifscCodeValue.financierName;
                this.serviceProvideObj.bankDetails.addressLineOne = ifscCodeValue.branchAddress;
                let address2 = '';
                if (ifscCodeValue.district) {
                    address2 += `${ifscCodeValue.district}, `
                }
                    if (ifscCodeValue.city) {
                        address2 += `${ifscCodeValue.city}, `
                    }
                        if (ifscCodeValue.pincode) {
                            address2 += ifscCodeValue.pincode
                        }
                        this.serviceProvideObj.bankDetails.bankAddressLineTwo = address2;
                        this.serviceProvideObj.bankDetails.bankAddressLineThree = ifscCodeValue.state;
                        this.serviceProvideObj.bankDetails.pinCode=ifscCodeValue.pincode
                        this.pincode1=ifscCodeValue.pincode
                        this.bankDetailsPincode()
                        // this.serviceProvideObj.bankDetailsAddressLine2 = `${ifscCodeValue.district}, ${ifscCodeValue.city}, ${ifscCodeValue.state}`
                    }
                },
                (error) => {
                    this.IFSCCodes = [];
                    this.hideProgress();
                    console.log("error", error);
                    this.showAlert(error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message);
                }, true
                );
            }
        },


    checkTabChanged(){
      if(this.documentTab=='NEFT Form'){
        this.documentAttachments=this.neftAttachment
      }
      if(this.documentTab=='Others'){
        this.documentAttachments=this.otherAttachment
      }
      if(this.documentTab=='KYC/CKYC'){
        this.documentAttachments=this.kycAttachment
      }
    },
    bankDetailsPincode(){
        let reqData = "pageNo=1&perPage=100&pinCode=" + this.pincode1
        this.GETM(
          "getPincodes",
          reqData,
          (res) => {
            let data=res.data.data.data[0]
            this.serviceProvideObj.bankDetails.city = data.cityDistrictName;
          this.serviceProvideObj.bankDetails.state  = data.stateName;
          this.serviceProvideObj.bankDetails.district  = data.cityDistrictName;
          },(err) => {
            console.error("Issue while fetching pin codes.", err);
          }, true)
    
          
  },
  
    OCRUpload() {
            if (this.cancelledCheque) {
                this.cancelledChequeFileShow = false; //hide view port
                this.isOCRUploaded = false; //check if OCR uploaded
                this.serviceProvideObj.overwritten[0].status = false; //check for manual entry
                // this.clearbankDetailsAvailable(); //reset bank fields
                // console.log(this.cancelledCheque)
                if (!this.cancelledCheque.name){
                    return // for while resetting the form
                }
                this.showProgress();
                
                this.readImageM(this.cancelledCheque.name, this.cancelledCheque, (res) => {
                  console.log(res)
                    let requestPayload = {
                        document: res.fileBase,
                    };
                    this.cancelledCheque = []; //reset field value
                    this.POSTM(
                        "GET_OCR_FORMAT",
                        requestPayload,
                        (response) => {
                            this.hideProgress();
                            if (response.data.data.Message == "Uploaded Image/Document orientation is incorrect") {
                                this.showAlert(response.data.data.Message);
                                this.serviceProvideObj.overwritten[0].status = true;
                            } else if (response.data.data.Message == "Invalid Image/Document") {
                                this.showAlert(response.data.data.Message);
                                this.serviceProvideObj.overwritten[0].status = true;
                            } else if (response.data.data) {
                                let bankres = response.data.data;
                                console.log(response.data.data)
                                if (!bankres.AccountNumber || !bankres.IFSC || !bankres.PayerName) {
                                    this.isOCRUploaded = true;
                                    
                                    this.serviceProvideObj.overwritten[0].status = true;
                                    this.showAlert("Could not retrieve data from OCR either re-upload the cancelled cheque or enter the details manually");
                                } else {
                                    
                                    //temprory fix
                                    this.showProgress();
                                    let request = bankres.IFSC + "?lob=Marine";
                                    this.GET_ByURLM(
                                        "GET_GC_BANK",
                                        request,
                                        (res) => {
                                            this.IFSCCodes = [];
                                            this.hideProgress();
                                            console.log(res.data.data)
                                            if (res.data.data && res.data.data.length > 0) {
                                                let ifscCodeValue = res.data.data[0];
                                                this.IFSCCodes = res.data.data;
                                                this.serviceProvideObj.bankDetails.ifscCode = bankres.IFSC;
                                                this.serviceProvideObj.bankDetails.accountNumber=bankres.AccountNumber
                                                this.checkBankAccount()
                                                this.serviceProvideObj.bankDetails.reEnterAccountNumber=bankres.AccountNumber
                                                this.serviceProvideObj.bankDetails.branch =ifscCodeValue.branchName

                                                this.serviceProvideObj.bankDetails.bankName = ifscCodeValue.financierName;
                                                this.serviceProvideObj.bankDetails.addressLineOne = ifscCodeValue.branchAddress;
                                                let address2 = '';
                                                if (ifscCodeValue.district) {
                                                    address2 += `${ifscCodeValue.district}, `
                                                }
                                                if (ifscCodeValue.city) {
                                                    address2 += `${ifscCodeValue.city}, `
                                                }
                                                if (ifscCodeValue.pincode) {
                                                    address2 += ifscCodeValue.pincode
                                                }
                                                this.serviceProvideObj.bankDetails.addressLineTwo = address2;
                                                this.serviceProvideObj.bankDetails.addressLineThree = ifscCodeValue.state;
                                                this.serviceProvideObj.bankDetails.pincode=ifscCodeValue.pincode
                                                this.pincode1=ifscCodeValue.pincode
                                                
                                                // this.bankDetailsPincode()
                                            }
                                        },
                                        (error) => {
                                            this.IFSCCodes = [];
                                            this.hideProgress();
                                            console.log("error", error);
                                            this.showAlert(error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message);
                                        }, true
                                    );
                                    this.cancelledChequeData.push(
                                        {
                                            file: res.file,
                                            fileBase: res.fileBase,
                                            documentType: "Cancelled Cheque",   
                                        }
                                    )
                                    
                                    
                                    // this.serviceProvideObj.bankDetailsAccountNumber = bankres.AccountNumber;
                                    // this.reEnterBankAccountNumber = bankres.AccountNumber;
                                    // this.serviceProvideObj.consumerFirstName = bankres.PayerName;
                                    // this.isOCRUploaded = true;
                                }
                            } else {
                                this.showAlert("No data found from OCR");
                            }
                        },
                        (error) => {
                            this.hideProgress();
                            this.serviceProvideObj.overwritten[0].status = true;
                            this.showAlert("Somthing went wrong in OCR,Please enter the details manually");
                        }, true
                    );
                });
            } else {
                this.showAlert("Please upload a file.")
            }
            this.cancelledCheque=[]
        },
        viewDocumentCheque(id){
        let requestData = "documentId=" + id + "&b64=true";
        this.GETM(
          "VIEW_DOCUMENT",
          requestData,
          (res) => {
            if (res.data.statusCode === 200) {
              this.showCheque=res.data.data
            }
          },
          (error) => {
            console.log("Error", error);
          },
          true
        );
      },
        cancelChequeView() {
                this.cancelledChequeFileShow = !this.cancelledChequeFileShow;
        },
        dropCancel(event) {
                event.preventDefault();
                this.cancelledCheque = event.dataTransfer.files;
                // console.log("all", this.all_attachments);
                 this.OCRUpload(); // Trigger the onChange event manually
                
            },
            browseCancel(){ //to select attachments
                document.getElementById("cancelchequeupload").click()
            },
            dragoverCancel(event) {
                event.preventDefault();
                
            },
            checkBankAccount(){
        this.bankAccount=this.serviceProvideObj.bankDetails.accountNumber
        this.serviceProvideObj.bankDetails.accountNumber=""
        for(let i=0;i<=this.bankAccount.length-1;i++){
            this.serviceProvideObj.bankDetails.accountNumber+='*'
        }
    },
    formatDate(date) {
      if (!date) return "";

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    convertObjToString(){
    this.serviceProvideObj.bankDetails.accountNumber=this.bankAccount
    this.serviceProvideObj.emailID=this.email_Obj.map(g=> {return g.email})
    this.serviceProvideObj.documents=[ ...this.neftAttachment,
            ...this.otherAttachment,
            ...this.kycAttachment,...this.cancelledChequeData]
  },
    onSubmit(){
    if(!this.$refs?.AccountNumberForm?.validate() && this.serviceProvideObj?.bankDetails?.confirmAccountFlag && !this.reject_Dialog){
        return this.showAlert("Please fill bank account number.");
    }
    if (!this.$refs.create_Service_Provider.validate()) {
        return this.showAlert("Please fill mandatory fields.");
      }else{
        if(this.serviceProviderDetails._id){
            let sendData={approveStatus:this.reject_Dialog ? "Rejected" : "Approved",
          rejectionReason:this.reason}
            this.PUTM(
          "updateMasterApprove",
          this.serviceProviderDetails._id,sendData,
          (res) => {
            this.hideProgress();
            if (res.data && res.data.statusCode == 200) {
              this.showAlert(res?.data?.msg || "Success", "", "", () => {
                
                 this.onReset();
                this.cancel(1)                              
              });
              // this.isView=true
            } else {
              this.showAlert(res.data.msg);
              this.hideProgress();
            }
          },
          (err) => {
            console.log(err);
            this.hideProgress();
            let msg =
              err?.response?.data?.msg || "Unable to submit Service Provider Approval.";
            this.showAlert(msg);
          },true
        );
        }
      }
  },
  openAuditTrail(){
      this.OpenAuditDialog = true
      this.auditTrailId = this.serviceProvideObj.serviceProviderId
    },
},
created() {
    this.$store.commit("SAVE_PAGE_HEADER", "Service Provider Master Approval");
    if(Object.keys(this.$route.params).length != 0){
        
        let a=`${this.$route.params.id}`
        console.log("ID",this.$route.params.id)
        this.GET_ByURLM('getFrequentApprove',a,(res)=>{
        this.serviceProvideObj=res.data.data.operationData[0]
        this.serviceProviderDetails=res.data.data
        this.popupOCR(this.serviceProviderDetails?.operationData?.[0]?.overwritten?.[0]?.status)
        this.masterId = 'SP' + this.serviceProvideObj?.serviceProviderId;
        this.isBankDetailsAvailable =  this.serviceProvideObj?.bankDetails?.accountNumber;
        this.serviceProvideObj.documents.forEach(e=>{
          if(e.documentType == 'NEFT Form'){
           this.neftAttachment.push(e)
          }
          if(e.documentType == 'KYC/CKYC'){
            this.kycAttachment.push(e)
          }
          
          if(e.documentType == 'OTHERS'){          
            this.otherAttachment.push(e)
          }
          if(e.documentType == 'Cancelled Cheque'){          
            this.cancelledChequeData.push(e)
            this.viewDocumentCheque(e.documentId)
          }
        })
        this.checkBankAccount()
        this.checkTabChanged()
       this.email_Obj= this.serviceProvideObj.emailID.map(e=>{return {email:e}})
       }, () => {}, true)
    }
},
computed:{
    computedPAN() {
            this.serviceProvideObj.panNumber = this.serviceProvideObj.panNumber ? this.serviceProvideObj.panNumber.toUpperCase() : '';
            return this.serviceProvideObj.panNumber;
        },
    //     computedLicenseRegistrationdate(){
    //   return this.formatDate(this.serviceProvideObj.licenselicenseDate);
    // },
    // computedLicenseExpirydate(){
    //   return this.formatDate(this.serviceProvideObj.licenseExpiryDate);
    // },
    computedLicenseRegistrationdate(){
                return this.serviceProvideObj.licenselicenseDate == null ? '' : moment(this.serviceProvideObj.licenselicenseDate).format('DD/MM/YYYY');
            },
    computedLicenseExpirydate(){
                return this.serviceProvideObj.licenseExpiryDate == null ? '' : moment(this.serviceProvideObj.licenseExpiryDate).format('DD/MM/YYYY');
            },
    getEndDate() {
     var endDate = new Date();
     return endDate.toISOString().slice(0,10)
    },
    checkIsPdScoreVerify(){
      if(this.showPDScoreDetails){
        return !this.isPdScoreVerify;
      }
        return false;
    },
    showPDScoreDetails(){
        return this.isBankDetailsAvailable ? true : false;
    }
}
}
</script>

<style scoped>
.iconstyle {
  background: #f7f7f7 0% 0% no-repeat padding-box;
  border: 0.5px solid #c1c8cc !important;
  color: #fff;
  width: 29px;
  margin-left: -0.1em;
  height: 38px;
  color: #6b6b6b;
}
.blueiconstyle {
  width: 38px;
  height: 38px;
  background: #01bbd9 0% 0% no-repeat padding-box;
  margin-right: -0.5em;
  color: #f7f7f7;
}
.claimProcessingTabsActive {
  background-color: #1ebbd9;
  color: #fff !important;
}
.unActive{
  border:1px solid #ccc;
  margin: 0px 5px
}
.dragbackground{
        background-color: #e7e7e7;
}
.margincls{
        font-size:12px;
        margin-left:-12px
}
.dlt_text{
        color: rgb(145, 145, 145);
}
.tabelwidth{
  width:500px;
  margin:0px 150px
}
.neftStyle{
        height: 150px;
        width: 350px;
        border: 3px dashed #ccc;
        /* padding-top: 40px;
        padding-left: 16%; */
        display: table-cell;
        vertical-align: middle;
        text-align: center;
}

.hyperLinkColor{
    color: blue; 
    cursor:pointer
}

.spaceBetweenElements{
    padding: 8px 10px;
}
.approveButtonDisabled{
    background-color: rgba(0, 0, 0, 0.12) !important;
}
</style>