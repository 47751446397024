<template>
  <div>
     <v-form ref="Formvalid">
    <v-flex class="mb-5">
      <v-row class="groupRowHead mt-0">
        <v-col align="left" class="mt-0">
          <v-flex class="ml-2 GroupName">{{ userDetails.first }} {{ userDetails.last }}</v-flex>
        </v-col>
      </v-row>
    </v-flex>

    <v-layout class="mt-1">
      <v-flex xs3>
        <v-layout column class="ml-3">
          <p class="mb-2 lableFontSize">
            Examiner ID<span class="mandatorycolor"> *</span>
          </p>
          <v-text-field 
            solo dense 
            class="forminput textDisable font-weight-bold"
            placeholder="Enter..." 
            v-model="markUserOffline.examinerId" 
            :rules="rulesValidation.examinerId"
            disabled
           >
          </v-text-field>
        </v-layout>
      </v-flex>

      <v-flex xs3>
        <v-layout column class="ml-3">
          <p class="mb-2 lableFontSize">
            Examiner Name<span class="mandatorycolor"> *</span>
          </p>
          <v-text-field
           placeholder="Enter..." 
           dense solo class="forminput textDisable font-weight-bold"
            v-model="markUserOffline.examinerName"
            :rules="rulesValidation.examinerName"
            disabled
           >
          </v-text-field>
        </v-layout>
      </v-flex>
      <v-flex xs3></v-flex>
      <v-flex xs3></v-flex>
    </v-layout>
    <v-layout class="mt-1">
      <v-flex xs3>
        <v-layout column class="ml-3">
          <p class="mb-2 lableFontSize">
            From Date<span class="mandatorycolor"> *</span>
          </p>
          <v-menu
            v-model="fromDateMenu"
            :close-on-content-click="false"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  placeholder="dd/mm/yyyy"
                  solo
                  dense
                  readonly
                  class="CalenderStartDate forminput"
                  required
                  v-bind="attrs"
                  v-on:focus="fromDateMenu=true"
                  v-on="on"
                  :rules="rulesValidation.fromDate"
                  append-icon="mdi-menu-down"
                  v-model="markUserOffline.fromDate">
                <template v-slot:prepend-inner class="mx-0 my-0">
                  <v-icon class="iconstyle">mdi-calendar</v-icon>
                </template>
              </v-text-field>
            </template>
            <v-date-picker
              v-model="fromDateFormatted"
              :min="todayDate"
              no-title
              @change="toDisableFromTime"
              @input="fromDateMenu = false"
              @click:date="markUserOffline.fromDate = formatDate(fromDateFormatted)"
            ></v-date-picker>
          </v-menu>
        </v-layout>
      </v-flex>
      <v-flex xs3>
        <v-layout column class="ml-3">
          <p class="lableFontSize mb-2">
            From Time<span class="mandatorycolor"> *</span>
          </p>
          <v-menu
            ref="fromTimeMenuCheck"
            v-model="fromTimeMenu"
            :close-on-content-click="false"
            :return-value.sync="markUserOffline.fromTime"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                placeholder="HH:MM"
                v-bind="attrs"
                solo
                dense
                readonly
                v-model="markUserOffline.fromTime"
                :rules="rulesValidation.fromTime"
                v-on="on"
                 v-on:focus="fromTimeMenu=true,fromDateMenu=false"
                class="forminput"
                append-icon="mdi-menu-down"
              >
                <template v-slot:prepend-inner class="mx-0">
                  <v-icon class="iconstyle">mdi-clock</v-icon>
                </template>
              </v-text-field>
            </template>
            <v-time-picker
                full-width
                no-title
                :min='currentFromTime'
                 format="24hr"
                   v-if="fromTimeMenu"
                 v-model="markUserOffline.fromTime"
                @click:minute="$refs.fromTimeMenuCheck.save(markUserOffline.fromTime)"
               ></v-time-picker>
          </v-menu>
        </v-layout>
      </v-flex>
      <!-- To date and Time Start -->
      <v-flex xs3>
        <v-layout column class="ml-3">
          <p class="mb-2 lableFontSize">
            To Date<span class="mandatorycolor"> *</span>
          </p>
          <v-menu
            v-model="toDateMenu"
            :close-on-content-click="false"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                placeholder="dd/mm/yyyy"
                solo
                dense
                readonly
                class="CalenderStartDate forminput"
                required
                v-bind="attrs"
               v-on:focus="toDateMenu=true,fromTimeMenu=false"
                v-on="on"
                append-icon="mdi-menu-down"
                v-model="markUserOffline.toDate"
                :rules="rulesValidation.toDate"
               >
                <template v-slot:prepend-inner class="mx-0 my-0">
                  <v-icon class="iconstyle">mdi-calendar</v-icon>
                </template>
              </v-text-field>
            </template>
            <v-date-picker 
                no-title
                :min="fromDateFormatted"
                v-model="toDateFormatted"
                @input="toDateMenu = false"
                @change="toDisableToTime"
                @click:date="markUserOffline.toDate = formatDate(toDateFormatted)"
            ></v-date-picker>
          </v-menu>
        </v-layout>
      </v-flex>
      <v-flex xs3>
        <v-layout column class="ml-3 mr-3">
          <p class="lableFontSize mb-2">
            To Time<span class="mandatorycolor"> *</span>
          </p>
          <v-menu
            ref="toTimeMenuCheck"
            v-model="toTimeMenu"
            :close-on-content-click="false"
            :return-value.sync="markUserOffline.toTime"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                placeholder="HH:MM"
                v-bind="attrs"
                solo
                dense
                readonly
                v-on:focus="toTimeMenu=true,toDateMenu=false"
                v-on="on"
                class="forminput"
                append-icon="mdi-menu-down"
                v-model="markUserOffline.toTime"
                :rules="rulesValidation.toTime"
              >
                <template v-slot:prepend-inner class="mx-0">
                  <v-icon class="iconstyle">mdi-clock</v-icon>
                </template>
              </v-text-field>
            </template>
            <v-time-picker full-width no-title format="24hr"
              :min='currentToTime'
               v-if="toTimeMenu"
               v-model="markUserOffline.toTime"
            	@click:minute="$refs.toTimeMenuCheck.save(markUserOffline.toTime)"
            ></v-time-picker>
          </v-menu>
        </v-layout>
      </v-flex>
    </v-layout>
    <!-- Reason and Message HtMl -->
    <v-layout class="mt-1">
      <v-flex xs6>
        <v-layout column class="ml-3">
          <p class="mb-2 lableFontSize">
            Reason <span class="mandatorycolor"> *</span>
          </p>
          <v-text-field solo dense class="forminput" placeholder="Enter..."
            v-model="markUserOffline.reason"
            :rules="rulesValidation.reason"
          >
          </v-text-field>
        </v-layout>
      </v-flex>

      <v-flex xs6>
        <v-layout column class="ml-3 mr-3">
          <p class="mb-2 lableFontSize">Message</p>
           <v-text-field placeholder="Enter..." dense solo class="forminput"
           v-model="markUserOffline.message"
           spellcheck="true"
          >
          </v-text-field>
        
        </v-layout>
      </v-flex>
    </v-layout>
    <!--  Submit add cancel clear buttons below-->
    <v-flex justify="end" class="mt-2">
      <v-row class="btnDiv ml-3 mr-3">
        <v-col align="right">
          <v-btn color="#23B1A9" dark class="buttonSize mr-5 btn" type="submit" @click.prevent="userOfflineSubmit">
            <v-icon class="mr-2 iconFontSize"  >
              mdi-checkbox-marked-circle</v-icon
            >
            <div class="vertical_line"></div>
            Submit
           </v-btn>
          <v-btn
            color="#E46A25"
            dark
            class="buttonSize mr-5"
            @click="$router.push('/home')"
          >
            <v-icon class="mr-2 iconFontSize">
              mdi-close-circle
            </v-icon>
            <div class="vertical_line"></div>
            Cancel
          </v-btn>
        </v-col>
      </v-row>
    </v-flex>
     </v-form>
  </div>
 
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      todayDate: new Date().toISOString().substr(0, 10),
      fromDateMenu: false,
      fromTimeMenu: false,
      toDateMenu: false,
      toTimeMenu: false,
      fromDateFormatted: null,
      fromTimeFormatted: null,
      toDateFormatted: null,
      toTimeFormatted: null,
      userDetails:{},
      currentFromTime:"",
      currentToTime:"",
      spchCharPattern:/^[A-Za-z0-9 ]+$/,
      spchCharMsg:"Special Characters Not Allowed.",
      errorMessage: "This field is required.",
      markUserOffline: {
        examinerId:"",
        examinerName:"",
        fromDate: "",
        fromTime: "",
        toDate: "",
        toTime: "",
        reason :"",
        message :""
      },
      toTimeTimeStamp:null,
      formTimeTimeStamp:null,
      rulesValidation: {
        examinerId   :  [(v) => !!v || this.errorMessage],
        examinerName :  [(v) => !!v || this.errorMessage],
        fromDate     :  [(v) => !!v || this.errorMessage],
        fromTime     :  [(v) => !!v || this.errorMessage,(v) => !!this.fromdateEnterCheck(v) || "Please Enter 'From Date' first."],
        toDate       :  [(v) => !!v || this.errorMessage, (v) => !!this.fromdateEnterCheck(v) || "Please Enter 'From Date' first."],  
        toTime       :  [(v) => !!v || this.errorMessage,
                        (v) => !!this.sameTimeCheck(v) || "'To Time' should be more than 'From Time'" ,
                        (v) => !!this.toTimeCheck(v) || "Please Enter 'To Date'  first."
        ],
        reason       :  [(v) => !!v || this.errorMessage, (v) => this.spchCharPattern.test(v) || this.spchCharMsg ],
        message      :  [ v => {if (v) return this.spchCharPattern.test(v) || this.spchCharMsg; else return true}]
      }
    };
  },
  computed:{
    makeTimeEnable(){
     return  this.markUserOffline.toDate=='' ? false : true;
    },
  },
  methods: {
    toTimeCheck(){
     if(this.markUserOffline.fromTime==''){
           return false;
      }else if(this.markUserOffline.toDate==''){
         return false;
      }else{
          return true;    
      }
    },
    fromdateEnterCheck(){
       return this.markUserOffline.fromDate == '' ? false : true ;
    },
    formatDate(date) {
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    ISOtoDDMMYY(date) {
      if (date !== undefined) {
        return moment(date).format("DD/MM/YYYY");
      } else {
        return date;
      }
    },
    toDisableFromTime(){
          if(	this.fromDateFormatted == moment().format("YYYY-MM-DD")){
              this.currentFromTime = moment().format("HH:mm");
              this.markUserOffline.fromTime ="";
          }else{
            this.currentFromTime ="";
          }
          this.markUserOffline.toDate="";
    },
   toDisableToTime(){
          if(	this.toDateFormatted == moment().format("YYYY-MM-DD")){
              this.currentToTime = moment().format("HH:mm");
              this.markUserOffline.toTime ="";
          }else{
            this.currentToTime ="";
          }       
    },
    sameTimeCheck(){
          let FT = this.markUserOffline.fromTime.split(":")
          this.formTimeTimeStamp = new Date(this.fromDateFormatted).setHours(FT[0],FT[1], 0, 0); 
          let TT  = this.markUserOffline.toTime.split(':');
          this.toTimeTimeStamp  = new Date(this.toDateFormatted).setHours(TT[0],TT[1], 0, 0);
        if(this.fromDateFormatted==this.toDateFormatted){
            if(this.formTimeTimeStamp == this.toTimeTimeStamp){
              return false;
            }else if(this.formTimeTimeStamp > this.toTimeTimeStamp){
               return false;
            }
            return true;           
          }
        return true;
    },
      toISOStringConvert(dateString) {
         return new Date(dateString).toISOString();
      },
     userOfflineSubmit() {
      if (this.$refs.Formvalid.validate()) {
          this.showProgress("Saving User Offline,please wait");
          let requestData= {
              examinerId: this.markUserOffline.examinerId,
              examinerName:this.markUserOffline.examinerName,
              fromDate: this.toISOStringConvert(this.fromDateFormatted),
              fromTime: this.formTimeTimeStamp.toString(),
              toDate: this.toISOStringConvert(this.toDateFormatted),
              toTime: this.toTimeTimeStamp.toString() ,
              reason :this.markUserOffline.reason,
              message :this.markUserOffline.message
           }
         this.POST_ByURLM("CREATE_UPDATE_USER_OFFLINE",requestData,(res) => {
              this.hideProgress();
              this.showAlert(res.data.msg);
          },
          (error) => {
                   this.hideProgress();
                  if(error.response.data.msg){
                    this.showAlert(error.response.data.msg);
                  }else if(error.response.data.message){
                    this.showAlert(error.response.data.message);
                  }else{
                    this.showAlert("Some thing went wrong");
              }
          }
        );
      }
    },
  },
  created(){
      this.userDetails = this.$store.getters.fetchName;
      this.markUserOffline.examinerName =this.userDetails.first + " "+ this.userDetails.last; 
      this.markUserOffline.examinerId=this.$store.getters.fetchLoginID;
  }
};
</script>

<style scoped>
.iconFontSize{
 font-size: 18px ;
}
.forminput {
  border-radius: 0;
}
.groupRowHead {
  margin-top: 16px;
  background-color: #01bbd9;
  height: 43px;
}
.GroupName {
  font-size: 15px;
  color: white;
  font-weight: bold;
}
.iconstyle {
  margin-left: -12px;
  background-color: rgb(247, 247, 247);
  width: 45px;
  height: 38px;
  font-size: 20px !important;
  border: 0.3px solid rgb(232, 235, 236);
  opacity: 1;
}

.btnDiv {
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #f7f7f7;
}
</style>