<template>
  <v-app>
    <v-form v-model="userSearch" ref="userSearch">
      <div style="background-color: #ffffff" column class="">
        <template>
          <v-row justify="center">
            <v-dialog
              v-model="dialogTable"
              persistent
              max-width="70%"
              style="border-radius: 0;"
              content-class="dialogStyle"
            >
            <!-- style="overflow:hidden" -->
              <v-card>
                <v-card-title
                  class="pl-0 px-0 py-0 dailogboxAllvcardTableBackground"
                >
                  <v-row no-gutters class="colorbg">
                    <span class="descrptionSpan mb-2 ml-8 mr-1">Search</span>
                    <v-icon
                      @click="dialogTable = false"
                      class="mt-2"
                      color="#fff"
                      >mdi-window-close</v-icon
                    >
                  </v-row>
                  <v-card-text class="vcardTextTableBackground pl-2">
                    <v-form ref="searchForm">
                      <v-layout>
                        <v-flex xs3>
                          <v-layout column class="cust_pd mx-2">
                            <p class="font-weight-bold cstm-clr-pd">NTID/User ID</p>
                            <v-text-field
                              solo
                              label="Enter..."
                              v-model="userID"
                              :rules="[spclCharNot]"
                              class="rounded-0"
                              :maxlength="max30"
                              :disabled="!disableAll"
                            >
                            </v-text-field>
                          </v-layout>
                        </v-flex>
                        <v-flex xs3>
                          <v-layout column class="mx-2 cust_pd">
                            <p class="font-weight-bold cstm-clr-pd">
                              Employee ID
                            </p>
                            <v-text-field
                              solo
                              label="Enter..."
                              v-model="employeeID"
                              :rules="[spclCharNot]"
                              class="rounded-0"
                              :maxlength="max30"
                              :disabled="!disableAll"
                            >
                            </v-text-field>
                          </v-layout>
                        </v-flex>

                        <v-flex xs3>
                          <v-layout column class="mx-2 cust_pd">
                            <p class="font-weight-bold cstm-clr-pd">
                              Employee Name
                            </p>
                            <v-text-field
                              solo
                              label="Enter..."
                              v-model="employeeName"
                              :rules="[onlyText]"
                              class="rounded-0"
                              :maxlength="max30"
                              :disabled="!disableAll"
                            >
                            </v-text-field>
                          </v-layout>
                        </v-flex>
                        <v-flex xs3>
                          <v-layout column class="mx-2 cust_pd">
                            <p class="font-weight-bold cstm-clr-pd">
                              LOB
                            </p>
                            <v-select
                              solo
                              label="Select LOB..."
                              v-model="bylob"
                              clearable
                              class="rounded-0"
                              :items="itemsOfLOB"
                            >
                            </v-select>
                          </v-layout>
                        </v-flex>
                      </v-layout>

                      <v-layout>
                        <v-flex xs3>
                          <v-layout column class="mx-2 cust_pd">
                            <p class="font-weight-bold cstm-clr-pd">
                              Settlement Office Name
                            </p>
                            <v-autocomplete
                              :items="SETOFF_name"
                              label="Select Settlement Office Name *"
                              v-model="settleOfficeName"
                              maxlength="50"
                              solo
                              dense
                              item-text="display"
                              item-value="_id"
                              return-object
                              :rules="[
                                () =>
                                  !!settleOfficeName ||
                                  'This field is required',
                              ]"
                              class="rounded-0"
                            >
                            </v-autocomplete>
                          </v-layout>
                        </v-flex>

                        <v-flex xs3>
                          <v-layout
                            column
                            class="cust_pln_em cust-rmvpd-em mx-2"
                          >
                            <p class="font-weight-bold cstm-clr-pln-em">
                              Mobile Number <span v-if="naForServiceId">*</span>
                            </p>
                            <v-text-field
                              solo
                              label="Enter..."
                              v-model="mobilenumber"
                              :rules="[onlynumberallowed()]"
                              class="rounded-0"
                              :maxlength="max10"
                              :disabled="!disableAll"
                            >
                              <div
                                slot="prepend-inner"
                                style="
                                  background-color: #f7f7f7;
                                  padding: 11px !important;
                                "
                              >
                                <v-icon dense>local_phone</v-icon>
                              </div>
                            </v-text-field>
                          </v-layout>
                        </v-flex>
                        <v-flex xs3>
                          <v-layout
                            column
                            class="cust_pln_em cust-rmvpd-em mx-2"
                          >
                            <p class="font-weight-bold cstm-clr-pln-em">
                              E-mail <span v-if="naForServiceId">*</span>
                            </p>
                            <v-text-field
                              solo
                              label="Enter..."
                              v-model="mailid"
                              :rules="[emailcheck]"
                              class="rounded-0"
                              maxlength="100"
                              :disabled="!disableAll"
                            >
                              <div
                                slot="prepend-inner"
                                style="
                                  background-color: #f7f7f7;
                                  padding: 11px !important;
                                "
                              >
                                <v-icon dense>mail</v-icon>
                              </div>
                            </v-text-field>
                          </v-layout>
                        </v-flex>

                        <v-flex xs3>
                          <v-layout column class="mx-2 cust_pd">
                            <p class="font-weight-bold cstm-clr-pd">Agreement Type</p>
                            <v-select
                              solo
                              label="Select Type..."
                              v-model="usertype"
                              clearable
                              class="rounded-0"
                              :items="['Employee', 'External Resource']"
                              @click:clear="$nextTick(() => typeofemployeesearch='')"
                            >
                            </v-select>
                          </v-layout>
                        </v-flex>
                      </v-layout>

                      <v-layout>
                        <v-flex xs3>
                          <v-layout column class="mx-2 cust_pd">
                            <p class="font-weight-bold cstm-clr-pd">
                              Type Of Employee
                            </p>
                            <v-select
                              solo
                              label="Select Type Of Employee"
                              v-model="typeofemployeesearch"
                              clearable
                              class="rounded-0"
                              :items="whatemployeewehave"
                              :disabled="!usertype"
                            >
                            </v-select>
                          </v-layout>
                        </v-flex>
                        <v-flex xs3>
                          <v-layout column class="mx-2 cust_pd">
                            <p class="font-weight-bold cstm-clr-pd">Status</p>
                            <v-select
                              solo
                              label="Select Status"
                              v-model="statussearch"
                              clearable
                              class="rounded-0"
                              :items="statusOptions"
                              item-text="show"
                              item-value="status"
                            >
                            </v-select>
                          </v-layout>
                        </v-flex>
                        <v-flex xs3>
                          <v-layout column class="mx-2 cust_pd">
                            <p class="font-weight-bold cstm-clr-pd">Zone</p>
                            <v-select
                              solo
                              label="Select Zone"
                              v-model="zonesearch"
                              clearable
                              class="rounded-0"
                              :items="zoneLstItms"
                            >
                            </v-select>
                          </v-layout>
                        </v-flex>
                        <v-flex xs3>
                        </v-flex>
                      </v-layout>

                      <v-row no-gutters>
                        <v-col align="left">
                          <v-btn
                            color="red"
                            class="btncommonstyle mr-3"
                            @click="dialogTable = false"
                            >Cancel
                          </v-btn>
                        </v-col>
                        <v-col align="right">
                          <v-btn
                            class="btncommonstyle cancelbtn mr-3"
                            @click="clearfields"
                            >Clear
                          </v-btn>
                          <v-btn
                            color="#23B1A9"
                            dark
                            class="btncommonstyle mr-2"
                            @click="()=>{this.page=1;searchUser(1,true)}"
                            >Search
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-card-title>
              </v-card>
            </v-dialog>
          </v-row>
        </template>

        <v-row>
          <p class="ml-8 mt-6 headfont">List of Users</p>
          <v-btn
            close
            class="px-3 searchtxt"
            height="30"
            dark
            color="#23b1a9"
            @click="clearfields"
            v-if="shwclrBtn === true"
            >Search Results
            <v-icon
              class="ml-2 fontStyle"
              >mdi-close</v-icon
            >
          </v-btn>
          <!-- <span v-if="shwTable === true" class="internalnotify mt-1 mr-4">{{
              totalLen
            }}   </span> -->

          <span
            v-if="shwTable === true"
            :class="totalLen < 100 ? 'internalnotify1' : 'internalnotify'"
            >{{ totalLen }}
          </span>

          <v-col align="right" class="mb-2 mt-3 mr-5">
            <v-btn
              class="createbtn"
              color="#23B1A9"
              :dark="disableAll"
              :disabled="!disableAll"
              @click="dialogTableOpen"
            >
              <v-icon class="mr-2 fontStyle">mdi-magnify</v-icon>
              <div class="vertical_line"></div>
              Search
            </v-btn>

            <v-btn
              width="200"
              class="btnstyle ml-10"
              color="#1EBBD9"
              dark
              @click="createNewUser()"
              ><v-icon class="mr-2 iconsize">mdi-plus-box </v-icon>
              <div class="vertical_line"></div>
              Create New User
            </v-btn>
          </v-col>
        </v-row>
        <!-- <v-card style="max-height:150px" class="mt-2 rounded-0">
                          <div class="mx-2 pa-2" style="background-color:#eee; display:flex;justify-content:space-between;margin-left:0px!important;margin-right:0px!important;"  >
                          <v-layout style="flex:1" justify-end > 
                          <v-btn :dark='disableAll' :disabled="!disableAll"  @click="searchUser(page)"  tile color="#23B1A9" class="text-capitalize px-3 mx-2 my-2" small><v-icon dark small>search</v-icon> <v-divider vertical class="mx-2"></v-divider>Search</v-btn>
                            <v-btn :dark='disableAll' :disabled="!disableAll"  @click="clearfields()"  tile color="#E46A25" class="text-capitalize px-3 mx-2 my-2" small><v-icon dark small>close</v-icon> <v-divider vertical class="mx-2"></v-divider>Clear</v-btn>
                          <v-btn dark  @click="$router.push('/home')" tile color="#E46A25" class="text-capitalize px-3 mx-2 my-2" small><v-icon dark small>cancel</v-icon> <v-divider vertical class="mx-2"></v-divider>Cancel</v-btn>
                          <v-btn :dark='disableAll' :disabled="!disableAll"   @click="createNewUser()" tile color="black" class="text-capitalize px-3 ml-2 mr-2 my-2" small><v-icon dark small>add_circle</v-icon> <v-divider vertical class="mx-2"></v-divider>Create New User</v-btn>
                          </v-layout>
                          </div>
                      </v-card> -->

        <v-card class="mt-4 rounded-0">
          <div v-if="shwTable === true">
            <!-- @dblclick:row="viewEditUser" -->
            <v-data-table
              style="cursor: pointer"
              :headers="headers"
              :items="userData"
              hide-default-footer
              
              dense
            >
              <template v-slot:[`item.action`]="{ item,index }">
                <v-menu
                  transition="slide-x-transition"
                  bottom
                  right
                  :nudge-left="0"
                  :nudge-bottom="24"
                  content-class="my-menu-bnkmstr"
                  flat
                  allow-overflow
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on">mdi-dots-vertical</v-icon
                    >{{ item.action }}
                  </template>
                  <v-list>
                    <v-list-item
                      dense
                      style="min-height: 28px !important"
                      v-for="(actionOption, actionIndex) in menuItems"
                      :key="actionIndex"
                      class="pa-0 ma-0"
                      @click="clickOnMenu(actionOption, index,item)"
                    >
                      <v-list-item-icon class="pa-2 pt-2 ma-0 mr-0">
                        <v-icon small v-text="actionOption.icon" :color="actionOption.color"
                          >mdi-{{ actionOption.icon }}</v-icon
                        >
                      </v-list-item-icon>
                      <v-divider vertical class="mx-1"></v-divider>
                      <v-list-item-title
                        class="pa-1 pr-3 ma-0 mr-0"
                        style="font-size: 12px !important"
                        >{{ actionOption.title }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>

              <template v-slot:[`item.ntidUserId`]="{ item }">
                <div>{{ item.ntidUserId }}</div>
              </template>

              <template v-slot:[`item.employeeId`]="{ item }">
                <div>
                  {{ item.employeeId }}
                </div>
              </template>
              <template v-slot:[`item.AGT`]="{ item }">
                <div>
                  {{ item.AGT }}
                </div>
              </template>

              <template v-slot:[`item.typeOfEmployee`]="{ item }">
                <div>
                  {{ item.typeOfEmployee }}
                </div>
              </template>

              <template v-slot:[`item.active`]="{ item }">
                <div>
                  {{ item.active ? "Active" :"Inactive" }}
                </div>
              </template>

              <template v-slot:[`item.joiningDate`]="{ item }">
                <div>
                  {{ formatDate(item.joiningDate) }}
                </div>
              </template>

              <template v-slot:[`item.assignRole`]="{ item }">
                <v-layout>
                  <div
                    class="mr-1"
                    v-for="(lst, index) in item.assignRole"
                    :key="index"
                  >
                    {{ lst.roleName
                    }}<span v-if="item.assignRole.length > 1 && item.assignRole.length-1 !== index">,</span>
                  </div>
                </v-layout>
              </template>

              <template v-slot:[`item.userZoneMapping`]="{ item }">
                <v-layout>
                  <div
                    class="mr-1"
                    v-for="(lst, index) in item.userZoneMapping"
                    :key="index"
                  >
                    {{ lst.zone
                    }}<span v-if="item.userZoneMapping.length > 1 && item.userZoneMapping.length-1 !== index">,</span>
                  </div>
                </v-layout>
              </template>
            </v-data-table>

            <v-layout
              row
              justify-end
              align-center
              class="mx-2 headercolor"
              style="margin-left: 0px !important; margin-right: 0px !important"
            >
              <p
                class="mr-1 font-weight-bold body-1"
                style="color: #3c3d3d; font-size: 12px !important"
              >
                <span class="" style="color: #e46a25; font-size: 12px"
                  >Showing {{ fval }}-{{ sval }} </span
                >out of {{ totalLen }} records
              </p>
              <v-divider class="mx-2" vertical></v-divider>
              <v-pagination
                class="cust-bnkAcc-pgn"
                v-model="page"
                color="#152F38"
                flat
                :total-visible="9"
                @input="nextPageBtn(page)"
                :length="pagelength"
              ></v-pagination>
            </v-layout>
          </div>
        </v-card>
      </div>
    </v-form>

    <!-- table popup -->
    <v-dialog v-model="dialogTable1">
      <v-card color="#F7F7F7" class="pb-2" tile>
        <v-card>
          <v-layout
            justify-space-between
            class=""
            style="flex: 1; background-ox-shadow: 0px 3px 5px #00000029"
          >
            <v-card-title
              class="pa-0 pl-3 text-capitalize"
              style="color: #10242b; font-size: 16px"
              >Audit Trail of User | {{ checkdatais(settleTableDataNew) }}
            </v-card-title>

            <div
              style="border-left: 1px solid #c1c8cc"
              @click="dialogTable1 = false"
            >
              <v-icon style="cursor: pointer" class="pa-2">close</v-icon>
            </div>
          </v-layout>
        </v-card>
        <!-- <v-data-table
            class="pa-4"
            :headers="headersNew"
            :items="settleTableDataNew"
            hide-default-footer
            dense
            disable-pagination
          >
            <template
              v-for="header in headersNew.filter((header) =>
                header.hasOwnProperty('formatter')
              )"
              v-slot:[`item.${header.value}`]="{ header, value }"
            >
              {{ header.formatter(value) }}
            </template>
          </v-data-table> -->
        <v-divider></v-divider>
        <v-layout
          row
          justify-end
          align-center
          style="background-color: #f7f7f7"
          class="pa-2 mr-3"
        >
          <span
            class="mr-1 font-weight-bold body-1"
            style="color: #3c3d3d; font-size: 12px !important"
          >
            <span class="" style="color: #e46a25; font-size: 12px"
              >Showing {{ fromPage }}-{{ toPage }} </span
            >out of {{ totalPages }} records</span
          >
          <v-divider class="ma-2" vertical></v-divider>
          <v-pagination
            class="pdct-stUp-bnkAcc-pgn"
            v-model="page"
            color="#152F38"
            flat
            @input="nextPageData(page)"
            :length="pageLengthNew"
          ></v-pagination>
        </v-layout>
      </v-card>
    </v-dialog>
  </v-app>
</template>
  
  <script>
import moment from "moment";
import axios from "axios";
export default {
  created() {
    this.$store.commit("SAVE_PAGE_HEADER", "Create/Search User");
    this.getSettlementMastrData();
    this.getAllSettlementOffice();
    // Surendra(21-Sep-2021):LOB List from BE,code starts here
    this.GET(3, "line-of-business/get-line-of-business-list", (res, error) => {
      if (!error) {
        console.log("response from api is itemsOfLOB = ", res.data.data);
        this.itemsOfLOB = res.data.data[0].lineOfBusiness;
      } else {
        console.log("error", error.response.data);
      }
    });
    //Surendra(21-Sep-2021):LOB List from BE,code ends here
    this.searchUser(1,false);
  },

  data() {
    return {
      zonesearch: "",
      zoneLstItms: [],
      dialogTable: false,
      dialogTable1: false,
      mobilenumber: "",
      mailid: "",
      usertype: "",
      typeofemployeesearch: "",
      statussearch: "",
      settleOfficeName: {},
      settleOfficeItemsArray: [],
      bylob: "",
      counter: 0,
      Level: "",
      lob: "",
      lobItems: ["PA", "Health"],
      fval: 0,
      sval: 0,
      totalLen: "",
      page: 1,
      userID: "",
      employeeID: "",
      employeeName: "",
      userSearch: false,
      shwTable: false,
      shwclrBtn: false,
      userData: [],
      pagelength: 0,
      itemsPerPage: 10,
      max30: 30,
      max10: 10,
      GetData: [
        {
          SerialNumber: "1",
          userID: "12345",
          employeeID: "PA",
          employeeName: "40,000",
          cgRole: "20,000",
        },
        {
          SerialNumber: "2",
          userID: "12345",
          employeeID: "PA",
          employeeName: "40,000",
          cgRole: "20,000",
        },
      ],
      headers: [
        {
          text: "Action",
          value: "action",
          sortable: false,
          divider: true,
          class: "hdr-txt-clr-userSrch hdr-bg-clr",
        },
        {
          text: "User ID",
          value: "ntidUserId",
          sortable: false,
          divider: true,
          class: "hdr-txt-clr-userSrch hdr-bg-clr",
        },
        {
          text: "Employee ID",
          value: "employeeId",
          sortable: false,
          divider: true,
          class: "hdr-txt-clr-userSrch hdr-bg-clr",
        },
        {
          text: "LOB",
          value: "selectedLOB",
          sortable: false,
          divider: true,
          class: "hdr-txt-clr-userSrch hdr-bg-clr",
        },
        {
          text: "Employee Name",
          value: "employeeName",
          sortable: false,
          divider: true,
          class: "hdr-txt-clr-userSrch hdr-bg-clr",
        },
        {
          text: "Joining Date",
          value: "joiningDate",
          sortable: false,
          divider: true,
          class: "hdr-txt-clr-userSrch hdr-bg-clr",
        },
        {
          text: "Type",
          value: "AGT",
          sortable: false,
          divider: true,
          class: "hdr-txt-clr-userSrch hdr-bg-clr",
        },
        {
          text: "Type of Employee",
          value: "typeOfEmployee",
          sortable: false,
          divider: true,
          class: "hdr-txt-clr-userSrch hdr-bg-clr",
        },
        {
          text: "CG Role",
          value: "assignRole",
          sortable: false,
          divider: true,
          class: "hdr-txt-clr-userSrch hdr-bg-clr",
        },
        {
          text: "Zone",
          value: "userZoneMapping",
          sortable: false,
          divider: true,
          class: "hdr-txt-clr-userSrch hdr-bg-clr",
        },
        {
          text: "Mobile Number",
          value: "contactMobileNo",
          sortable: false,
          divider: true,
          class: "hdr-txt-clr-userSrch hdr-bg-clr",
        },
        {
          text: "Mail ID",
          value: "email",
          sortable: false,
          divider: true,
          class: "hdr-txt-clr-userSrch hdr-bg-clr",
        },
        {
          text: "Status",
          value: "active",
          sortable: false,
          divider: true,
          class: "hdr-txt-clr-userSrch hdr-bg-clr",
        },
      ],
      settleTableDataNew: [],
      headersNew: [
        {
          text: "User NTID",
          value: "ntidUserId",
          sortable: false,
          divider: true,
          class: "hdr-txt-clr-userSrch hdr-bg-clr",
        },
        {
          text: "Employee ID",
          value: "employeeId",
          sortable: false,
          divider: true,
          class: "hdr-txt-clr-userSrch hdr-bg-clr",
        },
        {
          text: "LOB",
          value: "selectedLOB",
          sortable: false,
          divider: true,
          class: "hdr-txt-clr-userSrch hdr-bg-clr",
        },
        {
          text: "Employee Name",
          value: "employeeName",
          sortable: false,
          divider: true,
          class: "hdr-txt-clr-userSrch hdr-bg-clr",
        },
        {
          text: "Joining Date",
          value: "joiningDate",
          sortable: false,
          divider: true,
          class: "hdr-txt-clr-userSrch hdr-bg-clr",
          formatter: this.formatDate,
        },
        {
          text: "Type of Agreement",
          value: "AGT",
          sortable: false,
          divider: true,
          class: "hdr-txt-clr-userSrch hdr-bg-clr",
        },
        {
          text: "Type of Employee",
          value: "typeOfEmployee",
          sortable: false,
          divider: true,
          class: "hdr-txt-clr-userSrch hdr-bg-clr",
        },
        {
          text: "CG Role",
          value: "assignRole",
          sortable: false,
          divider: true,
          class: "hdr-txt-clr-userSrch hdr-bg-clr",
        },

        {
          text: "City",
          value: "city",
          sortable: false,
          divider: true,
          class: "hdr-txt-clrApproval hdr-bg-clr",
        },
        {
          text: "State",
          value: "state",
          sortable: false,
          divider: true,
          class: "hdr-txt-clrApproval hdr-bg-clr",
        },
        {
          text: "Settlement Office Code",
          value: "settlementOfficeCode",
          sortable: false,
          divider: true,
          class: "hdr-txt-clrApproval hdr-bg-clr",
        },
        {
          text: "Settlement Office Name",
          value: "settlementOfficeName",
          sortable: false,
          divider: true,
          class: "hdr-txt-clrApproval hdr-bg-clr",
        },
        {
          text: "Zone",
          value: "zone",
          sortable: false,
          divider: true,
          class: "hdr-txt-clrApproval hdr-bg-clr",
        },
        {
          text: "Corporate Office",
          value: "corporateOffice",
          sortable: false,
          divider: true,
          class: "hdr-txt-clrApproval hdr-bg-clr",
        },
        {
          text: "ECU",
          value: "ecu",
          sortable: false,
          divider: true,
          class: "hdr-txt-clrApproval hdr-bg-clr",
        },
        {
          text: "Changed By",
          value: "changedBy",
          sortable: false,
          divider: true,
          class: "hdr-txt-clrApproval hdr-bg-clr",
        },
        {
          text: "Status",
          value: "active",
          sortable: false,
          divider: true,
          class: "hdr-txt-clrApproval hdr-bg-clr",
        },
        {
          text: "FA Approval status",
          value: "approvalStatus",
          sortable: false,
          divider: true,
          class: "hdr-txt-clrApproval hdr-bg-clr",
        },
        {
          text: "Initiated By",
          value: "initiatedBy",
          sortable: false,
          divider: true,
          class: "hdr-txt-clrApproval hdr-bg-clr",
        },
        {
          text: "Initiated Date & Time",
          value: "initiatedDate",
          sortable: false,
          divider: true,
          class: "hdr-txt-clrApproval hdr-bg-clr",
        },
        {
          text: "Approved By",
          value: "approvedBy",
          sortable: false,
          divider: true,
          class: "hdr-txt-clrApproval hdr-bg-clr",
        },
        {
          text: "Approved Date & Time",
          value: "approvedDate",
          sortable: false,
          divider: true,
          class: "hdr-txt-clrApproval hdr-bg-clr",
        },
      ],

      menuItems: [
        { title: "VIEW", icon: "mdi-eye", color: "black" },
        { title: "MODIFY", icon: "mdi-pencil", color: "green" },
        {
          title: "AUDIT TRAIL",
          icon: "mdi-chart-line-variant",
          color: "orenge",
        },
      ],
      required() {
        return (v) => (v && v.length > 0) || "This Field is Required";
      },
      spclChar() {
        return (v) =>
          /^[A-Za-z0-9 ]+$/.test(v) || "Special Characters Not Allowed";
      },
      disableAll: true,
      fromPage: 0,
      totalPages: 0,
      toPage: 0,
      pageLengthNew: 0,
      statusOptions: [
        { show: "Active", status: "true" },
        { show: "Inactive", status: "false" },
        // { show: "Pending", status: "Pending" },
      ],
      naForServiceId: false,
      itemsOfLOB: [],
    };
  },

  computed: {
    whatemployeewehave() {
      let a = ["Full Time", "Contractual Employee", "Outsource"];
      let b = [
        "External",
        "Service ID",
        "Investigator",
        "Surveyor",
        "Other",
        "Vendor",
        "Repairer",
        "Outsource",
      ];
      if (this.usertype == "Employee") {
        return a;
      } else if (this.usertype == "External Resource") {
        return b;
      } else {
        return [];
      }
    },
    SETOFF_name() {
      return this.settleOfficeItemsArray.sort((a, b) =>
        a.settlementOfficeName.localeCompare(b.settlementOfficeName)
      );
    },

    filternow() {
      if (this.bylob == "") {
        return this.userData;
      } else {
        this.userData.filter((e) => {
          if (e.lob == this.bylob) {
            return e;
          }
        });
      }
    },
  },

  methods: {
    getAllSettlementOffice() {
      this.GET(6, "settlementoffice/get-zone-list", (res, error) => {
        if (!error) {
          if (res.data.statusCode === 200) {
            this.zoneLstItms = [];
            this.zoneLstItms = res.data.data;
            console.log(
              res.data.data,
              "settlementoffice/all-settlement-office"
            );
          } else {
            this.showToast(res.data.msg, this.TOST().ERROR);
            this.showAlert(res.data.msg);
          }
        } else {
          this.showToast(error.response.data.msg, this.TOST().WARNING);
          this.showAlert(error.response.data.msg);
        }
      });
    },
    clickOnMenu(action, index,item) {
      if (action.title  =="VIEW") {
        this.viewEditUser("view", { item: item });
        // console.log("zero index========", item);
      }else if (action.title  =="MODIFY") {
        // console.log("modify==============>", this.userData[index]);
        // this.redirectUrl(item);
        // console.log("zero index========", item);
        this.viewEditUser("edit", { item: item });
      }else if (action.title  =="AUDIT TRAIL") {
        this.view_item(item);
      }
    },
    checkdatais(indata) {
      let datais = "";
      if (indata.length != 0) {
        if (indata[0].employeeName) {
          datais = indata[0].employeeName + " - ";
          datais += indata[0].ntidUserId ? indata[0].ntidUserId : "";
          console.log(indata[0].employeeName, "indata", datais);
          return datais;
        }
      } else {
        return "-";
      }
    },

    getSettlementMastrData() {
      let self = this;
      let theheaders = this.authHeaderNew();
      theheaders.system = "CM" ;//this.user_details().lob ? this.user_details().lob : 
      axios({
        method: "get",
        url:
          self.API_Service_USER_MGMNT() +
          "settlementoffice/search-settlement-office",

        headers: theheaders,
      })
        .then(function (res, error) {
          if (!error) {
            if (res.data.statusCode === 200) {
              console.log(res, "resapi response");
              // self.showToast(res.data.msg, self.TOST().SUCCESS);

              if (res.data.hasOwnProperty("data")) {
                if (Object.keys(res.data.data).length != 0) {
                  self.settleOfficeItemsArray = res.data.data.data.map((e) => {
                    return {
                      display:
                        e.settlementOfficeName + " - " + e.settlementOfficeCode,
                      ...e,
                    };
                  });

                  return;
                }
              }
            }
          }
        })
        .catch((e) => {
          console.log("catch is ", e);
        });

      return;
    },

    agtis(indata) {
      // console.log("agtis", indata);
      let AGTIS = "-";
      if (indata.employee) {
        AGTIS = "Employee";
      }

      if (indata.externalResource) {
        AGTIS = "External Resource";
      }
      return AGTIS;
    },

    spclCharNot(v) {
      if (v.length !== 0) {
        // console.log("testing", /^[0-9 ]+$/.test(v));
        if (!/^[A-Za-z0-9_ ]+$/.test(v)) {
          return "Special Characters Not Allowed";
        } else {
          // console.log("else none");
          return true
        }
      }
    },
    onlyText(v) {
      if (v.length !== 0) {
        // console.log("testing", /^[0-9 ]+$/.test(v));
        if (!/^[a-zA-Z,]+(\s{0,1}[a-zA-Z, ])*$/.test(v)) {
          return "Only Alphabets are Allowed";
        } else {
          // console.log("else none");
          return true
        }
      }else{
        return true
      }
    },

    emailcheck(v) {
      if (v.length !== 0) {
        // console.log("testing", /^[0-9 ]+$/.test(v));
        if (!/^[a-zA-Z0-9.! #$%&'*+/=? ^_`{|}~-]+@[a-zA-Z0-9-.]+(?:\. [a-zA-Z0-9-]+)*$/.test(v)) {
          return "Email Format Not Correct";
        } else {
          // console.log("else none");
          return true
        }
      }else{
        return true;
      }
    },

    onlynumberallowed() {
      // return v=>/^(\s*|\d+)$/.test(v) || `Only Numbers are allowed.`
      return (v) => /^[0-9]*$/.test(v) || `Only Numbers are allowed.`;
    },
    nextPageBtn(pgNo) {
      // console.log("page no value", pgNo);
      this.searchUser(pgNo,true);
    },
    createNewUser() {
      let btnName = {
        editUser: false,
        user_id: null,
        ntid:null
      };
      this.$store.commit("USER_CREATE_EDIT_DATA", btnName);
      this.$router.push("/employeemaster");
    },
    viewEditUser(event, dta) {
      console.log(event, "Roww dta====>", dta);

      let btnName = {
        editUser: event == "edit" ? true : false,
        user_id: dta.item._id,
        ntid:dta.item.ntidUserId
      };
      this.$store.commit("USER_CREATE_EDIT_DATA", btnName);
      this.$router.push("/employeemaster");
    },

    formatDT(indata) {
      console.log("indata is", indata);
      if (indata == undefined || indata == null || indata == "") {
        return "-";
      } else {
        return indata.split("T")[0];
      }
    },

    searchUser(pageNum,showHideClrBtn) {
      this.dialogTable = false;
      this.shwclrBtn = showHideClrBtn;

      let employeesearch = "";
      let externalresource = "";

      if (this.usertype == "") {
        employeesearch = "";
        externalresource = "";
      } else {
        employeesearch = this.usertype == "Employee" ? true : false;
        externalresource = this.usertype == "External Resource" ? true : false;
      }

      let setoff = this.settleOfficeName._id;
      setoff = setoff == null || setoff == undefined ? "" : setoff;
      let self = this;
      let api_Is =  "users/search-users?perPage=" +  this.itemsPerPage + "&pageNo=" +     pageNum;
      this.userID ?    api_Is = api_Is + "&userid=" + this.userID : ""
      this.employeeID ? api_Is = api_Is + "&employeeId=" + this.employeeID : ""
      this.bylob  ?     api_Is = api_Is +  "&lob=" +  this.bylob : ""
      this.employeeName  ?     api_Is = api_Is +  "&employeeName=" +        this.employeeName : ""
      setoff  ?     api_Is = api_Is +  "&settlementOffice=" +        setoff : ""
      this.mobilenumber  ?     api_Is = api_Is +  "&phone=" +        this.mobilenumber : ""
      this.mailid  ?     api_Is = api_Is +  "&email=" +       this.mailid : ""
      this.typeofemployeesearch  ?     api_Is = api_Is +  "&typeOfEmployee=" + this.typeofemployeesearch : ""
      // this.usertype  ?     api_Is = api_Is +  "&typeOfEmployee=" + this.usertype : ""
      this.statussearch  ?     api_Is = api_Is +  "&active=" +        this.statussearch : ""
      employeesearch  ?     api_Is = api_Is +  "&employee=" +        employeesearch : ""
      externalresource  ?     api_Is = api_Is +  "&externalResource=" +       externalresource :    ""   
      this.zonesearch  ?     api_Is = api_Is +  "&zone=" +  this.zonesearch : ""
      this.GET(6, api_Is, (res, error) => {
        try {
          if (!error) {
            console.log(
              "GET APi response|->/users/search-users",
              res.data.data
            );
            this.userData = [];
            this.userData = res.data.data.data.map(function (
              currentValue,
              Index
            ) {
              currentValue.AGT = self.agtis(currentValue);
              currentValue.SerialNumber = 10 * pageNum - 9 + Index;
              return currentValue;
            });

            console.log("this.userData===>", this.userData);
            this.pagelength = res.data.data.total_pages;
            this.totalLen = res.data.data.total;
            const start = pageNum * this.itemsPerPage - this.itemsPerPage,
              end = start + this.itemsPerPage;
            console.log(start, end, "start End");
            if (this.totalLen === 0) {
              this.fval = 0;
            } else {
              this.fval = start + 1;
            }

            if (this.totalLen < this.itemsPerPage) {
              this.sval = this.totalLen;
            } else if (this.totalLen < end) {
              this.sval = this.totalLen;
            } else {
              this.sval = end;
            }
            this.shwTable = true;
          } else {
            console.log("GET APi error response|->/users/search-users", error);
            this.showToast("No Search Result Found", this.TOST().WARNING);
            this.showAlert("No Search Result Found");
            this.shwTable = false;
          }
        } catch (e) {
          console.log("GET APi  errorresponse|-> /users/search-users", e);
        }
      });
    },

    clearfields() {
      this.zonesearch = "";
      this.userID = "";
      this.employeeID = "";
      this.employeeName = "";
      this.bylob = "";
      this.shwTable = false;
      this.shwclrBtn = false;
      this.userData = [];
      this.settleOfficeName = {};
      this.mailid = "";
      this.usertype = "";
      this.typeofemployeesearch = "";
      this.statussearch = "";
      this.mobilenumber = "";
      this.searchUser(1,false);
    },

    dialogTableOpen() {
      this.dialogTable = true;
    },

    pagination(dataFromApi) {
      console.log("data in pagination", dataFromApi);
      this.pageLengthNew = dataFromApi.total_pages;
      this.totalPages = dataFromApi.total;
      var temp = this.page * 10;
      this.fromPage = temp - 9;

      if (this.lastPage) {
        this.toPage = this.totalPages;
        console.log(
          "value of toPage is ",
          this.toPage,
          "\n",
          "value of from page",
          this.fromPage
        );
      } else {
        if (this.totalPages < 10) {
          this.toPage = this.totalPages;
          console.log(
            "this are the pageLength for searchTable",
            this.totalPages
          );
        } else {
          this.toPage = dataFromApi.total;
          console.log(
            "value of toPage is ",
            this.toPage,
            "\n",
            "value of from page",
            this.fromPage
          );
        }
      }
    },

    formatDateTime(transactionTime) {
      return transactionTime && transactionTime !== "-"
        ? moment.utc(transactionTime).local().format("YYYY-MM-DD h:mm A")
        : "-";
    },
    formatDate(transactionTime) {
       return !transactionTime ? "" :  moment(transactionTime).format("DD-MM-YYYY");
        
    },
  },
};
</script>
  
  <style>
.headfont {
  font-weight: 500;
  font-size: 19px;
  margin-top: 6%;
  margin-bottom: 10px;
}

.cust-rmvpd-psins3
  .v-text-field.v-text-field--enclosed
  > .v-input__control
  > .v-input__slot {
  padding-right: 0px !important;
}

.cust_pr2 .v-text-field .v-input__slot,
.v-text-field--outline .v-input__slot {
  min-height: 42px !important;
  display: flex !important;
  align-items: center !important;
}

.cstm-clr-pr3 {
  color: #646666 !important;
  margin-bottom: 5px !important;
  font-size: 13px !important;
}

.cust_pd1 .v-text-field .v-input__slot,
.v-text-field--outline .v-input__slot {
  min-height: 42px !important;
  display: flex !important;
  align-items: center !important;
  /* border:1px red solid; */
}

.cust-rmvpd-pr1
  .v-text-field.v-text-field--enclosed
  > .v-input__control
  > .v-input__slot {
  padding: 0px !important;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 0.84rem !important;
}

.hdr-txt-clr-userSrch {
  color: white !important;
  font-weight: bold;
}

.hdr-bg-clr {
  background-color: #23b1a9;
}
.headercolor {
  background-color: #eee;
}
.cardfont {
  font-size: 0.84rem !important;
  padding-right: 2px;
}
.cust-bnkAcc-pgn .v-pagination__navigation {
  box-shadow: unset !important;
  border-radius: unset !important;
  background-color: #ffffff !important;
  border: 1px solid #dae1e6 !important;
}
.cust-bnkAcc-pgn .v-pagination__navigation--disabled {
  background-color: #dae1e6 !important;
}
.cust-bnkAcc-pgn .v-pagination__item {
  box-shadow: unset !important;
  min-width: 2rem !important;
  height: 2rem !important;
  border-radius: unset !important;
  border: 1px solid #dae1e6 !important;
}
.f-group1 {
  text-align: left;
  font: normal normal bold 13px/21px Roboto;
  letter-spacing: 0px;
  color: #646666 !important;
}
.btncommonstyle {
  text-transform: capitalize;

  height: 31px !important;
  font-size: 11px !important;
  border-radius: 4px;
}

.cancelbtn {
  border: 1px solid #4c4c4c;
}

.dailogboxAllvcardTableBackground {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 7px #00000029;
  opacity: 1;
  font-size: 14px;
}

.vcardTextTableBackground {
  background-color: #b4b4b440 !important;
}

.colorbg {
  background-color: #23b1a9;
  color: #fff;
}

.descrptionSpan {
  width: 90%;
  margin-top: 21px;
  font-weight: 500;
  font-size: 17px;
  letter-spacing: 0.4px;
}

.dialogBoxCardTitle {
  background: #ffffff 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 5px 7px #00000029 !important;
  opacity: 1 !important;
  font-size: 14px !important;
}

.ContainerBGColor {
  background-color: #f7f7f7;
}

.f-group .v-input {
  margin-top: 4px;
  border-radius: 0;
}

.f-group label {
  text-align: left;
  font: normal normal bold 13px/21px Roboto;
  letter-spacing: 0px;
  color: #646666 !important;
}

.text-xsm {
  font: normal normal bold 13px/22px Roboto !important;
}

.form-row {
  border-top: #f7f7ff solid 2px;
  padding: 2em 5em;
}

.overflowNone {
  overflow: hidden !important;
}

/* viewMore code */
.dialogBoxCardTitle {
  background: #ffffff 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 5px 7px #00000029 !important;
  opacity: 1 !important;
  font-size: 14px !important;
}

.ContainerBGColor {
  background-color: #f7f7f7;
}

.tableSize {
  width: 150% !important;
}
.dragLayout {
  width: 385px;
  height: 282px;
  background: #dddddd;
  margin-left: 347px;
  margin-top: -129px;
}
.internalnotify {
  padding: 7px 14px;
  position: absolute;
  background-color: orangered;
  color: #fff;
  left: 150px;
  width: 55px;
  top: 20px;
  height: 30px;
  border-radius: 20%;
  font-size: 13px;
  font-weight: bold;
}
.internalnotify1 {
  padding: 7px 9px;
  position: absolute;
  background-color: orangered;
  color: #fff;
  left: 150px;
  width: 30px;
  top: 20px;
  height: 30px;
  border-radius: 20%;
  font-size: 13px;
  font-weight: bold;
}
.searchtxt {
  margin-left: 100px !important;
  margin-top: 20px !important;
  text-transform: none;
}
.btnstyle {
  height: 37px !important;
  font-size: 15px !important;

  border-radius: 4px;
  text-transform: none;
}
.cstm-clr-pln-em {
  color: #646666 !important;
  margin-bottom: 5px !important;
  font-size: 13px !important;
}
.vDataTableStyling tr th {
  background-color: #01bbd9;
  color: white !important;
  font-size: 20px !important;
  height: 40px !important;
  border: 0.5px ridge #f7f7f7;
  border-width: 1px;
  border-right: none;
  border-collapse: collapse;
  font-weight: bold;
  text-align: center !important;
}

.vDataTableStyling tr td {
  border: 1px solid #f7f7f7;
  border-width: 2px;
  border-right: none;
  border-collapse: collapse;
  height: 40px !important;
  font-size: 15px !important;
  color: #10242b;
  text-align: center !important;
}
</style>