<template>
    <div>
      <v-container fluid>
        <v-layout row>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Diagnosis
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.diagnosis"
              maxlength="100"
              counter="100"
              class="rounded-0"
              aria-required="true"
              disabled="true"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Illness
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.illness"
              
              counter="20"
              class="rounded-0"
              disabled="true"
              aria-required="true"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Illness Description
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.shortDescription"
              class="rounded-0"
              aria-required="true"
              disabled="true"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              ICD Code
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.injuryICDCode"
              
              counter="20"
              class="rounded-0"
              aria-required="true"
              disabled="true"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        </v-layout>
        <v-layout row>
          <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Injury Details
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.injuryDetails"
              maxlength="200"
              counter="200"
              class="rounded-0"
              aria-required="true"
              disabled="true"
            >
            </v-text-field>
          </v-layout>
          </v-flex>
          <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Cause Of Injury
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.injuryCause"
              class="rounded-0"
              aria-required="true"
              disabled="true"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 mb-2 :align-self-end="true">
         <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Type of Admission
            </v-text>
            <v-select
              :items="formData.admissionList"
              label="Select"
              v-model="formData.typeOfAdmission"
              solo
              item-text="state"
              item-value="state"
              dense
              class="rounded-0"
              disabled="true"
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 mb-2 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Whether Accident Claim
            </v-text>
            <v-select
              :items="['Yes', 'No']"
              label="Select"
              v-model="formData.accidentClaim"
              solo
              item-text="state"
              item-value="state"
              dense
              class="rounded-0"
              disabled="true"
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Line of Treatment as per claim form
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.lineOfTreatmentAsPerClaimForm"
              counter="50"
              class="rounded-0"
              aria-required="true"
              disabled="true"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Line of Treatment as per actual
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.lineOfTreatmentAsPerActual"
              counter="50"
              class="rounded-0"
              aria-required="true"
              disabled="true"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 mb-2 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Line of treatment is same as per claim form
            </v-text>
            <v-select
              :items="['Yes', 'No']"
              label="Select"
              v-model="formData.isLineOfTreatmentAsPerClaimForm"
              solo
              item-text="state"
              item-value="state"
              dense
              class="rounded-0"
              disabled="true"
            >
            </v-select>
          </v-layout>
        </v-flex>
        </v-layout>
      </v-container>
    </div>
  </template>
  
  <script>
  import moment from "moment";
  import { getClaimDetailsId } from '../apiservices/formData';
  export default {
    data() {
      return {
        validationmessage: "This field is required",
        rules: {
          mandatoryrule: [(v) => !!v || this.validationmessage],
        },
        admissionList: [],
        formData: {
          diagnosis: "",
          illness: "",
          injuryShortDescription: "",
          injuryICDCode: "",
          injuryDetails: "",
          injuryCause: "",
          typeOfAdmission: "",
          accidentClaim: "",
          lineOfTreatmentAsPerClaimForm:"",
          lineOfTreatmentAsPerActual: "",
          isLineOfTreatmentAsPerClaimForm: "",
          primaryDiagnosis: {},
          shortDescription: ""
        },
      };
    },
    props: ['otherInfo'],
    async mounted() {
      let res =  await getClaimDetailsId(this.$route.params.claimId);
      let diagnosis = '';
      res?.data?.diseaseProcedureClasification?.diagnosis[0]?.length ? res.data.diseaseProcedureClasification.diagnosis[0].forEach((el)=>{ diagnosis = diagnosis + (el.diagnosisName ? (el.diagnosisName + ', ') : '') }) : '';
      this.formData.diagnosis = diagnosis;
      this.formData.illness = res?.data?.diseaseProcedureClasification?.illness[0]?.natureOfIllness || '';
      this.formData.injuryICDCode = res?.data?.diseaseProcedureClasification?.injuryICDCode || '';
      this.formData.injuryDetails = res?.data?.diseaseProcedureClasification?.injuryDetails || '';
      this.formData.injuryCause = res?.data?.diseaseProcedureClasification?.injuryCause || '';
      this.formData.accidentClaim = res?.data?.patientDetailsForHospital?.accident || '';
      this.formData.lineOfTreatmentAsPerClaimForm = res?.data?.diseaseProcedureClasification?.primaryDiagnosis?.lineOfTreatment || '';
      this.formData.shortDescription = res?.data?.diseaseProcedureClasification?.diagnosis[0][0]?.longDescription || '';
    },
    methods: {
      computedDate(name) {
        return this[name] ? moment(this[name]).format("DD/MM/YYYY") : "";
      },
      alphaNumeric(fieldName, name, mandatory = true) {
        if (fieldName && fieldName.length > 0) {
          return (v) => /^[a-zA-Z0-9]+$/.test(v) || "Enter valid " + name;
        }
      },
    },
  };
  </script>
  