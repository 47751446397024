<template>
  <div class="ma-6">
    <!-- Search Fields -->
    <v-card elevation="0" width="100%">
      <v-form class="mt-5 ml-2" @submit.prevent="" ref="searchForm">
    <v-layout class="mt-5" wrap>

        <v-flex class="form-group">

          <label class="form-label">
              LOB 
          </label>
            <v-select
                v-model="searchData.lob"
                label="Select"
                :items="lobOptions"
                solo
                dense
                class="form-control"
            ></v-select>
            </v-flex>

          <v-flex class="form-group">

            <label class="form-label ml-1">Court Type </label>
            <v-select
              class="form-control"
              v-model="searchData.courtType"
              placeholder="Select"
              :items="courtTypeItems"
              dense
              solo
              required
            ></v-select>
            </v-flex>

          <v-flex class="form-group">

            <label class="form-label">Court Name </label>
            <v-text-field
              placeholder="Enter..."
              class="form-control"
              :rules="[(v) => matchRegex(v, 'alphanum')]"
              maxlength="100"
              solo
              dense
              v-model="searchData.courtName"
              depressed
            />
            </v-flex>

          <v-flex class="form-group">

            <label class="form-label">Bank Name </label>
            <v-text-field
              placeholder="Enter..."
              :rules="[(v) => matchRegex(v, 'alphanum')]"
              class="form-control"
              maxlength="100"
              solo
              dense
              v-model="searchData.bankName"
              depressed
            />
            </v-flex>

          <v-flex class="form-group">

            <label class="form-label">Pin Code</label>
            <v-text-field
              placeholder="Enter..."
              class="form-control"
              :rules="[(v) => matchRegex(v, 'alphanum')]"
              maxlength="100"
              solo
              dense
              v-model="searchData.pinCode"
              depressed
            />
            </v-flex>

          <v-flex class="form-group">

            <label class="form-label ml-1">Master Entry Status </label>
            <v-select
              class="form-control"
              v-model="searchData.entryStatus"
              placeholder="Select"
              :items="entryStatusItems"
              dense
              solo
              required
            ></v-select>
</v-flex>
          <v-flex class="form-group">
          <label class="form-label">
              Status 
          </label>
            <v-select
                v-model="searchData.active"
                label="Select"
                :items="[{text:'Active',value:true},{text:'InActive',value:false}]"
                solo
                dense
                class="form-control"
            ></v-select>
            </v-flex>
</v-layout>
        <v-row>
          <v-col class="d-flex align-right pl-1 justify-end">
            <v-btn
              dark
              class="text-capitalize ml-2"
              color="#E46A25"
              depressed
              @click="getsearchData(1)"
            >
              <v-icon>mdi-magnify</v-icon>Search
            </v-btn>
            <v-btn
              dark
              type="submit"
              class="text-capitalize ml-2"
              color="#152F38"
              @click="handleReset"
              depressed
            >
              <v-icon>mdi-reload</v-icon>
              <v-divider class="mx-2 ml-2 white" vertical></v-divider>Reset
            </v-btn>
          </v-col>
        </v-row>
      </v-form>

      <!-- //// -->

      <v-data-table
        class="padding-0 vDataTableStyling"
        :headers="headers"
        :items="courtMasterList"
        :page.sync="page"
        :items-per-page="itemsPerPage"
        :hide-default-footer="true"
        :custom-sort="customSort"
      >
        <template v-slot:top>
          <v-layout
            justify-space-between
            align-center
            style="width: 100%"
            class="py-2"
          >
            <div style="display: flex; align-items: center; column-gap: 8px">
              <div class="page-title-small">
                List Of Examiners
                <span class="text__Orange">({{ totalListCount }})</span>
              </div>
              <v-chip
                small
                v-if="showSearchChip"
                close
                color="#20B7B0"
                text-color="white"
                label
                @click:close="chipclose"
              >
                Search Result
              </v-chip>
            </div>
          </v-layout>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-menu offset-y open-on-click>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                elevation=""
                icon
                v-bind="attrs"
                v-on="on"
                style="text-align: center"
                ><v-icon>mdi mdi-dots-vertical</v-icon></v-btn
              >
            </template>
            <v-list class="item-actions">
              <v-list-item
                v-for="(itemData, index) in dataItems"
                :key="index"
                @click="editmaster(itemData.link, index, item, itemData.name)"
              >
                <v-list-item-title :style="itemData.color">
                  <v-icon small :style="itemData.iconColor"
                    >mdi-{{ itemData.icon }}</v-icon
                  >&nbsp;{{ itemData.name }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <template v-slot:[`item.courtID`]="{ item }">
          {{ item.temporaryReferenceNumber }}
        </template>
        <template v-slot:[`item.courtName`]="{ item }">
          {{ item.operationData[0].court.name }}
        </template>
        <template v-slot:[`item.courtType`]="{ item }">
          {{ item.operationData[0].court.type }}
        </template>
        <template v-slot:[`item.address`]="{ item }">
          {{ item.operationData[0].court.addressLine1 }}
        </template>
        <template v-slot:[`item.city`]="{ item }">
          {{ item.operationData[0].court.city }}
        </template>
        <template v-slot:[`item.pincode`]="{ item }">
          {{ item.operationData[0].court.pinCode }}
        </template>
        <template v-slot:[`item.bankName`]="{ item }">
          {{ item.operationData[0].bank.bankName }}
        </template>
        <template v-slot:[`item.IFSCcode`]="{ item }">
          {{ item.operationData[0].bank.ifscCode }}
        </template>
        <template v-slot:[`item.bankAccountNo`]="{ item }">
          {{ item.operationData[0].bank.accountNumber }}
        </template>
        <template v-slot:[`item.state`]="{ item }">
          {{ item.operationData[0].court.state }}
        </template>
        <template v-slot:[`item.activeStatus`]="{ item }">
          {{ item.operationData[0].court.active ? "Active" : "InActive" }}
        </template>
      </v-data-table>

      <v-layout justify-end align-center style="flex: 0" class="py-2">
        <v-btn outlined color="red" class="btn" @click="cancel">
          <v-icon> mdi-backspace </v-icon>
          <v-divider vertical class="mx-2"></v-divider>
          Cancel
        </v-btn>
        <v-spacer></v-spacer>
        <div>
          <span style="color: orange"
            >Showing {{ pageStartIndex }}-{{ pageEndIndex }}</span
          >
          out of {{ totalListCount }} records
        </div>
        <div>
          <v-pagination
            v-model="page"
            :length="pageCount"
            :total-visible="5"
            @input="getsearchData(page)"
            color="#152F38"
          ></v-pagination>
        </div>
      </v-layout>
    </v-card>

    <!-- For Audit Trail -->

    <v-dialog v-model="audit_dialog" width="800">
      <v-card>
        <v-card-title class="white--text" style="background-color: #23b1a9">
          Audit Trail
          <v-spacer></v-spacer>
          <v-icon @click="audit_dialog = !audit_dialog" class="white--text"
            >mdi-window-close</v-icon
          >
        </v-card-title>
        <v-layout class="pt-4 form-group-container">
          <v-data-table
            :headers="audit_Header"
            hide-default-footer
            :items="audit_Data"
            class="vDataTableStyling"
          >
            <template v-slot:[`item.srno`]="{ index }">
              {{ 1 + index }}
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-btn
                v-if="item.action === 'Modified'"
                color="#FFFFFF"
                class="text-capitalize tablebtn"
                @click="seeModify(item)"
              >
                {{ item.action }}
                <v-icon class="tableicons">mdi-arrow-right-circle</v-icon>
              </v-btn>
              {{ item.action !== "Modified" ? item.action : null }}
            </template>
            <template v-slot:[`item.createdAt`]="{ item }">
              {{ formatDateTime(item.createdAt) }}
            </template>
          </v-data-table>
        </v-layout>
        <v-layout justify-end align-center class="pb-2 px-0 mr-4">
          <div>
            <span style="color: orange"
              >Showing {{ AuditPageStartIndex }}-{{ AuditPageEndIndex }}</span
            >
            out of {{ AuditTotalListCount }} records
          </div>
          <div>
            <v-pagination
              class="small-pagination"
              v-model="AuditPage"
              :length="AuditPageCount"
              :total-visible="5"
              @input="getAuditData(AuditPage, '', true)"
              color="#152F38"
            ></v-pagination>
          </div>
        </v-layout>
      </v-card>
    </v-dialog>
    <!-- Modify dialogue -->
    <v-dialog v-model="seeModifyDialog" width="600">
      <v-card>
        <v-card-title class="white--text" style="background-color: #23b1a9">
          <v-layout justify-space-between>
            <div>Modified Field Details</div>
          </v-layout>
          <v-btn
            color="transparent"
            small
            :ripple="false"
            depressed
            @click="seeModifyDialog = false"
          >
            <v-icon color="white">mdi-close </v-icon>
          </v-btn>
        </v-card-title>
        <div class="scrollable-table">
          <v-data-table
            disable-pagination
            :headers="modifyHeaders"
            :items="modifyItems"
            hide-default-footer
            class="pa-4 vDataTableStyling"
          >
            <template v-slot:[`item.srNo`]="{ index }">
              {{ 1 + index }}
            </template>
          </v-data-table>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="reject_Dialog" width="600">
      <v-card>
        <v-card-title class="white--text" style="background-color: #23b1a9"
          ><v-layout
            style="display: flex; justify-content: center; margin-left: 170px"
            >Reject
          </v-layout>
          <v-layout style="display: flex; justify-content: end">
            <v-icon
              class="white--text"
              @click="
                reject_Dialog = !reject_Dialog
                reason = ''
              "
              >mdi-close</v-icon
            >
          </v-layout>
        </v-card-title>
        <v-form ref="rejectForm" @submit="false">
          <v-layout class="pt-4 form-group-container">
            <v-flex class="mr-2">
              <label class="form-label"
                >Reason <span><strong class="red--text">*</strong></span></label
              >
              <v-text-field
                class="form-control"
                solo
                dense
                :rules="reject_Dialog ? [(v) => requiredField(v)] : []"
                v-model="reason"
                placeholder="Enter..."
              ></v-text-field>
            </v-flex>
          </v-layout>
        </v-form>
        <v-card-actions style="display: flex; justify-content: center">
          <v-btn
            class="btn"
            color="red white--text mr-0"
            @click="
              reject_Dialog = !reject_Dialog
              reason = ''
            "
          >
            No
          </v-btn>

          <v-btn
            class="btn"
            color="green white--text mr-0"
            @click="submitApproval"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="approve_Dialog" width="600">
      <v-card>
        <v-card-title class="white--text" style="background-color: #23b1a9"
          ><v-layout
            style="display: flex; justify-content: center; margin-left: 170px"
            >Approval
          </v-layout>
          <v-layout style="display: flex; justify-content: end">
            <v-icon
              class="white--text"
              @click="
                approve_Dialog = !approve_Dialog
                reason = ''
              "
              >mdi-close</v-icon
            >
          </v-layout>
        </v-card-title>
        <v-layout class="pt-4 form-group-container justify-center my-5">
          <h4>Are you sure want to Approve ?</h4>
        </v-layout>
        <v-card-actions style="display: flex; justify-content: center">
          <v-btn
            class="btn"
            color="red white--text mr-0"
            @click="
              approve_Dialog = !approve_Dialog
              reason = ''
            "
          >
            No
          </v-btn>

          <v-btn
            class="btn"
            color="green white--text mr-0"
            @click="submitApproval"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Audit Trail -->
    <audit-trail
      :dialogState="OpenAuditDialog"
      :auditTrailMaster="'court'"
      :auditTrailItem="{ uid: auditTrailId }"
      @close-dialog="OpenAuditDialog = false"
    />
  </div>
</template>

<script>
import moment from "moment"
import auditTrail from "../../../../component/MasterAuditTrail.vue"
export default {
  components: {
    auditTrail,
  },
  data() {
    return {
      reject_Dialog: false,
      reason: "",
      CurrentCourtObj: "",
      approve_Dialog: false,
      lobOptions:[],
      searchData: {
        lob:"",
        courtType: "",
        courtName: "",
        bankName: "",
        pinCode: "",
        entryStatus: "",
        active:"",
      },
      audit_dialog: false,
      seeModifyDialog: false,
      AuditPage: 1,
      AuditPageCount: 0,
      modifyItems: [],
      courtTypeItems: [
        "High Court",
        "Supreme Court",
        "District Consumer Forum",
        "State Consumer Forum",
        "NCDRC",
        "E.C.C",
        "PLA",
        "Ombudsman",
        "Civil Court",
        "Other",
      ],
      // displaySearchTable: false,
      entryStatusItems: ["Approved", "Rejected", "Pending for approval"],
      headers: [
        {
          text: "Actions",
          value: "actions",
          width: 50,
          sortable: false,
          align: "center",
        },
        {
          text: "Court ID",
          value: "operationData[0].uid",
          width: 100,
          align: "center",
        },
      {text:"LOB",value:"operationData[0].lob",sortable:false,align:"center"},
        {
          text: "Court Name",
          value: "courtName",
          align: "center",
          width: "150px",
        },
        {
          text: "Court Type",
          value: "courtType",
          align: "center",
          width: "150px",
        },
        {
          text: "Address",
          value: "address",
          sortable: false,
          width: 150,
          align: "center",
        },
        {
          text: "City",
          sortable: false,
          value: "city",
          width: "100px",
          align: "center",
        },
        {
          text: "State",
          value: "state",
          width: "100px",
          align: "center",
        },
        {
          text: "Pin Code",
          sortable: false,
          value: "pincode",
          width: "150px",
          align: "center",
        },
        {
          text: "Bank Name",
          value: "operationData[0].bank.bankName",
          width: "150px",
          align: "center",
        },
        {
          text: "IFSC code",
          sortable: false,
          value: "operationData[0].bank.ifscCode",
          width: "100px",
          align: "center",
        },
        {
          text: "Bank Account No.",
          sortable: false,
          value: "operationData[0].bank.accountNumber",
          width: "200px",
          align: "center",
        },
        {
          text: "Active Status",
          sortable: false,
          value: "activeStatus",
          width: "200px",
          align: "center",
        },
        {
          text: "Approve Status",
          sortable: false,
          value: "approveStatus",
          width: "200px",
          align: "center",
        },
        {text:"Initiated By",value:"createdBy", sortable: false, align: "center",},
      {text:"Date and Time",value:"createdAt", sortable: false, align: "center",}
      ],
      courtMasterList: [],
      page: 1,
      itemsPerPage: 10,
      totalListCount: 0,
      totalListCount: 0,
      pageStartIndex: 0,
      // pageCount: 0,
      dataItems: [
        {
          name: "VIEW",
          icon: "eye-outline",
          link: "/viewpage",
          color: "color:#23b1a9",
          iconColor: "color:#23b1a9",
        },
        // {
        //   name: "Approve",
        //   icon: "check-circle-outline",
        //   color: "color:#23b1a9",
        //   iconColor: "color:green",
        // },
        // {
        //   name: "Reject",
        //   icon: "cancel",
        //   color: "color:#23b1a9",
        //   iconColor: "color:red",
        // },
        {
          name: "AUDIT TRAIL",
          icon: "chart-timeline-variant",
          link: "/auditTrail",
          color: "color:orange",
          iconColor: "color:orange",
        },
      ],
      showSearchChip: false,
      audit_Data: [],
      AuditTotalListCount: 0,
      AuditPageCount: 0,
      AuditPageStartIndex: 0,
      AuditItemsPerPage: 10,
      audit_Header: [
        { text: "Sr. No.", value: "srno", sortable: false, align: "center" },
        {
          text: "Master ID",
          value: "masterId",
          sortable: false,
          align: "center",
        },
        {
          text: "Action Taken",
          value: "action",
          sortable: false,
          align: "center",
        },
        { text: "User ID", value: "userId", sortable: false, align: "center" },
        {
          text: "User Name",
          value: "userName",
          sortable: false,
          align: "center",
        },
        {
          text: "Time Stamp",
          value: "createdAt",
          sortable: false,
          align: "center",
        },
      ],
      modifyHeaders: [
        {
          text: "Sr. No.",
          value: "srNo",
          align: "center",
          sortable: false,
        },
        {
          text: "Field Name",
          value: "fieldName",
          align: "center",
          sortable: false,
        },
        {
          text: "Old Value",
          value: "oldValue",
          align: "center",
          sortable: false,
        },
        {
          text: "New Value",
          value: "newValue",
          align: "center",
          sortable: false,
        },
      ],
      OpenAuditDialog: false,
      auditTrailId: null,
    }
  },
  methods: {
    formatDateTime(dateTime) {
      return dateTime ? moment(dateTime).format("DD-MM-YYYY LT") : ""
    },
    getLOB() {
        let reqData = '';
        this.showProgress();
        this.GETM("getLOB", reqData, (res) => {
            this.hideProgress();
            if(res.data.statusCode === 200) {
                this.lobOptions = res.data.data[0].lineOfBusiness;
            } else {
                this.lobOptions = [];
                this.showAlert(res.data.msg);
            }
        }, (error) => {
            console.log(error);
            this.hideProgress();
        }, true)
    },

    cancel(data) {
      this.showConfirm(
        "Confirmation",
        "Are you sure you want to leave?",
        "Yes",
        "No",
        (Yes) => {
          this.$router.push({ name: "MasterApproval" }) //params:{pageNo:this.pageNo}}
        }
      )
    },
    chipclose() {
      this.searchData = {
        courtType: "",
        courtName: "",
        bankName: "",
        pinCode: "",
        entryStatus: "",
        active:"",
      }
      this.showSearchChip = false
      this.getsearchData()
    },
    submitApproval() {
      if (this.reject_Dialog) {
        if (!this.$refs?.rejectForm?.validate()) {
          return this.showAlert("Please fill mandatory fields.")
        }
      }
      let sendData = {
        approveStatus: this.reject_Dialog ? "Rejected" : "Approved",
        court: this.CurrentCourtObj,
        rejectionReason: this.reason,
      }
      // this.courtDetails.approveStatus=this.reject_Dialog ? "Rejected" : "Approved"
      // this.courtDetails.rejectionReason=this.reason
      // this.frequencyPayee.approveStatus=this.reject_Dialog ? "Rejected" : "Approved"
      this.reject_Dialog = false
      this.approve_Dialog = false

      this.PUTM(
        "updateMasterApprove",
        this.CurrentCourtObj._id,
        sendData,
        (res) => {
          this.hideProgress()
          if (res.data && res.data.statusCode == 200) {
            this.showAlert(res?.data?.msg || "Success", "", "", () => {
              this.getsearchData()
            })
          } else {
            this.showAlert(res.data.msg)
            this.hideProgress()
          }
        },
        (err) => {
          console.log(err)
          this.hideProgress()
          let msg =
            err?.response?.data?.msg ||
            "Unable to submit court Master Approval."
          this.showAlert(msg)
        },
        true
      )
    },
    getAuditData(newPageNum = 1, id, bool) {
      let reqData = ""
      if (bool) {
        reqData =
          "perPage=10" +
          "&pageNo=" +
          newPageNum +
          "&masterType=court" +
          "&masterId=" +
          this.audit_Data[0].masterId
      } else {
        reqData =
          "perPage=10" +
          "&pageNo=" +
          newPageNum +
          "&masterType=court" +
          "&masterId=" +
          id
      }
      this.GETM(
        "getAudits",
        reqData,
        (res) => {
          console.log("AUDIT", res.data)
          if (res.data.data.data.length > 0) {
            this.audit_Data = res.data.data.data
            this.AuditTotalListCount = res.data.data.total
            this.AuditPageCount = res.data.data.total_pages
            this.AuditPageStartIndex =
              newPageNum == 1
                ? 1
                : (newPageNum - 1) * this.AuditItemsPerPage + 1
          } else {
            this.AuditPageStartIndex = 0
          }
        },
        () => {},
        true
      )
    },
    formatDateTime(dateTime) {
      return dateTime ? moment(dateTime).format("DD-MM-YYYY LT") : ""
    },
    seeModify(item) {
      console.log(item.modification)
      this.seeModifyDialog = true
      this.modifyItems = item.modification
      // .map((m, index) => {
      //   let object = { ...m, srNo: index + 1 }
      //   if (this.dateFields.includes(m.fieldName)) {
      //     object.newValue = this.formatDateOnly(m.newValue)
      //     object.oldValue = this.formatDateOnly(m.oldValue)
      //   }
      //   return object
      // })
    },
    handleSearch() {
      this.displaySearchTable = true
      this.showSearchChip = true
      this.getsearchData()
    },
    editmaster(link, index, item, title) {
      console.log(item)
      if (link === "/auditTrail") {
        // this.seeAuditTrail(item);
        this.OpenAuditDialog = true
        this.auditTrailId = item.operationData[0].uid
        // this.audit_dialog = true
        // this.getAuditData(1,item.operationData[0].uid,false)
      } else if (link == "/viewpage") {
        let viewModifyObj = { viewMode: true, item: item }
        let modifyObj = { modifyMode: true }
        viewModifyObj.viewMode = link == "/viewpage" ? true : false
        modifyObj.modifyMode = link == "/modifypage" ? true : false

        this.$router.push({
          name: "createCourtMaster",
          params: {
            viewMode: link == "/viewpage" ? true : false,
            item: item,
            isView: link == "/viewpage" ? true : false,
            modifyMode: link == "/modifypage" ? true : false,
            fromApproval: true,
          },
        })
      } else {
        if (title == "Approve") {
          this.approve_Dialog = true
          this.CurrentCourtObj = item
        } else {
          this.reject_Dialog = true
          this.CurrentCourtObj = item
        }
      }
    },
    customSort(items, sec, third) {
      if (sec.length === 0) return items

      console.log("$$$", sec, third)
      let fieldName = sec[0]
      let flag = third[0]
      let result = [...items]
      switch (fieldName) {
        case "courtType":
          result.sort((a, b) => {
            a = a.operationData[0].court.type.toLowerCase()
            b = b.operationData[0].court.type.toLowerCase()
            if (flag) return a < b ? -1 : 1
            else return a > b ? -1 : 1
          })
          break
        case "courtName":
          result.sort((a, b) => {
            a = a.operationData[0].court.name.toLowerCase()
            b = b.operationData[0].court.name.toLowerCase()
            if (flag) return a < b ? -1 : 1
            else return a > b ? -1 : 1
          })
          break
        case "operationData[0].uid":
          result.sort((a, b) => {
            console.log(a.operationData[0].uid)
            a = a.operationData[0].uid
            b = b.operationData[0].uid
            if (flag) return a < b ? -1 : 1
            else return a > b ? -1 : 1
          })
          break
        case "bankName":
          result.sort((a, b) => {
            a = a.operationData[0].bank.bankName.toLowerCase()
            b = b.operationData[0].bank.bankName.toLowerCase()
            if (flag) return a < b ? -1 : 1
            else return a > b ? -1 : 1
          })
          break
        case "state":
          result.sort((a, b) => {
            a = a.operationData[0].court.state.toLowerCase()
            b = b.operationData[0].court.state.toLowerCase()
            if (flag) return a < b ? -1 : 1
            else return a > b ? -1 : 1
        })
        break
        default:
      }
      return result
    },
    handleReset() {
      this.showConfirm(
        "Confirmation",
        "Are you sure you want to Reset?",
        "Yes",
        "No",
        (Yes) => {
          this.searchData = {
            courtType: "",
            courtName: "",
            bankName: "",
            pinCode: "",
            entryStatus: "",
            active:"",
          }
          this.showSearchChip = false
          this.getsearchData()
        },
        (No) => {}
      )
    },
    getsearchData(newPageNum = 1) {
      // let statusOfApproval =
      //   this.searchData.entryStatus === ""
      //     ? "Pending for approval"
      //     : this.searchData.entryStatus
      // console.log("123", statusOfApproval)
      this.showProgress()
      // this.page = this.page !== newPageNum ? newPageNum : this.page
      // let reqData =
      //   "page=" +
      //   this.page +
      //   "&pageLimit=" +
      //   this.itemsPerPage +
      //   "&modelName=court"
      // reqData =
      //   reqData +
      //   "&courtType=" +
      //   this.searchData.courtType +
      //   "&courtName=" +
      //   this.searchData.courtName +
      //   "&bankName=" +
      //   this.searchData.bankName +
      //   "&pincode=" +
      //   this.searchData.pinCode +
      //   "&approveStatus=" +
      //   statusOfApproval

      let reqData = "page=" + this.page + "&pageLimit=" + this.itemsPerPage +"&approveStatus=Pending for approval&modelName=court";
      
      if (this.searchData.lob) {
        reqData= reqData + "&lob=" + this.searchData.lob;
                }
      if (this.searchData.courtType) {
        reqData= reqData + "&courtType=" + this.searchData.courtType;
                }
      if (this.searchData.courtName) {
        reqData= reqData +"&courtName=" +this.searchData.courtName;
                }
      if (this.searchData.bankName) {
        reqData= reqData +"&bankName=" +this.searchData.bankName;
                }
                if (this.searchData.pinCode) {
        reqData= reqData +"&pincode=" +this.searchData.pinCode;
                }
                if (this.searchData.active) {
        reqData= reqData +"&active=" +this.searchData.active;
                }else if(this.searchData.active === false){
                  reqData= reqData +"&active=" +this.searchData.active;
                }

      this.searchDialog = false
      this.GETM(
        "searchFrequentApprove",
        reqData,
        (res) => {
          // this.showChipHandler()
          if (
            res &&
            res.data &&
            res.data.data &&
            res.data.data.data &&
            res.data.data.data.length > 0
          ) {
            let result = res.data.data.data
            console.log("1234", result)
            this.courtMasterList = res.data.data.data.map((item) => ({
                                                    ...item,
                                                createdAt: this.formatDateTime(item.createdAt),
                                                }));
            this.totalListCount = res.data.data.total
            // this.pageCount = res.data.data.total_pages
            this.pageStartIndex = (newPageNum - 1) * this.itemsPerPage + 1
          } else {
            this.showAlert("No Data Found.", "", "", () => {
              this.courtMasterList = []
              this.totalListCount = 0
              this.pageStartIndex = 0
              // this.pageCount = 0
            })
          }
          this.hideProgress()
        },
        (err) => {
          this.hideProgress()
        },
        true
      )
    },
  },
  computed: {
    pageCount() {
      if (!this.totalListCount) {
        return 0
      } else {
        return Math.ceil(this.totalListCount / this.itemsPerPage)
      }
    },
    pageEndIndex() {
      if (!this.courtMasterList.length) {
        return 0
      } else {
        return this.pageStartIndex + this.itemsPerPage <= this.totalListCount
          ? this.pageStartIndex + this.itemsPerPage - 1
          : this.totalListCount
      }
    },
    AuditPageEndIndex() {
      if (!this.audit_Data.length) {
        return 0
      } else {
        return this.AuditPageStartIndex + this.AuditItemsPerPage <=
          this.AuditTotalListCount
          ? this.AuditPageStartIndex + this.AuditItemsPerPage - 1
          : this.AuditTotalListCount
      }
    },
  },
  created() {
    console.log("hit")
    this.getLOB();
    // this.getAuditData()
    // this.handleSearch()
    let newPageNum = 1
    let reqData =
      "page=" +
      this.page +
      "&pageLimit=" +
      this.itemsPerPage +
      "&approveStatus=Pending for approval&modelName=court"
    this.GETM(
      "searchFrequentApprove",
      reqData,
      (res) => {
        // this.showChipHandler()
        if (
          res &&
          res.data &&
          res.data.data &&
          res.data.data.data &&
          res.data.data.data.length > 0
        ) {
          let result = res.data.data.data
          console.log("1234", result)
          this.courtMasterList = res.data.data.data.map((item) => ({
                                                    ...item,
                                                createdAt: this.formatDateTime(item.createdAt),
                                                }));
          this.totalListCount = res.data.data.total
          // this.pageCount = res.data.data.total_pages
          this.pageStartIndex = (newPageNum - 1) * this.itemsPerPage + 1
        } else {
          this.showAlert("No Data Found.", "", "", () => {
            this.courtMasterList = []
            this.totalListCount = 0
            this.pageStartIndex = 0
            // this.pageCount = 0
          })
        }
        this.hideProgress()
      },
      (err) => {
        this.hideProgress()
      },
      true
    )
    this.showSearchChip = false
    // let reqData = "pageNo=1&perPage=10&modelName=court"
    this.$store.commit("SAVE_PAGE_HEADER", "Court Master Approval Search")
  },
}
</script>

<style scoped>
.tablebtn {
  background: #ffffff 0% 0% no-repeat padding-box !important;
  box-shadow: inset 0px 2px 4px #00000052 !important;
  border: 0.5px solid #c1c8cc;
  border-radius: 18px !important;
  height: 30px !important;
  text-transform: capitalize !important;
  width: 93%;
  justify-content: space-between;
  color: black !important;
  font-weight: 400 !important;
}
.tableicons {
  font-size: 18px !important;
  color: #23b1a9 !important;
}
</style>
