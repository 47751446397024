 <template> 
    <v-app>
        <!-- <v-card color="#F7F7F7" class="mb-1" tile>
          <v-btn-toggle v-model="text" dense mandatory class="" @change="tabseleted(text)" style="background-color:#F7F7F7;opacity:100%;" >
                <v-btn  flat  width="118px" min-height="90px" value="ms" class="text-capitalize font-weight-bold btn_tabs" active-class="srSelected">
                  Main Screen
                </v-btn>
                <v-btn flat  width="118px" min-height="90px" value="ocd" class="text-capitalize font-weight-bold btn_tabs" active-class="srSelected">
                  
                   Ownership & Contact Details
                  
                </v-btn>
                <v-btn flat  width="118px" min-height="90px" value="cfd" class="text-capitalize font-weight-bold btn_tabs" active-class="srSelected">
                   Contact Facility Details
                </v-btn>
                <v-btn flat  width="118px" min-height="90px" value="splty" class="text-capitalize font-weight-bold btn_tabs" active-class="srSelected">
                  Specialty
                </v-btn>
                  <v-btn flat  width="118px" min-height="90px" value="qt" class="text-capitalize font-weight-bold btn_tabs" active-class="srSelected">
                  Quality and Teaching
                </v-btn>
                  <v-btn flat  width="118px" min-height="90px" value="lr" class="text-capitalize font-weight-bold btn_tabs" active-class="srSelected">
                  Lab & Radiology
                </v-btn>
                  <v-btn flat  width="118px" min-height="90px" value="sd" class="text-capitalize font-weight-bold btn_tabs" active-class="srSelected">
                  Staff Details
                </v-btn>
          
                <v-btn flat  width="118px" min-height="90px" value="fclty" class="text-capitalize font-weight-bold btn_tabs" active-class="srSelected">
                  Facilities
                </v-btn>
                <v-btn flat  width="118px" min-height="90px" value="pymt" class="text-capitalize font-weight-bold btn_tabs" active-class="srSelected">
                  Payment
                </v-btn>
                <v-btn flat  width="118px" min-height="90px" value="pr" class="text-capitalize font-weight-bold btn_tabs" active-class="srSelected">
                  Provider Review
                </v-btn>
                <v-btn flat  width="118px" min-height="90px" value="acd" class="text-capitalize font-weight-bold btn_tabs" active-class="srSelected"> 
                  Approval and Contract Details
                </v-btn>
                
              </v-btn-toggle>
            </v-card> -->
      <v-card color="#F7F7F7" class="mb-1" tile>
          <v-btn-toggle v-model="text" dense mandatory class="" @change="tabseleted(text)" style="background-color:#F7F7F7;opacity:100%;display:flex;" >
                <div  :class="[mainscreen ? 'cust_brdr_green':'cust_brdr_red']" style="flex:1;"><v-btn   flat   min-height="90px" style="width:100%;letter-spacing: unset" value="ProviderEmpanelmentMain"  class="text-capitalize font-weight-bold btn_tabs rounded-0" active-class="srSelected">
                  Main Screen
                </v-btn> </div>
                <div v-if=" tabsRegisteredEntity !== 'Individual' || tabsProviderType === 'all' "  :class="[ownershipAndContactStatusScrn ? 'cust_brdr_green':'cust_brdr_red']" style="flex:1;" >
                  <v-btn :disabled = 'tabsTobeDisabled' flat   min-height="90px" style="width:100%;letter-spacing: unset"  value="ProviderEmpanelmentContact" class="text-capitalize font-weight-bold btn_tabs rounded-0" active-class="srSelected">
                    Ownership & Contact Details
                  </v-btn>
                </div>
                 <div v-if="tabsProviderType === 'all' || tabsRegisteredEntity === 'Individual' "  :class="[contactAndFacilityStatusScr ? 'cust_brdr_green':'cust_brdr_red']" style="flex:1;">
                <v-btn flat :disabled = 'tabsTobeDisabled'   min-height="90px" style="width:100%;letter-spacing: unset" value="ProviderEmpanelmentContactFacility" class="text-capitalize font-weight-bold btn_tabs rounded-0" active-class="srSelected">
                   Contact Facility Details
                </v-btn>
                 </div>
                 <div v-if="tabsProviderType === 'Hospital' || tabsProviderType === 'all'" :class="[specialityStatusScrn ? 'cust_brdr_green':'cust_brdr_red']" style="flex:1;">
                <v-btn flat :disabled = 'tabsTobeDisabled'   min-height="90px" style="width:100%;letter-spacing: unset" value="ProviderEmpanelmentSpeciality" class="text-capitalize font-weight-bold btn_tabs rounded-0" active-class="srSelected">
                  Specialty
                </v-btn>
                </div>
                <div v-if="tabsProviderType === 'Hospital' || tabsProviderType === 'Dental' || tabsProviderType === 'all'" :class="[qualityAndTeachingStatusScrn ? 'cust_brdr_green':'cust_brdr_red']" style="flex:1;">
                  <v-btn flat :disabled = 'tabsTobeDisabled'   min-height="90px" style="width:100%;letter-spacing: unset" value="ProviderEmpanelmentQualityTeaching" class="text-capitalize font-weight-bold btn_tabs rounded-0" active-class="srSelected">
                  Quality and Teaching
                </v-btn>
                 </div>
                 <div v-if="tabsProviderType === 'Hospital' || tabsProviderType === 'all'" :class="[labAndRadiologyStatusScrn ? 'cust_brdr_green':'cust_brdr_red']" style="flex:1;">
                    <v-btn flat :disabled = 'tabsTobeDisabled'   min-height="90px" style="width:100%;letter-spacing: unset" value="ProviderEmpanelmentLabRadiology" class="text-capitalize font-weight-bold btn_tabs rounded-0" active-class="srSelected">
                        Lab & Radiology
                    </v-btn>
                </div>
                <div v-if="tabsProviderType === 'Hospital' || tabsProviderType === 'all'" :class="[staffStatusScrn ? 'cust_brdr_green':'cust_brdr_red']" style="flex:1;">
                  <v-btn flat :disabled = 'tabsTobeDisabled'   min-height="90px" style="width:100%;letter-spacing: unset" value="ProviderEmpanelmentStaffDetails" class="text-capitalize font-weight-bold btn_tabs rounded-0" active-class="srSelected">
                  Staff Details
                </v-btn>
                 </div>
                <div v-if="tabsProviderType === 'Hospital' || tabsProviderType === 'Dental' || tabsProviderType === 'all'" :class="[facilitiesScrn ? 'cust_brdr_green':'cust_brdr_red']" style="flex:1;">
                  <v-btn flat :disabled = 'tabsTobeDisabled'   min-height="90px" style="width:100%;letter-spacing: unset" value="PrvdrEmpnlmntFacilities" class="text-capitalize font-weight-bold btn_tabs rounded-0" active-class="srSelected">
                    Facilities
                  </v-btn>
                </div>
                <div :class="[paymentStatusScrn ? 'cust_brdr_green':'cust_brdr_red']" style="flex:1;">
                <v-btn flat :disabled = 'tabsTobeDisabled'   min-height="90px" style="width:100%;letter-spacing: unset" value="ProviderPaymentDetails" class="text-capitalize font-weight-bold btn_tabs rounded-0" active-class="srSelected">
                  Payment
                </v-btn>
                </div>
                <div :class="[providerReviewStatusScrn ? 'cust_brdr_green':'cust_brdr_red']" style="flex:1;">
                <v-btn flat :disabled = 'tabsTobeEnabledFromProRevWorkItem'   min-height="90px" style="width:100%;letter-spacing: unset" value="ProviderReview" class="text-capitalize font-weight-bold btn_tabs rounded-0" active-class="srSelected">
                  Provider Review
                </v-btn>
                 </div>
                 <div :class="[approvalStatusScrn ? 'cust_brdr_green':'cust_brdr_red']" style="flex:1;">
                <v-btn flat :disabled = 'tabsTobeEnabledFromEmpAppWorkItem'   min-height="90px" style="width:100%;letter-spacing: unset" value="ApprovalContractDetails" class="text-capitalize font-weight-bold btn_tabs rounded-0" active-class="srSelected"> 
                  {{ empanelmentTypeFromRoute === 'renewal' ? 'Renewal Approval and Contract Details' : 'Approval and Contract Details'}}
                </v-btn>
                 </div>
              </v-btn-toggle>
            </v-card>


            <!-- <keep-alive> -->
              <component :is="selectedComponent"  @forenable = "enableTabs" @fordisable = "disableTabs"></component>
            <!-- </keep-alive> -->
            <!-- <PrvdrEmpnlmntFacilities v-if="fcltyshw===true"></PrvdrEmpnlmntFacilities>
            <ProviderEmpanelmentMain v-if="msshw==true"></ProviderEmpanelmentMain>
            <ProviderEmpanelmentContact v-if="ocdshw==true"></ProviderEmpanelmentContact> -->
    </v-app>
</template>
<script>
let arrForCancel = [];

// import fetchOnPageLoad from '../../common/onProjectLoad'
import ProviderEmpanelmentMain from './ProviderEmpanelmentMain.vue'
import ProviderEmpanelmentContact from './ProviderEmpanelmentContact.vue'
import ProviderEmpanelmentContactFacility from './ProviderEmpanelmentContactFacility.vue'
import ProviderEmpanelmentSpeciality from './ProviderEmpanelmentSpeciality.vue'
import ProviderEmpanelmentQualityTeaching from './ProviderEmpanelmentQualityTeaching.vue'
import ProviderEmpanelmentLabRadiology from './ProviderEmpanelmentLabRadiology.vue'
import ProviderEmpanelmentStaffDetails from './ProviderEmpanelmentStaffDetails.vue'
import PrvdrEmpnlmntFacilities from './PrvdrEmpnlmntFacilities.vue'
import ProviderPaymentDetails from './ProviderPaymentDetails.vue'
import ProviderReview from './ProviderReview.vue'
import ApprovalContractDetails from './ApprovalContractDetails.vue'
export default {

    components:{
      
        ProviderEmpanelmentMain,
        ProviderEmpanelmentContact,
        ProviderEmpanelmentContactFacility,
        ProviderEmpanelmentSpeciality,
        ProviderEmpanelmentQualityTeaching,
        ProviderEmpanelmentLabRadiology,
        ProviderEmpanelmentStaffDetails,
        PrvdrEmpnlmntFacilities,
        ProviderPaymentDetails,
        ProviderReview,
        ApprovalContractDetails
    },

    created() {
        this.$store.commit("SAVE_PAGE_HEADER", "Provider Empanelment");
          // fetchOnPageLoad.getcountry();
          // fetchOnPageLoad.getstate("BIHAR");
          // fetchOnPageLoad.getdistrict();
           let dataForEmp = {
                            mainScreenStatus: false,
                            ownershipAndContactStatus: false,
                            contactAndFacilityStatus: false,
                            specialityStatus: false,
                            qualityAndTeachingStatus: false,
                            labAndRadiologyStatus: false,
                            staffStatus: false,
                            facilitiesStatus: false,
                            paymentStatus: false,
                            providerReviewStatus: false,
                            approvalAndContractStatus: false,
                        }
          this.$store.commit("EMPANEL_TAB_STATUS", dataForEmp);
          this.$store.commit('EMPANEL_PROVIDER_DATA',{});
         if(this.$route.params.type === "Review Empanelment"){
                  this.comingFromReviewAction = true;
                  this.tabsTobeEnabledFromProRevWorkItem = false;
         }else if(this.$route.params.type === "Provider Review" || this.$route.params.type === "Provider Approval"){
                  console.log("%c i was in empanelment tab to disable approval tab","background-color:blue;color:white");
                  this.empanelmentTypeFromRoute = this.$route.params.empanelmentType
                  console.log("this.empanelmentTypeFromRoute",this.empanelmentTypeFromRoute);
                  this.tabsTobeEnabledFromEmpAppWorkItem = false
                  this.tabsTobeEnabledFromProRevWorkItem = false;
         }
         
          
    },

    computed: {
      
      tabsProviderType(){
        console.log("%c this is the data from tabs section","color:rgb(79, 128, 0)",this.$store.state.empanelProviderData);
        let providerTypeIs = this.$store.state.empanelProviderData
        if( Object.keys(providerTypeIs).length === 0 || providerTypeIs === undefined || providerTypeIs === null || providerTypeIs === '' ){ 
            console.log("this is providerTypeIs from if");
            return "all"
          }else{
            console.log("this is providerTypeIs from else",providerTypeIs.provider.providerType);

            return providerTypeIs.provider.providerType
        }
        // console.log("%c this is provider type","color:blue",this.$store.state.empanelProviderData.provider.providerType);
      }, 

      tabsRegisteredEntity(){
        // console.log("%c this the registered entity from provider section","color:darkblue",this.$store.state.empanelProviderData.provider.registeredEntity);
        let entityIs = this.$store.state.empanelProviderData
        if( Object.keys(entityIs).length === 0 || entityIs === undefined || entityIs === null || entityIs === '' ){ 
            console.log("%c this is entityIs from if","color:red");
            return "all"
          }else{
            console.log("%c this is entityIs from else","color:green",entityIs.provider.registeredEntity);

            return entityIs.provider.registeredEntity
        }
      },

      mainscreen(){
        if(this.$store.state.empanelmentTabStatus.mainScreenStatus){
          return true
        }else{
          return false
        }
        
      },

      facilitiesScrn(){
        if(this.$store.state.empanelmentTabStatus.facilitiesStatus){
          return true
        }else{
          return false
        }
      },

      staffStatusScrn(){
        if(this.$store.state.empanelmentTabStatus.staffStatus){
          return true
        }else{
          return false
        }
      },

      labAndRadiologyStatusScrn(){
        if(this.$store.state.empanelmentTabStatus.labAndRadiologyStatus){
          return true
        }else{
          return false
        }
      },

      specialityStatusScrn(){
        if(this.$store.state.empanelmentTabStatus.specialityStatus){
          return true
        }else{
          return false
        }
      },

      ownershipAndContactStatusScrn(){
        if(this.$store.state.empanelmentTabStatus.ownershipAndContactStatus){
          return true
        }else{
          return false
        }
      },

      contactAndFacilityStatusScr(){
        if(this.$store.state.empanelmentTabStatus.contactAndFacilityStatus){
          return true
        }else{
          return false
        }
      },

      qualityAndTeachingStatusScrn(){
        if(this.$store.state.empanelmentTabStatus.qualityAndTeachingStatus){
          return true
        }else{
          return false
        }
      },

      paymentStatusScrn(){
        if(this.$store.state.empanelmentTabStatus.paymentStatus){
          return true
        }else{
          return false
        }

      },

      providerReviewStatusScrn(){
        if(this.$store.state.empanelmentTabStatus.providerReviewStatus){
          this.tabsTobeEnabledFromProRevWorkItem = false;
          return true
        }else{
            if(this.comingFromReviewAction){
              this.tabsTobeEnabledFromProRevWorkItem = false;
            }else{
                this.tabsTobeEnabledFromProRevWorkItem = true;
                return false
            }
        }
      },

      approvalStatusScrn(){
        if(this.$store.state.empanelmentTabStatus.approvalAndContractStatus){
          this.tabsTobeEnabledFromEmpAppWorkItem = false;
          return true
        }else{
          this.tabsTobeEnabledFromEmpAppWorkItem = true;
          return false
        }
      }


    },

    data() {
        return {
          tabsTobeEnabledFromProRevWorkItem : true,
          tabsTobeEnabledFromEmpAppWorkItem : true,
          comingFromReviewAction : false,           // coming from action btn of provider reviewQueue page 
          empanelmentTypeFromRoute:'',
          tabsTobeDisabled: true,
          selectedComponent : "ProviderEmpanelmentMain",
        
          // fcltyshw:false,
          // ocdshw:false,
          // msshw:true,
          text:"ProviderEmpanelmentMain",
          activeClass: false
          

          
        };



    },

    provide(){
      return {
        selectedCompFromPage : this.tabseleted,
        arrToNavCancel : arrForCancel
      }
    },

    methods: {

     enableTabs(){
        console.log("%c now the tabs are enabled","background-color:#ff333a;color:black");
          this.tabsTobeDisabled = false
      },

      disableTabs(){
          console.log("%c tabs are disabled","background-color:#aaffee;color:black");
          this.tabsTobeDisabled = true
      },

      // setSelectedComponent(cmp){
      //     this.setSelectedComponent = cmp;
      // },

      tabseleted(text){
        
        this.selectedComponent = text;
        this.text = text
        arrForCancel.unshift(text);
        arrForCancel.splice(2)
        console.log(arrForCancel);
      }

        // tabseleted(itm){
        //     console.log("Selected tab",itm);
        //     if(itm==="fclty"){
        //       this.fcltyshw=true
        //       this.ocdshw=false
        //       this.msshw=false
        //     }else if (itm==="ocd") {
        //       this.ocdshw=true
        //       this.fcltyshw=false
        //       this.msshw=false
        //     } else if (itm==="ms") {
        //       this.msshw=true
        //       this.ocdshw=false
        //       this.fcltyshw=false
        //     }else if (itm==="cfd") {
        //       this.msshw=false
        //       this.ocdshw=false
        //       this.fcltyshw=false
        //     }else if (itm==="splty") {
        //      this.msshw=false
        //       this.ocdshw=false
        //       this.fcltyshw=false
        //     }else if (itm==="qt") {
        //       this.msshw=false
        //       this.ocdshw=false
        //       this.fcltyshw=false
        //     }else if (itm==="lr") {
        //      this.msshw=false
        //       this.ocdshw=false
        //       this.fcltyshw=false
        //     }else if (itm==="sd") {
        //      this.msshw=false
        //       this.ocdshw=false
        //       this.fcltyshw=false
        //     }else if (itm==="pymt") {
        //       this.msshw=false
        //       this.ocdshw=false
        //       this.fcltyshw=false
        //     }else if (itm==="pr") {
        //       this.msshw=false
        //       this.ocdshw=false
        //       this.fcltyshw=false
        //     }else if (itm==="abcd") {
        //       this.msshw=false
        //       this.ocdshw=false
        //       this.fcltyshw=false
        //     }
        // }

    }, 
}

</script>



<style >


.activeredbackground{
  color: #23B1A9 !important;
}

.v-btn__content { width: 100% !important; white-space: normal !important; font-size:13px !important}

.srSelected{
  background-color: #23B1A9 !important;
  color:#FFFFFF !important;
  
}
/* .btn_tabs{
  color:#10242B;
  opacity:100% !important; 
  height:95px !important;
  width:100px !important; 
  font-size:13px !important;
  font-weight: 700; */
  /* border-left:0.5px solid #C1C8CC; */

    /* border: solid;
    border-top-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 0px;
    border-left-width: thin;
    transition: inherit;
    border-top-color: rgba(255, 255, 255, 0.10);
    border-right-color: rgba(255, 255, 255, 0.10);
    border-bottom-color: rgba(255, 255, 255, 0.10);
    border-left-color: rgba(193, 200, 204, 0.26);
} */

.cust_brdr_green{
  /* z-index:200 !important; */
  border-bottom-width:5px !important;
  border-bottom-color:green !important;
  border-bottom-style: solid !important ;
  
  

}
.cust_brdr_red{
   border-bottom-width:5px !important;
  border-bottom-color:red !important;
  border-bottom-style: solid !important ;
}
</style>