<template>
  <div>
    <v-container fluid >
      <v-form ref="form">
      <v-layout row>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Neighbour/Sarpanch/Vicinity Details Available *
            </v-text>
            <v-select
              :items="['Yes', 'No']"
              label="Select"
              v-model="neighbourOrSarpanchOrVicinityVerificationDetails.isNeighbourOrSarpanchOrVicinityDetailsAvailable"
              :rules="rules.mandatoryrule"
              solo
              item-text="state"
              item-value="state"
              class="rounded-0"
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Neighbour/Relative/Family Member Name
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="neighbourOrSarpanchOrVicinityVerificationDetails.neighbourOrRelativeOrFamilyMemberName"
              maxlength="100"
              counter="100"
              class="rounded-0"
              aria-required="true"
              :disabled="disableAllFields"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Neighbour/Relative/Family Member Address
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="neighbourOrSarpanchOrVicinityVerificationDetails.neighbourOrRelativeOrFamilyMemberAddress"
              maxlength="500"
              counter="500"
              class="rounded-0"
              :disabled="disableAllFields"
              aria-required="true"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Neighbour/Relative/Family Member Mobile Number
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="neighbourOrSarpanchOrVicinityVerificationDetails.NeighbourOrRelativeOrFamilyMemberMbnumber"
              maxlength="10"
              counter="10"
              class="rounded-0"
              :disabled="disableAllFields"
              aria-required="true"
              :rules="[numericOnly(neighbourOrSarpanchOrVicinityVerificationDetails.NeighbourOrRelativeOrFamilyMemberMbnumber)]"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Neighbour/Relative/Family Member Version of Accident
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="neighbourOrSarpanchOrVicinityVerificationDetails.NeighbourOrRelativeOrFamilyMemberVersionOfAccident"
              maxlength="2000"
              counter="2000"
              class="rounded-0"
              aria-required="true"
              :disabled="disableAllFields"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Sarpanch/Gram Pradhan Name
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="neighbourOrSarpanchOrVicinityVerificationDetails.SarpanchOrGramPradhanName"
              maxlength="100"
              counter="100"
              class="rounded-0"
              :disabled="disableAllFields"
              aria-required="true"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Sarpanch/Gram Pradhan Address
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="neighbourOrSarpanchOrVicinityVerificationDetails.SarpanchOrGramPradhanAddress"
              maxlength="500"
              counter="500"
              :disabled="disableAllFields"
              class="rounded-0"
              aria-required="true"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Sarpanch/Gram Pradhan Mobile Number
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="neighbourOrSarpanchOrVicinityVerificationDetails.SarpanchOrGramPradhanMbnumber"
              maxlength="10"
              counter="10"
              class="rounded-0"
              :disabled="disableAllFields"
              aria-required="true"
              :rules="[numericOnly(neighbourOrSarpanchOrVicinityVerificationDetails.SarpanchOrGramPradhanMbnumber)]"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Sarpanch/Gram Pradhan Version of Accident
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="neighbourOrSarpanchOrVicinityVerificationDetails.SarpanchOrGramPradhanVersionOfAccident"
              maxlength="2000"
              counter="2000"
              class="rounded-0"
              :disabled="disableAllFields"
              aria-required="true"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md4 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Remarks *
            </v-text>
            <v-textarea
              solo
              label="Enter..."
              v-model="neighbourOrSarpanchOrVicinityVerificationDetails.remarks"
              maxlength="5000"
              counter="5000"
              rows="2"
              class="rounded-0"
              aria-required="true"
              :rules="rules.mandatoryrule"
            >
            </v-textarea>
          </v-layout>
        </v-flex>
      </v-layout>
      </v-form>
    </v-container>
    <InvestigationFooterCard 
      :tabno="sno" :formdata="getFormData" 
      ref="footerCardRef"
      @validateTabForms="validateTabForms"
      :nonMandatoryFields="nonMandatoryFields" 
      :inputFieldCorrectlyFilled="true"
      :enableFieldObject="getEnableFieldObject"
      :componentName="'neighbourOrSarpanchOrVicinityVerificationDetails'"
      :siuWorkItemId="siuWorkItemId"
      :tabFormDataId="tabFormDataId"
    />
  </div>
</template>
<script>
 import InvestigationFooterCard from './InvestigationFooterCard.vue';
 import {getPrePopulatedData} from '../apiservices/Investigation';
 export default {
    name: 'NeighbourVerificationDetails',
    data() {
        return {
            sno: 12,
            lossStatesList: ['A'],
            rules: {
                mandatoryrule: [(v) => !!v || this.validationmessage],
            },
            validationmessage: "This field is required",
            enableFieldsArr: [],
            nonMandatoryFields: [
              'neighbourOrRelativeOrFamilyMemberName',
              'neighbourOrRelativeOrFamilyMemberAddress',
              'NeighbourOrRelativeOrFamilyMemberMbnumber',
              'NeighbourOrRelativeOrFamilyMemberVersionOfAccident',
              'SarpanchOrGramPradhanName',
              'SarpanchOrGramPradhanAddress',
              'SarpanchOrGramPradhanMbnumber',
              'SarpanchOrGramPradhanVersionOfAccident',
            ],
            neighbourOrSarpanchOrVicinityVerificationDetails: {
              isTabSelected: true,
              isNeighbourOrSarpanchOrVicinityDetailsAvailable: "",
              neighbourOrRelativeOrFamilyMemberName: "",
              neighbourOrRelativeOrFamilyMemberAddress: "",
              NeighbourOrRelativeOrFamilyMemberMbnumber: "",
              NeighbourOrRelativeOrFamilyMemberVersionOfAccident: "",
              SarpanchOrGramPradhanName: "",
              SarpanchOrGramPradhanAddress: "",
              SarpanchOrGramPradhanMbnumber: "",
              SarpanchOrGramPradhanVersionOfAccident: "",
              remarks: ""
            },
        }
    },
    props: ['prepoulatedData', "siuWorkItemId", "tabFormDataId"],
    created() {
      let allKeys = Object.keys(this.prepoulatedData);
      allKeys.forEach(key => {
        this.neighbourOrSarpanchOrVicinityVerificationDetails[key] = this.prepoulatedData[key]
      })
    },
    components: {
      InvestigationFooterCard 
    },
    computed: {
      emailRules2(v) {
        if (v.length !== 0) {
          console.log("testing", /^[0-9 ]+$/.test(v));
          if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,6})+$/.test(v)) {
            return "E-mail must be valid";
          }
        }
      },
      getEnableFieldObject() {
        return this.enableFieldsArr = this.isNeighbourOrSarpanchOrVicinityDetailsAvailable !== 'No' ? {} : {remarks: this.remarks};
      },
      getFormData() {
        return {neighbourOrSarpanchOrVicinityVerificationDetails: this.$data.neighbourOrSarpanchOrVicinityVerificationDetails};
      },
      disableAllFields(){
        if(this.neighbourOrSarpanchOrVicinityVerificationDetails.isNeighbourOrSarpanchOrVicinityDetailsAvailable == 'No'){
          this.clearAllFields()
          return true
        }else{
          return false
        }
      }
  },
//   mounted() {
//     let found = this.items.find(el => el.sno == 20);
//     if(!found){
//       this.items.push({sno: 20, name: "View / Upload Documents"});
//     }
//     console.log("INDEX NUMBER 20", found);
    
//   },
  methods: {
    validateTabForms(){
      if(!this.$refs.form.validate()){
        this.$refs.form.validate() 
        this.showToast("Please fill all mandatory fields", this.TOST().WARNING);
        return
      }else{
        this.$refs.footerCardRef.interimSave();
      }
    },
    clearAllFields(){
      this.neighbourOrSarpanchOrVicinityVerificationDetails.neighbourOrRelativeOrFamilyMemberName = '';
      this.neighbourOrSarpanchOrVicinityVerificationDetails.neighbourOrRelativeOrFamilyMemberAddress = '';
      this.neighbourOrSarpanchOrVicinityVerificationDetails.NeighbourOrRelativeOrFamilyMemberMbnumber = '';
      this.neighbourOrSarpanchOrVicinityVerificationDetails.NeighbourOrRelativeOrFamilyMemberVersionOfAccident = '';
      this.neighbourOrSarpanchOrVicinityVerificationDetails.SarpanchOrGramPradhanName = '';
      this.neighbourOrSarpanchOrVicinityVerificationDetails.SarpanchOrGramPradhanAddress = '';
      this.neighbourOrSarpanchOrVicinityVerificationDetails.SarpanchOrGramPradhanMbnumber = '';
      this.neighbourOrSarpanchOrVicinityVerificationDetails.SarpanchOrGramPradhanVersionOfAccident = '';
    },
    dragleave(event) {
      this.visualEffect = false
    },
    browseCancel() {
      //to select attachments
    //   document.getElementById("cancelchequeupload").click()
    },
    dragover(event) {
      event.preventDefault()
      this.visualEffect = true
    },
    drop(event) {
      event.preventDefault()
    //   this.selectFile() // Trigger the onChange event manually
      this.visualEffect = false
    },
    computedDate(name) {
      return this[name] ? moment(this[name]).format("DD/MM/YYYY") : "";
    },
    goToPreviousTab() {
      this.sno--;
      let btnNumber = "myBtn" + this.sno;
      const elem = this.$refs[btnNumber];
      elem[0].$el.click();
    },
    goToNextTab() {
      this.sno++;
      console.log('this', this.$children.$options);
      let btnNumber = "myBtn" + this.sno;
      const elem = this.$refs[btnNumber];
      elem[0].$el.click();
    },
    switchTabs(sno, name) {
      this.sno = sno;
      this.tabName = name;
    },
    submit() {
      this.isAlertView = true;
    },
    accept() {
      this.isAlertView = true;
    },
    saveData() {},
    alphaNumeric(fieldName, name, mandatory=true) {
      console.log('fieldName', fieldName, name);
      if (fieldName.length > 0) {
        return (v) => /^[a-zA-Z0-9]+$/.test(v) || "Enter valid " + name;
      }
    },
    numericOnly(val) {
      if(val && val.length > 0){
        return (v) =>/^[1-9]\d{9}$/.test(v) || `Only Numbers are allowed.`;
      }
    },
    onSubmit() {},
    browse() {},
    selectFile() {},
  },
 }
</script>