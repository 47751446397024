<template>
  <div>
    <v-container fluid>
      <v-layout row>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Hospital Name
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="hospitalDetails.entityName"
              maxlength="100"
              counter="100"
              class="rounded-0"
              aria-required="true"
              :rules="[alphaNumeric(hospitalDetails.entityName, 'Hospital Name')]"
              disabled
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Hospital Ownership Type
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="hospitalDetails.providerType"
              maxlength="200"
              counter="200"
              class="rounded-0"
              aria-required="true"
              :rules="[
                alphaNumeric(hospitalDetails.providerType, 'Hospital Ownership Type'),
              ]"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Hospital PRN ( Hospital Id )
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="hospitalDetails.prn"
              maxlength="10"
              counter="10"
              class="rounded-0"
              aria-required="true"
              :rules="[
                alphaNumeric(hospitalDetails.prn, 'Hospital PRN ( Hospital Id )'),
              ]"
              disabled
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Hospital Rohini ID
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="hospitalDetails.rohiniId"
              maxlength="13"
              counter="13"
              class="rounded-0"
              aria-required="true"
              :rules="[alphaNumeric(hospitalDetails.rohiniId, 'Hospital Rohini ID')]"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Hospital Registration Number
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="hospitalDetails.registrationNumber"
              maxlength="50"
              counter="50"
              class="rounded-0"
              aria-required="true"
              :rules="[
                alphaNumeric(hospitalDetails.
                  registrationNumber,
                  'Hospital Registration Number'
                ),
              ]"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Hospital Registration Effective Date From
            </v-text>
            <v-menu
              ref="calender"
              :close-on-content-click="true"
              :nudge-right="40"
              transition="scale-transition"
              max-width="290px"
              min-width="290px"
              class="rounded-0"
            >
              <!-- :rules="typeOfEmployee !== 'Service ID'?[required()]:[()]" -->
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-bind="attrs"
                  label="Select.."
                  :value="computedDate('agreementStart')"
                  solo
                  class="rounded-0"
                  v-on="on"
                >
                  <div
                    slot="prepend-inner"
                    style="background-color: #f7f7f7; padding: 9px !important"
                  >
                    <v-icon>event</v-icon>
                  </div>
                </v-text-field>
              </template>
              <v-date-picker
                v-model="hospitalDetails.agreementStart"
                no-title
              >
              </v-date-picker>
            </v-menu>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Hospital Registration Effective Date To
            </v-text>
            <v-menu
              ref="calender"
              :close-on-content-click="true"
              :nudge-right="40"
              transition="scale-transition"
              max-width="290px"
              min-width="290px"
              class="rounded-0"
            >
              <!-- :rules="typeOfEmployee !== 'Service ID'?[required()]:[()]" -->
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-bind="attrs"
                  label="Select.."
                  :value="computedDate('agreementEnd')"
                  solo
                  class="rounded-0"
                  v-on="on"
                >
                  <div
                    slot="prepend-inner"
                    style="background-color: #f7f7f7; padding: 9px !important"
                  >
                    <v-icon>event</v-icon>
                  </div>
                </v-text-field>
              </template>
              <v-date-picker
                v-model="hospitalDetails.agreementEnd"
                no-title
              >
              </v-date-picker>
            </v-menu>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Hospital GST Number
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="hospitalDetails.hospitalGstNumber"
              maxlength="50"
              counter="50"
              class="rounded-0"
              aria-required="true"
              :rules="[alphaNumeric(hospitalDetails.hospitalGstNumber, 'Hospital GST Number')]"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Hospital PAN Number
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="hospitalDetails.panNumber"
              maxlength="10"
              counter="10"
              class="rounded-0"
              aria-required="true"
              :rules="[alphaNumeric(hospitalDetails.panNumber, 'Hospital PAN Number')]"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Hospital Type (Network/Non-network)
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="hospitalDetails.providerType"
              maxlength="20"
              counter="20"
              class="rounded-0"
              aria-required="true"
              :rules="[alphaNumeric(hospitalDetails.providerType, 'Hospital Type')]"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Hospital Categoty
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="hospitalDetails.hospitalCategory"
              maxlength="50"
              counter="50"
              class="rounded-0"
              aria-required="true"
              :rules="[alphaNumeric(hospitalDetails.hospitalCategory, 'Hospital Categoty')]"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Hospital Address
            </v-text>
            <v-tooltip top> 
              <template v-slot:activator="{ on, attrs }">
            <v-text-field
              solo
              label="Enter..."
              v-on="on" 
              v-bind="attrs"
              v-model="hospitalDetails.addressLine1"
              maxlength="200"
              counter="200"
              class="rounded-0"
              aria-required="true"
            >
            </v-text-field>
          </template>
            <span>{{hospitalDetails.addressLine1}}</span>
        </v-tooltip>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Hospital State
            </v-text>
            <v-text-field
              label="Enter..."
              disabled
              v-model="hospitalDetails.state"
              solo
              item-text="state"
              item-value="state"
              class="rounded-0"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Hospital City
            </v-text>
            <v-text-field
              label="Enter..."
              disabled
              v-model="hospitalDetails.cityTownVillage"
              solo
              item-text="state"
              item-value="state"
              class="rounded-0"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Hospital District
            </v-text>
            <v-text-field
              label="Enter..."
              disabled
              v-model="hospitalDetails.district"
              solo
              item-text="state"
              item-value="state"
              class="rounded-0"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Hospital Pincode
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="hospitalDetails.pinPostalCode"
              maxlength="6"
              counter="6"
              class="rounded-0"
              aria-required="true"
              :rules="[alphaNumeric(hospitalDetails.pinPostalCode, 'Hospital Pincode')]"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Hospital Total Number Of Beds
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="hospitalDetails.numberOfBeds"
              maxlength="5"
              counter="5"
              class="rounded-0"
              aria-required="true"
              :rules="[
                alphaNumeric(hospitalDetails.numberOfBeds, 'Hospital Total Number Of Beds'),
              ]"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Hospital Registered Body
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="hospitalDetails.registeredBody"
              maxlength="100"
              counter="100"
              class="rounded-0"
              aria-required="true"
              :rules="[alphaNumeric(hospitalDetails.registeredBody, 'Hospital Registered Body')]"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-container>
    <InvestigationFooterCard :tabno="sno" :formdata="getFormData" :componentName="'hospitalDetails'" :removeSaveButton="true"/>
  </div>
</template>
<script>
  import InvestigationFooterCard from './InvestigationFooterCard.vue';
  import { getAllPinCodes } from '../apiservices/Investigation';
  import { getClaimDetails, getClaimDetailsId } from '../apiservices/formData';
  import moment from 'moment';
  export default {
    name: 'HospitalDetails',
    data() {
        return {
            sno:4,
            rules: {
                mandatoryrule: [(v) => !!v || this.validationmessage],
            },
            lossStatesList:[],
            hospitalDetails: {
              entityName: "",
              validationmessage: "This field is required",
              providerType: "",
              prn: "",
              rohiniId: "",
              registrationNumber: "",
              registeredBody: "", // not given
              agreementStart: "",
              agreementEnd: "",
              hospitalGstNumber: "", // Anurag
              panNumber: "",
              providerType: "",
              hospitalCategory: "", // Anurag
              addressLine1: "",
              state: "",
              cityTownVillage: "",
              district: "",
              pinPostalCode: "",
              numberOfBeds: "",
            }
        }
    },
    components: {
      InvestigationFooterCard 
    },
    props: ['prepoulatedData'],
    async created() {
      // let token = this.$store.state.Auth && this.$store.state.Auth.user ? this.$store.state.Auth.user.signInUserSession.idToken.jwtToken : ''//this.$store.state.Auth.user.signInUserSession.idToken.jwtToken
      // await getHospitalDetails(token)
      //   .then((data) => {
      //     this.entityName = data.empanelment.entityName;
      //     let allKeys = Object.keys(data.empanelment.entityName);
      //     allKeys.forEach(key => {
      //       this.fIROrGDRVerificationDetails[key] = data.empanelment.entityName[key] !== undefined ? data.empanelment.entityName[key] : ''
      //     })
      //   }).catch((err)=> {
      //     console.log(err)
      //   });
      try{
          let res = await getClaimDetailsId(this.$route.params.claimId);
        // if(Object.keys().length > 0) {
            // Object.keys(this.$data.hospitalDetails).forEach(item => {
            //   this.hospitalDetails[item] = res[item];
            // });
            this.hospitalDetails.rohiniId = res.data.providerDetails.hospitalDetails.rohiniCode;
            this.hospitalDetails.entityName = res.data.providerDetails.hospitalDetails.providerName;
            this.hospitalDetails.prn = res.data.providerDetails.hospitalDetails.providerFlags.prn;
            this.hospitalDetails.panNumber = res.data.providerDetails.hospitalDetails.panNumber;
            this.hospitalDetails.addressLine1 = res.data.providerDetails.hospitalDetails.providerAddress;
            this.hospitalDetails.pinPostalCode = res.data.providerDetails.pinPostalCode;
            this.hospitalDetails.agreementStart = res.data.providerDetails.createdAt;
            this.hospitalDetails.providerType = res.data.providerDetails.hospitalDetails.providerType;
            this.hospitalDetails.hospitalCategory = res.data.providerDetails.hospitalDetails.providerStatus;
            this.getStateCity(this.hospitalDetails.pinPostalCode);
        // }
        } catch(e){
          this.showToast(e.response?.data?.msg || e.response?.data  || e.response || e, this.TOST().ERROR);
        }
    },
    computed: {
      getFormData() {
        return {hospitalDetails: this.$data};
      }
    },
    methods: {
      async getStateCity(pinCode) {
        try {
          let res = await getAllPinCodes(pinCode);
          this.hospitalDetails.state = res[0].stateName;
          this.hospitalDetails.cityTownVillage = res[0].stateName;
          this.hospitalDetails.district = res[0].cityDistrictName;
        } catch(e) {
          this.showToast(e.response?.data?.msg || e.response?.data  || e.response || e, this.TOST().ERROR);
        }
      },
    // getClaimRegistrationDetails() {
    //   axios.get('https://claimsdrive-health.salesdrive.app/claims-registration/claims-details/get-claim-registration-detail/63a43bc57d929019cdf14328',
    //   {headers:{
    //           "Content-Type": "application/json",
    //           "Authorization": this.$store.state.Auth && this.$store.state.Auth.user ? this.$store.state.Auth.user.signInUserSession.idToken.jwtToken : ''//this.$store.state.Auth.user.signInUserSession.idToken.jwtToken
    //         }}
    //   )
    //    .then((data) => { 
    //     console.log('shivam', data.data.data.claimsDetail);
    //     let claimDetail = data.data.data.claimsDetail;
    //     this.claimId = claimDetail.claimNumber;
    //     this.policyNumber = claimDetail.policyNumber;
    //     this.claimType = claimDetail.claimType;
    //     this.claimStatus = claimDetail.claimStatus;
    //     this.claimSubStatus = claimDetail.claimSubStatus;
    //     this.claimRegistrationDate = claimDetail.claimRegisteredDate;
    //     this.reservedAmount = claimDetail.reservedAmount;
    //     this.productName = claimDetail.productName;
    //     this.policyStatus = claimDetail.policyStatus;
    //     this.policyType = claimDetail.policyType;
    //     this.policyStartDate = claimDetail.policyStartDate;
    //     this.policyEndDate = claimDetail.policyEndDate;
    //     this.nameOfCorporate = claimDetail.nameOfCorporate;
    //     this.employeeId = claimDetail.employeeID;
    //     this.memberId = claimDetail.memberId;
    //     this.memberStatus = claimDetail.memberStatus;
    //     this.memberFirstName = claimDetail.firstName;
    //     this.memberMiddleName = claimDetail.middleName;
    //     this.memberLastName = claimDetail.lastName ? claimDetail.lastName : '' ;
    //     this.memberDateOfBirth = claimDetail.dateOfBirth;
    //     this.memberGender =  claimDetail.gender;
    //     this.relationshipToPrimaryInsured = claimDetail.relationshipToPrimaryInsured;
    //     this.memberOccupation = claimDetail.occupation;
    //     this.memberJoiningDate = claimDetail.joiningDate;
    //     this.memberLeavingDate = claimDetail.leavingDate;
    //     this.benefitClaimed = data.data.data.patientAdmissionDetails.benefitClaimed;
    //    }).catch((err) => {
    //     console.log(err);
    //    })
    // },
    computedDate(name) {
      return this[name] ? moment(this[name]).format("DD/MM/YYYY") : "";
    },
    goToPreviousTab() {
      this.sno--;
      let btnNumber = "myBtn" + this.sno;
      const elem = this.$refs[btnNumber];
      elem[0].$el.click();
    },
    goToNextTab() {
      this.sno++;
      let btnNumber = "myBtn" + this.sno;
      const elem = this.$refs[btnNumber];
      elem[0].$el.click();
    },
    switchTabs(sno, name) {
      console.log('sno', sno, name);
      this.sno = sno;
      this.tabName = name;
    },
    submit() {
      this.isAlertView = true;
    },
    accept() {
      this.isAlertView = true;
    },
    saveData() {},
    alphaNumeric(fieldName, name, mandatory=true) {
      console.log('fieldName', fieldName, name);
      if (fieldName && fieldName.length > 0) {
        return (v) => /^[a-zA-Z0-9]+$/.test(v) || "Enter valid " + name;
      }
    },
    numericOnly() {
      return (v) => /^[0-9 ]+$/.test(v) || `Only Numbers are allowed.`;
    },
    onSubmit() {},
    browse() {},
    selectFile() {},
  },
  }
</script>