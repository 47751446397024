<template>
    <div>

        <v-layout class="mt-6">
            <v-flex xs3>
                <v-layout column class="cust_pr_Ms mx-2">
                    <v-text class="font-weight-bold cstm-clr-pr-Ms">
                        Member ID
                    </v-text>
                
                    <v-text-field
                        solo
                        disabled
                        label="Enter..."
                        v-model="memberId"
                        class="rounded-0"
                        dense
                        >
                    </v-text-field>
                </v-layout>
            </v-flex>

            <v-flex xs12>
                
            </v-flex>
        </v-layout>

        <!-- <v-card class=" pa-5 rounded-0" style="background-color: #F7F7F7; display:flex;justify-content:space-between;">
            <v-layout style="flex:1" justify-end > 
                <v-btn dark tile color="#E46A25" class="text-capitalize px-3 ml-3 testing" small @click="searchMember"><v-icon dark small>search</v-icon> <v-divider vertical class="mx-2"></v-divider>Search</v-btn>
            </v-layout>
        </v-card> -->


        <v-card class="rounded-0">
                    <v-data-table
                        :headers="headers"
                        :items="dataItems"
                        dense
                        class="mt-5"
                        :page.sync="page"
                        :items-per-page="itemsPerPage"
                        hide-default-footer
                    >

                   <template v-slot:item.policyNumber="{ item }">
                            <div @click="policyNumberClick(item)" class="text-decoration-underline" style="color:#E46A25; cursor:pointer; font-size:12px !important">{{ item.policyNumber}}</div>
                        </template>
                        
                    </v-data-table>
                    <!-- <v-divider></v-divider>
                    <v-layout row justify-end align-center class="mx-2" >
                        <v-text class="mr-1 font-weight-bold body-1" style="color:#3C3D3D; font-size:12px !important">
                                <span class="" style="color:#E46A25;font-size:12px;">Showing {{fval}}-{{sval}} </span>out of {{totalLen }} records
                        </v-text>
                        <v-divider  class="mx-2" vertical ></v-divider>
                        <v-pagination 
                            class="cust-bnkAcc"
                            v-model="page"
                            color="#152F38"
                            flat
                            :total-visible="9"
                            @input="nextPageBtn(page)"
                            :length="pageLength"
                            ></v-pagination>
                    </v-layout> -->
            </v-card>



    </div>
</template>



<script>
export default {
    created(){
        this.$store.commit("SAVE_PAGE_HEADER", "Policy Details");
        console.log("coming from cliamsMainScreen",this.$route.params);
        this.memberId = this.$route.params.memberId;
        this.getMemberData()
        
    },

    data(){
        return{

            memberId:'',


            headers:[
                {text: 'Policy Number',value: 'policyNumber',sortable: false,divider:true,class:"hdr-txt-clrPer hdr-bg-clrPer "},
                {text: 'Policy Status',value: 'policyStatus',sortable: false,divider:true,class:"hdr-txt-clrPer hdr-bg-clrPer "},
                {text: 'Policy Type',value: 'policyType',sortable: false,divider:true,class:"hdr-txt-clrPer hdr-bg-clrPer "},
                {text: 'Product Name',value: 'productName',sortable: false,divider:true,class:"hdr-txt-clrPer hdr-bg-clrPer "},
                {text: 'Plan Type',value: 'planType',sortable: false,divider:true,class:"hdr-txt-clrPer hdr-bg-clrPer "},
                {text: 'First Policy Inception Date',value: 'firstIncepDate',sortable: false,divider:true,class:"hdr-txt-clrPer hdr-bg-clrPer "},
                {text: 'Policy Effective Date',value: 'policyEffDate',sortable: false,divider:true,class:"hdr-txt-clrPer hdr-bg-clrPer "},
            ],
            dataItems:[],


            // fval:0,
            // sval:0,
            // totalLen:'',
            // itemsPerPage:10,            
            // page: 1,
            // pageLength:'',
            // lastPage:false,
        }
    },

    methods:{
        getMemberData(){
            this.GET(8,'policy-details/get-policy-details?&memberId='+this.memberId,(res,error)=>{
                if(!error){
                    console.log("this is res",res.data.data);
                    let memberData = res.data.data
                    if(memberData.policyDet){
                        console.log("policy data",memberData.policyDet);
                        this.dataItems = memberData.policyDet.map(member => {
                                    return{
                                        policyNumber : member.policyNumber,  
                                        policyStatus : member.policyStatus,  
                                        policyType : member.policyType,    
                                        productName : member.productName,   
                                        planType : '',      
                                        firstIncepDate : member.firstPolicyInceptionDate,
                                        firstIncepDate : moment(incepDate).format('DD-MM-YYYY'),
                                        policyEffDate : member.policyEffDateTime, 
                                    }
                        })
                    }
                    }else{
                        console.log("there was error",error.response.data.msg);
                    }
                })
        },

        policyNumberClick(item){
                console.log("got item",item);
                this.$router.push({
                    name: 'PolicyDetails',
                    params: {
                        memberId: this.memberId,
                        policyNumber: item.policyNumber
                        }
                })
        }

    }


}

</script>

<style scoped>

.cstm-clr-pr-Ms{
    color:#646666 !important;
    margin-bottom: 5px !important;
    font-size: 13px !important;
}

.cust_pr_Ms .v-input{
    font-size: 13px !important;
}

.cstm-clr-pr-Ms+.v-text-field >>> label{ 
    font-size: 13px;
    
}

   .hdr-txt-clrPer{
    color: white !important;
    font-weight: bold;
    /* padding:0px!important;
    padding: 10px !important;
    padding-left: 14px !important; */
    }

    .hdr-bg-clrPer{
        background-color: #23B1A9;
    }
</style>
