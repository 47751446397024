import Vue from 'vue';
import Router from 'vue-router';

// *************************************************
  // PLEASE DO NOT TOUCH - AWS CONFIG AND IDS - stay away xD

import Home from './component/Home.vue';
import auth from './app/auth';
import LogoutSuccess from './component/LogoutSuccess';
import UserInfoStore from './app/user-info-store';
import UserInfoApi from './app/user-info-api';
import ErrorComponent from './component/Error.vue';


// *************************************************

import store from './store/state'
import mut from './store/index'
import mixone from './mixins'

import LoginView from './Page/LoginView'
import ProviderRegistration from './Page/ProviderRegistrationClaims/ProviderRegistrationTabs.vue'
import ProviderSummary from './Page/ProviderSummmaryClaims/ProviderSummaryTabs.vue'
import IndividualSummary from './Page/ProviderSummmaryClaims/IndividualSummary.vue'
// import InstitutionSummary from './Page/ProviderSummmaryClaims/InstitutionSummary.vue'
// import ViewSummaryInstitution from './Page/ProviderSummmaryClaims/ViewSummaryInstitution.vue'


import bulkUploadClams from './Page/bulkUploadClaims.vue';
import ProviderEmpanelment from './Page/ProviderEmpanelment/ProviderEmpanelmentTabs.vue';
import ProductSetup_ApprovalPrcess from './Page/ProductClaims/ProductSetup_ApprovalProcess.vue';
import CreateSearchRprting from './Page/ProductClaims/CreateSearchReporting.vue';
import CreateSearchPrdctType from './Page/ProductClaims/CreateSearchProductType.vue';

import HomePage from './Page/HomePage.vue'
 import ProviderSearch from './Page/ProviderSearch.vue'
import CreateSearchProduct from './Page/HC_Plans/CreateSearchProduct.vue';
import CreateEditProduct from './Page/HC_Plans/CreateEditProduct.vue'
import rel from './Page/Productconfigurescreens/RelationshipDependentInfo.vue'
// import ViewMyWorkItemsCases from './Page/ViewMyWorkItemsCases.vue';

//  import BasicPlanDetails from './Page/basicPlan/BasicPlanDetails.vue'


import ViewMyWorkItemsCases from './Page/ViewMyWorkItemsCases.vue'
import CreateEditPlan from './Page/HC_Plans/CreateEditPlan.vue'
import PolicySearch from './Page/HC_Plans/PolicySearch.vue'
import  Masterproduct from './Page/HC_Plans/masterproduct.vue'
import QuickEmpanelment from './Page/ProviderQuickEmpanelment/QuickEmpanelment.vue';
import ProviderValidation from './Page/ProviderValidation/ProviderValidation.vue';
import ProductConfigureTabs from './Page/Productconfigurescreens/ProductConfigureTabs.vue'
import CreatePolicyForProduct from './Page/HC_Plans/CreatePolicyForProduct.vue'
import CreateEditPlanForPolicy from './Page/HC_Plans/CreateEditPlanForPolicy.vue'
// import jestest from './component/imgup.vue'
import ProviderRegistrationAppQue from './Page/ProviderRegistrationAppQue.vue';
import ProviderEmpanelmentReview from './Page/ProviderEmpanelmentReview.vue';
import ProviderEmpanelmentApprovalQueue from './Page/ProviderEmpanelmentApprovalQueue.vue';
import ApprovalContractDetials from './Page/ApprovalContractDetialsQueue.vue';
import TDSDetials from './Page/TDSDetials.vue';
import TDSWorkItem from './Page/TDSWrokItem.vue'

// import jt from './component/tabsForEmpTest.vue';


import MapProductBenefitPolicy from './Page/Network/mapProductBenPol.vue'
import CreateTarrif from './Page/Network/Tariff/createTarrif.vue'
import SearchTarrif from './Page/Network/Tariff/searchTarrif.vue'
import AddProvider from './Page/Network/addProviders.vue'
import ProviderTarrifSelect from './Page/Network/providerTarrifSelect.vue'
import NetworkSetup from './Page/Network/NetworkSetup.vue'
import NetworkSearch from './Page/Network/NetworkSearch.vue'
import NetworkManagement from './Page/Network/NetworkManagement.vue';
import TarrifApproval from './Page/Network/prodTarrifApproval.vue';
import addProvBulkUpload from './Page/Network/empaneldBulkUpload.vue';

// user mngmnt
import RoleCreate from './Page/UserManagement/RoleCreate.vue';
import RoleSearch from './Page/UserManagement/RoleSearch.vue';
import SettlementOffice from './Page/UserManagement/SettlementOffice.vue';
import EmployeeMaster from './Page/UserManagement/EmployeeMaster.vue';
import UserZoneMapping from './Page/UserManagement/UserZoneMapping/UserMapSettlementOffice.vue';
import MapUserToZone from './Page/UserManagement/UserZoneMapping/MapUserToZone.vue';

// user mngmnt
import HospitalSearch from './Page/Hospital/HospitalSearch.vue';
import LoggedIn from './Page/Hospital/LoggedIn.vue';
import WorkItemQueue from './Page/Hospital/WorkItemQueue.vue';
import newlogHome from './Page/Hospital/newlogHome.vue';




// import InclusionPopup from './Page/TariffTagInclusion/InclusionPopup.vue';
// import PackageDiscountPopup from './Page/ConfigurePackageDiscount/PackageDiscount.vue';
import MedConsumeablePage from './Page/MedConsumeables/MedConsumeable.vue';
import ProfessionalFeePage from './Page/ProfessionalFee/ProfessionalFee.vue';
import InvestigationsChargePage from './Page/InvestigationsCharges/InvestigationsCharges.vue';
import AmbulanceChargePage from './Page/AmbulanceCharges/AmbulanceCharges.vue';
import CreateTariffPage from './Page/CreateNewTariff/CreateNewTariff.vue';
import ReviewCharges from './Page/CreateNewTariff/ReviewCharges.vue';
import TariffApprovalQueue from './Page/CreateNewTariff/TariffApprovalQueue.vue';
import PackagesPage from './Page/PackagesPage/PackagesPage.vue';
import PermissionSearch from './Page/UserManagement/PermissionSearch.vue';
import PermissionCreation from './Page/UserManagement/PermissionCreation.vue';

import TarrifConfigTabs from './Page/CreateNewTariff/TarrifConfigTabs.vue';
import ExcelBulkUploadTarrif from './Page/CreateNewTariff/ExcelBulkUploadTarrif.vue';
import ExcelBulkUploadTarrifLogs from './Page/CreateNewTariff/ExcelBulkUploadTarrifLogs.vue';


// FINANCIAL AUTHORITY
import FALimitMaster from './Page/UserManagement/FinancialAuthority/FALimitMaster.vue';
import FALimitMasterSearch from './Page/UserManagement/FinancialAuthority/FALimitMasterSearch.vue';
import FAApproval from './Page/UserManagement/FinancialAuthority/FAApproval.vue';

import FAMappingMasterTabs from './Page/UserManagement/NewFAMapping/faMappingApprovalNew.vue'//'./Page/UserManagement/FAMappingMaster/FAMappingMasterTabs.vue';
import UserApproval from './Page/UserManagement/UserApproval.vue';
import UserSearch from './Page/UserManagement/UserSearch.vue';
import MapUsertoFALevel from './Page/UserManagement/MapUsertoFALevel.vue';

//INWARDS
import InwardAssessor from './Page/Inwards/InwardAssessor.vue';
import InwardSupervisorQueue from './Page/Inwards/InwardSupervisorQueue.vue';

//Claim Intimation
import NewClaimIntimation from './Page/Claim_Intimations/New_Claim_Intimation.vue';
import ClaimIntimationSearch from './Page/Claim_Intimations/Claim_Intimation_search.vue';
import SelectIntimation from './Page/Claim_Intimations/Select_Intimation_ClaimScreen.vue';
import ClaimsMainScreen from './Page/Claim_Intimations/ClaimsMainScreen.vue';
import MemberSearch from './Page/Claim_Intimations/MemberSearch.vue';
import ClaimPolicySearch from './Page/Claim_Intimations/ClaimPolicySearch.vue';
import PolicyDetails from './Page/Claim_Intimations/PolicyDetails.vue';
import SearchPolicyDetails from './Page/Claim_Intimations/SearchPolicyDetails.vue';
import ClaimsProviderSearch from './Page/Claim_Intimations/ClaimsProviderSearch.vue';

//  import BoltonLogin from './Page/ProviderPortal/BoltonLogin.vue';
//  import MobileNum from './Page/ProviderPortal/MobileNum.vue';
// import MemberSearch from './Page/Claim_Intimations/MemberSearch.vue';

// OFR-CFR AA
import InvestigationAppointment from './Page/InvestigationAppointment.vue';
import InvestigationCommonModule from './Page/InvestigationCommonModule.vue';
import OFRdashboard from "./Page/OFR-CFRpages/OFRdashboard.vue";
import CFRdashboard from "./Page/OFR-CFRpages/CFRdashboard.vue";
import OFRauditSheet from "./Page/OFR-CFRpages/OFRauditSheet.vue";
import CFRauditSheet from "./Page/OFR-CFRpages/CFRauditSheet.vue";
// import OFRtriggerScreen from "./Page/OFR-CFRpages/OFRtriggerScreen.vue";
import OFRviewActivity from "./Page/OFR-CFRpages/OFRviewActivity.vue";
import CFRviewActivity from "./Page/OFR-CFRpages/CFRviewActivity.vue";

//Common CFR OFR by Travel Team
import CommonCFRView from "./Page/OFR-CFRpages/Common_CFR/CfrViewFile.vue"
import CommonCFRSearch from "./Page/OFR-CFRpages/Common_CFR/SearchCfr.vue"
import CommonOFRView from "./Page/OFR-CFRpages/Common_OFR/OfrViewFile.vue"
import CommonOFRSearch from "./Page/OFR-CFRpages/Common_OFR/SearchOfr.vue"

//Pre-Auth
import ClaimRegistration from './Page/PreAuth/ClaimRegistration.vue';
import InsuredPrsnHospitlzdDetails from './Page/PreAuth/InsuredPrsnHospitlzdDetails.vue';
import nidbdashboard from './Page/PreAuth/NidbWorkItem.vue'

// MARINE ROUTES IMPORTS BEGINS FROM HERE ======================= MARINE ====================

import PageNotFound from './components/PageNotFound';
// import loginView from './pages/Login/loginPageView';
// import ClaimDashboard from './pages/ClaimDashboard/claimdashboard.vue';
import AIGMaster from './pages/masters/aigOfficeMaster.vue';
import searchAIGOffice from './pages/masters/searchaigoffice.vue';
import claimMainScreen from './pages/claimMainScreen/claimMainScreen.vue';
import masters from './pages/masters/masters.vue'
import catastropheMaster from './pages/masters/CatastropheMasters.vue';
import fnolNewPage from './pages/FNOL/NewFNOL.vue'
//import AIGMasters from './pages/masters/AIGmasters.vue'
import PolicySearchMarine from './pages/FNOL/FNOLPolicySearch.vue';
import policyDetails from './pages/FNOL/FNOLpolicyDetails'
import TransactionHistory from './pages/TransactionHistory/transactionhistory.vue';
import majorClientMaster from './pages/masters/clientMaster.vue';
import userOffline from './pages/Useroffline/userOffline.vue'
import assignReassignAuditTrail from './pages/AuditTrail/assignreassignaudittrail.vue'

import TestTable from './pages/testpage/testtable.vue'
import DemoVuelidate from './pages/testpage/DemoVuelidate.vue'
// import samplepage from './pages/SamplePage/samplepage.vue'
//import testComponet from './components/uniTesting';
import testComponet from './components/Specialcomponents/Datepicker.vue'
import textComponent from './components/Specialcomponents/textfield.vue'
// import testComponet from './components/Specialcomponents/Datepicker.vue'
// import AIGMasterTest from './pages/masters/aigofficemastertest'
import statusAuditTrail from './pages/AuditTrail/statusAuditTrail.vue'
import generalAuditTrail from './pages/AuditTrail/generalAuditTrail.vue'
import auditTrail from './pages/AuditTrail/auditTrail.vue'
import masterAuditTrail from './pages/AuditTrail/masterAuditTrail.vue'
import workitemAssignment from './pages/ClaimDashboard/workitemAssignment.vue';
import SiuLanding from './Page/Landing/SiuLanding.vue';

import failedTransactionsDashboard from './Page/UserManagement/FailedTransactions/failedTransactions.vue';
import BotStatusComponent from './Page/Support/BotStatusComponent/BotStatusComponent';
import resetRetryCount from './Page/Support/ResetRetryCount/resetRetryCount.vue';

import newlogin from './component/newlogin.vue'
import mixinfunc from '../src/mixins/index'

// import Home from './component/Home.vue'
// import auth from './app/auth' ;
// import LogoutSuccess from '@/component/LogoutSuccess';
// import UserInfoStore from './app/user-info-store';
// import UserInfoApi from './app/user-info-api';

// By Travel Team
import createLitigation from './Page/Litigation/createLitigation.vue';
import litigationDetails from './Page/Litigation/litigationDetails.vue';
import searchLitigation from './Page/Litigation/searchLitigation.vue';

import Master from './Page/Master/master.vue';
import MasterApproval from './Page/Approval/Masters/MasterApproval.vue';
import FrequentPayee from './Page/Master/FrequentPayee/CreateFrequencyPayee.vue';
import SearchFrequentPayee from './Page/Master/FrequentPayee/SearchFrequencyPayee.vue';
import paymentRecovery from './Page/Recovery/paymentRecovery.vue';
import RecoverySearch from './Page/Recovery/searchRecovery.vue';
import FrequentPayeeApproval from './Page/Approval/Masters/FrequentPayee/CreateFrequentApproval.vue';
import SearchFrequentPayeeApproval from './Page/Approval/Masters/FrequentPayee/SearchFrequentApproval.vue';
import SearchCourtMaster from './Page/Master/Court/CourtMaster.vue';
import CreateCourtMaster from './Page/Master/Court/CreateCourtMaster.vue';
import SearchGroundOfDefenceMaster from './Page/Master/GroundOfDefence/GroundOfDefence.vue';
import CreateGroundOfDefenceMaster from './Page/Master/GroundOfDefence/CreateGroundOfDefence.vue';
import SearchPoliceStationMaster from './Page/Master/PoliceStation/PoliceStationMaster.vue';
import CreatePoliceStationMaster from './Page/Master/PoliceStation/CreatePoliceStationMaster.vue';
import SearchSiuQc2ConfiguratorMaster from './Page/Master/SiuQc2Configurator/SiuQc2ConfiguratorMaster.vue';
import CreateSiuQc2ConfiguratorMaster from './Page/Master/SiuQc2Configurator/CreateSiuQc2ConfiguratorMaster.vue';
import SearchSiuDocumentChecklistMaster from './Page/Master/SiuDocumentChecklist/SiuDocumentChecklistMaster.vue';
import CreateSiuDocumentChecklistMaster from './Page/Master/SiuDocumentChecklist/CreateSiuDocumentChecklistMaster.vue';
import CreateCourtApprovals from './Page/Approval/Masters/Court/CreateCourtApprovals.vue';
import SearchCourtApprovals from './Page/Approval/Masters/Court/SearchCourtApprovals.vue';
import SearchSalvageBuyerMaster from './Page/Master/SalvageBuyerMaster/SearchSalvageBuyerMaster.vue';
import CreateSalvageBuyerMaster from './Page/Master/SalvageBuyerMaster/CreateSalvageBuyerMaster.vue';
import SearchServiceProvider from './Page/Master/ServiceProvider/SearchServiceProvider.vue';
import CreateServiceProvider from './Page/Master/ServiceProvider/CreateServiceProvider.vue';
import SearchServiceProviderApprove from './Page/Approval/Masters/ServiceProvider/SearchServiceProviderApprove.vue';
import CreateServiceProviderApprove from './Page/Approval/Masters/ServiceProvider/CreateServiceProviderApprove.vue';

import SearchAIGMaster from './Page/Master/AIG/searchAIG.vue';
import CreateAIGMaster from './Page/Master/AIG/createAIG.vue';
import SearchCatastropheMaster from './Page/Master/Catastrophe/searchCatastrophe.vue';
import CreateCatastropheMaster from './Page/Master/Catastrophe/createCatastrophe.vue';
import SearchCommonCommunication from './Page/Common-Communication/CommonSearch.vue';
import SearchCommunication from './Page/Common-Communication/searchCommunication.vue';
import SearchAigApprove from './Page/Approval/Masters/Aig/searchAig.vue';
//Vendor Master
import SearchVendorMaster from './Page/Master/VendorMaster/SearchVendorMaster.vue'
import CreateVendorMaster from './Page/Master/VendorMaster/CreateVendorMaster.vue'
//Vendor Approval 
import SearchVendorApproval from './Page/Approval/Masters/Vendor/SearchVendorApproval.vue'
import VendorApproval from './Page/Approval/Masters/Vendor/VendorMasterApproval.vue'
//GST Master
import SearchGSTMaster from './Page/Master/GST/SearchGST.vue'
import CreateGSTMaster from './Page/Master/GST/CreateGST.vue'
//Repairer Master
import SearchRepairer from './Page/Master/Repairer/searchRepairer.vue';
import CreateRepairer from './Page/Master/Repairer/createrepairer.vue';
import CreateChildRepairer from './Page/Master/Repairer/createChildRepairer.vue'
import ParentWithChildRepairer from './Page/Master/Repairer/parentRepairerWithChild.vue';
import ChildWithParentRepairer from './Page/Master/Repairer/childRepairerWithParent.vue';

//Repairer Approval
import repairerApproval from './Page/Approval/Masters/Repairer/repairerapprovalsearch.vue'

import TDSMasterApproval from './Page/Approval/Masters/TDS-MasterApproval.vue';
import BaseRateTDS from "./Page/Approval/Masters/BaseRate/BaseRateTDS.vue"
import PenalRateTDS from "./Page/Approval/Masters/PenalRate/PenalRateTDS.vue"
import ConcessionalPenal from "./Page/Approval/Masters/ConcessionalPenal/ConcessionalPenal.vue"
import BulkUpload from "./Page/Approval/Masters/BulkUpload/BulkUpload.vue"

// TDS Master
import TDSMaster from './Page/TDS-Master/TDS-Master.vue';
import BaseTDSMaster from './Page/TDS-Master/Base.vue';
import PenalTDSMaster from './Page/TDS-Master/Penal.vue';
import ConcessionalTDSMaster from './Page/TDS-Master/Concessional.vue';

// TDS Master Bulk Pages
import BaseTDSMasterBulk from './Page/TDS-Master/BaseBulk.vue'
import ConcessionalTDSMasterBulk from './Page/TDS-Master/ConcessionalBulk.vue';
import WorkItems from './Page/TDS-Master/WorkItem.vue';

// Vue.use(Router)

// function requireAuth(to, from, next) {
  
//   if (!auth.auth.isUserSignedIn()) {
//     console.log("RA(), from IF route js", auth);
//       UserInfoStore.setLoggedIn(false);
//       next({
//       path: '/login',
//       query: { redirect: to.fullPath }
//       });
//   } else {
//     console.log("RA(), from ELSE  route js", auth);
//     UserInfoApi.getUserInfo().then(response => {
//       UserInfoStore.setLoggedIn(true);
//       UserInfoStore.setCognitoInfo(response);
//       next();
//     });
//   }
// }
Vue.use(Router);
function requireAuth(to, from, next) {
  // alert("required auth called")
  mixinfunc.methods.check_cache(res=>{
      console.log("Res is in AUTH", res)
    if(res){
      // alert("cache is present")
      next()
    }else{
      // alert("cache is NOT present")

      next('/login')
    }

  })

}
// const originalPush=Router.prototype.push
//     Router.prototype.push=function push(location){
//         return originalPush.call(this,location).catch(err=>{
//     })
// }
let routers_are = [
  {
     path: '/error', 
      component: ErrorComponent
    },
   {
      path: '/home',
      name: 'Home',
      component: Home,
      beforeEnter: requireAuth
    },
    {
      path: '/botStatus',
      name: 'botStatus',
      component: BotStatusComponent,
      beforeEnter: requireAuth
  },
    // {
    //   path: '/login', beforeEnter(to, from, next){
    //     auth.auth.getSession();
    //   }
    // },
     {
      path: '/logout', component: LogoutSuccess,  beforeEnter(to, from, next){
        auth.logout();
        next();
      }
    },
    {
      path: '/login/oauth2/code/commonuat', beforeEnter(to, from, next){
        console.log("on call back url ", window.location)
        console.log("on call back url-common UAT -> ", window.location)
        var currUrl = window.location.href;
        
        console.log('hitting the callback url [cc-UAT] ----->',currUrl);
        auth.auth.parseCognitoWebResponse(currUrl);
        //next();
      }
    },

    {
      path: '/loginis', beforeEnter(to, from, next){
        console.log(to,"to | from",from);
        console.log("loginis ", window.location)
        // console.log("mixinfunc",mixinfunc.methods.gettoken())
        // window.location.search.split('?')[1].split('=')[0]
          // Earlier code
            // let presetdata_is = window.location.search.split('?')[1].split('=');
            // console.log('presetdata_is >',presetdata_is);
            // if(presetdata_is[0] == '_id'){
            //     console.log('gettoken >',presetdata_is)
            //     //  alert("_id is found now calling token api")
            //     mixinfunc.methods.gettoken(presetdata_is[1], res=>{
            //       if(res){
            //         next({
            //               path: '/home'
            //               });
            //       }
            //     })
            // }
            let presetdata_is = window.location.search.split('_id=').pop().split('&')[0];
            let data = '', decodedComponent = '', parsedData = {}, path = '', name = '', params = {};
            if(window.location.search.includes('[')) {
              data = window.location.search.split('[').pop().split(']');
              decodedComponent = decodeURIComponent(data[0]);
              parsedData = JSON.parse(decodedComponent);
            }
            console.log('presetdata_is >',presetdata_is);
            if(presetdata_is){
                console.log('gettoken >',presetdata_is)
                if(parsedData) {
                  if(parsedData.hasOwnProperty('path')) {
                    path = parsedData.path;
                  }
                  if(parsedData.hasOwnProperty('name')) {
                    name = parsedData.name;
                    params = parsedData.params;
                  }
                  // this.$store.commit('SET_OTHER_LOB_DATA', parsedData);
                }
                console.log('new data is =>>>>>>>', data, decodedComponent, parsedData);
                //  alert("_id is found now calling token api")
                 mixinfunc.methods.gettoken(presetdata_is, res=>{
                   if(res){
                    console.log(`Redirecting to ${path}`)
                    if(path){
                      next({
                        path: path || '/home',
                      });
                    } else {
                      next({
                        name: name || 'Home',
                        params: params || {},
                      });
                    }
                   }
                 })
            }

          
      }
    },
    {
      path: '/login',
      name: 'newlogin',
      component: newlogin

    },

    // OFR-CFR AA
	{
		path: "/OFRdashboard",
		name: "OFRdashboard",
		component: OFRdashboard,
		beforeEnter: requireAuth,
	},
  {
    path: "/investigation-appointment",
    name: "InvestigationAppointment",
    component: InvestigationAppointment,
    beforeEnter: requireAuth,
  }, 
  {
    path: "/InvestigationCommonModule",
    name: "InvestigationCommonModule",
    component: InvestigationCommonModule,
    beforeEnter: requireAuth,
  },
  {
    path: "/investigation-workitem",
    name: "SiuInvestigatorWorkitem",
    component: SiuLanding,
    beforeEnter: requireAuth
  },

	{
		path: "/CFRdashboard",
		name: "CFRdashboard",
		component: CFRdashboard,
		beforeEnter: requireAuth,
	},
	{
		path: "/OFRauditSheet",
		name: "OFRauditSheet",
		component: OFRauditSheet,
		beforeEnter: requireAuth,
	},

	{
		path: "/CFRauditSheet",
		name: "CFRauditSheet",
		component: CFRauditSheet,
		beforeEnter: requireAuth,
	},
	// {
	// 	path: "/OFRtriggerScreen",
	// 	name: "OFRtriggerScreen",
	// 	component: OFRtriggerScreen,
	// 	beforeEnter: requireAuth,
	// },
	{
		path: "/OFRviewActivity",
		name: "OFRviewActivity",
		component: OFRviewActivity,
		beforeEnter: requireAuth,
	},
  {
		path: "/CFRviewActivity",
		name: "CFRviewActivity",
		component: CFRviewActivity,
		beforeEnter: requireAuth,
	},
  {
		path: "/commoncfr",
		name: "CommonCFRView",
		component: CommonCFRView,
		beforeEnter: requireAuth
	},
  {
		path: "/commoncfrsearch",
		name: "CommonCFRSearch",
		component: CommonCFRSearch,
		beforeEnter: requireAuth,
	},
  {
		path: "/commonofr",
		name: "CommonOFRView",
		component: CommonOFRView,
		beforeEnter: requireAuth,
	},
  {
		path: "/commonofrsearch",
		name: "CommonOFRSearch",
		component: CommonOFRSearch,
		beforeEnter: requireAuth,
	},
    

  // {
  //   path: '/boltonLogin',
  //   name: 'boltonLogin',
  
  // },
  // {
  //   path: '/mobileNum',
  //   name: 'mobileNum',
   
  // },
  // {
  //   path:'/InwardPreAuth',
  //   name:'InwardPreAuth',
  
  // },
  //  {
  //     path: '/',
  //     name: 'Home',

  //     beforeEnter: requireAuth
  //   },
  //   {
  //     path: '/login', beforeEnter(to, from, next){
  //       auth.auth.getSession();
  //     }
  //   },
  //   {
  //     path: '/login/oauth2/code/marine', beforeEnter(to, from, next){
  //       var currUrl = window.location.href;
        
  //       console.log('CALLBACK URL FROM r js',currUrl);
  //       auth.auth.parseCognitoWebResponse(currUrl);
  //       //next();
  //     }
  //  },
  {
    path:'/dashboard',
    name:'nidbdashboard',
     beforeEnter: requireAuth,
    component: nidbdashboard
  },
  {
    path:'/detailsofinsuredpersonhospitalized',
    name:'InsuredPrsnHospitlzdDetails',
     beforeEnter: requireAuth,
    component: InsuredPrsnHospitlzdDetails
  },
  {
    path:'/claimregistration',
    name:'claimRegistration',
     beforeEnter: requireAuth,
    component: ClaimRegistration
    
  },
  {
    path:'/SelectIntimation',
    name:'SelectIntimation',
     beforeEnter: requireAuth,
    component: SelectIntimation
  },
  {
    path:'/ClaimIntimationSearch',
    name:'ClaimIntimationSearch',
     beforeEnter: requireAuth,
    component: ClaimIntimationSearch
  },
  {
    path:'/NewClaimIntimation',
    name:'NewClaimIntimation',
     beforeEnter: requireAuth,
    component: NewClaimIntimation
  },
  {
    path:'/InwardSupervisorQueue',
    name:'InwardSupervisorQueue',
     beforeEnter: requireAuth,
    component: InwardSupervisorQueue
  },
  {
    path:'/InwardAssessor',
    name:'InwardAssessor',
     beforeEnter: requireAuth,
    component: InwardAssessor
  },
  {
    path:'/HospitalSearch',
    name:'HospitalSearch',
     beforeEnter: requireAuth,
    component: HospitalSearch
  },
  {
    path:'/LoggedIn',
    name:'LoggedIn',
     beforeEnter: requireAuth,
    component: LoggedIn
  },
  {
    path:'/newlogHome',
    name:'newlogHome',
     beforeEnter: requireAuth,
    component: newlogHome
  },

  {
    path:'/supervisordataentryqueue',
    name:'supervisordataentryqueue',
     beforeEnter: requireAuth,
    component: WorkItemQueue
  },
  {
    path:'/supervisorcodingqueue',
    name:'supervisorcodingqueue',
     beforeEnter: requireAuth,
    component: WorkItemQueue
  },
  {
    path:'/supervisormedicalscrutinyqueue',
    name:'supervisorMedicalScrutinyQueue',
     beforeEnter: requireAuth,
    component: WorkItemQueue
  },
  {
    path:'/supervisorcommercialscrutinyqueue',
    name:'SupervisorCommercialScrutinyQueue',
     beforeEnter: requireAuth,
    component: WorkItemQueue
  },
  {
    path:'/supervisorpaymentqueue',
    name:'supervisorPaymentQueue',
     beforeEnter: requireAuth,
    component: WorkItemQueue
  },
  {
    path:'/internalinvestigation',
    name:'internalInvestigation',
     beforeEnter: requireAuth,
    component: WorkItemQueue
  },
  {
    path:'/ofr',
    name:'OFR',
     beforeEnter: requireAuth,
    component: WorkItemQueue
  },
  {
    path:'/supervisorquickempanelmentqueue',
    name:'supervisorQuickEmpanelmentQueue',
     beforeEnter: requireAuth,
    component: WorkItemQueue
  },
  {
    path:'/negotiation',
    name:'negotiation',
     beforeEnter: requireAuth,
    component: WorkItemQueue
  },
  {
    path:'/cfr',
    name:'CFR',
     beforeEnter: requireAuth,
    component: WorkItemQueue
  },
  {
    path:'/MapUsertoFALevel',
    name:'MapUsertoFALevel',
     beforeEnter: requireAuth,
    component: MapUsertoFALevel
  },
  
  {
    path:'/FALimitMasterSearch',
    name:'FALimitMasterSearch',
     beforeEnter: requireAuth,
    component: FALimitMasterSearch
  },
  {
    path:'/FALimitMaster',
    name:'FALimitMaster',
     beforeEnter: requireAuth,
    component: FALimitMaster
  },
  {
    path:'/SettlementOffice',
    name:'SettlementOffice',
     beforeEnter: requireAuth,
    component: SettlementOffice
  },
  {
    path:'/RoleCreate',
    name:'RoleCreate',
     beforeEnter: requireAuth,
    component: RoleCreate
  },
  {
    path:'/RoleSearch',
    name:'RoleSearch',
     beforeEnter: requireAuth,
    component: RoleSearch
  },
  {
    path:'/NetworkSetup',
    name:'NetworkSetup',
     beforeEnter: requireAuth,
    component:NetworkSetup
  },
  {
    path:'/NetworkSearch',
    name:'NetworkSearch',
     beforeEnter: requireAuth,
    component:NetworkSearch
  },
  {
    path:'/NetworkManagement',
    name:'NetworkManagement',
     beforeEnter: requireAuth,
    component:NetworkManagement
  },
  {
    path:'/emp-bulk-upload',
    name:'addProvBulkUpload',
     beforeEnter: requireAuth,
    component:addProvBulkUpload
  },
  {
    path:'/tarrif-approval',
    name:'TarrifApproval',
     beforeEnter: requireAuth,
    component:TarrifApproval
  },
  {
    path:'/rel',
    name:'rel',
     beforeEnter: requireAuth,
    component:rel
  },
  {
    path:'/FAApproval',
    name:'FAApproval',
     beforeEnter: requireAuth,
    component:FAApproval
  },
  
  
  // { 
  //   path: '/jt',
  // },
  {
    path: '/map-product',
    name: 'MapProductBenefitPolicy',
     beforeEnter: requireAuth,
    component: MapProductBenefitPolicy
  },
  {
    path: '/add-provider',
    name: 'AddProvider',
     beforeEnter: requireAuth,
    component: AddProvider
  },
  {
    path: '/provider-tarrif-select',
    name: 'ProviderTarrifSelect',
     beforeEnter: requireAuth,
    component: ProviderTarrifSelect
  },
  {
    path: '/create-tarrif',
    name: 'CreateTarrif',
     beforeEnter: requireAuth,
    component: CreateTarrif
  },
  {
    path: '/search-tarrif',
    name: 'SearchTarrif',
     beforeEnter: requireAuth,
    component: SearchTarrif
  },
  // {
  //   path: '/login',
  //   name: 'login',

  // },
  {
    path: '/homePage',
    name: 'homePage',
     beforeEnter: requireAuth,
    component: HomePage,
    // beforeEnter: requireAuth
    // meta:{auth:true}
  },
   {
     path: '/ProviderSearch',
     name: 'ProviderSearch',
      beforeEnter: requireAuth,
     component: ProviderSearch
   },
  {
    path: '/product',
    name: 'masterproduct',
     beforeEnter: requireAuth,
    component: Masterproduct,
    children: [
                {
                  path: 'createEditProduct',
                  name: 'CreateEditProduct',
                   beforeEnter: requireAuth,
                  component: CreateEditProduct,
              },
              {
                  path: 'createSearchProduct',
                  name: 'CreateSearchProduct',
                   beforeEnter: requireAuth,
                  component: CreateSearchProduct,
              },
              {
                path: 'createditplan',
                name: 'CreateEditPlan',
                 beforeEnter: requireAuth,
                component: CreateEditPlan
              },
              {
                path: 'policysearch',
                name: 'PolicySearch',
                 beforeEnter: requireAuth,
                component: PolicySearch
              },
              {
                path: 'productconfiguration',
                name: 'productconfiguration',
                 beforeEnter: requireAuth,
                component: ProductConfigureTabs
              },
              {
                path: 'createditpolicyforproduct',
                name: 'createditpolicyforproduct',
                 beforeEnter: requireAuth,
                component: CreatePolicyForProduct
              },
              {
                path: 'createditplanforpolicy',
                name: 'createditplanforpolicy',
                 beforeEnter: requireAuth,
                component: CreateEditPlanForPolicy
              }
      ]
  },

  {
    path: '/Providerregistration',
    name: 'ProviderRegistrationnew',
     beforeEnter: requireAuth,
    component: ProviderRegistration
  },
  {
    path: '/providersummary',
    name: 'ProviderSummary',
     beforeEnter: requireAuth,
    component: ProviderSummary,
  },

  {
    path:'/indform',
    name:'IndividualSummary',
     beforeEnter: requireAuth,
    component:IndividualSummary
  },

  {  
  path: '/bulkuploadclaims',
  name: 'bulkUploadClams',
   beforeEnter: requireAuth,
  component: bulkUploadClams
  
  },

  {
    path: '/providerempanelment',
    name: 'ProviderEmpanelment',
     beforeEnter: requireAuth,
    component: ProviderEmpanelment
  },
  {
    path: '/productsetupapprovalprocess',
    name: 'productsetupapprovalprcess',
     beforeEnter: requireAuth,
    component: ProductSetup_ApprovalPrcess
  },
  {
    path: '/createsearchreporting',
    name: 'CreateSearchRprting',
     beforeEnter: requireAuth,
    component: CreateSearchRprting
  },
  {
    path: '/createsearchproducttype',
    name: 'CreateSearchPrdctType',
     beforeEnter: requireAuth,
    component: CreateSearchPrdctType
  },

  {
    path: '/vwwrkitmcases',
    name: 'ViewMyWorkItemsCases',
     beforeEnter: requireAuth,
    component: ViewMyWorkItemsCases
  },
  {
    path: '/quickEmpanelment',
    name: 'QuickEmpanelment',
     beforeEnter: requireAuth,
    component: QuickEmpanelment
  },
  {
    path: '/providerValidation',
    name: 'ProviderValidation',
     beforeEnter: requireAuth,
    component: ProviderValidation
  },
  {
    path: '/providerRegistrationAppQue',
    name: 'ProviderRegistrationAppQue',
     beforeEnter: requireAuth,
    component: ProviderRegistrationAppQue
  },
  // {
  //   path: '/InclusionPopup',
  //   name: 'InclusionPopup',

  // },
  // {
  //   path: '/PackageDiscountPopup',
  //   name: 'PackageDiscountPopup',

  // },
  {
    path: '/MedConsumeablePage',
    name: 'MedConsumeablePage',
     beforeEnter: requireAuth,
    component: MedConsumeablePage
  },
  {
    path: '/ProfessionalFeePage',
    name: 'ProfessionalFeePage',
     beforeEnter: requireAuth,
    component: ProfessionalFeePage
  },
  {
    path: '/InvestigationsChargePage',
    name: 'InvestigationsChargePage',
     beforeEnter: requireAuth,
    component: InvestigationsChargePage
  },
  {
    path: '/AmbulanceChargePage',
    name: 'AmbulanceChargePage',
     beforeEnter: requireAuth,
    component: AmbulanceChargePage
  },
  {
    path: '/CreateTariffPage',
    name: 'CreateTariffPage',
     beforeEnter: requireAuth,
    component: CreateTariffPage
  },
  {
    path: '/PackagesPage',
    name: 'PackagesPage',
     beforeEnter: requireAuth,
    component: PackagesPage
  },
  {
    path: '/tarrifconfiguration',
    name: 'TarrifConfigTabs',
     beforeEnter: requireAuth,
    component: TarrifConfigTabs
  },
  {
    path: '/tarrifbulkupload',
    name: 'ExcelBulkUploadTarrif',
     beforeEnter: requireAuth,
    component: ExcelBulkUploadTarrif
  },
  {
    path: '/providerEmpanelmentReview',
    name: 'ProviderEmpanelmentReview',
     beforeEnter: requireAuth,
    component: ProviderEmpanelmentReview
  },

  {
    path: '/providerEmpanelmentApprovalQueue',
    name: 'ProviderEmpanelmentApprovalQueue',
     beforeEnter: requireAuth,
    component: ProviderEmpanelmentApprovalQueue
    
  },

  {
    path: '/approvalContractDetials',
    name: 'ApprovalContractDetials',
     beforeEnter: requireAuth,
    component: ApprovalContractDetials
    
  },

  
  {
    path: '/TDSDetials',
    name: 'TDSDetials',
     beforeEnter: requireAuth,
    component: TDSDetials
    
  },
  

  {
    path: '/TDSWorkItem',
    name: 'TDSWorkItem',
     beforeEnter: requireAuth,
    component: TDSWorkItem
    
  },
  {
    path: '/reviewCharges',
    name: 'ReviewCharges',
     beforeEnter: requireAuth,
    component: ReviewCharges
  },
  {
    path: '/tariffapprovalqueue',
    name: 'TariffApprovalQueue',
     beforeEnter: requireAuth,
    component: TariffApprovalQueue
  },

  
  {
    path: '/permissionsearch',
    name: 'PermissionSearch',
     beforeEnter: requireAuth,
    component: PermissionSearch
  },

  {
    path: '/permissioncreation',
    name: 'PermissionCreation',
     beforeEnter: requireAuth,
    component: PermissionCreation
  },
  {
    path: '/famappingmaster',
    name: 'FAMappingMasterTabs',
     beforeEnter: requireAuth,
    component: FAMappingMasterTabs
  },
  {
    path: '/userApproval',
    name: 'UserApproval',
     beforeEnter: requireAuth,
    component: UserApproval,
  },
  {
    path:'/employeemaster',
    name:'EmployeeMaster',
     beforeEnter: requireAuth,
    component: EmployeeMaster
  },
  {
    path:'/userzonemapping',
    name:'UserZoneMapping',
     beforeEnter: requireAuth,
    component: UserZoneMapping
  },
  {
    path:'/mapusertozone',
    name:'MapUserToZone',
    component: MapUserToZone,
    beforeEnter: requireAuth
  },
  {
    path: '/usersearch',
    name: 'UserSearch',
     beforeEnter: requireAuth,
    component: UserSearch
  },
  {
    path: '/tarrifbulkuploadlogs',
    name: 'ExcelBulkUploadTarrifLogs',
     beforeEnter: requireAuth,
    component: ExcelBulkUploadTarrifLogs
  },

  {
    path: '/claimsMainScreen',
    name: 'ClaimsMainScreen',
     beforeEnter: requireAuth,
    component: ClaimsMainScreen
  },

  {
    path: '/memberSearch',
    name: 'MemberSearch',
     beforeEnter: requireAuth,
    component: MemberSearch
  },
  {
    path: '/memberSearch/:page',
    name: 'MemberSearch_Claims',
     beforeEnter: requireAuth,
    component: MemberSearch
  },
  {
    path: '/claimPolicySearch',
    name: 'ClaimPolicySearch',
     beforeEnter: requireAuth,
    component: ClaimPolicySearch
  },

  {
    path: '/policyDetails',
    name: 'PolicyDetails',
     beforeEnter: requireAuth,
    component: PolicyDetails
  },

    // OFR-CFR AA

	{
		path: "/OFRdashboard",
		name: "OFRdashboard",
		component: OFRdashboard,
		beforeEnter: requireAuth,
	},

	{
		path: "/CFRdashboard",
		name: "CFRdashboard",
		component: CFRdashboard,
		beforeEnter: requireAuth,
	},
	{
		path: "/OFRauditSheet",
		name: "OFRauditSheet",
		component: OFRauditSheet,
		beforeEnter: requireAuth,
	},

	{
		path: "/CFRauditSheet",
		name: "CFRauditSheet",
		component: CFRauditSheet,
		beforeEnter: requireAuth,
	},
	// {
	// 	path: "/OFRtriggerScreen",
	// 	name: "OFRtriggerScreen",
	// 	component: OFRtriggerScreen,
	// 	beforeEnter: requireAuth,
	// },
	{
		path: "/OFRviewActivity",
		name: "OFRviewActivity",
		component: OFRviewActivity,
		beforeEnter: requireAuth,
  },
  {
		path: "/CFRviewActivity",
		name: "CFRviewActivity",
		component: CFRviewActivity,
		beforeEnter: requireAuth,
	},
  {
		path: "/commoncfr",
		name: "CommonCFRView",
		component: CommonCFRView,
		beforeEnter: requireAuth
	},
  {
		path: "/commoncfrsearch",
		name: "CommonCFRSearch",
		component: CommonCFRSearch,
		beforeEnter: requireAuth,
	},
  {
		path: "/commonofr",
		name: "CommonOFRView",
		component: CommonOFRView,
		beforeEnter: requireAuth,
	},
  {
		path: "/commonofrsearch",
		name: "CommonOFRSearch",
		component: CommonOFRSearch,
		beforeEnter: requireAuth,
	},

  // ALL MARINE ROUTES TO BE REGISTERED FROM BELOW HERE ----------------- MARINE -ROUTES -----------------
    {
        path: '/PNF',
        name: 'PageNotFound',
         beforeEnter: requireAuth,
        component: PageNotFound //added by gopikrishna
    },
    {
        path: '/testtable',
        name: 'TestTable',
         beforeEnter: requireAuth,
        component: TestTable
    },
    {
        path: '/test',
        name: 'testComponet',
         beforeEnter: requireAuth,
        component: testComponet
    },
    {
        path: '/text',
        name: 'textComponent',
         beforeEnter: requireAuth,
        component: textComponent
    },
    
    {
        path: '/vuelidate',
        name: 'DemoVuelidate',
         beforeEnter: requireAuth,
        component: DemoVuelidate
    },
    // {
    //     path: '/login',
    //     name: 'Login',

    // },
    // {
    //     path: '/login',
    //     name: 'Login',
    //     // redirect: '/login'
    // },
    // {
    //     path: '/home',
    //     name: 'Home',
    //     component: ClaimDashboard,
    //     meta: {auth:true},
    //      beforeEnter: requireAuth,// 
    //     component:() => import('../pages/ClaimDashboard/claimdashboard.vue')

    // },
    {
        path: '/aigofficemaster',
        name: 'AIGMaster',
         beforeEnter: requireAuth,
        component: AIGMaster
    },
    {
        path: '/searchaigoffice',
        name: 'SearchAIGOffice',
         beforeEnter: requireAuth,
        component: searchAIGOffice
    },
    {
        path: '/claimmainscreen',
        name: 'ClaimMainScreen',
         beforeEnter: requireAuth,
        component: claimMainScreen
    },
    {
        path: '/masters',
        name: 'Masters',
         beforeEnter: requireAuth,
        component: masters,
    },
    {
        path: '/masters/catastrophemasters',
        name: 'CatastropheMasters',
         beforeEnter: requireAuth,
        component: catastropheMaster
    },

    {
        path: '/masters/clientMaster',
        name: 'MajorClientMaster',
         beforeEnter: requireAuth,
        component: majorClientMaster
    },
    {
        path: '/markuseroffline',
        name: 'MarkUserOffline',
         beforeEnter: requireAuth,
        component: userOffline
    },
 
    {
        path: '/fnol/policysearch',
        name: 'PolicySearchMarine',
         beforeEnter: requireAuth,
        component: PolicySearchMarine
    },
    {
        path: '/fnol/policydetails',
        name: 'PolicyDetails',
         beforeEnter: requireAuth,
        component: policyDetails
    },
    {
        path: '/transhistory',
        name: 'TransactionHistory',
         beforeEnter: requireAuth,
        component: TransactionHistory
    },
 
    {
        path: '/assignreassignaudittrail',
        name: 'AssignReassignAuditTrail',
         beforeEnter: requireAuth,
        component: assignReassignAuditTrail
    },
    {
        path: '/statusaudittrail',
        name: 'statusAuditTrail',
         beforeEnter: requireAuth,
        component: statusAuditTrail
    },
    {
        path: '/generalaudittrail',
        name: 'generalAuditTrail',
         beforeEnter: requireAuth,
        component: generalAuditTrail
    },
    {
        path: '/auditTrail',
        name: 'auditTrail',
         beforeEnter: requireAuth,
        component: auditTrail
    },
    {
        path: '/masteraudittrail',
        name: 'masterAuditTrail',
         beforeEnter: requireAuth,
        component: masterAuditTrail
    },
    {
        path: '/workitemAssignment',
        name: 'workitemAssignment',
         beforeEnter: requireAuth,
        component: workitemAssignment
    },
    {
      path: '/searchPolicyDetails',
      name: 'SearchPolicyDetails',
       beforeEnter: requireAuth,
      component: SearchPolicyDetails
    },
    {
      path: '/searchPolicyDetails',
      name: 'SearchPolicyDetails',
       beforeEnter: requireAuth,
      component: SearchPolicyDetails
    },

    {
      path: '/claimsProviderSearch',
      name: 'ClaimsProviderSearch',
       beforeEnter: requireAuth,
      component: ClaimsProviderSearch
    },
    {
        path: '/failedtransactions',
        name: 'FailedTransactions',
        component: failedTransactionsDashboard
    },
    {
      path: '/resetretrycount',
      name: 'ResetRetryCount',
      component: resetRetryCount
    },
    // By Travel Team
    {
      path: '/search-litigation',
      name: 'searchLitigation',
      component: searchLitigation
    },

    {
      path: '/litigation',
      name: 'createLitigation',
      component: createLitigation
    },

    {
      path: '/litigation-details',
      name: 'litigationDetails',
      component: litigationDetails
    },
    {
      path: '/master',
      name: 'Master',
      component: Master
    },
    {
      path: '/master-approval',
      name: 'MasterApproval',
      component: MasterApproval
    },
    {
      path: '/master/frequent-payee',
      name: 'FrequentPayee',
      component: FrequentPayee
    },
    {
      path: '/master/search-frequent-payee',
      name: 'SearchFrequentPayee',
      component: SearchFrequentPayee
    },
    {
      path: "/recovery",
      name: "Recovery",
      component: paymentRecovery,
    },
    {
      path: "/search-recovery",
      name: "RecoverySearch",
      component: RecoverySearch,
    },
    {
      path: '/master-approval/frequent-payee',
      name: 'FrequentPayeeApproval',
      component: FrequentPayeeApproval
    },
    {
      path: '/master-approval/search-frequent-payee',
      name: 'SearchFrequentPayeeApproval',
      component: SearchFrequentPayeeApproval
    },
    // Court Master
    {
      path: "/master/search-court",
      name: "SearchCourtMaster",
      component: SearchCourtMaster,
    },
    {
      path: "/master/create-court",
      name: "createCourtMaster",
      component: CreateCourtMaster,
    },
    {
      path: "/master-approval/court",
      name: "CreateCourtApprovals",
      component: CreateCourtApprovals,
    },
    {
      path: "/master-approval/search-court",
      name: "SearchCourtApprovals",
      component: SearchCourtApprovals,
    },
    //GroundOfDefence
    {
      path: "/master/search-ground-of-defence",
      name: "SearchGroundOfDefenceMaster",
      component: SearchGroundOfDefenceMaster,
    },
    {
      path: "/master/create-ground-of-defence",
      name: "createGroundOfDefenceMaster",
      component: CreateGroundOfDefenceMaster,
    },
    //Police Station Master
    {
      path: "/master/search-police-station",
      name: "SearchPoliceStationMaster",
      component: SearchPoliceStationMaster,
    },
    {
      path: "/master/create-police-station",
      name: "CreatePoliceStationMaster",
      component: CreatePoliceStationMaster,
    },
    // SIU QC2 Configurator Master
    {
      path: "/master/search-siu-qc2-configurator-master",
      name: "SearchSiuQc2ConfiguratorMaster",
      component: SearchSiuQc2ConfiguratorMaster,
    },
    {
      path: "/master/create-siu-qc2-configurator",
      name: "CreateSiuQc2ConfiguratorMaster",
      component: CreateSiuQc2ConfiguratorMaster,
    },
    // SIU Document Checklist Master
    {
      path: "/master/search-siu-document-checklist-master",
      name: "SearchSiuDocumentChecklistMaster",
      component: SearchSiuDocumentChecklistMaster,
    },
    {
      path: "/master/create-siu-document-checklist",
      name: "CreateSiuDocumentChecklistMaster",
      component: CreateSiuDocumentChecklistMaster,
    },
    //Salvage Buyer Master
    { path:"/master/search-salvage-buyer",
      name:"SearchSalvageBuyerMaster",
      component: SearchSalvageBuyerMaster,
    },
    { path:"/create-salvage-buyer",
      name:"CreateSalvageBuyerMaster",
      component: CreateSalvageBuyerMaster,
    },
    //Service Provider Master
    { path:"/master/search-service-provider",
      name:"SearchServiceProvider",
      component: SearchServiceProvider,
    },
    { path:"/master/create-service-provider",
      name:"CreateServiceProvider",
      component: CreateServiceProvider,
    },
    { path:"/master-approval/search-service-provider",
      name:"SearchServiceProviderApprove",
      component: SearchServiceProviderApprove,
    },
    { path:"/master-approval/create-service-provider",
      name:"CreateServiceProviderApprove",
      component: CreateServiceProviderApprove,
    },
    { path:"/master/search-aig",
      name:"SearchAIGMaster",
      component: SearchAIGMaster,
    },
    { path:"/master/create-aig",
      name:"CreateAIGMaster",
      component: CreateAIGMaster,
    },
    { path:"/master/search-catastrophe",
      name:"SearchCatastropheMaster",
      component: SearchCatastropheMaster,
    },
    { path:"/master/create-catastrophe",
      name:"CreateCatastropheMaster",
      component: CreateCatastropheMaster,
    },
    { path:"/common-communication",
      name:"SearchCommonCommunication",
      component: SearchCommonCommunication,
    },
    { path:"/common-communication/search",
      name:"SearchCommunication",
      component: SearchCommunication,
    },
    { path:"/master/search-vendor",
      name:"SearchVendorMaster",
      component: SearchVendorMaster,
    },
    { path:"/master/create-vendor",
    name:"CreateVendorMaster",
    component: CreateVendorMaster,
  },
    //Vendor Master Approval
    {
      path:"/master-approval/search-vendor",
      name:"SearchVendorApproval",
      component: SearchVendorApproval,
    },
    {
      path:"/master-approval/vendor-Approval",
      name:"VendorApproval",
      component: VendorApproval,
    },
    { path:"/master-approval/search-aig",
      name:"SearchAigApprove",
      component: SearchAigApprove,
    },
    { path:"/master/search-vendor",
      name:"SearchVendorMaster",
      component: SearchVendorMaster,
    },
    { 
      path:"/master/create-vendor",
      name:"CreateVendorMaster",
      component: CreateVendorMaster,
    },
    //Vendor Master Approval
    {
      path:"/master-approval/search-vendor",
      name:"SearchVendorApproval",
      component: SearchVendorApproval,
    },
    {
      path:"/master-approval/vendor-Approval",
      name:"VendorApproval",
      component: VendorApproval,
    },
    {
      path:"/master/search-gst",
      name:"SearchGSTMaster",
      component: SearchGSTMaster,
    },
    {
      path:"/master/create-gst",
      name:"CreateGSTMaster",
      component: CreateGSTMaster,
    },   

    {
        path: '/master/search-repairer',
        name: 'SearchRepairer',
        component: SearchRepairer,
    },
    {
      path: '/createrepairer',
      name: 'createRepairer',
      component: CreateRepairer,
  },
  {
    path: '/create-child-repairer',
    name: 'CreateChildRepairer',
    component: CreateChildRepairer,
},
{
  path: '/parentwithchildrepairer',
  name: 'ParentWithChildRepairer',
   
  component: ParentWithChildRepairer,
},
{
  path: '/childwithparentrepairer',
  name: 'ChildWithParentRepairer',
   
  component: ChildWithParentRepairer,
},
{
  path: '/repairerapprovalsearch',
  name: 'repairerApproval',
   
  component: repairerApproval,
},
{
  path: '/tds-master',
  name: 'TDSMaster',
  component: TDSMaster,
},
{
  path: '/tds-master/base-tds-master',
  name: 'BaseTDSMaster',
  component: BaseTDSMaster,
},
{
  path: '/tds-master/base-tds-master-bulk',
  name: 'BaseTDSMasterBulk',
  component: BaseTDSMasterBulk,
},
{
  path: '/tds-master/penal-tds-master',
  name: 'PenalTDSMaster',
  component: PenalTDSMaster,
},
{
  path: '/tds-master/concessional-tds-master',
  name: 'ConcessionalAndPenalTDSMaster',
  component: ConcessionalTDSMaster,
},
{
  path: '/tds-master/concessional-tds-master-bulk',
  name: 'ConcessionalAndPenalTDSMasterBulk',
  component: ConcessionalTDSMasterBulk
},
{
  path: '/work-items',
  name: 'WorkItems',
  component: WorkItems
},
{
  path: '/tds-master-approval',
  name: 'TDSMasterApproval',
  component: TDSMasterApproval,
},
{
  path: '/tds-master-approval/base-rate',
  name: 'BaseRateTDS',
  component: BaseRateTDS
},
{
  path: '/tds-master-approval/penal-rate',
  name: 'PenalRateTDS',
  component: PenalRateTDS
},
{
  path: '/tds-master-approval/concessional-penal',
  name: 'ConcessionalPenal',
  component: ConcessionalPenal 
},
{
  path: '/tds-master-approval/bulk-upload/:id',
  name: 'BulkUpload',
  component: BulkUpload 
},

  // {
  //   path: '/jt',
  //   name: 'jt',
  // }

]

const router = new Router({
  mode:'history',
  routes: routers_are
});


// console.log('from route js ', router)


export default router