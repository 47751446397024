<template>
    <v-app>
      <v-container class="containerStyle"
          style="padding:1.5rem; margin:0; max-width:100%">
        <v-layout>
            <h3>Search Frequent Payee Master Approval</h3>
            <!-- <v-spacer></v-spacer>
            <v-btn
            class="btnToggle"
          outlined
          elevation="0"
          @click="$router.push({name:'CreateFrequency'})"
            >
                <v-icon>mdi-plus-box-outline</v-icon>
                <v-divider  ></v-divider>
                Create Master
            </v-btn> -->
            
        </v-layout>
        <v-layout class="mt-5" wrap>
          <v-flex class="form-group">
          <label class="form-label" >
            LOB <span class="mandatorycolor">*</span>
          </label>
          <v-select
              v-model="lob"
              :items="lobOptions"
              multiple
              solo
              dense
              class="form-control"
              placeholder="Select"
          ></v-select>
        </v-flex>
        <v-flex class="form-group">
          <label class="form-label">
            Payee Name
          </label>
          <v-text-field
            solo
            dense
            v-model="payeeName"
            class="form-control"
            placeholder="Enter..."
            >
            </v-text-field>
          <!-- <v-autocomplete
          :search-input.sync="payeeNameSmart"
          v-model="payeeName"
          solo
          :items="payeeNameSearch"
          item-text="payeeName"
          dense
          maxlength="138"
          :rules="[(v)=>matchRegex(v,'alpha')]"
          class="form-control"
          placeholder="Enter..."
          @keyup="smartSearch(payeeNameSmart,'payeeName')"
          ></v-autocomplete> -->
        </v-flex>
    
            <v-flex class="form-group">
              <label class="form-label">
                Payee Type
              </label>
              <v-select
              :items="Payee_items"
              v-model="payeeType"
              solo
              dense
              class="form-control"
              placeholder="Select"
              ></v-select>
            </v-flex>
            <v-flex class="form-group">
          <label class="form-label">
          PAN Number
          </label>
          <!-- <v-text-field
            solo
            dense
            v-model="panNumber"
            :rules="[(v) => validatePanNumber(v)]"
            class="form-control"
            placeholder="Enter..."
            >
            </v-text-field> -->
          <v-autocomplete
          @keyup="smartSearch(panNumberSmart,'panNumber')"
          :search-input.sync="panNumberSmart"
          v-model="panNumber"
          :items="panNumberSearch"
          item-text="panNumber"
          solo
          :value="computedPAN"
          dense
          :rules="[(v) => validatePanNumber(v)]"
          class="form-control"
          placeholder="Enter..."
          ></v-autocomplete>
        </v-flex>
        <v-flex class="form-group">
          <label class="form-label">
            Aadhaar Number
          </label>
          <!-- <v-text-field
            solo
            dense
            v-model="aadharId"
            :rules="[ (v) => matchRegex(v, 'nums'), (v) => validateLength(v, { exact: 4 }, '', 'Field requires 4 digits.')]"
            class="form-control"
            placeholder="Enter..."
            >
            </v-text-field> -->
          <v-autocomplete
          @keyup="smartSearch(aadharNumber,'AadharNumber')"
          :search-input.sync="aadharNumber"
          v-model="aadharId"
          item-text="aadharId"
          solo
          :items="aadharNumberSearch"
          dense
          :rules="[ (v) => matchRegex(v, 'nums'), (v) => validateLength(v, { exact: 4 }, '', 'Field requires 4 digits.')]"
          class="form-control"
          placeholder="Enter..."
          ></v-autocomplete>
        </v-flex>
        <v-flex class="form-group">
          <label class="form-label">Master entry status</label>
          <v-select class="form-control" solo dense placeholder="Select" :items="master_status" v-model="approveStatus"></v-select>
        </v-flex>
        <v-flex class="form-group">
          <label class="form-label">
              Status 
          </label>
            <v-select
                v-model="status"
                label="Select"
                :items="[{text:'Active',value:true},{text:'InActive',value:false}]"
                solo
                dense
                class="form-control"
            ></v-select>
        </v-flex>
        <v-spacer></v-spacer>
        <v-flex d-flex justify-end>
          <v-btn class="mt-2 mr-5 btn" outlined color="red" @click="onReset(true)"><v-icon> mdi-backspace </v-icon>
            <v-divider vertical class="mx-2"></v-divider>
            Reset</v-btn>
            <v-btn class="mt-2 btn" color="teal white--text" dense solo @click="searchFrequency"><v-icon>mdi-arrow-right-circle</v-icon>
              <v-divider vertical class="mx-2"></v-divider>
              Search</v-btn>
        </v-flex>
        </v-layout>
        <v-layout class="mb-4">
          <h4 class="mr-3"><strong>Frequent Payee Master List <span class="orange--text">({{count}})</span></strong></h4>
      <v-chip small color="#1EBBD9" text-color="white" label close
                     v-if="showSearchChip==true"
                    @click:close="chipsearchFrequency">Search</v-chip><v-spacer></v-spacer> 
                    <!-- <v-btn depressed
                    color="#E46A25"
                    class="white--text mr-3"><v-icon>
                        mdi-file-excel
                    </v-icon>
                    <v-divider class="mx-4"
                    vertical
                    color="white"></v-divider>Export to Excel</v-btn> -->
                  </v-layout>
        <v-data-table
        :items="frequency_Table_Data"
        :headers="frequence_Header"
        :items-per-page="10"
        hide-default-footer
        class="vDataTableStyling">
        <template v-slot:[`item.actions`]="{ item }">
                          <v-menu offset-y open-on-hover>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                elevation=""
                                icon
                                v-bind="attrs"
                                v-on="on"
                                style="text-align:center"
                              >
                                <v-icon>mdi mdi-dots-vertical</v-icon>
                              </v-btn>
                            </template>
                            
                            <v-list class="item-actions" >
                             <template >
                              <v-list-item
                               v-for="(actions, index) in actionOptions"
                                :key="index"
                                @click="editmaster(actions.link, index, item, item._id, actions.name)"
                                
                              >
                              <!-- :disabled="actions.name === 'MODIFY' && !['', 'Saved'].includes(item.paymentStatus)" -->
                                <v-list-item-title :style="actions.color"
                                  ><v-icon small :style="actions.iconColor">{{ actions.icon }}</v-icon>
                                   {{ actions.name }}</v-list-item-title
                                >
                              </v-list-item>
                              </template>
                            </v-list>
                            
                          </v-menu>
                        </template>

                        <template v-slot:[`item.active`]="{ item }">
                      {{ item.active ? 'Active' : 'Inactive' }}
                    </template>
                        <!-- <template v-slot:[`item.approveStatus`]="{ item }">
                          {{item.approveStatus==='Pending for approval'? 'Pending':""}}
                        </template> -->
      </v-data-table>
      <v-layout  class="pb-2 px-0 mr-4 mt-5">
        <v-btn outlined color="red" class="btn" @click="cancel">
          <v-icon> mdi-backspace </v-icon>
          <v-divider vertical class="mx-2"></v-divider>
          Cancel
        </v-btn>
        <v-spacer></v-spacer>
        <div >
                    <span style="color:orange">Showing {{ pageStartIndex_master }}-{{ pageEndIndex_master() }}</span>
                    out of {{ totalListCount_master }} records
                  </div>
                  <div>
                    <v-pagination class="small-pagination" v-model="page_master" :length="pageCount_master" :total-visible="5" @input="searchFrequency" color="#152F38"></v-pagination>
                  </div>
                </v-layout>
                <!-- <v-row>
    <v-col>
     
    </v-col>
  </v-row> -->

<!-- For Audit Trail -->
<!-- <v-dialog v-model="audit_dialog" width="800">
      <v-card>
        <v-card-title class="white--text" style="background-color: #23b1a9">
          Audit Trail
          <v-spacer></v-spacer>
          <v-icon @click="audit_dialog = !audit_dialog" class="white--text"
            >mdi-window-close</v-icon
          >
        </v-card-title>
        <v-layout class="pt-4 form-group-container pr-4">
          <v-data-table
            :headers="audit_Header"
            hide-default-footer
            :items="audit_Data"
            class="vDataTableStyling w-100"
          >
            <template v-slot:[`item.srno`]="{ index }">
              {{ 1 + index }}
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-btn
                v-if="item.action === 'Modified'"
                color="#FFFFFF"
                class="text-capitalize tablebtn"
                @click="seeModify(item)"
              >
                {{ item.action }}
                <v-icon class="tableicons">mdi-arrow-right-circle</v-icon>
              </v-btn>
              {{ item.action !== "Modified" ? item.action : null }}
            </template>
            <template v-slot:[`item.createdAt`]="{ item }">
              {{ formatDateTime(item.createdAt) }}
            </template>
          </v-data-table>
        </v-layout>
        <v-layout justify-end align-center class="pb-2 px-0 mr-4">
          <div>
            <span style="color: orange"
              >Showing {{ AuditPageStartIndex }}-{{ AuditPageEndIndex }}</span
            >
            out of {{ AuditTotalListCount }} records
          </div>
          <div>
            <v-pagination
              class="small-pagination"
              v-model="AuditPage"
              :length="AuditPageCount"
              :total-visible="5"
              @input="getAuditData(AuditPage, '', true)"
              color="#152F38"
            ></v-pagination>
          </div>
        </v-layout>
      </v-card>
    </v-dialog> -->

      <!-- <v-dialog v-model="audit_dialog" width="1000">
        <v-card>
          <v-card-title
                        class="white--text"
                        style="background-color: #23b1a9"
                      >
                    Audit Trail
                    <v-spacer></v-spacer>
            <v-icon @click="audit_dialog=!audit_dialog" class="white--text">mdi-window-close</v-icon>
          </v-card-title>
          <v-layout class="pt-4 form-group-container">
                <v-data-table
                :headers="audit_Header"
                hide-default-footer
                :items="audit_Data"
                class="vDataTableStyling"
                >
                <template v-slot:[`item.time`]="{ item }">
                {{formatTime(item.updatedAt)}}
              </template>
              <template v-slot:[`item.updatedAt`]="{ item }">
                {{formatDate(item.updatedAt)}}
              </template>
                </v-data-table>
              </v-layout>
              <v-layout justify-end align-center class="pb-2 px-0 mr-4">
                  <div>
                    <span style="color:orange">Showing {{ pageStartIndex }}-{{ pageEndIndex }}</span>
                    out of {{ totalListCount }} records
                  </div>
                  <div>
                    <v-pagination class="small-pagination" v-model="page" :length="pageCount" :total-visible="5" @input="getAuditData(page,'',true)" color="#152F38"></v-pagination>
                  </div>
                </v-layout>
        </v-card>
        
        </v-dialog> -->
        <!-- Modify dialogue -->
        <!-- <v-dialog v-model="seeModifyDialog" width="600">
          <v-card>
            <v-card-title class="white--text"
                        style="background-color: #23b1a9">
              <v-layout justify-space-between>
                <div>
                  Modified Field Details
                </div>
              </v-layout>
              <v-btn color="transparent" small :ripple="false" depressed @click="seeModifyDialog = false">
                <v-icon color="white">mdi-close </v-icon>
              </v-btn>
            </v-card-title>
            <div class="scrollable-table">
            <v-data-table disable-pagination :headers="modifyHeaders" :items="modifyItems" hide-default-footer class="pa-4">
              <template v-slot:[`item.srNo`]="{ index }">
                {{ 1 + index }}
              </template>
            </v-data-table>
            </div>
          </v-card>
        </v-dialog> -->
        <!-- Modify dialogue -->
        <!-- Approve and Reject Dialog -->
        <v-dialog v-model="reject_Dialog" width="600">
                <v-card>
                    <v-card-title class="white--text" style="background-color: #23b1a9"
                    ><v-layout style="display:flex;justify-content:center;margin-left: 170px;" >Reject
                       </v-layout>
                       <v-layout style="display:flex;justify-content:end;">
                        <v-icon class="white--text " @click="reject_Dialog=!reject_Dialog;reason=''">mdi-close</v-icon>
                      </v-layout>
                      </v-card-title>
                      <v-form ref="rejectForm" @submit="false">
                        <v-layout class="pt-4 form-group-container" >
                           
                            <v-flex class="mr-2">
                                <label class="form-label">Reason <span><strong class="red--text">*</strong></span></label>
                                <v-text-field  class="form-control" solo dense :rules="reject_Dialog ? [(v)=>requiredField(v)]:[]" v-model="reason" placeholder="Enter..."></v-text-field>
                            </v-flex>
                        </v-layout>
                      </v-form>
                        <v-card-actions style="display:flex;justify-content:center">
                          <v-btn class="btn" color="red white--text mr-0" @click="reject_Dialog=!reject_Dialog;reason=''">
                            No
                        </v-btn>

                        <v-btn class="btn" color="green white--text mr-0" @click="onSubmit">
                            Yes
                        </v-btn>
                      </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="approve_Dialog" width="600">
                <v-card>
                    <v-card-title class="white--text" style="background-color: #23b1a9"
                    ><v-layout style="display:flex;justify-content:center;margin-left: 170px;" >Approve
                       </v-layout>
                       <v-layout style="display:flex;justify-content:end;">
                        <v-icon class="white--text " @click="approve_Dialog=!approve_Dialog;reason=''">mdi-close</v-icon>
                      </v-layout>
                      </v-card-title>
                        <v-layout class="pt-4 form-group-container" justify-center>
                           
                            <h4>Are you sure want to Approve ?</h4>
                        </v-layout>
                        <v-card-actions style="display:flex;justify-content:center">
                          <v-btn class="btn" color="red white--text mr-0" @click="approve_Dialog=!approve_Dialog;reason=''">
                            No
                        </v-btn>

                        <v-btn class="btn" color="green white--text mr-0" @click="onSubmit">
                            Yes
                        </v-btn>
                      </v-card-actions>
                </v-card>
            </v-dialog>
          <!-- Audit Trail -->
            <audit-trail
              :dialogState="OpenAuditDialog"
              :auditTrailMaster="'frequentPayee'"
              :auditTrailItem="{ uid: auditTrailId }"
              @close-dialog="OpenAuditDialog = false"
            />
      </v-container>
    </v-app>
    
    </template>
    <script>
    import moment from 'moment'
    import auditTrail from '../../../../component/MasterAuditTrail.vue'
    export default {
      components:{
        auditTrail
      },
      data(){
        return{
          validationmessage: "This field is required",
          lobOptions: [],
          frequency_Table_Data:[],
          Payee_items:['Insured','Claimant','Others'],
          reject_Dialog:false,
          approve_Dialog:false,
          id:"",
          reason:"",
          actionOptions: [
            {
              name: "VIEW",
              icon: "mdi-eye-outline",
              // link: "/childrenEducationCoverAddAssessment",
              color: "color:#23b1a9",
              iconColor: "color:#10242B",
            },
            // {
            //   name: "Approve",
            //   icon: "mdi-check-circle-outline",
            //   // link: "/childrenEducationCoverAddAssessment",
            //   color: "color:#23b1a9",
            //   iconColor: "color:green",
            // },
            // {
            //   name: "Reject",
            //   icon: "mdi-cancel",
            //   // link: "/childrenEducationCoverAddAssessment",
            //   color: "color:#23b1a9",
            //   iconColor: "color:red",
            // },
            {
              name: "AUDIT TRAIL",
              icon: "mdi-chart-timeline-variant",
              // link: "/modifypage",
              color: "color:orange",
              iconColor: "color:orange",
            },
          ],
          audit_Header: [
        { text: "Sr. No.", value: "srno", sortable: false, align: "center" },
        {
          text: "Master ID",
          value: "masterId",
          sortable: false,
          align: "center",
        },
        {
          text: "Action Taken",
          value: "action",
          sortable: false,
          align: "center",
        },
        { text: "User ID", value: "userId", sortable: false, align: "center" },
        {
          text: "User Name",
          value: "userName",
          sortable: false,
          align: "center",
        },
        {
          text: "Time Stamp",
          value: "createdAt",
          sortable: false,
          align: "center",
        },
      ],
        modifyHeaders: [
            {
              text: "Sr. No.",
              value: "srNo",
              align: "center",
              sortable: false,
            },
            {
              text: "Field Name",
              value: "fieldName",
              align: "center",
              sortable: false,
            },
            {
              text: "Old Value",
              value: "oldValue",
              align: "center",
              sortable: false,
            },
            {
              text: "New Value",
              value: "newValue",
              align: "center",
              sortable: false,
            },
          ],
          master_status:['Approved','Rejected','Pending for approval'],
        aadharNumber:'',
        panNumberSmart:'',
        payeeNameSmart:'',
        aadharNumberSearch:[],
        payeeNameSearch:[],
        panNumberSearch:[],
    
          frequence_Header:[{text:"Action",value:"actions",sortable: false,align: "center"},
      {text:"Frequent Id",value:"operationData[0].frequentPayeeId",width: "150px",sortable: false,align: "center"},
      {text:"LOB",value:"operationData[0].lob", sortable: false, align: "center", width: "100px"},
      {text:"Payee Name",value:"operationData[0].payeeName",width: "150px",sortable: false,align: "center"},
      {text:"Contact",value:"operationData[0].contactPerson.number",sortable: false,align: "center"},
      {text:"City",value:"operationData[0].city",sortable: false,align: "center"},
      {text:"Pincode",value:"operationData[0].pinCode",sortable: false,align: "center"},
      {text:"GSTIN No.",value:"operationData[0].gstinNumber",sortable: false,align: "center"},
      {text:"PAN No",value:"operationData[0].panNumber",sortable: false,align: "center"},
      {text:"Bank Name",value:"operationData[0].bank.bankName",width: "150px",sortable: false,align: "center"},
      {text:"Bank Account No.",value:"operationData[0].bank.accountNumber",width: "170px",sortable: false,align: "center"},
      {text:"IFCS Code",value:"operationData[0].bank.ifscCode",sortable: false,align: "center"},
      {text:"payee Type",value:"operationData[0].payeeType",width: "150px",sortable: false,align: "center"},
      {text:"Address",value:"operationData[0].addressLine1",sortable: false,align: "center",width: "150px"},
      {text:"Aadhaar Number",value:"operationData[0].aadharId",width: "150px",sortable: false,align: "center"},
      {text:"Email Id",value:"operationData[0].emails.id1",sortable: false,align: "center"},
      {text:"Active Status",value:"active",sortable: false,align: "center"},
      {text:"Approval Status",value:"approveStatus",width: "170px",sortable: false,align: "center"},
      {text:"Initiated By",value:"operationData[0].createdBy", sortable: false, align: "center",},
      {text:"Date and Time",value:"createdAt", sortable: false, align: "center",}],
        // value:"operationData[0].payeeName"
          lob:"",
          payeeName:"",
          panNumber:"",
          payeeType:"",
          aadharId:"",
          audit_dialog:false,
          audit_Data:[],
          seeModifyDialog:false,
          modifyItems: [],
          itemsPerPage: 10,
          totalListCount: 0,
          pageStartIndex: 1,
          pageCount: 0,
          page: 1,
          showSearchChip:false,
            count:0,
          itemsPerPage_master: 10,
          totalListCount_master: 0,
          pageStartIndex_master: 1,
          pageCount_master: 0,
          page_master: 1,
      AuditPage: 1,
      AuditTotalListCount: 0,
      AuditPageCount: 0,
      AuditPageStartIndex: 0,
      AuditItemsPerPage: 10,
      approveStatus: "",
      status:"",
            OpenAuditDialog: false,
            auditTrailId: null,
        }
      },
      methods:{

        formatDateTime(dateTime) {
        return dateTime ? moment(dateTime).format("DD-MM-YYYY LT") : "";
      },

        cancel(data) {
    this.showConfirm(
      "Confirmation",
      "Are you sure you want to leave?",
      "Yes",
      "No",
      (Yes) => {
        this.$router.push({ name: "MasterApproval" }); //params:{pageNo:this.pageNo}}
      }
    );
  },
  onReset(data=false){
      if(data){
        this.showConfirm("Confirmation","Are you sure you want to Reset?","Yes","No",(yes)=>{
        this.lob="",
      this.payeeName="",
      this.panNumber="",
      this.payeeType="",
      this.aadharId=""
      this.approveStatus=''
      this.status=""
      this.aadharNumber='',
      this.panNumberSmart='',
      this.payeeNameSmart='',
      this.aadharNumberSearch=[],
      this.payeeNameSearch=[],
      this.panNumberSearch=[]
      })
      }else{this.lob=""
      this.payeeName=""
      this.panNumber=""
      this.payeeType=""
      this.aadharId=""
      this.approveStatus=''
      this.status="",
      this.aadharNumber='',
      this.panNumberSmart='',
      this.payeeNameSmart='',
      this.aadharNumberSearch=[],
      this.payeeNameSearch=[],
      this.panNumberSearch=[]}
      
      
    },
    smartSearch(value,fieldName){
      
      if(value.length>2&&fieldName=='AadharNumber'){
        this.aadharNumberSearch=this.frequency_Table_Data
      }else{
        this.aadharNumberSearch=[]
      }
       if(value.length>2&&fieldName=='panNumber'){
        this.panNumberSearch=this.frequency_Table_Data
       }else{
        this.panNumberSearch=[]
       }
       if(value.length>2&&fieldName=='payeeName'){
        this.payeeNameSearch=this.frequency_Table_Data
       }else{
        this.payeeNameSearch=[]
       }
    },
        chipsearchFrequency(){
          let newPageNum=1
          this.itemsPerPage_master=0
          this.showSearchChip=false
          let reqData=`pageNo=1&perPage=10&approveStatus=Pending for approval&modelName=frequentPayee`
          this.GETM("searchFrequentApprove",reqData,(res)=>{
            this.onReset()
            if(res.data?.data?.data?.length>0){
              this.frequency_Table_Data= res.data.data.data.map((item) => ({
                ...item,
                createdAt: this.formatDateTime(item.createdAt),
              })) || []
              this.totalListCount_master = res.data.data.total;
              this.count=res.data.data.total
              this.pageCount_master = res.data.data.total_pages;
              this.pageStartIndex_master =newPageNum == 1 ? 1 : (newPageNum - 1) *  this.itemsPerPage_master + 1;
            }else{
              this.frequency_Table_Data = res.data?.data || []
              this.count = res.data?.data?.total || 0;
              this.totalListCount_master = res.data.data?.total || 0;
              this.pageCount_master = res.data?.data?.total_pages || 0;
              this.pageStartIndex_master = 0
            }
            
    },(error) => {
      this.pageStartIndex_master=0
          return error;
        },true)
    },
        searchFrequency(){
          let newPageNum=1
          this.itemsPerPage_master=0
          
          this.showSearchChip=true
          // let reqData=`lob=${this.lob}&payeeName=${this.payeeName}&payeeType=${this.payeeType}
          // &panNumber=${this.panNumber}&aadharId=${this.aadharId}&pageNo=${this.page_master || 1}
          // &perPage=10&approveStatus=${this.approveStatus || "Pending for approval"}&modelName=frequentPayee`
        
          let reqData = `&pageNo=${this.page_master || 1} &perPage=10&approveStatus=${this.approveStatus || "Pending for approval"}&modelName=frequentPayee`
        
          if(this.lob || this.payeeName || this.payeeType || this.panNumber || 
          this.aadharId || this.status === true || this.status === false){
           if(this.lob){
            reqData = reqData + "&lob=" + this.lob
           }
           if(this.payeeName){
            reqData = reqData + "&payeeName=" + this.payeeName
           }
           if(this.payeeType){
            reqData = reqData + "&payeeType=" + this.payeeType
           }
           if(this.panNumber){
            reqData = reqData + "&panNumber=" + this.panNumber
           }
           if(this.aadharId){
            reqData = reqData + "&aadharId=" + this.aadharId
           }
           if(this.status){
            reqData = reqData + "&status=" + this.status
           }else if(this.status === false){
            reqData = reqData + "&status=" + this.status
           }
          }else{
          reqData = `&pageNo=${this.page_master || 1} &perPage=10&approveStatus=${this.approveStatus || "Pending for approval"}&modelName=frequentPayee`

          }

          this.GETM("searchFrequentApprove",reqData,(res)=>{
          if(res.data?.statusCode === 200) {
            this.frequency_Table_Data= res.data.data.data.map((item) => ({
                ...item,
                createdAt: this.formatDateTime(item.createdAt),
              }));
            this.totalListCount_master = res.data.data.total;
            this.count=res.data.data.total
                  this.pageCount_master = res.data.data.total_pages;
                  this.pageStartIndex_master = newPageNum == 1 ? 1 : (newPageNum - 1) * this.itemsPerPage_master + 1;
                  // newPageNum == 1 ? 1 : (newPageNum - 1) * 
          } else {
          // this.showAlert(res.data?.msg || "Record not found")
          this.frequency_Table_Data = res.data?.data || []
          this.count = res.data?.data?.total || 0;
          this.totalListCount_master = res.data.data?.total || 0;
          this.pageCount_master = res.data?.data?.total_pages || 0;
          this.pageStartIndex_master = 0
        }
        },(error) => {
              return error;
            },true)
           
      },
      formatDate(dateTime) {
      return dateTime ? moment(dateTime).format("DD-MM-YYYY ") : "";
    },
    formatTime(dateTime) {
      return dateTime ? moment(dateTime).format("LT") : "";
    },
       seeModify(item) {
          console.log(item.modification)
          this.seeModifyDialog = true;
           this.modifyItems = item.modification
          //  .map((m, index) => {
          //    let object  = { ...m, srNo: index + 1 };
          //    if(this.dateFields.includes(m.fieldName)){
          //      object.newValue = this.formatDateOnly(m.newValue)
          //      object.oldValue = this.formatDateOnly(m.oldValue)
          //    }
          //    return object;
          //  });
        },
      editmaster(link, index, item, id, name,a) {
         if(index == 0){
          this.$router.push({
            name: "FrequentPayeeApproval",
            params: {
              isView: true,
              item: item,
            },
          });
         }
        //  if(index == 1){
        //   this.approve_Dialog=true
        // this.id=id}
        //  if(index == 2){
        //   this.reject_Dialog=true
        //   this.id=id
        //  }
         if(index == 1){
          this.OpenAuditDialog = true
          this.auditTrailId = item.operationData[0].frequentPayeeId
          // this.audit_dialog=true
          // this.getAuditData(1,item.operationData[0].frequentPayeeId,false)
         }
         console.log(a,name,id,link)
        },
      //   getAuditData(newPageNum,id,bool){
      //     let reqData =""
      // if(bool){
      //   reqData = "perPage=10" + "&pageNo=" + newPageNum + "&masterType=frequentPayee"+"&masterId=" +this.audit_Data[0].masterId
      // }else{
      //   reqData = "perPage=10" + "&pageNo=" + newPageNum + "&masterType=frequentPayee"+"&masterId=" +id
      // }
      //     //"&masterId=" + this.auditTrailItem.uid + 
      //     this.GETM('getAudits',reqData,(res)=>{
      //       this.audit_Data=res.data.data.data
      //       this.AuditTotalListCount = res.data.data.total;
      //           this.AuditPageCount = res.data.data.total_pages;
      //           this.AuditPageStartIndex = newPageNum == 1 ? 1 : (newPageNum - 1) * this.AuditItemsPerPage + 1;
      //     })
      //   },
        pageEndIndex_master() {
          if (!this.frequency_Table_Data.length) {
            return 0
            
          } else {
            return this.pageStartIndex_master + this.itemsPerPage_master <= this.totalListCount_master ? this.pageStartIndex_master + this.itemsPerPage_master - 1 : this.totalListCount_master;
          }
        },
  
        getLOB() {
            let reqData = '';
            this.showProgress();
            this.GETM("getLOB", reqData, (res) => {
                this.hideProgress();
                if(res.data.statusCode === 200) {
                    this.lobOptions = res.data.data[0].lineOfBusiness;
                } else {
                    this.lobOptions = [];
                    this.showAlert(res.data.msg);
                }
            }, (error) => {
                console.log(error);
                this.hideProgress();
            }, true)
        },
        onSubmit(){
    
        if(this.id){
          if(this.reject_Dialog){
            if(!this.$refs?.rejectForm?.validate()){
              return this.showAlert("Please fill mandatory fields.");
            }
          }

          let sendData={approveStatus:this.reject_Dialog ? "Rejected" : "Approved",
          rejectionReason:this.reason}
          // this.frequentDetails.approveStatus=this.reject_Dialog ? "Rejected" : "Approved"
          // this.frequentDetails.rejectionReason=this.reason
          // this.frequencyPayee.approveStatus=this.reject_Dialog ? "Rejected" : "Approved"
          
        this.PUTM(
          "updateMasterApprove",this.id,
          sendData,
          (res) => {
            this.hideProgress();
            if (res.data && res.data.statusCode == 200) {
              this.showAlert(res?.data?.msg || "Success", "", "", () => {
                
                // this.onReset();
                // this.cancel(true)
                // this.$router.push('/Claim-processing/Child-Eduction/add-assesment-sheet');
                      this.approve_Dialog=false
                      this.reject_Dialog=false  
                      this.chipsearchFrequency()        
              });
              // this.isView=true
            } else {
              this.showAlert(res.data.msg);
              this.hideProgress();
            }
          },
          (err) => {
            console.log(err);
            this.hideProgress();
            let msg =
              err?.response?.data?.msg || "Unable to submit claim data.";
            this.showAlert(msg);
          },true
        );}
      
  },
    formatDateTime(dateTime) {
      return dateTime ? moment(dateTime).format("DD-MM-YYYY LT") : ""
    },
        },
        computed:{
          pageEndIndex() {
          if (!this.audit_Data.length) {
            return 0;
          } else {
            return this.pageStartIndex + this.itemsPerPage <= this.totalListCount ? this.pageStartIndex + this.itemsPerPage - 1 : this.totalListCount;
          }
        },
        AuditPageEndIndex() {
          if (!this.audit_Data.length) {
            return 0
          } else {
            return this.AuditPageStartIndex + this.AuditItemsPerPage <=
              this.AuditTotalListCount
              ? this.AuditPageStartIndex + this.AuditItemsPerPage - 1
              : this.AuditTotalListCount
          }
        },
        computedPAN() {
            this.panNumber = this.panNumber ? this.panNumber.toUpperCase() : '';
            return this.panNumber;
        },
      },
      
      created(){
        this.$store.commit("SAVE_PAGE_HEADER", "Frequent Payee Master Approval Search");
        this.getLOB();
        let newPageNum=1
        let reqData=`pageNo=1&perPage=10&approveStatus=Pending for approval&modelName=frequentPayee`
        this.GETM("searchFrequentApprove",reqData,(res)=>{
          if(res.data.data.data.length>0){
            this.frequency_Table_Data= res.data.data.data.map((item) => ({
                ...item,
                createdAt: this.formatDateTime(item.createdAt),
              }));
          this.totalListCount_master = res.data.data.total;
          this.count=res.data.data.total
                this.pageCount_master = res.data.data.total_pages;
                this.pageStartIndex_master =newPageNum == 1 ? 1 : (newPageNum - 1) *  this.itemsPerPage_master + 1;
          }else{
            this.pageStartIndex_master=0
          }},(error) => {
            this.pageStartIndex_master=0
              return error;
              
            },true)
      },
    }
    </script>
    
    <style scoped>
    .btnToggle {
      text-transform: none;
      color: #fff;
      background-color: #1ebbd9;
    }
    .btn{
      text-transform: capitalize;
    }
    .tableicons {
      color: #23b1a9 !important;
    }
    .tablebtn {
      background: #ffffff 0% 0% no-repeat padding-box !important;
      box-shadow: inset 0px 2px 4px #00000052 !important;
      border: 0.5px solid #c1c8cc;
      border-radius: 18px !important;
      height: 30px !important;
      text-transform: capitalize !important;
      width: 100%;
      justify-content: space-between;
      font-size: small;
    }
    </style>