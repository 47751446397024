 <template>
    <v-app>
        <v-card  tile>
            <div class="ma-4">
                <v-form ref="netform">
                    <v-layout class="mt-2 mb-2" style="background-color: #FFFFFF;">
                        <v-flex xs3 >
                            <v-layout column class="cust_pr mx-3 ">
                                <p class="font-weight-bold cstm-clr-pr">Network Name</p>
                                <v-text-field
                                    solo
                                    label="Enter..."
                                    v-model="mapNtwrkName"
                                    maxlength="100"
                                    disabled
                                    background-color='#f7f7f7'
                                    class="rounded-0">
                                </v-text-field>
                                <!-- <div class="disable-field-style">
                                    <v-text class="font-weight-bold" style="font-size:13px">{{mapNtwrkName}}</v-text>
                                </div> -->
                            </v-layout>
                        </v-flex>
                        <v-flex xs3 >
                            <v-layout column class="cust_pr mx-3 ">
                                <p class="font-weight-bold cstm-clr-pr">Network ID</p>
                                <v-text-field
                                    solo
                                    background-color='#f7f7f7'
                                    label="Enter..."
                                    v-model="mapNtwrkID"
                                    maxlength="100"
                                    disabled
                                    class="rounded-0">
                                </v-text-field>
                                <!-- <div class="disable-field-style">
                                    <v-text class="font-weight-bold" style="font-size:13px">{{mapNtwrkID}}</v-text>
                                </div> -->
                            </v-layout>
                        </v-flex>
                        
                        <v-flex xs3 >
                            <v-layout column class="cust_pr mx-3 ">
                                <p class="font-weight-bold cstm-clr-pr">Products</p>
                                <v-text-field
                                    solo
                                    label="Enter..."
                                    v-model="mapNtwrkProd"
                                    maxlength="100"
                                    disabled
                                    background-color='#f7f7f7'
                                    class="rounded-0">
                                </v-text-field>
                                <!-- <div class="disable-field-style">
                                    <v-text class="font-weight-bold" style="font-size:13px">{{mapNtwrkProd.toString()}}</v-text>
                                </div> -->
                            </v-layout>
                        </v-flex>
                        <v-flex xs3 >
                            <v-layout column class="cust_pr mx-3 ">
                                <p class="font-weight-bold cstm-clr-pr">Products Benefits</p>
                                <v-text-field
                                    solo
                                    label="Enter..."
                                    v-model="mapNtwrkProdBenif"
                                    maxlength="100"
                                    disabled
                                    background-color='#f7f7f7'
                                    class="rounded-0">
                                </v-text-field>
                                <!-- <div class="disable-field-style">
                                    <v-text class="font-weight-bold" style="font-size:13px">{{mapNtwrkProdBenif}}</v-text>
                                </div> -->
                            </v-layout>
                        </v-flex>
                        <v-flex xs3 >
                            <v-layout column class="cust_pr mx-3 ">
                                <p class="font-weight-bold cstm-clr-pr">Group Policies</p>
                                <v-text-field
                                    solo
                                    label="Enter..."
                                    v-model="mapNtwrGrpPolicy"
                                    maxlength="100"
                                    disabled
                                    background-color='#f7f7f7'
                                    class="rounded-0">
                                </v-text-field>
                                <!-- <div class="disable-field-style">
                                    <v-text class="font-weight-bold" style="font-size:13px">{{mapNtwrGrpPolicy.toString()}}</v-text>
                                </div> -->
                            </v-layout>
                        </v-flex>
                    </v-layout>

                    <v-layout class="mt-4 mb-2" style="background-color: #FFFFFF;">
                        <v-flex xs3 >
                            <v-layout column class="cust_pr mx-3 ">
                                <p class="font-weight-bold cstm-clr-pr">Add Product(s)</p>
                                <v-select
                                    :items="mapAddProdsArray"
                                    label="Select"
                                    v-model="mapAddProducts"
                                    solo
                                    class="rounded-0"
                                    item-text="text"
                                    item-value="value"
                                    :rules="[rules.required]"
                                    multiple
                                    maxlength="30"
                                    return-object
                                    @change="addProdctChange(mapAddProducts)">
                                    <template v-slot:selection="{ item, index }">
                                        <span v-if="index === 0" >{{ item.text }}</span>
                                        <span v-if="index == 1" class="grey--text caption">(+{{ mapAddProducts.length - 1 }})</span>
                                    </template>
                                    <template v-slot:prepend-item>
                                        <v-list-item ripple @click="selectAddProdData()">
                                            <v-list-item-action>
                                                <v-icon :color="mapAddProducts.length > 0 ? 'blue darken-2' : ''">{{ iconProd }}</v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>Select All</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider ></v-divider>
                                    </template>
                                </v-select>
                            </v-layout>
                           
                        </v-flex>
                        
                        <v-flex xs3 >
                            <v-layout column class="cust_pr mx-3 ">
                                <p class="font-weight-bold cstm-clr-pr">Add Product Benefits</p>
                                <v-select
                                    :items="mapAddProdBenftArray"
                                    label="Select"
                                    v-model="mapAddProdBen"
                                    solo
                                    class="rounded-0"
                                    :rules="[rules.required]"
                                    multiple
                                    maxlength="50">
                                    <template v-slot:selection="{ item, index }">
                                        <span v-if="index === 0" >{{ item }}</span>
                                        <span v-if="index == 1" class="grey--text caption">(+{{ mapAddProdBen.length - 1 }})</span>
                                    </template>
                                    <template v-slot:prepend-item>
                                         <!-- @click="addProdctBenftChange()" -->
                                        <v-list-item ripple @click="addProdctBenftChange()">
                                            <v-list-item-action>
                                                <v-icon :color="mapAddProdBen.length > 0 ? 'blue darken-2' : ''">{{ iconBen }}</v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>Select All</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider ></v-divider>
                                    </template>
                                </v-select>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3 >
                            <v-layout column class="cust_pr mx-3 ">
                                <p class="font-weight-bold cstm-clr-pr">Add Group Policies</p>
                                <v-autocomplete
                                    :items="mapAddGrpPoliArray"
                                    v-model="mapAddGrpPolicy"
                                    color="white"
                                    item-text="policyNo"
                                    class="rounded-0"
                                    label="Enter"
                                    maxlength="50"
                                    return-object
                                    dense
                                    multiple
                                    solo
                                    @change="addGrpPolicyChange(mapAddGrpPolicy)"
                                    :rules="groupPolicyValid">
                                    <div slot="append" style="background-color:#F7F7F7;padding:7px;cursor:pointer;position:absolute;top:-6px;right:-12px;"><v-icon>search</v-icon></div>
                                    <template v-slot:selection="{ item, index }">
                                        <span v-if="index === 0" >{{ item.policyNo }}</span>
                                        <span v-if="index == 1" class="grey--text caption">(+{{ mapAddGrpPolicy.length - 1 }})</span>
                                    </template>
                                </v-autocomplete>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3></v-flex>
                        <v-flex xs3></v-flex>
                    </v-layout>
                </v-form>
            </div>

            <div class="" style="border:0.5px solid #C1C8CC;">
                <v-layout  style="padding:25px;background-color:#F7F7F7;align-items:center; justify-center; justify-content: flex-end;">
                    <!-- <v-btn dark  tile color="#23B1A9" class="text-capitalize px-3 mx-3" @click="clearData()" small><v-icon dark small>search</v-icon> <v-divider vertical class="mx-2"></v-divider>Search</v-btn> -->
                    <v-btn dark  tile color="#23B1A9" class="text-capitalize px-3 mx-3" @click="saveData()" small><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider>Save</v-btn>
                    <v-btn dark @click="$router.push('/NetworkManagement')" tile color="#E46A25" class="text-capitalize px-3 mx-3" small><v-icon dark small>arrow_back</v-icon> <v-divider vertical class="mx-2"></v-divider> Return</v-btn>
                </v-layout>
            </div>
        </v-card>
    </v-app>
</template>
<script>
import moment from 'moment';
import axios from "axios";

export default {

    components:{},

    created() {
        this.$store.commit("SAVE_PAGE_HEADER", "Map Products, Benefits & Policies");
        // this.getProductsData()
        let netwrkIDStore = this.$store.state.createNetworkData._id
        this.getNetworkData(netwrkIDStore)
        this.getGroupPolicyData()

    },

    computed: {
        // FOR ADD PRODUCTS
        compareProdList() {
            return this.mapAddProducts.length === this.mapAddProdsArray.length
        },
        selectSomeProd () {
            return this.mapAddProducts.length > 0 && !this.compareProdList
        },
        iconProd () {
            if (this.compareProdList) return 'mdi-close-box'
            if (this.selectSomeProd) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },

        // FOR ADD PRODUCTS AND BENEFITS
        compareBenList() {
            return this.mapAddProdBen.length === this.mapAddProdBenftArray.length
        },
        selectSomeBen () {
            return this.mapAddProdBen.length > 0 && !this.compareBenList
        },
        iconBen () {
            if (this.compareBenList) return 'mdi-close-box'
            if (this.selectSomeBen) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },
    },

    data() {
        return {
            mapNtwrkName:'',
            mapNtwrkID:'',
            mapNtwrkProd:[],
            mapNtwrkProdBenif:'',
            mapNtwrGrpPolicy:[],

            mapAddProducts:[],
            mapAddProdBen:[],
            mapAddGrpPolicy:[],

            mapAddProdsArray:[],
            mapAddProdBenftArray:[],
            mapAddGrpPoliArray:[],

            finalProductData:[],
            finalGrpPolicyData:[],
            groupPolicyValid:[],
            
            prevProdctData:[],
            mapNtwrGrpPrvData:[],
            mapProdPrvData:[],

            rules: {
                required: value => !!value || 'This Field is Required.',
                minExtRefNo: value => {
                    const pattern = /^[a-zA-Z0-9]+$/
                    return pattern.test(value) || 'This Field is Required.'
                },
            },
        }
    },

    methods: {
        getNetworkData(item){
            this.GET(4,'network-management/get-network-by-id/' + item,(res)=>{
                let _resp = res.data.data
                this.mapNtwrkName = _resp.networkName
                this.mapNtwrkID = _resp.networkId
                this.prevProdctData = _resp.products.length == 0 ? '-' : _resp.products
                this.mapProdPrvData = _resp.products.length == 0 ? '-' : _resp.products.map(function(el) { return {text :el.productName ,value:el.productId} })
                this.mapNtwrkProd = _resp.products.length == 0 ? '-' : _resp.products.map(function(el) { return el.productName })
                // console.log("=========(((((((((_resp)))))))))=======>>",_resp)
                this.getProductsData(_resp.products)
                this.mapNtwrkProdBenif = _resp.productBenefits.length == 0 ? '-' : _resp.productBenefits
                this.mapAddProdBen = this.mapNtwrkProdBenif !== '-' ? this.mapNtwrkProdBenif : []
                this.mapNtwrGrpPolicy = _resp.hasOwnProperty('groupPolicy') ? _resp.groupPolicy.length == 0 ? '-' : _resp.groupPolicy.map(function(el) { return el.policyNo }) : []
                this.mapNtwrGrpPrvData = _resp.hasOwnProperty('groupPolicy') ?  _resp.groupPolicy.length == 0 ? [] : _resp.groupPolicy.map(function(el) { return el }) : []
                this.getGroupPolicyData(this.mapNtwrGrpPrvData)
            })
        },

        addProdctChange(data){
            let _data = ''
            this.finalProductData = []
            for(let i = 0;i < data.length ; i++){
                _data += "products=" + data[i].text + "&";
                let _product = {
                    productId: data[i].value,
                    productName: data[i].text
                }
                this.finalProductData.push(_product)
            }
            // this.finalProductData = this.prevProdctData !== '-' ?  this.finalProductData.concat(this.prevProdctData) : this.finalProductData;
            let finalString = _data.slice(0, -1)
            this.getProductsBenefitsData(finalString)
        },
        selectAddProdData(){
            let _data = ''
            this.finalProductData = []

            this.$nextTick(() => {
                // this.compareProdList ? this.mapAddProducts = [] : this.mapAddProducts = this.mapAddProdsArray.slice()
                if(this.compareProdList){
                    this.mapAddProducts = []
                }else{
                    this.mapAddProducts = this.mapAddProdsArray.slice()
                    for(let i = 0;i < this.mapAddProducts.length ; i++){
                        _data += "products=" + this.mapAddProducts[i].text + "&";
                        let _product = {
                            productId: this.mapAddProducts[i].value,
                            productName: this.mapAddProducts[i].text
                        }
                        this.finalProductData.push(_product)
                    }
                    // this.finalProductData = this.prevProdctData !== '-' ?  this.finalProductData.concat(this.prevProdctData) : this.finalProductData;
                    let finalString = _data.slice(0, -1)
                    this.getProductsBenefitsData(finalString)
                }
                
            })
        },
        addProdctBenftChange(data){
            // console.log('(((((({{{{{[[[[[[   addProdctBenftChange   ]]]]]]}}}}})))))) ------',data)
            this.$nextTick(() => {
                this.compareBenList ? this.mapAddProdBen = [] : this.mapAddProdBen = this.mapAddProdBenftArray.slice()
            })
        },
        addGrpPolicyChange(data){
            this.finalGrpPolicyData = []
            this.groupPolicyValid = []
            for(let i = 0;i < data.length ; i++){
                let _product = {
                    policyNo: data[i].policyNo,
                    policyName: data[i].corporateGroupName,
                }
                this.finalGrpPolicyData.push(_product)
                for(let j = 0;j < this.mapNtwrGrpPolicy.length ; j++){
                    (data[i].policyNo.localeCompare(this.mapNtwrGrpPolicy[j]) == 0) ? this.groupPolicyValid.push('Policy is already mapped to network') : this.groupPolicyValid.push()

                }
            }
        },
        getProductsData(data){
            this.GET(3,'product-and-benefits/get-product-and-benefits',(res)=>{
                let _resp = res.data.data
                for(let j = 0;j < _resp.length ; j++){
                    // data[i].productName.localeCompare(_resp[j].product) == 0 ? _resp.splice(j, 1) : ''
                    let _data = { text:_resp[j].product, value:_resp[j]._id}
                    this.mapAddProdsArray.push(_data)
                }
                this.mapAddProducts = this.mapProdPrvData !== '-' ? this.mapProdPrvData : []
                this.mapAddProducts.length !== 0 ? this.addProdctChange(this.mapAddProducts) : ''
            })
        },
        
        getProductsBenefitsData(data){
            this.mapAddProdBenftArray = []
            this.GET(3,'product-and-benefits/search-product-benefits?' + data,(res,error)=>{
                if(!error){
                    if(res.data.statusCode === 200){
                        let _resp = res.data.data
                        this.mapAddProdBen = []
                        this.mapAddProdBenftArray = _resp
                        for(let i = 0;i < this.mapNtwrkProdBenif.length ; i++){
                            for(let j = 0;j < _resp.length ; j++){
                                this.mapNtwrkProdBenif[i] == _resp[j] ? this.mapAddProdBen.push(_resp[j]) : ''
                            }
                        }
                        
                    }else{
                        // this.mapAddProdBenftArray = []
                        this.showToast(res.data.msg ,this.TOST().ERROR); 
                    }
                }else{
                    this.mapAddProdBenftArray = []
                    this.showToast(error.response.data.msg, this.TOST().WARNING);
                }
            })
        },

        getGroupPolicyData(data){
            let self = this
            self.GET(1,'group-policy/group-policy-all',(res,error)=>{
                if(!error){
                    if(res.data.statusCode === 200){
                        let _resp = res.data.data.data
                        if(data.length !== 0){
                            for(let i = 0;i < data.length ; i++){
                                this.mapAddGrpPoliArray = []
                                for(let j = 0;j < _resp.length ; j++){
                                    if(_resp[j].hasOwnProperty('policyNumber') == true){
                                        data[i].policyNo.localeCompare(_resp[j].policyNumber) == 0 ? _resp.splice(j, 1) : ''
                                        let _data = {
                                            corporateGroupName : _resp[j].corporateGroupName,
                                            policyNo : _resp[j].policyNumber,
                                            productCode : _resp[j].productCode,
                                            _id : _resp[j]._id,
                                            productId:_resp[j].productId,
                                        }
                                        self.mapAddGrpPoliArray.push(_data)
                                    }
                                }
                            }
                        }else{
                            for(let i = 0;i < _resp.length ; i++){
                                let _data = {
                                    corporateGroupName : _resp[i].corporateGroupName,
                                    policyNo : _resp[i].policyNumber,
                                    productCode : _resp[i].productCode,
                                    _id : _resp[i]._id,
                                    productId:_resp[i].productId,
                                }
                                self.mapAddGrpPoliArray.push(_data)
                            }
                        }
                    }else{
                        self.showToast(res.data.msg ,self.TOST().ERROR); 
                    }
                }else{
                    self.showToast(error.response.data.msg, self.TOST().WARNING);
                }
            })
        },

        saveData(){
            // if(this.mapAddProducts.length == 0 || this.mapAddProdBen.length == 0 || this.mapAddGrpPolicy.length == 0 ){
            //     this.showToast('Please fill all empty fields', this.TOST().WARNING);
            //     this.$refs.netform.validate();
            //     return
            // } 
            if(this.groupPolicyValid.length > 0 ){
                this.showToast('Please fill Correct data', this.TOST().WARNING);
                return;
            }
            // this.mapAddProdBen = this.mapNtwrkProdBenif !== '-' ? this.mapAddProdBen.concat(this.mapNtwrkProdBenif) : this.mapAddProdBen;
            this.finalGrpPolicyData = this.finalGrpPolicyData.concat(this.mapNtwrGrpPrvData)
            let formData = {
                products: this.finalProductData,
                productBenefits: this.mapAddProdBen,
                groupPolicy: this.finalGrpPolicyData,
            }
            // return
            let netwrkID = this.$store.state.createNetworkData._id
            this.PUT(4,'network-management/update-network-product-policy/' + netwrkID,formData,(res,error)=>{
                if(!error){
                    if(res.data.statusCode === 200){
                        this.showToast(res.data.msg, this.TOST().SUCCESS);
                        this.getNetworkData(netwrkID)
                        this.$router.push('/NetworkManagement')

                    }else{
                        this.showToast(res.data.msg ,this.TOST().ERROR); 
                    }
                }else{
                    this.showToast(error.response.data.msg, this.TOST().WARNING);
                }
            })
        },

        clearData(){
            this.mapAddProducts = []
            this.mapAddProdBen = []
            this.mapAddGrpPolicy = []
            // this.$refs.netform.resetValidation()
        },
    },

    watch: {
        
    },
    
    
}

</script>
<style >
.activeredbackground{
  color:white;
}

.v-btn__content { width: 100% !important; white-space: normal !important; font-size:13px !important}

.srSelected{
  background-color: #23B1A9 !important;
  color:#FFFFFF !important;
}
.disable-field-style{
    border:1px solid #0014001f;
    padding:6px;
    background-color:#f7f7f7
}

</style>