<template>
  <v-app>
    <v-form v-model="MemberDetails" ref="MemberDetails">
      <v-card tile class="pa-3">
        <v-layout>
          <!-- :class="[dsblEmployeeName===true ? 'custDsbld_emplyMstr': '']" -->
          <v-flex xs3>
            <v-layout column class="cust_pln_em mx-2 ">
              <p class="font-weight-bold cstm-clr-pln-iphd_claims">
                Claim Number
              </p>
              <v-text-field
                solo
                label="Enter..."
                v-model="claimNumber"
                class="rounded-0"
                :maxlength="max30"
                :rules="[required()]"
                disabled
              >
              </v-text-field>
            </v-layout>
          </v-flex>

          <v-flex xs3>
            <v-layout column class="cust_pln_em mx-2 ">
              <p class="font-weight-bold cstm-clr-pln-iphd_claims">
                Feature Number
              </p>
              <v-text-field
                solo
                label="Enter..."
                v-model="featureNumber"
                class="rounded-0"
                :maxlength="max30"
                :rules="[required()]"
                disbled
              >
              </v-text-field>
            </v-layout>
          </v-flex>
          <v-flex xs3>
            <v-layout column class="cust_pln_em mx-2 ">
              <p class="font-weight-bold cstm-clr-pln-iphd_claims">
                Product Name
              </p>
              <v-text-field
                solo
                label="Enter..."
                v-model="productName"
                class="rounded-0"
                :maxlength="max30"
                :rules="[required()]"
                disabled
              >
              </v-text-field>
            </v-layout>
          </v-flex>

          <v-flex xs3>
            <v-layout column class="cust_pln_em mx-2">
              <p class="font-weight-bold cstm-clr-pln-iphd_claims">
                Coverage
              </p>
              <!-- item-text="planName"
                                item-value="_id" -->
              <v-select
                :items="coverageItems"
                label="Select"
                v-model="coverage"
                :rules="[required()]"
                solo
                dense
                class="rounded-0"
              >
              </v-select>
            </v-layout>
          </v-flex>

          <v-flex xs3></v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs3 class="">
            <v-layout column class="cust_pln_em mx-2">
              <p class="font-weight-bold cstm-clr-pln-iphd_claims">
                Member ID
              </p>
              <v-text-field
                v-model="memberID"
                label="Enter..."
                solo
                class="rounded-0"
                :rules="alphaNumeric"
                @click="visitMemberSearch"
              >
                <div
                  slot="append"
                  style="background-color:#F7F7F7;padding:8.5px;cursor:pointer;position:absolute;top:0px;right:0px"
                >
                  <v-icon>search</v-icon>
                </div>
              </v-text-field>
            </v-layout>
          </v-flex>

          <v-flex xs3>
            <v-layout column class="cust_pln_em mx-2 ">
              <p class="font-weight-bold cstm-clr-pln-iphd_claims">
                Policy Number
              </p>
              <v-text-field
                solo
                label="Enter..."
                v-model="policyNumber"
                class="rounded-0"
                :maxlength="max30"
                :rules="[required()]"
                disabled
              >
              </v-text-field>
            </v-layout>
          </v-flex>
          <v-flex xs3>
            <v-layout column class="cust_pln_em mx-2 ">
              <p class="font-weight-bold cstm-clr-pln-iphd_claims">
                Certificate Number {{ certificateNumber }}
              </p>
              <v-text-field
                solo
                label="Enter..."
                v-model="certificateNumber"
                class="rounded-0"
                :maxlength="max30"
                :rules="[required()]"
                disabled
              >
              </v-text-field>
            </v-layout>
          </v-flex>

          <v-flex xs3>
            <v-layout column class=" cust_pln_em mx-2 cust-rmvpd-iphd_claims">
              <p class="font-weight-bold cstm-clr-pln-iphd_claims">
                Date of Admission (Loss)
              </p>

              <v-menu
                ref="calender"
                :close-on-content-click="true"
                v-model="dateofAdmissionCalender"
                :nudge-right="40"
                transition="scale-transition"
                max-width="290px"
                min-width="290px"
                class="rounded-0"
              >
                <!-- :rules="typeOfEmployee !== 'Service ID'?[required()]:[]" -->
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-bind="attrs"
                    label="Select.."
                    :value="cmputddateofAdmissionMmntjs"
                    solo
                    class="rounded-0"
                    readonly
                    v-on="on"
                    :rules="[required()]"
                  >
                    <div
                      slot="prepend-inner"
                      style="background-color:#F7F7F7;padding: 9px !important"
                    >
                      <v-icon>event</v-icon>
                    </div>
                  </v-text-field>
                </template>
                <v-date-picker v-model="dateofAdmission" no-title>
                </v-date-picker>
              </v-menu>
            </v-layout>
          </v-flex>
          <v-flex xs3></v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs3>
            <v-layout column class="cust_pln_em mx-2 ">
              <p class="font-weight-bold cstm-clr-pln-iphd_claims">
                Name of Corporate
              </p>
              <v-text-field
                solo
                label="Enter..."
                v-model="nameOfCorporate"
                class="rounded-0"
                :maxlength="max30"
                :rules="[required()]"
                disabled
              >
              </v-text-field>
            </v-layout>
          </v-flex>
          <v-flex xs3>
            <v-layout column class="cust_pln_em mx-2 ">
              <p class="font-weight-bold cstm-clr-pln-iphd_claims">
                Employee ID
              </p>
              <v-text-field
                solo
                label="Enter..."
                v-model="employeeID"
                class="rounded-0"
                :maxlength="max30"
                :rules="[required()]"
                disabled
              >
              </v-text-field>
            </v-layout>
          </v-flex>
          <v-flex xs3>
            <v-layout column class="cust_pln_em mx-2 ">
              <p class="font-weight-bold cstm-clr-pln-iphd_claims">
                First Name
              </p>
              <v-text-field
                solo
                label="Enter..."
                v-model="firstName"
                class="rounded-0"
                :maxlength="max30"
                :rules="[required()]"
                disabled
              >
              </v-text-field>
            </v-layout>
          </v-flex>
          <v-flex xs3>
            <v-layout column class="cust_pln_em mx-2 ">
              <p class="font-weight-bold cstm-clr-pln-iphd_claims">
                Middle Name
              </p>
              <v-text-field
                solo
                label="Enter..."
                v-model="middleName"
                class="rounded-0"
                :maxlength="max30"
                :rules="[required()]"
                disabled
              >
              </v-text-field>
            </v-layout>
          </v-flex>
          <v-flex xs3>
            <v-layout column class="cust_pln_em mx-2 ">
              <p class="font-weight-bold cstm-clr-pln-iphd_claims">
                Surname
              </p>
              <v-text-field
                solo
                label="Enter..."
                v-model="surname"
                class="rounded-0"
                :maxlength="max30"
                :rules="[required()]"
                disabled
              >
              </v-text-field>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-divider class="mb-3"></v-divider>
        <v-layout>
          <v-flex xs3>
            <v-layout column class=" cust_pln_em mx-2 cust-rmvpd-iphd_claims">
              <p class="font-weight-bold cstm-clr-pln-iphd_claims">
                Date of Birth
              </p>

              <v-menu
                ref="calender"
                :close-on-content-click="true"
                v-model="dateOfBirthcalender"
                :nudge-right="40"
                transition="scale-transition"
                max-width="290px"
                min-width="290px"
                class="rounded-0"
              >
                <!-- :rules="typeOfEmployee !== 'Service ID'?[required()]:[]" -->
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-bind="attrs"
                    label="Select.."
                    :value="cmputddateOfBirthMmntjs"
                    solo
                    class="rounded-0"
                    readonly
                    v-on="on"
                    disabled
                  >
                    <div
                      slot="prepend-inner"
                      style="background-color:#F7F7F7;padding: 9px !important"
                    >
                      <v-icon>event</v-icon>
                    </div>
                  </v-text-field>
                </template>
                <v-date-picker v-model="dateOfBirth" no-title> </v-date-picker>
              </v-menu>
            </v-layout>
          </v-flex>

          <v-flex xs3>
            <v-layout column class="cust_pln_em cust-rmvpd-iphd_claims mx-2 ">
              <p class="font-weight-bold cstm-clr-pln-iphd_claims">
                Age
              </p>
              <v-text-field
                solo
                label="Enter..."
                v-model="Age"
                class="rounded-0"
                disabled
              >
                <div
                  slot="prepend-inner"
                  style="background-color:#F7F7F7;padding: 9px !important"
                >
                  <v-icon class>event</v-icon>
                </div>
              </v-text-field>
            </v-layout>
          </v-flex>

          <v-flex xs3>
            <v-layout column class="cust_pln_em mx-2">
              <p class="font-weight-bold cstm-clr-pln-iphd_claims">
                Gender
              </p>
              <div
                class=" elevation-1"
                style="background-color:#F7F7F7;padding-top: 8px;padding-bottom: 8px; "
              >
                <v-radio-group
                  v-model="gender"
                  mandatory
                  row
                  dense
                  class="ma-0 pa-0"
                  hide-details
                  :disabled="true"
                >
                  <v-radio
                    color="#23B1A9"
                    dense
                    value="Male"
                    class="ma-0 pa-0 pl-1"
                  
                  >
                    <template v-slot:label>
                      <div
                        class="font-weight-bold"
                        style="color:#10242B;font-size:12px"
                      >
                        Male
                      </div>
                    </template>
                  </v-radio>
                  <v-radio
                    color="#23B1A9"
                    dense
                    value="Female"
                    class="ma-0 pa-0 pl-2"
               
                  >
                    <template v-slot:label>
                      <div
                        class="font-weight-bold"
                        style="color:#10242B;font-size:12px"
                      >
                        Female
                      </div>
                    </template>
                  </v-radio>
                </v-radio-group>
              </div>
            </v-layout>
          </v-flex>
          <v-flex xs3>
            <v-layout column class="cust_pln_em mx-2">
              <p class="font-weight-bold cstm-clr-pln-iphd_claims">
                Relationship to Primary Insured
              </p>
              <!-- item-text="planName"
                                item-value="_id" -->
              <v-select
                :items="relationshipToPrimaryInsuredItems"
                label="Select"
                v-model="relationshipToPrimaryInsured"
                :rules="[required()]"
                solo
                dense
                class="rounded-0"
                disabled
              >
              </v-select>
            </v-layout>
          </v-flex>
          <v-flex xs3>
            <v-layout column class="cust_pln_em mx-2 ">
              <p class="font-weight-bold cstm-clr-pln-iphd_claims">
                Other
              </p>
              <v-text-field
                solo
                label="Enter..."
                v-model="otherRelationshipToPrimaryInsured"
                class="rounded-0"
                :maxlength="max30"
    
              >
              </v-text-field>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs3>
            <v-layout column class="cust_pln_em mx-2">
              <p class="font-weight-bold cstm-clr-pln-iphd_claims">
                Occupation
              </p>
              <!-- item-text="planName"
                                item-value="_id" -->
              <v-select
                :items="OccupationItems"
                label="Select"
                v-model="Occupation"
                :rules="[required()]"
                solo
                dense
                class="rounded-0"
              >
              </v-select>
            </v-layout>
          </v-flex>
          <v-flex xs3>
            <v-layout column class="cust_pln_em mx-2 ">
              <p class="font-weight-bold cstm-clr-pln-iphd_claims">
                Other
              </p>
              <v-text-field
                solo
                label="Enter..."
                v-model="otherOccupation"
                class="rounded-0"
                :maxlength="max30"
               
              >
              </v-text-field>
            </v-layout>
          </v-flex>
          <v-flex xs3>
            <v-layout column class="cust_pln_em mx-2">
              <p class="font-weight-bold cstm-clr-pln-iphd_claims">
                Tata AIG Employee
              </p>
              <!-- item-text="planName"
                                item-value="_id" -->
              <v-select
                :items="TataAIGEmployeeItems"
                label="Select"
                v-model="TataAIGEmployee"
                :rules="[required()]"
                solo
                dense
                class="rounded-0"
              >
              </v-select>
            </v-layout>
          </v-flex>
          <v-flex xs3></v-flex>
          <v-flex xs3></v-flex>
        </v-layout>
      </v-card>
      <v-card color="#01BBD9" class=" pa-2 pl-5" tile>
        <p
          class="text-uppercase  font-weight-medium ma-0 pa-0"
          style="color:#FFFFFF; font-size:13px"
        >
          Any Other Medical/Health Insurance
        </p>
      </v-card>
      <v-container>
        <v-row>
          <v-col cols="12" md="3"></v-col>
          <v-col cols="12" md="3"></v-col>
          <v-col cols="12" md="3"></v-col>
          <v-col cols="12" md="3" class="text-right">
            <v-btn
              dark
              tile
              color="#000"
              class="text-capitalize px-2 mx-2 my-2"
              small
              @click="addNewRow"
            >
              <v-icon dark small>add</v-icon>
            </v-btn>
            <v-btn
              dark
              tile
              color="#D1121B"
              class="text-capitalize px-2 mx-2 my-2"
              small
              @click="deleteRow(index)"
              ><v-icon dark small>delete</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-simple-table>
          <template v-slot:default>
            <thead style="background-color: rgb(1, 187, 217) ;">
              <tr>
                <th class="text-left mt-2" style="color: #fff">
                  All
                </th>
                <th class="text-left mt-2" style="color: #fff">
                  Sr.No
                </th>
                <th class="text-left mt-2" style="color: #fff">
                  Company
                </th>
                <th class="text-left mt-2" style="color: #fff">
                  Sum Insured
                </th>
                <th class="text-left mt-2" style="color: #fff">
                  Valid Upto
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in other_claims_insurances" :key="index">
                <td>{{ index }}</td>
                <td>
                  <v-checkbox
                    v-model="other_claims_insurances[index].selected_row"
                    @change="getSelectedRows(index)"
                  >
                  </v-checkbox>
                </td>
                <td>
                  <v-text-field
                    solo
                    label="Enter..."
                    v-model="other_claims_insurances[index].company"
                    class="rounded-0"
                    :maxlength="max30"
                    style=" height:40px !important"
                  />
                </td>
                <td>
                  <v-text-field
                    solo
                    label="Enter..."
                    v-model="other_claims_insurances[index].sumInsured"
                    class="rounded-0"
                    :maxlength="max30"
                    style=" height:40px !important"
                  />
                </td>
                <td>
                  <v-menu
                    ref="calender"
                    :close-on-content-click="true"
                    v-model="other_claims_insurances[index].validUptoCalender"
                    :nudge-right="40"
                    transition="scale-transition"
                    max-width="290px"
                    min-width="290px"
                    class="rounded-0"
                  >
                    <!-- :rules="typeOfEmployee !== 'Service ID'?[required()]:[]" -->
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-bind="attrs"
                        label="Select.."
                        v-model="other_claims_insurances[index].validUpto"
                        solo
                        class="rounded-0"
                        readonly
                        v-on="on"
                        style=" height:40px !important"
                      >
                        <div
                          slot="prepend-inner"
                          style="background-color:#F7F7F7;padding: 9px !important"
                        >
                          <v-icon>event</v-icon>
                        </div>
                      </v-text-field>
                    </template>

                    <v-date-picker
                      v-model="other_claims_insurances[index].validUpto"
                      no-title
                    >
                    </v-date-picker>
                  </v-menu>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-container>
      <claimsAddress
        ref="insuredDetailsAddress"
        @claimAddress_emit="Parent_claimAddress_emit"
      >
      </claimsAddress>

      <v-card tile style="max-height:150px" class="mx-3 mt-4 rounded-0">
        <div
          class="mx-2 pa-2"
          style="background-color:#eee; display:flex;justify-content:space-between;margin-left:0px!important;margin-right:0px!important;"
        >
          <v-layout style="flex:1" justify-end>
            <v-btn
              dark
              tile
              color="#152F38"
              class="text-capitalize px-3 mx-2 my-2"
              small
              @click="createFutureClaim"
              ><v-icon dark small>add</v-icon>
              <v-divider vertical class="mx-2"></v-divider> Create Feature
              Claim</v-btn
            >
            <v-btn
              dark
              tile
              color="#23B1A9"
              class="text-capitalize px-6 mx-2 my-2"
              small
            >
              NIDB</v-btn
            >
            <v-btn
              dark
              tile
              color="#E46A25"
              class="text-capitalize px-3 mx-2 my-2"
              small
              @click="$router.push('/HomePage')"
              ><v-icon dark small>cancel</v-icon>
              <v-divider vertical class="mx-2"></v-divider>Cancel</v-btn
            >
            <v-btn
              dark
              tile
              color="#D1121B"
              class="text-capitalize px-3 mx-2 my-2"
              small
                @clear="clearFields"
              ><v-icon dark small>delete</v-icon>
              <v-divider vertical class="mx-2"></v-divider>Clear</v-btn
            >
            <v-btn
              dark
              tile
              color="#23B1A9"
              class="text-capitalize px-3 mx-2 my-2"
              small
              @click="SaveDetails"
              ><v-icon dark small>check_circle</v-icon>
              <v-divider vertical class="mx-2"></v-divider>Save</v-btn
            >
            <v-btn
              dark
              tile
              color="#152F38"
              class="text-capitalize px-3 mx-2 my-2"
              small
              ><v-icon dark small>arrow_forward</v-icon>
              <v-divider vertical class="mx-2"></v-divider> Next</v-btn
            >
          </v-layout>
        </div>
      </v-card>
    </v-form>
    <v-dialog v-model="duplicateClaim" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2">
          Potential Duplicate Identified
        </v-card-title>

        <v-card-text>
          This is a potential duplicate of claim this claim Do you want to
          continue
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="SaveDetails('fromduplicate')">
            yes
          </v-btn>
          <v-btn color="primary" text @click="duplicateClaim = false">
            No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>
<script>
import moment from "moment";
import claimsAddress from "../../component/Claims_Address";
export default {
  components: {
    claimsAddress,
  },
  computed: {
    cmputdPlcyEfctveDteFrmtdMmntjs() {
      return this.dateReported
        ? moment(this.dateReported).format("DD/MM/YYYY")
        : "";
    },
    cmputdclaimRegisteredDateMmntjs() {
      return this.claimRegisteredDate
        ? moment(this.claimRegisteredDate).format("DD/MM/YYYY")
        : "";
    },
    cmputddateofAdmissionMmntjs() {
      return this.dateofAdmission
        ? moment(this.dateofAdmission).format("DD/MM/YYYY")
        : "";
    },

    cmputddateOfBirthMmntjs() {
      return this.dateOfBirth
        ? moment(this.dateOfBirth).format("DD/MM/YYYY")
        : "";
    },
    cmputddateofValidUpto() {
      return this.validUpto ? moment(this.validUpto).format("DD/MM/YYYY") : "";
    },
    // cmputddateofValidUpto(index) {
    //   return this.other_claims_insurances[index].validUpto
    //     ? moment(this.other_claims_insurances[index].validUpto).format("DD/MM/YYYY")
    //     : "";
    // },
  },
  
  mounted() {
    // checkfor registered claim id from claim regsitration screen
    if (this.$store.state.registeredClaimId) {
      // getting type of claimregistered and its id from params
      this.regClaimId = this.$store.state.registeredClaimId;
    }
    // if already claim detals of insuredprsn tab is submitted prepopulating the data
    if (this.$store.state.submittedClaimId) {
      this.setTabFieldsData();
    }
    if (this.$store.state.didMembersearch) {
      console.log("inside did membersearch", this.$store.state.didMembersearch);
      // getting membersearch data
      let memberdata = this.$store.getters.fetchClaimMembersearchData;
      console.log(
        "obj keys of member data",
        Object.keys(memberdata),
        memberdata
      );
      if (Object.keys(memberdata) !== 0) {
        console.log(memberdata, "member data");
        this.memberID = memberdata.memberId;
        this.policyNumber = memberdata.policyNumber;
        this.certificateNumber = memberdata.certificateNumber;
        this.nameOfCorporate = memberdata.corporateGroupName;
        this.employeeID = memberdata.employeeId;
        this.firstName = memberdata.firstName;
        this.surname = memberdata.lastName;
        this.dateOfBirth = memberdata.dateOfBirth;
        this.Age = memberdata.age;
        this.gender = memberdata.gender;
        // coming as number instead of available options
        // this.relationshipToPrimaryInsured = memberdata.relationshipToIsured;
        this.email = memberdata.email;
        this.mbNo = memberdata.mobileNumber;
      } else {
        console.log("member data is null");
      }
    }
  },
  data() {
    return {
      duplicateClaim: false,
      claimStatus: "No",
      selected_rows: [],
      MemberDetails: false,
      empanelmentType: "newClaim",
      dateReportedcalender: false,
      dateReported: "",
      claimRegisteredDateCalender: false,
      claimRegisteredDate: "",
      claimNumber: "123445",
      featureNumber: "1122334",
      coverageItems: ["Test"],
      coverage: "Test",
      claimedAmount: "",
      memberID: "",
      policyNumber: "",
      certificateNumber: "",
      dateofAdmissionCalender: false,
      dateofAdmission: "",
      validUptoCalender: false,
      company: "",
      suminsured: "",
      validUpto: "",
      nameOfCorporate: "",
      employeeID: "",
      firstName: "",
      middleName: "Test",
      surname: "",
      dateOfBirthcalender: false,
      dateOfBirth: "",
      Age: "",
      gender: '',
      relationshipToPrimaryInsuredItems: [
        "Self",
        "Spouse",
        "Mother",
        "Father",
        "Son",
        "Daughter",
        "Other",
      ],
      relationshipToPrimaryInsured: "Other",
      otherRelationshipToPrimaryInsured: "",
      OccupationItems: [
        "Service",
        "Self Employed",
        "Homemaker",
        "Student",
        "Retired",
        "Other",
      ],
      Occupation: "",
      TataAIGEmployeeItems: ["Yes", "No"],
      TataAIGEmployee: "",
      otherOccupation: "",
      productName: "Test product",
      catastropheCode: "",
      benefitClaimedItems: [],
      benefitClaimed: "",
      catastropheItems: ["Yes", "No"],
      catastrophe: "No",
      catastropheTypeItems: [],
      catastropheType: "",
      address: "",
      landmark: "",
      area: "",
      stateItems: [],
      state: "",
      cityTownVillageItems: [],
      cityTownVillage: "",
      district: "",
      pincode: "",
      email: "",
      mbNo: "",
      addDiffClm: "addDiffClmNo",
      addDiffClmaddress: "",
      addDiffClmlandmark: "",
      addDiffClmarea: "",
      addDiffstateItems: [],
      addDiffstate: "",
      addDiffdistrict: "",
      addDiffpincode: "",
      altDiffClm: "altDiffClmNo",
      altemail1: "",
      altemail2: "",
      altmbNo1: "",
      altmbNo2: "",
      timeReported: null,
      timeReportedmenu: false,
      timeAdmissionmenu: false,
      timeAdmission: null,
      max30: 30,
      max10: 10,
      other_claims_insurances: [{}],
      regClaimId: "",
      required() {
        return (v) => (v && v.length > 0) || "This Field is Required";
      },
      alphaNumeric() {
        return (v) => /^[0-9 ]+$/.test(v) || `Only Numbers are allowed.`;
      },
    };
  },
  methods: {
    clearFields(){

    },
    addNewRow() {
      this.other_claims_insurances.push({});
    },
    deleteRow() {
      this.selected_rows.forEach((rowId) => {
        this.other_claims_insurances.splice(rowId, 1);
      });
    },
    getSelectedRows(index) {
      this.selected_rows.push(index);
    },
    visitMemberSearch() {
      this.$store.commit("VALIDATE_MEMBER_SEARCH", true);
      this.$router.push({
        name: "MemberSearch_Claims",
        params: { page: "cashlsClaim" },
      });
    },
    emailRules2(v) {
      if (v.length !== 0) {
        console.log("testing", /^[0-9 ]+$/.test(v));
        if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,6})+$/.test(v)) {
          return "E-mail must be valid";
        } else {
          console.log("else none");
        }
      }
    },
    SaveDetails(data) {
      this.$refs.insuredDetailsAddress.claimAddress();

      // removing unwanted valid uptos from other_claims_insurances
      var copy_other_claims_insurances = this.other_claims_insurances;
       copy_other_claims_insurances.forEach(claim => {
        delete claim.validUptoCalender;
      });

      let formData = {
        // data which is not there but asking
        claimedAmount: "123456",
        timeOfAdmission: "840000",
        catastrophe: "No",
        catastropheType: "Local",
        catastropheCode: "Cat 2244",
        // end of data which isnt there
        claimType: "cashlessClaim",
        newClaim: "No",
        featureClaim: "Yes",
        claimNumber: "123445",
        featureNumber: "1122334",
        productName: this.productName,
        // not being allowed
        // coverage: this.coverage,
        // end of not being allowd
        healthInsuranceDetails: copy_other_claims_insurances,
        memberId: this.memberID,
        policyNumber: this.policyNumber,
        certificateNumber: this.certificateNumber,
        dateOfAdmission: this.dateofAdmission,
        nameOfCorporate: this.nameOfCorporate,
        employeeID: this.employeeID,
        firstName: this.firstName,
        middleName: this.middleName,
        surname: this.surname,
        dateOfBirth: this.dateOfBirth,
        years: "string",
        months: "string",
        gender: this.gender,
        relationshipToPrimaryInsured: this.relationshipToPrimaryInsured,
        occupation: this.occupation,
        other: this.otherOccupation,
        tataAIGEmployee: this.TataAIGEmployee,
        address: this.$refs.insuredDetailsAddress.address,
        landmark: this.$refs.insuredDetailsAddress.landmark,
        area: this.$refs.insuredDetailsAddress.area,
        cityTownVillage: this.$refs.insuredDetailsAddress.cityTownVillage,
        district: this.$refs.insuredDetailsAddress.District,
        pinCode: this.$refs.insuredDetailsAddress.pincode,
        state: this.$refs.insuredDetailsAddress.state,
        email: this.$refs.insuredDetailsAddress.email,
        mobileNumber: this.$refs.insuredDetailsAddress.mbNo,
        alternateAddressInClaim: this.$refs.insuredDetailsAddress.addDiffClm,
        alternateAddress: this.$refs.insuredDetailsAddress.addDiffClmaddress,
        alternateLandmark: this.$refs.insuredDetailsAddress.addDiffClmlandmark,
        alternateArea: this.$refs.insuredDetailsAddress.addDiffClmarea,
        alternateCityTownVillage: this.$refs.insuredDetailsAddress
          .addDiffcityTownVillage,
        alternateDistrict: this.$refs.insuredDetailsAddress.addDiffdistrict,
        alternatePincode: this.$refs.insuredDetailsAddress.addDiffpincode,
        alternateState: this.$refs.insuredDetailsAddress.addDiffstate,
        contactDetails: this.$refs.insuredDetailsAddress.altDiffClm,
        alternateEmailOne: this.$refs.insuredDetailsAddress.altemail1,
        alternateMobileNumberOne: this.$refs.insuredDetailsAddress.altmbNo1,
        alternateEmailTwo: this.$refs.insuredDetailsAddress.altemail2,
        alternateMobileNumberTwo: this.$refs.insuredDetailsAddress.altmbNo2,
      };
      if (data == "fromduplicate") {
        formData["claimStatus"] = "Yes";
        this.duplicateClaim = false;
      }
      let claimId = this.regClaimId;
      let apiURL = `/claims-details/craete-claim/${claimId}`;
      this.PUT(8, apiURL, formData, (res, error) => {
        if (!error) {
          console.log("POST APi response|-> member details ", res);
           this.store.state.commit('SAVE_SUBMITTED_CLAIM_TYPE',res.data._id);
          this.showToast(res.data.msg, this.TOST().SUCCESS);
        } else {
          console.log("POst APi error response|->  member details", error);
          if (error.response.data.msg == "Claim already exists") {
            this.duplicateClaim = true;
          } else {
            this.showToast(error.response.data.msg, this.TOST().ERROR);
          }
        }
      });
    },
    Parent_claimAddress_emit(emittedData) {
      console.log(emittedData, "emitted data");
    },
  },
};
</script>
<style>
.delpad_CR .v-input__slot {
  padding-right: 0px !important;
}

.cust_pln_iphd_claims .v-text-field .v-input__slot,
.v-text-field--outline .v-input__slot {
  min-height: 41px !important;
  display: flex !important;
  align-items: center !important;
  /* border:1px red solid; */
}

.cust_pln_iphd_claims .v-input .v-label {
  font-size: 13px !important;
}
.cust_pln_iphd_claims .v-input {
  font-size: 13px !important;
}
.cust_pln_iphd_claims .v-input input {
  font-size: 13px !important;
}

.cstm-clr-pln-iphd_claims {
  color: #646666 !important;
  margin-bottom: 5px !important;
  font-size: 13px !important;
}

.cust-rmvpd-iphd_claims
  .v-text-field.v-text-field--enclosed
  > .v-input__control
  > .v-input__slot {
  padding: 0px !important;
}
.v-data-table__wrapper {
  border: 1px solid #ccc;
}
/* .v-input__control{
  height: 34px;
} */
</style>
