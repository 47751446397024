<template>
    <v-app>
        <div class="mx-2">
            <v-layout class="mt-4">
                <v-flex xs3 >
                    <v-layout column class="custrtlPdt mx-2 cust_cepdt">
                        <p class="font-weight-bold cstm-clr-cepdt">
                            Product Code
                        </p>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="crtEdtPdt.pdtCd"
                             class="rounded-0"
                             disabled
                             >
                        </v-text-field>
                    </v-layout>
                </v-flex>

                 <v-flex xs3 >
                    <v-layout column class="custrtlPdt mx-2 cust_cepdt">
                        <p class="font-weight-bold cstm-clr-cepdt">
                            Product Description
                        </p>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="crtEdtPdt.pdtDsc"
                             class="rounded-0"
                             disabled
                             >
                        </v-text-field>
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class="custrtlPdt cust_celcy mx-2 ">
                        <p class="font-weight-bold cstm-clr-ceplcy">
                            Product Effective From
                        </p>
                     
                        <v-menu
                            ref="calender"
                            :close-on-content-click="true"
                            v-model="crtEdtPdt.pdtEfftvDteFrmcalender"
                            :nudge-right="40"
                           
                            transition="scale-transition"
                            disabled
                            max-width="290px"
                            min-width="290px" 
                              class="rounded-0"
                            >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    slot="activator"
                                    label="Select.."
                                    :value="cmputdpdtEfftvDteFrmMmntjs"
                                    solo
                                     class="rounded-0"
                                    disabled 
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                                                        
                                    <v-date-picker
                                        v-model="crtEdtPdt.pdtEfftvDteFrm"
                                        no-title
                                        disabled
                                    ></v-date-picker>
                        </v-menu>


                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class="custrtlPdt cust_celcy mx-2 ">
                        <p class="font-weight-bold cstm-clr-ceplcy">
                            Product Effective To
                        </p>
                     
                        <v-menu
                            ref="calender"
                            :close-on-content-click="true"
                            v-model="crtEdtPdt.pdtEfftvDteToFrmcalender"
                            :nudge-right="40"
                           disabled
                            transition="scale-transition"
                            
                            max-width="290px"
                            min-width="290px" 
                              class="rounded-0"
                            >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    slot="activator"
                                    label="Select.."
                                    :value="cmputdpdtEfftvDteToMmntjs"
                                    solo
                                     class="rounded-0"
                                    disabled 
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                                                        
                                    <v-date-picker
                                        v-model="crtEdtPdt.pdtEfftvDteTo"
                                        @change="validDateChk"
                                        no-title 
                                        disabled
                                    ></v-date-picker>
                        </v-menu>


                    </v-layout>
                </v-flex>

                <v-flex xs3 >
                    <v-layout column class="custrtlPdt mx-2 cust_cepdt">
                        <p class="font-weight-bold cstm-clr-cepdt">
                            Reporting Class
                        </p>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="crtEdtPdt.pdtRprtngClss"
                             class="rounded-0"
                             disabled
                             >
                        </v-text-field>
                    </v-layout>
                </v-flex>
            </v-layout>
            <v-layout>

                <v-flex xs3 >
                    <v-layout column class="custrtlPdt mx-2 cust_cepdt">
                        <p class="font-weight-bold cstm-clr-cepdt">
                            IRDA UIN Number
                        </p>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="crtEdtPdt.pdtIrdaNo"
                             class="rounded-0"
                             disabled
                             >
                        </v-text-field>
                    </v-layout>
                </v-flex>

                <v-flex xs3 >
                    <v-layout column class="custrtlPdt mx-2 cust_cepdt">
                        <p class="font-weight-bold cstm-clr-cepdt">
                            Product Type
                        </p>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="crtEdtPdt.pdtTyp"
                             class="rounded-0"
                             disabled
                             >
                        </v-text-field>
                    </v-layout>
                </v-flex>

                <v-flex xs3 >
                    <v-layout column class="custrtlPdt mx-2 cust_cepdt">
                        <p class="font-weight-bold cstm-clr-cepdt">
                            Product Status
                        </p>
                        <v-select
                        :items="pdtStatus"
                        label="Select"
                        v-model="crtEdtPdt.pdtSts"
                        solo
                        dense
                        disabled
                         class="rounded-0">
                    </v-select>
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <!-- <v-layout column class=" mx-2 mt-5">
                    <p class="font-weight-bold cstm-clr-cepdt">
                    
                    </p>
                    <v-btn tile dark color="#23B1A9" class="text-capitalize py-5" @click="cnfgPlanfrPdt()">
                        <v-icon small dark>add_circle</v-icon>
                        <v-divider vertical class=" mx-1"></v-divider>
                        Create New Plan
                    </v-btn>
                    </v-layout> -->
                </v-flex>
                <v-flex xs3></v-flex>
                
            </v-layout>

            <v-card  tile class="cust-tbl-cepdt mx-2">
                <div  style="background-color:#23B1A9" class="pa-2 pl-3"><v-text class="font-weight-bold body-2"  style="color:#F5F5F5">Plan Name</v-text></div>
                <!-- :page.sync="page"
                    :items-per-page="itemsPerPage"
                    @page-count="pageCount = $event" -->
                <v-data-table
                    :headers="headers"
                    :items="rtlPlnDta"
                    single-select
                    hide-default-footer
                    dense
                    disable-pagination
                    show-select
                    item-key="_id"
                    v-model="selected"
                >
                 <template v-slot:item.planEffectiveDate={item}> 
                            <div>
                                {{item.planEffectiveDate|formatDate}}
                            </div>  
                        </template>
                        <template v-slot:item.planExpiryDate={item}> 
                            <div>
                                {{item.planExpiryDate|formatDate}}
                            </div>  
                        </template>
                    <template v-slot:item.actions="{ item }" >
                        <v-menu
                            transition="slide-x-transition"
                            top
                            left
                            :nudge-left="30"
                            :nudge-bottom="17"
                            content-class="my-menu-bnkmstr"
                            style=""
                            flat
                            allow-overflow
                        >
                       
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn  style="width: 19px;height: 19px;background-color:#1E9690" fab dark small class= "my-1" v-bind="attrs"
                                v-on="on">
                                <v-icon dark small>more_horiz</v-icon>
                            </v-btn>
                        </template>
                        <!-- class="pa-0 ma-0 cust-bnk-tile" -->
                        <v-list dense style="background-color:#FFFFFF" class="pa-0 ma-0">
                            <!-- class="pa-0 ma-0" -->
                            <v-list-item
                                dense
                                style="min-height: 28px !important"
                                v-for="(itm, index) in chkr_items"
                                :key="index"
                                @click="chkr_seltd(itm,item)" 
                                class="pa-0 ma-0">
                                <!-- class="pa-0 ma-0" style="background-color:#FFFFFF;min-width: 37px; border-right:0.5px solid #C1C8CC"  -->
                                <v-list-item-icon class="pa-1 pt-2 ma-0 mr-0" > 
                                    <!-- :color="item.color" class="pl-2 pt-1" -->
                                    <v-icon  small>{{ itm.icon }}</v-icon>
                                </v-list-item-icon>
                                <v-divider vertical class="mx-1"></v-divider>
                                <!-- class="pa-0 ma-0 pl-2 pt-1 font-weight-bold" -->
                                <v-list-item-title  class="pa-1 pr-3 ma-0 mr-0" style="font-size: 12px !important;">
                                    {{ itm.title }}
                                </v-list-item-title>

                            </v-list-item>
                        </v-list>
                        </v-menu>
                    </template>
                </v-data-table>
                <v-divider></v-divider>
               
            </v-card>
            <v-card style="background-color:#F7F7F7" tile class="mx-2" v-if="rtlPlnDta.length != 0">
                 <v-layout row justify-end align-center class="mx-2" >
                            <span class="mr-1 font-weight-bold" style="color:#3C3D3D; font-size:13px;">
                                <span class="" style="color:#E46A25;">Showing {{fromvalue}}-{{toValu}} </span>out of {{totalCount}} records
                            </span>
                            <v-divider  class="ma-2" vertical ></v-divider>
                             <v-pagination
                                class="cust-bnkAcc-pgn"
                                v-model="page"
                                color="#152F38"
                                flat
                                @input="nextPageData(page)"
                                :length="pageCount"
                            ></v-pagination>
                </v-layout>

            </v-card>
            <v-card style="background-color:#F7F7F7" tile class="my-4 pa-2 py-4 mx-2">

                <v-layout style="flex:1" justify-end>
                     <v-btn tile dark small style="background-color:#23B1A9" class="text-capitalize px-3 ml-3" @click="cnfgPlanfrPdt()">
                        <v-icon small dark>add_circle</v-icon>
                        <v-divider vertical class=" mx-2"></v-divider>
                        Create New Plan
                    </v-btn>
                    <v-btn dark tile  small class="text-capitalize px-3 ml-3" style="font-size:12px;background-color:#E46A25" @click="$router.push('/homePage')">
                        <div class="mt-1">
                            <img src="../../assets/return.png" style="width: 16px;height: 16px"/>
                        </div> <v-divider vertical class="mx-2"></v-divider>Return</v-btn>
                    <!-- <v-btn dark  tile color="#23B1A9" class="text-capitalize px-3 mx-3" small @click.stop="showScheduleForm=true"><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider> Save</v-btn> -->
                    <v-btn dark tile style="font-size:12px;background-color:#152F38" small class="text-capitalize px-3 ml-3 mr-2" @click="sendForApprvl()"><v-icon dark small >arrow_forward</v-icon> <v-divider vertical class="mx-2"></v-divider>Send For Approval</v-btn>
                </v-layout>

            </v-card>
            <v-dialog v-model="dlteDilg" persistent max-width="420px">
                        <v-card color="#F7F7F7" class="pb-2" tile>
                            <v-card tile>
                                <v-layout justify-space-between  class="" style="flex:1;background-ox-shadow: 0px 3px 5px #00000029;">
                                    <v-layout align-center>
                                        <v-card-title class="pa-0 pl-3 py-2 text-capitalize font-weight-bold text-uppercase" style="color:#10242B;font-size:13px">Confirmation</v-card-title>   
                                    </v-layout>
                                    <div style="border-left:1px solid #C1C8CC;" @click="close_dltePlan()">
                                        <v-icon style="cursor: pointer;" class="pa-2" >close</v-icon>
                                    </div>
                                </v-layout>
                            </v-card>
                            <v-card tile  class="pa-4 py-6">
                               <p class="pa-0 ma-0 font-weight-bold" style="font-size:13px">Are you sure you want to delete ?</p>
                            </v-card>

                            
                            <v-layout class="ml-4 pt-4 pb-2" row style="">
                                <v-btn tile dark style="font-size:12px;background-color:#152F38" class=" px-3 mr-3 text-capitalize" small @click="close_dltePlan()">
                                    <v-icon color="" small >close</v-icon>
                                    <v-divider vertical class="ml-2 mr-2 text-capitalize"></v-divider>Cancel
                                </v-btn>
                                 <v-btn tile dark style="font-size:12px;background-color:#23B1A9" class=" px-8 text-capitalize" small @click="deletePlan(plnIdfrDlte)">
                                    <v-icon color="" small >check_circle</v-icon>
                                    <v-divider vertical class="ml-2 mr-2 text-capitalize"></v-divider>OK
                                </v-btn>
                            </v-layout>
                            
                            
                        </v-card>
            </v-dialog>
        </div>
    </v-app>
</template>

<script>
import axios from "axios";
import moment from 'moment';
export default {

    components:{
        
    },

    created() {

        this.$store.commit("SAVE_PAGE_HEADER", "Create Edit Plan");
        // this.crtEdtPdt.pdtCd=this.$store.getters.fetchProductCodeData
        this.token=this.$store.getters.successToken
        // this.crtEdtPdt.pdtCd=this.$store.getters.fetchProductCodeData
        console.log(this.$store.getters.fetchProductCodeData, "Product ID")
        this.Incmg_ProductId=this.$store.getters.fetchProductCodeData
        this.getProductDetailsByID(this.$store.getters.fetchProductCodeData)
        this.getAllRetlplndtls(1,this.Incmg_ProductId)

    },
    filters:{

      truncate: function (text, length, suffix) {
            if (text.length > length) {
                return text.substring(0, length) + suffix;
            } else {
                return text;
            }
        },
        formatDate(data){// eslint-disable-line no-mixed-spaces-and-tabs
           // console.log('Validity Data', data);

            if (data == "" || data == "undefined" || data == null || data == '' || data == 'Invalid Date') {
               return "-"
           } else {
            //    var n = new Date(data);
            //    return n.toLocaleString("en-IN")
            return moment(data,'YYYY-MM-DD').format('DD-MM-YYYY')
           }
        },
    },

    computed: {
         cmputdpdtEfftvDteFrmMmntjs(){
            return this.crtEdtPdt.pdtEfftvDteFrm ? moment(this.crtEdtPdt.pdtEfftvDteFrm).format("DD-MM-YYYY"): ''
        },
         cmputdpdtEfftvDteToMmntjs(){
            return this.crtEdtPdt.pdtEfftvDteTo ? moment(this.crtEdtPdt.pdtEfftvDteTo).format("DD-MM-YYYY"): ''
        },

        validDateChk(){
            
            let fromeDate = moment(this.crtEdtPdt.pdtEfftvDteFrm,'YYYY-MM-DD').toDate().getTime()
            let todayDate = Date.now();
            let Todate= moment(this.crtEdtPdt.pdtEfftvDteTo,'YYYY-MM-DD ').toDate().getTime()
            console.log(todayDate,"todayDate")
            let CurrentDate= todayDate
            console.log(CurrentDate,Todate,"CurrentDate,Product Effective To,")

            if(Todate < CurrentDate){
                console.log("if")
                return this.crtEdtPdt.pdtSts="Inactive"
            }else{
                console.log("else")
                return this.crtEdtPdt.pdtSts="Active"
            }
        }

    },

    data() {
        return{
            crtEdtPdt:{
                pdtCd:"",
                pdtDsc:"",
                pdtEfftvDteFrm:"",
                pdtEfftvDteFrmcalender:false,
                pdtEfftvDteTo:"",
                pdtEfftvDteToFrmcalender:false,
                pdtRprtngClss:"",
                pdtIrdaNo:"",
                pdtTyp:"",
                pdtSts:"",
                pdtId:"",
            },
            token:null,
            fromvalue:'',
            toValu:'',
            pdtStatus:["Active", "Inactive"],
            itemsPerPage: 10,
            totalCount:0,
            page: 1,
            crntPgNo:0,
            pageCount: 0,
            selected:[],
             dlteDilg:false,
            plnIdfrDlte:"",
            Incmg_ProductId:'',
            headers:[
                
                {text: 'Plan Name', value: 'planName',sortable: false,divider:true,class:"hdr-txt-clr-cepdt"},
                {text: 'Plan Description', value: 'planDescription',sortable: false,divider:true,class:"hdr-txt-clr-cepdt"},
                {text: 'Plan Effective Date', value: 'planEffectiveDate',sortable: false,divider:true,class:"hdr-txt-clr-cepdt"},
                {text: 'Plan Expiry Date', value: 'planExpiryDate',sortable: false,divider:true,class:"hdr-txt-clr-cepdt"},
                {text: 'Plan Status', value: 'planstatus',sortable: false,divider:true,class:"hdr-txt-clr-cepdt"},
                {text: 'Product Code', value: 'productCode',sortable: false,divider:true,class:"hdr-txt-clr-cepdt"},
                {text: 'External Reference No.', value: 'externalRefNo',sortable: false,divider:true,class:"hdr-txt-clr-cepdt"},
                {text: 'Action', value: 'actions',sortable: false,divider:true,class:"hdr-txt-clr-cepdt hdr-bg-clr-cepdt", width:"55px"}

            ],
            chkr_items: [
                { title: 'Delete', icon:'delete',color:"#646666"},
                { title: 'Edit', icon:'check_circle',color:"#1E9690" },
                { title: 'Configure' , icon:'settings',color:"#D1121B"},
                
            ],
            rtlPlnDta:[],
            GetBnkMstrHstryDta:[
                {
                    PlanName:"MEDC",
                    ProductCode:"1",
                    PlanDescription:"Medicare - Plan A",
                    PlanEffectiveDate:"13/1/2019",
                    PlanExpiryDate:"13/1/2020",
                    PlanStatus:"Active",
                    PolicyType:"Retail Health",
                    ExternalRefrenceNo:"- - - - - -"
                },{
                    PlanName:"MEDC",
                    ProductCode:"2",
                    PlanDescription:"Medicare - Plan A",
                    PlanEffectiveDate:"13/1/2019",
                    PlanExpiryDate:"13/1/2020",
                    PlanStatus:"Active",
                    PolicyType:"Retail Health",
                    ExternalRefrenceNo:"- - - - - -"
                },{
                    PlanName:"MEDC",
                    ProductCode:"3",
                    PlanDescription:"Medicare - Plan A",
                    PlanEffectiveDate:"13/1/2019",
                    PlanExpiryDate:"13/1/2020",
                    PlanStatus:"Active",
                    PolicyType:"Retail Health",
                    ExternalRefrenceNo:"- - - - - -"
                },{
                    PlanName:"MEDC",
                    ProductCode:"4",
                    PlanDescription:"Medicare - Plan A",
                    PlanEffectiveDate:"13/1/2019",
                    PlanExpiryDate:"13/1/2020",
                    PlanStatus:"Active",
                    PolicyType:"Retail Health",
                    ExternalRefrenceNo:"- - - - - -"
                },{
                    PlanName:"MEDC",
                    ProductCode:"5",
                    PlanDescription:"Medicare - Plan A",
                    PlanEffectiveDate:"13/1/2019",
                    PlanExpiryDate:"13/1/2020",
                    PlanStatus:"Active",
                    PolicyType:"Retail Health",
                    ExternalRefrenceNo:"- - - - - -"
                },{
                    PlanName:"MEDC",
                    ProductCode:"6",
                    PlanDescription:"Medicare - Plan A",
                    PlanEffectiveDate:"13/1/2019",
                    PlanExpiryDate:"13/1/2020",
                    PlanStatus:"Active",
                    PolicyType:"Retail Health",
                    ExternalRefrenceNo:"- - - - - -"
                },{
                    PlanName:"MEDC",
                    ProductCode:"7",
                    PlanDescription:"Medicare - Plan A",
                    PlanEffectiveDate:"13/1/2019",
                    PlanExpiryDate:"13/1/2020",
                    PlanStatus:"Active",
                    PolicyType:"Retail Health",
                    ExternalRefrenceNo:"- - - - - -"
                },{
                    PlanName:"MEDC",
                    ProductCode:"8",
                    PlanDescription:"Medicare - Plan A",
                    PlanEffectiveDate:"13/1/2019",
                    PlanExpiryDate:"13/1/2020",
                    PlanStatus:"Active",
                    PolicyType:"Retail Health",
                    ExternalRefrenceNo:"- - - - - -"
                },{
                    PlanName:"MEDC",
                    ProductCode:"9",
                    PlanDescription:"Medicare - Plan A",
                    PlanEffectiveDate:"13/1/2019",
                    PlanExpiryDate:"13/1/2020",
                    PlanStatus:"Active",
                    PolicyType:"Retail Health",
                    ExternalRefrenceNo:"- - - - - -"
                },{
                    PlanName:"MEDC",
                    ProductCode:"10",
                    PlanDescription:"Medicare - Plan A",
                    PlanEffectiveDate:"13/1/2019",
                    PlanExpiryDate:"13/1/2020",
                    PlanStatus:"Active",
                    PolicyType:"Retail Health",
                    ExternalRefrenceNo:"- - - - - -"
                },
                
            ],

        }




    },

    methods: {

        editBnkAcc(item){
            console.log("item", item)
        },
        close_dltePlan(){
            this.dlteDilg=false

        },
         chkr_seltd(itm,item){
            console.log("menu item clciked",itm);
            
            if(itm.title=="Edit"){
                console.log("item clciked Edit",item);
                let btnName={
                    text:'EditNwRtlPln',
                    visible:true,
                    plcy_Id:null,
                    plcy_No:null,
                    plan_No:null,
                    plan_Typ:"retail",
                    retailPlan_Id:item._id,
                    retailPlan_productCode:item.productCode,
                    retailPlan_productDescription:item.productDescription,
                    retailPlan_productId:item.productId
                }
                this.$store.commit('SAVE_PLCYID_FOR_PLAN', btnName)
                this.$router.push('/product/productconfiguration');
            }else if(itm.title=="Configure"){
                console.log("item clciked Configure",item);
                if(item.planName===""||item.planName===null||item.planName===undefined){
                    this.showToast("Please Add Plan Details First",this.TOST().WARNING);
                    return
                }
                
                let btnName={
                    text:'CnfgrNwRtlPln',
                    visible:true,
                    plcy_Id:null,
                    plcy_No:null,
                    plan_No:null,
                    plan_Typ:"retail",
                    retailPlan_Id:item._id,
                    retailPlan_productCode:item.productCode,
                    retailPlan_productDescription:item.productDescription,
                    retailPlan_productId:item.productId
                }
                this.$store.commit('SAVE_PLCYID_FOR_PLAN', btnName)
                this.$router.push('/product/productconfiguration');
            }else if(itm.title=="Delete"){
                console.log("item clciked Delete",item);
                console.log("item clciked",item);

                this.dlteDilg=true
                this.plnIdfrDlte=item._id
                // this.deletePlan(item._id)
            }
            
        },

        deletePlan(planId){
            this.dlteDilg=false
            console.log(planId,"incoming planId")
            let self=this
               axios({
                    method: "delete",
                    url:
                        self.API_Service_admin()+"retail-plan/delete-retail-plan/"+planId, 
                        headers: {
                            "Content-Type": "application/json", 
                            "x-access-token": self.token,
                        }
                })
                .then(function(response) {
                    console.log('retail-plan/delete-retail-plan------->>>',response.data.data);
                    self.showToast("Plan has been deleted successfully" ,self.TOST().SUCCESS)
                    self.page=1
                    self.getAllRetlplndtls(1,this.Incmg_ProductId)
                    
                    
                })
                .catch(function(error) {
                    console.log(error);
                    // self.showToast(error.response.data.msg,self.TOST().ERROR);
                });


        },

        sendForApprvl(){

            console.log("selected", this.selected)

            if(this.selected.length===0||!this.selected.length){
                this.showToast("Please Select Plan/Plans First",this.TOST().WARNING);
                return
            }
            for(let i=0;i<this.selected.length;i++){
                console.log("loop", i)
                 if(this.selected[i].planName===""||this.selected[i].planName===null||this.selected[i].planName===undefined){
                    this.showToast("Please Add Plan Details First",this.TOST().WARNING);
                    return
                }
            }

            
            let self=this
            let formData={
                code: this.selected[0].productCode,
                name: "",
                externalRefNo: this.selected[0].externalRefNo,
                description: "",
                type: "",
                docId: this.selected[0].productId,
                itemId: "",
                assignToName: "",
                status: "",
                planName:this.selected[0].planName,
                planDescription: this.selected[0].planDescription,
                productId: this.selected[0].productId,
                productCode: this.selected[0].productCode,
                planId: this.selected[0]._id,
                productDescription: this.selected[0].productDescription,
                productType: "retail-plan"
            }
            // let wrkItemDta = [];
            // self.selected.map((itm) => {
            //     wrkItemDta.push({
            //        externalRefNo:itm.externalRefNo,
            //        planName:itm.planName,
            //        planDescription:itm.planDescription,
            //        code:itm.productCode,
            //        docId:itm.productId

            //     });
            // });

            // console.log("wrkItemDta",wrkItemDta)
            axios({
                method: "post",
                url:
                    self.API_Service_admin()+"retail-plan/create-retail-work-item",
                headers: {
                    "Content-Type": "application/json",
                    "x-access-token": self.token
                    // "x-access-token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI1ZmJlMDg4ZGM0NmRhODNlZTdmODcxYmIiLCJyb2xlIjoiQWRtaW5Vc2VyIiwiaWF0IjoxNjA2MzY5MzI0LCJleHAiOjE2Mzc5MDUzMjR9.iS5hZs0uImNFz-rxNaQ7CTCc6qS_hG4H0FsePZ5Sgqc",
                },
                data:formData,
            })
            .then(function (response) {
                console.log(" work-items/multiple-create API Response>>>>>>", response);
                self.showToast(response.data.msg ,self.TOST().SUCCESS)
                self.selected=[]
            })
            .catch(function (error) {
                console.log(" work-items/multiple-create Error APi Response>>>>>>", error);
                // self.showToast(error.response.data.msg,self.TOST().ERROR);

            });

        },


        cnfgPlanfrPdt(){
            //  let btnName={
            //     text:'CrteNwRtlPln',
            //     visible:true,
            //     plan_Typ:"retail",
            //     plan_Id:null
            // }
            // this.$store.commit('SAVE_PLAN_FOR_RETAIL', btnName)

             let btnName={
                text:'CrteNwRtlPln',
                visible:true,
                plcy_Id:null,
                plcy_No:null,
                plan_No:null,
                plan_Typ:"retail",
                retailPlan_Id:null,
                retailPlan_productCode:this.crtEdtPdt.pdtCd,
                retailPlan_productDescription:this.crtEdtPdt.pdtDsc,
                retailPlan_productId:this.$store.getters.fetchProductCodeData
            }
            this.$store.commit('SAVE_PLCYID_FOR_PLAN', btnName)
            this.$router.push('/product/productconfiguration');
            
        },

        nextPageData(pgNo){
            console.log(pgNo,"=======>pgNo")
            console.log(this.crntPgNo,"=======>crntPgNo")
            if(this.crntPgNo===pgNo){
                console.log("Current page data already showing")
                return
            }
            this.getAllRetlplndtls(pgNo,this.Incmg_ProductId)
        },

        getProductDetailsByID(id){
            console.log("ID",id)
                let self = this;   
                axios({
                    method: "get",
                    url:
                        self.API_Service_admin()+"product/get-product/"+id, 
                        headers: {
                            "Content-Type": "application/json", 
                            "x-access-token": self.token,
                        }
                })
                .then(function(response) {
                    console.log('Product Details Response------->>>',response.data.data);
                    let pdtDta={}
                    pdtDta=response.data.data
          
                    self.crtEdtPdt.pdtCd=pdtDta.productCode
                    self.crtEdtPdt.pdtDsc=pdtDta.productDescription
                    self.crtEdtPdt.pdtEfftvDteFrm=moment(pdtDta.productEffectiveFrom,'YYYY-MM-DD').format('YYYY-MM-DD')
                    self.crtEdtPdt.pdtEfftvDteTo=moment(pdtDta.productEffectiveTo,'YYYY-MM-DD').format('YYYY-MM-DD')
                    self.crtEdtPdt.pdtRprtngClss=pdtDta.reportingClass
                    self.crtEdtPdt.pdtIrdaNo=pdtDta.irdaUinNumber
                    self.crtEdtPdt.pdtTyp=pdtDta.productType
                    self.crtEdtPdt.pdtId=pdtDta._id
                })

            //      crtEdtPdt:{
            //     pdtCd:"",
            //     pdtDsc:"",
            //     pdtEfftvDteFrm:"",
            //     pdtEfftvDteFrmcalender:false,
            //     pdtEfftvDteTo:"",
            //     pdtEfftvDteToFrmcalender:false,
            //     pdtRprtngClss:"",
            //     pdtIrdaNo:"",
            //     pdtTyp:"",
            //     pdtSts:"",
            // },
                .catch(function(error) {
                    console.log(error);
                    // self.showToast(error.response.data.msg,self.TOST().ERROR);
                });

        },
        getAllRetlplndtls(pgNo,pdtId){

            let self = this;   
            self.rtlPlnDta=[]
                axios({
                    method: "get",
                    url:
                        self.API_Service_admin()+"retail-plan/retail-plan-all?perPage="+self.itemsPerPage+"&pageNo="+pgNo+"&productId="+pdtId, 
                        headers: {
                            "Content-Type": "application/json", 
                            "x-access-token": self.token,
                        }
                })
                .then(function(response) {
                    console.log('Group paln Details------->>>',response.data.data.data);
                    if(!response.data.data.data || response.data.data.data.length == 0){
                        console.log("Array is either empty or does not exist")
                        // self.showToast("No Data Found",self.TOST().WARNING);
                        return
                    }
                    // self.planforPlcyData=response.data.data.data
                    let apiData=[]
                    apiData=response.data.data.data
                    self.pageCount=response.data.data.total_pages
                    self.totalCount=response.data.data.total
                     for (let i = 0; i < apiData.length; i++){
                        let status= self.valdDteChk(apiData[i].planExpiryDate )
                        console.log(status)
                        let productdata = {

                            _id:apiData[i]._id,
                            planName:apiData[i].planName,
                            planDescription:apiData[i].planDescription,
                            planEffectiveDate:apiData[i].planEffectiveDate,
                            planExpiryDate:apiData[i].planExpiryDate,
                            planstatus:status==="Active"?"Active":"Inactive",
                            productCode:apiData[i].productCode,
                            productDescription:apiData[i].productDescription,
                            productId:apiData[i].productId,
                            externalRefNo:apiData[i].externalRefNo,
                            createdAt:apiData[i].createdAt,
                            updatedAt:apiData[i].updatedAt,
                            __v:apiData[i].__v

                        }
                        self.rtlPlnDta.push(productdata);
                    }
                    self.crntPgNo=pgNo
                    console.log( self.crntPgNo,"=======> self.crntPgNo")

                    const start = pgNo * self.itemsPerPage - self.itemsPerPage,
                    end = start + self.itemsPerPage;
                    console.log(start,end,"start End")
                     if(self.totalCount===0){
                            self.fromvalue=0
                        }else{
                            self.fromvalue=start+1;
                        }
                        
                        if(self.totalCount <self.itemsPerPage){
                            self.toValu=self.totalCount
                        }else if(self.totalCount<end){
                            self.toValu=self.totalCount
                        }else{
                            self.toValu=end
                        }
                    
                })
                .catch(function(error) {
                    console.log(error);
                    // self.showToast(error.response.data.msg,self.TOST().ERROR);
                });

        },

        valdDteChk(todte){
                // console.log(todte,"incoming date")
            if(todte===null||todte===undefined|| todte===''){
                    return "Inactive"
            }else{
                let d = Date.now();
                let Todate= moment(todte,'YYYY-MM-DD').toDate().getTime()
                let CurrentDate= d
                console.log(Todate,CurrentDate,"Todate,CurrentDate")
                if(Todate===null||Todate===undefined|| Todate===''||Todate==="Invalid" ){
                    return "Inactive"
                }else if(Todate < CurrentDate ){
                    return "Inactive"
                }else if (Todate>CurrentDate){
                    return "Active"
                }
            }
        },
        
        


    },

    watch: {
    
    },
    
    
}

</script>
<style >
.cust_cepdt .v-text-field .v-input__slot,
.v-text-field--outline .v-input__slot {
    min-height: 42px !important;
    display: flex !important;
    align-items: center !important;
    /* border:1px red solid; */
}

.cust-rmvpd-pd .v-text-field.v-text-field--enclosed>.v-input__control>.v-input__slot {
    padding: 0px !important;;
}

.cstm-clr-cepdt{
    color:#646666 !important;
    margin-bottom: 5px !important;
    font-size: 13px !important;
}

.cust_cepdt .v-input .v-label {
    font-size: 13px !important;
}
.cust_cepdt .v-input {
    font-size: 13px !important;
}
.cust_cepdt .v-input input {
    font-size: 13px !important;
}

.title-clr-pr{
    color:#10242B;
    margin-bottom: 5px;
}

.lbl-bgclr{
    color:#152F38 !important;

}
.hdr-txt-clr-cepdt{
    color:#10242B !important;
    padding:0px!important;
    padding-left: 14px !important;
}

.hdr-bg-clr-cepdt{
    background-color: #f7f7f7!important;
}
/* .pdct-stUp-tbl table.v-table thead th{
    border-right:1px solid  #C1C8CC !important;
    background-color:#F3FBFB !important;
    color:#10242B !important;
    font-size:13px !important;
    font-family:Roboto !important;
    font-weight:700 !important;
    padding: 0px!important;
    padding-left: 9px !important;
} */
/* .pdct-stUp-tbl table.v-table thead th:last-child{
    border-right: unset !important;
    background-color:#F7F7F7 !important;
} */
/* .pdct-stUp-tbl table.v-table tbody td,
.pdct-stUp-tbl table.v-table thead tr{
    height: 40px !important;
    padding-left: 12px !important;
}
.pdct-stUp-tbl table.v-table thead tr:last-child{
    height: unset !important;
    
} */

.cust-tbl-cepdt .v-data-table > .v-data-table__wrapper > table > tbody > tr > td:last-child{
     background-color: #f7f7f7 !important;
}

.pdct-stUp-tbl-brdr{
    border:1px solid  #C1C8CC;
}

.pdct-stUp-td-bdrgt {
    border-right:1px solid #C1C8CC;
 }

 .pdct-stUp-bnkAcc-pgn .v-pagination__navigation{
        box-shadow:unset !important;
        border-radius: unset !important;
        background-color:#FFFFFF !important;
        border:1px solid #DAE1E6 !important;
        height: 30px !important;
        width: 30px !important;
    }
    .pdct-stUp-bnkAcc-pgn .v-pagination__navigation--disabled{
        background-color:#DAE1E6 !important;
    }
    .pdct-stUp-bnkAcc-pgn .v-pagination__item{
        box-shadow:unset !important;
        min-width:1.8rem !important;
        height:1.8rem !important;
        border-radius: unset !important;
        border:1px solid #DAE1E6 !important
    }

    .pdct-stUp-tbl .v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
    border: 1px solid  #C1C8CC !important;
}

.pdct-stUp-tbl .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
    border: 1px solid #C1C8CC !important;
}

.cust-tbl-cepdt .v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
    font-size: 0.84rem !important;
}

 .cust-tbl-cepdt .v-data-table--dense > .v-data-table__wrapper > table > thead > tr > th{
        border-right-width: thin !important;
        border-right-style: solid !important;
        border-right-color: rgba(0, 0, 0, 0.12) !important;
    } 

    .cust-tbl-cepdt .v-data-table--dense > .v-data-table__wrapper > table > tbody > tr > td{
        border-right-width: thin !important;
        border-right-style: solid !important;
        border-right-color: rgba(0, 0, 0, 0.12) !important;
    }

 .my-menu-bnkmstr {
        /* border: solid black; */
  margin-top: 40px;
  contain: initial;
  overflow: visible;
}
.my-menu-bnkmstr::before {
  position: absolute;
  content: "";
  top: 11px;
  right: -14px;
  transform: rotate(90deg);
  width: 10px; 
  height: 13px; 
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 12px solid #FFFFFF;
}

.custrtlPdt .theme--light.v-text-field--solo > .v-input__control > .v-input__slot {
    background: #F7F7F7;
}


</style>