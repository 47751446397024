<template>
    <v-app class="ml-6">
        <v-form @submit.prevent="submitDetails">
        <div style="background-color: #FFFFFF;" column class="py-3">
            <v-layout class=" mt-2" style="height:80px" >

            <!-- Provider*  -->
            <v-flex xs3>
                    <v-layout column class="cust_pr_quick cust-rmvpd-psins_quick mx-2  ">
                        <p class="font-weight-bold cstm-clr-pr-quick">
                            Provider*
                        </p>
                       
                    <div class="d-flex">
                        <!-- <p>{{searchedItems}}</p> -->
                    <v-autocomplete
                    v-model="providerDetails"
                    :items="searchedItems"
                    :loading="isLoading"
                    :search-input.sync="searchApi"
                    color="white"
                    item-text="name"
                    item-value="_id"
                    label="Provider Name..."
                    cache-items
                    return-object
                    class="rounded-0 deleIconQuick"
                    solo
                    :error-messages="providerErrors"
                    @blur="$v.providerDetails.$touch()"
                    required>
                <!-- <div slot="append" style="background-color:#F7F7F7;padding: 9px !important" class=""><v-icon @click="getProviderDetails">search</v-icon></div>        -->
                </v-autocomplete>
                    <v-card 
                        slot="append" style="  
                        background-color: rgb(247, 247, 247);
                        padding: 9px !important;
                        cursor: pointer;
                        height: 42px;
                        z-index: 1;" 
                        @click="getProviderDetails(providerDetails._id)"
                        class="rounded-0">
                        <v-icon elevation="1">search</v-icon></v-card>
                    </div>
                    </v-layout>
                </v-flex>    

            <!-- PRN  -->
            <v-flex xs3>
                    <v-layout column class="cust_pr_quick cust-rmvpd-psins_quick mx-2">
                        <p class="font-weight-bold cstm-clr-pr-quick">
                            PRN
                        </p>
                        <v-text-field
                            autocomplete="off"
                            solo
                            label="Enter..."
                            v-model="prn"
                            class="rounded-0"
                            disabled
                            background-color="#f7f7f7"
                            >
                        </v-text-field>
                    </v-layout>
                </v-flex>

            <!-- Provider Network  -->
             <v-flex xs3>
                    <v-layout column class="cust_pr_quick cust-rmvpd-psins_quick mx-2  ">
                        <p class="font-weight-bold cstm-clr-pr-quick">
                            Provider Network
                        </p>
                        <v-text-field
                            disabled
                            autocomplete="off"
                            solo
                            label="Provider Name..."
                            v-model="providerNetwork"
                            background-color="#f7f7f7"
                            class="rounded-0">
                            <!-- <div slot="append" style="background-color:#F7F7F7;padding: 9px !important" class=""><v-icon>search</v-icon></div> -->
                        </v-text-field>
                    </v-layout>
                </v-flex>         

                <!-- Network Number*  -->
                <v-flex xs3>
                    <v-layout column class=" mx-2 cust_pr_quick cust-rmvpd-psins_quick">
                        <p class="font-weight-bold cstm-clr-pr-quick">
                            Network ID
                        </p>
                        <v-text-field
                            disabled
                            autocomplete="off"
                            solo
                            background-color="#f7f7f7"
                            label="Enter..."
                            v-model="networkNumber"
                            class="rounded-0"
                            >
                            <!-- <div slot="prepend-inner" style="background-color:#F7F7F7;padding: 9px !important"><v-icon class="material-icons">call</v-icon></div> -->
                        </v-text-field>
                    </v-layout>
                </v-flex>
            </v-layout>

            <!-- Date  -->    
            <v-layout class="mt-2">
                <!-- From*  -->
                <v-flex xs3>
                    <v-layout column class=" mx-2  cust_pr_quick cust-rmvpd-pr-quick">
                        <p class="font-weight-bold cstm-clr-pr-quick">
                            From*
                        </p>
                        
                        <v-menu
                            ref="calender"
                            :close-on-content-click="false"
                            v-model="fromCalenderDate"
                            :nudge-right="40"
                            lazy
                            transition="scale-transition"
                            full-width
                            max-width="290px"
                            min-width="290px" 
                            class="rounded-0"   
                            >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-bind="attrs"
                                    v-on="on"
                                    id="fromDate"
                                    label="dd/mm/yyyy"
                                    :value="computedProviderDateForm"
                                    solo
                                    class="rounded-0"
                                    readonly
                                    required
                                    :error-messages="dateFromErrors"
                                    @input="$v.providerFromDate.$touch()"
                                    @blur="$v.providerFromDate.$touch()"
                                >
                            <div  slot="prepend-inner" style="background-color:#F7F7F7;  padding: 8px !important"><span class="material-icons">date_range</span></div>
                                
                                </v-text-field>
                            </template>                        
                                    <v-date-picker
                                        v-model="providerFromDate"
                                        no-title
                                        @input="fromCalenderDate = false"
                                    ></v-date-picker>
                        </v-menu>


                    </v-layout>
                </v-flex>

                <!-- To*  -->
                <v-flex xs3>
                        <v-layout column class=" mx-2 cust_pr_quick cust-rmvpd-pr-quick">
                            <p class="font-weight-bold cstm-clr-pr-quick">
                                To*
                            </p>
                            
                            <v-menu
                                ref="calender"
                                :close-on-content-click="false"
                                v-model="toCalenderDate"
                                :nudge-right="40"
                                lazy
                                transition="scale-transition"
                                full-width
                                max-width="290px"
                                min-width="290px" 
                                class="rounded-0"
                                
                                >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-bind="attrs"
                                        v-on="on"
                                        label="dd/mm/yyyy"
                                        :value="computedProviderDateTo"
                                        solo
                                        
                                        class="rounded-0"
                                        readonly
                                        required
                                        :error-messages="providerToDateErrors"
                                        @input="$v.providerToDate.$touch()"
                                        @blur="$v.providerToDate.$touch()"
                                        
                                    >
                                <div  slot="prepend-inner" style="background-color:#F7F7F7;  padding: 8px !important"><span class="material-icons">date_range</span></div>
                                    
                                    </v-text-field>
                                </template>                        
                                        <v-date-picker
                                            v-model="providerToDate"
                                            no-title
                                            @input="toCalenderDate = false"

                                        ></v-date-picker>
                            </v-menu>


                        </v-layout>
                </v-flex>

            </v-layout>

            <v-divider class="mt-2"></v-divider>

            <!-- PAYMENT DETAILS  -->
            <v-layout class="mt-2">
                
                <v-flex xs3>
                    <v-layout column class="mx-2 my-2">
                        <v-text class="text-uppercase font-weight-bold title-clr-pr">
                            PAYMENT DETAILS
                        </v-text>
                        <v-divider></v-divider>
                    </v-layout>
                </v-flex>
                
                <!-- Payee Name  -->
                <v-flex xs3>
                    <v-layout column class="cust_pr_quick cust-rmvpd-psins_quick mx-2 my-2 ">
                        <p class="font-weight-bold cstm-clr-pr-quick">
                            Payee Name
                        </p>
                        <v-text-field
                            autocomplete = "off"
                            solo
                            label="Enter..."
                            v-model.trim="payeeName"
                            class="rounded-0"
                            :error-messages="payeeErrors"
                            @input="$v.payeeName.$touch()"
                            @blur="$v.payeeName.$touch()"
                            required
                            maxlength=30
                            >
                        </v-text-field>
                    </v-layout>
                </v-flex>

                <!-- Bank Name  -->
                <v-flex xs3>
                <v-layout column class="cust_pr_quick cust-rmvpd-psins_quick mx-2 my-2 ">
                <p class="font-weight-bold cstm-clr-pr-quick">
                    Bank Name
                </p>
                 <v-autocomplete
                        v-model.trim="bankName"
                        :items="bankcollection"
                        color="white"
                        hide-no-data
                        cache-items
                        placeholder="Start typing to Search"
                        @change="grab_bankname(bankName, 0),changeData()"
                        solo
                        class="rounded-0"
                        :error-messages="bankNameErrors"
                        @input="$v.bankName.$touch()"
                        @blur="$v.bankName.$touch()"
                  ></v-autocomplete>
            </v-layout>
                </v-flex>

                <!-- Branch  -->
                <v-flex xs3>
                    <v-layout column class="cust_pr_quick  cust-rmvpd-psins_quick mx-2 my-2 ">
                        <p class="font-weight-bold cstm-clr-pr-quick">
                            Branch
                        </p>
                        <v-autocomplete
                        v-model="branch"
                        :items="branch_collection"
                        item-text="branch"
                        :item-value="branchValue"
                        class="rounded-0"
                        color="white"
                        hide-no-data
                        @change="setthedata(branch)"
                        placeholder="Enter..."
                        solo 
                        return-object
                        
                  >
                        <!-- :error-messages="branchErrors"
                        @input="$v.branch.$touch()"
                        @blur="$v.branch.$touch()" -->
                        </v-autocomplete>
                    </v-layout>
                </v-flex>

                <!-- MICR code  -->
                <v-flex xs3>
                    <v-layout column class="cust_pr_quick cust-rmvpd-psins_quick mx-2 my-2 ">
                        <p class="font-weight-bold cstm-clr-pr-quick">
                            MICR code
                        </p>
                        <v-text-field
                            autocomplete = "off"
                            solo
                            label="Enter..."
                            v-model.trim="micrCode"
                            class="rounded-0"
                            :error-messages="micrCodeErrors"
                            @input="$v.micrCode.$touch()"
                            @blur="$v.micrCode.$touch()"
                            maxlength=50>
                        </v-text-field>
                    </v-layout>
                </v-flex>
            </v-layout>

             <!-- IFSC Code  -->
            <v-layout>
                <v-flex xs3>
                    <v-layout column class="cust_pr_quick cust-rmvpd-psins_quick mx-2 my-2 ">
                        <p class="font-weight-bold cstm-clr-pr-quick">
                            IFSC code
                        </p>
                        <v-text-field
                            autocomplete = "off"
                            solo
                            label="Enter..."
                            v-model.trim="ifscCode"
                            class="rounded-0"
                            :error-messages="ifscCodeErrors"
                            @input="$v.ifscCode.$touch()"
                            @blur="$v.ifscCode.$touch()"
                            
                            required
                            maxlength=50>
                        </v-text-field>
                    </v-layout>
                </v-flex>

               <!-- Type of Account -->
                  <v-flex xs3>
                    <v-layout column class="cust_pr_quick cust-rmvpd-psins_quick mx-2 my-2 ">
                        <v-text class="font-weight-bold cstm-clr-pd">
                            Type of Account
                        </v-text>
                        <v-autocomplete
                            :items="account"
                            label="Select"
                            v-model.trim="typeOfAccount"
                            solo
                            dense
                            class="rounded-0"
                            maxlength=20
                            required
                            >

                        </v-autocomplete>
                    </v-layout>
                </v-flex>

                <!-- Account Number  -->
                <v-flex xs3>
                    <v-layout column class="cust_pr_quick cust-rmvpd-psins_quick mx-2 my-2 ">
                        <p class="font-weight-bold cstm-clr-pr-quick">
                            Account Number
                        </p>
                        <v-text-field
                            autocomplete = "off"
                            solo
                            label="Enter..."
                            v-model.trim="accountNumber"
                            class="rounded-0"
                            :error-messages="accountNumberErrors"
                            @input="$v.accountNumber.$touch()"
                            @blur="$v.accountNumber.$touch()"
                            required
                            maxlength=50
                            >
                        </v-text-field>
                    </v-layout>
                </v-flex>

                <!-- PAN  -->
               <v-flex xs3>
                    <v-layout column class="cust_pr_quick cust-rmvpd-psins_quick mx-2 my-2 ">
                        <p class="font-weight-bold cstm-clr-pr-quick">
                            PAN
                        </p>
                        <v-text-field
                            autocomplete = "off"
                            color="#f7f7f7"
                            solo
                            label="Enter..."
                            v-model.trim="panId"
                            class="rounded-0"
                            :error-messages="panErrors"
                            @input="$v.panId.$touch()"
                            @blur="$v.panId.$touch()"
                            required
                            maxlength=10
                            >
                        </v-text-field>
                       
                    </v-layout>
                     <!-- <p class="error" v-if="!$v.panId.panIdCheck">this is </p> -->
                </v-flex>

                <!-- Proprietorship Name -->
                <v-flex xs3>
                    <v-layout column class="cust_pr_quick cust-rmvpd-psins_quick mx-2 my-2 ">
                        <p class="font-weight-bold cstm-clr-pr-quick">
                            Proprietorship Name
                        </p>
                        <v-text-field
                            autocomplete = "off"
                            solo
                            label="Enter..."
                            v-model.trim="proprietorshipName"
                            class="rounded-0"
                            :error-messages="proprietorshipNameErrors"
                            @input="$v.proprietorshipName.$touch()"
                            @blur="$v.proprietorshipName.$touch()"
                            maxlength=100>
                        </v-text-field>
                    </v-layout>
                </v-flex>
            </v-layout>

            <!-- GSTIN   -->
             <v-flex xs3>
                    <v-layout column class="cust_pr_quick cust-rmvpd-psins_quick mx-2 my-2 ">
                        <p class="font-weight-bold cstm-clr-pr-quick">
                            GSTIN
                        </p>
                        <v-text-field
                            autocomplete = "off"
                            solo
                            dense
                            label="Enter..."
                            v-model.trim="gstin"
                            class="rounded-0"
                            style="width: 240px"
                            maxlength=15
                            :error-messages="gstinErrors"
                            @input="$v.gstin.$touch()"
                            @blur="$v.gstin.$touch()">
                        </v-text-field>
                    </v-layout>
                </v-flex>

            <v-divider class="mt-3"></v-divider>


            <!-- Radio buttons Bank Gurantee -->
            <v-layout class=" mt-3">

                <!-- Radio buttons Bank Gurantee -->
                <v-flex xs3>
                        <v-layout column class=" mx-2 my-2 cust_pr_quick cust-rmvpd-pr-quick">
                            <v-text class="font-weight-bold cstm-clr-pr-quick">
                                    Bank Gurantee
                             </v-text>
                            <v-layout   class="d-flex justify-start " style="background-color: #F7F7F7; height: 45px ; border: solid 0.5px #dae1e6;"  >
                                <v-radio-group    class="ml-2 pa-0 align-self-center" dense  v-model="bankRadioBtn" row  >
                                    <v-radio color = "#23b1a9"  @click="bankRadioButton"  style="height:8px width:8px" label="Yes" value="Yes" ></v-radio>
                                    <v-radio color = "#23b1a9" @click="bankRadioButton"  label="No" value="No"></v-radio>
                                </v-radio-group> 

                            </v-layout>
                        </v-layout>
                    </v-flex>

                <!-- If Amount Yes  -->
                <v-flex xs3>
                    <v-layout column class=" mx-2 my-2  cust_pr_quick cust-rmvpd-pr-quick">
                        <p class="font-weight-bold cstm-clr-pr-quick">
                            If yes,amount*
                        </p>
                        <v-text-field
                            autocomplete = "off"
                            :disabled = "bankDisableValue"
                            solo
                            label="Enter..."
                            v-model="bankIfYesAmount"
                            class="rounded-0"
                            :error-messages="bankIfYesAmountErrors"
                            @input="$v.bankIfYesAmount.$touch()"
                            @blur="$v.bankIfYesAmount.$touch()"
                            maxlength=20>
                            <div slot="prepend-inner" style="background-color:#F7F7F7;padding: 9px !important"><v-icon class="material-icons">mdi-currency-inr</v-icon></div>
                        </v-text-field>
                    </v-layout>
                </v-flex>

                <!-- Validity From  -->
                 <v-flex xs3>
                    <v-layout  column class=" mx-2 mt-2 cust_pr_quick cust-rmvpd-pr-quick">
                        <p class="font-weight-bold cstm-clr-pr-quick">
                            Validity From*
                        </p>
                        
                        <v-menu
                            ref="calender"
                            :close-on-content-click="false"
                            v-model="bankCalenderDateFrom"
                            :nudge-right="40"
                            lazy
                            transition="scale-transition"
                            full-width
                            max-width="290px"
                            min-width="290px" 
                            class="rounded-0"
                            
                            >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    :disabled = "bankDisableValue"
                                    v-bind="attrs"
                                    v-on="on"
                                    label="dd/mm/yyyy"
                                    :value="computedBankDateForm"
                                    solo
                                    class="rounded-0"
                                    readonly
                                    :error-messages="bankGuranteeFromErrors"
                                    @input="$v.bankDateFrom.$touch()"
                                    @blur="$v.bankDateFrom.$touch()" 
                                    
                                >
                            <div  slot="prepend-inner" style="background-color:#F7F7F7;  padding: 8px !important"><span class="material-icons">date_range</span></div>
                                
                                </v-text-field>
                            </template>                        
                                    <v-date-picker
                                        v-model="bankDateFrom" 
                                        @input="bankCalenderDateFrom = false"
                                        no-title
                                    ></v-date-picker>
                        </v-menu>


                    </v-layout>
                </v-flex>

                <!-- Validity To  -->
                <v-flex xs3>
                    <v-layout column class=" mx-2 mt-2 cust_pr_quick cust-rmvpd-pr-quick">
                        <p class="font-weight-bold cstm-clr-pr-quick">
                            Validity To*
                        </p>
                      
                        <v-menu
                            ref="calender"
                            :close-on-content-click="false"
                            v-model="bankCalenderDateTo"
                            :nudge-right="40"
                            lazy
                            transition="scale-transition"
                            full-width
                            max-width="290px"
                            min-width="290px" 
                            class="rounded-0"
                            >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    :disabled = "bankDisableValue"
                                    v-bind="attrs"
                                    v-on="on"
                                    label="dd/mm/yyyy"
                                    :value="computedBankDateTo"
                                    solo
                                    class="rounded-0"
                                    readonly
                                    :error-messages="bankGuranteeToErrors"
                                    @input="$v.bankDateTo.$touch()"
                                    @blur="$v.bankDateTo.$touch()"  >
                            <div  slot="prepend-inner" style="background-color:#F7F7F7;  padding: 8px !important"><span class="material-icons">date_range</span></div>
                                </v-text-field>
                            </template>                        
                                    <v-date-picker
                                        v-model="bankDateTo" 
                                        no-title
                                        @input="bankCalenderDateTo = false"

                                    ></v-date-picker>
                        </v-menu>


                    </v-layout>
                </v-flex>


            </v-layout>

            
            <!-- Account Payment  -->
           <v-layout class="mt-3 ">
                <!-- Radio buttons Advance Payment -->
                <v-flex xs3>
                        <v-layout column class=" mx-2 my-2 cust_pr_quick cust-rmvpd-pr-quick">
                            <v-text class="font-weight-bold cstm-clr-pr-quick">
                                   Advance Payment
                             </v-text>
                            <v-layout   class="d-flex justify-start " style="background-color: #F7F7F7; height: 45px ; border: solid 0.5px #dae1e6;"  >
                                <v-radio-group   class="mt1 ml-2 pa-0 align-self-center" dense  v-model="paymentRadioBtn" row  >
                                    <v-radio color = "#23b1a9" @click="payRadioBtn" style="height:8px width:8px" label="Yes" value="Yes" ></v-radio>
                                    <v-radio color = "#23b1a9" @click="payRadioBtn" label="No" value="No" ></v-radio>
                                </v-radio-group> 

                            </v-layout>
                        </v-layout>
                    </v-flex>

                <!-- If Amount Yes  -->
                <v-flex xs3>
                    <v-layout column class=" mx-2 my-2  cust_pr_quick cust-rmvpd-pr-quick">
                        <p class="font-weight-bold cstm-clr-pr-quick">
                            If yes,amount*
                        </p>
                        <v-text-field
                            autocomplete = "off"
                            :disabled = "payDisableValue"
                            solo
                            label="Enter..."
                            v-model="payeeIfYesAmount" 
                            class="rounded-0"
                            :error-messages="payeeIfYesAmountErrors"
                            @input="$v.payeeIfYesAmount.$touch()"
                            @blur="$v.payeeIfYesAmount.$touch()"
                             maxlength=20>
                            <div slot="prepend-inner" style="background-color:#F7F7F7;padding: 9px !important"><v-icon class="material-icons">mdi-currency-inr</v-icon></div>
                        </v-text-field>
                    </v-layout>
                </v-flex>

                <!-- Validity From  -->
                 <v-flex xs3>
                    <v-layout column class=" mx-2 mt-2 cust_pr_quick cust-rmvpd-pr-quick">
                        <p class="font-weight-bold cstm-clr-pr-quick">
                           Validity From*
                        </p>
                        
                        <v-menu
                            ref="calender"
                            :close-on-content-click="false"
                            v-model="accountCalenderDateFrom"
                            :nudge-right="40"
                            lazy
                            transition="scale-transition"
                            full-width
                            max-width="290px"
                            min-width="290px" 
                            class="rounded-0"
                            
                            >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    :disabled = "payDisableValue"
                                   
                                    v-bind="attrs"
                                    v-on="on"
                                    label="dd/mm/yyyy"
                                    :value="computedAccountDateForm"
                                    solo
                                    class="rounded-0"
                                    readonly
                                    :error-messages="accPaymentFromErrors"
                                    @input="$v.accountDateFrom.$touch()"
                                    @blur="$v.accountDateFrom.$touch()" 
                                >
                            <div  slot="prepend-inner" style="background-color:#F7F7F7;  padding: 8px !important"><span class="material-icons">date_range</span></div>
                                
                                </v-text-field>
                            </template>                        
                                    <v-date-picker
                                        v-model="accountDateFrom" 
                                        no-title
                                        @input="accountCalenderDateFrom = false"
                                        
                                    ></v-date-picker>
                        </v-menu>


                    </v-layout>
                </v-flex>

                <!-- Validity To  -->
                <v-flex xs3>
                    <v-layout column class=" mx-2 mt-2 cust_pr_quick cust-rmvpd-pr-quick">
                        <p class="font-weight-bold cstm-clr-pr-quick">
                            Validity To*
                        </p>
                      

                        <v-menu
                            ref="calender"
                            :close-on-content-click="false"
                            v-model="accountCalenderDateTo"
                            :nudge-right="40"
                            lazy
                            transition="scale-transition"
                            full-width
                            max-width="290px"
                            min-width="290px" 
                            class="rounded-0"
                            >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    :disabled = "payDisableValue"
                                    v-bind="attrs"
                                    v-on="on"
                                    label="dd/mm/yyyy"
                                    :value="computedAccountDateTo"
                                    solo
                                    class="rounded-0"
                                    readonly
                                    :error-messages="accPaymentToErrors"
                                    @input="$v.accountDateTo.$touch()"
                                    @blur="$v.accountDateTo.$touch()"  
                                >
                            <div  slot="prepend-inner" style="background-color:#F7F7F7;  padding: 8px !important"><span class="material-icons">date_range</span></div>
                                
                                </v-text-field> 
                            </template>                        
                                    <v-date-picker
                                        v-model="accountDateTo"
                                        no-title
                                        @input="accountCalenderDateTo = false"
                                        
                                    ></v-date-picker>
                        </v-menu>


                    </v-layout>
                </v-flex>


            </v-layout>     

                <div class="mx-3 mt-8 pa-2" style="background-color: #F7F7F7; display:flex;justify-content:space-between"  >
                    <v-layout style="flex:1" justify-end > 
                        <v-btn dark  tile color="#D1121B" class="text-capitalize px-3 mx-2" @click="clearData" small><v-icon dark small>delete</v-icon> <v-divider vertical class="mx-2"></v-divider>Clear</v-btn>
                        <v-btn dark  tile color="#23B1A9" class="text-capitalize px-3 ml-2" type = "submit"  small><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider> Save</v-btn>
                    </v-layout>
                </div>
            </div>
        </v-form>
    </v-app>
</template>

<script>
// import axios from 'axios';
import moment from 'moment';
import { required, numeric, alpha, alphaNum, helpers } from 'vuelidate/lib/validators';

// const requiredIfBank = requiredIf(function () { return this.bkisRequired })
// const requiredIfAdvance = requiredIf(function () { return this.adisRequired })



let providerIdForEmp = "";
let registeredEntity = "";
let providerType = "";
let dateIs = null;
let checkDateErrors = [];

// var fileUpload = null;

const panVal = /([A-Z]){5}([0-9]){4}([A-Z]){1}$/;
export const alphaNumeric = helpers.regex('alphaNumeric',  /^[a-zA-Z ]*$/);
const gstinNo = helpers.regex('gstinNo',/^([0]{1}[1-9]{1}|[1-2]{1}[0-9]{1}|[3]{1}[0-7]{1})([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})+$/)
// const nameChecker = /^[a-zA-Z ]+$/
// const ifscval = /[A-Z|a-z]{4}[0][a-zA-Z0-9]{6}$/;

function checksPanValue(value){
    return(panVal.test(value)) ? true : false;
}



export default {
      components:{
        
    },

    updated(){
        // console.log("%c I am Updated now payment details","color:red");
       
        
        if(this.bankRadioBtn === 'Yes'){
            console.log("this is  the message for bankgurantee",this.bankRadioBtn);
            this.bankDisableValue = false;
        }else{
            console.log("this is  the message for bankgurantee",this.bankRadioBtn);

            this.bankDisableValue = true;
        }

        if(this.paymentRadioBtn === 'Yes'){
            this.payDisableValue = false
        }else{
            this.payDisableValue = true
        }
        
    },

    created() {
            dateIs = moment(this.providerFromDate).clone().add({days:30 ,months:0,years:0});
            let toDateIs = moment(dateIs).format("MM-DD-YYYY")
            this.providerToDate = moment(toDateIs).clone().format("YYYY-MM-DD")
            this.$store.commit("SAVE_PAGE_HEADER", "Provider Quick Empanelment");
    },

    mounted(){
        this.bankRadioBtn='No';
        this.paymentRadioBtn='No';
        
    },
   
  


    validations: {
        providerDetails: { required },
        // networkNumber:{numeric}, 
        providerToDate: { required },
        providerFromDate:{required},
        payeeName: { alphaNumeric },
        bankName: {alphaNumeric},
        micrCode:{alphaNum},
        ifscCode: {alphaNum},
        accountNumber: {numeric},
        panId: {checksPanValue, alphaNum },
        proprietorshipName: {alphaNumeric},
        gstin: {gstinNo},
        bankIfYesAmount:{ required, numeric },
        payeeIfYesAmount:{required, numeric},
        bankDateFrom : { required },
        accountDateFrom : { required },
        bankDateTo : { required },
        accountDateTo : { required },
        
    },

    data() {

        return {
            providerFN:'',
            providerLN:'',
            entityName:'', 
            aliasName:'', 
            branchValue:'',
        
            branch_collection:[],
              bankcollection:[
            "STATE BANK OF HYDERABAD",
    "STATE BANK OF BIKANER AND JAIPUR",
    "A  U  SMALL FINANCE BANK  LTD",
    "A U SMALL FINANCE BANK LIMITED",
    "AANDHRA BANK",
    "AAXIS BANK LTD",
    "ABHYUDAYA CO-OP. BANK LTD.",
    "ACE CO-OPERATIVE BANK LTD.",
    "AHMEDABAD DIST.CO-OP. BANK LTD.",
    "AHMEDABAD DISTRICT CO-OP BANK LTD",
    "AHMEDABAD MERCANTILE CO-OP. BANK LTD.",
    "AHMEDNAGAR MERCHANTS CO OP BANK LTD",
    "AHMEDNAGAR MERCHANTS CO-OP. BANK LTD",
    "ALLAHABAD  BANK",
    "ALLAHABAD BANK",
    "ALLAHBAD  BANK",
    "ALLAHBAD BANK",
    "ALMORA URBAN COOPERATIVE BANK LIMITED",
    "AMBARNATH JAIHIND COOP BANK LTD AMBARNATH",
    "ANDHRA  BANK",
    "ANDHRA BAK",
    "ANDHRA BANK",
    "ANDHRA BANK MANSA",
    "ANDHRA BANK",
    "ANDHRA BANK",
    "ANDHRA PRADESH GRAMEENA BANK",
    "ANDHRA PRADESH GRAMEENA VIKAS BANK",
    "ANDHRA PRADESH MAHESH URBAN BANK LTD.",
    "ANDHRA PRAGATHI GRAMEENA BANK",
    "APNA SAHAKARI BANK LIMITED",
    "APNA SAHAKARI BANK LTD",
    "APNA SAHAKARI BANK LTD.",
    "ASSAM CO-OP. APEX BANK LTD.",
    "AU SMALL FIANACE BANK LTD",
    "AU SMALL FINANCE ANK LTD",
    "AU SMALL FINANCE BANK",
    "AU SMALL FINANCE BANK LIMITED",
    "AU SMALL FINANCE BANK LTD",
    "AU SMALL FINANCE BANK LIMITED",
    "AU SMALL FINANCE BANK LTD.",
    "AURANGABAD DIST.CENTRAL CO-OP. BANK LTD.",
    "AXIS  BANK LTD.",
    "AXIS  BANK LTD",
    "AXIS BANK",
    "AXIS BANK LTD",
    "AXIS BANK LTD.",
    "AXIS BANK LTD",
    "AXIS BANK LTD.",
    "AXIX BANK",
    "BANDHAN BANK",
    "BANDHAN BANK LIMITED",
    "BANDHAN BANK LTD",
    "BANK OF BAHRAIN & KUWAIT BSC",
    "BANK OF BAROAD",
    "BANK OF BARODA",
    "BANK OF BARODA LTD",
    "BANK OF BARODA NEW",
    "BANK OF BARODA.",
    "BANK OF CEYLON",
    "BANK OF INDIA",
    "BANK OF MAHARASHTRA",
    "BANK OF TOKYO-MITSUBISHI UFJ LTD",
    "BARODA CENTRAL CO BANK LTD",
    "BARODA RAJASTHAN BANK",
    "BASSEIN CATHOLIC BANK CO-OP BANK LTD",
    "BASSEIN CATHOLIC BANK LTD",
    "BASSEIN CATHOLIC C OP BANK LTD",
    "BASSEIN CATHOLIC CO-OP. BANK LTD.",
    "BHAGINI NIVEDITA SAHAKARI BANK LTD, PUNE.",
    "BHARAT BANK",
    "BHARAT CO OP BANK LTD",
    "BHARAT COOPERATIVE BANK MUMBAI LIMITED",
    "BHARATIYA MAHILA BANK",
    "BHAVNAGAR DIS CO OP BANK LTD",
    "BHRAMADEODADA MANE SAHAKARI BANK LTD, SOLAPUR",
    "BOMBAY MERCANTILE CO OP BANK LTD",
    "BOMBAY MERCANTILE CO-OP. BANK LTD.",
    "CANANRA BANK",
    "CANARA  BANK",
    "CANARA BANAK",
    "CANARA BANK",
    "CANARA BANK LTD",
    "CANARA BANK.",
    "CAPITAL LOCAL AREA BANK LTD",
    "CAPITAL SMALL FINANCE BANK",
    "CAPITAL SMALL FINANCE BANK LIMITED",
    "CATHOLIC SYRIAN BANK LTD.",
    "CENTRAL BANK OF INDIA",
    "CENTRAL BANK OF INDIA LTD",
    "CENTRAL BANK OF INDIA,",
    "CENTRAL MADHYA PRADESH GRAMIN BANK",
    "CHINATRUST COMMERCIAL BANK",
    "CITIZEN COOPERATIVE BANK LTD",
    "CITIZEN CREDIT CO-OP. BANK LTD.",
    "CITY UNION BANK LIMITED",
    "CITY UNION BANK LTD",
    "CITY UNION BANK LTD.",
    "CLA-CAPITAL LOCAL AREA BANK",
    "COL R D NIKAM SAINIK SAHAKARI BANK LTD",
    "CONTAI COOPERATIVE BANK LIMITED",
    "CORPORATION  BANK",
    "CORPORATION BANK",
    "CORPORATION BANK.",
    "DCB BANK LIMITED",
    "DCB BANK LTD",
    "DECCAN GRAMEENA BANK",
    "DELHI STATE CO-OP. BANK LTD.",
    "DELHI STATE COOPERATIVE BANK",
    "DENA BANK",
    "DENA BANK",
    "DENA GUJARAT GRAMIN BANK",
    "DEOGIRI NAGARI SAHAKARI BANK LTD",
    "DEOGIRI NAGARI SAHAKARI BANK LTD - AURANGABAD",
    "DEUTSCHE BANK",
    "DEUTSCHE BANK LTD.",
    "DEUTSCHE SECURITIES INDIA PRIVATE LIMITED",
    "DEVELOPMENT CREDIT BANK LIMITED",
    "DEVELOPMENT CREDIT BANK LTD",
    "DEVELOPMENT CREDIT BANK LTD.",
    "DHANALAKSHMI BANK LTD.",
    "DMK JAOLI BANK",
    "DOMBIVLI NAGARI SAHAKARI BANK LTD.",
    "DR PANJABRAO DESHMUKH URBAN CO OP BANK LTD",
    "DURGAPUR STEEL PEOPLES CO-OP BANK LTD",
    "ENDRSRANT WITH IDBI BANK LTD",
    "EQUITAS  FINANCE LTD",
    "EQUITAS SMALL  FINANCE BANK LTD",
    "EQUITAS SMALL FINANCE BANK",
    "EQUITAS SMALL FINANCE BANK  LTD",
    "EQUITAS SMALL FINANCE BANK LIMITED",
    "EQUITAS SMALL FINANCE BANK LIMITED.",
    "EQUITAS SMALL FINANCE BANK LTD",
    "ESAF SMALL FINANCE BANK",
    "ESAF SMALL FINANCE BANK LIMITED",
    "FEDERAL  BANK",
    "FEDERAL BANK",
    "FEDERAL BANK LTD",
    "FEDERAL BANK LTD.",
    "FEDRAL BANK",
    "FINO PAYMENTS BANK",
    "G P PARSIK BANK",
    "GANDHIBAGH SAHAKARI BANK LTD.",
    "GOPINATH PATIL PARSIK JANATA SAHAKARI BANK",
    "GOPINATH PATIL PARSIK JANATA SAHAKARI BANK LTD",
    "GRAMIN BANK OF ARYAVART",
    "GUJARAT STATE CO-OP. BANK LTD.",
    "GURGAON GRAMIN BANK",
    "HDFC BANK LTD",
    "HDFC BANK LTD.",
    "HDFC BANK",
    "HDFC BANK LTD",
    "HDFC BANK LTD.",
    "HDFC BANK LTD",
    "HDFC BANK LTD .",
    "HDFC BANK LTD.",
    "HIMACHAL PRADESH STATE CO-OP BANK LTD.",
    "HIMACHAL PRADESH STATE COOPERATIVE BANK LTD",
    "HIMATNAGAR NAGARIK SAHAKARI BANK LTD.",
    "HINDUSIND BANK LTD",
    "HSBC BANK",
    "ICIC BANK",
    "ICIC BANK LD",
    "ICIC BANK LTD",
    "ICICI BANK LTD",
    "ICICI BANK LTD.",
    "ICICI BANK",
    "ICICI BANK LTD",
    "ICICI BANK LTD.",
    "ICICI BANK LIMITED",
    "ICICI BANK LTD",
    "ICICI BANK LTD .",
    "IDBI  BANK",
    "IDBI  BANK LTD",
    "IDBI BANK",
    "IDBI BANK LTD",
    "IDBI BANK LTD .",
    "IDBI BANK LTD.",
    "IDFC BANK LTD",
    "IDFC BANK",
    "IDFC BANK LTD",
    "IDFC BANK LIMITED",
    "IDFC BANK LTD",
    "IDFC FIRST BANK LTD",
    "INDIA POST PAYMENT BANK",
    "INDIAN BANK",
    "INDIAN BANK .",
    "INDIAN OVERSEAS BANK",
    "INDIAN OVERSEAS BANK",
    "INDUSIND BANK LTD.",
    "INDUSIND BANK",
    "INDUSIND BANK LTD",
    "INDUSIND BANK LTD",
    "INDUSIND BANK LTD ALIGARH",
    "INDUSIND BANK LTD.",
    "INDUSLND BANK",
    "INDUSTRIAL BANK OF KOREA",
    "ING VYSYA BANK LTD",
    "INSUSIND BANK LTD BANGALORE",
    "J&K BANK",
    "JALGAON JANATA SAHKARI BANK LTD.",
    "JALGAON JANTA SAHAKARI BANK LTD.",
    "JAMMU & KASHMIR BANK LTD.",
    "JANA SMALL FINANCE BANK LTD",
    "JANASEVA SAHAKARI BANK (BORIVALI) LTD",
    "JANATA SAHAKARI BANK LIMITED",
    "JANATA SAHAKARI BANK LTD",
    "JANATA SAHAKARI BANK LTD",
    "JANATA SAHAKARI BANK LTD PUNE",
    "JANSEVA SAHAKARI BANK LTD.",
    "JHARKHAND STATE CO-OPERATIVE BANK LTD",
    "KALLAPPANNA AWADE ICHALKARANJI JANATA SAHAKARI BANK LIMITED",
    "KALUPUR COMMERCIAL CO OP BANK LTD",
    "KALYAN JANATA SAH.BANK",
    "KANGRA CO-OPERATIVE BANK LTD.",
    "KAPOLE CO-OP. BANK LTD.",
    "KARNATAKA BANK",
    "KARNATAKA BANK LIMITED",
    "KARNATAKA BANK LTD",
    "KARNATAKA BANK LTD.",
    "KARNATAKA VIKAS GRAMEENA BANK",
    "KARUR VYSYA BANK",
    "KARUR VYSYA BANK LTD.",
    "KAVERI GRAMEENA BANK",
    "KERALA GRAMIN BANK",
    "KOLHAPUR URBAN CO-OP. BANK LTD.",
    "KOTAK  MAHINDRA  BANK LTD",
    "KOTAK MAHINDER BANK",
    "KOTAK MAHINDRA BANK LTD",
    "KOTAK MAHINDRA BANK",
    "KOTAK MAHINDRA BANK LTD",
    "KOTAK MAHINDRA BANK LTD.",
    "KOTAK MAHINDRA BANK .",
    "KOTAK MAHINDRA BANK LIMITED",
    "KOTAK MAHINDRA BANK LTD",
    "KOTAK MAHINDRA BANK LTD.",
    "KRISHNA MERCANTILE CO-OP. BANK LTD., BHOPAL",
    "KRISHNA SAHAKARI BANK LTD,RETHARE BUDRUK",
    "KRUNG THAI BANK PUBLIC COMPANY LTD",
    "LAKSHMI VILAS  BANK",
    "LAKSHMI VILAS BANK LTD.",
    "LATUR URBAN CO-OP. BANK LTD, LATUR",
    "LAXMI VILAS BANK",
    "LAXMI VILAS BANK LTD",
    "M/S STATE BANK OF INDIA",
    "MADHYAPRADESH GRAMIN BANK",
    "MAHANAGAR CO OP BANK LTD",
    "MAHARASHTRA GRAMIN BANK",
    "MAHARASHTRA GRAMIN BANK LTD",
    "MAHARASHTRA STATE CO OPERATIVE BANK",
    "MAHARASHTRA STATE CO-OP.BANK LTD.",
    "MAHESH SAHAKARI BANK LTD PUNE",
    "MANORAMA CO-OP. BANK LTD., SOLAPUR.",
    "MEHSANA URBAN CO OP BANK LTD",
    "MEHSANA URBAN CO-OP. BANK LTD",
    "MIZUHO CORPORATE BANK LTD.",
    "MODEL CO-OP BANK LTD",
    "MUMBAI DISTRICT CENTRAL CO-OP BANK LTD",
    "MUMBAI DISTRICT CENTRAL CO-OP BANK LTD.",
    "MUZAFFARNAGAR DISTRICT CO-OPERATIVE BANK LTD.",
    "NAGAR URBAN CO OPERATIVE BANK",
    "NAGPUR NAGRIK SAHAKARI BANK LTD",
    "NKGSB CO-OP BANK LTD",
    "NORTH EAST SMALL FINANCE BANK LIMITED",
    "NORTH MALABAR GRAMIN BANK",
    "OORIENTAL BANK OF COMMERCE",
    "OSMANABAD JANATA SAHAKARI BANK LTD",
    "PALLAVAN GRAMA BANK",
    "POST OFFICE SAVINGS BANK",
    "PRAGATHI KRISHNA GRAMIN BANK",
    "PRAGATHI KRISHNA GRAMIN BANK LTD",
    "PRATHAMA BANK",
    "PRATHAMA BANK LTD",
    "PRIME CO OPERATIVE BANK LTD",
    "PRIME CO-OP. BANK LTD.",
    "PUNE DISTRICT CENTRAL CO-OP BANK LTD",
    "PUNE MERCHANTS' CO-OPERATIVE BANK LTD.",
    "PUNE PEOPLE'S CO-OP. BANK LTD",
    "PUNJAB  NATIONAL BANK",
    "PUNJAB & MAHARASHTRA CO OP BANK LTD",
    "PUNJAB & SIND  BANK",
    "PUNJAB & SIND BANK",
    "PUNJAB AND MAHARASHATRA CO OP BANK",
    "PUNJAB AND MAHARASHTRA CO OP BANK LTD",
    "PUNJAB AND MAHARASHTRA COOP BANK LTD",
    "PUNJAB AND SIND BANK",
    "PUNJAB NATIONAL BANK",
    "PUNJAB NATIONAL BAK LTD",
    "PUNJAB NATIONAL BANK",
    "RAJARAMBAPU   SAHAKARI  BANK   LTD",
    "RAJARAMBAPU SAHAKARI BANK LTD",
    "RAJARSHI SHAHU SAHAKARI BANK LTD PUNE",
    "RAJASTHAN GRAMIN BANK",
    "RAJASTHAN MARUDHARA  GRAMIN BANK",
    "RAJASTHAN MARUDHARA GRAMIN BANK",
    "RAJASTHAN MARUDHARA GRAMIN BANK LTD",
    "RAJGURUNAGAR SAHAKARI BANK LTD.",
    "RAJKOT NAGARIK SAHAKARI BANK LTD",
    "RATANCHAND SHAH CO OP BANK LTD MANGAWEDHA",
    "RATNAKAR BANK LTD",
    "RBL BANK",
    "RBL BANK LIMITED",
    "REINSTATEMENT CRS",
    "RESERVE BANK EMPLOYEES CO-OP. BANK LTD.",
    "SAMARTH SAHAKARI BANK LTD., SOLAPUR",
    "SAMATA SAHAKARI BANK LIMITED",
    "SARASPUR NAGRIK CO OPERATIVE BANK LTD",
    "SARASWAT CO-OP. BANK LTD.",
    "SARASWAT COOPERATIVE BANK LTD",
    "SATARA SAHAKARI BANK LTD",
    "SBI",
    "SHAMRAO VITHAL CO OP BANK LTD",
    "SHIKSHAK SAHAKARI BANK LTD., NAGPUR",
    "SHINHAN BANK",
    "SHIVAJI NAGARI SAHAKARI BANK LTD, PAITHAN",
    "SHIVALIK MERCANTILE CO OP BANK LTD",
    "SHIVALIK MERCANTILE CO-OP BANK LTD",
    "SHIVDAULAT SAHAKARI BANK LTD",
    "SHREE MORBI NAGARIK SAHAKARI BANK LTD",
    "SHREENATH CO-OPERATIVE BANK LTD",
    "SHREYAS GRAMIN BANK",
    "SHRI CHHATRAPATI RAJARSHI SHAHU URBAN CO OP BANK LTD",
    "SHRI CHHATRAPATI RAJASHRI SHAHU URBAN COOPERATIVE BANK LIMITED",
    "SHRI LAXMI MAHILA SAHAKARI BANK LTD., SANGLI",
    "SHRI PATNESHWAR URBAN CO-OP. BANK LTD.",
    "SHRI VEERSHAIV CO OP BANK LTD",
    "SIR M VISVESVARAYA CO OPERATIVE BANK LTD",
    "SOLAPUR JANATA SAH  BANK LTD.",
    "SOLAPUR JANATA SAHAKARI BANK LTD.",
    "SOUTH INDIAN BANK",
    "SOUTH INDIAN BANK LTD",
    "SOUTH INDIAN BANK LTD.",
    "STANDARD CHARTERED BANK",
    "STANDARD URBAN CO-OP BANK, (AURANGABAD) LTD.",
    "STATE  BANK OF INDIA",
    "STATE BANK  OF INDIA",
    "STATE BANK INDIA",
    "STATE BANK OF  INDIA",
    "STATE BANK OF BIKANER & JAIPUR",
    "STATE BANK OF BIKANER & JAIPUR.",
    "STATE BANK OF BIKANER AND JAIPUR",
    "STATE BANK OF HYDERABAD",
    "STATE BANK OF INDIA",
    "STATE BANK OF INDIA   DADAR",
    "STATE BANK OF INDIA LTD",
    "STATE BANK OF INDIA,",
    "STATE BANK OF INDIA.",
    "STATE BANK OF MYSORE",
    "STATE BANK OF PATIALA",
    "STATE BANK OF SAURASHTRA",
    "STATE BANK OF TRAVANCORE",
    "SUNDARLAL SAWJI URBAN CO OP BANK LTD",
    "SURAT DIST.CO-OP. BANK LTD.",
    "SURAT NATIONAL CO - OP BANK LIMITED",
    "SURAT NATIONAL CO OP  BANK LTD",
    "SURYODAY SMALL FINANCE BANK LIMITED",
    "SUTEX COOPERATIVE BANK LIMITED",
    "SUVARNAYUG SAHAKARI BANK LTD",
    "SYNDICATE  BANK",
    "SYNDICATE BANK",
    "TAMILNAD MERCANTILE BANK  LTD",
    "TAMILNAD MERCANTILE BANK LIMITED",
    "TAMILNAD MERCANTILE BANK LTD",
    "TAMILNADU MERCANTILE BANK LTD.",
    "TELANGANA STATE COOP APEX BANK",
    "TELANGANA STATE COOPERATIVE APEX BANK LTD",
    "TEXTILE CO-OPERATIVE BANK LTD.",
    "TEXTILE TRADERS CO OP BANK LTD",
    "THANE BHARAT SAHAKARI BANK LTD. THANE",
    "THANE JANATA SAHAKARI BANK LTD.",
    "THE AHMEDNAGAR DIST CENTRAL CO OP BANK LTD",
    "THE AHMEDNAGAR MERCHANT'S CO.OP.BANK LTD",
    "THE AKOLA DISTRICT CENTRAL COOPERATIVE BANK",
    "THE AKOLA JANATA COMMERCIAL CO-OPERATIVE BANK LTD.",
    "THE AKOLA JANATA COMMERCIAL COOPERATIVE BANK LTD",
    "THE ANDHRA PRADESH STATE COOPERATIVE BANK LIMITED",
    "THE ANDHRA PRADESH STATE COOPERITIVE BANK LTD",
    "THE BANASKANTHA DIST CENTRAL CO-OP BANK LTD",
    "THE BARAMATI SAHAKARI BANK LTD",
    "THE BARAMATI SAHAKARI BANK LTD .",
    "THE BARODA CENTRAL CO-OPERATIVE BANK LTD",
    "THE BHADRAN PEOPLE CO OP BANK LTD",
    "THE BHAGYODAYA CO-OPERATIVE BANK LTD.",
    "THE BHARAT  CO OP BANK LTD",
    "THE BHARAT CO OP BANK ( MUMBAI) LTD",
    "THE BHARAT CO-OP. BANK LTD.",
    "THE BHARAT COOP BANK LTD",
    "THE BHARAT COOPERATIVE BANK MUMBAI LTD",
    "THE CHANDIGARH STATE CO-OPERATIVE BANK LTD",
    "THE CITY CO-OP. BANK LTD.",
    "THE CO OP BANK OF RAJKOT",
    "THE COSMOS CO OP BANK LIMITED",
    "THE COSMOS CO-OPERATIVE BANK LTD",
    "THE DAHANU ROAD JANTA BANK",
    "THE DHINOJ NAG SAH BANK",
    "THE FEDERAL  BANK LTD",
    "THE FEDERAL BANK LTD",
    "THE FEDERAL BANK LTD .",
    "THE FEDERAL BANK LTD,",
    "THE FINANCIAL CO-OPERATIVE BANK LTD.",
    "THE GODHRA URBAN CO OP BANK LTD",
    "THE GUJARAT INDUSTRIAL CO OP BANK LTD",
    "THE GUJARAT STATE CO OP BANK LTD",
    "THE GUJARAT STATE CO OP. BANK LTD.",
    "THE GUJARAT STATE CO OPERATIVE BANK LIMITED",
    "THE GUJARAT STATE CO-OP BANK LTD.",
    "THE GUJARAT STATE CO-OP BANK LTD.,",
    "THE GUJARAT STATE CO-OPERATIVE BANK LTD,",
    "THE H.P. STATE CO-OP. BANK LTD.",
    "THE HASTI COOP BANK LTD",
    "THE JALGAON PEOPELS COOPERATIVE BANK LIMITED",
    "THE JALGAON PEOPLES COOPERATIVE BANK LTD",
    "THE JAMMU AND KASHMIR BANK",
    "THE JAMMU AND KASHMIR BANK LTD",
    "THE JAMMU CENTRAL COOPERATIVE BANK LTD",
    "THE JUNAGADH JILLA SAHAKARI BANK LTD.",
    "THE KALUPUR COMMERCIAL CO OP BANK LTD",
    "THE KALUPUR COMMERCIAL CO-OPERATIVE BANK LTD,",
    "THE KALUPUR COMMERCIAL CO. OP. BANK LTD.",
    "THE KALYAN JANATA SAHAKARI BANK LTD",
    "THE KALYAN JANATA SAHAKARI BANK LTD.",
    "THE KANGRA CENTRAL COOPERATIVE BANK LIMITED",
    "THE KARAD URBAN  CO OP BANK  LTD",
    "THE KARAD URBAN CO-OP BANK LTD",
    "THE KARNATAKA STATE APEX CO OP BANK LTD",
    "THE KARNATAKA STATE CO OP APEX BANK LTD",
    "THE KARNATAKA STATE CO-OPERATIVE APEX BANK LTD, BANGALORE",
    "THE KARUR  VYSYA BANK LTD",
    "THE KERALA STATE CO OPERATIVE BANK",
    "THE KHAMGAON URBAN COOPERATIVE BANK LTD",
    "THE KHATTRI CO-OPERATIVE URBAN BANK LTD",
    "THE KOPARGAON PEOPLES CO OP BANK LTD",
    "THE KURMANCHAL NAGAR SAHAKARI BANK LIMITED",
    "THE LAKSHMI VILAS BANK LTD",
    "THE LATUR DISTRICT CENTRAL CO-OP BANK LTD",
    "THE LIMDI URBAN CO OP BANK LTD",
    "THE MAHANAGAR CO-OP BANK LTD",
    "THE MAHANAGAR CO-OP. BANK LTD.",
    "THE MAHANAGAR COOP.BANK LTD.",
    "THE MALKAPUR URBAN CO OP BANK LTD",
    "THE MALKAPUR URBAN CO-OP. BANK LTD.",
    "THE MEHSANA DIST. CENTRAL CO-OPERATIVE BANK LTD.",
    "THE MEHSANA URBAN COOPERATIVE BANK LTD",
    "THE MUMBAI DISTRICT CENTRAL COOPERATIVE BANK LIMITED",
    "THE MUNICIPAL COOPERATIVE BANK LIMITED",
    "THE MUSLIM CO OP BANK LTD",
    "THE MUSLIM CO-OP BANK LTD",
    "THE NAINITAL BANK LIMITED",
    "THE NARODA NAGRIK CO-OP BANK LTD",
    "THE NASIK MERCHANTS CO-OP. BANK LTD.",
    "THE NAV JEEVAN CO OP BANK LTD",
    "THE NAVAL DOCKYARD CO-OP BANK LTD.",
    "THE NAVNIRMAN CO BANK LTD",
    "THE NAVNIRMAN CO OPERATIVE  BANK LTD",
    "THE PANDHARPUR URBAN BANK CO-OP BANK LTD",
    "THE PANDHARPUR URBAN CO OP BANK LTD",
    "THE PANDHARPUR URBAN CO-OP BANK LTD",
    "THE PRAGATI CO OP BANK LTD",
    "THE RAJASTHAN STATE COOPERATIVE BANK LIMITED",
    "THE RATNAKAR BANK LTD",
    "THE RATNAKAR BANK LTD.",
    "THE ROYAL BANK OF SCOTLAND NV",
    "THE SARASWAT  CO-OP BANK LTD.",
    "THE SARASWAT CO - OP BANK LTD",
    "THE SARASWAT CO OP  BANK LTD",
    "THE SARASWAT COOPERATIVE BANK LTD",
    "THE SARDARGANJ MERCANTILE CO OP BANK LTD",
    "THE SARDARGUNJ MARCANTILE CO-OP BANK LTD",
    "THE SEVA VIKAJ CO OP BANK LTD",
    "THE SHAMRAO  VITTHAL  CO OP   BANK  LTD",
    "THE SHAMRAO VITHAL CO OP BANK LTD",
    "THE SHAMRAO VITHAL CO OPERATIVE BANK LTD",
    "THE SHAMRAO VITHAL CO-OPERATIVE BANK LIMITED",
    "THE SHAMRAO VITHAL COOPERATIVE BANK LIMITED",
    "THE SINDHUDURG DISTRICT CENTRAL CO-OP BANK LTD,SINDHUDURG.",
    "THE SOUTH INDIAN BANK LTD",
    "THE SURAT DISTRICT CO OP BANK LTD.",
    "THE SURAT PEOPLES BANK LTD",
    "THE TALOD NAGARIK SAHAKARI BANK LTD",
    "THE TAMILNADU STATE APEX CO OP BANK LTD",
    "THE TAMILNADU STATE APEX COOPERATIVE BANK LIMITED",
    "THE THANE BHARAT SAHAKARI BANK LIMITED",
    "THE THANE DISTRICT CENTRAL CO-OP. BANK LTD.",
    "THE THANE JANATA SAHAKARI BANK LTD",
    "THE UCO BANK",
    "THE UDAIPUR URBAN CO OPERATIVE BANK LTD",
    "THE VARACHHA CO -OP BANK LTD",
    "THE VARACHHA CO OPERATIVE BANK LTD",
    "THE VIJAY CO OP BANK LTD",
    "THE VIJAY CO OPERATIVE BANK LTD",
    "THE VIJAY CO. OPERATIVE BANK LTD",
    "THE VIRAMGAM MERCANTILE CO-OP BANK LTD.",
    "THE WEST BENGAL STATE COOPERATIVE BANK LTD",
    "THE ZOROASTRIAN CO OP BANK LTD",
    "THE ZOROASTRIAN CO OPERATIVE BANK LTD",
    "THE ZOROASTRIAN CO-OPERATIVE BANK LTD",
    "THRISSUR DISTRICT CO-OPERATIVE BANK LTD",
    "TJSB SAHAKARI BANK LTD",
    "TJSB SAHAKARI BANK LTD.",
    "TUMKUR GRAIN MERCHANT CO-OP. BANK",
    "UCO BANK",
    "UCO BANK  LTD",
    "UCO BANK.",
    "UJJIVAN SMALL FINANCE BANK",
    "UJJIVAN SMALL FINANCE BANK LIMITED",
    "UJJIVAN SMALL FINANCE BANK LTD",
    "UNION BANK OF INDIA",
    "UNITED BANK OF INDIA",
    "UNITED BANK OF INDIA (LKO)",
    "URBAN CO OP BANK YADAWAD",
    "UTKARSH SMALL FINANCE BANK",
    "UTTAR PRADESH CO-OP BANK LTD BANK",
    "UTTARANCHAL GRAMIN BANK",
    "VARACHHA CO-OP. BANK LTD.",
    "VASAI JANATA SAHAKARI BANK LTD",
    "VASAI VIKAS SAHAKARI BANK LIMITED",
    "VIJAYA BANK",
    "VIJAYA BANK.",
    "VISHWESHWAR SAHAKARI BANK",
    "WEST BENGAL STATE CO-OP. BANK LTD.",
    "WOORI BANK",
    "YES BANK",
    "YES BANK  LTD",
    "YES BANK LTD",
    "YES BANK LTD.",
    "ZILA SAHAKRI BANK LIMITED GHAZIABAD"
        ],




            entries: [],
            isLoading: false,
            
            searchApi: null,

            fromCalenderDate:false,
            providerFromDate: new Date().toISOString().substr(0, 10),

            toCalenderDate:false,
            providerToDate: '',

            bankCalenderDateFrom:false,
            bankDateFrom:'',

            bankCalenderDateTo:false,
            bankDateTo:'',


            accountCalenderDateFrom:false,
            accountDateFrom:'',

            accountCalenderDateTo:false,
            accountDateTo:'',

            
            // providerDetails: '',
            providerDetails: '',

            prn:'',
            providerNetwork:'',
            regEntity:'',
            networkNumber:'',
            dateFrom:'',
            dateTo: '',
            payeeName: '',
            bankName: '',
            branch: '',
            micrCode: '',
            ifscCode: '',
            typeOfAccount: '',
            accountNumber: '',
            panId: '',
            proprietorshipName: '',
            gstin: '',
            bankIfYesAmount: '',
            payeeIfYesAmount: '',
            account: ['Current','Saving','CC'],
            
           
            bankRadioBtn: 'No',
            bankDisableValue: true,

            paymentRadioBtn: 'No',
            payDisableValue: true,
            
        }
    },

    computed: {
        
      
        
        searchedItems() {
            console.log("this is from searching api",this.entries[0]);
            return this.entries;
        },

        computedProviderDateForm () {
            console.log(this.providerFromDate)
            console.log(this.providerFromDate ? moment(this.providerFromDate).format("DD-MM-YYYY"): '')
            return this.providerFromDate ? moment(this.providerFromDate).format("DD-MM-YYYY"): ''
        },

        computedProviderDateTo() {  
            // console.log(this.providerToDate)
            console.log(this.providerToDate ? moment(this.providerToDate).format("DD-MM-YYYY"): '')
            return this.providerToDate ? moment(this.providerToDate).format("DD-MM-YYYY"): ''  

        },

        computedBankDateForm() {
            console.log(this.bankDateFrom)
            console.log(this.bankDateFrom ? moment(this.bankDateFrom).format("DD-MM-YYYY"): '')
            return this.bankDateFrom ? moment(this.bankDateFrom).format("DD-MM-YYYY"): ''
         },

        computedBankDateTo() {
            console.log(this.bankDateTo)
            console.log(this.bankDateTo ? moment(this.bankDateTo).format("DD-MM-YYYY"): '')
            return this.bankDateTo ? moment(this.bankDateTo).format("DD-MM-YYYY"): ''
        },

        computedAccountDateForm() {
            console.log(this.accountDateFrom)
            console.log(this.accountDateFrom ? moment(this.accountDateFrom).format("DD-MM-YYYY"): '')
            return this.accountDateFrom ? moment(this.accountDateFrom).format("DD-MM-YYYY"): ''
         },

        computedAccountDateTo() {
            console.log(this.accountDateTo)
            console.log(this.accountDateTo ? moment(this.accountDateTo).format("DD-MM-YYYY"): '')
            return this.accountDateTo ? moment(this.accountDateTo).format("DD-MM-YYYY"): ''
        },

        providerErrors(){
            const errors = [];
            // let check = this.$v.providerDetails.$model;
            // if(check.length > 1) return errors
            if (!this.$v.providerDetails.$dirty) return errors   
            !this.$v.providerDetails.required && errors.push('This field is required');
            return errors;
        },

        providerToDateErrors(){ 
           let toDate = this.$v.providerToDate.$model;
            let fromDate = this.$v.providerFromDate.$model;
            const errors = []
           
            // if(toDate.length > 0){
                if (!this.$v.providerToDate.$dirty) return errors   
                if(!moment(toDate).isAfter(fromDate))
                errors.push('To* date must be greater than From* date');
            // }
           
            return errors

        },


        payeeErrors(){
            const errors = []
            
            if (!this.$v.payeeName.$dirty) return errors
            !this.$v.payeeName.alphaNumeric && errors.push('Only alphabets are allowed');
            return errors;
        },

        bankNameErrors(){
            const errors = []
            
            if (!this.$v.bankName.$dirty) return errors
            // !this.$v.bankName.alphaNumeric && errors.push('Only alphabets are allowed');
            return errors;
        },

        micrCodeErrors(){
            const errors = []
            
            if (!this.$v.micrCode.$dirty) return errors
            !this.$v.micrCode.alphaNum && errors.push('MIRC should be AlphaNumeric');
            
            return errors;
        },


        // branchErrors(){
        //     const errors = []
            
        //     if (!this.$v.branch.$dirty) return errors

        //     return errors;
        // },

        ifscCodeErrors(){
            const errors = []
            
            if (!this.$v.ifscCode.$dirty) return errors
            !this.$v.ifscCode.alphaNum && errors.push('IFSC should be AlphaNumeric');

            return errors;
        },

        // typeOfAccountErrors(){
        //     const errors = []
            
        //     if (!this.$v.typeOfAccount.$dirty) return errors
        //     !this.$v.typeOfAccount.required && errors.push('Please Select Type of Account');

        //     return errors;
        // },

        accountNumberErrors(){ 
            const errors = []
            
            if (!this.$v.accountNumber.$dirty) return errors
            !this.$v.accountNumber.numeric && errors.push('Account Number should be Numeric');

            return errors;
        },

        panErrors(){ 
            const errors = []

            const value = this.$v.panId.$model;
            console.log("%c value of pan is","background-color:red",value);
            if (!this.$v.panId.$dirty) return errors
            !this.$v.panId.alphaNum && errors.push('Pan Number Should be AlphaNumeric.');
            if(value === undefined || value === null){
                console.log("pan value is undefined");
                return ""
            }
            if(value.length > 1){
                !this.$v.panId.checksPanValue && errors.push('Please Enter Valid PanId');
            }
            return errors;
        },

        proprietorshipNameErrors(){
            const errors = []
            
            if (!this.$v.proprietorshipName.$dirty) return errors
            !this.$v.proprietorshipName.alphaNumeric && errors.push('Numbers and Special Characters are not allowed');

            return errors;
        },

        gstinErrors(){ 
                const errors = []
            
            if (!this.$v.gstin.$dirty) return errors
            !this.$v.gstin.gstinNo && errors.push('Invalid GSTIN Number');

            return errors;
        },

        
        // networkNumberErrors(){
        //     const errors = [];
        //     if (!this.$v.networkNumber.$dirty) return errors            
        //     !this.$v.networkNumber.numeric && errors.push('Only Numbers are Allowed');
        //     return errors;
        // },

        bankGuranteeFromErrors(){
             const errors = [];
                if (this.bankDisableValue === false){
                    console.log("this is invalid details", this.$v.bankDateFrom.$invalid);
                    if (!this.$v.bankDateFrom.$dirty) return errors
                    !this.$v.bankDateFrom.required && errors.push('This field is required');
                     return errors
                }else{
                    return errors;
                }
        },

        bankGuranteeToErrors(){
            let btoDate = this.$v.bankDateTo.$model;
            let bfromDate = this.$v.bankDateFrom.$model;
            const errors = [];
                if (this.bankDisableValue === false) { 
                            if (!this.$v.bankDateTo.$dirty) return errors
                            !this.$v.bankDateTo.required && errors.push('This field is required');
                            if(!moment(btoDate).isAfter(bfromDate))
                            errors.push('To* date must be greater than From* date');
                        
                }
                    
                return errors
        },

         bankIfYesAmountErrors(){
                const value = this.$v.bankIfYesAmount.$model;
                const errors = [];
                    if (this.bankDisableValue === false) { 
                        if (!this.$v.bankIfYesAmount.$dirty) return errors
                        !this.$v.bankIfYesAmount.required &&errors.push('This field is required');
                        !this.$v.bankIfYesAmount.numeric && errors.push('Only Numbers are Allowed');
                    }
                    
                return errors;
        },

        accPaymentFromErrors(){
            const errors = []
                
                if (this.payDisableValue === false){
                    if (!this.$v.accountDateFrom.$dirty) return errors
                    !this.$v.accountDateFrom.required && errors.push('This field is required');
                    
                }
                    
                return errors
        },

        accPaymentToErrors(){
            let accpaytoDate = this.$v.accountDateTo.$model;
            let accpayfromDate = this.$v.accountDateFrom.$model;
            const errors = []
           
                if (this.payDisableValue === false){
                        if (!this.$v.accountDateTo.$dirty) return errors
                        !this.$v.accountDateTo.required && errors.push('This field is required');
                        if(!moment(accpaytoDate).isAfter(accpayfromDate))
                        errors.push('To* date must be greater than From* date');     
                }
                 
                return errors
            

        },
        
       

        payeeIfYesAmountErrors(){
                
                const value = this.$v.payeeIfYesAmount.$model;
                const errors = [];
                    if (this.payDisableValue === false) { 
                        if (!this.$v.payeeIfYesAmount.$dirty) return errors
                        !this.$v.payeeIfYesAmount.required &&errors.push('This field is required');
                        !this.$v.payeeIfYesAmount.numeric && errors.push('Only Numbers are Allowed');
                    }
                            
                    
                return errors;
        }
        
         
    },

    methods: {

        setthedata(indata){
            // console.log(" indata ifsc and mcir", indata);
            // return
            if(indata.hasOwnProperty('ifsc')){
               
                 this.ifscCode = indata.ifsc;
            }else{
                 this.ifscCode = ''
            }

            if(indata.hasOwnProperty('micrCode')){
                 this.micrCode = indata.micrCode;
            }else{
                 this.micrCode = ''
            }
           
           

        },

        grab_bankname(indata, ident, val){
            // gb99
            
            console.log(" val --> ", val);
            console.log(ident, " indata --> ", indata);
            try{

                    this.GET(3,'bank-details/branch-by-bank?bank='+indata , (res, error)=>{
                        this.branch_collection = []
                        // console.log("res is ---> ", res.data.data.data);
                        this.branch_collection = res.data.data.data;
                        if(ident == 1){
                            console.log(ident +"from ident 1 ", val);
                            var poss =  this.branch_collection.findIndex(e=> e.branch == val)
                              this.branch = this.branch_collection[poss];
                            //   this.branch = val
                        }
                        console.log( this.branch_collection," brc length is ---> ", this.branch_collection.length);                     

                    })

            }catch(error){
                  console.log("branch error is ---> ", error);
            }
          
            // this.branch_collection = []

        },
        changeData(){
            console.log("changing bankData");
            this.ifscCode = ''
            this.micrCode = ''
        },


    submitDetails(){
        console.log("save button is working");
        console.log(providerIdForEmp);
        var proEmpFormData = { 
                   
                    "empanelmentProvider": providerIdForEmp,
                    "prn": this.prn,
                    "providerNetwork": this.providerNetwork,
                    "networkNumber": this.networkNumber, //number
                    "from": this.providerFromDate,
                    "to": this.providerToDate,
                    "registeredEntity": registeredEntity,
                    "providerType": providerType,
                    "firstName": this.providerFN,
                    "lastName": this.providerLN,
                    "entityName":this.entityName,
                    "aliasName": this.aliasName,
                    "paymentDetails": {
                        "payeeName": this.payeeName,
                        "bankName": this.bankName,
                        "micrCode": this.micrCode, //string
                        "ifscCode": this.ifscCode,
                        "typeOfAccount": this.typeOfAccount,
                        "accountNumber": this.accountNumber, //number
                        "pan": this.panId === null || this.panId === undefined ? '' : this.panId,
                        "branch": this.branch,
                        "proprietorshipName": this.proprietorshipName,
                        "gstin": this.gstin,
                        "bankGurantee": this.bankRadioBtn,
                        "bankAmount": this.bankIfYesAmount, //number
                        "validityFrom": this.bankDateFrom,
                        "validityTo": this.bankDateTo,
                        "advancePayment": this.paymentRadioBtn,
                        "advanceAmount": this.payeeIfYesAmount, //number
                        "advanceValidityFrom": this.accountDateFrom,
                        "advanceValidityTo": this.accountDateTo
                        }
                    }
   
           this.$v.$touch();

            if(this.$v.providerDetails.$invalid || this.providerToDateErrors.length > 0 || this.panErrors.length > 0){
                // console.log("length of providertodatelength is --->",this.providerToDateErrors.length);
                console.log("It is not valid form");
                this.showToast("Please fill required fields",this.TOST().WARNING)
            }else{  
                console.log("It is valid form");
                 if(this.bankGuranteeFromErrors.length > 0 || 
                    this.bankIfYesAmountErrors.length > 0 ||
                    this.bankGuranteeToErrors.length > 0 ||
                    this.accPaymentFromErrors.length > 0 ||
                    this.accPaymentToErrors.length > 0 ||
                    this.payeeIfYesAmountErrors.length > 0){   
                    console.log("It is not valid form");
                 }else{
                        console.log("It is valid form");
                        this.POST(2,'empanelment/create-quickeampanelment-provider',proEmpFormData,
                            ( res, error) => {
                             if(!error){
                                    console.log(res.data);
                                    this.showToast(res.data.msg ,this.TOST().SUCCESS);
                            }else{
                                    console.log("when status is not 200", error.response.data.msg);
                                    this.showToast(error.response.data.msg ,this.TOST().ERROR);
                            }
                                    console.log("APi response from |-> provider/create-provider-individual ", res);
                                });
                        }
                 }
              
            

               
                      
        },



    getProviderDetails(){
            
            // console.log("searching for provider");
            console.log("this is provider from get provider details",this.providerDetails.name);

            if(this.providerDetails === ''){
                return   this.showToast("Please Select Valid Provider" ,this.TOST().WARNING);
            }
           
            this.GET(2,'provider/search-providers-by-name/'+this.providerDetails.name+'?'+'status=QuickEmpanelment',
            
                    (res,err) => {
                        if(!err){
                            console.log("this is provider from get provider details",this.providerDetails);
                            console.log(res.data.data.data[0]);
                            if(res.data.data.data[0].registeredEntity === "Individual"){
                                this.providerFN = res.data.data.data[0].firstName;
                                this.providerLN = res.data.data.data[0].lastName;
                            }else{
                                this.entityName = res.data.data.data[0].entityName
                                this.aliasName =  res.data.data.data[0].aliasName
                            }
                            let id = res.data.data.data[0]._id;
                            this.GET(2,'empanelment/empanelment-provider-by-id/'+id, (res,err) => {
                                            if(!err){
                                                
                                                if(res.data.data.empanelment === null){
                                                    console.log("user has not entered payment details yet");
                                                       this.clearPaymentDetails();         
                                                    return
                                                }else{
                                                        let dataFromEmp = res.data.data.empanelment.paymentDetails
                                                        console.log("this is quickEmpanelment paymentDetails details with providerid",dataFromEmp);
                                                        console.log("this is quickEmpanelment paymentDetails status",res.data.data.networkDetails.networkName);
                                                        // this.providerFN = provider.firstName;
                                                        // this.providerLN = provider.lastName;
                                                        if(res.data.data.hasOwnProperty('networkDetails')){
                                                                this.providerNetwork = this.chkplndata(res.data.data.networkDetails.networkName);
                                                                this.networkNumber = this.chkplndata(res.data.data.networkDetails.networkId);
                                                        }
                                                        if(res.data.data.empanelment.hasOwnProperty('paymentDetails')){
                                                            console.log("paymentdetails is present");
                                                            this.payeeName = dataFromEmp.hasOwnProperty('payeeName')?this.chkplndata(dataFromEmp.payeeName) : '';
                                                            this.bankName = dataFromEmp.hasOwnProperty('bankName')?this.chkplndata(dataFromEmp.bankName) : '';
                                                            this.branch = dataFromEmp.hasOwnProperty('branch')?this.chkplndata(dataFromEmp.branch) : ''
                                                            this.micrCode = dataFromEmp.hasOwnProperty('micrCode')?this.chkplndata(dataFromEmp.micrCode) : ''
                                                            this.ifscCode = dataFromEmp.hasOwnProperty('ifscCode')?this.chkplndata(dataFromEmp.ifscCode) : ''
                                                            this.typeOfAccount = dataFromEmp.hasOwnProperty('typeOfAccount')?this.chkplndata(dataFromEmp.typeOfAccount) : ''
                                                            this.accountNumber = dataFromEmp.hasOwnProperty('accountNumber')?this.chkplndata(dataFromEmp.accountNumber) : ''
                                                            this.panId = dataFromEmp.hasOwnProperty('pan')?this.chkplndata(dataFromEmp.pan) : ''
                                                            this.proprietorshipName = dataFromEmp.hasOwnProperty('proprietorshipName')?this.chkplndata(dataFromEmp.proprietorshipName) : ''
                                                            this.gstin = dataFromEmp.hasOwnProperty('gstin')?this.chkplndata(dataFromEmp.gstin) : ''
                                                            this.bankRadioBtn = dataFromEmp.hasOwnProperty('bankGurantee')?this.chkplndataradio(dataFromEmp.bankGurantee) : ''
                                                            this.bankIfYesAmount =  dataFromEmp.hasOwnProperty('bankAmount')?this.chkplndata(dataFromEmp.bankAmount) : ''
                                                            this.bankDateFrom = dataFromEmp.hasOwnProperty('validityFrom')?this.chkplndata(dataFromEmp.validityFrom) : ''
                                                            this.bankDateTo = dataFromEmp.hasOwnProperty('validityTo')?this.chkplndata(dataFromEmp.validityTo) : ''
                                                            this.paymentRadioBtn = dataFromEmp.hasOwnProperty('advancePayment')?this.chkplndataradio(dataFromEmp.advancePayment) : ''
                                                            this.payeeIfYesAmount = dataFromEmp.hasOwnProperty('advanceAmount')?this.chkplndata(dataFromEmp.advanceAmount) : ''
                                                            this.accountDateFrom = dataFromEmp.hasOwnProperty('advanceValidityFrom')?this.chkplndata(dataFromEmp.advanceValidityFrom) : ''
                                                            this.accountDateTo = dataFromEmp.hasOwnProperty('advanceValidityTo')?this.chkplndata(dataFromEmp.advanceValidityTo) : ''
                                                        }else{
                                                            console.log("paymentdetails is not present");
                                                            this.clearPaymentDetails(); 
                                                        }            
                                                }
                                              

                                            }else{

                                                console.log("ERROR in QuickEmpanelment provider details with providerid",res.data.data);
                                            }
                                    }); 
                        
                        
                            this.prn = res.data.data.data[0].prn;
                            providerIdForEmp = res.data.data.data[0]._id;
                            registeredEntity = res.data.data.data[0].registeredEntity
                            providerType = res.data.data.data[0].providerType
                            this.panId = res.data.data.data[0].panNumber
                            console.log("%c this is the pan for quickEmpanelment","color:green",res.data.data.data[0].panNumber);
                        }else{
                                this.showToast(err.response.data.msg ,this.TOST().ERROR);
                        }   

                    });

                    

           

        },

         chkplndata(data){
            console.log("chkplndata",data); 
            try{
                if (data ==="" || data === "undefined" || data === null) {
                    return ""
                } else {
                    return data;
                }

             }catch(err) {
                console.log(err)
            }
            
        },

        chkplndataradio(data){
            console.log("chkplndata",data); 
            try{
                if (data ==="" || data === "undefined" || data === null) {
                    return "No"
                } else {
                    return data;
                }

             }catch(err) {
                console.log(err)
            }
            
        },

        clearPaymentDetails(){
            this.payeeName = ''         
            this.bankName  = ''         
            this.branch = ''         
            this.micrCode = ''         
            this.ifscCode = ''         
            this.typeOfAccount = ''         
            this.accountNumber = ''         
            // this.panId  = ''         
            this.proprietorshipName = ''         
            this.gstin  = ''         
            this.bankRadioBtn   = 'No'         
            this.bankIfYesAmount = ''         
            this.bankDateFrom = ''         
            this.bankDateTo = ''         
            this.paymentRadioBtn = 'No'         
            this.payeeIfYesAmount = ''         
            this.accountDateFrom = ''         
            this.accountDateTo  = '' 
        },



        clearData(){
            this.$v.$reset()
            this.providerDetails ='';
            this.prn = '';
            this.providerNetwork ='';
            this.regEntity ='';
            this.networkNumber ='';
            this.dateFrom ='';
            this.dateTo = '';
            this.payeeName = '';
            this.bankName = '';
            this.branch = '';
            this.micrCode = '';
            this.ifscCode = '';
            this.typeOfAccount = '';
            this.accountNumber = '';
            this.panId = '';
            this.proprietorshipName = '';
            this.gstin = '';
            // this.providerFromDate = '',
            // this.providerToDate = '',
            this.bankDateFrom = '',
            this.bankDateTo = '',
            this.accountDateFrom = '',
            this.accountDateTo = ''
        },


       bankRadioButton(){
           if(this.bankRadioBtn === 'Yes'){
                    this.bankDisableValue = false;
                    
           }else{
               this.bankDisableValue = true;
               this.bankIfYesAmount = ''
               this.bankDateFrom = ''
               this.bankDateTo = ''
           }
       },

        payRadioBtn(){
           if(this.paymentRadioBtn === 'Yes'){
                this.payDisableValue = false;        
           }else{
               this.payDisableValue = true;
               this.payeeIfYesAmount = ''
               this.accountDateTo = ''
               this.accountDateFrom = ''         
           }
       
        },
 
        
    },

    watch: {

        searchApi(val) {
                // Items have already been loaded

        // if(val.length > 2){

            // if (this.searchedItems.length > 0) return

            // // Items have already been requested
            // if (this.isLoading) return

            // this.isLoading = true
            console.log(val);
            let provider = this.providerDetails;
            this.GET(2,'provider/search-providers-by-name/'+val+'?'+'status=QuickEmpanelment',
                   ( res,err) => {
                    if(!err){

                        console.log("this is response",res);
                        const providers = []
                        for(let provider of res.data.data.data){
                                console.log(provider.empanelmentProvider);
                                if(provider.registeredEntity === "Institution"){
                                    let _insData = {
                                        name:provider.entityName,
                                        _id:provider._id,
                                    }
                                    providers.push(_insData)
                                }else if(provider.registeredEntity === "Individual"){
                                    
                                    let _indData = {

                                        name:provider.firstName+' '+ provider.lastName,
                                        _id:provider._id,
                                    }
                                        providers.push(_indData)
                                }
                                
                        }
                        this.entries = providers
                        console.log("this is from searchAPI",this.entries)
                    }else{
                        this.showToast(err.response.data.msg ,this.TOST().ERROR);
                    }
                    });
                    this.isLoading = false

             },

        ifscCode(ifscVal){
            if(ifscVal.length == 11){
                this.GET(3,'bank-details/search-by-branch-ifsc?ifsc='+ifscVal , (res, error)=>{
                            if(!error){
                                console.log("this is res for ifsc code",res.data.data.data[0]);
                                this.bankName =  res.data.data.data[0].bank
                                let bankNameThrApi =  res.data.data.data[0].bank

                                //  this.GET(3,'bank-details/branch-by-bank?bank='+bankNameThrApi , (res, error)=>{
                                //      if(!error){
                                //          this.branch_collection = []
                                //          // console.log("res is ---> ", res.data.data.data);
                                //          this.branch_collection = res.data.data.data;
                                //      }else{
                                //          console.log("their is error in branch from watch function",error.response.data);
                                //      }
                                //  })
                                 this.grab_bankname(bankNameThrApi,0)
                                console.log("this is res for branch",res.data.data.data[0].branch);
                                this.branch = res.data.data.data[0].branch
                                this.branchValue = res.data.data.data[0].branch
                                // this.branch_collection.push(res.data.data.data[0].branch)
                                console.log(this.branch);
                                if(res.data.data.data[0].hasOwnProperty('micrCode')){
                                    console.log(this.micrCode);
                                    this.micrCode = res.data.data.data[0].micrCode
                                }else{
                                    console.log("not present micrCode");
                                    this.micrCode = ''
                                }
                                
                            }else{
                                console.log("this is error for ifsc code",error.response.data);
                            }
                })
            }else{
                // this.bankName = ''
                // this.branch = ''
                // this.showToast("Please enter valid IFSC code",this.TOST().WARNING)
                console.log("%c ifsc code error","background-color:#eeee20;color:black");
            }
        },

        
                      

    }
     
       
}
</script>


<style>
.cust-rmvpd-psins_quick .v-text-field.v-text-field--enclosed>.v-input__control>.v-input__slot {
    padding-right: 0px !important;
}

.cust_pr_quick .v-text-field .v-input__slot,
.v-text-field--outline .v-input__slot {
    min-height: 42px !important;
    display: flex !important;
    align-items: center !important;
    /* border:1px red solid; */
}

.cust_pr_quick .v-input .v-label {
    font-size: 13px !important;
    
}


.cust_pr_quick .v-input{
    font-size: 13px !important;
}


.cstm-clr-pr-quick{
    color:#646666 !important;
    margin-bottom: 5px !important;
    font-size: 13px !important;
    
}

.cust_pd_quick .v-text-field .v-input__slot,
.v-text-field--outline .v-input__slot {
    min-height: 42px !important;
    display: flex !important;
    align-items: center !important;
    /* border:1px red solid; */
}

.cust-rmvpd-pr-quick .v-text-field.v-text-field--enclosed>.v-input__control>.v-input__slot {
    padding: 0px !important;
}

.deleIconQuick .v-input__control .v-input__slot .v-select__slot .v-input__append-inner .v-input__icon--append{
    display: none;
}

</style>