import axios from 'axios';
import * as URL from "../common/API_Config.js";

export const getClaimDetails = async(claimNumber,memberId, policyNumber, featureNumber) => {
    let res = await axios.get(URL.claimsModuleBaseURL+`claims-registration/claimsResonDetails/get-claims-details?claimId=${claimNumber}&memberId=${memberId}&policyNumber=${policyNumber}&featureNumber=${featureNumber}`,
        {headers:{
            "Content-Type": "application/json"
        }}
    )
    return res.data;
};

export const getClaimDetailsId = async(id) => {
    let res = await axios.get(URL.claimsModuleBaseURL+`claims-registration/claims-details/get-claim-registration-detail/${id}`,
        {headers:{
            "Content-Type": "application/json"
        }}
    )
    return res.data;
};

export const getPolicyDetails = async(claimNumber,memberId, policyNumber, featureNumber) => {
    let res = await axios.get(URL.claimsModuleBaseURL+`claims-registration/claimsResonDetails/get-policy-details?claimId=${claimNumber}&memberId=${memberId}&policyNumber=${policyNumber}&featureNumber=${featureNumber}`,
        {headers:{
            "Content-Type": "application/json",
        }}
    )
    return res.data;
};