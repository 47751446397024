<template>
    <v-app>
       <v-card class="pa-5 ma-5" tile>
            <v-flex xs3 >
                <v-layout column class="cust_Aplcbl_cvrgs">
                        <p class="font-weight-bold cust_Aplcbl_cvrgs_clr">
                        Plan
                        </p>
                        <v-select
                            :items="planItems"
                            label="Select"
                            v-model="plan"
                            item-text="planName"
                            item-value="_id"
                            solo
                            dense
                            disabled
                            class="rounded-0">
                        </v-select>
                </v-layout>
            </v-flex>
        </v-card>
        <p class="font-weight-bold cust_Aplcbl_cvrgs_clr mx-5 py-2" style=" color: #646666;">List Of Coverages</p>
        <v-card class="mx-5 py-2 " tile>
            <v-layout align-center>
                <v-checkbox
                    dense
                    v-model="allSelctd"
                    @click="selectAllBox"
                    hide-details
                    class="ml-1 pa-0 pb-2 ma-0"
                    color="blue"
                    :disabled="viewonly"
                >
                    <span slot="label" class="font-weight-bold" style="font-size: 12px">Select All</span>
                </v-checkbox>
            </v-layout>
            <v-divider></v-divider>
            <v-layout row wrap class="px-2 py-3">
                    <v-flex xs2 v-for="(lst,index) in lstOfCvrgs" :key="index" class="">
                    <v-checkbox
                            v-model="lstIds"
                            color="blue"
                            :value="lst.id"
                            @click="slctCvrg"
                            hide-details
                            dense
                            :disabled="viewonly"
                            class="pa-2 ma-0"
                            ><span slot="label" class="lbl-bgclr font-weight-bold " style="font-size: 11px;word-wrap: break-word; " :style="{opacity:viewonly===true?0.5:1}">{{lst.name}}</span>
                        </v-checkbox>
                        </v-flex>
                </v-layout>
                <p v-if="minCheckBox===true" class="px-3" style="color:#ff5252;font-size: 12px;">Select Anyone Coverage</p>
        </v-card>
          <v-card tile class="mx-3 pa-3 my-4" style="background-color: #F7F7F7; display:flex;justify-content:space-between"  >

                <v-layout style="flex:1" justify-end>
                    <v-btn dark  tile style="background-color:#E46A25" class="text-capitalize px-3 mx-2" small @click="returnTab()"><v-icon dark small>arrow_back</v-icon> <v-divider vertical class="mx-2"></v-divider>Return</v-btn>
                    <v-btn :dark="!viewonly"  :disabled="viewonly" tile style="background-color:#23B1A9" class="text-capitalize px-3 mx-2" small @click="aplcbl_save()"><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider>Save</v-btn>
                    <v-btn  @click="nextTab()" tile style="background-color:#152F38" class="text-capitalize px-3 ml-2" small dark><v-icon dark small>arrow_forward</v-icon> <v-divider vertical class="mx-2"></v-divider> Next</v-btn>
                </v-layout>

        </v-card>
    </v-app>
</template>
<script>
import axios from "axios";
import moment from 'moment';
export default {
    components:{
        
    },
    created() {
        this.$store.commit("SAVE_PAGE_HEADER", "Applicable Coverages");
        console.log(this.$store.getters.fetchProductCodeData, "Product ID")
         this.token=this.$store.getters.successToken
        this.btnName=this.$store.getters.fetchPlcyIdForPlnData.text
        this.getProductbyId(this.$store.getters.fetchProductCodeData)
        
        
        if(this.btnName==="EditPlnFrPlcy"||this.btnName==="CrteNwPlnFrPlcy"||this.btnName==="EditNwRtlPln"||this.btnName==="CrteNwRtlPln"){
            this.viewonly=true
        }

        
    },

    computed: {

    },
    data() {
        return {
            token:null,
            plan:"",
            planItems:[],
            btnName:'',
            minCheckBox:false,
            viewonly:false,
            // btnshow:false,
            allSelctd:false,
            lstIds:[],
            lstOfCvrgs:[
            ],
        }
    },
    methods: {
        nextTab(){
            console.log("emitted")
            this.$emit('next');
        },
        returnTab(){
            console.log("emitted")
            this.$emit('return');
        },

         selectAllBox(){
            this.lstIds = [];
            if (this.allSelctd) {
                for (let i in this.lstOfCvrgs) {
                    this.lstIds.push(this.lstOfCvrgs[i].id.toString());
                }
                
            }
            if(this.lstIds.length!==0){
                this.minCheckBox=false
            }
        },
        slctCvrg(){
            this.allSelctd = false;
            if(this.lstIds.length!==0){
                this.minCheckBox=false
            }
            if(this.lstIds.length===this.lstOfCvrgs.length){
                console.log(this.allSelctd,"if")
                this.allSelctd= true
            }else{
                this.allSelctd= false
                console.log(this.allSelctd,"else")
            }
        },

         getGroupPlanbyId(planId){
            console.log(planId," Incoming PlanId")
            let self = this;   
            axios({
                method: "get",
                url:self.API_Service_admin()+"group-plan/get-group-plan/"+planId, 
                headers: {
                    "Content-Type": "application/json", 
                    "x-access-token": self.token,
                }
            })
            .then(function(response) {
                console.log('getGroupPlanbyId------->>>',response.data.data);
                let pdtDta={}
                pdtDta=response.data.data
                self.planItems.push(pdtDta)
                self.plan=self.planItems[0]
                if(pdtDta.hasOwnProperty("applicableCoverages")){
                    if(pdtDta.applicableCoverages.length !=0){
                        console.log("inside if",pdtDta.applicableCoverages.length!=0)
                        self.lstIds=[]
                        self.lstIds=pdtDta.applicableCoverages
                        self.slctCvrg()
                    }
                }
              
            })
            .catch(function(error) {
                console.log(error);
                // self.showToast(error.response.data.msg,self.TOST().ERROR);
            });
        },
        chkplndata(data){
            console.log("Policy sara",data); 
            try{
                if (data ==="" || data === "undefined" || data === null) {
                    return []
                } else {
                    return data;
                }

             }catch(err) {
                console.log(err)
            } 
        },

        getRetailPlanbyId(planId){
            console.log(planId," Incoming PlanId")
            let self = this;   
            axios({
                method: "get",
                url:self.API_Service_admin()+"retail-plan/get-retail-plan/"+planId, 
                headers: {
                    "Content-Type": "application/json", 
                    "x-access-token": self.token,
                }
            })
            .then(function(response) {
                console.log('getRetailPlanbyId------->>>',response.data.data);
                let pdtDta={}
                pdtDta=response.data.data
                self.planItems.push(pdtDta)
                self.plan=self.planItems[0]  
                if(pdtDta.hasOwnProperty("applicableCoverages")){
                    if(pdtDta.applicableCoverages.length!=0){
                        self.lstIds=[]
                        self.lstIds=pdtDta.applicableCoverages
                        self.slctCvrg()
                    }
                   
                }
              
            })
            .catch(function(error) {
                console.log(error);
                // self.showToast(error.response.data.msg,self.TOST().ERROR);
            });
        },

        aplcbl_save(){
           this.updateAplcblCvrgs()

        },
        getProductbyId(productId){
            console.log(productId," Incoming PlanId for grp coverage")
            let apiURL="product/get-product/"+productId
            this.GET(1,apiURL,(res,error)=>{
                try{
                        if(!error){
                            console.log(`GET APi response|->Product-details-by-id ${this.$store.getters.fetchProductCodeData}`, res.data.data)
                            this.lstOfCvrgs=res.data.data.coverages.map(e=>{
                                return{
                                    id:e,
                                    name:e,
                                }
                            })
                            this.lstIds=[]
                            this.lstIds=res.data.data.coverages
                            this.slctCvrg()
                            if(this.btnName==="EditPlnFrPlcy"||this.btnName==="ConfgrPlnFrPlcy"){
                                // this.nextbtn=false
                                this.getGroupPlanbyId(this.$store.getters.fetchPlcyIdForPlnData.plan_No)
                            }

                            if(this.btnName==="EditNwRtlPln"||this.btnName==="CnfgrNwRtlPln"){
                                // this.nextbtn=false
                                this.getRetailPlanbyId(this.$store.getters.fetchPlcyIdForPlnData.retailPlan_Id)
                            }
                        }else{
                            console.log("GET APi error response|->Product-details-by-id", error)
                        } 
                    }catch(e){
                         console.log("%c GET APi  errorresponse|-> Product-details-by-id From Catch","color:blue", e)
                    }

            })
        },
        updateAplcblCvrgs(){
             let formData={
                 applicableCoverages:this.lstIds
             }
            console.log(this.lstIds,"this.lstIds") 
            let apiURL=''
            if(this.btnName==="ConfgrPlnFrPlcy"){
                apiURL="group-plan/update-group-applicable-coverages/"+this.plan._id+"/"+this.$store.getters.fetchProductCodeData
            }else if(this.btnName==="CnfgrNwRtlPln"){
                 apiURL="retail-plan/update-retail-applicable-coverages/"+this.plan._id+"/"+this.$store.getters.fetchProductCodeData
            }
            this.PUT(1,apiURL,formData,(res,err)=>{
                if(!err){
                    console.log("PUT APi response|-> ctcvac ", res)
                    this.showToast(res.data.msg,this.TOST().SUCCESS)
                }else{
                    console.log("PUT APi error response|->ctcvac", err)
                    this.showToast(err.response.data.msg ,this.TOST().ERROR);
                }    
            })
        }
    },
}
</script>
<style>

.cust_Aplcbl_cvrgs .v-text-field .v-input__slot,
.v-text-field--outline .v-input__slot {
    min-height: 42px !important;
    display: flex !important;
    align-items: center !important;
    /* border:1px red solid; */
}

.cust_Aplcbl_cvrgs .v-input {
    font-size: 13px !important;
}
.cust_Aplcbl_cvrgs .v-input input {
    font-size: 13px !important;
}
.cust_Aplcbl_cvrgs .v-input .v-label {
    font-size: 13px !important;
}
.cust_Aplcbl_cvrgs_clr{
    color:#10242B !important;
    margin-bottom: 5px !important;
    font-size: 13px !important;
}

</style>