export default {
  fetchHeader (state) {
    return state.headerName;
  },

  loader(state) {
    return state.loader
  },

  current_role(state){
    return state.selected_current_role
  },

  successToken(state){
    return state.token 
  },

  // fetchRole(state){
    // using marine's as marine and health using same state and getter name
  //   return state.userRole
  // },
  
  // fetchName(state){
     // using marine's as marine and health usinng same state and getter name
  //   return state.name
  // },

  fetchPrvdrData(state){
    return state.prvdrData
  },

  fetchProductCodeData(state){
    return state.createProductCode
  },

  fetchProductItemData(state){
    return state.productItemData
  },

  fetchBtnName(state){
    return state.btnName
  },

  fetchPlcyFrPrdctBtnName(state){
    return state.plcyfrPrdctbtnName
  },

  fetchProductAllData(state){
    return state.sendProductData
  },

  fetchPlcyIdForPlnData(state){
    return state.plcyIdForPlan
  },

  fetchRetailPlnDta(state){
    return state.retailPlanPdt
  },

  fetchGrpPlcyDtaFrCln(state){
    return state.grpPlcyDtafrCln
  },

  fetchProviderData(){
    return state.empanelProviderData
  },
  fetchNetworkData(state){
    return state.createNetworkData
  },

  fetchupdateNetworkData(state){
    return state.updateNetworkData
  },
  
  fetchPdtDate(state){
    return state.productDate
  },
  fetchTarrifData(state){
    return state.selectTarrifData
  },

  fetchAssignRole(state){
    return state.assignRole
  },
  // fetchHeader(state) {
   // using marine's as in mutation both states are modified
  //   return state.pageName;
  // },
  fetchBackPageName(state) {
    return state.menuBarSelectedName;
  },
  fetchToken(state) {
    return state.token;
  },
  fetchclaimMainscreen_id(state) {
    return state.claimMainscreen_id;
  },

  fetchRole(state) {
    return state.userRole;
  },
  fetchName(state) {
    return state.name;
  },
  fetchLoginID(state) {
    return state.login_id;
  },
  fetchShowProgress(state) {
    return state.showProgress;
  },
  fetchShowProgressMessage(state) {
    return state.showProgressMessage;
  },
  fetchFnolPageEditCheck(state) {
    return state.fnloPageEditCheck;
  },
  fetchPolicySearchData(state) {
    return state.policySearchData;
  },
  fetchpolicyproposalNo(state) {
    return state.policyproposalNo;
  },
  fetchMainClaimNumberObj(state) {
    return state.mainClaimNumberObj;
  },
  //toast start
  fetchshowToast(state) {
    return state.toast.showToast;
  },
  fetchToastMessage(state) {
    return state.toast.toastMessage;
  },
  //toast end
  fetchFeatureNumber(state){
    return state.featureNumberObj;
  },



  // fetchConfirm(state) {
  //   return state.showConfirm;
  // },
  // fetchConfirmMessage(state) {
  //   return state.confirmMessage;
  // },
  // fetchConfirmTitle(state) {
  //   return state.confirmTitle;
  // },
  // fetchSuccessButton(state) {
  //   return state.successbutton
  // },
  // fetchFailureButton(state) {
  //   return state.failurebutton
  // },
  // fetchfunctions(state) {
  //   return state.callfunction
  // },
  // fetchfailurefunction(state) {
  //   return state.callfailurefunction
  // },
  // //show alert function's code start here.
  // fetchcustomAlert(state) {
  //   return state.showAlertObj.showAlert;
  // },
  // fetchcustomAlertMessage(state) {
  //   return state.showAlertObj.showAlertMessage;
  // },
  // fetchalertfunction(state) {
  //   return state.showAlertObj.alertfunction;
  // },
  // fetchalertfailurefunction(state) {
  //   return state.showAlertObj.failurealertfunction;
  // },
  // fetchalertsuccessAlertButtonName(state) {
  //   return state.showAlertObj.successAlertButtonName;
  // },
  // fetchalertfailureAlertButtonName(state) {
  //   return state.showAlertObj.failureAlertButtonName;
  // },
  // fetchalertcanclebtn(state) {
  //   return state.showAlertObj.showcancel;
  // },
  //show alert function's code End here.
  fetchCreateEditUserData(state){
    return state.userCreateEditDta
  },
  fetchClaimMembersearchData(state){
    return state.claimMembersearchdata
  },
  fetchDataFromOtherLOB(state) {
    return state.otherLOBData;
  },
  getTokenData(state){
    return state.Auth;
  },
  fetchCurrentPageData(state){
    return state.currentPageData;
  },
  fetchParentData(state) {
		return state.parentData;
	},

  
}
