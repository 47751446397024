<template>
  <div class="pa-6">
    <!-- table -->
    <v-data-table
      :headers="headers"
      :items="SearchData"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      hide-default-footer
      class="padding-0"
    >
      <template v-slot:top>
        <v-row class="mb-1">
          <v-col class="d-flex align-center">
            <h3 class="mt-1">List Of Users</h3>
            <v-chip class="ma-2" color="#E46A25" small text-color="white" label>
              {{ totalListCount }}
            </v-chip>
            <!--  @click:close="chip1 = false" -->
            <v-chip
              small
              close
              color="#20B7B0"
              text-color="white"
              label
              v-if="searchChip"
              @click:close="searchChipFun"
            >
              Search Result
            </v-chip>
            <!-- :search="search" -->
          </v-col>
          <v-spacer> </v-spacer>
          <v-col class="d-flex justify-end align-center">
            <v-btn
              depressed
              color="#23B1A9"
              dark
              @click="dialog = true"
              class="text-capitalize"
            >
              <v-icon>mdi-magnify</v-icon>
              <div class="vertical_line"></div>
              Search
            </v-btn>
          </v-col>
        </v-row>
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <v-menu offset-y open-on-hover>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              elevation=""
              icon
              v-bind="attrs"
              v-on="on"
              style="text-align:center"
            >
              <v-icon>mdi mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item v-for="(item_data, index) in itemsData" :key="index">
              <v-list-item-title
                :style="{ color: item_data.Textcolor }"
                @click="redirectUrl(item_data.url, item_data.title, item)"
              >
                <v-icon :style="{ color: item_data.color }">
                  mdi mdi-{{ item_data.icon }}
                </v-icon>
                {{ item_data.title }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <template v-slot:[`item.employee`]="{ item }">
        {{ item.employee ? "Employee" : "External Resource" }}
      </template>
      <template v-slot:[`item.userZoneMapping`]="{ item }">
        {{ diplayZone(item) }}
      </template>
      <template v-slot:[`item.assignRole`]="{ item }">
        {{ getRoleInString(item) }}
      </template>
    </v-data-table>

    <v-layout justify-end align-center style="flex:0;" class="py-2">
      <div>
        <span style="color:#e46a25"
          >Showing {{ pageStartIndex }}-{{ pageEndIndex }}</span
        >
        out of {{ totalListCount }} records
      </div>
      <div>
        <v-pagination
          v-model="page"
          :length="pageCount"
          :total-visible="5"
          @input="searchDataTable(page)"
          color="#152F38"
        ></v-pagination>
      </div>
    </v-layout>
    <!-- Table End -->

    <!-- Search Dialog -->
    <v-dialog v-model="dialog" persistent max-width="800px">
      <v-form @submit.prevent="" class="search-div" ref="searchForm">
        <v-card>
          <v-card-title class="py-2">
            <span class="text-h6">Search</span>
            <v-spacer></v-spacer>
            <v-btn color="white" text @click="dialog = false">
              <v-icon color="white--text">
                mdi-close
              </v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="4" class="pt-6 pb-0">
                  <label class="font-weight-bold cstm-clr-pd"
                    >NTID/User ID
                  </label>
                  <v-text-field
                    solo
                    dense
                    class="form-control"
                    placeholder="Enter"
                    v-model="searchObj.userId"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="4" class="pt-6 pb-0">
                  <label class="font-weight-bold cstm-clr-pd">Employee ID</label>
                  <v-text-field
                    solo
                    dense
                    class="form-control"
                    placeholder="Enter"
                    v-model="searchObj.employeeId"
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12" sm="4" class="pt-6 pb-0">
                  <label class="font-weight-bold cstm-clr-pd">Employee Name</label>
                  <v-text-field
                    solo
                    dense
                    class="form-control"
                    placeholder="Enter"
                    v-model="searchObj.employeeName"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="4" class="pt-0 pb-0">
                  <label class="font-weight-bold cstm-clr-pd">LOB</label>
                  <v-select
                    :items="lobList"
                    label="Select"
                    class="form-control"
                    dense
                    solo
                    v-model="searchObj.LOB"
                  ></v-select>
                </v-col>

                <v-col cols="4" sm="4" class="pt-0 pb-0">
                  <label class="font-weight-bold cstm-clr-pd"
                    >Agreement Type</label
                  >
                  <v-select
                    :items="agreementTypeList"
                    label="Select"
                    class="form-control"
                    dense
                    solo
                    clearable
                    v-model="searchObj.agreementType"
                    @click:clear="$nextTick(() => searchObj.typeOfEmployee='')"
                  ></v-select>
                </v-col>

                <v-col cols="12" sm="4" class="pt-0 pb-0">
                  <label class="font-weight-bold cstm-clr-pd">
                    Type Of Employee
                  </label>
                  <v-select
                    :items="typeOfEmployeeList"
                    label="Select"
                    class="form-control"
                    dense
                    solo
                    v-model="searchObj.typeOfEmployee"
                    :disabled="!searchObj.agreementType"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>                
                <v-col cols="12" sm="4" class="pt-0 pb-0">
                  <label class="font-weight-bold cstm-clr-pd">Zone</label>
                  <v-select
                    :items="zoneList"
                    label="Select"
                    class="form-control"
                    dense
                    solo
                    v-model="searchObj.zone"
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-btn
              outlined
              color="black"
              @click="dialog = false"
              justify="start"
              class="text-capitalize font-weight-bold cstm-clr-pd"
              style="margin-left:0px;margin:8px"
            >
              Cancel
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              outlined
              color="black"
              @click="clearSearchForm"
              justify="start"
              class="text-capitalize font-weight-bold cstm-clr-pd"
              style="margin-left:0px;margin:8px"
            >
              Clear
            </v-btn>
            <v-btn
              color="#23b1a9"
              dark
              class="text-capitalize font-weight-bold colorbg"
              style="margin:8px;"              
              @click="handleSearch"
              text-color="white"
            >
              Search
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      headers: [
        {
          text: "Action",
          align: "start",
          value: "action",
          sortable: false,
          class: "hdr-txt-clrApproval hdr-bg-clr",
        },
        {
          text: "NTID/User ID",
          align: "start",
          value: "ntidUserId",
          sortable: false,
          class: "hdr-txt-clrApproval hdr-bg-clr",
        },
        {
          text: "Employee ID",
          align: "start",
          value: "employeeId",
          sortable: false,
          class: "hdr-txt-clrApproval hdr-bg-clr",
        },
        {
          text: "Employee Name",
          align: "start",
          value: "employeeName",
          sortable: false,
          class: "hdr-txt-clrApproval hdr-bg-clr",
        },
        {
          text: "Agreement Type",
          align: "start",
          value: "employee",
          sortable: false,
          class: "hdr-txt-clrApproval hdr-bg-clr",
        },        
        {
          text: "Employment Type",
          align: "start",
          value: "typeOfEmployee",
          sortable: false,
          class: "hdr-txt-clrApproval hdr-bg-clr",
        },
        {
          text: "LOB",
          align: "start",
          value: "selectedLOB",
          sortable: false,
          class: "hdr-txt-clrApproval hdr-bg-clr",
        },
        {
          text: "Role",
          align: "start",
          value: "assignRole",
          sortable: false,
          class: "hdr-txt-clrApproval hdr-bg-clr",
        },
        {
          text: "Zone",
          align: "start",
          value: "userZoneMapping",
          sortable: false,
          class: "hdr-txt-clrApproval hdr-bg-clr",
        },
      ],
      SearchData: [],
      page: 1,
      itemsPerPage: 10,
      pageStartIndex: 0,
      totalListCount: 0,
      searchChip: false,
      dialog: false,
      
      itemsData: [
        {
          title: "View",
          icon: "eye-outline",
          url: "map-user-zone",
          Textcolor: "black",
          color: "black",
        },
        {
          title: "Modify",
          icon: "pencil",
          url: "map-user-zone",
          Textcolor: "#23b1a9",
          color: "black",
        },
        {
          title: "Audit Trail",
          icon: "chart-line-variant",
          Textcolor: "#e46a25",
          color: "#e46a25",
        },
      ],
      zoneList: [],
      lobList: [],
      agreementTypeList: ["Employee","External Resource"],
      searchObj: {
        userId: "",
        employeeId: "",
        employeeName: "",
        LOB: "",
        typeOfEmployee: "",
        zone: "",
        agreementType: "",
      },
    }
  },
  methods: {
    searchChipFun() {
      this.totalListCount=0;
      this.searchObj = {
        userId: "",
        employeeId: "",
        employeeName: "",
        LOB: "",
        typeOfEmployee: "",
        zone: "",
        agreementType: "",
      }
      this.searchChip = false
      this.SearchData = []
     this.searchDataTable(1)
    },
    searchDataTable(newPageNum) {
      let {
        userId,
        employeeId,
        employeeName,
        LOB,
        typeOfEmployee,
        zone,
        agreementType,
      } = this.searchObj
      let reqData = "users/search-users?pageNo=" + newPageNum + "&perPage=" + this.itemsPerPage
      reqData += `&userid=${userId}&employeeId=${employeeId}&employeeName=${employeeName}&lob=${LOB}&typeOfEmployee=${typeOfEmployee}&zone=${zone}`
      this.searchObj.agreementType && this.searchObj.agreementType == "Employee" ? reqData += "&employee=true" : this.searchObj.agreementType && this.searchObj.agreementType !== "Employee" ? reqData += "&employee=false" : ""
      this.showProgress()
      this.GET(6, reqData, (res, error) => {
          if(!error && res.data && res.data.data && res.data.data.data && res.data.data.data.length > 0) {
            this.SearchData = res.data.data.data
            this.totalListCount = res.data.data.total
            this.pageStartIndex = newPageNum == 1 ? 1 : (newPageNum - 1) * this.itemsPerPage + 1
            this.searchChip = true;
          }
          if (!error && res.data && res.data.msg === "No records found") {
            this.SearchData = []
            this.searchChip = false;
            this.showAlert(res.data.msg)
          }
          if(error){
            this.searchChip = false;
            this.hideProgress()
            console.log(error.response)
          }
        })
    },
    getLobList() {
      this.GET(3, "line-of-business/get-line-of-business-list", (res, error) => {
        if (!error) {
          console.log("response from api is itemsOfLOB = ", res.data.data);
          this.lobList = res.data.data[0].lineOfBusiness;
        } else {
          console.log("error", error.response.data);
        }
      });
    },
    getZoneList() {
      this.showProgress()
      this.GET(6, "settlementoffice/get-zone-list", (res, error) => {
        if(error){
          this.hideProgress()
          console.log(error.response)
        }else{
          this.hideProgress()
          this.zoneList = res.data.data
        }          
      })
    },
    clearSearchForm() {
      this.searchObj = {
        userId: "",
        employeeId: "",
        employeeName: "",
        LOB: "",
        typeOfEmployee: "",
        zone: "",
        agreementType: "",
      }
    },
    handleSearch() {
      // if (this.searchObj.userId === "" || this.searchObj.agreementType === "") {
      //   this.showAlert("Please fill the mandatory fields")
      //   this.$refs.searchForm.validate()
      //   return
      // }
      this.searchChip = true
      this.searchDataTable(1)
      this.dialog = false
    },
    diplayZone(item) {
      let result = item.userZoneMapping.reduce(
        (acc, val) => (acc === "" ? acc + val.zone : acc + "," + val.zone),
        ""
      )
      return result
    },
    redirectUrl(url, title, item) {
      console.log(url, title, item)
      this.$router.push({
        name: "MapUserToZone",
        params: {
          data: item,
          viewMode: title === "View" ? true : false,
          modifyMode: title === "Modify" ? true : false,
        },
      })
    },
    getRoleInString(item){
      console.log(item.assignRole)
      let roleInString  = "";
      if(!item.assignRole || !item.assignRole.length){
        return roleInString
      }
      
      for(var i in item.assignRole){
        roleInString = i == 0 ? item.assignRole[i].roleName : roleInString + ", " + item.assignRole[i].roleName
      }
      return roleInString
    }
  },
  created() {
    this.$store.commit("SAVE_PAGE_HEADER", "Map User To Settlement Office");//Search User Zone Mapping
    this.searchDataTable(1)
    this.getLobList()
    this.getZoneList()
  },
  computed: {
   
    pageCount() {
      if (!this.totalListCount) {
        return 0
      } else {
        return Math.ceil(this.totalListCount / this.itemsPerPage)
      }
    },
    pageEndIndex() {
      if (!this.SearchData.length) {
        return 0
      } else {
        return this.pageStartIndex + this.itemsPerPage <= this.totalListCount
          ? this.pageStartIndex + this.itemsPerPage - 1
          : this.totalListCount
      }
    },
    typeOfEmployeeList(){
     return this.searchObj.agreementType == "Employee" ? [
        "Full Time",
        "Contractual Employee",
        "Outsource"        
      ] : ["External",
        "Service ID",
        "Investigator",
        "Surveyor",
        "Other",
        "Vendor",
        "Repairer",
        "Outsource"]

    }
  },
  watch:{
    dialog(_,newValue){
        // if(newValue===false) this.$refs.searchForm.resetValidation()
    }
  }
}
</script>

<style scoped>
.v-list-item {
  flex: 1 1 100%;
  min-height: 30px;
  outline: none;
  padding: 0px 10px;
  position: relative;
  border-bottom: 1px solid #c1c0c0;
  cursor: pointer;
}
.v-list {
  padding: 0;
}
.v-list-item__title {
  font-size: 13px !important;
}
.v-card__title {
  background: #23b1a9;
  color: white;
}
.v-card__actions {
  background-color: #fff;
}
.search-div > .theme--light.v-card {
  background-color: #e0e0e0 !important;
}

.vDataTableStyling tr th {
  background-color: #01bbd9;
  color: white !important;
  font-size: 20px !important;
  height: 40px !important;
  border: 0.5px ridge #f7f7f7;
  border-width: 1px;
  border-right: none;
  border-collapse: collapse;
  font-weight: bold;
  text-align: center !important;
}

.vDataTableStyling tr td {
  border: 1px solid #f7f7f7;
  border-width: 2px;
  border-right: none;
  border-collapse: collapse;
  height: 40px !important;
  font-size: 15px !important;
  color: #10242b;
  text-align: center !important;
}
.colorbg {
  background-color: #23b1a9;
  color: #fff;
}
</style>
