<template>
  <div class="ma-6">
    <!-- Search Fields -->
    <v-card elevation="0" width="100%">
      <v-form class="mt-5 ml-2" @submit.prevent="" ref="searchForm">
        <v-row>
          <v-col>
            <label class="form-label">Police Station Name </label>
            <v-text-field
              placeholder="Enter..."
              class="form-control"
              :rules="[(v) => matchRegex(v, 'alphanum')]"
              maxlength="100"
              solo
              dense
              v-model="searchData.policeStationName"
              depressed
            />
          </v-col>
          <v-col full-width>
            <label class="form-label ml-1">State</label>
            <v-text-field
              class="form-control"
              v-model="searchData.state"
              placeholder="Select"
              dense
              solo
              :rules="[(v) => matchRegex(v, 'alphanum')]"
            ></v-text-field>
          </v-col>
          <v-col full-width>
            <label class="form-label ml-1">City</label>
            <v-text-field
              class="form-control"
              v-model="searchData.city"
              placeholder="Select"
              dense
              solo
              :rules="[(v) => matchRegex(v, 'alphanum')]"
            ></v-text-field>
          </v-col>
          <v-col>
            <label class="form-label">Pin Code</label>
            <v-text-field
              placeholder="Enter..."
              class="form-control"
              maxlength="6"
              solo
              dense
              v-model="searchData.pinCode"
              depressed
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex align-right pl-1 justify-end">
            <v-btn
              dark
              class="text-capitalize ml-2"
              color="#E46A25"
              depressed
              @click="downloadDocument"
            >
              <v-icon>mdi-arrow-down</v-icon>
              <v-divider class="mx-2 ml-2 white" vertical></v-divider>Download
              Excel
            </v-btn>
            <v-btn
              dark
              class="text-capitalize ml-2"
              color="#E46A25"
              depressed
              @click="handleSearch"
            >
              <v-icon>mdi-magnify</v-icon>Search
            </v-btn>
            <v-btn
              dark
              type="submit"
              class="text-capitalize ml-2"
              color="#152F38"
              @click="handleReset"
              depressed
            >
              <v-icon>mdi-reload</v-icon>
              <v-divider class="mx-2 ml-2 white" vertical></v-divider>Reset
            </v-btn>
            <v-btn
              dark
              class="text-capitalize ml-2"
              color="#152F38"
              depressed
              @click="handleCreate"
            >
              <v-icon>mdi-plus</v-icon>
              <v-divider class="mx-2 ml-2 white" vertical></v-divider>Create
            </v-btn>
          </v-col>
        </v-row>
      </v-form>

      <v-data-table
        class="padding-0 vDataTableStyling"
        v-if="displaySearchTable"
        :headers="headers"
        :items="policeStationMasterList"
        :page.sync="page"
        :items-per-page="itemsPerPage"
        :hide-default-footer="true"
      >
        <template v-slot:top>
          <v-layout
            justify-space-between
            align-center
            style="width: 100%"
            class="py-2"
          >
            <div style="display: flex; align-items: center; column-gap: 8px">
              <div class="page-title-small">
                List
                <span class="text__Orange">({{ totalListCount }})</span>
              </div>
              <v-chip
                small
                v-if="showSearchChip"
                close
                color="#20B7B0"
                text-color="white"
                label
                @click:close="chipclose"
              >
                Search Result
              </v-chip>
            </div>
          </v-layout>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-menu offset-y open-on-click>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                elevation=""
                icon
                v-bind="attrs"
                v-on="on"
                style="text-align: center"
                ><v-icon>mdi mdi-dots-vertical</v-icon></v-btn
              >
            </template>
            <v-list class="item-actions">
              <v-list-item
                v-for="(itemData, index) in dataItems"
                :key="index"
                @click="editmaster(itemData.link, index, item, itemData.name)"
                :disabled="
                  itemData.name === 'MODIFY' &&
                  ['Pending for approval'].includes(item.approveStatus)
                "
              >
                <v-list-item-title
                  :style="
                    itemData.name === 'MODIFY' &&
                    ['Pending for approval'].includes(item.approveStatus)
                      ? 'gray'
                      : itemData.color
                  "
                >
                  <v-icon small :style="itemData.iconColor"
                    >mdi-{{ itemData.icon }}</v-icon
                  >&nbsp;{{ itemData.name }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <template v-slot:[`item.createdDateToTakeEffect`]="{ item }">
          {{ formatDateTime(item.createdDateToTakeEffect) }}
        </template>
        <template v-slot:[`item.modifiedDateToTakeEffect`]="{ item }">
          {{ formatDateTime(item.modifiedDateToTakeEffect) }}
        </template>
        <template v-slot:[`item.updatedAt`]="{ item }">
          {{ formatDateTime(item.updatedAt) }}
        </template>
      </v-data-table>
      <v-layout
        justify-end
        align-center
        style="flex: 0"
        class="py-2"
        v-if="displaySearchTable"
      >
        <v-btn outlined color="red" class="btn" @click="cancel">
          <v-icon> mdi-backspace </v-icon>
          <v-divider vertical class="mx-2"></v-divider>
          Cancel
        </v-btn>
        <v-spacer></v-spacer>
        <div>
          <span style="color: orange"
            >Showing {{ pageStartIndex }}-{{ pageEndIndex }}</span
          >
          out of {{ totalListCount }} records
        </div>
        <div>
          <v-pagination
            v-model="page"
            :length="pageCount"
            :total-visible="5"
            @input="getsearchData"
            color="#152F38"
          ></v-pagination>
        </div>
      </v-layout>
    </v-card>
  </div>
</template>
<script>
import moment from "moment";
export default {
  data() {
    return {
      lobItems: [],
      searchData: {
        policeStationName: "",
        state: "",
        city: "",
        pinCode: "",
      },
      dataItems: [
        {
          name: "VIEW",
          icon: "eye-outline",
          link: "/viewpage",
          color: "color:#10242B",
          iconColor: "color:#10242B",
        },
        {
          name: "MODIFY",
          icon: "pencil",
          link: "/modifypage",
          iconColor: "color:#10242B",
          color: "color:#23b1a9",
        },
        {
          name: "AUDIT TRAIL",
          icon: "chart-timeline-variant",
          link: "/auditTrail",
          color: "color:orange",
          iconColor: "color:orange",
        },
      ],
      showSearchChip: false,
      displaySearchTable: false,
      headers: [
        {
          text: "Actions",
          value: "actions",
          width: 50,
          sortable: false,
          align: "center",
        },
        {
          text: "Master ID",
          value: "uid",
          sortable: false,
          width: 100,
          align: "center",
        },
        {
          text: "Police Station Name",
          value: "policeStationName",
          sortable: false,
          align: "center",
          width: "150px",
        },
        {
          text: "State",
          value: "state",
          sortable: false,
          width: "150px",
          align: "center",
        },
        {
          text: "City",
          value: "city",
          sortable: false,
          width: 150,
          align: "center",
        },
        {
          text: "Pin Code",
          value: "pinCode",
          sortable: false,
          width: 100,
          align: "center",
        },
        {
          text: "Created Date",
          sortable: false,
          value: "createdDateToTakeEffect",
          width: "100px",
          align: "center",
        },
        {
          text: "Effective Date",
          sortable: false,
          value: "modifiedDateToTakeEffect",
          width: "150px",
          align: "center",
        },
        {
          text: "Modified Date",
          sortable: false,
          value: "updatedAt",
          width: "150px",
          align: "center",
        },
      ],
      policeStationMasterList: [],
      page: 1,
      itemsPerPage: 10,
      totalListCount: 0,
      totalListCount: 0,
      pageStartIndex: 0,
    };
  },
  methods: {
    cancel(data) {
      this.showConfirm(
        "Confirmation",
        "Are you sure you want to leave?",
        "Yes",
        "No",
        (Yes) => {
          this.$router.push({ name: "Master" }); //params:{pageNo:this.pageNo}}
        }
      );
    },
    formatDateTime(dateTime) {
      return dateTime ? moment(dateTime).format("DD-MM-YYYY") : "";
    },
    chipclose() {
      this.searchData = {
        policeStationName: "",
        state: "",
        city: "",
        pinCode: "",
      };
      this.showSearchChip = false;
      this.getsearchData();
    },
    handleCreate() {
      this.$router.push("/master/create-police-station");
    },
    handleSearch() {
      this.displaySearchTable = true;
      this.showSearchChip = true;
      this.getsearchData();
    },
    downloadDocument() {
      let url = "EXPORT_POLICE_STATION";
      let reqData = "";
      this.GETM(
        url,
        reqData,
        (res) => {
          if (res && res.data && res.data.data) {
            window.open(res.data.data);
          } else {
            this.showAlert("No Data Found");
          }
        },
        (err) => {},
        true
      );
    },
    getsearchData(newPageNum = 1) {
      let url = "GET_POLICE_STATION";
      this.showProgress();
      this.page = this.page !== newPageNum ? newPageNum : this.page;
      let reqData = "page=" + this.page + "&pageLimit=" + this.itemsPerPage;
      reqData =
        reqData +
        "&policeStationName=" +
        this.searchData.policeStationName +
        "&state=" +
        this.searchData.state +
        "&city=" +
        this.searchData.city +
        "&pinCode=" +
        this.searchData.pinCode;

      this.searchDialog = false;
      this.GETM(
        url,
        reqData,
        (res) => {
          // this.showChipHandler()
          if (
            res &&
            res.data &&
            res.data.data &&
            res.data.data.data &&
            res.data.data.data.length > 0
          ) {
            this.policeStationMasterList = res.data.data.data;
            this.totalListCount = res.data.data.total;
            // this.pageCount = res.data.data.total_pages
            this.pageStartIndex =
              newPageNum == 1 ? 1 : (newPageNum - 1) * this.itemsPerPage + 1;
          } else {
            this.showAlert("No Data Found.", "", "", () => {
              this.policeStationMasterList = [];
              this.totalListCount = 0;
              this.pageStartIndex = 0;
              // this.pageCount = 0
            });
          }
          this.hideProgress();
        },
        (err) => {
          this.hideProgress();
        }
      );
    },
    handleReset() {
      this.showConfirm(
        "Confirmation",
        "Are you sure you want to Reset?",
        "Yes",
        "No",
        (Yes) => {
          this.searchData = {
            policeStationName: "",
            state: "",
            city: "",
            pinCode: "",
            createdDateToTakeEffect: "",
            modifiedDateToTakeEffect: "",
          };
          this.showSearchChip = false;
          this.getsearchData();
        },
        (No) => {}
      );
    },
    editmaster(link, index, item, title) {
      if (link === "/auditTrail") {
        // this.seeAuditTrail(item);
        this.audit_dialog = true;
        this.$router.push({ name: "auditTrail" });
        // this.getAuditData(1,item.uid,false);
      } else if (link !== "auditTrail") {
        let viewModifyObj = { viewMode: true, item: item };
        let modifyObj = { modifyMode: true };
        viewModifyObj.viewMode = link == "/viewpage" ? true : false;
        modifyObj.modifyMode = link == "/modifypage" ? true : false;

        this.$router.push({
          name: "CreatePoliceStationMaster",
          params: {
            viewMode: link == "/viewpage" ? true : false,
            item: item,
            isView: link == "/viewpage" ? true : false,
            modifyMode: link == "/modifypage" ? true : false,
          },
        });
      }
    },
  },
  created() {
    // this.getAuditData();
    this.handleSearch();
    this.showSearchChip = false;
    this.$store.commit("SAVE_PAGE_HEADER", "Police Station Master Search");
  },
  computed: {
    pageCount() {
      if (!this.totalListCount) {
        return 0;
      } else {
        return Math.ceil(this.totalListCount / this.itemsPerPage);
      }
    },
    pageEndIndex() {
      if (!this.policeStationMasterList.length) {
        return 0;
      } else {
        return this.pageStartIndex + this.itemsPerPage <= this.totalListCount
          ? this.pageStartIndex + this.itemsPerPage - 1
          : this.totalListCount;
      }
    },
  },
};
</script>
