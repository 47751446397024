<template>
    <v-app>
        <v-container class="containerStyle" style="padding:1.5rem; margin:0; max-width:100%">
    <v-layout>
        <h3>Salvage Buyer Master</h3>
        <v-spacer></v-spacer>
        <v-btn class="btnToggle"
      outlined
      elevation="0"
      @click="createMaster"
      >
      <!-- @click="$router.push({name:'CreateFrequency'})" -->
            <v-icon>mdi-plus-box-outline</v-icon>
            <v-divider  ></v-divider>
            Create Salvage Buyer Master
        </v-btn>
    </v-layout>

    <v-layout class="mb-4 mt-7">
      <h4 class="mr-3"><strong>Salvage Buyer Master List <span class="orange--text">({{count}})</span></strong></h4>
      <v-chip small color="#1EBBD9" text-color="white" label close
                     v-if="showSearchChip==true"
                    @click:close="searchSalvageBuyer(1);page_Salvage=1;Reset(false)">Search</v-chip>
      <v-spacer></v-spacer> 
      <v-btn depressed
      color="teal white--text " @click="search_dialog=true">
                <v-icon>mdi-arrow-right-circle</v-icon>
                <v-divider class="mx-4"
                vertical
                color="white" ></v-divider>Search</v-btn>
            </v-layout>
            <!-- Search dialog -->
            <v-dialog v-model="search_dialog" width="800">
                <v-card>
                    <v-card-title
                    class="white--text"
                    style="background-color: #23b1a9"
                  >
                  <h3 class="ml-5">Search Salvage Buyer Master</h3>
        <v-spacer></v-spacer>
        <v-icon @click="search_dialog=!search_dialog" class="white--text">mdi-window-close</v-icon>
                </v-card-title>
                <v-card-text class="grey lighten-2">
                    <v-form ref="SearchSalvageBuyer">
                    <v-layout wrap class="pt-4 form-group-container"> 
                        
                        <!-- <v-flex class="form-groups">
                            <label class="form-label">
                            Salvage Buyer Id <span class="red--text" v-if="search_dialog"><strong>*</strong></span>
                            </label>
                            <v-text-field
                                label="Enter"
                                solo
                                dense 
                                v-model="buyerId"
                                :rules="search_dialog?[(v)=>matchRegex(v,'alphanum'),(v)=>requiredField(v)]:[]"        
                                class="form-control">
                            </v-text-field>
                        </v-flex> -->
                        <v-flex class="form-groups">
                          <label class="form-label">LOB</label>
                          <v-select solo dense class="form-control" v-model="lob" :items="lobOptions" label="Select"></v-select>
                        </v-flex>
                        <v-flex class="form-groups">
                            <label class="form-label">
                                Salvage Buyer Name
                            </label>
                            <v-text-field
                            label="Enter"
                                solo
                                dense
                                :rules="[(v)=>matchRegex(v,'alphanum')]"
                                class="form-control"
                                v-model="buyerName"
                            ></v-text-field>
                        </v-flex>
                        <v-flex class="form-groups">
                            <label class="form-label">PAN Number</label>
                            <v-text-field label="Enter"
                                solo
                                dense
                                :rules="[(v)=>matchRegex(v,'alphanum')]" 
                                class="form-control"
                                v-model="panNumber">
                            </v-text-field>
                        </v-flex>
                        <v-flex class="form-groups">
                            <label class="form-label">GST Number</label>
                            <v-text-field label="Enter"
                                solo
                                dense
                                :rules="[(v)=>matchRegex(v,'alphanum')]" 
                                class="form-control"
                                v-model="gstNumber">
                            </v-text-field>
                        </v-flex>
                        <v-flex class="form-groups">
                            <label class="form-label">PinCode</label>
                            <PincodeSearch
                              :pinCode="postalCode"
                              :label="false"
                              :rules="[(v)=>matchRegex(v,'nums')]"
                              @on-change="change_Pincode" />
                            <!-- <v-text-field label="Enter"
                                solo
                                dense
                                :rules="[(v)=>matchRegex(v,'nums')]" 
                                class="form-control"
                                v-model="postalCode">
                            </v-text-field> -->
                        </v-flex>
                        <v-flex class="form-groups">
                            <label class="form-label">City</label>
                            <v-text-field label="Enter"
                                solo
                                dense 
                                class="form-control"
                                v-model="city">
                            </v-text-field>
                        </v-flex>
                        
                        <v-flex class="form-groups">
          <label class="form-label">
              Status 
          </label>
            <v-select
                v-model="active"
                label="Select"
                :items="[{text:'Active',value:true},{text:'InActive',value:false}]"
                solo
                dense
                class="form-control"
            ></v-select>
            </v-flex>
                    
                    </v-layout>
                </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
        <v-btn class="btn cancelbtn" @click="Reset(true)">Reset</v-btn>
        <v-btn color="#23b1a9" class="btn white--text" @click="searchSalvageBuyer(1)">Search Master</v-btn>
                </v-card-actions>
                </v-card>
            </v-dialog>


    <!-- Main-Table -->
    <v-data-table
    hide-default-footer
    :headers="main_table_header"
    :items="salvage_Buyer_Data"
    class=" vDataTableStyling">
    <template v-slot:[`item.actions`]="{ item }">
                      <v-menu offset-y open-on-hover>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            elevation=""
                            icon
                            v-bind="attrs"
                            v-on="on"
                            style="text-align:center"
                          >
                            <v-icon>mdi mdi-dots-vertical</v-icon>
                          </v-btn>
                        </template>
                        
                        <v-list class="item-actions" >
                         <template >
                          <v-list-item
                           v-for="(actions, index) in actionOptions"
                            :key="index"
                            @click="editmaster(actions.link, index, item, item._id, actions.name)"
                            
                          >
                          <!-- :disabled="actions.name === 'MODIFY' && ['Pending For Approval', 'Approved', 'Auto Approved', 'Rejected'].includes(item.claimNoteStatus)" -->
                            <v-list-item-title :style="actions.color"
                              ><v-icon small>{{ actions.icon }}</v-icon>
                               {{ actions.name }}</v-list-item-title
                            >
                          </v-list-item>
                          </template>
                        </v-list>
                      </v-menu>
                    </template>
                    <template v-slot:[`item.status`]="{ item }">
                        {{item.active ?"Active":"InActive"}}
                    </template>
    </v-data-table>
    <!-- Pagination -->
    <v-layout justify-end align-center class="pb-2 px-0 mr-4">
      <v-row>
    <v-col>
      <v-btn outlined color="red" class="btn" @click="cancel">
        <v-icon> mdi-backspace </v-icon>
        <v-divider vertical class="mx-2"></v-divider> 
        Cancel
      </v-btn>
    </v-col>
  </v-row>
              <div>
                <span style="color:orange">Showing {{ pageStartIndex_Salvage }}-{{ pageEndIndex_Salvage }}</span>
                out of {{ totalListCount_Salvage }} records
              </div>
              <div>
                <v-pagination  v-model="page_Salvage" 
                :length="pageCount_Salvage" 
                :total-visible="5" 
                @input="searchSalvageBuyer(page_Salvage)" color="#152F38"
                ></v-pagination>
              </div>
            </v-layout>

            <!-- For Audit Trail -->
<v-dialog v-model="audit_dialog" width="800">
      <v-card>
        <v-card-title class="white--text" style="background-color: #23b1a9">
          Audit Trail
          <v-spacer></v-spacer>
          <v-icon @click="audit_dialog = !audit_dialog" class="white--text"
            >mdi-window-close</v-icon
          >
        </v-card-title>
        <v-layout class="pt-4 form-group-container pr-4">
          <v-data-table
            :headers="audit_Header"
            hide-default-footer
            :items="audit_Data"
            class="vDataTableStyling w-100"
          >
            <template v-slot:[`item.srno`]="{ index }">
              {{ 1 + index }}
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-btn
                v-if="item.action === 'Modified'"
                color="#FFFFFF"
                class="text-capitalize tablebtn"
                @click="seeModify(item)"
              >
                {{ item.action }}
                <v-icon class="tableicons">mdi-arrow-right-circle</v-icon>
              </v-btn>
              {{ item.action !== "Modified" ? item.action : null }}
            </template>
            <template v-slot:[`item.createdAt`]="{ item }">
              {{ formatDateTime(item.createdAt) }}
            </template>
          </v-data-table>
        </v-layout>
        <v-layout justify-end align-center class="pb-2 px-0 mr-4">
          <div>
            <span style="color: orange"
              >Showing {{ AuditPageStartIndex }}-{{ AuditPageEndIndex }}</span
            >
            out of {{ AuditTotalListCount }} records
          </div>
          <div>
            <v-pagination
              class="small-pagination"
              v-model="AuditPage"
              :length="AuditPageCount"
              :total-visible="5"
              @input="getAuditData(AuditPage, '', true)"
              color="#152F38"
            ></v-pagination>
          </div>
        </v-layout>
      </v-card>
    </v-dialog>

    <!-- Modify dialogue -->
    <v-dialog v-model="seeModifyDialog" width="600">
      <v-card>
        <v-card-title class="white--text"
                    style="background-color: #23b1a9">
          <v-layout justify-space-between>
            <div>
              Modified Field Details
            </div>
          </v-layout>
          <v-btn color="transparent" small :ripple="false" depressed @click="seeModifyDialog = false">
            <v-icon color="white">mdi-close </v-icon>
          </v-btn>
        </v-card-title>
        <div class="scrollable-table">
        <v-data-table disable-pagination :headers="modifyHeaders" 
        :items="modifyItems" hide-default-footer class="pa-4 vDataTableStyling">
        <template v-slot:[`item.srNo`]="{ index }">
                {{ 1 + index }}
              </template>
      </v-data-table>

        </div>
      </v-card>
    </v-dialog>
    </v-container>
    </v-app>
</template>
<script>
import moment from 'moment';
import PincodeSearch from '../../../component/PincodeSearch.vue';
export default{
  components:{
        PincodeSearch,
    },
    data(){
        return{
          lobOptions:[],
          lob:"",
            buyerId:"",// Joi.string().optional().allow(""),
            buyerName:"",// Joi.string().optional().allow(["", null]),
            panNumber:"",// Joi.string().optional().allow(""),
            gstNumber:"",// Joi.string().optional().allow(""),
            city:"",// Joi.string().optional().allow(["", null]),
            postalCode:"",// Joi.string().optional().allow(["", null]),
            active:"",
            search_dialog:false,
            salvage_Buyer_Data:[],
            audit_dialog:false,
        itemsPerPage_Salvage: 10,
      totalListCount_Salvage: 0,
      pageStartIndex_Salvage: 1,
      
      page_Salvage: 1,
            count:0,
            seeModifyDialog:false,
            showSearchChip:false,
            itemsPerPage: 10,
      totalListCount: 0,
      pageStartIndex: 1,
      pageCount: 0,
      page: 1,
      audit_Data:[],
      modifyItems: [],
      audit_Header: [
        { text: "Sr. No.", value: "srno", sortable: false, align: "center" },
        {
          text: "Master ID",
          value: "masterId",
          sortable: false,
          align: "center",
        },
        {
          text: "Action Taken",
          value: "action",
          sortable: false,
          align: "center",
        },
        { text: "User ID", value: "userId", sortable: false, align: "center" },
        {
          text: "User Name",
          value: "userName",
          sortable: false,
          align: "center",
        },
        {
          text: "Time Stamp",
          value: "createdAt",
          sortable: false,
          align: "center",
        },
      ],
    modifyHeaders: [
        {
          text: "Sr. No.",
          value: "srNo",
          align: "center",
          sortable: false,
        },
        {
          text: "Field Name",
          value: "fieldName",
          align: "center",
          sortable: false,
        },
        {
          text: "Old Value",
          value: "oldValue",
          align: "center",
          sortable: false,
        },
        {
          text: "New Value",
          value: "newValue",
          align: "center",
          sortable: false,
        },
      ],
            //table headers
            main_table_header:[{text:"Action",value:"actions", align: "center"},
                                {text:"Master Id",value:"buyerId", align: "center"},
                                {text:"LOB",value:"lob", width: 100, align: "center"},
                                {text:"Salvage Buyer Name",value:"buyerName", align: "center"},
                                {text:"PAN Number",value:"panNumber", align: "center"},
                                {text:"GST Number",value:"gstNumber", align: "center"},
                                {text:"City",value:"city", align: "center"},
                                {text:"Pincode",value:"postalCode", align: "center"},
                                {text:"Status",value:"status", align: "center"}],
            actionOptions: [
                {
                name: "VIEW",
                icon: "mdi-eye-outline",
                // link: "/childrenEducationCoverAddAssessment",
                color: "color:#23b1a9",
                iconColor: "color:#10242B",
                },
                {
                name: "MODIFY",
                icon: "mdi-pencil",
                // link: "/modifypage",
                iconColor: "color:#10242B",
                color: "color:#23b1a9",
                },
                {
                name: "AUDIT TRAIL",
                icon: "mdi-chart-timeline-variant",
                // link: "/modifypage",
                color: "color:orange",
                iconColor: "color:orange",
                }],
      AuditPage: 1,
      AuditTotalListCount: 0,
      AuditPageCount: 0,
      AuditPageStartIndex: 0,
      AuditItemsPerPage: 10,
        }
    },
    methods:{getLOB() {
      let reqData = '';
      this.showProgress();
      this.GETM("getLOB", reqData, (res) => {
          this.hideProgress();
          if(res.data.statusCode === 200) {
              this.lobOptions = res.data.data[0].lineOfBusiness;
          } else {
              this.lobOptions = [];
              this.showAlert(res.data.msg);
          }
      }, (error) => {
          console.log(error);
          this.hideProgress();
      }, true)
  },
      cancel(data) {
    this.showConfirm(
      "Confirmation",
      "Are you sure you want to leave?",
      "Yes",
      "No",
      (Yes) => {
        this.$router.push({ name: "Master" }); //params:{pageNo:this.pageNo}}
      }
    );
  },
        editmaster(link, index, item, id, name,a) {
     if(index == 0){
      this.$router.push({
        name: "CreateSalvageBuyerMaster",
        params: {
          isView: true,
          item: item,
        },
      });
     }
     if(index == 1){
      if(this.checkPermission()){
          return this.showAlert("You don't have permission to create or modify record.")
      }
      this.$router.push({
        name: "CreateSalvageBuyerMaster",
        params: {
          isView: false,
          item: item,
           
        },
      });
     }
     if(index == 2){
      this.audit_dialog=true
      this.getAuditData(1,item)
     }
    //  console.log(a,name,id,link,item)
    },
    formatDate(dateTime) {
      return dateTime ? moment(dateTime).format("DD-MM-YYYY ") : "";
    },
    formatTime(dateTime) {
      return dateTime ? moment(dateTime).format("LT") : "";
    },
    getAuditData(newPageNum,item){
      let reqData = "masterId="+item.buyerId+ "&perPage=" + this.itemsPerPage + "&pageNo=" + newPageNum + '&masterType=salvageBuyer';
      //"&masterId=" + this.auditTrailItem.uid + 
      this.GETM('getAudits',reqData,(res)=>{
        this.audit_Data=res.data.data.data
        this.AuditTotalListCount = res.data.data.total;
                this.AuditPageCount = res.data.data.total_pages;
                this.AuditPageStartIndex = newPageNum == 1 ? 1 : (newPageNum - 1) * this.AuditItemsPerPage + 1;
      }, () => {}, true)
    },
    Reset(bool){
      if(bool){
        this.showConfirm('Confirmation','Are you sure you want to Reset?','Yes','No',(Yes)=>{
        this.lob='',
        this.buyerId="",// Joi.string().optional().allow(""),
        this.buyerName="",// Joi.string().optional().allow(["", null]),
        this.panNumber="",// Joi.string().optional().allow(""),
        this.gstNumber="",// Joi.string().optional().allow(""),
        this.city="",// Joi.string().optional().allow(["", null]),
        this.postalCode=""
        this.active=""
      })
      }else{
        this.lob='',
        this.buyerId="",// Joi.string().optional().allow(""),
        this.buyerName="",// Joi.string().optional().allow(["", null]),
        this.panNumber="",// Joi.string().optional().allow(""),
        this.gstNumber="",// Joi.string().optional().allow(""),
        this.city="",// Joi.string().optional().allow(["", null]),
        this.postalCode=""
        this.active=""
      }
      
        
    },
    seeModify(item) {
      console.log(item.modification)
      this.seeModifyDialog = true;
       this.modifyItems = item.modification
    },
    searchSalvageBuyer(num){
        let salvageData=""
        if(this.search_dialog){
          if(!this.$refs.SearchSalvageBuyer.validate() ){
            return 
        }else{
            this.showSearchChip=true
            salvageData=`lob=${this.lob}&buyerId=${this.buyerId}&buyerName=${this.buyerName}&panNumber=${this.panNumber}&gstNumber=${this.gstNumber}&city=${this.city}&postalCode=${this.postalCode}&active=${this.active}`
          }
        }else{
            salvageData=`page=${num}&pageLimit=${this.itemsPerPage_Salvage}`
            this.showSearchChip=false
            
        }
        this.GETM("SALVAGE_BUYER_SEARCH",salvageData,(res)=>{
            this.salvage_Buyer_Data=res.data.data.data
            console.log(this.salvage_Buyer_Data)
            this.count=res.data.data.total
            this.totalListCount_Salvage = res.data.data.total;
            this.pageStartIndex_Salvage = num == 1 ? 1 : (num - 1) * this.itemsPerPage_Salvage + 1;
            // this.Reset()
            this.search_dialog=false
        },(error) => {
          return error;
        },true)
    
    },
    formatDateTime(dateTime) {
      return dateTime ? moment(dateTime).format("DD-MM-YYYY LT") : ""
    },
    change_Pincode(data){
      if(data){
        this.postalCode = data?.pinCode;
      }
    },
    createMaster(){
      if(this.checkPermission()){
          return this.showAlert("You don't have permission to create or modify record.")
      }
      this.$router.push({name:'CreateSalvageBuyerMaster'})
    }
    },
    created() {
      let newPageNum=1
        this.GETM("SALVAGE_BUYER_SEARCH","",(res)=>{
            this.salvage_Buyer_Data=res.data.data.data
            console.log(this.salvage_Buyer_Data)
            this.count=res.data.data.total
      this.totalListCount_Salvage = res.data.data.total;
            // this.pageCount_Salvage = res.data.data.total_pages;
            this.pageStartIndex_Salvage = newPageNum == 1 ? 1 : (newPageNum - 1) * this.itemsPerPage_Salvage + 1;
        },(error) => {
          return error;
        },true)
        this.getLOB()
      this.$store.commit("SAVE_PAGE_HEADER", "Salvage Buyer Master Search");
    },
    computed:{
        pageCount_Salvage() {
      if (!this.totalListCount_Salvage) {
        return 0;
      } else {
        return Math.ceil(this.totalListCount_Salvage / this.itemsPerPage_Salvage);
      }
    },
    pageEndIndex() {
      if (!this.audit_Data.length) {
        return 0;
      } else {
        return this.pageStartIndex + this.itemsPerPage <= this.totalListCount ? this.pageStartIndex + this.itemsPerPage - 1 : this.totalListCount;
      }
    },
        pageEndIndex_Salvage() {
      if (!this.salvage_Buyer_Data.length) {
        return 0;
      } else {
        return this.pageStartIndex_Salvage + this.itemsPerPage_Salvage <= this.totalListCount_Salvage ? this.pageStartIndex_Salvage + this.itemsPerPage_Salvage - 1 : this.totalListCount_Salvage;
      }
    },
    AuditPageEndIndex() {
      if (!this.audit_Data.length) {
        return 0
      } else {
        return this.AuditPageStartIndex + this.AuditItemsPerPage <=
          this.AuditTotalListCount
          ? this.AuditPageStartIndex + this.AuditItemsPerPage - 1
          : this.AuditTotalListCount
      }
    },
    }
}
</script>

<style scoped>
.btnToggle {
  text-transform: none;
  color: #fff;
  background-color: #1ebbd9;
}
.form-groups{
    max-width:220px;
    margin:0 0 0 10px
}
.btn{
    text-transform: capitalize;
        letter-spacing: 0.4px;
}
.tablebtn {
  background: #ffffff 0% 0% no-repeat padding-box !important;
  box-shadow: inset 0px 2px 4px #00000052 !important;
  border: 0.5px solid #c1c8cc;
  border-radius: 18px !important;
  height: 30px !important;
  text-transform: capitalize !important;
  width: 100%;
  justify-content: space-between;
  font-size: small;
}
.tableicons {
  color: #23b1a9 !important;
}
</style>