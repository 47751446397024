<template>
    <v-app>
        
            
        <div>
        <div>
            <v-card class="mb-4 mx-3 mt-3 cust-bnkAcc-tbl" tile>
                <v-form v-model="valid" ref="form">

				<v-layout  style="background-color:#F7F7F7;align-items:center;">

                    <v-flex xs5>
                        <v-layout row class mt ml-3>
                            <v-flex style="flex: 1;" class="cust_pr mx-2 my-2 ">
                                <label class="font-weight-bold cstm-clr-pr" style=" color: #646666; ">Product Code</label>
                                
                                <v-flex class=" ">
                                    <v-text-field
                                        v-model="createData.productCode"
                                        label="Enter..."
                                        
                                        dense
                                        solo
                                         :maxlength="max10"
                                         counter
                                        class="rounded-0"
                                        :disabled="pdtCdsbld"
                                        :rules="[required(),spclChar(),minLength(3)]"
                                    ></v-text-field>
                                </v-flex>
                            </v-flex>
                            <v-flex style="flex: 1;" class="cust_pr mx-2 my-2 ">
                                <label class="font-weight-bold cstm-clr-pr" style="color: #646666;">Product Description</label>
                                
                                <v-flex class="">
                                    <v-text-field
                                        v-model="createData.productDescription"
                                        label="Enter..."
                                        :rules="[required(),spclChar(),minLength(3)]"
                                        dense
                                        solo
                                        :maxlength="max40"
                                        counter
                                        class="rounded-0"
                                        :disabled='btnshow'
                                    ></v-text-field>
                                </v-flex>
                            </v-flex>
                        </v-layout>
                    </v-flex>
                    <v-flex xs5 class="ml-5 mt-1" >
                        <v-layout row class >
                            <v-flex style="flex: 1;" class="cust_pr mx-2 my-2 ">
                                <label class="font-weight-bold cstm-clr-pr" style=" color: #646666;">Product Effective From</label>
                                
                                <v-menu
                                ref="calender"
                                :close-on-content-click="true"
                                v-model="productFromCalender"
                                :nudge-right="40"
                                
                                transition="scale-transition"
                                
                                max-width="290px"
                                min-width="290px" 
                                hide-details
                                >
                            <template v-slot:activator="{ on, attrs }">

                                <v-text-field
                                    
                                    label="Select.."
                                    :value="productEffectFromdateVlu"
                                    solo
                                    :rules="[required()]"
                                    :disabled='btnshow'
                                    v-bind="attrs"
                                    v-on="on"
                                    class="rounded-0"
                                    readonly
                                ></v-text-field>
                            </template>
                                                        
                                    <v-date-picker
                                        v-model="productEffectFromdate"
                                        no-title
                                        
                                    ></v-date-picker>
                        </v-menu>                                
                            </v-flex>
           
                            <v-flex style="flex: 1;" class="cust_pr mx-2 my-2  ">
                                <label class="font-weight-bold cstm-clr-pr" style="color:#646666;">Product Effective To</label>

                                <v-menu
                                    ref="calender"
                                    :close-on-content-click="true"
                                    v-model="productToCalender"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    full-width
                                    max-width="290px"
                                    min-width="290px" 
                                    hide-details
                                    >
                            <template v-slot:activator="{ on, attrs }">

                                <v-text-field
                                    
                                    label="Select.."
                                    :value="productEffectTodateVlu"
                                    solo
                                    :rules="[required(),dateCheck()]"
                                   v-bind="attrs"
                                    v-on="on"
                                    :disabled='btnshow'
                                    class="rounded-0"
                                    readonly 

                                ></v-text-field>
                            </template>
                                                        
                                    <v-date-picker
                                        v-model="productEffectTodate"
                                        @change="validDateChk"
                                        no-title
                                        
                                    ></v-date-picker>
                            </v-menu>
                            </v-flex>
                        </v-layout>
                    </v-flex>         					
				</v-layout>
<!--  -->
                <v-layout class="" style="background-color:#F7F7F7;align-items:center;">
                    <v-flex xs5 class="mb-2">
                        <v-layout row class="ml-3 mb-2" >
                            <v-flex style="flex: 1;" class="cust_pr mx-2 ">
                                <label class="font-weight-bold cstm-clr-pr" style=" color: #646666;">Reporting Class</label>
                                <v-flex class="">
                                    <v-select
                                        :items="selectReportingClass"
                                        item-text="code"
                                        item-value="_id"
                                        v-model="createData.reportingClass"
                                        label="Select"
                                        @change="selectreport"
                                        return-object
                                        dense
                                        :disabled='btnshow'
                                        solo
                                        :rules="[rules.required]"
                                        class="rounded-0"

                                    ></v-select>
                                </v-flex>
                            </v-flex>
                            <v-flex style="flex: 1;" class="cust_pr mx-2  ">
                                <label class="font-weight-bold cstm-clr-pr" style="color: #646666;">IRDA UIN Number</label>
                                <v-flex class=" ">
                                    <v-text-field
                                        v-model="createData.irdaUinNumber"
                                        label="Enter..."
                                        dense
                                        solo
                                        :disabled='btnshow'
                                        :rules="[required()]"
                                        class="rounded-0"
                                        :maxlength="max50"
                                        counter
                                    ></v-text-field>
                                </v-flex>
                            </v-flex>
                        </v-layout>
                    </v-flex>

                    <v-flex xs5 class="ml-5">
                        <v-layout row class="mb-4"  >
                            <v-flex style="flex: 1;" class="cust_pr mx-2 ">
                                <label class="font-weight-bold cstm-clr-pr" style=" color: #646666;">Product Type</label>
                                <v-flex class="">
                                    <v-select
                                        :items="selectProductType"
                                        item-text="productType"
                                        item-value="_id"
                                        v-model="createData.productType"
                                        label="Select"
                                        @change="selectdProductType"
                                        return-object
                                        dense
                                        :disabled='btnshow'
                                        solo
                                         :rules="[rules.required]"
                                        class="rounded-0"

                                    ></v-select>
                                </v-flex>
                            </v-flex>
                            <v-flex style="flex: 1;" class="cust_pr mx-2  ">
                                <label class="font-weight-bold cstm-clr-pr" style="color: #646666;">Product Status</label>
                                <v-flex class="">
                                    <v-select
                                        :items="selectProductStatus"
                                        item-text="text"
                                        item-value="value"
                                        v-model="createData.productStatus"
                                        label=""
                                        dense
                                        disabled
                                        solo
                                        
                                        class="rounded-0"

                                    ></v-select>
                                </v-flex>
                            </v-flex>
                        </v-layout>
                    </v-flex>      					
				</v-layout>
                </v-form>
                </v-card>
            </div>

            <div class="ml-4" >
                <p class="font-weight-bold cstm-clr-pr" style=" color: #646666;"
                    >List Of Coverages
                </p>
            </div>

             <v-card  class="mx-3 py-2 " tile>
               <v-layout align-center>
                    <!-- value="In-Patient Treatment" -->

                    <v-checkbox
                        dense
                        v-model="allSelctd"
                        @click="selectAllBox"
                        :disabled='btnshow'
                        hide-details
                        class="ml-1 pa-0 pb-2 ma-0"
                        color="blue">
                    <span slot="label" class="font-weight-bold" style="font-size: 12px">Select All</span>
                    </v-checkbox>
    
                </v-layout>
                <div>    
                    <hr class="" color="#C1C8CC" size="0.5" />
                </div>
<!-- 1 -->  
                <v-layout row wrap class="px-2 py-3">
                    <v-flex xs2 v-for="(lst,index) in lstOfCvrgs" :key="index" class="">
                    <v-checkbox
                            v-model="lstIds"
                            color="blue"
                            :value="lst.id"
                            @click="slctCvrg"
                            hide-details
                            dense
                            :disabled='btnshow'
                            class="pa-2 ma-0"
                            ><span slot="label" class="lbl-bgclr font-weight-bold " style="font-size: 11px;word-wrap: break-word; ">{{lst.name}}</span>
                        </v-checkbox>
                        </v-flex>
                </v-layout>
                <p v-if="minCheckBox===true" class="px-3" style="color:#ff5252;font-size: 12px;">Select Anyone Coverage</p>
                

<!-- 3 -->        
        </v-card>

            <div>
            <v-card class="mb-2 mx-3 mt-3 cust-bnkAcc-tbl" style="border:0.5px solid #C1C8CC;" tile>
				<v-layout  style="padding:10px;background-color:#F7F7F7;align-items:center; justify-center; justify-content: flex-end;">
						
                        <div class="">

                        <!-- <v-btn tile   
                                @click="editProduct()"
                                style="font-size:12px" small color="#23B1A9" :disabled="editBtn" class="text-capitalize mx-2 px-3">
                                    <v-icon dark small >edit</v-icon > 
                                    <v-divider vertical class="mx-2"></v-divider>
                                    Edit
                        </v-btn> -->
                        <!-- btnshow=false  -->
                         <v-btn tile  
                         
                                @click="editPdt()"
                                style="font-size:12px;background-color:#23B1A9;color:white" small :dark="!editBtn" :disabled="editBtn" class="text-capitalize mx-2 px-3">
                                    <v-icon dark small >edit</v-icon > 
                                    <v-divider vertical class="mx-2"></v-divider>
                                    Edit
                        </v-btn>

                        <v-btn tile :dark="!sbmtBtndsbld"
                        :disabled="sbmtBtndsbld" 
                                @click="save()"
                                style="font-size:12px;background-color:#23B1A9" small  class="text-capitalize mx-2 px-3">
                                    <v-icon dark small >check_circle</v-icon > 
                                    <v-divider vertical class="mx-2"></v-divider>
                                    Submit
                        </v-btn>

                        <v-btn tile dark  
                                
                                style="font-size:12px;background-color:#152F38" small class="text-capitalize mx-2 px-3"  @click.native="jump('/HomePage')" >
                                    <v-icon dark small >arrow_back</v-icon > 
                            <!-- <div> <img src="../assets/return.png" style="width: 20px;height: 19px"/> </div> -->
                                    <v-divider vertical class="mx-2"></v-divider>
                                    Return
                        </v-btn>

                        <v-btn tile 
                                 @click="crtEdtPlan()"
                                style="font-size:12px;background-color:#E46A25;color:white" small :dark="!planBtn" :disabled="planBtn" class="text-capitalize mx-2 px-3">
                                    <v-icon dark small >edit</v-icon > 
                                    <v-divider vertical class="mx-2"></v-divider>
                                Create/Edit Plan

                        </v-btn>

                        <v-btn tile   
                                @click="crtEdtPlcy()"
                                style="font-size:12px;background-color:#E46A25;color:white" small  :dark="!policyBtn" :disabled="policyBtn" class="text-capitalize mx-2 px-3">
                                    <v-icon dark small >edit</v-icon > 
                                    <v-divider vertical class="mx-2"></v-divider>
                                                            
                                Create/Edit Policy

                        </v-btn>

                        </div>  					
				</v-layout>
                </v-card>
            </div>
            <v-card  class="mx-3 mt-3 cust-bnkAcc-tbl" >             
            </v-card>
        </div>
    </v-app>
</template>

<script>
import axios from "axios";
import moment from 'moment';

export default {
    


    components:{
        
    },

    created() {
        console.log("Success Token", this.$store.getters.successToken)
        this.editBtn=this.$store.getters.fetchBtnName.visible
        // console.log('show edit btn',this.editBtn)

        this.token=this.$store.getters.successToken
        this.$store.commit("SAVE_PAGE_HEADER", "Create/Edit Product");
        this.getProductType()
        this.getReportingClass()
        console.log('Product Id',this.$store.getters.fetchProductCodeData)
        if(this.$store.getters.fetchBtnName.text==='Create'){
            this.createData.productCode=this.$store.getters.fetchProductCodeData
            this.btnshow=false
            this.pdtCdsbld=false
            this.pdtstatus=true
            this.sbmtBtndsbld=false
        }else{
            this.getProductDetailsByID(this.$store.getters.fetchProductCodeData)
        }
        
       
        // this.createData.productCode=this.$store.getters.fetchProductCodeData
        //  if(this.$store.getters.fetchBtnName.text==='Clone' ){
        //     this.fetchProductAll(this.$store.getters.fetchProductItemData)
        //     this.btnshow=true
        //     this.pdtstatus=true
        //  }else if(this.$store.getters.fetchBtnName.text==='Edit' ){
        //     this.ProductCodeAllData(this.$store.getters.fetchProductAllData)
        //     this.btnshow=true
        //  }else if(this.$store.getters.fetchBtnName.text==='Create'){
        //     this.btnshow=false
        //     this.pdtCdsbld=false
        //     this.pdtstatus=true
        //     this.sbmtBtndsbld=false
        //  }else if(this.$store.getters.fetchBtnName.text==='View Work Item'){
        //     this.ProductCodeAllData(this.$store.getters.fetchProductAllData)
        //     this.btnshow=true
        //  }
     

    },

    


     computed: {

        productEffectFromdateVlu(){
            return this.productEffectFromdate ? moment(this.productEffectFromdate).format("DD-MM-YYYY"): ''
        },

         productEffectTodateVlu(){
            return this.productEffectTodate ? moment(this.productEffectTodate).format("DD-MM-YYYY"): ''
        },
        validDateChk(){
            
            // let fromeDate = moment(this.productEffectFromdate,'YYYY-MM-DD').format('DD-MM-YYYY')
            let todayDate = Date.now();
            let Todate= moment(this.productEffectTodate,'YYYY-MM-DD ').toDate().getTime()
            let CurrentDate= todayDate
            console.log(Todate,CurrentDate,"Todate,CurrentDate")

            if(Todate < CurrentDate){
                return this.createData.productStatus="Inactive"
            }else{
                return this.createData.productStatus="Active"
            }
        }
     },

    data() {
        return {
            token:null,
            valid:false,
            fromvalue:"",
            toValu:"",
            pageNumber: 1,
            page:0,
            lstIds:[],
            allSelctd:false,
            sbmtBtndsbld:true,
        createData:{
            productCode:'',
            productDescription:'', 
            reportingClass:'',

            irdaUinNumber:'',
            productType:'',
            productStatus:'Active',
            },

            reportingClassId:'',
            productTypeId:'',

            productEffectFromdate:'',
            productEffectTodate:'',
           pdtCdsbld:true,
           pdtstatus:true,
         prdctCode:false,
         policyBtn:true,
         planBtn:true,

         btnshow:false,
            editBtn:false,
            submit:'',
            returnBack:'',
            createEditPlan:'',
            createEditPolicy:'',

            selectReportingClass:[],
            selectProductType:[],
            selectProductStatus:['Active','Inactive'],

            date:null,
            dob:null,
            calender:false,

            RgstrtnVldUpto:"",
            RgstrnVldUptoInstncalender: false,
            RgstrnVldUptoInstdate: "",

            
            productFromCalender:false,
            
            productToCalender:false,

            minCheckBox:false,
            max10:10,
            max20:20,
            max40:40,
            max50:50,
            clnplanDetails:"",
            newPrduct_id:'',

            
            //  selectBox2:['In-Patient Treatment','Post-Hospitalization','Orgon Donar','Global Cover','Family Transportation Benefit',
            //              'Maternity Cover','Nursing Allowance','Personal Accident','Critical Illness','OPD Treatment','Vaccination Cover',
            //              'Bariatric Surgery Cover','Pre-Hospitalization','Day Care Procedures','Domiciliary','Hospital Cash Benefit',
            //              'Ambulance Cover','Emergency Air Ambulance','Hospital Cash Benefit','Hearing Aid','Vision Care Cover',
            //              'OPD Treatment- Dental',"Health Check Up"],
            selectAll1:[],
             selectBox:[],
            rules: {
                required: value => !!value || 'This Field is Required.',
                spclChar: v=>/^[A-Za-z0-9 ]+$/.test(v) || `Special Characters Not Allowed`,
                
            },

            required(){
            return v=>v && v.length>0 || "This Field is Required"
            },
            minLength(minLength){
                return v=>v && v.length>=minLength || `Must be atleast ${minLength} Characters`
            },
            maxLength(maxLength){
                return v=>v && v.length<=maxLength || `Must be less than ${maxLength} Characters`
            },
            spclChar(){
                    return v=>/^[A-Za-z0-9 ]+$/.test(v) || `Special Characters Not Allowed`
                },
            dateCheck(){
                let todayDate = Date.now();
                let fromDate=moment(this.productEffectFromdate,'YYYY-MM-DD ').toDate().getTime()
                let Todate= moment(this.productEffectTodate,'YYYY-MM-DD ').toDate().getTime()
                console.log(todayDate,"todayDate")
                let CurrentDate= todayDate
                if(Todate < fromDate){
                    // this.showToast("The Product 'Effective date From' can not be greater than Product 'Effective date To'.",this.TOST().WARNING)
                    return "Cannot be less than 'Product Effective From'"
                }
            },
            lstOfCvrgs:[
               {"id":"In-patient hospitalization", "name":"In-patient hospitalization"},
               {"id":"In-patient Dental", "name":"In-patient Dental"},
               {"id":"Pre-Hospitalization", "name":"Pre-Hospitalization"},
               {"id":"Post – Hospitalization", "name":"Post – Hospitalization"},
               {"id":"Day care Coverage", "name":"Day care Coverage"},
               {"id":"Organ Donor", "name":"Organ Donor"},
               {"id":"Bariatric Surgery Cover", "name":"Bariatric Surgery Cover"},
               {"id":"Domiciliary Treatment", "name":"Domiciliary Treatment"},
               {"id":"Restore Benefit Coverage", "name":"Restore Benefit Coverage"},
               {"id":"Ambulance Coverage", "name":"Ambulance Coverage"},
               {"id":"Maternity Coverage", "name":"Maternity Coverage"},
               {"id":'New Born baby cover', "name":'New Born baby cover'},
               {"id":'Family Transportation Benefit', "name":'Family Transportation Benefit'},
               {"id":"Compassionate Travel Coverage", "name":"Compassionate Travel Coverage"},
               {"id":"Global Cover", "name":"Global Cover"},
               {"id":"Prolonged Hospitalization", "name":"Prolonged Hospitalization"},
               {"id":"High End Diagnostics", "name":"High End Diagnostics"},
               {"id":"Hearing Aid", "name":"Hearing Aid"},
               {"id":"Critical Illness", "name":"Critical Illness"},
               {"id":"Nursing Allowance", "name":"Nursing Allowance"},
               {"id":"OPD Treatment", "name":"OPD Treatment"},
               {"id":"Vision Care", "name":"Vision Care"},

               {"id":"Health Check-up Coverage", "name":"Health Check-up Coverage"},
               {"id":"OPD Dental Treatment", "name":"OPD Dental Treatment"},
             
               {"id":"Emergency Air Ambulance", "name":"Emergency Air Ambulance"},
               {"id":"Accidental Death Benefit", "name":"Accidental Death Benefit"},
               {"id":"Permanent Total Disability", "name":"Permanent Total Disability"},
               {"id":"Transportation of mortal remains", "name":"Transportation of mortal remains"},
               {"id":"Funeral Expenses", "name":"Funeral Expenses"},
               {"id":"Education Benefit", "name":"Education Benefit"},
               {"id":"Ayush Benefit", "name":"Ayush Benefit"},
               {"id":"Baby day one cover", "name":"Baby day one cover"},
               {"id":"Infertility Treatment", "name":"Infertility Treatment"},
               {"id":"Vaccination", "name":"Vaccination"},
                {"id":"Hospital Cash Benefit", "name":"Hospital Cash Benefit"},
               {"id":"Hospital cash benefit for accompanying an Insured child", "name":"Hospital cash benefit for accompanying an Insured child"},
               

               
               

            ]

            

        };



    },

    methods: {

        fetchProductAll(e){
            console.log('incomin data all',this.$store.getters.fetchProductItemData)
            // this.$store.getters.fetchProductItemData
            this.createData.productCode=e.productCode
            this.createData.productDescription=e.productDescription,
            this.createData.reportingClass={code:e.reportingClass,_id:e.reportingClassId},

            this.productEffectFromdate=e.productEffectiveFrom,
            this.productEffectTodate=e.productEffectiveTo,

            this.createData.irdaUinNumber=e.irdaUinNumber,
            this.createData.productType={productType:e.productType,_id:e.productTypeId},
            this.createData.productStatus='Active',
            this.lstIds=e.coverages
            if(this.lstIds.length=== this.lstOfCvrgs.length){
                this.allSelctd= true
                console.log(this.allSelctd,"if")
            }else{
                this.allSelctd= false
                console.log(this.allSelctd,"else")
            }

            if(e.productType==="Retail Health"){
                this.clnplanDetails=e.planDetails
            }
           
            

        },

        ProductCodeAllData(d){
            // console.log('incomin productcode data all',this.$store.getters.fetchProductAllData)
            console.log("incoming data",d)
            // return
            // this.$store.getters.fetchProductItemData
            this.createData.productCode=d.productCode
            this.createData.productDescription=d.productDescription,
            this.createData.reportingClass={code:d.reportingClass,_id:d.reportingClassId},

            this.productEffectFromdate=d.productEffectiveFrom,
            this.productEffectTodate=d.productEffectiveTo,

            this.createData.irdaUinNumber=d.irdaUinNumber,
            this.createData.productType={productType:d.productType,_id:d.productTypeId},
            this.createData.productStatus=d.productStatus,
            this.lstIds=d.coverages
            if(this.lstIds.length===this.lstOfCvrgs.length){
                 console.log(this.allSelctd,"if")
                this.allSelctd= true
            }else{
                this.allSelctd= false
                console.log(this.allSelctd,"else")
            }

            if(this.createData.productType.productType==="Group Health"){
                console.log(this.createData.productType,"if this.createData.productType")
                this.policyBtn=false
                this.planBtn=true
                
            }else if(this.createData.productType.productType==="Retail Health"){
               console.log(this.createData.productType,"else this.createData.productType")
                this.policyBtn=true
                this.planBtn=false
                
            }

            

        },

        selectdProductType(){
            console.log("product type data",this.createData.productType)
            // if(this.createData.productType.productType==="Group Health"){
            //     console.log(this.createData.productType,"if this.createData.productType")
            //     this.policyBtn=false
            //     this.planBtn=true
                
            // }else if(this.createData.productType.productType==="Retail Health"){
            //    console.log(this.createData.productType,"else this.createData.productType")
            //     this.policyBtn=true
            //     this.planBtn=false
                
            // }
        },

        selectreport(){
            console.log("Reoprting class data",this.createData.reportingClass)
        },

        selectAllBox(){
            this.lstIds = [];
            if (this.allSelctd) {
                for (let i in this.lstOfCvrgs) {
                    this.lstIds.push(this.lstOfCvrgs[i].id.toString());
                }
                
            }
            if(this.lstIds.length!==0){
                this.minCheckBox=false
            }
        },

        slctCvrg(){
            this.allSelctd = false;
            if(this.lstIds.length!==0){
                this.minCheckBox=false
            }
            if(this.lstIds.length===this.lstOfCvrgs.length){
                console.log(this.allSelctd,"if")
                this.allSelctd= true
            }else{
                this.allSelctd= false
                console.log(this.allSelctd,"else")
            }
        },

        nextPage(page) {
            this.pageNumber = page;
        },
        editBnkAcc(item){
                console.log("item", item)
        },

        save(){ 

            let todayDate = Date.now();
            let fromDate=moment(this.productEffectFromdate,'YYYY-MM-DD ').toDate().getTime()
            let Todate= moment(this.productEffectTodate,'YYYY-MM-DD ').toDate().getTime()
            console.log(todayDate,"todayDate")
            let CurrentDate= todayDate
            if(Todate < fromDate){
                return this.showToast("The Product 'Effective date From' can not be greater than Product 'Effective date To'.",this.TOST().WARNING)
            }

            if(this.$store.getters.fetchBtnName.text==='Edit' ||this.$store.getters.fetchBtnName.text==='View Work Item' ){
                this.editProduct()  
            }else{
                this.createProduct()  
            }
        },

        
        jump(to) {
                if (this.$router) {
                    this.$router.push(to)
                }
            },

            createProduct() {
                    if (this.$refs.form.validate()){
                        let self=this
                        console.log(this.lstIds,"this.lstIds")
                        if (this.lstIds.length===0){
                            this.minCheckBox= true
                            return
                        }
                        // self.showLoader('Loading', true);
                        let demodata={
                            productCode: self.createData.productCode,
                            productDescription: self.createData.productDescription,
                            productEffectiveFrom: self.productEffectFromdate,
                            productEffectiveTo: self.productEffectTodate,

                            reportingClassId: self.createData.reportingClass._id,
                            reportingClass: self.createData.reportingClass.code,

                            productTypeId: self.createData.productType._id,
                            productType: self.createData.productType.productType,

                            irdaUinNumber: self.createData.irdaUinNumber,
                            productStatus: self.createData.productStatus,
                            coverages: self.lstIds,
                        }

                        console.log("from demodata--------->",demodata);

                        axios({
                            method: "post",
                            url:
                                this.API_Service_admin()+"product/create-product",
                            headers: {
                                "Content-Type": "application/json",
                                "x-access-token": self.token
                                // "x-access-token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI1ZmJlMDg4ZGM0NmRhODNlZTdmODcxYmIiLCJyb2xlIjoiQWRtaW5Vc2VyIiwiaWF0IjoxNjA2MzY5MzI0LCJleHAiOjE2Mzc5MDUzMjR9.iS5hZs0uImNFz-rxNaQ7CTCc6qS_hG4H0FsePZ5Sgqc",
                            },
                            data:demodata,
                        })
                        .then(function (response) {
                            console.log(" Create product APi Response>>>>>>", response.data.data._id);
                            self.GetData =response.data  
                            // self.policy=false
                            // self.plan=false
                            self.minCheckBox= false
                            self.sbmtBtndsbld=true
                            self.pdtCdsbld=true
                            self.btnshow=true
                            self.editBtn=true
                            if(self.createData.productType.productType==="Group Health"){
                            console.log(self.createData.productType,"if self.createData.productType")
                            self.policyBtn=false
                            self.planBtn=true
                            
                            }else if(self.createData.productType.productType==="Retail Health"){
                            console.log(self.createData.productType,"else self.createData.productType")
                                self.policyBtn=true
                                self.planBtn=false
                                
                            }
                            self.showToast(response.data.msg ,self.TOST().SUCCESS)
                            self.$store.commit('SAVE_PRODUCT_NAME', response.data.data._id)
                            self.newPrduct_id=response.data.data._id
                            // self.$store.commit('SAVE_PRODUCT_ITEM_DATA', {})
                            // let btnName={
                            //     text:'',
                            //     visible:false
                            // }
                            // self.$store.commit('SAVE_PRODUCT_BTN_NAME', btnName)
                            // self.$store.commit('SAVE_PRODUCT_CODE_ALL_DATA', '')
                            // self.$router.push('/product/createSearchProduct');


                        })
                        .catch(function (error) {
                            console.log(" APi Response>>>>>>", error);
                            self.showToast(error.response.data.msg,self.TOST().ERROR);
                        });
                    }else{
                this.$refs.form.validate()
                this.showToast("Please Fill Required Fields",this.TOST().WARNING);
                }
            },


            getProductType() {
                
                    let self = this;   
                    axios({
                    method: "get",
                    
                    url:
                        this.API_Service_admin()+"product-type/product-type-all", 
                            headers: {
                                "Content-Type": "application/json", 
                                "x-access-token": self.token,
                                //  "x-access-token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI1ZmJlMDg4ZGM0NmRhODNlZTdmODcxYmIiLCJyb2xlIjoiQWRtaW5Vc2VyIiwiaWF0IjoxNjA2MzY5MzI0LCJleHAiOjE2Mzc5MDUzMjR9.iS5hZs0uImNFz-rxNaQ7CTCc6qS_hG4H0FsePZ5Sgqc",
                            }
                        })
                        .then(function(response) {
                    
                            // console.log('get product type data responce------->>>',response.data);
                             
                    
                    let apiData = response.data.data.data

                    // console.log("API RESPONSE for product type:::::",apiData);
                  
                    self.selectProductType = []
                    self.selectProductType= apiData
					
						console.log("product type dropdown DATA is---->>::",self.selectProductType);
			
                        })
                        .catch(function(error) {
                            console.log(error);
                            self.showToast(error.response.data.msg,self.TOST().ERROR);
                        });
             
            },

            getReportingClass() {
                
                    let self = this;   
                    axios({
                    method: "get",
                    
                    url:
                        this.API_Service_admin()+"reporting-class/all", 
                            headers: {
                                "Content-Type": "application/json", 
                                "x-access-token": self.token,
                                //  "x-access-token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI1ZmJlMDg4ZGM0NmRhODNlZTdmODcxYmIiLCJyb2xlIjoiQWRtaW5Vc2VyIiwiaWF0IjoxNjA2MzY5MzI0LCJleHAiOjE2Mzc5MDUzMjR9.iS5hZs0uImNFz-rxNaQ7CTCc6qS_hG4H0FsePZ5Sgqc",
                            }
                        })
                        .then(function(response) {
                    
                            // console.log('get Reporting Class data responce------->>>',response.data);
                             
                    
                    let apiData = response.data.data.list

                    // console.log("API RESPONSE for Reporting class :::::",apiData);
                  
                    self.selectReportingClass = []
                    self.selectReportingClass= apiData
					
						console.log("Reporting class dropdown DATA is---->>::",self.selectReportingClass);
			
                        })
                        .catch(function(error) {
                            console.log(error);
                            self.showToast(error.response.data.msg,self.TOST().ERROR);
                        });
             
            },
            getProductDetailsByID(id){
                
                let self = this; 
                self.showLoader('Loading', true);  
                axios({
                    method: "get",
                    url:
                        this.API_Service_admin()+"product/get-product/"+id, 
                        headers: {
                            "Content-Type": "application/json", 
                            "x-access-token": self.token,
                        }
                })
                .then(function(response) {
                    console.log('Product Details Response------->>>',response.data.data);
                    if(self.$store.getters.fetchBtnName.text==='Clone' ){
                        self.fetchProductAll(response.data.data)
                        self.btnshow=true
                        self.pdtstatus=true
                    }else if(self.$store.getters.fetchBtnName.text==='Edit' ){
                        self.ProductCodeAllData(response.data.data)
                        self.btnshow=true
                    }else if(self.$store.getters.fetchBtnName.text==='View Work Item'){
                        self.ProductCodeAllData(response.data.data)
                        self.btnshow=true
                    }
                    self.showLoader('Loading', false);
                })
                .catch(function(error) {
                    self.showLoader('Loading', false);
                    console.log(error);
                    // self.showToast(error.response.data.msg,self.TOST().ERROR);
                });

            },

            editPdt(){
                this.sbmtBtndsbld=false
                this.policyBtn=true
                this.planBtn=true
                // this.btnshow=false
                if(this.$store.getters.fetchBtnName.text==='Clone' ){
                    this.btnshow=false
                    this.pdtCdsbld=false
                    this.pdtstatus=true
                }else if(this.$store.getters.fetchBtnName.text==='Edit'||this.$store.getters.fetchBtnName.text==='View Work Item'){
                   this.btnshow=false
                    this.pdtCdsbld=true 
                    this.pdtstatus=true
                }
         
            },

            editProduct() {
                    if (this.$refs.form.validate()){
                        let self=this
                        console.log(this.lstIds,"this.lstIds")
                        if (this.lstIds.length===0){
                            this.minCheckBox= true
                            return
                        }
                        // self.showLoader('Loading', true);
                        let id=self.$store.getters.fetchProductCodeData
                        console.log(id, "self.createData.id")
                        console.log(self.createData.productType, "self.createData.productType")
                         console.log(self.createData.reportingClass, "self.createData.reportingClass")
                        let demodata={
                            // productCode: self.createData.productCode,
                            productDescription: self.createData.productDescription,
                            productEffectiveFrom: self.productEffectFromdate,
                            productEffectiveTo: self.productEffectTodate,
                            reportingClassId: self.createData.reportingClass._id,
                            reportingClass: self.createData.reportingClass.code,
                            productTypeId: self.createData.productType._id,
                            productType: self.createData.productType.productType,
                            irdaUinNumber: self.createData.irdaUinNumber,
                            // productStatus: self.createData.productStatus,
                            coverages: self.lstIds,

                        }

                        console.log("from demodata--------->",demodata);

                        axios({
                            method: "put",
                            url:
                                this.API_Service_admin()+"product/update-product/"+id,
                            headers: {
                                "Content-Type": "application/json",
                                "x-access-token": self.token
                            },
                            data:demodata,
                        })
                        .then(function (response) {
                            console.log(" Edit product APi Response>>>>>>", response); 
                            self.showToast(response.data.msg ,self.TOST().SUCCESS)
                             self.sbmtBtndsbld=true
                            self.pdtCdsbld=true
                            self.btnshow=true
                            // self.editBtn=true
                            if(self.createData.productType.productType==="Group Health"){
                            console.log(self.createData.productType,"if self.createData.productType")
                            self.policyBtn=false
                            self.planBtn=true
                            
                            }else if(self.createData.productType.productType==="Retail Health"){
                            console.log(self.createData.productType,"else self.createData.productType")
                                self.policyBtn=true
                                self.planBtn=false
                                
                            }
                            // self.$store.commit('SAVE_PRODUCT_NAME', '')
                            // self.$store.commit('SAVE_PRODUCT_ITEM_DATA', {})
                            // let btnName={
                            //     text:'',
                            //     visible:false
                            // }
                            // self.$store.commit('SAVE_PRODUCT_BTN_NAME', btnName)
                            // self.$store.commit('SAVE_PRODUCT_CODE_ALL_DATA', '')
                            // self.$router.push('/product/createSearchProduct');
 
                        })
                        .catch(function (error) {
                            console.log(" APi Response>>>>>>", error);
                             self.showToast(error.response.data.msg,self.TOST().ERROR);

                        });
                    }else{
                this.$refs.form.validate()
                this.showToast("Please Fill Required Fields",this.TOST().WARNING);
                }
            },

            crtEdtPlcy(){
                console.log(this.productEffectFromdate, "productEffectFromdate")
                console.log(this.productEffectTodate, "productEffectTodate")
                // console.log(this.$store.getters.fetchBtnName, "store Data")
                let pdtDate={
                    fromDate:this.productEffectFromdate,
                    toDate:this.productEffectTodate,
                    policyFromDate:null,
                    policyToDate:null
                }
                this.$store.commit('SAVE_PDT_DATE_FOR_PLAN', pdtDate)
                this.$router.push('/product/policysearch');
            },
            crtEdtPlan(){

                console.log(this.productEffectFromdate, "productEffectFromdate")
                console.log(this.productEffectTodate, "productEffectTodate")
                console.log(this.$store.getters.fetchBtnName, "store Data")
                let pdtDate={
                    fromDate:this.productEffectFromdate,
                    toDate:this.productEffectTodate,
                    policyFromDate:null,
                    policyToDate:null
                }
                this.$store.commit('SAVE_PDT_DATE_FOR_PLAN', pdtDate)
                if(this.$store.getters.fetchBtnName.text==="Create"){
                    this.$router.push('/product/createditplan');
                }
                if(this.$store.getters.fetchBtnName.text==="Edit"||this.$store.getters.fetchBtnName.text==='View Work Item'){
                    this.$router.push('/product/createditplan');
                }
                if(this.$store.getters.fetchBtnName.text==="Clone"){

                    console.log(this.clnplanDetails,"clnplanDetails")

                    
                    if(!this.clnplanDetails || this.clnplanDetails.length ===0){
                        this.$router.push('/product/createditplan')

                    }else{
                        let demodataArr=[]
                            // demodataArr=this.$store.getters.fetchGrpPlcyDtaFrCln.planDetails

                            this.clnplanDetails.map((itm) => {
                            demodataArr.push({

                                deductibleWaitingPeriodDetails:itm.deductibleWaitingPeriodDetails,
                                generalExclusions:itm.generalExclusions,
                                relationShipDependentInfo:itm.relationShipDependentInfo,
                                planName: "",
                                planDescription: itm.planDescription,
                                planEffectiveDate: itm.planEffectiveDate,
                                planExpiryDate: itm.planExpiryDate,
                                planstatus: itm.planstatus,
                                planType:"clone",
                                productCode: this.createData.productCode,
                                productDescription: this.createData.productDescription,
                                productId: this.$store.getters.fetchProductCodeData,
                                externalRefNo: itm.externalRefNo

                            });
                            });
                  
                    console.log("from demodataArr--------->",demodataArr);
                    let self=this
                    axios({
                        method: "post",
                        url:
                            self.API_Service_admin()+"retail-plan/clone-plan",
                        headers: {
                            "Content-Type": "application/json",
                            "x-access-token":self.token,
                        },
                        data:demodataArr,
                    })
                    .then(function (response) {
                        console.log("Create reporting>>>>>>", response);
                        self.$router.push('/product/createditplan');
                    })
                    .catch(function (error) {
                        console.log("Create reporting Error>>>>>>", error);
                        self.showToast(error.response.data.msg,self.TOST().ERROR);
                    });
                }
    
                }
                
                
            }

    },

    watch: {
    
    },
    
    
}

</script>
<style >
.cust_pr .v-text-field .v-input__slot,
.v-text-field--outline .v-input__slot {
    min-height: 42px !important;
    display: flex !important;
    align-items: center !important;
    /* border:1px red solid; */
}

.pending-work{
    justify-items: start;
}

.lay{
        direction: rtl;
        max-height: 50px;

}
.body-2{
        font-size:20px !important;

}
.body-3{
        font-size:18px !important;

}
.verticalline{
        height: 16px !important;
        width: 1px !important;
        margin-right: 8px !important;
        margin-left: 0px;
        background-color: #C1C8CC !important;


}
.cstm-clr-pr{
    color:#10242B;
    margin-bottom: 5px;
}
.cust-bnkAcc-tbl table.v-table thead th{
    border-right:1px solid  #C1C8CC !important;
    /* background-color:#F3FBFB !important; */
    color:#10242B !important;
    font-size:13px !important;
    font-family:Roboto !important;
    font-weight:700 !important;

}
.cust-bnkAcc-tbl table.v-table thead th:last-child{
    border-right: unset !important;
    
}
.cust-bnkAcc-tbl table.v-table tbody td,
.cust-bnkAcc-tbl table.v-table thead tr{
    height: 40px !important;
}
.cust-bnkAcc-tbl table.v-table thead tr:last-child{
    height: unset !important;
    
}


.bnkAcc-tbl-brdr{
    border:1px solid  #C1C8CC;
}
 .bnkAcc-td-bdrgt {
    border-right:1px solid #C1C8CC;
 }

 .cust-bnkAcc-pgn .v-pagination__navigation{
        box-shadow:unset !important;
        border-radius: unset !important;
        background-color:#FFFFFF !important;
        border:1px solid #DAE1E6 !important
    }
    .cust-bnkAcc-pgn .v-pagination__navigation--disabled{
        background-color:#DAE1E6 !important;
    }
    .cust-bnkAcc-pgn .v-pagination__item{
        box-shadow:unset !important;
        min-width:2rem !important;
        height:2rem !important;
        border-radius: unset !important;
        border:1px solid #DAE1E6 !important
    }   

    .cust-checkbox{
        color: #000;
    }
</style>