<template>
  <div>
    <v-dialog v-model="openNotesPopup" width="1200">
      <v-card-title class="white--text blueBackground">
        <v-layout justify-space-between>
          <div>
            Notes
          </div>
        </v-layout>
        <v-btn small @click="closeDialog" color="transparent">
          <v-icon color="white">mdi-close </v-icon>
        </v-btn>
      </v-card-title>
        <v-form ref="notesref" @submit.prevent="saveNote" class="greyBackground">
          <v-row class="">
            <v-col cols="4" class="mr-5 ml-2">
              <label class="form-label">
                Note Type
                <span class="red--text"><strong> * </strong></span>
              </label>
            <v-select solo dense class="" placeholder="Enter" :rules="[(v) => requiredField(v)]" :items="remarkTypeList"
              v-model="formData.noteType" clearable>
            </v-select>
          </v-col>
          <v-col cols="6" class="mr-3">
            <label class="form-label">
              Notes
              <span class="red--text"><strong> * </strong></span>
            </label>
            <v-text-field placeholder="Select" class="" v-model="formData.noteText" :rules="[(v) => requiredField(v)]"
              solo dense clearable>
            </v-text-field>
          </v-col>
          <v-col class="ml-2 mt-6">
            <v-btn class="submitButton" type="submit" dense depressed :disabled="buttonDisabled">
              <v-icon class="mr-2 iconsize">mdi-check-circle</v-icon>
              Save
            </v-btn>
          </v-col>
          </v-row>
          <!-- <v-col align="right" class="py-0 mr-5">
            <v-btn class="createbtn ml-2" color="#e46a25" dark @click="exportNote()">
              <v-icon class="mr-2 fontStyle">mdi-download</v-icon>
              <div class="vertical_line"></div>
              Export
            </v-btn>
          </v-col> -->
        <v-row class="mt-3 mb-3 ml-2">
          <v-data-table :headers="tableHeaders" :items="gridData" :hide-default-footer="true" item-key="_id" dense
            class="mr-8 remarksTable">
            <template v-slot:[`item.createdAt`]="{ item }">
              {{ formatDateTime(item.createdAt) }}
            </template>
          </v-data-table>
          <v-layout fluid class="mr-7">
            <v-layout justify-end>
              <div class="marginTop">
                <span class="orangetext">
                  Showing {{ totalListCount ? pageStartIndex : 1 }} -
                  {{ pageEndIndex }}
                </span>
                <span class="mr-2"> out of {{ totalListCount }} records </span>
              </div>
              <v-pagination v-model="page" :length="pageCount" :total-visible="itemsPerPage" @input="getPagination"
                color="#152F38"></v-pagination>
            </v-layout>
          </v-layout>

        </v-row>
      </v-form>
    </v-dialog>
  </div>
</template>
  
<script>
import moment from 'moment'
import axios from 'axios'
import { commomAuxilaryBaseURL } from "../common/API_Config.js";
export default {
  name: "Notes",
  data() {
    return {
      totalListCount: 0,
      pageStartIndex: 0,
      page: 1,
      itemsPerPage: 10,
      formData: {
        noteType: "",
        noteText: "",
        claimNumber: "",
        lobType: "",
        featureNumber: "",
      },
      noteText: "",
      remarkTypeList: [
        "General",
      ],
      toggleDetails: false,
      gridData: [],
      tableHeaders: [
        {
          text: "User NTID",
          value: "createdByNtid",
          sortable: false,
          align: "center",
          width: "150px",
        },
        {
          text: "User Name",
          value: "createdBy",
          sortable: false,
          align: "center",
          width: "150px",
        },
        {
          text: "Date and Time",
          value: "createdAt",
          sortable: false,
          align: "center",
          width: "170px",
        },
        {
          text: "Note Type",
          value: "noteType",
          sortable: false,
          align: "center",
          width: "160px",
        },
        {
          text: "Notes",
          value: "noteText",
          sortable: false,
          align: "center",
        },
      ],
    };
  },
  props: ["openNotesPopup", "claimNumber", "featureNumber", "lobType"],
  computed: {
    buttonDisabled() {
      return !(this.formData.noteText.length > 0 && this.formData.noteType)
    },
    pageCount() {
      if (!this.totalListCount) {
        return 0;
      } else {
        return Math.ceil(this.totalListCount / this.itemsPerPage);
      }
    },
    pageEndIndex() {
      if (!this.gridData.length) {
        return 0;
      } else {
        return this.pageStartIndex + this.itemsPerPage <= this.totalListCount
          ? this.pageStartIndex + this.itemsPerPage - 1
          : this.totalListCount;
      }
    },
  },

  created() {
    if (this.claimNumber && this.featureNumber) {
      this.getNotes();
    }
  },
  methods: {
    formatDateTime(date) {
      return moment(date).format('LLL')
    },
    closeDialog() {
      this.openNotesPopup = false
      this.$emit('closeNotes');
    },
    async getNotes() {
      if (this.claimNumber && this.featureNumber && this.lobType) {
        const res = await axios.get(
          commomAuxilaryBaseURL + `litigation/get-litigation-notes?claimNumber=${this.claimNumber}&featureNumber=${this.featureNumber}&lobType=${this.lobType}`,
          {
            headers: {
              "Content-Type": "application/json",
              system: "Health",
            },
          }
        )
        if (res.data.statusCode == 200) {
          if (
            res.data.statusCode == 200 &&
            res.data.data &&
            res.data.data.data.length > 0
          ) {
            this.gridData = res.data.data.data;
            this.totalListCount = res.data.data.data.length;
            this.getPagination(1);
          } else {
            this.gridData = [];
          }
        }
        else {
          this.showToast(res.data.msg, this.TOST().ERROR);
        }
      }
    },

    getPagination(newPageNum) {
      this.page = newPageNum;
      this.pageStartIndex =
        newPageNum == 1 ? 1 : (newPageNum - 1) * this.itemsPerPage + 1;
      let clone = JSON.parse(JSON.stringify(this.gridData));
      this.gridData = clone.splice(this.pageStartIndex - 1, this.pageEndIndex);
    },
    async saveNote() {
      this.formData.claimNumber = this.claimNumber
      this.formData.featureNumber = this.featureNumber
      this.formData.lobType = this.lobType
      const res = await axios.post(
        commomAuxilaryBaseURL + "litigation/create-litigation-notes",
        this.formData,
        {
          headers: {
            "Content-Type": "application/json",
            system: "Health",
          },
        }
      );
      if (res.data.statusCode == 200) {
        this.showToast(res?.data?.msg || "Success", this.TOST().SUCCESS)
        this.getNotes();
        this.formData.noteText = "";
        this.formData.noteType = "";
        this.formData.featureNumber = "";
        this.formdata.lobType = ""

        this.formData.claimNumber = ""
        this.$refs.notesref.resetValidation();
      } else {
        this.showToast(res.data.msg, this.TOST().ERROR);
      }
    },
  },
};
</script>
  
  <style scoped>
  .claim-details,
  .claim-row {
    background-color: #efeef4;
    border: 1px solid #00000052;
    padding: 12px !important;
    margin: 0px 20px;
  }
  .divider-menus {
    margin-top: 5px;
  }
  .claim-details,
  .claim-row {
    background-color: #efeef4;
    border: 1px solid #00000052;
    padding: 12px !important;
    margin: 0px 20px;
  }
  .divider-menus {
    margin-top: 5px;
  }
  
  
  .claim-main-row {
    width: 80%;
    margin: 0% 10%;
  }

  .claim-main-row {
    width: 80%;
    margin: 0% 10%;
  }
  
  
  .v-application .ml-3 {
    margin: 0px 5% !important;
  }
  .container {
    padding: 0px 12px;
  }

  .v-application .ml-3 {
    margin: 0px 5% !important;
  }
  .container {
    padding: 0px 12px;
  }
label {
  font: bold 14px Roboto;
  color: #10242b;
}

.menus_btn {
  font: medium 12px Roboto !important;
  color: #10242b;
  padding-left: 5px;
}

.master-single-card:not(:last-child) {
  margin-right: 12px;
  margin-bottom: 5px;
}

.master-single-card:hover {
  border-color: teal;
}

.master-single-card {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 194px;
  height: 150px;
  padding: 0 15px;
  margin-top: 8px;
  box-shadow: 0px 4px 8px #0000000a;
  border: 2px solid #e0e0e0;
  border-radius: 4px;
}

.v-sheet.v-toolbar:not(.v-sheet--outlined) {
  box-shadow: 0px 0px 0px 0px rgb(0 0 0), 0px 0px 0px 0px rgb(0 0 0),
    0px 0px 0px 0px rgb(0 0 0) !important;
}

.v-btn--is-elevated {
  box-shadow: 0px 0px 0px 0px rgb(0 0 0), 0px 0px 0px 0px rgb(0 0 0),
    0px 0px 0px 0px rgb(0 0 0) !important;
}

.theme--light.v-btn.v-btn--has-bg {
  background-color: #efeef4;
  color: #23b1a9;
}

.col-sm-2 {
  padding: 0px;
}

.headline {
  font-size: 10px !important;
  font-weight: bold;
  text-align: center;
  line-height: 20px;
}


.card:hover {
  border: 2px solid teal;
  cursor: pointer;
}


p {
  font: normal 14px Roboto !important;
  color: #10242b;
}



.headerstyle tr th {
  background-color: #f5535370 !important;
  color: rgb(189, 69, 69) !important;
  font-size: 15px !important;
  height: 40px !important;
  border-right: none;
  border-collapse: collapse;
  font-weight: bold;
  text-align: center !important;
}


.remarksTable {
  width: 100%;
}

.iconsize {
  font-size: 17px;
}

.orangetext {
  color: #e46a25;
  /* font-size: 17px; */
}

.greyBackground{
  background-color: #999999;
}

.blueBackground{
  background-color: #23b1a9;
}

.submitButton{
  color: white; 
  background-color: #111
}

.marginTop{
  margin-top: 15px;
}

</style>
  