<template>
    <v-app>
        
                    <v-card class="mx-3 mb-3 mt-3" color="#F7F7F7" style="background-color: #F7F7F7;" tile>
                <v-form v-model="valid" ref="form">

                <v-layout color="#F7F7F7" class="ma-2" >
                    
                    <v-flex xs3>
                        <v-layout column class=" mx-2 my-2 cust_pd">
                            <v-text class="font-weight-bold cstm-clr-psap">
                                Product Code
                            </v-text>
                            <v-text-field
                                solo
                                label="Enter..."
                                v-model="productCode"
                                :rules="[required('Reporting Class Description'),minLength(3)]"
                                    counter="10"
                                    :maxlength="maxCount"
                                
                                class="rounded-0">
                            </v-text-field>
                        </v-layout>
                    </v-flex>
                    <v-flex xs3>
                        <v-layout column class="cust_pd mx-2 my-2">
                            <v-text class="font-weight-bold cstm-clr-psap">
                                Product Description
                            </v-text>

                            <v-text-field
                                solo
                                label="Enter..."                                
                                v-model="productDescription"
                                class="rounded-0">
                            </v-text-field>
                        </v-layout>
                    </v-flex>
      
                </v-layout>
                </v-form>
            </v-card>
     

            
            <v-card class="mb-2 mx-3 mt-3 " style="border:0.5px solid #C1C8CC;" tile>
				<v-layout  style="padding:10px;background-color:#F7F7F7;align-items:center; justify-center; justify-content: flex-end;">
						


                        <div class="">
                            <!-- <v-btn tile dark  
                                    @click="showSearchResult()"
                                    style="font-size:12px" small color="#E46A25" class="text-capitalize mx-2 px-3">
                                        <v-icon dark small >search</v-icon > 
                                        <v-divider vertical class="mx-2"></v-divider>
                                        Search
                            </v-btn> -->
                            <v-btn tile dark  
                                    @click="SearchProduct()"
                                    style="font-size:12px;background-color:#E46A25" small  class="text-capitalize mx-2 px-3">
                                        <v-icon dark small >search</v-icon > 
                                        <v-divider vertical class="mx-2"></v-divider>
                                        Search
                            </v-btn>

                            <v-btn tile dark @click="$router.push('/homePage')" style="font-size:12px;background-color:#152F38" small class="text-capitalize mx-2 px-3">
                                        <v-icon dark small >arrow_back</v-icon > 
                                        <v-divider vertical class="mx-2"></v-divider>
                                        Return
                            </v-btn>

                            <v-btn tile  :dark="!clonebtn" style="font-size:12px;background-color:#23B1A9" small class="text-capitalize mx-2 px-3" 
                            :disabled="clonebtn" @click="sendProductData(selected[0])">
                                        <v-icon dark small >edit</v-icon > 
                                        <v-divider vertical class="mx-2"></v-divider>
                                        Clone
                            </v-btn>
                                <!-- :dark="!showtable" :disabled="showtable"  -->
                            <v-btn tile dark   
                                    style="font-size:12px;background-color:#23B1A9" small class="text-capitalize mx-2 px-3"  
                                    @click="CreatePdt()"
                                    >
                                        <v-icon dark small >add_circle</v-icon > 
                                        <v-divider vertical class="mx-2"></v-divider>
                                        Create
                            </v-btn>

                            <!-- @click.native="jump('/CreateEditProduct')" -->

                            <!-- <v-btn tile dark   
                                    style="font-size:12px" small color="#23B1A9" class="text-capitalize mx-2 px-3"  @click="showCreateData()">
                                        <v-icon dark small >add_circle</v-icon > 
                                        <v-divider vertical class="mx-2"></v-divider>
                                        Create
                            </v-btn> -->
                    
                            </div>                 					
				</v-layout>
                </v-card>

                <v-card v-if="showNosearch=== true" style="" tile class="mx-3 mt-3" >
                    <p class="mb-0 pa-4" 
                       style=" color: #646666; font-family: roboto; font-weight: 700;font-size:13px"
                       >No Search Result Found
                    </p>
                </v-card>


    
    
            


            <v-card v-if="showtable=== true" class="mx-3 mt-3 cust-brd-rght " tile>
                <!-- :page.sync="page"
                    :items-per-page="itemsPerPage"
                     @page-count="pageCount = $event" -->
                <v-data-table
                    :headers="headers"
                    :items="GetData"
                    hide-default-footer
                    dense
                    v-model="selected"
                    :single-select="true"
                    show-select
                    item-key="productCode"
                    disable-pagination

                >
                
                    <!-- <template v-slot:item.checkbox  style=""> -->

                    <!-- <template v-slot:item.ch={item}  style="">
                        <v-layout justify-center  style="">
                                <v-simple-checkbox  color="blue" v-model="item.cb" @input="showCloneBtn(item)" :value="item.cb" class=""></v-simple-checkbox>
                        </v-layout>   
                    </template> -->

                   <template v-slot:item.productCode={item}>
                        <div >
                                <v-tooltip right >
                                    <template v-slot:activator="{ on }">
                                        <div v-on="on" @click="sndPdctCdDtafrEdt(item)" style="text-decoration:underline;color:#1976d2;cursor:pointer">
                                            {{ item.productCode |truncate(16, '...')}}
                                        </div>
                                    </template>
                                        <span>{{item.productCode}}</span>
                                    
                                </v-tooltip>
                        </div>      
                    </template>
                     <template v-slot:item.productDescription={item}>
                        <div>
                                <v-tooltip right >
                                    <template v-slot:activator="{ on }">
                                        <div v-on="on" >
                                            {{ item.productDescription |truncate(16, '...')}}
                                        </div>
                                    </template>
                                        <span>{{item.productDescription}}</span>
                                    
                                </v-tooltip>
                        </div>
                    </template>
                    <template v-slot:item.productEffectiveFrom={item}>
                        <tr>
                            <td class="" >{{ item.productEffectiveFrom |formatDate}} </td>
                            
                        </tr>
                    </template>
                    <template v-slot:item.productEffectiveTo={item}>
                        <tr>
                            <td class="" >{{ item.productEffectiveTo | formatDate}} </td>
                            
                        </tr>
                    </template>
                    
                    <!-- <template v-slot:item.productStatus={item}>
                        <tr>
                            <td class="" >{{ item.productEffectiveTo | validDateChk }} </td>
                            
                        </tr>
                    </template> -->
                            
                </v-data-table>



                        <v-divider></v-divider>
                        <v-layout row justify-end align-center class="ma-2">
                            <span class="mr-1 font-weight-bold body-1" style="color:#3C3D3D; font-size:12px !important">
                                    <span class="" style="color:#E46A25;font-size:12px;">Showing {{fromvalue}}-{{toValu}} </span>out of {{totalCount}} records

                            </span>
                            <v-divider  class="ma-2" vertical ></v-divider>
                            <v-pagination
                                class="pdct-stUp-bnkAcc-pgn"
                                v-model="page"
                                color="#152F38"
                                flat
                                @input="nextPageData(page)"
                                :length="pageCount"
                            ></v-pagination>
                        </v-layout>
                        
            
        </v-card>
        <router-view></router-view>
    </v-app>
</template>

<script>
import axios from "axios";
import moment from 'moment';

export default {

    components:{
        
    },



    created() {
        
        console.log("Success Token for create search product", this.$store.getters.successToken)
        this.token=this.$store.getters.successToken
        this.$store.commit("SAVE_PAGE_HEADER", "Create/Search Product");
        this.getCreateProduct(this.page);
    },

    filters:{
        formatDate(data){// eslint-disable-line no-mixed-spaces-and-tabs
                    // console.log('Validity Data', data);

                    if (data == "" || data == "undefined" || data == null || data == '' || data == 'Invalid Date') {
                        return "-"
                    } else {
                        // var n = new Date(data);
                        // return n.toLocaleString("en-IN")
                        return moment(data,'YYYY-MM-DD').format('DD-MM-YYYY')
                    }
                },
            truncate: function (text, length, suffix) {
                if (text.length > length) {
                    return text.substring(0, length) + suffix;
                } else {
                    return text;
                }
            },  

        },

     computed: {
            hasAdditional(e) {
                return e.length > 0
            },

             clonebtn() {
                
                if(this.selected.length===0){
                    return true
                }else{
                    return false
                }
            },

        
        // productEffectFromdateVlu(){
        //     return this.productEffectiveFrom ? moment(this.productEffectiveFrom).format("DD-MM-YYYY"): ''
        // },

        //  productEffectTodateVlu(){
        //     return this.productEffectiveTo ? moment(this.productEffectiveTo).format("DD-MM-YYYY"): ''
        // },
    

        //  pages () {

        //      console.log(this.size ? Math.ceil(this.GetData.length / this.size) : 0)
        //      return this.size ? Math.ceil(this.GetData.length / this.size) : 0
        //  },

        //  pageCount() {
        //      let l = this.GetData.length,
        //          s = this.size;
        //      return Math.ceil(l / s) - 1;
        //  },
    //     paginatedData() {
    //         const start = this.pageNumber * this.size - this.size,
    //         end = start + this.size;
    //         console.log(start,end,"start End")

    //         if(!this.GetData || this.GetData.length == 0){
    //             this.fromvalue=0
    //         }else{
    //             this.fromvalue=start+1;
    //         }

            
    //         if(this.GetData.length <this.size){
    //             this.toValu=this.GetData.length
    //         }else if(this.GetData.length <end){
    //                 this.toValu=this.GetData.length
    //         }else{
    //             this.toValu=end
    //         }

    //         console.log(this.fromvalue,this.toValu,"this.fromvalue this.toValu")
    //         return this.GetData.slice(start, end);
    //     }

     },

    data() {
        return {

            email: '',
        // rules: {
        //   required: value => !!value || 'This Field is Required.',
          
        // },

         required(){
            return v=>v && v.length>0 || "This Field is Required"
          },
          minLength(minLength){
            return v=>v && v.length>=minLength || `Must be atleast ${minLength} Characters`
          },
          maxLength(maxLength){
            return v=>v && v.length<=maxLength || `Must be less than ${maxLength} Characters`
          },
          spclChar(){
                return v=>/^[A-Za-z0-9 ]+$/.test(v) || `Special Characters Not Allowed`
            },
            token:null,
            valid:false,
            srchApicall:false,
            selected:[],
            fromvalue:"",
            toValu:"",
            pageNumber: 1,
            pageCount: 0,
            totalCount:0,
            page:1,
            itemsPerPage:10,
            crntPgNo:0,
            maxCount:10,

            jumpCreateProduct:false,
            // clonebtn:'',
            productCode:'',
            productDescription:'',

            showCreate:true,

            showNosearch:false,
            showtable:true,
            returnBack:'',
            clone:'',
            create:'',
            
            checkbox:false,
            incomData:{},
            data:{},

            GetData:[],
            
            GetData1:[
                {
                    reportingClass:this.reportingClass,
                    productCode:"MEDB",
                    productDescription:"Medicare Basic",
                    productEffectiveDate:"19-12-2019",
                    productExpiryDate:"19-12-2019",
                    productStatus:"Active",
                    productType:"Retail Health",            
                    checkbox:false

                },{
                    reportingClass:"HLTH",
                    ProductCode:"MEDB",
                    ProductDescription:"Medicare Basic",
                    ProductEffectiveDate:"19-12-2019",
                    ProductExpiryDate:"19-12-2019",
                    ProductStatus:"Active",
                    ProductType:"Retail Health",
                    checkbox:false
                },{
                    reportingClass:"HLTH",
                    ProductCode:"MEDB",
                    ProductDescription:"Medicare Basic",
                    ProductEffectiveDate:"19-12-2019",
                    ProductExpiryDate:"19-12-2019",
                    ProductStatus:"Active",
                    ProductType:"Retail Health",
                    checkbox:false
                }
            ],
            size: 10,
            headers:[
                
                // {text: '', value: 'ch',sortable: false, divider:true,class:"hdr-txt-clr"},
                {text: 'Reporting Class', value: 'reportingClass',sortable: false, divider:true,class:"hdr-txt-clr"},
                {text: 'Product Code', value: 'productCode', sortable: false, divider:true,class:"hdr-txt-clr"},
                {text: 'Product Description', value: 'productDescription',sortable: false, divider:true,class:"hdr-txt-clr"},
                {text: 'Product Effective Date', value: 'productEffectiveFrom',sortable: false, divider:true,class:"hdr-txt-clr"},
                {text: 'Product Expiry Date', value: 'productEffectiveTo',sortable: false, divider:true,class:"hdr-txt-clr"},
                {text: 'Product Status', value: 'productStatus',sortable: false, divider:true,class:"hdr-txt-clr"},
                {text: 'Product Type', value: 'productType',sortable: false, divider:true,class:"hdr-txt-clr"},


            ],
            

        };
    },

    

    methods: {

        showCloneBtn(item){
            console.log('change clone btn',this.checkbox)
                        console.log('change clone data item',item)
                        this.GetData.map((e)=>{
                            e.cb=false
                            
                        })
                        this.$forceUpdate()
                        // var pos=this.GetData.findIndex(e=> e._id=== item._id)
                        // if (pos!==-1){
                        //     console.log('pos',pos)
                        //     this.GetData[pos].checkbox=true
                        //     this.incomData={}
                        //     this.incomData=this.GetData[pos]
                        // }
                        // console.log('in clone btn',this.incomData)
                        


            
            // if(this.checkbox==true){
            // this.clonebtn='';
            
            // }else 
            // {
            // this.clonebtn=false;
            // }

        },

        sndPdctCdDtafrEdt(d){
                console.log('product code data item--->',d)
                // return
                this.$store.commit('SAVE_PRODUCT_NAME', d._id)
                // this.$store.commit('SAVE_PRODUCT_CODE_ALL_DATA', d)
                let btnName={
                        text:'Edit',
                        visible:false
                    }
                this.$store.commit('SAVE_PRODUCT_BTN_NAME', btnName)
                this.$router.push('/product/createEditProduct')
        },

        sendProductData(e){
             console.log('selected data',this.selected)
            
                console.log('incoming product data item',e)
                    this.$store.commit('SAVE_PRODUCT_NAME', e._id)

                    // this.$store.commit('SAVE_PRODUCT_ITEM_DATA', e)
                    let btnName={
                        text:'Clone',
                        visible:false
                    }
                    this.$store.commit('SAVE_PRODUCT_BTN_NAME', btnName)
                    
                    this.$router.push('/product/createEditProduct')

        },

        CreatePdt(){

                    this.$store.commit('SAVE_PRODUCT_NAME', this.productCode)
                    let btnName={
                        text:'Create',
                        visible:true
                    }
                    this.$store.commit('SAVE_PRODUCT_BTN_NAME', btnName)
                    this.$router.push('/product/createEditProduct')

        },

         nextPageData(pgNo){
            console.log(pgNo,"=======>pgNo")
            console.log(this.crntPgNo,"=======>crntPgNo")
            if(this.crntPgNo===pgNo){
                console.log("Current page data already showing")
                return
            }
            if(this.srchApicall===true){
                this.getSearchProduct(pgNo)
            }else{
                
                this.getCreateProduct(pgNo)
            }
            
        },
        editBnkAcc(item){
                console.log("item", item)
        },

        showSearchResult() {
            this.showNosearch = false;
            // this.showtable = false;
            console.log('search data is------')
                     
        },
        showTableData() {
            console.log('table data is------')
            
            this.showNosearch = false;
            this.showtable = false;
        },
        // showCreateData() {
        //     console.log('create data is------')
        //     this.showCreate=true;
            
            
        // },
        jump(to) {
                if (this.$router) {
                    this.$router.push(to)
                }
            },


            getCreateProduct(pgNo) {
                
                    let self = this;  
                    self.GetData = [] 
                    axios({
                    method: "get",
                    
                    url:
                        this.API_Service_admin()+"product/product-all?perPage="+self.itemsPerPage+"&pageNo="+pgNo,
                            headers: {
                                "Content-Type": "application/json", 
                                "x-access-token": self.token,
                                //  "x-access-token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI1ZmJlMDg4ZGM0NmRhODNlZTdmODcxYmIiLCJyb2xlIjoiQWRtaW5Vc2VyIiwiaWF0IjoxNjA2MzY5MzI0LCJleHAiOjE2Mzc5MDUzMjR9.iS5hZs0uImNFz-rxNaQ7CTCc6qS_hG4H0FsePZ5Sgqc",
                            }
                        })
                .then(function(response) {
                    
                    console.log('get data responce------->>>',response.data);      
                    let apiData = response.data.data.data
                    console.log("API RESPONSE:::::",apiData);
                    self.showtable=true
                    self.showNosearch=false
                    self.pageCount=response.data.data.total_pages
                    self.totalCount=response.data.data.total
                    self.srchApicall=false
                    for (let i = 0; i < apiData.length; i++) {
                       let status= self.validDateChk(apiData[i].productEffectiveTo)
                        // console.log(status)
                        let productdata = {
                                reportingClass: apiData[i].reportingClass,
                                productStatus: status==="Active"?"Active":"Inactive",
                                productCode:apiData[i].productCode,
                                productDescription:apiData[i].productDescription,
                                productType:apiData[i].productType,
                                productTypeId:apiData[i].productTypeId,
                                reportingClassId:apiData[i].reportingClassId,
                                productEffectiveFrom:apiData[i].productEffectiveFrom,
                                productEffectiveTo:apiData[i].productEffectiveTo,
                                coverages:apiData[i].coverages,
                                irdaUinNumber:apiData[i].irdaUinNumber,
                                updatedAt:apiData[i].updatedAt,
                                __v:apiData[i].__v,
                                _id:apiData[i]._id,
                                createdAt:apiData[i].createdAt
                            }
                            self.GetData.push(productdata);
                    }
                    console.log(self.GetData)
                     self.crntPgNo=pgNo
                    console.log( self.crntPgNo,"=======> self.crntPgNo")

                    const start = pgNo * self.itemsPerPage - self.itemsPerPage,
                    end = start + self.itemsPerPage;
                    console.log(start,end,"start End")
                     if(self.totalCount===0){
                            self.fromvalue=0
                        }else{
                            self.fromvalue=start+1;
                        }
                        
                        if(self.totalCount <self.itemsPerPage){
                            self.toValu=self.totalCount
                        }else if(self.totalCount<end){
                            self.toValu=self.totalCount
                        }else{
                            self.toValu=end
                        }
                   
					
                })
                .catch(function(error) {
                    console.log(error);
                    self.showToast(error.response.data.msg,self.TOST().ERROR);

                });
             
            },
            SearchProduct(){
                    this.page=1
                    this.getSearchProduct(this.page)
            },
            getSearchProduct(pgNo) {
                if (this.$refs.form.validate()){
                    
                    let self=this
                    self.GetData = []
                    console.log(self.productDescription,"self.productDescription")
                    console.log(self.productCode,"self.productDescription")
                    let apiURL=''
                    if(self.productDescription===''||self.productDescription===undefined||self.productDescription===null){
                        apiURL=self.API_Service_admin()+"product/search-product?perPage="+self.itemsPerPage+"&pageNo="+pgNo+"&productCode="+self.productCode
                    }else{
                        apiURL=self.API_Service_admin()+"product/search-product?perPage="+self.itemsPerPage+"&pageNo="+pgNo+"&productCode="+self.productCode+"&productDescription="+self.productDescription
                    }
                    console.log(apiURL,"apiURL")
                    axios({
                        method: "get",
                        url:apiURL,
                        headers: {
                            "Content-Type": "application/json",
                            "x-access-token": self.token,    
                        },
                    })
                        .then(function(response) {
                                   console.log("Search reporting>>>>>>", response);
                                    let apiData=response.data.data.data
                                    let status=response.status
                                    self.showtable=true,
                                    self.showNosearch=false
                                    self.pageCount=response.data.data.total_pages
                                     self.totalCount=response.data.data.total
                                     self.srchApicall==true
                                    // console.log("API search RESPONSE -->:::::",apiData);
                                    // console.log("API status RESPONSE -->:::::",status);

                                    // self.GetData = []
                                    // self.GetData =apiData

                                    for (let i = 0; i < apiData.length; i++) {
                                    let status= self.validDateChk(apiData[i].productEffectiveTo)
                                        console.log(status)
                                        let productdata = {
                                                reportingClass: apiData[i].reportingClass,
                                                productStatus: status==="Active"?"Active":"Inactive",
                                                productCode:apiData[i].productCode,
                                                productDescription:apiData[i].productDescription,
                                                productType:apiData[i].productType,
                                                productTypeId:apiData[i].productTypeId,
                                                reportingClassId:apiData[i].reportingClassId,
                                                productEffectiveFrom:apiData[i].productEffectiveFrom,
                                                productEffectiveTo:apiData[i].productEffectiveTo,
                                                coverages:apiData[i].coverages,
                                                irdaUinNumber:apiData[i].irdaUinNumber,
                                                updatedAt:apiData[i].updatedAt,
                                                __v:apiData[i].__v,
                                                _id:apiData[i]._id,
                                                createdAt:apiData[i].createdAt
                                            }
                                            self.GetData.push(productdata);
                                    }
                                    console.log(self.GetData)
                                    self.crntPgNo=pgNo
                                    console.log( self.crntPgNo,"=======> self.crntPgNo")

                                    const start = pgNo * self.itemsPerPage - self.itemsPerPage,
                                    end = start + self.itemsPerPage;
                                    console.log(start,end,"start End")
                                    if(self.totalCount===0){
                                            self.fromvalue=0
                                        }else{
                                            self.fromvalue=start+1;
                                        }
                                        
                                        if(self.totalCount <self.itemsPerPage){
                                            self.toValu=self.totalCount
                                        }else if(self.totalCount<end){
                                            self.toValu=self.totalCount
                                        }else{
                                            self.toValu=end
                                        }
                                
                        })
                        .catch(function (error) {
                        console.log("Search reporting Error>>>>>>", error.response);
                        self.showtable=false,
                        self.showNosearch=true,
                        self.$store.commit('SAVE_PRODUCT_NAME', self.productCode)
                        // self.$store.commit('SAVE_PRODUCT_BTN_NAME', true)
                        // self.$store.commit('SAVE_PRODUCT_CODE_ALL_DATA', self.productCode)



                        // self.showToast(error.response.data.msg,self.TOST().ERROR);
                        
                });
                    
                }else{
                this.$refs.form.validate()
                console.log(this.$refs.form.validate())
                this.showToast("Please Fill Required Fields",this.TOST().WARNING);
                }
            },

            validDateChk(todte){
                // console.log(todte,"incoming date")
                 if(todte===null||todte===undefined|| todte===''){
                    return "Inactive"
                 }else{

                    let d = Date.now();
            // let CurrentDate = d.toLocaleString("en-IN");

            // let n = new Date(todte);
            // let Todate=n.toLocaleString("en-IN")
            // let fromeDate = moment(this.crtEdtPdt.pdtEfftvDteFrm,'YYYY-MM-DD').format('DD-MM-YYYY')
            // let todayDate = Date.now();
            // console.log(todte,"incoming Date")
            // let Todate= moment(todte,'YYYY-MM-DD').format('DD-MM-YYYY')
            let Todate= moment(todte,'YYYY-MM-DD').toDate().getTime()
            let CurrentDate= d
            // console.log(Todate,CurrentDate,"Todate,CurrentDate")

            if(Todate===null||Todate===undefined|| Todate===''||Todate==="Invalid" ){
                return "Inactive"
            }else if(Todate < CurrentDate ){
                return "Inactive"
            }else if (Todate>CurrentDate){
                return "Active"
            }


                 }
           
        }



    },

    watch: {
    
    },
    
    
}

</script>
<style >
.cust_pr .v-text-field .v-input__slot,
.v-text-field--outline .v-input__slot {
    min-height: 42px !important;
    display: flex !important;
    align-items: center !important;
    /* border:1px red solid; */
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
    font-size: 0.84rem !important;
}
/* .v-icon.v-icon--dense {
    font-size: 16px;
} */
/* .bnkAcc-tbl-brdr{
    border:0.5px solid  #C1C8CC !important;
}
.bnkAcc-td-bdrgt {
    border-right:0.5px solid #C1C8CC !important;
 }

.hdr-txt-clr-max{
    color:#10242B !important;
    height: 0px; */
    
    /* padding:0px!important;
    padding: 10px !important;
    padding-left: 14px !important; */
/* } */

.hdr-txt-clr{
    color:#10242B !important;
    /* padding:0px!important;
    padding: 10px !important;
    padding-left: 14px !important; */
}


.cstm-clr-bu{
    color:#10242B;
    margin-bottom: 5px;
    font-size: 13px !important;
}

.cust_pr .v-input{
    font-size: 13px !important;
}
.cust_pr .v-input input {
    font-size: 13px !important;
}

.cust_pr .v-input .v-label {
    font-size: 13px !important;
}


.cstm-clr-pr{
    color:#10242B;
    margin-bottom: 5px;
}



 .cust-bnkAcc-pgn .v-pagination__navigation{
        box-shadow:unset !important;
        border-radius: unset !important;
        background-color:#FFFFFF !important;
        border:1px solid #DAE1E6 !important
    }
    .cust-bnkAcc-pgn .v-pagination__navigation--disabled{
        background-color:#DAE1E6 !important;
    }
    .cust-bnkAcc-pgn .v-pagination__item{
        box-shadow:unset !important;
        min-width:2rem !important;
        height:2rem !important;
        border-radius: unset !important;
        border:1px solid #DAE1E6 !important
    }  

    .cust-brd-rght .v-data-table--dense > .v-data-table__wrapper > table > thead > tr > th{
        border-right-width: thin !important;
        border-right-style: solid !important;
        border-right-color: rgba(0, 0, 0, 0.12) !important;
    } 

    .cust-brd-rght .v-data-table--dense > .v-data-table__wrapper > table > tbody > tr > td{
         border-right-width: thin !important;
        border-right-style: solid !important;
        border-right-color: rgba(0, 0, 0, 0.12) !important;
    }
</style>