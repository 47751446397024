<template>
  <div>
    <v-container fluid>
      <v-expansion-panels multiple>
        <v-expansion-panel class="accordion-style" v-for="(item, index) in accordionList" :key="index">
          <v-expansion-panel-header style="font-weight:bold;">{{ item }}</v-expansion-panel-header>

          <v-expansion-panel-content v-if="item == 'Investigator Observation / Remarks'">
            <v-form ref="finalObservationOfInvestigator" @submit.prevent>
              <v-layout row>
                <v-flex md6 sm6 xs6>
                  <v-layout column class="cust_usrsrch mx-2 mt-2">
                    <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
                      Investigation Outcome
                    </v-text>
                    <v-select v-model="finalObservationOfInvestigator.investigationOutcome" label="Select"
                      :items="investigationOutcomeItemsList" item-text="investigationOutcome"  item-value="investigationOutcome" @change="changedValue"
                      :rules="[(v) => requiredField(v)]"></v-select>
                  </v-layout>
                </v-flex>
                <v-flex md6 sm6 xs6>
                  <v-layout column class="cust_usrsrch mx-2 mt-2">
                    <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
                      Ground of Defense
                    </v-text>
                    <v-select v-model="finalObservationOfInvestigator.groundOfDefense" label="Select"
                      :items="groundOfDefenseItemsList" item-text="groundOfDefense" :rules="[(v) => requiredField(v)]"></v-select>
                  </v-layout>
                </v-flex>
  
              </v-layout>
              <v-layout row>
                <v-flex md6 sm6 xs6 :align-self-end="true">
                  <v-layout column class="cust_usrsrch mx-2 mt-2">
                    <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
                      Evidence Justification
                    </v-text>
                    <v-textarea solo label="Enter..." v-model="finalObservationOfInvestigator.evidenceJustification"
                      maxlength="5000" counter="5000" rows="3" class="rounded-0" aria-required="true" :rules="[(v) => requiredField(v)]">
                    </v-textarea>
                  </v-layout>
                </v-flex>
                <v-flex md6 sm6 xs6 :align-self-end="true">
                  <v-layout column class="cust_usrsrch mx-2 mt-2">
                    <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
                      Brief Investigation Finding
                    </v-text>
                    <v-textarea solo label="Enter..." v-model="finalObservationOfInvestigator.briefInvestigationFinding"
                      maxlength="10000" counter="10000" rows="3" class="rounded-0" aria-required="true" :rules="[(v) => requiredField(v)]">
                    </v-textarea>
                  </v-layout>
                </v-flex>
              </v-layout>
              <v-layout row>
                <v-flex md6 sm6 xs6 :align-self-end="true">
                  <v-layout column class="cust_usrsrch mx-2 mt-2">
                    <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
                      Status
                    </v-text>
                    <v-select :items="investigatorItems" item-text="text" item-value="value" v-model="finalObservationOfInvestigator.status"
                      label="Select" dense solo :rules="[(v) => requiredField(v)]">
                    </v-select>
                  </v-layout>
                </v-flex>
                <v-flex md6 sm6 xs6>
                  <div class="md-12 mt-8 d-flex justify-end">
                    <!-- <v-btn class="mr-2">Save</v-btn> -->
                    <v-btn type="submit" @click="saveData('finalObservationOfInvestigator')" class="mr-2">Submit</v-btn>
                  </div>
                </v-flex>
              </v-layout>
            </v-form>
          </v-expansion-panel-content>

          <v-expansion-panel-content v-if="item == 'Qc1 Observation / Remarks'">
            <v-form ref="qc1ObservationAndRemarks" @submit.prevent>
            <v-layout row>
              <v-flex md6 sm3 xs6>
                <v-layout column class="cust_usrsrch mx-2 mt-2">
                  <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
                    Quality Of Report
                  </v-text>
                  <v-radio-group v-model="qc1ObservationAndRemarks.qualityOfReport" inline :rules="[(v) => requiredField(v)]">
                    <v-radio label="Report Satisfactory" value="satisfactory"></v-radio>
                    <v-radio label="Report Not Satisfactory" value="notSatisfactory"></v-radio>
                  </v-radio-group>
                </v-layout>
              </v-flex>
              <v-flex md6 sm3 xs6 :align-self-end="true">
                <v-layout column class="cust_usrsrch mx-2 mt-2">
                  <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
                    QC-1 Remarks
                  </v-text>
                  <v-textarea solo label="Enter..." v-model="qc1ObservationAndRemarks.remarks" maxlength="5000"
                    counter="5000" rows="3" class="rounded-0" aria-required="true" :rules="[(v) => requiredField(v)]">
                  </v-textarea>
                </v-layout>
              </v-flex>
            </v-layout>
            <v-layout row>
              <v-flex md6 sm6 xs6 :align-self-end="true">
                <v-layout column class="cust_usrsrch mx-2 mt-2">
                  <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
                    Status
                  </v-text>
                  <v-select :items="qc1Items" item-text="text" item-value="value" v-model="qc1ObservationAndRemarks.status" label="Select" dense
                    solo :rules="[(v) => requiredField(v)]">
                  </v-select>
                </v-layout>
              </v-flex>
              <v-flex md6 sm6 xs6>
                <div class="md-12 mt-8 d-flex justify-end">
                  <!-- <v-btn class="mr-2">Save</v-btn> -->
                  <v-btn type="submit" @click="saveData('qc1ObservationAndRemarks')" class="mr-2">Submit</v-btn>
                </div>
              </v-flex>
            </v-layout>
          </v-form>
          </v-expansion-panel-content>

          <v-expansion-panel-content v-if="item == 'Qc2 Observation / Remarks'">
            <v-form ref="qc2ObservationAndRemarks" @submit.prevent>
            <v-layout row>
              <v-flex md12 sm3 xs6 :align-self-end="true">
                <v-layout column class="cust_usrsrch mx-2 mt-2">
                  <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
                    QC-2 Remarks
                  </v-text>
                  <v-textarea solo label="Enter..." maxlength="5000" counter="5000"
                    v-model="qc2ObservationAndRemarks.remarks" rows="3" class="rounded-0" aria-required="true"
                    :rules="[(v) => requiredField(v)]">
                  </v-textarea>
                </v-layout>
              </v-flex>
            </v-layout>
            <v-layout row>
              <v-flex md6 sm6 xs6 :align-self-end="true">
                <v-layout column class="cust_usrsrch mx-2 mt-2">
                  <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
                    Status
                  </v-text>
                  <v-select :items="qc2Items" item-text="text" item-value="value" v-model="qc2ObservationAndRemarks.status" label="Select" dense
                    solo :rules="[(v) => requiredField(v)]">
                  </v-select>
                </v-layout>
              </v-flex>
              <v-flex md6 sm6 xs6>
                <div class="md-12 mt-8 d-flex justify-end">
                  <!-- <v-btn class="mr-2">Save</v-btn> -->
                  <v-btn type="submit" @click="saveData('qc2ObservationAndRemarks')" class="mr-2">Submit</v-btn>
                </div>
              </v-flex>
            </v-layout>
          </v-form>
          </v-expansion-panel-content>
          <v-expansion-panel-content v-if="item == 'Claim Examiner Observation / Remarks'">
            <v-layout row>

              <v-flex md12 sm3 xs6 :align-self-end="true">
                <v-layout column class="cust_usrsrch mx-2 mt-2">
                  <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
                    Remarks
                  </v-text>
                  <v-textarea v-model="claimExaminerObservationAndRemarks.remarks" solo label="Enter..." maxlength="5000" counter="5000" rows="3" class="rounded-0"
                    aria-required="true">
                  </v-textarea>
                </v-layout>
              </v-flex>
            </v-layout>
            <v-layout row>
              <v-flex md6 sm6 xs6 :align-self-end="true">
                <v-layout column class="cust_usrsrch mx-2 mt-2">
                  <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
                    Status
                  </v-text>
                  <v-select :items="examinerItems" item-text="text" item-value="value" v-model="claimExaminerObservationAndRemarks.status"
                    label="Select" dense solo>
                  </v-select>
                </v-layout>
              </v-flex>

              <v-flex md3 sm3 xs3 :align-self-end="true">
                <v-layout v-if="examinerStatus=='Case waived-off and closed'" column class="cust_usrsrch mx-2 mt-2">
                  <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
                    Reason
                  </v-text>
                  <v-select :items="examinerReasonItems" item-text="text" item-value="value" v-model="claimExaminerObservationAndRemarks.remarks"
                    label="Select" dense solo>
                  </v-select>
                </v-layout>
              </v-flex>
             
              <v-flex md3 sm3 xs3>
                <div class="md-12 mt-8 d-flex justify-end">
                  <!-- <v-btn class="mr-2">Save</v-btn> -->
                  <v-btn @click="saveData('claimExaminerObservationAndRemarks')" class="mr-2">Submit</v-btn>
                </div>
              </v-flex>
            </v-layout>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-container>
    <InvestigationFooterCard :tabno="sno" :formdata="getFormData" :nonMandatoryFields="nonMandatoryFields"
      :inputFieldCorrectlyFilled="true" :enableFieldObject="{}" :componentName="'finalObservationOfInvestigator'"
      :siuWorkItemId="siuWorkItemId" :tabFormDataId="tabFormDataId" />
  </div>
</template>
<script>
import InvestigationFooterCard from './InvestigationFooterCard';
import { saveInvestigationFormDetail, getInvestigationOutcome, getGroundOfDefence } from "../apiservices/Investigation";
import { getPrePopulatedData } from '../apiservices/Investigation';
import { getInvoiceDetails } from "../apiservices/invoiceService";

export default {
  name: 'FinalObservation',
  data() {
    return {
      investigationStatus: "",
      investigatorId: "",
      investigationOutcomeItemsList: ["LM","Fruad"],
      groundOfDefenseItemsList: [],
      currentRole : "",
      isSiuUser : false,
      siuRoles: ["CG77", "CG78","CG79","CG80","CG81","CG82"],
      investigatorStatus: '',
      investigatorItems: ["Final Report Send to QC"],
      qc1Status: '',
      qc1Items: ["QC1 Approved", "QC1 Sendback to INV"],
      qc2Status: '',
      qc2Items: ["QC2 Send Back", "QC2 Approved"],
      examinerStatus: '',
      examinerItems: ["SIU Case Completed & Closed", "Send Back to QC1", "Case waived-off and closed", "Case SIU sent back and closed"],
      examinerReasonItems: ["Low Value Claim","Triggers not justified","PED not applicable","Field Investigation is not required","Others"],
      examinerReason:'',
      sno: 19,
      lossStatesList: ['A'],
      validationmessage: "This field is required",
      rules: {
        mandatoryrule: [(v) => !!v || this.validationmessage],
      },
      accordionList: ['Investigator Observation / Remarks', 'Qc1 Observation / Remarks', 'Qc2 Observation / Remarks', 'Claim Examiner Observation / Remarks'],
      finalObservationOfInvestigator: {
        isTabSelected: true,
        investigationOutcome: "",
        groundOfDefense: "",
        evidenceJustification: "",
        briefInvestigationFinding: "",
        status: "",
      },
      qc1ObservationAndRemarks: {
        qualityOfReport: "",
        status: "",
        remarks: ""
      },
      qc2ObservationAndRemarks: {
        status: "",
        remarks: ""
      },
      claimExaminerObservationAndRemarks: {
        status: "",
        remarks: ""
      },
      nonMandatoryFields: [],
      approvedAmount : 0,
      investigatorType : ''
    }
  },
  components: {
    InvestigationFooterCard
  },
  props: ['prepoulatedData', "siuWorkItemId", "tabFormDataId", "qc1ObservationAndRemarksData", "qc2ObservationAndRemarksData", "claimExaminerObservationAndRemarksData"],
  async created() {
    let invoiceDetails = await getInvoiceDetails(this.siuWorkItemId);
    if(invoiceDetails?.data?.data?.length > 0){
      this.approvedAmount =  invoiceDetails.data.data[0].hasOwnProperty('totalApprovedAmount') ? invoiceDetails.data.data[0].totalApprovedAmount : 0;
    }

    this.currentRole = this.fetchRoleValue();
    this.isSiuUser = this.siuRoles.some(r=> this.currentRole.indexOf(r) >= 0);
    let tabs = ['finalObservationOfInvestigator', 'qc1ObservationAndRemarks', 'qc2ObservationAndRemarks', 'claimExaminerObservationAndRemarks'];
    let props = ['', 'qc1ObservationAndRemarksData', 'qc2ObservationAndRemarksData', 'claimExaminerObservationAndRemarksData'];
    this.investigatorId = this.$route.params.investigationWorkitemId || this.$route.params.allData.siuInvestigationTaskID;
    await this.getDropdownData();
    await this.getInvestigationData();
    for(let i=0; i < tabs.length; i++){
      let allKeys = Object.keys(this[tabs[i]]);
      allKeys.forEach(key => {
        this[tabs[i]][key] = i==0 ? this.prepoulatedData[key] : this[props[i]][key];
      });
    }
  },
  computed: {
    emailRules2(v) {
      if (v.length !== 0) {
        console.log("testing", /^[0-9 ]+$/.test(v));
        if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,6})+$/.test(v)) {
          return "E-mail must be valid";
        }
      }
    },
    getFormData() {
      return { finalObservationOfInvestigator: this.$data.finalObservationOfInvestigator , qc1ObservationAndRemarks: this.$data.qc1ObservationAndRemarks, qc2ObservationAndRemarks: this.$data.qc2ObservationAndRemarks, claimExaminerObservationAndRemarks : this.$data.claimExaminerObservationAndRemarks};
    }
  },
  methods: {
    async getInvestigationData() {
      try{
        let res = await getPrePopulatedData(this.investigatorId);
        this.investigationStatus = res.status;
        if(res.finalObservationOfInvestigator.investigationOutcome) {
          await this.changedValue(res.finalObservationOfInvestigator.investigationOutcome);
        }
        if(res.siuUserDetails?.investigatorDetails?.hasOwnProperty('investigatorType')){
          this.investigatorType = res.siuUserDetails.investigatorDetails.investigatorType

        }
      } catch(e){
        this.showToast(e.response?.data?.msg || e.response?.data  || e.response || e, this.TOST().ERROR);
      }
    },
    async getDropdownData(){
      try{
        let res = await getInvestigationOutcome();
        this.investigationOutcomeItemsList = res.data.data;
        console.log(this.investigationOutcomeItemsList);
      } catch(e){
        this.showToast(e.response?.data?.msg || e.response?.data  || e.response || e, this.TOST().ERROR);
      }
    },
    async changedValue(value){
      this.groundOfDefenseItemsList = null;
      this.finalObservationOfInvestigator.groundOfDefense = "";
      let res = await getGroundOfDefence(value);
      this.groundOfDefenseItemsList = res.data.data;
    },
    getFormData() {
      return { finalObservationOfInvestigator: this.$data.finalObservationOfInvestigator , qc1ObservationAndRemarks: this.$data.qc1ObservationAndRemarks, qc2ObservationAndRemarks: this.$data.qc2ObservationAndRemarks, claimExaminerObservationAndRemarks : this.$data.claimExaminerObservationAndRemarks};
    },
    computedDate(name) {
      return this[name] ? moment(this[name]).format("DD/MM/YYYY") : "";
    },
    async saveData(tabName) {
      console.log(tabName);
      if(this.isSiuUser) {
        if(tabName == 'finalObservationOfInvestigator' || tabName == 'claimExaminerObservationAndRemarks'){
          this.showToast('Unauthorized', this.TOST().ERROR);
          return;
        }
        if(tabName == 'qc1ObservationAndRemarks' || tabName == 'qc2ObservationAndRemarks') {
          if(this.investigationStatus != 'Submited') {
          this.showToast('Investigator submission required', this.TOST().ERROR);
          return;
      }
      let arr = ['QC-1 submitted, Pending for claims Review', 'QC-2 submitted, Pending for claims Review']
          if(arr.find((el)=>{return el == this.$route.params.allData.subStatus})){
            this.showToast('Already submitted', this.TOST().ERROR);
            return;
        }
        }
        if(tabName == 'qc1ObservationAndRemarks') {
          if(this.$route.params.userType == 'qc2') {
            this.showToast("QC2 can't submit QC1", this.TOST().ERROR);
            return;
          }
          if(this.$route.params.allData.subStatus == 'QC-1 submitted, Pending for QC-2 Review') {
            this.showToast('QPending with QC2', this.TOST().ERROR);
            return;
          }
          if(this.qc1ObservationAndRemarks.status == "QC1 Approved" && !this.approvedAmount && this.investigatorType == "External"){
            this.showToast('Please put approved amount in invoice, then approve the case', this.TOST().ERROR);
            return;
          }
        }
        if(tabName == 'qc2ObservationAndRemarks') {
          if(this.$route.params.userType == 'qc1') {
            this.showToast("QC1 can't submit QC2", this.TOST().ERROR);
            return;
          }
          if(this.$route.params.allData.subStatus == 'QC-2 send back, Pending for QC-1 Review') {
            this.showToast('Pending with QC1', this.TOST().ERROR);
            return;
        }
      }
      } else {
        if(tabName == 'finalObservationOfInvestigator' && this.investigationStatus !='Accepted'){
          this.showToast('Case must be accepted first', this.TOST().ERROR);
          return;
        }
        if(tabName == 'claimExaminerObservationAndRemarks' || tabName == 'qc1ObservationAndRemarks' || tabName == 'qc2ObservationAndRemarks') {
          this.showToast('Unauthorized', this.TOST().ERROR);
          return;
        }
      }
        this.showConfirm(
        "Confirmation",
        "Are you sure you want to Submit?",
        "Yes",
        "No",
        (Yes) => {
          this.submit(tabName);
          
        },
        (No) => {});
    },
    async submit(tabName) {
      console.log(this[tabName]);
      if(!this.$refs[tabName][0].validate()) {
        return this.showToast('Please fill mandatory fields.', this.TOST().ERROR);
      }
      try {
        let payload = JSON.parse(JSON.stringify(this[tabName]));
        if(tabName != 'finalObservationOfInvestigator') {
          delete payload.isTabSelected;
        }
      let obj = {siuWorkItemId: this.siuWorkItemId, ...{ [tabName]: payload }};
            let res = await saveInvestigationFormDetail(this.tabFormDataId, obj, tabName);
            if(res.data.statusCode == 200) {
              this.showToast(res.data.msg, this.TOST().SUCCESS);
              this.$router.push('investigation-workitem');
            } else {
              this.showToast(res.data.msg, this.TOST().WARNING);
            }
            this.$store.commit('SET_INVESTIGATION_FORMDATA', { finalObservationOfInvestigator: this.$data.finalObservationOfInvestigator , qc1ObservationAndRemarks: this.$data.qc1ObservationAndRemarks, qc2ObservationAndRemarks: this.$data.qc2ObservationAndRemarks, claimExaminerObservationAndRemarks : this.$data.claimExaminerObservationAndRemarks}); 
      } catch(e){
        this.showToast(e.response?.data?.msg || e.response?.data  || e.response || e, this.TOST().ERROR);
      }
    },
    alphaNumeric(fieldName, name, mandatory = true) {
      if (fieldName?.length > 0) {
        return (v) => /^[a-zA-Z0-9]+$/.test(v) || "Enter valid " + name;
      }
    },
  },
}
</script>
<style scoped>
.accordion-style {
  padding: 20px 20px 20px 20px;
}

.v-expansion-panel-content {
  margin-top: 25px !important;
}</style>