 <template>
    <v-app>
        <v-card color="#F7F7F7" class="mb-1" tile>
          <v-btn-toggle v-model="text"  tile mandatory  class="" @change="tabseleted(text)" style="background-color:#F7F7F7;opacity:100%;display:flex;" >
                <v-btn   value="bpd" class="text-capitalize font-weight-bold btn_tabs pr-3" active-class="srSelected" style="letter-spacing: unset">
                  Basic Plan Details
                </v-btn>
                <v-btn  value="rdi" class="text-capitalize font-weight-bold btn_tabs  pr-3" active-class="srSelected" style="flex:1;letter-spacing: unset">
                  Relationship & Dependent Info
                </v-btn>
                <v-btn  v-if="corpElgblshw===true" value="ce" class="text-capitalize font-weight-bold btn_tabs  pr-3" active-class="srSelected" style="flex:1;letter-spacing: unset">
                   Corporate Eligibility
                </v-btn>
                <v-btn  value="dwpd" class="text-capitalize font-weight-bold btn_tabs  pr-3" active-class="srSelected" style="flex:1;letter-spacing: unset">
                 Deductible & Waiting Period Details
                </v-btn>
                  <v-btn  value="ge" class="text-capitalize font-weight-bold btn_tabs  pr-3" active-class="srSelected" style="flex:1;letter-spacing: unset">
                  General Exclusions
                </v-btn>
                  <v-btn  value="ac" class="text-capitalize font-weight-bold btn_tabs  pr-3" active-class="srSelected" style="flex:1;letter-spacing: unset">
                  Applicable Coverages
                </v-btn>
                <v-btn  value="cd" class="text-capitalize font-weight-bold btn_tabs  pr-3" active-class="srSelected" style="flex:1;letter-spacing: unset">
                  Coverage Details
                </v-btn>
                
              </v-btn-toggle>
            </v-card>


            <BasicPlanDetails v-if="bpdShw===true" @next="switchtab('rdi','rdi')"></BasicPlanDetails>
            <RelationshipDependentInfo v-if="rdiShw===true" @next="switchtab('ce','dwpd')"  @return="returnToTab('bpd','bpd')"></RelationshipDependentInfo>
            <CorporateEligibility v-if="ceShw===true" @next="switchtab('dwpd','dwpd')" @return="returnToTab('rdi','rdi')"></CorporateEligibility>
            <DeductibleWaitingPeriodDetails v-if="dwpdShw===true" @next="switchtab('ge','ge')" @return="returnToTab('ce','rdi')"></DeductibleWaitingPeriodDetails>
            <GeneralExclusions v-if="geShw===true" @return="returnToTab('dwpd','dwpd')" @next="switchtab('ac','ac')"></GeneralExclusions>
            <ApplicableCoverages v-if="acShw===true" @return="returnToTab('ge','ge')"  @next="switchtab('cd','cd')"></ApplicableCoverages>
            <CoverageDetails v-if="cdShw===true"></CoverageDetails>
    </v-app>
</template>
<script>

import BasicPlanDetails from './BasicPlanDetails.vue'
import RelationshipDependentInfo from './RelationshipDependentInfo.vue'
import CorporateEligibility from './CorporateEligibility.vue'
import DeductibleWaitingPeriodDetails from './DeductibleWaitingPeriodDetails.vue'
import GeneralExclusions from './GeneralExclusions.vue'
import ApplicableCoverages from './ApplicableCoverages.vue'
import CoverageDetails from './CoverageDetails.vue'

export default {

    components:{
      
        BasicPlanDetails,
        RelationshipDependentInfo,
        CorporateEligibility,
        DeductibleWaitingPeriodDetails,
        GeneralExclusions,
        ApplicableCoverages,
        CoverageDetails,

    },

    created() {

        this.$store.commit("SAVE_PAGE_HEADER", "Provider Empanelment");
        console.log("Policy ID flow comng from",this.$store.getters.fetchPlcyIdForPlnData)
        this.btnName=this.$store.getters.fetchPlcyIdForPlnData.text
        if(this.btnName==="EditPlnFrPlcy"){
          this.text="bpd"
          this.tabseleted(this.text)
        }else if(this.btnName==="ConfgrPlnFrPlcy"||this.btnName==="CnfgrNwRtlPln"){
          this.text="bpd"
          this.tabseleted(this.text)
        }

        if(this.$store.getters.fetchPlcyIdForPlnData.plan_Typ==="retail"){
          this.corpElgblshw=false
        }

    },

    computed: {

    },

    data() {
        return {
        
        bpdShw:true,
        corpElgblshw:true,
        rdiShw:false,
        ceShw:false,
        dwpdShw:false,
        geShw:false,
        acShw:false,
        cdShw:false,
        text:"bpd",
        btnName:'',
        nextbtn:true,
        };



    },

    methods: {

        tabseleted(itm){
            console.log("Selected tab",itm);
            if(itm==="bpd"){
                this.bpdShw=true
                this.rdiShw=false
                this.ceShw=false
                this.dwpdShw=false
                this.geShw=false
                this.acShw=false
                this.cdShw=false
            }else if(itm==="rdi"){
               this.bpdShw=false
                this.rdiShw=true
                this.ceShw=false
                this.dwpdShw=false
                this.geShw=false
                this.acShw=false
                this.cdShw=false
            }else if(itm==="ce"){
               this.bpdShw=false
                this.rdiShw=false
                this.ceShw=true
                this.dwpdShw=false
                this.geShw=false
                this.acShw=false
                this.cdShw=false
            }else if(itm==="dwpd"){
               this.bpdShw=false
                this.rdiShw=false
                this.ceShw=false
                this.dwpdShw=true
                this.geShw=false
                this.acShw=false
                this.cdShw=false
            }else if(itm==="ge"){
               this.bpdShw=false
                this.rdiShw=false
                this.ceShw=false
                this.dwpdShw=false
                this.geShw=true
                this.acShw=false
                this.cdShw=false
            }else if(itm==="ac"){
               this.bpdShw=false
                this.rdiShw=false
                this.ceShw=false
                this.dwpdShw=false
                this.geShw=false
                this.acShw=true
                this.cdShw=false
            }else if(itm==="cd"){
               this.bpdShw=false
                this.rdiShw=false
                this.ceShw=false
                this.dwpdShw=false
                this.geShw=false
                this.acShw=false
                this.cdShw=true
            }
        },

        switchtab(e,f){
           this.bpdShw=false
                this.rdiShw=false
                this.ceShw=false
                this.dwpdShw=false
                this.geShw=false
                this.acShw=false
                this.cdShw=false
          console.log(e,f);
          if(this.corpElgblshw===true){
            this.tabseleted(e)
            this.text=e
          }else{
            console.log("else")
            this.tabseleted(f)
            this.text=f
          }
         
        },
        returnToTab(e,f){
      this.bpdShw=false
                this.rdiShw=false
                this.ceShw=false
                this.dwpdShw=false
                this.geShw=false
                this.acShw=false
                this.cdShw=false
          console.log(e,f);
          if(this.corpElgblshw===true){
            this.tabseleted(e)
            this.text=e
          }else{
            console.log("else")
            this.tabseleted(f)
            this.text=f
          }
         
        }

        
       

    },

    
    

    watch: {
    
    },
    
    
}

</script>
<style >



.activeredbackground{
  color:white;
}

.v-btn__content { width: 100% !important; white-space: normal !important; font-size:13px !important}

.srSelected{
  background-color: #23B1A9 !important;
  color:#FFFFFF !important;
}
/* .btn_tabs{
  color:#10242B;
  opacity:100% !important; 
  height:95px !important;
  width:100px !important; 
  font-size:13px !important;
  font-weight: 700; */
  /* border-left:0.5px solid #C1C8CC; */

    /* border: solid;
    border-top-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 0px;
    border-left-width: thin;
    transition: inherit;
    border-top-color: rgba(255, 255, 255, 0.10);
    border-right-color: rgba(255, 255, 255, 0.10);
    border-bottom-color: rgba(255, 255, 255, 0.10);
    border-left-color: rgba(193, 200, 204, 0.26);
} */

</style>