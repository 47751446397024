<template>
    <v-app>
        <v-form v-model="nidbWorkItem" ref="nidbWorkItem">
            <v-card tile class="pa-3">
                <v-layout>
                    <v-flex xs4>
                            <v-layout column class="cust_pln_em mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-iphd_claims">
                                    Inward ID
                                </p>
                                <v-text-field
                                    solo
                                    label="Enter..."
                                    v-model="nidbSearchInwardID"
                                    class="rounded-0"
                                    >
                                </v-text-field>
                            </v-layout>
                    </v-flex>

                    <v-flex xs4>
                            <v-layout column class="cust_pln_em mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-iphd_claims">
                                    Policy Number
                                </p>
                                <v-text-field
                                    solo
                                    label="Enter..."
                                    v-model="nidbSearchPolicyNumber"
                                    class="rounded-0"
                                    >
                                </v-text-field>
                            </v-layout>
                        </v-flex>

                        <v-flex xs4>
                            <v-layout column class="cust_pln_em mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-iphd_claims">
                                    Member ID
                                </p>
                                <v-text-field
                                    solo
                                    label="Enter..."
                                    v-model="nidbSearchMemberID"
                                    class="rounded-0"
                                    >
                                </v-text-field>
                            </v-layout>
                        </v-flex>

                         <v-flex xs4>
                            <v-layout column class="cust_pln_em mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-iphd_claims">
                                    Member First Name
                                </p>
                                <v-text-field
                                    solo
                                    label="Enter..."
                                    v-model="nidbSearchMemberFirstName"
                                    class="rounded-0"
                                    >
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                         <v-flex xs4>
                            <v-layout column class="cust_pln_em mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-iphd_claims">
                                    Member Last Name
                                </p>
                                <v-text-field
                                    solo
                                    label="Enter..."
                                    v-model="nidbSearchMemberLastName"
                                    class="rounded-0"
                                    >
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                </v-layout>
                <v-layout>
                    <v-flex xs4>
                            <v-layout column class="cust_pln_em mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-iphd_claims">
                                    Assign To
                                </p>
                                <v-select
                                        solo
                                        :items="nidbSearchwassignToItems"
                                        label="Enter..."
                                        v-model="nidbSearchwassignTo"
                                        class="rounded-0"
                                        >
                                    </v-select>
                            </v-layout>
                    </v-flex>

                    <v-flex xs4>
                        <v-layout column class="cust_pln_em mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-iphd_claims">
                                    Sub-Channel
                                </p>
                                <v-select
                                        solo
                                        :items="nidbSearchSubChannelItems"
                                        label="Select..."
                                        v-model="nidbSearchSubChannel"
                                        class="rounded-0"
                                        >
                                </v-select>
                        </v-layout>
                    </v-flex>
                    <v-flex xs4>
                            <v-layout column class="cust_pln_em mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-iphd_claims">
                                   TAT
                                </p>
                                <v-text-field
                                    solo
                                    label="Enter..."
                                    v-model="nidbSearchtat"
                                    class="rounded-0"
                                    >
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                        <v-flex xs4>
                            <v-layout column class="cust_pln_em mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-iphd_claims">
                                   Status
                                </p>
                                <v-select
                                    solo
                                    :items="nidbSearchStatusItems"
                                    label="Select..."
                                    v-model="nidbSearchStatus"
                                    class="rounded-0"
                                    >
                                </v-select>
                            </v-layout>
                        </v-flex>
                        <v-flex xs4>
                            <v-layout column class="cust_pln_em mx-2 ">
                                <p class="font-weight-bold cstm-clr-pln-iphd_claims">
                                 Other Remark
                                </p>
                                <v-text-field
                                    solo
                                    label="Enter..."
                                    v-model="nidbSearchOtherRemark"
                                    class="rounded-0"
                                    >
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                </v-layout>
                <div class="mx-2 pa-2 mb-1" style="background-color:#eee; display:flex;justify-content:space-between"  >
                    <v-layout style="flex:1" justify-end > 
                        <v-btn dark  tile color="#E46A25" class="text-capitalize px-3 mx-2 my-2" small ><v-icon dark small >search</v-icon> <v-divider vertical class="mx-2"></v-divider>Search</v-btn>
                         <v-btn dark  tile color="#E46A25" class="text-capitalize px-3 mx-2 my-2" small><v-icon dark small>cancel</v-icon> <v-divider vertical class="mx-2"></v-divider>Cancel</v-btn>
                    </v-layout>
                </div>
            </v-card>
        </v-form>
        <v-card color="#F7F7F7" class="pa-2 pl-4" tile>
            <p class="font-weight-bold ma-0 pa-0" style="font-size:13px">Search Results </p>
        </v-card>
        <v-card class="rounded-0" tile>

     <v-data-table        
        :headers="headers"
        :items="table_data"
        hide-default-footer
        class="elevation-1"
        dense
      > 
      </v-data-table>

      <v-divider></v-divider>
                
                <v-layout row justify-end align-center class="ma-2" >
                    <v-text class="mr-1 font-weight-bold" style="color:#3C3D3D; font-size:13px;">
                        <!-- Showing {{fval}}-{{sval}} </span>out of {{totalLen}} records -->
                        <span class="" style="color:#E46A25;font-size:12px;">Showing {{1}}-{{10}} </span>out of {{20}} records
                    </v-text>
                    <!-- <v-divider  class="mx-2" vertical ></v-divider> -->
                    <v-pagination
                        class="nidbWorkItm-bnkAcc-pgn"
                        v-model="page"
                        color="#152F38"
                        flat
                        :total-visible="9"
                        :length="pagelength"
                    ></v-pagination>
                </v-layout>
        </v-card>
    </v-app>
</template>
<script>
export default {
    data(){
        return{
            nidbWorkItem: false,
            nidbSearchInwardID:"",
            nidbSearchPolicyNumber:"",
            nidbSearchMemberID:"",
            nidbSearchMemberFirstName:"",
            nidbSearchMemberLastName:"",
            nidbSearchwassignToItems:["Channel","UW"],
            nidbSearchwassignTo:"",
            nidbSearchSubChannelItems:["Broker","Banca","Sales"],
            nidbSearchSubChannel:'',
            nidbSearchtat:'',
            nidbSearchStatusItems:[],
            nidbSearchStatus:'',
            nidbSearchOtherRemark:'',
            page: 1,
            fval:0,
            sval:0,
            totalLen:0,
            pagelength:0,
             headers:[
                {text: 'Sr. No.', value: 'SrNo.',sortable:false,divider:true,class:"hdr-txt-clr-nidbWrkItemQue hdr-bg-clr-nidbWrkItemQue"},
                {text: 'Inward ID', value: 'inwardId',sortable:false,divider:true,class:"hdr-txt-clr-nidbWrkItemQue  hdr-bg-clr-nidbWrkItemQue"},
                {text: 'Policy Number', value: 'policyNumber',sortable:false,divider:true,class:"hdr-txt-clr-nidbWrkItemQue hdr-bg-clr-nidbWrkItemQue"},
                {text: 'Member ID', value: 'memberId',sortable:false,divider:true,class:"hdr-txt-clr-nidbWrkItemQue hdr-bg-clr-nidbWrkItemQue"},
                {text: 'Member First Name', value: 'memberFirstName',sortable:false,divider:true,class:"hdr-txt-clr-nidbWrkItemQue hdr-bg-clr-nidbWrkItemQue"},
                {text: 'Member Last Name', value: 'memberLastName',sortable:false,divider:true,class:"hdr-txt-clr-nidbWrkItemQue hdr-bg-clr-nidbWrkItemQue"},
                {text: 'Other Remarks', value: 'otherRemarks',sortable:false,divider:true,class:"hdr-txt-clr-nidbWrkItemQue hdr-bg-clr-nidbWrkItemQue"},
                {text: 'Assigned To', value: 'assignedTo',sortable:false,divider:true,class:"hdr-txt-clr-nidbWrkItemQue hdr-bg-clr-nidbWrkItemQue"},
                {text: 'Sub-Channel', value: 'subChannel',sortable:false,divider:true,class:"hdr-txt-clr-nidbWrkItemQue hdr-bg-clr-nidbWrkItemQue"},
                {text: 'TAT', value: 'tat',sortable:false,divider:true,class:"hdr-txt-clr-nidbWrkItemQue hdr-bg-clr-nidbWrkItemQue"},
                {text: 'Status', value: 'status',sortable:false,divider:true,class:"hdr-txt-clr-nidbWrkItemQue hdr-bg-clr-nidbWrkItemQue"},
                {text: 'Action', value: 'action',sortable:false,divider:true,class:"hdr-txt-clr-nidbWrkItemQue hdr-bg-clr-nidbWrkItemQue"},
            ],

        }
    }
}
</script>
<style>
.hdr-txt-clr-nidbWrkItemQue {
    color: white !important;
    font-weight: bold;
    
}
.hdr-bg-clr-nidbWrkItemQue{
  background-color: #23B1A9 !important;
  color: #ffffff !important;
}

.nidbWorkItm-bnkAcc-pgn .v-pagination__navigation{
        box-shadow:unset !important;
        border-radius: unset !important;
        background-color:#FFFFFF !important;
        border:1px solid #DAE1E6 !important;
        height: 30px !important;
        width: 30px !important;
    }
.nidbWorkItm-bnkAcc-pgn .v-pagination__navigation--disabled{
    background-color:#DAE1E6 !important;
}
.nidbWorkItm-bnkAcc-pgn .v-pagination__item{
        box-shadow:unset !important;
        min-width:1.8rem !important;
        height:1.8rem !important;
        border-radius: unset !important;
        border:1px solid #DAE1E6 !important
}
</style>