<template>
    <v-app>
        <!-- <h3>{{indtype.type }}</h3> -->
        <div style="background-color: #FFFFFF;" column class="">
             <v-form
                ref="form"
                v-model="valid"
                class="masterfix"
            >
             <!-- lazy-validation -->
            <v-layout color="#FFFFFF" class="mx-2 mt-3" style="background-color: #FFFFFF;">
                 <v-flex xs3>
                    <v-layout column class="cust_pr mx-2 ">
                        <p class="font-weight-bold cstm-clr-pr">
                            Salutation *
                        </p>
                        <v-select
                            :items="sltnIndItems"
                            label="Select"
                            v-model="salutationind"
                            solo
                            dense
                            :error-messages="salute"
                            @input="$v.salutationind.$touch()"
                            @blur="$v.salutationind.$touch()"
                            class="rounded-0"
                            type="text"
                            
                            >
                        </v-select>
                    </v-layout>
                </v-flex>
                <v-flex xs3 >
                    <v-layout column class=" mx-2 cust_pr">
                        <p class="font-weight-bold cstm-clr-pr">
                            First Name *
                        </p>
                        <v-text-field
                            autocomplete="off"
                            solo
                            label="Enter..."
                            v-model="frstnmeInd"
                             :error-messages="fname"
                            class="rounded-0"
                            maxlength=20
                            counter="20"
                            @input="$v.frstnmeInd.$touch()"
                            @blur="$v.frstnmeInd.$touch()"
                            >
                        </v-text-field>
                          <!-- :rules="[required(0,''), rules.spclChar(1,'frstnmeInd')]" -->
                    </v-layout>
                </v-flex>
                <v-flex xs3 >
                    <v-layout column class=" mx-2 cust_pr">
                        <p class="font-weight-bold cstm-clr-pr">
                            Middle Name
                        </p>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="mdlnmeInd"
                              :error-messages="mname"
                            class="rounded-0"
                            maxlength=20
                            counter="20"
                            >
                        </v-text-field>
                          <!-- :rules="[rules.spclChar(2,'mdlnmeInd')]" -->
                    </v-layout>
                </v-flex>

                 <v-flex xs3>
                    <v-layout column class=" mx-2 cust_pr">
                        <p class="font-weight-bold cstm-clr-pr">
                            Last Name *
                        </p>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="lstnmeInd"
                            class="rounded-0"
                            maxlength=20
                            counter="20"
                            :error-messages="lname"
                            @input="$v.lstnmeInd.$touch()"
                            @blur="$v.lstnmeInd.$touch()"
                            >
                        </v-text-field>
                          <!-- :rules="[required(0,''),rules.spclChar(3,'lstnmeInd')]" -->
                    </v-layout>
                </v-flex>

                  <v-flex xs3>
                    <v-layout column class=" mx-2 cust_pr">
                        <p class="font-weight-bold cstm-clr-pr">
                            Gender
                        </p>
                        <v-select
                            :items="gndrIndItems"
                            label="Select"
                            v-model="gndrInd"
                            solo
                            dense
                            maxlength=11
                            class="rounded-0"
                            >
                        </v-select>
                    </v-layout>
                </v-flex>

            </v-layout>
           
            <v-layout style="" class="mx-2" >
               
              

                <v-flex xs3>
                    <v-layout column class=" mx-2 cust_pr cust-rmvpd-pr">
                        <p class="font-weight-bold cstm-clr-pr">
                            Mobile Number
                        </p>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="mbNoInd"
                            :rules="[rules.noOnly()]"
                            class="rounded-0"
                            maxlength=10
                            counter="10"
                            >
                            <div slot="prepend-inner" style="background-color:#F7F7F7;padding: 9px !important"><v-icon dense>smartphone</v-icon></div>
                        </v-text-field>
                        
                    </v-layout>
                </v-flex>

                 <v-flex xs3>
                    <v-layout column class="cust_pr cust-rmvpd-pr mx-2 ">
                        <p class="font-weight-bold cstm-clr-pr">
                            E-mail
                        </p>
                        <v-text-field
                            solo
                            label="Enter..."
                            
                            v-model="email"
                            :rules="emailRules"     
                            class="pa-0 rounded-0"
                            maxlength=50
                            counter="50"
                            >
                             <div slot="prepend-inner" style="background-color:#F7F7F7;padding: 9px !important"><v-icon dense>mail</v-icon></div>
                        </v-text-field>
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class=" mx-2 cust_pr">
                        <p class="font-weight-bold cstm-clr-pr">
                           External Reference No.
                        </p>
                        <v-text-field
                            autocomplete="off"
                            solo
                            label="Enter..."
                            v-model="extrnlRfNoInd"
                            maxlength=50
                            counter="50"
                            class="rounded-0">
                        </v-text-field>
                    </v-layout>
                </v-flex>

                 <v-flex xs3>
                    <v-layout column class="cust_pr custPrn_Indvl mx-2 ">
                        <p class="font-weight-bold cstm-clr-pr">
                            PRN
                        </p>
                        <v-text-field
                            solo
                            label="Auto Generated on Save"
                            v-model="prnInd"
                            class="rounded-0" 
                            disabled
                            maxlength=10
                            counter="10"
                            >
                        </v-text-field>
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class="cust_pr custPrn_Indvl mx-2 ">
                        <p class="font-weight-bold cstm-clr-pr">
                            Status
                        </p>
                        <v-text-field
                            disabled
                            label="Auto Generated on Save"
                            v-model="status"
                            solo
                            dense
                            class="rounded-0"
                            maxlength=100
                            counter="100"
                            >
                        </v-text-field>
                    </v-layout>
                </v-flex>
            </v-layout>
            <v-divider class="my-2"></v-divider>

            <v-layout color="#FFFFFF" class="mx-2 mt-4"  >
                 <v-flex xs3>
                    <v-layout column class="cust_pr mx-2 ">
                        <p class="font-weight-bold cstm-clr-pr">
                           Provider Type *
                        </p>
                        <v-select
                            :items="ptype"
                            label="Select"
                            v-model="prvdrTypeInd"
                            solo
                            dense
                            @change="ptypeis(prvdrTypeInd)"
                            :error-messages="prvdrTypeIndError"
                            @input="$v.prvdrTypeInd.$touch()"
                            @blur="$v.prvdrTypeInd.$touch()"
                            class="rounded-0"
                            maxlength=20
                            counter="20">
                        </v-select>
                    </v-layout>
                </v-flex>
                
                 <v-flex xs3>
                    <v-layout column class="cust_pr mx-2 ">
                        <p class="font-weight-bold cstm-clr-pr">
                           Other Provider Type
                        </p> 
                         <v-text-field
                           :disabled='!ptype_other'
                            solo
                            label="Enter..."
                            filled
                            v-model="otherptype"
                            :error-messages="othersisone"
                            @input="$v.otherptype.$touch()"
                            @blur="$v.otherptype.$touch()"
                            class="rounded-0"
                            maxlength=50
                            counter="50">
                        </v-text-field>
                    </v-layout>
                </v-flex>

                

                <v-flex xs3>
                    <v-layout column class="cust_pr mx-2 ">
                        <p class="font-weight-bold cstm-clr-pr">
                           Qualification
                        </p>
                         <!-- <v-text-field
                            solo
                            label="Enter..."
                            v-model="qualificationInd"
                            hide-details class="rounded-0">
                        </v-text-field> -->

                        <v-select
                            :items="qualificationIndItems"
                            label="Select"
                            v-model="qualificationInd"
                            @change="qualis(qualificationInd)"
                            solo
                            dense
                            hide-details class="rounded-0">
                        </v-select>
                        
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class="cust_pr mx-2 ">
                        <p class="font-weight-bold cstm-clr-pr">
                           Other Qualification
                        </p>
                         <v-text-field
                            :disabled='!qualtype_other_dis'
                            solo
                            label="Enter..."
                            v-model="other_qual"
                            class="rounded-0"
                            :error-messages="othersistwo"
                            @input="$v.other_qual.$touch()"
                            @blur="$v.other_qual.$touch()"
                            maxlength=50
                            counter="50">
                        </v-text-field>
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class="cust_pr mx-2 ">
                        <p class="font-weight-bold cstm-clr-pr">
                            Registration No.
                        </p>
                         <v-text-field
                            solo
                            label="Enter..."
                            v-model="RgstNo"
                            
                            class="rounded-0"
                            maxlength=50
                            counter="50">
                        </v-text-field>
                        <!-- <v-select
                            :items="RgstNoItems"
                            label="Select"
                            v-model="RgstNo"
                            solo
                            dense
                            hide-details class="rounded-0">
                        </v-select> -->
                    </v-layout>
                </v-flex> 
                 

                

            </v-layout>
            

            <v-layout style="" class="mx-2" >

                <!-- <v-flex xs3>
                    <v-layout column class="cust_pr mx-2 ">
                        <p class="font-weight-bold cstm-clr-pr">
                            Registration Valid Upto
                        </p>
                       
                        <v-menu
                            ref="calender"
                            :close-on-content-click="true"
                            v-model="RgstrnVldUptocalender"
                            :nudge-right="40"
                            transition="scale-transition"
                            max-width="290px"
                            min-width="290px" 
                             hide-details class="rounded-0"
                            >
                             <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-bind="attrs"
                                    label="DD-MM-YYYY"
                                     v-model="computedDateFormattedMomentjsnew"
                                    solo
                                    persistent-hint
                                    hide-details class="rounded-0"
                                    readonly 
                                    v-on="on"
                                ></v-text-field>
                                   </template>                     
                                    <v-date-picker
                                        v-model="computedDateFormattedMomentjs"
                                        no-title
                                        
                                    ></v-date-picker>
                        </v-menu>
                    </v-layout>
                </v-flex> -->

                <v-flex xs3>
                    <v-layout column class=" mx-2  cust_pr_quick cust-rmvpd-pr-quick">
                        <p class="font-weight-bold cstm-clr-pr-quick">
                            Registration Valid Upto
                        </p>
                        
                        <v-menu
                            ref="calender"
                            :close-on-content-click="false"
                            v-model="regCalenderDate"
                            :nudge-right="40"
                            lazy
                            transition="scale-transition"
                            full-width
                            max-width="290px"
                            min-width="290px" 
                            class="rounded-0"   
                            >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-bind="attrs"
                                    v-on="on"
                                    id="fromDate"
                                    label="dd/mm/yyyy"
                                    :value="computedregValidityTo"
                                    solo
                                    class="rounded-0"
                                    readonly
                                    required>
                            <div  slot="prepend-inner" style="background-color:#F7F7F7;  padding: 8px !important"><span class="material-icons">date_range</span></div>
                                
                                </v-text-field>
                            </template>                        
                                    <v-date-picker 
                                        v-model="regValidityTo"
                                        no-title
                                        @input="regCalenderDate = false"
                                    ></v-date-picker>
                        </v-menu>

                    </v-layout>
                </v-flex>




                <v-flex xs3>
                    <v-layout column class="cust_pr mx-2 ">
                        <p class="font-weight-bold cstm-clr-pr">
                           Registered Council
                        </p>
                         <v-select
                            :items="council"
                            label="Select"
                            v-model="RgstCnlInd"
                            @change="countype(RgstCnlInd)"
                            maxlength=20
                            counter="20"
                            solo
                            dense
                            hide-details class="rounded-0">
                        </v-select>
                    </v-layout>
                </v-flex>
                <v-flex xs3>
                    <v-layout column class="cust_pr mx-2 ">
                        <p class="font-weight-bold cstm-clr-pr">
                           Other Registered Council
                        </p>
                         <v-text-field
                            maxlength=50
                            counter="50"
                            :disabled='!council_other_dis'
                            v-model="council_other"
                            solo
                            label="Enter..."
                           :error-messages="othersisthree"
                             class="rounded-0">
                               
                        </v-text-field>
                    </v-layout>
                </v-flex>

                <!-- <v-flex xs3>
                    <v-layout column class="cust_pr mx-2 ">
                        <v-text class="font-weight-bold cstm-clr-pr">
                           Qualification
                        </v-text>
                         <v-text-field
                            solo
                            label="Enter..."
                            v-model="QualificationInd2"
                            hide-details class="rounded-0">
                        </v-text-field>
                    </v-layout>
                </v-flex> -->

                <v-flex xs3>
                    <v-layout column class="cust_pr mx-2 ">
                        <p class="font-weight-bold cstm-clr-pr">
                           Remarks
                        </p>
                         <v-text-field
                            solo
                            label="Enter..."
                            v-model="remarksInd"
                            class="rounded-0"
                            maxlength=200
                            counter="200">
                        </v-text-field>
                    </v-layout>
                </v-flex>

                <v-flex xs3></v-flex>
                

            </v-layout>

             </v-form>

       
                
             

             <div class="mx-3 pa-2 mt-2" style="background-color: #F7F7F7; display:flex;justify-content:flex-end;"  >
               
                  <v-btn   @click="opendocs" dark  tile color="#152F38"
                  v-if="indtype.type!=='validate'" 
                  class="text-capitalize px-3 mx-2" small>
                  <v-icon dark small>cloud_upload</v-icon>
                  <v-divider vertical class="mx-2"></v-divider> Upload Documents</v-btn>

                  <div >
                        <v-layout v-if="indtype.type == 'edit'" >
                            <v-btn dark tile  small color="success"  @click="updateFlag" class=" text-capitalize"><v-icon small left>arrow_upward</v-icon>Update Flag</v-btn>
                            <v-btn dark tile  small color="#E46A25" to="/ProviderSearch" class="text-capitalize px-3 mx-2" router-ln style="font-size:12px">
                                <div class="mt-1">
                                    <img src="../../assets/return.png" style="width: 16px;height: 16px"/>
                                </div> <v-divider vertical class="mx-2"></v-divider>Return</v-btn>
                            <!-- <v-btn dark tile  style="font-size:12px" small color="#23B1A9" class="text-capitalize px-3 mx-2"><v-icon dark small >edit</v-icon > <v-divider vertical class="mx-2"></v-divider>Edit Details</v-btn> -->
                            <v-btn dark tile style="font-size:12px" small color="#152F38" class="text-capitalize px-3 mx-2" @click="open_statushstry()"><v-icon dark small >find_in_page </v-icon> <v-divider vertical class="mx-2"></v-divider>Status History</v-btn>
                            <!-- <v-btn dark  tile color="#23B1A9" class="text-capitalize px-3 ml-2" small @click.stop="showScheduleForm=true"><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider> Save</v-btn> -->
                            <v-btn @click="nowsave" dark  tile color="#23B1A9" class="text-capitalize px-3 ml-2" small><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider> Save</v-btn>
                        </v-layout>

                        <v-layout style=""  v-else>
                            <v-btn v-if="indtype.type == 'new'" dark  tile color="#E46A25" class="text-capitalize px-3 mx-2" small to="/HomePage"><v-icon dark small>cancel</v-icon> <v-divider vertical class="mx-2"></v-divider>Cancel</v-btn>
                            <v-btn v-if="indtype.type == 'new'"  @click="clear" dark  tile color="#D1121B" class="text-capitalize px-3 mx-2" small><v-icon dark small>delete</v-icon> <v-divider vertical class="mx-2"></v-divider>Clear</v-btn>
                            <v-btn @click="nowsave" dark  tile color="#23B1A9" class="text-capitalize px-3 ml-2" small><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider> Save</v-btn>
                        </v-layout>
                  </div>
              
            </div>
        </div>

          <div v-if="docen">
                <docup @closedoc="docen = false"  @getdocslist="doc_for_creation"
                @deletenow="deletelocal"  :setdoc="{docen:docen,provider_data: this.indtype.data.indata, type:this.indtype.type, updocs:this.create_doc_arr,from:'pro' }" ></docup>
         </div>

           <v-layout >
            <v-dialog v-model="prvdrStsHstryDilg" persistent max-width="720px" style="overflow-x: hidden;">
                <v-card tile color="#F7F7F7" class="pb-2">
                    <v-card tile>
                        <v-layout justify-space-between  class="" style="flex:1;background-ox-shadow: 0px 3px 5px #00000029;">
                            <v-layout align-center>
                                <v-card-title class="pa-0 pl-3 text-capitalize font-weight-bold" style="color:#10242B;font-size:13px">Provider Status History</v-card-title>
                                <v-divider vertical inset class="mx-2"></v-divider>
                                <span class=" text-capitalize font-weight-bold" style="font-size:13px"><span style="color:#909699">{{ indtype.type === 'edit' ? indtype.data.indata.firstName+' '+indtype.data.indata.lastName : ''}} </span>- Status History</span>
                            </v-layout>
                            <div style="border-left:1px solid #C1C8CC;" @click="close_hstryFrm()">
                                <v-icon style="cursor: pointer;" class="pa-2" >close</v-icon>
                            </div>
                        </v-layout>
                    </v-card>
                        <!-- 
                            <v-card tile class="mx-3 mt-3">
                                <v-layout style="background-color:#23B1A9" >
                                    <span class="font-weight-bold pa-2 dlg-tbl-brdr" style="color:#FFFFFF;flex:1;">Status</span>
                                    <span class="font-weight-bold pa-2 dlg-tbl-brdr" style="color:#FFFFFF;flex:1;">Date</span>
                                    <span class="font-weight-bold pa-2 dlg-tbl-brdr" style="color:#FFFFFF;flex:1;">Time</span>
                                    <span class="font-weight-bold pa-2 dlg-tbl-brdr" style="color:#FFFFFF;flex:1.5;">User</span>
                                    <span class="font-weight-bold pa-2" style="color:#FFFFFF;flex:1.5;font-size:12px;border-top:1px solid #C1C8CC;">Remarks</span>
                                </v-layout>
                                <v-layout  v-for="(item,index) in 5" :key="index">
                                    <span style="color:#10242B;flex:1;" class="font-weight-bold pa-2 dlg-tbl-brdr">PRN Generated</span>
                                    <span style="color:#10242B;flex:1;" class="pa-2 dlg-tbl-brdr">10/02/2020</span>
                                    <span style="color:#10242B;flex:1;" class="pa-2 dlg-tbl-brdr">12.05 PM</span>
                                    <span style="color:#10242B;flex:1.5;" class="pa-2 dlg-tbl-brdr">ABC __ __ __ __ __ __</span>
                                    <span style="color:#10242B;flex:1.5;font-size:12px;border-top:1px solid #C1C8CC;" class="pa-2">2 fraud claims identified</span>
                                </v-layout>

                            </v-card> -->
                            
              
                <v-data-table
                    :headers="headers"
                    :items="indiStatusHisData"
                    :page.sync="page"
                    :items-per-page="statusItemsPerPage"
                    hide-default-footer
                    dense
                    @page-count="pageCount = $event"
                    height="195px"
                >
                <!-- <template v-slot:item.bulkUploadType="{ item }">
                    <div >{{ item.bulkUploadType | checkdata }}</div>
                </template>
                <template v-slot:item.dateOfUpload="{ item }">
                    <div >{{ item.dateOfUpload| checkdata |formatDate}}</div>
                </template>
                <template v-slot:item.FileName="{ item }">
                    <div >{{ item.fileName | checkdata}}</div>
                </template>
                <template v-slot:item.noOfEntries="{ item }">
                    <div >{{ item.noOfEntries| checkdata }}</div>
                </template>
                <template v-slot:item.noOfEntriesProcessed="{ item }">
                    <div >{{ item.noOfEntriesProcessed | checkdata}}</div>
                </template>
                <template v-slot:item.noOfEntriesFailed="{ item }">
                    <div >{{ item.noOfEntriesFailed | checkdata}}</div>
                </template>
                    <template v-slot:item.fileLink="{ item }" >
                         <v-btn color="#1E9690" @click="dwnldFaildRcrds(item.fileLink)" icon outlined dark small class= "my-1">
                            <v-icon dark small>get_app</v-icon>
                        </v-btn>
                    </template> -->
                </v-data-table>
                <v-divider></v-divider>
                <v-layout row justify-end align-center class="ma-2">
                            <span class="mr-1 font-weight-bold" style="color:#3C3D3D; font-size:13px;">
                                <span class="" style="color:#E46A25;">Showing {{recordFrom}}-{{recordTo}} </span>out of {{totalRecords}} records
                            </span>
                            <v-divider  class="mx-2" vertical ></v-divider>
                             <v-pagination
                                class="cust-bnkAcc-pgn"
                                v-model="page"
                                color="#152F38"
                                flat
                                @input="nextPageBtn"
                                :length="pageLength"
                            ></v-pagination>
                        </v-layout>
                            <!-- <v-card  tile class="mx-3 py-2 mb-2" color="#F7F7F7">
                            <v-layout row justify-end align-center  class="mr-1">
                                <span class="mr-1 font-weight-bold" style="color:#3C3D3D; font-size:13px;">
                                <span class="" style="color:#E46A25;">Showing 1-5 </span>out of 75 records
                                </span>
                                <v-divider  class="mx-2" vertical ></v-divider>
                                <v-pagination 
                                    class="cust-bnkAcc-pgn"
                                    v-model="pageNumber"
                                    color="#152F38"
                                    flat
                                    @input="nextPage"
                                    :length="3"></v-pagination>
                            </v-layout>
                            </v-card> -->
                            
                            <v-layout class="mr-2" row justify-center style="">
                                <v-btn tile  dark color="#152F38" class=" px-3 text-capitalize" small @click="close_hstryFrm()">
                                    <v-icon color="" small >cancel</v-icon>
                                    <v-divider vertical class="ml-2 mr-2 text-capitalize"></v-divider>Return
                                </v-btn>
                            </v-layout>
                            
                        </v-card>
                    </v-dialog>
                </v-layout>

       

            <v-layout>     
                    <v-dialog v-model="errDialog" persistent max-width="380">
                        <v-card class="my-0">
                        <v-card tile>
                                <v-layout justify-end class="" style="flex:1;background-ox-shadow: 0px 3px 5px #00000029; ">
                             
                                    <div  style="border-left:1px solid #C1C8CC;" @click="errForDupProvider('No')">
                                        <v-icon style="cursor: pointer; height:15px" class="pa-2" >close</v-icon>
                                    </div>
                                </v-layout>
                            </v-card>

                            <v-card-title class="headline rounded-0" >
                                
                                <v-alert
                                color="red"
                                dense
                                border="left"
                                elevation="2"
                                colored-border 
                                class="vaIcon"
                                icon="mdi-information"
                                style="width:300px"
                                
                                >
                                <v-card-text class="pa-0">
                                    <h4>WARNING</h4>
                                   <h5>Potential Duplicate Provider</h5>
                                </v-card-text>
                                </v-alert>

                                <v-card 
                                v-scroll.self="onScroll"
                                class="overflow-x-auto my-0"
                                max-height="400"
                                width="400px">   

                                    <div class="d-flex" justify-space-between style="width:250px" v-for="(data,index) of duplicateData" :key="index" :id="proiderDupliErrorId=data._id">
                                        <div>
                                            <p class="ma-0 ml-2" style="font-size:15px; height: 20px">First Name</p>
                                            <span class="ml-2" style="font-size: 13px; color: orange; cursor: pointer;"  @click="providerDupliErrorMsg">{{data.firstName}}</span>
                                        </div>                                                                                      
                                    <v-divider vertical inset class="mx-4" ></v-divider>
                                        <div>
                                                <p class="ma-0 ml-3" style="font-size:15px; height: 20px">Last Name</p>
                                                <span class=" ma-0 ml-3" style="font-size: 13px;">{{data.lastName}}</span>
                                        </div>                                                      
                                    </div> 

                                </v-card> 

                            </v-card-title>
                                
                            <v-card-actions>

                               

                                <!-- <v-btn dark tile color="#23B1A9" class="text-capitalize px-3  ml-2" small @click="errForDupProvider('Yes')" >
                                    Yes
                                </v-btn> -->
                                <v-btn tile dark color="#E46A25" class="text-capitalize px-3 ml-4" small @click="errForDupProvider('No')">
                                    <v-icon color="" small >cancel</v-icon>
                                    <v-divider vertical class="ml-2 mr-2"></v-divider>Cancel
                                </v-btn>

                                <v-btn tile dark color="#23B1A9" class="text-capitalize px-3" small @click="errForDupProvider('Yes')">
                                    <v-icon color="" small >check_circle</v-icon>
                                    <v-divider vertical class="ml-2 mr-2"></v-divider>Continue
                                </v-btn>
                                <!-- <v-btn dark tile color="#D1121B" class="text-capitalize px-3  mx-2 rounded-0" small @click="errForDupProvider('No')" >
                                    No
                                </v-btn> -->
                                 
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-layout>


                <!-- provider update flag  -->
                 <v-layout>     
                    <v-dialog v-model="updateFlagStatus"   max-width="80%">
                        <v-card class="mt-4 " height="600px" >
                        <v-card tile>
                                <v-layout justify-end class="" style="flex:1;background-ox-shadow: 0px 3px 5px #00000029; ">
                             
                                    <div  style="border-left:1px solid #C1C8CC;" @click="updateFlagStatus=false">
                                        <v-icon style="cursor: pointer; height:15px" class="pa-2" >close</v-icon>
                                    </div>
                                </v-layout>
                            </v-card>
                        <v-form ref="updateForm" class="pa-5">
                            <v-layout class=" mt-4" >
                                <v-flex xs3>
                                    <v-layout column class="mx-2 my-2">
                                        <v-text class="text-uppercase font-weight-bold title-clr-pr-ACD">
                                            Provider Flag Update
                                        </v-text>
                                        <v-divider></v-divider>
                                    </v-layout>
                                </v-flex>

                                <v-flex xs3>
                                <v-layout column class="cust_pdTDS mx-2 my-2 ">
                                    <v-text class="font-weight-bold cstm-clr-pd">
                                       PRN
                                    </v-text>
                                
                                    <v-text-field
                                        solo
                                        disabled
                                        label="Enter..."
                                        v-model="updatePRN"
                                        class="rounded-0"
                                        maxlength="10"
                                        dense
                                        >
                                    </v-text-field>
                                </v-layout>
                                </v-flex>

                                <v-flex xs3>
                                    <v-layout column class="cust_pdTDS mx-2 my-2 ">
                                        <v-text class="font-weight-bold cstm-clr-pd">
                                            Provider Name
                                        </v-text>
                                    
                                        <v-text-field
                                            solo
                                            disabled
                                            label="Enter..."
                                            v-model="updateProviderName"
                                            class="rounded-0"
                                            maxlength="10"
                                            dense
                                            >
                                        </v-text-field>
                                    </v-layout>
                                </v-flex>

                                <v-flex xs3>
                                    <v-layout column class="cust_pdTDS mx-2 my-2 ">
                                        <v-text class="font-weight-bold cstm-clr-pd">
                                            Provider Status
                                        </v-text>
                                    
                                        <v-text-field
                                            solo
                                            disabled
                                            label="Enter..."
                                            v-model="updateProStatus"
                                            class="rounded-0"
                                            maxlength="10"
                                            dense
                                            >
                                        </v-text-field>
                                    </v-layout>
                                </v-flex>

                                

                                
                            </v-layout>


                            <v-layout class=" mt-2" >
                                <v-flex xs3>
                                    <v-layout column class="mx-2 my-2">
                                        <v-text class="text-uppercase font-weight-bold title-clr-pr-ACD">
                                            Provider Flag 
                                        </v-text>
                                        <v-divider></v-divider>
                                    </v-layout>
                                </v-flex>

                            <v-flex xs3>
                                <v-layout column class="mx-2 my-2">
                                    <v-checkbox
                                                dense
                                                color = "#23b1a9" 
                                                style="margin-top: 3px; margin-left: 16px"
                                                label="Platinum" 
                                                v-model="platinum" 
                                                >
                                    </v-checkbox>

                                    <v-checkbox
                                                dense
                                                color = "#23b1a9" 
                                                style="margin-top: 3px; margin-left: 16px"
                                                label="Gold" 
                                                v-model="gold" 
                                                >
                                    </v-checkbox>


                                    <v-checkbox
                                                dense
                                                color = "#23b1a9" 
                                                style="margin-top: 3px; margin-left: 16px"
                                                label="Silver" 
                                                v-model="silver" 
                                                >
                                    </v-checkbox>
                                    
                                    <v-checkbox
                                                dense
                                                color = "#23b1a9" 
                                                style="margin-top: 3px; margin-left: 16px"
                                                label="Verified" 
                                                v-model="verifiedFlag"
                                                >
                                    </v-checkbox>
                                                
                                    <v-checkbox
                                                dense
                                                color = "#23b1a9" 
                                                style="margin-top: 3px; margin-left: 16px"
                                                label="Under Observation" 
                                                v-model="observationFlag" 
                                                >
                                    </v-checkbox>

                                    <v-checkbox
                                                disabled
                                                dense
                                                color = "#23b1a9" 
                                                style="margin-top: 3px; margin-left: 16px"
                                                label="Non-Service Provider" 
                                                v-model="nonService" 
                                                >
                                    </v-checkbox>

                                </v-layout>
                            </v-flex>

                                <v-flex xs6>
                                    <v-layout column class="cust_pdTDS mx-2 my-2 ">
                                        <v-text class="font-weight-bold cstm-clr-pd">
                                            Remarks*
                                        </v-text>
                                    
                                        <v-text-field
                                            solo 
                                            label="Enter..."
                                            v-model="updateRemarks"
                                            class="rounded-0"
                                            maxlength="200"
                                            dense
                                            :rules="[nameRulesUpdateFlag(updateRemarks)]"
                                            >
                                        </v-text-field>
                                    </v-layout>
                                </v-flex>  
                            </v-layout>

                            <div class="mx-2 pa-5" style="background-color: #F7F7F7; display:flex;justify-content:space-between;"  >
                                <v-layout style="flex:1" justify-end > 
                                    <v-btn dark tile color="#23B1A9" class="text-capitalize px-3 ml-3 testing" small @click="submitUpdateFlag"><v-icon dark small>mdi-arrow-up-circle</v-icon> <v-divider vertical class="mx-2"></v-divider>Update</v-btn>
                                    <v-btn dark tile color="#E46A25" class="text-capitalize px-3 ml-3" small  @click="updateFlagStatus=false"><v-icon dark small>cancel</v-icon> <v-divider vertical class="mx-2"></v-divider>Cancel</v-btn>
                                </v-layout>
                            </div>
                        </v-form>
                        </v-card>
                    </v-dialog>
                </v-layout>
               
                
    </v-app>
</template>

<script>
import moment from 'moment';
import docup from './docup'
// const { required, minLength, alpha, email } = require('vuelidate/lib/validators/')
const { required, minLength, alpha, email,helpers } = require('vuelidate/lib/validators/')
export const alphaRegex = helpers.regex('alpha', /^[a-zA-Z.' ]+$/);

let putId = '';

export default {

    components:{docup},

    created(){
        if(this.dataFromRoute){

            console.log("from route",this.dataFromRoute);
            this.frstnmeInd = this.dataFromRoute.data.firstName
            this.lstnmeInd = this.dataFromRoute.data.lastName
            this.mbNoInd = this.dataFromRoute.data.mobileNumber
            this.prnInd = this.dataFromRoute.data.prnNumber
            this.prvdrTypeInd = this.dataFromRoute.data.providerType
            this.RgstNo = this.dataFromRoute.data.registrationNumber
            this.RgstCnlInd = this.dataFromRoute.data.registeredCouncil
            
        }
         if(this.indtype.type == 'edit'){
            this.$store.commit("SAVE_PAGE_HEADER", "Provider Individual Summary");
         }else{
            this.$store.commit("SAVE_PAGE_HEADER", "Provider Individual Registration");
         }

        if(this.indtype.type == 'edit'){
            console.log("I am edit page 125");
            this.setformdata()
            
        }

        this.platinum = false
        this.gold = false
        this.silver = false
        this.verifiedFlag = false
        this.observationFlag = false
        this.nonService = false

    },

    mounted(){
        console.log("this is setdata 111",this.setformdata);
       console.log("from mounted props is -> ",this.indtype.data);
       let idFromEdit = this.indtype.data.indata._id
       putId = idFromEdit;
       console.log(putId);

    },
   
    computed: {
 
        salute(){
              const errors = []
                if (!this.$v.salutationind.$dirty) return errors
                !this.$v.salutationind.required && errors.push('This field is required')
                return errors
        },

        mname(){
              if(this.mdlnmeInd.length > 0){
             if(/^[a-zA-Z]+$/g.test(this.mdlnmeInd) != true){
                    // this.ttl_err = true
                    return "Only alphabets are allowed"
                }else{
                    // this.ttl_err = false
                    return []
                }
              }else{
                  return []
              }
        },

        
        othersistwo(){
               const errors = []
                 if(!this.qualtype_other_dis)  return errors
                 if (!this.$v.other_qual.$dirty) return errors
                !this.$v.other_qual.required && errors.push('Enter details for Qualification')
                return errors
        },

        othersisthree(){
                 const errors = []
            if(!this.council_other_dis)  return errors
                if (!this.$v.council_other.$dirty) return errors
                !this.$v.council_other.required && errors.push('Enter details for Provider Council')
                return errors
            
        },

        othersisone(){
             const errors = []
                if(!this.ptype_other)  return errors
                if (!this.$v.otherptype.$dirty) return errors
                !this.$v.otherptype.required && errors.push('Enter details for Provider type')
                return errors 
        },

        lname(){
            const errors = []
            if (!this.$v.lstnmeInd.$dirty) return errors
            !this.$v.lstnmeInd.required && errors.push('This field is required')
            !this.$v.lstnmeInd.alphaRegex && errors.push('Only alphabets are allowed')
            
            return errors
        },

        prvdrTypeIndError(){
            const errors = []
            if (!this.$v.prvdrTypeInd.$dirty) return errors
            !this.$v.prvdrTypeInd.required && errors.push('This field is required')
            return errors 
        },



        fname(){
            const errors = []
            if (!this.$v.frstnmeInd.$dirty) return errors
            !this.$v.frstnmeInd.required && errors.push('This field is required')
            !this.$v.frstnmeInd.alphaRegex && errors.push('Only alphabets are allowed')
            
            return errors
          },

        // computedDateFormattedMomentjsnew(){
        //     console.log(this.RgstrnVldUptodate);
        //     console.log(moment(this.RgstrnVldUptodate).format("DD-MM-YYYY"));
        //     return this.RgstrnVldUptodate ? moment(this.RgstrnVldUptodate).format("DD-MM-YYYY"): ''
        // },

        // computedDateFormattedMomentjs () {
        //     console.log(this.RgstrnVldUptodate);
        //     console.log(moment(this.RgstrnVldUptodate).format("YYYY-MM-DD"));
        //     return this.RgstrnVldUptodate ? moment(this.RgstrnVldUptodate).format("YYYY-MM-DD"): ''
        // },

        computedregValidityTo(){
            console.log(this.regValidityTo)
            console.log(this.regValidityTo ? moment(this.regValidityTo).format("DD-MM-YYYY"): '')
            return this.regValidityTo ? moment(this.regValidityTo).format("DD-MM-YYYY"): ''
        }


    },

    data() {
        return {
        updateFlagStatus:false,
        updatePRN:'',
        updateProviderName:'',
        updateProStatus:'',
        platinum:Boolean,
        gold:Boolean,
        silver:Boolean,
        verifiedFlag:Boolean,
        observationFlag:Boolean,
        nonService:Boolean,
        updateRemarks:'',
        // pagination variable 
        create_doc_arr:[],
        docen:false,
        page: 1,
        recordFrom:null,
        recordTo: null,
        statusItemsPerPage: 5,
        totalRecords: null,
        regCalenderDate:false,
        regValidityTo: "",

        indiStatusHisData: [],
        prvdrStsHstryDilg:false,
        headers:[
            {text: 'Status', value:'status',sortable: false,divider:true,class:"hdr-txt-clrnew "},
            {text: 'Date', value: 'Date',sortable: false,divider:true,class:"hdr-txt-clrnew"},
            {text: 'Time', value: 'Time',sortable: false,divider:true,class:"hdr-txt-clrnew"},
            {text: 'User', value: 'userName',sortable: false,divider:true,class:"hdr-txt-clrnew"},
            {text: 'Remarks (if any)', value: 'remarks',sortable: false,divider:true,class:"hdr-txt-clrnew"},   
         ],
            valid:true,
            council_other_dis:false,
            council_other:'',

            qualtype_other_dis:false,
            other_qual:'',

            ptype_other:false,
            otherptype:'',
            checkbox: false,
            items:['Institution', 'Individual'],
            rgstrdEntity:'Institution',
            RgstrnVldUptocalender: false,
            RgstrnVldUptodate: "",
            RgstrtnVldUpto:"",
            entityNme:'',
            alsNme:'',
            prvdrtyeitems:['Others'],
            providerType:"",
            other:"",
            rgstNo:"",
            SrvcsPrvded:"",
            tlphnNo:"",
            adrss:"",
            prn:"",
            status:"",
            statusItems:["Active", "Inactive"],
            ptype:[ 'Doctor',
                    'Dentist',
                    'Physiotherapist',
                    'Homeopath',
                    'Ayurved',
                    'Nurse',
                    'Psychotherapist',
                    'Nutritionist',
                    'Dietician',
                    'Other',
                ],
            rohiniId:"",
            RgstrdBdy:"",
            email:"",
            fxNo:"",
            adrsLne1:"",
            adrsLne2:"",
            adrsLne3:"",
            country:"",
            cntryItems:["India","Others"],
            stateItems:["Maharashtra","Gujarat","Karnataka"],
            state:"",
            district:"",
            districtItems:["Thane", "Palghar","Raigad"],
            pnCde:"",
            ctvItems:["City","Town","Village", "Others"],
            ctv:"",
            othrAdrs:"",
            NoofBeds:"",
            dlyMdclRcrdsItems:[],
            dlyMdclRcrds:"",
            hsOtItems:[],
            hsOt:"",
            mprtcItems:[],
            mprtc:"",
            nrtcItems:[],
            nrtc:"",
            shwInstitution:true,
            shwIndividual:false,
            salutationind:'',
            sltnIndItems:["Dr","Mr","Mrs","Ms","Prof"],
            frstnmeInd:'',
            mdlnmeInd:'',
            lstnmeInd:'',
            gndrInd:'',
            gndrIndItems:["Male","Female","Other"],
            mbNoInd:'',
            extrnlRfNoInd:'',
            prnInd:'',
            statusInd:"",
            statusIndItems:["Active", "Inactive"],
            qualificationIndItems:[
                    'MBBS',
                    'MD',
                    'MS',
                    'DM',
                    'MCh',
                    'DNB',
                    'FRCS',
                    'MRCP',
                    'BDS',
                    'MDS',
                    'BSc (Nursing)',
                    'MSc (Nursing)',
                    'BPt',
                    'MPt',
                    'BAMS',
                    'BHMS',
                    'BUMS',
                    'PhD',
                    'Other',],
        council:[
                'Himanchal Pradesh Medical Council',
                'Jammu & Kashmir Medical Council',
                'Jharkhand Medical Council',
                'Karnataka Medical Council',
                'Travancore Cochin Medical Council',
                'Madhya Pradesh Medical Council',
                'Maharashtra Medical Council',
                'Manipur Medical Council',
                'Mizoram Medical Council',
                'Nagaland Medical Council',
                'Orissa Council of Medical Registration',
                'Punjab Medical Council',
                'Rajasthan Medical Council',
                'Sikkim Medical Council',
                'Tamil Nadu Medical Council',
                'Telangana State Medical Council',
                'Tripura State Medical Council',
                'Uttar Pradesh Medical Council',
                'Uttarakhand Medical Council',
                'West Bengal Medical Council',
                'Central Council of Indian Medicine',
                'Other',

            ],
            prvdrTypeInd:'',            
            otherInd:'',
            qualificationInd:'',
            otherInd2:'',
            RgstNoItems:['Others'],
            RgstNo:'',
            RgstCnlInd:'',
            OtherInd3:'',
            QualificationInd2:'',
            remarksInd:'',
            providerDupliFirstName:'',
            providerDupliLastName :'',
            errDialog:false,
            isOverrideForError : false,
            duplicateData: [],
            proiderDupliErrorId:'',

            emailRules: [
                    // v => !!v || 'E-mail is required',
                    v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,6})+$/.test(v) || 'E-mail must be valid'
            ],
            
             rules: {
                  noOnly(){
                     return v => /^[0-9 ]+$/.test(v) || 'Numbers Only Allowed'
                 },


                spclChar(indata){
                    // console.log(this[indata] 
                    //     this[indata] = ''
                    if(v=>/^[A-Za-z ]+$/.test(v)){
                            console.log('from special chactracter',)
                    }else{
                        console.log("from else")
                    }
                
                    // || `Special Characters & numbers Not Allowed`
                },
                 
                // minRegNo: v =>v.length <= 3|| v.length == 5  || 'Enter valid Registration Number',
                minRegNo: value => {
                            // const pattern = /^([0-9])+$/
                        const pattern = /^[a-zA-Z0-9]+$/
                        return pattern.test(value) || 'Enter valid Registration Number.'
                },
             }

        };

    },

    validations: {

        salutationind: { required },
        frstnmeInd: { required, alphaRegex},
        lstnmeInd: { required, alphaRegex},
        council_other:{ required },
        other_qual:{required},
        otherptype:{required},
        mdlnmeInd:{ alpha },
        email:{email},
        prvdrTypeInd:{required}
   
    },

    props:{
        indtype:{
            required:true,
            type:Object
        },
        dataFromRoute:{
            type:Object
        }
    },

    methods:{

        opendocs(){  
                this.docen = true
            //   this.indtype.data.indata._id
        },

        nameRulesUpdateFlag(v){
                if(v.length > 0){
                    return v => /^[a-zA-Z ]+$/.test(v) || 'Only alphabets are allowed'
                }
        },

        submitUpdateFlag(){
            this.$refs.updateForm.validate()
            if(!this.$refs.updateForm.validate()){
                    console.log("not valid form");
                    this.showToast("Please fill all fields",this.TOST().WARNING)
            }else{
                let id =  this.indtype.data.indata._id
                let formDataUpdate =  {
                                        prn :  this.updatePRN,
                                        providerName : this.updateProviderName,
                                        providerStatus : this.updateProStatus,
                                        Platinum : this.platinum,
                                        Gold : this.gold,
                                        Silver : this.silver,
                                        Verified : this.verifiedFlag ,
                                        underObservation : this.observationFlag,
                                        nonServiceProvider : this.nonService,
                                        remarks : this.updateRemarks,
                                    }   
                
                this.PUT(2,'provider/update-flag/'+id, formDataUpdate, (res,error)=>{
                                        if(!error) {
                                                console.log("response data for update flag",res);
                                                this.showToast(res.data.msg,this.TOST().SUCCESS)
                                        }else{
                                            console.log("error response",error.response);
                                            this.showToast(error.response.data.msg,this.TOST().ERROR)
                                        }
                   })

            }
                


        },


        updateFlag(){
            console.log("update flag was clicked");
            this.updateFlagStatus = true
        },

        deletelocal(indata_id){
            console.log(this.create_doc_arr,"deletelocal called ", indata_id);
            var poss = this.create_doc_arr.findIndex(e => e._uid == indata_id)
            console.log("poss is ",poss);
            this.create_doc_arr.splice(poss, 1);
        },

        doc_for_creation(docarr){

            if(docarr.length != 0){
                  this.create_doc_arr.push(docarr)
            }

        },
       

        chkplcydata(data){
            console.log("Policy sara",data); 
            try{
                if (data ==="" || data === "undefined" || data === null) {
                    return ""
                } else {
                    return data;
                }

             }catch(err) {
                console.log(err)
            }   
        },

        checkDate(data){
            if (data == "" || data == "undefined" || data == null || data == 'Invalid Date') {
                return ""
            } else {
                return moment(data, 'YYYY-MM-DD').format('DD-MM-YYYY')
            }
        },

        // sf99
         setformdata(){
            this.salutationind = this.indtype.data.indata.salutation
            this.frstnmeInd = this.indtype.data.indata.firstName
            this.mdlnmeInd = this.indtype.data.indata.middleName
            this.lstnmeInd = this.indtype.data.indata.lastName
            this.mbNoInd = this.indtype.data.indata.mobileNumber
            this.extrnlRfNoInd = this.indtype.data.indata.externalReferenceNo
            this.prvdrTypeInd = this.indtype.data.indata.providerType
            this.otherptype = this.indtype.data.indata.providerTypeOther
            this.RgstNo = this.indtype.data.indata.registrationNumber
            this.prnInd = this.indtype.data.indata.prn
            this.status = this.indtype.data.indata.status
            this.gndrInd = this.indtype.data.indata.gender
            this.email = this.indtype.data.indata.email
            this.qualificationInd = this.indtype.data.indata.qualification
            this.other_qual = this.indtype.data.indata.qulificationOther
            this.regValidityTo = (this.indtype.data.indata.registrationValidUpto ===  '' || this.indtype.data.indata.registrationValidUpto ==  null) ? '': moment(this.indtype.data.indata.registrationValidUpto,'YYYY-MM-DD').format('YYYY-MM-DD')
            this.RgstCnlInd = this.indtype.data.indata.registeredCouncil
            this.council_other = this.indtype.data.indata.registeredCouncilOther
            this.remarksInd = this.indtype.data.indata.remarks
            this.updatePRN = this.indtype.data.indata.prn
            this.updateProviderName = this.indtype.data.indata.firstName
            this.updateProStatus = this.indtype.data.indata.status

            
            //    this.RgstrnVldUptodate = moment(this.RgstrnVldUptodate).format('DD-MM-YYYY');
            //  console.log("---> ", moment(this.indtype.data.indata.registrationValidUpto.split('T')[0]).format('DD-MM-YYYY'))
        },

         nextPageBtn(page){
            console.log(page);
            var tra =  this.page*10;
            this.recordFrom = tra-9;
            this.recordTo = tra;
            this.open_statushstry();
        },

        open_statushstry(){

            console.log(this.indtype.data.indata,"--> provider is for status history API ", this.indtype.data.indata._id);

               this.GET(2,'provider/history-by-provider/'+this.indtype.data.indata._id+'?perPage='+this.statusItemsPerPage+'&pageNo='+this.page, res=>{
                   console.log("history by provider -> ",res)
                   var sData = res.data.data.data;
                    this.pageLength = res.data.data.total_pages;
                    this.totalRecords = res.data.data.total
                    var tra =  this.page*10;
                    this.recordFrom = tra-9;
                    this.recordTo = tra

                    var statusData = []
                    for(var data of sData){
                        
                        data.Date = moment(data.createdAt, moment.ISO_8601).format("DD/MM/YYY hh:mm:ss").substr(0,10);
                            data.Time = moment(data.createdAt, moment.ISO_8601).format("DD/MM/YYY hh:mm:ss").substr(13,15);
                            
                            statusData.push(data)
                    }
                    this.indiStatusHisData = statusData
                    this.prvdrStsHstryDilg=true
               });
           
        },

        close_hstryFrm(){
            this.prvdrStsHstryDilg=false
        },

        
        ptypeis(indata){
                console.log("p type called as indata is other")
            if(indata == 'Other'){
                this.ptype_other = true
            }else{
                  this.ptype_other = false
                  this.otherptype = ''
            }

        },
      
        countype(indata){

            if(indata == 'Other'){
                this.council_other_dis = true
            }else{ 
                this.council_other_dis = false
                 this.council_other = ''
            }

        },

        qualis(indata){

            if(indata == 'Other'){
                this.qualtype_other_dis = true
            }else{
                this.qualtype_other_dis = false
                 this.other_qual = ''
            }

        },




        nowsave(){
           
              this.$v.$touch();
                console.log(" this.$v.frstnmeInd", this.$v)
                // console.log(this.indvdlshw," this.$v.frstnmeInd", this.$v)
            //  if(this.$v.frstnmeInd.$invalid || this.$v.lstnmeInd.$invalid || this.$v.mdlnmeInd.$invalid){
            //         console.log("Form has errors");  
            //         this.showToast('Please fill all required fields', this.TOST().WARNING);
            //         return
            // }
             if(this.$v.frstnmeInd.$invalid || this.$v.lstnmeInd.$invalid || this.$v.mdlnmeInd.$invalid || this.$v.email.$invalid || this.$v.prvdrTypeInd.$invalid){
                       console.log("Form has errors");  
                    this.showToast('Please fill all required fields', this.TOST().WARNING);
                    return
             }else{
                    console.log('proceed');
             }  

            

            console.log("pt ->" ,this.ptype_other);
            console.log("qual ->" ,this.qualtype_other_dis);
            console.log("council ->" ,this.council_other_dis);
        
             if(this.ptype_other){
                    if(this.$v.otherptype.$invalid){
                          this.showToast('Please fill all required fields', this.TOST().WARNING);
                          return
                    }
             }
             if(this.qualtype_other_dis){
                     if(this.$v.other_qual.$invalid){
                          this.showToast('Please fill all required fields', this.TOST().WARNING);
                          return
                    }
             }
             if(this.council_other_dis){
                  if(this.$v.council_other.$invalid){
                          this.showToast('Please fill all required fields', this.TOST().WARNING);
                          return
                    }
             }


           
            console.log("make form data hit api, milseconds is ");
                // return
            //   console.log(this.$v)
            //  this.$refs.form.validate()
            //  return
            //  console.log(this.$refs.form)

            // fd99

            var formData ={
                uploadDocs: this.create_doc_arr,
                salutation:this.salutationind,
                registeredEntity: 'Individual',
                prn: this.prnInd,
                firstName: this.frstnmeInd,
                lastName: this.lstnmeInd,
                middleName: this.mdlnmeInd,
                status: this.status,
                gender: this.gndrInd ,
                mobileNumber: this.mbNoInd,
                email: this.email ,
                externalReferenceNo: this.extrnlRfNoInd,
                // "salutation": "Unknown Type: date",
                qualification:this.qualificationInd,
                qulificationOther:this.other_qual,
                providerType: this.prvdrTypeInd,
                providerTypeOther: this.otherptype,
                registeredCouncil: this.RgstCnlInd,
                registeredCouncilOther: this.council_other,
                registrationNumber: this.RgstNo,
                registrationValidUpto: this.regValidityTo,
                remarks: this.remarksInd,
                 override: this.isOverrideForError,
            };


            var putFormdata ={
                salutation:this.salutationind,
                registeredEntity: 'Individual',
                prn: this.prnInd,
                firstName: this.frstnmeInd,
                lastName: this.lstnmeInd,
                middleName: this.mdlnmeInd,
                status: this.status,
                gender: this.gndrInd ,
                mobileNumber: this.mbNoInd,
                email: this.email ,
                externalReferenceNo: this.extrnlRfNoInd,
                // "salutation": "Unknown Type: date",
                qualification:this.qualificationInd,
                qulificationOther:this.other_qual,
                providerType: this.prvdrTypeInd,
                providerTypeOther: this.otherptype,
                registeredCouncil: this.RgstCnlInd,
                registeredCouncilOther: this.council_other,
                registrationNumber: this.RgstNo,
                registrationValidUpto: this.regValidityTo,
                remarks: this.remarksInd,
                override: this.isOverrideForError,
                _id: putId
            }

            console.log(' formData review  ', formData)
            
            var BUILD_API =  this.indtype.type == 'new' ? 'provider/create-provider-individual' :'provider/edit-provider-individual'
            console.log(BUILD_API);

            
            if(BUILD_API != 'provider/edit-provider-individual'){
                
                this.POST(2,BUILD_API, formData, (res,error)=>{
                   if(!error) {
                        if(res.data.statusCode == 200){
                        this.showToast(res.data.msg ,this.TOST().SUCCESS);
                        this.$router.push('/ProviderSearch')
                    }else{
                        console.log("when status is not 200", res);
                        this.showToast(res.data.msg ,this.TOST().ERROR); 
                    }
                }else{
                    console.log(error);
                    this.showToast(error.response.data.msg ,this.TOST().ERROR); 
                    if(error.response.data.msg == "Potential Duplicate Provider"){
                            console.log("Potential Duplicate Provider");
                            let errData =  error.response.data.data
                             console.log(error.response.data.data);
                            let dupArr = error.response.data.data;
                            for(let dupData of dupArr ){
                                console.log(dupData);
                                this.duplicateData.push(dupData);
                            }
                            this.errDialog = true
                        }

                }
                    console.log("APi response from |-> provider/create-provider-individual ", res);
                })
            }else{
                
               
                this.PUT(2,BUILD_API, putFormdata, (res,error)=>{
                    
                    if(!error){
                        if(res.data.statusCode === 200){
                        this.showToast(res.data.msg ,this.TOST().SUCCESS);
                        this.$router.push('/ProviderSearch')
                    }else{
                        console.log("when status is not 200", res);
                        this.showToast(res.data.msg ,this.TOST().ERROR); 
                    }
                }else{
                    console.log(error, res);
                    this.showToast(error.response.data.msg ,this.TOST().ERROR); 
                    if(error.response.data.msg == "Potential Duplicate Provider"){
                            console.log("Potential Duplicate Provider");
                             console.log(error.response.data.data);
                            let dupArr = error.response.data.data;
                            for(let dupData of dupArr ){
                                console.log(dupData);
                                this.duplicateData.push(dupData);
                            }
                            this.errDialog = true
                        }
                }
                    console.log("APi response from |-> provider/edit-provider-individual ", res);
                })
            }


        },

         //    This is for click on entity name  for Duplicate provider Dialog
        providerDupliErrorMsg(){
            console.log("%c this for provider duplication", "color:red");
            if(this.indtype.type === 'edit'){
                console.log("%c This is edit error for provider","color: violet");
                this.$emit('toggle-summary', this.proiderDupliErrorId)
            }else{
                this.$router.push({name: 'ProviderSummary', params: {id: 'individual',  prn: this.proiderDupliErrorId }});
            }
        },

        // This is for Yes No button  for Duplicate provider Dialog
        errForDupProvider(ans){
            if(ans == 'Yes'){
                this.errDialog = false
                this.isOverrideForError = true
            }else{
                this.isOverrideForError = false
                this.errDialog = false
            }
           
        },

        clear(){
            //  this.$refs.form.resetValidation();
            console.log("clear is working");
            this.$v.$reset();
            this.salutationind = '';
            this.frstnmeInd = '';
            this.mdlnmeInd = '';
            this.lstnmeInd = '';
            this.gndrInd = '';
            this.mbNoInd = '';
            this.email = '';
            this.extrnlRfNoInd = '';
            this.prnInd = '';
            this.status = '';
            this.prvdrTypeInd = '';
            this.otherptype = '';
            this.qualificationInd = '';
            this.other_qual = '';
            this.RgstNo = '';
            this.regCalenderDate = '';
            this.regValidityTo = '';
            this.RgstCnlInd = '';
            this.council_other = '';
            this.remarksInd = '';

        },

        

        required(ident, indata){ 
                //  console.log(indata, " ident is ", ident);
                   if(ident == 0){
                        return v=>v && v.length>0 || "This Field is Required"
                   }
                     
                        
        },

        // other_error_ptype(indata){
        //     if(this.ptype_other){
        //          return v=>v && v.length>0 || "Enter details for "+indata
        //     }else{
        //         return []
        //     }
        // },

        other_error_council(indata){
            if(this.council_other_dis){
                    return v=>v && v.length>0 || "Enter details for Council"
            }else{
                return []
            }
        },

        other_error_qual(indata){
             if(this.qualtype_other_dis){
                  return v=>v && v.length>0 || "Enter details for Qualification"
             }else{
                 return []
             }   
        },

        ptypeis(indata){

            if(indata == 'Other'){
                this.ptype_other = true
            }else{
                  this.ptype_other = false
                  this.otherptype = ''
            }

        },
      
        countype(indata){

            if(indata == 'Other'){
                this.council_other_dis = true
            }else{ 
                this.council_other_dis = false
                 this.council_other = ''
            }

        },

        qualis(indata){

            if(indata == 'Other'){
                this.qualtype_other_dis = true
            }else{
                this.qualtype_other_dis = false
                 this.other_qual = ''
            }

        },

        rgstrdEntySlctn(value){
            console.log(value)
            if(value==="Institution"){
                this.shwInstitution=true
                this.shwIndividual=false
            }else if(value==="Individual"){
                this.shwInstitution=false
                this.shwIndividual=true
            }

        }

    },

    watch: {
        // threeFlag(v){
        //     console.log("this is value of flag","\n",v);
        //     if(v.length >= 3){
        //        this.showToast("Platinum/Gold/Silver cannot be selected together",this.TOST().WARNING)
        //     }
        // }
    },
    
    
}

</script>
<style >

.masterfix{
    justify-content: space-between;
    background-color: rgb(255, 255, 255);
    height: 440px;
    display: flex;
    flex-direction: column;
   
}

.cust_pr .v-text-field .v-input__slot,
.v-text-field--outline .v-input__slot {
    min-height: 42px !important;
    display: flex !important;
    align-items: center !important;
    /* border:1px red solid; */
    
    
}

.cust-rmvpd-pr .v-text-field.v-text-field--enclosed>.v-input__control>.v-input__slot {
    padding: 0px !important;;
}
/* .cust_pr .v-input__slot{
    margin-bottom: unset !important;
} */
.cstm-clr-pr{
    color:#646666;
    margin-bottom: 5px;
    font-size: 13px !important;
}
.cust_pr .v-input {
    font-size: 13px !important;
}
.cust_pr .v-input input {
    font-size: 13px !important;
}
.cust_pr .v-input .v-label {
    font-size: 13px !important;
}

.title-clr-pr{
    color:#10242B;
    margin-bottom: 5px;
}

.lbl-bgclr{
    color:#152F38

}

/* .none .theme--light.v-text-field--solo > .v-input__control > .v-input__slot {
    background: #F7F7F7;
} */

.custPrn_Indvl .theme--light.v-text-field--solo > .v-input__control > .v-input__slot {
    background: #F7F7F7;
}

</style>