<template>
    <div class="mx-8 my-6">
        <v-row class="mb-2 ml-0 mr-0">
            <h3> Bulk Upload</h3>
            <v-spacer></v-spacer>
            <v-btn class="btnToggle pull-right" outlined elevation="0" @click="switchToBaseRateTdsUpload">
                <v-icon>mdi-repeat</v-icon>
                <v-divider class="mx-2 ml-2 white" vertical></v-divider>
                Switch to Base Rate TDS Master
            </v-btn>
        </v-row>

        <v-layout class="mb-0">
            <v-row>

                <v-col>
                    <h4> Upload File </h4>
                </v-col>

                <v-spacer></v-spacer>

                <v-col cols="3">
                    <v-btn class="btnToggle orange-background pull-right" outlined elevation="0" @click="downLoadExcelTemplateFile">
                        <v-icon>mdi-cloud-download</v-icon>
                        <v-divider class="mx-2 ml-2 white" vertical></v-divider>
                        Download Template
                    </v-btn>
                </v-col>

            </v-row>
        </v-layout>

        <v-row class="mx-0">
            <v-col cols="4" class="px-0">

                <div class="flex justify-between align-center">

                    <button
                        @click="uploadExcel"
                    >
                        <v-icon
                            class="rotate-90"
                            >mdi-attachment
                        </v-icon>
                    </button>
                    <v-text-field readonly flat class="filter-select-box mr-2" placeholder="Enter..." outlined solo dense depressed
                        hide-details v-model="excel.name" />
                    <v-btn
                        class="btnToggle orange-background pull-right"
                        outlined
                        elevation="0"
                        @click="processFile"
                        :disabled="!hasEditAccess || !excel.name">
                        <v-icon class="white-icon">mdi-cloud-upload</v-icon>
                        <v-divider class="mx-2 ml-2 white" vertical></v-divider>
                        Upload
                    </v-btn>

                    <v-file-input
                        single
                        v-model="excel"
                        accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/csv"
                        id="file-upload"
                        style="display: none"
                        @click="$event.target.value=''"
                    />

                </div>

            </v-col>
        </v-row>

        <v-row class="mx-0">
            <v-col class="px-0">
                <h4 class="mr-3">
                    Bulk Upload
                    <span class="orange--text">({{ totalRecords }})</span>
                </h4>
            </v-col>
        </v-row>


        <v-data-table :headers="tableHeaders" no-data-text="No Records" fixed-header :items="tableFilteredData"
            hide-default-footer class="vDataTableStyling">

            <template #item="{ item }">


                <tr>
                    <td>{{ item.fileName }}</td>

                    <td>
                        <span> {{ formattedDate(item.uploadedAt) }} </span>
                    </td>

                    <td>{{ item.uploadedBy }}</td>
                    <td>
                        <span class="textTransformCapitalize" :class="uploadStatusTextColor(item.status)">
                            {{ item.status }}
                        </span>
                    </td>

                    <!-- <td>
                        <span class="textTransformCapitalize" :class="approvedStatusTextColor(item.approvalStatus)"> {{
                            item.approvalStatus }} </span>
                    </td> -->


                    <td>
                        <v-btn plain color="#23b1a9" v-if="item.status == 'Complete'" @click="downloadReport(item)">
                            Download Report
                        </v-btn>

                        <v-btn v-else-if="item.status == 'Error'" plain color="#ee0914" @click="downloadErrorReport(item)">
                            Download Error Report
                        </v-btn>
                        <!-- <button @click="cancelEdit(item)">
                                <v-icon medium class="approvedTextColor">mdi-close</v-icon>
                            </button> --->
                    </td>
                </tr>

            </template>

        </v-data-table>

        <div class="text-center graybackground pdct-stUp-bnkAcc-pgn mt-3 mx-4" v-if="tableFilteredData.length > 0">
            <v-layout
                row
                justify-end
                align-center
                class="mx-2 mt-5"
                style="margin-left: 0px !important; margin-right: 0px !important"
            >
                <p
                    class="mr-1 font-weight-bold body-1 mt-4"
                    style="color: #3c3d3d; font-size: 12px !important"
                >
                    <span class="" style="color: #e46a25; font-size: 12px">
                        Showing {{ currentShowingRecordsStartIndex }}-{{ lastRecordLength }}
                    </span>out of {{ totalRecords }} records
                </p>
                <v-divider class="mx-2" vertical></v-divider>
                <v-pagination
                    class="cust-bnkAcc-pgn"
                    v-model="currentPage"
                    color="#152F38"
                    flat
                    :total-visible="10"
                    :length="totalPages"
                    @input="fetchFiles"
                ></v-pagination>
            </v-layout>
        </div>


       <validation-popup ref="popup" />

    </div>
</template>

<script>
import moment from "moment";
import ValidationPopup from './ValidationPopup.vue';

export default {
    components: {
        ValidationPopup,
    },
    data() {
        return {
            tableHeaders: [
                { text: 'File Name', align: 'left', value: 'fileName', sortable: false },
                { text: 'Uploaded At', align: 'left', value: 'uploadedAt', sortable: false },
                { text: 'Uploaded by', align: 'left', value: 'uploadedBy', sortable: false },
                { text: 'Uploaded Status', align: 'left', value: 'uploadStatus', sortable: false },
                { text: 'Action', align: 'left', value: 'actionEnabled', sortable: false },
            ],
            uploadList: [],
            tableFilteredData: [],
            totalRecords: 0,
            currentPage: 1,
            pageSize: 10,
            currentShowingRecordsStartIndex: 0,
            totalPages: 0,
            excelHeaders: [
                "Type of Vendor*",
                "PAN Code*",
                "TDS Rate With PAN*",
                "TDS Rate Without/invalid PAN*",
            ],
            /**
             * This data property holds file for us.
             */
            excel: [],
            selectedFile: [],
        }
    },
    computed: {
        lastRecordLength () {
            if (this.tableFilteredData.length < 10) {
                return this.currentShowingRecordsStartIndex + this.tableFilteredData.length;
            }
            return this.currentShowingRecordsStartIndex + this.pageSize;
        },
        hasEditAccess () {
            const routeName = this?.$router?.history?.current?.name;

            return this?.$store?.state?.screens_obj?.[routeName]?.isEdit;
        }
    },
    methods: {
        uploadStatusTextColor(status = '') {
            return {
                'Error': 'mandatorycolor',
            }[status] || '';
        },
        formattedDate(date) {
            return moment(new Date(date)).format('DD/MM/YYYY hh:mm a');
        },
        switchToBaseRateTdsUpload() {
            this.$router.push('/tds-master/base-tds-master');
        },
        polyFillProperties(existingData) {
            const polyFillProps = {}

            return existingData.map(e => ({
                ...polyFillProps,
                ...e,
            }));
        },
        filterTableData () {
            const filteredData = [ ...this.uploadList];
            this.tableFilteredData = this.polyFillProperties(filteredData);
        },
        downLoadExcelTemplateFile() {
            /**
             * Headers of the Template excel file
             * extracting from excelHeaders from data property
             */
            const tempData = [this.excelHeaders.reduce((a, v) => ( { ...a, [v]: "" }), {})];

            /**
             * Creating Excel here,
             * file will download after creation.
             */
            this.JSONToEXcel(tempData, "Base_Rate_TDS_Sample_File", "Sheet");
        },
        uploadExcel () {
            document.getElementById("file-upload").click();
        },
        readFile (file) {
            return new Promise((resolve) => {
                this.readImageM(
                file.name,
                file,
                (res) => {
                    const { base, fileName } = res;

                    resolve({
                        fileName,
                        fileBase: base.split(',')[1],
                    });
                },
            );
            });
        },
        async processFile () {
            if (!this.excel.size) return;

            try {
                /**
                 * Starting loader.
                 */
                this.showProgress();

                /**
                 * Validating Excel headers & excel rows here
                 * if file is not ok, do not process further.
                 */
                const validationMessages = await this.validateHeadersWithFieldValidation();
                if (validationMessages.length) {
                    /**
                     * Taking only unique validation messages and making a string,
                     * by adding a new line for every unique validation messsage.
                     */
                    const messages = Array.from(new Set(validationMessages));
                    // Showing message in dialog.

                    this.$refs.popup.showAlert(messages);

                    /**
                     * Clear the input.
                     */
                    this.excel = {};

                    return this.hideProgress();
                }

                /**
                 * Validating header sequence here
                 */
                const ifHeadersInSequence = await this.validateHeadersSequence();
                if (!ifHeadersInSequence) {

                    // Showing message in dialog.
                    this.$refs.popup.showAlert(['Please enter headers in correct sequence.']);

                    /**
                     * Clear the input.
                     */
                    this.excel = {};

                    return this.hideProgress();
                }

                /**
                 * Reading File and converting that to into base64 data,
                 * which will be sent via API.
                 */
                const file = await this.readFile(this.excel);

                // Payload
                const formData = {
                    masterType: 'baseTds',
                    file,
                };

                this.POSTM(
                    'BULK_UPLOAD_TDS_RATE',
                    formData,
                    (res) => {
                        this.showToast(
                            res?.data?.msg || "File Uploaded Successfully",
                            this.TOST().SUCCESS
                        );
                        this.fetchFiles();
                    },
                    (error) => {
                        const messages = error?.response?.data?.message?.errors || ['Something went wrong, Please try again'];
                        this.$refs.popup.showAlert(messages);
                    },
                    "CM"
                );

                this.excel = {};
                // this.$refs.fileInput.value = '';

                this.hideProgress();
            } catch (error) {
                this.hideProgress();

                this.showToast(error.message || 'Something went wrong, Please try again.', this.TOST().ERROR);
            }
        },
        validateExcelData (resolve) {
            return (data) => {
                resolve(this.validateBaseTDSBulkExcelData(data, this.excelHeaders));
            }
        },
        validateHeadersWithFieldValidation () {
            return new Promise((resolve, reject) => {
                this.excelToJSON(
                    this.excel,
                    this.validateExcelData(resolve),
                    (error) => reject({ ...error, message: 'Please upload correct excel file.' }),
                )
            });
        },
        async validateHeadersSequence () {
            const fileHeaders = await this.validateExcelHeadersSequence(this.excel);

            if (JSON.stringify(fileHeaders) !== JSON.stringify(this.excelHeaders)) {
                return false;
            }

            return true;
        },
        downloadReport(item) {
            if (item?.checkerDocument?.[0]?.documentId && item.fileName) {
                const url = this.getURL("GET_DOCUMENT_BY_ID") + '?documentId=' + item?.checkerDocument?.[0]?.documentId;
                this.downloadFileByURL(url, item.fileName);
            }
        },
        downloadErrorReport (item) {
            if (item?.errorDocument?.[0]?.documentId && item.fileName) {
                const url = this.getURL("GET_DOCUMENT_BY_ID") + '?documentId=' + item?.errorDocument?.[0]?.documentId;
                this.downloadFileByURL(url, item.fileName);
            }
        },
        getQueryParams () {
            const queryParamsObject = {
                pageNumber: this.currentPage,
                pageSize: this.pageSize,
                masterType: 'baseTds',
            };

            const params = new URLSearchParams(queryParamsObject);

            return params.toString();
        },
        async fetchFiles() {
            /**
             * Callback method which will be called after API
             */
            const afterAPI = () => {
                this.filterTableData();
                this.hideProgress();
            }
            this.showProgress();
            /**
             * calling API here
             */
            this.GETM(
                "GET_BULK_UPLOAD_TDS_RATE",
                this.getQueryParams(),
                (res) => {
                    this.uploadList = res?.data?.data;

                    this.totalPages = res.data.totalPages;
                    this.totalRecords = res?.data?.totalCount || 0;
                    this.currentShowingRecordsStartIndex = this.currentPage == 1 ? 0 : (this.currentPage - 1) * this.pageSize;
                    afterAPI(res?.data?.data)
                },
                (error) => {
                    console.log(error);
                    afterAPI([]);
                },
                "CM"
            );
        }
    },
    created() {
        this.$store.commit("SAVE_PAGE_HEADER", "Base TDS Bulk Upload");
        this.fetchFiles();
        this.hideProgress();
    },
}
</script>

<style lang="scss" scoped>
.p-5 {
    padding: 1rem;
    position: relative;
}

.font-size-12 {
    font-size: 12px;
}

.tds-input {
    margin-bottom: 2px;
}

.my-5 {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.v-data-table__wrapper table thead th {
    color: black !important;
    font-size: 45px;
}

.btnToggle {
    text-transform: none;
    color: #fff;
    background-color: #1ebbd9;
}

.orange-background {
    background-color: rgb(228, 106, 37);
}
.orange-background:disabled {
    opacity: .7;
    color: white !important;
}

.iconsize {
    font-size: 16px !important;
    font-weight: bold;
}

.tablebtn {
    background: #ffffff 0% 0% no-repeat padding-box !important;
    box-shadow: inset 0px 2px 4px #00000052 !important;
    border: 0.5px solid #c1c8cc;
    border-radius: 18px !important;
    height: 30px !important;
    text-transform: capitalize !important;
    width: 93%;
    justify-content: space-between;
    color: black !important;
    font-weight: 400 !important;
}

.tableheader {
    padding: 0;
}

.tableicons {
    font-size: 18px !important;
    color: #23b1a9 !important;
}

.approvedTextColor {
    color: #23b1a9;
}


.status-select-box {
    font-size: 11px;
    margin-bottom: 2px;
    // padding: 10px;
    // height: 12px;
}

.vs__dropdown-menu {
    text-transform: capitalize;
}

.flex {
    display: flex;
    justify-content: center;
}

.justify-between {
    justify-content: space-between;
}

.align-center {
    align-items: center;
}

.no-wrap {
    white-space: nowrap;
}

.w-200 {
    width: 200px;
}

.w-150 {
    max-width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.more-text-icon {
    background-color: lightgray;
    color: white;
    border-radius: 2px;
    font-size: 18px;
    height: 18px;
}

.w-100 {
    width: 800px;
}

.pull-right {
    float: right;
}

.rotate-90 {
    transform: rotate(-90deg);
    font-size: 40px;
}

.theme--light.v-btn.v-btn--disabled .v-icon {
    color: white !important;
}

.filter-select-box {
    font-size: 12px;
}
</style>