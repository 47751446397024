<template>
  <v-app>
    <v-container fluid>
      <v-card color="#01BBD9" class="pa-2 pl-5" tile>
        <p
          class="text-uppercase font-weight-medium ma-0 pa-0"
          style="color: #ffffff; font-size: 13px"
        >
          assign to
        </p>
      </v-card>
      <v-card class="pa-2 pl-5" tile>
        <v-radio-group
          v-model="row"
          mandatory
          row
          dense
          hide-details
          class="ma-0 pa-0 py-3"
        >
          <div
            style="
              background-color: #f7f7f7;
              width: 263px;
              border: 1px solid #c1c8cc;
            "
            class="pa-2 pr-14"
          >
            <v-radio
              value = "External"
              color="#23b1a9"
              class="ma-0 pa-0 pl-1"
              @click="onChangeInvestigator(row)"
            >
              <template v-slot:label>
                <div class="font-weight-bold" style="font-size: 13px">
                  External Investigator
                </div>
              </template>
            </v-radio>
          </div>
          <div
            style="
              background-color: #f7f7f7;
              width: 263px;
              border: 1px solid #c1c8cc;
            "
            class="ml-2 pa-2"
          >
            <v-radio
              value = "Internal"
              color="#23b1a9"
              class="ma-0 pa-0 pl-1"
              @click="onChangeInvestigator(row)"
            >
              <template v-slot:label>
                <div class="font-weight-bold" style="font-size: 13px">
                  Internal Investigator
                </div>
              </template>
            </v-radio>
          </div>
        </v-radio-group>
      </v-card>
      <v-layout row>
        <v-flex md3 sm3 xs6>
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Assign to
            </v-text>
            <v-text-field
              v-model="employeeName"
              disabled
              label="Select"
              solo
              dense
            ></v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md6 sm6 xs6>
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Remarks
            </v-text>
            <v-textarea
              solo
              label="Enter..."
              v-model="remarks"
              rows="1"
              maxlength="500"
              counter="500"
              class="rounded-0"
              aria-required="true"
              dense
            >
            </v-textarea>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-container>
    <v-container fluid>
      <v-data-table
        :headers="headers"
        :items="items"
        v-model="selected"
        item-key="sno"
        class="padding-0 vDataTableStyling"
        :items-per-page="23"
      >
        <template #item="{ item }">
          <tr>
            <!-- <td></td> -->
            <td>{{ item.sno }}</td>
            <td>{{ item.name }}</td>
            <td class="text-xs-center">
              <v-checkbox
                :disabled="item.sno <= 5 || item.sno >= 19"
                class="pa-0 ma-0 align-center justify-center"
                style="width: 0"
                :ripple="false"
                v-model="selected"
                :value="item"
                hide-details
              ></v-checkbox>
            </td>
            <!-- <td>
              <span v-for="(dropData, index) in dropData" :key="index">{{
                dropData + (index == dropData.length ? "" : ", ")
              }}</span>
            </td> -->
          </tr>
        </template>
      </v-data-table>
      <v-divider style="margin-top: 30px"></v-divider>
    </v-container>
    <v-card tile style="max-height: 150px" class="ma-3 mt-4 rounded-0">
      <div
        class="mx-2 pa-2"
        style="
          background-color: #eee;
          display: flex;
          justify-content: space-between;
          margin-left: 0px !important;
          margin-right: 0px !important;
        "
      >
        <v-layout style="flex: 1" justify-end>
          <!-- <v-btn
            dark
            tile
            color="#E46A25"
            class="text-capitalize px-3 mx-2 ml-4 my-2"
            small
            @click="uploadDocuments()"
            ><v-icon dark small>mdi-arrow-up-bold-box-outline</v-icon>
            <v-divider vertical class="mx-2"></v-divider> Upload Documents
          </v-btn> -->

          <!-- <v-btn
            dark
            tile
            color="#E46A25"
            class="text-capitalize px-3 mx-2 ml-4 my-2"
            small
            ><v-icon dark small>mdi-arrow-left</v-icon>
            <v-divider vertical class="mx-2"></v-divider> Send Back
          </v-btn> -->
          <v-btn
            dark
            tile
            color="#23B1A9"
            class="text-capitalize px-3 mx-2 my-2"
            small
            @click="submit()"
            ><v-icon dark small>arrow_forward</v-icon>
            <v-divider vertical class="mx-2"></v-divider>Appoint
          </v-btn>

          <!-- <v-btn
            tile
            color="#E46A25"
            class="text-capitalize px-3 mx-2 my-2"
            :dark="!btnWaveEnable"
            :disabled="btnWaveEnable"
            small
            @click="clickWaveOff"
            ><v-icon dark small>check_circle</v-icon>
            <v-divider vertical class="mx-2"></v-divider>Waive Off
          </v-btn> -->
        </v-layout>
      </div>
    </v-card>
    <v-dialog
      v-model="showPopup"
      transition="dialog-bottom-transition"
      max-width="1300"
      style="justify-content: center"
    >
      <template v-slot:default="dialog">
        <v-card>
          <v-card-text class="text-center">
            <v-row>
              <v-col cols="2">
                <v-text-field
                  v-model="searchByName"
                  label="Search by name"
                >
                </v-text-field>
              </v-col>
              <v-col cols="2">
                <v-text-field
                  v-model="searchByState"
                  label="Search by state"
                >
                </v-text-field>
              </v-col>
              <v-col cols="2">
                <v-text-field
                  v-model="searchByCity"
                  label="Search by city"
                >
                </v-text-field>
              </v-col>
              <v-col cols="2" class="align-self-center">
                <v-btn
                  dark
                  class="text-capitalize"
                  color="#E46A25"
                  depressed
                  @click="handleSearch"
                >
                  <v-icon>mdi-magnify</v-icon>Search
              </v-btn>
              </v-col>
            </v-row>
            <v-row class="mt-3">
              <v-flex xs12>
                <v-simple-table class="mt-4">
                  <template v-slot:default>
                    <thead style="background-color: rgb(1, 187, 217)">
                      <tr>
                        <th class="text-left mt-2" style="color: #fff">SrNo</th>
                        <th class="text-left mt-2" style="color: #fff">
                          User ID
                        </th>
                        <th class="text-left mt-2" style="color: #fff">Name</th>
                        <th class="text-left mt-2" style="color: #fff">
                          PAN Number
                        </th>
                        <th class="text-left mt-2" style="color: #fff">
                          Login User ID
                        </th>
                        <th class="text-left mt-2" style="color: #fff">
                          State
                        </th>
                        <th class="text-left mt-2" style="color: #fff">City</th>
                        <th class="text-left mt-2" style="color: #fff">
                          District
                        </th>
                        <th class="text-left mt-2" style="color: #fff">
                          Mobile Number
                        </th>
                        <th class="text-left mt-2" style="color: #fff">
                          Email ID
                        </th>
                        <th class="text-left mt-2" style="color: #fff">
                          Service Provider Code
                        </th>
                        <th class="text-left mt-2" style="color: #fff">
                          Line Of Business
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(item, index) in investigatorList"
                        :key="index"
                        @click="
                          onClickPopupGrid(item);
                          dialog.value = false;
                        "
                        style="cursor: pointer"
                      >
                        <td>
                          <p>{{ index + 1 }}</p>
                        </td>
                        <td>
                          {{ item.serviceProviderDetails.userId }}
                        </td>
                        <td>
                          {{ item.serviceProviderDetails.employeeName }}
                        </td>
                        <td>
                          {{ item.panNumber }}
                        </td>
                        <td>
                          {{ item.serviceProviderDetails.ntid }}
                        </td>
                        <td>
                          {{ item.spAddress[0].spState }}
                        </td>
                        <td>
                          {{ item.spAddress[0].spCity }}
                        </td>
                        <td>
                          {{ item.spAddress[0].spDistrict }}
                        </td>
                        <td>
                          {{ item.mobileNumber[0].number }}
                        </td>
                        <td>
                          {{ item.emailID[0] }}
                        </td>
                        <td>
                          {{ item.serviceProviderId }}
                        </td>
                        <td>
                          {{ item.lob[0] }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-flex>
            </v-row>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn
              small
              dark
              tile
              color="rgb(228, 106, 37)"
              @click="dialog.value = false"
              >Close</v-btn
            >
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
    <v-dialog
      v-model="uploadMode"
      transition="dialog-bottom-transition"
      max-width="600"
      style="justify-content: center"
    >
      <template v-slot:default="dialog">
        <v-card>
          <v-card-text class="text-center">
            <v-row>
              <v-flex
                xs12
                style="border-bottom:2px solid #f3ecec;margin-bottom: 10px;padding-bottom: 8px: font-weight: 600"
              >
                <span>Upload Documents</span>
              </v-flex>
            </v-row>
            <v-row>
              <v-flex
                xs2
                class="tm-fileContainer-bulk pt-2"
                style="
                  box-shadow: 0px 1px 1px #00000051;
                  background-color: rgb(228, 106, 37);
                  cursor: pointer;
                "
              >
                <span class="tm-upload-txt"> Choose File</span>
                <form enctype="multipart/form-data">
                  <input
                    type="file"
                    ref="fileref"
                    id="fileUpload"
                    multiple
                    @change="uploadFile"
                  />
                </form>

                <form action="" enctype="multipart/form-data">
                  <input
                    type="file"
                    ref="fileref"
                    id="fileUpload"
                    multiple
                    @change="uploadFile"
                  />
                </form>
              </v-flex>
              <!-- <v-flex xs2>
												<span style="color: black; font-weight: 600">{{ currentFileName }}</span>
											</v-flex> -->
            </v-row>
            <!-- <div v-if="showDocTable"> -->
            <div>
              <v-row style="text-align: center" class="mt-3">
                <h4>Uploaded Files</h4>
              </v-row>
              <v-row class="mt-3">
                <v-flex xs12>
                  <v-simple-table class="mt-4" :key="upKey">
                    <template v-slot:default>
                      <thead style="background-color: rgb(1, 187, 217)">
                        <tr>
                          <th class="text-left mt-2" style="color: #fff">
                            SrNo
                          </th>
                          <th class="text-left mt-2" style="color: #fff">
                            Document
                          </th>
                          <th class="text-left mt-2" style="color: #fff">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(doc, index) in dropData" :key="index">
                          <td>
                            <p>{{ index + 1 }}</p>
                          </td>
                          <td>
                            <a :href="doc" target="blank">{{ doc }}</a>
                          </td>
                          <td>
                            <v-btn
                              dark
                              tile
                              color="#D1121B"
                              class="text-capitalize"
                              small
                              @click="deleteDoc(index)"
                            >
                              <v-icon dark small>delete</v-icon>
                            </v-btn>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-flex>
              </v-row>
            </div>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn
              small
              dark
              tile
              color="rgb(228, 106, 37)"
              @click="dialog.value = false"
              >Close</v-btn
            >
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </v-app>
</template>

<script>
import {
  getInvestigatorsList,
  assignInvestigationAppointment,
} from "../apiservices/InvestigationAppointment";
export default {
  beforeCreate() {
    this.row = "";
  },
  data() {
    return {
      investigatorType: "",
      isExternalServiceProvider: "",
      remarks: "",
      searchByName: "",
      searchByState: "",
      searchByCity: "",
      investigatorDetails: {},
      ntId: "",
      gstinNumber: "",
      serviceProviderId: "",
      showPopup: false,
      uploadMode: false,
      dropData: ["Investigator 1", "Stark", "Steve", "Banner"],
      investigatorList: [],
      row: "",
      headers: [
        {
          text: "S. No.",
          value: "sno",
          class: "textClass bgColor",
          divider: true,
          align: "center",
          sortable: false,
        },
        {
          text: "SIU investigation checklist",
          value: "name",
          class: "textClass bgColor",
          divider: true,
          align: "center",
          sortable: false,
        },
        {
          text: "Select",
          value: "select",
          class: "textClass bgColor",
          divider: true,
          align: "center",
          sortable: false,
        },
        // {
        //   text: "Assigned to",
        //   value: "assigned",
        //   class: "textClass bgColor",
        //   divider: true,
        //   align: "center",
        // },
      ],
      items: [
        { sno: "1", name: "Basic Details", value: "basicDeatils" },
        {
          sno: "2",
          name: "Policy / Insured Details",
          value: "policyInsuredDetails",
        },
        {
          sno: "3",
          name: "Premium Payment / Payer Details",
          value: "premimunPaymentOrPayerDetails",
        },
        { sno: "4", name: "Hospital Details", value: "hospitalDetails" },
        {
          sno: "5",
          name: "Hospitalization / Accident Details",
          value: "hospitalizationAndAccidentDetails",
        },
        {
          sno: "6",
          name: "Member Verification Details",
          value: "memberVerificationDetail",
        },
        {
          sno: "7",
          name: "External Pathology Lab Details",
          value: "externalPathoLogyLabDetails",
        },
        {
          sno: "8",
          name: "External Radiology Lab Detials",
          value: "externalRadiologyLabDetails",
        },
        {
          sno: "9",
          name: "External Sonology Lab Details",
          value: "externalSonologyLabDetails",
        },
        {
          sno: "10",
          name: "External Pharmacy Details",
          value: "externalPharmacyDetails",
        },
        {
          sno: "11",
          name: "Employer Verification Details",
          value: "employerVerificationDetails",
        },
        {
          sno: "12",
          name: "Neighbour / Sarpanch / Vicinity / Verification Details",
          value: "neighbourOrSarpanchOrVicinityVerificationDetails",
        },
        {
          sno: "13",
          name: "Spot Verification Details",
          value: "spotVerificationDetails",
        },
        {
          sno: "14",
          name: "FIR / GDR Verification Details",
          value: "fIROrGDRVerificationDetails",
        },
        {
          sno: "15",
          name: "Accdent Vehicle Involved Details",
          value: "accidentVehicleInvolvedDetails",
        },
        // { sno: "16", name: "Insured Travel Verification Details", value: "" },
        {
          sno: "17",
          name: "Document Verification Details",
          value: "documentVerificationDetails",
        },
        {
          sno: "18",
          name: "Forensic Report Required",
          value: "forensicReportRequired",
        },
        { sno: "19", name: "Final Observation of the Investigator", value: "finalObservationOfInvestigator" },
        {
          sno: "20",
          name: "Investigator Documents",
          value: "viewUploadDocuments",
        },
        { sno: "21", name: "Invoice Details", value: "invoiceDetails" },
        { sno: "22", name: "General Details", value: "generalTab" },
        // { sno: "23", name: "SIU Notes", value: "siuNotes" },
      ],
      singleSelect: false,
      selected: [
        { sno: "1", name: "Basic Details", value: "basicDeatils" },
        {
          sno: "2",
          name: "Policy / Insured Details",
          value: "policyInsuredDetails",
        },
        {
          sno: "3",
          name: "Premium Payment / Payer Details",
          value: "premimunPaymentOrPayerDetails",
        },
        { sno: "4", name: "Hospital Details", value: "hospitalDetails" },
        {
          sno: "5",
          name: "Hospitalization / Accident Details",
          value: "hospitalizationAndAccidentDetails",
        },
        { sno: "19", name: "Final Observation of the Investigator", value: "finalObservationOfInvestigator" },
        {
          sno: "20",
          name: "Investigator Documents",
          value: "viewUploadDocuments",
        },
        { sno: "21", name: "Invoice Details", value: "invoiceDetails" },
        { sno: "22", name: "General Details", value: "generalTab" },
        // { sno: "23", name: "SIU Notes", value: "siuNotes" },
      ],
    };
  },
  methods: {
    clickWaveOff() {
      this.showConfirm(
        "Confirmation",
        "Are you sure you want to Waive Off?",
        "Yes",
        "No",
        (Yes) => {},
        (No) => {}
      );
    },
    async onChangeInvestigator(row) {
      this.investigatorType = row;
      this.showPopup = true;
      this.getServiceProviders(row, '', '', '', '');
    },
    async getServiceProviders(row, name, state, city) {
      try {
        let res = await getInvestigatorsList(row, name, state, city);
        this.investigatorList = res.data.data.data;
      } catch (e) {
        this.showToast(e.response?.data?.msg || e.response?.data  || e.response || e, this.TOST().ERROR);
      }
    },
    handleSearch() {
      this.getServiceProviders(this.investigatorType, this.searchByName, this.searchByState, this.searchByCity);
    },
    onClickPopupGrid(item) {
      this.ntId = item.serviceProviderDetails.ntid;
      this.gstinNumber = item.gstinNumber;
      this.serviceProviderId = item._id;
      this.employeeName = item.serviceProviderDetails.employeeName;
      this.investigatorDetails.ntidUserId = this.ntId;
      this.investigatorDetails.email = item.emailID[0];
      this.investigatorDetails.name = this.employeeName;
      this.investigatorDetails.mobile = item.mobileNumber[0].number;
      this.investigatorDetails.investigatorType = this.investigatorType;
      this.investigatorDetails.id = this.serviceProviderId;
      this.investigatorDetails.gstNumber = this.gstinNumber;
      this.isExternalServiceProvider = item.isExternalServiceProvider;
    },
    uploadFile(e) {
      console.log("upload file", e.target.files, e.target.files);
      var formData = new FormData();
      let mediaFile = e.target.files[0];
      formData.append("files", mediaFile);
      formData.append("claimNumber", this.pageData.claimNumber);
      formData.append("documentType", this.globalDocType);
    },
    uploadDocuments() {
      this.uploadMode = true;
    },
    async submit() {
      if (!this.ntId) {
        this.showToast("Select investigator first", this.TOST().WARNING);
        return;
      }
      if(!this.remarks) {
        this.showToast("Enter remarks", this.TOST().WARNING);
        return;
      }
      this.showConfirm(
        "Confirmation",
        "Are you sure you want to Submit?",
        "Yes",
        "No",
        (Yes) => {
          this.submitData()
        },
        (No) => { });
    },
    async submitData(){
      let obj = {};
          this.selected.forEach((el) => {
            obj[el.value] = { isTabSelected: true };
          });
          obj = {
            ...obj,
            ...{ ntidUserId: this.ntId },
            ...{ lob: "Health" },
            ...{ policyNumber: this.$route.params.policyNumber },
            ...{ siuWorkItemId: this.$route.params.siuWorkItemId },
            ...{ remarks: this.remarks },
            ...{ claimId: this.$route.params.claimNumber+this.$route.params.featureNumber },
            ...{ serviceProviderId: this.serviceProviderId },
            ...{ claimObjectId: this.$route.params.claimId },
            ...{ siuUserDetails: { investigatorDetails: this.investigatorDetails } },
          };
          if(this.isExternalServiceProvider != 'Yes') {
            obj['invoiceDetails'].isTabSelected = false;
          }
          try {
            let res = await assignInvestigationAppointment(obj);
            this.showToast(res.data.msg, this.TOST().SUCCESS);
            this.$router.push('investigation-workitem');
          } catch (e) {
            console.log(e);
            this.showToast(e.response?.data?.msg || e.response?.data  || e.response || e, this.TOST().ERROR);
          }
    },
  },
  created() {
    //console.log(this.$route.params.assignmentDone); // To identify if the assignment has been completed
    this.$store.commit("SAVE_PAGE_HEADER", "Investigation Appointment");
  },
};
</script>

<style scoped>
.v-input__slot {
  align-items: center;
  justify-content: center;
}
.textClass {
  color: white !important;
  font-weight: bold;
  font-size: 12px !important;
}
.bgColor {
  background-color: #01bbd9;
}
</style>
