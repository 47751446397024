// Sever IP
export const USER_URL = 'https://sdtatadevnode.iorta.in/secure/user/';
export const ADMIN_URL = 'http://ac7adbd4e2807434baa6058d452fcd92-1852449965.ap-south-1.elb.amazonaws.com/product-config/product-config-service/api/';
//export const ADMIN_URL = 'http://claimsdrive-health.salesdrive.app/product-config/product-config-service/api/';
// export const ADMIN_URL = 'http://a7c2a33ad24324255846f7168e5c10b8-b4ecd606e2b28c12.elb.ap-south-1.amazonaws.com:3001/product-config-service/api/';

//export const PROVIDER_URL = 'http://claimsdrive-health.salesdrive.app/provider-management/provider-management-service/api/';
export const PROVIDER_URL = 'http://ac7adbd4e2807434baa6058d452fcd92-1852449965.ap-south-1.elb.amazonaws.com/provider-management/provider-management-service/api/'

export const master = 'https://claimsdrive.salesdrive.app/common-system-service/'
//export const master = 'http://claimsdrive-health.salesdrive.app/master-data/master-data-service/api/'

// export const empanelment = 'http://claimsdrive-health.salesdrive.app/provider-management/provider-management-service/api/'

export const TASK_MGMNT = 'http://ac7adbd4e2807434baa6058d452fcd92-1852449965.ap-south-1.elb.amazonaws.com/task-management/task-management-service/api/'
//export const TASK_MGMNT = 'http://claimsdrive-health.salesdrive.app/task-management/task-management-service/api/'


export const USER_MGMNT_SERVICE = "https://claimsdrive.salesdrive.app/common-system-service/"
// 'http://claimsdrive-health.salesdrive.app/user-management/user-management-service/api/'

export const BANK_URL = 'https://ifsc.razorpay.com/'
export const BUKLIST_URL = 'http://localhost:3000/bulklist'

export const SOCKET_URL = 'https://sdtatadevnode.iorta.in';

export const CAPTCHA_ID = '6LeLq9sUAAAAAGDFU-HcNR5QH7vuxouh-FVHNc6M'
// IMAGE URL
export const SALESDRIVE_URL = 'http://salesdriveapp.iorta.in/img/'

export const node_img_url = 'https://nodesalesdrivedemo.iorta.in/ ' // Node Base URL  => port with ip
export const Image_URL = 'https://sdtatadevnode.iorta.in/'

// Network  url
//export const NETWORK_URL = 'http://ab6e074e902674466b4d5a40e0c8c063-1268690213.ap-south-1.elb.amazonaws.com/network-management/network-management-service/api/';
// Network  url
export const NETWORK_URL = 'http://ac7adbd4e2807434baa6058d452fcd92-1852449965.ap-south-1.elb.amazonaws.com/network-management/network-management-service/api/';

//User mngmnt URL
export const USER_MGMNT_URL = "https://claimsdrive.salesdrive.app/common-system-service/"//
//export const USER_MGMNT_SERVICE = 'http://ac7adbd4e2807434baa6058d452fcd92-1852449965.ap-south-1.elb.amazonaws.com/user-management/user-management-service/api/'
//export const USER_MGMNT_SERVICE = 'http://ac7adbd4e2807434baa6058d452fcd92-1852449965.ap-south-1.elb.amazonaws.com/user-management/user-management-service/api/'
// 'http://claimsdrive-health.salesdrive.app/user-management/user-management-service/api/'

//export const CLAIMS_MANAGEMENT = 'http://claimsdrive-health.salesdrive.app/claim-management/claim-management-service/api/'
export const CLAIMS_MANAGEMENT = 'http://ac7adbd4e2807434baa6058d452fcd92-1852449965.ap-south-1.elb.amazonaws.com/claim-management/claim-management-service/api/'


// Cliam Intimation 

//export const CLAIMS_INTIMATION = 'http://claimsdrive-health.salesdrive.app/claims-registration/claims-registration-service/api/';
export const CLAIMS_INTIMATION = 'http://ac7adbd4e2807434baa6058d452fcd92-1852449965.ap-south-1.elb.amazonaws.com/claims-registration/claims-registration-service/api/';




let BaseURl = "https://claimsdrive.salesdrive.app/"; //Dev-"http://a62384b38e060475d89f9a0d7cf3fa37-163558373.ap-south-1.elb.amazonaws.com/"
let claimUrl = BaseURl + "claims-management/"//Dev- "http://a62384b38e060475d89f9a0d7cf3fa37-163558373.ap-south-1.elb.amazonaws.com/claims-management/claims-management-service/api/"
let masterURl = BaseURl + "master-data/"//Dev- "http://a62384b38e060475d89f9a0d7cf3fa37-163558373.ap-south-1.elb.amazonaws.com/master-data/master-data-service/api/"
let auditTrailUrl = BaseURl + "common-audit-logs/"
let commonModulebasURL = BaseURl + "common-system-service/"
let marineBaseUrl = "https://claimsdrive-commercial.salesdrive.app/";
let marineMasterURl = marineBaseUrl + "master-data/";
let commonModuleMasterUrl = BaseURl + "common-masterdata/";
//Login 
export const LOGIN = "https://claimsdrive.salesdrive.app/common-system-service/users/login-with-ad"
// "https://claimsdrive-commercial.salesdrive.app/common-system-service/" + "user-management/login";//"user-management/user-management-service/api/users/login";

// OFR_CFR_SERVICE AA
export const OFR_CFR_SERVICE = 'https://claimsdrive-health.salesdrive.app/ofrcfr/'
export const WE_CARE_BASE_URL = 'https://claimsdrive-health.salesdrive.app'
//Catastraphe Master
export const CREATE_CATASROPHE = BaseURl + "master-data/catastrophe/create";//"master-data/master-data-service/api/catastrophe/create";
export const UPDATE_CATASROPHE = BaseURl + "master-data/catastrophe/update/";
export const GET_CATASROPHE_DETAILS = BaseURl + "master-data/catastrophe/single-detail/";
export const SEARCH_CATASROPHE_DETAILS = BaseURl + "master-data/catastrophe/search?";
//landing Page
export const WORKITEM_SEARCH = BaseURl + "task-management/work-items/search"//?perPage=10&pageNo=1
export const WORK_ITEM_SEARCH = BaseURl + "task-management/work-items/search?";

//WorkItem Assignment
export const WORKITEM_ASSIGNMENT = BaseURl + "task-management/work-items/assign";

//AIG Office Master
export const GET_OFFICE_DETAILS = BaseURl + "master-data/aig/single-detail";
export const SEARCH_AIG_OFFICE = BaseURl + "master-data/aig/search";//?perPage=10&pageNo=1
export const CREATE_AIG_OFFICE = BaseURl + "master-data/aig/create";
export const UPDATE_OFFICE_DETAILS = BaseURl + "master-data/aig/update/";
export const COUNTRY_LIST = BaseURl + "master-data/country/get-country-all";



// FNOL page
export const Create_Fnol = claimUrl + "claim/submit";// on submit
export const save_Fnol = claimUrl + "claim/save";// on save
export const FETCH_CLAIM_DETAILS = claimUrl + "claim/get-claim";
export const CHECKFIELDRULES = claimUrl + "claim/field-rules-check"
export const duplicateClaimCheck = claimUrl + "claim/duplicate-check"

//FNOL Feature Claim
export const saveFeature_Fnol = claimUrl + "claim/save-feature-claim";
export const CreateFeature_Fnol = claimUrl + "claim/submit-feature-claim";
export const getAllFeature_Fnol = claimUrl + "claim/all-feature-claims";
export const getAllClaimNumbers_Fnol = claimUrl + "claim/all-main-claim-numbers";
export const FETCH_FEATURECLAIM_DETAILS = claimUrl + "claim/get-feature-claim";



//service claim submit
export const submitServiceClaim_Fnol = claimUrl + "claim/submit-service-claim";


export const getLossDescription = masterURl + "loss-description/all-loss-description";
export const getAllCatastrophe = masterURl + "catastrophe/search";//get all Catastrophe details 
export const getAIGDetails = masterURl + "aig/search";//aig details
export const getCoutries = commonModuleMasterUrl + "country/get-country-all";//country names
export const getPincodes = commonModuleMasterUrl + "pincode/get-pincode-all";// 
export const getStates = commonModuleMasterUrl + "state/get-state-all";// 
export const getCities = commonModuleMasterUrl + "city/get-city-all";// 
export const getCoverage = masterURl + "marine-cover/search";

// Policy Service
export const GET_POLICYDETAILS = BaseURl + "claims-management/claim/get-policy-details";
export const GET_POLICYSEARCH = BaseURl + "claims-management/claim/policy-search";

//Transaction History
export const GetTransactionHistory = claimUrl + "transaction/transaction-history";

//Audit Trail 
export const assignReassignAudit = auditTrailUrl + "claim-assign-reassign-log/search";
export const GET_GENERAL_AUDITTRAIL = BaseURl + "common-audit-logs/general-log/search";
export const GET_STATUS_AUDITTRAIL = BaseURl + "common-audit-logs/claim-status-log/search";
//client Master 
export const createMCM = masterURl + "major-client/create";
export const searchMCM = masterURl + "major-client/search";
export const fetchMCM = masterURl + "major-client/single-detail";
export const updateMCM = masterURl + "major-client/update/";
export const deleteMCM = masterURl + "major-client/single-delete";
export const searchSettlingOffice = BaseURl + "common-system-service/settlementoffice/all-settlement-office?system=Marine&searchCriteria=settlementOfficeName&"

// export const searchExaminers = BaseURl +
//mark User Offline
export const CREATE_UPDATE_USER_OFFLINE = BaseURl + "user-management/mark-offline/create-mark-offline";
export const SEARCH_USEROFFLINE = BaseURl + "user-management/mark-offline/search";

//settlement-office and Zone API's

export const GET_SEARCH_SETTLEMNET_ZONE = commonModulebasURL + "settlementoffice/search-settlement-office";
export const GET_ZONE_LIST = commonModulebasURL + "settlementoffice/get-zone-list";
export const GET_SETTLEMNET_OFFICELIST = commonModulebasURL + "settlementoffice/all-settlement-office";
export const GET_ALLEXAMIERLIST = commonModulebasURL + "users/all-users?system=Marine";
export const GET_SEARCH_COMMON = commonModulebasURL + "settlementoffice/search-settlement-office";


export const claims_env = ["Health", "Marine", "PA", "AIGC", "EW"]

export const COGNITOLOGIN = 'https://claimsdrive.salesdrive.app/common-system-service/'

// let MarineUrl            =    "https://claimsdrive-commercial.salesdrive.app/finance/transaction/"
let MarineUrl = "https://claimsdrive-commercial.salesdrive.app/finance/transaction/";//WC-4670
let HealthUrl = "https://claimsdrive.salesdrive.app/common-payment/"
let EWUrl = "https://claimsdrive-ew.salesdrive.app/finance/"

export const MARINE_FAILEDTRANSACTIONS = MarineUrl + "download-excel-sheet"

export const HEALTH_FAILEDTRANSACTIONS = HealthUrl + "payment/failure-report"

export const EW_FAILEDTRANSACTIONS = EWUrl + "download-excel-sheet"

export const MARINE_RESETCOUNT = MarineUrl + "reset-count"

export const EW_RESETCOUNT = EWUrl + "transaction/reset-count"

export const HEALTH_RESETCOUNT = HealthUrl + "payment/reset-count"

export const NEW_CAPTCHA_LINK = 'https://claimsdrive.salesdrive.app/common-system-service/' + "session/get-svg-captcha";
export const VERIFY_CAPTCHA_LINK = 'https://claimsdrive.salesdrive.app/common-system-service/' + "session/verify-svg-captcha";

export const OFR_CFR_Master = 'https://claimsdrive-health.salesdrive.app/master-data/'

export let commomAuxilaryBaseURL = "https://claimsdrive.salesdrive.app/common-auxiliary/"
let commonModuleBaseURL = "https://claimsdrive.salesdrive.app/";
let claimsModuleBaseUrl = "https://claimsdrive-health.salesdrive.app/"
let commonPayement = commonModuleBaseURL + "common-payment/payment/";
let commonsurround = commonModuleBaseURL + "common-surround-integration/";
export const COMMON_SURROUND_INTEGRATION = commonsurround;
export const commonModuleBaseUrl = commonModuleBaseURL;
export const claimsModuleBaseURL = claimsModuleBaseUrl;
export const GET_GC_BANK = commonPayement + "get-bank-details";
export const GET_DOCUMENT_BY_ID = commonModuleBaseURL + 'common-system-service/documents/download-document';
//ofr
export const ofrSearch = commomAuxilaryBaseURL + "ofr/search"
export const updateOfr = commomAuxilaryBaseURL + "ofr/update/"
export const ofrExport = commomAuxilaryBaseURL + "ofr/export-ofr"
export const POST_REASSIGN_EXAMINER_OFR = commomAuxilaryBaseURL + "ofr/reassignment"

//Cfr
export const cfrSearch = commomAuxilaryBaseURL + "cfr/search"
export const updateCfr = commomAuxilaryBaseURL + "Cfr/update/"
export const cfrExport = commomAuxilaryBaseURL + "cfr/export-cfr"
export const POST_REASSIGN_EXAMINER_CFR = commomAuxilaryBaseURL + "cfr/reassignment"
// Litigation
export const getLitigation = commomAuxilaryBaseURL + 'litigation/get-litigation'
export const searchLitigation = commomAuxilaryBaseURL + 'litigation/search-litigation'
export const createLitigation = commomAuxilaryBaseURL + 'litigation/create-litigation'
export const updateLitigation = commomAuxilaryBaseURL + 'litigation/update-litigation/'
export const getLOB = USER_MGMNT_URL + 'line-of-business/get-line-of-business-list';
export const searchCourt = commonModuleMasterUrl + 'court/search-court';
export const getTataAigAttorneyList = commomAuxilaryBaseURL + 'litigation/attorney-names';
//CFR/OFR Count
export const ofrCount = commomAuxilaryBaseURL + "ofr/counts"
export const cfrCount = commomAuxilaryBaseURL + "cfr/counts"

export const GET_BIT_ARRAY = commonModulebasURL + 'users/parse-bitarray'

//Frequent payee
export const searchFrequent = commonModuleMasterUrl + "frequentPayee/searchFrequentPayee"
export const createFrequent = commonModuleMasterUrl + "frequentPayee/createFrequentPayee"
export const updateFrequent = commonModuleMasterUrl + "frequentPayee/updateFrequentPayee/"
export const getFrequent = commonModuleMasterUrl + "frequentPayee/single-detail/"

//Frequent payee Audits
export const getAudits = commonModuleBaseURL + "common-audits/masterAudit/search"

// View Document
export const VIEW_DOCUMENT =
  commonModuleBaseURL + "common-system-service/documents/download-document";
export const GET_OCR_FORMAT = commonsurround + "ocr/get-data";

//recovery
export const getRecoverys = commomAuxilaryBaseURL + 'recovery/search-recovery'
export const createRecovery = commomAuxilaryBaseURL + 'recovery/create-recovery'
export const getHouseBankDetails = commomAuxilaryBaseURL + 'recovery/get-house-bank'
export const voidRecovery = commomAuxilaryBaseURL + 'recovery/void-recovery'
export const getSingleRecovery = commomAuxilaryBaseURL + 'recovery/get-one-recovery'
export const getReceiptHistory = commomAuxilaryBaseURL + 'recovery/get-receipt-history'
export const searchServiceProvider = commonModuleMasterUrl + 'serviceProvider/get-all-serviceProvider'
export const searchSalvageBuyer = commonModuleMasterUrl + 'salvageBuyer/search-salvageBuyer'
export const GET_GST_NUMBER = commonModuleBaseURL + "common-masterdata/tagicGstNumber/search"
export const GET_TAX_RATES = marineMasterURl + "gst/search"
export const GET_VENDORS = commonModuleMasterUrl + "vendor/search-vendor"
export const GET_HOME_TAX_RATES = commonModuleMasterUrl + "gst/search"
export const updateRecovery = commomAuxilaryBaseURL + 'recovery/update-recovery/'

//Frequency Approval  
export const searchFrequentApprove = commonModuleMasterUrl + "masterApproval/search"
export const getFrequentApprove = commonModuleMasterUrl + "masterApproval/single-detail"
export const updateMasterApprove = commonModuleMasterUrl + "masterApproval/approve-request/"

export const SESSION_REDIRECT = commonModuleBaseURL + 'common-system-service/session/redirect?lob='
export const GET_USER_BY_ID = commonModuleBaseURL + "common-system-service/users/get-by-ntid"
export const GET_USER_DATA = commonModuleBaseURL + "common-system-service/users/search-users";

//Court Master
export const GET_SEARCH_COURT = commonModuleMasterUrl + "court/search-court";
export const POST_CREATE_COURT = commonModuleMasterUrl + "court/create-court";
export const GET_COURT = commonModuleMasterUrl + "court/get-court";
export const PUT_UPDATE_COURT = commonModuleMasterUrl + "court/update-court/";

//Ground Of Defence Master
export const SEARCH_GROUND_OF_DEFENCE = commonModuleMasterUrl + "siu/search-ground-defence-data";
export const CREATE_GROUND_OF_DEFENCE = commonModuleMasterUrl + "siu/insert-ground-of-defence";
export const GET_GROUND_OF_DEFENCE = commonModuleMasterUrl + "siu/get-ground-defence-data";
export const GET_GROUND_OF_DEFENCE_BY_ID = commonModuleMasterUrl + "siu/get-ground-of-defence-data";
export const UPDATE_GROUND_OF_DEFENCE = commonModuleMasterUrl + "siu/update-ground-of-defence";
export const EXPORT_GROUND_OF_DEFENCE = commonModuleMasterUrl + "siu/export-ground-of-defense";

//QC2 Configurator Master
export const SEARCH_QC2_CONFIGURATOR = commonModuleMasterUrl + "siu/search-qc-two-data";
export const CREATE_QC2_CONFIGURATOR = commonModuleMasterUrl + "siu/insert-qctwo-master";
export const GET_QC2_CONFIGURATOR = commonModuleMasterUrl + "siu/get-qctwo-master-data";
export const GET_QC2_CONFIGURATOR_BY_ID = commonModuleMasterUrl + "siu/get-qctwo-master-data";
export const UPDATE_QC2_CONFIGURATOR = commonModuleMasterUrl + "siu/update-qctwo-master/";
export const EXPORT_QC2_CONFIGURATOR = commonModuleMasterUrl + "siu/export-qctwo-master";
export const GET_PRODUCT_CODE = claimsModuleBaseUrl + "master-data/gccoverage/get-gc-all-coverage-list"

//Police Station Master
export const SEARCH_POLICE_STATION = commonModuleMasterUrl + "policeStationMaster/search-police-station";
export const CREATE_POLICE_STATION = commonModuleMasterUrl + "policeStationMaster/insert-police-station";
export const GET_POLICE_STATION = commonModuleMasterUrl + "policeStationMaster/get-police-station-data";
export const GET_POLICE_STATION_BY_ID = commonModuleMasterUrl + "policeStationMaster/get-police-station-data";
export const UPDATE_POLICE_STATION = commonModuleMasterUrl + "policeStationMaster/update-police-station";
export const EXPORT_POLICE_STATION = commonModuleMasterUrl + "policeStationMaster/export-document-police-station-master";

//Document Checklist Master
export const GET_DOCUMENT_CHECKLIST = commonModuleMasterUrl + "siu/get-document-checklist-master-data";
export const GET_DOCUMENT_CHECKLIST_BY_ID = commonModuleMasterUrl + "siu/get-document-checklist-master-data";
export const CREATE_DOCUMENT_CHECKLIST = commonModuleMasterUrl + "siu/insert-document-checklist-master";
export const UPDATE_DOCUMENT_CHECKLIST = commonModuleMasterUrl + "siu/update-document-checklist-master";
export const SEARCH_DOCUMENT_CHECKLIST = commonModuleMasterUrl + "siu/search-document-checklist-data";
export const EXPORT_DOCUMENT_CHECKLIST = commonModuleMasterUrl + "siu/export-document-checklist-master";

//salvage buyer
export const SALVAGE_BUYER_SEARCH = commonModuleMasterUrl + "salvageBuyer/search-salvageBuyer"
export const SALVAGE_BUYER_CREATE = commonModuleMasterUrl + "salvageBuyer/create-salvageBuyer"
export const SALVAGE_BUYER_UPDATE = commonModuleMasterUrl + "salvageBuyer/update-salvageBuyer/"
export const SALVAGE_BUYER_GET = commonModuleMasterUrl + "salvageBuyer/get-salvageBuyer/"

//Service Provider 
export const SERVICE_PROVIDER_MASTER_SEARCH = commonModuleMasterUrl + "serviceProvider/get-all-serviceProvider"
export const SERVICE_PROVIDER_MASTER_CREATE = commonModuleMasterUrl + "serviceProvider/create-serviceProvider"
export const SERVICE_PROVIDER_MASTER_UPDATE = commonModuleMasterUrl + "serviceProvider/update-serviceProvider/"
export const SERVICE_PROVIDER_MASTER_GET = commonModuleMasterUrl + "serviceProvider/get-serviceProvider"
export const SERVICE_PROVIDER_EXAMINER_LIST = USER_MGMNT_URL + "users/all-users"


//Search SuperVisor
export const SEARCH_SUPERVISOR = commonModuleBaseURL + "common-system-service/users/get-supervisor";
export const REDIRECT_TO_CLAIMSEARCHVIEW = "https://claimsdrive-health.salesdrive.app/claimSearchView"
// Travel Base URL
export const TRAVEL_BASE_URL = 'https://claimsdrive-travel.salesdrive.app/';
export const TRAVEL_GC_API = TRAVEL_BASE_URL + 'gc-integration/transaction/';
export const TRAVEL_FAILED_TRANSACTION = TRAVEL_GC_API + 'download-excel-sheet';
export const TRAVEL_RESET_COUNT = TRAVEL_GC_API + 'reset-count';
export const TRAVEL_GET_CLAIM = TRAVEL_BASE_URL + 'claims/claimRegistration/get-all';

export const createCatastrophe = commonModuleMasterUrl + 'catastrophe/create-catastrophe';
export const updateCatastrophe = commonModuleMasterUrl + 'catastrophe/update-catastrophe/';
export const searchCatastrophe = commonModuleMasterUrl + 'catastrophe/search-catastrophe';
export const getSingleCatastrophe = commonModuleMasterUrl + 'catastrophe/get-catastrophe';

export const createaig = commonModuleMasterUrl + 'aig/create-aig';
export const updateaig = commonModuleMasterUrl + 'aig/update-aig/';
export const searchaig = commonModuleMasterUrl + 'aig/search-aig';
export const getSingleaig = commonModuleMasterUrl + 'aig/get-aig/';

// Common-communication
let communicationUrl = commonModuleBaseURL + "common-communications/";
export const getCommunications = communicationUrl + "common-communication/get-communications";
export const resendCommunication = communicationUrl + "common-communication/resend-communications";
export const getTracking = communicationUrl + "common-communication/fetch-event";

//vendor Master
export const SEARCH_VENDOR_MASTER = commonModuleMasterUrl + "vendor/search-vendor"
export const CREATE_VENDOR_MASTER = commonModuleMasterUrl + "vendor/create-vendor"
export const GET_VENDOR_MASTER = commonModuleMasterUrl + 'vendor/get-vendor-details'
export const UPDATE_VENDOR_MASTER = commonModuleMasterUrl + 'vendor/update-vendor/'
export const GET_VENDOR_NAMES = commonModulebasURL + '/users/get-vendor'

export const DISABLE_ALL_LOB = USER_MGMNT_SERVICE + 'users/deactivate-user/'

//GST Master
export const POST_GST_MASTER = commonModuleMasterUrl + "gst/create"
export const GET_REPAIRER_MASTER = commonModuleMasterUrl + "repairer/searchRepairer"
export const GET_GST_MASTER = commonModuleMasterUrl + "gst/search"
export const PUT_GST_MASTER = commonModuleMasterUrl + "gst/update-gst/"

//Repairer Master
export const GET_SEARCH_REPAIRER_MASTER = commonModuleMasterUrl + "repairer/searchRepairer"
export const GET_REPAIRER_MASTER_USER = commonModulebasURL + 'users/get-repairer'
export const POST_REPAIRER_MASTER = commonModuleMasterUrl + 'repairer/createRepairer'
export const GET_SINGLE_REPAIRER_DETAILS = commonModuleMasterUrl + 'repairer/getRepairerDetails'
export const UPDATE_SINGLE_REPAIRER = commonModuleMasterUrl + 'repairer/updateRepairer/'
export const CREATE_CHILD_REPAIRER = commonModuleMasterUrl + 'repairer/createChildRepairer/'

//Common Master Audit Trail
export const GET_AUDIT_TRAIL = commonModuleBaseURL + "common-audits/masterAudit/search"
// Home Base URL
export const HOME_BASE_URL = 'https://claimsdrive-home.salesdrive.app/';
export const HOME_CLAIMS_URL = HOME_BASE_URL + "claims/";
export const HOME_GC_API = HOME_BASE_URL + 'gc-integration/transaction-history/';
export const HOME_FAILED_TRANSACTION = HOME_GC_API + 'download-excel-sheet';
export const HOME_RESET_COUNT = HOME_GC_API + 'reset-count';
export const HOME_GET_CLAIM = HOME_CLAIMS_URL + "claim-registration/get-one";

export const SERVICE_PROVIDER_INVESTIGATOR_LIST = USER_MGMNT_URL + "users/get-siu-investigator"

// TDS Masters
export const GET_BASE_RATE_TDS_URL = commonModuleMasterUrl + 'tds/get-base-rate-tds-master';
export const UPDATE_BASE_RATE_TDS_URL = commonModuleMasterUrl + 'tds/update-base-rate-tds-master/';
export const GET_PENAL_RATE_TDS_URL = commonModuleMasterUrl + 'tds/get-penal-tds-rate-master';
export const UPDATE_PENAL_RATE_TDS_URL = commonModuleMasterUrl + 'tds/update-penal-rate-tds-master/';
export const GET_CONCESSIONAL_AND_PENAL_MASTER_URL = commonModuleMasterUrl + 'tds/get-concessional-and-penal-tds';
export const CREATE_CONCESSIONAL_AND_PENAL_MASTER_URL = commonModuleMasterUrl + 'tds/create-concessional-penal-tds-master';
export const UPDATE_CONCESSIONAL_AND_PENAL_MASTER_URL = commonModuleMasterUrl + 'tds/update-concessional-penal-tds/';
export const BULK_UPLOAD_TDS_RATE = commonModuleMasterUrl + 'tds/bulk-upload-tds';
export const GET_BULK_UPLOAD_TDS_RATE = commonModuleMasterUrl + 'tds/get-tds-bulk-uploads';
export const GET_WORK_ITEMS = commonModuleMasterUrl + 'tds/get-work-items';
export const UPDATE_WORK_ITEM = commonModuleMasterUrl + 'tds/update-work-item';
//TDS Checker

export const GET_TDS_BULK_UPLOAD_CHECKER = commonModuleMasterUrl + 'tds/get-tds-bulk-uploads/';
export const POST_TDS_BULK_UPLOAD_DOCUMNENT_CHECKER = commonModuleMasterUrl + 'tds/checker-upload-tds'
export const TDS_MASTER_PENDING_COUNTS = commonModuleMasterUrl + '/tds/get-tds-count'

export const GET_PENNYDROP_SCORE = commonModuleBaseURL + "common-surround-integration/penny-drop/get-pennydrop-verification";
export const POST_PENNYDROP_REMARK = commonModuleBaseURL + "common-surround-integration/penny-drop/add-penny-drop-data";