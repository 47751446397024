<template>
  <v-app>
    <v-container
      class="containerStyle"
      style="padding: 1.5rem; margin: 0; max-width: 100%"
    >
      <v-layout
        ><h3>{{ heading }} Vendor Master</h3></v-layout
      >
      <v-form
        ref="create_Vendor_Master"
        lazy-validation
        @submit.prevent="onSubmitHandler"
      >
        <v-expansion-panels v-model="panels" multiple>
          <v-expansion-panel>
            <v-expansion-panel-header color="#1EBBD9">
              <span class="page-title-small white--text"
                >Vendor Information</span
              >
              <template v-slot:actions>
                <v-icon class="white--text">
                  {{
                    panels.indexOf(0) !== -1 ? "mdi-minus-box" : "mdi-plus-box"
                  }}
                </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-layout wrap class="pt-4 form-group-container">
                <v-flex class="form-group">
                  <label class="form-label">
                    LOB <span><strong class="red--text">*</strong></span>
                  </label>
                  <v-select
                    v-model="vendorObj.lob"
                    label="Select"
                    :items="lobOptions"
                    :rules="[(v) => requiredField(v)]"
                    solo
                    dense
                    multiple
                    class="form-control"
                    :disabled="isView"
                    clearable
                    @change="populateUserId(vendorObj.lob)"
                  ></v-select>
                </v-flex>
                <v-flex class="form-group">
                  <label class="form-label">
                    User ID <span class="red--text">*</span>
                  </label>
                  <v-select
                    solo
                    dense
                    :items="user_Id_Show"
                    item-text="employeenames"
                    item-value="_id"
                    class="form-control"
                    placeholder="Enter..."
                    v-model="userId"
                    :disabled="heading == 'View' || heading == 'Modify'"
                    @change="userid_change()"
                    :rules="[(v) => requiredField(v)]"
                  ></v-select>
                </v-flex>
                <v-flex class="form-group">
                  <label class="form-label">
                    Vendor ID <span class="red--text">*</span>
                  </label>
                  <v-text-field
                    solo
                    dense
                    class="form-control"
                    placeholder="Enter..."
                    v-model="vendorObj.vendorId"
                    :rules="[
                      (v) => requiredField(v),
                      (v) => matchRegex(v, 'alphanum'),
                    ]"
                    :disabled="heading == 'View' || heading == 'Modify'"
                  ></v-text-field>
                </v-flex>
                <v-flex class="form-group">
                  <label class="form-label">
                    Vendor Type <span class="red--text">*</span>
                  </label>
                  <v-select
                    solo
                    dense
                    class="form-control"
                    :rules="[(v) => requiredField(v)]"
                    placeholder="Enter..."
                    :items="vendorType"
                    v-model="vendorObj.vendorType"
                    :disabled="isView"
                  ></v-select>
                </v-flex>
                <v-flex class="form-group">
                  <label class="form-label">
                    Vendor Name <span class="red--text">*</span>
                  </label>
                  <v-text-field
                    solo
                    dense
                    :maxlength="138"
                    class="form-control"
                    :rules="[(v) => requiredField(v)]"
                    placeholder="Enter..."
                    v-model="vendorObj.vendorName"
                    :disabled="isView"
                  ></v-text-field>
                </v-flex>
                <v-flex class="form-group">
                  <label class="form-label">
                    Vendor Contact <span class="red--text">*</span>
                  </label>
                  <v-text-field
                    solo
                    dense
                    :maxlength="10"
                    :rules="[
                      (v) => requiredField(v),
                      (v) => matchRegex(v, 'nums'),
                      (v) =>
                        validateLength(
                          v,
                          { exact: 10 },
                          '',
                          'Field requires 10 digits.'
                        ),
                    ]"
                    class="form-control"
                    placeholder="Enter..."
                    prepend-inner-icon="mdi-phone"
                    v-model="vendorObj.vendorContact"
                    :disabled="isView"
                  ></v-text-field>
                </v-flex>
                <v-flex class="form-group">
                  <label class="form-label">
                    Address Line 1 <span class="red--text">*</span>
                  </label>
                  <v-text-field
                    solo
                    dense
                    class="form-control"
                    :rules="[(v) => requiredField(v),(v) => addressValidation(v)]"
                    :disabled="isView"
                    placeholder="Enter..."
                    :maxlength="45"
                    v-model="vendorObj.addressLine1"
                  ></v-text-field>
                </v-flex>
                <v-flex class="form-group">
                  <label class="form-label"> Address Line 2 <span class="red--text">*</span></label>
                  <v-text-field
                    solo
                    dense
                    class="form-control"
                    :disabled="isView"
                    placeholder="Enter..."
                    :maxlength="45"
                    :rules="[(v) => requiredField(v),(v) => addressValidation(v)]"
                    v-model="vendorObj.addressLine2"
                  ></v-text-field>
                </v-flex>
                <v-flex class="form-group">
                  <label class="form-label"> Address Line 3 </label>
                  <v-text-field
                    solo
                    dense
                    class="form-control"
                    :disabled="isView"
                    placeholder="Enter..."
                    :maxlength="35"
                    :rules="[(v) => addressValidation(v)]"
                    v-model="vendorObj.addressLine3"
                  ></v-text-field>
                </v-flex>

                <v-flex class="form-group">
                  <label class="form-label"> Postal Code </label>
                  <PincodeSearch
                    :pincodeRequired="true"
                    :isDisabled="isView"
                    :label="false"
                    :pinCode="vendorObj.pinCode"
                    @on-change="change_Pincode"
                  />
                </v-flex>
                <v-flex class="form-group">
                  <label class="form-label">
                    Country <span class="red--text">*</span>
                  </label>
                  <v-text-field
                    solo
                    dense
                    disabled
                    class="form-control"
                    placeholder="Enter..."
                    v-model="vendorObj.country"
                    :rules="[(v) => requiredField(v)]"
                  ></v-text-field>
                </v-flex>
                <v-flex class="form-group">
                  <label class="form-label">
                    City <span class="red--text">*</span>
                  </label>
                  <v-text-field
                    solo
                    dense
                    disabled
                    class="form-control"
                    placeholder="Enter..."
                    v-model="vendorObj.city"
                    :rules="[(v) => requiredField(v)]"
                  ></v-text-field>
                </v-flex>
                <v-flex class="form-group">
                  <label class="form-label">
                    District <span class="red--text">*</span>
                  </label>
                  <v-text-field
                    solo
                    dense
                    disabled
                    class="form-control"
                    placeholder="Enter..."
                    v-model="vendorObj.district"
                    :rules="[(v) => requiredField(v)]"
                  ></v-text-field>
                </v-flex>
                <v-flex class="form-group">
                  <label class="form-label">
                    State/Province <span class="red--text">*</span>
                  </label>
                  <v-text-field
                    solo
                    dense
                    disabled
                    class="form-control"
                    placeholder="Enter..."
                    v-model="vendorObj.state"
                    :rules="[(v) => requiredField(v)]"
                  ></v-text-field>
                </v-flex>

                <!-- <v-flex class="form-group">
                        <v-layout>
                            <v-flex>
                                <label class="form-label">
                                    ISD Code<span class="red--text">*</span>
                                </label>
                                <v-text-field
                                    class="form-control mr-2"
                                    style="max-width:110px"
                                    solo
                                    dense
                                    :disabled="isView"
                                    :rules="[(v)=>matchRegex(v,'nums')]" 
                                    :maxlength="3"
                                    placeholder="..."
                                    prepend-inner-icon=""
                                    v-model="vendorObj.contactNumber[0].isd"
                                    >
                                    <template v-slot:prepend-inner>
                                        <v-icon class="iconstyle">mdi-phone</v-icon>
                                    </template>
                                </v-text-field>
                            </v-flex>

                            <v-flex >
                                <label class="form-label">
                                    Contact Number<span class="red--text">*</span>
                                </label>
                                
                                <v-text-field
                                    placeholder="Enter..."
                                    solo
                                    dense
                                    :disabled="isView"
                                    :maxlength="10"
                                    
                                    :rules="[(v)=>requiredField(v),(v)=>matchRegex(v,'nums')]" 
                                    v-model="vendorObj.contactNumber[0].number"        
                                    class="form-control"
                                    
                                >
                                    <template v-slot:append>
                                    <v-icon class="blueiconstyle" @click="mobile_Dialog=!mobile_Dialog" >mdi-phone-plus</v-icon>
                                    </template>
                                </v-text-field>
                            </v-flex>
                        </v-layout>
                        </v-flex> -->
                <v-flex class="form-group">
                  <label class="form-label"
                    >Email Address <span class="red--text">*</span></label
                  >
                  <v-text-field
                    solo
                    dense
                    class="form-control"
                    placeholder="Enter..."
                    :rules="[
                      (v) => requiredField(v),
                      (v) => validateEmail(v, 'Email ID'),
                    ]"
                    v-model="vendorObj.emailAddress"
                    :disabled="isView"
                    prepend-inner-icon="mdi-email"
                  >
                  </v-text-field>
                </v-flex>
                <v-flex class="form-group">
                  <label class="form-label">Second Contact-Email Address</label>
                  <v-text-field
                    solo
                    dense
                    class="form-control"
                    placeholder="Enter..."
                    :rules="[(v) => validateEmail(v, 'Email ID')]"
                    v-model="vendorObj.secondContactEmailAddress"
                    :disabled="isView"
                    prepend-inner-icon="mdi-email"
                  >
                  </v-text-field>
                </v-flex>
                <v-flex class="form-group">
                  <label class="form-label">Extension</label>
                  <v-text-field
                    solo
                    dense
                    class="form-control"
                    placeholder="Enter..."
                    v-model="vendorObj.landlineNumber.isd"
                    :disabled="isView"
                  ></v-text-field>
                </v-flex>
                <v-flex class="form-group">
                  <label class="form-label">Phone Number</label>
                  <v-text-field
                    solo
                    dense
                    class="form-control"
                    placeholder="Enter..."
                    :maxlength="10"
                    prepend-inner-icon="mdi-phone"
                    v-model="vendorObj.landlineNumber.number"
                    :disabled="isView"
                    :rules="[
                      (v) => matchRegex(v, 'nums'),
                      (v) =>
                        validateLength(
                          v,
                          { exact: 10 },
                          '',
                          'Field requires 10 digits.'
                        ),
                    ]"
                  ></v-text-field>
                </v-flex>

                <v-flex class="form-group">
                  <label class="form-label">Fax Number</label>
                  <v-text-field
                    solo
                    dense
                    class="form-control"
                    placeholder="Enter..."
                    v-model="vendorObj.faxNumber"
                    :disabled="isView"
                  ></v-text-field>
                </v-flex>

                <v-flex class="form-group">
                  <label class="form-label">Second Contact-Fax Number</label>
                  <v-text-field
                    solo
                    dense
                    class="form-control"
                    placeholder="Enter..."
                    v-model="vendorObj.secondContactFaxNumber"
                    :disabled="isView"
                  ></v-text-field>
                </v-flex>

                <v-flex class="form-group">
                  <label class="form-label"
                    >Preferred Communication Method</label
                  >
                  <v-select
                    solo
                    dense
                    class="form-control"
                    :items="['Email', 'Fax']"
                    placeholder="Enter..."
                    :disabled="isView"
                    v-model="vendorObj.preferredCommunicationMethod"
                  ></v-select>
                </v-flex>

                <v-flex class="form-group">
                  <label class="form-label">Cell Phone Number</label>
                  <v-text-field
                    solo
                    dense
                    class="form-control"
                    placeholder="Enter..."
                    prepend-inner-icon="mdi-phone"
                    v-model="vendorObj.cellNumber"
                    :disabled="isView"
                    :maxlength="10"
                    :rules="[
                      (v) => matchRegex(v, 'nums'),
                      (v) =>
                        validateLength(
                          v,
                          { exact: 10 },
                          '',
                          'Field requires 10 digits.'
                        ),
                    ]"
                  ></v-text-field>
                </v-flex>
                <v-flex class="form-group">
                  <p class="mb-2 claimlabel"></p>
                  <v-checkbox
                      label="Is PAN not available"
                      v-model="vendorObj.isPanNotAvailable"
                      @change="checkIsPANAvailable()"
                      class="px-2 checkboxstyle"
                      dense
                      solo
                      :disabled="isView"
                      style="font-weight: bold"
                      color="#23B1A9"
                  ></v-checkbox>
                  </v-flex>
                <v-flex class="form-group">
                  <label class="form-label">
                    PAN Number <span class="red--text">*</span>
                  </label>
                  <v-text-field
                    solo
                    dense
                    :class="isView ? 'forminput textDisable font-weight-bold' : 'forminput'"
                    :disabled="isView"
                    v-model="vendorObj.panNumber"
                    :maxlength="10"
                    placeholder="Enter..."
                    :rules="[(v) => validatePanNumber(v, 'PAN Number'),(v) => requiredField(v)]"
                    :value="computedPAN"
                  ></v-text-field>
                </v-flex>

                <v-flex class="form-group">
                  <label class="form-label">
                    GST Number <span class="red--text">*</span>
                  </label>
                  <v-text-field
                    solo
                    dense
                    class="form-control"
                    placeholder="Enter..."
                    :disabled="isView"
                    :rules="[
                      (v) => validateGSTNumber(v, '', 'GST Number Invalid'),
                      (v) => requiredField(v),
                    ]"
                    v-model="vendorObj.gstinNumber"
                    :maxlength="15"
                    :value="computedGST"
                  ></v-text-field>
                </v-flex>
                <v-flex class="form-group">
                  <label class="form-label">GST Frequency</label>
                  <v-select
                    solo
                    dense
                    class="form-control"
                    label="Select"
                    v-model="vendorObj.gstFrequency"
                    :disabled="isView"
                    :items="[
                      'Monthly',
                      'Quarterly',
                      'Monthly less than 100 Crore',
                      'Monthly more  than 100 Crore but less than 500 Crore',
                      'Monthly More than 500 Crore',
                    ]"
                  ></v-select>
                </v-flex>
                <v-flex class="form-group">
                  <label class="form-label"
                    >TDS Type <span class="red--text">*</span></label
                  >
                  <v-select
                    solo
                    dense
                    class="form-control"
                    label="Select"
                    :rules="[(v) => requiredField(v)]"
                    v-model="vendorObj.tdsType"
                    :disabled="isView"
                    :items="[
                      'Contractors',
                      'Non Residents',
                      'Professional Services - CORP',
                      'Professional Services - NON CORP',
                      'TDS Not Applicable',
                    ]"
                  ></v-select>
                </v-flex>
                <v-flex class="form-group">
                  <v-checkbox
                    label="Differential TDS"
                    class="form-control mt-0"
                    v-model="vendorObj.differentialTds"
                    :disabled="isView"
                  ></v-checkbox>
                </v-flex>
                <v-flex class="form-group">
                  <label class="form-label"
                    >MSME <span class="red--text">*</span></label
                  >
                  <v-select
                    solo
                    dense
                    class="form-control"
                    label="Select"
                    :items="msme_Items"
                    v-model="vendorObj.msme"
                    :disabled="isView"
                  ></v-select>
                </v-flex>
                <v-flex class="form-group">
                  <label class="form-label">Enterprise Classification </label>
                  <v-select
                    solo
                    dense
                    class="form-control"
                    label="Select"
                    v-model="vendorObj.enterpriseClassification"
                    :disabled="isView"
                    :items="[
                      'Proprietorship',
                      'Association of Persons',
                      'Co-operative Society',
                      'Partnership',
                      'Firm',
                      'Company',
                      'Individual',
                    ]"
                  ></v-select>
                </v-flex>
                <v-flex class="form-group">
                  <label class="form-label">Enterprise Category</label>
                  <v-select
                    solo
                    dense
                    class="form-control"
                    label="Select"
                    v-model="vendorObj.enterpriseCategory"
                    :disabled="isView"
                    :items="['Micro', 'Small', 'Medium']"
                  ></v-select>
                </v-flex>
                <v-flex class="form-group">
                  <label class="form-label">UAN</label>
                  <v-text-field
                    solo
                    dense
                    class="form-control"
                    label="Enter..."
                    v-model="vendorObj.uan"
                    :disabled="isView"
                  ></v-text-field>
                </v-flex>
                <v-flex class="form-group">
                  <v-checkbox
                    label="Active"
                    class="form-control"
                    v-model="vendorObj.status"
                    :disabled="isView || !isModify"
                  ></v-checkbox>
                </v-flex>
              </v-layout>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header color="#1EBBD9">
              <span class="page-title-small white--text">Bank Details</span>
              <template v-slot:actions>
                <v-icon class="white--text">
                  {{
                    panels.indexOf(1) !== -1 ? "mdi-minus-box" : "mdi-plus-box"
                  }}
                </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-layout wrap class="pt-4 form-group-container">
                <v-flex class="form-group">
                  <v-checkbox
                    label="Bank Details Available"
                    :disabled="isView"
                    v-model="vendorObj.bankTransfer"
                    @change="bankDetails()"
                    class="form-control"
                  ></v-checkbox>
                </v-flex>
                <v-spacer></v-spacer>
                <div class="me-3 px-2" v-if="cancelledChequeData.length > 0">
                  <label
                    :disabled="!isOCRUploaded"
                    class="form-label mt-2 text-right"
                    @click="cancelChequeView"
                  >
                    <div
                      style="
                        color: #0ab417;
                        font: normal normal medium 12px/14px Roboto;
                        cursor: pointer;
                      "
                    >
                      <v-icon color="#0AB417"> mdi-eye </v-icon>
                      <span class="ml-2">View Cancelled Cheque</span>
                    </div>
                  </label>
                </div>

                <div>
                  <p
                    v-show="vendorObj.bankTransfer"
                    class="teal--text mr-8"
                    style="cursor: pointer"
                    @dragover="dragoverCancel"
                    @drop="dropCancel"
                    @click="browseCancel"
                  >
                    <v-icon color="#23B1A9" class="mr-3"
                      >mdi-tray-arrow-up</v-icon
                    >Upload Cancelled Cheque
                    <v-file-input
                      style="display: none"
                      v-model="cancelledCheque"
                      :disabled="isView"
                      id="cancelchequeupload"
                      label=""
                      dense
                      solo
                      @change="OCRUpload"
                      accept="image/png,image/jpeg,application/msword,application/pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    />
                  </p>
                </div>
              </v-layout>
              <v-layout wrap class="pt-4 form-group-container">
                <v-layout
                  class="py-4 cancel-cheque-view-port"
                  v-if="
                    cancelledChequeFileShow && cancelledChequeData.length > 0
                  "
                >
                  <v-flex xs12>
                    <div
                      class="close-cancell-cheque"
                      @click="
                        cancelledChequeFileShow = !cancelledChequeFileShow
                      "
                    >
                      Close
                    </div>
                    <v-layout justify-center>
                      <object
                        :data="
                          cancelledChequeData[0].fileBase
                            ? cancelledChequeData[0].fileBase
                            : showCheque
                        "
                        width="1000"
                        height="300"
                      ></object>
                      <!-- <img height="300" :src="investigatorMasterObj.documents.fileBase" /> -->
                    </v-layout>
                    <!-- <v-layout justify-center class="mt-2">
                                <div class="cancel-cheque-success-btn">OCR was successfully completed.</div></v-layout> -->
                  </v-flex>
                </v-layout>
              </v-layout>
              <v-layout wrap class="pt-4 form-group-container">
                <v-flex class="form-group" v-show="vendorObj.bankTransfer">
                  <v-checkbox
                    label="Overwritten"
                    v-model="vendorObj.overwriteManually"
                    @click="checkOverwritten"
                    :disabled="isView || dialog_Overwritten"
                    class="form-control"
                  ></v-checkbox>
                </v-flex>
                <v-flex class="form-group">
                  <label class="form-label"
                    >IFSC Code
                    <span v-if="vendorObj.bankTransfer"
                      ><strong class="red--text">*</strong></span
                    ></label
                  >
                  <v-autocomplete
                    solo
                    dense
                    class="form-control"
                    placeholder="Enter..."
                    :rules="
                      vendorObj.bankTransfer == true
                        ? [(v) => requiredField(v)]
                        : []
                    "
                    v-model="vendorObj.bank.ifscCode"
                    :disabled="!vendorObj.overwriteManually || isView"
                    :items="IFSCCodes"
                    item-text="ifscCode"
                    :search-input.sync="ifsc"
                    @keyup="ifscData"
                    @change="ifscchange"
                    :maxlength="11"
                    clearable
                    @click:clear="ifsc_clear"
                  >
                  </v-autocomplete>
                </v-flex>
                <v-flex class="form-group">
                  <label class="form-label"
                    >Bank Name
                    <span v-if="vendorObj.bankTransfer"
                      ><strong class="red--text">*</strong></span
                    ></label
                  >
                  <v-text-field
                    solo
                    dense
                    class="form-control"
                    disabled
                    v-model="vendorObj.bank.bankName"
                    placeholder="Enter..."
                  ></v-text-field>
                </v-flex>
                <v-flex class="form-group">
                  <label class="form-label"
                    >Bank Account Number
                    <span v-if="vendorObj.bankTransfer"
                      ><strong class="red--text">*</strong></span
                    ></label
                  >
                  <v-text-field
                    solo
                    dense
                    class="form-control"
                    placeholder="Enter..."
                    :type="fieldTypes.password"
                    name="password"
                    value=""
                    id="password"
                    @focus="handleType"
                    @blur="handleType"
                    autocomplete="off"
                    @copy.prevent
                    @paste.prevent
                    @click.right.prevent
                    :readonly="!vendorObj.overwriteManually"
                    @change="checkBankAccount(false)"
                    v-model="vendorObj.bank.accountNumber"
                    :rules="
                      vendorObj.bankTransfer == true
                        ? [
                            (v) => requiredField(v),(v)=>matchRegex(v,'alphanum')
                          ]
                        : []
                    "
                    :disabled="!vendorObj.overwriteManually || isView"
                    :maxlength="35"
                  >
                  </v-text-field>
                </v-flex>
                <v-flex class="form-group">
                  <label class="form-label"
                    >Re-enter Bank Account Number
                    <span v-if="vendorObj.bankTransfer"
                      ><strong class="red--text">*</strong></span
                    ></label
                  >
                  <v-text-field
                    solo
                    dense
                    class="form-control"
                    placeholder="Enter..."
                    :rules="
                      vendorObj.bankTransfer == true
                        ? [
                            (v) => requiredField(v),
                            (v) => matchRegex(v, 'alphanum'),
                            (v) =>
                              shouldBeSame(
                                vendorObj.bank.accountNumber,
                                v,
                                'Mismatch Bank Account Number'
                              ),
                          ]
                        : []
                    "
                    v-model="vendorObj.bank.confirmAccountNumber"
                    :maxlength="35"
                    :disabled="!vendorObj.overwriteManually || isView"
                    @paste.prevent
                    @change="checkBankAccount(false)"
                  >
                  </v-text-field>
                </v-flex>
                <v-flex class="form-group">
                  <label class="form-label"
                    >Branch
                    <span v-if="vendorObj.bankTransfer"
                      ><strong class="red--text">*</strong></span
                    ></label
                  >
                  <v-text-field
                    solo
                    dense
                    class="form-control"
                    placeholder="Enter..."
                    v-model="vendorObj.bank.branch"
                    :rules="
                      vendorObj.bankTransfer == true
                        ? [(v) => requiredField(v)]
                        : []
                    "
                    disabled
                  ></v-text-field>
                </v-flex>
                <v-flex class="form-group">
                  <label class="form-label"
                    >Bank Address Line 1
                    <span v-if="vendorObj.bankTransfer"
                      ><strong class="red--text">*</strong></span
                    ></label
                  >
                  <v-text-field
                    solo
                    dense
                    class="form-control"
                    placeholder="Enter..."
                    v-model="vendorObj.bank.bankAddressLine1"
                    :rules="
                      vendorObj.bankTransfer == true
                        ? [(v) => requiredField(v),(v) => matchRegex(v, 'alphanum')]
                        : []
                    "
                  :disabled="!vendorObj.overwriteManually && !editBankAddress1 || isView"
                  >
                  </v-text-field>
                </v-flex>
                <v-flex class="form-group">
                  <label class="form-label">Bank Address Line 2</label>
                  <v-text-field
                    solo
                    dense
                    class="form-control"
                    placeholder="Enter..."
                    v-model="vendorObj.bank.bankAddressLine2"
                    :rules="[(v) => matchRegex(v, 'alphanum')]"
                    :disabled="!vendorObj.overwriteManually && !editBankAddress2 || isView"
                  >
                  </v-text-field>
                </v-flex>
                <v-flex class="form-group">
                  <label class="form-label">Bank Address Line 3</label>
                  <v-text-field
                    solo
                    dense
                    class="form-control"
                    placeholder="Enter..."
                    v-model="vendorObj.bank.bankAddressLine3"
                    :rules="[(v) => matchRegex(v, 'alphanum')] "
                    :disabled="!vendorObj.overwriteManually && !editBankAddress3 || isView"
                  >
                  </v-text-field>
                </v-flex>
                <v-flex class="form-group">
                  <label class="form-label"
                    >District
                    <span v-if="vendorObj.bankTransfer"
                      ><strong class="red--text">*</strong></span
                    ></label
                  >
                  <v-text-field
                    solo
                    dense
                    class="form-control"
                    placeholder="Enter..."
                    v-model="vendorObj.bank.district"
                    :rules="
                      vendorObj.bankTransfer == true
                        ? [(v) => requiredField(v)]
                        : []
                    "
                    disabled
                  >
                  </v-text-field>
                </v-flex>
                <v-flex class="form-group">
                  <label class="form-label"
                    >City
                    <span v-if="vendorObj.bankTransfer"
                      ><strong class="red--text">*</strong></span
                    ></label
                  >
                  <v-text-field
                    solo
                    dense
                    class="form-control"
                    placeholder="Enter..."
                    v-model="vendorObj.bank.city"
                    :rules="
                      vendorObj.bankTransfer == true
                        ? [(v) => requiredField(v)]
                        : []
                    "
                    disabled
                  >
                  </v-text-field>
                </v-flex>
                <v-flex class="form-group">
                  <label class="form-label"
                    >State
                    <span v-if="vendorObj.bankTransfer"
                      ><strong class="red--text">*</strong></span
                    ></label
                  >
                  <v-text-field
                    solo
                    dense
                    class="form-control"
                    placeholder="Enter..."
                    v-model="vendorObj.bank.state"
                    :rules="
                      vendorObj.bankTransfer == true
                        ? [(v) => requiredField(v)]
                        : []
                    "
                    disabled
                  >
                  </v-text-field>
                </v-flex>
                <v-flex class="form-group">
                  <label class="form-label"
                    >Pin Code
                    <span v-if="vendorObj.bankTransfer"
                      ><strong class="red--text">*</strong></span
                    ></label
                  >
                  <v-text-field
                    solo
                    dense
                    :maxlength="6"
                    class="form-control"
                    placeholder="Enter..."
                    v-model="vendorObj.bank.pinCode"
                    :rules="
                      vendorObj.bankTransfer == true
                        ? [(v) => requiredField(v)]
                        : []
                    "
                    disabled
                  >
                  </v-text-field>
                </v-flex>
                <v-flex class="form-group">
                  <label class="form-label"
                    >Account Holder Name
                    <span v-if="vendorObj.bankTransfer"
                      ><strong class="red--text">*</strong></span
                    ></label
                  >
                  <v-text-field
                    solo
                    dense
                    class="form-control"
                    placeholder="Enter..."
                    v-model="vendorObj.bank.accountHolderName"
                    :rules="
                      vendorObj.bankTransfer == true
                        ? [(v) => requiredField(v)]
                        : []
                    "
                    :disabled="!vendorObj.overwriteManually || isView"
                  >
                  </v-text-field>
                </v-flex>
                <v-flex class="form-group">
                  <label class="form-label"
                    >Account Type
                    <span v-if="vendorObj.bankTransfer"
                      ><strong class="red--text">*</strong></span
                    ></label
                  >
                  <v-select
                    solo
                    dense
                    class="form-control"
                    placeholder="Enter..."
                    :items="accountTypes"
                    v-model="vendorObj.bank.accountType"
                    :rules="
                      vendorObj.bankTransfer == true
                        ? [(v) => requiredField(v)]
                        : []
                    "
                    :disabled="isView || !vendorObj.bankTransfer"
                  >
                  </v-select>
                </v-flex>
              </v-layout>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header color="#1EBBD9">
              <span class="page-title-small white--text">Upload Documents</span>
              <template v-slot:actions>
                <v-icon class="white--text">
                  {{
                    panels.indexOf(2) !== -1 ? "mdi-minus-box" : "mdi-plus-box"
                  }}
                </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-layout wrap class="pt-4 form-group-container">
                <v-tabs v-model="tab" hide-slider>
                  <!-- active-class="claimProcessingTabsActive" -->
                  <v-tab
                    v-for="item in tab_items"
                    :key="item"
                    @change="
                      documentTab = item
                      checkTabChanged()
                    "
                    :class="
                      documentTab == item
                        ? 'claimProcessingTabsActive'
                        : 'unActive'
                    "
                  >
                    {{ item }}
                  </v-tab>
                </v-tabs>
                <v-layout class="mt-5">
                  <div>
                    <div
                      class="neftStyle"
                      @dragover="dragover"
                      @dragleave="dragleave"
                      @drop="drop"
                      :class="visualEffect ? 'dragbackground' : ''"
                    >
                      <v-icon color="#23b1a9" class="iconfont"
                        >mdi-tray-arrow-up</v-icon
                      >
                      <span class="ml-2 margincls font-weight-bold"
                        >Drop Files To Upload or
                        <a @click="browse"><u> Browse</u></a></span
                      >
                      <p class="margincls dlt_text mb-0">
                        Max 5MB of PDF, Word, Excel, Mail or Image(.jpeg, .jpg,
                        .png)
                      </p>
                      <p class="dlt_text mt-0 ml-4" style="font-size: 10px">
                        Multiple documents can be uploaded
                      </p>
                    </div>
                    <span></span>
                    <v-file-input
                      multiple
                      v-model="all_attachments"
                      @change="selectFile"
                      accept=".doc, .docx, .csv, image/png,image/jpeg,application/msword,application/pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/csv"
                      id="SP_fileupload"
                      :disabled="isView"
                      style="display: none"
                    >
                    </v-file-input>
                    <!-- @change="selectFile" -->
                  </div>
                  <div>
                    <v-data-table
                      :headers="neftHeaders"
                      :items="documentAttachments"
                      hide-default-footer
                      disable-pagination
                      no-data-text="No Records for Attachments"
                      class="tabelwidth vDataTableStyling"
                    >
                      <template v-slot:[`item.action`]="{ item, index }">
                        <v-icon
                          class="tableicons mr-4"
                          color="#23B1A9"
                          @click="viewDocument(item)"
                          >mdi-eye</v-icon
                        >
                        <v-icon
                          class="tableicons mr-4"
                          color="#23B1A9"
                          @click="downloadDoc(item)"
                          >mdi-download</v-icon
                        >
                        <v-icon
                          class="tableicons"
                          color="#D1121B"
                          @click="deleteDocument(index)"
                          v-if="!item.documentId || isView"
                          >mdi-delete</v-icon
                        >
                      </template>
                    </v-data-table>
                  </div>
                </v-layout>
              </v-layout>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-form>

      <v-layout class="mt-5">
        <v-btn outlined color="red" class="mr-5 btn" @click="cancel">
          <v-icon> mdi-backspace </v-icon>
          <v-divider vertical class="mx-2"></v-divider> Cancel</v-btn
        >
        <v-btn
          outlined
          color="red"
          class="btn"
          @click="onReset"
          v-show="!isView"
        >
          <v-icon> mdi-backspace </v-icon>
          <v-divider vertical class="mx-2"> </v-divider> Reset</v-btn
        >

        <v-spacer></v-spacer>
        <v-btn
          class="btn"
          color="teal white--text"
          dense
          solo
          @click="onSubmit"
          v-show="!isView"
          ><v-icon>mdi-arrow-right-circle</v-icon>
          <v-divider vertical class="mx-2"></v-divider>
          Submit</v-btn
        >
      </v-layout>
    </v-container>
  </v-app>
</template>
<script>
import PincodeSearch from "../../../component/PincodeSearch.vue"
export default {
  components: {
    PincodeSearch,
  },
  data() {
    return {
      country: "",
      country_Items: [],
      search_State: null,
      search_Country: null,
      state_Items: [],
      state: "",

      district: "",
      district_Items: [],
      search_District: null,

      city: "",
      city_Items: [],
      search_City: null,

      lobOptions: [],
      userId: "",
      panels: [0, 1, 2],
      heading: "Create",
      isView: false,
      showCheque: [],
      visualEffect: false,
      cancelledChequeFileShow: false,
      cancelledCheque: [],
      cancelledChequeData: [],
      isOCRUploaded: false,
      accountTypes: ["Saving Account", "Current Account"],
      IFSCCodes: [],
      ifsc: null,
      tab: null,
      tab_items: ["NEFT Form", "KYC/CKYC", "Others"],
      documentTab: "NEFT Form",
      all_attachments: [],
      neftHeaders: [
        {
          text: "Document Name",
          align: "start",
          value: "file",
          sortable: false,
        },
        { text: "Action", align: "start", value: "action", sortable: false },
      ],
      documentAttachments: [],
      user_Id_Show: [],
      user_Id: [],
      neftAttachment: [],
      otherAttachment: [],
      kycAttachment: [],
      fieldTypes: { password: "password" },
      msme_Items: [
        { text: "Yes", value: true },
        { text: "No", value: false },
      ],
      dialog_Overwritten: false,
      vendorType: [
        "Client",
        "Delivery Company",
        "Manufacturer",
        "Other",
        "Supplier",
      ],
      vendorObj: {
        vendorName: "",
        user: {
          ntid: "",
          employeeName: "",
          userId: "",
        },

        addressLine1: "",
        addressLine2: "",
        addressLine3: "",
        city: "",
        district: "",
        state: "",
        country: "",
        pinCode: "",
        landlineNumber: { isd: "", number: "" },
        gstFrequency: "",
        tdsType: "",
        differentialTds: false,
        msme: false,
        enterpriseClassification: "",
        enterpriseCategory: "",
        uan: "",
        cellNumber: "",
        panNumber: "",
        isPanNotAvailable: false,
        emailAddress: "",
        secondContactEmailAddress: "",
        faxNumber: "",
        secondContactFaxNumber: "",
        preferredCommunicationMethod: "",
        vendorType: "",

        vendorContact: "",
        gstinNumber: "",
        emailAddress: "",
        bankTransfer: false,
        bank: {
          ifscCode: "",
          bankName: "",
          city: "",
          bankAddressLine1: "",
          bankAddressLine2: "",
          bankAddressLine3: "",
          branch: "",
          district: "",
          state: "",
          pinCode: "",
          accountNumber: "",
          confirmAccountNumber: "",
          accountType: "",
          accountHolderName: "",
          cancelledCheque: [],
        },
        lob: [],
        approveRequest: true,
        approveStatus: "Pending for approval",
        status: false,
        vendorId: "",

        overwriteManually: false,
        proceedDuplicate: false,
      },
      isModify: false,
      freeze: false,

      editBankAddress1:false,
      editBankAddress2:false,
      editBankAddress3:false
    }
  },

  created() {
    this.$store.commit("SAVE_PAGE_HEADER", "Vendor Master Create")

    if (Object.keys(this.$route.params).length != 0) {
      this.get_data(this.$route.params.item)
    }
    //  this.vendorNames()
    this.getLOB()
  },

  methods: {
    checkIsPANAvailable() {
        this.vendorObj.panNumber = '';
        this.vendorObj.tdsType = this.vendorObj.isPanNotAvailable ? "TDS not Applicable" : "";
        if(this.vendorObj.isPanNotAvailable) {
            this.showAlert("F & A approval won't be triggered & the base rate as per PAN Number Not Available will be applied.");
        }
    },
    checkBankAddress(){
 const specialChars = `\`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~`;

  specialChars.split('').some(specialChar => {
    if (this.vendorObj.bank.bankAddressLine1.includes(specialChar)) {
      this.editBankAddress1 = true
    }
   
  });

  specialChars.split('').some(specialChar => {
    if (this.vendorObj.bank.bankAddressLine2.includes(specialChar)) {
      this.editBankAddress2 = true
    }
  });

  specialChars.split('').some(specialChar => {
    if (this.vendorObj.bank.bankAddressLine3.includes(specialChar)) {
      this.editBankAddress3 = true
    }
  });
      },
    populateUserId(item) {
      // console.log("ITEMS",item)
      this.vendorNames()
    },

    getLOB() {
      let reqData = ""
      this.showProgress()
      this.GETM(
        "getLOB",
        reqData,
        (res) => {
          this.hideProgress()
          if (res.data.statusCode === 200) {
            this.lobOptions = res.data.data[0].lineOfBusiness
          } else {
            this.lobOptions = []
            this.showAlert(res.data.msg)
          }
        },
        (error) => {
          console.log(error)
          this.hideProgress()
        },
        true
      )
    },
    userid_change() {
      this.user_Id_Show.map((find) => {
        if (find._id == this.userId) {
          this.vendorObj.user.ntid = find.ntidUserId
          this.vendorObj.user.employeeName = find.employeeName
          this.vendorObj.user.userId = find._id
        }
      })
    },
    bankDetails() {
      if (this.vendorObj.bankTransfer == false) {
        this.vendorObj.bank = {
          ifscCode: "",
          bankName: "",
          city: "",
          bankAddressLine1: "",
          bankAddressLine2: "",
          bankAddressLine3: "",
          branch: "",
          district: "",
          state: "",
          pinCode: "",
          accountNumber: "",
          confirmAccountNumber: "",
          accountType: "",
          accountHolderName: "",
          cancelledCheque: [],
        }

        this.cancelledChequeFileShow = false
        this.cancelledCheque = null
        this.cancelledChequeData = []
        this.dialog_Overwritten = false
      }
      else{
            this.showAlert("Please Upload Cancelled Cheque")
        }
    },
    checkOverwritten() {
      this.showConfirm(
        "Confirmation",
        "Once checked cannot be unchecked. Do you want to proceed?",
        "Yes",
        "No",
        (Yes) => {
          this.dialog_Overwritten = true
        },
        ()=>this.vendorObj.overwriteManually=false
      )
    },
    cancel(data) {
      if (data == 1) {
        this.$router.push({ name: "SearchVendorMaster" })
      } else {
        this.showConfirm(
          "Confirmation",
          "Are you sure you want to leave?",
          "Yes",
          "No",
          (Yes) => {
            this.showProgress()
            this.$router.push({ name: "SearchVendorMaster" }) //params:{pageNo:this.pageNo}}
            this.hideProgress()
          }
        )
      }
    },
    change_Pincode(data) {
      this.vendorObj.pinCode = data.pinCode
      this.vendorObj.city = data.cityDistrictName
      this.vendorObj.district = data.cityDistrictName
      this.vendorObj.state = data.stateName
      this.vendorObj.country = data.countryName
      // this.vendorObj.spCountry=data.countryName
    },
    cancelChequeView() {
      this.cancelledChequeFileShow = !this.cancelledChequeFileShow
    },
    dropCancel(event) {
      event.preventDefault()
      this.cancelledCheque = event.dataTransfer.files
      this.OCRUpload() // Trigger the onChange event manually
    },
    browseCancel() {
      //to select attachments
      document.getElementById("cancelchequeupload").click()
    },
    dragoverCancel(event) {
      event.preventDefault()
    },

    handleType(event) {
      const { srcElement, type } = event
      const { name, value } = srcElement

      if (type === "blur" && !value) {
        this.fieldTypes[name] = "text"
      } else {
        this.fieldTypes[name] = "password"
      }
    },
    dragover(event) {
      event.preventDefault()
      this.visualEffect = true
    },
    dragleave(event) {
      this.visualEffect = false
    },
    browse() {
      //to select attachments
      document.getElementById("SP_fileupload").click()
    },

    drop(event) {
      event.preventDefault()
      this.all_attachments = event.dataTransfer.files
      // console.log("all", this.all_attachments);
      this.selectFile() // Trigger the onChange event manually
      this.visualEffect = false
    },
    selectFile() {
      if (this.all_attachments.length !== 0) {
        const fsize = this.all_attachments[0].size
        const file = Math.round(fsize / 1024)
        let self = this
        if (file > 5120) {
          self.showAlert("File is too big, please select a file less than 5MB")
        } else {
          self.readImageM(
            self.all_attachments[0].name,
            self.all_attachments[0],
            (res) => {
              console.log(res)
              var type = []
              type = self.all_attachments[0].type
              console.log("--", self.documentTab)
              if (self.documentTab == "NEFT Form") {
                self.neftAttachment.push({
                  file: res.fileName,
                  fileBase: res.base,
                  documentType: "NEFT Form",
                })
                self.neftAttachment = self.neftAttachment.reduce(
                  (unique, o) => {
                    if (!unique.some((obj) => obj.file === o.file)) {
                      unique.push(o)
                    }
                    return unique
                  },
                  []
                )

                console.log("---neft", self.neftAttachment)
              } else if (self.documentTab == "Others") {
                self.otherAttachment.push({
                  file: res.fileName,
                  fileBase: res.base,
                  documentType: "OTHERS",
                })
                self.otherAttachment = self.otherAttachment.reduce(
                  (unique, o) => {
                    if (!unique.some((obj) => obj.file === o.file)) {
                      unique.push(o)
                    }
                    return unique
                  },
                  []
                )
              } else {
                self.kycAttachment.push({
                  file: res.fileName,
                  fileBase: res.base,
                  documentType: "KYC/CKYC",
                })
                self.kycAttachment = self.kycAttachment.reduce((unique, o) => {
                  if (!unique.some((obj) => obj.file === o.file)) {
                    unique.push(o)
                  }
                  return unique
                }, [])
              }
              // // Phase-2 function call starts
              // this.getCancelledChequeData(res.base);
              // this.manualOverwrite = false;
              // // Phase-2 function call ends
              this.all_attachments = []
            }
          )
        }
      }
      this.checkTabChanged()
    },
    viewDocument(itemData) {
      if (itemData.fileBase) {
        this.viewDocUsingBase(itemData.fileBase)
      } else {
        let requeisdata = ""
        if (itemData !== null) {
          requeisdata = "documentId=" + itemData.documentId + "&b64=true"
        }
        this.GETM(
          "VIEW_DOCUMENT",
          requeisdata,
          (res) => {
            if (res.data.statusCode === 200) {
              this.viewDocUsingBase(res.data.data)
            }
          },
          (error) => {
            console.log("Error", error)
          },
          true
        )
      }
    },
    viewDocUsingBase(base64) {
      const base64ImageData = base64
      const contentType = base64.split(":")[1].split(";")[0]
      const byteCharacters = window.atob(
        base64ImageData.substr(`data:${contentType};base64,`.length)
      )
      const byteArrays = []
      for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
        const slice = byteCharacters.slice(offset, offset + 1024)
        const byteNumbers = new Array(slice.length)
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i)
        }
        const byteArray = new Uint8Array(byteNumbers)
        byteArrays.push(byteArray)
      }
      const blob = new Blob(byteArrays, { type: contentType })
      const blobUrl = window.URL.createObjectURL(blob)
      window.open(blobUrl, "_blank")
    },

    downloadDoc(item) {
      if (item.fileBase) {
        let base64 = item.fileBase
        const base64ImageData = base64
        const contentType = base64.split(":")[1].split(";")[0]
        const byteCharacters = window.atob(
          base64ImageData.substr(`data:${contentType};base64,`.length)
        )
        const byteArrays = []
        for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
          const slice = byteCharacters.slice(offset, offset + 1024)
          const byteNumbers = new Array(slice.length)
          for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i)
          }
          const byteArray = new Uint8Array(byteNumbers)
          byteArrays.push(byteArray)
        }
        const blob = new Blob(byteArrays, { type: contentType })
        const blobUrl = window.URL.createObjectURL(blob)

        const element = document.createElement("a")
        element.href = blobUrl
        element.download = item.file
        element.click()
      } else {
        let url =
          this.getURL("VIEW_DOCUMENT") + "?documentId=" + item.documentId
        window.open(url)
      }
    },
    deleteDocument(index) {
      if (this.documentTab == "NEFT Form") {
        this.neftAttachment.splice(index, 1)
      }

      if (this.documentTab == "Others") {
        this.otherAttachment.splice(index, 1)
      }
      if (this.documentTab == "KYC/CKYC") {
        this.kycAttachment.splice(index, 1)
      }
      this.checkTabChanged()
    },
    checkTabChanged() {
      if (this.documentTab == "NEFT Form") {
        this.documentAttachments = this.neftAttachment
      }
      if (this.documentTab == "Others") {
        this.documentAttachments = this.otherAttachment
      }
      if (this.documentTab == "KYC/CKYC") {
        this.documentAttachments = this.kycAttachment
      }
    },
    change_bank_Pincode(data) {
      let reqData = "pageNo=1&perPage=100&pinCode=" + data
      this.GETM(
        "getPincodes",
        reqData,
        (res) => {
          let data = res.data.data.data[0]
          this.vendorObj.bank.pinCode = data.pinCode
          this.vendorObj.bank.city = data.cityDistrictName
          this.vendorObj.bank.district = data.cityDistrictName
          this.vendorObj.bank.state = data.stateName
        },
        (err) => {
          console.error("Issue while fetching pin codes.", err)
        }
      )
    },
    checkBankAccount(isAccNum) {
      if (
        this.vendorObj.bank.accountNumber &&
        this.vendorObj.bank.confirmAccountNumber &&
        this.vendorObj.bank.accountNumber !==
          this.vendorObj.bank.confirmAccountNumber
      ) {
        this.showAlert(
          "Bank Account number and Re-enter Bank Account number do not match."
        )
        isAccNum
          ? (this.vendorObj.bank.accountNumber = "")
          : (this.vendorObj.bank.confirmAccountNumber = "")
      }
    },
    OCRUpload() {
      if (this.cancelledCheque) {
        console.log("4444", this.cancelledCheque)
        this.cancelledChequeData = []
        this.cancelledChequeFileShow = false //hide view port
        this.isOCRUploaded = false //check if OCR uploaded
        this.vendorObj.overwriteManually = false //check for manual entry
        //this.clearBankDetails() //reset bank fields
        if (!this.cancelledCheque.name) {
          return // for while resetting the form
        }
        this.showProgress()

        this.readImageM(
          this.cancelledCheque.name,
          this.cancelledCheque,
          (res) => {
            let requestPayload = {
              document: res.base,
            }
            this.cancelledCheque = [] //reset field value
            this.POSTM(
              "GET_OCR_FORMAT",
              requestPayload,
              (response) => {
                this.hideProgress()
                if (
                  response.data.data.Message ==
                  "Uploaded Image/Document orientation is incorrect"
                ) {
                  this.showAlert(response.data.data.Message)
                  this.vendorObj.overwriteManually = true
                  this.dialog_Overwritten = true
                } else if (
                  response.data.data.Message == "Invalid Image/Document"
                ) {
                  this.showAlert(response.data.data.Message)
                  this.vendorObj.overwriteManually = true
                  this.dialog_Overwritten = true
                } else if (response.data.data) {
                  let bankres = response.data.data
                  console.log(response.data.data)
                  if (
                    !bankres.AccountNumber ||
                    !bankres.IFSC ||
                    !bankres.PayerName
                  ) {
                    this.isOCRUploaded = true

                    this.vendorObj.overwriteManually = true
                    this.dialog_Overwritten = true
                    this.showAlert(
                      "Could not retrieve data from OCR either re-upload the cancelled cheque or enter the details manually"
                    )
                  } else {
                    //temprory fix
                    this.showProgress()
                    let request = bankres.IFSC + "?lob=Marine"
                    this.GET_ByURLM(
                      "GET_GC_BANK",
                      request,
                      (res) => {
                        this.IFSCCodes = []
                        this.hideProgress()
                        console.log(res.data.data)
                        if (res.data.data && res.data.data.length > 0) {
                          let ifscCodeValue = res.data.data[0]
                          console.log("9999", bankres)
                          console.log("33333", ifscCodeValue)
                          this.IFSCCodes = res.data.data
                          this.vendorObj.bank.ifscCode = bankres.IFSC
                          this.vendorObj.bank.accountNumber =
                            bankres.AccountNumber
                          this.vendorObj.bank.accountHolderName =
                            bankres.PayerName
                          this.dialog_Overwritten = false
                          this.vendorObj.bank.confirmAccountNumber =
                            bankres.AccountNumber
                          this.vendorObj.bank.branch = ifscCodeValue.branchName

                          this.vendorObj.bank.bankName =
                            ifscCodeValue.financierName
                          this.vendorObj.bank.bankAddressLine1 =
                            ifscCodeValue.branchAddress
                          let address2 = ""
                          if (ifscCodeValue.district) {
                            address2 += `${ifscCodeValue.district}, `
                          }
                          if (ifscCodeValue.city) {
                            address2 += `${ifscCodeValue.city}, `
                          }
                          if (ifscCodeValue.pincode) {
                            address2 += ifscCodeValue.pincode
                          }
                          this.vendorObj.bank.bankAddressLine2 = address2
                          this.vendorObj.bank.bankAddressLine3 =
                            ifscCodeValue.state
                            this.checkBankAddress()

                          //   this.vendorObj.bank.pinCode = ifscCodeValue.pincode
                          //this.vendorObj.bank.bankState = ifscCodeValue.state
                          this.change_bank_Pincode(ifscCodeValue.pincode)

                          // this.pincode1=ifscCodeValue.pincode

                          // this.changePincodeAndState(1)
                          // this.formDataAddressLine2 = `${ifscCodeValue.district}, ${ifscCodeValue.city}, ${ifscCodeValue.state}`
                          this.showAlert("OCR uploaded successfully");
                        }
                      },
                      (error) => {
                        this.IFSCCodes = []
                        this.hideProgress()
                        console.log("error", error)
                        this.showAlert(
                          error.response &&
                            error.response.data &&
                            error.response.data.message
                            ? error.response.data.message
                            : error.message
                        )
                      },
                      true
                    )

                    this.cancelledChequeData.push({
                      file: res.fileName,
                      fileBase: res.base,
                      documentType: "Cancelled Cheque",
                    })

                    // this.vendorObj.AccountNumber = bankres.AccountNumber;
                    // this.reEnterBankAccountNumber = bankres.AccountNumber;
                    //this.vendorObj.accountHolderName = bankres.PayerName
                    this.isOCRUploaded = true
                  }
                } else {
                  this.showAlert("No data found from OCR")
                }
              },
              (error) => {
                this.hideProgress()
                this.vendorObj.isOverWritten = true
                this.showAlert(
                  "Something went wrong in OCR,Please enter the details manually"
                )
              },
              true
            )
          }
        )
      } else {
        this.showAlert("Please upload a file.")
      }
    },
    ifsc_clear() {
      this.cancelledChequeData = []
      this.IFSCCodes = []
    },
    ifscData() {
      if (this.ifsc.length > 3) {
        let request = this.ifsc + "?lob=Marine"
        this.GET_ByURLM(
          "GET_GC_BANK",
          request,
          (res) => {
            this.IFSCCodes = []
            this.hideProgress()
            console.log(res.data.data)
            if (res.data.data && res.data.data.length > 0) {
              this.IFSCCodes = res.data.data
            }
          },
          (error) => {
            this.IFSCCodes = []
            this.hideProgress()
            console.log("error", error)
            this.showAlert(
              error.response &&
                error.response.data &&
                error.response.data.message
                ? error.response.data.message
                : error.message
            )
          },
          true
        )
      }
    },
    ifscchange() {
      this.IFSCCodes.forEach((ifsc) => {
        if (this.vendorObj.bank.ifscCode == ifsc.ifscCode) {
          let ifscCodeValue = ifsc
          // this.IFSCCodes=res.data.data
          this.vendorObj.bank.branch = ifscCodeValue.branchName
          this.vendorObj.bank.bankName = ifscCodeValue.financierName
          this.vendorObj.bank.bankAddressLine1 = ifscCodeValue.branchAddress
          let address2 = ""
          if (ifscCodeValue.district) {
            address2 += `${ifscCodeValue.district}, `
          }
          if (ifscCodeValue.city) {
            address2 += `${ifscCodeValue.city}, `
          }
          if (ifscCodeValue.pincode) {
            address2 += ifscCodeValue.pincode
          }
          this.vendorObj.bank.bankAddressLine2 = address2
          this.vendorObj.bank.bankAddressLine3 = ifscCodeValue.state
          //this.vendorObj.bank.pinCode=ifscCodeValue.pincode
          //   this.pincode1=ifscCodeValue.pincode
          this.change_bank_Pincode(ifscCodeValue.pincode)
        }
      })
    },
    vendorNames() {
      this.GETM(
        "GET_VENDOR_NAMES",
        "" + `lob=${this.vendorObj.lob.map((item) => item)}`,
        (res) => {
          this.user_Id = res.data.data
          this.user_Id_Show = res.data.data.map((obj) => {
            obj.employeenames = `${obj.employeeName} - ${obj.employeeId}`

            return obj
          })
          this.userId = this.vendorObj.user.userId
        },
        (err) => {
          console.log(err)
        },
        true
      )
    },
    convertObjToString() {
      this.vendorObj.attachments = [
        ...this.neftAttachment,
        ...this.otherAttachment,
        ...this.kycAttachment,
      ]
      this.vendorObj.bank.cancelledCheque = [...this.cancelledChequeData]
    },
    onReset() {
      this.showConfirm(
        "Confirmation",
        "Are you sure you want to Reset?",
        "Yes",
        "No",
        (Yes) => {
          ;(this.vendorObj.vendorName = ""), (this.vendorObj.lob = [])
          ;(this.vendorObj.addressLine1 = ""),
            (this.vendorObj.addressLine2 = ""),
            (this.vendorObj.addressLine3 = ""),
            (this.vendorObj.city = ""),
            (this.vendorObj.district = ""),
            (this.vendorObj.state = ""),
            (this.vendorObj.pinCode = ""),
            (this.vendorObj.country = ""),
            (this.vendorObj.emailAddress = "")
          this.vendorObj.secondContactEmailAddress = ""
          this.vendorObj.faxNumber = ""
          this.vendorObj.secondContactFaxNumber = ""
          this.vendorObj.preferredCommunicationMethod = ""
          this.vendorObj.vendorType = ""
          this.vendorObj.vendorId = ""
          this.vendorObj.vendorContact = ""
          this.vendorObj.landlineNumber = {
            isd: "",
            number: "",
          }

          // this.vendorObj.contactNumber= [
          //     {
          //         isd: "91",
          //         number: ""
          //     }
          // ],
            (this.vendorObj.isPanNotAvailable = false),
            (this.vendorObj.panNumber = ""),
            (this.vendorObj.aadharId = ""),
            (this.vendorObj.gstinNumber = ""),
            (this.vendorObj.emailId = ""),
            (this.vendorObj.gstFrequency = ""),
            (this.vendorObj.tdsType = ""),
            (this.vendorObj.differentialTds = ""),
            (this.vendorObj.msme = ""),
            (this.vendorObj.enterpriseClassification = ""),
            (this.vendorObj.enterpriseCategory = ""),
            (this.vendorObj.uan = ""),
            (this.vendorObj.cellNumber = ""),
            (this.vendorObj.bankTransfer = "")
          ;(this.vendorObj.bank = {
            ifscCode: "",
            bankName: "",
            city: "",
            bankAddressLine1: "",
            bankAddressLine2: "",
            bankAddressLine3: "",
            branch: "",
            district: "",
            state: "",
            pinCode: "",
            accountNumber: "",
            confirmAccountNumber: "",
            accountType: "",
            accountHolderName: "",
            cancelledCheque: [],
          }),
            (this.vendorObj.status = false),
            (this.vendorObj.approveRequest = true),
            (this.vendorObj.approveStatus = ""),
            (this.vendorObj.vendorId = ""),
            (this.vendorObj.overwriteManually = "")
        }
      )
    },
    viewDocumentCheque(id) {
      let requeisdata = "documentId=" + id + "&b64=true"
      this.GETM(
        "VIEW_DOCUMENT",
        requeisdata,
        (res) => {
          if (res.data.statusCode === 200) {
            this.showCheque = res.data.data
          }
        },
        (error) => {
          console.log("Error", error)
        },
        true
      )
    },
    get_data(value) {
      let reqData = `vendorId=${value.vendorId}&pageNo=1&perPage=10`
      this.GETM("SEARCH_VENDOR_MASTER", reqData,
       (res) => {
        if(res.data.statusCode === 200){
          this.vendorObj = res.data.data?.data?.[0] || {}
  
          this.checkBankAddress()
          
          if (this.vendorObj.attachments.length >= 1) {
            this.vendorObj.attachments.forEach(
              (e) => {
                if (e.documentType == "NEFT Form") {
                  e.file = e.fileName
                  this.neftAttachment.push(e)
                  }
                if (e.documentType == "KYC/CKYC") {
                  e.file = e.fileName
                  this.kycAttachment.push(e)
                }
  
                if (e.documentType == "OTHERS") {
                  e.file = e.fileName
                  this.otherAttachment.push(e)
                }
                // if(e.documentType == 'Cancelled Cheque'){
                //   e.file=e.fileName
                //   this.cancelledChequeData.push(e)
                //   this.viewDocumentCheque(e.documentId)
                // }
              }
            )
            this.checkTabChanged()
            this.documentTab = "NEFT Form"
          }
          if (this.vendorObj.bank.cancelledCheque.length >= 1) {
            this.cancelledChequeData.push(...this.vendorObj.bank.cancelledCheque)
            this.viewDocumentCheque(
              this.vendorObj.bank.cancelledCheque[0].documentId
            )
          }
          this.user_Id_Show = [...this.user_Id_Show, this.vendorObj.user.userId]
          this.userId = this.vendorObj.user.userId
          this.ifsc = this.vendorObj.bank.ifscCode
          this.ifscData()
          this.vendorNames()
        }
      },() => {},'Home')
      if (this.$route.params.isView) {
        this.heading = "View"
        this.isView = true
      } else {
        this.heading = "Modify"
        this.isView = false
        this.isModify = true
      }
    },
    create_vendor() {
      this.POSTM(
        "CREATE_VENDOR_MASTER",
        this.vendorObj,
        (res) => {
          this.hideProgress()
          if (res.data && res.data.statusCode == 200) {
            this.showAlert(res?.data?.msg || "Success", "", "", () => {
              this.cancel(1)
            })
            // this.isView=true
          } else if (
            res.data &&
            res.data.statusCode == 400 &&
            res.data.duplicate
          ) {
            this.showConfirm(
              "Confirmation",
              res.data.msg,
              "Yes",
              "No",
              (Yes) => {
                this.vendorObj.proceedDuplicate = res.data.duplicate
              }
            )
          } else {
            this.showAlert(res.data.msg)
            this.hideProgress()
          }
        },
        (err) => {
          console.log(err)
          this.hideProgress()
          let msg =
            err?.response?.data?.msg || "Unable to submit Vendor Master data."
          this.showAlert(msg)
        },
        true
      )
    },
    update_vendor() {
      this.vendorObj.approveRequest = true
      delete this.vendorObj.createdBy
      delete this.vendorObj.createdById
      delete this.vendorObj.createdAt
      delete this.vendorObj.updatedAt
      delete this.vendorObj.__v
      this.PUTM(
        "UPDATE_VENDOR_MASTER",
        this.vendorObj._id,
        this.vendorObj,
        (res) => {
          this.hideProgress()
          if (res.data && res.data.statusCode == 200) {
            this.showAlert(res?.data?.msg || "Success", "", "", () => {
              this.cancel(1)
            })
            this.isView = true
          } else if (
            res.data &&
            res.data.statusCode == 400 &&
            res.data.duplicate
          ) {
            this.vendorObj.proceedDuplicate = res.data.duplicate
          } else {
            this.showAlert(res.data.msg)
            this.hideProgress()
          }
        },
        (err) => {
          console.log(err)
          this.hideProgress()
          let msg =
            err?.response?.data?.msg || "Unable to submit Vendor Master data."
          this.showAlert(msg)
        },
        true
      )
    },
    onSubmit() {
      if (!this.$refs.create_Vendor_Master.validate()) {
        return this.showAlert("Please fill mandatory fields.")
      } else {
        this.convertObjToString()
        if (this.vendorObj._id) {
          this.update_vendor()
        } else {
          this.create_vendor()
        }
      }
    },
  },
  computed: {
    computedPAN() {
      this.vendorObj.panNumber = this.vendorObj.panNumber
        ? this.vendorObj.panNumber.toUpperCase()
        : ""
      return this.vendorObj.panNumber
    },
    computedGST() {
      this.vendorObj.gstinNumber = this.vendorObj.gstinNumber
        ? this.vendorObj.gstinNumber.toUpperCase()
        : ""
      return this.vendorObj.gstinNumber
    },
  },
}
</script>
<style scoped>
.iconstyle {
  background: #f7f7f7 0% 0% no-repeat padding-box;
  border: 0.5px solid #c1c8cc !important;
  color: #fff;
  width: 29px;
  margin-left: -0.1em;
  height: 38px;
  color: #6b6b6b;
}
.blueiconstyle {
  width: 38px;
  height: 38px;
  background: #01bbd9 0% 0% no-repeat padding-box;
  margin-right: -0.5em;
  color: #f7f7f7;
}
.claimProcessingTabsActive {
  background-color: #1ebbd9;
  color: #fff !important;
}
.unActive {
  border: 1px solid #ccc;
  margin: 0px 5px;
}
.margincls {
  font-size: 12px;
  margin-left: -12px;
}
.dlt_text {
  color: rgb(145, 145, 145);
}
.neftStyle {
  height: 150px;
  width: 350px;
  border: 3px dashed #ccc;
  /* padding-top: 40px;
        padding-left: 16%; */
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
.tabelwidth {
  width: 500px;
  margin: 0px 150px;
}
</style>
