<template>
  <div>
    <v-container fluid>
      <v-form ref="form">
      <v-layout row>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              External Pathology Lab Details Available ( Y/ N)
            </v-text>
            <v-select
              :items="['Yes', 'No']"
              label="Select"
              v-model="externalPathoLogyLabDetails.isPathologyDetailsAvelable"
              :rules="[required()]"
              solo
              item-text="state"
              item-value="state"
              class="rounded-0"
              @change="toggleAllFields(externalPathoLogyLabDetails.isPathologyDetailsAvelable)"
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Pathology Lab Name
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="externalPathoLogyLabDetails.pathoLogyDetails.labName"
              maxlength="100"
              counter="100"
              class="rounded-0"
              aria-required="true"
              :disabled="disableAllFields"
              :rules="!disableAllFields && [required()]"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Pathology Lab Address
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="externalPathoLogyLabDetails.pathoLogyDetails.address"
              maxlength="200"
              counter="200"
              class="rounded-0"
              aria-required="true"
              :disabled="disableAllFields"
              :rules="!disableAllFields && [required()]"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Pathology State
            </v-text>
            <v-text-field
              label="Enter..."
              v-model="externalPathoLogyLabDetails.pathoLogyDetails.state"
              :rules="!disableAllFields && [required()]"
              solo
              item-text="state"
              :disabled="disableAllFields"
              item-value="state"
              class="rounded-0"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Pathology City
            </v-text>
            <v-text-field
              label="Enter..."
              v-model="externalPathoLogyLabDetails.pathoLogyDetails.city"
              :rules="!disableAllFields && [required()]"
              :disabled="disableAllFields"
              solo
              item-text="state"
              item-value="state"
              class="rounded-0"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Pathology PIN Code
            </v-text>
            <v-autocomplete
              :items="pincodes"
              label="Enter to search..."
              v-model="pinNumber"
              :rules="!disableAllFields && [required()]"
              solo
              maxlength="6"
              :search-input.sync="searchPinCodes"
              item-text="locpincode"
              :disabled="disableAllFields"
              item-value="locpincode"
              class="rounded-0"
              @change="pinCodeChangeHandler"
            >
            </v-autocomplete>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Pathologist Name
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="externalPathoLogyLabDetails.pathoLogyDetails.pathologyName"
              maxlength="100"
              counter="100"
              class="rounded-0"
              :disabled="disableAllFields"
              aria-required="true"
              :rules="!disableAllFields && [required()]"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Pathologist Registration No
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="externalPathoLogyLabDetails.pathoLogyDetails.registrationNumber"
              maxlength="20"
              counter="20"
              class="rounded-0"
              aria-required="true"
              :disabled="disableAllFields"
              :rules="!disableAllFields && [required()]"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Pathologist State Registered
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="externalPathoLogyLabDetails.pathoLogyDetails.registerdState"
              maxlength="20"
              counter="20"
              class="rounded-0"
              :disabled="disableAllFields"
              aria-required="true"
              :rules="!disableAllFields && [required()]"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md5 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Remarks
            </v-text>
            <v-textarea
              solo
              label="Enter..."
              v-model="externalPathoLogyLabDetails.remarks"
              maxlength="5000"
              counter="5000"
              class="rounded-0"
              rows="3"
              aria-required="true"
              :rules="[required()]"
            >
            </v-textarea>
          </v-layout>
        </v-flex>
      </v-layout>
      </v-form>
    </v-container>
    <InvestigationFooterCard 
      :tabno="sno" :formdata="getFormData" 
      ref="footerCardRef"
      @validateTabForms="validateTabForms"
      :nonMandatoryFields="nonMandatoryFields"
      :inputFieldCorrectlyFilled="true"
      :enableFieldObject="getEnableFieldObject"
      :componentName="'externalPathoLogyLabDetails'"
      :siuWorkItemId="siuWorkItemId"
      :tabFormDataId="tabFormDataId"
    />
  </div>
</template>
<script>
import InvestigationFooterCard from './InvestigationFooterCard.vue';
import {getPrePopulatedData,getAllPinCodes} from '../apiservices/Investigation';
export default {
    name: 'ExternalPharmacy',
    data() {
        return {
            pinNumber: null,
            searchPinCodes:'',
            pincodes: [],
            disableAllFields:false,
            sno: 7,
            lossStatesList: ["A"],
            nonMandatoryFields: [],
            enableFieldsArr: [],
            externalPathoLogyLabDetails: {
              isTabSelected: true,
              isPathologyDetailsAvelable: "",
              pathoLogyDetails: {
                labName: '',
                address: '',
                pinCode: '',
                city: '',
                state: '',
                pathologyName:'',
                registrationNumber: '',
                registerdState: '',
              },
            },
            validationmessage: "This field is required",
            remarks: "",
            rules: {
                mandatoryrule: [(v) => !!v || this.validationmessage],
            },
            required(){
              return (v) => (v && v.length > 0) || "This Field is Required";
            }
        }
    },
    components: {
      InvestigationFooterCard 
    },
    props: ['prepoulatedData', "siuWorkItemId", "tabFormDataId"],
    created() {
      let allKeys = Object.keys(this.prepoulatedData);
      this.disableAllFields = this.prepoulatedData.isPathologyDetailsAvelable == "No" ? true : false;
      this.pincodes = [this.prepoulatedData?.pathoLogyDetails?.pinCode]
      this.pinNumber = this.prepoulatedData?.pathoLogyDetails?.pinCode
      allKeys.forEach(key => {
        this.externalPathoLogyLabDetails[key] = this.prepoulatedData[key]
      });

    },
 computed: {
    emailRules2(v) {
      if (v.length !== 0) {
        console.log("testing", /^[0-9 ]+$/.test(v));
        if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,6})+$/.test(v)) {
          return "E-mail must be valid";
        }
      }
    },
    getFormData() {
      return {externalPathoLogyLabDetails:this.$data.externalPathoLogyLabDetails};
    },
    getEnableFieldObject() {
      return this.enableFieldsArr = this.isPathologyDetailsAvelable !== 'No' ? {} : {remarks: this.remarks};
    }
  },
  watch:{
      async searchPinCodes(value) {
        if(value === null || value == undefined || value == '' || value.length < 3){
            this.pincodes = []
            // this.externalPathoLogyLabDetails.pathoLogyDetails.state = '';
            // this.externalPathoLogyLabDetails.pathoLogyDetails.city = '';
        }
      if (value && value.length > 2) {
        this.pincodes = await getAllPinCodes(value.split(" - ")[0])
      }
      }
    },
//   mounted() {
//     let found = this.items.find(el => el.sno == 20);
//     if(!found){
//       this.items.push({sno: 20, name: "View / Upload Documents"});
//     }
//     console.log("INDEX NUMBER 20", found);
    
//   },
  methods: {
    validateTabForms(){
      if(!this.$refs.form.validate()){
        this.$refs.form.validate() 
        this.showToast("Please fill all mandatory fields", this.TOST().WARNING);
        return
      }else{
        this.$refs.footerCardRef.interimSave();
      }
    },
    pinCodeChangeHandler(v) {
      const foundData = this.pincodes.find((item) => item.pinCode === v.split(" - ")[0]);
      this.externalPathoLogyLabDetails.pathoLogyDetails.state = foundData.stateName;
      this.externalPathoLogyLabDetails.pathoLogyDetails.city = foundData.cityDistrictName;
      this.externalPathoLogyLabDetails.pathoLogyDetails.pinCode = this.pinNumber ? Number(this.pinNumber.split(' - ')[0]) : null;
    },
    toggleAllFields(val){
      if(val == 'No'){
        this.disableAllFields = true;
        this.clearAllFields();
      }else{    
        this.disableAllFields = false;  
      }
    },
    clearAllFields(){
      this.externalPathoLogyLabDetails.pathoLogyDetails.labName = '';
      this.externalPathoLogyLabDetails.pathoLogyDetails.pathologyName = '';
      this.externalPathoLogyLabDetails.pathoLogyDetails.pinCode = null;
      this.externalPathoLogyLabDetails.pathoLogyDetails.registrationNumber = '';
      this.externalPathoLogyLabDetails.pathoLogyDetails.registerdState = '';
      this.externalPathoLogyLabDetails.pathoLogyDetails.city = '';
      this.externalPathoLogyLabDetails.pathoLogyDetails.state = '';
      this.externalPathoLogyLabDetails.pathoLogyDetails.address = '';
      this.pinNumber = null;
    },
    dragleave(event) {
      this.visualEffect = false
    },
    browseCancel() {
      //to select attachments
    //   document.getElementById("cancelchequeupload").click()
    },
    dragover(event) {
      event.preventDefault()
      this.visualEffect = true
    },
    drop(event) {
      event.preventDefault()
    //   this.selectFile() // Trigger the onChange event manually
      this.visualEffect = false
    },
    computedDate(name) {
      return this[name] ? moment(this[name]).format("DD/MM/YYYY") : "";
    },
    goToPreviousTab() {
      this.sno--;
      let btnNumber = "myBtn" + this.sno;
      const elem = this.$refs[btnNumber];
      elem[0].$el.click();
    },
    goToNextTab() {
      this.sno++;
      console.log('this', this.$children.$options);
      let btnNumber = "myBtn" + this.sno;
      const elem = this.$refs[btnNumber];
      elem[0].$el.click();
    },
    switchTabs(sno, name) {
      console.log('sno', sno, name);
      this.sno = sno;
      this.tabName = name;
    },
    submit() {
      this.isAlertView = true;
    },
    accept() {
      this.isAlertView = true;
    },
    saveData() {},
    alphaNumeric(fieldName, name, mandatory=true) {
      console.log('fieldName', fieldName, name);
      // if (fieldName?.length > 0) {
      //   return (v) => /^[a-zA-Z0-9]+$/.test(v) || "Enter valid " + name;
      // }
    },
    numericOnly() {
      return (v) => /^[0-9 ]+$/.test(v) || `Only Numbers are allowed.`;
    },
    onSubmit() {},
    browse() {},
    selectFile() {},
  },
}
</script>
