export default {
  claimicon:false,
  sideitems:[],
  current_page:null,
  reboot_screens:false,
  a_c_t:null,
  screens_obj:{},
  special_permissions:{sp:[]},
  role_data:{},
  user_data:{},
// environment definitation 
  lob_type: null,
  createtarrifDetails: {},
  //
  registeredClaimId:'',
  claimMembersearchdata:{},
  // id of claimdetals submitted cashless/reimbursememnt tabs
  submittedClaimId:'',
  currentClaimType:'',
  didMembersearch:false,
  //
  headerName: '',
  snackbar: {
    visible: false,
    text: '',
    color: 'red',
    timeout: 5000,
    multiline: false,
  },
  tariff_view:false,
  loader: {
    title: '',
    bool: ''
  },
  screens:'',
  NetworkSearch:{
  },
  emp_init_docs:[],
  empanelProviderData:{},
  selected_current_role:{},
  assignRole:[],
  prvdrData:{},
  selectTarrifData:{},
  home_providerdata:{},
  createProductCode:'',
  productItemData:{},
  btnName:{text:'',visible:false},
  plcyfrPrdctbtnName:{txt:'',vsble:false, plcy_id:null},
  userCreateEditDta:{editUser:false, user_id:null,ntid:null},
  sendProductData:'',
  name:{},
  employeeName:'',
  plcyIdForPlan:{
    text:'',
    visible:false, 
    plcy_id:null,
    plcy_No:null,
    plan_No:null,
    plan_Typ:null,
    retailPlan_Id:null,
    retailPlan_productCode:null,
    retailPlan_productDescription:null,
    retailPlan_productId:null
  
  },
  retailPlanPdt:{text:'',visible:false,plan_Typ:null,retailPlan_Id:null},
  grpPlcyDtafrCln:{},
  empanelmentTabStatus:{
      mainScreenStatus: false,
      ownershipAndContactStatus: false,
      contactAndFacilityStatus: false,
      specialityStatus: false,
      qualityAndTeachingStatus: false,
      labAndRadiologyStatus: false,
      staffStatus: false,
      facilitiesStatus: false,
      paymentStatus: false,
      providerReviewStatus: false,
      approvalAndContractStatus: false,
  },
  createNetworkData:{},
  updateNetworkData:{},
  productDate:{
    fromDate:null,
    toDate:null,
    policyFromDate:null,
    policyToDate:null
  
  },
  alphaNumeric:'',
  pageName: "",
  menuBarSelectedName: "",
  SearchByItemId: '',
 
  claimMainscreen_id: "",
  userRole: '',
  name: '',
  links: {},
  login_id:'',
  token: null,
  showProgress: false,
  showProgressMessage: "",
  fnloPageEditCheck: "",

  policySearchData: {},
  policyproposalNo: "",
  mainClaimNumberObj: {},
  toast: {
    //toast
    showToast: false,
    toastMessage: "",
  },
  featureNumberObj:"",
  Auth:null,
  is_refreshing:false,
  refreshCALL:undefined,
  otherLOBData: {},
  currentPageData: {},
  investigationFormData: {},
  parentData: {},
}



// showAlertObj: {
  //   showAlert: false,
  //   showAlertMessage: "",
  //   showcancel: false,
  //   alertfunction: "",
  //   failurealertfunction: "",
  //   successAlertButtonName: "",
  //   failureAlertButtonName: ""
  // }
  // showConfirm: false,
  // confirmMessage: "",
  // confirmTitle: "",
  // successbutton: "",
  // failurebutton: "",
  // callfunction: "",
  // callfailurefunction: "",
