<template>
    <v-app>
       <!-- <NetworkSearchVue @clear-network='clearname'>

       </NetworkSearchVue> -->
    <v-form v-model="valid" ref="perCreationform" >
        <v-card tile class="pt-2 mt-4">
               

                <v-layout color="#FFFFFF" class=" mt-1 ml-3" style="background-color: #FFFFFF;">
                <v-flex xs3>
                    <v-layout column class="cust_pr mx-3 ">
                         <v-card class="rounded-0 pl-4 mt-10" dense  style="height: 36px; display: flex;align-items: center;">
                                <v-checkbox
                                    dense
                                    color="#23b1a9"
                                    label="Active"
                                    class="errorMsg"
                                    v-model="activeCheckBox"
                                    :rules="[rules.requiredOn]">
                                  
                                </v-checkbox>
                            </v-card>
                        
                    </v-layout>
                </v-flex>
                    <!-- <v-divider  class="ma-2" vertical ></v-divider> -->
                
                
                    <v-flex xs3>
                        <v-layout column class="cust_pdPerSearch mx-2 my-2">
                            <v-text class="font-weight-bold cstm-clr-pd">
                                Permission ID
                            </v-text>
                            <v-text-field
                                solo
                                dense
                                label="Enter..."
                                v-model="PermissionID"
                                disabled
                                background-color="#f7f7f7"
                                class="rounded-0"
                                
                                >
                            </v-text-field>
                        </v-layout>
                    </v-flex>

                    <v-flex xs3>
                        <v-layout column class=" mx-2 my-2 cust_pdPerSearch">
                            <v-text class="font-weight-bold cstm-clr-pd">
                                Permission Name
                            </v-text>
                            <v-text-field
                                solo
                                dense
                                label="Enter..."
                                v-model="PermissionName"
                                :rules="[rules.alphaRules(PermissionName)]"
                                class="rounded-0"
                                maxlength=50
                                >
                            </v-text-field>
                        </v-layout>
                    </v-flex>

                    <v-flex xs3>
                        <v-layout column class=" mx-2 my-2 cust_pdPerSearch">
                            <v-text class="font-weight-bold cstm-clr-pd">
                                LOB*
                            </v-text>
                            <v-select
                                :items="itemsOfLOB"
                                label="Select"
                                v-model="lob"
                                solo
                                :disabled="lobdisble"
                                dense
                                @change="changeLob"
                                :rules="[rules.required]"
                                class="rounded-0"
                                >
                            </v-select>
                        </v-layout>
                    </v-flex>
                
                    <v-flex xs3> </v-flex>
                </v-layout>
               

            <v-card class=" cust-bnkAcc-tbl " style="background-color:#eeeeee" tile>
            <v-layout mt-3>
            <v-flex xs4 mt-3>
                <p class="font-weight-bold cstm-clr-pr-hd" style="text-align: center;">
                    SCREENS AVAILABLE
                </p>
            </v-flex>

            <v-icon large>swap_horiz</v-icon>

            <v-flex xs4 mt-3 >
                <p class="font-weight-bold cstm-clr-pr-hd" style="text-align: center;">
                    SELECTED ACTION
                </p>
            </v-flex>
            
            <v-icon large>swap_horiz</v-icon>

            <v-flex xs4 mt-3>
                <p class="font-weight-bold cstm-clr-pr-hd" style="text-align: center;">
                    SELECTED LIST OF PERMISSIONS
                </p>
            </v-flex>
            </v-layout>
            </v-card>
           
				<v-layout  style="padding:15px;">
                    <!-- PERMISSION NAME AVAILABLE -->
                    <!-- <v-flex xs4 ma-2>
                        <v-card class="mb-3 mt-3 cust-bnkAcc-tbl" style="border:0.5px solid #C1C8CC;min-height:200px" tile>
                            <v-flex  class="pa-2">
                                <v-layout column class="  mx-3 cust_pr">
                                    
                                    <v-autocomplete
                                        :items="selectScreenAvilableItems"
                                        item-text="type"
                                        item-value="_id"
                                        v-model="selectScreenAvilable"
                                        label="Select"
                                        return-object
                                        required
                                        solo
                                        dense
                                        multiple
                                        class="rounded-0 hideIcon"
                                        :rules="[rules.requiredOn]"
                                        @change="itemSelectedOnClick"
                                    >
                                    <v-icon slot="append" 
                                        dense 
                                        style="background-color:#F7F7F7; height:38px; width:40px;cursor: pointer;">
                                            search
                                    </v-icon>
                                    </v-autocomplete> 
                                </v-layout>
                            </v-flex> -->

                            <!-- <v-flex class="pa-2" v-show="!editMode">
                                <v-layout column class="mx-3 cust_pr chgColor" justify-center > -->
                                
                                    <!-- <span class="font-weight-bold cstm-clr-pr" style="color:#FFFFFF" > </span> -->
                                     <!-- <v-card class="rounded-0 pl-4 " v-if="hideSelectAll == true" dense  style="height: 40px; display: flex;align-items: center;">
                                        <v-checkbox class="ma-0 pa-0" v-model="allSelected"  hide-details  label="Select All" @click="selectAll" @change="selectAllChange">
                                        </v-checkbox>
                                     </v-card>
                                    <v-card v-for="(screen,index) in screenAvilable" :key="index" class="rounded-0 pl-4 mt-2" dense  style="height: 40px; display: flex;align-items: center;">
                                        <v-checkbox class="ma-0 pa-0" v-model="screenValues" hide-details :value="screen"  :label="screen" @change="changedValues($event,screen)">
                                        </v-checkbox>
                                     </v-card>
                                     
                                </v-layout>
                            </v-flex>

                            <v-flex class="pa-2" v-show="editMode">
                                <v-layout column class="mx-3 cust_pr chgColor" justify-center > -->
                                
                                    <!-- <span class="font-weight-bold cstm-clr-pr" style="color:#FFFFFF" > </span> -->
                                     <!-- <v-card class="rounded-0 pl-4 " v-if="updateHideSelectAll == !true" dense  style="height: 40px; display: flex;align-items: center;">
                                        <v-checkbox class="ma-0 pa-0" v-model="updateAllSelected"  hide-details  label="Select All" @click="updateSelectAll" @change="updateSelectAllChange">
                                        </v-checkbox>
                                     </v-card> -->
                                     
                                    <!-- <v-card v-for="(screen,index) in upadteScreenAvilable" :key="index" class="rounded-0 pl-4 mt-2" dense  style="height: 40px; display: flex;align-items: center;">
                                        <v-checkbox class="ma-0 pa-0" v-model="updateScreenValues" hide-details :value="screen"  :label="screen" @change="updateChangedValues($event,screen)">
                                        </v-checkbox>
                                     </v-card>
                                     
                                </v-layout>
                            </v-flex>


                        </v-card>
                    </v-flex> -->


                     <!-- PERMISSION NAME AVAILABLE -->
                    <v-flex xs4 ma-2>
                        <!-- <v-card tile class="pa-1">
                            <v-layout> -->
                                
                                    <v-card style="min-height:340px;max-height:340px; overflow-y:scroll"  tile class="pa-4">
                                        <v-text-field
                                            label="Search"
                                            solo
                                            class="rounded-0 mb-3 chgHeight"
                                            hide-details
                                            v-model="searchScreens"
                                        >
                                            <div slot="append" style="background-color:#23B1A9;padding:5.5px;cursor:pointer;position:absolute;top:0px;right:0px">
                                                <v-icon dark>search</v-icon>
                                            </div>
                                        </v-text-field>

                                        <v-layout column class="mx-3 cust_pr chgColor" justify-center >
                                        
                                            <!-- <span class="font-weight-bold cstm-clr-pr" style="color:#FFFFFF" > </span> -->
                                            <v-card v-if="screenAvilable.length" class="rounded-0 pl-4 "  dense  style="height: 40px; display: flex;align-items: center;">
                                               <!-- @click="selectAll" @change="selectAllChange" -->
                                                <v-checkbox @click="selectall_boxone" class="ma-0 pa-0" v-model="allSelected"  hide-details  label="Select All" >
                                                </v-checkbox>
                                            </v-card>

                                            <v-card v-for="(screen,index) in screenAvilableComputed" :key="index" class="rounded-0 pl-4 mt-2" dense  style="height: 40px; display: flex;align-items: center;">
                                                <v-checkbox class="ma-0 pa-0" v-model="screen.sel" hide-details :value="screen.sel"
                                                :label="screen.name" @click="changedValues(screen.name)">
                                                </v-checkbox>
                                            </v-card>
                                            
                                        </v-layout> 
                                    </v-card>
                                
                            <!-- </v-layout>
                        </v-card> -->
                    </v-flex>
                    <!--SELECTED ACTION -->
                    <v-flex xs4 ma-2>
                        <v-card class="mb-2 mt-3 cust-bnkAcc-tbl" style="border:0.5px solid #C1C8CC;min-height:200px" tile>

                            <v-card class="mb-3 cust-bnkAcc-tbl pa-5" style="border:0.1px solid #C1C8CC;" tile>
                            
                            </v-card>


                            <v-flex pa-1 class="mx-3 cust_pr mb-5" >     
                               <v-card v-if="selectedScreens.length" >
                                    <v-card v-for="(screen,index) in selectedScreens" :key="index" class="rounded-0 pl-4 mt-2" dense  style="height: 40px; display: flex;align-items: center;  pointer-events: none">
                                            <v-card-text class="pa-0 font-weight-bold"  style="color:black; width: 200px; font-size:13px;">
                                                {{ screen.name }} 
                                            </v-card-text>
                                            <!-- selectedScreens -->
                                             <!-- :key="index" :id="index"  :itemid="index"     :item-key="index"  -->
                                            <v-checkbox class="ma-0 pa-0 ml-2"      label="View"
                                            
                                             v-model="screen.v"  hide-details
                                             style="pointer-events: visible;" @change="viewCheckBox(1,screen, index)"></v-checkbox>

                                         <!-- :id="screen+2" :key="screen+2"  :itemid="screen+2"  :item-key="screen+2"  :value="screen"   -->

                                            <v-checkbox class="ma-0 pa-0 ml-2"
                                             label="Edit" 
                                          
                                             v-model="screen.e"
                                             hide-details style="pointer-events: visible;"
                                             @change="viewCheckBox(2,screen, index)"></v-checkbox>
                                            
                                        </v-card> 
                                    </v-card>

                                    <!-- <v-card v-if="upadteselectedScreens.length">
                                        <v-card v-for="(screen,index) in upadteselectedScreens" :key="index" class="rounded-0 pl-4 mt-2" dense  style="height: 40px; display: flex;align-items: center;  pointer-events: none">
                                            <v-card-text class="pa-0 font-weight-bold"  style="color:black; width: 200px; font-size:13px;">
                                                {{ screen.screenName }} 
                                            </v-card-text>
                                            <v-checkbox class="ma-0 pa-0 ml-2" label="View" v-model="screenView = screen.isView === true ? true : false"   hide-details style="pointer-events: visible;" @change="upviewCheckBox($event,screen)"></v-checkbox>
                                            <v-checkbox class="ma-0 pa-0 ml-2" label="Edit" v-model="screenEdit = screen.isEdit === true ? true : false"    hide-details style="pointer-events: visible;" @change="upeditCheckBox($event,screen)"></v-checkbox>
                                        </v-card> 
                                    </v-card> -->
                            </v-flex>

                          

                        </v-card>
                    </v-flex>

                    <!-- SELECTED LIST OF PERMISSIONS -->
                    <v-flex xs4 ma-2>

                        <v-card class="mb-2 mt-3 cust-bnkAcc-tbl" style="border:0.5px solid #C1C8CC;min-height:200px" tile>
                            
                            <v-layout  style="justify-center;">
                                <v-flex xs6>
                                    <v-card class=" cust-bnkAcc-tbl " style="background-color:#23B1A1;" tile>
                                        
                                        <v-card-text class="font-weight-bold pa-2 rounded-0" style="color:#ffffff;text-align: center;; justify-center; font-size:13px">
                                            Screen Name
                                        </v-card-text>
                                    </v-card>
                                </v-flex>

                                <v-flex xs6>
                                    <v-card class=" cust-bnkAcc-tbl " style="background-color:#23B1A9;" tile>
                                        <v-card-text class="font-weight-bold pa-2  rounded-0"  style="color:#ffffff;text-align: center;; justify-center; font-size:13px">
                                            Action Available
                                        </v-card-text>
                                    </v-card>
                                </v-flex>
                            </v-layout>

                            <v-flex pa-1 class="mx-3 cust_pr">                          
                                <v-card class="rounded-0 pl-4 my-2" dense v-for="(screen,index) in finallist" :key="index"  style="height: 40px; display: flex;align-items: center;">
                                        <v-card-text class="pa-0 font-weight-bold" style="color:black; width: 200px; font-size:13px">
                                            {{screen.screenName}}
                                        </v-card-text>
                                            <v-checkbox class="ma-0 pa-0 ml-2" v-show="screen.isView === true" label='View' 
                                            v-model="permissionListView"  disabled  style="margin-top: 20px !important;margin-left: 78px !important;"></v-checkbox>
                                            <v-checkbox class="ma-0 pa-0 ml-2" v-show="screen.isEdit === true" label='Edit' 
                                            v-model="permissionListEdit"  disabled  style="margin-top: 20px !important;margin-left: 78px !important;"></v-checkbox>
                                    </v-card>
                            </v-flex>
                        </v-card>
                    </v-flex>
                    
                  
				</v-layout>
            </v-card>


            
    </v-form>

        <div>
            <v-card class="mb-2 mt-3 cust-bnkAcc-tbl" style="border:0.5px solid #C1C8CC;" tile>
				<v-layout  style="padding:15px;background-color:#F7F7F7;align-items:center; justify-center; justify-content: flex-end;">
                    <v-btn dark  tile color="#23B1A9" class="clearbtn text-capitalize px-3 mx-3"  small @click="saveDetails" v-show="toggleSaveBtn"><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider>Save</v-btn>
                    <v-btn dark  tile color="#23B1A9" class="savebtn text-capitalize px-3 mx-3"   small @click="submitDetails" v-show="toggleSubmitBtn"><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider>Submit</v-btn>
                    <v-btn dark  tile color="#E46A25" class="cancelbtn text-capitalize px-3 mx-3" small @click="$router.go(-1)"><v-icon dark small>cancel</v-icon> <v-divider vertical class="mx-2"></v-divider> Close</v-btn>
				</v-layout>
            </v-card>         
        </div>


        
    </v-app>
</template>
<script>
export default {


    created() {

        this.$store.commit("SAVE_PAGE_HEADER", "Permission Creation");
        
        this.token=this.$store.getters.successToken

        if(Object.keys(this.$route.params).length != 0){
            console.log("object from route is present for edit",this.$route.params);
            this.updateCreation(this.$route.params.item.allData);
            this.toggleSaveBtn = true
            this.toggleSubmitBtn = false
            this.lobdisble = true
        }else{
            this.lobdisble = false
            this.toggleSaveBtn = false
            this.toggleSubmitBtn = true
            console.log("object from route is not present");
        }
        // this.updateID= this.$store.getters.fetchupdateNetworkData.data.data._id
        
        // console.log("networkCreatedbtn value--->>>", this.$store.getters.fetchupdateNetworkData.data.data.networkCreated)
        // this.networkCreatedbtn=this.$store.getters.fetchupdateNetworkData.data.data.networkCreated
      

        
        this.GET(3,'line-of-business/get-line-of-business-list', (res,error)=>{
            
            if(!error){
                console.log("response from api is for LOB");
                // this.itemsOfLOB=res.data.data[0].lineOfBusiness
            }else{
                console.log("error",error.response.data);
            }
        });


        //  console.log("this.getEditDetails(this.$router.history.current.name) from Mixin===>",this.getEditDetails(this.$router.history.current.name))
        //  if(this.getEditDetails(this.$router.history.current.name)){
        //     this.disableAll=this.getEditDetails(this.$router.history.current.name).isEdit;
        // }else{
        //     this.disableAll=false
        // }

        //Surendra(21-Sep-2021):LOB List from BE,code starts here
        this.GET(3,'line-of-business/get-line-of-business-list', (res,error)=>{
            if(!error){
                console.log("response from api is",res.data.data[0].lineOfBusiness);
                this.itemsOfLOB=res.data.data[0].lineOfBusiness
            }else{
                console.log("error",error.response.data);
            }
        });
        //Surendra(21-Sep-2021):LOB List from BE,code ends here

    },

    // updated(){
    //     console.log("something is updated");
    //       this.selectScreenAvilableItems = []
       
    // },
    

    data() {
        return {

            rules: {
                 required: value => !!value || 'This Field is Required.',

                 alphaRules: value => {
                        if(value.length>0){
                        return value => /^[a-zA-Z0-9 ]+$/.test(value) || 'Only alphabets are allowed'
                        }
                    },

                requiredOn: value => {
                    if(this.editMode === true){
                    
                    }else{
                        return  !!value || 'This field is required!'
                    }
                },

            },
            lobdisble:false,
            editMode:false,
            upadteScreenAvilable:[],
            // updateScreenValues:[],
            // updateHideSelectAll:false,
            // updateAllSelected:false,

            searchScreens:'',
            newSelectedScreens:[],


            toggleSaveBtn: false,
            toggleSubmitBtn: false,
            activeCheckBox:true,
            screenValues:[],
            PermissionID:'',
            PermissionName:'',
            lob:'',
            allSelected:false,
            hideSelectAll:false,
            itemsOfLOB:[],//'PA','Health','Marine','AIGC','EW'],
            selectScreenAvilableItems:  [],
            screenAvilable:[],
            selectScreenAvilable: '',
            selectedScreens:[],
            selectedListOfPermission:[],
            // actionSelectedView:'',
            // actionSelectedEdit:'',
            listOfPermission:'',
            viewIsSelected:'',
            editIsSelected:'',
            screenView:[],
            screenEdit:true,
            permissionListView:true,
            permissionListEdit:true,
            finalScreenSelected:'',
            actionSelected:false,
            idForupdate : '',
            viewForAction:[],
            editForAction:[],
            upadteselectedScreens:[],
            disableAll:true
            // required(){
            //     return v=>v && v.length>0 || "This Field is Required"
            //     }
        }
    },
    
    methods: {

        setALLis(arris){
            
             var d = 0
                arris.map(e=>{
                        if(e.sel){
                            d++
                        }
                })
                    if(d == arris.length){
                        console.log("select all should be CHECKED", arris)
                        this.allSelected = true
                    }else{
                        this.allSelected = false
                        console.log("select all should be DIS-CHECKED", arris)
                    } 
        },

        selectall_boxone(){

            let newData = [];
            newData=this.searchScreens.length!=0?this.newSelectedScreens:this.screenAvilable;

            if(this.allSelected){
               newData.map(e=>{
                    if(!e.sel){
                        e.sel = true
                        this.selectedScreens.push({name:e.name, v:false, e:false,})
                    } 
                })
            }else{

                for(let i in newData) {
                        newData[i].sel = false;
                        var sposs = this.selectedScreens.findIndex(e=> e.name ==  newData[i].name)
                        if(sposs != -1){
                            this.selectedScreens.splice(sposs, 1);

                            var poss_list = this.selectedListOfPermission.findIndex(e=> e.screenName == newData[i].name)
                        
                            if(poss_list != -1){
                                this.selectedListOfPermission.splice(poss_list,1)
                            }
                        }
  
                }
            
            }
                // this.screenAvilable.map(e=> e.sel = false)
                // this.selectedScreens.push()
             
        
        },

        updateCreation(someItem){
            console.log("this is data from item",someItem._id);
            this.editMode = true
            console.log("this is the item from search page",someItem);
            this.PermissionName = someItem.permissionName
            this.lob = someItem.lineOfBusiness
            this.changeLob(this.lob,someItem.screenForPermission,1);
            this.activeCheckBox = someItem.isActive === 'Active' ? true : false

            // pushing screens in PERMISSION NAME AVAILABLE 
            // this.upadteScreenAvilable = [...someItem.screenForPermission.map(scr => scr.screenName)]
            // this.screenValues = someItem.screenForPermission.map(scr => scr.screenName)
            // console.log("screenValues from updateScreen", this.screenValues);

            // pushing screens in SELECTED ACTION
            // this.upadteselectedScreens = someItem.screenForPermission

            // pushing screens in SELECTED LIST OF PERMISSIONS
            this.selectedListOfPermission = [...someItem.screenForPermission]

            console.log("this are the %c screen available","background-color:red;color:black",this.upadteScreenAvilable,'\n',this.updateScreenValues,'\n',"viewForAction-->",this.selectedScreens);
            console.log(someItem.screenForPermission);
            this.PermissionID = someItem.permissionId
            this.idForupdate = someItem._id
        },

        saveDetails(){
            console.log(this.selectedListOfPermission,"save button is working fine & active staus is ==>",this.selectedScreens);
            // if(this.selectedListOfPermission.length != this.selectedScreens.length){
            if(this.selectedScreens.filter(function(elem){ return !elem.v && !elem.e}).length > 0){
                console.log("Please, select actions for selected screens");
                this.showToast("Please, Select an action for selected screens",this.TOST().WARNING)
                return
            }else{
                console.log("proceed");
            }

            let self=this;
            let finalPermSet = this.selectedListOfPermission.filter(el => {
                return self.selectedScreens.find(element => {
                    return element.name === el.screenName;
                });
            });
            console.log("Final Screenlist = ",finalPermSet)

            let formData = {
                    permissionName: this.PermissionName,
                    lineOfBusiness: this.lob,
                    screenForPermission:[...finalPermSet],
                    isActive: this.activeCheckBox === true ? 'Active' : 'InActive'
                }

                if(this.selectedListOfPermission.length < 1){
                    if(this.selectedScreens < 1){
                         this.showToast("At least 1 screen must be selected",this.TOST().WARNING)
                    }else{

                        this.showToast("Please select action",this.TOST().WARNING)
                    }
                }else{

                    this.PUT(6,'permission/update-permission-details/'+this.idForupdate,formData, (res,error)=>{
                        if(!error){
                            this.$router.push('/permissionSearch')
                            this.showToast(res.data.msg,this.TOST().SUCCESS);
                            console.log("response from api is",res.data.msg);
                            // this.itemsOfLOB=res.data.data[0].lineOfBusiness
                            
                        }else{
                            console.log("error",error.response.data);
                            this.showToast(error.response.data.msg,this.TOST().ERROR);
                        }
                    });
                }
                
        },

        selectAll(){
            this.screenValues = []
            let newData = []
            newData=this.searchScreens.length!=0?this.newSelectedScreens:this.screenAvilable
                
            if(this.allSelected){
                for(let screen in newData){
                    this.screenValues.push(newData[screen])
                }
                this.selectedScreens = this.screenValues
            }   
        },
        // updateSelectAll(){
        //     this.updateScreenValues = []
        //     if(this.updateAllSelected){
        //         for(let screen in this.upadteScreenAvilable){
        //             this.updateScreenValues.push(this.upadteScreenAvilable[screen])
        //         }
        //             console.log("update Selected",this.upadteScreenAvilable,this.updateScreenValues);
        //             if(this.updateScreenValues.length > 0){
        //                 this.upadteselectedScreens = []
        //                 // this.upadteScreenAvilable = []
        //             } 
        //              this.selectedScreens = [...this.updateScreenValues]
        //             console.log("update Selected",this.selectedScreens);
        //     }
        // },
        selectAllChange(chvalue){
                  
            if(chvalue){
               console.log("value of selectAll is ",chvalue); 
            }else{
                console.log("this is false value");
                this.selectedScreens = []
                this.selectedListOfPermission = []
            }
        },

        // updateSelectAllChange(chvalue){
        //     if(chvalue){
        //        console.log("value of updateselectAll is ",chvalue); 
        //     }else{
        //         console.log("this is false value");
        //         this.upadteselectedScreens = []
        //         this.selectedScreens = []
        //         this.selectedListOfPermission = []
        //     }
        // },


        submitDetails(){
            // console.log(JSON.stringify(this.selectedListOfPermission),"submit button is working fine & active staus is ==>",JSON.stringify(this.selectedScreens));
            console.log(this.selectedListOfPermission,"selectedListOfPermission < | selectedScreens > ",this.selectedScreens);
            if(this.selectedListOfPermission.length != this.selectedScreens.length){
                console.log("Please, select actions for selected screens");
                this.showToast("Please, Select an action for selected screens",this.TOST().WARNING)
                return
            }else{
                console.log("proceed");
            }

           
            if(this.$refs.perCreationform.validate()){
                console.log("this is  valid");
                let formData = {
                    permissionName: this.PermissionName,
                    lineOfBusiness: this.lob,
                    screenForPermission:[...this.selectedListOfPermission],
                    isActive: this.activeCheckBox === true ? 'Active' : 'InActive'
                }

                console.log('to hit api formData on creation ', formData);
             
                if(this.selectedListOfPermission.length < 1){
                    if(this.selectedScreens < 1){
                         this.showToast("At least 1 screen must be selected",this.TOST().WARNING)
                    }else{

                        this.showToast("Please select action",this.TOST().WARNING)
                    }
                }else{

                    this.POST(6,'permission/create-new-permission',formData, (res,error)=>{
                        if(!error){
                            this.$router.push('/permissionSearch')
                            this.showToast(res.data.msg,this.TOST().SUCCESS);
                            console.log("response from api is",res.data.msg);
                            // this.itemsOfLOB=res.data.data[0].lineOfBusiness
                        }else{
                            console.log("error",error.response.data);
                            this.showToast(error.response.data.msg,this.TOST().ERROR);
                        }
                    });
                }
                
               
            }else{
                 this.showToast("Please enter all fields",this.TOST().WARNING)
            }
        },

        itemSelectedOnClick(value){
            console.log("item was clicked",value);
            console.log("updatedScreens ares ",this.upadteselectedScreens);
                if(value.length > 0){
                    console.log("value of array",value[value.length - 1]);
                    let clickedScreen = value[value.length - 1] 
                
                    let dupScreen = this.upadteselectedScreens.find(upScr => upScr.screenName === clickedScreen)
                    if(dupScreen){
                        this.showToast(dupScreen.screenName+" is alredy present",this.TOST().WARNING);
                        console.log("this is duplicate screen",dupScreen);
                    }else{
                        if(value.length > 1){
                            this.hideSelectAll = true
                        }else{
                            this.hideSelectAll = false
                        }
                        this.screenAvilable = value
                        this.upadteScreenAvilable.push(value[value.length - 1])
                        // this.selectedScreens = value
                        console.log("this are the screen seleted",this.screenAvilable, "and the length is ",this.selectedScreens.length);
                }
                }
                // if(dupScreen){
                //     return this.showToast("Screen is alredy present",this.TOST(),WARNING);
                // }
            
           
           
        },
        
    viewCheckBox(e,val,indexis){
           console.log("value is for selected screen from view is",val)
            // viewCheckBox(1,screen, index)

            if(e == 1){
                if(this.selectedScreens[indexis].v){
                    this.selectedScreens[indexis].e = false 
                }
            }else{
                this.selectedScreens[indexis].e ? this.selectedScreens[indexis].v = false : null
            }

        var viewis = this.selectedScreens[indexis].v;
        var editis = this.selectedScreens[indexis].e;
        var nameis = this.selectedScreens[indexis].name;

        let poss = this.selectedListOfPermission.findIndex(sel_lis_per => sel_lis_per.screenName == nameis)

        console.log("poss is ", poss)
     
        if(this.selectedScreens[indexis].v && !this.selectedScreens[indexis].e){

           if(poss != -1){
               this.selectedListOfPermission[poss].isView = true 
               this.selectedListOfPermission[poss].isEdit = false
           }else{
               this.selectedListOfPermission.push({screenName:nameis,isEdit:editis,isView:viewis}); 
           }

        }else if(!this.selectedScreens[indexis].v && this.selectedScreens[indexis].e){

            if(poss != -1){
               this.selectedListOfPermission[poss].isView = false 
               this.selectedListOfPermission[poss].isEdit = true
            }else{
               this.selectedListOfPermission.push({screenName:nameis,isEdit:editis,isView:viewis}); 
            }

        }else{
            console.log("from else")
             this.selectedListOfPermission.splice(poss, 1)
        }

        // if(this.selectedScreens[indexis].v){
        //    var poss = this.selectedListOfPermission.findIndex(sel_lis_per => sel_lis_per.name == nameis)
        //    if(poss != -1){
        //         var to_removed = this.selectedListOfPermission[poss];
        //         console.log("what is the element to be removed -> ",to_removed)
        //         if(to_removed.e){
        //              this.selectedListOfPermission.splice();
        //         }
        //    }  
        //    this.selectedListOfPermission.push({screenName:nameis,isEdit:editis,isView:viewis});
        // }else{
        //    this.selectedListOfPermission.push({screenName:nameis,isEdit:editis,isView:viewis});
        // }

                // this.selectedScreens[indexis].

            // if(e !== null){
            //     console.log("value is",val,"\n");
            //     let sameName = this.viewForAction.find(viewName => viewName === e)
            //     if(sameName !== e){
            //         this.selectedListOfPermission.push({'screenName':e,'isEdit':false,'isView':true})
            //     }
            // }else{
            //     console.log("value is from null ",val);
            //     let viewIndex = this.selectedListOfPermission.findIndex(view => view.screenName === val)
            //     this.selectedListOfPermission.splice(viewIndex,1)
            //     console.log("index is",viewIndex);
            // }
        },
        

        editCheckBox(e,val){
            if(e !== null){
                console.log("value is",val,"\n");
                let sameName = this.viewForAction.find(viewName => viewName === e)
                if(sameName !== e){

                    this.selectedListOfPermission.push({'screenName':e,'isEdit':true,'isView':false})
                }
            }else{
                console.log("value is from null ",val);
                let viewIndex = this.selectedListOfPermission.findIndex(view => view.screenName === val)
                this.selectedListOfPermission.splice(viewIndex,1)
                console.log("index is",viewIndex);
            }
        },

        upviewCheckBox(e,val){
            console.log("value is for selected screen from view is",val,e)
           
            if(e === true){
                console.log("value is for true",val.screenName);
                // let sameName = this.viewForAction.find(viewName => viewName === e)
                // if(sameName !== e){
                    this.selectedListOfPermission.push({'screenName':val.screenName,'isEdit':false,'isView':true})
                // }
            }else{
                console.log("value is from false ",val.screenName);
                let viewIndex = this.selectedListOfPermission.findIndex(view => view.screenName === val.screenName)
                this.selectedListOfPermission.splice(viewIndex,1)
                console.log("index is",viewIndex);
            }
        },

        upeditCheckBox(e,val){
           console.log("value is for selected screen from view is",val,e)
            if(e === true){
                console.log("value is for true",val.screenName);
                // let sameName = this.viewForAction.find(viewName => viewName === e)
                // if(sameName !== e){
                    this.selectedListOfPermission.push({'screenName':val.screenName,'isEdit':true,'isView':false})
                // }
            }else{
                console.log("value is from false ",val.screenName);
                let viewIndex = this.selectedListOfPermission.findIndex(view => view.screenName === val.screenName)
                this.selectedListOfPermission.splice(viewIndex,1)
                console.log("index is",viewIndex);
            }
        },

        changedValues(val){
            console.log("this is the value for change",val);
            
            console.log("this are arrays",this.selectedScreens);

             let dupSelectedScr =  this.selectedScreens.findIndex(selItem => selItem.name === val)
              console.log( this.selectedListOfPermission, "index from selectedScreen is",dupSelectedScr);

                if(dupSelectedScr == -1){
                    this.selectedScreens.push({name:val, v:false, e:false,}) 
                }else{   
                    this.selectedScreens.splice(dupSelectedScr, 1);
                      
                    var poss_list = this.selectedListOfPermission.findIndex(e=> e.screenName ==  val)

                    if(poss_list != -1){
                        this.selectedListOfPermission.splice(poss_list,1)
                    }
                }

             var arris = this.searchScreens.length!=0? this.newSelectedScreens:this.screenAvilable;
            this.setALLis(arris);
            // this.selectedListOfPermission.filter(e=>{

                //    console.log(this.selectedListOfPermission,"<- this are selected screen array data",this.selectedScreens);

            //    })

                // if(val === this.selectedScreens[dupSelectedScr]){
                //     console.log("selected screen is", this.selectedScreens,"screen to delete",val);
                //     // this.screenAvilable.splice(dupSelectedScr,1)
                //     this.selectedScreens.splice(dupSelectedScr,1)
                //     // this.selectedListOfPermission.splice(dupSelectedScr,1)
                // }else{
                //     this.selectedScreens.push(val)
                // }
            
            
            // let indFound1 = this.screenAvilable.findIndex(scrAv => scrAv.screenName === val)
            // let indFound2 = this.upadteselectedScreens.findIndex(sel => sel.screenName === val)
            // let indFound3 = this.selectedListOfPermission.findIndex(selPer => selPer.screenName === val)
            // this.screenAvilable.splice(indFound1,1)
            // this.upadteselectedScreens.splice(indFound2,1)
            // this.selectedListOfPermission.splice(indFound3,1)
            // // this.selectedScreens.push(val)
            // // let indFound2 = this.selectedListOfPermission.find(sel => val.map( va => sel === va))
            // // this.selectedScreens.splice(indFound,1)
            // // this.selectedListOfPermission.splice(indFound2,1)
            // console.log("this is indFound",indFound1,indFound2,indFound3);
            // this.selectedScreens = [...val]
        },

        updateChangedValues(e,val){
            console.log("upadted screen values",e,val);
            console.log("this is the value for change",e,val,"selectAll is=>",this.updateAllSelected);
            console.log("this are arrays",this.upadteselectedScreens,"\n",val);
               
                console.log("selected screens are", this.upadteselectedScreens.map(scr => scr.screenName),"screen to delete",val);
                let dupSelectedScrArr = []
                dupSelectedScrArr =  this.upadteselectedScreens.map(selItem => selItem.screenName );
              console.log("array for duplicate screen",dupSelectedScrArr);
              let indexToDel =  dupSelectedScrArr.findIndex(delItem => delItem === val)
              console.log("need to delete this index",indexToDel);
              if(indexToDel >= 0){
                  this.upadteselectedScreens.splice(indexToDel,1)
                //   this.selectedListOfPermission.splice(indexToDel,1)
              }else{
                    
                    console.log("present selectedScreens are",this.selectedScreens);
                    if(val === this.selectedScreens[0]){
                        console.log("i was in updatechangeValues if",this.selectedScreens[0]);
                        this.selectedScreens.splice(0,1)
                        // let dupIndxForSelecPer1 = this.selectedListOfPermission.findIndex(indSelPer => indSelPer.screenName === val) 
                        //             console.log("this is permission from selectedPermission",dupIndxForSelecPer1,val);
                        //                 if(dupIndxForSelecPer1 >= 0){
                        //                     this.selectedListOfPermission.splice(dupIndxForSelecPer1,1)
                        //                 }else{
                        //                     return
                        //                 }
                        return
                    }else{
                        console.log("i was in updatechangeValues else",val);
                       let dupIndxForSelecScr = this.selectedScreens.findIndex(indSel => indSel === val) 
                       if(val === this.selectedScreens[dupIndxForSelecScr]){
                           this.selectedScreens.splice(dupIndxForSelecScr,1)
                        //    let dupIndxForSelecPer = this.selectedListOfPermission.findIndex(indSelPer => indSelPer.screenName === val) 
                        //             console.log("this is permission from selectedPermission",dupIndxForSelecPer,val);
                        //                 if(dupIndxForSelecPer >= 0){
                        //                     this.selectedListOfPermission.splice(dupIndxForSelecPer,1)
                        //                 }else{
                        //                     return
                        //                 }

                       }else{

                           this.selectedScreens.push(val) 
                       }
                    }
              }
            // console.log("value of dupValueOfSelcetor",dupValueOfSelcetor);
            //     if(val === dupSelectedScr.screenName ){
            //         // this.screenAvilable.splice(dupSelectedScr,1)
            //         let dupSelectedIndx =  this.upadteselectedScreens.findIndex(delItem => delItem.screenName === val)
            //         this.upadteselectedScreens.splice(dupSelectedIndx,1)
            //         // this.selectedListOfPermission.splice(dupSelectedScr,1)
            //     }else{
            //         this.selectedScreens.push(val)
            //     }
        },

        changeLob(value, tableobject, ident){
            
            this.screenAvilable = []
            this.upadteScreenAvilable = []
            this.upadteselectedScreens = []
            this.selectedListOfPermission = []
            this.selectedScreens = []
            this.selectScreenAvilable = ''
            // this.selectScreenAvilableItems = []

            //  console.log("starting array____AFTER EMPTYYYY",this.selectScreenAvilableItems);
             this.GET(6,'permission-screens/get-permission-screens', (res,error)=>{
            if(!error){
                console.log("response from api is for permissionScreens",res.data.data);
                // console.log("inside array",this.selectScreenAvilableItems);
                    let screen_res = res.data.data
                    for(let z=0; z<screen_res.length; z++){
                        if(value == screen_res[z].lob){
                            // this.selectScreenAvilableItems =screen_res[z].screensList
                            this.screenAvilable = [...new Set(screen_res[z].screensList)];
                        }
                    }

                //   value == 'Health' ? this.screenAvilable.push('Employee Master','Financial Authority Limit Master'):null

                // return  // old response permission screens GET before common modules merge
                // // this.selectScreenAvilableItems=res.data.data[0].permissionScreens
               
                // if(value === 'Health'){
                //             console.log("health",res.data.data[0].permissionScreens[0].Health);
                //             // this.selectScreenAvilableItems = []
                //             this.selectScreenAvilableItems = res.data.data[0].permissionScreens[0].Health
                //             this.screenAvilable = res.data.data[0].permissionScreens[0].Health
                // }else if(value === 'Marine'){
                //             // this.selectScreenAvilableItems.splice(0,14)
                //             // this.selectScreenAvilableItems = []
                //             this.selectScreenAvilableItems = res.data.data[0].permissionScreens[0].Marine
                //             this.screenAvilable = res.data.data[0].permissionScreens[0].Marine
                // }else if(value === 'PA'){
                //             // this.selectScreenAvilableItems = []
                //             this.selectScreenAvilableItems = res.data.data[0].permissionScreens[0].PA
                //             this.screenAvilable = res.data.data[0].permissionScreens[0].PA
                // }else if(value === 'AIGC'){
                //             // this.selectScreenAvilableItems = []
                //             this.selectScreenAvilableItems = res.data.data[0].permissionScreens[0].AIGC
                //             this.screenAvilable = res.data.data[0].permissionScreens[0].AIGC
                // }

                this.screenAvilable = this.screenAvilable.map(e=>{
                    return {name:e, sel:false}
                })

                // re-bind
                if(ident == 1){
                       this.screenAvilable.map(e=>{
                           tableobject.map(tab=>{
                               if(tab.screenName == e.name){
                                   e.sel = true;
                                  this.selectedScreens.push({v:tab.isView, e:tab.isEdit, name:tab.screenName})
                               }
                                
                           })
                           
                        })
                }

                console.log(" this.screenAvilable ", this.screenAvilable);
               

                
            }else{
                console.log("error",error.response.data);
            }
        });
        
            }


    },

    computed:{

        finallist(){
            
            return this.selectedListOfPermission 
        
        },

        screenAvilableComputed(){

            return this.screenAvilable.filter(item => {
                // console.log("items of screen",item)
                if(!this.searchScreens) return this.screenAvilable;
                    return (  
                    item.name.toLowerCase().includes(this.searchScreens.toLowerCase())
                    // item.BankAcNo.includes(this.search)
                );
            });

        }
        
    }, 

    watch:{
        // selectedListOfPermission(val){
        //     console.log("this is listofPermission",val);
        // }
        screenAvilableComputed(val){
            if(this.searchScreens.length != 0){
                console.log("value of %c screenAvilableComputed is","background-color:#aabbee;color:black",val);
                this.newSelectedScreens = val
                if(!this.editMode){
                    this.screenValues = []
                }
            }else{
                this.newSelectedScreens = []
                console.log("%c value of screenAvilableComputed is","background-color:#aabbee;color:black",val);
            }

            var arris = this.searchScreens.length!=0? this.newSelectedScreens:this.screenAvilable;
            this.setALLis(arris);
            // var newlist = []
            // this.screenAvilable.filter(item => {
            //  newlist =  item.toLowerCase().includes(val.toLowerCase())
            // })
            // console.log("new list is ",newlist);
        }
   
    }
    
}

</script>
<style scoped>

.cust-rmvpd-pr .v-text-field.v-text-field--enclosed>.v-input__control>.v-input__slot {
    padding: 0px !important;
    
}

.chgColor .v-input .v-label {
    font-size: 13px !important;
    color: black !important;
    font-weight: bold;
}
    
.hideIcon .v-input__control .v-input__slot .v-select__slot .v-input__append-inner .v-input__icon--append{
    display: none;
}

.hideIcon .v-input__control .v-input__slot{
    padding-right: 0 !important
}

.errorMsg .v-input__control .v-messages .v-messages__wrapper .v-messages__message{
    padding-top: 10px;
}

.chgHeight .v-input__control {
    min-height: 35px !important;
    padding: 0;
}


</style>