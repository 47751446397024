<template>
     <!-- <v-flex xs3>
          <v-layout column class="ml-3 mt-4" >
            <p class="claimlabel mb-2">Text Field<span class="mandatorycolor"> *</span></p>
            <v-text-field
            :type="alphaNumeric"
            :rules="[mandatoryrule()]"
            hint="This field counts characters"
            solo
            counter="10"
            clearable
            prepend-inner-icon="mdi-text"
            dense
            v-model="text">
            </v-text-field>
          </v-layout>
     </v-flex> -->
    <v-app>
    <!-- <div id="app">
        <v-app id="inspire">
            <v-data-table
            id="virtual-scroll-table"
            v-scroll:#virtual-scroll-table="onScroll"
            dense :headers="headers" :items="dessertsLimited"
            item-key="name"
            disable-pagination
            hide-default-footer>
            <template v-if="start > 0">
                <tr>
                <td
                    :colspan="headers.length"
                    :style="'padding-top:'+startHeight+'px'">
                </td>
                </tr>
            </template>
            <template
                v-if="start + perPage < this.desserts.length">
                <tr>
                <td
                    :colspan="headers.length"
                    :style="'padding-top:'+endHeight+'px'">
                </td>
                </tr>
            </template>
            </v-data-table>
        </v-app>
    </div> -->
    <div>
        <input 
          type="file" 
          @change="excelExport"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"/>
    </div>
    <!-- <v-flex id="claimListTable" class="claimtablelist">
      <template>
        <v-data-table
            :items="claimTableList"
            hide-default-footer
            class="vDataTableStyling"
            id="virtual-scroll-table"
            v-scroll:#virtual-scroll-table="onScroll"
            dense :headers="claimColumnHeader"
            item-key="name"
            disable-pagination>
          <template
            v-for="header in claimColumnHeader.filter((header) =>
              header.hasOwnProperty('formatter'))"
            v-slot:[`item.${header.value}`]="{ value }">
            {{ header.formatter(value) }}
          </template>
          <template v-slot:item="{ item, index }" v-if="start + perPage < this.claimTableList.length">
            <tr style="cursor: pointer" @click="goToClaimMainPage(index)">
              <td class="colborder" >{{ item.isTempClaim != true ? item.mainClaimNumber : "" }} </td>
              <td class="colborder">{{ item.isTempClaim != true ? item.featureNumber : "" }}</td>
              <td class="colborder">{{ item.claimStatus }}</td>
              <td class="colborder">{{ item.examiner }}</td>
              <td class="colborder">{{ item.insuredName }}</td>
              <td class="colborder">{{ item.policyNumber }}</td>
              <td class="colborder">{{item.dateReported ? new Date(item.dateReported).toLocaleDateString("en-IN"): ""}}</td>
              <td class="colborder">{{item.dateOfLoss ? new Date(item.dateOfLoss).toLocaleDateString("en-IN"): ""}} </td>
              <td class="colborder">{{ item.externalAdjInvestigator }}</td>
              <td class="colborder">{{ item.outStandingReserve }}</td>
              <td class="colborder">{{ item.indemnityStatus }}</td>
              <td class="colborder">{{ item.settlingOffice }}</td>
              <td class="colborder">{{ item.productName }}</td>
              <td class="colborder">{{ item.coverageDescription }}</td>
              <td class="colborder">{{ item.dateOfLastActivityOnClaim }}</td>
              <td class="colborder">{{ item.estimatedLossAmountInINR }}</td>
              <td class="colborder">{{ item.assignedBy }}</td>
             
            </tr>
          </template>
        </v-data-table>
      </template>

      <div class="text-center graybackground pdct-stUp-bnkAcc-pgn">
        <v-row>
          <v-col cols="6"></v-col>
          <v-col cols="6">
            <v-row
              class="paginationrow">
              <v-spacer></v-spacer>
              <div style="margin-right: 10px">
                <span class="orangetext">
                  Showing {{ pageStartIndex }}-{{ pageEndIndex }}
                </span>
                <span> out of {{ totalListCount }} records </span>
              </div>
              
            </v-row>
          </v-col>
        </v-row>
        <br />
      </div>
    </v-flex> -->

    </v-app>
</template>

<script>
import moment from 'moment'
export default {
    data(){
        return{
           
            desserts: Array.from({ length: 5000 }).map((v, k) => ({
                name: `#${k}`,
                calories: 518,
                fat: 26.0,
                carbs: 65,
                protein: 7,
                iron: '6%',
            })),
            headers: [
            {
                text: 'Dessert (100g serving)',
                align: 'start',
                sortable: false,
                value: 'name',
            },
            { text: 'Calories', value: 'calories' },
            { text: 'Fat (g)', value: 'fat' },
            { text: 'Carbs (g)', value: 'carbs' },
            { text: 'Protein (g)', value: 'protein' },
            { text: 'Iron (%)', value: 'iron' },
            ],
            start: 0,
            timeout: null,
            rowHeight: 24,
            perPage: 25,

            // ------------

            claimTableList:[],
            page            :   1,
            pageStartIndex  :   1,
            pageRowCount  :   10,
            totalListCount  :   0,
            singleExpand    :   false,
            selectedCheckboxes        :   [],
            contentWidth       : 1800,
            overlay         :   false,
            absolute        :   true,
            providerName  :   "",
            myClaimCount  :    0,
            myTeamClaimCount:   0,
            myApprovalCount :   0,
            claimColumnHeader:  [
                 {text: 'Claim No.', align: 'start',value: 'mainClaimNumber', class:"headercolor"},
                 {text: 'Feature No.', align: 'start',value: 'featureNumber', sortable:false},
                 {text: 'Claim Status', align: 'start',value: 'claimStatus', sortable:false},
                 {text: 'Examiner', align: 'start',value: 'examiner', sortable:false},
                 {text: 'Insured Name', align: 'start',value: 'insuredName', sortable:false},
                 {text: 'Policy Number', align: 'start',value: 'policyNumber', sortable:false},
            ]
        }
    },

    methods:{
        onScroll(e) {
            // debounce if scrolling fast
            this.timeout && clearTimeout(this.timeout);
        
            this.timeout = setTimeout(() => {
                const { scrollTop } = e.target;
                const rows = Math.ceil(scrollTop / this.rowHeight);

                this.start = rows + this.perPage > this.claimTableList.length ?
                this.claimTableList.length - this.perPage: rows;

                this.$nextTick(() => {
                e.target.scrollTop = scrollTop;
                });
            }, 20);
        },

        goToNextPage(newPageNum){
            
            // this.claimTableList =   this.claimList.slice(this.pageStartIndex,this.pageStartIndex + this.pageRowCount)
            console.log("Event - ",event,this.page);
            this.showProgress();
            let reqData =   "perPage="+this.pageRowCount+"&pageNo="+newPageNum;
            this.GET("WORKITEM_SEARCH",reqData,(res)=>{
                this.hideProgress()
                console.log("WORKITEM_SEARCH - ",res);
                if(res.data && res.data.data && res.data.data.length > 0){
                    this.claimList      =   res.data.data;
                    this.totalListCount =   res.data.total;
                    this.myClaimCount   =   res.data.total;
                    this.claimTableList =   this.claimList.length > 0 ? this.claimList.slice(0,this.pageRowCount) : [];
                    this.pageStartIndex =   ((newPageNum - 1) * this.pageRowCount);
                    console.log("clainTableList - ",this.claimTableList);
                     this.hideProgress()
                }else if(res.data && res.data.data && res.data.data.data && res.data.data.data.length > 0){
                    this.claimList      =   res.data.data.data;
                    this.totalListCount =   res.data.data.total;
                    this.myClaimCount   =   res.data.data.total;
                    this.claimTableList =   this.claimList.length > 0 ? this.claimList.slice(0,this.pageRowCount) : [];
                    this.pageStartIndex =   newPageNum == 1 ? 1 : ((newPageNum - 1) * this.pageRowCount) + 1;
                    console.log("clainTableList - ",this.claimTableList);
                     this.hideProgress()
                }else{
                    this.claimList      =   [];
                    this.totalListCount =   0;
                    this.myClaimCount   =   0;
                    this.claimTableList =   [];
                    this.pageStartIndex =   0;
                }
                
            },(err)=>{
                    this.hideProgress()
                    console.log("WORKITEM_SEARCH Err -",err);
                    this.claimList      =   [];
                    this.totalListCount =   0;
                    this.myClaimCount   =   0;
                    this.claimTableList =   [];
                    this.pageStartIndex =   0; 
                    this.showAlert(err.response.data.msg)
               
            });
        },

        excelExport(event) {
          var input = event.target;
          var reader = new FileReader();
          reader.onload = () => {
            var fileData = reader.result;
            var wb = XLSX.read(fileData, {type : 'binary'});
            wb.SheetNames.forEach((sheetName) => {
              var rowObj =XLSX.utils.sheet_to_json(wb.Sheets[sheetName]);	        
              this.excelData = JSON.stringify(rowObj)
            })
          };
          reader.readAsBinaryString(input.files[0]);
        }
      
    },


    computed:{
        dessertsLimited() {
            return this.desserts.slice(this.start, this.perPage+this.start);
        },
        startHeight() {
            return this.start * this.rowHeight - 32;
        },
        endHeight() {
            return this.rowHeight * (this.desserts.length - this.start);
        },
    },

    created(){

    },

    mounted(){
        this.pageStartIndex =   0;
        this.claimTableList =   this.claimList.slice(0,this.pageRowCount);
        this.goToNextPage(1);
    },
}
</script>


<style scoped>
        #virtual-scroll-table {
        max-height: 400px;
        overflow: auto;
        }
</style>