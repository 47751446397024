<template>
    <v-app>
        <v-layout style="background-color:#F7F7F7" column class="" >
            <v-card class="mx-3 mb-3 mt-3" color="" style="" tile>
                <v-form v-model="valid" @submit.prevent="srchWrkItms()" ref="form">
                    <v-layout color="" class="ma-2" > 
                        <v-flex xs3>
                            <v-layout column class=" mx-2 mt-3 cust_pd">
                                <p class="font-weight-bold cstm-clr-psap">
                                    Work Item Id
                                </p>
                                <!-- :rules="[required()]" -->
                                <v-text-field
                                    solo
                                    label="Enter..."
                                    v-model="workItemId"
                                    
                                    class="rounded-0">
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3>
                            <v-layout column class="cust_pd mx-2 mt-3">
                                <p class="font-weight-bold cstm-clr-psap">
                                External Reference No.
                                </p>
                                <v-text-field
                                    solo
                                    label="Enter..."
                                    v-model="productCode"
                                    class="rounded-0">
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                        
                    </v-layout>
                </v-form>
            </v-card>

            <div color="" class="mx-3 mb-3 pa-2 py-4" style="border:0.5px solid #C1C8CC; display:flex; justify-content:flex-end;background-color:#F7F7F7;">
                <v-btn tile dark  style="font-size:12px;background-color:#E46A25" small  class="text-capitalize px-3 mr-1" @click="srchWrkItms()"><v-icon dark small >search </v-icon> <v-divider vertical class="mx-2"></v-divider>Search</v-btn>
                <v-btn tile dark  style="font-size:12px;background-color:#E46A25" small  class="text-capitalize px-3 mx-2" @click="cnclSrchWrkItms()"><v-icon dark small >cancel</v-icon > <v-divider vertical class="mx-2"></v-divider>Cancel</v-btn>
            </div>

            <v-card class="mx-3 mb-3" tile>
                    <v-data-table
                    :headers="headers"
                    :items="wrkItmDtaLst"
                    :page.sync="page"
                    :items-per-page="itemsPerPage"
                    hide-default-footer
                    dense
                    @page-count="pageCount = $event"
                >

                <template v-slot:item.itemId="{ item }">
                    <!-- to="/CreateEditProduct" -->
                    <div @click="wrkitmidClick(item.docId)" class="text-decoration-underline" style="color:#1976d2;cursor:pointer">{{ item.itemId | checkdata }}</div>
                </template>
                <template v-slot:item.code="{ item }">
                    <div >
                        <v-tooltip right >
                            <template v-slot:activator="{ on }">
                                <div v-on="on">
                                    {{ item.code| checkdata |truncate(16, '...')}}
                                </div>
                            </template>
                                <span>{{item.code}}</span>
                            
                        </v-tooltip>
                    </div>
                </template>
                <template v-slot:item.planName="{ item }">
                    <div >{{ item.planName | checkdata}}</div>
                </template>
               <template v-slot:item.productDescription="{ item }">
                    <!-- <div >{{ item.productDescription| checkdata|truncate(16, '...') }}</div> -->
                    <div >
                        <v-tooltip right >
                            <template v-slot:activator="{ on }">
                                <div v-on="on">
                                    {{ item.productDescription| checkdata |truncate(16, '...')}}
                                </div>
                            </template>
                                <span>{{item.productDescription}}</span>
                            
                        </v-tooltip>
                    </div>
                </template>
                <template v-slot:item.externalRefNo="{ item }">
                    <div >{{ item.externalRefNo | checkdata}}</div>
                </template>
                <template v-slot:item.assignedOn={item}> 
                            <div>
                                {{item.assignedOn|formatDate}}
                            </div>  
                        </template>
                <!-- <template v-slot:item.assignedOn="{ item }">
                    <div >{{ item.assignedOn |formatDate}}</div>
                </template> -->
                <template v-slot:item.assignToName="{ item }">
                    <div >{{ item.assignToName| checkdata }}</div>
                </template>
                <template v-slot:item.status="{ item }">
                    <div >{{ item.status| checkdata }}</div>
                </template>
                
                    <!-- <template v-slot:item.ApproverDecision >
                        <v-layout class="py-1">
                            <v-btn tile dark  style="font-size:12px" small color="#23B1A9" class="text-capitalize mr-2 px-3 "><v-icon dark small >checkdata</v-icon > <v-divider vertical class="mx-2"></v-divider>Accept</v-btn>
                            <v-btn tile dark style="font-size:12px" small color="#D1121B" class="text-capitalize  mr-2 px-3"><v-icon dark small >cancel</v-icon > <v-divider vertical class="mx-2"></v-divider>Reject</v-btn>
                        </v-layout>
                    </template>
                    <template v-slot:item.Remarks="{ item }" >
                            <v-layout justify-space-between align-center>
                                {{item.Remarks|truncate(10, '...')}} 
                            <v-btn tile color="#1E9690" @click="editRmrk(item,item.Remarks)" icon small dark  >
                                <div class="mt-1">
                                    <img src="../assets/edtRmrk.png" style="width: 22px;height: 22px"/>
                                </div>
                            </v-btn>   
                            </v-layout>
                    </template> -->
                </v-data-table>
                <v-divider></v-divider>
                
                <v-layout row justify-end align-center class="ma-2" v-if="wrkItmDtaLst.length!=0">
                    <v-text class="mr-1 font-weight-bold" style="color:#3C3D3D; font-size:13px;">
                        <span class="" style="color:#E46A25;">Showing {{fval}}-{{sval}} </span>out of {{totalLen}} records
                    </v-text>
                    <v-divider  class="mx-2" vertical ></v-divider>
                    <v-pagination
                        class="pdct-stUp-bnkAcc-pgn"
                        v-model="page"
                        @input="nextPageData(page)"
                        color="#152F38"
                        flat
                        :length="pagelength"
                    ></v-pagination>
                </v-layout>
            </v-card>

            <v-layout>
                    <v-dialog v-model="RmrkDilg" persistent max-width="480px" tile>
                        <v-card color="#F7F7F7" class="pb-2" tile>
                            <v-card tile>
                                <v-layout justify-space-between  class="" style="flex:1;background-ox-shadow: 0px 3px 5px #00000029;">
                                    
                                        <v-card-title class="pa-0 pl-3 text-capitalize font-weight-bold" style="color:#10242B;font-size:13px">Remarks</v-card-title>
                                       
                                    <div style="border-left:1px solid #C1C8CC;" @click="close_dwnld_dlg()">
                                        <v-icon style="cursor: pointer;" class="pa-2" >close</v-icon>
                                    </div>
                                </v-layout>
                            </v-card> 
                                <!-- <v-card class="ma-3 pa-2"> -->
                                      <v-textarea
                                        class="mx-3 mt-3"
                                        solo
                                        auto-grow
                                        v-model="rjctRmk"
                                        ></v-textarea>
                                <!-- </v-card> -->
                                <div color="" class="px-2" style="display:flex; justify-content:flex-end">
                                    <v-btn tile dark  style="font-size:12px;background-color:#E46A25" small  class="text-capitalize px-3 "><v-icon dark small >cancel</v-icon > <v-divider vertical class="mx-2"></v-divider>Close</v-btn>
                                    <v-btn tile dark  style="font-size:12px;background-color:#D1121B" small  class="text-capitalize px-3 mx-2"><v-icon dark small >delete </v-icon> <v-divider vertical class="mx-2"></v-divider>Clear</v-btn>
                                    <v-btn tile dark  style="font-size:12px;background-color:#23B1A9" small  class="text-capitalize px-3 mr-2"><v-icon dark small >check_circle</v-icon > <v-divider vertical class="mx-2"></v-divider>Save</v-btn>
                                </div>
                            
                        </v-card>
                    </v-dialog>
                </v-layout>
           
            
         
        </v-layout>
    </v-app>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
export default {


    components:{
        
    },

    created() {
        this.token=this.$store.getters.successToken
        this.$store.commit("SAVE_PAGE_HEADER", "View My Work Items Cases");
        this.wrkitmdta(this.page)
    },
    filters:{

      truncate: function (text, length, suffix) {
          if (text == "" || text == "undefined" || text == null || text == '' || text == 'Invalid Date') {
                return "-"
        
            }else if (text.length > length) {
                return text.substring(0, length) + suffix;
            } else {
                return text;
            }
        },
        checkdata(data){// eslint-disable-line no-mixed-spaces-and-tabs
            // console.log('Validity Data', data);
            if (data == "" || data == "undefined" || data == null || data == '' || data == 'Invalid Date') {
                return "-"
            } else {
                return data;
            }
        },
        formatDate(data){// eslint-disable-line no-mixed-spaces-and-tabs
           // console.log('Validity Data', data);

            if (data == "" || data == "undefined" || data == null || data == '' || data == 'Invalid Date') {
               return "-"
           } else {
            //   var n = new Date(data);
            //   return n.toLocaleString("en-IN")
            return moment(data,'YYYY-MM-DD').format('DD-MM-YYYY')
           }
        },
        
    },

    computed: {


    },

    data() {
        return {
            workItemId:"",
            productCode:"",
            policyNumber:"",
            token:null,
            headers:[
                
                {text: 'Work Item ID', value: 'itemId',sortable: false,divider:true, class:"hdr-txt-clr"},
                {text: 'Product Code', value: 'code',sortable: false,divider:true,class:"hdr-txt-clr"},
                {text: 'Plan Name', value: 'planName',sortable: false,divider:true,class:"hdr-txt-clr"},
                {text: 'Product Description', value: 'productDescription',sortable: false,divider:true,class:"hdr-txt-clr"},
                {text: 'External Ref No.', value: 'externalRefNo',sortable: false,divider:true,class:"hdr-txt-clr"},
                {text: 'Work Item Assigned Date', value: 'assignedOn',sortable: false,divider:true,class:"hdr-txt-clr"},
                {text: 'Assigned to', value: 'assignToName',sortable: false,divider:true,class:"hdr-txt-clr"},
                {text: 'Status', value: 'status',sortable: false,divider:true,class:"hdr-txt-clr"}
               
                

            ],
            GetBnkMstrHstryDta:[
            
                {
                   WorkItemID:"1223",
                    ProductCode:"MEDC",
                    PlanName:"MEDCA",
                    ProductDescription:"Medicare - Plan A",
                    ExternalRefNo:"10029876",
                    WorkItemAssignedDate:"19-12-2019",
                    Status:"Pending for Approval ",
                    Assignedto:"Mark"
                },{
                   WorkItemID:"1223",
                    ProductCode:"MEDC",
                    PlanName:"MEDCA",
                    ProductDescription:"Medicare - Plan A",
                    ExternalRefNo:"10029876",
                    WorkItemAssignedDate:"19-12-2019",
                    Status:"Pending for Approval ",
                    Assignedto:"Mark"
                },{
                   WorkItemID:"1223",
                    ProductCode:"MEDC",
                    PlanName:"MEDCA",
                    ProductDescription:"Medicare - Plan A",
                    ExternalRefNo:"10029876",
                    WorkItemAssignedDate:"19-12-2019",
                    Status:"Pending for Approval ",
                    Assignedto:"Mark"
                },{
                   WorkItemID:"1223",
                    ProductCode:"MEDC",
                    PlanName:"MEDCA",
                    ProductDescription:"Medicare - Plan A",
                    ExternalRefNo:"10029876",
                    WorkItemAssignedDate:"19-12-2019",
                    Status:"Pending for Approval ",
                    Assignedto:"Mark"
                },{
                   WorkItemID:"1223",
                    ProductCode:"MEDC",
                    PlanName:"MEDCA",
                    ProductDescription:"Medicare - Plan A",
                    ExternalRefNo:"10029876",
                    WorkItemAssignedDate:"19-12-2019",
                    Status:"Pending for Approval ",
                    Assignedto:"Mark"
                },{
                   WorkItemID:"1223",
                    ProductCode:"MEDC",
                    PlanName:"MEDCA",
                    ProductDescription:"Medicare - Plan A",
                    ExternalRefNo:"10029876",
                    WorkItemAssignedDate:"19-12-2019",
                    Status:"Pending for Approval ",
                    Assignedto:"Mark"
                },{
                   WorkItemID:"1223",
                    ProductCode:"MEDC",
                    PlanName:"MEDCA",
                    ProductDescription:"Medicare - Plan A",
                    ExternalRefNo:"10029876",
                    WorkItemAssignedDate:"19-12-2019",
                    Status:"Pending for Approval ",
                    Assignedto:"Mark"
                },{
                   WorkItemID:"1223",
                    ProductCode:"MEDC",
                    PlanName:"MEDCA",
                    ProductDescription:"Medicare - Plan A",
                    ExternalRefNo:"10029876",
                    WorkItemAssignedDate:"19-12-2019",
                    Status:"Pending for Approval ",
                    Assignedto:"Mark"
                },{
                   WorkItemID:"1223",
                    ProductCode:"MEDC",
                    PlanName:"MEDCA",
                    ProductDescription:"Medicare - Plan A",
                    ExternalRefNo:"10029876",
                    WorkItemAssignedDate:"19-12-2019",
                    Status:"Pending for Approval ",
                    Assignedto:"Mark"
                },{
                   WorkItemID:"1223",
                    ProductCode:"MEDC",
                    PlanName:"MEDCA",
                    ProductDescription:"Medicare - Plan A",
                    ExternalRefNo:"10029876",
                    WorkItemAssignedDate:"19-12-2019",
                    Status:"Pending for Approval ",
                    Assignedto:"Mark"
                },
            ],

            fromvalue:"",
            toValu:"",
            pageNumber: 1,
            size: 10,
            RmrkDilg:false,
            rjctRmk:"",
            wrkItmDtaLst:[],
              fval:0,
            sval:0,
            totalLen:0,
            swap_final_count:false,
            typesearch:false,

            page: 1,
             pagelength: 0,
           pageCount: 0,
           itemsPerPage: 10,
           valid:false,
           required(){
             return v=>v && v.length>0 || "This Field is Required"
            },
            


        };



    },

    methods: {
        nextPage(page) {
            this.pageNumber = page;
        },
         nextPageData(indata){

            console.log("isSearchIndiv => ", this.typesearch);

            if(this.pagelength == indata){
                this.swap_final_count = true
            }else{
                this.swap_final_count = false
            }

            
            console.log('indiv page no value',this.page)
            
            // this.getProviderALLIndividual(this.indiviSortBy,this.page)

            // this.SearchProviderIndividual(this.page)

            if(this.typesearch==false){
                this.wrkitmdta(this.page)
                  console.log("from if ",this.typesearch)
            }else{
                console.log("from else ",this.typesearch)
                 this.srchWrkItms()
            }

        },

        editRmrk(itm,rmkTxt){
            console.log(rmkTxt)
            console.log(itm)
            this.RmrkDilg=true
            this.rjctRmk=rmkTxt
        },

         close_dwnld_dlg(){
            this.RmrkDilg=false

        },

        wrkitmdta(pageNum){ 
            
            console.log(" page no ---->",pageNum)
              let self=this
              self.wrkItmDtaLst=[]
              axios({
                    method: "get",
                    url:
                    this.API_Service_TASK_MGMNT()+"work-items/search?perPage=10&pageNo="+pageNum+"&itemId="+this.workItemId+"&productCode="+this.productCode+"&policyNumber="+this.policyNumber+"&product=product",
                    
                    headers: {
                        "Content-Type": "application/json",
                        "x-access-token":self.token,
                    }
                })
                .then(function (response) {
                    console.log("Create reporting>>>>>>", response);
                    if(response.data.statusCode===200){
                        self.wrkItmDtaLst= response.data.data.data
                         console.log("self.wrkItmDtaLst>>>>>>", self.wrkItmDtaLst);
                        //  self.showToast(response.data.msg,self.TOST().SUCCESS)
                    }else{
                        self.showToast("Something Went Wrong",self.TOST().ERROR);
                    }
                    // self.resetfrm();

                    // for pagination
                self.pagelength=response.data.data.total_pages
                    console.log(self.page,' <-- page search | search next btn, pg length is ----->',self.pagelength)

                     self.totalLen=response.data.data.total

                      let l = self.wrkItmDtaLst.length
                
                    console.log( 10*self.page,'search skip simulation ');

                      var less_enough = self.totalLen
                        var checkless_init = false
                        if(less_enough < 10){
                            checkless_init = false
                        }else{
                            checkless_init = true
                        }

                        // if less than 15 we have second value same as total value as no pagination will occur
                        if(checkless_init){
                            
                                // checkinit is true means the final count is more than 15 (- 1)
                                var traverse =  10*self.page
                                self.fval = traverse-9
                                if(self.swap_final_count){
                                    self.sval =  self.totalLen
                                }else{
                                    self.sval = traverse
                                }

                        }else if(self.totalLen == 0){
                            
                          self.sval = self.fval = 0
                        
                       }else{
                             self.fval = 1
                             self.sval = self.totalLen
                        }

                    console.log('total search----->',self.totalLen)

                    
                })
                .catch(function (error) {
                    console.log("Create reporting Error>>>>>>", error);
                    self.showToast(error.response.data.msg,self.TOST().ERROR);
                });

        },

        srchWrkItms(){
            // if(!this.$refs.form.validate()){
            //     this.$refs.form.validate()
            //     console.log(this.$refs.form.validate())
            //     // alert("Failed")
            //     this.showToast("Please Fill Required Field",this.TOST().WARNING);
            //     return
            // }
            console.log("product code",this.productCode)
             this.page=1
            
            let apiURL=''
            // if((this.productCode===''||this.productCode===undefined||this.productCode===null) && (this.workItemId===''||this.workItemId===undefined||this.workItemId===null)){
            //     apiURL=this.API_Service_admin()+"work-items/search"
            // }else if((this.productCode===''||this.productCode===undefined||this.productCode===null) && (this.workItemId!==''||this.workItemId!==undefined||this.workItemId!==null)){
            //     apiURL=this.API_Service_admin()+"work-items/search?itemId="+this.workItemId
            // }else if((this.productCode!==''||this.productCode!==undefined||this.productCode!==null) && (this.workItemId===''||this.workItemId===undefined||this.workItemId===null)){
            //     apiURL=this.API_Service_admin()+"work-items/search?externalRefNo="+this.productCode
            // }else{
                apiURL=this.API_Service_TASK_MGMNT()+"work-items/search?perPage=10&pageNo=1&itemId="+this.workItemId+"&externalRefNo="+this.productCode+"&product=product"
            // }


            let self=this
              self.wrkItmDtaLst=[]
              axios({
                    method: "get",
                    url:apiURL,
                    headers: {
                        "Content-Type": "application/json",
                        "x-access-token":self.token,
                    }
                })
                .then(function (response) {
                    console.log("Create reporting>>>>>>", response);
                    if(response.data.statusCode===200){
                        self.wrkItmDtaLst= response.data.data.data
                        console.log("self.wrkItmDtaLst>>>>>>", self.wrkItmDtaLst);
                        self.showToast(response.data.msg,self.TOST().SUCCESS)
                        // for pagination
                self.pagelength=response.data.data.total_pages
                    console.log(self.page,' <-- page search | search next btn, pg length is ----->',self.pagelength)

                     self.totalLen=response.data.data.total

                      let l = self.wrkItmDtaLst.length
                
                    console.log( 10*self.page,'search skip simulation ');

                      var less_enough = self.totalLen
                        var checkless_init = false
                        if(less_enough < 10){
                            checkless_init = false
                        }else{
                            checkless_init = true
                        }

                        // if less than 15 we have second value same as total value as no pagination will occur
                        if(checkless_init){
                            
                                // checkinit is true means the final count is more than 15 (- 1)
                                var traverse =  10*self.page
                                self.fval = traverse-9
                                if(self.swap_final_count){
                                    self.sval =  self.totalLen
                                }else{
                                    self.sval = traverse
                                }

                        }else if(self.totalLen == 0){
                            
                          self.sval = self.fval = 0
                        
                       }else{
                             self.fval = 1
                             self.sval = self.totalLen
                        }

                    console.log('total search----->',self.totalLen)
                    }else{
                        self.showToast("Something Went Wrong",self.TOST().ERROR);
                    }
                    // self.resetfrm();
                    
                })
                .catch(function (error) {
                    console.log("Create reporting Error>>>>>>", error);
                    self.showToast(error.response.data.msg,self.TOST().ERROR);
                });

        },

        cnclSrchWrkItms(){
            if((this.productCode===''||this.productCode===undefined||this.productCode===null) && (this.workItemId===''||this.workItemId===undefined||this.workItemId===null)){
                return
            }else{
                this.wrkitmdta()
                this.productCode=''
                this.workItemId=''
                this.$refs.form.resetValidation()

            }
        },
        wrkitmidClick(id){

            console.log(id," doc id")
            
           
            if(id===""|| id=== null|| id===undefined){
                 console.log(id," doc id")
                return
            }

            this.$store.commit('SAVE_PRODUCT_NAME', id)
            let btnName={
                            text:'View Work Item',
                            visible:false
                        }
            this.$store.commit('SAVE_PRODUCT_BTN_NAME', btnName)
            this.$router.push('/product/createEditProduct')
            // let self=this
            // let apiURL=this.API_Service_admin()+"product/get-product/"+id
            // console.log(apiURL," apiURL")
            // axios({
            //     method: "get",
            //     url:apiURL,
            //     headers: {
            //         "Content-Type": "application/json",
            //         "x-access-token":self.token,
            //     }
            // })
            // .then(function (response) {
            //         console.log("Create reporting>>>>>>", response);
            //         if(response.data.statusCode===200){
            //             let pdtCode= response.data.data
            //             console.log("self.wrkItmDtaLst>>>>>>", pdtCode);
            //             self.$store.commit('SAVE_PRODUCT_NAME', pdtCode.productCode)
            //             self.$store.commit('SAVE_PRODUCT_CODE_ALL_DATA', pdtCode)
            //             let btnName={
            //                 text:'View Work Item',
            //                 visible:false
            //             }
            //             self.$store.commit('SAVE_PRODUCT_BTN_NAME', btnName)
            //             self.$router.push('/CreateEditProduct')
            //         }else{
            //             self.showToast("Something Went Wrong",self.TOST().ERROR);
            //         }
                
                    
            // })
            // .catch(function (error) {
            //     console.log("Create reporting Error>>>>>>", error);
            //     // self.showToast(error.response.data.msg,self.TOST().ERROR);
            // });

        },
        


    },

    watch: {
    
    },
    
    
}

</script>
<style >
.cust_pd .v-text-field .v-input__slot,
.v-text-field--outline .v-input__slot {
    min-height: 42px !important;
    display: flex !important;
    align-items: center !important;
    /* border:1px red solid; */
}

.cust-rmvpd-pd .v-text-field.v-text-field--enclosed>.v-input__control>.v-input__slot {
    padding: 0px !important;;
}

.cstm-clr-psap{
    color:#646666 !important;
    margin-bottom: 5px !important;
    font-size: 13px !important;
}

.cust_pd .v-input .v-label {
    font-size: 13px !important;
}
.cust_pd .v-input {
    font-size: 13px !important;
}
.cust_pd .v-input input {
    font-size: 13px !important;
}

.title-clr-pr{
    color:#10242B;
    margin-bottom: 5px;
}

.lbl-bgclr{
    color:#152F38 !important;

}
.hdr-txt-clr{
    color:#10242B !important;
    padding:0px!important;
    padding: 9px !important;
    padding-left: 14px !important;
}

/* .pdct-stUp-tbl table.v-table thead th{
    border-right:1px solid  #C1C8CC !important;
    background-color:#F3FBFB !important;
    color:#10242B !important;
    font-size:13px !important;
    font-family:Roboto !important;
    font-weight:700 !important;
    padding: 0px!important;
    padding-left: 9px !important;
} */
/* .pdct-stUp-tbl table.v-table thead th:last-child{
    border-right: unset !important;
    background-color:#F7F7F7 !important;
} */
/* .pdct-stUp-tbl table.v-table tbody td,
.pdct-stUp-tbl table.v-table thead tr{
    height: 40px !important;
    padding-left: 12px !important;
}
.pdct-stUp-tbl table.v-table thead tr:last-child{
    height: unset !important;
    
} */

.pdct-stUp-tbl-brdr{
    border:1px solid  #C1C8CC;
}

.pdct-stUp-td-bdrgt {
    border-right:1px solid #C1C8CC;
 }

 .pdct-stUp-bnkAcc-pgn .v-pagination__navigation{
        box-shadow:unset !important;
        border-radius: unset !important;
        background-color:#FFFFFF !important;
        border:1px solid #DAE1E6 !important;
        height: 30px !important;
        width: 30px !important;
    }
    .pdct-stUp-bnkAcc-pgn .v-pagination__navigation--disabled{
        background-color:#DAE1E6 !important;
    }
    .pdct-stUp-bnkAcc-pgn .v-pagination__item{
        box-shadow:unset !important;
        min-width:1.8rem !important;
        height:1.8rem !important;
        border-radius: unset !important;
        border:1px solid #DAE1E6 !important
    }

    .pdct-stUp-tbl .v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
    border: 1px solid  #C1C8CC !important;
}

.pdct-stUp-tbl .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
    border: 1px solid #C1C8CC !important;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
    font-size: 0.84rem !important;
}

</style>