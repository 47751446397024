<template>
<v-app>
        <div style="background-color: #FFFFFF;" column class="py-3">
            <v-form v-model="valid" ref="roleform">
        <v-layout class=" mt-2" >
                <v-flex xs3 class="ml-4">
                    <v-layout column class="cust_pd mx-4 my-2 ">
                        <v-text class="font-weight-bold cstm-clr-pd">
                            Role ID
                        </v-text>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="roleId"
                            :rules="[rules.minId]"
                            class="rounded-0"
                            v-on:keyup.enter="seekSearchData(1)"
                            >
                        </v-text-field>
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class=" mx-4 my-2 cust_pd">
                        <v-text class="font-weight-bold cstm-clr-pd">
                            Role 
                        </v-text>
                        <v-text-field
                            solo
                            label="Enter Role here eg:CG4"
                            v-model="searchWithCg"
                            :rules="spclChar(searchWithCg)"
                            class="rounded-0"
                            v-on:keyup.enter="seekSearchData(1)"
                            >
                        </v-text-field>
                    </v-layout>
                </v-flex>

                 <v-flex xs3>
                    <v-layout column class=" mx-4 my-2 cust_pd">
                        <v-text class="font-weight-bold cstm-clr-pd">
                            Role Description
                        </v-text>
                        <v-text-field
                            solo
                            label="Enter Role Description"
                            v-model="role"
                            :rules="[]"
                            class="rounded-0"
                               v-on:keyup.enter="seekSearchData(1)"
                            >
                        </v-text-field>
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class=" mx-4 my-2 cust_pd">
                        <v-text class="font-weight-bold cstm-clr-pd">
                            LOB
                        </v-text>
                        <v-select
                            :items="GetLOBlist"
                            label="Select LOB"
                            v-model="lob"
                            solo
                            dense
                            class="rounded-0"
                            :rules="[]"
                            
                            >
                        </v-select>
                    </v-layout>
                </v-flex>
            
                <v-flex xs3> </v-flex>
                                            
            </v-layout>

        </v-form>
        
            <v-divider class="mt-2 mb-1"></v-divider>
                
            
            <div class="mx-2 pa-5" style=" display:flex;justify-content:space-between;"  >
                <v-layout style="flex:1" justify-end > 
                    <v-btn dark  tile @click="seekSearchData(1)" color="#E46A25" class="text-capitalize px-3 mx-2" small><v-icon dark small>search</v-icon> <v-divider vertical class="mx-2"></v-divider>Search</v-btn>
                    <v-btn dark  tile @click="cancelbtn()" color="#E46A25" class="text-capitalize px-3 mx-2" small><v-icon dark small>cancel</v-icon> <v-divider vertical class="mx-2"></v-divider>Cancel</v-btn>
                    <v-btn dark tile color="black" class="text-capitalize px-3 mx-2" small @click="clearFields"><v-icon dark small>cancel</v-icon> <v-divider vertical class="mx-2"></v-divider>Clear</v-btn>
                    <v-btn dark tile @click="createNewbtn()" color="black" class="text-capitalize px-3 ml-2" small><v-icon dark small>add_circle</v-icon> <v-divider vertical class="mx-2"></v-divider> Create New Role</v-btn>
                </v-layout>
            </div>

    </div>

    <div v-if="showSearchdata===true">
        <v-card class="rounded-0">
            <v-layout class="headercolor">
                <p class="cardfont" style="font-weight:700;margin-bottom:7px!important;margin-top:10px;padding-left:10px;">Search Results</p>
            </v-layout>
        <v-data-table
                    :headers="headers"
                    :items="GetAllData"
                    hide-default-footer
                    dense
                >

                <template v-slot:[`item.roleId`]="{ item }">
                    <div @click="RoleClick(item)" class="text-decoration-underline" style="color:#1976d2; cursor:pointer; font-size:12px !important">{{ item.roleId | checkdata }}</div>

                </template>
        </v-data-table>
        
    
            <v-divider></v-divider>
                
                <v-layout row justify-end align-center class="ma-2" >
                    <v-text class="mr-1 font-weight-bold" style="color:#3C3D3D; font-size:13px;">
                        <span class="" style="color:#E46A25;font-size:12px;">Showing {{fval}}-{{sval}} </span>out of {{totalLen}} records
                    </v-text>
                    <v-divider  class="mx-2" vertical ></v-divider>
                    <v-pagination
                        class="pdct-stUp-bnkAcc-pgn"
                                v-model="page"
                                color="#152F38"
                                flat
                                @input="nextPageData(page)"
                                :total-visible="9"
                                :length="pagelength"
                    ></v-pagination>
                </v-layout>
            </v-card>
        </div>

            <v-card tile v-if="showrsltnotfound===true" class="my-3 " style="background-color:#F7F7F7;border:1px solid #C1C8CC;flex:1;">
                    <v-layout row justify-start align-center justify-space-between class="mx-">
                            <v-text class="mr-1 mx-5 pa-3 font-weight-bold body-1" style="color:#3C3D3D; font-size:16px !important">
                                NO RESULTS FOUND
                            </v-text>
                    </v-layout>
                        
            </v-card>

    </v-app>
</template>

<script>

import axios from "axios";

  
export default {

    created() {
        
         console.log("Success Token", this.$store.getters.successToken)
         this.token=this.$store.getters.successToken
         this.$store.commit("SAVE_PAGE_HEADER", "Role Search");

         this.getAllRole(null,this.page)
         this.GetLOBlistData()

        // console.log("this.getEditDetails(this.$router.history.current.name) from Mixin===>",this.getEditDetails(this.$router.history.current.name).isEdit)
        // if(this.getEditDetails(this.$router.history.current.name)){
        //     this.disableAll=this.getEditDetails(this.$router.history.current.name).isEdit
            
        // }else{
        //     this.disableAll=false
        // }

          
    },

    data() {
        return {
            // spclChar:[
                
            //     v=>  v.length > 0 ? /^[A-Za-z0-9 ]+$/.test(v) || "Special Characters Not Allowed" : ''
            // ],
            searchWithCg:'',
            roleId:'',
            role:'',
            lob:'',
            // selectLOB:['PA','Health'],
            GetLOBlist:[],

            GetAllData:[],
            GetData:[
                {
                    SerialNumber:"1",
                    PermissionId:"12345",
                    Role:"CG1",
                    lob:"Health",
                    
                },{
                    SerialNumber:"2",
                    PermissionId:"12346",
                    Role:"CG2",
                    lob:"PA",    
                },
            ],
            
            
            typesearch:false,
            swap_final_count:false,
            fval:0,
            sval:0,
            totalLen:0,
            showrsltnotfound:false,
            page: 1,
            pagelength:0,
            itemsPerPage: 10,
            disableAll:true,               
            headers:[
                {text: 'Sr. No.', value: 'SerialNumber',sortable: false,divider:true,class:"hdr-txt-clr-clr hdr-bg-clr srNoWidth"},
                {text: 'Role ID', value: 'roleId',sortable: false,divider:true,class:"hdr-txt-clr-clr hdr-bg-clr"},
                {text: 'Role Description', value: 'roleDescription',sortable: false,divider:true,class:"hdr-txt-clr-clr hdr-bg-clr"},
                {text: 'Role', value: 'roleName',sortable: false,divider:true,class:"hdr-txt-clr-clr hdr-bg-clr"},
                {text: 'LOB', value: 'lineOfBusiness',sortable: false,divider:true,class:"hdr-txt-clr-clr hdr-bg-clr"},
                // {text: '', value: 'dummy',sortable: false,divider:true,class:"hdr-txt-clr-clr hdr-bg-clr"},
            ],
            rules: {
            required(){
                return v=>v && v.length>0 || "This Field is Required"

            },
            minId: value => {
                            // const pattern = /^([0-9])+$/
                        const pattern = /^([R]){1}([O]){1}([L]){1}([E]){1}([0-9]){6}?$/
                        return value.length <= 0  || pattern.test(value) || 'Enter valid Role ID Format.'
                },
            }
        };


    },

filters:{

      
        checkdata(data){// eslint-disable-line no-mixed-spaces-and-tabs
            // console.log('Validity Data', data);
            if (data == "" || data == "undefined" || data == null || data == '' || data == 'Invalid Date') {
                return "-"
            } else {
                return data;
            }
        },
        
    },


    methods: {
    
            spclChar(val){
                console.log("val role", val)
                if(val.length > 0){
                   return /^[A-Za-z0-9 ]+$/.test(val) || "Special Characters Not Allowed"
                }  
            },

            createNewbtn(){

                // this.$router.push('/NetworkSetup')
                this.$router.push({name: 'RoleCreate', params:{value:true}})
            },

            cancelbtn(){
                this.$router.push('/home');
            },

            clearFields(){
                this.roleId = '';
                this.role = '';
                this.lob = '';
                this.searchWithCg = '';
                this.page = 1
                this.getAllRole(null,this.page )
            },

            RoleClick(item){
                console.log(item,"search result item ")
                this.$router.push({name: 'RoleCreate', params:{item,editValue:true}})
                // this.$store.commit("NETWORK_DATA",item)
                // this.$router.push('/RoleCreate')
            },

        GetLOBlistData(){
                
                let self=this      
                  let theheaders = this.authHeaderNew();
                    theheaders.system = "CM"//this.user_details().selectedLOB;
                
                axios({
                    method: "get",
                    //  url:"http://claimsdrive-health.salesdrive.app/master-data/master-data-service/api/line-of-business/get-line-of-business-list",
                     url:self.API_Service_MASTER()+"line-of-business/get-line-of-business-list",
                    
                    headers:theheaders 
                   
                })
                .then(function (response) {
                    let apiData = response.data.data[0].lineOfBusiness

                    console.log("API RESPONSE for ALL Network:::::",apiData);
                  
                    self.GetLOBlist = []
                    self.GetLOBlist= apiData
                    console.log(" lob api responce---->>>>>>", self.GetLOBlist);

                    // self.showToast(response.data.msg,self.TOST().SUCCESS)
                    console.log("lob api success msg--->>>>>>", response.data.msg);
                                       
                })
                .catch(function (error) {
                    console.log("lob list Error>>>>>>", error);
                    self.showToast(error.response.data.msg,self.TOST().ERROR);
                });
            
        },

        
        seekSearchData(indata){
            this.page=indata
            this.SearchRole(this.page);
        },
       
        nextPageData(indata){

            console.log("isSearchIndiv => ", this.typesearch);

            if(this.pagelength == indata){
                this.swap_final_count = true
            }else{
                this.swap_final_count = false
            }

            console.log('indiv sort value',this.indiviSortBy)
            console.log('indiv page no value',this.page)
            
            if(this.typesearch==false){
                this.getAllRole(null,this.page)
                  console.log("from if ",this.typesearch)
            }else{
                console.log("from else ",this.typesearch)
                this.seekSearchData(indata)
        
            }

        },

        getAllRole(sortBy1,pageNum) {
        console.log("role sort by-------",sortBy1)
                console.log("role page no ---->",pageNum)
                
                    let self = this;   
                    console.log('next btn',self.page)
                    self.showSearchdata =true
                    self.showrsltnotfound=false
                    self.typesearch = false
                    

                //     let apiURL=''
                // if(sortBy1===''||sortBy1===undefined||sortBy1===null){
                //     apiURL=self.API_Service_NETWORK_MGMNT()+"network-management/get-all-Network-Details?perPage=20&pageNo="+pageNum
                // }else{
                //     apiURL=self.API_Service_NETWORK_MGMNT()+"network-management/get-all-Network-Details?perPage=20&pageNo="+pageNum+"&sortBy="+sortBy1
                // }
                    
                    let theheaders = this.authHeaderNew();
                    theheaders.system = "CM";

                    axios({
                    method: "get",
                    
                    // url:apiURL,
                    //  url:"//claimsdrive-health.salesdrive.app/user-management/user-management-service/api/role/search-role",
                            
                            url:self.API_Service_USER_MGMNT()+"role/search-role?perPage="+10+"&pageNo="+pageNum,
                            
                            headers:theheaders
                        })
                        .then(function(response) {
                                       
                    let apiData = response.data.data.data

                    console.log("API RESPONSE for ALL Network:::::",apiData);
                  
                    self.GetAllData = []
                    // self.GetAllData= apiData
                    self.GetAllData= apiData.map((item,index) => {
                                                                        return{
                                                                                SerialNumber:10*(pageNum)-9+index,
                                                                                roleId:item.roleId,
                                                                                roleName:item.roleName,
                                                                                lineOfBusiness:item.lineOfBusiness,
                                                                                roleDescription:item.roleDescription,
                                                                                allData:item
                                                                            }
                                                                        });


                    
                     console.log("GetAllData RESPONSE ------->>>>>>>:::::",self.GetAllData);

            //pagination 
                    self.pagelength=response.data.data.total_pages
                    console.log(self.page,' <-- pageindu | next btn, pg length is ----->',self.pagelength)

                     self.totalLen=response.data.data.total
                      let l = self.GetAllData.length
                
                
                    console.log( 10*self.page,'skip simulation ');

                      var less_enough = self.totalLen
                        var checkless_init = false
                        if(less_enough < 10){
                            checkless_init = false
                        }else{
                            checkless_init = true
                        }

                        // if less than 15 we have second value same as total value as no pagination will occur
                        if(checkless_init){
                            
                                // checkinit is true means the final count is more than 15 (- 1)
                                var traverse =  10*self.page
                                self.fval = traverse-9
                                if(self.swap_final_count){
                                    self.sval =  self.totalLen
                                }else{
                                    self.sval = traverse
                                }

                        }else if(self.totalLen == 0){
                            
                          self.sval = self.fval = 0
                        
                       }else{
                             self.fval = 1
                             self.sval = self.totalLen
                        }

                    console.log('total ----->',self.totalLen)
                    console.log(self.sval,'<------sval<<   >>fval ----->',self.fval)

						console.log("role table DATA is---->>::",self.GetAllData);
			
                        })
                        .catch(function(error) {
                            console.log(error);
                            self.showToast(error.response.data.msg,self.TOST().ERROR);
                        });
             
            },

         SearchRole(pageNum) {

                console.log(this.searchWithCg,"incoming search result page no ---->",pageNum)
                        
                var self = this; 
                if(self.roleId=="" && self.role=="" && self.lob=="" && self.searchWithCg ==""){
                    self.showToast("Please enter search criteria.",self.TOST().WARNING);
                    return
                }

                 let theheaders = this.authHeaderNew();
                    theheaders.system = "CM"//this.user_details().selectedLOB;
                if (this.$refs.roleform.validate()){
                    self.showLoader(true);
                    axios({
                    method: "get",
                    // url:"//claimsdrive-health.salesdrive.app/user-management/user-management-service/api/role/search-role?perPage="+20+"&pageNo="+pageNum+"&sortBy="+"&roleName="+self.role+"&roleId="+self.roleId+"&lineOfBusiness="+self.lob,
                    url:self.API_Service_USER_MGMNT()+"role/search-role?perPage="+10+"&pageNo="+pageNum+"&sortBy="+"&roleName="+self.role+"&searchWithCg="+self.searchWithCg.toUpperCase()+"&roleId="+self.roleId+"&lineOfBusiness="+self.lob,
                    headers:theheaders
                        })
                        .then(function(response) {
                    
                        self.showLoader(false);

                                console.log("Search role reporting>>>>>>", response);

                    if(!response.data.data || response.data.data.length == 0){
                        console.log("Array is either empty or does not exist")
                        self.showSearchdata =false,
                        self.showrsltnotfound=true
                        
                    }else{
                        self.rsltData=response.data.data.data
                        console.log("self.rsltData",self.rsltData)
                        self.showSearchdata =true,
                        self.showrsltnotfound=false
                        

                    }
                                
                        let apiData=response.data.data.data
                        let status=response.status
                        
                        console.log("API provider individual search RESPONSE -->:::::",apiData);
                        console.log("API status RESPONSE -->:::::",status);
                        
                        self.GetAllData = []
                        
                        // self.GetAllData= apiData
                        self.GetAllData= apiData.map((item,index) => {
                                                                        return{
                                                                                SerialNumber:10*(pageNum)-9+index,
                                                                                roleId:item.roleId,
                                                                                roleName:item.roleName,
                                                                                lineOfBusiness:item.lineOfBusiness,
                                                                                roleDescription:item.roleDescription,
                                                                                allData:item
                                                                            }
                                                                        });
                        self.typesearch = true
                        self.isSearchindiv = true
                        console.log('isSearchindiv in Searchrole function ', self.isSearchindiv)
                                  

                // for role search pagination
                    
                    self.pagelength=response.data.data.total_pages
                    console.log(self.page,' <-- page search | search next btn, pg length is ----->',self.pagelength)

                     self.totalLen=response.data.data.total

                      let l = self.GetAllData.length
                
                    console.log( 20*self.page,'search skip simulation ');

                      var less_enough = self.totalLen
                        var checkless_init = false
                        if(less_enough < 10){
                            checkless_init = false
                        }else{
                            checkless_init = true
                        }

                        // if less than 15 we have second value same as total value as no pagination will occur
                        if(checkless_init){
                            
                                // checkinit is true means the final count is more than 15 (- 1)
                                var traverse =  10*self.page
                                self.fval = traverse-9
                                if(self.swap_final_count){
                                    self.sval =  self.totalLen
                                }else{
                                    self.sval = traverse
                                }

                        }else if(self.totalLen == 0){
                            
                          self.sval = self.fval = 0
                        
                       }else{
                             self.fval = 1
                             self.sval = self.totalLen
                        }

                    console.log('total search----->',self.totalLen)
                                    // self.prnNumber=""
                                    // console.log("prn clear status RESPONSE -->",self.prnNumber);

            
                        })
                        .catch(function (error) {
                    console.log("Search individual Error>>>>>>", error.response);
                    
                        // self.showToast(error.response.data.msg,self.TOST().ERROR);
                        self.showToast('No matching role found.',self.TOST().ERROR);
                        self.showSearchdata =false
                        self.showrsltnotfound=true
        
        
                    // if(self.userValue=="SuperUser" ||self.userValue=="DEO" || self.userValue=="Reviewer" || self.userValue=="ProviderManagementTeam"){
                    //             self.btndisCreateindu =false
                    // }else{
                    //     self.btndisCreateindu =true
                    // }

                });
                }else{
                this.showLoader(false);

                this.$refs.roleform.validate()
                this.showToast("Enter valid values in all the required fields.",this.TOST().WARNING);
            }
          },
                    
         

    },


}
</script>

<style>

.cust-rmvpd-psins3 .v-text-field.v-text-field--enclosed>.v-input__control>.v-input__slot {
    padding-right: 0px !important;
}

.cust_pr2 .v-text-field .v-input__slot,
.v-text-field--outline .v-input__slot {
    min-height: 42px !important;
    display: flex !important;
    align-items: center !important;
    /* border:1px red solid; */
}

.cstm-clr-pr3{
    color:#646666 !important;
    margin-bottom: 5px !important;
    font-size: 13px !important;
}

.cust_pd1 .v-text-field .v-input__slot,
.v-text-field--outline .v-input__slot {
    min-height: 42px !important;
    display: flex !important;
    align-items: center !important;
    /* border:1px red solid; */
}

.cust-rmvpd-pr1 .v-text-field.v-text-field--enclosed>.v-input__control>.v-input__slot {
    padding: 0px !important;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
    font-size: 0.84rem !important;
}

.hdr-txt-clr-clr {
        color: white !important;
    font-weight: bold;

}

.hdr-bg-clr{
  background-color: #23B1A9 !important;
  color: #ffffff !important;
  
}
.headercolor{
    background-color: #eee;
}
.cardfont{
    font-size: 0.84rem !important;
    padding-right: 2px;
}
.srNoWidth{
    width: 80px;

}


</style>