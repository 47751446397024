<template>
  <div class="pa-5">
    <v-form>
      <v-row
        no-gutters
        class="mx-1 pa-5 mt-2"
        style="background-color: #f7f7f7; display: flex"
      >
        <v-col align="">
          <v-btn
            tile
            color="#01b4bb"
            dark
            class="btnstyle12 mr-3"
            @click="switchTab('claimDetails')"
          >
            Claim Details
          </v-btn>
          <v-btn
            tile
            color="#01b4bb"
            dark
            class="btnstyle12 mr-3"
            @click="goToViewDocument()"
          >
            SIU Documents
          </v-btn>
          <v-btn
            tile
            color="#01b4bb"
            dark
            class="btnstyle12 mr-3"
            @click="openClaimDocs()"
          >
            Claim Documents
          </v-btn>
          <v-btn
            tile
            color="#01b4bb"
            dark
            class="btnstyle12 mr-3"
            @click="goToBRETrigges()"
          >
            BRE Trigges
          </v-btn>
          <v-btn
            tile
            color="#01b4bb"
            dark
            class="btnstyle12 mr-3"
            @click="goToSIULettersLibrary()"
          >
            SIU Letters Library
          </v-btn>
          <v-btn
            tile
            color="#01b4bb"
            dark
            class="btnstyle12 mr-3"
            @click="switchTab('illnessDetails')"
          >
            Illness Details
          </v-btn>
          <v-btn
            tile
            color="#01b4bb"
            dark
            class="btnstyle12 mr-3"
            @click="switchTab('siuReferralDetails')"
          >
            SIU Referral Details
          </v-btn>
          <v-btn
            tile
            color="#01b4bb"
            dark
            class="btnstyle12 mr-3"
            @click="switchTab('policyDetails')"
          >
            Policy Details
          </v-btn>
          <v-btn
            tile
            color="#01b4bb"
            dark
            class="btnstyle12 mr-3"
            @click="downloadPolicyForm()"
          >
            Policy Cum Proposal Form
          </v-btn>
          <v-btn
            tile
            color="#01b4bb"
            dark
            class="btnstyle12 mr-3"
            @click="switchTab('siuNotes')"
          >
            Siu Notes
          </v-btn>
        </v-col>
      </v-row>
      <v-row no-gutters class="justify-center mt-3">
        <v-card class="mb-3" style="" tile>
          <v-layout align-center>
          </v-layout>
        </v-card>
      </v-row>
    </v-form>
    <ClaimDetailsTab v-if="claimDetails" :otherInfo="this.$route.params" />
    <IllnessDetailsTab v-if="illnessDetails" :otherInfo="this.$route.params" />
    <SiuRefferalDetailsTab v-if="siuReferralDetails" />
    <PolicyDetailsTab v-if="policyDetails" :otherInfo="this.$route.params" />
    <InsuredDetailsTab v-if="insuredDetails" />
    <PayerDetailsTab v-if="payerDetails" />
    <SiuNotes v-if="siuNotes" />
    <SiuClaimDocuments 
      v-if="showClaimDocuments"
      @showHide="showHideClaimDocsPopup"
    />
    <!-- Document_dialog -->
    <v-dialog v-model="Document_dialog" width="90%">
      <v-card tile class="">
        <v-card-title class="py-2" style="border: 0.5px solid #c1c8cc">
          View Documents
          <v-spacer></v-spacer>
          <v-icon @click="Document_dialog = !Document_dialog" class=""
            >mdi-window-close</v-icon
          >
        </v-card-title>

        <v-data-table
          class="padding-0 pa-2 mx-3 vDataTableStyling"
          :headers="headers"
          :items="documentData"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          :hide-default-footer="true"
        >
          <template v-slot:top>
            <v-layout
              justify-space-between
              align-center
              style="width: 100%"
              class="py-2"
            >
            </v-layout>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-menu offset-y open-on-click>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  elevation=""
                  icon
                  v-bind="attrs"
                  v-on="on"
                  style="text-align: center"
                  ><v-icon>mdi mdi-dots-vertical</v-icon></v-btn
                >
              </template>
              <v-list class="item-actions">
                <v-list-item
                  v-for="(itemData, index) in dataItems"
                  :key="index"
                  @click="editmaster(itemData.link, index, item, itemData.name)"
                  :disabled="
                    itemData.name === 'MODIFY' &&
                    ['Pending for approval'].includes(item.approveStatus)
                  "
                >
                  <v-list-item-title
                    :style="
                      itemData.name === 'MODIFY' &&
                      ['Pending for approval'].includes(item.approveStatus)
                        ? 'gray'
                        : itemData.color
                    "
                  >
                    <v-icon small :style="itemData.iconColor"
                      >mdi-{{ itemData.icon }}</v-icon
                    >&nbsp;{{ itemData.name }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
          <template v-slot:item.srNum="{ item }">
            {{ item.srNum }}
          </template>
          <template v-slot:item.userName="{ item }">
            {{ item.userName }}
          </template>
          <template v-slot:item.documentType="{ item }">
            {{ item.documentType }}
          </template>
          <template v-slot:item.documentName="{ item }">
            {{ item.documentName }}
          </template>
          <template v-slot:item.documentDate="{ item }">
            {{ formatDateTime(item.documentDate) }}
          </template>
          <template v-slot:item.photocopy="{ item }">
            {{ item.photocopy }}
          </template>
          <template v-slot:item.view="{ item }">
            <div class="spaced_centered" v-if="item.fileLink">
              <v-icon
                dark
                @click="openFile(item, true)"
                color="black"
                >visibility</v-icon
              >
              <v-icon
                dark
                @click="openFile(item)"
                v-bind="attrs"
                v-on="on"
                color="black"
                >cloud_download</v-icon
              >
            </div>
          </template>
        </v-data-table>
      </v-card>
    </v-dialog>

    <!-- Trigger dialogue -->
    <v-dialog v-model="Trigger_dialog" width="800">
      <v-card>
        <v-card-title class="py-2" style="border: 0.5px solid #c1c8cc">
          BRE Triggers
          <v-spacer></v-spacer>
          <v-icon @click="Trigger_dialog = !Trigger_dialog" class=""
            >mdi-window-close</v-icon
          >
        </v-card-title>

        <v-layout style="" class="px-3 py-2">
          <v-flex xs4>
            <v-card
              tile
              class="mx-3 my-2 py-1"
              style="border: 0.5px solid #c1c8cc"
            >
              <v-flex row class="px-2" style="justify-content: space-between">
                <v-text
                  class="ml-3 claimlabel cstm-clr-pd"
                  style="align-self: center"
                >
                  BRE Triggers
                </v-text>

                <v-text
                  class="font-weight-bold cstm-clr-pd"
                  style="
                    font-size: 18px !important;
                    color: #018fa6 !important;
                    align-self: center;
                  "
                >
                  {{ this.$route.params.allData.triggerScore }}
                </v-text>
              </v-flex>
            </v-card>
          </v-flex>

          <v-flex xs4>
            <v-card
              tile
              class="mx-3 my-2 py-1"
              style="border: 0.5px solid #c1c8cc"
            >
              <v-flex row class="px-2" style="justify-content: space-between">
                <v-text
                  class="ml-3 claimlabel cstm-clr-pd"
                  style="align-self: center"
                >
                  Manual Triggers
                </v-text>

                <v-text
                  class="font-weight-bold cstm-clr-pd"
                  style="
                    font-size: 18px !important;
                    color: #018fa6 !important;
                    align-self: center;
                  "
                >
                  {{ this.$route.params.allData.manualScore }}
                </v-text>
              </v-flex>
            </v-card>
          </v-flex>

          <v-flex xs4>
            <div>
              <v-card
                tile
                class="mx-3 my-2 py-1"
                style="border: 0.5px solid #c1c8cc"
              >
                <v-flex row class="px-2" style="justify-content: space-between">
                  <v-text
                    class="ml-3 claimlabel cstm-clr-pd"
                    style="align-self: center"
                  >
                    Total Score
                  </v-text>
                  <v-text
                    class="font-weight-bold cstm-clr-pd"
                    style="
                      font-size: 18px !important;
                      color: #018fa6 !important;
                      align-self: center;
                    "
                  >
                    {{ (Number(this.$route.params.allData.manualScore) + Number(this.$route.params.allData.triggerScore)) || '' }}
                  </v-text>
                </v-flex>
              </v-card>
            </div>
          </v-flex>
        </v-layout>

        <v-layout class="pt-4 px-4">
          <v-layout column class="width-common">
            <v-card class="ma-2">
              <v-card color="" class="pa-2 pl-2" tile>
                <p class="claimlabel ma-0 pa-0" style="font-size: 13px">
                  BRE Triggers
                </p>
              </v-card>
              <v-simple-table fixed-header height="200px" class="ma-4">
                <template v-slot:default>
                  <thead style="background-color: rgb(1, 187, 217)">
                    <tr>
                      <th
                        class="text-left mt-2"
                        style="background-color: #f7f7f7; width: 150px"
                      >
                        Category
                      </th>
                      <th
                        class="text-left mt-2"
                        style="background-color: #f7f7f7"
                      >
                        Description
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(BREselected, index) in allData.triggerDetails"
                      :key="index"
                    >
                      <td class="pt-5" style="width: 150px">
                        <p>
                          {{
                            BREselected.triggerCategory
                              ? BREselected.triggerCategory
                              : ""
                          }}
                        </p>
                      </td>
                      <td class="pt-5">
                        <p>
                          {{
                            BREselected.triggerDescription
                              ? BREselected.triggerDescription
                              : ""
                          }}
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
          </v-layout>

          <v-layout column>
            <v-card class="ma-2">
              <v-card class="pa-2 pl-2" tile>
                <p class="claimlabel ma-0 pa-0" style="font-size: 13px">
                  Manual Triggers
                </p>
              </v-card>
              <v-simple-table fixed-header height="200px" class="ma-4">
                <template v-slot:default>
                  <thead style="background-color: rgb(1, 187, 217)">
                    <tr>
                      <th
                        class="text-left mt-2"
                        style="background-color: #f7f7f7; width: 150px"
                      >
                        Category
                      </th>
                      <th
                        class="text-left mt-2"
                        style="background-color: #f7f7f7"
                      >
                        Description
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(manualSelected, index) in allData.manualDetails"
                      :key="index"
                    >
                      <td class="pt-5" style="width: 150px">
                        <p>
                          {{
                            manualSelected.triggerCategory
                              ? manualSelected.triggerCategory
                              : ""
                          }}
                        </p>
                      </td>
                      <td class="pt-5">
                        <p>
                          {{
                            manualSelected.description
                              ? manualSelected.description
                              : ""
                          }}
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
          </v-layout>
        </v-layout>
      </v-card>
    </v-dialog>

        <!-- html ClaimantQuestionaireForDeathClaim -->
    <div style="height:0;overflow:hidden;">
      <div class="body1" style="padding: 40px;" id="ClaimantQuestionaireForDeathClaimpdf">

        <div style="text-align: left; margin-left: 90%">
              <img src="../assets/TATAAIGLogo.png" alt="Flow" style="width:60px;height:70px;">            
          </div>
        <div>
          <p style="margin-bottom: 0in; text-align: center">
            <b><u><span style="font-size: 14px">TATA AIG General Insurance Company Ltd.</span></u></b>
          </p>
        </div>

        <div>
          <p style="margin-bottom: 0in; text-align: center">
            <b
              ><span class="" style="line-height: 100%; font-size: 12px"
                >LOB - {{allData.lob}} | Claim Number - {{allData.claimNumber}} | Claim Type - {{allData.claimType}} | Insured Name - {{allData.insurerence}} | Claimant Name - {{allData.claimantName}} | Loss State & City - {{allData.state}} & {{allData.city}} | Date of Loss - {{formatDateTime(allData.dateOfAdmission)}}
              </span></b
            >
          </p>
          <p style="font-size: 12px; text-align: center">
            <i>
              (PLEASE ANSWER EACH QUESTION SPECIFICALLY WITHOUT USING “--“OR NOT
              APPLICABLE)
            </i>
          </p>
        </div>
    

          <ol style="font-size: 12px">
            <li style="text-align: justify; padding: 3px">
              <p style="margin-bottom: 18px;">
                Dear Sir/Madam, for what complaints, insured was admitted in the
                Hospital?
              </p>
              <p style="margin-bottom: 18px;">
              ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------
              </p>
              <p>
              ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------
              </p>
            </li>
            <li style="text-align: justify; padding: 3px">
              <p style="margin-bottom: 18px;">
                What were the early symptoms noticed and when? Please specify in
                detail.
              </p>
              <p style="margin-bottom: 18px;">
              ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------
              </p>
              <p>
              ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------
              </p>
            </li>
            <li style="text-align: justify; padding: 3px">
              <p style="margin-bottom: 18px;">
                Please specify the name and address of Doctor to whom you first
                consulted for this complaint? Please provide consultation papers for
                the same.
              </p>
              <p style="margin-bottom: 18px;">
              ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------
              </p>
              <p>
              ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------
              </p>
            </li>
            <li style="text-align: justify; padding: 3px">
              <p style="margin-bottom: 18px;">
                Please specify the history of similar complaints in past and
                treatment taken for the same.
              </p>
              <p style="margin-bottom: 18px;">
              ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------
              </p>
              <p>
              ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------
              </p>
            </li>
            <li style="text-align: justify; padding: 3px">
              <p style="margin-bottom: 18px;">
                Kindly specify what investigations were conducted and what diagnosis
                had been made?
              </p>
              <p style="margin-bottom: 18px;">
              ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------
              </p>
              <p>
              ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------
              </p>
            </li>

            <li style="text-align: justify; padding: 3px">
              <p style="margin-bottom: 18px;">Kindly specify the Place, Date & Time of death?</p>
              <p style="margin-bottom: 18px;">
              ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------
              </p>
              <p>
              ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------
              </p>
            </li>
            <li style="text-align: justify; padding: 3px">
              <p style="margin-bottom: 18px;">
                Please specify since how long patient was suffering from Heart
                Problem? Which doctor diagnosed it first and when? Kindly provide
                the copy of first consultation papers & treatment record for the
                same?
              </p>
              <p style="margin-bottom: 18px;">
              ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------
              </p>
              <p>
              ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------
              </p>
            </li>
            <li style="text-align: justify; padding: 3px">
              <p style="margin-bottom: 18px;">
                Please specify the history of Hypertension, diabetes or any other
                systemic disorder in the patient. What treatment had the patient
                taken for the same and from whom?
              </p>
              <p style="margin-bottom: 18px;">
              ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------
              </p>
              <p>
              ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------
              </p>
            </li>
            <li style="text-align: justify; padding: 3px">
              <p style="margin-bottom: 18px;">
                Please specify the history of Bronchial Asthma, COPD or any other
                Respiratory ailment in the patient. What treatment had the patient
                taken for the same and from whom?
              </p>
              <p style="margin-bottom: 18px;">
              ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------
              </p>
              <p>
              ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------
              </p>
            </li>
            <li style="text-align: justify; padding: 3px">
              <p style="margin-bottom: 18px;">
                Please specify the history of Hepatitis, cirrhosis, liver Disease,
                Kidney Disease or any other Chronic ailment. What treatment had the
                patient taken for the same and from whom?
              </p>
              <p style="margin-bottom: 18px;">
              ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------
              </p>
              <p>
              ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------
              </p>
            </li>
            <li style="text-align: justify; padding: 3px">
              <p style="margin-bottom: 18px;">
                Kindly specify the history of previous Hospitalization in past ten
                years. (provide records of the same)0
              </p>
              <p style="margin-bottom: 18px;">
              ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------
              </p>
              <p>
              ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------
              </p>
            </li>
            <li style="text-align: justify; padding: 3px">
              <p style="margin-bottom: 18px;">
                Kindly specify the history of previous accident or injury in the
                past?
              </p>
              <p style="margin-bottom: 18px;">
              ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------
              </p>
              <p>
              ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------
              </p>
            </li>
            <li style="text-align: justify; padding: 3px">
              <p style="margin-bottom: 18px;">
                Kindly provide details of your personal habits (Alcoholism, Smoking
                or any other drug addiction) if any please specify the quantity and
                frequency for the same per week/month.
              </p>
              <p style="margin-bottom: 18px;">
              ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------
              </p>
              <p>
              ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------
              </p>
            </li>
            <br>
            <br>
            <br>
            <br>
            <li style="text-align: justify; padding: 3px">
              <p style="margin-bottom: 18px;">
                Kindly provide us the copy of previous OPD consultation papers,
                investigational reports, treatment details or any other relevant
                documents concerned with the treatment for the settlement of the
                claim.
              </p>
              <p style="margin-bottom: 18px;">
              ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------
              </p>
              <p>
              ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------
              </p>
            </li>
            <li style="text-align: justify; padding: 3px">
              <p style="margin-bottom: 18px;">Please provide following Information about yourself:</p>
              <p style="margin-bottom: 18px;">
              ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------
              </p>
              <p>
              ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------
              </p>
            </li>
            <table
              style="
                width: 450.65pt;
                font-size: 12px;
                margin-left: 0pt;
                border-collapse: collapse;
                border: none;
              "
              width="620"
              cellspacing="0"
              cellpadding="0"
              border="1"
            >
              <tbody>
                <!-- first row -->
                <tr style="border: 1px solid black">
                  <td
                    style="width: 110.65pt; padding: 0in 5.4pt 0in 5.4pt"
                    valign="top"
                  >
                    <p style="margin-bottom: 0in; line-height: 360%">
                      Policy Holder/Claimant Name
                    </p>
                  </td>
                  <td
                    colspan="3"
                    style="width: 135.65pt; padding: 0in 5.4pt 0in 5.4pt"
                    width="180"
                    valign="top"
                  ></td>
                  <td
                    colspan="3"
                    style="width: 110.65pt; padding: 0in 5.4pt 0in 5.4pt"
                    width="160"
                    valign="top"
                  >
                    <p
                      class="MsoNormal"
                      style="margin-bottom: 0in; line-height: 360%"
                    >
                      <span style="line-height: 360%">Relation with Insured</span>
                    </p>
                  </td>
                  <td
                    colspan="3"
                    style="width: 135.65pt; padding: 0in 5.4pt 0in 5.4pt"
                    width="180"
                    valign="top"
                  ></td>
                </tr>

                <tr style="border: 1px solid black">
                  <td
                    style="width: 110.65pt; padding: 0in 5.4pt 0in 5.4pt"
                    valign="top"
                  >
                    <p style="margin-bottom: 0in; line-height: 360%">Address</p>
                  </td>
                  <td
                    colspan="3"
                    style="width: 135.65pt; padding: 0in 5.4pt 0in 5.4pt"
                    width="180"
                    valign="top"
                  ></td>
                  <td
                    colspan="3"
                    style="width: 110.65pt; padding: 0in 5.4pt 0in 5.4pt"
                    width="160"
                    valign="top"
                  >
                    <p
                      class="MsoNormal"
                      style="margin-bottom: 0in; line-height: 360%"
                    >
                      <span style="line-height: 360%">DOB</span>
                    </p>
                  </td>
                  <td
                    colspan="3"
                    style="width: 135.65pt; padding: 0in 5.4pt 0in 5.4pt"
                    width="180"
                    valign="top"
                  ></td>
                </tr>

                <tr style="border: 1px solid black">
                  <td
                    style="width: 110.65pt; padding: 0in 5.4pt 0in 5.4pt"
                    valign="top"
                  >
                    <p style="margin-bottom: 0in; line-height: 360%">Occupation</p>
                  </td>
                  <td
                    colspan="3"
                    style="width: 135.65pt; padding: 0in 5.4pt 0in 5.4pt"
                    width="180"
                    valign="top"
                  ></td>
                  <td
                    colspan="3"
                    style="width: 110.65pt; padding: 0in 5.4pt 0in 5.4pt"
                    width="160"
                    valign="top"
                  >
                    <p
                      class="MsoNormal"
                      style="margin-bottom: 0in; line-height: 360%"
                    >
                      <span style="line-height: 360%"
                        >Other Insurance Policies</span
                      >
                    </p>
                  </td>
                  <td
                    colspan="3"
                    style="width: 135.65pt; padding: 0in 5.4pt 0in 5.4pt"
                    width="180"
                    valign="top"
                  ></td>
                </tr>
              </tbody>
            </table>
          </ol>

      
        <div>
          <ul style="font-size: 12px">
            <li>
              <i
                >Answers given by me for the above mentioned questions are true
                to the best of my knowledge and have been given by my own will
                without any Coercion/ threat from anyone. Representative of the
                company did not ask for any kind of favors from me, Insured or
                Others.
              </i>
            </li>
            <li>
              <i>
                I give consent and authorize representative from the Insurance
                Company to collect all relevant Records using any Medium
                appropriate for the same from any Hospital/ Clinic/ Other
                Organization or anyone in India.
              </i>
            </li>

            <table
              style="
                width: 450.65pt;
                font-size: 12px;
                margin-left: 0pt;
                border-collapse: collapse;
                border: none;
              "
              width="620"
              cellspacing="0"
              cellpadding="0"
              border="1"
            >
              <tr style="border: 1px solid black">
                <td style="width: 225.65pt; padding: 0in 5.4pt 0in 5.4pt">
                  <p
                    class="MsoNormal"
                    style="margin-bottom: 0in; line-height: 260%"
                  >
                    <span style="line-height: 260%">Claimant's Name:</span>
                  </p>
                </td>
                <td style="width: 225.65pt; padding: 0in 5.4pt 0in 5.4pt">
                  <p
                    class="MsoNormal"
                    style="margin-bottom: 0in; line-height: 260%"
                  >
                    <span style="line-height: 260%">Witness Name:</span>
                  </p>
                </td>
              </tr>
              <tr style="border: 1px solid black">
                <td style="width: 225.65pt; padding: 0in 5.4pt 0in 5.4pt">
                  <p
                    class="MsoNormal"
                    style="margin-bottom: 0in; line-height: 260%"
                  >
                    <span style="line-height: 260%">Signature with Date:</span>
                  </p>
                </td>
                <td style="width: 225.65pt; padding: 0in 5.4pt 0in 5.4pt">
                  <p
                    class="MsoNormal"
                    style="margin-bottom: 0in; line-height: 260%"
                  >
                    <span style="line-height: 260%">Signature with Date:</span>
                  </p>
                </td>
              </tr>
              <tr style="border: 1px solid black">
                <td style="width: 225.65pt; padding: 0in 5.4pt 0in 5.4pt">
                  <p
                    class="MsoNormal"
                    style="margin-bottom: 0in; line-height: 260%"
                  >
                    <span style="line-height: 260%">Relation with Patient & Contact No.:</span>
                  </p>
                </td>
                <td style="width: 225.65pt; padding: 0in 5.4pt 0in 5.4pt">
                  <p
                    class="MsoNormal"
                    style="margin-bottom: 0in; line-height: 260%"
                  >
                    <span style="line-height: 260%">Contact No:</span>
                  </p>
                </td>
              </tr>
            </table>
          </ul>
        </div>

      </div>
    </div>

    <!-- html CustomerFeedbackFormpdf-->

    <div style="height:0;overflow:hidden;">
        <div class="body1" style="padding: 40px;" id="CustomerFeedbackFormpdf">
              <div style="text-align: left; margin-left: 90%">
                  <img src="../assets/TATAAIGLogo.png" alt="Flow" style="width:60px;height:70px;">            
              </div>
              <div>
                <p style="margin-bottom: 0in; text-align: center" >
                  <b><span style="font-size: 14px">TATA AIG General Insurance Company Ltd.</span></b>
                </p>
              </div>
              <div>
                <p  style="margin-bottom: 0in; text-align: center" >
                  <b><span class="" style="line-height: 100%;font-size: 12px">LOB - {{allData.lob}} | Claim Number - {{allData.claimNumber}} | Claim Type - {{allData.claimType}} | Insured Name - {{allData.insurerence}} | Claimant Name - {{allData.claimantName}} | Loss State & City - {{allData.state}} & {{allData.city}} | Date of Loss - {{formatDateTime(allData.dateOfAdmission)}}
                  </span></b>
                </p>
              </div>

          <div style="text-align: center">
              <b>
                  <span style="font-size: 14px">
                    <u>CUSTOMER  FEEDBACK  FORM</u>
                  </span>
              </b>
              <br>
              <br>
          </div>

          <div style="font-size: 12px">  
              <p >
                  <span>Customer Name:</span>
                  <!-- <span>Customer Name:{{Customer Name }}</span> -->
              </p>
              <br>
              <p >
                  <span>Address:</span>
                  <!-- <span>Address:{{Address}}</span> -->
              </p>
              <br>
              <p>
                  <span>Email/Phone:</span>
                  <!-- <span>Email/Phone:{{Email/Phone}}</span> -->
              </p>
              <br>
              <p>
                  <span>Vendor Name:</span>
                  <!-- <span>Vendor Name:{{Vendor Name}}</span> -->
              </p>
              <br>
              <p>
                  <span>Name of Verification Officer:</span>
                  <!-- <span>Name of Verification Officer:{{Name of Verification Officer}}</span> -->
              </p>
              <p class="MsoNormal" style="margin-bottom: 0in">
                      <span style="line-height: 115%">&nbsp;</span>
              </p>
          </div>


          <ol style="font-size: 12px">
              <li style="margin-bottom: 18px;text-align: justify;padding: 3px;">
                  <p>Did verification officer explain you the purpose of visit?</p>
                  <p style="margin-bottom: 18px;">
                    ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------
                  </p>
                  <p>
                  ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------
                  </p>
              </li>
              <li style="margin-bottom: 18px;text-align: justify;padding: 3px;">
                  <p>Did he take consent for verification process?</p>
                  <p style="margin-bottom: 18px;">
                  ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------
                  </p>
                  <p>
                  ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------
                  </p>
              </li>
              <li style="margin-bottom: 18px;text-align: justify;padding: 3px;">
                  <p>Did he present you authority letter/ Identity card?</p>
                  <p style="margin-bottom: 18px;">
                  ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------
                  </p>
                  <p>
                  ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------
                  </p>
              </li>
              <li style="margin-bottom: 18px;text-align: justify;padding: 3px;">
                  <p>Was there any misbehavior/rude/arrogant behavior from verification officer during verification?</p>
                  <p style="margin-bottom: 18px;">
                  ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------
                  </p>
                  <p>
                  ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------
                  </p>
              </li>
              <li style="margin-bottom: 18px;text-align: justify;padding: 3px;">
                  <p>Did he take photographs of all the related documents, prescription, reports, and films with willful consent?</p>
                  <p style="margin-bottom: 18px;">
                  ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------
                  </p>
                  <p>
                  ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------
                  </p>
              </li>

              <li style="margin-bottom: 18px;text-align: justify;padding: 3px;">
                  <p>Was the information shared without any force, fear, influence or pressure and without giving money or gift to the representative?</p>
                  <p style="margin-bottom: 18px;">
                  ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------
                  </p>
                  <p>
                  ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------
                  </p>
              </li>
            </ol>
              <div style="font-size: 12px">
                  <p class="MsoNormal" style="margin-bottom: 0in">
                      <span style="line-height: 115%">&nbsp;</span>
                  </p>
                  <p class="MsoNormal" style="margin-bottom: 0in;text-align: justify;">
                      <span style="line-height: 115%">Here by you are allowing us to take your Google locations as a part of claim verification.</span>
                  </p>
                  <p class="MsoNormal" style="margin-bottom: 0in">
                      <span style="line-height: 115%">&nbsp;</span>
                  </p>
                  <p class="MsoNormal" style="margin-bottom: -13px">
                      <span style="line-height: 115%">Date </span>    
                      <span style="line-height: 115%;text-align: left; margin-left: 54.8%">Date </span>
                  </p>
                  <br>
                  <p class="MsoNormal" style="margin-bottom: 0in">
                      <span style="line-height: 115%">&nbsp;</span>
                  </p>
                  <p class="MsoNormal" style="margin-bottom: -13px">
                      <span style="line-height: 115%">Signature of insured</span>
                      <span style="line-height: 115%;text-align: left; margin-left: 44.5%">Signature of verification officer </span>
                  </p>
                  <br>
                  <p class="MsoNormal" style="margin-bottom: 0in">
                      <span style="line-height: 115%">&nbsp;</span>
                  </p>
                  <p class="MsoNormal" style="margin-bottom: -13px">
                      <span style="line-height: 115%">Contact No : </span>
                      <span style="line-height: 115%;text-align: left; margin-left: 49%">Contact No : </span>
                  </p>
              </div>

            
        </div>
    </div>

      <!-- html InsuredStatementBlankCopy -->
    <div style="height:0;overflow:hidden;">
      <div class="body1" style="padding: 40px;" id="InsuredStatementBlankCopypdf">

        <div style="text-align: left; margin-left: 90%">
            <img src="../assets/TATAAIGLogo.png" alt="Flow" style="width:60px;height:70px;">            
        </div>
        <div>
            <p style="margin-bottom: 0in; text-align: center" >
                <b><span style="text-decoration: underline;font-size: 14px">TATA AIG GIC LTD</span></b>
            </p>
        </div>    
        <div>
          <p  style="margin-bottom: 0in; text-align: center" >
            <b><span class="" style="line-height: 100%;font-size: 12px">LOB - {{allData.lob}} | Claim Number - {{allData.claimNumber}} | Claim Type - {{allData.claimType}} | Insured Name - {{allData.insurerence}} | Claimant Name - {{allData.claimantName}} | Loss State & City - {{allData.state}} & {{allData.city}} | Date of Loss - {{formatDateTime(allData.dateOfAdmission)}}
            </span></b>
          </p>
        </div>

        <div style="text-align: center">
            <h3>
                <span style="line-height: 100%;text-decoration: underline;font-size: 14px">
                    STATEMENT BY CLAIMENT / INSURED
                </span>
            </h3>
            <p style="font-size: 12px">
                <span>(Kindly Describe the DISEASE IN DETAIL)</span>
            </p>
        
            
        </div>

        <div style="font-size: 12px">
            <p >
                <span>Please provide the previous investigation reports & consultation papers and discharge summary</span>
            </p>
            <p >
                <span>Please provide the previous hospitalization details for any ailments</span>
            </p>
            
            <p style="padding: 200px;">

            </p>
        </div>

        <ul style="font-size: 12px">
          <li >
              <i>Answers and declaration given by me above is true to best of my knowledge</i>
              
          </li>
          <li >
              <i>This is to confirm that representative of the company did not demand for any kind of favors from the insured</i>
              
          </li>
          <li >
              <i>I give content and authorize Insurance Company to collect relevant document from Hospital.</i>
              
          </li>
        </ul>

        <div style="font-size: 12px">
            <p class="MsoNormal" style="margin-bottom: 0in">
                <span style="line-height: 115%">&nbsp;</span>
            </p>
            <p class="MsoNormal" style="margin-bottom: -13px">
                <span style="line-height: 115%">Name ___________________________ </span>    
                <span style="line-height: 115%;text-align: left; margin-left: 30%">Witness Name ________________________ </span>
            </p>
            <p class="MsoNormal" style="margin-bottom: 0in">
                <span style="line-height: 115%">&nbsp;</span>
            </p>
            <p class="MsoNormal" style="margin-bottom: -13px">
                <span style="line-height: 115%">Signature ________________________</span>
                <span style="line-height: 115%;text-align: left; margin-left: 30.5%">Signature ____________________________ </span>
            </p>
            <p class="MsoNormal" style="margin-bottom: 0in">
                <span style="line-height: 115%">&nbsp;</span>
            </p>
            <p class="MsoNormal" style="margin-bottom: -13px">
                <span style="line-height: 115%">Phone ___________________________ </span>
                <span style="line-height: 115%;text-align: left; margin-left: 30%">Phone ______________________________ </span>
            </p>
            <p class="MsoNormal" style="margin-bottom: 0in">
                <span style="line-height: 115%">&nbsp;</span>
            </p>
            <p class="MsoNormal" style="margin-bottom: -13px">
                <span style="line-height: 115%">Date & Time ______________________ </span>
                <span style="line-height: 115%;text-align: left; margin-left: 29.5%">Date ________________________________ </span>
            </p>
        </div>

      </div>
    </div>  

      <!-- html LetterOfAppointmentForInvestigator -->
    <div style="height:0;overflow:hidden;">
      <div class="body1" style="padding: 40px;" id="LetterOfAppointmentForInvestigatorpdf">
        <div style="text-align: left; margin-left: 90%">
            <img src="../assets/TATAAIGLogo.png" alt="Flow" style="width:60px;height:70px;">            
        </div>

        <div style="text-align: left; margin-left: 65%;font-size: 12px">
            <p>
                <span style="line-height: 100%">Date: </span>
            </p>
        </div>

        <div >
            <p style="margin-bottom: 0in; text-align: center" >
            <b><span style="line-height: 115%;font-size: 14px">To Whomsoever It May Concern</span></b>
          </p>
          <p class="MsoNormal" style="margin-bottom: 0in">
                <span style="line-height: 100%">&nbsp;</span>
          </p>
        </div>

        <div style="font-size: 12px">
            <p>
                <b><span>Subject:</span></b>
                <span> Letter of Appointment for Claim Verification </span>
            </p>
            <p class="MsoNormal" style="margin-bottom: 0in">
                <span style="line-height: 100%">&nbsp;</span>
              </p>
            <p>
                <b><span>Reference:</span></b>
                <span> LOB - {{allData.lob}} | Claim Number - {{allData.claimNumber}} | Claim Type - {{allData.claimType}} | Insured Name - {{allData.insurerence}} | Claimant Name - {{allData.claimantName}} | Loss State & City - {{allData.state}} & {{allData.city}} | Date of Loss - {{formatDateTime(allData.dateOfAdmission)}} </span>
            </p>
        </div>


        <div style="margin-top:30px;font-size: 12px">
            <p class="MsoNormal" style="line-height: 20%;">
                <span>Dear Sir/Madam,</span>
            </p>
            <p class="MsoNormal" style="margin-bottom: 0in">
                <span style="line-height: 100%">&nbsp;</span>
            </p>
            <p class="MsoNormal" style="margin-bottom: 0in;text-align: justify;">
                We are pleased to appoint you to verify the facts and circumstances related to this incidence.    
            </p>
            <p>
                You are hereby authorized to collect and scrutinize claim related documents on company’s behalf from Hospital, Treating Doctor, Chemist, Pathologist & Radiologist, Insured, Claimant, Nominee, Legal Heir, Police Station, Employer, Neighbors & Relatives and other concerned offices in order to complete the task.
            </p>
            <p class="MsoNormal" style="margin-bottom: 0in">
                <span style="line-height: 100%">&nbsp;</span>
              </p>
        </div>

        <ol style="font-size: 12px">
            <li >
                <p>Hospital for collection and verification of IPD Case papers,medical and final hospital bills,etc</p>
            </li>
            <li >
                <p>Insured/Claimant/Nominee/Legal Heir – Claim Documents and Statement</p>
            </li>
            <li >
                <p>Police station for collection of accident related documents & criminal record papers</p>
            </li>
        </ol>

          <div style="font-size: 12px">
              <p class="MsoNormal" style="margin-bottom: 0in">
                <span style="line-height: 100%">&nbsp;</span>
              </p>
              <p class="MsoNormal" style="margin-bottom: 2px">
                <span style="line-height: 100%">Thanking you,</span>
              </p>
              <p class="MsoNormal" style="margin-bottom: 2px">
                <span style="line-height: 100%">Yours faithfully,</span>
            </p>
            <p class="MsoNormal" style="margin-bottom: 2px">
              <span style="line-height: 100%">Tata AIG General Insurance Co. Ltd.</span>
            </p>
          </div>  
      
      </div>
    </div>

      <!-- html LetterOfAuthorityForHospital -->
    <div style="height:0;overflow:hidden;">
      <div class="body1" style="padding: 40px;" id="LetterOfAuthorityForHospitalpdf">

          <div style="text-align: left; margin-left: 90%">
              <img src="../assets/TATAAIGLogo.png" alt="Flow" style="width:60px;height:70px;">            
          </div>
        
            <div style="text-align: center">
                <h3>
                    <span style="line-height: 115%;text-decoration: underline;font-size: 14px">LETTER OF AUTHORITY</span>
                </h3>
            </div>

            <div style="font-size: 12px">
                <p>
                    <span style="line-height: 115%">Date: </span>
                </p>
                <p class="MsoNormal" style="margin-bottom: 0in">
                <span style="line-height: 100%">&nbsp;</span>
            </p>
            </div>
            

            <div style="font-size: 12px">
                <p>
                    <b><span>Subject:</span></b>
                    <span> Letter of Appointment for Claim Verification</span>
                </p>
                <p class="MsoNormal" style="margin-bottom: 0in">
                    <span style="line-height: 100%">&nbsp;</span>
                </p>
                <p>
                    <b><span>Reference:</span></b>
                    <span> LOB - {{allData.lob}} | Claim Number - {{allData.claimNumber}} | Claim Type - {{allData.claimType}} | Insured Name - {{allData.insurerence}} | Claimant Name - {{allData.claimantName}} | Loss State & City - {{allData.state}} & {{allData.city}} | Date of Loss - {{formatDateTime(allData.dateOfAdmission)}} </span>
                </p>
                <p class="MsoNormal" style="margin-bottom: 0in">
                  <span style="line-height: 100%">&nbsp;</span>
                </p>
            </div>
            

            <div style="font-size: 12px">
                <p style="margin-bottom: 10px">
                    <span>To,</span>
                </p>
                <p >
                    <span> {{ allData.providerName }} </span>
                </p>
                <p >
                    <span>{{  }} hospital PRN </span>
                </p>
                <p>
                    <span>{{allData.providerAddress }}</span>
                </p>
                <p>
                    <span><< hospital telephoneNo >></span>
                </p>
                <p>
                    <span><< hospital emailId >></span>
                </p>
            </div>


            <div style="margin-top:30px;font-size: 12px">
                <p class="MsoNormal" style="line-height: 20%;">
                    <span>Dear Sir/Madam,</span>
                </p>
            </div>

            <div style="text-align: center;font-size: 14px">
                <p>
                    <span style="line-height: 115%;text-decoration: underline">TO WHOM SO EVER IT MAY CONCERN</span>
                </p>
                <p class="MsoNormal" style="margin-bottom: 0in">
                  <span style="line-height: 100%">&nbsp;</span>
                </p>
            </div>

        <div style="font-size: 12px">
            <p class="MsoNormal" style="margin-bottom: 0in;text-align: justify;">
                We are in receipt of << BenefitName >> Claim  {{ allData.claimNumber }} of << firstName >> << middleName >> << lastName >>, << memberid >>, {{ allData.policyNumber }} who was admitted at {{ allData.providerName }} on {{ formatDateTime(allData.dateOfAdmission) }} till {{ allData.dateOfDischarge }} for << hospitalization due to >> and << diagnosis >>.    
            </p>
            <p>
                We have appointed << investigator Name >>, representative to collect and verify the claim documents like copy of indoor case papers, discharge summary, other treatment papers, etc, on behalf of Tata Aig General Insurance Company Ltd.
            </p>
            <p class="MsoNormal" style="margin-bottom: 0in">
                  <span style="line-height: 100%">&nbsp;</span>
            </p>
            <p>
                Your kind co-operation in this matter shall be highly appreciated.
            </p>
            <p>
                For Tata AIG General Insurance Company Ltd.
            </p>
        </div>

      </div>
    </div>

      <!-- html LetterOfConsentForHospital -->
    <div style="height:0;overflow:hidden;">
      <div class="body1" style="padding: 40px;" id="LetterOfConsentForHospitalpdf">
          
          <div style="text-align: left; margin-left: 90%">
              <img src="../assets/TATAAIGLogo.png" alt="Flow" style="width:60px;height:70px;">            
          </div>

          <div style="text-align: left; margin-left: 65%;font-size: 12px">
              <p>
                  <span style="line-height: 115%">Date: </span>
              </p>
              <br>
          </div>
              


          <div style="font-size: 12px">

              <p style="margin-bottom: 10px">
                  <span>To,</span>
              </p>
              <p >
                  <span> 	MEDICAL SUPERINTENDENT/ RECORD SECTION </span>
              </p>
              <p>
                  __________________________________________
              </p>
              <p>
                  __________________________________________
              </p>
              <br>
              
          </div>

          <div style="text-align: center;font-size: 14px">
              <p>
                  <b><span style="line-height: 115%;text-decoration: underline;">Letter of Consent</span></b>
              </p>
              <br>
          </div>
          
          <div style="font-size: 12px">
              <p class="MsoNormal" style="margin-bottom: 0in;text-align: justify;">
                  I,________________________________, Age,  ___, S/O:D/O ________________________
                  Hereby give my wilful consent to TATA AIG Gen. Ins. Co. Ltd. & its authorised person/agent to verify and collect certified copies of indoor case papers/ history sheet papers/ investigation reports and any other relevant document from your hospital/ clinic for the purpose of my insurance claim settlement.                 
              </p>
              <br>
              <p >
                  <span>Claim Number - {{allData.claimNumber}} </span>
              </p>
              <br>
              <p >
                  <span>Policy Number- {{ allData.policyNumber }} </span>
              </p>
              <br>
              <p >
                  <span>Date of Admission- {{ formatDateTime(allData.dateOfAdmission) }} </span>
              </p>
              <br>
              <p >
                  <span>Date of Discharge- {{ allData.dateOfDischarge }} </span>
              </p>
          </div>
          <div style="font-size: 12px">
              <br>
              <br>
              <p>
                  <span>IP No.	________________</span>
                  <span style="text-align: left; margin-left: 30%">UHID: ________________</span>		
              </p>
              <br>
              <p>
                  Authorised Agency Name: _________________________________________
              </p>
              <br>
              <p>
                  Authorized Person Name:__________________________________________
              </p>
              <br>
              <br>
              <p>        
                  <span>Signature:</span>
                  <span style="text-align: left; margin-left: 37%">Witness Signature:</span>	
              </p>
              <br>
              <p> 
                  <span>Name:</span>
                  <span style="text-align: left; margin-left: 39.5%">Witness Name:</span>
              </p>
              <br>
              <p> 
                  <span>Address:</span>
                  <span style="text-align: left; margin-left: 38%">Address:</span>
              </p>
              <br>
              <br>
              <p> 
                  <span>Phone No:</span>
                  <span style="text-align: left; margin-left: 37%">Phone No:</span>
              </p>
          </div>
      </div>
    </div>
    <div v-if="Letter_dialog">
      <div class="body1" style="padding: 50px;" id="LetterOfConcentForOtherPurpose">
         <header>
             <div style="text-align: left; margin-left: 90%">
                 <img src="../assets/assets/images/TATAAIGLogo.png" alt="Flow" style="width:60px;height:70px;">            
             </div>
         </header>
         <div style="font-size: 12px">
             <p>
                 Date and Time :- {{ ' ' }}
             </p>
         </div>
 
         <div>
             <p style="margin-bottom: 18px">------------------------------------------------------------------------------------------------------------------------------------------------------------------</p>
             <p style="margin-bottom:0px;">To,</p>
             <p style="">______________________________________________________</p>
             <p style="">______________________________________________________</p>
             <p style="">______________________________________________________</p>
             <p style="">______________________________________________________</p>
         </div>
         
         <div style="text-align: center;text-decoration: underline;font-weight: bold; margin-top: 20px;">
             <p style="font-size: 12px;">Letter of Consent</p>
         </div>
 
         <div style="padding: 10px">
             <p>I, ___________________________________________, Age, ________, S/O:D/O ________________________________________</p>
             <p>For claim of_______________________________________________(Relation_________________________________________)</p>
             <p style="text-align: justify;">Hereby give my wilful consent to TATA AIG Gen. Ins. Co. Ltd. & its authorised person/agent to verify and collect certified copies of relevant document from your institution/office for the purpose of my insurance claim settlement.</p>
 
             <p>Claim Number- {{claimNumber}} / Policy Number- {{PolicyNumber}}</p>
             <p>Date of document____________________________________</p>
             <p style="margin-bottom: 18px">------------------------------------------------------------------------------------------------------------------------------------------------------------------</p>
         </div>
         <div>
             <P>Authorised Agency Name: _________________________________________</P>
             <P>Authorized Person Name:__________________________________________</P>
             <p style="margin-bottom: 18px">------------------------------------------------------------------------------------------------------------------------------------------------------------------</p>
         </div>
         <table style="width:800px;font-size: 12px;">
             <tr>
                 <td style="line-height: 200%;">Signature:</td>
                 <td></td>
                 <td>Witness Signature:</td>
                 <td></td>
             </tr>
             <tr>
                 <td style="line-height: 200%;">Name:</td>
                 <td></td>
                 <td>Witness Name:</td>
                 <td></td>
             </tr>
             <tr>
                 <td style="line-height: 200%;">Address:</td>
                 <td></td>
                 <td style="line-height: 200%;">Address:</td>
                 <td></td>
             </tr>
             <tr>
                 <td style="line-height: 200%;"></td>
                 <td style="line-height: 200%;"></td>
                 <td style="line-height: 200%;"></td>
                 <td style="line-height: 200%;"></td>
             </tr>
 
         </table>
         <p style="margin-bottom: 18px">------------------------------------------------------------------------------------------------------------------------------------------------------------------</p>
         <table style="width:580px;font-size: 12px;">
             <tr>
                 <td style="line-height: 200%;">Phone No:</td>
                 <td></td>
                 <td>Phone No:</td>
                 <td></td>
             </tr>
         </table>
 
      </div>
      <div class="body1" style="padding: 50px;" id="PatientQuestionaireForEvaluation">
         <header>
 
             <div style="text-align: left; margin-left: 90%">
                 <img src="../assets/assets/images/TATAAIGLogo.png" alt="Flow" style="width:60px;height:70px;">            
             </div>
             <div>
                 <p style="margin-bottom: 0in; text-align: center" >
                     <b><u><span style="font-size: 16px">TATA AIG GIC LTD</span></u></b>
                 </p>
             </div>
             
             <div>
                 <p style="margin-bottom: 0in; text-align: center" >
                     <b><span class="" style="line-height: 100%;font-size: 12px">LOB - {{allData.lob}} | Claim Number - {{allData.claimNumber}} | Claim Type - {{allData.claimType}} | Insured Name - {{allData.insurerence}} | Claimant Name - {{allData.claimantName}} | Loss State & City - {{allData.state}} & {{allData.city}} | Date of Loss - {{formatDateTime(allData.dateOfAdmission)}}
                     </span></b>
                 </p>
                 <p style="font-weight: bold;font-size: 13px;text-align: center;">Questionnaire for Insured/Patient</p>
                 <p style="font-size: 12px;text-align: center; margin-bottom: 12px;"><i>
                     (PLEASE ANSWER EACH QUESTION SPECIFICALLY WITHOUT USING “--“OR NOT APPLICABLE)
                 </i></p>
             </div>
             </header>
             
             <ol style="font-size: 12px">
             <li style="margin-bottom: 18px;text-align: justify;padding: 3px;">
                 <p style="margin-bottom: 18px;">Please give details about your JOB & workplace? (Add., Contact No. & Your Designation), Are you availing medical Reimbursement facility/ any policy from your company?</p>
                 <p style="margin-bottom: 18px;">------------------------------------------------------------------------------------------------------------------------------------------------------------------</p>
                 <p style="margin-bottom: 18px">------------------------------------------------------------------------------------------------------------------------------------------------------------------</p>
             </li>
             <li style="margin-bottom: 18px;text-align: justify;padding: 3px;">
                 <p style="margin-bottom: 18px">For what complaints you consulted 1st time to doctor this time & when? Please describe complaints in detail. Please provide copies of the prescriptions & investigation reports of treatment taken in the past. </p>
                 <p style="margin-bottom: 18px">------------------------------------------------------------------------------------------------------------------------------------------------------------------</p>
                 <p style="margin-bottom: 18px">------------------------------------------------------------------------------------------------------------------------------------------------------------------</p>
             </li>
             <li style="margin-bottom: 18px;text-align: justify;padding: 3px;">
                 <p style="margin-bottom: 18px">What was the date & time of Admission & Discharge in Hospital? Name the Hospital? </p>
                 <p style="margin-bottom: 18px">------------------------------------------------------------------------------------------------------------------------------------------------------------------</p>
                 <p style="margin-bottom: 18px">------------------------------------------------------------------------------------------------------------------------------------------------------------------</p>
             </li>
             <li style="margin-bottom: 18px;text-align: justify;padding: 3px;">
                 <p style="margin-bottom: 18px">What was the mode of payment to Hospital/Doctor bill? Give details?</p>
                 <p style="margin-bottom: 18px">------------------------------------------------------------------------------------------------------------------------------------------------------------------</p>
                 <p style="margin-bottom: 18px">------------------------------------------------------------------------------------------------------------------------------------------------------------------</p>
             </li>
             <li style="margin-bottom: 18px;text-align: justify;padding: 3px;">
                 <p style="margin-bottom: 18px">Please specify whether the hospitalization done for investigation, evaluation and diagnosis purpose.</p>
                 <p style="margin-bottom: 18px">------------------------------------------------------------------------------------------------------------------------------------------------------------------</p>
                 <p style="margin-bottom: 18px">------------------------------------------------------------------------------------------------------------------------------------------------------------------</p>
             </li>
 
             <li style="margin-bottom: 18px;text-align: justify;padding: 3px;">
                 <p style="margin-bottom: 18px">How many days bed rest was advised to you by doctor after discharge?</p>
                 <p style="margin-bottom: 18px">------------------------------------------------------------------------------------------------------------------------------------------------------------------</p>
                 <p style="margin-bottom: 18px">------------------------------------------------------------------------------------------------------------------------------------------------------------------</p>
             </li>
             <li style="margin-bottom: 18px;text-align: justify;padding: 3px; margin-top: 50px;">
                 <p style="margin-bottom: 18px">Please give details of any chronic ailment/ regular medicine you are taking, since how long? (like Hypertension, diabetes, hypothyroidism, or any other genetic disorder).</p>
                 <p style="margin-bottom: 18px">------------------------------------------------------------------------------------------------------------------------------------------------------------------</p>
                 <p style="margin-bottom: 18px">------------------------------------------------------------------------------------------------------------------------------------------------------------------</p>
             </li>
             <li style="margin-bottom: 18px;text-align: justify;padding: 3px;">
                 <p style="margin-bottom: 18px">From which lab investigation was done, give details of the same (Cash, credit card or cheque payment)?</p>
                 <p style="margin-bottom: 18px">------------------------------------------------------------------------------------------------------------------------------------------------------------------</p>
                 <p style="margin-bottom: 18px">------------------------------------------------------------------------------------------------------------------------------------------------------------------</p>
             </li>
             <li style="margin-bottom: 18px;text-align: justify;padding: 3px;">
                 <p style="margin-bottom: 18px">Please mention details whether current hospitalization done for evaluation and diagnosis of cause of?</p>
                 <p style="margin-bottom: 18px">------------------------------------------------------------------------------------------------------------------------------------------------------------------</p>
                 <p style="margin-bottom: 18px">------------------------------------------------------------------------------------------------------------------------------------------------------------------</p>
             </li>
             <li style="margin-bottom: 18px;text-align: justify;padding: 3px;">
                 <p style="margin-bottom: 18px">Please give details of other policies you are holding except this?</p>
                 <p style="margin-bottom: 18px">------------------------------------------------------------------------------------------------------------------------------------------------------------------</p>
                 <p style="margin-bottom: 18px">------------------------------------------------------------------------------------------------------------------------------------------------------------------</p>
             </li>
             <li style="margin-bottom: 18px;text-align: justify;padding: 3px;">
                 <p style="margin-bottom: 18px">Please provide details of any previous hospitalizations or surgeries done in past.</p>
                 <p style="margin-bottom: 18px">------------------------------------------------------------------------------------------------------------------------------------------------------------------</p>
                 <p style="margin-bottom: 18px">------------------------------------------------------------------------------------------------------------------------------------------------------------------</p>
             </li>
             <li style="margin-bottom: 18px;text-align: justify;padding: 3px;">
                 <p style="margin-bottom: 18px">Please provide details of your personal habits. (Alcohol/ Smoking/ Tobacco/Etc.)</p>
                 <p style="margin-bottom: 18px">------------------------------------------------------------------------------------------------------------------------------------------------------------------</p>
                 <p style="margin-bottom: 18px">------------------------------------------------------------------------------------------------------------------------------------------------------------------</p>
             </li>
             </ol>
 
             <ul style="font-size: 12px; margin-top: 15px; margin-bottom: 15px;">
                 <li>Please give copies of relevant documents (Previous investigations / consultation notes / medical store bills & discharge card)</li>
                 <li>Answers given by me for above mention questions are true to best of my knowledge.</li>
                 <li>give consent and authorize Insurance Company To collect relevant document from Hospital.</li>
             </ul>
 
             <footer>
                 <table style="border: none;font-size: 12px;width: 550.65pt;margin-left: 0pt;border-collapse: collapse;">
                     <tr style="line-height:-90px;">
                         <td></td>
                         <td>Patient</td>
                         <td>Witness</td>
                     </tr>
                         <tr style="line-height: 20px;">
                             <td>Name:</td>
                             <td>____________________________________</td>
                             <td>____________________________________</td>
                         </tr>
                         <tr style="line-height: 20px;">
                             <td>.</td>
                             <td></td>
                             <td></td>
                         </tr>
                         <tr style="line-height: 25px;">
                             <td>Signature:</td>
                             <td>____________________________________</td>
                             <td>____________________________________</td>
                         </tr>
                         <tr style="line-height: 25px;">
                             <td>Date:</td>
                             <td>____________________________________</td>
                             <td>____________________________________</td>
                         </tr>
                         <tr style="line-height: 25px;">
                             <td>Contact No:</td>
                             <td>____________________________________</td>
                             <td>____________________________________</td>
                         </tr>
                 </table>
             </footer>
      </div>
      <div class="body1" style="padding: 50px;" id="TDSForAllCases">
         <header>
 
             <div style="text-align: left; margin-left: 90%">
                 <img src="../assets/assets/images/TATAAIGLogo.png" alt="Flow" style="width:60px;height:70px;">            
             </div>
             <div>
                 <p style="margin-bottom: 0in; text-align: center" >
                     <b><u><span style="font-size: 14px">TATA AIG General Insurance Company Ltd.</span></u></b>
                 </p>
             </div>
             
             <div>
                 <p style="margin-bottom: 0in; text-align: center" >
                     <b><span class="" style="line-height: 100%;font-size: 12px">LLOB - {{allData.lob}} | Claim Number - {{allData.claimNumber}} | Claim Type - {{allData.claimType}} | Insured Name - {{allData.insurerence}} | Claimant Name - {{allData.claimantName}} | Loss State & City - {{allData.state}} & {{allData.city}} | Date of Loss - {{formatDateTime(allData.dateOfAdmission)}}
                     </span></b>
                 </p>
                 <p style="font-size: 14px;text-align: center;font-weight: bold;">
                     Treating Doctor Statement
                 </p>
             </div>
             </header>
 
             <div style="font-size: 12px;">
               <p>For Dr.  ............................................................................................................................................................................... </p>
               <p>Name of the patient............................................................................................................................................................ </p>
               <p>D.O.A. ............................................................................................................................................................................... </p>
               <p>Name of the Hospital......................................................................................................................................................... </p>
             </div>
             
             <ol style="font-size: 12px">
             <li style="text-align: justify;padding: 3px;">
                 <p style="margin-bottom: 18px;">When did you see this patient for the first time?</p>
                 <p style="margin-bottom: 18px;">
                 -----------------------------------------------------------------------------------------------------------------------------------------------------------------
                 </p>
                 <p>
                 ----------------------------------------------------------------------------------------------------------------------------------------------------------------
                 </p>
             </li>
             <li style="text-align: justify;padding: 3px;">
                 <p style="margin-bottom: 18px">Please tell presenting complaints with duration, Past History & reason for hospitalization of this patient.</p>
                 <p style="margin-bottom: 18px;">
                 ---------------------------------------------------------------------------------------------------------------------------------------------------------------
                 </p>
                 <p>
                 ----------------------------------------------------------------------------------------------------------------------------------------------------------------
                 </p>
             </li>
             <li style="text-align: justify;padding: 3px;">
                 <p style="margin-bottom: 18px;">What is the diagnosis? What is the etiology of the same?</p>
                 <p style="margin-bottom: 18px;">
                 ------------------------------------------------------------------------------------------------------------------------------------------------------------------
                 </p>
                 <p>
                 ------------------------------------------------------------------------------------------------------------------------------------------------------------------
                 </p>
             </li>
             <li style="text-align: justify;padding: 3px;">
                 <p style="margin-bottom: 18px;">Please describe whether current diagnosis related to alcohol consumption? Since how long patient was taking alcohol?</p>
                 <p style="margin-bottom: 18px;">
                 -------------------------------------------------------------------------------------------------------------------------------------------------------------------
                 </p>
                 <p>
                 ------------------------------------------------------------------------------------------------------------------------------------------------------------------
                 </p>
             </li>
             <li style="text-align: justify;padding: 3px;">
                 <p style="margin-bottom: 18px;">Please provide details of any HTN/DM/Chronic problem/Genetic Disorder if present in this case. Also provide details of treatment given and duration.</p>
                 <p style="margin-bottom: 18px;">
                 -------------------------------------------------------------------------------------------------------------------------------------------------------------------
                 </p>
                 <p>
                 ------------------------------------------------------------------------------------------------------------------------------------------------------------------
                 </p>
             </li>
 
             <li style="text-align: justify;padding: 3px;">
                 <p style="margin-bottom: 18px;">Please provide details of any previous surgery or hospitalization of the patient.</p>
                 <p style="margin-bottom: 18px;">
                 -----------------------------------------------------------------------------------------------------------------------------------------------------------------
                 </p>
                 <p>
                 -----------------------------------------------------------------------------------------------------------------------------------------------------------------
                 </p>
             </li>
             </ol>
             <div style="font-size: 12px;text-align: left;text-decoration: underline;">
               <p style="padding-bottom: 30px;">Signature with stamp and Reg. No:</p>
               <p>Date:</p>
 
             </div>
      </div>
      <div class="body1" style="padding: 50px;" id="TDSForCGD">
         <header>
 
             <div style="text-align: left; margin-left: 90%">
                 <img src="../assets/assets/images/TATAAIGLogo.png" alt="Flow" style="width:60px;height:70px;">            
             </div>
             <div>
                 <p style="margin-bottom: 0in; text-align: center" >
                     <b><u><span style="font-size: 14px">TATA AIG General Insurance Company Ltd.</span></u></b>
                 </p>
             </div>
             
             <div>
                 <p style="margin-bottom: 0in; text-align: center" >
                     <b><span class="" style="line-height: 100%;font-size: 12px">LOB - {{allData.lob}} | Claim Number - {{allData.claimNumber}} | Claim Type - {{allData.claimType}} | Insured Name - {{allData.insurerence}} | Claimant Name - {{allData.claimantName}} | Loss State & City - {{allData.state}} & {{allData.city}} | Date of Loss - {{formatDateTime(allData.dateOfAdmission)}}
                     </span></b>
                 </p>
                 <p style="font-size: 18 px;text-align: center;font-weight: bold;">
                     Treating Doctor Statement
                 </p>
             </div>
             </header>
 
             <div style="font-size: 12px;">
               <p>For Dr.  ............................................................................................................................................................................... </p>
               <p>Name of the patient............................................................................................................................................................ </p>
               <p>D.O.A. ............................................................................................................................................................................... </p>
               <p>Name of the Hospital......................................................................................................................................................... </p>
             </div>
             
             <ol style="font-size: 12px">
             <li style="text-align: justify;padding: 3px;">
                 <p style="margin-bottom: 18px;">When did you see this patient for the first time? For what complaint?</p>
                 <p style="margin-bottom: 18px;">
                 -----------------------------------------------------------------------------------------------------------------------------------------------------------------
                 </p>
                 <p>
                 -----------------------------------------------------------------------------------------------------------------------------------------------------------------
                 </p>
             </li>
             <li style="text-align: justify;padding: 3px;">
                 <p style="margin-bottom: 18px;">Have you seen this patient on O.P.D Basis before hospitalization? If Yes Since When?</p>
                 <p style="margin-bottom: 18px;">
                 -----------------------------------------------------------------------------------------------------------------------------------------------------------------
                 </p>
                 <p>
                 -----------------------------------------------------------------------------------------------------------------------------------------------------------------
                 </p>
             </li>
             <li style="text-align: justify;padding: 3px;">
                 <p style="margin-bottom: 18px;">Please tell presenting complaints with duration, Past History & reason for hospitalization of this patient.</p>
                 <p style="margin-bottom: 18px;">
                 -----------------------------------------------------------------------------------------------------------------------------------------------------------------
                 </p>
                 <p>
                 -----------------------------------------------------------------------------------------------------------------------------------------------------------------
                 </p>
             </li>
             <li style="text-align: justify;padding: 3px;">
                 <p style="margin-bottom: 18px;">What is the diagnosis? What is the etiology of the disease?</p>
                 <p style="margin-bottom: 18px;">
                 -----------------------------------------------------------------------------------------------------------------------------------------------------------------
                 </p>
                 <p>
                 -----------------------------------------------------------------------------------------------------------------------------------------------------------------
                 </p>
             </li>
             <li style="text-align: justify;padding: 3px;">
                 <p style="margin-bottom: 18px;">Give details whether diagnosis is related to Chronic disease or Genetic/ Congenital disorder.</p>
                 <p style="margin-bottom: 18px;">
                 -----------------------------------------------------------------------------------------------------------------------------------------------------------------
                 </p>
                 <p>
                 -----------------------------------------------------------------------------------------------------------------------------------------------------------------
                 </p>
             </li>
 
             <li style="text-align: justify;padding: 3px;">
                 <p style="margin-bottom: 18px;">Please give details of any previous hospitalizations/ treatment if any.</p>
                 <p style="margin-bottom: 18px;">
                 -----------------------------------------------------------------------------------------------------------------------------------------------------------------
                 </p>
                 <p>
                 -----------------------------------------------------------------------------------------------------------------------------------------------------------------
                 </p>
             </li>
             </ol>
             <div style="font-size: 12px;text-align: left;text-decoration: underline;">
               <p style="padding-bottom: 40px;">Signature with stamp:</p>
               <p>Date:</p>
 
             </div>
      </div>
      <div class="body1" style="padding: 50px;" id="TDSInCaseOfPoisoning">
         <header>
 
             <div style="text-align: left; margin-left: 90%">
                 <img src="../assets/assets/images/TATAAIGLogo.png" alt="Flow" style="width:60px;height:70px;">            
             </div>
             <div>
                 <p style="margin-bottom: 0in; text-align: center" >
                     <b><u><span style="font-size: 14px">TATA AIG General Insurance Company Ltd.</span></u></b>
                 </p>
             </div>
             
             <div>
                 <p style="margin-bottom: 0in; text-align: center" >
                     <b><span class="" style="line-height: 100%;font-size: 12px">LOB - {{allData.lob}} | Claim Number - {{allData.claimNumber}} | Claim Type - {{allData.claimType}} | Insured Name - {{allData.insurerence}} | Claimant Name - {{allData.claimantName}} | Loss State & City - {{allData.state}} & {{allData.city}} | Date of Loss - {{formatDateTime(allData.dateOfAdmission)}}
                     </span></b>
                 </p>
                 <p style="font-size: 18 px;text-align: center;font-weight: bold;">
                     Treating Doctor Statement
                 </p>
             </div>
             </header>
 
             <div style="font-size: 12px;">
               <p>For Dr.  ............................................................................................................................................................................................ </p>
               <p>Name of the patient......................................................................................................................................................................... </p>
               <p>D.O.A. ..................................................................................... D.O.D .......................................................................................... </p>
               <p>Name of the Hospital...................................................................................................................................................................... </p>
             </div>
             
             <ol style="font-size: 12px">
             <li style="text-align: justify;padding: 3px;">
                 <p style="margin-bottom: 18px;">When did you see this patient for the first time?</p>
                 <p style="margin-bottom: 18px;">
                 -----------------------------------------------------------------------------------------------------------------------------------------------------------------
                 </p>
                 <p>
                 ----------------------------------------------------------------------------------------------------------------------------------------------------------------
                 </p>
             </li>
             <li style="text-align: justify;padding: 3px;">
                 <p style="margin-bottom: 18px;">Please tell presenting complaints with duration, Past History & reason for hospitalization of this patient.</p>
                 <p style="margin-bottom: 18px;">
                 ----------------------------------------------------------------------------------------------------------------------------------------------------------------
                 </p>
                 <p>
                 ---------------------------------------------------------------------------------------------------------------------------------------------------------------
                 </p>
             </li>
             <li style="text-align: justify;padding: 3px;">
                 <p style="margin-bottom: 18px;">What is the diagnosis? What is the etiology of the same?</p>
                 <p style="margin-bottom: 18px;">
                 ---------------------------------------------------------------------------------------------------------------------------------------------------------------
                 </p>
                 <p>
                 --------------------------------------------------------------------------------------------------------------------------------------------------------------
                 </p>
             </li>
             <li style="text-align: justify;padding: 3px;">
                 <p style="margin-bottom: 18px;">Please describe the nature of poisoning and mode of taking the poison?</p>
                 <p style="margin-bottom: 18px;">
                 ---------------------------------------------------------------------------------------------------------------------------------------------------------------
                 </p>
                 <p>
                 --------------------------------------------------------------------------------------------------------------------------------------------------------------
                 </p>
             </li>
 
             <li style="text-align: justify;padding: 3px;">
                 <p style="margin-bottom: 18px;">Please specify whether poisoning was self inflicted in nature or accidental?</p>
                 <p style="margin-bottom: 18px;">
                 --------------------------------------------------------------------------------------------------------------------------------------------------------------
                 </p>
                 <p>
                 --------------------------------------------------------------------------------------------------------------------------------------------------------------
                 </p>
             </li>
             <li style="text-align: justify;padding: 3px;">
                 <p style="margin-bottom: 18px;">Please describe the circumstances for ingestion of poison as describes by the patient and his accompanying persons.</p>
                 <p style="margin-bottom: 18px;">
                 -------------------------------------------------------------------------------------------------------------------------------------------------------------
                 </p>
                 <p>
                 -------------------------------------------------------------------------------------------------------------------------------------------------------------
                 </p>
             </li>
             <li style="text-align: justify;padding: 3px;">
                 <p style="margin-bottom: 18px;">Please describe whether poison was in liquid or solid (powder) form. Its color, odor and other characteristics. </p>
                 <p style="margin-bottom: 18px;">
                 --------------------------------------------------------------------------------------------------------------------------------------------------------------
                 </p>
                 <p>
                 -------------------------------------------------------------------------------------------------------------------------------------------------------------
                 </p>
             </li>
             <li style="text-align: justify;padding: 3px;">
                 <p style="margin-bottom: 18px;">Please provide details of treatment given and amount of alleged poison taken by the patient.</p>
                 <p style="margin-bottom: 18px;">
                 ---------------------------------------------------------------------------------------------------------------------------------------------------------------
                 </p>
                 <p>
                 ---------------------------------------------------------------------------------------------------------------------------------------------------------------
                 </p>
             </li>
             </ol>
             <div style="font-size: 12px;text-align: left;text-decoration: underline;">
               <p style="padding-bottom: 10px;">Signature with stamp and Reg. No:</p>
               <p>Date:</p>
 
             </div>
     </div>
    </div>
    <!-- Letter dialogue -->
    <v-dialog v-model="Letter_dialog" width="800">
      <v-card tile>
        <v-card-title class="py-2" style="border: 0.5px solid #c1c8cc">
          SIU Letters
          <v-spacer></v-spacer>
          <v-icon @click="Letter_dialog = !Letter_dialog" class=""
            >mdi-window-close</v-icon
          >
        </v-card-title>

        <v-simple-table fixed-header height="250px" class="mx-4" :key="upKey">
          <template v-slot:default>
            <tbody>
              <tr v-for="(item, index) in letterItems" :key="index">
                <td class="">
                  <!-- <v-card tile class="mx-2 pa-1 " style="border: 0.5px solid #c1c8cc;"> -->
                  <!-- <a :href="item.link" target="blank">{{ item.link }}</a> -->
                  <p v-if="item.value=='ClaimantQuestionaireForDeathClaim'" @click="openAllSIUletter(item)" class="pa-0 ma-0">{{ item.link }}</p>
                  <p v-if="item.value=='CustomerFeedbackForm'" @click="openAllSIUletter(item)" class="pa-0 ma-0">{{ item.link }}</p>
                  <p v-if="item.value=='InsuredStatementBlankCopy'" @click="openAllSIUletter(item)" class="pa-0 ma-0">{{ item.link }}</p>
                  <p v-if="item.value=='LetterOfAppointmentForInvestigator'" @click="openAllSIUletter(item)" class="pa-0 ma-0">{{ item.link }}</p>
                  <p v-if="item.value=='LetterOfAuthorityForHospital'" @click="openAllSIUletter(item)" class="pa-0 ma-0">{{ item.link }}</p>
                  <p v-if="item.value=='LetterOfConsentForHospital'" @click="openAllSIUletter(item)" class="pa-0 ma-0">{{ item.link }}</p>
                  <p v-if="item.value=='LetterOfConcentForOtherPurpose'" @click="openAllSIUletter(item)" class="pa-0 ma-0">{{ item.link }}</p>
                  <p v-if="item.value=='PatientQuestionaireForEvaluation'" @click="openAllSIUletter(item)" class="pa-0 ma-0">{{ item.link }}</p>
                  <p v-if="item.value=='TDSForAllCases'" @click="openAllSIUletter(item)" class="pa-0 ma-0">{{ item.link }}</p>
                  <p v-if="item.value=='TDSForCGD'" @click="openAllSIUletter(item)" class="pa-0 ma-0">{{ item.link }}</p>
                  <p v-if="item.value=='TDSInCaseOfPoisoning'" @click="openAllSIUletter(item)" class="pa-0 ma-0">{{ item.link }}</p>
                  <!-- </v-card> -->
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
    </v-dialog>
    <InvestigationFooterCard
      :tabno="sno"
      :formdata="getFormData"
      :tabFormDataId="tabFormDataId"
      v-if="claimDetails"
    />
  </div>
</template>

<script>
import ClaimDetailsTab from "./ClaimDetailsTab.vue";
import IllnessDetailsTab from "./IllnessDetailsTab.vue";
import SiuRefferalDetailsTab from "./SiuRefferalDetailsTab.vue";
import PolicyDetailsTab from "./PolicyDetailsTab.vue";
import PayerDetailsTab from "./PayerDetailsTab.vue";
import InsuredDetailsTab from "./InsuredDetailsTab.vue";
import InvestigationFooterCard from "./InvestigationFooterCard.vue";
import SiuNotes from "./SiuNotes.vue";
import { getAllWoritemList } from "../apiservices/Workitem.js";
// import GenericViewDocument from '@tataaig/genericviewdocument'
import { REDIRECT_TO_CLAIMSEARCHVIEW } from "../common/API_Config";
import SiuClaimDocuments from './SiuClaimDocuments.vue';


import { getWoritemDetailsById } from "../apiservices/Workitem.js";

import jsPDF from "jspdf";

// import InsuredStatementBlankCopy from "./InsuredStatementBlankCopy.html";

import workerMixin from '@/mixins/workerMixin';
import moment from "moment";
export default {
  mixins: [workerMixin],
  data() {
    return {
      showClaimDocuments: false,
      allData:[],
      sno: 22,
      claimDetails: true,
      illnessDetails: false,
      siuReferralDetails: false,
      siuNotes: false,
      policyDetails: false,
      memberDetails: false,
      payerDetails: false,
      insuredDetails: false,
      claimAdjudicatorRemarks: "",
      Trigger_dialog: false,
      manualDetails: [],
      breDetails: [],
      BREtrigger: "2",
      manualTrigger: "4",
      totalScore: "5",
      Letter_dialog: false,
      Document_dialog: false,
      // breTriggerList: [],
      breTriggerTotalScore: 0,
      manualTriggerTotalScore: 0,
      manualTriggerList: [],
      messagePopup:false,
      worker: null,

      letterItems: [
        { link: "Customer Feedback Form" , value:'CustomerFeedbackForm'},
        { link: "Insured Statement Blank Copy"  , value:'InsuredStatementBlankCopy'},
        { link: "Letter Of Appointment For Investigator"  , value:'LetterOfAppointmentForInvestigator'},
        { link: "Letter Of Authority For Hospital"  , value:'LetterOfAuthorityForHospital'},
        { link: "Letter Of Consent For Hospital"  , value:'LetterOfConsentForHospital'},
        { link: "Claimant Questionaire For Death Claim"  , value:'ClaimantQuestionaireForDeathClaim'},
        { link: "Letter Of Concent For Other Purpose", value: 'LetterOfConcentForOtherPurpose' },
        { link: "Patient Questionaire For Evaluation", value: 'PatientQuestionaireForEvaluation' },
        { link: "TDS For All Cases", value: 'TDSForAllCases' },
        { link: "TDS For CGD", value: 'TDSForCGD' },
        { link: "TDS In Case Of Poisoning", value: 'TDSInCaseOfPoisoning' },

        
        

        
        { link: "aaaaa aaaaaaa loaaa weeer34" },
      ],
      incmData: "",

      headers: [
        {
          text: "Sr.No.",
          value: "sNo",
          width: 50,
          sortable: false,
          align: "center",
        },
        {
          text: "User Name",
          value: "userName",
          width: 142,
          sortable: false,
          align: "center",
        },
        {
          text: "Document Type",
          value: "document_type",
          align: "center",
          sortable: false,
          width: "150px",
        },
        {
          text: "Document Name",
          value: "document_checklist",
          width: "150px",
          sortable: false,
          align: "center",
        },
        {
          text: "Date & Time",
          value: "documentDate",
          sortable: false,
          width: 100,
          align: "center",
        },
        {
          text: "Photocopy",
          sortable: false,
          value: "photocopy",
          width: "100px",
          align: "center",
        },
        {
          text: "Reason",
          sortable: false,
          value: "docWOreason",
          width: "100px",
          align: "center",
        },
        {
          text: "View",
          sortable: false,
          value: "view",
          width: 100,
          align: "center",
        },
      ],

      documentData: [],
    };
  },

  async created() {

    this.$store.commit("SAVE_PAGE_HEADER", "SIU General Details");
    this.allData = this.$route.params.allData;
    console.log('allData', this.allData);
    this.documentData = this.$route.params.allData.documents;
    this.documentData.map((el, i) => {return el.sNo = i+1});
    console.log(this.$route.params.allData);
    try {
      let res = await getAllWoritemList();
      let allBreTriggerList = [];
      let allManualTriggersList = [];
      res.data.data.list.forEach((item) => {
        if (item.triggerDetails && item.triggerDetails.length) {
          allBreTriggerList.push(item.triggerDetails);
        }
        if (item.manualDetails && item.manualDetails.length) {
          allManualTriggersList.push(item.manualDetails);
        }
        
      });
      allBreTriggerList = allBreTriggerList.flat(1);
      allManualTriggersList = allManualTriggersList.flat(1);
      // this.breTriggerList = [...allBreTriggerList];
      this.manualTriggerList = [...allManualTriggersList];
      this.calculateScores();

      let res1 = await getWoritemDetailsById(this.$route.params.workitemId);
      console.log("res.data.data selected -------->>>",res1.data.data[0]);
      this.allData=res1.data.data[0]
      console.log("this.allData selected -------->>>",this.allData);
              
    } catch (err) {
      console.log(err);
    }

    // try{


    // }catch{

    // }
  },
  props: ['prepoulatedData', "siuWorkItemId", "tabFormDataId"],

  computed: {
    getFormData() {
      return this.$data;
    }
  },
  components: {
    InvestigationFooterCard,
    ClaimDetailsTab,
    IllnessDetailsTab,
    SiuRefferalDetailsTab,
    PolicyDetailsTab,
    PayerDetailsTab,
    InsuredDetailsTab,
    SiuNotes,
    // GenericViewDocument,
    SiuClaimDocuments,
},
  methods: {
    showHideClaimDocsPopup(val) {
      this.showClaimDocuments = val;
    },
    openClaimDocs() {
      this.showClaimDocuments = true;
    },
    downloadPolicyForm() {
					let apiUrl = "spa/get-policy-doc?proposalDate=11%2F11%2F2022&proposalNumber="+this.$route.params.allData.proposalNumber;
					let documentType = 'Policy Copy';
					let ext = 'pdf';
					let viewFlag = true;

					this.createWorker2(documentType, ext, null, this.authHeader(), viewFlag, apiUrl).then((downloadResponseMessage) => {
                this.showToast(downloadResponseMessage, this.TOST().SUCCESS)
              }).catch((error) => {
                if (error == "Failure")
                    this.messagePopup = true
                else
                    this.showToast(error, this.TOST().ERROR)
              }).finally(() => {
                  this.terminateWorker();
              })
			  this.worker.postMessage({ url: apiUrl });
    },
    formatDateTime(dateTime) {
      return dateTime ? moment(dateTime).format("DD-MM-YYYY") : "";
    },
    switchTab(tabName) {
      // this[tabName] = true;
      let tabs = [
        "claimDetails",
        "illnessDetails",
        "siuReferralDetails",
        "policyDetails",
        "memberDetails",
        "insuredDetails",
        "payerDetails",
        "siuNotes",
      ];
      tabs.forEach((el) => {
        if (el == tabName) {
          this[el] = true;
        } else {
          this[el] = false;
        }
      });
    },
    calculateScores() {
      this.breTriggerList.map((item) => {
        this.breTriggerTotalScore =
          this.breTriggerTotalScore + parseInt(item.individualTriggerScore);
      });
      this.manualTriggerList.map((item) => {
        this.manualTriggerTotalScore =
          this.manualTriggerTotalScore + parseInt(item.score);
      });
    },
    redirectToClaimHome(apiis) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(apiis);
        }, 1000);
      });
    },
    async goToClaimDetails(val) {
      try {
        let sellob = {
          lob: "",
          usr_jti:
            this.$store.state.Auth.user.signInUserSession.idToken.payload.jti,
          metaVal: "siu_Version" + "=" + val.claimRegistrationId,
        };
        sellob.lob = "Health";
        let apiis =
          this.cognito_login() +
          "session/redirect?lob=" +
          sellob.lob +
          "&jti=" +
          sellob.usr_jti +
          "&metadata=" +
          sellob.metaVal;
          let obj = {
            claimNumber:this.$route.params.claimNumber,
            featureNumber:this.$route.params.featureNumber,
            memberId:this.$route.params.memberId,
            policyNumber:this.$route.params.policyNumber
          }
          document.cookie = 'redirect=claimsearch;domain=.salesdrive.app'
          document.cookie='redirectObj=' + JSON.stringify(obj) + ';domain=.salesdrive.app'
          window.open(apiis, "_blank");
        return;
      } catch (errct) {
        console.log("error from catch re-direction", errct);
      }
    },

    goToViewDocument() {
      this.Document_dialog = true;
    },

    goToBRETrigges() {
      this.Trigger_dialog = true;
    },

    goToSIULettersLibrary() {
      this.Letter_dialog = true;
    },

    openAllSIUletter(val){
        console.log("openAllSIUletter selected tab-------->>>",val);

      var doc = new jsPDF("p", "mm", [800,1000]);
      doc.addFont("Roboto", "normal");
      doc.setFont("Roboto");

          if (val.value =='CustomerFeedbackForm') {
            var pdfjs = document.querySelector("#CustomerFeedbackFormpdf");
            doc.html(pdfjs, {
              callback: function(doc) {
                doc.save("CustomerFeedbackForm");
              },
              x:20,
              y: 20,
            });
          };
          
          if (val.value =='InsuredStatementBlankCopy') {
            var pdfjs = document.querySelector("#InsuredStatementBlankCopypdf");
            doc.html(pdfjs, {
              callback: function(doc) {
                doc.save("InsuredStatementBlankCopy");
              },
              x:20,
              y: 20,
            });
          };
            
          if (val.value =='LetterOfAppointmentForInvestigator') {
            var pdfjs = document.querySelector("#LetterOfAppointmentForInvestigatorpdf");
            doc.html(pdfjs, {
              callback: function(doc) {
                doc.save("LetterOfAppointmentForInvestigator");
              },
              x:20,
              y: 20,
            });
          };

          if (val.value =='LetterOfAuthorityForHospital') {
            var pdfjs = document.querySelector("#LetterOfAuthorityForHospitalpdf");
            doc.html(pdfjs, {
              callback: function(doc) {
                doc.save("LetterOfAuthorityForHospital");
              },
              x:20,
              y: 20,
            });
          };

          if (val.value =='LetterOfConsentForHospital') {
            var pdfjs = document.querySelector("#LetterOfConsentForHospitalpdf");
            doc.html(pdfjs, {
              callback: function(doc) {
                doc.save("LetterOfConsentForHospital");
              },
              x:20,
              y: 20,
            });
          };

          if (val.value =='ClaimantQuestionaireForDeathClaim') {
            var pdfjs = document.querySelector("#ClaimantQuestionaireForDeathClaimpdf");
            doc.html(pdfjs, {
              callback: function(doc) {
                doc.save("ClaimantQuestionaireForDeathClaim");
              },
              x:20,
              y: 20,
            });
          };

         if(val.value =='LetterOfConcentForOtherPurpose'){
          var pdfjs = document.querySelector("#LetterOfConcentForOtherPurpose");
          doc.html(pdfjs, {
            callback: function(doc) {
              doc.save("LetterOfConcentForOtherPurpose");
            },
            x:20,
            y: 20,
          });
          
        }
        if(val.value =='PatientQuestionaireForEvaluation'){
         var pdfjs = document.querySelector("#PatientQuestionaireForEvaluation");
         doc.html(pdfjs, {
           callback: function(doc) {
             doc.save("PatientQuestionaireForEvaluation");
           },
           x:20,
           y: 20,
         });
       }  
       if(val.value =='ClaimantQuestionaireForDeathClaim'){
         var pdfjs = document.querySelector("#ClaimantQuestionaireForDeathClaim");
         doc.html(pdfjs, {
           callback: function(doc) {
             doc.save("ClaimantQuestionaireForDeathClaim");
           },
           x:20,
           y: 20,
         });
         
       }
       if(val.value =='TDSForAllCases'){
         var pdfjs = document.querySelector("#TDSForAllCases");
         doc.html(pdfjs, {
           callback: function(doc) {
             doc.save("TDSForAllCases");
           },
           x:20,
           y: 20,
         });
       }
       if(val.value =='TDSForCGD'){
         var pdfjs = document.querySelector("#TDSForCGD");
         doc.html(pdfjs, {
           callback: function(doc) {
             doc.save("TDSForCGD");
           },
           x:20,
           y: 20,
         });
       }
        if(val.value =='TDSInCaseOfPoisoning'){
         var pdfjs = document.querySelector("#TDSInCaseOfPoisoning");
         doc.html(pdfjs, {
           callback: function(doc) {
             doc.save("TDSInCaseOfPoisoning");
           },
           x:20,
           y: 20,
         });
       }

          

                
    },



    // for Documents
    // getDocuments(){
    //     this.GET(8,""+claimNum,(res,err) => {
    //         if(!err){
    //             let incmData = res.data.data;
    //             incmData.forEach(data => {
    //                         let dataNeeded = {
    //                                     documentType:data.documentType,
    //                                     documentName: data.documentName,
    //                                     fileIs:{
    //                                         fileLink:data.fileLink
    //                                     },
    //                                     dateOfDoc:moment(data.createdAt).format('L'),
    //                                     checkType:'Investigation'
    //                             }
    //                         dataNeeded['from'] = 'Inward'
    //                         // fileData.from ? fileData.from : ''
    //                         this.documentData.push(dataNeeded)
    //                     })
    //         }else{
    //             this.showToast(error.response.data.msg, this.TOST().WARNING);

    //         }
    //     })
    // },

    getextension(filepath) {
      var ext = /^.+\.([^.]+)$/.exec(filepath);
      return ext == null ? "" : ext[1];
    },
        /**
         * downloads the document received from the API, 
         * and shows the popup to redirect to the omnidocs
         * if the response status is Failure
         * @param {Object} fileDetails contains the file details
         * @param {Boolean} viewFlag flag value to view or to download
         */
         openFile(fileDetails, viewFlag) {
          console.log(fileDetails);
            const fileUrl = fileDetails.fileIs?.fileUrl || fileDetails.fileIs?.fileLink || fileDetails.fileLink;
            const documentType = fileDetails.documentType || fileDetails.document_type;
            const documentName = fileDetails.documentName || fileDetails.fileName || fileDetails.fileKey;
            const fileKey = fileDetails.fileIs?.fileKey || fileDetails.fileKey;
            const bucket = fileDetails.fileIs?.bucket || fileDetails.bucket;
            const ext = this.getextension(documentName)
            if(fileDetails.documentType == 'Policy Details'){
                 this.$refs.clientDocForm.submit()
            }
            let endpointExtension
            let fileName = fileDetails.from == "Inward" ? `${documentName}.${this.getextension(documentName)}` : `${documentName}.pdf`
            if(!fileDetails.from == "Inward") endpointExtension = `location=OmniDocs&omnidocsId=${fileDetails.documentId}&fileName=${documentName}`
            else endpointExtension = `location=AWS&fileKey=${fileKey}&fileName=${documentName}&bucketName=${bucket}`
            this.createWorker(documentType, ext, endpointExtension, this.authHeader(), viewFlag, documentName).then((downloadResponseMessage) => {
                this.showToast(downloadResponseMessage, this.TOST().SUCCESS)
              }).catch((error) => {
                if (error == "Failure")
                    this.messagePopup = true
                else
                    this.showToast(error, this.TOST().ERROR)
              }).finally(() => {
                  this.terminateWorker();
              })
	        this.worker.postMessage({ url: fileUrl });
        },
  },
};
</script>

<style scoped>
.spaced_centered {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.iconsize {
  font-size: 16px !important;
  font-weight: bold;
}
.btnstyle12 {
  height: 28px !important;
  text-transform: capitalize;
  font-size: 11px !important;
  letter-spacing: 0.5px;
  margin-bottom: 1rem;
}
.activebackground {
  background-color: #23b1a9 !important;
  color: rgb(247 247 247) !important;
}

.inactivewhitebackground {
  background-color: #ffffff !important;
  color: #10242b !important;
}
.orangetext {
  color: #e46a25;
}
.width-common {
  width: 750px !important;
}

.body1 {
          margin: 10px;
          /* font-family: sans-serif; */
          width: 580pt;
          /* width: 100%; */

        }
</style>
