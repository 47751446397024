 <template>
    <v-app v-if="dataLoaded===true">
        <v-card color="" class="pa-3 mb-1" style="" tile v-if="summaryTabs===true">
          <v-layout align-center class="py-2">
            <v-text class="text-uppercase font-weight-bold title-clr-prtbs ml-2">
              Provider Details
            </v-text>
            <v-divider vertical class="mx-4"></v-divider>
             <v-text class="font-weight-bold cstm-clr-prtbs">
                Registered Entity *
              </v-text>
              <v-divider vertical class="mx-4"></v-divider>
              <!-- @change="tabseleted(text)" -->
            <v-btn-toggle dense tile  mandatory v-model="text"  class=""  style="background-color:#F7F7F7;opacity:100%;" >
                <v-btn  value="indvdl" :disabled="disindvdl" class="text-capitalize font-weight-bold px-5" active-class="srSelected">
                  <span>Individual</span>
                </v-btn>
                <!-- style="white-space: normal;" :class="[instutinshw === true ? 'activeredbackground' : '']" -->
                <v-btn  value="instutn" :disabled="disinstutn" class="text-capitalize font-weight-bold px-5" active-class="srSelected">
                  <span >Institution</span>
                </v-btn>
               
                
            </v-btn-toggle>

          </v-layout>
          
        </v-card>

            <IndividualSummary   v-if="indvdlshw===true"  @toggle-indiSummary="toggleSummary"></IndividualSummary>
            <InstitutionSummary  v-if="instutinshw===true" @toggle-instSummary="toggleSummary" ></InstitutionSummary>
            
            <ViewSummaryInstitution v-if="instutinSummryshw===true"></ViewSummaryInstitution>
            <ViewSummary v-if="indvdlSummryshw===true"></ViewSummary>
            <!-- <router-view></router-view>

          -->
          <v-card tile class="ma-3 mt-4" color="#F7F7F7" v-if="shwEdit===true">
            <div style="display:flex; justify-content:flex-end" class="pa-5">
                <v-btn tile  color="#23B1A9" class="text-capitalize px-3"  small @click="editSummary()" >
                    <v-icon color="" small >edit</v-icon>
                    <v-divider vertical class="ml-2 mr-2"></v-divider>Edit
                </v-btn>
            </div>
        </v-card>
    </v-app>
</template>
<script>

import IndividualSummary from './IndividualSummary.vue'
import InstitutionSummary from './InstitutionSummary.vue'
import ViewSummary from './ViewSummary.vue'
import ViewSummaryInstitution from './ViewSummaryInstitution.vue'
import axios from 'axios';

export default {

    components:{
      
        IndividualSummary,
        InstitutionSummary,
        ViewSummary,
        ViewSummaryInstitution,
        
    },

    created() {

        this.$store.commit("SAVE_PAGE_HEADER", "Provider Summary");
        this.txtrcvd=this.$route.params.id
        this.providerId=this.$route.params.prn
        console.log(this.providerId, "id recieved")
        this.token=this.$store.getters.successToken
        this.getDtabyId(this.providerId)
        this.showdata();

    },
    

    computed: {

    },

    data() {
        return {
          summaryTabs:false,
          indvdlshw:true,
          indvdlSummryshw:false,
          instutinshw:false,
          instutinSummryshw:false,
          text:"",
          txtrcvd:"",
          shwEdit:true,
          providerId:'',
          prvdrData:{},
          token:null,
          dataLoaded:false
        };



    },

    methods: {
      toggleSummary(id){
        if(id.length > 0){
            this.getDtabyId(id)
        }
          console.log("i was in providerSummarytabs file");
          // alert("i was in providerSummarytabs file")
      },

        // tabseleted(itm){
        //     console.log("Selected tab",itm);
        //     if(itm==="indvdl"){
        //       // this.indvdlshw=true
        //       // this.instutinshw=false
        //       this.$router.push('/providersummary/viewsummaryindividual')
        //     }else if(itm==="instutn"){
        //       this.$router.push('/providersummary/viewsummaryinstitution')
        //     }
        // },

        showdata(){
          console.log("called")
          if(this.txtrcvd==="institution"){
            console.log(" institution called")
              this.indvdlSummryshw=false
              this.instutinSummryshw=true
              this.summaryTabs=false 
              this.indvdlshw=false
              this.instutinshw=false
          }else if(this.txtrcvd==="individual"){
             console.log(" individual called")
              this.indvdlSummryshw=true
              this.instutinSummryshw=false
              this.summaryTabs=false 
              this.indvdlshw=false
              this.instutinshw=false
          }
        },

        editSummary(){
          this.summaryTabs=true 
         console.log("this.indvdlSummryshw",this.indvdlSummryshw)
          console.log(this.instutinSummryshw,"this.instutinSummryshw")
          console.log("%c provider is ","color:blue",this.isNonSrvcPrvdr)
         
          if(this.instutinSummryshw===true){
              this.disinstutn=false
              this.disindvdl=true
             this.indvdlshw=false
              this.instutinshw=true
              this.indvdlSummryshw=false
              this.instutinSummryshw=false
              this.shwEdit=false
          }else if(this.indvdlSummryshw===true){
             this.indvdlshw=true
              this.instutinshw=false
              this.indvdlSummryshw=false
              this.instutinSummryshw=false
              this.shwEdit=false
              this.disinstutn=true
              this.disindvdl=false
          }
        },

        getDtabyId(providerId){
          console.log(providerId, "incoming data")
            this.dataLoaded=false
        
            let apiURL=''
            if(this.txtrcvd==="individual"){
                apiURL=this.API_Service_provider()+"provider/get-provider-individual/"+providerId
            }else{
                apiURL=this.API_Service_provider()+"provider/get-provider-institution/"+providerId
            }
            let self=this
            self.prvdrData={}
              axios({
                    method: "get",
                    url:apiURL,
                    headers: {
                        "Content-Type": "application/json",
                        "x-access-token":self.token,
                    }
                })
                .then(function (response) {
                  // console.log("Create reporting>>>>>>", response);
              self.prvdrData={}
              if(self.txtrcvd==="individual"){
                let ind_tempdata = response.data.data

                self.prvdrData = {
                              salutation : ind_tempdata.hasOwnProperty('salutation')? self.chkplndata(ind_tempdata.salutation) : '',  
                              // salutation
                                firstName:ind_tempdata.hasOwnProperty('firstName')? self.chkplndata(ind_tempdata.firstName) : '', 
                                middleName:ind_tempdata.hasOwnProperty('middleName')? self.chkplndata(ind_tempdata.middleName) : '', 
                                lastName:ind_tempdata.hasOwnProperty('lastName')? self.chkplndata(ind_tempdata.lastName) : '', 
                                mobileNumber:ind_tempdata.hasOwnProperty('mobileNumber')? self.chkplndata(ind_tempdata.mobileNumber) : '', 
                                registeredEntity: ind_tempdata.hasOwnProperty('registeredEntity')? self.chkplndata(ind_tempdata.registeredEntity) : '',
                                externalReferenceNo:ind_tempdata.hasOwnProperty('externalReferenceNo')? self.chkplndata(ind_tempdata.externalReferenceNo) : '', 
                                providerType:ind_tempdata.hasOwnProperty('providerType')? self.chkplndata(ind_tempdata.providerType) : '', 
                                providerTypeOther:ind_tempdata.hasOwnProperty('providerTypeOther')? self.chkplndata(ind_tempdata.providerTypeOther) : '', 
                                registrationNumber:ind_tempdata.hasOwnProperty('registrationNumber')? self.chkplndata(ind_tempdata.registrationNumber) : '', 
                                prn:ind_tempdata.hasOwnProperty('prn')? self.chkplndata(ind_tempdata.prn) : '', 
                                status:ind_tempdata.hasOwnProperty('status')? self.chkplndata(ind_tempdata.status) : '', 
                                email:ind_tempdata.hasOwnProperty('email')? self.chkplndata(ind_tempdata.email) : '', 
                                gender:ind_tempdata.hasOwnProperty('gender')? self.chkplndata(ind_tempdata.gender) : '',
                                qualification:ind_tempdata.hasOwnProperty('qualification')? self.chkplndata(ind_tempdata.qualification) : '', 
                                qulificationOther:ind_tempdata.hasOwnProperty('qulificationOther')? self.chkplndata(ind_tempdata.qulificationOther) : '', 
                                //this.regValidityTo = (registrationValidUpto ===  '' || this.indtype.data.indata.registrationValidUpto ==  null) ? '': moment(this.indtype.data.indata.registrationValidUpto,'YYYY-MM-DD').format('YYYY-MM-DD')
                                registrationValidUpto: ind_tempdata.hasOwnProperty('registrationValidUpto')? self.chkplndata(ind_tempdata.registrationValidUpto) : '', 
                                registeredCouncil:ind_tempdata.hasOwnProperty('registeredCouncil')? self.chkplndata(ind_tempdata.registeredCouncil) : '', 
                                registeredCouncilOther:ind_tempdata.hasOwnProperty('registeredCouncilOther')? self.chkplndata(ind_tempdata.registeredCouncilOther) : '', 
                                remarks:ind_tempdata.hasOwnProperty('remarks')? self.chkplndata(ind_tempdata.remarks) : '', 
                                _id: ind_tempdata.hasOwnProperty('_id')? self.chkplndata(ind_tempdata._id) : '',
                                 flagDetails: ind_tempdata.hasOwnProperty('flagDetails')? self.chkplndata(ind_tempdata.flagDetails) : '',

                  }

              }else{

                
                  // for(let data in self.prvdrData){  
                  // }
                  let tempData = response.data.data
                  self.prvdrData = {
                    addressLine1 : tempData.hasOwnProperty('addressLine1')? self.chkplndata(tempData.addressLine1) : '',
                    addressLine2: tempData.hasOwnProperty('addressLine2')? self.chkplndata(tempData.addressLine2) : '',
                    addressLine3:tempData.hasOwnProperty('addressLine3')? self.chkplndata(tempData.addressLine3) : '',
                    aliasName:tempData.hasOwnProperty('aliasName')? self.chkplndata(tempData.aliasName) : '',
                    childProvider:tempData.hasOwnProperty('childProvider')? self.chkplndata(tempData.childProvider) : '',
                    parentProvider:tempData.hasOwnProperty('parentProvider')? self.chkplndata(tempData.parentProvider) : '',
                    mappingType:tempData.hasOwnProperty('mappingType')? self.chkplndata(tempData.mappingType) : '',
                    cityOthers:tempData.hasOwnProperty('cityOthers')? self.chkplndata(tempData.cityOthers) : '',
                    cityTownVillage:tempData.hasOwnProperty('cityTownVillage')? self.chkplndata(tempData.cityTownVillage) : '',
                    country:tempData.hasOwnProperty('country')? self.chkplndata(tempData.country) : '',
                    createdAt:tempData.hasOwnProperty('createdAt')? self.chkplndata(tempData.createdAt) : '',
                    district:tempData.hasOwnProperty('district')? self.chkplndata(tempData.district) : '',
                    doctorsList:tempData.hasOwnProperty('doctorsList')? self.chkplndataarry(tempData.doctorsList) : [],
                    documents:tempData.hasOwnProperty('documents')? self.chkplndataarry(tempData.documents) : [],
                    email:tempData.hasOwnProperty('email')? self.chkplndata(tempData.email) : '',
                    entityName:tempData.hasOwnProperty('entityName')? self.chkplndata(tempData.entityName) : '',
                    faxNumber:tempData.hasOwnProperty('faxNumber')? self.chkplndata(tempData.faxNumber) : '',
                    gstin:tempData.hasOwnProperty('gstin')? self.chkplndata(tempData.gstin) : '',
                    isNonServiceProvider: tempData.hasOwnProperty('isNonServiceProvider')? self.chkplndata(tempData.isNonServiceProvider) : '',
                    panNumber: tempData.hasOwnProperty('panNumber')? self.chkplndata(tempData.panNumber) : '',
                    pinPostalCode: tempData.hasOwnProperty('pinPostalCode')? self.chkplndata(tempData.pinPostalCode) : '',
                    prn: tempData.hasOwnProperty('prn')? self.chkplndata(tempData.prn) : '',
                    flagDetails:tempData.hasOwnProperty('flagDetails')? self.chkplndata(tempData.flagDetails) : '',
                    providerStatusHistory: tempData.hasOwnProperty('providerStatusHistory')? self.chkplndataarry(tempData.providerStatusHistory) : [],
                    providerType: tempData.hasOwnProperty('providerType')? self.chkplndata(tempData.providerType) : '',
                    providerTypeOther: tempData.hasOwnProperty('providerTypeOther')? self.chkplndata(tempData.providerTypeOther) : '',
                    registeredBody: tempData.hasOwnProperty('registeredBody')? self.chkplndata(tempData.registeredBody) : '',
                    registeredEntity: tempData.hasOwnProperty('registeredEntity')? self.chkplndata(tempData.registeredEntity) : '',
                    registrationDate:tempData.hasOwnProperty('registrationDate')? self.chkplndata(tempData.registrationDate) : '',
                    registrationNumber: tempData.hasOwnProperty('registrationNumber')? self.chkplndata(tempData.registrationNumber) : '',
                    registrationValidUpto: tempData.hasOwnProperty('registrationValidUpto')? self.chkplndata(tempData.registrationValidUpto) : '',
                    reviewerRemarks: tempData.hasOwnProperty('reviewerRemarks')? self.chkplndata(tempData.reviewerRemarks) : '',
                    rohiniId: tempData.hasOwnProperty('rohiniId')? self.chkplndata(tempData.rohiniId) : '',
                    servicesProvided: tempData.hasOwnProperty('servicesProvided')? self.chkplndataarry(tempData.servicesProvided) : [],
                    servicesProvidedOther: tempData.hasOwnProperty('servicesProvidedOther')? self.chkplndata(tempData.servicesProvidedOther) : '',
                    state: tempData.hasOwnProperty('state')? self.chkplndata(tempData.state) : '',
                    status:tempData.hasOwnProperty('status')? self.chkplndata(tempData.status) : '',
                    telephoneNumber: tempData.hasOwnProperty('telephoneNumber')? self.chkplndata(tempData.telephoneNumber) : '',
                    dailyMedicalRecords: tempData.hasOwnProperty('validationDetails')? tempData.validationDetails.hasOwnProperty('dailyMedicalRecords')? self.chkplndata(tempData.validationDetails.dailyMedicalRecords) :'' :'',
                    hasOperationTheatre: tempData.hasOwnProperty('validationDetails')? tempData.validationDetails.hasOwnProperty('hasOperationTheatre')? self.chkplndata(tempData.validationDetails.hasOperationTheatre) :'' :'',
                    medicalPractionerRoundTheClock: tempData.hasOwnProperty('validationDetails')? tempData.validationDetails.hasOwnProperty('medicalPractionerRoundTheClock')? self.chkplndata(tempData.validationDetails.medicalPractionerRoundTheClock) :'' :'',
                    noOfBeds: tempData.hasOwnProperty('validationDetails')? tempData.validationDetails.hasOwnProperty('noOfBeds')? self.chkplndata(tempData.validationDetails.noOfBeds) :'' :'',
                    nursingRoundTheClock: tempData.hasOwnProperty('validationDetails')? tempData.validationDetails.hasOwnProperty('nursingRoundTheClock')? self.chkplndata(tempData.validationDetails.nursingRoundTheClock) :'' :'',
                    registrationDateEffectiveFrom: tempData.hasOwnProperty('validationDetails')? tempData.validationDetails.hasOwnProperty('registrationDateEffectiveFrom')? self.chkplndata(tempData.validationDetails.registrationDateEffectiveFrom) :'' :'',
                    registrationDateEffectiveTo: tempData.hasOwnProperty('validationDetails')? tempData.validationDetails.hasOwnProperty('registrationDateEffectiveTo')? self.chkplndata(tempData.validationDetails.registrationDateEffectiveTo) :'' :'',
                    _id: tempData.hasOwnProperty('_id')? self.chkplndata(tempData._id) : '',
                    
                  }

              }
                  console.log("self.prvdrData",self.prvdrData)
                  self.$store.commit('PRVDR_SUCCESS', self.prvdrData)
                  self.dataLoaded=true
                  //  self.showToast(response.data.msg,self.TOST().SUCCESS)
                })
                .catch(function (error) {
                    console.log("Create reporting Error>>>>>>", error);
                    self.showToast(error.response.data.msg,self.TOST().ERROR);
                });

        },

        chkplndataarry(data){
            console.log("chkplndataarry",data); 
            try{
                if (data ==="" || data === "undefined" || data === null) {
                    return []
                } else {
                    return data;
                }

             }catch(err) {
                console.log(err)
            }
            
        },

        chkplndata(data){
            console.log("chkplndata",data); 
            try{
                if (data ==="" || data === "undefined" || data === null) {
                    return ""
                } else {
                    return data;
                }

             }catch(err) {
                console.log(err)
            }
            
        },
       

    },

      

    watch: {
    
    },
    
    
}

</script>
<style >

.srSelected{
  background-color: #23B1A9;
  color:"white"
}

.activeredbackground{
  color:white;
}

.title-clr-prtbs{
    color:#10242B;
    /* margin-bottom: 5px; */
}
.cstm-clr-prtbs{
    color:#646666 !important;
    /* margin-bottom: 5px !important; */
    font-size: 13px !important;
}

.btn_pr_tabs{
  color:#10242B;
  opacity:100% !important;  
  font-size:13px !important;
  font-weight: 700;
  /* border-left:0.5px solid #C1C8CC; */

    border: solid;
    border-top-width: thin;
    border-right-width: thin;
    border-bottom-width: thin;
    border-left-width: thin;
    transition: inherit;
    border-top-color: rgba(255, 255, 255, 0.26);
    border-right-color: rgba(255, 255, 255, 0.26);
    border-bottom-color: rgba(255, 255, 255, 0.26);
    border-left-color: rgba(193, 200, 204, 0.26);
}

</style>