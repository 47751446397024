<template>
  <div class="ma-3 mt-8">
    <v-form ref="createForm" lazy-validation @submit.prevent>
      <v-expansion-panels v-model="panels" multiple>
        <v-expansion-panel>
          <v-expansion-panel-header color="#1EBBD9">
            <span class="page-title-small white--text"
              >Create SIU Configurator Details</span
            >
            <template v-slot:actions>
              <v-icon class="white--text">
                {{
                  panels.indexOf(0) !== -1 ? "mdi-minus-box" : "mdi-plus-box"
                }}
              </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row class="mt-3">
              <v-col>
                <label class="form-label ml-1"
                  >LOB Segment
                  <span class="red--text"><strong> * </strong></span>
                </label>
                <v-autocomplete
                  class="form-control"
                  v-model="formData.lobSegment"
                  placeholder="Select"
                  :disabled="isView"
                  :items="lobSegmentItems"
                  :rules="[(v) => requiredField(v)]"
                  dense
                  solo
                  required
                ></v-autocomplete>
              </v-col>
              <v-col>
                <label class="form-label ml-1"
                  >LOB
                  <span class="red--text"><strong> * </strong></span>
                </label>
                <v-autocomplete
                  class="form-control"
                  v-model="formData.lob"
                  placeholder="Select"
                  :disabled="isView"
                  :items="lobItems"
                  :rules="[(v) => requiredField(v)]"
                  dense
                  solo
                  required
                ></v-autocomplete>
              </v-col>

              <v-col>
                <label class="form-label ml-1"
                  >Policy Type
                  <span class="red--text"><strong> * </strong></span>
                </label>
                <v-autocomplete
                  class="form-control"
                  v-model="formData.policyType"
                  placeholder="Select"
                  :disabled="isView"
                  :items="policyTypeList"
                  :rules="[(v) => requiredField(v)]"
                  dense
                  solo
                  required
                ></v-autocomplete>
              </v-col>
              <v-col>
                <label class="form-label ml-1"
                  >Claim Type
                  <span class="red--text"><strong> * </strong></span>
                </label>
                <v-autocomplete
                  class="form-control"
                  v-model="formData.claimType"
                  placeholder="Select"
                  :disabled="isView"
                  :items="claimTypeList"
                  :rules="[(v) => requiredField(v)]"
                  dense
                  solo
                  required
                ></v-autocomplete>
              </v-col>
              <v-col>
                <label class="form-label"
                  >Claimed amount from
                  <span class="red--text"><strong> * </strong></span>
                </label>
                <v-text-field
                  placeholder="Enter..."
                  class="form-control"
                  solo
                  :disabled="isView"
                  dense
                  :maxlength="138"
                  v-model="formData.claimedAmountFrom"
                  depressed
                  :rules="[
                    (v) => requiredField(v),
                    (v) => matchRegex(v, 'nums'),
                  ]"
                />
              </v-col>
            </v-row>
            <v-row align="end">
              <v-col>
                <label class="form-label"
                  >Claimed amount to
                  <span class="red--text"><strong> * </strong></span>
                </label>
                <v-text-field
                  placeholder="Enter..."
                  class="form-control"
                  solo
                  :disabled="isView"
                  dense
                  :maxlength="138"
                  v-model="formData.claimedAmountTo"
                  depressed
                  :rules="[
                    (v) => requiredField(v),
                    (v) => matchRegex(v, 'nums'),
                  ]"
                />
              </v-col>
              <v-col>
                <label class="form-label ml-1"
                  >Hospital State
                  <span class="red--text"><strong> * </strong></span>
                </label>
                <v-autocomplete
                  class="form-control"
                  v-model="formData.hospitalState"
                  placeholder="Select"
                  :disabled="isView"
                  :items="hospitalStateList"
                  :rules="[(v) => requiredField(v)]"
                  item-text="stateCodeName"
                  item-value="stateCodeName"
                  dense
                  solo
                  required
                ></v-autocomplete>
              </v-col>
              <v-col>
                <label class="form-label ml-1"
                  >Outcome
                  <span class="red--text"><strong> * </strong></span>
                </label>
                <v-autocomplete
                  class="form-control"
                  v-model="formData.outcome"
                  placeholder="Select"
                  :disabled="isView"
                  :items="outcomeList"
                  :rules="[(v) => requiredField(v)]"
                  dense
                  solo
                  required
                ></v-autocomplete>
              </v-col>
              <v-col cols="3" style="flex: 0 0 20%">
                <label class="form-label ml-1">
                  SIU Role for QC 2
                  <span class="red--text"><strong> * </strong></span>
                </label>
                <v-autocomplete
                  class="form-control"
                  v-model="formData.siuRoleForQc2"
                  placeholder="Select"
                  :disabled="isView"
                  :items="siuRoleForQc2List"
                  :rules="[(v) => requiredField(v)]"
                  dense
                  solo
                  required
                  @change="getSIUusers(formData.siuRoleForQc2,2)"
                ></v-autocomplete>
              </v-col>
              <v-col cols="3" style="flex: 0 0 20%">
                <label class="form-label">
                  Start Effective Date of QC 1 release <span class="red--text"><strong> * </strong></span>
                </label>
                <v-menu
                  ref="calender"
                  :close-on-content-click="true"
                  :nudge-right="40"
                  transition="scale-transition"
                  class="form-control"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-bind="attrs"
                      label="Select.."
                      :value="computedDate('startModifiedDateOfQCOneRelease')"
                      solo
                      dense
                      :disabled="isView || updateMode"
                      v-on="on"
                      class="form-control"
                    >
                      <div
                        slot="prepend-inner"
                        style="
                          background-color: #f7f7f7;
                        "
                      >
                        <v-icon>event</v-icon>
                      </div>
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="formData.startModifiedDateOfQCOneRelease"
                    no-title
                    :min="minDate"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row class="mt-3">
              <v-col cols="3" style="flex: 0 0 20%">
                <label class="form-label"
                  >End Effective Date of QC 1 release
                  <span class="red--text"><strong> * </strong></span></label
                >
                <v-menu
                  ref="calender"
                  :close-on-content-click="true"
                  :nudge-right="40"
                  transition="scale-transition"
                  class="form-control"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-bind="attrs"
                      label="Select.."
                      :value="computedDate('endModifiedDateOfQcOneRelease')"
                      :rules="[(v) => requiredField(v)]"
                      solo
                      dense
                      :disabled="isView"
                      class="form-control"                      
                      v-on="on"
                    >
                      <div slot="prepend-inner" style="background-color: #f7f7f7;">
                        <v-icon>event</v-icon>
                      </div>
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="formData.endModifiedDateOfQcOneRelease"
                    no-title
                    :min="minDate"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="3" style="flex: 0 0 20%">
                <label class="form-label"
                  >Created Date
                  <span class="red--text"><strong> * </strong></span></label
                >
                <v-menu
                  ref="calender"
                  :close-on-content-click="true"
                  :nudge-right="40"
                  transition="scale-transition"
                  class="form-control"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-bind="attrs"
                      label="Select.."
                      :value="computedDate('createdDateToTakeEffect')"
                      :rules="[(v) => requiredField(v)]"
                      solo
                      dense
                      :disabled="isView || updateMode"
                      class="form-control"                      
                      v-on="on"
                    >
                      <div slot="prepend-inner" style="background-color: #f7f7f7;">
                        <v-icon>event</v-icon>
                      </div>
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="formData.createdDateToTakeEffect"
                    no-title
                    :min="minDate"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="3" style="flex: 0 0 20%">
                <label class="form-label"
                  >Modified Date
                  <span class="red--text"><strong> * </strong></span></label
                >
                <v-menu
                  ref="calender"
                  :close-on-content-click="true"
                  :nudge-right="40"
                  transition="scale-transition"
                  class="form-control"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-bind="attrs"
                      label="Select.."
                      :value="computedDate('effectiveDate')"
                      :rules="[(v) => requiredField(v)]"
                      solo
                      dense
                      :disabled="isView || updateMode"
                      class="form-control"
                      v-on="on"
                    >
                      <div slot="prepend-inner" style=" background-color: #f7f7f7;">
                        <v-icon>event</v-icon>
                      </div>
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="formData.effectiveDate"
                    no-title
                    :min="minDate"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="3" style="flex: 0 0 20%">
                <label class="form-label ml-1"
                  >User
                  <span class="red--text"><strong> * </strong></span>
                </label>
                <v-select
                  class="form-control"
                  v-model="employeeName"
                  placeholder="Select"
                  :disabled="isView"
                  :items="userlist"
                  item-text="employeeName"
                  :rules="[(v) => requiredField(v)]"
                  dense
                  solo
                  required
                ></v-select>
              </v-col>
              <v-col
                cols="3"
                style="flex: 0 0 20%"
                v-if="Object.keys(this.$route.params).length != 0"
              >
                <v-checkbox
                  class="form-control"
                  v-model="formData.isActive"
                  dense
                  label="Active"
                  :disabled="isView"
                >
                </v-checkbox>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-form>
    <v-layout wrap class="mt-5">
      <v-layout class="mt-0">
        <v-btn
          v-if="isView === false"
          type="button"
          color="red"
          outlined
          class=""
          @click="onReset"
        >
          <v-icon> mdi-backspace </v-icon>

          <v-divider class="mx-2 ml-5" vertical></v-divider>
          <span class="subheading">Reset</span>
        </v-btn>
        <v-btn
          type="button"
          color="red"
          outlined
          class="ml-3 mx-2"
          @click="onCancel(true)"
        >
          <v-icon> mdi-backspace </v-icon>

          <v-divider class="mx-2 ml-5" vertical></v-divider>
          <span class="subheading">Cancel</span>
        </v-btn>
        <v-spacer></v-spacer>

        <v-btn
          v-if="isView === false"
          type="submit"
          color="teal white--text"
          class=""
          @click="onSubmit"
        >
          <v-icon> mdi-arrow-right-circle </v-icon>

          <v-divider class="mx-2" vertical color="white--text"></v-divider>
          <span class="subheading">Submit</span>
        </v-btn>
      </v-layout>
    </v-layout>
  </div>
</template>

<script>
import moment from "moment";
import { getUserList, getStatesList } from "../../../apiservices/master";
export default {
  data() {
    return {
      userlist: [],
      outcomeList: ["Fraud", "LM", "No Adverse finding"],
      hospitalStateList: ["Maharashtra"],
      siuRoleForQc2List: ["Regional manager", "SIU CO Supervisor", "SIU Corporate Office", "SIU Head"],
      minDate: this.getCurrentDate(),
      claimTypeList: [
        "Cashless",
        "Reimbursement",
        "Cashless Claim File",
      ],
      updateMode: false,
      visualEffect: false,
      all_attachments: [],
      documentAttachments: [{ name: "Download Master List" }],
      isView: false,
      tab_items: ["Form"],
      lobItems: [],
      lobSegmentItems: ["ALL", "ACCIDENT & HEALTH"],
      policyTypeList: ["Retail", "Group"],
      panels: [0, 1],
      employeeName: "",
      formData: {
        lobSegment: "",
        lob: "",
        policyType: "",
        claimType: "",
        claimedAmountFrom: "",
        claimedAmountTo: "",
        hospitalState: "",
        outcome: "",
        siuRoleForQc2: "",
        startModifiedDateOfQCOneRelease: "",
        endModifiedDateOfQcOneRelease: "",
        createdDateToTakeEffect: "",
        effectiveDate: "",
        isActive: true,
      },
      neftHeaders: [
        {
          text: "Document",
          align: "start",
          value: "name",
          sortable: false,
        },
      ],
    };
  },
  methods: {
    getCurrentDate() {
      return moment(new Date(Date.now())).format("YYYY-MM-DD");
    },
    computedDate(name) {
      return this.formData[name]
        ? moment(this.formData[name]).format("DD/MM/YYYY")
        : "";
    },
    onReset() {
      this.showConfirm(
        "Confirmation",
        "Are you sure you want to Reset?",
        "Yes",
        "No",
        (Yes) => {
          this.confirmReset();
          this.cancelledCheque = null;
        },
        (No) => {}
      );
    },
    confirmReset() {
      this.employeeName = "";
      this.formData = {
        lobSegment: "",
        lob: "",
        policyType: "",
        claimType: "",
        claimedAmountFrom: "",
        claimedAmountTo: "",
        hospitalState: "",
        outcome: "",
        siuRoleForQc2: "",
        startModifiedDateOfQCOneRelease: "",
        endModifiedDateOfQcOneRelease: "",
        createdDateToTakeEffect: "",
        effectiveDate: "",
        isActive: true,
      };
    },
    onCancel(data) {
      if (data) {
        this.showConfirm(
          "Confirmation",
          "Are you sure you want to leave?",
          "Yes",
          "No",
          (Yes) => {
            this.showProgress();
            this.$router.push({ name: "SearchSiuQc2ConfiguratorMaster" }); //params:{pageNo:this.pageNo}}
            this.hideProgress();
          }
        );
      } else {
        this.$router.push("/master/search-siu-qc2-configurator-master");
      }
    },
    onSubmit() {
      let index = this.userlist.findIndex((el)=>{return el.employeeName == this.employeeName});
      let userData = this.userlist[index];
      let obj = {};
      obj.uid = userData._id;
      obj.ntID = userData.ntidUserId;
      obj.employeeName = userData.employeeName;
      obj.contactMobileNo = userData.contactMobileNo;
      obj.state = userData.state || "";
      obj.city = userData.city || "";
      obj.zone = userData.zone || "";
      obj.roleDesignation = userData.role;
      obj.emailID = userData.email;
      if (!this.$refs.createForm.validate()) {
        return this.showAlert("Please fill the mandatory fields");
      } else {
        let payload = this.formData;
        console.log("888", payload);

        if (Object.keys(this.$route.params).length != 0) {
          let updateId = this.$route.params.item._id;
          this.PUT_ByURLM(
            "UPDATE_QC2_CONFIGURATOR",
            {...payload, ...{assginDetails: obj}},
            (res) => {
              this.showAlert(res?.data?.msg || "Success", "", "", () => {
                this.onCancel(false);
              });
              if (res.data.statusCode === 200) {
                console.log("999", res.data);
              }
            },
            (error) => {
              console.log("Error", error);
              // this.showAlert(res.data.msg)
            },
            updateId,
            true
          );
        } else {
          this.POSTM(
            "CREATE_QC2_CONFIGURATOR",
            {...payload, ...{assginDetails: obj}},
            (res) => {
              this.showAlert(res?.data?.msg || "Success", "", "", () => {
                // this.onReset();
                // this.cancel(1)
              });
              if (res.data.statusCode === 200) {
                console.log("999", res.data);
                this.onCancel(false);
              }
            },
            (error) => {
              console.log("Error", error);
              // this.showAlert(res.data.msg)
            },
            true
          );
        }
      }
    },
    getQc2Configurator(item) {
      let requestData = item._id;
      this.GET_ByURLM(
        "GET_QC2_CONFIGURATOR_BY_ID",
        requestData,
        (res) => {
          // this.showAlert(err)
          if (res.data && res.data.data) {
            let result = res.data.data[0];
            this.populateFields(result);
          }
        },
        (err) => {
          this.showAlert(err);
          console.log(err);
        },
        true
      );
    },
    populateFields(result) {
      this.getSIUusers(result.siuRoleForQc2, 2);
      this.employeeName = result.assginDetails.employeeName;
      this.formData.lobSegment = result.lobSegment;
      this.formData.lob = result.lob;
      this.formData.policyType = result.policyType;
      this.formData.claimType = result.claimType;
      this.formData.claimedAmountFrom = result.claimedAmountFrom;
      this.formData.claimedAmountTo = result.claimedAmountTo;
      this.formData.hospitalState = result.hospitalState;
      this.formData.outcome = result.outcome;
      this.formData.siuRoleForQc2 = result.siuRoleForQc2;
      this.formData.startModifiedDateOfQCOneRelease =
        result.startModifiedDateOfQCOneRelease;
      this.formData.endModifiedDateOfQcOneRelease =
        result.endModifiedDateOfQcOneRelease;
      this.formData.effectiveDate = result.updatedAt;
      this.formData.createdDateToTakeEffect = result.createdAt;
      this.formData.isActive = result.isActive;
    },
    browse() {},
    selectFile() {},
    getLob() {
      let reqData = "";
      this.showProgress();
      this.GETM(
        "getLOB",
        reqData,
        (res) => {
          this.hideProgress();
          if (res.data.statusCode === 200) {
            this.lobItems = res.data.data[0].lineOfBusiness;
          } else {
            this.lobItems = [];
            this.showAlert(res.data.msg);
          }
        },
        (error) => {
          console.log(error);
          this.hideProgress();
        },
        true
      );
    },
    async getUserData(){
      try{
        let role = this.fetchRoleValue();
        console.log(role);
        let res = await getUserList(role[0]);
        this.userlist = res.data.data;
        console.log(res.data.data);
      } catch(e){
        this.showToast(e.response?.data?.msg || e.response?.data  || e.response || e, this.TOST().ERROR);
      }
    },
    getSIUusers(val,flag){
        let role = '';
        if(flag == 1){
          role = this.fetchRoleValue();
        }
        if(flag == 2){
          role = val == "SIU Head" ? "CG77" : val == "SIU Corporate Office" ? "CG78" : val == "SIU CO Supervisor" ? "CG79" : val == "Regional manager" ? "CG80" : '';
        }console.log(role)
        this.GET(3,'users/get-users-for-qc2?siuRole='+role,(res,err) => {
          if(!err){
              console.log(res.data,'response')
              this.userlist = res.data.data;
          }else{
              console.log(err.response.data,'error')
          }
        })
    },
    async getStates() {
      try {
        let res = await getStatesList();
        this.hospitalStateList = res?.data?.data?.data;
        console.log("SS1", res.data.data.data);
      } catch(e) {
        this.showToast(e.response?.data?.msg || e.response?.data  || e.response || e, this.TOST().ERROR);
      }
    }
  },
  async created() {
    this.getLob();
    await this.getStates();
    if (Object.keys(this.$route.params).length != 0) {
      //isView
      this.updateMode = true;
      this.isView = this.$route.params.isView;
      let result = this.$route.params.item;
      console.log(result);
      this.getQc2Configurator(result);
      this.$store.commit("SAVE_PAGE_HEADER", "SIU QC2 Configurator Master");
    } else {
      this.formData.startModifiedDateOfQCOneRelease = this.getCurrentDate();
      this.formData.effectiveDate = this.getCurrentDate();
      this.formData.createdDateToTakeEffect = this.getCurrentDate();
      this.getSIUusers('',1)
    }
  },
};
</script>
<style scoped>
.controlfield {
  box-shadow: 0px 1px 3px rgb(0 0 0 / 25%) !important;
  border: rgba(0, 0, 0, 0.125) solid 1px;
  padding: 6px;
}
.claimProcessingTabsActive {
  background-color: #1ebbd9;
  color: #fff !important;
}
.unActive {
  border: 1px solid #ccc;
  margin: 0px 5px;
}
.dragbackground {
  background-color: #e7e7e7;
}
.dlt_text {
  color: rgb(145, 145, 145);
}

.tabelwidth {
  width: 500px;
  margin: 0px 150px;
}
.neftStyle {
  height: 150px;
  width: 350px;
  border: 3px dashed #ccc;
  padding-top: 40px;
  padding-left: 16%;
}
.margincls {
  font-size: 12px;
  margin-left: -12px;
}
</style>
