
<template>
<v-app>
        <v-layout>
                <v-dialog v-model="setdoc.docen" persistent max-width="720px" style="overflow-x: hidden;">
                        <v-card dense tile color="#F7F7F7" class="pb-2 ">

                            <v-card tile>
                                <v-layout justify-space-between  class="" style="flex:1;background-ox-shadow: 0px 3px 5px #00000029;">
                                    <v-layout align-center>
                                        <v-card-title class="pa-0 pl-3 text-capitalize font-weight-bold" style="color:#10242B;font-size:13px">Document Upload</v-card-title>
                                        <v-divider vertical inset class="mx-2"></v-divider>
                                    </v-layout>
                                    <div style="border-left:1px solid #C1C8CC;" @click="close_Dialog()" >
                                        <v-icon style="cursor: pointer;" class="pa-2" >close</v-icon>
                                    </div>
                                </v-layout>
                            </v-card>

            <v-card class="elevation-2 mx-3 my-3" >
                <v-layout  style="background-color:#f7f7f7; border: solid 1px #f7f7f7; align-items:center;" color="#FFFFFF" filled class="  mt-4 p-4 d-flex justify-space-around "   >
                <v-flex xs5  v-if="setdoc.from != 'addendum'" >
                    <v-layout column class="cust_pr_dialog mx-2 mt-4 ">
                        <p class="font-weight-bold cstm-clr-pr-dialog mb-2">
                            Document Type*
                        </p>
                        <v-select
                            :items="typeOfDocument"
                            label="Select"
                            v-model="docType"
                            solo
                            dense
                            class="rounded-0">
                        </v-select>
                    </v-layout>
                </v-flex>
                        <v-divider v-if="setdoc.from != 'addendum'"  vertical inset style="height:60px; margin-top: 30px"></v-divider>
                        <v-flex class="xs6 pa-2" style=" display: flex; flex-direction:column; align-items:flex-start; justify-content: space-between;" >
                            <div class="rowitnow2" >
                               
                                <p class="font-weight-bold cstm-clr-pr-dialog">Upload Documents*</p>
                                <p class="instructions mt-1 rmrg16" >Max Size: 5MB | Type: PDF, JPG, JPEG & PNG</p>
                               
                            </div>
                            
                            <!-- style=" width:400px;height: 80px" -->
                            <v-flex  class="rounded-0 elevation-1 xs12 pa-2" style="width:100%;background-color:#fff" >
                                <v-layout style="display: flex; justify-content: space-between; align-items:center;">
                                    <!-- <v-btn dark style="border: solid 1px dotted #EFEBE9"  
                                    tile color="#EFEBE9" class="my-2 text-capitalize px-3 mx-2" 
                                    small>Drag Image here</v-btn>
                                    <div>
                                        <v-icon dark small>cloud_upload</v-icon> <v-divider vertical class="mx-2"></v-divider> Drag images here
                                    </div> -->

                                    <input type="file"  style="opacity:0; display:none;"
                                        id="maindoc" multiple name="img_upload"
                                        accept="image/jpeg,image/png,application/pdf" 
                                        @change="upload_file($event, edited_item)">
                                    <v-btn @click="fileup"  dark  tile color="#E46A25" class="text-capitalize px-3 mx-2 " small><v-icon dark small>cloud_upload</v-icon> <v-divider vertical class="mx-2"></v-divider>Select Document</v-btn>
                                
                                    
                                    <v-flex v-if="upload_docs">
                                        <v-layout class="" justify-space-between align-center style="border:0.5px solid #C1C8CC; background-color:#fff; align-items:center;"  color="#FFFFFF" filled >
                                            <div >
                                                 <v-icon class="ml-2" small>perm_media</v-icon>
                                               
                                                <!-- <img  class="ml-1 mt-1"
                                                      src="../../assets/dmExcel.png"
                                                      style=" width: 18px;height: 18px;" /> -->
                                            </div>
                                            <div><span class="px-2 font-weight-bold"  style="font-size:12px; color:#646666;">{{docfilename|truncate(16, '...')}}</span></div>
                                            <div style="background-color:#D1121B; padding:3px" >
                                                <v-btn tile class="text-capitalize" style="height:16px;width:16px"  flat icon dark small @click="deleteimgfile">
                                                    <v-icon small>delete</v-icon>
                                                </v-btn>
                                            </div>
                                        </v-layout>
                                   </v-flex>
                                   <v-flex v-else >

                                   </v-flex>

                                  
                                   
                                
                                </v-layout>
                                

                            </v-flex>
                        </v-flex>

                        

                </v-layout>
                
                            
                <!-- Dates  -->
               <v-layout v-if="setdoc.from != 'addendum'" class="mx-2 mt-2 pa-2" style="background-color: #ffffff; border: solid 1px #f7f7f7"> 
                  

            <v-flex xs5>
                    <v-layout column class=" mx-2  cust_pr_quick cust-rmvpd-pr-quick">
                        <p class="font-weight-bold cstm-clr-pr-quick">
                           Validity From*
                        </p>
                        
                        <v-menu
                            ref="calender"
                            :close-on-content-click="false"
                            v-model="validityFrom"
                            :nudge-right="40"
                            lazy
                            transition="scale-transition"
                            full-width
                            max-width="290px"
                            min-width="290px" 
                            class="rounded-0"
                            
                            >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-bind="attrs"
                                    v-on="on"
                                    id="fromDate"
                                    label="dd/mm/yyyy"
                                    :value="computedDateFormattedMomentjs2"
                                    solo
                                    
                                    class="rounded-0"
                                    readonly
                                    required>
                            <div  slot="prepend-inner"  style="background-color:#F7F7F7;  padding: 8px !important"><span class="material-icons small">date_range</span></div>
                                
                                </v-text-field>
                            </template>                        
                                    <v-date-picker 
                                        v-model="validityFromDate"
                                        no-title
                                        @input="validityFrom = false"
                                    ></v-date-picker>
                        </v-menu>

                    </v-layout>
                </v-flex>

                   <!-- <v-flex xs5>
                    <v-layout column class=" mx-2 mt-2 cust_pd_dialog cust-rmvpd-pr-dialog">
                        <p class="font-weight-bold cstm-clr-pr-dialog" style="color:#10242B;font-size:13px">
                            Validity From*
                        </p>
                        

                        <v-menu
                            ref="calender"
                            :close-on-content-click="false"
                            v-model="validityFrom"
                            :nudge-right="40"
                            lazy
                            transition="scale-transition"
                            full-width
                            max-width="290px"
                            min-width="290px" 
                              class="rounded-0"
                            >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-bind="attrs"
                                    v-on="on"
                                    label="Select.."
                                    :value="computedDateFormattedMomentjs2"
                                    solo
                                     class="rounded-0"
                                    readonly 
                                >
                    <div  slot="prepend-inner" style="background-color:#F7F7F7;  padding: 8px !important"><span class="material-icons">date_range</span></div>
                                
                                </v-text-field>
                            </template>                        
                                    <v-date-picker
                                        v-model="validityFromDate"
                                        no-title
                                        
                                    ></v-date-picker>
                        </v-menu>


                    </v-layout>
                    </v-flex> -->

            <v-flex xs5>
                    <v-layout column class=" mx-2  cust_pr_quick cust-rmvpd-pr-quick">
                        <p class="font-weight-bold cstm-clr-pr-quick">
                           Validity To*
                        </p>
                        
                        <v-menu
                            ref="calender"
                            :close-on-content-click="false"
                            v-model="validityTo"
                            :nudge-right="40"
                            lazy
                            transition="scale-transition"
                            full-width
                            max-width="290px"
                            min-width="290px" 
                            class="rounded-0">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-bind="attrs"
                                    v-on="on"
                                    id="fromDate"
                                    label="dd/mm/yyyy"
                                    :value="computedDateFormattedMomentjs3"
                                    solo
                                    :rules="[dateCheck()]"
                                    class="rounded-0"
                                    readonly
                                    required>
                            <div  slot="prepend-inner"  style="background-color:#F7F7F7;  padding: 8px !important"><span class="material-icons small">date_range</span></div>
                                
                                </v-text-field>
                            </template>                        
                                    <v-date-picker 
                                        v-model="validityToDate"
                                        no-title
                                        @input="validityTo = false"
                                    ></v-date-picker>
                        </v-menu>

                    </v-layout>
                </v-flex>


                    <!-- <v-flex xs5>
                        <v-layout column class=" mx-2 mt-2 cust_pd_dialog cust-rmvpd-pr-dialog">
                            <p class="font-weight-bold cstm-clr-pr-dialog">
                                Validity To*
                            </p>
                          

                            <v-menu
                                ref="calender"
                                :close-on-content-click="false"
                                v-model="validityTo"
                                :nudge-right="40"
                                lazy
                                transition="scale-transition"
                                full-width
                                max-width="290px"
                                min-width="290px" 
                                class="rounded-0"
                                >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-bind="attrs"
                                        v-on="on"
                                        label="Select.."
                                        :value="computedDateFormattedMomentjs3"
                                        solo
                                        class="rounded-0"
                                        readonly 
                                    >
                        <div  slot="prepend-inner" style="background-color:#F7F7F7;  padding: 8px !important"><span class="material-icons">date_range</span></div>
                                    
                                    </v-text-field>
                                </template>                        
                                        <v-date-picker
                                            v-model="validityToDate"
                                            no-title
                                            
                                        ></v-date-picker>
                            </v-menu>


                        </v-layout>
                    </v-flex> -->

               </v-layout>


                    <v-layout class="mx-2" style="border: solid 1px #f7f7f7">
                   
                        <p class="font-weight-black ml-2 mt-2" style="font-size:14px" >Your Uploaded Documents</p>
                   
                    </v-layout>



                    <!-- Table  -->
                    <v-layout  v-if="alldocs.length != 0" class="mx-2" style="border: solid 1px #f7f7f7">
                    <!-- <template> -->
                    <!-- <v-simple-table>
                        <template v-slot:default> -->
                            <div class="tableWrap" >
                                         <table>
                                 <thead>
                            <tr bgcolor = '#23b1a9'>
                            <th  class="text-left headnew" style="font-size:14px;color:#fff;width: 150px">
                                Document No.
                            </th>
                            <th class="text-left headnew" style="font-size:14px;color:#fff; width: 150px">
                                File Name
                            </th>

                            <th class="text-left headnew" style="font-size:14px;color:#fff; width: 250px">
                                Document Type
                            </th>

                            <th class="text-left headnew" style="font-size:14px;color:#fff;width: 150px">
                                Action
                            </th>
                            </tr>
                        </thead>
                        <!-- <pre> {{alldocs}} </pre> -->
                        <tbody>
                            <tr
                            v-for="(doc,index) in alldocs"
                            :key="index"
                            >
                            <td>
                                <div class="rowitnow">
                                    {{ index+1 }}
                                    <div v-if="getextension(doc.fileName) == 'pdf' || getextension(doc.fileName) =='PDF'" > 
                                     <v-icon>picture_as_pdf</v-icon>
                                     </div>
                                     <div v-else >
                                     <img :src="doc.hasOwnProperty('blobis') ? doc.blobis : doc.fileUrl" class="imgsizeis" />
                                     </div>
                                   
                                </div>
                            </td>
                            <td class="addspace" >{{ doc.fileName | truncate(24, '...') }}</td>
                            <td class="addspace rghtbrd" >{{ doc.hasOwnProperty('docType') ?  doc.docType : '-' }}</td>
                            <td class="addspace rghtbrd" >
                                <img @click="deletedoc(doc._id,index, doc)" src="../../assets/deleteicon.png" class="deliconis" />
                            </td>

                            </tr>
                        </tbody>
                            </table>
                            </div>
                   
                       
                        <!-- </template>
                    </v-simple-table>
                    </template> -->
                        
                    </v-layout>

                      <v-layout  v-else class="mx-2 rowitnow" style="border: solid 1px #f7f7f7">
                           
                        <p class="font-weight-bold cstm-clr-pr-dialog " style="margin-bottom:0px !important;text-align:center;color:#10242B;font-size:15px !important">
                          No documents found
                        </p> 
                           
                      </v-layout>

                    <!-- Buttons -->
                    <v-layout class="mr-2 mt-4 pb-4 pt-3" row justify-center style="">
                        <v-btn tile  dark color="#152F38" class=" px-3 text-capitalize" small @click="close_Dialog()">
                            <v-icon color="" small >cancel</v-icon>
                            <v-divider vertical class="ml-2 mr-2 text-capitalize"></v-divider>Cancel
                        </v-btn>

                        <v-btn  @click="uploadserver" dark  tile color="#23B1A9" class="text-capitalize px-3 ml-2" type="submit" small>
                            <v-icon dark small>cloud_upload</v-icon> <v-divider vertical class="mx-2"></v-divider>
                        Upload</v-btn>

                    </v-layout>


                    



                        </v-card>
                        </v-card>   
                    </v-dialog>
         </v-layout>

</v-app>
</template>

<script>
import moment from 'moment';
import ErrorWarnings from '../../component/ErrorWarnings'

export default {


  data() {

        return {
            docfilename:"----",
            upload_docs:false,
            addblob:null,
            edited_items:null,
            alldocs:[],
            validmedia_file:null,
            edited_item:null,
            document: [
                {
                    docNumber: 1,
                    fileName: 'Doc1.pdf',
                    documentType: 'Registration Certificate',
                    action: 'mdi-delete'
                }

            ],

            docType: '',
            validityFrom: false,
            validityFromDate:'',
            fromdate:'',
            validityTo: false,
            validityToDate: '',
	
            typeOfDocument:['PAN','Registration Certificate','Tariff','MOU','Photo','Others','De-Empanelment Notice Response'],

            dialogBox: true,
             dateCheck(){
                // let todayDate = Date.now();
                let fromDate=moment(this.validityFromDate,'YYYY-MM-DD ').toDate().getTime()
                let Todate= moment(this.validityToDate,'YYYY-MM-DD ').toDate().getTime()
                console.log(fromDate,Todate,Todate < fromDate,"todayDate")
                // let CurrentDate= todayDate
                if(Todate < fromDate){
                    // this.showToast("The Product 'Effective date From' can not be greater than Product 'Effective date To'.",this.TOST().WARNING)
                    return "Validity Upto must be greater than Validity From"
                }
            },
        }
    },

    props:{
        setdoc:{
            default:{docen:false, provider_data:'',type:'new'},
            type:Object
        }
    },

 computed: {
	        computedDateFormattedMomentjs2 () {
            		console.log(this.validityFromDate)
            		console.log(this.validityFromDate ? moment(this.validityFromDate).format("DD-MM-YYYY"): '')
            		return this.validityFromDate ? moment(this.validityFromDate).format("DD-MM-YYYY"): ''
        	},
        	computedDateFormattedMomentjs3 () {
           		 console.log(this.validityToDate)
            		console.log(this.validityToDate ? moment(this.validityToDate).format("DD-MM-YYYY"): '')
            		return this.validityToDate ? moment(this.validityToDate).format("DD-MM-YYYY"): ''
       	    },
},

    created(){

         if(this.setdoc.type == 'new'){
             this.alldocs.push(...this.setdoc.updocs);
         }

         if(this.setdoc.type == 'edit' || this.setdoc.type == 'validate'){
             this.GETDOCS();
         }

    },

    filters:{
      truncate: function (text, length, suffix) {
            if (text.length > length) {
                return text.substring(0, length) + suffix;
            } else {
                return text;
            }
        },
    },

	methods:{

        getextension(filepath){

            var ext = /^.+\.([^.]+)$/.exec(filepath);
            return ext == null ? "" : ext[1];

        },

        deleteimgfile() {
            this.upload_docs = false; 
            this.docfilename = "----";
            this.validmedia_file =null
            // this.$refs.maindoc.value = ""; 

            // this.alwSbmt=false
             
        },

        deletedoc(delete_id, ind, docis){
            console.log("docis ", docis)

            if(this.setdoc.from == 'addendum'){
                this.showToast("Can't perform this action on Addendum" ,this.TOST().WARNING); 
                return
            }
          

           if(this.setdoc.type == 'new'){
               var posss =  this.alldocs.findIndex(e=> e._uid == docis._uid);
               this.alldocs.splice(posss, 1);
               this.$emit("deletenow", docis._uid);
               return
           }

            var self = this

            var build_del_api = this.setdoc.from == 'emp' ? 'empanelment/delete-document/' : this.setdoc.from == 'tds' ? 'empanelment/delete-tds-certificate/' : 'provider/delete-document/'

                this.DELETE(2,build_del_api+delete_id, (res, error)=>{
                     this.validmedia_file = null
                                console.log("delete docs success  => ", res.data);
                                if(res != null){
                                    self.alldocs.splice(ind, 1);

                                      if(this.setdoc.from == 'emp'){

                                          var store_doc = this.$store.state.emp_init_docs
                                          var poss = store_doc.findIndex(e=> e._id == docis._id);
                                          store_doc.splice(poss, 1)
                                          this.$store.commit('EMPDOCS',store_doc)
                                       
                                      }                          

                                    self.showToast(res.data.msg ,self.TOST().SUCCESS);
                                }else{
                                    console.log("when status is not 200", res);
                                    self.showToast(res.data.msg ,self.TOST().ERROR); 
                                }

                })
        },

        sanitizenow(data){
            if(data == null || data == undefined || data == '' || data == 'Invalid date'){
                return ''
            }else{
                return data
            }
        },

        GETDOCS(){
            console.log("from store we have ", this.$store.state.emp_init_docs);
        //    alert("GETDOCS called")
            //empanelment/docs-by-empanelment/{empanlementId} ,from:'pro' /empanelment/tds-upload-certificate
         
            var build_api = this.setdoc.from == 'emp' ? 'empanelment/docs-by-empanelment/' : this.setdoc.from == 'tds' ? 'empanelment/all-tds-certificates/' :  this.setdoc.from == 'addendum' ? 'empanelment/empanelment-details-by-id/' : 'provider/docs-by-provider/'
         
            // console.log("build_api is --> ",build_api);
            
            this.GET(2,build_api+this.setdoc.provider_data._id, res=>{
                                console.log("GETTING UPDATED documents  => ", res.data.data);
                    if(res.status == 200){
                     if(this.setdoc.from != 'addendum' ){
                          this.alldocs = res.data.data;
                     }else{
                          this.alldocs = []
                          if(res.data.data.hasOwnProperty('addendum')){
                               this.alldocs.push(res.data.data.addendum)
                          }
                         
                     }
                       
                        // if(this.setdoc.from == 'emp'){
                        //     this.alldocs.push(...this.$store.state.emp_init_docs)
                        // }
                        console.log("all docs is after calling getdocs res -> ",  this.alldocs);
                    }
                    this.validmedia_file = null
                
            })
            
        },

        fileup(){
            console.log("file upload called ");
             document.getElementById('maindoc').value = "";
             document.getElementById('maindoc').click();
        },

        upload_file(event){

             //  if(this.main_type[this.maindoc_category_ind].alldocs.length)
                // alert("UPLOAD_FILE CALLED");
                // console.log(event,"| QC EDIT ", qcedit);

                if(event.target.files.length == 0){
                    console.log(this.edited_items,"editm");
                    this.edited_items = null 
                    this.addblob = null 
                    return 
                }
            
               var compress_start = false;
               var img_compress = ''
               
               var tempimgdata = []
                var files = []
                var flenis = event.target.files.length
                var mynewarr = []
                var finalvalid = []
               
                console.log('event files ->',  event.target.files.length);

                if(flenis == 0){
                        console.log('event files length ->', flenis );
                    return
                }else{
                     console.log('proceed as files found ->', flenis );
                }

                // return
            
                for(let i=0; i<flenis;i++){
                    console.log("size is ->", event.target.files[i].size);
                    if(event.target.files[i].size > 5000000){
                        //  console.log("next, file found to big", i)
                         this.showToast('Please upload file with size less than 5 MB', this.TOST().WARNING);
                         
                         return
                    }else{
                          mynewarr.push(event.target.files[i]);
                    }
                }

                // var regex_VID = new RegExp('^(jpg|jpeg|pdf)$');
                for(let i=0; i<mynewarr.length;i++){

                    // if(mynewarr[i].type.split('/')[1] == 'png'){
                    //     finalvalid.push(mynewarr[i])
                    // }else 
                    // || mynewarr[i].type.split('/')[1] == 'PDF'

                    console.log('FILE TYPE IS ',mynewarr[i].type.split('/')[1])

                    if(mynewarr[i].type.split('/')[1] == 'jpg'){
                        finalvalid.push(mynewarr[i])
                    }else if(mynewarr[i].type.split('/')[1] == 'jpeg'){
                        finalvalid.push(mynewarr[i])
                    }else if(mynewarr[i].type.split('/')[1] == 'png'){
                        finalvalid.push(mynewarr[i])
                    }else if(mynewarr[i].type.split('/')[1] == 'pdf' || mynewarr[i].type.split('/')[1] == 'PDF'){
                        finalvalid.push(mynewarr[i])
                    }else{
                        console.log("in-valid file");
                    
                        this.showToast('Only Images and PDF files are allowed', this.TOST().WARNING);
                        // var posis = i 

                    //   mynewarr.splice(posis, 1) 
                    }
                }

                console.log("finalvalid file ", finalvalid[0]);
        
               this.validmedia_file = finalvalid[0];
                if(this.setdoc.type == 'new'){
                  this.addblob = finalvalid[0];
                }

                this.upload_docs = true;
                this.docfilename = finalvalid[0].name;

            // console.log(finalvalid,"<<--- finalvalid | type of media file is ",  this.validmedia_file);

        },

        doctypecheck(callback){

            var dtype = false
            for(let i=0; i<this.alldocs.length; i++){
                    if(this.docType == this.alldocs[i].docType){
                        // this.showToast('Selected Document Type Already exists', this.TOST().WARNING);
                        dtype = true
                    }
            }

            callback(dtype)

        },

        // checkdtype(){
        //     this.doctypecheck(res=>{
        //        if(res){
        //           this.showToast('Selected Document Type '+this.docType+' Already exists', this.TOST().WARNING);
        //           return
        //        }
        //    })
        // },

        uploadserver(){

            let fromDate=moment(this.validityFromDate,'YYYY-MM-DD ').toDate().getTime()
            let Todate= moment(this.validityToDate,'YYYY-MM-DD ').toDate().getTime()
            // console.log(fromDate,Todate,Todate < fromDate,"todayDate")
                // let CurrentDate= todayDate
            if(Todate < fromDate){
                this.showToast("Validity Upto must be greater than Validity From",this.TOST().WARNING)
                return 
            }
            console.log("upload server called -->>> ", this.validmedia_file);
            // alert("here ?")
            if(this.validmedia_file == null ){
                    this.showToast('Please, add a file to continue upload', this.TOST().WARNING);
                    return
            }

            //   console.log("after mediaFile ", this.validmedia_file);
        if(this.setdoc.from != 'addendum' ){
             this.docType = this.sanitizenow(this.docType)
             this.validityFromDate = this.sanitizenow(this.validityFromDate)
             this.validityToDate = this.sanitizenow(this.validityToDate)
            if(this.docType == ''){
                this.showToast('Please, add a Document Type to continue upload', this.TOST().WARNING);
                return
            }

            if(this.validityFromDate == '' || this.validityToDate == ''){
                this.showToast('Please, add Dates to continue upload', this.TOST().WARNING);
                return
            }
        }

        //     var dtypecheckis = false
        //    this.doctypecheck(res=>{
        //         dtypecheckis = res
        //        if(res){
        //           this.showToast('Selected Document Type '+this.docType+' Already exists', this.TOST().WARNING);
        //           return
        //        }
        //    }
        //    if(dtypecheckis){
        //        return
        //    }
            //   console.log("after doctype ", this.docType);
            //  console.log(this.validityToDate,"after dates ", this.validityFromDate);
            var formData = new FormData();
            formData.append('file',this.validmedia_file);
             
            if(this.setdoc.type == 'edit' || this.setdoc.type == 'validate'){
                formData.append('_id',this.setdoc.provider_data._id);
                formData.append('prn',this.setdoc.provider_data.prn);
            }
          

           if(this.setdoc.from != 'addendum'){
                formData.append('docType', this.docType);
                formData.append('validFrom', moment(this.validityFromDate).format('YYYY-MM-DD'));
                formData.append('validTo', moment(this.validityToDate).format('YYYY-MM-DD'));
           }
           
            // '10/02/2021'

            console.log("fordata", formData);

            // return
                // var formData =  {
                //     file:this.validmedia_file,
                //     _id :'5fdb0c342e70aad137c42462',
                //     prn:'PRN1000103',
                //     docType :'MOU',
                //     validFrom :'10/12/2020',
                //     validTo :'10/02/2021'
                // }
                // formData.append('file ',this.validmedia_file);
                // console.log(moment(this.validityFromDate).format('DD-MM-YYYY'),"my form Data is ------->-> ")
                // return
                var self = this;
                var build_api = self.setdoc.from == 'emp' ? 'empanelment/upload-document' : self.setdoc.from == 'tds' ? 'empanelment/tds-upload-certificate' :  'provider/upload-document'
                console.log("build_api for UPLOADSERVER -> ", build_api)

               if(this.setdoc.from == 'addendum'){
                    self.$emit("getdocslist",{url:'empanelment/generate-addendum',form_data: formData}); 
                    document.getElementById('maindoc').value = "";
                    self.deleteimgfile();
                    self.close_Dialog();
                    return
               }
                
                this.POST(2, build_api, formData, (res, error)=>{
                      this.validmedia_file = null;
                      document.getElementById('maindoc').value = "";
                      console.log(res,"<< res is");
                        // console.log("docment upload response is => ", error);
                        // if(error.res.statusCode == 4-00){
                        //     console.log("error is in upload document", error)
                        // }
                        if(res != null){

                             if(this.setdoc.type == 'new'){
                                    // alert("As type new get we're now emitting docs data");
                                 
                                    let final_imgobj = res.data.data
                                   
                                    var picReader = new FileReader();
                                    
                                    picReader.readAsDataURL(self.addblob);
                                    picReader.onload = function(e){
                                         final_imgobj.blobis = e.target.result;
                                         final_imgobj._uid = self.uid();
                                         console.log("finalimg_obj is ",final_imgobj);
                                         self.alldocs.push(final_imgobj);
                                         self.$emit("getdocslist",final_imgobj);
                                    }   

                                  
                                    
                             }else{
                                 
                                       self.GETDOCS();
                                
             
                             }                   
                            // this.validmedia_file = null
                            this.showToast(res.data.msg, this.TOST().SUCCESS);
                        }else{
                            this.showToast(res.data.msg, this.TOST().ERROR);
                        } 
                          this.deleteimgfile()     
                })
        },

        uid(){
            return Date.now().toString(36) + Math.random().toString(36).substr(2);
        },

 		close_Dialog(){
                    // this.setdoc = false;
                    this.$emit('closedoc');
        },

	 	open_Dialog(){
           		 this.setdoc.docen = true;
       	},
	}

}
</script>


<style>

.rowitnow2{
    display:flex; 
    flex-direction: row;
    width: 100%;
    
    justify-content: space-between;
}
.rmrg16{margin-bottom:0px !important;}
.instructions{ letter-spacing: 0.1px; font-family: roboto; font-weight:400; font-size:9.6px; color: #272727;  }
.addspace{
    padding: 5px;
    padding-left: 10px;
    border-left: 1px #ccc solid;
}

.rghtbrd{  border-right: 1px #ccc solid; }

.tableWrap {
  height: 220px;
  /* border: 2px solid black; */
  overflow: auto;
}
/* Set header to stick to the top of the container. */
.tableWrap thead tr th {
  position: sticky;
  top: 0;
}

.tableWrap table {
 border-collapse: collapse;
}

/* Because we must set sticky on th,
 we have to apply background styles here
 rather than on thead */
.headnew {
  padding: 16px;
  padding-left: 15px;
  border-left: 1px dotted rgba(200, 209, 224, 0.6);
  border-bottom: 1px solid #e8e8e8;
  background: #01b4bb;
  text-align: left;
  box-shadow: 0px 0px 0 2px #e8e8e8;
}

/* table tbody {
  display: block;
  max-height: 170px;
  overflow-y: scroll;
}

table thead, table tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
} */

.cust_pr_dialog .v-input .v-label {
    font-size: 13px !important;
}

.deliconis{ width: 21px; height: 21px; cursor: pointer; }

.imgsizeis{ width:45px; height:45px; padding:5px; }

.rowitnow{
    display:flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.cust_pr_dialog .v-input{
    font-size: 13px !important;
}

.cust_pr_dialog .v-input input {
    font-size: 13px !important;
}


.cstm-clr-pr-dialog{
    color:#646666 !important;
    margin-bottom: 5px !important;
    font-size: 13px !important;
}


.cust_pd_dialog .v-text-field .v-input__slot,
.v-text-field--outline .v-input__slot {
    min-height: 42px !important;
    display: flex !important;
    align-items: center !important;
    /* border:1px red solid; */
}



.cust-rmvpd-pr-dialog .v-text-field.v-text-field--enclosed>.v-input__control>.v-input__slot {
    padding: 0px !important;
}

</style>