<template>
    <v-dialog transition="scale-transition" width="auto" v-model="isOpen">

        <v-card>
            <v-toolbar color="#23b1a9" title="Opening from the top">
                <h4 class="white--text"> {{ title }} </h4>
            </v-toolbar>
            <v-card-text class="px-8 pt-8">
                <div class="py-6 bg-gray rounded">
                    <ul class="list-style-none w-400">
                        <li v-for="message in validations">
                            {{ message }}
                        </li>
                    </ul>
                </div>
            </v-card-text>
            <v-card-actions class="justify-center pb-5">
                <v-btn color="#23b1a9" class="white--text pa-4" @click="isOpen = false">Close</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>


<script>

export default {
    data() {
        return {
            isOpen: false,
            title: '',
            validations: []
        };
    },
    methods: {
        showAlert(messages, title = 'Validation Error') {
            this.validations = messages;
            this.title = title;
            this.isOpen = true;
        },
        closeAlert() {
            this.isOpen = false;
            this.validations = []
        }
    },
}

</script>

<style lang="scss" scoped>
.bg-gray {
    background-color: rgb(247, 247, 247);
}

.list-style-none {
    list-style: none;
}

.w-400 {
    min-width: 800px;
}
</style>