export const botStatusCodesList = [100, 101, 108, 111, 118, 121, 128, 129, 131, 138, 139, 141, 148, 149, 151, 158, 159, 161, 168, 171, 178, 999];
export const botStatusTypeItems = [
  {
    statusCode: 100,
    type: 'Bot to Start'
  },
  {
    statusCode: 101,
    type: 'DPC Supervisor Restart'
  },
  {
    statusCode: 108,
    type: 'DPC Supervisor Failed'
  },
  {
    statusCode: 110,
    type: 'DPC Supervisor Completed',
  },
  {
    statusCode: 111,
    type: 'DPC Restart',
  },
  {
    statusCode: 118,
    type: 'DPC Failed',
  },
  {
    statusCode: 120,
    type: 'DPC Completed',
  },
  {
    statusCode: 121,
    type: 'Commercial Approval Supervisor Restart',
  },
  {
    statusCode: 128,
    type: 'Commercial Approval Supervisor Failed',
  },
  {
    statusCode: 129,
    type: 'Commercial Approval Supervisor Rejected',
  },
  {
    statusCode: 130,
    type: 'Commercial Approval Supervisor Completed',
  },
  {
    statusCode: 131,
    type: 'Commercial Approval Restart',
  },
  {
    statusCode: 138,
    type: 'Commercial Approval Failed',
  },
  {
    statusCode: 139,
    type: 'Commercial Approval Rejected',
  },
  {
    statusCode: 140,
    type: 'Commercial Approval Completed',
  },
  {
    statusCode: 141,
    type: 'Medical Approval Supervisor Restart',
  },
  {
    statusCode: 148,
    type: 'Medical Approval Supervisor Failed',
  },
  {
    statusCode: 149,
    type: 'Medical Approval Supervisor Rejected',
  },
  {
    statusCode: 150,
    type: 'Medical Approval Supervisor Completed',
  },
  {
    statusCode: 151,
    type:'Medical Approval Restart',
  },
  {
    statusCode: 158,
    type:'Medical Approval Failed',
  },
  {
    statusCode: 159,
    type: 'Medical Approval Rejected',
  },
  {
    statusCode: 160,
    type:  'Medical Approval Completed',
  },
  {
    statusCode: 161,
    type:  'Payment Supervisor Restart',
  },
  {
    statusCode: 168,
    type:   'Payment Supervisor Failed',
  },
  {
    statusCode: 170,
    type: 'Payment Supervisor Completed',
  },
  {
    statusCode: 171,
    type: 'Payment Restart',
  },
  {
    statusCode: 178,
    type: 'Payment Failed',
  },
  {
    statusCode: 999,
    type: 'Payment Completed',
  },
  {
    statusCode: '000',
    type: 'Manual'
  }
];
export const claimTypeItems= ['Cashless', 'Reimbursement', 'Cashless Claim File'];
export const botWorkItemsHeaders= [
    {
      text: 'Claim Number',
      align: 'start',
      sortable: false,
      value: 'claimNumber',
      width: '50'
    },
    { text: 'Claim Type', align: 'start', value: 'claimType', width: '50', },
    { text: 'Claim Sub Type', align: 'start', value: 'claimSubType', width: '40' },
    { text: 'Feature Number', align: 'start', value: 'featureNumber', width: '10' },
    { text: 'Adjudication Type', align: 'start', value: 'adjudicationType', width: '10' },
    { text: 'Adjudication Category', align: 'start', value: 'adjudicationCategory', width: '10' },
    { text: 'Status-Code', align: 'start', value: 'botStatusCode', width: '50' },
    { text: 'Status-Type', align: 'start', value: 'botStatusType', width: '50' },
    { text: 'Processing-Type', align: 'start', value: 'processingType' },
    { text: 'Reason', align: 'start', value: 'remarks' },
    {
      text: 'Created At',
      align: 'start',
      sortable: false,
      value: 'createdAt',
      width: '180'
    },
    {
      text: 'Updated At',
      align: 'start',
      sortable: false,
      width: '180',
      value: 'updatedAt'
    },
    { text: 'Edit', align: 'start', value: 'actions', sortable: false },
  ]
  export const processingType= ['Auto', 'Manual'];
  export const dateAndTimeFormat = "DD-MM-YYYY LT"
