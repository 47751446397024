<template>
    <div>
        <v-card color="#F7F7F7" class="mb-1" tile>
            <v-btn-toggle v-model="text" dense class="" @change="tabseleted(text)" style="background-color:#F7F7F7;opacity:100%;display:flex;" >
                 
                <div style="flex:1;" >
                  <v-btn  flat  min-height="90px" style="width:100%;letter-spacing: unset"  value="MemberDetails" class="text-capitalize font-weight-bold rounded-0" active-class="tarrifConfgSelected">
                    Member Details
                  </v-btn>
                </div>

                <div style="flex:1;">
                  <v-btn  flat :disabled="shwtabs" min-height="90px" style="width:100%;letter-spacing: unset"   class="text-capitalize font-weight-bold rounded-0" active-class="tarrifConfgSelected">
                    Provider Details
                  </v-btn>
                </div>

                <div style="flex:1;">
                  <v-btn  flat :disabled="shwtabs" min-height="90px" style="width:100%;letter-spacing: unset"   class="text-capitalize font-weight-bold rounded-0" active-class="tarrifConfgSelected">
                   Patient Details From Hospital
                  </v-btn>
                </div>
                 <div style="flex:1;">
                  <v-btn  flat :disabled="shwtabs" min-height="90px" style="width:100%;letter-spacing: unset"  class="text-capitalize font-weight-bold rounded-0" active-class="tarrifConfgSelected">
                   Patient Admission Details
                  </v-btn>
                </div>
                 
                 <div style="flex:1;" >
                  <v-btn  flat :disabled="shwtabs" min-height="90px" style="width:100%;letter-spacing: unset"  class="text-capitalize font-weight-bold rounded-0" active-class="tarrifConfgSelected">
                   Invoice Addition Screen
                  </v-btn>
                </div>

                <div style="flex:1;">
                  <v-btn  flat :disabled="shwtabs" min-height="90px" style="width:100%;letter-spacing: unset" class="text-capitalize font-weight-bold rounded-0" active-class="tarrifConfgSelected">
                    Billing Screen
                  </v-btn>
                </div>

            </v-btn-toggle>

            <!-- <MedConsumeable></MedConsumeable> -->

            <component :is="selectedComponent"></component>

        </v-card>
    </div>
</template>
<script>
    import MemberDetails from './MemberDetails.vue'

export default {
    components:{
        MemberDetails
    },
    data(){
        return{
            text:"",
            selectedComponent:"",
        }
    },
    methods:{
      tabseleted(text){
            console.log(text,"Selected tab")
            this.selectedComponent = text;
      },
    },
    
}
</script>
<style >
.tarrifConfgSelected{
  background-color: #23B1A9 !important;
  color:#FFFFFF !important;
  
}

.cust_brdr_green{
  /* z-index:200 !important; */
  border-bottom-width:5px !important;
  border-bottom-color:green !important;
  border-bottom-style: solid !important ;
  
  

}
.cust_brdr_red{
   border-bottom-width:5px !important;
  border-bottom-color:red !important;
  border-bottom-style: solid !important ;
}

</style>