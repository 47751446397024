<template>
<v-app>

        <div style="background-color: #FFFFFF;" column class="py-3">
            <v-row>
                <v-flex xs3> </v-flex>
                <v-flex xs3> </v-flex>

                <v-flex xs3>
                    <v-select
                        :items="lobOptions"
                        label="Select"
                        v-model="selectedLob"
                        item-text="lob"
                        :return-object="true"
                        solo
                        dense
                        class="rounded-0 text-capitalize px-3 mx-2"   
                        @change="getCount(true)"                     
                    >
                    </v-select>
                    
                </v-flex>
                <v-spacer></v-spacer>
                <v-flex xs3> 
                    <v-btn 
                        dark  
                        tile 
                        color="#E46A25" 
                        class="text-capitalize px-3 mx-2" 
                        medium 
                        @click="getFile()"
                    >
                        <v-icon dark small>mdi-file-excel-outline</v-icon> 
                        <v-divider vertical class="mx-2"></v-divider>
                        Download Excel
                    </v-btn>
                </v-flex>
            </v-row>

        </div>

        <v-card class="rounded-0">
            <v-layout class="headercolor">
                <p class="cardfont" style="font-weight:700;margin-bottom:7px!important;margin-top:10px;padding-left:10px;">Count of Failed Transactions</p>
                
            </v-layout>
            <v-data-table
                :headers="headers"
                :items="failedTransactions"
                hide-default-footer
                dense                   
            >                        
            </v-data-table>
        </v-card>
    </v-app>
</template>

<script>
import moment from 'moment';
  
export default {
    data() {
        return {
            headers:[
                {text: "Type", value: 'type',sortable: false,divider:true,class:"hdr-txt-clrPer hdr-bg-clrPer srWidth",width:"250px"},
                {text: 'Indemnity', value: 'indemnity',sortable: false,divider:true,class:"hdr-txt-clrPer hdr-bg-clrPer",width:"250px"},
                {text: 'Expense', value: 'expense',sortable: false,divider:true,class:"hdr-txt-clrPer hdr-bg-clrPer",width:"250px"},
                {text: 'Total', value: 'total',sortable: false,divider:true,class:"hdr-txt-clrPer hdr-bg-clrPer",width:"250px"},
            ],
            failedTransactions: [],
            lobOptions:[
                { "lob":"Health","url":"HEALTH_FAILEDTRANSACTIONS" },
                { "lob":"Marine","url":"MARINE_FAILEDTRANSACTIONS" },
                { "lob":"AIGC","url":"MARINE_FAILEDTRANSACTIONS" },
                { "lob":"PE","url":"MARINE_FAILEDTRANSACTIONS" },
                { "lob":"Extended Warranty","url":"EW_FAILEDTRANSACTIONS" }, 
                { "lob":"Travel","url":"TRAVEL_FAILED_TRANSACTION" }, 
                { "lob":"PA","url":"TRAVEL_FAILED_TRANSACTION" },
                { "lob":"Home","url":"HOME_FAILED_TRANSACTION" }
            ],
            selectedLob:{"lob":"Marine","url":"MARINE_FAILEDTRANSACTIONS"}
        };
    },

    methods:{
       getCount(isCount){
        let reqData = '';
        if(this.selectedLob.url == 'TRAVEL_FAILED_TRANSACTION' || this.selectedLob.url == 'MARINE_FAILEDTRANSACTIONS') {
            reqData = `sendCount=true&lob=${this.selectedLob.lob}`;
        } else {
            reqData = "sendCount=true";
        }
        // let reqData =   "sendCount=true"// + (isCount ? "true" : "false")
        console.log("Inside failedTransactions")        
        this.showProgress()
        this.GETM(this.selectedLob.url,reqData,(res)=>{
            this.hideProgress()
            console.log(res)
            if(isCount && res.data && res.data.data){
                let typesOfTransactions =   Object.keys(res.data.data);
                let allTransactions     =   res.data.data
                this.failedTransactions =   []
                for(var i in typesOfTransactions){
                    this.failedTransactions.push({"type":typesOfTransactions[i].toUpperCase(),"indemnity":allTransactions[typesOfTransactions[i]].indemnity,"expense":allTransactions[typesOfTransactions[i]].expense,"total":allTransactions[typesOfTransactions[i]].total ? allTransactions[typesOfTransactions[i]].total : allTransactions[typesOfTransactions[i]].indemnity + allTransactions[typesOfTransactions[i]].expense})
                }
            }else{
                console.log("No Data")
                this.showAlert("No data found")
            }
        },(err)=>{
            console.log(err)
            this.hideProgress()
            this.failedTransactions =   []
        })
       },
       getFile(){
           let reqData =   ""// + (isCount ? "true" : "false")
            if(this.selectedLob.url == 'TRAVEL_FAILED_TRANSACTION'  || this.selectedLob.url == 'MARINE_FAILEDTRANSACTIONS') {
                reqData = `sendCount=false&lob=${this.selectedLob.lob}`;
            } else if(this.selectedLob.url === 'HOME_FAILED_TRANSACTION'){
                reqData = ""
            } else {
                reqData = "sendCount=false";
            }
            console.log("Inside failedTransactions")
            let url     =   this.getURL(this.selectedLob.url)+ "?" + reqData;
            window.open(url)
       }
    },
     created(){
        this.$store.commit("SAVE_PAGE_HEADER", "GC Sync Failure Report");
        this.getCount(true)
    }

    
}
</script>

<style>

.cust_pdPerSearch .v-input .v-label {
    font-size: 13px !important;
}
.cust_pdPerSearch .v-input {
    font-size: 13px !important;
}
.cust_pdPerSearch .v-input input {
    font-size: 13px !important;
}


.cust_pr_tds .v-input .v-label {
    font-size: 13px !important;
    
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
    font-size: 0.84rem !important;
}

.hdr-txt-clrPer{
    color: white !important;
    font-weight: bold;
    /* padding:0px!important;
    padding: 10px !important;
    padding-left: 14px !important; */
}

.hdr-bg-clrPer{
  background-color: #23B1A9;
}
.headercolor{
    background-color: #eee;
}
.cardfont{
    font-size: 0.84rem !important;
    padding-right: 2px;
}
.srWidth{
    width: 70px;
}


 .cust-bnkAcc-Permission .v-pagination__navigation{
        box-shadow:unset !important;
        border-radius: unset !important;
        background-color:#FFFFFF !important;
        border:1px solid #DAE1E6 !important
    }
    .cust-bnkAcc-Permission .v-pagination__navigation--disabled{
        background-color:#DAE1E6 !important;
    }
    .cust-bnkAcc-Permission .v-pagination__item{
        box-shadow:unset !important;
        min-width:2rem !important;
        height:2rem !important;
        border-radius: unset !important;
        border:1px solid #DAE1E6 !important
    }  
    .rightside{
        margin: 10px;
        margin-left: 70%;
    }

</style>