<template>
  <v-container class="containerStyle" >
    <v-form id="" ref="fnolform" @submit.prevent="submitFNOL">
      <!-- main Deails start hear -->
      <v-radio-group
        row
        v-model="claimDetails.claimType"
        class="pt-4 topdiv ml-3"
        id="radiogrp">
        <v-radio
          class="mb-0 mr-3 claim fnolRadioButtons"
          v-for="(opt,i) in claims"
          :id="'fnolRadioButton_'+i"
          :key="opt.name"
          :label="`${opt.name}`"
          :value="opt.name"
           :disabled="$store.getters.fetchFnolPageEditCheck=='FnolEditPage'"
           :style="{ backgroundColor: opt.selected ? '#e46a25' : 'white' }"
           @click="checkClaimValue(opt.name)">
        </v-radio>
      </v-radio-group>
      
      <v-container class="mainHeadBackgroundClaim containerMaxWidth" v-if="hideClaimNumber">
          <v-layout>
              <v-flex  class="ClaimGroup" v-if="disableClaimNumber==true">
            <v-layout column class="ml-3 mt-2 text-center">
              <div class="font-weight-bold fontsize">
                Claim Number
              </div>
              <div class="fontsize">{{claimDetails.mainClaimNumber}}</div>
          
             <!-- <div class="font-weight-bold mb-1" style="font-size: 14px">
                Claim Number
              </div>
              <v-text-field
                  v-if="disableClaimNumber==true"
                  id="claimnumber"
                  solo
                  v-model="claimDetails.mainClaimNumber"
                  dense
                  class="forminput mr-3"
                  style="min-height: 42px !important"
                  :disabled="disableClaimNumber">
              </v-text-field>  -->
            </v-layout>
          </v-flex>
          <v-flex  class="ClaimGroup" >
            <v-layout column class="ml-3 mt-2 text-center">
              <div class="font-weight-bold fontsize" >
                Feature Number
              </div>
              <div class="fontsize">{{claimDetails.featureNumber}}</div>
            </v-layout>
          </v-flex>
          <v-flex  class="ClaimGroup"  >
            <v-layout column class="ml-3 mt-2 text-center">
              <div class="font-weight-bold fontsize">
                Claim Status
              </div>
              <div class="fontsize" >{{claimDetails.claimStatus}}</div>
            </v-layout>
          </v-flex>
          <v-flex  class="ClaimGroup text-center">
            <v-layout column class="ml-3 mt-2">
              <div class="font-weight-bold fontsize">
                Reason for Suspense
              </div>
              <div class="fontsize">{{claimDetails.reasonForSuspense}}</div>
            </v-layout>
          </v-flex>
          </v-layout>
      </v-container>

      <v-layout class="mt-1">
         <v-flex xs3  v-if="disableClaimNumber==false">
            <v-layout column class="ml-3">
               <p class="claimlabel mb-2"> Claim Number<span class="mandatorycolor"> *</span></p>
              <v-autocomplete                  
                  v-model="claimDetails.mainClaimNumber"  
                  :rules="[mandatoryrule()]"
                  :items="allClaimsNumberArray"    
                  selection
                  :search-input.sync="SearchAllClaimNumbers"
                  :hide-no-data="true" 
                  item-text="mainClaimNumber"
                  item-value="mainClaimNumber"
                  index="_id"
                  class="forminput"    
                  id="claimnumber"
                  placeholder="Start typing to Search"                   
                  solo 
                  dense 
                  maxlength="10"
                  @change="getSingleClaimDeatils(claimDetails.mainClaimNumber)"
                  :disabled="disableClaimNumber" deletable-chips           
                  clearable>
                 
              ></v-autocomplete>
            </v-layout>
          </v-flex>
        <v-flex xs3>

          <!-- <span v-if="claimDetails.claimType!=='Feature Claim'">(Preferred mode of registration)</span> (Preferred mode of registration)-->
          <v-layout column class="ml-3">
            <p class="claimlabel mb-2">Policy Number<span v-if="policynotrue" class="mandatorycolor"> *</span></p>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-text-field
                  id="policynumber"
                  v-model="claimDetails.policyNumber"
                  placeholder="Enter..."
                  :disabled="disableOnCSCT || disablePolicyField"
                  :class="disableOnCSCT || disablePolicyField == true ? 'font-weight-bold':'font-weight-regular'"
                  dense
                  solo
                  v-on="on"
                  @change="checkPolicyNumber"
                  :rules="[policyrule(claimDetails.policyNumber)]"
                  class="forminput textDisable"
                  :maxlength="max15"
                ></v-text-field>
              </template>
              <span v-if="claimDetails.claimType !== 'Feature Claim'">Preferred mode of registration</span>
            </v-tooltip>
          </v-layout>
        </v-flex>

        <v-flex xs3>
            <v-layout column class="ml-3">
              <p class="claimlabel mb-2">Date of Loss <span class="mandatorycolor"> *</span></p>
                   <v-menu
                        v-model="dateOfLossmenu"
                        :close-on-content-click="false"
                        offset-y
                        min-width="auto">
                        <template v-slot:activator="{ on ,attrs}">
                          <v-text-field
                            :value="computedDateofloss"
                            placeholder="DD/MM/YYYY"
                            dense
                            solo
                            readonly
                            v-on="on"
                            @focus="onFocus"
                            @blur="onFocus"
                            v-bind="attrs"
                            :disabled="disableOnCSCT"
                            :class="disableOnCSCT == true ? 'font-weight-bold':'font-weight-regular'"
                            :rules='rules.dateofLossRules'
                            class="forminput textDisable"
                            id="lossTime"
                            append-icon="mdi-menu-down">
                            <template v-slot:prepend-inner class="mx-0 my-0">
                              <v-icon class="iconstyle" >mdi-calendar</v-icon>
                            </template>
                          </v-text-field>
                        </template>
                        <v-date-picker
                          :max="todaydatevalue"
                          no-title 
                          @change="toDisableTime()"
                          v-model="dateOfLoss"
                          @input="dateOfLossmenu = false">
                        </v-date-picker>
                  </v-menu> 
           </v-layout>
        </v-flex>

        <!-- @keyup="tocheckLossTime"-->
        <v-flex xs3>
          <v-layout column class="ml-3">
            <p class="claimlabel mb-2">
              Loss Time <span class="mandatorycolor"> *</span>
            </p>
            <v-menu
              ref="lossTimemenu"
              v-model="lossTimemenu"
              :close-on-content-click="false"
              :return-value.sync="lossTime"
              offset-y
              readonly
              max-width="290px"
              min-width="290px">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="claimDetails.lossTime"
                  placeholder="HH:MM"
                  v-bind="attrs"
                  solo
                  dense
                  readonly
                  v-on="on"
                  @focus="onLosstimeFocus"
                  :disabled="disableOnCSCT"
                  :rules="[mandatoryrule()]"
                  class="forminput textDisable"
                  :class="disableOnCSCT == true ? 'font-weight-bold':'font-weight-regular'"
                  append-icon="mdi-menu-down">
                  <template v-slot:prepend-inner class="mx-0">
                    <v-icon class="iconstyle">mdi-clock</v-icon>
                  </template>
                </v-text-field>
              </template>
              <v-time-picker
                v-if="lossTimemenu"
                v-model="claimDetails.lossTime"
                full-width
                no-title
                @change="tocheckLossTime()"
                :max="currentTime"
                @click:minute="$refs.lossTimemenu.save(claimDetails.lossTime)"
                format="24hr"
              ></v-time-picker>
            </v-menu>
          </v-layout>
        </v-flex>

        <v-flex xs3 class="mt-0">
          <v-layout class="ml-3">
            <v-checkbox
              v-model="claimDetails.withoutpolicyno"
              label="Without Policy Number"
              class="pl-2 withoutpolicycheck"
              dense
              @change="withoutPolicyno()"
              :disabled="disableOnCSCT"
              color="#23B1A9">
            </v-checkbox>
          </v-layout>
        </v-flex>
          <v-flex xs3> </v-flex>
      </v-layout>
      <!-- main Deails End hear -->

      <!-- policy buttons Start hear -->
      <v-flex justify="end" class="btnsdiv mt-2" v-if="claimDetails.claimType =='New Claim' || claimDetails.claimType =='Feature Claim'">
        <v-row>
          <v-col align="right" class="mt-5">
            <v-btn
              color="#152F38"
              class="buttonscolor mr-5"
              :disabled="toDisableolicyFetchData"
              @click.prevent="openPolicyData()">
              <v-icon class="mr-2 iconfontsize" > mdi-sync </v-icon>
              <div class="vertical_line"></div>Policy Fetch Data
            </v-btn>

            <v-btn
              color="#E46A25"
              class="buttonscolor mr-5"
              :disabled="toDisablePolicySearch"
              @click.prevent="openPolicySearch()">
              <v-img
                class="mr-2"
                src="../../assets/images/psearch.png"
                height="20"
                width="20"
                >
              </v-img>
              <div class="vertical_line"></div>Policy Search
            </v-btn>

            <v-btn
              color="#23B1A9"
              class="buttonscolor mr-5"
              :disabled="policyDeatilsDisable"
              @click.prevent="openPolicyDetails()">
              <v-img
                class="mr-2"
                src="../../assets/images/policy.png"
                height="20"
                width="20"
                >
              </v-img>
              <div class="vertical_line"></div>Policy Details
            </v-btn>
          </v-col>
        </v-row>
      </v-flex>

      <!-- Policy Details Group start -->
      <v-flex class="mb-5">
        <v-row class="groupRowHead mt-0">
          <v-col align="left" class="mt-0">
            <v-flex class="ml-2 GroupName">Policy Details</v-flex>
          </v-col>
          <v-col align="right" class="pt-2 mt-0">
            <v-icon
              class="mr-2 viconfont"
             
              dark
              @click="policyDetailsMenu = !policyDetailsMenu">
              {{ policyDetailsMenu == true ? "mdi-minus-box" : "mdi-plus-box" }}
            </v-icon>
          </v-col>
        </v-row>
      </v-flex>
      <v-flex v-if="policyDetailsMenu" class="mt-5 layoutBorder">
        <!-- Fields for service claim Policy Inception Date -->
        <v-layout v-if="showHideServiceClaimFields">
          <v-flex xs3>
              <v-layout column class="ml-3">
                <p class="claimlabel mb-2">Policy Inception Date<span class="mandatorycolor"> *</span></p>
                <v-menu
                  v-model="incepDatemenu"
                  :close-on-content-click="false"
                  offset-y
                  readonly
                  min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      placeholder="DD/MM/YYYY"
                      dense
                      solo
                      v-on="on"
                      @focus="onIncepDate"
                      :rules="[mandatoryrule()]"
                      class="forminput"
                      v-bind='attrs'
                      v-model="computedInceptionDate"
                      readonly
                      id="lossTime"
                      append-icon="mdi-menu-down">
                      <template v-slot:prepend-inner class="mx-0 my-0">
                        <v-icon class="iconstyle">mdi-calendar</v-icon>
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker
                    no-title
                    v-model="inceptionDate"
                    @change="toDisableInceptionDate()"
                    @input="incepDatemenu = false">
                  </v-date-picker>
                </v-menu>
              </v-layout>
          </v-flex>

          <v-flex xs3>
              <v-layout column class="ml-3">
                <p class="claimlabel mb-2">Policy Expiry Date<span class="mandatorycolor"> *</span></p>
                <v-menu
                  v-model="expiryDatemenu"
                  :close-on-content-click="false"
                  offset-y
                  readonly
                  min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      placeholder="DD/MM/YYYY"
                      dense
                      solo
                      v-on="on"
                      v-bind="attrs"
                      @focus="onExpiryDate"
                      :rules="rules.expiryDateRule"
                      v-model="computedExpiryDate"
                      class="forminput"
                      readonly
                      id="lossTime"
                      append-icon="mdi-menu-down">
                      <template v-slot:prepend-inner class="mx-0 my-0">
                        <v-icon class="iconstyle">mdi-calendar</v-icon>
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker
                    :min='disableInceptionDate'
                    no-title
                    v-model="expiryDate"
                    @change="toValidateDOL()"
                    @input="expiryDatemenu = false">
                  </v-date-picker>
                </v-menu>
              </v-layout>
          </v-flex>

          <v-flex xs3 class="">
            <v-layout column class="ml-3">
              <p class="claimlabel mb-2">
                Policy Issuing Company<span class="mandatorycolor"> *</span>
              </p>
              <v-autocomplete                  
                :search-input.sync="searchCompany"               
                class="forminput autostyle"                                               
                placeholder="Start typing to Search"
                :rules="[mandatoryrule()]"                         
                solo 
                :items='policyCompanyDetails'
                @change="toChangeCountry()"
                item-text='policyCompany'
                dense 
               v-model="claimDetails.policyIssuingCompany"
                @focus="onPolicyCompany"
                deletable-chips           
                clearable
              ></v-autocomplete>
            </v-layout>
          </v-flex>

          <v-flex xs3 class="">
            <v-layout column class="ml-3">
              <p class="claimlabel mb-2">
                Policy Issuing Country<span class="mandatorycolor"> *</span>
              </p>
              <v-text-field
                label=""
                placeholder=""
                solo
                dense
                v-model="claimDetails.policyIssuingCountry"
                :rules="[mandatoryrule()]"
                class="forminput">
              </v-text-field>
            </v-layout>
          </v-flex>         

          <v-flex xs3>
          </v-flex>

        </v-layout>

        <!-- Insured Name -->
        <v-layout class="mt-3">
          <v-flex xs3 class="">
            <v-layout column class="ml-3">
              <p class="claimlabel mb-2">Insured Name<span class="mandatorycolor"> *</span></p>
              <v-text-field
                placeholder="Enter..."
                :rules="rules.insuredName"
                :disabled="disableOnCSCT || isPolicyLoaded"
                :class="disableOnCSCT || isPolicyLoaded == true ? 'font-weight-bold':'font-weight-regular'"
                :maxLength="140"
                solo
                dense
                v-model="claimDetails.insuredName"
                class="forminput insuredname textDisable"
              ></v-text-field>
            </v-layout>
          </v-flex>

          <!-- <v-flex xs3>
            <v-layout column class="ml-3">
              <p class="claimlabel mb-2">
                Insured ID
                <span v-if="showinsuredID" class="mandatorycolor"> *</span>
              </p>
              <v-text-field
                v-model="claimDetails.insuredID"
                label=""
                placeholder="Enter..."
                :rules="[insuredIDRules()]"
                :maxLength="100"
                :disabled="disableOnCSCT"
                solo
                dense
                class="forminput"
              ></v-text-field>
            </v-layout>
          </v-flex> -->
          <v-flex xs3 v-if="checkTypeofClaim">
            <v-layout column class="ml-3" >
              <p class="mb-2 claimlabel">E-Marine Certificate</p>
              <v-text-field
                placeholder="Enter..."
                dense
                solo
                :maxLength="20"
                v-model="claimDetails.eMarinePolicyNumber"
                :disabled="enableOnFeatureClaim"
                :class="disableOnCS || isPolicyLoaded == true ? 'font-weight-bold':'font-weight-regular'"
                class="forminput textDisable"
              ></v-text-field>
            </v-layout>
          </v-flex> 

          <v-flex xs3 v-if="showHideServiceClaimFields">
              <v-layout column class="ml-3">
                <p class="mb-2 claimlabel">Certificate Number<span v-if="certificateNo" class="mandatorycolor"> *</span></p>
              <v-text-field
                placeholder="Enter..."
                solo
                dense
                :rules='[toMakeCetificateNoMandate(), alphaNumeric(claimDetails.certificateNumber)]'
                :maxLength="20"
                class="forminput textDisable"
                v-model="claimDetails.certificateNumber"
                :disabled='disableOnFeatureClaim || isPolicyLoaded'
                 :class="disableOnFeatureClaim || isPolicyLoaded== true ? 'font-weight-bold':'font-weight-regular'"
              ></v-text-field>
              </v-layout>
          </v-flex>

          <v-flex xs3>
            <v-layout column class="ml-3 mr-3">
              <p class="mb-2 claimlabel">Product Name <span class="mandatorycolor"> *</span></p>
              <v-select
                label="Select"
                solo
                @change="updateCoverageOptions"
                dense
                class="forminput textDisable"
                :rules="[mandatoryrule()]"
                :items="productnames"
                item-text="productName"
                v-model="claimDetails.productName"
                :disabled="disableOnCSCT || productName || isPolicyLoaded"
                :class="disableOnCSCT || productName || isPolicyLoaded == true ? 'font-weight-bold':'font-weight-regular'"
                clearable>
              </v-select>
            </v-layout>
          </v-flex>
          <v-flex xs3>
            <v-layout column class="ml-3">
              <p class="mb-2 claimlabel">LOB <span class="mandatorycolor"> *</span></p>
              <v-select
                label="Select"
                solo
                dense
                class="forminput textDisable"
                :items="LOB"
                v-model="claimDetails.lob"
                :rules="[mandatoryrule()]"
                :disabled="disableOnCSCT || isPolicyLoaded"
                :class="disableOnCSCT || isPolicyLoaded == true ? 'font-weight-bold':'font-weight-regular'"
                clearable
              ></v-select>
            </v-layout>
          </v-flex>
          <v-flex xs3> </v-flex>
        </v-layout>     
      </v-flex>

      <!-- Claim Intimation Details Group start -->
      <v-flex class="mb-5">
        <v-row class="groupRowHead mt-0">
          <v-col align="left" class="mt-0">
            <v-flex class="ml-2 GroupName">Claim Intimation Details</v-flex>
          </v-col>
          <v-col align="right" class="pt-2 mt-0">
            <v-icon
              class="mr-2 viconfont"
              
              dark
              @click="claimIntimationDetailsMenu = !claimIntimationDetailsMenu"
            >
              {{
                claimIntimationDetailsMenu == true
                  ? "mdi-minus-box"
                  : "mdi-plus-box"
              }}
            </v-icon>
          </v-col>
        </v-row>
      </v-flex>

      <v-flex v-if="claimIntimationDetailsMenu" class="mt-5 layoutBorder">
        <!-- this field is disabled  MC-873 -->
        <v-layout class="mt-3">
          <v-flex xs3>
            <v-layout column class="ml-3">
              <p class="mb-2 claimlabel">Claim Category <span class="mandatorycolor"> *</span></p>
              <v-select
                label="Select"
                solo
                dense
                @change="changeAIG"
                :items="claimCategory"
                :rules="[mandatoryrule()]"
                class="forminput textDisable"
                :disabled="disableOnOpen"
                :class="disableOnOpen == true ? 'font-weight-bold':'font-weight-regular'"
                v-model="claimDetails.claimCategory">
              </v-select>
            </v-layout>
          </v-flex>
          <v-flex xs3>
            <v-layout column class="ml-3">
              <p class="mb-2 claimlabel">Type of Claim <span class="mandatorycolor"> *</span></p>
              <v-select
                label="Select"
                solo
                dense
                :items="typeOfClaim"
                @change="toMakeStoragemandatory"
                :rules="[mandatoryrule()]"
                class="forminput textDisable"
                :disabled="disableOnCS"
                 :class="disableOnCS == true ? 'font-weight-bold':'font-weight-regular'"
                v-model="claimDetails.typeOfClaim"
                clearable
              >
              </v-select>
            </v-layout>
          </v-flex>
          <v-flex xs3>
            <v-layout class="ml-3" column>
              <p class="mb-2 claimlabel">LR/AWB/BL Details <span class="mandatorycolor" v-if="typeofClaimCheck"> *</span></p>
              <v-text-field
                placeholder="Add Details..."
                solo
                readonly
                v-model="LRAWSBLDetailsValidation"
                :rules="[mandatoryruleLRTable()]"
                dense
                class="forminput">
                <template v-slot:append class="my-0 mr-2">
                  <v-icon
                    class="blueiconstyle"
                    @click="LRDetailsTableMenu = true">mdi-plus</v-icon>
                </template>
              </v-text-field>
            </v-layout>
          </v-flex>
          <v-flex xs3>
            <v-layout column class="ml-3">
              <p class="mb-2 claimlabel">Customer Claim Number</p>
              <v-text-field
                placeholder="Enter..."
                solo
                dense
                :maxLength="30"
                v-model="claimDetails.customerClaimNumber"
                :disabled="disableOnClosed || disableOnFeatureClaim"
                class="forminput textDisable"
                :class="disableOnClosed || disableOnFeatureClaim == true ? 'font-weight-bold':'font-weight-regular'"
              ></v-text-field>
            </v-layout>
          </v-flex>
          
          <!-- <v-flex xs3>
            <v-layout column class="ml-3 mr-3">
              <p class="mb-2 claimlabel">Reported Date & Time</p>
              <v-text-field
                solo
                dense
                placeholder="DD/MM/YYYY HH:MM"
                disabled
                
                v-model="ShowreportedDateTime"
                class="forminput textDisable font-weight-bold">
                <template v-slot:prepend-inner class="mx-0 my-0">
                  <v-icon class="iconstyle">mdi-calendar</v-icon>
                </template>
              </v-text-field>
            </v-layout>
          </v-flex> -->
          
          <v-flex xs3>
            <v-layout column class="ml-3 mr-3">
              <p class="claimlabel mb-2">Reported Date<span class="mandatorycolor"> *</span></p>
                  <!-- :disabled="disableOnCSCT || disableUntillLossDateSelected" -->
                   <v-menu
                        v-model="reportedDatemenu"
                        :close-on-content-click="false"
                        offset-y
                        min-width="auto">
                        <template v-slot:activator="{ on ,attrs}">
                          <v-text-field
                            :value="computedreportedDate"
                            placeholder="DD/MM/YYYY"
                            dense
                            solo
                            readonly
                            v-on="on"
                            @focus="onFocusReportedDate"
                            @blur="onFocusReportedDate"
                            v-bind="attrs"
                            :disabled="enableFeatureClaimRPDateTime"
                            :class="enableFeatureClaimRPDateTime == true ? 'font-weight-bold':'font-weight-regular'"
                            :rules='rules.reportedDateRules'
                            class="forminput textDisable"
                            id="lossTime"
                            append-icon="mdi-menu-down">
                            <template v-slot:prepend-inner class="mx-0 my-0">
                              <v-icon class="iconstyle" >mdi-calendar</v-icon>
                            </template>
                          </v-text-field>
                        </template>
                        <v-date-picker              
                          :max="maxOnDateOfLoss" 
                          :min="minOnDateOfLoss"          
                          no-title             
                           @change="toDisableReportedTime()"             
                          v-model="reportedDate"
                          @input="reportedDatemenu = false">
                        </v-date-picker>
                  </v-menu> 
           </v-layout>
        </v-flex>
         <!-- CR-744:--D0014308-The reported date should be system date, but also to be kept editable. It should allow to be backdated only. It should not accept future dates. -->
      </v-layout>

        <!-- Date Difference -->
        <v-layout class="mt-2">
          
           <v-flex xs3>
          <v-layout column class="ml-3">
              <!-- :rules="[mandatoryrule()]" -->
            <p class="claimlabel mb-2">
              Reported Time <span class="mandatorycolor" v-if="checkProductname"> *</span>
            </p>
            <v-menu
              ref="reportedTimemenu"
              v-model="reportedTimemenu"
              :close-on-content-click="false"
              :return-value.sync="reportedTime"
              offset-y
              readonly
              max-width="290px"
              min-width="290px">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="claimDetails.reportedTime"
                  placeholder="HH:MM"
                  v-bind="attrs"
                  solo
                  dense
                  readonly
                  v-on="on"
                  @focus="onReportedTimeFocus"
                  :disabled="enableFeatureClaimRPDateTime"
                   :class="enableFeatureClaimRPDateTime == true ? 'font-weight-bold':'font-weight-regular'"
                  :rules="[tocheckMandatory()]"
                  class="forminput textDisable"
                  append-icon="mdi-menu-down">
                  <template v-slot:prepend-inner class="mx-0">
                    <v-icon class="iconstyle">mdi-clock</v-icon>
                  </template>
                </v-text-field>
              </template>
              <v-time-picker
                v-if="reportedTimemenu"
                v-model="claimDetails.reportedTime"
                full-width
                no-title               
                :max="currentTime"
                @click:minute="$refs.reportedTimemenu.save(claimDetails.reportedTime)"
                format="24hr"
              ></v-time-picker>
            </v-menu>
          </v-layout>
        </v-flex>
        <!-- CR-744:--D0014308-The reported date should be system date, but also to be kept editable. It should allow to be backdated only. It should not accept future dates. -->
<!--  @change="tocheckLossTime()" -->

          <v-flex xs3>
            <v-layout column class="ml-3">
              <p class="mb-2 claimlabel">Date Difference</p>
              <v-text-field
                placeholder="Enter..."
                solo
                dense
                disabled
                v-model="claimDetails.dateDifference"
                class="forminput textDisable font-weight-bold">
                <template v-slot:prepend-inner class="mx-0 my-0">
                  <v-icon class="iconstyle" width="15" height="15">mdi-calendar</v-icon>
                </template>
              </v-text-field>
            </v-layout>
          </v-flex>

          <v-flex xs3>
            
            <v-layout class="ml-3" column>
              
              <p class="mb-2 claimlabel" >Estimated Loss Amount in INR<span class="mandatorycolor"> *</span></p>
                 <!--style="width: 146%" <span style="color: #646666;
                  font-size: 10px;
                  font-weight: bold;">(No bearing on final claim amount)</span> -->
                 <v-tooltip bottom color="grey">
                  <template v-slot:activator="{ on }">
                  
                    <v-text-field
                      placeholder="Enter..."
                      solo
                      dense
                      v-on="on"
                      :rules="[mandatoryrule(),numeric(claimDetails.estimatedLossAmountInINR),]"
                      :maxLength="10"
                      v-model="claimDetails.estimatedLossAmountInINR"
                      class="forminput textDisable"
                      :class="disableOnClosed == true ? 'font-weight-bold':'font-weight-regular'"
                      :disabled="disableOnClosed">
                      <template v-slot:prepend-inner class="mx-0 my-0">
                        <v-icon class="iconstyle" width="15" height="15">mdi-currency-inr</v-icon>
                      </template>
                       <template v-slot:append class="my-0 mr-2">
                        <v-icon
                          class="blueiconstyle"
                          @click="showAlrt()">mdi-information-outline</v-icon>
                      </template>
                    </v-text-field>
                  </template>
                  <span class="nobearingamt"  >No bearing on final claim amount</span>
                 </v-tooltip>
            </v-layout>
           
          </v-flex>
        <v-flex xs3></v-flex>
        <v-flex xs3></v-flex>
        <!-- <v-flex xs3></v-flex> -->
         
        </v-layout>
      </v-flex>
      <!-- Caller/Insured contact details Group Satrt -->
      <v-flex class="mb-5">
        <v-row class="groupRowHead mt-0">
          <v-col align="left" class="mt-0">
            <v-flex class="ml-2 GroupName">Caller/Insured Contact Details </v-flex>
          </v-col>
          <v-col align="right" class="pt-2 mt-0">
            <v-icon
              class="mr-2 viconfont"
              
              dark
              @click="InsuredcontactDetailsMenu = !InsuredcontactDetailsMenu"
            >
              {{
                InsuredcontactDetailsMenu == true
                  ? "mdi-minus-box"
                  : "mdi-plus-box"
              }}
            </v-icon>
          </v-col>
        </v-row>
      </v-flex>

      <v-flex v-if="InsuredcontactDetailsMenu" class="mt-5 layoutBorder">
            <!-- Caller/Intimator Identification button line -->
        <v-layout class="mt-2">
          <v-flex xs3>
            <v-layout column class="ml-3">
              <p class="mb-2 claimlabel">Caller/Intimator Identification<span class="mandatorycolor"> *</span></p>
              <v-select
                label="Select"
                dense
                solo
                @change="changeName()"
                :disabled="disableOnCSCT"
                :class="disableOnCSCT == true ? 'font-weight-bold':'font-weight-regular'"
                :rules="[mandatoryrule()]"
                :items="callerIntimatorIdentification"
                v-model="claimDetails.callerIntimatorIdentification"
                class="forminput textDisable"
                clearable
                ></v-select>
            </v-layout>
          </v-flex>

          <v-flex xs3>
            <v-layout column class="ml-3">
              <p class="mb-2 claimlabel">Claim Intimated Through <span class="mandatorycolor"> *</span></p>
              <v-select
                label="Select"
                dense
                solo
                @change="checkCaller"
                :rules="[mandatoryrule()]"
                :disabled="disableOnCSCT"
                :class="disableOnCSCT == true ? 'font-weight-bold':'font-weight-regular'"
                :items="claimIntimatedThrough"
                v-model="claimDetails.claimIntimatedThrough"
                class="forminput textDisable"
                clearable></v-select>
            </v-layout>
          </v-flex>

          <v-flex xs3>
            <v-layout column class="ml-3">
              <p class="mb-2 claimlabel">Caller Name<span v-if="callername" class="mandatorycolor"> *</span></p>
              <v-text-field
                placeholder="Enter..."
                solo
                dense
                class="forminput textDisable"
                :maxLength="140"
                :disabled="disableOnCSCT"
                 :class="disableOnCSCT == true ? 'font-weight-bold':'font-weight-regular'"
                v-model="claimDetails.callerName"
                :rules="[checkCaller(), alphaNumeric(claimDetails.callerName)]"
              ></v-text-field>
            </v-layout>
          </v-flex>

          <v-flex xs3>
            <v-layout column class="ml-3">
              <p class="mb-2 claimlabel">Caller relationship with insured<span v-if="callertrue" class="mandatorycolor"> *</span></p>
              <v-select
                label="Select"
                dense
                solo
                :rules="[checkCallerIntimator(claimDetails.callerRelationshipwithInsured),]"
                @change="tochangeRelationship()"
                :items="callerRelationshipwithInsured"
                disabled
                v-model="claimDetails.callerRelationshipwithInsured"
                class="forminput textDisable font-weight-bold"
                clearable>
              </v-select>
            </v-layout>
          </v-flex>

          <v-flex xs3>
            <v-layout column class="ml-3 mr-3">
              <p class="mb-2 claimlabel">Please Specify<span v-if="otherelation" class="mandatorycolor"> *</span></p>
              <v-text-field
                placeholder="Enter..."
                solo
                dense
                class="forminput textDisable"
                :maxLength="50"
                :rules="[pleaseSpecify()]"
                :disabled="disableOnCS || disableOnFeatureClaim || checkRelation"
                :class="disableOnCS || disableOnFeatureClaim || checkRelation == true ? 'font-weight-bold':'font-weight-regular'"
                v-model="claimDetails.pleaseSpecify">
              </v-text-field>
            </v-layout>
          </v-flex>
        </v-layout>

            <!--  Caller Email ID button line -->
        <v-layout class="mt-2">
          <v-flex xs3>
            <v-layout column class="ml-3">
              <p class="mb-2 claimlabel">Caller Email ID<span v-if="callerEmailMandate" class="mandatorycolor"> *</span></p>
              <v-text-field
                placeholder="Enter..."
                solo
                dense
                v-model="callerEmailID"
                :rules="[callermultiplemailIds(callerEmailID)]"
                :maxLength="50"
                @keyup="addCallerEmailIDs(callerEmailID)"
                :disabled="disableOnCSCT"
                :class="disableOnCSCT == true ? 'font-weight-bold':'font-weight-regular'"
                class="forminput textDisable">
                <template v-slot:append class="my-0 mr-2">
                  <v-icon class="blueiconstyle" @click="opencallerEmaildialog()">mdi-email-plus</v-icon>
                </template>
              </v-text-field>
            </v-layout>
          </v-flex>

          <v-flex xs3>
            <v-layout column class="ml-3">
              <p class="mb-2 claimlabel">Insured Email ID</p>
              <v-text-field
                placeholder="Enter..."
                solo
                dense
                :rules="[email(claimDetails.insuredEmailID)]"
                :maxLength="50"
                :disabled="disableOnClosed || disableOnFeatureClaim"
                :class="disableOnClosed || disableOnFeatureClaim == true ? 'font-weight-bold':'font-weight-regular'"
                v-model="claimDetails.insuredEmailID"
                class="forminput textDisable">
                <template v-slot:prepend-inner class="mx-0 my-0">
                <v-icon class="iconstyle">mdi-email</v-icon>
                </template>
              </v-text-field>
          </v-layout>
          </v-flex>

          <v-flex xs3>
            <v-layout column class="ml-3">
              <p class="mb-2 claimlabel">E-mail ID for Communication</p>
              <v-text-field
                placeholder="Enter..."
                solo
                dense
                :rules="[multiplemailIds(emailIdForCommunication)]"
                :maxLength="50"
                v-model="emailIdForCommunication"
                @keyup="addEmailIDs(emailIdForCommunication)"
                class="forminput textDisable"
                :class="disableOnClosed == true ? 'font-weight-bold':'font-weight-regular'"
                :disabled="disableOnClosed">
                <template v-slot:append class="my-0 mr-2">
                  <v-icon class="blueiconstyle" @click="openEmailDialog()">mdi-email-plus</v-icon>
                </template>
              </v-text-field>
            </v-layout>
          </v-flex>

          <v-flex xs1>
            <v-layout column class="ml-3">
              <p class="mb-2 claimlabel">ISD Code<span v-if="callermobile" class="mandatorycolor"> *</span></p>
              <v-text-field
                placeholder="Enter..."
                :maxLength="5"
                solo
                dense
                v-model="claimDetails.callerMobileNo.isd"
                :rules="[checkCallerMobileIsd(claimDetails.callerMobileNo.isd)]"
                :disabled="disableOnCSCT"
                 :class="disableOnCSCT == true ? 'font-weight-bold':'font-weight-regular'"
                class="forminput textDisable">
              <template v-slot:prepend-inner class="mx-0 my-0">
                <v-icon class="iconstyle">mdi-phone</v-icon>
              </template>
              </v-text-field>
            </v-layout>
          </v-flex>

          <v-flex xs2>
            <v-layout column class="ml-3">
              <p class="mb-2 claimlabel">Caller Mobile Number<span v-if="callermobile" class="mandatorycolor"> *</span></p>
              <v-text-field
                placeholder="Enter..."
                solo
                dense
                :rules="[Numeric2(claimDetails.callerMobileNo.number)]"
                :maxLength="10"
                v-model="claimDetails.callerMobileNo.number"
                :disabled="disableOnCSCT"
                :class="disableOnCSCT == true ? 'font-weight-bold':'font-weight-regular'"
                class="forminput textDisable">
              </v-text-field>
            </v-layout>
          </v-flex>

          <v-flex xs1>
            <v-layout column class="ml-3">
              <p class="mb-2 claimlabel">ISD Code<span class="mandatorycolor"> *</span></p>
              <v-text-field
                placeholder="Enter..."
                :maxLength="5"
                solo
                dense
                :rules="[mandatoryrule(), validateIsd(insuredMobileISD)]"
                v-model="insuredMobileISD"
                :disabled="disableOnClosed || disableOnFeatureClaim"
                :class="disableOnClosed || disableOnFeatureClaim == true ? 'font-weight-bold':'font-weight-regular'"
                @change="addInsuredMobileno(insuredMobileISD, insuredMobileNumber)"
                class="forminput textDisable">
                <template v-slot:prepend-inner class="mx-0 my-0">
                  <v-icon class="iconstyle">mdi-phone</v-icon>
                </template>
              </v-text-field>
            </v-layout>
          </v-flex>

          <v-flex xs2>
            <v-layout column class="ml-3 mr-3">
              <p class="mb-2 claimlabel">Insured Mobile Number <span class="mandatorycolor"> *</span></p>
              <!-- <template v-slot:activator="{ on, attrs }">  -->
              <v-text-field
                placeholder="Enter..."
                solo
                dense
                :disabled="disableOnClosed || disableOnFeatureClaim"
                :class="disableOnClosed || disableOnFeatureClaim == true ? 'font-weight-bold':'font-weight-regular'"
                :rules="[multiplemobilenos(insuredMobileNumber)]"
                :maxLength="10"
                v-model="insuredMobileNumber"
                @change="addInsuredMobileno(insuredMobileISD, insuredMobileNumber)"
                class="forminput textDisable">
                <template v-slot:append class="my-0 mr-2">
                  <v-icon class="blueiconstyle" @click="openMobileDialog()">mdi-phone-plus</v-icon>
                </template>
              </v-text-field>
              <!-- </template> -->
            </v-layout>
          </v-flex>
        </v-layout>

          <!--  Name of Contact Person at LossLocation bottomline-->
        <v-layout class="mt-2">
          <v-flex xs3>
            <v-layout class="ml-3" column>
              <p class="mb-2 claimlabel">Name of Contact Person at Loss Location<span class="mandatorycolor"> *</span></p>
              <v-text-field
                placeholder="Enter..."
                solo
                dense
                :rules="[
                mandatoryrule(),
                AlphaChar(claimDetails.nameOfContactPersonAtLossLocation),
                ]"
                :maxLength="50"
                v-model="claimDetails.nameOfContactPersonAtLossLocation"
                class="forminput textDisable"
                :disabled="disableOnClosed"
                :class="disableOnClosed == true ? 'font-weight-bold':'font-weight-regular'"
              ></v-text-field>
            </v-layout>
          </v-flex>

          <v-flex xs1>
            <v-layout column class="ml-2">
              <p class="mb-2 claimlabel">ISD Code<span v-if="productrue" class="mandatorycolor"> *</span></p>
              <v-text-field
                placeholder="Enter..."
                :maxLength="5"
                solo
                dense
                :rules="[
                tocheckMandatory(),
                validateIsd(claimDetails.contactNumberAtLossLocation.isd),
                ]"
                v-model="claimDetails.contactNumberAtLossLocation.isd"
                :disabled="disableOnClosed"
                :class="disableOnClosed == true ? 'font-weight-bold':'font-weight-regular'"
                class="forminput textDisable">
                <template v-slot:prepend-inner class="mx-0 my-0">
                  <v-icon class="iconstyle">mdi-phone</v-icon>
                </template>
              </v-text-field>
            </v-layout>
          </v-flex>

          <v-flex xs2>
            <v-layout column class="ml-3">
              <p class="mb-2 claimlabel">Contact Number at Loss Location<span v-if="productrue" class="mandatorycolor"> *</span></p>
                <v-text-field
                 
                  placeholder="Enter..."
                  solo
                  dense
                  :rules="[
                  tocheckMandatory(),
                  numeric(claimDetails.contactNumberAtLossLocation.number),
                  checkMobileNoLength(claimDetails.contactNumberAtLossLocation.number),]"
                  :maxLength="10"
                  v-model="claimDetails.contactNumberAtLossLocation.number"
                  class="forminput losslocationwidth textDisable"
                  :class="disableOnClosed == true ? 'font-weight-bold':'font-weight-regular'"
                  :disabled="disableOnClosed">
                  <template v-slot:prepend-inner class="mx-0 my-0">
                    <v-icon class="iconstyle" width="15" height="15">mdi-phone</v-icon>
                  </template>
                </v-text-field>
              </v-layout> 
          </v-flex>
          
          <v-flex xs3>
            <v-layout column class="ml-3">
              <p class="mb-2 claimlabel">Is Insured The Claimant? <span class="mandatorycolor"> *</span></p>
              <v-select
                label="Select"
                solo
                dense
                :items="isInsuredTheClaimant"
                item-text="text"
                @change="checkInsured()"
                v-model="claimDetails.isInsuredTheClaimant"
                :disabled="disableOnClosed || disableOnFeatureClaim"
                :class="disableOnClosed || disableOnFeatureClaim == true ? 'font-weight-bold':'font-weight-regular'"
                class="forminput textDisable"
                clearable>
              </v-select>
            </v-layout>
          </v-flex>

          <v-flex xs3>
            <v-layout column class="ml-3">
              <p class="mb-2 claimlabel">Claimant Name <span class="mandatorycolor"> *</span></p>
              <v-text-field
                placeholder="Enter..."
                solo
                dense
                :maxLength="100"
                :rules="[mandatoryrule(),checkblankspaces(claimDetails.claimant.name)]"
                v-model="claimDetails.claimant.name"
                :disabled="disableOnClosed || disableOnFeatureClaim"
                :class="disableOnClosed || disableOnFeatureClaim == true ? 'font-weight-bold':'font-weight-regular'"
                class="forminput textDisable"></v-text-field>
            </v-layout>
          </v-flex>

          <v-flex xs3>
            <v-layout class="ml-3 mr-3" column>
              <!-- :rules="[mandatoryruleAddressTable()]" -->
              <p class="mb-2 claimlabel">Address</p>
              <v-text-field
                placeholder="Add Address..."
                solo
                dense
                class="forminput"
                v-model="addressFieldValidation"
                readonly>
                <template v-slot:append class="my-0 mr-2">
                  <v-icon class="blueiconstyle" @click="openAddressDailogbox = true">mdi-plus</v-icon>
                </template>
              </v-text-field>
            </v-layout>
          </v-flex>
        </v-layout>

        <v-layout column class="ml-3">
          <v-checkbox
            label="Whether Insured/Claimant is Relative of TATA AIG Employee or a Vendor of Tata AIG"
            required
            class="pl-2 mr-3 tataemp"
            dense
            color="#23B1A9"
            v-model="claimDetails.isTataEmp"
            :disabled="disableOnCS"
           >
          </v-checkbox>
        </v-layout>
      </v-flex>

      <!-- Loss and Corresponding Coverage Details Group Satrt -->
      <v-flex class="mb-5">
        <v-row class="groupRowHead mt-0">
          <v-col align="left" class="mt-0">
            <v-flex class="ml-2 GroupName"
              >Loss and Corresponding Coverage Details</v-flex
            >
          </v-col>
          <v-col align="right" class="pt-2 mt-0">
            <v-icon
              class="mr-2 viconfont"
             
              dark
              @click="CorrespondingDetailsMenu = !CorrespondingDetailsMenu"
            >
              {{
                CorrespondingDetailsMenu == true
                  ? "mdi-minus-box"
                  : "mdi-plus-box"
              }}
            </v-icon>
          </v-col>
        </v-row>
      </v-flex>
      
      <v-flex v-if="CorrespondingDetailsMenu" class="mt-5 layoutBorder">
        <v-layout class="mt-3">
          <v-flex xs3>
            <v-layout class="ml-3" column>
              <p class="mb-2 claimlabel">
                Coverage Description<span class="mandatorycolor"> *</span>
              </p>
              <v-select
                label="Select"
                solo
                @click="openCoverageDes()"
                @change="toCoverageCode()"
                dense
                :items="coverageOptions"
                item-text="perilName"
                item-value="coverGroupName"
                :rules="[mandatoryrule()]"
                v-model="claimDetails.coverageDescription"
                :disabled="disableOnCS"
                :class="disableOnCS == true ? 'font-weight-bold':'font-weight-regular'"
                class="forminput textDisable">
              </v-select>
            </v-layout>
          </v-flex>

          <v-flex xs3>
            <v-layout class="ml-3" column>
              <p class="mb-2 claimlabel">
                Coverage Code<span class="mandatorycolor"> *</span>
              </p>
              <v-text-field
                placeholder="Enter..."
                solo
                dense
                disabled
                :rules="[mandatoryrule()]"
                :maxLength="250"
                v-model="claimDetails.coverageCode"
                class="forminput textDisable font-weight-bold"
              ></v-text-field>
            </v-layout>
          </v-flex>

          <v-flex xs3 v-if="checkProductname">
            <v-layout class="ml-3" column>
              <p class="mb-2 claimlabel">Storage Description
                <span class="mandatorycolor" v-if="newClaimStorage || storage"> *</span>
              </p>
              <v-text-field
                placeholder="Enter..."
                solo
                dense
                readonly
                v-model="claimDetails.storageDescription"
                class="forminput textDisable"
                :background-color="disableOnClosed || disableForTransitClaim == true ? '#f5f5f5':'#FFFFFF'"
                :disabled="disableOnClosed || disableForTransitClaim"
                :class="disableOnClosed || disableForTransitClaim == true ? 'font-weight-bold':'font-weight-regular'"
                :rules="[mandatoryOnCoverRule(),mandatoryStorage()]"
                @change="onStorageDescChange">
                <template v-slot:append class="my-0 mr-2"  v-if="claimDetails.claimType !== 'Service Claim'">
                  <v-icon class="blueiconstyle" @click="openStorageDes()">mdi-plus</v-icon>
                </template>
              </v-text-field>
            </v-layout>
          </v-flex>

          <v-flex xs3 v-if="checkProductname">
            <v-layout class="ml-3" column>
              <p class="mb-2 claimlabel">Storage Code
                <span class="mandatorycolor" v-if="storage || newClaimStorage"> *</span>
              </p>
              <v-text-field
                placeholder="Enter..."
                solo
                dense
                :rules="[storageCodeRule(),numeric(claimDetails.storageCode)]"
                v-model="claimDetails.storageCode"
                class="forminput textDisable"
                :background-color="disableOnCS || disableForTransitClaim || disableForStorageWithPolicy == true ? '#f5f5f5':'#FFFFFF'"
                :disabled="disableOnCS || disableForTransitClaim || disableForStorageWithPolicy"
                :class="disableOnCS == true ? 'font-weight-bold':'font-weight-regular'"
              ></v-text-field>
            </v-layout>
          </v-flex>

          <v-flex xs3 v-if="checkProductname">
            <v-layout class="ml-3 mr-3" column>
              <p class="mb-2 claimlabel">Unnamed Storage Location</p>
              <v-text-field
                placeholder="Enter..."
                solo
                dense
                v-model="claimDetails.unnamedStorageLocation"
                :disabled="disableOnCS || disableForTransitClaim"  
                :class="disableOnCS == true ? 'font-weight-bold':'font-weight-regular'"
                class="forminput textDisable">
              </v-text-field>
            </v-layout>
          </v-flex>
        </v-layout> <!-- disableForTransitClaim remove from disable -->

        <!-- Address of loss location -->
        <v-layout class="mt-2">
          <v-flex xs3>
            <!-- this is disabled on MC-873 -->
            <v-layout column class="ml-3">
              <p class="mb-2 claimlabel">Address of Loss Location</p>
              <v-text-field
                placeholder="Enter..."
                solo
                dense
                :maxLength="100"
                class="forminput textDisable"
                v-model="claimDetails.addressOfLossLocation"
                :disabled="disableOnOpen"
                :class="disableOnOpen == true ? 'font-weight-bold':'font-weight-regular'"
              ></v-text-field>
            </v-layout>
          </v-flex>

          <v-flex xs3>
            <v-layout column class="ml-3">
              <p class="mb-2 claimlabel">Pin Code of Loss Location<span v-if="this.claimDetails.internationalLocation != true" class="mandatorycolor"> *</span>
              </p>
              <!-- locpincode pinCode-->
              <v-autocomplete
                v-model="claimDetails.pinCodeOfLossLocation"
                :items="itemspin"
                :search-input.sync="searchPincode"
                color="white"
                :rules="[tocheckLocation()]"
                hide-no-data
                cache-items
                hide-selected
                item-text="locpincode"
                item-value="locpincode"
                placeholder="Start typing to Search"
                :disabled="disableOnOpen"
                maxlength="6"
                solo
                dense
                class="forminput"
                clearable
              ></v-autocomplete>
            </v-layout>
          </v-flex>

          <v-flex xs3>
            <v-layout class="ml-3" column>
              <p class="mb-2 claimlabel">
                Loss description <span class="mandatorycolor"> *</span>
              </p>
              <v-select
                label="Select"
                solo
                dense
                :items="lossDescription"
                item-text="descriptionText"
                item-value="descriptionText"
                :rules="[mandatoryrule()]"
                :maxLength="250"
                v-model="claimDetails.lossDescription"
                class="forminput textDisable"
                :disabled="disableOnClosed"
                :class=" disableOnClosed==true ? 'font-weight-bold':'font-weight-regular'"
                clearable>
              </v-select>
            </v-layout>
          </v-flex>

          <v-flex xs3 v-if="checkProductname">
            <v-layout class="ml-3" column>
              <p class="mb-2 claimlabel">Property Damaged<span class="mandatorycolor"> *</span></p>
              <v-text-field
                placeholder="Enter..."
                solo
                dense
                :rules="[mandatoryrule(), checkblankspaces(claimDetails.propertyDamaged)]"
                :maxLength="100"
                v-model="claimDetails.propertyDamaged"
                class="forminput textDisable"
                :disabled="disableOnClosed"
                 :class=" disableOnClosed == true ? 'font-weight-bold':'font-weight-regular'"
              ></v-text-field>
            </v-layout>
          </v-flex>
          <v-flex xs3> </v-flex>
        </v-layout>
       
        <!-- Is event catastrophe -->
        <v-layout class="mt-2">
          <v-flex xs3>
            <v-layout class="ml-3" column>
              <v-checkbox
                label="Is event a Catastrophe"
                class="px-2 withoutpolicycheck"
                dense
                solo
                v-model="claimDetails.isEventACatastrophe"
                @change="isEventACatastrophe"
                :disabled="disableOnClosed || disableOnFeatureClaim || disableCatastrophe"
                color="#23B1A9"
                >
              </v-checkbox>
            </v-layout>
          </v-flex>

          <v-flex xs3>
            <v-layout column class="ml-3">
              <p class="mb-2 claimlabel">
                Catastrophe Description
                <span v-if="catastrophe" class="mandatorycolor"> *</span>
              </p>
              <v-select
                label="Select"
                solo
                dense
                :items="catastropheDesDetails"
                item-text="catastropheDescription"
                :rules="[catastropheValidation(claimDetails.catastropheDescription),]"
                @change="toCheckCatastrophe()"
                v-model="claimDetails.catastropheDescription"
                :background-color="disableCatastropheDescription || disableCatastrophe == true ? '#f5f5f5':'#FFFFFF'"
                :class="disableCatastropheDescription || disableCatastrophe == true ? 'font-weight-bold':'font-weight-regular'"
                :disabled="disableCatastropheDescription || disableCatastrophe"
                class="forminput textDisable"
                clearable
              >
              </v-select>
            </v-layout>
          </v-flex>

          <v-flex xs3>
            <v-layout column class="ml-3">
              <p class="mb-2 claimlabel">Catastrophe Code</p>
              <v-text-field
                placeholder="Enter..."
                solo
                dense
                v-model="claimDetails.catastropheCode"
                 :background-color="disableOnfeatureclaimandEventcheck || disableCatastrophe == true ? '#f5f5f5':'#FFFFFF'"
                :disabled="disableOnfeatureclaimandEventcheck || disableCatastrophe"
                :class="disableOnfeatureclaimandEventcheck || disableCatastrophe == true ? 'font-weight-bold':'font-weight-regular'"
                class="forminput textDisable"
              ></v-text-field>
            </v-layout>
          </v-flex>

          <v-flex xs3>
            <v-layout column class="ml-3">
              <p class="mb-2 claimlabel">Catastrophe Details</p>
              <v-text-field
                placeholder="Enter..."
                solo
                dense
                v-model="claimDetails.catastropheDetail"
                :background-color="disableOnfeatureclaimandEventcheck || disableCatastrophe == true ? '#f5f5f5':'#FFFFFF'"
                :disabled="disableOnfeatureclaimandEventcheck || disableCatastrophe"
                :class="disableOnfeatureclaimandEventcheck || disableCatastrophe == true ? 'font-weight-bold':'font-weight-regular'"
                class="forminput textDisable"
              ></v-text-field>
            </v-layout>
          </v-flex>

          <v-flex xs3>
            <v-layout column class="ml-3 mr-3">
              <p class="mb-2 claimlabel">Catastrophe Type</p>
              <v-select
                label="Select"
                solo
                dense
                :items="catastropheDesDetails"
                item-text="catastropheType"
                item-value="catastropheType"
                v-model="claimDetails.catastropheType"
                :background-color="disableOnfeatureclaimandEventcheck || disableCatastrophe == true ? '#f5f5f5':'#FFFFFF'"
                :disabled="disableOnfeatureclaimandEventcheck || disableCatastrophe"
                :class="disableOnfeatureclaimandEventcheck || disableCatastrophe == true ? 'font-weight-bold':'font-weight-regular'"
                clearable
                @click:clear="$nextTick(() => claimDetails.catastropheType='')"
                class="forminput textDisable">
              </v-select>
            </v-layout>
          </v-flex>
        </v-layout>

         <!-- Address of survey '-->
        <v-layout v-if="showHideServiceClaimFields">
          <v-flex xs3>
            <v-layout column class="ml-3">
              <p class="mb-2 claimlabel">Address of Survey</p>
              <v-text-field
                placeholder="Enter..."
                solo
                dense
                :rules='[alphaNumeric(claimDetails.addressOfSurvey)]'
                v-model='claimDetails.addressOfSurvey'
                :maxLength="200"
                class="forminput"
              ></v-text-field>
            </v-layout>
          </v-flex>

          <v-flex xs3>
          </v-flex>

          <v-flex xs3>
          </v-flex>         

          <v-flex xs3>
          </v-flex>

          <v-flex xs3>
          </v-flex>
        </v-layout>
      </v-flex>

      <!-- Transit Details Group start -->
      <v-flex class="mb-5">
        <v-row class="groupRowHead mt-0">
          <v-col align="left" class="mt-0">
            <v-flex class="ml-2 GroupName">Transit Details</v-flex>
          </v-col>
          <v-col align="right" class="pt-2 mt-0">
            <v-icon
              class="mr-2 viconfont"
              
              dark
              @click="transitDetailsMenu = !transitDetailsMenu"
            >
              {{
                transitDetailsMenu == true ? "mdi-minus-box" : "mdi-plus-box"
              }}
            </v-icon>
          </v-col>
        </v-row>
      </v-flex>

      <v-flex v-if="transitDetailsMenu" class="mt-5 layoutBorder">
        <v-layout class="mt-3">
           <v-flex xs3>
            <v-layout class="ml-3" column>
              <p class="mb-2 claimlabel">Transit From Country</p>
              <v-select
                label="Select"
                solo
                dense
                v-model="claimDetails.transitFromCountry"
                :items="Countries"
                item-text="countryName"
                class="forminput textDisable"
                :disabled="disableOnClosed"
                :class="disableOnClosed == true ? 'font-weight-bold':'font-weight-regular'"
                clearable
                @click:clear="$nextTick(() => claimDetails.transitFromCountry='')"
                 >
              </v-select>
            </v-layout>
          </v-flex>

          <v-flex xs3  v-if="claimDetails.transitFromCountry=='INDIA'">
            <v-layout class="ml-3" column>
              <!-- <p class="mb-2 claimlabel" v-if="claimDetails.transitFromCountry!='INDIA'">Transit From State</p> -->
              <p class="mb-2 claimlabel" >Transit From City</p>
              <v-autocomplete                  
                :search-input.sync="searchTransitFromCity"               
                class="forminput autostyle"                                               
                placeholder="Start typing to Search"
                solo 
                :items="transitFromCities"
                item-text="cityDistrictName"
                :disabled="disableOnClosed"
                @change="clearother"
                dense 
                v-model="claimDetails.transitFromCity"          
                clearable
                @click:clear="$nextTick(() => claimDetails.transitFromCity='')"
              ></v-autocomplete>

            </v-layout>
          </v-flex>

          <v-flex xs3 v-if="claimDetails.transitFromCountry!='INDIA'">
            <v-layout class="ml-3" column>
               <p class="mb-2 claimlabel" >Transit From City</p>
              <v-text-field
                        label="Enter"
                        solo
                        dense
                        v-model="claimDetails.transitFromCityOtherName"
                        class="forminput textDisable"
                        :disabled="disableOnClosed  || this.claimDetails.transitFromCountry=='INDIA' && this.claimDetails.transitFromCity!='OTHER' "
                        :class="(disableOnClosed  || this.claimDetails.transitFromCountry=='INDIA' && this.claimDetails.transitFromCity!='OTHER') == true ? 'font-weight-bold':'font-weight-regular'"
                        :rules="[transitAlphaChar(claimDetails.transitFromCityOtherName)]"
                          >
                      </v-text-field>
            </v-layout>
          </v-flex>

      <v-flex xs3>
            <v-layout class="ml-3" column>
              <p class="mb-2 claimlabel">Transit To Country</p>
              <v-select
                label="Select"
                solo
                dense
                v-model="claimDetails.transitToCountry"
                :items="Countries"
                item-text="countryName"
                :class="disableOnClosed == true ? 'font-weight-bold':'font-weight-regular'"
                class="forminput textDisable"
                :disabled="disableOnClosed"
                clearable
                 @click:clear="$nextTick(() => claimDetails.transitToCountry='')"
              >
              </v-select>

               

            </v-layout>
          </v-flex>

          <v-flex xs3 v-if="claimDetails.transitToCountry=='INDIA'">
            <v-layout class="ml-3" column>
              <!-- <p class="mb-2 claimlabel"  v-if="claimDetails.transitToCountry!='INDIA'">Transit To State</p> -->
              <p class="mb-2 claimlabel"  >Transit To City</p>
             
               <!-- <v-select
                label="Select"
                solo
                dense
                v-model="claimDetails.transitToCity"
                :items="transitToCities"
                item-text="cityDistrictName"
                class="forminput"
                :disabled="disableOnClosed"
                clearable
                @change="cleartransitTo"
                @click:clear="$nextTick(() => claimDetails.transitToCity='')"
              >
              </v-select> -->

               <v-autocomplete                  
                :search-input.sync="searchTransitToCity"               
                class="forminput autostyle"                                               
                placeholder="Start typing to Search"
                solo 
                :items="transitToCities"
                item-text="cityDistrictName"
                :disabled="disableOnClosed"
                @change="cleartransitTo"
                dense 
                v-model="claimDetails.transitToCity"          
                clearable
                @click:clear="$nextTick(() => claimDetails.transitToCity='')"
              ></v-autocomplete>
            

            </v-layout>
          </v-flex>

     <v-flex xs3 v-if="claimDetails.transitToCountry!='INDIA'">
            <v-layout class="ml-3" column>
               <p class="mb-2 claimlabel" >Transit To City</p>
              <v-text-field
                        label="Enter"
                        solo
                        dense
                        v-model="claimDetails.transitToCityOtherName"
                        class="forminput textDisable"
                        :disabled="disableOnClosed ||  this.claimDetails.transitToCountry=='INDIA' && this.claimDetails.transitToCity!='OTHER'"
                        :class="(disableOnClosed ||  this.claimDetails.transitToCountry=='INDIA' && this.claimDetails.transitToCity!='OTHER') == true ? 'font-weight-bold':'font-weight-regular'"
                        :rules="[transitAlphaChar(claimDetails.transitToCityOtherName)]"
                          >
                      </v-text-field>
            </v-layout>
          </v-flex>
 
      <v-flex xs3>
            <v-layout class="ml-3 mr-3" column v-if="checkProductname">
              <p class="mb-2 claimlabel">Transporter/Vessel Name</p>
              <v-text-field
                placeholder="Enter..."
                solo
                dense
                :maxLength="150"
                v-model="claimDetails.transporterVesselName"
                class="forminput textDisable"
                :disabled="disableOnClosed"
                :class="disableOnClosed == true ? 'font-weight-bold':'font-weight-regular'"
              ></v-text-field>
            </v-layout>
          </v-flex>

        </v-layout>
<!-- 
 <v-layout class="mt-3">
  

  

          
          <v-flex xs3></v-flex>
          <v-flex xs3></v-flex>
          <v-flex xs3></v-flex>
 </v-layout> -->
        

      
          <!-- International Location -->
        <v-layout class="mt-2">
          <v-flex xs3>
            <v-layout column class="ml-3">
              <v-checkbox
                label="International Location"
                class="px-2 internallocation"
                color="#23B1A9"
                dense
                @change="tocheckLocation"
                :disabled="disableOnCSCT"
                v-model="claimDetails.internationalLocation"
               >
              </v-checkbox>
            </v-layout>
          </v-flex>

          <v-flex xs3>
            <v-layout column class="ml-3">
              <p class="mb-2 claimlabel">Location Landmark</p>
              <v-text-field
                placeholder="Enter..."
                solo
                dense
                :background-color="onInternationalocation == true ? '#f5f5f5':'#FFFFFF'"
                :maxLength="100"
                :rules="[maxLength(claimDetails.locationLandmark, 100)]"
                :disabled="onInternationalocation || disableOnCSCT"
                 :class="onInternationalocation == true ? 'font-weight-bold':'font-weight-regular'"
                v-model="claimDetails.locationLandmark"
                class="forminput textDisable"
              ></v-text-field>
            </v-layout>
          </v-flex>

          <v-flex xs3>
            <v-layout column class="ml-3">
              <p class="mb-2 claimlabel">Country<span v-if="claimDetails.internationalLocation==true" class="mandatorycolor"> *</span></p>
              <v-select
                label="Select"
                solo
                dense
                :items="InternationalCountries"
                item-text="countryName"
                v-model="claimDetails.country"
                :rules="[mandatorylocationcountry()]"
                :background-color="disableOnClosed ||disableOnFeatureClaim || onInternationalocation == true ? '#f5f5f5':'#FFFFFF'"
                :class="(disableOnClosed ||disableOnFeatureClaim || onInternationalocation) == true ? 'font-weight-bold':'font-weight-regular'"
                :disabled="
                  disableOnClosed ||
                  disableOnFeatureClaim ||
                  onInternationalocation"
                clearable
                 @click:clear="$nextTick(() => claimDetails.country='')"
                class="forminput textDisable">
              </v-select>
            </v-layout>
          </v-flex>

          <v-flex xs3 v-if="this.claimDetails.country=='INDIA'">
            <v-layout column class="ml-3">
            <p class="mb-2 claimlabel">City</p>
             <v-autocomplete                  
                :search-input.sync="searchInternationCity"               
                class="forminput autostyle"                                               
                placeholder="Start typing to Search"
                solo 
                :items="Cities"
                item-text="cityDistrictName"
                @change="clearinternationlocationothercity"
                dense   
                v-model="claimDetails.city"
                :background-color="disableOnClosed ||disableOnFeatureClaim || onInternationalocation == true ? '#f5f5f5':'#FFFFFF'"
                :disabled="
                  disableOnClosed ||
                  disableOnFeatureClaim ||
                  onInternationalocation"
                clearable        
                @click:clear="$nextTick(() => claimDetails.city='')"
              ></v-autocomplete>


            </v-layout>
          </v-flex>

          <v-flex xs3   v-if="this.claimDetails.country!='INDIA' || this.claimDetails.city=='OTHER'">
            <v-layout column class="ml-3">
              <p class="mb-2 claimlabel">City</p>
              <v-text-field
                label="Enter"
                solo
                dense
                v-model="claimDetails.city"
                :background-color="disableOnClosed ||disableOnFeatureClaim || onInternationalocation == true ? '#f5f5f5':'#FFFFFF'"
                :class="(disableOnClosed ||disableOnFeatureClaim || onInternationalocation) == true ? 'font-weight-bold':'font-weight-regular'"
                :disabled="disableOnClosed || disableOnFeatureClaim ||onInternationalocation"
                class="forminput textDisable"
                :rules="[transitAlphaChar(claimDetails.city)]">
              </v-text-field>
            </v-layout>
          </v-flex>

          <!-- <span class="mandatorycolor"> *</span> -->
          <v-flex xs3 v-if="checkProductname">
            <v-layout class="ml-3 mr-3" column>
              <p class="mb-2 claimlabel">
                Invoice Details 
              </p>
              <v-text-field
                placeholder="Add Details..."
                solo
                v-model="invoiceFieldValidation"
                dense
                class="forminput"
                readonly>
                <template v-slot:append class="my-0 mr-2">
                  <v-icon
                    class="blueiconstyle"
                    @click="invoiceDetailsTableMenu = true">mdi-plus</v-icon>
                </template>
              </v-text-field>
            </v-layout>
          </v-flex>
        </v-layout>

        <!-- AIG Settling -->
        <v-layout class="mt-2">
          <v-flex xs3 v-if="claimDetails.claimType !== 'Service Claim'">
            <v-layout column class="ml-3">
              <p class="mb-2 claimlabel">AIG Settling Company</p>
              <!-- <v-select
                label="Select"
                solo
                dense
                :items="AIGDetails"
                :disabled="disableOnClosed || AIGdisable"
                item-text="nameOfAigOffice"
                @change="toAIGCountry"
                v-model="claimDetails.aigSettlingCompany"
                clearable
                class="forminput"
              >
              </v-select> -->
              <v-autocomplete                  
                :search-input.sync="searchAIGOffice"               
                class="forminput autostyle"
                :items="AIGDetails"
                :background-color="disableOnClosed ||AIGdisable == true ? '#f5f5f5':'#FFFFFF'"
                :disabled='disableOnClosed || AIGdisable'
                item-text="nameOfAigOffice"
                @change="toChangeCountry"
                v-model="claimDetails.aigSettlingCompany"                   
                placeholder="Start typing to Search"                           
                solo 
                
                dense 
                deletable-chips           
                clearable
              ></v-autocomplete>
            </v-layout>
          </v-flex>

          <v-flex xs3 v-if="claimDetails.claimType !== 'Service Claim'"> 
              <!-- :disabled="disableOnClosed || AIGdisable" -->
              <!-- :background-color="disableOnClosed ||AIGdisable == true ? '#f5f5f5':'#FFFFFF'" -->
              <!-- AIG Country field is always make disabled and ticket id -MC-748 -->
            <v-layout column class="ml-3">
              <p class="mb-2 claimlabel">AIG Country</p>
              <v-text-field
                placeholder="Enter..."
                solo
                dense
                background-color="#f5f5f5"
                disabled
                v-model="claimDetails.aigCountry"
                class="forminput font-weight-bold textDisable">
              </v-text-field>
            </v-layout>
          </v-flex>

          <v-flex xs3>
            <v-layout column class="ml-3">
              <p class="mb-2 claimlabel">AIG Claim Number <span v-if="aigClaimtrue || claimDetails.claimCategory=='Export Claim'" class="mandatorycolor"> *</span></p>
              <v-text-field
                placeholder="Enter..."
                solo
                dense
                :maxLength="AIGmaxlen"
                :rules="[aigmandatoryRule(), alphaNumeric(claimDetails.aigClaimNumber)]"
                :background-color="disableOnClosed ||AIGdisable == true ? '#f5f5f5':'#FFFFFF'"
                :disabled="disableOnClosed || AIGdisable"
                 :class="disableOnClosed || AIGdisable == true ? 'font-weight-bold':'font-weight-regular'"
                v-model="claimDetails.aigClaimNumber"
                class="forminput textDisable"
              ></v-text-field>
            </v-layout>
          </v-flex>

           <v-flex xs3 v-if="showHideServiceClaimFields">
            <v-layout column class="ml-3">
              <p class="mb-2 claimlabel">Carrier Name</p>
              <v-text-field
                placeholder="Enter..."
                solo
                dense
                v-model="claimDetails.carrierName"
                :maxLength="100"
                class="forminput"
              ></v-text-field>
            </v-layout>
          </v-flex>

          <!-- <v-flex xs3 v-if="claimDetails.claimType == 'Service Claim'">
            <v-layout column class="ml-3">
              <p class="mb-2 claimlabel">Transporter</p>
              <v-text-field
                placeholder="Enter..."
                solo
                dense
                :maxLength="150"
                class="forminput"
              ></v-text-field>
            </v-layout>
          </v-flex> -->

          <v-flex xs6>
            <v-layout class="ml-3" column>
              <p class="mb-2 claimlabel">Remarks</p>
              <v-text-field
                placeholder="Enter..."
                solo
                dense
                :rules="[maxLength(claimDetails.remarks, 250)]"
                v-model="claimDetails.remarks"
                class="forminput font-weight-light"
                :background-color="disableOnClosed == true ? '#f5f5f5':'#FFFFFF'"
                :class="claimDetails.claimType !== 'Service Claim' ? 'mr-3' : 'mr-0'"
                :disabled="disableOnClosed">
              </v-text-field>
            </v-layout>
          </v-flex>

          <v-flex xs3 v-if="claimDetails.claimType == 'Service Claim'">
          </v-flex>
          
        </v-layout>
      </v-flex>

      <!-- Loss Reserve Details Group start -->
      <v-flex class="mb-5">
        <v-row class="groupRowHead mt-0">
          <v-col align="left" class="mt-0">
            <v-flex class="ml-2 GroupName">Loss Reserve Details</v-flex>
          </v-col>
          <v-col align="right" class="pt-2 mt-0">
            <v-icon
              class="mr-2 viconfont"
              
              dark
              @click="lossReserveDetailsMenu = !lossReserveDetailsMenu">
              {{
                lossReserveDetailsMenu == true
                  ? "mdi-minus-box"
                  : "mdi-plus-box"
              }}
            </v-icon>
          </v-col>
        </v-row>
      </v-flex>
      <v-flex
        v-if="lossReserveDetailsMenu"
        class="mt-5 layoutBorder flexbottom">
        <v-layout class="mt-3">
          <v-flex xs3>
            <v-layout column class="ml-3">
              <p class="mb-2 claimlabel">Sum Insured</p>
              <v-text-field
                placeholder="Enter..."
                solo
                dense
                disabled
                v-model="claimDetails.sumInsured"
                class="forminput mx-0 my-0 font-weight-bold textDisable">
                <template v-slot:prepend-inner class="mx-0 my-0">
                  <v-icon class="iconstyle">mdi-currency-inr</v-icon>
                </template>
              </v-text-field>
            </v-layout>
          </v-flex>

          <v-flex xs3>
            <v-layout column class="ml-3">
              <p class="mb-2 claimlabel">Balance Sum Insured</p>
              <v-text-field
                placeholder="Enter..."
                solo
                dense
                disabled
                class="forminput mx-0 my-0 font-weight-bold textDisable">
                <template v-slot:prepend-inner class="mx-0 my-0">
                  <v-icon class="iconstyle">mdi-currency-inr</v-icon>
                </template>
              </v-text-field>
            </v-layout>
          </v-flex>

          <v-flex xs3>
            <v-layout column class="ml-3">
              <p class="mb-2 claimlabel">Reserve Amount</p>
              <v-text-field
                solo
                dense
                disabled
                
                v-model="claimDetails.reserveAmount"
                class="forminput textDisable font-weight-bold">
                <template v-slot:prepend-inner class="mx-0 my-0">
                  <v-icon class="iconstyle">mdi-currency-inr</v-icon>
                </template>
              </v-text-field>
            </v-layout>
          </v-flex>

          <v-flex xs3> </v-flex>
          <v-flex xs3> </v-flex>

          <!-- <template>
            <v-flex id="create">
              <v-speed-dial v-model="fab" 
                :right="right"
                style="bottom:120px;" 
                :direction="direction"
                :transition="transition">
                   
                  <template v-slot:activator>
                    <v-btn
                      v-model="fab"
                      color="blue darken-2"
                      dark
                      style="right: 70px; top:177px;" 
                      fab>
                      <v-icon v-if="fab">mdi-close</v-icon>
                      <v-icon v-else>mdi-account-circle</v-icon>
                    </v-btn>
                  </template>
                  <v-container class="SummaryFirstdivContainer containerMaxWidth">
                      <v-flex
                        color="white"
                        class="textTransformCapitalize claimSummerButtonsWidth"
                        v-for="item in SummerButtonsFirstrowTexts"
                        :key="item"
                        @click="openNewPagesRow(item)">{{ item }}
                      </v-flex>
                  </v-container>
              </v-speed-dial>
            </v-flex>
          </template> -->
        </v-layout>
      </v-flex> 

      <!-- submit and save and clear buttons -->
      <v-flex justify="end" class="btnsdiv mb-8">
        <v-row>
          <v-col align="right" class="mt-5">
            <v-btn color="#23B1A9" class="buttonscolor mr-5" type="submit" :disabled="submitDisable">
              <v-icon class="mr-2 iconfontsize">mdi-arrow-right-circle</v-icon>
              <div class="vertical_line"></div>Submit
            </v-btn>

            <v-btn
              color="#152F38"
              class="buttonscolor mr-5"
              @click.prevent="returnFNOL">
              <v-icon class="mr-2 iconfontsize" >mdi-arrow-left-circle</v-icon>
              <div class="vertical_line"></div>Return
            </v-btn>

            <v-btn
              v-if="false"
              color="#23B1A9"
              class="buttonscolor mr-5"
              @click.prevent="saveFNOL">
              <v-icon class="mr-2 iconfontsize" >mdi-checkbox-marked-circle</v-icon>
              <div class="vertical_line"></div>Save
            </v-btn>

            <v-btn
              color="#D1121B"
              class="buttonscolor mr-5"
              @click.prevent="clearFNOL"
              v-if="!claimDetails.mainClaimNumber">
              <v-icon class="mr-2 iconfontsize" >mdi-trash-can-outline</v-icon>
              <div class="vertical_line"></div>Clear
            </v-btn>

            <v-btn color="#23B1A9" class="buttonscolor mr-5"
             v-if="CreateFeatureClaimButton"
              @click.prevent="openCreateFeatureClaim">
              <v-icon class="mr-2 createfont" >mdi-plus</v-icon>
              <div class="vertical_line"></div>Create Feature Claim
            </v-btn>

            <v-btn
              color="#E46A25"
              class="buttonscolor mr-5"
              @click.prevent="uploadFNOL">
              <v-icon class="mr-2 iconfontsize" >mdi-cloud-upload</v-icon>
              <div class="vertical_line"></div>Bulk Upload Claim
            </v-btn>
          </v-col>
        </v-row>
      </v-flex>
    </v-form>

    <!-- all dailogbox start -->

    <v-row justify="center">
      <v-dialog v-model="addothertranstiFromCity" persistent max-width="400px">
        <v-card>
          <v-card-title class="pl-3 px-0 py-0 dialogBoxCardTitle">
            <span class="marginStyle">Add Other City</span>
            <div class="windowClsclass">
              <v-icon @click="cleartransitfrom" class="ml-3 mt-2">mdi-window-close</v-icon>
            </div>
          </v-card-title>
          <v-card-text class="ContainerBGColor">
          <v-form  ref="othertransitform" @submit.prevent="saveOther">
            <v-container class="px-0">
              <v-layout
                column
                class="px-4 py-2 layoutdivCls">
              <p class="mb-2 claimlabel">Transit from city(Other)</p>
               <v-text-field
                 label="Enter"
                 solo
                 dense
                 v-model="claimDetails.transitFromCityOtherName"
                 class="forminput"
                :disabled="disableOnClosed"
                :rules="[mandatoryfromcity()]">
              </v-text-field>
              <v-col md2 class="colStyle">
              <v-btn color="#23B1A9" dark class="buttonscolor mr-5" type="submit">
                      <v-icon class="mr-2 fontsizeStyle">mdi-arrow-right-circle</v-icon>
                      <div class="vertical_line"></div>Save
                    </v-btn>
              </v-col>
               
              </v-layout>
            </v-container>
             </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
     
    <v-row justify="center">
      <v-dialog v-model="addothertranstiToCity" persistent max-width="400px">
        <v-card>
          <v-card-title class="pl-3 px-0 py-0 dialogBoxCardTitle">
            <span class="marginStyle">Add Other City</span>
            <div class="windowClsclass">
              <v-icon @click="cleartransitto" class="ml-3 mt-2">mdi-window-close</v-icon>
            </div>
          </v-card-title>
          <v-card-text class="ContainerBGColor">
          <v-form  ref="othertransitto" @submit.prevent="saveOtherTo">
            <v-container class="px-0">
              <v-layout column class="px-4 py-2 layoutdivCls">
              <p class="mb-2 claimlabel">Transit To city(Other)</p>
               <v-text-field
                 label="Enter"
                 solo
                 dense
                 v-model="claimDetails.transitToCityOtherName"
                 class="forminput"
                :disabled="disableOnClosed"
                :rules="[mandatorytocity()]">
              </v-text-field>
              <v-col md2 class="colStyle">
              <v-btn color="#23B1A9" dark class="buttonscolor mr-5" type="submit">
                  <v-icon class="mr-2 fontsizeStyle">mdi-arrow-right-circle</v-icon>
                  <div class="vertical_line"></div>Save
              </v-btn>
              </v-col>
               
              </v-layout>
            </v-container>
             </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>

    <!-- Dialogbox for Caller Email -->
    <v-row justify="center">
      <v-dialog v-model="addcallerEmaildialog" persistent max-width="400px">
        <v-card>
          <v-card-title
            class="pl-6 px-0 py-0 dialogBoxCardTitle">
            <span class="titlemargin">Add Email ID</span>
            <div class="windowClsclass">
              <v-icon @click="addcallerEmaildialog = false" class="ml-2 mt-2">mdi-window-close</v-icon>
            </div>
          </v-card-title>
          <v-card-text class="ContainerBGColor">
            <v-container class="px-0">
              <v-layout
                column
                class="px-4 py-2 layoutdivCls">
                <p class="mb-2 claimlabel">Caller Email ID</p>
                <v-text-field
                  placeholder="Enter..."
                  solo
                  dense
                  :rules="[email(TempcallerEmailID)]"
                  :maxLength="50"
                  v-model="TempcallerEmailID"
                  class="forminput">
                  <template v-slot:append class="my-0 mr-2">
                    <v-icon
                      class="blueiconstyle"
                      style="cursor: pointer"
                      @click="addTempCallerEmailIDs(TempcallerEmailID)">mdi-plus</v-icon>
                  </template>
                </v-text-field>
                <div class="chipDIV"></div>
                <div>
                  <v-chip-group
                    v-for="(email, index) in claimDetails.callerEmailID"
                    :key="index"
                    v-model="claimDetails.callerEmailID"
                    chips
                    multiple>
                    <v-chip close small @click:close="removeCallerEmail(email)">{{ email }}</v-chip>
                  </v-chip-group>
                  <!-- @click:close="remove(emailIdForCommunication)" v-model="claimDetails.emailIdForCommunication"-->
                </div>
              </v-layout>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
    <!-- dialog box adding Insured Mobile Number -->
    <v-row justify="center">
      <v-dialog v-model="addInsureddialog" persistent max-width="600px">
        <v-card>
          <v-card-title class="pl-6 px-0 py-0 dialogBoxCardTitle">
            <span class="mobileMargin">Add Mobile Number</span>
            <div class="emailClsWindow">
              <v-icon @click="addInsureddialog = false" class="ml-2 mt-3">mdi-window-close</v-icon>
            </div>
          </v-card-title>
          <v-card-text class="ContainerBGColor">
            <v-container class="px-0">
              <v-layout
                column
                class="px-4 py-2 layoutdivCls">
                <v-layout class="mt-3">
                  <v-flex xs3>
                    <v-layout column class="ml-3">
                      <p class="mb-2 ml-10 claimlabel">ISD Code<span class="mandatorycolor"> *</span></p>
                      <v-text-field
                        placeholder="Enter..."
                        :maxLength="5"
                        solo
                        dense
                        :rules='[validateIsd(tempIsdCode)]'
                        v-model="tempIsdCode"
                        class="forminput">
                        <template v-slot:prepend-inner class="mx-0 my-0">
                          <v-icon class="iconstyle">mdi-phone</v-icon>
                        </template>
                      </v-text-field>
                    </v-layout>
                  </v-flex>
                  <v-flex xs6>
                    <v-layout column class="ml-3">
                      <p class="mb-2 claimlabel">Insured Mobile Number<span class="mandatorycolor"> *</span></p>
                      <v-text-field
                        placeholder="Enter..."
                        solo
                        dense
                        :rules="[mobilerule(tempMobileNumber)]"
                        :maxLength="10"
                        v-model="tempMobileNumber"
                        class="forminput">
                        <template v-slot:append class="my-0 mr-2">
                          <v-icon class="blueiconstyle" @click="addTempMobileno(tempIsdCode,tempMobileNumber)">mdi-plus</v-icon>
                        </template>
                      </v-text-field>
                    </v-layout>
                  </v-flex>
                </v-layout>
                <div class="chipDIV"></div>
                <!-- <v-col style="margin-top:6px"> -->
                <v-chip-group
                  v-for="(item, index) in claimDetails.insuredMobileNumber"
                  :key="index"
                  v-model="claimDetails.insuredMobileNumber"
                  chips
                  multiple>
                 <v-chip style="height: 23px;">{{ item.isd }}</v-chip>
                  <v-chip close small @click:close="removeMobile(index)">{{ item.number }}</v-chip>
                </v-chip-group>
                <!-- </v-col> -->
              </v-layout>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>

    <!-- DialogBox E-mail for Communication -->
    <v-row justify="center">
      <v-dialog v-model="addEmaildialog" persistent max-width="400px">
        <v-card>
          <v-card-title class="pl-6 px-0 py-0 dialogBoxCardTitle">
            <span class="titlemargin">Add Email ID</span>
            <div class="windowClsclass">
              <v-icon @click="addEmaildialog = false" class="ml-2 mt-2">mdi-window-close</v-icon>
            </div>
          </v-card-title>
          <v-card-text class="ContainerBGColor">
            <v-container class="px-0">
              <v-layout
                column
                class="px-4 py-2 layoutdivCls"
                style="">
                <p class="mb-2 claimlabel">E-mail ID For Communication</p>
                <v-text-field
                  placeholder="Enter..."
                  solo
                  dense
                  :rules="[email(TempemailIdForCommunication)]"
                  :maxLength="50"
                  v-model="TempemailIdForCommunication"
                  class="forminput">
                  <template v-slot:append class="my-0 mr-2">
                    <v-icon
                      class="blueiconstyle"
                      style="cursor: pointer"
                      @click="addTempEmailIDs(TempemailIdForCommunication)">mdi-plus</v-icon>
                  </template>
                </v-text-field>
                <div class="chipDIV"></div>
                <div>
                  <v-chip-group
                    v-for="(email, index) in claimDetails.emailIdForCommunication"
                    :key="index"
                    v-model="claimDetails.emailIdForCommunication"
                    chips
                    multiple>
                    <v-chip close small @click:close="remove(email)">{{ email }}</v-chip>
                  </v-chip-group>
                  <!-- @click:close="remove(emailIdForCommunication)" v-model="claimDetails.emailIdForCommunication"-->
                </div>
              </v-layout>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>

    <v-snackbar v-model="snackbar" right centered color="primary">"No bearing on final claim amount"
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">Close</v-btn>
      </template>
    </v-snackbar>
    <v-snackbar v-model="validationbox" top>{{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="validationbox = false">Close</v-btn>
      </template>
    </v-snackbar>

    <!-- Coverage Details -->
    <v-row justify="center">
      <v-dialog v-model="coverageDescrptionMenu" persistent max-width="71%">
        <v-card>
          <v-card-title class="pl-0 px-0 py-0 dailogboxAllvcardTableBackground">
            <v-row>
                <span class="descrptionSpan mb-5 ml-8 coverageSpan">Coverage Details</span>
                <v-icon @click="coverageDescrptionMenu = false" class="ml-7 mt-0">mdi-window-close</v-icon>
            </v-row>
            <v-card-text class="vcardTextTableBackground">
              <v-simple-table class="mt-1 coverageStorageTableWidth">
                <template v-slot:default>
                  <thead class="tableBackGroundColor">
                    <tr class="scrollCoverage">
                      <th class="tableheadCoverageEmpty"></th>
                      <th class="tableheadCoverage coverCodeth">Cover Code</th>
                      <th class="tableheadCoverage" >Cover Description</th>
                      <th class="tableheadCoverage perilNameth">Peril Name</th>
                      <th class="tableheadCoverage riskth" >Risk</th>
                      <th class="tableheadCoverage">Risk Serial No</th>
                      <th class="tableheadCoverage" >Sum Insured</th>
                    </tr>
                  </thead>
                  <tbody class="coverageBody">
                    <tr v-for="(item, index) in coverageDescrptionOptions" :key="index">
                      <td class="tableTdBorderRightNone">
                        <v-radio-group v-model="coverageIndex">
                          <v-radio :value="index"></v-radio>
                          <!--  @click="selectedCoverage(index)" -->
                        </v-radio-group>
                      </td>
                      <td class="tablebodyCoverage nolCodeTd">{{ item.nolCode }}</td>
                      <td class="tablebodyCoverage coverGroupNameTd">{{ item.coverGroupName }}</td>
                      <td class="tablebodyCoverage perilNameTd">{{ item.perilName }}</td>
                      <td class="tablebodyCoverage riskTd">{{ item.risk }}</td>
                      <td class="tablebodyCoverage riskDetailSerialTd" >{{ item.riskDetailSerial }}</td>
                      <td class="tablebodyCoverage sumInsuredTd" >{{ item.sumInsured }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <!-- back and proceed  code-->
              <v-flex justify="center" class="mt-5">
                <v-row class="btnDiv">
                  <v-col align="center">
                    <v-btn
                      color="#23B1A9"
                      dark
                      class="buttonscolor mr-5"
                      @click="selectThisCoverage">
                      <v-icon class="mr-2 iconfontsize">mdi-arrow-right-circle</v-icon>
                      <div class="vertical_line"></div>Proceed
                    </v-btn>
                    <v-btn
                      color="#E46A25"
                      dark
                      class="buttonscolor mr-5"
                      @click="coverageDescrptionMenu = false">
                      <v-icon class="mr-2 iconfontsize" >mdi-close-circle</v-icon>
                      <div class="vertical_line"></div>Close
                    </v-btn>
                  </v-col>
                </v-row>
              </v-flex>
            </v-card-text>
          </v-card-title>
        </v-card>
      </v-dialog>
    </v-row>
    <!-- Storage deatils -->
      <v-row justify="center">
      <v-dialog v-model="storageDescrptionMenu" persistent max-width="70%">
        <v-card>
          <v-card-title class="pl-0 px-0 py-0 dailogboxAllvcardTableBackground">
            <v-row>
              <span class="descrptionSpan mb-5 ml-8 storageSpan" >Storage Details</span>
              <v-icon @click="storageDescrptionMenu = false" class="ml-3">mdi-window-close</v-icon>
            </v-row>
            <v-card-text class="vcardTextTableBackground">
              <v-simple-table class="mt-1 coverageStorageTableWidth">
                <template v-slot:default>
                  <thead class="tableBackGroundColor">
                    <tr>
                      <th class="tablehead storageEmpyth"></th>
                      <th class="tablehead storage1th">Storage Location Code</th>
                      <th class="tablehead storage2th">Storage Location Address</th>
                      <th class="tablehead storage3th">Country</th>
                      <th class="tablehead storage4th">State</th>
                      <th class="tablehead storage5th">City or District</th>
                      <th class="tablehead storage6th">Pincode</th>
                    </tr>
                  </thead>
                  <tbody class="storageBody">
                    <tr v-for="(item, index) in storageDescrptionOptions" :key="index">
                      <td
                        class="tabledata tableTdBorderRightNone"
                        v-if="item.storageLocationCode">
                        <v-radio-group v-model="storageIndex">
                          <v-radio :value="index"></v-radio>
                          <!-- @click="selectedCoverage(index)" -->
                        </v-radio-group>
                      </td>
                      <td class="tabledata storage1td"  v-if="item.storageLocationCode">{{ item.storageLocationCode }}</td>
                      <td class="tabledata storage2td"  v-if="item.storageLocationCode">{{ item.storageDescription }}</td>
                      <td class="tabledata storage3td"  v-if="item.storageLocationCode">{{ item.country }}</td>
                      <td class="tabledata storage4td"  v-if="item.storageLocationCode">{{ item.state }}</td>
                      <td class="tabledata storage5td"  v-if="item.storageLocationCode">{{ item.cityORDistrict }}</td>
                      <td class="tabledata storage6td"  v-if="item.storageLocationCode">{{ item.pinCode }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <v-flex v-if="storageDescrptionOptions.length === 0">Storage data not available.</v-flex>
              <!-- back and proceed  code-->
              <v-flex justify="center" class="mt-5">
                <v-row class="btnDiv">
                  <v-col align="center">
                    <v-btn
                      color="#23B1A9"
                      dark
                      class="buttonscss mr-5"
                      @click="selectThisStorage">
                      <v-icon class="mr-2 iconfontsize">mdi-checkbox-marked-circle</v-icon>
                      <div class="vertical_line"></div>Select
                    </v-btn>
                  </v-col>
                </v-row>
              </v-flex>
            </v-card-text>
          </v-card-title>
        </v-card>
      </v-dialog>
    </v-row>

    <!-- add adddress dailogbox -->
    <v-row justify="center">
      <v-dialog v-model="openAddressDailogbox" persistent max-width="50%">
        <v-card>
          <v-card-title class="pl-0 px-0 py-0 dailogboxAllvcardTableBackground">
            <v-row>
              <span class="descrptionSpan mb-5 ml-8">Address</span>
              <v-icon @click="openAddressDailogbox = false" class="ml-7 mt-0">mdi-window-close</v-icon>
            </v-row>
            <v-card-text class="vcardTextTableBackground">
              <v-form  ref="addressform" @submit.prevent="submitAddress">
                <v-flex class="backgroundWhite">
                  <v-layout class="mt-2">
                    <v-flex xs4>
                      <v-layout column class="ml-3">
                        <p class="mb-2 claimlabel">Address Line 1</p>
                        <v-text-field
                          placeholder="Enter..."
                          solo
                          dense
                          :rules="[checkblankspaces(claimDetails.claimant.addressLineOne)]"
                          :maxLength="150"
                          v-model="claimDetails.claimant.addressLineOne"
                          class="forminput"
                          :disabled="disableOnClosed || disableOnFeatureClaim"
                        ></v-text-field>
                      </v-layout>
                    </v-flex>

                    <v-flex xs4>
                      <v-layout column class="ml-3">
                        <p class="mb-2 claimlabel">Address Line 2</p>
                        <v-text-field
                          placeholder="Enter..."
                          solo
                          dense
                          :rules="[checkblankspaces(claimDetails.claimant.addressLineTwo)]"
                          :maxLength="150"
                          v-model="claimDetails.claimant.addressLineTwo"
                          class="forminput"
                          :disabled="disableOnClosed || disableOnFeatureClaim"
                        ></v-text-field>
                      </v-layout>
                    </v-flex>

                    <v-flex xs4>
                      <v-layout column class="ml-3 mr-3">
                        <p class="mb-2 claimlabel">Address Line 3</p>
                        <v-text-field
                          placeholder="Enter..."
                          solo
                          dense
                          :maxLength="150"
                          :rules="[checkblankspaces(claimDetails.claimant.addressLineThree)]"
                          v-model="claimDetails.claimant.addressLineThree"
                          class="forminput"
                          :disabled="disableOnClosed || disableOnFeatureClaim"
                        ></v-text-field>
                      </v-layout>
                    </v-flex>
                  </v-layout>

                  <v-layout class="mt-2">
                    <v-flex xs4>
                      <v-layout column class="ml-3">
                        <p class="mb-2 claimlabel">Pincode</p>
                        <v-autocomplete
                          v-model="claimDetails.claimant.pincode"
                          :search-input.sync="search"
                          class="forminput"
                          :items="itemspin"
                          item-text="locpincode"
                          item-value="locpincode"
                          @change="pinCodeCityStateCountry(claimDetails.claimant.pincode)"
                          placeholder="Start typing to Search"
                          maxlength="6"
                          solo
                          dense
                          deletable-chips
                          :disabled="disableOnClosed || disableOnFeatureClaim"
                          clearable
                        ></v-autocomplete>
                      </v-layout>
                    </v-flex>

                    <v-flex xs4>
                      <v-layout column class="ml-3">
                        <p class="mb-2 claimlabel">Country</p>
                        <v-select
                          label="Select"
                          solo
                          dense
                          :items="Countries"
                          item-text="countryName"
                          @change="getClaimantStates"
                          v-model="claimDetails.claimant.country"
                          class="forminput"
                          :disabled="disableOnClosed || disableOnFeatureClaim || IsDisabledafterpincode"
                          clearable>
                        </v-select>
                      </v-layout>
                    </v-flex>

                    <v-flex xs4>
                      <v-layout column class="ml-3 mr-3">
                        <p class="mb-2 claimlabel">State</p>
                        <v-select
                          label="Select"
                          solo
                          dense
                          :items="claimantStates"
                          item-text="stateCodeName"
                          @change="getClaimantCities"
                          v-model="claimDetails.claimant.state"
                          class="forminput"
                          :disabled="disableOnClosed || disableOnFeatureClaim || IsDisabledafterpincode"
                          clearable>
                        </v-select>
                      </v-layout>
                    </v-flex>
                  </v-layout>

                  <v-layout class="mt-2">
                    <v-flex xs4>
                      <v-layout column class="ml-3">
                        <p class="mb-2 claimlabel">District</p>
                        <v-select
                          label="Select"
                          solo
                          dense
                          :items="claimantCities"
                          item-text="cityDistrictName"
                          v-model="claimDetails.claimant.district"
                          class="forminput"
                          :disabled="disableOnClosed || disableOnFeatureClaim || IsDisabledafterpincode"
                          clearable>
                        </v-select>
                      </v-layout>
                    </v-flex>

                    <v-flex xs4>
                      <v-layout column class="ml-3">
                        <p class="mb-2 claimlabel">City</p>               
                        <v-autocomplete                  
                            :search-input.sync="searchClaimantCity"               
                            class="forminput addresspagepointer"                                               
                            placeholder="Start typing to Search"
                            solo 
                            :items="claimantCities"
                            item-text="cityDistrictName"
                            @change="clearClaimantcity"
                            dense 
                            v-model="claimDetails.claimant.city"
                            :disabled="disableOnClosed || disableOnFeatureClaim || IsDisabledafterpincode"
                            clearable        
                            @click:clear="$nextTick(() => claimDetails.claimant.city='')"
                ></v-autocomplete>


                      </v-layout>
                    </v-flex>

                    <v-flex xs4 v-if="claimDetails.claimant.city=='OTHER'">
                      <v-layout column class="ml-3">
                        <p class="mb-2 claimlabel">Other City<span v-if="claimDetails.claimant.city=='OTHER'" class="mandatorycolor"> *</span></p>
                        <v-text-field
                          label="Select"
                          solo
                          dense
                          :rules="[mandatoryclaimantcity()]"
                          v-model="claimDetails.claimant.cityOtherName"
                          class="forminput"
                          :disabled="disableOnClosed || disableOnFeatureClaim">
                        </v-text-field>
                      </v-layout>
                    </v-flex>

                    <v-flex xs4>
                      <v-layout column class="ml-3">
                        <p class="mb-2 claimlabel">Email ID</p>
                        <v-text-field
                          placeholder="Enter..."
                          solo
                          dense
                          v-model="claimDetails.claimant.emailID"
                          :rules="[email(claimDetails.claimant.emailID),]"
                          :maxLength="50"
                          class="forminput"
                          :disabled="disableOnClosed || disableOnFeatureClaim">
                          <template v-slot:prepend-inner class="mx-0 my-0">
                            <v-icon class="iconstyle">mdi-email</v-icon>
                          </template>
                        </v-text-field>
                      </v-layout>
                    </v-flex>                  
                  </v-layout>

                  <v-layout class="mt-2">
                    <v-flex xs2>
                      <v-layout column class="ml-3">
                        <p class="mb-2 ml-9 claimlabel">ISD Code</p>
                        <v-text-field
                          placeholder="Enter..."
                          solo
                          dense
                          :rules="[validateIsd(claimDetails.claimant.mobileNo.isd)]"
                          :maxLength="isdMax"
                          v-model="claimDetails.claimant.mobileNo.isd"
                          :disabled="disableOnClosed || disableOnFeatureClaim"
                          class="forminput">
                          <template v-slot:prepend-inner class="mx-0 my-0">
                            <v-icon class="iconstyle">mdi-phone</v-icon>
                          </template>
                        </v-text-field>
                      </v-layout>
                    </v-flex>

                    <v-flex xs4>
                      <v-layout column class="ml-3">
                        <p class="mb-2 claimlabel">Mobile Number</p>
                        <v-text-field
                          placeholder="Enter..."
                          solo
                          dense
                          v-model="claimDetails.claimant.mobileNo.number"
                          :maxLength="10"
                          :rules="[numeric(claimDetails.claimant.mobileNo.number),checkMobileNoLength(claimDetails.claimant.mobileNo.number)]"
                          class="forminput"
                          :disabled="disableOnClosed || disableOnFeatureClaim">
                          <template v-slot:prepend-inner class="mx-0 my-0">
                            <v-icon class="iconstyle">mdi-phone</v-icon>
                          </template>
                        </v-text-field>
                      </v-layout>
                    </v-flex>

                    <v-flex xs2>
                      <v-layout column class="ml-3">
                        <p class="mb-2 ml-9 claimlabel">ISD Code</p>
                        <v-text-field
                          placeholder="Enter..."
                          solo
                          :maxLength="isdMax"
                          v-model="claimDetails.claimant.contactNo.isd"
                          dense
                          :rules='[validateIsd(claimDetails.claimant.contactNo.isd)]'
                          :disabled="disableOnClosed || disableOnFeatureClaim"
                          class="forminput">
                          <template v-slot:prepend-inner class="mx-0 my-0">
                            <v-icon class="iconstyle">mdi-phone</v-icon>
                          </template>
                        </v-text-field>
                      </v-layout>
                    </v-flex>
                    <v-flex xs4>
                      <v-layout column class="ml-3 mr-3">
                        <p class="mb-2 claimlabel">Contact Number</p>
                        <v-text-field
                          placeholder="Enter..."
                          solo
                          dense
                          v-model="claimDetails.claimant.contactNo.number"
                          :rules="[numeric(claimDetails.claimant.contactNo.number),checkMobileNoLength(claimDetails.claimant.contactNo.number)]"
                          :maxLength="10"
                          class="forminput"
                          :disabled="disableOnClosed || disableOnFeatureClaim">
                          <template v-slot:prepend-inner class="mx-0 my-0">
                            <v-icon class="iconstyle">mdi-phone</v-icon>
                          </template>
                        </v-text-field>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-flex>

                <!-- back and proceed  code-->
                <v-flex justify="center" class="mt-5">
                  <v-row class="btnDiv">
                    <v-col align="center">
                      <v-btn color="#23B1A9" dark class="buttonscolor mr-5" type="submit">
                        <v-icon class="mr-2 iconfontsize">mdi-arrow-right-circle</v-icon>
                        <div class="vertical_line"></div>Proceed
                      </v-btn>
                      <v-btn
                        color="#E46A25"
                        dark
                        class="buttonscolor mr-5"
                        @click="openAddressDailogbox = false">
                        <v-icon class="mr-2 iconfontsize" >mdi-close-circle</v-icon>
                        <div class="vertical_line"></div>Close
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-flex>
              </v-form>
            </v-card-text>
          </v-card-title>
        </v-card>
      </v-dialog>
    </v-row>

    <!-- lR table Dailogbix -->
    <v-row justify="center">
      <v-dialog v-model="LRDetailsTableMenu" persistent max-width="48%">
        <v-card>
          <v-card-title  class="pl-0 px-0 py-0 dailogboxAllvcardTableBackground">
            <v-row>
              <span class="descrptionSpan mb-5">LR/AWB/BL Details</span>
              <v-icon @click="LRDetailsTableMenu = false" class="ml-0">mdi-window-close</v-icon>
            </v-row>
            <v-card-text class="vcardTextTableBackground" >
              <v-simple-table
                class="ml-6 mt-7 mb-6 lrInvoictableWidth" >
                <template v-slot:default>
                  <thead class="vlrtableBackground">
                    <tr>                    
                      <th class="lr1th" >Sr.No</th>
                      <th class="vlratbletd lr2th" >LR/AWB/BL Number<span v-if="typeofClaimCheck" class="mandatorycolor"> *</span></th>
                      <th class="vlratbletd lr3th">LR/AWB/BL Date<span v-if="typeofClaimCheck" class="mandatorycolor"> *</span></th>
                      <th class="vlratbletd pa-0">
                        <v-btn
                          color="#D1121B"
                          dark
                           :disabled="disableOnCS"
                          @click="deleteLRRowAll()"
                          class="lrDelete">
                          <v-icon class="mr-2 deleteAllIconTh">mdi-trash-can-outline</v-icon>
                          <!-- <div class="mr-2 vertical_line"></div>All -->
                        </v-btn>
                      </th>
                    </tr>
                  </thead>
                  <tbody class="TableScroll">
                    <tr v-for="(claim, index) in claimDetails.awbNumberAndDate" :key="index">
                      <td class="lr1td">{{ index + 1 }}</td>
                      <!-- spclChar(), -->
                      <td class="invoiceDatatd lr2td">
                        <v-text-field
                          placeholder="A3243SD23"
                          v-model="claim.number"
                          :disabled="disableOnCS"
                          :rules="[tocheckMandatory()]"
                          :maxLength="100"
                          dense
                          @change="checkDuplicateLRAWBEntry(index)"
                          @blur="checkDuplicateLRAWBEntry(index)"
                         ></v-text-field>
                      </td>
                      <td class="invoiceDatatd lr3td">
                        <v-menu
                          v-model="claim.lRAWBBLdatemenu"
                          :close-on-content-click="false"
                          offset-y
                          min-width="auto">
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              placeholder="dd/mm/yyyy"
                              dense
                              v-model="claim.date"
                              v-on="on"
                              v-bind="attrs"
                              :disabled="disableOnCS"
                              :rules="[tocheckMandatory(),checklossdatedifference(claim.date)]"
                               append-icon="mdi-calendar"
                               readonly>
                            </v-text-field>
                          </template>
                          <v-date-picker
                            :max="todaydatevalue"
                            v-model="lRAWBBLdate"
                            no-title
                            @change="checkDuplicateLRAWBEntry(index,computedlRAWBBLdate)"
                            @input="claim.lRAWBBLdatemenu = false">
                          </v-date-picker>
                        </v-menu>
                      </td>
                      <td>
                         <v-icon class="mr-2 deleteIconTd"  :disabled="disableOnCS" @click="deleteLRRow(index)">mdi-trash-can-outline</v-icon>
                      </td> 
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <v-flex justify="center" class="mt-5">
                <v-row class="btnDiv">
                  <v-col align="center" >
                      <v-btn color="#E46A25" dark class="buttonscolor mr-5" :disabled="disableOnCS"  @click="saveLRTable()">
                      <v-icon class="mr-2 iconfontsize"  >mdi-checkbox-marked-circle</v-icon>
                      <div class="vertical_line"></div>Save
                    </v-btn>
                    <v-btn color="#152F38" dark class="buttonscolor mr-5" :disabled="disableOnCS"  @click="addNewLRRow">
                      <v-icon class="mr-2 iconfontsize"  >mdi-plus</v-icon>
                      <div class="vertical_line"></div>Add
                    </v-btn>
                    <!-- <v-btn color="#23B1A9" dark class="buttonscolor mr-5" @click="ProccedLRTable">
                      <v-icon class="mr-2" style="font-size: 16px" >mdi-arrow-right-circle</v-icon>
                      <div class="vertical_line"></div>Proceed
                    </v-btn> -->
                  </v-col>
                </v-row>
              </v-flex>
            </v-card-text>
          </v-card-title>
        </v-card>
      </v-dialog>
    </v-row>
    <!-- Invoice Details Dailogbix -->
    <v-row justify="center">
      <v-dialog v-model="invoiceDetailsTableMenu" persistent max-width="53%">
        <v-card>
          <v-card-title class="pl-0 px-0 py-0 dailogboxAllvcardTableBackground">
            <v-row>
              <span class="descrptionSpan mb-5">Invoice Details</span>
              <v-icon @click="invoiceDetailsTableMenu = false" class="ml-0">mdi-window-close</v-icon>
            </v-row>
            <v-card-text class="vcardTextTableBackground">
              <v-simple-table class="ml-6 mt-7 mb-6 lrInvoictableWidth">
                <template v-slot:default>
                  <thead class="vlrtableBackground">
                    <tr>
                      <th  class="invoic1th" >Sr.No</th>
                      <th class="vlratbletd invoic2th">Invoice Number</th>
                      <th class="vlratbletd invoic3th">Invoice Date</th>
                      <th class="vlratbletd invoic4th">Invoice Amount</th>
                      <th class="vlratbletd pa-0 invoic5th">
                        <v-btn
                          color="#D1121B"
                          dark
                          @click="deleteInvoiceAll()"
                          class="invoiceDelete">
                          <v-icon class="mr-2 deleteAllIconInvoic">mdi-trash-can-outline</v-icon>
                          <!-- <div class="mr-2 vertical_line"></div>All -->
                        </v-btn>
                      </th>
                    </tr>
                  </thead>
                  <tbody class="TableScroll">
                    <tr v-for="(invoice, index) in claimDetails.invoice" :key="index">
                      <td class="invoiceDatatd invoic1td">{{ index + 1 }}</td>
                      <td class="invoiceDatatd">
                        <v-text-field
                          placeholder="248Q337H"
                          dense
                          v-model="invoice.number"
                          :maxLength="100"
                          :disabled="disableOnClosed"
                          @change="checkDuplicateInvoice(index)"
                        ></v-text-field>
                      </td>
                      <td class="invoiceDatatd">
                        <v-menu
                          v-model="invoice.invoiceDatemenu"
                          :close-on-content-click="false"
                          offset-y
                          min-width="auto">
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="invoice.date"
                              placeholder="dd/mm/yyyy"
                              v-bind="attrs"
                              dense
                              v-on="on"
                              id="invoiceDate"
                              append-icon="mdi-calendar"
                              :disabled="disableOnClosed"
                              readonly>
                            </v-text-field>
                          </template>
                          <v-date-picker
                           :max="todaydatevalue"
                            v-model="invoiceDate"
                            no-title
                            @change="checkDuplicateInvoice(index,computedinvoiceDate)"
                            @input="invoice.invoiceDatemenu = false">
                          </v-date-picker>
                        </v-menu>
                      </td>
                      <td class="invoiceDatatd">
                        <v-text-field
                          :maxLength="15"
                          placeholder="50,000" 
                          v-model="invoice.amount"   
                          dense
                          :disabled="disableOnClosed"
                          @change="checkDuplicateInvoice(index)"
                        ></v-text-field>
                      </td>
                        <td class="invoiceDatatd">
                        <v-icon class="mr-2 deleteIconInvoic" @click="deleteInvoice(index)">mdi-trash-can-outline</v-icon>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <v-flex justify="center" class="mt-5">
                <v-row class="btnDiv">
                  <v-col align="center">
                     <v-btn color="#E46A25" dark class="buttonscolor mr-5"  @click="saveInvoicetable()">
                      <v-icon class="mr-2 iconfontsize"  >mdi-checkbox-marked-circle</v-icon>
                      <div class="vertical_line"></div>Save
                    </v-btn>
                    <v-btn color="#152F38" dark class="buttonscolor mr-5"  @click="addInvoiceRow">
                      <v-icon class="mr-2 iconfontsize"  >mdi-plus</v-icon>
                      <div class="vertical_line"></div>Add
                    </v-btn>
                    <!-- <v-btn color="#23B1A9" dark class="buttonscolor mr-5" @click="proceedInvoice">
                      <v-icon class="mr-2" style="font-size: 18px" >mdi-arrow-right-circle</v-icon>
                      <div class="vertical_line"></div>Proceed
                    </v-btn> -->
                  </v-col>
                </v-row>
              </v-flex>
            </v-card-text>
          </v-card-title>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>
    
<script>

// import validations from './../constants/validations';
import { required, numeric, alpha, alphaNum, helpers,email } from 'vuelidate/lib/validators';
import moment from 'moment'
import axios from 'axios';

export default {
      validations:{
          policyNumber: {required,alphaNum}
      },

      data() {
        return {
          //to show floating btns
            direction: 'left',//direction to show list
            fab: false,//to close and open
            tabs: null,
            right: true,// direction to show icon for open and close
            transition: 'slide-y',
            top: false,
            bottom: true,
            left: false,
            submitDisable:false,
            SummerButtonsFirstrowTexts: [
                "Co-Insurance Summary",
                "Document Checklist",
                "Transaction History",
                "Feature Activity",
                "Claim Summary",   
                "RI Summary",    
                "Audit Trail",
                "Litigation",
                "Red Flag",
                "Referral",
                "Notes",
                "Query",
                "CFR"
            ],
          //end

          // FNOL new Code start
          invoiceFieldValidation:'',
          LRAWSBLDetailsValidation:'',
          addressFieldValidation:'',
          insuredMobileISD:'91',
          tempIsdCode:'',
          isdMax: 5,
          invoiceDeleteCheckbox: false,
          lrDeleteCheckbox: false,
          LRDetailsTableMenu: false,
          invoiceDetailsTableMenu: false,
          openAddressDailogbox: false,
          policyDetailsMenu: true,
          claimIntimationDetailsMenu: true,
          CorrespondingDetailsMenu: true,
          transitDetailsMenu: true,
          lossReserveDetailsMenu: true,
          InsuredcontactDetailsMenu:true,
          allClaimsNumberArray:[],
          SearchAllClaimNumbers:'',
          // FNOL new Code end
          policyEndDate:"",
          classNameDialog:"errorDailogboxMsg",
          validationmessage:'This field is required',
          message:'',
          testvalue:'',
          Datepattern: /^(((0|1)[0-9]|2[0-9]|3[0-1])\/(0[1-9]|1[0-2])\/((19|20)\d\d))$/,

         // claims:["New Claim", "Feature Claim", "Service Claim"],

       claims:[{name:"New Claim",selected:false}, {name:"Feature Claim",selected:false}, {name:"Service Claim",selected:false}],

          // productnames:["Open Policy Marine Cargo", "Marine Specific Policy","Marine Advanced Loss Of Profit",
          // "Marine Freight Forward Liability", "Marine Liabilities Policy", "Marine Tea Crop Policy"],

          LOB:["Marine Cargo"],
          
          callerIntimatorIdentification:["Insured", "Consignee", "Consigner", "Broker", "Agent", "Bank", "Transporter"],
          
          claimIntimatedThrough:["Telephone", "Email", "SMS", "Post/Courier", "Walk in", "Online"],

          callerRelationshipwithInsured:["Broker", "Agent", "Employee", "Other"],

          claimCategory:["Local Claim", "Export Claim"],

          typeOfClaim:["Transit", "Storage"],

          claimStatus:["Suspended", "Open", "Closed", "Cancelled"],

          reasonForSuspense:["Policy number not available/not found", "RI Distribution Missing", "Cancelled Policy",
                            "Date of loss not covered", "Missing Coverage"],

          isInsuredTheClaimant:[{"text": "Yes", "value": true},{"text":"No","value":false}],      
        
           claimDetails: {
              claimType:'New Claim',
              
              policyNumber : '',
              dateOfLoss : '',//date ISOformat
              lossTime : '', //time

              // service claim values
              
              policyIssuingCompany:'',
              policyIssuingCountry:'',
              policyInceptionDate:'',
              policyExpiryDate:'',
              addressOfSurvey:'',
              carrierName:'',
              
           // service claim values end
              insuredName : '',
              insuredID : '',
              certificateNumber : '',
              applicationNumber : '',
              productName : '',
              lob:'',
              eMarinePolicyNumber:'',

              claimCategory : 'Local Claim',
              typeOfClaim : '',
              sumInsured:'',
              reserveAmount:'10000',//Surendra(17-04-2021):Uncommented for Shubham(Backend) to map value in GC
              customerClaimNumber:'',
              callerIntimatorIdentification:'',
              claimIntimatedThrough:'',
              callerName : '',
              callerRelationshipwithInsured : '',
              pleaseSpecify : '',
              withoutpolicyno: false,

               callerMobileNo: {
                      isd:'91',
                      number:''
                      },
              callerEmailID : [],
              insuredMobileNumber : [],
              insuredEmailID : '',
              emailIdForCommunication : [],
              reportedDate : moment().format('YYYY-MM-DD'),//date
              reportedTime : moment().format('HH:mm'),//time
              dateDifference : '',//date
              addressOfLossLocation : '',
              pinCodeOfLossLocation : '',
              
              //table values //"number":"1","date":""
              awbNumberAndDate:[{"number":"","date":""}],
              invoice:[{"number":"","date":"","amount":""}],

              nameOfContactPersonAtLossLocation : '',
              contactNumberAtLossLocation :{
                                          isd:'91',
                                          number:''
                                        },
              lossDescription : '',
              propertyDamaged : '',
              coverageDescription : '',
              coverageCode : '',
              estimatedLossAmountInINR : '',
              storageDescription : '',
              storageCode : '',
              unnamedStorageLocation : '',

              transitTo: "",
              transitFrom: "",
                          
              transporterVesselName : '',
              transitToCountry : '',
              transitToState : '',
              transitToCity:'',

              transitFromCountry : '',
              transitFromState : '',
              transitFromCity : '',

              isInsuredTheClaimant : false,
              claimant : {
                name:'',
                addressLineOne:'',
                addressLineTwo:'',
                addressLineThree:'',
                country:'',
                state:'',
                otherState:'',
                city:'',
                cityOtherName:'',
                district:'',
                pincode:'',
                mobileNo: {
                    isd:'91',
                    number:''
                  },
                contactNo: {
                  isd:'91',
                  number:''
                },
                emailID:''
              },
              internationalLocation : false,
              locationLandmark : '',

              country : '',
              city : '',
              aigSettlingCompany : '',
              aigCountry : '',
              aigClaimNumber : '',
              isEventACatastrophe : false,
              catastropheDescription : '',
              catastropheCode : '',
              catastropheDetail : '',
              catastropheType : '',

              claimStatus:'Suspended',
              reasonForSuspense:'',

              isTataEmp : false,
              remarks : '',
              notes : '',
              detailsOne: '',
              detailsTwo: '',
              detailsThree : '',
              detailsFour : '',

              proposalNo: '',
              policyStartDate:'',
            //  mainClaimNumber:''
              // mainClaimNumber:''
              // policyStartDt: '',
              // policyEndDt: '',
              // businessType: '',
              // lrNo: '',
              // activeFlag: '',
              // claimSubStatus: ''
              transitFromCityOtherName:'',
              transitToCityOtherName:'',
              otherCityName:'',
              featureClaimType:'',
              coverGroupIndex:'',//Surendra(08-04-2021):Value added for Krishna
              riskSerialNumber:'',//Surendra(17-04-2021):Value added for Shubham
              perilName:''//Surendra(20-04-2021):Value added for Shubham
           },

          //arrays for response data ---getmethods
          policyCompanyDetails:[],
          policyCountryDetails:[],

          Countries:[],
          InternationalCountries:[],
          fromStatesOptions:[],
          toStatesOptions:[],
          claimantStates:[],
          claimantCities:[],
          Cities:[],
          transitFromCities:[],
          transitToCities:[],
          Pincodes:[],
          lossLocationPincodes:[],
          AIGDetails:[],
          AigOriginalDetails:[],
          catastropheDesDetails:[],
          lossDescription:[],
          Coverage:[],
          coverageOptions:[],
          productnames:[],
          policyClaimantDetails:{
            addressLine1: '',
            addressLine2:'',
            addressLine3 :'',
            cityDistrict: '',
            state :'',
            pincode:'',
            countryname:'',
            mobileNumber:'',
            emailId:''
          },

          reserveAmount:'10000',
          claimNumber : '',
          featureNumber : '',
          primaryClaimNumber : '',
          insuredMobileNumber:'',
          callerEmailID:'',
          emailIdForCommunication:'',

          tempMobileNumber:'',
          TempemailIdForCommunication:'',
          TempcallerEmailID:'',
          
          
          reqData:'',
          additionaldetailsArr:[{"index":0}],
          todaydatevalue:'',
          currentTime:'',
          counter:1,

          //checkbox values
        
          row:null,
          disable:false,
          AIGdisable:true,
          aigClaimtrue: false, //to show star (as mandatory)
          storage:false,
          certificatenoDisable:true,
          certificateNo :false,
          coverageCodeDisable:false,
          // disableOnClaimStatus:false,
          // disableOnCSCT:false,
          // enableOnCS: false,

          //modals, menus and dialog boxes
          modal: false,
          lossTimemenu: false,
          lossTime:null,

          reportedTime:null,
          reportedTimemenu:false,

          dateOfLossmenu: false,
          dateOfLoss:null,
          // computedDateofloss:'',
      
          reportedDatemenu:false,
          reportedDate:null,//moment().format('DD/MM/YYYY'),

          lRAWBBLdatemenu:false,
          lRAWBBLdate:null,

          expiryDatemenu:false,
          incepDatemenu:false,
          inceptionDate:null,
          expiryDate:null,

          disableInceptionDate:null,

          invoiceDatemenu:false,
          invoiceDate : null,
          searchPincode:'',//pincodes
          search:'',
          searchAIGOffice:'',
          searchCompany:'',
          searchTransitFromCity:'',
          searchTransitToCity:'',
          searchInternationCity:'',
          searchClaimantCity:'',
          // itemspin:[],
          pitems:[],

          addInsureddialog:false,
          addEmaildialog:false,
          addcallerEmaildialog:false,
          dialogbox:false,
          dialogBoxText:'',
          storageDialog:false,
          snackbar:false,
          validationbox:false,
          savedialogbox:false,
          deleteDialogConfirm:false,
          dialogboxError:false,
          // mandatory 
          mandatory:false,
          callertrue:false,
          location:false,
          productrue:false,
          catastrophe:false,
          policynotrue:true,
          disablepolicy:false,
          
          checkRelation:true,
          otherelation:false,
          onInternationalocation:false,
          Policynocheck:false,
          pincodeReqSent:false,
          pincodeReq:false,

          // to show (*) for mandatory values 
          callername:false,
          callermobile:false,
          calleremail:false,
          productName:false,
          showinsuredID:true,

          coverageDescrptionMenu: false,
          storageDescrptionMenu:false,
          coverageIndex: "",
          storageIndex:"",
          storageDescrptionOptions: [],
          coverageDescrptionOptions: [],
          isPolicyLoaded:false,
          addothertranstiFromCity:false,
          addothertranstiToCity:false,
          IsDisabledafterpincode:false,
          max15 : 15,
          AIGmaxlen:100,
          savelrflag:false,
          unNamedStorage   :   {
              "storageLocationCode" :	"333333",
              "storageDescription" :	"Un-named",
              "cityORDistrict" :	"Un-named",
              "state" :	"Un-named",
              "country" :	"Un-named",
              "pinCode" :	"999999"
            },
            panIndiaStorage   : {
                "storageLocationCode" :	"666666",
                "storageDescription" :	"Pan India",
                "cityORDistrict" :	"Pan India",
                "state" : 	"Pan India",
                "country" :	"Pan India",
                "pinCode" :	"111111"
            },
            reportederrormsg:'',
          rules:{
              insuredName:[
                v => !!v || this.validationmessage,
                // v => v && !!v.trim() || 'Only spaces are not allowed',
                v => (v && v.length <= 140) || 'Insured Name should be less than 140 characters.',
                v => /^(?=[A-Za-z0-9./',\\-\h])([A-Za-z0-9.'/,\\-\s\h]*)(?<=[A-Za-z0-9.'/,\\-\h])$/.test(v) || 'Insured Name is not valid'//Surendra(18-03-2021):Name was allowing special char MC-558//Surendra(01-04):Special characters allowed in start and end too
                
              ],
              
              validate:[
                v => !!v || this.validationmessage,
                v => /^[a-zA-Z0-9]+$/.test(v) || 'It is not valid',
              ],

              eMarinePolicyNumber:[
                v => (v && v.length <= 20) || 'It should be less than 20 characters.',
                v => /^[a-zA-Z0-9]+$/.test(v) || 'It is not valid',
              ],

              // mobilerules:[
              //   v => (v && /(^[0-9]+$)/.test(v)) || 'Only Numbers are allowed',
              //   // v => (v && v.length == 10) || 'Mobile Number should be 10 digits.',
              // ],

              emailRules: [
                  v => !!v || 'E-mail is required',
                  v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,6})+$/.test(v) || 'E-mail must be valid'
              ],

              multiplemobilerules:[
                v => !!v || this.validationmessage,
                v => /^(\d{10},)*\d{10}$/.test(v)  || 'Mobile Numbers is not valid',
                v => (v && v.length < 50)  || 'Max Length should be 50 digits'
              ],
              
              dateofLossRules:[
                (v) => !!v || this.validationmessage,
                (v) => this.Datepattern.test(v) || "Enter valid date format as DD/MM/YYYY.",
                (v) => !!this.pastDateCheck(v) || "Future dates not allowed."
              ],

              dateRule:[
                (v) => !!v || this.validationmessage,
                // (v) => this.Datepattern.test(v) || "Enter valid date format as DD/MM/YYYY.",
              ],

              invoiceDaterule:[
                (v) => this.Datepattern.test(v) || "Enter valid date format as DD/MM/YYYY.",
              ],

              expiryDateRule:[
                (v) => !!v || this.validationmessage,
                (v) => !!this.futureDateCheck(v) || "Expiry date should be greater than Inception Date"
              ],

              invoiceAmtRule:[
                (v) => /^\S[0-9a-zA-Z , . _ - @ %]+\S$/.test(v) || 'This field is not valid'
              ],

              reportedDateRules:[
                (v) => !!v || this.validationmessage,
                (v) => this.Datepattern.test(v) || "Enter valid date format as DD/MM/YYYY.",
                (v) => !!this.pastDateCheck(v) || "Future dates not allowed.",
                (v) => !!this.reportedDateCheck(v) || "Reported date should more than loss date.",
               // (v) =>  (this.dateOfLoss ? (new Date(this.dateOfLoss) <= new Date(v) ||  new Date(this.dateOfLoss) == new Date(v)  ): '') || "Reported date should more than loss date."
              ],
                          
          },          
          
          minLength(minLength){
            return v=>v && v.length>=minLength || `Must be atleast ${minLength} Characters`
          },
          
          spclChar(){
            return v=>/^[A-Za-z0-9.!#$%&'*+/=?^_`{|}~-]+$/.test(v) || `Special Characters Allowed`
          },

          mandatoryrule(){
            // console.log('-------',v)
           return v => !!v || this.validationmessage
            
          },
        
          mandatoryOtherCity(){
            if(this.claimDetails.city=='OTHER'){
               return v => !!v || 'This field is requred'
            }
            else{
             return true;
            }

          },
          mandatoryfromcity(){
               if(this.claimDetails.transitFromCity=="OTHER"){
                  return v => !!v || 'This field is requred'
                }
                else{
                 return true;
               }
          },

           mandatorytocity(){
               if(this.claimDetails.transitToCity=="OTHER"){
                  return v => !!v || 'This field is requred'
               }
               else{
                 return true;
               }
          },
          mandatorylocationcountry(){
              if(this.claimDetails.internationalLocation==true){
                 return v => !!v || 'This field is requred'
              }
              else{
                return true;
              }
          },
          mandatoryclaimantcity(){
               if(this.claimDetails.claimant.city=="OTHER"){
                  return v => !!v || 'This field is requred'
               }else{
                 return true;
               }
          },
          mandatoryclaimantstate(){
              if(this.claimDetails.claimant.state=="OTHER"){
                  return v => !!v || 'This field is requred'
               }else{
                 return true;
               }
          },
            mandatorypincode(){
            if(this.claimDetails.claimant.country=='INDIA'){
               return v => !!v || this.validationmessage
            }
            else{
              return true
            }
              
          },
          mandatoryruleLRTable(){
                if (this.claimDetails.typeOfClaim == 'Storage' ){
                   return true;//MC-941
                }else if(this.LRAWSBLDetailsValidation=='' && this.productrue==true){
                  return v => !!v || 'Please enter all mandatory fields'
                }else if(this.claimDetails.productName == "MarineFreightForwardLiability"){
                   return true;
                }else{
                  return true;
                }
          },
          mandatoryruleAddressTable(){
              if(this.addressFieldValidation==''){
                  return v => !!v || 'Please enter all mandatory fields'
                }else{
                  return true;
                }

          },
          requiredRule(v){
              return v=>(!!v || v !== false) || this.validationmessage
          }    
          
        }
      },

      computed:{
          enableFeatureClaimRPDateTime(){///MC-937
            if(this.claimDetails.mainClaimNumber && this.claimDetails.claimStatus == "Open" && this.claimDetails.claimType == "Feature Claim" && this.claimDetails.featureNumber =="A"){
              return false;
            } else if ((this.claimDetails.mainClaimNumber && this.claimDetails.claimStatus == "Open" && this.claimDetails.claimType !== "Feature Claim" ) || this.claimDetails.claimType == "Feature Claim"){
                return true
            }else if(this.claimDetails.mainClaimNumber && this.claimDetails.claimStatus == "Suspended"){
               return true;//MC-937
            }
            else{
              return false;
            }
            },
        typeofClaimCheck(){
           return this.claimDetails.typeOfClaim == 'Storage' || this.claimDetails.productName == "MarineFreightForwardLiability" ? false : true ; //MC-941
         },
       disableOnOpen(){
            return (this.claimDetails.mainClaimNumber && this.claimDetails.claimStatus == "Open") ? true : false;
            //  this is disabled on MC-873
        },
        newClaimStorage(){
          if (this.claimDetails.claimType =='New Claim' && this.claimDetails.typeOfClaim =="Storage"  && this.claimDetails.withoutpolicyno == false){
              return true;// tcket number Mc-831 storage cover is coming as lower case in perilName varibale
          }else{
             return false;
          }
        },

        // to show star mark on calleremail(ticket id:835)
        callerEmailMandate(){
          return ((this.claimDetails.callerIntimatorIdentification !== "Insured" && this.claimDetails.callerIntimatorIdentification !== "") && 
              (this.claimDetails.claimIntimatedThrough == 'Email' || this.claimDetails.claimIntimatedThrough == 'Online') && this.claimDetails.claimIntimatedThrough !== '')
              ? true : false;
        },

        // Fnol new page code start
        showHideServiceClaimFields(){
         return (this.claimDetails.claimType =='Service Claim'|| this.claimDetails.featureClaimType=="Service Claim") ? true:false;
        },

        disableClaimNumber(){
          // && this.claimDetails.isTempClaim==false
         // return this.claimDetails.mainClaimNumber==undefined && this.claimDetails.claimType == "Feature Claim" ?  false: true ;
           return (this.claimDetails.isTempClaim==undefined && this.claimDetails.mainClaimNumber==undefined ) && this.claimDetails.claimType == "Feature Claim" ?  false: true ;
        },

        CreateFeatureClaimButton(){
          if((this.claimDetails.claimType =='New Claim' || this.claimDetails.claimType =='Service Claim') && (this.claimDetails.mainClaimNumber!==undefined && this.claimDetails.mainClaimNumber!=='') && this.claimDetails.claimStatus=="Open" ){
             return true;
          }else{
             return false;
          }
        },

        ShowreportedDateTime(){
          let dateTime= moment(this.claimDetails.reportedDate).format('DD/MM/YYYY')+" "+ this.claimDetails.reportedTime;
          return dateTime;
        },
        //  Fnol new page code end
        disableOnClosed(){
          return this.claimDetails.claimStatus == "Closed" ? true : false;
        },
        disableOnFeatureClaim(){
          return this.claimDetails.claimType == "Feature Claim" ? true : false;
        },

        enableOnFeatureClaim(){
          if(this.claimDetails.claimType == "Feature Claim"){
              return false;
          }else if(this.claimDetails.claimType == "New Claim"){
             return false;
          } else if (this.claimDetails.mainClaimNumber && this.claimDetails.claimStatus == "Open" && this.claimDetails.claimType !== "Feature Claim"){
              return true
          }else{
            return true;
          }
        },

        disablePolicyField(){ //(this.claimDetails.claimType == "New Claim" && this.claimDetails.withoutpolicyno == true)(this.claimDetails.claimType == "Service Claim" && this.claimDetails.withoutpolicyno == true)) 
          return (this.claimDetails.claimType == "Feature Claim") ? true : false;
        },

        toDisableolicyFetchData(){
          if(this.claimDetails.mainClaimNumber && this.claimDetails.claimStatus == "Open"){
           return true;
          }else if(this.claimDetails.withoutpolicyno == true){
              return true;
          }else if( this.claimDetails.lossTime !=="" && this.computedDateofloss !=="" && this.claimDetails.policyNumber !=="" ){
              return false;
          }else{
              return true;
          }
        },

        policyDeatilsDisable(){
          if(this.claimDetails.mainClaimNumber && this.claimDetails.claimStatus == "Open"){
              return false;
          } else if( this.claimDetails.lossTime !=="" && this.computedDateofloss !=="" && this.claimDetails.policyNumber !=="" && this.isPolicyLoaded){
              return false;
          } 
          else{
              return true;
          }
				},
        toDisablePolicySearch(){
          if(this.claimDetails.mainClaimNumber && this.claimDetails.claimStatus == "Open"){
           return true;
          }else if(this.claimDetails.withoutpolicyno == true){
            return true;
          }else{
            return false;
          }
      },
        hideClaimNumber(){
          if(this.claimDetails.mainClaimNumber !==undefined){
             return true;
          }else if(this.claimDetails.mainClaimNumber==undefined && this.claimDetails.claimType == "Feature Claim"){
            return true;
          }else{
            return  false;
          }
        },

        hideshow(){
          if(this.claimDetails.mainClaimNumber!=undefined){
             return this.claimDetails.claimType == "Feature Claim" ? false : true
          }
        },

        hideshowFN(){ 
           return this.claimDetails.mainClaimNumber && this.claimDetails.featureNumber ?  true : false ;
        },
        
        // certificatenoDisable(){
        //    return this.claimDetails.policyNumber == "" || this.claimDetails.claimType == "Feature Claim" ? true : false
        // },
        disableOnCSCT(){
            return (this.claimDetails.mainClaimNumber && this.claimDetails.claimStatus == "Open" && this.claimDetails.claimType !== "Feature Claim" ) || this.claimDetails.claimType == "Feature Claim" ? true : false;
        },

        disableOnCS(){
          return (this.claimDetails.mainClaimNumber && this.claimDetails.claimStatus == "Open") ? true : false;
        }, 
        
        enableOnCS(){
          return (this.claimDetails.mainClaimNumber && ((this.claimStatus.claimStatus !== "Open" || this.claimStatus.claimStatus !== "Suspended") && (this.claimDetails.claimType == "New Claim"))) ? false : true;
        },

        disableonBoth(){
            return (this.claimDetails.mainClaimNumber && (this.claimDetails.claimStatus == "Open" && this.claimDetails.claimType == "New Claim"))|| (this.claimDetails.claimType == "Feature Claim" || this.claimDetails.callerIntimatorIdentification == "Insured") ? true : false;
        },   

        disableOnOpSu(){
          return (this.claimDetails.mainClaimNumber && ((this.claimStatus.claimStatus !== "Open" || this.claimStatus.claimStatus !== "Suspended") && (this.claimDetails.claimType == "New Claim"))) ? true : false;
        },        

        // disableOnfeatureclaim(){
        //   return this.claimDetails.claimType == "Feature Claim" ? true : false;
        // },
        disableCatastropheDescription(){
           if(this.claimDetails.isEventACatastrophe==false || this.claimDetails.claimType == "Feature Claim"){
             return true;
           }else{
             return false;
           }
        },

        disableOnfeatureclaimandEventcheck(){
           if(this.claimDetails.isEventACatastrophe==false || this.claimDetails.claimType == "Feature Claim" || this.claimDetails.catastropheDescription){
             return true;
           }else{
             return false;
           }
        },

        checkProductname(){
          if(this.claimDetails.claimType !== 'Service Claim'){
            return this.claimDetails.productName == "MarineFreightForwardLiability" ? false : true;
          }else {
            return true;
          }
        },

        checkTypeofClaim(){
            return (this.claimDetails.claimType == "Service Claim" || this.claimDetails.productName == "MarineFreightForwardLiability" || this.claimDetails.featureClaimType == "Service Claim") ? false : true;
        },

        checklength(){
          return this.insuredMobileNumber.length == 0 ? false : true;
        },

        checkEmailLength(){
          return this.emailIdForCommunication.length == 0 ? false : true;
        },

        computedDateofloss(){
          return this.dateOfLoss == null ? '' : moment(this.dateOfLoss).format('DD/MM/YYYY');
        },

        computedreportedDate(){
          return this.reportedDate == null ? '' : moment(this.reportedDate).format('DD/MM/YYYY');
        },

        computedinvoiceDate(){
          return this.invoiceDate == null ? '' : moment(this.invoiceDate).format('DD/MM/YYYY');
        },

        computedlRAWBBLdate(){
          return this.lRAWBBLdate == null ? '' : moment(this.lRAWBBLdate).format('DD/MM/YYYY');
        },

        computedInceptionDate(){
          return this.inceptionDate == null ? '' : moment(this.inceptionDate).format('DD/MM/YYYY');
        },

        computedExpiryDate(){
          return this.expiryDate == null ? '' : moment(this.expiryDate).format('DD/MM/YYYY');
        },
        
        policyNoErrors(){
            const errors = []
            
            if (!this.$v.claimNumber.policyNumber.$dirty) return errors
            !this.$v.claimNumber.policyNumber.required && errors.push('This field is required');
            !this.$v.claimNumber.policyNumber.alphaNum && errors.push('Policy Number should be AlphaNumeric');
            
            return errors;
        },

        disableStorageCode(){
          //Surendra(02-03-2021):Disable storage Code
          if(this.claimDetails.typeOfClaim == "Transit"){
            return true;
          }else if(this.claimDetails.coverageDescription.toLowerCase()== "storage cover"){
              return false
          }else{
              return false
          }
          // return (this.claimDetails.typeOfClaim == "Transit") ? true : false;
        },

        disableUnnamedStorageLocation(){
          //Surendra(02-03-2021):Disable storage Code
          return (this.claimDetails.storageDescription == "Transit Claim") ? true : false;
        },

        itemspin(){
            return this.pitems
        },
        disableCatastrophe(){
          return !this.dateOfLoss ? true : false;
        },
        disableForTransitClaim(){
          //Surendra(06-04-2021):MC756-"even in without policy, when type of claim is selected as transit, then the storage fields should be greyed out
          return this.claimDetails.typeOfClaim  ==  "Transit" ? true : false;
        },
        maxOnDateOfLoss(){
          console.log("Max on Date of loss",this.dateOfLoss ? this.dateOfLoss : new Date().toISOString())
          return  new Date().toISOString();
        },
        minOnDateOfLoss(){
          console.log("Max on Date of loss",this.dateOfLoss ? this.dateOfLoss : new Date().toISOString())
          return this.dateOfLoss ? this.dateOfLoss : new Date().toISOString();
        },
        disableUntillLossDateSelected(){
          return this.dateofLoss ? true : false;
        },
        disableUntillLossTimeSelected(){
          return this.lossTime ? true : false;
        },
        disableForStorageWithPolicy(){
          //Surendra(14-04-2021):MC826-"for with policy cases, if the type of claim is storage, the storage code shouldnt be editable
          return this.isPolicyLoaded && this.claimDetails.typeOfClaim  ==  "Storage" ? true : false;
        },
        
      },

      methods: {
            reportedDateCheck(RD){
              if(this.dateOfLoss){
                let lossDMY= moment(this.dateOfLoss).format('DD/MM/YYYY');
                let lossDate = moment(lossDMY, "DD/MM/YYYY").toDate().getTime();
                let ReportedDate=moment(RD, "DD/MM/YYYY").toDate().getTime();
                if(this.reportedDate == moment().format('YYYY-MM-DD')){
                  return true;
                }else if(new Date(lossDate).setHours(0, 0, 0, 0) <= new Date(ReportedDate).setHours(0, 0, 0, 0)){
                  return true;
                }else{
                  return false;
                }
               }else{
                return true;
              }
            },
            openNewPagesRow(buttonText){
                if (buttonText == "Transaction History") {
                  this.$router.push("/transhistory");
              }
            },

            deleteAllConfirm(){
             if(this.deleteAllconfirmName=='invoice'){
              this.claimDetails.invoice=[]; 
             }else{
                this.claimDetails.awbNumberAndDate =  [];
	              this.LRAWSBLDetailsValidation      =  "";
             }
             this.deleteDialogConfirm=false;
            },

            showAlrt(){
               this.showAlert(" If estimated loss is not known please provide invoice value.")

              //  this.$fire({
              //   title: 'First Notice Of Loss',
              //   html: "No bearing on final claim amount <br /> If estimated loss is not known please provide invoice value.",
              //   confirmButtonColor: "#23b1a9",
                
              // })
            },

            invoiceAmtRule(v){
              let exp = /^\S[0-9a-zA-Z , . _ - @ %]+$/
              if(v && !(exp.test(v))){
                return 'This field is not valid'
              }else{
                return true
              }
            },

            // FNOL new Code start
            getSingleClaimDeatils(mainClaimNumber){
              if(mainClaimNumber){
                  const indexItem =this.allClaimsNumberArray.find((elem)=>{
                  return elem.mainClaimNumber == mainClaimNumber;
              })
              if(indexItem){
                  // if(this.claimDetails.claimType=="New Claim"){
                  // this.claimDetails.featureClaimType=this.claimDetails.claimType;
                  // }else{
                  // this.claimDetails.featureClaimType=this.claimDetails.claimType;
                  // }
                 // let URl = this.getURL("FETCH_CLAIM_DETAILS") + indexItem._id;
                   let selected={claimType:"New Claim",claimId: indexItem._id}
                  this.$store.commit("SAVE_LANDINGPAGE_ID", {data: selected});
                  this.$store.commit("FNOL_PAGE_EDIT_CHECK", "FnolEditPage");
                 // this.Estimatedblank=true
                  this.fetchClaimDeatils("FETCH_CLAIM_DETAILS",'Feature Claim');  
                  
              }
              
              
              }
            },

            proceedInvoice(){
                this.invoiceDetailsTableMenu=false;
            },

            submitAddress(){
             if(this.$refs.addressform.validate()){
                this.addressFieldValidation=this.claimDetails.claimant.addressLineOne;
                this.openAddressDailogbox=false;
              }
            },

            ProccedLRTable(){
              if(this.LRAWSBLDetailsValidation!==''){
                this.LRDetailsTableMenu=false;
              }
            },

            pinCodeCityStateCountry(pincode){
                if(pincode){
                    let indexTtem =this.itemspin.find((elem)=>{
                      return elem.locpincode == pincode;
                    })
                    if(indexTtem){
                        console.log("indexTtem---->",indexTtem);
                      this.claimDetails.claimant.country=indexTtem.pincodeArry.countryName==undefined ?"":indexTtem.pincodeArry.countryName;
                      this.claimDetails.claimant.state=indexTtem.pincodeArry.stateName==undefined?"":indexTtem.pincodeArry.stateName;
                      this.claimDetails.claimant.city=indexTtem.pincodeArry.cityDistrictName==undefined?"":indexTtem.pincodeArry.cityDistrictName;
                       this.claimDetails.claimant.district=indexTtem.pincodeArry.cityDistrictName==undefined?"":indexTtem.pincodeArry.cityDistrictName;
                      this.getClaimantStates(); 
                      this.getClaimantCities();
                      this.IsDisabledafterpincode=true
                    }
                }else{
                      // console.log('0-==============',pincode)
                      this.claimDetails.claimant.country = '';
                      this.claimDetails.claimant.state = '';
                      this.claimDetails.claimant.city = '';
                      this.claimDetails.claimant.district='';
                       this.IsDisabledafterpincode=false
                }
            },

            openCreateFeatureClaim(){
              // if(this.claimDetails.claimType=="New Claim"){
              //   this.claimDetails.featureClaimType=this.claimDetails.claimType;
              // }else{
              //   this.claimDetails.featureClaimType=this.claimDetails.claimType;
              // }
                // this.claimDetails.claimType ='Feature Claim';
               // let URl = this.getURL("FETCH_CLAIM_DETAILS") + this.$store.getters.fetchclaimMainscreen_id.claimId;
                this.$store.commit("FNOL_PAGE_EDIT_CHECK", "FnolEditPage");
                this.fetchClaimDeatils("FETCH_CLAIM_DETAILS",'Feature Claim');  
               // this.Estimatedblank=true;
            },

            deleteInvoiceAll() {
              this.showConfirm("Confirmation","Do you want to delete all?","Yes","No",(Yes)=>{
                      this.claimDetails.invoice=[];
                  },(No)=>{})
            },

            deleteLRRowAll() {
               this.showConfirm("Confirmation","Do you want to delete all?","Yes","No",(Yes)=>{
                      this.claimDetails.awbNumberAndDate= [];
                      this.LRAWSBLDetailsValidation=  "";
                  },(No)=>{});
            },

            onFocus(){
                this.dateOfLossmenu = true;
            },

            onFocusReportedDate(){
                this.reportedDatemenu = true;
            },

            onLosstimeFocus(){
                this.dateOfLossmenu = false;
                this.lossTimemenu = true;
            },

            onReportedTimeFocus(){
              this.reportedDatemenu = false;
              this.reportedTimemenu = true;
            },

            onIncepDate(){
              this.incepDatemenu = true;
            },

            onExpiryDate(){
              this.incepDatemenu = false;
              this.expiryDatemenu = true;
            },

            onPolicyCompany(){
              this.expiryDatemenu = false;
            },

            // openCal(i){
            //   this.claimDetails.awbNumberAndDate.map((e) => {
            //     return (e.lRAWBBLdatemenu = true);
            //   });
            //   this.$forceUpdate();
            //   // console.log('------->',data, i)
            //   // return data[i].lRAWBBLdatemenu = true;
            //   // this.lRAWBBLdatemenu = true
            // },

            // onInvoiceFocus(menu){
            //   console.log("menu============>",menu)
            //     this.invoiceDatemenu = true;
            // },

            mobilerule(v){
                var exp = /^[0-9]+$/
                if(v && !(exp.test(v))){
                    return 'Only numbers allowed'
                }else{
                  return true;
                }
            },

            disableCoverageCode(){
                if(this.claimDetails.mainClaimNumber){
                  if(this.claimDetails.mainClaimNumber &&  this.claimDetails.claimStatus == "Open" && this.claimDetails.claimType === "New Claim"){
                    this.coverageCodeDisable= true;
                  }
                }else if(this.claimDetails.claimStatus == "Open" && this.claimDetails.claimType === "New Claim"){
                    this.coverageCodeDisable= true;
                }else{
                   this.coverageCodeDisable= false;
                }
                
            },

           mandatoryOnCoverRule() {
                //Surendra(01-03-2021):If "Storage cover" is selected as Coverage Desc then Storage Desc is mandatory otherwise not
                if (this.claimDetails.claimType == 'New Claim' && this.claimDetails.typeOfClaim == "Storage"  && this.claimDetails.withoutpolicyno == false) {
                      if (!this.claimDetails.storageDescription) {
                     return this.validationmessage;
                  } else {// tcket number Mc-831
                    return true;
                  }
                  } 
                if (this.claimDetails.claimType == 'New Claim' && this.claimDetails.typeOfClaim == "Storage" && this.claimDetails.coverageDescription.toLowerCase()== "storage cover" && this.claimDetails.withoutpolicyno == false) {
                  if (!this.claimDetails.storageDescription) {
                     return this.validationmessage;
                  } else {// tcket number Mc-831
                    return true;
                  }
                } else if (this.claimDetails.claimType == 'Feature Claim') {
                  if (this.claimDetails.coverageDescription && this.claimDetails.coverageDescription.toLowerCase()== "storage cover") {
                    if (!this.claimDetails.storageDescription) {
                      return this.validationmessage;
                    } else {
                      return true;
                    }
                  } else {
                    return true;
                  }
                } else if (this.claimDetails.claimType == 'Service Claim') {
                  if (this.claimDetails.typeOfClaim == 'Storage' && !this.claimDetails.storageDescription) {
                    return this.validationmessage;
                  } else {
                    return true;
                  }
                  
                }
                 else {
                  return true;
                }
           },

           mandatoryStorage(){
            if(this.claimDetails.claimType != 'Service Claim' )  {
              if ( this.claimDetails.coverageDescription.toLowerCase()== "storage cover") {
                if (!this.claimDetails.storageDescription) {
                    this.storage=true;
                    return this.validationmessage;
                }
                else {
                    this.storage=true;
                    return true;
                }
              }
              else {
              this.storage=false;
                return true;
              }
            } else{
                this.storage=false;
                return true;
            }
           },

            storageCodeRule(){
              if(this.claimDetails.claimType == 'Service Claim'){
                  if(this.claimDetails.typeOfClaim == 'Storage' && !this.claimDetails.storageDescription){
                    return this.validationmessage;
                  }else{
                    return true;
                  }
              }else{
                return true;
              }
            },

            aigmandatoryRule(){
                if(this.claimDetails.claimType == 'Service Claim' && this.claimDetails.aigClaimNumber == ''){
                    return this.validationmessage;
                 }else if(this.claimDetails.claimType == 'New Claim' && this.claimDetails.claimCategory=='Export Claim' && this.claimDetails.aigClaimNumber == '' ){
                         return this.validationmessage;
                  }else{
                  return true;
                }
            },

            tryClickMenu(){
              alert("Menu Clicked")
            },

            toDisableTime(){
              if(this.dateOfLoss && this.dateOfLoss == moment().format("YYYY-MM-DD")){
                  console.log('diabletime---------->', this.dateOfLoss == moment().format("YYYY-MM-DD"))
                  this.claimDetails.lossTime ="";
                  this.currentTime = moment().format("HH:mm");
              }else{
                this.currentTime ="";
              }

              if(this.claimDetails.claimType == 'Service Claim'){
                if(this.inceptionDate && this.expiryDate){
                  let  dol = new Date(this.dateOfLoss).setHours(0, 0, 0, 0);
                  let inceptionDate =  new Date(this.inceptionDate).setHours(0, 0, 0, 0);
                  let expiryDate = new Date(this.expiryDate).setHours(0, 0, 0, 0);
                    //  console.log('======>validate DOL', new Date(inceptionDate), new Date(dol), new Date(expiryDate));
                  if(dol <= inceptionDate || dol >= expiryDate){
                        // console.log('true');
                        this.dialogBoxText = "Loss date is not within Policy Period";
                        // this.dialogboxError = true;
                        this.showAlert(this.dialogBoxText);
                  }
                  if(this.inceptionDate!==""){

                    let    lossEnd = moment(this.dateOfLoss).format("DD-MM-YYYY");
                    const policy = moment(this.inceptionDate).format("DD-MM-YYYY");
                    const date1 = moment(lossEnd, 'DD-MM-YYYY');//lossDate
                    const date2 =  moment(policy, 'DD-MM-YYYY');//policyStartDat                         
                    
                    if(date1>date2){
                      var diffmonths = date1.diff(date2, 'months');
                      // console.log('month',diffmonths)
                      date2.add(diffmonths, 'months');
                      var diffdays = date1.diff(date2, 'days')
                      // console.log('days',diffdays)
                    
                      if(diffmonths==0){
                        this.claimDetails.dateDifference= diffdays.toString() + ' days'
                      }else if(diffdays==0){
                        this.claimDetails.dateDifference= diffmonths.toString() + ' Months'
                      }else{
                        this.claimDetails.dateDifference= diffmonths+ " "+ "Months" + " " + diffdays+ " " + "Days"
                      }
                    }
                  }
                }
              }   
              this.catastropheDesDetails =[];
              this.GETM("getAllCatastrophe","", (res) => {
                let catastropheDesDetails = res.data.data.data
                for (var i =0; i<catastropheDesDetails.length; i++){
                  if(catastropheDesDetails[i].endDate){
                      //added condition based on ticket MC-833
                      // this.catastropheDesDetails =[];
                    if(new Date(catastropheDesDetails[i].startDate).setHours(0, 0, 0, 0) <= new Date(this.dateOfLoss).setHours(0,0,0,0) && new Date(this.dateOfLoss).setHours(0,0,0,0) <= new Date(catastropheDesDetails[i].endDate).setHours(0, 0, 0, 0) ){
                      this.catastropheDesDetails.push(catastropheDesDetails[i]) 
                      console.log("this.catastropheDesDetails",this.catastropheDesDetails)
                    }
                  }
                }            
              },(error)=>{
                  return error;
              });  
            },

            
            toDisableReportedTime(){
                if(this.reportedDate == moment().format("YYYY-MM-DD")){
                  //  console.log('diabletime---------->', this.reportedDate == moment().format("YYYY-MM-DD"))
                   this.claimDetails.reportedTime ="";
                   this.reportedTime = moment().format("HH:mm");
                   this.currentTime= moment().format("HH:mm");
                    
                }else{
                  this.reportedTime ="";
                  this.claimDetails.reportedTime ="";
                }
                  
            },

            tocheckLossTime(){

               //to check the loss time is after reported time
               if(this.lossTime && this.dateOfLoss == moment().format("YYYY-MM-DD")){
                   let LT = this.claimDetails.lossTime.split(":")
                   let lossTime = new Date().setHours(LT[0],LT[1], 0, 0); 

                   let RT  = this.claimDetails.reportedTime.split(':');
                   let reportedTime  = new Date().setHours(RT[0],RT[1], 0, 0);
                   if(lossTime > reportedTime){
                      this.showAlert('Loss Time cannot be after reported time');
                      this.claimDetails.lossTime = "";
                      
                   }else{
                     return true;
                   }
               } 


              // to check the lossTimee greater than current time when user enter manually
                console.log('this.dateOfLoss', this.claimDetails.lossTime)
                // if(this.dateOfLoss && this.dateOfLoss == moment().format("YYYY-MM-DD")){

                //    let LT = this.claimDetails.lossTime.split(":")
                //    let lossTime = new Date().setHours(LT[0],LT[1], 0, 0);
                //    let currentime = new Date().getTime()

                //   //  console.log('this.dateOfLoss',lossTime, currentime)
                //    if(lossTime > currentime){
                //      this.claimDetails.lossTime = "";
                //      alert("Future time is not allowed");
                //    }else{
                //      return true;
                //    }
                // }else if(this.claimDetails.lossTime > "23:59"){
                //   this.claimDetails.lossTime = "";
                //   this.currentTime ="";
                //   return true;
                // }
            },

            computedDate(date){
              // console.log('-----date---', date);
              return moment(date).format('DD/MM/YYYY');
            },
            
            toISOStringConvert(dateString) {
              return new Date(dateString).toISOString();
            },

            changeDateFormat(date){
              console.log('lossdate',date)
              if (this.Datepattern.test(date)) {

                var inputdate = moment(date, 'DD/MM/YYYY').toDate().getTime();
                var todayDate = new Date().getTime()

                  // console.log('date',inputdate, todayDate)
                if(inputdate >= todayDate){
                  this.computedDateofloss = "";
                  this.showAlert('Future Date is not allowed')
                }else{
                    const [day, month, year] = date.split("/");
                    this.dateOfLoss = `${year}-${month}-${day}`;
                    this.dateOfLossmenu = false;
                    return `${year}-${month}-${day}`;
                }
              } else {
                this.dateOfLoss = moment().format("YYYY-MM-DD");
              }
                // console.log('----------',date);
                // if (this.Datepattern.test(date)) {
                //   console.log('----------if',date);
                //   this.dateOfLoss = moment(date).format('YYYY-MM-DD');
                //   this.dateOfLossmenu = false;
                //   return moment(date).format('YYYY-MM-DD');
                // } else {
                //   this.dateOfLoss = moment().format('YYYY-MM-DD');
                // }
            },

            pastDateCheck(date) {
              if (date) {
                date = moment(date, "DD/MM/YYYY").toDate().getTime();
                if (new Date(date).setHours(0, 0, 0, 0) <= new Date().setHours(0, 0, 0, 0)) {
                  return true;
                }
                return false;
              }
            },

            toDisableInceptionDate(date){
              this.disableInceptionDate = this.inceptionDate;
              console.log('this.disableInceptionDate', this.disableInceptionDate)
              if(this.inceptionDate!==""){
                    let    lossEnd = moment(this.dateOfLoss).format("DD-MM-YYYY");
                    const policy = moment(this.inceptionDate).format("DD-MM-YYYY");
                    const date1 = moment(lossEnd, 'DD-MM-YYYY');//lossDate
                    const date2 =  moment(policy, 'DD-MM-YYYY');//policyStartDat                         
                if(date1>date2){
                  var diffmonths = date1.diff(date2, 'months');
                  // console.log('month',diffmonths)
                  date2.add(diffmonths, 'months');
                  var diffdays = date1.diff(date2, 'days')
                  // console.log('days',diffdays)
                
                  if(diffmonths==0){
                    this.claimDetails.dateDifference= diffdays.toString() + ' days'
                  }else if(diffdays==0){
                    this.claimDetails.dateDifference= diffmonths.toString() + ' Months'
                  }
                  else{
                    this.claimDetails.dateDifference= diffmonths+ " "+ "Months" + " " + diffdays+ " " + "Days"
                  }
                }
              }
            },

            futureDateCheck(date) {
             
              if (date !== "" && this.inceptionDate!=="") {
                date = moment(date, "DD/MM/YYYY").toDate().getTime();
              let inceptionDate = moment(this.computedInceptionDate, "DD/MM/YYYY").toDate().getTime()
                if (new Date(date).setHours(0, 0, 0, 0) <  new Date(inceptionDate).setHours(0, 0, 0, 0)) {
                  return false;
                }
                return true;
              }else{
                return true;
              }
            },

            toValidateDOL(){
              if(this.dateOfLoss){
                if(this.computedInceptionDate && this.computedExpiryDate){
                  let  dol = new Date(this.dateOfLoss).setHours(0, 0, 0, 0);
                  let inceptionDate =  new Date(this.inceptionDate).setHours(0, 0, 0, 0);
                  let expiryDate = new Date(this.expiryDate).setHours(0, 0, 0, 0);
                  // console.log('======>validate DOL', new Date(inceptionDate), new Date(dol), new Date(expiryDate));
                    if(dol <= inceptionDate || dol >= expiryDate){
                          // console.log('true');
                          this.dialogBoxText = "Loss date is not within Policy Period";
                         // this.dialogboxError = true;
                          this.showAlert(this.dialogBoxText);
                    }
                }
              }  
            },

            insuredIDRules(){
                let IDExep = /^[a-zA-Z0-9]+$/;
                if(!this.claimDetails.withoutpolicyno && !this.claimDetails.insuredID){
                  return this.validationmessage
                }else if(this.claimDetails.insuredID && !(IDExep.test(this.claimDetails.insuredID))){
                   return 'Insured ID is not valid'
                }else{
                  return true;
                }
            },

            numeric(v){
              if(v && !(/^[0-9]+$/.test(v))){
                  return 'Only Numbers are required'
              }else{
                return true;
              }
            },

            checkblankspaces(v){
              if(v && !(/^\S[a-zA-Z0-9\s.\-:,;}/#()*%]+\S$/.test(v))){
                return "This field is not valid"
              }else{
                return true;
              }
             
            },
            
            policyrule(v){
                  if(!this.claimDetails.withoutpolicyno && !v){
                    return this.validationmessage
                  }else if(v && !(/^[a-zA-Z0-9]+$/.test(v))){
                    return 'It is not valid'
                  }else if(v.length > 15){
                    return 'Policy Number should be less than 15 characters.'
                  }else{
                    return true;
                  }             
            },

            checkPolicyNumber(){
              if(this.claimDetails.claimType == 'Service Claim'){
                  if(this.claimDetails.policyNumber){
                    // this.certificatenoDisable = false;
                    this.certificateNo = false 
                  }else{
                    // this.claimStatus = "Suspended"
                    // this.certificatenoDisable = true;
                    this.certificateNo = true; //to show star(as mandatory)
                  }
              }
            },

            toMakeCetificateNoMandate(){
              if(this.claimDetails.withoutpolicyno == true && this.claimDetails.certificateNumber == ""){
                  return 'This field is required';
              }else{
                return true;
              }
            },

            withoutPolicyno(){     
              if(this.claimDetails.withoutpolicyno == true){
                // this.$refs.fnolform.resetValidation();
                this.claimDetails.policyNumber = "";
                
                this.policynotrue = false; // to show star (as mandatory)
                this.disablepolicy = true; // to disable the field
                // this.showinsuredID = false; 
                this.certificateNo = true;  // to show star (as mandatory)
                
                //Surendra(04-03-2021):If user selects Without Policy number, product array should be resetted,starts here
                this.coverageDescrptionOptions   = [];
                this.GETM("getCoverage","", (res) => {
                      this.Coverage = res.data.data.data
                      this.productnames = [];
                      for(var i in this.Coverage){
                        if(!this.productnames.find((elem)=>{
                          elem.productCode == this.Coverage[i].productCode
                        })){
                          this.productnames.push(this.Coverage[i]);
                          
                        }
                      }
                    },(error)=>{            
                        return error;
                    });
                  //Surendra(04-03-2021):If user selects Without Policy number, product array should be resetted,ends here
                  this.typeOfClaim   = ["Transit", "Storage"];//Surendra(05-03-2021): Reset options
                  this.claimDetails.reasonForSuspense="Policy number not available/not found";
                  //Surendra(06-03-2021):If user selects Without Policy number, Storage options should be resetted,starts here
                  this.storageDescrptionOptions   = []
                  this.storageDescrptionOptions.push(this.unNamedStorage);
                  this.storageDescrptionOptions.push(this.panIndiaStorage);
                  //Surendra(06-03-2021):If user selects Without Policy number, Storage options  should be resetted,ends here
                  this.isPolicyLoaded   = false;//Surendra(19-03-2021):MC577-Disable if policy data is loaded
              }else{
                this.policynotrue = true;
                this.disablepolicy = false;
                this.certificateNo = false;
                // this.showinsuredID = true;

                //ToDo:AutoPopulate product array based on policy number
              }
            },

            multiplemailIds(v){
              let emailExp = /^(([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)(\s*,\s*|\s*$))*$/
              if(v && !(emailExp.test(v))){
                return `Enter valid email ID`
              }else {
                return true;
              }
            },

            maxLength(v,maxLength){
              if(v && v.length > maxLength){
                    return `Must be less than ${maxLength} Characters`
              }else {
                return true;
              }
            },

            checkcontactlength(v){
              if (v.length !== 0) {
                 if (v && v.length < 5) {
                     return "Contact Number should be more than 5 digits";
                 }
                 else {
                  return true;
                }
              }else{
                return true;
              }
            },

            checkMobileNoLength(v){

                if (v && v.length !== 0) {
                  if (v && v.length < 10) {
                     return "Mobile Number should be of 10 digits";//Surendra(23-03-2021):"Mobile Number should be more than 5 digits";
                  }
                  else {
                    return true;
                  }
                }else{
                  return true;
                }
            },

            Numeric2(v){
              if((this.claimDetails.callerIntimatorIdentification !== "Insured" && this.claimDetails.callerIntimatorIdentification !== "")&& !v){
                return this.validationmessage;
              }else if(v==undefined){
                 return true;
              }else if (v.length !== 0) {
                  if(!/^[0-9]+$/.test(v)){
                      return "Only Numbers are allowed."
                  }else if (v && v.length < 10) {
                     return "Mobile number should be of 10 digits";//Surendra(23-03-2021):"Mobile Number should be more than 5 digits";
                  }
                  else{
                      return true;             
                  }
              }else{
                return true;
              }            
            },

            checkCallerMobileIsd(v){
              let isdExp = /^[0-9]+$/;
              if((this.claimDetails.callerIntimatorIdentification !== "Insured" && this.claimDetails.callerIntimatorIdentification !== "")&& !v){
                return this.validationmessage;
              }else if(v && !(isdExp.test(v))) {
                return 'Invalid ISD code'
              } else {
                return true;
              }
            },

            alphaNumeric(v) {
              if (v !== undefined) {
                if (v.length !== 0) {
                  //  if(v && !v.trim()){
                  //         return "Only spaces are not allowed"
                  //   }
                  if (!/^\S[0-9a-zA-Z\s]+\S$/.test(v)) {
                    return "This field is not valid"
                  } else {
                    return true;
                  }
                } else {
                  return true;
                }
              } else {
                return true;
              }
            },

            AlphaChar(v){
              if (v !== undefined) {
                if (v.length !== 0) {
                  if (!/^\S[a-zA-Z\s',-]+\S$/.test(v)) {
                    return "This field is not valid"
                  } else {
                    return true;
                  }
                }else{
                  return true;
                }
              }else{
                return true;
              }
            },
            transitAlphaChar(v){
               if (v !== undefined) {
                if (v.length !== 0) {
                  if (!/^[a-zA-Z\s'@,-]+$/.test(v)) {
                    return "This field is not valid"
                  } else {
                    return true;
                  }
                }else{
                  return true;
                }
              }else{
                return true;
              }
            },
            
            multiplemobilenos(v){
               let mobileExp=/^[0-9]+$/;
                // let mobileExp = /^(\d{10},)*\d{10}$/;
              if(!v){
                return this.validationmessage;
              } else if(v && !(mobileExp.test(v))){
                  return 'Enter valid Mobile Numbers'
              }else if (v && v.length < 10) {
                  return "Mobile number should be of 10 digits";//Surendra(23-03-2021):"Mobile Number should be more than 5 digits";
              }
              else{
                return true;
              }
                
            },

            callermultiplemailIds(v){
              //ticket id:835
              let emailExp = /^(([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)(\s*,\s*|\s*$))*$/
              if((this.claimDetails.callerIntimatorIdentification !== "Insured" && this.claimDetails.callerIntimatorIdentification !== "") && 
              ((this.claimDetails.claimIntimatedThrough == 'Email' || this.claimDetails.claimIntimatedThrough == 'Online') && this.claimDetails.claimIntimatedThrough !== '')&& !v){
                // this.calleremail = true; 
                return v => !!v || this.validationmessage
              }else if(v && !(emailExp.test(v))){
                return `Enter valid email ID`
              }else {
                // this.calleremail = false;
                return true;
              }
            },

            email(v){
              let emailExp =/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
              if(v && !(emailExp.test(v))){
                  return "Email must be valid"
              }else{
                return true;
              }
              
            },

            addDetails(){
                this.counter = this.counter+1;
            },

            toMakeStoragemandatory(){
              if(this.claimDetails.claimType == 'Service Claim' || this.claimDetails.claimType == 'New Claim'){
                  if(this.claimDetails.typeOfClaim == "Storage"){
                     this.storage=true;
                     if(this.claimDetails.coverageDescription.toLowerCase()!='storage cover'){
                       this.claimDetails.coverageDescription="";
                       this.claimDetails.coverageCode="";
                     }
                  }else{
                     this.storage=false;
                     this.claimDetails.storageDescription="";
                     this.claimDetails.storageCode="";
                     if(this.claimDetails.coverageDescription.toLowerCase()=='storage cover'){
                       this.claimDetails.coverageDescription="";
                       this.claimDetails.coverageCode="";
                     }
                  }
              }
                
            },

            checkInsured(){
                if(this.claimDetails.isInsuredTheClaimant == true && this.claimDetails.policyNumber){
                  this.claimDetails.claimant.name = this.claimDetails.insuredName;
                  this.claimDetails.claimant.addressLineOne = this.policyClaimantDetails.addressLine1==undefined?"": this.policyClaimantDetails.addressLine1
                  this.claimDetails.claimant.addressLineTwo =this.policyClaimantDetails.addressLine2==undefined?"": this.policyClaimantDetails.addressLine2
                  this.claimDetails.claimant.addressLineThree = this.policyClaimantDetails.addressLine3==undefined?"":this.policyClaimantDetails.addressLine3
                  
                       
                  // this.getClaimantStates();
                  // this.getClaimantCities(this.policyClaimantDetails.cityDistrict);
                  // this.claimDetails.claimant.state = this.policyClaimantDetails.state
                  // this.claimDetails.claimant.city = this.policyClaimantDetails.cityDistrict

                  // this.callapi(this.policyClaimantDetails.pincode.slice(0,6))
                  // this.claimDetails.claimant.pincode = this.policyClaimantDetails.pincode 
                  //Surendra(06-03-2021):Populate details from service,starts here
                    this.claimDetails.claimant.mobileNo.isd    =  "91";//Surendra(19-03-2021):Validation msg issue for claimnt address
                    this.claimDetails.claimant.mobileNo.number = this.policyClaimantDetails.mobileNumber;
                    this.claimDetails.claimant.emailID = this.policyClaimantDetails.emailId

                  var self  = this;
                  this.GETM("getCoutries","",(res) => {
                    self.Countries = res.data.data.data
                    self.claimDetails.claimant.country = self.policyClaimantDetails.countryname                    
                  },(error)=>{
                      return error;
                  }); 

                  var stateRequestData = "countryName="+this.policyClaimantDetails.countryname;
                  this.GETM("getStates", stateRequestData, (res) => {
                    self.claimantStates = res.data.data.data;                        
                    self.claimDetails.claimant.state = self.policyClaimantDetails.state;                    
                  },(error)=>{
                    return error;
                  });
                  
                  var cityReq = "stateName="+this.policyClaimantDetails.state;

                  this.GETM("getCities", cityReq, (res) => {
                      self.claimantCities = res.data.data.data
                      self.claimDetails.claimant.city = self.policyClaimantDetails.cityDistrict
                      self.claimDetails.claimant.district = self.policyClaimantDetails.cityDistrict
                  },(error)=>{
                      return error;
                  });  

                  let pincodeReqData = "pageNo=1&perPage=100&pinCode="+this.policyClaimantDetails.pincode.slice(0,6)
                  this.GETM("getPincodes",pincodeReqData, res=>{                                          
                    var pin_not_found = false
                    if(res.data.data.length == 0){
                        pin_not_found = true
                    }else{
                        var processed_pin =  res.data.data.data.map(e=>{
                          if(e.cityDistrictName && e.cityDistrictName !== ""){
                              return { locpincode:e.pinCode + " - " + e.cityDistrictName}
                          }else{
                              return {locpincode: e.pinCode, all:e};
                          }                  
                        })
                      self.pitems.push(...processed_pin)
                      // console.log("-==>>> ",self.pitems.length);
                    }  
                    if(pin_not_found && this.policyClaimantDetails.pincode){
                          self.pitems.push({locpincode:self.policyClaimantDetails.pincode.slice(0,6).toString(),all:''});
                    }
                    if(self.policyClaimantDetails.pincode.length >6){
                        self.claimDetails.claimant.pincode = self.policyClaimantDetails.pincode;
                    }else{
                        self.claimDetails.claimant.pincode = self.policyClaimantDetails.pincode + " - " + self.policyClaimantDetails.cityDistrict;
                    }
                    this.submitAddress();//Surendra(19-03-2021):Validation msg issue for claimnt address
                    // this.pitems = res.data.data.data.map
                    // console.log("pitems is -> ", this.pitems);
                  },(error)=>{
                        return error;
                  });            

                  // console.log('=============>if',this.policyClaimantDetails);
                  //Surendra(06-03-2021):Populate details from service,ends here

                  //Surendra(14-04-2021):MC834-if claimant is insured, then address in the address field to be auto populated, instead of going inside and clicking on proceed,code starts here
                    // this.$refs.addressform.validate()
                    this.addressFieldValidation=this.claimDetails.claimant.addressLineOne;
                  //Surendra(14-04-2021):MC834-if claimant is insured, then address in the address field to be auto populated, instead of going inside and clicking on proceed,code starts here


                }else if(this.claimDetails.isInsuredTheClaimant == true && !this.claimDetails.policyNumber) {
                  this.claimDetails.claimant.name = this.claimDetails.insuredName;
                }else if(this.claimDetails.isInsuredTheClaimant == false){
                  this.claimDetails.claimant.name = "";
                   
                  this.claimDetails.claimant.addressLineOne = ""
                  this.claimDetails.claimant.addressLineTwo = ""
                  this.claimDetails.claimant.addressLineThree = ""
                  this.claimDetails.claimant.city = ""
                  this.claimDetails.claimant.state = ""
                  this.claimDetails.claimant.pincode = ""
                  this.claimDetails.claimant.country = ""
                  this.claimDetails.claimant.mobileNo = {
                    isd:'',
                    number:''
                  }
                  this.claimDetails.claimant.emailID = ""
                  // console.log('=============>else',this.policyClaimantDetails);
                  this.claimDetails.claimant.district = "";//Surendra(16-04-2021):MC-840 = Make address empty on No.
                   this.addressFieldValidation  = "";//Surendra(16-04-2021):MC-840 = Make address empty on No.
                }
            },          

                    // mandatory conditions
            reasonMandatory(){
                if(this.claimDetails.claimStatus == "Suspended"){
                  this.mandatory = true;
                  // console.log('---------',v);
                  return v => !!v || this.validationmessage
                }else{
                  this.mandatory = false;
                  return true;
                }
            },

            changeName(){
              if(this.claimDetails.callerIntimatorIdentification !== ""){
                if(this.claimDetails.callerIntimatorIdentification == "Insured"){
                  this.callermobile = false;
                  // this.calleremail = false;
                  // console.log('=-------->if insured')
                  this.claimDetails.callerName = this.claimDetails.insuredName;
                  this.claimDetails.callerRelationshipwithInsured  = "";//Surendra(07-04-2021): MC758-Caller identification and caller relationship
                  this.tochangeRelationship();//Surendra(07-04-2021): MC758-Caller identification and caller relationship
                }else if(this.claimDetails.callerIntimatorIdentification !== "Insured"){
                  this.callermobile = true;
                  // this.calleremail = true;
                  
                  this.claimDetails.callerName = "";
                  // console.log('=-------->else insured')
                  //Surendra(07-04-2021): MC758-Caller identification and caller relationship,starts here
                  if(this.claimDetails.callerIntimatorIdentification == "Agent" || this.claimDetails.callerIntimatorIdentification == "Broker"){
                      this.claimDetails.callerRelationshipwithInsured  = this.claimDetails.callerIntimatorIdentification;
                  }else if(this.claimDetails.callerIntimatorIdentification){
                      this.claimDetails.callerRelationshipwithInsured  =  "Other"
                  }else{
                    this.claimDetails.callerRelationshipwithInsured  = "";
                  }
                  this.tochangeRelationship();//Surendra(07-04-2021): MC758-Caller identification and caller relationship
                  //Surendra(07-04-2021): MC758-Caller identification and caller relationship,ends here

                  return v => !!v || this.validationmessage;
                }
              }
            },            
            
            // //for caller name
            // checkCaller(){
            //   if(this.claimDetails.claimIntimatedThrough == "Email"){
            //       this.callername = false;
            //       return true;
            //   }else if(this.claimDetails.claimIntimatedThrough !== "Email"){
            //       this.callername = true;
            //       return v => !!v || this.validationmessage;
            //   }else if(this.claimDetails.productName == "MarineFreightForwardLiability" ){             
            //       this.callername = false;
            //       return true;
            //   }else{
            //       this.callername = false;
            //       return true;
            //   }
            //  else if ((this.claimDetails.callerIntimatorIdentification !== "Insured" && this.claimDetails.callerIntimatorIdentification !== "") && 
            //   (this.claimDetails.claimIntimatedThrough == 'Email' || this.claimDetails.claimIntimatedThrough == 'Online') && this.claimDetails.claimIntimatedThrough !== ''){
            //       this.calleremail = true;
            //       this.callername = false;
            //       return  true;
            //   }
            // },     

            //for caller name
            checkCaller(v){
              // console.log('000000',v);
              if(this.claimDetails.productName == "MarineFreightForwardLiability"){             
                  this.callername = false;
                  // console.log('------if productname',this.claimDetails.productName);
                  return true;
              }else if(this.claimDetails.claimIntimatedThrough == "Email"){
                  this.callername = false;
                  this.calleremail = false;
                  return true;
              }else if(this.claimDetails.claimIntimatedThrough !== "Email" && (this.claimDetails.claimIntimatedThrough !== "")){
                  this.callername = true;
                  this.calleremail = false;
                  // return true;
                  return v => !!v || this.validationmessage;
              }else {
                  this.calleremail = false;
                  this.callername = false;
                  return true;
              }
            },         

            // //for caller relationship
            // checkCallerIntimator(){
            //   if(((this.claimDetails.callerIntimatorIdentification && this.claimDetails.callerIntimatorIdentification !== "Insured"))){
            //         this.callertrue = true;
            //         return v => !!v || this.validationmessage;
            //   }else if(this.claimDetails.productName == "MarineFreightForwardLiability"){     
            //         this.callertrue = true;                
            //         return true;
            //   }else{
            //         this.callertrue = false;
            //         return true;
            //   }
            // },                       
                        

            // //for caller relationship
            checkCallerIntimator(v){
              if(this.claimDetails.productName == "MarineFreightForwardLiability"){     
                    this.callertrue = false;                
                        // console.log('------==>if caller productname',this.claimDetails.productName);
                    return true;
              }else if(this.claimDetails.productName !== "MarineFreightForwardLiability"){
                    if(this.claimDetails.callerIntimatorIdentification !== ""){
                          if(this.claimDetails.claimIntimatedThrough !== "Email" 
                              && this.claimDetails.callerIntimatorIdentification !== "Insured" &&
                              this.claimDetails.claimIntimatedThrough !== ""){
                                this.callertrue = true;
                                // console.log('------==>if caller productname',this.claimDetails.claimIntimatedThrough);
                                // return true;
                                return v => !!v || this.validationmessage;
                          }else{
                            this.callertrue = false;
                            return true;
                          }
                    }else{
                      this.callertrue = false;
                      return true;
                    }
              }else if(this.claimDetails.claimIntimatedThrough == "Email" && this.claimDetails.callerIntimatorIdentification == "Insured"){
                    this.callertrue = false;
                    return true;
              }else{
                    this.callertrue = false;
                    return true;
              }
            }, 

            pleaseSpecify(){
              if(this.claimDetails.callerRelationshipwithInsured == "Other" && this.claimDetails.pleaseSpecify == ""){
                return v => !!v || this.validationmessage;
              }else{
                return true;
              }
            },        

            validatePincodeLL(v){
                let exp = /^[0-9]+$/
                if(v.length > 0  && !(exp.test(v))){
                  return 'This field is not valid'
                }
                else{
                  return true;
                }
            },

                        
            tocheckLocation(){
             
              if(this.claimDetails.internationalLocation == true){
                this.onInternationalocation = false; // to enable on true
                this.location = false; 
                this.claimDetails.pinCodeOfLossLocation=""
                return true;
                
              }else{
                this.onInternationalocation = true;
                this.location = true;  // to show star(as mandatory)
                this.claimDetails.country="";
                this.claimDetails.city = "";
                this.claimDetails.locationLandmark = ""
                 return v => !!v || this.validationmessage;
              }
            
            },
            // losspincodemandatory(){
            //   return v => !!v || this.validationmessage;
            // },
            // unamedstoragename(){
            //  return v => !!v || this.validationmessage;
            // },

            tocheckMandatory(){
              if(this.claimDetails.claimType !== 'Service Claim'){
                  if(this.claimDetails.productName == "MarineFreightForwardLiability"){
                    this.productrue = false;
                    return true;
                  }else {
                    this.productrue = true;
                    return v => !!v || this.validationmessage;
                  }
              }else{
                return v => !!v || this.validationmessage;
              }    
            },
            
            checklossdatedifference(v){

                var lossdate= moment(this.computedDateofloss, "DD/MM/YYYY").toDate().getTime();
                    console.log(lossdate)
                    var lrDate =  moment(v, "DD/MM/YYYY").toDate().getTime();
                    console.log(lrDate)
                    if(lrDate > lossdate){
                      return  "LR/AWB/BL date must be less than lossdate."//Surendra(22-03-2021):Msg corrected
                    }
                    else{
                      return true
                    }

            },

            isEventACatastrophe(){
                if(this.claimDetails.isEventACatastrophe == true){
                  this.catastrophe= true;
                  return v => !!v || this.validationmessage;
                }else{
                  this.claimDetails.catastropheDescription  = "";
                  this.claimDetails.catastropheCode="";
                  this.claimDetails.catastropheDetail="";
                  this.claimDetails.catastropheType="";
                  this.catastrophe= false;
                  return true;
                }
            },

            catastropheValidation(v){
              if(this.claimDetails.isEventACatastrophe && !v){
                  return this.validationmessage
                }else{
                  return true;
                }
            },

            // ------------------LR Table actions--------------------
            addNewLRRow() {
              console.log("addLRrow", this.claimDetails.awbNumberAndDate.length)
              if(this.claimDetails.awbNumberAndDate.length < 100){
                  this.claimDetails.awbNumberAndDate.push({"number":"","date":""});
              }
            },

            deleteLRRow(index){
                this.claimDetails.awbNumberAndDate.splice(index,1);
                if(this.claimDetails.awbNumberAndDate.length == 0){
                  this.LRAWSBLDetailsValidation = '';
                }else{
                  if(index == this.claimDetails.awbNumberAndDate.length){
                    this.LRAWSBLDetailsValidation =  this.claimDetails.awbNumberAndDate[index-1].number;
                  }
                }
                // console.log("deleteLRrow");
                this.dialogBoxText = "LR/AWB/BL Details deleted";
                this.showAlert(this.dialogBoxText);
               // this.savedialogbox = true;
            },           

            saveLRTable(){
              // alert(this.savelrflag)
              // if(this.savelrflag==true){
              let awbNumberAndDate = this.claimDetails.awbNumberAndDate
              for(var i = 0; i<awbNumberAndDate.length; i++){
                if(awbNumberAndDate[i].number && (awbNumberAndDate[i].date)){
                    if((awbNumberAndDate[i].date) && !(this.Datepattern.test(awbNumberAndDate[i].date))){
                        this.dialogBoxText = "Enter proper/valid date"
                       // this.savedialogbox = true;
                       this.showAlert(this.dialogBoxText);
                    }
                    // console.log('for check',awbNumberAndDate.length)
                    // if(awbNumberAndDate[i].number && !awbNumberAndDate[i].date && awbNumberAndDate.length>1){
                    //   alert()
                    // }
                  
                    if((awbNumberAndDate[i].number))
                    console.log(this.computedDateofloss)
                    var lossdate= moment(this.computedDateofloss, "DD/MM/YYYY").toDate().getTime();
                    console.log(lossdate)
                    var lrDate =  moment(awbNumberAndDate[i].date, "DD/MM/YYYY").toDate().getTime();
                    console.log(lrDate)
                    if(lrDate > lossdate){
                       this.dialogBoxText = "LR/AWB/BL date must be less than lossdate."//Surendra(22-03-2021):Msg corrected
                      this.LRDetailsTableMenu=true;
                      this.showAlert(this.dialogBoxText);
                    }
                    else{
                        this.LRAWSBLDetailsValidation = awbNumberAndDate[i].number;
                        this.dialogBoxText = "LR/AWB/BL Details Saved"
                        //this.savedialogbox = true;
                        this.LRDetailsTableMenu=false;
                        this.showAlert(this.dialogBoxText);
                    }
                }else if(this.claimDetails.productName == "MarineFreightForwardLiability" || this.claimDetails.typeOfClaim == 'Storage'){//MC-941
                      var lossdate= moment(this.computedDateofloss, "DD/MM/YYYY").toDate().getTime();
                      var lrDate =  moment(awbNumberAndDate[i].date, "DD/MM/YYYY").toDate().getTime();
                      if(lrDate > lossdate){
                      this.dialogBoxText = "LR/AWB/BL date must be less than lossdate."//Surendra(22-03-2021):Msg corrected
                      this.LRDetailsTableMenu=true;
                      this.showAlert(this.dialogBoxText);
                      return true;
                      }
                      this.LRAWSBLDetailsValidation = awbNumberAndDate[i].number;
                      this.dialogBoxText = "LR/AWB/BL Details Saved"
                      this.LRDetailsTableMenu=false;
                      this.showAlert(this.dialogBoxText);
                  }else{
                    this.dialogBoxText = "Enter All LR/AWB/BL Mandatory Details"
                    this.LRDetailsTableMenu=true;
                    this.showAlert(this.dialogBoxText);
                }
              }
              //  this.savelrflag=false
              // }
            },
              
            //------------------Invoice Table------------------
            
            addInvoiceRow(){
              if(this.claimDetails.invoice.length < 50){
                this.claimDetails.invoice.push({"number":"","date":"","amount":""});
              }
                // console.log("AddInvoice")
            },
            
            deleteInvoice(index){
              this.claimDetails.invoice.splice(index,1);
                if(this.claimDetails.invoice.length == 0){
                  this.invoiceFieldValidation = ''
                }else{
                  if(index == this.claimDetails.invoice.length){
                    this.invoiceFieldValidation =  this.claimDetails.invoice[index-1].number;
                  }
                }
                // console.log("deleteInvoice")
                this.dialogBoxText = "Invoice Details deleted"
               // this.savedialogbox = true;
               this.showAlert(this.dialogBoxText);
            },

            saveInvoicetable(){;
              let invoice = this.claimDetails.invoice
              for(var i = 0; i<invoice.length; i++){
                // console.log('00000000',invoice[i].number);
                if(invoice[i].number || invoice[i].date || invoice[i].amount){
                    if(invoice[i].date && !(this.Datepattern.test(invoice[i].date))){
                      
                      this.dialogBoxText = "Enter proper/valid invoice date"
                     // this.savedialogbox = true;
                     this.showAlert(this.dialogBoxText);
                    }else{
                      this.dialogBoxText = "Invoice Details Saved"
                     // this.savedialogbox = true;
                     this.showAlert(this.dialogBoxText);
					            this.invoiceFieldValidation=invoice[i].number;
                      this.invoiceDetailsTableMenu=false;
                    }
                }else{
                    this.dialogBoxText = "Enter Invoice Details"
                   // this.savedialogbox = true;
                   this.showAlert(this.dialogBoxText);
                }
              }
            },


            //------------------- FNOL-------------------
            toCheckCatastrophe(){
              // console.log("23456789", this.claimDetails.catastropheDescription);
              //Surendra(31-03-2021):If claimDetails.catastropheDescription is deselected then clear other related fields
              if(this.claimDetails.catastropheDescription){
                var self  = this;
                let selectedValue = this.catastropheDesDetails.find(function(elem){
                  return elem.catastropheDescription == self.claimDetails.catastropheDescription
                });
                // console.log("selectedValue - ",selectedValue);
                this.claimDetails.catastropheCode = selectedValue.catastropheId;
                this.claimDetails.catastropheDetail = selectedValue.catastropheDetail;
                this.claimDetails.catastropheType = selectedValue.catastropheType
              }else{
                this.claimDetails.catastropheCode = "";
                this.claimDetails.catastropheDetail = "";
                this.claimDetails.catastropheType = ""
              }
              
            },

            toCoverageCode(){
              // console.log("23456789", this.claimDetails.coverageDescription);
              var self  = this;
              let selectedValue = {};
              if(!this.isPolicyLoaded){
                 selectedValue = this.Coverage.find(function(elem){
                // console.log('-00000000000',elem)
                  return elem.coverGroupName == self.claimDetails.coverageDescription
                });
                this.claimDetails.coverageCode = selectedValue.coverCode;  
                this.coverageCodeDisable = true;        

                this.claimDetails.perilName       = selectedValue.perilName;
                this.claimDetails.coverGroupIndex = selectedValue.coverGroupIndex;//Surendra(08-04-2021):Value added for Krishna
              
              }else{
                   //Surendra(17-04-2021):RiskSerialNumber added for backend,code starts here
                  selectedValue = this.coverageDescrptionOptions.find(function(elem){
                  // console.log('-00000000000',elem)
                    return elem.coverGroupName == self.claimDetails.coverageDescription
                  });
                  this.claimDetails.riskSerialNumber  = selectedValue.riskDetailSerial;
                  //Surendra(17-04-2021):RiskSerialNumber added for backend,code ends here

                  this.claimDetails.coverageCode = selectedValue.nolCode;  
                  this.coverageCodeDisable = true;        

                  this.claimDetails.perilName       = selectedValue.perilName;
                  this.claimDetails.coverGroupIndex = selectedValue.coverGrpIndx;//Surendra(08-04-2021):Value added for Krishna
                
              }
              console.log("selectedValue - ",selectedValue);
              // console.log('showcove',self.claimDetails.coverageDescription)
              //Surendra(03-03-2021):Check Policy Number,Product name, coverageDescription and storageDescription with DB,starts here
              console.log('showcove',self.claimDetails.coverageDescription)
               if(self.claimDetails.typeOfClaim=='Storage' && self.claimDetails.coverageDescription.toLowerCase() != 'storage cover'){
                 
               //  this.showAlert('Please select Storage Cover', false, this.clearCoverage)
                 this.showAlert("Please select Storage Cover","OK","", (OK) => {
                                    this.clearCoverage();
                              },(No)=>{})
               }
            else if(self.claimDetails.typeOfClaim=='Transit' && self.claimDetails.coverageDescription.toLowerCase() == 'storage cover'){
                self.claimDetails.coverageDescription=""
                 self.claimDetails.coverageCode=""
                //  this.showAlert('Do Not Select storage cover', false, this.clearCoverage)
               }
               else{
                 
             if(this.claimDetails.policyNumber && this.claimDetails.claimType !== 'Service Claim' ){//944
                  let reqData   = "policyNumber="+this.claimDetails.policyNumber+"&coverageDescription="+this.claimDetails.coverageDescription+"&proposalNo="+this.claimDetails.proposalNo;
                 this.showProgress("Fetching data, please wait...");
                  this.GETM("CHECKFIELDRULES",reqData,(res)=>{
                    console.log(res);
                    this.hideProgress();
                  },(err)=>{
                      console.log(err.response);
                      this.hideProgress();
                     // this.classNameDialog = "errorDailogboxMsg";
                      this.dialogBoxText = err.response.data.msg;
                      this.showAlert(this.dialogBoxText);
                     // this.dialogboxError = true;
                      // alert(err.response.data.msg)
                  });
               }
               }
              //Surendra(03-03-2021):Check Policy Number,Product name, coverageDescription and storageDescription with DB,ends here

            },       

            clearCoverage(){
              this.claimDetails.coverageDescription=""
              this.claimDetails.coverageCode=""
            },
   
            onStorageDescChange(){
                //Surendra(03-03-2021):Check Policy Number,Product name, coverageDescription and storageDescription with DB,starts here
              if(this.claimDetails.policyNumber && this.claimDetails.claimType !== 'Service Claim'){
                  let reqData   = "policyNumber="+this.claimDetails.policyNumber+"&storageDescription="+this.claimDetails.storageDescription+"&proposalNo="+this.claimDetails.proposalNo;
                 this.showProgress("Fetching data, please wait...");
                  this.GETM("CHECKFIELDRULES",reqData,(res)=>{
                    console.log(res);
                    this.hideProgress();
                  },(err)=>{
                      this.hideProgress();
                      console.log(err.response);
                     // this.classNameDialog = "errorDailogboxMsg";
                      this.dialogBoxText = err.response.data.msg;
                      this.showAlert(this.dialogBoxText);
                     // this.dialogboxError = true;
                      // alert(err.response.data.msg)
                  });
                }
              
              //Surendra(03-03-2021):Check Policy Number,Product name, coverageDescription and storageDescription with DB,ends here
            },
            
            updateCoverageOptions(){
              // console.log("claimDetails.productName - ", this.claimDetails.productName);
              // console.log("this.Coverage - ", this.Coverage)
              // console.log("claimDetails.coverageDescription - ",this.claimDetails.coverageDescription)
              //Surendra(17-03-2021):MC-544:- if product is selected then LOB should be auto populated,starts here
              if(this.claimDetails.productName){
                  this.claimDetails.lob = this.LOB[0];
              }  

              //Surendra(17-03-2021):MC-544:- if product is selected then LOB should be auto populated,ends here
              this.coverageOptions   = [];
              if(this.$store.getters.fetchFnolPageEditCheck == "FnolNewPage"){
                  this.claimDetails.coverageCode = ''
              }
              for(var i in this.Coverage){
                if(this.Coverage[i].productName == this.claimDetails.productName){
                  this.coverageOptions.push(this.Coverage[i])
                  
                }
              }
              //Surendra(04-03-2021):If product name is selected as "Marine Freight Forward Liability" make emarine certificate empty, starts here
                if(this.claimDetails.productName == "MarineFreightForwardLiability"){
                    this.claimDetails.eMarinePolicyNumber = ""
                }                
              //Surendra(04-03-2021):If product name is selected as "Marine Freight Forward Liability" make emarine certificate empty, ends here


              //Surendra(03-03-2021):Check Policy Number,Product name, coverageDescription and storageDescription with DB,starts here
              if(this.claimDetails.policyNumber && this.claimDetails.claimType !== 'Service Claim'){
                  let reqData   = "policyNumber="+this.claimDetails.policyNumber+"&productName="+this.claimDetails.productName+"&proposalNo="+this.claimDetails.proposalNo;
                  this.showProgress("Fetching data, please wait...");
                  this.GETM("CHECKFIELDRULES",reqData,(res)=>{
                    console.log(res);
		                this.hideProgress();
                  },(err)=>{
                      console.log(err.response);
                      this.hideProgress();
                     // this.classNameDialog = "errorDailogboxMsg";
                      this.dialogBoxText = err.response.data.msg;
                      this.showAlert(this.dialogBoxText);
                     // this.dialogboxError = true;
                  });
                }
              
              //Surendra(03-03-2021):Check Policy Number,Product name, coverageDescription and storageDescription with DB,ends here
            },

            toChangeCountry(){
                if(this.claimDetails.claimType !== 'Service Claim'){
                    if(this.claimDetails.aigSettlingCompany){
                      console.log("toAIGCountry", this.claimDetails.aigSettlingCompany);
                      var self  = this;
                      let selectedValue = this.AIGDetails.find(function(elem){
                        return elem.nameOfAigOffice == self.claimDetails.aigSettlingCompany
                      });
                        // console.log("selectedValue - ",selectedValue);
                        this.claimDetails.aigCountry = selectedValue.originDetails[0].countryName; 
                    }else{
                      this.claimDetails.aigCountry = ""
                    }
                }else if(this.claimDetails.claimType == 'Service Claim'){
                  if(this.claimDetails.policyIssuingCompany){
                      var self  = this;
                      let selectedValue = this.policyCompanyDetails.find(function(elem){
                        return elem.policyCompany == self.claimDetails.policyIssuingCompany
                      });
                        // console.log("selectedValue - ",selectedValue);
                        // this.policyCountryDetails = 
                        this.claimDetails.policyIssuingCountry = selectedValue.originDetails[0].countryName; 
                  }else{
                      this.claimDetails.policyIssuingCountry = '';
                  }
                }
            },         
                           
            toGetCities(){
                var req="perPage=100&pageNo=1&countryName="+this.claimDetails.country;
                this.GETM("getCities", req, (res) => {
                    this.Cities = res.data.data.data
                    var other ={
                      cityDistrictName:'OTHER'
                    }
                    console.log(this.Cities)
                    if(this.Cities==undefined){
                       this.Cities=[];
                       }
                    this.Cities.push(other)
                   
                },(error)=>{
                    return error;
                });           
            },            

            getToStates(){
              
              if(this.claimDetails.transitToCountry !== ""){
                
                var req="perPage=100&pageNo=1&countryName="+this.claimDetails.transitToCountry;
                this.GETM("getCities", req, (res) => {
                    this.transitToCities = res.data.data.data
                     var other ={
                      cityDistrictName:'OTHER'
                    }
                    if(this.transitToCities==undefined){
                      this.transitToCities=[];
                    }
                    this.transitToCities.push(other)
                    console.log(this.transitToCities)
                },(error)=>{
                    return error;
                }); 
                 }
              // else{
              //     this.showProgress();
              //     var requestData = "countryName="+this.claimDetails.transitToCountry;
              //     this.GETM("getStates", requestData, (res) => {
              //     // console.log("------------states-----------",res.data.data);
              //     this.toStatesOptions = res.data.data.data;       
              //     this.hideProgress();   
              // },(error)=>{
              //    this.hideProgress();   
              //   return error;
              // });
            // }

               
              // }
             
            },

            getFromStates(){
              
              if(this.claimDetails.transitFromCountry !== ""){
                
                     var req="perPage=100&pageNo=1&countryName="+this.claimDetails.transitFromCountry;
                this.GETM("getCities", req, (res) => {
                    this.transitFromCities = res.data.data.data
                    var other ={
                      cityDistrictName:'OTHER'
                    }
                    if(this.transitFromCities==undefined){
                      this.transitFromCities=[];
                    }
                    this.transitFromCities.push(other)
                    console.log(this.transitFromCities)
                },(error)=>{
                    return error;
                }); 
                
              //   else{
              //     var requestData = "countryName="+this.claimDetails.transitFromCountry;
              //     this.showProgress();
              //     this.GETM("getStates", requestData, (res) => {
              //   // console.log("------------states-----------",res.data.data);
              //   this.fromStatesOptions = res.data.data.data;   
              //   console.log( 'forcheck',this.fromStatesOptions)
              //    this.hideProgress();    
                                          
              // },(error)=>{
              //    this.hideProgress();
              //   return error;
              // });

              // }
              }
              
            },  
           
   
            openOtherTransitCityDropdown(){
               if( this.claimDetails.transitFromCity=='OTHER'){
                 this.addothertranstiFromCity=true
               } 
               else{
                   this.claimDetails.transitFromCityOtherName=""
                  // this.$refs.othertransitform.reset()
               }
            },    

            saveOther(){
              if( this.claimDetails.transitFromCity=='OTHER'){
                 if(this.$refs.othertransitform.validate()){ 
                 this.addothertranstiFromCity=false
                 }
               }
               else{
                 this.claimDetails.transitFromCityOtherName=""
                //  this.$refs.othertransitform.reset()
               }

            },
            
            cleartransitfrom(){
              this.addothertranstiFromCity=false;
              this.claimDetails.transitFromCity="";
            },
           
            openOtherTransitToCity(){
                if( this.claimDetails.transitToCity=='OTHER'){
                 this.addothertranstiToCity=true
               } 
               else{
                  this.claimDetails.transitToCityOtherName=""
                  // this.$refs.othertransitto.reset()
               }
            },

            saveOtherTo(){
              if( this.claimDetails.transitToCity=='OTHER'){
                 if(this.$refs.othertransitto.validate()){ 
                 this.addothertranstiToCity=false
                 }
               }
               else{
                //  this.claimDetails.transitToOtherCity=""
                 this.$refs.othertransitto.reset()
               }

            },

            clearother(){
              if(this.claimDetails.transitFromCity!='OTHER'){
                this.claimDetails.transitFromCityOtherName=""
              }
            },

            cleartransitTo(){
              if(this.claimDetails.transitToCity!='OTHER'){
                this.claimDetails.transitToCityOtherName=""
              }
            },

            cleartransitto(){
              this.addothertranstiToCity=false;
              this.claimDetails.transitToCity="";
            },

            clearinternationlocationothercity(){
              if(this.claimDetails.city!='OTHER'){
                this.claimDetails.otherCityName=""
              }
            },

            clearClaimantcity(){
              if(this.claimDetails.claimant.city!='OTHER'){
               this.claimDetails.claimant.cityOtherName=""
              }
            },

            getClaimantStates(){
              if(this.claimDetails.claimant.country !== ""){
                 this.showProgress();
                var requestData = "countryName="+this.claimDetails.claimant.country;

                this.GETM("getStates", requestData, (res) => {
                  this.claimantStates = res.data.data.data; 
                    // var other ={
                    //   stateCodeName:'OTHER'
                    // }
                    // if(this.claimantStates==undefined){
                    //   this.claimantStates=[];
                    // }
                    // this.claimantStates.push(other)
                   this.hideProgress();   

                },(error)=>{
                   this.hideProgress();
                  return error;
                });
              }
            },

            getClaimantCities(){
              if(this.claimDetails.claimant.state!==""){
                this.showProgress();
               var req="stateName="+this.claimDetails.claimant.state;
                
                this.GETM("getCities", req, (res) => {
                    this.claimantCities = res.data.data.data;
                     var other ={
                      cityDistrictName:'OTHER'
                    }
                    if(this.claimantCities==undefined){
                      this.claimantCities=[];
                    }
                    this.claimantCities.push(other)
                    console.log(this.claimantCities)
                        this.hideProgress();
                },(error)=>{
                      this.hideProgress();
                    return error;
                }); 
              }
               
            },
          
            tochangeRelationship(){
              if(this.claimDetails.callerRelationshipwithInsured =="Other"){
                this.checkRelation = false;
                 this.otherelation = true;
              }else{
                this.checkRelation = true;
                this.otherelation = false;
                this.claimDetails.pleaseSpecify = "";
              }
            },            

            checkClaimValue(opt){
                this.claimDetails.claimType = opt;
                if(this.claimDetails.claimType == "Service Claim"){
                  this.AIGdisable = false; //to make aig comapany, country disable
                  this.aigClaimtrue = true; // to show star(as mandatory)
                  this.AIGmaxlen = 20;
                  this.claimCategory = ['Service Claim', 'Local Claim', 'Export Claim']
                  this.claimDetails.claimCategory = 'Service Claim'

                }else if(this.claimDetails.claimType == "New Claim"){
                  this.AIGmaxlen = 100;
                  this.aigClaimtrue = false;
                  this.claimCategory = ['Local Claim', 'Export Claim']
                  this.claimDetails.claimCategory = 'Local Claim'
                  if(this.claimDetails.claimCategory !== "Export Claim"){
                      this.AIGdisable = true;
                  }else{
                      this.AIGdisable = false;  
                  }

                }else{
                  this.claimCategory = ['Local Claim', 'Export Claim']
                  this.claimDetails.claimCategory = 'Local Claim'
                }
            },

            changeAIG(){
              if(this.claimDetails.claimType !== "Service Claim"){
                if(this.claimDetails.claimCategory == "Export Claim"){
                  this.AIGdisable = false;
                  this.claimDetails.internationalLocation = true;
                  // minLength();
                }else{
                  this.claimDetails.internationalLocation = false;
                  this.AIGdisable = true;
                  this.claimDetails.aigSettlingCompany = "";
                  this.claimDetails.aigCountry = "";
                  this.claimDetails.aigClaimNumber="";
                }
              }else{
                if(this.claimDetails.claimCategory == "Export Claim"){
                    this.claimDetails.internationalLocation = true;
                }else{
                    this.claimDetails.internationalLocation = false;
                }
              }
            },

            //-------------------------dialog box functions----------------------
            openStorageDes(){
                this.storageDescrptionMenu = true;
                // this.storageIndex   =   "";
            },

            openCoverageDes(){
              if(this.coverageDescrptionOptions.length > 0){
                this.coverageDescrptionMenu = true;
                this.coverageOptions=this.coverageDescrptionOptions;
                // this.coverageIndex    = "";
              }
            },

            openMobileDialog(){
              this.addInsureddialog = true;
            },

            validateIsd(v){
              let isdExp = /^[0-9]+$/;
              if (v && !(isdExp.test(v))) {
                return 'Invalid ISD code'
              } else {
                return true;
              }
            },

            addInsuredMobileno(isd, number){
           
              var regexp = /^[0-9]+/;
              if(isd !== ""){
                  if(regexp.test(number) && number.length == 10){
                      this.claimDetails.insuredMobileNumber.push({
                                              isd:isd,
                                              number:number
                                            })
                      this.claimDetails.insuredMobileNumber =                        
                      this.claimDetails.insuredMobileNumber.reduce((unique, o) => {
                                  if(!unique.some(obj => obj.number === o.number &&   obj.isd === o.isd)) {
                                    unique.push(o);
                                  }
                                  return unique;
                      },[]);
                      this.tempMobileNumber="";
                      this.tempIsdCode="";
                  }
                  if(this.claimDetails.insuredMobileNumber.length > 0){
                    this.insuredMobileNumber = this.claimDetails.insuredMobileNumber[0].number;
                    this.insuredMobileISD=this.claimDetails.insuredMobileNumber[0].isd;
                  }
              }
              

              // console.log('000000000000',myData);
              // myData = myData.filter(function(elem, index, self){ return self.indexOf(elem) == index });
              
              // // console.log('111111111111111111',myData);
              // // if (indexof !== -1) {
              //   this.claimDetails.insuredMobileNumber = [];
               
              //   for (let i in myData) {
              //     if (myData[i] !== "" ) {
              //       if (regexp.test(myData[i]) && myData[i].length == 10) {
              //         this.claimDetails.insuredMobileNumber.push(myData[i]);
              //        console.log('-----------------', this.claimDetails.insuredMobileNumber[0])
              //       }
              //     }
              //   }
            
                  console.log(this.claimDetails.insuredMobileNumber)
              // }
            },

            addTempMobileno(isd,number){
              var regexp = /^[0-9]+$/;
              // console.log(value)
              if(isd !== ""){
                  if(regexp.test(number) && number.length == 10){
                      
                      this.claimDetails.insuredMobileNumber.push({
                                              isd:isd,
                                              number:number
                                            })
                      // console.log(this.claimDetails.insuredMobileNumber)
                      if(this.claimDetails.insuredMobileNumber.length>0){
                        this.claimDetails.insuredMobileNumber = 
                        this.claimDetails.insuredMobileNumber.reduce((unique, o) => {
                                  if(!unique.some(obj => obj.number === o.number && obj.isd === o.isd)) {
                                    unique.push(o);
                                  }
                                  return unique;
                        },[]);
                        console.log("this.claimDetails.insuredMobileNumber",this.claimDetails.insuredMobileNumber)
                      }
                      this.tempMobileNumber="";
                      this.tempIsdCode="";
                  }
              }
              if(this.claimDetails.insuredMobileNumber.length > 0){
                this.insuredMobileNumber = this.claimDetails.insuredMobileNumber[0].number;
                this.insuredMobileISD=this.claimDetails.insuredMobileNumber[0].isd;
              }
              // console.log(this.claimDetails.insuredMobileNumber)
            },          

            removeMobile(index){
              this.claimDetails.insuredMobileNumber.splice(index,1);
              if(this.claimDetails.insuredMobileNumber.length == 0){
                this.insuredMobileNumber = '';
                this.insuredMobileISD = '';
              } 
              // console.log('hjdfj',item);
              // var arr = this.insuredMobileNumber.split(",");
              // arr.splice(this.claimDetails.insuredMobileNumber.indexOf(item), 1);
              // this.insuredMobileNumber = arr.toString();
              // this.claimDetails.insuredMobileNumber.splice(this.claimDetails.insuredMobileNumber.indexOf(item), 1);
              // this.claimDetails.insuredMobileNumber = [...this.claimDetails.insuredMobileNumber];
              //Surendra(19-06-2021):Bug fix, if first mobile number is deleted, first number should be populated on main UI,starts here
              if(this.claimDetails.insuredMobileNumber.length > 0){
                    this.insuredMobileNumber    =   this.claimDetails.insuredMobileNumber[0].number;
                    this.insuredMobileISD       =   this.claimDetails.insuredMobileNumber[0].isd;
              }else{
                this.insuredMobileNumber        =   '';
                this.insuredMobileISD           =   '';
              }
              //Surendra(19-06-2021):Bug fix, if first mobile number is deleted, first number should be populated on main UI,ends here    
            },

            openEmailDialog(){
                this.addEmaildialog = true;
            },   
            
            disclaimerAdded(){
              this.snackbar = true;
            },            
            
            addEmailIDs(value) {

              let data = value;
              var regexp = /^([a-zA-Z0-9_.-])+@([a-zA-Z0-9_.-])+\.([a-zA-Z])+([a-zA-Z])/;
              let indexof = this.emailIdForCommunication.indexOf(",");
              let myData = data.split(",");

              myData = myData.filter(function(elem, index, self){ return self.indexOf(elem) == index })

              // if (indexof !== -1) {
                this.claimDetails.emailIdForCommunication = [];
                for (let i in myData) {
                  if (myData[i] !== "") {
                    if (regexp.test(myData[i])) {
                      this.claimDetails.emailIdForCommunication.push(myData[i]);

                      // console.log('-----------------', this.claimDetails.emailIdForCommunication[0])
                    }
                  }
                }
                this.emailIdForCommunication = myData.toString();
              // }
            },

            addTempEmailIDs(value){
              var regexp = /^([a-zA-Z0-9_.-])+@([a-zA-Z0-9_.-])+\.([a-zA-Z])+([a-zA-Z])/;
              // console.log(value)
              if(regexp.test(value)){
              this.claimDetails.emailIdForCommunication.push(value)
              // console.log(this.claimDetails.emailIdForCommunication)
              this.claimDetails.emailIdForCommunication = this.claimDetails.emailIdForCommunication.filter(function(elem, index, self){ return self.indexOf(elem) == index });
              this.TempemailIdForCommunication=""
              }
              if(this.claimDetails.emailIdForCommunication){
                this.emailIdForCommunication = this.claimDetails.emailIdForCommunication[0].toString();
              }
            },

            remove(item) {

              console.log('item',item);

              var arr = this.emailIdForCommunication.split(",");
               arr.splice(this.claimDetails.emailIdForCommunication.indexOf(item), 1);
              this.emailIdForCommunication =arr.toString();

              this.claimDetails.emailIdForCommunication.splice(this.claimDetails.emailIdForCommunication.indexOf(item), 1);
              this.claimDetails.emailIdForCommunication = [...this.claimDetails.emailIdForCommunication];
              

            },

            opencallerEmaildialog(){
              this.addcallerEmaildialog = true;
            },

            addCallerEmailIDs(value){
                let data = value;
                var regexp = /^([a-zA-Z0-9_.-])+@([a-zA-Z0-9_.-])+\.([a-zA-Z])+([a-zA-Z])/;
                let indexof = this.callerEmailID.indexOf(",");
                let myData = data.split(",");

                myData = myData.filter(function(elem, index, self){ return self.indexOf(elem) == index })
                // if (indexof !== -1) {
                  this.claimDetails.callerEmailID = [];
                  for (let i in myData) {
                    if (myData[i] !== "") {
                      if (regexp.test(myData[i])) {
                        this.claimDetails.callerEmailID.push(myData[i]);

                        // console.log('-----------------', this.claimDetails.callerEmailID)
                      }
                    }
                  }
                  this.callerEmailID = myData.toString();
                // }
            },

            addTempCallerEmailIDs(value){
               var regexp = /^([a-zA-Z0-9_.-])+@([a-zA-Z0-9_.-])+\.([a-zA-Z])+([a-zA-Z])/;
                // console.log(value)
                if(regexp.test(value)){
                  this.claimDetails.callerEmailID.push(value)
                  // console.log(this.claimDetails.callerEmailID)
                  this.claimDetails.callerEmailID = this.claimDetails.callerEmailID.filter(function(elem, index, self){ return self.indexOf(elem) == index });
                  this.TempcallerEmailID=""
                }
                  if(this.claimDetails.callerEmailID){
                    this.callerEmailID = this.claimDetails.callerEmailID[0].toString();
                  }
            },

            removeCallerEmail(item){
                 console.log('item',item);

              var arr = this.callerEmailID.split(",");
              arr.splice(this.claimDetails.callerEmailID.indexOf(item), 1);
              this.callerEmailID =arr.toString();

              this.claimDetails.callerEmailID.splice(this.claimDetails.callerEmailID.indexOf(item), 1);
              this.claimDetails.callerEmailID = [...this.claimDetails.callerEmailID];
            },

            // ----------Submit, Save, Return functions---------------------
            submitFNOL() {
              // console.log(this.claimDetails)
                  var dateofLoss = new Date(this.dateOfLoss).toISOString();
                  this.claimDetails.dateOfLoss = dateofLoss;
             
                
                  if(this.$refs.fnolform.validate()){
                                        
                   // var reportedDate = new Date(this.claimDetails.reportedDate).toISOString();old code for reportedDate 
                    var reportedDate = new Date(this.reportedDate).toISOString();//mc-744 change
                    this.claimDetails.reportedDate = reportedDate;  
                    
                    // if(this.claimDetails.dateDifference !== null && this.claimDetails.dateDifference !== undefined 
                    // && this.claimDetails.dateDifference !== "")  {
                    //       this.claimDetails.dateDifference = new Date().toISOString();
                    // }
                    
                    if(this.$store.getters.fetchFnolPageEditCheck !== "FnolNewPage"){
                      // console.log('=============>FnolEdit page')
                     
                      // delete this.claimDetails.mainClaimNumber;
                            

                      // this.claimDetails.dateDifference = ""
                      if(this.claimDetails.dateOfLoss == undefined || this.claimDetails.dateOfLoss == null){
                        this.claimDetails.dateOfLoss = "";
                      }

                      for(var i = 0; i< this.claimDetails.invoice.length; i++){
                        if(this.claimDetails.invoice[i].date == null || this.claimDetails.invoice[i].date =="Invalid date"){
                          this.claimDetails.invoice[i].date = "";
                        }                                     
                      }
                      
                      for(var i = 0; i< this.claimDetails.awbNumberAndDate.length; i++){
                        if(this.claimDetails.awbNumberAndDate[i].date == null || 
                            this.claimDetails.awbNumberAndDate[i].date == "Invalid date"){
                            this.claimDetails.awbNumberAndDate[i].date =""
                        }
                      }

                            delete this.claimDetails.isTempClaim;
                            delete this.claimDetails.createdBy;
                            delete this.claimDetails.serialNumber;
                            delete this.claimDetails.createdById;
                            delete this.claimDetails.createdAt;
                            delete this.claimDetails.updatedAt;                                 
                            delete this.claimDetails.__v;
                            // change for all claims need to send proposalNo key 30th aprial 2021
                            if(this.claimDetails.claimType == "New Claim"){
                               delete this.claimDetails.featureNumber;
                            }
                            
                    }

                    for(var i=0; i<this.claimDetails.awbNumberAndDate.length;i++){
                        delete this.claimDetails.awbNumberAndDate[i].lRAWBBLdatemenu;
                    }
                    for(var i=0; i<this.claimDetails.invoice.length;i++){
                        delete this.claimDetails.invoice[i].invoiceDatemenu;
                    }                                  
                                 let URLRequest;
                                if(this.claimDetails.withoutpolicyno == true){ 
                                  //this.claimDetails.policyNumber !== ''
                                    this.claimDetails.claimStatus = 'Suspended';
                                    this.claimDetails.reasonForSuspense = 'Policy number not available/not found';
                                }else if(this.claimDetails.withoutpolicyno == false){
                                    this.claimDetails.claimStatus = 'Open';
                                    this.claimDetails.reasonForSuspense = '';
                                }
                              if(this.claimDetails.claimType == "New Claim"){
                                URLRequest="Create_Fnol";
                                delete this.claimDetails. policyIssuingCompany;
                                delete this.claimDetails.policyIssuingCountry;
                                delete this.claimDetails. policyInceptionDate;
                                delete this.claimDetails.policyExpiryDate;
                                delete this.claimDetails.addressOfSurvey;
                                delete this.claimDetails.carrierName;
                                delete this.claimDetails.featureClaimType;                                  
                              }else if(this.claimDetails.claimType == "Feature Claim"){
                                  URLRequest="CreateFeature_Fnol";
                                  if(this.claimDetails.mainClaimNumberID==undefined){
                                     this.claimDetails.mainClaimNumberID=this.claimDetails._id;
                                     delete this.claimDetails._id;
                                  }
                                  if(this.claimDetails.featureClaimType !=="Service Claim"){
                                      delete this.claimDetails. policyIssuingCompany;
                                      delete this.claimDetails.policyIssuingCountry;
                                      delete this.claimDetails. policyInceptionDate;
                                      delete this.claimDetails.policyExpiryDate;
                                      delete this.claimDetails.addressOfSurvey;
                                      delete this.claimDetails.carrierName;
                                  }
                              }else{
                                
                                var inceptionDate = new Date(this.inceptionDate).toISOString();
                                this.claimDetails.policyInceptionDate = inceptionDate;

                                var expiryDate = new Date(this.expiryDate).toISOString();
                                this.claimDetails.policyExpiryDate = expiryDate;
                                delete this.claimDetails.policyStartDate;
                                delete this.claimDetails.featureClaimType;    

                                URLRequest="submitServiceClaim_Fnol";
                              }
                     console.log("<<<=====FNOLsubmit request======>>>>>>>>>",JSON.stringify(this.claimDetails));
                     this.showProgress("Claim is submitting, Please wait");
                     this.POSTM(URLRequest, this.claimDetails, (res,error) => {
                         console.log('res.data',res.data);
                        //"claimStatus":"Suspended","reasonForSuspense":"Policy number not available/not found"
                          if(this.$store.getters.fetchFnolPageEditCheck == "FnolNewPage" && res.data.data.mainClaimNumber){
                            this.dialogBoxText = "The Claim is submitted successfully\n" +"-"+res.data.data.mainClaimNumber
                            if(res.data.data.claimStatus == "Suspended"){
                              //Surendra(25-03-2021):ReasonForSuspense should be shown if status is Suspended
                              this.dialogBoxText =  this.dialogBoxText +  ". Reason for suspense is " + res.data.data.reasonForSuspense
                            }
                            //Surendra(25-03-2021):Change in Alert because of msg size
                             this.showAlert(this.dialogBoxText,"OK","", (OK) => {
                                    this.$router.push("/home");
                              },(No)=>{})
                          }else if(res.data.data.mainClaimNumberID){
                            this.dialogBoxText = res.data.msg+"\n FeatureNumber- "+res.data.data.featureNumber;
                            if(res.data.data.claimStatus == "Suspended"){
                              //Surendra(25-03-2021):ReasonForSuspense should be shown if status is Suspended
                              this.dialogBoxText =  this.dialogBoxText +  ". Reason for suspense is " + res.data.data.reasonForSuspense
                            }
                            //Surendra(25-03-2021):Change in Alert because of msg size
                              this.showAlert(this.dialogBoxText, "OK","", (OK) => {
                                    this.$router.push("/home");
                              },(No)=>{})
                          }
                          else if(res.data.mainClaimNumber){
                            this.dialogBoxText = "The Claim is updated successfully\n" +" - "+res.data.mainClaimNumber
                            if(res.data.data.claimStatus == "Suspended"){
                              //Surendra(25-03-2021):ReasonForSuspense should be shown if status is Suspended
                              this.dialogBoxText =  this.dialogBoxText +  ". Reason for suspense is " + res.data.data.reasonForSuspense
                            }
                            //Surendra(25-03-2021):Change in Alert because of msg size
                            this.showAlert(this.dialogBoxText, "OK","", (OK) => {
                                    this.$router.push("/home");
                              },(No)=>{})
                          }else{
                            this.dialogBoxText = "The Claim is updated successfully"
                            if(res.data.data.claimStatus == "Suspended"){
                              //Surendra(25-03-2021):ReasonForSuspense should be shown if status is Suspended
                              this.dialogBoxText =  this.dialogBoxText +  ". Reason for suspense is " + res.data.data.reasonForSuspense
                            }
                            //Surendra(25-03-2021):Change in Alert because of msg size
                             this.showAlert(this.dialogBoxText,"OK","", (OK) => {
                                    this.$router.push("/home");
                              },(No)=>{})
                            
                          }
                          this.submitDisable=true;
                          this.$store.commit("FNOL_PAGE_EDIT_CHECK", "FnolNewPage");
                          let selected={claimType: "", claimId: ""};
                          this.$store.commit("SAVE_LANDINGPAGE_ID", {data: selected});
                          this.hideProgress();    
                      },(error)=>{
                         this.hideProgress();
                         console.log("error response--->",error.response);
                        if(error.response.data.msg){
                          this.showAlert(error.response.data.msg);
                        }else if(error.response.data.message){
                           this.showAlert(error.response.data.message);
                         }
                        else{
                          this.showAlert("Some thing went wrong");
                        }
                    });
                  }else{
                    this.showAlert("Please enter all mandatory fields.");
                  }
            },

            saveFNOL() {
                if(this.claimDetails.insuredName !== "" && this.dateOfLoss){
                  this.$refs.fnolform.resetValidation();
                      // Date Of Loss
                      if(this.dateOfLoss){
                          var dateofLoss = new Date(this.dateOfLoss).toISOString();
                          this.claimDetails.dateOfLoss = dateofLoss;
                          console.log("if",this.claimDetails.dateOfLoss,this.dateOfLoss)
                      }else{
                        this.claimDetails.dateOfLoss = "";
                        console.log("else",this.claimDetails.dateOfLoss,this.dateOfLoss)
                      }
                      
                      // if(this.claimDetails.policyStartDate!="" || this.claimDetails.policyStartDate!=undefined){
                      //   console.log(this.claimDetails.dateOfLoss)
                      //   console.log(this.claimDetails.policyStartDate)
                      //   this.claimDetails.dateDifference = new Date( this.claimDetails.dateOfLoss).getTime() - new Date( this.claimDetails.policyStartDate).getTime()
                      //   console.log('dtdiffon save',this.claimDetails.dateDifference)
                      // }
                      // reported date
                      var reportedDate = new Date(this.claimDetails.reportedDate).toISOString();
                      this.claimDetails.reportedDate = reportedDate;
                      
                      //Date difference
                      // if(this.claimDetails.dateDifference !== null && this.claimDetails.dateDifference !== undefined 
                      // && this.claimDetails.dateDifference !== "")  {
                      //       this.claimDetails.dateDifference = new Date().toISOString();
                      // }

                      // console.log('---->', this.claimDetails.dateOfLoss)                               
                      //tables menu deleted
                      for(var i=0; i<this.claimDetails.awbNumberAndDate.length;i++){
                          delete this.claimDetails.awbNumberAndDate[i].lRAWBBLdatemenu;
                      }
                      for(var i=0; i<this.claimDetails.invoice.length;i++){
                          delete this.claimDetails.invoice[i].invoiceDatemenu;
                      }               

                      //already saved record
                          if(this.$store.getters.fetchFnolPageEditCheck !== "FnolNewPage"){

                                delete this.claimDetails.proposalNo;
                                delete this.claimDetails.policyStartDate;
                                delete this.claimDetails.isTempClaim;
                                delete this.claimDetails.createdBy;
                                delete this.claimDetails.serialNumber;
                                delete this.claimDetails.createdById;
                                delete this.claimDetails.createdAt;
                                delete this.claimDetails.updatedAt;
                                delete this.claimDetails.__v;
                              if(!this.claimDetails.dateOfLoss){
                                  this.claimDetails.dateOfLoss = "";
                                  console.log('ifffff')
                              }

                              for(var i = 0; i< this.claimDetails.invoice.length; i++){
                                if(this.claimDetails.invoice[i].date == null || this.claimDetails.invoice[i].date =="Invalid date"){
                                  this.claimDetails.invoice[i].date = "";
                                }                                     
                              }
                              
                              for(var i = 0; i< this.claimDetails.awbNumberAndDate.length; i++){
                                if(this.claimDetails.awbNumberAndDate[i].date == null || 
                                    this.claimDetails.awbNumberAndDate[i].date == "Invalid date"){
                                    this.claimDetails.awbNumberAndDate[i].date =""
                                }
                              }

                              if(this.claimDetails.claimType == "New Claim") {
                                delete this.claimDetails.featureNumber; 
                                delete this.claimDetails.mainClaimNumber;
                              }
                          } 
                                
                                let URLRequest;
                                if(this.claimDetails.claimType == "New Claim"){
                                    URLRequest="save_Fnol";
                                      delete this.claimDetails.featureClaimType;    
                                }else if(this.claimDetails.claimType == "Feature Claim"){
                                    URLRequest="saveFeature_Fnol";
                                    this.claimDetails.mainClaimNumberID=this.claimDetails._id;
                                    delete this.claimDetails._id;
                                    delete this.claimDetails. policyIssuingCompany;
                                    delete this.claimDetails.policyIssuingCountry;
                                    delete this.claimDetails. policyInceptionDate;
                                    delete this.claimDetails.policyExpiryDate;
                                    delete this.claimDetails.addressOfSurvey;
                                    delete this.claimDetails. carrierName;
                                }else{
                                    if(this.inceptionDate){
                                      var inceptionDate = new Date(this.inceptionDate).toISOString();
                                      this.claimDetails.policyInceptionDate = inceptionDate;
                                    }else{
                                      this.claimDetails.policyInceptionDate = ''
                                    }
                                    
                                    if(this.expiryDate){
                                      var expiryDate = new Date(this.expiryDate).toISOString();
                                      this.claimDetails.policyExpiryDate = expiryDate;
                                    }else{
                                      this.claimDetails.policyInceptionDate = '';
                                    }
                                  delete this.claimDetails.proposalNo;
                                  delete this.claimDetails.policyStartDate;
                                  delete this.claimDetails.featureClaimType;    
                                  URLRequest="save_Fnol"
                                }

                          console.log("request Sending save----------->", JSON.stringify(this.claimDetails))

                          this.showProgress("Claim is saving, Please wait");
                          this.POSTM(URLRequest, this.claimDetails, (res,error) => {
                          
                          if(!error){
                               this.dialogBoxText = "The Claim is saved successfully" 
                                    this.showAlert(this.dialogBoxText,"OK","",(Ok) => {
                                    this.$router.push("/home");
                              },(No)=>{})
                          }
                            this.hideProgress();
                      },(error)=>{
                         this.hideProgress();
                          console.log("error resp- ",error.response.data.msg)
                          this.message = error.response.data.msg;
                          this.showAlert(this.message);
                         
                      });
                }else{
                   this.message = "Insured Name & Date Of Loss fields are required"
                   this.showAlert(this.message);
                }
            },

            returnFNOL(){
              this.showConfirm("Confirmation","Are you sure to Return","Yes","No",(Yes)=>{
                     this.$router.push('/home');
                  },(No)=>{})      
            },

            toHomePage(){
               // this.dialogbox = false;
                this.$router.push('/home');
            },
            
            clearFNOL(){
               this.showConfirm("Confirmation","Are you sure to Clear FNOL Page","Yes","No",(Yes)=>{
                  this.dateOfLoss = null,
                  this.isPolicyLoaded   = false;
                  this.computedDateofloss='';
                  this.insuredMobileNumber='';
                  this.insuredMobileISD='91';
                  this.callerEmailID='';
                  this.emailIdForCommunication='';
                   this.claimDetails= {
                      claimType:'New Claim',
                      policyNumber : '',
                      dateOfLoss : '',//date ISOformat
                      lossTime : '', //time

                      insuredName : '',
                      insuredID : '',
                      certificateNumber : '',
                      applicationNumber : '',
                      productName : '',
                      lob:'',
                      eMarinePolicyNumber:'',

                      claimCategory : 'Local Claim',
                      typeOfClaim : '',
                      sumInsured:'',
                      reserveAmount:'10000',//Surendra(17-04-2021):Uncommented for Shubham(Backend) to map value in GC
                      customerClaimNumber:'',
                      callerIntimatorIdentification:'',
                      claimIntimatedThrough:'',
                      callerName : '',
                      callerRelationshipwithInsured : '',
                      pleaseSpecify : '',
                      withoutpolicyno: false,

                      callerMobileNo: {
                      isd:'91',
                      number:''
                      },
                      callerEmailID : [],
                      insuredMobileNumber : [],
                      insuredEmailID : '',
                      emailIdForCommunication : [],
                      reportedDate : moment().format('YYYY-MM-DD'),//date
                      reportedTime : moment().format('HH:mm'),//time
                      dateDifference : '',//date
                      addressOfLossLocation : '',
                      pinCodeOfLossLocation : '',

                      //table values //"number":"1","date":""
                      awbNumberAndDate:[{"number":"","date":""}],
                      invoice:[{"number":"","date":"","amount":""}],

                      nameOfContactPersonAtLossLocation : '',
                      contactNumberAtLossLocation :{
                      isd:'91',
                      number:''
                      },
                      lossDescription : '',
                      propertyDamaged : '',
                      coverageDescription : '',
                      coverageCode : '',
                      estimatedLossAmountInINR : '',
                      storageDescription : '',
                      storageCode : '',
                      unnamedStorageLocation : '',

                      transitTo: "",
                      transitFrom: "",

                      transporterVesselName : '',
                      transitToCountry : '',
                      transitToState : '',
                      transitToCity:'',

                      transitFromCountry : '',
                      transitFromState : '',
                      transitFromCity:'',
                      
                      isInsuredTheClaimant : false,
                      claimant : {
                      name:'',
                      addressLineOne:'',
                      addressLineTwo:'',
                      addressLineThree:'',
                      country:'',
                      state:'',
                      city:'',
                      // district:'',
                      pincode:'',
                      mobileNo: {
                            isd:'91',
                            number:''
                      },
                      contactNo: {
                          isd:'91',
                          number:''
                      },
                      emailID:''
                      },
                      internationalLocation : false,
                      locationLandmark : '',
                      country : '',
                      city : '',
                      aigSettlingCompany : '',
                      aigCountry : '',
                      aigClaimNumber : '',
                      isEventACatastrophe : false,
                      catastropheDescription : '',
                      catastropheCode : '',
                      catastropheDetail : '',
                      catastropheType : '',

                      claimStatus:'Suspended',
                      reasonForSuspense:'',
                      // Estimatedblank:false,
                      isTataEmp : false,
                      remarks : '',
                      notes : '',
                      detailsOne: '',
                      detailsTwo: '',
                      detailsThree : '',
                      detailsFour : '',

                      proposalNo: '',
                      policyStartDate:'',
                      featureClaimType:''
                      // mainClaimNumber:''
                      // policyStartDt: '',
                      // policyEndDt: '',
                      // businessType: '',
                      // lrNo: '',
                      // activeFlag: '',
                      // claimSubStatus: ''

                   }
                   },(No)=>{})   
            },

            restPolicyDataFields(){
                      this.claimDetails.insuredName = "";
                      this.claimDetails.insuredID = "";
                      this.claimDetails.certificateNumber = "";
                      this.claimDetails.applicationNumber = "";
                      this.claimDetails.productName = "";
                      this.claimDetails.lob = "Marine Cargo";
                      this.claimDetails.eMarinePolicyNumber = "";
                      this.claimDetails.proposalNo = "";
                      this.claimDetails.claimStatus = "Suspended";
                      this.claimDetails.reasonForSuspense = "";
                      this.storageDescrptionOptions = [];
                      this.coverageDescrptionOptions=[];
                      this.claimDetails.policyNumber="";
                      this.insuredMobileNumber= "";
                      this.claimDetails.insuredEmailID="";
                      this.claimDetails.policyStartDate="";
                      this.policyEndDate="";
                      this.policyClaimantDetails = {
                              addressLine1: '',
                              addressLine2:'',
                              addressLine3 :'',
                              cityDistrict: '',
                              state :'',
                              pincode:'',
                              countryname:''
                      };
                      this.isPolicyLoaded   = false;//Surendra(19-03-2021):MC577-Disable if policy data is loaded
            },

            openPolicyData() {
              // policy search request date of loss change to DD/MM/YYYY
              let requestData = {
                policyNo: this.claimDetails.policyNumber,
                insuredName: "",
                insuredId: "",
                certificateNo: "",
                applicationNo: "",
                eMarinePolicyNo: "",
                lrNumber: "",
                invoiceNumber: "",
                lossTime: this.claimDetails.lossTime,
                dateOfLoss: this.computedDateofloss,
                proceedSearch : true  
              };
              this.showProgress("Data is fetching ,please wait");
              this.POST_ByURLM("GET_POLICYSEARCH", requestData, (res) => {
                
                 if( res.data && res.data.data && res.data.data.data && res.data.data.data[0].proposalDtls){
                            let finalResponse=res.data.data.data[0]
                            let policyData = res.data.data.data[0].proposalDtls[0];
                            let marineDtls=res.data.data.data[0].marineDtls[0];

                            // this.claimDetails.claimStatus = finalResponse.claimStatus;
                            // this.claimDetails.reasonForSuspense = finalResponse.reasonForSuspense;                                                       
                            
                            if(finalResponse.claimSubStatus=="Expired"){

                              // this.classNameDialog = "errorDailogboxMsg";
                               this.dialogBoxText = "Loss date is not within Policy Period"; 
                              //  this.dateOfLoss = null
                               this.restPolicyDataFields();
                            }
                            else if(finalResponse.claimSubStatus!=="Expired"){

                                console.log('======================>policyData',policyData)
                                this.classNameDialog = "successDailogboxMsg";

                                if(finalResponse.claimSubStatus=="Cancelled"){
                                  this.dialogBoxText=res.data.msg +" and Policy in cancelled status."; 
                                }else{
                                  this.dialogBoxText = res.data.msg;  
                                }                                

                              this.claimDetails.insuredName = policyData.insuredName
                              // this.claimDetails.certificateNumber = policyData.certificateNumber;
                              // this.claimDetails.applicationNumber = policyData.applicationNumber;
                              this.claimDetails.productName = policyData.txtProductName;
                              this.claimDetails.lob = "Marine Cargo";
                              this.claimDetails.eMarinePolicyNumber = policyData.eMarinePolicyNumber == undefined ? "" : policyData.eMarinePolicyNumber;
                              this.claimDetails.proposalNo = policyData.proposalNumber;
                              this.tempIsdCode = '91'
                              if(policyData.insuredMobileNumber) {
                                
                                  this.insuredMobileISD           =   '91';
                                  this.insuredMobileNumber        =   policyData.insuredMobileNumber;
                              
                              //Surendra(19-03-2021):Add Insured mobile number to Array,starts here
                                this.claimDetails.insuredMobileNumber.push({
                                  "isd":"91",
                                  "number":policyData.insuredMobileNumber
                                })
                              }
                              //Surendra(19-03-2021):Add Insured mobile number to Array,ends here
                              this.claimDetails.insuredEmailID=policyData.insuredEmailId;
                              this.claimDetails.policyStartDate=policyData.policyStartInceptionDate;
                              this.policyEndDate=policyData.policyExpiryDate==undefined ?"": policyData.policyExpiryDate;
                            // this.claimDetails.insuredID = policyData.insuredId==undefined ? policyData.txtCustomerId: policyData.insuredId;
                              if(marineDtls!=undefined){
                                  this.claimDetails.sumInsured=marineDtls.sumInsured==undefined ? "": marineDtls.sumInsured;
                              }else{     
                                  this.claimDetails.sumInsured=""
                              }
                              
                                // if(PolicyDeatis.length!==0 ||PolicyDeatis.length!==undefined ){
                                //   this.claimDetails.insuredID = policyDatails.insuredId==undefined ?"": policyDatails.insuredId;
                                // }
                                  //   if(this.claimDetails.policyStartDate!==""){
                                  //       const lossEnd = moment(requestData.dateOfLoss).format("DD-MM-YYYY")
                                  //       const policy = moment(this.claimDetails.policyStartDate).format("DD-MM-YYYY")
                                  //       let startDate = moment(policy, 'DD-MM-YYYY');
                                  //       let endDate = moment(lossEnd, 'DD-MM-YYYY');
                                  //       let dayDiff = endDate.diff(startDate, 'days');
                                  //       let monthDiff = endDate.diff(startDate, 'months');
                                  //       let yearDiff = endDate.diff(startDate, 'years');
                                  //       if(yearDiff==0){
                                  //       this.claimDetails.dateDifference=monthDiff.toString() + ' months ' + dayDiff.toString() + ' days'             
                                  //       }else if(yearDiff==0 && monthDiff==0){
                                  //       this.claimDetails.dateDifference= dayDiff.toString() + ' days'             
                                  //       }
                                  //       else{
                                  //        this.claimDetails.dateDifference=yearDiff.toString() + ' years ' + monthDiff.toString() + ' months ' + dayDiff.toString() + ' days'
                                  //       }
                                  // }else{
                                  //      this.claimDetails.dateDifference="";
                                  // }
                                if(this.claimDetails.policyStartDate!==""){
                                 // const lossEnd = moment(requestData.dateOfLoss).format("DD-MM-YYYY")
                                  const [day, month, year] = requestData.dateOfLoss.split("/");
                                  const lossEnd =`${day}-${month}-${year}`;
                                  const policy = moment(this.claimDetails.policyStartDate).format("DD-MM-YYYY");
                                  const date1 = moment(lossEnd, 'DD-MM-YYYY');
                                  const date2 =  moment(policy, 'DD-MM-YYYY');

                                  
                                  
                                  if(date1>date2){
                                  var diffmonths = date1.diff(date2, 'months');
                                  // console.log('month',diffmonths)
                                  date2.add(diffmonths, 'months');
                                  var diffdays = date1.diff(date2, 'days')
                                  // console.log('days',diffdays)
                                  
                                    if(diffmonths==0){
                                      this.claimDetails.dateDifference= diffdays.toString() + ' days'
                                    }else if(diffdays==0){
                                      this.claimDetails.dateDifference= diffmonths.toString() + ' Months'
                                    }
                                    else{
                                      this.claimDetails.dateDifference= diffmonths+ " "+ "Months" + " " + diffdays+ " " + "Days"
                                    }
                                  }
                                }
                              this.policyClaimantDetails = {
                                addressLine1: policyData.addressLine1,
                                addressLine2:policyData.addressLine2,
                                addressLine3 :policyData.addressLine3,
                                cityDistrict: policyData.cityDistrict,
                                state :policyData.state,
                                pincode:policyData.pincode,
                                countryname:policyData.countryName,
                                mobileNumber:policyData.insuredMobileNumber,
                                emailId:policyData.insuredEmailId
                              }

                              // this.claimDetails.policyStartDt = policyData.policyStartInceptionDate;
                              // this.claimDetails.policyEndDt = policyData.policyExpiryDate;
                              // this.claimDetails.businessType = policyData.txtBusinessType;
                              // this.claimDetails.lrNo = res.data.data.data.marineDtls[0].lrNumber;
                              // this.claimDetails.activeFlag = policyData.txtActiveFlag;
                              // this.claimDetails.claimSubStatus=res.data.data.data.claimSubStatus;
                              
                              
                              this.loadStorageAndCoverageDesc(); //Surendra(27Feb2021)- Get Storage and Coverage Desc options
                              this.updateCoverageOptions(); //Surendra(27Feb2021)- Update coverage options on product name
                              this.disableCoverageCode();

                              this.productnames = [];//Surendra(02-03-2021):Show relevant policy options only
                              this.productnames.push({"productName":policyData.txtProductName})//Surendra(02-03-2021):Show relevant policy options only
                          

                              //Surendra(05-03-2021):Check Storage length for policy and update options for type of claim.starts here
                              if(res.data && res.data.data && res.data.data.data && finalResponse.storageDetails && finalResponse.storageDetails.length > 0){
                                this.typeOfClaim   = ["Transit", "Storage"];                             
                              }else{
                                this.typeOfClaim   = ["Transit"];
                              }
                              console.log('last check',this.typeOfClaim)
                              //Surendra(05-03-2021):Check Storage length for policy and update options for type of claim.ends here
                              this.isPolicyLoaded   = true;//Surendra (19-03-2021):MC577  and gopi (6thapr2021 MC-759)Disable if policy data is loaded 
                              //Surendra(23-03-2021):Update Caller Name and Claimnt Name based on respective values,code starts here
                                if(this.claimDetails.callerIntimatorIdentification == "Insured"){
                                  this.claimDetails.callerName = policyData.insuredName
                                }else if(!this.claimDetails.callerName){
                                  this.claimDetails.callerName = ''
                                }

                                if(this.claimDetails.isInsuredTheClaimant){
                                  this.claimDetails.claimant.name = policyData.insuredName
                                }else if(!this.claimDetails.claimant.name){
                                  this.claimDetails.claimant.name = '';
                                }
                                 //Surendra(23-03-2021):Update Caller Name and Claimnt Name based on respective values,code ends here
                            }
                            //Surendra(05-05-2021):If coverage desc is selected check if it matches the marindtls or not,starts here
                            var self = this;
                            if(marineDtls && this.claimDetails.coverageDescription && res.data.data.data[0].marineDtls.length > 0 && !res.data.data.data[0].marineDtls.find(function(elem){
                              return self.claimDetails.coverageDescription == elem.coverGroupName
                            })){
                              this.claimDetails.coverageDescription   = ""
                              this.claimDetails.coverageCode          = ""
                            }
                            //Surendra(05-05-2021):If coverage desc is selected check if it matches the marindtls or not,ends here
                            //this.dialogboxError = true;
                           this.showAlert(this.dialogBoxText);
                            this.hideProgress();
                 }else{
                        this.hideProgress();
                          // this.classNameDialog = "errorDailogboxMsg";
                          this.dialogBoxText = res.data.msg;
                           this.showAlert(this.dialogBoxText);
                          // this.dialogboxError = true;
                          this.restPolicyDataFields();
                 }
              }, (error) => {
                  this.hideProgress();
                      if(error.response.data.msg){
                         this.showAlert(error.response.data.msg);
                      }else if(error.response.data.message){
                         this.showAlert(error.response.data.message);
                      }
                      else{
                          this.showAlert("Some thing went wrong");
                      }
                  this.restPolicyDataFields();
                  console.log(error);
              });
            },

            openPolicySearch(){
              let policyData={
                  lossTime:this.claimDetails.lossTime,
                  dateOfLoss:this.computedDateofloss,
                  policyNo:this.claimDetails.policyNumber
               }
	           	 this.$router.push({ name: 'PolicySearchMarine', params: policyData });
            },

            openPolicyDetails() {
                  let policyData={
                  lossTime:this.claimDetails.lossTime,
                  dateOfLoss:this.computedDateofloss,
                  policyNo:this.claimDetails.policyNumber,
                  claimDetails:this.claimDetails
                  }
	           	   this.$router.push({ name: 'PolicyDetails', params: policyData });  
            },

            //Surendra(27Feb2021)- Get Storage and Coverage Description on policy number,start here
            loadStorageAndCoverageDesc(){
               // dateOfLoss: new Date(this.dateOfLoss).toISOString(),
                if(this.claimDetails.policyNumber && this.computedDateofloss){
                  let requestData = {
                        policyNo: this.claimDetails.policyNumber,
                        insuredName: "",
                        insuredId: "",
                        certificateNo: "",
                        applicationNo: "",
                        eMarinePolicyNo: "",
                        lrNumber: "",
                        invoiceNumber: "",
                        lossTime:this.claimDetails.lossTime,
                        dateOfLoss: this.computedDateofloss,
                        proceedSearch : true  
                    };
                    this.showProgress("Data is fetching ,please wait");
                     this.POST_ByURLM("GET_POLICYSEARCH", requestData, (res) => {
                     this.hideProgress();
                      // console.log("loadStorageAndCoverageDesc res - ",res);
                      if(res.data && res.data.data && res.data.data.data){
                        this.storageDescrptionOptions = [];
                        this.storageDescrptionOptions = res.data.data.data[0].storageDetails ? res.data.data.data[0].storageDetails : [];
                        this.coverageDescrptionOptions  = res.data.data.data[0].marineDtls ? res.data.data.data[0].marineDtls : [];
                        // console.log(this.coverageDescrptionOptions)
                         
                        this.storageIndex     =   "";
                        this.coverageIndex    =   "";
                        
                        this.storageDescrptionOptions.push(this.unNamedStorage);
                        this.storageDescrptionOptions.push(this.panIndiaStorage);
                        console.log('for check coverage ', this.coverageDescrptionOptions)
                        console.log(this.typeOfClaim)
                        // this.coverageDescrptionOptions.forEach(obj=>{
                        //    if(obj.coverGroupName == 'Storage Cover'){
                        //       this.typeOfClaim   = ["Transit","Storage"];
                        //    }
                        //    else{
                        //        this.typeOfClaim   = ["Transit"];
                        //    }
                        // })
                        var a =  this.coverageDescrptionOptions.filter(x=>x.coverGroupName=='Storage Cover')
                        console.log(a)
                        if(a.length>0){
                            this.typeOfClaim   = ["Transit","Storage"];
                        }else{
                            this.typeOfClaim   = ["Transit"];
                        } 
                       

                      }

                  },(err)=>{
                      this.hideProgress();
                      console.log("err - ",err);
                  });               
              }
              
            },

            selectThisStorage(){
              console.log("selected storageDescrptionOptions - ",this.storageDescrptionOptions[this.storageIndex])
              if(this.storageIndex !== ""){
                let selectedStorage                       =   this.storageDescrptionOptions[this.storageIndex];
                this.storageDescrptionMenu                =   false;//Close Popup
                this.claimDetails.storageDescription      =   selectedStorage.storageDescription;
                this.claimDetails.storageCode             =   (selectedStorage.storageLocationCode).toString();
                // let storageLocString                      =   "";
                // storageLocString                          =   selectedStorage.storageLocationCode ? selectedStorage.storageLocationCode : "" 
                // storageLocString                          =   selectedStorage.storageDescription ? storageLocString + " " + selectedStorage.storageDescription : "";  
                // storageLocString                          =   selectedStorage.cityORDistrict ? storageLocString + " " + selectedStorage.cityORDistrict : ""; 
                // storageLocString                          =   selectedStorage.state  ? storageLocString + " " +  selectedStorage.state : "";
                // storageLocString                          =   selectedStorage.country  ? storageLocString + " " + selectedStorage.country : "";
                // storageLocString                          =   selectedStorage.pinCode ? storageLocString + " " + selectedStorage.pinCode : "";     
                // this.claimDetails.unnamedStorageLocation  =   storageLocString;
                if(selectedStorage.pinCode){
                  
                    this.callapi(((selectedStorage.pinCode).toString()).slice(0,6));
                    this.claimDetails.pinCodeOfLossLocation   =   (selectedStorage.pinCode).toString() && (selectedStorage.cityORDistrict)? (selectedStorage.pinCode).toString() + " - " + (selectedStorage.cityORDistrict): "";
                console.log('------------->', this.claimDetails.pinCodeOfLossLocation, this.lossLocationPincodes);
                }
              }              
            },
             
            selectThisCoverage(){
               if(this.coverageIndex !== ""){
                 let selectedCoverage     =   this.coverageDescrptionOptions[this.coverageIndex];
                 this.claimDetails.coverageCode   = selectedCoverage.nolCode;
                 this.claimDetails.coverageDescription  = selectedCoverage.coverGroupName;//Surendra(07-04-2021):Item value changed to perilName.
                 this.toCoverageCode();
                 this.coverageDescrptionMenu    = false;
               }
            },
            //Surendra(27Feb2021)- Get Storage and Coverage Description on policy number,end here
                        
            callapi(indata){
              if(indata!==""){
                  if(isNaN(indata)){
                    //Surendra(16-04-2021):Added if else for MC839-not able to search city data by first three letters
                    this.reqData = "pageNo=1&perPage=100&cityDistrictName="+indata
                  }else{
                    this.reqData = "pageNo=1&perPage=100&pinCode="+indata
                  }
                  
                  this.GETM("getPincodes",this.reqData, res=>{
                // console.log("response of pincode api is  -> ", res.data.data.data);
                
                var pin_not_found = false
                if(res.data.data.length == 0){
                    pin_not_found = true
                    // this.counter = ''
                    this.message ='Pin code not found'
                    this.validationbox = true;
                }else{
                    var processed_pin =  res.data.data.data.map(e=>{
                      if(e.cityDistrictName && e.cityDistrictName !== ""){
                          return { locpincode:e.pinCode + " - " + e.cityDistrictName, pincodeArry:e}
                      }else{
                          return {locpincode: e.pinCode, all:e};
                      }                  
                    })
                  this.pitems.push(...processed_pin)
                  // console.log("-==>>> ",this.pitems.length);
                }  
                if(pin_not_found){
                      // this.pitems.push({locpincode:'No data Available',all:''});
                      this.pitems.push();

                }
                // this.pitems = res.data.data.data.map
                // console.log("pitems is -> ", this.pitems);
              },(error)=>{
                    return error;
              });
              }
            }, 
            
            callAIGapi(indata){
              if(indata!==""){
                  this.reqData = "pageNo=1&perPage=100&nameOfAigOffice="+indata

                  this.GETM("getAIGDetails", this.reqData, (res) => {
                    // this.AIGDetails = res.data.data.data;                
                  
                // console.log("response of pincode api is  -> ", res.data.data.data);
                
                var officename_not_found = false
                if(res.data.data.length == 0){
                    officename_not_found = true
                    // this.counter = ''
                    this.message ='Office Name not found'
                    this.validationbox = true;
                }else{
                    var processed_officeName =  res.data.data.data.map(e=>{
                      if(e.nameOfAigOffice && e.nameOfAigOffice !== ""){
                          return { nameOfAigOffice:e.nameOfAigOffice, originDetails:e.originDetails}
                      }                 
                    })
                  this.AIGDetails.push(...processed_officeName)
                  console.log("-==>>> ",this.AIGDetails.length);
                }  
                if(officename_not_found){
                      // this.AIGDetails.push({nameOfAigOffice:indata.toString(),all:''});
                      this.AIGDetails.push('');
                }
                // this.pitems = res.data.data.data.map
                // console.log("pitems is -> ", this.pitems);
              },(error)=>{
                    return error;
              });
              }
            },

            callPolicyCompany(indata){
                if(indata!==""){
                    this.reqData = "pageNo=1&perPage=100&nameOfAigOffice="+indata

                    this.GETM("getAIGDetails", this.reqData, (res) => {
                                               
                      var officename_not_found = false
                      if(res.data.data.length == 0){
                          officename_not_found = true
                          // this.counter = ''
                          this.message ='Company Name not found'
                          this.validationbox = true;
                      }else{
                          var policyOffice =  res.data.data.data.map(e=>{
                            if(e.nameOfAigOffice && e.nameOfAigOffice !== ""){
                                return { policyCompany:e.nameOfAigOffice, originDetails:e.originDetails}
                            }                 
                          })
                        this.policyCompanyDetails.push(...policyOffice)
                        // console.log("-==>>> ",this.policyCompanyDetails.length);
                      }  
                      if(officename_not_found){
                            this.policyCompanyDetails.push({policyCompany:indata.toString(),all:''});
                      }
                      // this.pitems = res.data.data.data.map
                      // console.log("pitems is -> ", this.pitems);
                    },(error)=>{
                          return error;
                    });
                }
            },        

            checkDuplicateInvoice(index,computedinvoiceDate){
              //Surendra(18-03-2021):MC-561 - Same invoice no and same date if entered should give an error message only, to make the user alert about the duplication but should be allowed to capture the data
              if(computedinvoiceDate){
                this.claimDetails.invoice[index].date   = computedinvoiceDate;
              }
              var self  = this;
              if(this.claimDetails.invoice.filter((elem)=>{
                return elem.number  == self.claimDetails.invoice[index].number && elem.date  == self.claimDetails.invoice[index].date && elem.amount  == self.claimDetails.invoice[index].amount
              }).length > 1 ){
                    this.showAlert("This is a duplicate entry.")
              }
              
              //invoice.number,invoice.date,invoice.amount

            },

            checkDuplicateLRAWBEntry(index,computedDate){
              if(computedDate){
                this.claimDetails.awbNumberAndDate[index].date     =   computedDate;
              }
              //Surendra(26-03-2021):Check duplicate in table
              var self  = this;
              let existingEntries   = this.claimDetails.awbNumberAndDate.filter((elem)=>{
                return elem.date == self.claimDetails.awbNumberAndDate[index].date && elem.number == self.claimDetails.awbNumberAndDate[index].number
              });
              this.claimDetails.awbNumberAndDate.forEach(element => {
                if((this.claimDetails.awbNumberAndDate[index].number == element.number) && ((this.claimDetails.awbNumberAndDate[index].date !== '') && this.claimDetails.awbNumberAndDate[index].date !== element.date)){
                    self.claimDetails.awbNumberAndDate[index].number = "";
                    self.claimDetails.awbNumberAndDate[index].date = "";
                   var duplicateClaimMSg = "Same LR/AWB/BL number cannot have multiple dates"
                    self.showConfirm("FNOL",duplicateClaimMSg,"Ok","Cancel",()=>{
                      self.claimDetails.awbNumberAndDate[index].number = "";
                      self.claimDetails.awbNumberAndDate[index].date = "";
                      //Success Case,No need to do anything let user proceed
                      console.log("Local yes index",index)
                    },()=>{
                      self.claimDetails.awbNumberAndDate[index].number = "";
                      self.claimDetails.awbNumberAndDate[index].date = "";
                    })
                }
              });

              if(this.claimDetails.awbNumberAndDate[index].number && this.claimDetails.awbNumberAndDate[index].date && existingEntries.length >1){
                var duplicateClaimMSg = "This is a Duplicate Entry, Would you want to proceed with the registration?"
                  self.showConfirm("FNOL",duplicateClaimMSg,"Yes","No",()=>{
                     //Success Case,No need to do anything let user proceed
                     console.log("Local yes index",index)
                  },()=>{
                    //Failure Case,Delete the number and date
                      console.log("Local No index",index)
                      self.claimDetails.awbNumberAndDate[index].number = "";
                      self.claimDetails.awbNumberAndDate[index].date = "";
                  })
              }              
            //Surendra(22-03-2021):Check duplicate entry in table with other claims with help of service
             if(existingEntries.length < 2 && this.claimDetails.policyNumber && this.dateOfLoss && this.claimDetails.lossTime && this.claimDetails.awbNumberAndDate[index].number && this.claimDetails.awbNumberAndDate[index].date){
              var reqData = "policyNumber="+this.claimDetails.policyNumber+"&dateOfLoss="+this.dateOfLoss;//Surendra(08-04-2021): MC-651: Remove Loss time from request, +"&lossTime="+this.claimDetails.lossTime
              reqData     = reqData + "&awbNumber="+this.claimDetails.awbNumberAndDate[index].number + "&awbDate="+this.claimDetails.awbNumberAndDate[index].date;
              console.log("ReqData = "+reqData);
              this.showProgress("Checking for duplicate record...")
              this.GETM("duplicateClaimCheck",reqData,(res)=>{
                self.hideProgress();
                console.log(res)
                if(res.data && res.data.data && res.data.data.isDuplicateClaim){
                  var duplicateClaimMSg = "This is a Duplicate Claim for Claim number "+res.data.data.mainClaimNumber+". Would you want to proceed with the registration?"
                  
                  self.showConfirm("FNOL",duplicateClaimMSg,"Yes","No",()=>{
                     //Success Case,No need to do anything let user proceed
                     console.log("Serv yes index",index)
                    //  this.savelrflag=true
                  },()=>{
                    //Failure Case,Delete the number and date
                      console.log("Serv No index",index)
                      self.claimDetails.awbNumberAndDate[index].number = "";
                      self.claimDetails.awbNumberAndDate[index].date = "";
                       if(self.claimDetails.awbNumberAndDate.length == 0){
                          self.LRAWSBLDetailsValidation = '';
                      }else if(self.claimDetails.awbNumberAndDate[0].number == "" && self.claimDetails.awbNumberAndDate[0].date == ""){
                          self.LRAWSBLDetailsValidation = '';
                      }
                     
                  })
                }else{
                  // this.savelrflag=true
                }
              },(err)=>{
                this.hideProgress();
                console.log("checkDuplicateLRAWBEntry - ",err.response);
                if(err.response && err.response.data && err.response.data.msg){
                  this.showAlert(err.response.data.msg)
                }else{
                  this.showAlert("Something went wrong, please try again.")
                }
              })
                
              }
              
            },

            fetchClaimDeatils(reqClaimURL,featureChange){
                this.showProgress("Loading, Please wait");
                let requestData = this.$store.getters.fetchclaimMainscreen_id.claimId;
                this.GET_ByURL(reqClaimURL, requestData, (response) => {
                  let responseData = response.data.data.claim;
                  delete responseData.ruleInfoTime;
                  this.claimDetails = responseData;
                  // if(this.Estimatedblank==true){
                  //   this.claimDetails.estimatedLossAmountInINR=""; this line code is added in if (featureChange) condtion by gopikrishna
                  //   this.Estimatedblank=false
                  // }
                   // added by sneha for MC-966
                  console.log("openload------ fetch ClaimDeatils------->", JSON.stringify(this.claimDetails));
                  if (responseData.mainClaimNumber !== undefined) {
                    let requestData = "?mainClaimNumber=" + responseData.mainClaimNumber;
                    this.GET_ByURL("getAllClaimNumbers_Fnol", requestData, (res) => {
                      this.allClaimsNumberArray = res.data.data;
                    }, (error) => {
                      console.log(error);
                    });
                  }
                  if (featureChange) {// this code is for to change the feature claim
                    this.claimDetails.featureClaimType = this.claimDetails.claimType;
                    this.claimDetails.claimType = featureChange;
                    this.claimDetails.estimatedLossAmountInINR="";  // added by sneha for MC-966
                  }
                  if (this.claimDetails.claimType == "Service Claim" || this.claimDetails.featureClaimType == "Service Claim") {
                    this.aigClaimtrue = true;
                    if (this.claimDetails.policyInceptionDate) {
                      this.inceptionDate = moment(this.claimDetails.policyInceptionDate).format("YYYY-MM-DD");
                    }
                    if (this.claimDetails.policyExpiryDate) {
                      this.expiryDate = moment(this.claimDetails.policyExpiryDate).format("YYYY-MM-DD");
                    }
                    this.claimCategory = ["Service Claim", "Local Claim", "Export Claim"];
                  } else {
                    this.claimCategory = ["Local Claim", "Export Claim"];
                  }
                  //dateofLoss
                  if (this.claimDetails.dateOfLoss) {
                    this.dateOfLoss = moment(this.claimDetails.dateOfLoss).format("YYYY-MM-DD");
                  }
                  if (this.claimDetails.dateDifference == null || response.dateDifference == "Invalid date") {
                    this.claimDetails.dateDifference = "";
                  }
                  if (this.claimDetails.isInsuredTheClaimant == true) {
                    this.claimDetails.claimant.name = this.claimDetails.insuredName;
                    // console.log('if---->',this.claimDetails.isInsuredTheClaimant);
                  }
                  if (this.claimDetails.callerEmailID.length > 0) {
                    this.callerEmailID = responseData.callerEmailID[0];
                    // this.callerEmailID = responseData.callerEmailID.toString();
                  }
                  if (this.claimDetails.insuredMobileNumber.length > 0) {
                    this.insuredMobileNumber = responseData.insuredMobileNumber[0].number;
                    this.insuredMobileISD = this.claimDetails.insuredMobileNumber[0].isd;
                    // this.insuredMobileNumber = responseData.insuredMobileNumber.toString();
                  }
                  if (this.claimDetails.emailIdForCommunication.length > 0) {
                    this.emailIdForCommunication = responseData.emailIdForCommunication[0];
                    // this.emailIdForCommunication = responseData.emailIdForCommunication.toString();
                  }
                  this.reportedDate = moment(responseData.reportedDate).format("YYYY-MM-DD"); //this code is for MC-744
                  // this.claimDetails.reportedDate = moment(responseData.reportedDate).format("YYYY-MM-DD");old code
                  this.claimDetails.invoice = responseData.invoice;
                  this.claimDetails.awbNumberAndDate = responseData.awbNumberAndDate;
                  for (var i = 0; i < this.claimDetails.invoice.length; i++) {
                    if (!this.claimDetails.invoice[i].date) {
                      //   this.claimDetails.invoice[i].date = moment(this.claimDetails.invoice[i].date).format("DD/MM/YYYY");
                      // }else{
                      this.claimDetails.invoice[i].date = "";
                    }
                  }
                  for (var i = 0; i < this.claimDetails.awbNumberAndDate.length; i++) {
                    if (!this.claimDetails.awbNumberAndDate[i].date) {
                      //     this.claimDetails.awbNumberAndDate[i].date = moment(this.claimDetails.awbNumberAndDate[i].date).format("DD/MM/YYYY");
                      // }else{
                      this.claimDetails.awbNumberAndDate[i].date = "";
                    } else {
                      this.LRAWSBLDetailsValidation = this.claimDetails.awbNumberAndDate[i].number;
                    }
                  }
                  this.addressFieldValidation = this.claimDetails.claimant.addressLineOne;
                  if (this.claimDetails.internationalLocation == true) {
                    this.location = false;
                    this.onInternationalocation = false;
                  } else {
                    this.location = true;
                    this.onInternationalocation = true;
                    // return v => !!v || this.validationmessage;
                  }
                  if (this.claimDetails.callerIntimatorIdentification !== "") {
                    if (this.claimDetails.callerIntimatorIdentification == "Insured") {
                      this.callermobile = false;
                      // this.calleremail = false;
                      // console.log('=-------->if insured')
                      this.claimDetails.callerName = this.claimDetails.insuredName;
                    } else if (this.claimDetails.callerIntimatorIdentification !== "Insured") {
                      this.callermobile = true;
                      // this.calleremail = true;
                      // this.claimDetails.callerName = ""; // sneha if callerIntiIden is other than insured then its not displaying
                      // console.log('=-------->else insured')
                      // return v => !!v || this.validationmessage;
                    }
                  }
                  if (this.claimDetails.claimStatus == "Suspended") {
                    this.mandatory = true;
                    // return v => !!v || this.validationmessage
                  } else {
                    this.mandatory = false;
                  }
                  if (
                    (this.claimDetails.claimIntimatedThrough !== "Email" && this.claimDetails.productName == "MarineFreightForwardLiability") || this.claimDetails.claimIntimatedThrough == "") {
                    this.callertrue = false;
                  } else if (this.claimDetails.claimIntimatedThrough !== "Email") {
                    this.callertrue = true;
                    // return v => !!v || this.validationmessage;
                  } else {
                    this.callertrue = false;
                  }
                  if (this.claimDetails.isEventACatastrophe == true) {
                    this.catastrophe = true;
                    // return v => !!v || this.validationmessage;
                  } else {
                    this.catastrophe = false;
                  }
                  this.tochangeRelationship();
                  this.checkPolicyNumber();
                  this.withoutPolicyno();
                  this.changeAIG();
                  this.getToStates();
                  this.getFromStates();
                  this.toGetCities();
                  this.getClaimantStates();
                  this.getClaimantCities();
                  this.disableCoverageCode();
                  this.updateCoverageOptions();
                  if (this.claimDetails.pinCodeOfLossLocation !== "") {
                    this.callapi(this.claimDetails.pinCodeOfLossLocation.slice(0, 6));
                  }
                  if (this.claimDetails.claimant.pincode !== "") {
                    this.callapi(this.claimDetails.claimant.pincode.slice(0, 6));
                  }
                  if (this.claimDetails.aigSettlingCompany !== "") {
                    this.callAIGapi(this.claimDetails.aigSettlingCompany);
                  }
                  if (this.claimDetails.policyIssuingCompany) {
                    this.callPolicyCompany(this.claimDetails.policyIssuingCompany);
                  }
                  if (this.claimDetails.isInsuredTheClaimant == true) {
                    this.policyClaimantDetails = {
                      addressLine1: responseData.claimant.addressLineOne,
                      addressLine2: responseData.claimant.addressLineTwo,
                      addressLine3: responseData.claimant.addressLineThree,
                      cityDistrict: responseData.claimant.city,
                      state: responseData.claimant.state,
                      pincode: responseData.claimant.pincode,
                      countryname: responseData.claimant.country,
                      mobileNumber: responseData.claimant.mobileNo,
                      emailId: responseData.claimant.emailID,
                    };
                  }
                  this.loadStorageAndCoverageDesc(); //Surendra(27Feb2021)-Load options

                  if(this.claimDetails.mainClaimNumber && this.claimDetails.claimStatus == "Open" && this.claimDetails.claimType == "Feature Claim" && this.claimDetails.featureNumber =="A"){
                      this.reportedDate = moment().format("YYYY-MM-DD")  //MC-937
                      this.claimDetails.reportedTime = moment().format("HH:mm");
                      this.reportedTime = moment().format("HH:mm");
                      this.currentTime= moment().format("HH:mm");
                  }
                if(this.$store.getters.fetchFnolPageEditCheck=="FnolEditPage"){
                  if(this.claimDetails.claimType == "Feature Claim"){
                      this.claims[0].selected = false;
                      this.claims[1].selected = true;
                      this.claims[2].selected = false;
                  }else if(this.claimDetails.claimType == "Service Claim"){
                        this.claims[0].selected = false;
                        this.claims[1].selected = false;
                        this.claims[2].selected = true;
                  }else{
                        this.claims[0].selected = true;
                        this.claims[1].selected = false;
                        this.claims[2].selected = false;
                  }
                }
                
                  console.log("openload modified ==========>", JSON.stringify(this.claimDetails));
                  this.hideProgress();
                }, (error) => {
                  this.hideProgress();
                  if (error.response.data.msg) {
                    this.showAlert(error.response.data.msg);
                  } else if (error.response.data.message) {
                    this.showAlert(error.response.data.message);
                  } else {
                    this.showAlert("Some thing went wrong");
                  }
                });
              
            },
            
            callMountedFunctionCode() {
              if (Object.keys(this.$route.params).length !== 0 && this.$route.params.proceedSearch == undefined) {
                let policyDataStore = this.$route.params;
                this.claimDetails = policyDataStore.claimDetails;
                this.productnames = [];
                this.productnames.push({"productName": this.claimDetails.productName })
                const [day, month, year] = policyDataStore.dateOfLoss.split("/");
                this.dateOfLoss = `${year}-${month}-${day}`;
                this.isPolicyLoaded = true;
                this.tochangeRelationship();
                this.checkPolicyNumber();
                this.withoutPolicyno();
                this.changeAIG();
                this.getToStates();
                this.getFromStates();
                this.toGetCities();
                this.loadStorageAndCoverageDesc();
                this.getClaimantStates();
                this.getClaimantCities();
                this.disableCoverageCode();
                this.updateCoverageOptions();
                if (this.claimDetails.pinCodeOfLossLocation !== "") {
                  this.callapi(this.claimDetails.pinCodeOfLossLocation.slice(0, 6));
                }
                if (this.claimDetails.claimant.pincode !== "") {
                  this.callapi(this.claimDetails.claimant.pincode.slice(0, 6))
                }
                if (this.claimDetails.aigSettlingCompany !== "") {
                  this.callAIGapi(this.claimDetails.aigSettlingCompany);
                }
                if (this.claimDetails.policyIssuingCompany) {
                  this.callPolicyCompany(this.claimDetails.policyIssuingCompany)
                }
                this.loadStorageAndCoverageDesc();
                return true;
              } else if (Object.keys(this.$route.params).length !== 0) {
                let policyDataStore = this.$route.params;
                let requestData = {
                  policyNo: policyDataStore.policyNo,
                  insuredName: policyDataStore.insuredName,
                  insuredId: policyDataStore.insuredId,
                  certificateNo: policyDataStore.certificateNo,
                  applicationNo: policyDataStore.applicationNo,
                  eMarinePolicyNo: policyDataStore.eMarinePolicyNo,
                  lrNumber: policyDataStore.lrNumber,
                  invoiceNumber: policyDataStore.invoiceNumber,
                  lossTime: policyDataStore.lossTime,
                  dateOfLoss: policyDataStore.dateOfLoss,
                  proceedSearch: policyDataStore.proceedSearch
                };
                this.showProgress("Data is fetching ,please wait");
                this.POST_ByURL("GET_POLICYSEARCH", requestData, (res) => {
                  if (res.data && res.data.data && res.data.data.data && res.data.data.data[0].proposalDtls) {
                    let finalResponse = res.data.data.data[0];
                    let policyData = finalResponse.proposalDtls[0];
                    let marineDtls = finalResponse.marineDtls[0];
                    this.claimDetails.insuredName = policyData.insuredName;
                    //this.claimDetails.insuredID = policyData.insuredId==undefined ? policyData.txtCustomerId: policyData.insuredId;
                    // this.claimDetails.certificateNumber = policyData.certificateNumber;
                    // this.claimDetails.applicationNumber = policyData.applicationNumber;
                    this.claimDetails.productName = policyData.txtProductName;
                    this.claimDetails.lob = "Marine Cargo";
                    this.claimDetails.eMarinePolicyNumber = policyData.eMarinePolicyNumber == undefined ? "" : policyData.eMarinePolicyNumber;
                    this.claimDetails.proposalNo = policyData.proposalNumber;
                    this.claimDetails.policyNumber = policyData.policyNumber;
                    this.claimDetails.lossTime = policyDataStore.lossTime;
                    this.policyEndDate = policyData.policyExpiryDate == undefined ? "" : policyData.policyExpiryDate;
                    this.claimDetails.policyStartDate = policyData.policyStartInceptionDate;
                    if (marineDtls != undefined) {
                      this.claimDetails.sumInsured = marineDtls.sumInsured == undefined ? "" : marineDtls.sumInsured;
                    } else {
                      this.claimDetails.sumInsured = "";
                    }
                    const [day, month, year] = policyDataStore.dateOfLoss.split("/");
                    this.dateOfLoss = `${year}-${month}-${day}`;
                    // this.computedDateofloss = moment(policyDataStore.dateOfLoss).format('DD/MM/YYYY');
                    if (this.claimDetails.policyStartDate !== "") {
                      const [day, month, year] = requestData.dateOfLoss.split("/");
                      const lossEnd = `${day}-${month}-${year}`;
                      const policy = moment(this.claimDetails.policyStartDate).format("DD-MM-YYYY");
                      const date1 = moment(lossEnd, 'DD-MM-YYYY');
                      const date2 = moment(policy, 'DD-MM-YYYY');
                      if (date1 > date2) {
                        var diffmonths = date1.diff(date2, 'months');
                        console.log('month', diffmonths)
                        date2.add(diffmonths, 'months');
                        var diffdays = date1.diff(date2, 'days')
                        console.log('days', diffdays)
                        if (diffmonths == 0) {
                          this.claimDetails.dateDifference = diffdays.toString() + ' days'
                        } else if (diffdays == 0) {
                          this.claimDetails.dateDifference = diffmonths.toString() + ' Months'
                        } else {
                          this.claimDetails.dateDifference = diffmonths + " " + "Months" + " " + diffdays + " " + "Days"
                        }
                      }
                    }
                    // this.claimDetails.policyStartDt = policyData.policyStartInceptionDate;
                    // this.claimDetails.policyEndDt = policyData.policyExpiryDate;
                    // this.claimDetails.businessType = policyData.txtBusinessType;
                    // this.claimDetails.lrNo = res.data.data.data.marineDtls[0].lrNumber;
                    // this.claimDetails.activeFlag = policyData.txtActiveFlag;
                    //this.claimDetails.claimSubStatus=res.data.data.data.claimSubStatus;
                    this.claimDetails.claimStatus = finalResponse.claimStatus;
                    this.claimDetails.reasonForSuspense = finalResponse.reasonForSuspense;
                    if (policyData.insuredMobileNumber) {
                      this.insuredMobileISD = '91';
                      this.insuredMobileNumber = policyData.insuredMobileNumber;
                      this.claimDetails.insuredMobileNumber.push({
                        "isd": "91",
                        "number": policyData.insuredMobileNumber
                      })
                    }
                    this.claimDetails.insuredEmailID = policyData.insuredEmailId;
                    this.claimDetails.policyStartDate = policyData.policyStartInceptionDate;
                    this.policyClaimantDetails = {
                      addressLine1: policyData.addressLine1,
                      addressLine2: policyData.addressLine2,
                      addressLine3: policyData.addressLine3,
                      cityDistrict: policyData.cityDistrict,
                      state: policyData.state,
                      pincode: policyData.pincode,
                      countryname: policyData.countryName,
                      mobileNumber: policyData.insuredMobileNumber,
                      emailId: policyData.insuredEmailId
                    }
                    this.hideProgress();
                    this.loadStorageAndCoverageDesc(); //Surendra(27Feb2021)- Get Storage and Coverage Desc options
                    this.isPolicyLoaded = true;
                    this.disableCoverageCode();
                    this.productnames = []; //Surendra(02-03-2021):Show relevant policy options only
                    this.productnames.push({
                      "productName": policyData.txtProductName
                    }) //Surendra(02-03-2021):Show relevant policy options only
                    this.updateCoverageOptions(); //Surendra(27Feb2021)- Update coverage options on product name
                  } else {
                    this.restPolicyDataFields();
                    this.hideProgress();
                  }
                }, (error) => {
                  this.restPolicyDataFields();
                  this.hideProgress();
                if(error.response.data.msg){
                  this.showAlert(error.response.data.msg);
                }else if(error.response.data.message){
                  this.showAlert(error.response.data.message);
                }
                else{
                  this.showAlert("Some thing went wrong");
                }
                });
              }
            }
      },

      created(){
          this.todaydatevalue = moment().format("YYYY-MM-DD");
          this.reportedDate   = moment().format("YYYY-MM-DD");
          // this.updateCoverageOptions();
          
          if(this.claimDetails.claimType == "Service Claim"){
            this.AIGmaxlen = 20;
          }else if(this.claimDetails.claimType == "New Claim"){
            this.AIGmaxlen = 100;
          }
        
          this.GETM("getLossDescription","", (res) => {
            this.lossDescription = res.data.data.data
                  
          },(error)=>{
              return error;
          });

          this.GETM("getAllCatastrophe","", (res) => {
            let catastropheDesDetails = res.data.data.data
            for (var i =0; i<catastropheDesDetails.length; i++){
              if(catastropheDesDetails[i].endDate){
                  // console.log("this.catastropheDesDetails")
                  // console.log('start',catastropheDesDetails[i].startDate)
                  //  console.log('end',catastropheDesDetails[i].endDate)
                if(new Date(catastropheDesDetails[i].startDate).setHours(0, 0, 0, 0) <= new Date(this.dateOfLoss).setHours(0,0,0,0) && new Date(catastropheDesDetails[i].endDate).setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0)){
                  
                  this.catastropheDesDetails.push(catastropheDesDetails[i]) 
                  // console.log("this.catastropheDesDetails",this.catastropheDesDetails)
                }
              }
            }            
          },(error)=>{
              return error;
          });

          // this.GETM("getAIGDetails",{}, (res) => {
          //   this.AIGDetails = res.data.data.data;                
          // },(error)=>{    

          //     return error;
          // });

              
          this.GETM("getCoutries","",(res) => {
            this.Countries = res.data.data.data
               console.log('realcon',this.Countries )
              const removeItem = 'INDIA';
              const result = this.Countries.filter(
                  x => x.countryName != removeItem
              );
              console.log('splice check',result)
              this.InternationalCountries = result
          },(error)=>{

              return error;
          });     
                 
          this.GETM("getCoverage","", (res) => {
          this.Coverage = res.data.data.data
           this.productnames = [];
            for(var i in this.Coverage){
              if(!this.productnames.find((elem)=>{
                elem.productCode == this.Coverage[i].productCode
              })){
                this.productnames.push(this.Coverage[i]);
                
              }
            }
          },(error)=>{            
              return error;
          });

          // this.reqData = "pageNo=1&perPage=100"
          //   this.GETM("getPincodes", this.reqData, (res) => {
              
          //       this.Pincodes = res.data.data.data;
              
          //   },(error)=>{

          //       return error;
          //   });                 
          
        if(this.claimDetails.claimType === "Feature Claim" || this.claimDetails.Policynocheck ===true){
          this.productName=true
        }
        if(this.withoutpolicyno == true || this.claimDetails.claimType === "Feature Claim"){
          console.log("this",this.withoutpolicyno)
          this.disable = true;
        }
        if(this.claimDetails.callerRelationshipwithInsured !=="Other" || this.claimDetails.claimType==="Feature Claim"){
          this.checkRelation = true;
        }
        if(this.claimDetails.callerIntimatorIdentification == "Insured" || this.claimDetails.claimType === "Feature Claim"){
          this.callername = true;
        }
        if(this.claimDetails.internationalLocation == false || this.claimDetails.claimType === "Feature Claim"){
          this.onInternationalocation = true;
        }

        
        // if(this.claimDetails.claimIntimatedThrough !== "" && this.claimDetails.claimIntimatedThrough !== "Email"){
        //   this.callertrue = true;
        // }

        
        
        // else if(this.claimDetails.claimStatus == "Suspended"){
        //    this.disableOnCSCT = false;
        // }else if(this.claimDetails.claimStatus == ""){}

        

        if(this.claimDetails.isEventACatastrophe == true){
          this.catastrophe= true;
          return v => !!v || this.validationmessage;
        }else{
          this.catastrophe = false;
        }
        
              if(this.$store.getters.fetchFnolPageEditCheck == "FnolEditPage"){
                  // console.log("this.$route.params created method----------->", this.$store.getters.fetchclaimMainscreen_id);
                  let reqClaimURL;
                  if(this.$store.getters.fetchclaimMainscreen_id.claimType=="Feature Claim"){
                     reqClaimURL="FETCH_FEATURECLAIM_DETAILS";
                  }else{
                      reqClaimURL="FETCH_CLAIM_DETAILS";
                  }
                 // let URl = this.getURL(reqClaimURL) + this.$store.getters.fetchclaimMainscreen_id.claimId;
                  this.fetchClaimDeatils(reqClaimURL);
              }else{
                  this.callMountedFunctionCode();
              }
              
              //Surendra(02-03-2021):Add dummy storage options, starts here
              this.storageDescrptionOptions.push(this.unNamedStorage);
              this.storageDescrptionOptions.push(this.panIndiaStorage);
              //Surendra(02-03-2021):Add dummy storage options, ends here
      },

      watch:{

          SearchAllClaimNumbers(val){
            if(val!==null){
              if(val.length > 4){
                  let requestData="?mainClaimNumber="+val;
                  this.GET_ByURLM("getAllClaimNumbers_Fnol",requestData,(res) => {
                        if(res.data.data && res.data.data.length==0){
                             this.showAlert("Claim number is invalid.");
                        }else{
                           this.allClaimsNumberArray = res.data.data;
                        }
                  },(error)=>{
                    console.log(error);
                  });
              }else{
                 this.validationbox = true;
                 this.message = 'Please enter Minimum 5 digits to search claim number';
                 return;
              }
            }else{
                return;
            }
          },
       
          searchPincode (val){
            if(val == null){
                return
            }
            if(val.length >2){
                  if(val.length > 6){
                    val = val.slice(0,6);
                  }
                  var vlen = val.length
                      
                  if(this.itemspin.length == 0 && val.length > 2){
                      this.callapi(val);
                      return
                  }
                    for(let i=0; i<this.itemspin.length;i++){
                        if(vlen > 3){
                          vlen = 3
                          var strlen = this.itemspin[i].locpincode.slice(0,vlen)
                          // console.log("strlen is",strlen)
                        }else{
                          var strlen = this.itemspin[i].locpincode.slice(0,vlen)
                        } 
                        if(vlen > strlen.length){
                          // console.log("data not available to search")
                          return
                        }
                
                        if(strlen != val){
                            this.callapi(val)
                            return
                        }else{
                          return
                        }
                    }
                return
            }else{
              return
            }
          },

          search (val){
              if(val == null){
                  return
              }
              if(val.length >2){
                    if(val.length > 6){
                        val = val.slice(0,6);
                    }
                  var vlen = val.length
              
                  // console.log('vlen',vlen)
                    if(this.itemspin.length == 0 && val.length > 2){
                        this.callapi(val);
                        return
                    }
                      for(let i=0; i<this.itemspin.length;i++){
                          if(vlen > 3){
                            vlen = 3
                            var strlen = this.itemspin[i].locpincode.slice(0,vlen)
                            // console.log("strlen is",strlen)
                          }else{
                            var strlen = this.itemspin[i].locpincode.slice(0,vlen)
                          }
                          if(vlen > strlen.length){
                            // console.log("data not available to search")
                            return
                          }
                  
                          if(strlen != val){
                              // console.log("strlen",strlen,"val",val);
                              this.callapi(val)
                              return
                          }else{
                            return
                          }
                      }
              }else{
                return
              }
          },

          searchAIGOffice (val){
              if(val == null){
                  return
              }
              if(val.length >2){
                    if(val.length > 6){
                        val = val.slice(0,6);
                    }
                  var vlen = val.length
              
                  // console.log('vlen',vlen)
                    if(this.AIGDetails.length == 0 && val.length > 2){
                        this.callAIGapi(val);
                        return
                    }
                      for(let i=0; i<this.AIGDetails.length;i++){
                          if(vlen > 3){
                            vlen = 3
                            var strlen = this.AIGDetails[i].nameOfAigOffice.slice(0,vlen)
                            // console.log("strlen is",strlen)
                          }else{
                            var strlen = this.AIGDetails[i].nameOfAigOffice.slice(0,vlen)
                          }
                          if(vlen > strlen.length){
                            // console.log("data not available to search")
                            return
                          }
                  
                          if(strlen != val){
                              // console.log("strlen",strlen,"val",val);
                              this.callAIGapi(val)
                              return
                          }else{
                            return
                          }
                      }
              }else{
                return
              }
          },

          searchTransitFromCity(val){
            console.log(val)
          if(this.claimDetails.transitFromCountry !== ""){
            if(val!==null){
              if(val.length >= 3){
                var req="perPage=100&pageNo=1&countryName="+this.claimDetails.transitFromCountry;
                if(val){
                  //Surendra(16-04-2021): MC839-not able to search city data by first three letters
                  req   = req + "&cityDistrictName="+val
                }
                this.GETM("getCities", req, (res) => {
                    this.transitFromCities = res.data.data.data
                    var other ={
                      cityDistrictName:'OTHER'
                    }
                    if(this.transitFromCities==undefined){
                      this.transitFromCities=[];
                    }
                    this.transitFromCities.push(other)
                    console.log(this.transitFromCities)
                },(error)=>{
                    return error;
                }); 
              }else{
                 this.validationbox = true;
                 this.message = 'Please enter Minimum 3 charactor to search Transit From City';
                this.transitFromCities.push();
                return;
              }
            }else{
                this.transitFromCities.push();
                return;
            }
             }
          },       

          searchTransitToCity(val){
            console.log(val)
          if(this.claimDetails.transitToCountry !== ""){
            if(val!==null){
              if(val.length >= 3){
                  var req="perPage=100&pageNo=1&countryName="+this.claimDetails.transitToCountry;
                if(val){
                  //Surendra(16-04-2021): MC839-not able to search city data by first three letters
                  req   = req + "&cityDistrictName="+val
                }
                this.GETM("getCities", req, (res) => {
                    this.transitToCities = res.data.data.data
                     var other ={
                      cityDistrictName:'OTHER'
                    }
                    if(this.transitToCities==undefined){
                      this.transitToCities=[];
                    }
                    this.transitToCities.push(other)
                    console.log(this.transitToCities)
                },(error)=>{
                    return error;
                }); 
              }else{
                 this.validationbox = true;
                 this.message = 'Please enter Minimum 3 charactor to search Transit To City';
                this.transitToCities.push();
                return;
              }
            }else{
                this.transitToCities.push();
                return;
            }
             }
          },       

          searchInternationCity(val){
            if(this.claimDetails.country !== ""){
            if(val!==null){
              if(val.length >= 3){
                var req="perPage=100&pageNo=1&countryName="+this.claimDetails.country;
                if(val){
                  //Surendra(16-04-2021): MC839-not able to search city data by first three letters
                  req   = req + "&cityDistrictName="+val
                }
                this.GETM("getCities", req, (res) => {
                    this.Cities = res.data.data.data
                    var other ={
                      cityDistrictName:'OTHER'
                    }
                    console.log(this.Cities)
                    if(this.Cities==undefined){
                      this.Cities=[];
                      }
                    this.Cities.push(other)
                  
                },(error)=>{
                    return error;
                });  
              }else{
                this.validationbox = true;
                this.message = 'Please enter Minimum 3 charactor to search City';
                this.Cities.push();
                return;
              }
            }else{
                this.Cities.push();
                return;
            }
            }
          },

          searchClaimantCity(val){
              
            if(this.claimDetails.claimant.state !== ""){
              if(val!==null){
                if(val.length >= 3){
                    this.showProgress();
                  var req="stateName="+this.claimDetails.claimant.state;
                  
                  this.GETM("getCities", req, (res) => {
                      this.claimantCities = res.data.data.data;
                        var other ={
                        cityDistrictName:'OTHER'
                      }
                      this.claimantCities.push(other)
                      console.log(this.claimantCities)
                          this.hideProgress();
                  },(error)=>{
                        this.hideProgress();
                      return error;
                  }); 
                }else{
                    this.validationbox = true;
                    this.message = 'Please enter Minimum 3 charactor to search City';
                  this.claimantCities.push();
                  return;
                }
              }else{
                  this.claimantCities.push();
                  return;
              }
                }
          },

          searchCompany(val){
                 if(val == null){
                  return
              }
              if(val.length >2){
                    if(val.length > 6){
                        val = val.slice(0,6);
                    }
                  var vlen = val.length
              
                  // console.log('vlen',vlen)
                    if(this.policyCompanyDetails.length == 0 && val.length > 2){
                        this.callPolicyCompany(val);
                        return
                    }
                      for(let i=0; i<this.policyCompanyDetails.length;i++){
                          if(vlen > 3){
                            vlen = 3
                            var strlen = this.policyCompanyDetails[i].policyCompany.slice(0,vlen)
                            // console.log("strlen is",strlen)
                          }else{
                            var strlen = this.policyCompanyDetails[i].policyCompany.slice(0,vlen)
                          }
                          if(vlen > strlen.length){
                            // console.log("data not available to search")
                            return
                          }
                  
                          if(strlen != val){
                              // console.log("strlen",strlen,"val",val);
                              this.callPolicyCompany(val)
                              return
                          }else{
                            return
                          }
                      }
              }else{
                return
              }
          }       

      },
       
      mounted(){
       if(Object.keys(this.$route.params).length !==0 && this.$route.params.proceedSearch==undefined){
            let policyDataStore =this.$route.params;
                this.claimDetails=policyDataStore.claimDetails;
                this.productnames = [];
                this.productnames.push({"productName":this.claimDetails.productName})
                const [day, month, year] = policyDataStore.dateOfLoss.split("/");
                this.dateOfLoss  =  `${year}-${month}-${day}`;
                this.isPolicyLoaded   = true;
                this.tochangeRelationship();
                this.checkPolicyNumber();
                this.withoutPolicyno();
                this.changeAIG();
                this.getToStates();
                this.getFromStates();  
                this.toGetCities();   
                this.loadStorageAndCoverageDesc();
                this.getClaimantStates();
                this.getClaimantCities();
                this.disableCoverageCode();
                this.updateCoverageOptions();
                if(this.claimDetails.pinCodeOfLossLocation !== ""){
                  this.callapi(this.claimDetails.pinCodeOfLossLocation.slice(0,6));
                }
                if(this.claimDetails.claimant.pincode !== ""){
                  this.callapi(this.claimDetails.claimant.pincode.slice(0,6))
                }
                if(this.claimDetails.aigSettlingCompany !== ""){
                  this.callAIGapi(this.claimDetails.aigSettlingCompany);
                }
                if(this.claimDetails.policyIssuingCompany){
                  this.callPolicyCompany(this.claimDetails.policyIssuingCompany)
                } 
               this.loadStorageAndCoverageDesc();
               return true;
         }else if(Object.keys(this.$route.params).length !==0){
              let policyDataStore =this.$route.params;
              let requestData = {
              policyNo: policyDataStore.policyNo,
              insuredName: policyDataStore.insuredName,
              insuredId: policyDataStore.insuredId,
              certificateNo: policyDataStore.certificateNo,
              applicationNo: policyDataStore.applicationNo,
              eMarinePolicyNo: policyDataStore.eMarinePolicyNo,
              lrNumber: policyDataStore.lrNumber,
              invoiceNumber: policyDataStore.invoiceNumber,
              lossTime: policyDataStore.lossTime,
              dateOfLoss: policyDataStore.dateOfLoss,
              proceedSearch:policyDataStore.proceedSearch
            };
            this.showProgress("Data is fetching ,please wait");
            this.POST_ByURLM("GET_POLICYSEARCH", requestData, (res) => {
              if( res.data && res.data.data && res.data.data.data && res.data.data.data[0].proposalDtls){
              let finalResponse=res.data.data.data[0];
              let policyData = finalResponse.proposalDtls[0];
              let marineDtls=finalResponse.marineDtls[0];
              this.claimDetails.insuredName = policyData.insuredName;
              //this.claimDetails.insuredID = policyData.insuredId==undefined ? policyData.txtCustomerId: policyData.insuredId;
              // this.claimDetails.certificateNumber = policyData.certificateNumber;
              // this.claimDetails.applicationNumber = policyData.applicationNumber;
              this.claimDetails.productName = policyData.txtProductName;
              this.claimDetails.lob = "Marine Cargo";
              this.claimDetails.eMarinePolicyNumber = policyData.eMarinePolicyNumber == undefined ? "" : policyData.eMarinePolicyNumber;
              this.claimDetails.proposalNo = policyData.proposalNumber;
              this.claimDetails.policyNumber=policyData.policyNumber;
              this.claimDetails.lossTime =policyDataStore.lossTime;
              this.policyEndDate=policyData.policyExpiryDate==undefined ?"": policyData.policyExpiryDate;
              this.claimDetails.policyStartDate=policyData.policyStartInceptionDate;
              if(marineDtls!=undefined){
                  this.claimDetails.sumInsured=marineDtls.sumInsured==undefined ? "": marineDtls.sumInsured;
              }else{     
                 this.claimDetails.sumInsured="";
              }
                const [day, month, year] = policyDataStore.dateOfLoss.split("/");
                this.dateOfLoss  =  `${year}-${month}-${day}`;
               // this.computedDateofloss = moment(policyDataStore.dateOfLoss).format('DD/MM/YYYY');
                        if(this.claimDetails.policyStartDate!==""){
                                const [day, month, year] = requestData.dateOfLoss.split("/");
                                const lossEnd =`${day}-${month}-${year}`;
                                const policy = moment(this.claimDetails.policyStartDate).format("DD-MM-YYYY");
                                const date1 = moment(lossEnd, 'DD-MM-YYYY');
                                const date2 =  moment(policy, 'DD-MM-YYYY');
                                if(date1>date2){
                                    var diffmonths = date1.diff(date2, 'months');
                                    console.log('month',diffmonths)
                                    date2.add(diffmonths, 'months');
                                    var diffdays = date1.diff(date2, 'days')
                                    console.log('days',diffdays)
                                    
                                    if(diffmonths==0){
                                      this.claimDetails.dateDifference= diffdays.toString() + ' days'
                                    }else if(diffdays==0){
                                      this.claimDetails.dateDifference= diffmonths.toString() + ' Months'
                                    }
                                    else{
                                      this.claimDetails.dateDifference= diffmonths+ " "+ "Months" + " " + diffdays+ " " + "Days"
                                    }
                                }
                        }
              // this.claimDetails.policyStartDt = policyData.policyStartInceptionDate;
              // this.claimDetails.policyEndDt = policyData.policyExpiryDate;
              // this.claimDetails.businessType = policyData.txtBusinessType;
              // this.claimDetails.lrNo = res.data.data.data.marineDtls[0].lrNumber;
              // this.claimDetails.activeFlag = policyData.txtActiveFlag;
              //this.claimDetails.claimSubStatus=res.data.data.data.claimSubStatus;
              this.claimDetails.claimStatus = finalResponse.claimStatus;
              this.claimDetails.reasonForSuspense = finalResponse.reasonForSuspense;
              if(policyData.insuredMobileNumber) {
                this.insuredMobileISD           =   '91';
                this.insuredMobileNumber        =   policyData.insuredMobileNumber;
                                           
                this.claimDetails.insuredMobileNumber.push({
                  "isd":"91",
                  "number":policyData.insuredMobileNumber
                })
              }
              this.claimDetails.insuredEmailID = policyData.insuredEmailId;
              this.claimDetails.policyStartDate = policyData.policyStartInceptionDate;
                this.policyClaimantDetails = {
                            addressLine1: policyData.addressLine1,
                            addressLine2:policyData.addressLine2,
                            addressLine3 :policyData.addressLine3,
                            cityDistrict: policyData.cityDistrict,
                            state :policyData.state,
                            pincode:policyData.pincode,
                            countryname:policyData.countryName,
                            mobileNumber:policyData.insuredMobileNumber,
                            emailId:policyData.insuredEmailId
                          }
              this.hideProgress();
              this.loadStorageAndCoverageDesc(); //Surendra(27Feb2021)- Get Storage and Coverage Desc options
              this.isPolicyLoaded   = true;
              this.disableCoverageCode();
              this.productnames = [];//Surendra(02-03-2021):Show relevant policy options only
              this.productnames.push({"productName":policyData.txtProductName})//Surendra(02-03-2021):Show relevant policy options only
              this.updateCoverageOptions(); //Surendra(27Feb2021)- Update coverage options on product name
              }else{
               this.restPolicyDataFields();
               this.hideProgress();
              }
            }, (error) => {
               this.restPolicyDataFields();
               this.hideProgress();
              if(error.response.data.msg){
                 // this.classNameDialog = "errorDailogboxMsg";
                  this.dialogBoxText = error.response.data.msg;
                  this.showAlert(this.dialogBoxText);
                 // this.dialogboxError = true;
              }
              console.log(error);
            });
        }
      }
}
    
</script>

<style  scoped>
  /* new FNOL page styling */
.addresspagepointer{
cursor: pointer !important;;
}
  .invoic1th{
width:80px !important;
}
.invoic2th{
width:228px !important;
}
.invoic3th{
width:228px !important;
}
.invoic4th{
width:211px !important;
}
.invoic5th{
width:0px !important;
}
.deleteAllIconInvoic{
font-size: 26px;
margin-left: 11px;
}
.deleteIconInvoic{
font-size: 20px; 
color:red ;
}
.invoic1td{
width:60px !important;
}
.invoic2td{
width:60px !important;
}

  .marginStyle{
    margin-right: 66%;
  }

  .fontsizeStyle{
    font-size:16px;
  }
  .colStyle{
    text-align: center;padding: 0px;
  }
  .layoutdivCls{
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 2px #00000051;
    border: 0.5px solid #c1c8cc;
    opacity: 1;
  }

  
  .chipDIV{
    border-top: 0.5px solid #c1c8cc;
    margin-top: -10px;
    margin-bottom: 10px;
  }

  .mobileMargin{
    margin-right:70%
  }

  .titlemargin{
    margin-right:67%
  }

  .ContainerBGColor{
    background-color: #f7f7f7;
  }
  .emailClsWindow{
    border-left: 1px solid #c1c8cc; height: 45px;
  }

  .windowClsclass{
    border-left: 1px solid #c1c8cc; height: 40px;
  }

  .dialogBoxCardTitle{
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 7px #00000029;
    opacity: 1;
    font-size: 14px !important;
  }
  
.lr1th{
width:85px !important;
}
.lr2th{
width:286px !important;
}
.lr3th{
width:300px !important;
}
.deleteAllIconTh{
font-size: 26px;
margin-left: 11px;
}

.lr1td{
width:58px !important;
}
.lr2td{
width:274px !important;
}
.lr3td{
 width:258px !important;
}
.deleteIconTd{
font-size: 20px; 
color:red ;
}

.storage1td{
width:200px !important;
}
.storage2td{
width:224px !important;
}
.storage3td{
width:165px !important;
}
.storage4td{
width:157px !important;
}
.storage5td{
width:150px !important;
}
.storage6td{
width:105px !important;
}
.storageEmpyth{
 width:76px !important;
}
.storage1th{
width:192px !important;
border-left: none !important;
}
.storage2th{
width:220px !important;
}
.storage3th{
width:160px !important;
}
.storage4th{
width:157px !important;
}
.storage5th{
width:150px !important;
}
.storage6th{
width:122px !important;
}

.nolCodeTd{
border-left: none !important;
width:172px !important;
}
.coverGroupNameTd{
width:376px !important;
}
.perilNameTd{
width:290px !important;
}
.riskTd{
width:785px !important;
}
.riskDetailSerialTd{
width:300px !important;
}
.sumInsuredTd{
width:300px !important;
}
.backgroundWhite{
background: white !important;
}
.storageSpan{
  width: 91% !important;
}
.coverageSpan{
 width: 89% !important;
}
.tableTdBorderRightNone{
   border-right: none !important;
 }
.vcardTextTableBackground{
    background-color: #f7f7f7 !important;
  }
.dailogboxAllvcardTableBackground{
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 7px #00000029;
  opacity: 1;
  font-size: 14px;
  }
.lrInvoictableWidth{
 width: 90% !important;
  }
.coverageStorageTableWidth{
 width: 100% !important;
  }
  .coverCodeth{
border-left: none !important;
width: 190px !important;
}

.perilNameth{
 width: 210px !important;
}
.riskth{
 width: 500px !important;
}

  .TableScroll {
  display: block;
  height: 250px;
  overflow-y: scroll;
  }

  .checkboxWidth {
    height: 20px;
    width: 20px;
  }
  .invoiceDatatd {
    border: 1px solid #f7f7f7;
  }
  .saveinvoicebtn {
    text-transform: none;
    height: 43px !important;
    width: 135px;
    font-size: 12px;
    border-radius: 0;
    box-shadow: none;
    background-color: #e46a25 !important;
    color: #fff;
  }
  .addinvoicebtn {
    color: #fff;
    text-transform: none;
    height: 43px !important;
    width: 125px;
    font-size: 12px;
    border-radius: 0;
    background-color: #000 !important;
  }
  .invoiceDelete {
    text-transform: none;
    background-color: rgb(209, 18, 27);
    border-color: rgb(209, 18, 27);
    color: rgb(255, 255, 255);
    text-transform: none;
    height: 43px !important;
    /* width: 183px; */
    font-size: 12px;
    border-radius: 0;
  }
  .lrDelete {
    text-transform: none;
    background-color: rgb(209, 18, 27);
    border-color: rgb(209, 18, 27);
    color: rgb(255, 255, 255);
    text-transform: none;
    height: 43px !important;
    /* width: 183px; */
    font-size: 12px;
    border-radius: 0;
  }
  .addLRbtn {
    color: #fff;
    text-transform: none;
    height: 43px !important;
    width: 140px;
    font-size: 12px;
    border-radius: 0;
    background-color: #000 !important;
  }
  .saveLRbtn {
    text-transform: none;
    height: 43px !important;
    width: 140px;
    font-size: 12px;
    border-radius: 0;
    box-shadow: none;
    background-color: #e46a25 !important;
    color: #fff;
  }
  .vlrtableBackground {
    background-color: #01bbd9;
    width: 100% !important;
    display:block !important;
  }
  .CheckVlrtable {
    height: 2px;
    margin-bottom: 15px;
    padding-bottom: 11px;
    padding-top: 0px;
    margin-top: 0px;
  }
  /* .td {
    border: 1px solid #c1c8cc;
    background-color: #fff;
  } */
  th {
    height: 30px !important;
    color: white !important;
    font-size: 13px !important;
  }
  /* .vlratbletd {
    border: 1px solid #f7f7f7;
    border-width: 2px;
    border-right: none;
    border-collapse: collapse;
    height: 35px !important;
    font-size: 15px !important;
    color: #10242b;
  } */
  .layoutBorder {
    box-shadow: 0 2px 2px rgb(0 0 0 / 32%);
    border-bottom: 2px solid #f7f7f7;
    margin-bottom: 13px;
  }
  .GroupName {
    font-size: 15px;
    color: white;
    font-weight: bold;
  }

  .ClaimGroup {
    background: white;
    margin-left: 18px;
    height: 54px;
    border-radius: 5px;
  }
  .mainHeadBackgroundClaim {
      margin-top: 10px;
      background-color: #01bbd9;
      padding: 11px;
      margin-right: 0px;
      padding-bottom: 13px;
      padding-left: 0px;
      margin-bottom: 20px;
  }
  .groupRowHead {
    margin-top: 16px;
    background-color: #01bbd9;
    height: 43px;
  }
  /* new COde end css for newFNOL page */
  .scrollCoverage {
    max-height: 100px !important;
  }
  .mandatorycolor {
    color: #ee0914;
  }
  .img {
    border-right: 0.5px solid #ffffff;
    opacity: 0.5;
  }

  .commonstyle {
    max-width: 37%;
    border-radius: 0;
  }
  .blueiconstyle {
    margin-right: -12px;
    background-color: #01bbd9;
    width: 45px;
    height: 38px;
    font-size: 26px !important;
    opacity: 1;
    color: #fff !important;
    cursor: pointer !important;
  }
  .iconstyle {
    margin-left: -12px;
    background-color: rgb(247, 247, 247);
    width: 45px;
    height: 38px;
    font-size: 20px !important;
    border: 0.3px solid rgb(232, 235, 236);
    opacity: 1;
    /* box-shadow: rgba(0, 0, 0, 0.318) 0px 2px 2px; */
  }

  .okaybutton {
    color: #f5f6fa !important;
    text-transform: capitalize;
    height: 30px !important;
    width: 120px;
  }
  .buttonscolor {
    border-radius: 0 !important;
    height: 31px !important;
    color: #f5f6fa !important;
    text-transform: capitalize;
  }
  .bottomline {
    border-bottom: 1px solid #c1c8cc;
  }
  .topline {
    border-top: 1px solid #c1c8cc;
  }
  .inputwidth {
    max-width: 274px !important;
  }
  .claim {
    background-color: #f7f7f7;
    border: 1px solid #c1c8cc;
    font-weight: bold;
    width: 250px;
    height: 39px;
    padding: 10px;
  }
  .topdiv {
    border-bottom: 2px solid #cecece5e;
    width: 100%;
    margin: 0;
  }
  .forminput {
    border-radius: 0;
    font-size: 14px !important;
  }
  .claimlabel {
    color: #646666;
    font-size: 12px;
    font-weight: bold;
  }
  .btnsdiv {
    width: 1840px;
    height: 95px;
    background: #f7f7f7 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 2px #00000051;
    /* border: 0.5px solid #c1c8cc; */
  }
  v-input__prepend-outer {
    margin: 0 !important;
  }

  .addbutton {
    margin-top: 11px;
    height: 42px !important;
    width: 136px;
    border-radius: 0px;
    margin-right: 22%;
  }

  .additionaldtlsbar {
    background-color: #01bbd9;
    height: 42px !important;
    width: 100%;
    margin-right: 0;
    color: #fff;
    margin-top: 11px;
  }

  /* th {
    height: 38px !important;
    background-color: #01bbd9 !important;
    padding: 0 !important;
  }
  .v-data-table {
    width: 80% !important;
  }
  td {
    border: 1px solid #c1c8cc;
    background-color: #fff;
  } */

  .tableBackGroundColor {
    background-color: #23b1a9;
    width: 100% !important;
    display: block;
  }

  .coverageBody{
    display: block;
    overflow-y: scroll;
    height: 250px;
  }

  .tablehead {
    color: white !important;
    font-size: 12px !important;
    /* height: 35px !important; */
    border: 0.5px ridge #f7f7f7;
    border-width: 1px;
    border-right: none;
    border-collapse: collapse;
    text-align: center !important;
  }
  .tableheadCoverage {
    color: white !important;
    font-size: 12px !important;
    /* height: 35px !important; */
    border: 0.5px ridge #f7f7f7;
    border-width: 1px;
    border-right: none;
    border-collapse: collapse;
    text-align: center !important;
    width: 250px;
  }

  .tablebodyCoverage{
    font-size:13px !important;
    text-align: left !important;
  }
  .storageBody{
    display: block;
    height: 250px;
    overflow-y: scroll;
  }

  .tableheadCoverageEmpty {
    color: white !important;
    /* font-size: 15px !important; */
    border: 0.5px ridge #f7f7f7;
    border-width: 1px;
    border-right: none !important;
    border-collapse: collapse;
    text-align: center !important;
  }
  .buttonscss {
    border-radius: 0 !important;
    height: 30px !important;
    text-transform: capitalize;
    font-size: 15px;
    width: 110px !important;
  }

  .tabledata {
    /* border: 1px solid #f7f7f7; */
    border-width: 2px;
    border-right: none;
    border-collapse: collapse;
    height: 35px !important;
    font-size: 13px !important;
    color: #10242b;
    text-align:center;
  }

  .descrptionSpan {
    width: 85%;
    margin-left: 53px;
    margin-top: 21px;
    margin-bottom: 14px !important;
    font-weight: bold;
    font-size: 16px;
  }
   
  #create .v-speed-dial {
    position: absolute;
  }

  #create .v-btn--floating {
    position: relative;
  }

  .SummaryFirstdivContainer {
    padding-right: 10px;
    margin-right: 0;
    margin-left: 12px;
    background: white;
  }

  .claimSummerButtonsWidth {
    opacity: 1;
    text-align: center;
    height: 20px;
    font-size: 12px;
    font-weight: bold;
    color: #122569;
    width: 136px;
    padding-right: 3px;
    margin-top: 1px;
    cursor: pointer;
    border: 1px solid #122569;
    border-radius: 10px;
  }

  .v-speed-dial__list{
    top: -160px !important;
    right: 14px !important;
  }
  .containerStyle{
    margin: 0; 
    padding: 0; 
    max-width: 100%
  }
  .fontsize{
    font-size: 14px;
  }
  .withoutpolicycheck{
    height: 40px;
    padding-top: 8px;
    margin-top: 25px;
    width: 100%;
  }
  
  .iconfontsize{
    font-size: 18px;
}
.viconfont{
 font-size: 26px;
} 
.nobearingamt{
  margin-top:20px;
}
.losslocationwidth{
   width: 190px
}
.tataemp{
 padding-top: 8px; 
 height: 39px
}
.flexbottom{
  margin-bottom: 23px
}
.autostyle{
  cursor: pointer;
}
.internallocation{
  height: 40px;
  padding-top: 8px;
  margin-top: 25px;
}
.createfont{
font-size: 23px
}           
</style>