<template>
  <div class="ma-5">
    <h3>Map User To Zone</h3>
    <v-row>
      <v-col class="pt-6 pb-0">
        <label class="form-label font-weight-bold cstm-clr-pln-em">NTID/User ID</label>
        <v-text-field
          disabled
          solo
          dense
          class="forminput textDisable"
          placeholder="Enter"
          v-model="userObj.userId"
        >
        </v-text-field>
      </v-col>
      <v-col class="pt-6 pb-0">
        <label class="form-label font-weight-bold cstm-clr-pln-em">Full Name</label>
        <v-text-field
          solo
          disabled
          dense
          class="forminput rounded-0 textDisable"
          placeholder="Enter"
          v-model="userObj.employeeName"
        >
        </v-text-field>
      </v-col>
      <v-col class="pt-6 pb-0">
        <label class="form-label font-weight-bold cstm-clr-pln-em">Employee ID</label>
        <v-text-field
          solo
          disabled
          dense
          class="forminput rounded-0 textDisable"
          placeholder="Enter"
          v-model="userObj.employeeId"
        >
        </v-text-field>
      </v-col>
      <v-col class="pt-6 pb-0">
        <label class="form-label font-weight-bold cstm-clr-pln-em">LOB</label>
        <v-text-field
          solo
          disabled
          dense
          class="forminput rounded-0 textDisable font-weight-bold"
          placeholder="Enter"
          v-model="userObj.LOB"
        >
        </v-text-field>
      </v-col>
      <v-col class="pt-6 pb-0">
        <label class="form-label font-weight-bold cstm-clr-pln-em">Type Of Employee</label>
        <v-text-field
          solo
          dense
          disabled
          class="forminput rounded-0 textDisable"
          placeholder="Enter"
          v-model="userObj.typeOfEmployee"
        >
        </v-text-field>
      </v-col>
    </v-row>
      
    <v-row class="mb-5">
      <v-col class="pt-6 pb-0">
        <label class="form-label font-weight-bold cstm-clr-pln-em">Agreement Type</label>
        <div
                class="mt-1 mb-2"
              >
                <v-radio-group
                  v-model="userObj.agreementType"
                  row
                  dense
                  class="ma-0 pa-0 pb-1 pt-1 forminput"
                  style="background: #f1f1f1;"
                  hide-details
                  readonly
                >
                  <v-radio
                    color="#23B1A9"
                    dense
                    value="employee"
                    class="ma-0 pa-0 pl-2 "
                  >
                    <template v-slot:label>
                      <div
                        class="font-weight-bold"
                        style="color:#10242B;font-size:12px"
                      >
                        Employee
                      </div>
                    </template>
                  </v-radio>
                  <v-radio
                    color="#23B1A9"
                    dense
                    value="externalResource"
                    class="ma-0 pa-0 pl-2"
                  >
                    <template v-slot:label>
                      <div
                        class="font-weight-bold"
                        style="color:#10242B;font-size:12px"
                      >
                        External Resource
                      </div>
                    </template>
                  </v-radio>
                </v-radio-group>
              </div>
      </v-col>
      <v-col>
      </v-col>
      <v-col>
      </v-col>
      <v-col>
      </v-col>
      <v-col>
      </v-col>
    </v-row>
    <!--user Zone Map Bar  -->

    <v-card v-for="(userZone, index) in userZoneList" :key="index" class="mt-2">
      <v-row class="userZoneBar my-0 mx-0">
        <v-col class="d-flex justify-end mt-n2" cols="2" @click.stop="">
          <v-select
            class="mt-3 font-weight-bold custom-placeholer-color"
            :readonly="enableDisableZoneSelection(index,userZone)"
            @change="handleMainZoneChange(index)"
            dense
            rounded
            dark
            :items="zoneList"
            v-model="userZone.zone"
            placeholder="Select Zone"
          >
          </v-select>
        </v-col>
        <v-spacer></v-spacer>
        <v-col class="d-flex justify-end mt-n2">
          <!-- v-if="index + 1 > zoneListLength" -->
          <v-btn
            small
            color="transparent"
            class="white--text font-weight-bold"
            elevation="0"
            :disabled="!isEditMode"
            @click="handleDelete(userZone.zone, index)"
            >DELETE</v-btn
          >
          <!-- <v-btn
            small
            color="transparent"
            class="white--text"
            elevation="0"
            @click="handleExpansion(index)"
            >EDIT</v-btn
          > -->
          <v-btn
            v-if="userZone.zone"
            small
            color="#23b1a9"
            icon
            elevation="0"
            @click="handleExpansion(index)"
          >
            <v-icon v-if="expansionList[index]" color="white"
              >mdi-minus-box</v-icon
            >
            <v-icon v-else color="white">mdi-plus-box</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="ma-1" v-show="expansionList[index]">
        <v-col cols="3" class="mt-1">
          <v-card outlined class="pt-1">
            <v-checkbox
              color="#1ebbd9"
              v-model="userZone.zoneManager"
              :disabled="(!isEditMode && !(index + 1 > zoneListLength)) || (!(userRoles.filter((elem)=>{ return elem.roleName == 'CG4'}).length > 0) && (userObj.LOB == 'Marine' || userObj.LOB == 'AIGC' || userObj.LOB == 'PE'))"
              label="Zone Manager"
              class="ma-0 pa-0 mb-n4 ml-2"
            >
            </v-checkbox>
          </v-card>
        </v-col>
        <v-col cols="9">
          <v-card outlined height="300px" color="#F0F0F0" class="px-3 py-0">
            <v-row>
              <v-col cols="5" class="d-flex pa-0 ml-2 mr-15">
                <h4 class="pt-2">Settling Office List <span>*</span></h4>
              </v-col>
              <v-col cols="6" class="pa-0">
                <h4>
                  <v-icon large color="#23b1a9" class="mr-2">mdi-swap-horizontal</v-icon>
                  Selected Settling Office List
                </h4>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pt-1">
                <v-card height="256" color="white" outlined>
                  <v-text-field
                    v-model="Search_V_Models[index]"
                    placeholder="Search Settling Office"
                    solo
                    dense
                    class="forminput mx-2 mt-1 elevation-0 rounded-0"
                  >
                    <template v-slot:append class="my-0 mr-2">
                      <v-icon class="blueiconstyle">mdi-magnify</v-icon>
                    </template>
                  </v-text-field>
                  <v-checkbox
                    dense
                    v-model="userZone.allSelected"
                    @click="selectAllSettlingOffices(userZone,index)"                  
                    hide-details
                    class="pa-0 ma-0 pa-2"
                    color="#23B1A9"
                    :disabled="!isEditMode && !(index + 1 > zoneListLength)"
                  >
                    <span slot="label" class="font-weight-bold" style="font-size: 12px;color:#10242B">Select All</span>
                  </v-checkbox>
                  <div class="Zonelist ml-2">
                    <div
                      class="d-flex align-center"
                      :style="zone.settlementOfficeName && Search_V_Models.length &&
                        zone.settlementOfficeName.includes(
                          Search_V_Models[index].toUpperCase()
                        )
                          ? { height: '32px' }
                          : { display: 'none' }
                      "
                      v-for="(zone, i) in SettlingOfficeList[userZone.zone]"
                      :key="i"
                    >
                      <v-checkbox
                        color="#23b1a9"
                        dense
                        :disabled="!isEditMode && !(index + 1 > zoneListLength)"
                        v-model="addedZoneList[userZone.zone]"
                        :value="zone.settlementOfficeCode"
                        :label="
                          zone.settlementOfficeCode +
                            ' - ' +
                            zone.settlementOfficeName
                        "
                        v-if="zone.settlementOfficeName && Search_V_Models.length &&
                          zone.settlementOfficeName.includes(
                            Search_V_Models[index].toUpperCase()
                          )
                        "
                        :class="[ addedZoneList[userZone.zone].length > 0 ? addedZoneList[userZone.zone].includes(zone.settlementOfficeCode) ? 'textColorFOrcheckboxActive' : 'textColorFOrcheckboxIncative' :'textColorFOrcheckboxIncative']"
                         @change="checkSelectAll(userZone,index)"
                      ></v-checkbox>
                    </div>
                  </div>
                </v-card>
              </v-col>
              <v-col class="pt-1">
                <v-card height="230" color="white" outlined>
                  <!-- selectedZones -->
                  <div class="Zonelist2 ml-2">
                    <div
                      class="d-flex align-center"
                      :style="addedZoneList[userZone.zone] &&
                        addedZoneList[userZone.zone].includes(
                          zone.settlementOfficeCode
                        )
                          ? { height: '32px',color:'red' }
                          : { display: 'none' ,color:'yellow' }
                      "
                      v-for="(zone, j) in SettlingOfficeList[userZone.zone]"
                      v-show="!zone.selected"
                      :key="j"
                    >
                    <!-- :disabled="true" -->
                      <v-checkbox
                        dense
                        color="#23b1a9"
                        class="textColorFOrcheckboxActive"
                        v-if="addedZoneList[userZone.zone] &&
                          addedZoneList[userZone.zone].includes(zone.settlementOfficeCode)
                        "
                        v-model="addedZoneList[userZone.zone]"
                        readonly
                        :value="zone.settlementOfficeCode"
                        :label="zone.settlementOfficeCode + ' - ' + zone.settlementOfficeName"
                      ></v-checkbox>
                      <!-- {{ zone.settlementOfficeName }} -->
                    </div>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-card>

    <!-- Add Zone Button Part -->
    <v-row class="mt-2">
      <v-col cols="12" class="d-flex justify-center">
        <v-btn elevation="0" @click="handleAddZone" :disabled="!isEditMode" v-if="showHideAddZoneButton">
          <v-icon color="#23B1A9">mdi-plus</v-icon>
          <h4 class="addBtn ml-1 text-capitalize">Add Zone</h4>
        </v-btn>        
      </v-col>
    </v-row>

    <!-- Footer Button Part -->
    <v-row>
      <v-col>
        <v-btn
          @click="$router.go(-1)"
          color="red"
          outlined
          class="subtitle-2 text-capitalize mr-1"
        >
          <v-icon class="">mdi-backspace</v-icon>
          <v-divider class="mx-2 ml-2" vertical></v-divider>
          Cancel
        </v-btn>
      </v-col>
      <v-spacer></v-spacer>
      <v-col class="d-flex justify-end">
        <v-btn
          dark
          type="submit"
          class="text-capitalize ml-2"
          color="#23B1A9"
          depressed
          @click="submitZoneMapping()"
          v-if="showHideSubmitButton"
          :disabled="!isEditMode"
        >
          <v-icon>mdi-arrow-right-circle</v-icon>
          <v-divider vertical color="white" class="mx-2"></v-divider>
          Submit
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// import showProgressVue from "../../components/showProgress.vue"
export default {
  data() {
    return {
      userObj: {
        userId: "",
        employeeId: "",
        employeeName: "",
        LOB: "",
        typeOfEmployee: "",
      },
      zoneListLength: 0,
      userZoneList: [],
      zoneList: [],
      zoneModel: [],
      Search_V_Models: [],
      check: true,
      // selectedZones: [],
      SettlingOfficeList: {},
      addedZoneList: {},
      SettlingOfficeList2: {},
      expansionList: [],
      selectedMainZones: [],
      isEditMode: false,
      userRoles:[]
    }
  },
  methods: {
    getSettlingOfficeList(LOB) {
      this.showProgress()
      let result = {}
      console.log(this.userZoneList)
      for (let i = 0; i < this.userZoneList.length; i++) {
        
       this.GET(6,'settlementoffice/search-settlement-office'+'?lob='+LOB+'&zone='+this.userZoneList[i].zone,(res,error)=>{//+'&zone='+zone
            if (!error && res.data && res.data.data && res.data.data.data) {
              let list = res.data.data.data
              result[this.userZoneList[i].zone] = list
              this.SettlingOfficeList2[this.userZoneList[i].zone] = list
              if (i === this.userZoneList.length - 1) {
                console.log("looping...")
                this.hideProgress()
                this.showProgress()
                setTimeout(() => {
                  this.hideProgress()
                  this.SettlingOfficeList = result
                }, 50)
              }
            }else{
              this.hideProgress()
              console.log(error.response)
            }
          })
      }
    },
    handleSearch(officeName, searchedValue) {
      console.log("searching function")
      if (searchedValue === "") {
        return true
      }
      return officeName.includes(searchedValue)
      // return arr.filter((item) =>
      //   item.settlementOfficeName.includes(searchedValue.toUpperCase())
      // )
      // console.log(
      //   this.SettlingOfficeList2[zone].filter((item) =>
      //     item.settlementOfficeName.includes(searchValue.toUpperCase())
      //   )
      // )
      // let result = this.SettlingOfficeList2[zone].filter((item) =>
      //   item.settlementOfficeName.includes(searchValue.toUpperCase())
      // )
      //   this.SettlingOfficeList = Object.assign({}, this.SettlingOfficeList, {
      //     [zone]: result,
      //   })
    },
    getZoneList() {
      this.showProgress()
      this.GET(6, "settlementoffice/get-zone-list", (res, error) => {
        if(error){
          this.hideProgress()
          console.log(error.response)
        }else{
          this.hideProgress()
          this.zoneList = res.data.data
        }          
      })
    },
    handleDelete(zone, index) {
      let msg = "Are you sure You want to delete "+zone+" Zone?"
      this.showConfirm(
        "Delete Zone",
        msg,
        "Yes,Delete",
        "Cancel",
        () => {
          // this.userZoneList = this.userZoneList.filter((_, i) => i !== index)
          console.log("Before - this.expansionList = ",this.expansionList," this.userZoneList",this.userZoneList," this.Search_V_Models = ",this.Search_V_Models)
          this.expansionList.splice(index, 1)
          this.userZoneList.splice(index, 1)
          this.Search_V_Models.splice(index, 1)
          console.log("After - this.expansionList = ",this.expansionList," this.userZoneList",this.userZoneList," this.Search_V_Models = ",this.Search_V_Models)
        }
      )
    }, 
    handleAddZone() {
      let emptyOBj = {
        zone: "",
        zoneManager: this.userRoles.filter((elem)=>{ return elem.roleName == "CG4"}).length > 0 ? true : false,
        allSelected:false
        // selectedZones: [],
      }
      this.expansionList.push(false)
      this.userZoneList.push(emptyOBj)
      this.Search_V_Models.push("")
      this.expansionList = this.expansionList.map((item) => false)
    },
    getSelectedOffice(zonelist, userId) {
      this.showProgress()
      console.log(zonelist)
       let api_Is ="users/all-users/"+userId;// "users/search-users?userid=" + userId;          
        this.GET(6, api_Is, (res, error) => {
          if(!error){
            this.hideProgress()
            console.log(res.data.data)
            if(res.data && res.data.data){
              let data = res.data.data
              let result = data.userZoneMapping.reduce((acc, item) => {
                let arr = item.selectedSettlingOffice.map(
                  (office) => office.settlementOfficeCode
                )
                return { ...acc, [item.zone]: arr }
              }, {})

              this.addedZoneList = result
            }
            
          }else{
            this.hideProgress()
            console.log(error.response)
          }
          
        })
    },
    handleExpansion(index) {
      if (this.userZoneList[index].zone === "") {
        return
      }
      this.expansionList.splice(index, 1, !this.expansionList[index])
    },
    getOfficeList(index, zone) {
      let LOB = this.$route.params.data.selectedLOB
      this.GET(6,'settlementoffice/search-settlement-office'+'?lob='+LOB+'&zone='+zone,(res,error)=>{
          if (!error && res.data && res.data.data && res.data.data.data) {
            let list = res.data.data.data
            // this.SettlingOfficeList
            console.log(list)
            this.SettlingOfficeList = {
              ...this.SettlingOfficeList,
              [zone]: list,
            }
            this.SettlingOfficeList2 = {
              ...this.SettlingOfficeList2,
              [zone]: list,
            }
            // this.SettlingOfficeList = Object.assign(
            //   {},
            //   {...this.SettlingOfficeList,[zone]: list },
            // )
            // this.SettlingOfficeList2 = Object.assign(
            //   {},
            //   {...this.SettlingOfficeList2, [zone]: list },
            // )
            console.log(list)
          }else{
            this.hideProgress()
            console.log(error.response)
            this.showAlert(error.response && error.response.data && error.response.data.msg ? error.response.data.msg : "Error")
          }
        })
    },
    handleMainZoneChange(index) {
      if (index + 1 < this.zoneListLength) {
        return
      }
      //checking zone are already selected
      if (this.userZoneList.filter((elem)=>{
            return elem.zone == this.userZoneList[index].zone;}).length > 1) {
        let emptyOBj = {
          zone: "",
          zoneManager: false,
          // selectedZones: [],
        }
        setTimeout(() => {
          this.userZoneList.splice(index, 1, emptyOBj)
        }, 0)
        return this.showAlert(
          `Zone ${this.userZoneList[index].zone} is already selected`
        )
      }
      this.getOfficeList(index, this.userZoneList[index].zone)
      // this.addedZoneList = {
      //   ...this.addedZoneList,
      //   [this.userZoneList[index].zone]: [],
      // }
      this.addedZoneList = Object.assign({}, this.addedZoneList, {
        [[this.userZoneList[index].zone]]: [],
      })
      this.expansionList.splice(index, 1, true)
    },
    submitZoneMapping() {
      let length=this.$route.params.data.userZoneMapping.length;
      let isFieldFilled=false;
      let isZoneFilled=false;
      let emptyZoneName = ""
      for(let i=0;i<this.userZoneList.length;i++){
        if(this.userZoneList[i].zone==="") {isZoneFilled=true;console.log("empty zone");break;}
        if(this.addedZoneList[this.userZoneList[i].zone].length===0) {
          isFieldFilled = true; 
          emptyZoneName = this.userZoneList[i].zone
          break;
        }
      }
      
      if(isZoneFilled) return this.showAlert("Please select the zone") 
      if(isFieldFilled){
        return this.showAlert("Please select atleast one Settling Office"+(emptyZoneName ? " for "+ emptyZoneName : ""))
      }
      
      let result = [...this.userZoneList]
      console.log(this.addedZoneList)
      console.log(this.userZoneList)
      for(let i=0;i<=this.userZoneList.length-1;i++){
        let zone =this.userZoneList[i].zone
        //selectedSettlingOffice
        result[i].selectedSettlingOffice=this.SettlingOfficeList[zone].filter((item2)=>this.addedZoneList[zone].includes(item2.settlementOfficeCode)).map((item)=>{
          return item._id
        })
      }
      result.map((ele) =>{ delete ele._id; delete ele.allSelected})// /CM-56 by gopi
      console.log(result);
      let apiURL='users/update-zone/'+this.userObj._id
            try{
                this.PUT(6,apiURL,result,(res,error)=>{
                    if(!error){
                          console.log("Put APi response|-> empanelment/save-staff-details ", res.data)
                          if(res.data.status){
                            this.zonechange = false;
                            this.showToast("User Zone Mapping saved successfully",this.TOST().SUCCESS)
                            this.showAlert("User Zone Mapping saved successfully")
                            // this.getUsersById(this.UserObjId)
                            // this.$router.push('/usersearch');
                            this.$router.go(-1)
                          }else{
                            this.showAlert(res.data.msg)
                          }
                        //   else if(incmgTxt==='submit'){
                        //     this.zonechange = false;
                        //     this.showToast("User Zone Mapping updated successfully",this.TOST().SUCCESS)  
                        //     this.showAlert("User Zone Mapping updated successfully")
                        //     this.getUsersById(this.UserObjId)
                        //     this.$router.push('/usersearch');
                        //   }
                      
                          
                    }else{
                        console.log("Put APi error response|-> empanelment/save-staff-details ", error)
                        this.showToast(error.response.data.msg ,this.TOST().ERROR);
                        this.showAlert(error.response.data.msg)
                    }
                }) 
            } catch (error) {
                 console.log("from ctch -> Put APi error response|-> empanelment/save-staff-details ", error)
            }
    },
    selectAllSettlingOffices(userZone,index){
      console.log("SettlingOfficeList => ",this.SettlingOfficeList[userZone.zone]," UserZone[index] => ",this.userZoneList[index],"userZone.zone => ",userZone.zone, "addedZoneList => ",this.addedZoneList[userZone.zone])
      this.addedZoneList[userZone.zone] = []
      if(userZone.allSelected){
        for(var i in this.SettlingOfficeList[userZone.zone]){
          this.addedZoneList[userZone.zone].push(this.SettlingOfficeList[userZone.zone][i].settlementOfficeCode)
        }        
      }
    },
    checkSelectAll(userZone,index){
      console.log(this.userZoneList,index)
      this.userZoneList[index].allSelected  = false
      if(this.addedZoneList[userZone.zone].length ==  this.SettlingOfficeList[userZone.zone].length){
        this.userZoneList[index].allSelected  = true
      }
    },
    enableDisableZoneSelection(index,userZone){
      return !(index + 1 > this.zoneListLength) && userZone.hasOwnProperty("_id")
    }
  },
  created() {
    this.$store.commit("SAVE_PAGE_HEADER", "User Zone Mapping");
    this.getZoneList()
    if (this.$route.params.modifyMode) this.isEditMode = true
    console.log("Params", this.$route.params)
    let data = this.$route.params.data
    console.log("complete data - ",data);
    if(data.selectedLOB == "Marine" || data.selectedLOB == "AIGC" || data.selectedLOB == "PE"){
      let isCG4 = data.assignRole.filter((elem)=>{return elem.roleName == "CG4"}).length > 0
      if(isCG4){
        data.userZoneMapping.map((zoneData)=>{
          console.log("zoneManager -true ",zoneData,isCG4)
          return zoneData.zoneManager = true
        })
      }else{
        data.userZoneMapping.map((zoneData)=>{
          console.log("zoneManager -true ",zoneData,isCG4)
          return zoneData.zoneManager = false
        })
      }

    }
    this.userZoneList = [...data.userZoneMapping]
    this.getSettlingOfficeList(data.selectedLOB)
    this.userObj._id    = data._id;
    this.userObj.userId = data.ntidUserId
    this.userObj.employeeId = data.employeeId
    this.userObj.employeeName = data.employeeName
    this.userObj.LOB = data.selectedLOB
    this.userObj.typeOfEmployee = data.typeOfEmployee
    this.userObj.agreementType  = data.externalResource ? "externalResource" : "employee"
    this.zoneListLength = data.userZoneMapping.length
    this.getSelectedOffice(data.userZoneMapping, data._id)
    this.Search_V_Models = Array(data.userZoneMapping.length).fill("")
    this.expansionList = Array(data.userZoneMapping.length).fill(false)
    this.selectedMainZones = data.userZoneMapping.map((item) => item.zone)
    this.userRoles  = data.assignRole
    // for(var i in this.userZoneList){
    //   this.checkSelectAll(this.userZoneList[i],i)
    // }
  },
  computed:{
    showHideSubmitButton(){
      // console.log(this.userZoneList,"userZoneList")
      // if(!this.userZoneList.length){
      //   return false
      // }
      let find = this.userZoneList.find(ele=> !ele.zone);
      return find ? false : true;
    },
    showHideAddZoneButton(){
      // console.log(this.userZoneList,"showHideAddZoneButton")/
      let find = this.userZoneList.find(ele=> !ele.zone);
      return this.userZoneList.length && find ? false : true;
    }
  }
}
</script>

<style scoped>
.addBtn {
  color: #23b1a9 !important;
}
.userZoneBar {
  /* background-color: #1ebbd9 !important; */
  background-color: #01bbd9ed !important;
  height: 35px !important;
}
.zoneCol {
  background-color: #23b1a9 !important ;
}
.Zonelist {
  height: 150px !important;
  overflow-y: scroll !important;
}
.Zonelist2 {
  height: 210px !important;
  overflow-y: scroll !important;
}
.blueiconstyle {
    margin-right: -12px;
    background-color: #01bbd9;
    width: 45px;
    height: 38px;
    font-size: 26px !important;
    opacity: 1;
    color: #fff !important;
    cursor: pointer !important;
  }
  

</style>
<style>
.textColorFOrcheckboxActive .v-label.theme--light {
    color: #23b1a9 !important;
    font-weight: 500;
}
.textColorFOrcheckboxIncative .v-label.theme--light {
  color: #000000a1;
  font-weight: 500;
}
.custom-placeholer-color input::placeholder {
  color: white!important;
  opacity: 1;
}


</style>
