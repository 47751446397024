<template>
    <v-app>
            <v-card class="ma-3 " tile >
                <v-data-table
                    :headers="headers"
                    :items="GetData"
                   
                    hide-default-footer
                    dense
                   >

                    <template v-slot:item.inwardID={item} >
                        <tr>
                             <!-- @click="onClickItem(item, value)" -->
                            <td style=" !important;"
                             @click="inwardsdetails"  > 
                             <span class="hyperlnk" >{{item.inwardID}}</span>
                            </td>
                        </tr>
                    </template>

                            
                            
                </v-data-table>
                    <v-divider></v-divider>
                    <v-layout row justify-end align-center class="mx-2">
                        <v-text class="mr-1 font-weight-bold body-1" style="color:#3C3D3D; font-size:12px !important">
                                <span class="" style="color:#E46A25;font-size:12px;">Showing {{fval}}-{{sval}} </span>out of {{totalLen }} records
                        </v-text>
                        <v-divider  class="mx-2" vertical ></v-divider>
                        <v-pagination 
                            class="cust-bnkAcc-pgn"
                            v-model="page"
                            color="#152F38"
                            flat
                            :total-visible="9"
                            @input="nextPageBtn(page)"
                            :length="pageLength"
                            ></v-pagination>
                    </v-layout>
            
            </v-card>
    </v-app>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
export default {

    created() {
        this.$store.commit("SAVE_PAGE_HEADER", "Inward Supervisor Queue");
        // this.searchAll(this.page)
        console.log("GetData ", this.GetData);

    },
      filters:{
    
        checkdata(data){
            // console.log('Validity Data', data);
            if (data ==='' || data ===undefined || data === null || data=== 0 || data === 'Invalid Date' ) {
                return '-'
            } else {
                return data;
            }
        },
    
    },

    data() {
        return {

            // for pagination AA
            swap_final_count:false,
            fval:0,
            sval:0,
            totalLen:'',
            itemsPerPage:10,            
            page: 1,
            pageLength:'',
            
            GetData:[
                {
                    inwardID:'ISDIUHD937YE38',
                    claimID:'CLADND7Y87',
                    consignrecieveDate:'4/5/2021',
                    consignrecieveTime:'4/5/2021',
                    consignNumber:'COSIGNNUMBER90920',
                    noofDoc:'2',
                    allData:{}
                },
                  {
                    inwardID:'ISDIUHD937YE39',
                    claimID:'CLADND7Y88',
                    consignrecieveDate:'4/5/2021',
                    consignrecieveTime:'4/5/2021',
                    consignNumber:'COSIGNNUMBER90925',
                    noofDoc:'1',
                    allData:{}
                },
            ],

           
            headers:[
                
                {text: 'Inward ID', value: 'inwardID',sortable: true,divider:true,class:"hdr-txt-clr-inwardSupervisorQueue backColor"},
                {text: 'Claim ID', value: 'claimID',sortable: true,divider:true,class:"hdr-txt-clr-inwardSupervisorQueue backColor"},
                {text: 'Consignment Recieved Date', value:'consignrecieveDate',sortable: false,divider:true,class:"hdr-txt-clr-inwardSupervisorQueue backColor"},
                {text: 'Consignment Recieved Time', value: 'consignrecieveTime',sortable: false,divider:true,class:"hdr-txt-clr-inwardSupervisorQueue backColor"},
                {text: 'Consignment Number', value: 'consignNumber',sortable: false,divider:true,class:"hdr-txt-clr-inwardSupervisorQueue backColor"},
                {text: 'No of Documents', value: 'noofDoc',sortable: false,divider:true,class:"hdr-txt-clr-inwardSupervisorQueue assignWidth backColor"}
            ],
            WorkItem:[ 
                    {text:'select'},
                    {text:'xyz'}
            ],

        };



    },

    methods: {

        inwardsdetails(){
            this.$router.push('/InwardAssessor')
        },

        nextPageBtn(page){
            if(this.pageLength === page){
                this.lastPage = true
                console.log("this is last page",page);
            }else{
                this.lastPage = false
                console.log("this is not last page",page);
            }
            this.searchAll(page)
        }, 


        searchAll(page){

                  axios({
                    method: "get", 
                    url:'http://claimsdrive-health.salesdrive.app/inward-management/inward/omni-docs/'+page+'/'+10,
                            headers: {
                                "Content-Type": "application/json", 
                                "x-access-token":this.$store.getters.successToken,
                                //  "x-access-token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI1ZmJlMDg4ZGM0NmRhODNlZTdmODcxYmIiLCJyb2xlIjoiQWRtaW5Vc2VyIiwiaWF0IjoxNjA2MzY5MzI0LCJleHAiOjE2Mzc5MDUzMjR9.iS5hZs0uImNFz-rxNaQ7CTCc6qS_hG4H0FsePZ5Sgqc",
                            }, 
                        })
                        .then(function(res, error) {

                            console.log("api response is ", res)

                    })

         
        }
        
}, 
}

</script>
<style >
/* .v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
    font-size: 0.84rem !important;
} */

.hyperlnk{ text-decoration: underline; color:rgb(25, 118, 210); cursor:pointer; }



.backColor{
    background-color: #23B1A9;
    
}

.hdr-txt-clr-inwardSupervisorQueue{
    color:white !important;
}

.hdr-bg-clr{
    background-color: #f7f7f7;
}
.cust_pr .v-text-field .v-input__slot,
.v-text-field--outline .v-input__slot {
    min-height: 38px !important;
    display: flex !important;
    align-items: center !important;
    /* border:1px red solid; */
}

.pending-work{
    justify-items: start;
}

.lay{
        direction: rtl;
        max-height: 50px;

}
.body-2{
        font-size:18px !important;

}
.body-3{
        font-size:16px !important;

}



.cstm-clr-pr{
    color:#10242B;
    margin-bottom: 5px;
}
.cust_pr .v-input{
    font-size: 13px !important;
}
.cust_pr .v-input input {
    font-size: 13px !important;
}

.cust_pr .v-input .v-label {
    font-size: 13px !important;
}


 .cust-bnkAcc-pgn .v-pagination__navigation{
        box-shadow:unset !important;
        border-radius: unset !important;
        background-color:#FFFFFF !important;
        border:1px solid #DAE1E6 !important
    }
    .cust-bnkAcc-pgn .v-pagination__navigation--disabled{
        background-color:#DAE1E6 !important;
    }
    .cust-bnkAcc-pgn .v-pagination__item{
        box-shadow:unset !important;
        min-width:2rem !important;
        height:2rem !important;
        border-radius: unset !important;
        border:1px solid #DAE1E6 !important
    }   
</style>