<template>
  <div>
    <v-row class="mt-10">
      <v-flex xs3>
        <v-layout column class="ml-3">
          <p class="mb-2 lableFontSize">
            simple Date picker with DD/MM/YYYY<span class="mandatorycolor">
              *</span
            >
          </p>
          <v-menu
            v-model="dateMenu"
            :close-on-content-click="false"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                placeholder="dd/mm/yyyy"
                solo
                dense
                readonly
                class="CalenderStartDate"
                required
                v-bind="attrs"
                v-on:focus="dateMenu = true"
                v-on="on"
                :rules="rulesValidation.date"
                append-icon="mdi-menu-down"
                v-model="datePickerModel"
              >
                <template v-slot:prepend-inner class="mx-0 my-0">
                  <v-icon class="iconstyle">mdi-calendar</v-icon>
                </template>
              </v-text-field>
            </template>
            <v-date-picker
              v-model="dateFormatted"
              :min="todayDate"
              no-title
              @input="dateMenu = false"
              @click:date="datePickerModel = formatDate(dateFormatted)"
            ></v-date-picker>
          </v-menu>
        </v-layout>
      </v-flex>

      <v-flex xs3>
        <v-layout column class="ml-3">
          <p class="mb-2 lableFontSize">
            Date Range and scroll<span class="mandatorycolor"> *</span>
          </p>
          <v-menu
            v-model="dateMenu1"
            :close-on-content-click="false"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                solo
                dense
                readonly
                class="CalenderStartDate"
                required
                v-bind="attrs"
                v-on:focus="dateMenu1 = true"
                v-on="on"
                append-icon="mdi-menu-down"
                v-model="dateRangeText"
              >
                <template v-slot:prepend-inner class="mx-0 my-0">
                  <v-icon class="iconstyle">mdi-calendar</v-icon>
                </template>
              </v-text-field>
            </template>
            <v-date-picker
              v-model="dateFormattedRange"
              scrollable
              range
              no-title
              @change="DateRangechange"
            ></v-date-picker>
          </v-menu>
        </v-layout>
      </v-flex>

      <v-flex xs3>
        <v-layout column class="ml-3">
          <p class="mb-2 lableFontSize">
            Defined by function<span class="mandatorycolor"> *</span>
          </p>
          <v-menu
            v-model="dateMenu2"
            :close-on-content-click="false"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                solo
                dense
                readonly
                class="CalenderStartDate"
                required
                v-bind="attrs"
                v-on:focus="dateMenu2 = true"
                v-on="on"
                append-icon="mdi-menu-down"
              >
                <template v-slot:prepend-inner class="mx-0 my-0">
                  <v-icon class="iconstyle">mdi-calendar</v-icon>
                </template>
              </v-text-field>
            </template>
            <v-date-picker
              v-model="dateDefined"
              :landscape="true"
              :event-color="(date) => (date[9] % 2 ? 'red' : 'yellow')"
              :events="functionEvents"
              @change="DefinedFunction"
            ></v-date-picker>
          </v-menu>
        </v-layout>
      </v-flex>
    </v-row>

      <v-btn color="red"   @click="closed"> Toast </v-btn>
  </div>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      todayDate: new Date().toISOString().substr(0, 10),
      dateFormatted: null,
      dateMenu: false,
      dateMenu1: false,
      dateMenu2: false,
      datePickerModel: "",
      datePickerModel1: "",
      errorMessage: "This is required.",
      dateFormattedRange: ["2020-05-10", "2020-05-20"],
      dateDefined: new Date().toISOString().substr(0, 10),
      rulesValidation: {
        date: [(v) => !!v || this.errorMessage],
      },
    };
  },

  computed: {
    dateRangeText() {
      return this.dateFormattedRange.join(" ~ ");
    },
  },
  methods: {
    closed(){
         this.showToast("Hewloooooo toast")
    },
    formatDate(date) {
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    DateRangechange() {
      this.dateMenu1 = false;
    },
    DefinedFunction() {
      this.dateMenu2 = false;
    },
    functionEvents(date) {
      const [, , day] = date.split("-");
      if ([12, 17, 28].includes(parseInt(day, 10))) return true;
      if ([1, 19, 22].includes(parseInt(day, 10))) return ["red", "#00f"];
      return false;
    },
  },
};
</script>
<style scoped>
.iconstyle {
  margin-left: -12px;
  background-color: rgb(247, 247, 247);
  width: 45px;
  height: 38px;
  font-size: 20px !important;
  border: 0.3px solid rgb(232, 235, 236);
  opacity: 1;
}
</style>