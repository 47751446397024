<template>
    <v-dialog v-model="show"  persistent width="70">
        <!-- <v-card color="rgb(1, 180, 187)" dark>
            <v-card-text class="text-xs-center"> 
                {{title}} -->
                <div class="text-xs-center">
                    <v-progress-circular 
                        indeterminate color="#C1C8CC" 
                        :size="70" 
                        class="mb-0"
                        :width="2">
                    </v-progress-circular>
                </div>
                
            <!-- </v-card-text>
        </v-card> -->
    </v-dialog>
</template>

<script>

export default {
    computed: {
        title() {
            return this.$store.getters.loader.title 
        },
        show() {
            return this.$store.getters.loader.bool 
        }
    }
}
</script>

