<template>
    <v-app>
        
       
            <v-card class="ma-3 " tile >
                    <v-layout class="pa-2" align-center justify-space-between style="background-color:#23B1A9;" >
                    
                            <div class="">
                                <v-text class="font-weight-bold "  style="color:#F5F5F5">
                                    Provider Empanelment Approval
                                </v-text>
                            </div>
                            <v-flex xs3>
                                <div class="ml-15" >
                                    <v-text class=" font-weight-regular"  style="color:#F5F5F5; ">Pending Work Items : <span class="font-weight-bold ">{{10}}</span>
                                    </v-text>
                                </div>
                            </v-flex>
                            <v-flex xs2>
                               </v-flex>
                    
                    </v-layout>
                


                
                
                <v-data-table
                    :headers="headers"
                    :items="GetData"
                    :page.sync="page"
                    :items-per-page="itemsPerPage"
                    hide-default-footer
                    dense
                    @page-count="pageCount = $event">

                    <template v-slot:item.all.AssignedTo = {item,value} >
                        <tr >
                            <td style=" !important;"
                                @click="onClickItem(item, value)"> 
                        
                                <div class="d-flex flex-row justify-space-around"  v-if="item.assignToName === undefined">
                                    
                                    <p style="width: 120px; color: #8a9aa5 " class="mt-2" >
                                        Do you want to select work item?
                                    </p>

                                    <v-radio-group
                                        dense
                                        style="background-color: ;display:flex; align-items:center;justify-content:center"
                                        row
                                        :key="item.assignToId"
                                        v-model="value"
                                        >
                                        <v-radio
                                            label="Yes"
                                            value="Yes"
                                            class="black--text"
                                            color= #23B1A9
                                        ></v-radio>
                                        <v-radio
                                            label="No"
                                            value="No"
                                            class="black--text"
                                            color=#23B1A9
                                        ></v-radio>
                                    </v-radio-group>
                                </div>
                                
                                <div class="d-flex justify-space-between"  style="width: 265px;"   v-else>
                                    <p class="mt-3 assignedName">{{item.all.assignToName}}</p>
                                    <v-icon class="assignedNameclear" small>mdi-close-circle-outline</v-icon>
                                    
                                </div>
                            
                            </td>
                        </tr>
                    </template>

                    <template v-slot:item.Action = {item,value} >
                        
                        <tr >
                            <td style=" !important;">
                        
                            <v-btn color="#1E9690" class="" style="width: 25px; height: 25px" @click="editBnkAcc(item)" fab dark small>
                                    <v-icon  small>arrow_forward</v-icon>
                            </v-btn>
                        
                        </td>
                        </tr>
                    </template>
                            
                            
                </v-data-table>
                    <v-divider></v-divider>
                    <v-layout row justify-end align-center class="mx-2">
                        <v-text class="mr-1 font-weight-bold body-1" style="color:#3C3D3D; font-size:12px !important">
                                <span class="" style="color:#E46A25;font-size:12px;">Showing {{fval}}-{{sval}} </span>out of {{totalLen }} records

                        </v-text>
                        <v-divider  class="mx-2" vertical ></v-divider>
                        <v-pagination 
                            class="cust-bnkAcc-pgn"
                            v-model="page"
                            color="#152F38"
                            flat
                            :total-visible="9"
                            @input="nextPageBtn(page)"
                            :length="pageLength"
                            ></v-pagination>
                    </v-layout>
            
            </v-card>
    </v-app>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
export default {

    components:{
        
    },

    created() {
        this.$store.commit("SAVE_PAGE_HEADER", " Provider Empanelment Approval");
        
        console.log("Success Token", this.$store.getters.successToken)
        this.token=this.$store.getters.successToken
        this.getProviderApp(this.page)

    },

   

    

     computed: {


     },
      filters:{
    
        checkdata(data){
            // console.log('Validity Data', data);
            if (data ==='' || data ===undefined || data === null || data=== 0 || data === 'Invalid Date' ) {
                return '-'
            } else {
                return data;
            }
        },
    
    },

    data() {
        return {

            // for pagination AA
            swap_final_count:false,
            fval:0,
            sval:0,
            totalLen:'',
            itemsPerPage:10,            
            page: 1,
            pageLength:'',
            
            GetData:[],

           
            headers:[
                
                {text: 'Received Date', value: 'newdate',sortable: true,divider:true,class:"hdr-txt-clr backColor"},
                {text: 'Work Item', value: 'workItemId',sortable: true,divider:true,class:"hdr-txt-clr backColor"},
                {text: 'Provider Name', value:'name',sortable: false,divider:true,class:"hdr-txt-clr backColor"},
                {text: 'Due Date', value: 'newduedate',sortable: false,divider:true,class:"hdr-txt-clr backColor"},
                {text: 'Pending From', value: 'all.pendingFrom',sortable: false,divider:true,class:"hdr-txt-clr backColor"},
                {text: 'Assigned To', value: 'all.AssignedTo',sortable: false,divider:true,class:"hdr-txt-clr assignWidth backColor"},

                {text: 'Action', value: 'Action',sortable: false,divider:true, width:"40px",class:"hdr-txt-clr hdr-bg-clr"}

            ],
            WorkItem:[ 
                    {text:'select'},
                    {text:'xyz'}
            ],

        };



    },

    methods: {

        nextPageBtn(page){
            this.getProviderApp(this.page)
        },

        // nextPage(page) {
        //     this.pageNumber = page;
        // },
        editBnkAcc(item){
               console.log("%c this is the value from action button","color:green", item.all)

                if(item.all.assignToName === undefined){
                        this.showToast('Select a work item' ,this.TOST().WARNING);
                }else{  
                        let id = item.all.docId;
                        if(id===""|| id=== null|| id===undefined){
                                console.log(id," doc id")
                                return
                            }
                        if(this.$store.getters.fetchRole === 'AdminUser'){
                            this.$store.commit('SAVE_PRODUCT_NAME', id)
                            let btnName={
                                            text:'View Work Item',
                                            visible:false
                                        }
                            this.$store.commit('SAVE_PRODUCT_BTN_NAME', btnName)


                            this.$router.push('/product/createEditProduct')
                            
                        }else{
                            if(this.$store.getters.fetchRole === 'SuperUser'){

                            this.$router.push(
                                {
                                    name: 'ProviderEmpanelment', 
                                    params: {
                                                userId:id,
                                                name:item.all.name,
                                                type:item.all.type,
                                                empanelmentType:item.all.empanelmentType,
                                                allData:item
                                            }
                                })
                        }else if(this.$store.getters.fetchRole === 'Reviewer'){

                            this.$router.push(
                                {
                                    name: 'ProviderEmpanelment', 
                                    params: {
                                                userId:id,
                                                name:item.all.name,
                                                type:item.all.type,
                                                empanelmentType:item.all.empanelmentType
                                            }
                                })
                        }
                        // else{
                        //     this.$router.push('/homePage')
                        // }
                        }
                                        
                    }
        },


        getProviderApp(pageNum) {
        // console.log("individual sort by-------",sortBy1)
                console.log("praq====> page no ---->",pageNum)
                
                    let self = this;   
                    axios({
                            method: "get",
                            url:self.API_Service_TASK_MGMNT()+"work-items/all/Provider Approval?"+"perPage="+self.itemsPerPage+"&pageNo="+pageNum,            
                                    
                                    headers: {
                                        "Content-Type": "application/json", 
                                        "x-access-token": self.token,
                                    },
                                    
                                })
                                .then(function(response) {
                                                
                                let apiData = response.data.data.list

                                console.log("API RESPONSE for ALL Provider registration APP:::::",apiData);
                                self.pageLength = response.data.data.total_pages;
                                self.totalLen = response.data.data.total
                                var temp =  self.page*10;
                                self.fval = temp-9;
                                self.sval = temp;

                                self.GetData = []
                                self.GetData = apiData.map(data=>{
                                                                return { 
                                                                        assignToId:data._id,
                                                                        workItemId:data.itemId,
                                                                        assignToName:data.assignToName ,
                                                                        ...(data.hasOwnProperty('createdAt') && {newdate:moment(data.createdAt, moment.ISO_8601).format("DD/MM/YYYY hh:mm:ss").substr(0,10)}),
                                                                        ...(data.hasOwnProperty('dueDate') && {newduedate:moment(data.dueDate, moment.ISO_8601).format("DD/MM/YYYY hh:mm:ss").substr(0,10)}),
                                                                        name:data.name,
                                                                        all:data,


                                                                        // newdate: data.createdAt.split('T')[0],
                                                                        // newduedate:data.dueDate.split('T')[0], 

                                                                    }

                                    })
			
                }).catch(function(error) {
                            console.log(error);
                            self.showToast(error.response.data.msg,self.TOST().ERROR);
                        });

                        
            },

        onClickItem( item, radioValue) {
            console.log(radioValue);
            console.log(item);
            
            console.log(item.all._id);
            if(radioValue === "Yes"){
                let id = item.all._id;
                let self = this;    
                axios({
                        method: "put",
                        url:self.API_Service_TASK_MGMNT()+"work-items/assign/"+id,      
                        headers: {
                                "Content-Type": "application/json", 
                                "x-access-token": self.token,
                            },        
                        }).then(function(response) {
                            console.log(response);
                            let data = response.data.data;
                            self.getProviderApp(1);
                           self.page = 1

                         }) .catch(function(error) {
                                console.log(error);
                    });             
                }else{
                    return
                }

            console.log(this.assignedUser);
        }
},

watch: {
    
},
    
    
}

</script>
<style >
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
    font-size: 0.84rem !important;
}

.assignWidth{
    width: 300px;  
}

.assignedName{
    color: #1E9690; 
    font-weight: bold;
}

.assignedNameclear{
    cursor: pointer;
}

.backColor{
    background-color: #f7f7f7;
    
}

.hdr-txt-clr{
    color:#10242B !important;
    /* padding:0px!important;
    padding: 10px !important;
    padding-left: 14px !important; */
}

.hdr-bg-clr{
    background-color: #f7f7f7;
}
.cust_pr .v-text-field .v-input__slot,
.v-text-field--outline .v-input__slot {
    
    min-height: 38px !important;
    display: flex !important;
    align-items: center !important;
    /* border:1px red solid; */
}

.pending-work{
    justify-items: start;
}

.lay{
        direction: rtl;
        max-height: 50px;

}
.body-2{
        font-size:18px !important;

}
.body-3{
        font-size:16px !important;

}



.cstm-clr-pr{
    color:#10242B;
    margin-bottom: 5px;
}
.cust_pr .v-input{
    font-size: 13px !important;
}
.cust_pr .v-input input {
    font-size: 13px !important;
}

.cust_pr .v-input .v-label {
    font-size: 13px !important;
}


 .cust-bnkAcc-pgn .v-pagination__navigation{
        box-shadow:unset !important;
        border-radius: unset !important;
        background-color:#FFFFFF !important;
        border:1px solid #DAE1E6 !important
    }
    .cust-bnkAcc-pgn .v-pagination__navigation--disabled{
        background-color:#DAE1E6 !important;
    }
    .cust-bnkAcc-pgn .v-pagination__item{
        box-shadow:unset !important;
        min-width:2rem !important;
        height:2rem !important;
        border-radius: unset !important;
        border:1px solid #DAE1E6 !important
    }   
</style>