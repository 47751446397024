<template>
    <div>
     <v-container fluid>
       <v-layout row>
         <v-flex md3 sm3 xs6>
           <v-layout column class="cust_usrsrch mx-2 mt-2">
             <p class="font-weight-bold cstm-clr-pln-usrsrch">
                 Name of Corporate
             </p>
             <v-text-field
               solo
               label="Enter..."
               v-model="formData.nameOfCorporate"
               maxlength="20"
               counter="20"
               class="rounded-0"
               aria-required="true"
               disabled
             >
             </v-text-field>
           </v-layout>
         </v-flex>
         <v-flex md3 sm3 xs6>
           <v-layout column class="cust_usrsrch mx-2 mt-2">
             <p class="font-weight-bold cstm-clr-pln-usrsrch">
                Employee ID
             </p>
             <v-text-field
               solo
               label="Enter..."
               v-model="formData.employeeId"
               maxlength="20"
               counter="20"
               class="rounded-0"
               aria-required="true"
               disabled
             >
             </v-text-field>
           </v-layout>
         </v-flex>
         <v-flex md3 sm3 xs6>
           <v-layout column class="cust_usrsrch mx-2 mt-2">
             <p class="font-weight-bold cstm-clr-pln-usrsrch">
                Member Id
             </p>
             <v-text-field
               solo
               label="Enter..."
               v-model="formData.memberId"
               maxlength="20"
               counter="20"
               class="rounded-0"
               aria-required="true"
               disabled
             >
             </v-text-field>
           </v-layout>
         </v-flex>
         <v-flex md3 sm3 xs6>
           <v-layout column class="cust_usrsrch mx-2 mt-2">
             <p class="font-weight-bold cstm-clr-pln-usrsrch">
                Member Inception Date Tata Aig
             </p>
             <v-text-field
               solo
               label="Enter..."
               v-model="formData.memberInspectionDateTataAig"
               maxlength="20"
               counter="20"
               class="rounded-0"
               aria-required="true"
               disabled
             >
             </v-text-field>
           </v-layout>
         </v-flex>
         <v-flex md3 sm3 xs6>
           <v-layout column class="cust_usrsrch mx-2 mt-2">
             <p class="font-weight-bold cstm-clr-pln-usrsrch">
                Member Name
             </p>
             <v-text-field
               solo
               label="Enter..."
               v-model="formData.memberName"
               maxlength="20"
               counter="20"
               class="rounded-0"
               aria-required="true"
               disabled
             >
             </v-text-field>
           </v-layout>
         </v-flex>
         <v-flex md3 sm3 xs6>
           <v-layout column class="cust_usrsrch mx-2 mt-2">
             <p class="font-weight-bold cstm-clr-pln-usrsrch">
                Member PAN Number
             </p>
             <v-text-field
               solo
               label="Enter..."
               v-model="formData.memberPanNumber"
               maxlength="20"
               counter="20"
               class="rounded-0"
               aria-required="true"
               disabled
             >
             </v-text-field>
           </v-layout>
         </v-flex>
         <v-flex md3 sm3 xs6>
           <v-layout column class="cust_usrsrch mx-2 mt-2">
             <p class="font-weight-bold cstm-clr-pln-usrsrch">
                Member Aadhaar Number
             </p>
             <v-text-field
               solo
               label="Enter..."
               v-model="formData.memberAadharNumber"
               maxlength="20"
               counter="20"
               class="rounded-0"
               aria-required="true"
               disabled
             >
             </v-text-field>
           </v-layout>
         </v-flex>
         <v-flex md3 sm3 xs6>
           <v-layout column class="cust_usrsrch mx-2 mt-2">
             <p class="font-weight-bold cstm-clr-pln-usrsrch">
                Member Date of Birth
             </p>
             <v-text-field
               solo
               label="Enter..."
               v-model="formData.memberDateOfBirth"
               maxlength="20"
               counter="20"
               class="rounded-0"
               aria-required="true"
               disabled
             >
             </v-text-field>
           </v-layout>
         </v-flex>
         <v-flex md3 sm3 xs6>
           <v-layout column class="cust_usrsrch mx-2 mt-2">
             <p class="font-weight-bold cstm-clr-pln-usrsrch">
                Member Age
             </p>
             <v-text-field
               solo
               label="Enter..."
               v-model="formData.memberAge"
               maxlength="20"
               counter="20"
               class="rounded-0"
               aria-required="true"
               disabled
             >
             </v-text-field>
           </v-layout>
         </v-flex>
         <v-flex md3 sm3 xs6>
           <v-layout column class="cust_usrsrch mx-2 mt-2">
             <p class="font-weight-bold cstm-clr-pln-usrsrch">
                Member Gender
             </p>
             <v-text-field
               solo
               label="Enter..."
               v-model="formData.memberGender"
               maxlength="20"
               counter="20"
               class="rounded-0"
               aria-required="true"
               disabled
             >
             </v-text-field>
           </v-layout>
         </v-flex>
         <v-flex md3 sm3 xs6>
           <v-layout column class="cust_usrsrch mx-2 mt-2">
             <p class="font-weight-bold cstm-clr-pln-usrsrch">
                Relationship to Primary Insured
             </p>
             <v-text-field
               solo
               label="Enter..."
               v-model="formData.relationToPrimaryInsured"
               maxlength="20"
               counter="20"
               class="rounded-0"
               aria-required="true"
               disabled
             >
             </v-text-field>
           </v-layout>
         </v-flex>
         <v-flex md3 sm3 xs6>
           <v-layout column class="cust_usrsrch mx-2 mt-2">
             <p class="font-weight-bold cstm-clr-pln-usrsrch">
                Member Occupation
             </p>
             <v-text-field
               solo
               label="Enter..."
               v-model="formData.memberOccupation"
               maxlength="20"
               counter="20"
               class="rounded-0"
               aria-required="true"
               disabled
             >
             </v-text-field>
           </v-layout>
         </v-flex>
         <v-flex md3 sm3 xs6>
           <v-layout column class="cust_usrsrch mx-2 mt-2">
             <p class="font-weight-bold cstm-clr-pln-usrsrch">
                Member Joining Date
             </p>
             <v-text-field
               solo
               label="Enter..."
               v-model="formData.memberJoiningDate"
               maxlength="20"
               counter="20"
               class="rounded-0"
               aria-required="true"
               disabled
             >
             </v-text-field>
           </v-layout>
         </v-flex>
         <v-flex md3 sm3 xs6>
           <v-layout column class="cust_usrsrch mx-2 mt-2">
             <p class="font-weight-bold cstm-clr-pln-usrsrch">
                Member Leaving Date
             </p>
             <v-text-field
               solo
               label="Enter..."
               v-model="formData.memberLeavingDate"
               maxlength="20"
               counter="20"
               class="rounded-0"
               aria-required="true"
               disabled
             >
             </v-text-field>
           </v-layout>
         </v-flex>
         <v-flex md3 sm3 xs6>
           <v-layout column class="cust_usrsrch mx-2 mt-2">
             <p class="font-weight-bold cstm-clr-pln-usrsrch">
                Tata AIG Employee
             </p>
             <v-text-field
               solo
               label="Enter..."
               v-model="formData.tataAigEmployee"
               maxlength="20"
               counter="20"
               class="rounded-0"
               aria-required="true"
               disabled
             >
             </v-text-field>
           </v-layout>
         </v-flex>
       </v-layout>
     </v-container>
   </div>
  </template>
  <script>
  export default {
     data() {
         return {
             formData: {
                 memberInspectionDatePortability: "",
                 nameOfCorporate: "",
                 employeeId: "",
                 memberId: "",
                 memberInspectionDateTataAig: "",
                 memberStatus: "",
                 memberName: "",
                 memberPanNumber: "",
                 memberAadharNumber: "",
                 memberDateOfBirth: "",
                 memberAge: "",
                 memberGender: "",
                 relationToPrimaryInsured: "",
                 memberOccupation: "",
                 memberJoiningDate: "",
                 memberLeavingDate: "",
                 tataAigEmployee: ""
             }
         }
     },
     props: ["claimDetails"],
     created() {
    },
    mounted() {
      this.setMemberDetails();
     },
     methods: {
      async setMemberDetails() {
        try {
          this.formData.memberName = this.claimDetails?.claimsDetail?.memberName;
          this.formData.nameOfCorporate = this.claimDetails?.claimsDetail?.nameOfCorporate;
          this.formData.memberId = this.claimDetails?.claimsDetail?.memberId;
        } catch(e) {
          this.showToast(e.response?.data?.msg || e.response?.data  || e.response || e, this.TOST().ERROR);
        }
      }
     }
  }
  </script>