<template>
    <div class="pa-5">
        <v-form ref="aigref" @submit.prevent="searchAIGMaster">
            <v-row no-gutters>
                <v-col class="ml-3 mr-3">
                    <p class="claimlabel mb-2">Name of AIG Office </p>
                    <v-text-field
                        placeholder="Enter"
                        v-model="aigSearchObj.nameOfAigOffice"
                        solo
                        dense
                        class="forminput"
                        clearable
                    ></v-text-field>
                </v-col>
                <v-col class="mr-3">
                    <p class="claimlabel mb-2">Country </p>
                    <v-autocomplete
                        label="Select" 
                        v-model="aigSearchObj.countryName"
                        :items="countryList"
                        item-text="countryName"
                        class="forminput"    
                        solo 
                        dense 
                        chips
                        clearable
                        deletable-chips
                        multiple
                        small-chips
                    ></v-autocomplete>
                </v-col>
                <v-col class="mr-3">
                    <p class="claimlabel mb-2">Payee Bank Name </p>
                    <v-text-field
                        placeholder="Enter"
                        v-model="aigSearchObj.payeeBankName"
                        solo
                        dense
                        class="forminput"
                        clearable
                    ></v-text-field>
                </v-col>
                <v-col class="mr-3">
                    <!-- <p class="claimlabel mb-2">Master Entry Status </p>
                    <v-select
                        label="Select"
                        v-model="aigSearchObj.approveStatus"
                        :items="masterStatusOption"
                        solo
                        dense
                        class="forminput"
                        clearable
                    ></v-select> -->
                </v-col>
                <v-col class="mr-3"></v-col>
            </v-row>
            <v-row>
                <v-col>
                    <span>AIG Master List</span>
                    <span class="orangetext ml-1 mr-2">({{ this.totalListCount }})</span>
                    <v-btn color="#23B1A9" dark class="btnstyle mr-3" @click="resetFilter" v-if="totalListCount > 0">Filter
                        <v-icon class="ml-3 iconsize">mdi-close</v-icon>
                    </v-btn>
                </v-col>
                <v-col align="right">
                    <v-btn color="#23B1A9" dark class="btnstyle mr-3" type="submit">
                        <v-icon class="mr-2 iconsize">mdi-magnify</v-icon>
                        <div class="vertical_line"></div>Search
                    </v-btn>
                    <v-btn outlined color="#D1121B" class="btnstyle mr-3" @click="reset()">
                        <v-icon class="mr-2 iconsize">mdi-close-box-outline</v-icon>
                        <div class="vertical_line"></div>Reset
                    </v-btn>
                    <v-btn color="#152F38" dark class="btnstyle mr-3" @click="goToBackPage()">
                        <v-icon class="mr-2 iconsize">mdi-arrow-left-circle</v-icon>
                        <div class="vertical_line"></div>Back
                    </v-btn>
                </v-col>
            </v-row>
        </v-form>
        <template>
            <v-data-table
                :headers="aigOfficeHeaders" 
                no-data-text="No Records for AIG Master" 
                fixed-header 
                :items="officeTableList" 
                hide-default-footer 
                class="vDataTableStyling tableborder my-3">
                <template  v-slot:[`item.action`]="{ item }">
                    <div class="text-center">
                        <v-menu
                            bottom
                            offset-y
                            rounded="0"
                            nudge-left="0">
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon class="mr-2 menusize" v-bind="attrs" v-on="on">mdi-dots-vertical</v-icon>
                            </template>

                            <v-list>
                                <v-list-item
                                    style="min-height: 28px !important" 
                                    v-for="(item1, index) in menuItems"
                                    :key="index"
                                    dense
                                    :color="item1.color"
                                    class="ma-0 pa-0"
                                    @click="modify(item, index)">
                                    <v-icon small class="pa-2 pt-2 iconsize" v-text="item1.icon" :style="item1.color"></v-icon>
                                    <v-divider vertical class="mx-1"></v-divider>
                                    <v-list-item-title class="pa-1 pr-3 ma-0 mr-0" v-text="item1.title" :style="item1.color" :class="item1.name" style="font-size: 12px !important"></v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </div>
                </template>
            </v-data-table>
        </template>
        <!-- pagination -->
        <div class="text-center graybackground pdct-stUp-bnkAcc-pgn mt-3 mx-4" v-if="officeTableList.length > 0">
            <v-layout justify-end>
                    <div style="margin-top: 15px">
                    <span class="orangetext paginationText">
                        Showing {{ totalListCount ? pageStartIndex + 1 : 0 }} - {{ pageEndIndex }}
                    </span>
                    <span class=" mr-2 paginationText"> out of {{ totalListCount }} records </span>
                    </div>
                    <!-- @input="goNextPagePagination" -->
                    <v-pagination
                        v-model="pageNumber"
                        :length="totalPages"
                        :total-visible="pageRowCount" 
                        @input="goToNextPage"
                        color="#152F38"
                        class="navpagenumber pt-1"
                    ></v-pagination>
            </v-layout>
            <br />
        </div>
        <v-row>
            <v-dialog v-model="reject_Dialog" width="600">
                <v-card>
                    <v-card-title class="white--text" style="background-color: #23b1a9"
                    ><v-layout style="display:flex;justify-content:center;margin-left: 170px;" >Reject
                       </v-layout>
                       <v-layout style="display:flex;justify-content:end;">
                        <v-icon class="white--text " @click="reject_Dialog=false">mdi-close</v-icon>
                      </v-layout>
                      </v-card-title>
                      <v-form ref="rejectForm" @submit="false">
                        <v-layout class="pt-4 form-group-container" >
                          
                            <v-flex class="mr-2">
                                <label class="form-label">Reason <span><strong class="red--text">*</strong></span></label>
                                <v-text-field  class="form-control" solo dense :rules="reject_Dialog ? [(v)=>requiredField(v)]:[]" v-model="rejectionReason" placeholder="Enter..." clearable></v-text-field>
                            </v-flex>
                          
                        </v-layout>
                      </v-form>
                        <v-card-actions style="display:flex;justify-content:center">
                          <v-btn class="btn" color="red white--text mr-0" @click="reject_Dialog=false">
                            No
                        </v-btn>

                        <v-btn class="btn" color="green white--text mr-0" @click="rejectAig">
                            Yes
                        </v-btn>
                      </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
        <!-- Audit Trail  -->
        <v-dialog v-model="audit_dialog" width="800">
            <v-card>
                <v-card-title
                    class="white--text"
                    style="background-color: #23b1a9"
                >   Audit Trail
                <v-spacer></v-spacer>
                    <v-icon @click="audit_dialog=false" class="white--text">mdi-window-close</v-icon>
                </v-card-title>
                <v-layout class="pt-4 form-group-container">
                    <v-data-table
                    :headers="audit_Header"
                    hide-default-footer
                    :items="audit_Data"
                    class=" vDataTableStyling"
                    >
                    <template v-slot:[`item.srno`]="{ index }">
                        {{ 1 + index }}
                        </template>
                        <template v-slot:[`item.action`]="{ item }">
                        <v-btn v-if="item.action === 'Modified'" color="#FFFFFF" class="text-capitalize tablebtn" @click="seeModify(item)">
                            {{ item.action }}
                        <v-icon class="tableicons">mdi-arrow-right-circle</v-icon> </v-btn
                        >
                        {{ (item.action !== "Modified") ? item.action : null }}
                        </template>
                        <template v-slot:[`item.createdAt`]="{ item }">
                        {{dateFormat(item.createdAt)}}
                        </template>
                    </v-data-table>
                    </v-layout>
                    <v-layout justify-end align-center class="pb-2 px-0 mr-4">
                        <div>
                        <span style="color:orange">Showing {{  totalListCount ? pageStartIndex1 + 1 : 0 }}-{{ pageEndIndex1 }}</span>
                        out of {{ totalListCount1 }} records
                        </div>
                        <div>
                        <v-pagination class="small-pagination" v-model="page" :length="pageCount" :total-visible="5" @input="getAuditData(page,'',true)" color="#152F38"></v-pagination>
                        </div>
                    </v-layout>
            </v-card>
        </v-dialog>
        <!-- Modified data in AUdit  -->
        <v-dialog v-model="seeModifyDialog" width="600">
            <v-card>
                <v-card-title class="white--text"
                            style="background-color: #23b1a9">
                    <v-layout justify-space-between>
                    <div>
                        Modified Field Details
                    </div>
                    </v-layout>
                    <v-btn color="transparent" small :ripple="false" depressed @click="seeModifyDialog = false">
                    <v-icon color="white">mdi-close </v-icon>
                    </v-btn>
                </v-card-title>
                <div class="scrollable-table">
                    <v-data-table disable-pagination :headers="modifyHeaders" 
                    :items="modifyItems" hide-default-footer class="pa-4 vDataTableStyling">
                    <template v-slot:[`item.srNo`]="{ index }">
                            {{ 1 + index }}
                            </template>
                            
                            <template v-slot:[`item.oldValue`]="{item}">
                                {{item.fieldName=='License Expiry Date'?formatDate(item.oldValue):item.oldValue}}
                            </template>
                            <template v-slot:[`item.newValue`]="{item}">
                                {{item.fieldName=='License Expiry Date'?formatDate(item.newValue):item.newValue}}
                            </template>
                            
                    </v-data-table>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import moment from 'moment';
    export default {
        data() {
            return {
                aigSearchObj: {
                    nameOfAigOffice: '',
                    countryName: [],
                    payeeBankName: '',
                    approveStatus: '',
                },
                countryList: [],
                masterStatusOption: ['Approved', 'Rejected', 'Pending for approval'],
                officeTableList: [],
                aigOfficeHeaders: [
                    {text: 'Action',align: 'center',value: 'action',sortable: false},
                    {text: 'AIG Code',align: 'center',value: 'operationData[0].aigCode',sortable: false},
                    {text: 'Name of AIG Office',align: 'center',value: 'operationData[0].nameOfAigOffice',sortable: false},
                    // {text: 'Country',align: 'center',value: 'countryName',sortable: false},
                    {text: 'Approval Status',align: 'center',value: 'approveStatus',sortable: false},
                    // {text: 'TRC Validity Date',align: 'center',value: 'startDate',sortable: false},
                    // {text: 'Payee Bank Name',align: 'center',value: 'endDate',sortable: false},
                ],
                menuItems: [
                    {
                        title: "VIEW",
                        icon: "mdi-eye-outline",
                        color: "color:#23b1a9",
                        iconColor: "color:#10242B",
                    },
                    {
                        title: "Approve",
                        icon: "mdi-check-circle-outline",
                        color: "color:#23b1a9",
                        iconColor: "color:green",
                    },
                    {
                        title: "Reject",
                        icon: "mdi-cancel",
                        color: "color:#23b1a9",
                        iconColor: "color:red",
                    },
                    {
                        title: "AUDIT TRAIL",
                        icon: "mdi-chart-timeline-variant",
                        // link: "/modifypage",
                        color: "color:orange",
                        iconColor: "color:orange",
                    },
                ],
                pageNumber: 1,
                pageStartIndex: 0,
                pageRowCount: 10,
                totalListCount: 0,
                totalPages:null,
                reject_Dialog: false,
                rejectItem: {},
                rejectionReason: '',
                // Audit Trail Data 
                audit_Data:[],
                modifyItems: [],
                audit_Header:[
                    {text:"Sr. No.",value:"srno",sortable:false,align:"center"},
                    {text:"Master ID",value:"masterId",sortable:false,align:"center"},
                    {text:"Action Taken",value:"action",sortable:false,align:"center"},
                    {text:"User ID",value:"userId",sortable:false,align:"center"},
                    {text:"User Name",value:"userName",sortable:false,align:"center"},
                    {text:"Time Stamp",value:"createdAt",sortable:false,align:"center"}
                ],
                modifyHeaders: [
                    {
                        text: "Sr. No.",
                        value: "srNo",
                        align: "center",
                        sortable: false,
                    },
                    {
                        text: "Field Name",
                        value: "fieldName",
                        align: "center",
                        sortable: false,
                    },
                    {
                        text: "Old Value",
                        value: "oldValue",
                        align: "center",
                        sortable: false,
                    },
                    {
                        text: "New Value",
                        value: "newValue",
                        align: "center",
                        sortable: false,
                    },
                ],
                audit_dialog: false,
                seeModifyDialog:false,
                itemsPerPage: 10,
                totalListCount1: 0,
                pageStartIndex1: 0,
                pageCount: 0,
                page: 1,
            }
        },

        created() {
            this.$store.commit("SAVE_PAGE_HEADER", "AIG Master Search");
            this.getCountryList();
            // this.searchAIGMaster();
        },

        computed: {
            pageEndIndex() {
                return this.pageStartIndex + this.pageRowCount <= this.totalListCount? this.pageStartIndex + this.pageRowCount: this.totalListCount;           
            },
            pageEndIndex1() {
                return this.pageStartIndex1 + this.itemsPerPage <= this.totalListCount1 ? this.pageStartIndex1 + this.itemsPerPage : this.totalListCount1;
            },
        },

        methods: {
            formatDate(date) {
                return date == null ? "" : moment(date).format('DD/MM/YYYY');
            },

            searchAIGMaster() {
                // if(!this.aigSearchObj.nameOfAigOffice && !this.aigSearchObj.countryName.length){
                //     this.showAlert("Please enter atleast one field.")
                // }else{
                    this.goToNextPage(1)
                // }   
            },

            goToNextPage(newPageNum){
                // if(!this.aigSearchObj.nameOfAigOffice && !this.aigSearchObj.countryName.length){
                //     this.showAlert("Please enter atleast one field.")
                // }else{
                    let reqData =   "perPage="+this.pageRowCount+"&pageNo="+newPageNum + '&modelName=aig' + "&approveStatus=Pending for approval";
                    if(this.aigSearchObj.nameOfAigOffice){
                        reqData =   reqData +   "&nameOfAigOffice=" + this.aigSearchObj.nameOfAigOffice;
                    }
                    if(this.aigSearchObj.countryName.length){
                        reqData =   reqData +   "&countryName=" + JSON.stringify(this.aigSearchObj.countryName);
                    }
                    // if(this.aigSearchObj.approveStatus){
                    //     reqData =   reqData +   "&approveStatus=" + this.aigSearchObj.approveStatus;
                    // }
                    if(this.aigSearchObj.payeeBankName){
                        reqData =   reqData +   "&payeeBankName=" + this.aigSearchObj.payeeBankName;
                    }
                    this.showProgress();
                    var self    =   this;
                    this.GETM("searchFrequentApprove",reqData,(res)=>{
                        this.hideProgress();
                        if(res.data && res.data.data && res.data.data.data && res.data.data.data.length >0){
                            self.officeTableList  =   res.data.data.data;
                            this.pageRowCount = 10;
                            this.totalListCount = res.data.data.total;
                            this.totalPages = Math.ceil(res.data.data.total / this.pageRowCount);
                            this.pageStartIndex = this.pageNumber == 1 ? 0 : (this.pageNumber - 1) * this.pageRowCount;
                        }else{
                            this.pageRowCount = 0;
                            this.totalListCount = 0;
                            this.totalPages = 0;
                            this.pageStartIndex =0;
                            this.officeTableList = [];
                            self.showAlert("No data.")
                        }
                    },(err)=>{
                        this.hideProgress();
                        console.log("SEARCH_AIG_OFFICE err - ",+err.response.data.msg);                    
                    }, true);
                // }

            },

            modify(item, index) {
                if(index === 0) {
                    this.$router.push({
                        name: 'CreateAIGMaster',
                        params: {
                            aig_Data:item.operationData[0],
                            isView:true,
                            isApproval: true,
                            _id: item._id
                        }
                    })
                }
                if(index === 1) {
                    this.showConfirm("Confirmation", `Are you sure you want to approve?`, "Yes", "No", () => {
                        let id = item._id;
                        this.approveAig(id, item);
                    }, () => {})
                }
                if(index === 2){
                    this.reject_Dialog = true;
                    this.rejectItem = item;
                }
                if(index === 3){
                    this.audit_dialog=true
                    this.getAuditData(this.page, item.operationData[0].uid,false)
                }
            },
            rejectAig() {
                let reqData = {
                    approveStatus: 'Rejected',
                    rejectionReason: this.rejectionReason,
                }
                this.rejectUpdateHandler(this.rejectItem._id, reqData);
            },

            approveAig(id, item) {
                let reqData = {
                    approveStatus: 'Approved',
                    payeeName: item.operationData[0].payeeName,
                    nameOfAigOffice: item.operationData[0].nameOfAigOffice,
                    uid: item.operationData[0].uid,
                }
                this.rejectUpdateHandler(id, reqData);
            },

            rejectUpdateHandler(id, reqData) {
                this.PUTM("updateMasterApprove", id, reqData, (res) => {
                    if(res?.data?.statusCode == 200) {
                        this.showAlert(res.data.msg);
                        this.rejectionReason = '';
                        this.reject_Dialog = false;
                        this.searchAIGMaster();
                    } else {
                        this.showAlert(res.data.msg);
                    }
                }, (err) => {
                    console.log(err);
                }, true);
            },

            getAuditData(newPageNum,id,bool){
                let reqData =""
                if(bool){
                    reqData = "perPage=10" + "&pageNo=" + newPageNum + "&masterType=aig"+"&masterId=" +this.audit_Data[0].uid
                }else{
                    reqData = "perPage=10" + "&pageNo=" + newPageNum + "&masterType=aig"+"&masterId=" +id
                }
                //"&masterId=" + this.auditTrailItem.uid + 
                this.GETM('getAudits',reqData,(res)=>{
                this.audit_Data=res.data.data.data
                this.totalListCount1 = res.data.data.total;
                    this.pageCount = res.data.data.total_pages;
                    this.pageStartIndex1 = newPageNum == 1 ? 0 : (newPageNum - 1) * this.itemsPerPage;
                }, () => {}, true)
            },

            seeModify(item) {
                this.seeModifyDialog = true;
                this.modifyItems = item.modification;
            },

            goToBackPage() {
                this.showConfirm("Confirmation", `Are you sure you want to leave?`, "Yes", "No", () => {
                    this.$router.push({
                        name: 'MasterApproval'
                    });
                }, () => {})
            },

            reset() {
                this.showConfirm("Confirmation", `Are you sure you want to reset?`, "Yes", "No", () => {
                    this.aigSearchObj = {
                        nameOfAigOffice: '',
                        countryName: '',
                        payeeBankName: '',
                        approveStatus: '',
                    }
                    this.officeTableList = [];
                    // this.searchAIGMaster();
                }, () => {})
            },

            resetFilter() {
                this.officeTableList = [];
                this.totalListCount = 0;
            },

            getCountryList() {
                this.showProgress();
                this.GETM("COUNTRY_LIST",'',(res)=>{
                    this.hideProgress();
                    let data    =   res.data.data.data;
                    this.countryList    =   data;
                },(err)=>{
                    console.log(err);
                    this.hideProgress();
                })
            },
        }
    }
</script>

<style scoped>
    .iconsize {
        font-size: 16px !important;
        font-weight: bold;
    }
    .btnstyle{
        height: 28px !important;
        text-transform: capitalize;
        font-size: 11px !important;
        letter-spacing: 0.5px;
    }
    .orangetext {
        color: #e46a25;
    }
</style>