<template>
    <v-app>
        <v-container
      class="containerStyle"
      style="padding:1.5rem; margin:0; max-width:100%"
    >
<h3>Open File Reviews</h3>
<!-- <v-btn-toggle  class="mt-5" v-model="ofr_btnList" multiple>
    
    <v-btn solo outlined value="casesunderOFR" 
    :class="[caseUnderOfr == true ? 'activebg' : 'inactivebg' ]" 
    @click="caseUnderOfr =!caseUnderOfr;callingauxiliarapi()">Cases Under OFR</v-btn>

    OFR Completed Cases
    <v-btn class="ml-5" 
    value="Ofrcompletedcases"
    solo 
    :class="[ofrCompletedCase == true ? 'active' : 'inactivebg' ]"
    @click="ofrCompletedCase=!ofrCompletedCase ; callingauxiliarapi()"
     
    >OFR Completed Cases</v-btn>
</v-btn-toggle> -->
<v-layout class="mt-5">
    <v-btn solo outlined 
    :class="[caseUnderOfr == true ? 'activebg' : 'inactivebg' ]" 
    style="padding:25px;width:200px;border-radius:none" 
    class="createbtn px-2" 
    @click="caseUnderOfr =!caseUnderOfr;ofrCompletedCase=!ofrCompletedCase;callingauxiliarapi()">Cases Under OFR</v-btn>
    
    
    <v-btn solo outlined 
    :class="[ofrCompletedCase == true ? 'active' : 'inactivebg' ]" 
    style="padding:25px;width:200px;border-radius;:none" 
    class="createbtn ml-5" @click="ofrCompletedCase=!ofrCompletedCase;caseUnderOfr =!caseUnderOfr ; callingauxiliarapi()">OFR Completed Cases</v-btn>
</v-layout>



<v-layout class="mt-5">
    <h4 >Open File Review List <span class="orange--text">({{count}})</span></h4>
    <v-spacer></v-spacer>
    <v-btn color="#23b1a9" class="createbtn white--text " solo dense @click="dialog_search=!dialog_search">
        <v-icon >mdi-magnify</v-icon>
        <v-divider></v-divider>
        Search
    </v-btn>
    <v-btn class="mx-2 createbtn white--text" color="#e46a25" solo dense>
        <v-icon>mdi-cloud-download</v-icon>
        <v-divider vertical class="mx-2 white--text"></v-divider>
        Download
    </v-btn>
    <v-dialog v-model="dialog_search" width="1000">
    <v-card>
                  <v-card-title
                    class="white--text"
                    style="background-color: #23b1a9"
                  >
        <h3 class="ml-5">Open File Review Search</h3>
        <v-spacer></v-spacer>
        <v-icon @click="dialog_search=!dialog_search" class="white--text">mdi-window-close</v-icon>
    </v-card-title>
    <v-card-text class=" grey lighten-2">
        <v-layout wrap class="pt-4 form-group-container">
            <v-flex class="form-groups">
                <v-label class="lableFontSize">
                    Claim No
                </v-label>
                <v-text-field
                    label="Enter"
                    solo
                    dense 
                    v-model="claimNumber"         
                    class="forminput"
                     maxlength="50"
                     counter="50"
                  ></v-text-field>
            </v-flex>
            <v-flex class="form-groups">
                <v-label class="lableFontSize">
                    Feature No.
                </v-label>
                <v-text-field
                    label="Enter"
                    solo
                    dense
                    v-model="featureNumber"          
                    class="forminput"
                     maxlength="50"
                    
                  ></v-text-field>
            </v-flex>
            <v-flex class="form-groups">
                <v-label class="lableFontSize">
                    Claimant Name
                </v-label>
                <v-text-field
                    label="Enter"
                    solo
                    dense 
                    v-model="claimantName"         
                    class="forminput"
                     maxlength="50"
                     
                  ></v-text-field>
            </v-flex>
            <v-flex class="form-groups">
                <v-label class="lableFontSize">
                    Insured Name
                </v-label>
                <v-text-field
                    label="Enter"
                    solo
                    dense 
                    v-model="insuredName"         
                    class="forminput"
                     maxlength="50"
                    
                  ></v-text-field>
            </v-flex>
        </v-layout>

        <v-layout  class="form-group-container">
            <v-flex class="form-groups">
                <v-label class="lableFontSize">
                    OFR status
                </v-label>
                <v-text-field
                    label="Enter"
                    solo
                    dense          
                    class="forminput"
                     maxlength="50"
                     
                  ></v-text-field>
            </v-flex>
            <v-flex class="form-groups">
                <v-label class="lableFontSize">
                    Examiner Name
                </v-label>
                <v-text-field
                    label="Enter"
                    solo
                    dense 
                    v-model="examinerName"         
                    class="forminput"
                     maxlength="50"
                    
                  ></v-text-field>
            </v-flex>
            <v-flex class="form-groups">
                <v-label class="lableFontSize">
                    Date Reported From
                </v-label>
                <v-menu
                    v-model="menu"
                    offset-y
                    nudge-top="20"
                    :close-on-content-click="false"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="computeddateReportedFrom"
                        placeholder="DD/MM/YYYY"
                        
                        dense
                        solo
                        readonly
                        v-on="on"
                        @focus="menu = true"
                        @blur="menu = true"
                        v-bind="attrs"
                        class="forminput"
                        append-icon="mdi-menu-down"
                        prepend-inner-icon="mdi-calendar"
                      >
                      </v-text-field>
                    </template>
                    <v-date-picker
                      no-title
                      :pickerDate.sync="pickerDate"
                      :max=getEndDate
                      v-model="dateReportedFrom"
                      @input="menu = false"
                    >
                    </v-date-picker>
                  </v-menu>
            </v-flex>
            <v-flex class="form-groups">
                <v-label class="lableFontSize">
                    Date Reported To
                </v-label>
                <v-menu
                    v-model="menu1"
                    offset-y
                    nudge-top="20"
                    :close-on-content-click="false"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="computeddateReportedTo"
                        placeholder="DD/MM/YYYY"
                        
                        dense
                        solo
                        readonly
                        v-on="on"
                        @focus="menu1 = true"
                        @blur="menu1 = true"
                        v-bind="attrs"
                        class="forminput"
                        append-icon="mdi-menu-down"
                        prepend-inner-icon="mdi-calendar"
                      >
                      </v-text-field>
                    </template>
                    <v-date-picker
                      no-title
                      :pickerDate1.sync="pickerDate"
                      :max=getEndDate
                      v-model="dateReportedTo"
                      @input="menu1 = false"
                    >
                    </v-date-picker>
                  </v-menu>
            </v-flex>
        </v-layout>
    </v-card-text>
    <v-card-actions>
        <v-btn class="reset createbtn" style="background:white" dark @click="clear()">Reset</v-btn>
        <v-spacer></v-spacer>
        <v-btn class="createbtn cancelbtn" @click="dialog_search=!dialog_search">Cancel</v-btn>
        <v-btn color="#23b1a9" class="createbtn white--text" @click="searchOfr()">Search</v-btn>
    </v-card-actions>
    </v-card>
</v-dialog>
</v-layout>
    <v-data-table
    :items="ofr_Table"
    hide-default-footer
    :headers="table_headers"
    dense
    class="mt-5 vDataTableStyling">
    <template v-slot:[`item.action`]="{ item }">
                    <a class="tablebtn mr-1" @click="viewOpenFilePage(item)">                            
                        <v-icon class="tableicons">mdi-eye-outline</v-icon><span class="ml-0 viewStyle"> VIEW </span>
                    </a>
                </template>   
                <template v-slot:[`item.Date`]="{ item }">
                    {{item.Date?dateConvert(item.Date):"----"}}
                </template> 
                <template v-slot:[`item.turnAroundTime`]="{item}">
                    {{noOfDays(item.createdAt)+" Day"}}
                </template>           
                <template v-slot:[`item.ofrStatus`]="{ item }">
                    <span :class="checkOfrStatus(item)">{{ item.ofrStatus === true ? "Closed" : 'Open'}}</span> 
                    <!-- <span>
                        {{ item.ofrStatus == true ? "Closed" : 'Open'}}
                    </span> -->
                    
                </template>
    </v-data-table>
    <div   class="pt-2">
                    <v-row class="text-right px-4 align-center">
                      <v-col class="mt-5">
                        <p>
                          <span class="result"
                            >Showing {{ pageStartIndex }} - {{ pageEndIndex }}</span
                          >
                          out of {{ totalListCount }} records
                        </p>
                      </v-col>
                      <v-pagination
                        v-model="page"
                        :length="pageCount"
                        :total-visible="10"
                        @input="searchData"
                        prev-icon="mdi-arrow-left"
                        next-icon="mdi-arrow-right"
                        color="#152F38"
                      >
                      </v-pagination>
                      <!--  -->
                    </v-row>
                  </div>

</v-container>


    </v-app>
</template>
<script>
    import moment from 'moment';
export default{
data(){
    return{
        pageCount:0,
        totalListCount: 0,
      pageStartIndex: 0,
      pageEndIndex:0,
      page: 1,
      itemsPerPage: 10,
        pickerDate:null,
        pickerDate1:null,
        menu:false,
        menu1:false,
        dateReportedTo:"",
        dateReportedFrom:"",
        claimNumber:"",
        featureNumber:"",
        claimantName:"",
        insuredName:"",
        examinerName:"",
        ofr_btnList:[],
        caseUnderOfr:false,
        ofrCompletedCase:false,
        ofr_Table:[],
        btn2:false,
        count:0,
        dialog_search:false,
        datacaseUnderOfr:[],
        dataofrCompletedCase:[],
        table_headers:[
        {text:"Action", align: "start",width:"100px", sortable: false,value:"action"},
            {text:"Claim No", align: "start",width:"100px", sortable: false,value:"claimNumber"},
            {text:"Feature No", align: "start",width:"100px", sortable: false,value:"featureNumber"},
            {text:"Claimant Name", align: "start",width:"100px", sortable: false,value:"claimantName"},
            {text:"Insured Name", align: "start",width:"200px", sortable: false,value:"insuredName"},
            {text:"Total Indemnity Paid", align: "start",width:"100px", sortable: false,value:"claimedAmount"},
            {text:"Total Expense Paid", align: "start",width:"100px", sortable: false,value:"expensePaid"},
            {text:"Examiner Name", align: "start",width:"100px", sortable: false,value:"examiner.employeeName"},
            {text:"Sum Insured", align: "start",width:"100px", sortable: false,value:"sumInsured"},
            {text:"Outstanding Reserve Amount", align: "start",width:"100px", sortable: false,value:"reserveAmount"},
            {text:"OFR Reviewed Date", align: "start",width:"100px", sortable: false,value:"Date"},
            {text:"Turn Around Time", align: "start",width:"100px", sortable: false,value:"turnAroundTime"},
            {text:"OFR Status", align: "start",width:"100px", sortable: false,value:"ofrStatus"}
        ]
    
    }

},
methods:{
    noOfDays(date) {
        
      const date1 = new Date(date);
      const date2 = new Date();
      
      const diffDays = parseInt((date2 - date1) / (1000 * 60 * 60 * 24), 10);
      return diffDays;
    },
    searchData(data){
        this.pageStartIndex=1
        this.pageEndIndex=data.total
        this.totalListCount=data.total
        this.pageCount=data.total_pages
    },
    formatDate(date) {
      if (!date) return "";

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    dateConvert(a){
        return moment(a).format("DD-MM-YYYY")
    },
    
    checkOfrStatus(item){
             if(item.ofrStatus === true){
                    return "closeClr"
                }else{
                    return "openClr"
                }     
            },
            callingauxiliarapi(){
                if(this.caseUnderOfr || this.ofrCompletedCase){
                    let getData=`ofrStatus=${!this.caseUnderOfr}`
                this.GETM("ofrSearch",getData,(res)=>{
                this.ofr_Table=res.data.data.data
                this.count=res.data.data.total
                this.searchData(res.data.data)
                    },(error) => {
                    return error;
                },true)
                
                }


                
            },
            searchOfr(){
                this.dateReportedFrom=this.dateReportedFrom.length>0?moment(this.dateReportedFrom):""
                this.dateReportedTo=this.dateReportedTo.length>0?moment(this.dateReportedTo):""
                let reqData=`ofrStatus=${!this.caseUnderOfr}&claimNumber=${this.claimNumber}&featureNumber=${this.featureNumber}&insuredName=${this.insuredName}&claimantName=${this.claimantName}&examiner=${this.examinerName}&dateReportedFrom=${this.dateReportedFrom}&dateReportedTo=${this.dateReportedTo}`
                this.GETM("ofrSearch",reqData,(res)=>{
                this.ofr_Table=res.data.data.data
                this.count=res.data.data.total
                this.searchData(res.data.data)
                    },(error) => {
                    return error;
                },true)
                this.dialog_search=false
                this.clear()
            },
            viewOpenFilePage(item){
                this.$router.push({
        name: "CommonOFRView",
        params: {
            isView: item.ofrStatus,
            item: item,
        },
        });
    },
    clear(){
        this.dateReportedTo="",
        this.dateReportedFrom="",
        this.claimNumber="",
        this.featureNumber="",
        this.claimantName="",
        this.insuredName="",
        this.examinerName=""
        const a=new Date().toISOString().split('T')[0]
        this.pickerDate=a
        this.pickerDate1=a
    },
},
computed: {
    computeddateReportedFrom(){
      return this.formatDate(this.dateReportedFrom);
    },
    computeddateReportedTo(){
      return this.formatDate(this.dateReportedTo);
    },
    getEndDate() {
     var endDate = new Date();
     return endDate.toISOString().slice(0,10)
    },
},
created() {
    let getData="ofrStatus=false"
    this.GETM("ofrSearch",getData,(res)=>{
        this.ofr_Table=res.data.data.data
        this.count=res.data.data.total
        this.caseUnderOfr=true
        this.searchData(res.data.data)        
    },(error) => {
        return error;
    },true)
 },
}
</script>
<style scoped>
.active {
  background-color: #1ebbd9 !important;
  color: #fff !important;
}
.createbtn {
        text-transform: capitalize;
        letter-spacing: 0.4px;
        /* font-weight: 300;
        font-size: 13px !important; */
    }
.tableicons {
        font-size: 19px !important;
        font-weight:bold;
        color:rgb(85, 78, 78);
    }
.form-groups{
    max-width:220px;
    margin:0 0 0 10px
}
.openClr{
        color: #E46A25;
        background: none !important;
        font-weight: bold;
    }
    .closeClr{
        color: #0AB417;
        font-weight: bold;
        background: none !important;
    }
.reset {
        background-color: white;
        border:1px solid #D1121B;
        color:#D1121B;
    }
.cancelbtn {
        border: 1px solid #6e7f7f;
        color: #6e7f7f !important;
    }
.activebg {
  color: white ;
  background-color: #01bbd9 !important;
  
}

.inactivebg {
  color: black !important;
  background-color: white !important;
  
}
</style>