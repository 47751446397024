
<template>
    <v-app>
        <v-layout>
                <v-dialog v-model="setdoct.docen" persistent max-width="720px" style="overflow-x: hidden;">
                        <v-card dense tile color="#F7F7F7" class="pb-2 ">

                            <v-card tile>
                                <v-layout justify-space-between  class="" style="flex:1;background-ox-shadow: 0px 3px 5px #00000029;">
                                    <v-layout align-center>
                                        <v-card-title class="pa-0 pl-3 text-capitalize font-weight-bold" style="color:#10242B;font-size:13px">Doctors List - Upload</v-card-title>
                                        <v-divider vertical inset class="mx-2"></v-divider>
                                    </v-layout>
                                    <div style="border-left:1px solid #C1C8CC;" @click="close_Dialog()" >
                                        <v-icon style="cursor: pointer;" class="pa-2" >close</v-icon>
                                    </div>
                                </v-layout>
                            </v-card>

            <v-card class="elevation-2 mx-3 my-3" >
                <v-layout  style="background-color:#f7f7f7; border: solid 1px #f7f7f7; align-items:center;" color="#FFFFFF" filled class="mt-4 p-4 d-flex">
                       
                        <v-flex xs6 class="pa-2" style="display: flex; flex-direction: row; align-items: center; justify-content:flex-start;" >
                            <p class="font-weight-bold cstm-clr-pr-dialog">Upload Excel Copy</p>
                            <!-- style=" width:400px;height: 80px" -->
                            <v-flex  class="rounded-0 elevation-1 xs7 ml-3 pa-3" style="background-color:#fff" >
                                <v-layout style="display: flex; justify-content: center;">
                                    <!-- <v-btn dark style="border: solid 1px dotted #EFEBE9"  
                                    tile color="#EFEBE9" class="my-2 text-capitalize px-3 mx-2" 
                                    small>Drag Image here</v-btn>
                                    <div>
                                        <v-icon dark small>cloud_upload</v-icon> <v-divider vertical class="mx-2"></v-divider> Drag images here
                                    </div> -->
                                    <input type="file"  style="opacity:0; display:none;"
                                        id="imgfiles" multiple name="img_upload"
                                        ref="imgfiles"
                                       
                                        @change="upload_file($event, edited_item)">
                                    <v-btn @click="fileup"  dark  tile color="#E46A25" class="text-capitalize px-3 mx-2 " small><v-icon dark small>cloud_upload</v-icon> <v-divider vertical class="mx-2"></v-divider>Upload</v-btn>
                                </v-layout>
                            </v-flex>
                        </v-flex>
                           <v-flex  class="pa-4" v-if="upload_xls">
                                        <v-layout class="" justify-space-between align-center style="border:0.5px solid #C1C8CC; background-color:#fff; align-items:center;"  color="#FFFFFF" filled >
                                            <div ><img  class="ml-1 mt-1" src="../../assets/dmExcel.png" style=" width: 18px;height: 18px;"/></div>
                                            <div><span class="font-weight-bold"  style="font-size:12px; color:#646666;">{{excelfilename|truncate(16, '...')}}</span></div>
                                            <div style="background-color:#D1121B" class="pa-1">
                                                <v-btn tile class="text-capitalize" style="height:18px;width:18px"  flat icon dark small @click="dmRmvExcel">
                                                    <v-icon small>delete</v-icon>
                                                </v-btn>
                                            </div>
                                        </v-layout>
                           </v-flex>
                </v-layout>
                            
                    <v-layout class="mx-2" style="border: solid 1px #f7f7f7">
                        <p class="font-weight-black ml-2 mt-2" style="font-size:14px" >Doctors List - Upload Result</p>
                    </v-layout>

                    <!-- Table  -->
                    <v-layout  v-if="alldocs.length != 0" class="mx-2" style="border: solid 1px #f7f7f7">
                    <template>
                    <v-simple-table dense >
                        <template v-slot:default>
                        <thead>
                            <tr bgcolor = '#23b1a9'>
                            <th  class="text-left" style="font-size:14px;color:#fff;width: 150px">
                                No. of Enteries
                            </th>
                            <th class="text-left" style="font-size:14px;color:#fff; width: 150px">
                                No. of Enteries Processed
                            </th>
                            <th class="text-left" style="font-size:14px;color:#fff; width: 250px">
                              No. of Enteries Failed
                            </th>
                            <th class="text-left" style="font-size:14px;color:#fff;width: 150px">
                               Failed File Link
                            </th>

                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(doc,index) in alldocs"
                                :key="index">
                            <td>
                                <div class="rowitnow" >
                                    {{ doc.noOfEntries }}
                                    <!-- <img :src="doc.fileUrl" class="imgsizeis" /> -->
                                </div>
                            </td>
                            <td>{{ doc.noOfEntriesProcessed }}</td>
                            <td>{{ doc.noOfEntriesFailed }}</td>
                            <td><v-icon class="custombg ma-1" @click="deletedoc(doc._id,index,doc.fileLink,doc.fileName)" >mdi-download</v-icon></td>
                            </tr>
                        </tbody>
                        </template>
                    </v-simple-table>
                    </template>
                        
                    </v-layout>

                      <v-layout  v-else class="mx-2 rowitnow" style="border: solid 1px #f7f7f7">
                           
                        <p class="font-weight-bold cstm-clr-pr-dialog " style="margin-bottom:0px !important;text-align:center;color:#10242B;font-size:15px !important">
                          No documents found
                        </p> 
                           
                      </v-layout>

                    <!-- Buttons -->
                    <v-layout class="mr-2 mt-4 pb-4 pt-3" row justify-center style="">
                        <v-btn tile  dark color="#152F38" class=" px-3 text-capitalize" small @click="close_Dialog()">
                            <v-icon color="" small >cancel</v-icon>
                            <v-divider vertical class="ml-2 mr-2 text-capitalize"></v-divider>Cancel
                        </v-btn>

                        <v-btn  @click="uploadserver" dark  tile color="#23B1A9" class="text-capitalize px-3 ml-2" type="submit" small>
                            <v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider>
                        Save</v-btn>

                    </v-layout>


                    



                        </v-card>
                        </v-card>   
                    </v-dialog>
         </v-layout>

    </v-app>
</template>






<script>
import moment from 'moment';
import ErrorWarnings from '../../component/ErrorWarnings'

export default {

  data() {

        return {

            upload_xls:false,
            excelfilename:'',
            alldocs:[],
            validmedia_file:null,
            edited_item:null,
            document: [
                {
                    docNumber: 1,
                    fileName: 'Doc1.pdf',
                    documentType: 'Registration Certificate',
                    action: 'mdi-delete'
                }
            ],
            docType: '',
            validityFrom: false,
            validityFromDate:'',
            fromdate:'',
            validityTo: false,
            validityToDate: '',
	
            typeOfDocument:['PAN','Registration Certificate','Tariff','MOU','Photo','Others','De-Empanelment Notice Response'],

            dialogBox: true,
        }
    },

    props:{
        setdoct:{
            default:{docten:false, provider_data:''},
            type:Object
        }
    },

 computed: {
	        computedDateFormattedMomentjs2 () {
            		console.log(this.validityFromDate)
            		console.log(this.validityFromDate ? moment(this.validityFromDate).format("DD-MM-YYYY"): '')
            		return this.validityFromDate ? moment(this.validityFromDate).format("DD-MM-YYYY"): ''
        	},
        	computedDateFormattedMomentjs3 () {
           		 console.log(this.validityToDate)
            		console.log(this.validityToDate ? moment(this.validityToDate).format("DD-MM-YYYY"): '')
            		return this.validityToDate ? moment(this.validityToDate).format("DD-MM-YYYY"): ''
       	    },
},

    created(){

        this.GETDOCTORS_UP_LIST()

    },

    filters:{
      truncate: function (text, length, suffix) {
            if (text.length > length) {
                return text.substring(0, length) + suffix;
            } else {
                return text;
            }
        },
    },

	methods:{

        dmRmvExcel() {
            this.upload_xls = false; 
            this.excelfilename = "";
            this.validmedia_file =null
            this.$refs.imgfiles.value = ""; 

            // this.alwSbmt=false
             
        },

        deletedoc(delete_id, ind, link, docfilename){

               const downloadLink = document.createElement("a");
                const fileName = docfilename;
                downloadLink.href = link;
                downloadLink.download = fileName;
                downloadLink.click();

            return
            // var self = this
            //     this.DELETE(2,'provider/delete-document/'+delete_id, res=>{
            //          this.validmedia_file = null
            //                     console.log("delete docs success  => ", res.data);
            //                     if(res.statusCode == 200){
            //                         self.alldocs.splice(ind, 1);
            //                         self.showToast(res.msg ,self.TOST().SUCCESS);
            //                     }else{
            //                         console.log("when status is not 200", res);
            //                         self.showToast(res.msg ,self.TOST().ERROR); 
            //                     }
            //     })
        },

        sanitizenow(data){
            if(data == null || data == undefined || data == '' || data == 'Invalid date'){
                return ''
            }else{
                return data
            }
        },

        GETDOCTORS_UP_LIST(){
           
               this.GET(2,'provider/get-doctors-upload-history/'+this.setdoct.provider_data._id, res=>{
                                console.log("GETTING UPDATED documents  => ", res.data);
                                      if(res.status == 200){
                                           this.alldocs = []
                                        this.alldocs = res.data.data.data
                                        console.log("all docs is after calling GETDOCTORS_UP_LIST res -> ",  this.alldocs);
                                      }
                                      this.validmedia_file = null

                })
        },

        fileup(){
            //  this.$refs.imgfiles.value = "";
             console.log("imgfiles is ",  this.$refs); 
             console.log("fiek upload called ");
             document.getElementById('imgfiles').click();
        },

        upload_file(event){

                if(event.target.files.length == 0){
                    console.log(this.edited_items,"editm");
                    this.edited_items = null  
                    return 
                }
            
               var compress_start = false;
               var img_compress = ''
               
               var tempimgdata = []
                var files = []
                var flenis = event.target.files.length
                var mynewarr = []
                var finalvalid = []
               
                console.log('updated files ->',  event.target.files[0]);

                if(flenis == 0){
                        console.log('event files length ->', flenis );
                    return
                }else{
                     console.log('proceed as files found ->', flenis );
                }

                // return
                

                for(let i=0; i<flenis;i++){
                    console.log("size is ->", event.target.files[i].size);
                    if(event.target.files[i].size > 5000000){
                         console.log("next, file found to big", i)
                    }else{
                          mynewarr.push(event.target.files[i]);
                    }
                }

                // var regex_VID = new RegExp('^(jpg|jpeg|pdf)$');
                console.log("--->=>> ", mynewarr[0].type.split('/')[1]);
              
                for(let i=0; i<mynewarr.length;i++){

                    // if(mynewarr[i].type.split('/')[1] == 'png'){
                    //     finalvalid.push(mynewarr[i])
                    // }else 
                    if(mynewarr[i].type.split('/')[1] == 'vnd.openxmlformats-officedocument.spreadsheetml.sheet'){
                        finalvalid.push(mynewarr[i])
                    }else{
                        console.log("in-valid file");
                    
                        this.showToast('A non-image or unsupported file was found and was excluded', this.TOST().WARNING);
                        // var posis = i 

                    //   mynewarr.splice(posis, 1) 
                    }
                    // else if(mynewarr[i].type.split('/')[1] == 'jpeg'){
                    //     finalvalid.push(mynewarr[i])
                    // }else if(mynewarr[i].type.split('/')[1] == 'pdf' || mynewarr[i].type.split('/')[1] == 'PDF'){
                    //     finalvalid.push(mynewarr[i])
                    // }
                }

                this.excelfilename = event.target.files[0].name;
                this.upload_xls = true
               this.validmedia_file = finalvalid[0]

            console.log(finalvalid,"<<--- finalvalid | type of media file is ",  this.validmedia_file);

        },

        uploadserver(){

            if(this.validmedia_file == null ){
                    this.showToast('Please, add a file to continue upload', this.TOST().WARNING);
                    return
            }
           
         
            var formData = new FormData();
            formData.append('file',this.validmedia_file);
         
       
            var self = this
                this.POST(2,'provider/upload-doctors/'+this.setdoct.provider_data._id,formData, (res, error)=>{
                        console.log(error,"DOCTORS upload response is => ", res);
                        // if(error.res.statusCode == 4-00){
                        //     console.log("error is in upload document", error)}
                        if(res !== null){
                             
                             self.validmedia_file = null
                             self.showToast(res.data.msg, self.TOST().SUCCESS);
                              self.dmRmvExcel()
                              self.GETDOCTORS_UP_LIST()
                        }else{

                                self.showToast(error.response.data.msg, self.TOST().ERROR);
                                self.validmedia_file = null
                            // if(res.status == 200){    
                            // }else{
                            //     self.showToast(res.msg, self.TOST().ERROR);
                            // }
                             self.dmRmvExcel()
                             self.GETDOCTORS_UP_LIST()
                        }
                       

                      
                })

        },

 		close_Dialog(){
                    // this.setdoct = false;
                    this.$emit('closedoctor');
        },

	 	open_Dialog(){
           		 this.setdoct.docen = true;
       	 	},
	}

}
</script>


<style>

.cust_pr_dialog .v-input .v-label {
    font-size: 13px !important;
}

.custombg{
    background-color: #eceef0;
    border-radius: 55px;
    padding: 4px;
}

.imgsizeis{ width:45px; height:45px; padding:5px; }

.rowitnow{
    display:flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.cust_pr_dialog .v-input{
    font-size: 13px !important;
}

.cust_pr_dialog .v-input input {
    font-size: 13px !important;
}


.cstm-clr-pr-dialog{
    color:#646666 !important;
    margin-bottom: 5px !important;
    font-size: 13px !important;
}


.cust_pd_dialog .v-text-field .v-input__slot,
.v-text-field--outline .v-input__slot {
    min-height: 42px !important;
    display: flex !important;
    align-items: center !important;
    /* border:1px red solid; */
}



.cust-rmvpd-pr-dialog .v-text-field.v-text-field--enclosed>.v-input__control>.v-input__slot {
    padding: 0px !important;
}

</style>