<template>
    <v-container class="containerStyle">
        <v-form id="" ref="searchRepairer" @submit.prevent="submitSearchRepairerForm" class="mt-3">
            <v-layout wrap class="mt-3 form-group-container">
                <v-flex class="form-group">
                    <label class="form-label">LOB</label>
                    <v-select
                v-model="repairerMasterSearchObj.lob"
                label="Select"
                :items="lobOptions"
                
                solo
                dense
                class="form-control"
            ></v-select>
                </v-flex>
                <v-flex class="form-group">
                    <label class="form-label">
                        Repairer Name
                    </label>
                    <v-autocomplete
                        solo
                        dense
                        v-model="repairerMasterSearchObj.repairerName"
                        :rules="[v => matchRegex(v, 'alphanum')]"
                        class="form-control"
                        :search-input.sync="repairerNameSync"
                        @keyup="getRepairerName(repairerNameSync)"
                        :items="repairerNamesData"
                        placeholder="Start typing to Search"
                    ></v-autocomplete>
                </v-flex>

                <v-flex  class="form-group">
                        <label class="form-label">Repairer ID</label>
                        <v-autocomplete
                            placeholder="Enter..."
                            solo
                            dense
                            :items="repairerIdData"
                            :search-input.sync="repairerIDSync"
                            v-model="repairerMasterSearchObj.repairerId"
                            @keyup="getRepairerID(repairerIDSync)"
                            @keydown.space="(event) => event.preventDefault()"
                            :rules="[v => matchRegex(v, 'alphanum')]"
                            class="form-control"
                        ></v-autocomplete>
                </v-flex>

                <v-flex  class="form-group">
                        <label class="form-label">Postal Code</label>
                        <v-autocomplete
                            v-model="repairerMasterSearchObj.postalCode"
                            :search-input.sync="searchPincode"
                            class="form-control"
                            :items="pincodesList"
                            item-text="locpincode"
                            item-value="locpincode"
                            @change="toGetCountryCity(repairerMasterSearchObj.postalCode)"
                            @keyup="getPincode(searchPincode)"
                            placeholder="Start typing to Search"
                            :maxlength="6"
                            solo
                            dense
                            deletable-chips
                            hide-no-data
                            clearable>
                        </v-autocomplete>
                </v-flex>

                <v-flex  class="form-group">
                        <label class="form-label">City</label>
                        <v-autocomplete
                            v-model="repairerMasterSearchObj.city"
                            :search-input.sync="searchCity"
                            class="form-control"
                            :items="cities"
                            item-text="cityDistrictName"
                            item-value="cityDistrictName"
                            placeholder="Start typing to Search"
                            solo
                            @change="clearClaimantcity"
                            dense
                            deletable-chips
                            hide-no-data
                            clearable>
                        </v-autocomplete>
                </v-flex>

                <v-flex  class="form-group">
                        <label class="form-label">Approved Status</label>
                        <v-select
                            label="Select"
                            solo
                            dense
                            :items="approvedStatusOptions"
                            v-model="repairerMasterSearchObj.status"
                            class="form-control"
                        ></v-select>
                </v-flex>

                <!-- <v-flex  class="form-group">
                        <label class="form-label">Country <span class="mandatorycolor">*</span></label>
                        <v-autocomplete
                            v-model="repairerMasterSearchObj.country"
                            :search-input.sync="searchCountry"
                            class="form-control"
                            :items="countries"
                            item-text="countryName"
                            @change="getClaimantStates"
                            placeholder="Start typing to Search"
                            solo
                            dense
                            :rules="rules.mandatoryrule"
                            deletable-chips
                            hide-no-data
                            clearable>
                        </v-autocomplete>
                </v-flex> -->

                <!-- <v-flex  class="form-group">
                    <v-layout column class="ml-3 mr-3">
                    <label class="form-label">State/Province</label>
                        <v-autocomplete
                            v-model="repairerMasterSearchObj.state"
                            :search-input.sync="searchState"
                            class="form-control"
                            :items="states"
                            item-text="stateCodeName"
                            item-value="stateCodeName"
                            @change="getClaimantCities"
                            placeholder="Start typing to Search"
                            solo
                            dense
                            deletable-chips
                            hide-no-data
                            clearable>
                        </v-autocomplete>
                </v-flex> -->
                
                <!-- <v-flex  class="form-group">
                        <label class="form-label">District</label>
                        <v-autocomplete
                            v-model="repairerMasterSearchObj.district"
                            :search-input.sync="searchDistrict"
                            class="form-control"
                            :items="districts"
                            item-text="cityDistrictName"
                            item-value="cityDistrictName"
                            placeholder="Start typing to Search"
                            :maxlength="10"
                            solo
                            dense
                            deletable-chips
                            hide-no-data
                            clearable>
                        </v-autocomplete>
                </v-flex> -->


                <!-- <v-flex  class="form-group">
                        <label class="form-label">Record</label>
                        <v-select
                            label="Select"
                            solo
                            dense
                            :items="recordOptions"
                            v-model="repairerMasterSearchObj.record"
                            class="form-control"
                        ></v-select>
                </v-flex> -->

                <v-flex  class="form-group">
                        <label class="form-label">Mobile Number</label>
                        <v-text-field
                            placeholder="Enter..."
                            solo
                            dense
                            :maxLength="10"
                            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                            v-model="repairerMasterSearchObj.phoneNumber"
                            :rules="[v => matchRegex(v, 'nums')]"
                            prepend-inner-icon="mdi-phone"
                            class="form-control">
                        </v-text-field>
                </v-flex>

                <v-flex  class="form-group">
                        <label class="form-label">Product (Type)</label>
                        <v-select
                            label="Multi Select"
                            solo
                            dense
                            multiple
                            :items="productTypeOptions"
                            v-model="repairerMasterSearchObj.productType"
                            class="form-control">
                            <template v-slot:selection="{ item, index }">
                            <span v-if="index === 0">{{ item }}</span>
                            <span
                                v-if="index === 1"
                                class="grey--text text-caption">
                                (+{{ repairerMasterSearchObj.productType.length - 1 }} others)
                            </span>
                            </template>
                        </v-select>
                </v-flex>

                <v-flex  class="form-group">
                        <label class="form-label">Authorized Brand(s)</label>
                        <v-select
                            label="Multi Select"
                            solo
                            dense
                            multiple
                            :items="authorizedBrandOptions"
                            v-model="repairerMasterSearchObj.authorizedBrand"
                            class="form-control">
                            <template v-slot:prepend-item>
                            <v-list-item
                                ripple
                                @click="toggle">
                                <v-list-item-action>
                                <v-icon :color="repairerMasterSearchObj.authorizedBrand.length > 0 ? 'indigo darken-4' : ''">
                                    {{ icon }}
                                </v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                <v-list-item-title>
                                    All
                                </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-divider class="mt-2"></v-divider>
                            </template>
                            <template v-slot:selection="{ item, index }">
                            <span v-if="index === 0">{{ item }}</span>
                            <span
                                v-if="index === 1"
                                class="grey--text text-caption">
                                (+{{ repairerMasterSearchObj.authorizedBrand.length - 1 }} others)
                            </span>
                            </template>
                        </v-select>
                </v-flex>
<!--                 
                <v-flex  class="form-group">
                    <v-checkbox
                    class="form-control ml-3 pt-0"
                    v-model="repairerMasterSearchObj.isActive"
                    dense
                    label="Active"
                    
                  >
                  </v-checkbox>
                </v-flex> -->
                <v-flex  class="form-group">
                        <label class="form-label">Status</label>
                        <v-select
                            label="Select"
                            solo
                            dense
                            item-text="name"
                            item-value="value"
                            :items="statusOptions"
                            v-model="repairerMasterSearchObj.isActive"
                            class="form-control"
                        ></v-select>
                </v-flex>
            </v-layout>

            <!-- Buttons -->
            <v-layout fluid class="mb-3 flexbg" justify-end>
                <v-flex align-self-center class="ml-3 d-flex align-item-end">
                    <v-btn color="#152F38" dark class="mr-2  buttonscolor" style="float: right" @click="back()">
                        <v-icon class="iconsize mr-2">mdi-arrow-left-circle</v-icon>
                        <div class="vertical_line"></div>Back
                    </v-btn>
                    <v-btn color="#E46A25" dark class="mr-2  buttonscolor" @click="reset">
                        <v-icon class="iconsize mr-2">mdi-close-circle</v-icon>
                        <div class="vertical_line"></div>
                        Reset
                    </v-btn>
                    <!-- phase2 changes for download -->
                    <!-- <v-btn color="#E46A25" dark class="buttonscolor mr-2" @click="downloadRepairer">
                        <v-icon class="mr-2 iconsize">mdi-download</v-icon>
                        <div class="vertical_line"></div>Download
                    </v-btn> -->
                </v-flex>
                <v-flex align-self-center class="d-flex justify-end">
                    <v-btn color="#152F38" dark class="mr-2  buttonscolor"  @click="goToCreateRepairer()">
                        <v-icon class="iconsize mr-2">mdi-plus</v-icon>
                        <div class="vertical_line"></div>Create
                    </v-btn>
                    <v-btn color="#E46A25" dark class="mr-2  buttonscolor" type="submit">
                        <v-icon class="iconsize mr-2">mdi-magnify</v-icon>
                        <div class="vertical_line"></div>
                        Search
                    </v-btn>
                </v-flex>
            </v-layout>

        </v-form>

        <v-layout fluid class="mb-3 flexbg" v-if="this.selctedrowId" justify-start>
        <v-flex align-self-center style="float: right">
            <!-- <v-btn color="#E46A25" class="mr-2  buttonscolor" dark v-if="this.repairerStatus" @click="deactivate()" style="float: right">
            <v-icon class="iconsize mr-2">mdi-check-circle</v-icon>
            <div class="vertical_line"></div>
            Deactivate
            </v-btn>
            <v-btn color="#23B1A9" class="mr-2  buttonscolor" dark v-if="!this.repairerStatus" @click="deactivate()" style="float: right">
            <v-icon class="iconsize mr-2">mdi-check-circle</v-icon>
            <div class="vertical_line"></div>
            Activate
            </v-btn> -->
            <v-btn color="#23B1A9" class="mr-2  buttonscolor" dark @click="modify" style="float: right"> 
            <v-icon class="iconsize mr-2">mdi-pencil</v-icon>
            <div class="vertical_line"></div>
            Modify
            </v-btn>
            <v-btn color="#23B1A9" class="mr-2 buttonscolor" @click="view" style="float: right">
            <v-icon class="iconsize mr-2">mdi-eye</v-icon>
            <div class="vertical_line"></div>
            View
            </v-btn>
        </v-flex>
        </v-layout>

        <template v-if="this.repairerMastersData.length > 0">
        <div>
            <v-data-table
                :headers="repairerHeaders"
                :items="repairerMastersData"
                hide-default-footer
                no-data-text="No Records for Repairers"
                class="vDataTableStyling tableborder">
                <template v-slot:[`item.select`]="{ item }">
                    <v-radio-group v-model="selctedrowId">
                    <v-radio
                        color="#23B1A9"
                        :value="item._id"
                        @click="getStatus(item.repairerStatus)"
                        class="workitemcheck"
                    ></v-radio>
                    </v-radio-group>
                </template>
                <template v-slot:[`item.parentRepairerId`] = "{item}">
                    <v-btn color="#FFFFFF" class="tablebtn" @click="openParentWithChildRepairer(item.isChildRepairer ? item.parentRepairerId : item.repairerId, item)">
                    {{ limitTextLength(item.isChildRepairer ? item.parentRepairerId : item.repairerId) }}
                    <v-icon class="tableicons">mdi-arrow-right-circle</v-icon>
                    </v-btn>
                </template>
                <template v-slot:[`item.childRepairerID`] = "{item}">
                <v-btn color="#FFFFFF" class="tablebtn" @click="openChildWithParentRepairer(item.isChildRepairer && item.repairerId)"  v-if="item.isChildRepairer">
                    {{  limitTextLength(item.repairerId) }} 
                    <v-icon class="tableicons">mdi-arrow-right-circle</v-icon>
                </v-btn>
                <div v-else style="text-align: left">---</div>
                </template>
                <template v-slot:[`item.repairerName`]="{ item }">
                    <span>
                        {{limitTextLength(item.repairerName)}}
                    </span>
                </template>
                <template v-slot:[`item.status`]="{ item }">
                <span :class="getStatusStyle(item)">
                    {{item.repairerStatus ? 'Active' : 'Inactive'}}
                </span>
                </template>
                <template v-slot:[`item.approveStatus`]="{ item }">
                    <span :class="approveStatusColor(item.approveStatus)">{{ item.approveStatus }}</span>
                </template>
                <template v-slot:[`item.panApprovalStatus`]="{ item }">
                    <span :class="approveStatusColor(item.panApprovalStatus)">{{ item.panApprovalStatus }}</span>
                </template>
            </v-data-table>
            <v-layout fluid class="flexbg mb-3">
                <v-layout justify-end v-if="this.repairerMastersData.length > 0">
                    <div style="margin-top: 10px">
                    <span class="orangetext paginationText">
                        Showing {{ totalListCount ? pageStartIndex + 1 : 0 }} - {{
                        pageEndIndex
                        }}
                    </span>
                    <span class=" mr-2 paginationText"> out of {{ totalListCount }} records </span>
                    </div>
                    <v-pagination
                        v-model="pageNumber"
                        :length="totalPages"
                        :total-visible="pageRowCount"
                        @input="goNextPagePagination"
                        color="#152F38"
                        class="navpagenumber pt-1"
                    ></v-pagination>
                </v-layout>
            </v-layout>
        </div>
        </template>
    </v-container>
</template>

<script>
    export default {
        data () {
            return {
                repairerNameSync:"",
                repairerIDSync:"",
                repairerNamesData:[],
                pincodesList:[],
                repairerIdData:[],

                selctedrowId: '',
                pageNumber: 1,
                pageStartIndex: 0,
                pageRowCount: 10,
                totalListCount: 0,
                totalPages:null,

                repairerHeaders: [
                    {text: 'Select',align: 'center',sortable: false,value: 'select',width: "23px"},
                    { text: "Master ID", value: "uid", align: "center", width: '150' },
                    { text: 'LOB', value: 'lob', sortable: false, width: 100,align: 'center' },
                    { text: 'Parent Repairer ID', value: 'parentRepairerId', sortable: false,align: 'center' },
                    { text: 'Child Repairer ID', value: 'childRepairerID', sortable: false,align: 'center' },
                    { text: 'Repairer Name', value: 'repairerName', sortable: false,align: 'center' },
                    { text: 'Phone Number', value: 'phoneNumber', sortable: false,align: 'center' },
                    { text: 'Pincode', value: 'postalCode', sortable: false,align: 'center' },
                    { text: 'City', value: 'city', sortable: false,align: 'center' },
                    { text: 'District', value: 'district', sortable: false,align: 'center' },
                    { text: 'State', value: 'state', sortable: false,align: 'center' },
                    { text: 'Status', value: 'status', sortable: false,align: 'center' },
                    {text: "Approval Status",value: "approveStatus",sortable: false, align: 'center'},
                    {text: "F & A Status",value: "panApprovalStatus",sortable: false, align: 'center'}
                ],
                repairerMastersData: [],
                repairerMasterSearchObj: {
                    lob:[],
                    repairerId: '',
                    repairerName: '',
                    country: '',
                    state: '',
                    district: '',
                    city: '',
                    postalCode: '',
                    phoneNumber: '',
                    record: 'Current',
                    status: '',
                    isActive:null,
                    productType: [],
                    authorizedBrand: []
                },

                userObj: {},

                searchCountry: '',
                countries: [],

                pitems: [],
                searchPincode: '',

                searchState:'',
                states: [],

                searchDistrict: '',
                districts : [],

                searchCity:'',
                cities:[],

                IsDisabledafterpincode:false,
                repairerStatus: null,
                lobOptions:[],
                lob:[],
                
                approvedStatusOptions: ["Approved", "Rejected", "Pending for approval"],
                statusOptions: [{name:"Active", value:true}, {name:"Inactive", value:false}],

                recordOptions: ["Current", "History", "ALL"],
                authorizedBrandOptions: [
                    "ACER",
                    "ALCATEL",
                    "ALTEC",
                    "ANJALI",
                    "AO SMITH",
                    "APPLE",
                    "APPLE I-PH",
                    "APPLE IPOD",
                    "AQUA SURE",
                    "ASUS",
                    "BAJAJ",
                    "BEE",
                    "BINATONE",
                    "BIONAIRE",
                    "BLACK AND DECKER",
                    "BLACKBERRY",
                    "BLAPUNKT",
                    "BLUESTAR",
                    "BOSCH",
                    "BOSE",
                    "BOYNQ",
                    "BRAUN",
                    "BRIDGE",
                    "BROTHER",
                    "CANON",
                    "CARRIER",
                    "CARRIER MIDEA",
                    "CASELOGIC",
                    "Cello",
                    "CG",
                    "COMPAQ",
                    "CREATIVE",
                    "CROMA",
                    "CROMPTON",
                    "CRUISE",
                    "DAIKIN",
                    "DELL",
                    "DSE",
                    "ELECTROLUX",
                    "EPSON",
                    "EUREKA FORBES",
                    "EUREKAFORB",
                    "FABER",
                    "FLY",
                    "FUJI",
                    "GE",
                    "GLEN",
                    "GODREJ",
                    "HAIER",
                    "HAMILTON BEACH",
                    "HAVELLS",
                    "HCL",
                    "Hindware",
                    "HITACHI",
                    "HP",
                    "HTC",
                    "HUL PUREIT",
                    "IAUDIO",
                    "IBALL",
                    "IFB",
                    "INASLA",
                    "INTEX",
                    "JABRA",
                    "JAMO",
                    "JBL",
                    "KAFF",
                    "KAILASH",
                    "KELVINATOR",
                    "Kenstar",
                    "KENT",
                    "KENWOOD",
                    "KINDLE",
                    "KITCHEN AID",
                    "KLIPSCH",
                    "KODAK",
                    "LABTEC",
                    "LENOVO",
                    "LG",
                    "LIV PURE",
                    "LOGIC3",
                    "LOGITECH",
                    "Maharaja",
                    "MAHARAJA WHITELINE",
                    "MI",
                    "MI or XIAOMI",
                    "MICROSOFT",
                    "MITASHI",
                    "MORPHY RICHARDS",
                    "MORPHYRICH",
                    "MOSERBAER",
                    "MOTOROLA",
                    "NATRAJ",
                    "NIKON",
                    "NOKIA",
                    "OGENERAL",
                    "Olympus",
                    "ONE PLUS",
                    "ONIDA",
                    "ONKYO",
                    "OPPO",
                    "OPTIMA",
                    "Orient",
                    "OSIM",
                    "PADMINI",
                    "PANASONIC",
                    "PHILIPS",
                    "PIGEON",
                    "PILOT",
                    "POLK AUDIO",
                    "POLORID",
                    "PREETHI",
                    "PRESTIGE",
                    "RACOLD",
                    "REALME",
                    "REDMI",
                    "REMINGTON",
                    "RUSSELL HOBBS",
                    "SAMSUNG",
                    "SANDISK",
                    "SANSUI",
                    "SCRATCHGAR",
                    "SEIMENS",
                    "SHARP",
                    "SIEMENS",
                    "SOLO",
                    "SONY",
                    "SONY ERICS",
                    "SUNFLAME",
                    "SYMPHONY",
                    "TATA",
                    "TATA SKY",
                    "TCL",
                    "TOSHIBA",
                    "ULTRA",
                    "UNIDEN",
                    "VIDEOCON",
                    "VIEWSONIC",
                    "VIRGIN",
                    "VIVO",
                    "VOLTAS",
                    "VOXTEL",
                    "VU",
                    "WHIRLPOOL",
                    "XIAOMI",
                    "YAMAHA"
                ],
                productTypeOptions: [
                    "Airconditioner","Computer Accessories","CRT TV/LCD TV/PLASMA","Dishwasher",
                    "DVD/VCD/Audio System","Gas Stove/Burners","KITCHEN APPLIANCES","Laptops/Desktops",
                    "Microwaveoven/OTG","Phones - Fixed or Mobile","Printer Fax Machine","Projection Television",
                    "Refrigerator/Chiller","Washing Machine/Dryers"
                ],

                // RULES
                validationmessage:"This field is required",
                rules: {
                    repairerName: [
                        v => !!v || this.validationmessage,
                        // v => v && !!v.trim() || 'Only spaces are not allowed',
                        v => (v && v.length <= 200) || 'Repairer Name should be less than 200 characters.',
                    ],
                    postalCode: [
                        v => !!v || this.validationmessage,
                        // v => v && !!v.trim() || 'Only spaces are not allowed',
                        v => (v && /^[0-9]+$/.test(v) && v.length === 6 && v[0] != 0) || 'Please enter correct pin code.',
                    ],
                    alphaNumeric: [
                        v => /^[a-zA-Z0-9]+$/.test(v) || 'It is not valid',
                    ],
                    mandatoryrule: [
                        v => !!v || this.validationmessage
                    ],
                    emailRules: [
                        (v) => !!v || this.validationmessage,
                        (v) => (v && v.length <= 50) || "Email should be less than 50 characters.",
                        (v) => /.+@.+\..+/.test(v) || "E-mail is not valid.",
                    ],
                    corrBankNameRules: [
                        (v) => !!v || this.validationmessage,
                        (v) => (v && v.length <= 100) || "Bank Name should be less than 100 characters.",
                        (v) => /[a-zA-Z0-9\s]+$/.test(v) || "Bank Name is not valid.",
                    ],
                    corrBankAddressRules: [
                        (v) => !!v || this.validationmessage,
                        (v) =>
                            (v && v.length <= 50) ||
                            "Address should be less than 50 characters.",
                        (v) => /[a-zA-Z0-9\s.-:;,/#]+$/.test(v) || "Address is not valid.",
                    ],
                    corrBankAccNumRule: [
                        (v) => !!v || this.validationmessage,
                        (v) => (v && v.length <= 50) || "Account Number should be less than 50 characters.",
                        (v) => /[a-zA-Z0-9]+$/.test(v) || "Account Number is not valid.",
                    ],
                    corrBankReAccNumRule: [
                        (v) => !!v || this.validationmessage,
                        (v) => (v && v.length <= 50) || "Account Number should be less than 50 characters.",
                        (v) => /[a-zA-Z0-9]+$/.test(v) || "Account Number is not valid.",
                        // v => (v && v !== this.aigMasterForm.corrBankDetails.bankAccNum) || 'Account Number does not match.'
                    ],
                }
            }
        },

        created() {
            this.$store.commit("SAVE_PAGE_HEADER", "Repairer Master");
            if(Object.keys(this.$route.params).length !== 0) {
                if(this.$route.params.repairerMasterSearchObj) {
                    this.repairerMasterSearchObj = this.$route.params.repairerMasterSearchObj;
                }
                if(this.$route.params.pageno){
                    console.log("from pageno")
                    this.goToNextPage(this.$route.params.pageno)
                }else{
                    this.goToNextPage(1)
                }
            }
            this.getCountries()
            this.getLOB()
            this.goToNextPage(1)
            // if(this.repairerMasterSearchObj.country){
                // this.getClaimantStates()
            // }
        },
        
        computed: {
            pincodes(){
                return this.pitems
            },

            pageEndIndex() {
                return this.pageStartIndex + this.pageRowCount <= this.totalListCount
                ? this.pageStartIndex + this.pageRowCount
                : this.totalListCount;
            },
            
            selectAllBrands () {
                return this.repairerMasterSearchObj.authorizedBrand.length === this.authorizedBrandOptions.length
            },

            selectSomeBrands () {
                return this.repairerMasterSearchObj.authorizedBrand.length > 0 && !this.selectAllBrands
            },

            icon () {
                if (this.selectAllBrands) return 'mdi-close-box'
                if (this.selectSomeBrands) return 'mdi-minus-box'
                return 'mdi-checkbox-blank-outline'
            },
        },
        
        methods: {
            getPincode(code){
                if(code.length > 2){
                    this.pincodesList = this.repairerMastersData.map((item) => item.postalCode)
                }else{
                    this.pincodesList = []
                }
            },
            getRepairerName(name){
                if(name.length > 2){
                    this.repairerNamesData = this.repairerMastersData.map((item) =>({value:item.repairerName,text:`${item.repairerName} - ${item.city} - ${item.panNumber}`}))
                }else{
                    this.repairerNamesData = []
                }
            },
            getRepairerID(name){
                console.log(name)
                if(name?.length > 2){
                    this.repairerIdData = this.repairerMastersData.map((item) =>item.repairerId)
                }else{
                    this.repairerIdData = []
                }
            },

            getLOB() {
        let reqData = '';
        this.showProgress();
        this.GETM("getLOB", reqData, (res) => {
            this.hideProgress();
            if(res.data.statusCode === 200) {
                this.lobOptions = res.data.data[0].lineOfBusiness;
            } else {
                this.lobOptions = [];
                this.showAlert(res.data.msg);
            }
        }, (error) => {
            console.log(error);
            this.hideProgress();
        }, true)
    },
            back(){
                this.showConfirm("Confirmation","Are you sure to return back ?","Yes","No",(Yes) => {
                    this.$router.push("/master");
                },(No) => {});
            },

            isNumber(evt) {
                evt = (evt) ? evt : window.event;
                var charCode = (evt.which) ? evt.which : evt.keyCode;
                if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                    evt.preventDefault();
                }
                return true;
            },

            onlyAlphaNumeric(e){
              var code = ('charCode' in e) ? e.charCode : e.keyCode;
              if (
                  !(code > 47 && code < 58) && // numeric (0-9)
                  !(code > 64 && code < 91) && // upper alpha (A-Z)
                  !(code > 96 && code < 123)) { // lower alpha (a-z)
                  e.preventDefault();
              }
            },

            isLetter(e) {
                let char = String.fromCharCode(e.keyCode);
                if(/^[A-Z a-z]$/.test(char)) return true;
                else e.preventDefault();
            },

            getStatusStyle(item) {
                return !item.repairerStatus ? 'inactive' : 'active'
            },

            toggle () {
                this.$nextTick(() => {
                    if (this.selectAllBrands) {
                    this.repairerMasterSearchObj.authorizedBrand = []
                    } else {
                    this.repairerMasterSearchObj.authorizedBrand = this.authorizedBrandOptions.slice()
                    }
                })
            },

            goToCreateRepairer () {
                if(this.checkPermission()){
                    return this.showAlert("You don't have permission to create or modify record.")
                }
                this.$router.push('/createrepairer');
            },

            getStatus(status) {
                this.repairerStatus = status
            },

            limitTextLength(name) {
                return name.length > 15 ? name.substring(0, 11).concat('...') : name
            },

            openParentWithChildRepairer (id, item) {
                let selecteditemid = {
                    id,
                    view: true,
                    pincodeFunctionCall:true,
                    repairerMasterSearchObj: this.repairerMasterSearchObj,
                    approveStatus: item.approveStatus,
                    panApprovalStatus: item?.panApprovalStatus,
                    repairerStatus: item.repairerStatus,
                    pageno:this.pageNumber
                };
                this.$router.push({
                    name: "ParentWithChildRepairer",
                    params: selecteditemid,
                });
            },

            openChildWithParentRepairer (id) {
                let selecteditemid = {
                    id,
                    view: true,
                    pincodeFunctionCall:true,
                    repairerMasterSearchObj: this.repairerMasterSearchObj,
                    pageno:this.pageNumber
                };
                this.$router.push({
                    name: "ChildWithParentRepairer",
                    params: selecteditemid,
                });
            },

            goNextPagePagination(pageNumber) {
                this.goToNextPage(pageNumber);
            },

            submitSearchRepairerForm() {
                if (this.$refs.searchRepairer.validate()) {
                    this.goToNextPage(1);
                } else {
                    this.showAlert("Please enter all mandatory fields");
                }
            },
            
            goToNextPage(pageNumber) {
                this.pageNumber= pageNumber ? pageNumber : 1;
                let requestData = "perPage=" + this.pageRowCount + "&pageNo=" + pageNumber;
                if (this.repairerMasterSearchObj.repairerId) {
                    requestData= requestData +"&repairerId=" +this.repairerMasterSearchObj.repairerId;
                }
                if (this.repairerMasterSearchObj.repairerName) {
                requestData= requestData + "&repairerName=" + this.repairerMasterSearchObj.repairerName;
                }
                if (this.repairerMasterSearchObj.country) {
                requestData= requestData + "&country=" + this.repairerMasterSearchObj.country;
                }
                if (this.repairerMasterSearchObj.lob.length>0) {
                requestData= requestData + "&lob=" + this.repairerMasterSearchObj.lob;
                }
                if (this.repairerMasterSearchObj.state) {
                requestData= requestData + "&state=" + this.repairerMasterSearchObj.state;
                }
                if (this.repairerMasterSearchObj.district) {
                    requestData=  requestData + "&district=" + this.repairerMasterSearchObj.district;
                }
                if (this.repairerMasterSearchObj.city) {
                    requestData=  requestData + "&city=" + this.repairerMasterSearchObj.city;
                }
                if (this.repairerMasterSearchObj.postalCode) {
                requestData= requestData + "&postalCode=" + this.repairerMasterSearchObj.postalCode;
                }
                if (this.repairerMasterSearchObj.phoneNumber) {
                requestData= requestData + "&phoneNumber=" + this.repairerMasterSearchObj.phoneNumber;
                }
                if (this.repairerMasterSearchObj.isActive === true) {
                    // let status = this.repairerMasterSearchObj.isActive === true ? true : false
                    requestData= requestData + "&repairerStatus=" + this.repairerMasterSearchObj.isActive;
                }else if(this.repairerMasterSearchObj.isActive === false){
                    requestData= requestData + "&repairerStatus=" + this.repairerMasterSearchObj.isActive;
                }
                if (this.repairerMasterSearchObj.status) {
                    //let status = this.repairerMasterSearchObj.status === 'Active' ? true : false
                    requestData= requestData + "&approveStatus=" + this.repairerMasterSearchObj.status;
                }
                if (this.repairerMasterSearchObj.productType && this.repairerMasterSearchObj.productType.length > 0) {
                requestData= requestData + "&productType=" + encodeURIComponent(JSON.stringify(this.repairerMasterSearchObj.productType));
                }
                if (this.repairerMasterSearchObj.authorizedBrand && this.repairerMasterSearchObj.authorizedBrand.length > 0) {
                requestData= requestData + "&authorizedBrand=" + encodeURIComponent(JSON.stringify(this.repairerMasterSearchObj.authorizedBrand));
                }
                    this.showProgress("Data is searching, Please wait");
                    this.GETM("GET_SEARCH_REPAIRER_MASTER",requestData,(res) => {
                        if (res.data.statusCode === 200 && res.data.data && res.data.data.data) {
                            this.hideProgress(); 
                            this.repairerMastersData = res.data.data.data;
                            this.pageRowCount = 10;
                            this.totalListCount = res.data.data.total;
                            this.totalPages = Math.ceil(res.data.data.total / this.pageRowCount);
                            this.pageStartIndex = pageNumber == 1 ? 0 : (pageNumber - 1) * this.pageRowCount;
                        }else{
                            this.hideProgress(); 
                            this.repairerMastersData = [];
                            this.pageRowCount = 0;
                            this.totalListCount = 0;
                            this.totalPages = 0;
                            this.pageStartIndex =0;
                            this.showAlert("No Data Available!");
                        }
                    },(error) => {
                        this.hideProgress();
                        this.repairerMastersData = [];
                        if (error.response.data.msg) {
                            this.showAlert(error.response.data.msg);
                        } else if (error.response.data.message.msg) {
                            this.showAlert(error.response.data.message.msg);
                        } else if (error.response.data.message) {
                            this.showAlert(error.response.data.message);
                        } else {
                            this.showAlert("Something went wrong");
                        }
                    },true);
            },

            reset() {
                this.showConfirm("Confirmation","Are you sure you want to reset?","Yes","No", (Yes) => {
                    this.repairerMasterSearchObj = {
                        lob:[],
                    repairerId: '',
                    repairerName: '',
                    country: '',
                    state: '',
                    district: '',
                    city: '',
                    postalCode: '',
                    phoneNumber: '',
                    record: 'Current',
                    status: '',
                    isActive:null,
                    productType: [],
                    authorizedBrand: []
                    }
                    this.repairerMastersData = []
                    this.$refs.searchRepairer.resetValidation();
                }, (No) => {});
            },

            view() {
                let id = this.selctedrowId ? this.repairerMastersData.find((data) => data._id === this.selctedrowId) : '';
                let selecteditemid = {
                    id: id.repairerId,
                    view: true,
                    pincodeFunctionCall:true,
                    repairerMasterSearchObj: this.repairerMasterSearchObj,
                    approveStatus: id.approveStatus,
                    panApprovalStatus: id?.panApprovalStatus,
                    repairerStatus: id.repairerStatus,
                    pageno:this.pageNumber
                };
                this.$router.push({
                    name: id.isChildRepairer ? "ChildWithParentRepairer" : "ParentWithChildRepairer",
                    params: selecteditemid,
                });
            },

            modify() {
                if(this.checkPermission()){
                    return this.showAlert("You don't have permission to create or modify record.")
                }
                let id = this.selctedrowId ? this.repairerMastersData.find((data) => data._id === this.selctedrowId) : '';
                if (id.approveStatus == "Pending for approval") {
                    this.showAlert('Approval for this record is pending');
                } else if (id.panApprovalStatus == "Pending for approval") {
                    this.showAlert("Master record pending with F & A team for approval and hence master record is not allowed for editing.");
                } else {
                    let selecteditemid = {
                        id: id.repairerId,
                        view: false,
                        pincodeFunctionCall:true,
                        repairerMasterSearchObj: this.repairerMasterSearchObj,
                        approveStatus: id.approveStatus,
                        panApprovalStatus: id?.panApprovalStatus,
                        repairerStatus: id.repairerStatus,
                        pageno:this.pageNumber
                    };
                    this.$router.push({
                        name: id.isChildRepairer ? "ChildWithParentRepairer" : "ParentWithChildRepairer",
                        params: selecteditemid,
                    });
                }
            },

            updateFile(file) {
                let newDocument = file.map((fileData) => {
                    if(fileData.fileBase) {
                    return fileData
                    } else {
                    // fileData["fileBase"] = "";
                    return fileData
                    }
                });
                return newDocument;
            },

            deactivate() {
                let itemData = this.selctedrowId ? this.repairerMastersData.find((data) => data._id === this.selctedrowId) : '';
                if(itemData) {
                    this.userObj = itemData.user
                }
                let requestData = {
                    // repairerId: this.childRepairerID,
                    repairerName: itemData.repairerName,
                    addressLine1: itemData.addressLine1,
                    addressLine2: itemData.addressLine2,
                    addressLine3: itemData.addressLine3,
                    country: itemData.country,
                    state: itemData.state,
                    district: itemData.district,
                    city: itemData.city, 
                    postalCode: itemData.postalCode,
                    phoneNumber: itemData.phoneNumber,
                    preCommMethod: itemData.preCommMethod,
                    repairerStatus: !itemData.repairerStatus,
                    faxNumber: itemData.faxNumber,
                    secondFaxNumber: itemData.secondFaxNumber,
                    superRepairerIndicator: itemData.superRepairerIndicator,
                    overllSatisfactionRating: itemData.overllSatisfactionRating,
                    agreementEffectiveDate: itemData.agreementEffectiveDate ? itemData.agreementEffectiveDate : "",
                    agreementExpirationDate: itemData.agreementExpirationDate ? itemData.agreementExpirationDate : "",
                    pickUp: itemData.pickUp,
                    isTripFee: itemData.isTripFee, 
                    tripFee: itemData.tripFee,
                    laborRate: itemData.laborRate,
                    jobRate1: itemData.jobRate1,
                    jobRate2: itemData.jobRate2,
                    jobRate3: itemData.jobRate3,
                    lastAuditDate: itemData.lastAuditDate ? itemData.lastAuditDate : "",
                    nextAuditDate: itemData.nextAuditDate ? itemData.nextAuditDate : "",
                    productType: itemData.productType,
                    authorizedBrand: itemData.authorizedBrand,
                    repairerWarrantyPeriod: itemData.repairerWarrantyPeriod,
                    salutation: itemData.salutation,
                    firstName: itemData.firstName,
                    middleName: itemData.middleName,
                    lastName: itemData.lastName,
                    // suffix: itemData.suffix,
                    workPhoneNumber: itemData.workPhoneNumber,
                    emailId: itemData.emailId,
                    cellPhoneNumber: itemData.cellPhoneNumber,
                    commEmailId: itemData.commEmailId,
                    secondEmailId: itemData.secondEmailId,
                    panNumber: itemData.panNumber,
                    gstNumber: itemData.gstNumber,
                    gstFrequency: itemData.gstFrequency,
                    tdsType: itemData.tdsType,
                    differentialTds: itemData.differentialTds,
                    msme: itemData.msme === 'Yes' ? true : false,
                    enterpriceClassification: itemData.enterpriseClassification,
                    enterpriceCategory: this.enterpriseCategory,
                    uan: itemData.uan,
                    bankTransfer: itemData.bankTransfer,
                    bankName: itemData.bankName,
                    ifscCode: itemData.ifscCode,
                    bankAddress1: itemData.bankAddress1,
                    bankAddress2: itemData.bankAddress2,
                    bankAddress3: itemData.bankAddress3,
                    bankAccountNumber: itemData.bankAccountNumber,
                    confirmBankAccountNumber: itemData.bankAccountNumber,
                    bankAccountType: itemData.bankAccountType,
                    branchName: itemData.branchName,
                    cancelledCheque: this.updateFile(itemData.cancelledCheque),
                    attachments: this.updateFile(itemData.attachments),
                    user: {
                        ntid: this.userObj &&  this.userObj.ntid,
                        employeeName: this.userObj && this.userObj.employeeName,
                        userId: this.userObj && this.userObj.userId
                    }
                }
                this.PUT_ByURL("UPDATE_SINGLE_REPAIRER",requestData,(res) => {
                    this.showAlert(res.data.msg,"OK",null ,() => {
                    });
                    this.goToNextPage(1);
                    this.selctedrowId = ''
                    // this.goToNextPage(1)
                },function (err) {
                    // this.hideProgress();
                    let errorMsg = err.response.data.msg;
                    this.showAlert( errorMsg);
                }, itemData.repairerId); 
            },
            
            callPincodeAPI(indata){
                if(indata!==""){
                    if(isNaN(indata)){
                        this.returnLength = 100
                        this.reqData = "pageNo=1&perPage=100&smartSearch="+indata
                    }else{
                        this.returnLength = 6
                        this.reqData = "pageNo=1&perPage=100&pinCode="+indata
                    }
                    this.GETM("getPincodes",this.reqData, res=>{

                        var pin_not_found = false
                        if(res.data.data.length == 0){
                            pin_not_found = true
                            // this.counter = ''
                            this.message ='Pin code not found'
                            this.validationbox = true;
                        }else{
                            var processed_pin =  res.data.data.data.map(e=>{
                                if(e.cityDistrictName && e.cityDistrictName !== ""){
                                    return { locpincode:e.pinCode + " - " + e.pinCodeLocality, pincodeArry:e}
                                }else{
                                    return {locpincode: e.pinCode, all:e};
                                }                  
                            })
                            this.pitems.push(...processed_pin)

                        }  
                        if(pin_not_found){
                            // this.pitems.push({locpincode:'No data Available',all:''});
                            this.pitems.push();
                        }
                    },(error)=>{
                            return error;
                    });
                }
            },

            toGetCountryCity(pincode){
                if(pincode){
                    let indexItem =this.pincodes.find((elem)=>{
                        return elem.locpincode == pincode;
                    })
                    if(indexItem){
                        this.repairerMasterSearchObj.country = indexItem.pincodeArry.countryName ? indexItem.pincodeArry.countryName : "" ;
                        this.repairerMasterSearchObj.state = indexItem.pincodeArry.stateName ? indexItem.pincodeArry.stateName : "" ;
                        this.repairerMasterSearchObj.city = indexItem.pincodeArry.cityDistrictName ? indexItem.pincodeArry.cityDistrictName : "";
                        this.repairerMasterSearchObj.district = indexItem.pincodeArry.cityDistrictName ? indexItem.pincodeArry.cityDistrictName : "";  
                        // this.IsDisabledafterpincode = true;
                        // this.getClaimantStates()
                        // this.getClaimantCities()
                        this.searchCity=indexItem.pincodeArry.cityDistrictName
                    }
                }else{
                    this.repairerMasterSearchObj.country = '';
                    this.repairerMasterSearchObj.district = '';
                    this.repairerMasterSearchObj.state = '';
                    this.repairerMasterSearchObj.city = '';
                    // this.IsDisabledafterpincode = false
                    this.pitems = []
                    this.cities = []
                    this.districts = []
                    this.states = []
                    this.getCountries()
                }
            },

            clearClaimantcity(){
                if(this.repairerMasterSearchObj.city!='OTHER'){
                    this.repairerMasterSearchObj.cityOtherName=""
                }
            },

            // getClaimantStates(){
            //     if(this.repairerMasterSearchObj.country){
            //         // this.showProgress()
            //         var requestData = "countryName="+this.repairerMasterSearchObj.country
            //         this.GETM("getStates", requestData, (res) => {
            //             this.states = res.data.data.data
            //         },(error)=>{
            //             return error
            //         });
            //     }else{
            //         this.repairerMasterSearchObj.state = ''
            //         this.repairerMasterSearchObj.district = ''
            //         this.repairerMasterSearchObj.city = ''
            //         this.cities = []
            //         this.districts = []
            //         this.states = []
            //         this.getCountries()
            //     }
            // },

            // getClaimantCities(){
            //     if(this.repairerMasterSearchObj.state){
            //         this.showProgress()
            //         var req="stateName="+this.repairerMasterSearchObj.state                    
            //         this.GETM("getCities", req, (res) => {
            //             this.cities = res.data.data.data
            //             this.districts = res.data.data.data
            //             this.hideProgress()                    
            //         },(error)=>{
            //             this.hideProgress()
            //             return error;
            //         })
            //     }else{
            //         this.repairerMasterSearchObj.district = ''
            //         this.repairerMasterSearchObj.city = ''
            //         this.cities = []
            //         this.districts = []
            //     }
            // },

            //phase-2 start
            approveStatusColor(status) {
                    //return item.approveStatus !== "Approved" ? "red" : "green"; 
                //console.log(item)
                if(status == "Approved"){
                    return "green"
                }else if(status == "Rejected"){
                    return "redcolor"
                }else{
                    return "orange"
                }                      
            },

            // phase2 change for download
            openBase64File(base64) {
                const base64ImageData = base64;
                const contentType = base64.split(":")[1].split(";")[0];
                const byteCharacters = window.atob(base64ImageData.substr(`data:${contentType};base64,`.length));
                const byteArrays = [];
                for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
                    const slice = byteCharacters.slice(offset, offset + 1024);
                    const byteNumbers = new Array(slice.length);
                    for (let i = 0; i < slice.length; i++) {
                        byteNumbers[i] = slice.charCodeAt(i);
                    }
                    const byteArray = new Uint8Array(byteNumbers);
                    byteArrays.push(byteArray);
                }
                const blob = new Blob(byteArrays, {type: contentType,});
                const blobUrl = window.URL.createObjectURL(blob);
                window.open(blobUrl, "_blank");
            },

            viewFile(item) {
              let documentID = item.documentId ? item.documentId : item.doucumentId;
                if (documentID) {
                    let request = "documentId=" + documentID + "&b64=true";
                    this.GETM("VIEW_DOCUMENT", request, (response) => {
                        if(response.data.statusCode == 200){
                            let base64 = response.data.data;
                            this.openBase64File(base64);
                        }
                    }, (error) => {
                        this.showAlert(error.response.data.msg);
                    }, "remove");
                } else if (this.documentShowData[0].base64) {
                    this.openBase64File(this.documentShowData[0].base64);
                }
            },

            downloadRepairer(){
                let requestData = "perPage=" + '' + "&pageNo=" + '';
                if (this.repairerMasterSearchObj.repairerId) {
                    requestData= requestData +"&repairerId=" +this.repairerMasterSearchObj.repairerId;
                }
                if (this.repairerMasterSearchObj.repairerName) {
                requestData= requestData + "&repairerName=" + this.repairerMasterSearchObj.repairerName;
                }
                if (this.repairerMasterSearchObj.country) {
                requestData= requestData + "&country=" + this.repairerMasterSearchObj.country;
                }
                if (this.repairerMasterSearchObj.state) {
                requestData= requestData + "&state=" + this.repairerMasterSearchObj.state;
                }
                if (this.repairerMasterSearchObj.district) {
                    requestData=  requestData + "&district=" + this.repairerMasterSearchObj.district;
                }
                if (this.repairerMasterSearchObj.city) {
                    requestData=  requestData + "&city=" + this.repairerMasterSearchObj.city;
                }
                if (this.repairerMasterSearchObj.postalCode) {
                requestData= requestData + "&postalCode=" + this.repairerMasterSearchObj.postalCode;
                }
                if (this.repairerMasterSearchObj.phoneNumber) {
                requestData= requestData + "&phoneNumber=" + this.repairerMasterSearchObj.phoneNumber;
                }
                if (this.repairerMasterSearchObj.status) {
                    let status = this.repairerMasterSearchObj.status === 'Active' ? true : false
                    requestData= requestData + "&status=" + status;
                }
                if (this.repairerMasterSearchObj.productType && this.repairerMasterSearchObj.productType.length > 0) {
                requestData= requestData + "&productType=" + encodeURIComponent(JSON.stringify(this.repairerMasterSearchObj.productType));
                }
                if (this.repairerMasterSearchObj.authorizedBrand && this.repairerMasterSearchObj.authorizedBrand.length > 0) {
                requestData= requestData + "&authorizedBrand=" + encodeURIComponent(JSON.stringify(this.repairerMasterSearchObj.authorizedBrand));
                }
                this.showProgress("Data is searching, Please wait");
                this.GETM("exportRepairer",requestData,(res) => {
                    if (res.data.statusCode == 200 &&res.data.data &&res.data.data.length > 0) {
                        this.viewFile(res.data.data[0])
                    } else {
                    this.showAlert("No data available");
                    }
                    this.hideProgress();
                },(error) => {
                    this.hideProgress();
                    this.repairerMastersData = [];
                    if (error &&error.response &&error.response.data &&error.response.data.msg)
                        this.showAlert(error.response.data.msg);
                });
            },
            //end phase2

            getCountries(){
                this.GETM("getCoutries", "", (res) => {
                    this.countries = res.data.data.data;
                },(error) => {
                    return error;
                })
            }
        },

        watch: {
            // searchPincode(val){
            //     if(val !==null && val.charAt(0) === '0') {
            //         this.showToast("Please enter correct pin code", this.TOST().WARNING)
            //     }
            //     if(val == null){
            //         return
            //     }
            //     if(val.length > 4){
            //         if(val.length > 6){
            //             val = val.slice(0,6);
            //         }
            //         var vlen = val.length
            //         if(this.pincodes.length == 0 && val.length > 2){
            //             this.callPincodeAPI(val);
            //             return
            //         }
            //         for(let i=0; i<this.pincodes.length;i++){
            //             if(vlen > 3){
            //                 vlen = 3
            //                 var strlen = this.pincodes[i].locpincode.slice(0,vlen)
            //             }else{
            //                 var strlen = this.pincodes[i].locpincode.slice(0,vlen)
            //             } 
            //             if(vlen > strlen.length){
            //                 return
            //             }
            //             if(strlen != val){
            //                 this.callPincodeAPI(val)
            //                 return
            //             }else{
            //                 return
            //             }
            //         }
            //     }else{
            //         return
            //     }
            // },

            searchCountry(val) {
                if(val && val.length > 2){
                    let req = "countryName=" + val
                    this.GETM("getCountries", req, (res) => {
                        this.countries = res.data.data.data;
                    },(error) => {
                        return error;
                    })
                }
            },

            searchState(val){
                if(val!==null){
                    if(val.length > 2){        
                        let req = '';
                        if(this.repairerMasterSearchObj.country){
                            req = "countryName=" + this.repairerMasterSearchObj.country
                        } else {
                            req = "stateCodeName=" + val
                        }
                        this.GETM("getStates", req, (res) => {
                            this.states = res.data.data.data
                        },(error)=>{
                            return error;
                        }); 
                    }else{
                        return;
                    }
                }else{
                    return;
                }
            },

            searchCity(val){
                if(val!==null){
                    if(val.length > 2){
                        this.showProgress();
                        var req = ''
                        if(this.repairerMasterSearchObj.state) {
                            req = "stateName=" + this.repairerMasterSearchObj.state
                        }else if(this.repairerMasterSearchObj.country){
                            req = "countryName=" + this.repairerMasterSearchObj.country
                        } else {
                            req = "cityDistrictName=" + val
                        }
                        this.GETM("getCities", req, (res) => {
                            this.cities = res.data.data.data;
                            var other ={
                            cityDistrictName:'OTHER'
                            }
                            this.cities.push(other)
                                this.hideProgress();
                        },(error)=>{
                            this.hideProgress();
                            return error;
                        }); 
                    }else{
                        this.validationbox = true;
                        this.message = 'Please enter Minimum 3 charactor to search City';
                        this.cities.push();
                        return;
                    }
                }else{
                    this.cities.push();
                    return;
                }
            },

            // searchDistrict(val){
            //     if(val!==null){
            //         if(val.length > 2){
            //             var req = ''
            //             if(this.repairerMasterSearchObj.state) {
            //                 req = "stateName=" + this.repairerMasterSearchObj.state
            //             } else if(this.repairerMasterSearchObj.country){
            //                 req = "countryName=" + this.repairerMasterSearchObj.country
            //             } else {
            //                 req = "cityDistrictName=" + val
            //             }
            //             this.GETM("getCities", req, (res) => {
            //                 this.districts = res.data.data.data;
            //                     var other ={
            //                     cityDistrictName:'OTHER'
            //                 }
            //                 if(this.districts==undefined){
            //                     this.districts=[];
            //                 }
            //                 this.districts.push(other)
            //             },(error)=>{
            //                 return error;
            //             }); 
            //         }else{
            //             return;
            //         }
            //     }else{
            //         return;
            //     }
            // },
        }
    }
</script>

<style scoped>
    .containerStyle {
        margin: 0;
        padding: 0;
        max-width: 100%;
    }
    
    .iconsize{
        font-size: 18px !important;
    }
    .iconstyle {
        margin-left: -12px;
        background-color: rgb(247, 247, 247);
        width: 45px;
        height: 38px;
        font-size: 20px !important;
        border: 0.3px solid rgb(232, 235, 236);
        opacity: 1;
    }
    .tableicons{
      font-size: 18px !important;
      color: #23B1A9 !important;
      justify-content: right;
    }
    .tablebtn{
      background: #FFFFFF 0% 0% no-repeat padding-box !important;
      box-shadow: inset 0px 2px 1px #00000052 !important;
      border: 0.5px solid #C1C8CC;
      border-radius: 18px !important;
      height: 30px !important;
	    text-transform: capitalize !important;
      justify-content: space-between;
      width: 95%;
      font-size: 12px;
      letter-spacing: 0px;
    }
    .workitemcheck {
      height: 2px !important;
      margin-top: 0px !important;
    }
    .inactive {
      color: #E46A25;
      background: none !important;
      font-weight: bold;
    }
    .active {
      color: #1E9690;
      background: none !important;
      font-weight: bold;
    }
    .orangetext {
      color: #E46A25;
      font-size: 17px;
    }
    .paginationText{
      font-size: 12px;
    }
     /* phase2 */
    .redcolor {
        color: red;
        font-weight: bold;
        background: none !important;
    }
    .orange{
        color: #E46A25;
        font-weight: bold;
        background: none !important;
    }
    .green{
        color: #23b1a9;
        background: none !important;
        font-weight: bold;
    }
</style>