<template>
  <div class="mx-8 my-6">
    <v-row>
      <h3>Search GST Master</h3>
      <v-spacer></v-spacer>
      <v-btn class="btnToggle" outlined elevation="0" @click="handleCreateGST">
        <v-icon>mdi-plus-box-outline</v-icon>
        <v-divider class="mx-2 ml-2 white" vertical></v-divider>
        Create GST Master
      </v-btn>
    </v-row>
    <div class="d-flex align-center">
      <v-row style="max-width: 62%">
        <v-col>
          <label class="form-label">Tax Rate</label>
          <v-select
            class="form-control"
            v-model="searchData.taxRate"
            placeholder="Select"
            :items="taxRateOption"
            dense
            solo
            required
          ></v-select>
        </v-col>
        <v-col>
          <label class="form-label">Active Status</label>
          <v-select
            class="form-control"
            v-model="searchData.active"
            placeholder="Select"
            :items="entryStatusItems"
            item-value="value"
            item-text="text"
            dense
            solo
            required
          ></v-select>
        </v-col>
        <v-col>
          <label class="form-label">Master Enter Status</label>
          <v-select
            class="form-control"
            v-model="searchData.approveStatus"
            placeholder="Select"
            :items="masterEnterStatus"
            dense
            solo
            required
          ></v-select>
        </v-col>
      </v-row>
      <v-btn class="btn ml-4" outlined color="red" @click="handleReset(true)"
        ><v-icon> mdi-backspace </v-icon>
        <v-divider vertical class="mx-2"></v-divider>
        Reset</v-btn
      >
      <v-btn
        class="ml-4 white--text"
        color="#009688"
        dense
        solo
        @click="handleSearch"
        ><v-icon>mdi-arrow-right-circle</v-icon>
        <v-divider vertical class="mx-2 ml-2 white"></v-divider>
        Search</v-btn
      >
    </div>
    <v-layout class="mb-4">
      <h4 class="mr-3">
        <strong
          >GST Master List
          <span class="orange--text">({{ totalListCount?totalListCount:0 }})</span></strong
        >
      </h4>
      <v-chip
        small
        color="#1EBBD9"
        text-color="white"
        label
        close
        v-if="showSearchChip == true"
        @click:close="closeChip"
        >Search</v-chip
      >
      <v-spacer></v-spacer>
    </v-layout>
    <v-data-table
      :headers="gstHeader"
      no-data-text="No Records for GST Master"
      fixed-header
      :items="gstTableData"
      hide-default-footer
      class="vDataTableStyling"
    >
    <template v-slot:[`item.action`]="{ item }">
          <v-menu offset-y open-on-click>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                elevation=""
                icon
                v-bind="attrs"
                v-on="on"
                style="text-align: center"
                ><v-icon>mdi mdi-dots-vertical</v-icon></v-btn
              >
            </template>
            <v-list class="item-actions">
              <v-list-item
                v-for="(itemData, index) in menuItems"
                :key="index"
                @click="editmaster(itemData.link, index, item, itemData.name)"
                :disabled="
                  itemData.name === 'MODIFY' &&
                  ['Pending for approval'].includes(item.approveStatus)
                "
              >
                <v-list-item-title
                  :style="
                    itemData.name === 'MODIFY' &&
                    ['Pending for approval'].includes(item.approveStatus)
                      ? 'gray'
                      : itemData.color
                  "
                >
                  <v-icon small :style="itemData.iconColor"
                    >mdi-{{ itemData.icon }}</v-icon
                  >&nbsp;{{ itemData.name }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <template v-slot:[`item.active`]="{ item }">
        <span>
          {{ item.active ?"Active":"Inactive"}}
        </span>
      </template>
      <template v-slot:[`item.effectiveStartDate`]="{ item }">
        <span>
          {{ formatDate(item.effectiveStartDate) }}
        </span>
      </template>
      <template v-slot:[`item.effectiveEndDate`]="{ item }">
        <span>
          {{ endDateFormat(item.effectiveEndDate) }}
        </span>
      </template>
    </v-data-table>
    <!-- pagination -->
    <div
      class="text-center graybackground pdct-stUp-bnkAcc-pgn mt-3 mx-4"
      v-if="gstTableData.length > 0"
    >
      <v-layout justify-end>
        <div style="margin-top: 15px">
          <span class="orangetext paginationText">
            Showing {{ totalListCount ? pageStartIndex + 1 : 0 }} -
            {{ pageEndIndex }}
          </span>
          <span class="mr-2 paginationText">
            out of {{ totalListCount }} records
          </span>
        </div>
        <!-- @input="goNextPagePagination" -->
        <v-pagination
          v-model="pageNumber"
          :length="totalPages"
          :total-visible="pageRowCount"
          @input="searchGSTData"
          color="#152F38"
          class="navpagenumber pt-1"
        ></v-pagination>
      </v-layout>
      <br />
    </div>

    <!-- For Audit Trail -->
    <v-dialog v-model="audit_dialog" width="800">
      <v-card>
        <v-card-title class="white--text" style="background-color: #23b1a9">
          Audit Trail
          <v-spacer></v-spacer>
          <v-icon @click="audit_dialog = !audit_dialog" class="white--text"
            >mdi-window-close</v-icon
          >
        </v-card-title>
        <v-layout class="pt-4 form-group-container pr-4">
          <v-data-table
            :headers="audit_Header"
            hide-default-footer
            :items="audit_Data"
            class="vDataTableStyling w-100"
          >
            <template v-slot:[`item.srno`]="{ index }">
              {{ 1 + index }}
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-btn
                v-if="item.action === 'Modified'"
                color="#FFFFFF"
                class="text-capitalize tablebtn"
                @click="seeModify(item)"
              >
                {{ item.action }}
                <v-icon class="tableicons">mdi-arrow-right-circle</v-icon>
              </v-btn>
              {{ item.action !== "Modified" ? item.action : null }}
            </template>
            <template v-slot:[`item.createdAt`]="{ item }">
              {{ formatDateTime(item.createdAt) }}
            </template>
          </v-data-table>
        </v-layout>
        <v-layout justify-end align-center class="pb-2 px-0 mr-4">
          <div>
            <span style="color: orange"
              >Showing {{ AuditPageStartIndex }}-{{ AuditPageEndIndex }}</span
            >
            out of {{ AuditTotalListCount }} records
          </div>
          <div>
            <v-pagination
              class="small-pagination"
              v-model="AuditPage"
              :length="AuditPageCount"
              :total-visible="5"
              @input="getAuditData(AuditPage, '', true)"
              color="#152F38"
            ></v-pagination>
          </div>
        </v-layout>
      </v-card>
    </v-dialog>
    <!-- Modify dialogue -->
    <v-dialog v-model="seeModifyDialog" width="600">
      <v-card>
        <v-card-title class="white--text" style="background-color: #23b1a9">
          <v-layout justify-space-between>
            <div>Modified Field Details</div>
          </v-layout>
          <v-btn
            color="transparent"
            small
            :ripple="false"
            depressed
            @click="seeModifyDialog = false"
          >
            <v-icon color="white">mdi-close </v-icon>
          </v-btn>
        </v-card-title>
        <div class="scrollable-table">
          <v-data-table
            disable-pagination
            :headers="modifyHeaders"
            :items="modifyItems"
            hide-default-footer
            class="pa-4 vDataTableStyling"
          >
            <template v-slot:[`item.srNo`]="{ index }">
              {{ 1 + index }}
            </template>

            <template v-slot:[`item.oldValue`]="{ item }">
              {{     
                 (item.oldValue=="")?"--":item.oldValue
              }}
            </template>
            <template v-slot:[`item.newValue`]="{ item }">
              {{
               (item.newValue=="")?"--":item.newValue
              }}
            </template>
          </v-data-table>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      initialSearchData: {
        taxRate: "",
        active: "",
        approveStatus:"",
      },
      searchData: {},
      taxRateOption: ["0", "5", "6", "12", "18", "28"],
      entryStatusItems: [{value:true,text:"Active"},{value:false,text:"Inactive"}],
      masterEnterStatus: ['Approved', 'Rejected', 'Pending for approval'],
      showSearchChip: false,
      audit_dialog:false, 
      seeModifyDialog:false,
      count: 0,
      gstHeader: [
        {text: 'Action',align: 'center',value: 'action',sortable: false},
        {text: 'Master Id',align: 'center',value: 'uid',sortable: false},
        {text: 'LOB',align: 'center',value: 'lob',sortable: false, width: 100},
        {text: 'Approved/pending',align: 'center',value: 'approveStatus',sortable: false},
        {text: 'SGST/UGST(%)',align: 'center',value: 'sgstUgst',sortable: false},
        {text: 'CGST(%)',align: 'center',value: 'cgst',sortable: false},
        {text: 'IGST(%)',align: 'center',value: 'igst',sortable: false},
        {text: 'Active Status',align: 'center',value: 'active',sortable: false},
        {text: 'Tax Rate(%)',align: 'center',value: 'taxRate',sortable: false},
        {text: 'Effective Start Date',align: 'center',value: 'effectiveStartDate',sortable: false},
        {text: 'Effective End Date',align: 'center',value: 'effectiveEndDate',sortable: false},
                ],
      gstTableData:[],
      menuItems: [
      {
          name: "VIEW",
          icon: "eye-outline",
          link: "/viewpage",
          color: "color:#10242B",
          iconColor: "color:#10242B",
        },
        {
          name: "MODIFY",
          icon: "pencil",
          link: "/modifypage",
          iconColor: "color:#10242B",
          color: "color:#23b1a9",
        },
        {
          name: "AUDIT TRAIL",
          icon: "chart-timeline-variant",
          link: "/auditTrail",
          color: "color:orange",
          iconColor: "color:orange",
        },
                ],
      audit_Header: [
        { text: "Sr. No.", value: "srno", sortable: false, align: "center" },
        {
          text: "Master ID",
          value: "masterId",
          sortable: false,
          align: "center",
        },
        {
          text: "Action Taken",
          value: "action",
          sortable: false,
          align: "center",
        },
        { text: "User ID", value: "userId", sortable: false, align: "center" },
        {
          text: "User Name",
          value: "userName",
          sortable: false,
          align: "center",
        },
        {
          text: "Time Stamp",
          value: "createdAt",
          sortable: false,
          align: "center",
        },
      ],
      pageNumber: 1,
      pageStartIndex: 0,
      pageRowCount: 10,
      totalListCount: 0,
      totalPages:null,
      //audit trail
      audit_Data: [],
      AuditPage: 1,
      AuditTotalListCount: 0,
      AuditPageCount: 0,
      AuditPageStartIndex: 0,
      AuditItemsPerPage: 10,
      modifyItems: [],
      modifyHeaders: [
        {
          text: "Sr. No.",
          value: "srNo",
          align: "center",
          sortable: false,
        },
        {
          text: "Field Name",
          value: "fieldName",
          align: "center",
          sortable: false,
        },
        {
          text: "Old Value",
          value: "oldValue",
          align: "center",
          sortable: false,
        },
        {
          text: "New Value",
          value: "newValue",
          align: "center",
          sortable: false,
        },
      ],
      selectedItem: {},
    }
  },
  computed:{
    pageEndIndex() {
                return this.pageStartIndex + this.pageRowCount <= this.totalListCount? this.pageStartIndex + this.pageRowCount: this.totalListCount;
            },
    AuditPageEndIndex() {
      if (!this.audit_Data.length) {
        return 0
      } else {
        return this.AuditPageStartIndex + this.AuditItemsPerPage <=
          this.AuditTotalListCount
          ? this.AuditPageStartIndex + this.AuditItemsPerPage - 1
          : this.AuditTotalListCount
      }
    },
  },
  methods: {
    handleReset(popup = false) {
      if(popup) {
        this.showConfirm('Confirmation', 'Are you sure want to reset?', 'Yes', 'No', () => {
          this.searchData = { ...this.initialSearchData }
        })
      } else {
        this.searchData = { ...this.initialSearchData }
      }
    },
    closeChip(){
      this.handleReset();
      this.showSearchChip = false
      this.searchGSTData();
    },
    seeModify(item) {
      console.log(item.modification)
      this.seeModifyDialog = true
      this.modifyItems = item.modification
    },
    formatDateTime(dateTime) {
      return dateTime ? moment(dateTime).format("DD-MM-YYYY LT") : ""
    },
    getAuditData(newPageNum = 1, id, bool) {
      let reqData = ""
      // if (bool) {
      //   reqData =
      //     "perPage=10" +
      //     "&pageNo=" +
      //     newPageNum +
      //     "&masterType=gst" 
      // } else {
        reqData =
          "perPage=10" +
          "&pageNo=" +
          newPageNum +
          "&masterId=" + this.selectedItem.uid +
          "&masterType=gst"
      // }

      this.GETM(
        "getAudits",
        reqData,
        (res) => {
          if (res.data.data.data.length > 0) {
            console.log("AUDIT", res.data)
            this.audit_Data = res.data.data.data
            this.AuditTotalListCount = res.data.data.total
            this.AuditPageCount = res.data.data.total_pages
            this.AuditPageStartIndex =
              newPageNum == 1
                ? 1
                : (newPageNum - 1) * this.AuditItemsPerPage + 1
          } else {
            this.AuditPageStartIndex = 0
          }
        },
        () => {},
        true
      )
    },
    editmaster(link, index, item, title) {
      if (link === "/auditTrail") {
        // this.seeAuditTrail(item);
        this.audit_dialog = true
        console.log("1234",item)
        this.selectedItem = item
        this.getAuditData(1, item.uid, false)
      } else if (link !== "auditTrail") {
        if(this.checkPermission('/master/create-gst')){
          return this.showAlert("You don't have permission to create or modify record.")
        }
        let viewModifyObj = { viewMode: true, item: item }
        let modifyObj = { modifyMode: true }
        viewModifyObj.viewMode = link == "/viewpage" ? true : false
        modifyObj.modifyMode = link == "/modifypage" ? true : false

        this.$router.push({
          name: "CreateGSTMaster",
          params: {
            viewMode: link == "/viewpage" ? true : false,
            item: item,
            isView: link == "/viewpage" ? true : false,
            modifyMode: link == "/modifypage" ? true : false,
            // fromApproval:false,
          },
        })
      }
    },
    handleSearch() {
      this.showSearchChip = true
      this.searchGSTData()
    },
    handleCreateGST() {
      if(this.checkPermission('/master/create-gst')){
          return this.showAlert("You don't have permission to create or modify record.")
      }
      this.$router.push("/master/create-gst")
    },
    formatDate(date) {
      return date == null ? "" : moment(date).format('DD-MMM-YYYY');
    },
    endDateFormat(date){
      return date == null ? "" : moment(date).format('DD MMM YYYY');
    },
    searchGSTData(){

      // let reqData = `taxRate=${this.searchData.taxRate}
      // &active=${this.searchData.active}
      // &approveStatus=${this.searchData.approveStatus}
      // &page=${this.pageNumber}&pageLimit=10`;

      let reqData = `&page=${this.pageNumber}&pageLimit=10`;

      if(this.searchData.taxRate || this.searchData.approveStatus || this.searchData.active === true || this.searchData.active === false){
        if(this.searchData.taxRate){
          reqData= reqData + "&taxRate=" + this.searchData.taxRate;
        }
        if(this.searchData.approveStatus){
          reqData= reqData + "&approveStatus=" + this.searchData.approveStatus;
        }
        if(this.searchData.active){
          reqData= reqData + "&active=" + this.searchData.active;
        }else if(this.searchData.active === false){
          reqData= reqData + "&active=" + this.searchData.active;
        }
      }else{
          reqData = `&page=${this.pageNumber}&pageLimit=10`
      }

      this.showProgress();
      this.GETM("GET_GST_MASTER", reqData, (res) => {
          this.hideProgress();
          if(res.data.statusCode === 200) {
            (res.data.data.data)?
              this.gstTableData = res.data.data.data:this.gstTableData = []
              // this.gstTableData = res.data.data.data;
              this.pageRowCount = 10;
              this.totalListCount = res.data.data.total;

              this.totalPages = Math.ceil(res.data.data.total / this.pageRowCount);
              this.pageStartIndex = this.pageNumber == 1 ? 0 : (this.pageNumber - 1) * this.pageRowCount;
          } else {
              this.gstTableData=[]
              this.pageRowCount = 0;
              this.totalListCount = 0;
              this.totalPages = 0;
              this.pageNumber=1
              this.pageStartIndex =0;
              this.showAlert(res.data.msg);
          }
      }, (error) => {
          console.log(error);
          this.hideProgress();
      })
    }
  },
  created() {
    console.log("hit")
    this.$store.commit("SAVE_PAGE_HEADER", "GST Master");
    this.searchData = { ...this.initialSearchData }
    this.searchGSTData()
  },
}
</script>

<style lang="scss" scoped>
.btnToggle {
  text-transform: none;
  color: #fff;
  background-color: #1ebbd9;
}
.iconsize {
  font-size: 16px !important;
  font-weight: bold;
}
.tablebtn {
  background: #ffffff 0% 0% no-repeat padding-box !important;
  box-shadow: inset 0px 2px 4px #00000052 !important;
  border: 0.5px solid #c1c8cc;
  border-radius: 18px !important;
  height: 30px !important;
  text-transform: capitalize !important;
  width: 93%;
  justify-content: space-between;
  color: black !important;
  font-weight: 400 !important;
}
.tableicons {
  font-size: 18px !important;
  color: #23b1a9 !important;
}
</style>
