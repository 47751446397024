<template>
  <div>
    <ClaimDetailsVue :claimData="claimDetails"></ClaimDetailsVue>
    <div class="ma-5">
      <p class="font-weight-bold" v-if="!litigationDetails.litigationId">
        New Petition
      </p>
      <p class="font-weight-bold" v-else>
        {{ viewOnly ? "View" : "Modify" }} Petition
      </p>

      <v-form ref="litigationref" @submit.prevent>
        <!-- Claim details  -->
        <v-row class="groupRowHeadNewLable ma-0 mt-2">
          <v-col align="left" class="pt-2">
            <v-flex class="ml-0 GroupNameNewLable">Claim Details</v-flex>
          </v-col>
          <v-col align="right" class="pt-1 mt-0">
            <v-icon
              class="mr-2 viconfont"
              dark
              @click="claimdetailsSection = !claimdetailsSection"
            >
              {{
                claimdetailsSection == true ? "mdi-minus-box" : "mdi-plus-box"
              }}
            </v-icon>
          </v-col>
        </v-row>
        <div class="borderStyle" v-if="claimdetailsSection">
          <v-row no-gutters class="mt-2">
            <v-col class="ml-3 mr-3">
              <p class="claimlabel mb-2">
                LOB <span :class="viewOnly ? '' : 'mandatorycolor'">*</span>
              </p>
              <v-select
                :label="viewOnly ? '' : 'Select'"
                v-model="litigationDetails.lobType"
                :items="lobTypeOption"
                :rules="[mandatoryrule()]"
                solo
                dense
                disabled
                background-color="#f5f5f5"
                class="forminput textDisable font-weight-bold"
                clearable
              ></v-select>
            </v-col>
            <v-col class="mr-3">
              <p class="claimlabel mb-2">
                Claim Number
                <span :class="viewOnly ? '' : 'mandatorycolor'">*</span>
              </p>
              <v-text-field
                :placeholder="viewOnly ? '' : 'Enter'"
                v-model="litigationDetails.claimNumber"
                :rules="[mandatoryrule()]"
                solo
                dense
                disabled
                background-color="#f5f5f5"
                class="forminput textDisable font-weight-bold"
                clearable
              ></v-text-field>
            </v-col>
            <v-col class="mr-3">
              <p class="claimlabel mb-2">
                Feature Number
                <span :class="viewOnly ? '' : 'mandatorycolor'">*</span>
              </p>
              <v-text-field
                :placeholder="viewOnly ? '' : 'Enter'"
                v-model="litigationDetails.featureNumber"
                :rules="[mandatoryrule()]"
                solo
                dense
                disabled
                background-color="#f5f5f5"
                class="forminput textDisable font-weight-bold"
                clearable
              ></v-text-field>
            </v-col>
            <v-col class="mr-3">
              <p class="claimlabel mb-2">Insured Name</p>
              <v-text-field
                :placeholder="viewOnly ? '' : 'Enter'"
                v-model="litigationDetails.insuredName"
                solo
                dense
                disabled
                :background-color="viewOnly ? '#f5f5f5' : '#ffffff'"
                class="forminput textDisable font-weight-bold"
                clearable
              ></v-text-field>
            </v-col>
            <v-col class="mr-3">
              <p class="claimlabel mb-2">
                Date of Loss
                <span :class="viewOnly ? '' : 'mandatorycolor'">*</span>
              </p>
              <v-menu
                :close-on-content-click="false"
                offset-y
                v-model="dateOfLossMenu"
                min-width="auto"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    :placeholder="viewOnly ? '' : 'DD/MM/YYYY'"
                    v-model="computedLossDate"
                    dense
                    solo
                    readonly
                    v-on="on"
                    disabled
                    :background-color="viewOnly ? '#f5f5f5' : '#ffffff'"
                    class="forminput textDisable font-weight-bold"
                  >
                    <template v-slot:prepend-inner class="mx-0 my-0">
                      <v-icon class="iconstyle">mdi-calendar</v-icon>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="litigationDetails.lossDate"
                  no-title
                  @input="dateOfLossMenu = false"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row no-gutters class="mt-2">
            <v-col class="ml-3 mr-3">
              <p class="claimlabel mb-2">Policy Number</p>
              <v-text-field
                :placeholder="viewOnly ? '' : 'Enter'"
                v-model="litigationDetails.policyNumber"
                solo
                dense
                disabled
                :background-color="viewOnly ? '#f5f5f5' : '#ffffff'"
                class="forminput textDisable font-weight-bold"
                clearable
              ></v-text-field>
            </v-col>
            <v-col class="mr-3">
              <p class="claimlabel mb-2">Claimant Name</p>
              <v-text-field
                :placeholder="viewOnly ? '' : 'Enter'"
                v-model="litigationDetails.claimantName"
                solo
                dense
                disabled
                :background-color="viewOnly ? '#f5f5f5' : '#ffffff'"
                class="forminput textDisable font-weight-bold"
                clearable
              ></v-text-field>
            </v-col>
            <v-col class="mr-3">
              <p class="claimlabel mb-2">Claim Reserve Amount (Real Time)</p>
              <v-text-field
                :placeholder="viewOnly ? '' : 'Enter'"
                v-model="litigationDetails.claimReserveAmount"
                v-on:keypress="numbersOnly"
                solo
                dense
                disabled
                :background-color="viewOnly ? '#f5f5f5' : '#ffffff'"
                class="forminput textDisable font-weight-bold"
                clearable
              ></v-text-field>
            </v-col>
            <!-- <v-col class="mr-3">
                        <p class="claimlabel mb-2">TAT (Real Time) </p>
                        <v-text-field
                            :placeholder="viewOnly ? '' : 'Enter'"
                            v-model="litigationDetails.TAT"
                            solo
                            dense
                            disabled
                            :background-color="viewOnly ? '#f5f5f5' : '#ffffff'" 
                            class="forminput textDisable font-weight-bold"
                            clearable
                        ></v-text-field>
                    </v-col> -->
            <v-col class="mr-3">
              <p class="claimlabel mb-2">Product Name</p>
              <v-text-field
                :placeholder="viewOnly ? '' : 'Enter'"
                v-model="litigationDetails.productName"
                solo
                dense
                disabled
                :background-color="viewOnly ? '#f5f5f5' : '#ffffff'"
                class="forminput textDisable font-weight-bold"
                clearable
              ></v-text-field>
            </v-col>
            <v-col class="mr-3">
              <p class="claimlabel mb-2">Coverage</p>
              <v-tooltip v-model="showCoverageTooltip" top>
                <template v-slot:activator="{ on, attrs }">
                  <div
                    @mouseenter="showCoverageTooltip = true"
                    @mouseleave="showCoverageTooltip = false"
                  >
                    <v-text-field
                      :placeholder="viewOnly ? '' : 'Enter'"
                      v-model="litigationDetails.coverage"
                      solo
                      dense
                      disabled
                      v-on="on"
                      v-bind="attrs"
                      :background-color="viewOnly ? '#f5f5f5' : '#ffffff'"
                      class="forminput textDisable font-weight-bold"
                      clearable
                    ></v-text-field>
                  </div>
                </template>
                <span>{{ litigationDetails.coverage }}</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </div>
        <!-- Petition Details  -->
        <v-row class="groupRowHeadNewLable ma-0 mt-2">
          <v-col align="left" class="pt-2">
            <v-flex class="ml-0 GroupNameNewLable">Petition Details</v-flex>
          </v-col>
          <v-col align="right" class="pt-1 mt-0">
            <v-icon
              class="mr-2 viconfont"
              dark
              @click="petitionDetailsSection = !petitionDetailsSection"
            >
              {{
                petitionDetailsSection == true
                  ? "mdi-minus-box"
                  : "mdi-plus-box"
              }}
            </v-icon>
          </v-col>
        </v-row>
        <div class="borderStyle" v-if="petitionDetailsSection">
          <v-data-table
            :headers="petitionHeaders"
            :items="petitiondetailsData"
            hide-default-footer
            class="vDataTableStyling mx-3 mb-2"
            fixed-header
            no-data-text="No Records for Petition Details"
          >
            <template v-slot:[`item.actions`]="{ item, index }">
              <div class="text-center">
                <v-menu bottom offset-y rounded="0" nudge-left="0">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon class="mr-2 menusize" v-bind="attrs" v-on="on"
                      >mdi-dots-vertical</v-icon
                    >
                  </template>

                  <v-list>
                    <v-list-item
                      style="min-height: 28px !important"
                      v-for="(item1, index1) in menuItems"
                      :key="index1"
                      dense
                      :color="item1.color"
                      class="ma-0 pa-0"
                      :disabled="
                        viewOnly
                          ? item1.title !== 'VIEW'
                            ? true
                            : false
                          : false
                      "
                      @click="modify(item, index1, index)"
                    >
                      <v-icon
                        small
                        class="pa-2 pt-2 iconsize"
                        :color="item1.color"
                        >{{ item1.icon }}</v-icon
                      >
                      <v-divider vertical class="mx-1"></v-divider>
                      <v-list-item-title
                        class="pa-1 pr-3 ma-0 mr-0"
                        :color="item1.color"
                        style="font-size: 12px !important"
                        >{{ item1.title }}</v-list-item-title
                      >
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </template>
          </v-data-table>
          <v-row class="mt-2" v-if="!viewOnly">
            <v-col align="center">
              <v-btn solo dense elevation="0" :disabled="litigationStatusShow" @click="addRow()" >
              
                <v-icon class="iconsize" color="#23B1A9">mdi-plus</v-icon>
                <span class="iconsize ml-3 addrow">Add</span>
              </v-btn>
            </v-col>
          </v-row>
        </div>
        <!-- Award Heads  -->
        <v-row class="groupRowHeadNewLable ma-0 mt-2">
          <v-col align="left" class="pt-2">
            <v-flex class="ml-0 GroupNameNewLable">Award Heads</v-flex>
          </v-col>
          <v-col align="right" class="pt-1 mt-0">
            <v-icon
              class="mr-2 viconfont"
              dark
              @click="petitionDataSection = !petitionDataSection"
            >
              {{
                petitionDataSection == true ? "mdi-minus-box" : "mdi-plus-box"
              }}
            </v-icon>
          </v-col>
        </v-row>
        <div class="borderStyle" v-if="petitionDataSection">
          <v-row no-gutters class="mt-2">
            <!-- <v-col class="ml-3 mr-3">
                        <p class="claimlabel mb-2">Offer Amount</p>
                        <v-text-field
                            :placeholder="viewOnly ? '' : 'Enter'"
                            v-model="litigationDetails.offerAmount"
                            v-on:keypress="numbersOnly" 
                            solo
                            dense
                            :disabled="viewOnly" 
                            :background-color="viewOnly ? '#f5f5f5' : '#ffffff'" 
                            :class="viewOnly ? 'forminput textDisable font-weight-bold' : 'forminput'"
                        ></v-text-field>
                    </v-col> -->
            <v-col class="ml-3 mr-3">
              <p class="claimlabel mb-2">Offer Date</p>
              <v-menu
                :close-on-content-click="false"
                offset-y
                v-model="offerDateStatus"
                min-width="auto"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    :placeholder="viewOnly ? '' : 'DD/MM/YYYY'"
                    v-model="computedOfferDate"
                    dense
                    solo
                    readonly
                    v-on="on"
                    :disabled="viewOnly"
                    :background-color="viewOnly ? '#f5f5f5' : '#ffffff'"
                    :class="
                      viewOnly
                        ? 'forminput textDisable font-weight-bold'
                        : 'forminput'
                    "
                  >
                    <template v-slot:prepend-inner class="mx-0 my-0">
                      <v-icon class="iconstyle">mdi-calendar</v-icon>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="litigationDetails.dateOfOffer"
                  no-title
                  @input="offerDateStatus = false"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col class="mr-3">
              <p class="claimlabel mb-2">
                Order Amount
                <span
                  :class="viewOnly ? '' : 'mandatorycolor'"
                  v-if="litigationDetails.disposition"
                  >*</span
                >
              </p>
              <v-text-field
                :placeholder="viewOnly ? '' : 'Enter'"
                v-model="litigationDetails.orderAmount"
                v-on:keypress="numbersOnly"
                @keyup="calculateTotalAwardAmount()"
                solo
                dense
                :rules="litigationDetails.disposition ? [mandatoryrule()] : []"
                :disabled="viewOnly"
                :background-color="viewOnly ? '#f5f5f5' : '#ffffff'"
                :class="
                  viewOnly
                    ? 'forminput textDisable font-weight-bold'
                    : 'forminput'
                "
              ></v-text-field>
            </v-col>
            <v-col class="mr-3">
              <p class="claimlabel mb-2">
                Rate of interest Awarded
                
              </p>
              <v-text-field
                :placeholder="viewOnly ? '' : 'Enter'"
                v-model="litigationDetails.rateOfInterestAwarded"
                v-on:keypress="numbersOnly"
                @blur="calculateRateOfInterest()"
                solo
                dense
                :maxlength="50"
                :disabled="viewOnly"
                :background-color="viewOnly ? '#f5f5f5' : '#ffffff'"
                :class="
                  viewOnly
                    ? 'forminput textDisable font-weight-bold'
                    : 'forminput'
                "
              ></v-text-field>
            </v-col>
            <v-col class="mr-3">
              <p class="claimlabel mb-2">
                Interest Awarded
                <span
                  :class="viewOnly ? '' : 'mandatorycolor'"
                  v-if="litigationDetails.disposition"
                  >*</span
                >
              </p>
              <v-text-field
                :placeholder="viewOnly ? '' : 'Enter'"
                v-model="litigationDetails.interestAwarded"
                v-on:keypress="numbersOnly"
                @keyup="calculateTotalAwardAmount()"
                solo
                dense
                :rules="litigationDetails.disposition ? [mandatoryrule()] : []"
                :disabled="viewOnly"
                :background-color="viewOnly ? '#f5f5f5' : '#ffffff'"
                :class="
                  viewOnly
                    ? 'forminput textDisable font-weight-bold'
                    : 'forminput'
                "
              ></v-text-field>
            </v-col>
            <v-col class="mr-3">
              <p class="claimlabel mb-2">
                Cost Awarded
                <span
                  :class="viewOnly ? '' : 'mandatorycolor'"
                  v-if="litigationDetails.disposition"
                  >*</span
                >
              </p>
              <v-text-field
                :placeholder="viewOnly ? '' : 'Enter'"
                v-model="litigationDetails.costAwarded"
                v-on:keypress="numbersOnly"
                @keyup="calculateTotalAwardAmount()"
                :rules="litigationDetails.disposition ? [mandatoryrule()] : []"
                solo
                dense
                :disabled="viewOnly"
                :background-color="viewOnly ? '#f5f5f5' : '#ffffff'"
                :class="
                  viewOnly
                    ? 'forminput textDisable font-weight-bold'
                    : 'forminput'
                "
              ></v-text-field>
            </v-col>
            
          </v-row>
          <v-row no-gutters>
            <v-col class="ml-3 mr-3">
              <p class="claimlabel mb-2">
                Total Award Amount
                <span
                  :class="viewOnly ? '' : 'mandatorycolor'"
                  v-if="litigationDetails.disposition"
                  >*</span
                >
              </p>
              <v-text-field
                :placeholder="viewOnly ? '' : 'Enter'"
                v-model="litigationDetails.totalAwardAmount"
                v-on:keypress="numbersOnly"
                solo
                dense
                :rules="litigationDetails.disposition ? [mandatoryrule()] : []"
                :disabled="viewOnly || litigationStatusShow"
                :background-color="viewOnly || litigationStatusShow ? '#f5f5f5' : '#ffffff'"
                :class="
                  viewOnly || litigationStatusShow
                    ? 'forminput textDisable font-weight-bold'
                    : 'forminput'
                "
              ></v-text-field>
            </v-col>
            <v-col class="mr-3">
              <p class="claimlabel mb-2">
                Disposition
                <span :class="viewOnly ? '' : 'mandatorycolor'" v-if="!litigationStatusShow">*</span>
              </p>
              <v-text-field :label="viewOnly ? '' : 'Select'"
              v-if="!litigationDetails.disposition.includes(['In favor of Company','Against Company','Conditional Order','Dismissed on Default or Withdrawn','Settled or Compromised','Compromised After Award','']) && !dispositionField"
                v-model="litigationDetails.disposition" solo
                dense
                @click="dispositionField =! dispositionField"
                :disabled="viewOnly || litigationStatusShow"
                :background-color="viewOnly || litigationStatusShow ? '#f5f5f5' : '#ffffff'"
                :class="
                  viewOnly || litigationStatusShow
                    ? 'forminput textDisable font-weight-bold'
                    : 'forminput'
                "></v-text-field>
              <v-select
              v-else
                :label="viewOnly ? '' : 'Select'"
                v-model="litigationDetails.disposition"
                :items="dispositionOption"
                :rules="!litigationStatusShow?[mandatoryrule()]:[]"
                @blur="checkingDisposition"
                solo
                dense
                :disabled="viewOnly || litigationStatusShow"
                :background-color="viewOnly||litigationStatusShow ? '#f5f5f5' : '#ffffff'"
                :class="
                  viewOnly||litigationStatusShow
                    ? 'forminput textDisable font-weight-bold'
                    : 'forminput'
                "
                
              ></v-select>
            </v-col>
            <v-col class="mr-3">
              <p class="claimlabel mb-2">
                Date Of Receipt Of Order By Advocate
                <span
                  :class="viewOnly ? '' : 'mandatorycolor'"
                  v-if="litigationDetails.disposition"
                  >*</span
                >
              </p>
              <v-menu
                :close-on-content-click="false"
                offset-y
                v-model="receiptOrderDateByAdvocateStatus"
                min-width="auto"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    :placeholder="viewOnly ? '' : 'DD/MM/YYYY'"
                    v-model="computedReceiptOrderDateByAdvocate"
                    dense
                    solo
                    readonly
                    v-on="on"
                    :rules="
                      litigationDetails.disposition ? [mandatoryrule()] : []
                    "
                    :disabled="viewOnly"
                    :background-color="viewOnly ? '#f5f5f5' : '#ffffff'"
                    :class="
                      viewOnly
                        ? 'forminput textDisable font-weight-bold'
                        : 'forminput'
                    "
                  >
                    <template v-slot:prepend-inner class="mx-0 my-0">
                      <v-icon class="iconstyle">mdi-calendar</v-icon>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="litigationDetails.receiptOrderAdvocateDate"
                  no-title
                  @input="receiptOrderDateByAdvocateStatus = false"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col class="mr-3">
              <p class="claimlabel mb-2">
                Date Of Receipt Of Order By TAGIC
                <span
                  :class="viewOnly ? '' : 'mandatorycolor'"
                  v-if="litigationDetails.disposition"
                  >*</span
                >
              </p>
              <v-menu
                :close-on-content-click="false"
                offset-y
                v-model="receiptOrderDateByTagicStatus"
                min-width="auto"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    :placeholder="viewOnly ? '' : 'DD/MM/YYYY'"
                    v-model="computedReceiptOrderDateByTagic"
                    dense
                    solo
                    readonly
                    v-on="on"
                    :rules="
                      litigationDetails.disposition ? [mandatoryrule()] : []
                    "
                    :disabled="viewOnly"
                    :background-color="viewOnly ? '#f5f5f5' : '#ffffff'"
                    :class="
                      viewOnly
                        ? 'forminput textDisable font-weight-bold'
                        : 'forminput'
                    "
                  >
                    <template v-slot:prepend-inner class="mx-0 my-0">
                      <v-icon class="iconstyle">mdi-calendar</v-icon>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="litigationDetails.receiptOrderTAGICDate"
                  no-title
                  @input="receiptOrderDateByTagicStatus = false"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col class="mr-3">
              <p class="claimlabel mb-2">
                Disposition Amount
                <span :class="viewOnly ? '' : 'mandatorycolor'">*</span>
              </p>
              <v-text-field
                :placeholder="viewOnly ? '' : 'Enter'"
                v-model="litigationDetails.dispositionAmount"
                v-on:keypress="numbersOnly"
                :rules="[mandatoryrule()]"
                solo
                dense
                :disabled="viewOnly"
                :background-color="viewOnly ? '#f5f5f5' : '#ffffff'"
                :class="
                  viewOnly
                    ? 'forminput textDisable font-weight-bold'
                    : 'forminput'
                "
              ></v-text-field>
            </v-col>
            
          </v-row>
          <v-row no-gutters>
            <!-- <v-col class="mr-3">
                        <p class="claimlabel mb-2">Judgement Value</p>
                        <v-text-field
                            :placeholder="viewOnly ? '' : 'Enter'"
                            v-model="litigationDetails.judgementValue"
                            solo
                            dense
                            :disabled="viewOnly" 
                            :background-color="viewOnly ? '#f5f5f5' : '#ffffff'" 
                            :class="viewOnly ? 'forminput textDisable font-weight-bold' : 'forminput'"
                        ></v-text-field>
                    </v-col>
                    <v-col class="mr-3">
                        <v-checkbox
                            label="Is Order XXI Completed?"
                            v-model="litigationDetails.isOrderXxiCompleted"
                            solo
                            dense
                            class="form-checkbox-group checkboxmargin"
                            :disabled="viewOnly" 
                            :class="viewOnly ? 'forminput textDisable font-weight-bold' : 'forminput'"
                        ></v-checkbox>
                    </v-col>
                    <v-col class="mr-3">
                        <p class="claimlabel mb-2">Compliance Date</p>
                        <v-menu
                            :close-on-content-click="false"
                            offset-y
                            v-model="complianceDateMenu"
                            min-width="auto">
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    :placeholder="viewOnly ? '' : 'DD/MM/YYYY'"
                                    v-model="computedComplianceDate"
                                    dense
                                    solo
                                    readonly
                                    v-on="on"
                                    :disabled="viewOnly" 
                                    :background-color="viewOnly ? '#f5f5f5' : '#ffffff'" 
                                    :class="viewOnly ? 'forminput textDisable font-weight-bold' : 'forminput'"
                                >
                                    <template v-slot:prepend-inner class="mx-0 my-0">
                                    <v-icon class="iconstyle" >mdi-calendar</v-icon>
                                    </template>
                                </v-text-field>
                            </template>
                            <v-date-picker        
                                v-model="litigationDetails.complianceDate"      
                                no-title        
                                @input="complianceDateMenu = false">
                            </v-date-picker>
                        </v-menu>
                    </v-col> -->
                    <v-col class="ml-3 mr-3">
              <p class="claimlabel mb-2">
                Disposition Date
                <span :class="viewOnly ? '' : 'mandatorycolor'">*</span>
              </p>
              <v-menu
                :close-on-content-click="false"
                offset-y
                v-model="dispositionDateMenu"
                min-width="auto"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    :placeholder="viewOnly ? '' : 'DD/MM/YYYY'"
                    v-model="computedDispositionDate"
                    :rules="[mandatoryrule()]"
                    dense
                    solo
                    readonly
                    v-on="on"
                    :disabled="viewOnly"
                    :background-color="viewOnly ? '#f5f5f5' : '#ffffff'"
                    :class="
                      viewOnly
                        ? 'forminput textDisable font-weight-bold'
                        : 'forminput'
                    "
                  >
                    <template v-slot:prepend-inner class="mx-0 my-0">
                      <v-icon class="iconstyle">mdi-calendar</v-icon>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="litigationDetails.dispositionDate"
                  no-title
                  @input="dispositionDateMenu = false"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
                    <v-col class="ml-3 mr-3">
              <p class="claimlabel mb-2">Date Deposit</p>
              <v-menu
                :close-on-content-click="false"
                offset-y
                v-model="depositeDateStatus"
                min-width="auto"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    :placeholder="viewOnly ? '' : 'DD/MM/YYYY'"
                    v-model="computedDepositeDate"
                    dense
                    solo
                    readonly
                    v-on="on"
                    :disabled="viewOnly"
                    :background-color="viewOnly ? '#f5f5f5' : '#ffffff'"
                    :class="
                      viewOnly
                        ? 'forminput textDisable font-weight-bold'
                        : 'forminput'
                    "
                  >
                    <template v-slot:prepend-inner class="mx-0 my-0">
                      <v-icon class="iconstyle">mdi-calendar</v-icon>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="litigationDetails.dateDeposit"
                  no-title
                  @input="depositeDateStatus = false"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col class="mr-3" >
              <p class="claimlabel mb-2">
                Interest from
                
              </p>
              <v-select
                :placeholder="viewOnly ? '' : 'Enter'"
                v-model="litigationDetails.interestFrom"
                v-on:keypress="onlyAlphaNumeric"
                solo
                dense
                :items="interestFormOptions"
                :disabled="viewOnly"
                :background-color="viewOnly ? '#f5f5f5' : '#ffffff'"
                :class="
                  viewOnly
                    ? 'forminput textDisable font-weight-bold'
                    : 'forminput'
                "
              ></v-select>
            </v-col>
            <v-col class="mr-3" >
              <p class="claimlabel mb-2">
                Interest From Date
                
              </p>
              <v-menu
                :close-on-content-click="false"
                offset-y
                v-model="interestFromDate"
                min-width="auto"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    :placeholder="viewOnly ? '' : 'DD/MM/YYYY'"
                    v-model="computedepInterestFromDate"
                    
                    dense
                    solo
                    readonly
                    v-on="on"
                    :disabled="viewOnly"
                    :background-color="viewOnly ? '#f5f5f5' : '#ffffff'"
                    :class="
                      viewOnly
                        ? 'forminput textDisable font-weight-bold'
                        : 'forminput'
                    "
                  >
                    <template v-slot:prepend-inner class="mx-0 my-0">
                      <v-icon class="iconstyle">mdi-calendar</v-icon>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                @change="calculateDate"
                  v-model="litigationDetails.interestFromDate"
                  no-title
                  @input="interestFromDate = false"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col class="mr-3" >
              <p class="claimlabel mb-2">
                Interest To Date
              </p>
              <v-menu
                :close-on-content-click="false"
                offset-y
                v-model="interestToDate"
                min-width="auto"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    :placeholder="viewOnly ? '' : 'DD/MM/YYYY'"
                    v-model="computedepInterestToDate"
                    
                    dense
                    solo
                    readonly
                    v-on="on"
                    :disabled="viewOnly"
                    :background-color="viewOnly ? '#f5f5f5' : '#ffffff'"
                    :class="
                      viewOnly
                        ? 'forminput textDisable font-weight-bold'
                        : 'forminput'
                    "
                  >
                    <template v-slot:prepend-inner class="mx-0 my-0">
                      <v-icon class="iconstyle">mdi-calendar</v-icon>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                @change="calculateDate"
                :min="litigationDetails.interestFromDate"
                  v-model="litigationDetails.interestToDate"
                  no-title
                  @input="interestToDate = false"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
            <!-- <v-col class="mr-3">
                        <p class="claimlabel mb-2">Penalty Amount</p>
                        <v-text-field
                            :placeholder="viewOnly ? '' : 'Enter'"
                            v-model="litigationDetails.penaltyAmount"
                            v-on:keypress="numbersOnly" 
                            solo
                            dense
                            :disabled="viewOnly" 
                            :background-color="viewOnly ? '#f5f5f5' : '#ffffff'" 
                            :class="viewOnly ? 'forminput textDisable font-weight-bold' : 'forminput'"
                        ></v-text-field>
                    </v-col>
                        <v-col class="mr-3">
                        <p class="claimlabel mb-2">Expenses</p>
                        <v-text-field
                            v-model="litigationDetails.expenses"
                            v-on:keypress="numbersOnly" 
                            solo
                            dense
                            disabled 
                            background-color= '#f5f5f5'  
                            class= 'forminput textDisable font-weight-bold'
                            :value='calculateExpenses'
                        ></v-text-field>
                    </v-col> -->
          </v-row>
          <v-row no-gutters>
            <v-col class="ml-3 mr-3" >
              <p class="claimlabel mb-2">
                Number of days
              </p>
              <v-text-field
                :placeholder="viewOnly ? '' : 'Enter'"
                v-model="litigationDetails.numberOfDays"
                v-on:keypress="onlyAlphaNumeric"
                solo
                dense
                
                disabled
                background-color="#f5f5f5"
                class="forminput textDisable font-weight-bold"
              ></v-text-field>
            </v-col>
            <v-col class="ml-3 mr-3">
              <p class="claimlabel mb-2">
                Is Execution Filed
                <span :class="viewOnly ? '' : 'mandatorycolor'">*</span>
              </p>
              <v-checkbox
                label="Is Execution Filed"
                v-model="litigationDetails.isExecutionFiled"
                solo
                dense
                class="form-checkbox-group"
                :disabled="viewOnly"
                :class="
                  viewOnly
                    ? 'forminput textDisable font-weight-bold'
                    : 'forminput'
                "
              ></v-checkbox>
            </v-col>
            <v-col class="mr-3" v-if="litigationDetails.isExecutionFiled">
              <p class="claimlabel mb-2">
                EP Number
                <span :class="viewOnly ? '' : 'mandatorycolor'">*</span>
              </p>
              <v-text-field
                :placeholder="viewOnly ? '' : 'Enter'"
                v-model="litigationDetails.epNumber"
                v-on:keypress="onlyAlphaNumeric"
                solo
                dense
                :rules="[mandatoryrule()]"
                :disabled="viewOnly"
                :background-color="viewOnly ? '#f5f5f5' : '#ffffff'"
                :class="
                  viewOnly
                    ? 'forminput textDisable font-weight-bold'
                    : 'forminput'
                "
              ></v-text-field>
            </v-col>
            <v-col class="mr-3" v-else></v-col>
            <v-col class="mr-3" v-if="litigationDetails.isExecutionFiled">
              <p class="claimlabel mb-2">
                EP Year <span :class="viewOnly ? '' : 'mandatorycolor'">*</span>
              </p>
              <v-menu
                ref="menu1"
                v-model="menu1"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :placeholder="viewOnly ? '' : 'YYYY'"
                    v-model="litigationDetails.epYear"
                    solo
                    dense
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    :rules="[mandatoryrule()]"
                    :disabled="viewOnly"
                    :background-color="viewOnly ? '#f5f5f5' : '#ffffff'"
                    :class="
                      viewOnly
                        ? 'forminput textDisable font-weight-bold'
                        : 'forminput'
                    "
                    clearable
                  ></v-text-field>
                </template>
                <v-date-picker
                  ref="picker1"
                  v-model="date1"
                  :max="
                    new Date(
                      Date.now() - new Date().getTimezoneOffset() * 60000
                    )
                      .toISOString()
                      .substr(0, 10)
                  "
                  @input="save1"
                  reactive
                  no-title
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col class="mr-3" v-else></v-col>
            <v-col class="mr-3" v-if="litigationDetails.isExecutionFiled">
              <p class="claimlabel mb-2">
                Date of EP Hearing
                <span :class="viewOnly ? '' : 'mandatorycolor'">*</span>
              </p>
              <v-menu
                :close-on-content-click="false"
                offset-y
                v-model="epHearingDateMenu"
                min-width="auto"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    :placeholder="viewOnly ? '' : 'DD/MM/YYYY'"
                    v-model="computedepHearingDate"
                    :rules="[mandatoryrule()]"
                    dense
                    solo
                    readonly
                    v-on="on"
                    :disabled="viewOnly"
                    :background-color="viewOnly ? '#f5f5f5' : '#ffffff'"
                    :class="
                      viewOnly
                        ? 'forminput textDisable font-weight-bold'
                        : 'forminput'
                    "
                  >
                    <template v-slot:prepend-inner class="mx-0 my-0">
                      <v-icon class="iconstyle">mdi-calendar</v-icon>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="litigationDetails.epHearingDate"
                  no-title
                  @input="epHearingDateMenu = false"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col class="mr-3" v-else></v-col>
            
          </v-row>
          <v-row>
            <v-col class="ml-3 mr-3" v-if="litigationDetails.isExecutionFiled">
              <p class="claimlabel mb-2">
                Execution Closure
                
              </p>
              <v-select
                :label="viewOnly ? '' : 'Select'"
                v-model="litigationDetails.executionClosure"
                :items="executionClosureOption"
                solo
                dense
                
                :disabled="viewOnly"
                :background-color="viewOnly ? '#f5f5f5' : '#ffffff'"
                :class="
                  viewOnly
                    ? 'forminput textDisable font-weight-bold'
                    : 'forminput'
                "
                clearable
              ></v-select>
            </v-col>
            <v-col class="mr-3" v-else></v-col>
            <v-col class="mr-3"></v-col>
            <v-col class="mr-3"></v-col>
            <v-col class="mr-3"></v-col>
            <v-col class="mr-3"></v-col>
          </v-row>
        </div>

        <!-- Petition Data  -->
        <v-row class="groupRowHeadNewLable ma-0 mt-2">
          <v-col align="left" class="pt-2">
            <v-flex class="ml-0 GroupNameNewLable">Petition Data</v-flex>
          </v-col>
          <v-col align="right" class="pt-1 mt-0">
            <v-icon
              class="mr-2 viconfont"
              dark
              @click="petitionExecutionSection = !petitionExecutionSection"
            >
              {{
                petitionExecutionSection == true
                  ? "mdi-minus-box"
                  : "mdi-plus-box"
              }}
            </v-icon>
          </v-col>
        </v-row>
        <div class="borderStyle" v-if="petitionExecutionSection">
          <v-row no-gutters class="mt-2">
            <v-col class="ml-3 mr-3">
              <p class="claimlabel mb-2">
                Petition Amount
                
              </p>
              <v-text-field
                :placeholder="viewOnly ? '' : 'Select'"
                v-model="litigationDetails.petitionAmount"
                v-on:keypress="numbersOnly"
                
                solo
                dense
                :disabled="viewOnly"
                :background-color="viewOnly ? '#f5f5f5' : '#ffffff'"
                :class="
                  viewOnly
                    ? 'forminput textDisable font-weight-bold'
                    : 'forminput'
                "
                clearable
              ></v-text-field>
            </v-col>
            <v-col class="mr-3" v-if="litigationDetails.lobType!='Home'">
              <p class="claimlabel mb-2">Offer Amount</p>
              <v-text-field
                :placeholder="viewOnly ? '' : 'Select'"
                v-model="litigationDetails.offerAmount"
                v-on:keypress="numbersOnly"
                solo
                dense
                :disabled="viewOnly"
                :background-color="viewOnly ? '#f5f5f5' : '#ffffff'"
                :class="
                  viewOnly
                    ? 'forminput textDisable font-weight-bold'
                    : 'forminput'
                "
                clearable
              ></v-text-field>
            </v-col>
            <v-col class="mr-3">
              <p class="claimlabel mb-2">
                Grounds Of Defense
                
              </p>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <div multiple v-on="on">
                    <v-select
                      :label="viewOnly ? '' : 'Select'"
                      v-model="litigationDetails.groundsOfDefence"
                      :items="groundsOfDefenceOption"
                      solo
                      dense
                      v-bind="attrs"
                      
                      :background-color="viewOnly ? '#f5f5f5' : '#ffffff'"
                      :class="
                        viewOnly
                          ? 'forminput textDisable font-weight-bold'
                          : 'forminput'
                      "
                      clearable
                      multiple
                      class="selectstyle"
                    >
                      <template v-slot:prepend-item>
                        <v-list-item ripple @click="toggle">
                          <v-list-item-action>
                            <v-icon
                              :color="
                                litigationDetails.groundsOfDefence.length > 0
                                  ? 'indigo darken-4'
                                  : ''
                              "
                            >
                              {{ icon }}
                            </v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title> All </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider class="mt-2"></v-divider>
                      </template>
                      <template v-slot:selection="{ item, index }">
                        <span v-if="index === 0">{{
                          limitTextLength(item, 20)
                        }}</span>
                        <span
                          v-if="index === 1"
                          class="grey--text text-caption"
                        >
                          (+{{
                            litigationDetails.groundsOfDefence.length - 1
                          }}
                          others)
                        </span>
                      </template>
                    </v-select>
                  </div>
                </template>
                <span>{{ litigationDetails.groundsOfDefence + "" }}</span>
              </v-tooltip>
            </v-col>
            <v-col
              class="mr-3"
              v-if="
                litigationDetails.groundsOfDefence.includes(
                  'Others (mention reason)'
                )
              "
            >
              <p class="claimlabel mb-2">
                Reason <span :class="viewOnly ? '' : 'mandatorycolor'">*</span>
              </p>
              <v-text-field
                :placeholder="viewOnly ? '' : 'Select'"
                v-model="litigationDetails.groundsOfDefenceReason"
                v-on:keypress="onlyAlphaNumeric"
                solo
                dense
                :rules="[mandatoryrule()]"
                :disabled="viewOnly"
                :background-color="viewOnly ? '#f5f5f5' : '#ffffff'"
                :class="
                  viewOnly
                    ? 'forminput textDisable font-weight-bold'
                    : 'forminput'
                "
                clearable
              ></v-text-field>
            </v-col>
            <v-col class="mr-3" v-else></v-col>
            <v-col class="mr-3"></v-col>
          </v-row>
          <!-- <v-row no-gutters>
                    <p class="claimlabel mb-2 ml-3">Grounds of Defence</p>
                </v-row> -->
          <!-- <v-row class="mt-2 mb-3" no-gutters>
                    <v-col class="ml-3 mr-3">
                        <v-checkbox
                            label="Breach of Warranty"
                            v-model="litigationDetails.groundsOfDefence"
                            value="Breach of Warranty"
                            solo
                            dense
                            class="form-checkbox-group checkboxmargin"
                            :disabled="viewOnly" 
                            :background-color="viewOnly ? '#f5f5f5' : '#ffffff'" 
                            :class="viewOnly ? 'forminput textDisable font-weight-bold' : 'forminput'"
                        ></v-checkbox>
                    </v-col>
                    <v-col class="mr-3">
                        <v-checkbox
                            label="Fraud"
                            v-model="litigationDetails.groundsOfDefence"
                            value="Fraud"
                            solo
                            dense
                            class="form-checkbox-group checkboxmargin"
                            :disabled="viewOnly" 
                            :background-color="viewOnly ? '#f5f5f5' : '#ffffff'" 
                            :class="viewOnly ? 'forminput textDisable font-weight-bold' : 'forminput'"
                        ></v-checkbox>
                    </v-col>
                    <v-col class="mr-3">
                        <v-checkbox
                            label="Cheque Dishonour/Insufficient premium"
                            v-model="litigationDetails.groundsOfDefence"
                            value="Cheque Dishonour/Insufficient premium"
                            solo
                            dense
                            class="form-checkbox-group checkboxmargin"
                            :disabled="viewOnly" 
                            :background-color="viewOnly ? '#f5f5f5' : '#ffffff'" 
                            :class="viewOnly ? 'forminput textDisable font-weight-bold' : 'forminput'"
                        ></v-checkbox>
                    </v-col>
                    <v-col class="mr-3">
                        <v-checkbox
                            label="Loss Falls under exclusion"
                            v-model="litigationDetails.groundsOfDefence"
                            value="Loss Falls under exclusion"
                            solo
                            dense
                            class="form-checkbox-group checkboxmargin"
                            :disabled="viewOnly" 
                            :background-color="viewOnly ? '#f5f5f5' : '#ffffff'" 
                            :class="viewOnly ? 'forminput textDisable font-weight-bold' : 'forminput'"
                        ></v-checkbox>
                    </v-col>
                </v-row>
                <v-row class="mt-2 mb-3" no-gutters>
                    <v-col class="ml-3 mr-3">
                        <v-checkbox
                            label="Missing Coverage when claim was reported"
                            v-model="litigationDetails.groundsOfDefence"
                            value="Missing Coverage when claim was reported"
                            solo
                            dense
                            class="form-checkbox-group checkboxmargin"
                            :disabled="viewOnly" 
                            :background-color="viewOnly ? '#f5f5f5' : '#ffffff'" 
                            :class="viewOnly ? 'forminput textDisable font-weight-bold' : 'forminput'"
                        ></v-checkbox>
                    </v-col>
                    <v-col class="ml-3 mr-3">
                        <v-checkbox
                            label="Loss Location Not covered under policy"
                            v-model="litigationDetails.groundsOfDefence"
                            value="Loss Location Not covered under policy"
                            solo
                            dense
                            class="form-checkbox-group checkboxmargin"
                            :disabled="viewOnly" 
                            :background-color="viewOnly ? '#f5f5f5' : '#ffffff'" 
                            :class="viewOnly ? 'forminput textDisable font-weight-bold' : 'forminput'"
                        ></v-checkbox>
                    </v-col>
                    <v-col class="mr-3">
                        <v-checkbox
                            label="Others (mention reason)"
                            v-model="litigationDetails.groundsOfDefence"
                            value="Others (mention reason)"
                            solo
                            dense
                            class="form-checkbox-group checkboxmargin"
                            :disabled="viewOnly" 
                            :background-color="viewOnly ? '#f5f5f5' : '#ffffff'" 
                            :class="viewOnly ? 'forminput textDisable font-weight-bold' : 'forminput'"
                        ></v-checkbox>
                    </v-col>
                    <v-col class="mr-3" v-if="litigationDetails.groundsOfDefence.includes('Others (mention reason)')">
                        <p class="claimlabel mb-2">Reason <span :class="viewOnly ? '' : 'mandatorycolor'">*</span></p>
                        <v-text-field
                            :placeholder="viewOnly ? '' : 'Select'"
                            v-model="litigationDetails.groundsOfDefenceReason"
                            v-on:keypress="onlyAlphaNumeric" 
                            solo
                            dense
                            :rules="[mandatoryrule()]"
                            :disabled="viewOnly" 
                            :background-color="viewOnly ? '#f5f5f5' : '#ffffff'" 
                            :class="viewOnly ? 'forminput textDisable font-weight-bold' : 'forminput'"
                            clearable
                        ></v-text-field>
                    </v-col>
                    <v-col class="mr-3" v-else></v-col>
                </v-row> -->
        </div>
        <!-- buttons  -->
        <v-row class="mt-2">
          <v-col>
            <v-btn
              color="#152F38"
              dark
              class="btnstyle mr-3"
              @click="cancel()"
              v-if="viewOnly"
            >
              <v-icon class="mr-2 iconsize">mdi-arrow-left-circle</v-icon>
              <div class="vertical_line"></div>
              Back
            </v-btn>
            <v-btn
              outlined
              color="#D1121B"
              class="btnstyle mr-3"
              @click="cancel()"
              v-else
            >
              <v-icon class="mr-2 iconsize">mdi-close-box-outline</v-icon>
              <div class="vertical_line"></div>
              Cancel
            </v-btn>
            <v-btn
              outlined
              color="#D1121B"
              class="btnstyle mr-3"
              @click="reset()"
              v-if="!modify_show_btn"
            >
              <v-icon class="mr-2 iconsize">mdi-close-box-outline</v-icon>
              <div class="vertical_line"></div>
              Reset
            </v-btn>
          </v-col>
          <v-col align="right">
            <v-btn
              color="#23B1A9"
              dark
              class="btnstyle mr-3"
              type="submit"
              v-if="!viewOnly && !litigationDetails.isSubmitted"
              @click="submitPetition()"
            >
              <v-icon class="mr-2 iconsize">mdi-arrow-right-circle</v-icon>
              <div class="vertical_line"></div>
              Save
            </v-btn>
            <v-btn
              color="#23B1A9"
              dark
              class="btnstyle"
              type="submit"
              v-if=" !modify_show_btn"
              @click="submitPetition(true)"
            >
              <v-icon class="mr-2 iconsize">mdi-arrow-right-circle</v-icon>
              <div class="vertical_line"></div>
              Submit
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
      <!-- Petition details  -->
      <v-row justify="center">
        <v-dialog
          v-model="petitionDetailsDialog"
          persistent
          max-width="65%"
          style="border-radius: 0"
        >
          <v-card>
            <v-card-title
              class="pl-0 px-0 py-0 dailogboxAllvcardTableBackground"
            >
              <v-row class="colorbg">
                <span class="descrptionSpan mb-2 ml-8 mr-5"
                  >Petition Details</span
                >
                <v-icon
                  @click="petitionDetailsDialog = false"
                  class="ml-2 mr-2 mt-2"
                  color="#fff"
                  >mdi-window-close</v-icon
                >
              </v-row>
              <v-card-text class="vcardTextTableBackground pl-2">
                <v-form ref="petitionDetailsref" @submit.prevent="addTOTable()">
                  <v-row no-gutters class="mt-2">
                    <v-col class="ml-3 mr-3">
                      <p class="claimlabel mb-2">
                        Litigation Type
                        <span :class="viewOnly ? '' : 'mandatorycolor'">*</span>
                      </p>
                      <v-select
                        :label="viewOnly ? '' : 'Select'"
                        v-model="petitionDetailsObj.litigationType"
                        :items="litigationTypeOptions"
                        :rules="[mandatoryrule()]"
                        solo
                        dense
                        :disabled="viewOnly || viewPetition"
                        :background-color="
                          viewOnly || viewPetition ? '#f5f5f5' : '#ffffff'
                        "
                        :class="
                          viewOnly || viewPetition
                            ? 'forminput textDisable font-weight-bold'
                            : 'forminput'
                        "
                        clearable
                      ></v-select>
                    </v-col>
                    <v-col class="mr-3" cols="2">
                      <p class="claimlabel mb-2">
                        Case Number
                        <span :class="viewOnly ? '' : 'mandatorycolor'">*</span>
                      </p>
                      <v-text-field
                        :placeholder="viewOnly ? '' : 'Enter'"
                        v-model="petitionDetailsObj.caseNumber"
                        :rules="[mandatoryrule()]"
                        v-on:keypress="onlyAlphaNumeric"
                        solo
                        dense
                        :disabled="viewOnly || viewPetition"
                        :background-color="
                          viewOnly || viewPetition ? '#f5f5f5' : '#ffffff'
                        "
                        :class="
                          viewOnly || viewPetition
                            ? 'forminput textDisable font-weight-bold'
                            : 'forminput'
                        "
                        clearable
                      ></v-text-field>
                    </v-col>
                    <v-col class="mr-3" cols="2">
                      <p class="claimlabel mb-2">
                        Case Year
                        <span :class="viewOnly ? '' : 'mandatorycolor'">*</span>
                      </p>
                      <v-menu
                        ref="menu"
                        v-model="menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            :placeholder="viewOnly ? '' : 'YYYY'"
                            v-model="petitionDetailsObj.caseYear"
                            :rules="[mandatoryrule()]"
                            solo
                            dense
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            :disabled="viewOnly || viewPetition"
                            :background-color="
                              viewOnly || viewPetition ? '#f5f5f5' : '#ffffff'
                            "
                            :class="
                              viewOnly || viewPetition
                                ? 'forminput textDisable font-weight-bold'
                                : 'forminput'
                            "
                            clearable
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          ref="picker"
                          v-model="date"
                          :max="
                            new Date(
                              Date.now() -
                                new Date().getTimezoneOffset() * 60000
                            )
                              .toISOString()
                              .substr(0, 10)
                          "
                          :min="minCaseYear"
                          @input="save"
                          reactive
                          no-title
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col class="mr-3">
                      <p class="claimlabel mb-2">Case Title</p>
                      <v-tooltip v-model="showTooltip" top>
                        <template v-slot:activator="{ on, attrs }">
                          <div
                            @mouseenter="showTooltip = true"
                            @mouseleave="showTooltip = false"
                          >
                            <v-text-field
                              v-model="cpmputedCaseTitle"
                              solo
                              dense
                              disabled
                              v-on="on"
                              v-bind="attrs"
                              background-color="#f5f5f5"
                              class="forminput textDisable font-weight-bold"
                              clearable
                            ></v-text-field>
                          </div>
                        </template>
                        <span>{{ petitionDetailsObj.caseTitle }}</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col class="ml-3 mr-3" >
                      <p class="claimlabel mb-2">
                        Case Stage
                        <span :class="viewOnly ? '' : 'mandatorycolor'">*</span>
                      </p>
                      <v-select
                        :label="viewOnly ? '' : 'Enter...'"
                        v-model="petitionDetailsObj.caseStage"
                        :items="caseStageOptions"
                        :rules="[mandatoryrule()]"
                        solo
                        dense
                        :disabled="viewOnly || viewPetition"
                        :background-color="viewOnly ? '#f5f5f5' : '#ffffff'"
                        :class="
                          viewOnly
                            ? 'forminput textDisable font-weight-bold'
                            : 'forminput'
                        "
                        clearable
                      ></v-select>
                    </v-col>
                    <v-col class="mr-3" >
                      <p class="claimlabel mb-2">
                        CNR Number
                        
                      </p>
                      <v-text-field
                        :label="viewOnly ? '' : 'Enter...'"
                        v-model="petitionDetailsObj.cnrNumber"
                        :maxlength="50"
                        
                        :rules="[(v)=>matchRegex(v,'alphanum')]"
                        solo
                        dense
                        :disabled="viewOnly || viewPetition"
                        :background-color="viewOnly ? '#f5f5f5' : '#ffffff'"
                        :class="
                          viewOnly
                            ? 'forminput textDisable font-weight-bold'
                            : 'forminput'
                        "
                        clearable
                      ></v-text-field>
                    </v-col>
                    <v-col class="mr-3">
                      <p class="claimlabel mb-2">
                        Date Of Filing Of Petition
                        <span :class="viewOnly ? '' : 'mandatorycolor'">*</span>
                      </p>
                      <v-menu
                        :close-on-content-click="false"
                        offset-y
                        v-model="petitionFiledDateStatus"
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            :placeholder="viewOnly ? '' : 'DD/MM/YYYY'"
                            v-model="computedPetitionFiledDate"
                            :rules="[mandatoryrule()]"
                            dense
                            solo
                            readonly
                            v-on="on"
                            :disabled="viewOnly || viewPetition"
                            :background-color="
                              viewOnly || viewPetition ? '#f5f5f5' : '#ffffff'
                            "
                            :class="
                              viewOnly || viewPetition
                                ? 'forminput textDisable font-weight-bold'
                                : 'forminput'
                            "
                            clearable
                          >
                            <template v-slot:prepend-inner class="mx-0 my-0">
                              <v-icon class="iconstyle">mdi-calendar</v-icon>
                            </template>
                          </v-text-field>
                        </template>
                        <v-date-picker
                          v-model="petitionDetailsObj.dateOfFilingOfPetition"
                          no-title
                          :max="todayDate"
                          :min="litigationDetails.lossDate"
                          @input="petitionFiledDateStatus = false"
                        >
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    
                    <!-- <v-col class="mr-3">
                                        <p class="claimlabel mb-2">Trial Date <span :class="viewOnly ? '' : 'mandatorycolor'">*</span></p>
                                        <v-menu
                                            :close-on-content-click="false"
                                            offset-y
                                            v-model="trialDateMenu"
                                            min-width="auto">
                                            <template v-slot:activator="{ on }">
                                                <v-text-field
                                                    :placeholder="viewOnly ? '' : 'DD/MM/YYYY'"
                                                    v-model="computedTrialDate"
                                                    :rules="[mandatoryrule()]"
                                                    dense
                                                    solo
                                                    readonly
                                                    v-on="on"
                                                    :disabled="viewOnly || viewPetition" 
                                                    :background-color="viewOnly || viewPetition ? '#f5f5f5' : '#ffffff'" 
                                                    :class="viewOnly || viewPetition ? 'forminput textDisable font-weight-bold' : 'forminput'"
                                                    clearable
                                                >
                                                    <template v-slot:prepend-inner class="mx-0 my-0">
                                                    <v-icon class="iconstyle" >mdi-calendar</v-icon>
                                                    </template>
                                                </v-text-field>
                                            </template>
                                            <v-date-picker     
                                                v-model="petitionDetailsObj.trialDate"         
                                                no-title        
                                                :max="todayDate"
                                                @input="trialDateMenu = false">
                                            </v-date-picker>
                                        </v-menu>
                                    </v-col> -->
                    <!-- <v-col class="mr-3">
                                        <p class="claimlabel mb-2">Plantiff Attorney Email</p>
                                        <v-text-field
                                            :placeholder="viewOnly ? '' : 'Enter'"
                                            v-model="petitionDetailsObj.plaintiffAttorneyEmail"
                                            :rules="[validateEmail(petitionDetailsObj.plaintiffAttorneyEmail)]"
                                            solo
                                            dense
                                            :disabled="viewOnly || viewPetition" 
                                            :background-color="viewOnly ? '#f5f5f5' : '#ffffff'" 
                                            :class="viewOnly ? 'forminput textDisable font-weight-bold' : 'forminput'"
                                        >
                                            <template v-slot:prepend-inner class="mx-0 my-0">
                                                <v-icon class="iconstyleTextField">mdi-email</v-icon>
                                            </template>
                                        </v-text-field>
                                    </v-col> -->
                    
                  </v-row>
                  <v-row no-gutters>
                    <v-col class="ml-3 mr-3">
                      <p class="claimlabel mb-2">Date Of Hearing</p>
                      <v-menu
                        :close-on-content-click="false"
                        offset-y
                        v-model="hearingDateStatus"
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            :placeholder="viewOnly ? '' : 'DD/MM/YYYY'"
                            v-model="computedHearingDate"
                            dense
                            solo
                            readonly
                            v-on="on"
                            :disabled="viewOnly || viewPetition"
                            :background-color="
                              viewOnly || viewPetition ? '#f5f5f5' : '#ffffff'
                            "
                            :class="
                              viewOnly || viewPetition
                                ? 'forminput textDisable font-weight-bold'
                                : 'forminput'
                            "
                            clearable
                          >
                            <template v-slot:prepend-inner class="mx-0 my-0">
                              <v-icon class="iconstyle">mdi-calendar</v-icon>
                            </template>
                          </v-text-field>
                        </template>
                        <v-date-picker
                          v-model="petitionDetailsObj.dateOfHearing"
                          no-title
                          @input="hearingDateStatus = false"
                          :min="litigationDetails.lossDate"
                        >
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col class="mr-3">
                      <p class="claimlabel mb-2">
                        Venue
                        <span :class="viewOnly ? '' : 'mandatorycolor'">*</span>
                      </p>
                      <v-text-field
                        :placeholder="viewOnly ? '' : 'Enter'"
                        v-model="petitionDetailsObj.venue"
                        :rules="[mandatoryrule()]"
                        solo
                        dense
                        :disabled="viewOnly || viewPetition"
                        :background-color="
                          viewOnly || viewPetition ? '#f5f5f5' : '#ffffff'
                        "
                        :class="
                          viewOnly || viewPetition
                            ? 'forminput textDisable font-weight-bold'
                            : 'forminput'
                        "
                        clearable
                      ></v-text-field>
                    </v-col>
                    <v-col class="mr-3">
                      <p class="claimlabel mb-2">
                        
                        {{ litigationDetails.lobType=='Home'?'Plantiff':'Complainant Name'}}
                        <span :class="viewOnly ? '' : 'mandatorycolor'">*</span>
                      </p>

                      <v-text-field
                        :placeholder="viewOnly ? '' : 'Enter'"
                        v-model="petitionDetailsObj.plaintiff"
                        :rules="[mandatoryrule()]"
                        v-on:keypress="onlyAlphaNumeric"
                        solo
                        dense
                        :disabled="viewOnly || viewPetition"
                        :background-color="
                          viewOnly || viewPetition ? '#f5f5f5' : '#ffffff'
                        "
                        :class="
                          viewOnly || viewPetition
                            ? 'forminput textDisable font-weight-bold'
                            : 'forminput'
                        "
                        clearable
                      ></v-text-field>
                    </v-col>
                    
                    <!-- <v-col class="mr-3">
                                        <p class="claimlabel mb-2">Plantiff Attorney</p>
                                        <v-checkbox
                                            label="Enter Manually"
                                            v-model="petitionDetailsObj.isPlaintiffAttorney"
                                            solo
                                            dense
                                            class="form-checkbox-group"
                                            :disabled="viewOnly || viewPetition" 
                                            :class="viewOnly || viewPetition ? 'forminput textDisable font-weight-bold' : 'forminput'"
                                            clearable
                                        ></v-checkbox>
                                    </v-col> -->
                    
                  </v-row>
                  <v-row no-gutters class="rowstyle">
                    <v-col class="ml-3 mr-3">
                      <p class="claimlabel mb-2">{{ litigationDetails.lobType=='Home'?'Plantiff Attorney':'Complainant Attorney Name'}}</p>
                      <v-text-field
                        :placeholder="viewOnly ? '' : 'Enter'"
                        v-model="petitionDetailsObj.plaintiffAttorney"
                        v-on:keypress="onlyAlphaNumeric"
                        solo
                        dense
                        :disabled="viewOnly || viewPetition"
                        :background-color="
                          viewOnly || viewPetition ? '#f5f5f5' : '#ffffff'
                        "
                        :class="
                          viewOnly || viewPetition
                            ? 'forminput textDisable font-weight-bold'
                            : 'forminput'
                        "
                        clearable
                      ></v-text-field>
                    </v-col>
                    <v-col class="mr-3">
                      <p class="claimlabel mb-2">
                        Defendant
                        <span :class="viewOnly ? '' : 'mandatorycolor'">*</span>
                      </p>
                      <v-text-field
                        :placeholder="viewOnly ? '' : 'Enter'"
                        v-model="petitionDetailsObj.defendantName"
                        v-on:keypress="onlyAlphaNumeric"
                        :rules="[mandatoryrule()]"
                        solo
                        dense
                        :disabled="viewOnly || viewPetition"
                        :class="
                          viewOnly || viewPetition
                            ? 'forminput textDisable font-weight-bold'
                            : 'forminput'
                        "
                        clearable
                      ></v-text-field>
                    </v-col>
                    <v-col class="mr-3">
                      <p class="claimlabel mb-2">
                        Defendant Attorney
                        <span :class="viewOnly ? '' : 'mandatorycolor'">*</span>
                      </p>
                      <v-checkbox
                        label="Enter Manually"
                        v-model="petitionDetailsObj.isDefendantAttorney"
                        solo
                        dense
                        class="form-checkbox-group"
                        :disabled="viewOnly || viewPetition"
                        :class="
                          viewOnly || viewPetition
                            ? 'forminput textDisable font-weight-bold'
                            : 'forminput'
                        "
                        clearable
                      ></v-checkbox>
                    </v-col>
                    
                    
                  </v-row>
                  <v-row no-gutters>
                    <!-- <v-col class="ml-3 mr-3">
                                        <p class="claimlabel mb-2">Court Code <span :class="viewOnly ? '' : 'mandatorycolor'">*</span></p>
                                        <v-text-field
                                            :placeholder="viewOnly ? '' : 'Enter'"
                                            v-model="petitionDetailsObj.courtCode"
                                            :rules="[mandatoryrule()]"
                                            solo
                                            dense
                                            :disabled="viewOnly || viewPetition" 
                                            :background-color="viewOnly || viewPetition ? '#f5f5f5' : '#ffffff'" 
                                            :class="viewOnly || viewPetition ? 'forminput textDisable font-weight-bold' : 'forminput'"
                                            clearable
                                        ></v-text-field>
                                    </v-col> -->
                                    <v-col class="ml-3 mr-3">
                      <p class="claimlabel mb-2">
                        TATA AIG Attorney Name
                        <span :class="viewOnly ? '' : 'mandatorycolor'">*</span>
                      </p>
                      <v-text-field
                        v-if="petitionDetailsObj.isDefendantAttorney"
                        :placeholder="viewOnly ? '' : 'Enter'"
                        v-model="petitionDetailsObj.defendantAttorney"
                        :rules="[mandatoryrule()]"
                        solo
                        dense
                        v-on:keypress="onlyAlphaNumeric"
                        :disabled="viewOnly || viewPetition"
                        :background-color="
                          viewOnly || viewPetition ? '#f5f5f5' : '#ffffff'
                        "
                        :class="
                          viewOnly || viewPetition
                            ? 'forminput textDisable font-weight-bold'
                            : 'forminput'
                        "
                        clearable
                      >
                        <!-- <template v-slot:append class="my-0 mr-2" >
                                                <v-icon :disabled="viewOnly || viewPetition" :class="viewOnly ? 'forminput textDisable font-weight-bold' :'blueiconstyle'">mdi-magnify</v-icon>
                                            </template> -->
                      </v-text-field>
                      <v-select
                        v-else
                        :label="viewOnly || viewPetition ? '' : 'Select'"
                        v-model="petitionDetailsObj.defendantAttorney"
                        :items="serviceProviderList"
                        :rules="[mandatoryrule()]"
                        item-text="employeeName"
                        item-value="employeeName"
                        solo
                        dense
                        :disabled="viewOnly || viewPetition"
                        :background-color="
                          viewOnly || viewPetition ? '#f5f5f5' : '#ffffff'
                        "
                        :class="
                          viewOnly || viewPetition
                            ? 'forminput textDisable font-weight-bold'
                            : 'forminput'
                        "
                        clearable
                      ></v-select>
                    </v-col>
                                    <v-col class="mr-3">
                      <p class="claimlabel mb-2">
                        Court Name
                        <span :class="viewOnly ? '' : 'mandatorycolor'">*</span>
                      </p>
                      <!-- <v-text-field
                                            :placeholder="viewOnly ? '' : 'Enter'"
                                            v-model="petitionDetailsObj.courtName"
                                            :rules="[mandatoryrule()]"
                                            solo
                                            dense
                                            :disabled="viewOnly || viewPetition" 
                                            :background-color="viewOnly || viewPetition ? '#f5f5f5' : '#ffffff'" 
                                            :class="viewOnly || viewPetition ? 'forminput textDisable font-weight-bold' : 'forminput'"
                                            clearable
                                        ></v-text-field> -->
                      <v-autocomplete
                        :search-input.sync="search"
                        :label="viewOnly || viewPetition ? '' : 'Search'"
                        v-model="petitionDetailsObj.courtName"
                        :items="courtNameList"
                        :rules="[mandatoryrule()]"
                        item-text="name"
                        item-value="name"
                        @change="openCourtDetailDialog"
                        solo
                        dense
                        :disabled="viewOnly || viewPetition"
                        :background-color="
                          viewOnly || viewPetition ? '#f5f5f5' : '#ffffff'
                        "
                        :class="
                          viewOnly || viewPetition
                            ? 'forminput textDisable font-weight-bold'
                            : 'forminput'
                        "
                        clearable
                      ></v-autocomplete>
                    </v-col>
                    <v-col class="mr-3">
                      <p class="claimlabel mb-2">
                        Court Location
                        <span :class="viewOnly ? '' : 'mandatorycolor'">*</span>
                      </p>
                      <v-text-field
                        :placeholder="viewOnly ? '' : 'Enter'"
                        v-model="petitionDetailsObj.caseLocation"
                        :rules="[mandatoryrule()]"
                        solo
                        dense
                        disabled
                        :background-color="
                          viewOnly || viewPetition ? '#f5f5f5' : '#ffffff'
                        "
                        class="forminput textDisable font-weight-bold"
                        clearable
                      ></v-text-field>
                    </v-col>
                    
                    
                  </v-row>
                  <v-row no-gutters>
                    <v-col class="ml-3 mr-3">
                      <p class="claimlabel mb-2">
                        Petition Under Section
                        <span :class="viewOnly ? '' : 'mandatorycolor'">*</span>
                      </p>
                      <v-text-field
                        :placeholder="viewOnly ? '' : 'Enter'"
                        v-model="petitionDetailsObj.petitionUnderSection"
                        :rules="[mandatoryrule()]"
                        solo
                        dense
                        :disabled="viewOnly || viewPetition"
                        :background-color="
                          viewOnly || viewPetition ? '#f5f5f5' : '#ffffff'
                        "
                        :class="
                          viewOnly || viewPetition
                            ? 'forminput textDisable font-weight-bold'
                            : 'forminput'
                        "
                        clearable
                      ></v-text-field>
                    </v-col>
                    <v-col class="mr-3">
                      <p class="claimlabel mb-2">
                        Notice Received
                        <span :class="viewOnly ? '' : 'mandatorycolor'">*</span>
                      </p>
                      <v-menu
                        :close-on-content-click="false"
                        offset-y
                        v-model="noticeReceivedStatus"
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            :placeholder="viewOnly ? '' : 'DD/MM/YYYY'"
                            v-model="computedNoticeReceived"
                            :rules="[mandatoryrule()]"
                            dense
                            solo
                            readonly
                            v-on="on"
                            :disabled="viewOnly || viewPetition"
                            :background-color="
                              viewOnly || viewPetition ? '#f5f5f5' : '#ffffff'
                            "
                            :class="
                              viewOnly || viewPetition
                                ? 'forminput textDisable font-weight-bold'
                                : 'forminput'
                            "
                            clearable
                          >
                            <template v-slot:prepend-inner class="mx-0 my-0">
                              <v-icon class="iconstyle">mdi-calendar</v-icon>
                            </template>
                          </v-text-field>
                        </template>
                        <v-date-picker
                          v-model="petitionDetailsObj.noticeReceivedOn"
                          no-title
                          :max="todayDate"
                          :min="litigationDetails.lossDate"
                          @input="noticeReceivedStatus = false"
                        >
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col class="mr-3" style="max-width:265px">
                      <p class="claimlabel mb-2">
                        Litigation Status
                        <span :class="viewOnly  ? '' : 'mandatorycolor'" >*</span>
                      </p>
                      <v-select
                        :label="viewOnly ? '' : 'Select'"
                        v-model="petitionDetailsObj.litigationStatus"
                        :items="litigationStatus"
                        :rules="[mandatoryrule()]"
                        solo
                        dense
                        :disabled="viewOnly || viewPetition"
                        :background-color="viewOnly ? '#f5f5f5' : '#ffffff'"
                        :class="
                          viewOnly
                            ? 'forminput textDisable font-weight-bold'
                            : 'forminput'
                        "
                        clearable
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col class="ml-3 mr-3" style="max-width:265px">
                      <p class="claimlabel mb-2">
                        Litigation Closure
                        
                      </p>
                      <v-select
                        :label="viewOnly ? '' : 'Select'"
                        v-model="petitionDetailsObj.litigationClosure"
                        :items="litigationClosureOption"
                        
                        solo
                        dense
                        :disabled="viewOnly || viewPetition || petitionDetailsObj.litigationStatus=='OPEN'"
                        :background-color="viewOnly ? '#f5f5f5' : '#ffffff'"
                        :class="
                          viewOnly
                            ? 'forminput textDisable font-weight-bold'
                            : 'forminput'
                        "
                        clearable
                      ></v-select>
                    </v-col>
                    <v-col class="mr-3" style="max-width:265px">
                      <p class="claimlabel mb-2">
                        Litigation Closure Date
                        <span :class="viewOnly  ? '' : 'mandatorycolor'" v-if="petitionDetailsObj.litigationClosure !== ''">*</span>
                      </p>
                      <v-menu
                :close-on-content-click="false"
                offset-y
                v-model="litigationClosureDate"
                min-width="auto"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    :placeholder="viewOnly ? '' : 'DD/MM/YYYY'"
                    v-model="computedepLitigationClosureDate"
                    :rules="petitionDetailsObj.litigationClosure !== ''?[mandatoryrule()]:[]"
                    dense
                    solo
                    readonly
                    v-on="on"
                    :disabled="viewOnly"
                    :background-color="viewOnly ? '#f5f5f5' : '#ffffff'"
                    :class="
                      viewOnly
                        ? 'forminput textDisable font-weight-bold'
                        : 'forminput'
                    "
                  >
                    <template v-slot:prepend-inner class="mx-0 my-0">
                      <v-icon class="iconstyle">mdi-calendar</v-icon>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                
                  v-model="petitionDetailsObj.litigationClosureDate"
                  no-title
                  @input="litigationClosureDate = false"
                >
                </v-date-picker>
              </v-menu>
                    </v-col>
                    <v-col class="mr-3" style="max-width:265px" v-if="litigationDetails.lobType=='Home'">
                      <p class="claimlabel mb-2"
                  >Offer Amount
                </p>
                <v-text-field
                  placeholder="Enter..."
                  :class=" viewOnly
                            ? 'forminput textDisable font-weight-bold'
                            : 'forminput'"
                  solo
                  :disabled="viewOnly"
                  dense
                  v-model="petitionDetailsObj.offerAmount"
                  depressed
                  :rules="[
                    (v) => matchRegex(v, 'alphanum'),
                  ]"
                />
              </v-col>
                  </v-row>
                    
               <!-- <v-col class="mr-3">
                      <p class="claimlabel mb-2"
                  >Plantiff
                </p>
                <v-text-field
                  placeholder="Enter..."
                  :class=" viewOnly
                            ? 'forminput textDisable font-weight-bold'
                            : 'forminput'"
                  solo
                  :disabled="viewOnly"
                  dense
                  v-model="petitionDetailsObj.plantiff"
                  depressed
                  :rules="[
                    (v) => matchRegex(v, 'alphanum'),
                  ]"
                />
              </v-col>
              <v-col class="mr-3">
                      <p class="claimlabel mb-2"
                  >Plantiff Attorney
                </p>
                <v-text-field
                  placeholder="Enter..."
                  :class=" viewOnly
                            ? 'forminput textDisable font-weight-bold'
                            : 'forminput'"
                  solo
                  :disabled="viewOnly"
                  dense
                  v-model="petitionDetailsObj.plantiffAttorney"
                  depressed
                  :rules="[
                    (v) => matchRegex(v, 'alphanum'),
                  ]"
                />
              </v-col>
              <v-col class="mr-3">
                      <p class="claimlabel mb-2"
                  >Offer Amount
                </p>
                <v-text-field
                  placeholder="Enter..."
                  :class=" viewOnly
                            ? 'forminput textDisable font-weight-bold'
                            : 'forminput'"
                  solo
                  :disabled="viewOnly"
                  dense
                  v-model="petitionDetailsObj.offerAmount"
                  depressed
                  :rules="[
                    (v) => matchRegex(v, 'alphanum'),
                  ]"
                />
              </v-col>  
              <v-col v-else ></v-col>
                  </v-row>
                  <!-- <v-row no-gutters>
                                    <v-col class="mr-3">
                                        <v-checkbox
                                            label="Intimation U/S 158(6)?"
                                            v-model="petitionDetailsObj.intimationUnderSection"
                                            solo
                                            dense
                                            class="form-checkbox-group checkboxmargin"
                                            :disabled="viewOnly || viewPetition" 
                                            :class="viewOnly || viewPetition ? 'forminput textDisable font-weight-bold' : 'forminput'"
                                            clearable
                                        ></v-checkbox>
                                    </v-col>
                                    <v-col class="mr-3">
                                        <v-checkbox
                                            label="Delhi Agreed Procedure"
                                            v-model="petitionDetailsObj.delhiAgreedProcedure"
                                            solo
                                            dense
                                            class="form-checkbox-group checkboxmargin"
                                            :disabled="viewOnly || viewPetition" 
                                            :class="viewOnly || viewPetition ? 'forminput textDisable font-weight-bold' : 'forminput'"
                                            clearable
                                        ></v-checkbox>
                                    </v-col>
                                </v-row> -->
                  <v-row class="mt-2">
                    <v-col>
                      <v-btn
                        outlined
                        color="#D1121B"
                        class="btnstyle ml-3 mr-3"
                        @click="petitionDetailsDialog = false"
                      >
                        <v-icon class="mr-2 iconsize"
                          >mdi-close-box-outline</v-icon
                        >
                        <div class="vertical_line"></div>
                        Cancel
                      </v-btn>
                      <v-btn
                        outlined
                        color="#D1121B"
                        class="btnstyle mr-3"
                        @click="resetPetition()"
                        v-if="!viewOnly && !viewPetition"
                      >
                        <v-icon class="mr-2 iconsize"
                          >mdi-close-box-outline</v-icon
                        >
                        <div class="vertical_line"></div>
                        Reset
                      </v-btn>
                    </v-col>
                    <v-col align="right">
                      <v-btn
                        color="#23B1A9"
                        dark
                        class="btnstyle mr-3"
                        type="submit"
                        
                        v-if="!viewOnly && !viewPetition"
                      >
                        <v-icon class="mr-2 iconsize">mdi-arrow-right</v-icon>
                        <div class="vertical_line"></div>
                        Proceed
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-card-title>
          </v-card>
        </v-dialog>
      </v-row>
      <!-- Court Details Dialog  -->
      <v-row justify="center">
        <v-dialog
          v-model="showCourtDeail"
          persistent
          max-width="35%"
          style="border-radius: 0"
        >
          <v-card>
            <v-card-title
              class="pl-0 px-0 py-0 dailogboxAllvcardTableBackground"
            >
              <v-row class="colorbg">
                <span class="courtdescrptionSpan mb-2 ml-8 mr-5"
                  >Petition Details</span
                >
                <v-icon
                  @click="showCourtDeail = false"
                  class="ml-2 mr-2 mt-2"
                  color="#fff"
                  >mdi-window-close</v-icon
                >
              </v-row>
              <v-card-text class="vcardTextTableBackground pl-2">
                <v-form ref="courtDetailsref">
                  <v-row no-gutters>
                    <v-col class="ml-5" cols="4">
                      <label>Court Name</label>
                    </v-col>
                    <v-col>
                      <p>{{ courtDetailObj.courtName || "---------" }}</p>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col class="ml-5" cols="4">
                      <label>Court District</label>
                    </v-col>
                    <v-col>
                      <p>{{ courtDetailObj.courtDistrict || "---------" }}</p>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col class="ml-5" cols="4">
                      <label>Address</label>
                    </v-col>
                    <v-col>
                      <p>{{ courtDetailObj.address || "---------" }}</p>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col class="ml-5" cols="4">
                      <label>City</label>
                    </v-col>
                    <v-col>
                      <p>{{ courtDetailObj.city || "---------" }}</p>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col class="ml-5" cols="4">
                      <label>State</label>
                    </v-col>
                    <v-col>
                      <p>{{ courtDetailObj.state || "---------" }}</p>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col class="ml-5" cols="4">
                      <label>Pin code</label>
                    </v-col>
                    <v-col>
                      <p>{{ courtDetailObj.pinCode || "---------" }}</p>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-card-title>
          </v-card>
        </v-dialog>
      </v-row>
      <!-- defendent attorny -->
      <!-- <v-row justify="center">
                <v-dialog v-model="defendentAttorny_dialog" persistent max-width="55%" style="border-radius:0">
                    <v-card>
                        <v-card-title class="pl-0 px-0 py-0 dailogboxAllvcardTableBackground">
                            <v-row class="colorbg">
                                <span class="descrptionSpan mb-2 ml-8 mr-5">Service Provider Legal Expert List</span>
                                <v-icon @click="defendentAttorny_dialog = false" class="ml-2 mr-2 mt-2" color="#fff">mdi-window-close</v-icon>
                            </v-row>
                            <v-card-text class="vcardTextTableBackground pl-2">
                                <v-form  ref="searchSPForm" @submit.prevent="searchSPM(1)">
                                    <v-layout>
                                        <v-flex xs4>
                                            <v-layout column class="ml-3">
                                                <p class="mb-2 claimlabel">Name</p>
                                                <v-text-field
                                                    placeholder="Enter"
                                                    solo
                                                    dense
                                                    v-model="serchByName"
                                                    @keyup="defendentAttornyList"
                                                    class="forminput"
                                                    clearable
                                                ></v-text-field>
                                            </v-layout>
                                        </v-flex>

                                        <v-flex xs4>
                                            <v-layout column class="ml-3">
                                                <p class="mb-2 claimlabel">City</p>
                                                <v-text-field
                                                    placeholder="Enter"
                                                    solo
                                                    dense
                                                    v-model="serchByCity"
                                                    @keyup="defendentAttornyList"
                                                    class="forminput"
                                                    clearable
                                                ></v-text-field>
                                            </v-layout>
                                        </v-flex>

                                        <v-flex xs4>
                                            <v-layout column class="ml-3">
                                                <p class="mb-2 claimlabel">Pincode</p>
                                                <v-text-field
                                                    placeholder="Enter"
                                                    solo
                                                    dense
                                                    v-model="serchByPincode"
                                                    @keyup="defendentAttornyList"
                                                    class="forminput">                                            
                                                </v-text-field>
                                            </v-layout>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout>
                                        <v-flex xs4>
                                            <v-layout column class="ml-3">
                                                <p class="mb-2 claimlabel">Mobile Number</p>
                                                <v-text-field
                                                    placeholder="Enter"
                                                    :maxLength="10"
                                                    solo
                                                    @keyup="defendentAttornyList"
                                                    v-model="serchByMobile"
                                                    dense
                                                    class="forminput">
                                                </v-text-field>
                                            </v-layout>
                                        </v-flex>
                                        <v-flex xs4></v-flex>
                                        <v-flex xs4></v-flex>
                                    </v-layout>
                                    <v-data-table :headers="defendentAttorny" :items="attornyData" hide-default-footer class="vDataTableStyling mx-3 mb-2" 
                                        @click:row="defendentAttornyClick" fixed-header height="250" no-data-text="No Records for Defendant Attorney">
                                    </v-data-table>
                                </v-form>
                            </v-card-text>
                        </v-card-title>
                    </v-card>
                </v-dialog>
            </v-row> -->

      <!-- audits dialog-->
      <!-- <v-row justify="center">
            <v-dialog v-model="auditDialog" persistent max-width="75%">
            <v-card>
                <v-card-title class="pl-0 px-0 py-0 addressPopUpHeaderStyle">
                <v-row class="colorbg">
                    <span class="descrptionSpan mb-1 ml-10 mr-7">Audit Trail</span>
                    <v-icon @click="auditDialog = false" class="ml-10 mt-3">mdi-window-close</v-icon>
                </v-row>
                <v-card-text>
                    <v-flex>
                        <v-simple-table fixed-header class="vDataTableStyling mx-3 mt-2 doctable ">
                            <template>
                                <thead>
                                    <tr>
                                        <th class="text-center" width="8%">Sr. No.</th>
                                        <th class="text-center" width="14%">User ID</th>
                                        <th class="text-center" width="14%">User Name</th>
                                        <th class="text-center" width="18%">Time Stamp</th>
                                        <th class="text-center" width="14%">Action Taken</th>
                                    </tr>
                                </thead>
                                 <tbody>
                                    <tr v-for="(item, Aindex) in auditsData" :key="'AU'+ Aindex"> 
                                        <td>{{(pageRowCount1 * (pageNumber1 - 1)) + Aindex + 1}}</td>
                                        <td>{{item.createdById ==  "" ? "-------" : item.createdById}}</td>
                                        <td>{{item.createdBy ==  "" ? "-------" : item.createdBy}}</td>
                                        <td>{{item.createdAt == "" ? "-------" : dateFormat(item.createdAt)}}</td>
                                        <td v-if="item.action == 'Modified'">
                                            <a class="textcolr" @click="openDetailedAuditTrail(item.modification)">
                                                {{item.action ==  "" ? "-------" : item.action}}
                                            </a>
                                        </td>
                                        <td v-else>
                                                {{item.action ==  "" ? "-------" : item.action}}
                                        </td>
                                    </tr>                                   
                                    <tr class="v-data-table__empty-wrapper" v-if="auditsData.length === 0">
                                        <td colspan="7">No Records for Audit Trail</td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>   
           
                       <v-layout fluid class="graybackground mb-2 mx-3" v-if="auditsData.length > 0">
                            <v-layout justify-end>
                                <div style="margin-top: 12px">
                                    <span class="orangetext paginationText">
                                        Showing {{ totalListCount1 ? pageStartIndex1 + 1 : 0 }} - {{pageEndIndex1 }}
                                    </span>
                                    <span class=" mr-2 paginationText"> out of {{ totalListCount1 }} records </span>
                                </div>
                                <v-pagination
                                    v-model="pageNumber1"
                                    :length="totalPages1"
                                    :total-visible="pageRowCount1"
                                    @input="getAuditDetails"
                                    color="#152F38"
                                    class="navpagenumber pt-1"
                                ></v-pagination>
                            </v-layout>
                        </v-layout>
                    </v-flex>
                </v-card-text>
                </v-card-title>
            </v-card>
            </v-dialog>
        </v-row> -->

      <!-- detailed audit  -->
      <!-- <v-row justify="center">
    </div>
            <v-dialog v-model="detailedAuditTrailOpen" persistent max-width="65%">
                <v-card>
                    <v-card-title class="pl-0 px-0 py-0 addressPopUpHeaderStyle">                     
                        <v-row class="colorbg">
                            <span class="descrptionSpan mb-2 ml-10 mr-7">Modified Fields Details</span>
                            <v-icon @click="detailedAuditTrailOpen = false" class="ml-5 mt-3">mdi-window-close</v-icon>
                        </v-row>
                        <v-card-text class="addressPopUpStyle">
                            <v-flex>
                                <v-simple-table fixed-header class="vDataTableStyling mx-3 mt-2 doctable mb-0">
                                    <template>
                                        <thead>
                                            <tr>
                                                <th class="text-center" width="8%">Sr. No.</th>
                                                <th class="text-center" width="14%">Field Name</th>
                                                <th class="text-center" width="14%">Old Value</th>
                                                <th class="text-center" width="18%">New Value</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(item, Rindex) in detailedAuditTrailData" :key="'CR'+ Rindex">
                                                <td>{{ Rindex + 1 }}</td>
                                                <td>{{ item.fieldName ==  "" ? "-------" : item.fieldName}}</td>
                                                <td>{{item.oldValue ==  "" ? "-------" : item.oldValue}}</td>
                                                <td>{{item.newValue == "" ? "-------" : item.newValue}}</td>
                                            </tr>
                                            <tr class="v-data-table__empty-wrapper" v-if="detailedAuditTrailData.length === 0">
                                                <td colspan="12">No Records Available</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>    
                            </v-flex>
                        </v-card-text>
                    </v-card-title>
                </v-card>
            </v-dialog>
        </v-row> -->
    </div>
  </div>
</template>

<script>
import moment from "moment"
import ClaimDetailsVue from "../../component/ClaimDetails.vue"
export default {
  components: {
    ClaimDetailsVue,
  },
  data() {
    return {
      claimDetails: {},
      minCaseYear:"",
      showCoverageTooltip: false,
      search: "",
      todayDate: moment(new Date()).format("YYYY-MM-DD"),
      // RULES
      validationmessage: "This field is required",

      mandatoryrule() {
        return (v) => !!v || this.validationmessage
      },
      // for year picker
      date: null,
      menu: false,
      date1: null,
      menu1: false,

      claimdetailsSection: true, // show/hide Claim Details section
      petitionDetailsSection: true, // show/hide Petition Details section
      petitionDataSection: true, // show/hide Award Heads section
      petitionExecutionSection: true, // show/hide Petition Data section

      showLitigation: false, // show form view
      showAddButton: false, // show no data available screen
      viewOnly: false, // for view/modify mode
      isNewPetition: true, // for creating new petition
      // for showing date
      // trialDateMenu: false,
      noticeReceivedStatus: false,
      petitionFiledDateStatus: false,
      hearingDateStatus: false,
      offerDateStatus: false,
      receiptOrderDateByTagicStatus: false,
      receiptOrderDateByAdvocateStatus: false,
      dispositionDateMenu: false,
      depositeDateStatus: false,
      epHearingDateMenu: false,
      interestFromDate:false,
      litigationClosureDate:false,
      interestToDate:false,
      // complianceDateMenu:false,
      dateOfLossMenu: false,
      litigationClosureOption: ["Lost", "Won", "Withdrawn", "Compromised",'Dismissed'],
      litigationStatus:['OPEN','CLOSED'],
      interestFormOptions:['Date of Order','Date of Repudiation','Date of Default','Date of Filing'],
      caseStageOptions:['Appearance','Filing of Written Version','Evidence of Complainant','Evidence of Company','Argument','Judgement to be pronounced','Judgement'],
      executionClosureOption: ["Deposit Payment", "Stay Order", "Dismissed"],
      menuItems: [
        { title: "VIEW", icon: "mdi-eye", color: "#000000", name: "view" },
        {
          title: "MODIFY",
          icon: "mdi-pencil",
          color: "#1EBBD9",
          name: "modify",
        },
        {
          title: "DELETE",
          icon: "mdi-cancel",
          color: "#D1121B",
          name: "delete",
        },
        // { title: 'AUDIT TRAIL', icon: 'mdi-chart-timeline-variant', color:"color:#e46a25", name:'audit'},
      ],
      petitionHeaders: [
        { text: "Action", align: "center", value: "actions", sortable: false },
        {
          text: "Litigation Type",
          align: "center",
          value: "litigationType",
          sortable: false,
        },
        { text: "Litigation Status", align: "center", value: "litigationStatus", sortable: false },
        {
          text: "Case Number",
          align: "center",
          value: "caseNumber",
          sortable: false,
          width: "15%",
        },
        {
          text: "Case Year",
          align: "center",
          value: "caseYear",
          sortable: false,
          width: "9%",
        },
        {
          text: "Case Title",
          align: "center",
          value: "caseTitle",
          sortable: false,
          width: "15%",
        },
        {
          text: "Court Name",
          align: "center",
          value: "courtName",
          sortable: false,
          width: "15%",
        },
        // {text: "Court Code",align: "center",value: "courtCode",sortable: false, width: '15%'},
      ],
      litigationStatusShow:false,
      // v-model for petition form
      litigationDetails: {
        lobType: "",
        claimNumber: "",
        featureNumber: "",
        insuredName: "",
        lossDate: null,
        policyNumber: "",
        claimantName: "",
        claimReserveAmount: 0,
        TAT: "",
        petitionDetails: [],
        petitionAmount: 0,
        groundsOfDefence: [],
        // plaintiffAgreedForSettlement: false,
        epNumber: "",
        epYear: "",
        offerAmount: 0,
        executionClosure: "",
        dateOfOffer: null,
        disposition: "",
        dispositionAmount: "",
        dispositionDate: null,
        epHearingDate: null,
        // complianceDate: null,
        receiptOrderTAGICDate: null,
        receiptOrderAdvocateDate: null,
        orderAmount: 0,
        interestAwarded: 0,
        costAwarded: 0,
        totalAwardAmount: 0,
        // judgementValue: '',
        // isOrderXxiCompleted: false,
        dateDeposit: null,
        isExecutionFiled: false,
        //changes
        // dateOfClosure: null,
        // penaltyAmount: 0,
        // expenses: 0,
        isSubmitted: false,
        rateOfInterestAwarded:'',
        interestFrom:'',
        interestFromDate:null,
        interestToDate:null,
        numberOfDays:'',
      },
      petitionDetailsObj: {
        litigationType: "",
        caseNumber: "",
        caseYear: "",
        caseTitle: "",
        dateOfFilingOfPetition: null,
        dateOfHearing: null,
        // trialDate: null,
        venue: "",
        // isPlaintiffAttorney: false,
        plaintiffAttorney: "",
        plaintiff: "",
        isDefendantAttorney: false,
        defendantName: "",
        defendantAttorney: "",
        courtName: "",
        courtCode: "",
        petitionUnderSection: "",
        // caseLocation: '',
        // plaintiffAttorneyEmail: '',
        isCaseFitForCompromiseInAllRespects: false,
        // intimationUnderSection: false,
        // delhiAgreedProcedure: false,
        caseLocation: "",
        noticeReceivedOn: null,
        litigationStatus:'',
        cnrNumber:'',
        caseStage:'',
        litigationClosure: "",
        offerAmount:0,
        litigationClosureDate:null
      },
      petitiondetailsData: [],
      defaultObj: {},
      lobTypeOption: [],
      defendentAttorny_dialog: false,
      defendentAttorny: [
        {
          text: "ID",
          align: "start",
          value: "serviceProviderId",
          sortable: false,
        },
        {
          text: "Name",
          align: "start",
          value: "serviceProviderName",
          sortable: false,
        },
      ],
      attornyData: [],
      litigationTypeOptions: [
        "National CDRF",
        "Ombudsman",
        "Arbitration",
        "Mediation",
        "Civil Court",
        "Permanent Lok Adalat",
        "High Court",
        "Supreme Court",
        "District C.D.R.C",
        "State C.D.R.C",
        "Others",
      ],
      groundsOfDefenceOption: [
        "Breach of Warranty",
        "Fraud",
        "Cheque Dishonour/Insufficient premium",
        "Loss Falls under exclusion",
        "Missing Coverage when claim was reported",
        "Loss Location Not covered under policy",
        "Others (mention reason)",
      ],

      dispositionOption: ['In favor of Company','Against Company','Conditional Order','Dismissed on Default or Withdrawn','Settled or Compromised','Compromised After Award'],
      // Array for radio button
      optionArray: [
        { name: "Yes", selected: true },
        { name: "No", selected: false },
      ],
      claimStatus: "",
      dispositionField:false,
      // audits table data
      pageNumber1: 1,
      pageStartIndex1: 0,
      pageRowCount1: 10,
      totalListCount1: 0,
      totalPages1: null,

      auditDialog: false,
      auditsData: [],
      detailedAuditTrailOpen: false,
      detailedAuditTrailData: [],
      petitionDetailsDialog: false,
      viewPetition: false,
      isModifyClicked: false,
      petitionIndex: -1,
      serviceProviderList: [],
      courtNameList: [],
      //tooltip
      showTooltip: false,
      showCourtDeail: false,
      courtDetailObj: {
        courtName: "",
        courtDistrict: "",
        address: "",
        city: "",
        state: "",
        pinCode: "",

        
      },
      modify_show_btn:false,
    }
  },

  computed: {
    // computedTrialDate() {
    //     return this.petitionDetailsObj.trialDate == null ? "" : moment(this.petitionDetailsObj.trialDate).format('DD/MM/YYYY');
    // },

    computedNoticeReceived() {
      return this.petitionDetailsObj.noticeReceivedOn == null
        ? ""
        : moment(this.petitionDetailsObj.noticeReceivedOn).format("DD/MM/YYYY")
    },

    computedPetitionFiledDate() {
      return this.petitionDetailsObj.dateOfFilingOfPetition == null
        ? ""
        : moment(this.petitionDetailsObj.dateOfFilingOfPetition).format(
            "DD/MM/YYYY"
          )
    },

    computedHearingDate() {
      return this.petitionDetailsObj.dateOfHearing == null
        ? ""
        : moment(this.petitionDetailsObj.dateOfHearing).format("DD/MM/YYYY")
    },

    computedOfferDate() {
      return this.litigationDetails.dateOfOffer == null
        ? ""
        : moment(this.litigationDetails.dateOfOffer).format("DD/MM/YYYY")
    },

    computedLossDate() {
      return this.litigationDetails.lossDate == null
        ? ""
        : moment(this.litigationDetails.lossDate).format("DD/MM/YYYY")
    },

    computedReceiptOrderDateByTagic() {
      return this.litigationDetails.receiptOrderTAGICDate == null
        ? ""
        : moment(this.litigationDetails.receiptOrderTAGICDate).format(
            "DD/MM/YYYY"
          )
    },

    computedDispositionDate() {
      return this.litigationDetails.dispositionDate == null
        ? ""
        : moment(this.litigationDetails.dispositionDate).format("DD/MM/YYYY")
    },
    computedepInterestFromDate() {
      return this.litigationDetails.interestFromDate == null
        ? ""
        : moment(this.litigationDetails.interestFromDate).format("DD/MM/YYYY")
    },
    computedepLitigationClosureDate() {
      return this.petitionDetailsObj.litigationClosureDate == null
        ? ""
        : moment(this.petitionDetailsObj.litigationClosureDate).format("DD/MM/YYYY")
    },
    computedepInterestToDate() {
      return this.litigationDetails.interestToDate == null
        ? ""
        : moment(this.litigationDetails.interestToDate).format("DD/MM/YYYY")
    },
    computedepHearingDate() {
      return this.litigationDetails.epHearingDate == null
        ? ""
        : moment(this.litigationDetails.epHearingDate).format("DD/MM/YYYY")
    },

    computedDepositeDate() {
      return this.litigationDetails.dateDeposit == null
        ? ""
        : moment(this.litigationDetails.dateDeposit).format("DD/MM/YYYY")
    },

    computedReceiptOrderDateByAdvocate() {
      return this.litigationDetails.receiptOrderAdvocateDate == null
        ? ""
        : moment(this.litigationDetails.receiptOrderAdvocateDate).format(
            "DD/MM/YYYY"
          )
    },

    selectAllBrands() {
      return (
        this.litigationDetails.groundsOfDefence.length ===
        this.groundsOfDefenceOption.length
      )
    },

    selectSomeBrands() {
      return (
        this.litigationDetails.groundsOfDefence.length > 0 &&
        !this.selectAllBrands
      )
    },

    icon() {
      if (this.selectAllBrands) return "mdi-close-box"
      if (this.selectSomeBrands) return "mdi-minus-box"
      return "mdi-checkbox-blank-outline"
    },

    // computedComplianceDate() {
    //     return this.litigationDetails.complianceDate == null ? "" : moment(this.litigationDetails.complianceDate).format('DD/MM/YYYY');
    // },

    calculateExpenses() {
      return (this.litigationDetails.expenses =
        this.roundOffValue(this.litigationDetails.interestAwarded) +
        this.roundOffValue(this.litigationDetails.penaltyAmount))
    },
    cpmputedCaseTitle() {
      this.petitionDetailsObj.caseTitle =
        this.petitionDetailsObj.plaintiff +
        " vs " +
        this.petitionDetailsObj.defendantName
      return this.petitionDetailsObj.plaintiff &&
        this.petitionDetailsObj.defendantName
        ? this.limitTextLength(this.petitionDetailsObj.plaintiff, 12) +
            " vs " +
            this.limitTextLength(this.petitionDetailsObj.defendantName, 12)
        : ""
    },
    pageEndIndex1() {
      return this.pageStartIndex1 + this.pageRowCount1 <= this.totalListCount1
        ? this.pageStartIndex1 + this.pageRowCount1
        : this.totalListCount1
    },
  },

  created() {
    this.$store.commit("SAVE_PAGE_HEADER", "Litigation")
    // if(this.$store.getters.fetchAllPageValues){
    //     this.storeValues = this.$store.getters.fetchAllPageValues
    // }
    this.getLOB()
    // this.getCourtNames();
    this.defaultObj = { ...this.litigationDetails }
    this.defaultPetitionDetailsObj = { ...this.petitionDetailsObj }
    console.log(
      "this.$route.params",
      this.$route.params,
      this.$store.getters.fetchDataFromOtherLOB
    )
    // let prevPageData = JSON.parse(window.sessionStorage.getItem('SET_CURRENT_PAGE_DATA')) || {};
    let prevPageData = this.$store.getters.fetchCurrentPageData || {}
    if (
      Object.keys(prevPageData).length != 0 ||
      Object.keys(this.$route.params).length != 0
    ) {
      this.claimDetails = this.$route.params.claimDetails || prevPageData.data
      let data = this.$route.params.claimDetails || prevPageData.data
      if (data._id) {
        this.getLitigationData(data._id)
      }
      this.viewOnly = this.$route.params.viewOnly || prevPageData.viewOnly
    } else {
      let storeData = this.$store.getters.fetchDataFromOtherLOB
      this.claimDetails = storeData
      this.litigationDetails.lobType = storeData.lobType
      this.litigationDetails.claimNumber = storeData.claimNumber
      this.litigationDetails.featureNumber = storeData.featureNumber
      this.litigationDetails.insuredName = storeData.insuredName
      this.litigationDetails.lossDate = storeData.dateOfLoss || storeData.lossDate
      this.litigationDetails.policyNumber = storeData.policyNumber
      this.litigationDetails.claimantName = storeData.claimantName
      this.litigationDetails.claimReserveAmount = storeData.claimReserveAmount
      this.litigationDetails.productName = storeData.productName
      this.litigationDetails.coverage = storeData.coverage
      this.litigationDetails.TAT = storeData.TAT
    }
    if (this.viewOnly) {
      this.menuItems[1].color = "#808080"
      this.menuItems[2].color = "#808080"
    }
    
      
  },

  watch: {
    search(value) {
      if (value && value.length > 2) {
        this.getCourtNames(value)
      }
    },
    menu(val) {
      val && this.$nextTick(() => (this.$refs.picker.activePicker = "YEAR"))
    },
    menu1(val) {
      val && this.$nextTick(() => (this.$refs.picker1.activePicker = "YEAR"))
    },
    petitionDetailsDialog(val){
      if(val){
        this.getServiceProviders()
        this.minCaseYear=new Date(this.litigationDetails.lossDate).getFullYear().toString();
        console.log("234",this.minCaseYear,this.litigationDetails.lossDate,this.litigationDetails)
      }
    }
  },

  methods: {
    litigationStatusDisplay(){
      console.log('function working')
      this.petitiondetailsData.map(data=>{
        if(data.litigationStatus == 'OPEN'){
          this.litigationStatusShow=true
        }else{
          this.litigationStatusShow=false
        }
      })
      
    },
    calculateDate(){
      console.log('interestToDate' in this.litigationDetails)
      
      if('interestToDate' in this.litigationDetails && 'interestFromDate' in this.litigationDetails){
        if(this.litigationDetails.interestToDate !== null && this.litigationDetails.interestFromDate !== null){
          this.litigationDetails.numberOfDays=moment(this.litigationDetails.interestToDate).diff(moment(this.litigationDetails.interestFromDate), "days")
        }else {
          this.litigationDetails.numberOfDays=''
        }
        
      }else{
        this.litigationDetails.numberOfDays=''
      }
      
    },
    openCourtDetailDialog(value) {
      if (value) {
        this.showCourtDeail = true
        let courtObj = this.courtNameList.find((item) => item.name == value)
        this.courtDetailObj = {
          courtName: courtObj.name,
          courtDistrict: courtObj.district,
          address: `${courtObj.addressLine1} ${courtObj.addressLine2} ${courtObj.addressLine3}`,
          city: courtObj.city,
          state: courtObj.state,
          pinCode: courtObj.pinCode,
        }
        this.petitionDetailsObj.caseLocation = this.courtDetailObj.city
      }
    },
    checkingDisposition(){
      this.dispositionField=this.litigationDetails.disposition.includes(['In favor of Company',
      'Against Company','Conditional Order','Dismissed on Default','Dismissed on Withdrawn','Settled','Compromised','Compromised After Award'])
      
    },

    getLitigationData(_id) {
      let reqData = _id
      this.showProgress()
      this.GET_ByURLM(
        "getLitigation",
        reqData,
        (res) => {
          this.hideProgress()
          if (res.data.statusCode === 200) {
            this.litigationDetails = res.data.data
            this.petitiondetailsData = res.data.data.petitionDetails.map(
              (a) => {
                return { ...a }
              }
            )
            this.litigationStatusDisplay()
            if(this.litigationDetails.isSubmitted){
      this.modify_show_btn=true
    }
          } else {
            this.showAlert(res.data.msg)
          }
        },
        (error) => {
          console.log(error)
          this.hideProgress()
        },
        true
      )
    },

    getLOB() {
      let reqData = ""
      this.showProgress()
      this.GETM(
        "getLOB",
        reqData,
        (res) => {
          this.hideProgress()
          if (res.data.statusCode === 200) {
            this.lobTypeOption = res.data.data[0].lineOfBusiness
          } else {
            this.lobTypeOption = []
            this.showAlert(res.data.msg)
          }
        },
        (error) => {
          console.log(error)
          this.hideProgress()
        },
        true
      )
    },

    save(date) {
      let d = date.split("-")
      console.log("date", date.split("-"))
      this.$refs.menu.save(date)
      this.petitionDetailsObj.caseYear = d[0]
      this.$refs.picker.activePicker = "YEAR"
      this.menu = false
    },

    save1(date1) {
      let d = date1.split("-")
      // console.log("date1", date1.split('-'));
      this.$refs.menu1.save(date1)
      this.litigationDetails.epYear = d[0]
      this.$refs.picker1.activePicker = "YEAR"
      this.menu1 = false
    },
    // validate text field for numbers only
    numbersOnly(evt) {
      evt = evt ? evt : window.event
      var charCode = evt.which ? evt.which : evt.keyCode
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault()
      } else {
        return true
      }
    },

    onlyAlphaNumeric(e) {
      var code = "charCode" in e ? e.charCode : e.keyCode
      if (
        !(code > 47 && code < 58) && // numeric (0-9)
        !(code > 64 && code < 91) && // upper alpha (A-Z)
        !(code > 96 && code < 123) &&
        !(code == 32)
      ) {
        // lower alpha (a-z)
        e.preventDefault()
      }
    },

    isLetter(e) {
      let char = String.fromCharCode(e.keyCode)
      if (/^[A-Z a-z]$/.test(char)) return true
      else e.preventDefault()
    },

    formatDate(date) {
      return date == null ? "" : moment(date).format("DD/MM/YYYY")
    },

    validateEmail(v) {
      let emailExp =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
      if (v && !emailExp.test(v)) {
        return "Email must be valid"
      } else {
        return true
      }
    },
    calculateRateOfInterest(){
      let num = parseFloat(this.litigationDetails.rateOfInterestAwarded).toFixed(2)
      
      if(num=='NaN'){
        this.litigationDetails.rateOfInterestAwarded=''
        num=''
      }else{
        this.litigationDetails.rateOfInterestAwarded=num
      }
      
    },
    calculateTotalAwardAmount() {
      this.litigationDetails.totalAwardAmount =
        this.roundOffValue(this.litigationDetails.orderAmount) +
        this.roundOffValue(this.litigationDetails.interestAwarded) +
        this.roundOffValue(this.litigationDetails.costAwarded)
    },

    //for expenses
    roundOffValue(value) {
      if (value && !isNaN(parseFloat(value))) {
        return parseFloat(parseFloat(value).toFixed(2))
      }
      return 0
    },

    submitPetition(isSubmit = false) {
      if (
        !this.petitionDetailsSection ||
        !this.petitionDataSection ||
        !this.claimdetailsSection ||
        !this.petitionExecutionSection
      ) {
        this.petitionDetailsSection = true
        this.petitionDataSection = true
        this.claimdetailsSection = true
        this.petitionExecutionSection = true
      } else if (isSubmit ? this.$refs.litigationref.validate() : !isSubmit) {
        let action = isSubmit ? "submit" : "save"
        this.litigationDetails.petitionDetails = this.petitiondetailsData
        if(isSubmit && this.litigationStatusShow){
          this.showAlert("Please Close petition details.")
          return
        }
        if (isSubmit && this.litigationDetails.petitionDetails.length == 0) {
          this.showAlert("Please add atleast one row for petition details.")
          return
        }
        this.showConfirm(
          "Confirmation",
          `Are you sure you want to ${action}?`,
          "Yes",
          "No",
          () => {
            // call submit api
            this.litigationDetails.isSubmitted = isSubmit
            if (
              parseInt(this.litigationDetails.offerAmount) >
              parseInt(this.litigationDetails.petitionAmount)
            ) {
              this.showAlert(
                "Please Enter Offer Amount Less Than Petition Amount"
              )
            } else if (this.litigationDetails.litigationId) {
              this.updatePetition()
            } else {
              this.createPetition()
            }
          },
          () => {}
        )
      } else {
        this.showAlert("Please enter mandatory fields")
      }
    },

    createPetition() {
      // if(this.$refs.litigationref.validate()) {
      this.POSTM(
        "createLitigation",
        this.litigationDetails,
        (res) => {
          if (res.data.statusCode === 200) {
            this.showAlert(res?.data?.msg || "Success", "", "", () => {
              this.$router.push({
                name: "litigationDetails",
                params: {
                  claimDetails: {
                    claimNumber: this.litigationDetails.claimNumber,
                    featureNumber: this.litigationDetails.featureNumber,
                  },
                },
              })
            })
          } else {
            this.showAlert(
              res?.data?.msg || res?.data?.data?.msg || "Something went wrong"
            )
          }
        },
        (error) => {
          console.log(error)
        },
        true
      )
      // } else {
      //     this.showAlert("Please enter mandatory fields");
      // }
    },

    updatePetition() {
      this.PUTM(
        "updateLitigation",
        this.litigationDetails.litigationId,
        this.exceptObject(this.litigationDetails, [
          "_id",
          "__v",
          "updatedAt",
          "createdAt",
          "status",
        ]),
        (res) => {
          if (res.data.statusCode === 200) {
            this.showAlert(res?.data?.msg || "Success", "", "", () => {
              this.$router.push({
                name: "litigationDetails",
                params: {
                  claimDetails: {
                    claimNumber: this.litigationDetails.claimNumber,
                    featureNumber: this.litigationDetails.featureNumber,
                  },
                },
              })
            })
          }
        },
        (error) => {
          console.log(error)
        },
        true
      )
    },

    cancel() {
      this.showConfirm(
        "Confirmation",
        `Are you sure you want to ${this.viewOnly ? "leave" : "cancel"}?`,
        "Yes",
        "No",
        () => {
          this.$router.push({
            name: "litigationDetails",
            params: {
              claimDetails: {
                claimNumber: this.litigationDetails.claimNumber,
                featureNumber: this.litigationDetails.featureNumber,
              },
            },
          })
        },
        () => {}
      )
    },

    reset() {
      this.showConfirm(
        "Confirmation",
        `Are you sure you want to reset?`,
        "Yes",
        "No",
        () => {
          this.litigationDetails = {
            ...this.defaultObj,
            lobType: this.litigationDetails.lobType,
            claimNumber: this.litigationDetails.claimNumber,
            featureNumber: this.litigationDetails.featureNumber,
            insuredName: this.litigationDetails.insuredName,
            lossDate: this.litigationDetails.lossDate,
            productName: this.litigationDetails.productName,
            coverage: this.litigationDetails.coverage,
            policyNumber: this.litigationDetails.policyNumber,
            claimantName: this.litigationDetails.claimantName,
            claimReserveAmount: this.litigationDetails.claimReserveAmount,
            TAT: this.litigationDetails.TAT,
            litigationId: this.litigationDetails.litigationId || "",
          }
          this.petitiondetailsData = []
          this.litigationStatusDisplay()
          this.$refs.litigationref.resetValidation()
        },
        () => {}
      )
    },
    checkCaseYear() {
      let caseYear = new Date(this.petitionDetailsObj.caseYear).getFullYear()
      let dateOfLoss = new Date(this.litigationDetails.lossDate).getFullYear()
      let yearDiff = caseYear - dateOfLoss
      if (yearDiff < 0) return false
      return true
    },

    addTOTable() {
      if (this.$refs.petitionDetailsref.validate()) {
        let isCaseYearValid = this.checkCaseYear()
        if (!isCaseYearValid)
          return this.showAlert(
            "Case year cannot be before the Date of loss’s year"
          )
        if (this.isModifyClicked) {
          // this.$set(this.petitiondetailsData[this.petitionIndex], this.petitionIndex, this.petitionDetailsObj);
          // this.petitiondetailsData[this.petitionIndex] = { ...this.petitionDetailsObj }
          this.petitiondetailsData.splice(
            this.petitionIndex,
            1,
            this.petitionDetailsObj
          )
          console.log("petition details", this.petitiondetailsData)
        } else {
          this.petitiondetailsData.push(this.petitionDetailsObj)
        }
        this.litigationStatusDisplay()
        this.petitionDetailsDialog = false
      } else {
        this.showAlert("Please enter mandatory fields.")
      }
      if(this.litigationDetails.isSubmitted)
      {
        this.modify_show_btn=false
      }
    },

    modify(item, index, itemIndex) {
      this.getCourtNames(item.courtName)
      console.log("petitionDetailsObj=", item)
      this.petitionDetailsObj = { ...item }
      if (index == 0) {
        this.petitionDetailsDialog = true
        this.viewPetition = true
        this.isModifyClicked = false
      } else if (index == 1) {
        this.petitionDetailsDialog = true
        this.viewPetition = false
        this.isModifyClicked = true
        this.petitionIndex = itemIndex
      } else if (index == 2) {
        this.showConfirm(
          "Confirmation",
          `Are you sure you want to delete?`,
          "Yes",
          "No",
          () => {
            this.petitiondetailsData.splice(itemIndex, 1)
          }
        )
      }
    },

    addRow() {
      if(this.petitiondetailsData.length >= 10){
        this.showAlert('Only ten petitions are allowed ')
      }else{
        this.petitionDetailsDialog = true
      this.viewPetition = false
      this.isModifyClicked = false
      this.petitionDetailsObj = { ...this.defaultPetitionDetailsObj }
      this.$refs.petitionDetailsref.resetValidation()
      }
      
    },

    resetPetition() {
      this.showConfirm(
        "Confirmation",
        `Are you sure you want to reset?`,
        "Yes",
        "No",
        () => {
          this.petitionDetailsObj = { ...this.defaultPetitionDetailsObj }
          this.$refs.petitionDetailsref.resetValidation()
          this.litigationStatusDisplay()
        },
        () => {}
      )
    },

    getServiceProviders() {
      console.log("LOB=",this?.$store?.getters?.fetchDataFromOtherLOB,this.litigationDetails.lobType)
      this.GETM(
        "searchServiceProvider",
        `page=1&pageLimit=100&lob=${this?.$store?.getters?.fetchDataFromOtherLOB?.lobType || this.litigationDetails.lobType}&active=true`,
        (res) => {
          if (res.data.statusCode === 200) {
            this.serviceProviderList = res.data.data.data
              .filter((x) => x.serviceProviderType === "Lawyer/Legal Expert")
              .map((y) => y.serviceProviderDetails)
          } else {
            this.serviceProviderList = []
            this.showAlert(res.data.msg)
          }
        },
        (error) => {
          console.log(error)
        },
        true
      )
    },

    getCourtNames(val) {
      let reqData = "smartSearch=" + val
      this.GETM(
        "searchCourt",
        reqData,
        (res) => {
          if (res.data.statusCode === 200) {
            this.courtNameList = res.data.data.data.map((x) => x.court)
          } else {
            this.courtNameList = []
            this.showAlert(res.data.msg)
          }
        },
        (error) => {
          console.log(error)
        },
        true
      )
    },

    toggle() {
      this.$nextTick(() => {
        if (this.selectAllBrands) {
          this.litigationDetails.groundsOfDefence = []
        } else {
          this.litigationDetails.groundsOfDefence =
            this.groundsOfDefenceOption.slice()
        }
      })
    },
  },
}
</script>

<style scoped>
.aligncenter {
  position: fixed;
  left: 45%;
  top: 45%;
}

.borderStyle {
  border: 0.5px solid #d7d7d7;
}

.iconsize {
  font-size: 16px !important;
  font-weight: bold;
}
.btnstyle {
  height: 28px !important;
  text-transform: capitalize;
  font-size: 11px !important;
  letter-spacing: 0.5px;
}
.modify {
  color: #20b7b0;
}
.delete {
  color: #d1121b;
}
.descrptionSpan {
  width: 85%;
  margin-top: 21px;
  font-weight: 500;
  font-size: 17px;
  letter-spacing: 0.4px;
}
.courtdescrptionSpan {
  width: 75%;
  margin-top: 21px;
  font-weight: 500;
  font-size: 17px;
  letter-spacing: 0.4px;
}
.blueiconstyle {
  margin-right: -12px;
  background-color: #01bbd9;
  width: 45px;
  height: 38px;
  font-size: 26px !important;
  opacity: 1;
  color: #fff !important;
  cursor: pointer !important;
}
.colorbg {
  background-color: #23b1a9;
  color: #fff;
}
.textcolor {
  color: #1a0dab;
  font-weight: 400;
}
.audit {
  color: #e46a25;
}

.descrptionSpan1 {
  width: 83%;
  margin-left: 53px;
  margin-top: 21px;
  margin-bottom: 14px !important;
  font-weight: bold;
  font-size: 16px;
}
.descriptionstyle {
  background-color: #23b1a9;
  color: #ffffff;
}
.orangetext {
  color: #e46a25;
}

.paginationText {
  font-size: 12px;
}

.selectstyle {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.groupRowHeadNewLable {
  margin-top: 16px;
  background-color: #01bbd9;
  height: 35px;
}
.GroupNameNewLable {
  font-size: 12px;
  color: white;
  font-weight: bold;
  letter-spacing: 0.2px;
}
.form-checkbox-group {
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25) !important;
  border: rgba(0, 0, 0, 0.125) solid 1px;
  padding: 10px;
  display: flex;
  height: 38px;
  justify-content: flex-start;
  align-items: center;
  column-gap: 15px;
  padding-top: 26px !important;
}
.checkboxmargin {
  margin-top: 26px;
}
</style>


