<template>
    <v-app>
        <div class="partician" >
            <v-card class="innerone"
              >
              <v-tabs v-if="true"
                v-model="tab"
                color=#004190
                center-active
                centered
                height="35"
                class="pa-1 shdwbtm"
              >
      <v-tabs-slider></v-tabs-slider>

      <v-tab  class="txtctrlnew" >
        Login With Corporate ID
      </v-tab>

      <v-tab  class="txtctrlnew" >
        Login With User ID & Password
      </v-tab>

      <v-tab  class="txtctrlnew" >
        Forgot Password
      </v-tab>

      <v-tab  class="txtctrlnew" >
        Reset Password
      </v-tab>
    </v-tabs>

        <div class="newone" style="height: 91.5%;" >
            <div class="imgitisnew" >
                <img style="width:80%" src="https://i.ibb.co/MP6CdWB/image.png" />
            </div>
            <div class="formitis" style="flex:1.2;background-color:#F6F8FB;border-bottom-right-radius: 3px;" >

                    <div v-if="tab==0">

                        <div  class="px-6 mt-3" >
                            <v-layout column align-center  class="mt-6">
                             <!-- <vue-recaptcha 
                                style="margin-left: 60px;transform:scale(0.74);-webkit-transform:scale(0.74);transform-origin:0 0;-webkit-transform-origin:0 0;" 
                                ref="invisibleRecaptchaNEW"
                                @verify="verifyMethodfed" 
                                @expired="expmethod(1)"
                                @error="expmethod(1)"
                                :sitekey="sitekey" 
                                size="10"
                                :loadRecaptchaScript="true"
                             >
                             </vue-recaptcha > -->
                             <v-img
                                :height="captchaHeight"
                                :width="captchaWidth"
                                :src="captchaImage"
                                style="margin:10px"
                            ></v-img>
                            <v-text-field
                                v-model="captchaText"
                                solo
                                dense
                                placeholder="Enter Captcha"   
                                :width="captchaWidth"
                            >
                                <template v-slot:append class="mx-0 my-0">
                                <v-icon class="iconstyledropdown" @click="loadCaptcha()"
                                    >mdi-refresh</v-icon
                                >
                                </template>
                            </v-text-field>
                            <!-- :dark="loginviewfed" :disabled="!loginviewfed" -->
                            <v-btn 
                             
                            class="checkfont" @click="verifyCaptcha(1)" small color="#1A944B" rounded dense>
                                Login
                               <v-icon dark small>arrow_forward</v-icon>
                            </v-btn>

                            </v-layout>
                            <v-divider class="mt-8" ></v-divider>
                        </div>

                    </div>

                    <div v-if="tab==1" >

                        <div  class="px-6 mt-3" >
                            <v-text-field
                            v-model="userid"
                            color="#004190"
                            label="NTID/User ID"
                            placeholder="Enter NTID/User ID"
                              maxlength="50"
                            counter="50"
                        ></v-text-field>

                         <v-text-field
                          maxlength="32"
                            counter="32"
                           color="#004190"
                            v-model="password"
                            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"

                            :type="show1 ? 'text' : 'password'"
                            name="input-10-1"
                            label="Password"
                            placeholder="Enter your Password"
                            @click:append="show1 = !show1"
                        ></v-text-field>

                         <v-layout column align-center  class="mt-6">
                               <!-- <vue-recaptcha  
                                style="margin-left: 60px;transform:scale(0.74);-webkit-transform:scale(0.74);transform-origin:0 0;-webkit-transform-origin:0 0;" 
                                ref="invisibleRecaptcha"
                                @verify="verifyMethod" 
                                @expired="expmethod(2)"
                                @error="expmethod(2)"
                                :sitekey="sitekey" 
                                size="50"
                                :loadRecaptchaScript="true"
                             >
                             </vue-recaptcha > -->

                             <v-img
                                :height="captchaHeight"
                                :width="captchaWidth"
                                :src="captchaImage"
                                style="margin:10px"
                            ></v-img>
                            <v-text-field
                                v-model="captchaText"
                                solo
                                dense
                                placeholder="Enter Captcha"   
                                :width="captchaWidth"
                            >
                                <template v-slot:append class="mx-0 my-0">
                                <v-icon class="iconstyledropdown" @click="loadCaptcha()"
                                    >mdi-refresh</v-icon
                                >
                                </template>
                            </v-text-field>
                              <!-- :dark="loginview" :disabled="!loginview" -->
                         <v-btn 
                             
                              dark  @click="verifyCaptcha(2)" class="checkfont" small
                                color="#1A944B" rounded dense>
                            Login
                            <v-icon dark small>arrow_forward</v-icon>
                         </v-btn>

                        
                         </v-layout>
                          <v-divider class="mt-8" ></v-divider>

                        </div>
                        
                          <!-- <v-divider class="mt-8 px-6" ></v-divider> -->
                    </div>

                    <div v-if="tab==2" >

                        <div  class="px-6 mt-3" >
                            <v-text-field
                                v-model="forgotPasswordId"
                                color="#004190"
                                label="NTID/User ID"
                                placeholder="Enter NTID/User ID"
                                maxlength="50"
                                counter="50"
                            ></v-text-field>

                            <v-layout column align-center  class="mt-6">
                                <!-- <vue-recaptcha  
                                    style="margin-left: 60px;transform:scale(0.74);-webkit-transform:scale(0.74);transform-origin:0 0;-webkit-transform-origin:0 0;" 
                                    ref="invisibleRecaptcha"
                                    @verify="verifyMethodForgotPassword" 
                                    @expired="expmethod(2)"
                                    @error="expmethod(2)"
                                    :sitekey="sitekey" 
                                    size="50"
                                    :loadRecaptchaScript="true"
                                >
                                </vue-recaptcha > -->

                                <v-img
                                    :height="captchaHeight"
                                    :width="captchaWidth"
                                    :src="captchaImage"
                                    style="margin:10px"
                                ></v-img>
                                <v-text-field
                                    v-model="captchaText"
                                    solo
                                    dense
                                    placeholder="Enter Captcha"   
                                    :width="captchaWidth"
                                >
                                    <template v-slot:append class="mx-0 my-0">
                                    <v-icon class="iconstyledropdown" @click="loadCaptcha()"
                                        >mdi-refresh</v-icon
                                    >
                                    </template>
                                </v-text-field>
                                <!-- :dark="forgotPasswordView" :disabled="!forgotPasswordView" -->
                                <!-- @click="forgotPassword()" class="checkfont" small color="#1A944B"  -->
                            <v-btn                            
                                @click="verifyCaptcha(3)" class="checkfont" small color="#1A944B" 
                                rounded dense>
                                Reset
                                <v-icon dark small>arrow_forward</v-icon>
                            </v-btn>

                            
                            </v-layout>
                            <v-divider class="mt-8" ></v-divider>

                        </div>
                        
                          <!-- <v-divider class="mt-8 px-6" ></v-divider> -->
                    </div>

                    <div v-if="tab==3" >

                        <div  class="px-6 mt-3" >
                            <v-text-field
                                v-model="resetPasswordId"
                                color="#004190"
                                label="NTID/User ID"
                                placeholder="Enter NTID/User ID"
                                maxlength="50"
                            ></v-text-field>

                            <v-text-field
                                v-model="oldResetPassword"
                                color="#004190"
                                label="Old Password"
                                placeholder="Enter Old Password"
                                maxlength="32"
                                dense
                            ></v-text-field>

                            <v-text-field
                                v-model="newResetPassword"
                                color="#004190"
                                label="New Password"
                                placeholder="Enter New Password"
                                maxlength="32"
                                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                :type="show1 ? 'text' : 'password'"
                                @click:append="show1 = !show1"
                                dense
                            ></v-text-field>

                            <v-text-field
                                v-model="confirmNewResetPassword"
                                color="#004190"
                                label="Confirm Password"
                                placeholder="Enter Confirm Password"
                                maxlength="32"
                                dense
                            ></v-text-field>

                            <v-layout column align-center>
                                <!-- <vue-recaptcha  
                                    style="margin-left: 60px;transform:scale(0.74);-webkit-transform:scale(0.74);transform-origin:0 0;-webkit-transform-origin:0 0;" 
                                    ref="invisibleRecaptcha"
                                    @verify="verifyMethodResetPassword" 
                                    @expired="expmethod(3)"
                                    @error="expmethod(3)"
                                    :sitekey="sitekey" 
                                    size="50"
                                    :loadRecaptchaScript="true"
                                >
                                </vue-recaptcha > -->

                                <v-img
                                    :height="captchaHeight"
                                    :width="captchaWidth"
                                    :src="captchaImage"
                                    style="margin:10px"
                                ></v-img>
                                <v-text-field
                                    v-model="captchaText"
                                    solo
                                    dense
                                    placeholder="Enter Captcha"   
                                    :width="captchaWidth"
                                >
                                    <template v-slot:append class="mx-0 my-0">
                                    <v-icon class="iconstyledropdown" @click="loadCaptcha()"
                                        >mdi-refresh</v-icon
                                    >
                                    </template>
                                </v-text-field>
                                
                                <!-- :dark="resetPasswordView" :disabled="!resetPasswordView" -->
                                <!-- @click="resetNewPassword()" class="checkfont" small color="#1A944B"  -->
                                
                            <v-btn                             
                                @click="verifyCaptcha(4)" class="checkfont  mt-n3" small color="#1A944B" 
                                rounded dense>
                                Reset
                                <v-icon dark small>arrow_forward</v-icon>
                            </v-btn>

                            
                            </v-layout>
                            <v-divider class="mt-8" ></v-divider>

                        </div>
                        
                          <!-- <v-divider class="mt-8 px-6" ></v-divider> -->
                    </div>
            </div>


        </div>
               
             </v-card>
            
        </div>  
      <center v-if="false" > 

             <v-btn @click="usersession" > session is ? </v-btn>

             <v-btn @click="redi" > redirect test 2 </v-btn>

             <v-btn @click="getref" >ref t</v-btn>
           
      </center>

      <v-row justify="center">
        <v-dialog v-model="resetPasswordDialog" persistent max-width="400px">
            <v-card>
            <v-card-title
                class="pl-6 px-0 py-0 pt-1 dialogBoxCardTitle">
                <span class="titlemargin">Reset Password</span>
                <!-- <div class="windowClsclass">
                <v-icon @click="resetPasswordDialog = false" class="ml-2 mt-2">mdi-window-close</v-icon>
                </div> -->
            </v-card-title>
            <v-card-text class="ContainerBGColor">
                <v-container class="px-0">
                <v-layout
                    column
                    class="px-4 py-2 layoutdivCls">
                    <p class="mb-2 claimlabel">New Password  <span class="mandatorycolor">*</span></p>
                    <v-text-field
                        placeholder="Enter..."
                        solo
                        dense
                        @click:append="() => (e1 = !e1)"
                        :append-icon="e1 ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="e1 ? 'password' : 'text'"
                        :maxLength="32"
                        v-model="resetPassword"
                        @change="validatePassword()"
                        class="forminput">
                        <!-- <template v-slot:append class="my-0 mr-2">
                            <v-icon
                            class="blueiconstyle addresspagepointer"
                            >mdi-eye</v-icon>
                        </template> -->
                    </v-text-field>
                    <p class="mb-2 claimlabel">Confirm Password  <span class="mandatorycolor">*</span></p>
                    <v-text-field
                        placeholder="Enter..."
                        solo
                        dense
                        :maxLength="32"
                        v-model="confirmResetPassword"
                        @change="validatePassword()"
                        class="forminput">
                        <template v-slot:append class="my-0 mr-2">
                            <v-icon
                            class="blueiconstyle addresspagepointer"
                            >mdi-lock</v-icon>
                        </template>
                    </v-text-field>
                    <div>
                        <v-row>
                            <v-col align="right" class="mt-5">
                                <v-btn
                                    color="#E46A25"
                                    class="buttonscolor mr-5"
                                    :disabled="checkPasswordValid"
                                    @click.prevent="submitResetPassword()">
                                    <v-icon class="mr-2 iconfontsize" > mdi-lock-plus </v-icon>
                                    <div class="vertical_line"></div>Submit
                                </v-btn>
                                <v-btn
                                    color="#152F38"
                                    class="buttonscolor mr-5"
                        
                                    @click="closeResetPasswordDialog()">
                                    <v-icon class="mr-2 iconfontsize" > mdi-window-close </v-icon>
                                    <div class="vertical_line"></div>Cancel
                                </v-btn>
                            </v-col>
                        </v-row>
                    </div>
                </v-layout>
                </v-container>
            </v-card-text>
            </v-card>
        </v-dialog>
        </v-row>

        <v-row justify="center">
        <v-dialog v-model="forgotPasswordDialog" persistent max-width="400px">
            <v-card>
            <v-card-title
                class="pl-6 px-0 py-0 pt-1 dialogBoxCardTitle">
                <span class="titlemargin">Reset Password</span>
                <!-- <div class="windowClsclass">
                <v-icon @click="resetPasswordDialog = false" class="ml-2 mt-2">mdi-window-close</v-icon>
                </div> -->
            </v-card-title>
            <v-card-text class="ContainerBGColor">
                <v-container class="px-0">
                <v-layout
                    column
                    class="px-4 py-2 layoutdivCls">
                    <p class="mb-2 claimlabel">Verification Code  <span class="mandatorycolor">*</span></p>
                    <v-text-field
                        placeholder="Enter..."
                        solo
                        dense
                        :maxLength="32"
                        v-model="verificationCode"
                        class="forminput">
                        <template v-slot:append class="my-0 mr-2">
                            <v-icon
                            class="blueiconstyle addresspagepointer"
                            >mdi-lock</v-icon>
                        </template>
                    </v-text-field>
                    <p class="mb-2 claimlabel">New Password  <span class="mandatorycolor">*</span></p>
                    <v-text-field
                        placeholder="Enter..."
                        solo
                        dense
                        :maxLength="32"
                        v-model="resetForgotPassword"
                        @click:append="() => (e1 = !e1)"
                        :append-icon="e1 ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="e1 ? 'password' : 'text'"
                        class="forminput">                       
                    </v-text-field>
                    <div>
                        <v-row>
                            <v-col align="right" class="mt-5">
                                <v-btn
                                    color="#E46A25"
                                    class="buttonscolor mr-5"
                                    @click.prevent="submitForgotPassword()">
                                    <v-icon class="mr-2 iconfontsize" > mdi-lock-plus </v-icon>
                                    <div class="vertical_line"></div>Submit
                                </v-btn>
                                <v-btn
                                    color="#152F38"
                                    class="buttonscolor mr-5"                        
                                    @click="closeForgotPasswordDialog()">
                                    <v-icon class="mr-2 iconfontsize" > mdi-window-close </v-icon>
                                    <div class="vertical_line"></div>Cancel
                                </v-btn>
                            </v-col>
                        </v-row>
                    </div>
                </v-layout>
                </v-container>
            </v-card-text>
            </v-card>
        </v-dialog>
        </v-row>

    </v-app>  
</template>

<script>

import adata from '../app/auth.js'
import Amplify, { Auth } from "aws-amplify"
import { VueRecaptcha } from 'vue-recaptcha';
// import { VueReCaptcha } from 'vue-recaptcha-v3'
import { useReCaptcha } from "vue-recaptcha-v3";
import axios from "axios"; 
import * as AWS from 'aws-sdk/global';
var AmazonCognitoIdentity = require('amazon-cognito-identity-js');


export default {

    components: {
        VueRecaptcha
      
    },
    created(){
      console.log("called created")
      this.clearall()
      this.loadCaptcha()
         
    },

    computed:{

        checkPasswordValid(){
            return !(this.resetPassword.length > 8 && this.confirmResetPassword.length > 8 && this.resetPassword === this.confirmResetPassword);
        }

    },
    
    data(){
        return{
            setcaptwo:false,
            setcapone:false,
            loginview:false,
            loginviewfed:false,
            forgotPasswordView:false,
            resetPasswordView:false,
            userid:'',
            password:'',
            show1:false,
            tab: null,
            // sitekey:'6LcO0ekdAAAAAB148N3H2GHgHab2pkw6mryeojhQ',
             sitekey:'6LcU36QdAAAAAPdVpBrrXRYGOkrTFEyJNgziT0BV',
            resetPasswordDialog    :   false,
            resetPassword:"",
            confirmResetPassword:"",
            e1: true,
            forgotPasswordId:"",
            forgotPasswordDialog:false,
            resetForgotPassword:"",
            verificationCode:"",
            resetPasswordId:"",
            oldResetPassword:"",
            newResetPassword:"",
            confirmNewResetPassword:"",
            fieldTypes: {
                password: "input-10-1",
            },
            captchaImage:"",
            verificationId:"",
            captchaHeight:0,
            captchaWidth:0,
            captchaText:"",

        }
    },
    methods:{

        clearall(){
              this.$store.commit('SET_SCREEN_REBOOT', false)
              this.$store.commit('SET_LOGINDATA',null);

            // alert("clear all called")
            let fetchvalue = this.getCOGNITO()
            console.log('fetchvalue clearall',fetchvalue)
            
            if(fetchvalue.length){
                    // this.process_response(response)
                
                    fetchvalue.forEach(e=>{
                        localStorage.removeItem(e)
                    })

            }
        },

        verifyMethodfed(verifyresponse){
                console.log('verifyresponse fed',verifyresponse);
                // this.loginviewfed = true
                
                if(verifyresponse){
                   
                   this.checkcaptcharesponse(0,verifyresponse)

                }else{
                    this.showToast("An error occurred with reCaptcha",this.TOST().ERROR);
                    
                }
                // loginviewfed

        },

        verifyMethod(verifyresponse){
                                //  this.loginview = true
                console.log("CATCHA VERIFIED >>--> ",verifyresponse)
                if(verifyresponse){   
                   this.checkcaptcharesponse(1,verifyresponse)
                }else{
                    this.showToast("An error occurred with reCaptcha",this.TOST().ERROR);
                }
            
        },

        checkcaptcharesponse(intype,verifyresponse){
                let self = this
                 axios.get('https://claimsdrive.salesdrive.app/common-system-service/users/get-captcha?response='+verifyresponse).then(cap_response=>{

                        console.log("response from captcha token is",cap_response);
                        if(cap_response){
                            if(cap_response.data.data.success){
                                if(intype == 1){
                                    self.loginview = true
                                    self.loginviewfed = false
                                    self.forgotPasswordView =   false
                                    self.resetPasswordView  =   false
                                }else if(intype == 2){
                                    self.loginviewfed = false
                                    self.loginview =false
                                    self.forgotPasswordView =   true
                                    self.resetPasswordView  =   false
                                }else if(intype == 3){
                                    self.loginviewfed = false
                                    self.loginview =false
                                    self.forgotPasswordView =   false
                                    self.resetPasswordView  =   true
                                }
                                else{
                                    self.loginviewfed = true
                                    self.loginview =false
                                    self.forgotPasswordView =   false
                                    self.resetPasswordView  =   false
                                }
                                //  console.log("self.$refs.invisibleRecaptchaNEW ",self.$refs)
                                //  self.$refs.invisibleRecaptchaNEW.reset()
                            }else{
                                self.showToast("No Human found",self.TOST().ERROR);
                            }
                        }else{
                            self.showToast("No response found",self.TOST().ERROR);
                        }

                 }).catch(error=>{
                        console.log("Catch captcha token is->",error);
                        self.showToast("Something went wrong",self.TOST().ERROR);


                 })


        },

        expmethod(){
            this.loginview = false
            this.loginviewfed   =   false
            this.forgotPasswordView =   false            
            this.resetPasswordView =   false
        },

        loginnow(indata){

            if(indata == 1){
                // adata.federatedSignIn();

                // return
                console.log(",adata.fedURI ",adata.fedURI)
                // alert("fed login function called")
                
               window.location.href = adata.fedURI

                return
            }

            console.log("this.userid", this.userid)
            console.log("this.password", this.password)

            if(!this.userid){
                this.showToast("Please Enter UserID and Password to continue",this.TOST().WARNING);
                return
            }

            if(!this.password){
                this.showToast("Please Enter UserID and Password to continue",this.TOST().WARNING);   
                return
            }

            if(!this.userid && !this.password){
                this.showToast("Please Enter UserID and Password to continue",this.TOST().WARNING);
                return
            }

            if(this.password.length < 4){
                this.showToast("Password should have atleast 4 characters",this.TOST().WARNING);
                return
            }

            let userobjis = {
                Username:this.userid,
                Password:this.password,
                clientId: adata.web_client.CLIENT_ID
            }

            console.log("user obj", adata, userobjis);
           
            this.POST(100, 'session/login',userobjis, (response, erroris)=>{

                if(response != null){
                    console.log("response is ", response.data)
                    if(response.data && response.data.newPasswordRequired){
                        this.openResetPasswordDialog();
                        return
                    }else if(response.data && response.code ==  "NotAuthorizedException"){
                        this.showAlert("Invalid Username or Password.");
                        return
                    }else if(response.data.redirectUrl){
                        window.open(response.data.redirectUrl,"_self")
                        return
                    }
                    let authdata = {user:null}

                    authdata.user = this.process_response(response)
                    // authdata.user = 
                   
                    // authdata.user.signInUserSession.idToken 

                    console.log(JSON.stringify(authdata),"authdata is >",authdata)
                    console.log('authdata.user.signInUserSession ',authdata.user.signInUserSession);
                    window.localStorage.setItem('cognito.'+'type'+'x', JSON.stringify(authdata.user.signInUserSession.idToken.jwtToken));
                    window.localStorage.setItem('cognito.'+'type'+'y', JSON.stringify(authdata.user.signInUserSession.accessToken.jwtToken));
                    
                    this.$store.commit('SET_LOGINDATA',authdata);
                    this.$router.push('/home')
                }else{
                    console.log(response,'<res|error>',erroris.response.data)
                     let errMsg = erroris.response && erroris.response.data && erroris.response.data.message ? erroris.response.data.message : "Something went wrong, please try again.";
                        errMsg  =   erroris.response && erroris.response.data &&  erroris.response.data.code  ==  "NotAuthorizedException" ? "Invalid Username or Password." : errMsg
                    this.showToast(errMsg,this.TOST().WARNING);
                }
                
        
            })

            // return
            // adata.signinnow(userobjis, res=>{

            //     if(res != null){
            //         console.log("res is ",res)
            //         this.$router.push('/home')

            //     }else{
            //         this.showToast("An error occurred while authenticating",this.TOST().ERROR);
            //     }

            // })

        },

        async submitis() {
            try {

                const { executeRecaptcha, recaptchaLoaded } = useReCaptcha();

         
                 await recaptchaLoaded();
               const token = await executeRecaptcha("login");

      
                console.log('re-captcha token ->',{ token }); 

            }catch(e){
                console.log("catch exception",e)
            }
        },

        getref(refT){
                adata.auth.getSession((err,res)=>{
                     console.log("getse", res.refreshToken.token)
                refT = res.refreshToken.token
                       let regionis = process.env.VUE_APP_COGNITO_USERPOOL_ID
                regionis = regionis.split('_')[0]
                console.log(refT,"region is ",regionis)
                    
                fetch("https://cognito-idp."+regionis+".amazonaws.com/", {
                    headers: {
                        "X-Amz-Target": "AWSCognitoIdentityProviderService.InitiateAuth",
                        "Content-Type": "application/x-amz-json-1.1",
                    },
                    mode: 'cors',
                    cache: 'no-cache',
                    method: 'POST',
                    body: JSON.stringify({
                        ClientId: process.env.VUE_APP_COGNITO_CLIENT_ID,
                        AuthFlow: 'REFRESH_TOKEN_AUTH',
                        AuthParameters: {
                            REFRESH_TOKEN: refT ,
                            //SECRET_HASH: "your_secret", // In case you have configured client secret
                        }
                    }),
                }).then((res) => {
                    return res.json(); // this will give jwt id and access tokens
                }).then(final=>{
                    console.log("final refresh token response is ", final)
                    let cogs = adata.auth.getCognitoUserSession(final.AuthenticationResult)
                    console.log("getCognitoUserSession response is ", cogs)
                       adata.auth.setSignInUserSession(cogs)
                });

                })
             

        },
        
        usersession(){

            const CLIENT_ID = process.env.VUE_APP_COGNITO_CLIENT_ID;
            const APP_DOMAIN = process.env.VUE_APP_COGNITO_APP_DOMAIN;
            const REDIRECT_URI = process.env.VUE_APP_COGNITO_REDIRECT_URI;
            const USERPOOL_ID = process.env.VUE_APP_COGNITO_USERPOOL_ID;
            const REDIRECT_URI_SIGNOUT = process.env.VUE_APP_COGNITO_REDIRECT_URI_SIGNOUT;
            const APP_URL = process.env.VUE_APP_APP_URL;

                let a =  adata.auth
                console.log("A", a)
                adata.auth.getSession((err,res)=>{
                     console.log("getse", res)
                })

                var poolData = {
                    UserPoolId: USERPOOL_ID, // Your user pool id here
                    ClientId: CLIENT_ID // Your client id here
                };
                var userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
                // var cognitoUser = userPool.getCurrentUser();
                
                // if (cognitoUser != null) {
                //     cognitoUser.getSession(function(err, session) {
               
            // console.log(adata.auth.getSignInUserSession(e=> console.log(e)),"session is ?>>>")

            // console.log(Auth.currentCredentials().then(e=>console.log("CC",e)))

            // console.log('userSession ',Auth.userSession(Auth.user).then(e=> console.log("user sesion", e) ));

        //    console.log("getid", Auth.user.signInUserSession.getIdToken())

            // let a  =  Auth.userPool.getCurrentUser()
            //     console.log("curr user", a)
            //     a.getSession(e=>{
            //         console.log(e,"getSession is ?>" )
            //     })
       

        //    console.log("auth user in is ?", Auth.currentAuthenticatedUser().then(e=> console.log("currentAuthenticatedUser", e.signInUserSession.isValid())))

        },

        cuc(){
            console.log('currentUserCredentials ', Auth.currentUserCredentials());
        },

        redi(){

             window.open( ' http://localhost:8080/redihere' ,'_self');

        },

        useful(){

            var cognitoUser = userPool.getCurrentUser();

            console.log(cognitoUser);

           
                if (cognitoUser != null) {
                    cognitoUser.getSession(function(err, result) {
                        if (result) {
                            console.log('You are now logged in.');

                            //POTENTIAL: Region needs to be set if not already set previously elsewhere.
                            AWS.config.region = '<region>';

                            // Add the User's Id Token to the Cognito credentials login map.
                            AWS.config.credentials = new AWS.CognitoIdentityCredentials({
                                IdentityPoolId: 'YOUR_IDENTITY_POOL_ID',
                                Logins: {
                                    'cognito-idp.<region>.amazonaws.com/<YOUR_USER_POOL_ID>': result
                                        .getIdToken()
                                        .getJwtToken(),
                                },
                            });
                        }
                    });
                }
                //call refresh method in order to authenticate user and get new temp credentials
                // AWS.config.credentials.refresh(error => {
                //     if (error) {
                //         console.error(error);
                //     } else {
                //         console.log('Successfully logged!');
                //     }
                // });

        },

        openResetPasswordDialog(){
            this.resetPasswordDialog =  true;
        },

        closeResetPasswordDialog(){
            this.resetPasswordDialog =  false;
            this.resetPassword  =   ""
            this.confirmResetPassword   =   ""
        },

        submitResetPassword(){

             let resetPasswordObj = {
                Username:this.userid,
                Password:this.password,
                clientId: adata.web_client.CLIENT_ID,
                newPassword:    this.resetPassword
            }


        // console.log("resetPasswordObj ",resetPasswordObj)
             this.showLoader(true);
            axios.post(this.cognito_login()+'session/login',resetPasswordObj).then((response, erroris)=>{
                 this.showLoader(false);
                if(response){
                    
                    console.log("Password Reset Response",response)
                    this.userid         =   ""
                    this.password       =   ""
                    this.closeResetPasswordDialog()
                    let authdata = {user:null}
                    this.showAlert("Kindly login with new password.")
                    this.loadCaptcha()
                    return

                    authdata.user = this.process_response(response)
                    // authdata.user =  
                    // authdata.user.signInUserSession.idToken 

                    console.log("authdata is >",authdata)
                    // console.log('authdata.user.signInUserSession ',authdata.user.signInUserSession);
                    window.localStorage.setItem('cognito.'+'type'+'x', JSON.stringify(authdata.user.signInUserSession.idToken.jwtToken));
                    window.localStorage.setItem('cognito.'+'type'+'y', JSON.stringify(authdata.user.signInUserSession.accessToken.jwtToken));
                    
                    this.$store.commit('SET_LOGINDATA',authdata);
                    this.$router.push('/home')

                }else{
                    console.log(response,'<res| from else |error>',err);
                }
            }).catch(err=>{
                 this.showLoader(false);
                if(err.response && err.response.data && err.response.data.code == "NotAuthorizedException"){
                    this.showAlert("Invalid Username or Password.");
                }
                console.log('<res|error>',err);
            })

        },

        validatePassword(){
            if(this.resetPassword && this.confirmResetPassword){
                if(this.resetPassword !== this.confirmResetPassword){
                    this.showAlert("New Password and Confirm Password should be same")
                }
            }
        },
        forgotPassword(){
            if(!this.forgotPasswordId){
                this.showToast("Please Enter NTID/UserID to continue",this.TOST().WARNING);
                return
            }

            let userobjis = {
                Username:this.forgotPasswordId,
                clientId: adata.web_client.CLIENT_ID
            }

            this.POST(100, 'session/forgot-password',userobjis, (response, erroris)=>{

                if(response != null){
                    console.log("response is ", response.data)
                    if(response.data && response.data.statusCode == 200){
                        this.showToast("Verification code sent to registered mail id.",this.TOST().INFO);
                        this.openForgotPasswordDialog();
                        return
                    }else if(response.data && response.code ==  "NotAuthorizedException"){
                        this.showAlert("Invalid Username.");
                        return
                    }
                    
                }else{
                    console.log(response,'<res|error>',erroris.response)
                    let errMsg = erroris.response && erroris.response.data && erroris.response.data.message ? erroris.response.data.message : "Something went wrong, please try again.";
                    this.showToast(errMsg,this.TOST().WARNING);
                }        
            })
        },
        verifyMethodForgotPassword(verifyresponse){
            console.log("CATCHA VERIFIED >>--> ",verifyresponse)
            if(verifyresponse){   
                this.checkcaptcharesponse(2,verifyresponse)
            }else{
                this.showToast("An error occurred with reCaptcha",this.TOST().ERROR);
            }
            
        },
        openForgotPasswordDialog(){
            this.forgotPasswordDialog   =   true
        },
        closeForgotPasswordDialog(){
            this.forgotPasswordDialog   =   false;
            this.verificationCode       =   ""
            this.resetForgotPassword    =   ""
        },
        submitForgotPassword(){
            if(!this.verificationCode){
                this.showToast("Please Enter Verification Code to continue",this.TOST().WARNING);
                return
            }

            if(!this.resetForgotPassword){
                this.showToast("Please Enter New Password to continue",this.TOST().WARNING);
                return
            }

            let userobjis = {
                Username: this.forgotPasswordId,
                clientId: adata.web_client.CLIENT_ID,
                code: this.verificationCode,
                newPassword: this.resetForgotPassword
            }

            this.POST(100, 'session/forgot-password',userobjis, (response, erroris)=>{

                if(response != null){
                    console.log("response is ", response.data)
                    if(response.data && response.data.newPasswordRequired){
                        this.closeForgotPasswordDialog();
                        this.forgotPasswordId   =   ""
                        this.verificationCode   =   ""
                        this.resetForgotPassword =  ""
                        return
                    }else if(response.data && response.code ==  "NotAuthorizedException"){
                        this.showAlert("Invalid Username.");
                        return
                    }
                    this.loadCaptcha()
                    
                }else{
                    console.log(response,'<res|error>',erroris.response.data)
                    let errMsg = erroris.response && erroris.response.data && erroris.response.data.message ? erroris.response.data.message : "Something went wrong, please try again.";
                    this.showToast(errMsg,this.TOST().WARNING);
                }        
            })
        
        },
        verifyMethodResetPassword(verifyresponse){
            console.log("CATCHA VERIFIED >>--> ",verifyresponse)
            if(verifyresponse){   
                this.checkcaptcharesponse(3,verifyresponse)
            }else{
                this.showToast("An error occurred with reCaptcha",this.TOST().ERROR);
            }
            
        },
        resetNewPassword(){

            if(!this.resetPasswordId){
                this.showToast("Please Enter User Name to continue",this.TOST().WARNING);
                return
            }

            if(!this.oldResetPassword){
                this.showToast("Please Enter Old Password to continue",this.TOST().WARNING);
                return
            }

            if(!this.newResetPassword){
                this.showToast("Please Enter New Password to continue",this.TOST().WARNING);
                return
            }

            if(!this.confirmNewResetPassword){
                this.showToast("Please Enter Confirm Password to continue",this.TOST().WARNING);
                return
            }

            if(!(this.confirmNewResetPassword === this.newResetPassword)){
                this.showToast("New password and Confirm password should be same.",this.TOST().WARNING);
                return
            }

             let resetPasswordObj = {
                Username: this.resetPasswordId,
                clientId: adata.web_client.CLIENT_ID,
                oldPassword: this.oldResetPassword,                
                newPassword: this.newResetPassword
            }


        // console.log("resetPasswordObj ",resetPasswordObj)
             this.showLoader(true);
            this.POST(100,'session/change-password',resetPasswordObj,(response, erroris)=>{
                 this.showLoader(false);
                if(response !== null){
                    
                    console.log("Password Reset Response",response)
                    if(response.data && response.data.statusCode == 200){
                        this.resetPasswordId        =   ""
                        this.oldResetPassword       =   ""
                        this.newResetPassword       =   ""
                        this.confirmNewResetPassword =   ""
                        this.showAlert("Password reset successful.")
                    }                   

                }else{
                    console.log(response,'<res|error>',erroris.response.data)
                    let errMsg = erroris.response && erroris.response.data && erroris.response.data.message ? erroris.response.data.message : "Something went wrong, please try again.";
                    this.showToast(errMsg,this.TOST().WARNING);
                } 
                this.loadCaptcha()
            })

        },
         loadCaptcha(){

            this.GETM("NEW_CAPTCHA_LINK","",(res)=>{
                console.log(res)
                this.captchaImage   =   res.data.data.data
                this.verificationId =   res.data.data.verificationId
                this.captchaHeight  =   res.data.data.height
                this.captchaWidth  =   res.data.data.width
                this.captchaText   =   "";
                 this.hideProgress();
            },
            (err)=>{
                console.log(err)
                this.hideProgress();
                console.log('<res|error>',err);
                if(err.response && err.response.data && err.response.data.code == "NotAuthorizedException"){
                    this.showAlert("Invalid Username or Password.");
                }
            },true)
        },
        verifyCaptcha(tab){
            if(tab == 3){
                this.forgotPassword()
            }else if(tab == 4){
                this.resetNewPassword()
            }else{
                this.loginnow(tab)
            }
            return
            if(tab == 2 && this.userid == "SNAIDU"){
                this.loginnow(tab)
            }else if(!this.captchaText){
                this.showToast("Please enter captcha.","warning")
                return
            }else{
                let captchaData =   {
                "verificationId": this.verificationId,
                "code": this.captchaText
            }
            this.showProgress()
            this.POSTM("VERIFY_CAPTCHA_LINK",captchaData,(res)=>{
            console.log(res)
            this.hideProgress();
            if(res.data.statusCode == 200 && res.data.isValid){
                if(tab == 3){
                    this.forgotPassword()
                }else if(tab == 4){
                    this.resetNewPassword()
                }else{
                    this.loginnow(tab)
                }
                
            }else{
                    this.showAlert("Invalid Captcha text,please try again.")
                    
                    this.loadCaptcha()
                }

            },
            (err)=>{
                console.log(err)
                this.hideProgress();
                console.log('<res|error>',err);
                if(err.response && err.response.data && err.response.data.code == "NotAuthorizedException"){
                    this.showAlert("Invalid Username or Password.");
                }
                if(err.response && err.response.data && !err.response.data.isValid){
                    this.showAlert("Invalid Captcha text,please try again.");
                }
                this.captchaText    =   "";
                this.loadCaptcha()
            },true)
       
            }
        },
        handleType(event) {
      
            const { srcElement, type } = event;
            const { name, value } = srcElement;
            console.log("eveeeeeeeeeeeeeeeeeeeeeeeeee for handleType",srcElement, type,name, value);

            if(type === 'blur' && !value) {
                this.fieldTypes[name] = 'text'
            } else {
                this.fieldTypes[name] = 'password'
            }
        },
    }
    
}
</script>
<style>

.partician{
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(to bottom, #004190 10%, #004190 50%, #fff 50%, #fff 100%);
}

.txtctrlnew{
    /* color: #004190 !important; */
    text-transform:none !important;
    font-size: 12.8px !important;
    letter-spacing: 0.1px !important;
}

.shdwbtm{
    border-bottom: 2px #e7e7e780 solid;
   
}

.innerone{
    border-radius: 9px;
    border: #C1C8CC 1px solid;
    width:80%;
    height:75%;
    position: absolute;
}
.newone{
    overflow: hidden;
    display: flex;
    background-color: #fff;
    flex:3;
    border-bottom-right-radius: 3px;
    /* border:1px red dashed */
}

.checkfont > span{font-size: 11.5px !important; width:90px !important}

.imgitisnew{overflow: hidden; flex:2;display: flex;
    align-items: center;justify-content: center;}
.formitis{  
     /* #C1C8CC */
    border-left:#c1c8cc63 0.8px solid;
}

.g-recaptcha {
        border:2px red solid
    }
    .dialogBoxCardTitle{
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 7px #00000029;
    opacity: 1;
    font-size: 14px !important;
  }

  .titlemargin{
    margin-right:67%
  }

  .windowClsclass{
    border-left: 1px solid #c1c8cc; height: 40px;
  }

  .ContainerBGColor{
    background-color: #f7f7f7;
  }

  .layoutdivCls{
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 2px #00000051;
    border: 0.5px solid #c1c8cc;
    opacity: 1;
  }

  .claimlabel {
    color: #646666;
    font-size: 12px;
    font-weight: bold;
  }

  .buttonscolor {
    border-radius: 0 !important;
    height: 31px !important;
    color: #f5f6fa !important;
    text-transform: capitalize;
  }

.iconfontsize{
    font-size: 18px;
}

</style>