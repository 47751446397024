<template>
    <v-app>
        
        <!-- <v-divider></v-divider> -->
        <div>
        <div class="headercolor pa-4" style="">
            <!-- <p class="font-weight-bold mt-3 mb-3 ml-4">Search Results</p> -->
            <v-layout align-center >
                <v-flex xs4>
                    <v-layout column class="cust_FAMpngTbl pt-1">
                        <p class="font-weight-bold cstm-clr-cepdt">
                                Level
                        </p>
                        <v-autocomplete
                            v-model="level"
                            :items="computedLevelItems" 
                            :loading="isLoading"
                            :search-input.sync="searchApi"
                            color="white"
                            cache-items
                            hide-selected
                            item-value="API"
                            label="Select"
                            return-object
                            solo
                            class="rounded-0"
                            >
                            </v-autocomplete>
                    </v-layout>
                </v-flex>
                <v-flex xs4 style="">
                    <v-layout column class="cust_FAMpngTbl mx-4">
                        <p class="font-weight-bold cstm-clr-cepdt">LOB</p>
                        <!-- <v-text class="font-weight-bold cstm-clr-pd">LOB</v-text> -->
                        <v-select
                            :items="lobItems"
                            label="Select LOB"
                            v-model="lob"
                            solo
                            dense
                            class="rounded-0">
                        </v-select>
                    </v-layout>
                </v-flex>
                <!-- <div @click="searchIconData" style="background-color:#23B1A9" class="pa-1 ml-2 mt-1 elevation-3"><v-icon dark>search</v-icon></div>
                <div @click="clearSearch" style="background-color:#152F38" class="pa-1 ml-2 mt-1 elevation-3"><v-icon dark>cancel</v-icon></div> -->
                <v-layout align-center>
                    <v-btn tile @click="searchIconData" dark style="font-size:12px;background-color:#23B1A9" class="text-capitalize px-2 mx-2" small><v-icon dark small>search</v-icon> <v-divider vertical class="mx-2"></v-divider> Search</v-btn>
                    <v-btn tile @click="clearSearch" dark style="font-size:12px;background-color:#152F38" class="text-capitalize px-2 mx-2" small><v-icon dark small>cancel</v-icon> <v-divider vertical class="mx-2"></v-divider>Cancel</v-btn>
                </v-layout>
                <!-- <v-btn dark color="#23B1A9" small tile><v-icon dark small>search</v-icon></v-btn>
                <v-btn dark color="#152F38" small tile><v-icon dark small>cancel</v-icon></v-btn> -->
            </v-layout>
        </div>
        <!-- <h2>{{allowance}}</h2> -->
            <v-form v-model="tableform" ref="tableForm">
                <v-layout>
                    <v-flex xs12>
                <v-card tile>
                            <fieldset style="border:none !important;min-width: 0;max-width: 100%; width: 100%;" :disabled="allowance" >
                        
                            <v-data-table              
                                :headers="headers"
                                :items="statusData"
                                :items-per-page="15"
                                hide-default-footer
                                dense
                                allow-overflow
                            >       
                            <template v-slot:[`item.decision`]="{item}" >
                                    <v-select
                                        :disabled="allowance"
                                        label=""
                                        v-model="item.decision"
                                        solo 
                                        placeholder="Select"
                                        :items="decisionItems"
                                        class="rounded-0"
                                        @change="changeText(item)"
                                        style="font-size: 15px;position: relative;top: 10px"
                                    ></v-select>
                            </template> 
                            <template v-slot:[`item.preMaxReserveAmnt`]={item}>
                                <tr>
                                    <td class="spcit" >{{ item.preMaxReserveAmnt |moneyformat}} </td>
                                            
                                </tr>
                            </template> 
                            <template v-slot:[`item.preMaxPayAmnt`]={item}>
                                <tr>
                                    <td class="spcit" >{{ item.preMaxPayAmnt |moneyformat}} </td>
                                            
                                </tr>
                            </template> 
                            <template v-slot:[`item.maxReserveAmnt`]={item}>
                                <tr>
                                    <td class="spcit" >{{ item.maxReserveAmnt |moneyformat}} </td>
                                            
                                </tr>
                            </template>
                        
                            <template v-slot:[`item.maxPayAmnt`]={item}>
                                <tr>
                                    <td class="spcit" >{{ item.maxPayAmnt |moneyformat}} </td>
                                            
                                </tr>
                            </template>   
                            <template v-slot:[`item.dateTime`]={item}>
                                <tr>
                                    <td class="" >{{ item.dateTime}} </td>
                                            
                                </tr>
                            </template>
                            <template v-slot:[`item.rejectReason`]="{item}" >
                                    <v-text-field  
                                        :disabled="allowance" 
                                        label="Enter..."
                                        v-model="item.rejectReason"
                                        solo 
                                        class="rounded-0"
                                        style="font-size: 15px;position: relative;top: 10px"
                                        :rules="[textRule(item.rejectReason,item.decision)]"
                                    ></v-text-field>
                            </template>

                            </v-data-table>
                    </fieldset>
                
                        
                      
                </v-card>

                <v-card class="pb-3 pr-6 mb-6" style="background-color:#F7F7F7">
                     
                    <v-layout row justify-end align-center style="background-color:#f7f7f7" class="pt-2 mr-2">
                            <span class="mr-1 font-weight-bold body-1" style="color:#3C3D3D; font-size:12px !important">
                            <span class="" style="color:#E46A25;font-size:12px;">Showing {{fval}}-{{sval}} </span>out of {{totalLen}} records</span>
                            <v-divider  class="ma-2" vertical ></v-divider>
                            <v-pagination 
                                class="cust-bnkAcc-Permission"
                                v-model="page"
                                color="#152F38"
                                
                                :total-visible="9"
                                @input="nextPageBtn(page)"
                                :length="pageLength"
                                ></v-pagination>
                        </v-layout>
                </v-card>
                
                <v-card>
                    
                    <v-layout style="background-color:#F7F7F7" justify-end class="pa-4 pr-8">
                            <v-btn :dark="!allowance" :disabled="allowance" tile @click="saveData"  style="font-size:12px;background-color:#23B1A9" class="text-capitalize px-3 mx-2" small><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider> Save</v-btn>
                            <!-- Commented by Surendra(19-02-2022): D0022387 : In Financial Authority Approval Screen If Click on Close button user should come on Home page. Old code - <v-btn tile to = "/FALimitMasterSearch" -->
                            <v-btn tile to = "/home"
                            dark style="font-size:12px;background-color:#E9854D"
                            class="text-capitalize px-3 mx-2" small><v-icon dark small>cancel</v-icon> <v-divider vertical class="mx-2"></v-divider> Close</v-btn>  
                    </v-layout>
                   
                </v-card>
                </v-flex>
                </v-layout>
            </v-form>
       </div>
    </v-app>
</template>

<script>
import moment from 'moment';
let dateIs = null;
  
export default {
    created(){
        this.availableroles = this.fetchroles()
        this.$store.commit("SAVE_PAGE_HEADER", "Financial Level Approval");
        // if(this.getEditDetails(this.$router.history.current.name)){
        //     this.disableAll=this.getEditDetails(this.$router.history.current.name).isEdit
            
        // }else{
        //     this.disableAll=false
        // }
        this.dataOnCreation(this.page);
        this.setcgcontrol()
        this.GET(6,'financilauthority/all-financial-autority-levels', (res, err) => {
           if(!err){
               console.log("all-financial-autority-levels",res.data.data);
               this.levelItems = res.data.data;
            }else{
                console.log(err);
            }
        })  
        
         this.GET(3,'line-of-business/get-line-of-business-list', (res,error)=>{
            if(!error){
                console.log("response from api is",res.data.data[0].lineOfBusiness);
                this.lobItems =   res.data.data[0].lineOfBusiness
                // this.claims_env =   res.data.data[0].lineOfBusiness
            }else{
                console.log("error",error.response.data);
            }
        });

    },

    data() {
        return {
            pageLength:0,
            availableroles:[],
            allowance:true,
            fval: 0,
            sval: 0,
            totalLen: '',
            statusItemsPerPage: 10,
            page: 1,
            lastPage: false,
            tableform: false,
            level: '',
            lob: '',
            levelItems: [],
            dataItems: [],
            toggelBtn:'Financial Authority Mapping',
            showFAMapping:true,
            showFAApproval:false,
            Level:'',
            lob:'',
            lobItems:['PA','Health','AIGC','Marine','EW'],
            decisionItems:['Approved','Rejected'],
            statusData:[],
            headers:[
                {text: 'Sr. No.', value: 'SerialNumber',sortable: false,divider:true,width:'',class:"hdr-txt-clr_faApprvl hdr-bg-clr"},
                {text: 'Levels', value: 'level',sortable: false,divider:true,width:'100px',class:"hdr-txt-clr_faApprvl hdr-bg-clr"},
                {text: 'Line of Business', value: 'lob',sortable: false,divider:true,class:"hdr-txt-clr_faApprvl hdr-bg-clr"},
                {text: 'Prior Max Reserve Authorization Amt', value: 'preMaxReserveAmnt',sortable: false,divider:true,width:'140px',class:"hdr-txt-clr_faApprvl hdr-bg-clr"},
                {text: 'Prior Max Pay Authority Amt', value: 'preMaxPayAmnt',sortable: false,divider:true,width:'115px',class:"hdr-txt-clr_faApprvl hdr-bg-clr"},
                {text: 'Max Reserve Authorization Amt', value: 'maxReserveAmnt',sortable: false,divider:true,width:'140px',class:"hdr-txt-clr_faApprvl hdr-bg-clr"},
                {text: 'Max Pay Authority Amt', value: 'maxPayAmnt',sortable: false,divider:true,width:'120px',class:"hdr-txt-clr_faApprvl hdr-bg-clr"},
                {text: 'Status', value: 'decision',sortable: false,divider:true,width:'160px',class:"hdr-txt-clr_faApprvl hdr-bg-clr"},
                {text: 'Rejection Reason', value: 'rejectReason',sortable: false,divider:true,width:'180px',class:"hdr-txt-clr_faApprvl hdr-bg-clr"},
                {text: 'Initiated By', value: 'initiatedBy',sortable: false,divider:true,width:'140px',class:"hdr-txt-clr_faApprvl hdr-bg-clr"},
                {text: 'Date & Time', value: 'dateTime',sortable: false,divider:true,width:'200px',class:"hdr-txt-clr_faApprvl hdr-bg-clr"},
            ],
            searchApi: null,
            timeString: false,
            timeArr: [],
            updatedTimeDisplay: '',
            isLoading: false,
            required(){
                return v=>v && v.length>0 || "This Field is Required"
            },
            disableAll:true
        };
    },

    watch: {
        searchApi(val){
            if (this.isLoading) return 
            this.isLoading = true;
            this.GET(6,'financilauthority/search-levels-by-name/'+ val,(res, err) => {
                if(!err){
                    console.log(res);
                    // this.levelItems = res.data.data;
                }else{
                    console.log(err);
                }
            })
             this.isLoading = false;
        }
    },

    filters:{
       
        formatDate(data){// eslint-disable-line no-mixed-spaces-and-tabs
                    // console.log('Validity Data', data);

                    if (data == "" || data == "undefined" || data == null || data == '' || data == 'Invalid Date') {
                        return "-"
                    } else {
                        // var n = new Date(data);
                        return moment(data).format("DD-MM-YYYY hh:mm:ss A")//n.toLocaleString("en-IN")
                        // return moment(data,'YYYY-MM-DD').format('DD-MM-YYYY')
                    }
                },
    },

    methods:{

        fetchroles(){
             let fetchAssignRole = []
             fetchAssignRole    =   this.user_data().auth.signInUserSession.idToken.payload.CM.roleDescription;
             return fetchAssignRole;
            //   = fetchAssignRole.split(',').map(role=>{
            //                     return role.trim()
            //         })
        },    

        theroles(indata){
            let val = false;
            this.availableroles.map(e=>{
                if(e == indata){
                    console.log(e, "< matched ? >",indata)
                    val = true
                }
            })
            console.log("what's the val", val)
            return val
        },

        setcgcontrol(){
            //  role_data:{},
            //   user_data:{},
        //  console.log(' ---> ', this.$store.state.role_data);
         this.theroles('CG2') ? this.allowance = false : this.allowance = true;
        //  if(this.disableAll==false){
        //     this.allowance = true
        //  }
         console.log("allowance ", this.allowance);

        },

        nextPageBtn(page){

            console.log("PAGE:::",page)
            if(this.pageLength === page){
                this.lastPage = true
                console.log("this is last page",page);
            }else{
                this.lastPage = false
                console.log("this is not last page",page);
            }
            this.dataOnCreation(page);
        },

        dataOnCreation(page){
            this.GET(6,'financilauthority/search-financial-autority-approval?status=Initiated for approval&perPage=10&pageNo='+page, (res, err) => {
            if(!err){
                // alert("INit for approval")
                console.log(res.data.data.data);
                this.pageLength = res.data.data.total_pages;
                this.totalLen = res.data.data.total
                
                // var temp =  this.page*10;

                // this.fval = temp-9;
                // // this.sval = temp;
                // if(this.lastPage){
                //         this.sval = this.totalLen
                //         console.log("value of sval is fo",this.sval);
                //     }else{
                //         if(!this.totalLen < 10){
                //             this.sval = this.totalLen
                //             console.log("this are the pageLength for searchTable",this.totalLen);
                //          }else{
                //             this.sval = temp;
                //             console.log("value of sval is",this.sval);
                //         }
                //     }
                const start = page * this.statusItemsPerPage - this.statusItemsPerPage,
                end = start + this.statusItemsPerPage;
                console.log(start,end,"start End")
                if(this.totalLen===0){
                this.fval=0
                }else{
                this.fval=start+1;
                }

                if(this.totalLen <this.statusItemsPerPage){
                this.sval=this.totalLen
                }else if(this.totalLen<end){
                this.sval=this.totalLen
                }else{
                this.sval=end
                }


                this.dataItems = res.data.data.data;

                this.dataItems = res.data.data.data;
                this.statusData = this.dataItems.map((item,index) => {                    
                    
                    let createdByNull = '';
                    if(item.createdBy == null || item.createdBy.ntidUserId == undefined){
                        createdByNull = '';    
                    }else{
                        // createdByNull = item.createdBy.name.first + ' ' + item.createdBy.name.last
                        createdByNull = item.createdBy.ntidUserId
                    }

                    console.log("item.updatedAt Sur => ",item.updatedAt, moment(item.updatedAt).format("DD-MM-YYYY hh:mm:ss A"))

                    return {
                        vtext: false,
                        _id: item._id,
                        SerialNumber: 10*(page)-9+index,
                        level: item.level,
                        decision: item.status,
                        lob: item.lob,
                        preMaxReserveAmnt: item.prevreservingAuthorityLimit,
                        preMaxPayAmnt: item.prevpaymentAuthorityLimit,
                        maxReserveAmnt: item.reservingAuthorityLimit,
                        maxPayAmnt: item.paymentAuthorityLimit,
                        dateTime: item.updatedAt ? moment(item.updatedAt).format("DD-MM-YYYY hh:mm:ss A") : "",
                        rejectReason: item.reason,
                        initiatedBy: createdByNull
                    }
                    
                })
            }else{
                console.log(err);
            }
        })
        },

        changeText(data){
            // var tableData = this.statusData;
            for(var i=0; i<this.statusData.length; i++){
                if(this.statusData[i]._id === data._id){
                    this.statusData[i].vtext = true;
                }
            }
            console.log('Data is', data);
            if(data.decision == 'Approved' || data.decision == 'Approval Awaited'){
                data.rejectReason = '';
            }
        },

        textRule(v, typeis){
            console.log(typeis);
            console.log(v);
            if(typeis == 'Rejected'){
                return v=>v && v.length>0 || "This Field is Required"
            }else{
                console.log(v);
                return !!v
            }
            // [v => !!v || 'This field is required']
            //  required(v, typeis){
            //     //   console.log(v, "<- testing ->",typeis);
            //       if(typeis == 'Inclusions') return
            //       if(typeis == 'Exclusions') return
            //     return v=>v && v.length>0 || "This Field is Required"
            // },
        },

        searchIconData(){
            this.GET(6,'financilauthority/search-financial-autority-approval?status=Initiated for approval&level='+this.level+'&lob='+this.lob, (res, err) => {
            if(!err){
                console.log(res.data.data.data);
                this.pageLength = res.data.data.total_pages;
                   this.totalLen = res.data.data.total
                   var temp =  this.page*10;
                   this.fval = temp-9;
                   if(this.lastPage){
                        this.sval = this.totalLen
                        console.log("value of sval is fo",this.sval);
                    }else{
                        if(!this.totalLen < 10){
                            this.sval = this.totalLen
                            console.log("this are the pageLength for searchTable",this.totalLen);
                         }else{
                            this.sval = temp;
                            console.log("value of sval is",this.sval);
                        }
                    }

                this.dataItems = res.data.data.data;
                this.statusData = this.dataItems.map((item,index) => {

                    // dateIs = moment(item.updatedAt)._i.split("T")[0] +' ';
                    // console.log(dateIs);
                    // let toDateIs = moment(dateIs).format("DD-MM-YYYY");
                    // let timeIs = moment(item.updatedAt)._i.split("T")[1];
                    // console.log(item.status);
                    
                    let createdByNull = '';
                    if(item.createdBy == null || item.createdBy.ntidUserId == undefined){
                        createdByNull = '';    
                    }else{
                        // createdByNull = item.createdBy.name.first + ' ' + item.createdBy.name.last
                        createdByNull = item.createdBy.ntidUserId
                    }

                    return {
                        SerialNumber: index + 1,
                        _id: item._id,
                        level: item.level,
                        lob: item.lob,
                        decision: item.status,
                        preMaxReserveAmnt: '',
                        preMaxPayAmnt: '',
                        preMaxReserveAmnt: item.prevreservingAuthorityLimit,
                        preMaxPayAmnt: item.prevpaymentAuthorityLimit,
                        maxReserveAmnt: item.reservingAuthorityLimit,
                        maxPayAmnt: item.paymentAuthorityLimit,
                        dateTime: item.updatedAt ? moment(item.updatedAt).format("DD-MM-YYYY hh:mm:ss A") : "",
                        rejectReason: item.reason,
                        initiatedBy: createdByNull
                    }
                    
                })
            }else{
                console.log(err);
                this.showToast("No matching Results found",this.TOST().ERROR);
            }
        })
        },

        clearSearch(){
           this.level = '';
           this.lob = '';
           this.statusData = [];
           this.fval = 0;
           this.sval = 0;
           this.totalLen = 0;
          //this.dataOnCreation();
            this.page=1
            this.dataOnCreation(this.page);
        },

        // validate(){
        //    this.$refs.tableForm.validate();
        // },

        saveData(){
            
            var dataVal = false;
            console.log(this.statusData);
            for(var i=0; i<this.statusData.length; i++){
                if(this.statusData[i].vtext == true){
                    console.log("Working fine");
                    if(this.statusData[i].decision == "Rejected"){
                        console.log(this.statusData[i].decision);
                        if(this.statusData[i].rejectReason == null || this.statusData[i].rejectReason == ''){
                            console.log("Working !!!!");
                            dataVal = true;
                        }
                    }
                }
            }
            console.log(dataVal);
            if(dataVal == true){
                this.showToast("Please fill required fields",this.TOST().WARNING);
                this.$refs.tableForm.validate();
                return
            }
            var tempArr = [];
            for(var i=0; i<this.statusData.length; i++){
                if(this.statusData[i].vtext == true){
                    tempArr.push({
                        _id: this.statusData[i]._id,
                        status: this.statusData[i].decision,
                        reason: this.statusData[i].rejectReason
                    });  
                }
            }
            console.log(tempArr);
            this.timeArr = tempArr;
            if(tempArr.length > 0){
            this.PUT(6, 'financilauthority/multiple-update', tempArr, (res, err) => {
                if(!err){
                    console.log(res);
                    this.timeString = true;
                    // console.log(this.timeArr);
                    this.showToast(res.data.msg, this.TOST().SUCCESS);
                    this.dataOnCreation(this.page);
                    // this.updatedTimeDisplay = getHours() + ":" + getMinutes() + ":" + getSeconds();
                }else{
                    console.log(err);
                    console.log("err.response",err.response)
                    this.showToast(err.response && err.response.data && err.response.data.msg ? err.response.data.msg : "Something went wrong...")
                }
            })
            }else{
                 this.showToast("Please Approve a level to Update", this.TOST().WARNING);
            }
           
        },

        
        romanToNum(roman) {
            if (roman === "")           return 0;
            if (roman.startsWith("L"))  return 50 + this.romanToNum(roman.substr(1));
            if (roman.startsWith("XL")) return 40 + this.romanToNum(roman.substr(2));
            if (roman.startsWith("X"))  return 10 + this.romanToNum(roman.substr(1));
            if (roman.startsWith("IX")) return 9  + this.romanToNum(roman.substr(2));
            if (roman.startsWith("V"))  return 5  + this.romanToNum(roman.substr(1));
            if (roman.startsWith("IV")) return 4  + this.romanToNum(roman.substr(2));
            if (roman.startsWith("I"))  return 1  + this.romanToNum(roman.substr(1));
            return 0;
        },
        

        // showFAMappingData() {
        //     this.showFAMapping = true;
        //     this.showFAApproval = false;
        // },
        // showFAApprovalData() {
        //     this.showFAMapping = false;
        //     this.showFAApproval = true;
        // },
    },
    computed:{
        computedLevelItems(){
            return this.levelItems && this.levelItems.length > 0 ?
                   this.levelItems
                   .map((n) => ({name: n, num: this.romanToNum(n.split(" ").pop())}))
                    .sort((a, b) => (a.num - b.num))
                    .map(({name, num}) => name) : []
            
        }
    }
    

}
</script>

<style>
.cust_FAMpngTbl .v-text-field .v-input__slot,
.cust_FAMpngTbl .v-text-field--outline .v-input__slot {
    min-height: 42px !important;
    display: flex !important;
    align-items: center !important;
    /* border:1px red solid; */
}

.cust_FAMpngTbl .v-input .v-label {
    font-size: 13px !important;
}
.cust_FAMpngTbl .v-input {
    font-size: 13px !important;
}
.cust_FAMpngTbl .v-input input {
    font-size: 13px !important;
}
.cstm-clr-cepdt{
    color:#646666 !important;
    margin-bottom: 5px !important;
    font-size: 13px !important;
}

.cust-rmvpd-psins3 .v-text-field.v-text-field--enclosed>.v-input__control>.v-input__slot {
    padding-right: 0px !important;
}

.cust_pr2 .v-text-field .v-input__slot,
.v-text-field--outline .v-input__slot {
    min-height: 42px !important;
    display: flex !important;
    align-items: center !important;
    /* border:1px red solid; */
}

.cstm-clr-pr3{
    color:#646666 !important;
    margin-bottom: 5px !important;
    font-size: 13px !important;
}

.cust_pd1 .v-text-field .v-input__slot,
.v-text-field--outline .v-input__slot {
    min-height: 42px !important;
    display: flex !important;
    align-items: center !important;
    /* border:1px red solid; */
}

.cust-rmvpd-pr1 .v-text-field.v-text-field--enclosed>.v-input__control>.v-input__slot {
    padding: 0px !important;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
    font-size: 0.84rem !important;
}

.hdr-txt-clr_faApprvl{
    color: white !important;
    font-weight: bold;
    /* padding:0px!important;
    padding: 10px !important;
    padding-left: 14px !important; */
}
.activebackground {
    background-color: #23B1A9 !important;
    color:rgb(247 247 247) !important;
}

.inactivewhitebackground {
    background-color: #ffffff !important;
    color:#10242B !important;
}

.hdr-bg-clr{
  background-color: #23B1A9;
}
.headercolor{
    background-color:#f7f7f7;
}
.cardfont{
    font-size: 0.84rem !important;
    padding-right: 2px;
}
.spcit{letter-spacing: 0.4px;}

</style>