<template>
    <v-app class="mrtop" >
       <div>
          <v-flex xs12>
                <v-layout column class="mt-2 cust_pr_quick cust-rmvpd-pr-quick font-weight-bold graybackground" style="padding:10px;color:#000000;">
                  Master Audit Trail
                </v-layout>
            </v-flex>

         <v-flex id="claimListTable" style="overflow:hidden">
              <template>
              <v-data-table
                :headers="masterAuditHeader"
                :items="auditTrailDetail"
                hide-default-footer
                class="vDataTableStyling"
               
            >
           
               
            </v-data-table>
        </template>
           <div class="text-center graybackground pdct-stUp-bnkAcc-pgn" >
                <v-row>
                    <v-col cols="6"></v-col>
                    <v-col cols="6">
                        <v-row class="paginrow">
                            <v-spacer></v-spacer>
                        <div class="mr10" > 
                            <span class="orangetext"> Showing {{pageStartIndex }} - {{pageEndIndex}} </span>                         
                            <span> out of {{totalListCount}} records </span>
                        </div>                               
                        <v-pagination
                            v-model="page"
                            :length="getNoOfPages"
                            :total-visible="5"
                            prev-icon="mdi-arrow-left"
                            next-icon="mdi-arrow-right"
                            color="#152F38"                    
                           
                        ></v-pagination>
                        </v-row>
                    </v-col>
                 </v-row>
                 <br>
               
            </div>
          
        </v-flex>
          <v-layout  class="closwsec my-4">
                  <v-row>
                                <v-col align="center" class="mt-8 mb-3">
                                    
                                     <v-btn
                                        color="#E46A25"
                                        class="buttonscolor mr-5"
                                        @click="$router.go(-1)">
                                        <v-icon class="mr-2" style="font-size: 19px">mdi-close-circle</v-icon>
                                        <div class="vertical_line"></div>Close
                                    </v-btn>
                                </v-col>
                            </v-row>
          </v-layout>
       </div>
        </v-app>
</template>

<script>
export default {
    data(){
        return{
             pageStartIndex  :   1,
            pageRowCount  :   10,
            totalListCount  :   0,
            page            :   1,
          masterAuditHeader:  [
                 {text: 'Sr No.', align: 'start',value: 'srNo', class:"headercolor"},
                 {text: 'Name of the Master', align: 'start',value: 'masterName', sortable:false},
                 {text: 'Old Value', align: 'start',value: 'oldvalue', sortable:false},
                 {text: 'New Value', align: 'start',value: 'newvalue', sortable:false},
                 {text: 'Changed By', align: 'start',value: 'changedby', sortable:false},
                 {text: 'User Id', align: 'start',value: 'userid', sortable:false},
                 {text: 'Date of change', align: 'start',value: 'dateofchange', sortable:false},
                 {text: 'Time of change', align: 'start',value: 'timeofchange', sortable:false},
                 {text: 'Approver Id', align: 'start',value: 'approverid', sortable:false},
                 {text: 'Approver Name', align: 'start',value: 'approvername', sortable:false},
               
             ],
              auditTrailDetail:  [
                 {srNo: '01', masterName:'Aig Office',oldvalue:'sdfs',newvalue:'trrew', changedby:'admin', userid:'09989',dateofchange: '11/04/2021',timeofchange: '2:20', approverid:"admin" , approvername:'kj',},
                 {srNo: '02', masterName:'Aig Office',oldvalue:'sdfs',newvalue:'trrew', changedby:'admin', userid:'09989',dateofchange: '11/04/2021',timeofchange: '2:20', approverid:"admin" , approvername:'kj',},
                 {srNo: '03', masterName:'Aig Office',oldvalue:'sdfs',newvalue:'trrew', changedby:'admin', userid:'09989',dateofchange: '11/04/2021',timeofchange: '2:20', approverid:"admin" , approvername:'kj',},
                 {srNo: '04', masterName:'Aig Office',oldvalue:'sdfs',newvalue:'trrew', changedby:'admin', userid:'09989',dateofchange: '11/04/2021',timeofchange: '2:20', approverid:"admin" , approvername:'kj',},
                 {srNo: '05', masterName:'Aig Office',oldvalue:'sdfs',newvalue:'trrew', changedby:'admin', userid:'09989',dateofchange: '11/04/2021',timeofchange: '2:20', approverid:"admin" , approvername:'kj',},
                 {srNo: '06', masterName:'Aig Office',oldvalue:'sdfs',newvalue:'trrew', changedby:'admin', userid:'09989',dateofchange: '11/04/2021',timeofchange: '2:20', approverid:"admin" , approvername:'kj',},
                 
               
             ],
        }
    },
    computed : {
      getNoOfPages(){
            if(!this.totalListCount){
                return 0;
            }else{
                return Math.ceil(this.totalListCount / this.pageRowCount);
            }
        },
         pageEndIndex(){
            if(!this.auditTrailDetail.length){
                return 0
            }else{
                return ((this.PageStartIndex + (this.pageRowCount)) <= this.totalListCount) ? this.PageStartIndex + (this.pageRowCount) - 1 : this.totalListCount;
                
            }
        }
    },
     methods:{
            goToNextPage(page){
                console.log(this.auditTrailDetail.length)
            this.PageStartIndex =   1;
            this.totalListCount =   this.auditTrailDetail.length;
        },
       
    },
     mounted(){
            
            this.goToNextPage(1)
        }
}
</script>

<style scoped>
.vDataTableStyling {
    white-space: nowrap;
}
.graybackground {
        background-color:  #F7F7F7;
    }
    .buttonscolor {
        border-radius: 0 !important;
        height: 31px !important;
        color: #f5f6fa !important;
        text-transform: capitalize;
        float:right;
    }
.paginrow{
     margin-right:10px;
     margin-top:10px;
     align-items: center;
   }
.mr10{
  margin-right:10px;
 }
 .closwsec{
     background-color: #F7F7F7; 
     height:95px; 
     border: 0.3px solid #C1C8CC;
     margin-top:24px;
 } 
 .mrtop{
   margin-top:10px
 }
</style>