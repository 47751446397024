<template>
    <v-app>
        <div style="background-color: #FFFFFF;" column class="">
            <v-layout color="#FFFFFF" class="mx-2 mt-3" style="background-color: #FFFFFF;">
                <v-flex xs3 >
                    <v-layout column class="mx-2  cust_pr" justify-center >
                        <v-text class="font-weight-bold cstm-clr-pr" style="color:#FFFFFF" ></v-text>
                        <div style="background-color: #f7f7f7;border: 1px solid #c1c8cc;" class="pa-2 mt-5">
                            <v-checkbox class="ma-0 pa-0" v-model="isNonSrvcPrvdr" hide-details >
                                <span slot="label" class="lbl-bgclr font-weight-bold " style="font-size: 13px">Is Non-Service Provider</span>
                            </v-checkbox>
                        </div>
                    </v-layout>
                </v-flex>

                <v-flex xs3 >
                    <v-layout column class=" mx-2 cust_pr">
                        <p class="font-weight-bold cstm-clr-pr">
                            Entity Name *
                        </p>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="entityNme"
                             class="rounded-0">
                        </v-text-field>
                    </v-layout>
                </v-flex>


                <v-flex xs3 >
                    <v-layout column class="cust_pr mx-2">
                        <p class="font-weight-bold cstm-clr-pr">
                            Alias Name *
                        </p>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="alsNme"
                             class="rounded-0">
                        </v-text-field>
                    </v-layout>
                </v-flex>

                <v-flex xs3 v-if="isNonSrvcPrvdr===true">
                    <v-layout column class="cust_pr  cust-rmvpd-pr mx-2 ">
                        <p class="font-weight-bold cstm-clr-pr">
                            Telephone Number 
                        </p>
                        <v-text-field
                            solo
                           
                            label="Enter..."
                            v-model="isNonSrvcPrvdrtlphnNo"
                             class="rounded-0">
                            <div slot="prepend-inner" style="background-color:#F7F7F7;padding: 9px !important"><v-icon>local_phone</v-icon></div>
                        </v-text-field>
                    </v-layout>
                </v-flex>

                <v-flex xs3 v-if="isNonSrvcPrvdr===true">
                    <v-layout column class="cust_pr cust-rmvpd-pr mx-2 ">
                        <p class="font-weight-bold cstm-clr-pr">
                            E-mail
                        </p>
                        <v-text-field
                            solo
                            label="Enter..."
                            prepend-inner-icon="mail"
                            v-model="isNonSrvcPrvdremail"
                             class="rounded-0"
                            >
                             <div slot="prepend-inner" style="background-color:#F7F7F7;padding: 9px !important"><v-icon>mail</v-icon></div>
                        </v-text-field>
                    </v-layout>
                </v-flex>
                <v-flex xs3 v-if="isNonSrvcPrvdr===false">
                    <v-layout column class="cust_pr mx-2 ">
                        <p class="font-weight-bold cstm-clr-pr">
                            Provider Type 
                        </p>
                        <v-select
                            :items="prvdrtyeitems"
                            label="Select"
                            v-model="providerType"
                            solo
                            dense
                             class="rounded-0">
                        </v-select>
                    </v-layout>
                </v-flex>
                <v-flex xs3 v-if="isNonSrvcPrvdr===false">
                    <v-layout column class="cust_pr mx-2 ">
                        <p class="font-weight-bold cstm-clr-pr">
                            Other 
                        </p>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="other"
                             class="rounded-0">

                        </v-text-field>
                    </v-layout>
                </v-flex>
                

            </v-layout>
            <v-layout style="" class="mx-2" v-if="isNonSrvcPrvdr===false" >
                
                

                <v-flex xs3>
                    <v-layout column class="cust_pr mx-2 ">
                        <p class="font-weight-bold cstm-clr-pr">
                        Registration Number 
                        </p>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="rgstNo"
                             class="rounded-0">

                        </v-text-field>
                    </v-layout>
                </v-flex>

                <!-- <v-flex xs3>
                    <v-layout column class="mx-1 my-2">
                        <v-text class="font-weight-bold cstm-clr-pr" >
                        </v-text> -->
                        <!-- <v-btn color="#23B1A9" dark class="mt-4"><v-icon small left>arrow_upward</v-icon>Update</v-btn> -->
                        <!-- <v-btn color="#23B1A9" dark class="mt-4 text-capitalize">
                            <div class="mt-1">
                                <img src="../../assets/update.png" style="width: 16px;height: 16px"/>
                            </div>
                            <v-divider vertical class="mx-2"></v-divider>
                            Upload
                        </v-btn>
                    </v-layout>
                </v-flex> -->

                <v-flex xs3>
                    <v-layout column class="cust_pr mx-2 ">
                        <p class="font-weight-bold cstm-clr-pr">
                            Registration Valid Upto
                        </p>
                        <!-- <v-text-field
                            solo
                            label="Enter..."
                            v-model="Rgstvlduptops"
                             class="rounded-0">
                        </v-text-field> -->

                        <v-menu
                            ref="calender"
                            :close-on-content-click="true"
                            v-model="RgstrnVldUptoInstncalender"
                            :nudge-right="40"
                           
                            transition="scale-transition"
                            
                            max-width="290px"
                            min-width="290px" 
                              class="rounded-0"
                            >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    slot="activator"
                                    label="Select.."
                                    :value="cmputdRgstrnVldUptoInstdteMmntjs"
                                    solo
                                     class="rounded-0"
                                    readonly 
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                                                        
                                    <v-date-picker
                                        v-model="RgstrnVldUptoInstdate"
                                        no-title
                                        
                                    ></v-date-picker>
                        </v-menu>


                    </v-layout>
                </v-flex>
                <v-flex xs3>
                    <v-layout column class="cust_pr mx-2 ">
                        <p class="font-weight-bold cstm-clr-pr">
                            Registered Body
                        </p>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="RgstrdBdy"
                             class="rounded-0">
                        </v-text-field>
                    </v-layout>
                </v-flex>

                 <v-flex xs3>
                    <v-layout column class="cust_pr mx-2 ">
                        <p class="font-weight-bold cstm-clr-pr">
                            Services Provided
                        </p>
                        <v-select
                            :items="prvdrtyeitems"
                            label="Select"
                            v-model="SrvcsPrvded"
                            solo
                            dense
                             class="rounded-0">
                        </v-select>
                    </v-layout>
                </v-flex>
                <v-flex xs3>
                    <v-layout column class="cust_pr mx-2 ">
                        <p class="font-weight-bold cstm-clr-pr">
                            Other 
                        </p>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="other"
                             class="rounded-0">
                        </v-text-field>
                    </v-layout>
                </v-flex>
            </v-layout>
            <v-layout color="#FFFFFF" class="mx-2" v-if="isNonSrvcPrvdr===true" >
                <v-flex xs3>
                    <v-layout column class="cust_pr mx-2 mb-3">
                        <p class="font-weight-bold cstm-clr-pr">
                            Fax Number
                        </p>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="isNonSrvcPrvdrfxNo"
                             class="rounded-0">
                        </v-text-field>
                    </v-layout>
                </v-flex>
                 <v-flex xs3>
                    <v-layout column class="cust_pr custPrn_Instn mx-2 ">
                        <p class="font-weight-bold cstm-clr-pr">
                            PRN
                        </p>
                        <v-text-field
                            solo
                            label="Auto Generated on Save"
                            v-model="isNonSrvcPrvdrprn"
                            class="rounded-0"
                            disabled>
                        </v-text-field>
                    </v-layout>
                </v-flex>
                <v-flex xs3>
                    <v-layout column class="cust_pr mx-2 ">
                        <p class="font-weight-bold cstm-clr-pr">
                            Status
                        </p>
                        <v-select
                            :items="statusItems"
                            label="Select"
                            v-model="isNonSrvcPrvdrstatus"
                            solo
                            dense
                             class="rounded-0">
                        </v-select>
                    </v-layout>
                </v-flex>

                <v-flex xs3></v-flex>
                <v-flex xs3></v-flex>

            </v-layout>
            <v-divider class="mb-6"></v-divider>

            <v-layout color="#FFFFFF" class="mx-2" v-if="isNonSrvcPrvdr===false"  >
              
                <v-flex xs3>
                    <v-layout column class="cust_pr  cust-rmvpd-pr mx-2 ">
                        <p class="font-weight-bold cstm-clr-pr">
                            Telephone Number 
                        </p>
                        <v-text-field
                            solo
                           
                            label="Enter..."
                            v-model="tlphnNo"
                             class="rounded-0">
                            <div slot="prepend-inner" style="background-color:#F7F7F7;padding: 9px !important"><v-icon>local_phone</v-icon></div>
                        </v-text-field>
                    </v-layout>
                </v-flex>

                

                <v-flex xs3>
                    <v-layout column class="cust_pr custPrn_Instn mx-2 ">
                        <p class="font-weight-bold cstm-clr-pr">
                            PRN
                        </p>
                        <v-text-field
                            solo
                            label="Auto Generated on Save"
                            v-model="prn"
                             class="rounded-0"
                             disabled>
                        </v-text-field>
                    </v-layout>
                </v-flex>

                 <v-flex xs3>
                    <v-layout column class="cust_pr mx-2 ">
                        <p class="font-weight-bold cstm-clr-pr">
                            Status
                        </p>
                        <v-select
                            :items="statusItems"
                            label="Select"
                            v-model="status"
                            solo
                            dense
                             class="rounded-0">
                        </v-select>
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class="cust_pr mx-2 ">
                        <p class="font-weight-bold cstm-clr-pr">
                            PAN
                        </p>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="pan"
                             class="rounded-0">
                        </v-text-field>
                    </v-layout>
                </v-flex>

                 <v-flex xs3>
                    <v-layout column class="cust_pr mx-2 ">
                        <p class="font-weight-bold cstm-clr-pr">
                            ROHINI ID
                        </p>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="rohiniId"
                             class="rounded-0">
                        </v-text-field>
                    </v-layout>
                </v-flex>
               
                
            </v-layout>

            
            <v-layout style="" class="mx-2"  v-if="isNonSrvcPrvdr===false" >

               

                 <v-flex xs3>
                    <v-layout column class="cust_pr cust-rmvpd-pr mx-2 ">
                        <p class="font-weight-bold cstm-clr-pr">
                            E-mail
                        </p>
                        <v-text-field
                            solo
                            label="Enter..."
                            prepend-inner-icon="mail"
                            v-model="email"
                             class="rounded-0"
                            >
                             <div slot="prepend-inner" style="background-color:#F7F7F7;padding: 9px !important"><v-icon>mail</v-icon></div>
                        </v-text-field>
                    </v-layout>
                </v-flex>
               
                

                <v-flex xs3>
                    <v-layout column class="cust_pr mx-2 mb-3">
                        <p class="font-weight-bold cstm-clr-pr">
                            Fax Number
                        </p>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="fxNo"
                             class="rounded-0">
                        </v-text-field>
                    </v-layout>
                </v-flex>
                <v-flex xs3></v-flex>
                <v-flex xs3></v-flex>
                <v-flex xs3></v-flex>
                
            </v-layout>

            
            <v-divider class="mb-6" v-if="isNonSrvcPrvdr===false" ></v-divider>

            <v-layout color="#FFFFFF" class="mx-2"  >
                <v-flex xs3>
                <v-layout column class="mx-2">
                    <p class="text-uppercase font-weight-bold title-clr-pr">
                        Address
                    </p>
                    <v-divider></v-divider>
                </v-layout>
                </v-flex>
                <v-flex xs3>
                <v-layout column class="cust_pr mx-2 ">
                    <p class="font-weight-bold cstm-clr-pr">
                        Address Line 1 *
                    </p>
                    <v-text-field
                        solo
                        label="Enter..."
                        v-model="adrsLne1"
                         class="rounded-0">
                    </v-text-field>
                </v-layout>
                </v-flex>
                <v-flex xs3>
                <v-layout column class="cust_pr mx-2 ">
                    <p class="font-weight-bold cstm-clr-pr">
                        Address Line 2
                    </p>
                    <v-text-field
                        solo
                        label="Enter..."
                        v-model="adrsLne2"
                         class="rounded-0">
                    </v-text-field>
                </v-layout>
                </v-flex>
                <v-flex xs3>
                <v-layout column class="cust_pr mx-2 ">
                    <p class="font-weight-bold cstm-clr-pr">
                        Address Line 3
                    </p>
                    <v-text-field
                        solo
                        label="Enter..."
                        v-model="adrsLne3"
                         class="rounded-0">
                    </v-text-field>
                </v-layout>
                </v-flex>
                <v-flex xs3>
                <v-layout column class="cust_pr mx-2 ">
                    <p class="font-weight-bold cstm-clr-pr">
                        Country
                    </p>
                    <v-select
                        :items="cntryItems"
                        label="Select"
                        v-model="country"
                        solo
                        dense
                         class="rounded-0">
                    </v-select>
                </v-layout>
                </v-flex>
            </v-layout>

            <v-layout style="" class="mx-2" >
                <v-flex xs3>
                <v-layout column class="cust_pr mx-2 ">
                    <p class="font-weight-bold cstm-clr-pr">
                        State *
                    </p>
                    <v-select
                        :items="stateItems"
                        label="Select"
                        v-model="state"
                        solo
                        dense
                         class="rounded-0">
                    </v-select>
                </v-layout>
                </v-flex>
                <v-flex xs3>
                <v-layout column class="cust_pr mx-2 ">
                    <p class="font-weight-bold cstm-clr-pr">
                        District *
                    </p>
                    <v-select
                        :items="districtItems"
                        label="Select"
                        v-model="district"
                        solo
                        dense
                         class="rounded-0">
                    </v-select>
                </v-layout>
                </v-flex>
                <v-flex xs3>
                <v-layout column class="cust_pr mx-2 ">
                    <p class="font-weight-bold cstm-clr-pr">
                        Pin Code *
                    </p>
                    <v-text-field
                        solo
                        label="Enter..."
                        v-model="pnCde"
                         class="rounded-0">
                    </v-text-field>
                </v-layout>
                </v-flex>
                <v-flex xs3>
                <v-layout column class="cust_pr mx-2 ">
                    <p class="font-weight-bold cstm-clr-pr">
                        City/Town/Village *
                    </p>
                    <v-select
                        :items="ctvItems"
                        label="Select"
                        v-model="ctv"
                        solo
                        dense
                         class="rounded-0">
                    </v-select>
                </v-layout>
                </v-flex>
                <v-flex xs3>
                <v-layout column class="cust_pr mx-2 mb-3">
                    <p class="font-weight-bold cstm-clr-pr">
                       Other
                    </p>
                    <v-text-field
                        solo
                        label="Enter..."
                        v-model="othrAdrs"
                         class="rounded-0">
                    </v-text-field>
                </v-layout>
                </v-flex>
                
            </v-layout>
             <v-divider class="mb-6"></v-divider>

            <v-layout color="#FFFFFF" class="mx-2"  style="" v-if="isNonSrvcPrvdr===false">
                <v-flex xs3>
                    <v-layout column class="mx-2">
                        <p class="text-uppercase font-weight-bold title-clr-pr">
                            Validation Details
                        </p>
                        <v-divider></v-divider>
                    </v-layout>
                </v-flex>
                <v-flex xs3>
                    <v-layout column class="cust_pr mx-2 ">
                        <p class="font-weight-bold cstm-clr-pr">
                            No. of Beds
                        </p>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="NoofBeds"
                             class="rounded-0">
                        </v-text-field>
                    </v-layout>
                </v-flex>
                <v-flex xs3>
                    <v-layout column class="cust_pr mx-2 ">
                        <p class="font-weight-bold cstm-clr-pr">
                            Daily Medical Records
                        </p>
                        <v-select
                            :items="dlyMdclRcrdsItems"
                            label="Select"
                            v-model="dlyMdclRcrds"
                            solo
                            dense
                             class="rounded-0">
                        </v-select>
                    </v-layout>
                </v-flex>
                <v-flex xs3>
                    <v-layout column class="cust_pr mx-2 ">
                        <p class="font-weight-bold cstm-clr-pr">
                            Has Operation Theatre
                        </p>
                        <v-select
                            :items="hsOtItems"
                            label="Select"
                            v-model="hsOt"
                            solo
                             class="rounded-0">
                        </v-select>
                    </v-layout>
                </v-flex>
                <v-flex xs3></v-flex>
            
            </v-layout>
            <v-layout class="mx-2" v-if="isNonSrvcPrvdr===false" >
                <v-flex xs3>
                    <v-layout column class="cust_pr mx-2 ">
                        <p class="font-weight-bold cstm-clr-pr">
                            Medical Practitioner Round The Clock
                        </p>
                        <v-select
                            :items="mprtcItems"
                            label="Select"
                            v-model="mprtc"
                            solo
                            dense
                             class="rounded-0">
                        </v-select>
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class="cust_pr mx-2 ">
                        <p class="font-weight-bold cstm-clr-pr">
                            Nursing Round The Clock
                        </p>
                    
                        <v-select
                            :items="nrtcItems"
                            label="Select"
                            v-model="nrtc"
                            solo
                            dense
                             class="rounded-0">
                        </v-select>
                        
                    </v-layout>
                </v-flex>
                <v-flex xs3></v-flex>
                <v-flex xs3></v-flex>
                <v-flex xs3></v-flex>
            </v-layout>
            
             <v-layout class="ma-3 pa-2" justify-space-between style="background-color: #F7F7F7"  >
                
                <v-layout style="flex:1" justify-end>
                    <v-btn tile dark color="#23B1A9" class="text-capitalize px-3 mx-2" small><v-icon dark small>cloud_upload</v-icon> <v-divider vertical class="mx-2"></v-divider> Upload</v-btn>
                    <v-btn tile dark color="#23B1A9" class="text-capitalize px-3 mx-2" small><v-icon dark small>cloud_upload</v-icon> <v-divider vertical class="mx-2"></v-divider> Update Flag</v-btn>
                    <v-btn dark  tile color="#111212" class="text-capitalize px-3 mx-2" small ><v-icon dark small>mdi-arrow-left-circle</v-icon><v-divider vertical class="mx-2"></v-divider>Send Back</v-btn>
                    <v-btn dark  tile color="#E46A25" class="text-capitalize px-3  mx-2" small @click="opn_dwnld_dlg()"><v-icon dark small>mdi-checkbox-marked-circle</v-icon> <v-divider vertical class="mx-2"></v-divider>Validate</v-btn>
                    <v-btn dark tile color="#D1121B" class="text-capitalize px-3  mx-2" small @click="opn_dwnld_dlg()"><v-icon dark small>mdi-timer</v-icon> <v-divider vertical class="mx-2"></v-divider>Decline</v-btn>
                    <v-btn dark tile color="#23B1A9" class="text-capitalize px-3  ml-2 " small><v-icon dark small style="transform: rotate(45deg);">mdi-arrow-left-circle</v-icon> <v-divider vertical class="mx-2"></v-divider> Exit</v-btn>
                </v-layout>
            </v-layout>

            <v-flex>  
            </v-flex>
             
           
            
            
        </div>

        
    </v-app>
</template>

<script>
import moment from 'moment';
export default {

    components:{
        
    },

    created() {

        this.$store.commit("SAVE_PAGE_HEADER", "Provider Registration");

    },

    computed: {

         cmputdRgstrnVldUptoInstdteMmntjs(){
            return this.RgstrnVldUptoInstdate ? moment(this.RgstrnVldUptoInstdate).format("DD-MM-YYYY"): ''
        },

        cmputdRegistrationDateFromjs(){
            return this.RegistrationDateFrom ? moment(this.RegistrationDateFrom).format("DD-MM-YYYY"): ''
        },
        cmputdRegistrationDateTojs(){
            return this.RegistrationDateTo ? moment(this.RegistrationDateTo).format("DD-MM-YYYY"): ''
        },
        cmputdRegistrationDatejs(){
            return this.RegistrationDate ? moment(this.RegistrationDate).format("DD-MM-YYYY"): ''
        }

    },

    data() {
        return {
            isNonSrvcPrvdr: false,
            items:['Institution', 'Individual'],
            rgstrdEntity:'Institution',
            entityNme:'',
            alsNme:'',
            prvdrtyeitems:['Others'],
            providerType:"",
            other:"",
            rgstNo:"",
            RgstrtnVldUpto:"",
            RgstrnVldUptoInstncalender: false,
            RgstrnVldUptoInstdate: "",
            SrvcsPrvded:"",
            tlphnNo:"",
            isNonSrvcPrvdrtlphnNo:"",
            pan:"",
            prn:"",
            isNonSrvcPrvdrprn:"",
            status:"",
            isNonSrvcPrvdrstatus:"",
            statusItems:["Active", "Inactive"],
            rohiniId:"",
            RgstrdBdy:"",
            email:"",
            isNonSrvcPrvdremail:'',
            fxNo:"",
            isNonSrvcPrvdrfxNo:"",
            adrsLne1:"",
            adrsLne2:"",
            adrsLne3:"",
            country:"",
            cntryItems:["India","Others"],
            stateItems:["Maharashtra","Gujarat","Karnataka"],
            state:"",
            district:"",
            districtItems:["Thane", "Palghar","Raigad"],
            pnCde:"",
            ctvItems:["City","Town","Village", "Others"],
            ctv:"",
            othrAdrs:"",
            NoofBeds:"",
            dlyMdclRcrdsItems:[],
            dlyMdclRcrds:"",
            hsOtItems:[],
            hsOt:"",
            mprtcItems:[],
            mprtc:"",
            nrtcItems:[],
            nrtc:"",
            shwInstitution:true,
            shwIndividual:false,
            salutationind:'',
            sltnIndItems:["Mr.","Mrs"],
            frstnmeInd:'',
            mdlnmeInd:'',
            lstnmeInd:'',
            gndrInd:'',
            mbNoInd:'',
            extrnlRfNoInd:'',
            prnInd:'',
            statusInd:"",
            statusIndItems:["Active", "Inactive"],
            prvdrTypeInd:'',            
            otherInd:'',
            qualificationInd:'',
            otherInd2:'',
            RgstNoItems:['Others'],
            RgstNo:'',
            RgstCnlInd:'',
            OtherInd3:'',
            QualificationInd2:'',
            remarksInd:'',
            smplefileDwnldDilg:false,
            regDatefrom:false,
            RegistrationDateFrom: "",
            RegDateTo:false,
            RegistrationDateTo:"",
            RegDate:false,
            RegistrationDate:"",

        };



    },

    methods: {

        rgstrdEntySlctn(value){
            console.log(value)
            if(value==="Institution"){
                this.shwInstitution=true
                this.shwIndividual=false
            }else if(value==="Individual"){
                this.shwInstitution=false
                this.shwIndividual=true
            }

        }
     
    },

    watch: {
    
    },
    
    
}

</script>
<style >
.cust_pr .v-text-field .v-input__slot,
.v-text-field--outline .v-input__slot {
    min-height: 42px !important;
    display: flex !important;
    align-items: center !important;
    /* border:1px red solid; */
}

.cust-rmvpd-pr .v-text-field.v-text-field--enclosed>.v-input__control>.v-input__slot {
    padding: 0px !important;;
}
/* .cust_pr .v-input__slot{
    margin-bottom: unset !important;
} */
.cstm-clr-pr{
    color:#646666;
    margin-bottom: 5px;
    font-size: 13px !important;
}
.cust_pr .v-input {
    font-size: 13px !important;
}
.cust_pr .v-input input {
    font-size: 13px !important;
}
.cust_pr .v-input .v-label {
    font-size: 13px !important;
}

.title-clr-pr{
    color:#10242B;
    margin-bottom: 5px;
}

.lbl-bgclr{
    color:#152F38

}

.custPrn_Instn .theme--light.v-text-field--solo > .v-input__control > .v-input__slot {
    background: #F7F7F7;
}

</style>