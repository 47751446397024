<template>
<v-app>

<!-- for Hospital search -->
        <div style="background-color: #FFFFFF;" column class="py-3">
            
            <v-form v-model="valid" ref="roleform">
            
            <v-layout class=" mt-2" >
            
                <v-flex xs3 class="ml-4">
                    <v-layout column class="cust_pd mx-4 my-2 ">
                        <v-text class="font-weight-bold cstm-clr-pd">
                            City/Town/Village
                        </v-text>
                        <v-autocomplete
                                    v-model="cityTown"
                                    :items="searchedItems"
                                    :loading="isLoading"
                                    :search-input.sync="searchCity"
                                    color="white"
                                    cache-items
                                    label="City ..."
                                    class="rounded-0 delpad"
                                    return-object
                                    solo
                                    >
                                    <div slot="append" style="background-color:#F7F7F7;padding:9px !important;cursor:pointer;"><v-icon>search</v-icon></div>
                                     
                        </v-autocomplete>
                        
                        <!-- <v-select
                            :items="GetCityList"
                            label="Select"
                            v-model="cityTown"
                            solo
                            dense
                            class="rounded-0"
                            :rules="[]"
                            >
                        </v-select> -->
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class=" mx-4 my-2 cust_pd">
                        <v-text class="font-weight-bold cstm-clr-pd">
                            State
                        </v-text>
                        <v-select
                            :items="GetStatelist"
                            item-text="state"
                            item-value="state"
                            label="Select"
                            v-model="state"
                            solo
                            dense
                            class="rounded-0 "
                            :rules="[]"
                            >
                        </v-select>
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class=" mx-4 my-2 cust_pd">
                        <v-text class="font-weight-bold cstm-clr-pd">
                            Pincode
                        </v-text>

                        <v-autocomplete
                                   
                                    v-model="pincode"
                                    :items="searchedItemsPin"
                                    :loading="isLoadingPin"
                                    :search-input.sync="searchPincode"
                                    color="white"
                                    cache-items
                                    label="Pincode ..."
                                    class="rounded-0 delpad"
                                    return-object
                                    solo
                                    

                                    >
                                    <div slot="append" style="background-color:#F7F7F7;padding:9px !important;cursor:pointer;"><v-icon>search</v-icon></div>
                                     
                        </v-autocomplete>
                        <!-- <v-text-field
                            solo
                            label="Enter..."
                            v-model="pincode"
                            :rules="[]"
                            class="rounded-0"
                            >
                        </v-text-field> -->
                    </v-layout>
                </v-flex>
                <v-flex xs3>
                    <v-layout column class=" mx-4 my-2 cust_pd">
                        <v-text class="font-weight-bold cstm-clr-pd">
                            District
                        </v-text>
                        <v-autocomplete
                                  
                                    v-model="district"
                                    :items="searchedItemsDist"
                                    :loading="isLoadingDist"
                                    :search-input.sync="searchDistrict"
                                    color="white"
                                    cache-items
                                    label="District ..."
                                    class="rounded-0 delpad"
                                    return-object
                                    solo
                                    >
                                    <div slot="append" style="background-color:#F7F7F7;padding:9px !important;cursor:pointer;"><v-icon>search</v-icon></div>
                                     
                        </v-autocomplete>



                        <!-- <v-text-field
                            solo
                            label="Enter..."
                            v-model="district"
                            :rules="[]"
                            class="rounded-0"
                            >
                        </v-text-field> -->
                    </v-layout>
                </v-flex>
            
                
                                            
            </v-layout>

        </v-form>
        
            <v-divider class="mt-2 mb-1"></v-divider>
                
            
            <div class="mx-2 pa-5" style=" display:flex;justify-content:space-between;"  >
                <v-layout style="flex:1" justify-end > 
                    <v-btn dark  tile @click="seekSearchData(1)" color="#E46A25" class="text-capitalize px-3 mx-2" small><v-icon dark small>search</v-icon> <v-divider vertical class="mx-2"></v-divider>Search</v-btn>
                    <v-btn tile @click="clearbtn()" dark small color="#E46A25" class="clearbtn text-capitalize px-3 mx-2"><v-icon dark small >clear</v-icon > <v-divider vertical class="mx-2"></v-divider>Clear All</v-btn>
                    <v-btn dark  tile @click="returnbtn()" color="#000" class="text-capitalize px-3 mx-2" small><v-icon dark small>arrow_back</v-icon> <v-divider vertical class="mx-2"></v-divider>Return</v-btn>
                </v-layout>
            </div>

    </div>

    <div v-if="showSearchdata===true">
        <v-card class="rounded-0">
            <v-layout class="headercolor">
                <p class="cardfont" style="font-weight:700;margin-bottom:7px!important;margin-top:10px;padding-left:10px;">Provider List</p>
            </v-layout>
        
            <v-data-table
                    :headers="headers"
                    :items="GetAllData"
                    :page.sync="page"
                    :items-per-page="itemsPerPage"
                    hide-default-footer
                    dense

                    @page-count="pageCount = $event"
                
                >

                <template v-slot:item.entityName="{ item }">
                    <div @click="claimClick(item)" class="text-decoration-underline" style="color:#E46A25; cursor:pointer; font-size:12px !important">{{ item.entityName}}</div>
                </template>
        </v-data-table>
        
    
            <v-divider></v-divider>
                
                <v-layout row justify-end align-center class="ma-2" >
                    <v-text class="mr-1 font-weight-bold" style="color:#3C3D3D; font-size:13px;">
                        <span class="" style="color:#E46A25;font-size:12px;">Showing {{fval}}-{{sval}} </span>out of {{totalLen}} records
                    </v-text>
                    <v-divider  class="mx-2" vertical ></v-divider>
                    <v-pagination
                        class="pdct-stUp-bnkAcc-pgn"
                                v-model="page"
                                color="#152F38"
                                flat
                                @input="nextPageData(page)"
                                :total-visible="9"
                                :length="pagelength"
                    ></v-pagination>
                </v-layout>
            </v-card>
        </div>
            <div>
            <v-card tile v-if="showrsltnotfound===true" class="my-3 " style="background-color:#F7F7F7;border:1px solid #C1C8CC;flex:1;">
                    <v-layout row justify-start class="">
                            <v-text class="mr-1 mx-5 pa-3 font-weight-bold body-1 text-capitalize" style="color:#3C3D3D; font-size:16px !important">
                                NO RESULTS FOUND
                            </v-text>
                    </v-layout>
                        
            </v-card>
            </div>

    </v-app>
</template>

<script>

import axios from "axios";
    // regex for only alphabets and a space before after and in between
  let d_regex = /^[a-zA-Z ]+$/  

export default {
   

    created() {
        
         console.log("Success Token", this.$store.getters.successToken)
         this.token=this.$store.getters.successToken
         this.$store.commit("SAVE_PAGE_HEADER", "Hospital Search");

          this.getAllHospital(null,this.page)
          this.GetStatelistData()
        //   this.SearchHospital(this.page)

          
    },

    data() {
        return {
           
            cityTown:'',
            state:'',
            pincode:'',
            district:'',

            searchEntries: [],
            isLoading: false,
            searchCity: null,
            searchDataArray:[],

            searchEntriesPin: [],
            isLoadingPin: false,
            searchPincode: null,
            searchDataArrayPin:[],

            searchEntriesDist: [],
            isLoadingDist: false,
            searchDataArrayDist:[],
            searchDistrict:null,

            GetCityList:['Agra','Nagpur','Vijaywada'],
            GetStatelist:[],

             GetAllData:[],
            GetAllData1:[
                {
                    SerialNumber:"2",
                    providerName:"XYZ",
                    providerType:"TYPE2",
                    aliasName:"Ram", 
                    address:'Sita Bardi', 
                    city:'Nagpur',
                    state:'Maharashtra'
                },
            ],
            
            typesearch:false,
            swap_final_count:false,
            fval:0,
            sval:0,
            totalLen:0,
            showrsltnotfound:false,
            page: 1,
            pagelength:0,
            itemsPerPage: 20,
                            
            headers:[
                
                {text: 'Sr. No.', value: 'SerialNumber',sortable: true,divider:true,class:"hdr-txt-clr-clr hdr-bg-clr srNoWidth1"},
                {text: 'Provider Name', value: 'entityName',sortable: true,divider:true,class:"hdr-txt-clr-clr  hdr-bg-clr"},
                {text: 'Provider Type', value: 'providerType',sortable: true,divider:true,class:"hdr-txt-clr-clr hdr-bg-clr"},
                {text: 'Alias Name', value: 'aliasName',sortable: true,divider:true,class:"hdr-txt-clr-clr hdr-bg-clr"},
                {text: 'Address', value: 'addressLine1',sortable: true,divider:true,class:"hdr-txt-clr-clr hdr-bg-clr"},
                {text: 'City/Town', value: 'cityTownVillage',sortable: true,divider:true,class:"hdr-txt-clr-clr hdr-bg-clr"},
                {text: 'State', value: 'state',sortable: true,divider:true,class:"hdr-txt-clr-clr hdr-bg-clr"},

            ],
            required(){
                return v=>v && v.length>0 || "This Field is Required"
            }
        };

    },
    filters:{
      
        checkdata(data){// eslint-disable-line no-mixed-spaces-and-tabs
            // console.log('Validity Data', data);
            if (data == "" || data == "undefined" || data == null || data == '' || data == 'Invalid Date') {
                return "-"
            } else {
                return data;
            }
        },
        
    },
    computed: {
      
        // numericcheck(){
        //       const errors = []
        //         if (!this.$v.salutationind.$dirty) return errors
        //         !this.$v.salutationind.required && errors.push('Only Numbers are allowed')
        //         return errors
        // },
        searchedItems () {
            return this.searchEntries;
        },

        searchedItemsPin () {
            return this.searchEntriesPin;
        },

        searchedItemsDist () {
            return this.searchEntriesDist;
        },
    
    },


    watch: {

      
    
        searchCity(val) {
    
            if(val == null) return

            let validis = null
            this.onlychandspace(val, res=>{
                validis = res
            })

            if(validis){
                this.showToast('Only Alphabets are allowed',this.TOST().WARNING); 
                return
            }
           
            if(val.length<3){
                return this.cityTown=''
            }

            let provider = [];
            let self=this      
                axios({
                    method: "get",
                     url:self.API_Service_MASTER()+"city/get-city-detail?city="+val,
                     
                    headers: {
                        "Content-Type": "application/json",
                        "x-access-token":self.token,
                    },
                    
                })
                .then(function (response) {
                    let searchDataArray = response.data.data

                    console.log("API RESPONSE for City:::::",searchDataArray);
                    const providers = []
                    for(let provider of response.data.data){
                            providers.push(provider.area)           
                    }
                    self.searchEntries = providers
                  
                    console.log("City api responce---->>>>>>", self.searchEntries);

                    // self.showToast(response.data.msg,self.TOST().SUCCESS)
                    // console.log("state api success msg--->>>>>>", response.data.msg);
                                       
                })
                .catch(function (error) {
                    console.log("City list Error>>>>>>", error);
                    self.showToast(error.response.data.msg,self.TOST().ERROR);
                });

            this.isLoading = false

        },

        searchPincode(val){
            // if (this.searchedItemsPin.length > 0) return
            // // Items have already been requested
            // if (this.isLoadingPin) return
            // this.isLoadingPin = true
            if(val == null) return
        //      let regxr = /^\d+\.\d{0,1}$/
            
        //     let valstring = val.toString()
        //    console.log('test for decimal', regxr.test(valstring))


        //      if(regxr.test(valstring)){
        //          this.showToast('Decimal is not allowed, Only numbers are allowed',this.TOST().WARNING);
        //          return
        //      }
        // console.log(this.$v.pincode.numeric, " $v ", this.$v);
        //     if(!this.$v.pincode.numeric){
        //         this.showToast('Decimal is not allowed, Only numbers are allowed',this.TOST().WARNING);
        //         this.pincode = this.pincode.substring(0, this.pincode.length - 1);
        //         return
        //     }
           let regex = /^[0-9]*(?:\\d{1})?$/
  
            /* /^[0-9]*(?:\.\d{1})?$/ */ 
            // allow only numbers [0-9]
          
            if(!regex.test(val)){
                console.log('invalid value', this.pincode);
                  this.showToast('Only numbers are allowed',this.TOST().WARNING);
                return
                //  this.pincode = this.pincode.substring(0, this.pincode.length - 1);
            }

           

            if(val.length<3){
                console.log("length still less then 3")
                return 
            }

          

            let listPincode = [];

            let self=this      
                axios({
                    method: "get",
                     url:self.API_Service_MASTER()+"city/get-pincode-detail/"+val,
                     
                    
                    headers: {
                        "Content-Type": "application/json",
                        "x-access-token":self.token,
                    },
                    
                })
                .then(function (response) {
                    let searchDataArrayPin = response.data.data

                    console.log("API RESPONSE for Pincode:::::",searchDataArrayPin);

                    const pincodelist = []
                    for(let listPincode of response.data.data){
                        
                            pincodelist.push(listPincode.pincode)           
                    }
                    self.searchEntriesPin = pincodelist
                  
                    console.log(" Pin code api responce---->>>>>>", self.searchEntriesPin);

                    // self.showToast(response.data.msg,self.TOST().SUCCESS)
                    // console.log("state api success msg--->>>>>>", response.data.msg);
                                       
                })
                .catch(function (error) {
                    console.log("Pincode list Error>>>>>>", error);
                    self.showToast(error.response.data.msg,self.TOST().ERROR);
                });
            
            this.isLoadingPin = false

        },

        searchDistrict(val) {
           
             if(val == null) return
          
            // let regex = /^[a-zA-Z ]*(?:\\d)?$/
        
            let validis = null
            this.onlychandspace(val, res=>{
                validis = res
            })

            if(validis){
                this.showToast('Only Alphabets are allowed',this.TOST().WARNING); 
                return
            }
            // if(this.spclCharNot(val) != 'valid'){
            //       this.showToast('Special Characters are not allowed',this.TOST().WARNING);
            //         console.log("specual chaeacters found", val)
            //     return
            // }

            if(val.length<3){
                return this.district=''
            }

            // if(this.spclCharNot(val) != 'valid'){
                
            //         console.log("specual chaeacters found", val)
            //     return
            // }


            let listDistName = [];

            let self=this      
                axios({
                    method: "get",
                     url:self.API_Service_MASTER()+"district/get-district-detail?district="+val,
                     
                    
                    headers: {
                        "Content-Type": "application/json",
                        "x-access-token":self.token,
                    },
                    
                })
                .then(function (response) {
                    let searchDataArrayDist = response.data.data

                    console.log("API RESPONSE for Distrtict:::::",searchDataArrayDist);

                    const districtList = []
                    for(let listDistName of response.data.data){
                        
                            districtList.push(listDistName.district)           
                    }
                    self.searchEntriesDist = districtList
                  
                    console.log(" Distrtict api responce---->>>>>>", self.searchEntriesDist);

                    // self.showToast(response.data.msg,self.TOST().SUCCESS)
                    // console.log("state api success msg--->>>>>>", response.data.msg);
                                       
                })
                .catch(function (error) {
                    console.log("Distrtict list Error>>>>>>", error);
                    self.showToast(error.response.data.msg,self.TOST().ERROR);
                });
            
            this.isLoadingDist = false

        },


    },


    methods: {
        claimClick(item){
            console.log("item is",item);
            // this.$router.push(
            //                     {
            //                         name: 'NewClaimIntimation', 
            //                         params: {
            //                                     allData:item,
            //                                     hospitalDta: true,
            //                                 }
            //                     })     
            if(this.$route.params.fromEdit == true){
                console.log("toEdit true");
                this.$router.push({name: 'NewClaimIntimation', params: {allData:item,hospitalDta: true,toEdit: true}});
            }else{
                console.log("toEdit false");
                this.$router.push({name: 'NewClaimIntimation', params: {allData:item,hospitalDta: true,toEdit: false}});
            }    
        },

       onlychandspace(val, cb){

            if(!d_regex.test(val)){
                // console.log('invalid value', this.pincode);
               
                return cb(true)
                //  this.pincode = this.pincode.substring(0, this.pincode.length - 1);
            }else{
                return cb(false)
            }

       },

       spclCharNot(v){
            if(v.length !== 0) {
                console.log("testing",/^[0-9 ]+$/.test(v))
                if(!/^[A-Za-z0-9 ]+$/.test(v)){
                    return "Special Characters Not Allowed"
                }else{
                   return ''
                }
            }
        },
            returnbtn(){
                this.$router.push('/HomePage')
            },

            RoleClick(item){
                console.log(item,"search result item ")
                this.$router.push({name: 'RoleCreate', params:{item}})
                // this.$router.push('/RoleCreate')
            },

        GetStatelistData(){
                
                let self=this      
                axios({
                    method: "get",
                     url:self.API_Service_MASTER()+"state/get-state-all",
                    
                    headers: {
                        "Content-Type": "application/json",
                        "x-access-token":self.token,
                    },
                    
                })
                .then(function (response) {
                    let apiData = response.data.data.data

                    console.log("API RESPONSE for ALL Network:::::",apiData);
                  
                    self.GetStatelist = []
                    self.GetStatelist= apiData
                    console.log(" state api responce---->>>>>>", self.GetStatelist);

                    // self.showToast(response.data.msg,self.TOST().SUCCESS)
                    console.log("state api success msg--->>>>>>", response.data.msg);
                                       
                })
                .catch(function (error) {
                    console.log("state list Error>>>>>>", error);
                    self.showToast(error.response.data.msg,self.TOST().ERROR);
                });
            
        },

        
        seekSearchData(indata){
            this.page=indata
            this.SearchHospital(this.page);
        },
       
        nextPageData(indata){

            console.log("isSearchIndiv => ", this.typesearch);

            if(this.pagelength == indata){
                this.swap_final_count = true
            }else{
                this.swap_final_count = false
            }

            console.log('indiv sort value',this.indiviSortBy)
            console.log('indiv page no value',this.page)
            
            if(this.typesearch==false){
                this.getAllHospital(null,this.page)
                  console.log("from if ",this.typesearch)
            }else{
                console.log("from else ",this.typesearch)
                this.seekSearchData(indata)
        
            }

        },

        clearbtn(){

            if((this.cityTown===''||this.cityTown===undefined||this.cityTown===null) 
            && (this.state===''||this.state===undefined||this.state===null) 
            && (this.pincode===''||this.pincode===undefined||this.pincode===null)
            && (this.district===''||this.district===undefined||this.district===null) 
            
            ){
                
                return
            }else{
                
                this.cityTown=''
                this.state=''
                this.pincode=''
                this.district=''
                
                this.getAllHospital(null,1);
                this.typesearch = false
                this.page=1
                console.log('cancel search data',)
                this.$refs.form.resetValidation()
                 this.showSearchdata=true,
                     this.showrsltnotfound=false
                    
            }
        },

        getAllHospital(sortBy1,pageNum) {
        console.log("HOSPITAL sort by-------",sortBy1)
                console.log("HOSPITAL page no ---->",pageNum)
                console.log("token for search ---->",this.token)

                
                    let self = this;   
                    console.log('next btn',self.page)
                    self.showSearchdata =true
                    self.showrsltnotfound=false
                    
                    axios({
                    method: "get",

                    url:self.API_Service_provider()+"provider/search-provider-institution?&providerType=Hospital"+"&perPage=20&pageNo="+pageNum,                            
    
                    headers: {
                                "Content-Type": "application/json", 
                                "x-access-token": self.token,                                
                            },
                        })
                        .then(function(response) {
                                       
                    let apiData = response.data.data.data

                    console.log("API RESPONSE for ALL Network:::::",apiData);
                  
                    self.GetAllData = []
                    self.GetAllData= apiData.map((item,index) => {
                                                                return{
                                                                         SerialNumber:20*(pageNum)-19+index,
                                                                        entityName:item.entityName,
                                                                        providerType:item.providerType,
                                                                        aliasName:item.aliasName,
                                                                        addressLine1:item.addressLine1,
                                                                        cityTownVillage:item.cityTownVillage,
                                                                        state:item.state,
                                                                        pinPostalCode:item.pinPostalCode,
                                                                        district:item.district,
                                                                        allData:item

                                          
                                                                    }
                                                                });
                    
                     console.log("GetAllData RESPONSE ------->>>>>>>:::::",self.GetAllData);

            //pagination 
                    self.pagelength=response.data.data.total_pages
                    console.log(self.page,' <-- pageindu | next btn, pg length is ----->',self.pagelength)

                     self.totalLen=response.data.data.total
                      let l = self.GetAllData.length
                
                
                    console.log( 20*self.page,'skip simulation ');

                      var less_enough = self.totalLen
                        var checkless_init = false
                        if(less_enough < 20){
                            checkless_init = false
                        }else{
                            checkless_init = true
                        }

                        if(checkless_init){
                            
                                var traverse =  20*self.page
                                self.fval = traverse-19
                                if(self.swap_final_count){
                                    self.sval =  self.totalLen
                                }else{
                                    self.sval = traverse
                                }

                        }else if(self.totalLen == 0){
                            
                          self.sval = self.fval = 0
                        
                       }else{
                             self.fval = 1
                             self.sval = self.totalLen
                        }

                    console.log('total ----->',self.totalLen)
                    console.log(self.sval,'<------sval<<   >>fval ----->',self.fval)

						console.log("table DATA is---->>::",self.GetAllData);
			
                        })
                        .catch(function(error) {
                            console.log(error);
                            self.showToast(error.response.data.msg,self.TOST().ERROR);
                        });
             
            },

         SearchHospital(pageNum) {

                 console.log("incoming search result page no ---->",pageNum)

                   

                    var self = this; 
                    if(self.cityTown=="" && self.state=="" && self.pincode=="" && self.district==""){
                        self.showToast("Please enter search criteria.",self.TOST().WARNING);
                    return
                    }
                    
                    let demodata={
                             cityTownVillage:self.cityTown,
                            state:self.state,
                            pinPostalCode :self.pincode,
                            district:self.district,                            
                    }
                
                if (this.$refs.roleform.validate()){
                    axios({
                    method: "get",
                    url:self.API_Service_provider()+"provider/search-provider-institution?&providerType=Hospital"+"&cityTownVillage="+self.cityTown+"&district="+self.district+"&state="+self.state
                              +"&pinPostalCode="+self.pincode+"&perPage=20&pageNo="+pageNum,
     
                            headers: {
                                "Content-Type": "application/json",
                                "x-access-token": self.token,    
                            },
                            data:demodata,

                        })
                        .then(function(response) {
                                console.log("Search reporting>>>>>>", response);

                    if(!response.data.data || response.data.data.length == 0){
                        console.log("search hospital Array is either empty or does not exist")
                        self.showSearchdata =false,
                        self.showrsltnotfound=true
                        
                    }else{
                        self.rsltData=response.data.data.data
                        console.log("self.rsltData",self.rsltData)
                        self.showSearchdata =true,
                        self.showrsltnotfound=false
                        
                    }
                                
                        let apiData=response.data.data.data
                        let status=response.status
                        
                        console.log("API hospital search RESPONSE -->:::::",apiData);
                        console.log("API status RESPONSE -->:::::",status);
                        
                        self.GetAllData = []
                        
                        // self.GetAllData= apiData
                        self.GetAllData= apiData.map((item,index) => {
                                                                return{
                                                                        SerialNumber:20*(pageNum)-19+index,
                                                                        entityName:item.entityName,
                                                                        providerType:item.providerType,
                                                                        aliasName:item.aliasName,
                                                                        addressLine1:item.addressLine1,
                                                                        cityTownVillage:item.cityTownVillage,
                                                                        state:item.state,
                                                                        pinPostalCode:item.pinPostalCode,
                                                                        district:item.district,
                                                                        allData:item                                          
                                                                    }
                            });
                           
                        self.typesearch = true
                        self.isSearchindiv = true
                        console.log('isSearchindiv in Search function ', self.isSearchindiv)
                                  

                // for role search pagination
                    
                    self.pagelength=response.data.data.total_pages
                    console.log(self.page,' <-- page search | search next btn, pg length is ----->',self.pagelength)

                     self.totalLen=response.data.data.total

                      let l = self.GetAllData.length
                
                    console.log( 20*self.page,'search skip simulation ');

                      var less_enough = self.totalLen
                        var checkless_init = false
                        if(less_enough < 20){
                            checkless_init = false
                        }else{
                            checkless_init = true
                        }

                        // if less than 15 we have second value same as total value as no pagination will occur
                        if(checkless_init){
                            
                                // checkinit is true means the final count is more than 15 (- 1)
                                var traverse =  20*self.page
                                self.fval = traverse-19
                                if(self.swap_final_count){
                                    self.sval =  self.totalLen
                                }else{
                                    self.sval = traverse
                                }

                        }else if(self.totalLen == 0){
                            
                          self.sval = self.fval = 0
                        
                       }else{
                             self.fval = 1
                             self.sval = self.totalLen
                        }

                    console.log('total search----->',self.totalLen)
                                    // self.prnNumber=""
                                    // console.log("prn clear status RESPONSE -->",self.prnNumber);

                        })
                        .catch(function (error) {
                    console.log("Search  Error>>>>>>", error.response);
                    
                        // self.showToast(error.response.data.msg,self.TOST().ERROR);
                        self.showToast('No result available for entered search criteria',self.TOST().ERROR);
                        self.showSearchdata =false
                        self.showrsltnotfound=true
        
                });
                }else{
                this.$refs.roleform.validate()
                this.showToast("Enter valid values in all the required fields.",this.TOST().WARNING);
            }
          },
                    
         

    },


}
</script>

<style>

.cust-rmvpd-psins3 .v-text-field.v-text-field--enclosed>.v-input__control>.v-input__slot {
    padding-right: 0px !important;
}

.cust_pr2 .v-text-field .v-input__slot,
.v-text-field--outline .v-input__slot {
    min-height: 42px !important;
    display: flex !important;
    align-items: center !important;
    /* border:1px red solid; */
}

.cstm-clr-pr3{
    color:#646666 !important;
    margin-bottom: 5px !important;
    font-size: 13px !important;
}

.cust_pd1 .v-text-field .v-input__slot,
.v-text-field--outline .v-input__slot {
    min-height: 42px !important;
    display: flex !important;
    align-items: center !important;
    /* border:1px red solid; */
}

.cust-rmvpd-pr1 .v-text-field.v-text-field--enclosed>.v-input__control>.v-input__slot {
    padding: 0px !important;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
    font-size: 0.84rem !important;
}

.hdr-txt-clr-clr {
        color: white !important;
    font-weight: bold;

}

.theme--light.v-data-table .v-data-table-header th.sortable .v-data-table-header__icon {
    color: white !important;
    margin-left:10px !important;
}

.theme--light.v-data-table .v-data-table-header th.sortable.active .v-data-table-header__icon{
    color: white !important;
    margin-left:10px !important;
}

.hdr-bg-clr{
  background-color: #23B1A9 !important;
  color: #ffffff !important;
  
}
.headercolor{
    background-color: #eee;
}
.cardfont{
    font-size: 0.84rem !important;
    padding-right: 2px;
}
.srNoWidth1{
    width: 110px;
    
}
.delpad .v-input__slot{
    padding-right: 0px !important;
}


</style>