<template>
    <v-app >
        <v-form @submit.prevent="submitDetails">
        <div style="background-color: #FFFFFF;" column class="py-3">
            <v-layout class="mx-2 mt-2" >
                 <v-flex xs3>
                    <v-layout column class="mx-2 my-2">
                        <v-text class="text-uppercase font-weight-bold title-clr-pr">
                            Ownership Details
                        </v-text>
                        <v-divider></v-divider>
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class="cust_pd mx-2 my-2 ">
                        <v-text class="font-weight-bold cstm-clr-pd">
                            Ownership Type *
                        </v-text>
                        <v-select
                            :items="ownerItems"
                            label="Select"
                            v-model="ownership"
                            solo
                            dense
                            :error-messages="ownershipErrors"
                            @input="$v.ownership.$touch()"
                            @blur="$v.ownership.$touch()"
                            class="rounded-0"
                            maxlength=10
                            >
                        </v-select>
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class="cust_pd mx-2 my-2 ">
                        <v-text class="font-weight-bold cstm-clr-pd">
                           Any other (please specify)
                        </v-text>
                        <v-text-field
                            label="Select"
                            v-model="otherOwner"
                            solo
                            dense
                            :disabled="ownershipAnyOther"
                            :error-messages="otherOwnerErrors"
                            @input="$v.otherOwner.$touch()"
                            @blur="$v.otherOwner.$touch()"
                            class="rounded-0"
                            maxlength=200
                            >
                        </v-text-field>
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class=" mx-2 my-2 cust_pd">
                        <v-text class="font-weight-bold cstm-clr-pd">
                            Name of Owner
                        </v-text>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="ownerName"
                            class="rounded-0"
                            :error-messages="ownerNameErrors"
                            @input="$v.ownerName.$touch()"
                            @blur="$v.ownerName.$touch()"
                            maxlength=30>
                        </v-text-field>
                    </v-layout>
                </v-flex>

                <v-flex xs3></v-flex>
            </v-layout >

            <v-layout class="mx-2 mb-2">
                
                <v-flex xs3>
                    <v-layout column class="mx-2 my-2">
                        <v-text class="text-uppercase font-weight-bold title-clr-pr">
                        Contact Details
                        </v-text>
                        <v-divider></v-divider>
                    </v-layout>
                </v-flex>
                

                <v-flex xs3>
                    <v-layout column class="cust_pd mx-2 my-2 ">
                        <v-text class="font-weight-bold cstm-clr-pd" id="std">
                            STD Code *
                        </v-text>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="stdCode"
                            :error-messages="stdCodeErrors"
                            @input="$v.stdCode.$touch()"
                            @blur="$v.stdCode.$touch()"
                            class="rounded-0"
                            maxlength=10
                            >
                        </v-text-field>
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class=" mx-2 my-2 cust_pd cust-rmvpd-pr">
                        <v-text class="font-weight-bold cstm-clr-pr">
                            Telephone Number 1 *
                        </v-text>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="teleFirstContact"
                            :error-messages="teleFirstContactCodeErrors"
                            @input="$v.teleFirstContact.$touch()"
                            @blur="$v.teleFirstContact.$touch()"
                            class="rounded-0 telefirstTest"
                            maxlength=10
                        >
                            <div slot="prepend-inner" style="background-color:#F7F7F7;padding: 9px !important"><v-icon>smartphone</v-icon></div>
                        </v-text-field>
                        
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class=" mx-2 my-2 cust_pr cust-rmvpd-pr">
                        <v-text class="font-weight-bold cstm-clr-pr">
                            Telephone Number 2
                        </v-text>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="teleSecondContact"
                            :error-messages="teleSecondContactErrors"
                            @input="$v.teleSecondContact.$touch()"
                            @blur="$v.teleSecondContact.$touch()"
                            class="rounded-0"
                            maxlength=10
                            >
                            <div slot="prepend-inner" style="background-color:#F7F7F7;padding: 9px !important"><v-icon>smartphone</v-icon></div>
                        </v-text-field>
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class="cust_pr cust-rmvpd-pr mx-2 my-2 ">
                        <v-text class="font-weight-bold cstm-clr-pr">
                            E-mail
                        </v-text>
                        <v-text-field
                            solo
                            label="Enter..."
                            prepend-inner-icon="mail"
                            v-model="emailContact"
                           :error-messages="emailContactErrors"
                            @input="$v.emailContact.$touch()"
                            @blur="$v.emailContact.$touch()"
                            maxlength=50
                            class="pa-0 rounded-0">
                             <div slot="prepend-inner" style="background-color:#F7F7F7;padding: 9px !important"><v-icon>mail</v-icon></div>
                        </v-text-field>
                    </v-layout>
                </v-flex>
            </v-layout>
            <v-divider></v-divider>
            <v-layout class="mx-2 mt-2">
                <v-flex xs3>
                    <v-layout column class="cust_pd mx-2 my-2 ">
                        <v-text class="font-weight-bold cstm-clr-pd">
                            Pre Auth FAX Number
                        </v-text>
                        <v-text-field
                            
                            label="Other"
                            v-model="preauthFax"
                            solo
                            dense
                            :error-messages="preauthFaxErrors"
                            @input="$v.preauthFax.$touch()"
                            @blur="$v.preauthFax.$touch()"
                            maxlength=20
                            class="rounded-0"
                            >
                        </v-text-field>
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class=" mx-2 my-2 cust_pd">
                        <v-text class="font-weight-bold cstm-clr-pd">
                            FAX Number
                        </v-text>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="faxNumber"
                            :error-messages="faxNumberErrors"
                            @input="$v.faxNumber.$touch()"
                            @blur="$v.faxNumber.$touch()"
                            maxlength=20
                            class="rounded-0">
                        </v-text-field>
                    </v-layout>
                </v-flex>

                <v-flex xs3></v-flex>
                <v-flex xs3></v-flex>
                <v-flex xs3></v-flex>
            </v-layout>

            <v-layout class="mx-2 mb-2">

                <v-flex xs3>
                    <v-layout column class="mx-2 my-2">
                        <v-text class="text-uppercase font-weight-bold title-clr-pr">
                        Personal Details
                        </v-text>
                        <v-divider></v-divider>
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class=" mx-2 my-2 cust_pd">
                        <v-text class="font-weight-bold cstm-clr-pd">
                            Insurance/TPA Coordinator *
                        </v-text>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="insurance"
                            :error-messages="insuranceErrors"
                            @input="$v.insurance.$touch()"
                            @blur="$v.insurance.$touch()"
                            maxlength=30
                            class="rounded-0">
                        </v-text-field>
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class=" mx-2 my-2 cust_pd">
                        <v-text class="font-weight-bold cstm-clr-pd">
                            Designation *
                        </v-text>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="designationPersonal"
                            :error-messages="designationPersonalErrors"
                            @input="$v.designationPersonal.$touch()"
                            @blur="$v.designationPersonal.$touch()"
                            maxlength=30
                            class="rounded-0">
                        </v-text-field>
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class=" mx-2 my-2 cust_pd">
                        <v-text class="font-weight-bold cstm-clr-pd">
                            STD Code *
                        </v-text>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="stdPersonal"
                            :error-messages="stdPersonalErrors"
                            @input="$v.stdPersonal.$touch()"
                            @blur="$v.stdPersonal.$touch()"
                            maxlength=10
                            class="rounded-0">
                        </v-text-field>
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class=" mx-2 my-2 cust_pr cust-rmvpd-pr">
                        <v-text class="font-weight-bold cstm-clr-pr">
                            Telephone Number 1 *
                        </v-text>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="teleFirstPersonal"
                            :error-messages="teleFirstPersonalErrors"
                            @input="$v.teleFirstPersonal.$touch()"
                            @blur="$v.teleFirstPersonal.$touch()"
                            maxlength=10
                            class="rounded-0"
                            >
                            <div slot="prepend-inner" style="background-color:#F7F7F7;padding: 9px !important"><v-icon>smartphone</v-icon></div>
                        </v-text-field>
                    </v-layout>
                </v-flex>
            </v-layout>
            <v-divider></v-divider>

            <v-layout class="mx-2 mt-2">

                <v-flex xs3>
                    <v-layout column class=" mx-2 my-2 cust_pr cust-rmvpd-pr">
                        <v-text class="font-weight-bold cstm-clr-pr">
                            Telephone Number 2
                        </v-text>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="teleSecondPersonal"
                            :error-messages="teleSecondPersonalErrors"
                            @input="$v.teleSecondPersonal.$touch()"
                            @blur="$v.teleSecondPersonal.$touch()"
                            maxlength=10
                            class="rounded-0"
                            >
                            <div slot="prepend-inner" style="background-color:#F7F7F7;padding: 9px !important"><v-icon>smartphone</v-icon></div>
                        </v-text-field>
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class=" mx-2 my-2 cust_pr cust-rmvpd-pr">
                        <v-text class="font-weight-bold cstm-clr-pr">
                            Telephone Number 3
                        </v-text>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="teleThirdPersonal"
                            :error-messages="teleThirdPersonalErrors"
                            @input="$v.teleThirdPersonal.$touch()"
                            @blur="$v.teleThirdPersonal.$touch()"
                            maxlength=10
                            class="rounded-0"
                            >
                            <div slot="prepend-inner" style="background-color:#F7F7F7;padding: 9px !important"><v-icon>smartphone</v-icon></div>
                        </v-text-field>
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class="cust_pr cust-rmvpd-pr mx-2 my-2 ">
                        <v-text class="font-weight-bold cstm-clr-pr">
                            E-mail
                        </v-text>
                        <v-text-field
                            solo
                            label="Enter..."
                            prepend-inner-icon="mail"
                            v-model="emailPersonal"
                            :error-messages="emailPersonalErrors"
                            @input="$v.emailPersonal.$touch()"
                            @blur="$v.emailPersonal.$touch()"
                            maxlength=50
                            class="pa-0 rounded-0"
                            >
                             <div slot="prepend-inner" style="background-color:#F7F7F7;padding: 9px !important"><v-icon>mail</v-icon></div>
                        </v-text-field>
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class=" mx-2 my-2 cust_pd">
                        <v-text class="font-weight-bold cstm-clr-pd">
                            Escalation Name 1 *
                        </v-text>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="escalationFirstPersonal"
                            :error-messages="escalationFirstPersonalErrors"
                            @input="$v.escalationFirstPersonal.$touch()"
                            @blur="$v.escalationFirstPersonal.$touch()"
                            maxlength=50
                            class="rounded-0">
                        </v-text-field>
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class=" mx-2 my-2 cust_pd">
                        <v-text class="font-weight-bold cstm-clr-pd">
                            Escalation Name 2
                        </v-text>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="escalationSecondPersonal"
                            :error-messages="escalationSecPersonalErrors"
                            @input="$v.escalationSecondPersonal.$touch()"
                            @blur="$v.escalationSecondPersonal.$touch()"
                            
                            class="rounded-0"
                            maxlength=50
                            >
                        </v-text-field>
                    </v-layout>
                </v-flex>

            </v-layout>

            <v-layout class="mx-2 mt-1 mb-2">
                
                <v-flex xs3>
                    <v-layout column class="cust_pd mx-2 my-2 mb-4">
                        <v-text class="font-weight-bold cstm-clr-pd">
                            Escalation Name 3
                        </v-text>
                        <v-text-field
                            label="Enter..."
                            v-model="escalationThirdPersonal"
                            solo
                            dense
                            
                             :error-messages="escalationThirdPersonalErrors"
                            @input="$v.escalationThirdPersonal.$touch()"
                            @blur="$v.escalationThirdPersonal.$touch()"
                            class="rounded-0"
                            maxlength=50
                            >
                        </v-text-field>
                    </v-layout>
                </v-flex>

                <v-flex xs3></v-flex>
                <v-flex xs3></v-flex>
                <v-flex xs3></v-flex>
                <v-flex xs3></v-flex>

            </v-layout>
            <v-divider></v-divider>

            <v-layout class="mx-2 my-2">
                
                <v-flex xs3>
                    <v-layout column class=" mx-2 my-2 cust_pd">
                        <v-text class="font-weight-bold cstm-clr-pd">
                            Relationship Manager *
                        </v-text>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="relManager"
                            :error-messages="relManagerErrors"
                            @input="$v.relManager.$touch()"
                            @blur="$v.relManager.$touch()"
                            class="rounded-0"
                            maxlength=30
                            >
                        </v-text-field>
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class=" mx-2 my-2 cust_pd">
                        <v-text class="font-weight-bold cstm-clr-pd">
                            Designation *
                        </v-text>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="designationRelation"
                            :error-messages="designationRelationErrors"
                            @input="$v.designationRelation.$touch()"
                            @blur="$v.designationRelation.$touch()"
                            maxlength=30
                            class="rounded-0">
                        </v-text-field>
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class=" mx-2 my-2 cust_pd">
                        <v-text class="font-weight-bold cstm-clr-pd">
                            STD Code *
                        </v-text>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="stdRelation"
                            :error-messages="stdRelationErrors"
                            @input="$v.stdRelation.$touch()"
                            @blur="$v.stdRelation.$touch()"
                            maxlength=10
                            class="rounded-0">
                        </v-text-field>
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class=" mx-2 my-2 cust_pr cust-rmvpd-pr">
                        <v-text class="font-weight-bold cstm-clr-pr">
                            Telephone Number 1 *
                        </v-text>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="teleFirstRelation"
                            :error-messages="teleFirstRelationErrors"
                            @input="$v.teleFirstRelation.$touch()"
                            @blur="$v.teleFirstRelation.$touch()"
                            maxlength=10
                            class="rounded-0"
                            >
                            <div slot="prepend-inner" style="background-color:#F7F7F7;padding: 9px !important"><v-icon>smartphone</v-icon></div>
                        </v-text-field>
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class=" mx-2 my-2 cust_pr cust-rmvpd-pr">
                        <v-text class="font-weight-bold cstm-clr-pr">
                            Telephone Number 2
                        </v-text>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="teleSecondRelation"
                            :error-messages="teleSecondRelationErrors"
                            @input="$v.teleSecondRelation.$touch()"
                            @blur="$v.teleSecondRelation.$touch()"
                            maxlength=10
                            class="rounded-0"
                            >
                            <div slot="prepend-inner" style="background-color:#F7F7F7;padding: 9px !important"><v-icon>smartphone</v-icon></div>
                        </v-text-field>
                    </v-layout>
                </v-flex>
            </v-layout>
            
            <v-layout mx-2 mt-n2 mb-2>

                <v-flex xs3>
                    <v-layout column class=" mx-2 my-2 cust_pr cust-rmvpd-pr">
                        <v-text class="font-weight-bold cstm-clr-pr">
                            Telephone Number 3
                        </v-text>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="teleThirdRelation"
                            :error-messages="teleThirdRelationErrors"
                            @input="$v.teleThirdRelation.$touch()"
                            @blur="$v.teleThirdRelation.$touch()"
                            maxlength=10
                            class="rounded-0"
                            >
                            <div slot="prepend-inner" style="background-color:#F7F7F7;padding: 9px !important"><v-icon>smartphone</v-icon></div>
                        </v-text-field>
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class="cust_pr cust-rmvpd-pr mx-2 my-2 ">
                        <v-text class="font-weight-bold cstm-clr-pr">
                            E-mail
                        </v-text>
                        <v-text-field
                            solo
                            label="Enter..."
                            prepend-inner-icon="mail"
                            v-model="emailRelation"
                            :error-messages="emailRelationErrors"
                            @input="$v.emailRelation.$touch()"
                            @blur="$v.emailRelation.$touch()"
                            maxlength=50
                            class="pa-0 rounded-0">
                             <div slot="prepend-inner" style="background-color:#F7F7F7;padding: 9px !important"><v-icon>mail</v-icon></div>
                        </v-text-field>
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class=" mx-2 my-2 cust_pd">
                        <v-text class="font-weight-bold cstm-clr-pd">
                            Escalation Name 1 *
                        </v-text>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="escalationFirstRelation"
                            :error-messages="escalationFirstRelationErrors"
                            @input="$v.escalationFirstRelation.$touch()"
                            @blur="$v.escalationFirstRelation.$touch()"
                            maxlength=50
                            class="rounded-0">
                        </v-text-field>
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class=" mx-2 my-2 cust_pd">
                        <v-text class="font-weight-bold cstm-clr-pd">
                            Escalation Name 2
                        </v-text>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="escalationSecondRelation"
                            :error-messages="escalationSecondRelationErrors"
                            @input="$v.escalationSecondRelation.$touch()"
                            @blur="$v.escalationSecondRelation.$touch()"
                            maxlength=50
                            class="rounded-0">
                        </v-text-field>
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class="cust_pd mx-2 my-2 mb-4">
                        <v-text class="font-weight-bold cstm-clr-pd">
                            Escalation Name 3
                        </v-text>
                        <v-text-field
                            label="Enter..."
                            v-model="escalationThirdRelation"
                            :error-messages="escalationThirdRelationErrors"
                            @input="$v.escalationThirdRelation.$touch()"
                            @blur="$v.escalationThirdRelation.$touch()"
                            solo
                            dense
                            
                            class="rounded-0"
                            maxlength=50
                            >
                        </v-text-field>
                    </v-layout>
                </v-flex>
            </v-layout>
            <v-divider></v-divider>

            <v-layout class="mx-2 my-2">
                
                <v-flex xs3>
                    <v-layout column class=" mx-2 my-2 cust_pd">
                        <v-text class="font-weight-bold cstm-clr-pd">
                            Finance Manager *
                        </v-text>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="finManager"
                            :error-messages="finManagerErrors"
                            @input="$v.finManager.$touch()"
                            @blur="$v.finManager.$touch()"
                            maxlength=30
                            class="rounded-0">
                        </v-text-field>
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class=" mx-2 my-2 cust_pd">
                        <v-text class="font-weight-bold cstm-clr-pd">
                            Designation *
                        </v-text>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="designationFinance"
                            :error-messages="designationFinanceErrors"
                            @input="$v.designationFinance.$touch()"
                            @blur="$v.designationFinance.$touch()"
                            maxlength=50
                            class="rounded-0">
                        </v-text-field>
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class=" mx-2 my-2 cust_pd">
                        <v-text class="font-weight-bold cstm-clr-pd">
                            STD Code *
                        </v-text>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="stdFinance"
                            :error-messages="stdFinanceErrors"
                            @input="$v.stdFinance.$touch()"
                            @blur="$v.stdFinance.$touch()"
                            maxlength=10
                            class="rounded-0">
                        </v-text-field>
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class=" mx-2 my-2 cust_pr cust-rmvpd-pr">
                        <v-text class="font-weight-bold cstm-clr-pr">
                            Telephone Number 1 *
                        </v-text>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="teleFirstFinance"
                            :error-messages="teleFirstFinanceErrors"
                            @input="$v.teleFirstFinance.$touch()"
                            @blur="$v.teleFirstFinance.$touch()"
                            maxlength=10
                            class="rounded-0"
                            >
                            <div slot="prepend-inner" style="background-color:#F7F7F7;padding: 9px !important"><v-icon>smartphone</v-icon></div>
                        </v-text-field>
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class=" mx-2 my-2 cust_pr cust-rmvpd-pr">
                        <v-text class="font-weight-bold cstm-clr-pr">
                            Telephone Number 2
                        </v-text>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="teleSecondFinance"
                            :error-messages="teleSecondFinanceErrors"
                            @input="$v.teleSecondFinance.$touch()"
                            @blur="$v.teleSecondFinance.$touch()"
                            maxlength=10
                            class="rounded-0"
                            >
                            <div slot="prepend-inner" style="background-color:#F7F7F7;padding: 9px !important"><v-icon>smartphone</v-icon></div>
                        </v-text-field>
                    </v-layout>
                </v-flex>
            </v-layout>
            
            <v-layout mx-2 my-2 mt-n2>
                
                <v-flex xs3>
                    <v-layout column class=" mx-2 my-2 cust_pr cust-rmvpd-pr">
                        <v-text class="font-weight-bold cstm-clr-pr">
                            Telephone Number 3
                        </v-text>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="teleThirdFinance"
                            :error-messages="teleThirdFinanceErrors"
                            @input="$v.teleThirdFinance.$touch()"
                            @blur="$v.teleThirdFinance.$touch()"
                            maxlength=10
                            class="rounded-0"
                            >
                            <div slot="prepend-inner" style="background-color:#F7F7F7;padding: 9px !important"><v-icon>smartphone</v-icon></div>
                        </v-text-field>
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class="cust_pr cust-rmvpd-pr mx-2 my-2 ">
                        <v-text class="font-weight-bold cstm-clr-pr">
                            E-mail
                        </v-text>
                        <v-text-field
                            solo
                            label="Enter..."
                            prepend-inner-icon="mail"
                            v-model="emailFinance"
                            :error-messages="emailFinanceErrors"
                            @input="$v.emailFinance.$touch()"
                            @blur="$v.emailFinance.$touch()"
                            maxlength=50
                            class="pa-0 rounded-0">
                             <div slot="prepend-inner" style="background-color:#F7F7F7;padding: 9px !important"><v-icon>mail</v-icon></div>
                        </v-text-field>
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class=" mx-2 my-2 cust_pd">
                        <v-text class="font-weight-bold cstm-clr-pd">
                            Escalation Name 1 *
                        </v-text>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="escalationFirstFinance"
                            :error-messages="escalationFirstFinanceErrors"
                            @input="$v.escalationFirstFinance.$touch()"
                            @blur="$v.escalationFirstFinance.$touch()"
                            maxlength=50
                            class="rounded-0">
                        </v-text-field>
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class=" mx-2 my-2 cust_pd">
                        <v-text class="font-weight-bold cstm-clr-pd">
                            Escalation Name 2
                        </v-text>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="escalationSecondFinance"
                            :error-messages="escalationSecondFinanceErrors"
                            @input="$v.escalationSecondFinance.$touch()"
                            @blur="$v.escalationSecondFinance.$touch()"
                            
                            class="rounded-0"
                            maxlength=50>
                        </v-text-field>
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class="cust_pd mx-2 my-2 mb-4">
                        <v-text class="font-weight-bold cstm-clr-pd">
                            Escalation Name 3
                        </v-text>
                        <v-text-field
                            label="Enter..."
                            v-model="escalationThirdFinance"
                            :error-messages="escalationThirdFinanceErrors"
                            @input="$v.escalationThirdFinance.$touch()"
                            @blur="$v.escalationThirdFinance.$touch()"
                            solo
                            dense
                            
                            class="rounded-0"
                            maxlength=50
                            >
                        </v-text-field>
                    </v-layout>
                </v-flex>
            </v-layout>
            <v-divider></v-divider>

            <v-layout class="mx-2 my-2 mb-4">

                <v-flex xs3>
                    <v-layout column class=" mx-2 my-2 cust_pd">
                        <v-text class="font-weight-bold cstm-clr-pd">
                            Head of Institute *
                        </v-text>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="hoi"
                            :error-messages="hoiErrors"
                            @input="$v.hoi.$touch()"
                            @blur="$v.hoi.$touch()"
                            maxlength=30
                            class="rounded-0">
                        </v-text-field>
                    </v-layout>
                </v-flex>
                
                <v-flex xs3>
                    <v-layout column class=" mx-2 my-2 cust_pd">
                        <v-text class="font-weight-bold cstm-clr-pd">
                            Designation *
                        </v-text>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="designationHoi"
                            :error-messages="designationHoiErrors"
                            @input="$v.designationHoi.$touch()"
                            @blur="$v.designationHoi.$touch()"
                            maxlength=50
                            class="rounded-0">
                        </v-text-field>
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class=" mx-2 my-2 cust_pd">
                        <v-text class="font-weight-bold cstm-clr-pd">
                            STD Code *
                        </v-text>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="stdHoi"
                            :error-messages="stdHoiErrors"
                            @input="$v.stdHoi.$touch()"
                            @blur="$v.stdHoi.$touch()"
                            maxlength=10
                            class="rounded-0">
                        </v-text-field>
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class=" mx-2 my-2 cust_pr cust-rmvpd-pr">
                        <v-text class="font-weight-bold cstm-clr-pr">
                            Telephone Number *
                        </v-text>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="teleHoi"
                            :error-messages="teleHoiErrors"
                            @input="$v.teleHoi.$touch()"
                            @blur="$v.teleHoi.$touch()"
                            maxlength=10
                            class="rounded-0"
                            >
                            <div slot="prepend-inner" style="background-color:#F7F7F7;padding: 9px !important"><v-icon>smartphone</v-icon></div>
                        </v-text-field>
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class="cust_pr cust-rmvpd-pr mx-2 my-2 ">
                        <v-text class="font-weight-bold cstm-clr-pr">
                            E-mail
                        </v-text>
                        <v-text-field
                            solo
                            label="Enter..."
                            prepend-inner-icon="mail"
                            v-model="emailHoi"
                            :error-messages="emailHoiErrors"
                            @input="$v.emailHoi.$touch()"
                            @blur="$v.emailHoi.$touch()"
                            maxlength=50
                            class="pa-0 rounded-0">
                             <div slot="prepend-inner" style="background-color:#F7F7F7;padding: 9px !important"><v-icon>mail</v-icon></div>
                        </v-text-field>
                    </v-layout>
                </v-flex>
            </v-layout>

        <v-dialog v-model="confirmDialogCancel" persistent max-width="450px">
                 
                         <v-card tile color="#F7F7F7" >
                            <v-card tile>
                                <v-layout justify-space-between  class="" style="flex:1;background-ox-shadow: 0px 3px 5px #00000029;">
                             
                                    <v-card-title class="pa-0 pl-3 text-capitalize font-weight-regular" style="color:black"> 
                                       CONFIRMATION
                                    </v-card-title>
                                    <div style="border-left:1px solid #C1C8CC;" @click="close_cancelDialog()">
                                        <v-icon style="cursor: pointer;" class="pa-2" >close</v-icon>
                                    </div>
                                </v-layout>
                            </v-card>
                        <v-flex >
                           
                       
                        </v-flex>
                        <v-card-text >
                                Details saved on screen will be lost
                        </v-card-text>
                        <v-card-actions justify-start>
                          
                           
                           
                            <v-btn dark  tile color="black" @click="confirmDialogCancel = false" class="text-capitalize px-3 mx-2" small><v-icon dark small>cancel</v-icon> <v-divider vertical class="mx-2"></v-divider>Cancel</v-btn>

                            <!-- <v-btn depressed small color="secondary" class="rounded-0"  >
                                <v-icon dark right>check_circle</v-icon>
                            </v-btn> -->
                            <v-btn @click="selectedCompFromPage(arrToNavCancel[1])" dark  tile color="#23B1A9" class="text-capitalize px-3 ml-2" small><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider> OK</v-btn>

                        </v-card-actions>
                    </v-card>
                </v-dialog>

            <v-dialog v-model="confirmDialogNext" persistent max-width="450px">
                 
                         <v-card tile color="#F7F7F7" >
                            <v-card tile>
                                <v-layout justify-space-between  class="" style="flex:1;background-ox-shadow: 0px 3px 5px #00000029;">
                             
                                    <v-card-title class="pa-0 pl-3 text-capitalize font-weight-regular" style="color:black"> 
                                       CONFIRMATION
                                    </v-card-title>
                                    <div style="border-left:1px solid #C1C8CC;" @click="close_cancelDialog()">
                                        <v-icon style="cursor: pointer;" class="pa-2" >close</v-icon>
                                    </div>
                                </v-layout>
                            </v-card>
                        <v-flex >
                           
                       
                        </v-flex>
                        <v-card-text >
                                 Details entered on the page will be lost if not saved
                        </v-card-text>
                        <v-card-actions justify-start>
                          
                           
                           
                            <v-btn dark  tile color="black" @click="confirmDialogNext = false" class="text-capitalize px-3 mx-2" small><v-icon dark small>cancel</v-icon> <v-divider vertical class="mx-2"></v-divider>Cancel</v-btn>

                            <!-- <v-btn depressed small color="secondary" class="rounded-0"  >
                                <v-icon dark right>check_circle</v-icon>
                            </v-btn> -->
                            <v-btn @click="selectedCompFromPage(nextTabsName)" dark  tile color="#23B1A9" class="text-capitalize px-3 ml-2" small><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider> OK</v-btn>

                        </v-card-actions>
                    </v-card>
                </v-dialog>


            <div class="mx-3 pa-4" style="background-color: #F7F7F7; display:flex ">
            
                <v-layout style="flex:1" justify-end > 
                    <v-btn dark  tile color="#E46A25" class="text-capitalize px-3 mx-2" small @click="cancelData()"><v-icon dark small>cancel</v-icon> <v-divider vertical class="mx-2"></v-divider>Cancel</v-btn>
                    <v-btn dark  tile color="#D1121B" class="text-capitalize px-3 mx-2" small @click="clearData"><v-icon dark small>delete</v-icon> <v-divider vertical class="mx-2"></v-divider>Clear</v-btn>
                    <v-btn dark  tile color="#23B1A9" class="text-capitalize px-3 ml-2" small type = "submit"><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider> Save</v-btn>
                    <v-btn dark  tile color="black" class="text-capitalize px-3 ml-4" small @click="nextBtn()"><v-icon dark small>arrow_forward</v-icon> <v-divider vertical class="mx-2"></v-divider> Next</v-btn>
                </v-layout>
            </div>

            <v-layout class="mb-5"></v-layout>
        </div> 
        </v-form>  
    </v-app>
</template>

<script>
import { required, numeric, alpha, alphaNum, helpers,email } from 'vuelidate/lib/validators';
export const nameChecker = helpers.regex('telenumber',  /^[a-zA-Z ]+$/);
// var fileUpload = null;
export default {

    inject : ['selectedCompFromPage', 'arrToNavCancel'],

    components:{
        
    },

    created() {
        // console.log("unshifted",this.ownerItems);
      
        // console.log("this is ownership value", this.ownership);
        // this.ownerItems = this.ownerItems.push("something")
        // this.getOwnerContactDetailsById("5ff58264af657a505b6d0cfa")


         let userID = this.$store.state.empanelProviderData.empanelment._id
        let _proviType = this.$store.state.empanelProviderData.empanelment.providerType


        this.getOwnerContactDetailsById(userID);
        
    },

    validations:{
        ownership:{required},
        otherOwner:{nameChecker},
        ownerName:{nameChecker},
        stdCode:{required, numeric},
        teleFirstContact: {required, numeric},
        teleSecondContact: {numeric},
        emailContact:{email},
        preauthFax:{numeric},
        faxNumber:{numeric},
        insurance:{required,nameChecker},
        designationPersonal:{required, nameChecker},
        teleFirstPersonal:{required,numeric},
        stdPersonal:{required,numeric},
        teleSecondPersonal:{numeric},
        teleThirdPersonal:{numeric},
        emailPersonal:{email},
        escalationFirstPersonal:{required,nameChecker},
        escalationSecondPersonal:{nameChecker},
        escalationThirdPersonal:{nameChecker},
        relManager:{required,nameChecker},
        designationRelation:{required, nameChecker},
        stdRelation:{required, numeric},
        teleFirstRelation:{required,numeric},
        teleSecondRelation: {numeric},
        teleThirdRelation:{numeric},
        emailRelation:{email},
        escalationFirstRelation:{required,nameChecker},
        escalationSecondRelation:{nameChecker},
        escalationThirdRelation:{nameChecker},
        finManager:{required,nameChecker},
        designationFinance: {required, nameChecker},
        stdFinance:{required, numeric},
        teleFirstFinance:{required, numeric},
        teleSecondFinance:{numeric},
        teleThirdFinance:{numeric},
        emailFinance:{email},
        escalationFirstFinance:{required,nameChecker},
        escalationSecondFinance:{nameChecker},
        escalationThirdFinance:{nameChecker},
        hoi:{required,nameChecker},
        designationHoi: {required, nameChecker},
        stdHoi:{required, numeric},
        teleHoi:{required, numeric},
        emailHoi:{email},
        


    },

    computed: {
    
    ownershipErrors(){
        const errors = [];
           
            if (!this.$v.ownership.$dirty) return errors   
            !this.$v.ownership.required && errors.push('This field is required');
            return errors;
    },

    otherOwnerErrors(){
            const errors = [];
            
                if (!this.$v.otherOwner.$dirty) return errors   
                !this.$v.otherOwner.nameChecker && errors.push('Only alphabets are allowed');
                return errors;
        },
    ownerNameErrors(){
        const errors = [];
           
            if (!this.$v.ownerName.$dirty) return errors   
            !this.$v.ownerName.nameChecker && errors.push('Only alphabets are allowed');
            return errors;
    },

    stdCodeErrors(){
        const errors = [];
           
            if (!this.$v.stdCode.$dirty) return errors   
            !this.$v.stdCode.required && errors.push('This field is required');
            !this.$v.stdCode.numeric && errors.push('Only Numbers are Allowed');
            return errors;
    },

    teleFirstContactCodeErrors(){
         const errors = [];
           
            if (!this.$v.teleFirstContact.$dirty) return errors   
            !this.$v.teleFirstContact.required && errors.push('This field is required');
            !this.$v.teleFirstContact.numeric && errors.push('Only Numbers are Allowed');
            return errors;
    },
    teleSecondContactErrors(){
        const errors = [];
           
            if (!this.$v.teleSecondContact.$dirty) return errors   
            !this.$v.teleSecondContact.numeric && errors.push('Only Numbers are Allowed');
            return errors;
    },
    emailContactErrors(){
         const errors = [];
           
            if (!this.$v.emailContact.$dirty) return errors   
            !this.$v.emailContact.email && errors.push('E-mail must be valid');
            return errors;
    },
    preauthFaxErrors(){
         const errors = [];
            if (!this.$v.preauthFax.$dirty) return errors   
            !this.$v.preauthFax.numeric && errors.push('Only Numbers are Allowed');
            return errors;
    },

    faxNumberErrors(){
            const errors = [];
                if (!this.$v.faxNumber.$dirty) return errors   
                !this.$v.faxNumber.numeric && errors.push('Only Numbers are Allowed');
                return errors;
        },

    insuranceErrors(){
            const errors = [];
                if (!this.$v.insurance.$dirty) return errors
                !this.$v.insurance.required && errors.push('This field is required');  
                !this.$v.insurance.nameChecker && errors.push('Only alphabets are allowed');
                return errors;
        },
    designationPersonalErrors(){
            const errors = [];
                if (!this.$v.designationPersonal.$dirty) return errors
                !this.$v.designationPersonal.required && errors.push('This field is required'); 
                !this.$v.designationPersonal.nameChecker && errors.push('Only alphabets are allowed'); 
                return errors;
        },
    teleFirstPersonalErrors(){
            const errors = [];
                if (!this.$v.teleFirstPersonal.$dirty) return errors
                !this.$v.teleFirstPersonal.required && errors.push('This field is required'); 
                !this.$v.teleFirstPersonal.numeric && errors.push('Only Numbers are Allowed');
                
                return errors;
        },
    stdPersonalErrors(){
            const errors = [];
           
            if (!this.$v.stdPersonal.$dirty) return errors   
            !this.$v.stdPersonal.required && errors.push('This field is required');
            !this.$v.stdPersonal.numeric && errors.push('Only Numbers are Allowed');
            return errors;
    },

    teleSecondPersonalErrors(){
            const errors = [];
           
            if (!this.$v.teleSecondPersonal.$dirty) return errors   
            
            !this.$v.teleSecondPersonal.numeric && errors.push('Only Numbers are Allowed');
            return errors;
    },

    teleThirdPersonalErrors(){
            const errors = [];
           
            if (!this.$v.teleThirdPersonal.$dirty) return errors   
            
            !this.$v.teleThirdPersonal.numeric && errors.push('Only Numbers are Allowed');
            return errors;
    },

     emailPersonalErrors(){
         const errors = [];
           
            if (!this.$v.emailPersonal.$dirty) return errors   
            !this.$v.emailPersonal.email && errors.push('E-mail must be valid');
            return errors;
    },

    escalationFirstPersonalErrors(){
         const errors = [];
           
            if (!this.$v.escalationFirstPersonal.$dirty) return errors   
            !this.$v.escalationFirstPersonal.required && errors.push('This field is required');
            !this.$v.escalationFirstPersonal.nameChecker && errors.push('Only alphabets are allowed');
            return errors;
    },

    escalationSecPersonalErrors(){
         const errors = [];
           
            if (!this.$v.escalationSecondPersonal.$dirty) return errors   ;
            !this.$v.escalationSecondPersonal.nameChecker && errors.push('Only alphabets are allowed');
            return errors;
    },


    escalationThirdPersonalErrors(){
         const errors = [];
           
            if (!this.$v.escalationThirdPersonal.$dirty) return errors   
            !this.$v.escalationThirdPersonal.nameChecker && errors.push('Only alphabets are allowed');
            return errors;
    },

    relManagerErrors(){
         const errors = [];
           
            if (!this.$v.relManager.$dirty) return errors   
            !this.$v.relManager.required && errors.push('This field is required');
            !this.$v.relManager.nameChecker && errors.push('Only alphabets are allowed');
            return errors;
    },

    designationRelationErrors(){
         const errors = [];
           
            if (!this.$v.designationRelation.$dirty) return errors   
            !this.$v.designationRelation.required && errors.push('This field is required');
            !this.$v.designationRelation.nameChecker && errors.push('Only alphabets are allowed');
            return errors;
    },

    stdRelationErrors(){
         const errors = [];
           
            if (!this.$v.stdRelation.$dirty) return errors   
            !this.$v.stdRelation.required && errors.push('This field is required');
            !this.$v.stdRelation.numeric && errors.push('Only Numbers are Allowed');
            return errors;
    },

    teleFirstRelationErrors(){
         const errors = [];
           
            if (!this.$v.teleFirstRelation.$dirty) return errors   
            !this.$v.teleFirstRelation.required && errors.push('This field is required');
            !this.$v.teleFirstRelation.numeric && errors.push('Only Numbers are Allowed');
            return errors;
    },

    teleSecondRelationErrors(){
        const errors = [];
            if (!this.$v.teleSecondRelation.$dirty) return errors   
            !this.$v.teleSecondRelation.numeric && errors.push('Only Numbers are Allowed');
            return errors;
    },

    teleThirdRelationErrors(){
        const errors = [];
            if (!this.$v.teleThirdRelation.$dirty) return errors   
            !this.$v.teleThirdRelation.numeric && errors.push('Only Numbers are Allowed');
            return errors;
    },

    emailRelationErrors(){
         const errors = [];
           
            if (!this.$v.emailRelation.$dirty) return errors   
            !this.$v.emailRelation.email && errors.push('E-mail must be valid');
            return errors;
    },

    escalationFirstRelationErrors(){
        const errors = [];
            if (!this.$v.escalationFirstRelation.$dirty) return errors   
            !this.$v.escalationFirstRelation.required && errors.push('This field is required');
            !this.$v.escalationFirstRelation.nameChecker && errors.push('Only alphabets are allowed');
            return errors;
    },

    escalationSecondRelationErrors(){
        const errors = [];
            if (!this.$v.escalationSecondRelation.$dirty) return errors   
            !this.$v.escalationSecondRelation.nameChecker && errors.push('Only alphabets are allowed');
            return errors;
    },

    escalationThirdRelationErrors(){
        const errors = [];
            if (!this.$v.escalationThirdRelation.$dirty) return errors
            !this.$v.escalationThirdRelation.nameChecker && errors.push('Only alphabets are allowed');
            return errors;
    },

   finManagerErrors(){
        const errors = [];
            if (!this.$v.finManager.$dirty) return errors   
            !this.$v.finManager.required && errors.push('This field is required');
            !this.$v.finManager.nameChecker && errors.push('Only alphabets are allowed');
            return errors;
    },


    designationFinanceErrors(){
         const errors = [];
           
            if (!this.$v.designationFinance.$dirty) return errors   
            !this.$v.designationFinance.required && errors.push('This field is required');
            !this.$v.designationFinance.nameChecker && errors.push('Only alphabets are allowed');
            return errors;
    },

    stdFinanceErrors(){
         const errors = [];
           
            if (!this.$v.stdFinance.$dirty) return errors   
            !this.$v.stdFinance.required && errors.push('This field is required');
            !this.$v.stdFinance.numeric && errors.push('Only Numbers are Allowed');
            return errors;
    },

    teleFirstFinanceErrors(){
         const errors = [];
           
            if (!this.$v.teleFirstFinance.$dirty) return errors   
            !this.$v.teleFirstFinance.required && errors.push('This field is required');
            !this.$v.teleFirstFinance.numeric && errors.push('Only Numbers are Allowed');
            return errors;
    },

    teleSecondFinanceErrors(){
         const errors = [];
           
            if (!this.$v.teleSecondFinance.$dirty) return errors   
            !this.$v.teleSecondFinance.numeric && errors.push('Only Numbers are Allowed');
            return errors;
    },

    teleThirdFinanceErrors(){
         const errors = [];
           
            if (!this.$v.teleThirdFinance.$dirty) return errors   
            !this.$v.teleThirdFinance.numeric && errors.push('Only Numbers are Allowed');
            return errors;
    },

    emailFinanceErrors(){
         const errors = [];
           
            if (!this.$v.emailFinance.$dirty) return errors   
            !this.$v.emailFinance.email && errors.push('E-mail must be valid');
            return errors;
    },

    escalationFirstFinanceErrors(){
          const errors = [];
            if (!this.$v.escalationFirstFinance.$dirty) return errors   
            !this.$v.escalationFirstFinance.required && errors.push('This field is required');
            !this.$v.escalationFirstFinance.nameChecker && errors.push('Only alphabets are allowed');
            return errors;
    },

    escalationSecondFinanceErrors(){
          const errors = [];
            if (!this.$v.escalationSecondFinance.$dirty) return errors   
            !this.$v.escalationSecondFinance.nameChecker && errors.push('Only alphabets are allowed');
            return errors;
    },

    escalationThirdFinanceErrors(){
          const errors = [];
            if (!this.$v.escalationThirdFinance.$dirty) return errors   
            !this.$v.escalationThirdFinance.nameChecker && errors.push('Only alphabets are allowed');
            return errors;
    },

    hoiErrors(){
          const errors = [];
            if (!this.$v.hoi.$dirty) return errors   
            !this.$v.hoi.required && errors.push('This field is required');
            !this.$v.hoi.nameChecker && errors.push('Only alphabets are allowed');
            return errors;
    },

    designationHoiErrors(){
          const errors = [];
            if (!this.$v.designationHoi.$dirty) return errors   
            !this.$v.designationHoi.required && errors.push('This field is required');
            !this.$v.designationHoi.nameChecker && errors.push('Only alphabets are allowed');
            return errors;
    },


    stdHoiErrors(){
            const errors = [];
            
                if (!this.$v.stdHoi.$dirty) return errors   
                !this.$v.stdHoi.required && errors.push('This field is required');
                !this.$v.stdHoi.numeric && errors.push('Only Numbers are Allowed');
                return errors;
        },

        

    teleHoiErrors(){
         const errors = [];
           
            if (!this.$v.teleHoi.$dirty) return errors   
            !this.$v.teleHoi.required && errors.push('This field is required');
            !this.$v.teleHoi.numeric && errors.push('Only Numbers are Allowed');
            return errors;
    },

    emailHoiErrors(){
         const errors = [];
           
            if (!this.$v.emailHoi.$dirty) return errors   
            !this.$v.emailHoi.email && errors.push('E-mail must be valid');
            return errors;
    },

    },


    filters:{

      
    },

    

    data() {
        

        return {
            nextTabsName : '',
            confirmDialogCancel:false,
            confirmDialogNext:false,
            ownership:'',
            ownerItems:["Individual Proprietorship","Registered Partnership","Company - Private Limited","Company - Public Limited","Trust / Charitable","Co-Operative Society","Central Government","State Government","Local Government","Public Sector Undertaking","Any Other"],
            items:[],
            ownershipAnyOther: true,
            otherOwner:"",
            ownerName:"",
            stdCode:"",
            teleFirstContact:"",
            teleSecondContact:"",
            emailContact:"",
            preauthFax:"",
            faxNumber:"",
            insurance:"",
            designationPersonal:"",
            stdPersonal:"",
            teleFirstPersonal:"",
            teleSecondPersonal:"",
            teleThirdPersonal:"",
            emailPersonal:"",
            escalationFirstPersonal:"",
            escalationSecondPersonal:"",
            escalationThirdPersonal:"",
            relManager:"",
            designationRelation:"",
            stdRelation:"",
            teleFirstRelation:"",
            teleSecondRelation:"",
            teleThirdRelation:"",
            emailRelation:"",
            escalationFirstRelation:"",
            escalationSecondRelation:"",
            escalationThirdRelation:"",
            finManager:"",
            designationFinance:"",
            stdFinance:"",
            teleFirstFinance:"",
            teleSecondFinance:"",
            teleThirdFinance:"",
            emailFinance:"",
            escalationFirstFinance:"",
            escalationSecondFinance:"",
            escalationThirdFinance:"",
            hoi:"",
            designationHoi:"",
            stdHoi:"",
            teleHoi:"",
            emailHoi:"",

        };



    },

    methods: {

            submitDetails(){
                  let userID = this.$store.state.empanelProviderData.empanelment._id
                let _proviType = this.$store.state.empanelProviderData.empanelment.providerType
                            var ownerShipFormData = {
                                    "providerType": _proviType,
                                    "ownershipDetails": {
                                        
                                        "ownershipType": this.ownership,
                                        "ownershipTypeOther": this.otherOwner,
                                        "nameOfOwner": this.ownerName
                                    },
                                    "contactDetails": {
                                        "stdCode": this.stdCode,
                                        "telephoneNumberOne": this.teleFirstContact,
                                        "telephoneNumberTwo": this.teleSecondContact,
                                        "email": this.emailContact,
                                        "preauthFaxNumber": this.preauthFax,
                                        "faxNumber": this.faxNumber,
                                    },
                                    "personalDetails": {
                                        "insuranceOrTpacordinator": this.insurance,
                                        "designation": this.designationPersonal,
                                        "stdCode":this.stdPersonal,
                                        "telephoneNumberOne": this.teleFirstPersonal,
                                        "telephoneNumberTwo": this.teleSecondPersonal,
                                        "telephoneNumberThree": this.teleThirdPersonal,
                                        "email": this.emailPersonal,
                                        "escalationNameOne": this.escalationFirstPersonal,
                                        "escalationNameTwo": this.escalationSecondPersonal,
                                        "escalationNameThree": this.escalationThirdPersonal,          
                                        "relationManager": this.relManager,
                                        "rmDesignation": this.designationRelation,
                                        "rmstdCode": this.stdRelation,
                                        "rmtelephoneNumberone": this.teleFirstRelation,
                                        "rmtelephoneNumbertwo": this.teleSecondRelation,
                                        "rmtelephoneNumberthree": this.teleThirdRelation,
                                        "rmemail": this.emailRelation,
                                        "rmescalationNameone": this.escalationFirstRelation,
                                        "rmescalationNametwo": this.escalationSecondRelation,
                                        "rmescalationNamethree": this.escalationThirdRelation,
                                        "finaceManager": this.finManager,
                                        "fmDesignation": this.designationFinance,
                                        "fmstdCode": this.stdFinance,
                                        "fmtelephoneNumberone": this.teleFirstFinance,
                                        "fmtelephoneNumbertwo": this.teleSecondFinance,
                                        "fmtelephoneNumberthree":this.teleThirdFinance,
                                        "fmemail": this.emailFinance,
                                        "fmescalationNameone": this.escalationFirstFinance,
                                        "fmescalationNametwo": this.escalationSecondFinance,
                                        "fmescalationNamethree": this.escalationThirdFinance,
                                        "headofinstitute": this.hoi,
                                        "hoiDesignation": this.designationHoi,
                                        "hoistdCode": this.stdHoi,
                                        "hoitelephoneNumber": this.teleHoi,
                                        "hoiemail": this.emailHoi
                                    }
                                    }


                    this.$v.$touch();

                    if(this.$v.$invalid){
                        console.log("It is not valid form");
                        return this.showToast("Please fill all the fields",this.TOST().WARNING)
                    }else{
                        console.log("It is valid form");
                         let api_Is = 'empanelment/update-ownership-contact-details/'+ userID
                        this.PUT(2,api_Is,ownerShipFormData,(res,error)=>{
                        if(!error){
                            
                            console.log("PUT APi response|-> empanelment/save-payment-details ", res)
                            this.showToast(res.data.msg,this.TOST().SUCCESS)
                            this.getOwnerContactDetailsById(userID); 
                            
                        }else{
                            console.log(error.response.data.msg);
                            console.log("PUT APi error response|->empanelment/save-payment-details", error)
                            this.showToast(error.response.data.msg,this.TOST().ERROR);

                        }    
                    });

                    }

                },


                getOwnerContactDetailsById(id){
                                    
                            let api_Is = 'empanelment/empanelment-details-by-id/'+id;
                            this.GET(2,api_Is,(res,error)=>{
                            if(!error){
                                console.log("%c this is ownership data","color:blue",res.data.data);
                                console.log("%c this is ownership data","color:darkblue",res.data.data.providerType);
                                if(res.data.data.providerType === 'Hospital'){
                                        this.nextTabsName = 'ProviderEmpanelmentSpeciality';
                                }else if(res.data.data.providerType === 'Dental'){
                                        this.nextTabsName = 'ProviderEmpanelmentQualityTeaching'
                                }else if(res.data.data.providerType === 'Ambulance'){
                                        this.nextTabsName = 'ProviderPaymentDetails'
                                }else if(res.data.data.registeredEntity === 'Individual'){
                                    this.nextTabsName = 'ProviderEmpanelmentContactFacility'
                                }else{
                                    this.nextTabsName = 'ProviderPaymentDetails'
                                }
                                
                                    let ownerData = res.data.data;

                                    this.ownership = ownerData.hasOwnProperty('ownershipDetails')? ownerData.ownershipDetails.hasOwnProperty('ownershipType')? this.chkplndata(ownerData.ownershipDetails.ownershipType) :'' :'';
                                    this.otherOwner = ownerData.hasOwnProperty('ownershipDetails')? ownerData.ownershipDetails.hasOwnProperty('ownershipTypeOther')? this.chkplndata(ownerData.ownershipDetails.ownershipTypeOther) :'' :'';
                                    this.ownerName = ownerData.hasOwnProperty('ownershipDetails')? ownerData.ownershipDetails.hasOwnProperty('nameOfOwner')? this.chkplndata(ownerData.ownershipDetails.nameOfOwner) :'' :'';
                                    // this.otherOwner = 'ownershipTypeOther' in ownerData.ownershipDetails ? ownerData.ownershipTypeOther : '';
                                    // this.ownerName = 'nameOfOwner' in ownerData.ownershipDetails ? ownerData.nameOfOwner : '';


                                    this.stdCode =  ownerData.hasOwnProperty('contactDetails')? ownerData.contactDetails.hasOwnProperty('stdCode')? this.chkplndata(ownerData.contactDetails.stdCode) :'' :'';
                                    this.teleFirstContact =  ownerData.hasOwnProperty('contactDetails')? ownerData.contactDetails.hasOwnProperty('telephoneNumberOne')? this.chkplndata(ownerData.contactDetails.telephoneNumberOne) :'' :'';
                                    this.teleSecondContact = ownerData.hasOwnProperty('contactDetails')? ownerData.contactDetails.hasOwnProperty('telephoneNumberTwo')? this.chkplndata(ownerData.contactDetails.telephoneNumberTwo) :'' :'';
                                    this.emailContact =     ownerData.hasOwnProperty('contactDetails')? ownerData.contactDetails.hasOwnProperty('email')? this.chkplndata(ownerData.contactDetails.email) :'' :'';
                                    this.preauthFax = ownerData.hasOwnProperty('contactDetails')? ownerData.contactDetails.hasOwnProperty('preauthFaxNumber')? this.chkplndata(ownerData.contactDetails.preauthFaxNumber) :'' :'';
                                    this.faxNumber = ownerData.hasOwnProperty('contactDetails')? ownerData.contactDetails.hasOwnProperty('faxNumber')? this.chkplndata(ownerData.contactDetails.faxNumber) :'' :'';


                                    this.insurance = ownerData.hasOwnProperty('personalDetails')? ownerData.personalDetails.hasOwnProperty('insuranceOrTpacordinator')? this.chkplndata(ownerData.personalDetails.insuranceOrTpacordinator) :'' :'';
                                    this.designationPersonal = ownerData.hasOwnProperty('personalDetails')? ownerData.personalDetails.hasOwnProperty('designation')? this.chkplndata(ownerData.personalDetails.designation) :'' :'';
                                    this.stdPersonal = ownerData.hasOwnProperty('personalDetails')? ownerData.personalDetails.hasOwnProperty('stdCode')? this.chkplndata(ownerData.personalDetails.stdCode) :'' :'';
                                    this.teleFirstPersonal = ownerData.hasOwnProperty('personalDetails')? ownerData.personalDetails.hasOwnProperty('telephoneNumberOne')? this.chkplndata(ownerData.personalDetails.telephoneNumberOne) :'' :'';
                                    this.teleSecondPersonal = ownerData.hasOwnProperty('personalDetails')? ownerData.personalDetails.hasOwnProperty('telephoneNumberTwo')? this.chkplndata(ownerData.personalDetails.telephoneNumberTwo) :'' :'';
                                    this.teleThirdPersonal = ownerData.hasOwnProperty('personalDetails')? ownerData.personalDetails.hasOwnProperty('telephoneNumberThree')? this.chkplndata(ownerData.personalDetails.telephoneNumberThree) :'' :'';
                                    this.emailPersonal = ownerData.hasOwnProperty('personalDetails')? ownerData.personalDetails.hasOwnProperty('email')? this.chkplndata(ownerData.personalDetails.email) :'' :'';
                                    this.escalationFirstPersonal = ownerData.hasOwnProperty('personalDetails')? ownerData.personalDetails.hasOwnProperty('escalationNameOne')? this.chkplndata(ownerData.personalDetails.escalationNameOne) :'' :'';
                                    this.escalationSecondPersonal = ownerData.hasOwnProperty('personalDetails')? ownerData.personalDetails.hasOwnProperty('escalationNameTwo')? this.chkplndata(ownerData.personalDetails.escalationNameTwo) :'' :'';
                                    this.escalationThirdPersonal  = ownerData.hasOwnProperty('personalDetails')? ownerData.personalDetails.hasOwnProperty('escalationNameThree')? this.chkplndata(ownerData.personalDetails.escalationNameThree) :'' :'';
                                    this.relManager = ownerData.hasOwnProperty('personalDetails')? ownerData.personalDetails.hasOwnProperty('relationManager')? this.chkplndata(ownerData.personalDetails.relationManager) :'' :'';
                                    this.designationRelation = ownerData.hasOwnProperty('personalDetails')? ownerData.personalDetails.hasOwnProperty('rmDesignation')? this.chkplndata(ownerData.personalDetails.rmDesignation) :'' :'';
                                    this.stdRelation = ownerData.hasOwnProperty('personalDetails')? ownerData.personalDetails.hasOwnProperty('rmstdCode')? this.chkplndata(ownerData.personalDetails.rmstdCode) :'' :'';
                                    this.teleFirstRelation = ownerData.hasOwnProperty('personalDetails')? ownerData.personalDetails.hasOwnProperty('rmtelephoneNumberone')? this.chkplndata(ownerData.personalDetails.rmtelephoneNumberone) :'' :'';
                                    this.teleSecondRelation = ownerData.hasOwnProperty('personalDetails')? ownerData.personalDetails.hasOwnProperty('rmtelephoneNumbertwo')? this.chkplndata(ownerData.personalDetails.rmtelephoneNumbertwo) :'' :'';
                                    this.teleThirdRelation = ownerData.hasOwnProperty('personalDetails')? ownerData.personalDetails.hasOwnProperty('rmtelephoneNumberthree')? this.chkplndata(ownerData.personalDetails.rmtelephoneNumberthree) :'' :'';
                                    this.emailRelation = ownerData.hasOwnProperty('personalDetails')? ownerData.personalDetails.hasOwnProperty('rmemail')? this.chkplndata(ownerData.personalDetails.rmemail) :'' :'';
                                    this.escalationFirstRelation = ownerData.hasOwnProperty('personalDetails')? ownerData.personalDetails.hasOwnProperty('rmescalationNameone')? this.chkplndata(ownerData.personalDetails.rmescalationNameone) :'' :'';
                                    this.escalationSecondRelation = ownerData.hasOwnProperty('personalDetails')? ownerData.personalDetails.hasOwnProperty('rmescalationNametwo')? this.chkplndata(ownerData.personalDetails.rmescalationNametwo) :'' :'';
                                    this.escalationThirdRelation = ownerData.hasOwnProperty('personalDetails')? ownerData.personalDetails.hasOwnProperty('rmescalationNamethree')? this.chkplndata(ownerData.personalDetails.rmescalationNamethree) :'' :'';
                                    
                                    this.finManager = ownerData.hasOwnProperty('personalDetails')? ownerData.personalDetails.hasOwnProperty('finaceManager')? this.chkplndata(ownerData.personalDetails.finaceManager) :'' :'';
                                    this.designationFinance = ownerData.hasOwnProperty('personalDetails')? ownerData.personalDetails.hasOwnProperty('fmDesignation')? this.chkplndata(ownerData.personalDetails.fmDesignation) :'' :'';
                                    this.stdFinance = ownerData.hasOwnProperty('personalDetails')? ownerData.personalDetails.hasOwnProperty('fmstdCode')? this.chkplndata(ownerData.personalDetails.fmstdCode) :'' :'';
                                    this.teleFirstFinance = ownerData.hasOwnProperty('personalDetails')? ownerData.personalDetails.hasOwnProperty('fmtelephoneNumberone')? this.chkplndata(ownerData.personalDetails.fmtelephoneNumberone) :'' :'';
                                    this.teleSecondFinance = ownerData.hasOwnProperty('personalDetails')? ownerData.personalDetails.hasOwnProperty('fmtelephoneNumbertwo')? this.chkplndata(ownerData.personalDetails.fmtelephoneNumbertwo) :'' :'';
                                    this.teleThirdFinance = ownerData.hasOwnProperty('personalDetails')? ownerData.personalDetails.hasOwnProperty('fmtelephoneNumberthree')? this.chkplndata(ownerData.personalDetails.fmtelephoneNumberthree) :'' :'';
                                    this.emailFinance = ownerData.hasOwnProperty('personalDetails')? ownerData.personalDetails.hasOwnProperty('fmemail')? this.chkplndata(ownerData.personalDetails.fmemail) :'' :'';
                                    this.escalationFirstFinance = ownerData.hasOwnProperty('personalDetails')? ownerData.personalDetails.hasOwnProperty('fmescalationNameone')? this.chkplndata(ownerData.personalDetails.fmescalationNameone) :'' :'';
                                    this.escalationSecondFinance = ownerData.hasOwnProperty('personalDetails')? ownerData.personalDetails.hasOwnProperty('fmescalationNametwo')? this.chkplndata(ownerData.personalDetails.fmescalationNametwo) :'' :'';
                                    this.escalationThirdFinance = ownerData.hasOwnProperty('personalDetails')? ownerData.personalDetails.hasOwnProperty('fmescalationNamethree')? this.chkplndata(ownerData.personalDetails.fmescalationNamethree) :'' :'';
                                    
                                    this.hoi = ownerData.hasOwnProperty('personalDetails')? ownerData.personalDetails.hasOwnProperty('headofinstitute')? this.chkplndata(ownerData.personalDetails.headofinstitute) :'' :'';
                                    this.designationHoi = ownerData.hasOwnProperty('personalDetails')? ownerData.personalDetails.hasOwnProperty('hoiDesignation')? this.chkplndata(ownerData.personalDetails.hoiDesignation) :'' :'';
                                    this.stdHoi = ownerData.hasOwnProperty('personalDetails')? ownerData.personalDetails.hasOwnProperty('hoistdCode')? this.chkplndata(ownerData.personalDetails.hoistdCode) :'' :'';
                                    this.teleHoi = ownerData.hasOwnProperty('personalDetails')? ownerData.personalDetails.hasOwnProperty('hoitelephoneNumber')? this.chkplndata(ownerData.personalDetails.hoitelephoneNumber) :'' :'';
                                    this.emailHoi = ownerData.hasOwnProperty('personalDetails')? ownerData.personalDetails.hasOwnProperty('hoiemail')? this.chkplndata(ownerData.personalDetails.hoiemail) :'' :'';

                                    this.$store.commit("EMPANEL_TAB_STATUS", ownerData);                    
                            
                        }else{
                            console.log(error.response.data);
                            this.showToast(error.response.data.msg,this.TOST().ERROR);
                        }
                    })
                },


                 chkplndata(data){
                    console.log("chkplndata",data); 
                        try{
                            if (data ==="" || data === "undefined" || data === null) {
                                return ""
                            } else {
                                return data;
                            }

                        }catch(err) {
                            console.log(err)
                        }
            
                },

                 nextBtn(){
                    console.log("%c this is data for next btn on OwnershipTab","color:green",this.$store.state.empanelmentTabStatus);
                    if(this.$store.state.empanelmentTabStatus.ownershipAndContactStatus === false){
                        this.confirmDialogNext = true
                    }else{
                         this.selectedCompFromPage(this.nextTabsName)
                    }
                },

                cancelData(){
                    this.confirmDialogCancel = true
                },
                close_cancelDialog(){
                    this.confirmDialogCancel = false
                    this.confirmDialogNext = false
                },

                clearData(){                     
                        this.ownership = ''
                        this.otherOwner = ''
                        this.ownerName = ''

                        this.stdCode = ''
                        this.teleFirstContact = ''
                        this.teleSecondContact = ''
                        this.emailContact = ''
                        this.preauthFax = ''
                        this.faxNumber = ''


                        this.insurance = ''
                        this.designationPersonal = ''
                        this.stdPersonal = ''
                        this.teleFirstPersonal = ''
                        this.teleSecondPersonal = ''
                        this.teleThirdPersonal = ''
                        this.emailPersonal = ''
                        this.escalationFirstPersonal = ''
                        this.escalationSecondPersonal = ''
                        this.escalationThirdPersonal = ''
                        this.relManager = ''
                        this.designationRelation = ''
                        this.stdRelation = ''
                        this.teleFirstRelation = ''
                        this.teleSecondRelation = ''
                        this.teleThirdRelation = ''
                        this.emailRelation = ''
                        this.escalationFirstRelation = ''
                        this.escalationSecondRelation = ''
                        this.escalationThirdRelation = ''
                        this.finManager = ''
                        this.designationFinance = ''
                        this.stdFinance = ''
                        this.teleFirstFinance = ''
                        this.teleSecondFinance = ''
                        this.teleThirdFinance = ''
                        this.emailFinance = ''
                        this.escalationFirstFinance = ''
                        this.escalationSecondFinance = ''
                        this.escalationThirdFinance = ''
                        this.hoi = ''
                        this.designationHoi = ''
                        this.stdHoi = ''
                        this.teleHoi = ''
                        this.emailHoi = ''
                               
                    }


                                
    },

    watch: {
        ownership(val){
            if(val == "Any Other"){
                console.log("%c this is ownership any other","color:red");
                this.ownershipAnyOther = false;
            }
        }
    },
    
    
}

</script>
<style >
.cust-rmvpd-psins .v-text-field.v-text-field--enclosed>.v-input__control>.v-input__slot {
    padding-right: 0px !important;
}

.cust_pd .v-text-field .v-input__slot,
.v-text-field--outline .v-input__slot {
    min-height: 42px !important;
    display: flex !important;
    align-items: center !important;
    /* border:1px red solid; */
}


</style>