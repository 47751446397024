import axios from "axios";

export const addAuthorizationToken = function () {
  axios.interceptors.request.use((config) => {
    if (!config.headers.Authorization) {
      appendAuthHeader.call(this, config);
    }
    return config;
  });
};

function appendAuthHeader(config) {
  config.headers.Authorization =
    this.$store.state.Auth && this.$store.state.Auth.user
      ? this.$store.state.Auth.user.signInUserSession.idToken.jwtToken
      : "";
}
