<template>
  <div>
    <v-container fluid>
      <v-form ref="form">
      <v-layout row>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Employer Details Available ( Y/ N) *
            </v-text>
            <v-select
              :items="['Yes', 'No']"
              label="Select"
              v-model="employerVerificationDetails.isEmployerDetailsAvelable"
              :rules="rules.mandatoryrule"
              solo
              item-text="state"
              item-value="state"
              class="rounded-0"
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Type of employment *
            </v-text>
            <v-select
              :items="typeOfEmploymentList"
              label="Select"
              v-model="employerVerificationDetails.typeOfEmployment"
              :disabled="employerVerificationDetails.isEmployerDetailsAvelable == 'No' ? true : false"
              :rules="employerVerificationDetails.isEmployerDetailsAvelable == 'Yes' && rules.mandatoryrule"
              solo
              item-text="state"
              item-value="state"
              class="rounded-0"
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Employer/Institution Name *
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="employerVerificationDetails.employerOrInstitutionName"
              maxlength="100"
              counter="100"
              class="rounded-0"
              :disabled="isReadOnlyField ? true : false"
              aria-required="true"
              :rules="!isReadOnlyField && [required()]"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Employer/Institution Address *
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="employerVerificationDetails.employerOrInstitutionAddress"
              :disabled="isReadOnlyField ? true : false"
              maxlength="500"
              counter="500"
              class="rounded-0"
              aria-required="true"
              :rules="!isReadOnlyField && [required()]"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Employer/Institution Mobile Number *
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="employerVerificationDetails.employerOrInstitutionMbNumber"
              :disabled="isReadOnlyField ? true : false"
              maxlength="10"
              counter="10"
              class="rounded-0"
              aria-required="true"
              :rules="!isReadOnlyField && [required(),numericOnly(employerVerificationDetails.employerOrInstitutionMbNumber)]"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Employer/Institution Email ID *
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="employerVerificationDetails.employerOrInstitutionEmail"
              maxlength="50"
              counter="50"
              class="rounded-0"
              :disabled="isReadOnlyField ? true : false"
              aria-required="true"
              :rules="!isReadOnlyField && [required(),emailRules2]"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Date of Joining/Enrolment *
            </v-text>
            <v-menu
              ref="calender"
              :close-on-content-click="true"
              v-model="regCalenderDate"
              :nudge-right="40"
              transition="scale-transition"
              max-width="290px"
              min-width="290px"
              class="rounded-0"
            >
              <!-- :rules="typeOfEmployee !== 'Service ID'?[required()]:[()]" -->
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-bind="attrs"
                  label="Select.."
                  :value="computedDate"
                  solo
                  :disabled="isReadOnlyField ? true : false"
                  class="rounded-0"
                  :rules="!isReadOnlyField && [required()]"
                  v-on="on"
                  @click="datePickerValue(1)"
                  @input="datePickerValue2(1)"
                >
                  <div
                    slot="prepend-inner"
                    style="background-color: #f7f7f7; padding: 9px !important"
                  >
                    <v-icon>event</v-icon>
                  </div>
                </v-text-field>
              </template>
              <v-date-picker v-model="employerVerificationDetails.dateOfJoiningOrEnrolment" no-title>
              </v-date-picker>
            </v-menu>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Leave History/Record details *
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="employerVerificationDetails.leaveHistoryOrRecordDetails"
              maxlength="500"
              :disabled="isReadOnlyField ? true : false"
              counter="500"
              class="rounded-0"
              aria-required="true"
              :rules="!isReadOnlyField && [required()]"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Salary Slip Details *
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="employerVerificationDetails.salarytSleepDetails"
              maxlength="2000"
              counter="2000"
              :disabled="isReadOnlyField ? true : false"
              class="rounded-0"
              aria-required="true"
              :rules="!isReadOnlyField && [required()]"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md4 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Remarks *
            </v-text>
            <v-textarea
              solo
              label="Enter..."
              v-model="employerVerificationDetails.remarks"
              maxlength="5000"
              counter="5000"
              rows="2"
              class="rounded-0"
              aria-required="true"
              :rules="[required()]"
            >
            </v-textarea>
          </v-layout>
        </v-flex>
      </v-layout>
      </v-form>
    </v-container>
    <InvestigationFooterCard :tabno="sno" :formdata="getFormData" :componentName="'employerVerificationDetails'" ref="footerCardRef" @validateTabForms="validateTabForms"
      :siuWorkItemId="siuWorkItemId" :nonMandatoryFields="nonMandatoryFields" 
      :tabFormDataId="tabFormDataId"/>
  </div>
</template>
<script>
 import InvestigationFooterCard from './InvestigationFooterCard.vue';
 import {getPrePopulatedData} from '../apiservices/Investigation';
 import moment  from 'moment';
 export default{
    name: 'EmployerVerificationDetails',
    data() {
        return {
            regCalenderDate: false,
            sno: 11,
            validationmessage: "This field is required",
            lossStatesList: ['A'],
            rules: {
                mandatoryrule: [(v) => !!v || this.validationmessage],
            },
            required(){
                return (v) => (v && v.length > 0) || "This Field is Required";
            },
            typeOfEmploymentList: ['Private Job', 'Business', 'Govt. Service', 'Student', 'Homemaker','Unemployed', 'Other'],
            employerVerificationDetails: {
              isTabSelected: true,
              typeOfEmployment: "",
              isEmployerDetailsAvelable: "",
              employerOrInstitutionName: "",
              employerOrInstitutionAddress: "",
              employerOrInstitutionMbNumber: "",
              employerOrInstitutionEmail: "",
              dateOfJoiningOrEnrolment: "",
              leaveHistoryOrRecordDetails: "",
              salarytSleepDetails: "",
              remarks: "",
            },
            nonMandatoryFields: [],
            required() {
                return (v) => (v && v.length > 0) || "This Field is Required";
            },
            emailRules2(v) {
              if (v.length !== 0) {
              if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,6})+$/.test(v)) {
                return "E-mail must be valid";
              }
      }
    },
        }
    },
    components: {
      InvestigationFooterCard 
       
    },
  computed: {
    isReadOnlyField() {
      if( this.employerVerificationDetails.isEmployerDetailsAvelable === 'No'){
          this.clearAllFields(1);
          return true;
      }else if(this.employerVerificationDetails.typeOfEmployment === 'Business' || 
        this.employerVerificationDetails.typeOfEmployment === 'Other' || 
        this.employerVerificationDetails.typeOfEmployment === 'Homemaker' || 
        this.employerVerificationDetails.typeOfEmployment === 'Unemployed') {
          this.clearAllFields();
          return true;   
      }else{
          return false;
      }
    },
    getFormData() {
      console.log('changes coming is', this.$data.employerVerificationDetails);
      return {employerVerificationDetails: this.$data.employerVerificationDetails};
    },
    computedDate() {
            if(this.employerVerificationDetails.dateOfJoiningOrEnrolment == 'Invalid date'){
                this.employerVerificationDetails.dateOfJoiningOrEnrolment = this.computedatenew('def') ?? ''
                return this.employerVerificationDetails.dateOfJoiningOrEnrolment ? moment(this.employerVerificationDetails.dateOfJoiningOrEnrolment).format("DD-MM-YYYY") : "";
            }else{
                return this.employerVerificationDetails.dateOfJoiningOrEnrolment ? moment(this.employerVerificationDetails.dateOfJoiningOrEnrolment).format("DD-MM-YYYY") : "";
            }
         },

  },
//   mounted() {
//     let found = this.items.find(el => el.sno == 20);
//     if(!found){
//       this.items.push({sno: 20, name: "View / Upload Documents"});
//     }
//     console.log("INDEX NUMBER 20", found);
    
//   },
  props: ['prepoulatedData', "siuWorkItemId", "tabFormDataId"],
  created() {
    let allKeys = Object.keys(this.prepoulatedData);
    allKeys.forEach(key => {
      this.employerVerificationDetails[key] = this.prepoulatedData[key]
    })
  },
  methods: {
    validateTabForms(){
      if(!this.$refs.form.validate()){
        this.$refs.form.validate() 
        this.showToast("Please fill all mandatory fields", this.TOST().WARNING);
        return
      }else{
        this.$refs.footerCardRef.interimSave();
      }
    },
    datePickerValue(incmg){     
            let valueIs = incmg==1?this.$refs.datePicker.lazyValue:incmg==2?this.$refs.datePickerToDate.lazyValue:this.$refs.datePickerIntDate.lazyValue
            console.log("valueIs",typeof(valueIs));
            if(valueIs == 'Invalid date'){
                incmg==1?this.admissionDate = this.computedatenew('def'):incmg==2?this.dischargeDate = this.computedatenew('def'):this.intimationDate = this.computedatenew('def')
            }else{
                incmg==1?this.admissionDate = this.computedatenew('txt',valueIs):incmg==2?this.dischargeDate = this.computedatenew('def'):this.intimationDate = this.computedatenew('txt',valueIs)
            }
        }, 

        datePickerValue2(incmg){
            let valueIs = incmg==1?this.$refs.datePicker.lazyValue:this.$refs.datePickerToDate.lazyValue
            if(valueIs.length == 10){
                incmg==1?this.admissionDate = this.computedatenew('txt',valueIs):incmg==2?this.dischargeDate = this.computedatenew('txt',valueIs):this.intimationDate = this.computedatenew('txt',valueIs)
            }
        },
        clearAllFields(flag){
          if(flag === 1){
            this.employerVerificationDetails.typeOfEmployment = '';
          }
          this.employerVerificationDetails.employerOrInstitutionName = '';
          this.employerVerificationDetails.employerOrInstitutionAddress = '';
          this.employerVerificationDetails.employerOrInstitutionMbNumber = '';
          this.employerVerificationDetails.employerOrInstitutionEmail = '';
          this.employerVerificationDetails.dateOfJoiningOrEnrolment = '';
          this.employerVerificationDetails.leaveHistoryOrRecordDetails = '';
          this.employerVerificationDetails.salarytSleepDetails = '';
        },
    dragleave(event) {
      this.visualEffect = false
    },
    browseCancel() {
      //to select attachments
    //   document.getElementById("cancelchequeupload").click()
    },
    dragover(event) {
      event.preventDefault()
      this.visualEffect = true
    },
    drop(event) {
      event.preventDefault()
    //   this.selectFile() // Trigger the onChange event manually
      this.visualEffect = false
    },
    // computedDate(name) {
    //   return this[name] ? moment(this[name]).format("DD/MM/YYYY") : "";
    // },
    goToPreviousTab() {
      this.sno--;
      let btnNumber = "myBtn" + this.sno;
      const elem = this.$refs[btnNumber];
      elem[0].$el.click();
    },
    goToNextTab() {
      this.sno++;
      console.log('this', this.$children.$options);
      let btnNumber = "myBtn" + this.sno;
      const elem = this.$refs[btnNumber];
      elem[0].$el.click();
    },
    switchTabs(sno, name) {
      this.sno = sno;
      this.tabName = name;
    },
    submit() {
      this.isAlertView = true;
    },
    accept() {
      this.isAlertView = true;
    },
    saveData() {},
    alphaNumeric(fieldName, name, mandatory=true) {
      console.log('fieldName', fieldName, name);
      if (fieldName.length > 0) {
        return (v) => /^[a-zA-Z0-9]+$/.test(v) || "Enter valid " + name;
      }
    },
    numericOnly(val) {
      if(val && val.length > 0){
        return (v) =>/^[1-9]\d{9}$/.test(v) || `Only Numbers are allowed.`;
      }
    },
    onSubmit() {},
    browse() {},
    selectFile() {},
  },
 }
</script>