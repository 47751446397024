<template>
    <v-app>
        <div style="background-color: #FFFFFF;" column class=" pt-3">
            <v-form ref="settleForm">
                <v-layout class="pt-2 ml-2 pb-2">
                    <v-flex xs3>
                        <v-layout column class=" mx-2 my-2 cust_pd">
                            <v-text class="font-weight-bold cstm-clr-pd">
                                Settlement Office Code *
                            </v-text>
                            <v-autocomplete
                                :items="settleOfficeItemsArraycode"
                                label="Select"
                                v-model="settleOfficeCode"
                                maxlength="50"
                                solo
                                dense
                                item-text="settlementOfficeCode"
                                item-value="_id"
                                return-object
                                @change="settleOfficeData(settleOfficeCode)"
                                :rules="[() => !!settleOfficeCode || 'This field is required']"
                                class="rounded-0"
                                >
                                <!-- @input="fieldValidation(settleOfficeCode,'officeCode')"
                                :rules="settleOfficeCodeValid" -->
                            </v-autocomplete>
                        </v-layout>
                    </v-flex>
                    
                    <v-flex xs3>
                        <v-layout column class=" mx-2 my-2 cust_pd">
                            <v-text class="font-weight-bold cstm-clr-pd">
                                Settlement Office Name *
                            </v-text>
                            <v-autocomplete
                                :items="SETOFF_name"
                                label="Select"
                                v-model="settleOfficeName"
                                maxlength="50"
                                solo
                                dense
                                item-text="settlementOfficeName"
                                item-value="_id"
                                return-object
                                @change="settleOfficeNameData(settleOfficeName)"
                                :rules="[() => !!settleOfficeName || 'This field is required']"
                                class="rounded-0"
                                >
                                <!-- @input="fieldValidation(settleOfficeName,'officeName')"
                                :rules="settleOfficeNameValid" -->
                            </v-autocomplete>
                        </v-layout>
                    </v-flex>

                    <v-flex xs3>
                        <v-layout column class=" mx-2 my-2 cust_pd">
                            <v-text class="font-weight-bold cstm-clr-pd">City *</v-text>
                            <v-select
                                :items="cityItemsArray"
                                label="Select"
                                v-model="settleCity"
                                maxlength="50"
                                solo
                                dense
                                disabled
                                background-color='#f7f7f7'
                                @change="getCityData(settleCity)"
                                @input="fieldValidation(settleCity,'city')"
                                :rules="cityValid"
                                class="rounded-0"
                                >
                            </v-select>
                        </v-layout>
                    </v-flex>

                    <v-flex xs3>
                        <v-layout column class=" mx-2 my-2 cust_pd">
                            <v-text class="font-weight-bold cstm-clr-pd">State *</v-text>
                            <v-select
                                :items="stateItemsArray"
                                label="Select"
                                v-model="settleState"
                                maxlength="50"
                                solo
                                dense
                                disabled
                                background-color='#f7f7f7'
                                @change="getCityData(settleState)"
                                class="rounded-0"
                                >
                                <!-- @input="fieldValidation(settleState,'city')"
                                :rules="cityValid" -->
                            </v-select>
                        </v-layout>
                    </v-flex>

                    <v-flex xs3>
                        <v-layout column class=" mx-2 my-2 cust_pd">
                            <v-text class="font-weight-bold cstm-clr-pd">Zone Name *</v-text>
                            <v-select
                                :items="zoneItemsArray"
                                label="Select"
                                v-model="settleZone"
                                maxlength="50"
                                solo
                                dense
                                :rules="[required()]"
                                class="rounded-0"
                                @change="checkSelectedZone()"
                                >
                            </v-select>
                        </v-layout>
                    </v-flex>
                </v-layout >
                
                <v-layout class="ml-2 pb-2">
                    <!-- <v-flex xs3 >
                        <v-layout column class="mx-3 cust_pr chgColor" >
                            <v-card class="rounded-0 pl-4 " dense  style="height: 40px; display: flex;align-items: center; width:270px">
                            <v-checkbox class="ma-0 pa-0" v-model="allSelected"  hide-details  label="ECU"></v-checkbox>
                            </v-card>
                        </v-layout>
                    </v-flex> -->
                    <v-flex xs3>
                        <!-- disabled removed for now as  disabled -->
                        <v-layout column class="mx-2 cust_pd" style="margin-top:34px" >
                            <v-card tile class="pa-2">
                                <v-checkbox class="ma-0 pa-0" v-model="settleECU"  hide-details  label="ECU" ></v-checkbox>
                            </v-card>
                        </v-layout>
                    </v-flex>
                    <v-flex xs3>
                        <v-layout column class="mx-2 cust_pd" style="margin-top:34px" >
                            <v-card tile class="pa-2">
                                <v-checkbox class="ma-0 pa-0" v-model="settleCorpOffice"  hide-details  label="Corporate Office" ></v-checkbox>
                            </v-card>
                        </v-layout>
                    </v-flex> 
                    <v-flex xs3>
                        <v-layout column class=" mx-2 my-2 cust_pd">
                            <v-text class="font-weight-bold cstm-clr-pd">LOB *</v-text>
                            <v-select
                                v-model="settleLob"
                                :items="lobItems"
                                label="Select"
                                solo
                                dense
                                multiple
                                class="rounded-0"
                                :rules="[required()]"
                            >
                            <template v-slot:prepend-item>
                                <v-list-item ripple @click="selectLOBData()">
                                    <v-list-item-action>
                                        <v-icon :color="settleLob.length > 0 ? 'blue darken-2' : ''">{{ icon }}</v-icon>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title>Select All</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-divider ></v-divider>
                            </template>
                            </v-select>
                        </v-layout>
                    </v-flex>
                    <!-- <v-flex xs3></v-flex> -->
                    <v-flex xs3></v-flex>
                    <v-flex xs3></v-flex>
                </v-layout>
                <v-divider></v-divider>
                <v-card tile style="" class="rounded-0">
                    <div class="mx-2 pa-2" style="background-color:#eee; display:flex;justify-content:space-between;margin-left:0px!important;margin-right:0px!important;"  >
                        <v-layout style="flex:1" justify-end > 
                            <v-btn dark @click="clearSettleData()" tile color="#E46A25" class="text-capitalize px-3 mx-2 my-2" small><v-icon dark small>cancel</v-icon> <v-divider vertical class="mx-2"></v-divider>Clear</v-btn>
                            <v-btn dark :loading="CREDT" @click="createSettlemnt()" tile color="black" class="text-capitalize px-3 mx-2 my-2" small><v-icon dark small>{{ !edit_mode ? 'add_circle' : 'edit'}}</v-icon> <v-divider vertical class="mx-2"></v-divider> {{!edit_mode ? 'Create' : 'Edit/Update'}} </v-btn>
                            <v-btn dark @click="closeSettleData()" tile color="#E46A25" class="text-capitalize px-3 ml-2 mr-2 my-2" small><v-icon dark small>cancel</v-icon> <v-divider vertical class="mx-2"></v-divider>Close</v-btn>
                        </v-layout>
                    </div>
                </v-card>

                <v-card class="rounded-0">
                    <v-layout class="settlemntheadercolor">
                        <p class="cardfont" style="font-weight:700;margin-bottom:7px!important;margin-top:10px;padding-left:10px;">SETTLEMENT OFFICE SEARCH</p>
                    </v-layout>
                    <v-card >
                        <v-layout flex-wrap align-center class="pt-3 pb-3 settlementbgcolor">
                            <v-flex xs3 v-if="false" >
                                <v-layout column class=" mx-2 pl-2 cust_pd">
                                    <v-text class="font-weight-bold cstm-clr-pd">Search Criteria</v-text>
                                        <!-- <h3> {{searchCriteria}} </h3> -->
                                    <v-select
                                        :items="searchCritItemsArray"
                                        label="Select"
                                        v-model="searchCriteria"
                                        @change="changeSearchCriteria(searchCriteria)"
                                        solo
                                        dense
                                        class="rounded-0"
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex>

                            <v-flex xs3 v-if="showECUDropdown == true">
                                <v-layout column class=" mx-2 pl-2 cust_pd">
                                    <v-text class="font-weight-bold cstm-clr-pd">Search Text</v-text>
                                    <v-select
                                        :items="searchECUArray"
                                        label="Select"
                                        v-model="searchText"
                                        solo
                                        dense
                                        class="rounded-0"
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex>

                            <div class="inrownow" v-if="showECUDropdown == false">
                                <v-flex xs3 v-for="(entSrch, indexis) in  cr_mapping" :key="indexis" >
                                    <v-layout column class=" mx-2 pl-2 cust_pd">
                                        <span class="font-weight-bold cstm-clr-pd">{{entSrch.dd}}</span>
                                       
                                        <div   v-if="entSrch.dd == 'ECU' || entSrch.dd == 'Corporate Office' || entSrch.dd == 'LOB'" >
                                             
                                                <v-select
                                                    :items="ecu_corp"
                                                    item-text="txt"
                                                     item-value="value"
                                                    :label="'Select '+setextra(entSrch.dd)"
                                                    v-model.trim="entSrch.srchval"
                                                    solo
                                                    dense
                                                    class="rounded-0"
                                                    v-if="entSrch.dd !== 'LOB'"
                                                    >
                                                </v-select>

                                                <v-select
                                                v-model="entSrch.srchval"
                                                :items="lobItems"
                                                label="Select"
                                                solo
                                                dense
                                                multiple
                                                class="rounded-0"
                                                v-if="entSrch.dd == 'LOB'"
                                            >
                                            <template v-slot:prepend-item>
                                                <v-list-item ripple @click="selectLOBData()">
                                                    <v-list-item-action>
                                                        <v-icon :color="settleLob.length > 0 ? 'blue darken-2' : ''">{{ icon }}</v-icon>
                                                    </v-list-item-action>
                                                    <v-list-item-content>
                                                        <v-list-item-title>Select All</v-list-item-title>
                                                    </v-list-item-content>
                                                </v-list-item>
                                                <v-divider ></v-divider>
                                            </template>
                                            </v-select>
                                        </div>

                                         <div v-else  >
                                            
                                            <v-text-field
                                              
                                                solo
                                                :label="'Enter '+setextra(entSrch.dd)"
                                                v-model.trim="entSrch.srchval"
                                                class="rounded-0"
                                                dense
                                                >
                                            </v-text-field>
                                            
                                        </div>
                                    </v-layout>
                                </v-flex>
                            </div>

                            <v-flex xs3 >
                                <v-layout > 
                                    <v-btn dark @click="settleSearch(1)" tile color="#23B1A9" class="text-capitalize px-3 mx-2 my-2 ml-4" small><v-icon dark small>search</v-icon> <v-divider vertical class="mx-2"></v-divider>Search</v-btn>
                                    <v-btn dark @click="clearSettleSearch()" tile color="black" class="text-capitalize px-3 mx-2 my-2" small><v-icon dark small>cancel</v-icon> <v-divider vertical class="mx-2"></v-divider>Clear</v-btn>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3></v-flex>
                        </v-layout>
                    </v-card>
                </v-card>
                <!-- <v-divider class="mt-4"></v-divider> -->
                <v-card class=" mb-4 rounded-0" v-if="searchResults">
                    <v-layout class="headercolor">
                        <p class="cardfont" style="font-weight:700;margin-bottom:7px!important;margin-top:10px;padding-left:10px;">Search Results</p>
                    </v-layout>
                    <v-data-table
                        :headers="headers"
                        :items="settleTableData"
                        hide-default-footer
                        dense
                        disable-pagination>
                        <template  v-slot:[`item.corporateOffice`]={item}>
                            <tr ><td  >{{ item.hasOwnProperty('corporateOffice') ? item.corporateOffice == true ? 'Y' : 'N' : ''}} </td></tr>
                        </template>
                        <template  v-slot:[`item.ecu`]={item}>
                            <tr ><td  >{{ item.ecu == true ? 'Y' : 'N'}} </td></tr>
                        </template>
                        <template  v-slot:[`item.editoffice`]={item}>
                            <tr ><td  > <v-btn  elevation="1" class="my-2" fab x-small @click="set_edit(item)" > 
                                <v-icon>edit</v-icon> </v-btn> </td></tr>
                        </template>
                        <template  v-slot:[`item.audit`]={item}>
                            <tr ><td> <v-btn tile color="#F7F7F7" class="px-3 my-2" small @click="view_item(item)" > 
                                <v-icon color="" small>visibility</v-icon></v-btn> </td></tr>
                        </template>
                    </v-data-table>
                    <v-divider></v-divider>
                    <v-layout row justify-end align-center style="background-color:#f7f7f7" class="pa-2">
                        <span class="mr-1 font-weight-bold body-1" style="color:#3C3D3D; font-size:12px !important">
                        <span class="" style="color:#E46A25;font-size:12px;">Showing {{fval}}-{{sval}} </span>out of {{totalLen}} records</span>
                        <v-divider  class="ma-2" vertical ></v-divider>
                        <v-pagination
                            class="pdct-stUp-bnkAcc-pgn"
                            v-model="pageSettle"
                            color="#152F38"
                            flat
                            @input="nextPageData(pageSettle)"
                            :length="pagelength"
                        ></v-pagination>
                    </v-layout>
                    
                </v-card>
                <v-card tile class="pa-2 pl-4" v-if="noSearchResults">
                    <v-layout justify-center>
                        <p class="font-weight-bold pa-0 ma-0 cstm-clr-settlement-searchResult">No results were found based on your search criteria.</p>
                    </v-layout>
                </v-card>
            </v-form>
        </div>

        <!-- table popup -->
        <v-dialog v-model="dialogTable">
            <v-card color="#F7F7F7" class="pb-2" tile>
                <v-card>
          <v-layout
            justify-space-between
            class=""
            style="flex:1;background-ox-shadow: 0px 3px 5px #00000029;"
          >
            <v-card-title
              class="pa-0 pl-3 text-capitalize"
              style="color:#10242B;font-size:16px"
              >Audit Trail of Settlement Office</v-card-title
            >

            <div
              style="border-left:1px solid #C1C8CC;"
              @click="closeDialog"
            >
              <v-icon style="cursor: pointer;" class="pa-2">close</v-icon>
            </div>
          </v-layout>
        </v-card>
                <v-data-table
                        class="pa-4"
                        :headers="headersNew"
                        :items="settleTableDataNew"
                        hide-default-footer
                        dense
                        disable-pagination>
                        <!-- <template  v-slot:item.corporateOffice={item}>
                            <tr ><td  >{{ item.hasOwnProperty('corporateOffice') ? item.corporateOffice == true ? 'Y' : 'N' : ''}} </td></tr>
                        </template>
                        <template  v-slot:item.ecu={item}>
                            <tr ><td  >{{ item.ecu == true ? 'Y' : 'N'}} </td></tr>
                        </template> -->
                        
                </v-data-table>
                <v-divider></v-divider>
                    <v-layout row justify-end align-center style="background-color:#f7f7f7" class="pa-2 mr-3">
                        <span class="mr-1 font-weight-bold body-1" style="color:#3C3D3D; font-size:12px !important">
                        <span class="" style="color:#E46A25;font-size:12px;">Showing {{fromPage}}-{{toPage}} </span>out of {{totalPages}} records</span>
                        <v-divider  class="ma-2" vertical ></v-divider>
                        <v-pagination
                            class="pdct-stUp-bnkAcc-pgn"
                            v-model="page"
                            color="#152F38"
                            flat
                            @input="nextPageData(page)"
                            :length="pageLengthNew"
                        ></v-pagination>
                    </v-layout>

            </v-card>
            <!-- <v-card tile>
            <v-flex xs3 style="position:relative;left:88%">
                    <v-layout > 
                        <v-btn dark @click="()=>{dialogTable = false}" tile color="#23B1A9" class="text-capitalize px-3 mx-2 my-2 ml-4" small><v-icon dark small>search</v-icon> <v-divider vertical class="mx-2"></v-divider>Search</v-btn>
                        <v-btn dark @click="clearSettleSearch()" tile color="black" class="text-capitalize px-3 mx-2 my-2" small><v-icon dark small>cancel</v-icon> <v-divider vertical class="mx-2"></v-divider>Clear</v-btn>
                    </v-layout>
                </v-flex>
            </v-card>     -->

        </v-dialog>

    </v-app>
</template>

<script>
import axios from "axios";
import moment from 'moment';
  
export default {
    created() {
        this.$store.commit("SAVE_PAGE_HEADER", " Settlement Office Creation Master");
        // console.log("this.getEditDetails(this.$router.history.current.name) from Mixin===>",this.getEditDetails(this.$router.history.current.name).isEdit)
        // if(this.getEditDetails(this.$router.history.current.name)){
        //     this.disableAll=this.getEditDetails(this.$router.history.current.name).isEdit
            
        // }else{
        //     this.disableAll=false
        // }
        console.log("this.API_Service_USER_MGMNT() ",this.API_Service_USER_MGMNT())
        // this.getAllSettlementData(1)
        this.getSettlementMastrData()
        this.getAllCityData()

        
        //Surendra(21-Sep-2021):LOB List from BE,code starts here
        this.GET(3,'line-of-business/get-line-of-business-list', (res,error)=>{
            if(!error){
                console.log("response from api is",res.data.data[0].lineOfBusiness);
                this.lobItems=res.data.data[0].lineOfBusiness
            }else{
                console.log("error",error.response.data);
            }
        });
        //Surendra(21-Sep-2021):LOB List from BE,code ends here
    },

    data() {
        return {
            dialogTable: false,
            CREDT:false,
            edit_mode:false,
            current_edit:{},
            settleOfficeItemsArray:[],
            settleOfficeItemsArraycode:[],
            // settleOfficeNameItemsArray:[],
            pageSettle: 1,
            pagelength:0,
            fval:0,
            sval:0,
            totalLen:0,

            fromPage:0,
            toPage:0,
            totalPages:'',
            // itemsPerPage:20,            
            page:1,
            pageLengthNew:'',
            lastPage:false,

            swap_final_count:false,
            typesearch:false,
            searchECUText:'',
            searchECUArray:[
                {text:'Yes',value:'true'},
                {text:'No',value:'false'},
            ],
            settleOfficeCode:'',
            settleOfficeName:'',
            lobItems:[],//'Health','PA','AIGC','Marine'],
            ecu_corp:[
                {txt:'Yes',value:true},
                {txt:'No',value:false}
            ],
            cr_mapping:[
                {srchval:"",
                    dd:'City', api_param:'city'},

	{srchval:"",
        dd:'State', api_param:'state'},

	{srchval:"",
        dd:'Settlement Office Code', api_param:'settlementOfficeCode'},

	{srchval:"",
        dd:'Settlement Office Name', api_param:'settlementOfficeName' },

	{srchval:"",
        dd:'Zone', api_param:'zone'},

	{srchval:"",
        dd:'ECU',  api_param:'ecu'},

	{srchval:"",
        dd:'Corporate Office', api_param:'corporateOffice' },

	{srchval:"",
        dd:'LOB', api_param:'lob' }
            ],
            searchCritItemsArray:['City','State','Settlement Office Code','Settlement Office Name','Zone','ECU','Corporate Office','LOB'],
            zoneItemsArray:['DELHI', 'HYDERABAD', 'AHMEDABAD', 'KOLKATA', 'BANGALORE', 'PUNE', 'MUMBAI', 'CHENNAI','CORPORATE','ECU'],//Surendra(15-09-2021):'CORPORATE','ECU' added from Deepak's mail
            cityItemsArray:[],
            stateItemsArray:[],
            settleCity:'',
            settleState:'',
            settleZone:'',
            settleECU:false,
            settleCorpOffice:false,
            settleLob:[],
            searchCriteria:'',
            searchText:'',
            showECUDropdown:false,
            GetData:[
                {
                    SerialNumber:"1",
                    lob:"PA,Health",
                    city:"Mumbai",
                    state:"Maharashtra",
                    setOfficeCode:"1234",
                    setOfficeName:"ABC",
                    zone:"Zone 1",
                    ecu:"Y"
                },{
                    SerialNumber:"1",
                    lob:"PA,Health",
                    city:"Mumbai",
                    state:"Maharashtra",
                    setOfficeCode:"1234",
                    setOfficeName:"ABC",
                    zone:"Zone 1",
                    ecu:"Y"
                },
                {
                   SerialNumber:"1",
                    lob:"PA,Health",
                    city:"Mumbai",
                    state:"Maharashtra",
                    setOfficeCode:"1234",
                    setOfficeName:"ABC",
                    zone:"Zone 1",
                    ecu:"Y"
                },
                {
                    SerialNumber:"1",
                    lob:"PA,Health",
                    city:"Mumbai",
                    state:"Maharashtra",
                    setOfficeCode:"1234",
                    setOfficeName:"ABC",
                    zone:"Zone 1",
                    ecu:"Y"
                },
            ],
            settleTableData:[],
            headers:[
                // {text: 'Sr. No.', value: 'SerialNumber',sortable: false,divider:true,class:"hdr-txt-clrApproval hdr-bg-clr"},
                {text: 'LOB', value: 'lob',sortable: false,divider:true,class:"hdr-txt-clrApproval hdr-bg-clr"},
                {text: 'City', value: 'city',sortable: false,divider:true,class:"hdr-txt-clrApproval hdr-bg-clr"},
                {text: 'State', value: 'state',sortable: false,divider:true,class:"hdr-txt-clrApproval hdr-bg-clr"},
                {text: 'Settlement Office Code', value: 'settlementOfficeCode',sortable: false,divider:true,class:"hdr-txt-clrApproval hdr-bg-clr"},
                {text: 'Settlement Office Name', value: 'settlementOfficeName',sortable: false,divider:true,class:"hdr-txt-clrApproval hdr-bg-clr"},
                {text: 'Zone', value: 'zone',sortable: false,divider:true,class:"hdr-txt-clrApproval hdr-bg-clr"},
                {text: 'Corporate Office', value: 'corporateOffice',sortable: false,divider:true,class:"hdr-txt-clrApproval hdr-bg-clr"},
                {text: 'ECU', value: 'ecu',sortable: false,divider:true,class:"hdr-txt-clrApproval hdr-bg-clr"},
                {text: 'Edit', value: 'editoffice',sortable: false,divider:true,class:"hdr-txt-clrApproval hdr-bg-clr"},
                {text: 'Audit Trail', value: 'audit',sortable: false,divider:true,class:"hdr-txt-clrApproval hdr-bg-clr"}
        
            ],
            settleTableDataNew:[],
            headersNew:[
                // {text: 'Sr. No.', value: 'SerialNumber',sortable: false,divider:true,class:"hdr-txt-clrApproval hdr-bg-clr"},
                {text: 'LOB', value: 'lob',sortable: false,divider:true,class:"hdr-txt-clrApproval hdr-bg-clr"},
                {text: 'City', value: 'city',sortable: false,divider:true,class:"hdr-txt-clrApproval hdr-bg-clr"},
                {text: 'State', value: 'state',sortable: false,divider:true,class:"hdr-txt-clrApproval hdr-bg-clr"},
                {text: 'Settlement Office Code', value: 'settlementOfficeCode',sortable: false,divider:true,class:"hdr-txt-clrApproval hdr-bg-clr"},
                {text: 'Settlement Office Name', value: 'settlementOfficeName',sortable: false,divider:true,class:"hdr-txt-clrApproval hdr-bg-clr"},
                {text: 'Zone', value: 'zone',sortable: false,divider:true,class:"hdr-txt-clrApproval hdr-bg-clr"},
                {text: 'Corporate Office', value: 'corporateOffice',sortable: false,divider:true,class:"hdr-txt-clrApproval hdr-bg-clr"},
                {text: 'ECU', value: 'ecu',sortable: false,divider:true,class:"hdr-txt-clrApproval hdr-bg-clr"},
                {text: 'Changed By', value: 'changedBy',sortable: false,divider:true,class:"hdr-txt-clrApproval hdr-bg-clr"},
                {text: 'User ID', value: 'userId',sortable: false,divider:true,class:"hdr-txt-clrApproval hdr-bg-clr"},
                {text: 'Date & time of change', value: 'dateTime',sortable: false,divider:true,class:"hdr-txt-clrApproval hdr-bg-clr"},
                {text: 'Approver Name', value: 'approverName',sortable: false,divider:true,class:"hdr-txt-clrApproval hdr-bg-clr"},
                {text: 'Approver ID', value: 'approverId',sortable: false,divider:true,class:"hdr-txt-clrApproval hdr-bg-clr"},
        
            ],
            settleOfficeCodeValid:[],
            settleOfficeNameValid:[],
            cityValid:[],

            stateValid:[],
            zoneValid:[],
            lobValid:[],
            required(ev){
                return v=>v && v.length>0 || "This Field is Required"
            },
            disableAll:true,
            noSearchResults:false,
            searchResults:false
        };
    },
    computed:{
        compareLOBList() {
            return this.settleLob.length === this.lobItems.length
        },
        selectSomeLOB () {
            return this.settleLob.length > 0 && !this.compareLOBList
        },
        icon () {
            if (this.compareLOBList) return 'mdi-close-box'
            if (this.selectSomeLOB) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },

        SETOFF_name(){
            return this.settleOfficeItemsArray.sort((a,b)=> a.settlementOfficeName.localeCompare(b.settlementOfficeName))
        },

        // SETOFF_CODE(){
        //      return this.settleOfficeItemsArray.sort((a,b)=> a.settlementOfficeCode-b.settlementOfficeCode)
        // }


    },

    methods:{

        setextra(inplaceholder){
            return inplaceholder == 'ECU' ? inplaceholder+" values Yes/No" : inplaceholder == 'Corporate Office' ?inplaceholder+" values Yes/No":  inplaceholder
        },

        settleOfficeData(data){
            let self = this
            console.log("**********((((((((  settleOfficeData +++++++++++++>>>>>", data)
            self.settleOfficeName = data
            self.settleMasterCityZoneAPI(data.cityCode,data.zoneCode)
            this.settleECU=false
            this.settleCorpOffice=false
            this.settleLob = []
        },
        settleOfficeNameData(data){
            let self = this
            // console.log("**********((((((((  settleOfficeNameData +++++++++++++>>>>>", data)
            self.settleOfficeCode = data 
            self.settleMasterCityZoneAPI(data.cityCode,data.zoneCode)
             this.settleECU=false
            this.settleCorpOffice=false
              this.settleLob = []
        },
        settleMasterCityZoneAPI(cityID,zoneID,support_type){
            let self = this
            this.GET(3,'settlementoffice/search-settlement-office-master-data/'+ cityID + '/' + zoneID,(res,error)=>{
                if(!error){
                    if(res.data.statusCode === 200){
                        // this.showToast(res.data.msg, this.TOST().SUCCESS);
                        let _region = res.data.data
                        self.settleCity = _region.city
                        self.settleState = _region.state
                        self.settleZone = _region.zoneName
                        self.edit_mode = support_type == 'office_edit'?true:false
                        self.CREDT = false
                    }else{
                        self.showToast(res.data.msg ,self.TOST().ERROR);
                        self.showAlert(res.data.msg ); 
                        self.CREDT = false
                    }
                }else{
                    self.showToast(error.response.data.msg, self.TOST().WARNING);
                    self.showAlert(error.response.data.msg);
                    self.CREDT = false
                }
            })
        },
        getSettlementMastrData(){
            // adc99
            let self = this
            this.GET(3,'settlementoffice/settlement-office-masterdata',(res,error)=>{
                if(!error){
                    if(res.data.statusCode === 200){
                        // this.showToast(res.data.msg, this.TOST().SUCCESS);
                        let _settle = res.data.data
                        for(let _prop of _settle ){
                            if(_prop.hasOwnProperty('settlementOfficeCode')){
                              self.settleOfficeItemsArraycode.push(_prop)
                            } self.settleOfficeItemsArray.push(_prop)
                        }

                        self.settleOfficeItemsArraycode = self.settleOfficeItemsArraycode.sort((a,b)=> a.settlementOfficeCode-b.settlementOfficeCode)

                        // self.settleOfficeItemsArray

                    }else{
                        this.showToast(res.data.msg ,this.TOST().ERROR); 
                        this.showAlert(res.data.msg );
                    }
                }else{
                    this.showToast(error.response.data.msg, this.TOST().WARNING);
                    this.showAlert(error.response.data.msg );
                }
            })
        },
        nextPageData(indata){
            this.pagelength == indata ? this.swap_final_count = true : this.swap_final_count = false
            // this.typesearch == false  ? this.getAllSettlementData(this.pageSettle) : this.settleSearch(this.pageSettle)
            this.settleSearch(this.pageSettle)
        },
        getAllCityData(){
            let self = this
            self.GET(3,'settlementoffice/search-by-city',(res,error)=>{
                if(!error){
                    if(res.data.statusCode === 200){
                        // self.showToast(res.data.msg, self.TOST().SUCCESS);
                        res.data.data.map(function(el) {
                            if(el.hasOwnProperty('city') == true){
                                if(el.city !== undefined) self.cityItemsArray.push(el.city)
                            }
                            if(el.hasOwnProperty('state') == true){
                                if(el.state !== undefined) self.stateItemsArray.push(el.state)
                            }
                        })
                    }else{
                        self.showToast(res.data.msg ,self.TOST().ERROR); 
                        self.showAlert(res.data.msg);
                    }
                }else{
                    self.showToast(error.response.data.msg, self.TOST().WARNING);
                    self.showAlert(error.response.data.msg );
                }
            })
        },
        changeSearchCriteria(ev){
            if(ev == 'ECU' || ev == 'Corporate Office') {
                this.showECUDropdown = true
            }else{
                this.showECUDropdown = false
                this.searchText = ''
            }
        },
        selectLOBData(ev){
            this.$nextTick(() => {
                this.compareLOBList ? this.settleLob = [] : this.settleLob = this.lobItems.slice()
            })
        },
        fieldValidation(value,fieldName){
            let nameRegex = /^[a-zA-Z0-9]+$/
            let officeNameRegex = /^[ a-zA-Z0-9]+$/
            // console.log('(((((((((( fieldValidation ))))))))))',value)
            // value.match(nameRegex)
            if(fieldName == 'officeCode'){
                this.settleOfficeCodeValid = []
                value === '' ? this.settleOfficeCodeValid.push('This field is required') : ''
                nameRegex.test(value) ? this.settleOfficeCodeValid.push() : this.settleOfficeCodeValid.push('Only alphanumeric values are allowed')

            }else if(fieldName == 'officeName'){
                this.settleOfficeNameValid = []
                value === '' ? this.settleOfficeNameValid.push('This field is required') : ''
                officeNameRegex.test(value) ? this.settleOfficeNameValid.push() : this.settleOfficeNameValid.push('Only alphanumeric values are allowed')

            }else if(fieldName == 'city'){
                this.cityValid = []
                value === '' ? this.cityValid.push('This field is required') : ''
                nameRegex.test(value) ? this.cityValid.push() : this.cityValid.push('Enter Valid City')

            }
        },
        clearSettleData(){
            this.current_edit = {}
            this.edit_mode = false
            this.settleOfficeCode = ''
            this.settleOfficeName = ''
            this.settleCity = ''
            this.settleState = ''
            this.settleZone = ''
            this.settleECU = false
            this.settleCorpOffice = false
            this.settleLob = []
            this.$refs.settleForm.resetValidation()
        },
        closeSettleData(){
            this.$router.push('home')
        },
        clearSettleSearch(){
            this.cr_mapping.map(e=> e.srchval = '');
            this.searchCriteria = ''
            this.searchText = ''
            this.settleTableData=[]
            this.showECUDropdown = false
            this.searchResults=false
            this.noSearchResults=false
            // this.getAllSettlementData(1)
        },
        settleSearch(skip){
  let criterianew = ""
              this.cr_mapping.forEach((e,indis)=>{
                    if(e.srchval.toString().length > 0){
                        console.log(e,"srchval", e.srchval)
                        let type = criterianew.length == 0? '?' : '&' 
                        criterianew += type+e.api_param+"="+e.srchval 
                       
                        console.log("criterianew ", criterianew)
                    }
              })

            if(criterianew.length == 0){
            
                this.showToast('Please enter search criteria', this.TOST().WARNING);
                this.showAlert('Please enter search criteria');
               
                return
            // }   
            }
            console.log(' searched hit ', criterianew.toString())
            
            let self = this

            // if(self.searchCriteria == '' || self.searchText == ''){
            //     self.showToast('Please enter search criteria', self.TOST().WARNING);
            //     self.showAlert('Please enter search criteria');
            //     // self.$refs.settleForm.validate();
            //     return
            // }   
            //     let criteria = ''
            //   this.cr_mapping.forEach(e=>{
            //         if(e.dd == self.searchCriteria){
            //             criteria='?'+e.api_param+"="+self.searchText
            //         }
            //   })

            //   console.log("criteria --->>>", criteria);

            // if(criteria == ''){
            //     self.showToast('Please select or enter a search criteria', self.TOST().WARNING);
            //     // self.showAlert('Please enter search criteria');
            //     return
            // }

            let theheaders = this.authHeaderNew();
            theheaders.system = "CM"//this.user_details().selectedLOB;
            let a =  self.API_Service_USER_MGMNT() + 
                'settlementoffice/search-settlement-office'+criterianew+'&perPage=20&pageNo='+skip
                console.log("url is ", a)
             
            axios({
                method: "get",
                url:self.API_Service_USER_MGMNT() + 
                'settlementoffice/search-settlement-office'+criterianew+'&perPage=20&pageNo='+skip,
                
                // ?searchCriteria='+ self.searchCriteria +'&searchText='+ self.searchText+'
              
                headers: theheaders,
            }).then(function (res,error) {
                if(!error){
                    if(res.data.statusCode === 200){
                        console.log(res,'resapi response')
                        // self.showToast(res.data.msg, self.TOST().SUCCESS);
                            self.cleartable()
                          
                        if(res.data.hasOwnProperty('data')){
                            if(Object.keys(res.data.data).length == 0){
                           
                             self.showAlert(res.data.msg );
                            //  self.showToast(res.data.msg,self.TOST().ERROR);
                              
                               return 
                            }
                        }
                           
                        self.settleTableData = res.data.data.data.map(function(currentValue, Index) {
                            // currentValue.SerialNumber = (Index + 1) 
                            currentValue.SerialNumber = 20 * (skip) - 19 + Index 
                            return currentValue
                            
                        })
                        self.pagelength = res.data.data.total_pages
                        self.totalLen = res.data.data.total
                        
                        var less_enough = self.totalLen
                        var checkless_init = false
                        less_enough < 20 ? checkless_init = false : checkless_init = true

                        // if less than 15 we have second value same as total value as no pagination will occur
                        if(checkless_init){
                            // checkinit is true means the final count is more than 15 (- 1)
                            var traverse =  20 * self.pageSettle
                            self.fval = traverse - 19
                            self.swap_final_count ? self.sval =  self.totalLen : self.sval = traverse
                        }else if(self.totalLen == 0){
                            self.sval = self.fval = 0
                        
                        }else{
                            self.fval = 1
                            self.sval = self.totalLen
                        }
                        self.searchResults=true
                        self.noSearchResults=false
                    }else{
                        self.showToast(res.data.msg ,self.TOST().ERROR); 
                    }
                }else{
                    // self.cleartable()
                    self.showToast(error.response.data.msg, self.TOST().WARNING);
                    self.showAlert(error.response.data.msg );
                    self.searchResults=false
                    self.noSearchResults=true
                }
            }).catch(function (error) {
                console.log("error is ", error);
                // self.cleartable()
                self.showToast(error.response.data.msg,self.TOST().ERROR);
                self.showAlert(error.response.data.msg );
                self.searchResults=false
                self.noSearchResults=true
            });
        },

        cleartable(){
                     this.settleTableData = []
                     this.totalLen = 0
                     this.sval = this.fval = 0
                     this.pagelength = 0
                    //  this.searchResults=false
                    // this.noSearchResults=false
        },

        getCityData(name){
            let self = this
            this.GET(3,'settlementoffice/search-by-city?city=' + name,(res,error)=>{
                if(!error){
                    if(res.data.statusCode === 200){
                        // self.showToast(res.data.msg, self.TOST().SUCCESS);
                        self.settleState = res.data.data[0].state       
                    }else{
                        self.showToast(res.data.msg ,self.TOST().ERROR); 
                        self.showAlert(res.data.msg );
                    }
                }else{
                    self.showToast(error.response.data.msg, self.TOST().WARNING);
                    self.showAlert(error.response.data.msg );
                }
            })
        },
        getAllSettlementData(skip){
            let self = this
               let theheaders = this.authHeaderNew();
            theheaders.system = this.user_details().selectedLOB;
            axios({
                method: "get",
                url:self.API_Service_USER_MGMNT() + "settlementoffice/all-settlement-office?perPage=20&pageNo=" + skip,
                headers: theheaders
            }).then(function (res,error) {
                if(!error){
                    if(res.data.statusCode === 200){
                        // self.showToast(res.data.msg, self.TOST().SUCCESS);
                        // console.log("=========(((((((((_____RESPPP)))))))))=======>>",res)
                        self.settleTableData = res.data.data.data.map(function(currentValue, Index) {
                            currentValue.SerialNumber = 20 * (skip) - 19 + Index 
                            return currentValue
                        })

                        self.pagelength = res.data.data.total_pages
                        self.totalLen = res.data.data.total
                        
                        var less_enough = self.totalLen
                        var checkless_init = false
                        less_enough < 20 ? checkless_init = false : checkless_init = true

                        // if less than 15 we have second value same as total value as no pagination will occur
                        if(checkless_init){
                            // checkinit is true means the final count is more than 15 (- 1)
                            var traverse =  20 * self.pageSettle
                            self.fval = traverse - 19
                            self.swap_final_count ? self.sval =  self.totalLen : self.sval = traverse
                        }else if(self.totalLen == 0){
                            self.sval = self.fval = 0
                        
                        }else{
                            self.fval = 1
                            self.sval = self.totalLen
                        }
                    }else{
                        self.showToast(res.data.msg ,self.TOST().ERROR); 
                        self.showAlert(res.data.msg );
                    }
                }else{
                    self.showToast(error.response.data.msg, self.TOST().WARNING);
                    self.showAlert(error.response.data.msg );
                }
            }).catch(function (error) {
                self.showToast(error.response.data.msg,self.TOST().ERROR);
                self.showAlert(error.response.data.msg );
            });
        },
        createSettlemnt(){
            let self = this

            if(self.settleOfficeCode == '' || self.settleOfficeName == ''){
                self.showToast('Please fill all empty fields', self.TOST().WARNING);
                // self.showAlert('Please fill all empty fields' );
                self.$refs.settleForm.validate();
                return
            }   

            if(self.settleOfficeCodeValid.length > 0 || self.settleOfficeNameValid.length > 0  || self.cityValid.length > 0){
                self.showToast('Please fill Correct data', self.TOST().WARNING);
                // self.showAlert('Please fill Correct data');
                return;
            }

            if(this.settleLob.length == 0){
                self.showToast('Please Enter LOB to continue', self.TOST().WARNING);
                // self.showAlert('Please fill Correct data');
                return;
            }        

            let formData = {
                settlementOfficeCode: self.settleOfficeCode.settlementOfficeCode,
                settlementOfficeName: self.settleOfficeName.settlementOfficeName,
                city: self.settleCity,
                state: self.settleState,
                zone: self.settleZone,
                ecu: self.settleECU,
                lob: self.settleLob,
                corporateOffice:self.settleCorpOffice,
            }

            let theheaders = this.authHeaderNew();
            theheaders.system = this.user_details().selectedLOB;
            // console.log("=========(((((((((formData)))))))))=======>>",formData)
            let editmode = this.edit_mode;
            // 'update-settlement-office-details/'
            let APIbuild =  editmode?"settlementoffice/update-settlement-office-details/"+this.current_edit._id:"settlementoffice/create-settlement-office"
            console.log("APIbuild >>",APIbuild);
            
            if(editmode){
                    self.PUT(6,APIbuild,formData, (res, error)=>{
                        if(!error){
                            if(res.data.statusCode === 200){
                                self.showToast(res.data.msg, self.TOST().SUCCESS);
                                self.showAlert(res.data.msg);
                                // self.$router.push('/tarrif-approval')
                                self.clearSettleData()
                                self.getAllSettlementData()
                            }else{
                                self.showToast(res.data.msg ,self.TOST().ERROR); 
                                self.showAlert(res.data.msg);
                            }
                        }else{
                            self.showToast(error.response.data.msg, self.TOST().WARNING);
                            self.showAlert(error.response.data.msg);
                        }
                    })
            }else{
                    self.POST(6,APIbuild,formData, (res, error)=>{
                        if(!error){
                            if(res.data.statusCode === 200){
                                self.showToast(res.data.msg, self.TOST().SUCCESS);
                                self.showAlert(res.data.msg);
                                // self.$router.push('/tarrif-approval')
                                self.clearSettleData()
                                self.getAllSettlementData()
                            }else{
                                self.showToast(res.data.msg ,self.TOST().ERROR); 
                                self.showAlert(res.data.msg);
                            }
                        }else{
                            self.showToast(error.response.data.msg, self.TOST().WARNING);
                            self.showAlert(error.response.data.msg);
                        }
                    })
            }
            // axios({
            //     method: editmode? "put":"post",
            //     url:APIbuild,
            //     headers:theheaders ,
            //     data: formData,
            // }).then(function (res,error) {
               
            // }).catch(function (error) {
            //     self.showToast(error.response.data.msg,self.TOST().ERROR);
            //     self.showAlert(error.response.data.msg);
            // });
            // this.POST(6,'settlementoffice/create-settlement-office',formData,(res,error)=>{
            //     if(!error){
            //         if(res.data.statusCode === 200){
            //             this.showToast(res.data.msg, this.TOST().SUCCESS);
            //             this.$router.push('/tarrif-approval')
            //         }else{
            //             this.showToast(res.data.msg ,this.TOST().ERROR); 
            //         }
            //     }else{
            //         this.showToast(error.response.data.msg, this.TOST().WARNING);
            //     }
            // })
        },
        checkSelectedZone(){
            //Surendra(15-09-2021):  Corporate Zone to be introduced. Corporate office check box is always read only & auto selected only if corporate zone is selected./\NU check box is auto selected when CG 'NU' is selected
            console.log(this.settleZone);
            if(this.settleZone  ==  "ECU"){
                this.settleECU   =   true;
            }else{
                this.settleECU   =   false;
            } 
            if(this.settleZone  ==  "CORPORATE"){
                this.settleCorpOffice   =   true;
            }else{
                this.settleCorpOffice   =   false;
            }
        },

        set_edit(ofcdata){
            // re-binding to form se99
            console.log("officedata", ofcdata);
            
            this.CREDT = true
            this.current_edit = ofcdata;
            let posone =  this.settleOfficeItemsArray.findIndex(e=> e.settlementOfficeCode == ofcdata.settlementOfficeCode)
            console.log(posone,'posone>> ',this.settleOfficeItemsArray[posone])
            if(posone == -1){
                  this.CREDT = false
                this.showToast('Settelment office data not found to perform Edit operation',this.TOST().WARNING);
                return
            }
            this.settleOfficeCode = this.settleOfficeItemsArray[posone];
            this.settleOfficeName = this.settleOfficeItemsArray[posone];
            this.settleLob = [...ofcdata.lob];
            this.settleECU = ofcdata.ecu;
            this.settleCorpOffice = ofcdata.corporateOffice;
            this.settleMasterCityZoneAPI(this.settleOfficeItemsArray[posone].cityCode,this.settleOfficeItemsArray[posone].zoneCode,'office_edit');
            
            // this.settleOfficeCode = ofcdata.settleOfficeCode


        },

        closeDialog(){
           this.dialogTable = false;
           this.settleTableDataNew = [];
           this.fromPage = 0;
           this.toPage = 0;
           this.totalPages = 0;
        //    this.page = 0
        },

        view_item(item){
            console.log("item", item);
            this.dialogTable = true;
            this.GET(6,'settlementoffice/settlement-office-audit/'+item._id,(res,error)=>{
                if(!error){
                    console.log(res.data.data);
                    if(res.data.data == undefined){
                        console.log("res");
                        this.showToast(res.data.msg, this.TOST().ERROR);
                    }else{
                    console.log("res");
                    this.pagination(res.data.data);

                    this.settleTableDataNew = res.data.data.data.map((item,index) => {
                        let dateIs = moment(item.updatedAt.split('T')[0]).format("DD-MM-YYYY");
                        console.log(dateIs);

                        let timeIs = item.updatedAt.split('T')[1].slice(0,8)

                        return{
                            lob: item.lob[0],
                            city: item.city,
                            state: item.state,
                            settlementOfficeCode: item.settlementOfficeCode,
                            settlementOfficeName: item.settlementOfficeName,
                            zone: item.zone,
                            corporateOffice: item.corporateOffice,
                            ecu: item.ecu,
                            changedBy: res.data.data.changedBy,
                            userId: res.data.data.userId,
                            approverName: 'NA',
                            approverId: 'NA',
                            dateTime: dateIs + ' ' + timeIs
                        }
                    })
                    }
                }else{
                    console.log(err);
                    this.showToast(err.response.data.msg, this.TOST().WARNING);
                    // self.showAlert(error.response.data.msg );
                }
            })
        },

        pagination(dataFromApi){
            console.log("data in pagination",dataFromApi);
                                    this.pageLengthNew = dataFromApi.total_pages;
                                    this.totalPages = dataFromApi.total
                                    var temp =  this.page*10;
                                    this.fromPage = temp-9;
                                   
                                    if(this.lastPage){
                                        this.toPage = this.totalPages
                                        console.log("value of toPage is ",this.toPage,"\n","value of from page",this.fromPage);
                                    }else{
                                        if(this.totalPages < 10){
                                            this.toPage = this.totalPages
                                            console.log("this are the pageLength for searchTable",this.totalPages);
                                        }else{

                                            this.toPage = temp;
                                            console.log("value of toPage is ",this.toPage,"\n","value of from page",this.fromPage);

                                        }

                                    }
        },

    },
}
</script>

<style>

.inrownow{
    display:flex; flex-direction: row; justify-content: space-around; align-items: center;
    flex-wrap: wrap;
}

.cust-rmvpd-psins3 .v-text-field.v-text-field--enclosed>.v-input__control>.v-input__slot {
    padding-right: 0px !important;
}

.cust_pr2 .v-text-field .v-input__slot,
.v-text-field--outline .v-input__slot {
    min-height: 42px !important;
    display: flex !important;
    align-items: center !important;
    /* border:1px red solid; */
}

.cstm-clr-pr3{
    color:#646666 !important;
    margin-bottom: 5px !important;
    font-size: 13px !important;
}

.cust_pd1 .v-text-field .v-input__slot,
.v-text-field--outline .v-input__slot {
    min-height: 42px !important;
    display: flex !important;
    align-items: center !important;
    /* border:1px red solid; */
}

.cust-rmvpd-pr1 .v-text-field.v-text-field--enclosed>.v-input__control>.v-input__slot {
    padding: 0px !important;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
    font-size: 0.84rem !important;
}

.hdr-txt-clrApproval{
    color: white !important;
    font-weight: bold;
    /* padding:0px!important;
    padding: 10px !important;
    padding-left: 14px !important; */
}

.hdr-bg-clr{
  background-color: #23B1A9;
}

.cardfont{
    font-size: 0.84rem !important;
    padding-right: 2px;
}
.cust-bnkAcc-pgn .v-pagination__navigation{
        box-shadow:unset !important;
        border-radius: unset !important;
        background-color:#FFFFFF !important;
        border:1px solid #DAE1E6 !important
    }
    .cust-bnkAcc-pgn .v-pagination__navigation--disabled{
        background-color:#DAE1E6 !important;
    }
    .cust-bnkAcc-pgn .v-pagination__item{
        box-shadow:unset !important;
        min-width:2rem !important;
        height:2rem !important;
        border-radius: unset !important;
        border:1px solid #DAE1E6 !important
    }

    .chgColor .v-input .v-label {
    font-size: 13px !important;
    color: black !important;
    font-weight: bold
    }

    .settlemntheadercolor{
        background-color: #01BBD9 !important;
        color: white !important;
    }
    .settlementbgcolor{
        background-color: #eee;
    }

    .cstm-clr-settlement-searchResult{
        color:#10242B !important;
        margin-bottom: 5px !important;
        font-size: 13px !important;
    }
</style>