<template>
  <div>
    <!-- form fields -->
    <v-form ref="Formvalid" class="mr-2 mt-5">
      <v-row class="ml-1">
        <v-col class="inputwidth">
          <v-flex class="mb-2 font-weight-bold">Search Criteria</v-flex>
        </v-col>
        <v-col class="inputwidth">
          <v-flex class="mb-2 claimlabel">Policy Number </v-flex>
          <v-text-field
            placeholder="Enter..."
            v-model="policyDeatils.policyNo"
            solo
            class="forminput"
            type="text"
            maxlength="15"
            dense
            :rules="rulesValidation.policyNo"
          ></v-text-field>
        </v-col>
        <v-col class="inputwidth">
          <v-flex class="mb-2 claimlabel">Certificate Number </v-flex>
          <v-text-field
            placeholder="Enter..."
            solo
            v-model="policyDeatils.certificateNo"
            class="forminput"
            dense
            type="text"
            :maxlength="max20"
            :rules="rulesValidation.alphaNumaric"
          ></v-text-field>
        </v-col>
        <v-col class="inputwidth">
          <v-flex class="mb-2 claimlabel">Application No.</v-flex>
          <v-text-field
            placeholder="Enter..."
            solo
            v-model="policyDeatils.applicationNo"
            :rules="rulesValidation.alphaNumaric"
            class="forminput"
            type="text"
            dense
            :maxlength="max20"
          ></v-text-field>
        </v-col>
        <v-col class="inputwidth">
          <v-flex class="mb-2 claimlabel">E-Marine Policy Number </v-flex>
          <v-text-field
            label=""
            placeholder="Enter..."
            solo
            v-model="policyDeatils.eMarinePolicyNo"
            class="forminput"
            type="text"
            dense
            :rules="rulesValidation.alphaNumaric"
            :maxlength="max20"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="ml-2">
        <v-col class="inputwidth">
          <v-flex class="mb-2 claimlabel">Insured Name</v-flex>
          <v-text-field
            placeholder="Enter..."
            solo
            v-model="policyDeatils.insuredName"
            maxlength="150"
            class="forminput"
            dense
            type="text"
            :rules="rulesValidation.insuredName"
          ></v-text-field>
        </v-col>
        <v-col class="inputwidth">
          <v-flex class="mb-2 claimlabel">Insured ID </v-flex>
          <v-text-field
            placeholder="Enter..."
            v-model="policyDeatils.insuredId"
            solo
            class="forminput"
            dense
            type="text"
            :maxlength="max20"
            :rules="rulesValidation.alphaNumaric"
          ></v-text-field>
        </v-col>
        <v-col class="inputwidth">
          <v-flex class="mb-2 claimlabel">Policy Start Date</v-flex>
          <v-menu
            v-model="PolicyStartMenu"
            :close-on-content-click="false"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                type="text"
                v-model="policyDeatils.policyStartDate"
                placeholder="dd/mm/yyyy"
                solo
                dense
                readonly
                v-on:focus="onPSFocus"
                v-on="on"
               class="forminput"
                v-bind="attrs"
                :rules="rulesValidation.StartDateRules"
                append-icon="mdi-menu-down"
                @keyup="ChangeDateBindCalenderStart(policyDeatils.policyStartDate)"
              >
                <template v-slot:prepend-inner class="mx-0 my-0">
                  <v-icon class="iconstyle">mdi-calendar</v-icon>
                </template>
              </v-text-field>
            </template>
            <v-date-picker
              v-model="policyStartDateFormatted"
              no-title
              :max="todayDate"
              @input="PolicyStartMenu = false"
              @click:date="policyDeatils.policyStartDate = formatDate(policyStartDateFormatted)"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col class="inputwidth">
          <v-flex class="mb-2 claimlabel"> Policy End Date </v-flex>
          <v-menu
            v-model="PolicyEndMenu"
            :close-on-content-click="false"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="policyDeatils.policyEndDate"
                placeholder="dd/mm/yyyy"
                solo
                dense
                readonly
                v-on:focus="onPEFocus"
                v-on="on"
              class="forminput"
                v-bind="attrs"
                
                :rules="rulesValidation.endDateRules"
                @keyup="ChangeDateBindCalenderEnd(policyDeatils.policyEndDate)"
                append-icon="mdi-menu-down"
              >
                <template v-slot:prepend-inner class="mx-0 my-0">
                  <v-icon class="iconstyle">mdi-calendar</v-icon>
                </template>
              </v-text-field>
            </template>
            <v-date-picker
              v-model="policyEndDateFormatted"
              no-title
              @input="PolicyEndMenu = false"
              @click:date="policyDeatils.policyEndDate = formatDate(policyEndDateFormatted)"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col class="inputwidth">
          <v-flex class="mb-2 claimlabel">LR Number</v-flex>
          <v-text-field
            label=""
            placeholder="Enter..."
            solo
            @focus="LrFocus"
            class="forminput"
            :maxlength="max20"
            type="text"
            :rules="rulesValidation.alphaNumaric"
            dense
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="ml-2">
        <v-col class="inputwidth">
          <v-flex class="mb-2 claimlabel">Invoice Number</v-flex>
          <v-text-field
            placeholder="Enter..."
            solo
            v-model="policyDeatils.invoiceNumber"
            class="forminput"
            dense
            :maxlength="max20"
            :rules="rulesValidation.alphaNumaric"
            type="text"
          ></v-text-field>
        </v-col>
        <v-col class="inputwidth">
          <v-flex class="mb-2 claimlabel">Business Type</v-flex>
          <v-text-field
            placeholder="Enter..."
            v-model="policyDeatils.businessType"
            solo
            :maxlength="max20"
            :rules="rulesValidation.alphaNumaric"
            class="forminput"
            dense
            type="text"
          ></v-text-field>
        </v-col>

        <v-col class="inputwidth">
          <v-flex class="mb-2 claimlabel"> Date of Loss </v-flex>
          <v-menu
            v-model="dateofLossMenu"
            :close-on-content-click="false"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="policyDeatils.dateOfLoss"
                placeholder="dd/mm/yyyy"
                solo
                dense
                v-on="on"
                v-on:focus="onDLFocus"
                readonly
                class="forminput"
                :rules="rulesValidation.dateOfLoss"
                v-bind="attrs"
                append-icon="mdi-menu-down"
                @keyup="ChangeDateBindCalenderDateOfLoss(policyDeatils.dateOfLoss)"
              >
                <template v-slot:prepend-inner class="mx-0 my-0">
                  <v-icon class="iconstyle">mdi-calendar</v-icon>
                </template>
              </v-text-field>
            </template>
            <v-date-picker
              v-model="dateofLossFormatted"
              :max="todayDate"
                no-title
              @change="toDisableTime"
              @input="dateofLossMenu = false"
              @click:date="policyDeatils.dateOfLoss = formatDate(dateofLossFormatted)"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col class="inputwidth">
          <v-flex class="mb-2 claimlabel"> Loss Time </v-flex>
          <v-menu
            ref="lossTimeMenuCheck"
            v-model="lossTimeMenu"
            :close-on-content-click="false"
            :return-value.sync="policyDeatils.lossTime"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="policyDeatils.lossTime"
                placeholder="HH:MM"
                solo
                dense
                readonly
                v-on:focus="onLTFocus"
                v-on="on"
               class="forminput"
                v-bind="attrs"
                append-icon="mdi-menu-down"
                :rules="rulesValidation.lossTime"
              >
                <template v-slot:prepend-inner class="mx-0">
                  <v-icon class="iconstyle">mdi-clock</v-icon>
                </template>
              </v-text-field>
            </template>
            <!-- @input="lossTimeMenu = false" -->
            <!-- @click:minute="$refs.lossTimemenu.save(claimDetails.lossTime)" -->
            <v-time-picker
              full-width
              format="24hr"
               no-title
                v-if="lossTimeMenu"
               :max='currentTime'
              v-model="policyDeatils.lossTime"
                @click:minute="$refs.lossTimeMenuCheck.save(policyDeatils.lossTime)"
            ></v-time-picker>
          </v-menu>
        </v-col>
        <v-col class="inputwidth">
        </v-col>
      </v-row>
      <v-row class="mt-2">
        <!--  Search buttons -->
        <v-flex justify="end">
          <v-row class="btnDiv">
            <v-col align="right">
              <v-btn
                color="#E46A25"
                dark
                class="buttonscolor mr-5"
                type="submit"
                @click.prevent="SearchPolicy">
                <v-icon class="mr-2 fontClass">mdi-magnify</v-icon>
                <div class="vertical_line"></div>Search
              </v-btn>
              <v-btn
                color="#E46A25"
                dark
                class="buttonscolor mr-5"
                @click="ConfirmRest">
                <v-icon class="mr-2 fontClass">mdi-close-circle</v-icon>
                <div class="vertical_line"></div>Clear
              </v-btn>
              <v-btn
                color="#152F38"
                dark
                class="buttonscolor mr-5"
                @click="backTOFnol">
                <v-icon class="mr-2 fontClass">mdi-arrow-left-circle</v-icon>
                <div class="vertical_line"></div>Back
              </v-btn>
            </v-col>
          </v-row>
        </v-flex>
      </v-row>
    </v-form>
    <!--  Table -->
    <!-- <v-flex class="mt-6 ml-3 backgroundTable">
      <v-flex class="font-weight-bold body-1 pl-3 pt-1"> Search Results</v-flex>
      <v-simple-table class="mt-1">
        <template v-slot:default>
          <thead class="tableBackGroundColor">
            <tr class="trowhight">
              <th
                class="text-left"
                v-for="(item, index) in PloclySearchHeadOptions"
                :key="index"
              >
                {{ item }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in polcicySearchTableData" :key="index">
              <td>{{ index + 1 }}</td>
              <td>{{ item.PolicyNumber }}</td>
              <td>{{ item.InsuredName }}</td>
              <td>{{ item.InsuredID }}</td>
              <td>{{ item.CertificateNumber }}</td>
              <td>{{ item.LOB }}</td>
              <td>{{ item.ProductName }}</td>
              <td>{{ item.PolicyStartDate }}</td>
              <td>{{ item.PolicyEndDate }}</td>
              <td
                style="color: #1a0dab; text-decoration: underline"
                class="font-weight-bold"
                @click="viewMoreFun(index)"
              >
                View More >
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-flex> -->
    <!--  proceed and back buttons -->
    <v-flex class="mt-6 ml-0 backgroundTable">
      <v-flex class="font-weight-bold pl-3 pt-1 mb-3 fontSizeCls">Search Results</v-flex>
      <v-data-table
        :headers="PloclySearchHeadOptions"
        :items="itemsWithSno"
        disable-pagination
        :hide-default-footer="true"
        class="vDataTableStyling tableHeight">
        <template v-slot:[`item.action`]="{ item }">
          <v-btn
            color="white"
            class="buttonscolor mr-5"
            @click="viewMoreFun(item.sNo)"
            max-height="25">
            <v-icon class="mr-2 font-weight-bold fontClass">mdi-eye</v-icon>
            <div class="vertical_lineBlack"></div>  View
          </v-btn>
          <!-- <div
            style="color: #1a0dab; text-decoration: underline"
            small
            class="mr-2 font-weight-bold"
            
          >
            Action>
          </div> -->
        </template>
      </v-data-table>
    </v-flex>
    <!-- back and proceed  code-->
    <v-flex justify="end" class="mt-5">
      <v-row class="btnDiv">
        <v-col align="right">
          <v-btn
            v-if="polcicySearchTableData.length == 1 ? true : false"
            color="#23B1A9"
            dark
            class="buttonscolor mr-5"
            @click="proceedFnol">
            <v-icon class="mr-2 fontClass">mdi-arrow-right-circle</v-icon>
            <div class="vertical_line"></div>Proceed
          </v-btn>
          <v-btn
            color="#152F38"
            dark
            class="buttonscolor mr-5"
            @click="backRetrun">
            <v-icon class="mr-2 fontClass">mdi-arrow-left-circle</v-icon>
            <div class="vertical_line"></div>
            Back
          </v-btn>
        </v-col>
      </v-row>
    </v-flex>

    <!-- view More Dailogbox -->
    <v-row justify="center">
      <v-dialog v-model="viewMore" persistent max-width="54%">
        <v-card>
          <v-card-title class="pl-3 px-0 py-0 cardTitleStyle">
            <v-row>
              <span class="policyDeatilsSpan mb-5">Policy Details</span>
              <v-icon @click="viewMore = false" class="ml-3 mt-2">mdi-window-close</v-icon>
            </v-row>
            <v-card-text class="backgroundTable">
              <v-flex class="flexStyle">
                <v-row class="rowWidthViewMore">
                  <v-flex>
                    <div class="font-weight-bold">Policy Number</div>
                    <div>{{viewMoreData.policyNumber}}</div>
                  </v-flex>
                  <v-flex>
                    <div class="font-weight-bold">Insured Name</div>
                    <div>{{viewMoreData.insuredName}}</div>
                  </v-flex>
                  <v-flex>
                    <div class="font-weight-bold">Insured ID</div>
                    <div>{{viewMoreData.insuredId== undefined ? viewMoreData.txtCustomerId : viewMoreData.insuredId}}</div>
                  </v-flex>
                  <v-flex>
                    <div class="font-weight-bold">Certificate Number</div>
                    <div>{{viewMoreData.certificateNumber}}</div>
                  </v-flex>
                </v-row>
                <div class="horizontal_line"></div>
                <v-row class="rowWidthViewMore">
                  <v-flex>
                    <div class="font-weight-bold">Product Name</div>
                    <div>{{viewMoreData.txtProductName}}</div>
                  </v-flex>
                  <v-flex>
                    <div class="font-weight-bold">Policy Start Date</div>
                    <div>{{ISOtoDDMMYY(viewMoreData.policyStartInceptionDate)}}</div>
                  </v-flex>
                  <v-flex>
                    <div class="font-weight-bold">Policy End Date</div>
                    <div>{{ISOtoDDMMYY(viewMoreData.policyExpiryDate)}}</div>
                  </v-flex>
                  <v-flex>
                    <div class="font-weight-bold">Application Number</div>
                    <div>{{viewMoreData.applicationNumber}}</div>
                  </v-flex>
                </v-row>
                <div class="horizontal_line"></div>
                <v-row class="rowWidthViewMore">
                  <v-flex>
                    <div class="font-weight-bold">Business Type</div>
                    <div>{{viewMoreData.txtBusinessType}}</div>
                  </v-flex>
                  <v-flex>
                    <div class="font-weight-bold">
                      E-Marine Certificate Number
                    </div>
                    <!-- <div>{{viewMoreData.eMarineCertificateNo== underline ? "" : viewMoreData.eMarineCertificateNo}}</div> -->
                  </v-flex>
                  <v-flex>
                    <div class="font-weight-bold">LR Number</div>
                     <div>{{viewMoreDataMarin.lrNumber}}</div>
                  </v-flex>
                  <v-flex>
                    <div class="font-weight-bold">Invoice Number</div>
                    <div>{{viewMoreDataMarin.invoiceNumber}}</div>
                  </v-flex>
                </v-row>
              </v-flex>
            </v-card-text>
          </v-card-title>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import moment from "moment";
export default {
	data() {
		return {
			todayDate: new Date().toISOString().substr(0, 10),
			max20: 20,
      classNameDialog: "errorDailogboxMsg",
			ConfirmText: "",
			dailoboxSubmitText: "",
      currentTime:'',
			viewMore: false,
			proceedMenu: true,
			PolicyStartMenu: false,
			PolicyEndMenu: false,
			dateofLossMenu: false,
			lossTimeMenu: false,
			lostTimeCheckValidation: false,
			policyStartDateFormatted: null,
			policyEndDateFormatted: null,
			dateofLossFormatted: null,
			lossTimeFormatted: null,
			lossTime: null,
      viewMoreData:{},
      viewMoreDataMarin:{},
			policyDeatils: {
				policyStartDate: "",
				policyEndDate: "",
				dateOfLoss: "",
				lossTime: "",
				policyNo: "",
				insuredName: "",
				insuredId: "",
				certificateNo: "",
				applicationNo: "",
				eMarinePolicyNo: "",
				lrNumber: "",
				invoiceNumber: "",
        businessType:"",
        eMarineCertificateNo:""
			},
			PloclySearchHeadOptions: [{
				text: "Sr.No",
				value: "sNo",
				sortable: false,
			}, {
				text: "Policy Number",
				value: "policyNumber",
				sortable: false,
			}, {
				text: "Insured Name",
				value: "insuredName",
				sortable: false,
			}, {
				text: "Insured ID",
				value: "insuredId",
				sortable: false,
			}, {
				text: "Certificate Number",
				value: "certificateNumber",
				sortable: false,
			}, {
				text: "Product Name",
				value: "txtProductName",
				sortable: false,
			}, {
				text: "Policy Start Date",
				value: "policyStartInceptionDate",
				sortable: false,
			}, {
				text: "Policy End Date",
				value: "policyExpiryDate",
				sortable: false,
			}, {
				text: "Action",
				value: "action",
				sortable: false,
			}, ],
			polcicySearchTableData: [],
      policySearchmarineData:[],
      claimStatus:'',
      claimSubStatus:'',
      reasonForSuspense:'',
			pattern: {
				splChar: /^[A-Za-z0-9 ]+$/,
				Datepattern: /^(((0|1)[0-9]|2[0-9]|3[0-1])\/(0[1-9]|1[0-2])\/((19|20)\d\d))$/,
				splcharMsg: "Special Characters Not Allowed.",
				errorMessage: "This field  is required.",
        insuredName : /^[a-zA-Z0-9\s\/]+$/,
        policynumber: /^[a-zA-Z0-9]+$/
			},
			rulesValidation: {
				StartDateRules: [
					(v) => !!v || false, 
          (v) => this.pattern.Datepattern.test(v) || "Enter valid date format as DD/MM/YYYY.",
          (v) => !!this.pastDateCheck(v) || "Future dates not allowed.",
				],
				endDateRules: [
					(v) => !!v || false,
          (v) => this.pattern.Datepattern.test(v) || "Enter valid date format as DD/MM/YYYY.",
				],
				dateOfLoss: [
					(v) => !!v || false, 
          (v) => this.pattern.Datepattern.test(v) || "Enter valid date format as DD/MM/YYYY.",
           (v) => !!this.pastDateCheck(v) || "Future dates not allowed.",
				],
				lossTime: [
					(v) => !!v || true, 
          (v) => !!this.tocheckLossTime(v) || "Future time is not allowed.",
				],
				alphaNumaric: [
					(v) => !!v || false, (v) => this.pattern.splChar.test(v) || this.pattern.splcharMsg,
				],
        insuredName: [
					(v) => !!v || false, (v) => this.pattern.insuredName.test(v) || "Insured Name is not valid.",
				],
        policyNo: [
					(v) => !!v || false, (v) => this.pattern.policynumber.test(v) || "This is not valid.",
				],
			},
		};
	},
	computed: {
		itemsWithSno() {
			return this.polcicySearchTableData.map((item, index) => (
        {...item,
				sNo: index + 1,
        policyExpiryDate :this.ISOtoDDMMYY(item.policyExpiryDate),
        policyStartInceptionDate:this.ISOtoDDMMYY(item.policyStartInceptionDate),
        insuredId:item.insuredId==undefined ? item.txtCustomerId: item.insuredId
        
			})
      
      );
		},
	},
  created(){
        this.policyDeatils.lossTime=this.$route.params.lossTime==undefined? "":this.$route.params.lossTime;
        this.policyDeatils.dateOfLoss=this.$route.params.dateOfLoss==undefined ? "" : this.$route.params.dateOfLoss;
        this.policyDeatils.policyNo=this.$route.params.policyNo==undefined ? "":this.$route.params.policyNo;
        this.dateofLossFormatted=this.$route.params.dateOfLoss==undefined ? "": this.ChangeDateBindCalenderDateOfLoss(this.policyDeatils.dateOfLoss);
        
  },
	methods: {

       //to open calender menu on tab click
       onPSFocus(){
          this.PolicyStartMenu = true;
       },

       onPEFocus(){
         this.PolicyStartMenu = false;
         this.PolicyEndMenu= true;
       },

       LrFocus(){
         this.PolicyEndMenu = false;
       },

       onDLFocus(){
          this.dateofLossMenu = true;
       },

       onLTFocus(){
          this.dateofLossMenu = false;
          this.lossTimeMenu = true;
       },
       
       toDisableTime(){
                if(	this.dateofLossFormatted == moment().format("YYYY-MM-DD")){
                    this.currentTime = moment().format("HH:mm");
                    this.policyDeatils.lossTime ="";
                }else{
                  this.currentTime ="";
                }
                
            },
		tocheckLossTime() {
			if (this.dateofLossFormatted == moment().format("YYYY-MM-DD")) {
				let LT = this.policyDeatils.lossTime.split(":");
				let lossTime = new Date().setHours(LT[0], LT[1], 0, 0);
				let currentime = new Date().getTime();
				if (lossTime > currentime) {
					this.lostTimeCheckValidation = true;
					return false;
				} else {
					this.lostTimeCheckValidation = false;
					return true;
				}
			} else {
				this.currentTime = "";
				this.lostTimeCheckValidation = false;
				return true;
			}
		},
     ISOtoDDMMYY(date) {
      if (date) {
       return moment(date).format("DD/MM/YYYY")
      }else{
        return date;
      }
    },
		pastDateCheck(StartDate) {
			if (StartDate !== "") {
				StartDate = moment(StartDate, "DD/MM/YYYY").toDate().getTime();
				if (new Date(StartDate).setHours(0, 0, 0, 0) <= new Date().setHours(0, 0, 0, 0)) {
					return true;
				}
				return false;
			}
		},
		ChangeDateBindCalenderStart(date) {
			if (this.pattern.Datepattern.test(date)) {
				const [day, month, year] = date.split("/");
				this.policyStartDateFormatted = `${year}-${month}-${day}`;
				this.PolicyStartMenu = false;
				return `${year}-${month}-${day}`;
			} else {
				this.policyStartDateFormatted = moment().format("YYYY-MM-DD");
			}
		},
		ChangeDateBindCalenderEnd(date) {
			if (this.pattern.Datepattern.test(date)) {
				const [day, month, year] = date.split("/");
				this.policyEndDateFormatted = `${year}-${month}-${day}`;
				this.PolicyEndMenu = false;
				return `${year}-${month}-${day}`;
			} else {
				this.policyEndDateFormatted = moment().format("YYYY-MM-DD");
			}
		},
		ChangeDateBindCalenderDateOfLoss(date) {
			if (this.pattern.Datepattern.test(date)) {
				const [day, month, year] = date.split("/");
				this.dateofLossFormatted = `${year}-${month}-${day}`;
				this.dateofLossMenu = false;
				return `${year}-${month}-${day}`;
			} else {
				this.dateofLossFormatted = moment().format("YYYY-MM-DD");
			}
		},
		backTOFnol() {
      this.showConfirm("Confirmation","Do you want to go back to FNOL screen ?","Yes","No",(Yes)=>{
                      
                  },(No)=>{})
		},
    backRetrun(){
      
    },
   
     proceedConfirm(){
    	let StoreSave = {
            policyNo: this.policyDeatils.policyNo,
            insuredName: this.policyDeatils.insuredName,
            insuredId: this.policyDeatils.insuredId,
            certificateNo: this.policyDeatils.certificateNo,
            applicationNo: this.policyDeatils.applicationNo,
            eMarinePolicyNo: this.policyDeatils.eMarinePolicyNo,
            lrNumber: this.policyDeatils.lrNumber,
            invoiceNumber: this.policyDeatils.invoiceNumber,
            lossTime:this.policyDeatils.lossTime,
            dateOfLoss: this.policyDeatils.dateOfLoss ,
            proceedSearch: true
		   	};
      
 },

	   SearchPolicy() {
          let requestData = {
            policyNo: this.policyDeatils.policyNo,
            insuredName: this.policyDeatils.insuredName,
            insuredId: this.policyDeatils.insuredId,
            certificateNo: this.policyDeatils.certificateNo,
            applicationNo: this.policyDeatils.applicationNo,
            eMarinePolicyNo: this.policyDeatils.eMarinePolicyNo,
            lrNumber: this.policyDeatils.lrNumber,
            invoiceNumber: this.policyDeatils.invoiceNumber,
            lossTime: this.policyDeatils.lossTime,
            dateOfLoss: (this.policyDeatils.dateOfLoss == null || this.policyDeatils.dateOfLoss == "") ? "" : this.policyDeatils.dateOfLoss,
            proceedSearch: false
          };
          console.log("policy Search Request--->", JSON.stringify(requestData));
          if (this.policyDeatils.policyNo == "" && this.policyDeatils.certificateNo == "" && this.policyDeatils.eMarinePolicyNo == "") {
            this.dailoboxSubmitText = "Atleast one of these field is mandatory Policy number/E-Marine number/Certificate number.";
            this.showAlert(this.dailoboxSubmitText);
            return true;
          } else if (this.lostTimeCheckValidation == false) {
            this.showProgress("Data is fetching ,please wait");
            this.POST_ByURLM("GET_POLICYSEARCH", requestData, (res) => {
              this.polcicySearchTableData = [];
              this.policySearchmarineData = [];
              if (res.data && res.data.data && res.data.data.data && res.data.data.data.length == 1 && res.data.data.data[0].proposalDtls) {
                let finalResponseSingle = res.data.data.data[0]
               // this.polcicySearchTableData = finalResponseSingle.proposalDtls;
                if(finalResponseSingle.proposalDtls.length !==0){
                    this.polcicySearchTableData.push(finalResponseSingle.proposalDtls[0]);
                }
                if (finalResponseSingle.marineDtls.length !== 0) {
                  //this.policySearchmarineData = finalResponseSingle.marineDtls
                  this.policySearchmarineData.push(finalResponseSingle.marineDtls[0]);
                }
                this.claimSubStatus = finalResponseSingle.claimSubStatus;
                this.claimStatus = finalResponseSingle.claimStatus;
                this.reasonForSuspense = finalResponseSingle.reasonForSuspense;
                this.classNameDialog = "successDailogboxMsg";
                if (this.claimSubStatus == "Cancelled") {
                  this.dailoboxSubmitText = res.data.msg + " and Policy in cancelled status.";
                  this.ConfirmText = "Policy in cancelled status,do you want to proceed ?";
                } else if (this.claimSubStatus == "Expired") {
                  this.dailoboxSubmitText = res.data.msg + " and Date and Time of loss not covered.";
                  this.ConfirmText = "Date and time of loss not covered,do you want to proceed ?";
                } else if (this.claimSubStatus !== "Cancelled" || this.claimSubStatus !== "Expired") {
                  this.dailoboxSubmitText = res.data.msg;
                }
                this.hideProgress();
                this.showAlert(this.dailoboxSubmitText);
              } else if (res.data && res.data.data && res.data.data.data && res.data.data.data.length !== 1) {
                let finalResponse = res.data.data.data;
                for(var i in finalResponse){
                      this.polcicySearchTableData.push(finalResponse[i].proposalDtls[0]);
                      if(finalResponse[i].marineDtls.length == 0){
                           this.policySearchmarineData.push({});
                      }else{
                        this.policySearchmarineData.push(finalResponse[i].marineDtls[0]);
                    }
                 }
                 this.claimSubStatus = "";
                this.hideProgress();
                this.dailoboxSubmitText = res.data.msg;
                this.showAlert(this.dailoboxSubmitText);
              } else {
                this.hideProgress();
                this.dailoboxSubmitText = res.data.msg;
                this.showAlert(this.dailoboxSubmitText);
                this.polcicySearchTableData = [];
                this.policySearchmarineData = [];
                this.policyDeatils.policyNo = "";
                this.claimSubStatus = "";
              }
            }, (error) => {
              this.hideProgress();
              console.log(error);
              if (error.response.data.msg) {
                this.dailoboxSubmitText = error.response.data.msg;
                this.showAlert(this.dailoboxSubmitText);
              }
              this.polcicySearchTableData = [];
              this.policySearchmarineData = [];
              this.policyDeatils.policyNo = "";
              this.claimSubStatus = "";
            });
          }
  	},

		proceedFnol() {
			if ((this.policyDeatils.dateOfLoss == "" || this.policyDeatils.dateOfLoss == null) && (this.policyDeatils.lossTime == "" || this.policyDeatils.lossTime == null)) {
				this.dailoboxSubmitText = "Date of loss and loss time required to procced.";
        this.showAlert(this.dailoboxSubmitText);
				return true;
			} else if (this.policyDeatils.dateOfLoss == "" || this.policyDeatils.dateOfLoss == null) {
				this.dailoboxSubmitText = "Date of loss is required to procced.";
        this.showAlert(this.dailoboxSubmitText);
				return true;
			} else if (this.policyDeatils.lossTime == "" || this.policyDeatils.lossTime == null ) {
				this.dailoboxSubmitText = "Loss time is required to procced.";
         this.showAlert(this.dailoboxSubmitText);
				return true;
			} else {
		       if(this.claimSubStatus=="Cancelled" || this.claimSubStatus=="Expired"){
            this.showConfirm("Confirmation", this.ConfirmText,"Yes","No",(Yes)=>{
                      this.proceedConfirm();
                  },(No)=>{})
            }else{
            this.proceedConfirm();
            }
      }
		},
		viewMoreFun(index) {
			console.log(index);
			let Index = index - 1;
      this.viewMoreData	=this.polcicySearchTableData[Index];
      if(this.policySearchmarineData.length!==0){
         this.viewMoreDataMarin	=this.policySearchmarineData[Index];
      }
			this.viewMore = true;
		},
		formatDate(date) {
			const [year, month, day] = date.split("-");
			return `${day}/${month}/${year}`;
		},
		formatDateEndDate(date) {
			const [year, month, day] = date.split("-");
			return `${day}/${month}/${year}`;
		},
		ConfirmRest() {
      this.showConfirm("Confirmation","Are you sure to Clear all Information?","Yes","No",(Yes)=>{
                     this.resetForm()
                  },(No)=>{})
		},
		resetForm() {
			this.policyDeatils= {
				policyStartDate: "",
				policyEndDate: "",
				dateOfLoss: "",
				lossTime: "",
				policyNo: "",
				insuredName: "",
				insuredId: "",
				certificateNo: "",
				applicationNo: "",
				eMarinePolicyNo: "",
				lrNumber: "",
				invoiceNumber: "",
        businessType:"",
        eMarineCertificateNo:""
			}
      this.polcicySearchTableData = [];
      this.policySearchmarineData = [];
		},
	},
};
</script>

<style scoped>

.flexStyle{
  background-color: #f7f7f7; 
  padding: 20px
}

.cardTitleStyle{
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 7px #00000029;
  opacity: 1;
  font-size: 14px;
}

.tableHeight{
  height: 200px;
}

.fontSizeCls{
  font-size: 19px;
}
.fontClass{
  font-size: 16px;
}
.backgroundTable {
  background-color: #f7f7f7 !important;
}
.rowWidthViewMore {
  text-align: left;
  width: 100%;
  font-size: 13px;
  margin-bottom: 13px;
  margin-top: 18px;
}
.rowWidthViewMore .flex {
  width: 20%;
}

.policyDeatilsSpan {
  width: 91%;
  margin-left: 15px;
  margin-top: 21px;
  margin-bottom: 14px;
  font-weight: bold;
}
.horizontal_line {
  border-bottom: 1px solid #eeeeee;
  height: 14px;
  margin-right: 9px;
}
tr.trowhight {
  height: 30px !important;
}
.tableBackGroundColor {
  background-color: #23b1a9;
}
/* thead tr {
  color: white !important;
  font-size: 13px !important;
  height: 40px !important;
  border: 0.5px ridge #f7f7f7;
  border-width: 1px;
  border-right: none;
  border-collapse: collapse;
  font-weight: 400;
}

td {
  border: 1px solid #f7f7f7;
  border-width: 2px;
  border-right: none;
  border-collapse: collapse;
  height: 40px !important;
  font-size: 13px !important;
  color: #10242b;
} */
.inputwidth {
  max-width: 300px;
  border-radius: 0;
}
.claimlabel {
  color: #646666;
  font-size: 15px;
  font-weight: bold;
}
.btnDiv {
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #f7f7f7;
}
.buttonscolor {
  border-radius: 0 !important;
  height: 30px !important;
  text-transform: capitalize;
  font-size: 15px;
  width: 110px !important;
}
.buttonsWidth {
  border-radius: 0 !important;
  height: 35px !important;
  text-transform: capitalize;
  font-size: 15px;
  width: 100px !important;
}
.iconstyle {
  margin-left: -12px;
  background-color: rgb(247, 247, 247);
  width: 45px;
  height: 38px;
  font-size: 20px !important;
  border: 0.3px solid rgb(232, 235, 236);
  opacity: 1;
  /* box-shadow: rgba(0, 0, 0, 0.318) 0px 2px 2px; */
}
.forminput {
    border-radius: 0;
  }
</style>