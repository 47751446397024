import { USER_MGMNT_SERVICE,COMMON_SURROUND_INTEGRATION } from '@/common/API_Config.js';
export default {

    getDocumentWorkerCode(endpointExtension, authHeaders, apiUrl) {
                let finalApi ='';
                if(endpointExtension == null){
                    finalApi = `${COMMON_SURROUND_INTEGRATION}${apiUrl}`
                }else{
                    finalApi = `${USER_MGMNT_SERVICE}/documents/get-file?${endpointExtension}`
                }
        return `self.addEventListener('message', event => {
                const url = "${finalApi}"
                const headers = {authorization:"${authHeaders.headers.authorization}"}
                headers['Content-Type'] = 'application/json'
                headers['system'] = 'Health'
                headers['x-api-key'] = "${authHeaders.headers['x-api-key']}"
                fetch(url, {headers})
                    .then(response => response.blob())
                    .then(blob => {
                        // send the downloaded data back to the main thread
                        if (blob.type == 'application/json') self.postMessage({ success: false, error: 'Failure' });
                        else self.postMessage({ success: true, data: blob });
                    })
                    .catch(error => {
                        // send the error information back to the main thread
                        self.postMessage({ success: false, error: error.message });
                    });
            });
        `;
    
    }
}
