<template>
  <div>
    <!--  form fields -->
    <v-form ref="Formvalid">
      <v-row class="mt-1">
        <v-col class="inputwidth ml-4">
          <v-flex class="mb-2 claimlabel"
            >Catastrophe ID<span class="mandatorycolor"> *</span></v-flex
          >
          <v-text-field
            label=""
            placeholder="Enter..."
            solo
            class="forminput"
            type="text"
            v-model="CatastropheMasterObj.catastropheId"
            :error-messages="CatastropheIDValidation"
            @blur="$v.CatastropheMasterObj.catastropheId.$touch()"
            @input="$v.CatastropheMasterObj.catastropheId.$touch()"
            dense
          ></v-text-field>
        </v-col>
        <v-col class="inputwidth ml-4">
          <v-flex class="mb-2 claimlabel"
            >Catastrophe ID Same<span class="mandatorycolor"> *</span></v-flex
          >
          <v-text-field
            label=""
            placeholder="Enter..."
            solo
            class="forminput"
            type="text"
            v-model="CatastropheMasterObj.catastropheIdSame"
            :error-messages="CatastropheIDSameValidation"
            @blur="$v.CatastropheMasterObj.catastropheIdSame.$touch()"
            @input="$v.CatastropheMasterObj.catastropheIdSame.$touch()"
            dense
          ></v-text-field>
        </v-col>
        <v-col class="inputwidth ml-4">
          <v-flex class="mb-2 claimlabel"
            >Age<span class="mandatorycolor"> *</span></v-flex
          >
          <v-text-field
            label=""
            placeholder="Enter..."
            solo
            class="forminput"
            type="text"
            v-model="CatastropheMasterObj.age"
            :error-messages="CatastropheAgeValidation"
            @blur="$v.CatastropheMasterObj.age.$touch()"
            @input="$v.CatastropheMasterObj.age.$touch()"
            dense
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="mt-2">
        <v-col class="ml-4 inputwidth unitest">
          <v-flex class="mb-2 claimlabel">
            Start Date<span class="mandatorycolor"> *</span></v-flex
          >

          <v-text-field
            v-model="CatastropheMasterObj.startDate"
            label="dd/mm/yyyy"
            solo
            dense
            :error-messages="StartDateValidation"
            @blur="$v.CatastropheMasterObj.startDate.$touch()"
            @input="$v.CatastropheMasterObj.startDate.$touch()"
          >
          </v-text-field>
        </v-col>
        <v-col class="ml-4 inputwidth unitest">
          <v-flex class="mb-2 claimlabel">
            Number validation <span class="mandatorycolor"> *</span></v-flex
          >

          <v-text-field
            v-model="CatastropheMasterObj.catastropheDescription"
            solo
            dense
            label="enter.."
            :error-messages="numberValidation"
            @blur="$v.CatastropheMasterObj.catastropheDescription.$touch()"
            @input="$v.CatastropheMasterObj.catastropheDescription.$touch()"
          >
          </v-text-field>
        </v-col>
        <v-col class="ml-4 inputwidth unitest">
          <v-flex class="mb-2 claimlabel">
            Email<span class="mandatorycolor"> *</span></v-flex
          >
          <v-text-field
            v-model="CatastropheMasterObj.email"
            label="Enter.."
            type="email"
            solo
            dense
            :error-messages="emailValidation"
            @blur="$v.CatastropheMasterObj.email.$touch()"
            @input="$v.CatastropheMasterObj.email.$touch()"
          >
          </v-text-field>
        </v-col>
        <v-col class="ml-4 inputwidth unitest">
          <v-flex class="mb-2 claimlabel"
            >Special Characters Not Allowed
          </v-flex>
          <v-text-field
            v-model="CatastropheMasterObj.spclchar"
            label="Enter.."
            solo
            dense
            :error-messages="spclCharNotAllowed"
            @input="$v.CatastropheMasterObj.spclchar.$touch()"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <!--  save add cancel clear buttons below-->
      <p>
        Email mandatory------>{{
          JSON.stringify($v.CatastropheMasterObj.email)
        }}
      </p>

      <p>
        Special Characters Not Allowed non mandatory------>{{
          JSON.stringify($v.CatastropheMasterObj.spclchar)
        }}
      </p>

      <v-flex class="ml-10 px-30">
        <v-btn
          color="#152F38"
          dark
          class="buttonscolor mr-5"
          type="submit"
          @click.prevent="SaveValidationVue"
        >
          Submit
        </v-btn>
      </v-flex>
    </v-form>
  </div>
</template>

<script>
import moment from "moment";
// email,numeric, minValue,
import {
  required,
  minLength,
  maxLength,
  numeric,
  between,
  sameAs,
  email,
} from "vuelidate/lib/validators";
export default {
  validations: {
    CatastropheMasterObj: {
      catastropheId: {
        required,
        minLen: minLength(8),
        maxLen: maxLength(10),
      },
      catastropheIdSame: {
        required,
        sameAs: sameAs((vm) => {
          return vm.catastropheId;
        }),
      },
      catastropheDescription: {
        required,
        numeric,
      },
      startDate: {
        required,
      },
      age: {
        required,
        between: between(20, 30),
      },
      email: {
        required,
        email,
      },
      spclchar: {},
    },
  },
  computed: {
    spclCharNotAllowed() {
      const errors = [];
      if (!this.$v.CatastropheMasterObj.spclchar.$dirty) return errors;
      !this.pattern.SpecialChar.test(this.CatastropheMasterObj.spclchar) &&
        errors.push("Special Characters Not Allowed");
      return errors;
    },
    numberValidation() {
      const errors = [];
      if (!this.$v.CatastropheMasterObj.catastropheDescription.$dirty)
        return errors;
      !this.$v.CatastropheMasterObj.catastropheDescription.required &&
        errors.push(this.errorMessage);
      !this.$v.CatastropheMasterObj.catastropheDescription.numeric &&
        errors.push("Only Numbers are Allowed");
      return errors;
    },
    emailValidation() {
      const errors = [];

      if (!this.$v.CatastropheMasterObj.email.$dirty) return errors;
      !this.$v.CatastropheMasterObj.email.required &&
        errors.push(this.errorMessage);
      !this.$v.CatastropheMasterObj.email.email &&
        errors.push("E-mail must be valid");
      return errors;
    },
    CatastropheAgeValidation() {
      const errors = [];
      if (!this.$v.CatastropheMasterObj.age.$dirty) return errors;
      !this.$v.CatastropheMasterObj.age.required &&
        errors.push(this.errorMessage);
      !this.$v.CatastropheMasterObj.age.between &&
        errors.push(
          "Age must be between" +
            this.$v.CatastropheMasterObj.age.$params.between.min +
            "and" +
            this.$v.CatastropheMasterObj.age.$params.between.max
        );
      return errors;
    },
    CatastropheIDValidation() {
      const errors = [];
      if (!this.$v.CatastropheMasterObj.catastropheId.$dirty) return errors;
      !this.$v.CatastropheMasterObj.catastropheId.required &&
        errors.push(this.errorMessage);
      !this.$v.CatastropheMasterObj.catastropheId.minLen &&
        errors.push("minimum length should be 8");
      !this.$v.CatastropheMasterObj.catastropheId.maxLen &&
        errors.push("maxlength length should be 10");

      return errors;
    },
    CatastropheIDSameValidation() {
      const errors = [];
      if (!this.$v.CatastropheMasterObj.catastropheIdSame.$dirty) return errors;
      !this.$v.CatastropheMasterObj.catastropheIdSame.required &&
        errors.push(this.errorMessage);
      !this.$v.CatastropheMasterObj.catastropheIdSame.$invalid == false &&
        errors.push("Catastrophe ID same must be  Same as Catastrophe ID ");
      return errors;
    },
    StartDateValidation() {
      const errors = [];
      if (!this.$v.CatastropheMasterObj.startDate.$dirty) return errors;
      !this.$v.CatastropheMasterObj.startDate.required &&
        errors.push(this.errorMessage);
      !this.pattern.date.test(this.CatastropheMasterObj.startDate) &&
        errors.push("Enter valid date format as DD/MM/YYYY.");
      !this.pastDateCheck(this.CatastropheMasterObj.startDate) &&
        errors.push("Future dates not allowed");
      return errors;
    },
  },
  data() {
    return {
      pattern: {
        date: /(((0|1)[0-9]|2[0-9]|3[0-1])\/(0[1-9]|1[0-2])\/((19|20)\d\d))$/,
        SpecialChar: /^[A-Za-z0-9 ]+$/,
        alphaRegex: /^[a-zA-Z.' ]+$/,
        alphabets: /^[a-zA-z \s]+$/g,
        OnlyNumbers: /^[0-9 ]+$/,
        alphabetsandnumbers: /^[0-9a-zA-z \s]+$/,
        fordescriptions: /^[0-9a-zA-z &;:,!.?= \s]*$/,
        fortitles: /^[0-9a-zA-z!?.&, \s]+$/,
        onlynumbers: /^([0-9]{10})+$/,
        panNumber: /^(([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$)+$/,
        PRNnumber: /^(([a-zA-Z]){3}([0-9]){7}?)+$/,
        aadhar: /^[2-9]{1}[0-9]{3}\\s[0-9]{4}\\s[0-9]{4}$/,
        email: /^([a-zA-Z0-9_.-])+@([a-zA-Z0-9_.-])+\.([a-zA-Z])+([a-zA-Z])/,
        mobile: /^([1-9]){1}([0-9]){9}?$/,
      },
      replace: {
        // value = value.replace(/[^0-9a-zA-z &;:,!.?= \s]/g, '');
        //  value = value.replace(/\D/g, '');
      },
      CatastropheMasterObj: {
        spclchar: "",
        catastropheId: "",
        catastropheDescription: "",
        catastropheType: "Local",
        catastropheDetail: "",
        startDate: "",
        endDate: "",
        _id: "",
        updatedAt: "",
        createdById: "",
        createdBy: "",
        createdAt: "",
        age: "",
        catastropheIdSame: "",
        email: "",
      },
      todayDate: new Date().toISOString().substr(0, 10),
      paginationShowHide: true,
      dialogbox: false,
      descriptionSearch: "",
      dailoboxSubmitText: "",
      mytset: "newd",
      mynewTest: "",
      errorMessage: "This field  is required.",
      currentPage: 1,
      pageStartIndex: 0,
      pageRowCount: 10,
      records: null,
      totalPages: null,
      StartDateMenu: false,
      endDateMenu: false,
      StartDateFormatted: null,
      endDateFormatted: null,
      options: ["Selecte", "test 1", "test2"],
      MastertableHeadOptions: [
        "Sr. No.",
        "Catastrophe ID *",
        "Catastrophe Description *",
        "Catastrophe Detail",
        "Catastrophe Type *",
        "Start Date *",
        "End Date *",
      ],
      catastropheTypeOptions: ["Local", "International"],
      catastropheDetailOptions: [],
      MasterTableData: [],
    };
  },

  methods: {
    SaveValidationVue() {
      // if (!this.$v.$invalid) {
      //   alert("submitt");
      // }
      this.$v.$touch();
      if (!this.$v.$invalid) {
        alert("submitt");
      }
    },

    pastDateCheck(StartDate) {
      if (StartDate !== "") {
        StartDate = moment(StartDate, "DD/MM/YYYY").toDate().getTime();
        if (
          new Date(StartDate).setHours(0, 0, 0, 0) <=
          new Date().setHours(0, 0, 0, 0)
        ) {
          return true;
        }
        return false;
      }
    },
    futureDateCheck(endDate) {
      if (endDate !== "") {
        endDate = moment(endDate, "DD/MM/YYYY").toDate().getTime();
        if (
          new Date(endDate).setHours(0, 0, 2, 0) <=
          new Date().setHours(0, 0, 0, 0)
        ) {
          return false;
        }
        return true;
      }
    },
  },
  mounted() {},
};
</script>

<style scoped>
.dispayInlinePagination {
  display: inline-flex;
  margin-left: 55%;
}

.tableBackGroundColor {
  background-color: #23b1a9;
}

th {
  color: white !important;
  font-size: 12px !important;
  height: 28px !important;
  border: 0.5px ridge #f7f7f7;
  border-width: 1px;
  border-right: none;
  border-collapse: collapse;
}

td {
  border: 1px solid #f7f7f7;
  border-width: 2px;
  border-right: none;
  border-collapse: collapse;
  height: 28px !important;
  font-size: 11px !important;
  color: #10242b;
}

.btnDiv {
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #f7f7f7;
}

.claimlabel {
  color: #646666;
  font-size: 12px;
  font-weight: bold;
}

.inputwidth {
  max-width: 325px;
}

.buttonscolor {
  border-radius: 0 !important;
  height: 24px !important;
  text-transform: capitalize;
  font-size: 11px;
  width: 97px !important;
}

.buttonsWidth {
  border-radius: 0 !important;
  height: 35px !important;
  text-transform: capitalize;
  font-size: 11px;
  width: 90px !important;
}

.searchRowBackground {
  background-color: #f7f7f7;
  padding-top: 3px;
  height: 70px;
}

.backgroundTable {
  background-color: #f7f7f7 !important;
}

.dropdownSlotImgDiv {
  padding-left: 13px;
  border-left: 1px solid #c1c8cc;
  height: 38px;
  background-color: #f7f7f7;
  width: 44px;
  padding-top: 10px;
}

.v-text-field .v-input__control .v-input__slot .myInputTextField {
  padding-right: 0 !important;
}
</style>
