<template>
  <div class="ma-6">
    <v-card elevation="0" width="100%">
      <v-form class="mt-5 ml-2" @submit.prevent="" ref="searchForm">
        <SearchFormComponent @searchFilterData="searchFilterData"/>
        <v-radio-group inline label="Select" v-model="userType" row v-if="isSiuUser">
          <v-radio @click="getTabsDataList" label="Qc1" value="qc1"></v-radio>
          <v-radio @click="getTabsDataList" label="Qc2" value="qc2"></v-radio>
        </v-radio-group>
      </v-form>
      <v-data-table
        class="padding-0 vDataTableStyling"
        :headers="headers"
        :items="healthCaseList"
        :page.sync="page"
        :items-per-page="itemsPerPage"
        :hide-default-footer="true"
      >
        <template v-slot:top>
          <v-layout
            justify-space-between
            align-center
            style="width: 100%"
            class="py-2"
          >
            <div style="display: flex; align-items: center; column-gap: 8px">
              <div class="page-title-small">
                List
                <span class="text__Orange">({{ totalListCount }})</span>
              </div>
            </div>
          </v-layout>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-menu offset-y open-on-click>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                elevation=""
                icon
                v-bind="attrs"
                v-on="on"
                style="text-align: center"
                ><v-icon>mdi mdi-dots-vertical</v-icon></v-btn
              >
            </template>
            <v-list class="item-actions">
              <v-list-item
                v-for="(itemData, index) in dataItems"
                :key="index"
                @click="onclickAuditTrail(item, index)"
                :disabled="
                  itemData.name === 'MODIFY' &&
                  ['Pending for approval'].includes(item.approveStatus)
                "
              >
                <v-list-item-title
                  :style="
                    itemData.name === 'MODIFY' &&
                    ['Pending for approval'].includes(item.approveStatus)
                      ? 'gray'
                      : itemData.color
                  "
                >
                  <v-icon small :style="itemData.iconColor"
                    >mdi-{{ itemData.icon }}</v-icon
                  >&nbsp;{{ itemData.name }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <template v-slot:item.siuInvTaskId="{ item }">
          <span v-if="item.siuInvTaskId">
            {{ item.siuInvTaskId }}
          </span>
        </template>
        <template v-slot:item.claimNumber="{ item }">
          <a @click="goToTabs(item)">{{ item.claimNumber }}</a>
        </template>
        <template v-slot:item.more="{ item }">
          <a @click="handleViewMore(item)">View more</a>
        </template>
        <template v-slot:item.subStatus="{ item }">
          <span class="elipsis">{{ item.subStatus }}</span>
        </template>
      </v-data-table>
      <v-layout
        justify-end
        align-center
        style="flex: 0"
        class="py-2"
      >
        <v-spacer></v-spacer>
        <div>
          <span style="color: orange"
            >Showing {{ pageStartIndex }}-{{ pageEndIndex }}</span
          >
          out of {{ totalListCount }} records
        </div>
        <div>
          <v-pagination
            v-model="page"
            :length="pageCount"
            :total-visible="5"
            @input="getTabsDataList"
            color="#152F38"
          ></v-pagination>
        </div>
      </v-layout>
    </v-card>
    <v-row>
      <v-col class="d-flex align-right pl-1 justify-end">
        <v-btn
          dark
          class="text-capitalize ml-2"
          color="#E46A25"
          depressed
          @click="exportToExcel"
        >
          <v-icon>file_download</v-icon
          ><v-divider class="mx-2 ml-2 white" vertical></v-divider>Export To
          Excel
        </v-btn>
      </v-col>
    </v-row>
    <DialogComponent
      v-if="viewMore"
      :investigationDetail="investigationDetail"
      @viewMoreCustom="handleToggle"
    />
    <AuditTrail
      v-if="showAuditTrail"
      @showHide="showHideAuditTrail"
      :siuWorkItemId="siuWorkItemId"
      
    />
  </div>
</template>
<script>
import moment from "moment";
import { getWoritemList, getWoritemDetailsById, downloadPdf, downloadExcel } from "../../apiservices/Workitem";
import { getTabsData } from "../../apiservices/Investigation";
import DialogComponent from "./DialogComponent.vue";
import AuditTrail from "./AuditTrail.vue";
import SearchFormComponent from "./SearchFormComponent.vue";

export default {
  data() {
    return {
      userType: "qc1",
      siuWorkItemId: "",
      currentRole: "",
      isSiuUser: false,
      siuRoles: ["CG77", "CG78", "CG79", "CG80", "CG81", "CG82"],
      dateFormat: "DD/MM/YYYY",
      investigationDetail: {},
      totalListCount: 0,
      itemsPerPage: 10,
      pageStartIndex: 0,
      page: 1,
      showSearchField: "",
      viewMore: false,
      showAuditTrail: false,
      searchData: {
        lob: "",
        policyNumber: "",
        claimNumber: "",
        featureNumber: "",
        siuZone: "",
        claimType: "",
        referralType: "",
        referredFrom: "",
        referredTo: "",
        reassignedFrom: "",
        referredDateFrom: "",
        referredDateTo: "",
        reassignedDateFrom: "",
        reassignedDateTo: "",
        reopenDateFrom: "",
        reopenDateTo: "",
        siuCaseStatus: "",
        siuCaseSubStatus:""
      },
      dataItems: [
        {
          name: "Audit Trail",
          icon: "pencil",
          link: "/modifypage",
          iconColor: "color:#10242B",
          color: "color:#23b1a9",
        },
        {
          name: "Reassign To Other SIU Manager",
          icon: "chart-timeline-variant",
          link: "/auditTrail",
          color: "color:orange",
          iconColor: "color:orange",
        },
        {
          name: "Download PDF",
          icon: "download",
          link: "",
          color: "color:green",
          iconColor: "color:green",
        },
      ],
      headers: [
        {
          text: "Actions",
          value: "actions",
          width: 50,
          sortable: false,
          align: "center",
        },
        {
          text: "Claim No",
          value: "claimNumber",
          sortable: false,
          width: 100,
          align: "center",
        },
        {
          text: "SIU Task ID",
          value: "investigationTaskID",
          sortable: false,
          align: "center",
          width: 145,
        },
        {
          text: "SIU INV Task ID",
          value: "siuInvTaskId",
          sortable: false,
          width: 108,
          align: "center",
        },
        {
          text: "Claim Type",
          value: "claimType",
          sortable: false,
          align: "center",
          width: "100px",
        },
        {
          text: "Loss City",
          value: "city",
          sortable: false,
          width: "100px",
          align: "center",
        },
        {
          text: "Loss State",
          value: "state",
          sortable: false,
          width: 105,
          align: "center",
        },
        {
          text: "SIU Zone",
          value: "zone",
          sortable: false,
          width: 100,
          align: "center",
        },
        {
          text: "Patient Name",
          sortable: false,
          value: "patientName",
          width: 125,
          align: "center",
        },
        {
          text: "SIU Status",
          sortable: false,
          value: "status",
          width: "100px",
          align: "center",
        },
        {
          text: "SIU Sub Status",
          sortable: false,
          value: "subStatus",
          width: 107,
          align: "center",
        },
        {
          text: "More",
          sortable: false,
          value: "more",
          width: "100px",
          align: "center",
        },
      ],
      healthCaseList: [],
      searchFilterDataMapping: {
        "claimNumber" : "claimNumber",
        "siuCaseStatus" : "status",
        "siuCaseSubStatus" : "subStatus",
        'claimType' : "claimType",
      }
    };
  },
  components: { DialogComponent, SearchFormComponent, AuditTrail },
  methods: {
    computedDate(name) {
      return this.searchData[name]
        ? moment(this.searchData[name]).format(this.dateFormat)
        : "";
    },
    searchFilterData($event){
      this.searchData = $event.searchData,
      this.showSearchField = $event.showSearchField
      if(this.searchData[this.showSearchField]){
        this.getTabsDataList();
      }
    },
    async exportToExcel() {
        try {
          let url = '';
          if(this.isSiuUser) {
            url = 'common-auxiliary/hos-and-acc-details/get-workItemeport';
          } else {
            url = 'common-auxiliary/hos-and-acc-details/get-invreport';
          }
          let res = await downloadExcel(url);
          window.open(res?.data?.data);
        } catch (e) {
          this.showToast(e.response?.data?.msg || e.response?.data  || e.response || e, this.TOST().ERROR);
        }
      },
    goToTabs(link) {
      if (this.isSiuUser) {
        this.$router.push({
          name: "InvestigationCommonModule",
          params: {
            claimNumber: link.claimNumber.slice(0, -1),
            policyNumber: link.policyNumber,
            featureNumber: link.claimNumber.slice(-1),
            assignmentDone: link.invistigatorAssignmentStatus,
            claimId: link.claimId,
            workitemId: link._id,
            investigationWorkitemId: link.siuInvestigationTaskID,
            memberId: link.memberId,
            allData: link,
            userType: this.userType,
          },
        });
      } else {
        this.$router.push({
          name: "InvestigationCommonModule",
          params: {
            claimNumber: link.claimNumber.slice(0, -1),
            policyNumber: link.policyNumber,
            featureNumber: link.claimNumber.slice(-1),
            assignmentDone: link.invistigatorAssignmentStatus,
            claimId: link.claimId,
            workitemId: link._id,
            memberId: link.memberId,
            tabData: link.tabData,
            allData: link,
            userType: this.userType,
          },
        });
      }
    },
    handleToggle(val) {
      this.viewMore = val;
    },
    showHideAuditTrail(val) {
      this.showAuditTrail = val;
    },
    async onclickAuditTrail(item, index){
      if(index == 1) {
        return;
      }
      this.siuWorkItemId = item._id;
      if(index == 2) {
        try {
          let res = await downloadPdf(this.isSiuUser ? item._id : item.tabData.siuWorkItemId);
        this.downloadDoc(res?.data?.data?.base64, item);
      } catch (e) {
        this.showToast(e.response?.data?.msg || e.response?.data  || e.response || e, this.TOST().ERROR);
      }
        return;
      }
      this.showAuditTrail = true;
    },
    downloadDoc(base64, item){
        const element = document.createElement("a")
        element.href ="data:image/pdf;base64," + base64;
        element.download = `Investigation_report_${item.investigationTaskID || item.tabData.investigationTaskID}.pdf`;
        element.click();
    },
    async handleViewMore(item) {
      try {
        let res = await getWoritemDetailsById(item._id);
        this.investigationDetail = res.data.data[0];
        this.viewMore = true;
      } catch (e) {
        this.showToast(e.response?.data?.msg || e.response?.data  || e.response || e, this.TOST().ERROR);
      }
    },
    async getTabsDataList() {
      let newPageNum = this.page
      let userType = this.isSiuUser ? this.userType : "";
      let reqData = "page=" + this.page + "&pageLimit=" + this.itemsPerPage + "&userType=" + userType;
      let searchFilterReqData = ""
      if(this.searchData[this.showSearchField]){
        let filterField = this.searchFilterDataMapping[this.showSearchField]
        searchFilterReqData =  filterField + "=" + String(this.searchData[this.showSearchField]);
      }
      reqData = searchFilterReqData ? reqData + "&" + searchFilterReqData : reqData;
      try {
      if (this.isSiuUser) {
        let res = await getWoritemList(reqData);
        this.healthCaseList = res.data.data.list;
        if(res?.data?.data?.list?.length > 0) {
          this.totalListCount = res.data.data.total
          this.pageStartIndex = (newPageNum - 1) * this.itemsPerPage + 1
        } else {
          this.showAlert("No Data Found.", "", "", () => {
              this.totalListCount = 0
              this.pageStartIndex = 0
            })
        }
      } else {
        this.itemsPerPage = 100;
        let res = await getTabsData(searchFilterReqData);
        let arr = [];
        res.data.data && res.data.data.forEach((el, i) => {
          if (el.claimDetails[0]) {
            arr.push(el.claimDetails[0]);
          } else {
            arr.push({});
          }
          arr[i].tabData = el;
        });
        this.healthCaseList = arr;
        arr.map((el, i) => {if(!el.claimNumber){return arr.splice(i,1)}});
      }
    } catch (e) {
      this.showToast(e.response?.data?.msg || e.response?.data  || e.response || e, this.TOST().ERROR);
    }
    }
  },
  computed: {
    pageCount() {
      if (!this.totalListCount) {
        return 0
      } else {
        return Math.ceil(this.totalListCount / this.itemsPerPage)
      }
    },
    pageEndIndex() {
      if (!this.healthCaseList.length) {
        return 0
      } else {
        return this.pageStartIndex + this.itemsPerPage <= this.totalListCount
          ? this.pageStartIndex + this.itemsPerPage - 1
          : this.totalListCount
      }
    },
  },
  async created() {
    this.currentRole = this.fetchRoleValue();
    this.isSiuUser = this.siuRoles.some(
      (r) => this.currentRole.indexOf(r) >= 0
    );
    this.$store.commit("SAVE_PAGE_HEADER", "My Workitem");
    this.getTabsDataList();
  },
};
</script>
<style>
.align-down {
  align-self: end;
}
.custom-margin {
  margin-bottom: 27px;
}
.checkbox-align {
  margin-bottom: 0.5rem;
}
.cursor {
  cursor: pointer;
}
.elipsis{
  white-space: nowrap;
}
</style>
