<template>
    <v-flex class="mt-3">
        <v-form ref="repairerapprovalSearch" @submit.prevent="getPendingApprovalData(1)">
            <v-layout>
                <v-flex xs3>
                    <v-layout column class="ml-3">
                        <p class="mb-2 claimlabel">LOB</p>
            <v-select
                v-model="searchRObj.lob"
                label="Select"
                :items="lobOptions"
                solo
                dense
                class="forminput"
            ></v-select>
                        <p class="mb-2 claimlabel">Repairer ID</p>
                        <v-autocomplete
                            :search-input.sync="syncRepairerId"
                            placeholder="Start typing to Search"
                            :items="repairerIDList"
                            class="forminput"
                            :maxlength="6"
                            solo
                            dense
                            v-model="searchRObj.repairerId"
                            @keyup="getRepairerId(syncRepairerId)"
                            deletable-chips
                            hide-no-data
                            clearable>
                        </v-autocomplete>
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class="ml-3">
                        <p class="mb-2 claimlabel">Repairer Name</p>
                        <v-autocomplete
                            :search-input.sync="syncRepairerName"
                            placeholder="Start typing to Search"
                            :items="repairerNameList"
                            class="forminput"
                            :maxlength="6"
                            solo
                            dense
                            v-model="searchRObj.repairerName"
                            @keyup="getRepairerName(syncRepairerName)"
                            deletable-chips
                            hide-no-data
                            clearable>
                        </v-autocomplete>
                    </v-layout>
                </v-flex>                

                <v-flex xs3>
                    <v-layout column class="ml-3">
                        <p class="mb-2 claimlabel">Postal Code</p>
                        <v-autocomplete
                            :search-input.sync="searchPostalCode"
                            placeholder="Start typing to Search"
                            :items="pincodesList"
                            item-text="locpincode"
                            item-value="locpincode"
                            class="forminput"
                            :maxlength="6"
                            solo
                            dense
                            v-model="searchRObj.postalCode"
                            @keyup="getPincode(searchPostalCode)"
                            deletable-chips
                            hide-no-data
                            clearable>
                        </v-autocomplete>
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class="ml-3">
                        <p class="mb-2 claimlabel">State/Province</p>
                        <v-autocomplete
                            :search-input.sync="searchState"
                            placeholder="Start typing to Search"
                            :items="statesList"
                            item-text="stateCodeName"
                            item-value="stateCodeName"
                            class="forminput"
                            :maxlength="6"
                            solo
                            dense
                            v-model="searchRObj.state"
                            deletable-chips
                            hide-no-data
                            clearable
                            @keyup="getState(searchState)"
                            >
                        </v-autocomplete>
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class="ml-3 mr-3">
                        <p class="mb-2 claimlabel">Status</p>
                        <v-select
                            placeholder="Select"
                            solo
                            item-text="name"
                            item-value="value"
                            dense
                            :items="statusOptions"
                            v-model="searchRObj.status"
                            class="forminput"
                        ></v-select>
                    </v-layout>
                </v-flex>
            </v-layout>

            <v-layout fluid class="mb-1 flexbgclr" justify-end>
                <v-flex align-self-center class="ml-3">
                    <v-btn color="#E46A25" dark class="mr-2  buttonscolor" type="submit">
                        <v-icon class="iconsize mr-2">mdi-magnify</v-icon>
                        <div class="vertical_line"></div>Search
                    </v-btn>

                    <v-btn color="#E46A25" dark class="mr-2  buttonscolor" @click="reset">
                        <v-icon class="iconsize mr-2">mdi-close-circle</v-icon>
                        <div class="vertical_line"></div>Reset
                    </v-btn>
                    
                </v-flex>
                <v-btn color="#10242B" dark class="mr-2 mt-2 buttonscolor" @click="back()">
                    <v-icon class="iconsize mr-2">mdi-arrow-left-circle</v-icon>
                    <div class="vertical_line"></div>Back
                </v-btn>
            </v-layout>
        </v-form>

        <v-layout class="flexbgclr">
            <v-flex class="ml-3 mt-3 searchtxt">
                Search Results
            </v-flex>
            <v-col align="right" class="pt-2"> 
               <v-btn :class="selectedRow ? 'auditbtn' :'nocolor'" class="buttonscolor mr-2" @click="aduitTrail" :disabled='!selectedRow'>
                    <v-icon class="mr-2 iconsize">mdi-file-document</v-icon>
                    <div class="vertical_line"></div>Audit Trail
                </v-btn>
                <v-btn :color="selectedRow ? 'auditbtn' :''" class="buttonscolor mr-1" @click="viewData" :disabled='!selectedRow'>
                    <v-icon class="mr-2 iconsize">mdi-eye</v-icon>
                    <div class="vertical_line"></div>View
                </v-btn>
            </v-col>
        </v-layout>  

        <template>
            <v-data-table
                :headers="repairerSearch"
                :items="repairerSearchDetails"
                hide-default-footer
                no-data-text="No Records for Repairer Master"
                class="vDataTableStyling tableborder">
                <template v-slot:[`item.select`]="{ item }">
                    <v-radio-group v-model="selectedRow">
                        <v-radio
                            color="#23B1A9"
                            :value="item._id"
                            class="workitemcheck"
                        ></v-radio>
                    </v-radio-group>
                </template>
                <template v-slot:[`item.operationData[0].repairerId`] = "{ item }">
                    <v-btn color="#FFFFFF" class="tablebtn" :disabled='item.operationData[0].isChildRepairer' 
                        @click="viewData(item._id)">
                        {{ limitTextLength(item.operationData[0].isChildRepairer ? item.operationData[0].parentRepairerId : item.operationData[0].repairerId) }}
                    <v-icon class="tableicons iconsize">mdi-arrow-right-circle</v-icon>
                    </v-btn>
                </template>
                <template v-slot:[`item.operationData[0].childRepairerId`] = "{item}" >
                    <v-btn color="#FFFFFF" class="tablebtn" v-if="item.operationData[0].isChildRepairer" 
                        @click="viewData(item._id)">
                        {{  limitTextLength(item.operationData[0].repairerId ? item.operationData[0].repairerId : '--------') }} 
                    <v-icon class="tableicons iconsize">mdi-arrow-right-circle</v-icon>
                    </v-btn>
                    <div v-else>--------</div>
                </template>

                <template v-slot:[`item.operationData[0].district`]="{ item }">
                    <span>{{limitTextLength(item.operationData[0].district ? item.operationData[0].district : '---------') }}</span>
                </template>
                <template v-slot:[`item.operationData[0].repairerStatus`]="{ item }">
                    <span :class="getStatusStyle(item)">{{item.operationData[0].repairerStatus ? 'Active' : 'Inactive'}}</span>
                </template>
                <template v-slot:[`item.approveStatus`]="{ item }">
                    <span :class="approveStatusColor(item)" class="font-weight-bold">{{item.approveStatus ? item.approveStatus : '---------' }}</span>
                </template>
                
            </v-data-table>
        </template>

        <div class="text-center graybackground pdct-stUp-bnkAcc-pgn mt-3" v-if="this.repairerSearchDetails.length !== 0">
            <v-row>
                <v-col cols="6"></v-col>
                <v-col cols="6">
                    <v-row class="paginationrow">
                    <v-spacer></v-spacer>
                    <div  class="mt-4">
                        <span class="orangetext"> Showing  {{ pageStartIndex }} - {{ pageEndIndex }}</span>
                        <span> out of {{ totalListCount }} records </span>
                    </div>
                    <v-pagination
                        class="mr-4 mt-1 navpagenumber"
                        v-model="page"
                        :length="getNoOfPages"
                        @input="getPendingApprovalData"
                        :total-visible="5"
                        color="#152F38"
                    ></v-pagination>
                        
                    </v-row>
                </v-col>
            </v-row>
            <br />
        </div>

        <!-- Audit trail Dialog -->
        <v-row justify="center">
            <v-dialog v-model="auditDialog" persistent max-width="78%">
                <v-card>
                    <v-card-title class="pl-0 px-0 py-0 addressPopUpHeaderStyle">
                    <v-row>
                        <span class="descrptionSpan mb-0 ml-8 mr-8">Repairer Audit Trail</span>
                        <v-icon @click="auditDialog = false" class="ml-10 mt-0">mdi-window-close</v-icon>
                    </v-row>
                    <v-card-text class="vcardTextTableBackground">
                        <v-flex class="backgroundWhite">
                            <v-simple-table fixed-header class="vDataTableStyling mx-3 mt-3 doctable mb-3">
                                <template>
                                    <thead>
                                        <tr>
                                            <th class="text-center" width="8%">Sr. No.</th>
                                            <th class="text-center" width="15%">Repairer ID</th>
                                            <th class="text-center" width="14%">Repairer Name</th>
                                            <th class="text-center" width="14%">Action Taken</th>
                                            <th class="text-center" width="14%">User ID</th>
                                            <th class="text-center" width="15%">User Name</th>
                                            <th class="text-center" width="17%">Time Stamp</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, Aindex) in aduitsData" :key="'AU'+ Aindex"> 
                                            <td>{{ (auditpageRowCount * (auditpage - 1)) + Aindex + 1}}</td>
                                            <td>{{ item.masterId ==  "" ? "-------" : item.masterId}}</td>
                                            <td>{{ item.masterName ==  "" ? "-------" : item.masterName}}</td>
                                            <td v-if="item.action == 'Modified'">
                                                <v-btn color="#FFFFFF" class="tablebtn" @click="openDetailedAuditTrail(item.modification)">
                                                    {{item.action ==  "" ? "-------" : item.action}}
                                                    <v-icon class="tableicons">mdi-arrow-right-circle</v-icon>
                                                </v-btn>
                                            </td>
                                            <td v-else>
                                                {{item.action ==  "" ? "-------" : item.action}}
                                            </td>
                                            <td>{{item.createdById ==  "" ? "-------" : item.createdById}}</td>
                                            <td>{{item.createdBy ==  "" ? "-------" : item.createdBy}}</td>
                                            <td>{{item.createdAt == "" ? "-------" : dateFormat(item.createdAt)}}</td>
                                        </tr>                                   
                                        <tr class="v-data-table__empty-wrapper" v-if="aduitsData.length === 0">
                                            <td colspan="7">No Records for Audit Trail</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>  
                            <div class="text-center graybackground pdct-stUp-bnkAcc-pgn mt-3 mx-3 mb-2" v-if="this.aduitsData.length !== 0">
                                <v-row>
                                    <v-col cols="6"></v-col>
                                    <v-col cols="6">
                                        <v-row class="paginationrow">
                                        <v-spacer></v-spacer>
                                        <div  class="mt-3">
                                            <span class="orangetext"> Showing {{ auditpageStartIndex }} - {{ auditpageEndIndex }}</span>
                                            <span> out of {{ audittotalCount }} records </span>
                                        </div>
                                        <v-pagination
                                            class="mr-4 mt-1 navpagenumber"
                                            v-model="auditpage"
                                            :length="getauditNoOfPages"
                                            @input="getAuditsData"
                                            :total-visible="5"
                                            color="#152F38"
                                        ></v-pagination>
                                        </v-row>
                                    </v-col>
                                </v-row>
                                <br />
                            </div>  
                        </v-flex>
                    </v-card-text>
                    </v-card-title>
                </v-card>
            </v-dialog>
        </v-row>

        <!-- detailed audit  -->
        <v-row justify="center">
            <v-dialog v-model="detailedAuditTrailOpen" persistent max-width="75%">
                <v-card>
                    <v-card-title class="pl-0 px-0 py-0 addressPopUpHeaderStyle">
                    <v-row>
                        <span class="descrptionSpan mb-5 ml-8 mr-8">Modified Fields Details</span>
                        <v-icon @click="detailedAuditTrailOpen = false" class="ml-10 mt-0">mdi-window-close</v-icon>
                    </v-row>
                    <v-card-text class="addressPopUpStyle">
                        <v-flex class="backgroundWhite">
                            <v-simple-table fixed-header class="vDataTableStyling mx-3 mt-2 doctable mb-0">
                                <template>
                                    <thead>
                                        <tr>
                                            <th class="text-center" width="8%">Sr. No.</th>
                                            <th class="text-center" width="14%">Field Name</th>
                                            <th class="text-center" width="14%">Old Value</th>
                                            <th class="text-center" width="18%">New Value</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, Rindex) in detailedAuditTrailData" :key="'CR'+ Rindex">
                                            <td>{{ Rindex + 1 }}</td>
                                            <td>{{ item.fieldName ==  "" ? "-------" : item.fieldName}}</td>
                                            <td>{{item.oldValue ==  "" ? "-------" : item.oldValue}}</td>
                                            <td>{{item.newValue == "" ? "-------" : item.newValue}}</td>
                                        </tr>
                                        <tr class="v-data-table__empty-wrapper" v-if="detailedAuditTrailData.length === 0">
                                            <td colspan="12">No Records Available</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>    
                        </v-flex>
                    </v-card-text>
                    </v-card-title>
                </v-card>
            </v-dialog>
        </v-row>
    <!-- Audit Trail -->
    <audit-trail
      :dialogState="OpenAuditDialog"
      :auditTrailMaster="'repairer'"
      :auditTrailItem="{ uid: auditTrailId }"
      @close-dialog="OpenAuditDialog = false"
    />
    </v-flex>   
</template>

<script>
import moment from 'moment';
import auditTrail from '../../../../component/MasterAuditTrail.vue'

    export default {
  components:{auditTrail},
        data(){
            return{
      lobOptions:[],
                searchRObj:{
                    lob:"",
                    repairerId:'',
                    repairerName:'',
                    postalCode:'',
                    state:'',
                    status:null
                },
                statusOptions: [{name:"Active", value:true}, {name:"Inactive", value:false}],

                // table related
                page: 1,
                pageStartIndex: 1,
                pageRowCount: 10,
                totalListCount: 0,

                // audits table
                auditpage: 1,
                auditpageStartIndex: 1,
                auditpageRowCount: 10,
                audittotalCount: 0,

                syncRepairerId:'',
                repairerIDList:[],
                syncRepairerName:'',
                repairerNameList:[],
                searchPostalCode:'',
                pincodesList:[],
                
                

                selectedRow:'',
                pitems: [],

                searchState:'',
                statesList:[],

                repairerSearch:[
                    {text: "Select",align: "center",value: "select",sortable: false},
                    {text: "Master ID",align: "center",value: "operationData[0].uid",sortable: false, width: '150'},
                    {text: "Parent Repairer ID",align: "center",value: "operationData[0].repairerId",sortable: false},
                    {text: "Child Repairer ID",align: "center",value: "operationData[0].childRepairerId",sortable: false},
                    {text:"LOB",value:"operationData[0].lob", sortable: false, align: "center", width: "100px"},
                    {text: "Repairer Name",align: "center",value: "operationData[0].repairerName",sortable: false},
                    {text: "Phone Number",align: "center",value: "operationData[0].phoneNumber",sortable: false},
                    {text: "Pincode",align: "center",value: "operationData[0].postalCode",sortable: false},
                    {text: "District",align: "center",value: "operationData[0].district",sortable: false},
                    {text: "City",align: "center",value: "operationData[0].city",sortable: false},
                    {text: "State",align: "center",value: "operationData[0].state",sortable: false},
                    {text: "Status",align: "center",value: "operationData[0].repairerStatus",sortable: false},
                    {text: "Approval Status",align: "center",value: "approveStatus",sortable: false},
                    {text:"Initiated By",value:"createdBy", sortable: false, align: "center",},
                    {text:"Date and Time",value:"createdAt", sortable: false, align: "center",}
                ],
                repairerSearchDetails:[],
                auditDialog:false,
                aduitsData:[],

                repairerID:'',

                detailedAuditTrailOpen: false,
                detailedAuditTrailData: [],
      OpenAuditDialog: false,
      auditTrailId: null,
            }
        },

        created(){
            if (Object.keys(this.$route.params).length !== 0) {              
                this.getPendingApprovalData(this.$route.params.pageno)
            }else{
                this.getPendingApprovalData(1)
            }
        this.getLOB()

        },

        computed:{
            getNoOfPages(){
                if(!this.totalListCount){
                    return 0;
                }else{
                    return Math.ceil(this.totalListCount / this.pageRowCount);
                }
            },           

            pageEndIndex(){
                if(!this.totalListCount){
                    return 0
                }else{
                    return ((this.pageStartIndex + (this.pageRowCount)) <= this.totalListCount) ? this.pageStartIndex + (this.pageRowCount) - 1 : this.totalListCount;
                }
            },

            getauditNoOfPages(){
                if(!this.audittotalCount){
                    return 0;
                }else{
                    return Math.ceil(this.audittotalCount / this.auditpageRowCount);
                }
            },           

            auditpageEndIndex(){
                if(!this.audittotalCount){
                    return 0
                }else{
                    return ((this.auditpageStartIndex + (this.auditpageRowCount)) <= this.audittotalCount) ? this.auditpageStartIndex + (this.auditpageRowCount) - 1 : this.audittotalCount;
                }
            },

            pincodes(){
                return this.pitems
            }
        },

        methods:{
            getLOB() {
        let reqData = '';
        this.showProgress();
        this.GETM("getLOB", reqData, (res) => {
            this.hideProgress();
            if(res.data.statusCode === 200) {
                this.lobOptions = res.data.data[0].lineOfBusiness;
            } else {
                this.lobOptions = [];
                this.showAlert(res.data.msg);
            }
        }, (error) => {
            console.log(error);
            this.hideProgress();
        }, true)
    },


            formatDateTime(dateTime) {
                    return dateTime ? moment(dateTime).format("DD-MM-YYYY LT") : ""
            },

            getRepairerId(id){
                if(id.length > 2){
                let list =this.repairerSearchDetails.map((item) => item.operationData.map((value) => value.repairerId))
                    this.repairerIDList = list.flat(1)
                }else{
                    this.repairerIDList = []
                }   
            },

            getRepairerName(name){
                if(name.length > 2){
                let list =this.repairerSearchDetails.map((item) => item.operationData.map((value) => value.repairerName))
                    this.repairerNameList = list.flat(1)
                }else{
                    this.repairerNameList = []
                }
            },

            getPincode(code){
                if(code.length > 2){
                    let list =this.repairerSearchDetails.map((item) => item.operationData.map((value) => value.postalCode))
                    this.pincodesList = list.flat(1)
                }else{
                    this.pincodesList = []
                }
            },

            getState(state){
                if(state.length > 2){
                    let list = this.repairerSearchDetails.map((item) => item.operationData.map((value) => value.state))
                    this.statesList = list.flat(1)
                }else{
                    this.statesList = []
                }
            },

            back(){
                this.showConfirm("Confirmation","Are you sure to return back ?","Yes","No",(Yes) => {
                    this.$router.push("/master-approval");
                },(No) => {});
            },

            approveStatusColor(item) {
                //return item.approveStatus !== "Approved" ? "red" : "green"; 
                //console.log(item)
                if(item.approveStatus == "Approved"){
                    return "green"
                }else if(item.approveStatus == "Rejected"){
                    return "red"
                }else{
                    return "orange"
                }                      
            },

            allowAlphaSpace(e) {
                var code = ('charCode' in e) ? e.charCode : e.keyCode;
                if (!(code == 32) && // space
                    !(code > 64 && code < 91) && // upper alpha (A-Z)
                    !(code > 96 && code < 123)) { // lower alpha (a-z)
                    e.preventDefault();
                }
            },

            onlyAlphaNumeric(e){
                var code = ('charCode' in e) ? e.charCode : e.keyCode;
                if (
                    !(code > 47 && code < 58) && // numeric (0-9)
                    !(code > 64 && code < 91) && // upper alpha (A-Z)
                    !(code > 96 && code < 123)) { // lower alpha (a-z)
                    e.preventDefault();
                }
            },

            numbersOnly(evt) {
                evt = (evt) ? evt : window.event;
                var charCode = (evt.which) ? evt.which : evt.keyCode;
                if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                    evt.preventDefault();
                } else {
                    return true;
                }
            },

            reset(){
                this.showConfirm("Confirmation","Are you sure you want to clear?","Yes","No",(Yes)=>{
                   this.searchRObj = {
                        repairerId : '',
                        repairerName : '',
                        postalCode : '',
                        state : '',
                        status : null
                    }
                    this.repairerSearchDetails =[]
                },(No)=>{})
            },

            aduitTrail(){
                console.log("this.repairerSearchDetails",this.repairerSearchDetails)
                let item =  this.selectedRow? this.repairerSearchDetails.find((data) => data._id === this.selectedRow): ""
                this.OpenAuditDialog = true
                console.log("item",item)
                this.auditTrailId = item.operationData[0].uid
                // this.auditDialog = true
                // this.repairerID = item.operationData[0].repairerId
                // this.getAuditsData(1)
            },

            getAuditsData(pageno){
                this.auditpage = pageno ? pageno : 1
                let request = 'masterId='+ this.repairerID + "&masterType=repairer&perPage=" + this.auditpageRowCount +"&pageNo=" + this.auditpage
                this.GET("getAuditTrailData", request, (res) => {
                    if (res.data.statusCode === 200 && res.data.data.data && res.data.data.data.length !== 0) {
                        this.aduitsData = res.data.data.data
                        this.audittotalCount = res.data.data.total
                        console.log('-----',this.auditpage)
                        this.auditpageStartIndex = this.auditpage == 1 ? 1 : (this.auditpage - 1) * this.auditpageRowCount +1;
                    }
                },(error) => {
                    this.hideProgress();
                    if (error.response.data.msg) {
                        this.showAlert(error.response.data.msg);
                    } else if (error.response.data.message.msg) {
                        this.showAlert(error.response.data.message.msg);
                    } else if (error.response.data.message) {
                        this.showAlert(error.response.data.message);
                    } else {
                        this.showAlert("Something went wrong");
                    }
                });
            },

            limitTextLength(name) {
                // console.log('---', name)
                return name && name.length > 10 ? name.substring(0, 9).concat('...') : name
            },

            viewData(value){
                console.log('----',value)
                let item = this.selectedRow? this.repairerSearchDetails.find((data) => data._id === this.selectedRow) : value ? this.repairerSearchDetails.find((data) => data._id === value): "";
                if(item.operationData[0].isChildRepairer){
                    this.$router.push({
                        name: "ChildWithParentRepairer",
                        params: {
                            masterapprovalData:item,
                            view: true,
                            masterApproval:true,
                            pageno:this.page
                        }
                    })
                }else{
                    this.$router.push({
                        name: "ParentWithChildRepairer",
                        params: {
                            masterapprovalData:item,
                            view: true,
                            masterApproval:true,
                            pageno:this.page
                        }
                    })
                }
            },


            getPendingApprovalData(pageno){
                this.selectedRow = ''
                this.page = pageno ? pageno : 1;
                let requestData = "perPage=" + this.pageRowCount + "&pageNo=" + this.page ;
                if (this.searchRObj.lob) {
                    requestData = requestData + "&lob=" + this.searchRObj.lob;
                }
                if (this.searchRObj.repairerId) {
                    requestData = requestData + "&repairerId=" + this.searchRObj.repairerId;
                }
                if (this.searchRObj.repairerName) {
                    requestData = requestData + "&repairerName=" + this.searchRObj.repairerName;
                }
                if (this.searchRObj.postalCode) {
                    requestData = requestData + "&postalCode=" + this.searchRObj.postalCode;
                }
                if (this.searchRObj.state) {
                    requestData = requestData + "&state=" + this.searchRObj.state;
                }
                if (this.searchRObj.status == true || this.searchRObj.status == false) {
                    requestData = requestData + "&repairerStatus=" + this.searchRObj.status ;
                }
                requestData = requestData + '&modelName=repairer'
                requestData = requestData + '&approveStatus=Pending for approval'
                this.showProgress('Data fetching, Please wait')
                this.GETM("searchFrequentApprove", requestData, (res) => {
                    if(res.data.statusCode == 200 && res.data.data && res.data.data.data &&  res.data.data.data.length !== 0){
                        this.repairerSearchDetails = res.data.data.data.map((item) => ({
                                                    ...item,
                                                createdAt: this.formatDateTime(item.createdAt),
                                                }));
                        
                        this.totalListCount = res.data.data.total
                        this.pageStartIndex = this.page == 1 ? 1 : (this.page - 1) * this.pageRowCount +1;

                    }else{
                        this.repairerSearchDetails = []
                        // this.showAlert('No Data Available')                    
                    }
                    this.hideProgress();

                },(error) => {
                    this.hideProgress();
                    if(error.response.data.msg){
                        this.showAlert(error.response.data.msg);
                    }else if(error.response.data.message.msg){
                        this.showAlert(error.response.data.message.msg);
                    }else if(error.response.data.message){
                        this.showAlert(error.response.data.message);
                    }else{
                        this.showAlert("Something went wrong");
                    }
                },true)
            },

            callPincodeAPI(indata) {
                if (indata !== "") {
                    if(isNaN(indata)){
                        this.reqData = "pageNo=1&perPage=100&smartSearch="+indata
                    }else{
                        this.reqData = "pageNo=1&perPage=100&pinCode="+indata
                    }
                    this.GET("getPincodes", this.reqData, (res) => {
                        // console.log("response of pincode api is  -> ", res.data.data.data);
                        var pin_not_found = false;
                        if (res.data.data.length == 0) {
                            pin_not_found = true;
                            this.message = "Pin code not found";
                        } else {
                            var processed_pin = res.data.data.data.map((e) => {
                                if (e.cityDistrictName && e.cityDistrictName !== "") {
                                    // return {locpincode: e.pinCode,pincodeArry: e,};
                                    return { locpincode:e.pinCode + " - " + e.pinCodeLocality, pincodeArry:e}
                                } else {
                                    return {locpincode: e.pinCode,all: e,};
                                }
                            });
                            this.pitems.push(...processed_pin);
                        }
                        if (pin_not_found) {
                            this.pitems.push();
                        }
                    },(error) => {
                        return error;
                    });
                }
            },
            
            getStatusStyle(item) {
                return !item.operationData[0].repairerStatus ? 'inactive' : 'active'
            },

            // for detailed audit trail

            openDetailedAuditTrail(data) {
                this.detailedAuditTrailOpen = true;
                this.loadNextDetailedPage(data);
            },

            loadNextDetailedPage(data) {
                if (data.length > 0) {
                    this.detailedAuditTrailData = data;
                }else{
                    this.detailedAuditTrailData = [];
                }
            },
        },

        watch:{
            // searchPostalCode(val){
            //     if(val == null){
            //         return
            //     }
            //     if(val.length >=2){
            //         if(val.length > 6){
            //             val = val.slice(0,6);
            //         }
            //         var vlen = val.length
            //         if(this.pincodes.length == 0 && val.length > 2){
            //             this.callPincodeAPI(val);
            //             return
            //         }
            //             for(let i=0; i<this.pincodes.length;i++){
            //                 if(vlen > 3){
            //                     vlen = 3
            //                     var strlen = this.pincodes[i].locpincode.slice(0,vlen)
            //                 }else{
            //                     var strlen = this.pincodes[i].locpincode.slice(0,vlen)
            //                 } 
            //                 if(vlen > strlen.length){
            //                     return
            //                 }
            //                 if(strlen != val){
            //                     this.callPincodeAPI(val)
            //                     return
            //                 }else{
            //                     return
            //                 }
            //             }
            //     }else{
            //         return
            //     }
            // },

            // searchState(val){
            //     if(val!==null){
            //         if(val.length > 2){
            //             var requestData = "stateName="+val;
            //             this.GET("getStates", requestData, (res) => {
            //                 this.States = res.data.data.data
            //             },(error)=>{
            //                 return error;
            //             }); 
            //         }else{
            //             return;
            //         }
            //     }else{
            //         return;
            //     }
            // },
        }
    }
</script>

<style scoped>
    .red {
        color: red;
        font-weight: bold;
        background: none !important;
    }
    .orange{
        color: #E46A25;
        font-weight: bold;
        background: none !important;
    }
    .green{
        color: #23b1a9;
        background: none !important;
        font-weight: bold;
    }
    .vcardTextTableBackground {
        background-color: #f7f7f7 !important;
        box-shadow: inset 0px 3px 4px 0px #00000047;
    }

    .backgroundWhite {
        background: white !important;
        border: 0.5px solid #e4e0e0 !important;
    }

    .descrptionSpan {
        width: 85%;
        margin-left: 53px;
        margin-top: 21px;
        margin-bottom: 14px !important;
        font-weight: bold;
        font-size: 16px;
    }

    .auditbtn{
        background-color: #23B1A9 !important;
        color:#fff;
    }

    .nocolor{
        background-color: grey;
    }

    .tableicons {
        color: #23b1a9 !important;
    }

    .orangetext {
        color: #e46a25;
        font-size: 14px;
    }

    .graybackground {
        background-color: #e7e7e7;
        height: 45px;
        font-size:12px
    }

    .tablebtn {
        background: #ffffff 0% 0% no-repeat padding-box !important;
        box-shadow: inset 0px 2px 1px #00000052 !important;
        border: 0.5px solid #c1c8cc;
        border-radius: 18px !important;
        height: 30px !important;
        width: 110%;
        text-transform: capitalize !important;
        overflow: hidden;
        font-size: 12px;
        letter-spacing: 0;
        justify-content: space-between;
    }
    
    .workitemcheck{
        margin-top: 0px;
        height: 0px;
    }

    .searchtxt{
        font-weight: bold;
        font-size: 14px;
    }

    .iconsize {
        font-size: 18px !important;
        overflow: hidden;
    }

    .flexbgclr {
        background-color: #e8e8e8 !important;
        border: 0.5px solid #dcdcdc;
        height: 43px;
    }

    .inactive {
      color: #E46A25;
      background: none !important;
      font-weight: bold;
    }
    .active {
      color: #1E9690;
      background: none !important;
      font-weight: bold;
    }
</style>