import { USER_MGMNT_SERVICE } from "@/common/API_Config.js";
import worker from '@/common/worker'

let viewDocumentDataTypes = ["png", "pdf", "jpeg", "jpg"]
let blobDocument = {
    downloaded: "File has been downloaded",
    opened: "File has been opened",
    blobResolutionType: 'application/pdf'
}
var viewDownloadDocument = (documentType, ext, viewFlag, event) => {

  if (event.data.success) {
      const blob = event.data.data;
      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = `${documentType}.${ext}`;
      downloadLink.target = "_blank";
      if (viewFlag && viewDocumentDataTypes.includes(ext.toLowerCase())) {
          window.open(downloadLink)
          return blobDocument.opened
      }
      else {
          window.open()
          downloadLink.click();
          return blobDocument.downloaded
      }
  } else {
      const error = event.data.error;
      return (error);
  }
}
export default {
  data() {
    return {
      viewDataTypes: ["png", "pdf", "jpeg", "jpg"],
    };
  },
  methods: {
    createWorker(
      documentType,
      ext,
      endpointExtension,
      authHeaders,
      viewFlag,
      documentName
    ) {
      const workerCode = `
            
                self.addEventListener('message', event => {
                    const url = '${USER_MGMNT_SERVICE}documents/get-file?${endpointExtension}'
                    const headers = {authorization:"${authHeaders.headers.Authorization}"}
                    headers['Content-Type'] = 'application/json'
                    headers['system'] = 'Health'
                    fetch(url, {headers})
                        .then(response => response.blob())
                        .then(blob => {
                            // send the downloaded data back to the main thread
                            if (blob.type == 'application/json') self.postMessage({ success: false, error: 'Failure' });
                            else self.postMessage({ success: true, data: blob });
                        })
                        .catch(error => {
                            // send the error information back to the main thread
                            self.postMessage({ success: false, error: error.message });
                        });
                });
            `;

      const workerBlob = new Blob([workerCode], { type: "application/pdf" });
      const workerUrl = URL.createObjectURL(workerBlob);

      this.worker = new Worker(workerUrl);

      return new Promise((resolve, reject) => {
        this.worker.addEventListener("message", (event) => {
          if (event.data.success) {
            const blob = event.data.data;
            const downloadLink = document.createElement("a");
            downloadLink.href = URL.createObjectURL(blob);
            downloadLink.download = `${documentName}.${ext}`;
            downloadLink.target = "_blank";
            if (viewFlag && this.viewDataTypes.includes(ext.toLowerCase())) {
              window.open(downloadLink);
              resolve("file opened");
            } else {
              window.open();
              downloadLink.click();
              resolve("Download was successful");
            }
          } else {
            const error = event.data.error;
            reject(error);
          }
        });
      });
    },
        createWorker2(documentType, ext, endpointExtension, authHeaders, viewFlag, apiUrl) {
            this.showLoader('Loading', true);
            let workerCode = worker.getDocumentWorkerCode(endpointExtension, authHeaders, apiUrl)
            const workerBlob = new Blob([workerCode], { type: blobDocument.blobResolutionType });
            const workerUrl = URL.createObjectURL(workerBlob);
            
            this.worker = new Worker(workerUrl);
            return new Promise((resolve, reject) => {
                this.worker.addEventListener('message', event => {
                    const result = viewDownloadDocument(documentType, ext, viewFlag, event);
                    if (result == blobDocument.opened || result == blobDocument.downloaded) {
                        resolve(result)
                    } else {
                        reject(result)
                    }
                    this.showLoader('Loading', false);
                });
            });
          },

    terminateWorker() {
      this.worker.removeEventListener("message", (event) => {});
      this.worker.terminate();
      this.worker = null;
    },
  },
};
