<template>
    <div>
        <v-card @click="check_cache" tile color="#01BBD9" style="display:flex;color:#fff;" class="splitalign" >
                <p class="font-weight-bold ma-0 pa-0 py-2 pl-4" style="color:#FFFFFF;font-size:15px">Hi {{profileDetails.employeeName|checkdata}}, Welcome To Common Modules Home.</p>
                <span style="font-size:7px;" class="pr-4">V 1.0.241</span>
        </v-card>
        <v-card tile color="#f7fcfb" class="pa-3">
            <v-layout justify-space-between style="flex:5;">
                <v-layout justify-space-between align-center  style="flex:1.3;">
                   
                    <v-layout justify-space-between align-center >
                        <div class="mx-2 mr-4">
                           <v-avatar
                            color="#FFFFFF"
                            size="50"
                            class="elevation-2"
                           >
                              <span class=" text-h6" style="color:'grey;">{{profileDetails.employeeName|nameToInitials}}</span>
                           </v-avatar>
                        </div>
                        <v-layout column justify-space-between>
                            <v-layout class="mb-2 mt-1">
                                <p class="font-weight-medium ma-0 pa-0 mr-3" style="color:#c9c7c1;font-size:13px">User Name:</p>
                                <p class="font-weight-bold ma-0 pa-0 ml-1" style="color:#10242B;font-size:13px">{{profileDetails.ntidUserId|checkdata}}</p>
                            </v-layout>
                            <v-layout class="mb-2">
                                <p class="font-weight-medium ma-0 pa-0 mr-5" style="color:#c9c7c1;font-size:13px">Name:</p>
                                <p class="font-weight-bold ma-0 pa-0 ml-7" style="color:#10242B;font-size:13px">{{profileDetails.employeeName|checkdata}}</p>
                            </v-layout>
                        </v-layout>
                    </v-layout>
                    <v-divider vertical color="#eeeee4" class="elevation-1" inset></v-divider>
                </v-layout>
                
                <v-layout column class="mx-2 ml-6" style="flex:2;">
                        <v-layout class="mb-2 mt-1" align-center>
                            <p class="font-weight-medium ma-0 pa-0 mr-3" style="color:#c9c7c1;font-size:13px">User Role:</p>
                            <!-- <p class="font-weight-bold ma-0 pa-0 ml-2" style="color:#10242B;font-size:13px">{{profileDetails.allRoles|checkdata}}</p> -->
                            <p class="font-weight-bold ma-0 pa-0 ml-2" style="color:#10242B;font-size:13px">{{profileDetails.CM && profileDetails.CM.roleDescription ? profileDetails.CM.roleDescription.toString() : "" |checkdata}}</p>
                        </v-layout>
                        <v-layout class="mb-2" align-center>
                            <p class="font-weight-medium ma-0 pa-0 mr-4" style="color:#c9c7c1;font-size:13px">Email ID:</p>
                            <p class="font-weight-bold ma-0 pa-0 ml-3" style="color:#10242B;font-size:13px">{{userData.email}}</p>
                        </v-layout>
                </v-layout>
                <v-layout align-center justify-end style="flex:1.7" class="pr-6">
                    <v-btn
                      @click="calloutlog"
                        fab
                        color="white"
                        dark
                        small
                        class="elevation-0"
                    >
                         <img   @click="calloutlog" src="../assets/images/Group_11258.png" class="" style="width: 41px;height: 41px"/> 
                    </v-btn>
                    <p @click="calloutlog" class="font-weight-bold ma-0 pa-0 ml-3" style="cursor:pointer;color:#10242B;font-size:15px" >Logout</p>
                </v-layout>

            </v-layout>

        </v-card>
        <v-layout  v-if="false">
      <v-flex>
        <v-card tile>
          <v-card-text>
            <h4 style="color: #000" class="pl-1">Select LOB</h4>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
    <!-- end of top bar -->
    <!-- cards grid start -->
    <v-layout style="border-bottom: 1px solid #f7f7f7f;" class="mt-3" v-if="false">
      <v-flex xs3 v-for="(i,index) in 3" :key="index" class="px-2">
        <v-card @click="gotToPage(index)" tile class="mx-3" style="height: 220px;inset 1px 1px 40px 0 rgba(0, 0, 0, 0.45) !important" :class="index==3?'bg-grey-100':''">
          <v-card-text class="pt-3">
            <img
              v-if="index == 0"
              src="../assets/marine.png"
              alt=""
              srcset=""
              style="height: 120px;"
              class="img-zoom1"
            />
            <!-- <img
              v-if="index == 0"
              src="../assets/Group_11262.png"
              alt=""
            
              style="height: 120px;"
              class="img-zoom2"
            /> -->
            <!-- <img
              v-if="index == 2"
              src="../assets/Group_11261.png"
              alt=""
              srcset=""
              style="height: 120px;"
              class="img-zoom3"
            /> -->
            <img
              v-if="index == 1"
              src="../assets/health.png"
              alt=""
              style="height: 120px;"
              class="img-zoom3"
            />

            <img
              v-if="index == 2"
              src="../assets/EWClaims.png"
              alt=""
              style="height: 120px;"
              class="img-zoom3"
            />
            <!-- <h2 
              color="#bfc6c9" 
              style="height: 125px; font-size: 55px;text-align: center;position:relative;top: 58px;" 
              v-if="index == 1"
            >
              CM
            </h2> -->
          </v-card-text>
          <v-card-actions>
            <v-flex xs12 style="border-top: 1px solid #ccc"  >
              <v-layout>
                <v-flex xs12 class=" pt-3">
                  <!-- <h4 v-if="index == 0">Health Claims</h4> -->
                  <h4 v-if="index == 0">Marine Claims</h4>
                  <!-- <h4 v-if="index == 2">AIGC Claims</h4> -->
                  <h4 v-if="index == 1">Health Claims</h4>
                  <h4 v-if="index == 2">EW Claims</h4>

                </v-flex>
                <v-flex class="xs6 text-right">
                  <v-btn icon>
                <div class="mt-4">
                  <!-- <img
                    src="../assets/Group_11259.png"
                    style="width: 32px;height: 32px"
                  /> -->
                </div>
              </v-btn>
                </v-flex>
              </v-layout>
              
            </v-flex>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
       
    </div>
</template>

<script>

import UserInfoStore from '../app/user-info-store.js';
import userlogout from '../app/user-info-api.js';
import datais from '../app/auth.js'
import {CognitoAuth, StorageHelper   } from 'amazon-cognito-auth-js';
import { Auth } from "aws-amplify";
var storageHelper = new StorageHelper();
var storage = storageHelper.getStorage();

import axios from 'axios';

export default {
    name: 'Home',

    created(){
      console.log('window.localstorage',window.localStorage)
         this.$store.commit('SET_SCREEN_REBOOT', false)
          //  if(!Auth.user){
          //    alert("as auth doesnt have user")
          //     datais.getref('',responseis=>{
          //     console.log("response is in VUE js", responseis)
          //     Auth =  responseis
              // this.$store.commit('SET_SCREEN_REBOOT', true)
              // this.re_renderdone()
            // })
            // return
          // }
          this.profileDetails = this.user_details();
          this.getUsersById(this.user_details().ntidUserId)
          this.$nextTick(() => {
          // Okay, now that everything is destroyed, lets build it up again
          // if(!Auth.user){
          //   datais.getref('',responseis=>{
          //     console.log("response is in VUE js", responseis)
          //     Auth =  responseis
          //     this.$store.commit('SET_SCREEN_REBOOT', true)

          //   })
          // }else{

            this.$store.commit('SET_SCREEN_REBOOT', true)
            this.re_renderdone()
        });
         
        
    },
    filters:{
    
        checkdata(data){
            // console.log('Validity Data', data);
            if (data ==='' || data ===undefined || data === null || data=== 0) {
                return '-'
            } else {
                return data;
            }
        },

        nameToInitials(fullName) {
            if(fullName===''||fullName===null||fullName===undefined ){
              return ''
            }
            const namesArray = fullName.split(' ');
            if (namesArray.length === 1) return `${namesArray[0].charAt(0).toUpperCase()}`;
            else return `${namesArray[0].charAt(0).toUpperCase()}${namesArray[namesArray.length - 1].charAt(0).toUpperCase()}`;
        }
    
    },

    methods:{

         getUsersById(id) {
      console.log("Incoming id", id);
      //users/get-by-ntid/:ntidUserId
      // this.GET(6, "users/all-users/" + id, (res, error) => {
        this.arrayItem  = [];
      this.GET_ByURLM("GET_USER_BY_ID", id, (res) => {
        
          if (res.data.statusCode === 200) {
            console.log(res.data.data, "Get users by _id");
            let tempData = res.data.data[0];
            let userData  = {
                employeeID : tempData.hasOwnProperty("employeeId") ? tempData.employeeId : "",
                mobileNumber : tempData.hasOwnProperty("contactMobileNo") ? tempData.contactMobileNo : "",
                email : tempData.hasOwnProperty("email")  ? tempData.email  : ""
            }
            this.userData = userData
             
            
            

          } else {
            this.showToast(res.data.msg, this.TOST().ERROR);
          }
      },(error)=>{
        console.log(error)
          this.showToast(error.response.data.msg, this.TOST().WARNING);
        },true);
    },

         check_cache(){
              let cachedata = {data:{}}
              let fetchvalue = this.getCOGNITO()
              console.log('fetchvalue',fetchvalue)
              
                if(fetchvalue){
                      // this.process_response(response)
                      let set_token = {}
                      fetchvalue.forEach(e=>{
                          set_token = JSON.parse(window.localStorage.getItem(e))
                            console.log(e,'set_token is',set_token)
                          if(e.split('.')[1].slice(e.split('.')[1].length-1) == 'x'){
                            cachedata.data.idToken = set_token
                          }else{
                            cachedata.data.accessToken = set_token
                          }
                      })

                       let authdata = {user:null}

                    authdata.user = this.process_response(cachedata)
                    this.$store.commit('SET_LOGINDATA',authdata);


                console.log('cachedata final authdata for vuex',authdata);
                
                }else{
                    this.showToast("An error occurred while fetching data", this.TOST().ERROR)
                }
                console.log('cachedata last',cachedata);

            

        },

        calloutlog(){
           this.log_out_function()
        },

        re_renderdone(){
          // alert("re-render is called")
          this.showLoader('Loading', false);
          this.$store.commit("SAVE_PAGE_HEADER", "Welcome to Dashboard");
          this.showToast("You're now Logged-in",'success',)
          console.log(this.user_details(),"this.user_details()");
          this.profileDetails = this.user_details();
          // console.log(datais.auth.signInUserSession.idToken,'res datais  | user-info from the store, ready for API hit',this.userInfo);
          // this.$store.commit('SET_A_C_T_token', datais.auth.signInUserSession.idToken)
          this.userdata= this.$store.state.Auth.user.signInUserSession.idToken
          // datais.auth.signInUserSession.idToken
          let tokendata = this.userdata.payload
          console.log(tokendata.selectedLOB);

          this.$store.state.lob_type = tokendata.selectedLOB
          console.log(this.$store.state.lob_type);

        },

        logout(){
        
          this.$router.push('/login');
          this.showToast("User Successfully logged Out",'success',)
        },

         SETLOGOUT(){
            console.log("LOGOUT is called, preparing for flush !");
                // datais.logout(keystoragedata=>{
                //     console.log("Logout process ", res)

                 this.$router.push('/logout');
                    // userlogout.logouthit();
                    
                    //   for(let thekeys in keystoragedata){
                    //       console.log("")
                    //         storage.removeItem(thekeys);
                    //     }

                    // window.open("http://localhost:8080/logout",'_self');
                   
                // });
        },
        gotToPage(incomgIndex){
          console.log("incomgIndex===>",incomgIndex);
          //  datais.logout()
           // for DEV
          let rd={
            marineServer:"https://claimsdrive-commercial.salesdrive.app/login/",
            healthServer: "https://claimsdrive-health.salesdrive.app/login/",
            // healthServer: "http://localhost:8080/redihere"
          }

        //   for UAT
          // let rd={
          //   marineServer:" https://claimsdrive-commercial.salesdrive.app/login/",
          //   commonServer: "https://claimsdrive.salesdrive.app/login/"
          // }
        // console.log(this.$store.state.Auth.user.signInUserSession,"<<<->>>", rd)
          
            // 'https://claimsdrive.salesdrive.app/common-system-service/session/redirect?lob=Marine&jti=f1c92ae6-4045-4ce2-82d3-c774df918466'
          try{
              let sellob = {lob:'' ,
                            usr_jti:this.$store.state.Auth.user.signInUserSession.idToken.payload.jti}
                
              if(incomgIndex == 2){
                sellob.lob = 'EW'
              }else if(incomgIndex == 0){
                sellob.lob = 'Marine'
              }else if(incomgIndex == 1){
                sellob.lob = 'Health'
              }
              
              let apiis = 'https://claimsdrive.salesdrive.app/common-system-service/session/redirect?lob='+sellob.lob+'&jti='+sellob.usr_jti
              
              console.log('apiis', apiis)

              window.location.href = apiis

            return
            //  return
              // axios.get(
              //   // 'https://claimsdrive.salesdrive.app/common-system-service/session/redirect?lob=Health&jti=cf1e4b26-410e-4aa3-b0e3-4458e1fe147e'
              //   'https://claimsdrive.salesdrive.app/common-system-service/session/redirect?lob='+sellob.lob+'&jti='+sellob.usr_jti
              //   ).then(res=>{

              //   console.log("reseponse if redirect is >>>", res)

              // }).catch(e=>{
              //   console.log("from catch",e)
              // })

          }catch(errct){
            console.log("error from catch re-direction",errct)
          }
         
          // if(incomgIndex===0){
          //   window.open(rd.marineServer,'_self');
          // }else if(incomgIndex===1){
          //   window.open(rd.healthServer,'_self');
          // }

        },

        setpathH(ident){
            //  let rd={
            //     local:"http://localhost:8083/",
            //     server:"https://test-claimsdrive-health.salesdrive.app/"
            // }
            // let hst = 'https://common-module.auth.ap-south-1.amazoncognito.com/'+
            //      'login?client_id=2e2sn1rs2tob3ud8nugoflvh2b'+
            //       '&response_type=code'+
            //       '&scope=aws.cognito.signin.user.admin+email+openid'+
            //       '&redirect_uri=https://test-claimsdrive-health.salesdrive.app/login/oauth2/code/cognito'
             let rd={

                server:"https://claimsdrive-commercial.salesdrive.app/login/",
                servercommon: "https://claimsdrive.salesdrive.app/login/",
                
            }
         // let re_direction_url = ""+"login?response_type=code"+"&client_id=1nh2j7eflfk37c5u2cmu7melvn"+"&redirect_uri=http://localhost:8081"

            // window.open('http://localhost:8081/login/oauth2/code/marine'+,'_blank')
                
                // "login?response_type=code"+
                // "&client_id=1nh2j7eflfk37c5u2cmu7melvn"+
                // "&redirect_uri=http://localhost:4200"
            // _____________________________________________________
            // from hosted UI 
                 // https://common-module.auth.ap-south-1.amazoncognito.com/
                // login?client_id=2e2sn1rs2tob3ud8nugoflvh2b
                // &response_type=code
                // &scope=aws.cognito.signin.user.admin+email+openid
                // &redirect_uri=http://localhost:8083/login/oauth2/code/cognito

        // 'https://common-module.auth.ap-south-1.amazoncognito.com/'
        // 'login?client_id=2e2sn1rs2tob3ud8nugoflvh2b&response_type=code'
        // '&scope=aws.cognito.signin.user.admin+email+openid&redirect_uri=http://localhost:8083/login/oauth2/code/cognito'


        //   let same_as_hosted_ui =  'https://common-module.auth.ap-south-1.amazoncognito.com/'+
        //          'login?client_id=2e2sn1rs2tob3ud8nugoflvh2b'+
        //           '&response_type=code'+
        //           '&scope=aws.cognito.signin.user.admin+email+openid'+
        //           '&redirect_uri=http://localhost:8083/login/oauth2/code/cognito'

        // let re_dir = same_as_hosted_ui
        // 'http://localhost:8083/login/oauth2/code/cognito' not working just landed on the port and route
        // console.log("re-directing to -> ", re_dir)
        // window.open(re_dir,'_self');
                //  window.open('http://localhost:8083/','_self');
            if(ident == 1){
                // alert("Now re-directing to Marine")
                console.log("rd is ?", rd.server);
                 window.open(rd.server,'_self');
            }else if(ident == 2){
               
            }else if(ident == 3){
               
            }else if(ident ==9){
                //  alert("Now re-directing to Common-Modules")
                window.open(rd.servercommon,'_self');
                
            }else{
                 window.open(rd.local,'_self');
            }
        },

    },
    data: function() {
        return{
            profileDetails:{},
            userdata:null,
            userPoolId: process.env.VUE_APP_COGNITO_USERPOOL_ID,
            userInfo: UserInfoStore.state.cognitoInfo,
            userData:{}
        }
    }
}
</script>

<style>

.splitalign{
    color:#fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

</style>
