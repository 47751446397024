<template>
<v-app>
      
      <v-form @submit.prevent="submitDetails"> 
          <v-card style="background-color:#FFFFFF" class="pa-3"> 
                    <v-layout class="mt-3"  wrap style="align-items:flex-end">

                        <v-flex xs3>
                        <v-layout column class="cust_pln_cd mx-2 ">
                            <p class="font-weight-bold cstm-clr-pln-cd">
                                Provider Name*
                            </p>

                            <!-- <v-autocomplete  
                            :items="selectItems"    
                            v-model="providerDetails"
                            @change="changeVal"
                            label="Select"             
                            solo
                            class="rounded-0"
                            required
                            :search-input.sync="searchApi"
                            :error-messages="providerErrors"
                            return-object
                            cache-items
                            item-value="API"
                            :loading="isLoading"
                             
                        ></v-autocomplete> -->

                        <v-autocomplete
                            v-model="providerDetails"
                            :items="selectItems" 
                            :loading="isLoading"
                            :search-input.sync="searchApi"
                            color="white"
                            cache-items
                            hide-selected
                            item-value="API"
                            label="Provider Name..."
                            return-object
                            solo
                            :disabled="disableFuncName"
                            :error-messages="providerErrors"
                            @blur="$v.providerDetails.$touch()"
                            required>
                        <div slot="append" style="position:relative;left:13px;background-color:#F7F7F7;padding: 9px !important" class=""><v-icon @click="getProviderDetails">search</v-icon></div>       
                        </v-autocomplete>

                        </v-layout>
                    </v-flex>

                    <v-flex xs3 class="mb-1">
                        <v-layout column class="cust_pln_cd mx-2 ">
                            <p class="font-weight-bold cstm-clr-pln-cd">
                                Provider Type
                            </p>
                            <v-text-field      
                            v-model="providerType"
                            label="Select..."             
                            solo
                            class="rounded-0"
                             required
                            :rules="requiredRules"
                            :disabled="disableFunc"
                            dense
                            background-color="#f7f7f7"
                        ></v-text-field >
                        </v-layout>
                    </v-flex>

                    <v-flex xs3 class="mb-1">
                        <v-layout column class="cust_pln_cd mx-2 ">
                            <p class="font-weight-bold cstm-clr-pln-cd">
                                PRN
                            </p>
                            <v-text-field      
                            v-model="prn"
                            label="Select"             
                            solo
                            class="rounded-0"
                             required
                            :rules="requiredRules"
                            :disabled="disablePrn"
                            dense
                            background-color="#f7f7f7"
                        ></v-text-field >
                        </v-layout>
                    </v-flex>

                     <v-flex xs3>
                    <v-layout column class=" mx-2  cust_pr_quick cust-rmvpd-pr-quick">
                        <p class="font-weight-bold cstm-clr-pr-quick">
                            Tariff Effective Date*
                        </p>
                        
                        <v-menu
                            ref="calender"
                            :close-on-content-click="false"
                            v-model="fromCalenderDate"
                            :nudge-right="40"
                            lazy
                            transition="scale-transition"
                            full-width
                            max-width="290px"
                            min-width="290px" 
                            class="rounded-0"   
                            >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-bind="attrs"
                                    v-on="on"
                                    id="fromDate"
                                    label="dd/mm/yyyy"
                                    :value="computedProviderEffectiveDate"
                                    solo
                                    class="rounded-0"
                                    readonly
                                    :disabled="disableEffectiveDate"
                                    required
                                    :error-messages="nameErrors"
                                     @input="$v.providerEffectiveDate.$touch()"
                                     @blur="$v.providerEffectiveDate.$touch()"
                                >
                            <div  slot="prepend-inner" style="background-color:#F7F7F7;  padding: 8px !important"><span class="material-icons">date_range</span></div>
                                
                                </v-text-field>
                            </template>                        
                                    <v-date-picker
                                        v-model="providerEffectiveDate"
                                        no-title
                                        @input="fromCalenderDate = false"
                                    ></v-date-picker>
                        </v-menu>


                    </v-layout>
                </v-flex>

                    <v-flex xs3>
                    <v-layout column class="mt-3 mx-2  cust_pr_quick cust-rmvpd-pr-quick">
                        <p class="font-weight-bold cstm-clr-pr-quick">
                            Tariff Expiration Date*
                        </p>
                        
                        <v-menu
                            ref="calender"
                            :close-on-content-click="false"
                            v-model="toCalenderDate"
                            :nudge-right="40"
                            lazy
                            transition="scale-transition"
                            full-width
                            max-width="290px"
                            min-width="290px" 
                            class="rounded-0"   
                            >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-bind="attrs"
                                    v-on="on"
                                    id="fromDate"
                                    label="dd/mm/yyyy"
                                    :value="computedProviderExpiryDate"
                                    solo
                                    class="rounded-0"
                                    readonly
                                    required
                                    :error-messages="providerExpiryDateErrors"
                                     @input="$v.providerExpiryDate.$touch()"
                                     @blur="$v.providerExpiryDate.$touch()"
                                  
                                >
                            <div  slot="prepend-inner" style="background-color:#F7F7F7;  padding: 8px !important"><span class="material-icons">date_range</span></div>
                                
                                </v-text-field>
                            </template>                        
                                    <v-date-picker
                                        :min="new Date().toISOString().substr(0, 10)"
                                        v-model="providerExpiryDate"
                                        no-title
                                        @input="toCalenderDate = false"
                                    ></v-date-picker>
                        </v-menu>


                    </v-layout>
                </v-flex>
                

                    <v-flex xs3>
                        <v-layout column class="cust_pln_cd mx-2 ">
                            <p class="font-weight-bold cstm-clr-pln-cd">
                                Tariff Name*
                            </p>
                            <v-text-field      
                            v-model="tariffName"
                            label="Select"             
                            solo
                            class="rounded-0"
                             required
                             autocomplete="off"
                             :disabled="disableFuncName"
                            :error-messages="providerErrorsTariff"
                             @input="$v.tariffName.$touch()"
                             @blur="$v.tariffName.$touch()"
                        ></v-text-field>
                        </v-layout>
                    </v-flex>

                    </v-layout>
                    </v-card>

              <v-card color="#F7F7F7" class="mt-8 pa-5 overflow-y-auto">
                       <v-row class="mt-1 mr-1 float-right">
                               <v-btn @click="$router.push('/search-tarrif')" tile dark color="#152F38" class="text-capitalize mr-6" small>
                                   <v-icon color="" small >mdi-arrow-left</v-icon>
                                   <v-divider vertical class="ml-2 mr-2"></v-divider>Return
                               </v-btn>
                               <v-btn v-show="disableCreateNewBtn" dark tile type="submit" color="#23B1A9" class="text-capitalize mr-6" small>
                                   <v-icon color="" small >mdi-plus-circle</v-icon>
                                   <v-divider vertical class="ml-2 mr-2"></v-divider>Create New
                               </v-btn>
                               <v-btn v-if="newBtns == true" tile type="submit" dark color="#23B1A9" class="text-capitalize mr-6" small>
                                   <v-icon color="" small >check_circle</v-icon>
                                   <v-divider vertical class="ml-2 mr-2"></v-divider>Save
                               </v-btn>
                               <v-btn v-if="newBtns == true" @click="() => {dialogNextBtn = true}" tile dark color="#152F38" class="text-capitalize mr-6" small>
                                   <v-icon color="" small >mdi-arrow-right</v-icon>
                                   <v-divider vertical class="ml-2 mr-2"></v-divider>Next
                               </v-btn>
                        </v-row>
                   </v-card>

                   <v-dialog v-model="dialogFunc" persistent max-width="420px" max-height="400px" class="" >
                        <v-card color="#F7F7F7" class="pb-2" tile>
                            <v-card>
                                <v-layout justify-space-between  class="" style="flex:1;background-ox-shadow: 0px 3px 5px #00000029;">
                                    
                                        <v-card-title class="pa-0 pl-3 text-capitalize font-weight-bold" style="color:#10242B;font-size:13px">Tariff Configuration</v-card-title>
                                       
                                    <div style="border-left:1px solid #C1C8CC;" @click="closeDialog">
                                        <v-icon style="cursor: pointer;" class="pa-2" >close</v-icon>
                                    </div>
                                </v-layout>
                            </v-card> 
                        <v-card class="ma-4 pa-4" tile>
                                    
                        <v-layout color="#FFFFFF" class="mx-2 "  style="" justify-center>
                            <v-btn @click="$router.push('/tarrifbulkupload')" tile dark color="#23B1A9" class="text-capitalize px-3 mx-2" small><v-icon dark small>mdi-file-excel-box</v-icon> <v-divider vertical class="mx-2"></v-divider>Excel Upload</v-btn>
                            <v-btn @click="$router.push('/tarrifconfiguration')" tile dark color="#111212" class="text-capitalize px-3 mx-2" small><v-icon dark small>mdi-tune</v-icon> <v-divider vertical class="mx-2"></v-divider> Manual Submission</v-btn>
                        
                    </v-layout>
                </v-card>
            </v-card>
            </v-dialog>

             <!-------NEXT Button popup--->
             <v-dialog v-model="dialogNextBtn" persistent max-width="420px" max-height="400px" class="" >
                        <v-card color="#F7F7F7" class="pb-2" tile>
                            <v-card>
                                <v-layout justify-space-between  class="" style="flex:1;background-ox-shadow: 0px 3px 5px #00000029;">
                                    
                                        <v-card-title class="pa-0 pl-3 text-capitalize" style="color:#10242B;font-size:23px">CONFIRMATION</v-card-title>
                                       
                                    <div style="border-left:1px solid #C1C8CC;" @click="closeDialog">
                                        <v-icon style="cursor: pointer;" class="pa-2" >close</v-icon>
                                    </div>
                                </v-layout>
                            </v-card> 
                        <div class="ml-4 pt-2">
                            <p>Details entered on the page will be lost if not saved</p>
                        </div>

                        <v-row class="mt-1 ml-4 pb-2">
                               <v-btn @click="() => {dialogNextBtn = false}" tile dark color="#152F38" class="text-capitalize mr-6" small>
                                   <v-icon color="" small >cancel</v-icon>
                                   <v-divider vertical class="ml-2 mr-2"></v-divider>Cancel
                               </v-btn>
                               <v-btn @click="() => {dialogFunc = true}" tile dark color="#23B1A9" class="text-capitalize mr-6" small>
                                   <v-icon color="" small >check_circle</v-icon>
                                   <v-divider vertical class="ml-2 mr-2"></v-divider>Ok
                               </v-btn>
                        </v-row>
            </v-card>
            </v-dialog>

                </v-form>

  

  


</v-app>
</template>

<script>
import moment from 'moment';
let dateIs = null;
import {required, alpha, helpers} from 'vuelidate/lib/validators';
const nameSpaces = helpers.regex('nameSpaces', /^[a-zA-Z' ]+$/);

export default {
    data(){
        return{
            disableCreateNewBtn: true,
            newBtns: false,
            cloneid: '',
            dialogFunc: false,
            dialogNextBtn: false,
            cicoverageType: '',
            civalid: true,
            requiredRules: [v => !!v || 'Item is required'],
            providerDetails: '',
            prn: '',
            
            providerType: '',
            toCalenderDate:false,
            fromCalenderDate:false,
            providerEffectiveDate: new Date().toISOString().substr(0, 10),
            providerExpiryDate: '',
           itemsType: ['Doctor','Hospital','Dentist','Physiotherapist','Homeopath',
           'Ayurved','Nurse','Psychotherapist','Nutritionist','Dietician','Other',
           'Daycare Center','Nursing Home','Clinic','Rehabilitation Center','Pharmacy',
           'Diagnostic Centre','Home Care provider','Hospice','Ambulance','Air Ambulance',
           'Telehealth Provider','Psychiatric Facility'],

           disableFunc: false,
           disablePrn: false,
           disableFuncName: false,
           disableEffectiveDate: false,
           providerId: '',
           tariffName: '',
           searchApi: null,
           isLoading: false,
           entries: [],

        }
    },


    validations: {
       providerDetails: {required},
       providerEffectiveDate: {required},
       providerExpiryDate: {required},
       tariffName: {required, nameSpaces}
    },

    created(){
       this.$store.commit("SAVE_PAGE_HEADER_CREATE_TARIFF", "Create New Tariff");
    //    console.log(this.$route.params.cloneDetails);
    //    console.log(this.$route.params.cloneTariff);
    //    console.log(this.$route.params.renewDetails);

       
       
       if(this.$route.params.cloneTariff == 'CLONE_TARIFF_DETAILS'){
           this.cloneTariffDetails(this.$route.params.cloneDetails);
       }else if(this.$route.params.renewTariff == 'RENEW_TARIFF_DETAILS'){
           this.renewTariffDetails(this.$route.params.renewDetails);
       }else if(this.$route.params.editTariff == 'EDIT_TARIFF_DETAILS'){
           this.editTariffDetails(this.$route.params.editDetails);
       }
       
    },

    mounted(){
        console.log(this.$route.params.renewTariff);
        console.log(this.$route.params.editTariff);
        if(this.$route.params.renewTariff == 'RENEW_TARIFF_DETAILS'){
            this.disableFuncName = true;
            this.disableFunc = true;
            this.disablePrn = true;
        }else if(this.$route.params.editTariff == 'EDIT_TARIFF_DETAILS'){
            this.disableFuncName = true;
            this.disableFunc = true;
            this.disablePrn = true;
            this.disableEffectiveDate = true;
            this.newBtns = true;
            this.disableCreateNewBtn = false;
        }
        this.disableFunc = true;
        this.disablePrn = true;
    },

    
    watch:{
       searchApi(val){
            //   if (this.selectItems.length > 0) return

            // Items have already been requested
            if (this.isLoading) return 
            this.isLoading = true;
        //   console.log(item);
        //   this.getProviderDetails(item)
        //    this.GET(2,'provider/search-providers-for-name/'+ val ,(res, err) => {
            this.GET(2,'provider/search-providers-for-tariff/'+ val ,(res, err) => {
                if(!err){
                 var newData = res.data.data.data;
                console.log(newData);
                this.cloneid = newData[0]._id;
                console.log(newData[0]._id);

               var newArr = [];
                for(let provider of newData){ 
                    if(provider.registeredEntity === 'Individual'){
                     newArr.push(provider.firstName);
                    }else if(provider.registeredEntity === 'Institution'){
                         newArr.push(provider.entityName);
                    }
                    
                }
                
                
                console.log(newArr);
                // this.cloneid = newArr;
                // this.selectItems = mapData;
                this.entries = newArr;
                } 
                else{
                    console.log(err);
                }
            })   
            this.isLoading = false;
       },

    },

    computed: {
        selectItems(){
          return this.entries;
        },

        nameErrors(){
            const errors = []
           
            // if(toDate.length > 0){
                if (!this.$v.providerExpiryDate.$dirty) return errors   
              !this.$v.providerEffectiveDate.required && errors.push('This field is required!');
            // }
           
            return errors
        },

        computedProviderEffectiveDate () {
            console.log(this.providerEffectiveDate)
            console.log(this.providerEffectiveDate ? moment(this.providerEffectiveDate).format("DD-MM-YYYY"): '')
            return this.providerEffectiveDate ? moment(this.providerEffectiveDate).format("DD-MM-YYYY"): ''
        },

         computedProviderExpiryDate() {  
            // console.log(this.providerToDate)
            console.log(this.providerExpiryDate ? moment(this.providerExpiryDate).format("DD-MM-YYYY"): '')
            return this.providerExpiryDate ? moment(this.providerExpiryDate).format("DD-MM-YYYY"): ''  

        },

        providerErrors(){
            const errors = [];
            // let check = this.$v.providerDetails.$model;
            // if(check.length > 1) return errors
            if (!this.$v.providerDetails.$dirty) return errors   
            !this.$v.providerDetails.required && errors.push('This field is required!');
            return errors;
        },

        providerErrorsTariff(){
            const errors = [];
        
            if (!this.$v.tariffName.$dirty) return errors   
            !this.$v.tariffName.required && errors.push('This field is required!');
            !this.$v.tariffName.nameSpaces && errors.push("Only Alphabets are allowed");       
               

            return errors;   
                
        },

        providerExpiryDateErrors(){ 
           let toDate = this.$v.providerExpiryDate.$model;
            let fromDate = this.$v.providerEffectiveDate.$model;
            let currentDate = new Date();
            const errors = []
           
            // if(toDate.length > 0){
                if (!this.$v.providerExpiryDate.$dirty) return errors   
                if(!moment(toDate).isAfter(fromDate))
                errors.push('*Expiration date must be greater than Effective date');
            // }
            // if(this.$route.params.renewTariff == 'RENEW_TARIFF_DETAILS'){
            //     console.log("from renew error",currentDate);
            //     console.log(this.providerExpiryDate);
            //     dateIs = moment(this.providerExpiryDate)._i.split("T")[0] +' ';
            //     console.log(dateIs);
            //     let toDateIs = moment(dateIs).format("DD-MM-YYYY");
            //     console.log(toDateIs);
            //     if(toDateIs < currentDate){
            //         errors.push('*Expiration date must be greater than system current date');
            //     }
            // }
           
            return errors

        },

    },


    methods: {
        closeDialog(){
            this.dialogFunc = false;
            this.dialogNextBtn = false;
        },

        cloneTariffDetails(data){
            // dateIs = moment(this.$route.params.cloneDetails.tariffEffectiveDate)._i.split("T")[0];
            // let toDateData = moment(dateIs).format("DD-MM-YYYY");
            // dateIs = moment(this.$route.params.cloneDetails.tariffExpirationDate)._i.split("T")[0];
            // let fromDateData = moment(dateIs).format("DD-MM-YYYY");
            // console.log(toDateData);
            this.entries.push(data.providerName);
            this.providerDetails = data.providerName;
            this.providerType = data.providerType;
            this.prn = data.prn;
            this.providerEffectiveDate = data.tariffEffectiveDate;
            this.providerExpiryDate = data.tariffExpirationDate;
            this.tariffName = data.tariffName;
            console.log(this.providerEffectiveDate);
        },

        renewTariffDetails(data){
            console.log(data)
            this.entries.push(data.providerName);
            this.providerDetails = data.providerName;
            this.providerType = data.providerType;
            this.prn = data.prn;
            this.providerEffectiveDate = data.tariffEffectiveDate;
            this.providerExpiryDate = data.tariffExpirationDate;
            this.tariffName = data.tariffName; 
        },

        editTariffDetails(data){
            this.entries.push(data.providerName);
            this.providerDetails = data.providerName;
            this.providerType = data.providerType;
            this.prn = data.prn;
            this.providerEffectiveDate = data.tariffEffectiveDate;
            this.providerExpiryDate = data.tariffExpirationDate;
            this.tariffName = data.tariffName; 
        },
        

        // getProviderDetails(val){
                       
        //     this.GET(2, 'provider/search-providers-by-name/'+ val ,(res, err) => {
        //         if(!err){
        //          var newData = res.data.data.data;
        //         console.log(newData);
        //         var newArr = [];
        //         for(let provider of newData){ 
        //             if(provider.registeredEntity === 'Individual'){
        //              provider.push(provider.firstName);
        //             }else if(provider.registeredEntity === 'Institution'){
        //                  provider.push(provider.entityName);
        //             }
                    
        //         }
                
                
        //         console.log(newArr);
        //         // this.selectItems = mapData;
        //         this.entries = newArr;
        //         } 
        //         else{
        //             console.log(err);
        //         }
        //     })       
        
        //-----from QuickEmplmnt
        getProviderDetails(){
            
            // console.log("searching for provider");
            if(this.providerDetails === ''){
                return   this.showToast("Please Select Valid Provider" ,this.TOST().WARNING);
            }
           
            this.GET(2,'provider/search-providers-for-tariff/'+this.providerDetails,
            
                    (res,err) => {
                        if(!err){
                            console.log(this.providerDetails);
                            console.log(res.data.data.data);
                            console.log(res.data.data.data[0].providerType);
                            this.providerId = res.data.data.data[0]._id;
                            this.providerType = res.data.data.data[0].providerType;
                            this.prn = res.data.data.data[0].prn;
                            
                        }else{
                                this.showToast(err.response.data.msg ,this.TOST().ERROR);
                        }   

                    });

           

        },


        submitDetails(){
            console.log("%c form is fine", "background-color:green");
            var formData = {
              'providerName': this.providerDetails,
              'providerType': this.providerType,
              'prn': this.prn,
              "tariffEffectiveDate": this.providerEffectiveDate,
              'tariffExpirationDate': this.providerExpiryDate,
              'tariffName': this.tariffName,
              'providerId': this.providerId
            }

            this.$v.$touch();
            if(this.$v.$invalid || this.providerErrorsTariff.length > 0 || this.providerExpiryDateErrors.length > 0){
                // console.log("length of providertodatelength is --->",this.providerToDateErrors.length);
                console.log("It is not valid form");
                this.showToast("Please fill required fields",this.TOST().WARNING)
            }else if(this.$route.params.cloneTariff == 'CLONE_TARIFF_DETAILS'){
                let toDate = this.$v.providerExpiryDate.$model;
                if(!moment(toDate).isAfter(new Date())){
                    //  console.log('Not Fine');
                     this.showToast('Expiration Date must be greater than system current Date' ,this.TOST().WARNING);
                }else{
                     console.log('Clone fine');
                var newId = this.$route.params.cloneDetails._id;
                console.log(this.cloneid);
                var cloneData = {
                  'providerId': this.cloneid,  
                  'providerName': this.providerDetails,
                  'providerType': this.providerType,
                  'prn': this.prn,
                  'tariffName': this.tariffName,
                  'tariffEffectiveDate': this.providerEffectiveDate,
                  'tariffExpirationDate': this.providerExpiryDate,
                  'type': 'clone'
                }
                console.log(cloneData);
                this.POST(2,'tariff/clone-tariff/'+newId, cloneData,(res, err) => {
                   if(!err){
                      console.log(res.data.data);
                      this.showToast(res.data.msg  ,this.TOST().SUCCESS);
                      this.$router.push('/search-tarrif');
                   }else{
                        this.showToast('Something went wrong' ,this.TOST().ERROR);
                   } 
                })  
                }              
            }else if(this.$route.params.renewTariff == 'RENEW_TARIFF_DETAILS'){
                let toDate = this.$v.providerExpiryDate.$model;
                if(!moment(toDate).isAfter(new Date())){
                    //  console.log('Not Fine');
                     this.showToast('Expiration Date must be greater than system current Date' ,this.TOST().WARNING);
                }else{
                    console.log('Renew fine');
                var newId = this.$route.params.renewDetails._id;
                console.log(this.cloneid);
                var renewData = {
                  'providerId': this.cloneid,  
                  'providerName': this.providerDetails,
                  'providerType': this.providerType,
                  'prn': this.prn,
                  'tariffName': this.tariffName,
                  'tariffEffectiveDate': this.providerEffectiveDate,
                  'tariffExpirationDate': this.providerExpiryDate,
                  'type': 'renew'
                }
                console.log(renewData);
                this.POST(2,'tariff/clone-tariff/'+newId, renewData,(res, err) => {
                   if(!err){
                      console.log(res.data.data);
                      this.showToast(res.data.msg ,this.TOST().SUCCESS);
                      this.$router.push('/search-tarrif');
                   }else{
                        this.showToast('Something went wrong' ,this.TOST().ERROR);
                   } 
                }) 
              }      
            }
            else if(this.$route.params.editTariff == 'EDIT_TARIFF_DETAILS'){
                // var newId = this.$route.params.editDetails._id;
                var editData = {
                  'id': this.$route.params.editDetails._id,
                  'tariffExpirationDate': this.providerExpiryDate,
                }
                console.log(editData);
                this.PUT(2,'tariff/update-tariff', editData,(res, err) => {
                   if(!err){
                      console.log(res.data.data);
                      this.showToast(res.data.msg ,this.TOST().SUCCESS);
                   }else{
                        this.showToast('Something went wrong' ,this.TOST().ERROR);
                   } 
                }) 
            }
            else{
                console.log("all fine");
                this.POST(2,'tariff/create-new-tariff',formData ,
                            ( res, error) => {
                             if(!error){
                                    console.log(res.data.data);
                                    
                                    this.showToast(res.data.msg ,this.TOST().SUCCESS);
                                    this.dialogFunc = true;
                                    // this.$router.push('/search-tarrif')
                                    this.$store.commit("SAVE_DETAILS", res.data.data);
                                    
                            }else{
                                    console.log("when status is not 200", error.response);
                                    this.showToast(error.response.data.msg ,this.TOST().ERROR);
                            }
    
                });
            }

            // if(this.$v.$invalid){
            //     console.log("validations failed");
            // }else {
            //     console.log("all fine");
            // }

            // var formData = {
            //   'providerId': this.providerId,
            //   'providerName': this.providerDetails,
            //   'providerType': this.providerType,
            //   'prn': this.prn, 
            // }

            // //  this.$v.$touch();http://localhost:3002/provider-management-service/api/tariff/create-new-tariff
            // this.POST(2,'tariff/create-new-tariff',formData ,
            //                 ( res, error) => {
            //                  if(!error){
            //                         console.log(res.data);
            //                         this.showToast(res.data.msg ,this.TOST().SUCCESS);
            //                 }else{
            //                         console.log("when status is not 200", error.response.data.msg);
            //                         this.showToast(error.response.data.msg ,this.TOST().ERROR);
            //                 }
            //                         console.log("APi response from |-> provider/create-provider-individual ", res);
            //              });
            }
        },

    

}
</script>


<style>

.cust_pln_cd .v-text-field .v-input__slot,
.v-text-field--outline .v-input__slot {
    min-height: 41px !important;
    display: flex !important;
    align-items: center !important;
    /* border:1px red solid; */
}

.cust_pln_cd .v-input .v-label {
    font-size: 13px !important;
}
.cust_pln_cd .v-input {
    font-size: 13px !important;
}
.cust_pln_cd .v-input input {
    font-size: 13px !important;
}

.cstm-clr-pln-cd{
    color:#646666 !important;
    margin-bottom: 5px !important;
    font-size: 13px !important;
}
.cust-pln-cd .v-text-field.v-text-field--enclosed>.v-input__control>.v-input__slot {
    padding: 0px !important;
}

.cust_lbl-bgclr{
    color:#152F38

}

.gridheader{
    background-color: #01B4BB;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>