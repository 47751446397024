<template>
  <v-app>
     <v-layout class="pt-3 graybackground">
      <v-flex xs8>
        <v-layout column class="ml-3">
          <v-text-field
            v-model="providerName"
            solo
            dense
            placeholder="Enter..."
            class="textFieldSearch forminput"       
          >
            <template v-slot:append class="mx-0 my-0">
              <v-icon class="iconstyledropdown" @click="openCloseFilters"
                >mdi-menu-down</v-icon
              >
            </template>
          </v-text-field>
        </v-layout>
      </v-flex>
      <v-flex xs4>
        <v-layout column class="ml-3">
          <div>
            <v-btn
              color="#23B1A9"
              tile
              large
              class="btn_margin"
              @click="searchProvider"
            >
              <v-icon> mdi-magnify </v-icon>
              <div class="vertical_line"></div>
              Search
            </v-btn>
            <v-btn
              color="#152F38"
              tile
              large
              class="btn_margin"
              @click="clearSearch"
            >
              <v-icon class="clearicon"> mdi-close-circle </v-icon>
              <div class="vertical_line"></div>
              Clear
            </v-btn>
          </div>
        </v-layout>
      </v-flex>
      <!-- <v-flex xs2>
        <v-layout column class="ml-3 mr-2">
          <v-btn color="#23B1A9" tile large class="btn_margin" @click="$router.push('/markuseroffline')">
            <v-icon class="mr-2"> mdi-cloud-off-outline </v-icon>
            <div class="vertical_line"></div>
            User Offline
          </v-btn>
        </v-layout>
      </v-flex> -->
    </v-layout>

     <v-layout
      class="graybackground searchback"
       v-if="searchListOptionMenu"
    >
      <!-- Search Zone Html Code -->
      <v-flex xs4 class="totaldivBackgroundSearch ml-3 mr-3">
        <v-text-field placeholder="Search Zone" solo dense class="forminput"
         clearable  :rules="searchzoneRule" v-model="searchObj.zoneModel"  
         @keyup="searchZoneList(searchObj.zoneModel)"
         @click:clear="getZoneList" deletable-chips 
          >
          <template v-slot:append class="my-0 mr-2">
            <v-icon class="buttonSearchIcon">mdi-magnify</v-icon>
          </template>
        </v-text-field>
        <v-card
          class="mx-auto overflowcard"
          max-width="800"
          max-height="240"
         
        >
          <v-list  v-if="searchObj.searchZoneitems.length !==0">
             <v-list-item-group>
                 <v-list-item
                  class="vlistBorder mb-0 pb-0"
                  dense
                  active-class="blue--text text--accent-5"
                  @change="selectZone()"
                 >
                  <template v-slot:default="{ active }">
                    <v-list-item-action>
                      <v-checkbox :input-value="active" disabled  id="zoneselectallId"></v-checkbox>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>Select All</v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-list-item>
             </v-list-item-group>
           </v-list>
          <v-list v-if="searchObj.searchZoneitems">
            <v-list-item-group v-model="searchObj.searchZone" multiple>
              <template v-for="(item, i) in searchObj.searchZoneitems">
                <v-divider v-if="!item" :key="`divider-${i}`"></v-divider>
                <v-list-item
                  class="vlistBorder"
                  v-else
                  :key="`item-${i}`"
                  :value="item"
                  dense
                  active-class="blue--text text--accent-5"
                >
                  <template v-slot:default="{ active }">
                    <v-list-item-action>
                      <v-checkbox
                        :input-value="active"
                        class="shrink"
                        disabled
                      ></v-checkbox>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title v-text="item"></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-list-item>
              </template>
            </v-list-item-group>
          </v-list>
          <v-list class="ml-5 pt-0" v-if="searchObj.searchZoneitems.length == 0">No data available</v-list>
        </v-card>
      </v-flex>
      <!-- Search Settling Office Html Code -->
      <v-flex xs4 class="ml-3 mr-3 totaldivBackgroundSearch">
        <v-text-field
          placeholder="Search Settling Office"
          solo
          dense
          class="forminput"
          :rules="searchOfficRule"
          v-model="searchObj.settlingModel" clearable  deletable-chips   @click:clear="getOfflineList"  @keyup="searchSettlingOfficeList(searchObj.settlingModel)"
        >
          <template v-slot:append class="my-0 mr-2">
            <v-icon class="buttonSearchIcon">mdi-magnify</v-icon>
          </template>
        </v-text-field>
        <v-card
          class="mx-auto overflowcard"
          max-width="800"
          max-height="240"
         
        >
           <v-list  v-if="searchObj.searchSettlingOfficeitems.length !==0">
              <v-list-item-group>
                 <v-list-item
                  class="vlistBorder mb-0 pb-0"
                  dense
                  active-class="blue--text text--accent-5"
                  @change="selectSettlingOffice()"
                 >
                  <template v-slot:default="{ active }">
                    <v-list-item-action>
                      <v-checkbox :input-value="active" disabled id="settlingselectallId"></v-checkbox>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>Select All</v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-list-item>
                 </v-list-item-group>
           </v-list>
          <v-list v-if="searchObj.searchSettlingOfficeitems">
            <v-list-item-group v-model="searchObj.searchSettlingOffice" multiple>
              <template v-for="(item, i) in searchObj.searchSettlingOfficeitems">
                <v-divider v-if="!item" :key="`divider-${i}`"></v-divider>
                <v-list-item
                  class="vlistBorder"
                  v-else
                  :key="`item-${i}`"
                  :value="item._id"
                  dense
                  active-class="blue--text text--accent-5"
                >
                  <template v-slot:default="{ active }">
                    <v-list-item-action>
                      <v-checkbox :input-value="active" disabled></v-checkbox>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title v-text="item.settlementOfficeName"></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-list-item>
              </template>
            </v-list-item-group>
          </v-list>
             <v-list class="ml-5 pt-0" v-if="searchObj.searchSettlingOfficeitems.length == 0">No data available</v-list>
        </v-card>
      </v-flex>
      <!-- Search Examiner Html Code -->
      <v-flex xs4 class="mr-3 totaldivBackgroundSearch">
        <v-text-field
          placeholder="Search Examiner"
          solo
          dense
          class="forminput"
          :rules="searchExaminerRule"
          deletable-chips 
           v-model="searchObj.examinerModel" clearable @click:clear="getExaminerList"  @keyup="searchExaminerList(searchObj.examinerModel)"
        >
          <template v-slot:append class="my-0 mr-2">
            <v-icon class="buttonSearchIcon">mdi-magnify</v-icon>
          </template>
        </v-text-field>
        <v-card
          class="mx-auto overflowcard"
          max-width="800"
          max-height="240"
         
        >
           <v-list  v-if="searchObj.searchExamineritems.length !==0">
              <v-list-item-group>
                 <v-list-item
                  class="vlistBorder mb-0 pb-0"
                  dense
                  active-class="blue--text text--accent-5"
                   @change="selectExaminerlistItem()"
                 >
                  <template v-slot:default="{ active }">
                    <v-list-item-action>
                      <v-checkbox :input-value="active" disabled id="examinerselectallId"></v-checkbox>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>Select All</v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-list-item>
              </v-list-item-group>
           </v-list>
          <v-list v-if="searchObj.searchExamineritems">
            <v-list-item-group v-model="searchObj.searchExaminer" multiple>
              <template v-for="(item, i) in searchObj.searchExamineritems">
                <v-divider v-if="!item" :key="`divider-${i}`"></v-divider>
                <v-list-item
                  class="vlistBorder"
                  v-else
                  :key="`item-${i}`"
                  :value="item._id"
                  dense
                  active-class="blue--text text--accent-5"
                >
                  <template v-slot:default="{ active }">
                    <v-list-item-action>
                      <v-checkbox :input-value="active" disabled></v-checkbox>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title v-text="item.employeeName"></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-list-item>
              </template>
            </v-list-item-group>
          </v-list>
             <v-list class="ml-5 pt-0" v-if="searchObj.searchExamineritems.length == 0">No data available</v-list>
        </v-card>
      </v-flex>
    </v-layout>


    <!-- Assigned to me and Team HTML buttons -->
    <v-layout class="whitebackground lessflex">
      <v-row>
        <v-col cols="4">
          <v-card color="#01BBD9" class="rectboard dashcard">
            <img
              src="../../assets/images/Group10692.png"
              width="50"
              height="50"
            />
            <div class="card_txt">
              <div class="textFontSize">Assigned</div>
              <div class="textFontSize">To Me</div>
            </div>
            <div class="card_count" @click="showMyClaimList">
              {{ myClaimCount }}
            </div>
            <!-- </v-row> -->
          </v-card>
        </v-col>

        <v-col cols="4">
          <v-card color="#01BBD9" class="rectboard dashcard">
            <img
              src="../../assets/images/Group10688.png"
              width="50"
              height="50"
            />
            <div class="card_txt">
              <div class="textFontSize">Assigned</div>
              <div class="textFontSize">To My Team</div>
            </div>
            <div class="card_count" @click="showMyTeamsClaimList">
              {{ myTeamClaimCount }}
            </div>
          </v-card>
        </v-col>

        <v-col cols="4">
          <v-card color="#01BBD9" class="rectboard dashcard">
            <img
              src="../../assets/images/Group10691.png"
              width="50"
              height="50"
            />
            <div class="card_txt approveflex">
              <div class="textFontSize">Assigned</div>
              <div class="textFontSize">For My Approval</div>
            </div>
            <div class="card_count" @click="showMyApprovalList">
              {{ myApprovalCount }}
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-layout>

    <div class="search_result_txt graybackground font-weight-bold">
      <v-row>
        <v-col md-8>
          <p style="margin-top: 2%" class="ml-3">Search Results</p>
        </v-col>
        <v-col md-4>
          <v-btn
            medium
            right
            class="filterbtn"
           @click="openModalfilter"
          >
            <v-icon> mdi-magnify </v-icon>
            <div class="vertical_line"></div>
            Filter
          </v-btn>
        </v-col>
      </v-row>
    </div>

    <v-flex id="claimListTable" class="claimtablelist">
      <template>
        <v-data-table
          :headers="claimColumnHeader"
          :items="claimTableList"
          hide-default-footer
          class="vDataTableStyling"
        >
          <template
            v-for="header in claimColumnHeader.filter((header) =>
              header.hasOwnProperty('formatter')
            )"
            v-slot:[`item.${header.value}`]="{ value }"
          >
            {{ header.formatter(value) }}
          </template>
          <template v-slot:header.select="{ header }">
                <v-checkbox class="workitemcheck white--text"  dark color=" #F7F7F7" @click="selectAllWorkItem"  :label="header.text" ></v-checkbox>
            </template>
         
          <template v-slot:item="{ item, index }">
            <tr style="cursor: pointer">
              <td class="colborder" @click="goToClaimMainPage(index)">{{ item.isTempClaim != true ? item.mainClaimNumber : "" }} </td>
              <td class="colborder" @click="goToClaimMainPage(index)">{{ item.isTempClaim != true ? item.featureNumber : "" }}</td>
              <td class="colborder" @click="goToClaimMainPage(index)">{{ item.claimStatus }}</td>
              <td class="colborder">{{ item.examiner }}</td>
              <td class="colborder">{{ item.insuredName }}</td>
              <td class="colborder">{{ item.policyNumber }}</td>
              <td class="colborder">{{item.dateReported ? new Date(item.dateReported).toLocaleDateString("en-IN"): ""}}</td>
              <td class="colborder">{{item.dateOfLoss ? new Date(item.dateOfLoss).toLocaleDateString("en-IN"): ""}} </td>
              <td class="colborder">{{ item.externalAdjInvestigator }}</td>
              <td class="colborder">{{ item.outStandingReserve }}</td>
              <td class="colborder">{{ item.indemnityStatus }}</td>
              <td class="colborder">{{ item.settlingOffice }}</td>
              <td class="colborder">{{ item.productName }}</td>
              <td class="colborder">{{ item.coverageDescription }}</td>
              <td class="colborder">{{ item.dateOfLastActivityOnClaim }}</td>
              <td class="colborder">{{ item.estimatedLossAmountInINR }}</td>
              <td class="colborder">{{ item.assignedBy }}</td>
              <!-- Sprint4 -->
              <td class="colborder">
                <v-checkbox
                   class="workitemcheck"
                   v-model="item.select"
                  @click="selectClaimNumbers(item.select, index)"
                ></v-checkbox>
              </td>
              <td class="colborder">
                <v-btn
                  class="buttonscolor mr-5"
                  dark
                  max-height="25"
                  @click="openAssignPage(index)"
                >
                  Re-assign</v-btn
                >
              </td>
              <!-- <v-icon class="mr-2 font-weight-bold" style="font-size: 15px">mdi-eye</v-icon> <div class="vertical_lineBlack"></div> -->
            </tr>
          </template>
        </v-data-table>
      </template>

      <div class="text-center graybackground pdct-stUp-bnkAcc-pgn">
        <v-row>
          <v-col cols="6"></v-col>
          <v-col cols="6">
            <v-row
              class="paginationrow"
            >
              <v-spacer></v-spacer>
              <div class="mr10" >
                <span class="orangetext">
                  Showing {{ pageStartIndex }}-{{ pageEndIndex }}
                </span>
                <span> out of {{ totalListCount }} records </span>
              </div>
              <v-pagination
                v-model="page"
                :length="getNoOfPages"
                :total-visible="5"
                prev-icon="mdi-arrow-left"
                next-icon="mdi-arrow-right"
                color="#152F38"
                @input="NextPage"
              ></v-pagination>
            </v-row>
          </v-col>
        </v-row>
        <br />
      </div>
    </v-flex>
    <v-overlay :absolute="absolute" :value="overlay">
      <v-progress-circular
        :size="70"
        color="#01BBD9"
        indeterminate
      ></v-progress-circular>
    </v-overlay>

    <v-row justify="center">
      <v-dialog v-model="searchresultmodal" persistent max-width="70%">
        <v-card>
          <v-card-title class="pl-0 px-0 py-0 searchdialog">
            <v-row>
              <span class="descrptionSpan mb-5 ml-7">Search Details</span>
              <v-icon @click="closeModal" class="ml-3 mt-1">mdi-window-close</v-icon>
            </v-row>
            <v-card-text class="graybackground">
              <v-form id="" ref="searchform">
                <v-row class="mt-1">
                  <v-col class="inputwidth">
                    <v-flex class="mb-2 claimlabel">Claim Status</v-flex>
                    <v-select
                      placeholder="Select"
                      solo
                      dense
                      required
                      :items="claimStatus"
                      v-model="filterData.claimStatus"
                      clearable
                    ></v-select>
                  </v-col>
                  <v-col class="inputwidth">
                    <v-flex class="mb-2 claimlabel">Insured Name</v-flex>
                    <v-text-field
                      placeholder="Enter..."
                      solo
                      class="forminput"
                      type="text"
                      required
                      v-model="filterData.insuredName"
                      :rules="[insuredrule(filterData.insuredName)]"
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col class="inputwidth">
                    <v-flex class="mb-2 claimlabel">Claim No</v-flex>
                    <v-text-field
                      placeholder="Enter..."
                      maxlength="100"
                      solo
                      class="forminput"
                      type="text"
                      v-model="filterData.claimNumber"
                      required
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col class="inputwidth">
                    <v-flex class="mb-2 claimlabel">Feature No</v-flex>
                    <v-text-field
                      placeholder="Enter..."
                      maxlength="150"
                      solo
                      class="forminput"
                      type="text"
                      dense
                      v-model="filterData.featureNumber"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row class="mt-1">
                  <v-col class="inputwidth">
                    <v-flex class="mb-2 claimlabel">Policy Number</v-flex>
                    <v-text-field
                      placeholder="Enter..."
                      solo
                      class="forminput"
                      type="text"
                      required
                      v-model="filterData.policyNumber"
                      :rules="[policyrule(filterData.policyNumber)]"
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col class="inputwidth">
                    <v-flex class="mb-2 claimlabel">Product Name</v-flex>
                    <v-text-field
                      placeholder="Enter..."
                      maxlength="100"
                      solo
                      class="forminput"
                      type="text"
                      required
                      dense
                      v-model="filterData.productName"
                    ></v-text-field>
                  </v-col>
                  <v-col class="inputwidth">
                    <!-- :rules="rulesValidation.CatastropheDeatilsRules" -->
                    <v-flex class="mb-2 claimlabel"
                      >Estimated loss amount (INR)</v-flex
                    >
                    <v-text-field
                      placeholder="Enter..."
                      maxlength="150"
                      solo
                      class="forminput"
                      type="text"
                      dense
                      v-model="filterData.estimatedLossAmountInINR"
                      :rules="[numeric(filterData.estimatedLossAmountInINR)]"
                    ></v-text-field>
                  </v-col>
                  <v-col class="inputwidth">
                    <v-flex class="mb-2 claimlabel"
                      >Coverage Description</v-flex
                    >
                    <v-text-field
                      placeholder="Enter..."
                      maxlength="100"
                      solo
                      class="forminput"
                      type="text"
                      required
                      dense
                      v-model="filterData.coverageDescription"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row class="mt-1">
                  <v-col class="inputdatefield">
                    <v-flex class="mb-2 claimlabel">Date Reported</v-flex>
                    <v-menu
                      v-model="dateOfReportedmenu"
                      :close-on-content-click="false"
                      offset-y
                      min-width="auto"
                    >
                      <!-- computedDateofloss -->
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          :value="computedDateofReported"
                          placeholder="DD/MM/YYYY"
                          dense
                          solo
                          readonly
                          v-on="on"
                          class="forminput"
                          id="lossTime"
                          append-icon="mdi-menu-down"
                        >
                          <template v-slot:prepend-inner class="mx-0 my-0">
                            <v-icon class="iconstyle">mdi-calendar</v-icon>
                          </template>
                        </v-text-field>
                      </template>
                      <v-date-picker
                        :max="todaydatevalue"
                        no-title
                        v-model="dateOfReported"
                        @input="dateOfReportedmenu = false"
                      >
                      </v-date-picker>
                    </v-menu>
                  </v-col>

                  <v-col class="inputdatefield">
                    <v-flex class="mb-2 claimlabel">Date Of Loss</v-flex>
                    <v-menu
                      v-model="dateOfLossmenu"
                      :close-on-content-click="false"
                      offset-y
                      min-width="auto"
                    >
                      <!-- computedDateofloss -->
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          :value="computedDateofloss"
                          placeholder="DD/MM/YYYY"
                          dense
                          solo
                          readonly
                          v-on="on"
                          class="forminput"
                          id="lossdate"
                          append-icon="mdi-menu-down"
                        >
                          <template v-slot:prepend-inner class="mx-0 my-0">
                            <v-icon class="iconstyle">mdi-calendar</v-icon>
                          </template>
                        </v-text-field>
                      </template>
                      <v-date-picker
                        :max="todaydatevalue"
                        no-title
                        v-model="dateOfLoss"
                        @input="dateOfLossmenu = false"
                      >
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-form>

              <!-- back and proceed  code-->
              <v-flex justify="center" class="mt-5">
                <v-row class="btnDiv">
                  <v-col align="center">
                    <v-btn
                      color="#23B1A9"
                      @click="SearchData(1)"
                      dark
                      class="buttonscolor mr-5"
                    >
                      <v-icon class="mr-2" style="font-size: 16px">
                        mdi-magnify
                      </v-icon>
                      <div class="vertical_line"></div>
                      Search
                    </v-btn>
                    <v-btn
                      color="#E46A25"
                      dark
                      class="buttonscolor mr-5"
                      @click="clearData()"
                    >
                      <v-icon class="mr-2 clearfont">
                        mdi-close-circle
                      </v-icon>
                      <div class="vertical_line"></div>
                      Clear
                    </v-btn>
                  </v-col>
                </v-row>
              </v-flex>
            </v-card-text>
          </v-card-title>
        </v-card>
      </v-dialog>
    </v-row>
    <!-- Re-assign to Examiner Dailogbox -->
 <v-row justify="center">
    <v-dialog v-model="ReassigntoExaminerMenu" persistent max-width="30%">
      <v-card>
        <v-card-title class="pl-0 px-0 py-0 graybackground">
          <v-row>
            <span class="descrptionSpan mb-5 ml-7" style="width: 80%;">Re-assign to Examiner<span class="mandatorycolor"> *</span></span>
            <v-icon @click="ReassigntoExaminerMenu = false" class="ml-3 mt-1">mdi-window-close</v-icon>
          </v-row>
          <v-card-text class="graybackground">
            <v-form ref="FormvalidExaminer">
              <v-row>
                <v-col cols="12">
                <v-autocomplete
                        :items="Examineritems"
                        solo
                        dense
                        class="forminput examinerstyle"
                        clearable
                        placeholder="Search Examiner"
                        
                        v-model="examinerName"
                        :rules='examinerNameRule'
                ></v-autocomplete>
                </v-col>
              </v-row>
            <!-- back and proceed  code-->
            <v-flex justify="center" class="mt-5">
              <v-row class="btnDiv mb-6">
                <v-col align="center">
                  <v-btn
                    color="#152F38"
                    dark
                    class="buttonscolor mr-5 btnwidth"
                    
                    type="submit"
                    @click.prevent="reassigntoExaminer"
                  >
                    <v-icon class="mr-2 clearfont" >
                      mdi-plus
                       <!-- mdi-account-multiple-plus -->
                    </v-icon>
                    <div class="vertical_line"></div>
                    Re-assign
                  </v-btn>
                  <v-btn
                    color="#E46A25"
                    dark
                    class="buttonscolor mr-5"
                    @click="ReassigntoExaminerMenu = false"
                  >
                    <v-icon class="mr-2 clearfont" >
                      mdi-close-circle
                    </v-icon>
                    <div class="vertical_line"></div>
                    Close
                  </v-btn>
                </v-col>
              </v-row>
            </v-flex>
            </v-form>
          </v-card-text>
        </v-card-title>
      </v-card>
    </v-dialog>
  </v-row>
  </v-app>
</template>

<script>
import moment from 'moment'
export default {
    data(){
        return {
            overlay         :   false,
            absolute        :   true,
            providerName  :   "",
            myClaimCount  :    0,
            myTeamClaimCount:   0,
            myApprovalCount :   0,
            claimColumnHeader:  [
                 {text: 'Claim No.', align: 'start',value: 'mainClaimNumber', class:"headercolor"},
                 {text: 'Feature No.', align: 'start',value: 'featureNumber', sortable:false},
                 {text: 'Claim Status', align: 'start',value: 'claimStatus', sortable:false},
                 {text: 'Examiner', align: 'start',value: 'examiner', sortable:false},
                 {text: 'Insured Name', align: 'start',value: 'insuredName', sortable:false},
                 {text: 'Policy Number', align: 'start',value: 'policyNumber', sortable:false},
                //  {text: 'Date Reported', align: 'start',value: 'reportedDate',},
                 {text: 'Date Reported', align: 'start',value: 'dateReported',},//new varaibale change for workitem table
                 {text: 'Date Of Loss', align: 'start',value: 'dateOfLoss',sortable:false},
                 {text: 'External Adjuster/Investigator', align: 'start',value: 'externalAdjInvestigator',sortable:false},
                 {text: 'Outstanding Reserve', align: 'start',value: 'outStandingReserve',sortable:false},
                 {text: 'Indemnity Status', align: 'start',value: 'indemnityStatus ',sortable:false},
                 {text: 'Settling Office', align: 'start',value: 'settlingOffice',sortable:false},
                 {text: 'Product Name', align: 'start',value: 'productName',sortable:false},
                 {text: 'Coverage Description', align: 'start',value: 'coverageDescription',sortable:false},
                 {text: 'Date Of Last Activity On Claim', align: 'start',value: 'dateOfLastActivityOnClaim',sortable:false},
                 {text: 'Estimated loss amount (INR)', align: 'start',value: 'estimatedLossAmountInINR',},
                {text: 'Assigned By', align: 'start',value: 'assignedBy',sortable:false},
                {text: 'Select', align: 'start',value: 'select',sortable:false},
                {text: 'Re-assign', align: 'start',value: 'assigne',sortable:false},
             ],
            claimList       :   [],
            myTeamClaimList :   [],   
            myClaimApprovalList :   [],        
            claimTableList  :   [],                    
            page            :   1,
            pageStartIndex  :   1,
            pageRowCount  :   10,
            totalListCount  :   0,
            singleExpand    :   false,
            selectedCheckboxes        :   [],
            contentWidth       : 1800,
            searchresultmodal:false,
            claimStatus:["Suspended", "Open", "Closed", "Cancelled"],
            filterData: {
                 claimStatus:'',
                 insuredName : '',
                 claimNumber:'',
                 featureNumber:'',
                 policyNumber:'',
                 productName:'',
                 estimatedLossAmountInINR:'',
                 coverageDescription:'',
                 dateOfLoss : '',
                 dateOfReported : '',
            },
            dateOfLossmenu: false,
            dateOfLoss:null,
            dateOfReportedmenu:false,
            dateOfReported:null,
            IsFilter:false,
            Datepattern: /^(((0|1)[0-9]|2[0-9]|3[0-1])\/(0[1-9]|1[0-2])\/((19|20)\d\d))$/,
            rules:{
            //   insuredName:[
            //     // v => !!v || 'This field is required',
            //     // v => v && !!v.trim() || 'Only spaces are not allowed',
            //     v => (v && v.length <= 100) || 'Insured Name should be less than 100 characters.',
            //     v => /^\S[a-zA-Z0-9\s\/]+\S$/.test(v) || 'Insured Name is not valid'
            //   ],
            //    dateofLossRules:[
            //     // (v) => !!v || this.validationmessage,
            //     // (v) => this.Datepattern.test(v) || "Enter valid date format as DD/MM/YYYY.",
            //     (v) => !!this.pastDateCheck(v) || "Future dates not allowed.",
            //   ],
            },
            // sprint code start hear
            searchListOptionMenu:false,
             searchObj:{
                searchZoneitems: [],
                searchSettlingOfficeitems: [],
                searchExamineritems: [],
                searchZone: [],
                searchSettlingOffice: [],
                searchExaminer: [],
                zoneModel:"",
                settlingModel:"",
                examinerModel:"",
            },
            ReassigntoExaminerMenu:false,
            examinerNameRule:[(v) => !!v || 'This is required',],
            examinerName:'',
            reAssignClaimList : [],
            Examineritems: [],
            searchzoneRule:[  v => (v && v.length > 2)  || 'Enter Minimum 3 characters.'],
            searchOfficRule:[  v => (v && v.length > 2)  || 'Enter Minimum 3 characters.'],
            searchExaminerRule:[  v => (v && v.length > 2)  || 'Enter Minimum 3 characters.'],

        }
    },
    
    methods:{
      selectAllWorkItem(){
          
      },
       searchZoneList(value){
             if(value.length > 2){
               let request="zone="+value;
                this.GET("GET_ZONE_LIST", request, (res) => {
                    this.searchObj.searchZoneitems=[];
                    this.searchObj.searchZone=[];
                  if (res.data.data) {
                      this.searchObj.searchZoneitems = res.data.data;
                  }
                }, (error) => {
                    console.log("error--->",error);
                     this.searchObj.searchZoneitems=[];
                     this.searchObj.searchZone=[];
                },"commonModuleApi")
             }else if(value==""){
               this.getZoneList();
             }
           },
           searchSettlingOfficeList(value){
             if(value.length > 2){
             let request="&pageNo=1&perPage=100&settlementOfficeName="+value;
                this.GET("GET_SETTLEMNET_OFFICELIST", request, (res) => {
                    this.searchObj.searchSettlingOffice=[];
                    this.searchObj.searchSettlingOfficeitems=[];
                  if (res.data.data &&  res.data.data.data) {
                      this.searchObj.searchSettlingOfficeitems= res.data.data.data;
                  }
                }, (error) => {
                     console.log("error--->",error);
                     this.searchObj.searchSettlingOfficeitems=[];
                     this.searchObj.searchSettlingOffice=[];
                },"commonModuleApi")
             }else if(value==""){
                this.getOfflineList();
             }
           },
           searchExaminerList(value){
                if(value.length > 2){
              let request="role=Examiner&offlineStatus=false&pageNo=1&perPage=100&employeeName="+value;
                this.GET("GET_ALLEXAMIERLIST", request, (res) => {
                    this.searchObj.searchExamineritems=[];
                    this.searchObj.searchExaminer=[];
                  if (res.data.data && res.data.data.data) {
                    this.searchObj.searchExamineritems = res.data.data.data;
                  }
                }, (error) => {
                     this.searchObj.searchExamineritems=[];
                     this.searchObj.searchExaminer=[];
                    console.log("error--->",error);
                },"commonModuleApi")
                }else if(value==""){
                   this.getOfflineList();
              }
           },

          selectSettlingOffice(){
             let selectall=document.getElementById("settlingselectallId").checked;
              if(selectall == false){
                this.searchObj.searchSettlingOfficeitems.forEach((element) => {
                this.searchObj.searchSettlingOffice.push(element._id)})
              }else {
                this.searchObj.searchSettlingOffice=[];
              }
          },
          selectZone(){
              let selectall=document.getElementById("zoneselectallId").checked;
              if(selectall == false){
                this.searchObj.searchZoneitems.forEach((element) => {
                this.searchObj.searchZone.push(element)})
              }else{
                this.searchObj.searchZone=[];
              }
          },
          selectExaminerlistItem(){
            let selectall=document.getElementById("examinerselectallId").checked;
              if(selectall == false){
                this.searchObj.searchExamineritems.forEach((element) => {
                this.searchObj.searchExaminer.push(element._id)})
              }else{
                 this.searchObj.searchExaminer=[]
              }
          },
          
          getZoneList(){
                this.searchObj.searchZone=[];
                this.searchObj.searchZoneitems=[];
                let requestZoneData="zone=";
               this.GET("GET_ZONE_LIST", requestZoneData, (res) => {
                  if (res.data.data) {
                     this.searchObj.searchZoneitems = res.data.data;
                  }
                }, (error) => {
                    console.log("error--->",error);
                },"commonModuleApi")
          },
          getOfflineList(){
                 this.searchObj.searchSettlingOffice=[];
                this.searchObj.searchSettlingOfficeitems=[];
                 let requestSettlementData="&pageNo=1&perPage=100&settlementOfficeName="
                 this.GET("GET_SETTLEMNET_OFFICELIST", requestSettlementData, (res) => {
                  if (res.data.data && res.data.data.data) {
                     this.searchObj.searchSettlingOfficeitems = res.data.data.data;
                  }
                }, (error) => {
                     console.log("error--->",error);
                },"commonModuleApi")
          },
        
          getExaminerList() {
                this.searchObj.searchExaminer=[];
                let requestExaminerData="role=Examiner&offlineStatus=false&pageNo=1&perPage=50&employeeName="
                 this.GET("GET_ALLEXAMIERLIST", requestExaminerData, (res) => {
                  if (res.data.data && res.data.data.data) {
                    this.searchObj.searchExamineritems = res.data.data.data;
                    this.Examineritems=res.data.data.data;
                  }else{
                     this.searchObj.searchExamineritems=[];
                     this.Examineritems=[];
                  }
                }, (error) => {
                    this.searchObj.searchExamineritems=[];
                    this.Examineritems=[];
                    console.log("error--->",error);
                },"commonModuleApi")
              } ,
        reassigntoExaminer(){
         if (this.$refs.FormvalidExaminer.validate() ){
            this.ReassigntoExaminerMenu=false;
            this.examinerName="";

            let reqData   = {
              "assignToId": "603e37a47681351ab41cb574",
              "assignToName": "Shubham",
              "workItems": this.reAssignClaimList
            }

            this.POSTM("WORKITEM_ASSIGNMENT",reqData,(res)=>{
              this.hideProgress();
              console.log("response--->",res);
              this.showAlert("Claim(s) assigned successfully.");
              this.deselectAll();
            },(error)=>{
              this.hideProgress();
              console.log("error response--->",error.response);
              this.showAlert(error.response.data.msg);
            })

          }
        },
        selectClaimNumbers(item,index){
          if(item){
            this.reAssignClaimList.push({"_id":this.claimTableList[index]._id,"claimId":this.claimTableList[index].claimId,"mainClaimNumber":this.claimTableList[index].mainClaimNumber,"featureNumber":this.claimTableList[index].featureNumber});
          }else{
            let self  = this;
            this.reAssignClaimList.splice(this.reAssignClaimList.findIndex(function(elem){return elem._id == self.claimTableList[index]._id}),1)
          }            
        },
        deselectAll(){
          this.claimTableList.map((elem)=>{
            elem.select = false;
          });
          this.reAssignClaimList  = [];
        },
        openAssignPage(index){
          if(this.reAssignClaimList.length){
            this.ReassigntoExaminerMenu=true;
            //this.getExaminerList();
          }else{
            this.showAlert("Please select atleast 1 claim to assign/re-assign.")
          }
          
        },
        searchProvider(){
            // this.goToNextPage(1);
        },
        clearSearch(){
          this.providerName   =   "";
          //sprint4
          this.searchListOptionMenu=false;
          this.getZoneList();
          this.getOfflineList();
          this.getExaminerList();
          this.searchObj.searchSettlingOffice=[];
          this.searchObj.searchZone=[];
          this.searchObj.searchExaminer=[];
        },
        openCloseFilters(){
            console.log("Clicked");
             this.searchListOptionMenu =!this.searchListOptionMenu;//sprint4
        },
        showProgress(){
            this.overlay    =   true;
        },
        hideProgress(){
            this.overlay    =   false;
        },
        goToNextPage(newPageNum){
            
            // this.claimTableList =   this.claimList.slice(this.pageStartIndex,this.pageStartIndex + this.pageRowCount)
            console.log("Event - ",event,this.page);
            this.showProgress();
            let reqData =   "perPage="+this.pageRowCount+"&pageNo="+newPageNum;
            this.GETM("WORKITEM_SEARCH",reqData,(res)=>{
                this.hideProgress()
                console.log("WORKITEM_SEARCH - ",res);
                if(res.data && res.data.data && res.data.data.length > 0){
                    this.claimList      =   res.data.data;
                    this.totalListCount =   res.data.total;
                    this.myClaimCount   =   res.data.total;
                    this.claimTableList =   this.claimList.length > 0 ? this.claimList.slice(0,this.pageRowCount) : [];
                    this.pageStartIndex =   ((newPageNum - 1) * this.pageRowCount);
                    console.log("clainTableList - ",this.claimTableList);
                     this.hideProgress()
                }else if(res.data && res.data.data && res.data.data.data && res.data.data.data.length > 0){
                    this.claimList      =   res.data.data.data;
                    this.totalListCount =   res.data.data.total;
                    this.myClaimCount   =   res.data.data.total;
                    this.claimTableList =   this.claimList.length > 0 ? this.claimList.slice(0,this.pageRowCount) : [];
                    this.pageStartIndex =   newPageNum == 1 ? 1 : ((newPageNum - 1) * this.pageRowCount) + 1;
                    console.log("clainTableList - ",this.claimTableList);
                     this.hideProgress()
                }else{
                    this.claimList      =   [];
                    this.totalListCount =   0;
                    this.myClaimCount   =   0;
                    this.claimTableList =   [];
                    this.pageStartIndex =   0;
                }
                
            },(err)=>{
                    this.hideProgress()
                    console.log("WORKITEM_SEARCH Err -",err);
                    this.claimList      =   [];
                    this.totalListCount =   0;
                    this.myClaimCount   =   0;
                    this.claimTableList =   [];
                    this.pageStartIndex =   0; 
                    this.showAlert(err.response.data.msg)
               
            });
        },
        goToClaimMainPage(elemIndex){
            var selectedRow     =   this.claimTableList[elemIndex];
                let selected={
                        claimType:selectedRow.claimType,
                        claimId:selectedRow.claimId
                    }
             this.$store.commit("SAVE_LANDINGPAGE_ID", {data: selected});
             this.$store.commit("FNOL_PAGE_EDIT_CHECK", "FnolEditPage");
                if(selectedRow.isTempClaim==true){
                    this.$router.push("/fnol");
                }
                else{
                   this.$router.push("/claimmainscreen");
                }
        },
        showMyClaimList(){
            console.log(" showMyClaimList ")
            this.goToNextPage(1);
        },
        showMyTeamsClaimList(){
            // console.log(" showMyTeamsClaimList ")
            // this.page           =   1;
            // this.pageStartIndex =   0;
            // this.totalListCount =   this.myTeamClaimList.length;
            // this.myTeamClaimCount=   this.myTeamClaimList.length;            
            // this.claimTableList =   this.myTeamClaimList.slice(0,this.pageRowCount);
        },
        showMyApprovalList(){
            // console.log(" showMyApprovalList ")
            // this.page           =   1;
            // this.pageStartIndex =   0;
            // this.totalListCount =   this.myClaimApprovalList.length;
            // this.myApprovalCount =   this.myClaimApprovalList.length;
            // this.claimTableList =   this.myClaimApprovalList.slice(0,this.pageRowCount);
        },
         handleScroll(event) {
             console.log("scroll event - ",event)
            if (event.target._prevClass === 'content') {
                this.$refs.topScroll.scrollLeft = this.$refs.content.scrollLeft
            } else {
                this.$refs.content.scrollLeft = this.$refs.topScroll.scrollLeft
            }
        },
        openModalfilter(){
          this.searchresultmodal=true
          this.IsFilter=true
        },
        policyrule(v){
                //   if(!this.claimDetails.withoutpolicyno && !v){
                //     return this.validationmessage
                //   }else
                if(v){
                 if(v && !(/^[a-zA-Z0-9]+$/.test(v))){
                    return 'It is not valid'
                  }else if(v.length > 15){
                    return 'Policy Number should be less than 15 characters.'
                  }else{
                    return true;
                  }  
                }           
            },
             numeric(v){
              if(v && !(/^[0-9]+$/.test(v))){
                  return 'Only Numbers are required'
              }else{
                return true;
              }
            },
            insuredrule(v){
                console.log(v)
               if(v && !(/^\S[a-zA-Z0-9\s\/]+\S$/.test(v))){
                  return 'Insured Name is not valid'
              }
              
              else{
                return true;
              } 
              
            },
            daterules(v){
               if(v){
                if(!this.Datepattern.test(v)){
                    return 'Enter valid date format as DD/MM/YYYY.'
                }
                if(!this.pastDateCheck(v)){
                    return 'Future dates not allowed.'
                }
                else{
                    return false;
                }
               }
            },
            pastDateCheck(date) {
                console.log('date',date)
              if (date!="") {
                date = moment(date, "DD/MM/YYYY").toDate().getTime();
                if (new Date(date).setHours(0, 0, 0, 0) <= new Date().setHours(0, 0, 0, 0)) {
                  return true;
                }
                return false;
              }
            },
            SearchData(newPageNum){
                
                
                        if(this.dateOfLoss!=null){
                            var dateloss = new Date(this.dateOfLoss).toISOString();
                            console.log('hjg',dateloss)
                            this.filterData.dateOfLoss = dateloss
                        }
                            
                            console.log(this.dateOfReported)
                           if(this.dateOfReported!=null){
                            var dateOfReported = new Date(this.dateOfReported).toISOString();
                            this.filterData.dateOfReported = dateOfReported;
                           }
                            
                console.log('final', this.filterData)
                 if(this.$refs.searchform.validate()){
                      if(this.filterData.claimStatus=="" && 
                      this.filterData.insuredName=="" && 
                      this.filterData.claimNumber=="" 
                      && this.filterData.featureNumber==""
                      && this.filterData.policyNumber==""
                      && this.filterData.dateOfReported==""
                      &&  this.filterData.dateOfLoss==""
                      && this.filterData.productName==""
                      && this.filterData.estimatedLossAmountInINR==""
                      && this.filterData.coverageDescription==""

                   ){
                           this.showAlert('Please fill atleast one field')
                      }
                else{
                    if(this.filterData.claimStatus!=null && this.filterData.claimStatus!=undefined && this.filterData.claimStatus!=""){
                        this.claim  = "&claimStatus=" + this.filterData.claimStatus
                        }
                    else{
                        this.claim=""
                        }
                    
                    if(this.filterData.claimNumber!=null && this.filterData.claimNumber!=undefined && this.filterData.claimNumber!=""){
                        this.claimNo  = "&mainClaimNumber=" + this.filterData.claimNumber
                        }
                    else{
                        this.claimNo=""
                        }
                
                    if(this.filterData.featureNumber!=null && this.filterData.featureNumber!=undefined && this.filterData.featureNumber!=""){
                        this.featureNo  = "&featureNumber=" + this.filterData.featureNumber
                        }
                    else{
                        this.featureNo=""
                        }

                    if(this.filterData.insuredName!=null && this.filterData.insuredName!=undefined && this.filterData.insuredName!=""){
                        this.insurname  = "&insuredName=" + this.filterData.insuredName
                        }
                    else{
                        this.insurname=""
                        }

                    if(this.filterData.policyNumber!=null && this.filterData.policyNumber!=undefined && this.filterData.policyNumber!=""){
                        this.policyNo  = "&policyNumber=" + this.filterData.policyNumber
                        }
                    else{
                        this.policyNo=""
                        }
                    
                    if(this.filterData.dateOfReported!=null && this.filterData.dateOfReported!=undefined && this.filterData.dateOfReported!=""){
                        this.reporteddate  = "&dateReported=" + this.filterData.dateOfReported
                        }
                    else{
                        this.reporteddate=""
                        }

                    if(this.filterData.dateOfLoss!=null && this.filterData.dateOfLoss!=undefined && this.filterData.dateOfLoss!=""){
                        this.lossdate  = "&dateOfLoss=" + this.filterData.dateOfLoss
                        }
                    else{
                        this.lossdate=""
                        }

                    if(this.filterData.productName!=null && this.filterData.productName!=undefined && this.filterData.productName!=""){
                        this.pname  = "&productName=" + this.filterData.productName
                        }
                    else{
                        this.pname=""
                        }

                    if(this.filterData.estimatedLossAmountInINR!=null && this.filterData.estimatedLossAmountInINR!=undefined  && this.filterData.estimatedLossAmountInINR!=""){
                        this.Eamount  = "&estimatedLossAmountInINR=" + this.filterData.estimatedLossAmountInINR
                        }
                    else{
                        this.Eamount=""
                        }

                    if(this.filterData.coverageDescription!=null && this.filterData.coverageDescription!=undefined && this.filterData.coverageDescription!=""){
                        this.covdic  = "&coverageDescription=" + this.filterData.coverageDescription
                        }
                    else{
                        this.covdic=""
                        }

                    let reqData =  "perPage="+this.pageRowCount+"&pageNo="
                        + newPageNum + this.claim +  this.claimNo + this.featureNo + this.insurname + this.policyNo +
                        this.reporteddate + this.lossdate + this.pname + this.Eamount + this.covdic
                          this.GETM("WORKITEM_SEARCH",reqData,(res)=>{
                            this.hideProgress()
                            console.log("search done - ",res);
                            if(res.data && res.data.data && res.data.data.length > 0){
                                this.claimList      =   res.data.data;
                                this.totalListCount =   res.data.total;
                                this.myClaimCount   =   res.data.total;
                                this.claimTableList =   this.claimList.length > 0 ? this.claimList.slice(0,this.pageRowCount) : [];
                                this.pageStartIndex =   ((newPageNum - 1) * this.pageRowCount);
                                console.log("searchTableList - ",this.claimTableList);
                                this.searchresultmodal=false
                            }else if(res.data && res.data.data && res.data.data.data && res.data.data.data.length > 0){
                                this.claimList      =   res.data.data.data;
                                this.totalListCount =   res.data.data.total;
                                this.myClaimCount   =   res.data.data.total;
                                this.claimTableList =   this.claimList.length > 0 ? this.claimList.slice(0,this.pageRowCount) : [];
                                this.pageStartIndex =   newPageNum == 1 ? 1 : ((newPageNum - 1) * this.pageRowCount) + 1;
                                console.log("searchTableList - ",this.claimTableList);
                                this.searchresultmodal=false
                            }else{
                               this.showAlert('Search data not found')
                            }
                            
                        },(err)=>{
                            this.hideProgress()
                            console.log("searchTableList Err -",err);
                            this.showAlert(err.response.data.msg,false)
                            
                        });
                      }
                 }
                 else{
                  
                 }
            },
            clearData(){
                this.$refs.searchform.reset()
                // this.goToNextPage(1)
                //  this.searchresultmodal=false
                 this.IsFilter=false
                 this.filterData={
                 claimStatus:'',
                 insuredName : '',
                 claimNumber:'',
                 featureNumber:'',
                 policyNumber:'',
                 productName:'',
                 estimatedLossAmountInINR:'',
                 coverageDescription:'',
                 dateOfLoss : '',
                 dateOfReported : '',
                 }
                 this.dateOfLoss=null
                 this.dateOfReported=null
               },
               closeModal(){
                   this.$refs.searchform.reset()
                this.goToNextPage(1)
                 this.searchresultmodal=false
                 this.IsFilter=false
                 this.filterData={
                 claimStatus:'',
                 insuredName : '',
                 claimNumber:'',
                 featureNumber:'',
                 policyNumber:'',
                 productName:'',
                 estimatedLossAmountInINR:'',
                 coverageDescription:'',
                 dateOfLoss : '',
                 dateOfReported : '',
                 }
                 this.dateOfLoss=null
                 this.dateOfReported=null
               },
               NextPage(){
                   
                   if(this.IsFilter==false){
                       console.log(this.page)
                      this.goToNextPage(this.page)
                   }
                   else{
                     this.SearchData(this.page)
                   }
               }
            
    },
    computed:{
        claimColumnList(){
            return Object.keys(this.claimList[0]);
        },
        getNoOfPages(){
            if(!this.totalListCount){
                return 0;
            }else{
                return Math.ceil(this.totalListCount / this.pageRowCount);
            }
        },
        pageEndIndex(){
            if(!this.totalListCount){
                return 0
            }else{
                return ((this.pageStartIndex + (this.pageRowCount)) <= this.totalListCount) ? this.pageStartIndex + (this.pageRowCount) - 1 : this.totalListCount;
            }
        },
        computedDateofloss(){
          return this.dateOfLoss == null ? '' : moment(this.dateOfLoss).format('DD/MM/YYYY');
        },
        computedDateofReported(){
          return this.dateOfReported == null ? '' : moment(this.dateOfReported).format('DD/MM/YYYY');
        }
         
    },
    mounted(){
        this.pageStartIndex =   0;
        this.claimTableList =   this.claimList.slice(0,this.pageRowCount);
        this.goToNextPage(1);
    },
    created(){
      this.todaydatevalue = moment().format("YYYY-MM-DD");
        this.getZoneList();
        this.getOfflineList();
        this.getExaminerList();
    }
   
     
}
</script>

<style scoped>
/* gopi code start here */
.buttonscolor {
  border-radius: 0 !important;
  height: 30px !important;
  text-transform: capitalize;
  font-size: 15px;
  width: 110px !important;
}
.iconstyledropdown {
  margin-left: -12px;
  background-color: rgb(247, 247, 247);
  width: 45px;
  height: 38px;
  font-size:40px !important;
  border: 0.3px solid rgb(232, 235, 236);
  opacity: 1;
  /* box-shadow: rgba(0, 0, 0, 0.318) 0px 2px 2px; */
}
.vlistBorder {
  width: auto;
  box-shadow: 0px 2px 2px #00000051;
  border: 0.5px solid #c1c8cc;
  opacity: 1;
  margin-bottom: 10px;
  height: 10px;
  margin-right: 5px;
 
}
.buttonSearchIcon {
  margin-right: -12px;
  background-color: #23b1a9;
  width: 45px;
  height: 38px;
  font-size: 26px !important;
  opacity: 1;
  color: #fff !important;
  cursor: pointer !important;
}
.totaldivBackgroundSearch {
  background: #ffffff;
  box-shadow: 0px 2px 2px #00000051;
  border: 0.5px solid #c1c8cc;
  opacity: 1;
  padding: 20px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.textFontSize{
  font-size: 12px;
}

th {
    font-size: 12px !important;
    height: 34px !important;
    border: 0.5px ridge #f7f7f7;
    border-width: 1px;
    border-right: none;
}

td {
    height: 34px !important;
    font-size: 11px !important;
    color: #10242b;
    border: 0.5px ridge #f7f7f7;
    border-width: 1px;
    border-right: none;
}

/* gopi code end here */
.dashcard {
    display: flex;
    align-items: center;
    justify-content: space-around;
    /* padding: 10px; */
}
.lessflex {
    flex: none;
}

.v-btn {
    text-transform: none;
}
.search_result_txt {
    margin-left: 0px;
    /* font: normal normal bold 20px/24px Roboto; */
    /* padding:5px; */
    /* padding-bottom:5px */
}

.card_img {
    margin: 10px;
    padding: 15px;
    border-right: 1px solid #FFFFFF;
    width: 50px;
    height: 50px;
}

.card_txt{
    margin: 10px;
    padding: 10px;
    padding-top: 5px;
    border-right: 1px solid #FFFFFF;
    color: #FFFFFF;
    text-align: left;
    /* font: normal normal bold 18px/22px Roboto; */
    flex: 0.4;
    border-left:1px solid #FFFFFF;
    display: flex; 
    flex-direction:column;
    align-items: center;
    justify-content: space-between;
}

.card_count{
    margin: 10px;
    padding: 15px;    
    color: #FFFFFF;
    text-align: left;
    /* font: normal normal bold 40px/22px Roboto; */
}

.btn_margin{
    margin-left: 10px;
   
}

.v-btn {    
    color: #FFFFFF !important;
}

.whitebackground{
    padding: 20px;
}

.graybackground {
    background-color:  #F7F7F7;
}

.greencolor {
    background-color: #23B1A9;
}

.rectboard{
    /* width: 473px; */
    /* height: 120px; */
    background: #01BBD9 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 2px #00000051;
    border-radius: 5px;
}

.orangetext {
    color: #E46A25;
}
.colborder{
    border: 1px solid #DAE1E6;
}
.headercolor{
    white-space: nowrap;
    background-color:#23B1A9!important;
    font: normal normal bold 17px/20px Roboto;
    color:#FFFFFF
}
.vDataTableStyling {
    white-space: nowrap;
}
.descrptionSpan {
  width: 90%;
  margin-left: 15px;
  margin-top: 21px;
  margin-bottom: 14px !important;
  font-weight: bold;
}
 
.inputwidth {
  max-width: 325px;
  border-radius: 0 !important;
}
.inputdatefield{
  max-width: 231px;
}
.approveflex{
   flex:none !important;
}
.overflowcard{
  overflow-y: auto;
}
.filterbtn{
  margin: 10px;
  background-color: #e46a25 !important;
  color: #ffffff;
  float: right;
    }
.claimtablelist{
  overflow: hidden;
}
.paginationrow{
  margin-right: 10px;
  margin-top: 10px; 
  align-items: center
}
.searchdialog{
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 7px #00000029;
    opacity: 1;
    font-size: 14px;
}

.clearfont{
 font-size: 18px;
}
 .examinerstyle{
  width: 364px; 
 
 }
.workitemcheck{
  height: 33px; 
  margin-top: 0px
}
.mr10{
 margin-right: 10px
}
.btnwidth{
 width:130px !important
}
.btn_margin{
    margin-left: 10px;
   max-height: 39px !important;
}
.selectAlltext{
  color: #F7F7F7 !important;
}
</style>