<template>
    
    <v-app>
		<v-card class="">
            <div>
                <v-card class="pa-3 mb-1" style="" tile>
				<v-layout align-center class="py-2" >
							<v-text class="text-uppercase font-weight-bold title-clr-prtbs ml-2"
                            >Provider Search</v-text>
                        <v-divider vertical class="mx-4"></v-divider>

                        <!-- <div class="verticalline mt-2" style=""></div> -->
							<v-text class="font-weight-bold cstm-clr-prtbs ml-2" style="color:">Registered Entity</v-text>
						
                        <v-divider vertical class="mx-4"></v-divider>

                        <!-- <div class="verticalline mt-2" style=""></div> -->
                        <!-- <div class="pb-4" style="height: 40px;width: 357px; border: 0.5px solid #C1C8CC;" > -->
                            
                        <v-btn-toggle dense mandatory v-model="text" tile class="" style=""  >
                            <v-btn  value="indvdl" class="text-capitalize font-weight-bold  px-5" active-class=""
                                @click="showIndividualData()" style="white-space: normal;"
                            :class="[showIndividual === true ? 'activebackground' : 'inactivewhitebackground']">Individual
                            </v-btn>

                            <v-btn  value="instutn" class="text-capitalize font-weight-bold   px-5" active-class=""
                                @click="showInstitutionData()" style="white-space: normal;"
                            :class="[showInstitution === true ? 'activebackground' : 'inactivewhitebackground']">Institution
                            </v-btn>    
                        </v-btn-toggle>
                        
                                                
				</v-layout>
                </v-card>
            </div>

            <div class="ma-7" v-if="showIndividual === true">
                <v-form v-model="valid" ref="form">

                <v-layout class="">
                    <v-flex xs6>
                        <v-layout row class>
                            
                            

                            <!-- <v-flex style="flex: 1;">
                                <label style=" color: #646666; font-family: roboto; font-weight: 700;">First Name</label>
                                
                                <v-flex class="sel pt-2 pr-4">
                                    <v-text-field
                                        v-model="firstName"
                                        label="Enter..."
                                        dense
                                        solo
                                    ></v-text-field>
                                </v-flex>
                            </v-flex> -->
                            <v-flex style="flex: 1;" class="cust_pr mx-2 mr-3">
                                <label class="font-weight-bold cstm-clr-pr" style="color: #646666;">First Name Filter</label>
                                <v-flex class="">
                                    <v-select
                                        :items="selectFirstNameFilter"
                                        item-text="text"
                                        item-value="value"
                                        v-model="firstNameFilter"
                                        label="Contains"
                                        dense
                                        solo
                                        
                                        class="rounded-0"
                                    ></v-select>
                                </v-flex>
                            </v-flex>

                            <v-flex style="flex: 1;" class="cust_pr mx-2  mr-3">
                                <label class="font-weight-bold cstm-clr-pr" style=" color: #646666; ">First Name</label>
                                
                                <v-flex class=" ">
                                    <v-text-field
                                        v-model="firstName"
                                        label="Enter..."
                                        solo
                                        dense
                                        validFirstName
                                        :error-messages="validFirstName"
                                        counter="20"
                                        :maxlength="maxCount"
                                        
                                        class="rounded-0"
                                    ></v-text-field>
                                </v-flex>
                            </v-flex>
                        </v-layout>
                    </v-flex>

                    <v-flex xs6 class="ml-7">
                        <v-layout row class>
                            <v-flex style="flex: 1;" class="cust_pr mx-2  mr-3">
                                <label class="font-weight-bold cstm-clr-pr" style="color: #646666;">Last Name Filter</label>
                                <v-flex class="">
                                    <v-select
                                        :items="selectLastNameFilter"
                                        item-text="text"
                                        item-value="value"
                                        v-model="lastNameFilter"
                                        label="Contains"
                                        dense
                                        solo
                                        
                                        class="rounded-0"
                                    ></v-select>
                                </v-flex>
                            </v-flex>

                            <v-flex style="flex: 1;" class="cust_pr mx-2  mr-3">
                                <label class="font-weight-bold cstm-clr-pr" style="color: #646666;">Last Name</label>
                                <v-flex class="">
                                    <v-text-field
                                        v-model="lastName"
                                        label="Enter..."
                                        dense
                                        solo
                                        :error-messages="validLastName"
                                        counter="20"
                                        :maxlength="maxCount"
                                        
                                        class="rounded-0"
                                    ></v-text-field>
                                </v-flex>
                            </v-flex>
                        </v-layout>
                    </v-flex>
                </v-layout>

<!--                         
                            
                        

                    <v-flex xs6 class="ml-7">
                        <v-layout row class>
                            <v-flex style="flex: 1;" class="cust_pr mx-2  mr-3">
                                <label
                                    class="font-weight-bold cstm-clr-pr"
                                    style="color: #646666;">PAN
                                </label>
                                <v-flex class="">
                                    <v-text-field
                                        v-model="panNumber"
                                        label="Enter..."
                                        class="rounded-0"
                                        dense
                                        solo
                                        :rules="[rules.validPAN]"
                                    ></v-text-field>
                                </v-flex>
                            </v-flex>

                            <v-flex style="flex: 1;" class="cust_pr mx-2">
                                <label
                                   class="font-weight-bold cstm-clr-pr"
                                    style="color: #646666;">PRN
                                </label>
                                <v-flex class="">
                                    <v-text-field
                                        v-model="prnNumber"
                                        label="Enter..."
                                        dense
                                        solo
                                        :rules="[rules.minPRN]"
                                        class="rounded-0 "
                                    ></v-text-field>
                                </v-flex>
                            </v-flex>
                        </v-layout>
                    </v-flex> -->

            
                <v-layout>
                    <v-flex xs6>
                        <v-layout row class>
                            <v-flex style="flex: 1;" class="cust_pr mx-2 my-2 mr-3">
                                <label
                                    class="font-weight-bold cstm-clr-pr"
                                    style="color: #646666;">PAN
                                </label>
                                
                                <v-flex class="">
                                    <v-text-field
                                        v-model="panNumber"
                                        label="Enter..."
                                        class="rounded-0"
                                        dense
                                        solo
                                        
                                        :error-messages="validPanNo"
                                    ></v-text-field>
                                </v-flex>
                            </v-flex>
                            <v-flex style="flex: 1;" class="cust_pr mx-2 my-2 mr-3">
                                <label
                                   class="font-weight-bold cstm-clr-pr"
                                    style="color: #646666;">PRN
                                </label>
                                <v-flex class="">
                                    <v-text-field
                                        v-model="prnNumber"
                                        label="Enter..."
                                        dense
                                        solo
                                        :error-messages="validPrnNo"
                                        class="rounded-0 "
                                    ></v-text-field>
                                </v-flex>

                            </v-flex>
                        </v-layout>
                    </v-flex>


                    <v-flex xs6 class="ml-7">
                        <v-layout row class>
                            <v-flex style="flex: 1;" class="cust_pr mx-2 my-2 mr-3">
                                <label   
                                class="font-weight-bold cstm-clr-pr"
                                style=" color: #646666;">Registered Council</label>
                                <v-flex class="">
                                    <v-select
                                        :items="selectRegisteredCouncil"
                                        item-text="text"
                                        item-value="value"
                                        v-model="registeredCouncil"
                                        label="Select"
                                        dense
                                        solo
                                        hide-details 
                                        class="rounded-0"
                                    ></v-select>
                                </v-flex>
                            </v-flex>

                            <v-flex style="flex: 1;" class="cust_pr mx-2 my-2 ">
                                <label 
                                    class="font-weight-bold cstm-clr-pr"
                                    style="color: #646666;font-family: roboto;font-weight: 700;">Registration Number</label>
                                <v-flex class="">
                                    <v-text-field
                                        v-model="registrationNumber"
                                        label="Enter..."
                                        dense
                                        solo
                                        :rules="[rules.minRegNo]"
                                        counter="50"
                                        :maxlength="max"
                                        class="rounded-0"
                                    ></v-text-field>
                                </v-flex>
                            </v-flex>
                        </v-layout>
                    </v-flex>
                </v-layout>

                    
                
<!-- 2 -->
                <!-- <v-layout>
                    <v-flex xs6>
                        <v-layout row class>
                            <v-flex style="flex: 1;" class="cust_pr mx-2 my-2 mr-3">
                                <label   
                                class="font-weight-bold cstm-clr-pr"
                                style=" color: #646666;">Registered Council</label>
                                
                                <v-flex class="">
                                    <v-select
                                        :items="selectRegisteredCouncil"
                                        item-text="text"
                                        item-value="value"
                                        v-model="registeredCouncil"
                                        label="Select"
                                        dense
                                        solo
                                        hide-details 
                                        class="rounded-0"
                                    ></v-select>
                                </v-flex>
                            </v-flex>
                            <v-flex style="flex: 1;" class="cust_pr mx-2 my-2 mr-3">
                                <label 
                                    class="font-weight-bold cstm-clr-pr"
                                    style="color: #646666;font-family: roboto;font-weight: 700;">Registration Number</label>
                                
                                <v-flex class="">
                                    <v-text-field
                                        v-model="registrationNumber"
                                        label="Enter..."
                                        dense
                                        solo
                                        :rules="[rules.minRegNo]"
                                        class="rounded-0"
                                    ></v-text-field>
                                </v-flex>
                            </v-flex>
                        </v-layout>
                    </v-flex>

                    <v-flex xs6 class="ml-7">
                        <v-layout row class>
                            <v-flex style="flex: 1;" class="cust_pr mx-2 my-2 mr-3">
                                <label
                                    class="font-weight-bold cstm-clr-pr"
                                    style="color: #646666;">Provider Type
                                </label>
                                <v-flex class="">
                                    <v-select
                                        :items="indiProviderType"
                                        item-text="text"
                                        item-value="value"
                                        v-model="providerType"
                                        label="Select"
                                        dense
                                        solo
                                        hide-details 
                                        class="rounded-0"
                                    ></v-select>
                                </v-flex>
                            </v-flex>

                            <v-flex style="flex: 1;" class="cust_pr mx-2 my-2 ">
                                <label
                                    class="font-weight-bold cstm-clr-pr"
                                    style="color: #646666;">Mobile Number
                                </label>
                                <v-flex class="">
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="mobileNumber"
                                        :rules="[rules.validMOB]"
                                        class="rounded-0">
                                        <div slot="prepend-inner" style="background-color:#F7F7F7;padding: px !important"><v-icon>smartphone</v-icon></div>
                                    </v-text-field>
                                </v-flex>
                            </v-flex>
                        </v-layout>
                    </v-flex>
                </v-layout> -->
    <!-- 3 -->

    <!-- 4 -->
    <v-layout>
                    <v-flex xs6>
                        <v-layout row class>
                            <v-flex style="flex: 1;" class="cust_pr mx-2 my-2 mr-3">
                                <label
                                    class="font-weight-bold cstm-clr-pr"
                                    style="color: #646666;">Provider Type
                                </label>
                                <v-flex class="">
                                    <v-select
                                        :items="indiProviderType"
                                        item-text="text"
                                        item-value="value"
                                        v-model="providerType"
                                        label="Select"
                                        dense
                                        solo
                                        hide-details 
                                        class="rounded-0"
                                    ></v-select>
                                </v-flex>
                            </v-flex>
                            <v-flex style="flex: 1;" class="cust_pr mx-2 my-2 mr-3">
                                <label
                                    class="font-weight-bold cstm-clr-pr"
                                    style="color: #646666;">Mobile Number
                                </label>
                                <v-flex class="">
                                    <v-text-field
                                        solo
                                        autocomplete="off"
                                        label="Enter..."
                                        v-model="mobileNumber"
                                        class="rounded-0 comboxPaddingPro"
                                        :maxlength="maxMob"
                                        counter="10"
                                        :error-messages="validMobNo"
                                        dense
                                        >
                                        <v-icon 
                                        slot="prepend-inner" 
                                        dense  
                                        style="background-color:#F7F7F7; height: 38px; width: 40px;">
                                            call
                                        </v-icon>
                                    </v-text-field>
                                </v-flex>
                            </v-flex>

                        </v-layout>
                    </v-flex>

                    <!-- <v-flex xs6 class="ml-7">
                        <v-layout row class>
                            <v-flex style="flex: 1;" class="cust_pr mx-2 my-2 mr-3">
                                <label
                                    class="font-weight-bold cstm-clr-pr"
                                    style="color: #646666;">Provider Type
                                </label>
                                <v-flex class="">
                                    <v-select
                                        :items="indiProviderType"
                                        item-text="text"
                                        item-value="value"
                                        v-model="providerType"
                                        label="Select"
                                        dense
                                        solo
                                        hide-details 
                                        class="rounded-0"
                                    ></v-select>
                                </v-flex>
                            </v-flex>

                            <v-flex style="flex: 1;" class="cust_pr mx-2 my-2 ">
                                <label
                                    class="font-weight-bold cstm-clr-pr"
                                    style="color: #646666;">Mobile Number
                                </label>
                                <v-flex class="">
                                    <v-text-field
                                        solo
                                        label="Enter..."
                                        v-model="mobileNumber"
                                        :rules="[rules.validMOB]"
                                        class="rounded-0">
                                        <div slot="prepend-inner" style="background-color:#F7F7F7;padding: px !important"><v-icon>smartphone</v-icon></div>
                                    </v-text-field>
                                </v-flex>
                            </v-flex>
                        </v-layout>
                    </v-flex> -->
                </v-layout>

                <!-- <div>    
                     <hr class="mb-2" color="#C1C8CC" size="0.5" />
                </div> -->

                <v-divider class="mt-3"></v-divider>


                <div class="my-2">    
                    <label class="" style="color: #646666; font-family: roboto; font-weight: 700;">ADDRESS</label>
                </div> 
                    
                <v-layout>
                    <v-flex xs6>
                        <v-layout row class>
                            <v-flex style="flex: 1;" class="cust_pr mx-2  mr-3">
                                <label 
                                    class="font-weight-bold cstm-clr-pr"
                                    style=" color: #646666;">Address Contains</label>
                                <v-flex class="">
                                    <v-text-field
                                        v-model="addressContains"
                                        label="Enter..."
                                        dense
                                        solo
                                        :error-messages="validAddress"
                                        counter="100"
                                        :maxlength="maxAdd"
                                        class="rounded-0"
                                    ></v-text-field>
                                </v-flex>
                            </v-flex>
                            <v-flex style="flex: 1;" class="cust_pr mx-2 mr-3">
                                <label 
                                    class="font-weight-bold cstm-clr-pr"
                                    style="color: #646666;">State</label>
                                
                                <v-flex class="">
                                    <v-select
                                        :items="selectState"
                                        item-text="state"
                                        item-value="state"
                                        v-model="state"
                                        label="Select"
                                        dense
                                        solo
                                        hide-details 
                                        class="rounded-0"
                                    ></v-select>
                                </v-flex>
                            </v-flex>
                        </v-layout>
                    </v-flex>

                    <v-flex xs6 class="ml-7">
                        <v-layout row class>
                            <v-flex style="flex: 1;" class="cust_pr mx-2 mr-3">
                                <label
                                    class="font-weight-bold cstm-clr-pr"
                                    style="color: #646666;">City/Town/Village
                                </label>
                                <v-flex class="">
                                    <!-- <v-select
                                        :items="selectCity"
                                        item-text="text"
                                        item-value="value"
                                        v-model="city"
                                        label="Select"
                                        dense
                                        solo
                                        hide-details 
                                        class="rounded-0"
                                    ></v-select> -->
                                    <v-text-field
                                        v-model="city"
                                        label="Enter..."
                                        
                                        dense
                                        solo
                                        :error-messages="validCity" 
                                        counter="50"
                                        :maxlength="max"
                                        class="rounded-0"
                                    ></v-text-field>
                                </v-flex>
                            </v-flex>

                            <v-flex style="flex: 1;" class="cust_pr mx-2">
                                <label
                                    class="font-weight-bold cstm-clr-pr"
                                    style="color: #646666;">Pincode
                                </label>
                                <v-flex class=" ">
                                    <v-text-field
                                        v-model="pincode"
                                        label="Enter..."
                                        
                                        dense
                                        solo
                                        :error-messages="validPinNo"
                                        class="rounded-0"
                                    ></v-text-field>
                                </v-flex>
                            </v-flex>
                        </v-layout>
                    </v-flex>
                </v-layout>
<!-- 4 -->
                 <v-layout>
                    <v-flex xs3 class="mr-7">
                        <v-layout row class>
                            <v-flex style="flex: 1;max-width:295px !important" class="cust_pr mx-2 my-2 mr-3">
                                <label
                                    class="font-weight-bold cstm-clr-pr" 
                                    style=" color: #646666;">District</label>
                                <!-- :readonly="true" -->
                                <v-flex class="">
                                    <v-text-field
                                        v-model="district"
                                        label="Enter"
                                        dense
                                        solo
                                        :error-messages="validDistrict" 
                                        counter="50"
                                        :maxlength="max"
                                        class="rounded-0"
                                    ></v-text-field>
                                </v-flex>
                            </v-flex>
                            
                        </v-layout>
                    </v-flex>

                    <v-flex xs8>
                        <v-layout row class="mt-8">
                            <v-btn tile @click="cnclIndividual()" style="font-size:12px;background-color:#f7f7f7" small text color="black" class="text-capitalize px-3 mx-2"><v-icon dark small >clear</v-icon > <v-divider vertical class="mx-2"></v-divider>Clear All</v-btn>
                            <v-btn tile dark @click="seekind(1)" style="font-size:12px" small color="" class="text-capitalize px-3 mx-2">Search</v-btn>
                             
                            <v-btn tile @click="createProviderIndividual()" style="font-size:12px;color:white"  small color="#23B1A9" class="text-capitalize px-3 mx-2"><v-icon dark small >add</v-icon > <v-divider vertical class="mx-2"></v-divider>Create New Provider</v-btn>

                         <v-btn tile @click="export_provider_excel(1)" 
                                style="font-size:12px;background-color::#01b4bb;" small
                                text color="#fff" class="text-capitalize px-3 mx-2">
                                 <v-icon dark small >cloud_download</v-icon >
                                 <v-divider vertical class="mx-2"></v-divider>Export Providers</v-btn> 
                        </v-layout>
                    </v-flex>

                </v-layout>
            </v-form>
                
                     
            <!-- <v-card tile class="my-3 pa-2" style="background-color:#F7F7F7;border:1px solid #C1C8CC;flex:1;">  
                    <v-layout row justify-start align-center class="mx-2">
                            <v-text class="mr-1 font-weight-bold body-1" style="color:#3C3D3D; font-size:16px !important">
                                PROVIDER LIST
                            </v-text>
                        </v-layout>
                        
                </v-card> -->

                <v-card tile class="my-3 " style="background-color:#F7F7F7;border:1px solid #C1C8CC;flex:1;">  
                    <v-layout row justify-start align-center justify-space-between class="mx-">
                            <v-text class="mr-1 mx-5 font-weight-bold body-1" style="color:#3C3D3D; font-size:16px !important">
                                PROVIDER LIST 
                            </v-text>


                 <!-- <v-card tile class="my-3 " style="background-color:#F7F7F7;border:1px solid #C1C8CC;flex:1;">  
                    <v-layout row justify-start align-center justify-space-between class="mx-">
                            <p class="mr-1 mx-5 font-weight-bold body-1" style="color:#3C3D3D; font-size:16px !important">
                                PROVIDER LIST 
                                
                            </p> -->
<!-- =>{{isSearchindiv}} {{typesearch}} -->

                <v-layout justify-end row>
                    <label class="pa-1 px-2" style="background-color:#23B1A9" >
                         <v-icon color="white" @click="sortOD" style="background-color:#ccc;margin-top: 3.5px;border-radius:55px;" >
                {{ sortorder.count == 1 ? 'arrow_upward':'arrow_downward' }}  </v-icon>
                        <!-- <v-icon @click="sortOD"  color="white" class="" style="background-color:#23B1A9;padding: 9px !important;" >sort</v-icon> -->
                    </label>
                    <v-flex xs2 column class="cust_pr mr-6">
                        <v-select
                            dense
                            :items="sortHeaderIndividual"
                                item-text="text"
                                item-value="value"
                                v-model="indiviSortBy"
                                label="Provider Name"
                                @change="sortIndiviChange()"
                            clearable
                                solo
                            class=" rounded-0"
                            hide-details
                            value="Provider Name"   
                        >
                        <span slot="label" class="font-weight-bold" style="font-size: 14px">Sort By: ind</span>
                        </v-select>
                    </v-flex>
                </v-layout>

                </v-layout>
            </v-card>

            <v-card v-if="showrsltnotfound===true" tile class="my-3 pa-3" style="background-color:;border:1px solid #C1C8CC;flex:1;">  
                    <v-layout row justify-start align-center justify-space-between class="mx-">
                            <v-text class="mr-1 mx-5 font-weight-bold body-1" style="color:#3C3D3D; font-size:16px !important">
                                NO RESULTS FOUND
                            </v-text>
                    </v-layout>
                        
            </v-card>

        <div v-if="showSearchIndividual===true">
                <v-card tile v-for="(i, index) in GetIndividualData" :key="index">
                <v-layout row  class="my-2">
                    <v-flex  style="flex: 1">
                    <v-layout column justify-start class="ml-4 mx-2 pa-1">                        
                            <v-text class="lbl-bgclr font-weight-bold text-truncate" style="color:#3C3D3D; font-size:12px !important">PRN</v-text>
                        
                                 <v-text class=""
                                         @click="$router.push({name: 'ProviderSummary', params: {id: 'individual',  prn:i._id }})"
                                         style="cursor:pointer;text-decoration:underline; color:#E46A25; font-size:12px !important">{{i.prn}}</v-text>
                           
                          
                    </v-layout>
                    </v-flex>
                    <v-divider  class="pa-0 ma-0" vertical ></v-divider>
                    <v-flex  style="flex: 1">
                    <v-layout  column justify-start style="width:90px;" class="mx-1 pa-1">                        
                            <v-text class="lbl-bgclr font-weight-bold text-truncate" style="color:#3C3D3D; font-size:12px !important">Provider Name</v-text>
                            <v-text class="" style="color:#3C3D3D; max-width:100px; font-size:12px !important">{{i.firstName}} {{i.lastName}}</v-text>
                    </v-layout>
                    </v-flex>
                    <v-divider  class="" vertical ></v-divider>
                    <v-flex  style="flex: 1">
                    <v-layout  column justify-start style="width:95px;" class="mx-1 pa-1">                        
                            <v-text class="lbl-bgclr font-weight-bold text-truncate" style="color:#3C3D3D; font-size:12px !important">Provider Type</v-text>
                            <v-text class="" style="color:#3C3D3D; max-width:px; font-size:12px !important">{{i.providerType}}</v-text>
                    </v-layout>
                    </v-flex>
                    <v-divider  class="" vertical ></v-divider>
                    <v-flex  style="flex: 1">
                    <v-layout  column justify-start style="width:80px;" class="mx-1 pa-1">                        
                            <v-text class="lbl-bgclr font-weight-bold text-truncate" style="color:#3C3D3D; font-size:12px !important">Status</v-text>
                            <v-text class="" style="color:#3C3D3D; max-width:80px; font-size:12px !important">{{i.status}}</v-text>
                    </v-layout>
                    </v-flex>

                    <v-divider  class="" vertical ></v-divider>
                    <v-flex  style="flex: 1">
                    <v-layout  column justify-start style="width:85px;" class="mx-1 pa-1 ">                        
                            <v-text class="lbl-bgclr font-weight-bold text-truncate" style="color:#3C3D3D; font-size:12px !important">Mobile No.</v-text>
                            <v-text class="" style="color:#3C3D3D;  font-size:12px !important">{{i.mobileNumber}}</v-text>
                    </v-layout>
                    </v-flex>

                    <v-divider  class="" vertical ></v-divider>
                    <v-flex  style="flex: 1">
                    <v-layout  column justify-start style="width:100px;" class="mx-1 pa-1">                        
                            <v-text  class="lbl-bgclr font-weight-bold text-truncate" style="color:#3C3D3D; font-size:12px !important">Registration No.</v-text>
                            <v-text class="" style="color:#3C3D3D; max-width:80px; font-size:12px !important">{{i.registrationNumber}}</v-text>
                    </v-layout>
                    </v-flex>
                    <v-divider  class="" vertical ></v-divider>
                    <v-flex  style="flex: 1">
                    <v-layout  column justify-start style="width:80px;" class="mx-1 pa-1">                        
                            <v-text class="lbl-bgclr font-weight-bold text-truncate" style="color:#3C3D3D; font-size:12px !important">Council</v-text>
                            <v-text class="" style="color:#3C3D3D; max-width:80px; font-size:12px !important">{{i.registeredCouncil}}</v-text>
                    </v-layout>
                    </v-flex>
                    <v-divider  class="" vertical ></v-divider>
                    <v-flex  style="flex: 1">
                    <v-layout  column justify-start style="width:80px;" class="mx-1 pa-1">                        
                            <v-text class="lbl-bgclr font-weight-bold text-truncate" style="color:#3C3D3D; font-size:12px !important">Address</v-text>
                            <v-text class="" style="color:#3C3D3D; max-width:80px; font-size:12px !important">{{i.addressLine1}} {{i.addressLine2}} {{i.addressLine3}}</v-text>
                    </v-layout>
                    </v-flex>
                    <v-divider  class="" vertical ></v-divider>
                    <v-flex  style="flex: 1">
                    <v-layout  column justify-start style="width:80px;" class="mx-1 pa-1">                        
                            <v-text class="lbl-bgclr font-weight-bold text-truncate" style="color:#3C3D3D; font-size:12px !important">City/Town</v-text>
                            <v-text class="" style="color:#3C3D3D; max-width:px; font-size:12px !important">{{i.cityTownVillage}}</v-text>
                    </v-layout>
                    </v-flex>
                    <v-divider  class="" vertical ></v-divider>
                    <v-flex  style="flex: 1">
                    <v-layout  column justify-start style="width:90px;" class="mx-1 pa-1">                        
                            <v-text class="lbl-bgclr font-weight-bold text-truncate" style="color:#3C3D3D; font-size:12px !important">State</v-text>
                            <v-text class="" style="color:#3C3D3D; max-width:px; font-size:11px !important">{{i.state}}</v-text>
                    </v-layout>
                    </v-flex>
                    <v-divider  class="" vertical ></v-divider>
                    <v-flex  style="flex: 1">
                    <v-layout  column justify-start style="width:80px;" class="mx-1 pa-1 ml-1">                        
                            <v-text class="lbl-bgclr font-weight-bold text-truncate" style="color:#3C3D3D; font-size:12px !important">PinCode</v-text>
                            <v-text class="" style="color:#3C3D3D; max-width:px; font-size:12px !important">{{i.pinPostalCode}}</v-text>
                    </v-layout>
                    </v-flex>
                    
                </v-layout>
                </v-card>
        </div>

                <v-card v-if="showSearchIndividual===true" tile class="my-3" style="background-color:#F7F7F7;border:1px solid #C1C8CC;flex:1;">  
                    <v-layout row justify-end align-center class="mx-2">
                            <v-text class="mr-1 font-weight-bold body-1" style="color:#3C3D3D; font-size:12px !important">

                                    <span class="" style="color:#E46A25;font-size:12px;">Showing {{fval}}-{{sval}} </span>out of {{totalLen}} records

                            </v-text>
                            <v-divider  class="mx-2" vertical ></v-divider>
                            <v-pagination
                                class="pdct-stUp-bnkAcc-pgn"
                                v-model="pageIndu"
                                
                                @input="nextPageData(pageIndu)"
                                color="#152F38"
                                flat
                                :length="pagelength"
                            ></v-pagination>
                        </v-layout>
                </v-card>
            
                </div>

<!-- for Institution -->
            <div class="ma-7" v-if="showInstitution === true">
                <v-form v-model="valid" ref="form">
                <v-layout>

                    <v-flex xs6>
                        <v-layout row class>
                            <v-flex style="flex: 1;" class="cust_pr mx-2 mr-3">
                                <label class="font-weight-bold cstm-clr-pr" style=" color: #646666;">Entity Name Filter</label>
                                
                                <v-flex class="">
                                    <v-select
                                        :items="selectEntityNameFilter"
                                        item-text="text"
                                        item-value="value"
                                        v-model="entityNameFilter"
                                        label="Contains"
                                        dense
                                        solo
                                        
                                        class="rounded-0"
                                    ></v-select>
                                </v-flex>
                            </v-flex>
                            <v-flex style="flex: 1;" class="cust_pr mx-2 mr-3">
                                <label class="font-weight-bold cstm-clr-pr" style=" color: #646666;">Entity Name</label>
                                
                                <v-flex class="">
                                    <v-text-field
                                        v-model="inEntityName"
                                        label="Enter..."
                                        dense
                                        solo
                                        :error-messages="validinEntityName" 
                                        
                                        :maxlength="maxCount" 
                                        class="rounded-0"
                                    ></v-text-field>
                                </v-flex>
                            </v-flex>
                        </v-layout>
                    </v-flex>

                    <v-flex xs6 class="ml-7">
                        <v-layout row class>
                            <v-flex style="flex: 1;" class="cust_pr mx-2 mr-3">
                                <label 
                                class="font-weight-bold cstm-clr-pr"
                                style="color: #646666;">Registration Number</label>
                                
                                <v-flex class="">
                                    <v-text-field
                                        v-model="inRegistrationNumber"
                                        label="Enter..."
                                        dense
                                        solo
                                        :rules="[rules.minRegNo]"
                                        counter="50"
                                        :maxlength="max"
                                         
                                        class="rounded-0"
                                    ></v-text-field>
                                </v-flex>
                                <!-- <label
                                    class="font-weight-bold cstm-clr-pr"
                                    style="color: #646666;">PAN
                                </label>
                                <v-flex class="">
                                    <v-text-field
                                        v-model="inPanNumber"
                                        label="Enter..."
                                        dense
                                        solo
                                        :rules="[rules.validPAN]"
                                        class="rounded-0"
                                    ></v-text-field>
                                </v-flex> -->
                            </v-flex>

                            <v-flex style="flex: 1;" class="cust_pr mx-2 mr-3">
                                <label
                                    class="font-weight-bold cstm-clr-pr"
                                    style="color: #646666;">ROHINI ID
                                </label>
                                <v-flex class=" ">
                                    <v-text-field
                                        v-model="inRohiniId"
                                        label="Enter..."
                                        
                                        
                                        dense
                                        solo
                                         :error-messages="validRohiniId"
                                        class="rounded-0"
                                    ></v-text-field>
                                </v-flex>
                            </v-flex>
                        </v-layout>
                    </v-flex>
                </v-layout>
<!-- 2 -->
                <v-layout >
                    <v-flex xs6 class="">
                        <v-layout row class="">
                            <v-flex style="flex: 1;" class="cust_pr mx-2 my-2 mr-3">
                                <label class="font-weight-bold cstm-clr-pr" style=" color: #646666;">PRN</label>
                                
                                <v-flex class="">
                                    <v-text-field
                                        v-model="inPrnNumber"
                                        label="Enter..."
                                        dense
                                        solo
                                        
                                        :error-messages="valid_inPrnNumber"
                                        class="rounded-0"
                                    ></v-text-field>
                                </v-flex>
                            </v-flex>
                            <v-flex style="flex: 1;" class="cust_pr mx-2 my-2 mr-3">
                                <label
                                    class="font-weight-bold cstm-clr-pr"
                                    style="color: #646666;">Provider Type
                                </label>
                                <v-flex class="">
                                    <v-select
                                        :items="instiProviderType"
                                        item-text="text"
                                        item-value="value"
                                        v-model="inProviderType"
                                        label="Select"
                                        dense
                                        solo
                                        
                                        class="rounded-0"
                                    ></v-select>
                                </v-flex>
                            </v-flex>
                        </v-layout>
                    </v-flex>

                    <v-flex xs6 class="ml-7">
                        <v-layout row class>
                            <v-flex style="flex: 1;" class="cust_pr mx-2 my-2 mr-3">
                                <label
                                    class="font-weight-bold cstm-clr-pr"
                                    style="color: #646666;">PAN
                                </label>
                                <v-flex class="">
                                    <v-text-field
                                        v-model="inPanNumber"
                                        label="Enter..."
                                        dense
                                        solo
                                        
                                        :error-messages="valid_inPanNo"
                                        class="rounded-0"
                                    ></v-text-field>
                                </v-flex>
                            </v-flex>

                            <v-flex style="flex: 1;" class="cust_pr mx-2 my-2 mr-3">
                                <label
                                    class="font-weight-bold cstm-clr-pr"
                                    style="color: #646666;">GSTIN
                                </label>
                                <v-text-field
                                        v-model="inGstinAddress"
                                        label="Enter..."
                                        type="alphanumeric"
                                        :rules="[rules.minGSTIN]"
                                        dense
                                        solo

                                        class="rounded-0"
                                ></v-text-field>
                                
                            </v-flex>
                        </v-layout>
                    </v-flex>

                
                </v-layout>
    <!-- 3 -->
                <!-- <div>    
                     <hr class="mb-2" color="#C1C8CC" size="0.5" />

                </div> -->
                <v-divider class="mt-3"></v-divider>
                <div class="my-2">    
                    <label class="" style=" color: #646666; font-family: roboto; font-weight: 700;">ADDRESS</label>
                </div> 
            <v-layout>
                <v-flex xs6>
                        <v-layout row class>
                          <v-flex style="flex: 1;" class="cust_pr mx-2  mr-3">
                                <label 
                                    class="font-weight-bold cstm-clr-pr"
                                    style=" color: #646666;">Address Contains</label>
                                <v-flex class="">
                                    <v-text-field
                                        v-model="inAddressContains"
                                        label="Enter..."
                                        type="text"

                                        dense
                                        counter="100"
                                        :maxlength="maxAdd"
                                        :error-messages="valid_inAddress"
                                        solo
                                        class="rounded-0"
                                    ></v-text-field>
                                </v-flex>
                            </v-flex>
                            <v-flex style="flex: 1;" class="cust_pr mx-2 mr-3">
                                <label 
                                    class="font-weight-bold cstm-clr-pr"
                                    style="color: #646666;">State</label>
                                <v-flex class="">
                                    <v-select
                                        :items="selectState"
                                        item-text="state"
                                        item-value="state"
                                        v-model="inState"
                                        label="Select"
                                        dense
                                        solo
                                        hide-details 
                                        class="rounded-0"
                                    ></v-select>
                                </v-flex>
                            </v-flex>
                        </v-layout>
                    </v-flex>
                    <v-flex xs6 class="ml-7">
                        <v-layout row class>
                            <v-flex style="flex: 1;" class="cust_pr mx-2 mr-3">
                                <label
                                    class="font-weight-bold cstm-clr-pr"
                                    style="color: #646666;">City/Town/Village
                                </label>
                                <v-flex class="">
                                    <!-- <v-select
                                        :items="selectCity"
                                        item-text="text"
                                        item-value="value"
                                        v-model="inCity"
                                        label="Select"
                                        dense
                                        solo
                                        hide-details 
                                        class="rounded-0"
                                    ></v-select> -->
                                    <v-text-field
                                        v-model="inCity"
                                        label="Enter..."
                                        
                                        dense
                                        solo
                                        :error-messages="validinCity" 
                                        counter="50"
                                        :maxlength="max" 
                                        class="rounded-0"
                                    ></v-text-field>
                                </v-flex>
                            </v-flex>
                            <v-flex style="flex: 1;" class="cust_pr mx-2 ">
                                <label
                                    class="font-weight-bold cstm-clr-pr"
                                    style="color: #646666;">Pincode
                                </label>
                                <v-flex class=" ">
                                    <v-text-field
                                        v-model="inPincode"
                                        label="Enter..."
                                        
                                        dense
                                        solo
                                        :error-messages="valid_inPinNo" 
                                        
                                        class="rounded-0"
                                    ></v-text-field>
                                </v-flex>
                            </v-flex>
                        </v-layout>
                    </v-flex>
                </v-layout>
<!-- 4 -->
                 <v-layout>
                    <v-flex xs3 class="mr-7">
                        <v-layout row class>
                            <v-flex style="flex: 1;max-width:295px !important" class="cust_pr mx-2 my-2 mr-3">
                                <label
                                    class="font-weight-bold cstm-clr-pr" 
                                    style=" color: #646666;">District</label>
                                <!-- :readonly="true" -->
                                <v-flex class="">
                                    <v-text-field
                                        v-model="inDistrict"
                                        label="Enter"
                                        dense
                                        solo
                                        :error-messages="validinDistrict" 
                                        counter="50"
                                        :maxlength="max" 
                                        class="rounded-0"
                                    ></v-text-field>
                                </v-flex>
                            </v-flex>
                            
                        </v-layout>
                    </v-flex>

                    <v-flex xs8>
                        <v-layout row class="mt-8">
                                <!-- <v-btn tile @click="sortPrn()"  style="font-size:12px;background-color:#f7f7f7" small text color="black" class="text-capitalize px-3 mx-2"><v-icon dark small >clear</v-icon > <v-divider vertical class="mx-2"></v-divider>Clear All</v-btn> -->

                            <v-btn tile @click="cnclInstitution()"  style="font-size:12px;background-color:#f7f7f7" small text color="black" class="text-capitalize px-3 mx-2"><v-icon dark small >clear</v-icon > <v-divider vertical class="mx-2"></v-divider>Clear All</v-btn>
                            <v-btn tile dark @click="seekinsti"  style="font-size:12px" small color="" class="text-capitalize px-3 mx-2">Search</v-btn>

                            <!-- <v-btn tile dark @click="createProviderInstitution()"  style="font-size:12px;color:white" small color="#23B1A9" class="text-capitalize px-3 mx-2"><v-icon dark small >add</v-icon > <v-divider vertical class="mx-2"></v-divider>Create New Provider</v-btn> -->
                            <v-btn tile  @click="createProviderInstitution()"  style="font-size:12px;color:white"  small color="#23B1A9" class="text-capitalize px-3 mx-2"><v-icon dark small >add</v-icon > <v-divider vertical class="mx-2"></v-divider>Create New Provider</v-btn>

                             <v-btn tile @click="export_provider_excel(2)" 
                                style="font-size:12px;background-color:#01b4bb;" small
                                text color="#fff" class="text-capitalize px-3 mx-2">
                                 <v-icon dark small >cloud_download</v-icon >
                                 <v-divider vertical class="mx-2"></v-divider>Export Providers</v-btn>

                        </v-layout>
                    </v-flex>
                    
                </v-layout>
            </v-form>


                <v-card tile class="my-3 " style="background-color:#F7F7F7;border:1px solid #C1C8CC;flex:1;">  
                    <v-layout row justify-start align-center justify-space-between class="mx-">
                            <v-text class="mr-1 mx-5 font-weight-bold body-1" style="color:#3C3D3D; font-size:16px !important">
                                PROVIDER LIST 
                            </v-text>


                <v-layout justify-end row>
                    <label>
                        <v-icon color="white" class="" style="background-color:#23B1A9;padding: 9px !important;" >sort</v-icon>
                    </label>
                    <v-flex xs2 column class="cust_pr mr-6">
                        <v-select
                            dense
                            :items="sortHeaderInsti"
                                item-text="text"
                                item-value="value"
                                v-model="instiSortBy"
                                @change="sortInstiChange()"
                                label="Provider Name"
                                solo
                            class=" rounded-0"
                            hide-details
                            value="Provider Name"   
                        >
                        <span slot="label" class="font-weight-bold" style="font-size: 14px">Sort By: insti</span>
                        </v-select>
                    </v-flex>
                </v-layout>

                            <!-- <v-flex xs3  class=" "  >
                                <v-layout row justify-start align-center class="">

                                    <label >Sort By:</label>

                                    <v-select
                                        
                                        :items="sortHeaders"
                                        item-text="text"
                                        item-value="value"
                                        v-model="sortBy"
                                        label="Provider Name"
                                        class="mr-6"
                                        dense
                                        solo
                                    ></v-select>
                                </v-layout>
                        </v-flex> -->
                        </v-layout>
                </v-card>

        <div v-if="showSearchInstitution===true">
            <v-card tile  v-for="(i, index) in GetInstitutionData" :key="index" :sort-by="['PRN', 'Provider Name']" :sort-desc="[false, true]" multi-sort>
                <v-layout row  class="my-2">
                    <v-flex style="flex: 1">
                        <v-layout column justify-start style="width:80px;" class="ml-4 mx-2 pa-1">                        
                                <v-text  class="lbl-bgclr font-weight-bold text-truncate" style="color:#3C3D3D; font-size:12px !important">PRN</v-text>
                                <v-text class="" @click="$router.push({name: 'ProviderSummary', params: {id: 'institution',  prn:i._id }})" style="cursor:pointer;text-decoration:underline; color:#E46A25; font-size:12px !important">{{i.prn}}</v-text>
                        </v-layout>
                    </v-flex>
                    <v-divider  class="" vertical ></v-divider>
                    <v-flex style="flex: 1">
                        <v-layout  column justify-start style="width:90px;" class="mx-2 pa-1">                        
                                <v-text  class="lbl-bgclr font-weight-bold text-truncate" style="color:#3C3D3D; font-size:12px !important">Provider Name</v-text>
                                <v-text class="" style="color:#3C3D3D; font-size:12px !important">{{i.entityName}}</v-text>
                        </v-layout>
                    </v-flex>
                    <v-divider  class="" vertical ></v-divider>
                    <v-flex style="flex: 1">
                        <v-layout  column justify-start style="width:90px;" class="mx-2 pa-1">                        
                                <v-text  class="lbl-bgclr font-weight-bold text-truncate" style="color:#3C3D3D; font-size:12px !important">Provider Type</v-text>
                                <v-text class="" style="color:#3C3D3D; font-size:12px !important">{{i.providerType}}</v-text>
                        </v-layout>
                    </v-flex>
                    <v-divider  class="" vertical ></v-divider>
                    <v-flex style="flex: 1">
                        <v-layout  column justify-start style="width:90px;" class="mx-2 pa-1">                        
                                <v-text  class="lbl-bgclr font-weight-bold text-truncate" style="color:#3C3D3D; font-size:12px !important">Status</v-text>
                                <v-text class="" style="color:#3C3D3D; font-size:12px !important">{{i.status}}</v-text>
                        </v-layout>
                    </v-flex>
                    <v-divider  class="" vertical ></v-divider>
                    <v-flex style="flex: 1">
                        <v-layout  column justify-start style="width:80px;" class="mx-2 pa-1">                        
                                <v-text class="lbl-bgclr font-weight-bold text-truncate" style="color:#3C3D3D; font-size:12px !important">Alias Name</v-text>
                                <v-text class="" style="color:#3C3D3D; font-size:12px !important">{{i.aliasName}}</v-text>
                        </v-layout>
                    </v-flex>
                    <v-divider  class="" vertical ></v-divider>
                    <v-flex style="flex: 1">
                        <v-layout  column justify-start style="width:100px;" class="mx-1 pa-1">                        
                                <v-text  class="lbl-bgclr font-weight-bold text-truncate" style="color:#3C3D3D; font-size:12px !important">ROHINI ID</v-text>
                                <v-text class="" style="color:#3C3D3D; font-size:12px !important">{{i.rohiniId}}</v-text>
                        </v-layout>
                    </v-flex>
                    <v-divider  class="" vertical ></v-divider>
                    <v-flex style="flex: 1">
                        <v-layout  column justify-start style="width:90px;" class="mx-2 pa-1">                        
                                <v-text  class="lbl-bgclr font-weight-bold text-truncate" style="color:#3C3D3D; font-size:12px !important">Address</v-text>
                                <v-text class="" style="color:#3C3D3D; font-size:12px !important">{{i.addressLine1}} {{i.addressLine2}} {{i.addressLine3}}</v-text>
                        </v-layout>
                    </v-flex>
                    <v-divider  class="" vertical ></v-divider>
                    <v-flex style="flex: 1">
                        <v-layout  column justify-start style="width:90px;" class="mx-2 pa-1">                        
                                <v-text  class="lbl-bgclr font-weight-bold text-truncate" style="color:#3C3D3D; font-size:12px !important">City/Town</v-text>
                                <v-text class="" style="color:#3C3D3D; font-size:12px !important">{{i.cityTownVillage}}</v-text>
                        </v-layout>
                    </v-flex>
                    <v-divider  class="" vertical ></v-divider>
                    <v-flex  style="flex: 1">
                        <v-layout  column justify-start style="width:80px;" class="mx-2 pa-1">                        
                                <v-text  class="lbl-bgclr font-weight-bold text-truncate" style="color:#3C3D3D; font-size:12px !important">State</v-text>
                                <v-text class="" style="color:#3C3D3D; font-size:12px !important">{{i.state}}</v-text>
                        </v-layout>
                    </v-flex>
                    <v-divider  class="" vertical ></v-divider>
                    <v-flex  style="flex: 1">
                        <v-layout  column justify-start style="width:80px;" class="mx-2 pa-1">                        
                                <v-text  class="lbl-bgclr font-weight-bold text-truncate" style="color:#3C3D3D; font-size:12px !important">PinCode</v-text>
                                <v-text class="" style="color:#3C3D3D; font-size:12px !important">{{i.pinPostalCode}}</v-text>
                        </v-layout>
                    </v-flex>
                    
                </v-layout>
            </v-card>
        </div>

        


                <v-card v-if="showSearchInstitution===true" tile class="my-3" style="background-color:#F7F7F7;border:1px solid #C1C8CC;flex:1;">  
                    <!-- <v-layout row justify-end align-center class="mx-2">
                            <v-text class="mr-1 font-weight-bold body-1" style="color:#3C3D3D; font-size:12px !important">
                                    <span class="" style="color:#E46A25;font-size:12px;">Showing 1-10 </span>out of 75 records

                            </v-text>
                            <v-divider  class="mx-2" vertical ></v-divider>
                            <v-pagination 
                                class="cust-bnkAcc-pgn"
                                v-model="pageNumber"
                                color="#152F38"
                                flat
                                @input="nextPage"
                                :length="pages"></v-pagination>
                        </v-layout> -->

                        <v-layout row justify-end align-center class="ma-2">
                            <span class="mr-1 font-weight-bold" style="color:#3C3D3D; font-size:13px;">
                                <!-- <span class="" style="color:#E46A25;">Showing 1-{{GetInstitutionData.length}} </span>out of {{totalLength}} records -->
                                
                                <span class="" style="color:#E46A25;">Showing {{fval}}-{{sval}} </span>out of {{totalLength}} records

                            </span>
                            <v-divider  class="mx-2" vertical ></v-divider>
                             <v-pagination
                                class="pdct-stUp-bnkAcc-pgn"
                                v-model="inPage"
                                color="#152F38"
                                flat
                                @input="nextPageBtnInsti(inPage)"
                                :length="pagelengthinsti"
                            ></v-pagination>
                        </v-layout>
                </v-card>

                <v-card v-if="rsltnotfound===true" tile class="my-3 pa-3" style="background-color:;border:1px solid #C1C8CC;flex:1;">  
                    <v-layout row justify-start align-center justify-space-between class="mx-">
                            <v-text class="mr-1 mx-5 font-weight-bold body-1" style="color:#3C3D3D; font-size:16px !important">
                                NO RESULTS FOUND
                            </v-text>
                    </v-layout>
                        
                </v-card>
            
            
            
            </div>    
		</v-card>
    </v-app>    
</template>
            
<script>
import axios from "axios";
import { required, minLength,numeric,alphaNum,maxLength,alpha,helpers} from 'vuelidate/lib/validators'
export const alphaRegex = helpers.regex('alpha', /^[a-zA-Z.' ]+$/);

export default {
        validations: {
        
        // for individual
        firstName:{
            alphaRegex,
            maxLength: maxLength(20),
        },

        lastName:{
            alphaRegex,
            maxLength: maxLength(20),
        },
            panNumber:{
                minLength :minLength(10),
                alphaNum
            },

            prnNumber:{
                minLength :minLength(10),
              maxLength: maxLength(10),
              alphaNum
            },

            mobileNumber:{
                minLength :minLength(10),
              maxLength: maxLength(10),
              numeric 
            },

            addressContains:{
                minLength :minLength(3),
            },

            pincode:{
                minLength :minLength(6),
                maxLength: maxLength(6),
                numeric

            },
            city:{
                alpha,
                maxLength: maxLength(50),
            },
            district:{
                alpha,
                maxLength: maxLength(50),
            },

            // for institution

            inEntityName:{
                
                alphaRegex,
                maxLength: maxLength(20),
            },

            inPincode:{
                minLength :minLength(6),
                maxLength: maxLength(6),
                numeric
            },

            inPrnNumber:{
              minLength :minLength(10),
              maxLength: maxLength(10),
              alphaNum              
            },

            inPanNumber:{
                minLength :minLength(10),
                alphaNum
            },
            
            inRohiniId:{
                minLength :minLength(13),
                maxLength: maxLength(13),
                numeric
            },

            inAddressContains:{
                minLength :minLength(3),
            },
            inCity:{
                alpha,
                maxLength: maxLength(50),
            },

            inDistrict:{
                alpha,
                maxLength: maxLength(50),
            },

        },


    created() {
             console.log('user access for create new provider',this.$store.getters.fetchRole)
             this.userValue=this.$store.getters.fetchRole

        console.log("Success Token", this.$store.getters.successToken)
        this.token=this.$store.getters.successToken
        this.$store.commit("SAVE_PAGE_HEADER", "Provider Search");

        this.getProviderALLInstitution(null,this.inPage)
        this.getALLState()
        this.getProviderALLIndividual(null,this.pageIndu)
        //    this.SearchProviderIndividual()
        // this.SearchProviderInstitution()
                    console.log("token is -> ", this.$store.getters.successToken); 

       
        
                    


    },

     computed: {

// validation for individual

        validFirstName(){ 
            const errors = []
            if(this.firstName.length > 0){
               
                if (!this.$v.firstName.maxLength) {    
                    errors.push('Enter Max 20 Characters only.')
                    return errors
                    }
                console.log("from if ",this.$v.firstName);
                if(/^[a-zA-Z.' ]+$/g.test(this.firstName) != true){
                // if(/^([a-zA-Z])+$/g.test(this.firstName) != true){
                    // this.ttl_err = true
                    return "Enter Characters only."
                }else{
                    // this.ttl_err = false
                    return []
                }
              }
         },

         validLastName(){ 
            const errors = []
            if(this.lastName.length > 0){
               
                if (!this.$v.lastName.maxLength) {    
                    errors.push('Enter Max 20 Characters only.')
                    return errors
                    }
                console.log("from if ",this.$v.lastName);
                if(/^[a-zA-Z.' ]+$/g.test(this.lastName) != true){
                // if(/^([a-zA-Z])+$/g.test(this.lastName) != true){
                    // this.ttl_err = true
                    return "Enter Characters only."
                }else{
                    // this.ttl_err = false
                    return []
                }
              }
         },

         validCity(){ 
            const errors = []
            if(this.city.length > 0){
               
                if (!this.$v.city.maxLength) {    
                    errors.push('Enter Max 20 Characters only.')
                    return errors
                    }
                console.log("from if ",this.$v.city);
                if(/^([a-zA-Z])+$/g.test(this.city) != true){
                    // this.ttl_err = true
                    return "Enter Characters only."
                }else{
                    // this.ttl_err = false
                    return []
                }
              }
         },

         validDistrict(){ 
            const errors = []
            if(this.district.length > 0){
               
                if (!this.$v.district.maxLength) {    
                    errors.push('Enter Max 20 Characters only.')
                    return errors
                    }
                console.log("from if ",this.$v.district);
                if(/^([a-zA-Z])+$/g.test(this.district) != true){
                    // this.ttl_err = true
                    return "Enter Characters only."
                }else{
                    // this.ttl_err = false
                    return []
                }
              }
         },

        validPanNo(){ 
            const errors = []
            if(this.panNumber.length > 0){
               
                if (!this.$v.panNumber.minLength) {    
                    errors.push('Enter Valid PAN Number.')
                    return errors
                    }
                console.log("from if ",this.$v.panNumber);
                if(/^(([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$)+$/g.test(this.panNumber) != true){
                    // this.ttl_err = true
                    return "Enter Valid PAN Number."
                }else{
                    // this.ttl_err = false
                    return []
                }
              }
         },

         validPrnNo(){ 
            const errors = []
            if(this.prnNumber.length > 0){
               
                if (!this.$v.prnNumber.minLength) {    
                    errors.push('Enter Valid PRN Number.')
                    return errors
                    }
                console.log("from if ",this.$v.prnNumber);
                
                if(/^(([a-zA-Z]){3}([0-9]){7}?)+$/g.test(this.prnNumber) != true){
                    // this.ttl_err = true
                    return "Enter Valid PRN Number."
                }else{
                    // this.ttl_err = false
                    return []
                }
              }
         },

         validMobNo(){ 
            const errors = []
            if(this.mobileNumber.length > 0){
               
                // if (!this.$v.mobileNumber.minLength) {    
                //     errors.push('Enter Valid Mobile Number.')
                //     return errors
                //     }
                console.log("from if ",this.$v.mobileNumber);
                if (!this.$v.mobileNumber.maxLength) {    
                    errors.push('Enter 10 digits only.')
                    return errors
                    }
                
                if(/^([0-9]{10})+$/g.test(this.mobileNumber) != true){
                    // this.ttl_err = true
                    return "Enter Valid Number."
                }
                else{
                    // this.ttl_err = false
                    return []
                }
              }
         },

         validAddress(){ 
            const errors = []
            if(this.addressContains.length > 0){
               
                if (!this.$v.addressContains.minLength) {    
                    errors.push('Enter minimum 3 characters for ‘Address Contains’ field.')
                                    console.log("from if ",this.$v.addressContains);

                    return errors
                    }
                else{

                    // this.ttl_err = false
                    return []
                }
              }
         },

        validPinNo(){ 
            const errors = []
            if(this.pincode.length > 0){
                if(/^[0-9]+$/g.test(this.pincode) != true){
                    return "Only Numbers are allowed"
                }
               
                if (!this.$v.pincode.minLength) {    
                    errors.push('Enter valid PinCode.')
                    return errors
                    }
                // if(/^[0-9 ]+$/.test(this.pincode))
                
                console.log("from if ",this.$v.pincode);
                    if (!this.$v.pincode.maxLength) {    
                    errors.push('Enter Valid Digit. ')
                    return errors
                    }
                 
                
                else{
                    // this.ttl_err = false
                    return []
                }
              }
         },


// validation for Institution

        validinEntityName(){ 
            const errors = []
            if(this.inEntityName.length > 0){
               
                if (!this.$v.inEntityName.maxLength) {    
                    errors.push('Enter Max 20 Characters only.')
                    return errors
                    }
                console.log("from if ",this.$v.inEntityName);
                // ('alpha', /^[a-zA-Z.' ]+$/)
                
                if(/^[a-zA-Z.' ]+$/g.test(this.inEntityName) != true){
                    // this.ttl_err = true
                    // !this.$v.entityNme.alphaRegex && errors.push('Only alphabets are allowed')
                     return "Enter Characters only."
                }else{
                    // this.ttl_err = false
                    return []
                }
              }
         },


        valid_inPinNo(){ 
            const errors = []
            if(this.inPincode.length > 0){

                if(/^[0-9]+$/g.test(this.inPincode) != true){
                    return "Only Numbers are allowed"
                }
               
                if (!this.$v.inPincode.minLength) {    
                    errors.push('Enter valid PinCode.')
                    return errors
                    }
                console.log("from if ",this.$v.inPincode);
                    if (!this.$v.inPincode.maxLength) {    
                    errors.push('Enter Valid Digit. ')
                    return errors
                    }
                 
                // if(inRohiniId.length==13+g.test(this.inRohiniId) != true){
                //     // this.ttl_err = true
                //     return "Enter Valid Rohini Number."
                // }
                else{
                    // this.ttl_err = false
                    return []
                }
              }
         },

        validRohiniId(){ 
            const errors = []
            if(this.inRohiniId.length > 0){

                if(/^[0-9]+$/g.test(this.inRohiniId) != true){
                    return "Only Numbers are allowed"
                }
               
                if (!this.$v.inRohiniId.minLength) {    
                    errors.push('Enter valid Rohini Id.')
                    return errors
                    }
                console.log("from if ",this.$v.inRohiniId);
                    if (!this.$v.inRohiniId.maxLength) {    
                    errors.push('max 13 Numbers.')
                    return errors
                    }
                 
                // if(inRohiniId.length==13+g.test(this.inRohiniId) != true){
                //     // this.ttl_err = true
                //     return "Enter Valid Rohini Number."
                // }
                else{
                    // this.ttl_err = false
                    return []
                }
              }
         },

         valid_inPanNo(){ 
            const errors = []
            if(this.inPanNumber.length > 0){
               
                if (!this.$v.inPanNumber.minLength) {    
                    errors.push('Enter Valid PAN Number.')
                    return errors
                    }
                console.log("from if ",this.$v.inPanNumber);
                if(/^(([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$)+$/g.test(this.inPanNumber) != true){
                    // this.ttl_err = true
                    return "Enter Valid PAN Number."
                }else{
                    // this.ttl_err = false
                    return []
                }
              }
         },


        valid_inPrnNumber(){ 
            const errors = []
            if(this.inPrnNumber.length > 0){
               
                if (!this.$v.inPrnNumber.minLength) {    
                    errors.push('Enter Valid PRN Number.')
                    return errors
                    }
                console.log("from if ",this.$v.inPrnNumber);
                
                if(/^(([a-zA-Z]){3}([0-9]){7}?)+$/g.test(this.inPrnNumber) != true){
                    // this.ttl_err = true
                    return "Enter Valid PRN Number."
                }else{
                    // this.ttl_err = false
                    return []
                }
              }
         },


         valid_inAddress(){ 
            const errors = []
            if(this.inAddressContains.length > 0){
               
                if (!this.$v.inAddressContains.minLength) {    
                    errors.push('Enter minimum 3 characters for ‘Address Contains’ field.')
                                    console.log("from if ",this.$v.inAddressContains);

                    return errors
                    }
                
                // if(/^(([a-zA-Z]){3}([0-9]){7}?)+$/g.test(this.inAddressContains) != true){
                //     // this.ttl_err = true
                //     return "Enter Valid PRN Number."
                // }
                else{

                    // this.ttl_err = false
                    return []
                }
              }
         },

         validinDistrict(){ 
            const errors = []
            if(this.inDistrict.length > 0){
               
                if (!this.$v.inDistrict.maxLength) {    
                    errors.push('Enter Max 20 Characters only.')
                    return errors
                    }
                console.log("from if ",this.$v.inDistrict);
                if(/^([a-zA-Z])+$/g.test(this.inDistrict) != true){
                    // this.ttl_err = true
                    return "Enter Characters only."
                }else{
                    // this.ttl_err = false
                    return []
                }
              }
         },

        validinCity(){ 
            const errors = []
            if(this.inCity.length > 0){
               
                if (!this.$v.inCity.maxLength) {    
                    errors.push('Enter Max 20 Characters only.')
                    return errors
                    }
                console.log("from if ",this.$v.inCity);
                if(/^([a-zA-Z])+$/g.test(this.inCity) != true){
                    // this.ttl_err = true
                    return "Enter Characters only."
                }else{
                    // this.ttl_err = false
                    return []
                }
              }
         },
         

         pageCount() {
              let l = this.GetIndividualData.length
                   
              return Math.ceil(l / 20) - 1;
            //   return Math.ceil(GetIndividualData.length/10)-1;
          },
    
     },


    components:{
        
    },

    data(){ return {
            sortorder:{count:0, type:null},
            sort_seek:{
                ind_seek:false,
                ind_sort:false,
                inst_seek:false,
                inst_sort:false,},
            download_excel:null,
            typesearch:false,
            typesearchtwo:false,

            isSearchIndiv:false,
            isSearchinsti:false,

            swap_final_count:false,
            fval:0,
            sval:0,
            totalLen:0,
            token:null,
            userValue:'',
            showIndividual: true,
            showInstitution: false, 
            individual:'Individual',
            institution:'Institution',

            showSearchInstitution: false,
            rsltnotfound:false,
            
            showSearchIndividual: false,
            showrsltnotfound:false,
            maxCount:20,
            max:50,
            maxAdd:100,
            maxMob:10,
            

            pageNumber: 1,
            size: 10,

            page: 1,

            pageIndu:1,
            
            inPage:1,
            totalLength:'',
            
        
           itemsPerPage: 10,
            
            firstName:'',
            lastName:'',
            panNumber:'',
            prnNumber:'',
            registeredCouncil:'',
            registrationNumber:'',
            providerType:'',
            mobileNumber:'',

            addressContains:'',
            state:'',
            city:'',
            pincode:'',
            district:'',

            selectFirstNameFilter:['Contains','Start With' ,'End With' ,'Equals'],
            firstNameFilter:'',

            selectLastNameFilter:['Contains','Start With' ,'End With' ,'Equals'],
            lastNameFilter:'',

            selectEntityNameFilter:['Contains','Start With' ,'End With' ,'Equals'],
            entityNameFilter:'',

            inEntityName:'',
            inRegistrationNumber:'',
            inPanNumber:'',
            inRohiniId:'',
            inPrnNumber:'',
            inProviderType:'',
            inGstinAddress:'',

            inAddressContains:'',
            inState:'',
            inCity:'',
            inPincode:'',
            inDistrict:'',

            providerName:'',
            aliasName:'',
            status:'',

            btndisCreate:'',
            btndisCreateindu:true,

            GetIndividualData : [],
            GetInstitutionData: [],

            selectRegisteredCouncil:['Himanchal Pradesh Medical Council',
                                    'Jammu & Kashmir Medical Council',
                                    'Jharkhand Medical Council',
                                    'Karnataka Medical Council',
                                    'Travancore Cochin Medical Council',
                                    'Madhya Pradesh Medical Council',
                                    'Maharashtra Medical Council',
                                    'Manipur Medical Council',
                                    'Mizoram Medical Council',
                                    'Nagaland Medical Council',
                                    'Orissa Council of Medical Registration',
                                    'Punjab Medical Council',
                                    'Rajasthan Medical Council',
                                    'Sikkim Medical Council',
                                    'Tamil Nadu Medical Council',
                                    'Telangana State Medical Council',
                                    'Tripura State Medical Council',
                                    'Uttar Pradesh Medical Council',
                                    'Uttarakhand Medical Council',
                                    'West Bengal Medical Council',
                                    'Central Council of Indian Medicine',
                                    'Others'],
            indiProviderType:['Doctor','Dentist','Physiotherapist','Homeopath','Ayurveda','Nurse','Psychotherapist','Other'],
            instiProviderType:['Hospital','Daycare Center','Nursing Home','Clinic','Rehabilitation Center','Pharmacy','Diagnostic Centre','Home Care provider','Hospice','Ambulance','Air Ambulance','Telehealth Provider','Psychiatric Facility','Other'],
            selectState:[],
            selectCity:['Mumbai','Panji','Agra'],

            instiSortBy:'',

            sortHeaderInsti:[
                {text:'PRN',value:'prn'},
                {text:'Provider Name',value:'entityName'},
                {text:'Provider Type',value:'providerType'},
                {text:'Status',value:'status'},
                {text:'Alias Name',value:'aliasName'},
                {text:'ROHINI ID',value:'rohiniId'},
                {text:'Address',value:'addressLine1'},
                {text:'City/Town',value:'cityTownVillage'},
                {text:'State',value:'state'},
                {text:'PinCode',value:'pinPostalCode'},
            ],

            indiviSortBy:'',

            sortHeaderIndividual:[
                {text:'PRN',value:'prn'},

                {text:'Provider Name',value:'firstName'},
                {text:'Provider Type',value:'providerType'},
                {text:'Status',value:'status'},
                {text:'Registration No.',value:'registrationNumber'},
                {text:'Council',value:'registeredCouncil'},
                {text:'Address',value:'addressLine1'},
                {text:'City/Town',value:'cityTownVillage'},
                {text:'State',value:'state'},
                {text:'PinCode',value:'pinPostalCode'},
            ],

            show1: false,

          

        // rules: {
        //         required: value =>value.length <= 3|| !!value || 'This Field is Required.',
                
        //         minRegNo: value => {
                            
        //                 const pattern = /^[a-zA-Z0-9]+$/
        //                 return pattern.test(value) || 'Enter valid Registration Number.'
        //         },

        //         minROHINI: v =>v.length <= 3|| v.length == 13 || 'Invalid ROHINI ID',
        //         minGSTIN: v=>v.length <= 3|| v.length == 15 || 'Invalid GSTIN Number',
        //         minAdd: v=>v.length >= 3 || 'Enter minimum 3 characters for ‘Address Contains’ field',
        //         minPIN: v=>v.length == 6 || 'Incorrect PinCode',
        //         minPRN: value => {
        //                     const pattern = /^([a-zA-Z]){3}([0-9]){7}?$/
                        
        //                 return value.length <= 3|| pattern.test(value) || 'Enter valid PRN.'
        //         },
        //         validPAN: value => {
        //                 const pattern = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/
        //                 return value.length <= 3|| pattern.test(value) || 'Enter valid PAN Number.'
        //         },
        //         validMOB: value => {
        //                 const pattern = /^([1-9]){1}([0-9]){9}?$/
        //                 return value.length <= 3|| pattern.test(value) || 'Enter valid Mobile Number.'
        //         },
        //         emailMatch: () => (`The email and password you entered don't match`),
        //         counter: value => value.length <= 20 || 'Max 20 characters',
        //         },

            rules: {
                required: value =>value.length <= 3|| !!value || 'This Field is Required.',
                // minRegNo: v =>v.length <= 3|| v.length == 5  || 'Enter valid Registration Number',
                minRegNo: value => {
                            // const pattern = /^([0-9])+$/
                        const pattern = /^[a-zA-Z0-9]+$/
                        return value.length <= 0 || pattern.test(value) || 'Enter valid Registration Number.'
                },

                // minROHINI: v => v.length == 13 || 'Invalid ROHINI ID',
                minGSTIN: v=> v.length <= 0 || v.length == 15 || 'Invalid GSTIN Number',
                minAdd: v=> v.length >= 3 || 'Enter minimum 3 characters for ‘Address Contains’ field',
                minPIN: v=>v.length <= 0 || v.length == 6 || 'Incorrect PinCode',
                // minPRN: value => {
                //             const pattern = /^([a-zA-Z]){3}([0-9]){7}?$/
                //         // const pattern = /^[a-zA-Z0-9]+$/
                //         return  pattern.test(value) || 'Enter valid PRN.'
                // },
                // validPAN: value => {
                //         const pattern = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/
                //         return  pattern.test(value) || 'Enter valid PAN Number.'
                // },
                validMOB: value => {
                        const pattern = /^([1-9]){1}([0-9]){9}?$/
                        return  pattern.test(value) || 'Enter valid Mobile Number.'
                },
                
                },



            clearAll:'',
            search:'',
            createNewProvider:'',

            pagelength:0,

        }
        
        },
        

    methods:{

        sortOD(){
            if(this.sortorder.count == 1){
                this.sortorder.count = 0;
                return
            }
            this.sortorder.count++;
            // this.SearchProviderIndividual(this.indiviSortBy,this.pageIndu) 

        },

        export_provider_excel(ident){
            var build_api = '';
             
            if(ident == 1){
                build_api = "provider/download-provider-individual?registrationNumber="+this.registrationNumber+"&registeredCouncil="+this.registeredCouncil
                         +"&prn="+this.prnNumber+"&providerType="+this.providerType+"&firstName="+this.firstName+
                         "&lastName="+this.lastName+"&addressContains="+this.addressContains+"&cityTownVillage="+this.city+"&district="+this.district+"&state="+this.state+"&pinPostalCode="+this.pincode
                         +"&firstNameFilter="+this.firstNameFilter+"&lastNameFilter="+this.lastNameFilter+"&mobileNumber="+this.mobileNumber+"&panNumber="+this.panNumber
                         
                              
            }else{
                build_api = "provider/download-provider-institution?entityName="+this.inEntityName+"&registrationNumber="+this.inRegistrationNumber+"&panNumber="+this.inPanNumber+"&rohiniId="+this.inRohiniId
                              +"&prn="+this.inPrnNumber+"&providerType="+this.inProviderType+"&cityTownVillage="+this.inCity+"&district="+this.inDistrict+"&state="+this.inState
                              +"&pinPostalCode="+this.inPincode+"&addressContains="+this.inAddressContains+"&entityNameFilter="+this.entityNameFilter+"&gstin="+this.inGstinAddress
            }
            console.log(" build_api -> ",build_api);

            try {
                this.GET(2, build_api, ( res,err) => {
                 if(!err){
                    console.log("response of excel is -> ", res.data)
                    if(!res.data.data.hasOwnProperty('base64')){
                         this.showToast('Excel data not found', this.TOST().WARNING);
                         return
                    } 
                    var item = res.data.data.base64;
                    var sourceexcel = "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,"+res.data.data.base64
                            
                    if (item === null|| item===undefined|| item===''){
                        this.showToast('No Data Found in response', this.TOST().WARNING);
                    }else{

                        this.showToast(res.data.msg, this.TOST().SUCCESS);
                        var a = document.createElement('a');
                        a.href = sourceexcel;
                        a.download = ident == 1 ? "Individual " : "Institution "  +"searched_providers_excel";
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);
                    }
                    
                    }
            })
            } catch (error) {
                console.log("Error getting excel file ", error)
                  this.showToast(res.data.msg, this.TOST().ERROR);
            }
          

        },

        seekinsti(){
            this.inPage = 1
            this.sort_seek.inst_seek = true
            this.SearchProviderInstitution(this.inPage);
        },

        seekind(indata){
            this.pageIndu=indata
            this.sort_seek.ind_seek = true
            this.SearchProviderIndividual(null,this.pageIndu);
        },

        sortIndiviChange(){
               this.pageIndu=1
               console.log("When SORT BY IS selected", this.typesearch)
               this.sort_seek.ind_sort = true
            //    if(this.typesearch==false){
              if(!this.sort_seek.ind_seek){
                  // this.sort_seek.inst_seek = true
                  this.getProviderALLIndividual(this.indiviSortBy,this.pageIndu)
               }else{
                  this.SearchProviderIndividual(this.indiviSortBy,this.pageIndu) 
               }
            
            //  this.SearchProviderIndividual(this.pageIndu)
            //  if(this.isSearchIndiv==true){
                
            //     this.SearchProviderIndividual(this.pageIndu) 
            // }else{
            //     this.getProviderALLIndividual(this.indiviSortBy,this.pageIndu)
            // }

        },

        sortInstiChange(){
            this.inPage=1
             this.getProviderALLInstitution(this.instiSortBy,this.inPage)

            //  if(this.isSearchinsti==true){
                // this.SearchProviderInstitution(this.inPage)
            //      
            // }else{
                
            //     this.getProviderALLInstitution(this.indiviSortBy,this.pageIndu)
            // }

            
        },




        nextPageData(indata){

            console.log("isSearchIndiv => ", this.typesearch);

            if(this.pagelength == indata){
                this.swap_final_count = true
            }else{
                this.swap_final_count = false
            }

            console.log('indiv sort value',this.indiviSortBy)
            console.log('indiv page no value',this.pageIndu)
            
            // this.getProviderALLIndividual(this.indiviSortBy,this.pageIndu)

            // this.SearchProviderIndividual(this.pageIndu)

            if(this.typesearch==false){
                this.getProviderALLIndividual(this.indiviSortBy,this.pageIndu)
                  console.log("from if ",this.typesearch)
            }else{
                console.log("from else ",this.typesearch)
                this.SearchProviderIndividual(this.indiviSortBy,this.pageIndu)
            }

        },

        nextPageBtnInsti(indata){
            
            console.log(this.typesearchtwo,' <-- typesearchtwo | sort value',this.instiSortBy)
            console.log('page no value',this.inPage)
            if(this.pagelengthinsti == indata){
                this.swap_final_count = true
            }else{
                this.swap_final_count = false
            }
            
            // this.getProviderALLInstitution(this.instiSortBy,this.inPage)

             if(this.typesearchtwo==false){
                this.getProviderALLInstitution(this.instiSortBy,this.inPage)
                 
            }else{
                this.SearchProviderInstitution(this.instiSortBy,this.inPage)
            }

        },

    

        showIndividualData() {
            this.showIndividual = true;
            this.showInstitution = false;
            console.log('individual data is------')
            this.getProviderALLIndividual(null,1)
        },
        showInstitutionData() {
            console.log('institution data is------')
            
            this.showIndividual = false;
            this.showInstitution = true;
            this.getProviderALLInstitution()            
        },

        
        cnclIndividual(){
            if((this.prnNumber===''||this.prnNumber===undefined||this.prnNumber===null) 
            && (this.firstName===''||this.firstName===undefined||this.firstName===null) 
            && (this.lastName===''||this.lastName===undefined||this.lastName===null) 
            && (this.panNumber===''||this.panNumber===undefined||this.panNumber===null)
            && (this.registeredCouncil===''||this.registeredCouncil===undefined||this.registeredCouncil===null) 
            && (this.registrationNumber===''||this.registrationNumber===undefined||this.registrationNumber===null)
            && (this.providerType===''||this.providerType===undefined||this.providerType===null) 
            && (this.mobileNumber===''||this.mobileNumber===undefined||this.mobileNumber===null)
            && (this.addressContains===''||this.addressContains===undefined||this.addressContains===null) 
            && (this.state===''||this.state===undefined||this.state===null)
            && (this.city===''||this.city===undefined||this.city===null) 
            && (this.pincode===''||this.pincode===undefined||this.pincode===null)
            && (this.district===''||this.district===undefined||this.district===null)
            && (this.firstNameFilter===''||this.firstNameFilter===undefined||this.firstNameFilter===null)
            && (this.lastNameFilter===''||this.lastNameFilter===undefined||this.lastNameFilter===null)
            && (this.indiviSortBy===''||this.indiviSortBy===undefined||this.indiviSortBy===null)

            ){
                
                return
            }else{
                this.isSearchIndiv=false
                this.typesearch = false
                this.pageIndu=1
                this.getProviderALLIndividual(null,1);
                this.btndisCreateindu=true,
                console.log('cancel Individual data',)
                this.firstName=''
                this.lastName=''
                this.prnNumber=''
                this.panNumber=''
                this.registeredCouncil=''
                this.registrationNumber=''
                this.providerType=''
                this.mobileNumber=''

                this.addressContains=''
                this.state=''
                this.city=''
                this.pincode=''
                this.district=''

                this.firstNameFilter=''
                this.lastNameFilter=''

                this.indiviSortBy='',

                this.$refs.form.resetValidation()
                showSearchIndividual=true,
                    this.showrsltnotfound=false
                    
            }
        },

        cnclInstitution(){
            if((this.inPrnNumber===''||this.inPrnNumber===undefined||this.inPrnNumber===null) 
            && (this.inRohiniId===''||this.inRohiniId===undefined||this.inRohiniId===null)
            && (this.inEntityName===''||this.inEntityName===undefined||this.inEntityName===null) 
            && (this.inRegistrationNumber===''||this.inRegistrationNumber===undefined||this.inRegistrationNumber===null)
            && (this.inProviderType===''||this.inProviderType===undefined||this.inProviderType===null) 
            && (this.inGstinAddress===''||this.inGstinAddress===undefined||this.inGstinAddress===null)
            && (this.inPanNumber===''||this.inPanNumber===undefined||this.inPanNumber===null)
            && (this.inAddressContains===''||this.inAddressContains===undefined||this.inAddressContains===null) 
            && (this.inState===''||this.inState===undefined||this.inState===null)
            && (this.inCity===''||this.inCity===undefined||this.inCity===null) 
            && (this.inPincode===''||this.inPincode===undefined||this.inPincode===null)
            && (this.inDistrict===''||this.inDistrict===undefined||this.inDistrict===null)
            && (this.entityNameFilter===''||this.entityNameFilter===undefined||this.entityNameFilter===null)
            
            && (this.instiSortBy===''||this.instiSortBy===undefined||this.instiSortBy===null)
            ){
                return
            }else{
                this.isSearchinsti=false
                this.typesearchtwo = false
                this.inPage=1
                this.getProviderALLInstitution()
                this.btndisCreate=true,
                this.inEntityName=''
                this.inRegistrationNumber=''
                this.inPanNumber=''
                this.inProviderType=''
                this.inGstinAddress=''
                this.inPrnNumber=''
                this.inRohiniId=''

                this.inAddressContains=''
                this.inState=''
                this.inCity=''
                this.inPincode=''
                this.inDistrict=''
                this.entityNameFilter=''

                
                this.instiSortBy=''

                this.$refs.form.resetValidation()
                this.showSearchInstitution =true,
                        this.rsltnotfound=false
                        
            }
        },
// API for Individual

createProviderIndividual(){
    // alert('coming soon')
     console.log('user access for create new provider',this.$store.getters.fetchRole)
     this.$router.push({
                        name: 'ProviderRegistrationnew',  
                        params: {
                                    empanelType: 'Individual',
                                    firstName:this.firstName,
                                    lastName:this.lastName,
                                    pan:this.panNumber,
                                    prn:this.prnNumber,
                                    registeredCouncil: this.registeredCouncil,
                                    registrationNumber:this.registrationNumber,
                                    providerType:this.providerType,
                                    mobileNumber:this.mobileNumber
                                }
                        })
    // this.showToast("COMING SOON",this.TOST().WARNING);
},

    getProviderALLIndividual(sortBy1,pageNum){
        console.log("get called individual sort by-------",sortBy1)
        console.log("get called individual page no ---->",pageNum)        
                    let self = this;   
                    console.log('next btn',self.pageIndu)
                    self.showSearchIndividual =true
                    self.showrsltnotfound=false
                    

                    let apiURL=''
                // if(sortBy1===''||sortBy1===undefined||sortBy1===null){
                //     apiURL=self.API_Service_provider()+"provider/provider-individual-all?perPage=20&pageNo="+pageNum
                // }else{
                    apiURL=self.API_Service_provider()+"provider/provider-individual-all?perPage=20&pageNo="+pageNum+"&sortBy="+sortBy1
                // }
                console.log("a99 apiURL -> ", apiURL);
                // return
                 if(this.sort_seek.ind_sort){
                        let setsort = {};
                        // sortorder:{count:0, type:null},
                        setsort[sortBy1] = this.sortorder.count == 1 ? 1 : -1
                        gen_api_url = gen_api_url+"&sortBy="+JSON.stringify(setsort)
                 }
                    axios({
                    method: "get",
                    url:apiURL,
                        // "http://claimsdrive-health.salesdrive.app/provider-management/provider-management-service/api/provider/provider-individual-all", 
                            // self.API_Service_provider()+"provider/provider-individual-all?perPage=20&pageNo="+self.pageIndu+"&sortBy="+self.indiviSortBy, 
                            // self.API_Service_provider()+"provider/provider-individual-all?pageNo="+self.page,     
                            headers: {
                                "Content-Type": "application/json", 
                                "x-access-token": self.token,
                                
                            },
                        })
                        .then(function(response) {
                                       
                    let apiData = response.data.data.data

                    console.log("API RESPONSE for ALL Provider Individual:::::",apiData);
                  

                    self.GetIndividualData = []
                    self.GetIndividualData= apiData
                    
                    self.pagelength=response.data.data.total_pages
                    console.log(self.pageIndu,' <-- pageindu | next btn, pg length is ----->',self.pagelength)

                     self.totalLen=response.data.data.total
                      let l = self.GetIndividualData.length
                
                
                    console.log( 20*self.pageIndu,'skip simulation ');

                      var less_enough = self.totalLen
                        var checkless_init = false
                        if(less_enough < 20){
                            checkless_init = false
                        }else{
                            checkless_init = true
                        }

                        // if less than 15 we have second value same as total value as no pagination will occur
                        if(checkless_init){
                            
                                // checkinit is true means the final count is more than 15 (- 1)
                                var traverse =  20*self.pageIndu
                                self.fval = traverse-19
                                if(self.swap_final_count){
                                    self.sval =  self.totalLen
                                }else{
                                    self.sval = traverse
                                }

                        }else if(self.totalLen == 0){
                            
                          self.sval = self.fval = 0
                        
                       }else{
                             self.fval = 1
                             self.sval = self.totalLen
                        }

                    console.log('total ----->',self.totalLen)

                    // if(self.indiviSortBy != ''){
                    //     self.selectSorting_indi()
                    // }
					
						console.log("Individual table DATA is---->>::",self.GetIndividualData);
			
                        })
                        .catch(function(error) {
                            console.log(error);
                            self.showToast(error.response.data.msg,self.TOST().ERROR);
                        });
             
            },
            

             SearchProviderIndividual(sortBy1,pageNum) {

                 console.log("incoming individual page no ---->",pageNum)

                    var self = this; 
                    if(self.prnNumber==""&&self.firstName==""&&self.lastName==""&&self.registrationNumber==""&&self.registeredCouncil==""
                    &&self.providerType==""&&self.addressContains==""&&self.state==""&&self.city==""&&self.pincode==""&&self.district==""
                    &&self.firstNameFilter==""&&self.lastNameFilter==""&&self.mobileNumber==""&&self.panNumber==""){
                        self.showToast("Please enter additional search criteria.",this.TOST().WARNING);
                        return
                    }
                    
                if(this.$v.$invalid){
                    console.log("Form has errors");  
                    this.showToast('Please Entered Valid data.', this.TOST().WARNING);
                    return
                }else{

                    let gen_api_url =  self.API_Service_provider()+"provider/search-provider-individual?registrationNumber="+self.registrationNumber+"&registeredCouncil="+self.registeredCouncil
                         +"&prn="+self.prnNumber+"&providerType="+self.providerType+"&firstName="+self.firstName+
                         "&lastName="+self.lastName+"&addressContains="+self.addressContains+"&cityTownVillage="+self.city+"&district="+self.district+"&state="+self.state+"&pinPostalCode="+self.pincode
                         +"&firstNameFilter="+self.firstNameFilter+"&lastNameFilter="+self.lastNameFilter+"&mobileNumber="+self.mobileNumber+"&panNumber="+self.panNumber
                         +"&perPage=20"+"&pageNo="+pageNum
                    // +"&sortBy="+sortBy1
                    // if(sortBy1!=''||sortBy1===undefined||sortBy1===null){
                    // sort ind sort add sort when search is called wid sort
                    if(this.sort_seek.ind_sort){
                        let setsort = {};
                        // sortorder:{count:0, type:null},
                        setsort[sortBy1] = this.sortorder.count == 1 ? 1 : -1
                       
                        gen_api_url = gen_api_url+"&sortBy="+JSON.stringify(setsort)
                    }

                    console.log("full sort thing is ", gen_api_url);

                    axios({
                    method:"get",
                    url:
                        // "http://claimsdrive-health.salesdrive.app/provider-management/provider-management-service/api/provider/search-provider-individual?prn="+self.prnNumber+"&firstName="+self.firstName+"&lastName="+self.lastName, 
                        //  "http://claimsdrive-health.salesdrive.app/provider-management/provider-management-service/api/provider/search-provider-individual?registrationNumber="+self.registrationNumber+"&registeredCouncil="+self.registeredCouncil+"&prn="+self.prnNumber+"&providerType="+self.providerType+"&firstName="+self.firstName+"&lastName="+self.lastName, 
                        gen_api_url, 
                            headers: {
                                "Content-Type": "application/json",
                                "x-access-token": self.token,    
                                // "x-access-token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI1ZmJlMDg4ZGM0NmRhODNlZTdmODcxYmIiLCJyb2xlIjoiQWRtaW5Vc2VyIiwiaWF0IjoxNjA2MzY5MzI0LCJleHAiOjE2Mzc5MDUzMjR9.iS5hZs0uImNFz-rxNaQ7CTCc6qS_hG4H0FsePZ5Sgqc",
                            },
                        })
                        .then(function(response) {
                                console.log("Search provider individual reporting>>>>>>", response);

                    if(!response.data.data || response.data.data.length == 0){
                        console.log("Array is either empty or does not exist")
                        self.showSearchIndividual =false,
                        self.showrsltnotfound=true
                        
                    }else{
                        self.rsltData=response.data.data.data
                        console.log("self.rsltData",self.rsltData)
                        self.showSearchIndividual =true,
                        self.showrsltnotfound=false
                        

                    }
                                
                                    let apiData=response.data.data.data
                                    let status=response.status
                                    
                                    console.log("API provider individual search RESPONSE -->:::::",apiData);
                                    console.log("API status RESPONSE -->:::::",status);
                                    
                                    self.GetIndividualData = []
                                    self.GetIndividualData =apiData
                                    self.typesearch = true
                                    self.isSearchindiv = true
                                    console.log('isSearchindiv in SearchProviderIndividual function ', self.isSearchindiv)
                                  

                // for individual search pagination
                    
                    self.pagelength=response.data.data.total_pages
                    console.log(self.pageIndu,' <-- pageindu search | search next btn, pg length is ----->',self.pagelength)

                     self.totalLen=response.data.data.total

                      let l = self.GetIndividualData.length
                
                    console.log( 20*self.pageIndu,'search skip simulation ');

                      var less_enough = self.totalLen
                        var checkless_init = false
                        if(less_enough < 20){
                            checkless_init = false
                        }else{
                            checkless_init = true
                        }

                        // if less than 15 we have second value same as total value as no pagination will occur
                        if(checkless_init){
                            
                                // checkinit is true means the final count is more than 15 (- 1)
                                var traverse =  20*self.pageIndu
                                self.fval = traverse-19
                                if(self.swap_final_count){
                                    self.sval =  self.totalLen
                                }else{
                                    self.sval = traverse
                                }

                        }else if(self.totalLen == 0){
                            
                          self.sval = self.fval = 0
                        
                       }else{
                             self.fval = 1
                             self.sval = self.totalLen
                        }

                    console.log('total search----->',self.totalLen)
                                    // self.prnNumber=""
                                    // console.log("prn clear status RESPONSE -->",self.prnNumber);

            
                        })
                        .catch(function (error) {
                    console.log("Search individual Error>>>>>>", error.response);
                    
                        // self.showToast(error.response.data.msg,self.TOST().ERROR);
                        self.showToast('No matching provider found.',self.TOST().ERROR);
                        self.showSearchIndividual =false
                        self.showrsltnotfound=true
        
        
                    if(self.userValue=="SuperUser" ||self.userValue=="DEO" || self.userValue=="Reviewer" || self.userValue=="ProviderManagementTeam"){
                                self.btndisCreateindu =false
                    }else{
                        self.btndisCreateindu =true
                    }

                });
          }
                    
        },



// API for Institution

         createProviderInstitution() {
                // alert('coming soon')
                this.$router.push({
                    
                                    name: 'ProviderRegistrationnew', 
                                    params: {
                                        empanelType: 'Institution',
                                        entityName:this.inEntityName,
                                        pan:this.inPanNumber,
                                        prn:this.inPrnNumber,
                                        rohiniId: this.inRohiniId,
                                        registrationNumber:this.inRegistrationNumber,
                                        providerType:this.inProviderType,
                                        gstin:this.inGstinAddress,
                                        address:this.inAddressContains,
                                        state:this.inState,
                                        cityTownVillage:this.inCity,
                                        district:this.inDistrict,
                                        pincode:this.inPincode

                                    }
                                })



                                            // this.showToast("Coming Soon" )
                                            // this.$refs.form.validate()
                                            // this.showToast("COMING SOON",this.TOST().WARNING);

        //             if (this.$refs.form.validate()){
        //                 let self=this
        //                 // self.showLoader('Loading', true);
        //                 let demodata={
                            
        //                     registeredEntity: "string",
        //                     status: "string",
        //                     entityName: self.inEntityName,
        //                     aliasName: self.aliasName,
        //                     panNumber: self.inPanNumber,
        //                     providerType: self.inProviderType,
        //                     rohiniId: self.inRohiniId,
        //                     registrationNumber: self.inRegistrationNumber,
        //                     registeredBody: "string",
        //                     registrationValidUpto: "Unknown Type: date",
        //                     email: "string",
        //                     telephoneNumber: 0,
        //                     faxNumber: 0,
        //                     servicesProvided: "string",
        //                     childProvider: "string",
        //                     parentProvider: "string",
        //                     mappingType: "string",
        //                     addressLine1: self.inAddressContains,
        //                     addressLine2: "string",
        //                     addressLine3: "string",
        //                     pinPostalCode: self.inPincode,
        //                     cityTownVillage: self.inCity,
        //                     district: self.inDistrict,
        //                     state: self.inState,
        //                     country: "string",
        //                     other: "string",
        //                     validationDetails: {},
        //                     uploadDoctorsList: {},
        //                     providerStatusHistory: [
        //                         {}
        //                     ]
        //                 }

        //                 console.log("from demodata--------->",demodata);

        //                 axios({
        //                     method: "post",
        //                     url:
        //                         "http://claimsdrive-health.salesdrive.app/provider-management/provider-management-service/api/provider/create-provider-institution",
        //                     headers: {
        //                         "Content-Type": "application/json",
        //                         "x-access-token": self.token
        //                         // "x-access-token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI1ZmJlMDg4ZGM0NmRhODNlZTdmODcxYmIiLCJyb2xlIjoiQWRtaW5Vc2VyIiwiaWF0IjoxNjA2MzY5MzI0LCJleHAiOjE2Mzc5MDUzMjR9.iS5hZs0uImNFz-rxNaQ7CTCc6qS_hG4H0FsePZ5Sgqc",
        //                     },
        //                     data:demodata,
        //                 })
        //                 .then(function (response) {
        //                     console.log("Provider Search Institution APi Response>>>>>>", response);
        //                     self.GetData =response.data  
        //                 })
        //                 .catch(function (error) {
        //                     console.log(" APi Response>>>>>>", error);
        //                 });
        //             }else{
        //         this.$refs.form.validate()
        //         this.showToast("Please Fill Required Fields",this.TOST().WARNING);
        //         }
             },

            getProviderALLInstitution(sortBy,pageNo) {
                console.log("sort by-------",sortBy)
                console.log("page no ---->",pageNo)
                
                    let self = this; 
                    self.showSearchInstitution =true 
                    self.rsltnotfound=false
                    
                let apiURL=''
                // if(self.instiSortBy===''||self.instiSortBy===undefined||self.instiSortBy===null){
                if(sortBy===''||sortBy===undefined||sortBy===null){
                    apiURL=self.API_Service_provider()+"provider/provider-institution-all?perPage=20&pageNo="+pageNo
                }else{
                    apiURL=self.API_Service_provider()+"provider/provider-institution-all?perPage=20&pageNo="+pageNo+"&sortBy="+sortBy
                }
                    axios({
                    method: "get",


                    
                    url:apiURL,
                        // "http://claimsdrive-health.salesdrive.app/provider-management/provider-management-service/api/provider/provider-institution-all", 
                        
                        // self.API_Service_provider()+"provider/provider-institution-all?perPage=20&pageNo="+self.inPage+"&sortBy="+self.instiSortBy, 
                        
                            
                            headers: {
                                "Content-Type": "application/json", 
                                "x-access-token": self.token,
                            }
                        })
                        .then(function(response) {
                                                          
                    let apiData = response.data.data.data

                    console.log("get provider institution all data responce------->>>",apiData);
                    self.showtable=true,
                    self.showNosearch=false,

                    self.GetInstitutionData = []
                    self.GetInstitutionData= apiData

		self.pagelengthinsti=response.data.data.total_pages
                    console.log('next btn ----->',self.pagelengthinsti)

                    self.totalLength=response.data.data.total
                    console.log('total ----->',self.totalLength)
                    self.totalLen = self.totalLength
                     let l = self.GetIndividualData.length
                
                
                    console.log( 20*self.inPage,'skip simulation ');

                      var less_enough = self.totalLen
                        var checkless_init = false
                        if(less_enough < 20){
                            checkless_init = false
                        }else{
                            checkless_init = true
                        }

                        // if less than 15 we have second value same as total value as no pagination will occur
                        if(checkless_init){
                            
                                // checkinit is true means the final count is more than 15 (- 1)
                                var traverse =  20*self.inPage
                                self.fval = traverse-19
                                if(self.swap_final_count){
                                    self.sval =  self.totalLen
                                }else{
                                    self.sval = traverse
                                }

                        }else if(self.totalLen == 0){
                            
                          self.sval = self.fval = 0
                        
                       }else{
                             self.fval = 1
                             self.sval = self.totalLen
                        }

                    // if(self.instiSortBy != ''){
                    //     self.selectSorting()


                    // }
			
                        })
                        .catch(function(error) {
                            console.log(error);
                            self.showToast(error.response.data.msg,self.TOST().ERROR);
                        });
             
            },

            SearchProviderInstitution(pageNo) {
                //  if (this.$refs.form.validate()){
                    var self = this;
                     
                    if(self.inPanNumber==""&&self.inRohiniId==""&&self.inPrnNumber==""&&self.inEntityName==""&&self.inRegistrationNumber==""
                    &&self.inProviderType==""&&self.inState==""&&self.inCity==""&&self.inPincode==""&&self.inDistrict==""
                    &&self.inAddressContains==""&&self.entityNameFilter==""&&self.inGstinAddress==""){
                        self.showToast("Please enter additional search criteria.",this.TOST().WARNING);
                    return
                    }
                    
                    // let demodata={
                    //         panNumber: self.inPanNumber,
                    //         rohiniId: self.inRohiniId,
                    //         prn:self.inPrnNumber,
                    //         entityName:self.inEntityName,
                    //         registrationNumber:self.inRegistrationNumber,
                    //         providerType:self.inProviderType,
                    //             addressContains:self.inAddressContains,
                    //             // addressLine2:self.inAddressContains,
                    //             // addressLine3:self.inAddressContains,
                    //         state:self.inState,
                    //         cityTownVillage:self.inCity,
                    //         pinPostalCode:self.inPincode,
                    //         district:self.inDistrict,
                    //         entityNameFilter:self.entityNameFilter,
                    //         gstin:self.inGstinAddress
                    // }
            self.$v.$touch();

            if(self.$v.$invalid){
                console.log("Form has errors");  
                self.showToast('Please Entered Valid data.', self.TOST().WARNING);
                return
             }else{


                    axios({
                    method: "get",
                    
                    url:
                            // "http://claimsdrive-health.salesdrive.app/provider-management/provider-management-service/api/provider/search-provider-institution?prn="+self.inPrnNumber, 
                            //   "http://claimsdrive-health.salesdrive.app/provider-management/provider-management-service/api/provider/search-provider-institution?entityName="+self.inEntityName+"&registrationNumber="+self.inRegistrationNumber+"&panNumber="+self.inPanNumber+"&rohiniId="+self.inRohiniId+"&prn="+self.inPrnNumber+"&providerType="+self.inProviderType, 
                              self.API_Service_provider()+"provider/search-provider-institution?entityName="+self.inEntityName+"&registrationNumber="+self.inRegistrationNumber+"&panNumber="+self.inPanNumber+"&rohiniId="+self.inRohiniId
                              +"&prn="+self.inPrnNumber+"&providerType="+self.inProviderType+"&cityTownVillage="+self.inCity+"&district="+self.inDistrict+"&state="+self.inState
                              +"&pinPostalCode="+self.inPincode+"&addressContains="+self.inAddressContains+"&entityNameFilter="+self.entityNameFilter+"&gstin="+self.inGstinAddress
                              +"&perPage=20&pageNo="+pageNo, 

// +"entityNameFilter="+self.entityNameFilter
                            headers: {
                                "Content-Type": "application/json",
                                "x-access-token": self.token,    
                                // "x-access-token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI1ZmJlMDg4ZGM0NmRhODNlZTdmODcxYmIiLCJyb2xlIjoiQWRtaW5Vc2VyIiwiaWF0IjoxNjA2MzY5MzI0LCJleHAiOjE2Mzc5MDUzMjR9.iS5hZs0uImNFz-rxNaQ7CTCc6qS_hG4H0FsePZ5Sgqc",
                            },
                          

                        })
                        .then(function(response) {

                    if(!response.data.data.data || response.data.data.length == 0){
                        console.log("Array is either empty or does not exist")
                        self.showSearchInstitution =false,
                        self.rsltnotfound=true
                        
                    }else{
                        self.rsltData=response.data.data.data
                        console.log("self.rsltData",self.rsltData)
                        self.showSearchInstitution =true,
                        self.rsltnotfound=false
                        

                    }
                                
                        let apiData=response.data.data.data
                        
                        console.log("API provider institution search RESPONSE -->:::::",apiData);
                        
                        self.GetInstitutionData = []
                        self.GetInstitutionData =apiData
                        self.typesearchtwo = true
                        self.isSearchinsti=true

        // search pagination for institution

                    self.pagelengthinsti=response.data.data.total_pages
                    console.log('next btn ----->',self.pagelengthinsti)

                    self.totalLength=response.data.data.total
                    console.log('total ----->',self.totalLength)
                    self.totalLen = self.totalLength
                     let l = self.GetIndividualData.length
                
                
                    console.log( 20*self.inPage,'skip simulation ');

                      var less_enough = self.totalLen
                        var checkless_init = false
                        if(less_enough < 20){
                            checkless_init = false
                        }else{
                            checkless_init = true
                        }

                        // if less than 15 we have second value same as total value as no pagination will occur
                        if(checkless_init){
                            
                                // checkinit is true means the final count is more than 15 (- 1)
                                var traverse =  20*self.inPage
                                self.fval = traverse-19
                                if(self.swap_final_count){
                                    self.sval =  self.totalLen
                                }else{
                                    self.sval = traverse
                                }

                        }else if(self.totalLen == 0){
                            
                          self.sval = self.fval = 0
                        
                       }else{
                             self.fval = 1
                             self.sval = self.totalLen
                        }

                    console.log('total search institution result----->',self.totalLen)
                                    
                        })
                        .catch(function (error) {
                    console.log("Search reporting Error>>>>>>", error.response);
                        
                        // self.showToast(error.response.data.msg,self.TOST().ERROR);
                        self.showToast('No matching provider found.',self.TOST().ERROR);
                        self.showSearchInstitution =false
                        self.rsltnotfound=true
                        
                if(self.userValue=="SuperUser" || self.userValue=="DEO" || self.userValue=="Reviewer" || self.userValue=="ProviderManagementTeam"){
                        self.btndisCreate =false
                }else{
                    self.btndisCreate =true
                }
                        
                        

                });
                //  }else{
                //  this.$refs.form.validate()
                //  this.showToast("Please Fill Required Fields",this.TOST().WARNING);
                //  }   
             } 
        },

        getALLState() {
                
                    let self = this;   
                    axios({
                    method: "get",
                    
                    url:
                        "http://claimsdrive-health.salesdrive.app/master-data/master-data-service/api/state/get-state-all", 
                            headers: {
                                "Content-Type": "application/json", 
                                "x-access-token": self.token,
                                //  "x-access-token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI1ZmJlMDg4ZGM0NmRhODNlZTdmODcxYmIiLCJyb2xlIjoiQWRtaW5Vc2VyIiwiaWF0IjoxNjA2MzY5MzI0LCJleHAiOjE2Mzc5MDUzMjR9.iS5hZs0uImNFz-rxNaQ7CTCc6qS_hG4H0FsePZ5Sgqc",
                            }
                        })
                        .then(function(response) {
                    
                            console.log('get all state data responce------->>>',response.data);
                                      
                    let apiData = response.data.data.data

                    console.log("API RESPONSE:::::",apiData);
                    

                    self.selectState = []
                    self.selectState= apiData
					

						console.log("all state list DATA is---->>::",self.selectState);
			
                        })
                        .catch(function(error) {
                            console.log(error);
                            self.showToast(error.response.data.msg,self.TOST().ERROR);

                        });
             
            },
    }
    
}
</script>

<style>
.verticalline{
    height: 38px !important;
    width: 0.5px !important;
    margin-right: 20px !important;
    margin-left: 30px !important;

    background-color: #C1C8CC;
}
.cstm-clr-pr{
    color:#646666 !important;
    margin-bottom: 5px !important;
    font-size: 13px !important;
}

.di-btn {
    text-transform: none;
}

.activebackground {
    background-color: #23B1A9 !important;
    color:rgb(247 247 247) !important;
}

.inactivewhitebackground {
        background-color: #ffffff !important;
    /* color: #646666 !important; */
      color:#10242B !important;

}

.title-clr-prtbs{
    color:#10242B !important;
    /* margin-bottom: 5px; */
}
.cstm-clr-prtbs{
    color:#646666 !important;
    /* margin-bottom: 5px !important; */
    font-size: 13px !important;
}
.btn_pr_btn{
  color:#10242B;
  opacity:100% !important;  
  font-size:13px !important;
  font-weight: 700;
  /* border-left:0.5px solid #C1C8CC; */

    border: solid;
    border-top-width: thin;
    border-right-width: thin;
    border-bottom-width: thin;
    border-left-width: thin;
    transition: inherit;
    border-top-color: rgba(255, 255, 255, 0.26);
    border-right-color: rgba(255, 255, 255, 0.26);
    border-bottom-color: rgba(255, 255, 255, 0.26);
    border-left-color: rgba(193, 200, 204, 0.26);
}
.cust_pr .v-input{
    font-size: 13px !important;
}
.cust_pr .v-input input {
    font-size: 13px !important;
}

.cust_pr .v-input .v-label {
    font-size: 13px !important;
}

.comboxPaddingPro .v-input__control .v-input__slot{
    padding-left: 0 !important;
}


</style>