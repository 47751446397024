<template>
	<div id="main">
		<v-form ref="recoveryForm" v-model="valid" :disabled="disableFields">
			<v-card tile class="pa-3">
				<v-layout>
					<v-flex xs3>
						<v-layout column class="cust_pln_em mx-2">
							<p class="font-weight-bold cstm-clr-pln-iphd_claims">Claim Number</p>
							<v-text-field solo label="Enter..." v-model="claimNumber" class="rounded-0" :maxlength="max30" disabled> </v-text-field>
						</v-layout>
					</v-flex>
					<v-flex xs3>
						<v-layout column class="cust_pln_em mx-2">
							<p class="font-weight-bold cstm-clr-pln-iphd_claims">Feature Number</p>
							<v-text-field solo label="Enter..." v-model="featureNumber" class="rounded-0" :maxlength="max30" disabled> </v-text-field>
						</v-layout>
					</v-flex>
					<v-flex xs3>
						<v-layout column class="cust_pln_em mx-2">
							<p class="font-weight-bold cstm-clr-pln-iphd_claims">Member Name</p>
							<v-text-field solo label="Enter..." v-model="memberName" class="rounded-0" :maxlength="max30" disabled>
							</v-text-field>
						</v-layout>
					</v-flex>
                    <v-flex xs3>
						<v-layout column class="cust_pln_em mx-2">
							<p class="font-weight-bold cstm-clr-pln-iphd_claims">Provider Name</p>
							<v-text-field solo label="Enter..." v-model="providerName" class="rounded-0" :maxlength="max30" disabled> </v-text-field>
						</v-layout>
					</v-flex>
                    <v-flex xs3>
						<v-layout column class="cust_pln_em mx-2">
							<p class="font-weight-bold cstm-clr-pln-iphd_claims">Adjudicator Name</p>
							<v-text-field solo label="Enter..." v-model="adjudicatorName" class="rounded-0" :maxlength="max30" disabled> </v-text-field>
						</v-layout>
					</v-flex>
					
					
				</v-layout>
				<v-layout class="">
                    <v-flex xs3>
						<v-layout column class="cust_pln_em mx-2">
							<p class="font-weight-bold cstm-clr-pln-iphd_claims">Claimed Amount</p>
							<v-text-field solo label="Enter..." v-model="claimPaidAmount" class="rounded-0" :maxlength="max30" disabled> </v-text-field>
						</v-layout>
					</v-flex>
                    <v-flex xs3>

					</v-flex>
                    <v-flex xs3>

					</v-flex>
                    <v-flex xs3>
                    </v-flex>
                    <v-flex xs3>
                    </v-flex>

				</v-layout>

            <v-layout class="mx-2">

                <v-flex xs3>
                    <div
                        style="background-color: #F7F7F7; cursor:pointer;border: 0.2px solid #C1C8CC;"
                        class="pa-2 pr-14 "
                        :class="[
                        QueryStatus == 'OFRtriggers'
                            ? 'activeRadioclass'
                            : '',
                        ]"
                        @click="changeQueryStatus('OFRtriggers','')"
                    >
                        <div
                        class="font-weight-bold"
                        :class="[
                            QueryStatus == 'OFRtriggers'
                            ? 'text-white-insrns1'
                            : 'text-dark-insrns1',
                        ]"
                        >
                        <!-- QueryStatus -->
                        OFR Triggers
                        </div>
                    </div>
                </v-flex>

                <v-flex xs3>
                    <div
                        style="background-color: #F7F7F7;  cursor:pointer;border: 0.2px solid #C1C8CC;"
                        class="pa-2"
                        :class="[
                        QueryStatus == 'policyAndMemberDetails'
                            ? 'activeRadioclass'
                            : '',
                        ]"
                        
                        @click="changeQueryStatus('policyAndMemberDetails','Policy And Memeber Details')"
                    >
                        <div
                        class="font-weight-bold"
                        :class="[
                            QueryStatus == 'policyAndMemberDetails'
                            ? 'text-white-insrns1'
                            : 'text-dark-insrns1',
                        ]"
                        >
                        Policy & Member Details
                        </div>
                    </div>
                </v-flex>

                <v-flex xs3>
                    <div
                        style="background-color: #F7F7F7;  cursor:pointer;border: 0.2px solid #C1C8CC;"
                        class="pa-2"
                        :class="[
                        QueryStatus == 'providerDetails'
                            ? 'activeRadioclass'
                            : '',
                        ]"

                        @click="changeQueryStatus('providerDetails','Provider Details')"
                        
                    >
                        <div
                        class="font-weight-bold"
                        :class="[
                            QueryStatus == 'providerDetails'
                            ? 'text-white-insrns1'
                            : 'text-dark-insrns1',
                        ]"
                        >
                        Provider Details
                        </div>
                    </div>
                </v-flex>

                <v-flex xs3>
                    <div
                        style="background-color: #F7F7F7;  cursor:pointer;border: 0.2px solid #C1C8CC;"
                        class="pa-2"
                        :class="[
                        QueryStatus == 'diagnosisAndIlness'
                            ? 'activeRadioclass'
                            : '',
                        ]"

                        @click="changeQueryStatus('diagnosisAndIlness','Diagnosis And Ilness')"
                    >
                        <div
                        class="font-weight-bold"
                        :class="[
                            QueryStatus == 'diagnosisAndIlness'
                            ? 'text-white-insrns1'
                            : 'text-dark-insrns1',
                        ]"
                        >
                        Diagnosis & illness
                        </div>
                    </div>
                </v-flex>
                                
                <v-flex xs3>
                    <div
                        style="background-color: #F7F7F7;  cursor:pointer;border: 0.2px solid #C1C8CC;"
                        class="pa-2"
                        :class="[
                        QueryStatus == 'overallRating'
                            ? 'activeRadioclass'
                            : '',
                        ]"
                        @click="changeQueryStatus('overallRating','Overall Rating')"
                    >
                        <div
                        class="font-weight-bold"
                        :class="[
                            QueryStatus == 'overallRating'
                            ? 'text-white-insrns1'
                            : 'text-dark-insrns1',
                        ]"
                        >
                        Overall Rating
                        </div>
                    </div>
                </v-flex>

            </v-layout>

            <v-layout class="mx-2">
                <v-flex xs12 style="min-height: 160px;border: 0.5px solid #C1C8CC;">
                    <!--tab 1  -->
                    <div v-if="QueryStatus == 'OFRtriggers'">
                        <v-card class="my-8 mx-2">
                            <v-data-table
                                :headers="viewScoreHeader"
                                :items="viewScoreItems"
                                hide-default-footer
                                dense
                            >
                                <!-- <template #item.serialNumber="{ item }">
                                <td>
                                    {{ viewScoreItems.indexOf(item) + 1 }}
                                </td>
                                </template> -->

                            </v-data-table>
                        </v-card>
                    </div>

                    <!--tab 2  -->
                    <div v-if="QueryStatus == 'policyAndMemberDetails'">
                        <!-- <v-form ref="tab2Form" v-model="validT2" :disabled="disableFields"> -->
                        <v-layout v-for="(i, index) in GetQueData" :key="index" class="cust_pln_em mx-2 mt-3">

                            <v-flex xs4 class="mt-3">
                                <p class="font-weight-bold cstm-clr-pln-iphd_claims">
                                    {{i.question}}
                                </p>
                            </v-flex>
                                    
                            <v-flex xs2>        
                                    <v-select
                                        solo
                                        :items="SelectItems"
                                        label="Select"
                                        class="rounded-0"
                                        :rules="[rules.required]"
                                        :disabled="disableWhenComplteOFR"
                                        :value="i.answer"
                                        @input="(answer) => updateTab2QueAns(i, answer,index,GetQueData)"

                                        >
                                    </v-select>
                            </v-flex>
                        </v-layout>

                        <!-- <v-layout class="cust_pln_em mx-2 mt-3">
                            <v-flex xs4 class="mt-3">
                                <p class="font-weight-bold cstm-clr-pln-iphd_claims">
                                    Was there any fraud claim against member 
                                </p>
                            </v-flex>
                                    
                            <v-flex xs2>        
                                    <v-select
                                        solo
                                        :items="SelectItems"
                                        label="Select"
                                        v-model="tab2one"
                                        class="rounded-0" 
                                        :rules="[rules.required]"
                                        :disabled="disableWhenComplteOFR"
                                        >
                                    </v-select>
                            </v-flex>
                        </v-layout>

                        <v-layout class="cust_pln_em mx-2 ">
                            <v-flex xs4 class="mt-3">
                                <p class="font-weight-bold cstm-clr-pln-iphd_claims">
                                    Was the master policy marked for fraud 
                                </p>
                            </v-flex>
                                    
                            <v-flex xs2>        
                                    <v-select
                                        solo
                                        :items="SelectItems"
                                        label="Select"
                                        v-model="tab2two"
                                        class="rounded-0" 
                                        :rules="[rules.required]"
                                        :disabled="disableWhenComplteOFR"
                                        >
                                    </v-select>
                            </v-flex>
                        </v-layout> -->
                        <!-- </v-form> -->
                    </div>
                
                <!--tab 3  -->
                    <div v-if="QueryStatus == 'providerDetails'">
                        <v-layout v-for="(i, index) in GetQueData" :key="index" class="cust_pln_em mx-2 mt-3">
                            <v-flex xs4 class="mt-3">
                                <p class="font-weight-bold cstm-clr-pln-iphd_claims">
                                    {{i.question}}
                                </p>
                            </v-flex>
                                    
                            <v-flex xs2>        
                                    <v-select
                                        solo
                                        :items="SelectItems"
                                        label="Select"
                                        class="rounded-0"
                                        :rules="[rules.required]"
                                        :disabled="disableWhenComplteOFR"
                                        :value="i.answer"
                                        @input="(answer) => updateTab3QueAns(i, answer,index,GetQueData)"
                                        >
                                    </v-select>
                            </v-flex>
                        </v-layout>

                        <!-- <v-layout class="cust_pln_em mx-2 mt-3">
                            <v-flex xs4 class="mt-3">
                                <p class="font-weight-bold cstm-clr-pln-iphd_claims">
                                    Was the provider blacklist or suspended 
                                </p>
                            </v-flex>
                                    
                            <v-flex xs2>        
                                    <v-select
                                        solo
                                        :items="SelectItems"
                                        label="Select"
                                        v-model="tab3one"
                                        class="rounded-0" 
                                        :rules="[rules.required]"
                                        :disabled="disableWhenComplteOFR"
                                        >
                                    </v-select>
                            </v-flex>
                        </v-layout>

                        <v-layout class="cust_pln_em mx-2 ">
                            <v-flex xs4 class="mt-3">
                                <p class="font-weight-bold cstm-clr-pln-iphd_claims">
                                    Was there a history of fraud claims against provider
                                </p>
                            </v-flex>
                                    
                            <v-flex xs2>        
                                    <v-select
                                        solo
                                        :items="SelectItems"
                                        label="Select"
                                        v-model="tab3two"
                                        class="rounded-0" 
                                        :rules="[rules.required]"
                                        :disabled="disableWhenComplteOFR"
                                        >
                                    </v-select>
                            </v-flex>
                        </v-layout> -->

            <!--tab 4  -->
                    </div>
                <!-- tab 4 -->
                    <div v-if="QueryStatus == 'diagnosisAndIlness'">
                        <v-layout v-for="(i, index) in GetQueData" :key="index" class="cust_pln_em mx-2 mt-3">
                            <v-flex xs4 class="mt-3">
                                <p class="font-weight-bold cstm-clr-pln-iphd_claims">
                                    {{i.question}}
                                </p>
                            </v-flex>
                                    
                            <v-flex xs2>        
                                    <v-select
                                        solo
                                        :items="SelectItems"
                                        label="Select"
                                        class="rounded-0"
                                        :rules="[rules.required]"
                                        :disabled="disableWhenComplteOFR"
                                        :value="i.answer"
                                        @input="(answer) => updateTab4QueAns(i, answer,index,GetQueData)"
                                        >
                                    </v-select>
                            </v-flex>
                        </v-layout>

                        <!-- <v-layout class="cust_pln_em mx-2 mt-3">
                            <v-flex xs4 class="mt-3">
                                <p class="font-weight-bold cstm-clr-pln-iphd_claims">
                                    Is Medical Procedure matching with Diagnosis  
                                </p>
                            </v-flex>
                                    
                            <v-flex xs2>        
                                    <v-select
                                        solo
                                        :items="SelectItems"
                                        label="Select"
                                        v-model="tab4one"
                                        class="rounded-0" 
                                        :rules="[rules.required]"
                                        :disabled="disableWhenComplteOFR"
                                        >
                                    </v-select>
                            </v-flex>
                        </v-layout>

                        <v-layout class="cust_pln_em mx-2">
                            <v-flex xs4 class="mt-3">
                                <p class="font-weight-bold cstm-clr-pln-iphd_claims">
                                    Is diagnosis matching with gender of patient  
                                </p>
                            </v-flex>
                                    
                            <v-flex xs2>        
                                    <v-select
                                        solo
                                        :items="SelectItems"
                                        label="Select"
                                        v-model="tab4two"
                                        class="rounded-0" 
                                        :rules="[rules.required]"
                                        :disabled="disableWhenComplteOFR"
                                        >
                                    </v-select>
                            </v-flex>
                        </v-layout>

                        <v-layout class="cust_pln_em mx-2">
                            <v-flex xs4 class="mt-3">
                                <p class="font-weight-bold cstm-clr-pln-iphd_claims">
                                    Is diagnosis matching with age of patient  
                                </p>
                            </v-flex>
                                    
                            <v-flex xs2>        
                                    <v-select
                                        solo
                                        :items="SelectItems"
                                        label="Select"
                                        v-model="tab4three"
                                        class="rounded-0" 
                                        :rules="[rules.required]"
                                        :disabled="disableWhenComplteOFR"
                                        >
                                    </v-select>
                            </v-flex>
                        </v-layout>

                        <v-layout class="cust_pln_em mx-2">
                            <v-flex xs4 class="mt-3">
                                <p class="font-weight-bold cstm-clr-pln-iphd_claims">
                                    Are the bills submitted matching with diagnosis  
                                </p>
                            </v-flex>
                                    
                            <v-flex xs2>        
                                    <v-select
                                        solo
                                        :items="SelectItems"
                                        label="Select"
                                        v-model="tab4four"
                                        class="rounded-0" 
                                        :rules="[rules.required]"
                                        :disabled="disableWhenComplteOFR"
                                        >
                                    </v-select>
                            </v-flex>
                        </v-layout> -->
                    </div>
                
                <!--tab 5  -->
                    <div v-if="QueryStatus == 'overallRating'">
                        <v-layout v-for="(i, index) in GetQueData" :key="index" class="cust_pln_em mx-2 mt-3">
                            <v-flex xs4 class="mt-3">
                                <p class="font-weight-bold cstm-clr-pln-iphd_claims">
                                    {{i.question}}
                                </p>
                            </v-flex>
                                    
                            <v-flex xs2>        
                                    <v-select
                                        solo
                                        :items="SelectItems"
                                        label="Select"
                                        class="rounded-0"
                                        :rules="[rules.required]"
                                        :disabled="disableWhenComplteOFR"
                                        :value="i.answer"
                                        @input="(answer) => updateTab5QueAns(i, answer,index,GetQueData)"

                                        >
                                    </v-select>
                            </v-flex>
                        </v-layout>

                        <!-- <v-layout class="cust_pln_em mx-2 mt-3">
                            <v-flex xs4 class="mt-3">
                                <p class="font-weight-bold cstm-clr-pln-iphd_claims">
                                    Did the overall handling of the claim meet all standards and expectations? 
                                </p>
                            </v-flex>
                                    
                            <v-flex xs2>        
                                    <v-select
                                        solo
                                        :items="SelectItems"
                                        label="Select"
                                        v-model="tab5one"
                                        class="rounded-0" 
                                        :rules="[rules.required]"
                                        :disabled="disableWhenComplteOFR"
                                        >
                                    </v-select>
                            </v-flex>
                        </v-layout> -->

                        <!-- <v-layout class="cust_pln_em mx-2 ">
                            <v-flex xs4 class="mt-3">
                                <p class="font-weight-bold cstm-clr-pln-iphd_claims">
                                    Is diagnosis matching with gender of patient
                                </p>
                            </v-flex>
                                    
                            <v-flex xs2>        
                                    <v-select
                                        solo
                                        :items="SelectItems"
                                        label="Select"
                                        v-model="tab5two"
                                        class="rounded-0" 
                                        :rules="[rules.required]"
                                        >
                                    </v-select>
                            </v-flex>
                        </v-layout> -->

                        <!-- <v-layout class="cust_pln_em mx-2 ">
                            <v-flex xs4 class="mt-3">
                                <p class="font-weight-bold cstm-clr-pln-iphd_claims">
                                    Any issue in overall review
                                </p>
                            </v-flex>
                                    
                            <v-flex xs2 >        
                                    <v-checkbox class="ma-0 pa-0 mt-2" :disabled="disableWhenComplteOFR" :rules="[rules.required]" v-model="tab5two" hide-details color="#23B1A9" >
                        
                                    </v-checkbox>
                            </v-flex>
                        </v-layout> -->
                    </div>
                </v-flex>
            </v-layout>

            <v-layout v-if="QueryStatus == 'overallRating'" class="my-2">
                <v-layout class="cust_pln_em mx-2 mt-3">
                    <v-flex xs1 class="mt-3">
                        <p class="font-weight-bold cstm-clr-pln-iphd_claims">
                            Suggestions *
                        </p>
                    </v-flex>
                    <v-layout>    
                        <v-radio-group
                                v-model="transaction"
                                row
                                class="ma-0 pa-0 py-1"
                                @change="transactionChange(transaction)"
                                :rules="[required123()]"
                                :disabled="disableWhenComplteOFR"
                            >
                                <div style=" width:80px" class="ml-4 pa-1">
                                    <v-radio value="pay" color="#23B1A9" class="ma-0 pa-0 pl-1">
                                        <template v-slot:label>
                                            <div class="font-weight-bold" style="color:#10242B;font-size:12px">
                                                Pay
                                            </div>
                                        </template>
                                    </v-radio>
                                </div>
                                <div style=" width:100px" class="ml-4 pa-1">
                                    <v-radio value="dontPay" color="#23B1A9" class="ma-0 pa-0 pl-1">
                                        <template v-slot:label>
                                            <div class="font-weight-bold" style="color:#10242B;font-size:12px">
                                                Don’t Pay
                                            </div>
                                        </template>
                                    </v-radio>
                                </div>
                                <div style=" width:80px" class="ml-4 pa-1">
                                    <v-radio value="reject" color="#23B1A9" class="ma-0 pa-0 pl-1" >
                                        <template v-slot:label>
                                            <div class="font-weight-bold" style="color:#10242B;font-size:12px">
                                                Reject
                                            </div>
                                        </template>
                                    </v-radio>
                                </div>
                                <div style=" width:80px" class="ml-4 pa-1">
                                    <v-radio value="review" color="#23B1A9"  class="ma-0 pa-0 pl-1">
                                        <template v-slot:label>
                                            <div class="font-weight-bold" style="color:#10242B;font-size:12px">
                                                Review
                                            </div>
                                        </template>
                                    </v-radio>
                                </div>
                                <div style=" width:80px" class="ml-4 pa-1 ">
                                    <v-radio value="query" color="#23B1A9" class="ma-0 pa-0 pl-1">
                                        <template v-slot:label>
                                            <div class="font-weight-bold" style="color:#10242B;font-size:12px">
                                                Query
                                            </div>
                                        </template>
                                    </v-radio>
                                </div>
                        </v-radio-group>
                    </v-layout>
                </v-layout>
            </v-layout>

				<v-layout class="my-2">
					<v-flex xs12>
						<v-layout column class="cust_pln_em mx-2">
							<p class="font-weight-bold cstm-clr-pln-iphd_claims">Comments *</p>
							<v-text-field 
                                solo label="Enter..." 
                                v-model="comments"
                                :rules="[rules.required]"
                                class="rounded-0"
                                :maxlength="max500"
                                :disabled="disableWhenComplteOFR"
                            > </v-text-field>
						</v-layout>
					</v-flex>
                    <!-- :rules="[rules.required,rules.alphaRule]" -->
				</v-layout>

				<v-layout>
					<v-flex xs3>
						<v-layout column class="cust_pln_em mx-2">
							<p class="font-weight-bold cstm-clr-pln-iphd_claims">User ID</p>
							<v-text-field solo label="Enter..."   v-model="userID" class="rounded-0" disabled> </v-text-field>
						</v-layout>
					</v-flex>
					<v-flex xs3>
						<v-layout column class="cust_pln_em mx-2">
							<p class="font-weight-bold cstm-clr-pln-iphd_claims">Date</p>
							<v-menu
								ref="calender"
								:close-on-content-click="true"
								v-model="chequeDateCalender"
								:nudge-right="40"
								transition="scale-transition"
								max-width="290px"
								min-width="290px"
								class="rounded-0"
							>
								<template v-slot:activator="{ on, attrs }">
									<v-text-field
										v-bind="attrs"
										label="Select.."
										:value="chequeDateCalenderMmntStatus"
										solo
										class="rounded-0 iconAlign"
										readonly
										v-on="on"
                                        disabled

									>
										<div slot="prepend-inner" style="background-color: #f7f7f7; padding: 9px !important" >
											<v-icon>event</v-icon>
										</div>
									</v-text-field>
								</template>
								<v-date-picker v-model="dateCalender" no-title disabled></v-date-picker>
							</v-menu>
						</v-layout>
					</v-flex>
                    <v-flex xs3>
                    </v-flex>
                    <v-flex xs3>
                    </v-flex>
                    <v-flex xs3>
                    </v-flex>

				</v-layout>

				<v-layout>
				</v-layout>
			</v-card>
		</v-form>

		<v-form ref="recoveryForm2" v-model="recoveryForm2">
			<v-card tile style="background-color: #f7f7f7" class="py-2">

						<v-layout justify-end>
                            
								<v-btn tile :disabled="disableWhenComplteOFR" color="#23B1A9" class="text-capitalize text-white px-3 mx-2 my-2" @click="saveOFR()" small style="color: #fff">
									<v-icon dark small>check_circle</v-icon>
									<v-divider vertical class="mx-2"></v-divider>Submit
								</v-btn>

                                <v-btn
                                    tile
                                    color="#23B1A9"
                                    class="text-capitalize text-white px-3 mx-2 my-2"
                                    small
                                    style="color: #fff"
                                    :disabled="disableWhenComplteOFR"
                                    @click="saveAsDraft()"
                                >
                                    <v-icon dark small>check_circle</v-icon>
                                    <v-divider vertical class="mx-2"></v-divider>Save as Draft
                                </v-btn>

                <!-- for claim details -->
                
                            <v-btn dark tile color="#23B1A9" class="text-capitalize px-3 mx-2 my-2" small @click="goToclaimDetails(incmngData)"><v-icon dark small>list</v-icon><v-divider vertical class="mx-2"></v-divider>Claim Details</v-btn>

                            <v-btn tile color="#E46A25" style="color: #fff" :disabled="disableWhenComplteOFR" class="text-capitalize px-3 mx-2 my-2 mr-5" small @click="clearData">
                                <v-icon dark small>refresh</v-icon>
                                <v-divider vertical  class="mx-2"></v-divider>Reset
                            </v-btn>

                                    <!-- <v-btn dark tile color="#E46A25" class="text-capitalize px-3 mx-2 my-2" small @click="goBack">
                                        <v-icon dark small>cancel</v-icon>
                                        <v-divider vertical class="mx-2"></v-divider>Close
                                    </v-btn> -->
                        </v-layout>
			</v-card>
		</v-form>

	</div>
</template>
<script>
import moment from "moment";
export default {

    created() {
		this.$store.commit("SAVE_PAGE_HEADER",  "OFR Audit Sheet");
        console.log("Success Token", this.$store.getters.successToken);
        this.token = this.$store.getters.successToken;
		this.incmngData = this.$route.params.allData;
		this.populateData(this.incmngData);
    },


	data() {
		return {

            disableWhenComplteOFR:false,
                claimsregistrationid:'',
                ofrunVal: false,
                ofrcoVal: true,

                GetQueData:[],

                tabShow2:false,
                GetQueData2:[],

                tabShow3:false,
                GetQueData3:[],

                tabShow4:false,
                GetQueData4:[],

                tabShow5:false,
                GetQueData5:[],
                
                getTab22:[],
                getTab2Final:[],

                getTab33:[],
                getTab3Final:[],

                getTab44:[],
                getTab4Final:[],

                getTab55:[],
                getTab5Final:[],

            QueryStatus: 'OFRtriggers',
            SelectItems:['Yes','No','NA'],

            // tab1one:'',

            // tab2one:'',
            // tab2two:'',
            
            // tab3one:'',
            // tab3two:'',
            
            // tab4one:'',
            // tab4two:'',
            // tab4three:'',
            // tab4four:'',

            // tab5one:'',
            // tab5two:false,
            // tab5three:'',

            transaction:[],
            draftVal:false,
            formData:[],
            
			
			valid: false,
            // validT2:false,
			recoveryForm2: false,
			claimNumber: "",
			featureNumber: "",
			memberName: "",
			claimPaidAmount: "",
			providerName: "",
            adjudicatorName: "",
            
            comments:'',
            userID:'',
            
			chequeDateCalender: false,
			dateCalender: "",
			
			disableFields: false,
            allGetData:[],

            viewScoreItems: [],
            viewScoreHeader: [
                
                {
                text: "Trigger Number",
                value: "triggerNumber",
                sortable: false,
                divider: true,
                class: "textClass bgColor",
                align: "center",
                },
                {
                text: "Trigger Description",
                value: "triggerDescription",
                sortable: false,
                divider: true,
                class: "textClass bgColor",
                align: "center",
                },
                {
                text: "Trigger Flag",
                value: "triggerFlag",
                sortable: false,
                divider: true,
                class: "textClass bgColor",
                align: "center",
                }
            ],
			//   for filed length
            max30: 30,
            max500: 500,

            rules: {
                required: v => !!v || "This field is required.",
                // alphaRule: v =>{ 
                //                         // const pattern = /^[0-9a-zA-Z .,]+$/
                //                         const pattern = /^[a-zA-Z'!@#$&()\\`.+,/\" 0-9]+$/
                //                         return v.length <= 0 || pattern.test(v) || 'Enter valid values.'
                //     },
            },

            required123() {
                    return (v) => (v && v.length > 0) || "This Field is Required.";
                },
		};
	},

	computed: {
		chequeDateCalenderMmntStatus() {
			return this.dateCalender ? moment(this.dateCalender).format("DD/MM/YYYY") : "";
		},
	},

	watch: {
		
	},

	methods: {
        transactionChange(value){
            console.log("change",value,'transaction',this.transaction);
        },

        // changeQueryStatus(val){
        //   console.log(val,'ww');
        //   this.QueryStatus = val;
        // //   this.headers[1].text = val +' '+'Query ID';
        // },

//ofr que Config------------------===--->>> 

        changeQueryStatus(val,queType){
          this.QueryStatus = val;
          this.tabstab = queType
            console.log(val,'<<< === val dataaa ===>',queType);
            let apiURL = `ofr-cfr-master/view-ofr-questions?filedType=`+queType

            this.GET(21, apiURL, (response, error) => {
                if (!error) {
                let apiData = response.data.data;
                // this.GetQueData = apiData.questions
                console.log('incm apiData dataaa ---===>',apiData);
                        if (this.disableWhenComplteOFR == false) {
                        // alert('Not complted')
                            console.log('????????????????underOFR que tab== ----===------------>');
                            console.log('GetQueData2 dataaa ---+++++-===>',this.GetQueData2);

                            if (this.tabShow2== true && val=='policyAndMemberDetails') {
                                this.GetQueData = this.GetQueData2
                                // this.GetQueData2
                                console.log('GetQueData2 dataaa ----===>',this.GetQueData);
                                

                            }else if (this.tabShow3== true && val=='providerDetails') {
                                this.GetQueData = this.GetQueData3
                                // this.GetQueData3
                                console.log('GetQueData3 dataaa ===>',this.GetQueData);
                                
                            }else if (this.tabShow4== true && val=='diagnosisAndIlness') {
                                this.GetQueData = this.GetQueData4
                                console.log('GetQueData4 dataaa ===>',this.GetQueData);
                                    
                            }else if (this.tabShow5== true && val=='overallRating') {
                                this.GetQueData = this.GetQueData5
                                console.log('GetQueData5 dataaa ===>',this.GetQueData);
                                
                            } else {
                                this.GetQueData = apiData.questions
                                if(val=='policyAndMemberDetails'){
                                    this.fetchquestiondata('policyAndMemberDetails')
                                }else if(val=='providerDetails'){
                                    this.fetchquestiondata('providerDetails')
                                }else if(val=='diagnosisAndIlness'){
                                    this.fetchquestiondata('diagnosisAndIlness')
                                }else if(val=='overallRating'){
                                    this.fetchquestiondata('overallRating')
                                }
                                console.log('GetQueData dataaa ==>',this.GetQueData);
                            }
                            
                        } else {
                            // alert('completed')
                            if (this.tabShow2== true || val=='policyAndMemberDetails') {
                                this.GetQueData = this.GetQueData2
                                // this.GetQueData2
                                console.log('GetQueData2 dataaa \\\\----===>',this.GetQueData);
                            }else if (this.tabShow3== true || val=='providerDetails') {
                                this.GetQueData = this.GetQueData3
                                console.log('GetQueData3 dataaa ===>',this.GetQueData);
                                
                            }else if (this.tabShow4== true || val=='diagnosisAndIlness') {
                                this.GetQueData = this.GetQueData4
                                console.log('GetQueData4 dataaa ===>',this.GetQueData);
                                
                            }else if (this.tabShow5== true || val=='overallRating') {
                                this.GetQueData = this.GetQueData5
                                console.log('GetQueData5 dataaa ===>',this.GetQueData);
                                
                            } else {
                                this.GetQueData = apiData.questions
                                console.log('GetQueData dataaa ==>',this.GetQueData);   
                            }   
                        }
                }else{
                    this.showToast(error.response.data.message.msg,this.TOST().ERROR)
                
                }
            })          
        },

        updateTab2QueAns(allTab22,answer,index,comletearray) {
            let temobj = []
            temobj = comletearray
            // this.getTab2Final = []
            console.log("allTab22",allTab22)
            console.log("INDEX::",index)
            console.log("comletearray",comletearray)
            console.log("answer::",answer)

            
            if(allTab22.hasOwnProperty('answer')){
                console.log("inside::")
                // temobj.splice(index, 1)
                temobj[index].answer = answer
                this.getTab2Final = temobj
                temobj = []
                console.log("temobj",this.getTab2Final)
            }else{
                allTab22.answer = answer
                console.log(allTab22,'tab22 dataaa ===>');     
                this.getTab22.push(allTab22)
                console.log('getTab22 dataaa ===>',this.getTab22);

                let newArray = [];
                let uniqueObject = {};
                let objTitle=[]

                for (let i in this.getTab22) {
      
                    objTitle = this.getTab22[i]['_id'];
        
                    uniqueObject[objTitle] = this.getTab22[i];
                }
              
                // Loop to push unique object into array
                for (let a in uniqueObject) {
                    newArray.push(uniqueObject[a]);
                }
                
                // Display the unique objects
                // temobj[index] = newArray
                console.log('newww-------------------->>',newArray);
                this.getTab2Final= newArray
            }
            

            
        },

        updateTab3QueAns(allTab33, answer,index,comletearray) {

            let temobj = comletearray
            console.log("allTab33",allTab33)
            console.log("INDEX::",index)
            console.log("comletearray",comletearray)
            console.log("answer::",answer)


            if(allTab33.hasOwnProperty('answer')){
                temobj[index].answer = answer
                this.getTab3Final = temobj
                console.log("temobj",this.getTab3Final)
            }else{
                allTab33.answer = answer
                this.getTab33.push(allTab33)
                let newArray = [];
                let uniqueObject = {};
                let objTitle=[]

                for (let i in this.getTab33) {
                    objTitle = this.getTab33[i]['_id'];
                    uniqueObject[objTitle] = this.getTab33[i];
                }
                // Loop to push unique object into array
                for (let a in uniqueObject) {
                    newArray.push(uniqueObject[a]);
                }
                // Display the unique objects
                console.log('newww-------------------->>',newArray);
                this.getTab3Final= newArray
            }
                
        },

        updateTab4QueAns(allTab44, answer,index,comletearray) {
            let temobj = comletearray
            console.log("allTab44",allTab44)
            console.log("INDEX::",index)
            console.log("comletearray",comletearray)
            console.log("answer::",answer)


            if(allTab44.hasOwnProperty('answer')){
                temobj[index].answer = answer
                this.getTab4Final = temobj
                console.log("temobj",this.getTab4Final)
            }else{
                allTab44.answer = answer
                
                this.getTab44.push(allTab44)
                let newArray = [];
                let uniqueObject = {};
                let objTitle=[]

                for (let i in this.getTab44) {
                    objTitle = this.getTab44[i]['_id'];
                    uniqueObject[objTitle] = this.getTab44[i];
                }
                // Loop to push unique object into array
                for (let a in uniqueObject) {
                    newArray.push(uniqueObject[a]);
                }
                // Display the unique objects
                console.log('newww-------------------->>',newArray);
                this.getTab4Final= newArray
            }
                
        },

        updateTab5QueAns(allTab55, answer,index,comletearray) {
            let temobj = comletearray
            console.log("allTab55",allTab55)
            console.log("INDEX::",index)
            console.log("comletearray",comletearray)
            console.log("answer::",answer)


            if(allTab55.hasOwnProperty('answer')){
                temobj[index].answer = answer
                this.getTab5Final = temobj
                console.log("temobj",this.getTab5Final)
            }else{
                allTab55.answer = answer
                
                this.getTab55.push(allTab55)
                let newArray = [];
                let uniqueObject = {};
                let objTitle=[]

                for (let i in this.getTab55) {
                    objTitle = this.getTab55[i]['_id'];
                    uniqueObject[objTitle] = this.getTab55[i];
                }
                // Loop to push unique object into array
                for (let a in uniqueObject) {
                    newArray.push(uniqueObject[a]);
                }
                // Display the unique objects
                console.log('newww-------------------->>',newArray);
                this.getTab5Final= newArray
            }
            
        },

        fetchquestiondata(tabsname){
            let apiURL = `ofr/ofr-by-id?claimRegistrationId=`+this.claimsregistrationid
            this.GET(20, apiURL, (response, error) => {
                if (!error) {
                    let apiData = response.data.data;
                    console.log("get data----------->>>>",apiData)

                    if(tabsname == 'policyAndMemberDetails'){
                        if(apiData.policyAndMemberDetails.length > 0){
                            this.GetQueData = apiData.policyAndMemberDetails
                        }
                    }

                    if(tabsname == 'providerDetails'){
                        if(apiData.providerDetails.length > 0){
                            this.GetQueData = apiData.providerDetails
                        }
                    }

                    if(tabsname == 'diagnosisAndIlness'){
                        if(apiData.diagnosisAndIlness.length > 0){
                            this.GetQueData = apiData.diagnosisAndIlness
                        }
                    }

                    if(tabsname == 'overallRating'){
                        if(apiData.overallRating.length > 0){
                            this.GetQueData = apiData.overallRating
                        }
                    }
                    // this.showToast(response.data.msg, this.TOST().SUCCESS);
                }else{
                    this.showToast(error.response.data.msg,this.TOST().ERROR)
                
                }
            })
        },

        clearquestion(){
            let apiURL = `ofr-cfr-master/view-ofr-questions?filedType=`+this.tabstab
            this.GET(21, apiURL, (response, error) => {
                if (!error) {
                    let apiData = response.data.data;
                    if (this.disableWhenComplteOFR == false) {
                        this.$refs.recoveryForm.resetValidation()
                        // alert('Not complted-----')
                            console.log('????????????????underoFR que tab== ----===------------>');
                            console.log('GetQueData2 dataaa ---+++++-===>',this.GetQueData2);

                            if (this.tabShow2== true && val=='policyAndMemberDetails') {
                                this.GetQueData = this.GetQueData2 
                                // this.GetQueData2
                                console.log('GetQueData2 dataaa ---\\\\-===>',this.GetQueData);                            
                            }else if (this.tabShow3== true && val=='providerDetails') {
                                this.GetQueData = this.GetQueData3
                                // this.GetQueData3
                                console.log('GetQueData3 dataaa ===>',this.GetQueData);
                                
                            }else if (this.tabShow4== true && val=='diagnosisAndIlness') {
                                this.GetQueData = this.GetQueData4
                                console.log('GetQueData4 dataaa ===>',this.GetQueData);
                                    
                            }else if (this.tabShow5== true && val=='overallRating') {
                                this.GetQueData = this.GetQueData5
                                console.log('GetQueData5 dataaa ===>',this.GetQueData);
                                
                            } else {
                                this.GetQueData = apiData.questions
                                console.log(' on clear GetQueData dataaa ---+++++-===>',this.GetQueData);                                    
                            }
                            
                        } else {
                            // alert('completed')
                            if (this.tabShow2== true || val=='policyAndMemberDetails') {
                            
                            this.GetQueData = this.GetQueData2
                            // this.GetQueData2
                                console.log('GetQueData2 dataaa ---+++-===>',this.GetQueData);

                            }else if (this.tabShow3== true || val=='providerDetails') {
                                this.GetQueData = this.GetQueData3
                                // this.GetQueData3
                                console.log('GetQueData3 dataaa ===>',this.GetQueData);
                                
                            }else if (this.tabShow4== true || val=='diagnosisAndIlness') {
                                this.GetQueData = this.GetQueData4
                                console.log('GetQueData4 dataaa ===>',this.GetQueData);
                                    
                            }else if (this.tabShow5== true || val=='overallRating') {
                                this.GetQueData = this.GetQueData5
                                console.log('GetQueData5 dataaa ===>',this.GetQueData);
                                
                            } else {
                                this.GetQueData = apiData.questions
                                console.log('GetQueData dataaa ==>',this.GetQueData);
                            }
                        }
                }else{
                    this.showToast(error.response.data.message.msg,this.TOST().ERROR)
                }
            }) 
        },
        
		reasonRule(v){
			console.log(v,'dd');
			// alert(1)
					console.log('hhhh')
					if (v.length < 1) {
						console.log('hhhh')
						return (v) => !!v || "This Field is required!";
					}
			},

		goBack() {
			this.$router.push("/OFRdashboard");
		},

		populateData(val) {
            console.log('<<< === val dataaa ===>',val);
            this.claimsregistrationid = val.claimRegistrationId
            
            let apiURL = `ofr/ofr-by-id?claimRegistrationId=`+val.claimRegistrationId
    
            this.GET(20, apiURL, (response, error) => {
                if (!error) {
                let apiData = response.data.data;
                this.allGetData = apiData
                console.log(this.allGetData,'api dataaa ----===>',apiData)
                
                    this.claimNumber =apiData.claimId ? apiData.claimId : "--"
                    this.featureNumber =apiData.featureNumber ? apiData.featureNumber : "--"
                    this.memberName =apiData.memberName ? apiData.memberName : "--",
                    this.claimPaidAmount =apiData.claimedAmount ? apiData.claimedAmount : "--",
                    this.providerName =apiData.hospitalName ? apiData.hospitalName : "--",
                    this.adjudicatorName =apiData.adjudicatorName ? apiData.adjudicatorName : "--",
                    // this.expensePaid =apiData.,
                    // this.netScore =apiData.,
                    this.comments =apiData.comments ? apiData.comments : "",
                    this.userID =apiData.userId ? apiData.userId : "--",
                    // this.dateCalender =apiData.TAT ? moment(apiData.TAT).format("DD/MM/YYYY") : "--";
                    // this.dateCalender =apiData.Date
                    this.dateCalender =new Date();
                    // this.dateCalender =apiData.Date ? moment(apiData.Date).format("DD/MM/YYYY") : "--";
                    
                    if (apiData.policyAndMemberDetails.length !=0) {
                        this.tabShow2 == true
                        this.GetQueData2 = apiData.policyAndMemberDetails
                        console.log('GetQueData2 ofr api dataaa ----===>',this.GetQueData2)

                        // this.tab2one = apiData.policyAndMemberDetails[0].answer
                        // this.tab2two = apiData.policyAndMemberDetails[1].answer
                    }

                    if (apiData.providerDetails.length !=0) {
                        this.tabShow3 == true
                        this.GetQueData3 = apiData.providerDetails
                        console.log('if ofr api dataaa providerDetails----===>',this.tabShow3)

                        // this.tab3one = apiData.providerDetails[0].answer
                        // this.tab3two = apiData.providerDetails[1].answer

                    }

                    if (apiData.diagnosisAndIlness.length !=0) {
                        this.tabShow4 == true
                        this.GetQueData4 = apiData.diagnosisAndIlness

                        // this.tab4one = apiData.diagnosisAndIlness[0].answer
                        // this.tab4two = apiData.diagnosisAndIlness[1].answer
                        // this.tab4three = apiData.diagnosisAndIlness[2].answer
                        // this.tab4four = apiData.diagnosisAndIlness[3].answer
                    }

                    if (apiData.overallRating.length !=0) {
                        this.tabShow5 == true
                        this.GetQueData5 = apiData.overallRating

                        // this.tab5one = apiData.overallRating[0].answer
                        // this.tab5two = apiData.overallRating[1].answer
                    }

                    this.transaction = apiData.suggestions[0] ? apiData.suggestions[0] : ""
                   
                    if (apiData.ofrCompletedCase == true) {
                        this.disableWhenComplteOFR = true
                    }else{
                        this.disableWhenComplteOFR = false

                    }

                    console.log("CHECK :::",this.disableWhenComplteOFR)
                    if(this.disableWhenComplteOFR == true){
                        this.dateCalender = apiData.Date
                    }else{
                        this.dateCalender =new Date();
                    }

                console.log("this.dateCalender ------->",this.dateCalender)

                    this.triggerScoreClick(this.allGetData)
                }else{
                    this.showToast(error.response.data.message.msg,this.TOST().ERROR)
                
                }
            })
        },

        triggerScoreClick(val){
             console.log("selected triggerScoreClick ----->>>",val);
            // if (this.claimNumber!=0) {
                let apiURL = `ofr/view-ofr-trigger-score?claimId=`+val.claimId;
                this.GET(20, apiURL, (res, error) => {
                    if (!error) {
                        this.showToast(res.data.msg, this.TOST().SUCCESS);
                        let resArray = res.data.data.trigger
                        console.log("resArray indata------->",resArray)
                            this.viewScoreItems = resArray.map(itemTriArr => {
                                return {
                                        triggerNumber : itemTriArr.triggerNumber,
                                        triggerFlag : itemTriArr.triggerFlag,
                                        triggerDescription : itemTriArr.triggerDescription,
                                        // triggerScore : itemTriArr.ofrTriggerScore ? itemTriArr.ofrTriggerScore : '--',
                                }
                            })
                        console.log("this.viewScoreItems ------->",this.viewScoreItems)

                        this.viewScoreData=true
                    } else {
                        this.showToast(error.response.data.message.msg, this.TOST().ERROR);
                    }
                });

            // }else{
            //     this.showToast('Trigger Score not valid.',this.TOST().WARNING)
            //     return;
            // }                
        },
        
        async saveAsDraft() {
            this.draftVal= true
            this.saveOFR()
        
        },

		async saveOFR() {
			if (this.$refs.recoveryForm.validate()) {
                if (this.draftVal== false) {
                    if (this.transaction=='' || this.tab5two=="") {
                        this.showToast("Please select required fields.", this.TOST().WARNING);
                        return
                    }
                }
                let apiURL = `ofr/update-ofr-audit-sheet/`+this.allGetData._id;
                this.formData =[]
                if (this.draftVal== true) {

                    this.formData = {
                        _id: this.allGetData._id,
                        auditType:this.QueryStatus,
                        caseUnderOFR: this.allGetData.caseUnderOFR,
                        ofrCompletedCase: this.allGetData.ofrCompletedCase,
                        claimRegistrationId: this.allGetData.claimRegistrationId,
                        claimId: this.claimNumber,
                        featureNumber: this.featureNumber,
                        memberName: this.memberName,
                        corporateGroupName: "",
                        coverage: this.allGetData.coverage,

                        insuredName: this.allGetData.insuredName,
                        policyNumber: this.allGetData.policyNumber,
                        hospitalName: this.providerName,
                        patientName: this.allGetData.patientName,
                        productName: this.allGetData.productName,
                        totalAmountApproved: this.claimPaidAmount,
                        claimedAmount: this.allGetData.claimedAmount,
                        adjudicatorName: this.adjudicatorName,
                        claimantName: this.allGetData.claimantName,
                        sumInsured: this.allGetData.sumInsured,
                        reserveAmount: this.allGetData.reserveAmount,
                        litigationType: this.allGetData.litigationType,
                        TAT: this.allGetData.TAT,

                            policyAndMemberDetails: this.tabShow2== false ? this.getTab2Final : this.GetQueData2,
                            providerDetails: this.tabShow3== false ? this.getTab3Final : this.GetQueData3 ,
                            diagnosisAndIlness: this.tabShow4== false ? this.getTab4Final : this.GetQueData4 ,
                            overallRating: this.tabShow5== false ? this.getTab5Final : this.GetQueData5 ,
                            
                        // suggestions: this.transaction.split(','),
                        suggestions: this.transaction,

                        comments:this.comments,
                        userId:this.userID,
                        Date:this.dateCalender,
                        ofrId: this.allGetData.ofrId,
                    }
                    // console.log("if formdata -=------>", this.formData)

                }else{

                    this.formData = {
                        _id: this.allGetData._id,
                        auditType:this.QueryStatus,
                        
                        claimRegistrationId: this.allGetData.claimRegistrationId,
                        claimId: this.claimNumber,
                        featureNumber: this.featureNumber,
                        memberName: this.memberName,
                        corporateGroupName: "",
                        coverage: this.allGetData.coverage,

                        insuredName: this.allGetData.insuredName,
                        policyNumber: this.allGetData.policyNumber,
                        hospitalName: this.providerName,
                        patientName: this.allGetData.patientName,
                        productName: this.allGetData.productName,
                        totalAmountApproved: this.claimPaidAmount,
                        claimedAmount: this.allGetData.claimedAmount,
                        adjudicatorName: this.adjudicatorName,
                        claimantName: this.allGetData.claimantName,
                        sumInsured: this.allGetData.sumInsured,
                        reserveAmount: this.allGetData.reserveAmount,
                        litigationType: this.allGetData.litigationType,
                        TAT: this.allGetData.TAT,

                            policyAndMemberDetails: this.tabShow2== false ? this.getTab2Final : this.GetQueData2,
                            providerDetails: this.tabShow3== false ? this.getTab3Final : this.GetQueData3 ,
                            diagnosisAndIlness: this.tabShow4== false ? this.getTab4Final : this.GetQueData4 ,
                            overallRating: this.tabShow5== false ? this.getTab5Final : this.GetQueData5 ,
                            
                        // suggestions: this.transaction.split(','),
                        suggestions: this.transaction,

                        comments:this.comments,
                        userId:this.userID,
                        Date:this.dateCalender,
                        ofrId: this.allGetData.ofrId,
                       
                        // caseUnderOFR: this.allGetData.caseUnderOFR,
                        // ofrCompletedCase: this.allGetData.ofrCompletedCase,
                        // caseUnderOFR:false,
                        // ofrCompletedCase:true,

                        caseUnderOFR: this.ofrunVal,
                        ofrCompletedCase: this.ofrcoVal
                            
                    }
                    // console.log("else formdata ------->",this.formData)
                }

                await this.PUT(20, apiURL, this.formData, (res, error) => {
                    if (!error) {
                        this.showToast(res.data.msg, this.TOST().SUCCESS);
                        
                        if (this.draftVal== true) {
                            this.populateData(this.incmngData)
                            this.draftVal=false
                            // console.log("if redirection       -=------>", this.draftVal)
                        }else{
                            this.draftVal=false
                            // console.log("else redirection     -=------>",this.draftVal)
                            this.$router.push({name: 'OFRdashboard' })
                        }

                    } else {
                        this.showToast(error.response.data.msg, this.TOST().ERROR);
                    }
                });
            }else{
                this.$refs.recoveryForm.validate();
                // this.$refs.tab2Form.validate();
				this.showToast("Please fill required fields", this.TOST().WARNING);
				return;
			}
		},

//AA for claim Details
            goToclaimDetails(val){
                console.log("val---------===>",val);
                    try{
                        let sellob = {
                            lob:'' ,
                            usr_jti:this.$store.state.Auth.user.signInUserSession.idToken.payload.jti,
                            metaVal : 'clm_Version' +'='+ val.claimRegistrationId  

                        }

                        sellob.lob = 'Health'
                        // let apiis = this.cognito_login()+'session/redirect?lob='+sellob.lob+'&jti='+sellob.usr_jti
                        let apiis = this.cognito_login()+'session/redirect?lob='+sellob.lob+'&jti='+sellob.usr_jti+'&metadata='+sellob.metaVal
                        
                        console.log('apiis------>>>', apiis)
                        window.location.href = apiis

                        return
                    }catch(errct){
                    console.log("error from catch re-direction",errct)
                    }
            },

    // claimDetails(){

    //         //   this.$store.commit('SET_DOCS_UTIL_IDS',{claimId:this.claimNumber, inwardId:this.claimData.inwardId})
    //         this.$store.commit('SET_DOCS_UTIL_IDS',{claimId:this.claimNumber})		
    //         this.$store.commit("SET_NIDB_PENDING", true);
    //                 this.$store.commit("SET_COMING_CCO", false);
    //         this.$router.push({
    //             name: 'ClaimsMainScreen',
    //             params: {
    //             //    docIdFromSummTransac : this.clickedDoc_ID,
    //             docIdFromSummTransac : this.allGetData._id,
    //             dataNeeded : this.allGetData,
    //             claimId: this.allGetData.claimId,
    //             claimType: this.allGetData.claimType,
    //             comingFrom : "claimSummary"
    //             }
    //         })
    // },


		clearData() {
			this.comments = "";
            // this.userID = "";
            // this.dateCalender = "";

            this.transaction='';
            this.clearquestion()
		},

	

	},
};
</script>

<style scoped>

.text-dark-insrns1 {
    color: #10242b;
    font-size: 11px;
}
.text-white-insrns1 {
    color: #fff;
    font-size: 11px;
}

.iconAlign >>> .v-input__slot{
	padding-left: 0 !important;
}
</style>
