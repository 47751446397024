<template>
<v-app>
  <v-container class="containerStyle"
      style="padding:1.5rem; margin:0; max-width:100%">
    <v-layout>
        <h3>Search Frequent Payee Master</h3>
        <v-spacer></v-spacer>
        <v-btn
        class="btnToggle"
      outlined
      elevation="0"
      @click="createMaster"
        >
            <v-icon>mdi-plus-box-outline</v-icon>
            <v-divider  ></v-divider>
            Create Master
        </v-btn>
        
    </v-layout>
    <v-layout class="mt-5" wrap>
        <v-flex class="form-group">
          <label class="form-label">
            LOB <span class="mandatorycolor">*</span>
          </label>
          <v-select
              v-model="lob"
              :items="lobOptions"
              solo
              dense
              class="form-control"
              placeholder="Select"
          ></v-select>
        </v-flex>
        <v-flex class="form-group">
          <label class="form-label">
            Payee Name
          </label>
          <v-text-field
            solo
            dense
            v-model="payeeName"
            maxlength="50"
            class="form-control"
            placeholder="Enter..."
            >
            </v-text-field>
          <!-- <v-autocomplete
          :search-input.sync="payeeNameSmart"
          v-model="payeeName"
          solo
          :items="payeeNameSearch"
          item-text="payeeName"
          dense
          maxlength="50"
          :rules="[(v)=>matchRegex(v,'alphanum')]"
          class="form-control"
          placeholder="Enter..."
          @keyup="smartSearch(payeeNameSmart,'payeeName')"
          ></v-autocomplete> -->
        </v-flex>

        <v-flex class="form-group">
          <label class="form-label">
            Payee Type
          </label>
          <v-select
          v-model="payeeType"
          solo
          dense
          :items="Payee_items"
          class="form-control"
          placeholder="Select"
          ></v-select>
        </v-flex>
        <v-flex class="form-group">
          <label class="form-label">
          PAN Number
          </label>
          <!-- <v-text-field
            solo
            dense
            v-model="panNumber"
            :rules="[(v) => validatePanNumber(v)]"
            class="form-control"
            placeholder="Enter..."
            >
            </v-text-field> -->
          <v-autocomplete
          @keyup="smartSearch(panNumberSmart,'panNumber')"
          :search-input.sync="panNumberSmart"
          v-model="panNumber"
          :items="panNumberSearch"
          item-text="panNumber"
          solo
          :value="computedPAN"
          dense
          :rules="[(v) => validatePanNumber(v)]"
          class="form-control"
          placeholder="Enter..."
          ></v-autocomplete>
        </v-flex>
        <v-flex class="form-group">
          <label class="form-label">
            Aadhaar Number
          </label>
          <!-- <v-text-field
            solo
            dense
            v-model="aadharId"
            :maxlength="12"
            :rules="[ (v) => matchRegex(v, 'nums'), (v) => validateLength(v, { exact: 4 }, '', 'Field requires 4 digits.')]"
            class="form-control"
            placeholder="Enter..."
            >
            </v-text-field> -->
          <v-autocomplete
          @keyup="smartSearch(aadharNumber,'AadharNumber')"
          :search-input.sync="aadharNumber"
          v-model="aadharId"
          item-text="aadharId"
          solo
          :maxlength="12"
          :items="aadharNumberSearch"
          dense
          :rules="[ (v) => matchRegex(v, 'nums'), (v) => validateLength(v, { exact: 4 }, '', 'Field requires 4 digits.')]"
          class="form-control"
          placeholder="Enter..."
          ></v-autocomplete>
        </v-flex>
        <v-flex class="form-group">
          <label class="form-label">Master entry status</label>
          <v-select class="form-control" solo dense placeholder="Select" :items="master_status" v-model="approveStatus"></v-select>
        </v-flex>
        <v-flex class="form-group">
          <label class="form-label">
              Status 
          </label>
            <v-select
                v-model="status"
                label="Select"
                :items="[{text:'Active',value:true},{text:'InActive',value:false}]"
                solo
                dense
                class="form-control"
            ></v-select>
        </v-flex>
        <v-spacer></v-spacer>
        <v-btn class="mt-7 mr-7 btn" outlined color="red" @click="onReset(true)"><v-icon> mdi-backspace </v-icon>
          <v-divider vertical class="mx-2"></v-divider>
          Reset</v-btn>
        <v-btn class="mt-7 btn" color="teal white--text" dense solo @click="searchFrequency(1,true)"><v-icon>mdi-arrow-right-circle</v-icon>
          <v-divider vertical class="mx-2"></v-divider>
          Search</v-btn>
    </v-layout>
    <v-layout class="mb-4">
      <h4 class="mr-3"><strong>Frequent Payee Master List <span class="orange--text">({{count}})</span></strong></h4>
      <v-chip small color="#1EBBD9" text-color="white" label close
                     v-if="showSearchChip==true"
                    @click:close="chipsearchFrequency(1)">Search</v-chip>
      <v-spacer></v-spacer> 
      <!-- <v-btn depressed
                color="#E46A25"
                class="white--text mr-3"><v-icon>
                    mdi-file-excel
                </v-icon>
                <v-divider class="mx-4"
                vertical
                color="white"></v-divider>Export to Excel</v-btn> -->
              </v-layout>
    <v-data-table
    :items="frequency_Table_Data"
    :headers="frequence_Header"
    :items-per-page="10"
    hide-default-footer
    class="vDataTableStyling">
    <template v-slot:[`item.actions`]="{ item }">
                      <v-menu offset-y open-on-hover>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            elevation=""
                            icon
                            v-bind="attrs"
                            v-on="on"
                            style="text-align:center"
                          >
                            <v-icon>mdi mdi-dots-vertical</v-icon>
                          </v-btn>
                        </template>
                        
                        <v-list class="item-actions" >
                         <template >
                          <v-list-item
                           v-for="(actions, index) in actionOptions"
                            :key="index"
                            @click="editmaster(actions.link, index, item, item._id, actions.name)"
                            :disabled="actions.name === 'MODIFY' && ['Pending for approval'].includes(item.approveStatus)"
                          >
                            <v-list-item-title :style="(actions.name === 'MODIFY' && ['Pending for approval'].includes(item.approveStatus)) ? 'gray' : actions.color"
                              ><v-icon small>{{ actions.icon }}</v-icon>
                               {{ actions.name }}</v-list-item-title
                            >
                          </v-list-item>
                          </template>
                        </v-list>
                        
                      </v-menu>
                    </template>
                    <template v-slot:[`item.active`]="{ item }">
                      {{item.active?'Active':'Inactive'}}
                    </template>
  </v-data-table>
  <v-layout justify-end align-center class="pb-2 px-0 mr-4">
              <div>
                <span style="color:orange">Showing {{ pageStartIndex_payee }}-{{ pageEndIndex_payee }}</span>
                out of {{ totalListCount_payee }} records
              </div>
              <div>
                <v-pagination  v-model="page_payee" 
                :length="pageCount_payee" :total-visible="5" 
                @input="searchFrequency(page_payee,false)" color="#152F38"
                ></v-pagination>
              </div>
            </v-layout>
  <v-row>
    <v-col>
      <v-btn outlined color="red" class="btn" @click="cancel">
        <v-icon> mdi-backspace </v-icon>
        <v-divider vertical class="mx-2"></v-divider> 
        Cancel
      </v-btn>
    </v-col>
  </v-row>

<!-- For Audit Trail -->
<v-dialog v-model="audit_dialog" width="800">
      <v-card>
        <v-card-title class="white--text" style="background-color: #23b1a9">
          Audit Trail
          <v-spacer></v-spacer>
          <v-icon @click="audit_dialog = !audit_dialog" class="white--text"
            >mdi-window-close</v-icon
          >
        </v-card-title>
        <v-layout class="pt-4 form-group-container pr-4">
          <v-data-table
            :headers="audit_Header"
            hide-default-footer
            :items="audit_Data"
            class="vDataTableStyling w-100"
          >
            <template v-slot:[`item.srno`]="{ index }">
              {{ 1 + index }}
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-btn
                v-if="item.action === 'Modified'"
                color="#FFFFFF"
                class="text-capitalize tablebtn"
                @click="seeModify(item)"
              >
                {{ item.action }}
                <v-icon class="tableicons">mdi-arrow-right-circle</v-icon>
              </v-btn>
              {{ item.action !== "Modified" ? item.action : null }}
            </template>
            <template v-slot:[`item.createdAt`]="{ item }">
              {{ formatDateTime(item.createdAt) }}
            </template>
          </v-data-table>
        </v-layout>
        <v-layout justify-end align-center class="pb-2 px-0 mr-4">
          <div>
            <span style="color: orange"
              >Showing {{ AuditPageStartIndex }}-{{ AuditPageEndIndex }}</span
            >
            out of {{ AuditTotalListCount }} records
          </div>
          <div>
            <v-pagination
              class="small-pagination"
              v-model="AuditPage"
              :length="AuditPageCount"
              :total-visible="5"
              @input="getAuditData(AuditPage, '', true)"
              color="#152F38"
            ></v-pagination>
          </div>
        </v-layout>
      </v-card>
    </v-dialog>

  
    <!-- Modify dialogue -->
    <v-dialog v-model="seeModifyDialog" width="600">
      <v-card>
        <v-card-title class="white--text"
                    style="background-color: #23b1a9">
          <v-layout justify-space-between>
            <div>
              Modified Field Details
            </div>
          </v-layout>
          <v-btn color="transparent" small :ripple="false" depressed @click="seeModifyDialog = false">
            <v-icon color="white">mdi-close </v-icon>
          </v-btn>
        </v-card-title>
        <div class="scrollable-table">
        <v-data-table disable-pagination :headers="modifyHeaders" 
        :items="modifyItems" hide-default-footer class="pa-4 vDataTableStyling">
        <template v-slot:[`item.srNo`]="{ index }">
                {{ 1 + index }}
              </template>
      </v-data-table>
        </div>
      </v-card>
    </v-dialog>
    <!-- Modify dialogue -->
    <!-- Audit Trail -->
    <audit-trail
      :dialogState="OpenAuditDialog"
      :auditTrailMaster="'frequentPayee'"
      :auditTrailItem="{ uid: auditTrailId }"
      @close-dialog="OpenAuditDialog = false"
    />
  </v-container>
</v-app>

</template>
<script>
import moment from 'moment'
import auditTrail from '../../../component/MasterAuditTrail.vue'
export default {
  components:{
    auditTrail
  },
  data(){
    return{
      validationmessage: "This field is required",
      lobOptions: [],
      frequency_Table_Data:[],
      Payee_items:['Insured','Claimant','Others'],
      actionOptions: [
        {
          name: "VIEW",
          icon: "mdi-eye-outline",
          // link: "/childrenEducationCoverAddAssessment",
          color: "color:#23b1a9",
          iconColor: "color:#10242B",
        },
        {
          name: "MODIFY",
          icon: "mdi-pencil",
          // link: "/modifypage",
          iconColor: "color:#10242B",
          color: "color:#23b1a9",
        },
        {
          name: "AUDIT TRAIL",
          icon: "mdi-chart-timeline-variant",
          // link: "/modifypage",
          color: "color:orange",
          iconColor: "color:orange",
        },
      ],
      audit_Header: [
        { text: "Sr. No.", value: "srno", sortable: false, align: "center" },
        {
          text: "Master ID",
          value: "masterId",
          sortable: false,
          align: "center",
        },
        {
          text: "Action Taken",
          value: "action",
          sortable: false,
          align: "center",
        },
        { text: "User ID", value: "userId", sortable: false, align: "center" },
        {
          text: "User Name",
          value: "userName",
          sortable: false,
          align: "center",
        },
        {
          text: "Time Stamp",
          value: "createdAt",
          sortable: false,
          align: "center",
        },
      ],
    modifyHeaders: [
        {
          text: "Sr. No.",
          value: "srNo",
          align: "center",
          sortable: false,
        },
        {
          text: "Field Name",
          value: "fieldName",
          align: "center",
          sortable: false,
        },
        {
          text: "Old Value",
          value: "oldValue",
          align: "center",
          sortable: false,
        },
        {
          text: "New Value",
          value: "newValue",
          align: "center",
          sortable: false,
        },
      ],
      master_status:['Approved','Rejected','Pending for approval'],
        aadharNumber:'',
        panNumberSmart:'',
        payeeNameSmart:'',
        aadharNumberSearch:[],
        payeeNameSearch:[],
        panNumberSearch:[],
      frequence_Header:[{text:"Action",value:"actions", sortable: false, align: "center",},
      {text:"Master ID",value:"frequentPayeeId", sortable: false, align: "center",},
      {text:"LOB",value:"lob", sortable: false, align: "center", width: "100px"},
      {text:"Payee Name",value:"payeeName", sortable: false, align: "center",},
      {text:"Contact",value:"contactPerson.number", sortable: false, align: "center",},
      {text:"City",value:"city", sortable: false, align: "center",},
      {text:"Pincode",value:"pinCode", sortable: false, align: "center",},
      {text:"GSTIN No.",value:"gstinNumber", sortable: false, align: "center",},
      {text:"PAN No",value:"panNumber", sortable: false, align: "center",},
      {text:"Bank Name",value:"bank.bankName", sortable: false, align: "center",},
      {text:"Bank Account No.",value:"bank.accountNumber", sortable: false, align: "center",},
      {text:"IFCS Code",value:"bank.ifscCode", sortable: false, align: "center",},
      {text:"payee Type",value:"payeeType", sortable: false, align: "center",},
      {text:"Address",value:"addressLine1", sortable: false, align: "center",},
      {text:"Aadhaar Number",value:"aadharId", sortable: false, align: "center",},
      {text:"Email Id",value:"emails.id1", sortable: false, align: "center",},
      {text:"Active Status",value:"active", sortable: false, align: "center",},
      {text:"Approval Status",value:"approveStatus", sortable: false, align: "center",}],
      

      lob:"",
      payeeName:"",
      panNumber:"",
      payeeType:"",
      aadharId:"",
      approveStatus:'',
      status:"",
      audit_dialog:false,
      audit_Data:[],
      seeModifyDialog:false,
      modifyItems: [],
      itemsPerPage: 10,
      totalListCount: 0,
      pageStartIndex: 1,
      pageCount: 0,
      page: 1,
      itemsPerPage_payee: 10,
      totalListCount_payee: 0,
      pageStartIndex_payee: 1,
      // pageCount_payee: 0,
      page_payee: 1,
      count:0,
      showSearchChip:false,
      AuditPage: 1,
      AuditTotalListCount: 0,
      AuditPageCount: 0,
      AuditPageStartIndex: 0,
      AuditItemsPerPage: 10,
      OpenAuditDialog: false,
      auditTrailId: null,
    }
  },
  methods:{
    onReset(data=false){
      if(data){
        this.showConfirm("Confirmation","Are you sure you want to Reset?","Yes","No",(yes)=>{
        this.lob="",
      this.payeeName="",
      this.panNumber="",
      this.payeeType="",
      this.aadharId=""
      this.approveStatus=''
      this.aadharNumber='',
      this.panNumberSmart='',
      this.payeeNameSmart='',
      this.status='',
      this.aadharNumberSearch=[],
      this.payeeNameSearch=[],
      this.panNumberSearch=[]
      })
      }else{this.lob=""
      this.payeeName=""
      this.panNumber=""
      this.payeeType=""
      this.aadharId=""
      this.approveStatus=''
      this.aadharNumber='',
      this.panNumberSmart='',
      this.payeeNameSmart='',
      this.status='',
      this.aadharNumberSearch=[],
      this.payeeNameSearch=[],
      this.panNumberSearch=[]}
      
      
    },
    smartSearch(value,fieldName){
      
      if(value.length>2&&fieldName=='AadharNumber'){
        this.aadharNumberSearch=this.frequency_Table_Data
      }else{
        this.aadharNumberSearch=[]
      }
       if(value.length>2&&fieldName=='panNumber'){
        this.panNumberSearch=this.frequency_Table_Data
       }else{
        this.panNumberSearch=[]
       }
       if(value.length>2&&fieldName=='payeeName'){
        this.payeeNameSearch=this.frequency_Table_Data
       }else{
        this.payeeNameSearch=[]
       }
    },
    chipsearchFrequency(newPageNum=1){
      this.showSearchChip=false
      this.GETM("searchFrequent","",(res)=>{
        this.onReset()
        if(res?.data?.statusCode === 200){
          this.frequency_Table_Data=res.data.data.data||[]
          this.count=res.data.data.total
          this.totalListCount_payee = res.data.data.total;
            // this.pageCount_payee = res.data.data.total_pages;
            this.pageStartIndex_payee = newPageNum == 1 ? 1 : (newPageNum - 1) * this.itemsPerPage_payee + 1;
        }else{
          this.frequency_Table_Data = res.data?.data?.data || []
          this.count = res.data?.data?.total || 0;
          this.totalListCount_payee = res.data.data?.total || 0;
          // this.pageCount_payee = res.data.data.total_pages;
          this.pageStartIndex_payee = 0
        }},(error) => {
          return error;
        },true)
        this.page_payee= 1
    },
    searchFrequency(newPageNum = 1, pagination_Search) {
      this.frequency_Table_Data = []
      // let reqData = '';

      let reqData = `page=${newPageNum}&perPage=${this.itemsPerPage_payee}`
      if(this.lob){
        reqData += `&lob=${this.lob}`
      }
      if(this.payeeName){
        reqData += `&payeeName=${this.payeeName}`
      }
      if(this.payeeType){
        reqData += `&payeeType=${this.payeeType}`
      }
      if(this.panNumber){
        reqData += `&panNumber=${this.panNumber}`
      }
      if(this.aadharId){
        reqData += `&aadharId=${this.aadharId}`
      }
      if(this.approveStatus){
        reqData += `&approveStatus=${this.approveStatus}`
      }
      if(this.status !== ""){
        reqData += `&active=${this.status}`
      }
      
      if (pagination_Search) {
        this.showSearchChip = true
       }

       this.GETM("searchFrequent", reqData, (res) => {
        if(res?.data?.statusCode === 200){
          this.frequency_Table_Data = res.data.data.data
          this.count = res.data.data.total
          this.totalListCount_payee = res.data.data.total;
          // this.pageCount_payee = res.data.data.total_pages;
          this.pageStartIndex_payee = newPageNum == 1 ? 1 : (newPageNum - 1) * this.itemsPerPage_payee + 1;
        } else {
          // this.showAlert(res.data?.msg || "Record not found")
          this.frequency_Table_Data = res.data?.data?.data || []
          this.count = res.data?.data?.total || 0;
          this.totalListCount_payee = res.data.data?.total || 0;
          // this.pageCount_payee = res.data.data.total_pages;
          this.pageStartIndex_payee = 0
        }
        }, (error) => {
          return error;
        }, true)

        
    },
  cancel(data) {
    this.showConfirm(
      "Confirmation",
      "Are you sure you want to leave?",
      "Yes",
      "No",
      (Yes) => {
        this.$router.push({ name: "Master" }); //params:{page:this.page}}
      }
    );
  },
  
  getLOB() {
      let reqData = '';
      this.showProgress();
      this.GETM("getLOB", reqData, (res) => {
          this.hideProgress();
          if(res.data.statusCode === 200) {
              this.lobOptions = res.data.data[0].lineOfBusiness;
          } else {
              this.lobOptions = [];
              this.showAlert(res.data.msg);
          }
      }, (error) => {
          console.log(error);
          this.hideProgress();
      }, true)
  },

  formatDate(dateTime) {
      return dateTime ? moment(dateTime).format("DD-MM-YYYY ") : "";
    },
    formatTime(dateTime) {
      return dateTime ? moment(dateTime).format("LT") : "";
    },
   seeModify(item) {
      console.log(item.modification)
      this.seeModifyDialog = true;
       this.modifyItems = item.modification
      //  .map((m, index) => {
      //    let object  = { ...m, srNo: index + 1 };
      //    if(this.dateFields.includes(m.fieldName)){
      //      object.newValue = this.formatDateOnly(m.newValue)
      //      object.oldValue = this.formatDateOnly(m.oldValue)
      //    }
      //    return object;
      //  });
    },
  editmaster(link, index, item, id, name,a) {
     if(index == 0){
      this.$router.push({
        name: "FrequentPayee",
        params: {
          isView: true,
          item: item,
        },
      });
     }
     if(index == 1){
      if(this.checkPermission()){
          return this.showAlert("You don't have permission to create or modify record.")
      }
      this.$router.push({
        name: "FrequentPayee",
        params: {
          isView: false,
          item: item,
           
        },
      });
     }
     if(index == 2){
      this.OpenAuditDialog = true
      this.auditTrailId = item.frequentPayeeId
      // this.audit_dialog=true
      // this.getAuditData(1,item.frequentPayeeId,false)
     }
    },
    getAuditData(newPageNum,id,bool){
      let reqData =""
      if(bool){
        reqData = "perPage=10" + "&page=" + newPageNum + "&masterType=frequentPayee"+"&masterId=" +this.audit_Data[0].masterId
      }else{
        reqData = "perPage=10" + "&page=" + newPageNum + "&masterType=frequentPayee"+"&masterId=" +id
      }
      
      //"&masterId=" + this.auditTrailItem.uid + 
      this.GETM('getAudits',reqData,(res)=>{
        this.audit_Data=res.data.data.data
        this.AuditTotalListCount = res.data.data.total;
                this.AuditPageCount = res.data.data.total_pages;
                this.AuditPageStartIndex = newPageNum == 1 ? 1 : (newPageNum - 1) * this.AuditItemsPerPage + 1;
      })
    },
   
    formatDateTime(dateTime) {
      return dateTime ? moment(dateTime).format("DD-MM-YYYY LT") : ""
    },
    createMaster() {
      if(this.checkPermission()){
          return this.showAlert("You don't have permission to create or modify record.")
      }
      this.$router.push({name:'FrequentPayee'})
    }
    },
    computed:{ 
      pageCount_payee() {
      if (!this.totalListCount_payee) {
        return 0;
      } else {
        return Math.ceil(this.totalListCount_payee / this.itemsPerPage_payee);
      }
    },
      pageEndIndex() {
      if (!this.audit_Data.length) {
        return 0;
      } else {
        return this.pageStartIndex + this.itemsPerPage <= this.totalListCount ? this.pageStartIndex + this.itemsPerPage - 1 : this.totalListCount;
      }
    },
    pageEndIndex_payee() {
      if (!this.frequency_Table_Data.length) {
        return 0;
      } else {
        return this.pageStartIndex_payee + this.itemsPerPage_payee <= this.totalListCount_payee ? this.pageStartIndex_payee + this.itemsPerPage_payee - 1 : this.totalListCount_payee;
      }
    },
    computedPAN() {
            this.panNumberSmart = this.panNumberSmart ? this.panNumberSmart.toUpperCase() : '';
            return this.panNumberSmart;
        },
        AuditPageEndIndex() {
      if (!this.audit_Data.length) {
        return 0
      } else {
        return this.AuditPageStartIndex + this.AuditItemsPerPage <=
          this.AuditTotalListCount
          ? this.AuditPageStartIndex + this.AuditItemsPerPage - 1
          : this.AuditTotalListCount
      }
    },
  },
  
  created(){
    this.$store.commit("SAVE_PAGE_HEADER", "Frequent Payee Master Search");
    this.getLOB();
    let newPageNum=1
      let reqData=`page=${newPageNum}&perPage=${this.itemsPerPage_payee}`
    this.GETM("searchFrequent",reqData,(res)=>{
      if(res?.data?.statusCode === 200){
          this.frequency_Table_Data=res.data.data.data||[]
          this.count=res.data.data.total
          this.totalListCount_payee = res.data.data.total;
            // this.pageCount_payee = res.data.data.total_pages;
            this.pageStartIndex_payee = newPageNum == 1 ? 1 : (newPageNum - 1) * this.itemsPerPage_payee + 1;
        }else{
          this.frequency_Table_Data = res.data?.data?.data || []
          this.count = res.data?.data?.total || 0;
          this.totalListCount_payee = res.data.data?.total || 0;
          // this.pageCount_payee = res.data.data.total_pages;
          this.pageStartIndex_payee = 0
        }
    },(error) => {
          return error;
        },true)
  },
}
</script>

<style scoped>
.btnToggle {
  text-transform: none;
  color: #fff;
  background-color: #1ebbd9;
}
.btn{
  text-transform: capitalize;
}
.tableicons {
  color: #23b1a9 !important;
}
.tablebtn {
  background: #ffffff 0% 0% no-repeat padding-box !important;
  box-shadow: inset 0px 2px 4px #00000052 !important;
  border: 0.5px solid #c1c8cc;
  border-radius: 18px !important;
  height: 30px !important;
  text-transform: capitalize !important;
  width: 100%;
  justify-content: space-between;
  font-size: small;
}
</style>