<template>
  <v-container fluid class="containerStyle">
    <v-row>
      <v-col cols="12">
        <v-btn
          class="financial_auth_btn"
          height="50"
          :class="{ selected_button: financial_autority_mapping === true }"
          @click="toggleBtn(1)"
          :disabled="availableroles.includes('CG1')"
        >
          Financial Authority Mapping
        </v-btn>
        <v-btn
          class="financial_auth_btn ml-1"
          height="50"
          :class="{ selected_button: financial_autority_approval === true }"
          @click="toggleBtn(2)"
        >
          Financial Authority Approval
        </v-btn>
        <v-divider style="width:100%;"></v-divider>
      </v-col>
    </v-row>
    <!-- Financial Authority Mapping Part -->
    <v-row v-if="this.financial_autority_mapping">
      <v-col cols="4">
        <p class="list_heading mt-4">List of Authority</p>
        <v-chip class="aigc_chip ml-3 mb-1" color="#E46A25" label small>
          {{fAuthMapTotal}}
        </v-chip>
        <v-chip class="ma-1 aigc_chip mb-2 ml-4" color="cyan" label small v-if="(this.faAuthSearchData.ntidUserId || this.faAuthSearchData.zoneName || this.faAuthSearchData.employeeId || this.faAuthSearchData.selectedLob || this.faAuthSearchData.status || this.faAuthSearchData.settlementOffice)">
          Search Result <span style="margin-left: 5px;"  @click="clearAuthMapSearch()">X</span></v-chip
        >
      </v-col>
      <v-col cols="8">
        <v-btn class="list_btn mt-2" @click="openFAMapDialogueBox = true">
          <v-icon class="mdi mdi-magnify mr-2"></v-icon>
          <div class="vertical_line"></div>
          Search
        </v-btn>
      </v-col>
    </v-row>

    <v-form ref="tableForm">
      <div class="testOuterTable" v-if="this.financial_autority_mapping">
        <v-data-table
          :headers="financialAuthMapHeader"
          :items="allUsersData"
          hide-default-footer
          disable-pagination
          class="phaseTwoVDailogBox mt-2 tableborder fancy-scrollbar" 
          :single-expand="singleExpand"
          :expanded.sync="expanded"
          fixed-header 
          height="450"
        >
          <template v-for="header in financialAuthMapHeader.filter((header) => header.hasOwnProperty('formatter'))" v-slot:[`item.${header.value}`]="{ header, value }">
              {{ header.formatter(value) }}
          </template>
          <template v-slot:[`item.recommendFAlevel`]="{item}"  >
            <v-select
              label="Select"
              v-model="item.recommendFAlevel"
              solo 
              item-text="level"
              item-value="_id"
              return-object
              @change="slctdFAlevel(item.recommendFAlevel,item)"
              :items="item.levelItems"
              class="rounded-0 mt-3"
              dense
              :disabled="!disableAll"
              clearable
            ></v-select>
          </template>
            <template v-slot:[`item.remarks`]="{item}" >
              <v-text-field   
                  label="Enter..."
                  v-model="item.remarks"
                  solo 
                  dense
                  class="rounded-0 mt-3"
                  :rules="[textRule(item.remarks,item.recommendFAlevel)]"
                  :disabled="!disableAll"
                  maxlength="500"
              ></v-text-field>
          </template>

          <template v-slot:[`item.existingRecomendedReserving`]="{ item }">
              <span >{{ parseInt(item.existingRecomendedReserving) | moneyformat }}</span>
          </template>

          <template v-slot:[`item.existingRecomendedPayments`]="{ item }">
              <span >{{ parseInt(item.existingRecomendedPayments) | moneyformat}}</span>
          </template>

          <template v-slot:[`item.recommendedReserving`]="{item}" >
              <span >{{ parseInt(item.recommendedReserving) | moneyformat }}</span>
          </template>

          <template v-slot:[`item.recomendedPayments`]="{item}" >
              <span >{{ parseInt(item.recomendedPayments) | moneyformat }}</span>
          </template>


          <template v-slot:[`item.joiningDate`]="{ item }">
              <div >{{ item.joiningDate | formatDateOnly}}</div>
          </template>
          <template v-slot:[`item.updatedAt`]="{ item }">
              <div >{{ item.updatedAt | formatDate2}}</div>
          </template>
      
        </v-data-table>
        <v-layout
          row
          justify-end
          align-center
          class="mx-2 headercolor"
          style="margin-left: 0px !important; margin-right: 0px !important"
        >
          <p
            class="mr-1 font-weight-bold body-1"
            style="color: #3c3d3d; font-size: 12px !important"
          >
            <span class="" style="color: #e46a25; font-size: 12px"
              >Showing {{ fAuthMapStartIndex }}-{{ fAuthMapEndIndex }} </span
            >out of {{ fAuthMapTotal }} records
          </p>
          <v-divider class="mx-2" vertical></v-divider>
          <v-pagination
            class="cust-bnkAcc-pgn"
            v-model="fAuthPage"
            color="#152F38"
            flat
            :total-visible="9"
            @input="getLevelDetails(fAuthPage)"          
            :length="getFAuthNoOfPages"
          ></v-pagination>
        </v-layout>
        <v-row>
          <v-flex xs6></v-flex>
          <v-flex xs3></v-flex>
          <v-flex xs3>
            <v-layout style="background-color:#F7F7F7" justify-end class="pa-4" v-if="allUsersData.length > 0">
                <v-btn tile  @click="saveFALevel" dark
                style="font-size:12px;background-color:#23B1A9"
                class="text-capitalize px-3 mx-2" small>
                    <v-icon dark small>check_circle</v-icon>
                    <v-divider vertical class="mx-2"></v-divider> Save</v-btn>
            </v-layout>
          </v-flex>
        </v-row>
      </div>
    </v-form>
    <!------------------------Search Financial Auth Mapping Dialougebox---------------->

    <v-row justify="center">
      <v-dialog
        v-model="openFAMapDialogueBox"
        persistent
        max-width="60%"
        style="border-radius:0"
      >
        <v-card>
          <v-card-title class="pl-0 px-0 py-0 searchDialougeBoxBackground">
            <v-row>
              <span class="descrptionSpan mb-3 ml-8 mr-2">Search</span>
              <v-icon
                @click="openFAMapDialogueBox = false"
                class="ml-4 mt-2 close_dialouge"
                style="color:#fff;"
                >mdi-window-close</v-icon
              >
            </v-row>
            <v-card-text class="vcardTextTableBackground pl-2 pt-2 mt-3">
              <v-form ref="searchSPForm">
                <v-layout>
                  <v-flex xs4>
                    <v-layout column class="ml-3">
                      <p class="mb-2 lableFontSize">NTID/UserID</p>
                      <v-text-field
                        placeholder="Enter"
                        solo
                        dense
                        class="forminput"
                        v-model="faAuthSearchData.ntidUserId"
                      >
                      </v-text-field>
                    </v-layout>
                  </v-flex>
                  <v-flex xs4>
                    <v-layout column class="ml-3">
                      <p class="mb-2 lableFontSize">Zone Name</p>
                      <v-text-field
                        placeholder="Enter"
                        solo
                        dense
                        class="forminput"
                        v-model="faAuthSearchData.zoneName"
                         @change="getSettlementOfficeList(faAuthSearchData.selectedLob,faAuthSearchData.zoneName)"
                      >
                      </v-text-field>
                    </v-layout>
                  </v-flex>
                  <v-flex xs4>
                    <v-layout column class="ml-3">
                      <p class="mb-2 lableFontSize">Employee ID</p>
                      <v-text-field
                        placeholder="Enter"
                        solo
                        dense
                        class="forminput"
                        v-model="faAuthSearchData.employeeId"
                      >
                      </v-text-field>
                    </v-layout>
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex xs4>
                    <v-layout column class="ml-3">
                      <p class="mb-2 lableFontSize">Status</p>
                      <!-- <v-text-field
                        placeholder="Enter"
                        solo
                        dense
                        class="forminput"
                        v-model="faAuthSearchData.status"
                      >
                      </v-text-field> -->
                      <v-select
                        placeholder="Select"
                        solo
                        dense
                        class="forminput"
                        :items="financialAuthorityMappingStatusList"
                        v-model="faAuthSearchData.status"
                        clearable
                      >
                      </v-select>
                    </v-layout>
                  </v-flex>
                  <v-flex xs4>
                    <v-layout column class="ml-3">
                      <p class="mb-2 lableFontSize">LOB</p>
                      <v-select
                        placeholder="Select"
                        solo
                        dense
                        class="forminput"
                        :items="lobList"
                        v-model="faAuthSearchData.selectedLob"
                        @change="getSettlementOfficeList(faAuthSearchData.selectedLob,faAuthSearchData.zoneName)"
                        clearable
                      >
                      </v-select>
                    </v-layout>
                  </v-flex>
                  <v-flex xs4>
                    <v-layout column class="ml-3">
                      <p class="mb-2 lableFontSize">Settlement Office Name</p>
                      <v-autocomplete
                        placeholder="Select"
                        solo
                        dense
                        class="forminput"
                        v-model="faAuthSearchData.settlementOffice"
                        :items="settlementOfficeListForAuthMapping"   
                        item-text="settlementOfficeCodeName"                    
                        item-value="_id"
                        clearable
                      >
                        <template slot="item" slot-scope="data">
                          <!-- HTML that describe how select should render items when the select is open -->
                          {{ data.item.settlementOfficeCode }} - {{ data.item.settlementOfficeName }}
                        </template>
                        <template slot="selection" slot-scope="data">
                          <!-- HTML that describe how select should render selected items -->
                          {{ data.item.settlementOfficeCode }} - {{ data.item.settlementOfficeName }}
                        </template>
                      </v-autocomplete>
                    </v-layout>
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex xs4>
                    <v-layout column class="ml-3">
                      <p class="mb-2 lableFontSize">Agreement Type</p>
                      <v-select
                        placeholder="Select"
                        solo
                        dense
                        class="forminput"
                        v-model="faAuthSearchData.agreementType"
                        :items="agreementTypeOptions"
                        clearable
                      >
                      </v-select>
                    </v-layout>
                  </v-flex>

                   <v-flex xs4>
                    <v-layout column class="ml-3">
                      <p class="mb-2 lableFontSize">Type of Employee</p>
                      <v-select
                        placeholder="Select"
                        solo
                        dense
                        class="forminput"
                        v-model="faAuthSearchData.typeOfEmployee"
                        :items="whatemployeewehave"
                        clearable
                      >
                      </v-select>
                    </v-layout>
                  </v-flex>
                </v-layout>
                <v-row style="background-color:#fff">
                  <v-col align="right">
                    <v-btn class="btnstyle cancelbtn mr-3" @click="openFAMapDialogueBox = false">
                      Cancel
                    </v-btn>
                    <v-btn 
                      color="#23B1A9" 
                      dark 
                      class="btnstyle mr-2"
                      @click="searchFaAuthData()"
                    >
                      Search
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card-title>
        </v-card>
      </v-dialog>
    </v-row>

    <!-- Financial Authority Mapping Approval Part -->
    <v-row v-if="this.financial_autority_approval">
      <v-col cols="4">
        <p class="list_heading mt-4">List of Approvals</p>
        <v-chip class="aigc_chip ml-3 mb-1" color="#E46A25" label small>
          {{fAuthApproveTotal}}
        </v-chip>
        <v-chip class="ma-1 aigc_chip mb-2 ml-4" color="cyan" label small v-if="(this.searchData.ntidUserId || this.searchData.zoneName || this.searchData.employeeId || this.searchData.selectedLob || this.searchData.status || this.searchData.settlementOffice) && this.financialAuthApprovaltems.length > 0">
          Search Result <span style="margin-left: 5px;" @click="clearApproveSearch()">X</span></v-chip
        >
      </v-col>
      <v-col cols="8">
        <v-btn class="list_btn mt-2" @click="openDialogueBox = true">
          <v-icon class="mdi mdi-magnify mr-2"></v-icon>
          <div class="vertical_line"></div>
          Search
        </v-btn>
      </v-col>
    </v-row>

    <div class="testOuterTable" v-if="this.financial_autority_approval">
      <v-data-table
        :headers="financialAuthApprovaHeader"
        :items="financialAuthApprovaltems"
        hide-default-footer
        class="phaseTwoVDailogBox mt-2 tableborder fancy-scrollbar"
        :single-expand="singleExpand"
        :expanded.sync="expanded"
        disable-pagination
        fixed-header
        height="450"
      >
        <template v-slot:[`item.approvalStatus`]="{ item }">
          <!-- <v-flex xs4> -->
            <v-select
              type="text"
              placeholder="Select"
              :items="approveRejectOptions"
              class="autocompleteItems"
              dense
              solo
              v-model="item.approvalStatus"
              clearable
              @change="changeText(item)"
              :disabled="disableApproveRejectOption"
            >
            </v-select>
          <!-- </v-flex> -->
        </template>
        <template v-slot:[`item.reasonForRejection`]="{ item }">
          <v-text-field
            type="text"
            class="textfieldItems"
            dense
            placeholder="Enter"
            solo
            v-model="item.reasonForRejection"
            :disabled="item.approvalStatus !== 'Reject'"
            maxlength="500"
          ></v-text-field>
        </template>
        <!-- <template v-slot:[`item.remark`]="{ item }">
          <v-text-field
            type="text"
            class="textfieldItems"
            dense
            placeholder="Enter"
            solo
            v-model="item.remark"
            disabled
          ></v-text-field>
        </template> -->
        <template v-slot:[`item.action`]="{ item,index }">
          <span @click="expandUserLobData(item,index)" class="viewCls font-weight-bold"
            >View</span
          >
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td class="px-1" :colspan="headers.length" v-if="item.showData" style="background-color:#f7f7f7;">
            <v-row style="margin-top:0px;height:35px">
              <v-col
                
                align="right"
                class="mb-0 mt-5 mr-3 pb-3"
                style="cursor:pointer"
              >
                <span
                  @click="closeView(item)"
                  class="font-weight-bold"
                  style="margin-right:
                  15px"
                  >CLOSE</span
                >
              </v-col>
            </v-row>
            <div class="testInnerTable">
              <v-data-table
                :headers="faApprovalInnerTableHeaders"
                :items="item.users"
                hide-default-footer
                fixed-header
                disable-pagination
                :height="item.users && item.users.length > 2 ? 250 :150"
                class="vDataTableSHeaderNewColor mb-3 mx-4">
                <!-- <template v-slot:header="{ props }">
                  <th  v-for="head in props.headers" :key="head.text"  class="inner_table_color">{{ head.text }}</th>
                </template>

                <template v-for="header in faApprovalInnerTableHeaders.filter((header) => header.hasOwnProperty('formatter'))" v-slot:[`item.${header.value}`]="{  value }">
                    {{ header.formatter(value) }}
                </template> -->
                <template v-slot:[`item.remark`]="{item,index}" v-if="viewMoreRemarkHideShow">
                  <span v-if="!item.isViewMore"> {{item.remark && item.remark.length > 20 ? item.remark.substr(0,20) : item.remark}}  <p @click="showMoreLess(item,index)" class="viewCls" v-if="item.remark && item.remark.length > 20 && !item.isViewMore">View More</p></span>
                  <v-flex v-else>{{item.remark}}<p @click="showMoreLess(item,index)" class="viewCls">View Less</p></v-flex>
                </template>
              </v-data-table>
            </div>
          </td>
        </template>
      </v-data-table>
      <v-layout
        row
        justify-end
        align-center
        class="mx-2 headercolor"
        style="margin-left: 0px !important; margin-right: 0px !important"
      >
        <p
          class="mr-1 font-weight-bold body-1"
          style="color: #3c3d3d; font-size: 12px !important"
        >
          <span class="" style="color: #e46a25; font-size: 12px"
            >Showing {{ fAuthApproveStartIndex }}-{{ fAuthApproveEndIndex }} </span
          >out of {{ fAuthApproveTotal }} records
        </p>
        <v-divider class="mx-2" vertical></v-divider>
        <v-pagination
          class="cust-bnkAcc-pgn"
          v-model="fApprovePage"
          color="#152F38"
          flat
          :total-visible="9"
          @input="getDataForApproval(fApprovePage)"
          :length="getFApproveNoOfPages"
        ></v-pagination>
      </v-layout>
      <v-row>
        <v-flex xs6></v-flex>
        <v-flex xs3></v-flex>
        <v-flex xs3>
          <v-layout style="background-color:#F7F7F7" justify-end class="pa-4" v-if="financialAuthApprovaltems.length > 0">
              <v-btn tile  @click="saveApprovalData" dark
              style="font-size:12px;background-color:#23B1A9"
              class="text-capitalize px-3 mx-2" medium v-if="!isCg4User">
                  <v-icon dark medium>mdi-arrow-right-circle</v-icon>
                  <v-divider vertical class="mx-2"></v-divider> Submit</v-btn>
          </v-layout>
        </v-flex>
      </v-row>
    </div>
    <!------------------------Search Approval Users Dialougebox---------------->

    <v-row justify="center">
      <v-dialog
        v-model="openDialogueBox"
        persistent
        max-width="60%"
        style="border-radius:0"
      >
        <v-card>
          <v-card-title class="pl-0 px-0 py-0 searchDialougeBoxBackground">
            <v-row>
              <span class="descrptionSpan mb-3 ml-8 mr-2">Search</span>
              <v-icon
                @click="openDialogueBox = false"
                class="ml-4 mt-2 close_dialouge"
                style="color:#fff;"
                >mdi-window-close</v-icon
              >
            </v-row>
            <v-card-text class="vcardTextTableBackground pl-2 pt-2 mt-3">
              <v-form ref="searchSPForm">
                <v-layout>
                  <v-flex xs4>
                    <v-layout column class="ml-3">
                      <p class="mb-2 lableFontSize">NTID/UserID</p>
                      <v-text-field
                        placeholder="Enter"
                        solo
                        dense
                        class="forminput"
                        v-model="searchData.ntidUserId"
                      >
                      </v-text-field>
                    </v-layout>
                  </v-flex>
                  <v-flex xs4>
                    <v-layout column class="ml-3">
                      <p class="mb-2 lableFontSize">Zone Name</p>
                      <v-text-field
                        placeholder="Enter"
                        solo
                        dense
                        class="forminput"
                        v-model="searchData.zoneName"                        
                        @change="getSettlementOfficeList(searchData.selectedLob,searchData.zoneName)"
                      >
                      </v-text-field>
                    </v-layout>
                  </v-flex>
                  <v-flex xs4>
                    <v-layout column class="ml-3">
                      <p class="mb-2 lableFontSize">Employee ID</p>
                      <v-text-field
                        placeholder="Enter"
                        solo
                        dense
                        class="forminput"
                        v-model="searchData.employeeId"
                      >
                      </v-text-field>
                    </v-layout>
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex xs4>
                    <v-layout column class="ml-3">
                      <p class="mb-2 lableFontSize">Status</p>
                      <!-- <v-text-field
                        placeholder="Enter"
                        solo
                        dense
                        class="forminput"
                        v-model="searchData.status"
                      >
                      </v-text-field> -->
                      <v-select
                        placeholder="Select"
                        solo
                        dense
                        class="forminput"
                        :items="financialAuthorityMappingStatusList"
                        v-model="searchData.status"
                        clearable
                      >
                      </v-select>
                    </v-layout>
                  </v-flex>
                  <v-flex xs4>
                    <v-layout column class="ml-3">
                      <p class="mb-2 lableFontSize">LOB</p>
                      <v-select
                        placeholder="Select"
                        solo
                        dense
                        class="forminput"
                        v-model="searchData.selectedLob"
                        :items="lobList"
                        @change="getSettlementOfficeList(searchData.selectedLob,searchData.zoneName)"
                        clearable
                      >
                      </v-select>
                    </v-layout>
                  </v-flex>
                  <v-flex xs4>
                    <v-layout column class="ml-3">
                      <p class="mb-2 lableFontSize">Settlement Office Name</p>
                      <v-autocomplete
                        placeholder="Select"
                        solo
                        dense
                        class="forminput"
                        v-model="searchData.settlementOffice"
                        :items="settlementOfficeListForApproval"
                        item-text="settlementOfficeCodeName"   
                        item-value="_id"
                        clearable
                      >
                        <template slot="item" slot-scope="data">
                          <!-- HTML that describe how select should render items when the select is open -->
                          {{ data.item.settlementOfficeCode }} - {{ data.item.settlementOfficeName }}
                        </template>
                        <template slot="selection" slot-scope="data">
                          <!-- HTML that describe how select should render selected items -->
                          {{ data.item.settlementOfficeCode }} - {{ data.item.settlementOfficeName }}
                        </template>
                      </v-autocomplete>
                    </v-layout>
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex xs4>
                    <v-layout column class="ml-3">
                      <p class="mb-2 lableFontSize">Agreement Type</p>
                      <v-select
                        placeholder="Select"
                        solo
                        dense
                        class="forminput"
                        v-model="searchData.agreementType"
                        :items="agreementTypeOptions"
                        clearable
                      >
                      </v-select>
                    </v-layout>
                  </v-flex>

                   <v-flex xs4>
                    <v-layout column class="ml-3">
                      <p class="mb-2 lableFontSize">Type of Employee</p>
                      <v-select
                        placeholder="Select"
                        solo
                        dense
                        class="forminput"
                        v-model="searchData.typeOfEmployee"
                        :items="typeOfEmployeeWeHaveOther"
                        clearable
                      >
                      </v-select>
                    </v-layout>
                  </v-flex>
                </v-layout>
                <v-row style="background-color:#fff">
                  <v-col align="right">
                    <v-btn class="btnstyle cancelbtn mr-3" @click="openDialogueBox = false">
                      Cancel
                    </v-btn>
                    <v-btn 
                      color="#23B1A9" 
                      dark 
                      class="btnstyle mr-2"
                      @click="searchFaApprovalData()"
                    >
                      Search
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card-title>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import moment from 'moment';
export default {
  name: "commonModule",
  data() {
    return {
      expanded: [],
      financial_autority_mapping: false,
      financial_autority_approval: false,
      openDialogueBox: false,      
      faApprovalInnerTableHeaders: [
       
        // {
        //   text: "Major Line Code",
        //   align: "start",
        //   value: "majorLineCode",
        //   sortable: false,
        //   width: "5%",
        // },
        {
          text: "Major Line Description",
          align: "start",
          sortable: false,
          value: "selectedLOB",
          width: "5%",
        },
        
        {
          text: "Agreement Type",
          align: "start",
          value: "agreementType",
          sortable: false,
          width: "10%",
          // width: "20%",
        },
        
        {
          text: "Type of Employee",
          align: "start",
          value: "typeOfEmployee",
          sortable: false,
          width: "10%",
          // width: "20%",
        },
        
        {
          text: "Existing Level",
          align: "start",
          sortable: false,
          value: "existingFALevel",
          width: "5%",
        },
        {
          text: "Existing Reserve",
          align: "start",
          sortable: false,
          value: "existingRecomendedReserving",
          width: "5%",
        },
        {
          text: "Existing Payments",
          align: "start",
          sortable: false,
          value: "existingRecomendedPayments",
          width: "5%",
        },
        // {
        // Commented for https://iortatech.atlassian.net/browse/CM-184
        //   text: "Existing FA Level Last Updated",
        //   align: "start",
        //   sortable: false,
        //   value: "lastFaUpdateDate",
        //   width: "5%",
        //   formatter: this.formatDate
        // },
        {
          text: "Recommended FA Level",
          align: "start",
          sortable: false,
          value: "recomendedFALevel",
          width: "5%",
        },
        {
          text: "Recommended Reserves",
          align: "start",
          sortable: false,
          value: "recomendedReserving",
          width: "15%",
        },
        {
          text: "Recommended Payments",
          align: "start",
          sortable: false,
          value: "recomendedPayments",
          width: "15%",
        },
        
        //Requester Data
         {
          text: "Requester ID",
          align: "start",
          sortable: false,
          value: "faInitiatorNtid",
          width: "5%",
        },
        {
          text: "Requester Name",
          align: "start",
          sortable: false,
          value: "faInitiatorName",
          width: "5%",
        },
        {
          text: "Recommended by ID",
          align: "start",
          sortable: false,
          value: "faRecommenderNtid",
          width: "5%",
        },
        {
          text: "Recommended by Name",
          align: "start",
          sortable: false,
          value: "faRecommenderName",
          width: "5%",
        },
        //Requester Data
        {
          text: "Remark",
          align: "start",
          sortable: false,
          value: "remark",
          width: "25%"
        },
        {
          text: "Status",
          align: "start",
          sortable: false,
          value: "faLevelApprovalStatus",
          width: "15%",
        },
        {
          text: "Approved Date By CCO",
          align: "start",
          sortable: false,
          value: "approveByCCODate",
          width: "5%",
          formatter: this.formatDate
        },
        {
          text: "Date of System Update",
          align: "start",
          sortable: false,
          value: "updatedAt",
          width: "15%",
          formatter: this.formatDate
        },
        
      ],
      financialAuthApprovaHeader: [
        {
          text: "NTID/User ID",
          align: "start",
          value: "ntidUserId",
          sortable: false,
          width: "10%",
        },
        {
          text: "Employee Code",
          align: "start",
          value: "employeeId",
          sortable: false,
          width: "10%",
          // width: "20%",
        },
        {
          text: "Name",
          align: "start",
          value: "employeeName",
          sortable: false,
        },
        {
          text: "Approve/Reject",
          align: "start",
          value: "approvalStatus",
          sortable: false,
        },
        {
          text: "Reason for Rejection",
          align: "start",
          value: "reasonForRejection",
          sortable: false,
        },
        // {
        //   text: "Remarks",
        //   align: "start",
        //   value: "remark",
        //   sortable: false,
        // },
        {
          text: "Actions",
          align: "start",
          value: "action",
          sortable: false,
        },
      ],
      financialAuthMapHeader:[
          {text: 'Sr. No.', value: 'SrNo',sortable: false,divider:true,width: "10%",height:"10px",class:"hdr-txt-clr_Famp backColor_Famp"},
          {text: 'User ID/NTID', value: 'ntidUserId',sortable: false,divider:true,width: "10%",height:"10px",class:"hdr-txt-clr_Famp backColor_Famp"},
          {text: 'Employee ID', value:'employeeId',sortable: false,divider:true,width: "10%",height:"10px",class:"hdr-txt-clr_Famp backColor_Famp"},
          {text: 'Employee Name', value: 'employeeName',sortable: false,divider:true,width: "10%",height:"10px",class:"hdr-txt-clr_Famp backColor_Famp"},
          {text: 'Agreement Type', value: 'agreementType',sortable: false,divider:true,width: "10%",height:"10px",class:"hdr-txt-clr_Famp backColor_Famp"},
          {text: 'Type of Employee', value: 'typeOfEmployee',sortable: false,divider:true,width: "10%",height:"10px",class:"hdr-txt-clr_Famp backColor_Famp"},
          // {text: 'Major Line Code', value: 'majorLineCode',sortable: false,divider:true,width: "155px",class:"hdr-txt-clr_Famp backColor_Famp"},
          {text: 'Major Line Description', value: 'selectedLOB',sortable: false,divider:true,width: "10%",height:"10px",class:"hdr-txt-clr_Famp backColor_Famp"},
          {text: 'Joining Date', value: 'joiningDate',sortable: false,divider:true,width: "10%",height:"10px",class:"hdr-txt-clr_Famp backColor_Famp"},
          {text: 'Existing Level', value: 'faLevel',sortable: false,divider:true,width: "10%",height:"10px",class:"hdr-txt-clr_Famp backColor_Famp"},
          {text: 'Existing Reserving', value: 'existingRecomendedReserving',sortable: false,height:"10px",divider:true,width: "10%",class:"hdr-txt-clr_Famp backColor_Famp"},
          {text: 'Existing Payments', value: 'existingRecomendedPayments',sortable: false,height:"10px",divider:true,width: "10%",class:"hdr-txt-clr_Famp backColor_Famp"},
          // {text: 'Last Updated Date of Existing FA Level', value: 'lastFaUpdateDate',sortable: false,divider:true,width: "235px",class:"hdr-txt-clr_Famp backColor_Famp",formatter:this.formatDateTime},// Commented for https://iortatech.atlassian.net/browse/CM-184
          //Requester Data
      /*   {
          text: "Requester ID",
          align: "start",
          sortable: false,
          value: "faInitiatorNtid",
          divider:true,width: "172px",class:"hdr-txt-clr_Famp backColor_Famp"
        },
        {
          text: "Requester Name",
          align: "start",
          sortable: false,
          value: "faInitiatorName",
          divider:true,width: "172px",class:"hdr-txt-clr_Famp backColor_Famp"
        },
        {
          text: "Recommended by ID",
          align: "start",
          sortable: false,
          value: "faRecommenderNtid",
          divider:true,width: "172px",class:"hdr-txt-clr_Famp backColor_Famp"
        },
        {
          text: "Recommended by Name",
          align: "start",
          sortable: false,
          value: "faRecommenderName",
          divider:true,width: "172px",class:"hdr-txt-clr_Famp backColor_Famp"
        },*/
        //Requester Data
          {text: 'Recommend FA Level', value: 'recommendFAlevel',sortable: false,divider:true,width: "10%",height:"10px",class:"hdr-txt-clr_Famp backColor_Famp"},
          {text: 'Recommended Reserving', value: 'recommendedReserving',sortable: false,divider:true,width: "10%",height:"10px",class:"hdr-txt-clr_Famp backColor_Famp"},
          {text: 'Recommended Payments', value: 'recomendedPayments',sortable: false,divider:true,width: "10%",height:"10px",class:"hdr-txt-clr_Famp backColor_Famp"},
          {text: 'Remarks *', value: 'remarks',sortable: false,divider:true,width: "200px",height:"10px",class:"hdr-txt-clr_Famp backColor_Famp"}

      ],
      financialAuthApprovaltems: [],
      innerTableDataList:[],
      singleExpand:false,
      searchData:    {
        ntidUserId  : "",
        zoneName  : "",
        employeeId  : "",
        status  : "",
        selectedLob : "",
        settlementOffice  : "",
        agreementType:"",
        typeOfEmployee:""
      },
      levelData:[],
      allUsersData:[],
      page:1,
      disableAll:true,
      sortedFA:[{"num":1,"lvl":"Level I"},{"num":2,"lvl":"Level II"},{"num":3,"lvl":"Level III"},{"num":4,"lvl":"Level IV"},{"num":5,"lvl":"Level V"},{"num":6,"lvl":"Level VI"},{"num":7,"lvl":"Level VII"},{"num":8,"lvl":"Level VIII"},{"num":9,"lvl":"Level IX"},{"num":10,"lvl":"Level X"},{"num":11,"lvl":"Level XI"},{"num":12,"lvl":"Level XII"},{"num":13,"lvl":"Level XIII"},{"num":14,"lvl":"Level XIV"},{"num":15,"lvl":"Level XV"},{"num":16,"lvl":"Level XVI"},{"num":17,"lvl":"Level XVII"},{"num":18,"lvl":"Level XVIII"},{"num":19,"lvl":"Level XIX"},{"num":20,"lvl":"Level XX"},{"num":21,"lvl":"Level XXI"},{"num":22,"lvl":"Level XXII"},{"num":23,"lvl":"Level XXIII"},{"num":24,"lvl":"Level XXIV"},{"num":25,"lvl":"Level XXV"}],
      fAuthMapStartIndex:0,
      fAuthMapTotal:0,
      fAuthPage:1,
      pageRowCount:50,//10,//https://iortatech.atlassian.net/browse/CM-218
      fAuthApproveStartIndex:0,
      fAuthApproveTotal:0,
      fApprovePage:1,
      openFAMapDialogueBox:false,
      faAuthSearchData:    {
        ntidUserId  : "",
        zoneName  : "",
        employeeId  : "",
        status  : "",
        selectedLob : "",
        settlementOffice  : "",
        agreementType:"",
        typeOfEmployee:""
      },
      lobList:[],
      settlementOfficeListForApproval:[],
      settlementOfficeListForAuthMapping:[],
      viewMoreRemarkHideShow:true,
      availableroles:"",
      financialAuthorityMappingStatusList:  ["Submitted", "Recommended", "Approved", "Rejected", "Closed"],
      financialAuthorityApprovalStatusList:  ["Approved", "Rejected", "Initiated for approval"],
      agreementTypeOptions: ["Employee","External Resource"]
    };
  },
  
  methods: {
    toggleBtn(num) {
      this.financial_autority_mapping = false;
      this.financial_autority_approval = false;
      this.fAuthPage  = 1
      this.fApprovePage = 1
      let searchObj = {
            ntidUserId  : "",
            zoneName  : "",
            employeeId  : "",
            status  : "",
            selectedLob : "",
            settlementOffice  : ""
          };
      this.searchData =  searchObj  
		  
		  this.faAuthSearchData =    searchObj
      if (num == 1) {
        this.financial_autority_mapping = true;
        this.getLevelDetails()      
      } else if (num == 2) {
        this.financial_autority_approval = true;
        this.getDataForApproval(1);
      }
      this.getSettlementOfficeList();
    },
    expandUserLobData(row, index) {
      console.log("ExpandedUserData = ",row)
      this.financialAuthApprovaltems[index].showData  = true
      this.expanded = [];
      this.expanded = row.users//row === this.expanded[index] ? [] : [row];
    },
    closeView(item) {
      this.financialAuthApprovaltems.map((elem)=>{
        return item.ntidUserId == elem.ntidUserId ? elem.showData  = false : true
      })
      if(this.financialAuthApprovaltems.filter((elem)=>{
        return elem.showData
      }).length == 0){
        this.expanded = [];
      }else if(this.expanded.length > 0){
        this.expanded = this.financialAuthApprovaltems.filter((elem)=>{
          return elem.showData
        })
      }else{
        this.expanded = [];
      }
      
      
    },
    searchFaApprovalData(){
      this.openDialogueBox = false
      this.fApprovePage = 1
      this.getDataForApproval(1)
    },
    
    //OldFunctions

    getDataForApproval(page){  
      let req =  'approvalProcess/get-users-for-approval?perPage='+this.pageRowCount+'&pageNo='+page 
      this.searchData.ntidUserId ? req = req + "&ntidUserId="+this.searchData.ntidUserId : ""
      this.searchData.zoneName ? req = req + "&zoneName="+this.searchData.zoneName : ""
      this.searchData.employeeId ? req = req + "&employeeId="+this.searchData.employeeId : ""
      this.searchData.status && !this.availableroles.find(function(elem){return elem == "CG2"}) && !this.availableroles.find(function(elem){return elem == "CG1"}) ? req = req + "&status="+this.searchData.status : ""
      this.searchData.selectedLob ? req = req + "&selectedLob="+this.searchData.selectedLob : ""
      this.searchData.settlementOffice ? req = req + "&settlementOffice="+this.searchData.settlementOffice : ""
      this.searchData.agreementType ? req = req + "&agreementType="+this.searchData.agreementType : ""
      this.searchData.typeOfEmployee ? req = req + "&typeOfEmployee="+this.searchData.typeOfEmployee : ""
      
      if(this.availableroles.find(function(elem){return elem == "CG2"})){
          req =   req + '&status='+'Recommended'
          console.log('from if Recommended');
      }else if(this.availableroles.find(function(elem){return elem == "CG1"})){
          req =   req + '&status='+'Approved'
          this.status =   'Approved'
          console.log('from if Approved');
      }
      this.statusData  = []
      this.financialAuthApprovaltems  = []
      this.GET(6,req, (res, err) => {
          if(!err){
              console.log(res.data.data.data);
              this.fAuthApproveTotal = res.data.data.total
              this.fAuthApproveStartIndex =  res.data.data.current_page_no == 1 ? 1 : ((res.data.data.current_page_no - 1) * this.pageRowCount) + 1;          
             
              this.financialAuthApprovaltems = res.data.data.data;
              if(res.data.data.data.length == 0){
                this.fAuthApproveStartIndex = 0
              }

              if(this.theroles('CG1')){
                  this.levelApprove = ['Approve'];
              }
              this.financialAuthApprovaltems.map((elem)=>{
                      elem.reasonForRejection = this.concatAllRejection(elem);
                return elem.remark = this.concatAllRemarks(elem);
              })

              this.financialAuthApprovaltems.map((authApprovalItem,outerIndex)=>{
                console.log("outerIndex => ",outerIndex)
                return authApprovalItem.users.map((userDetails)=>{
                  userDetails.outerIndex  = outerIndex
                  return userDetails.isViewMore = false
                })
              })

              this.statusData = this.financialAuthApprovaltems.map((item,index) => {
                  console.log("item for status => ",item)
                    item.status = item.faLevelApprovalStatus
                  if(item.status == 'Recommended' || item.status == 'Approved' || item.status == 'Approve' || item.status == 'Yes'){
                      this.disableCG2 = true;
                  }
              //    else if(item.status == 'Rejected' || item.status == 'Reject' || item.status == 'No'){
                      else{
                      this.disableCG2 = false;
                  }

                  if(item.status == 'Approved' || item.status == 'Approve' || item.status == 'Yes'){
                      this.disableCG3 = true;
                  }else{
                      this.disableCG3 = false;
                  }

                  let noDate = '';
                  let ccoDateData = '';
                  if(item.approveByCCODate == undefined){
                      noDate = '';
                  }else{
                      dateIs = moment(item.approveByCCODate).format("DD-MM-YYYY hh:mm:ss A");//._i.split("T")[0] +' ';
                      console.log(dateIs);
                      ccoDateData = dateIs//moment(dateIs).format("DD-MM-YYYY");
                  }

                  let nullDate = '';
                  let toDateData = '';
                  if(item.updatedAt == undefined){
                      nullDate = '';
                  }else{
                      dateIs = moment(item.updatedAt).format("DD-MM-YYYY hh:mm:ss A");//._i.split("T")[0] +' ';
                      console.log(dateIs);
                      toDateData = dateIs//moment(dateIs).format("DD-MM-YYYY");
                  }
                  
                  console.log(page);
                  return {                       
                  isDisable: this.theroles('CG3') && item.status == 'Submitted' ? false : this.theroles('CG2') && this.disableCG2 == true ? false : this.theroles('CG1') && this.disableCG3 == true ? false : true,
                      
                      vtext: false,
                      id: item.ntidUserId,
                      SrNo: this.pageRowCount*(page)-(this.pageRowCount - 1)+index,
                      userId: item.ntidUserId,
                      employeeCode: item.employeeId,
                      name: item.employeeName,
                      majorLineCode: "",//item.selectedLOB == 'Health' || item.selectedLOB == 'PA' ? '02' : item.selectedLOB == 'AIGC' ? '22' : item.selectedLOB == 'Marine' ? '41': '',
                      majorLineDescription: item.selectedLOB,
                      existingLevel: item.faLevel,
                      existingReserving: item.existingRecomendedReserving,
                      existingPayment: item.existingRecomendedPayments,
                      lastUpdatedDateofexistingFAlevel: item.approveByCCODate ? item.approveByCCODate : item.createdAt,//item.updatedAt == undefined ? nullDate: toDateData,
                      recommendFAlevel: item.recomendedFALevel,
                      recommendedReserving: item.recomendedReserving,
                      recomendedPayments: item.recomendedPayments,
                      remarks: item.remark,
                      disableRemark:this.theroles('CG4')? true:false,
                      status: item.status == 'Approved' || item.status == 'Approve' || item.status == 'Yes' ? 'Approved': item.status == 'Submitted' ? 'Submitted' : item.status == 'Recommended' ? 'Recommended': item.status == 'Rejected' || item.status == 'Reject' || item.status == 'No' ? 'Rejected' : item.status == 'Closed' ? 'Closed': '',
                  //    approve: item.status == 'Approved' || item.status == 'Approve' ? 'Approve': item.status == 'Pending' ? '': item.status == 'Reject' || item.status == 'Rejected'? 'Reject': '',
                      approve: this.theroles('CG1') ? '': item.status == 'Approved' || item.status == 'Approve' || item.status == 'Yes' ? 'Approve': item.status == 'Pending' ? '': item.status == 'Rejected' || item.status == 'Reject' || item.status == 'No' ? 'Reject': '',
                      reasonForRejection: item.status == 'Approved' || item.status == 'Approve' || item.status == 'Yes' ? '' : item.status == 'Reject' || item.status == 'Rejected' || item.status == 'No' ? item.remark : '',
                      approvedDateCCO: item.approveByCCODate == undefined ? noDate : ccoDateData,
                      systemDateUpdate: item.updatedAt == undefined ? nullDate: toDateData,
                  }
              })
              console.log("this.statusData => ",this.statusData)
          }else{
              console.log(err);
              console.log("err.response",err.response)
              console.log("err.data",err.data)
              this.showToast(err && err.data && err.data.msg ? err.data.msg : "Something went wrong..")
          }
      })  
    },
    fetchroles(){
             let fetchAssignRole = []
           fetchAssignRole  =   this.user_data().auth.signInUserSession.idToken.payload.CM.roleDescription;//this.user_data().auth.signInUserSession.idToken.payload.allRoles;
                       return fetchAssignRole;
                    //    = fetchAssignRole.split(',').map(role=>{
                    //             return role.trim()
                    //     })

        },    

        theroles(indata){
            let val = false;
            this.availableroles.map(e=>{
                if(e == indata){
                    // console.log(e, "< matched ? >",indata)
                    val = true
                }
            })
            // console.log("what's the val", val)
            return val
        },

        nextPageBtn(page){
            if(this.pageLength === page){
                this.lastPage = true
                console.log("this is last page",page);
            }else{
                this.lastPage = false
                console.log("this is not last page",page);
            }

            if(this.searchEventFnVar == true){
               this.searchEvent(page);
               console.log("from search btn")
            }else if(this.searchIconVar == true){
                this.searchIcon(page);
                console.log("from search icon")
            }else{
               this.getDataForApproval(page);
               console.log("from nothing")
            }
        },

        ZoneName(incomngData){
            console.log(incomngData,"Emitted Data");
            this.zoneSearch=incomngData
            this.searchDsbld=true
        },

        cancelIcon(page){
            if(this.fAApproval===false){
              this.zoneSearch=''
              this.userId=''
              this.lob=''
              this.$refs.zoneSrchFrm.resetValidation()
              this.clrdata=Date.now().toString(36) + Math.random().toString(36).substr(2);
              
            return
          }
          if(this.fAApproval == true){
            this.getDataForApproval(page);
            this.paginCond = true;
            this.zoneSearch = '';
            this.userId='';
            this.lob='';
            this.status='';
            this.searchEventFnVar = false;
            this.searchIconVar = false;
            this.$refs.zoneSrchFrm.resetValidation();
          }
        },

        changeText(data){
            // var tableData = this.statusData;
            for(var i=0; i<this.statusData.length; i++){
                if(this.statusData[i].id === data.ntidUserId){
                    this.statusData[i].vtext = true;
                }
            }

            // if(this.$store.getters.fetchAssignRole[0].roleName == 'CG1'){
            //     for(var i=0; i<this.statusData.length; i++){
            //     if(this.statusData[i].id === data.id){
            //         this.statusData[i].vtext = true;
            //         this.statusData[i].approve = '';
            //     }
            // }
            // }
            // console.log(this.statusData);
            console.log('Data is', data);
            if(data.approve == 'Approve'){
                data.reasonForRejection = '';
            }

            let indexOfChangedRecord  = this.financialAuthApprovaltems.findIndex((elem)=>{
              return elem.ntidUserId  ==  data.ntidUserId
            })
            if(data.approvalStatus == "Approve"){
              this.financialAuthApprovaltems[indexOfChangedRecord].reasonForRejection = ""
            }
            
        },

        textRule(v, typeis){
            console.log(typeis);
            console.log(v);
            if(typeis == 'Reject'){
                return v=>v && v.length>0 || "This Field is Required"
            }else{
                console.log(v);
                return !!v
            }
        },

        tabseleted(text){
            console.log(text,"Selected tab")
            this.selectedComponent = text;
            if(text == 'FinancialAuthorityApprovalTbl'){
                this.fAApproval = true;
                console.log(this.fAApproval)
            }else{
                this.fAApproval = false;
            }
             
      }, 

      enableTabs(){
        console.log("%c this is the emit event","color:blue");
          this.shwtabs = false
      },

      disableTabs(){
          console.log("%c this is the emit event","color:blue");
          this.shwtabs = true
      },
      togglefrm(){
           console.log("%c this is the emit event","color:blue");
          this.shwFAForm =!this.shwFAForm 
      },

      searchEvent(){
        //   if(this.zoneData == '' && this.lob == '' && this.statusData == ''){
        //       this.showToast("Please Enter Search Criteria",this.TOST().WARNING);
        //       return
        //   }

          this.searchEventFnVar = true;
          console.log("Search Button Clicked");
          console.log(this.lob);
        //   console.log(this.statusData);

          if(this.fAApproval===false){
            this.sData={
                zone:this.zoneSearch,
                userId:this.userId,
                lobItems:this.lob,
            }
            return
          }

          console.log(this.searchTable);
          var tempStatus = this.status == undefined ? '': this.status;
          var zoneData = this.zoneSearch.toUpperCase() == undefined ? '': this.zoneSearch.toUpperCase();
          var userID = this.userId == undefined ? '': this.userId;
          var dd = this.userId.split(',').map(e=> '&ntId='+e);

          let api_Is=''
            if(userID.length!=0){
                // let sendUserId= JSON.stringify(dd)
                let sendUserId=dd.join().replaceAll(",", "")
                console.log(sendUserId,"sendUserId stingify")
                api_Is = 'approvalProcess/get-users-for-approval?lineOfBusiness='+this.lob+'&status='+tempStatus+'&zone='+zoneData+sendUserId+'&perPage='+this.pageRowCount+'&pageNo='+this.page
            }else{
                api_Is = 'approvalProcess/get-users-for-approval?lineOfBusiness='+this.lob+'&status='+tempStatus+'&zone='+zoneData+'&perPage='+this.pageRowCount+'&pageNo='+this.page
            }

            // console.log(this.zoneSearch);
               
            if(this.zoneSearch == '' && this.lob == '' && this.userId == '' && this.status == ''){
              this.showToast("Please Enter Search Criteria",this.TOST().WARNING);
              console.log("null data");
            //   this.searchEventFnVar == false
            }else{
                this.GET(6,api_Is, (res, err) => {
                    if(!err){
                        console.log(res.data.data.data);
                        this.showToast(res.data.msg,this.TOST().SUCCESS);
                        this.pageLength = res.data.data.total_pages;
                        this.totalLen = res.data.data.total
                        var temp =  this.page*this.pageRowCount;
                        this.fval = temp-(this.pageRowCount - 1);
                        if(this.lastPage){
                                this.sval = this.totalLen
                                console.log("value of sval is fo",this.sval);
                            }else{
                                if(!this.totalLen < this.pageRowCount){
                                    this.sval = this.totalLen
                                    console.log("this are the pageLength for searchTable",this.totalLen);
                                }else{
                                    this.sval = temp;
                                    console.log("value of sval is",this.sval);
                                }
                            }
                        this.financialAuthApprovaltems = res.data.data.data;
                        this.statusData = this.financialAuthApprovaltems.map((item,index) => {
                                item.status = item.faLevelApprovalStatus
                            if(item.status == 'Recommended' || item.status == 'Approved' || item.status == 'Approve' || item.status == 'Yes'){
                                this.disableCG2 = true;
                            }else{
                                this.disableCG2 = false;
                            }

                            if(item.status == 'Approved' || item.status == 'Approve' || item.status == 'Yes'){
                                this.disableCG3 = true;
                            }else{
                                this.disableCG3 = false;
                            }

                            let noDate = '';
                            let ccoDateData = '';
                            if(item.approveByCCODate == undefined){
                                noDate = '';
                            }else{
                                dateIs = moment(item.approveByCCODate).format("DD-MM-YYYY hh:mm:ss A");//._i.split("T")[0] +' ';
                                console.log(dateIs);
                                ccoDateData = dateIs//moment(dateIs).format("DD-MM-YYYY");
                            }

                            let nullDate = '';
                            let toDateData = '';
                            if(item.updatedAt == undefined){
                                nullDate = '';
                            }else{
                                dateIs = moment(item.updatedAt).format("DD-MM-YYYY hh:mm:ss A");//._i.split("T")[0] +' ';
                                console.log(dateIs);
                                toDateData = dateIs//moment(dateIs).format("DD-MM-YYYY");
                            }
                            return {
                                //    isDisable: this.$store.getters.fetchAssignRole[0].roleName == 'CG3' && item.status == 'Submitted' ? false : this.$store.getters.fetchAssignRole[0].roleName == 'CG2' && this.disableCG2 == true ? false : this.$store.getters.fetchAssignRole[0].roleName == 'CG1' && this.disableCG3 == true ? false : true,
                                
                                isDisable: this.theroles('CG3') && item.status == 'Submitted' ? false : this.theroles('CG2') && this.disableCG2 == true ? false : this.theroles('CG1') && this.disableCG3 == true ? false : true,
                                
                                //    this.theroles(
                                vtext: false,
                                id: item._id,
                                SrNo: this.pageRowCount*(this.page)-(this.pageRowCount -1 )+index,
                                userId: item.ntidUserId,
                                employeeCode: item.employeeId,
                                name: item.employeeName,
                                majorLineCode: "",//item.selectedLOB == 'Health' || item.selectedLOB == 'PA' ? '02' : item.selectedLOB == 'AIGC' ? '22' : item.selectedLOB == 'Marine' ? '41': '',
                                majorLineDescription: item.selectedLOB,
                                existingLevel: item.faLevel,
                                existingReserving: item.existingRecomendedReserving,
                                existingPayment: item.existingRecomendedPayments,
                                lastUpdatedDateofexistingFAlevel: item.approveByCCODate ? item.approveByCCODate : item.createdAt,//item.updatedAt == undefined ? nullDate: toDateData,
                                recommendFAlevel: item.recomendedFALevel,
                                recommendedReserving: item.recomendedReserving,
                                recomendedPayments: item.recomendedPayments,
                                status: item.status == 'Approved' || item.status == 'Approve' || item.status == 'Yes' ? 'Approved': item.status == 'Submitted' ? 'Submitted' : item.status == 'Recommended' ? 'Recommended': item.status == 'Rejected' || item.status == 'Reject' || item.status == 'No' ? 'Rejected' : item.status == 'Closed' ? 'Closed': '',
                                //    faLevelApprovalStatus: item.status == 'Approved' || item.status == 'Approve' || item.status == 'Yes' ? 'Approved': item.status == 'Submitted' ? 'Submitted' : item.status == 'Recommended' ? 'Recommended': item.status == 'Rejected' || item.status == 'Reject' || item.status == 'No' ? 'Rejected' : item.status == 'Closed' ? 'Closed': '',
                                approve: this.theroles('CG1') ? '': item.status == 'Approved' || item.status == 'Approve' || item.status == 'Yes' ? 'Approve': item.status == 'Pending' ? '': item.status == 'Rejected' || item.status == 'Reject' || item.status == 'No' ? 'Reject': '',
                                reasonForRejection: item.status == 'Approved' || item.status == 'Approve' || item.status == 'Yes' ? '' : item.status == 'Reject' || item.status == 'Rejected' || item.status == 'No' ? item.remark : '',
                                approvedDateCCO: item.approveByCCODate == undefined ? noDate : ccoDateData,
                                systemDateUpdate: item.updatedAt == undefined ? nullDate: toDateData,
                            }
                        })
                        console.log(this.statusData);
                        //    this.optionsTableDisplay = true;
                        //    this.searchTable = true;
                    }else{
                        console.log(err);
                        this.showToast("No Data Found",this.TOST().ERROR);
                        this.paginCond = false;
                        this.statusData = [];
                    }
                }) 
            }
            
      },

      searchIcon(){
          this.searchIconVar = true;
        //   if(this.zoneSearch===''||this.zoneSearch===undefined||this.zoneSearch===null){
        //       this.$refs.zoneSrchFrm.validate();
        //       this.showToast("Please Enter Zone name", this.TOST().WARNING); 
        //       return
        //   }

        console.log("Search Button Clicked");

          if(this.fAApproval===false){
            this.sData={
                zone:this.zoneSearch,
                userId:this.userId,
                lobItems:this.lob,
            }
            return
          }

          var statusData = this.status == undefined ? '': this.status;
          var zoneData = this.zoneSearch.toUpperCase() == undefined ? '': this.zoneSearch.toUpperCase();
          var userID = this.userId == undefined ? '': this.userId;
          var dd = this.userId.split(',').map(e=> '&ntId='+e);

          let api_Is=''
            if(userID.length!=0){
                // let sendUserId= JSON.stringify(dd)
                let sendUserId=dd.join().replaceAll(",", "")
                console.log(sendUserId,"sendUserId stingify")
                api_Is = 'approvalProcess/get-users-for-approval?lineOfBusiness='+this.lob+'&status='+statusData+'&zone='+zoneData+'&ntId='+sendUserId+'&perPage='+this.pageRowCount+'&pageNo='+this.page
            }else{
                api_Is = 'approvalProcess/get-users-for-approval?lineOfBusiness='+this.lob+'&status='+statusData+'&zone='+zoneData+'&perPage='+this.pageRowCount+'&pageNo='+this.page
            }

            if(this.zoneSearch == '' && this.lob == '' && this.userId == '' && this.status == ''){
              this.showToast("Please Enter Search Criteria",this.TOST().WARNING);
              console.log("null data");
            //   this.searchEventFnVar == false
            }else{
                this.GET(6,api_Is, (res, err) => {
                        if(!err){
                            console.log(res.data.data.data);
                            this.showToast(res.data.msg,this.TOST().SUCCESS);
                            this.pageLength = res.data.data.total_pages;
                            this.totalLen = res.data.data.total
                            var temp =  this.page*this.pageRowCount;
                            this.fval = temp-(this.pageRowCount - 1);
                            if(this.lastPage){
                                    this.sval = this.totalLen
                                    console.log("value of sval is fo",this.sval);
                                }else{
                                    if(!this.totalLen < this.pageRowCount){
                                        this.sval = this.totalLen
                                        console.log("this are the pageLength for searchTable",this.totalLen);
                                    }else{
                                        this.sval = temp;
                                        console.log("value of sval is",this.sval);
                                    }
                                }
                            this.financialAuthApprovaltems = res.data.data.data;
                            this.financialAuthApprovaltems.map((elem)=>{
                              elem.remark = concatAllRemarks(elem);
                              return elem.showData  = false
                            })
                            this.statusData = this.financialAuthApprovaltems.map((item,index) => {
                                    item.status = item.faLevelApprovalStatus
                                if(item.status == 'Recommended' || item.status == 'Approved' || item.status == 'Approve' || item.status == 'Yes'){
                                    this.disableCG2 = true;
                                }else{
                                    this.disableCG2 = false;
                                }

                                if(item.status == 'Approved' || item.status == 'Approve' || item.status == 'Yes'){
                                    this.disableCG3 = true;
                                }else{
                                    this.disableCG3 = false;
                                }

                                let noDate = '';
                                let ccoDateData = '';
                                if(item.approveByCCODate == undefined){
                                    noDate = '';
                                }else{
                                    dateIs = moment(item.approveByCCODate).format("DD-MM-YYYY hh:mm:ss A");//._i.split("T")[0] +' ';
                                    console.log(dateIs);
                                    ccoDateData = dateIs;//moment(dateIs).format("DD-MM-YYYY");
                                }

                                let nullDate = '';
                                let toDateData = '';
                                if(item.updatedAt == undefined){
                                    nullDate = '';
                                }else{
                                    dateIs = moment(item.updatedAt).format("DD-MM-YYYY hh:mm:ss A");//._i.split("T")[0] +' ';
                                    console.log(dateIs);
                                    toDateData = dateIs//moment(dateIs).format("DD-MM-YYYY");
                                }
                                return {
                                    isDisable:this.theroles('CG3') && item.status == 'Submitted' ? false : this.theroles('CG2') && this.disableCG2 == true ? false : this.theroles('CG1') && this.disableCG3 == true ? false : true,
                                    SrNo: this.pageRowCount*(this.page)-(this.pageRowCount - 1)+index,
                                    vtext: false,
                                    id: item._id,
                                    userId: item.ntidUserId,
                                    employeeCode: item.employeeId,
                                    name: item.employeeName,
                                    majorLineCode: "",// item.selectedLOB == 'Health' || item.selectedLOB == 'PA' ? '02' : item.selectedLOB == 'AIGC' ? '22' : item.selectedLOB == 'Marine' ? '41': '',
                                    majorLineDescription: item.selectedLOB,
                                    existingLevel: item.faLevel,
                                    existingReserving: item.existingRecomendedReserving,
                                    existingPayment: item.existingRecomendedPayments,
                                    lastUpdatedDateofexistingFAlevel: item.approveByCCODate ? item.approveByCCODate : item.createdAt,//item.updatedAt == undefined ? nullDate: toDateData,
                                    recommendFAlevel: item.recomendedFALevel,
                                    recommendedReserving: item.recomendedReserving,
                                    recomendedPayments: item.recomendedPayments,
                                    status: item.status == 'Approve' || item.status == 'Approved' || item.status == 'Yes' ? 'Approved': item.status == 'Submitted' ? 'Submitted' : item.status == 'Recommended' ? 'Recommended': item.status == 'Rejected' || item.status == 'Reject' || item.status == 'No' ? 'Rejected' : item.status == 'Closed' ? 'Closed': '',
                                    approve: this.theroles('CG1') ? '': item.status == 'Approved' || item.status == 'Approve' || item.status == 'Yes' ? 'Approve': item.status == 'Pending' ? '': item.status == 'Rejected' || item.status == 'Reject' || item.status == 'No' ? 'Reject': '',
                                    reasonForRejection: item.status == 'Approved' || item.status == 'Approve' || item.status == 'Yes' ? '' : item.status == 'Reject' || item.status == 'Rejected' || item.status == 'No' ? item.remark : '',
                                    approvedDateCCO: item.approveByCCODate == undefined ? noDate : ccoDateData,
                                    systemDateUpdate: item.updatedAt == undefined ? nullDate: toDateData,
                                }
                            })
                            console.log(this.statusData);
                            //    this.searchTable = true;
                        }else{
                            console.log(err);
                            this.showToast("No Data Found",this.TOST().ERROR);
                            this.paginCond = false;
                            this.statusData = [];
                        }
                    }) 
            }
      },
      concatAllRejection(elem){
        console.log(elem)
        let concatedrejectedReason  = ""
        for(let i in elem.users){
          if(concatedrejectedReason && concatedrejectedReason.length && elem.users[i].rejectedReason){
            concatedrejectedReason  = concatedrejectedReason + ", " +elem.users[i].rejectedReason
          }else if(elem.users[i].rejectedReason){
            concatedrejectedReason  = elem.users[i].rejectedReason
          }
        }
        return  concatedrejectedReason ? concatedrejectedReason : "";
      },

      concatAllRemarks(elem){
        console.log(elem)
        let concatedRemark  = ""
        for(let i in elem.users){
          if(concatedRemark && concatedRemark.length && elem.users[i].remark){
            concatedRemark  = concatedRemark + ", " +elem.users[i].remark
          }else if(elem.users[i].remark){
            concatedRemark  = elem.users[i].remark
          }
        }
        return  concatedRemark ? concatedRemark : "";
      },

      saveApprovalData(){
           var dataVal = false;
            console.log("this.financialAuthApprovaltems => ",this.financialAuthApprovaltems," statusData => ",this.statusData);
            for(var i=0; i<this.financialAuthApprovaltems.length; i++){
                // if(this.statusData[i].vtext == true){
                    console.log("Working fine");
                    if(this.financialAuthApprovaltems[i].approvalStatus == "Reject"){
                        console.log(this.financialAuthApprovaltems[i].approvalStatus);
                        if(this.financialAuthApprovaltems[i].reasonForRejection == null || this.financialAuthApprovaltems[i].reasonForRejection == ''){
                            console.log("Working !!!!");
                            dataVal = true;
                        }
                    // }
                }
            }
            console.log(dataVal);
            if(dataVal == true){
                this.showToast("Please provide reason for rejection in all rejected records.",this.TOST().WARNING);
                console.log(this.$refs.tableForm.validate());
                this.$refs.tableForm.validate();
                return
            }
            var tempArr = [];
            for(var i=0; i<this.statusData.length; i++){
                if(this.statusData[i].vtext == true){
                    let faObj = this.financialAuthApprovaltems.find((elem)=>{
                      return this.statusData[i].id ==  elem.ntidUserId
                    })
                    faObj.users.map((userData)=>{
                      tempArr.push({
                        id: userData._id,
                        status: faObj.approvalStatus,
                        reason: faObj.approvalStatus == "Reject" ? faObj.reasonForRejection : ""
                    });  
                      return userData
                    })
                    // tempArr.push({
                    //     id: this.statusData[i].id,
                    //     status: this.statusData[i].approve,
                    //     reason: this.statusData[i].reasonForRejection
                    // });  
                }
            }
            console.log(tempArr);
            if(tempArr.length > 0){
            this.PUT(6, 'approvalProcess/user-fa-level-mapping-approval', tempArr, (res, err) => {
                if(!err){
                    console.log(res);
                    for(var i=0; i<this.statusData.length; i++){
                        this.statusData[i].vtext = false;
                    }
                    this.showToast(res.data.msg, this.TOST().SUCCESS);//Surendra(27-05-2022):MC-5372:- Msg changed from "Success!! Financial authority moved for approval successfully"
                    
                    for(var i=0; i<this.statusData.length; i++){
                        if(this.statusData[i].approve == 'Rejected' || this.statusData[i].approve == 'Reject'){
                            this.statusData[i].status = '';
                        }else if(this.statusData[i].approve == 'Approve'){
                            this.statusData[i].status = 'Approved';
                        }
                        
                    } 
                    this.fApprovePage = 1
                    this.getDataForApproval(1);
                }else{
                    console.log(err);
                    this.showToast(res.data.msg, this.TOST().WARNING);
                }
            })
            }else{
                 this.showToast("No users were updated", this.TOST().WARNING);
            }
      },
      formatDateTime(dateTime) {
        return dateTime ? moment(dateTime).format("DD-MM-YYYY hh:mm:ss A") : "";
      },
      getLevelDetails(){
            let api_Is = 'financilauthority/search-financial-autority'
            this.GET(6,api_Is,(res,error)=>{
                try{
                    if(!error){

                        console.log("GET APi response|->get Level Details", res.data.data.data)
                        this.levelData=[];
                        this.levelData=res.data.data.data
                        // let fetchAssignRole = this.$store.getters.fetchAssignRole
                       let fetchAssignRole = this.fetchroles()

                        console.log("fetchAssignRole", fetchAssignRole)

                        let shwData=false
                        for(let i=0;i<fetchAssignRole.length;i++){
                            if(fetchAssignRole[i]=="CG4"){
                                shwData=true
                            }
                        }
                        //ToDo: Uncomment for CG4
                        // if(shwData===true){
                        //     this.getUsersForAuthMapping(this.levelData,'','','')
                        // }
                        this.getUsersForAuthMapping(this.levelData,'','','')
                       
                    }else{
                        console.log("GET APi error response|->get Level Details", error)
                        // this.showToast("No Search Result Found", this.TOST().WARNING); 
                    } 
                }catch(e){
                    console.log("GET APi  errorresponse|-> get Level Details", e)
                }
            })
        },
        getUsersForAuthMapping(leveldata,zoneName,UserId,lob){
            console.log(leveldata,"level data incoming")
            console.log(UserId,"UserId array")
            console.log(zoneName,"zoneName array")
            console.log(lob,"lob array")
            if(UserId==undefined ||UserId==null){
                UserId=''
            }
            if(zoneName==undefined ||zoneName==null){
                zoneName=''
            }
            if(lob==undefined ||lob==null){
                lob=''
            }
            var dd = UserId ? UserId.split(',').map(e=> '&ntId='+e) : []

            console.log("this.fAuthPage => ",this.fAuthPage)
            
            
            

            let api_Is='approvalProcess/get-users-for-mapping?perPage='+this.pageRowCount+'&pageNo='+this.fAuthPage
            if(UserId.length!=0){
                // let sendUserId= JSON.stringify(dd)
                let sendUserId=dd.join().replaceAll(",", "")
                console.log(sendUserId,"sendUserId stingify")
                api_Is = api_Is + '&zone='+zoneName.toUpperCase()+'&lineOfBusiness='+lob+sendUserId
            }else if(this.faAuthSearchData.typeOfEmployee || this.faAuthSearchData.agreementType || this.faAuthSearchData.ntidUserId || this.faAuthSearchData.zoneName || this.faAuthSearchData.employeeId || this.faAuthSearchData.selectedLob || this.faAuthSearchData.status || this.faAuthSearchData.settlementOffice){
              this.faAuthSearchData.ntidUserId ? api_Is  = api_Is  + '&ntidUserId=' + this.faAuthSearchData.ntidUserId : ""
              this.faAuthSearchData.zoneName ? api_Is  = api_Is  + '&zoneName=' + this.faAuthSearchData.zoneName : ""
              this.faAuthSearchData.employeeId ? api_Is  = api_Is  + '&employeeId=' + this.faAuthSearchData.employeeId : ""
              this.faAuthSearchData.selectedLob ? api_Is  = api_Is  + '&selectedLob=' + this.faAuthSearchData.selectedLob : ""
              this.faAuthSearchData.status ? api_Is  = api_Is  + '&status=' + this.faAuthSearchData.status : ""
              this.faAuthSearchData.settlementOffice ? api_Is  = api_Is  + '&settlementOffice=' + this.faAuthSearchData.settlementOffice : ""
              this.faAuthSearchData.typeOfEmployee ? api_Is  = api_Is  + '&typeOfEmployee=' + this.faAuthSearchData.typeOfEmployee : ""
              this.faAuthSearchData.agreementType ? api_Is  = api_Is  + '&agreementType=' + this.faAuthSearchData.agreementType : ""
            }else{
              // zoneName  = "CORPORATE"
              // lob = "Marine" //perPage=10&pageNo=1
              // api_Is = api_Is + '&zone='+zoneName.toUpperCase()+'&lineOfBusiness='+lob
            }
           
            let self = this
            this.GET(6,api_Is,(res,error)=>{
                try{
                    if(!error){
                        console.log("GET APi response|->approvalProcess/get-users-for-mapping", res.data.data.data)
                        console.log("GET APi response|->approvalProcess/get-users-for-mapping", res.data.data.zone)
                        let shwData=false
                        // let fetchAssignRole = this.$store.getters.fetchAssignRole
                           let fetchAssignRole = this.fetchroles()
                        console.log(fetchAssignRole,"fetchAssignRole======")
                        for(let i=0;i<fetchAssignRole.length;i++){
                            if(fetchAssignRole[i]=="CG4"){
                                shwData=true
                            }
                        }

                        if(shwData===true){
                            this.$emit('ZoneName',res.data.data.zone)
                        }
                        let tempData=[]
                        tempData=res.data.data.data
                        
                        // this.allUsersData 
                        tempData = tempData.map(function(currentValue, Index) {
                            console.log(currentValue,'currentValue')
                            currentValue.levelItems=[]
                            for(let i=0;i<leveldata.length;i++){
                                if (leveldata[i].lob===currentValue.selectedLOB && leveldata[i].status==="Approved")
                                    currentValue.levelItems.push(leveldata[i])
                            }
                            currentValue.levelItems = self.sortedFAcall(currentValue.levelItems)
                            currentValue.SrNo=(res.data.data.current_page_no == 1 ? 1 : ((res.data.data.current_page_no - 1) * self.pageRowCount))+Index+(res.data.data.current_page_no == 1 ? 0 : 1)
                            currentValue.status = false
                            currentValue.recommendFAlevel={}
                            currentValue.recommendedReserving=''
                            currentValue.recomendedPayments=''
                            currentValue.remarks=''
                            currentValue.majorLineCode=''
                            // if(currentValue.selectedLOB==='Health'||currentValue.selectedLOB==='PA'){
                            //     currentValue.majorLineCode='02'
                            // }else if(currentValue.selectedLOB==='Marine'){
                            //     currentValue.majorLineCode='41'
                            // }else if(currentValue.selectedLOB==='AIGC'){
                            //     currentValue.majorLineCode='22'
                            // }
                            return currentValue
                        })
                        this.allUsersData = tempData
                        console.log("level Data temp", tempData, this.allUsersData, "hai naaa");
                        this.fAuthMapStartIndex = res.data.data.current_page_no == 1 ? 1 : ((res.data.data.current_page_no - 1) * this.pageRowCount) + 1;
                        this.fAuthMapTotal  = res.data.data.total
                        this.fAuthMapStartIndex = res.data.data.total == 0 ? 0 : this.fAuthMapStartIndex
                        this.openFAMapDialogueBox = false
                        // tempData.map((e,ind)=>{
                        console.log(tempData,"this.allUsersData after new key addition", this.allUsersData)

                        //     e.

                        // })


                    }else{
                        console.log("GET APi error response|->approvalProcess/get-users-for-mapping", error.response.data.data.zone)
                        let shwData=false
                        // let fetchAssignRole = this.$store.getters.fetchAssignRole

                        let fetchAssignRole = this.fetchroles()

                        console.log(fetchAssignRole,"fetchAssignRole======")
                        for(let i=0;i<fetchAssignRole.length;i++){
                            if(fetchAssignRole[i]=="CG4"){
                                shwData=true
                            }
                        }

                        if(shwData===true){
                            this.$emit('ZoneName',error.response.data.data.zone)
                        }
                        
                        this.allUsersData=[]
                        this.fAuthMapStartIndex = 0
                        this.fAuthMapTotal  = 0
                        this.openFAMapDialogueBox = false
                        this.showToast("No Search Result Found", this.TOST().WARNING); 
                    } 
                }catch(e){
                    console.log("GET APi  errorresponse|-> approvalProcess/get-users-for-mapping", e)
                    this.showToast("Something went wrong, please try again.", this.TOST().WARNING); 
                }
            })
        },
        sortedFAcall(indata){
            let filterlvls = []
            let lvlsare = {}
              indata.forEach(e=>{
                  lvlsare = {}
                this.sortedFA.forEach(sf=>{
                    if(e.level == sf.lvl){
                        // console.log(e,"matched lvl is",sf)
                        lvlsare = {...e, num:sf.num}
                        filterlvls.push(lvlsare)
                    }
                })
             })
            console.log("filterlvls",filterlvls)
            // this.sortedFA
            filterlvls = filterlvls.sort(function (a, b) {  return a.num - b.num});
            // console.log("final  this.sortedFA",filterlvls)
            return filterlvls
        },
        slctdFAlevel(data,rowdata){
          console.log("Selected level", data)
            console.log("rowdata", rowdata)
            console.log("this.allUsersData after new key addition", this.allUsersData)
             let self=this
            let alertfirst = new Promise((resolve)=>{
               
                let EXlevelwith_num = this.sortedFAcall([{level:rowdata.existingFALevel}])
                let currentlevelwith_num =  this.sortedFAcall([{level:data.level}])

                console.log(currentlevelwith_num,"selected level | EXlevelwith_num",EXlevelwith_num)
                if(currentlevelwith_num[0].num < EXlevelwith_num[0].num){
                    // alert("selected level is lower than existing level")
                      self.showToast("Recommended authority is lower than the existing authority",self.TOST().WARNING)
                }

                resolve('done')

            })

            alertfirst.then(afteris=>{
                console.log("afteris then", alertfirst)

                
                this.set_or_clear_recommendedLVL(data, rowdata)
            
                // console.log("this.allUsersData after for loop", this.allUsersData)

            }).catch(e=>{
                console.log("alert first catch is ", e);
                   this.set_or_clear_recommendedLVL(data, rowdata)

            })

           
        },

        set_or_clear_recommendedLVL(data,rowdata){
             let self = this
             let sameLevelSltd=false
               
            for(let i=0;i<self.allUsersData.length;i++){
                if(rowdata._id===self.allUsersData[i]._id){
                    console.log(rowdata.recommendFAlevel,"rowdata.recommendFAlevel inside loop")
                    if(!rowdata.recommendFAlevel){
                        self.allUsersData[i].recommendFAlevel={}
                        self.allUsersData[i].recommendedReserving=''
                        self.allUsersData[i].recomendedPayments=''
                        // self.allUsersData[i].lastUpdatedDateofexistingFAlevel=data.updatedAt
                        self.allUsersData[i].status=false
                        // self.$forceUpdate()
                        // sameLevelSltd=true
                    }else if(rowdata.recommendFAlevel.level===self.allUsersData[i].existingFALevel){
                        self.allUsersData[i].recommendFAlevel={}
                        self.allUsersData[i].recommendedReserving=''
                        self.allUsersData[i].recomendedPayments=''
                        // self.allUsersData[i].lastUpdatedDateofexistingFAlevel=data.updatedAt
                        self.allUsersData[i].status=false
                        // self.$forceUpdate()
                        sameLevelSltd=true
                    }
                }
                
            }

            if(sameLevelSltd===true){
                self.showToast("Recommended Financial authority is same as the existing authority",self.TOST().WARNING)
                return
            }

            if(data){
                for(let i=0;i<self.allUsersData.length;i++){
                    console.log("seconf for loop")
                    if(rowdata._id===self.allUsersData[i]._id){
                        self.allUsersData[i].recommendedReserving=data.reservingAuthorityLimit
                        self.allUsersData[i].recomendedPayments=data.paymentAuthorityLimit
                        // self.allUsersData[i].lastUpdatedDateofexistingFAlevel=data.updatedAt
                        self.allUsersData[i].status=true
                    }
                }
            }

        },

        saveFALevel(){
            
            // if (!this.$refs.tableForm.validate()){
            //     this.$refs.tableForm.validate()
            //     this.showToast("Please fill required fields",this.TOST().WARNING);
            //     return
            // } 
            let dtaValid = false;
            for(let i=0;i<this.allUsersData.length;i++){
                if(Object.keys(this.allUsersData[i].recommendFAlevel).length != 0){
                    // this.allUsersData[i].recommendFAlevel!='' 
                    // console.log("not empty")
                    if(this.allUsersData[i].remarks===''||this.allUsersData[i].remarks===null||this.allUsersData[i].remarks===undefined){
                        dtaValid=true
                    }
                }
            }
            if(dtaValid===true){
                this.$refs.tableForm.validate()
                this.showToast("Please fill required fields",this.TOST().WARNING);
                return
            }
            console.log("this.allUsersData after new key addition", this.allUsersData)
            var tempArr = [];
            for(var i=0; i<this.allUsersData.length; i++){
                if(this.allUsersData[i].status == true){
                    tempArr.push({
                        id: this.allUsersData[i]._id,
                        recomendedFALevelID:this.allUsersData[i].recommendFAlevel._id,
                        recomendedFALevel: this.allUsersData[i].recommendFAlevel.level,
                        recomendedReserving: this.allUsersData[i].recommendedReserving,
                        recomendedPayments:this.allUsersData[i].recomendedPayments,
                        remark:this.allUsersData[i].remarks
                    });  
                }
            }
            console.log("tempArr for POst API", tempArr)

            if(tempArr.length ===0){
                this.showToast("Please update atleast 1 item", this.TOST().WARNING);
                return
            }
            let apiURL='approvalProcess/update-user-financial-authority'
             this.PUT(6,apiURL,tempArr,(res,error)=>{
                if(!error){
                    console.log("Put APi response|-> empanelment/save-staff-details ", res)
                    this.showToast(res.data.msg,this.TOST().SUCCESS)
                    this.getUsersForAuthMapping(this.levelData,this.searchData.zone,this.searchData.userId,this.searchData.lobItems)
                }else{
                    console.log("Put APi error response|-> empanelment/save-staff-details ", error)
                    this.showToast(error.response.data.msg ,this.TOST().ERROR);
                }
            })
            
        },
        searchFaAuthData(){
          this.fAuthPage  = 1;
          this.getLevelDetails()
        },
        clearAuthMapSearch(){
          this.faAuthSearchData=    {
            ntidUserId  : "",
            zoneName  : "",
            employeeId  : "",
            status  : "",
            selectedLob : "",
            settlementOffice  : ""
          };
          this.fAuthPage  = 1;
          this.getLevelDetails()
        },
        clearApproveSearch(){
          this.searchData=    {
            ntidUserId  : "",
            zoneName  : "",
            employeeId  : "",
            status  : "",
            selectedLob : "",
            settlementOffice  : ""
          };
          this.fApprovePage  = 1;
          this.getDataForApproval(1)
        },
        getZoneList() {
          this.showProgress()
          this.GET(6, "settlementoffice/get-zone-list", (res, error) => {
            if(error){
              this.hideProgress()
              console.log(error.response)
            }else{
              this.hideProgress()
              this.zoneList = res.data.data
            }          
          })
      },
    getSettlementOfficeList(lob, zone) {
      let req = ""
      if(lob){
        req = "?lob="+lob
      }
      if(zone){
       lob ? req = req + "&zone="+zone : req = "?zone="+zone
      }
      this.GET(6,'settlementoffice/search-settlement-office'+req,(res,error)=>{
          if (!error && res.data && res.data.data && res.data.data.data) {
            let list = res.data.data.data
            // this.SettlingOfficeList
            console.log(list)
            this.faAuthSearchData.settlementOffice = ""
            this.searchData.settlementOffice = ""
            this.settlementOfficeListForApproval  = []
            this.settlementOfficeListForAuthMapping = []
            list.map((elem)=>{
              elem.settlementOfficeCodeName = elem.settlementOfficeCode + " - " + elem.settlementOfficeName
            })
             console.log(list," list ")
            this.financial_autority_mapping ? this.settlementOfficeListForAuthMapping = list : this.settlementOfficeListForApproval =  list
            
            
          }else{
            this.hideProgress()
            console.log(error.response)
            this.showAlert(error.response && error.response.data && error.response.data.msg ? error.response.data.msg : "Error")
          }
        })
    },
    // formatDate(date){
    //   return date ? moment(date).format("DD-MM-YYYY hh:mm") : "";
    // },
    formatDate(data){
        if (data == "" || data == "undefined" || data == null || data == '' || data == 'Invalid Date') {
            return ""
        } else {
            // var n = new Date(data);
            // return n.toLocaleString("en-IN")
            return moment(data).format('DD-MM-YYYY hh:mm:ss A')//return moment(data,'YYYY-MM-DD').format('DD-MM-YYYY hh:mmm:ss A')
        }
    },
    showMoreLess(item,index){
      console.log(index," - showMoreLess => ",item)
     
      this.financialAuthApprovaltems[item.outerIndex].users[index].isViewMore = !item.isViewMore;
      this.viewMoreRemarkHideShow = false
      this.viewMoreRemarkHideShow = true
    }
  },
  computed:{
    whatemployeewehave() {
   
      let a = ["Full Time", "Contractual Employee", "Outsource"];
      let b = [
        "External",
        "Service ID",
        "Investigator",
        "Surveyor",
        "Other",
        "Vendor",
        "Repairer",
        "Outsource",
      ];
      console.log("this.faAuthSearchData.agreementType - ",this.faAuthSearchData.agreementType)
      if (this.faAuthSearchData.agreementType == "Employee") {
        return a;
      } else {
        return b;
      }
    },
    typeOfEmployeeWeHaveOther() {
   
      let a = ["Full Time", "Contractual Employee", "Outsource"];
      let b = [
        "External",
        "Service ID",
        "Investigator",
        "Surveyor",
        "Other",
        "Vendor",
        "Repairer",
        "Outsource",
      ];
      console.log("this.searchData.agreementType - ",this.searchData.agreementType)
      if (this.searchData.agreementType == "Employee") {
        return a;
      } else {
        return b;
      }
    },
    statusItems(){
      return this.availableroles.includes("CG2") ? ['Recommended'] : this.availableroles.includes("CG1") ? ['Approved'] : ['Submitted','Approved','Rejected','Recommended','Closed']
    },
    getFAuthNoOfPages() {
        if (!this.fAuthMapTotal) {
            return 0;
        } else {
            return Math.ceil(this.fAuthMapTotal / this.pageRowCount);
        }
    },
    getFApproveNoOfPages() {
        if (!this.fAuthApproveTotal) {
            return 0;
        } else {
            return Math.ceil(this.fAuthApproveTotal / this.pageRowCount);
        }
    },
    fAuthMapEndIndex() {
      if (!this.allUsersData.length) {
          return 0
      } else {
          return ((this.fAuthMapStartIndex + (this.pageRowCount)) <= this.fAuthMapTotal) ? this.fAuthMapStartIndex + (this.pageRowCount) - 1 : this.fAuthMapTotal;

      }
    },
    fAuthApproveEndIndex() {
      if (!this.financialAuthApprovaltems.length) {
          return 0
      } else {
          return ((this.fAuthApproveStartIndex + (this.pageRowCount)) <= this.fAuthApproveTotal) ? this.fAuthApproveStartIndex + (this.pageRowCount) - 1 : this.fAuthApproveTotal;

      }
    },
    approveRejectOptions(){
      return this.availableroles.length > 0 && this.availableroles.includes("CG1") ? ["Approve"] : ["Approve", "Reject"]
    },
    isCg4User(){
      console.log("isCg4User - ",this.availableroles)
      let allLob = this.user_details().lob.split(",")

        let allLobRoles = [];

        for(var i in allLob){
            console.log(allLob[i])
            this.user_details()[allLob[i]].roleDescription.map((elem)=>{
                allLobRoles.push(elem)
            })    
        }
        console.log("allLobRoles => ",allLobRoles)
      return allLobRoles.includes("CG4")// this.availableroles.includes("CG4")
    },
    disableApproveRejectOption(){
      if(this.availableroles.includes('CG1') || this.availableroles.includes('CG2') || this.availableroles.includes('CG3')){
        return false
      }
      return true
    }
  },
  filters:{
        checkdata(data){
            // console.log('Validity Data', data);
            if (data ==='' || data ===undefined || data === null || data=== 0 || data === 'Invalid Date' ) {
                return '-'
            } else {
                return data;
            }
        },
        truncate: function (text, length, suffix) {
            if (text.length > length) {
                return text.substring(0, length) + suffix;
            } else {
                return text;
            }
        },
        formatDate(data){
            if (data == "" || data == "undefined" || data == null || data == '' || data == 'Invalid Date') {
                return ""
            } else {
                // var n = new Date(data);
                // return n.toLocaleString("en-IN")
                return moment(data).format('DD-MM-YYYY hh:mm:ss A')//return moment(data,'YYYY-MM-DD').format('DD-MM-YYYY hh:mmm:ss A')
            }
        },
        formatDate2(data){
            if (data == "" || data == "undefined" || data == null || data == '' || data == 'Invalid Date') {
                return ""
            } else {
                // var n = new Date(data);
                // return n.toLocaleString("en-IN")
                return moment(data).format('DD-MM-YYYY hh:mm:ss A')
            }
        },
        formatDateOnly(data){
          if (data == "" || data == "undefined" || data == null || data == '' || data == 'Invalid Date') {
                return ""
            } else {
                // var n = new Date(data);
                // return n.toLocaleString("en-IN")
                return moment(data).format('DD-MM-YYYY')
            }
        }
    },
  mounted(){
      if(this.availableroles.find(function(elem){return elem == "CG2"})){
          this.status =   'Recommended'
          console.log('from if Recommended>');
      }else if(this.availableroles.find(function(elem){return elem == "CG1"})){
          // this.$refs.faApproval.click()
          console.log('from else>');

          this.tabseleted("FinancialAuthorityApprovalTbl")
      }
  },
  created(){
      // this.financial_autority_mapping = true;
      // this.financial_autority_approval  = true
      

      this.availableroles = this.fetchroles()
      console.log("avr",  this.availableroles)
      this.$store.commit("SAVE_PAGE_HEADER", "Financial Authority Approval");
      console.log(this.$store.getters.fetchAssignRole,"fetchAssignRole");

      for(let i=0;i<this.availableroles.length;i++){
          // if(this.availableroles[i]=="CG4"){
          if(this.availableroles[i].includes("CG4")){
              this.saveButton=true
          }
      }
      if(this.availableroles.find(function(elem){return elem == "CG1"})){
        this.toggleBtn(2)
      }else{
        this.toggleBtn(1)
      }
      // if(this.$store.getters.fetchAssignRole[0].roleName == 'CG2'){
      //     this.disableCG2 = true;
      // }
      // console.log("this.getEditDetails(this.$router.history.current.name) from Mixin===>",this.getEditDetails(this.$router.history.current.name).isEdit)
      // if(this.getEditDetails(this.$router.history.current.name)){
      //     this.disableAll=this.getEditDetails(this.$router.history.current.name).isEdit
          
      // }else{
      //     this.disableAll=false
      // }
      // this.getLevelDetails()
      // this.getDataForApproval(this.page);

      //Surendra(21-Sep-2021):LOB List from BE,code starts here
      this.GET(3,'line-of-business/get-line-of-business-list', (res,error)=>{
          if(!error){
              console.log("response from api is",res.data.data[0].lineOfBusiness);
              this.lobList  = res.data.data[0].lineOfBusiness
              this.lobList.includes("CM") ? this.lobList.splice(this.lobList.indexOf("CM"),1) : ""
          }else{
              console.log("error",error.response.data);
          }
      });
      //Surendra(21-Sep-2021):LOB List from BE,code ends here

      
      
  },
};
</script>
<style>
.phaseTwoVDailogBox tr th {
  /* // background-color: #23b1a9; */
  background-color: #26b1a9 !important;
  color: white !important;
  font-size: 13px !important;
  height: 35px !important;
  border: 0.5px ridge #f7f7f7;
  border-width: 1px;
  border-right: none;
  flex-wrap: wrap;

  /* border-collapse: collapse; */
  /* overflow: none; */
  font-weight: bold;
  text-align: center !important;
  white-space: nowrap;
  margin-top: 10px;
}
.phaseTwoVDailogBox tr td {
  border: 1px solid #f7f7f7;
  /* border-width: 2px; */
  /* border-right: none; */
  border-collapse: collapse;
  height: 50px !important;
  font-size: 13px !important;
  color: #10242b;

  text-align: center !important;
  white-space: nowrap;
  margin-top: 10px;
}

.phaseTwoVDailogBoxStyling {
 background-color: #b5c1c4;
}
.vDataTableSHeaderNewColor tr th {
	background-color: #01bbd9 !important;
}
</style>

<style scoped>

.financial_auth_btn {
  width: 255px;
  height: 45px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #b5c1c4;
  border-radius: 4px 4px 0px 0px;
  opacity: 1;
  text-align: left;
  font-size: 21px Roboto;
  letter-spacing: 0px;
  color: black;
  text-transform: capitalize;
  display: inline-block;
  opacity: 1;
}
.list_heading {
  font-family: Roboto;
  font-weight: bold;
  font-size: 18px;
  color: #10242b;
  display: inline-block;
}
.financial_auth_btn.selected_button .icon-wrap,
.financial_auth_btn:hover .icon-wrap {
  background: transparent !important;
  color: #fff !important;
}
.financial_auth_btn.selected_button,
.financial_auth_btn:hover {
  background: #1ebbd9 !important;
  color: #fff !important;
}
.aigc_chip {
  font-size: 12px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
}
.list_btn {
  width: 120px;
  height: 35px;
  color: #ffffff;
  background-color: #23b1a9 !important;
  padding-right: 4px;
  float: right;
  box-shadow: 0px 3px 4px #23b1a93d;
  border-radius: 4px;
  font-size: 12px;
  font-weight: Roboto;
  text-transform: capitalize;
  opacity: 1;
}
mdi mdi-magnify {
  font-size: 12px;
}
.close_dialouge {
  font-size: 20px;
  color: #fff;
  float: right;
  cursor: pointer;
  position: relative;
  right: -20px;
  font-family: Roboto;
  font-weight: 500;
}

.searchDialougeBoxBackground {
  background-color: #23b1a9;
  box-shadow: 0px 5px 7px #00000029;
  opacity: 1;
  padding: 5px;
  font-size: 14px;
}
.descrptionSpan {
  width: 85%;
  margin-top: 25px;
  font-weight: 500;
  font-size: 20px;
  color: #fff;
  font-family: Roboto;
  letter-spacing: 0.4px;
}
.vcardTextTableBackground {
  background-color: #f0f0f0 !important;
}
.btnstyle {
  height: 32px !important;
  text-transform: capitalize;
  font-size: 11px !important;
  letter-spacing: 0.5px;
  border: 1px solid #4C4C4C;
}
.autocompleteItems {
  background-color: #f0f0f0 !important;
  border: 0.5px solid #c1c8cc;
  opacity: 1;
  /* width: 185px; */
  border-radius: 2px;
  /* margin: 5px; */
  /* top: 215px; */
  /* left: 625px; */
  height: 40px;
  padding: 0px;
  font-family: Roboto;
  font-size: 13px;
}
tr > td {
  font-family: Roboto;
  font-size: 10px;
}
.textfieldItems {
  background-color: #f0f0f0 !important;
  border: 0.5px solid #c1c8cc;
  opacity: 1;
  /* width: 100%; */
  border-radius: 2px;
  margin: 5px;
  /* top: 215px; */
  /* left: 625px; */
  height: 40px;
  padding: 0px;
  font-family: Roboto;
  font-size: 13px;
}
.actionBtn {
  text-align: left;
  font-size: 14px Roboto;
  letter-spacing: 0px;
  color: #1A0DAB;
  background: none;
  opacity: 1;
  text-transform: capitalize;
}
.viewCls {
  color: #1A0DAB;
  cursor: pointer;
}
.testInnerTable {
  /* height: 101px; */
    margin-left: 0px;
    width:100%;
    margin-top: 10px;
    /* margin-left: -20%; */
    background-color: #f7f7f7;
    padding: 10px;
}
.inner_table_color {
  background: #1ebbd9 !important;
  color: #fff !important;
  padding: 3px;
}
</style>
