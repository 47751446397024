<template>
  <div>
    <v-container fluid>
      <v-form ref="form">
      <v-layout row>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              External Sonology Lab Details Available
            </v-text>
            <v-select
              :items="['Yes', 'No']"
              label="Select"
              v-model="externalSonologyLabDetails.isSonologyDetailsAvelable"
              :rules="rules.mandatoryrule"
              solo
              item-text="state"
              item-value="state"
              class="rounded-0"
              @change="toggleAllFields(externalSonologyLabDetails.isSonologyDetailsAvelable)"
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Sonology Lab Name
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="
                externalSonologyLabDetails.sonologyDetails.sonologyLabName
              "
              maxlength="100"
              counter="100"
              class="rounded-0"
              :disabled="disableAllFields"
              aria-required="true"
              :rules="!disableAllFields && [required()]"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Sonology Lab Address
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="externalSonologyLabDetails.sonologyDetails.sonologyLabAddress"
              :disabled="disableAllFields"
              maxlength="200"
              counter="200"
              class="rounded-0"
              aria-required="true"
              :rules="!disableAllFields && [required()]"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Sonology State
            </v-text>
            <v-text-field
              label="Enter..."
              v-model="externalSonologyLabDetails.sonologyDetails.state"
              :rules="!disableAllFields && rules.mandatoryrule"
              :disabled="disableAllFields"
              solo
              item-text="state"
              item-value="state"
              class="rounded-0"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Sonology City
            </v-text>
            <v-text-field
              label="Enter..."
              v-model="externalSonologyLabDetails.sonologyDetails.city"
              :disabled="disableAllFields"
              :rules="!disableAllFields && rules.mandatoryrule"
              solo
              item-text="state"
              item-value="state"
              class="rounded-0"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Sonology PIN Code
            </v-text>
            <v-autocomplete
              :items="pincodes"
              label="Enter to search..."
              v-model="pinNumber"
              :disabled="disableAllFields"
              :rules="!disableAllFields && rules.mandatoryrule"
              maxlength="6"
              :search-input.sync="searchPinCodes"
              solo
              item-text="locpincode"
              item-value="locpincode"
              class="rounded-0"
              @change="pinCodeChangeHandler"
            >
            </v-autocomplete>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Sonologist Name
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="
                externalSonologyLabDetails.sonologyDetails.sonologistName
              "
              maxlength="100"
              :disabled="disableAllFields"
              counter="100"
              class="rounded-0"
              aria-required="true"
              :rules="!disableAllFields && [required()]"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Sonologist Registration Number
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="
                externalSonologyLabDetails.sonologyDetails.registrationNumber
              "
              :disabled="disableAllFields"
              maxlength="20"
              counter="20"
              class="rounded-0"
              aria-required="true"
              :rules="!disableAllFields && [
                required(),
                alphaNumeric(
                  'registrationNumber',
                  'Sonologist Registration Number'
                ),
              ]"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Sonologist State Registered
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="
                externalSonologyLabDetails.sonologyDetails.registerdState
              "
              maxlength="20"
              :disabled="disableAllFields"
              counter="20"
              class="rounded-0"
              aria-required="true"
              :rules="!disableAllFields && [required()]"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md4 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Remarks
            </v-text>
            <v-textarea
              solo
              label="Enter..."
              v-model="externalSonologyLabDetails.remarks"
              maxlength="5000"
              counter="5000"
              rows="3"
              class="rounded-0"
              aria-required="true"
              :rules="[required()]"
            >
            </v-textarea>
          </v-layout>
        </v-flex>
      </v-layout>
      </v-form>
    </v-container>
    <InvestigationFooterCard
      :tabno="sno"
      ref="footerCardRef"
      @validateTabForms="validateTabForms"
      :formdata="getFormData"
      :nonMandatoryFields="nonMandatoryFields"
      :inputFieldCorrectlyFilled="true"
      :enableFieldObject="getEnableFieldObject"
      :componentName="'externalSonologyLabDetails'"
      :siuWorkItemId="siuWorkItemId"
      :tabFormDataId="tabFormDataId"
    />
  </div>
</template>
<script>
import InvestigationFooterCard from "./InvestigationFooterCard";
import { getPrePopulatedData,getAllPinCodes } from "../apiservices/Investigation";
export default {
  name: "ExternalSonology",
  data() {
    return {
      pinNumber:null,
      disableAllFields:false,
      searchPinCodes:'',
      pincodes: [],
      sno: 9,
      lossStatesList: ["A"],
      validationmessage: "This field is required",

      nonMandatoryFields: [],
      rules: {
        mandatoryrule: [(v) => !!v || this.validationmessage],
      },
      enableFieldsArr: [],
      externalSonologyLabDetails: {
        isTabSelected: true,
        isSonologyDetailsAvelable: "",
        remarks: "",
        sonologyDetails: {
          sonologyLabAddress: "",
          sonologyLabName: "",
          state: "",
          city: "",
          pinCode: "",
          sonologistName: "",
          registrationNumber: "",
          registerdState: "",
        },
      },
      required(){
          return (v) => (v && v.length > 0) || "This Field is Required";
      }
    };
  },
  props: ["prepoulatedData", "siuWorkItemId", "tabFormDataId"],
  created() {
    let allKeys = Object.keys(this.prepoulatedData);
    this.disableAllFields = this.prepoulatedData.isSonologyDetailsAvelable == "No" ? true : false;
    this.pincodes = [this.prepoulatedData?.sonologyDetails?.pinCode]
    this.pinNumber = this.prepoulatedData?.sonologyDetails?.pinCode
    allKeys.forEach((key) => {
      this.externalSonologyLabDetails[key] !== undefined
        ? (this.externalSonologyLabDetails[key] = this.prepoulatedData[key])
        : (this.externalSonologyLabDetails.sonologyDetails[key] =
            this.prepoulatedData.sonologyDetails[key]);
    });
  },
  components: {
    InvestigationFooterCard,
  },
  watch:{
      async searchPinCodes(value) {
        if(value === null || value == undefined || value == '' || value.length < 3){
            this.pincodes = []
            // this.externalSonologyLabDetails.sonologyDetails.city = '';
            // this.externalSonologyLabDetails.sonologyDetails.state = '';
        }
      if (value && value.length > 2) {
        this.pincodes = await getAllPinCodes(value.split(" - ")[0])
      }
    },
    },
  computed: {
    emailRules2(v) {
      if (v.length !== 0) {
        console.log("testing", /^[0-9 ]+$/.test(v));
        if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,6})+$/.test(v)) {
          return "E-mail must be valid";
        }
      }
    },
    getEnableFieldObject() {
      return (this.enableFieldsArr =
        this.isSonologyDetailsAvelable !== "No"
          ? {}
          : { remarks: this.remarks });
    },
    getFormData() {
      return {
        externalSonologyLabDetails: this.$data.externalSonologyLabDetails,
      };
    },
  },
  //   mounted() {
  //     let found = this.items.find(el => el.sno == 20);
  //     if(!found){
  //       this.items.push({sno: 20, name: "View / Upload Documents"});
  //     }
  //     console.log("INDEX NUMBER 20", found);

  //   },
  methods: {
    validateTabForms(){
      if(!this.$refs.form.validate()){
        this.$refs.form.validate() 
        this.showToast("Please fill all mandatory fields", this.TOST().WARNING);
        return
      }else{
        this.$refs.footerCardRef.interimSave();
      }
    },
    pinCodeChangeHandler(v) {
      const foundData = this.pincodes.find((item) => item.pinCode === v.split(" - ")[0]);
      this.externalSonologyLabDetails.sonologyDetails.state = foundData.stateName;
      this.externalSonologyLabDetails.sonologyDetails.city = foundData.cityDistrictName;
      this.externalSonologyLabDetails.sonologyDetails.pinCode = this.pinNumber ? Number(this.pinNumber.split(' - ')[0]) : null;
    },
    toggleAllFields(val){
      if(val == 'No'){
        this.disableAllFields = true;
        this.clearAllFields();
      }else{    
        this.disableAllFields = false  
      }
    },
    clearAllFields(){
      this.externalSonologyLabDetails.sonologyDetails.sonologyLabName = '';
      this.externalSonologyLabDetails.sonologyDetails.sonologyLabAddress = '';
      this.externalSonologyLabDetails.sonologyDetails.state = '';
      this.externalSonologyLabDetails.sonologyDetails.city = '';
      this.externalSonologyLabDetails.sonologyDetails.pinCode = null;
      this.externalSonologyLabDetails.sonologyDetails.sonologistName = '';
      this.externalSonologyLabDetails.sonologyDetails.registerdState = '';
      this.externalSonologyLabDetails.sonologyDetails.registrationNumber = '';
      this.pinNumber = null;
    },
    dragleave(event) {
      this.visualEffect = false;
    },
    browseCancel() {
      //to select attachments
      //   document.getElementById("cancelchequeupload").click()
    },
    dragover(event) {
      event.preventDefault();
      this.visualEffect = true;
    },
    drop(event) {
      event.preventDefault();
      //   this.selectFile() // Trigger the onChange event manually
      this.visualEffect = false;
    },
    computedDate(name) {
      return this[name] ? moment(this[name]).format("DD/MM/YYYY") : "";
    },
    goToPreviousTab() {
      this.sno--;
      let btnNumber = "myBtn" + this.sno;
      const elem = this.$refs[btnNumber];
      elem[0].$el.click();
    },
    goToNextTab() {
      this.sno++;
      console.log("this", this.$children.$options);
      let btnNumber = "myBtn" + this.sno;
      const elem = this.$refs[btnNumber];
      elem[0].$el.click();
    },
    switchTabs(sno, name) {
      this.sno = sno;
      this.tabName = name;
    },
    submit() {
      this.isAlertView = true;
    },
    accept() {
      this.isAlertView = true;
    },
    saveData() {},
    alphaNumeric(fieldName, name, mandatory = true) {
      console.log("fieldName", fieldName, name);
      if (fieldName.length > 0) {
        return (v) => /^[a-zA-Z0-9]+$/.test(v) || "Enter valid " + name;
      }
    },
    numericOnly() {
      return (v) => /^[0-9 ]+$/.test(v) || `Only Numbers are allowed.`;
    },
    onSubmit() {},
    browse() {},
    selectFile() {},
  },
};
</script>
