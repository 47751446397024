<template>
    
    <v-app>
		<v-card class="">
            
            <div class="ma-7" >
                <v-form v-model="valid" ref="form">
                <v-layout row class>
                    
                <v-flex style="flex: 1;" class=" ">
                      <v-layout column class=" mx-3 cust_pr">
                          <label class="font-weight-bold cstm-clr-pr" style=" color: #646666; ">Network Name</label>
                                
                                <v-flex class=" ">
                                    <v-text-field
                                        v-model="networkName"
                                        label="Enter..."
                                        solo
                                        dense
                                        readonly
                                        class="rounded-0"
                                    ></v-text-field>
                                </v-flex>
                            
                        </v-layout>
                    </v-flex> 

                    
                <v-flex style="flex: 1;" class=" ">
                    <v-layout column class="cust_pr mx-3 ">
                        <p class="font-weight-bold cstm-clr-pr">
                            Network ID
                        </p>
                        <v-text-field
                            solo
                            dense
                            label="Enter..."
                            v-model="networkId"
                            class="rounded-0"
                            readonly
                            :rules="[rules.minId]"
                          
                        >
                        </v-text-field>
                    </v-layout>
                </v-flex>

                <v-flex style="flex: 1;" class="">
                    <v-layout column class="cust_pr mx-3 ">
                        <p class="font-weight-bold cstm-clr-pr">
                            Network status
                        </p>
                        <v-select
                            :items="selectStatus"
                            item-text="state"
                            item-value="state"
                            v-model="networkStatus"
                            label="Select"
                            dense
                            solo
                            readonly
                            hide-details 
                            class="rounded-0"
                        ></v-select>
                        
                    </v-layout>
                </v-flex>

                <v-flex style="flex: 1;" class="cust_pr mx-3">
                                <label 
                                    class="font-weight-bold cstm-clr-pr"
                                    style="color: #646666;">Clinical Specialties
                                </label>
                                <v-text-field
                                    solo
                                    dense
                                    label="Enter..."
                                    v-model="clinicalSpecial"
                                    class="rounded-0"
                                    readonly
                                        
                                >
                                </v-text-field>
                                
                                <!-- <v-flex class="">
                                    <v-select
                                        :items="selectClinicalSpecialties"
                                        item-text="state"
                                        item-value="state"

                                        v-model="clinicalSpecial"
                                        label="Select"
                                        multiple
                                        solo
                                        
                                        
                                        class="rounded-0"
                                    >
                                    <template v-slot:selection="{ item, index }">
                                        <span v-if="index === 0">{{ item }}</span>
                                        
                                        <span
                                        v-if="index === 1"
                                        class="grey--text caption"
                                        >
                                        (+{{ clinicalSpecial.length - 1 }} others)
                                        </span>
                                    </template>
                                    </v-select>
                                </v-flex> -->
                            </v-flex>

                <v-flex style="flex: 1;" class="">
                        <v-layout column class=" mx-3 cust_pr cust-rmvpd-pr">
                            <p class="font-weight-bold cstm-clr-pr">
                            Network Effective Start Date
                            </p>
                        <v-menu
                            ref="calender"
                            :close-on-content-click="true"
                            v-model="netendDatecalender"
                            :nudge-right="40"                           
                            transition="scale-transition"                            
                            max-width="290px"
                            min-width="290px" 
                            class="rounded-0"  

                            disabled
                            >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    slot="activator"
                                    label="dd/mm/yyyy"
                                    :value="netstartDateFromcalmoment"
                                    solo
                                    dense
                                    class="rounded-0"
                                    readonly
                                    
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                <div  slot="prepend-inner" style="background-color:#F7F7F7;  padding: 6px !important"><span class="material-icons">date_range</span></div>
                                </v-text-field>
                            </template>                                
                                    <v-date-picker
                                        v-model="netstartDate"
                                        no-title
                                        readonly
                                        
                                    ></v-date-picker>
                        </v-menu>
                    </v-layout>
                </v-flex>
            </v-layout>

            <v-flex  class="my-5" >
                <v-layout row class>
                    
                    <v-flex style="flex: 1;" class="">
                        <v-layout column class=" mx-4 cust_pr cust-rmvpd-pr">
                            <p class="font-weight-bold cstm-clr-pr">
                            Network Effective End Date
                            </p>
                        <v-menu
                            ref="calender"
                            :close-on-content-click="true"
                            v-model="netEndDateFromcal"
                            :nudge-right="40"
                           
                            transition="scale-transition"
                            
                            max-width="290px"
                            min-width="290px" 
                              class="rounded-0"
                              disabled
                            >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    slot="activator"
                                    label="dd/mm/yyyy"
                                    :value="netEndDateFromcalmoment"
                                    solo
                                    dense
                                     class="rounded-0"
                                    :rules="[dateCheck()]"
                                    v-bind="attrs"
                                    v-on="on"
                                    readonly
                                >
                                <div slot="prepend-inner" style="background-color:#F7F7F7;  padding: 6px !important"><span class="material-icons">date_range</span></div>
                                </v-text-field>
                            </template>
                                                        
                                    <v-date-picker
                                        v-model="netEndDate"
                                        no-title
                                     readonly
                                        
                                    ></v-date-picker>
                                </v-menu>
                            </v-layout>
                    </v-flex> 
                    
                <v-flex style="flex: 1;" class="">
                    <v-layout column class="cust_pr mx-3 mr-5 ">
                        <p class="font-weight-bold cstm-clr-pr">
                            Remark
                        </p>
                        <v-text-field
                            solo
                            dense
                            label="Enter..."
                            v-model="remark"
                            class="rounded-0"
                            :rules="[rules]"
                          readonly
                        >
                        </v-text-field>
                    </v-layout>
                </v-flex>
                <v-flex style="flex: 1;" class="">
                    <v-layout column class="cust_pr mx-2 mr-6">
                        <p class="font-weight-bold cstm-clr-pr">
                            Product
                        </p>
                        <v-text-field
                            solo
                            dense
                            label="Enter..."
                            v-model="product"
                            class="rounded-0"
                            :rules="[rules]"
                          readonly
                        >
                        </v-text-field>
                    </v-layout>
                </v-flex>

                <v-flex style="flex: 1;" class="">
                    <v-layout column class="cust_pr mx-1 ">
                        <p class="font-weight-bold cstm-clr-pr">
                            Product Benefits
                        </p>
                        <v-text-field
                            solo
                            dense
                            label="Enter..."
                            v-model="productBenefits"
                            class="rounded-0"
                            :rules="[rules]"
                          readonly
                        >
                        </v-text-field>
                    </v-layout>
                </v-flex>

                <v-flex style="flex: 1;" class="">
                     <v-layout column class="cust_pr mx-3 ">
                        <!-- <p class="font-weight-bold cstm-clr-pr">
                            Product Benefits
                        </p>
                        <v-text-field
                            solo
                            dense
                            label="Enter..."
                            v-model="productBenefits"
                            class="rounded-0"
                            :rules="[rules]"
                          
                        >
                        </v-text-field> -->
                    </v-layout> 
                </v-flex>
            </v-layout>
            </v-flex>

            </v-form>

             <v-card class="mb-2 mt-3 " style="border:0.5px solid #C1C8CC;" tile>
				<v-layout  style="padding:20px;background-color:#F7F7F7;align-items:center; justify-center; justify-content: flex-end;">
                        <div class="">
                            <v-btn tile dark @click="$router.push('/NetworkSearch')" style="font-size:12px;background-color:#E46A25" small class="text-capitalize mx-2 px-3">
                                        <v-icon dark small >arrow_back</v-icon > 
                                        <v-divider vertical class="mx-2"></v-divider>
                                        Return
                            </v-btn>
                            <v-btn tile  dark style="font-size:12px;background-color:#23B1A9" small class="text-capitalize mx-2 px-3" 
                                         @click="EditNetworkDetails()">
                                        <v-icon dark small >edit</v-icon > 
                                        <v-divider vertical class="mx-2"></v-divider>
                                        Edit Network Details
                            </v-btn>
                            
                            <v-btn tile dark  
                                    @click="AddProvider()"
                                    style="font-size:12px;background-color:#23B1A9" small  class="text-capitalize mx-2 px-3">
                                        <v-icon dark small >add_circle</v-icon > 
                                        <v-divider vertical class="mx-2"></v-divider>
                                        Add Provider
                            </v-btn>

                            <v-btn tile dark   
                                    style="font-size:12px;background-color:#152F38" small class="text-capitalize mx-2 px-3"  
                                    @click="MapProducts()"
                                    >
                                        <v-icon dark small >add_circle</v-icon > 
                                        <v-divider vertical class="mx-2"></v-divider>
                                        Map Products, Benefits & Policies
                            </v-btn>
                    </div>                 					
				</v-layout>
            </v-card>
            <!-- </v-card> -->

            <v-card tile class="mt-3 " style="background-color:#23B1A9;border:1px solid #C1C8CC;flex:1;">  
                    <v-layout row justify-start align-center justify-space-between class="mx-">
                        <v-text dark class="mr-1 mx-5 pa-3 font-weight-bold body-1" style="color:#ffffff; font-size:16px !important">
                            Provider List 
                        </v-text>
                   </v-layout>
            </v-card>

            <v-dialog v-model="confirmDialogremove" persistent max-width="400px">
                 
                         <v-card tile >
                            <v-card tile>
                                <v-layout justify-space-between  class="" style="flex:1;background-ox-shadow: 0px 3px 5px #00000029;">
                             
                                    <v-card-title class="pa-0 pl-3 text-capitalize font-weight-bold" style="color:#10242B;font-size:15px"> 
                                       CONFIRMATION
                                    </v-card-title>
                                    <div style="border-left:1px solid #C1C8CC;" @click="close_cancelDialog()">
                                        <v-icon style="cursor: pointer;" class="pa-2" >close</v-icon>
                                    </div>
                                </v-layout>
                            </v-card>
                        <v-card-text class="font-weight-regular pa-0 ma-0 pl-3 py-3" style="color:#10242B;font-size:15px">
                                "Are you sure to remove provider."
                        </v-card-text>
                    </v-card>
                    <v-card tile color="#F7F7F7">
                        <v-card-actions justify-start  >
                            <v-btn dark  tile color="black" @click="close_cancelDialog()" class="text-capitalize px-3 mx-2" small><v-icon dark small>cancel</v-icon> <v-divider vertical class="mx-2"></v-divider>No</v-btn>
                            <v-btn @click="removeProvider(value)" dark  tile color="#23B1A9" class="text-capitalize px-3 ml-2" small><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider> Yes</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
        

                <v-card class="mt-3 mb-3" tile>
             
                    <v-data-table
                    :headers="headers"
                    :items="providerData"
                    :page.sync="page"
                    :items-per-page="itemsPerPage"
                    hide-default-footer
                    dense

                    @page-count="pageCount = $event"
                >


                
                 <template v-slot:item.prn="{ item }">
                    <div >{{ item.prn | checkdata }}</div>

                    <!-- <div @click="wrkitmidClick(item.docId)" class="text-decoration-underline" style="color:#E46A25; cursor:pointer; font-size:12px !important">{{ item.NetworkID | checkdata }}</div> -->
                </template>
                 <template v-slot:item.entityName="{ item }">
                    <div >{{ item.entityName | checkdata}}</div>
                </template>
                <template v-slot:item.providerType="{ item }">
                    <div >{{ item.providerType | checkdata}}</div>
                </template>
                <template v-slot:item.aliasName="{ item }">
                    <div >{{ item.aliasName |checkdata}}</div>
                </template>
                
               
                 <template v-slot:item.status="{ item }">
                    <div >{{ item.status | checkdata}}</div>
                </template>
                <template v-slot:item.rohiniId="{ item }">
                    <div >{{ item.rohiniId | checkdata}}</div>
                </template>
                <template v-slot:item.ProductBenefits="{ item }">
                    <div >{{ item.ProductBenefits |checkdata}}</div>
                </template>
                <template v-slot:item.addressLine1="{ item }">
                    <div >{{ item.addressLine1 |checkdata}}</div>
                </template>
                
                <template v-slot:item.cityTownVillage="{ item }">
                    <div >{{ item.cityTownVillage| checkdata }}</div>
                </template>
                <template v-slot:item.state="{ item }">
                    <div >{{ item.state| checkdata }}</div>
                </template>
                
                <template v-slot:item.Action="{ item }">

                
                    <div @click="RemoveClick(item )" class="text-truncate" style="color:#D1121B; cursor:pointer; font-size:12px !important">
                        <v-icon small style="color:#D1121B; cursor:pointer">delete</v-icon >
                        Remove{{ item.Action }}</div>

                    
                </template>

                
                    
                </v-data-table>
                <v-divider></v-divider>
                
                <v-layout row justify-end align-center class="ma-2" >
                    <v-text class="mr-1 font-weight-bold" style="color:#3C3D3D; font-size:13px;">
                        <span class="" style="color:#E46A25;font-size:12px;">Showing {{fval}}-{{sval}} </span>out of {{totalLen}} records
                    </v-text>
                    <v-divider  class="mx-2" vertical ></v-divider>
                    <v-pagination
                        class="pdct-stUp-bnkAcc-pgn"
                                v-model="page"
                                color="#152F38"
                                flat
                                @input="nextPageData(page)"
                                :total-visible="9"
                                :length="pagelength"
                                
                    ></v-pagination>
                </v-layout>
            </v-card>

        
            </div>
   
		</v-card>
    </v-app>    
</template>
            
<script>
import axios from "axios";
 import moment from 'moment';

export default {
        

    created() {
            
        console.log("Success Token", this.$store.getters.successToken)
        this.token=this.$store.getters.successToken
        this.$store.commit("SAVE_PAGE_HEADER", "Network Management");

        
        //  this.getProviderALLInstitution(this.page)
        console.log('store',this.$store)
                this.newnetid=this.$store.getters.fetchNetworkData._id
        this.getnetworkDetailsByID(this.$store.getters.fetchNetworkData._id, 1)
        console.log("from search network data by id--->>>", this.$store.getters.fetchNetworkData._id)

        //  this.getProductsData(this.$store.getters.fetchNetworkData._id)


        // this.getnetworkDetailsByID(this.$store.getters.fetchNetworkData.data.data._id)
        // console.log("network data by id--->>>", this.$store.getters.fetchNetworkData.data.data._id)
        // console.log("fetchProductCodeData data by id--->>>", this.$store.getters.fetchProductCodeData)


    },

    
     computed: {

        netstartDateFromcalmoment(){
            return this.netstartDate ? moment(this.netstartDate).format("DD-MM-YYYY"): ''
        },
         
         netEndDateFromcalmoment(){
            return this.netEndDate ? moment(this.netEndDate).format("DD-MM-YYYY"): ''
        },
        
        validDateChk(){
            
           
            let todayDate = Date.now();
            let Todate= moment(this.netstartDate,'YYYY-MM-DD ').toDate().getTime()
            console.log(todayDate,"todayDate")
            let CurrentDate= todayDate

        }, 

        
     },

     filters:{

      truncate: function (text, length, suffix) {
          if (text == "" || text == "undefined" || text == null || text == '' || text == 'Invalid Date') {
                return "-"
        
            }else if (text.length > length) {
                return text.substring(0, length) + suffix;
            } else {
                return text;
            }
        },
        checkdata(data){// eslint-disable-line no-mixed-spaces-and-tabs
            // console.log('Validity Data', data);
            if (data == "" || data == "undefined" || data == null || data == '' || data == 'Invalid Date') {
                return "-"
            } else {
                return data;
            }
        },
        formatDate(data){// eslint-disable-line no-mixed-spaces-and-tabs
            // console.log('Validity Data', data);

             if (data == "" || data == "undefined" || data == null || data == '' || data == 'Invalid Date') {
                return "-"
            } else {
                var n = new Date(data);
                return n.toLocaleString("en-IN")
            }
        },
    

      truncate: function (text, length, suffix) {
            if (text.length > length) {
                return text.substring(0, length) + suffix;
            } else {
                return text;
            }
        }
    },


    components:{
        
    },

    data(){
        return {
            valid:'',
            networkName:'',
            networkId:'',
            networkStatus:'',
            selectStatus:['Active','Inactive'],

            netstartDate:'',           
            netEndDate:'',
            remark:'',
           
            netstartDateFromcal:false,
            netEndDateFromcal:false,
            
            product:[],    
            productBenefits:[],

            mapAddProdsArray:[],
                    
            clinicalSpecial:'',
            selectClinicalSpecialties:[],
            // selectClinicalSpecialties:['abc','xyz','pqr'],

            value:'',
            tablerowdata:{},

            GetInstitutionData:[],

            headers:[
                
                {text: 'PRN', value: 'prn',sortable: true,divider:true, class:"hdr-txt-clr_NtwrkMgmt "},
                {text: 'Provider Name', value: 'entityName',sortable: true,divider:true,class:"hdr-txt-clr_NtwrkMgmt text-truncate"},
                {text: 'Provider Type', value: 'providerType',sortable: true,divider:true,class:"hdr-txt-clr_NtwrkMgmt text-truncate"},
                {text: 'Status', value: 'status',sortable: true,divider:true,class:"hdr-txt-clr_NtwrkMgmt "},
                {text: 'Alias Name', value: 'aliasName',sortable: true,divider:true,class:"hdr-txt-clr_NtwrkMgmt text-truncate"},
                {text: 'ROHINI ID', value: 'rohiniId',sortable: true,divider:true,class:"hdr-txt-clr_NtwrkMgmt text-truncate"},
                {text: 'Address', value: 'addressLine1',sortable: true,divider:true,class:"hdr-txt-clr_NtwrkMgmt text-truncate"},
                {text: 'City/Town', value: 'cityTownVillage',sortable: true,divider:true,class:"hdr-txt-clr_NtwrkMgmt text-truncate"},
                {text: 'State', value: 'state',sortable: true,divider:true,class:"hdr-txt-clr_NtwrkMgmt "},
                {text: 'Action', value: 'Action',divider:true,class:"hdr-txt-clr_NtwrkMgmt text-truncate"},
                

             ],
             wrkItmDtaLst:[
                 {
                   PRN:"PRN122313",
                    ProviderName:"Ashish",
                    ProviderType:"xyz",
                    AliasName:"A.B.C.asd",
                    Status:"ACTIVE",
                    ROHINIID:"12312323456",
                    Address:"xyzaa",
                    City:"Akola",
                    State:'Maharashtra'
                    
                },{
                   PRN:"PRN122313",
                    ProviderName:"Ashish",
                    ProviderType:"xyz",
                    AliasName:"A.B.C.dcp",
                    Status:"ACTIVE",
                    ROHINIID:"12312323456",
                    Address:"xyzaaasfdf",
                    City:"Akola",
                    State:'Maharashtra'
                    
                },{
                   PRN:"PRN122313",
                    ProviderName:"Ashish A.",
                    ProviderType:"xyz",
                    AliasName:"A.B.C.",
                    Status:"ACTIVE",
                    ROHINIID:"12312323",
                    Address:"xyzaalkkl",
                    City:"Akola",
                    State:'Maharashtra'
                    
                }
                 
             ],


            typesearch:false,

            swap_final_count:false,
            fval:0,
            sval:0,
            totalLen:0,
            token:null,
            
            showrsltnotfound:false,
            pageNumber: 1,
            size: 10,
            page: 1,
            pagelength:0,
            itemsPerPage:20,
            totalLength:'',
            confirmDialogremove:false,

            providerData:[],
            productsDetails:[],
            newnetid:'',

            rules: {
                required: value => !!value || 'This Field is Required.',
                minId: value => {
                            // const pattern = /^([0-9])+$/
                        const pattern = /^([0-9]){6}?$/
                        return value.length <= 0  || pattern.test(value) || 'Enter valid Network ID.'
                },
                
                 },

            dateCheck(){
                let todayDate = Date.now();
                let fromDate=moment(this.netstartDate,'YYYY-MM-DD ').toDate().getTime()
                let Todate= moment(this.netEndDate,'YYYY-MM-DD ').toDate().getTime()
                console.log(todayDate,"today start date")
                let CurrentDate= todayDate
                if(Todate < fromDate){
                    // this.showToast("The Product 'Effective date From' can not be greater than Product 'Effective date To'.",this.TOST().WARNING)
                    return "Enter valid date range."
                }
            },
            

        }
        
        },
        

    methods:{

        getnetworkDetailsByID(id, pageNum){
                console.log(pageNum,'<- incoming PageNum | network Details Response id------->>>',id);
                let self = this; 
               
                self.showLoader('Loading', true);  

                axios({
                    method: "get",
                    url:self.API_Service_NETWORK_MGMNT()+"network-management/get-network-by-id/"+id+"?perPage="+20+"&pageNo="+pageNum, 
                    headers: {
                        "Content-Type": "application/json", 
                        "x-access-token": self.token,
                    }
                }).then(function(response){
                    console.log('network Details Response------->>>',response.data.data);
                    
                        self.fetchNetworkAll(response.data.data)
                        self.$store.commit("UPDATE_NETWORK_DATA",response.data.data)

                        self.selectClinicalSpecialties=response.data.data.clinicalSpecialities
    
                                    self.providerData=response.data.data.providerDetails.map(proData =>{
                                        return{
                                            prn:proData.providerPRN,
                                            entityName:proData.providerName,
                                            providerType:proData.providerType,
                                            status:proData.providerStatus,
                                            // aliasName:proData.
                                            rohiniId:proData.providerRohiniId,
                                            addressLine1:proData.providerAddress,
                                            cityTownVillage:proData.city,
                                            state:proData.state,
                                            _id:proData._id,
                                            providerId:proData.providerId,
                                            
                                        }
                                        // console.log('provider prn no--->>>',prn)
                                    })

                // for network list pagination
                //   self.pagelength=response.data.data.total_pages
                //  if()
                 self.pagelength= response.data.providerPageDetails.total_pages;
                    console.log('next btn ----->',self.pagelength)

                    self.totalLength=response.data.providerPageDetails.total 
                    console.log('total ----->',self.totalLength)
                    self.totalLen = self.totalLength
                     let l = self.providerData.length
                
                
                    console.log( 2*self.page,'skip simulation ');

                      var less_enough = self.totalLen
                        var checkless_init = false
                        if(less_enough < 20){
                            checkless_init = false
                        }else{
                            checkless_init = true
                        }

                        // if less than 15 we have second value same as total value as no pagination will occur
                        if(checkless_init){
                                // checkinit is true means the final count is more than 15 (- 1)
                                var traverse =  20*self.page
                                self.fval = traverse-19
                                if(self.swap_final_count){
                                    self.sval =  self.totalLen
                                }else{
                                    self.sval = traverse
                                }

                        }else if(self.totalLen == 0){
                            
                          self.sval = self.fval = 0
                        
                       }else{
                             self.fval = 1
                             self.sval = self.totalLen
                        }

// pagination

                    console.log("provider incomig data----->>>",self.providerData)
                    
                    self.showLoader('Loading', false);
                })
                .catch(function(error) {
                    self.showLoader('Loading', false);
                    console.log(error);
                    // self.showToast(error.response.data.msg,self.TOST().ERROR);
                });

            },

        fetchNetworkAll(e){
             console.log('incomin data all',this.$store.getters.fetchNetworkData)
             this.$store.getters.fetchNetworkData
           
             this.clinicalSpecial=e.clinicalSpecialities
            //  this.selectClinicalSpecialties=e.clinicalSpecialities,

            this.networkCreatedbtn=e.networkCreated,
            this.networkId=e.networkId,
            this.netstartDate=e.networkEffectiveStartDate,
            this.netEndDate=e.networkEffectiveEndDate,
            this.networkName=e.networkName,
            this.networkStatus=e.networkStatus,
            this.productBenefits=e.productBenefits,
            this.product=e.products.map(productsdata => {
                                 return productsdata.productName    
                       console.log('product benifits name--->>>',productsdata)      
                    }),
            this.remark=e.remarks
            

        },

        // getProductsData(data){
        //     this.GET(3,'product-and-benefits/get-product-and-benefits',(res)=>{
        //         let _resp = res.data.data
        //         for(let i = 0;i < data.length ; i++){
        //             // this.fetchNetworkAll=[]
        //              this.mapAddProdsArray = []
        //              console.log("n/w mangment product bene data",this.mapAddProdsArray)
        //             for(let j = 0;j < _resp.length ; j++){
        //                 // data[i].productId.localeCompare(_resp[j]._id) == 0 ? _resp.splice(j, 1) : ''
        //                 let _data = { text:_resp[j].product, value:_resp[j]._id}
        //                 // this.fetchNetworkAll.push(_data)
        //                  this.mapAddProdsArray.push(_data)
                         
        //             }
        //         }
        //     })
        // },

        selectClinicalSpeci(){
            console.log("clinicalSpecialities are---->",this.clinicalSpecial)

        },

        EditNetworkDetails(){ 
            // this.$router.push('/NetworkSetup')
            this.$router.push({name: 'NetworkSetup', params:{editValue:true}})
            console.log("move to EditNetworkDetails====> ");
        },
        AddProvider(){ 
            
            this.$router.push('/add-provider')
            console.log("move to AddProvider====> ");
        },
        MapProducts(){ 
            
            this.$router.push('/map-product')
            console.log("move to MapProducts====> ");
        },

        close_cancelDialog(){
                this.confirmDialogremove=false
            },

        RemoveClick(item){
            console.log("item btn from list====> ",item);
            this.value=item
            this.tablerowdata=item
            this.confirmDialogremove=true
            console.log("Remove provider from list====> ",this.value);
        },

        

        removeProvider(value){
            console.log("item btn from RemoveClick btn====> ",value);

                    console.log('remove Provider data rmk--->',this.tablerowdata)

            // if( this.rjctRmk===''|| this.rjctRmk===null|| this.rjctRmk===undefined){
            //      this.showToast('Please enter remarks to Reject a Product', this.TOST().WARNING);
            //      return
            // }
                let self=this
                // self.showLoader('Loading', true);
                let demodata={

                    // addressLine1: self.tablerowdata.addressLine1,
                    // cityTownVillage: self.tablerowdata.cityTownVillage,
                    // state: self.tablerowdata.state,
                    // entityName: self.tablerowdata.entityName,
                    // prn: self.tablerowdata.prn,
                    // providerType: self.tablerowdata.providerType,
                    // rohiniId: self.tablerowdata.rohiniId,
                    // status:self.tablerowdata.status,
                    providerId: self.tablerowdata.providerId,
                    newnetid: self.tablerowdata._id,
                    
                }
                console.log("from demodata--------->",demodata);
                
                axios({
                    method: "put",
                    url:self.API_Service_NETWORK_MGMNT()+"network-management/remove-provider-from-network/"+self.newnetid+"?providerId="+self.tablerowdata.providerId,
                    
                    headers: {
                        "Content-Type": "application/json",
                        "x-access-token":self.token,
                    },
                    data:demodata,
                })
                // self.RmrkDilg=false

                .then(function (response) {
                    console.log(" remove-provider>>>>>>", response);
                    // self.resetfrm();
                    self.showToast(response.data.msg,self.TOST().SUCCESS)
                    console.log(" remove-provider success msg--->>>>>>", response.data.msg);
                    self.getnetworkDetailsByID(self.newnetid)
                     self.confirmDialogremove=false
                })
                .catch(function (error) {
                    console.log("remove-provider Error>>>>>>", error);
                    self.showToast(error.response.data.msg,self.TOST().ERROR);

                    
                });
            
        },

        

        
        nextPageData(indata){

            console.log("isSearchIndiv => ", this.typesearch);

            if(this.pagelength == indata){
                this.swap_final_count = true
            }else{
                this.swap_final_count = false
            }

            console.log(' sort value',this.indiviSortBy)
            console.log(indata, ' page no value',this.newnetid)
            
            this.getnetworkDetailsByID(this.newnetid, indata)
            
            //  this.getProviderALLInstitution(this.page)

            // this.SearchProviderIndividual(this.pageIndu)

            // if(this.typesearch==false){
            //     this.getProviderALLIndividual(this.indiviSortBy,this.pageIndu)
            //       console.log("from if ",this.typesearch)
            // }else{
            //     console.log("from else ",this.typesearch)
            //     this.SearchProviderIndividual(this.pageIndu)
            // }

        },

        
        cnclbtn(){


            if((this.networkNameFilter===''||this.networkNameFilter===undefined||this.networkNameFilter===null) 
            && (this.networkName===''||this.networkName===undefined||this.networkName===null) 
            && (this.networkId===''||this.networkId===undefined||this.networkId===null)
            && (this.networkStatus===''||this.networkStatus===undefined||this.networkStatus===null) 
            && (this.netstartDateFrom===''||this.netstartDateFrom===undefined||this.netstartDateFrom===null)
            && (this.netstartDateTo===''||this.netstartDateTo===undefined||this.netstartDateTo===null) 
            && (this.netEndDateFrom===''||this.netEndDateFrom===undefined||this.netEndDateFrom===null)
            && (this.netEndDateTo===''||this.netEndDateTo===undefined||this.netEndDateTo===null) 
            && (this.product===''||this.product===undefined||this.product===null)
            && (this.productBenefit===''||this.productBenefit===undefined||this.productBenefit===null) 
            && (this.selectprovider===''||this.selectprovider===undefined||this.selectprovider===null)
            && (this.clinicalSpecial===''||this.clinicalSpecial===undefined||this.clinicalSpecial===null) 
            && (this.groupPolicy===''||this.groupPolicy===undefined||this.groupPolicy===null)
            
            ){
                
                return
            }else{
                
                
                this.networkNameFilter=''
                this.networkName=''
                this.networkId=''
                this.networkStatus=''
                this.netstartDateFrom=''
                this.netstartDateTo=''
                this.netEndDateFrom=''
                this.netEndDateTo=''
                this.product=''

                this.productBenefit=''
                this.selectprovider=''
                this.clinicalSpecial=''
                this.groupPolicy=''
                console.log('cancel Individual data',)
                this.$refs.form.resetValidation()
                // showSearchIndividual=true,
                //     this.showrsltnotfound=false
                    
            }
        },


    // getProviderALLInstitution(pageNo) {
    //             // console.log("sort by-------",sortBy)
    //             console.log("page no ---->",pageNo)
                
    //                 let self = this; 
    //                 self.showSearchInstitution =true 
    //                 self.rsltnotfound=false
                    
    //             let apiURL=''
    //             // if(self.instiSortBy===''||self.instiSortBy===undefined||self.instiSortBy===null){
    //             // if(sortBy===''||sortBy===undefined||sortBy===null){

    //                 apiURL=self.API_Service_provider()+"provider/provider-institution-all?perPage=20&pageNo="+pageNo
    //             // }else{
    //             //     apiURL=self.API_Service_provider()+"provider/provider-institution-all?perPage=20&pageNo="+pageNo+"&sortBy="+sortBy
    //             // }
    //                 axios({
    //                 method: "get",


                    
    //                 url:apiURL,
    //                     // "http://claimsdrive-health.salesdrive.app/provider-management/provider-management-service/api/provider/provider-institution-all", 
                        
    //                     // self.API_Service_provider()+"provider/provider-institution-all?perPage=20&pageNo="+self.inPage+"&sortBy="+self.instiSortBy, 
                        
                            
    //                         headers: {
    //                             "Content-Type": "application/json", 
    //                             "x-access-token": self.token,
    //                         }
    //                     })
    //                     .then(function(response) {
                                                          
    //                 let apiData = response.data.data.data

    //                 console.log("get provider institution all data responce------->>>",apiData);
    //                 self.showtable=true,
    //                 self.showNosearch=false,

    //                 self.GetInstitutionData = []
    //                 self.GetInstitutionData= apiData

	// 	self.pagelength=response.data.data.total_pages
    //                 console.log('next btn ----->',self.pagelength)

    //                 self.totalLength=response.data.data.total
    //                 console.log('total ----->',self.totalLength)
    //                 self.totalLen = self.totalLength
    //                  let l = self.GetInstitutionData.length
                
                
    //                 console.log( 20*self.page,'skip simulation ');

    //                   var less_enough = self.totalLen
    //                     var checkless_init = false
    //                     if(less_enough < 20){
    //                         checkless_init = false
    //                     }else{
    //                         checkless_init = true
    //                     }

    //                     // if less than 15 we have second value same as total value as no pagination will occur
    //                     if(checkless_init){
                            
    //                             // checkinit is true means the final count is more than 15 (- 1)
    //                             var traverse =  20*self.page
    //                             self.fval = traverse-19
    //                             if(self.swap_final_count){
    //                                 self.sval =  self.totalLen
    //                             }else{
    //                                 self.sval = traverse
    //                             }

    //                     }else if(self.totalLen == 0){
                            
    //                       self.sval = self.fval = 0
                        
    //                    }else{
    //                          self.fval = 1
    //                          self.sval = self.totalLen
    //                     }

    //                 // if(self.instiSortBy != ''){
    //                 //     self.selectSorting()


    //                 // }
			
    //                     })
    //                     .catch(function(error) {
    //                         console.log(error);
    //                         self.showToast(error.response.data.msg,self.TOST().ERROR);
    //                     });
             
    //         },        
    
        
    }
    
}
</script>

<style>

.cstm-clr-pr{
    color:#646666 !important;
    margin-bottom: 5px !important;
    font-size: 13px !important;
}



.cust_pr .v-input{
    font-size: 13px !important;
}
.cust_pr .v-input input {
    font-size: 13px !important;
}

.cust_pr .v-input .v-label {
    font-size: 13px !important;
}
.hdr-txt-clr_NtwrkMgmt{
    font-size: 13px !important;
    
    
    
}


</style>