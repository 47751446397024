<template>
        <v-app>
            <v-simple-table class="ml-6 mt-7 mb-6">
                <template v-slot:default>
                <thead style="color: #fff">
                    <tr>
                    <th
                        class="py-0 pl-2 text-left ml-2"
                        style="width: 10px; color: #fff">
                        Sr.No
                    </th>
                    <!-- <div class="vertical_line"></div> -->
                    <th class="py-0 pl-3 text-left" style="width: 30%; color: #fff">
                        LR/AWB/BI Number *
                    </th>
                    <th
                        class="py-0 pl-2 text-left"
                        style="width: 71%; color: #fff"
                        colspan="2">
                        LR/AWB/BI Date *
                    </th>
                    <th>
                        <!-- @click="saveLRDetails()" -->
                        <v-btn
                        color="#E46A25"
                        dark
                        style="
                            text-transform: none;
                            color: rgb(255, 255, 255);
                            text-transform: none;
                            height: 41px;
                            width: 143px;
                            font-size: 12px;
                            border-radius: 0;
                            box-shadow: none;">
                        <v-icon class="mr-2" style="font-size: 18px">mdi-checkbox-marked-circle</v-icon>
                        <div
                            style="
                            border-right: 1px solid #fff;
                            height: 14px;
                            opacity: 0.5;" class="mr-2"></div>Save
                        </v-btn>
                    </th>
                    <th class="py-0 text-left">
                        <v-btn
                        color="#152F38"
                        dark
                        style="
                            text-transform: none;
                            color: rgb(255, 255, 255);
                            text-transform: none;
                            height: 41px;
                            width: 143px;
                            font-size: 12px;
                            border-radius: 0;" @click="addNewLRRow">
                        <v-icon class="mr-2" style="font-size: 18px">mdi-plus</v-icon>
                        <div
                            style="
                            border-right: 1px solid #fff;
                            height: 14px;
                            opacity: 0.5;" class="mr-2"></div> Add
                        </v-btn>
                    </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(claim, index) in claimDetails.awbNumberAndDate" :key="index">
                    <td>{{ index + 1 }}</td>
                    <!-- spclChar(), -->
                    <td><v-text-field 
                        placeholder="A3243SD23"
                        v-model="claim.number"                       
                        dense></v-text-field></td>
                    <td colspan="3">                
                        <v-menu
                            v-model="lRAWBBLdatemenu"
                            :close-on-content-click="false"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field 
                                placeholder="dd/mm/yyyy" 
                                dense 
                                v-model="claim.date"
                                v-on="on"
                                v-bind="attrs"    
                                @click="showCalendar(index)"                            
                                >
                            </v-text-field>                                                       
                            </template>
                            <v-date-picker
                            v-model="claim.date"
                            :id="assignId(index)"
                            @input="updateClaimDate(index)">
                            </v-date-picker>
                    </v-menu>
                    </td>
                    <td>
                        <v-btn
                        color="#D1121B"
                        dark
                        @click="deleteLRRow(index)"
                        style="
                            text-transform: none;
                            background-color: rgb(209, 18, 27);
                            border-color: rgb(209, 18, 27);
                            color: rgb(255, 255, 255);
                            text-transform: none;
                            height: 30px;
                            width: 110px;
                            font-size: 12px;
                            border-radius: 0;">
                        <v-icon class="mr-2" style="font-size: 18px">mdi-trash-can-outline</v-icon>
                        <div style="
                            border-right: 1px solid #fff;
                            height: 14px;
                            opacity: 0.5;" class="mr-2"></div>
                        Delete
                        </v-btn>
                    </td>
                    </tr>
                </tbody>
                </template>
            </v-simple-table>

        </v-app>
</template>

<script>
import moment from 'moment'
export default {
    data(){
        return {
            lRAWBBLdatemenu:false,
            lRAWBBLdate:"",
            claimDetails: {
                claimType:'New Claim',
          
                policyNumber : '',
                dateOfLoss : '',//date ISOformat
                lossTime : '', //time

                insuredName : '',
                insuredID : '',
                certificateNumber : '',
                applicationNumber : '',
                productName : '',
                lob:'',
                eMarinePolicyNumber:'',

                claimCategory : 'Local Claims',
                typeOfClaim : '',
                // sumInsured:'',
                // reserveAmount:'',
                customerClaimNumber:'',
                callerIntimatorIdentification:'',
                claimIntimatedThrough:'',
                callerName : '',
                callerRelationshipwithInsured : '',
                pleaseSpecify : '',

                callerMobileNo : '',
                callerEmailID : '',
                insuredMobileNumber : [],
                insuredEmailID : [],
                emailIdForCommunication : [],
                reportedDate : '',//date
                reportedTime : '',//date
                dateDifference : '',//date
                addressOfLossLocation : '',
                pinCodeOfLossLocation : '',
                
                //table values //"number":"1","date":""
                awbNumberAndDate:[{}],
                invoice:[{}],

                nameOfContactPersonAtLossLocation : '',
                contactNumberAtLossLocation : '',
                lossDescription : '',
                propertyDamaged : '',
                coverageDescription : '',
                coverageCode : '',
                estimatedLossAmountInINR : '',
                storageDescription : '',
                storageCode : '',
                unnamedStorageLocation : '',

                transitTo: "",
                transitFrom: "",
                            
                transporterVesselName : '',
                transitToCountry : '',
                transitToState : '',

                transitFromCountry : '',
                transitFromState : '',

                isInsuredTheClaimant : false,
                claimantName : '',
                address : '',
                internationalLocation : false,
                locationLandmark : '',

                country : '',
                city : '',
                aigSettingCompany : '',
                aigCountry : '',
                aigClaimNumber : '',
                isEventACatastrophe : false,
                catastropheDescription : '',
                catastropheCode : '',
                catastropheDetail : '',
                catastropheType : '',

                claimStatus:'Suspended',
                reasonForSuspense:'',

                isTataEmp : false,
                remarks : '',
                notes : '',
                detailsOne: '',
                detailsTwo: '',
                detailsThree : '',
                detailsFour : ''
                // isTempClaim: true,
            },
        }
    },
    methods:{
        addNewLRRow() {
          console.log("addLRrow")
          this.claimDetails.awbNumberAndDate.push({"date":"","number":""});
        },
        updateClaimDate(index){
            console.log(this.claimDetails.awbNumberAndDate[index].date)
            this.lRAWBBLdatemenu = false
            //  this.claimDetails.awbNumberAndDate[index].date == null ? '' : moment(this.claimDetails.awbNumberAndDate[index].date).format('DD/MM/YYYY');
        },
        showCalendar(index){
            console.log(index)
            for(var i in this.claimDetails.awbNumberAndDate){
                if(document.getElementById("datePick_"+i)){
                    document.getElementById("datePick_"+i).style.display = "none"
                }
                
            }
            if(document.getElementById("datePick_"+index)){
                document.getElementById("datePick_"+index).style.display = ""
            }

        },
        assignId(index){
            console.log("datePick_"+index)
            return "datePick_"+index;
        },
        maxLength(v,maxLength){
            if(v && v.length >= maxLength){
                  return `Must be less than ${maxLength} Characters`
            }else {
              return false;
            }
            // return v=>v && v.length<=maxLength || `Must be less than ${maxLength} Characters`
          },
          tocheckMandatory(){
            if(this.claimDetails.productName == "Marine Freight Forward Liability"){
              return false;
            }else {
              return v => !!v || this.validationmessage;
            }
          },
          deleteLRRow(index){
            this.claimDetails.awbNumberAndDate.splice(1,index);
            console.log("deleteLRrow")
        },
          
    },
    computed:{
          computedlRAWBBLdate(){
          //  return this.lRAWBBLdate ? moment(this.lRAWBBLdate).format("DD/MM/YYYY"): ''
          return this.lRAWBBLdate == null ? '' : moment(this.lRAWBBLdate).format('DD/MM/YYYY');
        }
    }
}
</script>

<style scoped>

</style>