<template>
    <div>
      <v-dialog v-model="auditDialog" max-width="800" persistent>
        <v-card min-height="200" rounded="0">
          <v-card-title>
            <v-layout justify-space-between>
              <div>Audit Trail</div>
            </v-layout>
            <v-spacer></v-spacer>
            <v-btn
              color="transparent"
              small
              :ripple="false"
              depressed
              @click="$emit('close-dialog')"
            >
              <v-icon color="white">mdi-close </v-icon>
            </v-btn>
          </v-card-title>
          <v-layout justify-center align-center>
            <v-flex xs1 v-if="auditItems.length == 0">
              <v-progress-circular
                :size="60"
                :width="5"
                color="#23b1a9"
                indeterminate
                class="my-8"
              ></v-progress-circular
            ></v-flex>
            <v-flex xs12 v-if="auditItems.length > 0">
              <v-data-table
                :headers="auditHeaders"
                :items="auditItems"
                hide-default-footer
                class="pa-2"
              >
                <template v-slot:[`item.action`]="{ item }">
                  <v-btn
                    v-if="item.action === 'Modified'"
                    color="#FFFFFF"
                    class="text-capitalize tablebtn"
                    @click="seeModify(item)"
                  >
                    {{ item.action }}
                    <v-icon class="tableicons">mdi-arrow-right-circle</v-icon>
                  </v-btn>
  
                  <v-btn
                    v-if="item.action === 'Rejected'"
                    color="#FFFFFF"
                    class="text-capitalize tablebtn"
                    @click="seeRejected(item)"
                  >
                    {{ item.action }}
                    <v-icon class="tableicons"
                      >mdi-arrow-right-circle</v-icon
                    > </v-btn
                  >{{
                    item.action !== "Rejected" && item.action !== "Modified"
                      ? item.action
                      : null
                  }}
                </template>
                <template v-slot:[`item.srNo`]="{ index }">
                  {{ pageStartIndex + index }}
                </template>
              </v-data-table>
              <v-layout justify-end align-center class="pb-2 px-0 mr-4">
                <div>
                  <span style="color: orange"
                    >Showing {{ pageStartIndex }}-{{ pageEndIndex }}</span
                  >
                  out of {{ totalListCount }} records
                </div>
                <div>
                  <v-pagination
                    class="small-pagination"
                    v-model="page"
                    :length="pageCount"
                    :total-visible="5"
                    @input="searchAuditTrail"
                    color="#152F38"
                  ></v-pagination>
                </div>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-dialog>
  
      <!-- Modify dialogue -->
      <v-dialog v-model="seeModifyDialog" width="600" persistent>
        <v-card>
          <v-card-title>
            <v-layout justify-space-between>
              <div>Modified Field Details</div>
            </v-layout>
            <v-btn
              color="transparent"
              small
              :ripple="false"
              depressed
              @click="() => {seeModifyDialog = false; modifyItems = []}"
            >
              <v-icon color="white">mdi-close </v-icon>
            </v-btn>
          </v-card-title>
          <div class="scrollable-table">
            <v-data-table
              disable-pagination
              :headers="modifyHeaders"
              :items="modifyItems"
              hide-default-footer
              class="pa-4"
            >
            </v-data-table>
          </div>
        </v-card>
      </v-dialog>
      <!-- Modify dialogue -->
  
      <!-- Rejected dialogue -->
      <v-dialog v-model="seeRejectedDialog" width="600" persistent>
        <v-card>
          <v-card-title>
            <v-layout justify-space-between>
              <div>Rejected Field Details</div>
            </v-layout>
            <v-btn
              color="transparent"
              small
              :ripple="false"
              depressed
              @click="seeRejectedDialog = false"
            >
              <v-icon color="white">mdi-close </v-icon>
            </v-btn>
          </v-card-title>
          <div class="scrollable-table">
            <v-data-table
              disable-pagination
              :headers="rejectedHeaders"
              :items="rejectedItems"
              hide-default-footer
              class="pa-4"
            >
            </v-data-table>
          </div>
        </v-card>
      </v-dialog>
      <!-- end Rejected dialogue -->
    </div>
  </template>
  
  <script>
  import moment from "moment";
  export default {
    name: "auditTrail",
    props: {
      dialogState: {
        default: false,
      },
      auditTrailMaster: {
        default: "",
      },
      auditTrailItem: {
        default: () => null,
      },
      auditTrailMaster: {
        default: "",
      },
    },
    computed: {
      pageEndIndex() {
        if (!this.auditItems.length) {
          return 0;
        } else {
          return this.pageStartIndex + this.itemsPerPage <= this.totalListCount
            ? this.pageStartIndex + this.itemsPerPage - 1
            : this.totalListCount;
        }
      },
      auditDialog: {
        set() {
          return this.dialogState;
        },
        get() {
          return this.dialogState;
        },
      },
    },
    data() {
      return {
        page: 1,
        showAuditTrail: false,
        seeModifyDialog: false,
        auditItems: [],
        modifyItems: [],
        auditHeaders: [
          {
            text: "Sr. No.",
            value: "srNo",
            align: "center",
            sortable: false,
          },
          {
            text: "Master ID",
            value: "masterId",
            align: "center",
            sortable: false,
          },
          {
            text: "Action Taken",
            value: "action",
            align: "center",
            sortable: false,
            width: 40,
          },
          {
            text: "User ID",
            value: "userId",
            align: "center",
            sortable: false,
            // width:20
          },
          {
            text: "User Name",
            value: "userName",
            align: "center",
            sortable: false,
          },
          {
            text: "Timestamp",
            value: "createdAt",
            align: "center",
            sortable: false,
          },
        ],
        modifyHeaders: [
          {
            text: "Sr. No.",
            value: "srNo",
            align: "center",
            sortable: false,
          },
          {
            text: "Field Name",
            value: "fieldName",
            align: "center",
            sortable: false,
          },
          {
            text: "Old Value",
            value: "oldValue",
            align: "center",
            sortable: false,
          },
          {
            text: "New Value",
            value: "newValue",
            align: "center",
            sortable: false,
          },
        ],
        itemsPerPage: 10,
        totalListCount: 0,
        pageStartIndex: 1,
        pageCount: 0,
        dateFields: [
          "Start Date",
          "End Date",
          "License Reg Date",
          "License Exp Date",
        ],
        seeRejectedDialog: false,
        rejectedItems: [],
        rejectedHeaders: [
          {
            text: "Sr. No.",
            value: "srNo",
            align: "center",
            sortable: false,
          },
          {
            text: "Remark type",
            value: "remarkType",
            align: "center",
            sortable: false,
            width:"150px",

          },
          {
            text: "Remark",
            value: "remarkText",
            align: "center",
            sortable: false,
            width:"300px",
          },
          // {
          //   text: "Date",
          //   value: "date",
          //   align: "center",
          //   sortable: false,
          // },
        ],
      };
    },
    methods: {
      formatDateOnly(date){
        return date ? moment(date).utc().format("DD-MM-YYYY") : "";
      },
      formatDateTime(dateTime) {
        return dateTime ? moment(dateTime).format("DD-MM-YYYY LT") : "";
      },
      seeModify(item) {
        this.seeModifyDialog = true;
        this.modifyItems = item.modification.map((m, index) => {
          let object = { ...m, srNo: index + 1 };
          if (this.dateFields.includes(m.fieldName)) {
            object.newValue = this.formatDateOnly(m.newValue);
            object.oldValue = this.formatDateOnly(m.oldValue);
          }
          return object;
        });
      },
      searchAuditTrail(newPageNum) {
        this.page = newPageNum;
        this.auditItems = [];
        let reqData =
          "perPage=" +
          this.itemsPerPage +
          "&pageNo=" +
          newPageNum +
          "&masterId=" +
          this.auditTrailItem.uid +
          "&masterType=" +
          this.auditTrailMaster;
        this.GETM(
          "GET_AUDIT_TRAIL",
          reqData,
          (res) => {
            this.hideProgress();
            if (res.data && res.data.data && res.data.data.data) {
              this.auditItems = res.data.data.data.map((item) => ({
                ...item,
                createdAt: this.formatDateTime(item.createdAt),
              }));
              this.totalListCount = res.data.data.total;
              this.pageCount = res.data.data.total_pages;
              this.pageStartIndex =
                newPageNum == 1 ? 1 : (newPageNum - 1) * this.itemsPerPage + 1;
            } else {
              this.showAlert("RECORD NOT FOUND", "", "", () => {
                this.$emit("close-dialog");
              });
            }
          },
          (err) => {
            this.showAlert("RECORD NOT FOUND", "", "", () => {
              this.$emit("close-dialog");
            });
            this.$emit("close-dialog");
          }
        );
      },
      seeRejected(item) {
        this.seeRejectedDialog = true;
        // let object = {};
        this.rejectedItems = [{...item,srNo:1}]
      },
    },
    watch: {
      auditDialog(value) {
        if (value) {
          this.auditItems = [];
          this.searchAuditTrail(1);
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .v-card__actions {
    background-color: #fff;
  }
  .v-card__title {
    background: #23b1a9;
    color: white;
  }
  .v-application .text-h5 {
    font-size: 18px !important;
    font-weight: 500;
  }
  .elevation-1 {
    padding: 8px;
  }
  .v-application .elevation-1 {
    box-shadow: none !important;
  }
  main {
    display: inline-block;
  }
  .text__Orange {
    color: rgb(255, 108, 23) !important;
  }
  /* Saqeb Audit Trail */
  .tableicons {
    color: #23b1a9 !important;
  }
  .tablebtn {
    background: #ffffff 0% 0% no-repeat padding-box !important;
    box-shadow: inset 0px 2px 4px #00000052 !important;
    border: 0.5px solid #c1c8cc;
    border-radius: 18px !important;
    height: 30px !important;
    text-transform: capitalize !important;
    width: 100%;
    justify-content: space-between;
    font-size: small;
  }
  /* Saqeb Audit Trail */
  .scrollable-table {
    max-height: 500px;
    overflow-x: auto;
  }
  </style>
  