<template>
<v-app>
        <div style="background-color: #FFFFFF;" column class="">
        <v-layout class=" mt-2" >
            
                    <v-flex xs3>
                    <v-layout  column class=" mx-2 mt-2 cust_pr_quick cust-rmvpd-pr-quick">
                        <p class="font-weight-bold cstm-clr-pr-quick">
                            Consignment Recieved Date
                        </p>
                        
                        <v-menu
                            ref="calender"
                            :close-on-content-click="false"
                            v-model="bankCalenderDateFrom"
                            :nudge-right="40"
                            lazy
                            transition="scale-transition"
                            full-width
                            max-width="290px"
                            min-width="290px" 
                            class="rounded-0"
                            >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    :disabled = "disableValueOfBank"
                                    v-bind="attrs"
                                    v-on="on"
                                    label="dd/mm/yyyy"
                                    :value="computedBankDateForm"
                                    solo
                                    class="rounded-0"
                                    readonly
                                    :error-messages="bankGuranteeFromErrors"
                                    @input="$v.paymentBankDateFrom.$touch()"
                                    @blur="$v.paymentBankDateFrom.$touch()" >
                            <div  slot="prepend-inner" style="background-color:#F7F7F7;  padding: 8px !important"><span class="material-icons">date_range</span></div>
                                
                                </v-text-field>
                            </template>                        
                                    <v-date-picker
                                        v-model="paymentBankDateFrom" 
                                        @input="bankCalenderDateFrom = false"
                                        no-title
                                    ></v-date-picker>
                        </v-menu>
                        </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class=" mx-2 my-2 cust_pd">
                        <v-text class="font-weight-bold cstm-clr-pd">
                            Consignment Number
                        </v-text>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="PaymentAuthLimit"
                            :rules="[required()]"
                            class="rounded-0"
                            >
                        </v-text-field>
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class=" mx-2 my-2 cust_pd">
                        <v-text class="font-weight-bold cstm-clr-pd">
                            Inward ID
                        </v-text>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="PaymentAuthLimit"
                            :rules="[required()]"
                            class="rounded-0"
                            >
                        </v-text-field>
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class=" mx-2 my-2 cust_pd">
                        <v-text class="font-weight-bold cstm-clr-pd">
                            No of Documents
                        </v-text>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="PaymentAuthLimit"
                            :rules="[required()]"
                            class="rounded-0"
                            >
                        </v-text-field>
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class=" mx-2 my-2 cust_pd">
                        <v-text class="font-weight-bold cstm-clr-pd">
                            Claims ID
                        </v-text>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="PaymentAuthLimit"
                            :rules="[required()]"
                            class="rounded-0"
                            >
                        </v-text-field>
                    </v-layout>
                </v-flex>
                                            
            </v-layout>

            <v-layout class="mt-1 ">

                    <v-flex xs4>
                    <v-layout column class=" mx-2 my-2 cust_pd">
                        <v-text class="font-weight-bold cstm-clr-pd">
                            Remarks
                        </v-text>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="PaymentAuthLimit"
                            :rules="[required()]"
                            class="rounded-0"
                            >
                        </v-text-field>
                    </v-layout>
                </v-flex>
               
            </v-layout>
        
        <v-divider class=" mt-2 mb-3"></v-divider>

        <v-card class="rounded-0 mt-2" style="max-width:1000px">
        <v-card tile>
            <v-data-table
                :headers="headers"
                :items="GetData"
                hide-default-footer
                :page.sync="page"
                allow-overflow
                :items-per-page="itemsPerPage"
                @page-count="pageCount = $event"
                dense>
                <template v-slot:item.docName="{ item }">
                    <div>
                        
                      <a @click="routeOnClick(item)">{{ item.docName     }}</a> 
                        
                    </div>
                </template>
                <template v-slot:item.level="{ item }">
                    <div>
                        
                      <a @click="routeOnClick(item)">{{ item.level }}</a> 
                        
                    </div>
                </template>

                <template v-slot:item.docType="{item}" >
                    
                    <v-select
                        class="my-2 maxWis"
                        :items="documentItems"
                        dense
                        label="Select"
                        solo
                        v-model="item.docType"
                        hide-details="auto"
                    ></v-select>
                   
                   
            </template>

            <template v-slot:item.photoCopy="{item}" >
                    <v-select
                    
                    hide-details="auto"
                        class="my-2 maxWis"
                        solo
                        :items="photocopyItems"
                        dense
                        label="Select"
                        v-model="item.photoCopy"
                        item-value="text"
                    ></v-select>
            </template>

            <template v-slot:item.action = {item,value} >
                        
                        <tr >
                            <td style=" !important;">
                        
                            <v-btn @click="showdocs(item.indocs)" dark color="white" style="color:black" class="text-capitalize px-3 mx-2 my-2" small><v-icon dark small>remove_red_eye</v-icon> <v-divider vertical class="mx-2"></v-divider>View</v-btn>
                        
                        </td>
                        </tr>
                    </template>
            </v-data-table>
            <v-divider></v-divider>

           <v-layout row justify-end align-center class="mx-2">
                        <v-text class="mr-1 font-weight-bold body-1" style="color:#3C3D3D; font-size:12px !important">
                                <span class="" style="color:#E46A25;font-size:12px;">Showing {{fval}}-{{sval}} </span>out of {{totalLen }} records

                        </v-text>
                        <v-divider  class="mx-2" vertical ></v-divider>
                        <v-pagination 
                            class="cust-bnkAcc-Permission"
                            v-model="page"
                            color="#152F38"
                            flat
                            :total-visible="9"
                            @input="nextPageBtn(page)"
                            :length="pageLength"
                            ></v-pagination>
                    </v-layout>
        </v-card>
        </v-card>

        <v-divider class="mt-5 mb-2"></v-divider>

            <v-layout class="mb-3">
                <v-flex xs3>
                    <v-layout column class=" mx-2 my-2 cust_pd">
                        <v-text class="font-weight-bold cstm-clr-pd">
                            Claim type
                        </v-text>
                        <v-select
                            :items="lobItems"
                            label="Select"
                            v-model="lob"
                            solo
                            dense
                            class="rounded-0"
                            :rules="[required()]"
                            >
                        </v-select>
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class=" mx-2 my-2 cust_pd">
                        <v-text class="font-weight-bold cstm-clr-pd">
                            Claim subtype
                        </v-text>
                        <v-select
                            :items="lobItems"
                            label="Select"
                            v-model="lob"
                            solo
                            dense
                            class="rounded-0"
                            :rules="[required()]"
                            >
                        </v-select>
                    </v-layout>
                </v-flex>

                <v-flex xs3></v-flex>
                <v-flex xs3></v-flex>
                <v-flex xs3></v-flex>
            </v-layout>

            <v-card style="max-height:150px" class=" rounded-0">
                <div class="mx-2 pa-2" style=" display:flex;justify-content:space-between;"  >
                <v-layout style="flex:1" justify-end >
                <v-btn dark  tile color="#152F38" class="text-capitalize px-3 mx-2 my-2" small><v-icon dark small>mdi-account-check</v-icon> <v-divider vertical class="mx-2"></v-divider>Assign to supervisor</v-btn>
                <v-btn dark  tile color="#D1121B" class="text-capitalize px-3 mx-2 my-2" small><v-icon dark small>cancel</v-icon> <v-divider vertical class="mx-2"></v-divider>Reject</v-btn>
                <v-btn dark  tile color="#23B1A9" class="text-capitalize px-3 mr-2 ml-2 my-2" small><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider> Save as Draft</v-btn>
                <v-btn dark  tile color="#23B1A9" class="text-capitalize px-3 mx-2 my-2" small><v-icon dark small>arrow_forward</v-icon> <v-divider vertical class="mx-2"></v-divider>Submit</v-btn>
                </v-layout>
                </div>
            </v-card>
     </div> 
    </v-app>
</template>


<script>
  import moment from 'moment';
export default {
    created(){
        this.$store.commit("SAVE_PAGE_HEADER", "Inward Assessor");
    },

    computed:{
        
    },

    data() {
        return {
            bankCalenderDateFrom:false,
            Level:'',
            lob:'',
            paymentBankDateFrom:'',
            lobItems:['PA','Health'],
           GetData:[
                {
                    SerialNumber:"1",
                    docID:"1234567890",
                    docName:"Doc1.pdf",
                    docDate:"25/03/2021",
                    docType:"",
                    photoCopy:"",
                    action:"",
                    indocs:{
                        fileUrl:'https://naomisimson.com/wp-content/uploads/image-placeholder.jpg'
                    }
                },{
                    SerialNumber:"1",
                    docID:"1234567890",
                    docName:"Doc1.pdf",
                    docDate:"25/03/2021",
                    docType:"",
                    photoCopy:"",
                    action:"",
                    indocs:{
                        fileUrl:'https://naomisimson.com/wp-content/uploads/image-placeholder.jpg'
                    }
                },
            ],
            headers:[
                
                {text: 'Sr. No.', value: 'SerialNumber',sortable: false,divider:true,class:"hdr-txt-clr_FALMS hdr-bg-clr_inwards srWidth"},
                {text: 'Document ID', value: 'docID',sortable: false,divider:true,class:"hdr-txt-clr_FALMS hdr-bg-clr_inwards"},
                {text: 'Document Name', value: 'docName',sortable: false,divider:true,class:"hdr-txt-clr_FALMS hdr-bg-clr_inwards"},
                {text: 'Document Date', value: 'docDate',sortable: false,divider:true,class:"hdr-txt-clr_FALMS hdr-bg-clr_inwards"},
                {text: 'Document Type', value: 'docType',sortable: false,divider:true,class:"hdr-txt-clr_FALMS hdr-bg-clr_inwards"},
                {text: 'Photocopy', value: 'photoCopy',sortable: false,divider:true,class:"hdr-txt-clr_FALMS hdr-bg-clr_inwards"},
                {text: 'Action', value: 'action',sortable: false,divider:true,class:"hdr-txt-clr_FALMS hdr-bg-clr_inwards acWidth"},
            ],
            required(){
                return v=>v && v.length>0 || "This Field is Required"
            },
            documentItems:['Claim Form','Health card','PAN card','Aadhar card','Bill','Discharge Summary','Cancelled Cheque'],
            photocopyItems:['Yes','No']
    };
    },
    methods:{

        getextension(filepath){
            var ext = /^.+\.([^.]+)$/.exec(filepath);
            return ext == null ? "" : ext[1];
        },
       
        showdocs(indoc){
            console.log("showing docs is ->",indoc);
            try {
                var typeis =  this.getextension(indoc.fileName)
            // var myimg = new Image()
            // myimg.onload = function(){      
                // myimg.style.display = 'block';
                // myimg.style.width=200+"px";
                // myimg.style.height=200+"px";
                // var url=myimg.getAttribute('src');
                // window.open(indoc.fileUrl,'_blank');
                console.log('the doc type is ',typeis);
                if(typeis == 'pdf' || typeis =='PDF'){
                     window.open(indoc.fileUrl,"_blank");

                }else{
                    
                const image_window = window.open("", "_blank")
                    image_window.document.write(`
                        <html>
                            <head>
                            </head>
                            <body>
                            <img src="`+indoc.fileUrl+`" alt="Example" height="100%" width="100%">
                            </body>
                        </html>
                `);
                }


            } catch (error) {
                
            }
   
            // };
            
            // myimg.src = indoc.fileUrl
            
        },

    },

    computed:{
        computedBankDateForm() {
            console.log(this.paymentBankDateFrom)
            console.log(this.paymentBankDateFrom ? moment(this.paymentBankDateFrom).format("DD-MM-YYYY"): '')
            return this.paymentBankDateFrom ? moment(this.paymentBankDateFrom).format("DD-MM-YYYY"): ''
         },

         bankGuranteeFromErrors(){
             const errors = [];
                if (this.disableValueOfBank === false){
                    
                    if (!this.$v.paymentBankDateFrom.$dirty) return errors
                    !this.$v.paymentBankDateFrom.required && errors.push('This field is required');
                     return errors
                }else{
                    return errors;
                }
        },
    }
    
}
</script>

<style>

.cust-rmvpd-psins3 .v-text-field.v-text-field--enclosed>.v-input__control>.v-input__slot {
    padding-right: 0px !important;
}
.maxWis{width: 150px;}
.cust_pr2 .v-text-field .v-input__slot,
.v-text-field--outline .v-input__slot {
    min-height: 42px !important;
    display: flex !important;
    align-items: center !important;
    /* border:1px red solid; */
}

.cust_pr_quick .v-input .v-label {
    font-size: 13px !important;
    
}


.cust_pr_quick .v-input{
    font-size: 13px !important;
}

.cstm-clr-pr-quick{
    color:#646666 !important;
    margin-bottom: 5px !important;
    font-size: 13px !important;
    
}

.cust_pd1 .v-text-field .v-input__slot,
.v-text-field--outline .v-input__slot {
    min-height: 42px !important;
    display: flex !important;
    align-items: center !important;
    /* border:1px red solid; */
}

.cust-rmvpd-pr-quick .v-text-field.v-text-field--enclosed>.v-input__control>.v-input__slot {
    padding: 0px !important;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
    font-size: 0.84rem !important;
    
}

.hdr-txt-clr_FALMS{
    color: white !important;
    font-weight: bold;
    /* padding:0px!important;
    padding: 10px !important;
    padding-left: 14px !important; */
}

.hdr-bg-clr_inwards{
  background-color: #01BBD9;
}
.headercolor{
    background-color: rgb(248, 240, 240);
}
.cardfont{
    font-size: 0.84rem !important;
    padding-right: 2px;
}

.srWidth{
    width: 100px;
}

.acWidth{
    width: 150px;
}



</style>