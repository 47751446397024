<template>
    <v-container class="containerStyle">
        <!--policy Number-->
        <v-layout class="mt-3">
          <v-flex xs3>
            <v-layout column class="ml-4">
              <p class="mb-2 claimlabel">Policy Number</p>
              <v-text-field
                placeholder="Enter..."
                solo
                dense
                :rules='[alphaNumeric(clientMasteObj.policyNumber)]'
                v-model="clientMasteObj.policyNumber"
                class="forminput"></v-text-field>
            </v-layout>
          </v-flex>

          <v-flex xs3>
            <v-layout column class="ml-4">
              <p class="mb-2 claimlabel">Insured Name</p>
              <v-text-field
                placeholder="Enter..."
                solo
                dense
                v-model="clientMasteObj.insuredName"
                class="forminput"></v-text-field>
            </v-layout>
          </v-flex>

          <v-flex xs3>
            <v-layout class="ml-4" column>
              <p class="mb-2 claimlabel">Insured ID</p>
              <v-text-field
                placeholder="Enter..."
                solo
                dense
                :rules='[alphaNumeric(clientMasteObj.insuredID)]'
                v-model="clientMasteObj.insuredID"
                class="forminput"></v-text-field>
            </v-layout>
          </v-flex>

          <v-flex xs3>
            <v-layout column class="ml-4">
              <p class="mb-2 claimlabel">Settling Office</p>
              <!-- <v-text-field
                placeholder="Enter..."
                solo
                dense
                color="white"
                v-model="clientMasteObj.settlingOffice"
                class="forminput"></v-text-field> -->
                <v-autocomplete
                    v-model="clientMasteObj.settlingOffice"
                    hide-no-data
                    cache-items
                    hide-selected
                    placeholder="Start typing to Search"
                    :search-input.sync="searchSettlingOffice"
                    item-text="settlementOfficeName"
                    :items="settling_OfficeArr"
                    maxlength=6
                    solo
                    dense
                    class="forminput"
                    clearable
                ></v-autocomplete>
            </v-layout> 
          </v-flex>      
        
          <v-flex xs3>
          </v-flex>
        </v-layout>

        <!-- Examiner Id -->
        <v-layout>
          <v-flex xs3>
            <v-layout column class="ml-4">
                <p class="mb-2 claimlabel">Examiner ID</p>
                <v-text-field
                placeholder="Enter..."
                solo
                dense
                :rules='[alphaNumeric(clientMasteObj.examinerID)]'
                v-model="clientMasteObj.examinerID"
                class="forminput"></v-text-field>
                
            </v-layout>
          </v-flex>

          <v-flex xs3>
            <v-layout column class="ml-4">
              <p class="mb-2 claimlabel">Examiner Name</p>
              <!-- <v-text-field
                placeholder="Enter..."
                solo
                dense
                v-model="clientMasteObj.examinerName"
                class="forminput"></v-text-field> -->
                <v-autocomplete
                    hide-no-data
                    cache-items
                    hide-selected
                    placeholder="Start typing to Search"
                    maxlength=6
                    solo
                    dense
                    :rules='[alphaNumeric(clientMasteObj.examinerId)]'
                    v-model="clientMasteObj.examinerId"
                    class="forminput"
                    clearable
                ></v-autocomplete>
            </v-layout>
          </v-flex>

          <v-flex xs3>
              <v-layout column class="ml-4">
              <p class="mb-2 claimlabel">Contact No</p>
               <!-- v-model="clientMasteObj.contactNo" -->
              <v-text-field
                placeholder="Enter..."
                solo
                dense
                :rules='[numeric(clientMasteObj.contactNo)]'
               
                class="forminput"></v-text-field>
              </v-layout>
          </v-flex>

          <v-flex xs3>
              <v-layout column class="ml-4">
                  <!-- v-model="clientMasteObj.emailID" -->
              <p class="mb-2 claimlabel">Email ID</p>
              <v-text-field
                placeholder="Enter..."
                solo
                dense
                class="forminput"></v-text-field>
              </v-layout>
          </v-flex>

          <v-flex xs3>
          </v-flex>
        </v-layout>

        <v-layout class="assisLayoutStyle">
            <p class="mt-4 ml-4 whiteColor">ASSIGNMENT BASIS</p>
        </v-layout>
        
        <!-- radiobuttons -->
        <v-layout class="">
            <!-- <p class="mb-2 claimlabel">Basis Settling Office Mapping</p> -->
            <v-radio-group
                row
                v-model="assignmentbasis"
                class="pt-4 topdiv ml-4"
                id="radiogrp">
                <v-radio
                    v-for="opt in assignmentbasisArr"
                    :key="opt"
                    :label="`${opt}`"
                    :value="opt"
                    @click="changeAssisValues(opt)"
                    class="mb-0 claim">
                </v-radio>
            </v-radio-group>
        </v-layout>

        <!-- button search--reset--create -->
        <v-layout class="mx-4 my-4 buttonRowstyle">
            <v-row>
                <v-col align="right" class="mt-8">
                    <v-btn
                    color="#E46A25"
                    @click.prevent="searchMCM()"
                    class="buttonscolor mr-5">
                    <v-img
                        class="mr-2"
                        src="../../assets/images/psearch.png"
                        height="20"
                        width="20"
                        style="">
                    </v-img>
                    <div class="vertical_line"></div>Search
                    </v-btn>

                    <v-btn
                    color="#E46A25"
                    @click.prevent="resetValues()"
                    class="buttonscolor mr-5">
                    <v-icon class="mr-2" style="font-size: 19px">mdi-close-circle</v-icon>
                    <div class="vertical_line"></div>Reset
                    </v-btn>

                    <v-btn
                    color="#152F38"
                    @click.prevent="createClientMaster()"
                    class="buttonscolor mr-5">
                    <v-icon class="mr-2" style="font-size: 20px">mdi-plus</v-icon>
                    <div class="vertical_line"></div>Create
                    </v-btn>
                </v-col>
            </v-row>
        </v-layout>

        <!-- table data -->
        <template>
            <v-data-table 
                :headers="clientMasterTableHeader"
                :items="clientMasterTable"
                hide-default-footer
                class="mcmDatatable dataTblStyle">
                <template v-for="header in clientMasterTableHeader.filter((header) => header.hasOwnProperty('formatter'))" v-slot:[`item.${header.value}`]="{ value } ">
                    {{ header.formatter(value)}} 
                </template>
                    <template  v-slot:item="{ item, index }"> 
                       <tr  class="cursorStyle">
                            <td class="text-left">{{ index + 1}}</td>
                            <td class="colborder">{{ item.policyNumber }}</td>
                            <td class="colborder">{{ item.insuredName }}</td>
                            <td class="colborder">{{ item.examinerName }}</td>
                            <td class="colborder tdStyle">
                                <v-btn class="tablebtn mr-5"  @click.prevent="viewTableValues(item)">
                                    <v-icon class="mr-2 fontClass" > mdi-eye </v-icon>
                                    <div class="vertical_line borderStyle" ></div>View</v-btn>
                                <v-btn class="tablebtn" @click.prevent="modifyTableValues(item)">
                                    <v-icon class="mr-2 fontClass" > mdi-pencil </v-icon>
                                    <div class="vertical_line borderStyle"></div>Modify</v-btn>
                            </td>
                            <!-- <td class="colborder">{{ item.policyNumber }}</td> -->
                       </tr>    
                   </template> 
            </v-data-table>
        </template>        
      
        <!-- dialog box to create function-->
        <v-row justify="center">
            <v-dialog v-model="openDialogbox" persistent max-width="60%" class="borderRadiusCls">
                <v-card>
                <v-card-title class="pl-0 px-0 py-0 titleStyle">
                    <v-row>
                    <span class="descrptionSpan mb-5 ml-8">Create Major Client</span>
                    <v-icon @click="openDialogbox = false" class="ml-7 mt-0">mdi-window-close</v-icon>
                    </v-row>
                    <v-card-text class="backgroundStyle">
                        <v-form  ref="cmcform" @submit.prevent="saveCMC()">
                            <v-flex class="flexStyle">
                                <v-layout class="mt-2">
                                    <v-flex xs4>
                                        <v-layout column class="ml-3">
                                        <!-- <p class="mb-2 claimlabel">Tata Motor Policy</p> -->
                                            <v-checkbox
                                                @change="toCheckTMPConditions()"
                                                v-model="mcmData.tataMotorPolicy"
                                                label="Tata Motor Policy"
                                                class="pl-2 mt-7 checkBoxStyle"
                                                dense
                                                :disabled="mcmDataDisable"
                                                color="#23B1A9">
                                            </v-checkbox>
                                        </v-layout>
                                    </v-flex>                                

                                    <v-flex xs4>
                                        <v-layout column class="ml-3">
                                        <p class="mb-2 claimlabel">Types of Tata Motor Policy<span class="mandatorycolor" v-if="mcmData.tataMotorPolicy == true"> *</span></p>
                                         <v-select
                                            label="Select"
                                            solo
                                            dense                                       
                                            v-model="mcmData.typeOfTataMotorsPolicy"
                                            :rules='[mandateRuleOnTMP()]'                                         
                                            :items="tataMotors"  
                                            :background-color="mcmDataDisable == true ? '#f5f5f5':'#FFFFFF'" 
                                            :class="mcmDataDisable == true ? 'font-weight-bold' : ''"
                                            :disabled="mcmDataDisable"                                         
                                            class="forminput textDisable"></v-select>
                                        </v-layout>
                                    </v-flex>

                                    <v-flex xs4>
                                        <v-layout column class="ml-3 mr-3">
                                        <p class="mb-2 claimlabel">Dealer Location <span class="mandatorycolor" v-if="mcmData.tataMotorPolicy == true"> *</span></p>
                                        <v-autocomplete
                                            placeholder="Start typing to Search"
                                            maxlength=6
                                            solo
                                            dense
                                            :search-input.sync="dealerLocSearch"
                                            :items="dealerLocItems"
                                            :class="mcmDataDisable == true ? 'font-weight-bold' : ''"
                                            :background-color="mcmDataDisable == true ? '#f5f5f5':'#FFFFFF'"
                                            item-text="cityDistrictName"
                                            :disabled="mcmDataDisable"
                                            v-model="mcmData.dealerLocation"
                                            :rules='[mandateRuleOnTMP()]'
                                            class="forminput textDisable"
                                            clearable
                                        ></v-autocomplete>
                                        </v-layout>
                                    </v-flex>
                                </v-layout>                           
                                
                                <v-layout class="mt-2">
                                    <v-flex xs4>
                                        <v-layout column class="ml-3">
                                        <p class="mb-2 claimlabel">Dealer Name<span class="mandatorycolor" v-if="mcmData.tataMotorPolicy == true"> *</span></p>
                                        <v-text-field
                                            placeholder="Enter..."
                                            solo
                                            v-model="mcmData.dealerName"
                                            dense
                                            :background-color="mcmDataDisable == true ? '#f5f5f5':'#FFFFFF'"
                                            :class="mcmDataDisable == true ? 'font-weight-bold' : ''"
                                            :disabled="mcmDataDisable"
                                            :rules='[mandateRuleOnTMP(), alphaNumeric(mcmData.dealerName)]'
                                            class="forminput textDisable"></v-text-field>
                                        </v-layout>
                                    </v-flex>                                

                                    <v-flex xs4>
                                        <v-layout column class="ml-3">
                                        <p class="mb-2 claimlabel">Policy Number<span class="mandatorycolor"> *</span></p>
                                        <v-text-field
                                            placeholder="Enter..."
                                            solo
                                            dense
                                            :background-color="mcmDataDisable == true ? '#f5f5f5':'#FFFFFF'"
                                            :class="mcmDataDisable == true ? 'font-weight-bold' : ''"
                                            :disabled="mcmDataDisable"
                                            v-model="mcmData.policyNumber"
                                            :rules='[madatoryRule()]'
                                            class="forminput textDisable"></v-text-field>
                                        </v-layout>
                                    </v-flex>

                                    <v-flex xs4>
                                        <v-layout column class="ml-3 mr-3">
                                        <p class="mb-2 claimlabel">Insured Name<span class="mandatorycolor"> *</span></p>
                                        <v-text-field
                                            placeholder="Enter..."
                                            solo
                                            dense
                                            :background-color="mcmDataDisable == true ? '#f5f5f5':'#FFFFFF'"
                                            :class="mcmDataDisable == true ? 'font-weight-bold' : ''"
                                            :disabled="mcmDataDisable"
                                            v-model="mcmData.insuredName"
                                            :rules='[madatoryRule()]'
                                            class="forminput textDisable"></v-text-field>
                                        </v-layout>
                                    </v-flex>
                                </v-layout>  

                                <v-layout class="mt-2">
                                    <v-flex xs4>
                                        <v-layout column class="ml-3">
                                        <p class="mb-2 claimlabel">Insured ID<span class="mandatorycolor"> *</span></p>
                                        <v-text-field
                                            placeholder="Enter..."
                                            solo
                                            dense
                                            :background-color="mcmDataDisable == true ? '#f5f5f5':'#FFFFFF'"
                                            :class="mcmDataDisable == true ? 'font-weight-bold' : ''"
                                            :disabled="mcmDataDisable"
                                            :rules='[madatoryRule()]'
                                            v-model="mcmData.insuredID"
                                            class="forminput textDisable"></v-text-field>
                                        </v-layout>
                                    </v-flex>                                

                                    <v-flex xs4>
                                        <v-layout column class="ml-3">
                                        <p class="mb-2 claimlabel">Contact No</p>
                                        <!-- v-model="clientMasteObj.contactNo" -->
                                        <v-text-field
                                            placeholder="Enter..."
                                            solo
                                            dense
                                            
                                            class="forminput"></v-text-field>
                                        </v-layout>
                                    </v-flex>

                                    <v-flex xs4>
                                        <v-layout column class="ml-3 mr-3">
                                            <!-- v-model="clientMasteObj.emailID" -->
                                        <p class="mb-2 claimlabel">Email ID</p>
                                        <v-text-field
                                            placeholder="Enter..."
                                            solo
                                            dense
                                            class="forminput"></v-text-field>
                                        </v-layout>
                                    </v-flex>
                                </v-layout>   

                                <v-layout class="assisLayout">
                                    <p class="mt-2 ml-4 whiteColor">ASSIGNMENT BASIS</p>
                                </v-layout> 

                                <!-- radiobuttons -->
                                <v-layout class="bottomline">
                                    <!-- <p class="mb-2 claimlabel">Basis Settling Office Mapping</p> -->
                                    <v-radio-group
                                        row
                                        v-model='assignmentBasis'
                                        class="pt-2 topdiv ml-3"
                                        id="radiogrp">
                                        <v-radio
                                            v-for="opt in assignmentbasisArr"
                                            :key="opt"
                                            :label="`${opt}`"
                                            :value="opt"
                                            :rules="assignmentBasisRule"
                                            :disabled='disableOnTataMotorPolicy || mcmDataDisable'
                                            class="mb-0 py-2 pl-1 pr-0 mr-3 basisradiobtns"
                                            @click="checkBasisValue(opt)">
                                        </v-radio>
                                    </v-radio-group>
                                    
                                </v-layout> 

                                <v-layout class="mt-2">
                                    <v-flex xs4>
                                        <!-- :style="assignmentBasis == 'Basis Settling office mapping' ? 'background: #eeeeee' :'background: #eeeeee'" -->
                                        <v-layout column class="ml-3">
                                        <p class="mb-2 claimlabel">Settling Office</p>
                                        <v-autocomplete
                                            v-model="settlingOfficeName"
                                            placeholder="Start typing to Search"
                                            :search-input.sync="searchOffice"
                                            item-text="settlementOfficeName"
                                            :items="settlingOfficeItems"
                                            :background-color="mcmDataDisable == true ? '#f5f5f5':'#FFFFFF'"
                                            :class="mcmDataDisable == true ? 'font-weight-bold' : ''"
                                            :disabled='disableOnBasisSettligOff || mcmDataDisable'
                                            solo
                                            dense
                                            class="forminput"
                                            clearable>
                                            <template v-slot:append class="my-0 mr-2" >
                                                <v-icon class="blueiconstyle" @click="addSettlingOffice()">mdi-plus</v-icon>
                                            </template>
                                        </v-autocomplete>
                                       
                                        </v-layout>
                                    </v-flex>     

                                    <v-flex xs7 class="countryListFlex ml-4 mt-5"> 
                                         <v-btn-toggle v-model="toggledOffice" active-class="active_btn">
                                            <v-btn
                                            medium
                                            dense
                                            class="countrylistAllbuttons"
                                            @click="toggleOffice(item.name, index)"
                                            :class="isBtnActive(index)"
                                            v-for="(item,index) in mcmData.settlingOffice"
                                            :key="item.name">
                                            {{ item.name}}
                                            </v-btn>
                                        </v-btn-toggle>
                                    </v-flex>                                 

                                </v-layout>
                                <!-- to show the values of toggled office -->
                                <div v-for="(item, index) in  mcmData.settlingOffice" :key="item.name"> 
                                    <div v-if="toggledOffice == index">
                                        <v-layout class="bottomline">
                                            <v-flex xs4>
                                                <v-layout column class="ml-3">
                                                    <!-- v-model="mcmData.product"
                                                    :rules='[alphaNumeric(mcmData.product)]' -->
                                                <p class="mb-2 claimlabel">Product</p>
                                                <v-text-field
                                                    placeholder="Enter..."
                                                    solo
                                                    dense
                                                    :background-color="mcmDataDisable == true ? '#f5f5f5':'#FFFFFF'"
                                                    :class="mcmDataDisable == true ? 'font-weight-bold' : ''"
                                                    :disabled="mcmDataDisable"
                                               
                                                    class="forminput textDisable"></v-text-field>
                                                </v-layout>
                                            </v-flex>
                                        </v-layout>

                                        <v-layout class="mt-2">
                                            <v-flex xs4>
                                                <v-layout column class="ml-3">
                                                <p class="mb-2 claimlabel">Examiner ID<span class="mandatorycolor"> *</span></p>
                                                <v-autocomplete
                                                    
                                                    hide-no-data
                                                    cache-items
                                                    hide-selected
                                                    placeholder="Start typing to Search"
                                                    maxlength=6
                                                    solo
                                                    dense
                                                    :search-input.sync="searchExaminer"
                                                    item-text="settlementOfficeName"
                                                    :items="settlingOfficeItems"
                                                    :background-color="mcmDataDisable == true ? '#f5f5f5':'#FFFFFF'"
                                                    :disabled="mcmDataDisable"
                                                    :rules='[madatoryRule()]'
                                                    class="forminput"
                                                    clearable
                                                ></v-autocomplete>
                                                </v-layout>
                                            </v-flex>

                                            <v-flex xs4>
                                                <v-layout column class="ml-3">
                                                <p class="mb-2 claimlabel">Examiner Name<span class="mandatorycolor"> *</span></p>
                                                <v-autocomplete
                                                   
                                                    hide-no-data
                                                    cache-items
                                                    hide-selected
                                                    placeholder="Start typing to Search"
                                                    maxlength=6
                                                    solo
                                                    dense
                                                    :background-color="mcmDataDisable == true ? '#f5f5f5':'#FFFFFF'"
                                                    :disabled="mcmDataDisable"
                                                    :rules='[madatoryRule()]'
                                                    class="forminput"
                                                    clearable
                                                ></v-autocomplete>
                                                </v-layout>
                                            </v-flex>    

                                            <v-flex>                
                                                <v-btn
                                                    color="#01bbd9"
                                                    style="cursor: pointer;"
                                                    class="plusbutton mr-5 mt-7 ml-4">
                                                    <v-icon class="mr-0 plusFont">mdi-plus</v-icon>
                                                </v-btn>
                                            </v-flex>
                                            <!-- <v-btn
                                                color="#D1121B"
                                                style="cursor: pointer;"
                                                class="plusbutton mr-5 mt-7 ml-4 mb-6">
                                                <v-icon class="mr-0 plusFont">mdi-trash-can-outline</v-icon>
                                            </v-btn> -->
                                        </v-layout>                              

                                        <v-simple-table
                                            class="ml-0 mt-5 mb-6 examinerTablewidth">
                                            <template v-slot:default>
                                            <thead class="examinertableBackground">
                                                <tr>                    
                                                    <th class="lr1th" >Sr. No.</th>
                                                    <th class="vlratbletd lr2th" >Examiner ID</th>
                                                    <th class="vlratbletd lr3th">Examiner Name</th>
                                                    <th class="vlratbletd pa-0">
                                                        <v-btn
                                                        color="#D1121B"
                                                        dark
                                                        class="lrDelete">
                                                        <v-icon class="mr-2 deleteAllIconTh" @click="deleteAllExaminers()">mdi-trash-can-outline</v-icon>
                                                        <!-- <div class="mr-2 vertical_line"></div>All -->
                                                        </v-btn>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <!-- :style="mcmData.settlingOffice.examiners.length = 4 ? 'height=200px' : 'height=00px'"  -->
                                            <tbody class="TableScroll" >
                                                <tr v-for="(item, index) in mcmData.settlingOffice" :key="index">
                                                <td class="lr1td">{{ index + 1 }}</td>
                                                <td class="invoiceDatatd lr2td">{{item.examinerId}}</td> 
                                                <td class="invoiceDatatd lr3td">{{item.examinerId}}</td>
                                                <td>
                                                    <v-icon class="mr-2 deleteIconTd" @click="deleteExaminer(index)">mdi-trash-can-outline</v-icon>
                                                </td> 
                                                </tr>
                                            </tbody>
                                            </template>
                                        </v-simple-table>
                                    </div>
                                </div>
                            </v-flex>
                            <v-row>
                                <v-col align="center" class="mt-3 mb-3">
                                    <v-btn
                                        color="#23B1A9"
                                        style="cursor: pointer;"
                                        type='submit'
                                        :disabled="mcmDataDisable"
                                        class="buttonscolor mr-5">
                                        <v-icon class="mr-2" style="font-size: 19px">mdi-checkbox-marked-circle</v-icon>
                                        <div class="vertical_line"></div>Save
                                    </v-btn>

                                    <v-btn
                                        color="#D1121B"
                                        :disabled="mcmDataDisable"
                                        @click.prevent="clearCMC()"
                                        class="buttonscolor mr-5">
                                        <v-icon class="mr-2" style="font-size: 19px">mdi-trash-can-outline</v-icon>
                                        <div class="vertical_line"></div>clear
                                    </v-btn>

                                    <v-btn
                                        color="#E46A25"
                                        @click="openDialogbox = false"
                                        class="buttonscolor mr-5">
                                        <v-icon class="mr-2" style="font-size: 19px">mdi-close-circle</v-icon>
                                        <div class="vertical_line"></div>Close
                                    </v-btn>

                                    <v-btn
                                        color="#D1121B"
                                        :disabled="deletebtndisable"
                                        @click.prevent="deleteCMC()"
                                        class="buttonscolor mr-5">
                                        <v-icon class="mr-2" style="font-size: 20px">mdi-trash-can-outline</v-icon>
                                        <div class="vertical_line"></div>Delete
                                        </v-btn>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>

                </v-card-title>
                </v-card>
            </v-dialog>
        </v-row>
        
    </v-container>
</template>

<script>

import axios from "axios";


    export default {

        data(){
            return{

                validationmessage:'This field is required',
                Examiners:[
                    {a:'dsfsd'},
                     {a:'dsfsd'},
                      {a:'dsfsd'},
                      {a:'dsfsd'},
                     {a:'dsfsd'},
                      {a:'dsfsd'}

                ],

                rules:{
                    insuredName:[
                    // v => !!v || this.validationmessage,
                    // v => v && !!v.trim() || 'Only spaces are not allowed',
                    v => (v && v.length <= 140) || 'Insured Name should be less than 140 characters.',
                    v => /^(?=[A-Za-z0-9.',-\h])([A-Za-z0-9.',\s-\h]*)(?<=[A-Za-z0-9.',-\h])$/.test(v) || 'Insured Name is not valid'
                    ]
                },

                clientMasteObj:{
                    policyNumber:'',
                    insuredName:'',
                    insuredID:'',
                    settlingOffice:'',
                    examinerID:'',
                    examinerName:'',
                    // contactNo:'',
                    // emailID:'',
                    basisSettlingOfficeMapping:'',
                    basisPanIndia:'',
                    basisIssuingToSettlingOffice:'', 
                },

                mcmData:{
                //values in dialog box to create CMC
                    tataMotorPolicy:false,
                    typeOfTataMotorsPolicy:'',
                    dealerLocation:'',
                    dealerName:'',
                    policyNumber:'',
                    insuredName:'',
                    insuredID:'',
                    settlingOffice:[
                        // {"settlingOfficeName":"Andheri","ProductName":"MarineEvop","Examiners":[{"examinerId":"AG101","examinerName":"Admin"}]},
                    ],
                    examinerID:'',
                    examinerName:'',
                    basisSettlingOfficeMapping:false,
                    basisPanIndia:false,
                    basisIssuingToSettlingOffice:false,                   
                    // product:'',
                    // _id:''
                              
                },
                

                toggledOffice:'',//(v-model of the buttons shown beside settling office)
                toggledOfficeName:'',

                dealerLocSearch:'',//serach input DL
                dealerLocItems : [],
                searchExaminer:'',

                searchOffice:'',
                searchSettlingOffice:'',
                settlingOfficeItems:[],   //used in create major client master
                settling_OfficeArr:[],
                settlingOfficeName:'',//used in create client master(add settlingoffice)            

                assignmentBasis:'',
                assignmentbasis:'',
                // assignmentbasisArr :[{text:'Basis Settling office mapping',ABvalue:false},{text:'Basis Pan India',ABvalue:false},
                // {text:'Basis Issuing to settling office',ABvalue:false}],
                assignmentbasisArr :['Basis Settling office mapping','Basis Pan India','Basis Issuing to settling office'],
                tataMotors:['CVBU', 'PCBU'],
                //values in dialog box end            

                openDialogbox:false,
                mcmDataDisable:false,
                deletebtndisable:false,
                addOfficeDialog:false,
               
                //table data
                clientMasterTableHeader:[
                    {text: 'Sr. No.', align: 'start',value: '', class:"headercolor"},
                    {text: 'Policy number', align: 'start',value: 'policyNumber', class:"headercolor"},
                    {text: 'Insured name', align: 'start',value: 'insuredName', class:"headercolor"},
                    {text: 'Examiner', align: 'start',value: 'examinerOneName', class:"headercolor"},
                    {text: 'Action', align: 'end',value: '', class:"headercolor"},
                ],
                clientMasterTable:[],
                // table data end               
            }        
        },

        computed:{
            disableOnTataMotorPolicy(){
                return this.mcmData.tataMotorPolicy == true ? true : false;
            },

            disableOnBasisSettligOff(){
                return this.assignmentBasis == 'Basis Settling office mapping' ? true : false;
            },

            // checkLength(){
            //     return this.mcmData.settlingOffice.examiners.length > 0 ? true :false;
            // },

            DLitems(){
                return this.dealerLocItems
            },

            officeItems(){
                return this.settlingOfficeItems
            }
        },

        methods:{

            //validations
            alphaNumeric(v){
                let exp = /^\S[a-z0-9A-Z]+\S$/
                if(v && !(exp.test(v))){
                    return 'This field is not valid'
                }else{
                    return true;
                }
            },

            numeric(v){
                let exp = /^[0-9]+$/
                if(v && v.length > 0){
                    if(!(exp.test(v))){
                        return 'Only number are allowed'
                    }else{
                        return true;
                    }
                }else{
                    return true;
                }
            },

            madatoryRule(){
                return v => !!v || this.validationmessage;
            },

            assignmentBasisRule(){
                if(this.mcmData.basisSettlingOfficeMapping !== "" || this.mcmData.basisPanIndia !== "" ||
                this.mcmData.basisIssuingToSettlingOffice !== ""){
                    return "Select any one of these Assignment Basis"
                }
            },

            mandateRuleOnTMP(){
                if(this.tataMotorPolicy == true){
                    return this.validationmessage;
                }else{
                    return true;
                }
            },          
            //end validations
            changeAssisValues(value){
                if(value == "Basis Settling office mapping"){
                    this.clientMasteObj.basisSettlingOfficeMapping = true;
                    this.clientMasteObj.basisIssuingToSettlingOffice = false;
                    this.clientMasteObj.basisPanIndia = false; 
                    console.log('=====1',this.clientMasteObj .basisSettlingOfficeMapping);
                }else if(value == "Basis Pan India"){
                    this.clientMasteObj.basisPanIndia = true;  
                    this.clientMasteObj.basisIssuingToSettlingOffice = false;
                    this.clientMasteObj.basisSettlingOfficeMapping = false;
                    console.log('=====2',this.clientMasteObj.basisPanIndia);
                }else{
                    this.clientMasteObj.basisIssuingToSettlingOffice = true;
                    this.clientMasteObj.basisSettlingOfficeMapping = false;
                    this.clientMasteObj.basisPanIndia = false;  

                    console.log('=====3',this.clientMasteObj.basisIssuingToSettlingOffice);
                }
                console.log('=====',this.clientMasteObj);
            }, 

            searchMCM(){
                // console.log('---------->searching',JSON.stringify(this.clientMasteObj))
                    this.showProgress("Claim is searching, Please wait");
                let reqData = "pageNo=1&perPage=10&policyNumber=" +this.clientMasteObj.policyNumber + "&insuredName="+ this.clientMasteObj.insuredName+
                    "&insuredID="+ this.clientMasteObj.insuredID + "&basisSettlingOfficeMapping="+ this.clientMasteObj.basisSettlingOfficeMapping+
                    "&basisPanIndia="+ this.clientMasteObj.basisPanIndia + "&basisIssuingToSettlingOffice="+ this.clientMasteObj.basisIssuingToSettlingOffice+
                    "&examinerID="+ this.clientMasteObj.examinerID + "&examinerName="+ this.clientMasteObj.examinerName
                this.GETM('searchMCM', reqData, (res) => {
                    if(res){
                        if(res.data.data && res.data.data.data.length >0){
                            this.message = res.data.msg;
                            this.showAlert(this.message);
                            this.clientMasterTable = res.data.data.data;
                        }else{
                            this.message = 'No Records Found'
                            this.showAlert(this.message);
                        }
                      console.log('if--------search',res.data.data)
                    } 
                    this.hideProgress();    
                },(error)=>{
                        this.hideProgress();
                        console.log("error response--->",error.response);
                    // this.showAlert(error.response.data.msg);
                });
            },

            createClientMaster(){   
                this.mcmData ={
                //values in dialog box to create CMC
                    tataMotorPolicy:false,
                    typeOfTataMotorsPolicy:'',
                    dealerLocation:'',
                    dealerName:'',
                    policyNumber:'',
                    insuredName:'',
                    insuredID:'',
                    settlingOffice:[],
                    examinerOneID:'',
                    examinerOneName:'',
                    examinerTwoID:'',
                    examinerTwoName:'',
                    basisSettlingOfficeMapping:false,
                    basisPanIndia:false,
                    basisIssuingToSettlingOffice:false,                   
                    product:''
                },
                this.assignmentBasis = ''
                this.openDialogbox = true;
                this.mcmDataDisable = false;
                this.deletebtndisable = true;
            }, 

            resetValues(){
                this.clientMasteObj={
                    policyNumber:'',
                    insuredName:'',
                    insuredID:'',
                    settlingOffice:'',
                    examinerID:'',
                    examinerName:'',
                    contactNo:'',
                    emailID:'',
                    basisSettlingOfficeMapping:false,
                    basisPanIndia:false,
                    basisIssuingToSettlingOffice:false, 
                }
                this.assignmentbasis = '';
            },

            // dialogBox functions
            saveCMC(){
                // if(this.$refs.cmcform.validate()){
                    console.log('res.data',JSON.stringify(this.mcmData));
                    this.showProgress("Claim is submitting, Please wait");
                    if(!this.mcmData._id){
                        this.POSTM('createMCM', this.mcmData, (res, error) => {
                        if(!error){
                            this.message = res.data.msg;
                            this.showAlert(this.message);
                            this.searchMCM();
                            console.log('if--------submit',res.data)
                        } else{
                            console.log('erorr--------submit',error.response)
                            this.message = error.response.msg;
                            this.showAlert(this.message);
                            this.openDialogbox = false;
                        } 
                        this.hideProgress();    
                        },(error)=>{
                            this.hideProgress();
                            console.log("error response--->",error.response);
                            this.showAlert(error.response.data.msg);
                        });
                    }else{
                        this.PUTM('updateMCM', this.mcmData._id, this.mcmData, (res, error) => {
                        if(!error){
                            this.message = res.data.msg;
                            this.showAlert(this.message);
                            this.searchMCM();
                            this.openDialogbox = false;
                            console.log('if--------submit',res.data)
                        } else{
                            console.log('erorr--------submit',error.response)
                            this.message = error.response.msg;
                            this.showAlert(this.message);
                        } 
                        this.hideProgress();    
                        },(error)=>{
                            this.hideProgress();
                            console.log("error response--->",error.response);
                            this.showAlert(error.response.data.msg);
                        });
                    }
                // }
            },

            deleteCMC(){
                this.GET_ByURLM('delete', this.mcmData._id, (res, error) => {
                    if(!error){
                        this.message = res.data.msg;
                        this.showAlert(this.message);
                        this.searchMCM();
                        this.openDialogbox = false;
                        console.log('if--------delete',res.data)
                    } else{
                        console.log('erorr--------delete',error.response)
                        this.message = error.response.msg;
                        this.showAlert(this.message);
                    } 
                    this.hideProgress();    
                },(error)=>{
                    this.hideProgress();
                    console.log("error response--->",error.response);
                    this.showAlert(error.response.data.msg);
                });
            },

            clearCMC(){
                this.assignmentBasis = '';
                this.mcmData = {
                    tataMotorPolicy:false,
                    typeOfTataMotorsPolicy:'',
                    dealerLocation:'',
                    dealerName:'',
                    policyNumber:'',
                    insuredName:'',
                    insuredID:'',
                    settlingOffice:[],
                    examinerOneID:'',
                    examinerOneName:'',
                    examinerTwoID:'',
                    examinerTwoName:'',
                    basisSettlingOfficeMapping:false,
                    basisPanIndia:false,
                    basisIssuingToSettlingOffice:false,                   
                    product:'',                             
                }

            },            

            selectedExaminer(){
            },

            toCheckTMPConditions(){
                this.assignmentBasis = ''
            },            

            addSettlingOffice(){
                if(this.settlingOfficeName != ""){
                    if (this.toggledOffice == "") {
                        this.toggledOffice = 0;
                        this.toggledOfficeName = this.settlingOfficeName;
                    }
                    let newOffice = this.toggledOfficeName
                    if(!this.mcmData.settlingOffice.find(function (elem) {
                        return elem.name == newOffice})) {
                        this.mcmData.settlingOffice.push({
                            name: newOffice,
                            product:'',
                            examiners:[{
                                examinerID:'',
                                examinerName:''
                            }]
                        })

                        // if(this.mcmData.settlingOffice.length>0){
                        //     // this.mcmData.settlingOffice = this.mcmData.settlingOffice.filter(function(elem, index, self){ return self.indexOf(elem) == index })
                        //     this.mcmData.settlingOffice =                        
                        //     this.mcmData.settlingOffice.reduce((unique, o) => {
                        //             if(!unique.some(obj => obj.settlingOfficeName === o.settlingOfficeName)) {
                        //                 unique.push(o);
                        //             }
                        //             return unique;
                        //     },[]);
                        //     console.log("office",this.mcmData.settlingOffice)
                        //     // this.settlingOfficeName = ''
                        // }
                    }
                }
            },

            toggleOffice(officeName, index){
                this.toggledOfficeName = officeName;
                if (this.toggledOffice == index) {
                    console.log("going for undefined.");
                    var self = this;
                    setTimeout(() => {
                        console.log("self.toggledCountry - ", self.toggledOffice);
                        if (self.toggledOffice !== index) {
                            console.log("self.toggledCountry inside - ",self.toggledOffice,"index",index);
                            self.toggledOffice = index;
                        }
                    }, 1);
                }
            },

            isBtnActive(index) {
                if (index == this.toggledOffice) {
                    return "active_btn";
                } else {
                    return "";
                }
            },


            deleteAllExaminers(){
                this.showConfirm("Confirmation","Do you want to delete all?","Yes","No",(Yes)=>{
                    //   this.claimDetails.awbNumberAndDate= [];
                    //   this.settlingOfficeName=  "";
                  },(No)=>{});
            },

            deleteExaminer(index){
                // this.claimDetails.awbNumberAndDate.splice(index,1);
            },

            removeOffice(value){

                // this.settlingOfficeArr.splice(this.settlingOfficeArr.indexOf(value), 1);
                // this.settlingOfficeOne = this.settlingOfficeArr.toString();
                
                // this.settlingOfficeArr = [...this.settlingOfficeArr];
            },                          

            checkBasisValue(value){
                if(value == "Basis Settling office mapping"){
                    this.mcmData.basisSettlingOfficeMapping = true;
                    this.mcmData.basisIssuingToSettlingOffice = false;
                    this.mcmData.basisPanIndia = false; 
                    console.log('=====1',this.mcmData .basisSettlingOfficeMapping);
                }else if(value == "Basis Pan India"){
                    this.mcmData.basisPanIndia = true;  
                    this.mcmData.basisIssuingToSettlingOffice = false;
                    this.mcmData.basisSettlingOfficeMapping = false;
                    console.log('=====2',this.mcmData.basisPanIndia);
                }else{
                    this.mcmData.basisIssuingToSettlingOffice = true;
                    this.mcmData.basisSettlingOfficeMapping = false;
                    this.mcmData.basisPanIndia = false;  

                    console.log('=====3',this.mcmData.basisIssuingToSettlingOffice);
                }
                console.log('=====',value);
            },          
            //end dialogBox functions  

            viewTableValues(item){
                this.mcmDataDisable = true;
                // this.showProgress();
                this.GET_ByURLM('fetchMCM', item._id, (res) => {
                    if(res){
                        // this.message = res.data.msg;
                        // this.showAlert(this.message);
                        this.mcmData = res.data.data;
                        if(res.data.data.dealerLocation !== ''){
                            this.calldealerLocapi(res.data.data.dealerLocation)
                        }
                        if(res.data.data.basisPanIndia == true){
                            this.assignmentBasis = "Basis Pan India"
                        }else if(res.data.data.basisSettlingOfficeMapping == true){
                            this.assignmentBasis = "Basis Settling office mapping"
                        }else{
                            this.assignmentBasis = "Basis Issuing to settling office"
                        }
                        
                        console.log('if--------search',res.data.data)
                    } 
                    this.hideProgress();    
                },(error)=>{
                        this.hideProgress();
                        console.log("error response--->",error);
                    // this.showAlert(error.response.data.msg);
                });
                    this.openDialogbox = true;
            },

            modifyTableValues(item){
                // this.showProgress();
                this.mcmDataDisable = false;
                this.GET_ByURLM('fetchMCM', item._id, (res) => {
                    if(res){
                        // this.message = res.data.msg;
                        // this.showAlert(this.message);
                        this.mcmData = res.data.data;
                        if(res.data.data.dealerLocation !== ''){
                            this.calldealerLocapi(res.data.data.dealerLocation)
                        }
                        if(res.data.data.basisPanIndia == true){
                            this.assignmentBasis = "Basis Pan India"
                        }else if(res.data.data.basisSettlingOfficeMapping == true){
                            this.assignmentBasis = "Basis Settling office mapping"
                        }else{
                            this.assignmentBasis = "Basis Issuing to settling office"
                        }
                        console.log('if--------search',res.data.data)
                    } 
                    this.hideProgress();    
                },(error)=>{
                        this.hideProgress();
                        console.log("error response--->",error);
                    // this.showAlert(error.response.data.msg);
                });
                this.openDialogbox = true;
            },
            
            calldealerLocapi(indata){
                if(indata!==""){
                    if(isNaN(indata)){
                        this.reqData = "pageNo=1&perPage=100&cityDistrictName="+indata
                    }
                  
                    this.GETM("getCities",this.reqData, res=>{
                
                        var dealerLoc_not_found = false
                        if(res.data.data.length == 0){
                            dealerLoc_not_found = true
                            this.message ='Dealer Location not found'
                            this.validationbox = true;
                        }else{
                            var dealer_loc =  res.data.data.data.map(e=>{
                                if(e.cityDistrictName && e.cityDistrictName !== ""){
                                    return {cityDistrictName: e.cityDistrictName, all:e};
                                }                  
                            })
                        this.dealerLocItems.push(...dealer_loc)
                        // console.log("-==>>> ",this.pitems.length);
                        }  
                        if(dealerLoc_not_found){
                            // this.pitems.push({locpincode:'No data Available',all:''});
                            this.dealerLocItems.push();

                        }
                        // this.pitems = res.data.data.data.map
                        // console.log("pitems is -> ", this.pitems);
                    },(error)=>{
                            return error;
                    });
                }
            }, 
            
            callSettlingOffice(indata){
                if(indata!==""){
                    if(isNaN(indata)){
                        this.reqData = "&searchText="+indata
                    }
                  
                    this.GET("GET_SETTLEMNET_OFFICELIST",this.reqData, res=>{
                
                        var office_not_found = false
                        if(res.data.data.length == 0){
                            office_not_found = true
                            this.message ='Settling Office not found'
                            this.validationbox = true;
                        }else{
                            var settling_Office =  res.data.data.data.map(e=>{
                                if(e.settlementOfficeName && e.settlementOfficeName !== ""){
                                    return {settlementOfficeName: e.settlementOfficeName, all:e};
                                }                  
                            })
                        this.settlingOfficeItems.push(...settling_Office)
                        // console.log("-==>>> ",this.pitems.length);
                        }  
                        if(office_not_found){
                            // this.pitems.push({locpincode:'No data Available',all:''});
                            this.settlingOfficeItems.push();

                        }
                        // this.pitems = res.data.data.data.map
                        // console.log("pitems is -> ", this.pitems);
                    },(error)=>{
                            return error;
                    },'commonModule');
                }
            }
        },

        watch:{

            dealerLocSearch(val){
                if(val == null){
                  return
                }
                if(val.length >=2){
                  var vlen = val.length
                 
                    if(this.DLitems.length == 0 && val.length > 2){
                        this.calldealerLocapi(val);
                        return
                    }
                      for(let i=0; i<this.DLitems.length;i++){
                          if(vlen > 3){
                            vlen = 3
                            var strlen = this.DLitems[i].cityDistrictName.slice(0,vlen)
                            // console.log("strlen is",strlen)
                          }else{
                            var strlen = this.DLitems[i].cityDistrictName.slice(0,vlen)
                          }
                          if(vlen > strlen.length){
                            // console.log("data not available to search")
                            return
                          }
                  
                          if(strlen != val){
                              // console.log("strlen",strlen,"val",val);
                              this.calldealerLocapi(val)
                              return
                          }else{
                            return
                          }
                        }
                }else{
                    return
                }
            },

            searchOffice(val){
                if(val == null){
                  return
                }
                if(val.length >=2){
                  var vlen = val.length
                 
                    if(this.officeItems.length == 0 && val.length > 2){
                        this.callSettlingOffice(val);
                        return
                    }
                      for(let i=0; i<this.officeItems.length;i++){
                          if(vlen > 3){
                            vlen = 3
                            var strlen = this.officeItems[i].settlementOfficeName.slice(0,vlen)
                            // console.log("strlen is",strlen)
                          }else{
                            var strlen = this.officeItems[i].settlementOfficeName.slice(0,vlen)
                          }
                          if(vlen > strlen.length){
                            // console.log("data not available to search")
                            return
                          }
                  
                          if(strlen != val){
                              // console.log("strlen",strlen,"val",val);
                              this.callSettlingOffice(val)
                              return
                          }else{
                            return
                          }
                        }
                }else{
                    return
                }
            },
            
            searchSettlingOffice(val){
                if(val!==null){
                    if(val.length > 2){
                        let requestData = "&searchText=" + val
                        this.GET("GET_SETTLEMNET_OFFICELIST", requestData, (res) => {
                                if(res.data.data && res.data.data.length==0){
                                    this.showAlert("Office name is invalid.");
                                }else{
                                this.settling_OfficeArr = res.data.data.data;
                                }
                        },(error)=>{
                            console.log(error);
                        },'commonModule');
                    }else{
                        this.validationbox = true;
                        this.message = 'Please enter Minimum 3 digits to search Office';
                        return;
                    }
                }else{
                    return;
                }
            },
            
            searchExaminer(val){
                if(val!==null){
                    if(val.length > 2){
                        let requestData = "&searchText=" + val
                        this.GET("searchExaminers", requestData, (res) => {
                                if(res.data.data && res.data.data.length==0){
                                    this.showAlert("Office name is invalid.");
                                }else{
                                this.settling_OfficeArr = res.data.data;
                                }
                        },(error)=>{
                            console.log(error);
                        });
                    }else{
                        this.validationbox = true;
                        this.message = 'Please enter Minimum 3 digits to search Office';
                        return;
                    }
                }else{
                    return;
                }
            }

        }
    }
</script>

<style  scoped>

    .countryListFlex{
        margin-right: 10px !important;
        overflow: auto !important;
        display: flex !important;
        flex-direction: column-start !important;
    }

    .active_btn {
        background-color: #01bbd9 !important;
    }

    .countrylistAllbuttons{
        width: 200px !important;
        height: 40px !important;
        margin: 10px !important;
        text-transform:none !important;
        background-color: #01bbd9 !important;
        /* border: 1px solid #c1c8cc !important; */
        color: #fff !important;
    }


    .lrDelete {
        /* text-transform: none; */
        /* background-color: rgb(209, 18, 27);
        border-color: rgb(209, 18, 27); */
        color: rgb(255, 255, 255);
        min-width: 75px !important;
        /* text-transform: none; */
        height: 48px !important;
        /* width: 183px; */
        font-size: 12px;
        border-radius: 0;
    }

    .deleteIconTd{
        font-size: 20px; 
        color:red ;
    }

    .workitemcheck{
        height: 33px; 
        margin-top: 0px
    }

    .invoiceDatatd {
        border: 1px solid #f7f7f7;
    }

    .examinerTablewidth tr th{
        color: #fff  !important;
        border-right: 0.1px solid #f2f1f163;

    }
    .lr1th{
        width:100px !important;
    }
    .lr2th{
        width:336px !important;
    }
    .lr3th{
        width:300px !important;
    }

    .lr1td{
         width:92px !important;
    }
    .lr2td{
        width:286px !important;
    }
    .lr3td{
        width:260px !important;
    }

    .TableScroll {
        display: block;
        height: 150px;
        overflow-y: scroll;
    }
    .examinerTablewidth{
        width: 67% !important;
        border-bottom: 1px solid #80808021;
    }

    .examinertableBackground {
        background-color: #01bbd9;
        width: 100% !important;
        display:block !important;
        
    }

    .plusFont{
        font-size:23px
    }

    .plusbutton {
        height: 40px !important;
        color: #f5f6fa !important;
        min-width: 53px !important;
        padding: 0;
    }

    .checkBoxStyle{
        height: 40px;
        padding-top: 8px;
        border:1px solid #cdcdcd;
        width: 100%;
    }

    .dataTblStyle{
        margin-left: 16px; 
        width: 70%;
        margin-bottom:40px !important;
    }

    .borderStyle{
        border-right: 1px solid #090909;
    }

    .fontClass{
        font-size:18px;
    }

    .tdStyle{
        width: 301px;
        padding-right: 0;
        text-align: right !important;
        border-right: 0.1px solid #c3c7c399 !important;
    }

    .containerStyle{
        margin: 0; 
        padding: 0; 
        max-width: 100%;
    }

    .officedialogCss{
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 2px 2px #00000051;
        border: 0.5px solid #c1c8cc;
        opacity: 1;
    }
    .cursorStyle{
        cursor: pointer
    }

    .whiteColor{
        color:#fff;
    }

    .bottomLineSO{
        border-top: 0.5px solid #c1c8cc; 
        margin-top: -10px; 
        margin-bottom: 10px;
    }
    .cardTextColor{
        background-color: #f7f7f7;
    }

    .buttonRowstyle{
        background-color: #F7F7F7;
        height:95px; 
        border: 0.3px solid #C1C8CC;
    }

    .borderRadiusCls{
        border-radius:none !important;
    }

    .backgroundStyle{
        background-color: #f7f7f7;
    }
    .assisLayout{
        background-color: #01BBD9;
        width:100%; 
        height:35px
    }
    .flexStyle{
        background: white; 
        box-shadow: 0px 2px 2px #00000051; 
        border: 0.5px solid #C1C8CC;
    }
    .assisLayoutStyle{
        background-color: #01BBD9; 
        width:100%; 
        height:50px;
    }


    .claimlabel {
        color: #646666;
        font-size: 12px;
        font-weight: bold;
    }

    .titleStyle{
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 5px 7px #00000029;
        opacity: 1;
        font-size: 14px;
    }

    .forminput {
        border-radius: 0;
    }

    .claim {
        background-color: #f7f7f7;
        border: 1px solid #c1c8cc;
        font-weight: bold;
        width: 250px;
        height: 39px;
        padding: 10px;
    }

    .basisradiobtns{
        background-color: #f7f7f7;
        border: 1px solid #c1c8cc;
        font-weight: bold;
        width: 235px;
        height: 39px;
        
        margin-right: 13px !important;
    }
    

    .buttonscolor {
        border-radius: 0 !important;
        height: 31px !important;
        color: #f5f6fa !important;
        text-transform: capitalize;
    }

    .tablebtn{
        /* background: #FFFFFF 0% 0% no-repeat padding-box; */
        box-shadow: 0px 2px 2px #00000051;
        border: 0.5px solid #C1C8CC;
        border-radius: 0 !important;
        height: 31px !important;
        color: #000 !important;
        text-transform: capitalize;
    }

    .dataTable tr th{
        background-color:rgb(1, 187, 217)!important;
    }

    .headercolor{
        white-space: nowrap;
        background-color:rgb(1, 187, 217)!important;
        font: normal normal bold 17px/20px Roboto;
        color:#FFFFFF
    }

    .colborder{
        border: 1px solid #DAE1E6;
    }

    .headerone{
        width:12%  !important;
    }

    .descrptionSpan {
        width: 85%;
        margin-left: 53px;
        margin-top: 21px;
        margin-bottom: 14px !important;
        font-weight: bold;
    }

    .bottomline {
        border-bottom: 1px solid #c1c8cc;
    }

    .blueiconstyle {
        margin-right: -12px;
        background-color: #01bbd9;
        width: 45px;
        height: 38px;
        font-size: 26px !important;
        opacity: 1;
        color: #fff !important;
        cursor: pointer !important;
    }

    .mandatorycolor{
        color:red
    }

    .mcmDatatable tr th {
        background-color: #01BBD9;
        color: white !important;
        font-size: 15px !important;
        height: 40px !important;
        border: 0.5px ridge #f7f7f7;
        border-width: 1px;
        border-right: none;
        border-collapse: collapse;
        font-weight: bold;
        text-align: center !important;
    }

    .mcmDatatable tr td{
        border: 1px solid #f7f7f7;
        border-width: 2px;
        border-right: none;
        border-collapse: collapse;
        height: 40px !important;
        font-size: 15px !important;
        color: #10242b;
        text-align: left !important;
    }

</style>