<template>
    <v-app>
        <v-container class="containerStyle" style="padding:1.5rem; margin:0; max-width:100%">
            <h3>{{heading}} Salvage Buyer Master</h3>
            <v-form ref="salvageBuyer" lazy-validation @submit.prevent="onSubmitHandler">
            <v-layout class="mt-5">
               <v-flex class="form-group">
                <label class="form-label">LOB <span class="red--text">*</span></label>
                <v-select solo dense class="form-control" multiple 
                v-model="salvageBuyerObj.lob" :rules="[(v)=>requiredField(v)]" :items="lobOptions" :disabled="isView"
                label="Select"></v-select>
               </v-flex>
                <v-flex class="form-group">
                    <label class="form-label">Buyer Name <span class="red--text">*</span></label>
                    <v-text-field
                    :disabled="isView"
                    solo
                    dense
                    label="Enter buyer name"
                    class="form-control"
                    :rules="[(v)=>requiredField(v)]"
                    v-model="salvageBuyerObj.buyerName"
                    >
                    </v-text-field>
                </v-flex>
                <v-flex class="form-group">
                    <v-checkbox  label="Active" v-model="salvageBuyerObj.active" class="form-control" :disabled="isView || !isModified"></v-checkbox>
                </v-flex>
            </v-layout>
            <v-expansion-panels v-model="panels" multiple>
       <v-expansion-panel>
            <v-expansion-panel-header color="#1EBBD9">
              <span class="page-title-small white--text" >Payee Contact Information</span>
              <template v-slot:actions>
                <v-icon class="white--text">
                  {{
                    panels.indexOf(0) !== -1 ? "mdi-minus-box" : "mdi-plus-box"
                  }}
                </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content >
                <v-layout class="pt-4 form-group-container">
                    <v-flex class="form-group">
                        <label class="form-label">Address Line 1 <span class="red--text"><strong>*</strong></span></label>
                        <v-text-field
                        solo
                        dense
                        :maxlength="45"
                        :disabled="isView"
                        class="form-control"
                        label="Enter..."
                        v-model="salvageBuyerObj.addressLine1"
                        :rules="[(v)=>requiredField(v)]"
                        ></v-text-field>
                    </v-flex>

                    <v-flex class="form-group">
                        <label class="form-label">Address Line 2 </label>
                        <v-text-field
                        solo
                        dense
                        :disabled="isView"
                        v-model="salvageBuyerObj.addressLine2"
                        class="form-control"
                        label="Enter..."
                        :maxlength="45"
                        ></v-text-field>
                    </v-flex>

                    <v-flex class="form-group">
                        <label class="form-label">Address Line 3 </label>
                        <v-text-field
                        solo
                        dense
                        :disabled="isView"
                        v-model="salvageBuyerObj.addressLine3"
                        class="form-control"
                        label="Enter..."
                        :maxlength="45"
                        ></v-text-field>
                    </v-flex>

                    <v-flex class="form-group">
                        <label class="form-label">Pincode <span class="red--text">*</span></label>
                        <v-autocomplete  solo
                        dense
                        :items="searchPincode"
                        item-text="pinCode"
                        maxlength="6"
                        :search-input.sync="pincode"
                        class="form-control"
                        label="Enter..."
                        :disabled="isView"
                        @keyup="pincode_Search"
                        @change="pincode_Change"
                        v-model="salvageBuyerObj.postalCode"
                        :rules="[(v)=>requiredField(v)]">
                        </v-autocomplete>
                    </v-flex>

                    <v-flex class="form-group">
                        <label class="form-label">City <span class="red--text">*</span></label>
                        <v-text-field  solo
                        dense
                        disabled
                        class="form-control"
                        label="Enter..."
                        v-model="salvageBuyerObj.city"
                        :rules="[(v)=>requiredField(v)]">
                        </v-text-field>
                    </v-flex>

                    <v-flex class="form-group">
                        <label class="form-label">District <span class="red--text">*</span></label>
                        <v-text-field  solo
                        dense
                        disabled
                        class="form-control"
                        label="Enter..."
                        v-model="salvageBuyerObj.district"
                        :rules="[(v)=>requiredField(v)]">
                        </v-text-field>
                    </v-flex>

                    <v-flex class="form-group">
                        <label class="form-label">State <span class="red--text">*</span></label>
                        <v-text-field  solo
                        dense
                        disabled
                        class="form-control"
                        label="Enter..."
                        v-model="salvageBuyerObj.state"
                        :rules="[(v)=>requiredField(v)]">
                        </v-text-field>
                    </v-flex>

                    <v-flex class="form-group">
                        <v-layout>
                            <v-flex>
                                <label class="form-label">
                                    ISD<span class="red--text">*</span>
                                </label>
                                <v-text-field
                                    class="form-control mr-2"
                                    style="max-width:150px"
                                    solo
                                    dense
                                    :disabled="isView"
                                    :rules="[(v)=>requiredField(v),(v)=>checkRules(v,'nums')]" 
                                    :maxlength="5"
                                    placeholder="..."
                                    prepend-inner-icon=""
                                    v-model="salvageBuyerObj.landlineNumber[0].isd"
                                    >
                                    <template v-slot:prepend-inner>
                                        <v-icon class="iconstyle">mdi-phone</v-icon>
                                    </template>
                                </v-text-field>
                            </v-flex>

                            <v-flex >
                                <label class="form-label">
                                    Mobile Number<span class="red--text">*</span>
                                </label>
                                <!-- <template v-slot:activator="{ on, attrs }">  -->
                                <v-text-field
                                    placeholder="Enter..."
                                    solo
                                    dense
                                    :disabled="isView"
                                    :maxlength="10"
                                    v-model="salvageBuyerObj.landlineNumber[0].number"
                                    :rules="[(v)=>requiredField(v),(v)=>matchRegex(v,'nums')]" 
                                              
                                    class="form-control"
                                    
                                >
                                    <template v-slot:append>
                                    <v-icon class="blueiconstyle"  @click="contact_Dialog=!contact_Dialog">mdi-phone-plus</v-icon>
                                    </template>
                                </v-text-field>
                            </v-flex>
                        </v-layout>
                    </v-flex>
                    <v-flex class="form-group">
                        <label class="form-label">
                            Email Id <span><strong class="red--text">*</strong></span>
                        </label>
                        <v-text-field
                        solo
                        dense
                        :readonly="isView"
                        :rules="[(v)=>requiredField(v),(v) => validateEmail(v, 'Email ID')]"
                        class="form-control"
                        maxlength="50"
                        placeholder="Enter..."
                       v-model="email_Obj[0].email"
                        >
                        <template v-slot:append>
                                    <v-icon class="blueiconstyle" @click="email_Dialog=!email_Dialog">mdi-email-plus</v-icon>
                                    </template>
                                
                        </v-text-field>
                    </v-flex>
                    <v-flex class="form-group">
                        <label class="form-label">
                            Area Of Specialization
                        </label>
                        <v-text-field
                        solo
                        dense
                        :disabled="isView"
                        :rules="[(v)=>matchRegex(v,'alphanum')]"
                        class="form-control"
                        maxlength="50"
                        placeholder="Enter..."
                        v-model="salvageBuyerObj.areaOfSpecialization"
                        ></v-text-field>
                    </v-flex>
                    <v-flex class="form-group">
                        <label class="form-label">
                            PAN <span><strong class="red--text">*</strong></span>
                        </label>
                        <v-text-field
                        solo
                        dense
                        :disabled="isView"
                        :rules="[(v) => validatePanNumber(v, 'PAN Number'),(v) => requiredField(v)]"
                        :value="computedPAN"
                        v-model="salvageBuyerObj.panNumber"
                        class="form-control"
                        :maxlength="10"
                        placeholder="Enter..."
                        
                        >
                        </v-text-field>
                    </v-flex>

                    <v-flex class="form-group">
                        <label class="form-label">
                            AADHAR Card No (Last 4 Digits)
                        </label>
                        <v-text-field
                        solo
                        dense
                        :disabled="isView"
                        v-model="salvageBuyerObj.aadharId"
                        :rules="[ (v) => matchRegex(v, 'nums'), (v) => validateLength(v, { exact: 4 }, '', 'Field requires 4 digits.')]" 
                        :maxlength="4"
                        class="form-control"
                        placeholder="Enter..."
                        >
                        </v-text-field>
                    </v-flex>
                    <v-flex class="form-group">
                        <label class="form-label">
                            GSTIN Number
                        </label>
                        <v-text-field
                        solo
                        dense
                        :disabled="isView"
                        :rules="[(v)=>matchRegex(v,'alphanum')]"
                        v-model="salvageBuyerObj.gstNumber"
                        class="form-control"
                        :maxlength="15"
                        placeholder="Enter..."
                        
                        >
                        </v-text-field>
                    </v-flex>

                </v-layout>
            </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
            <v-expansion-panel-header color="#1EBBD9">
              <span class="page-title-small white--text" >Upload</span>
              <template v-slot:actions>
                <v-icon class="white--text">
                  {{
                    panels.indexOf(1) !== -1 ? "mdi-minus-box" : "mdi-plus-box"
                  }}
                </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content >
                <v-layout class="pt-4 form-group-container">
                    <h4>Upload Survey Documents</h4>
                    <v-tabs
                    v-model="tab"
                    hide-slider
                    class="mt-5"
                  >
                  <!-- active-class="claimProcessingTabsActive" -->
                    <v-tab
                      v-for="item in tab_items"
                      :key="item"
                      @change="documentTab=item;checkTabChanged()"
                      :class="documentTab == item ? 'claimProcessingTabsActive' :'unActive'"
                    >
                      {{ item }}
                    </v-tab>
                  </v-tabs>
                  <v-layout class="mt-5">
                  <div >
                    <div class="neftStyle"   @dragover="dragover" @dragleave="dragleave" @drop="drop" 
                    :class="visualEffect ? 'dragbackground': ''">
                      <v-icon color="#23b1a9" class="iconfont">mdi-tray-arrow-up</v-icon>
                      <span class="ml-2 margincls font-weight-bold" :disabled="isView">Drop Files To Upload or <a @click="browse" ><u > Browse</u></a></span>
                      <p class="margincls dlt_text mb-0">Max 5MB of PDF, Word, Excel, Mail or Image(.jpeg, .jpg, .png)</p>
                      <p class="dlt_text mt-0 ml-4" style="font-size:10px">Multiple documents can be uploaded</p>
                      
                    </div>
                    <span></span>
                            <v-file-input
                                multiple
                                v-model="all_attachments"
                                @change="selectFile"
                                accept=".doc, .docx, .csv, image/png,image/jpeg,application/msword,application/pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/csv"
                                id="SP_fileupload"
                                :disabled="isView"
                                style="display: none">
                            </v-file-input>
                            <!-- @change="selectFile" -->
                  </div>
                  <div>
                    <v-data-table
                            :headers="neftHeaders"
                            :items="documentAttachments"                            
                            hide-default-footer
                            no-data-text="No Records for Attachements"
                            class="tabelwidth vDataTableStyling">
                            <template v-slot:[`item.action`] = '{ item, index }'>            
                                <v-icon class="tableicons mr-4" color="#23B1A9" @click="viewDocument(item)">mdi-eye</v-icon>
                                <v-icon class="tableicons mr-4" color="#23B1A9" @click="downloadDoc(item)">mdi-download</v-icon>          
                                <v-icon class="tableicons" color="#D1121B" @click="deleteDocument(index)" v-if="!isView"
                                >mdi-delete</v-icon>
                            </template>
                        </v-data-table>
                  </div>
                  </v-layout>
                </v-layout>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</v-form>
<v-layout class="mt-5">
                <v-btn outlined color="red" class="btn"  v-show="!isView" @click="onReset">
                   <v-icon> mdi-backspace </v-icon>
                    <v-divider vertical class="mx-2">
                      </v-divider> Reset</v-btn>
                <v-btn outlined color="red" class="ml-5 btn" @click="cancel">
                   <v-icon> mdi-backspace </v-icon>
                    <v-divider vertical class="mx-2"></v-divider> Cancel</v-btn>
                <v-spacer></v-spacer>
                <v-btn class="btn" color="teal white--text" dense solo v-show="!isView"  @click="onSubmit"><v-icon>mdi-arrow-right-circle</v-icon>
          <v-divider vertical class="mx-2"></v-divider>
          Submit</v-btn>
            </v-layout>
            <v-dialog v-model="email_Dialog" width="500">
                <v-card>
                    <v-card-title
                    class="white--text"
                    style="background-color: #23b1a9"
                  >
                  <h4 class="ml-5">Add Multiple Email ID</h4>
        <v-spacer></v-spacer>
        <v-icon @click="email_Dialog=!email_Dialog" class="white--text">mdi-window-close</v-icon>
                </v-card-title>
                <v-card-text class="grey lighten-2">
                    <v-data-table hide-default-footer
                    :headers="email_Headers"
                    :items="email_Obj"
                    class=" vDataTableStyling"
                    >
                   <template v-slot:[`item.email`]="{item}">
                    <v-text-field
                    solo
                    dense
                    class="form-control mt-5"
                    v-model="item.email"
                    :disabled="isView"
                    :rules="[(v) => validateEmail(v, 'Email ID')]"
                    >

                    </v-text-field>
                   </template>
                   <template v-slot:[`item.action`]="{item}">
                    <v-icon left
                      top color="#d1121b" @click="email_Delete(email_Obj.indexOf(item))" :disabled="isView">mdi-delete</v-icon>
                   </template>
                </v-data-table>
                <v-layout class="addbtn" justify-center>
                <v-btn
                
                  @click="email_Add_Row"
                  text
                  elevation="0"
                  :disabled="isView"
                  class="grey lighten-2 mt-5"
                  style="color: #23B1A9"
                  >+ Add Email ID</v-btn
                >
              </v-layout>
                </v-card-text>
                </v-card>
            </v-dialog>

    <!-- Mobile Dialog -->
    <v-dialog v-model="contact_Dialog" width="500">
                <v-card>
                    <v-card-title
                    class="white--text"
                    style="background-color: #23b1a9"
                  >
                  <h4 class="ml-5">Add Multiple Contact Number</h4>
        <v-spacer></v-spacer>
        <v-icon @click="contact_Dialog=!contact_Dialog" class="white--text">mdi-window-close</v-icon>
                </v-card-title>
                <v-card-text class="grey lighten-2">
                    <v-data-table hide-default-footer
                    :headers="contact_Headers"
                    :items="salvageBuyerObj.landlineNumber"
                    class=" vDataTableStyling"
                    >
                    <template v-slot:[`item.isd`]="{item}">
                        <v-text-field
                    solo
                    dense
                    :maxlength="3"
                    :disabled="isView"
                    class="form-control mt-5"
                    v-model="item.isd"
                    :rules="[(v) => checkRules(v)]"
                    >

                    </v-text-field>
                    </template>
                   <template v-slot:[`item.number`]="{item}">
                    <v-text-field
                    solo
                    dense
                    :disabled="isView"
                    class="form-control mt-5"
                    :maxlength="10"
                    v-model="item.number"
                    :rules="[(v) => matchRegex(v, 'nums')]"
                    >

                    </v-text-field>
                   </template>
                   <template v-slot:[`item.action`]="{item}">
                    <v-icon left
                      top color="#d1121b" @click="contact_Delete(salvageBuyerObj.landlineNumber.indexOf(item))" :disabled="isView">mdi-delete</v-icon>
                   </template>
                </v-data-table>
                <v-layout class="addbtn" justify-center>
                <v-btn
                
                  @click="contact_Add_Row"
                  text
                  elevation="0"
                  :disabled="isView"
                  class="grey lighten-2 mt-5"
                  style="color: #23B1A9"
                  >+ Add Contact Number</v-btn>
              </v-layout>
                </v-card-text>
                </v-card>
            </v-dialog>
</v-container>
</v-app>
</template>
<script>
export default{
    data(){
        return{
            email_Obj:[{email:""}],
            email_Dialog:false,
            contact_Dialog:false,
            pincode:null,
            visualEffect: false,
            isModified:false,
            searchPincode:[],
            isView:false,
            heading:"Create",
            all_attachments:[],
            documentTab:'AADHAR',
            documentAttachments:[],
            email_Headers:[{text:"Email",value:"email",align:"center"},{text:"Action",value:"action",align:"center"}],
            contact_Headers:[{text:"ISD",value:"isd",align:"center"},{text:"Contact Number",value:"number",align:"center"},{text:"Action",value:"action",align:"center"}],
            panels:[0,1],
            tab:null,
            panAttachment:[],
            otherAttachment:[],
            aadharAttachment:[],
            lobOptions:[],
            tab_items:['AADHAR','PAN','OTHERS'],
            neftHeaders:[{text: "Document Name",align: "start",value: "file",sortable: false},
                                {text: "Action",align: "start",value: "action",sortable: false}],
                                
            salvageBuyerObj:{
              lob:[],
            buyerId:"",// Joi.string().optional().allow(["", null]),
            buyerName:"",// Joi.string().required(),
            active:false,
            addressLine1:"",// Joi.string().required(),
            addressLine2:"",// Joi.string().optional().allow(["", null]),
            addressLine3:"",// Joi.string().optional().allow(["", null]),
            postalCode:"",// Joi.string().required(),
            city:"",// Joi.string().required(),
            district:"",// Joi.string().optional().allow(""),
            state:"",// Joi.string().required(),
            country:"",// Joi.string().required(),
            aadharId:"",// Joi.string().optional().allow(["", null]),
            landlineNumber:[{isd:"+91",number:""}],// Joi.array().items(Joi.object({
            //     _id: Joi.string().optional(),
            //     isd: Joi.string().max(5).allow(""),
            //     number: Joi.string().max(10).required(),
            // })),
            // mobileNumber:"",// Joi.string().required(),
            // stdNumber:"",// Joi.string().optional().allow(["", null]),
            // isdNumber:"",// Joi.string().optional().allow(["", null]),
            emailAddress:[],// Joi.array().items(Joi.string()).optional(),
            areaOfSpecialization:"",// Joi.string().optional().allow(["", null]),
            panNumber:"",// Joi.string().required(),
            gstNumber:"",// Joi.string().optional().allow(""),
            status:true,// Joi.boolean().optional().allow([true, false]),
            attachments:[],// Joi.array()
            proceedDuplicate:false
            }
        }
    },
    methods:{
      getLOB() {
      let reqData = '';
      this.showProgress();
      this.GETM("getLOB", reqData, (res) => {
          this.hideProgress();
          if(res.data.statusCode === 200) {
              this.lobOptions = res.data.data[0].lineOfBusiness;
          } else {
              this.lobOptions = [];
              this.showAlert(res.data.msg);
          }
      }, (error) => {
          console.log(error);
          this.hideProgress();
      }, true)
  },
        contact_Add_Row(){
            this.salvageBuyerObj.landlineNumber.push({isd:"+91",number:""})
        },
        contact_Delete(index){
          if(this.salvageBuyerObj.landlineNumber.length == 1){
            return this.showAlert("Atleast one row is mandatory")
           }else{
            this.showConfirm(
              "Confirmation",
              "Are you sure you want to delete?",
              "Yes",
              "No",
              (Yes) => {
                    this.salvageBuyerObj.landlineNumber.splice(index,1)
              },
              (No) => {}
            )
          }
        },
        email_Add_Row(){
            this.email_Obj.push({email:""})
        },
        email_Delete(index){
            this.email_Obj.splice(index,1)
        },
        pincode_Search(){
            if(this.pincode.length>=3){
      let reqData = "pageNo=1&perPage=100&pinCode=" + this.pincode
        this.GETM(
          "getPincodes",
          reqData,
          (res) => {
            let a=res.data.data.data
            this.searchPincode=a
            
          },(err) => {
            console.error("Issue while fetching pin codes.", err);
          })
    }
        },
        pincode_Change(){
            let data=[]
            this.searchPincode.forEach(i=>{
      if(i.pinCode == this.salvageBuyerObj.postalCode){
        data= i
      }
    
  })
  
  this.salvageBuyerObj.city=data.cityDistrictName
  this.salvageBuyerObj.district=data.cityDistrictName
  this.salvageBuyerObj.state=data.stateName
        },
onReset(){
  this.showConfirm('Confirmation','Are you sure you want to Reset?','Yes','No',(Yes)=>{
    this.salvageBuyerObj.lob=[]
    this.salvageBuyerObj.buyerName=""
    this.salvageBuyerObj.active=false
    this.salvageBuyerObj.addressLine1=""
    this.salvageBuyerObj.addressLine2=""
    this.salvageBuyerObj.addressLine3=""
    this.salvageBuyerObj.postalCode=""
    this.salvageBuyerObj.city=""
    this.salvageBuyerObj.district=""
    this.salvageBuyerObj.state=""
    this.salvageBuyerObj.country=""
    this.salvageBuyerObj.aadharId=""
    this.salvageBuyerObj.landlineNumber=[{isd:"91",number:""}]
    this.salvageBuyerObj.emailAddress=[]
    this.salvageBuyerObj.areaOfSpecialization=""
    this.salvageBuyerObj.panNumber=""
    this.salvageBuyerObj.gstNumber=""
    this.email_Obj=[{email:""}]
            this.pincode=null
            this.searchPincode=[]
            this.salvageBuyerObj.attachments=[]
            this.panAttachment=[]
            this.otherAttachment=[]
            this.aadharAttachment=[]
            this.deleteDocument()
  })
    
},
        dragover(event) {
                event.preventDefault();
                this.visualEffect = true;
            },
    dragleave(event) {
                this.visualEffect = false;
            },
    browse(){ //to select attachments
                document.getElementById("SP_fileupload").click()
            },

    drop(event) {
                event.preventDefault();
                this.all_attachments = event.dataTransfer.files;
                // console.log("all", this.all_attachments);
                 this.selectFile(); // Trigger the onChange event manually
                this.visualEffect = false;
            },
            selectFile(){
              
                if(this.all_attachments.length !== 0){
                    const fsize = this.all_attachments[0].size;
                    const file = Math.round(fsize / 1024);
                    let self = this
                    if (file > 5120) {
                        self.showAlert("File is too big, please select a file less than 5MB");
                    } else{
                        self.readImageM(self.all_attachments[0].name, self.all_attachments[0], (res) => { 
                          console.log(res)
                        var type = [];
                        type = self.all_attachments[0].type
                        console.log('--',self.documentTab)
                        if(self.documentTab == 'AADHAR'){
                            if(self.aadharAttachment.length < 5){
                                self.aadharAttachment.push({ 
                                  file: res.fileName,
                                    fileBase: res.base,
                                    documentType: 'AADHAR'
                                })
                                self.aadharAttachment = self.aadharAttachment.reduce((unique, o) => {
                                    if(!unique.some(obj => obj.file === o.file)) {
                                        unique.push(o);                                        
                                    }
                                    return unique;
                                },[]);
                            }else{
                                self.aadharAttachment.splice(0,1)
                                self.aadharAttachment.push({ 
                                  file: res.fileName,
                                    fileBase: res.base,
                                    documentType: 'AADHAR'
                                })
                                self.aadharAttachment = self.aadharAttachment.reduce((unique, o) => {
                                    if(!unique.some(obj => obj.file === o.file)) {
                                        unique.push(o);                                        
                                    }
                                    return unique;
                                },[]);
                            }
                            console.log('---neft', self.aadharAttachment)
                        }else if(self.documentTab == 'OTHERS'){
                            if(self.otherAttachment.length < 5){
                                self.otherAttachment.push({ 
                                  file: res.fileName,
                                    fileBase: res.base,
                                    documentType: 'OTHERS'
                                })
                                self.otherAttachment = self.otherAttachment.reduce((unique, o) => {
                                    if(!unique.some(obj => obj.file === o.file)) {
                                        unique.push(o);                                        
                                    }
                                    return unique;
                                },[]);
                            }else{
                                self.otherAttachment.splice(0,1)
                                self.otherAttachment.push({ 
                                  file: res.fileName,
                                    fileBase: res.base,
                                    documentType: 'OTHERS'
                                })
                                self.otherAttachment = self.otherAttachment.reduce((unique, o) => {
                                    if(!unique.some(obj => obj.file === o.file)) {
                                        unique.push(o);                                        
                                    }
                                    return unique;
                                },[]);
                            }
                        }else {
                            if(self.panAttachment.length < 5){
                                self.panAttachment.push({ 
                                  file: res.fileName,
                                    fileBase: res.base,
                                    documentType: 'PAN'
                                })
                                self.panAttachment = self.panAttachment.reduce((unique, o) => {
                                    if(!unique.some(obj => obj.file === o.file)) {
                                        unique.push(o);                                        
                                    }
                                    return unique;
                                },[]);
                            }else{
                                self.panAttachment.splice(0,1)
                                self.panAttachment.push({ 
                                  file: res.fileName,
                                    fileBase: res.base,
                                    documentType: 'PAN'
                                })
                                self.panAttachment = self.panAttachment.reduce((unique, o) => {
                                    if(!unique.some(obj => obj.file === o.file)) {
                                        unique.push(o);
                                        
                                    }
                                    return unique;
                                },[]);
                            }
                            }
                this.checkTabChanged()
                this.all_attachments=[]
                        })
                    }
                }
        },
        cancel(data){
                  if(data == 1){
                    this.$router.push({name:'SearchSalvageBuyerMaster'} );
              }else{
              this.showConfirm("Confirmation","Are you sure you want to leave?","Yes","No",(Yes) => {
                    this.showProgress();
                    this.$router.push({name:'SearchSalvageBuyerMaster'} );//params:{pageNo:this.pageNo}}
                    this.hideProgress();
                })
              }
            },
        checkTabChanged(){
      if(this.documentTab=='AADHAR'){
        this.documentAttachments=this.aadharAttachment
      }
     
      if(this.documentTab=='PAN'){
        this.documentAttachments=this.panAttachment
      }
      
      if(this.documentTab=='OTHERS'){
        this.documentAttachments=this.otherAttachment
      }
      
    },

    
    downloadDoc(item) {
                if(item.fileBase) {
                    let base64 = item.fileBase;
                    const base64ImageData = base64
                    const contentType = base64.split(':')[1].split(';')[0];
                    const byteCharacters = window.atob(base64ImageData.substr(`data:${contentType};base64,`.length));
                    
                    const byteArrays = [];
                    for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
                        const slice = byteCharacters.slice(offset, offset + 1024);
                        const byteNumbers = new Array(slice.length);
                    for (let i = 0; i < slice.length; i++) {
                        byteNumbers[i] = slice.charCodeAt(i);
                    }
                    const byteArray = new Uint8Array(byteNumbers);
                        byteArrays.push(byteArray);
                    }
                    const blob = new Blob(byteArrays, {type: contentType});
                    const blobUrl =  window.URL.createObjectURL(blob);

                    const element = document.createElement("a");
                    element.href = blobUrl;
                    element.download = item.file;
                    element.click();
                } else {
                    let url = this.getURL("VIEW_DOCUMENT") + "?documentId=" + item.documentId;
                    window.open(url);
                }
            },
    checkRules(value){
      let regex=new RegExp(/^[0-9 +]*$/);
      if (value && !regex.test(value)) {
          // if (field != "") return `field is invalid.`;
          return "The Field is Invalid";
        }
      return true;
    },

    deleteDocument(index) {
        if(this.documentTab=='AADHAR'){
        this.aadharAttachment.splice(index, 1);
      }
     
      if(this.documentTab=='PAN'){
        this.panAttachment.splice(index, 1);
      }
      
      if(this.documentTab=='OTHERS'){
        this.otherAttachment.splice(index, 1);
      }
     
      this.checkTabChanged()
            },
            viewDocument(itemData) {
      if (itemData.fileBase) {
        this.viewDocUsingBase(itemData.fileBase);
      } else {
        let requestData = "";
        if (itemData !== null) {
          requestData = "documentId=" + itemData.documentId + "&b64=true";
        }
        this.GETM(
          "VIEW_DOCUMENT",
          requestData,
          (res) => {
            if (res.data.statusCode === 200) {
              this.viewDocUsingBase(res.data.data);
            }
          },
          (error) => {
            console.log("Error", error);
          },true
        );
      }
    },
    convertObjToString(){
    this.salvageBuyerObj.emailAddress=this.email_Obj.map(g=> {return g.email})
    this.salvageBuyerObj.attachments=[...this.aadharAttachment,...this.panAttachment,...this.otherAttachment]
  },
        viewDocUsingBase(base64) {
      const base64ImageData = base64;
      const contentType = base64.split(":")[1].split(";")[0];
      const byteCharacters = window.atob(
        base64ImageData.substr(`data:${contentType};base64,`.length)
      );
      const byteArrays = [];
      for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
        const slice = byteCharacters.slice(offset, offset + 1024);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
      const blob = new Blob(byteArrays, { type: contentType });
      const blobUrl = window.URL.createObjectURL(blob);
      window.open(blobUrl, "_blank");
    },
    update(){
      this.PUTM(
          "SALVAGE_BUYER_UPDATE",this.salvageBuyerObj.buyerId,
          this.salvageBuyerObj,
          (res) => {
            this.hideProgress();
            if (res.data && res.data.statusCode == 200) {
              this.showAlert(res?.data?.msg || "Success", "", "", () => {
                
                 
                this.cancel(1)              
              });
               this.isView=true
             }
            else if(res.data && res.data.statusCode == 400&&res.data.duplicate){
              this.showConfirm('Confirmation',res.data.msg,"Yes","No",(Yes) => {this.salvageBuyerObj.proceedDuplicate=res.data.duplicate})
            }  
            else {
              this.showAlert(res.data.msg);
              this.hideProgress();
            }
          },
          (err) => {
            console.log(err);
            this.hideProgress();
            let msg =
              err?.response?.data?.msg || "Unable to submit claim data.";
            this.showAlert(msg);
          }, true
        );
    },
    createRec(){
      
        this.POSTM(
          "SALVAGE_BUYER_CREATE",
          this.salvageBuyerObj,
          (res) => {
            this.hideProgress();
            if (res.data && res.data.statusCode == 200) {
              this.showAlert(res?.data?.msg || "Success", "", "", () => {
                
                // this.onReset();
                this.cancel(1)
                // this.$router.push('/Claim-processing/Child-Eduction/add-assesment-sheet');
                                
              });
              // this.isView=true
            }
            else if(res.data && res.data.statusCode == 400&&res.data.duplicate){
              this.showConfirm('Confirmation',res.data.msg,"Yes","No",(Yes) => {this.salvageBuyerObj.proceedDuplicate=res.data.duplicate})
            }
              else {
              this.showAlert(res.data.msg);
              this.hideProgress();
            }
          },
          (err) => {
            console.log(err);
            this.hideProgress();
            let msg =
              err?.response?.data?.msg || "Unable to submit claim data.";
            this.showAlert(msg);
          },true
        );
    },
    updateData(value){
      let a=`${value.item._id}`
      this.GET_ByURLM('SALVAGE_BUYER_GET',a,(res)=>{
        console.log(res.data.data,this.$route.params.isView)
        this.salvageBuyerObj=res.data.data
        
        this.pincode=this.salvageBuyerObj.postalCode
        this.pincode_Search()
        this.salvageBuyerObj.attachments.forEach(e=>{
          if(e.documentType == 'AADHAR'){
           this.aadharAttachment.push(e)
          }
          if(e.documentType == 'PAN'){
            this.panAttachment.push(e)
          }
          
          if(e.documentType == 'OTHERS'){          
            this.otherAttachment.push(e)
          }
        })
        this.checkTabChanged()
       this.email_Obj= this.salvageBuyerObj.emailAddress.map(e=>{return {email:e}})
       }, () => {}, true)
       this.isView=this.$route.params.isView
      if(this.$route.params.isView && Object.keys(this.$route.params).length != 0){
        this.heading="View"
      }
      if(!this.$route.params.isView && Object.keys(this.$route.params).length != 0){
        this.heading="Modify"
      }
    },
    onSubmit(){
    if (!this.$refs.salvageBuyer.validate()) {
        return this.showAlert("Please fill mandatory fields.");
      }else{
        this.convertObjToString()
        
        if(this.salvageBuyerObj._id){
         this.update()
        }else{
          this.createRec()
            }
      }
  },
  
   
},
computed:{
        computedPAN() {
            this.salvageBuyerObj.panNumber = this.salvageBuyerObj.panNumber ? this.salvageBuyerObj.panNumber.toUpperCase() : '';
            return this.salvageBuyerObj.panNumber;
        },
    },
    created(){
      if(Object.keys(this.$route.params).length != 0){
        this.updateData(this.$route.params)
        this.isModified=true
      }
      this.getLOB()
      this.$store.commit("SAVE_PAGE_HEADER", "Salvage Buyer Master");
    },
}
</script>
<style scoped>
.iconstyle {
  background: #f7f7f7 0% 0% no-repeat padding-box;
  border: 0.5px solid #c1c8cc !important;
  color: #fff;
  width: 29px;
  margin-left: -0.1em;
  height: 38px;
  color: #6b6b6b;
}
.blueiconstyle {
  width: 38px;
  height: 38px;
  background: #01bbd9 0% 0% no-repeat padding-box;
  margin-right: -0.5em;
  color: #f7f7f7;
}
.claimProcessingTabsActive {
  background-color: #1ebbd9;
  color: #fff !important;
}
.unActive{
  border:1px solid #ccc;
  margin: 0px 5px
}
.neftStyle{
        height: 150px;
        width: 350px;
        border: 3px dashed #ccc;
        /* padding-top: 40px;
        padding-left: 16%; */
        display: table-cell;
        vertical-align: middle;
        text-align: center;
}
.dragbackground{
        background-color: #e7e7e7;
}
.margincls{
        font-size:12px;
        margin-left:-12px
}
.dlt_text{
        color: rgb(145, 145, 145);
}
.tabelwidth{
  width:500px;
  margin:0px 150px
}
</style>