<template>
   <div>
       <p>FinancialAuthorityApprovalTbl</p>
        <v-card tile>
            <v-data-table              
                    :headers="headers"
                    :items="statusData"
                    :page.sync="page"
                    :items-per-page="statusItemsPerPage"
                    hide-default-footer
                    dense
                    @page-count="pageCount = $event"
                    height="500px"
            >          
            <template v-slot:[`item.recommendFAlevel`]="{item}" >
                    <v-select
                        flat
                        label="Select"
                        v-model="item.recommendFAlevel"
                        solo 
                        attach
                        :items="levels"
                        class="rounded-0"
                    ></v-select>
                </template>
                <template v-slot:[`item.remarks`]="{item}" >
                    <v-text-field   
                        flat
                        label="Enter..."
                        v-model="item.remarks"
                        solo 
                        class="rounded-0"
                    ></v-text-field>
                </template>
                <template v-slot:[`item.approve`]="{item}" >
                    <v-select
                        flat
                        label="Approved"
                        v-model="item.approve"
                        solo 
                        attach
                        :items="levelApprove"
                        class="rounded-0"
                    ></v-select>
                </template>
            </v-data-table>
            <v-card color="#F7F7F7" class="mb-6 pa-3 overflow-y-auto">
            <v-btn tile dark color="#23B1A9" class="float-right text-capitalize px-3 mr-3" small>
                <v-icon color="" small >check_circle</v-icon>
                <v-divider vertical class="ml-2 mr-2"></v-divider>Save
            </v-btn>
            </v-card>
        </v-card>
   </div>
</template>

<script>
export default {
    data(){
        return{
            headers:[
            {text: 'Sr. No.',value:'SrNo',sortable: false,divider:true,width: "80px",class:"hdr-txt-clr-Ot backColorOt"}, 
            {text: 'User ID/NT ID',value:'userId',sortable: false,divider:true,width: "155px",class:"hdr-txt-clr-Ot backColorOt"},
            {text: 'Employee code', value: 'employeeCode',sortable: false,divider:true,width: "155px",class:"hdr-txt-clr-Ot backColorOt"},
            {text: 'Name', value: 'name',sortable: false,divider:true,width: "155px",class:"hdr-txt-clr-Ot backColorOt"},
            {text: 'Major Line Code', value: 'majorLineCode',sortable: false,divider:true,width: "155px",class:"hdr-txt-clr-Ot backColorOt"},
            {text: 'Major Line Description',value: 'majorLineDescription',sortable: false,divider:true,width: "155px",class:"hdr-txt-clr-Ot backColorOt"},  
            {text: 'Existing Level',value: 'existingLevel',sortable: false,divider:true,width: "155px",class:"hdr-txt-clr-Ot backColorOt"},
            {text: 'Existing Reserving',value: 'existingReserving',sortable: false,divider:true,width: "155px",class:"hdr-txt-clr-Ot backColorOt",path: ['Yes', 'No']},   
            {text: 'Existing Payments',value: 'existingPayment',sortable: false,divider:true,width: "155px",class:"hdr-txt-clr-Ot backColorOt"}, 
            {text: 'Last updated date of existing FA Level',value:'lastUpdatedDateofexistingFAlevel',sortable: false,divider:true,width: "235px",class:"hdr-txt-clr-Ot backColorOt"}, 
            {text: 'Recommend FA level',value:'recommendFAlevel',sortable: false,divider:true,width: "200px",class:"hdr-txt-clr-Ot backColorOt"},
            {text: 'Recommend Reserving', value: 'recommendedReserving',sortable: false,divider:true,width: "172px",class:"hdr-txt-clr-Ot backColorOt"},
            {text: 'Recommend Payments', value: 'recommendedPayments',sortable: false,divider:true,width: "172px",class:"hdr-txt-clr-Ot backColorOt"},
            {text: 'Remarks', value: 'remarks',sortable: false,divider:true,width: "172px",class:"hdr-txt-clr-Ot backColorOt"},
            {text: 'Status',value: 'status',sortable: false,divider:true,class:"hdr-txt-clr-Ot backColorOt"},  
            {text: 'Approve',value: 'approve',sortable: false,divider:true,width: "152px",class:"hdr-txt-clr-Ot backColorOt"},
            {text: 'Reason for rejection',value: 'reasonForRejection',sortable: false,divider:true,width: "192px",class:"hdr-txt-clr-Ot backColorOt",path: ['Yes', 'No']},   
            {text: 'Approved Date by CCO',value: 'approvedDateCCO',sortable: false,divider:true,width: "172px",class:"hdr-txt-clr-Ot backColorOt"},
            {text: 'Date of System Update',value: 'systemDateUpdate',sortable: false,divider:true,width: "152px",class:"hdr-txt-clr-Ot backColorOt"},
          ],

          statusData:[
                {
                    SrNo:"1",
                    userId:"NEBAROT",
                    employeeCode:"3952433",
                    name:"NEEHA BAROT",
                    majorLineCode:"01",
                    majorLineDescription:"Auto OD",
                    existingLevel:"Level-II",
                    existingReserving:"15500",
                    existingPayment:"1500000",
                    lastUpdatedDateofexistingFAlevel:"Mar 18, 2019",
                    recommendFAlevel:"",
                    recommendedReserving:"50000",
                    recommendedPayments:"50000",
                    remarks:'',
                    status: "Submitted",
                    approve: "",
                    reasonForRejection: "Still needs some training",
                    approvedDateCCO: "Mar 18, 2019",
                    systemDateUpdate: "Mar 18, 2019"
                },
                {
                    SrNo:"2",
                    userId:"NEBAROT",
                    employeeCode:"3952433",
                    name:"NEEHA BAROT",
                    majorLineCode:"01",
                    majorLineDescription:"Auto OD",
                    existingLevel:"Level-II",
                    existingReserving:"15500",
                    existingPayment:"1500000",
                    lastUpdatedDateofexistingFAlevel:"Mar 18, 2019",
                    recommendFAlevel:"",
                    recommendedReserving:"50000",
                    recommendedPayments:"50000",
                    remarks:'',
                    status: "Submitted",
                    approve: "",
                    reasonForRejection: "Still needs some training",
                    approvedDateCCO: "Mar 18, 2019",
                    systemDateUpdate: "Mar 18, 2019"
                },
                {
                    SrNo:"3",
                    userId:"NEBAROT",
                    employeeCode:"3952433",
                    name:"NEEHA BAROT",
                    majorLineCode:"01",
                    majorLineDescription:"Auto OD",
                    existingLevel:"Level-II",
                    existingReserving:"15500",
                    existingPayment:"1500000",
                    lastUpdatedDateofexistingFAlevel:"Mar 18, 2019",
                    recommendFAlevel:"",
                    recommendedReserving:"50000",
                    recommendedPayments:"50000",
                    remarks:'',
                    status: "Submitted",
                    approve: "",
                    reasonForRejection: "Still needs some training",
                    approvedDateCCO: "Mar 18, 2019",
                    systemDateUpdate: "Mar 18, 2019"
                },
                {
                    SrNo:"4",
                    userId:"NEBAROT",
                    employeeCode:"3952433",
                    name:"NEEHA BAROT",
                    majorLineCode:"01",
                    majorLineDescription:"Auto OD",
                    existingLevel:"Level-II",
                    existingReserving:"15500",
                    existingPayment:"1500000",
                    lastUpdatedDateofexistingFAlevel:"Mar 18, 2019",
                    recommendFAlevel:"",
                    recommendedReserving:"50000",
                    recommendedPayments:"50000",
                    remarks:'',
                    status: "Submitted",
                    approve: "",
                    reasonForRejection: "Still needs some training",
                    approvedDateCCO: "Mar 18, 2019",
                    systemDateUpdate: "Mar 18, 2019"
                },
                {
                    SrNo:"5",
                    userId:"NEBAROT",
                    employeeCode:"3952433",
                    name:"NEEHA BAROT",
                    majorLineCode:"01",
                    majorLineDescription:"Auto OD",
                    existingLevel:"Level-II",
                    existingReserving:"15500",
                    existingPayment:"1500000",
                    lastUpdatedDateofexistingFAlevel:"Mar 18, 2019",
                    recommendFAlevel:"",
                    recommendedReserving:"50000",
                    recommendedPayments:"50000",
                    remarks:'',
                    status: "Submitted",
                    approve: "",
                    reasonForRejection: "Still needs some training",
                    approvedDateCCO: "Mar 18, 2019",
                    systemDateUpdate: "Mar 18, 2019"
                },
                {
                    SrNo:"6",
                    userId:"NEBAROT",
                    employeeCode:"3952433",
                    name:"NEEHA BAROT",
                    majorLineCode:"01",
                    majorLineDescription:"Auto OD",
                    existingLevel:"Level-II",
                    existingReserving:"15500",
                    existingPayment:"1500000",
                    lastUpdatedDateofexistingFAlevel:"Mar 18, 2019",
                    recommendFAlevel:"",
                    recommendedReserving:"50000",
                    recommendedPayments:"50000",
                    remarks:'',
                    status: "Submitted",
                    approve: "",
                    reasonForRejection: "Still needs some training",
                    approvedDateCCO: "Mar 18, 2019",
                    systemDateUpdate: "Mar 18, 2019"
                },
                {
                    SrNo:"7",
                    userId:"NEBAROT",
                    employeeCode:"3952433",
                    name:"NEEHA BAROT",
                    majorLineCode:"01",
                    majorLineDescription:"Auto OD",
                    existingLevel:"Level-II",
                    existingReserving:"15500",
                    existingPayment:"1500000",
                    lastUpdatedDateofexistingFAlevel:"Mar 18, 2019",
                    recommendFAlevel:"",
                    recommendedReserving:"50000",
                    recommendedPayments:"50000",
                    remarks:'',
                    status: "Submitted",
                    approve: "",
                    reasonForRejection: "Still needs some training",
                    approvedDateCCO: "Mar 18, 2019",
                    systemDateUpdate: "Mar 18, 2019"
                },
                {
                    SrNo:"8",
                    userId:"NEBAROT",
                    employeeCode:"3952433",
                    name:"NEEHA BAROT",
                    majorLineCode:"01",
                    majorLineDescription:"Auto OD",
                    existingLevel:"Level-II",
                    existingReserving:"15500",
                    existingPayment:"1500000",
                    lastUpdatedDateofexistingFAlevel:"Mar 18, 2019",
                    recommendFAlevel:"",
                    recommendedReserving:"50000",
                    recommendedPayments:"50000",
                    remarks:'',
                    status: "Submitted",
                    approve: "",
                    reasonForRejection: "Still needs some training",
                    approvedDateCCO: "Mar 18, 2019",
                    systemDateUpdate: "Mar 18, 2019"
                },
                {
                    SrNo:"9",
                    userId:"NEBAROT",
                    employeeCode:"3952433",
                    name:"NEEHA BAROT",
                    majorLineCode:"01",
                    majorLineDescription:"Auto OD",
                    existingLevel:"Level-II",
                    existingReserving:"15500",
                    existingPayment:"1500000",
                    lastUpdatedDateofexistingFAlevel:"Mar 18, 2019",
                    recommendFAlevel:"",
                    recommendedReserving:"50000",
                    recommendedPayments:"50000",
                    remarks:'',
                    status: "Submitted",
                    approve: "",
                    reasonForRejection: "Still needs some training",
                    approvedDateCCO: "Mar 18, 2019",
                    systemDateUpdate: "Mar 18, 2019"
                },
                {
                    SrNo:"10",
                    userId:"NEBAROT",
                    employeeCode:"3952433",
                    name:"NEEHA BAROT",
                    majorLineCode:"01",
                    majorLineDescription:"Auto OD",
                    existingLevel:"Level-II",
                    existingReserving:"15500",
                    existingPayment:"1500000",
                    lastUpdatedDateofexistingFAlevel:"Mar 18, 2019",
                    recommendFAlevel:"",
                    recommendedReserving:"50000",
                    recommendedPayments:"50000",
                    remarks:'',
                    status: "Submitted",
                    approve: "",
                    reasonForRejection: "Still needs some training",
                    approvedDateCCO: "Mar 18, 2019",
                    systemDateUpdate: "Mar 18, 2019"
                },

                {
                    SrNo:"11",
                    userId:"NEBAROT",
                    employeeCode:"3952433",
                    name:"NEEHA BAROT",
                    majorLineCode:"01",
                    majorLineDescription:"Auto OD",
                    existingLevel:"Level-II",
                    existingReserving:"15500",
                    existingPayment:"1500000",
                    lastUpdatedDateofexistingFAlevel:"Mar 18, 2019",
                    recommendFAlevel:"",
                    recommendedReserving:"50000",
                    recommendedPayments:"50000",
                    remarks:'',
                    status: "Submitted",
                    approve: "",
                    reasonForRejection: "Still needs some training",
                    approvedDateCCO: "Mar 18, 2019",
                    systemDateUpdate: "Mar 18, 2019"
                },{
                    SrNo:"12",
                    userId:"NEBAROT",
                    employeeCode:"3952433",
                    name:"NEEHA BAROT",
                    majorLineCode:"01",
                    majorLineDescription:"Auto OD",
                    existingLevel:"Level-II",
                    existingReserving:"15500",
                    existingPayment:"1500000",
                    lastUpdatedDateofexistingFAlevel:"Mar 18, 2019",
                    recommendFAlevel:"",
                    recommendedReserving:"50000",
                    recommendedPayments:"50000",
                    remarks:'',
                    status: "Submitted",
                    approve: "",
                    reasonForRejection: "Still needs some training",
                    approvedDateCCO: "Mar 18, 2019",
                    systemDateUpdate: "Mar 18, 2019"
                },
                {
                    SrNo:"13",
                    userId:"NEBAROT",
                    employeeCode:"3952433",
                    name:"NEEHA BAROT",
                    majorLineCode:"01",
                    majorLineDescription:"Auto OD",
                    existingLevel:"Level-II",
                    existingReserving:"15500",
                    existingPayment:"1500000",
                    lastUpdatedDateofexistingFAlevel:"Mar 18, 2019",
                    recommendFAlevel:"",
                    recommendedReserving:"50000",
                    recommendedPayments:"50000",
                    remarks:'',
                    status: "Submitted",
                    approve: "",
                    reasonForRejection: "Still needs some training",
                    approvedDateCCO: "Mar 18, 2019",
                    systemDateUpdate: "Mar 18, 2019"
                },
            ],
            levels:["Level1","Level2","Level3","Level4","Level5"],
            levelApprove: ["Approve", "Reject"]

        }
    }
}
</script>

<style>

</style>