<template>
  <div>
    <v-container fluid>
      <v-form ref="form">
      <v-layout row>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Member Details Available ( Y/N)
            </v-text>
            <v-select
              :items="lossStatesList"
              label="Select"
              v-model="memberVerificationDetail.memberDetailsAvelable"
              solo
              item-text="state"
              item-value="state"
              :rules="[required()]"
              class="rounded-0"
              @change="toggleAllFields(memberVerificationDetail.memberDetailsAvelable)"
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Patient Name
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="memberVerificationDetail.patientDetails.name"
              maxlength="100"
              counter="100"
              :disabled="disableAllFields"
              class="rounded-0"
              aria-required="true"
              :rules="!disableAllFields && [required()]"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex v-if="!showIDproofTxtBox" md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Patient ID Proof
            </v-text>
            <v-select
              solo
              label="Enter..."
              :items="idProofItems"
              v-model="memberVerificationDetail.patientDetails.idProof"
              maxlength="20"
              :disabled="disableAllFields"
              counter="20"
              class="rounded-0"
              aria-required="true"
              @change="changeIDProof(memberVerificationDetail.patientDetails.idProof)"
              :rules="!disableAllFields && [required()]"
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex v-else md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Patient ID Proof
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="memberVerificationDetail.patientDetails.idProof"
              maxlength="20"
              counter="20"
              :disabled="disableAllFields"
              class="rounded-0"
              aria-required="true"
              :rules="!disableAllFields && [required()]"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Patient ID Number
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="memberVerificationDetail.patientDetails.idNumber"
              maxlength="50"
              counter="50"
              :disabled="disableAllFields"
              class="rounded-0"
              aria-required="true"
              :rules="!disableAllFields && [required(),alphaNumeric(memberVerificationDetail.patientDetails.idNumber, 'Patient ID Number')]"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Patient Occupation
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="memberVerificationDetail.patientDetails.occupation"
              maxlength="20"
              counter="20"
              class="rounded-0"
              :disabled="disableAllFields"
              aria-required="true"
              :rules="!disableAllFields && [required()]"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Patient Age
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="memberVerificationDetail.patientDetails.age"
              maxlength="3"
              counter="3"
              class="rounded-0"
              :disabled="disableAllFields"
              aria-required="true"
              :rules="!disableAllFields && [required(),ageRules(memberVerificationDetail.patientDetails.age)]"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Patient Gender
            </v-text>
            <v-select
              :items="genderItems"
              solo
              label="Enter..."
              v-model="memberVerificationDetail.patientDetails.gender"
              :disabled="disableAllFields"
              class="rounded-0"
              aria-required="true"
              :rules="!disableAllFields && [required()]"
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Patient Mobile Number
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="memberVerificationDetail.patientDetails.mobileNumber"
              maxlength="10"
              counter="10"
              :disabled="disableAllFields"
              class="rounded-0"
              aria-required="true"
              :rules="!disableAllFields && [required(),numericOnly(memberVerificationDetail.patientDetails.mobileNumber)]"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Patient Email ID
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="memberVerificationDetail.patientDetails.email"
              maxlength="50"
              counter="50"
              :disabled="disableAllFields"
              class="rounded-0"
              aria-required="true"
              :rules="[emailRules2]"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Relationship of Patient with Insured
            </v-text>
            <v-select
              :items="relationList"
              label="Select"
              v-model="memberVerificationDetail.reltionWithpatientWithInsured"
              solo
              item-text="state"
              item-value="state"
              :disabled="disableAllFields"
              class="rounded-0"
              :rules="!disableAllFields && [required()]"
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Relationship of Patient with Proposer
            </v-text>
            <v-select
              :items="relationList"
              label="Select"
              v-model="memberVerificationDetail.reltionWithpatientWithProposer"
              solo
              item-text="state"
              item-value="state"
              :disabled="disableAllFields"
              class="rounded-0"
              :rules="!disableAllFields && [required()]"
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Relationship of Patient with Nominee
            </v-text>
            <v-select
              :items="relationList"
              label="Select"
              v-model="memberVerificationDetail.reltionWithpatientWithNominee"
              solo
              item-text="state"
              item-value="state"
              :disabled="disableAllFields"
              class="rounded-0"
              :rules="!disableAllFields && [required()]"
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Type of Complaint
            </v-text>
            <v-text-field
              v-model="typeOfComplaint"
              label="Enter..."
              solo
              maxlength="30"
              counter="30"
              :disabled="disableAllFields"
              class="rounded-0"
              :rules="!disableAllFields && [required()]"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-tooltip top> 
              <template v-slot:activator="{ on, attrs }">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Details of Complaint
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="detailsOfComplaint"
              maxlength="500"
              v-on="on" 
              v-bind="attrs"
              counter="500"
              class="rounded-0"
              :disabled="disableAllFields"
              aria-required="true"
              :rules="!disableAllFields && [required()]"
            >
            </v-text-field>
            </template>
              <span>{{detailsOfComplaint}}</span>
              </v-tooltip>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Duration of complaint/First diagnosed
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="memberVerificationDetail.durationofComplaintOrFirstDiagnosed"
              maxlength="20"
              counter="20"
              class="rounded-0"
              aria-required="true"
              :disabled="disableAllFields"
              :rules="!disableAllFields && [required()]"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Date of First Consultation is same as per claim form (Y/N)
            </v-text>
            <v-select
              :items="lossStatesList"
              label="Select"
              v-model="memberVerificationDetail.isDateSameasFirstConsultaionAndClainForm"
              :rules="!disableAllFields && rules.mandatoryrule"
              solo
              item-text="state"
              item-value="state"
              :disabled="disableAllFields"
              class="rounded-0"
              
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Previous Hospitalization History
            </v-text>
            <v-select
              :items="lossStatesList"
              label="Select"
              v-model="memberVerificationDetail.previousHospitlizationHistory"
              :rules="!disableAllFields && rules.mandatoryrule"
              solo
              item-text="state"
              item-value="state"
              :disabled="disableAllFields"
              class="rounded-0"
              
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Previous Disease History
            </v-text>
            <v-select
              :items="lossStatesList"
              label="Select"
              v-model="memberVerificationDetail.previousDiseaseHistory"
              :rules="!disableAllFields && rules.mandatoryrule"
              solo
              item-text="state"
              item-value="state"
              :disabled="disableAllFields"
              class="rounded-0"
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Surgical history
            </v-text>
            <v-select
              :items="lossStatesList"
              label="Select"
              v-model="memberVerificationDetail.surgicalHistory"
              :rules="!disableAllFields && rules.mandatoryrule"
              solo
              item-text="state"
              item-value="state"
              class="rounded-0"
              :disabled="disableAllFields"
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Accidental History
            </v-text>
            <v-select
              :items="lossStatesList"
              label="Select"
              v-model="memberVerificationDetail.accidentalHistory"
              :rules="!disableAllFields && rules.mandatoryrule"
              solo
              item-text="state"
              :disabled="disableAllFields"
              item-value="state"
              class="rounded-0"
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              H/O Alcohol Intake
            </v-text>
            <v-select
              :items="lossStatesList"
              label="Select"
              v-model="memberVerificationDetail.hoAlchoalIntake"
              :rules="!disableAllFields && rules.mandatoryrule"
              solo
              item-text="state"
              item-value="state"
              class="rounded-0"
              :disabled="disableAllFields"
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              H/O Tobaco Chewing
            </v-text>
            <v-select
              :items="lossStatesList"
              label="Select"
              v-model="memberVerificationDetail.hoTobacoChewing"
              :rules="!disableAllFields && rules.mandatoryrule"
              solo
              item-text="state"
              item-value="state"
              :disabled="disableAllFields"
              class="rounded-0"
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              H/O Tobaco Smoking
            </v-text>
            <v-select
              :items="lossStatesList"
              label="Select"
              v-model="memberVerificationDetail.hoTobacoChewing"
              :rules="!disableAllFields && rules.mandatoryrule"
              solo
              item-text="state"
              item-value="state"
              class="rounded-0"
              :disabled="disableAllFields"
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-tooltip top> 
              <template v-slot:activator="{ on, attrs }">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Any Other Addiction
            </v-text>
            <v-text-field
              solo
              v-on="on" 
              v-bind="attrs"
              label="Enter..."
              v-model="memberVerificationDetail.anyOtherEdection"
              maxlength="100"
              counter="100"
              :disabled="disableAllFields"
              class="rounded-0"
              aria-required="true"
              :rules="!disableAllFields && [required()]"
            >
            </v-text-field>
            </template>
              <span>{{memberVerificationDetail.anyOtherEdection}}</span>
              </v-tooltip>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Any Other Insurance polices (Y/N)
            </v-text>
            <v-select
              :items="lossStatesList"
              label="Select"
              v-model="memberVerificationDetail.anyOtherInsurancePolicy"
              :rules="!disableAllFields && rules.mandatoryrule"
              solo
              item-text="state"
              item-value="state"
              :disabled="disableAllFields"
              class="rounded-0"
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Other Insurance Policy details
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="memberVerificationDetail.anyOtherInsurancePolicyDetails"
              maxlength="100"
              counter="100"
              class="rounded-0"
              aria-required="true"
              :disabled="disableInsuracePolicy"
              :rules="!disableInsuracePolicy && [required()]"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Any Previous Claims (Y/N)
            </v-text>
            <v-select
              :items="lossStatesList"
              label="Select"
              v-model="anyPreviousClaims"
              :rules="!disableAllFields && rules.mandatoryrule"
              :disabled="disableAllFields"
              solo
              item-text="state"
              item-value="state"
              class="rounded-0"
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Previous Claim Details
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="memberVerificationDetail.previousClaimDetails"
              maxlength="100"
              counter="100"
              class="rounded-0"
              :disabled="disablePreviosClaim"
              aria-required="true"
              :rules="!disablePreviosClaim && [required()]"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Address of Patient is same as per Claim form (Y/N)
            </v-text>
            <v-select
              :items="lossStatesList"
              label="Select"
              v-model="memberVerificationDetail.isPatientAddressSameAsPerClaimForm"
              :rules="!disableAllFields && rules.mandatoryrule"
              solo
              item-text="state"
              item-value="state"
              class="rounded-0"
              :disabled="disableAllFields"
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Patient Current Address
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="memberVerificationDetail.patientDetails.currentAddress"
              maxlength="500"
              counter="500"
              :disabled="disablePatientCurAddress"
              class="rounded-0"
              :rules="!disablePatientCurAddress && [required()]"
              aria-required="true"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Patient Current State
            </v-text>
            <v-text-field
              label="Enter..."
              v-model="memberVerificationDetail.patientDetails.currentState"
              :rules="!disablePatientCurAddress && rules.mandatoryrule"
              solo
              :disabled="disablePatientCurAddress"
              item-text="state"
              item-value="state"
              class="rounded-0"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Patient Current City
            </v-text>
            <v-text-field
              label="Enter..."
              v-model="memberVerificationDetail.patientDetails.currentCity"
              :rules="!disablePatientCurAddress && rules.mandatoryrule"
              solo
              :disabled="disablePatientCurAddress"
              item-text="state"
              item-value="state"
              class="rounded-0"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Patient Current PIN Code
            </v-text>
            <v-autocomplete
              :items="pincodes"
              label="Enter to search..."
              v-model="pinNumber"
              :rules="!disablePatientCurAddress && rules.mandatoryrule"
              maxlength="6"
              :search-input.sync="searchPinCodes"
              solo
              :disabled="disablePatientCurAddress"
              item-text="locpincode"
              item-value="locpincode"
              class="rounded-0"
              @change="pinCodeChangeHandler"
            >
            </v-autocomplete>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Patient Employer details
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="memberVerificationDetail.patientDetails.employerDetails"
              maxlength="200"
              counter="200"
              :disabled="disableAllFields"
              class="rounded-0"
              aria-required="true"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Name of the intermediary/ agent from whom policy purchased
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="memberVerificationDetail.nameOfIntermediaryOrAgentfromPolicyPurchesed"
              maxlength="100"
              counter="100"
              :disabled="disableAllFields"
              class="rounded-0"
              aria-required="true"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Remarks
            </v-text>
            <v-textarea
              solo
              label="Enter..."
              v-model="memberVerificationDetail.remarks"
              maxlength="5000"
              counter="5000"
              class="rounded-0"
              aria-required="true"
              :rules="[required()]"
            >
            </v-textarea>
          </v-layout>
        </v-flex>
      </v-layout>
      </v-form>
    </v-container>
    <InvestigationFooterCard :tabno="sno" ref="footerCardRef" :formdata="getFormData" @validateTabForms="validateTabForms" :nonMandatoryFields="nonMandatoryFields" :siuWorkItemId="siuWorkItemId" :tabFormDataId="tabFormDataId" :componentName="'memberVerificationDetail'" />
  </div>
</template>
<script>
  import InvestigationFooterCard from './InvestigationFooterCard.vue';
  import {getPrePopulatedData,getAllPinCodes} from '../apiservices/Investigation';
  export default {
    name: 'MemberVerification',
    components: {
      InvestigationFooterCard 
    },
    computed: {
        disableInsuracePolicy(){
      if(this.memberVerificationDetail.anyOtherInsurancePolicy == 'Y' && !this.disableAllFields){
        return false;
      }else{
        return true;
      }
    },
    disablePatientCurAddress(){
      if(this.memberVerificationDetail.isPatientAddressSameAsPerClaimForm == 'N' && !this.disableAllFields){
        return false;
      }
      else{
        return true;
      }
    },
    disablePreviosClaim(){
      if(this.anyPreviousClaims == 'Y' && !this.disableAllFields){
        return false;
      }else{
        return true;
      }
    },
        getFormData() {
          return {memberVerificationDetail: this.$data.memberVerificationDetail};
        }
    },
    watch:{
      async searchPinCodes(value) {
        if(value === null || value == undefined || value == '' || value.length < 3){
            this.pincodes = []
            this.memberVerificationDetail.patientDetails.currentState = '';
            this.memberVerificationDetail.patientDetails.currentCity = '';
        }
      if (value && value.length > 2) {
        this.pincodes = await getAllPinCodes(value.split(" - ")[0])
      }
    },
    },
    data() {
     return {
      pinNumber:null,
      searchPinCodes:'',
      pincodes: [],
      genderItems:["Male","Female","Others"],
      disableAllFields:false,
      showIDproofTxtBox:false,
      idProofItems:["PAN card","Aadhar card","voter id","driving license","passport","Ration card","Others"],
      lossStatesList:['Y','N'],
      sno: 6,
      rules: {
        mandatoryrule: [(v) => !!v || this.validationmessage],
      },
      validationmessage: "This field is required",
      typeOfComplaint: "",
      detailsOfComplaint: "",
      anyPreviousClaims: "",
      nonMandatoryFields: ['email','employerDetails','nameOfIntermediaryOrAgentfromPolicyPurchesed'],
      relationList: ['Father', 'Mother','Self ','Spouse', 'Son','Daughter','Sister','Brother','Friend', 'Others'],
      memberVerificationDetail: {
        isTabSelected: true,
        memberDetailsAvelable: "",
        patientDetails:{
          name: '',
          idProof: '',
          idNumber:'',
          occupation:'',
          age:'',
          gender:'',
          mobileNumber:'',
          email:'',
          currentAddress:'',
          currentPinCode: '',
          currentState:'',
          currentCity: '',
          employerDetails: ''
        },
        reltionWithpatientWithInsured: "",
        reltionWithpatientWithProposer: "",
        reltionWithpatientWithNominee: "",
        durationofComplaintOrFirstDiagnosed: "",
        isDateSameasFirstConsultaionAndClainForm: "",
        previousHospitlizationHistory: "",// not givem
        previousDiseaseHistory: "",
        surgicalHistory: "",
        accidentalHistory: "",
        hoAlchoalIntake: "",
        hoTobacoChewing: "",
        hoSmoking: "", // not given
        anyOtherEdection: "",
        anyOtherInsurancePolicy: "",
        anyOtherInsurancePolicyDetails: "",
        previousClaimDetails: "",
        isPatientAddressSameAsPerClaimForm: "",
        nameOfIntermediaryOrAgentfromPolicyPurchesed: "",
        remarks: "",
      },
      required() {
        return (v) => (v && v.length > 0) || "This Field is Required";
      },
      }
    },
    props: ['prepoulatedData',"siuWorkItemId", "tabFormDataId"],
    created() {
      console.log("PREPOPULATED DATA", this.prepoulatedData);
      let allKeys = Object.keys(this.prepoulatedData);
      allKeys.forEach(key => {
        if(this.memberVerificationDetail[key] !== undefined) {
          this.memberVerificationDetail[key] = this.prepoulatedData[key];
        } else {
          this.memberVerificationDetail.patientDetails[key] = this.prepoulatedData.patientDetails[key];
        }
      })
    },
  methods: {
    validateTabForms(){
      if(!this.$refs.form.validate()){
        this.$refs.form.validate() 
        this.showToast("Please fill all mandatory fields", this.TOST().WARNING);
        return
      }else{
        this.$refs.footerCardRef.interimSave();
      }
    },
    pinCodeChangeHandler(v) {
      const foundData = this.pincodes.find((item) => item.pinCode === v.split(" - ")[0]);
      this.memberVerificationDetail.patientDetails.currentState = foundData.stateName;
      this.memberVerificationDetail.patientDetails.currentCity = foundData.cityDistrictName;
      this.memberVerificationDetail.patientDetails.currentPinCode = this.pinNumber ? Number(this.pinNumber.split(' - ')[0]) : null;
    },
    toggleAllFields(val){
      if(val == 'N'){
        this.disableAllFields = true;
        this.clearAllFields();
      }else{    
        this.disableAllFields = false  
      }
    },
    clearAllFields(){
      this.memberVerificationDetail.patientDetails.name = '';
      this.memberVerificationDetail.patientDetails.idProof = '';
      this.memberVerificationDetail.patientDetails.idNumber = '';
      this.memberVerificationDetail.patientDetails.occupation = '';
      this.memberVerificationDetail.patientDetails.age = '';
      this.memberVerificationDetail.patientDetails.gender = '';
      this.memberVerificationDetail.patientDetails.mobileNumber = '';
      this.memberVerificationDetail.patientDetails.email = '';
      this.memberVerificationDetail.reltionWithpatientWithInsured = '';
      this.memberVerificationDetail.reltionWithpatientWithNominee = '';
      this.memberVerificationDetail.durationofComplaintOrFirstDiagnosed = '';
      this.memberVerificationDetail.reltionWithpatientWithProposer = '';
      this.memberVerificationDetail.previousHospitlizationHistory = '';
      this.memberVerificationDetail.isDateSameasFirstConsultaionAndClainForm = '';
      this.memberVerificationDetail.previousDiseaseHistory = '';
      this.memberVerificationDetail.surgicalHistory = '';
      this.memberVerificationDetail.accidentalHistory = '';
      this.memberVerificationDetail.hoAlchoalIntake = '';
      this.memberVerificationDetail.hoTobacoChewing = '';
      this.memberVerificationDetail.anyOtherEdection = '';
      this.memberVerificationDetail.anyOtherInsurancePolicy = '';
      this.memberVerificationDetail.anyOtherInsurancePolicyDetails = '';
      this.memberVerificationDetail.previousClaimDetails = '';
      this.memberVerificationDetail.isPatientAddressSameAsPerClaimForm = '';
      this.memberVerificationDetail.nameOfIntermediaryOrAgentfromPolicyPurchesed = '';
      this.memberVerificationDetail.patientDetails.currentAddress = '';
      this.memberVerificationDetail.patientDetails.currentState = '';
      this.memberVerificationDetail.patientDetails.currentCity = '';
      this.memberVerificationDetail.patientDetails.currentPinCode = null;
      this.memberVerificationDetail.patientDetails.employerDetails = '';
      this.typeOfComplaint = '';
      this.detailsOfComplaint = '';
      this.anyPreviousClaims = '';
      this.pinNumber = null;
      
    },
    changeIDProof(val){
      if(val == 'Others'){
        this.showIDproofTxtBox = true;
      }else{
        this.showIDproofTxtBox = false;
      }
    },
    emailRules2(v) {
            if (v.length !== 0) {
                console.log("testing", /^[0-9 ]+$/.test(v));
                if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,6})+$/.test(v)) {
                return "E-mail must be valid";
                }
            }
        },
    dragleave(event) {
      this.visualEffect = false
    },
    browseCancel() {
      //to select attachments
    //   document.getElementById("cancelchequeupload").click()
    },
    dragover(event) {
      event.preventDefault()
      this.visualEffect = true
    },
    drop(event) {
      event.preventDefault()
    //   this.selectFile() // Trigger the onChange event manually
      this.visualEffect = false
    },
    computedDate(name) {
      return this[name] ? moment(this[name]).format("DD/MM/YYYY") : "";
    },
    goToPreviousTab() {
      this.sno--;
      let btnNumber = "myBtn" + this.sno;
      const elem = this.$refs[btnNumber];
      elem[0].$el.click();
    },
    goToNextTab() {
      this.sno++;
      let btnNumber = "myBtn" + this.sno;
      const elem = this.$refs[btnNumber];
      elem[0].$el.click();
    },
    switchTabs(sno, name) {
      console.log('sno', sno, name);
      this.sno = sno;
      this.tabName = name;
    },
    submit() {
      this.isAlertView = true;
    },
    accept() {
      this.isAlertView = true;
    },
    saveData() {},
    alphaNumeric(fieldName, name, mandatory=true) {
      console.log('fieldName', fieldName, name);
      if (fieldName && fieldName.length > 0) {
        return (v) => /^[a-zA-Z0-9]+$/.test(v) || "Enter valid " + name;
      }
    },
    numericOnly(val) {
      if(val && val.length > 0){
        return (v) =>/^[1-9]\d{9}$/.test(v) || `Only Numbers are allowed.`;
      }
    },
    ageRules(val){
       if(val && val.length > 0){
        return (v) =>/^(?!0)\d{1,3}$/.test(v) || `Enter valid age value.`;
      }
    },
    onSubmit() {},
    browse() {},
    selectFile() {},
  },
}
</script>