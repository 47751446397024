<template>
   <div>
    <v-container fluid>
      <v-layout row>
        <v-flex md2 sm3 xs6>
          <v-layout column class="cust_usrsrch mx-2 mt-5">
            <p class="font-weight-bold cstm-clr-pln-usrsrch">
                Producer Name
            </p>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.producerName"
              maxlength="20"
              counter="20"
              class="rounded-0"
              aria-required="true"
              disabled
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6>
          <v-layout column class="cust_usrsrch mx-2 mt-5">
            <p class="font-weight-bold cstm-clr-pln-usrsrch">
              Premium Receipt Date
            </p>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.premiumReceiptDate"
              maxlength="20"
              counter="20"
              class="rounded-0"
              aria-required="true"
              disabled
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6>
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <p class="font-weight-bold cstm-clr-pln-usrsrch mb-1">
              Premium Collection Mode (Cheq/Cash/NEFT/Online)
            </p>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.premiumCollectionMode"
              maxlength="20"
              counter="20"
              class="rounded-0"
              aria-required="true"
              disabled
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6>
          <v-layout column class="cust_usrsrch mx-2 mt-5">
            <p class="font-weight-bold cstm-clr-pln-usrsrch">
              Policy Date of Realization
            </p>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.policyDateOfRealization"
              maxlength="20"
              counter="20"
              class="rounded-0"
              aria-required="true"
              disabled
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6>
          <v-layout column class="cust_usrsrch mx-2 mt-5">
            <p class="font-weight-bold cstm-clr-pln-usrsrch">
              Policy Collection Status
            </p>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.policyCollectionStatus"
              maxlength="20"
              counter="20"
              class="rounded-0"
              aria-required="true"
              disabled
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6>
          <v-layout column class="cust_usrsrch mx-2 mt-5">
            <p class="font-weight-bold cstm-clr-pln-usrsrch">
              Premium Payer Details
            </p>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.premiumPayerDetails"
              maxlength="20"
              counter="20"
              class="rounded-0"
              aria-required="true"
              disabled
            >
            </v-text-field>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>
<script>
 export default {
    data() {
        return {
            formData: {
              producerName: "",
              premiumReceiptDate: "",
              premiumCollectionMode: "",
              policyDateOfRealization: "",
              policyCollectionStatus: "",
              premiumPayerDetails: ""
            }
        }
    },
    props: ['claimDetails'],
    mounted() {
      this.setPayerDetails();
     },
     methods: {
      async setPayerDetails() {
        try {
          this.formData.memberName = this.claimDetails?.claimsDetail?.memberName;
        } catch(e) {
          this.showToast(e.response?.data?.msg || e.response?.data  || e.response || e, this.TOST().ERROR);
        }
      }
     }
 }
</script>