<template>
<v-app>
        
        <div style="background-color: #FFFFFF;" column class="py-3">
            <v-form v-model="sdvalid" ref="sdForm">
        <v-layout class=" mt-2" >
             
            <v-flex xs2>
                    <v-layout column class="mx-2 my-2">
                        <span class="text-uppercase font-weight-bold title-clr-pr">
                            Staff Details
                        </span>
                        <v-divider></v-divider>
                    </v-layout>
                </v-flex>


                <v-flex xs3>
                    <v-layout column class="cust_pd mx-2 my-2 ">
                        <span class="font-weight-bold cstm-clr-pd">
                        No. of Full Time Consultants/Sr Consultants *
                        </span>
                        <v-text-field
                            label="Select"
                            v-model="fulltimeConsult"
                            solo
                            dense
                            class="rounded-0"
                            :rules="[required(),alphaNumeric(),numberRule()]"
                             :maxlength="max05"
                        >
                        </v-text-field>
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class=" mx-2 my-2 cust_pd">
                        <span class="font-weight-bold cstm-clr-pd">
                            No. of Visiting Consultants/Sr Consultants *
                        </span>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="visitingConsult"
                           :maxlength="max05"
                            class="rounded-0"
                            :rules="[required(),alphaNumeric()]"
                            >
                        </v-text-field>
                    </v-layout>
                </v-flex>

                <v-flex xs5>
                    <v-layout column class=" mx-2 my-2 cust_pd">
                        <span class="font-weight-bold cstm-clr-pd">
                            No. of Qualified Doctors (Full Time) RMO/DMO Registrar/Jr Consultant *
                        </span>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="qualifiedDoctor"
                           :maxlength="max05"
                            class="rounded-0"
                            :rules="[required(),alphaNumeric()]"
                        >
                        </v-text-field>
                    </v-layout>
                </v-flex>
                              
            </v-layout>
                
            <v-layout>
               
               <v-flex xs3>
                    <v-layout column class=" mx-2 my-2 cust_pd">
                        <span class="font-weight-bold cstm-clr-pd">
                            No. of Registered Nurses *
                        </span>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="regNurse"
                           :maxlength="max05"
                            class="rounded-0"
                            :rules="[required(),alphaNumeric()]"
                        >
                        </v-text-field>
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class=" mx-2 my-2 cust_pd">
                        <span class="font-weight-bold cstm-clr-pd">
                            No. of Unregistered Nurses *
                        </span>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="unregNurse"
                           :maxlength="max05"
                            class="rounded-0"
                            :rules="[required(),alphaNumeric()]"
                            >
                        </v-text-field>
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class=" mx-2 my-2 cust_pd">
                        <span class="font-weight-bold cstm-clr-pd">
                            No. of Paramedical Staff *
                        </span>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="paramedicStaff"
                            class="rounded-0"
                            :maxlength="max05"
                            :rules="[required(),alphaNumeric()]"
                        >
                        </v-text-field>
                    </v-layout>
                </v-flex>

                
                <v-flex xs3></v-flex>
                <v-flex xs3></v-flex>

        </v-layout>


         <v-dialog v-model="confirmDialogCancel" persistent max-width="450px">
                 
                         <v-card tile color="#F7F7F7" >
                            <v-card tile>
                                <v-layout justify-space-between  class="" style="flex:1;background-ox-shadow: 0px 3px 5px #00000029;">
                             
                                    <v-card-title class="pa-0 pl-3 text-capitalize font-weight-regular" style="color:black"> 
                                       CONFIRMATION
                                    </v-card-title>
                                    <div style="border-left:1px solid #C1C8CC;" @click="close_cancelDialog()">
                                        <v-icon style="cursor: pointer;" class="pa-2" >close</v-icon>
                                    </div>
                                </v-layout>
                            </v-card>
                        <v-flex >
                           
                       
                        </v-flex>
                        <v-card-text >
                                Details saved on screen will be lost
                        </v-card-text>
                        <v-card-actions justify-start>
                          
                           
                           
                            <v-btn dark  tile color="black" @click="confirmDialogCancel = false" class="text-capitalize px-3 mx-2" small><v-icon dark small>cancel</v-icon> <v-divider vertical class="mx-2"></v-divider>Cancel</v-btn>

                            <!-- <v-btn depressed small color="secondary" class="rounded-0"  >
                                <v-icon dark right>check_circle</v-icon>
                            </v-btn> -->
                            <v-btn @click="selectedCompFromPage(arrToNavCancel[1])" dark  tile color="#23B1A9" class="text-capitalize px-3 ml-2" small><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider> OK</v-btn>

                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <v-dialog v-model="confirmDialogNext" persistent max-width="450px">
                 
                         <v-card tile color="#F7F7F7" >
                            <v-card tile>
                                <v-layout justify-space-between  class="" style="flex:1;background-ox-shadow: 0px 3px 5px #00000029;">
                             
                                    <v-card-title class="pa-0 pl-3 text-capitalize font-weight-regular" style="color:black"> 
                                       CONFIRMATION
                                    </v-card-title>
                                    <div style="border-left:1px solid #C1C8CC;" @click="close_cancelDialog()">
                                        <v-icon style="cursor: pointer;" class="pa-2" >close</v-icon>
                                    </div>
                                </v-layout>
                            </v-card>
                        <v-flex >
                           
                       
                        </v-flex>
                        <v-card-text >
                                 Details entered on the page will be lost if not saved
                        </v-card-text>
                        <v-card-actions justify-start>
                          
                           
                           
                            <v-btn dark  tile color="black" @click="confirmDialogNext = false" class="text-capitalize px-3 mx-2" small><v-icon dark small>cancel</v-icon> <v-divider vertical class="mx-2"></v-divider>Cancel</v-btn>

                            <!-- <v-btn depressed small color="secondary" class="rounded-0"  >
                                <v-icon dark right>check_circle</v-icon>
                            </v-btn> -->
                            <v-btn @click="selectedCompFromPage('PrvdrEmpnlmntFacilities')" dark  tile color="#23B1A9" class="text-capitalize px-3 ml-2" small><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider> OK</v-btn>

                        </v-card-actions>
                    </v-card>
                </v-dialog>


            <div class="mx-2 pa-5" style="background-color: #F7F7F7; display:flex;justify-content:space-between;"  >
                <v-layout style="flex:1" justify-end > 
                    <v-btn dark  tile color="#E46A25" class="text-capitalize px-3 mx-2" small @click="cancelData()"><v-icon dark small>cancel</v-icon> <v-divider vertical class="mx-2"></v-divider>Cancel</v-btn>
                    <v-btn dark  tile color="#D1121B" class="text-capitalize px-3 mx-2" small @click="StaffDetails_Clear()"><v-icon dark small>delete</v-icon> <v-divider vertical class="mx-2"></v-divider>Clear</v-btn>
                    <v-btn dark  tile color="#23B1A9" class="text-capitalize px-3 ml-2" small @click="StaffDetails_save()"><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider> Save</v-btn>
                    <v-btn dark  tile color="black" class="text-capitalize px-3 ml-4" small @click="nextBtn()"><v-icon dark small>arrow_forward</v-icon> <v-divider vertical class="mx-2"></v-divider> Next</v-btn>
                </v-layout>
            </div>
             </v-form>
        </div>
       
        
    </v-app>
</template>

<script>
  
export default {

    inject : ['selectedCompFromPage', 'arrToNavCancel'],
created() {

    let userID = this.$store.state.empanelProviderData.empanelment._id
    // let _proviType = this.$store.state.empanelProviderData.empanelment.providerType
    this.getEmpanelmentDetailsbyId(userID)
  },

    data() {
        return {
            confirmDialogCancel:false,
            confirmDialogNext:false,
            fulltimeConsult:'',
            visitingConsult:'',
            qualifiedDoctor:'',
            regNurse:'',
            unregNurse:'',
            paramedicStaff:'',
            sdvalid:false,
            max05:5,
            required(){
                return v=>v && v.length>0 || "This Field is Required"
            },
            minLength(minLength){
                return v=>v && v.length>=minLength || `Must be atleast ${minLength} Characters`
            },
            maxLength(maxLength){
                return v=>v && v.length<=maxLength || `Must be less than ${maxLength} Characters`
            },
            spclChar(){
                return v=>/^[A-Za-z0-9 ]+$/.test(v) || `Special Characters Not Allowed`
            },
            alphaNumeric(){
                return v=>/^[0-9 ]+$/.test(v) || `Only Numbers are allowed.`
            },
            numberRule(){
                // if (!v.trim()) return true;
                // if (!isNaN(parseFloat(v)) && v >= 0 && v <= 999) return true;
                // return 'Number has to be between 0 and 999';
                return v=>v && v>0 || "Cannot be zero"
            },
            decimalRule(){
                return v=>/^\d+(\.\d{1,2})?$/.test(v) || `Numeric upto two decimals.`
            }

        }
    },
    methods: {
        StaffDetails_Clear(){
            this.$refs.sdForm.resetValidation()
            this.fulltimeConsult=''
            this.visitingConsult=''
            this.qualifiedDoctor=''
            this.regNurse=''
            this.unregNurse=''
            this.paramedicStaff=''

        },

        StaffDetails_save(){

            if(parseInt(this.regNurse)===0 && parseInt(this.unregNurse)===0){
                this.showToast("Both registered and unregistered nurses cannot be zero",this.TOST().WARNING); 
                return
            }
            if (this.$refs.sdForm.validate()){
                // this.showToast("Success",this.TOST().SUCCESS);
                let userID = this.$store.state.empanelProviderData.empanelment._id
                let _proviType = this.$store.state.empanelProviderData.empanelment.providerType

                let formData={
                    _id:userID,
                    providerType:_proviType,
                    noOfFullTimeConsultants:parseInt(this.fulltimeConsult),
                    noOfVisitingConsultants:parseInt(this.visitingConsult),
                    noOfQualifiedDoctors:parseInt(this.qualifiedDoctor),
                    noOfRegisteredNurses:parseInt(this.regNurse),
                    noOfUnRegisteredNurses:parseInt(this.unregNurse),
                    noOfParamedicalStaff:parseInt(this.paramedicStaff),
                    
                }
                console.log(formData,"=========sdForm Data")
                let apiUrl='empanelment/save-staff-details'
                this.PUT(2,apiUrl,formData,(res,error)=>{
                    if(!error){
                          console.log("Put APi response|-> empanelment/save-staff-details ", res)
                          this.getEmpanelmentDetailsbyId(userID)
                          this.showToast(res.data.msg,this.TOST().SUCCESS)
                        //   this.$router.push('/homePage');
                    }else{
                        console.log("Put APi error response|-> empanelment/save-staff-details ", error)
                        this.showToast(error.response.data.msg ,this.TOST().ERROR);
                    }
                })
            }else{
                this.$refs.sdForm.validate()
                console.log(this.regNurse)
                if (this.regNurse===''||this.regNurse===undefined||this.regNurse===null||parseInt(this.regNurse)===0|| this.unregNurse===''|| this.unregNurse===undefined||this.unregNurse===null||parseInt(this.unregNurse)===0){
                    this.showToast("Invalid nurse details",this.TOST().WARNING);
                    return
                }
                this.showToast("Please Fill Required Fields",this.TOST().WARNING);
            }

        },
        getEmpanelmentDetailsbyId(id){
            let api_Is = 'empanelment/empanelment-details-by-id/'+id
            this.GET(2,api_Is,(res,error)=>{
                     try{
                         if(!error){
                            console.log("GET APi response|-> empanelment/empanelment-details-by-id", res.data.data)
                            // this.showToast(res.data.msg,this.TOST().SUCCESS)
                            let tempData= res.data.data
                            this.fulltimeConsult=tempData.hasOwnProperty('noOfFullTimeConsultants')? this.chkplndata(tempData.noOfFullTimeConsultants) : ''
                            this.visitingConsult=tempData.hasOwnProperty('noOfVisitingConsultants')? this.chkplndata(tempData.noOfVisitingConsultants) : ''
                            this.qualifiedDoctor=tempData.hasOwnProperty('noOfQualifiedDoctors')? this.chkplndata(tempData.noOfQualifiedDoctors) : ''
                            this.regNurse=tempData.hasOwnProperty('noOfRegisteredNurses')? this.chkplndata(tempData.noOfRegisteredNurses) : ''
                            this.unregNurse=tempData.hasOwnProperty('noOfUnRegisteredNurses')? this.chkplndata(tempData.noOfUnRegisteredNurses) : ''
                            this.paramedicStaff=tempData.hasOwnProperty('noOfParamedicalStaff')? this.chkplndata(tempData.noOfParamedicalStaff) : ''
                            this.$store.commit("EMPANEL_TAB_STATUS", tempData);
                        }else{
                            console.log("GET APi error response|->empanelment/empanelment-details-by-id", error)
                            // this.showToast(err.response.data.msg ,this.TOST().ERROR);
                        } 
                    }catch{
                         console.log("GET APi  errorresponse|-> empanelment/empanelment-details-by-id", error)
                    }
                        
                })

        },
        chkplndataarry(data){
            console.log("chkplndataarry",data); 
            try{
                if (data ==="" || data === "undefined" || data === null) {
                    return []
                } else {
                    return JSON.stringify(data);
                }

             }catch(err) {
                console.log(err)
            }
            
        },

        chkplndata(data){
            console.log("chkplndata",data); 
            try{
                if (data ==="" || data === "undefined" || data === null) {
                    return ""
                } else {
                    return JSON.stringify(data);
                }

             }catch(err) {
                console.log(err)
            }
            
        },

        // nextBtn(){
        //     this.confirmDialogNext = true
        // },

        nextBtn(){
                console.log("%c this is data for next btn on OwnershipTab","color:green",this.$store.state.empanelmentTabStatus);
                if(this.$store.state.empanelmentTabStatus.staffStatus === false){
                    this.confirmDialogNext = true
                }else{
                        this.selectedCompFromPage('PrvdrEmpnlmntFacilities')
                }
            },


        cancelData(){
            this.confirmDialogCancel = true
        },
        close_cancelDialog(){
            this.confirmDialogCancel = false
            this.confirmDialogNext = false
        },

    },


}
</script>

<style>

.cust-rmvpd-psins3 .v-text-field.v-text-field--enclosed>.v-input__control>.v-input__slot {
    padding-right: 0px !important;
}

.cust_pr2 .v-text-field .v-input__slot,
.v-text-field--outline .v-input__slot {
    min-height: 42px !important;
    display: flex !important;
    align-items: center !important;
    /* border:1px red solid; */
}

.cstm-clr-pr3{
    color:#646666 !important;
    margin-bottom: 5px !important;
    font-size: 13px !important;
}

.cust_pd1 .v-text-field .v-input__slot,
.v-text-field--outline .v-input__slot {
    min-height: 42px !important;
    display: flex !important;
    align-items: center !important;
    /* border:1px red solid; */
}

.cust-rmvpd-pr1 .v-text-field.v-text-field--enclosed>.v-input__control>.v-input__slot {
    padding: 0px !important;
}


</style>