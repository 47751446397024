<template>
  <v-dialog v-model="viewMore" width="1150" persistent>
    <v-card>
      <v-form class="mt-5 ml-4 mr-4" ref="viewMoreForm">
        <v-row>
          <v-col class="d-flex align-right pl-1 justify-end">
            <v-btn
              dark
              class="text-capitalize ml-2"
              color="#E46A25"
              depressed
              @click="handleClose()"
            >
              <v-icon>close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <label class="form-label">Policy Number</label>
            <v-text-field
              placeholder="Enter..."
              class="form-control"
              v-model="formData.policyNumber"
              maxlength="100"
              disabled
              solo
              dense
              depressed
            />
          </v-col>
          <v-col>
            <label class="form-label">Claim Sub-Status</label>
            <v-text-field
              placeholder="Enter..."
              class="form-control"
              v-model="formData.subStatus"
              maxlength="100"
              disabled
              solo
              dense
              depressed
            />
          </v-col>
          <v-col>
            <label class="form-label">Claimant/Member Name</label>
            <v-text-field
              placeholder="Enter..."
              class="form-control"
              v-model="formData.memberName"
              maxlength="100"
              disabled
              solo
              dense
              depressed
            />
          </v-col>
          <v-col>
            <label class="form-label">Loss Pincode</label>
            <v-text-field
              placeholder="Enter..."
              class="form-control"
              v-model="formData.lossPincode"
              maxlength="100"
              disabled
              solo
              dense
              depressed
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <label class="form-label">Claimed Amount</label>
            <v-text-field
              placeholder="Enter..."
              class="form-control"
              v-model="formData.claimedAmount"
              maxlength="100"
              disabled
              solo
              dense
              depressed
            />
          </v-col>
          <v-col>
            <label class="form-label">Ageing</label>
            <v-text-field
              placeholder="Enter..."
              class="form-control"
              v-model="formData.age"
              maxlength="100"
              disabled
              solo
              dense
              depressed
            />
          </v-col>
          <v-col>
            <label class="form-label">Referral Type</label>
            <v-text-field
              placeholder="Enter..."
              class="form-control"
              v-model="formData.referralType"
              maxlength="100"
              disabled
              solo
              dense
              depressed
            />
          </v-col>
          <v-col>
            <label class="form-label">Referred Date</label>
            <v-text-field
              placeholder="Enter..."
              class="form-control"
              v-model="formData.referredDate"
              maxlength="100"
              disabled
              solo
              dense
              depressed
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <label class="form-label">Referred Time</label>
            <v-text-field
              placeholder="Enter..."
              class="form-control"
              v-model="formData.referredTime"
              maxlength="100"
              disabled
              solo
              dense
              depressed
            />
          </v-col>
          <v-col>
            <label class="form-label">Referred From</label>
            <v-text-field
              placeholder="Enter..."
              class="form-control"
              v-model="formData.referredFrom"
              maxlength="100"
              disabled
              solo
              dense
              depressed
            /> </v-col
          ><v-col>
            <label class="form-label">Referred To</label>
            <v-text-field
              placeholder="Enter..."
              class="form-control"
              v-model="formData.referredTo"
              maxlength="100"
              disabled
              solo
              dense
              depressed
            />
          </v-col>
          <v-col>
            <label class="form-label">Investigator Name</label>
            <v-text-field
              placeholder="Enter..."
              class="form-control"
              v-model="formData.investigatorName"
              maxlength="100"
              disabled
              solo
              dense
              depressed
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <label class="form-label">Appointment Date & Time</label>
            <v-text-field
              placeholder="Enter..."
              class="form-control"
              v-model="formData.dateOfAppointment"
              maxlength="100"
              disabled
              solo
              dense
              depressed
            />
          </v-col>
          <v-col>
            <label class="form-label">IR submission Date & Time</label>
            <v-text-field
              placeholder="Enter..."
              class="form-control"
              v-model="formData.invSubmissionDate"
              maxlength="100"
              disabled
              solo
              dense
              depressed
            />
            </v-col>
            <v-col>
            <label class="form-label">Product Name</label>
            <v-text-field
              placeholder="Enter..."
              class="form-control"
              v-model="formData.productName"
              maxlength="100"
              disabled
              solo
              dense
              depressed
            />
          </v-col>
          <v-col cols="3">
            <label class="form-label">Hospital Name</label>
            <v-text-field
              placeholder="Enter..."
              class="form-control"
              v-model="formData.hospitalName"
              maxlength="100"
              disabled
              solo
              dense
              depressed
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3">
            <label class="form-label">QC 1 Submission Date</label>
            <v-text-field
              placeholder="Enter..."
              class="form-control"
              v-model="formData.qc1SubmissionDate"
              maxlength="100"
              disabled
              solo
              dense
              depressed
            />
          </v-col>
          <v-col cols="3">
            <label class="form-label">Insured Name</label>
            <v-text-field
              placeholder="Enter..."
              class="form-control"
              v-model="formData.insuredName"
              maxlength="100"
              disabled
              solo
              dense
              depressed
            />
          </v-col>
        </v-row>
        <v-row>
        </v-row>
        <v-row>
          <v-col class="d-flex align-right align-center pl-1 justify-end">
            <v-btn
              dark
              class="text-capitalize ml-2"
              color="#E46A25"
              depressed
              @click="handleClose()"
            >
              <v-icon>close</v-icon
              ><v-divider class="mx-2 ml-2 white" vertical></v-divider>Close
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script>
import moment from 'moment';
import { getPrePopulatedData } from '../../apiservices/Investigation';
export default {
  name: "DialogComponent",
  data() {
    return {
      viewMore: true,
      formData: {
        claimNumber: "",
        featureNumber: "",
        claimType: "",
        referralType: "",
        referredFrom: "",
        referredTo: "",
        referredTime: "",
        city: "",
        state: "",
        lossPincode: "",
        claimedAmount: "",
        fruadTriggerScore: "",
        investigatorName: "",
        dateOfAppointment: "",
        invSubmissionDate: "",
        status: "",
        lastModifiedDate: "",
        lastModifiedBy: "",
        age: "",
        policyNumber: "",
        productName: "",
        memberName: "",
        coverageType: "",
        subStatus: "",
        referredDate: "",
        hospitalName: "",
        insuredName: "",
        productName: "",
        qc1SubmissionDate: "",
      },
    };
  },
  props: ["investigationDetail"],
  created() {
    this.formData = this.investigationDetail;
    this.formData.invSubmissionDate = this.investigationDetail.invSubmissionDate ? (moment(this.investigationDetail.invSubmissionDate).format("DD-MM-YYYY LT")) : '';
    this.formData.qc1SubmissionDate = this.investigationDetail.qc1SubmissionDate ? (moment(this.investigationDetail.qc1SubmissionDate).format("DD-MM-YYYY LT")) : '';
    this.formData.referredFrom = this.investigationDetail.referedFromNtid;
    this.formData.referredTo = this.investigationDetail.assginDetails.userName;
    this.formData.referredTime = moment(this.investigationDetail.createdAt).format("LT");
    this.formData.referredDate = moment(this.investigationDetail.createdAt).format("DD/MM/YYYY");
    this.formData.lossPincode = this.investigationDetail?.hospitalPinCode;
    this.formData.memberName = this.formData.patientName;
    if(this.investigationDetail.siuInvestigationTaskID) {
      this.getInvDetails();
    }
  },
  methods: {
    async getInvDetails() {
      try{
        let res = await getPrePopulatedData(this.investigationDetail.siuInvestigationTaskID);
        this.formData.investigatorName = res.siuUserDetails.investigatorDetails.name;
        this.formData.dateOfAppointment = res?.createdAt ? moment(res.createdAt).format("DD-MM-YYYY LT") : '';
        this.$forceUpdate();
        
      } catch(e) {
        this.showToast(e.response?.data?.msg || e.response?.data  || e.response || e, this.TOST().ERROR);
      }
    },
    handleClose() {
      this.$emit('viewMoreCustom', false);
    }
  }
  // watch: {
  //   // whenever question changes, this function will run
  //   investigationDetail() {
      
  //     this.formData = this.investigationDetail;
      
  //   }
  // },
};
</script>
