<template>
    <v-app>
         <template>
        <v-card id="create">
          <v-speed-dial v-model="fab" 
            :right="right"
            :direction="direction"
            :transition="transition">
                <!-- style="right: 50px; top:160px;"  -->
            <template v-slot:activator>
              <v-btn
                v-model="fab"
                color="blue darken-2"
                dark
                fab>
                <v-icon v-if="fab">mdi-close</v-icon>
                <v-icon v-else>mdi-account-circle</v-icon>
              </v-btn>
            </template>
              <v-container class="SummaryFirstdivContainer containerMaxWidth">
                <!-- <v-row> -->
                  <v-flex
                    color="white"
                    class="textTransformCapitalize claimSummerButtonsWidth"
                    v-for="item in SummerButtonsFirstrowTexts"
                    :key="item"
                    @click="openNewPagesRow(item)">{{ item }}
                  </v-flex>
                  <!-- <v-flex
                    color="white"
                    class="textTransformCapitalize claimSummerButtonsWidth"
                    v-for="item in SummerButtonsSecondrowTexts"
                    :key="item">{{ item }}
                  </v-flex> -->
                <!-- </v-row> -->
              </v-container>
               
          </v-speed-dial>
        </v-card>
    </template>
    </v-app>
</template>

<script>
    export default {
        data(){
            return{
                direction: 'left',//direction to show list
                fab: false,//to close and open
                tabs: null,
                right: true,// direction to show icon for open and close
                transition: 'slide-y',
                top: false,
                bottom: true,
                left: false,

                SummerButtonsFirstrowTexts: [
                    "Co-Insurance Summary",
                    "Document Checklist",
                    "Transaction History",
                    "Feature Activity",
                    "Claim Summary",   
                    "RI Summary",    
                    "Audit Trail",
                    "Litigation",
                    "Red Flag",
                    "Referral",
                    "Notes",
                    "Query",
                    "CFR"
                ],
            }
        },

        methods:{
            openNewPagesRow(buttonText){
                if (buttonText == "Transaction History") {
                    this.$router.push("/transhistory");
                }
            }
        }

    }
</script>

<style scoped>

    #create .v-speed-dial {
        position: absolute;
    }

    #create .v-btn--floating {
        position: relative;
    }

    .SummaryFirstdivContainer {
        padding-right: 0;
        margin-right: 0;
        margin-left: 12px;
    }

    .claimSummerButtonsWidth {
        opacity: 1;
        text-align: right;
        height: 20px;
        font-size: 12px;
        font-weight: bold;  
        color: #132184  ;
        width: 130px;
        cursor: pointer;
    }
    
</style>