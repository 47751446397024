import axios from "axios";
import * as URL from "../common/API_Config.js";

export const getWoritemList = async function (reqData) {
  const res = await axios.get(
    URL.commonModuleBaseUrl +
      "common-auxiliary/investigtion/get-investigation-workitem-details?"+reqData,
    {
      headers: {
        "Content-Type": "application/json",
        system: "CM",
      },
    }
  );
  return res;
};

export const getWoritemDetailsById = async function (id) {
  const res = await axios.get(
    URL.commonModuleBaseUrl +
      "common-auxiliary/investigtion/get-investigation-workitem-details/" +
      id,
    {
      headers: {
        "Content-Type": "application/json",
        system: "CM",
      },
    }
  );
  return res;
};

export const getAllWoritemList = async function () {
  const res = await axios.get(
    URL.commonModuleBaseUrl +
      "common-auxiliary/investigtion/get-investigation-workitem-details",
    {
      headers: {
        "Content-Type": "application/json",
        system: "CM",
      },
    }
  );
  return res;
};

export const getAuditTrailList = async function (reqData) {
  const res = await axios.get(
    URL.commonModuleBaseUrl +
      "common-audits/siuaudit/searchSiuAudit"+reqData,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return res;
};

export const acceptWorkitemAuditTrail = async(payload) => {
  const res = await axios.post(URL.commonModuleBaseUrl + 'common-audits/siuaudit/createSiuAudit',
    payload,
      {headers:{
          "Content-Type": "application/json",
      }}
  );
  return res;
}
export const acceptRejectWorkitem = async(invWoritemId, payload) => {
  const res = await axios.put(URL.commonModuleBaseUrl + `common-auxiliary/hos-and-acc-details/accept-reject-by-investigator/${invWoritemId}`,
      payload,
      {headers:{
          "Content-Type": "application/json",
          "system": "Health"
      }}
  );
  return res;
}

export const sendBackWaiveOffWidhdraw = async(siuWoritemId, payload) => {
  const res = await axios.put(URL.commonModuleBaseUrl + `common-auxiliary/hos-and-acc-details/sendBack-withdran/${siuWoritemId}`,
      payload,
      {headers:{
          "Content-Type": "application/json",
          "system": "Health"
      }}
  );
  return res;
}

export const downloadPdf = async function (workitemId) {
  const res = await axios.get(URL.commonModuleBaseUrl + "common-auxiliary/investigtion/get-pdf-details-by-workitemid/" + workitemId,
    {
      headers: {
        "Content-Type": "application/json",
        system: "CM",
      },
    }
  );
  return res;
};

export const downloadExcel = async function (url) {
  const res = await axios.get(URL.commonModuleBaseUrl + url,
    { headers: { "Content-Type": "application/json", system: "Health" } }
  );
  return res;
};