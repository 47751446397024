/* eslint-disable */
// import * as AWS from 'aws-sdk/global';
// var AmazonCognitoIdentity = require('amazon-cognito-identity-js');

// import Amplify, { Auth } from "aws-amplify";

// var CognitoUserPool = AmazonCognitoIdentity.CognitoUserPool;

const CLIENT_ID = process.env.VUE_APP_COGNITO_CLIENT_ID;
const APP_DOMAIN = process.env.VUE_APP_COGNITO_APP_DOMAIN;
const REDIRECT_URI = process.env.VUE_APP_COGNITO_REDIRECT_URI;
const USERPOOL_ID = process.env.VUE_APP_COGNITO_USERPOOL_ID;
const REDIRECT_URI_SIGNOUT = process.env.VUE_APP_COGNITO_REDIRECT_URI_SIGNOUT;
const APP_URL = process.env.VUE_APP_APP_URL;
const SERVER_REDIC_URI = 'https://claimsdrive.salesdrive.app/common-system-service/session/auth-code/' + CLIENT_ID

let fedloginURI = 'https://claimsdrive.auth.ap-south-1.amazoncognito.com/oauth2/authorize?identity_provider=tataaig&redirect_uri=' + SERVER_REDIC_URI + '&response_type=CODE&client_id=' + CLIENT_ID + '&scope=openid email'//'https://common-module.auth.ap-south-1.amazoncognito.com/oauth2/authorize?identity_provider=tataaig&redirect_uri='+SERVER_REDIC_URI+'&response_type=CODE&client_id='+CLIENT_ID+'&scope=openid email'
// '&state='+createStateis(25)+
console.log("process file", process.env);
var web_client = {
    CLIENT_ID: CLIENT_ID,
    USERPOOL_ID: USERPOOL_ID,
    APP_DOMAIN: APP_DOMAIN,
}

var poolData = {
    UserPoolId: USERPOOL_ID, // Your user pool id here
    ClientId: CLIENT_ID // Your client id here
};
// console.log("poolData",AmazonCognitoIdentity)
// var userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

// console.log("userPool new",userPool)

// var userData = {
// 	Username: 'DEOA',
//     Pool: userPool,
// };
// var cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
// alert("first here")
// console.log('cognitoUser is----------->',cognitoUser);



// console.log("ONLY AUTH IS", Auth)

// if(window.location.pathname != 'newlogin'){

//     if(!Auth.user){
//         alert("no user on refresh")
//             // if(!Auth.user.signInUserSession){
//                 // alert("no session on refresh")

//                let refis = readcookie('.refreshToken')
//                console.log("ref exist ? ",refis)
//                getref()

//             // }

//     }

// }

function createStateis(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() *
            charactersLength));
    }
    return result;

}

// console.log(Auth,"AUTH CURRENT SESSION", Auth.currentSession().then(e=>console.log("CURR SESS",e)))

function signinnow(indata, cbis) {

    // console.log("mixin is ",mixins)
    // indata['clientId'] = CLIENT_ID
    // console.log("indata is ", indata)
    // try {

    //     mixins.methods.POST(100, 'session/login',indata, (response, erroris)=>{

    //         if(response != null){
    //             console.log("response is ", response)
    //         }else{
    //             console.log(response,'<res|error>',erroris)
    //         }


    //     })

    // } catch (error) {
    //     console.log(' catch error>',error)
    // }

    // return

    // Auth.signIn(indata.username, indata.key)
    // .then((user) => {
    //     console.log("Success on auth user >", user.signInUserSession)

    //     window.localStorage.setItem('newdata', JSON.stringify(user.signInUserSession));

    //     cbis(user)
    // }).catch((err) =>{
    //     cbis(null)
    //     console.log("ERR2", err)
    // });

}

// signinnow()

// signinnow()

function federatedSignIn(cbis) {


    Auth.federatedSignIn({ provider: "iorta.in" })
        .then((user) => {
            alert("now !!! in then")
            console.log('user fed-sign-in', user);
            return user.currentAuthenticatedUser();
        }).then(finalres => {
            console.log(Auth, 'final res', finalres);
            cbis({ finalres: finalres, Auth: Auth })
        })
        .catch((err) => console.log("ERROR in fed sign-in is", err));


}

// cognitoUser.authenticateUser(authenticationDetails, {
// 	onSuccess: function(result) {
//         console.log("result is ", result)
//         // alert("WIN")

//         // let usp = userPool.getCurrentUser()
//         // console.log("usp is ", usp)
//         // alert("session is")
//         // usp.getSession((err, session)=>{
//         //     console.log("the needed session", session)  
//         // })

// 		var accessToken = result.getAccessToken().getJwtToken();
//         console.log("ACT",accessToken)
// 		//POTENTIAL: Region needs to be set if not already set previously elsewhere.
// 		AWS.config.region = 'ap-south-1';

// 		AWS.config.credentials = new AWS.CognitoIdentityCredentials({
// 			IdentityPoolId: 'ap-south-1:db70c3d4-8671-41c3-9604-a0fcd77b0a07', // your identity pool id here
// 			Logins: {
// 				// Change the key below according to the specific region your user pool is in.
// 				'cognito-idp.ap-south-1.amazonaws.com/ap-south-1_qtEiKmTqj': result
// 					.getIdToken()
// 					.getJwtToken(),
// 			},
//         });

//         AWS.config.credentials.get(function (err) {
//             if (!err) {                      
//                console.log("WIN 2 ?")
//             } else {
//                console.log(err ,"ERR CRED get");
//             }
//         });

// 		//refreshes credentials using AWS.CognitoIdentity.getCredentialsForIdentity()
// 		// AWS.config.credentials.refresh(error => {
// 		// 	if (error) {
// 		// 		console.error(error);
// 		// 	} else {
// 		// 		// Instantiate aws sdk service objects now that the credentials have been updated.
// 		// 		// example: var s3 = new AWS.S3();
// 		// 		console.log('Successfully logged!');
// 		// 	}
// 		// });
// 	},

// 	onFailure: function(err) {
// 		alert(err.message || JSON.stringify(err));
// 	},
// });


// import "@aws-amplify/ui-components";
// import {
//   applyPolyfills,
//   defineCustomElements,
// } from "@aws-amplify/ui-components/loader";


// console.log("aws SDK", AWS)

// console.log("Amplify",Amplify)
// console.log("Auth ",Auth);

function readcookie(intype, lookfor) {

    let name = 'CognitoIdentityServiceProvider'
    var cookies = null
    let c = document.cookie.split('; ');
    cookies = {};

    for (let i = c.length - 1; i >= 0; i--) {
        let C = c[i].split('=');
        cookies[C[0]] = C[1];
    }

    let theuser = getuser('LastAuthUser', cookies)
    let clid = process.env.VUE_APP_COGNITO_CLIENT_ID

    var keyPrefix = 'CognitoIdentityServiceProvider.' + clid + '.' + theuser
    var refreshTokenKey = keyPrefix + intype;

    console.log(refreshTokenKey, "a", cookies)
    // let lookfor= 'LastAuthUser'


    console.log("CognitoIdentityServiceProvider ", cookies);
    // adata.auth.getSession((err,res)=>{
    // console.log("getse", res.refreshToken.token)
    return {
        alldatais: cookies,
        ref: cookies[refreshTokenKey],
        userid: theuser
    }

}

function getuser(lookfor, cookiedata) {
    console.log('cookiedata', cookiedata);
    let lookuser = null
    Object.keys(cookiedata).forEach((e, ind) => {

        if (e.includes(lookfor)) {
            let whereitis = Object.keys(cookiedata)[ind]
            lookuser = cookiedata[whereitis]
            console.log("lookyser is", lookuser)
        }

    })
    return lookuser
}


function getref(refT, cbavailable) {
    console.log('Auth on refresh call is', Auth);

    let name = 'CognitoIdentityServiceProvider'
    let cookies = null
    let c = document.cookie.split('; ');
    cookies = {};

    for (let i = c.length - 1; i >= 0; i--) {
        let C = c[i].split('=');
        cookies[C[0]] = C[1];
    }

    let clid = process.env.VUE_APP_COGNITO_CLIENT_ID
    let usm = getuser('LastAuthUser', cookies).userid
    var keyPrefix = 'CognitoIdentityServiceProvider.' + clid + '.' + usm
    var refreshTokenKey = keyPrefix + '.refreshToken';

    // console.log(refreshTokenKey,"a", cookies[refreshTokenKey])

    console.log("CognitoIdentityServiceProvider ", cookies)
    // adata.auth.getSession((err,res)=>{
    // console.log("getse", res.refreshToken.token)
    refT = readcookie('.refreshToken').ref
    //    cookies[refreshTokenKey]
    let regionis = process.env.VUE_APP_COGNITO_USERPOOL_ID
    regionis = regionis.split('_')[0]
    //    console.log(refT,"region is ",regionis)

    fetch("https://cognito-idp." + regionis + ".amazonaws.com/", {
        headers: {
            "X-Amz-Target": "AWSCognitoIdentityProviderService.InitiateAuth",
            "Content-Type": "application/x-amz-json-1.1",
        },
        mode: 'cors',
        cache: 'no-cache',
        method: 'POST',
        body: JSON.stringify({
            ClientId: process.env.VUE_APP_COGNITO_CLIENT_ID,
            AuthFlow: 'REFRESH_TOKEN_AUTH',
            AuthParameters: {
                REFRESH_TOKEN: refT,
                //SECRET_HASH: "your_secret", // In case you have configured client secret
            }
        }),
    }).then((res) => {
        return res.json(); // this will give jwt id and access tokens
    }).then(final => {
        console.log(Auth, "final refresh token response is ", final)

        if (Auth.user == null) {

            Auth.user = Auth.createCognitoUser(readcookie('.refreshToken').userid)
            console.log('Auth is in then ', Auth);
            let cogs = Auth.user.getCognitoUserSession(final.AuthenticationResult)
            console.log("getCognitoUserSession response is >>", cogs.refreshToken)
            if (!cogs.refreshToken.token) {
                console.log('refresh token is', refT);
                cogs.refreshToken = refT
            }
            Auth.user.setSignInUserSession(cogs)
            console.log("setsignin user cogs above")
            // alert("newone now")
            let newone = JSON.stringify(Auth.user.signInUserSession)
            console.log(Auth.user.signInUserSession, "newone is", newone)
            window.localStorage.setItem('newdata', JSON.stringify({ fromrefresh: "set on refresh" }));
            // alert("before cbavailable")
            cbavailable(Auth)
        }

    }).catch(errct => {
        let newone = JSON.stringify(Auth.user.signInUserSession)
        console.log("newone is", newone)
        window.localStorage.setItem('newdata', JSON.stringify(Auth.user.signInUserSession));
        console.log(Auth, "from re-direct refresh catch errct", errct)
        cbavailable(Auth);

    })

    //    })

}

// let fromstore = null
// fromstore = JSON.parse(window.localStorage.getItem("newdata"))

// if(fromstore){

//     fromstore

// }


export default {
    web_client: web_client,
    fedURI: fedloginURI,
    // Auth:fromstore,
    // Auth:Auth,
    // login the user using credentials
    signinnow,
    // to login via SAML federated login
    federatedSignIn,
    // to get a new access and ID tokens from a refresh token
    getref

}