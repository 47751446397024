<template>

    <v-app>
         <div style="background-color: #FFFFFF;" column class="">

             <div class="mt-6 px-4 py-3" style="background-color:#F7F7F7">
                    <p class="font-weight-bold cstm-clr-pln-Ot pa-0 ma-0">Intimations</p>
                </div>
                <v-divider></v-divider>
     <v-card tile>
         <div>
                <v-data-table
                    :headers="headers"
                    :items="statusData"
                    :page.sync="page"
                    :items-per-page="statusItemsPerPage"
                    hide-default-footer
                    dense
                    @page-count="pageCount = $event"
                >
                      <template v-slot:item.select="{ item }">
                            <div>
                                <v-radio-group v-model="valueOfGRadio">
                                    <!-- value -->
                                    <v-radio class="resizebtn" color = "#23b1a9" :key="item" :id="item" :value="item" @click="dialogOk(item)"></v-radio>
                                </v-radio-group>
                                <!-- <input type="radio" id="one" :value="valueOfBtn" ref="valueOfBtnIs" @click="valueOfRadio" /> -->
                            </div>
                      </template>
                </v-data-table>
       </div>
     </v-card>

         </div>

         <v-card color="#F7F7F7" class="mt-5 mx-2 pa-3 overflow-y-auto">
            <v-row class="pt-2 pb-2 mt-1 mr-2 float-right">
              <v-btn @click="tagIntimation" tile dark color="#E56C24" class="float-right text-capitalize px-3 mr-5" small>
                <v-icon color="" small >close</v-icon>
                <v-divider vertical class="ml-2 mr-2"></v-divider>Tag
              </v-btn>
              <v-btn @click="$router.go(-1)" tile dark color="#152F38" class="float-right text-capitalize px-3 mr-5" small>
                <v-icon color="" small >mdi-arrow-left</v-icon>
                <v-divider vertical class="ml-2 mr-2"></v-divider>Return
              </v-btn>  
            </v-row>
        </v-card>

    </v-app>

</template>

<script>
import moment from 'moment';
let dateIs = null;

export default {
    created(){
        this.$store.commit("SAVE_PAGE_HEADER", "Select Intimation");
        this.dataOnCreated();
    },

    data(){
    return{
        intimationSelected: false,
        selectTableData: [],
      valueOfGRadio:'',
      statusData: [],
      headers:[ 
            {text: 'Intimation ID',value:'intimationId',sortable: false,divider:true,width: "120px",class:"hdr-txt-clr-Ot backColorOt"},
            {text: 'Member ID', value: 'memberId',sortable: false,divider:true,width: "110px",class:"hdr-txt-clr-Ot backColorOt"},
            {text: 'Policy Number', value: 'policyNumber',sortable: false,divider:true,width: "120px",class:"hdr-txt-clr-Ot backColorOt"},
            {text: 'First Name',value: 'firstName',sortable: false,divider:true,width: "110px",class:"hdr-txt-clr-Ot backColorOt"},  
            {text: 'Last Name',value: 'lastName',sortable: false,divider:true,width: "110px",class:"hdr-txt-clr-Ot backColorOt"},
            {text: 'Illness',value: 'illness',sortable: false,divider:true,width: "120px",class:"hdr-txt-clr-Ot backColorOt",path: ['Yes', 'No']},   
            {text: 'Intimation Status',value: 'intimationStatus',sortable: false,divider:true,width: "130px",class:"hdr-txt-clr-Ot backColorOt"}, 
            {text: 'Intimation Date',value:'intimationDate',sortable: false,divider:true,width: "120px",class:"hdr-txt-clr-Ot backColorOt"}, 
            {text: 'Date of Admission',value:'dateofAdmission',sortable: false,divider:true,width: "140px",class:"hdr-txt-clr-Ot backColorOt"},
            {text: 'Select',value:'select',sortable: false,divider:true,width: "90px",class:"hdr-txt-clr-Ot backColorOt"}, 
        ],
    }
  },

  methods: {
    dialogOk(item){
        console.log(item,"radion btn click");
        this.intimationSelected = true;
    },

    tagIntimation(){
        // console.log(this.selectTableData.length);kjdsk
        // console.log(this.intimationSelected);
        // if(this.selectTableData.length == true){
            if(this.intimationSelected == true){
            this.showToast("Intimation has been successfully tagged to the claim",this.TOST().SUCCESS);
            }else{
            this.showToast('No Intimation was selected',this.TOST().WARNING);
            }
        
        
    },

    dataOnCreated(){
            this.GET(8,'claims-intimation/search-intimation', (res, err) => {
               if(!err){
                   console.log(res.data.data.data);
                   this.dataItems = res.data.data.data;

                   let tempArr = [];
                   this.dataItems.forEach(element => {
                       if(element.intimationStatus == 'Created'){
                           tempArr.push(element);
                       }
                   }); 
                   console.log(tempArr);
                   this.statusData =  tempArr.map((item,index) => {

                          let toDateData = '';
                           dateIs = moment(item.intimationDate)._i.split("T")[0];
                           console.log(dateIs);
                           toDateData = moment(dateIs).format("DD-MM-YYYY");
                           console.log(toDateData);

                          let toAdmissionDateData = '';
                           if(item.dateOfAdmissionLoss == undefined){
                               toAdmissionDateData = ''
                           }else{
                           dateIs = moment(item.dateOfAdmissionLoss)._i.split("T")[0];
                           console.log(dateIs);
                           toAdmissionDateData = moment(dateIs).format("DD-MM-YYYY");
                           console.log(toAdmissionDateData);
                           }
                           
                           console.log(item.intimationStatus);


                        // if(item.intimationStatus == 'Created'){

                        return {
                        //    id: item._id,
                          intimationId: item.intimationId,
                        //   claimId:
                          memberId: item.memberId,
                          policyNumber: item.policyNo,
                          firstName: item.firstName,
                          lastName: item.lastName,
                          mobileNo: item.mobileNo,
                          emailId: item.emailId,
                          dateofAdmission: toAdmissionDateData,
                          dateOfDischarge: item.dateOfDischarge,
                          // intimationDate: toDateData,
                          modeOfIntimation: item.modeOfIntimation,
                          intimationStatus: item.intimationStatus,
                          illness: item.illness,
                          illnessDescription: item.illnessDescription,
                          diagnosis: item.diagnosis,
                          treatment: item.treatment,
                          actualOrEstimatedAmount: item.actualOrEstimatedAmount,
                          intimationType: item.intimationType,
                          hospitalName: item.hospitalName,
                          secondOpinion: item.secondOpinion,
                          hospitalAddressLine1: item.hospitalAddressLine1,
                          hospitalAddressLine2: item.hospitalAddressLine2,
                          hospitalAddressLine3: item.hospitalAddressLine3,
                          pincode: item.pincode,
                          country: item.country,
                          state: item.state,
                          district: item.district,
                          cityTownVillage: item.cityTownVillage,
                          remarks: item.remarks,
                        //   intimationStatus: item.intimationStats,
                          intimationDate: toDateData,
                          // dateofAdmission: item.dateofAdmission,
                       }
                        
                   })
               }else{
                   console.log(err);
               }
            })

       },
  },

}
</script>

<style scoped>

/* .resizebtn  >>> i{
    font-size: 15px !important;
    margin-left: 10px;
}

.resizebtn  >>> label{
    font-size: 14px;
    margin-left: 10px;

} */

</style>