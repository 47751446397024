<template>
<v-app>
    <div style="background-color: #FFFFFF;" column class="py-3">
        <v-form ref="form">
            <v-layout class=" mt-2" >
                    <v-flex xs3>
                        <v-layout column class="mx-2 my-2">
                            <v-text class="text-uppercase font-weight-bold title-clr-pr">
                                Contact Details
                            </v-text>
                            <v-divider></v-divider>
                        </v-layout>
                    </v-flex>


                    <v-flex xs3>
                        <v-layout column class="cust_pd mx-2 my-2 ">
                            <v-text class="font-weight-bold cstm-clr-pd">
                                STD Code *
                            </v-text>
                            <v-text-field
                                solo
                                label="Enter..."
                                v-model="stdCode"
                                class="rounded-0"
                                maxlength="10"
                                :rules="stdCodeValid"
                                @blur="fieldValidation(stdCode,'stdCode')"
                                @input="fieldValidation(stdCode,'stdCode')">
                            </v-text-field>
                        </v-layout>
                    </v-flex>

                    <v-flex xs3>
                        <v-layout column class=" mx-2 my-2 cust_pd cust-rmvpd-pr">
                            <v-text class="font-weight-bold cstm-clr-pr">
                                Telephone Number 1 *
                            </v-text>
                            <v-text-field
                                solo
                                label="Enter..."
                                v-model="teleFirstContact"
                                class="rounded-0"
                                maxlength="10"
                                :rules="phoneNum1Valid"
                                @blur="fieldValidation(teleFirstContact,'phoneNum1')"
                                @input="fieldValidation(teleFirstContact,'phoneNum1')"
                            >
                                <div slot="prepend-inner" style="background-color:#F7F7F7;padding: 9px !important"><v-icon>smartphone</v-icon></div>
                            </v-text-field>
                            
                        </v-layout>
                    </v-flex>

                    <v-flex xs3>
                        <v-layout column class=" mx-2 my-2 cust_pr cust-rmvpd-pr">
                            <v-text class="font-weight-bold cstm-clr-pr">
                                Telephone Number 2
                            </v-text>
                            <v-text-field
                                solo
                                label="Enter..."
                                v-model="teleSecondContact"
                                class="rounded-0"
                                maxlength="10"
                                :rules="phoneNum2Valid"
                                @blur="fieldValidation(teleSecondContact,'phoneNum2')"
                                @input="fieldValidation(teleSecondContact,'phoneNum2')"
                                >
                                <div slot="prepend-inner" style="background-color:#F7F7F7;padding: 9px !important"><v-icon>smartphone</v-icon></div>
                            </v-text-field>
                        </v-layout>
                    </v-flex>
                
                    <v-flex xs3>
                        <v-layout column class="cust_pr cust-rmvpd-pr mx-2 my-2 ">
                            <v-text class="font-weight-bold cstm-clr-pr">
                                E-mail *
                            </v-text>
                            <v-text-field
                                solo
                                label="Enter..."
                                prepend-inner-icon="mail"
                                v-model="emailContact"
                                maxlength="30"
                                :rules="emailValid"
                                @input="fieldValidation(emailContact,'email')"
                                class="pa-0 rounded-0">
                                <div slot="prepend-inner" style="background-color:#F7F7F7;padding: 9px !important"><v-icon>mail</v-icon></div>
                            </v-text-field>
                        </v-layout>
                    </v-flex>
                                                
                </v-layout>
                    
                <v-layout>
                
                <v-flex xs3>
                        <v-layout column class="cust_pd mx-2 my-2 ">
                            <v-text class="font-weight-bold cstm-clr-pd">
                                Pre Auth FAX Number
                            </v-text>
                            <v-text-field
                                solo
                                label="Enter..."
                                v-model="preauthFax"
                                class="rounded-0"
                                maxlength="20"
                                >
                            </v-text-field>
                        </v-layout>
                    </v-flex>

                    <v-flex xs3>
                        <v-layout column class=" mx-2 my-2 cust_pr cust-rmvpd-pr">
                            <v-text class="font-weight-bold cstm-clr-pr">
                                Mobile Number *
                            </v-text>
                            <v-text-field
                                solo
                                label="Enter..."
                                v-model="mobileNumber"
                                class="rounded-0"
                                maxlength="10"
                                :rules="mobileValid"
                                @input="fieldValidation(mobileNumber,'mobile')"
                                >
                                <div slot="prepend-inner" style="background-color:#F7F7F7;padding: 9px !important"><v-icon>smartphone</v-icon></div>
                            </v-text-field>
                        </v-layout>
                    </v-flex>

                    <v-flex xs3></v-flex>
                    <v-flex xs3></v-flex>
                    <v-flex xs3></v-flex>

            </v-layout>
            <v-divider class=" mt-3"></v-divider>

            <v-layout class=" mt-3" >
                <v-flex xs3>
                        <v-layout column class="mx-2 my-2">
                            <v-text class="text-uppercase font-weight-bold title-clr-pr">
                                Provider Facility Details
                            </v-text>
                            <v-divider></v-divider>
                        </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class=" mx-2 my-2 cust_pd">
                        <v-text class="font-weight-bold cstm-clr-pd">
                            Specialities *
                        </v-text>
                        <v-select
                            :items="specialityItems"
                            label="Select..."
                            v-model="specialities"
                            solo
                            multiple
                            class="rounded-0"
                            :rules="specilityValid"
                            @blur="fieldValidation(specialities,'special')"
                            @input="fieldValidation(specialities,'special')">
                        </v-select>
                    </v-layout>
                </v-flex>
                <v-flex xs3></v-flex>
                <v-flex xs3></v-flex>
                <v-flex xs3></v-flex>
            </v-layout>
        </v-form>

         <v-dialog v-model="confirmDialogCancel" persistent max-width="450px">
                 
                         <v-card tile color="#F7F7F7" >
                            <v-card tile>
                                <v-layout justify-space-between  class="" style="flex:1;background-ox-shadow: 0px 3px 5px #00000029;">
                             
                                    <v-card-title class="pa-0 pl-3 text-capitalize font-weight-regular" style="color:black"> 
                                       CONFIRMATION
                                    </v-card-title>
                                    <div style="border-left:1px solid #C1C8CC;" @click="close_cancelDialog()">
                                        <v-icon style="cursor: pointer;" class="pa-2" >close</v-icon>
                                    </div>
                                </v-layout>
                            </v-card>
                        <v-flex >
                           
                       
                        </v-flex>
                        <v-card-text >
                                Details saved on screen will be lost
                        </v-card-text>
                        <v-card-actions justify-start>
                            <v-btn dark  tile color="black" @click="confirmDialogCancel = false" class="text-capitalize px-3 mx-2" small><v-icon dark small>cancel</v-icon> <v-divider vertical class="mx-2"></v-divider>Cancel</v-btn>
                            <!-- <v-btn depressed small color="secondary" class="rounded-0"  >
                                <v-icon dark right>check_circle</v-icon>
                            </v-btn> -->
                            <v-btn @click="selectedCompFromPage(arrToNavCancel[1])" dark  tile color="#23B1A9" class="text-capitalize px-3 ml-2" small><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider> OK</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <v-dialog v-model="confirmDialogNext" persistent max-width="450px">
                 
                         <v-card tile color="#F7F7F7" >
                            <v-card tile>
                                <v-layout justify-space-between  class="" style="flex:1;background-ox-shadow: 0px 3px 5px #00000029;">
                             
                                    <v-card-title class="pa-0 pl-3 text-capitalize font-weight-regular" style="color:black"> 
                                       CONFIRMATION
                                    </v-card-title>
                                    <div style="border-left:1px solid #C1C8CC;" @click="close_cancelDialog()">
                                        <v-icon style="cursor: pointer;" class="pa-2" >close</v-icon>
                                    </div>
                                </v-layout>
                            </v-card>
                        <v-flex >
                           
                        </v-flex>
                        <v-card-text >
                                 Details entered on the page will be lost if not saved
                        </v-card-text>
                        <v-card-actions justify-start>
                           
                            <v-btn dark  tile color="black" @click="confirmDialogNext = false" class="text-capitalize px-3 mx-2" small><v-icon dark small>cancel</v-icon> <v-divider vertical class="mx-2"></v-divider>Cancel</v-btn>

                            <!-- <v-btn depressed small color="secondary" class="rounded-0"  >
                                <v-icon dark right>check_circle</v-icon>
                            </v-btn> -->
                            <v-btn @click="selectedCompFromPage('ProviderPaymentDetails')" dark  tile color="#23B1A9" class="text-capitalize px-3 ml-2" small><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider> OK</v-btn>

                        </v-card-actions>
                    </v-card>
                </v-dialog>

            <div class="mx-2 pa-5" style="background-color: #F7F7F7; display:flex;justify-content:space-between;"  >
                <v-layout style="flex:1" justify-end > 
                    <v-btn dark @click="cancelData()" tile color="#E46A25" class="text-capitalize px-3 mx-2" small><v-icon dark small>cancel</v-icon> <v-divider vertical class="mx-2"></v-divider>Cancel</v-btn>
                    <v-btn dark @click="clearData()" tile color="#D1121B" class="text-capitalize px-3 mx-2" small><v-icon dark small>delete</v-icon> <v-divider vertical class="mx-2"></v-divider>Clear</v-btn>
                    <v-btn dark  @click="saveData()" tile color="#23B1A9" class="text-capitalize px-3 ml-2" small><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider> Save</v-btn>
                    <v-btn dark  @click="nextBtn()" tile color="black" class="text-capitalize px-3 ml-4" small><v-icon dark small>arrow_forward</v-icon> <v-divider vertical class="mx-2"></v-divider> Next</v-btn>
                </v-layout>
            </div>
        </div>
        
    </v-app>
</template>

<script>
  
export default {
     inject : ['selectedCompFromPage', 'arrToNavCancel'],

    created(){
        console.log('(((((((((( STOREEEE ))))))))))',this.$store.state.empanelProviderData.provider)
        let userId = this.$store.state.empanelProviderData.empanelment._id
        this.getEmpDetails(userId)
    },

    data() {
        return {
            confirmDialogCancel:false,
            confirmDialogNext:false,
            // confirmDialog:false,
            stdCodeValid:[],
            phoneNum1Valid:[],
            phoneNum2Valid:[],
            emailValid:[],
            mobileValid:[],
            specilityValid:[],
            stdCode:'',
            teleFirstContact:'',
            teleSecondContact:'',
            locationComm:[],
            emailContact:'',
            preauthFax:'',
            mobileNumber:'',
            specialities:[], 
            
            specialityItems:["Internal Medicine","General Surgery","Obstetrics & Gynecology","Pediatrics","Orthopedic","Gastroenterology & Hepatology","Nephrology","Urology","Endocrinology","Rheumatology","Pulmonology","Ophthalmology","Otorhinolaryngology","Cardiology","Cardiothoracic Vascular Surgery (CTVS)","Interventional Radiology","Thoracic Surgery","Neurology","Neurosurgery","Neuropsychiatry","Medical Oncology","Surgical Oncology","Radiation Oncology","Gynecological Oncology","GI Surgery","Colorectal Surgery/Proctology","Transplant Surgery","Hand Surgery","Plastic and Reconstructive Surgery","Pediatric Surgery","Neonatology","Psychiatry","Dermatology","Anesthesiology","Dental - Orthodontics","Dental - Endodontics","Dental - Oral and Maxillofacial Surgery","Dental - Periodontics","Dental - Prosthodontics","Ayurveda","Unani","Siddha","Homeopathy","Other"]
        }
    },

    methods:{

        getEmpDetails(userId){
            // console.log("%c i was in getEmpDetails","color:darkblue");
            this.GET(2,'empanelment/empanelment-details-by-id/' + userId,(res,error)=>{
                console.log('(((((((((( userId CONT  RESPPPP ))))))))))',res)
                try{
                    if(!error){
                        let _resp = res.data.data
                        if(_resp.hasOwnProperty('contactDetails') == true){
                            this.stdCode = _resp.hasOwnProperty('contactDetails') == true ?  this.checkValid(_resp.contactDetails.stdCode) : ''
                            this.teleFirstContact =  _resp.hasOwnProperty('contactDetails') == true ?  this.checkValid(_resp.contactDetails.telephoneNumberOne) : ''
                            this.teleSecondContact = _resp.hasOwnProperty('contactDetails') == true ?  this.checkValid(_resp.contactDetails.telephoneNumberTwo) : ''
                            this.emailContact = _resp.hasOwnProperty('contactDetails') == true ?  this.checkValid(_resp.contactDetails.email) : ''
                            this.preauthFax = _resp.hasOwnProperty('contactDetails') == true ?  this.checkValid(_resp.contactDetails.preauthFaxNumber) : ''
                            this.mobileNumber = _resp.hasOwnProperty('contactDetails') == true ?  this.checkValid(_resp.contactDetails.mobile) : ''
                            this.specialities = _resp.hasOwnProperty('providerFacilityDetails') == true ?  this.checkArrayValid(_resp.providerFacilityDetails.specialities) : []

                            this.$store.commit("EMPANEL_TAB_STATUS", _resp);
                        }else{
                            let _provReg = this.$store.state.empanelProviderData.provider

                            this.stdCode = ''
                            this.teleFirstContact =  ''
                            this.teleSecondContact = ''
                            this.emailContact = this.checkValid(_provReg.email)
                            this.preauthFax = ''
                            this.mobileNumber = this.checkValid(_provReg.mobileNumber)
                            this.specialities = []

                            this.$store.commit("EMPANEL_TAB_STATUS", _resp);

                        }
                        
                    }else{
                        // this.showToast(err.response.data.msg ,this.TOST().ERROR);
                    } 
                }catch{}
            })
        },

        checkValid(data){
            try{
                if (data == "" || data === "undefined" || data === null || data === "undefined" || data === undefined) {
                    return ""
                } else {
                    return data;
                }
            }catch(err) {}
        },
        checkArrayValid(data){
            try{
                if (data.length == 0) {
                    return []
                } else {
                    return data;
                }
            }catch(err) {}
        },

        fieldValidation(value,fieldName){
            let nameRegex =/^[A-Za-z&_. -]+$/;
            let noRegex =/^[0-9 ]+$/;
            var phoneno = /^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/;
            let emailFormate = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
            var mobileNoReg = /^((\\+91-?)|0)?[0-9]{10}$/;

            console.log('(((((((((( fieldValidation ))))))))))',value)

            if(fieldName == 'stdCode'){
                this.stdCodeValid = []
                value === '' ? this.stdCodeValid.push('This field is required') : ''
                value.match(noRegex) ? this.stdCodeValid.push() : this.stdCodeValid.push('Only Numbers are Allowed')

            }else if(fieldName == 'phoneNum1'){
                this.phoneNum1Valid = []
                value === '' ? this.phoneNum1Valid.push('This field is required') : ''
                value.match(noRegex) ? this.phoneNum1Valid.push() : this.phoneNum1Valid.push('Only Numbers are Allowed')

            }else if(fieldName == 'phoneNum2'){
                this.phoneNum2Valid = []
                value === '' ? this.phoneNum2Valid.push() : ''
                value.match(noRegex) || value === '' ? this.phoneNum2Valid.push() : this.phoneNum2Valid.push('Only Numbers are Allowed')

            }else if(fieldName == 'email'){
                this.emailValid = []
                value === '' ? this.emailValid.push('This field is required') : ''
                value.match(emailFormate) ? this.emailValid.push() : this.emailValid.push('E-mail must be valid')

            }else if(fieldName == 'mobile'){
                this.mobileValid = []
                value === '' ? this.mobileValid.push('This field is required') : ''
                value.match(mobileNoReg) ? this.mobileValid.push() : this.mobileValid.push('Only Numbers are Allowed')

            }else if(fieldName == 'special'){
                this.specilityValid = []
                value.length == 0 ? this.specilityValid.push('This field is required') : ''
                // value.match(mobileNoReg) ? this.specilityValid.push() : this.specilityValid.push('Only Numbers are Allowed')

            }
            
        },

        saveData(){
            let userID = this.$store.state.empanelProviderData.empanelment._id;
            let _proviType = this.$store.state.empanelProviderData.empanelment.providerType || this.$store.state.empanelProviderData.provider.providerType
            let formData = {
                providerType: _proviType,
                empanelmentProvider: this.$store.state.empanelProviderData.empanelment.empanelmentProvider,
                contactDetails: {
                    stdCode: this.stdCode,
                    telephoneNumberOne: this.teleFirstContact,
                    telephoneNumberTwo: this.teleSecondContact,
                    email: this.emailContact,
                    preauthFaxNumber: this.preauthFax,
                    mobile:this.mobileNumber,
                },
                providerFacilityDetails: {
                    specialities: this.specialities.length === 0 ? [] : this.specialities
                }
            }

            if(this.stdCode === "" || this.teleFirstContact === "" || this.mobileNumber === "" || this.emailContact === "" || this.specialities == ""  ){
                // console.log('(((((((((( AMMMM HEREEEEE ))))))))))')
                this.showToast('Please fill all empty fields', this.TOST().WARNING);
                this.$refs.form.validate(); 
                this.stdCodeValid = [ !!this.stdCode || 'This field is required' ]
                this.phoneNum1Valid = [ !!this.teleFirstContact || 'This field is required' ]
                this.mobileValid = [ !!this.mobileNumber || 'This field is required' ]
                this.emailValid = [ !!this.emailContact || 'This field is required' ]
                // this.specilityValid = [ !!this.specialities || 'This field is required' ]
                return
            }else if(this.stdCodeValid.length > 0 || this.phoneNum1Valid.length > 0  || this.mobileValid.length > 0 || this.emailValid.length > 0  || this.phoneNum2Valid.length > 0){
                // console.log('(((((((((( AMMMM HEREEEEE ))))))))))',this.phoneNum2Valid)
                this.showToast('Please fill Correct data', this.TOST().WARNING);
                return
            }else{

                this.PUT(2,'empanelment/update-contact-facility-details/' + userID,formData ,  ( res, error) => {
                    if(!error){
                        if(res.data.statusCode === 200){
                            this.getEmpDetails(userID)
                            this.showToast(res.data.msg, this.TOST().SUCCESS);
                            console.log("%c this is the id for ContactFacility tab","color:darkgreen",userID);
                        }else{
                            this.showToast(res.data.msg ,this.TOST().ERROR); 
                        }
                    }else{
                        this.showToast(error.response.data.msg, this.TOST().WARNING);
                    }
                })

            }

 
        },

    //   nextBtn(){
    //         this.confirmDialogNext = true
    //     },

    nextBtn(){
                    console.log("%c this is data for next btn on OwnershipTab","color:green",this.$store.state.empanelmentTabStatus);
                    if(this.$store.state.empanelmentTabStatus.contactAndFacilityStatus === false){
                        this.confirmDialogNext = true
                    }else{
                         this.selectedCompFromPage('ProviderPaymentDetails')
                    }
                },



        cancelData(){
            this.confirmDialogCancel = true
        },
        close_cancelDialog(){
            this.confirmDialogCancel = false
            this.confirmDialogNext = false
        },


        clearData(){
            this.stdCode = ''
            this.teleFirstContact = ''
            this.teleSecondContact = ''
            this.emailContact = ''
            this.preauthFax = ''
            this.specialities = []
            this.mobileNumber = ''

        },
    },


}
</script>

<style>

.cust-rmvpd-psins3 .v-text-field.v-text-field--enclosed>.v-input__control>.v-input__slot {
    padding-right: 0px !important;
}

.cust_pr2 .v-text-field .v-input__slot,
.v-text-field--outline .v-input__slot {
    min-height: 42px !important;
    display: flex !important;
    align-items: center !important;
    /* border:1px red solid; */
}

.cstm-clr-pr3{
    color:#646666 !important;
    margin-bottom: 5px !important;
    font-size: 13px !important;
}

.cust_pd1 .v-text-field .v-input__slot,
.v-text-field--outline .v-input__slot {
    min-height: 42px !important;
    display: flex !important;
    align-items: center !important;
    /* border:1px red solid; */
}

.cust-rmvpd-pr1 .v-text-field.v-text-field--enclosed>.v-input__control>.v-input__slot {
    padding: 0px !important;
}


</style>