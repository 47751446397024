<template>
    <v-app>
        <div style="background-color: #FFFFFF;" column class="py-3" v-if="providerType==='Hospital'">
          <v-form v-model="fHspvalid" ref="fHspForm">
           <v-layout class="mx-2" >
               <v-flex xs3>
                    <v-layout column class="mx-2">
                        <p class="text-uppercase font-weight-bold title-clr-pr">
                            Facilities Details
                        </p>
                        <v-divider></v-divider>
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class="cust_pd mx-2 ">
                        <p class="font-weight-bold cstm-clr-pd">
                            Type of Clinical Services *
                        </p>
                        <v-select
                            :items="clinicserviceItems"
                            label="Select"
                            v-model="typClSrvcs"
                            solo
                            dense
                            class="rounded-0"
                            :rules="[required()]"
                            >
                        </v-select>
                    </v-layout>
                </v-flex>

                 <v-flex xs3>
                    <v-layout column class=" mx-2 cust_pd">
                        <p class="font-weight-bold cstm-clr-pd">
                            Number of Beds *
                        </p>
                        <v-text-field
                            class="rounded-0"
                            solo
                            label="Enter..."
                            v-model="nofbds"
                            :maxlength="max05"
                            :rules="[required(),alphaNumeric()]"
                        >
                        </v-text-field>
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class=" mx-2 cust_pd">
                        <p class="font-weight-bold cstm-clr-pd">
                            Single Room - Beds *
                        </p>
                        <v-text-field
                            class="rounded-0"
                            solo
                            label="Enter..."
                            v-model="snglRmBds"
                            :maxlength="max05"
                            :rules="[required(),alphaNumeric()]"
                        >
                        </v-text-field>
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class=" mx-2 cust_pd">
                        <p class="font-weight-bold cstm-clr-pd">
                            Shared Room - Beds *
                        </p>
                        <v-text-field
                            class="rounded-0"
                            solo
                            label="Enter..."
                            v-model="shrdRmBds"
                            :maxlength="max05"
                            :rules="[required(),alphaNumeric()]"
                        >
                        </v-text-field>
                    </v-layout>
                </v-flex>
           </v-layout>
           <v-layout class="mx-2">
               <v-flex xs3>
                    <v-layout column class=" mx-2 mt-4 cust_pd">
                        <p class="font-weight-bold cstm-clr-pd">
                           Ward Beds *
                        </p>
                        <v-text-field
                            class="rounded-0"
                            solo
                            label="Enter..."
                            v-model="wardBeds"
                            :maxlength="max05"
                            :rules="[required(),alphaNumeric()]">
                        </v-text-field>
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class="cust_pd mx-2 mt-4">
                        <p class="font-weight-bold cstm-clr-pd">
                           Intensive Care Unit - Beds *
                        </p>
                        <v-text-field
                            class="rounded-0"
                            solo
                            label="Enter..."
                            v-model="IntensiveCareUnitBds"
                            :maxlength="max05"
                            :rules="[required(),alphaNumeric()]"
                        >
                        </v-text-field>
                    </v-layout>
                </v-flex>

                <v-flex xs3></v-flex>
                <v-flex xs3></v-flex>
                <v-flex xs3></v-flex>
            </v-layout>
            <v-divider class="mt-4"></v-divider>
           
           <v-layout class="mx-2 ">
               <v-flex xs3>
                    <v-layout column class=" mx-2 mt-4 cust_pd">
                        <p class="font-weight-bold cstm-clr-pd">
                           Total Operation Theaters *
                        </p>
                        <v-text-field
                            class="rounded-0"
                            solo
                            label="Enter..."
                            v-model="totalOT"
                            :maxlength="max05"
                            :rules="[required(),alphaNumeric()]"
                        >
                        </v-text-field>
                    </v-layout>
                </v-flex>

                

                <v-flex xs3>
                    <v-layout column class=" mx-2 mt-4 cust_pd">
                        <p class="font-weight-bold cstm-clr-pd">
                           Major OT *
                        </p>
                        <v-text-field
                            class="rounded-0"
                            solo
                            label="Enter..."
                            v-model="majorOT"
                            :maxlength="max05"
                            :rules="[required(),alphaNumeric()]"
                        >
                        </v-text-field>
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class=" mx-2 mt-4 cust_pd">
                        <p class="font-weight-bold cstm-clr-pd">
                           Minor OT *
                        </p>
                        <v-text-field
                            class="rounded-0"
                            solo
                            label="Enter..."
                            v-model="minorOT"
                        :maxlength="max05"
                            :rules="[required(),alphaNumeric()]"
                        >
                        </v-text-field>
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class=" mx-2 mt-4 cust_pd">
                        <p class="font-weight-bold cstm-clr-pd">
                           Cath Lab *
                        </p>
                        <v-text-field
                            class="rounded-0"
                            solo
                            label="Enter..."
                            v-model="cathLab"
                            :maxlength="max05"
                            :rules="[required(),alphaNumeric()]"
                        >
                        </v-text-field>
                    </v-layout>
                </v-flex>

                <v-flex xs3></v-flex>

           </v-layout>

           <v-layout class="mx-2 mb-2">
               <v-flex xs3>
                    <v-layout column class=" mx-2 mt-4 cust_pd">
                        <p class="font-weight-bold cstm-clr-pd">
                           Ambulances - BLS *
                        </p>
                        <v-text-field
                            class="rounded-0"
                            solo
                            label="Enter..."
                            v-model="ambulancesBLS"
                            :maxlength="max05"
                            :rules="[required(),alphaNumeric()]"
                            >
                        </v-text-field>
                    </v-layout>
                </v-flex>
                <v-flex xs3>
                    <v-layout column class=" mx-2 mt-4 cust_pd">
                        <p class="font-weight-bold cstm-clr-pd">
                           Ambulances - ACLS *
                        </p>
                        <v-text-field
                            class="rounded-0"
                            solo
                            label="Enter..."
                            v-model="ambulancesACLS"
                            :maxlength="max05"
                            :rules="[required(),alphaNumeric()]"
                        >
                        </v-text-field>
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class=" mx-2 mt-4 cust_pd">
                        <p class="font-weight-bold cstm-clr-pd">
                           Inhouse Pharmacy *
                        </p>
                        <v-select
                            :items="items"
                            label="Select"
                            v-model="InhousePharmacy"
                            solo
                            dense
                            class="rounded-0"
                            :rules="[required()]"
                            >
                        </v-select>
                    </v-layout>
                </v-flex>
                <v-flex xs3>
                    <v-layout column class=" mx-2 mt-4 cust_pd">
                        <p class="font-weight-bold cstm-clr-pd">
                           Blood Bank *
                        </p>
                        <v-select
                            :items="items"
                            label="Select"
                            v-model="bloodBnk"
                            solo
                            dense
                            class="rounded-0"
                            :rules="[required()]"
                            >
                        </v-select>
                    </v-layout>
                </v-flex>
                 <v-flex xs3>
                    <v-layout column class="cust_pd mx-2 mt-4">
                        <p class="font-weight-bold cstm-clr-pd">
                            Inhouse Laboratory * 
                        </p>
                        <v-select
                            :items="items"
                            label="Select"
                            v-model="inhouseLaboratory"
                            solo
                            dense
                            class="rounded-0"
                            :rules="[required()]"
                            >
                        </v-select>
                    </v-layout>
                </v-flex>
                
           </v-layout>

                <v-dialog v-model="confirmDialogCancel" persistent max-width="450px">
                 
                         <v-card tile color="#F7F7F7" >
                            <v-card tile>
                                <v-layout justify-space-between  class="" style="flex:1;background-ox-shadow: 0px 3px 5px #00000029;">
                             
                                    <v-card-title class="pa-0 pl-3 text-capitalize font-weight-regular" style="color:black"> 
                                       CONFIRMATION
                                    </v-card-title>
                                    <div style="border-left:1px solid #C1C8CC;" @click="close_cancelDialog()">
                                        <v-icon style="cursor: pointer;" class="pa-2" >close</v-icon>
                                    </div>
                                </v-layout>
                            </v-card>
                        <v-flex >
                           
                       
                        </v-flex>
                        <v-card-text >
                                Details saved on screen will be lost
                        </v-card-text>
                        <v-card-actions justify-start>
                          
                           
                           
                            <v-btn dark  tile color="black" @click="confirmDialogCancel = false" class="text-capitalize px-3 mx-2" small><v-icon dark small>cancel</v-icon> <v-divider vertical class="mx-2"></v-divider>Cancel</v-btn>

                            <!-- <v-btn depressed small color="secondary" class="rounded-0"  >
                                <v-icon dark right>check_circle</v-icon>
                            </v-btn> -->
                            <v-btn @click="selectedCompFromPage(arrToNavCancel[1])" dark  tile color="#23B1A9" class="text-capitalize px-3 ml-2" small><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider> OK</v-btn>

                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <v-dialog v-model="confirmDialogNext" persistent max-width="450px">
                 
                         <v-card tile color="#F7F7F7" >
                            <v-card tile>
                                <v-layout justify-space-between  class="" style="flex:1;background-ox-shadow: 0px 3px 5px #00000029;">
                             
                                    <v-card-title class="pa-0 pl-3 text-capitalize font-weight-regular" style="color:black"> 
                                       CONFIRMATION
                                    </v-card-title>
                                    <div style="border-left:1px solid #C1C8CC;" @click="close_cancelDialog()">
                                        <v-icon style="cursor: pointer;" class="pa-2" >close</v-icon>
                                    </div>
                                </v-layout>
                            </v-card>
                        <v-flex >
                           
                       
                        </v-flex>
                        <v-card-text >
                                 Details entered on the page will be lost if not saved
                        </v-card-text>
                        <v-card-actions justify-start>
                          
                           
                           
                            <v-btn dark  tile color="black" @click="confirmDialogNext = false" class="text-capitalize px-3 mx-2" small><v-icon dark small>cancel</v-icon> <v-divider vertical class="mx-2"></v-divider>Cancel</v-btn>

                            <!-- <v-btn depressed small color="secondary" class="rounded-0"  >
                                <v-icon dark right>check_circle</v-icon>
                            </v-btn> -->
                            <v-btn @click="selectedCompFromPage('ProviderPaymentDetails')" dark  tile color="#23B1A9" class="text-capitalize px-3 ml-2" small><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider> OK</v-btn>

                        </v-card-actions>
                    </v-card>
                </v-dialog>



            <div class="mx-3 pa-3 mt-4" style="background-color: #F7F7F7; display:flex; border-radius">
            
                <v-layout style="flex:1" justify-end > 
                    <v-btn dark  tile color="#E46A25" class="text-capitalize px-3 mx-2" small @click="cancelData()"><v-icon dark small>cancel</v-icon> <v-divider vertical class="mx-2"></v-divider>Cancel</v-btn>
                    <v-btn dark  tile color="#D1121B" class="text-capitalize px-3 mx-2" small @click="Facilities_Hsptl_Clear()"><v-icon dark small>delete</v-icon> <v-divider vertical class="mx-2"></v-divider>Clear</v-btn>
                    <v-btn dark  tile color="#23B1A9" class="text-capitalize px-3 ml-2" small @click="Facilities_Hsptl_save()"><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider> Save</v-btn>
                    <v-btn dark  tile color="#152F38" class="text-capitalize px-3 ml-4" small @click="nextBtn()"><v-icon dark small>arrow_forward</v-icon> <v-divider vertical class="mx-2"></v-divider> Next</v-btn>
                </v-layout>
            </div>
            </v-form>
        </div>

        
   
    
    <div style="background-color: #FFFFFF;" column class="py-3"  v-if="providerType==='Dental'">
        <v-form v-model="fDntlvalid" ref="fDntlForm">
        <v-layout class=" mt-2" >
            <v-flex xs3>
                    <v-layout column class="mx-2">
                        <p class="text-uppercase font-weight-bold title-clr-pr">
                            Facilities Details
                        </p>
                        <v-divider></v-divider>
                    </v-layout>
                </v-flex>
            
                <v-flex xs3>
                    <v-layout column class="cust_pd mx-2 my-2 ">
                        <v-text class="font-weight-bold cstm-clr-pd">
                           Type of Clinical Services *
                        </v-text>
                        <v-select
                            :items="clinicserviceItems"
                            label="Select"
                            v-model="dntlclinicalService"
                            solo
                            dense
                            class="rounded-0"
                            :rules="[required()]"
                            disabled
                            >
                        </v-select>
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class=" mx-2 my-2 cust_pd">
                        <v-text class="font-weight-bold cstm-clr-pd">
                            No. of dental chairs
                        </v-text>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="dentalChairs"
                            class="rounded-0"
                             :rules="[required(),alphaNumeric()]">
                        </v-text-field>
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class="cust_pd mx-2 my-2 ">
                        <v-text class="font-weight-bold cstm-clr-pd">
                           Speciality
                        </v-text>
                        <v-select
                            :items="Specialityitems"
                            label="Select"
                            v-model="speciality"
                            solo
                            dense
                            class="rounded-0"
                            multiple
                             :rules="[required()]"
                            >
                            <template v-slot:selection="{ item, index }">
                                   
                                    <span v-if="index === 0" >{{ item }}</span>
                                   
                                    <span
                                    v-if="index === 1"
                                    class="grey--text caption"
                                    >
                                    (+{{ speciality.length - 1 }} others)
                                    </span>
                             </template>
                        </v-select>
                    </v-layout>
                </v-flex>                                 
        </v-layout>

          <v-dialog v-model="confirmDialogCancel" persistent max-width="450px">
                 
                         <v-card tile color="#F7F7F7" >
                            <v-card tile>
                                <v-layout justify-space-between  class="" style="flex:1;background-ox-shadow: 0px 3px 5px #00000029;">
                             
                                    <v-card-title class="pa-0 pl-3 text-capitalize font-weight-regular" style="color:black"> 
                                       CONFIRMATION
                                    </v-card-title>
                                    <div style="border-left:1px solid #C1C8CC;" @click="close_cancelDialog()">
                                        <v-icon style="cursor: pointer;" class="pa-2" >close</v-icon>
                                    </div>
                                </v-layout>
                            </v-card>
                        <v-flex >
                           
                       
                        </v-flex>
                        <v-card-text >
                                Details saved on screen will be lost
                        </v-card-text>
                        <v-card-actions justify-start>
                          
                           
                           
                            <v-btn dark  tile color="black" @click="confirmDialogCancel = false" class="text-capitalize px-3 mx-2" small><v-icon dark small>cancel</v-icon> <v-divider vertical class="mx-2"></v-divider>Cancel</v-btn>

                            <!-- <v-btn depressed small color="secondary" class="rounded-0"  >
                                <v-icon dark right>check_circle</v-icon>
                            </v-btn> -->
                            <v-btn @click="selectedCompFromPage(arrToNavCancel[1])" dark  tile color="#23B1A9" class="text-capitalize px-3 ml-2" small><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider> OK</v-btn>

                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <v-dialog v-model="confirmDialogNext" persistent max-width="450px">
                 
                         <v-card tile color="#F7F7F7" >
                            <v-card tile>
                                <v-layout justify-space-between  class="" style="flex:1;background-ox-shadow: 0px 3px 5px #00000029;">
                             
                                    <v-card-title class="pa-0 pl-3 text-capitalize font-weight-regular" style="color:black"> 
                                       CONFIRMATION
                                    </v-card-title>
                                    <div style="border-left:1px solid #C1C8CC;" @click="close_cancelDialog()">
                                        <v-icon style="cursor: pointer;" class="pa-2" >close</v-icon>
                                    </div>
                                </v-layout>
                            </v-card>
                        <v-flex >
                           
                       
                        </v-flex>
                        <v-card-text >
                                Details saved on screen will be lost
                        </v-card-text>
                        <v-card-actions justify-start>
                          
                           
                           
                            <v-btn dark  tile color="black" @click="confirmDialogNext = false" class="text-capitalize px-3 mx-2" small><v-icon dark small>cancel</v-icon> <v-divider vertical class="mx-2"></v-divider>Cancel</v-btn>

                            <!-- <v-btn depressed small color="secondary" class="rounded-0"  >
                                <v-icon dark right>check_circle</v-icon>
                            </v-btn> -->
                            <v-btn @click="selectedCompFromPage('ProviderPaymentDetails')" dark  tile color="#23B1A9" class="text-capitalize px-3 ml-2" small><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider> OK</v-btn>

                        </v-card-actions>
                    </v-card>
                </v-dialog>


            <div class="mx-2 pa-5" style="background-color: #F7F7F7; display:flex;justify-content:space-between"  >
                <v-layout style="flex:1" justify-end > 
                    <v-btn dark  tile color="#E46A25" class="text-capitalize px-3 mx-2" small @click="cancelData()"><v-icon dark small>cancel</v-icon> <v-divider vertical class="mx-2"></v-divider>Cancel</v-btn>
                    <v-btn dark  tile color="#D1121B" class="text-capitalize px-3 mx-2" small @click="Facilities_Dntl_Clear()" ><v-icon dark small>delete</v-icon> <v-divider vertical class="mx-2"></v-divider>Clear</v-btn>
                    <v-btn dark  tile color="#23B1A9" class="text-capitalize px-3 ml-2" small @click="Facilities_Dntl_save()"><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider> Save</v-btn>
                    <v-btn dark  tile color="#152F38" class="text-capitalize px-3 ml-4" small @click="nextBtn()"><v-icon dark small>arrow_forward</v-icon> <v-divider vertical class="mx-2"></v-divider> Next</v-btn>
                </v-layout>
            </div>
        </v-form>
        </div>
</v-app>
</template>

<script>
export default {
    inject : ['selectedCompFromPage', 'arrToNavCancel'],
    components:{
        
    },

    created() {
        this.dntlclinicalService="Single Speciality"
        let userID = this.$store.state.empanelProviderData.empanelment._id
        console.log(this.$store.state.empanelProviderData,"Provider details")
        // this.nofbds =this.$store.state.empanelProviderData.provider.validationDetails.noOfBeds
        this.nofbds = this.$store.state.empanelProviderData.provider.hasOwnProperty('validationDetails')? this.$store.state.empanelProviderData.provider.validationDetails.hasOwnProperty('noOfBeds')? this.chkplnDataStrfy(this.$store.state.empanelProviderData.provider.validationDetails.noOfBeds) :'' :'' 
        this.providerType = this.$store.state.empanelProviderData.empanelment.providerType
        this.getEmpanelmentDetailsbyId(userID)


    },

    computed: {

    },

    data() {
        return {

            confirmDialogCancel:false,
            confirmDialogNext:false,

            checkbox: false,
            items:['Yes','No'],
            clinicserviceItems:["Super Speciality","Multi-Speciality","Single Speciality","General"],
            Specialityitems:['Dental - Orthodontics', 'Dental - Endodontics', 'Dental - Oral and Maxillofacial Surgery', 'Dental - Periodontics', 'Dental - Prosthodontics'],
            typClSrvcs:'',
            speciality:[],
            dntlclinicalService:"Single Speciality",
            dentalChairs:'',
            nofbds:'',
            snglRmBds:'',
            shrdRmBds:'',
            wardBeds:'',
            IntensiveCareUnitBds:'',
            totalOT:'',
            majorOT:'',
            minorOT:'',
            cathLab:'',
            ambulancesBLS:'',
            ambulancesACLS:'',
            InhousePharmacy:'',
            bloodBnk:'',
            inhouseLaboratory:'',
            providerType:'Dental',
            max05:5,
             max03:3,
             fDntlvalid:false,
             fHspvalid:false,
            required(){
                return v=>v && v.length>0 || "This Field is Required"
            },
            minLength(minLength){
                return v=>v && v.length>=minLength || `Must be atleast ${minLength} Characters`
            },
            maxLength(maxLength){
                return v=>v && v.length<=maxLength || `Must be less than ${maxLength} Characters`
            },
            spclChar(){
                return v=>/^[A-Za-z0-9 ]+$/.test(v) || `Special Characters Not Allowed`
            },
            alphaNumeric(){
                return v=>/^[0-9 ]+$/.test(v) || `Only Numbers are allowed.`
            },
            numberRule(){
                // if (!v.trim()) return true;
                // if (!isNaN(parseFloat(v)) && v >= 0 && v <= 999) return true;
                // return 'Number has to be between 0 and 999';
                return v=>v && v>0 || "Cannot be zero"
            },
            decimalRule(){
                return v=>/^\d+(\.\d{1,2})?$/.test(v) || `Numeric upto two decimals.`
            }


        };



    },

    methods: {
        
        Facilities_Hsptl_Clear(){

            this.$refs.fHspForm.resetValidation()
            this.typClSrvcs=''
            this.nofbds=''         
            this.snglRmBds=''        
            this.shrdRmBds=''       
            this.wardBeds=''     
            this.IntensiveCareUnitBds=''
            this.totalOT=''     
            this.majorOT=''     
            this.minorOT=''     
            this.cathLab=''     
            this.ambulancesBLS='' 
            this.ambulancesACLS=''
            this.InhousePharmacy=''
            this.bloodBnk=''     
            this.inhouseLaboratory=''

        },
        Facilities_Dntl_Clear(){
            this.$refs.fDntlForm.resetValidation()
            this.dentalChairs=""
            this.speciality=[]

        },
        Facilities_Hsptl_save(){

            let sumOfBeds=parseInt(this.snglRmBds)+parseInt(this.shrdRmBds)+parseInt(this.wardBeds)+parseInt(this.IntensiveCareUnitBds)
            console.log(sumOfBeds,"sumOfBeds",this.nofbds,"this.nofbds")
            if(parseInt(this.nofbds)!=sumOfBeds){
                this.showToast("Incorrect bed details",this.TOST().WARNING);
                return
            }
            if (this.$refs.fHspForm.validate()){
                let userID = this.$store.state.empanelProviderData.empanelment._id
                // this.providerType = this.$store.state.empanelProviderData.empanelment.providerType

                let formData={
                   
                    facilityDetails: {
                         providerType: this.providerType,
                        typeOfClinicalService: this.typClSrvcs,
                        // noOfDentalChairs:0,
                        // speciality:"",
                        numberOfBeds: parseInt(this.nofbds),
                        singleRoomBeds: parseInt(this.snglRmBds) ,
                        sharedRoomBeds: parseInt(this.shrdRmBds),
                        wardBeds: parseInt(this.wardBeds),
                        intensiveCareUnitBeds: parseInt(this.IntensiveCareUnitBds),
                        totalOperationTheaters: parseInt(this.totalOT),
                        majorOt: parseInt(this.majorOT),
                        minorOt: parseInt(this.minorOT),
                        cathLab: parseInt(this.cathLab),
                        ambulancesBls: parseInt(this.ambulancesBLS),
                        ambulancesCls: parseInt( this.ambulancesACLS),
                        inhousePharmacy: this.InhousePharmacy,
                        bloodBank: this.bloodBnk,
                        inhouseLaboratory:  this.inhouseLaboratory
                    }
                    
                }

                console.log(formData,"=========sdForm Data")
                let apiUrl='empanelment/update-facilities/'+userID
                this.PUT(2,apiUrl,formData,(res,error)=>{
                    if(!error){
                          console.log("Put APi response|-> empanelment/save-staff-details ", res)
                          this.getEmpanelmentDetailsbyId(userID)
                          this.showToast(res.data.msg,this.TOST().SUCCESS)
                        //   this.$router.push('/homePage');
                    }else{
                        console.log("Put APi error response|-> empanelment/save-staff-details ", error)
                        this.showToast(error.response.data.msg ,this.TOST().ERROR);
                    }
                })

            }else{
                this.$refs.fHspForm.validate()
                this.showToast("Please Fill Required Fields",this.TOST().WARNING);
            }


        },
        Facilities_Dntl_save(){
            if (this.$refs.fDntlForm.validate()){
                let userID = this.$store.state.empanelProviderData.empanelment._id
                // this.providerType = this.$store.state.empanelProviderData.empanelment.providerType
                let formData={
                   
                    facilityDetails: {
                         providerType: this.providerType,
                        typeOfClinicalService: this.dntlclinicalService,
                        noOfDentalChairs: parseInt(this.dentalChairs),
                        speciality:this.speciality,
                        // numberOfBeds: "",
                        // singleRoomBeds:  "",
                        // sharedRoomBeds: "",
                        // wardBeds: "",
                        // intensiveCareUnitBeds: "",
                        // totalOperationTheaters: "",
                        // majorOt: "",
                        // minorOt:"",
                        // cathLab:  "",
                        // ambulancesBls: "",
                        // ambulancesCls:  "",
                        // inhousePharmacy: "",
                        // bloodBank: "",
                        // inhouseLaboratory:  ""
                    }
                    
                }
                
                console.log(formData,"=========fDntlForm Data")
                let apiUrl='empanelment/update-facilities/'+userID
                this.PUT(2,apiUrl,formData,(res,error)=>{
                    if(!error){
                          console.log("Put APi response|-> empanelment/save-staff-details ", res)
                          this.showToast(res.data.msg,this.TOST().SUCCESS)
                          this.getEmpanelmentDetailsbyId(userID)
                        //   this.$router.push('/homePage');
                    }else{
                        console.log("Put APi error response|-> empanelment/save-staff-details ", error)
                        this.showToast(error.response.data.msg ,this.TOST().ERROR);
                    }
                })

            }else{
                this.$refs.fDntlForm.validate()
                this.showToast("Please Fill Required Fields",this.TOST().WARNING);
            }

        },
         getEmpanelmentDetailsbyId(id){
            let api_Is = 'empanelment/empanelment-details-by-id/'+id
            this.GET(2,api_Is,(res,error)=>{
                     try{
                         if(!error){
                            console.log("GET APi response|-> empanelment/empanelment-details-by-id", res.data.data)
                            // this.showToast(res.data.msg,this.TOST().SUCCESS)
                            let tempData= res.data.data
                            // this.$refs.fDntlForm.resetValidation()
                            this.dntlclinicalService=tempData.hasOwnProperty('facilityDetails')? tempData.facilityDetails.hasOwnProperty('typeOfClinicalService')? this.chkplndatadntl(tempData.facilityDetails.typeOfClinicalService) :'Single Speciality' :'Single Speciality'
                            this.dentalChairs=tempData.hasOwnProperty('facilityDetails')? tempData.facilityDetails.hasOwnProperty('noOfDentalChairs')? this.chkplnDataStrfy(tempData.facilityDetails.noOfDentalChairs) :'' :''
                            this.speciality=tempData.hasOwnProperty('facilityDetails')? tempData.facilityDetails.hasOwnProperty('speciality')? this.chkplndataarry(tempData.facilityDetails.speciality) :[] :[]
                            
                            this.typClSrvcs=tempData.hasOwnProperty('facilityDetails')? tempData.facilityDetails.hasOwnProperty('typeOfClinicalService')? this.chkplndata(tempData.facilityDetails.typeOfClinicalService) :'' :''
                            this.nofbds=tempData.hasOwnProperty('facilityDetails')? tempData.facilityDetails.hasOwnProperty('numberOfBeds')? this.chkplnDataStrfy(tempData.facilityDetails.numberOfBeds) :'' :''
                            this.snglRmBds=tempData.hasOwnProperty('facilityDetails')? tempData.facilityDetails.hasOwnProperty('singleRoomBeds')? this.chkplnDataStrfy(tempData.facilityDetails.singleRoomBeds) :'' :''
                            this.shrdRmBds=tempData.hasOwnProperty('facilityDetails')? tempData.facilityDetails.hasOwnProperty('sharedRoomBeds')? this.chkplnDataStrfy(tempData.facilityDetails.sharedRoomBeds) :'' :''
                            this.wardBeds=tempData.hasOwnProperty('facilityDetails')? tempData.facilityDetails.hasOwnProperty('wardBeds')? this.chkplnDataStrfy(tempData.facilityDetails.wardBeds) :'' :''
                            this.IntensiveCareUnitBds=tempData.hasOwnProperty('facilityDetails')? tempData.facilityDetails.hasOwnProperty('intensiveCareUnitBeds')? this.chkplnDataStrfy(tempData.facilityDetails.intensiveCareUnitBeds) :'' :''
                            this.totalOT=tempData.hasOwnProperty('facilityDetails')? tempData.facilityDetails.hasOwnProperty('totalOperationTheaters')? this.chkplnDataStrfy(tempData.facilityDetails.totalOperationTheaters) :'' :''
                            this.majorOT=tempData.hasOwnProperty('facilityDetails')? tempData.facilityDetails.hasOwnProperty('majorOt')? this.chkplnDataStrfy(tempData.facilityDetails.majorOt) :'' :''
                             this.minorOT= tempData.hasOwnProperty('facilityDetails')? tempData.facilityDetails.hasOwnProperty('minorOt')? this.chkplnDataStrfy(tempData.facilityDetails.minorOt) :'' :''
                             this.cathLab=tempData.hasOwnProperty('facilityDetails')? tempData.facilityDetails.hasOwnProperty('cathLab')? this.chkplnDataStrfy(tempData.facilityDetails.cathLab) :'' :''
                             this.ambulancesBLS=tempData.hasOwnProperty('facilityDetails')? tempData.facilityDetails.hasOwnProperty('ambulancesBls')? this.chkplnDataStrfy(tempData.facilityDetails.ambulancesBls) :'' :''
                             this.ambulancesACLS=tempData.hasOwnProperty('facilityDetails')? tempData.facilityDetails.hasOwnProperty('ambulancesCls')? this.chkplnDataStrfy(tempData.facilityDetails.ambulancesCls) :'' :''
                             this.InhousePharmacy=tempData.hasOwnProperty('facilityDetails')? tempData.facilityDetails.hasOwnProperty('inhousePharmacy')? this.chkplndata(tempData.facilityDetails.inhousePharmacy) :'' :''
                             this.bloodBnk=tempData.hasOwnProperty('facilityDetails')? tempData.facilityDetails.hasOwnProperty('bloodBank')? this.chkplndata(tempData.facilityDetails.bloodBank) :'' :''
                             this.inhouseLaboratory=tempData.hasOwnProperty('facilityDetails')? tempData.facilityDetails.hasOwnProperty('inhouseLaboratory')? this.chkplndata(tempData.facilityDetails.inhouseLaboratory) :'' :'' 
                             this.$store.commit("EMPANEL_TAB_STATUS", tempData);
                        }else{
                            console.log("GET APi error response|->empanelment/empanelment-details-by-id", error)
                            // this.showToast(err.response.data.msg ,this.TOST().ERROR);
                        } 
                    }catch(e){
                         console.log("GET APi  errorresponse|-> empanelment/empanelment-details-by-id", e)
                    }
                        
                })

        },
        chkplndataarry(data){
            console.log("chkplndataarry",data); 
            try{
                if (data ==="" || data === "undefined" || data === null) {
                    return []
                } else {
                    return data;
                }

             }catch(err) {
                console.log(err)
            }
            
        },

        chkplndata(data){
            console.log("chkplndata",data); 
            try{
                if (data ==="" || data === "undefined" || data === null) {
                    return ""
                } else {
                    return data;
                }

             }catch(err) {
                console.log(err)
            }
            
        },
        chkplndatadntl(data){
            console.log("chkplndata",data); 
            try{
                if (data ==="" || data === "undefined" || data === null) {
                    return "Single Speciality"
                } else {
                    return data;
                }

             }catch(err) {
                console.log(err)
            }

        },
        chkplnDataStrfy(data){
            console.log("chkplndata",data); 
            try{
                if (data ==="" || data === "undefined" || data === null) {
                    return ""
                } else {
                    return JSON.stringify(data);
                }

             }catch(err) {
                console.log(err)
            }
            
        },

        // nextBtn(){
        //     this.confirmDialogNext = true
        // },

        nextBtn(){
                    console.log("%c this is data for next btn on OwnershipTab","color:green",this.$store.state.empanelmentTabStatus);
                    if(this.$store.state.empanelmentTabStatus.facilitiesStatus === false){
                        this.confirmDialogNext = true
                    }else{
                         this.selectedCompFromPage('ProviderPaymentDetails')
                    }
                },



        cancelData(){
            this.confirmDialogCancel = true
        },
        close_cancelDialog(){
            this.confirmDialogCancel = false
            this.confirmDialogNext = false
        },

    },

    watch: {
    
    },
    
    
}

</script>
<style >
.cust_pd .v-text-field .v-input__slot,
.v-text-field--outline .v-input__slot {
    min-height: 42px !important;
    display: flex !important;
    align-items: center !important;
    /* border:1px red solid; */
}

.cust-rmvpd-pd .v-text-field.v-text-field--enclosed>.v-input__control>.v-input__slot {
    padding: 0px !important;;
}

.cstm-clr-pd{
    color:#646666 !important;
    margin-bottom: 5px !important;
    font-size: 13px !important;
}

.cust_pd .v-input .v-label {
    font-size: 13px !important;
}
.cust_pd .v-input {
    font-size: 13px !important;
}
.cust_pd .v-input input {
    font-size: 13px !important;
}

.title-clr-pr{
    color: #212121 !important;
    margin-bottom: 5px;
}

.lbl-bgclr{
    color:#152F38;
}

</style>