<template>
  <div>
    <v-row class="mt-5 ml-3">

      <v-flex xs3>
        <v-btn
          color="#01BBD9"
          dark
          style="height: 77px; width: 300px; padding-right: 76px"
          class="textTransformCapitalize"
          @click="goToStatusAuditTrail">
          <img
            src="../../assets/Master/AIGMasters.png"
            height="40"
            width="40"/>
          <div
            style="
              border-right: 1px solid #fff;
              height: 40px;
              opacity: 0.5;
              margin-right: 24px;
              margin-left: 23px;"></div>Status Audit Trail
        </v-btn>
      </v-flex>

      <v-flex xs3>
        <v-btn
          color="#01BBD9"
          dark
          style="height: 77px; width: 300px"
          class="textTransformCapitalize"
          @click="goToGeneral">
          <img height="40" width="40" src="../../assets/Master/master.png" />
          <div
            style="
              border-right: 1px solid #fff;
              height: 40px;
              opacity: 0.5;
              margin-right: 24px;
              margin-left: 23px;"></div>General Audit Trail
        </v-btn>
      </v-flex>

      <v-flex xs3 >
        <v-btn
          color="#01BBD9"
          dark
          style="height: 77px; width: 300px"
          class="textTransformCapitalize"
          @click="goToassignaudit">
          
          <v-icon class="blueiconstyle">mdi-account-circle</v-icon>
          <div
            style="
              border-right: 1px solid #fff;
              height: 40px;
              opacity: 0.5;
              margin-right: 24px;
              margin-left: 23px;"></div>
          Assignment Audit Trail
        </v-btn>
      </v-flex>
      
      <v-flex xs3>
           <v-btn
          color="#01BBD9"
          dark
          style="height: 77px; width: 300px"
          class="textTransformCapitalize"
           @click="goToMasterAudit"
          >
          
          <v-icon class="blueiconstyle">mdi-account-circle</v-icon>
          <div
            style="
              border-right: 1px solid #fff;
              height: 40px;
              opacity: 0.5;
              margin-right: 24px;
              margin-left: 23px;"></div>
           Master Audit Trail
        </v-btn>
      </v-flex>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";
export default {
  methods: {
    goToStatusAuditTrail() {
      this.$router.push("/statusaudittrail");
    },

    goToGeneral() {
      this.$router.push("/generalaudittrail");
    },

    goToassignaudit() {
       this.$router.push("/assignreassignaudittrail");
    },
    goToMasterAudit(){
        this.$router.push("/masteraudittrail");
    }
  },
  created() {
   
  },
};
</script>
<style scoped>
  .blueiconstyle {
    font-size: 33px !important;
    color: #fff !important;
    cursor: pointer !important;
  }

</style>