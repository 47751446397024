<template>
    <v-app>
        <v-form>
            <v-layout style="background-color: #F7F7F7;border:0.5px solid #C1C8CC;" class=" px-4 py-2">
                <v-flex xs4 >
                    <v-card tile class=" mx-3 my-2 py-1" style="background-color:#F0FDFF ;border:0.5px solid #C1C8CC;">
                        <v-flex row class=" px-2" style="justify-content:space-between;">   
                            <v-text class="ml-3  font-weight-bold cstm-clr-pd" style="align-self: center;">
                                TOTAL WORKING ITEM
                            </v-text>

                            <v-text class="font-weight-bold cstm-clr-pd " style="font-size: 18px !important;color:#018FA6 !important;align-self: center;">
                                    {{totalwrkitm}}
                            </v-text>
                        </v-flex>
                    </v-card>
                </v-flex>

                <v-flex xs4 >
                    <v-card tile class=" mx-3 my-2 py-1" style="background-color: #FEF9F6;border:2px solid #E46A25;">
                        <v-flex row class=" px-2" style="justify-content:space-between;">   
                            <v-text class="ml-3 font-weight-bold cstm-clr-pd" style="align-self: center;">
                                NEARING TAT
                            </v-text>

                            <v-text class="font-weight-bold cstm-clr-pd" style="font-size: 18px !important;color:#E46A25 !important;align-self: center;">
                                    {{nearTAT}}
                            </v-text>
                        </v-flex>
                    </v-card>
                </v-flex>

                <v-flex xs4 >
                    <v-card tile class=" mx-3 my-2 py-1" style="background-color:#FFFFFF ;border:0.5px solid #C1C8CC;">
                        <v-flex row class=" px-2" style="justify-content:space-between;">   
                            <v-text class="ml-3 font-weight-bold cstm-clr-pd" style="align-self: center;">
                                EXCEEDED TAT
                            </v-text>

                            <v-text class="font-weight-bold cstm-clr-pd " style="font-size: 18px !important;color:#1E9690 !important;align-self: center;">
                                    {{exceededTAT}}
                            </v-text>
                        </v-flex>
                    </v-card>
                </v-flex>
                <!-- <v-flex xs4 >
                    <v-btn dark  tile @click="clearAllbtn()"  color="#E46A25" class="text-capitalize px-3 mx-2 mt-5" small><v-icon dark small>cancel</v-icon> <v-divider vertical class="mx-2"></v-divider>Clear All</v-btn>
                </v-flex> -->
            </v-layout>
            <v-layout>
                <v-flex xs3 class="ml-4">
                    <v-layout column class="cust_pd mx-3 my-2 ">
                        <v-text class="font-weight-bold cstm-clr-pd">
                            Claim Type
                        </v-text>
                        <v-select
                            :items="claimTypeList"
                            label="Select"
                            v-model="claimType"
                            solo
                            dense
                            class="rounded-0"
                            :rules="[]"
                            >
                        </v-select>
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class=" mx-3 my-2 cust_pd">
                        <v-text class="font-weight-bold cstm-clr-pd">
                            Claim Status
                        </v-text>
                        <v-select
                            :items="claimStatusList"
                            label="Select"
                            v-model="claimStatus"
                            solo
                            dense
                            class="rounded-0"
                            :rules="[]"
                            >
                        </v-select>
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class=" mx-3 my-2 cust_pd">
                        <v-text class="font-weight-bold cstm-clr-pd">
                            Claim Sub-Status
                        </v-text>
                        <v-select
                            :items="claimSubStatusList"
                            label="Select"
                            v-model="claimSubStatus"
                            solo
                            dense
                            class="rounded-0"
                            :rules="[]"
                            >
                        </v-select>
                    </v-layout>
                </v-flex>
                <v-flex xs3 class="mr-4">
                    <v-layout column class=" mx-3 my-2  cust_pd">
                        <v-text class="font-weight-bold cstm-clr-pd">
                            Flag
                        </v-text>
                        <v-select
                            :items="flaglist"
                            label="Select"
                            v-model="flag"
                            solo
                            dense
                            class="rounded-0"
                            :rules="[]"
                            >
                        </v-select>
                    </v-layout>
                </v-flex>
            </v-layout>
            
            <v-layout class="mt-2 pa-3" style="flex:1 ;background-color: #F7F7F7;border:0.5px solid #C1C8CC;" justify-end > 
                    <v-btn @click="set_table_data(set_route_for_page,'filter',{CT:claimType,CSS:claimSubStatus,CS:claimStatus,FL:flag})" dark  tile  color="#23B1A9" class="text-capitalize px-3 mx-2"
                    small><v-icon dark small>tune</v-icon>
                    <v-divider vertical class="mx-2"></v-divider>Filter</v-btn>
<!-- @click="filter_status = false" -->
                    <v-btn @click="clearFilter()"  dark  tile  color="#E46A25" class="text-capitalize px-3 mx-2"
                    small><v-icon dark small>cancel</v-icon>
                    <v-divider vertical class="mx-2"></v-divider>Clear</v-btn>
            </v-layout>
            
        </v-form>

<div v-if="showSearchdata===true">
    <v-card class="rounded-0">

     <v-data-table        
        :headers="headers"
        :items="table_data"
        hide-default-header
        hide-default-footer
        class="elevation-1"
        :page.sync="page"
        :items-per-page="itemsPerPage"
        dense
        @page-count="pageCount = $event"
      >  
        <template v-slot:item.claimId="{ item }">
            <div @click="claimClick(item)" class="text-decoration-underline" style="color:#E46A25; cursor:pointer; font-size:12px !important">{{ item.claimId}}</div>
        </template>



        <template #header="{ props: { headers } }">
            <thead  class="v-data-table-header makepointer">
            <tr>
                <!-- class="text-uppercase" -->
                <th
                v-for="header in headers"
                :key="header.value"
                class="hdr-txt-clr-WrkItemQue hdr-bg-clr-WrkItemQue"
                    color="red"
                scope="col"
                    @click="headercontrol(header)"
                >
                {{ header.text  }} 
                <!-- {{ header.stype }} -->
                    <v-icon style="background-color:#ccc; border-radius:55px;" v-if="header.stype != ' '" >
                        {{ header.stype == 'asc' ? 'arrow_upward':'arrow_downward' }}  </v-icon>
                
                </th>
            
                
            </tr>
            </thead>
        </template>
  
        
      </v-data-table>

      <v-divider></v-divider>
                
                <v-layout row justify-end align-center class="ma-2" >
                    <v-text class="mr-1 font-weight-bold" style="color:#3C3D3D; font-size:13px;">
                        <span class="" style="color:#E46A25;font-size:12px;">Showing {{fval}}-{{sval}} </span>out of {{totalLen}} records
                    </v-text>
                    <!-- <v-divider  class="mx-2" vertical ></v-divider> -->
                    <v-pagination
                        class="pdct-stUp-bnkAcc-pgn"
                                v-model="page"
                                color="#152F38"
                                flat
                                @input="nextPageData(page)"
                                :total-visible="9"
                                :length="pagelength"
                    ></v-pagination>
                </v-layout>
        </v-card>
    </div>
        <div>
            <v-card tile v-if="showrsltnotfound===true" class="my-3 " style="background-color:#F7F7F7;border:1px solid #C1C8CC;flex:1;">
                    <v-layout row justify-start class="">
                        <v-text class="mr-1 mx-5 pa-3 font-weight-bold body-1 text-capitalize" style="color:#3C3D3D; font-size:16px !important">
                            NO RESULTS FOUND
                        </v-text>
                    </v-layout>   
            </v-card>
        </div>

    </v-app>  
</template>

<script>

import axios from "axios";
export default {

    created(){
        console.log("Success Token", this.$store.getters.successToken)
         this.token=this.$store.getters.successToken
         this.$store.commit("SAVE_PAGE_HEADER", "Claim User Home");
         this.GetTATcount()

        console.log(this.$router.history.current.fullPath,"Route name");
        this.set_custom_headers();
        this.set_route_for_page = this.$router.history.current.fullPath
        this.set_table_data(this.$router.history.current.fullPath, 'normal')
        
    },
    computed: {},
    watch: {
        $route(to, from){
            // react somehow
            console.log(to,"Route name",from)
            this.set_route_for_page = to.fullPath
            this.set_table_data(to.fullPath, 'normal');
        }
    },
    data(){
        return{
            filter_status:false,
            sortable_params:{}, 
            type_for_pagination:'',
            set_route_for_page:'',
            pagenum:1,
            role_data:[],
            headers:[
                {text: 'Claim ID', value: 'claimId',sortable:false,divider:true,class:"hdr-txt-clr-WrkItemQue hdr-bg-clr-WrkItemQue srNoWidth1"},
                {text: 'Inward ID', value: 'inwardId',sortable:false,divider:true,class:"hdr-txt-clr-WrkItemQue  hdr-bg-clr-WrkItemQue"},
                {text: 'Claim Type', value: 'claimType',sortable:false,divider:true,class:"hdr-txt-clr-WrkItemQue hdr-bg-clr-WrkItemQue"},
                {text: 'Claim Sub Type', value: 'claimSubType',sortable:false,divider:true,class:"hdr-txt-clr-WrkItemQue hdr-bg-clr-WrkItemQue"},
                {text: 'Claim Status', value: 'claimStatus',sortable:false,divider:true,class:"hdr-txt-clr-WrkItemQue hdr-bg-clr-WrkItemQue"},
                {text: 'Claim Sub-Status', value: 'claimSubStatus',sortable:false,divider:true,class:"hdr-txt-clr-WrkItemQue hdr-bg-clr-WrkItemQue"},
                {text: 'Flags', value: 'flags',sortable:false,divider:true,class:"hdr-txt-clr-WrkItemQue hdr-bg-clr-WrkItemQue"},
                {text: 'Ageing', value: 'ageing',sortable:false,divider:true,class:"hdr-txt-clr-WrkItemQue hdr-bg-clr-WrkItemQue"},
               
            ],
            store_header_index:null,
            totalwrkitm:'',
            nearTAT:'',
            exceededTAT:'',
            claimTypeList:['Cashless','Reimbursement'],
            claimType:'',
            claimStatusList:['Open','Closed','Cancelled','Suspended'],
            claimStatus:'',
            claimSubStatusList:['Queried','Inactive Policy','Invalid Policy','NIDB','RI Missing','Reimbursement Registered',
                                    'Medical Approved','Queried','Rejected','No query response','Partially Paid','Paid',
                                    'Re-opened','Cashless Registered','Commercial Approved','Junk'],
            claimSubStatus:'',
            flaglist:['Co-Issurance','VIP','Senior Citizen','Suspected','Social Media Complaint','64VB Compliance','NEFT',
                        'CKYC','Death','Employee'],
            flag:'',
            table_data:[],

             swap_final_count:false,
            fval:0,
            sval:0,
            totalLen:0,
            
            page: 1,
            pagelength:0,
            itemsPerPage: 20,
            showSearchdata:true,
            showrsltnotfound:false,
            typesearch:false,

        }
    },
    methods:{
        claimClick(item){
            console.log("item is",item);
            this.$router.push(
                                {
                                    name: 'ClaimsMainScreen', 
                                    params: {
                                                claimId:item.claimId,
                                                claimType:item.claimType,
                                                allData:item
                                            }
                                })       
        },

        GetTATcount(){
                
                let self=this      
                let demodata={
                 }
                axios({
                    method: "get",
                      url:self.API_Service_ClaimsManagement()+'claim-management/get-tat-count',
                    //  url:self.API_Service_MASTER()+"line-of-business/get-line-of-business-list",
                    headers: {
                        "Content-Type": "application/json",
                        "x-access-token":self.token,
                    },
                    data:demodata,
                })
                .then(function (response) {
                    let apiData = response.data.data

                    console.log("TAT API RESPONSE for ALL Network:::::",apiData);
                  
                    self.GetTATData = []
                    self.GetTATData= apiData
                    console.log(" TAT api responce---->>>>>>", self.GetTATData);
                    self.totalwrkitm= apiData.totalWorkItems
                    self.exceededTAT= apiData.exceedTAT
                    self.nearTAT= apiData.nearTAT

                    // self.showToast(response.data.msg,self.TOST().SUCCESS)
                    console.log("TAT api success msg--->>>>>>", response.data.msg);
                                       
                })
                .catch(function (error) {
                    console.log("TAT list Error>>>>>>", error);
                    self.showToast(error.response.data.msg,self.TOST().ERROR);
                });
            
        },

        // clearAllbtn(){

        //     if((this.totalwrkitm===''||this.totalwrkitm===undefined||this.totalwrkitm===null) 
        //     && (this.nearTAT===''||this.nearTAT===undefined||this.nearTAT===null) 
        //     && (this.exceededTAT===''||this.exceededTAT===undefined||this.exceededTAT===null) 
            
        //     ){
                
        //         return
        //     }else{                
        //         this.totalwrkitm=''
        //         this.nearTAT=''
        //         this.exceededTAT=''
            
                
        //         // this.set_table_data();
        //         this.typesearch = false
        //         this.page=1
        //         console.log('cancel search data',)
        //         // this.$refs.form.resetValidation()
        //           this.showSearchdata=true,
        //               this.showrsltnotfound=false
        //         this.set_table_data(this.$router.history.current.fullPath, 'normal')
        //           this.set_custom_headers()
                
                    
        //     }
        // },

        clearFilter(){
                


            if((this.claimType===''||this.claimType===undefined||this.claimType===null) 
            && (this.claimSubStatus===''||this.claimSubStatus===undefined||this.claimSubStatus===null) 
            && (this.claimStatus===''||this.claimStatus===undefined||this.claimStatus===null)
            && (this.flag===''||this.flag===undefined||this.flag===null) 
            
            ){
                
                return
            }else{
                
                
                this.claimType=''
                this.claimSubStatus=''
                this.claimStatus=''
                this.flag=''
                
                
                this.typesearch = false
                this.page=1
                console.log('cancel search data',)
                // this.$refs.form.resetValidation()
                
                this.showSearchdata=true,
                     this.showrsltnotfound=false

                if(this.sort_status){
                    this.set_table_data(this.set_route_for_page, 'sort', this.sortable_params)
                }else{
                    this.set_table_data(this.set_route_for_page, 'normal')
                }
                // this.set_table_data(this.$router.history.current.fullPath, 'normal')
                
                 this.filter_status = false
                 
                    
            }
             
        },

        nextPageData(indata){

            console.log("isSearchIndiv => ", this.typesearch);

            if(this.pagelength == indata){
                this.swap_final_count = true
            }else{
                this.swap_final_count = false
            }

            console.log('indiv sort value',this.indiviSortBy)
            console.log('indiv page no value',this.page)
            
            if(this.typesearch==false){
                this.pagination_start()
                // this.set_table_data(null,this.page)
                  console.log("from if ",this.typesearch)
            }else{
                console.log("from else ",this.typesearch)
                // this.set_table_data(indata)
                this.pagination_start()
        
            }

        },
        

        pagination_start(){

            if(this.type_for_pagination == 'filter'){
                this.page=1
                this.set_table_data(this.set_route_for_page,'filter',{CT:this.claimType,CSS:this.claimSubStatus,CS:this.claimStatus,FL:this.flag})
                
            }else if(this.type_for_pagination == 'sort'){
                this.set_table_data(this.set_route_for_page,'sort', this.sortable_params)
            }else if(this.type_for_pagination == 'normal'){
                this.set_table_data(this.set_route_for_page,'normal','')
            }

           
        },

        get_roles(){
            try {
                    // /users/get-user-list-by-rolename/{role}
            } catch (error) {
                
            }
        },

        set_custom_headers(){
            //ssetting new key for tracking sorting order for a particular column, along with index mapping to new key
             this.headers = this.headers.map((e, index)=>{
                 return {...e,index: index, stype:' ' }}) 
        },

        headercontrol(indata){
            console.log("getting header click now ?", indata)

                    if(indata.stype == ' ' &&  this.store_header_index != indata.index && indata.index!=7){
                            // ASC - from none
                            this.headers[indata.index].stype = 'asc'
                            if(this.store_header_index != null ){
                                this.headers[this.store_header_index].stype = ' '
                            }
                            this.store_header_index = indata.index

                    }else if(indata.stype == 'dsc'){
                            // ASC - from DSC
                            this.headers[indata.index].stype = ' '
                            this.store_header_index = null

                    }else if(indata.stype == 'asc'){
                            //DSC - from ASC
                            this.headers[indata.index].stype = 'dsc'
                            this.store_header_index = indata.index
                    }

                    console.log(this.headers[indata.index].stype);
                    // return
                  let srtby = this.headers[indata.index].stype == 'asc' ? '1' : '-1'
                  

                  if(this.headers[indata.index].stype == ' '){
                      this.sort_status = false;
                      let setting_type_outter = {type:'normal', params:''}
                      if(this.filter_status){
                          setting_type_outter.type = 'filter'
                          setting_type_outter.params = {CT:this.claimType,CSS:this.claimSubStatus,CS:this.claimStatus,FL:this.flag}
                      }
                        
                      this.set_table_data(this.set_route_for_page, setting_type_outter.type, setting_type_outter.params)
                      return
                  }
                    var sortable_parameters = {
                        sortBy_data:'&sortBy='+indata.value+'&sortdirection='+srtby
                    }

                    console.log("sortable_parameters ---> ", sortable_parameters);
                    this.sortable_params = sortable_parameters
                    this.set_table_data(this.set_route_for_page, 'sort', sortable_parameters)
                    this.headers.map(e=> {
                        if(e.stype == ' '){
                            e.stype = ' '
                        }
                    }) 
        //this.headers[indata.index].stype=earlier == 'asc' ? 'dsc' : 'asc'
      },

        set_table_data(indata, type, custom_params){

            // var claims_role_is = '';

            // if(indata == '/supervisordataentryqueue'){
            //     claims_role_is = 'Data entry Queue'
            // }else if(indata == '/supervisorcodingqueue'){
            //     claims_role_is = 'Coding Queue'
            // }else if(indata == '/supervisormedicalscrutinyqueue'){
            //     claims_role_is = 'Medical Scrutiny Queue'
            // }else if(indata == '/supervisorcommercialscrutinyqueue'){
            //     claims_role_is = 'Commercial Scrutiny Queue'
            // }else if(indata == '/supervisorpaymentqueue'){
            //     claims_role_is = 'Payment Queue'
            // }else if(indata == '/internalinvestigation'){
            //     claims_role_is = 'Internal investigation'
            // }else if(indata == '/ofr'){
            //     claims_role_is = 'OFR'
            // }else if(indata == '/supervisorquickempanelmentqueue'){
            //     claims_role_is = 'Quick empanelment Queue'
            // }else if(indata == '/negotiation'){
            //     claims_role_is = 'Negotiation'
            // }else if(indata == '/cfr'){
            //     claims_role_is = 'CFR'
            // }
            // indata is route names
            // Data Entry Queue
            // Coding Queue
            // Medical Scrutiny Queue
            // Commercial Scrutiny Queue
            // Payment Queue    
            // indata
            // return
        // let default_test_parameter = 'Payment Queue'; // this is a rolename
       
        let build_api = 'claim-management/get-claim-list?'
        console.log("type is ", type);
        if(type == 'filter'){
            
            this.filter_status = true
            build_api = build_api+'&claimType='+custom_params.CT+'&claimSubStatus='+custom_params.CSS+'&claimStatus='+custom_params.CS+'&flag='+custom_params.FL
            if(this.sort_status){
                  build_api = build_api+this.sortable_params.sortBy_data
            }
            console.log(custom_params,'<- from filter ', build_api);
        }

        if(type == 'sort'){
             this.sort_status = true
        
             build_api = build_api+custom_params.sortBy_data;
             console.log(custom_params,'from sort ', build_api);
             if(this.filter_status){
                  let custp = {CT:this.claimType,CSS:this.claimSubStatus,CS:this.claimStatus,FL:this.flag}  
                  build_api = build_api+'&claimType='+custp.CT+'&claimSubStatus='+custp.CSS+'&claimStatus='+custp.CS+'&flag='+custp.FL
             }
        }


        this.type_for_pagination = type

        build_api = build_api+'&perPage=20&pageNo='+this.page

        console.log(this.type_for_pagination,'final build_api -> ', build_api);


        
            try{
                this.GET(7, build_api, (res,error)=>{
                    if(res != null){
                       
                        console.log("reponse of api is ", res.data.data)
                        if(!res.data.hasOwnProperty('data')){
                            this.showToast(res.data.msg ,this.TOST().ERROR);
                            this.table_data = []
                           this.fval = 0
                           this.sval = 0 
                           this.totalLen = 0
                           
                            return
                        }
                     
                        // this.showSearchdata=false
                        // this.showrsltnotfound=true

                        this.table_data = res.data.data.data;
                        this.typesearch = true
                    


                //pagination 
                    this.pagelength=res.data.data.total_pages
                    console.log(this.page,' <-- pageindu | next btn, pg length is ----->',this.pagelength)

                     this.totalLen=res.data.data.total
                      let l = this.table_data.length
                
                
                    console.log( 20*this.page,'skip simulation ');

                      var less_enough = this.totalLen
                        var checkless_init = false
                        if(less_enough < 20){
                            checkless_init = false
                        }else{
                            checkless_init = true
                        }

                        // if less than 15 we have second value same as total value as no pagination will occur
                        if(checkless_init){
                            
                                // checkinit is true means the final count is more than 15 (- 1)
                                var traverse =  20*this.page
                                this.fval = traverse-19
                                if(this.swap_final_count){
                                    this.sval =  this.totalLen
                                }else{
                                    this.sval = traverse
                                }

                        }else if(this.totalLen == 0){
                            
                          this.sval = this.fval = 0
                        
                       }else{
                             this.fval = 1
                             this.sval = this.totalLen
                        }

                    console.log('total ----->',this.totalLen)
                    console.log(this.sval,'<------sval<<   >>fval ----->',this.fval)

						console.log(" table DATA is---->>::",this.table_data);
		
                    }else{
                        
                        console.log(" table no DATA error msg is---->>::");
                      
                        
                        
                     }
                })
            
             }catch(error){

               console.log("table no DATA  error -> ",error)
            // //    this.showToast(error.res.data.msg,this.TOST().ERROR);
             }
        //    this.get_roles(claims_role_is);
        },


        

     


    }
    
}
</script>
<style>
.hdr-txt-clr-WrkItemQue {
    color: white !important;
    font-weight: bold;
    
}
.hdr-bg-clr-WrkItemQue{
  background-color: #23B1A9 !important;
  color: #ffffff !important;
}

</style>