<template>
  <v-app-bar
    color="#10242B"
    dense
    dark
    app
    fixed
    clipped-left
    class="custm-pd0"
  >
    <v-spacer></v-spacer>
    <v-flex style="margin-left: -97px">
      <p
        class="text-uppercase title font-weight-bold font-italic text-xs-center mt-4"
      >
        Claims
      </p>
    </v-flex>
  </v-app-bar>
</template>


<script>
export default {
    data(){
        return{
      drawer: null,
    
        }
    },

    props: {
      source: String
    },

     methods:{
         jump(to) {
                if (this.$router) {
                    this.$router.push(to)
                }
            },

         logout(){
              
        // const Authenticate={
        //     email: '',
        //     password: ''
        // }                
            this.$store.commit('SET_AGENT_DATA', {})
            this.$router.push('/LoginView');
        }
    }
}
</script>
<style>
    .crsr{
        cursor: pointer;
    }

</style>
