<template>
  <div class="ma-3 mt-8">
    <v-form ref="createForm" lazy-validation @submit.prevent>
      <v-expansion-panels v-model="panels" multiple>
        <v-expansion-panel>
          <v-expansion-panel-header color="#1EBBD9">
            <span class="page-title-small white--text"
              >Create Document Checklist Details</span
            >
            <template v-slot:actions>
              <v-icon class="white--text">
                {{
                  panels.indexOf(0) !== -1 ? "mdi-minus-box" : "mdi-plus-box"
                }}
              </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row class="mt-3">
              <v-col>
                <label class="form-label ml-1"
                  >LOB Segment
                  <span class="red--text"><strong> * </strong></span>
                </label>
                <v-autocomplete
                  class="form-control"
                  v-model="formData.lobSegment"
                  placeholder="Select"
                  :disabled="isView"
                  :items="lobSegmentItems"
                  :rules="[(v) => requiredField(v)]"
                  dense
                  solo
                  required
                ></v-autocomplete>
              </v-col>
              <v-col>
                <label class="form-label ml-1"
                  >LOB
                  <span class="red--text"><strong> * </strong></span>
                </label>
                <v-autocomplete
                  class="form-control"
                  v-model="formData.lob"
                  placeholder="Select"
                  :disabled="isView"
                  :items="lobItems"
                  :rules="[(v) => requiredField(v)]"
                  dense
                  solo
                  required
                ></v-autocomplete>
              </v-col>
              <v-col>
                <label class="form-label ml-1"
                  >Claim Type
                  <span class="red--text"><strong> * </strong></span>
                </label>
                <v-autocomplete
                  class="form-control"
                  v-model="formData.lobType"
                  placeholder="Select"
                  :disabled="isView"
                  :items="lobTypeItems"
                  :rules="[(v) => requiredField(v)]"
                  dense
                  solo
                  required
                ></v-autocomplete>
              </v-col>
              <v-col>
                <label class="form-label ml-1"
                  >Document Name
                  <span class="red--text"><strong> * </strong></span>
                </label>
                <v-text-field
                  class="form-control"
                  v-model="formData.documentName"
                  placeholder="Select"
                  :disabled="isView"
                  :rules="[(v) => requiredField(v)]"
                  dense
                  solo
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-3">
              <v-col>
                <label class="form-label ml-1"
                  >Mandatory
                  <span class="red--text"><strong> * </strong></span>
                </label>
                <v-autocomplete
                  class="form-control"
                  v-model="formData.mandatory"
                  placeholder="Select"
                  :disabled="isView"
                  :items="mandatoryList"
                  :rules="[(v) => requiredField(v)]"
                  dense
                  solo
                  required
                ></v-autocomplete>
              </v-col>
              <v-col>
                <label class="form-label"
                  >Created Date
                  <span class="red--text"><strong> * </strong></span></label
                >
                <v-menu
                  ref="calender"
                  :close-on-content-click="true"
                  :nudge-right="40"
                  transition="scale-transition"
                  max-width="290px"
                  min-width="290px"
                  class="rounded-0"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-bind="attrs"
                      label="Select.."
                      :value="computedDate('createdDateToTakeEffect')"
                      solo
                      dense
                      depressed
                      :disabled="true"
                      class="rounded-0"
                      v-on="on"
                    >
                      <div
                        slot="prepend-inner"
                        style="
                          background-color: #f7f7f7;
                          padding: 9px !important;
                        "
                      >
                        <v-icon>event</v-icon>
                      </div>
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="formData.createdDateToTakeEffect"
                    no-title
                    :min="minDate"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col>
                <label class="form-label"
                  >Effective Date
                  <span class="red--text"><strong> * </strong></span></label
                >
                <v-menu
                  ref="calender"
                  :close-on-content-click="true"
                  :nudge-right="40"
                  transition="scale-transition"
                  max-width="290px"
                  min-width="290px"
                  class="rounded-0"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-bind="attrs"
                      label="Select.."
                      :value="computedDate('modifiedDateToTakeEffect')"
                      solo
                      dense
                      :disabled="isView"
                      class="rounded-0"
                      v-on="on"
                    >
                      <div
                        slot="prepend-inner"
                        style="
                          background-color: #f7f7f7;
                          padding: 9px !important;
                        "
                      >
                        <v-icon>event</v-icon>
                      </div>
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="formData.modifiedDateToTakeEffect"
                    no-title
                    :min="minDate"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="3">
                <label class="form-label"
                  >Modified Date
                  <span class="red--text"><strong> * </strong></span></label
                >
                <v-menu
                  ref="calender"
                  :close-on-content-click="true"
                  :nudge-right="40"
                  transition="scale-transition"
                  max-width="290px"
                  min-width="290px"
                  class="rounded-0"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-bind="attrs"
                      label="Select.."
                      :value="computedDate('updatedAt')"
                      solo
                      dense
                      :disabled="true"
                      class="rounded-0"
                      v-on="on"
                    >
                      <div
                        slot="prepend-inner"
                        style="
                          background-color: #f7f7f7;
                          padding: 9px !important;
                        "
                      >
                        <v-icon>event</v-icon>
                      </div>
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="formData.updatedAt"
                    no-title
                    :min="minDate"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col v-if="Object.keys(this.$route.params).length != 0">
                <v-checkbox
                  class="form-control"
                  v-model="formData.isActive"
                  dense
                  label="Active"
                  :disabled="isView"
                >
                </v-checkbox>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-form>
    <v-layout wrap class="mt-5">
      <v-layout class="mt-0">
        <v-btn
          v-if="isView === false"
          type="button"
          color="red"
          outlined
          class=""
          @click="onReset"
        >
          <v-icon> mdi-backspace </v-icon>

          <v-divider class="mx-2 ml-5" vertical></v-divider>
          <span class="subheading">Reset</span>
        </v-btn>
        <v-btn
          type="button"
          color="red"
          outlined
          class="ml-3 mx-2"
          @click="onCancel(true)"
        >
          <v-icon> mdi-backspace </v-icon>

          <v-divider class="mx-2 ml-5" vertical></v-divider>
          <span class="subheading">Cancel</span>
        </v-btn>
        <v-spacer></v-spacer>

        <v-btn
          v-if="isView === false"
          type="submit"
          color="teal white--text"
          class=""
          @click="onSubmit"
        >
          <v-icon> mdi-arrow-right-circle </v-icon>

          <v-divider class="mx-2" vertical color="white--text"></v-divider>
          <span class="subheading">Submit</span>
        </v-btn>
      </v-layout>
    </v-layout>
  </div>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      minDate: this.getCurrentDate(),
      all_attachments: [],
      documentAttachments: [{ name: "Download Master List" }],
      isView: false,
      tab_items: ["Form"],
      lobItems: [],
      lobSegmentItems: ["ALL", "ACCIDENT & HEALTH"],
      lobTypeItems: ["ALL", "CASHLESS", "REIMBURSEMENT", "CASHLESS CLAIM FILE"],
      mandatoryList: ["Yes", "No"],
      panels: [0, 1],
      formData: {
        lobSegment: "",
        lob: "",
        lobType: "",
        documentName: "",
        mandatory: "",
        createdDateToTakeEffect: "",
        modifiedDateToTakeEffect: "",
        updatedAt: "",
        isActive: true,
      },
      neftHeaders: [
        {
          text: "Document",
          align: "start",
          value: "name",
          sortable: false,
        },
      ],
    };
  },
  methods: {
    getCurrentDate() {
      return moment(new Date(Date.now())).format("YYYY-MM-DD");
    },
    computedDate(name) {
      return this.formData[name]
        ? moment(this.formData[name]).format("DD/MM/YYYY")
        : "";
    },
    dragleave(event) {
      this.visualEffect = false;
    },
    onReset() {
      this.showConfirm(
        "Confirmation",
        "Are you sure you want to Reset?",
        "Yes",
        "No",
        (Yes) => {
          this.confirmReset();
          this.cancelledCheque = null;
        },
        (No) => {}
      );
    },
    confirmReset() {
      this.formData = {
        lobSegment: "",
        lob: "",
        lobType: "",
        documentName: "",
        mandatory: "",
        createdDateToTakeEffect: "",
        modifiedDateToTakeEffect: "",
        updatedAt: "",
        isActive: true,
      };
    },
    onCancel(data) {
      if (data) {
        this.showConfirm(
          "Confirmation",
          "Are you sure you want to leave?",
          "Yes",
          "No",
          (Yes) => {
            this.showProgress();
            this.$router.push({ name: "SearchSiuDocumentChecklistMaster" }); //params:{pageNo:this.pageNo}}
            this.hideProgress();
          }
        );
      } else {
        this.$router.push("/master/search-siu-document-checklist-master");
      }
    },
    onSubmit() {
      if (!this.$refs.createForm.validate()) {
        return this.showAlert("Please fill the mandatory fields");
      } else {
        let payload = this.formData;
        console.log("888", payload);

        if (Object.keys(this.$route.params).length != 0) {
          let updateId = "/" + this.$route.params.item._id;
          this.PUT_ByURLM(
            "UPDATE_DOCUMENT_CHECKLIST",
            payload,
            (res) => {
              this.showAlert(res?.data?.msg || "Success", "", "", () => {
                this.onCancel(false);
              });
              if (res.data.statusCode === 200) {
                console.log("999", res.data);
              }
            },
            (error) => {
              console.log("Error", error);
              // this.showAlert(res.data.msg)
            },
            updateId,
            true
          );
        } else {
          this.POSTM(
            "CREATE_DOCUMENT_CHECKLIST",
            payload,
            (res) => {
              this.showAlert(res?.data?.msg || "Success", "", "", () => {
                // this.onReset();
                // this.cancel(1)
              });
              if (res.data.statusCode === 200) {
                console.log("999", res.data);
                this.onCancel(false);
              }
            },
            (error) => {
              console.log("Error", error);
              // this.showAlert(res.data.msg)
            },
            true
          );
        }
      }
    },
    browse() {},
    selectFile() {},
    populateFields(result) {
      this.formData.lobSegment = result.lobSegment;
      this.formData.lob = result.lob;
      this.formData.lobType = result.lobType;
      this.formData.documentName = result.documentName;
      this.formData.mandatory = result.mandatory;
      this.formData.createdDateToTakeEffect = result.createdDateToTakeEffect;
      this.formData.modifiedDateToTakeEffect = result.modifiedDateToTakeEffect;
      this.formData.updatedAt = result.updatedAt;
      this.formData.isActive = result.isActive;
    },
    getDocumentChecklist(item) {
      let requestData = item._id;
      this.GET_ByURLM(
        "GET_DOCUMENT_CHECKLIST_BY_ID",
        requestData,
        (res) => {
          // this.showAlert(err)
          if (res.data && res.data.data) {
            let result = res.data.data[0];
            this.populateFields(result);
          }
        },
        (err) => {
          this.showAlert(err);
          console.log(err);
        },
        true
      );
    },
    getLob() {
      let reqData = "";
      this.showProgress();
      this.GETM(
        "getLOB",
        reqData,
        (res) => {
          this.hideProgress();
          if (res.data.statusCode === 200) {
            this.lobItems = res.data.data[0].lineOfBusiness;
          } else {
            this.lobItems = [];
            this.showAlert(res.data.msg);
          }
        },
        (error) => {
          console.log(error);
          this.hideProgress();
        },
        true
      );
    },
  },
  created() {
    this.getLob();
    if (Object.keys(this.$route.params).length != 0) {
      //isView
      this.isView = this.$route.params.isView;
      let result = this.$route.params.item;
      console.log(result);
      this.getDocumentChecklist(result);
      this.$store.commit("SAVE_PAGE_HEADER", "SIU Document Checklist Master");
    } else {
      this.formData.createdDateToTakeEffect = this.getCurrentDate();
      this.formData.modifiedDateToTakeEffect = this.getCurrentDate();
      this.formData.updatedAt = this.getCurrentDate();
    }
  },
};
</script>
