<template>
    <v-dialog transition="dialog-bottom-transition" max-width="600" v-model="isOpen" fullscreen
        class="z-index-1000" persistent>
        <v-card>

            <v-toolbar dark color="#10242B">
                <v-toolbar-title>Concessional and Penal for PAN Master</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                    <v-btn dark text @click="closeModal">
                        Close
                    </v-btn>
                </v-toolbar-items>
            </v-toolbar>

            <div class="mx-8 main-dialog-container">

                <v-row>
                    <v-col cols="10">
                        <h4 class="mb-5">
                            Concessional and Penal for Pan Master
                        </h4>
                    </v-col>
                    <v-col cols="2">
                        <v-btn v-if="!isFormEditing && formData.approveStatus !== 'Pending for approval'"
                            class="white--text font-size-10 mt-2 pull-right" color="#009688" dense solo @click="modifyForm"
                            :disabled="!hasEditAccess">
                            <v-icon>mdi-pencil-outline</v-icon>
                            <v-divider vertical class="mx-2 ml-2 white"></v-divider>
                            Modify
                        </v-btn>
                    </v-col>
                </v-row>

                <v-form ref="form" lazy-validation @submit.prevent="submitFormData">

                    <v-row>
                        <v-col>
                            <label class="input-label">Type Of Vendor <span class="text-danger">*</span></label>
                            <v-select class="filter-select-box" outlined
                                :rules="[(v) => requiredField(v), notNullValidation, v => vendorEnum.includes(v)]"
                                placeholder="Select" dense solo :items="vendorEnum" v-model="formData.typeOfVendor"
                                @change="() => (populateBaseTds(), populatePenalTds())"
                                :disabled="!isFormEditing || !!formData._id"></v-select>
                        </v-col>
                        <v-col>
                            <label class="input-label">Vendor Name <span class="text-danger">*</span></label>
                            <v-text-field class="filter-select-box" placeholder="Enter" outlined solo dense
                                :rules="[(v) => requiredField(v), notNullValidation]" v-model="formData.vendorName"
                                :disabled="!isFormEditing" />
                        </v-col>
                        <v-col>
                            <label class="input-label">PAN Number <span class="text-danger">*</span></label>
                            <v-text-field class="filter-select-box" placeholder="Enter" outlined solo dense
                                v-mask="'AAAAA####A'"
                                @change="extractPanCode"
                                :rules="[(v) => validatePanNumber(v, 'PAN Number'), (v) => requiredField(v), validatePanNumberByCode]"
                                v-model="formData.panNumber" :disabled="!isFormEditing || !!formData._id" />
                        </v-col>
                        <v-col>
                            <label class="input-label">PAN Code</label>
                            <v-select class="filter-select-box" outlined placeholder="Select" dense solo
                                :items="panCodeEnum" v-model="formData.panCode"
                                disabled>
                            </v-select>
                        </v-col>
                        <v-col>
                            <label class="input-label">Base TDS Rate</label>
                            <v-text-field class="filter-select-box" placeholder="Enter" outlined solo dense
                                @change="removeDecimal($event, 'baseTdsRate')"
                                v-model="formData.baseTdsRate" disabled />
                        </v-col>

                    </v-row>

                    <v-row>
                        <v-col>
                            <label class="input-label">Penal TDS Applicable <span class="text-danger">*</span></label>
                            <v-select class="filter-select-box" outlined rule placeholder="Select" dense solo
                                @change="updatePenalTdsApplicable" :items="penalTDSApplicableEnum"
                                v-model="formData.penalTdsApplicable"
                                :rules="[(v) => requiredField(v), notNullValidation, v => penalTDSApplicableEnum.includes(v)]"
                                :disabled="!isFormEditing"></v-select>
                        </v-col>
                        <v-col>
                            <label class="input-label">Penal TDS Code</label>
                            <v-select class="filter-select-box" outlined rule placeholder="Select" dense solo
                                :rules="!ifPenalTdsApplicable ? [] : [(v) => requiredField(v), notNullValidation, v => penalTDSCodeEnum.includes(v)]"
                                :items="penalTDSCodeEnum" v-model="formData.penalTdsCode"
                                @change="populatePenalTds"
                                :disabled="!isFormEditing || !ifPenalTdsApplicable" />
                        </v-col>
                        <v-col class="d-flex">
                            <div class="flex">
                                <label class="input-label">Penal TDS Rate </label>
                                <v-text-field class="filter-select-box" placeholder="Enter" outlined solo dense
                                    :rules="!ifPenalTdsApplicable ? [] : [(v) => requiredField(v), notNullValidation, (v) => validateMinMaxValue(v, 0, 100)]"
                                    v-model="formData.penalTdsRate" disabled />
                            </div>

                            <div class="ml-2" v-if="formData.penalTdsCode === 'INF'">
                                <label class="input-label">Applicable Penal TDS Rate </label>
                                <v-text-field class="filter-select-box" placeholder="Enter" outlined solo dense
                                    v-model="applicablePenalTdsRate" disabled />
                            </div>
                        </v-col>
                        <v-col>
                            <label class="input-label">Concessional TDS Applicable <span
                                    class="text-danger">*</span></label>
                            <v-select class="filter-select-box" outlined rule @change="updateConcessionalTdsApplicable"
                                :rules="ifPenalTdsApplicable ? [] : [(v) => requiredField(v), v => concessionalTDSApplicableEnum.includes(v)]"
                                placeholder="Select" dense solo :items="concessionalTDSApplicableEnum"
                                v-model="formData.concessionalTdsApplicable"
                                :disabled="!isFormEditing || ifPenalTdsApplicable"></v-select>
                        </v-col>
                        <v-col>
                            <label class="input-label">Concessional TDS Rate</label>
                            <v-text-field class="filter-select-box" placeholder="Enter" outlined solo dense
                                v-mask="dynamicMask(formData.concessionalTdsRate)"
                                @change="removeDecimal($event, 'concessionalTdsRate')"
                                :rules="!ifConcessionalTdsApplicable ? [] : [(v) => requiredField(v), notNullValidation, (v) => validateMinMaxValue(v, 0, 100)]"
                                v-model="formData.concessionalTdsRate"
                                :disabled="!isFormEditing || !ifConcessionalTdsApplicable" />
                        </v-col>

                    </v-row>

                    <v-row>
                        <v-col>
                            <label class="input-label">Certificate Reference Number <span
                                    class="text-danger">{{ !ifConcessionalTdsApplicable ? '' : '*' }}</span></label>
                            <v-text-field class="filter-select-box" placeholder="Enter ..." outlined solo dense
                                :rules="!ifConcessionalTdsApplicable ? [] : [v => requiredField(v), (v) => matchRegex(v, 'alphanum')]"
                                v-model="formData.certificateReferenceNumber"
                                :disabled="!isFormEditing || !ifConcessionalTdsApplicable" />
                        </v-col>
                        <v-col>
                            <label class="input-label">Certificate Issue Date <span class="text-danger">{{ !ifConcessionalTdsApplicable ? '' : '*' }}</span></label>
                            <v-menu v-model="isCertificateIssueDateDatePickerOpen" :disabled="!isFormEditing" offset-y
                                :close-on-content-click="false" min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field :value="formattedCertificateIssueDate" placeholder="DD/MM/YYYY" dense solo
                                        :rules="!ifConcessionalTdsApplicable ? [] : [(v) => requiredField(v)]" readonly
                                        v-on="on" @focus="menu = true" @blur="menu = true" v-bind="attrs" outlined
                                        class="filter-select-box" append-icon="mdi-calendar"
                                        :disabled="!isFormEditing || !ifConcessionalTdsApplicable">
                                    </v-text-field>
                                </template>
                                <v-date-picker no-title :max="new Date().toISOString()"
                                    v-model="formData.certificateIssueDate"
                                    @input="isCertificateIssueDateDatePickerOpen = false">
                                </v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col>
                            <label class="input-label">Concessional TDS Effective Start Date <span
                                    class="text-danger">{{ !ifConcessionalTdsApplicable ? '' : '*' }}</span></label>
                            <v-menu v-model="isConcessionalTDSEffectiveStartDatePickerOpen" offset-y
                                :close-on-content-click="false" min-width="auto" :disabled="true">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field :value="formattedConcessionalTdsEffectiveStartDate"
                                        placeholder="DD/MM/YYYY" :rules="[]" dense solo readonly v-on="on"
                                        @focus="menu = true" @blur="menu = true" v-bind="attrs" outlined
                                        class="filter-select-box" append-icon="mdi-calendar" :disabled="true">
                                    </v-text-field>
                                </template>
                                <v-date-picker no-title v-model="formData.concessionalTdsEffectiveStartDate"
                                    @input="isConcessionalTDSEffectiveStartDatePickerOpen = false">
                                </v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col>
                            <label class="input-label">Concessional TDS Effective End Date <span
                                    class="text-danger">{{ !ifConcessionalTdsApplicable ? '' : '*' }}</span></label>
                            <v-menu v-model="isConcessionalTDSEffectiveEndDatePickerOpen" offset-y
                                :close-on-content-click="false" min-width="auto" :disabled="!isFormEditing">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field :value="formattedConcessionalTdsEffectiveEndDate" placeholder="DD/MM/YYYY"
                                        :rules="!ifConcessionalTdsApplicable ? [] : [(v) => requiredField(v)]" dense solo
                                        readonly v-on="on" @focus="menu = true" @blur="menu = true" v-bind="attrs" outlined
                                        class="filter-select-box" append-icon="mdi-calendar"
                                        :disabled="!isFormEditing || !ifConcessionalTdsApplicable">
                                    </v-text-field>
                                </template>
                                <v-date-picker no-title
                                    :min="minimumConcessionalDate"
                                    v-model="formData.concessionalTdsEffectiveEndDate"
                                    @input="isConcessionalTDSEffectiveEndDatePickerOpen = false">
                                </v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col>
                            <label class="input-label">Certificate Received Date <span class="text-danger">{{ !ifConcessionalTdsApplicable ? '' : '*' }}</span></label>
                            <v-menu v-model="isCertificateReceivedDateDatePickerOpen" offset-y
                                :close-on-content-click="false" min-width="auto" :disabled="!isFormEditing">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field :value="formattedCertificateReceivedDate" placeholder="DD/MM/YYYY" dense
                                        solo :rules="!ifConcessionalTdsApplicable ? [] : [(v) => requiredField(v)]" readonly
                                        v-on="on" @focus="menu = true" @blur="menu = true" v-bind="attrs" outlined
                                        class="filter-select-box" append-icon="mdi-calendar"
                                        :disabled="!isFormEditing || !ifConcessionalTdsApplicable">
                                    </v-text-field>
                                </template>
                                <v-date-picker no-title :max="new Date().toISOString()"
                                    :min="formData?.certificateIssueDate" v-model="formData.certificateReceivedDate"
                                    @input="isCertificateReceivedDateDatePickerOpen = false">
                                </v-date-picker>
                            </v-menu>
                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col>
                            <label class="input-label">Threshold Limit <span class="text-danger"> {{ !ifConcessionalTdsApplicable ? '' : '*' }}</span></label>
                            <v-text-field class="filter-select-box" placeholder="Enter ..." outlined solo dense
                                v-mask="'##########'"
                                :rules="!ifConcessionalTdsApplicable ? [] : [notNullValidation, v => requiredField(v,), (v) => matchRegex(v, 'nums')]"
                                v-model="formData.thresholdLimit" :disabled="!isFormEditing || !ifConcessionalTdsApplicable" />
                        </v-col>
                        <v-col>
                            <label class="input-label"> Financial Year</label>
                            <v-text-field class="filter-select-box" outlined rule placeholder="Select" dense solo
                                :rules="!ifConcessionalTdsApplicable ? [] : [(v) => requiredField(v)]"
                                v-model="formData.financialYear" disabled />
                        </v-col>

                        <v-col></v-col>
                        <v-col></v-col>
                        <v-col></v-col>
                    </v-row>


                    <v-row>
                        <v-col>
                            <h5>Document Upload <span class="text-danger">*</span></h5>
                        </v-col>
                    </v-row>

                    <v-row class="mt-2">

                        <v-col cols="auto" :disabled="!isFormEditing || !ifConcessionalTdsApplicable">

                            <div class="file-drop-zone" @dragover="dragover" @dragleave="dragleave" @drop="drop"
                                :class="isDragging || !isFormEditing || !ifConcessionalTdsApplicable ? 'dragbackground' : (oneFileRequired ? 'required-border' : '')"
                                :disabled="!isFormEditing || !ifConcessionalTdsApplicable">
                                <v-icon color="#23b1a9" class="iconfont"> mdi-tray-arrow-up </v-icon>
                                <span class="ml-2 drop-zone-heading-text"
                                    :disabled="!isFormEditing || !ifConcessionalTdsApplicable">
                                    Drop Files To Upload or <a @click="browse"> <u> Browse </u> </a>
                                </span>
                                <p class="drop-zone-sub-text mb-0"> Max 5MB of PDF, Word, Mail or Image(.jpeg, .jpg,
                                    .png) </p>
                                <p class="drop-zone-note"> Multiple documents can be uploaded </p>

                            </div>
                            <span></span>
                            <v-file-input multiple v-model="allFiles" @change="processFile"
                                accept=".doc, .docx, image/png,image/jpeg,application/msword,application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                id="file-upload" style="display: none"
                                :disabled="!isFormEditing || !ifConcessionalTdsApplicable">
                            </v-file-input>
                            <div class="error--text v-messages ml-3" v-if="oneFileRequired && isFormEditing">This field is required.</div>
                        </v-col>

                        <v-col>
                            <v-data-table :headers="documentsTableHeaders" :items="formData.documents" hide-default-footer
                                disable-pagination no-data-text="No Records for Attachments"
                                class="tabelwidth vDataTableStyling">
                                <template v-slot:[`item.action`]='{ item, index }'>
                                    <v-icon
                                        class="mr-4"
                                        color="#23B1A9"
                                        @click="viewDocument(item)"
                                        v-if="item.documentId || !!item.fileBase"
                                    >mdi-eye</v-icon>
                                    <v-icon
                                        class=""
                                        color="#D1121B"
                                        @click="confirmDelete(index)"
                                        v-if="!!item.fileBase && isFormEditing"
                                    >mdi-delete</v-icon>
                                </template>
                            </v-data-table>
                        </v-col>

                    </v-row>


                    <v-row class="mt-5">
                        <v-col>
                            <label class="input-label">Remark <span class="text-danger"></span></label>
                            <v-textarea outlined full-width class="filter-select-box" solo :height="50" dense
                                :rules="[v => validateLength(v, { max: 250 }, null, 'Only 250 characters allowed.')]"
                                placeholder="Enter Remarks" v-model="formData.remarks"
                                :disabled="!isFormEditing"></v-textarea>
                        </v-col>
                    </v-row>


                    <v-row class="mt-5">
                        <v-col cols="10">
                            <v-btn class="btn mr-2" dense solo outlined color="red" @click="closeModal">
                                <v-icon> mdi-backspace </v-icon>
                                <v-divider vertical class="mx-2 red"></v-divider>
                                Cancel
                            </v-btn>
                            <v-btn v-if="isFormEditing" class="btn" dense solo outlined color="red" @click="resetFormData">
                                <v-icon> mdi-backspace </v-icon>
                                <v-divider vertical class="mx-2 red"></v-divider>
                                Reset
                            </v-btn>
                        </v-col>

                        <v-col cols="2">
                            <v-btn :loading="this.$store.getters.fetchShowProgress" v-if="isFormEditing"
                                class="white--text font-size-10 ml-0 pull-right" color="#009688" dense solo type="submit">
                                <v-icon>mdi-arrow-right-circle</v-icon>
                                <v-divider vertical class="mx-2 ml-2 white"></v-divider>
                                Submit
                            </v-btn>
                        </v-col>
                    </v-row>

                </v-form>

            </div>
        </v-card>
    </v-dialog>
</template>


<script>
import moment from 'moment';

export default {
    props: {
        // isOpen: Boolean,
        // closeEditDialog: Function,
        // isFormEditing: Boolean,
        // editingFormData: Object,
        // editForm: Function,
        refreshConcessionalRates: Function,
        baseRates: Array,
        penalRates: Array,
    },
    data() {
        return {
            isOpen: false,
            menu: false,
            isFormEditing: false,
            formData: this.getFormData(),
            oldData: this.getFormData(),
            panCodeEnum: ['A', 'B', 'C', 'F', 'G', 'H', 'J', 'L', 'P', 'T'],
            vendorEnum: ['Hospital', 'Service Provider', "Repairer", 'Vendor'],
            penalTDSApplicableEnum: ['Yes', 'No'],
            penalTDSCodeEnum: ["INF", 'ANL'],
            concessionalTDSApplicableEnum: ['Yes', 'No'],
            // financialYearEnum: Array.from({ length: 81 }, (_, i) => i + 1970),
            isCertificateIssueDateDatePickerOpen: false,
            isConcessionalTDSEffectiveStartDatePickerOpen: false,
            isConcessionalTDSEffectiveEndDatePickerOpen: false,
            isCertificateReceivedDateDatePickerOpen: false,
            documentsTableHeaders: [
                {
                    text: "Document Name",
                    align: "start",
                    value: "file",
                    sortable: false,
                    width: "200px",
                },
                {
                    text: "Upload By",
                    align: "start",
                    value: "uploadedBy",
                    width: "130px",
                    sortable: false
                },
                {
                    text: "Action",
                    align: "start",
                    value: "action",
                    sortable: false,
                    width: "150px"
                },
            ],
            // documents: [],
            allFiles: [],
            isDragging: false,
        }
    },
    computed: {
        formattedCertificateIssueDate() {
            if (this.formData?.certificateIssueDate) {
                return moment(this.formData?.certificateIssueDate).format('DD-MM-YYYY');
            }
        },
        formattedConcessionalTdsEffectiveStartDate() {
            if (this.formData?.concessionalTdsEffectiveStartDate || this.formData?.concessionalTdsEffectiveDate) {
                return moment(this.formData?.concessionalTdsEffectiveStartDate || this.formData?.concessionalTdsEffectiveDate).format('DD-MM-YYYY');
            }
        },
        formattedConcessionalTdsEffectiveEndDate() {
            if (this.formData?.concessionalTdsEffectiveEndDate) {
                return moment(this.formData?.concessionalTdsEffectiveEndDate).format('DD-MM-YYYY');
            }
        },
        formattedCertificateReceivedDate() {
            if (this.formData?.certificateReceivedDate) {
                return moment(this.formData?.certificateReceivedDate).format('DD-MM-YYYY');
            }
        },
        minimumConcessionalDate() {
            const date = this.formData?.concessionalTdsEffectiveStartDate || this.formData?.certificateIssueDate || new Date();

            return new Date(date).toISOString();
        },
        ifConcessionalTdsApplicable() {
            return this.formData.concessionalTdsApplicable === 'Yes';
        },
        ifPenalTdsApplicable() {
            return this.formData.penalTdsApplicable === 'Yes';
        },
        oneFileRequired() {
            if (!this.ifConcessionalTdsApplicable || this.formData?.documents?.length) {
                return false;
            }
            return true;
        },
        hasEditAccess() {
            const routeName = this?.$router?.history?.current?.name;

            return this?.$store?.state?.screens_obj?.[routeName]?.isEdit;
        },
        calculateCurrentFinancialYear() {
            const date = new Date();

            const month = date.getMonth() + 1; // Note: Months are 0-indexed in JavaScript
            const year = date.getFullYear();

            if (month > 3) {
                return year + 1;
            } else {
                return year;
            }
        },
        applicablePenalTdsRate () {
            if (
                !this.formData.panCode ||
                this.formData.penalTdsApplicable !== 'Yes' ||
                !this.formData.typeOfVendor ||
                this.formData.penalTdsCode !== 'INF'
            ) {
                return '';
            }

            /**
             * Finding Applicable Base Rate & Penal Rate,
             * based on Type Of Vendor & Selected PAN Code.
             */
            const applicableBaseRate = this.baseRates.find(r => r.typeOfVendor === this.formData.typeOfVendor && r.panCode === this.formData.panCode);

            const applicablePenalRate = this.penalRates.find(r => r.typeOfVendor === this.formData.typeOfVendor && r.penalCode === this.formData.penalTdsCode);

            /**
             * If rates are found, calculate which rate is greater by
             * Multiplying Base rate on multiplication factor on INF.
             */
            if (applicableBaseRate?.tdsRateWithPan && applicablePenalRate?.multiplicationFactor) {

                /**
                 * Calculating Base Rate by multiplying factor on INF.
                 */
                const calculatedBaseRate =
                    applicableBaseRate.tdsRateWithPan * applicablePenalRate.multiplicationFactor;

                return Math.max(this.formData.penalTdsRate, calculatedBaseRate);
            }

            return '';
        }
    },
    methods: {
        validateMinMaxValue (value, min, max) {
            if (min <= Number(value) && Number(value) <= max) {
                return true;
            }
            return "Please enter values between 0 to 100";
        },
        removeDecimal (c = '', property) {
            const charArr = c.split('');

            if (charArr.pop() === '.') {
                this.formData[property] = charArr.join('');
            }
        },
        closeModal(refreshRates = false) {
            this.isOpen = false;
            this.formData = this.getFormData();

            /**
             * If coming from success then refresh the rates,
             * in concessional rates screen.
             */
            if (refreshRates) {
                this.refreshConcessionalRates();
            }
        },
        openModal () {
            this.isOpen = true;
        },
        getDocumentsData (docs = []) {
            return docs.map(d => ({
                fileBase: d.fileBase || '',
                file: d.file || '',
                fileUrl: d.fileUrl || '',
                documentId: d.documentId || '',
                uploadedBy: d.uploadedBy || '',
            }));
        },
        getFormData(data = {}) {
            return {
                // Database id if it is not blank then it is edit form
                _id: data._id || '',
                // Do not touch and send in API as it is.
                approveStatus: data.approveStatus || '',
                uid: data.uid || '',
                typeOfVendor: data.typeOfVendor || '',
                vendorName: data.vendorName || '',
                panNumber: data.panNumber || '',
                panCode: data.panCode || '',
                baseTdsRate: data.baseTdsRate || '',
                penalTdsApplicable: data.penalTdsApplicable || '',
                penalTdsCode: data.penalTdsCode || '',
                penalTdsRate: data.penalTdsRate || '',
                concessionalTdsApplicable: data.concessionalTdsApplicable || '',
                concessionalTdsRate: data.concessionalTdsRate || '',
                certificateReferenceNumber: data.certificateReferenceNumber || '',
                certificateIssueDate: data.certificateIssueDate || '',
                concessionalTdsEffectiveStartDate: data.concessionalTdsEffectiveStartDate || data.concessionalTdsEffectiveDate || '',
                concessionalTdsEffectiveEndDate: data.concessionalTdsEffectiveEndDate || '',
                certificateReceivedDate: data.certificateReceivedDate || '',
                thresholdLimit: data.thresholdLimit || '',
                financialYear: data.financialYear || '',
                documents: this.getDocumentsData(data.documents),
                remarks: data.remarks || '',
            }
        },
        resetFormValidation () {
            try {
                this.$refs?.form?.resetValidation();
            } catch (error) {
                console.log(error);
            }
        },
        openForm(data = {}) {
            this.resetFormValidation();
            this.isFormEditing = false;
            this.formData = this.getFormData(data);
            this.oldData = this.getFormData(data);
            this.openModal();
        },
        editForm(data = {}) {
            this.resetFormValidation();
            this.isFormEditing = true;
            this.formData = this.getFormData(data);
            this.oldData = this.getFormData(data);
            this.openModal();
        },
        modifyForm () {
            this.isFormEditing = true;
        },
        dynamicMask(val = '') {
            return '##.##';
        },
        notNullValidation(value) {
            return ![null, undefined].includes(value);
        },
        resetFormData() {
            const _id = this.formData._id;

            this.formData = this.getFormData({ _id });
        },
        dragover(event) {
            event.preventDefault();
            this.isDragging = true;
        },
        dragleave(_event) {
            this.isDragging = false;
        },
        drop(event) {
            // Stop Event bubbling
            event.preventDefault();

            for (const file of event.dataTransfer.files) {
                this.allFiles.push(file);
            }

            /**
            * Process file which got dropped.
            */
            this.processFile();

            this.isDragging = false;
        },
        async processFile() {

            /**
             * Checking if files exists.
             */
            if (this.allFiles.length > 0) {

                /**
                 * Reading all the files,
                 * one by one
                 */
                for (const file of this.allFiles) {
                    await this.processSingleFile(file);
                }

                /**
                * Remove files array for next upload.
                */

                this.allFiles = [];

            } else {
                this.showAlert("Please upload a file.")
            }
        },
        async processSingleFile(file) {
            /**
                 * File Size validation check,
                 * File should not be more than 5MB.
                 */
            if (Math.round(file.size / 1024) > 5120) {
                return this.showAlert("File is too big, please select a file less than 5MB");
            }

            /**
             * Converting file into base64
             * so that we can send this on request via json.
             */
            this.readImageM(
                file.name,
                file,
                (res) => {
                    const { base, fileName } = res;

                    this.formData.documents.push({
                        file: fileName,
                        uploadedBy: this.getUserName(),
                        fileBase: base,
                        action: true,
                    });
                },
            );
        },
        browse() {
            document.getElementById("file-upload").click();
        },
        confirmDelete(index) {
            this.formData.documents.splice(index, 1);
        },
        downloadFileBase64FromURL(documentId) {
            /**
             * Takes documentId, using a GET api download file as base64
             * return that base64 string.
             */
            return new Promise((resolve, reject) => {
                this.GETM(
                    "VIEW_DOCUMENT",
                    `documentId=${documentId}&b64=true`,
                    res => {
                        if (res.data.statusCode === 200) {
                            resolve(res.data.data);
                        } else {
                            reject('Something went wrong');
                        }
                    },
                    err => {
                        console.log(err);
                        reject(err);
                    },
                    true
                );
            });
        },
        async viewDocument(item) {
            /**
             * Initializing the variable fileBase with base64 if the file is locally present
             * if not download file then download base64 string via get method
             * then view the file by opening new tab.
             */
            let { fileBase } = item;

            try {
                if (item.documentId) {
                    fileBase = await this.downloadFileBase64FromURL(item.documentId);
                }

                // Viewing the doc by Opening new tab here
                this.viewDocUsingBase(fileBase);
            } catch (error) {
                this.showAlert("Something went wrong, Please try again");
            }
        },
        /**
         * @Warning legacy code do not attempt to change
         * TODO: Add comments for better readability
         * Takes base64 string converts into blob & opens it into new tab.
         * @param {string} base64 Takes base64 string
         */
        viewDocUsingBase(base64) {
            const base64ImageData = base64;
            const contentType = base64.split(":")[1].split(";")[0];
            const byteCharacters = window.atob(
                base64ImageData.substr(`data:${contentType};base64,`.length)
            );
            const byteArrays = [];
            for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
                const slice = byteCharacters.slice(offset, offset + 1024);
                const byteNumbers = new Array(slice.length);
                for (let i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                byteArrays.push(byteArray);
            }
            const blob = new Blob(byteArrays, { type: contentType });
            const blobUrl = window.URL.createObjectURL(blob);
            window.open(blobUrl, "_blank");
        },
        submitFormData() {
            /**
             * Function declarations which are callbacks
             */
            const successCallBack = res => {
                this.hideProgress();
                this.closeModal(true);
                this.showToast(
                    res?.data?.msg || "Updated Successfully",
                    this.TOST().SUCCESS
                );
            };

            const errorCallBack = err => {
                // Should be a logger here.
                console.log(err);
                this.hideProgress();
                this.showAlert(err?.response?.data?.message || "Something went wrong, Please try again");
            }

            /**
             * Starting In-Progress/Loading Animation
             */
            this.showProgress();


            /**
             * Validating form here
             */
            if (!this.$refs.form.validate() || this.oneFileRequired) {
                this.hideProgress();
                return this.showAlert("Please fill the mandatory fields")
            }

            /**
             * Validating if form has been modified or not.
             */
            if (!this.findChangesInFields(this.formData, this.oldData)) {
                this.hideProgress();
                return this.showAlert("Please change something to submit.")
            }

            /**
             * If Documents are present
             * then map otherwise skip
             */
            if (this.formData?.documents?.length) {
                this.formData.documents = this.formData?.documents?.map(d => ({
                    fileBase: d.fileBase,
                    file: d.file,
                    fileUrl: d.fileUrl,
                    documentId: d.documentId,
                }));
            }

            const data = { ...this.formData };

            delete data.isAuditTrailOpen;
            delete data._id;
            delete data.createdAt;
            delete data.updatedAt;
            delete data.__v;
            delete data.concessionalTdsEffectiveDate;
            delete data.approveStatus;

            data.baseTdsRate = removeLastDecimal(String(data.baseTdsRate || ''));
            data.penalTdsRate = removeLastDecimal(String(data.penalTdsRate || ''));
            data.concessionalTdsRate = removeLastDecimal(String(data.concessionalTdsRate || ''));

            function removeLastDecimal(val) {
                const charArr = val.split('');

                if (charArr.pop() === '.') {
                    return charArr.join('');
                }
                return val;
            }

            if (data.uid === '') {
                delete data.uid;
            }


            /**
             * Based on _id present or not determining create or update.
             */
            if (this.formData._id) {
                this.PUTM(
                    'UPDATE_CONCESSIONAL_AND_PENAL_MASTER_URL',
                    this.formData._id,
                    data,
                    successCallBack,
                    errorCallBack,
                    'CM',
                );
            } else {
                /**
                 * This flag to identify in backend whether it is checker req or maker.
                 */

                data.approveRequest = true;

                this.POSTM(
                    'CREATE_CONCESSIONAL_AND_PENAL_MASTER_URL',
                    data,
                    successCallBack,
                    errorCallBack,
                    'CM',
                );
            }

        },
        updatePenalTdsApplicable(value) {
            if (value === 'Yes') {
                this.formData = {
                    ...this.formData,
                    concessionalTdsApplicable: 'No',
                };
                this.clearPenalTdsValues();
            } else {
                this.formData = {
                    ...this.formData,
                    concessionalTdsApplicable: 'Yes',
                    financialYear: this.calculateCurrentFinancialYear
                };
                this.clearConcessionalTdsValues();
            }
            this.populateBaseTds();
        },
        updateConcessionalTdsApplicable(value) {
            if (value === 'Yes') {
                this.formData = {
                    ...this.formData,
                    financialYear: this.calculateCurrentFinancialYear,
                    baseTdsRate: '',
                };
            } else {
                this.clearPenalTdsValues();
            }
            this.populateBaseTds();
        },
        clearConcessionalTdsValues() {
            this.formData.penalTdsCode = '';
            this.formData.penalTdsRate = '';
        },
        clearPenalTdsValues() {
            this.formData.concessionalTdsRate = '';
            this.formData.certificateReferenceNumber = '';
            this.formData.certificateIssueDate = '';
            this.formData.concessionalTdsEffectiveStartDate = '';
            this.formData.concessionalTdsEffectiveEndDate = '';
            this.formData.certificateReceivedDate = '';
            this.formData.thresholdLimit = '';
            this.formData.financialYear = '';
            this.formData.documents = [];
        },
        findChangesInFields(newData, oldData) {
            for (const key in newData) {
                if (key !== 'documents') {
                    if (newData?.[key] != oldData?.[key]) {
                        return true;
                    }
                } else {
                    if (this.findChangesInDocuments(newData?.[key], oldData?.[key])) {
                        return true;
                    }
                }
            }
        },
        findChangesInDocuments (newData, oldData) {
            if (newData.length !== oldData.length) {
                return true;
            }

            newData.forEach((d, i) => {
                return this.findChangesInFields(d, oldData[i]);
            })
        },
        extractPanCode (val = '') {
            if (val?.length > 3) {
                this.formData.panCode = val[3];
                this.populateBaseTds();
            } else {
                this.formData.panCode = '';
            }
        },
        populateBaseTds() {
            if (
                this.formData.typeOfVendor &&
                this.formData.panCode &&
                this.formData.penalTdsApplicable === 'No' &&
                this.formData.concessionalTdsApplicable === 'No'
            ) {

                const findRateMethod = r => r.typeOfVendor === this.formData.typeOfVendor && r.panCode === this.formData.panCode;

                const applicableRate = this.baseRates.find(findRateMethod);

                if (applicableRate?.tdsRateWithPan) {
                    this.formData.baseTdsRate = applicableRate.tdsRateWithPan;
                }
            } else {
                this.clearBaseTdsRate();
            }
        },
        clearBaseTdsRate () {
            this.formData.baseTdsRate = '';
        },
        populatePenalTds() {
            if (this.formData.typeOfVendor && this.formData.penalTdsApplicable === 'Yes') {

                const findRateMethod = r => r.typeOfVendor === this.formData.typeOfVendor && r.penalCode === this.formData.penalTdsCode;

                const applicableRate = this.penalRates.find(findRateMethod);

                if (applicableRate?.penalTDSRate) {
                    return this.formData.penalTdsRate = applicableRate.penalTDSRate;
                }
            } else {
                return this.formData.penalTdsRate = '';
            }
        },
        validatePanNumberByCode(val) {
            const msg = "Please enter correct PAN Number.";
            if (val?.length > 3) {
                return this.panCodeEnum.includes(val[3]) ? true : msg;
            }
            return msg;
        }

    },
}
</script>


<style lang="scss" scoped>
.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)>.v-input__control>.v-input__slot {
    box-shadow: none;
}

.main-dialog-container {
    padding-bottom: 24px;
    padding-top: 70px;
}

.pull-right {
    float: right;
}

.input-label {
    font-size: 10px;
    font-weight: bold;
}

.filter-select-box {
    font-size: 12px;
    border-radius: 0;
}

.text-danger {
    color: red;
}

.file-drop-zone {
    height: 150px;
    min-width: 525px;
    border: 1px dashed #ccc;
    padding-left: 10px;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
}

.required-border {
    border: 1px dashed red;
}

// .drop-zone-text {
//     font-size: 12px;
//     margin-left: -12px
// }

.drop-zone-sub-text {
    font-size: 13px;
    margin-top: 5px;
    color: grey;
}

.drop-zone-note {
    font-size: 10px;
    color: rgb(145, 145, 145);
    margin-top: 5px;
}

.drop-zone-heading-text {
    font-size: 15px;
}

.row .col {
    padding: 0px 12px 0px 12px;
}

.not-allowed {
    cursor: not-allowed;
}
</style>