<template>
    <div>
     <v-container fluid>
       <v-layout row>
         <v-flex md3 sm3 xs6>
           <v-layout column class="cust_usrsrch mx-2 mt-2">
             <p class="font-weight-bold cstm-clr-pln-usrsrch">
                Insured Address
             </p>
             <v-text-field
               solo
               label="Enter..."
               v-model="formData.insuredAddress"
               maxlength="20"
               counter="20"
               class="rounded-0"
               aria-required="true"
               disabled
             >
             </v-text-field>
           </v-layout>
         </v-flex>
         <v-flex md3 sm3 xs6>
           <v-layout column class="cust_usrsrch mx-2 mt-2">
             <p class="font-weight-bold cstm-clr-pln-usrsrch">
                Insured Landmark
             </p>
             <v-text-field
               solo
               label="Enter..."
               v-model="formData.insuredLandmark"
               maxlength="20"
               counter="20"
               class="rounded-0"
               aria-required="true"
               disabled
             >
             </v-text-field>
           </v-layout>
         </v-flex>
         <v-flex md3 sm3 xs6>
           <v-layout column class="cust_usrsrch mx-2 mt-2">
             <p class="font-weight-bold cstm-clr-pln-usrsrch">
                Insured Area
             </p>
             <v-text-field
               solo
               label="Enter..."
               v-model="formData.insuredArea"
               maxlength="20"
               counter="20"
               class="rounded-0"
               aria-required="true"
               disabled
             >
             </v-text-field>
           </v-layout>
         </v-flex>
         <v-flex md3 sm3 xs6>
           <v-layout column class="cust_usrsrch mx-2 mt-2">
             <p class="font-weight-bold cstm-clr-pln-usrsrch">
                Insured State
             </p>
             <v-text-field
               solo
               label="Enter..."
               v-model="formData.insuredState"
               maxlength="20"
               counter="20"
               class="rounded-0"
               aria-required="true"
               disabled
             >
             </v-text-field>
           </v-layout>
         </v-flex>
         <v-flex md3 sm3 xs6>
           <v-layout column class="cust_usrsrch mx-2 mt-2">
             <p class="font-weight-bold cstm-clr-pln-usrsrch">
                Insured City
             </p>
             <v-text-field
               solo
               label="Enter..."
               v-model="formData.insuredCity"
               maxlength="20"
               counter="20"
               class="rounded-0"
               aria-required="true"
               disabled
             >
             </v-text-field>
           </v-layout>
         </v-flex>
         <v-flex md3 sm3 xs6>
           <v-layout column class="cust_usrsrch mx-2 mt-2">
             <p class="font-weight-bold cstm-clr-pln-usrsrch">
                Insured District
             </p>
             <v-text-field
               solo
               label="Enter..."
               v-model="formData.insuredDistrict"
               maxlength="20"
               counter="20"
               class="rounded-0"
               aria-required="true"
               disabled
             >
             </v-text-field>
           </v-layout>
         </v-flex>
         <v-flex md3 sm3 xs6>
           <v-layout column class="cust_usrsrch mx-2 mt-2">
             <p class="font-weight-bold cstm-clr-pln-usrsrch">
                Insured Pin code
             </p>
             <v-text-field
               solo
               label="Enter..."
               v-model="formData.insuredPinCode"
               maxlength="20"
               counter="20"
               class="rounded-0"
               aria-required="true"
               disabled
             >
             </v-text-field>
           </v-layout>
         </v-flex>
         <v-flex md3 sm3 xs6>
           <v-layout column class="cust_usrsrch mx-2 mt-2">
             <p class="font-weight-bold cstm-clr-pln-usrsrch">
                Insured Mobile Number
             </p>
             <v-text-field
               solo
               label="Enter..."
               v-model="formData.insuredMobileNumber"
               maxlength="20"
               counter="20"
               class="rounded-0"
               aria-required="true"
               disabled
             >
             </v-text-field>
           </v-layout>
         </v-flex>
         <v-flex md3 sm3 xs6>
           <v-layout column class="cust_usrsrch mx-2 mt-2">
             <p class="font-weight-bold cstm-clr-pln-usrsrch">
                Insured Email Id
             </p>
             <v-text-field
               solo
               label="Enter..."
               v-model="formData.insuredEmailId
               "
               maxlength="20"
               counter="20"
               class="rounded-0"
               aria-required="true"
               disabled
             >
             </v-text-field>
           </v-layout>
         </v-flex>
         <v-flex md3 sm3 xs6>
           <v-layout column class="cust_usrsrch mx-2 mt-2">
             <p class="font-weight-bold cstm-clr-pln-usrsrch">
                Insured Alternate Address
             </p>
             <v-text-field
               solo
               label="Enter..."
               v-model="formData.insuredAlternateAddress"
               maxlength="20"
               counter="20"
               class="rounded-0"
               aria-required="true"
               disabled
             >
             </v-text-field>
           </v-layout>
         </v-flex>
         <v-flex md3 sm3 xs6>
           <v-layout column class="cust_usrsrch mx-2 mt-2">
             <p class="font-weight-bold cstm-clr-pln-usrsrch">
                Insured Alternate Landmark
             </p>
             <v-text-field
               solo
               label="Enter..."
               v-model="formData.insuredAlternateLandmark"
               maxlength="20"
               counter="20"
               class="rounded-0"
               aria-required="true"
               disabled
             >
             </v-text-field>
           </v-layout>
         </v-flex>
         <v-flex md3 sm3 xs6>
           <v-layout column class="cust_usrsrch mx-2 mt-2">
             <p class="font-weight-bold cstm-clr-pln-usrsrch">
                Insured Alternate Area
             </p>
             <v-text-field
               solo
               label="Enter..."
               v-model="formData.insuredAlternateArea"
               maxlength="20"
               counter="20"
               class="rounded-0"
               aria-required="true"
               disabled
             >
             </v-text-field>
           </v-layout>
         </v-flex>
         <v-flex md3 sm3 xs6>
           <v-layout column class="cust_usrsrch mx-2 mt-2">
             <p class="font-weight-bold cstm-clr-pln-usrsrch">
                Insured Alternate State
             </p>
             <v-text-field
               solo
               label="Enter..."
               v-model="formData.insuredAlternateState"
               maxlength="20"
               counter="20"
               class="rounded-0"
               aria-required="true"
               disabled
             >
             </v-text-field>
           </v-layout>
         </v-flex>
         <v-flex md3 sm3 xs6>
           <v-layout column class="cust_usrsrch mx-2 mt-2">
             <p class="font-weight-bold cstm-clr-pln-usrsrch">
                Insured Alternate City
             </p>
             <v-text-field
               solo
               label="Enter..."
               v-model="formData.insuredAlternateCity"
               maxlength="20"
               counter="20"
               class="rounded-0"
               aria-required="true"
               disabled
             >
             </v-text-field>
           </v-layout>
         </v-flex>
         <v-flex md3 sm3 xs6>
           <v-layout column class="cust_usrsrch mx-2 mt-2">
             <p class="font-weight-bold cstm-clr-pln-usrsrch">
                Insured Alternate District
             </p>
             <v-text-field
               solo
               label="Enter..."
               v-model="formData.insuredAlternateDistrict"
               maxlength="20"
               counter="20"
               class="rounded-0"
               aria-required="true"
               disabled
             >
             </v-text-field>
           </v-layout>
         </v-flex>
         <v-flex md3 sm3 xs6>
           <v-layout column class="cust_usrsrch mx-2 mt-2">
             <p class="font-weight-bold cstm-clr-pln-usrsrch">
                Insured Alternate Pincode
             </p>
             <v-text-field
               solo
               label="Enter..."
               v-model="formData.insuredAlternatePincode"
               maxlength="20"
               counter="20"
               class="rounded-0"
               aria-required="true"
               disabled
             >
             </v-text-field>
           </v-layout>
         </v-flex>
         <v-flex md3 sm3 xs6>
           <v-layout column class="cust_usrsrch mx-2 mt-2">
             <p class="font-weight-bold cstm-clr-pln-usrsrch">
                Insured Alternate E-mail1
             </p>
             <v-text-field
               solo
               label="Enter..."
               v-model="formData.insuredAlternateEMail1"
               maxlength="20"
               counter="20"
               class="rounded-0"
               aria-required="true"
               disabled
             >
             </v-text-field>
           </v-layout>
         </v-flex>
         <v-flex md3 sm3 xs6>
           <v-layout column class="cust_usrsrch mx-2 mt-2">
             <p class="font-weight-bold cstm-clr-pln-usrsrch">
                Insured Alternate E-mail2
             </p>
             <v-text-field
               solo
               label="Enter..."
               v-model="formData.insuredAlternateEMail2"
               maxlength="20"
               counter="20"
               class="rounded-0"
               aria-required="true"
               disabled
             >
             </v-text-field>
           </v-layout>
         </v-flex>
         <v-flex md3 sm3 xs6>
           <v-layout column class="cust_usrsrch mx-2 mt-2">
             <p class="font-weight-bold cstm-clr-pln-usrsrch">
                Insured Alternate Mobile Number
             </p>
             <v-text-field
               solo
               label="Enter..."
               v-model="formData.insuredAlternateMobileNumber"
               maxlength="20"
               counter="20"
               class="rounded-0"
               aria-required="true"
               disabled
             >
             </v-text-field>
           </v-layout>
         </v-flex>
       </v-layout>
     </v-container>
   </div>
  </template>
  <script>
  export default {
     data() {
         return {
             formData: {
                insuredAddress: '',
                insuredLandmark: "",
                insuredArea: "",
                insuredState: "",
                insuredCity: "",
                insuredDistrict: "",
                insuredPinCode: "",
                insuredMobileNumber: "",
                insuredEmailId: "",
                insuredAlternateAddress: "",
                insuredAlternateLandmark: "",
                insuredAlternateArea: "",
                insuredAlternateState: "",
                insuredAlternateCity: "",
                insuredAlternateDistrict: "",
                insuredAlternatePincode: "",
                insuredAlternateEMail1: "",
                insuredAlternateEMail2: "",
                insuredAlternateMobileNumber: ""
             }
         }
     },
     props: ["claimDetails"],
     created() {
    },
     mounted() {
      this.setInsuredDetails();
     },
     methods: {
      async setInsuredDetails() {
        try {
          this.formData.insuredAddress = this.claimDetails?.claimsDetail?.address;
          this.formData.insuredLandmark = this.claimDetails?.claimsDetail?.landmark;
          this.formData.insuredArea = this.claimDetails?.claimsDetail?.area;
          this.formData.insuredArea = this.claimDetails?.claimsDetail?.area;
          this.formData.insuredState = this.claimDetails?.claimsDetail?.state;
          this.formData.insuredCity = this.claimDetails?.claimsDetail?.cityTownVillage;
          this.formData.insuredDistrict = this.claimDetails?.claimsDetail?.district;
          this.formData.insuredPinCode = this.claimDetails?.claimsDetail?.pinCode;
          this.formData.insuredMobileNumber = this.claimDetails?.claimsDetail?.mobileNumber;
          this.formData.insuredEmailId = this.claimDetails?.claimsDetail?.email;
          this.formData.insuredAlternateAddress = this.claimDetails?.claimsDetail?.alternateAddress;
          this.formData.insuredAlternateLandmark = this.claimDetails?.claimsDetail?.alternateLandmark;
          this.formData.insuredAlternateState = this.claimDetails?.claimsDetail?.alternateState;
          this.formData.insuredAlternateCity = this.claimDetails?.claimsDetail?.alternateCityTownVillage;
          this.formData.insuredAlternateDistrict = this.claimDetails?.claimsDetail?.alternateDistrict;
          this.formData.insuredAlternatePincode = this.claimDetails?.claimsDetail?.pinCode;
          this.formData.insuredAlternateEMail1 = this.claimDetails?.claimsDetail?.alternateEmailOne;
          this.formData.insuredAlternateEMail2 = this.claimDetails?.claimsDetail?.alternateEmailTwo;
          this.formData.insuredAlternateMobileNumber = this.claimDetails?.claimsDetail?.alternateMobileNumberOne;
        } catch(e) {
          this.showToast(e.response?.data?.msg || e.response?.data  || e.response || e, this.TOST().ERROR);
        }
      }
     }
  }
  </script>