<template>
    <v-app  class="mrtop">
       <div>
          <v-flex xs12>
                <v-layout column class="mt-2 cust_pr_quick cust-rmvpd-pr-quick font-weight-bold graybackground" style="padding:10px;color:#000000;">
                  General Audit Trail
                </v-layout>
            </v-flex>

         <v-flex id="claimListTable" style="overflow:hidden">
              <template>
              <v-data-table
                :headers="generalauditHeader"
                :items="generalAuditTrailDetail"
                hide-default-footer
                class="vDataTableStyling"
               
            >
             <template v-for="header in generalauditHeader.filter((header) => header.hasOwnProperty('formatter'))" v-slot:[`item.${header.value}`]="{ header, value }">
                    {{ header.formatter(value) }}
                </template>

                <template v-slot:item.srNo="{  index }">
                    <span>{{ index + 1}}</span>
                    </template>
            <!-- <template v-slot:item="{ item, index }">
                <tr>
                 <td>{{index + 1}}</td> 
                  <td>{{item.transactionDate ? new Date(item.transactionDate).toLocaleDateString("en-IN"): ""}}</td>
                   <td>{{item.transactionTime }}</td>
                   <td>{{item.activity}}</td>
                   <td>{{item.amount}}</td>
                   <td>{{item.examiner}}</td>
                   <td>{{item.approver}}</td>
                   <td>{{item.status}}</td>
                   <td>{{item.subStatus}}</td>
                </tr>    

            </template>     -->
           
        <!-- <template v-slot:item.srNo="{ item, index }">
           <span>{{item.transactionDate ? new Date(item.transactionDate).toLocaleDateString("en-IN"): ""}}</span>
         </template>

        <template v-slot:item.transactionDate="{ item }">
           <span>{{item.transactionDate ? new Date(item.transactionDate).toLocaleDateString("en-IN"): ""}}</span>
         </template> -->
               
            </v-data-table>
        </template>
           <div class="text-center graybackground pdct-stUp-bnkAcc-pgn" >
                <v-row>
                    <v-col cols="6"></v-col>
                    <v-col cols="6">
                        <v-row class="paginrow">
                            <v-spacer></v-spacer>
                        <div class="mr10" > 
                            <span class="orangetext"> Showing {{pageStartIndex }} - {{pageEndIndex}}</span>                         
                            <span> out of {{totalListCount}} records </span>
                        </div>                               
                        <v-pagination
                            v-model="page"
                            :length="getNoOfPages"
                            :total-visible="5"
                            prev-icon="mdi-arrow-left"
                            next-icon="mdi-arrow-right"
                            color="#152F38"                    
                            @input="NextPage"
                        ></v-pagination>
                        </v-row>
                    </v-col>
                 </v-row>
                 <br>
               
            </div>
          
        </v-flex>
          <v-layout  class="closwsec my-4">
                  <v-row>
                    <v-col align="center" class="mt-8 mb-3">
                        
                            <v-btn
                            color="#E46A25"
                            class="buttonscolor mr-5"
                            @click="$router.go(-1)">
                            <v-icon class="mr-2" style="font-size: 19px">mdi-close-circle</v-icon>
                            <div class="vertical_line"></div>Close
                        </v-btn>
                    </v-col>
                </v-row>
          </v-layout>
       </div>
        </v-app>
</template>

<script>

import moment from 'moment';

export default {
    
    data(){
        return{
            pageStartIndex  :   1,
            pageRowCount  :   10,
            totalListCount  :   0,
            page            :   1,
          generalauditHeader:  [
                 {text: 'Sr No.', align: 'start',value: 'srNo', class:"headercolor"},
                 {text: 'Transaction Date', align: 'start',value: 'transactionDate', sortable:false, formatter:this.formatDate},
                 {text: 'Transaction Time', align: 'start',value: 'transactionTime', sortable:false, formatter: this.formatTime},
                 {text: 'Activity', align: 'start',value: 'activity', sortable:false},
                 {text: 'Amount', align: 'start',value: 'amount', sortable:false},
                 {text: 'Examiner', align: 'start',value: 'examiner', sortable:false},
                {text: 'Approver', align: 'start',value: 'approver', sortable:false},
                {text: 'Status', align: 'start',value: 'status', sortable:false},
                {text: 'Sub-Status', align: 'start',value: 'subStatus', sortable:false},
               
             ],
             
             generalAuditTrailDetail:[],
             generalList:[],
        }
    },
    computed : {
      getNoOfPages(){
            if(!this.totalListCount){
                return 0;
            }else{
                return Math.ceil(this.totalListCount / this.pageRowCount);
            }
        },
         pageEndIndex(){
            if(!this.generalAuditTrailDetail.length){
                return 0
            }else{
                return ((this.PageStartIndex + (this.pageRowCount)) <= this.totalListCount) ? this.PageStartIndex + (this.pageRowCount) - 1 : this.totalListCount;
                
            }
        }
    },
    methods:{
            goToNextPage(newPageNum){
            //     console.log(this.auditTrailDetail.length)
            // this.PageStartIndex =   1;
            // this.totalListCount =   this.auditTrailDetail.length; 
             
           console.log("Event - ",event,this.page);
            this.showProgress();
            let reqData =   "perPage="+this.pageRowCount+"&pageNo="+newPageNum+"&mainClaimNumber="+this.$store.getters.fetchMainClaimNumberObj+"&featureNumber="+this.$store.getters.fetchFeatureNumber;
            this.GETM("GET_GENERAL_AUDITTRAIL",reqData,(res)=>{
                this.hideProgress()
                console.log("- ",res);
                if(res.data && res.data.data.data && res.data.data.data.length > 0){
                    this.generalList      =   res.data.data.data;
                    console.log( 'checkarray', this.generalList)
                    this.totalListCount =   res.data.data.total;
                   
                    this.generalAuditTrailDetail =   this.generalList.length > 0 ? this.generalList.slice(0,this.pageRowCount) : [];
                    this.pageStartIndex =   ((newPageNum - 1) * this.pageRowCount);
                    console.log("clainTableList - ",this.generalAuditTrailDetail);
                     this.hideProgress()
                    this.totalListCount =   res.data.data.total;
                    this.generalAuditTrailDetail =   this.generalList.length > 0 ? this.generalList.slice(0,this.pageRowCount) : [];
                    this.pageStartIndex =   newPageNum == 1 ? 1 : ((newPageNum - 1) * this.pageRowCount) + 1;
                    console.log("clainTableList - ",this.generalAuditTrailDetail);
                    //  this.generalAuditTrailDetail.forEach(obj=>{
                    //      var tempTime = moment.duration(obj.transactionTime);
                    //     var y = tempTime.hours() + tempTime.minutes();
                    //     console.log(tempTime)
                    //  })
                     this.hideProgress()
                } 
                   
                else{
                    this.showAlert("No data available.")
                    this.generalList      =   [];
                    this.generalAuditTrailDetail=[]
                    this.totalListCount =   0;
                    this.pageStartIndex =   0;
                }
                
            },(err)=>{
                    this.hideProgress()
                    console.log("WORKITEM_SEARCH Err -",err);
                    this.generalList      =   [];
                    this.totalListCount =   0;
                    this.generalAuditTrailDetail =   [];
                    this.pageStartIndex =   0; 
                    this.showAlert(err.response.data.msg)
               
            });

        },
         NextPage(){
            console.log(this.page)
            this.goToNextPage(this.page)
         },
          formatTime(transactionTime){
            console.log(transactionTime);
            return transactionTime ? new Date(parseInt(transactionTime)).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }) : "";
        },
         formatDate(date){
            return date ? moment(date).format("DD-MM-YYYY") : "";
        },
       
    },
     mounted(){
            
            this.goToNextPage(1)
        }
}
</script>

<style scoped>
.vDataTableStyling {
    white-space: nowrap;
}
.graybackground {
        background-color:  #F7F7F7;
    }
    .buttonscolor {
        border-radius: 0 !important;
        height: 31px !important;
        color: #f5f6fa !important;
        text-transform: capitalize;
        float:right;
    }
    .vDataTableStyling tr td {
      text-align: left !important;
   }
   .paginrow{
     margin-right:10px;
     margin-top:10px;
     align-items: center;
   }
 .mr10{
  margin-right:10px;
 }
 .closwsec{
     background-color: #F7F7F7; 
     height:95px; 
     border: 0.3px solid #C1C8CC
 }  
 .mrtop{
   margin-top:10px
 }
</style>