import * as URL from '../common/API_Config.js';
import * as xlsx from "xlsx";
import axios from 'axios';
import { mapMutations } from 'vuex';
import a_c_t_data from '../app/auth'
import { Auth } from "aws-amplify";
import * as vuestore from '../store/index'
import store from '../store/index'
import moment from 'moment';
import routeDetails from '../routes.js'
// console.log("store is from mixin",store._modules.root.state.Auth.user.signInUserSession.idToken.payload.jti)

  let thejti = "ff685a9d-fa17-44b8-aa64-c8b9e1c2bf77"
  // store._modules.root.state.Auth.user.signInUserSession.idToken.payload.jti

// axios.interceptors.response.use(function (response) {
//   // Any status code that lie within the range of 2xx cause this function to trigger
//   // Do something with response data
//   return response;
// }, function (error) {
//     console.log("old error first occurred", error.config)
//   // Any status codes that falls outside the range of 2xx cause this function to trigger
//   // Do something with response error
//     if(!error.config.data){
//       let access_store = store._modules.root.state;
//         return refresh_token(access_store).then(_ => {
//             error.config.headers['Authorization'] = store._modules.root.state.Auth.user.signInUserSession.idToken.jwtToken
//             console.log(">>new error ->",error.config)
//             return axios.request(error.config);
//         });
//     }
     



//   return Promise.reject(error);
// });

//   function refresh_token(access_store){
//     // store._modules.root.state.Auth.user.signInUserSession.idToken.payload.jti

//       console.log("access_store --->",access_store.is_refreshing)
//       alert("refresh token function called as failed api spotted")
//       if(access_store.is_refreshing) {
//         alert("in IF")
//           return access_store.refreshingCall;
//       }




       
//     store.commit('SET_REFRESHSTATE_ONE',true);
//     alert("starting refresh state, so commit true")

//     const refreshingCall = axios.get(URL.COGNITOLOGIN+'session/refresh?jti='+thejti).then((response, err)=>{

//       if(response){
//         console.log("getting token after redirection _id received is", response.data)
//         let authdata = {user:null}
//         alert("setting ID & ACC from refresh"); 
//         authdata.user = this.process_response(response)
//         console.log("authdata is >>",authdata)
//         window.localStorage.setItem('cognito.'+'type'+'x', JSON.stringify(authdata.user.signInUserSession.idToken.jwtToken));
//         window.localStorage.setItem('cognito.'+'type'+'y', JSON.stringify(authdata.user.signInUserSession.accessToken.jwtToken));
        
//         store.commit('SET_LOGINDATA',authdata);
//         store.commit('SET_REFRESHSTATE_ONE',false);
//         store.commit('SET_REFRESHCALL',undefined);

//         return Promise.resolve(true);
//       }

//     }).catch(caterr=>{
//       return Promise.reject(false);
//       console.log("from catch on refreshing the session/token",caterr);
//     })

//       console.log("refreshingCall after const",refreshingCall)
//     store.commit('SET_REFRESHCALL',refreshingCall);
//     return refreshingCall

//   }


// error.response.data.message
export default {
  data() {
    return {
        url: "",
        XLSX:xlsx
    }
},
  methods: {

    

    moneyfilter (input) {
      // console.log("mobeyfilter", input)
      if(typeof input != 'number'){
          input = parseInt(input)
      }
      input = input.toLocaleString('en-IN', {
          maximumFractionDigits: 2,
          style: 'currency',
          currency: 'INR'
      });
      return input = isNaN(input) ? "-" : input
  },
    
     user_details(){
            // return a_c_t_data.auth.signInUserSession.idToken.payload
            return this.$store.state.Auth.user.signInUserSession.idToken.payload
            // Auth.user.signInUserSession.idToken.payload
     },

    //AA for role

     fetchRoleValue(){
      console.log(this.user_details(),"=====------++--===> rolelist before split")
      // let rolelist=this.user_details().CM ? this.user_details().CM.roleDescription.split('..') : this.user_details().Health.roleDescription.split('..')
      let rolelist=this.user_details().CM ? this.user_details().CM.roleDescription : this.user_details().Health.roleDescription

    
      // let rolelist=this.user_details().RD.split('.')
      // let rolelist=this.user_details().CM.roleDescription.split('.')
      // console.log(rolelist,"=====____> rolelist after split")

      return rolelist
      // let b = {}
      // let final = []

      // rolelist.map(e=>{
      //     b = {}
      //          console.log("e -->", e.split(".."))
      //         b['type'] = e.split("..")[0]
      //         b['value'] = e.split("..")[1]

      //         final.push(b)
      // })

    //    console.log(final[0], "----finall role list====+++")
    //   return final[0]
    },
      

    getdocs(indata){
      console.log("indata of getdocs is ", indata)
          if(indata == "") return
        this.GET(2,'empanelment/empanelment-details-by-id/' + indata._id,(res,error)=>{
          // console.log('(((((((((( EMPPPP   RESPPPP ))))))))))',res.data.data)
          try{
              if(!error){
                  console.log(" documents from details of emp by ID res.data.data -> ",  res.data.data.documents);
                  this.$store.commit('EMPDOCS', res.data.data.documents);

              }
          }catch(e){
              console.log("something went wrong during getting empanelment data", e);
          }
      
        })

    },

    validate_alpha_numeric(value, identity, callback) {

      if (identity == 1) {

        //for titles
        if (value.length > 0) {
          if (/^[0-9a-zA-z!?.&, \s]+$/g.test(value) != true) {

            //value = value.replace(/[^0-9A-Za-z!?., ]/g, ''); 
            callback(true, value)
          } else {
            callback(false, value)
          }
        }
      } else if (identity == 2) {

        // for descriptions
        if (value.toString().length > 0) {
          if (/^[0-9a-zA-z &;:,!.?= \s]*$/g.test(value) != true) {

            value = value.replace(/[^0-9a-zA-z &;:,!.?= \s]/g, '');
            callback(true, value)
          } else {
            callback(false, value)
          }
        }
      } else if (identity == 3) {

        // for only numbers
        if (value.toString().length > 0) {
          if (/^[0-9]*$/g.test(value) != true) {
            value = value.replace(/\D/g, '');
            callback(true, value)
          } else {
            callback(false, value)
          }
        }

      } else if (identity == 4) {

        // for only alphabets
        if (value.length > 0) {
          if (/^[a-zA-z \s]+$/g.test(value) != true) {
            value = value.replace(/[^A-Za-z \s]/g, '');
            callback(true, value)
          } else {
            callback(false, value)
          }
        }

      } else {

        // for only alphabets and numbers
        if (value.toString().length > 0) {
          if (/^[0-9a-zA-z \s]+$/g.test(value) != true) {
            value = value.replace(/[^0-9A-Za-z \s]/g, '');
            callback(true, value)
          } else {
            callback(false, value)
          }
        }

      }

    },

    ...mapMutations(['showSnackbar', 'closeSnackbar']),
    jump(to) {
      if (this.$router) {
        this.$router.push(to)
      }
    },
    TOST() {
      return {
        INFO: 'info',
        SUCCESS: 'success',
        ERROR: 'error',
        WARNING: 'warning'
      }
    },

    generateRandomCode(howMany = 6, key = 'abcdefhgklmnpqrtuysovwxz0123456789') {
      let code = '';
      for (let x = 0; x < howMany; x++) {
        code += key.charAt(Math.floor(Math.random() * key.length));
      }
      return code;
    },

    imageURL() {
      var imgUrl = URL.Image_URL
      return imgUrl;
    },
    SALES_DRIVE_URL() {
      var Sales_drive = URL.SALESDRIVE_URL
      return Sales_drive;
    },

    showToast(content, type) {
      type == "info" ? this.showSnackbar({
          text: content,
          color: 'info'
        }) :
        type == "success" ? this.showSnackbar({
          text: content,
          color: '#43A047'
        }) :
        type == "error" ? this.showSnackbar({
          text: content,
          color: 'error'
        }) :
        type == "warning" ? this.showSnackbar({
          text: content,
          color: '#FB8C00'
        }) : ""
    },

    API_Service() {
      var API = URL.USER_URL;
      return API
    },

    API_Service_admin() {
      var API = URL.ADMIN_URL;
      return API
    },

    API_Service_MASTER(){
      var api = URL.master;
      return api;
    },
    API_Service_NETWORK_MGMNT(){
      var api = URL.NETWORK_URL;
      return api;
    },
    API_Service_USER_MGMNT(){
      var api = URL.USER_MGMNT_URL;
      return api;
    },

    API_Service_OFR_CFR(){
      var API = URL.OFR_CFR_SERVICE;
      return API
    },
    API_Service_OFR_CFR_MASTER(){
      var API = URL.OFR_CFR_Master;
      return API
    },

    
        
   
    API_Service_TASK_MGMNT(){
      var api = URL.TASK_MGMNT;
      return api
    },

    API_Service_admin_bank() {
      var API = URL.BANK_URL;
      return API
    },

    API_Service_admin_bulklist() {
      var API = URL.BUKLIST_URL;
      return API
    },

    
      
      

    GET(TYPE,API, callback) {
      // g99
      let self = this;
      let sys = {}
      if(TYPE == 6 || TYPE == 3 || TYPE === 0){
          let auth = this.authHeader()
          console.log("this.user_details().lob ",this.user_details())
            auth.headers.system = "CM" //this.user_details().lob ? this.user_details().lob :
            // this.$store.state.lob_type
            sys = {
              ...auth
            }
      }else{
         sys = this.authHeader()
      }

      console.log(sys,"authh headers ?", this.authHeader());
      self.showLoader("",true);
      let url = TYPE ? this.SET_API_TYPE(TYPE) + API : API
      axios.get(url, sys
      // this.authHeader()
      )
        .then(function(response) {
             self.showLoader("catch of GET",false);
          // if (response.data.errCode === 2025) {
          //   localStorage.clear();
          //   self.$store.commit('SET_AGENT_DATA', {})
          //   self.showLoader('Loading', false);
          //   self.jump('/LoginView');
          //   self.showToast('UnAuthorised User', 'error', )
          // } else if (response.data.errCode === 2916) {
          //   self.OpenSessionExpiredpopup(true)
          // } else if (response.data.errCode === 3025) {
          //   localStorage.clear();
          //   self.$store.commit('SET_AGENT_DATA', {})
          //   self.showLoader(false);
          //   self.jump('/LoginView');
          //   self.showToast('UnAuthorised User', 'error', )
          // } else {
            self.checksession(response, res=>{
                    if(res == 'continue'){
                        // successfulCallback(response);
                        callback(response)
                    }else{
                        console.log("An error occurred while fetching data",response)
                    }
            })
          // } 
        })
        .catch(function(error) {
          console.log(error,"catch of GET", error.response && error.response.data && error.response.data.message ? error.response.data.message : " Err")
          self.showLoader("catch of GET",false);
          // message: "The incoming token has expired"
          if (error.response && error.response.status && error.response.status == 401) {
            self.showAlert("Session Expired, kindly relogin to continue.")
            self.log_out_function()
          }
          callback(null, error);
        });
    },
    GETSTATE(callback) {
      this.GET('getState_city?userId=' + this.$store.state.agentDetails._id + '&getstate=allstate', res => {
        if (res.data.errCode === -1) {
          callback(res.data.errMsg)
        }
      })
    },
    GETCITY(statecode, callback) {
      this.GET('getState_city?userId=' + this.$store.state.agentDetails._id + '&stateCode=' + statecode, res => {
        if (res.data.errCode === -1) {
          callback(res.data.errMsg)
        }
      })

    },

    SET_API_TYPE(TYPE){
      return TYPE == 1 ? this.API_Service_admin() : TYPE == 3 ? this.API_Service_MASTER() : TYPE == 4 ? this.API_Service_NETWORK_MGMNT() : TYPE == 5 ? this.API_Service_TASK_MGMNT() :  TYPE == 6 ? this.API_USER_MGMNT_SERVICE() : TYPE == 7 ? this.API_Service_ClaimsManagement()  : TYPE == 8 ? this.API_Service_ClaimsIntimation() : TYPE == 100 ? this.cognito_login() :TYPE == 20 ? this.API_Service_OFR_CFR() :TYPE == 21 ? this.API_Service_OFR_CFR_MASTER() : this.API_Service_provider(); 
      // return TYPE == 1 ? this.API_Service_admin() : TYPE == 3 ? this.API_Service_MASTER()  : this.API_Service_provider(); 
    },

    santizedata(data){
      console.log("indatais ",data); 
      try{
          if (data ==="" || data === "undefined" || data === null) {
              return ""
          } else {
              return data;
          }

       }catch(err) {
          console.log(err)
      }
      
  },

    DELETE_FORMDATA(TYPE,API, callback){
      let self = this;
     
      axios.delete(this.SET_API_TYPE(TYPE)+API, this.authHeader())
        .then(function(response) {
          console.log('response of delete mixin is ', response)
          // if (response.data.errCode === 2025) {
          //   localStorage.clear();
          //   self.$store.commit('SET_AGENT_DATA', {})
          //   self.showLoader('Loading', false);
          //   self.jump('/LoginView');
          //   self.showToast('UnAuthorised User', 'error', )
          // } else if (response.data.errCode === 2916) {
          //   self.OpenSessionExpiredpopup(true)
          // } else if (response.data.errCode === 3025) {
          //   localStorage.clear();
          //   self.$store.commit('SET_AGENT_DATA', {})
          //   self.showLoader(false);
          //   self.jump('/LoginView');
          //   self.showToast('UnAuthorised User', 'error', )
          // } else {
            callback(response)
          // }
        })
        .catch(function(error) {
          callback(null, error);
        });
    },

     authHeaderNew() {
      return {
         
          "Content-Type": "application/json", 
          "Authorization": this.$store.state.Auth && this.$store.state.Auth.user ? this.$store.state.Auth.user.signInUserSession.idToken.jwtToken : ''//this.$store.state.Auth.user.signInUserSession.idToken.jwtToken
          // a_c_t_data.auth.signInUserSession.idToken.jwtToken
          

      }
    },

    DELETE(TYPE,API, callback){
      let self = this;
      axios.delete(this.SET_API_TYPE(TYPE)+API, this.authHeader())
        .then(function(response) {
          console.log('response of delete mixin is ', response)
          // if (response.data.errCode === 2025) {
          //   localStorage.clear();
          //   self.$store.commit('SET_AGENT_DATA', {})
          //   self.showLoader('Loading', false);
          //   self.jump('/LoginView');
          //   self.showToast('UnAuthorised User', 'error', )
          // } else if (response.data.errCode === 2916) {
          //   self.OpenSessionExpiredpopup(true)
          // } else if (response.data.errCode === 3025) {
          //   localStorage.clear();
          //   self.$store.commit('SET_AGENT_DATA', {})
          //   self.showLoader(false);
          //   self.jump('/LoginView');
          //   self.showToast('UnAuthorised User', 'error', )
          // } else {
            callback(response)
          // }
        })
        .catch(function(error) {
          callback(null, error);
        });
    },

    POST(TYPE,API, formData, callback) {

      let routeName=this.$router.history.current.name
      // let routeName="CFR"
      console.log("this.$store.state.screens_obj.hasOwnProperty(routeName) ", this.$store.state.screens_obj[routeName])
      let act = false
      if(!this.$store.state.hasOwnProperty('screens_obj')){
        console.log("permissions not found, please set permissions for the user")
        this.showToast("permissions not found", this.TOST().ERROR)
        return
      }
      if(this.$store.state.screens_obj.hasOwnProperty(routeName)){
        console.log(routeName,"current_routes name inside if",this.$store.state.screens_obj[routeName])
        act=this.$store.state.screens_obj[routeName].isEdit
      }

      act = this.$store.state.claimicon == true ? true : act
      if(window.location.pathname == '/login') {
        act = true
      }
      if(!act){
        console.log(act,"iisEdit False")
        console.log("NO hit API")
        this.showToast(routeName + " is view only", this.TOST().WARNING)
        // return
      }

      // console.log(isEdit,"will hit API")
      // return

      let self = this;
     
      let sys = {}

      if(window.location.pathname != '/login') {
        if(TYPE == 6){
            let auth = this.authHeader()
            auth.headers.system = "CM"//this.user_details().selectedLOB ? this.user_details().selectedLOB :
            sys = {
              ...auth
            }
        }else{
          sys = this.authHeader()
        }
      }
      self.showLoader('Loading', true);
      axios.post(this.SET_API_TYPE(TYPE)+API, formData, sys
      // this.authHeader()
      )
        .then(function(response) {
          console.log('response of POST mixin is ', response)

            self.showLoader('Loading', false);
            self.checksession(response, res=>{
              if(res == 'continue'){
                          // successfulCallback(response);
                          callback(response)
                      }else{
                          console.log("An error occurred while fetching data",response)
                      }
            })
           
          // }
        })
        .catch(function(error) {
          self.showLoader('Loading', false);
          if (error.response && error.response.status && error.response.status == 401) {
            self.showAlert("Session Expired, kindly relogin to continue.")
            self.log_out_function()
          }
          callback(null, error);
        });
    },

    PUT(TYPE,API, formData, callback) {

      let routeName=this.$router.history.current.name

      // let routeName="CFR" isEdit
      console.log(this.$store.state.screens_obj,"<- this.$store.state.screens_obj.hasOwnProperty(routeName) ", routeName)
      let act = false
      if(!this.$store.state.hasOwnProperty('screens_obj')){
        console.log("permissions not found, please set permissions for the user")
        this.showToast("permissions not found", this.TOST().ERROR)
        return
      }
      if(this.$store.state.screens_obj.hasOwnProperty(routeName)){
        console.log(routeName,"current_routes name inside if",this.$store.state.screens_obj[routeName])
        act=this.$store.state.screens_obj[routeName].isEdit
      }

      act = this.$store.state.claimicon == true ? true : act

      if(!act){
        console.log(act,"act False")
        console.log("NO hit API")
        this.showToast(routeName + " is view only", this.TOST().WARNING)
        // return
      }

      console.log(act,"will hit API")
      

      let self = this;
      self.showLoader('Loading', true);
          let sys = {}
      if(TYPE == 6){
          let auth = this.authHeader()
            auth.headers.system = "CM"//this.user_details().selectedLOB ? this.user_details().selectedLOB : 
            // this.$store.state.lob_type
            sys = {
              ...auth
            }
      }else{
          sys = this.authHeader()
      }

      axios.put(this.SET_API_TYPE(TYPE)+API, formData, sys)
        .then(function(response) {
          console.log('from put mixin is ',response)

          self.showLoader('Loading', false);
          self.checksession(response, res=>{
              if(res == 'continue'){
                  // successfulCallback(response);
                  callback(response)
              }else{
                  console.log("An error occurred while fetching data",response)
              }
          })

        })
        .catch(function(error) {
          self.showLoader('Loading', false);
          console.log(error)
          if (error.response && error.response.status && error.response.status == 401) {
            self.showAlert("Session Expired, kindly relogin to continue.")
            self.log_out_function()
          }
          callback(null, error);
        });
    },

    GETBANKAPI(API, callback) {
      let self = this
      self.showLoader(true);
      axios.get(this.API_Service_admin_bank() + API, this.authHeader())
        .then(function(response) {
          self.showLoader(false);
          self.checksession(response, res=>{
                    if(res == 'continue'){
                        // successfulCallback(response);
                        callback(response)
                    }else{
                        console.log("An error occurred while fetching data",response)
                    }
          })
          // callback(response);
        })
        .catch(function(error) {
          self.showLoader(false);
          if (error.response && error.response.status && error.response.status == 401) {
            self.showAlert("Session Expired, kindly relogin to continue.")
            self.log_out_function()
          }
          callback(null, error);
        });
    },

    GETBULKLIST(API, callback) {
      let self = this
      self.showLoader(true);
      axios.get(this.API_Service_admin_bulklist() + API, this.authHeader())
        .then(function(response) {
          self.showLoader(false);
          self.checksession(response, res=>{
                    if(res == 'continue'){
                        // successfulCallback(response);
                        callback(response)
                    }else{
                        console.log("An error occurred while fetching data",response)
                    }
          })
        })
        .catch(function(error) {
          self.showLoader(false);
          if (error.response && error.response.status && error.response.status == 401) {
            self.showAlert("Session Expired, kindly relogin to continue.")
            self.log_out_function()
          }
          callback(null, error);
        });
    },

    getAgentInfo() {
      return this.$store.getters.getAgentDetails;
    },
    paginationdiff() {
      let diff = 15
      return diff
    },

    node_img_url() {
      var imgsrc = URL.node_img_url
      return imgsrc;
    },
    readImage(evt, callback) {
      let reader = new FileReader();
      reader.readAsDataURL(evt.target.files[0]);
      let temp = {};
      reader.onload = function() {
        temp.image = reader.result;
        temp.name = evt.target.files[0].name;
        callback(temp);
      };
    },
    readImageNew(fileName, fileData, callback) {
      let reader = new FileReader();
      reader.readAsDataURL(fileData);
      let temp = {};
      reader.onload = function() {
        temp.base = reader.result;
        temp.name = fileName;
        callback(temp);
      };
    },
    showConfirmDialog(title, message, type, callback) {
      this.commit('SHOW_CONFIRM_BOX', {
        title,
        message,
        type,
        show: true,
        callback
      });
    },
    showLoader(title, bool) {
      this.$store.commit('SHOW_LOADER', {
        title: title,
        bool: bool
      });
    },

    commit(commitName, data) {
      this.$store.commit(commitName, data);
    },
    getStore(getter) {
      return this.$store.getters[getter];
    },
    //user data
    user_data(){
      return { auth: this.$store.state.Auth.user }
      // return Auth
    },

    authHeader() {
      return {
        headers: {
          "Content-Type": "application/json", 
          "Authorization": this.$store.state.Auth && this.$store.state.Auth.user ? this.$store.state.Auth.user.signInUserSession.idToken.jwtToken : '',//this.$store.state.Auth.user.signInUserSession.idToken.jwtToken
          "x-api-key": process.env.VUE_APP_API_KEY
          //  a_c_t_data.auth.signInUserSession.idToken.jwtToken
          // "x-access-token": this.$store.getters.successToken,

        
          //  "x-access-token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI1ZmJlMDg4ZGM0NmRhODNlZTdmODcxYmIiLCJyb2xlIjoiQWRtaW5Vc2VyIiwiaWF0IjoxNjA2MzY5MzI0LCJleHAiOjE2Mzc5MDUzMjR9.iS5hZs0uImNFz-rxNaQ7CTCc6qS_hG4H0FsePZ5Sgqc",
      },
        // headers: {
        //   Authorization: 'JWT ' + this.getAgentInfo().JWT_token,
        //   sid: this.getAgentInfo().SID_Code
        // }
      }
    },

      // "role": this.$store.getters.current_role

    // Mixin filter

    doSentenceCase(strText) {
      if (strText === '' || strText == null || typeof(strText) === undefined || !isNaN(strText)) {
        return strText;
      }

      try {
        var _str = strText.toLowerCase();
        var collection = _str.split(" ");
        var modifyStrigs = [];
        _str = '';
        for (var i = 0; i < collection.length; i++) {
          modifyStrigs[i] = collection[i].charAt(0).toUpperCase() + collection[i].slice(1);
          _str = _str + modifyStrigs[i] + ' ';
        }
        return _str;
      } catch (err) {
        return strText;
      }
    },
    idFilter(id, initial = null) {
      try {
        if (id !== '') {
          if (initial === null || initial === undefined || initial === "") {
            initial = 'L';
          }
          if (typeof(id) !== undefined) {
            id = initial + id.slice(16, 25).toUpperCase();
          }
        }
        return id;
      } catch (err) {
        return id;
      }
    },
    OpenSessionExpiredpopup(event) {
      this.$store.commit('LOGOT_SESSION', event)
    },

    getStates() {
      return this.$store.getters.fetchStatesCities.states;
    },

    filterCities(state) {
      let self = this;
      try {
        let cities = this.$store.getters.fetchStatesCities.allCities.filter(e => e.StateName.toLowerCase().localeCompare(state.toLowerCase()) === 0);
        console.log('cities from filter cities are ->', cities);
        let citList = cities.map(e => self.doSentenceCase(e.cityName));

        return citList;
      } catch (exception) {
        console.log(exception)
      }
    },

    matchCity(city, cities) {
      let filterCity = cities.filter(e => e.toLowerCase().localeCompare(city.toLowerCase()) === 0);
      if (filterCity.length === 0) {
        return '';
      } else {
        return filterCity[0];
      }
    },
    API_Service_provider() {
      var API = URL.PROVIDER_URL;
      return API
    },
    API_Service_ClaimsManagement() {
      var API = URL.CLAIMS_MANAGEMENT;
      return API
    },

    API_Service_ClaimsIntimation(){
      var API = URL.CLAIMS_INTIMATION;
      return API
    },

    API_USER_MGMNT_SERVICE(){
      var API = URL.USER_MGMNT_SERVICE;
      return API
    },
    getTariffById(id,callback){
      let api_Is = 'tariff/get-tariff-by-id/'+id
      this.GET(2,api_Is,(res,error)=>{
        try{
              if(!error){
                console.log("GET APi response|-> empanelment/empanelment-details-by-id", res.data.data)
                let tempData= res.data.data
                callback(tempData)
            }else{
                console.log("GET APi error response|->empanelment/empanelment-details-by-id", error)
            } 
        }catch(error){
            console.log("GET APi  errorresponse|-> empanelment/empanelment-details-by-id", error)
        }       
      })
    }, 

// ==========================FROM HERE MARINE MIXIN STARTS========================== MARINE =========================================

showProgress(message) {
  this.$store.commit("SHOW_PROGRESS", {
      value: true,
      message: message == undefined ? "" : message
  });
},

hideProgress() {
  this.$store.commit("HIDE_PROGRESS", false);
},

getURL(URL_TYPE) {
  var url = URL[URL_TYPE];
  return url;
},

authHeaderM(isCommonModuleApi) {
  let  headerObj= {
              headers:{
              "Content-Type": "application/json",
              // "x-access-token": this.$store.getters.fetchToken
              "Authorization": this.$store.state.Auth && this.$store.state.Auth.user ? this.$store.state.Auth.user.signInUserSession.idToken.jwtToken : ''//this.$store.state.Auth.user.signInUserSession.idToken.jwtToken
              }
       }
  if(isCommonModuleApi === 'Travel'){
      headerObj.headers.system ="Travel";
  }else if(isCommonModuleApi === 'Home'){
  headerObj.headers.system ="Home";
  }else if(isCommonModuleApi){
      headerObj.headers.system ="CM";
   }
  return headerObj;
  // return {
  //     headers: {
  //         "Content-Type": "application/json",
  //         "x-access-token": this.$store.getters.fetchToken
  //     }
  // }
},
POSTM(API, requestData, successfulCallback, errorCallback,isCommonModuleApi) {
  this.url = this.getURL(API);
  axios.post(this.url, requestData, this.authHeaderM(isCommonModuleApi))
      .then((response) => {
          successfulCallback(response);
      })
      .catch((error) => {
          console.log("postAPI", requestData)
          console.log('error of POST mixin is ', error)
          if (error.response && error.response.status && error.response.status == 401) {
            this.showAlert("Session Expired, kindly relogin to continue.")
            this.log_out_function()
          }
          errorCallback(error);
      });
},
PUTM(API, API_ID, requestData, successfulCallback, errorCallback,isCommonModuleApi) {
  this.url = this.getURL(API) + API_ID;
  if (requestData == "" || requestData == null || requestData == undefined) {
    requestData = {}
  }
  console.log('response of PUT mixin request ', API_ID, this.url)
  axios.put(this.url, requestData, this.authHeaderM(isCommonModuleApi))
      .then((response) => {
          successfulCallback(response)
      })
      .catch((error) => {
          console.log('error of PUT mixin is ', error)
          if (error.response && error.response.status && error.response.status == 401) {
            this.showAlert("Session Expired, kindly relogin to continue.")
            this.log_out_function()
        }
          errorCallback(error);
      });
},
GETM(API, requestData, successfulCallback, errorCallback,isCommonModuleApi) {
  this.url = this.getURL(API);
  if (requestData) {
      this.url = this.url + "?" + requestData;
  }
  console.log('this.url', this.url)
  axios.get(this.url, this.authHeaderM(isCommonModuleApi))
      .then((response) => {
          successfulCallback(response);
      })
      .catch((error) => {
          console.log('error of GET mixin is ', error)
          if (error.response && error.response.status && error.response.status == 401) {
            this.showAlert("Session Expired, kindly relogin to continue.")
            this.log_out_function()
        }
          errorCallback(error);
      });
},
GET_ByURLM(API, requestData, successfulCallback, errorCallback,isCommonModuleApi) {
  this.url = this.getURL(API);
  if (requestData) {
      this.url = this.url + "/" + requestData;
  }
  axios.get(this.url, this.authHeaderM(isCommonModuleApi))
      .then((response) => {
          successfulCallback(response);
      })
      .catch((error) => {
          console.log('error of GET mixin is ', error)
          if (error.response && error.response.status && error.response.status == 401) {
            this.showAlert("Session Expired, kindly relogin to continue.")
            this.log_out_function()
        }
          errorCallback(error);
      });
},
POST_ByURLM(API, requestData, successfulCallback, errorCallback, updateId,isCommonModuleApi) {
  this.url = this.getURL(API);
  if (updateId) {
      this.url = this.url + updateId;
  }
  axios.post(this.url, requestData, this.authHeaderM(isCommonModuleApi))
      .then((response) => {
          successfulCallback(response);
      })
      .catch((error) => {
          console.log("postAPI", requestData)
          console.log('error of POST mixin is ', error)
          if (error.response && error.response.status && error.response.status == 401) {
            this.showAlert("Session Expired, kindly relogin to continue.")
            this.log_out_function()
        }
          errorCallback(error);
      });
},
PUT_ByURLM(API, requestData, successfulCallback, errorCallback, updateId,isCommonModuleApi) {
  this.url = this.getURL(API);
  if (updateId) {
      this.url = this.url + updateId;
  }
  console.log('this.url', this.url, updateId, requestData)
  axios.put(this.url, requestData, this.authHeaderM(isCommonModuleApi))
      .then((response) => {
          successfulCallback(response);
      })
      .catch((error) => {
          console.log("PUT_ByURL", requestData)
          console.log('error of Put mixin is ', error)
          if (error.response && error.response.status && error.response.status == 401) {
            this.showAlert("Session Expired, kindly relogin to continue.")
            this.log_out_function()
        }
          errorCallback(error);
      });
},
readImageM(fileName, fileData, callback) {
  let reader = new FileReader();
  reader.readAsDataURL(fileData);
  let temp = {};
  reader.onload = function () {
      temp.base = reader.result;
      temp.fileName = fileName;
      callback(temp);
  };
},

showAlert(message, successbuttonName, failurebuttonName, alertfunction, failurealertfunction, template = '') {
  this.$fire({
      confirmButtonColor: "#23b1a9",
      cancelButtonColor: "#dd3333",
      title: this.$store.getters.fetchHeader,
      text: message,
      template,
      showCancelButton: true,
      showCancelButton: (failurebuttonName == undefined || failurebuttonName == "" || failurebuttonName == null) ? false : true,
      showCloseButton: false,
      confirmButtonText: (successbuttonName == undefined || successbuttonName == "" || successbuttonName == null) ? "OK" : successbuttonName,
      cancelButtonText: (failurebuttonName == undefined || failurebuttonName == "" || failurebuttonName == null) ? "" : failurebuttonName,
      reverseButtons: false,
      allowOutsideClick: false
  }).then((result) => {
      if (result.value == true && alertfunction) {
          alertfunction();
      } else if (failurealertfunction) {
          failurealertfunction();
      }
  });
},

showConfirm(title, message, successbuttonName, failurebuttonName, callfunction, callfailurefunction) {
  this.$fire({
      confirmButtonColor: "#23b1a9",
      cancelButtonColor: "#dd3333",
      title: title,
      text: message,
      showCancelButton: true,
      showCancelButton: true,
      showCloseButton: false,
      confirmButtonText: (successbuttonName == undefined || successbuttonName == "" || successbuttonName == null) ? "" : successbuttonName,
      cancelButtonText: (failurebuttonName == undefined || failurebuttonName == "" || failurebuttonName == null) ? "" : failurebuttonName,
      reverseButtons: true,
      allowOutsideClick: false
  }).then((result) => {
      if (result.value == true) {
          callfunction();
      } else {
          callfailurefunction();
      }
  });
},
// console.log(routeName,"routeName")
//   console.log(this.$store.state.screens_obj,"this.$store.state.screens_obj")
//   let a = {}
//     a = this.$store.state.screens_obj[routeName]
//   console.log("BEF A", a)
//   if(this.$store.state.screens_obj.hasOwnProperty(routeName)){
//     console.log(routeName,"current_routes name inside if",this.$store.state.screens_obj[routeName])
   
//     a.isEdit = true
//     console.log("A is if ", a)
//     return a
    
//   }else{
//     // alert("from else")
//       if(a == undefined || a == null){
//         a = {}
//       }
//       a.isEdit = true
//       console.log("A is else", a)
//       return a
//   }
   
    checksession(response, cbis){
      // alert("check session called")
      console.log("CHECK session statusCode",response)

      if(response.data.statusCode == 401 && response.data.message == 'cust_unauthorized'){
          this.showToast("Session is now Expired", this.TOST().WARNING)
          this.log_out_function()
          
      }else{
          cbis('continue')
      }

    },

  cognito_login(){
    return URL.COGNITOLOGIN;
  },

  check_cache(cbis){
    let cachedata = {data:{}}
    let fetchvalue = this.getCOGNITO()
    console.log('fetchvalue',fetchvalue)

      if(fetchvalue.length){
            // this.process_response(response)
            let set_token = {}
            fetchvalue.forEach(e=>{
                set_token = JSON.parse(window.localStorage.getItem(e))
                  console.log(e,'set_token is',set_token)
                if(e.split('.')[1].slice(e.split('.')[1].length-1) == 'x'){
                  cachedata.data.idToken = set_token
                }else{
                  cachedata.data.accessToken = set_token
                }
            })

             let authdata = {user:null}

          authdata.user = this.process_response(cachedata)
          store.commit('SET_LOGINDATA',authdata);
          cbis(true)
          

      console.log('cachedata final authdata for vuex',authdata);
      
      }else{

          // this.showToast("Unauthorized: An error occurred while fetching data", this.TOST().ERROR)
          cbis(false);    
      }
      // console.log('cachedata last',cachedata);
  },

  parsedata(lgathdata,isIdToken){
      console.log('parseing data',lgathdata);
    // try {
    //     return JSON.parse(atob(lgathdata.split('.')[1]));
    // } catch (e) {
    //     console.log("from parse data catch",e);
    //     return null;
    // }
    try {
      console.log(isIdToken, "decompress => ", isIdToken ? this.decompress(JSON.parse(atob(lgathdata.split('.')[1]))) : JSON.parse(atob(lgathdata.split('.')[1])))
      return  isIdToken ? this.decompress(JSON.parse(atob(lgathdata.split('.')[1]))) : JSON.parse(atob(lgathdata.split('.')[1]))//JSON.parse(atob(lgathdata.split('.')[1]));
    } catch (e) {
      console.log("from parse data catch",e);
      return null;
    }
  },

  process_response(response){
   return { 
      signInUserSession:{
              idToken:{
                  jwtToken:response.data.idToken,
                  payload:this.parsedata(response.data.idToken,"idToken")
              },
              accessToken:{
                  jwtToken:response.data.accessToken,
                  payload:this.parsedata(response.data.accessToken)
              }
      }
  }


  },


  getCOGNITO(){
       let a = []
       Object.keys(localStorage).forEach(e=>{

           if(e.split('.')[0] == 'cognito'){
             a.push(e)
           }

       })
       return a

  },

  gettoken(theid, cbis){
    console.log("store is",store)
    // '61d192fa54a49647477d94f2'
    // 'https://claimsdrive.salesdrive.app/common-system-service/session/get-token?_id=61d192fa54a49647477d94f2'
    // console.log(vuestore.default._mutations,"data is", theid)
    // return
    if(theid){

      console.log(vuestore,"in if data is", theid)
      // let self = this
      
      axios.get(this.cognito_login()+'session/get-token?_id='+theid).
      then((response, err)=>{
        // alert("api hit token? ")
        if(response.data.message == 'Token_Already_Generated' || response.data.message =='Unauthorized'){
          this.log_out_function()
          return
        }
        console.log("response",response)
        if(response){
  
          console.log("getting token after redirection _id received is", response.data)
          let authdata = {user:null}
  
          authdata.user = this.process_response(response)
        
          console.log("authdata is >>",authdata)
          window.localStorage.setItem('cognito.'+'type'+'x', JSON.stringify(authdata.user.signInUserSession.idToken.jwtToken));
          window.localStorage.setItem('cognito.'+'type'+'y', JSON.stringify(authdata.user.signInUserSession.accessToken.jwtToken));
          
          store.commit('SET_LOGINDATA',authdata);
          cbis(true)
  
        }else{
          console.log("err is from gettoken", err)
          cbis(false)
        }
  
      }).catch(e=>{
        console.log(e,"Caught one : id not found, cannot proceed further")
        if (error.response && error.response.status && error.response.status == 401) {
          self.showAlert("Session Expired, kindly relogin to continue.")
          self.log_out_function()
      }
        cbis(false)
        
      })
    }else{
      
      // alert("Id not found")
      console.log("id not found, cannot proceed further")
      cbis(false)
    }



  },

    log_out_function(){
          this.showProgress()
          let self = this
   
        axios.post(this.cognito_login()+'session/logout',{
                "jti": this.$store.state.Auth.user.signInUserSession.idToken.payload.jti
              }).then((response, erroris)=>{
                this.hideProgress()
                console.log("logout res new",response.data)
                if(response.data.message == 'Session_Logout' || response.data.message == 'Session_Doesnt_Exist'){
                    this.hideProgress()
                //   this.POST(100,'session/logout',{
                //     "jti": this.$store.state.Auth.user.signInUserSession.idToken.payload.jti
                //   }, (res, err)=>{
                    store.commit('SET_SCREEN_REBOOT', false)
                    store.commit('SET_LOGINDATA',null);
      
                //   alert("clear all called")
                  let fetchvalue = self.getCOGNITO()
                //   console.log('fetchvalue clearall',fetchvalue)
                  
                  if(fetchvalue){
                          // this.process_response(response)
                      
                          fetchvalue.forEach(e=>{
                              localStorage.removeItem(e)
                          })
      
                  }
                    // console.log('logout successfull', res)
                    self.$router.push('/login')
                }else{
                    this.hideProgress()
                        console.log("error while logging out", erroris);
                }
                
        }).catch (error=>{
            this.hideProgress()
            store.commit('SET_SCREEN_REBOOT', false)
            store.commit('SET_LOGINDATA',null);

        //   alert("clear all called")
          let fetchvalue = self.getCOGNITO()
        //   console.log('fetchvalue clearall',fetchvalue)
          
          if(fetchvalue){
                  // this.process_response(response)
              
                  fetchvalue.forEach(e=>{
                      localStorage.removeItem(e)
                  })

          }
            // console.log('logout successfull', res)
            self.$router.push('/login')
          console.log("axios Catched error while logging out", error);
        })
    },

getEditDetailsNEW(routeName){
  console.log(routeName,"routeName")
  console.log(this.$store.state.screens_obj,"this.$store.state.screens_obj")
  let a = {}
    a = this.$store.state.screens_obj[routeName]
  console.log("BEF A", a)
  if(this.$store.state.screens_obj.hasOwnProperty(routeName)){
    console.log(routeName,"current_routes name inside if",this.$store.state.screens_obj[routeName])
   
    a.isEdit = true
    console.log("A is if ", a)
    return a
    
  }else{
    // alert("from else")
      if(a == undefined || a == null){
        a = {}
      }
      a.isEdit = true
      console.log("A is else", a)
      return a
  }
},
    computedatenew(intype, valueIs){

      if(intype=='txt'){
          let tempDate = moment(valueIs,'DD-MM-YYYY').format()
          return moment(tempDate).format('YYYY-MM-DD')
      }else if(intype=='def'){
          let dateToHandel = moment(new Date()).format('DD-MM-YYYY')
          let initDate = moment(dateToHandel,'DD-MM-YYYY').format()
          return moment(initDate).format('YYYY-MM-DD')
      }
    },
    formatExcel(json) {
      let widthArr = Object.keys(json).map(() => {
          return { width: 22, hpt: 25 }
      })
      return widthArr;
  },
  JSONToEXcel(data, fileName, sheetName = 'jsonWorkSheet') {
      let excelFileName = fileName + ".xlsx";
      const jsonWorkSheet = xlsx.utils.json_to_sheet(data);
      const finalData = this.formatExcel(jsonWorkSheet);
      jsonWorkSheet["!cols"] = finalData;
      jsonWorkSheet["!rows"] = finalData;
      const workBook = {
          SheetNames: [sheetName],
          Sheets: {
            [sheetName]: jsonWorkSheet,
          },
      };
      xlsx.writeFile(workBook, excelFileName);
  },
  excelToJSON(fileData, callback, errorCallback) {
    const files = fileData;
    this.readJsoneData = [];
    const fileReader = new FileReader(); // construction function that can read the file content
    fileReader.onload = (ev) => {
      try {
        const data = ev.target.result;
        const workbook = xlsx.read(data, {
            type: "binary", // binary
        });
        const wsname = workbook.SheetNames[0]; //take the first sheet
        const ws = xlsx.utils.sheet_to_json(workbook.Sheets[wsname], { defval: '' }); //Get the data in this table
        ws.forEach((value, index, ws) => {
            this.readJsoneData.push(value);
        });
        callback(this.readJsoneData);
      } catch (error) {
        if (errorCallback && typeof errorCallback === 'function') {
          errorCallback(error);
        } else {
          console.log(error);
        }
      }
    }
    fileReader.readAsBinaryString(files); // read file, trigger onload
  },

  JSONTOPDF(testcolumns, newArray, fileName) {
      console.log(newArray);
      let doc = new jsPDF('p', 'pt');
      doc.autoTable(testcolumns, newArray);
      doc.save(fileName);
  },
  JSToPdfLandscape(testcolumns, newArray, fileName) {
      // Landscape export, 2×4 inches
      const doc = new jsPDF({
          orientation: "landscape",
          // unit: "in",
          // format: [2, 2]
      });

      doc.autoTable(testcolumns, newArray);
      doc.save(fileName);
  },
  decompress(token) {
    console.log(" token ===>",token)
      try {
          let newToken = {
              frontendPermission: token.frontendPermission,
              ntidUserId: token.ntidUserId,
              employeeName: token.employeeName,
              lastLogin: token.lastLogin,
              currentLogin: token.currentLogin,
              jti: token.jti,
              lob: token.lob
          }
          token.lob.split(',').map((o, i) => {
              newToken[o] = {
                  _id: token._id.split(',')[i],
                  roleDescription: this.getRoleArray(token.RD.split('|')[i]),//token.RD.split('|')[i],
                  faLevel: token.faLevel.split(',')[i],
                  reservingAuthorityLimit: token.authority.split('|')[i].split(',')[0],
                  paymentAuthorityLimit: token.authority.split('|')[i].split(',')[1],
                  isCorporateUser: token.CO[i] == '1',
                  isNominatedUser: token.NU[i] == '1',
                  jti: token.jti
              }
          });
          return newToken;
      } catch (error) {
          console.log(error);
          throw error;
      }
  },

  exceptObject(obj, except = []) {
    let updatedObject = {};
    if (obj) {
      for (const key in obj) {
        if (Object.hasOwnProperty.call(obj, key)) {
          if (!except.includes(key)) {
            updatedObject[key] = obj[key];
          }
        }
      }
    }
    return updatedObject;
  },
  getRoleArray(roleStr){
    if(!roleStr){
      return
    }
    let roleArray = []
    let roleAndNameArray  = roleStr.includes(",") ? roleStr.split(",") : [roleStr]
    roleAndNameArray.map((roleData)=>{
      roleArray.push(roleData.split("..")[0])
      return ""
    })
    return roleArray
  },  
  getUniqueDataForRoles(arr) {
    let uniqueArr = [];
    for (let i of arr) {
      if (uniqueArr.indexOf(i) === -1) {
        uniqueArr.push(i);
      }
    }
    return uniqueArr;
},

viewDocument(itemData) {
    let requestData = "";
    if (itemData !== null) {
        requestData = "documentId=" + itemData + "&b64=true";
    }
    this.GETM("GET_DOCUMENT_BY_ID", requestData, (res) => {
        if (res.data.statusCode === 200) {
            this.openBase64File(res.data.data);
        } else {
            this.showAlert(res.data.message);
        }
    }, (error) => {
        console.log("Error", error);
        this.showAlert(error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message);
    }, true);
},
openBase64File(base64) {
  if (base64) {
    const base64ImageData = base64;
    const contentType = base64.split(":")[1].split(";")[0];
    const byteCharacters = window.atob(base64ImageData.substr(`data:${contentType};base64,`.length));
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
      const slice = byteCharacters.slice(offset, offset + 1024);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, { type: contentType });
    const blobUrl = window.URL.createObjectURL(blob);
    window.open(blobUrl, "_blank");
  } else {
    this.showAlert("Somthing went wrong!");
  }
},

getLOB() {
  return new Promise((resolve, reject) => {
    let reqData = '';
    this.showProgress();
    this.GETM("getLOB", reqData, (res) => {
        this.hideProgress();
        if(res.data.statusCode === 200) {
          resolve(res.data.data[0].lineOfBusiness);
        } else {
          this.showAlert(res.data.msg);
          resolve([]);
        }
    }, (error) => {
        console.log(error);
        this.hideProgress();
        reject(error)
    }, true)
  })
},
popupOCR(value){
  if(value){
    this.showAlert('OCR has been over written')
  }
},

checkPermission(path) {
  let permission = false
  let currentPage  = routeDetails.options.routes.find((elem)=>{
    return elem.path == (path || this.$route.path)
  })
  // let currentPage  = this.$store.state.current_page
    let permObj = this.$store.state.screens_obj[currentPage.name]
    if(!permObj?.isEdit){
      permission = true
    }
    return permission
},
    validateBaseTDSBulkExcelData(data, excelHeaders) {
      const [fileHeaders = {}] = data;

      const validationMessages = [];

      /**
       * Validating Headers here.
       */
      for (let column of excelHeaders) {
        /**
         * If column not found on Excel
         * Add a validation message of column name is missing
         */
        if (!fileHeaders.hasOwnProperty(column)) {
          validationMessages.push(`${column} column is missing.`);
        }
      }

      /**
       * Validating Excel rows data
       */
      for (let row of data) {
        const typeOfVendor = this.removeWhiteSpaces(row[excelHeaders[0]]);
        const panCode = this.removeWhiteSpaces(row[excelHeaders[1]]);
        const tdsRateWithPan = row[excelHeaders[2]];
        const tdsRateWithoutPan = row[excelHeaders[3]];

        const typeOfVendorsEnum = [
          "Hospital",
          "Service Provider",
          "Vendor",
          "Repairer",
        ];
        const panCodeEnum = [
          "A",
          "B",
          "C",
          "F",
          "G",
          "H",
          "J",
          "L",
          "P",
          "T",
          "PAN NOT VALID",
        ];
        const checkerDecisionEnum = ["APPROVE", "REJECT"];

        function validateTwoDecimalOnly (val) {
          const valInString = String(val);

          const valArray = valInString.split('.');
          if (valArray?.[1]) {
            if (valArray[1].length < 3) {
              return true;
            }
          } else {
            return true;
          }
        }

        if (!typeOfVendorsEnum.includes(typeOfVendor)) {
          validationMessages.push(`Please enter correct Vendor. ${typeOfVendor}`);
        }
        if (!panCodeEnum.includes(panCode.toUpperCase())) {
          validationMessages.push("Please enter correct PAN Code.");
        }
        /**
         * If Invalid pan is selected,
         * then validate tds without pan column
         * otherwise field would be blank.
         */
        if (["PAN NOT VALID", ""].includes(panCode.toUpperCase())) {
          if (
              isNaN(Number(tdsRateWithoutPan))||
              !(tdsRateWithoutPan >= 0 && tdsRateWithoutPan <= 100) ||
              (String(tdsRateWithoutPan).length > 5) ||
              !validateTwoDecimalOnly(tdsRateWithoutPan)
            ) {
            validationMessages.push(`Please enter correct TDS Rate without PAN. ${tdsRateWithoutPan}`);
          }
          if (tdsRateWithPan !== '') {
            validationMessages.push("Please enter correct TDS Rate with PAN.");
          }
        } else {
          if (
              isNaN(Number(tdsRateWithPan)) ||
              !(tdsRateWithPan >= 0 && tdsRateWithPan <= 100) ||
              (String(tdsRateWithPan).length > 5) ||
              !validateTwoDecimalOnly(tdsRateWithPan)
            ) {
            validationMessages.push(`Please enter correct TDS Rate with PAN. ${tdsRateWithPan}`);
          }
          if (tdsRateWithoutPan !== '') {
            validationMessages.push("Please enter correct TDS Rate without PAN.");
          }
        }

        /**
         * For Checking Checker side data validations
         * Which are Checker Decision & Remarks
         */
        if (excelHeaders[5] === "Checker decision") {
          const checkerDecision = this.removeWhiteSpaces(row[excelHeaders[5]]);
          const rejectionRemarks = this.removeWhiteSpaces(row[excelHeaders[4]]);

          if (!checkerDecisionEnum.includes(checkerDecision.toUpperCase())) {
              validationMessages.push( "Please enter either Approve or Reject on Checker Decision Column.");
          }
          if (checkerDecision === "Reject" && !rejectionRemarks) {
            validationMessages.push("Please enter Rejection Remarks for Rejection.");
          }
        }
      }

      /**
       * If every case of validation is passed
       * return validate = [];
       */
      return validationMessages;
    },
    validateConcessionalTDSBulkExcelData(data, excelHeaders) {
        const [fileHeaders = {}] = data;
        const validationMessages = [];

        /**
         * Validating Headers here.
         */
        for (let column of excelHeaders) {
            /**
             * If column not found on Excel
             * Add a validation message of column name is missing
             */
            if (!fileHeaders.hasOwnProperty(column)) {
              validationMessages.push(`${column} column is missing.`);
            }
        }

        /**
         * Validating Excel rows data
         */
        for (let row of data) {

            const typeOfVendor = this.removeWhiteSpaces(row[excelHeaders[0]]);
            const vendorName = this.removeWhiteSpaces(row[excelHeaders[1]]);
            const panNumber = this.removeWhiteSpaces(row[excelHeaders[2]]);
            const penalTdsApplicable = this.removeWhiteSpaces(row[excelHeaders[3]]);
            const penalTdsCode = this.removeWhiteSpaces(row[excelHeaders[4]]);
            // const penalTdsRate = row[excelHeaders[5]];
            const concessionalTdsApplicable = this.removeWhiteSpaces(row[excelHeaders[5]]);
            const concessionalTdsRate = row[excelHeaders[6]];
            const certificateReferenceNumber = row[excelHeaders[7]];
            const certificateIssueDate = row[excelHeaders[8]];
            const concessionalTdsEffectiveDate = row[excelHeaders[9]];
            const concessionalTdsEndEffectiveDate = row[excelHeaders[10]];
            const certificateReceivedDate = row[excelHeaders[11]];
            const thresholdLimit = row[excelHeaders[12]];
            const financialYear = row[excelHeaders[13]];
            // No validation on Remarks.
            // const remarks = row[excelHeaders[15]];
            const flag = this.removeWhiteSpaces(row[excelHeaders[15]]);


            /**
             * These are the Enum values which are
             * some fields constraint
             */
            const typeOfVendorsEnum = ['Hospital', 'Service Provider', 'Vendor', 'Repairer'];
            const penalTdsCodeEnum = ['INF', 'ANL'];
            const penalTdsApplicableEnum = ['yes', 'no'];
            // This contains 1970 to 2050 year (Number).
            function validateTwoDecimalOnly (val) {
              const valInString = String(val);

              const valArray = valInString.split('.');
              if (valArray?.[1]) {
                if (valArray[1].length < 3) {
                  return true;
                }
              } else {
                return true;
              }
            }

            function calculateCurrentFinancialYear () {
              const date = new Date();

              const month = date.getMonth() + 1; // Note: Months are 0-indexed in JavaScript
              const year = date.getFullYear();

              if (month > 3) {
                return year + 1;
              } else {
                return year;
              }
            }
            function validatePanNumberByPanCode (val = '') {
              return ['A', 'B', 'C', 'F', 'G', 'H', 'J', 'L', 'P', 'T'].includes(val[3])
            }
            const financialYearEnum = [calculateCurrentFinancialYear()];

            const flagEnum = ['A', 'M'];
            const checkerDecisionEnum = ["APPROVE", "REJECT"];

            if (!typeOfVendorsEnum.includes(typeOfVendor)) {
                validationMessages.push(`Please enter correct Vendor. ${typeOfVendor}`);
            }
            if (!vendorName) {
                validationMessages.push(`Please enter correct Vendor Name.`);
            }
            if (this.validatePanNumber(panNumber) !== true || !validatePanNumberByPanCode(panNumber)) {
                validationMessages.push(`Please enter correct PAN Number. ${panNumber}`);
            }
            if (!penalTdsApplicableEnum.includes(penalTdsApplicable?.toLowerCase())) {
                validationMessages.push(`Please enter Yes or No in Penal TDS Applicable.`);
            }
            if (penalTdsApplicable?.toLowerCase() === 'yes') {
                if (!penalTdsCodeEnum.includes(penalTdsCode)) {
                    validationMessages.push("Please enter correct Penal TDS Code.");
                }
                // if (
                //     isNaN(Number(penalTdsRate)) ||
                //     !(penalTdsRate >= 0 && penalTdsRate <= 100) ||
                //     (String(penalTdsRate).length > 5) ||
                //     !validateTwoDecimalOnly(penalTdsRate)
                //   ) {
                //     validationMessages.push("Please enter correct Penal TDS Rate.");
                // }
                if (!penalTdsApplicableEnum.includes(concessionalTdsApplicable?.toLowerCase())) {
                    validationMessages.push("Please enter Yes or No in Concessional TDS Applicable.");
                }
            }
            if (concessionalTdsApplicable?.toLowerCase() === 'yes') {
                if (
                    isNaN(Number(concessionalTdsRate)) ||
                    !(concessionalTdsRate >= 0 && concessionalTdsRate <= 100) ||
                    (String(concessionalTdsRate).length > 5) ||
                    !validateTwoDecimalOnly(concessionalTdsRate)
                  ) {
                    validationMessages.push("Please enter correct Concessional TDS Rate.");
                }
                if (!certificateReferenceNumber || !new RegExp(/^[a-zA-Z0-9 ]*$/).test(certificateReferenceNumber)) {
                    validationMessages.push(`Please enter correct Certificate Reference Number. ${certificateReferenceNumber}`);
                }
                const momentCertificateIssueDate = (typeof certificateIssueDate === 'number')
                  ? moment(Date.UTC(0, 0, certificateIssueDate - 1))
                  : moment(certificateIssueDate, ['DD/MM/YYYY', 'DD-MM-YYYY', 'DD/MM/YY', 'DD-MM-YY'], true);

                if (
                  !certificateIssueDate ||
                  !momentCertificateIssueDate.isValid() ||
                  momentCertificateIssueDate.isAfter(moment())
                ) {
                    validationMessages.push("Please enter correct Certificate Issue Date in DD/MM/YYYY format.");
                }
                // if (!moment(concessionalTdsEffectiveDate).isValid()) {
                //     validationMessages.push("Please enter correct Concessional TDS Effective Date.");
                // }
                const momentConcessionalTdsEndEffectiveDate = (typeof concessionalTdsEndEffectiveDate === 'number')
                  ? moment(Date.UTC(0, 0, concessionalTdsEndEffectiveDate - 1))
                  : moment(concessionalTdsEndEffectiveDate, ['DD/MM/YYYY', 'DD-MM-YYYY', 'DD/MM/YY', 'DD-MM-YY'], true);

                const momentCertificateReceivedDate = (typeof certificateReceivedDate === 'number')
                  ? moment(Date.UTC(0, 0, certificateReceivedDate - 1))
                  : moment(certificateReceivedDate, ['DD/MM/YYYY', 'DD-MM-YYYY', 'DD/MM/YY', 'DD-MM-YY'], true);

                if (
                  !concessionalTdsEndEffectiveDate ||
                  !momentConcessionalTdsEndEffectiveDate.isValid() ||
                  momentConcessionalTdsEndEffectiveDate.isBefore(momentCertificateReceivedDate)
                ) {
                    validationMessages.push("Please enter correct Concessional TDS End Effective Date in DD/MM/YYYY format.");
                }
                if (
                  !certificateReceivedDate ||
                  momentCertificateReceivedDate.isAfter(moment())
                ) {
                    validationMessages.push("Please enter correct Certificate Received Date in DD/MM/YYYY format.");
                }
                // if (!financialYearEnum.includes(Number(financialYear))) {
                //     validationMessages.push("Please enter correct full Financial Year.");
                // }
                if (thresholdLimit === '' || isNaN(Number(thresholdLimit)) || !(thresholdLimit >= 0 && thresholdLimit <= 9999999999)) {
                    validationMessages.push(`Please enter correct Threshold Limit." ${thresholdLimit}`);
                }
            }
            if (penalTdsApplicable?.toLowerCase() === 'yes' && concessionalTdsApplicable?.toLowerCase() === 'yes') {
                validationMessages.push("Please Enter Either Penal or Concessional TDS Applicable.");
            }
            if (!flagEnum.includes(flag.toUpperCase())) {
                validationMessages.push("Please enter correct Flag A for addition & M for modification.");
            }

            /**
             * For Checking Checker side data validations
             * Which are Checker Decision & Remarks
             */
            if (excelHeaders[18] === "Checker decision") {
              const checkerDecision = this.removeWhiteSpaces(row[excelHeaders[18]]);
              const rejectionRemarks = this.removeWhiteSpaces(row[excelHeaders[19]]);

              if (!checkerDecisionEnum.includes(checkerDecision.toUpperCase())) {
                  validationMessages.push( "Please enter either Approve or Reject on Checker Decision Column.");
              }
              if (checkerDecision === "Reject" && !rejectionRemarks) {
                  validationMessages.push("Please enter Rejection Remarks for Rejection.");
              }
            }
        }

        /**
         * If every case of validation is passed
         * return validate = [];
         */
        return validationMessages;
    },
    validateExcelHeadersSequence (excel) {
      /**
       * Making async function because
       * reading excel is not synchronous, so returning Promise
       */
      return new Promise ((resolve, reject) => {
        const reader = new FileReader();

        /**
         * When file is readed this callback method is called with binary data.
         */
        reader.onload = function(event) {
          try {
            /**
             * Reading binary data with xlxs library.
            */
           const workBook = xlsx.read( event.target.result, { type: 'binary' });
            /**
              * Extracting first sheet
            */
            const firstSheetName = workBook.SheetNames[0];
            const firstSheet = workBook.Sheets[firstSheetName];
            /**
             * Reading data in first sheet and reading their headers only.
             */
            const headers = xlsx.utils.sheet_to_json(firstSheet, { defval: '', header: 1 })[0];

            /**
             * Returing Headers only here.
             */
            resolve(headers);
          } catch (error) {
            reject(error);
          }
        }

        reader.readAsBinaryString(excel);
      });
    },
    async downloadFileByURL(url, fileName, headers = {}) {
      /**
       * If file name not passed, or url throw error.
       */
      if (!fileName) {
        throw new Error("Please provide file name.")
      }
      if (!url) {
        throw new Error("Please provide url.")
      }

      /**
       * Method Definition
       */
      try {
        // Calling API here
        const response = await axios.get(url, { headers, responseType: 'arraybuffer' });

        /**
         * Making a blob from response of API
         */
        const blob = new Blob([response.data], { type: 'application/octet-stream' });

        /**
         * Downloading file here via making a local anchor tag
         * and clicking on it. (via JS)
         */
        const localObjectURL = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = localObjectURL;

        // Setting file name here
        link.download = fileName;

        link.click();
      } catch (error) {
        /**
         * If any errro
         * throw Error to consumer.
         */
        console.log(error);
        throw new Error(error);
      }
    },
    getUserName () {
      return this.user_details()?.employeeName || '';
    },
    removeWhiteSpaces(str = '') {
      if (typeof str === 'string') {
        return str.trim();
      }
      return str;
    }
}
}
