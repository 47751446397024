<template>
<v-app>

        <div style="background-color: #FFFFFF;" column class="py-3">
          
            <v-form ref="form" >
                <v-layout class=" mt-2" >
                
                    <v-flex xs3>
                        <v-layout column class="cust_pdPerSearch mx-2 my-2 ">
                            <v-text class="font-weight-bold cstm-clr-pd">
                                Permission ID
                            </v-text>
                            <v-text-field
                                solo
                                dense
                                label="Enter..."
                                v-model="PermissionID"
                                :rules="[alphaNumRules(PermissionID)]"
                                class="rounded-0"
                                maxlength=50
                                v-on:keyup.enter="onSearch"
                                >
                            </v-text-field>
                        </v-layout>
                    </v-flex>

                    <v-flex xs3>
                        <v-layout column class=" mx-2 my-2 cust_pdPerSearch">
                            <v-text class="font-weight-bold cstm-clr-pd">
                                Permission Name
                            </v-text>
                            <v-text-field
                                solo
                                dense
                                label="Enter..."
                                v-model="PermissionName"
                                :rules="[alphaRules(PermissionName)]"
                                class="rounded-0"
                                maxlength=50
                                v-on:keyup.enter="onSearch"
                                >
                            </v-text-field>
                        </v-layout>
                    </v-flex>

                    <v-flex xs3>
                        <v-layout column class=" mx-2 my-2 cust_pdPerSearch">
                            <v-text class="font-weight-bold cstm-clr-pd">
                                LOB
                            </v-text>
                            <v-select
                                :items="itemsOfLOB"
                                label="Select"
                                v-model="lob"
                                solo
                                dense
                                class="rounded-0"
                               
                                >
                            </v-select>
                        </v-layout>
                    </v-flex>
                
                    <v-flex xs3> </v-flex>
                    <v-flex xs3> </v-flex>

                                                
                </v-layout>

                <v-divider class="mt-2 mb-1"></v-divider>
                    
                <div class="mx-2 pa-5" style=" display:flex;justify-content:space-between;"  >
                    <v-layout style="flex:1" justify-end > 
                        <v-btn dark  tile color="#E46A25" class="text-capitalize px-3 mx-2" small @click="onSearch"><v-icon dark small>search</v-icon> <v-divider vertical class="mx-2"></v-divider>Search</v-btn>
                        <v-btn dark tile color="#E46A25" class="text-capitalize px-3 mx-2" small @click="$router.go(-1)"><v-icon dark small>cancel</v-icon> <v-divider vertical class="mx-2"></v-divider>Cancel</v-btn>
                        <v-btn dark tile color="black" class="text-capitalize px-3 mx-2" small @click="clearFields"><v-icon dark small>cancel</v-icon> <v-divider vertical class="mx-2"></v-divider>Clear</v-btn>
                        <v-btn dark tile color="black" class="text-capitalize px-3 ml-2" small to = "/permissioncreation" ><v-icon dark small>add_circle</v-icon> <v-divider vertical class="mx-2"></v-divider> Create New Permission</v-btn>
                    </v-layout>
                </div>
            </v-form>
        </div>

        <v-card class="rounded-0">
            <v-layout class="headercolor">
                <p class="cardfont" style="font-weight:700;margin-bottom:7px!important;margin-top:10px;padding-left:10px;">Search Results</p>
            </v-layout>
        <v-data-table
                    :headers="headers"
                    :items="GetData"
                    hide-default-footer
                    dense
                    :page.sync="page"
                    :items-per-page="itemsPerPage"
                    @page-count="pageCount = $event"
                   
                >

                <template v-slot:item.PermissionId="{ item }">
                    <div>
                        
                      <a @click="routeOnClick(item)">{{ item.PermissionId }}</a> 
                        
                    </div>
                </template>
                     
        </v-data-table>
         <v-divider></v-divider>
                    <v-layout row justify-end align-center class="mx-2" >
                        <v-text class="mr-1 font-weight-bold body-1" style="color:#3C3D3D; font-size:12px !important">
                                <span class="" style="color:#E46A25;font-size:12px;">Showing {{fval}}-{{sval}} </span>out of {{totalLen }} records

                        </v-text>
                        <v-divider  class="mx-2" vertical ></v-divider>
                        <v-pagination 
                            class="cust-bnkAcc-Permission"
                            v-model="page"
                            color="#152F38"
                            flat
                            :total-visible="9"
                            @input="nextPageBtn(page)"
                            :length="pageLength"
                            ></v-pagination>
                    </v-layout>
        </v-card>
    </v-app>
</template>

<script>
  
export default {
     created(){
        this.$store.commit("SAVE_PAGE_HEADER", " Permission Search");

        this.GET(3,'line-of-business/get-line-of-business-list', (res,error)=>{
            if(!error){
                console.log("response from api is",res.data.data[0].lineOfBusiness);
                this.itemsOfLOB=res.data.data[0].lineOfBusiness
            }else{
                console.log("error",error.response.data);
            }
        });
        
        this.searchTable(this.page);
        // console.log("this.getEditDetails(this.$router.history.current.name) from Mixin===>",this.getEditDetails(this.$router.history.current.name).isEdit)
        //  if(this.getEditDetails(this.$router.history.current.name)){
        //     this.disableAll=this.getEditDetails(this.$router.history.current.name).isEdit
            
        // }else{
        //     this.disableAll=false
        // }

    },

    data() {
        return {
            PermissionID:'',
            PermissionName:'',
            lob:'',
            itemsOfLOB:[],
            GetData:[],
            headers:[
                {text: 'Sr. No.', value: 'SerialNumber',sortable: false,divider:true,class:"hdr-txt-clrPer hdr-bg-clrPer srWidth"},
                {text: 'Permission ID', value: 'PermissionId',sortable: false,divider:true,class:"hdr-txt-clrPer hdr-bg-clrPer"},
                {text: 'Permission Name', value: 'PermissionName',sortable: false,divider:true,class:"hdr-txt-clrPer hdr-bg-clrPer"},
                {text: 'LOB', value: 'lob',sortable: false,divider:true,class:"hdr-txt-clrPer hdr-bg-clrPer"},
            ],
            fval:0,
            sval:0,
            totalLen:'',
            itemsPerPage:10,            
            page: 1,
            pageLength:'',
            lastPage:false,
            disableAll:true
        };


    },

    methods:{
        alphaNumRules(v){
            if(v.length>0){
               return v => /^[a-zA-Z0-9 ]+$/.test(v) || 'Only alphanumeric values are allowed'
            }
        },

        alphaRules(v){
             if(v.length>0){
               return v => /^[a-zA-Z0-9 ]+$/.test(v) || 'Only alphabets are allowed'
            }
        },

        routeOnClick(item){
            console.log("this is the item from table",item);
            this.$router.push({'name':'PermissionCreation', params:{item}})
        },

        nextPageBtn(page){
            if(this.pageLength === page){
                this.lastPage = true
                console.log("this is last page",page);
            }else{
                this.lastPage = false
                console.log("this is not last page",page);
            }
            this.searchTable(page)
        },

        clearFields(){
            this.PermissionID = '';
            this.PermissionName = '';
            this.lob = '';
            this.searchTable(this.page);
        },

        onSearch(){
            console.log("search btn is working",this.page);
             if(!this.$refs.form.validate()){
               this.showToast("Please fill details correctly",this.TOST().WARNING)
               
                console.log("%c It is a not a valid Form","color:red");

            }else{
                if(this.PermissionID === '' && this.PermissionName === '' && this.lob === ''){
                    this.showToast("Please enter search criteria.",this.TOST().WARNING)
                console.log("%c It is a valid Form","color:green");
               }
                this.searchTable(this.page)
            }       
        },

        searchTable(page){
            this.GET(6,'permission/search-permission?'+'perPage=10&pageNo='+page+'&permissionName='+this.PermissionName+'&permissionId='+this.PermissionID+'&lineOfBusiness='+this.lob,
            // +'&lineOfBusiness='+this.lob+'&permissionName='+this.PermissionName+'&permissionId='+this.PermissionID,
                            (res,error) => {
                                if(!error){
                                    console.log("response from permission search is",res.data.data.data);
                                    this.pageLength = res.data.data.total_pages;
                                    this.totalLen = res.data.data.total
                                    var temp =  this.page*10;
                                    this.fval = temp-9;
                                   
                                    if(this.lastPage){
                                        this.sval = this.totalLen
                                        console.log("value of sval is fo",this.sval);
                                    }else{
                                        if(this.totalLen < 10){
                                            this.sval = this.totalLen
                                            console.log("this are the pageLength for searchTable",this.totalLen);
                                        }else{

                                            this.sval = temp;
                                           console.log("value of sval is",this.sval);
                                        }

                                    }
                                    console.log("this is response for search===>",res.data.data.data);
                                    let tempArr = res.data.data.data;
                                    // this.GetData = [];
                                        this.GetData = tempArr.map((item,index) => {
                                                                         
                                                                            return{
                                                                                    SerialNumber: 10*(page)-9+index,
                                                                                    PermissionId:item.permissionId,
                                                                                    PermissionName:item.permissionName,
                                                                                    lob:item.lineOfBusiness,
                                                                                    allData:item
                                                                                }
                                                                            });
                                    // this.showToast(res.data.data.msg,this.TOST().SUCCESS)
                                    
                                }else{
                                    this.showToast(error.response.data.msg,this.TOST().ERROR)
                                    console.log("their was error in search==>",error.response.data);
                                }
                            })
            }
        }

    
}
</script>

<style>

.cust_pdPerSearch .v-input .v-label {
    font-size: 13px !important;
}
.cust_pdPerSearch .v-input {
    font-size: 13px !important;
}
.cust_pdPerSearch .v-input input {
    font-size: 13px !important;
}


.cust_pr_tds .v-input .v-label {
    font-size: 13px !important;
    
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
    font-size: 0.84rem !important;
}

.hdr-txt-clrPer{
    color: white !important;
    font-weight: bold;
    /* padding:0px!important;
    padding: 10px !important;
    padding-left: 14px !important; */
}

.hdr-bg-clrPer{
  background-color: #23B1A9;
}
.headercolor{
    background-color: #eee;
}
.cardfont{
    font-size: 0.84rem !important;
    padding-right: 2px;
}
.srWidth{
    width: 70px;
}


 .cust-bnkAcc-Permission .v-pagination__navigation{
        box-shadow:unset !important;
        border-radius: unset !important;
        background-color:#FFFFFF !important;
        border:1px solid #DAE1E6 !important
    }
    .cust-bnkAcc-Permission .v-pagination__navigation--disabled{
        background-color:#DAE1E6 !important;
    }
    .cust-bnkAcc-Permission .v-pagination__item{
        box-shadow:unset !important;
        min-width:2rem !important;
        height:2rem !important;
        border-radius: unset !important;
        border:1px solid #DAE1E6 !important
    }  

</style>