<template>
    <v-app>
        <v-form v-model="valid" ref="searchform" :disabled="!disableAll">
        <v-card tile>
            <v-layout class=" mx-2" >
                <v-flex xs3 class=" mx-2 my-2 mt-2 cust_pd">
                        <v-text class="font-weight-bold cstm-clr-pd">
                            Level
                        </v-text>
                        <!-- levelItems -->
                        <v-autocomplete
                            v-model="Level"
                            :items="staticlevelItems"
                            :loading="isLoading"
                            :search-input.sync="searchApi"
                            color="white"
                            cache-items
                            hide-selected
                            item-value="API"
                            label="Select"
                            class="rounded-0"
                            return-object
                            solo
                            v-on:keyup.enter="onSearch"
                            >
                            </v-autocomplete>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class=" mx-2 my-2 cust_pd">
                        <v-text class="font-weight-bold cstm-clr-pd">
                            LOB
                        </v-text>
                        <v-select
                            :items="itemsOfLOB"
                            label="Select"
                            v-model="lob"
                            solo
                            dense
                            class="rounded-0"
                            >
                        </v-select>
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class=" mx-2 my-2 cust_pd">
                        <v-text class="font-weight-bold cstm-clr-pd">
                            Status
                        </v-text>
                        <v-select
                            :items="statusFieldItems"
                            label="Select"
                            v-model="statusField"
                            solo
                            dense
                            class="rounded-0"
                            >
                        </v-select>
                    </v-layout>
                </v-flex>
            
                <v-btn :dark='disableAll' :disabled="!disableAll" tile color="#E46A25" class="text-capitalize px-3 mx-2 my-11" small @click="onSearch"><v-icon dark small>search</v-icon> <v-divider vertical class="mx-2"></v-divider>Search</v-btn>
                <v-btn :dark='disableAll'  :disabled="!disableAll" tile color="#E46A25" class="text-capitalize px-3 mx-2 my-11" small @click="clearresults"><v-icon dark small>clear</v-icon> <v-divider vertical class="mx-2"></v-divider>Clear</v-btn>
                <v-btn :dark='disableAll'  :disabled="!disableAll" tile color="#E46A25" class="text-capitalize px-3 mx-2 my-11" small @click="$router.go(-1)"><v-icon dark small>cancel</v-icon> <v-divider vertical class="mx-2"></v-divider>Cancel</v-btn>
                <v-btn :dark='disableAll' :disabled="!disableAll" tile color="black" class="text-capitalize px-3 ml-2 my-11" small @click="createNewbtn()"><v-icon dark small>add_circle</v-icon> <v-divider vertical class="mx-2"></v-divider> Create New Level</v-btn>
            </v-layout>
        </v-card>
        </v-form>
        <!-- <v-divider></v-divider> -->

        
        <v-card class="rounded-0">
            <v-layout class="headercolor">
                <p class="cardfont" style="font-weight:700;margin-bottom:7px!important;margin-top:10px;padding-left:10px;">Search Results</p>
            </v-layout>
        <v-card tile>
            <v-data-table
                :headers="headers"
                    :items="GetData"
                    hide-default-footer
                    dense
                    :page.sync="page"
                    :items-per-page="itemsPerPage"
                    @page-count="pageCount = $event">

                <template v-slot:[`item.level`]="{ item }">
                    <div v-if="item.status!='Initiated for approval'&& item.status!='Approval Awaited'">
                        
                      <a @click="routeOnClick(item)" class="text-decoration-underline">{{ item.level }}</a> 
                        
                    </div>
                    <div v-else>
                        
                      {{ item.level }}
                        
                    </div>
                </template>
                
                <template v-slot:[`item.ReservingAuthority`]={item}>
                    <tr>
                        <td class="spcit" >{{ item.ReservingAuthority |moneyformat}} </td>      
                    </tr>
                </template>

                <template v-slot:[`item.PaymentAuthority`]={item}>
                    <tr>
                        <td class="spcit" >{{ item.PaymentAuthority |moneyformat}} </td>      
                    </tr>
                </template>
                <template  v-slot:[`item.audit`]={item}>
                            <tr ><td> <v-btn tile color="#F7F7F7" class="px-3 my-2" small @click="view_item(item)" > 
                                <v-icon color="" small>visibility</v-icon></v-btn> </td></tr>
                        </template>

            </v-data-table>
            <v-divider></v-divider>

           <v-layout row justify-end align-center class="mx-2">
                        <v-text class="mr-1 font-weight-bold body-1" style="color:#3C3D3D; font-size:12px !important">
                                <span class="" style="color:#E46A25;font-size:12px;">Showing {{fval}}-{{sval}} </span>out of {{totalLen }} records

                        </v-text>
                        <v-divider  class="mx-2" vertical ></v-divider>
                        <v-pagination 
                            class="cust-bnkAcc-Permission"
                            v-model="page"
                            color="#152F38"
                            flat
                            :total-visible="9"
                            @input="nextPageBtn(page)"
                            :length="pageLength"
                            ></v-pagination>
                    </v-layout>
        </v-card>
        </v-card>

        <!-- table popup -->
        <v-dialog v-model="dialogTable">
            <v-card color="#F7F7F7" class="pb-2" tile>
                <v-card>
          <v-layout
            justify-space-between
            class=""
            style="flex:1;background-ox-shadow: 0px 3px 5px #00000029;"
          >
            <v-card-title
              class="pa-0 pl-3 text-capitalize"
              style="color:#10242B;font-size:16px"
              >Audit Trail of Financial Authority Limit Master</v-card-title
            >

            <div
              style="border-left:1px solid #C1C8CC;"
              @click="dialogTableclose"
            >
              <v-icon style="cursor: pointer;" class="pa-2">close</v-icon>
            </div>
          </v-layout>
        </v-card>
                <v-data-table
                        class="pa-4"
                        :headers="headersNew"
                        :items="settleTableDataNew"
                        hide-default-footer
                        dense
                        disable-pagination>
                        <template v-slot:[`item.modifiedOn`]="{ item }">
                                <div   
                              >
                                    {{ formatDateTime(item.modifiedOn) }} </div>
                            </template>
                        <!-- <template  v-slot:item.corporateOffice={item}>
                            <tr ><td  >{{ item.hasOwnProperty('corporateOffice') ? item.corporateOffice == true ? 'Y' : 'N' : ''}} </td></tr>
                        </template>
                        <template  v-slot:item.ecu={item}>
                            <tr ><td  >{{ item.ecu == true ? 'Y' : 'N'}} </td></tr>
                        </template> -->
                        
                </v-data-table>
                <v-divider></v-divider>
                    <v-layout row justify-end align-center style="background-color:#f7f7f7" class="pa-2 mr-3">
                        <span class="mr-1 font-weight-bold body-1" style="color:#3C3D3D; font-size:12px !important">
                        <span class="" style="color:#E46A25;font-size:12px;">Showing {{fromPage}}-{{toPage}} </span>out of {{totalPages}} records</span>
                        <v-divider  class="ma-2" vertical ></v-divider>
                        <v-pagination
                            class="pdct-stUp-bnkAcc-pgn"
                            v-model="page"
                            color="#152F38"
                            flat
                            @input="nextPageData(page)"
                            :length="pageLengthNew"
                        ></v-pagination>
                    </v-layout>

            </v-card>
            

        </v-dialog>

        
    </v-app>
</template>

<script>
import moment from 'moment';
  
export default {

    created(){
        this.$store.commit("SAVE_PAGE_HEADER", "Financial Authority Limit Master Search");
        console.log("Financial Authority Limit Master Search page===>",this.$router.history.current.name)

        // console.log("this.getEditDetails(this.$router.history.current.name) from Mixin===>",this.getEditDetails(this.$router.history.current.name))

        
        

        this.GET(3,'/line-of-business/get-line-of-business-list', (res,error)=>{
            if(!error){
                console.log("response from api is",res.data.data[0].lineOfBusiness);
                this.itemsOfLOB=res.data.data[0].lineOfBusiness
            }else{
                console.log("error",error.response.data);
            }
        });

        this.GET(6,'financilauthority/all-financial-autority-levels', (res,error)=>{
            if(!error){
                console.log("response from api is",res.data.data);
                this.levelItems=res.data.data
            }else{
                console.log("error",error.response.data);
            }
    });

        this.searchAll(this.page)//,'Approved'

        // if(this.getEditDetails(this.$router.history.current.name)){
        //     this.disableAll=this.getEditDetails(this.$router.history.current.name).isEdit
        // }else{
        //     this.disableAll=false
        // }
    },
    data() {
        return {
            dialogTable: false,
            Level:'',
            lob:'',
            statusFieldItems: ['Approved','Rejected','Initiated for approval'],
            statusField: '',
            items:['PA','Health'],
            itemsOfLOB:[],
            levelItems:[],
            staticlevelItems:["Level I","Level II","Level III","Level IV","Level V","Level VI","Level VII","Level VIII","Level IX","Level X","Level XI","Level XII","Level XIII","Level XIV","Level XV","Level XVI","Level XVII","Level XVIII","Level XIX","Level XX","Level XXI","Level XXII","Level XXIII","Level XXIV","Level XXV"],
            page:1,
            fval:0,
            sval:0,
            totalLen:'',
            pageLength:'',
            GetAllData:'',
            GetData:[ ],
            headers:[
                {text: 'Sr. No.', value: 'SerialNumber',sortable: false,divider:true,class:"hdr-txt-clr_FALmtMstrSrch hdr-bg-clr"},
                {text: 'Level', value: 'level',sortable: false,divider:true,class:"hdr-txt-clr_FALmtMstrSrch hdr-bg-clr"},
                {text: 'Line of Business', value: 'lob',sortable: false,divider:true,class:"hdr-txt-clr_FALmtMstrSrch hdr-bg-clr"},
                {text: 'Reserving Authority', value: 'ReservingAuthority',sortable: false,divider:true,class:"hdr-txt-clr_FALmtMstrSrch hdr-bg-clr"},
                {text: 'Payment Authority', value: 'PaymentAuthority',sortable: false,divider:true,class:"hdr-txt-clr_FALmtMstrSrch hdr-bg-clr"},
                {text: 'Status', value: 'status',sortable: false,divider:true,class:"hdr-txt-clr_FALmtMstrSrch hdr-bg-clr"},
                {text: 'Audit Trail', value: 'audit',sortable: false,divider:true,class:"hdr-txt-clrApproval hdr-bg-clr"}
            ],
            settleTableDataNew:[],
            headersNew:[
                {text: 'Level', value: 'level',sortable: false,divider:true,class:"hdr-txt-clrApproval hdr-bg-clr"},
                {text: 'LOB', value: 'lob',sortable: false,divider:true,class:"hdr-txt-clrApproval hdr-bg-clr"},
                {text: 'Maximum Reserve Authority', value: 'reserveAuth',sortable: false,divider:true,class:"hdr-txt-clrApproval hdr-bg-clr"},
                {text: 'Maximum Payment Authority', value: 'paymentAuth',sortable: false,divider:true,class:"hdr-txt-clrApproval hdr-bg-clr"},
                {text: 'Status', value: 'status',sortable: false,divider:true,class:"hdr-txt-clrApproval hdr-bg-clr"},
                {text: 'Last Modified On', value: 'modifiedOn',sortable: false,divider:true,class:"hdr-txt-clrApproval hdr-bg-clr"},
                {text: 'Last Modified By', value: 'modifiedBy',sortable: false,divider:true,class:"hdr-txt-clrApproval hdr-bg-clr"},
                // {text: 'Corporate Office', value: 'corporateOffice',sortable: false,divider:true,class:"hdr-txt-clrApproval hdr-bg-clr"},
                // {text: 'ECU', value: 'ecu',sortable: false,divider:true,class:"hdr-txt-clrApproval hdr-bg-clr"},
                // {text: 'Changed By', value: 'changedBy',sortable: false,divider:true,class:"hdr-txt-clrApproval hdr-bg-clr"},
                // {text: 'User ID', value: 'userId',sortable: false,divider:true,class:"hdr-txt-clrApproval hdr-bg-clr"},
                // {text: 'Date & time of change', value: 'dateTime',sortable: false,divider:true,class:"hdr-txt-clrApproval hdr-bg-clr"},
                // {text: 'Approver Name', value: 'approverName',sortable: false,divider:true,class:"hdr-txt-clrApproval hdr-bg-clr"},
                // {text: 'Approver ID', value: 'approverId',sortable: false,divider:true,class:"hdr-txt-clrApproval hdr-bg-clr"},
        
            ],
            searchApi: null,
            isLoading: false,
            required(){
                return v=>v && v.length>0 || "This Field is Required"
            },
            lastPage:false,
            itemsPerPage:20,
            disableAll:true,
            pageLengthNew:0,
            totalPages:0,
            toPage:0,
            fromPage:0
        };
    },
    watch: {
        searchApi(val){
            if (this.isLoading) return 
            this.isLoading = true;
            this.GET(6,'financilauthority/search-levels-by-name/'+ val,(res, err) => {
                if(!err){
                    console.log(res);
                    // this.levelItems = res.data.data;
                }else{
                    console.log(err);
                }
            })
             this.isLoading = false;
        }
    },
    methods:{

        clearresults(){
            this.Level = ''
            this.lob = ''
            this.statusField = ''
            this.searchAll(this.page)//,'Approved'
        },

        createNewbtn(){
                this.$router.push({name: 'FALimitMaster', params:{value:true}})
            },
        routeOnClick(item){
            console.log("this is the item from table",item);
            this.$router.push({'name':'FALimitMaster', params:{item,editValue:true}})
        },
        nextPageBtn(page){
            if(this.pageLength === page){
                this.lastPage = true
                console.log("this is last page",page);
            }else{
                this.lastPage = false
                console.log("this is not last page",page);
            }
            if(this.statusField == '' || this.statusField == null || this.statusField == undefined){
                this.searchAll(page)//, 'Approved'
            }else{
                this.searchAll(page, this.statusField)
            }
        },
        onSearch(){
            
            console.log("search btn is working",this.page);
             if(!this.$refs.searchform.validate()){
               this.showToast("Please fill all fields",this.TOST().WARNING)
                console.log("%c It is a not a valid Form","color:red");
                return
            }else{
                if(this.Level=="" && this.lob=="" && this.statusField==""){
                this.showToast("Please enter search criteria.",this.TOST().WARNING);
                console.log("%c It is a valid Form","color:green");
                return
            }
               this.searchAll(this.page)
            }
        },
        
        searchAll(page,def){
            console.log('def is before',def)
            
            if(def != 'Approved'){
            //     alert("as undefined")
            //    def =  this.statusField 
             
                // alert("Def undefined")
                if(this.statusField == ''){
                    // def = 'Approved'
                }else{
                    def =  this.statusField 
                }
                
            }
             
            let reqData = "level=" +this.Level+'&lob='+this.lob+'&perPage=20&pageNo='+page
            if(def){
                reqData =   reqData +   '&status='+def
            }
           console.log('def is ',def)
            this.GET(6,'financilauthority/search-financial-autority?'+reqData,
                            (res,error) => {
                                if(!error){
                                    console.log("response from financial search is",res.data.data.data);
                                    this.pageLength = res.data.data.total_pages;
                                    this.totalLen = res.data.data.total
                                    var temp =  this.page*20;
                                    this.fval = temp-19;
                                    
                                    if(this.lastPage){
                                        this.sval = this.totalLen
                                        console.log("value of sval is fo",this.sval);
                                    }else{
                                         if(this.totalLen < 20){
                                            this.sval = this.totalLen
                                            console.log("this are the pageLength for searchTable",this.totalLen);
                                        }else{

                                            this.sval = temp;
                                           console.log("value of sval is",this.sval);
                                        }
                                    }
                                    console.log("this is response for search===>",res.data.data);
                                    let tempArr = res.data.data.data;
                                    // this.GetData = [];
                                    console.log("temp arr",tempArr)
                                    this.GetData = tempArr.map((item,index) => {
                                                                        return{
                                                                                SerialNumber: 20*(page)-19+index,
                                                                                level:item.level,
                                                                                lob:item.lob,
                                                                                ReservingAuthority:item.reservingAuthorityLimit ,
                                                                                PaymentAuthority:item.paymentAuthorityLimit,
                                                                                allData:item,
                                                                                status:item.status
                                                                            }
                                                                        });
                                }else{
                                    
                                    this.showToast("Records not found",this.TOST().WARNING);
                                    this.GetData = [];
                                    console.log("their was error in search==>",error.response.data);
                                }

                                
                            })
        },

        dialogTableclose(){
            this.settleTableDataNew = [];
            this.fromPage = 0;
            this.toPage = 0;
            this.totalPages = 0;
            this.dialogTable = false;
        },

        view_item(item){
            console.log("item", item);
            this.dialogTable = true;
            this.GET(6,'financilauthority/financial-autority-audit/'+item.allData._id,(res,error)=>{
                if(!error){
                    console.log(res);
                    if(res.data.msg == "No records found"){
                        console.log("res");
                        this.settleTableDataNew = [];
                        this.fromPage = 0;
                        this.toPage = 0;
                        this.totalPages = 0;
                        this.showToast(res.data.msg, this.TOST().ERROR);
                    }else{
                    console.log("res");
                    this.pagination(res.data.data);
                    this.settleTableDataNew = [];
                    this.settleTableDataNew = res.data.data.data.map((item,index) => {
                        let dateIs = item.createdAt//moment(item.createdAt.split('T')[0]).format("DD-MM-YYYY");
                        console.log(dateIs);

                        // let timeIs = item.createdAt.split('T')[1].slice(0,8)

                        return{
                            level: item.level,
                            lob: item.lob,
                            reserveAuth: item.reservingAuthorityLimit,
                            paymentAuth: item.paymentAuthorityLimit,
                            status: item.status,
                            modifiedOn: dateIs,// + ' ' + timeIs
                            modifiedBy: item.createdByUser,
                            // corporateOffice: item.corporateOffice,
                            // ecu: item.ecu,
                            
                            // dateTime: dateIs + ' ' + timeIs
                        }
                    })
                    }
                }else{
                    console.log(err.response);
                    this.settleTableDataNew = [];
                        this.fromPage = 0;
                        this.toPage = 0;
                        this.totalPages = 0;
                    this.showToast(err.response.data.msg, this.TOST().WARNING);
                    
                }
            })
        },

        pagination(dataFromApi){
            console.log("data in pagination",dataFromApi);
                                    this.pageLengthNew = dataFromApi.total_pages;
                                    this.totalPages = dataFromApi.total
                                    var temp =  this.page*10;
                                    this.fromPage = temp-9;
                                   
                                    if(this.lastPage){
                                        this.toPage = this.totalPages
                                        console.log("value of toPage is ",this.toPage,"\n","value of from page",this.fromPage);
                                    }else{
                                        if(this.totalPages < 10){
                                            this.toPage = this.totalPages
                                            console.log("this are the pageLength for searchTable",this.totalPages);
                                        }else{

                                            this.toPage = temp;
                                            console.log("value of toPage is ",this.toPage,"\n","value of from page",this.fromPage);

                                        }

                                    }
        },
        formatDateTime(transactionTime){
            console.log(transactionTime," moment.utc(transactionTime).local().format('YYYY-MMM-DD h:mm A') ",moment.utc(transactionTime).local().format('YYYY-MMM-DD h:mm A')," new Date(transactionTime).toLocaleString('en-IN') ",new Date(transactionTime).toLocaleString('en-IN'));
            return transactionTime && transactionTime !== "-" ? moment.utc(transactionTime).local().format('YYYY-MM-DD h:mm A') : "-" //moment(transactionTime).format("DD-MM-YYYY") + " " + moment(transactionTime).format('LT') : "" //new Date(transactionTime).toLocaleString('en-IN') : transactionTime;//"";//, { hour: 'numeric', minute: 'numeric', hour12: true }
        },

    },

    filters:{

      
        
        
    }
}
</script>

<style>

.cust-rmvpd-psins3 .v-text-field.v-text-field--enclosed>.v-input__control>.v-input__slot {
    padding-right: 0px !important;
}

.cust_pr2 .v-text-field .v-input__slot,
.v-text-field--outline .v-input__slot {
    min-height: 42px !important;
    display: flex !important;
    align-items: center !important;
    /* border:1px red solid; */
}

.cstm-clr-pr3{
    color:#646666 !important;
    margin-bottom: 5px !important;
    font-size: 13px !important;
}

.cust_pd1 .v-text-field .v-input__slot,
.v-text-field--outline .v-input__slot {
    min-height: 42px !important;
    display: flex !important;
    align-items: center !important;
    /* border:1px red solid; */
}

.cust-rmvpd-pr1 .v-text-field.v-text-field--enclosed>.v-input__control>.v-input__slot {
    padding: 0px !important;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
    font-size: 0.84rem !important;
}

.hdr-txt-clr_FALmtMstrSrch{
    color: white !important;
    font-weight: bold;
    /* padding:0px!important;
    padding: 10px !important;
    padding-left: 14px !important; */
}

.hdr-bg-clr{
  background-color: #23B1A9;
}
.headercolor{
    background-color:#f7f7f7;
}
.cardfont{
    font-size: 0.84rem !important;
    padding-right: 2px;
}
.srNoWidth{
    width: 80px;

}
.spcit{letter-spacing: 0.4px;}

</style>