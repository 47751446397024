 <template>
    <v-app>
        <v-card color="" class="pa-3 mb-1" style="" tile>
          <v-layout align-center class="py-2">
            <v-text class="text-uppercase font-weight-bold title-clr-prtbs ml-2">
              Provider Details
            </v-text>
            <v-divider vertical class="mx-4"></v-divider>
             <v-text class="font-weight-bold cstm-clr-prtbs">
                Registered Entity *
              </v-text>
              <v-divider vertical class="mx-4"></v-divider>
            <v-btn-toggle dense mandatory v-model="text" tile class="" @change="tabseleted(text)" style="background-color:#F7F7F7;opacity:100%;"  >
                <!-- <v-btn  value="indvdl" disabled class="text-capitalize font-weight-bold px-5" active-class="srSelected">
                  <span style="white-space: normal;" :class="[indvdlshw === true ? 'activeredbackground' : '']">Individual</span>
                </v-btn> -->
                <v-btn  value="instutn" class="text-capitalize font-weight-bold  px-5" active-class="srSelected">
                  <span >Institution</span>
                </v-btn>
               
                
            </v-btn-toggle>

          </v-layout>
          
        </v-card>
                <template v-if="enbleinstitution" >
                   <insti :indtype="{type:'validate',data:{indata:this.instutionData}}"  ></insti>
                </template>
                
            <!-- <InstitutionValidation v-if="instutinshw===true"></InstitutionValidation> -->
    </v-app>
</template>
<script>

import InstitutionValidation from './InstitutionValidation.vue'
import insti from '../ProviderRegistrationClaims/Institution';
export default {

    components:{
      
        InstitutionValidation,
        insti
    },

    created() {

        this.$store.commit("SAVE_PAGE_HEADER", "Provider Validation");

        this.get_single_provider(this.$route.params.institution_id);

    },

    computed: {

    },

    data() {
        return {
        
          indvdlshw:false,
          instutinshw:true,
          text:"instutn",
          instutionData:null,
          enbleinstitution:false
        }
    },

    methods: {

        get_single_provider(indata_institution_id){

          // GET_PROVIDER_HOME
            this.enbleinstitution = false
            this.GET(2,'provider/get-provider-institution/'+indata_institution_id, response=>{
          
                  console.log("response institution latest data for homepage work item to product validation >>>>>>", response.data);
                    this.instutionData = {}
                    let tempData = response.data.data
                    this.instutionData={

                      addressLine1 : tempData.hasOwnProperty('addressLine1')? this.chkplndata(tempData.addressLine1) : '',
                      addressLine2: tempData.hasOwnProperty('addressLine2')? this.chkplndata(tempData.addressLine2) : '',
                      addressLine3:tempData.hasOwnProperty('addressLine3')? this.chkplndata(tempData.addressLine3) : '',
                      aliasName:tempData.hasOwnProperty('aliasName')? this.chkplndata(tempData.aliasName) : '',
                      childProvider:tempData.hasOwnProperty('childProvider')? this.chkplndata(tempData.childProvider) : '',
                       parentProvider:tempData.hasOwnProperty('parentProvider')? this.chkplndata(tempData.parentProvider) : '',
                      mappingType:tempData.hasOwnProperty('mappingType')? this.chkplndata(tempData.mappingType) : '',
                      cityOthers:tempData.hasOwnProperty('cityOthers')? this.chkplndata(tempData.cityOthers) : '',
                      cityTownVillage:tempData.hasOwnProperty('cityTownVillage')? this.chkplndata(tempData.cityTownVillage) : '',
                      country:tempData.hasOwnProperty('country')? this.chkplndata(tempData.country) : '',
                      createdAt:tempData.hasOwnProperty('createdAt')? this.chkplndata(tempData.createdAt) : '',
                      district:tempData.hasOwnProperty('district')? this.chkplndata(tempData.district) : '',
                      doctorsList:tempData.hasOwnProperty('doctorsList')? this.chkplndataarry(tempData.doctorsList) : [],
                      documents:tempData.hasOwnProperty('documents')? this.chkplndataarry(tempData.documents) : [],
                      email:tempData.hasOwnProperty('email')? this.chkplndata(tempData.email) : '',
                      entityName:tempData.hasOwnProperty('entityName')? this.chkplndata(tempData.entityName) : '',
                      faxNumber:tempData.hasOwnProperty('faxNumber')? this.chkplndata(tempData.faxNumber) : '',
                      gstin:tempData.hasOwnProperty('gstin')? this.chkplndata(tempData.gstin) : '',
                      isNonServiceProvider: tempData.hasOwnProperty('isNonServiceProvider')? this.chkplndata(tempData.isNonServiceProvider) : '',
                      panNumber: tempData.hasOwnProperty('panNumber')? this.chkplndata(tempData.panNumber) : '',
                      pinPostalCode: tempData.hasOwnProperty('pinPostalCode')? this.chkplndata(tempData.pinPostalCode) : '',
                      prn: tempData.hasOwnProperty('prn')? this.chkplndata(tempData.prn) : '',
                      providerStatusHistory: tempData.hasOwnProperty('providerStatusHistory')? this.chkplndataarry(tempData.providerStatusHistory) : [],
                      providerType: tempData.hasOwnProperty('providerType')? this.chkplndata(tempData.providerType) : '',
                      providerTypeOther: tempData.hasOwnProperty('providerTypeOther')? this.chkplndata(tempData.providerTypeOther) : '',
                      registeredBody: tempData.hasOwnProperty('registeredBody')? this.chkplndata(tempData.registeredBody) : '',
                      registeredEntity: tempData.hasOwnProperty('registeredEntity')? this.chkplndata(tempData.registeredEntity) : '',
                      registrationDate:tempData.hasOwnProperty('registrationDate')? this.chkplndata(tempData.registrationDate) : '',
                      registrationNumber: tempData.hasOwnProperty('registrationNumber')? this.chkplndata(tempData.registrationNumber) : '',
                      registrationValidUpto: tempData.hasOwnProperty('registrationValidUpto')? this.chkplndata(tempData.registrationValidUpto) : '',
                      rohiniId: tempData.hasOwnProperty('rohiniId')? this.chkplndata(tempData.rohiniId) : '',
                      servicesProvided: tempData.hasOwnProperty('servicesProvided')? this.chkplndataarry(tempData.servicesProvided) : [],
                      servicesProvidedOther: tempData.hasOwnProperty('servicesProvidedOther')? this.chkplndata(tempData.servicesProvidedOther) : '',
                      state: tempData.hasOwnProperty('state')? this.chkplndata(tempData.state) : '',
                      status:tempData.hasOwnProperty('status')? this.chkplndata(tempData.status) : '',
                      telephoneNumber: tempData.hasOwnProperty('telephoneNumber')? this.chkplndata(tempData.telephoneNumber) : '',
                      dailyMedicalRecords: tempData.hasOwnProperty('validationDetails')? tempData.validationDetails.hasOwnProperty('dailyMedicalRecords')? this.chkplndata(tempData.validationDetails.dailyMedicalRecords) :'' :'',
                      hasOperationTheatre: tempData.hasOwnProperty('validationDetails')? tempData.validationDetails.hasOwnProperty('hasOperationTheatre')? this.chkplndata(tempData.validationDetails.hasOperationTheatre) :'' :'',
                      medicalPractionerRoundTheClock: tempData.hasOwnProperty('validationDetails')? tempData.validationDetails.hasOwnProperty('medicalPractionerRoundTheClock')? this.chkplndata(tempData.validationDetails.medicalPractionerRoundTheClock) :'' :'',
                      noOfBeds: tempData.hasOwnProperty('validationDetails')? tempData.validationDetails.hasOwnProperty('noOfBeds')? this.chkplndata(tempData.validationDetails.noOfBeds) :'' :'',
                      nursingRoundTheClock: tempData.hasOwnProperty('validationDetails')? tempData.validationDetails.hasOwnProperty('nursingRoundTheClock')? this.chkplndata(tempData.validationDetails.nursingRoundTheClock) :'' :'',
                      registrationDateEffectiveFrom: tempData.hasOwnProperty('validationDetails')? tempData.validationDetails.hasOwnProperty('registrationDateEffectiveFrom')? this.chkplndata(tempData.validationDetails.registrationDateEffectiveFrom) :'' :'',
                      registrationDateEffectiveTo: tempData.hasOwnProperty('validationDetails')? tempData.validationDetails.hasOwnProperty('registrationDateEffectiveTo')? this.chkplndata(tempData.validationDetails.registrationDateEffectiveTo) :'' :'',
                      _id: tempData.hasOwnProperty('_id')? this.chkplndata(tempData._id) : '',

                    }
                    this.enbleinstitution = true
                  //  this.$store.commit('GET_PROVIDER_HOME',response.data.data);
                  //  self.showToast(response.data.msg,self.TOST().SUCCESS)
            })

        },

         chkplndataarry(data){
            console.log("chkplndataarry",data); 
            try{
                if (data ==="" || data === "undefined" || data === null) {
                    return []
                } else {
                    return data;
                }

             }catch(err) {
                console.log(err)
            }
            
        },

        chkplndata(data){
            console.log("chkplndata",data); 
            try{
                if (data ==="" || data === "undefined" || data === null) {
                    return ""
                } else {
                    return data;
                }

             }catch(err) {
                console.log(err)
            }
            
        },

        tabseleted(itm){
            console.log("Selected tab",itm);
            if(itm==="indvdl"){
              this.indvdlshw=true
              this.instutinshw=false
            }else if(itm==="instutn"){
              this.indvdlshw=false
              this.instutinshw=true
            }
        }
       

    },

    watch: {
    
    },
    
    
}

</script>
<style >

.srSelected{
  background-color: #23B1A9 !important;
  color:"white"
}

.activeredbackground{
  color:white;
}

.title-clr-prtbs{
    color:#10242B !important;
    /* margin-bottom: 5px; */
}
.cstm-clr-prtbs{
    color:#646666 !important;
    /* margin-bottom: 5px !important; */
    font-size: 13px !important;
}

.btn_pr_tabs{
  color:#10242B;
  opacity:100% !important;  
  font-size:13px !important;
  font-weight: 700;
  /* border-left:0.5px solid #C1C8CC; */

    border: solid;
    border-top-width: thin;
    border-right-width: thin;
    border-bottom-width: thin;
    border-left-width: thin;
    transition: inherit;
    border-top-color: rgba(255, 255, 255, 0.26);
    border-right-color: rgba(255, 255, 255, 0.26);
    border-bottom-color: rgba(255, 255, 255, 0.26);
    border-left-color: rgba(193, 200, 204, 0.26);
}

</style>