<template>
<div>
   
<v-app v-if="empanelmentValue === 'empanelment'">

   
   
        <div style="background-color: #FFFFFF;" column class="py-3">
        <v-form ref="form">
            <v-layout class=" mt-2" >
                        <v-flex xs3>
                            <v-layout column class="mx-2 my-2">
                                <v-text class="text-uppercase font-weight-bold title-clr-pr-ACD">
                                    PROVIDER APPROVAL
                                </v-text>
                                <v-divider></v-divider>
                            </v-layout>
                        </v-flex>


                        <v-flex xs3>
                            <v-layout column class="cust_pd mx-2 my-2 ">
                                <v-text class="font-weight-bold cstm-clr-pd">
                                    Approver Remarks
                                </v-text>
                            
                                <v-text-field
                                    :disabled="onSendMouDisable"
                                    solo
                                    label="Enter..."
                                    v-model="approverRemarks"
                                    class="rounded-0"
                                    maxlength="200"
                                    dense
                                    :rules="[approverRemarksRules(approverRemarks)]"
                                    >
                                </v-text-field>
                            </v-layout>
                        </v-flex>

                        
                        <v-flex xs3>
                            <v-layout column class="cust_pd mx-2 my-2 testingStatus">
                                <v-text class="font-weight-bold cstm-clr-pd">
                                   Provider Empanelment Approval *
                                </v-text>
                            
                                <v-select
                                     :disabled="onSendMouDisable"
                                    :items="empanelmentApproverItem"
                                    solo
                                    label="Enter..."
                                    v-model="empanelmentApprover"
                                    class="rounded-0 testStatusValue"
                                    data-test="select"
                                    maxlength="10"
                                    :rules="[v => !!v || 'This field is required']"
                                    >
                                </v-select>
                            </v-layout>
                        </v-flex>


                    <v-flex xs3>
                            <v-layout column class="cust_pd mx-2 my-2 ">
                                <v-text class="font-weight-bold cstm-clr-pd">
                                    Reason *
                                </v-text>
                            
                                <v-text-field
                                    :disabled="onSendMouDisable"
                                    solo
                                    label="Enter..."
                                    v-model="reason"
                                    class="rounded-0"
                                    maxlength="200"
                                    :rules="reasonRules"
                                    >
                                </v-text-field>
                            </v-layout>
                        </v-flex>

                        <v-flex xs3></v-flex>
                    
                                                    
                    </v-layout>

                   
        <v-dialog v-model="confirmDialogCancel" persistent max-width="450px">
                 
                         <v-card tile color="#F7F7F7" >
                            <v-card tile>
                                <v-layout justify-space-between  class="" style="flex:1;background-ox-shadow: 0px 3px 5px #00000029;">
                             
                                    <v-card-title class="pa-0 pl-3 text-capitalize font-weight-regular" style="color:black"> 
                                       CONFIRMATION
                                    </v-card-title>
                                    <div style="border-left:1px solid #C1C8CC;" @click="close_cancelDialog()">
                                        <v-icon style="cursor: pointer;" class="pa-2" >close</v-icon>
                                    </div>
                                </v-layout>
                            </v-card>
                        <v-flex >
                           
                       
                        </v-flex>
                        <v-card-text >
                                Details saved on screen will be lost
                        </v-card-text>
                        <v-card-actions justify-start>
                          
                           
                           
                            <v-btn dark  tile color="black" @click="confirmDialogCancel = false" class="text-capitalize px-3 mx-2" small><v-icon dark small>cancel</v-icon> <v-divider vertical class="mx-2"></v-divider>Cancel</v-btn>

                            <!-- <v-btn depressed small color="secondary" class="rounded-0"  >
                                <v-icon dark right>check_circle</v-icon>
                            </v-btn> -->
                            <v-btn @click="okClick" dark  tile color="#23B1A9" class="text-capitalize px-3 ml-2" small><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider> OK</v-btn>

                        </v-card-actions>
                    </v-card>
                </v-dialog>

                
            <div class="mx-2 pa-5" style="background-color: #F7F7F7; display:flex;justify-content:space-between;"  >
                <v-layout style="flex:1" justify-end > 
                    <v-btn @click="viewdocs" :dark = "!onSendMouDisable"   :disabled="onSendMouDisable" tile color="black" class="text-capitalize px-3 ml-3" small><v-icon dark small>visibility</v-icon> <v-divider vertical class="mx-2"></v-divider>View Documents</v-btn>
                    <v-btn :dark = "!onSendMouDisable" tile color="black"  :disabled="onSendMouDisable" class="text-capitalize px-3 ml-3" small @click="sendBackForm"><v-icon dark small >mdi-arrow-left</v-icon> <v-divider vertical class="mx-2"></v-divider>Send Back</v-btn>
                    <v-btn :dark = "!onSendMouDisable" tile color="#E46A25"    :disabled="onSendMouDisable" class="text-capitalize px-3 ml-3" small @click="cancelData()"><v-icon dark small>cancel</v-icon> <v-divider vertical class="mx-2"></v-divider>Close</v-btn>
                    <v-btn :dark = "!onSendMouDisable" tile color="#23B1A9"    :disabled="onSendMouDisable" class="text-capitalize px-3 ml-3 testing" small @click="submitDetails"><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider>Submit</v-btn>
                    <v-btn :dark = "!onSendMouDisable" tile color="green"  :disabled="onSendMouDisable" class="text-capitalize px-3 ml-3 testing" v-if='enableGenerate === true' small @click="generatemou" ><v-icon dark small>add</v-icon> <v-divider vertical class="mx-2"></v-divider>Generate MoU</v-btn>

                     <v-btn :dark = "!onSendMouDisable"   :disabled="onSendMouDisable" tile color="green" class="text-capitalize px-3 ml-3 testing"
                            v-if='aftergenmou' small @click="sendmou" > 
                            
                           <v-icon dark small>send</v-icon>
                    <v-divider vertical class="mx-2"></v-divider>Send MoU</v-btn>
                    
                </v-layout>
            </div>

        </v-form>
            
        </div>

       

    <div v-if="docen">
                <docup 
                    @closedoc="docen = false"    
                    
                    :setdoc="{docen:docen,provider_data:$store.state.empanelProviderData.empanelment,
                    type:'edit', updocs:[],from:'emp' }" >
                </docup>
    </div>
    
     <v-dialog v-model="genmou" persistent max-width="560px">
                        <v-card color="#F7F7F7" class="pb-2" tile max-height="560px" style="overflow-y: scroll;" >
                         
                            <v-card tile class="fixh" >
                                <v-layout justify-space-between  class="pa-1" style="flex:1;background-ox-shadow: 0px 3px 5px #00000029;">
                                    <v-layout align-center>
                                        <v-card-title class="pa-0 pl-3 text-capitalize font-weight-bold"
                                        style="color:#10242B;font-size:13px">Generate & Edit MOU</v-card-title>   
                                    </v-layout>
                                     <!-- <v-layout align-center> -->
                                          <!-- <v-btn @click="openpdf" :dark="aftergenmou" :disabled="!aftergenmou"  tile style="background-color:#23B1A9;" class="text-capitalize px-3 ml-2" small><v-icon dark small>chrome_reader_mode</v-icon>
                            <v-divider vertical class="mx-2"></v-divider>Open MOU PDF</v-btn> -->
                                        <!-- <v-card-title class="pa-0 pl-3 text-capitalize font-weight-bold"
                                        style="color:#10242B;font-size:13px">Generate & Edit MOU</v-card-title>    -->
                                    <!-- </v-layout> -->
                                    <div style="border-left:1px solid #C1C8CC;" @click="genmou = false">
                                        <v-icon style="cursor: pointer;" class="pa-2" >close</v-icon>
                                    </div>
                                </v-layout>
                            </v-card>
                            <div class="pa-5 mt-10" >
                                 <v-card color="#fff" class="pb-2 pa-4 ma-2" tile >
                            <v-form ref="themouform" >

                           
                                 <v-flex >
                                    <v-layout column class="cust_pr mx-2 ">
                                        <p class="font-weight-bold cstm-clr-pr">
                                           Provider Name
                                        </p>
                                        <v-text-field
                                           readonly
                                            solo
                                            label="Enter..."
                                            v-model="pname"
                                            class="rounded-0"
                                        >
                                           
                                        </v-text-field>
                                    </v-layout>
                                </v-flex>

                                <v-flex class="my-4" >
                                    <v-layout column class="cust_pr mx-2 ">
                                        <p class="font-weight-bold cstm-clr-pr">
                                          Address
                                        </p>
                                        <v-text-field
                                           readonly
                                            solo
                                            label="Enter..."
                                            v-model="addressis"
                                            class="rounded-0"
                                        >
                                           
                                        </v-text-field>
                                    </v-layout>
                                </v-flex>

                                <v-flex class="my-4" >
                                    <v-layout column class="cust_pr mx-2 ">
                                        <p class="font-weight-bold cstm-clr-pr">
                                          Office at
                                        </p>
                                        <v-text-field
                                           readonly
                                            solo
                                            label="Enter..."
                                            v-model="officeat"
                                            class="rounded-0"
                                        >
                                           
                                        </v-text-field>
                                    </v-layout>
                                </v-flex>

                                <!-- <v-flex class="my-4" v-if="false" >
                                    <v-layout column class="cust_pr mx-2 ">
                                        <p class="font-weight-bold cstm-clr-pr">
                                        Agreement effective date
                                        </p>
                                        <v-text-field
                                           
                                            solo
                                            label="Enter..."
                                            v-model="aed"
                                            class="rounded-0"
                                        >
                                           
                                        </v-text-field>
                                    </v-layout>
                                </v-flex> -->

                                <v-flex class="my-4">
                                    <v-layout column class=" mx-2 mt-2 cust_pr_quick cust-rmvpd-pr-quick">
                                        <p class="font-weight-bold cstm-clr-pr-quick">
                                            Agreement effective date
                                        </p>
                                    
                                        <v-menu
                                            ref="calender"
                                            :close-on-content-click="false"
                                            v-model="aedCalenderDate"
                                            :nudge-right="40"
                                            lazy
                                            transition="scale-transition"
                                            full-width
                                            max-width="290px"
                                            min-width="290px" 
                                            class="rounded-0"
                                            >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    label="dd/mm/yyyy"
                                                    :value="computedaed"
                                                      :rules="[requiredis()]"
                                                    solo
                                                    class="rounded-0"
                                                    readonly
                                                    >
                                            <div  slot="prepend-inner" style="background-color:#F7F7F7;  padding: 8px !important"><span class="material-icons">date_range</span></div>
                                                </v-text-field>
                                            </template>                        
                                                    <v-date-picker
                                                        v-model="aed"
                                                        @input="aedCalenderDate = false"
                                                        no-title
                                                    ></v-date-picker>
                                        </v-menu>


                                    </v-layout>
                                </v-flex>

                                 <v-flex class="my-4" >
                                    <v-layout column class="cust_pr mx-2 ">
                                        <p class="font-weight-bold cstm-clr-pr">
                                        Discount
                                        </p>
                                        <v-text-field
                                            maxlength="3"
                                            :rules="[setnumeric(discountis), requiredis(discountis)]"
                                            solo
                                            label="Enter..."
                                            v-model="discountis"

                                            class="rounded-0"
                                        >
                                           
                                        </v-text-field>
                                    </v-layout>
                                </v-flex>

                            <v-flex class="my-4" >
                                    <v-layout column class="cust_pr mx-2 ">
                                        <p class="font-weight-bold cstm-clr-pr">
                                         Insurer Attn: Mr.
                                        </p>
                                        <v-text-field
                                           maxlength="100"
                                            solo
                                            label="Enter..."
                                            v-model="insat"
                                            class="rounded-0"
                                             :rules="[spclChar(insat), requiredis(insat)]"
                                        >
                                           
                                        </v-text-field>
                                    </v-layout>
                            </v-flex>
                               
                             <v-flex class="my-4" >
                                    <v-layout column class="cust_pr mx-2 ">
                                        <p class="font-weight-bold cstm-clr-pr">
                                        PAN
                                        </p>
                                        <v-text-field
                                            readonly
                                            solo
                                            label="Enter..."
                                            v-model="thepan"
                                            class="rounded-0"
                                        >
                                           
                                        </v-text-field>
                                    </v-layout>
                            </v-flex>

                            <v-flex class="my-4" >
                                    <v-layout column class="cust_pr mx-2 ">
                                        <p class="font-weight-bold cstm-clr-pr">
                                         Hospital Attn.:
                                        </p>
                                        <v-text-field
                                            maxlength="100"
                                            solo
                                            label="Enter..."
                                            v-model="hosat"
                                            class="rounded-0"
                                            :rules="[spclChar(hosat), requiredis()]"
                                        > 
                                        </v-text-field>
                                    </v-layout>
                            </v-flex>

                            <v-flex class="my-4" >
                                    <v-layout column class="cust_pr mx-2 ">
                                        <p class="font-weight-bold cstm-clr-pr">
                                       Additional Clauses
                                        </p>
                                        <v-textarea
                                         filled
                                         label="Enter Additional Clauses"
                                         solo
                                         v-model="adc"
                                         class="rounded-0"
                                        > 
                                        </v-textarea>
                                    </v-layout>
                            </v-flex>
                         </v-form>
                           <v-layout style="justify-content:center;" >
                            <v-btn @click="postmou" dark  tile color="#23B1A9" class="text-capitalize px-3 ml-2" small><v-icon dark small>check_circle</v-icon>
                            <v-divider vertical class="mx-2"></v-divider>Submit</v-btn>

                            <v-btn @click="clearallis" dark  tile color="red" class="text-capitalize px-3 ml-2" small><v-icon dark small>delete</v-icon>
                            <v-divider vertical class="mx-2"></v-divider>Clear</v-btn>

                            <v-btn @click="openpdf" :dark="aftergenmou" :disabled="!aftergenmou"  tile style="background-color:#23B1A9;" class="text-capitalize px-3 ml-2" small><v-icon dark small>chrome_reader_mode</v-icon>
                            <v-divider vertical class="mx-2"></v-divider>Open MOU PDF</v-btn>
                        </v-layout>
                         </v-card>
                      
                           </div>
                        </v-card>
                   
            </v-dialog>
   <!-- CONTRACT DETAILS For New Emapnelment  -->
        <div style="background-color: #FFFFFF;" column class="py-3" v-if="approvalContractDetails===true">
       
          
           
          
        <v-form>
            <v-layout  class="my-2">
                <v-text class="text-uppercase font-weight-bold title-clr-pr-ACD">
                    CONTRACT DETAILS
                </v-text>     
            </v-layout>

            <v-divider></v-divider>

            <v-layout>
                <v-flex xs3 class="mt-5"  >
                    <v-layout column class="cust_pd  my-2" @click="uploadFileThClick">
                        <v-text class="font-weight-bold cstm-clr-pd" style="color:black !important">
                            Upload Signed MoU
                        </v-text>
                    
                    <v-card  class="d-flex rounded-0 align-center" style="height:50px; width:300px; cursor: pointer;"   @dragover.prevent @drop="dropImage">
                        <v-icon  v-if="imageUploaded === true" class="iconSize" thin color="#b8b2b2">mdi-cloud-upload-outline</v-icon>
                        <svg v-if="imageUploaded === true" viewBox="0 0 80 80" xmlns="http://www.w3.org/2000/svg" >
                            
                                <rect class="dashed" x="1" y="32" width="69" height="16" />
                                <text x="25" y="42" class="smalltext" >Drag image here</text>
                        </svg>
                        <v-card-text v-if="imageUploaded === false" >
                            {{filesArr[0].name | truncate(15, '...')}}
                        </v-card-text>
                        <input :type="typeOfFile" style="display:none"
                            accept="/*" 
                            ref="uploadThClick"
                            @change="selectedFile">
                            <v-btn  dark tile color="#E46A25" 
                                    class="text-capitalize" 
                                    style="margin-right:20px" small
                                    @click="uploadDocument">
                                <v-icon dark small>
                                    cloud_upload
                                </v-icon> 
                                <v-divider vertical class="mx-2"></v-divider>
                                Upload
                            </v-btn>
                    </v-card>
                    

                    </v-layout>
                </v-flex>

                <v-flex xs2 class="mt-5" style="margin-top: 43px !important;">
                    <v-layout column class="cust_pd  my-2 ">
                        <v-text class="font-weight-bold cstm-clr-pd" style="font-size:11px !important; color:black !important ">
                            Signed MoU Doc
                        </v-text>
                        
                        <div class="d-flex justify-space-between" style="width:700px">

                            <div class="d-flex justify-start align-center flex-1 boxStyle">
                                <v-icon color="#23B1A9" class="flex-1 ml-2"  >mdi-text-box</v-icon> 
                                <v-divider vertical inset class="mx-2"></v-divider>
                                <p class="flex-2 mb-0 font-weight-bold" style="font-size:13px">{{ document1 === ''? 'Document 1' : document1 | truncate(10, '...') }}</p>
                                
                            </div>
                            <div class="deleteIcon">
                                    <v-icon 
                                        color="white" 
                                        class="icondel"  
                                        small right
                                        @click="delDocument1(document1)">
                                        mdi-trash-can-outline
                                    </v-icon>
                            </div>

                            <div class="d-flex justify-start align-center flex-1 boxStyle ml-3"  style=" height:30px; width:170px">
                                <v-icon color="#23B1A9" class="flex-1 ml-2"  >mdi-text-box</v-icon> 
                                <v-divider vertical inset class="mx-2"></v-divider>
                                <p class="flex-2 mb-0 font-weight-bold" style="font-size:13px">{{ document2 === ''? 'Document 2' : document2 | truncate(10, '...') }}</p>
                            </div>
                            <div class="deleteIcon">
                                    <v-icon 
                                        color="white" 
                                        class="icondel"  
                                        small
                                        @click="delDocument2(document2)">
                                        mdi-trash-can-outline
                                    </v-icon>
                            </div>

                            <div class="d-flex justify-start align-center flex-1 boxStyle ml-3"  style=" height:30px; width:170px">
                                <v-icon color="#23B1A9" class="flex-1 ml-2"  >mdi-text-box</v-icon> 
                                <v-divider vertical inset class="mx-2"></v-divider>
                                <p class="flex-2 mb-0 font-weight-bold" style="font-size:13px">{{ document3 === ''? 'Document 3' : document3 | truncate(10, '...') }}</p>
                            </div>
                            <div class="deleteIcon">
                                <v-icon 
                                color="white" 
                                class="icondel"  
                                small
                                @click="delDocument3(document3)">
                                    mdi-trash-can-outline
                                </v-icon>
                            </div>

                            <div class="d-flex justify-start align-center flex-1 boxStyle ml-3"  style=" height:30px; width:170px">
                                <v-icon color="#23B1A9" class="flex-1 ml-2"  >mdi-text-box</v-icon> 
                                <v-divider vertical inset class="mx-2"></v-divider>
                                <p class="flex-2 mb-0 font-weight-bold" style="font-size:13px">{{ document4 === ''? 'Document 4' : document4 | truncate(10, '...') }}</p>
                            </div>
                                <div class="deleteIcon">
                                    <v-icon 
                                    color="white" 
                                    class="icondel"  
                                    small
                                    @click="delDocument4(document4)">
                                        mdi-trash-can-outline
                                    </v-icon>
                                </div>
                                
                    
                        </div>

                        
                    </v-layout>
                </v-flex>
            </v-layout>       
    
        <v-divider class="mt-4"></v-divider>

            <v-layout class="mt-4">
                    <v-flex xs3>
                        <v-layout  column class=" mx-2 mt-2 cust_pr_quick cust-rmvpd-pr-quick">
                            <p class="font-weight-bold cstm-clr-pr-quick">
                                Agreement Start*
                            </p>
                            
                            <v-menu
                                ref="calender"
                                :close-on-content-click="false"
                                v-model="agreementStartCalenderDateFrom"
                                :nudge-right="40"
                                lazy
                                transition="scale-transition"
                                full-width
                                max-width="290px"
                                min-width="290px" 
                                class="rounded-0"
                                >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-bind="attrs"
                                        v-on="on"
                                        label="dd/mm/yyyy"
                                        :value="computedagreementStart"
                                        solo
                                        class="rounded-0"
                                        readonly
                                        :error-messages="agreementStartErrors"
                                        @input="$v.agreementStartFrom.$touch()"
                                        @blur="$v.agreementStartFrom.$touch()" >
                                <div  slot="prepend-inner" style="background-color:#F7F7F7;  padding: 8px !important"><span class="material-icons">date_range</span></div>
                                    
                                    </v-text-field>
                                </template>                        
                                        <v-date-picker
                                            v-model="agreementStartFrom" 
                                            @input="agreementStartCalenderDateFrom = false"
                                            no-title
                                        ></v-date-picker>
                            </v-menu>


                        </v-layout>
                    </v-flex>


                    <v-flex xs3>
                        <v-layout column class=" mx-2 mt-2 cust_pr_quick cust-rmvpd-pr-quick">
                            <p class="font-weight-bold cstm-clr-pr-quick">
                                Agreement End*
                            </p>
                        
                            <v-menu
                                ref="calender"
                                :close-on-content-click="false"
                                v-model="agreementEndCalenderDateTo"
                                :nudge-right="40"
                                lazy
                                transition="scale-transition"
                                full-width
                                max-width="290px"
                                min-width="290px" 
                                class="rounded-0"
                                >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        
                                        v-bind="attrs"
                                        v-on="on"
                                        label="dd/mm/yyyy"
                                        :value="computedagreementEnd"
                                        solo
                                        class="rounded-0"
                                        readonly
                                        :error-messages="agreementEndErrors"
                                        @input="$v.agreementEnd.$touch()"
                                        @blur="$v.agreementEnd.$touch()">
                                <div  slot="prepend-inner" style="background-color:#F7F7F7;  padding: 8px !important"><span class="material-icons">date_range</span></div>
                                    </v-text-field>
                                </template>                        
                                        <v-date-picker
                                            v-model="agreementEnd"
                                            @input="agreementEndCalenderDateTo = false"
                                            no-title
                                        ></v-date-picker>
                            </v-menu>


                        </v-layout>
                    </v-flex>

                    <v-flex xs3>
                        <v-layout column class=" mx-2 mt-2 cust_pr_quick cust-rmvpd-pr-quick">
                            <p class="font-weight-bold cstm-clr-pr-quick">
                                Agreement signed Date*
                            </p>
                        
                            <v-menu
                                ref="calender"
                                :close-on-content-click="false"
                                v-model="agreementSignCalenderDateTo"
                                :nudge-right="40"
                                lazy
                                transition="scale-transition"
                                full-width
                                max-width="290px"
                                min-width="290px" 
                                class="rounded-0"
                                >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-bind="attrs"
                                        v-on="on"
                                        label="dd/mm/yyyy"
                                        :value="computedagreementSign"
                                        solo
                                        class="rounded-0"
                                        readonly
                                        :error-messages="agreementSignErrors"
                                        @input="$v.agreementSign.$touch()"
                                        @blur="$v.agreementSign.$touch()">
                                <div  slot="prepend-inner" style="background-color:#F7F7F7;  padding: 8px !important"><span class="material-icons">date_range</span></div>
                                    </v-text-field>
                                </template>                        
                                        <v-date-picker
                                            v-model="agreementSign"
                                            @input="agreementSignCalenderDateTo = false"
                                            no-title
                                        ></v-date-picker>
                            </v-menu>


                        </v-layout>
                    </v-flex>

                    <v-flex xs3>
                    </v-flex>

                    <v-flex xs3>
                    </v-flex>

            </v-layout>

            <v-layout class="mt-4">
                    <v-flex xs3>
                                <v-layout column class="cust_pd mx-2 my-2">
                                    <v-text class="font-weight-bold cstm-clr-pd">
                                    Guarantee Required
                                    </v-text>
                                
                                    <v-select
                                        :items="gauranteeReqItem"
                                        solo
                                        label="Enter..."
                                        v-model="gauranteeReq"
                                        class="rounded-0"
                                        data-test="select"
                                        maxlength="20"
                                        >
                                    </v-select>
                                </v-layout>
                            </v-flex>

                    <v-flex xs3>
                        <v-layout column class=" mx-2 my-2  cust_pr_quick cust-rmvpd-pr-quick">
                            <p class="font-weight-bold cstm-clr-pr-quick">
                                Amount
                            </p>
                            <v-text-field
                                autocomplete = "off"
                                :disabled = "guaranteeReqIf"
                                solo
                                label="Enter..."
                                v-model="amount"
                                class="rounded-0"
                                :error-messages="amountErrors"
                                @input="$v.amount.$touch()"
                                @blur="$v.amount.$touch()"
                                maxlength=20
                                >
                                <div slot="prepend-inner" style="background-color:#F7F7F7;padding: 9px !important"><v-icon class="material-icons">mdi-currency-inr</v-icon></div>
                            </v-text-field>
                        </v-layout>
                    </v-flex>

                    <v-flex xs3>
                        <v-layout column class=" mx-2 my-2  cust_pr_quick  cust-rmvpd-psins_quick">
                            <p class="font-weight-bold cstm-clr-pr-quick">
                                Banker Name
                            </p>
                            <v-text-field
                                autocomplete = "off"
                                :disabled = "guaranteeReqIfBank"
                                solo
                                label="Enter..."
                                v-model="bankerName"
                                class="rounded-0"
                                :error-messages="bankerNameErrors"
                                @input="$v.bankerName.$touch()"
                                @blur="$v.bankerName.$touch()"
                                maxlength=100
                                >
                            </v-text-field>
                        </v-layout>
                    </v-flex>


                    <v-flex xs3>
                        <v-layout column class=" mx-2 mt-2 cust_pr_quick cust-rmvpd-pr-quick">
                            <p class="font-weight-bold cstm-clr-pr-quick">
                                Expiry Date
                            </p>
                        
                            <v-menu
                                ref="calender"
                                :close-on-content-click="false"
                                v-model="expiryCalenderDate"
                                :nudge-right="40"
                                lazy
                                transition="scale-transition"
                                full-width
                                max-width="290px"
                                min-width="290px" 
                                class="rounded-0"
                                >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        :disabled = "guaranteeReqIfBank"
                                        v-bind="attrs"
                                        v-on="on"
                                        label="dd/mm/yyyy"
                                        :value="computedexpiryDate"
                                        solo
                                        class="rounded-0"
                                        readonly
                                        :error-messages="expiryDateErrors"
                                        @input="$v.expiryDate.$touch()"
                                        @blur="$v.expiryDate.$touch()">
                                <div  slot="prepend-inner" style="background-color:#F7F7F7;  padding: 8px !important"><span class="material-icons">date_range</span></div>
                                    </v-text-field>
                                </template>                        
                                        <v-date-picker
                                            v-model="expiryDate"
                                            @input="expiryCalenderDate = false"
                                            no-title
                                        ></v-date-picker>
                            </v-menu>


                        </v-layout>
                    </v-flex>

                    <v-flex xs3></v-flex>

            </v-layout>

            <div class="mx-2 pa-5" style="background-color: #F7F7F7; display:flex;justify-content:space-between;"  >
                    <v-layout style="flex:1" justify-end > 
                        <v-btn dark tile color="#E46A25" class="text-capitalize px-3 ml-3" small @click="cancelData()"><v-icon dark small>cancel</v-icon> <v-divider vertical class="mx-2"></v-divider>Close</v-btn>
                        <v-btn :dark = "!disSaveContract" tile color="#23B1A9" class="text-capitalize px-3 ml-3 testing" small :disabled="disSaveContract" @click="submitContractDetails('Approval')"><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider>Save Contract</v-btn>
                    </v-layout>
                </div>
        </v-form> 
        </div>
   
</v-app>


<!-- Provider Empanelment Renewal Approval -->
<v-app v-else>
     <v-form ref="renform">
        <div style="background-color: #FFFFFF;" column class="py-3">

            <v-layout class=" mt-2" >
                        <v-flex xs3>
                            <v-layout column class="mx-2 my-2">
                                <v-text class="text-uppercase font-weight-bold title-clr-pr-ACD">
                                    Provider Renewal Approval
                                </v-text>
                                <v-divider></v-divider>
                            </v-layout>
                        </v-flex>


                        <v-flex xs5>
                            <v-layout column class="cust_pd mx-2 my-2 ">
                                <v-text class="font-weight-bold cstm-clr-pd">
                                    Approver Remarks
                                </v-text>
                            
                                <v-text-field
                                    :disabled="onSendMouDisable"
                                    solo
                                    label="Enter..."
                                    v-model="approverRemarksRen"
                                    :rules="[approverRules(approverRemarksRen)]"
                                    class="rounded-0"
                                    maxlength="200"
                                    dense
                                    >
                                </v-text-field>
                            </v-layout>
                        </v-flex>

                        
                        <v-flex xs3>
                            <v-layout column class="cust_pd mx-2 my-2 testingStatus">
                                <v-text class="font-weight-bold cstm-clr-pd">
                                   Provider Empanelment Renewal Approval *
                                </v-text>
                            
                                <v-select
                                    :disabled="onSendMouDisable"
                                    :items="empanelmentApproverRenItem"
                                    solo
                                    label="Enter..."
                                    v-model="empanelmentApproverRen"
                                    class="rounded-0 testStatusValue"
                                    data-test="select"
                                    maxlength="10"
                                    :rules="[v => !!v || 'This field is required']"
                                    >
                                </v-select>
                            </v-layout>
                        </v-flex>



                        <v-flex xs3></v-flex>
                    
                                                    
                    </v-layout>

                    <v-layout class=" mt-2" >

                        
                        <v-flex xs5>
                                <v-layout column class="cust_pd mx-2 my-2 ">
                                    <v-text class="font-weight-bold cstm-clr-pd">
                                        Reason *
                                    </v-text>
                                
                                    <v-text-field
                                        :disabled="onSendMouDisable"
                                        solo
                                        label="Enter..."
                                        v-model="reasonRen"
                                        class="rounded-0"
                                        maxlength="200"
                                        :rules="reasonRenRules"
                                        >
                                    </v-text-field>
                                </v-layout>
                            </v-flex>

                        <v-flex xs3>
                            <v-layout column class="cust_pd mx-2 my-2 testingStatus">
                                <v-text class="font-weight-bold cstm-clr-pd">
                                   Is extension required?
                                </v-text>
                            
                                <v-select
                                    :disabled="onSendMouDisable"
                                    :items="isExtensionItem"
                                    solo
                                    label="Enter..."
                                    v-model="isExtension"
                                    class="rounded-0 testStatusValue"
                                    data-test="select"
                                    maxlength="3"
                                   
                                    >
                                </v-select>
                            </v-layout>
                        </v-flex>

                        <v-flex xs3>
                            <v-layout column class=" mx-2 mt-2 cust_pr_quick cust-rmvpd-pr-quick">
                                <p class="font-weight-bold cstm-clr-pr-quick">
                                    Extension Date
                                </p>
                            
                                <v-menu
                                    ref="calender"
                                    :close-on-content-click="false"
                                    v-model="extensionCalenderDate"
                                    :nudge-right="40"
                                    lazy
                                    transition="scale-transition"
                                    full-width
                                    max-width="290px"
                                    min-width="290px" 
                                    class="rounded-0"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            :disabled = "extensionReqIf"
                                            v-bind="attrs"
                                            v-on="on"
                                            label="dd/mm/yyyy"
                                            :value="computedextensionDate"
                                            solo
                                            class="rounded-0"
                                            readonly
                                            :rules="extensionDateRules"
                                            >
                                    <div  slot="prepend-inner" style="background-color:#F7F7F7;  padding: 8px !important"><span class="material-icons">date_range</span></div>
                                        </v-text-field>
                                    </template>                        
                                            <v-date-picker
                                                v-model="extensionDate"
                                                @input="extensionCalenderDate = false"
                                                no-title
                                            ></v-date-picker>
                                </v-menu>


                            </v-layout>
                        </v-flex>

                        <v-flex xs3>
                            
                        </v-flex>

                    </v-layout>

                   <v-dialog v-model="confirmDialogCancel" persistent max-width="450px">
                 
                         <v-card tile color="#F7F7F7" >
                            <v-card tile>
                                <v-layout justify-space-between  class="" style="flex:1;background-ox-shadow: 0px 3px 5px #00000029;">
                             
                                    <v-card-title class="pa-0 pl-3 text-capitalize font-weight-regular" style="color:black"> 
                                       CONFIRMATION
                                    </v-card-title>
                                    <div style="border-left:1px solid #C1C8CC;" @click="close_cancelDialog()">
                                        <v-icon style="cursor: pointer;" class="pa-2" >close</v-icon>
                                    </div>
                                </v-layout>
                            </v-card>
                        <v-flex >
                           
                       
                        </v-flex>
                        <v-card-text >
                                Details saved on screen will be lost
                        </v-card-text>
                        <v-card-actions justify-start>
                          
                           
                           
                            <v-btn dark  tile color="black" @click="confirmDialogCancel = false" class="text-capitalize px-3 mx-2" small><v-icon dark small>cancel</v-icon> <v-divider vertical class="mx-2"></v-divider>Cancel</v-btn>

                            <!-- <v-btn depressed small color="secondary" class="rounded-0"  >
                                <v-icon dark right>check_circle</v-icon>
                            </v-btn> -->
                            <v-btn @click="okClick" dark  tile color="#23B1A9" class="text-capitalize px-3 ml-2" small><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider> OK</v-btn>

                        </v-card-actions>
                    </v-card>
                </v-dialog>

                
            <div class="mx-2 pa-5" style="background-color: #F7F7F7; display:flex;justify-content:space-between;"  >
                <v-layout style="flex:1" justify-end > 
                    <v-btn :dark="!onSendMouDisable" :disabled="onSendMouDisable"  tile color="black" class="text-capitalize px-3 ml-3" small @click="sendBackFormRen"><v-icon dark small>mdi-arrow-left</v-icon> <v-divider vertical class="mx-2"></v-divider>Send Back</v-btn>
                    <v-btn :dark="!onSendMouDisable" :disabled="onSendMouDisable"  tile color="#E46A25" class="text-capitalize px-3 ml-3" small @click="cancelData()"><v-icon dark small >cancel</v-icon> <v-divider vertical class="mx-2"></v-divider>Close</v-btn>
                    <v-btn :dark="!onSendMouDisable" :disabled="onSendMouDisable"  tile color="#23B1A9" class="text-capitalize px-3 ml-3 testing" small @click="submitRenewalDetails"><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider>Submit</v-btn>
                    <v-btn :dark="!onSendMouDisable" :disabled="onSendMouDisable"  tile color="green" class="text-capitalize px-3 ml-3 testing" v-if='enableGenerate === true' small @click="generatemou" ><v-icon dark small>add</v-icon> <v-divider vertical class="mx-2"></v-divider>Generate MoU</v-btn>
                    <!-- :dark="!onSendMouDisable" :disabled="onSendMouDisable" -->
                       <v-btn :dark="!onSendMouDisable" :disabled="onSendMouDisable"  tile color="green" class="text-capitalize px-3 ml-3 testing"
                           v-if='aftergenmou' small @click="sendmou" >
                           <v-icon dark small>send</v-icon>
                    <v-divider vertical class="mx-2"></v-divider>Send MoU</v-btn>
                    <v-btn  tile dark
                     color="green" class="text-capitalize px-3 ml-3 testing" v-if ='enableGenerate === true'
                     small @click="generateAden(1)" ><v-icon dark small>add</v-icon>
                     <v-divider vertical class="mx-2"></v-divider>Upload Addendum</v-btn>
                        <!-- <pre>{{enable_send_adendum}}</pre> -->
                     <v-btn  tile  :dark="!enable_send_adendum" :disabled="enable_send_adendum"
                     color="green" class="text-capitalize px-3 ml-3 testing" v-if ='enableGenerate === true'
                     small @click="nowfinalsend" ><v-icon dark small>add</v-icon>
                     <v-divider vertical class="mx-2"></v-divider>Send Addendum</v-btn>
                     
                 

                    
                </v-layout>
            </div>


            
        </div>
     </v-form>

   <!-- CONTRACT DETAILS For Renewal Emapnelment  -->
    
    <div style="background-color: #FFFFFF;" column class="py-3" v-if='approvalContractDetails === true'>
       

        <v-layout>
        
                <v-flex xs2>
                    <v-layout column class="mx-2 my-2">
                        <v-text class="text-uppercase font-weight-bold title-clr-pr-ACD">
                            CONTRACT DETAILS
                        </v-text>
                        <v-divider></v-divider>
                    </v-layout>
                </v-flex>


                <!-- Agreement Type -->
                <v-flex xs3>
                    <v-layout column class=" mx-2 my-3 cust_pr_quick cust-rmvpd-pr-quick">
                        <v-text class="font-weight-bold cstm-clr-pr-quick">
                                Agreement Type
                            </v-text>
                        <v-layout  style="background-color: #F7F7F7; height: 40px; border: solid 0.5px #dae1e6;"  >
                            <v-radio-group   class="mt1 ml-2 pa-0 d-flex align-self-center" dense  v-model="agreementType" row >
                                <v-radio color = "#23b1a9"  @click="agreementTypeBtn" class="radioLabel"  label="Fresh" value="Fresh" ></v-radio>
                                <v-radio color = "#23b1a9"  @click="agreementTypeBtn" class="radioLabel" label="Addendum" value="Addendum" ></v-radio>
                            </v-radio-group> 

                        </v-layout>
                    </v-layout>
                </v-flex>

                <!-- Upload Signed MoU -->
                <v-flex xs5 class="mx-2">
                    <v-layout column class="cust_pd" @click="uploadFileThClick">
                        <v-text class="font-weight-bold cstm-clr-pd" style="color:black !important">
                            Upload Signed MoU
                        </v-text>
                    
                    <v-card class="d-flex rounded-0 align-center" style="height:50px;" @dragover.prevent @drop="dropRenImage">
                        <v-icon v-if="imageUploaded === true" class="iconSizeRenewal" thin color="#b8b2b2">mdi-cloud-upload-outline</v-icon>
                        <svg v-if="imageUploaded === true" viewBox="0 0 80 80" xmlns="http://www.w3.org/2000/svg" style="width:150px">
                            
                                <rect class="dashedRenewel" x="1" y="32" width="74" height="16" />
                                <text x="30" y="42" class="smalltextRenewal" >Drag image here</text>
                        </svg>
                        <v-card-text v-if="imageUploaded === false" >
                            {{nameForRenewal | truncate(10, '...')}}
                        </v-card-text>
                        <input  :type="typeOfFile" 
                                style="display:none"
                                accept="/*" 
                                ref="uploadThClick"
                                @change="renSelectedFile">
                            <v-btn 
                                dark tile 
                                color="#E46A25" 
                                class="text-capitalize" 
                                style="margin-right:12px" small
                                @click="uploadRenDocument">
                                <v-icon dark small>
                                    cloud_upload
                                </v-icon> 
                                <v-divider vertical class="mx-2"></v-divider>
                                    Upload
                            </v-btn>
                    
                        <div class="d-flex justify-start align-center flex-1 boxStyle" >
                            <v-icon color="#23B1A9" class="flex-1 ml-2"  >mdi-text-box</v-icon> 
                            <v-divider vertical inset class="mx-2"></v-divider>
                            <p class="flex-2 mb-0 font-weight-bold" style="font-size:13px">{{ renDocument1 === ''? 'Document 1' : renDocument1 | truncate(10, '...') }}</p>
                            
                        </div>
                        <div class="deleteIcon"  style="margin-right:20px">
                            <v-icon color="white" class="icondel"  small right @click="delDocumentRen" >mdi-trash-can-outline</v-icon>
                        </div>
                        
                    </v-card>

                    

                    </v-layout>
                </v-flex>

                <!-- New MoU Expiry Date -->
                <v-flex xs3 v-if="agreementType === 'Addendum'">
                        <v-layout column class=" mx-2 mt-2 cust_pr_quick cust-rmvpd-pr-quick">
                            <p class="font-weight-bold cstm-clr-pr-quick">
                                New MoU Expiry Date
                            </p>
                        
                            <v-menu
                                ref="calender"
                                :close-on-content-click="false"
                                v-model="expiryMouCalenderDate"
                                :nudge-right="40"
                                lazy
                                transition="scale-transition"
                                full-width
                                max-width="290px"
                                min-width="290px" 
                                class="rounded-0"
                                >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        
                                        v-bind="attrs"
                                        v-on="on"
                                        label="dd/mm/yyyy"
                                        :value="computedexpiryMouDate"
                                        solo
                                        class="rounded-0"
                                        readonly
                                        :error-messages="expiryMouDateErrors"
                                        @input="$v.expiryMouDate.$touch()"
                                        @blur="$v.expiryMouDate.$touch()">
                                <div  slot="prepend-inner" style="background-color:#F7F7F7;  padding: 8px !important"><span class="material-icons">date_range</span></div>
                                    </v-text-field>
                                </template>                        
                                        <v-date-picker
                                            v-model="expiryMouDate"
                                            @input="expiryMouCalenderDate = false"
                                            no-title
                                        ></v-date-picker>
                            </v-menu>


                        </v-layout>
                </v-flex>

                <!-- Agreement Start -->
                    <v-flex xs3  v-if="agreementType === 'Fresh'">
                        <v-layout  column class=" mx-2 mt-2 cust_pr_quick cust-rmvpd-pr-quick">
                            <p class="font-weight-bold cstm-clr-pr-quick">
                                Agreement Start*
                            </p>
                            
                            <v-menu
                                ref="calender"
                                :close-on-content-click="false"
                                v-model="agreementStartCalenderDateFrom"
                                :nudge-right="40"
                                lazy
                                transition="scale-transition"
                                full-width
                                max-width="290px"
                                min-width="290px" 
                                class="rounded-0"
                                >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-bind="attrs"
                                        v-on="on"
                                        label="dd/mm/yyyy"
                                        :value="computedagreementStart"
                                        solo
                                        class="rounded-0"
                                        readonly
                                        :error-messages="agreementStartErrors"
                                        @input="$v.agreementStartFrom.$touch()"
                                        @blur="$v.agreementStartFrom.$touch()" >
                                <div  slot="prepend-inner" style="background-color:#F7F7F7;  padding: 8px !important"><span class="material-icons">date_range</span></div>
                                    
                                    </v-text-field>
                                </template>                        
                                        <v-date-picker
                                            v-model="agreementStartFrom" 
                                            @input="agreementStartCalenderDateFrom = false"
                                            no-title
                                        ></v-date-picker>
                            </v-menu>


                        </v-layout>
                    </v-flex>

                <v-flex xs2>
                    
                </v-flex>
                
        </v-layout>       
 
 
       
        <v-layout  v-if="agreementType === 'Fresh'">

                <!-- Agreement End -->
                <v-flex xs3 >
                    <v-layout column class=" mx-2 mt-2 cust_pr_quick cust-rmvpd-pr-quick">
                        <p class="font-weight-bold cstm-clr-pr-quick">
                            Agreement End*
                        </p>
                      
                        <v-menu
                            ref="calender"
                            :close-on-content-click="false"
                            v-model="agreementEndCalenderDateTo"
                            :nudge-right="40"
                            lazy
                            transition="scale-transition"
                            full-width
                            max-width="290px"
                            min-width="290px" 
                            class="rounded-0"
                            >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    
                                    v-bind="attrs"
                                    v-on="on"
                                    label="dd/mm/yyyy"
                                    :value="computedagreementEnd"
                                    solo
                                    class="rounded-0"
                                    readonly
                                    :error-messages="agreementEndErrors"
                                    @input="$v.agreementEnd.$touch()"
                                    @blur="$v.agreementEnd.$touch()">
                            <div  slot="prepend-inner" style="background-color:#F7F7F7;  padding: 8px !important"><span class="material-icons">date_range</span></div>
                                </v-text-field>
                            </template>                        
                                    <v-date-picker
                                        v-model="agreementEnd"
                                        @input="agreementEndCalenderDateTo = false"
                                        no-title
                                    ></v-date-picker>
                        </v-menu>


                    </v-layout>
                </v-flex>


                <!-- Agreement signed Date -->
                <v-flex xs3 >
                    <v-layout column class=" mx-2 mt-2 cust_pr_quick cust-rmvpd-pr-quick">
                        <p class="font-weight-bold cstm-clr-pr-quick">
                            Agreement signed Date*
                        </p>
                      
                        <v-menu
                            ref="calender"
                            :close-on-content-click="false"
                            v-model="agreementSignCalenderDateTo"
                            :nudge-right="40"
                            lazy
                            transition="scale-transition"
                            full-width
                            max-width="290px"
                            min-width="290px" 
                            class="rounded-0"
                            >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    
                                    v-bind="attrs"
                                    v-on="on"
                                    label="dd/mm/yyyy"
                                    :value="computedagreementSign"
                                    solo
                                    class="rounded-0"
                                    readonly
                                    :error-messages="agreementSignErrors"
                                    @input="$v.agreementSign.$touch()"
                                    @blur="$v.agreementSign.$touch()">
                            <div  slot="prepend-inner" style="background-color:#F7F7F7;  padding: 8px !important"><span class="material-icons">date_range</span></div>
                                </v-text-field>
                            </template>                        
                                    <v-date-picker
                                        v-model="agreementSign"
                                        @input="agreementSignCalenderDateTo = false"
                                        no-title
                                    ></v-date-picker>
                        </v-menu>


                    </v-layout>
                </v-flex>


                <!-- Guarantee Required    -->
                <v-flex xs3>
                    <v-layout column class="cust_pd mx-2 my-2">
                        <v-text class="font-weight-bold cstm-clr-pd">
                        Guarantee Required
                        </v-text>
                    
                        <v-select
                            :items="gauranteeReqItem"
                            solo
                            label="Enter..."
                            v-model="gauranteeReq"
                            class="rounded-0"
                            data-test="select"
                            maxlength="20"
                            >
                        </v-select>
                    </v-layout>
                </v-flex>


                <!-- Amount -->
                <v-flex xs3>
                    <v-layout column class=" mx-2 my-2  cust_pr_quick cust-rmvpd-pr-quick">
                        <p class="font-weight-bold cstm-clr-pr-quick">
                            Amount
                        </p>
                        <v-text-field
                            autocomplete = "off"
                            :disabled = "guaranteeReqIf"
                            
                            solo
                            label="Enter..."
                            v-model="amount"
                            class="rounded-0"
                            :error-messages="amountErrors"
                            @input="$v.amount.$touch()"
                            @blur="$v.amount.$touch()"
                            maxlength=20
                            >
                            <div slot="prepend-inner" style="background-color:#F7F7F7;padding: 9px !important"><v-icon class="material-icons">mdi-currency-inr</v-icon></div>
                        </v-text-field>
                    </v-layout>
                </v-flex>


                <!-- Banker Name -->
                <v-flex xs3 >
                    <v-layout column  class=" mx-2 my-2  cust_pr_quick  cust-rmvpd-psins_quick">
                        <p class="font-weight-bold cstm-clr-pr-quick">
                            Banker Name
                        </p>
                        <v-text-field
                            autocomplete = "off"
                            :disabled = "guaranteeReqIfBank"
                            solo
                            label="Enter..."
                            v-model="bankerName"
                            class="rounded-0"
                            :error-messages="bankerNameErrors"
                            @input="$v.bankerName.$touch()"
                            @blur="$v.bankerName.$touch()"
                            maxlength=100
                            >
                        </v-text-field>
                    </v-layout>
                </v-flex>
 
        </v-layout>


        <v-layout v-if="agreementType === 'Fresh'">

                <v-flex xs3>
                    <v-layout column class=" mx-2 mt-2 cust_pr_quick cust-rmvpd-pr-quick">
                        <p class="font-weight-bold cstm-clr-pr-quick">
                            Expiry Date
                        </p>
                      
                        <v-menu
                            ref="calender"
                            :close-on-content-click="false"
                            v-model="expiryCalenderDate"
                            :nudge-right="40"
                            lazy
                            transition="scale-transition"
                            full-width
                            max-width="290px"
                            min-width="290px" 
                            class="rounded-0"
                            >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    :disabled = "guaranteeReqIfBank"
                                    v-bind="attrs"
                                    v-on="on"
                                    label="dd/mm/yyyy"
                                    :value="computedexpiryDate"
                                    solo
                                    class="rounded-0"
                                    readonly
                                    :error-messages="expiryDateErrors"
                                    @input="$v.expiryDate.$touch()"
                                    @blur="$v.expiryDate.$touch()">
                            <div  slot="prepend-inner" style="background-color:#F7F7F7;  padding: 8px !important"><span class="material-icons">date_range</span></div>
                                </v-text-field>
                            </template>                        
                                    <v-date-picker
                                        v-model="expiryDate"
                                        @input="expiryCalenderDate = false"
                                        no-title
                                    ></v-date-picker>
                        </v-menu>


                    </v-layout>
                </v-flex>

                <v-flex xs3></v-flex>
                <v-flex xs3></v-flex>
                <v-flex xs3></v-flex>
                <v-flex xs3></v-flex>


        </v-layout>

        <v-layout  v-if="agreementType === 'Addendum'">
                
                <!-- Remarks -->
                 <v-flex xs6 v-if="agreementType === 'Addendum'">
                    <v-layout column class=" mx-2 my-2  cust_pr_quick  cust-rmvpd-psins_quick">
                        <p class="font-weight-bold cstm-clr-pr-quick">
                            Remarks
                        </p>
                        <v-text-field
                            autocomplete = "off"
                            
                            solo
                            label="Enter..."
                            v-model="remarksAddendum"
                            class="rounded-0"
                            :error-messages="remarksAddendumErrors"
                            @input="$v.remarksAddendum.$touch()"
                            @blur="$v.remarksAddendum.$touch()"
                            maxlength=100
                            >
                        </v-text-field>
                    </v-layout>
                </v-flex>

                <v-flex xs3></v-flex>
                <v-flex xs3></v-flex>
                <v-flex xs3></v-flex>

        </v-layout>

          <div class="mx-2 pa-5" style="background-color: #F7F7F7; display:flex;justify-content:space-between;"  >
                <v-layout style="flex:1" justify-end > 
                    <v-btn dark tile color="#E46A25" class="text-capitalize px-3 ml-3" small @click="cancelData()"><v-icon dark small>cancel</v-icon> <v-divider vertical class="mx-2"></v-divider>Close</v-btn>
                    <v-btn :dark = "!disSaveContract" tile color="#23B1A9" class="text-capitalize px-3 ml-3 testing" small :disabled="disSaveContract" @click="submitContractDetails('Renewal')"><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider>Save Contract</v-btn>
                </v-layout>
            </div>
    </div>

     <div>

         <!-- addendum -->
    <div v-if="enable_Addendumpopup">
        <!-- @closedoc=""  enable_Addendumpopup = false -->
                <updoc 
                    @closedoc="enable_Addendumpopup = false"    
                    @getdocslist="send_addensum" 
                    :setdoc="{docen:enable_Addendumpopup,
                    provider_data:$store.state.empanelProviderData.empanelment,
                    type:'edit', updocs:[],from:'addendum'  }" >
                </updoc>
    </div>
         
            <v-dialog v-model="genmou" persistent max-width="560px">
                        <v-card color="#F7F7F7" class="pb-2" tile max-height="560px" style="overflow-y: scroll;" >
                         
                            <v-card tile class="fixh" >
                                <v-layout justify-space-between  class="pa-1" style="flex:1;background-ox-shadow: 0px 3px 5px #00000029;">
                                    <v-layout align-center>
                                        <v-card-title class="pa-0 pl-3 text-capitalize font-weight-bold"
                                        style="color:#10242B;font-size:13px">Generate & Edit MOU</v-card-title>   
                                    </v-layout>
                                     <!-- <v-layout align-center>
                                          <v-btn @click="openpdf" :dark="aftergenmou" :disabled="!aftergenmou"  tile style="background-color:#23B1A9;" class="text-capitalize px-3 ml-2" small><v-icon dark small>chrome_reader_mode</v-icon>
                            <v-divider vertical class="mx-2"></v-divider>Open MOU PDF</v-btn> -->
                                        <!-- <v-card-title class="pa-0 pl-3 text-capitalize font-weight-bold"
                                        style="color:#10242B;font-size:13px">Generate & Edit MOU</v-card-title>    -->
                                    <!-- </v-layout> -->
                                    <div style="border-left:1px solid #C1C8CC;" @click="genmou = false">
                                        <v-icon style="cursor: pointer;" class="pa-2" >close</v-icon>
                                    </div>
                                </v-layout>
                            </v-card>
                            <div class="pa-5 mt-10" >
                                 <v-card color="#fff" class="pb-2 pa-4 ma-2" tile >
                            <v-form ref="themouform" >

                           
                                 <v-flex >
                                    <v-layout column class="cust_pr mx-2 ">
                                        <p class="font-weight-bold cstm-clr-pr">
                                           Provider Name
                                        </p>
                                        <v-text-field
                                           readonly
                                            solo
                                            label="Enter..."
                                            v-model="pname"
                                            class="rounded-0"
                                        >
                                           
                                        </v-text-field>
                                    </v-layout>
                                </v-flex>

                                <v-flex class="my-4" >
                                    <v-layout column class="cust_pr mx-2 ">
                                        <p class="font-weight-bold cstm-clr-pr">
                                          Address
                                        </p>
                                        <v-text-field
                                           readonly
                                            solo
                                            label="Enter..."
                                            v-model="addressis"
                                            class="rounded-0"
                                        >
                                           
                                        </v-text-field>
                                    </v-layout>
                                </v-flex>

                                <v-flex class="my-4" >
                                    <v-layout column class="cust_pr mx-2 ">
                                        <p class="font-weight-bold cstm-clr-pr">
                                          Office at
                                        </p>
                                        <v-text-field
                                           readonly
                                            solo
                                            label="Enter..."
                                            v-model="officeat"
                                            class="rounded-0"
                                        >
                                           
                                        </v-text-field>
                                    </v-layout>
                                </v-flex>

                                <!-- <v-flex class="my-4" v-if="false" >
                                    <v-layout column class="cust_pr mx-2 ">
                                        <p class="font-weight-bold cstm-clr-pr">
                                        Agreement effective date
                                        </p>
                                        <v-text-field 
                                            solo
                                            label="Enter..."
                                            v-model="aed"
                                            class="rounded-0">
                                           
                                        </v-text-field>
                                    </v-layout>
                                </v-flex> -->

                                <v-flex class="my-4">
                                    <v-layout column class=" mx-2 mt-2 cust_pr_quick cust-rmvpd-pr-quick">
                                        <p class="font-weight-bold cstm-clr-pr-quick">
                                            Agreement effective date
                                        </p>
                                    
                                        <v-menu
                                            ref="calender"
                                            :close-on-content-click="false"
                                            v-model="aedCalenderDate"
                                            :nudge-right="40"
                                            lazy
                                            transition="scale-transition"
                                            full-width
                                            max-width="290px"
                                            min-width="290px" 
                                            class="rounded-0"
                                            >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    label="dd/mm/yyyy"
                                                    :value="computedaed"
                                                    :rules="[requiredis()]"
                                                    solo
                                                    class="rounded-0"
                                                    readonly
                                                    >
                                            <div  slot="prepend-inner" style="background-color:#F7F7F7;  padding: 8px !important"><span class="material-icons">date_range</span></div>
                                                </v-text-field>
                                            </template>                        
                                                    <v-date-picker
                                                        v-model="aed"
                                                        @input="aedCalenderDate = false"
                                                        no-title
                                                    ></v-date-picker>
                                        </v-menu>


                                    </v-layout>
                                </v-flex>

                                 <v-flex class="my-4" >
                                    <v-layout column class="cust_pr mx-2 ">
                                        <p class="font-weight-bold cstm-clr-pr">
                                        Discount
                                        </p>
                                        <v-text-field
                                            maxlength="3"
                                            :rules="[setnumeric(discountis), requiredis()]"
                                            solo
                                            label="Enter..."
                                            v-model="discountis"
                                            class="rounded-0"
                                        >
                                           
                                        </v-text-field>
                                    </v-layout>
                                </v-flex>

                            <v-flex class="my-4" >
                                    <v-layout column class="cust_pr mx-2 ">
                                        <p class="font-weight-bold cstm-clr-pr">
                                         Insurer Attn: Mr.
                                        </p>
                                        <v-text-field
                                           maxlength="100"
                                            solo
                                            label="Enter..."
                                            v-model="insat"
                                            class="rounded-0"
                                             :rules="[spclChar(insat), requiredis()]"
                                        >
                                           
                                        </v-text-field>
                                    </v-layout>
                            </v-flex>
                               
                             <v-flex class="my-4" >
                                    <v-layout column class="cust_pr mx-2 ">
                                        <p class="font-weight-bold cstm-clr-pr">
                                        PAN
                                        </p>
                                        <v-text-field
                                           
                                            solo
                                            label="Enter..."
                                            v-model="thepan"
                                            class="rounded-0"
                                        >
                                           
                                        </v-text-field>
                                    </v-layout>
                            </v-flex>

                            <v-flex class="my-4" >
                                    <v-layout column class="cust_pr mx-2 ">
                                        <p class="font-weight-bold cstm-clr-pr">
                                         Hospital Attn.:
                                        </p>
                                        <v-text-field
                                            maxlength="100"
                                            solo
                                            label="Enter..."
                                            v-model="hosat"
                                            class="rounded-0"
                                            :rules="[spclChar(hosat),requiredis()]"
                                        > 
                                        </v-text-field>
                                    </v-layout>
                            </v-flex>

                            <v-flex class="my-4" >
                                    <v-layout column class="cust_pr mx-2 ">
                                        <p class="font-weight-bold cstm-clr-pr">
                                       Additional Clauses
                                        </p>
           
                                        <v-textarea
                                         filled
                                         label="Enter Additional Clauses"
                                        solo
                                        v-model="adc"
                                        class="rounded-0"
                                        > 
                                        </v-textarea>
                                    </v-layout>
                            </v-flex>
                         </v-form>
                           <v-layout style="justify-content:center;" >
                            <v-btn @click="postmou" dark  tile color="#23B1A9" class="text-capitalize px-3 ml-2" small><v-icon dark small>check_circle</v-icon>
                            <v-divider vertical class="mx-2"></v-divider>Submit</v-btn>

                            <v-btn @click="clearallis" dark  tile color="red" class="text-capitalize px-3 ml-2" small><v-icon dark small>delete</v-icon>
                            <v-divider vertical class="mx-2"></v-divider>Clear</v-btn>

                            <v-btn @click="openpdf" :dark="aftergenmou" :disabled="!aftergenmou"  tile style="background-color:#23B1A9;" class="text-capitalize px-3 ml-2" small><v-icon dark small>chrome_reader_mode</v-icon>
                            <v-divider vertical class="mx-2"></v-divider>Open MOU PDF</v-btn>
                        </v-layout>
                         </v-card>
                      
                           </div>
                        </v-card>
                   
            </v-dialog>
     </div>

     

    <div v-if="docen">
                <docup 
                    @closedoc="docen = false"    
                    :setdoc="{docen:docen,provider_data:$store.state.empanelProviderData.empanelment,
                    type:'new', updocs:[],from:'emp' }" >
                </docup>
    </div>
        
</v-app>


</div>
</template>

<script>
import { required,numeric,helpers} from 'vuelidate/lib/validators';
import moment from 'moment';
import docup from './viewdocument.vue';
import updoc from '../ProviderRegistrationClaims/docup.vue'

export const nameChecker = helpers.regex('telenumber',  /^[a-zA-Z ]+$/);

export default {
    inject : ['selectedCompFromPage', 'arrToNavCancel'],
    components:{
        docup, updoc
    },
    created(){
        this.$store.commit("SAVE_PAGE_HEADER", "Provider Approval and Contract Details");
        
         console.log("%c this is the fresh or addendum value","background-color:blue;color:black",this.agreementType);
        //  console.log("%c this is data from store","background-color:#d1171b;color:darkblue",this.$store.state.empanelProviderData.empanelment.empanelmentType);
            // this.empanelmentValue = this.$store.state.empanelProviderData.empanelment.empanelmentType;
            // console.log("%c this is from params","background-color:#d1121b",this.$route.params.empanelmentType)
            console.log("%c this.$store.state.empanelProviderData","background-color:#d1121b",this.$store.state.empanelProviderData)
            this.empanelmentTypeIs = this.$store.state.empanelProviderData.empanelment.empanelmentType
            if(Object.keys(this.$store.state.empanelProviderData).length != 0){
                if(this.$route.params.empanelmentType){
                    console.log("%c this is from params","background-color:#01b4bb",this.$route.params.empanelmentType)
                    console.log("came here from params click",this.$route.params);
                    this.routeData = this.$route.params.allData
                    if(this.$route.params.type === "Provider Approval"){
                        console.log("%c to enable approval and contract details","background-color:#fee71f;color:black");
                        this.approvalContractDetails = true
                        this.approvalFormDetails()
                    }
                    // console.log("%c this is not from params","background-color:#01b4bb",this.$route.params.empanelmentType)
                    this.empanelmentValue = this.$route.params.empanelmentType;
                    this.arrToNavCancel.push('ProviderEmpanelmentMain')
                }else{
                    console.log("came here from tabs click");
                    console.log("%c this.$store.state.empanelProviderData","background-color:#a2485f",this.$store.state.empanelProviderData.empanelment.empanelmentType)
                    this.empanelmentValue = this.$store.state.empanelProviderData.empanelment.empanelmentType
                }
                
            }else{
                 console.log("%c this is not from params","background-color:#01b4bb",this.$route.params.empanelmentType)
                this.empanelmentValue = this.$store.state.empanelProviderData.empanelment.empanelmentType;
            }
            let userID = this.$store.state.empanelProviderData.empanelment._id;
            if(this.$store.state.empanelmentTabStatus.providerReviewStatus === true){
                console.log("the providerReviewStatus is true");
                this.getApprovalDetailsById(userID)
            }

            // this.setdataformou()
            
            console.log("this.$route.params", this.$route.params)
    },
    data(){
        return {
            empanelmentTypeIs:'',

           requiredis(){ return v=>v && v.length>0 || "This Field is Required"},
           adden_data:null,
            enable_send_adendum:true,
            selected_pdffile:null,
            enable_Addendumpopup:false,
            getdataby_id:{},
            onSendMouDisable:false,
             spclChar(v){
                    if(v.length == 0)return
                    return v=>/^[A-Za-z0-9., ]+$/.test(v) || `Special Characters Not Allowed`
            },
            providerids:{ID:'', _id:''},
            pdfdata:'',
            pname:'',
            officeat:'',
            aed:'',
            discountis:'',
            addressis:'',
            thepan:'',
            hosat:'',
            insat:'',
            adc:'',
            genmou:false,
            aftergenmou:false,
            aedCalenderDate:false,

                imageUploaded:true,
                approvalContractDetails:false,
                confirmDialogCancel:false,
                docen:false,
                enableGenerate:false,
                empanelmentValue:'',
                approverRemarks:"",   
                empanelmentApproverItem:['Approved', 'Rejected'],
                empanelmentApprover: '',
                reason:'',
                reasonRules:[
                    v => !!v || 'This field is required',
                    v => /^[ a-zA-Z0-9!@#'":;\&?*\/\\)\(+=,.-]+$/g.test(v) || 'Only alphabets are allowed'
                    ],
                agreementStartFrom:'',
                agreementStartCalenderDateFrom:false,
                agreementEnd:'',
                agreementEndCalenderDateTo:false,
                agreementSign:'',
                agreementSignCalenderDateTo:false,
                gauranteeReqItem:['Bank Guarantee','Advance Payment', 'No'],
                gauranteeReq:'',
                amount:'',
                bankerName:'',
                expiryDate:'',
                expiryCalenderDate:false,
                guaranteeReqIf:true,
                guaranteeReqIfBank:true,
                extensionReqIf:true,


                empanelmentApproverRenItem:['Approved', 'Rejected'],
                empanelmentApproverRen:'',
                approverRemarksRen:'',
                isExtensionItem:['Yes','No'],
                isExtension:'',
                reasonRen:'',
                until100:[],
                reasonRenRules:[
                    v => !!v || 'This field is required',
                    v => /^[ a-zA-Z0-9!@#'":;\&?*\/\\)\(+=,.-]+$/g.test(v) || 'Only alphabets are allowed'
                    ],
                extensionDate:'',
                extensionCalenderDate:false,
                expiryMouDate:'',
                expiryMouCalenderDate:false,

                agreementType:'Fresh',
                filesArr:[],
                fileUrlArr:[],
                document1:'',
                document2:'',
                document3:'',
                document4:'',
                renDocument1:'',
                nameForRenewal:'',
                typeOfFile:'file',
                fileSelectedToUpload:'',
                currentDate: new Date().toISOString().substr(0, 10),
                disSaveContract:true,
                disableIsExtensionDate:false,

                routeData:''


        }
    },

    

    validations:{
            agreementStartFrom:{required},
            agreementEnd:{required},
            agreementSign:{required},
            amount:{required,numeric},
            bankerName:{required,nameChecker},
            expiryDate:{required},
            expiryMouDate:{required}
    },

    computed:{
        

        computedagreementStart(){
            console.log(this.agreementStartFrom)
            console.log(this.agreementStartFrom ? moment(this.agreementStartFrom).format("DD-MM-YYYY"): '')
            return this.agreementStartFrom ? moment(this.agreementStartFrom).format("DD-MM-YYYY"): ''
        },
         computedagreementEnd(){
            console.log(this.agreementEnd)
            console.log(this.agreementEnd ? moment(this.agreementEnd).format("DD-MM-YYYY"): '')
            return this.agreementEnd ? moment(this.agreementEnd).format("DD-MM-YYYY"): ''
        },

        computedagreementSign(){
            console.log(this.agreementSign)
            console.log(this.agreementSign ? moment(this.agreementSign).format("DD-MM-YYYY"): '')
            return this.agreementSign ? moment(this.agreementSign).format("DD-MM-YYYY"): ''
        },

        computedexpiryDate(){
            console.log(this.expiryDate)
            console.log(this.expiryDate ? moment(this.expiryDate).format("DD-MM-YYYY"): '')
            return this.expiryDate ? moment(this.expiryDate).format("DD-MM-YYYY"): ''
        },

        computedaed(){
            console.log(this.aed)
            console.log(this.aed ? moment(this.aed).format("MM-DD-YYYY"): '')
            return this.aed ? moment(this.aed).format("MM-DD-YYYY"): ''
        },

        computedextensionDate(){
            console.log(this.extensionDate)
            console.log(this.extensionDate ? moment(this.extensionDate).format("DD-MM-YYYY"): '')
            return this.extensionDate ? moment(this.extensionDate).format("DD-MM-YYYY"): ''
        },

        computedexpiryMouDate(){
            console.log(this.expiryMouDate)
            console.log(this.expiryMouDate ? moment(this.expiryMouDate).format("DD-MM-YYYY"): '')
            return this.expiryMouDate ? moment(this.expiryMouDate).format("DD-MM-YYYY"): ''
        },




         agreementStartErrors(){
            const errors = []
            if(this.agreementType === 'Fresh'){   
            if (!this.$v.agreementStartFrom.$dirty) return errors
                !this.$v.agreementStartFrom.required && errors.push('This field is required'); 
                return errors
              }else{
                return errors
            }
          
        },

        agreementEndErrors(){
            let agreementEndDate = this.$v.agreementEnd.$model;
            let agreementStartDate = this.$v.agreementStartFrom.$model;
            const errors = []
            if(this.agreementType === 'Fresh'){   
                if (!this.$v.agreementEnd.$dirty) return errors
                !this.$v.agreementEnd.required && errors.push('This field is required');
                if(!moment(agreementEndDate).isAfter(agreementStartDate))
                errors.push('To* date must be greater than From* date');     
                return errors
             }else{
                return errors
            }
        },

        agreementSignErrors(){
            const errors = []
            if(this.agreementType === 'Fresh'){     
                if (!this.$v.agreementSign.$dirty) return errors
                !this.$v.agreementSign.required && errors.push('This field is required'); 
                return errors
             }else{
                return errors
            }
        },

        amountErrors(){
            const errors = []   
            if(this.guaranteeReqIf === false){
                if (!this.$v.amount.$dirty) return errors
                !this.$v.amount.required && errors.push('This field is required');
                !this.$v.amount.numeric && errors.push('Only Numbers are Allowed');
                return errors
            }else{
                return errors
            }
        },

        bankerNameErrors(){
            const errors = []   
            if(this.guaranteeReqIfBank === false){
                if (!this.$v.bankerName.$dirty) return errors
                !this.$v.bankerName.required && errors.push('This field is required');
                !this.$v.bankerName.nameChecker && errors.push('Only alphabets are allowed'); 
                return errors
            }else{
                return errors
            }
        },


        expiryDateErrors(){
            const errors = []   
            if(this.guaranteeReqIfBank === false){
                if (!this.$v.expiryDate.$dirty) return errors
                !this.$v.expiryDate.required && errors.push('This field is required'); 
                return errors
            }else{
                return errors
            }
        },

        extensionDateRules(){
            console.log("this is date ", this.extensionDate);
            console.log(moment(this.extensionDate).format("DD-MM-YYYY"),"this is extension date formated")
            let formatedExtDate = moment(this.extensionDate).format("DD-MM-YYYY")
            var new_date = moment(formatedExtDate, "DD-MM-YYYY").add(45, 'days');
            console.log("new_date which is +45days",moment(new_date).format("DD-MM-YYYY"));
           return this.isExtension === 'Yes' ? [v => !!v || 'This field is required'] :'' 
        },

        // if addendum is selected 
        expiryMouDateErrors(){
            const errors = []   
            if(this.agreementType === 'Addendum'){
                if(!this.$v.expiryMouDate.$dirty) return errors
                !this.$v.expiryMouDate.required && errors.push('This field is required'); 
                return errors
            }else{
                return errors
            }
        },

        


    },

   filters:{
      truncate: function (text, length, suffix) {
            if (text.length > length) {
                return text.substring(0, length) + suffix;
            } else {
                return text;
            }
        }
    },

    methods:{

        send_addensum(indata){
            
            console.log(indata, "indata is on close and upload ");
            this.enable_send_adendum = false;
            this.adden_data = indata;


        },

        nowfinalsend(){
            // s99
            if(this.adden_data != null){
                try {
                    this.POST(2, this.adden_data.url , this.adden_data.form_data,(res, error)=>{
                    if(res != null){
                            console.log(' response of send addendum ', res.data);
                            this.showToast(res.data.msg,this.TOST().SUCCESS) 
                            this.adden_data = null
                            this.enable_send_adendum = true
                    }else{
                            this.adden_data = null
                            this.enable_send_adendum = true
                            this.showToast(res.data.msg,this.TOST().ERROR) 
                    }
                    })
                } catch (error) {
                    console.log(' An Error has occurred while Generating MOU (POST) ', error);
                }
            }else{
                  this.showToast("Please upload an Addendum to send",this.TOST().WARNING) 
            }
       
        },

        // gg(indata){
        //     console.log("what we get in gg", indata)
        // },

        approvalFormDetails(){
            this.onSendMouDisable = true;
            console.log("%c data for empanelment in approval contract details","background-color:limegreen;color:white",this.$store.state.empanelProviderData,'\n', this.$route.params.empanelmentType);
            let populateData = this.$store.state.empanelProviderData.empanelment.providerApproval
            if(this.$route.params.empanelmentType === 'empanelment'){
                console.log("%c empanelment type is ","background-color:#ffcccc;color:black",this.$route.params.empanelmentType);
                this.approverRemarks = populateData.approverRemarks
                this.empanelmentApprover = populateData.providerEmpanelmentApproval
                this.reason = populateData.reason
            }else{
                console.log("%c empanelment type is ","background-color:#ffcccc;color:black",this.$route.params.empanelmentType);
                console.log("%c populateData approval contract details","background-color:#ffeecc;color:black",populateData);
                this.approverRemarksRen = populateData.approverRemarks
                console.log(this.approverRemarksRen,"this is the approver remarks from renewal");
                this.empanelmentApproverRen = populateData.providerEmpanelmentApproval
                this.reasonRen = populateData.reason
                this.isExtension = populateData.extensionRequired
                this.disableIsExtensionDate = true //to disable extension date while in approval contract details form
                this.extensionDate = populateData.extensionDate
            }
        },

        setnumeric(v){
             
            if (v.length !== 0) {
                console.log("testing",/^[0-9 ]+$/.test(v))
                if(!/^[0-9 ]+$/.test(v)){
                    return "Only Numbers are allowed."
                }else{
                    if(parseInt(v)>100){
                        return v => !v || 'Only values between 0 to 100 are accepted'
                    }
                }
            }
        },

        setdataformou(){
            try {
                // if(this.$store.state.empanelProviderData.hasOwnProperty('empanelment') && this.$store.state.empanelProviderData.hasOwnProperty('provider') ){
                    var valis = this.getdataby_id;
                    console.log("genrate mou from get api ?",this.getdataby_id);
                    console.log("data from setdataformou",this.getdataby_id.providerApproval);
                    console.log("%c empanelmentType from setdataformou","background-color:#dd11aa;color:black",valis);
                    
                    if(valis.empanelmentType === "empanelment"){
                        this.approverRemarks = valis.hasOwnProperty('providerApproval') ? valis.providerApproval.hasOwnProperty('approverRemarks') ? this.santizedata(valis.providerApproval.approverRemarks) : '' : '' ;
                        this.empanelmentApprover = valis.hasOwnProperty('providerApproval') ? valis.providerApproval.hasOwnProperty('providerEmpanelmentApproval') ? this.santizedata(valis.providerApproval.providerEmpanelmentApproval) : '' : '' ;
                        this.reason = valis.hasOwnProperty('providerApproval') ? valis.providerApproval.hasOwnProperty('reason') ? this.santizedata(valis.providerApproval.reason) : '' : '' ;
                    }else{
                        this.approverRemarksRen = valis.hasOwnProperty('providerApproval') ? valis.providerApproval.hasOwnProperty('approverRemarks') ? this.santizedata(valis.providerApproval.approverRemarks) : '' : '' ;
                        this.empanelmentApproverRen = valis.hasOwnProperty('providerApproval') ? valis.providerApproval.hasOwnProperty('providerEmpanelmentApproval') ? this.santizedata(valis.providerApproval.providerEmpanelmentApproval) : '' : '' ;
                        this.reasonRen = valis.hasOwnProperty('providerApproval') ? valis.providerApproval.hasOwnProperty('reason') ? this.santizedata(valis.providerApproval.reason) : '' : '' ;
                        this.isExtension = valis.hasOwnProperty('providerApproval') ? valis.providerApproval.hasOwnProperty('extensionRequired') ? this.santizedata(valis.providerApproval.extensionRequired) : '' : '' ;
                        this.extensionDate = valis.hasOwnProperty('providerApproval') ? valis.providerApproval.hasOwnProperty('extensionDate') ? this.santizedata(valis.providerApproval.extensionDate) : '' : '' ;
                    }

                    this.pname = valis.hasOwnProperty('entityName') ? this.santizedata(valis.entityName) : this.santizedata(valis.firstName)+' '+this.santizedata(valis.lastName)
                    this.officeat =  Object.values(valis.communicationAddress).join(' ')
                    this.aed = '';
                    //this.discountis = 
                    this.addressis = Object.values(valis.healthServiceAddress).join(' ')
                    this.thepan = valis.hasOwnProperty('paymentDetails') ? valis.paymentDetails.hasOwnProperty('pan') ? this.santizedata(valis.paymentDetails.pan) : '' : '' ;
                    this.hosat = valis.hasOwnProperty('personalDetails') ? valis.personalDetails.hasOwnProperty('headofinstitute') ? this.santizedata(valis.personalDetails.headofinstitute) : '' : '' ;
                    this.insat = '';
                    this.adc = '';
                    this.providerids.ID = this.$store.state.empanelProviderData.provider._id;
                    this.providerids._id = this.$store.state.empanelProviderData.empanelment._id;
                    console.log(valis.personalDetails,"what is in hosat ",  this.hosat)
                // }else{
                //     console.log('Provider and empanelment data not found, hit api & get it?');
                // }
               
            } catch (error) {
                console.log('An error has occurred when setting mou data', error)
            }
          
        },

        santizedata(data){
            console.log("indatais ",data); 
            try{
            if (data ==="" || data === "undefined" || data === null) {
                return ""
            } else {
                return data;
            }

            }catch(err) {
            console.log(err)
            }

        },

        clearallis(){
             this.adc = ''
             this.discountis = ''
             this.hosat = ''
             this.insat = ''
        },

        generateAden(ident){

            if(ident == 1){
                this.enable_Addendumpopup = true;
                this.selected_pdffile = null;
            }else{

                this.enable_Addendumpopup = false;

            }

        },
        postmou(){
            // pm99
            // this.
             if (!this.$refs.themouform.validate()){
                this.$refs.themouform.validate()
                 this.showToast("Please fill and correct all required fields.",this.TOST().WARNING);
                return
            } 
           
            var fdis ={  
                    providerName:this.pname,
                    officeAt:this.officeat,
                    agreementEffectiveDate:'03/16/2021',
                    discount:this.discountis,
                    address:this.addressis,
                    pan:this.thepan,
                    hospitalAttn:this.hosat,
                    insurerAttn:this.insat,
                    additionalClauses:this.adc

            }

              console.log("formbody is -> ", fdis);
            try {
                this.POST(2,'empanelment/generate-mou',fdis,(res, error)=>{
                  if(res != null){
                       console.log(' response of generate mou ', res.data);
                         this.showToast(res.data.msg,this.TOST().SUCCESS) 
                       this.pdfdata = res.data.data.pdf
                        this.aftergenmou = true;
                     
                        // this.openpdf(res.data.data.pdf)
                      
                  }else{
                      console.log("thier is error");
                        this.showToast(error.response.data.msg,this.TOST().ERROR); 
                  }
                })
            } catch (error) {
                 console.log(' An Error has occurred while Generating MOU (POST) ', error);
            }
             
        },

         openpdf(pdfdata){
//       <embed src="" width="500" height="375" 
//  type="application/pdf">
// alert('open pdf called')
            const image_window = window.open("", "_blank")
                console.log('image_window ->', image_window)
                    image_window.document.write(`
                            <html>
                                <head>
                                </head>
                                <body>
                                <embed height="100%" width="100%" src="`+'data:application/pdf;base64,'+this.pdfdata+`"   type="application/pdf">
                                </body>
                            </html>
                    `);
                       
   

    },

         sendmou(){
            // pm99
            // this.
        
            let sendmoudata ={  
                _id:  this.providerids._id,
                providerId:  this.providerids.ID,
                providerName:this.pname,
                officeAt:this.officeat,
                // :this.aed,
               discount:this.discountis,
                address:this.addressis,
               pan:this.thepan,
               hospitalAttn:this.hosat,
               insurerAttn:this.insat,
                additionalClauses:this.adc
            }



              console.log("sendmoudata formbody is -> ", sendmoudata);
                // try {
                    this.POST(2,'empanelment/send-mou',sendmoudata,(res, error)=>{
                        if(!error){
                            console.log(' response of send mou ', res.data);
                            // this.showToast(res.data.msg,this.TOST().SUCCESS);
                            this.onSendMouDisable = true;
                            this.extensionReqIf = true
                             let formData = {
                                 workItemId : this.routeData._id,
                                 workItemType : this.routeData.type,
                                 empanelType: this.empanelmentTypeIs
                             }
                            this.PUT(2,'empanelment/create-provider-approval-workitem/'+this.providerids._id,formData,(res,error) => {
                                    if(!error){
                                        console.log("this is response from sendMou",res.data.msg);   
                                        this.showToast(res.data.msg,this.TOST().SUCCESS)
                                    }else{
                                        console.log("their was error",error.response.data.msg);
                                        this.showToast(error.response.data.msg,this.TOST().ERROR)
                                    }
                                })
                        
                        //    this.enableGenerate = false;
                        //     this.aftergenmou = false;
                        }else{
                            console.log(' An error has occurred while sending mou ',error.response.data.msg);
                            this.showToast(error.response.data.msg,this.TOST().ERROR);
                            // this.enableGenerate = false;
                            // this.aftergenmou = false;
                            this.onSendMouDisable = true;
                            this.extensionReqIf = true
                        }
                    })                            
                
            
         },

        
                

         getApprovalDetailsById(userID){
             let api_Is = 'empanelment/empanelment-details-by-id/'+userID;
            this.GET(2,api_Is,(res,error)=>{
                    if(!error){
                        console.log("this is response form getApprovalDetails",res.data.data);
                        let approvalData = res.data.data;
                        this.getdataby_id = res.data.data;
                        this.setdataformou();
                        this.$store.commit("EMPANEL_TAB_STATUS", approvalData);
                    }
            })
         },
        // dragFile(e){
        //     e.preventDefault();
        // },
        // dragLeave(e){
        //     e.preventDefault();
        // },
        approverRules(v){
            if(v.length > 0){
                return v => /^[ a-zA-Z0-9!@#'":;\&?*\/\\)\(+=,.-]+$/g.test(v) || 'Only alphabets are allowed'
            }
            // console.log("%c this is the value for remarks","background-color:#df4512;color:black",v);
        },

        approverRemarksRules(v){
            if(v.length > 0){
                return v => /^[ a-zA-Z0-9!@#'":;\&?*\/\\)\(+=,.-]+$/g.test(v) || 'Only alphabets are allowed'
                                    //  , . ' ":;()@!#&?/\-+=*space tab
            }
        },

        // if the file is drag and drop 
        dropImage(e){

            e.preventDefault();
            e.stopPropagation(); 
            console.log(e,"\n",e.dataTransfer.files);
            const files = e.dataTransfer.files

            Array.from(files).forEach(file => this.addFile(file))
        },

       
        // if file is selected through upload button 
        selectedFile(e){
            console.log("file selected by clicking upload button",e.target.files);
            this.showToast("File selected! Now Click Upload button to Upload File",this.TOST().INFO)
             const files = e.target.files
            Array.from(files).forEach(file => this.addFile(file))
        },

         dropRenImage(e){

            e.preventDefault();
            e.stopPropagation(); 
            console.log("this is the file from renewal thro dropimage",e,"\n",e.dataTransfer.files[0].name);
            const file = e.dataTransfer.files;
            this.fileSelectedToUpload = file;
            this.addRenewalFile(file);
            
        },

        // if file is selected through upload button for Renewal
        renSelectedFile(e){
            console.log("file selected by clicking upload button  in renewal",e.target.files);
             this.showToast("File selected! Now Click Upload button to Upload File",this.TOST().INFO)
                const file = e.target.files

                this.fileSelectedToUpload = file[0];

                console.log("this is the event target files",file[0].name);
                if(!e.type.match('/*')){
                console.log("not a valid document");
                console.log(e.type);
                }
               this.addRenewalFile(file)
        
        },

        addRenewalFile(file){

             if(file[0].name !== this.renDocument1){  
                    console.log("No duplicate file");
                    this.typeOfFile = 'text'
                    this.imageUploaded = false
                    this.nameForRenewal = file[0].name
                }else{
                    this.showToast("File with same name already present"+" "+file[0].name,this.TOST().WARNING)
                    this.imageUploaded = true
                }
        },

        addFile(file){
            // filesArr
            // fileUrlArr
            if(!file.type.match('/*')){
                console.log("not a valid document");
                console.log(file.type);
            }
            
              
                var sameFile = this.filesArr.find(dupFile =>  dupFile.name === file.name)
                console.log("same file if present",sameFile);
                if(!sameFile){  
                   if(this.filesArr.length <= 3){

                       console.log("No duplicate file");
                       this.typeOfFile = 'text'
                       this.filesArr.unshift(file)
                       this.imageUploaded = false
                       var reader = new FileReader();
                    //    this.fileSelectedToUpload = file
                       reader.onload = (e) => this.fileUrlArr.push(e.target.result);
                       reader.readAsDataURL(file);
                       console.log(this.filesArr);
                   }else{
                        this.showToast("Only four files are allowed",this.TOST().WARNING)
                        return
                   }
                }else{
                    this.imageUploaded = true
                    this.showToast("File with same name already present"+" "+sameFile.name,this.TOST().WARNING)
                        
                    
                }
               
        },

        uploadFileThClick(){
            console.log("I was clicked",this.$refs.uploadThClick);
            if(this.imageUploaded === true){
                     this.typeOfFile = 'file'
                      this.$refs.uploadThClick.click()
                }else{
                        return
                }

           
        },

        

        uploadDocument(){
                if(this.imageUploaded === true){
                     this.typeOfFile = 'file'
                }else{
                    console.log("this is files arr from uploadDocument",this.filesArr);
                    if(this.document1 === ''){
                        this.imageUploaded = true
                        this.document1 = this.filesArr[0].name
                         this.disSaveContract = false
                        console.log("document added to document1",this.fileSelectedToUpload);
                    }else if(this.document2 === ''){
                        this.imageUploaded = true
                        this.document2 = this.filesArr[0].name
                        console.log("document added to document2",this.fileSelectedToUpload);
                    }else if(this.document3 === ''){
                        this.imageUploaded = true
                        this.document3 = this.filesArr[0].name
                        console.log("document added to document3",this.fileSelectedToUpload);
                    }else if(this.document4 === ''){
                        this.imageUploaded = true
                        this.document4 = this.filesArr[0].name
                        console.log("document added to document4",this.fileSelectedToUpload);
                    }
                }

        },

        uploadRenDocument(){
                if(this.imageUploaded === true){
                     this.typeOfFile = 'file'
                }else{
                        this.imageUploaded = true
                        this.disSaveContract = false
                        this.renDocument1 = this.nameForRenewal
                        console.log("document added to renDocument1",this.fileSelectedToUpload);
                    
                }

        },

        delDocument1(document1){
            console.log("deleting document clicked",document1,this.filesArr.length);
            let indexToDel1 = this.filesArr.findIndex(file => file.name === document1);
            console.log("this is the index to delete",indexToDel1);
             this.filesArr.splice(indexToDel1,1);
             this.document1 = ''
            if(this.filesArr.length === 0){
                this.disSaveContract = true
            }
        },
        delDocument2(document2){
            console.log("deleting document clicked",document2,this.filesArr);
            let indexToDel2 = this.filesArr.findIndex(file => file.name === document2);
            console.log("this is the index to delete",indexToDel2);
            this.filesArr.splice(indexToDel2,1);
            this.document2 = ''
            if(this.filesArr.length === 0){
                this.disSaveContract = true
            }
        },
        delDocument3(document3){
            console.log("deleting document clicked",document3,this.filesArr);
            let indexToDel3 = this.filesArr.findIndex(file => file.name === document3);
            console.log("this is the index to delete",indexToDel3);
            this.filesArr.splice(indexToDel3,1);
            this.document3 = ''
            if(this.filesArr.length === 0){
                this.disSaveContract = true
            }
        },
        delDocument4(document4){
            console.log("deleting document clicked",document4,this.filesArr);
            let indexToDel4 = this.filesArr.findIndex(file => file.name === document4);
            console.log("this is the index to delete",indexToDel4);
            this.filesArr.splice(indexToDel4,1);
            this.document4 = ''
            if(this.filesArr.length === 0){
                this.disSaveContract = true
            }
        },

        delDocumentRen(){
            this.disSaveContract = true
            this.typeOfFile = 'text'
            this.renDocument1 = ''
            this.filesArr = [] 
        },


        viewdocs(){
            // alert("view docs called")
             this.docen = true
        }, 

        agreementTypeBtn(){
            console.log("%c this is the fresh or addendum value","color:green",this.agreementType);
            this.delDocumentRen()
            this.disSaveContract = true
        },
       
        submitDetails(){
            console.log("%c submitting the form","color:darkblue");
            this.$refs.form.validate()
            if(!this.$refs.form.validate()){
                console.log("%c It is a not a valid Form","color:red");
                this.showToast("Please fill all fields",this.TOST().WARNING)
            }else{
                
                console.log("%c It is a valid Form","color:green");
                console.log(this.empanelmentValue, "this is empanelment type");
                let formData = {
                                empanelmentType: 'New Empanelment',
                                approverRemarks: this.approverRemarks,
                                providerEmpanelmentApproval: this.empanelmentApprover,
                                reason: this.reason,
                                extensionRequired: this.isExtension,
                                extensionDate: this.extensionDate 
                                }
                let id = this.$store.state.empanelProviderData.empanelment._id    
                    this.PUT(2,'empanelment/create-provider-approval/'+id,formData,(res,error) => {
                                if(!error){
                                    if(this.empanelmentApprover === 'Rejected'){
                                         this.showToast(res.data.msg ,this.TOST().SUCCESS);
                                        this.getApprovalDetailsById(id);
                                        return console.log("%c form is rejected","background-color:#fd1120;color:black",res.data);

                                    }else{

                                        this.enableGenerate = true;
                                        console.log("%c Response from provider Approval for work Item","color:green");
                                        this.getApprovalDetailsById(id);
                                        this.showToast(res.data.msg ,this.TOST().SUCCESS);
                                    }
                                }else{
                                    console.log("%c Error Response from provider Approval for work Item","color:red");
                                     this.showToast(error.response.data.msg ,this.TOST().ERROR);
                                }
                    })
            }
        },

        sendBackForm(){
            console.log("%c sending back provider to deo","color:red");
            let id = this.$store.state.empanelProviderData.empanelment._id;
                 let formDataSendBack = {
                                empanelmentType: 'New Empanelment',
                                approverRemarks: this.approverRemarks,
                                providerEmpanelmentApproval: this.empanelmentApprover,
                                reason: this.reason,
                                extensionRequired: this.isExtension,
                                extensionDate: this.extensionDate 
                                }
            this.PUT(2,'empanelment/create-sendback-provider-approval/'+id,formDataSendBack,(res,error) => {
                            if(!error){
                                    console.log(res);
                                    this.showToast(res.data.msg ,this.TOST().SUCCESS);
                                }else{
                                    console.log(error.response);
                                    this.showToast(error.response.data.msg ,this.TOST().ERROR);
                                }
            })
        },

        sendBackFormRen(){
            console.log("%c sending back provider to deo","color:red");
            let id = this.$store.state.empanelProviderData.empanelment._id;
                 let formDataSendBack = {
                                empanelmentType: 'Empanelment Renewal',
                                approverRemarks: this.approverRemarksRen,
                                providerEmpanelmentApproval: this.empanelmentApproverRen,
                                reason: this.reasonRen,
                                extensionRequired: this.isExtension,
                                extensionDate: this.extensionDate 
                                }
            this.PUT(2,'empanelment/create-sendback-provider-approval/'+id,formDataSendBack,(res,error) => {
                            if(!error){
                                    console.log(res);
                                    this.showToast(res.data.msg ,this.TOST().SUCCESS);
                                }else{
                                    console.log(error.response);
                                    this.showToast(error.response.data.msg ,this.TOST().ERROR);
                                }
            })
        },

        generatemou(){
            this.setdataformou()
            this.genmou = true
        },

        generateS(){
            console.log("generate is clicked");
            this.generatemou()
        },

        submitContractDetails(form){
             this.$v.$touch();
            console.log("%c submitting the contract details form provider Approval Contract Details","color: #23B1A9;");
            if( this.agreementStartErrors.length>0 || 
                this.agreementEndErrors.length>0 ||
                this.agreementSignErrors.length>0 ||
                this.amountErrors.length>0 ||
                this.bankerNameErrors.length>0 ||
                this.expiryDateErrors.length>0 ||
                this.expiryMouDateErrors.length>0){

                console.log("%c It is a not a valid Form","color:red");
                this.showToast("Please fill all fields",this.TOST().WARNING)
            }else{
                console.log("%c It is a valid Form","color:green");
                if(form === 'Renewal'){
                    console.log("this is renewal data");
                    let empId = this.$store.state.empanelProviderData.empanelment._id;
                    let bodyDataRen = {
                        empanelmentType : "Empanelment Renewal",
                        signedMou: this.fileSelectedToUpload,
                        agreementType :  this.agreementType,
                        agreementStart : this.agreementStartFrom,
                        agreementEnd : this.agreementEnd,
                        agreementSignedDate : this.agreementSign,
                        guaranteeRequired : this.gauranteeReq,
                        amount : this.amount,
                        bankerName : this.bankerName,
                        expiryDate : this.expiryDate,
                        remarks : this.remarksAddendum,
                        newMouExpiryDate : this.expiryMouDate,
                        workItemId : this.routeData.all._id,
                        workItemType : this.routeData.all.type,
                        // empanelType: this.empanelmentTypeIs
                    }
                    const formData = new FormData();
                        for(let key in bodyDataRen){
                            formData.append(key,bodyDataRen[key])
                        }
                        this.PUT(2,'empanelment/create-provider-contract/'+empId,formData,(res,error)=>{
                            if(!error){ 
                               console.log("working fine", res.data);
                               this.showToast(res.data.msg ,this.TOST().SUCCESS);
                            }else{
                                console.log("not working fine",error.response.data);
                                this.showToast(error.response.data.msg ,this.TOST().ERROR); 
                            }
                        })
                  

                }else{
                    console.log("this is approval formdata",this.filesArr,this.routeData.all._id);
                    let empId = this.$store.state.empanelProviderData.empanelment._id;
                    let bodyData = {
                        empanelmentType : "New Empanelment",
                        files: this.filesArr,
                        // agreementType :  this.agreementType,
                        agreementStart : this.agreementStartFrom,
                        agreementEnd : this.agreementEnd,
                        agreementSignedDate : this.agreementSign,
                        guaranteeRequired : this.gauranteeReq,
                        amount : this.amount,
                        bankerName : this.bankerName,
                        expiryDate : this.expiryDate,
                        workItemId : this.routeData.all._id,
                        workItemType : this.routeData.all.type,
                        // empanelType: this.empanelmentTypeIs
                        // remarks : this.remarksAddendum,
                        // newMouExpiryDate : this.expiryMouDate
                    }
                    const formDataApp = new FormData();
                        for(let key in bodyData){
                            formDataApp.append(key,bodyData[key])
                        }
                    this.PUT(2,'empanelment/create-provider-contract/'+empId,formDataApp,(res,error)=>{
                        if(!error){ 
                           console.log("working fine", res.data.msg);
                           this.showToast(res.data.msg ,this.TOST().SUCCESS); 
                        }else{
                            console.log("not working fine",error.response.data);
                            this.showToast(error.response.data.msg ,this.TOST().ERROR); 
                        }
                    })


                
                }
            }
        },


        submitRenewalDetails(){
            console.log("%c submitting the form for Renewal","color:darkblue");
            this.$refs.renform.validate()

           if(!this.$refs.renform.validate()){
               this.showToast("Please fill all fields",this.TOST().WARNING)
                console.log("%c It is a not a valid Form","color:red");
            }else{
                
                console.log("%c It is a valid Form","color:green");
                 console.log(this.empanelmentValue, "this is empanelment type");
                let formData = {
                                empanelmentType: 'Empanelment Renewal',
                                approverRemarks: this.approverRemarksRen,
                                providerEmpanelmentApproval: this.empanelmentApproverRen,
                                reason: this.reasonRen,
                                extensionRequired: this.isExtension,
                                extensionDate: this.extensionDate 
                                }
                let id = this.$store.state.empanelProviderData.empanelment._id    
                    this.PUT(2,'empanelment/create-provider-approval/'+id,formData,(res,error) => {
                                if(!error){
                                    if(this.empanelmentApproverRen === 'Rejected'){
                                        this.showToast(res.data.msg ,this.TOST().SUCCESS);
                                        this.getApprovalDetailsById(id);
                                        return console.log("%c form is rejected","background-color:#ffff20;color:black",res.data);
                                    }else{
                                        this.enableGenerate = true;
                                        console.log("%c Response from provider Approval for work Item","color:green");
                                         this.getApprovalDetailsById(id);
                                        this.showToast(res.data.msg ,this.TOST().SUCCESS);
                                    }
                                }else{
                                    console.log("%c Error Response from provider Approval for work Item","color:red");
                                     this.showToast(error.response.data.msg ,this.TOST().ERROR);
                                }
                    })

            }
        },

        okClick(){
            console.log("%c OK button was clicked","background-color:#11457d;color:white",this.arrToNavCancel);

            if(this.arrToNavCancel.length === 3){
                this.selectedCompFromPage(this.arrToNavCancel[2])
            }else{
                this.selectedCompFromPage(this.arrToNavCancel[1])
            }
                 
        },

        cancelData(){
            this.confirmDialogCancel = true
        },

        close_cancelDialog(){
            this.confirmDialogCancel = false
        },

    },//methods end



    watch:{
        gauranteeReq(value){
            if(value === "Bank Guarantee" || value === "Advance Payment"){
                      this.guaranteeReqIf = false;  
                if(value === "Bank Guarantee"){
                        this.guaranteeReqIfBank = false;
                    }else{
                        this.guaranteeReqIfBank = true;
                        this.bankerName = '';

                    }
            }else{
                this.guaranteeReqIf = true;  
                this.guaranteeReqIfBank = true;
                this.amount = '';
                this.expiryDate = '';
            }
            
        },

        isExtension(value){
            console.log('value of isExtension is=>>>',value);
            if(value==='Yes'){
                if(this.disableIsExtensionDate){
                    this.extensionReqIf = true
                }else{
                    this.extensionReqIf = false
                }
            }else{
                this.extensionReqIf = true
                this.extensionDate = ''
            }
        }
    }


}
</script>


<style>

.fixh{
    position: fixed;
    width: 560px;
    z-index: 10;
}

.title-clr-pr-ACD{
    color:#10242B;
    margin-bottom: 5px;
}

/* .dragImage{
    border: solid 2px;
    border-color: rgb(241, 235, 235);
    width: 40%;
   
} */

.smalltext{
    font:  5.5px sans-serif;
    fill: #b8b2b2;
}
.dashed{
    stroke: #d8d1d1;
    stroke-width: 0.8px;
    stroke-dasharray: 4px 2px;
    fill: #f7f7f7;
}
.iconSize{
    position: absolute;
    left: 40px;
    right: 0;
    
}

.deleteIcon{
    background-color: #d1121b !important;
    width:30px !important;
    height:30px !important;
   box-shadow: 1px 1px 1px rgb(216, 207, 207);
}

.icondel{
    margin-left: 5px;
    margin-top: 2px;
}

.signedMoU{
    margin-top: 50px !important;
}

.boxStyle{
        border: solid 1px #f7f7f7;
        height:30px; 
        width:170px;
        box-shadow: 1px 1px 1px rgb(216, 207, 207);
       
}

.iconSizeRenewal{
    left: 35px;
   
}

.smalltextRenewal{
    font:  5.5px sans-serif;
    fill: #b8b2b2;
}
.dashedRenewel{
    stroke: #d8d1d1;
    stroke-width: 0.7px;
    stroke-dasharray: 4px 2px;
    fill: #f7f7f7;
}


/* .radioLabel > .v-label{
    color: green !important
} */
</style>