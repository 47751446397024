<template>
    <v-app>
        <v-form v-model="valid" ref="famasterform" >
        <v-card class="nopadding rounded-0">
            <v-layout class="pt-2 ml-2 pb-2">
                <v-flex xs3>
                    <v-layout column class="cust_pd mx-2 my-2 " 
                        :class="[dsblLvl===true ? 'custDsbld_faLmtMstr': '']">
                        <v-text class="font-weight-bold cstm-clr-pr3">
                            Level *
                        </v-text>
                        <v-autocomplete
                            :items="staticlevelItems"
                            label="Select"
                            v-model="Level"
                            solo
                            maxlength=20
                            dense
                            class="rounded-0"
                            :rules="[rules.required]"
                            :disabled="dsblLvl"
                            
                            >
                        </v-autocomplete>
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class=" mx-2 my-2 cust_pd"
                        :class="[dsblLob===true ? 'custDsbld_faLmtMstr': '']">
                        <v-text class="font-weight-bold cstm-clr-pr3">
                            LOB *
                        </v-text>
                        <v-select
                            :items="itemsOfLOB"
                            label="Select"
                            v-model="lob"
                            solo
                            dense
                            class="rounded-0"
                            :rules="[rules.required,rules.alphaNumRules]"
                            :disabled="dsblLob"
                            >
                        </v-select>
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class=" mx-2 my-2 cust_pd">
                        <v-text class="font-weight-bold cstm-clr-pr3">
                            Reserving Authority Limit *
                        </v-text>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="ResAuthLimit"
                            :rules="[rules.NumericWithCommas]"
                            :maxlength="29"
                            class="rounded-0"
                            @keyup="filterMoney(ResAuthLimit,'ResAuthLimit')"
                            >
                        </v-text-field>
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class=" mx-2 my-2 cust_pd">
                        <v-text class="font-weight-bold cstm-clr-pr3">
                            Payment Authority Limit *
                        </v-text>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="PaymentAuthLimit"
                            :maxlength="29"
                            :rules="[rules.NumericWithCommas]"
                            class="rounded-0"
                            @keyup="filterMoney(PaymentAuthLimit,'PaymentAuthLimit')"
                            >
                        </v-text-field>
                    </v-layout>
                </v-flex>
                <v-flex xs3></v-flex>
            </v-layout>
        </v-card>
        </v-form>

        <v-card style="max-height:150px" class="mt-4 rounded-0">
            <div class="mx-2 pa-2" style=" display:flex;justify-content:space-between;"  >
            <v-layout style="flex:1" justify-end > 
            <v-btn  tile color="#23B1A9" class="text-capitalize px-3 mx-2 my-2" small @click="saveDetails()" :dark="!updateBtn" :disabled="updateBtn"><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider>Save</v-btn>
            <v-btn  tile color="#23B1A9" class="text-capitalize px-3 mx-2 my-2" small @click="submitDetails()"  :dark="!createBtn" :disabled="createBtn" ><v-icon dark small>arrow_forward</v-icon> <v-divider vertical class="mx-2"></v-divider>Submit</v-btn>
            <v-btn  dark tile color="#E46A25" class="text-capitalize px-3 mr-2 ml-2 my-2" small @click=$router.go(-1)><v-icon dark small>cancel</v-icon> <v-divider vertical class="mx-2"></v-divider> Close</v-btn>
            </v-layout>
            </div>
        </v-card>
    </v-app>
</template>

<script>

  
export default {

    created(){

        console.log("this.$route.params",this.$route.params);
        if(this.$route.params.editValue===true){
            this.dsblLvl=true
            this.dsblLob=true
        }else{
             this.dsblLvl=false
            this.dsblLob=false
        }
        this.$store.commit("SAVE_PAGE_HEADER", "Financial Authority Limit Master");

        this.token=this.$store.getters.successToken

        this.GET(3,'/line-of-business/get-line-of-business-list', (res,error)=>{
            if(!error){
                console.log("response from api is",res.data.data[0].lineOfBusiness);
                this.itemsOfLOB=res.data.data[0].lineOfBusiness
            }else{
                console.log("error",error.response.data);
            }
        });

        this.GET(6,'financilauthority/all-financial-autority-levels', (res,error)=>{
            if(!error){
                console.log("response from api is",res.data.data);
                this.levelitems=res.data.data
            }else{
                console.log("error",error.response.data);
            }
    });

        this.rolebtnvalue=this.$route.params.value
            console.log("incoming params--->",this.rolebtnvalue)

        if(this.rolebtnvalue==true){
            this.createBtn=false
            this.updateBtn=true
            console.log('incoming params createbtn----->',this.createBtn)
         }else{             
                this.createBtn=true
                this.updateBtn=false
         }

        if(Object.keys(this.$route.params).length != 0){
            console.log("object from route is present for edit",this.$route.params);
            this.updateCreation(this.$route.params.item.allData);
            
        }else{
            console.log("object from route is not present");
        }

        // console.log("this.getEditDetails(this.$router.history.current.name) from Mixin===>",this.getEditDetails(this.$router.history.current.name))
        //  if(this.getEditDetails(this.$router.history.current.name)){
        //     this.disableAll=this.getEditDetails(this.$router.history.current.name).isEdit
            
        // }else{
        //     this.disableAll=false
        // }

        // if(this.disableAll==false){
        //     this.createBtn=true
        //     this.updateBtn=true
        // }
        
         
    },

    data() {
        return {
            disableAll:true,
            Level:'',
            lob:'',
            ResAuthLimit:'',
            PaymentAuthLimit:'',
            levelitems:[],
            itemsOfLOB:[],
            items:['PA','Health'],
            staticlevelItems:["Level I","Level II","Level III","Level IV","Level V","Level VI","Level VII","Level VIII","Level IX","Level X","Level XI","Level XII","Level XIII","Level XIV","Level XV","Level XVI","Level XVII","Level XVIII","Level XIX","Level XX","Level XXI","Level XXII","Level XXIII","Level XXIV","Level XXV"],
            idForupdate:'',
            GetLOBlistData:[],
            rules:{ required: v=>v && v.length>0 || "This Field is Required",
                    Numeric: v=>/^[0-9 ]+$/.test(v) || "Only Numbers are allowed.",
                    alphaNumRules: v => /^[a-zA-Z0-9 ]+$/.test(v) || 'Only alphanumeric values are allowed',
                    NumericWithCommas: v=>/^[0-9, ]+$/.test(v) || "Only Numbers and commas are allowed.",
            },
            updateBtn:false,
            createBtn:false,
            dsblLvl:false,
            dsblLob:false,
            rolebtnvalue:'',
            rolehyperlink:'',
            valid:''
          
    }},

    methods: {

        updateCreation(someItem){
            console.log("this is the item from search page",someItem);
            this.Level = someItem.level
            this.lob = someItem.lob
            this.ResAuthLimit = someItem.reservingAuthorityLimit
            this.PaymentAuthLimit = someItem.paymentAuthorityLimit
            this.filterMoney(someItem.reservingAuthorityLimit.toString(),"ResAuthLimit")
            this.filterMoney(someItem.paymentAuthorityLimit.toString(),"PaymentAuthLimit")
            console.log("level is",someItem.level);
            console.log("lob is ",someItem.lob);
            this.PermissionID = someItem.permissionId
            this.idForupdate = someItem._id
        },

        submitDetails(){
            console.log("submit button is working fine & active staus is ==>");
            if(this.$refs.famasterform.validate()){
                console.log("this is  valid");
                
                let formData = {
                    level:this.Level,
                    lob:this.lob,
                    reservingAuthorityLimit:this.ResAuthLimit,
                    paymentAuthorityLimit:this.PaymentAuthLimit
                }
                if(formData.reservingAuthorityLimit && formData.reservingAuthorityLimit.length > 0){
                    formData.reservingAuthorityLimit   =  parseFloat(formData.reservingAuthorityLimit.split(",").join(""))
                }

                if(formData.paymentAuthorityLimit && formData.paymentAuthorityLimit.length > 0){
                    formData.paymentAuthorityLimit   =  parseFloat(formData.paymentAuthorityLimit.split(",").join(""))
                }
            this.POST(6,'financilauthority/create-financial-autority',formData, (res,error)=>{
                if(!error){
                    this.showToast(res.data.msg,this.TOST().SUCCESS);
                    console.log("response from api is",res.data.msg);
                    this.$router.push('/FALimitMasterSearch')
                    // self.$router.push('/FALimitMasterSearch')
                    // this.itemsOfLOB=res.data.data[0].lineOfBusiness
                }else{
                    console.log("error",error.response.data);
                    this.showToast(error.response.data.msg,this.TOST().ERROR);
                }
            });
               
            }else{
                console.log("this is not valid form");
            }
        },

        saveDetails(){
            let formData = {
                    level:this.Level,
                    lob:this.lob,
                    reservingAuthorityLimit:this.ResAuthLimit,
                    paymentAuthorityLimit:this.PaymentAuthLimit
                }

                if(formData.reservingAuthorityLimit && formData.reservingAuthorityLimit.length > 0){
                    formData.reservingAuthorityLimit   =  parseFloat(formData.reservingAuthorityLimit.split(",").join(""))
                }

                if(formData.paymentAuthorityLimit && formData.paymentAuthorityLimit.length > 0){
                    formData.paymentAuthorityLimit   =  parseFloat(formData.paymentAuthorityLimit.split(",").join(""))
                }
            this.PUT(6,'financilauthority/update-financial-autority-details/'+this.idForupdate,formData, (res,error)=>{
                if(!error){
                    this.showToast(res.data.msg,this.TOST().SUCCESS);
                    console.log("response from api is",res.data.msg);
                    this.$router.push('/FALimitMasterSearch')
                    // this.itemsOfLOB=res.data.data[0].lineOfBusiness
                }else{

                    console.log("error",error.response.data);
                    this.showToast(error.response.data.msg,this.TOST().ERROR);
                }
            });
                
        },

        closebtn(){
            this.$router.push('/home')

        },

        filterMoney(amount,param){
            if(amount.includes(",")){
                amount  =   amount.split(",").join("")
            }
            amount=amount.toString();
            var lastThree = amount.substring(amount.length-3);
            var otherNumbers = amount.substring(0,amount.length-3);
            if(otherNumbers != '')
                lastThree = ',' + lastThree;
            console.log(otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree);
            this[param]    =   otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree
            // return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }

}
}
</script>

<style>

.cust-rmvpd-psins3 .v-text-field.v-text-field--enclosed>.v-input__control>.v-input__slot {
    padding-right: 0px !important;
}

.cust_pr2 .v-text-field .v-input__slot,
.v-text-field--outline .v-input__slot {
    min-height: 42px !important;
    display: flex !important;
    align-items: center !important;
    /* border:1px red solid; */
}

.cstm-clr-pr3{
    color:#646666 !important;
    margin-bottom: 5px !important;
    font-size: 13px !important;
}

.cust_pd1 .v-text-field .v-input__slot,
.v-text-field--outline .v-input__slot {
    min-height: 42px !important;
    display: flex !important;
    align-items: center !important;
    /* border:1px red solid; */
}

.cust-rmvpd-pr1 .v-text-field.v-text-field--enclosed>.v-input__control>.v-input__slot {
    padding: 0px !important;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
    font-size: 0.84rem !important;
}

/* .hdr-txt-clr{ */
    /* color: white !important;
    font-weight: bold; */
    /* padding:0px!important;
    padding: 10px !important;
    padding-left: 14px !important; */
/* } */

.hdr-bg-clr{
  background-color: #23B1A9;
}
.headercolor{
    background-color: rgb(248, 240, 240);
}
.cardfont{
    font-size: 0.84rem !important;
    padding-right: 2px;
}

.nopadding{
    padding-top: 0px  !important;
}

.custDsbld_faLmtMstr .theme--light.v-text-field--solo > .v-input__control > .v-input__slot {
    background: #F7F7F7;
}
</style>