<template>
    <v-app>
        <v-container class="containerStyle"
      style="padding:1.5rem; margin:0; max-width:100%">
       <h3>{{heading}} Frequent Payee Master</h3>
       <v-form ref="claimForm" lazy-validation @submit.prevent>
       <v-layout class="mt-3">
        <v-flex class="form-group">
            <label class="form-label">
                Payee Type <span><strong class="red--text">*</strong></span>
            </label>
            <v-select
            :items="payeeType"
            solo
            dense
            :rules="[(v)=>requiredField(v)]"
            class="form-control"
            v-model="frequencyPayee.payeeType"
            :disabled="isView"
            placeholder="Select"></v-select>
        </v-flex>

        <v-flex class="form-group" v-show="frequencyPayee.payeeType == 'Others'">
            <label class="form-label">
                Other Name <span v-if="frequencyPayee.payeeType == 'Others'"><strong class="red--text">*</strong></span>
            </label>
            <v-text-field
            solo
            dense
            :rules="frequencyPayee.payeeType == 'Others' ? [(v)=>requiredField(v),(v)=>matchRegex(v,'alphanum')]:[]"
            v-model="frequencyPayee.otherName"
            :maxlength="50"
            class="form-control"
            :disabled="isView"
            placeholder="Enter..."></v-text-field>
        </v-flex>

        <v-flex class="form-group">
            <label class="form-label">
                Payee Name <span><strong class="red--text">*</strong></span>
            </label>
            <v-text-field
            solo
            dense
            :maxlength="138"
            v-model="frequencyPayee.payeeName"
            :rules="[(v)=>requiredField(v)]"
            class="form-control"
            :disabled="isView"
            placeholder="Enter..."></v-text-field>
        </v-flex>

        <v-flex class="form-group">
          <label class="form-label">
              LOB <span><strong class="red--text">*</strong></span>
          </label>
            <v-select
                v-model="frequencyPayee.lob"
                label="Select"
                :items="lobOptions"
                :rules="[(v)=>requiredField(v)]"
                solo
                dense
                multiple
                class="form-control"
                :disabled="isView"
                clearable
            ></v-select>
        </v-flex>

        <v-flex class="form-group">
            <v-checkbox
            label="Active"
            v-model="frequencyPayee.active"
            :disabled="isView || !isModify"
            class="form-control"></v-checkbox>
        </v-flex>

        <v-flex class="form-group">
            <v-checkbox
            label="Watchlist"
            v-model="frequencyPayee.watchList"
            :disabled="isView"
            class="form-control"></v-checkbox>
        </v-flex>
       </v-layout>
       <v-expansion-panels v-model="panels" multiple>
       <v-expansion-panel>
            <v-expansion-panel-header color="#1EBBD9">
              <span class="page-title-small white--text" >Payee Contact Information</span>
              <template v-slot:actions>
                <v-icon class="white--text">
                  {{
                    panels.indexOf(0) !== -1 ? "mdi-minus-box" : "mdi-plus-box"
                  }}
                </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <v-layout wrap class="pt-4 form-group-container">
                    <v-flex class="form-group">
                        <label class="form-label">
                            Address Line 1 <span><strong class="red--text">*</strong></span>
                        </label>
                        <v-text-field
                        solo
                        dense
                        :maxlength="45"
                        :rules="[(v)=>requiredField(v),(v)=>addressValidation(v),(v) => matchRegex(v, 'alphanum')]"
                        v-model="frequencyPayee.addressLine1"
                        class="form-control"
                        :disabled="isView"
                        placeholder="Enter..."
                        >
                        </v-text-field>
                    </v-flex>

                    <v-flex class="form-group">
                        <label class="form-label">
                            Address Line 2
                        </label>
                        <v-text-field
                        solo
                        dense
                        v-model="frequencyPayee.addressLine2"
                        class="form-control"
                        maxlength="45"
                        :rules="[(v)=>addressValidation(v),(v) => matchRegex(v, 'alphanum')]"
                        placeholder="Enter..."
                        :disabled="isView"
                        >
                        </v-text-field>
                    </v-flex>

                    <v-flex class="form-group">
                        <label class="form-label">
                            Address Line 3
                        </label>
                        <v-text-field
                        solo
                        dense
                        v-model="frequencyPayee.addressLine3"
                        class="form-control"
                        maxlength="35"
                        :rules="[(v)=>addressValidation(v),(v) => matchRegex(v, 'alphanum')]"
                        placeholder="Enter..."
                        :disabled="isView"
                        >
                        </v-text-field>
                    </v-flex>

                    <v-flex class="form-group">
                        <label class="form-label">
                            Pin Code <span><strong class="red--text">*</strong></span>
                        </label>
                        <v-autocomplete
                        :items="searchPincode"
                        item-text="pinCode"
                        solo
                        :search-input.sync="pincode"
                        dense
                        :rules="[(v)=>requiredField(v)]"
                        v-model="frequencyPayee.pinCode"
                        class="form-control"
                        maxlength="6"
                        @keyup="checkPincode"
                        @change="changePincodeAndState"
                        placeholder="Start typing to search"
                        :disabled="isView"
                        >
                        </v-autocomplete>
                    </v-flex>

                    <v-flex class="form-group">
                        <label class="form-label">
                            City <span><strong class="red--text">*</strong></span>
                        </label>
                        <v-text-field
                        solo
                        dense
                        disabled
                        :rules="[(v)=>requiredField(v)]"
                        v-model="frequencyPayee.city"
                        class="form-control"
                        maxlength="50"
                        placeholder="Enter..."
                        
                        >
                        </v-text-field>
                    </v-flex>
                </v-layout>
                <v-layout wrap class="pt-4 form-group-container">
                    <v-flex class="form-group">
                        <label class="form-label">
                            District <span><strong class="red--text">*</strong></span>
                        </label>
                        <v-text-field
                        solo
                        disabled
                        :rules="[(v)=>requiredField(v)]"
                        dense
                        v-model="frequencyPayee.district"
                        class="form-control"
                        maxlength="50"
                        placeholder="Enter..."
                        
                        >
                        </v-text-field>
                    </v-flex>

                    <v-flex class="form-group">
                        <label class="form-label">
                            State <span><strong class="red--text">*</strong></span>
                        </label>
                        <v-text-field
                        solo
                        dense
                        disabled
                        :rules="[(v)=>requiredField(v)]"
                        v-model="frequencyPayee.state"
                        class="form-control"
                        maxlength="50"
                        placeholder="Enter..."
                        
                        >
                        </v-text-field>
                    </v-flex>

                    <v-flex class="form-group">
                        <label class="form-label">
                            Country <span><strong class="red--text">*</strong></span>
                        </label>
                        <v-text-field
                        solo
                        dense
                        disabled
                        :rules="[(v)=>requiredField(v)]"
                        v-model="frequencyPayee.country"
                        class="form-control"
                        maxlength="50"
                        placeholder="Enter..."
                        
                        >
                        </v-text-field>
                    </v-flex>

                    <v-flex class="form-group">
                        <label class="form-label">
                            PAN <span><strong class="red--text">*</strong></span>
                        </label>
                        <v-text-field
                        solo
                        dense
                        :rules="[(v) => validatePanNumber(v, 'PAN Number'),(v) => requiredField(v)]"
                        :value="computedPAN"
                        v-model="frequencyPayee.panNumber"
                        class="form-control"
                        :maxlength="10"
                        placeholder="Enter..."
                        :disabled="isView"
                        >
                        </v-text-field>
                    </v-flex>

                    <v-flex class="form-group">
                        <label class="form-label">
                          AADHAR Card Number(Last 4 Numbers)
                        </label>
                        <v-text-field
                        solo
                        dense
                        v-model="frequencyPayee.aadharId"
                        :rules="[ (v) => matchRegex(v, 'nums'), (v) => validateLength(v, { exact: 4 }, '', 'Field requires 4 digits.')]"
                        class="form-control"
                        :maxlength="4"
                        placeholder="Enter..."
                        :disabled="isView"
                        >
                        </v-text-field>
                    </v-flex>
                </v-layout>
                <v-layout wrap class="pt-4 form-group-container">
                    <v-flex class="form-group">
                        <v-checkbox
                            label="GST Not Applicable"
                            v-model="frequencyPayee.gstNotApplicable"
                            :disabled="isView"
                            class="form-control"></v-checkbox>
                    </v-flex>
                    <v-flex class="form-group" v-show="!frequencyPayee.gstNotApplicable">
                        <label class="form-label">
                            GSTIN Number <span class="red--text">*</span>
                        </label>
                        <v-text-field
                        solo
                        dense
                        :value="computedGST"
                        :rules="[(v)=>validateGSTNumber(v,'','GST Number Invalid')]"
                        v-model="frequencyPayee.gstinNumber"
                        class="form-control"
                        maxlength="15"
                        placeholder="Enter..."
                        :disabled="isView"
                        >
                        </v-text-field>
                    </v-flex>

                    <v-flex class="form-group">
                        <v-layout>
              <v-flex >
                <label class="form-label">
                  ISD<span class="red--text">*</span></label
                >
                <v-text-field
                  class="form-control mr-2"
                  style="max-width:110px"
                  solo
                  dense
                  :disabled="isView"
                  :rules="[(v)=>matchRegex(v,'nums')]" 
                  v-model="frequencyPayee.mobileNumber[0].isd"
                  :maxlength="5"
                  placeholder="..."
                  prepend-inner-icon=""
                >
                  <template v-slot:prepend-inner>
                    <v-icon class="iconstyle">mdi-phone</v-icon>
                  </template>
                </v-text-field>
              </v-flex>

              <v-flex>
                <label class="form-label">
                  Mobile Number<span class="red--text">*</span></label
                >
                <!-- <template v-slot:activator="{ on, attrs }">  -->
                <v-text-field
                  placeholder="Enter..."
                  solo
                  dense
                  v-model="frequencyPayee.mobileNumber[0].number"
                  :maxlength="10"
                  :rules="[(v)=>requiredField(v),(v)=>matchRegex(v,'nums'),(v) => validateLength(v, { exact: 10 }, '', 'Field requires 10 digits.')]" 
                  :disabled="isView"              
                  class="form-control"
                  
                >
                  <template v-slot:append>
                    <v-icon
                      class="blueiconstyle"
                      @click="mobile_Dialog=!mobile_Dialog" 
                      >mdi-phone-plus</v-icon
                    >
                  </template>
                </v-text-field>
              </v-flex>
            </v-layout>
                    </v-flex>
      <!-- Landline number -->
                    <v-flex class="form-group">
                        <v-layout>
              <v-flex >
                <label class="form-label">
                  STD</label
                >
                <v-text-field
                  class="form-control mr-2"
                  style="max-width:110px"
                  solo
                  dense
                  :rules="[(v)=>matchRegex(v,'nums')]" 
                  v-model="frequencyPayee.landlineNumber[0].std"
                  :maxlength="5"
                  placeholder="..."
                  prepend-inner-icon=""
                  :disabled="isView"
                >
                  <template v-slot:prepend-inner>
                    <v-icon class="iconstyle">mdi-phone</v-icon>
                  </template>
                </v-text-field>
              </v-flex>

              <v-flex >
                <label class="form-label">
                  Landline Number</label
                >
                <!-- <template v-slot:activator="{ on, attrs }">  -->
                <v-text-field
                  placeholder="Enter..."
                  solo
                  dense
                  v-model="frequencyPayee.landlineNumber[0].number"
                  :maxlength="10" 
                  :rules="[(v)=>matchRegex(v,'nums')]"               
                  class="form-control"
                  :disabled="isView"
                >
                  <!-- <template v-slot:append>
                    <v-icon
                      class="blueiconstyle"
                     
                      >mdi-phone-plus</v-icon
                    >
                  </template> -->
                </v-text-field>
              </v-flex>
            </v-layout>
                    </v-flex>
                </v-layout>
                </v-expansion-panel-content>
                </v-expansion-panel>
        <v-expansion-panel>
            <v-expansion-panel-header color="#1EBBD9">
              <span class="page-title-small white--text" >Contact Person Information</span>
              <template v-slot:actions>
                <v-icon class="white--text">
                  {{
                    panels.indexOf(1) !== -1 ? "mdi-minus-box" : "mdi-plus-box"
                  }}
                </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content >
                <v-layout wrap class="pt-4 form-group-container">
                    <v-flex class="form-group">
                        <label class="form-label">
                            Email ID <span><strong class="red--text">*</strong></span>
                        </label>
                        <v-text-field solo dense class="form-control" placeholder="Enter..." :disabled="isView" v-model="email_Obj.length > 0 && email_Obj[0].email" :rules="[(v)=>requiredField(v),(v) => validateEmail(v, 'Email ID')]">
                            <template v-slot:append>
                                <v-icon class="blueiconstyle" @click="email_Dialog=!email_Dialog">mdi-email-plus</v-icon>
                            </template>
                        </v-text-field>
                        <!-- <v-text-field
                        solo
                        dense
                        :rules="[(v)=>requiredField(v),(v) => validateEmail(v, 'Email ID')]"
                        v-model="frequencyPayee.emails.id1"
                        class="form-control"
                        maxlength="50"
                        placeholder="Enter..."
                        :disabled="isView"
                        prepend-inner-icon="mdi-email"
                        >
                        </v-text-field> -->
                    </v-flex>

                    <!-- <v-flex class="form-group">
                        <label class="form-label">
                            Email ID 2 
                        </label>
                        <v-text-field
                        solo
                        dense
                        :rules="[(v) => validateEmail(v, 'Email ID')]"
                        v-model="frequencyPayee.emails.id2"
                        class="form-control"
                        maxlength="50"
                        placeholder="Enter..."
                        :disabled="isView"
                        prepend-inner-icon="mdi-email"
                        >
                        </v-text-field>
                    </v-flex>

                    <v-flex class="form-group">
                        <label class="form-label">
                            Email ID 3 
                        </label>
                        <v-text-field
                        solo
                        dense
                        :rules="[(v) => validateEmail(v, 'Email ID')]"
                        v-model="frequencyPayee.emails.id3"
                        class="form-control"
                        maxlength="50"
                        placeholder="Enter..."
                        :disabled="isView"
                        prepend-inner-icon="mdi-email"
                        >
                        </v-text-field>
                    </v-flex>

                    <v-flex class="form-group">
                        <label class="form-label">
                            Email ID 4 
                        </label>
                        <v-text-field
                        solo
                        dense
                        :rules="[(v) => validateEmail(v, 'Email ID')]"
                        v-model="frequencyPayee.emails.id4"
                        class="form-control"
                        maxlength="50"
                        placeholder="Enter..."
                        :disabled="isView"
                        prepend-inner-icon="mdi-email"
                        >
                        </v-text-field>
                    </v-flex> -->

                    <v-flex class="form-group">
                        <label class="form-label">
                            Fax No.
                        </label>
                        <v-text-field
                        solo
                        dense
                        v-model="frequencyPayee.faxNumber"
                        class="form-control"
                        maxlength="50"
                        placeholder="Enter..."
                        :disabled="isView"
                        :rules="[(v)=>matchRegex(v,'alphanum')]" 
                        >
                        </v-text-field>
                    </v-flex>

                    <v-flex class="form-group">
                  <label class="form-label">
                    End Date
                    <span  class="red--text"
                      ><strong>*</strong></span
                    >
                  </label>
                <v-menu
                    v-model="menu"
                    offset-y
                    nudge-top="20"
                    
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="computedEndDate"
                        placeholder="DD/MM/YYYY"
                        
                        dense
                        :rules="[(v)=>requiredField(v)]"
                        solo
                        readonly
                        v-on="on"
                        @focus="menu = true"
                        @blur="menu = true"
                        v-bind="attrs"
                        class="form-control"
                        append-icon="mdi-menu-down"
                        prepend-inner-icon="mdi-calendar"
                        :disabled="isView"
                      >
                      </v-text-field>
                    </template>
                    <v-date-picker
                      no-title
                      :min="getEndDate"
                      v-model="frequencyPayee.endDate"
                      @input="menu2 = false"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-flex>
                <v-flex class="form-group">
                    <label class="form-label">Category <span><strong class="red--text">*</strong></span></label>
                    <v-select
                    solo
                    dense
                    :rules="[(v)=>requiredField(v)]"
                    :items="categorys"
                    v-model="frequencyPayee.category"
                    class="form-control"
                    placeholder="Select"
                    :disabled="isView"
                    ></v-select>
                </v-flex>

                <v-flex class="form-group">
                        <label class="form-label">
                            Contact Person Name <span><strong class="red--text">*</strong></span>
                        </label>
                        <v-text-field
                        solo
                        dense
                        v-model="frequencyPayee.contactPerson.name"
                        class="form-control"
                        maxlength="50"
                        placeholder="Enter..."
                        :rules="[(v)=>requiredField(v),(v)=>matchRegex(v,'alphanum')]"
                        :disabled="isView"
                        >
                        </v-text-field>
                    </v-flex>

        <v-flex class="form-groups">
            <v-layout>
              <v-flex>
                <label class="form-label">
                  ISD<span class="red--text">*</span></label
                >
                <v-text-field
                  class="form-control mr-2"
                  style="max-width:110px"
                  solo
                  dense
                  :rules="[(v)=>matchRegex(v,'nums')]" 
                  v-model="frequencyPayee.contactPerson.isdCode"
                  :maxlength="5"
                  placeholder="..."
                  prepend-inner-icon=""
                  :disabled="isView"
                >
                  <template v-slot:prepend-inner>
                    <v-icon class="iconstyle">mdi-phone</v-icon>
                  </template>
                </v-text-field>
              </v-flex>

              <v-flex>
                <label class="form-label">
                  Contact Person Number<span class="red--text">*</span></label
                >
                <!-- <template v-slot:activator="{ on, attrs }">  -->
                <v-text-field
                  placeholder="Enter..."
                  solo
                  dense
                  :rules="[(v)=>requiredField(v),(v)=>matchRegex(v,'nums')]" 
                  v-model="frequencyPayee.contactPerson.number"
                  :maxlength="10"
                  class="form-control"
                  :disabled="isView"
                >
                  <!-- <template v-slot:append>
                    <v-icon
                      class="blueiconstyle"
                     
                      >mdi-phone-plus</v-icon
                    >
                  </template> -->
                </v-text-field>
              </v-flex>
            </v-layout>
                    </v-flex>
                    </v-layout>
                </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
        <v-expansion-panel-header color="#1EBBD9">
              <span class="page-title-small white--text">Bank Details</span>
              <template v-slot:actions>
                <v-icon class="white--text">
                  {{
                    panels.indexOf(2) !== -1 ? "mdi-minus-box" : "mdi-plus-box"
                  }}
                </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content >
                <v-layout wrap class="pt-4 form-group-container">
                    <v-flex class="form-group">
                        <v-checkbox
                        class="form-control"
                        label="Bank Details"
                        v-model="frequencyPayee.bank.bankDetails"
                        @change="checkBankDetails"
                        :disabled="isView"
                        >
                          </v-checkbox>
                    </v-flex>
                    <v-spacer></v-spacer>
                    <div class="me-3 px-2" v-if="cancelledChequeData.length > 0">
                                    <label :disabled="!isOCRUploaded " class="form-label mt-2 text-right"
                                        @click="cancelChequeView">
                                        <div style="color:#0AB417; font: normal normal medium 12px/14px Roboto;cursor:pointer">
                                            <v-icon color="#0AB417">
                                                mdi-eye
                                            </v-icon>
                                            <span class="ml-2">View Cancelled Cheque</span>
                                        </div>
                                    </label>
                                </div>

                                <div>
                    <p v-show="frequencyPayee.bank.bankDetails" class="teal--text mr-8" :disabled="isView" style="cursor:pointer" @dragover="dragoverCancel"  @drop="dropCancel" @click="browseCancel" >
                      <v-icon color="#23B1A9" class="mr-3">mdi-tray-arrow-up</v-icon>Upload Cancelled Cheque
                      <v-file-input style="display:none;" v-model="cancelledCheque"
                        id="cancelchequeupload" label="" dense solo @change="OCRUpload" :disabled="isView"
                        accept="image/png,image/jpeg,application/msword,application/pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        /></p>
                                          
                      </div>
                     </v-layout>
                     <v-layout wrap class="pt-4 form-group-container">
                                <v-layout class="py-4 cancel-cheque-view-port" v-if="cancelledChequeFileShow && cancelledChequeData.length > 0">
                                    <v-flex xs12>
                                        <div class="close-cancell-cheque"
                                            @click="cancelledChequeFileShow = !cancelledChequeFileShow">Close</div>
                                        <v-layout justify-center>
                                            <object :data="cancelledChequeData[0].fileBase ? cancelledChequeData[0].fileBase:showCheque" width="1000"
                                                height="300"></object>
                                            <!-- <img height="300" :src="investigatorMasterObj.documents.fileBase" /> -->
                                        </v-layout>
                                        <!-- <v-layout justify-center class="mt-2">
                                            <div class="cancel-cheque-success-btn">OCR was successfully completed.</div>
                                        </v-layout> -->
                                    </v-flex>
                                </v-layout>
                            
                              </v-layout>
                     <v-layout wrap class="pt-4 form-group-container">
                      <v-flex class="form-group mt-n7" v-show="frequencyPayee.bank.bankDetails">
                        <label class="form-label">&nbsp;</label>
                        <v-checkbox
                        class="form-control"
                        label="Over Written"
                        v-model="frequencyPayee.overwritten[0].status"
                        :disabled="!cancelledChequeData.length>0 || isView || dialog_Overwritten"
                        type="checkbox"
                        @click="checkoverwritten"
                        >
                          </v-checkbox>
                    </v-flex>
                            <v-flex class="form-group">
                                <label class="form-label">IFSC Code 
                                  <span v-if="frequencyPayee.bank.bankDetails"><strong class="red--text">*</strong></span></label>
                                <v-autocomplete solo dense
                                class="form-control"
                                placeholder="Enter..."
                                :rules="frequencyPayee.bank.bankDetails==true ? [(v)=>requiredField(v)]:[]"
                                v-model="frequencyPayee.bank.ifscCode"
                                :disabled="!frequencyPayee.overwritten[0].status||isView"
                                :items="IFSCCodes"
                                item-text="ifscCode"
                                :search-input.sync="ifsc"
                                @keyup="ifscData"
                                @change="ifscchange"
                                :maxlength="11"
                                clearable
                                @click:clear="ifsc_clear"
                                >
                                </v-autocomplete>
                            </v-flex>

                            <v-flex class="form-group">
                                <label class="form-label">Bank Name <span v-if="frequencyPayee.bank.bankDetails"><strong class="red--text">*</strong></span></label>
                                <v-text-field solo dense
                                class="form-control"
                                placeholder="Enter..."
                                :rules="frequencyPayee.bank.bankDetails==true ? [(v)=>requiredField(v)]:[]"
                                v-model="frequencyPayee.bank.bankName"
                                disabled
                                >
                                </v-text-field>
                              </v-flex>
                              <v-flex class="form-group">
                              <label class="form-label"
                                >Account Holder Name
                                <span v-if="frequencyPayee.bank.bankDetails"><strong class="red--text">*</strong></span></label
                              >
                              <v-text-field
                                placeholder="Enter..."
                                class="form-control"
                                solo
                                dense
                                v-model="frequencyPayee.bank.accountHolderName"
                                :rules="frequencyPayee.bank.bankDetails==true ? [(v)=>requiredField(v)]:[]"
                                :disabled="
                                  (!frequencyPayee.overwritten[0].status) || isView
                                "
                                depressed
                              />
                            </v-flex>

                            <v-flex class="form-group">
                                <label class="form-label">City <span v-if="frequencyPayee.bank.bankDetails"><strong class="red--text">*</strong></span></label>
                                <v-text-field solo dense
                                class="form-control"
                                placeholder="Enter..."
                                v-model="frequencyPayee.bank.city"
                                :rules="frequencyPayee.bank.bankDetails==true ? [(v)=>requiredField(v)]:[]"
                                disabled
                                >
                                </v-text-field>
                            </v-flex>

                            <v-flex class="form-group">
                                <label class="form-label">Bank Address Line 1 <span v-if="frequencyPayee.bank.bankDetails"><strong class="red--text">*</strong></span></label>
                                <v-text-field solo dense
                                class="form-control"
                                placeholder="Enter..."
                                v-model="frequencyPayee.bank.bankAddressLine1"
                                :rules="frequencyPayee.bank.bankDetails==true ? [(v)=>requiredField(v),(v) => matchRegex(v, 'alphanum')]:[]"
                                :disabled="!frequencyPayee.overwritten[0].status && !editBankAddress1  || isView"
                                >
                                </v-text-field>
                            </v-flex>

                            <v-flex class="form-group">
                                <label class="form-label">Bank Address Line 2 </label>
                                <v-text-field solo dense
                                class="form-control"
                                placeholder="Enter..."
                                v-model="frequencyPayee.bank.bankAddressLine2"
                                :rules="[(v) => matchRegex(v, 'alphanum')]"
                                :disabled="!frequencyPayee.overwritten[0].status && !editBankAddress2  || isView"
                                >
                                </v-text-field>
                            </v-flex>

                            <v-flex class="form-group">
                                <label class="form-label">Bank Address Line 3</label>
                                <v-text-field solo dense
                                class="form-control"
                                placeholder="Enter..."
                                v-model="frequencyPayee.bank.bankAddressLine3"
                                :rules="[(v) => matchRegex(v, 'alphanum')] "
                                :disabled="!frequencyPayee.overwritten[0].status && !editBankAddress3 || isView"
                                >
                                </v-text-field>
                            </v-flex>

                            <v-flex class="form-group">
                                <label class="form-label">Branch <span v-if="frequencyPayee.bank.bankDetails"><strong class="red--text">*</strong></span></label>
                                <v-text-field solo dense
                                class="form-control"
                                placeholder="Enter..."
                                v-model="frequencyPayee.bank.branch"
                                :rules="frequencyPayee.bank.bankDetails==true ? [(v)=>requiredField(v)]:[]"
                                disabled
                                >
                                </v-text-field>
                            </v-flex>

                            <v-flex class="form-group">
                                <label class="form-label">District <span v-if="frequencyPayee.bank.bankDetails"><strong class="red--text">*</strong></span></label>
                                <v-text-field solo dense
                                class="form-control"
                                placeholder="Enter..."
                                v-model="frequencyPayee.bank.district"
                                :rules="frequencyPayee.bank.bankDetails==true ? [(v)=>requiredField(v)]:[]"
                                disabled
                                >
                                </v-text-field>
                            </v-flex>

                            <v-flex class="form-group">
                                <label class="form-label">State <span v-if="frequencyPayee.bank.bankDetails"><strong class="red--text">*</strong></span></label>
                                <v-text-field solo dense
                                class="form-control"
                                placeholder="Enter..."
                                v-model="frequencyPayee.bank.state"
                                :rules="frequencyPayee.bank.bankDetails==true ? [(v)=>requiredField(v)]:[]"
                                disabled
                                >
                                </v-text-field>
                            </v-flex>

                            <v-flex class="form-group">
                                <label class="form-label">Pin Code <span v-if="frequencyPayee.bank.bankDetails"><strong class="red--text">*</strong></span></label>
                                <v-autocomplete
                                  :items="searchPincode"
                                  :search-input.sync="bankPincode"
                                  item-text="pinCode"
                                  solo
                                  dense
                                  :rules="[(v)=>requiredField(v)]"
                                  :model-value="frequencyPayee.bank.pinCode"
                                  class="form-control"
                                  maxlength="6"
                                  @keyup="fetchPinCodeFromBank"
                                  @change="(v) => fetchAddressByPincode(v)"
                                  placeholder="Start typing to search"
                                >
                                </v-autocomplete>
                            </v-flex>

                            <v-flex class="form-group">
                                <label class="form-label">Bank Account Number <span v-if="frequencyPayee.bank.bankDetails"><strong class="red--text">*</strong></span></label>
                                <v-text-field solo dense
                                class="form-control"
                                placeholder="Enter..."
                                :type="fieldTypes.password"
                                name="password"
                                value=""
                                id="password"
                                @focus="handleType"
                                @blur="handleType"
                                autocomplete="off"
                                @copy.prevent
                                @paste.prevent
                                @click.right.prevent
                                :readonly="!frequencyPayee.overwritten[0].status"
                                @change="checkBankAccount(false)"
                                v-model="frequencyPayee.bank.accountNumber"
                                :rules="frequencyPayee.bank.bankDetails==true ? [(v)=>requiredField(v),(v)=>matchRegex(v,'alphanum')]:[]"
                                :disabled="!frequencyPayee.overwritten[0].status||isView"
                                :maxlength="35"
                                >
                                </v-text-field>
                            </v-flex>

                            <v-flex class="form-group">
                                <label class="form-label">Re-Enter Bank Account Number <span v-if="frequencyPayee.bank.bankDetails"><strong class="red--text">*</strong></span></label>
                                <v-text-field solo dense
                                class="form-control"
                                placeholder="Enter..."
                                :rules="frequencyPayee.bank.bankDetails==true ? [(v) => requiredField(v),(v) => matchRegex(v, 'alphanum'), (v) => shouldBeSame(frequencyPayee.bank.accountNumber, v, 'Mismatch Bank Account Number')]:[]"
                                v-model="frequencyPayee.bank.confirmAccountNumber"
                                :maxlength="35"
                                :disabled="!frequencyPayee.overwritten[0].status||isView"
                                @paste.prevent
                                @change="checkBankAccount(false)"
                                >
                                </v-text-field>
                            </v-flex>

                            <v-flex class="form-group">
                                <label class="form-label">Account Type <span v-if="frequencyPayee.bank.bankDetails"><strong class="red--text">*</strong></span></label>
                                <v-select solo dense
                                class="form-control"
                                placeholder="Enter..."
                                :items="accountTypes"
                                v-model="frequencyPayee.bank.accountType"
                                :rules="frequencyPayee.bank.bankDetails==true ? [(v)=>requiredField(v)]:[]"
                                :disabled="isView"
                                >
                                </v-select>
                            </v-flex>

                            <v-flex class="form-group">
                                <label class="form-label">MSME <span><strong class="red--text">*</strong></span></label>
                                <v-select solo dense
                                class="form-control"
                                placeholder="Enter..."
                                :items="msmes"
                                v-model="frequencyPayee.bank.msme"
                                :rules="[(v) => (!!v || 'This field is required')]"
                                :disabled="isView"
                                >
                                </v-select>
                            </v-flex>

                            <v-flex class="form-group" v-if="frequencyPayee.bank.msme=='Yes'">
                                <label class="form-label">Enterprise classification
                                   <span v-if="frequencyPayee.bank.msme=='Yes'"><strong class="red--text">*</strong></span></label>
                                <v-select solo dense
                                class="form-control"
                                placeholder="Enter..."
                                v-model="frequencyPayee.bank.enterpriseClassification"
                                :items="enterpriseclassifications"
                                :rules="frequencyPayee.bank.msme=='Yes' ? [(v)=>requiredField(v)]:[]"
                                :disabled="frequencyPayee.bank.msme=='No'"
                                >
                                </v-select>
                            </v-flex>

                            <v-flex class="form-group" v-if="frequencyPayee.bank.msme=='Yes'">
                                <label class="form-label">Enterprise category <span v-if="frequencyPayee.bank.msme=='Yes'"><strong class="red--text">*</strong></span></label>
                                <v-select solo dense
                                class="form-control"
                                placeholder="Enter..."
                                v-model="frequencyPayee.bank.enterpriseCategory"
                                :items="enterprisecategorys"
                                :rules="frequencyPayee.bank.msme=='Yes' ? [(v)=>requiredField(v)]:[]"
                                :disabled="frequencyPayee.bank.msme=='No'"
                                >
                                </v-select>
                            </v-flex>

                            <v-flex class="form-group" v-if="frequencyPayee.bank.msme=='Yes'">
                                <label class="form-label">UAN <span v-if="frequencyPayee.bank.msme=='Yes'"><strong class="red--text">*</strong></span></label>
                                <v-text-field solo dense
                                class="form-control"
                                v-model="frequencyPayee.bank.uan"
                                placeholder="Enter..."
                                :rules="frequencyPayee.bank.msme=='Yes' ? [(v)=>requiredField(v),(v)=>matchRegex(v,'alphanum')]:[]"
                                :disabled="frequencyPayee.bank.msme=='No'"
                                >
                                </v-text-field>
                            </v-flex>

                            <v-flex class="form-group">
                                <label class="form-label">CKYC</label>
                                <v-text-field solo dense
                                class="form-control"
                                placeholder="Enter..."
                                maxlength="20"
                                :rules="[(v)=>matchRegex(v,'alphanum')]"
                                v-model="frequencyPayee.bank.cKYC"
                                :disabled="isView"
                                >
                                </v-text-field>
                            </v-flex>
                        </v-layout>
            </v-expansion-panel-content>
            </v-expansion-panel>
            
            <v-expansion-panel>
            <v-expansion-panel-header color="#1EBBD9">
              <span class="page-title-small white--text" >Upload Documents</span>
              <template v-slot:actions>
                <v-icon class="white--text">
                  {{
                    panels.indexOf(3) !== -1 ? "mdi-minus-box" : "mdi-plus-box"
                  }}
                </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content >
                <v-layout wrap class="pt-4 form-group-container">
                  <v-tabs
                    v-model="tab"
                    hide-slider
                    
                  >
                  <!-- active-class="claimProcessingTabsActive" -->
                    <v-tab
                      v-for="item in tab_items"
                      :key="item"
                      @change="documentTab=item;checkTabChanged()"
                      :class="documentTab == item ? 'claimProcessingTabsActive' :'unActive'"
                    >
                      {{ item }}
                    </v-tab>
                  </v-tabs>
                  <v-layout class="mt-5">
                  <div :disabled="isView">
                    <div class="neftStyle" :disabled="isView"  @dragover="dragover" @dragleave="dragleave" @drop="drop" 
                    :class="visualEffect ? 'dragbackground': ''">
                      <v-icon color="#23b1a9" class="iconfont">mdi-tray-arrow-up</v-icon>
                      <span class="ml-2 margincls font-weight-bold" :disabled="isView">Drop Files To Upload or <a @click="browse" ><u > Browse</u></a></span>
                      <p class="margincls dlt_text mb-0">Max 5MB of PDF, Word, Excel, Mail or Image(.jpeg, .jpg, .png)</p>
                      <p class="dlt_text mt-0 ml-4" style="font-size:10px">Multiple documents can be uploaded</p>
                      
                    </div>
                    <span></span>
                            <v-file-input
                                multiple
                                v-model="all_attachments"
                                @change="selectFile"
                                accept=".doc, .docx, .csv,.eml, image/png,image/jpeg,application/msword,application/pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/csv"
                                id="SP_fileupload"
                                :disabled="isView"
                                style="display: none">
                            </v-file-input>
                            <!-- @change="selectFile" -->
                  </div>
                  <div>
                    <v-data-table
                            :headers="neftHeaders"
                            :items="documentAttachments"                            
                            hide-default-footer
                            disable-pagination
                            no-data-text="No Records for Attachements"
                            class="tabelwidth vDataTableStyling">
                            <template v-slot:[`item.action`] = '{ item, index }'>            
                                <v-icon class="tableicons mr-4" color="#23B1A9" @click="viewDocument(item)">mdi-eye</v-icon>
                                <v-icon class="tableicons mr-4" color="#23B1A9" @click="downloadDoc(item)">mdi-download</v-icon>
                                <v-icon class="tableicons" color="#D1121B" @click="confirmDelete(index)" v-if="!(item.documentId )" :disabled="isView">mdi-delete</v-icon>
                            </template>
                            <template v-slot:[`item.updatedAt`]="{ item }">
                                <div>
                                    {{ formatDateTime(item.updatedAt) }}
                                </div>
                            </template>
                        </v-data-table>
                  </div>
                  </v-layout>

                </v-layout>
                </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-form>


            <v-layout class="mt-5">
                <v-btn outlined color="red" class="btn" @click="onReset" v-show="!isView">
                   <v-icon> mdi-backspace </v-icon>
                    <v-divider vertical class="mx-2">
                      </v-divider> Reset</v-btn>
                <v-btn outlined color="red" class="ml-5 btn" @click="cancel">
                   <v-icon> mdi-backspace </v-icon>
                    <v-divider vertical class="mx-2"></v-divider> Cancel</v-btn>
                <v-spacer></v-spacer>
                <v-btn class="btn" color="teal white--text" dense solo v-show="!isView" @click="onSubmit"><v-icon>mdi-arrow-right-circle</v-icon>
          <v-divider vertical class="mx-2"></v-divider>
          Submit</v-btn>
            </v-layout>

            <!-- Mobile Dialog -->
    <v-dialog v-model="mobile_Dialog" width="500">
                <v-card>
                    <v-card-title
                    class="white--text"
                    style="background-color: #23b1a9"
                  >
                  <h4 class="ml-5">Add Multiple Contact Number</h4>
        <v-spacer></v-spacer>
        <v-icon @click="mobile_Dialog=!mobile_Dialog" class="white--text">mdi-window-close</v-icon>
                </v-card-title>
                <v-card-text class="grey lighten-2">
                    <v-data-table hide-default-footer
                    :headers="contact_Headers"
                    :items="frequencyPayee.mobileNumber"
                    class=" vDataTableStyling"
                    v-if="frequencyPayee.mobileNumber.length>0"
                    >
                    <template v-slot:[`item.isd`]="{item}">
                        <v-text-field
                    solo
                    dense
                    :maxlength="3"
                    :disabled="isView"
                    class="form-control mt-5"
                    v-model="item.isd"
                    :rules="[(v) => matchRegex(v, 'nums')]"
                    >

                    </v-text-field>
                    </template>
                   <template v-slot:[`item.number`]="{item}">
                    <v-text-field
                    solo
                    dense
                    :disabled="isView"
                    class="form-control mt-5"
                    :maxlength="10"
                    v-model="item.number"
                    :rules="[(v) => matchRegex(v, 'nums'),(v) => validateLength(v, { exact: 10 }, '', 'Field requires 10 digits.')]"
                    >

                    </v-text-field>
                   </template>
                   <template v-slot:[`item.action`]="{item}">
                    <v-icon left
                      top 
                      color="#d1121b"
                       @click="contact_Delete(frequencyPayee.mobileNumber.indexOf(item))" 
                       :disabled="isView"
                       >mdi-delete</v-icon>
                   </template>
                </v-data-table>
                <v-layout class="addbtn" justify-center>
                <v-btn
                
                  @click="contact_Add_Row"
                  text
                  elevation="0"
                  :disabled="isView"
                  class="grey lighten-2 mt-5"
                  style="color: #23B1A9"
                  >+ Add Contact Number</v-btn>
              </v-layout>
                </v-card-text>
                </v-card>
            </v-dialog>

            <v-dialog v-model="email_Dialog" width="500">
                <v-card>
                    <v-card-title
                    class="white--text"
                    style="background-color: #23b1a9"
                  >
                  <h4 class="ml-5">Add Multiple Email ID</h4>
        <v-spacer></v-spacer>
        <v-icon @click="email_Dialog=!email_Dialog" class="white--text">mdi-window-close</v-icon>
                </v-card-title>
                <v-card-text class="grey lighten-2">
                    <v-data-table hide-default-footer
                    :headers="email_Headers"
                    :items="email_Obj"
                    class=" vDataTableStyling"
                    >
                   <template v-slot:[`item.email`]="{item}">
                    <v-text-field
                    solo
                    dense
                    class="form-control mt-5"
                    v-model="item.email"
                    :disabled="isView"
                    :rules="[(v) => validateEmail(v, 'Email ID')]"
                    >

                    </v-text-field>
                   </template>
                   <template v-slot:[`item.action`]="{item}">
                    <v-icon left
                      top color="#d1121b" @click="email_Delete(email_Obj.indexOf(item))" :disabled="isView">mdi-delete</v-icon>
                   </template>
                </v-data-table>
                <v-layout class="addbtn" justify-center>
                <v-btn
                
                  @click="email_Add_Row"
                  text
                  elevation="0"
                  :disabled="isView"
                  class="grey lighten-2 mt-5"
                  style="color: #23B1A9"
                  >+ Add Email ID</v-btn
                >
              </v-layout>
                </v-card-text>
                </v-card>
            </v-dialog>
        </v-container>
    </v-app>
</template>
<script>
import moment from 'moment'
export default {
    data() {
        return{
            payeeType:['Insured','Claimant','Others'],
            categorys:['Individual','Corporate'],
            accountTypes:['Saving Account','Current Account'],
            msmes:['Yes','No'],
            neftHeaders:[{text: "Document Name",align: "start",value: "file",sortable: false,width:"200px"},
                                {text: "Action",align: "start",value: "action",sortable: false,width:"150px"},
                                { text: "Upload by", align: "start", value: "createdBy",width:"130px", sortable: false },
                                { text: "Date and Time", align: "start", value: "updatedAt",width:"130px", sortable: false },
                              ],
            enterpriseclassifications:['Proprietorship','Hindu undivided family','Association of persons','Co-operative society','Partnership firm'
        ,'Company','Individual'],
            enterprisecategorys:['Micro','Small','Medium'],
            payeetype:"",
            panels:[0,1,2,3],
            documentAttachments:[],
            menu:false,
            pincode:null,
           
            msme:false,
            bankAccount:"",
            cancelledChequeFileShow: false,
            cancelledCheque:[],
            cancelledChequeData: [],
            isOCRUploaded: false,
            searchPincode:[],
            searchPincode1:[],
            tab:null,
            documentTab:"NEFT Form",
            visualEffect: false,
            all_attachments:[],
            neftAttachment:[],
            
            panAttachment:[],
            gstnAttachment:[],
            otherAttachment:[],
            kycAttachment:[],
            IFSCCodes:[],
            isView:false,
            heading:"Create",
            pincode1:null,
            ifsc:null,
            showCheque:"",
            dialog_Overwritten:false,
            fieldTypes: {
        password: 'password',
      },
           

            tab_items:['NEFT Form','PAN Card',
          'GSTN','Others','KYC/CKYC'],

    frequencyPayee:{
              payeeType:"",
              otherName:"",
              payeeName:"",
              active:false,
              watchList:false,
              addressLine1:"",
              addressLine2:"",
              addressLine3:"",
              pinCode:"",
              city:"",
              district:"",
              state:"",
              country:"",
              panNumber:"",
              aadharId:"",
              gstNotApplicable:false,
              gstinNumber:"",
              lob:[],
              landlineNumber:[{std:"",number:""}],
              mobileNumber:[{isd:"91",number:""}],
              // emails:{id1:"",id2:"",id3:"",id4:""},
              emails: [{email:""}],
              faxNumber:"",
              endDate:"",
              category:"",
              contactPerson:{name:"",number:"",isdCode:"91"},
              proceedDuplicate:false,
              bank:{
                bankDetails:false,
                ifscCode:"",
                bankName:"",
                city: "",
                bankAddressLine1: "",
                bankAddressLine2: "",
                bankAddressLine3: "",
                branch: "",
                district: "",
                state: "",
                pinCode: "",
                accountNumber: "",
                accountHolderName:"",
                confirmAccountNumber: "",
                accountType: "",
                msme: 'No',
                enterpriseClassification: "",
                enterpriseCategory: "",
                uan: "",
                cKYC: "",
                documents: []
            },
            approveRequest: true,
            approveStatus: "",
            frequentPayeeId: "",
            ntidChangedUser: "",
            nameOfChangedUser: "",
            overwritten:[{
              status:false
            }]
            },
            lobOptions: [],
            isModify: false,
            mobile_Dialog: false,
            contact_Headers:[{text:"ISD",value:"isd",align:"center"},{text:"Contact Number",value:"number",align:"center"},{text:"Action",value:"action",align:"center", width:"107px"}],
            email_Headers:[{text:"Email",value:"email",align:"center"},{text:"Action",value:"action",align:"center"}],
            email_Dialog: false,
            email_Obj: [{email:""}],

            editBankAddress1:false,
            editBankAddress2:false,
            editBankAddress3:false,
            bankPincode: ''
        }
    },
    methods:{

      fetchAddressByPincode (pinCode = '') {
        try {
          if (pinCode.length !== 6) {
            return;
          }

          this.pincode1 = pinCode;
          this.changePincodeAndState(1)

        } catch (error) {
          console.log(error);
        }
      },

      fetchPinCodeFromBank () {
        if(this.bankPincode.length < 3) {
          return;
        }
        const reqData = "pageNo=1&perPage=100&pinCode=" + this.bankPincode
        this.GETM(
          "getPincodes",
          reqData,
          (res) => {
            this.searchPincode = res.data.data.data
          },(err) => {
            console.error("Issue while fetching pin codes.", err);
          })
      },


      checkBankAddress(){
 const specialChars = `\`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~`;

  specialChars.split('').some(specialChar => {
    if (this.frequencyPayee.bank.bankAddressLine1.includes(specialChar)) {
      this.editBankAddress1 = true
    }
   
  });

  specialChars.split('').some(specialChar => {
    if (this.frequencyPayee.bank.bankAddressLine2.includes(specialChar)) {
      this.editBankAddress2 = true
    }
  });

  specialChars.split('').some(specialChar => {
    if (this.frequencyPayee.bank.bankAddressLine3.includes(specialChar)) {
      this.editBankAddress3 = true
    }
  });
      },

      handleType(event) {
      
      const { srcElement, type } = event;
      const { name, value } = srcElement;

      if(type === 'blur' && !value) {
        this.fieldTypes[name] = 'text'
      } else {
        this.fieldTypes[name] = 'password'
      }
    },
        formatDate(date) {
      if (!date) return "";

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    checkBankAccount(isAccNum){
      if(this.frequencyPayee.bank.accountNumber && this.frequencyPayee.bank.confirmAccountNumber && (this.frequencyPayee.bank.accountNumber !== this.frequencyPayee.bank.confirmAccountNumber)){
            this.showAlert("Bank Account number and Re-enter Bank Account number do not match.");
            isAccNum ? this.frequencyPayee.bank.accountNumber= "" : this.frequencyPayee.bank.confirmAccountNumber= ""
        }
    },
    checkoverwritten(){
      this.showConfirm('Confirmation','Once checked cannot be unchecked. Do you want to proceed?','Yes','No',(Yes)=>{
        this.dialog_Overwritten=true
      },()=>this.frequencyPayee.overwritten[0].status=false)
    },
    clearBankDetails(){
      
      this.frequencyPayee.bank.ifscCode="",
      this.frequencyPayee.bank.bankName="",
      this.frequencyPayee.bank.city= "",
      this.frequencyPayee.bank.bankAddressLine1= "",
      this.frequencyPayee.bank.bankAddressLine2= "",
      this.frequencyPayee.bank.bankAddressLine3= "",
      this.frequencyPayee.bank.branch= "",
      this.frequencyPayee.bank.district= "",
      this.frequencyPayee.bank.state= "",
      this.frequencyPayee.bank.pinCode= "",
      this.bankPinCode= "",
      this.frequencyPayee.bank.accountNumber= "",
      this.frequencyPayee.bank.confirmAccountNumber= "",
      this.frequencyPayee.bank.accountType= "",
      // this.frequencyPayee.bank.msme= 'No',
      // this.frequencyPayee.bank.enterpriseClassification= "",
      // this.frequencyPayee.bank.enterpriseCategory= "",
      // this.frequencyPayee.bank.uan= "",
      // this.frequencyPayee.bank.cKYC= "",
      this.frequencyPayee.bank.accountHolderName= "",
                
                this.cancelledChequeData=[]
                this.isOCRUploaded=false
    },
    cancelChequeView() {
                this.cancelledChequeFileShow = !this.cancelledChequeFileShow;
        },
    dragover(event) {
                event.preventDefault();
                this.visualEffect = true;
            },
    dragleave(event) {
                this.visualEffect = false;
            },
    browse(){ //to select attachments
                document.getElementById("SP_fileupload").click()
            },

    drop(event) {
                event.preventDefault();
                this.all_attachments = event.dataTransfer.files;
                // console.log("all", this.all_attachments);
                 this.selectFile(); // Trigger the onChange event manually
                this.visualEffect = false;
            },
    dropCancel(event) {
                event.preventDefault();
                this.cancelledCheque = event.dataTransfer.files;
                // console.log("all", this.all_attachments);
                 this.OCRUpload(); // Trigger the onChange event manually
                
            },
            browseCancel(){ //to select attachments
                document.getElementById("cancelchequeupload").click()
            },
            dragoverCancel(event) {
                event.preventDefault();
                
            },
            // dragleaveCancel(event){

            // },
            checkBankDetails(){
              if(this.frequencyPayee.bank.bankDetails == false){
                this.cancelledCheque=null
                this.frequencyPayee.bank.ifscCode="",
      this.frequencyPayee.bank.bankName="",
      this.frequencyPayee.bank.city= "",
      this.frequencyPayee.bank.bankAddressLine1= "",
      this.frequencyPayee.bank.bankAddressLine2= "",
      this.frequencyPayee.bank.bankAddressLine3= "",
      this.frequencyPayee.bank.branch= "",
      this.frequencyPayee.bank.district= "",
      this.frequencyPayee.bank.state= "",
      this.frequencyPayee.bank.pinCode= "",
      this.bankPinCode= "",
      this.frequencyPayee.bank.accountNumber= "",
      this.frequencyPayee.bank.confirmAccountNumber= "",
      this.frequencyPayee.bank.accountType= "",
      this.frequencyPayee.bank.msme= 'No',
      this.frequencyPayee.bank.enterpriseClassification= "",
      this.frequencyPayee.bank.enterpriseCategory= "",
      this.frequencyPayee.bank.uan= "",
      this.frequencyPayee.bank.cKYC= "",
      this.frequencyPayee.bank.accountHolderName= "",
      this.dialog_Overwritten=false
      this.frequencyPayee.overwritten[0].status=false
                
                this.cancelledChequeData=[]
                this.isOCRUploaded=false
                              }
              else{
                this.showAlert("Please Upload Cancelled Cheque")
              }
            },

            selectFile(){
                if(this.all_attachments.length !== 0){
                    const fsize = this.all_attachments[0].size;
                    const file = Math.round(fsize / 1024);
                    let self = this
                    if (file > 5120) {
                        self.showAlert("File is too big, please select a file less than 5MB");
                    } else {
                        self.readImageM(self.all_attachments[0].name, self.all_attachments[0], (res) => { 
                          console.log(res)
                        var type = [];
                        type = self.all_attachments[0].type
                        console.log('--',self.documentTab)
                        if(self.documentTab == 'NEFT Form'){
                            // if(self.neftAttachment.length < 5){
                                self.neftAttachment.push({ 
                                  file: res.fileName,
                                    fileBase: res.base,
                                    documentType: 'NEFT Form'
                                })
                                self.neftAttachment = self.neftAttachment.reduce((unique, o) => {
                                    if(!unique.some(obj => obj.file === o.file)) {
                                        unique.push(o);                                        
                                    }
                                    return unique;
                                },[]);
                            // }else{
                            //     self.neftAttachment.splice(0,1)
                            //     self.neftAttachment.push({ 
                            //       file: res.fileName,
                            //         fileBase: res.base,
                            //         documentType: 'NEFT Form'
                            //     })
                            //     self.neftAttachment = self.neftAttachment.reduce((unique, o) => {
                            //         if(!unique.some(obj => obj.file === o.file)) {
                            //             unique.push(o);                                        
                            //         }
                            //         return unique;
                            //     },[]);
                            // }
                            console.log('---neft', self.neftAttachment)
                        }else if(self.documentTab == 'Others'){
                            // if(self.otherAttachment.length < 5){
                                self.otherAttachment.push({ 
                                  file: res.fileName,
                                    fileBase: res.base,
                                    documentType: 'OTHERS'
                                })
                                self.otherAttachment = self.otherAttachment.reduce((unique, o) => {
                                    if(!unique.some(obj => obj.file === o.file)) {
                                        unique.push(o);                                        
                                    }
                                    return unique;
                                },[]);
                            // }else{
                            //     self.otherAttachment.splice(0,1)
                            //     self.otherAttachment.push({ 
                            //       file: res.fileName,
                            //         fileBase: res.base,
                            //         documentType: 'OTHERS'
                            //     })
                            //     self.otherAttachment = self.otherAttachment.reduce((unique, o) => {
                            //         if(!unique.some(obj => obj.file === o.file)) {
                            //             unique.push(o);                                        
                            //         }
                            //         return unique;
                            //     },[]);
                            // }
                        }else if(self.documentTab == 'PAN Card'){
                            // if(self.panAttachment.length < 5){
                                self.panAttachment.push({ 
                                  file: res.fileName,
                                    fileBase: res.base,
                                    documentType: 'PAN Card'
                                })
                                self.panAttachment = self.panAttachment.reduce((unique, o) => {
                                    if(!unique.some(obj => obj.file === o.file)) {
                                        unique.push(o);                                        
                                    }
                                    return unique;
                                },[]);
                            // }else{
                            //     self.panAttachment.splice(0,1)
                            //     self.panAttachment.push({ 
                            //       file: res.fileName,
                            //         fileBase: res.base,
                            //         documentType: 'PAN Card'
                            //     })
                            //     self.panAttachment = self.panAttachment.reduce((unique, o) => {
                            //         if(!unique.some(obj => obj.file === o.file)) {
                            //             unique.push(o);
                                        
                            //         }
                            //         return unique;
                            //     },[]);
                            // }
                        }else if(self.documentTab == 'GSTN'){
                            // if(self.gstnAttachment.length < 5){
                                self.gstnAttachment.push({ 
                                  file: res.fileName,
                                    fileBase: res.base,
                                    documentType: 'GSTN'
                                })
                                self.gstnAttachment = self.gstnAttachment.reduce((unique, o) => {
                                    if(!unique.some(obj => obj.file === o.file)) {
                                        unique.push(o);                                        
                                    }
                                    return unique;
                                },[]);
                            // }else{
                            //     self.gstnAttachment.splice(0,1)
                            //     self.gstnAttachment.push({ 
                            //       file: res.fileName,
                            //         fileBase: res.base,
                            //         documentType: 'GSTN'
                            //     })
                            //     self.gstnAttachment = self.gstnAttachment.reduce((unique, o) => {
                            //         if(!unique.some(obj => obj.file === o.file)) {
                            //             unique.push(o);
                                        
                            //         }
                            //         return unique;
                            //     },[]);
                            // }
                        }else{
                            // if(self.kycAttachment.length < 5){
                                self.kycAttachment.push({ 
                                  file: res.fileName,
                                    fileBase: res.base,
                                    documentType: 'KYC/CKYC'
                                })
                                self.kycAttachment = self.kycAttachment.reduce((unique, o) => {
                                    if(!unique.some(obj => obj.file === o.file)) {
                                        unique.push(o);                                        
                                    }
                                    return unique;
                                },[]);
                            // }else{
                            //     self.kycAttachment.splice(0,1)
                            //     self.kycAttachment.push({ 
                            //       file: res.fileName,
                            //         fileBase: res.base,
                            //         documentType: 'KYC/CKYC'
                            //     })
                            //     self.kycAttachment = self.kycAttachment.reduce((unique, o) => {
                            //         if(!unique.some(obj => obj.file === o.file)) {
                            //             unique.push(o);
                                        
                            //         }
                            //         return unique;
                            //     },[]);
                            // }
                        }
                        // // Phase-2 function call starts
                        // this.getCancelledChequeData(res.base);
                        // this.manualOverwrite = false;
                        // // Phase-2 function call ends
                        this.all_attachments=[]
                        });
                    }
                }
                setTimeout(() => {
                  this.checkTabChanged()
                }, 100);
            },
      viewDocumentCheque(id){
        let requestData = "documentId=" + id + "&b64=true";
        this.GETM(
          "VIEW_DOCUMENT",
          requestData,
          (res) => {
            if (res.data.statusCode === 200) {
              this.showCheque=res.data.data
            }
          },
          (error) => {
            console.log("Error", error);
          },
          true
        );
      },
      viewDocument(itemData) {
      if (itemData.fileBase) {
        this.viewDocUsingBase(itemData.fileBase);
      } else {
        let requestData = "";
        if (itemData !== null) {
          requestData = "documentId=" + itemData.documentId + "&b64=true";
        }
        this.GETM(
          "VIEW_DOCUMENT",
          requestData,
          (res) => {
            if (res.data.statusCode === 200) {
              this.viewDocUsingBase(res.data.data);
            }
          },
          (error) => {
            console.log("Error", error);
          },
          true
        );
      }
    },
    viewDocUsingBase(base64) {
      const base64ImageData = base64;
      const contentType = base64.split(":")[1].split(";")[0];
      const byteCharacters = window.atob(
        base64ImageData.substr(`data:${contentType};base64,`.length)
      );
      const byteArrays = [];
      for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
        const slice = byteCharacters.slice(offset, offset + 1024);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
      const blob = new Blob(byteArrays, { type: contentType });
      const blobUrl = window.URL.createObjectURL(blob);
      window.open(blobUrl, "_blank");
    },

    downloadDoc(item) {
                if(item.fileBase) {
                    let base64 = item.fileBase;
                    const base64ImageData = base64
                    const contentType = base64.split(':')[1].split(';')[0];
                    const byteCharacters = window.atob(base64ImageData.substr(`data:${contentType};base64,`.length));
                    const byteArrays = [];
                    for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
                        const slice = byteCharacters.slice(offset, offset + 1024);
                        const byteNumbers = new Array(slice.length);
                    for (let i = 0; i < slice.length; i++) {
                        byteNumbers[i] = slice.charCodeAt(i);
                    }
                    const byteArray = new Uint8Array(byteNumbers);
                        byteArrays.push(byteArray);
                    }
                    const blob = new Blob(byteArrays, {type: contentType});
                    const blobUrl =  window.URL.createObjectURL(blob);

                    const element = document.createElement("a");
                    element.href = blobUrl;
                    element.download = item.file;
                    element.click();
                } else {
                    let url = this.getURL("VIEW_DOCUMENT") + "?documentId=" + item.documentId;
                    window.open(url);
                }
            },

            confirmDelete(index){
              this.showConfirm("Confirmation", "Are you sure want to Delete?", "Yes", "No",
              () => this.deleteDocument(index))
            },

            deleteDocument(index) {
                if(this.documentTab=='NEFT Form'){
        this.neftAttachment.splice(index, 1);
      }
      
      if(this.documentTab=='PAN Card'){
        this.panAttachment.splice(index, 1);
      }
      if(this.documentTab=='GSTN'){
        this.gstnAttachment.splice(index, 1);
      }
      if(this.documentTab=='Others'){
      this.otherAttachment.splice(index, 1);
      }
      if(this.documentTab=='KYC/CKYC'){
      this.kycAttachment.splice(index, 1);
      }
      this.checkTabChanged()
            },


    checkTabChanged(){
      if(this.documentTab=='NEFT Form'){
        this.documentAttachments=this.neftAttachment
      }
     
      if(this.documentTab=='PAN Card'){
        this.documentAttachments=this.panAttachment
      }
      if(this.documentTab=='GSTN'){
        this.documentAttachments=this.gstnAttachment
      }
      if(this.documentTab=='Others'){
        this.documentAttachments=this.otherAttachment
      }
      if(this.documentTab=='KYC/CKYC'){
        this.documentAttachments=this.kycAttachment
      }
      this.documentAttachments = this.documentAttachments.map(item => {
        return {...item, createdBy: this.frequencyPayee.createdBy, updatedAt: this.frequencyPayee.updatedAt}
      })
    },
    getFormattedDate(date) {
      if(date === "" || date === null) return "";
      return moment(date).format("YYYY-MM-DD");
    },
    formatDateTime(date) {
        return date
            ? moment(date).format("DD - MMM - YYYY") +
            " " +
            moment(date).format("LT")
            : "";
    },
    checkPincode(){
      if(this.pincode.length>=3){
      let reqData = "pageNo=1&perPage=100&pinCode=" + this.pincode
        this.GETM(
          "getPincodes",
          reqData,
          (res) => {
            let a=res.data.data.data
            this.searchPincode=a
            
          },(err) => {
            console.error("Issue while fetching pin codes.", err);
          })
    }
  },
OCRUpload() {
            if (this.cancelledCheque) {
                this.cancelledChequeFileShow = false; //hide view port
                this.isOCRUploaded = false; //check if OCR uploaded
                this.frequencyPayee.overwritten[0].status = false; //check for manual entry
                this.clearBankDetails(); //reset bank fields
                if (!this.cancelledCheque.name){
                    return // for while resetting the form
                }
                this.showProgress();
                
                this.readImageM(this.cancelledCheque.name, this.cancelledCheque, (res) => {
                  console.log(res)
                    let requestPayload = {
                        document: res.base,
                    };
                    this.cancelledCheque = []; //reset field value
                    this.POSTM(
                        "GET_OCR_FORMAT",
                        requestPayload,
                        (response) => {
                            this.hideProgress();
                            if (response.data.data.Message == "Uploaded Image/Document orientation is incorrect") {
                                this.showAlert(response.data.data.Message);
                                this.frequencyPayee.overwritten[0].status = true;
                            } else if (response.data.data.Message == "Invalid Image/Document") {
                                this.showAlert(response.data.data.Message);
                                this.frequencyPayee.overwritten[0].status = true;
                            } else if (response.data.data) {
                                let bankres = response.data.data;
                                console.log(response.data.data)
                                if (!bankres.AccountNumber || !bankres.IFSC || !bankres.PayerName) {
                                    this.isOCRUploaded = true;
                                    
                                    this.frequencyPayee.overwritten[0].status = true;
                                    this.showAlert("Could not retrieve data from OCR either re-upload the cancelled cheque or enter the details manually");
                                } else {
                                    
                                    //temprory fix
                                    this.showProgress();
                                    let request = bankres.IFSC + "?lob=Marine";
                                    console.log("%%",bankres)
                                    this.frequencyPayee.bank.accountHolderName=bankres.PayerName;
                                    this.GET_ByURLM(
                                        "GET_GC_BANK",
                                        request,
                                        (res) => {
                                            this.IFSCCodes = [];
                                            this.hideProgress();
                                            console.log(res.data.data)
                                            if (res.data.data && res.data.data.length > 0) {
                                                let ifscCodeValue = res.data.data[0];
                                                this.IFSCCodes = res.data.data;
                                                this.frequencyPayee.bank.ifscCode = bankres.IFSC;
                                                this.frequencyPayee.bank.accountNumber=bankres.AccountNumber
                                                this.checkBankAccount()
                                                this.dialog_Overwritten=false
                                                this.frequencyPayee.bank.confirmAccountNumber=bankres.AccountNumber
                                                this.frequencyPayee.bank.branch =ifscCodeValue.branchName

                                                this.frequencyPayee.bank.bankName = ifscCodeValue.financierName;
                                                this.frequencyPayee.bank.bankAddressLine1 = ifscCodeValue.branchAddress;
                                                let address2 = '';
                                                if (ifscCodeValue.district) {
                                                    address2 += `${ifscCodeValue.district}, `
                                                }
                                                if (ifscCodeValue.city) {
                                                    address2 += `${ifscCodeValue.city}, `
                                                }
                                                if (ifscCodeValue.pincode) {
                                                    address2 += ifscCodeValue.pincode
                                                }
                                                this.frequencyPayee.bank.bankAddressLine2 = address2;
                                                this.frequencyPayee.bank.bankAddressLine3 = ifscCodeValue.state;
                                                this.checkBankAddress()
                                                this.frequencyPayee.bank.pinCode=ifscCodeValue.pincode
                                                this.bankPincode=ifscCodeValue.pincode
                                                this.pincode1=ifscCodeValue.pincode
                                                
                                                this.changePincodeAndState(1)
                                                // this.frequencyPayee.bankAddressLine2 = `${ifscCodeValue.district}, ${ifscCodeValue.city}, ${ifscCodeValue.state}`
                                                this.showAlert("OCR uploaded successfully");
                                            }
                                        },
                                        (error) => {
                                            this.IFSCCodes = [];
                                            this.hideProgress();
                                            console.log("error", error);
                                            this.showAlert(error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message);
                                        }, true
                                    );
                                    this.cancelledChequeData.push(
                                        {
                                            file: res.fileName,
                                            fileBase: res.base,
                                            documentType: "Cancelled Cheque",
                                            
                                        }
                                    )
                                    console.log(this.cancelledCheque)
                                    
                                    // this.frequencyPayee.bankAccountNumber = bankres.AccountNumber;
                                    // this.reEnterBankAccountNumber = bankres.AccountNumber;
                                    // this.frequencyPayee.consumerFirstName = bankres.PayerName;
                                    // this.isOCRUploaded = true;
                                }
                            } else {
                                this.showAlert("No data found from OCR");
                            }
                        },
                        (error) => {
                            this.hideProgress();
                            this.frequencyPayee.overwritten[0].status = true;
                            this.showAlert("Somthing went wrong in OCR,Please enter the details manually");
                        }, true
                    );
                });
            } else {
                this.showAlert("Please upload a file.")
            }
            this.cancelledCheque=[]
        },
   
    
    ifscData(){
            if(this.ifsc.length>3){
              let request =  this.ifsc+ "?lob=Marine";
              this.GET_ByURLM("GET_GC_BANK",
              request,
              (res) => {
                this.IFSCCodes = [];
                this.hideProgress();
                console.log(res.data.data)
                if (res.data.data && res.data.data.length > 0) {
                  this.IFSCCodes=res.data.data
                }},
                (error) => {
                  this.IFSCCodes = [];
                  this.hideProgress();
                  console.log("error", error);
                  this.showAlert(error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message);
                }, true);
              }
            },
    ifscchange(){
      this.IFSCCodes.forEach(ifsc=>{
        if(this.frequencyPayee.bank.ifscCode==ifsc.ifscCode){
              let ifscCodeValue = ifsc
              // this.IFSCCodes=res.data.data
              this.frequencyPayee.bank.branch =ifscCodeValue.branchName
              this.frequencyPayee.bank.bankName = ifscCodeValue.financierName;
              this.frequencyPayee.bank.bankAddressLine1 = ifscCodeValue.branchAddress;
              let address2 = '';
              if (ifscCodeValue.district) {
                address2 += `${ifscCodeValue.district}, `
              }
              if (ifscCodeValue.city) {
                address2 += `${ifscCodeValue.city}, `
              }
              if (ifscCodeValue.pincode) {
                address2 += ifscCodeValue.pincode
              }
              this.frequencyPayee.bank.bankAddressLine2 = address2;
              this.frequencyPayee.bank.bankAddressLine3 = ifscCodeValue.state;
              this.frequencyPayee.bank.pinCode=ifscCodeValue.pincode
              this.bankPinCode=ifscCodeValue.pincode
              this.pincode1=ifscCodeValue.pincode
              this.changePincodeAndState(1)
        }
       
      })
      
    },
  changePincodeAndState(data=0){
    
    if(data==1){
      if(this.pincode1.length>=3){
        let reqData = "pageNo=1&perPage=100&pinCode=" + this.pincode1
        this.GETM("getPincodes",reqData,(res) => {
          let a=res.data.data.data
          this.searchPincode1=a
          console.log(this.searchPincode1)
          this.bankDetailsPincode()
        },
        (err) => {console.error("Issue while fetching pin codes.", err);})
      }
    }else{
      let data=""
      this.searchPincode.forEach(i=>{
        if(i.pinCode == this.frequencyPayee.pinCode){
          console.log(i.pinCode)
          data= i
        }
      })
    // this.frequencyPayee.pinCode=a.pinCode
            this.frequencyPayee.city = data.pinCodeLocality;
        this.frequencyPayee.state  = data.stateName;
        this.frequencyPayee.district  = data.cityDistrictName;
        this.frequencyPayee.country=data.countryName}
    
  },
  bankDetailsPincode(){
    let data=this.searchPincode1[0]
    console.log(this.searchPincode1[0])
    this.frequencyPayee.bank.city = data.cityDistrictName;
    this.frequencyPayee.bank.state  = data.stateName;
    this.frequencyPayee.bank.district  = data.cityDistrictName;
          
  },
  ifsc_clear(){
    this.cancelledChequeData=[]
    this.IFSCCodes = [];
  },
  onReset(){
    this.showConfirm("Confirmation","Are you sure you want to Reset?","Yes","No",(yes)=>{
      this.frequencyPayee={
              payeeType:"",
              otherName:"",
              payeeName:"",
              active:false,
              watchList:false,
              addressLine1:"",
              addressLine2:"",
              addressLine3:"",
              pinCode:"",
              city:"",
              district:"",
              state:"",
              country:"",
              panNumber:"",
              aadharId:"",
              gstNotApplicable:false,
              gstinNumber:"",
              lob:[],
              landlineNumber:[{std:"",number:""}],
              mobileNumber:[{isd:"",number:""}],
              // emails:{id1:"",id2:"",id3:"",id4:""},
              faxNumber:"",
              endDate:"",
              category:"",
              contactPerson:{name:"",number:"",isdCode:""},
              bank:{
                bankDetails:false,
                ifscCode:"",
                bankName:"",
                city: "",
                bankAddressLine1: "",
                bankAddressLine2: "",
                bankAddressLine3: "",
                branch: "",
                district: "",
                state: "",
                pinCode: "",
                accountNumber: "",
                confirmAccountNumber: "",
                accountType: "",
                msme: 'No',
                enterpriseClassification: "",
                enterpriseCategory: "",
                uan: "",
                cKYC: "",
                accountHolderName: "",
                documents: [],
                
              },
              overwritten:[
                  {
                    status: true,
                  }
                ],
                
  },
  this.email_Obj = [{email:""}]
  this.checkBankDetails()
  this.panAttachment=[]
  this.neftAttachment=[]
  this.gstnAttachment=[]
  this.otherAttachment=[]
  this.kycAttachment=[]
  this.checkTabChanged()
    })
   
},
  cancel(data){
                  if(data == 1){
                    this.$router.push({name:'SearchFrequentPayee'} );
              }else{
              this.showConfirm("Confirmation","Are you sure you want to leave?","Yes","No",(Yes) => {
                    this.showProgress();
                    this.$router.push({name:'SearchFrequentPayee'} );//params:{pageNo:this.pageNo}}
                    this.hideProgress();
                })
              }
            },
            get_data(value){
              let a=`${value._id}`
       this.GET_ByURLM('getFrequent',a,(res)=>{
        console.log(res.data.data,this.$route.params.isView)
        this.frequencyPayee=res.data.data
        // console.log("checking",this.frequencyPayee.bank.bankAddressLine1.includes("+", "-", "&&", "||", "!", "(", ")", "{", "}", "[", "]", "^",
        //     "~", "*", "?", ":","\"","\\"))
        //     console.log("checking",this.frequencyPayee.bank.bankAddressLine2.includes("+", "-", "&&", "||", "!", "(", ")", "{", "}", "[", "]", "^",
        //     "~", "*", "?", ":","\"","\\"))
        //     console.log("checking",this.frequencyPayee.bank.bankAddressLine3.includes("+", "-", "&&", "||", "!", "(", ")", "{", "}", "[", "]", "^",
        //     "~", "*", "?", ":","\"","\\"))

        // if(this.frequencyPayee.bank.bankAddressLine1 || 
        //     this.frequencyPayee.bank.bankAddressLine2 || 
        //     this.frequencyPayee.bank.bankAddressLine3){
        //   console.log("ADDRESS 1",this.frequencyPayee.bank.bankAddressLine1)
        //   console.log("ADDRESS 2",this.frequencyPayee.bank.bankAddressLine2)
        //   console.log("ADDRESS 3",this.frequencyPayee.bank.bankAddressLine3)
        // }
        this.checkBankAddress()
        this.frequencyPayee.endDate=this.getFormattedDate(this.frequencyPayee.endDate)
        this.pincode=this.frequencyPayee.pinCode
        this.checkPincode()
        this.email_Obj= this.frequencyPayee.emails.map(e=>{return {email:e}})
        this.frequencyPayee.bank.documents.forEach(e=>{
          if(e.documentType == 'NEFT Form'){
            e.file=e.fileName
            // delete e.fileName
            this.neftAttachment.push(e)
          }
          if(e.documentType == 'PAN Card'){
            e.file=e.fileName
            // delete e.fileName
            this.panAttachment.push(e)
          }
          if(e.documentType == 'GSTN'){
            e.file=e.fileName
            // delete e.fileName
            this.gstnAttachment.push(e)
          }
          if(e.documentType == 'OTHERS'){
            e.file=e.fileName
            // delete e.fileName
            this.otherAttachment.push(e)
          }
          if(e.documentType == 'KYC/CKYC'){
            e.file=e.fileName
            // delete e.fileName
            this.kycAttachment.push(e)
          }
          if(e.documentType == 'Cancelled Cheque'){
            e.file=e.fileName
            // delete e.fileName
            this.cancelledChequeData.push(e)
            this.viewDocumentCheque(e.documentId)
          }
          
        })
        this.ifsc=this.frequencyPayee.bank.ifscCode
        this.ifscData()
        this.checkTabChanged()
        // this.checkBankAccount()
       })
            },
            create_frequent(){
              this.POSTM(
          "createFrequent",
          this.frequencyPayee,
          (res) => {
            this.hideProgress();
            if (res.data && res.data.statusCode == 200) {
              this.showAlert(res?.data?.msg || "Success", "", "", () => {
                
                
                this.cancel(1)
                // this.$router.push('/Claim-processing/Child-Eduction/add-assesment-sheet');
                                
              });
              // this.isView=true
            }else if(res.data && res.data.statusCode == 400&&res.data.duplicate){
              return this.showAlert(res.data.msg)
            } else {
              this.showAlert(res.data.msg);
              this.hideProgress();
            }
          },
          (err) => {
            console.log(err);
            this.hideProgress();
            let msg =
              err?.response?.data?.msg || "Unable to submit data.";
            this.showAlert(msg);
          },true
        );
            },
            update_frequent(){
              this.frequencyPayee.approveRequest= true,
          this.PUTM(
          "updateFrequent",this.frequencyPayee._id,
          this.frequencyPayee,
          (res) => {
            this.hideProgress();
            if (res.data && res.data.statusCode == 200) {
              this.showAlert(res?.data?.msg || "Success", "", "", () => {
                
                
                this.cancel(1)
                // this.$router.push('/Claim-processing/Child-Eduction/add-assesment-sheet');
                                
              });
              // this.isView=true
            }else if(res.data && res.data.statusCode == 400&&res.data.duplicate){
              this.showConfirm('Confirmation',res.data.msg,"Yes","No",(Yes) => {this.frequencyPayee.proceedDuplicate=res.data.duplicate; update_frequent()})
            }  else {
              this.showAlert(res.data.msg);
              this.hideProgress();
            }
          },
          (err) => {
            console.log(err);
            this.hideProgress();
            let msg =
              err?.response?.data?.msg || "Unable to submit data.";
            this.showAlert(msg);
          },true
        );
            },
  onSubmit(){
    if (!this.$refs.claimForm.validate()) {
        return this.showAlert("Please fill mandatory fields.");
      }else{
        this.frequencyPayee.emails = this.email_Obj.map(g=>  g.email)
        this.frequencyPayee.bank.documents=[...this.neftAttachment,...this.cancelledChequeData,
      ...this.panAttachment,...this.gstnAttachment,...this.otherAttachment,...this.kycAttachment]
        if(this.frequencyPayee._id){
         
          this.update_frequent()
          
        }else{
        this.create_frequent()
        
      
        }
      }
  },
  
  getLOB() {
        let reqData = '';
        this.showProgress();
        this.GETM("getLOB", reqData, (res) => {
            this.hideProgress();
            if(res.data.statusCode === 200) {
                this.lobOptions = res.data.data[0].lineOfBusiness;
            } else {
                this.lobOptions = [];
                this.showAlert(res.data.msg);
            }
        }, (error) => {
            console.log(error);
            this.hideProgress();
        }, true)
    },

    contact_Add_Row(){
        this.frequencyPayee.mobileNumber.push({isd:"91",number:""})
    },
    
    contact_Delete(index){
           
           if(this.frequencyPayee.mobileNumber.length == 1){
            return this.showAlert("Atleast one row is mandatory")
           }else{
           this.showConfirm(
         "Confirmation",
         "Are you sure you want to delete?",
         "Yes",
         "No",
         (Yes) => {
               this.frequencyPayee.mobileNumber.splice(index,1)
         },
         (No) => {}
       )
           }
       },
       
       email_Delete(index){
          if(this.email_Obj.length == 1){
              return this.showAlert("Atleast one row is mandatory")
          }else{
          this.showConfirm(
        "Confirmation",
        "Are you sure you want to delete?",
        "Yes",
        "No",
        (Yes) => {
          this.email_Obj.splice(index,1)
        },
        (No) => {}
      )
          }
      },
      email_Add_Row(){
        this.email_Obj.push({email:""})
      },
    },
    computed:{
        computedEndDate() {
       return this.formatDate(this.frequencyPayee.endDate)
    },
    getEndDate() {
     var endDate = new Date();
     return endDate.toISOString().slice(0,10)
    },
    computedPAN() {
            this.frequencyPayee.panNumber = this.frequencyPayee.panNumber ? this.frequencyPayee.panNumber.toUpperCase() : '';
            return this.frequencyPayee.panNumber;
        },
        computedGST() {
            this.frequencyPayee.gstinNumber = this.frequencyPayee.gstinNumber ? this.frequencyPayee.gstinNumber.toUpperCase() : '';
            return this.frequencyPayee.gstinNumber;
        },
    },
    created(){
      this.getLOB();
      if(Object.keys(this.$route.params).length != 0){
        this.get_data(this.$route.params.item)
        
       
      }
      this.isView=this.$route.params.isView
      if(this.$route.params.isView && Object.keys(this.$route.params).length != 0){
        this.heading="View"
      }
      if(!this.$route.params.isView && Object.keys(this.$route.params).length != 0){
        this.isModify = !this.$route.params.isView
        this.heading="Modify"
      }
      this.$store.commit("SAVE_PAGE_HEADER", "Frequent Payee Master");
    },
}
</script>

<style scoped>
.borderStyle{
        border:0.5px solid #d7d7d7;
        /* margin:0 21px 3px 8px; */
    }
    .btn{
 
 text-transform: capitalize;
}
.tabelwidth{
  width:620px;
  margin:0px 150px
}
.neftStyle{
        height: 150px;
        width: 350px;
        border: 3px dashed #ccc;
        padding-left:10px;
        display: table-cell;
        vertical-align: middle;
        text-align: center;
    }
.dragbackground{
        background-color: #e7e7e7;
    }  
.dlt_text{
        color: rgb(145, 145, 145);
    }
.btnToggle {
  text-transform: none;
  color: #fff;
  background-color: #1ebbd9;
}
.margincls{
        font-size:12px;
        margin-left:-12px
    }
.iconstyle {
  background: #f7f7f7 0% 0% no-repeat padding-box;
  border: 0.5px solid #c1c8cc !important;
  color: #fff;
  width: 29px;
  margin-left: -0.1em;
  height: 38px;
  color: #6b6b6b;
}
.f-group-inner .v-input.input-1 {
  margin-right: 0.75em !important;
}
.cancel-cheque-view-port {
    background: #dddddd;
    margin-bottom: 30px;
    position: relative;
}
.close-cancell-cheque:hover {
    background: transparent !important;
}
.close-cancell-cheque {
    cursor: pointer;
    position: absolute;
    right: 0;
    font: normal 500 12px Roboto !important;
    color: #000000;
    text-transform: uppercase;
    padding: 0 15px;
}
.blueiconstyle {
  width: 38px;
  height: 38px;
  background: #01bbd9 0% 0% no-repeat padding-box;
  margin-right: -0.5em;
  color: #f7f7f7;
}
.claimProcessingTabsActive {
  background-color: #1ebbd9;
  color: #fff !important;
}
.uploadbr {
  text-align: center;
  height: 150px;
  max-width: 340px;
  border: 3px dashed #ccc;
  padding-top: 40px;
  /* padding-left: 16%; */
  cursor: pointer;
}
.unActive{
  border:1px solid #ccc;
  margin: 0px 5px
}
.form-groups{
  max-width: 300px;
}
</style>