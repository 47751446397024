 <template>
    <v-app>
        <v-card color="" class="pa-3 mb-1" style="" tile>
          <v-layout align-center class="py-2">
            <v-text class="text-uppercase font-weight-bold title-clr-prtbs ml-2">
              Provider Details
            </v-text>
            <v-divider vertical class="mx-4"></v-divider>
             <v-text class="font-weight-bold cstm-clr-prtbs">
                Registered Entity *
              </v-text>
              <v-divider vertical class="mx-4"></v-divider>
            <v-btn-toggle dense mandatory v-model="text" tile class="" @change="tabseleted(text)" style="background-color:#F7F7F7;opacity:100%;"  >
                <v-btn  value="indvdl"  class="text-capitalize font-weight-bold px-5" active-class="srSelected">
                  <span >Individual</span>
                </v-btn>
                <v-btn  value="instutn" class="text-capitalize font-weight-bold  px-5" active-class="srSelected">
                  <span >Institution</span>
                </v-btn>
               
                
            </v-btn-toggle>

          </v-layout>
          
        </v-card>

            <Individual  :indtype="{type:'new',data:{indata:null}}" :dataFromRoute="{data:routeData}" v-if="indvdlshw===true"></Individual>
            <Institution :indtype="{type:'new',data:{indata:null}}" :dataFromRoute="{data:routeData}" v-if="instutinshw===true"></Institution>
    </v-app>
</template>
<script>

import Individual from './Individual.vue'
import Institution from './Institution.vue'
export default {

    components:{
      
        Individual,
        Institution,
        
    },

    created() {

      console.log("%c coming from route","background-color:red",this.$route.params); 
      if(this.$route.params){
        if(this.$route.params.empanelType === 'Institution'){
              this.text = 'instutn'
              this.indvdlshw=false
              this.instutinshw=true
              this.routeData = this.$route.params
        }else{
               this.text = 'indvdl'
               this.indvdlshw=true
              this.instutinshw=false
               this.routeData = this.$route.params
        }
      }
        this.$store.commit("SAVE_PAGE_HEADER", "Provider Registration");

    },

    computed: {

    },

    data() {
        return {
        
          indvdlshw:true,
          instutinshw:false,
          text:"indvdl",
          routeData:'',
        };



    },

    methods: {

        tabseleted(itm){
            console.log("Selected tab",itm);
            if(itm==="indvdl"){
              this.indvdlshw=true
              this.instutinshw=false
            }else if(itm==="instutn"){
              this.indvdlshw=false
              this.instutinshw=true
            }
        }
       

    },

    watch: {
    
    },
    
    
}

</script>
<style >

.srSelected{
  background-color: #23B1A9 !important;
  color:white !important
}

.activeredbackground{
  color:white !important;
}

.title-clr-prtbs{
    color:#10242B !important;
    /* margin-bottom: 5px; */
}
.cstm-clr-prtbs{
    color:#646666 !important;
    /* margin-bottom: 5px !important; */
    font-size: 13px !important;
}

.btn_pr_tabs{
  color:#10242B;
  opacity:100% !important;  
  font-size:13px !important;
  font-weight: 700;
  /* border-left:0.5px solid #C1C8CC; */

    border: solid;
    border-top-width: thin;
    border-right-width: thin;
    border-bottom-width: thin;
    border-left-width: thin;
    transition: inherit;
    border-top-color: rgba(255, 255, 255, 0.26);
    border-right-color: rgba(255, 255, 255, 0.26);
    border-bottom-color: rgba(255, 255, 255, 0.26);
    border-left-color: rgba(193, 200, 204, 0.26);
}

</style>