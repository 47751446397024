var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-form',{ref:"otForm",model:{value:(_vm.icuvalid),callback:function ($$v) {_vm.icuvalid=$$v},expression:"icuvalid"}},[_c('v-card',{attrs:{"tile":"","flat":""}},[_c('div',{staticClass:"px-4 pt-4"},[_c('v-flex',{attrs:{"xs3":""}},[_c('v-layout',{staticClass:"cust_pln_Ot mx-2 mb-4",attrs:{"column":""}},[_c('p',{staticClass:"font-weight-bold cstm-clr-pln-Ot"},[_vm._v(" Discount Percentage ")]),_c('v-text-field',{staticClass:"rounded-0",attrs:{"solo":"","label":"Enter...","maxlength":_vm.max05,"disabled":_vm.fromview,"rules":[_vm.Numeric2]},model:{value:(_vm.dscntPercntg),callback:function ($$v) {_vm.dscntPercntg=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"dscntPercntg"}})],1)],1)],1)]),_c('div',{staticClass:"px-4 py-3",staticStyle:{"background-color":"#F7F7F7"}},[_c('p',{staticClass:"font-weight-bold cstm-clr-pln-Ot pa-0 ma-0"},[_vm._v("Ambulance Charges")])]),_c('v-divider'),_c('v-card',{attrs:{"tile":""}},[_c('div',[(_vm.shwDtaTbl===true)?_c('tableis',{ref:"AmbulanceCharges",attrs:{"tabledata":{
                                tabledata:_vm.addCategory,
                                headersdata:_vm.headersNew,
                                dd:_vm.medConsumablesLvl4,
                                tariffshw:false,
                                roomconfig:_vm.temproomtype,
                                req:true,
                                del_endpoint:'Ambulance Charges',
                                  url:'tariff/get-ambulance-charges'
                            }},on:{"save":_vm.saveMedConsmechrgs}}):_vm._e()],1)]),(!_vm.fromview)?_c('v-card',{staticClass:"mt-5 pa-4 overflow-y-auto",attrs:{"color":"#F7F7F7"}},[_c('v-row',{staticClass:"mt-1 mr-1 float-right"},[_c('v-btn',{staticClass:"text-capitalize mr-6",attrs:{"tile":"","dark":"","color":"#152F38","small":""},on:{"click":function($event){return _vm.save_medConsme_ctgry()}}},[_c('v-icon',{attrs:{"color":"","small":""}},[_vm._v("mdi-plus-circle")]),_c('v-divider',{staticClass:"ml-2 mr-2",attrs:{"vertical":""}}),_vm._v("Add Category ")],1),_c('v-btn',{staticClass:"text-capitalize ml-20",attrs:{"tile":"","dark":"","color":"#23B1A9","small":""},on:{"click":function($event){return _vm.save_ot_confg()}}},[_c('v-icon',{attrs:{"color":"","small":""}},[_vm._v("check_circle")]),_c('v-divider',{staticClass:"ml-2 mr-2",attrs:{"vertical":""}}),_vm._v("Save ")],1)],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }