<template>
    <v-app>
       <v-form ref="officeSearchForm" @submit.prevent="searchOffice" class="formFont">
        <v-row class="row_line">
                <v-flex xs3 class="mr-2">
                    <v-layout column class="mx-2 my-2">
                        <p class="font-weight-bold cstm-clr-pr-quick">
                            Name of AIG Office
                        </p>
                        
                        <v-text-field
                            v-model="searchForm.aigOfficeName"
                            placeholder="Enter.."
                            class="rounded-0"                  
                            solo 
                            dense                
                            :maxlength="max100"
                        ></v-text-field>
                    </v-layout>
                </v-flex>

                 <v-flex xs3 class="mr-2">
                    <v-layout column class="mx-2 my-2">
                        <p class="font-weight-bold cstm-clr-pr-quick">
                            Country
                        </p>
                        <v-autocomplete
                            v-model="searchForm.country"
                            :items="countryList"
                            item-text="countryName"
                            class="rounded-0" 
                            placeholder="Select"   
                            label="Select" 
                            solo 
                            dense 
                            chips
                            clearable
                            deletable-chips
                            multiple
                            small-chips
                        ></v-autocomplete>
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                </v-flex>

                <v-flex xs3>
                </v-flex>

                <v-flex xs3>
                </v-flex>
            </v-row>

            <v-row  class="row_line buttonsRowbackGround">
                <v-flex>
                    <v-layout class="buttonsLayout">
                        <v-btn
                            medium
                            right       
                            color="#152F38"
                            dark
                            class="ma-2"                                                                      
                            @click="createNewOffice"
                        >
                        <v-icon class="mr-1"> mdi-plus </v-icon>
                        <div class="vertical_line"></div>
                          Create
                        </v-btn>

                        <v-btn
                            medium
                            right   
                            color="#E46A25"
                            dark
                            class="ma-2"                                                 
                            type = "submit"
                        >
                        <v-icon class="mr-1"> mdi-magnify </v-icon>
                        <div class="vertical_line"></div>
                          Search
                        </v-btn>

                         <v-btn
                            medium
                            right    
                            color="#E46A25"
                            dark
                            class="ma-2"                        
                            @click="clearForm"
                        >
                        <v-icon class="mr-1"> mdi-close-circle-outline </v-icon>
                        <div class="vertical_line"></div>
                          Clear
                        </v-btn>

                        <v-btn
                            medium
                            right
                            color="#152F38"
                            dark
                            class="ma-2"   
                            @click="goBack"                     
                        >
                        <v-icon class="mr-1"> mdi-arrow-left-circle </v-icon>
                        <div class="vertical_line"></div>
                         Back
                        </v-btn>                        
                    </v-layout>
                </v-flex>
            </v-row>

            <!--  -->

            <div class="search_result_txt graybackground font-weight-bold ml-0">
                Search Results
            </div>
            <v-flex id="claimListTable" class="overflowHIden" xs12>
                <v-simple-table
                    height="550px"
                >
                    <template v-slot:default >
                        <thead class="greencolor">
                            <tr >
                                <th class="text-left tableHead"  v-for="item in claimColumnList"
                                :key="item" >
                                    {{item}}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                            v-for="(office,index) in officeTableList"
                            :key="index"
                            >
                            <td class="colborder" >{{ index +1 }}</td>
                            <td class="colborder cursorPoniter" @click="goToOfficeDetails(index)">{{ office.aigCode }}</td>
                            <td class="colborder" >{{ office.nameOfAigOffice }}</td>
                            <td class="colborder">{{ office.countryName }}</td>
                            <td class="colborder">{{ office.addressLine1 }}</td>
                            <td class="colborder">{{ office.addressLine2 }}</td>
                            <td class="colborder">{{ office.addressLine3 }}</td>
                            <td class="colborder">{{ office.contactPerson1.name }}</td>
                            <td class="colborder">{{ office.contactPerson1.officeNum.number }}</td>
                            <td class="colborder">{{ office.contactPerson1.mobileNum.number }}</td>
                            <td class="colborder">{{ office.contactPerson1.email }}</td>
                            <td class="colborder">{{ office.contactPerson2.name }}</td>
                            <td class="colborder">{{ office.contactPerson2.officeNum.number }}</td>
                            <td class="colborder">{{ office.contactPerson2.mobileNum.number }}</td>
                            <td class="colborder">{{ office.contactPerson2.email }}</td>
                            <td class="colborder" >{{ office.peValidityDate }}</td>
                            <td class="colborder">{{ office.trcApplicable }}</td>
                            <td class="colborder">{{ office.trcValidityDate }}</td>
                            <td class="colborder">{{ office.payeeBank.bankName }}</td>
                            <td class="colborder">{{ office.payeeBank.bankAddressLine1 }}</td>
                            <td class="colborder">{{ office.payeeBank.bankAddressLine2 }}</td>
                            <td class="colborder">{{ office.payeeBank.bankAddressLine3 }}</td>
                            <td class="colborder">{{ office.payeeBank.accountNumber }}</td>
                            <td class="colborder">{{ office.payeeBank.swiftCode }}</td>
                            <td class="colborder">{{ office.payeeBank.ibanNumber }}</td>
                            <td class="colborder">{{ office.payeeBank.sortCode }}</td>
                            <td class="colborder">{{ office.corrBank.bankName }}</td>
                            <td class="colborder">{{ office.corrBank.bankAddressLine1 }}</td>
                            <td class="colborder">{{ office.corrBank.bankAddressLine2 }}</td>
                            <td class="colborder">{{ office.corrBank.bankAddressLine3 }}</td>
                            <td class="colborder">{{ office.corrBank.accountNumber }}</td>
                            <td class="colborder">{{ office.corrBank.swiftCode }}</td>
                            <td class="colborder">{{ office.corrBank.abaCode }}</td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
                <div class="text-center graybackground pdct-stUp-bnkAcc-pgn mb-10" >
                    <v-row>
                        <v-col cols="6"></v-col>
                        <v-col cols="6">
                            <v-row class="mr-2 mt-2 text-center">
                                <v-spacer></v-spacer>
                            <div class="mr-2 mt-3"> 
                                <span class="showing"> Showing {{totalListCount ? (pageStartIndex+1) : 0}}-{{pageEndIndex}} </span>                         
                                <span class="outOfRecord"> out of {{totalListCount}} records </span>
                            </div>                               
                            <v-pagination
                                v-model="page"
                                :length="getNoOfPages"
                                :total-visible="5"
                                prev-icon="mdi-arrow-left"
                                next-icon="mdi-arrow-right"
                                color="#152F38"                    
                                @input="goToNextPage"
                            ></v-pagination>
                            </v-row>
                        </v-col>
                    </v-row>
                </div>
            </v-flex>
        </v-form>
        <v-overlay
          :absolute="absolute"
          :value="overlay"
        >
            <v-progress-circular
            :size="70"
            color="#01BBD9"
            indeterminate
            ></v-progress-circular>
        </v-overlay>
    </v-app>    
</template>

<script>
export default {
    data(){
        return {
            searchForm:{
                aigOfficeName   :   "",
                country         :   []
            },
            aigOfficeList   :   [],
            officeTableList  :   [],                    
            page            :   1,
            pageStartIndex  :   0,
            pageRowCount  :   10,
            totalListCount  :   0,
            valid: false,
            countryList     :   [],
            validationMessages: {
                "mandatory"     :   "This field is required."
            },
            allRules:{                
                officeNameRules: [
                    
                    v => (v && v.length <= 100) || 'AIG Office Name should be less than 100 characters.',
                    v => /[a-zA-Z0-9\s]+$/.test(v) || 'AIG Office Name is not valid.'
                ],
            },
            overlay         :   false,
            absolute        :   true,
            max100           :   100
        }
    },
    methods:{
        searchOffice(){
            if(!this.searchForm.aigOfficeName && !this.searchForm.country.length){
                this.showAlert("Please enter atleast one field.")
                console.log("searchForm - ",this.searchForm);
            }else if(this.searchForm.aigOfficeName && this.searchForm.aigOfficeName.length < 3){
                this.showAlert("Please enter atleast 3 letters of office name.")
            }else{
                console.log("searchForm - ",this.searchForm);

                this.goToNextPage(1)
            }            
        },
        goToOfficeDetails(index){
            console.log(this.officeTableList[index]);
            this.$router.push({ name: 'AIGMaster', params:this.officeTableList[index]})
        },
        goToNextPage(newPageNum){
            if(!this.searchForm.aigOfficeName && !this.searchForm.country.length){
                this.showAlert("Please enter atleast one field.")
                console.log("searchForm - ",this.searchForm);
            }else{
                console.log("searchForm - ",this.searchForm);
                this.pageStartIndex =   ((newPageNum - 1) * this.pageRowCount);
                this.officeTableList =   this.aigOfficeList.slice(this.pageStartIndex,this.pageStartIndex + this.pageRowCount)
                console.log("newPageNum - ",newPageNum,this.page);

                let reqData =   "perPage="+this.pageRowCount+"&pageNo="+newPageNum;
                if(this.searchForm.aigOfficeName){
                    reqData =   reqData +   "&nameOfAigOffice=" + this.searchForm.aigOfficeName;
                }
                if(this.searchForm.country.length){
                    reqData =   reqData +   "&countryName=" + JSON.stringify(this.searchForm.country);
                }
                this.showProgress();
                var self    =   this;
                this.GETM("SEARCH_AIG_OFFICE",reqData,(res)=>{
                    this.hideProgress();
                    console.log("SEARCH_AIG_OFFICE res - ",res);
                    if(res.data && res.data.data && res.data.data.data && res.data.data.data.length >0){
                        let data    =   res.data.data;
                        self.totalListCount =  data.total;
                        self.officeTableList  =   this.mapTheResponse(data.data)
                        // self.totalListCount =  self.officeTableList.length;
                        // self.officeTableList =   self.aigOfficeList.slice(0,this.pageRowCount);
                    }else{
                        self.totalListCount =  0;
                        self.officeTableList  =   [];
                        this.officeTableList =   [];
                        self.showAlert("No data.")
                    }
                },(err)=>{
                    this.hideProgress();
                    console.log("SEARCH_AIG_OFFICE err - ",+err.response.data.msg);                    
                });
            }

        },
        clearForm(){
            console.log("Reset")
            this.$refs.officeSearchForm.reset();
            this.$refs.officeSearchForm.resetValidation();
        },
        createNewOffice(){
            
            this.$router.push("/aigofficemaster");
        },
        showProgress(){
            this.overlay    =   true;
        },
        hideProgress(){
            this.overlay    =   false;
        },
        mapTheResponse(resp){
             let finalArr   =   [];
             for(var i in resp){               

                for(var j in resp[i].originDetails){
                    var tempObj     =   {
                        nameOfAigOffice :   resp[i].nameOfAigOffice,
                        aigCode         :   resp[i].aigCode,
                        _id             :   resp[i]._id,
                        _idCountry      :   resp[i].originDetails[j]._id,
                        countryName     :   resp[i].originDetails[j].countryName,
                        addressLine1    :   resp[i].originDetails[j].addressLine1,
                        addressLine2    :   resp[i].originDetails[j].addressLine2,
                        addressLine3    :   resp[i].originDetails[j].addressLine3,
                        contactPerson1  :   resp[i].originDetails[j].contactPerson1,
                        contactPerson2  :   resp[i].originDetails[j].contactPerson2,
                        peValidityDate  :   resp[i].originDetails[j].peValidityDate,
                        trcApplicable   :   resp[i].originDetails[j].trcApplicable,
                        trcValidityDate :   resp[i].originDetails[j].trcValidityDate,
                        payeeBank       :   resp[i].originDetails[j].payeeBank,
                        corrBank        :   resp[i].originDetails[j].corrBank
                    }
                    finalArr.push(tempObj)
                }

             }

             return finalArr;
        },
        goBack(){
            this.clearForm();
            this.$router.go(-1);
        }
    },
    computed:{
        claimColumnList(){
            return ["Sr.No.","AIG Code","Name of AIG Office","Country","Address Line 1","Address Line 2","Address Line 3",
            "Contact Person 1 Name","Contact Person 1 Office No.","Contact Person 1 Mobile No.","Contact Person 1 Email",
            "Contact Person 2 Name","Contact Person 2 Office No.","Contact Person 2 Mobile No.","Contact Person 2 Email",
            "PE Validity Date","TRC Applicable","TRC Validity Date","Payee Bank Name","Payee Address Line 1","Payee Address Line 2","Payee Address Line 3",
            "Payee Account Number","Payee Swift Code","Payee IBAN Number","Payee Sort Code","Corr. Bank Name","Corr. Bank Address Line 1","Corr. Bank Address Line 2","Corr. Bank Address Line 3",
            "Corr. Bank Account Number","Corr. Bank Swift Code","Corr. Bank ABA Code"]
            //Object.keys(this.aigOfficeList[0]);
        },
        getNoOfPages(){
            return Math.ceil(this.totalListCount / this.pageRowCount);
        },
        pageEndIndex(){
            return ((this.pageStartIndex + (this.pageRowCount)) <= this.totalListCount) ? this.pageStartIndex + (this.pageRowCount) : this.totalListCount;
        }
    },
    mounted(){
        this.pageStartIndex =   0;       
        this.$store.commit("SAVE_PAGE_HEADER", "AIG Office Master");        
        
        // this.goToNextPage(1);//Populate the data
        //----------Get Country List--------------------//
        let self    =   this;
        this.showProgress();
        this.GETM("COUNTRY_LIST",{},(res)=>{
            this.hideProgress();
            let data    =   res.data.data.data;
            console.log("countryList - ",this.countryList);
            self.countryList    =   data;
        },(err)=>{
            console.log(err);
            this.hideProgress();
        })
    },    
}
</script>

<style scoped>
.row_line {
    margin:10px;
}

.preicon_background {
    background-color:#C1C8CC;
    margin-right:2px;
}
.lessflex {
    flex: 0.7;
}

.v-btn {
    text-transform: none;
}
.search_result_txt {
    margin-left:10px;
    font: normal normal bold 20px/24px Roboto;
    padding:5px;
    /* padding-bottom:5px */
}
.graybackground {
    background-color:  #F7F7F7;
}

.greencolor {
    background-color: #23B1A9;
}
.colborder{
    border: 1px solid #DAE1E6;
    white-space: nowrap;
   	font-size: 15px !important;
	color: #10242b;
    font-weight: 400;
}
.tableHead{
	white-space: nowrap !important;
	background-color:#23B1A9 !important;
	color:#FFFFFF !important;
    font-size: 15px !important;
    border: 1px solid #f7f7f7;
    border-right: none;
    height: 50px !important;
	}
.cursorPoniter{
  cursor: pointer !important;
}
.overflowHIden{
 overflow: hidden  !important;
}
.formFont{
    font: normal normal bold 16px/19px Roboto;
}
.buttonsRowbackGround{
	background-color:#F7F7F7;
	padding:10px;
    margin-left: 0;
    margin-right: 0;
}

.buttonsLayout{
display:flex;
flex-direction:row;
align-items: center;
justify-content: flex-end;
}
.outOfRecord {
	color: #3c3d3d;
	font-size: 14px;
}
.showing {
	color: #e46a25;
	font-size: 14px;
}
</style>