<template>
    <div style="background-color:#FFFFFF">
        <v-form v-model="roomNursingvalid" ref="roomNursingForm">
           <v-card tile flat class="">
               <div class="px-4 pt-4">
                    <v-flex xs3>
                        <v-layout column class="cust_pln_RmNrsng2 mx-2">
                            <p class="font-weight-bold cstm-clr-pln-RmNrsng">
                                Discount Percentage
                            </p>
                            <v-text-field
                                solo
                                label="Enter..."
                                v-model.trim="dscntPercntg"
                                class="rounded-0"
                                :maxlength="max05"
                                :disabled="fromview"
                                :rules="[Numeric2]"
                            >
                            </v-text-field>
                        </v-layout>
                    </v-flex>
               </div>
               <div class="px-4 py-3" style="background-color:#F7F7F7">
                  <p class="font-weight-bold cstm-clr-pln-RmNrsng pa-0 ma-0">Room Charges</p>
               </div>
               <v-divider></v-divider>
               <tableis v-if="shwDtaTbl===true" 
                    :tabledata="{
                        tabledata:addRmchrgs,
                        headersdata:headersRmchrgs,
                        dd:RmNrsngLvl4,
                        tariffshw:true,
                        roomconfig:temproomtype,
                         req:false,
                         url:'room-and-nursing-charges/get-room-and-nursing-charges'
                    }"  
                    ref="rmchrgs" 
                    @save="saveRmchrgs" 
                ></tableis>
              
                <div class="px-4 py-3"  style="background-color:#F7F7F7">
                    <p class="font-weight-bold cstm-clr-pln-RmNrsng pa-0 ma-0">Nursing Charges</p>
                </div>
                <v-divider></v-divider>
                <v-card tile>
                    <div>
                         <tableis v-if="shwDtaTbl===true"
                            :tabledata="{
                                tabledata:addCategory,
                                headersdata:headersNew,
                                dd:RmNrsngLvl4,
                                tariffshw:false,
                                roomconfig:temproomtype,
                                req:true,
                                del_endpoint:'nursing charges',
                                url:'room-and-nursing-charges/get-room-and-nursing-charges'
                            }" 
                            ref="Nrsngchrgs" 
                            @save="saveNrsngchrgs" 
                          ></tableis>
              
                        <!-- <v-data-table              
                            :headers="headers2"
                            :items="statusData"
                            hide-default-footer
                            dense
                        >
                        <template v-slot:body="props">
                            <tr v-for="item in props.items">
                                <td v-for="(header, index) in headers2">
                                    <span v-if="index">{{ item.cols[index-1] }}</span>
                                    <span v-else>{{ item.name }}</span>
                                </td>
                            </tr>
                        </template>
                        </v-data-table> -->
                    </div>
                </v-card>
            </v-card>
            <v-layout style="background-color:#F7F7F7" justify-end class="pa-4 mt-4" v-if="!fromview">
                <v-btn  tile @click="save_rn_ctgry()"  dark style="font-size:12px;background-color:#152F38"  class="text-capitalize px-3 mx-2" small><v-icon dark small>add_circle</v-icon> <v-divider vertical class="mx-2"></v-divider>Add Category</v-btn>
                <v-btn tile  @click="save_rn_confg()" dark style="font-size:12px;background-color:#23B1A9"
                class="text-capitalize px-3 mx-2" small>
                <v-icon dark small>check_circle</v-icon>
                <v-divider vertical class="mx-2"></v-divider> 
                Save</v-btn>
            </v-layout>
        </v-form>
    </div>
</template>
<script>

import tableis from './data_table.vue'

export default {
    components:{
        tableis
    },
    created(){
        this.$store.commit("SAVE_PAGE_HEADER", "Room & Nursing Charges - Tariff");
        console.log(this.$store.state.createtarrifDetails,"this.$store.state.createtarrifDetails",this.$store.state.tariff_view);
        this.getRmNrsngLvl4()
        this.fromview = this.$store.state.tariff_view;
        
    },
    data(){
        return{
            temproomtype:{},
            fromview: false,
            roomNursingvalid:false,
            shwDtaTbl:false,
            dscntPercntg:'',
            tempRmChrgs:[],
            max03:3,
            max05:5,
            max10:10,
            max20:20,
            max30:30,
            max40:40,
            max50:50,
            required(){
                return v=>v && v.length>0 || "This Field is Required"
            },
            minLength(minLength){
                return v=>v && v.length>=minLength || `Must be atleast ${minLength} Characters`
            },
            maxLength(maxLength){
                return v=>v && v.length<=maxLength || `Must be less than ${maxLength} Characters`
            },
            spclChar(){
                return v=>/^[A-Za-z0-9 ]+$/.test(v) || `Special Characters Not Allowed`
            },
            alphaNumeric(){
                return v=>/^[0-9 ]+$/.test(v) || `Only Numbers are allowed.`
            },
            numberRule: v  => {
                if (!v.trim()) return true;
                if (!isNaN(parseFloat(v)) && v >0 && v <=10) return true;
                return 'Values between 1 and 10 are accepted';
            },
            decimalRule(){
                return v=>/^\d+(\.\d{1,2})?$/.test(v) || `Numeric upto two decimals.`
            },
            headers:[
                {text: 'Sr. No.',value:'status',sortable: false,divider:true,class:"hdr-txt-clr-Rn backColorRn"}, 
                {text: 'Service Category(Level 4)',value:'status',sortable: false,divider:true,class:"hdr-txt-clr-Rn backColorRn"},
                {text: 'Hospital Service(Level 5)', value: 'Date',sortable: false,divider:true,class:"hdr-txt-clr-Rn backColorRn"},
                {text: 'Tariff', value: 'tariff',sortable: false,divider:true,class:"hdr-txt-clr-Rn backColorRn"},
                {text: 'Inclusions',value: 'remarks',sortable: false,divider:true,class:"hdr-txt-clr-Rn backColorRn"}, 
                {text: 'Exclusions',value: 'remarks',sortable: false,divider:true,class:"hdr-txt-clr-Rn backColorRn"},   
            ],
             headersNew:[ 
                {text: 'scl4', displayval:'Service Category(Level 4)'},
                {text: 'hsl5',displayval:'Hospital Service (Level 5)'},
                // {text: 'Tariff' },
                // {text: 'Tariff',displayval:'Tariff'},
                {text: 'Inclusions',displayval:'Inclusions'}, 
                {text: 'Exclusions',displayval:'Exclusions'}, 
                {text: 'del',displayval:'Action'},   
            ],
            headersRmchrgs:[ 
              {text: 'scl4', displayval:'Service Category(Level 4)'},
                {text: 'hsl5',displayval:'Hospital Service (Level 5)'},
                // {text: 'Tariff' },
                {text: 'Tariff',displayval:'Tariff'},
                {text: 'Inclusions',displayval:'Inclusions'}, 
                {text: 'Exclusions',displayval:'Exclusions'},    
            ],
            addCategory:[],
            addRmchrgs:[],
            RmNrsngLvl4:[],
            headers2:[
                {text: 'Sr. No.',value:'srNo',sortable: false,divider:true,class:"hdr-txt-clr-Rn backColorRn"}, 
                {text: 'Service Category(Level 4)',value:'status',sortable: false,divider:true,class:"hdr-txt-clr-Rn backColorRn"},
                {text: 'Hospital Service(Level 5)', value: 'Date',sortable: false,divider:true,class:"hdr-txt-clr-Rn backColorRn"},
                // {text: 'Room Type 1', value: 'Time',sortable: false,divider:true,class:"hdr-txt-clr-Rn backColorRn"},
                // {text: 'Room Type 2', value: 'userName',sortable: false,divider:true,class:"hdr-txt-clr-Rn backColorRn"},
                // {text: 'Room Type 3',value: 'remarks',sortable: false,divider:true,class:"hdr-txt-clr-Rn backColorRn"},  
                // {text: 'Room Type 4',value: 'remarks',sortable: false,divider:true,class:"hdr-txt-clr-Rn backColorRn"},
                {text: 'Inclusions',value: 'remarks',sortable: false,divider:true,class:"hdr-txt-clr-Rn backColorRn"}, 
                {text: 'Exclusions',value: 'remarks',sortable: false,divider:true,class:"hdr-txt-clr-Rn backColorRn"},   
            ],
            roomconfigis:[  {"roomTyp":"fjcnf"},{"roomTyp":"jhedc"},{"roomTyp":"jhexb"},{"roomTyp":"ejnxd"},{"roomTyp":"hdbxd"},{"roomTyp":"yedbv"},{"roomTyp":"uyuyu"},{"roomTyp":"acaca"},{"roomTyp":"bcbvc"}],
            noOfRooms:[
                // { text:'General',value:"" },
                // { text:'Delux' ,value:""},
                // { text:'SuperDelux',value:""},
                // { text:'VIP',value:""},
              
            ],
            statusData:[{
                srNo:'1',
                status:"Active",
                Date:"TESt",
                General:"General",
                Delux:"General",
                SuperDelux:"General",
                VIP:"General"
            }],
            tempNoOfRoomTyp:0,


        }
    },
    methods:{
        getRmNrsngLvl4(){
            this.RmNrsngLvl4=[]
            this.getRoomNrsngConfig(this.$store.state.createtarrifDetails._id)
            return
            this.GET(3,'room-and-nursing-charges/get-room-and-nursing-charges',(res,error)=>{
                if(!error){
                    if(res.data.statusCode === 200){
                        this.RmNrsngLvl4 = res.data.data.data
                        console.log(this.RmNrsngLvl4,"this.RmNrsngLvl4")
                        this.getRoomNrsngConfig(this.$store.state.createtarrifDetails._id)
                    }else{
                        this.showToast(res.data.msg ,this.TOST().ERROR); 
                    }
                }else{
                    this.showToast(error.response.data.msg, this.TOST().WARNING);
                }
            })
        },
        Numeric2(v){
            if (v.length !== 0) {
                console.log("testing",/^[0-9 ]+$/.test(v))
                if(!/^(100(?:\.00?)?|\d?\d(?:\.\d\d?)?)$/.test(v)){
                    return "Numeric between 0 - 100 and upto two decimals are allowed."
                }else{
                console.log("else none")  
                }
            }
        },
        saveNrsngchrgs(incmgDta){
            if(incmgDta.dta.length ===0){
                this.showToast("Please add atleast one category",this.TOST().WARNING);
                return
            }
             if (!this.$refs.roomNursingForm.validate()){
                this.$refs.roomNursingForm.validate()
                this.showToast("Data should be entered for all required fields.",this.TOST().WARNING);
                return
            }
            console.log(this.tempRmChrgs ,"this.tempRmChrgs")
            console.log(incmgDta, "saveNrsngchrgs commponent emitted ")
            let roomCharges= []
            this.tempRmChrgs.map((e)=>{
                let a={
                    // levelId:e.scl4._id,
                    // level1:e.scl4.level1,
                    // level2:e.scl4.level2,
                    // level3:e.scl4.level3,
                    serviceCategory:e.scl4,
                    hospitalService:e.hsl5,
                    tariff:e.Tariff,
                    inclusion: e.Inclusions,
                    exclusion:e.Exclusions

                }
                if(e.hasOwnProperty('_id')){
                    if(e._id.length!=0){
                         a._id=e._id
                    }else{
                        console.log("Id not found")
                    }
                }
                console.log("tempdata with Id/not",a)

                roomCharges.push(a)
            })
            
            let nursingCharges= []
            incmgDta.dta.map((e)=>{
                console.log("fromm -> ",e)
                // var result = {};
                // for (var i = 0; i < e.noOfRooms.length; i++) {
                //     result[e.noOfRooms[i].text] = e.noOfRooms[i].inputValue;
                // }
                var tempRoomType=[]
                 for (var i = 0; i < e.noOfRooms.length; i++) {
                  tempRoomType.push({
                      roomType:e.noOfRooms[i].text,
                      charges:e.noOfRooms[i].inputValue
                  })
                }

                let b={
                    // levelId:e.scl4._id,
                    // level1:e.scl4.level1,
                    // level2:e.scl4.level2,
                    // level3:e.scl4.level3,
                    serviceCategory:e.scl4,
                    hospitalService:e.hsl5,
                    inclusion: e.Inclusions,
                    exclusion:e.Exclusions,
                    roomTypes:tempRoomType 

                }
                if(e.hasOwnProperty('_id')){
                    if(e._id.length!=0){
                        b._id=e._id
                    }else{
                        console.log("Id not found")
                    }
                }
                console.log("tempdata with Id/not",b)
                nursingCharges.push(b)
            })
            // console.log(nursingCharges,"nursingCharges")
            // console.log(roomCharges,"roomCharges")
            let formData={
                tariffId:this.$store.state.createtarrifDetails._id,
                discount:this.dscntPercntg,
                roomCharges:roomCharges,
                nursingCharges:nursingCharges,
            }
             console.log(formData,"formData")

             this.PUT(2,'tariff/update-room-and-nursing-charges',formData ,
                ( res, error) => {
                 if(!error){
                    console.log(res.data);
                    this.getRoomNrsngConfig(this.$store.state.createtarrifDetails._id)
                    this.showToast(res.data.msg ,this.TOST().SUCCESS);
                    this.$emit('forenable', { emitType:"roomAndNursingCharges", roomAndNursingCharges: true }); 

                }else{
                    console.log("when status is not 200", error.response);
                    this.showToast(error.response.data.msg ,this.TOST().ERROR);
                }
    
            });

        },
        saveRmchrgs(incmgDta){
             
            console.log(incmgDta, " saveRmchrgs commponent emitted")
            this.tempRmChrgs=[]
            this.tempRmChrgs=incmgDta.dta
            this.$refs.Nrsngchrgs.save_click()


        },
        save_rn_confg(){
            
            this.$refs.rmchrgs.save_click()
        },
        save_rn_ctgry(){
            var headerRefObj={
                scl4:'',
                hsl5:'',
                inclusions:'',
                exclusions:'',
                del:''
            }
            for(let i=0;i<this.noOfRooms.length;i++){
                headerRefObj[this.noOfRooms[i].text]= this.noOfRooms[i]
            }
            console.log(headerRefObj,"headerRefObj")
            this.addCategory.push({
                scl4:'',
                hsl5:'',
                // // tarrif:'',
                // noOfRooms:this.noOfRooms,
                inclusions:'',
                exclusions:'',
                del:''
            })
        },
        getRoomNrsngConfig(id){
            this.shwDtaTbl=false
            this.addRmchrgs=[]
            this.noOfRooms=[]

            this.headersNew =[ 
                {text: 'scl4', displayval:'Service Category(Level 4)'},
                {text: 'hsl5',displayval:'Hospital Service (Level 5)'},
                // {text: 'Tariff' },
                // {text: 'Tariff',displayval:'Tariff'},
                {text: 'Inclusions',displayval:'Inclusions'}, 
                {text: 'Exclusions',displayval:'Exclusions'}, 
                {text: 'del',displayval:'Action'},   
            ]
            var compnt_render=new Promise((resolve,reject)=>{

                this.getTariffById(id,tempData=>{
                    console.log(tempData,"tempdata")

                    

                    if(tempData.hasOwnProperty('roomConfiguration') && tempData.roomConfiguration!=null){
                        this.tempNoOfRoomTyp=tempData.roomConfiguration.roomConfiguration.hasOwnProperty('numberOfRooms')? this.chkplndataStrnfy(tempData.roomConfiguration.roomConfiguration.numberOfRooms) : ''
                        let roomTyp=tempData.roomConfiguration.roomConfiguration.hasOwnProperty('roomTypes')? this.chkplndataarry(tempData.roomConfiguration.roomConfiguration.roomTypes) : []
                        
                        this.temproomtype = roomTyp
                        console.log("roomTyp  ", roomTyp)
                        
                        // if(Object.keys(temproomType).length != 0){
                        //     for (let i in temproomType ){
                        //         console.log(i,"inside temproomType ")
                        //         this.noOfRooms.push(
                        //             {
                        //                 displayval:temproomType[i],
                        //                 text:temproomType[i], 
                        //                 value:temproomType[i],
                        //                 inputValue:''
                        //             }
                        //         )
                        //     }
                        // }
                        for(let i=0;i<roomTyp.length;i++){
                            this.noOfRooms.push(
                                {
                                    displayval:roomTyp[i].roomType,
                                    text:{ [i]:roomTyp[i].roomType}, 
                                    value:roomTyp[i].roomType,
                                    inputValue:''
                                }
                            )
                        }
                        console.log(this.noOfRooms,"this.noOfRooms")
                        for (let i=0;i<this.noOfRooms.length;i++){
                            this.addRmchrgs.push(
                                {
                                    scl4:'',
                                    hsl5:'',
                                    // hsl5:this.noOfRooms[i].value,
                                    // noOfRooms:this.noOfRooms[i].value,
                                    inclusions:'',
                                    exclusions:'',
                                    Tariff:'',
                                }
                            )
                        }

                        this.headersNew.splice(2, 0, ...this.noOfRooms);
                        console.log(this.headersNew,"this.headersNew")

                        if(tempData.hasOwnProperty('roomAndNursingCharges')&& tempData.roomAndNursingCharges!=null){
                            this.dscntPercntg=tempData.roomAndNursingCharges.hasOwnProperty('discount')? this.chkplndata(tempData.roomAndNursingCharges.discount) : ''
                            if(tempData.roomAndNursingCharges.roomCharges.length!=0){
                                this.addRmchrgs=[]
                                for (let i=0;i<tempData.roomAndNursingCharges.roomCharges.length;i++){
                                    console.log(i,"inside temproomType ")
                                    
                                    this.addRmchrgs.push(
                                        {
                                            _id:tempData.roomAndNursingCharges.roomCharges[i]._id,
                                            scl4:tempData.roomAndNursingCharges.roomCharges[i].serviceCategory,
                                            hsl5:tempData.roomAndNursingCharges.roomCharges[i].hospitalService,
                                            // noOfRooms:this.noOfRooms[i].value,
                                            Inclusions:tempData.roomAndNursingCharges.roomCharges[i].inclusion,
                                            Exclusions:tempData.roomAndNursingCharges.roomCharges[i].exclusion,
                                            Tariff:tempData.roomAndNursingCharges.roomCharges[i].tariff
                                        }
                                    )
                                }

                            }
                             console.log( this.addRmchrgs," this.addRmchrgs")

                            //  add new rooms

                             if(this.tempNoOfRoomTyp>this.addRmchrgs.length){
                                let  newNoofRooms =this.tempNoOfRoomTyp-this.addRmchrgs.length
                                console.log("newNoofRooms", newNoofRooms)
                                for(let i=0;i<newNoofRooms;i++){
                                    this.addRmchrgs.push({
                                         scl4:'',
                                        hsl5:'',
                                        // hsl5:this.noOfRooms[i].value,
                                        // noOfRooms:this.noOfRooms[i].value,
                                        inclusions:'',
                                        exclusions:'',
                                        Tariff:'',

                                    })
                                }
                            }

                            if(tempData.roomAndNursingCharges.nursingCharges.length!=0){
                                this.addCategory=[]
                                //  this.noOfRooms=[]
                                for (let i=0;i<tempData.roomAndNursingCharges.nursingCharges.length;i++){
                                    console.log(i,"inside temproomType ")
                                    var tempnoOfRooms=[]
                                    for(let j in tempData.roomAndNursingCharges.nursingCharges[i].roomType){
                                        console.log(j,"inside temproomType ")
                                        tempnoOfRooms.push(
                                            {
                                            text:j, 
                                            value:j,
                                            inputValue:tempData.roomAndNursingCharges.nursingCharges[i].roomType[j]
                                            }
                                        )
                                        
                                    }

                                    var roomholder = {}
                                  var roomtypes_data = []
                                  
                                    roomtypes_data = []
                                  tempData.roomAndNursingCharges.nursingCharges[i].roomTypes.map((e,index)=>{
                                            roomholder = {}
                                            roomholder[index] = e.charges 

                                         roomtypes_data.push(roomholder)
                                        console.log(roomholder,"newonjis ", roomtypes_data);
                                       
                                    })

                                    console.log("roomtypes_data ", ...roomtypes_data);
                                    var finalrooms =  Object.assign({},...roomtypes_data)
                                    this.addCategory.push(
                                        {
                                            _id:tempData.roomAndNursingCharges.nursingCharges[i]._id,
                                            scl4:tempData.roomAndNursingCharges.nursingCharges[i].serviceCategory,
                                            hsl5:tempData.roomAndNursingCharges.nursingCharges[i].hospitalService,
                                            Inclusions:tempData.roomAndNursingCharges.nursingCharges[i].inclusion,
                                            Exclusions:tempData.roomAndNursingCharges.nursingCharges[i].exclusion,
                                            tarrif:tempData.roomAndNursingCharges.nursingCharges[i].tariff,
                                             ...finalrooms

                                        }
                                    )
                                }

                            }
                            
                        }
                        console.log(this.addRmchrgs,"this.addRmchrgs")
                        resolve(true)
                    }
                })
            })
            console.log("When did it ran")
            compnt_render.then(res=>{
                console.log(res,"Resolve Promise")
                this.shwDtaTbl=true
            })

           
        },
        chkplndataStrnfy(data){
            console.log("chkplndata",data); 
            try{
                if (data ==="" || data === "undefined" || data === null) {
                    return ""
                } else {
                    return JSON.stringify(data);
                }

             }catch(err) {
                console.log(err)
            }
            
        },

        chkplndataarry(data){
            console.log("chkplndataarry",data); 
            try{
                if (data ==="" || data === "undefined" || data === null) {
                    return []
                } else {
                    return data;
                }

             }catch(err) {
                console.log(err)
            }
            
        },

        chkplndata(data){
            console.log("chkplndata",data); 
            try{
                if (data ==="" || data === "undefined" || data === null) {
                    return ""
                } else {
                    return data;
                }

             }catch(err) {
                console.log(err)
            }
            
        },
    }
}
</script>
<style>
.hdr-txt-clr-Rn{
    color:#FFFFFF!important;
    font-weight:500
}
.backColorRn{
    background-color: #23B1A9 !important;
    color:#FFFFFF!important;
    font-weight:500 !important;
    font-size:13px !important;
    /* padding:4px !important;  */
    /* padding-left:10px !important; */
}

/* .cust_pln_RmNrsng .v-text-field .v-input__slot,
.v-text-field--outline .v-input__slot {
    min-height: 38px !important;
    display: flex !important;
    align-items: center !important;
    
}

.cust_pln_RmNrsng .v-input .v-label {
    font-size: 13px !important;
}
.cust_pln_RmNrsng.v-input {
    font-size: 13px !important;
}
.cust_pln_RmNrsng .v-input input {
    font-size: 13px !important;
} */

.cust_pln_RmNrsng2 .v-text-field .v-input__slot,
.v-text-field--outline .v-input__slot {
    min-height: 41px !important;
    display: flex !important;
    align-items: center !important;
    /* border:1px red solid; */
}

.cust_pln_RmNrsng2 .v-input .v-label {
    font-size: 13px !important;
}
.cust_pln_RmNrsng2.v-input {
    font-size: 13px !important;
}
.cust_pln_RmNrsng2 .v-input input {
    font-size: 13px !important;
}

.cstm-clr-pln-RmNrsng{
    color:#646666 !important;
    margin-bottom: 5px !important;
    font-size: 13px !important;
}
</style>