<template>
  <v-app style="background-color: #F7F7F7;">
    <v-form v-model="employeeMaster" ref="employeeMaster">
      <v-card tile class="px-3 pt-4 ">
        <v-layout>
          <v-flex xs3>
            <v-layout column class="cust_pln_em mx-2 ">
              <p class="font-weight-bold cstm-clr-pln-em">
                Agreement Type *
              </p>
              <div
                class="mt-1 elevation-1"
                style="padding-top: 9px;padding-bottom: 9px; "
              >
                <v-radio-group
                  v-model="agreementType"
                  mandatory
                  row
                  dense
                  class="ma-0 pa-0"
                  hide-details
                  :disabled="dsblEmployeeName"
                >
                  <v-radio
                    color="#23B1A9"
                    dense
                    value="employee"
                    class="ma-0 pa-0 pl-2 "
                  >
                    <template v-slot:label>
                      <div
                        class="font-weight-bold"
                        style="color:#10242B;font-size:12px"
                      >
                        Employee
                      </div>
                    </template>
                  </v-radio>
                  <v-radio
                    color="#23B1A9"
                    dense
                    value="externalResource"
                    class="ma-0 pa-0 pl-2"
                  >
                    <template v-slot:label>
                      <div
                        class="font-weight-bold"
                        style="color:#10242B;font-size:12px"
                      >
                        External Resource
                      </div>
                    </template>
                  </v-radio>
                </v-radio-group>
              </div>
            </v-layout>
          </v-flex>
          <v-flex xs3>
            <v-layout column class="cust_pln_em mx-2 mt-1">
              <p class="font-weight-bold cstm-clr-pln-em">
                Type of Employee <span v-if="naTypeOfEmployee">*</span>
              </p>
              <!-- item-text="planName"
                                item-value="_id" -->
              <v-select
                :items="whatemployeewehave"
                label="Select"
                v-model="typeOfEmployee"
                :rules="agreementType == 'externalResource' ? [] : [required()]"
                @change="slcttypeOfEmployee"
                solo
                dense
                class="rounded-0"
                :disabled="dsblEmployeeName"
              >
              </v-select>
            </v-layout>
          </v-flex>
          <v-flex v-if="agreementType == 'employee'" xs3>
            <v-layout
              column
              class="cust_pln_em mx-2 "
              :class="[dsblNtidId === true ? 'custDsbld_emplyMstr' : '']"
            >
              <p class="font-weight-bold cstm-clr-pln-em">
                NTID/User ID *
              </p>
              <v-text-field
                solo
                label="Enter..."
                v-model="NTIDUserID"
                class="rounded-0"
                :maxlength="max30"
                :rules="[required(), spclCharNtid(NTIDUserID)]"
                :disabled="dsblNtidId"
              >              
                <!-- @change="checkForUniqueNtid()" -->
              </v-text-field>
            </v-layout>
          </v-flex>
          <v-flex v-if="agreementType !== 'employee'" xs3>
            <v-layout
              column
              class="cust_pln_em mx-2 "
              :class="[dsblNtidId === true ? 'custDsbld_emplyMstr' : '']"
            >
              <p class="font-weight-bold cstm-clr-pln-em">
                NTID/User ID
              </p>
              <v-text-field
                solo
                label="Enter..."
                v-model="NTIDUserID"
                class="rounded-0"
                :maxlength="max30"
                :rules="[spclCharNtid(NTIDUserID)]"
                :disabled="dsblNtidId"
              >
              </v-text-field>
            </v-layout>
          </v-flex>
          <v-flex xs3 v-if="agreementType == 'employee'">
            <v-layout
              column
              class="cust_pln_em  mx-2 mt-1"
              :class="[dsblEmplyId === true ? 'custDsbld_emplyMstr' : '']"
            >
              <p class="font-weight-bold cstm-clr-pln-em">
                Employee ID <span v-if="naForemployeeID">*</span>
              </p>
              <v-text-field
                solo
                label="Enter..."
                v-model="employeeID"
                class="rounded-0"
                :maxlength="max30"
                :rules="
                  typeOfEmployee == 'Part Time' || typeOfEmployee == 'Outsource'
                    ? [spclCharNot]
                    : [required(), spclCharNot]
                "
                :disabled="dsblEmplyId"
              >
              </v-text-field>
            </v-layout>
          </v-flex>

          <v-flex xs3 v-if="agreementType !== 'employee'">
            <v-layout
              column
              class="cust_pln_em  mx-2 mt-1"
              :class="[dsblEmplyId === true ? 'custDsbld_emplyMstr' : '']"
            >
              <p class="font-weight-bold cstm-clr-pln-em">
                Employee ID <span v-if="naForemployeeID"></span>
              </p>
              <v-text-field
                solo
                label="Enter..."
                v-model="employeeID"
                class="rounded-0"
                :maxlength="max30"
                :rules="
                  typeOfEmployee == 'Part Time' || typeOfEmployee == 'Outsource'
                    ? [spclCharNot]
                    : [spclCharNot]
                "
                :disabled="dsblEmplyId"
              >
              </v-text-field>
            </v-layout>
          </v-flex>
          <!-- <v-flex xs3 >
                        <v-layout column class="mx-2 mb-7 cust_pln_em" justify-center >
                               
                            <span class="font-weight-bold cstm-clr-pln-em" style="color:#FFFFFF" > </span>
                            <div style="" class="pa-2 mt-6 elevation-1">
                                <v-checkbox  hide-details class="ma-0 pa-0" v-model="settlingOffice"
                                @click="slctsettlingOffice" color="#23B1A9"
                                  
                                >
                                    <span slot="label" class="font-weight-bold" style="color:#10242B;font-size:12px"> Settling Office</span>
                                </v-checkbox>
                            </div>
                        </v-layout>
                    </v-flex> -->
          <!-- <v-flex xs3 >
                        <v-layout column class="mx-2 mb-7 cust_pln_em" justify-center >
                            <span class="font-weight-bold cstm-clr-pln-em" style="color:#FFFFFF" > </span>
                            <div style="" class="pa-2 mt-6 elevation-1">
                                <v-checkbox class="ma-0 pa-0"  v-model="active" hide-details color="#23B1A9" >
                                    <span slot="label" class="font-weight-bold" style="color:#10242B;font-size:12px"> Active</span>
                                </v-checkbox>
                            </div>
                        </v-layout>
                    </v-flex> -->
          <v-flex xs3>
            <v-layout
              column
              class="cust_pln_em mx-2 "
              :class="[dsblEmployeeName === true ? 'custDsbld_emplyMstr' : '']"
            >
              <p class="font-weight-bold cstm-clr-pln-em">First Name *</p>
              <v-text-field
                solo
                label="Enter..."
                v-model="name.first"
                class="rounded-0"
                :maxlength="max30"
                :rules="[required(), onlyText]"
                :disabled="dsblEmployeeName"
              >
              </v-text-field>
              <!-- v-model="employeeName" -->
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs3>
            <v-layout
              column
              class="cust_pln_em mx-2 "
              :class="[dsblEmployeeName === true ? 'custDsbld_emplyMstr' : '']"
            >
              <p class="font-weight-bold cstm-clr-pln-em">Last Name *</p>
              <v-text-field
                solo
                label="Enter..."
                v-model="name.last"
                class="rounded-0"
                :maxlength="max30"
                :rules="[required(), onlyText]"
                :disabled="dsblEmployeeName"
              >
              </v-text-field>
            </v-layout>
          </v-flex>

          <!-- <v-flex xs3 >
                        <v-layout column class="cust_pln_em mx-2 " :class="[dsblEmployeeName===true ? 'custDsbld_emplyMstr': '']">
                            <p class="font-weight-bold cstm-clr-pln-em">
                               Employee Name *
                            </p>
                            <v-text-field
                                solo
                                label="Enter..."
                                v-model="employeeName"
                                class="rounded-0"
                                :maxlength="max30" 
                                :rules="[required(),onlyText]" 
                                :disabled="dsblEmployeeName" 
                            >
                            </v-text-field>
                        </v-layout>
                    </v-flex> -->

          <v-flex v-if="agreementType == 'employee'" xs3>
            <v-layout column class=" cust_pln_em mx-2 cust-rmvpd-em">
              <p class="font-weight-bold cstm-clr-pln-em">
                Joining Date <span v-if="naForServiceId">*</span>
              </p>

              <v-menu
                ref="calender"
                :close-on-content-click="true"
                v-model="joiningDatecalender"
                :nudge-right="40"
                :nudge-top="-40"
                transition="scale-transition"
                max-width="290px"
                min-width="290px"
                class="rounded-0"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-bind="attrs"
                    label="Select.."
                    :value="cmputdPlcyEfctveDteFrmtdMmntjs"
                    solo
                    class="rounded-0"
                    :rules="
                      typeOfEmployee !== 'Service ID'
                        ? [required(), dateRegex(joiningDate)]
                        : [dateRegex(joiningDate)]
                    "
                    @click="datePickerValue(1)"
                    @input="datePickerValue2(1, 'input')"
                    ref="datePicker_1"
                    v-on="on"
                    :disabled="dsblEmployeeName"
                  >
                    <div
                      slot="prepend-inner"
                      style="background-color:#F7F7F7;padding: 9px !important"
                    >
                      <v-icon>event</v-icon>
                    </div>
                  </v-text-field>
                </template>
                <v-date-picker v-model="joiningDate" no-title> </v-date-picker>
              </v-menu>
            </v-layout>
          </v-flex>

          <v-flex v-if="agreementType !== 'employee'" xs3>
            <v-layout column class=" cust_pln_em mx-2 cust-rmvpd-em">
              <p class="font-weight-bold cstm-clr-pln-em">
                Joining Date <span v-if="naForServiceId"></span>
              </p>

              <v-menu
                ref="calender"
                :close-on-content-click="true"
                v-model="joiningDatecalender"
                :nudge-right="40"
                :nudge-top="-40"
                transition="scale-transition"
                max-width="290px"
                min-width="290px"
                class="rounded-0"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-bind="attrs"
                    label="Select.."
                    :value="cmputdPlcyEfctveDteFrmtdMmntjs"
                    solo
                    class="rounded-0"
                    :rules="
                      typeOfEmployee !== 'Service ID'
                        ? [dateRegex(joiningDate)]
                        : [dateRegex(joiningDate)]
                    "
                    @click="datePickerValue(2)"
                    @input="datePickerValue2(2, 'input')"
                    ref="datePicker_2"
                    v-on="on"
                    :disabled="isViewOnly"
                  >
                    <div
                      slot="prepend-inner"
                      style="background-color:#F7F7F7;padding: 9px !important"
                    >
                      <v-icon>event</v-icon>
                    </div>
                  </v-text-field>
                </template>
                <v-date-picker v-model="joiningDate" no-title> </v-date-picker>
              </v-menu>
            </v-layout>
          </v-flex>

          <v-flex xs3>
            <v-layout column class="cust_pln_em cust-rmvpd-em mx-2 ">
              <p class="font-weight-bold cstm-clr-pln-em">
                E-mail <span v-if="naForServiceId">*</span>
              </p>
              <v-text-field
                solo
                label="Enter..."
                v-model="email"
                class="rounded-0"
                :rules="
                  typeOfEmployee !== 'Service ID'
                    ? [required(), emailRules2]
                    : [emailRules2]
                "
                :disabled="isViewOnly"
                @change="isUserDataChanged = true"
              >
                <div
                  slot="prepend-inner"
                  style="background-color:#F7F7F7;padding: 11px !important"
                >
                  <v-icon dense>mail</v-icon>
                </div>
              </v-text-field>
            </v-layout>
          </v-flex>

          <v-flex xs3>
            <v-layout column class="cust_pln_em cust-rmvpd-em mx-2 ">
              <p class="font-weight-bold  cstm-clr-pln-em">
                Mobile Number <span v-if="naForServiceId">*</span>
              </p>
              <v-text-field
                solo
                label="Enter..."
                v-model="mbNo"
                :maxlength="max10"
                class="rounded-0"
                :rules="
                  typeOfEmployee !== 'Service ID'
                    ? [required(), alphaNumeric2]
                    : [alphaNumeric2]
                "
                :disabled="isViewOnly"
                @change="isUserDataChanged = true"
              >
                <div
                  slot="prepend-inner"
                  style="background-color:#F7F7F7;padding:11px !important"
                >
                  <v-icon dense>local_phone</v-icon>
                </div>
              </v-text-field>
            </v-layout>
          </v-flex>
          <v-flex xs3></v-flex>
          
        </v-layout>
      </v-card>
      <v-row>
        <!-- <v-layout
              column
              class="cust_pln_em mx-2 "
              :class="[dsblEmployeeName === true ? 'custDsbld_emplyMstr' : '']"
            > -->
            <v-flex xs6></v-flex>
        <v-flex xs3> 
              <v-btn
                v-if="!isViewOnly && this.$store.getters.fetchCreateEditUserData.user_id"
                width="190"
                class="btnstyle ml-3 mt-6 btnLeft"
                color="#23B1A9"
                dark
                @click="confirmDisableAllLobs()"
                ><v-icon class="mr-2 iconsize">mdi-cancel</v-icon>
                <div class="vertical_line"></div>
                Deactive User
              </v-btn>
        </v-flex>
        <v-flex xs3>
              <v-btn
                v-if="!isViewOnly && this.$store.getters.fetchCreateEditUserData.user_id"
                width="110"
                class="btnstyle ml-3 btnLeft"
                color="#23B1A9"
                dark
                @click="updateUserData()"
                ><v-icon class="mr-2 iconsize"
                  >mdi-arrow-right-bold-circle</v-icon
                >
                <div class="vertical_line"></div>
                Submit
              </v-btn>
            
          </v-flex>
          <!-- </v-layout> -->
      </v-row>
      <!-- <center
        v-if="addFiled"
        class=" px-3 searchtxt text--#23B1A9 ml-8"
        style="cursor: pointer;"
        @click="() => ((selectContainer = true), (addFiled = false))"
      >
        <v-icon class="ml-2  fontStyle" color="#23B1A9 ">mdi-plus</v-icon>
        Add LOB
      </center> -->

      <div v-for="(item,index) in arrayItem" :key="index" >
        <div class="selectContent mt-2" v-if="selectContainer">
          <v-layout>
            <v-flex xs3 class="mb-2">
              <v-select
                :items="itemsOfLOB"
                v-model="item.lob"
                placeholder="Select LOB"
                @change="changeLob(item, null, null,index)"
                solo
                dense
                dark
                class="selectList pt-2 pl-2 custom-placeholer-color"
                :class="item._id ? 'darkText' : ''"
                background-color="#1ebbd9"
                :disabled="isViewOnly || isIdAvailable(item)"
              >
              </v-select>
            </v-flex>
            <v-flex class="mt-4" style="margin-left:990px">
              <p class="textColor" style="cursor: pointer;" @click.prevent="checkEditForModifty(item,index)"  v-if="item._id && !isViewOnly">EDIT</p>
              <b
                style="cursor: pointer;"
                class="textColor"
                @click="confirmDeleteLob(item)"
                v-if="item.lob && !item._id"
                >DELETE</b
              >
            </v-flex>
            <v-flex class="mt-4" style="cursor: pointer;" @click="expandCollapseRow(index)" v-if="item.lob">
              <v-icon v-if="!item.expandRow" color="white">mdi-plus</v-icon>
              <v-icon v-if="item.expandRow" color="white">mdi-minus</v-icon>
            </v-flex>
          </v-layout>
        </div>

        <div v-if="insideContent && item.lob && item.expandRow">
          <v-layout style="background-color:#F7F7F7" align-center class="py-2">
            <v-flex xs3>
              <v-layout column class="mx-2 cust_pln_em" justify-center>
                <span
                  class="font-weight-bold cstm-clr-pln-em"
                  style="color:#FFFFFF"
                >
                </span>
                <div style="" class="pa-2  elevation-1">
                  <v-checkbox
                    class="ma-0 pa-0"
                    v-model="item.active"
                    hide-details
                    color="#23B1A9"
                    :disabled="isViewOnly"
                    @change="item.isLobDataChanged = true"
                  >
                    <span
                      slot="label"
                      class="font-weight-bold"
                      style="color:#10242B;font-size:12px"
                    >
                     Is Active</span
                    >
                  </v-checkbox>                  
                </div>

                <!-- <div style="" class="pa-2  elevation-1">
                  <v-checkbox
                    class="ma-0 pa-0"
                    v-model="item.isNominatedUser"
                    hide-details
                    color="#23B1A9"
                    readonly
                  >
                    <span
                      slot="label"
                      class="font-weight-bold"
                      style="color:#10242B;font-size:12px"
                    >
                     Nominated User</span
                    >
                  </v-checkbox>                  
                </div> -->
              </v-layout>
            </v-flex>

            <v-flex xs4>
              <p
                class="font-weight-bold pa-0 ma-0 text-uppercase"
                style="color:#10242B;font-size:14px"
              >
                Assign Role *
              </p>
            </v-flex>

            <v-icon large color="#23B1A9">swap_horiz</v-icon>

            <v-flex xs4>
              <p
                class="font-weight-bold  pa-0 ma-0 text-uppercase"
                style="text-align: center;color:#10242B;font-size:14px"
              >
                Assign special permission
              </p>
            </v-flex>
          </v-layout>
          <v-card tile class="pa-1">
            <v-layout>
              <!-- <v-flex xs3 class="pa-2"> </v-flex> -->
              <v-flex xs3>
                <v-layout column class="mx-2 cust_pln_em" justify-center>
                  <span
                    class="font-weight-bold cstm-clr-pln-em"
                    style="color:#FFFFFF"
                  >
                  </span>
                  
                  <div style="" class="pa-2  elevation-1" v-if="item.lob=='Travel' || item.lob =='Health'">
                    <p class="font-weight-bold  cstm-clr-pln-em">
                      Supervisor
                    </p>
                    <v-autocomplete
                      class="ma-0 pa-0"
                      placeholder="Select"
                      v-model="supervisor"
                      :disabled="isViewOnly"
                      :search-input.sync="searchSupervisors"
                      :items="supervisorOptions"
                      :item-text="getItemText"
                      :return-object="true"
                      hide-details
                      color="#23B1A9"
                      clearable
                      chips
                      @change="supervisorChanged(item,index)"
                      multiple
                    >
                      
                    </v-autocomplete>                  
                  </div>
                </v-layout>
              </v-flex>

              <v-flex xs5 class="pa-4">
                <v-card
                  style="min-height:340px;max-height:340px; overflow-y:scroll"
                  tile
                  class="pa-4 cust_pln_em"
                >
                  <v-text-field
                    label="Search"
                    solo
                    class="rounded-0"
                    hide-details
                    :disabled="isViewOnly"
                    v-model="role_search"
                    @keyup="searchRole(item, role_search)"
                  >
                    <div
                      slot="append"
                      style="background-color:#23B1A9;padding:8.5px;cursor:pointer;position:absolute;top:0px;right:0px"
                    >
                      <v-icon dark>search</v-icon>
                    </div>
                  </v-text-field>

                  <!-- <pre>{{selectedRole}}</pre> -->
                  <!-- <p
                    v-if="noRoleSltd === true"
                    class="ma-0 pa-0 px-3 mt-2"
                    style="color:#ff5252;font-size: 12px;"
                  >
                    At least One Role should be selected
                  </p> -->
                  <v-list dense :disabled="isViewOnly">
                    <v-list-item-group
                      v-model="item.selectedRole"
                      @change="chkRoleSltd(item)"
                      active-class="selectedRole_clr"
                      multiple
                      
                    >
                      <!-- checking in special permissions from role -> checkin_SP -->
                      <template v-for="(roleItem, index) in item.filteredRoleOptions">
                        <!-- disabled => item.disableRoleList ||  -->
                        <v-list-item
                          :disabled="isViewOnly" 
                          @click="checkin_SP(roleItem)"
                          :key="index"
                          class=" ma-0 pa-0  pl-2 mx-2 mb-2 elevation-1"
                          :value="roleItem._id"
                        >
                          <v-list-item-content
                            class="ma-0 pa-0"
                            color="#646666"
                          >
                            <div style="font-size: 12px;">
                              <span
                                class=" py-2 mt-2 font-weight-bold "
                                style="font-size: 12px;word-wrap: break-word; "
                              >
                                {{ roleItem.Rolenamewithdesignation }}
                              </span>
                            </div>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-list-item-group>
                  </v-list>
                </v-card>
              </v-flex>

              <v-flex xs5 class="pa-4">
                <v-card
                  style="min-height:340px;max-height:340px; overflow-y:scroll"
                  tile
                  class="pa-4 cust_pln_em"
                >
                  <v-text-field
                    label="Search"
                    solo
                    class="rounded-0"
                    hide-details
                    v-model="special_permission_search"
                    :disabled="isViewOnly"
                  >
                    <div
                      slot="append"
                      style="background-color:#23B1A9;padding:8.5px;cursor:pointer;position:absolute;top:0px;right:0px"
                    >
                      <v-icon dark>search</v-icon>
                    </div>
                  </v-text-field>
                  <!-- <p v-if="minCheckBox===true" class="ma-0 pa-0 px-3 " style="color:#ff5252;font-size: 12px;">At least one item should be selected</p> -->
                  <v-layout align-center class="elevation-1 mt-3">
                    <v-checkbox
                      dense
                      v-model="item.allSelctd"
                      @click="selectAllBox(item)"
                      hide-details
                      class="pa-0 ma-0 pa-2"
                      color="#23B1A9"
                      :disabled="isViewOnly"
                      v-if="item.lstOfCvrgs && item.lstOfCvrgs.length > 0"
                    >
                      <span
                        slot="label"
                        class="font-weight-bold"
                        style="font-size: 12px;color:#10242B"
                        >Select All</span
                      >
                    </v-checkbox>
                  </v-layout>

                  <v-layout column wrap v-if="showHidePerm">
                    <!-- <h2>which side array</h2> -->
                    <v-flex
                      xs2
                      v-for="lst in item.lstOfCvrgs"
                      :key="lst._id"
                      class=""
                    >
                    <!-- item.selectedSpecialPermissions -->  
                    <!-- :value="lst._id"                -->
                      <v-checkbox
                        :disabled="lst.permission_check || isViewOnly"
                        v-model="lst.sel"
                        color="#23B1A9"                        
                        @click="slctCvrg(item,true)"
                        hide-details
                        :class="{
                          disablestyle: lst.permission_check,
                          'elevation-1': !lst.permission_check,
                        }"
                        class="pa-0 ma-0 pa-2 py-2 mt-2"
                        ><span
                          slot="label"
                          class="font-weight-bold"
                          style="font-size: 12px;word-wrap: break-word; color:#646666 "
                          >{{ lst.permissionName }}</span
                        >
                      </v-checkbox>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>
          </v-card>
          <v-card
            tile
            class=" pa-3 my-4"
            style="background-color: #F7F7F7; display:flex;justify-content:space-between"
          >
            <!-- <v-layout style="flex:1" justify-end>
          <v-btn
            :dark="!dsbltrialBtn"
            :disabled="dsbltrialBtn"
            @click="auditTrial"
            tile
            style="background-color:#23B1A9"
            class="text-capitalize px-3 mx-2"
            small
            ><v-icon dark small>check_circle</v-icon>
            <v-divider vertical class="mx-2"></v-divider>Audit Trail</v-btn
          >
          <v-btn
            :dark="!dsblSaveBtn"
            :disabled="dsblSaveBtn"
            @click="CreateUser"
            tile
            style="background-color:#23B1A9"
            class="text-capitalize px-3 mx-2"
            small
            ><v-icon dark small>check_circle</v-icon>
            <v-divider vertical class="mx-2"></v-divider>Save</v-btn
          >
          <v-btn
            :dark="!dsblSubmitBtn"
            :disabled="dsblSubmitBtn"
            @click="CreateUser"
            tile
            style="background-color:#23B1A9"
            class="text-capitalize px-3 mx-2"
            small
            ><v-icon dark small>arrow_forward</v-icon>
            <v-divider vertical class="mx-2"></v-divider>Submit</v-btn
          >
          <v-btn
            @click="confirmDialogCancel = true"
            tile
            style="background-color:#E46A25"
            class="text-capitalize px-3 ml-2"
            small
            dark
            ><v-icon dark small>cancel</v-icon>
            <v-divider vertical class="mx-2"></v-divider> Close</v-btn
          >
          <v-btn
            :dark="!shwNxtbtn"
            :disabled="shwNxtbtn"
            @click="NxtClick"
            tile
            style="background-color:#23B1A9"
            class="text-capitalize px-3 mx-2"
            small
            ><v-icon dark small>arrow_forward</v-icon>
            <v-divider vertical class="mx-2"></v-divider>Next</v-btn
          >
        </v-layout> -->

            <!-- <v-btn width="110" class="btnstyle ml-3 reset" outlined color="red" v-if="!isViewOnly && !$store.getters.fetchCreateEditUserData.user_id" @click="clearData()">
              <v-icon class="mr-2 iconsize" color="red">mdi-backspace</v-icon>
              <div class="vertical_line text--white"></div>
              Clear
            </v-btn> -->
            <v-btn 
              width="110" 
              class="btnstyle ml-3 reset" 
              outlined 
              color="red" 
              @click="closeClick()">
                <v-icon class="mr-2 iconsize" color="red">mdi-backspace</v-icon>
                <div class="vertical_line text--white"></div>
              Cancel
            </v-btn>
            <v-btn
              width="110"
              class="btnstyle ml-3 btnLeft"
              color="#23B1A9"
              dark
              @click="submitLobData(item,index)"
              :disabled="isViewOnly"
              ><v-icon class="mr-2 iconsize"
                >mdi-arrow-right-bold-circle</v-icon
              >
              <div class="vertical_line"></div>
              Submit
            </v-btn>
          </v-card>

          <v-dialog v-model="confirmDialogCancel" persistent max-width="450px" content-class="dialogStyle">
            <v-card tile color="#F7F7F7">
              <v-card tile>
                <v-layout
                  justify-space-between
                  class=""
                  style="flex:1;background-ox-shadow: 0px 3px 5px #00000029;"
                >
                  <v-card-title
                    class="pa-0 pl-3 text-capitalize font-weight-regular"
                    style="color:black"
                  >
                    CONFIRMATION
                  </v-card-title>
                  <div
                    style="border-left:1px solid #C1C8CC;"
                    @click="close_cancelDialog()"
                  >
                    <v-icon style="cursor: pointer;" class="pa-2">close</v-icon>
                  </div>
                </v-layout>
              </v-card>
              <v-flex> </v-flex>
              <v-card-text>
                Details saved on screen will be lost
              </v-card-text>
              <v-card-actions justify-start>
                <v-btn
                  dark
                  tile
                  color="black"
                  @click="confirmDialogCancel = false"
                  class="text-capitalize px-3 mx-2"
                  small
                  ><v-icon dark small>cancel</v-icon>
                  <v-divider vertical class="mx-2"></v-divider>Cancel</v-btn
                >
                <v-btn
                  dark
                  @click="closeClick"
                  tile
                  color="#23B1A9"
                  class="text-capitalize px-3 ml-2"
                  small
                  ><v-icon dark small>check_circle</v-icon>
                  <v-divider vertical class="mx-2"></v-divider> OK</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- crate and map diolog -->
          <template>
            <v-row no-gutters justify="center">
              <v-dialog
                v-model="approveDialog"
                persistent
                max-width="58%"
                style="border-radius: 0 ;"
                content-class="dialogStyle"
              >
                <v-card height="37vh">
                  <v-card-title
                    class="pl-0 px-0 py-0 dailogboxAllvcardTableBackground"
                  >
                    <v-row no-gutters class="colorbg">
                      <span class="descrptionSpan mb-2 ml-8 mr-1"
                        >Create And Map LOB</span
                      >
                      <v-icon
                        @click="approveDialog = false"
                        class="mt-2"
                        color="#fff"
                        >mdi-window-close</v-icon
                      >
                    </v-row>
                    <v-card-text class="vcardTextTableBackground pl-2">
                      <v-form ref="searchForm">
                        <v-layout>
                          <v-flex xs12>
                            <v-layout column class="ml-3">
                              <p class="fontPragrapth stopbreaking">
                                You have successfully created user "<b>{{ name.first +" " + name.last}}</b>" & Mapped to LOB " <b>{{newLob}}</b>"<br />
                              </p>
                              <br />

                              <p class="fontPragrapth stopbreaking">
                                Would you Like to
                                <b class="bdFont">
                                  Map {{ name.first +" " + name.last}} To another LOB ?</b
                                >
                              </p>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                        <br />

                        <v-row no-gutters>
                          <v-col align="right">
                            <v-btn
                              class="btncommonstyle cancelbtn mr-3"
                              @click="closeAddAnotherLobDialog"
                              >No,I am Done
                            </v-btn>
                            <v-btn
                              color="#23B1A9"
                              dark
                              class="btncommonstyle mr-2"
                              @click="addRow(true)"
                              >Yes,Map To Another LOB
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-card-text>
                  </v-card-title>
                </v-card>
              </v-dialog>
            </v-row>
          </template>
          <!-- delete zone -->
          <template>
            <v-row no-gutters justify="center">
              <v-dialog
                v-model="deleteDialog"
                persistent
                max-width="53%"
                style="border-radius: 0 ;"
                content-class="dialogStyle"
              >
                <v-card height="31vh">
                  <v-card-title
                    class="pl-0 px-0 py-0 dailogboxAllvcardTableBackground"
                  >
                    <v-row no-gutters class="colorbg">
                      <span class="descrptionSpan mb-2 ml-8 mr-1 fontPragrapth"
                        >Delete LOB</span
                      >
                      <v-icon
                        @click="closeDeleteDialog"
                        class="mt-2"
                        color="#fff"
                        >mdi-window-close</v-icon
                      >
                    </v-row>
                    <v-card-text class="vcardTextTableBackground pl-2">
                      <v-form ref="searchForm">
                        <v-layout>
                          <v-flex xs12>
                            <v-layout column class="ml-3">
                              <p class="fontPragrapth">
                                Are you sure you want to delete <b>{{ deleteLobName }}</b> LOB?
                              </p>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                        <br />
                        <br />
                        <br />

                        <v-row no-gutters>
                          <v-col align="right">
                            <v-btn
                              class="btncommonstyle cancelbtn mr-3"
                              @click="closeDeleteDialog"
                              >Cancel
                            </v-btn>
                            <v-btn
                              color="red"
                              dark
                              class="btncommonstyle mr-2"
                              @click="deleteLob()"
                              >yes,Delete
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-card-text>
                  </v-card-title>
                </v-card>
              </v-dialog>
            </v-row>
          </template>
        </div>
      </div>
      <center
        v-if="showHideAddLobBtn"
        class=" px-3 searchtxt text--#23B1A9 ml-8"
        @click="()=>{!isViewOnly ? addRow() :''}"
        style="cursor:pointer;"
      >
      <!-- (selectContainer = true), (addFiled = false), -->
        <v-icon class="ml-2  fontStyle" color="#23B1A9" >mdi-plus</v-icon>
        Add LOB
      </center>
    </v-form>
  </v-app>
</template>
<script>
import moment from "moment";

export default {
  created() {
    this.$store.commit("SAVE_PAGE_HEADER", "Employee Master");

    console.log("Params==========>", this.$route.params);

    // console.log(this.$route.params, "this.$route.params");
    console.log(
      this.$store.getters.fetchCreateEditUserData,
      "this.$store.getters.fetchCreateEditUserData" 
    );

    if (this.$store.getters.fetchCreateEditUserData.editUser === true) {
      this.dsblEmplyId = true;
      this.dsblNtidId = true;
      this.dsblEmployeeName = true;
      this.modify=true;
      this.addFiled=false;
      this.selectContainer=true;
      this.UserObjId = this.$store.getters.fetchCreateEditUserData.user_id;
      this.isViewOnly = false
      // this.getUsersById(this.UserObjId);
      this.getUsersById(this.$store.getters.fetchCreateEditUserData.ntid)
      
    } else if (this.$store.getters.fetchCreateEditUserData.editUser === false) {      
      //Multilob
      if(this.$store.getters.fetchCreateEditUserData.user_id){
        //view case
        this.dsblEmplyId = true;
        this.dsblNtidId = true;
        this.dsblEmployeeName = true;
        this.modify=false;      
        this.addFiled=false;
        this.selectContainer=true;
        this.UserObjId = this.$store.getters.fetchCreateEditUserData.user_id;
        this.isViewOnly = true
        // this.getUsersById(this.UserObjId);
        this.getUsersById(this.$store.getters.fetchCreateEditUserData.ntid)
        
      }else{
        //Create Case
        this.isViewOnly = false
      }
      
      // this.getRoleDetails(this.selectedLob)
    }

    // this.slctsettlingOffice();

    //Surendra(21-Sep-2021):LOB List from BE,code starts here
    this.GET(3, "line-of-business/get-line-of-business-list", (res, error) => {
      if (!error) {
        console.log("response from api is", res.data.data[0].lineOfBusiness);
        this.itemsOfLOB = res.data.data[0].lineOfBusiness;
        console.log(this.itemsOfLOB)
        this.itemsOfLOB = res.data.data[0].lineOfBusiness;
      } else {
        console.log("error", error.response.data);
      }
    });
    
  },

  data() {
    return {
      showHidePerm:false,
      modify:false,
      arrayItem: [],
      AddLob: false,
      selectCheck: [],
      arrayItemlob: [1, 2],
      addFiled: true,
      deleteDialog: false,
      insideContent: false,
      selectContainer: false,
      approveDialog: false,
      enableafter: true,
      checkboxnom: false,
      nominateduser: {},
      item_obj: {},
      disable_special_permissions: [],
      name: { first: "", last: "" },
      itemsOfLOB: [],
      newSelectedScreens: [],
      special_permission_search: "",
      UserObjId: "",
      dsbltrialBtn: true,
      employeeMaster: false,
      confirmDialogCancel: false,
      active: true,
      // settlingOffice: true,     
      typeOfEmployeeItems: [],
      typeOfEmployee: "",
      employeeID: "",
      agreementType: null,
      NTIDUserID: "",
      employeeName: "",
      joiningDatecalender: false,
      joiningDate: null,
      email: "",
      mbNo: "",
      max30: 30,
      max10: 10,
      selectedRole: [],
      lstIds: [],
      selectedLob: [],
      allSelctd: false,
      noRoleSltd: false,
      dsblEmplyId: false,
      dsblNtidId: false,
      dsblEmployeeName: false,
      // minCheckBox:false,
      lstOfCvrgs: [],
      roleLstItms: [],
      AfterSortRoledataarray: [],
      items: [
        {
          role: "CGI1",
        },
        {
          role: "CGI2",
        },
        {
          role: "CGI3",
        },
        {
          role: "CGI4",
        },
        {
          role: "CGI5",
        },
      ],
      shwNxtbtn: true,
      Booleanrequired() {
        return (v) => (v && v == true) || "This Field is Required";
      },
      required() {
        return (v) => (v && v.length > 0) || "This Field is Required";
      },
      minLength(minLength) {
        return (v) =>
          (v && v.length >= minLength) ||
          `Must be atleast ${minLength} Characters`;
      },
      maxLength(maxLength) {
        return (v) =>
          (v && v.length <= maxLength) ||
          `Must be less than ${maxLength} Characters`;
      },
      spclChar() {
        return (v) =>
          /^[A-Za-z0-9 ]+$/.test(v) || "Special Characters Not Allowed";
      },
      alphaNumeric() {
        return (v) => /^[0-9 ]+$/.test(v) || `Only Numbers are allowed.`;
      },
      numberRule: (v) => {
        if (!v.trim()) return true;
        if (!isNaN(parseFloat(v)) && v > 0 && v <= 10) return true;
        return "Values between 1 and 10 are accepted";
      },
      decimalRule() {
        return (v) =>
          /^\d+(\.\d{1,2})?$/.test(v) || `Numeric upto two decimals.`;
      },
      emailRules() {
        return (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,6})+$/.test(v) ||
          "E-mail must be valid";
      },
      spclCharNtid(v) {
        return (v) =>
          /^[A-Za-z0-9-_]+$/.test(v) || "Special Characters Not Allowed";
      },
      disableAll: true,
      disableRoleList: false,
      editFiled:false,
      deleteLobName:"",
      newLob:"",
      isUserDataChanged:false,
      userDataFromDB:{lobData:[],mbNo:"",email:""},
      role_search:"",
      searchSupervisors:"",
      supervisorOptions:[],
      supervisor:[]
    };
  },
  methods: {
    searchRole(item, role) {
      let lobIndex  = this.arrayItem.findIndex((elem)=>{
                              return elem.lob == item.lob;
                            })
      this.arrayItem[lobIndex].filteredRoleOptions = 
                    this.arrayItem[lobIndex].roleOptions.filter((ele) => {
                      return ele.Rolenamewithdesignation.toLowerCase().includes(role.toLowerCase())
                    });
      console.log("role item++++", item, role, this.arrayItem[lobIndex], lobIndex);

    },
    isRoleChanged(currentIndex){
      if(this.arrayItem[currentIndex].selectedRole.length !==  this.userDataFromDB.lobData[currentIndex].assignRole.length){
        return true
      }else if(this.arrayItem[currentIndex].selectedRole.filter((elem)=>{ return this.userDataFromDB.lobData[currentIndex].assignRole.find((role)=>{ return role._id != elem})}).length > 0){
        return true
      }
      return false
    },
    isSpecialPermChanged(currentIndex){
      if(this.arrayItem[currentIndex].selectedSpecialPermissions.length !==  this.userDataFromDB.lobData[currentIndex].assignSpecialPermission.length){
        return true
      }else if( this.userDataFromDB.lobData[currentIndex].assignSpecialPermission.filter((elem)=>{ return this.arrayItem[currentIndex].selectedSpecialPermissions.find((role)=>{ return role != elem})}).length > 0){
        return true
      }
      return false
    },
    checkEditForModifty(item,index){ 
      if(this.isUserDataChanged && item._id && !this.isViewOnly && (this.email !== this.userDataFromDB.email || this.mbNo !== this.userDataFromDB.mbNo)){
        this.showAlert("Please submit user data to proceed.");
        return
      }


      let currentExpandedIndex  = this.arrayItem.findIndex((e) => { return e.expandRow ==  true });
      if(currentExpandedIndex == index){
        return
      }
      if(this.arrayItem.filter((lobData)=>{
        return lobData.isLobDataChanged ? true : false
      }).length > 0 &&  (this.userDataFromDB.lobData[currentExpandedIndex].active !== this.arrayItem[currentExpandedIndex].active || this.isSpecialPermChanged(currentExpandedIndex) || this.isRoleChanged(currentExpandedIndex))){
        // let changedLob  = this.arrayItem.filter((lobData)=>{ return lobData.isLobDataChanged ? true : false })
        this.showAlert("Please submit data for "+this.arrayItem[currentExpandedIndex].lob);
        return
      }


       if(item._id && !this.isViewOnly){
          this.arrayItem.map((e,i) => {e.expandRow =  index==i ? true :false});
          this.arrayItem= [...this.arrayItem];
       }
      //  this.showAlert("Please click on submit to proceed.");
    },
    nominatedcheck(ident, lob) {
      // ident == 1 : checkbox
      // ident == 2 : radio button
      console.log(
        this.nominateduser,
        "this.nominateduser | this.selectedLOB ",
        this.selectedLob
      );
      if (ident == 1) {
        if (Object.keys(this.nominateduser).length == 0) {
          this.enableafter = true;
          this.checkboxnom = false;
          this.showToast(
            "Please Select an LOB to continue",
            this.TOST().WARNING
          );
          console.log("Select lob to continue");
          return;
        }

        // console.log(this.nominateduser.hasOwnProperty(this.selectedLOB.toString()),"this.nominateduser | this.selectedLOB ")
        if (this.nominateduser.hasOwnProperty(this.selectedLob.toString())) {
          console.log(
            this.selectedLob,
            "before going to if ",
            this.nominateduser[this.selectedLob.toString()]
          );
          //  alert("ident 1")
          if (this.nominateduser[this.selectedLob.toString()]) {
            //  alert("ident 1-2 IF")
            this.nominateduser[this.selectedLob.toString()] = false;
            console.log(this.nominateduser, " checkboxnom ");
          } else {
            //  alert("ident 1-3 ELSE")
            this.nominateduser[this.selectedLob.toString()] = true;
          }
        } else {
          if (this.checkboxnom) {
            this.nominateduser[this.selectedLob.toString()] = false;
          } else {
            this.nominateduser[this.selectedLob.toString()] = true;
          }
        }
      } else {
        if (Object.keys(this.nominateduser).length == 0) {
          //   alert("ident object length is 0");
          this.nominateduser[this.selectedLob] = false;
          this.enableafter = false;
          console.log(this.selectedLob, "nomintaed user", this.nominateduser);
          return;
        }

        //  alert("very else");
        console.log(
          this.nominateduser,
          "<- before maintaining checkbox status this.nominateduser ",
          this.selectedLob
        );

        if (this.nominateduser.hasOwnProperty(this.selectedLob)) {
          if (this.nominateduser[this.selectedLob]) {
            this.checkboxnom = true;
          } else {
            this.checkboxnom = false;
          }
        } else {
          this.checkboxnom = false;
        }

        if (!this.nominateduser.hasOwnProperty(this.selectedLob)) {
          this.nominateduser[this.selectedLob] = false;
        }
      }
    },

    close_cancelDialog() {
      this.confirmDialogCancel = false;
    },
    setALLis(arris,indexOfChangedData) {
      var d = 0;
      arris.map((e) => {
        if (e.sel) {
          d++;
        }
      });
      arris = arris.filter((elem)=>{ return !elem.permission_check })
      if (d == arris.length) {
        // console.log("select all should be CHECKED", arris);
        if (d == 0 && arris.length == 0) {
          this.arrayItem[indexOfChangedData].allSelctd = false;
          return;
        }
        this.arrayItem[indexOfChangedData].allSelctd = true;
      } else {
        this.arrayItem[indexOfChangedData].allSelctd = false;
        // console.log("select all should be DIS-CHECKED", arris);
      }
    },

    onFocus() {
      this.joiningDatecalender = true;
    },

    spclCharNot(v) {
      if (v && v.length !== 0) {
        // console.log("testing", /^[0-9 ]+$/.test(v));
        if (!/^[A-Za-z0-9]+$/.test(v)) {
          return "Special Characters Not Allowed";
        } else {
          // console.log("else none");
          return  true
        }
      }else{
        return  true
      }
    },
    onlyText(v) {
      if (v && v.length !== 0) {
        // console.log("testing", /^[0-9 ]+$/.test(v));
        if (!/^[a-zA-Z,]+(\s{0,1}[a-zA-Z, ])*$/.test(v)) {
          return "Only Alphabets are Allowed";
        } else {
          // console.log("else none");
          return  true
        }
      }else{
        return true
      }
    },

    Numeric2(v) {
      if (v && v.length !== 0) {
        // console.log("testing", /^[0-9 ]+$/.test(v));
        if (!/^[0-9 ]+$/.test(v)) {
          return "Only Numbers are allowed.";
        } else {
          // console.log("else none");
          return  true
        }
      }else{
        return true
      }
    },
    emailRules2(v) {
      if (v && v.length !== 0) {
        // console.log("testing", /^[0-9 ]+$/.test(v));
        if (!/^\w+([.-/\+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,6})+$/.test(v)) {
          return "E-mail must be valid";
        } else {
          // console.log("else none");
          return  true
        }
      }else{
        return true
      }
    },
    alphaNumeric2(v) {
      if (v && v.length !== 0) {
        // console.log("testing", /^[0-9 ]+$/.test(v));
        if (!/^[0-9 ]+$/.test(v)) {
          return "Only Numbers are allowed";
        } else {
          // console.log("else none");
          return  true
        }
      }else{
        return true
      }
    },
    requiredIf(v) {
      console.log("this.typeOfEmployee", this.typeOfEmployee);
      if (this.typeOfEmployee == "Service ID") return;
      return (v) => (v && v.length > 0) || "This Field is Required";
    },
    selectAllBox(item) {
      console.log(item)
      this.showHidePerm = false
      let newData = [];
      newData =
        this.special_permission_search.length != 0
          ? this.newSelectedScreens
          : item.lstOfCvrgs;
      let lobIndex  = this.arrayItem.findIndex((elem)=>{
                return elem.lob == item.lob
              })
      if (item.allSelctd) {
        console.log("newData is ", newData);
        for (let i in this.arrayItem[lobIndex].lstOfCvrgs) {
          if (!this.arrayItem[lobIndex].lstOfCvrgs[i].sel) {
            if (!this.arrayItem[lobIndex].lstOfCvrgs[i].permission_check) {
              this.arrayItem[lobIndex].lstOfCvrgs[i].sel = true;
              console.log("this.arrayItem[lobIndex] => ",this.arrayItem[lobIndex])
            }
          }
        }
        // for (let i in newData) {
        //   if (!newData[i].sel) {
        //     if (!newData[i].permission_check) {
        //       newData[i].sel = true;
        //     }
        //   }
        // }
      } else {
        for (let i in this.arrayItem[lobIndex].lstOfCvrgs) {
          if (!this.arrayItem[lobIndex].lstOfCvrgs[i].permission_check) {
            this.arrayItem[lobIndex].lstOfCvrgs[i].sel = false;
          }
        }
      }
      
      
      
      // this.special_permission_search.length != 0
      //   ? this.newSelectedScreens
      //   : (this.arrayItem[lobIndex].lstOfCvrgs = newData);

        this.showHidePerm = true    
    },
    slctCvrg(item,isFromUi) {
      let indexOfChangedData  = this.arrayItem.findIndex((elem)=>{return elem.lob == item.lob})
      if(isFromUi){        
        this.arrayItem[indexOfChangedData].isLobDataChanged = true
      }
      

      this.showHidePerm = false
      var arris =
        this.special_permission_search.length != 0
          ? this.newSelectedScreens
          : item.lstOfCvrgs;
          this.showHidePerm = true
      this.setALLis(arris,indexOfChangedData);
    },
    changeLob(item, roleData, spclPrmssnData,index) {
         let filter = this.arrayItem.filter(ele =>ele.lob== item.lob);
         if(filter.length > 1){
             this.showAlert("This lob is already added, please select another one.", "OK", "", () => {
                this.arrayItem[index].lob="";
                this.arrayItem=[...this.arrayItem];
             });
             return;
         }
      this.AddLob = true;
      this.insideContent = true;
      console.log(this.selectedLob, "selectedLob");
      let lobis = item.lob;
      // this.nominatedcheck(2, lobis);
      let indexOfChangedData  = this.arrayItem.findIndex((elem)=>{return elem.lob == item.lob})
      if (item.lob !== "Marine" && item.lob !== "AIGC") {
        // this.disableRoleList = false;//LL
        this.arrayItem[indexOfChangedData].disableRoleList  = false
        console.log("Line 1529")
      }
      
      
      
      if(item.lob == "Travel" || item.lob == "Health"){
        this.callSearchSupervisor(item.lob)
      }
      this.getRoleDetails(item.lob, roleData, (res) => {
        // res here is the re-binding data if available, and is used for rebinding role's permisison to special permission
        this.getPermissionDetails(item.lob, spclPrmssnData, res);
      });
    },
    getRoleDetails(lob, roleData, spitdata) {
      console.log(lob, "lob",this.arrayItem);
      console.log(roleData, "roleData");
       let lobIndex  = this.arrayItem.findIndex((elem)=>{
                              return elem.lob == lob;
                            })
      
      // this.arrayItem.selectedRole = roleData;
      this.GET(6, "role/search-role?lineOfBusiness=" + lob, (res, error) => {
        this.arrayItem[lobIndex].roleOptions = [];
        this.selectedRole = [];
        if (!error) {
          if (res.data.statusCode === 200) {
            this.arrayItem[lobIndex].roleOptions = [];
            this.selectedRole = [];
            // this.roleLstItms = res.data.data.data;
            // let lobIndex  = this.arrayItem.findIndex((elem)=>{
            //                   return elem.lob == lob;
            //                 })
            this.arrayItem[lobIndex].roleOptions  = res.data.data.data
            console.log(res.data.data.data, "res Get Role Details");
            let role_permissions = [];
            //l=============LL==============

              if (
                (this.typeOfEmployee == "Contractual Employee" || this.typeOfEmployee == "Outsource") && lob == "Marine"           // this.selectedLob == "Marine"
              ) {
                for (let i = 0; i < this.arrayItem[lobIndex].roleOptions.length; i++) {
                  if (this.arrayItem[lobIndex].roleOptions[i].roleName == "CG9") {
                    // this.disableRoleList = true;//LL
                    this.arrayItem[lobIndex].disableRoleList = true;
                    console.log("Line 1573")
                    break;
                  } else {
                    // this.disableRoleList = false;//LL
                    this.arrayItem[lobIndex].disableRoleList = false;
                    console.log("Line 1578")
                  }
                }
              }
            //=============LL=================
            if (roleData != null) {
              
              // this.arrayItem.selectedRole = roleData;

              this.arrayItem[lobIndex].roleOptions.forEach((ritems) => {
                ritems.Rolenamewithdesignation =
                  ritems.roleName + " - " + ritems.roleDescription;
                ritems.splitname = ritems.roleName.split("CG");
                roleData.map((e) => {
                  if (ritems._id == e) {
                    role_permissions.push(ritems);
                  }
                });
              });
            } else {
              // this.roleLstItms.forEach((ritems) => {
                this.arrayItem[lobIndex].roleOptions.forEach((ritems) => {
                ritems.Rolenamewithdesignation =
                  ritems.roleName + " - " + ritems.roleDescription;
                ritems.splitname = ritems.roleName.split("CG");
              });
            }
            console.log(this.arrayItem[lobIndex].roleOptions, "before Pushing Data");
            this.arrayItem[lobIndex].roleOptions.sort((a, b) => a.splitname[1] - b.splitname[1]);

            console.log(this.arrayItem[lobIndex].roleOptions, "AfTer Pushing Data");

            if (roleData == null) {
          
              if (
                (this.typeOfEmployee == "Contractual Employee" ||
                  this.typeOfEmployee == "Outsource") && lob == "Marine"
                // this.selectedLob == "Marine"
              ) {
                for (let i = 0; i < this.arrayItem[lobIndex].roleOptions.length; i++) {
                  if (this.arrayItem[lobIndex].roleOptions[i].roleName == "CG9") {
                    this.selectedRole.push(this.arrayItem[lobIndex].roleOptions[i]._id);
                    // this.disableRoleList = true;//LL
                    this.arrayItem[lobIndex].disableRoleList = true;
                    console.log("Line 1601")
                    break;
                  } else {
                    // this.disableRoleList = false;//LL
                    this.arrayItem[lobIndex].disableRoleList = false;
                    console.log("Line 1606")
                  }
                }
              }
            }
            this.selectContainer = false
            this.insideContent = false
            this.arrayItem[lobIndex].filteredRoleOptions = this.arrayItem[lobIndex].roleOptions.map(a => {return {...a}})

      this.selectContainer = true
      this.insideContent = true
            spitdata(role_permissions);

          } else {
            this.showToast(res.data.msg, this.TOST().ERROR);
          }
        } else {
          let msg = "Roles not found."
          console.log(error.response.data)
          this.arrayItem[lobIndex].roleOptions  = []
          this.arrayItem[lobIndex].filteredRoleOptions  = []
          this.arrayItem[lobIndex].lstOfCvrgs   = []
          this.showToast(msg, this.TOST().WARNING);
        }
      });
    },

    getPermissionDetails(lob, spclPrmssnData, incomingroledata) {
      console.log(lob, "lob");
      // this.arrayItem.selectedRole = lob;
      console.log(spclPrmssnData, "spclPrmssnData");
      this.GET(
        6,
        "permission/search-permission?lineOfBusiness=" + lob,
        (res, error) => {
          if (!error) {
            if (res.data.statusCode === 200) {
              let lobIndex  = this.arrayItem.findIndex((elem)=>{
                return elem.lob == lob
              })
              this.arrayItem[lobIndex].lstOfCvrgs = [];
              this.arrayItem[lobIndex].lstIds = [];
              this.arrayItem[lobIndex].lstOfCvrgs = res.data.data.data;

              this.arrayItem[lobIndex].lstOfCvrgs.forEach((e) => {
                e.permission_check = false;
                e.sel = false;
              });

              console.log(res.data.data.data, "res getPermissionDetails");
              if (spclPrmssnData != null) {
                // this.lstIds=spclPrmssnData
                if (incomingroledata == null) {
                  incomingroledata = [];
                }

                // re-binding check of roles with multiple permissions-in it to disable the special permission list
                this.arrayItem[lobIndex].lstOfCvrgs.forEach((e) => {
                  incomingroledata.forEach((data) => {
                    data.permissionIds.map((roleis) => {
                      if (roleis == e._id) {
                        e.permission_check = true;
                      }
                    });
                  });
                  // for selecting and searching sel value is made true, if it matches anything on edit (re-bind)
                  spclPrmssnData.map((permission) => {
                    if (permission == e._id) {
                      e.sel = true;
                    }
                  });
                });
              }
              this.slctCvrg(this.arrayItem[lobIndex],false);
              this.showHidePerm = true;

            } else {
              this.showToast(res.data.msg, this.TOST().ERROR);
            }
          } else {
            this.showToast(error.response.data.msg, this.TOST().WARNING);
          }
        }
      );
    },
    // slctsettlingOffice() {
    //   if (this.settlingOffice === true) {
    //     this.shwNxtbtn = false;
    //   } else {
    //     this.shwNxtbtn = true;
    //   }
    // },

    set_special_permissioncheck(indata) {
      
      let setcheckbox = false;
      this.disable_special_permissions.forEach((datais) => {
        if (datais == indata) {
          setcheckbox = true;
        }
      });
      return setcheckbox;
    },

    getUsersById(id) {
      console.log("Incoming id", id);
      //users/get-by-ntid/:ntidUserId
      // this.GET(6, "users/all-users/" + id, (res, error) => {
        this.arrayItem  = [];
      this.GET(6, "users/get-by-ntid/" + id, (res, error) => {
        if (!error) {
          if (res.data.statusCode === 200) {
            console.log(res.data.data, "Get users by _id");
            // let tempData = res.data.data;
            for(let tempData of res.data.data){
              console.log("tempta => ",tempData)
            this.supervisor = tempData.supervisor
            this.UserObjId = tempData.hasOwnProperty("_id") ? this.chkplndata(tempData._id)  : "";
            this.active = tempData.hasOwnProperty("active") ? this.chkplndataBoolean(tempData.active)   : false;
            this.typeOfEmployee = tempData.hasOwnProperty("typeOfEmployee") ? this.chkplndata(tempData.typeOfEmployee) : "";
            this.employeeID = tempData.hasOwnProperty("employeeId") ? this.chkplndata(tempData.employeeId) : "";
            this.NTIDUserID = tempData.hasOwnProperty("ntidUserId") ? this.chkplndata(tempData.ntidUserId) : "";
            if (tempData.hasOwnProperty("name")) {
              if (tempData.name.hasOwnProperty("first")) {
                this.name.first = this.chkplndata(tempData.name.first);
              }
              if (tempData.name.hasOwnProperty("first")) {
                this.name.last = this.chkplndata(tempData.name.last);
              }
            }

            if (tempData.hasOwnProperty("joiningDate")) {
              if (this.chkplndata(tempData.joiningDate) == "") {
                this.joiningDate = null;
              } else {
                this.joiningDate = moment(
                  tempData.joiningDate,
                  "YYYY-MM-DD"
                ).format("YYYY-MM-DD");
              }
            }
            //    this.joiningDate=tempData.hasOwnProperty('joiningDate')? moment(this.chkplndata(tempData.joiningDate),'YYYY-MM-DD').format('YYYY-MM-DD') : ''
            this.mbNo = tempData.hasOwnProperty("contactMobileNo") ? this.chkplndata(tempData.contactMobileNo) : "";
            this.email = tempData.hasOwnProperty("email")  ? this.chkplndata(tempData.email)  : "";
            this.selectedLob = tempData.hasOwnProperty("selectedLOB") ? this.chkplndata(tempData.selectedLOB)  : "";

            let tempassignRole = tempData.hasOwnProperty("assignRole")  ? this.chkplndataarry(tempData.assignRole)   : [];
            let tempassignRole2 = [];
            for (let i in tempassignRole) {
              tempassignRole2.push(tempassignRole[i]._id.toString());
            }

            tempassignRole.map((e) => {
              this.disable_special_permissions.push(e.permissionIds);
            });

            let tempassignSpecialPermission = tempData.hasOwnProperty("assignSpecialPermission") ? this.chkplndataarry(tempData.assignSpecialPermission) : [];
              let tempLobData = {
                lob: tempData.selectedLOB,
                active: tempData.active ? true : false,
                selectedRole: [],
                selectedSpecialPermissions: [],
                roleOptions:[],
                specialPermissionOptions:[],
                allSelctd:false,
                _id:tempData._id,
                expandRow: false, //added by gopi
                isLobDataChanged:false,
                supervisor:tempData.hasOwnProperty("supervisor") ? tempData.supervisor : []
              }
              if(tempData.hasOwnProperty("supervisor") && tempData.supervisor && tempData.supervisor.ntidUserId && tempData.supervisor.userId){
                this.supervisor = tempData.supervisor
                this.getSupervisorData(tempData.supervisor.ntidUserId)
                this.callSearchSupervisor(tempData.selectedLOB)
              }
              tempData.assignRole.map((role)=>{
                tempLobData.selectedRole.push(role._id)
              })

              tempData.assignSpecialPermission.map((perm)=>{
                tempLobData.selectedSpecialPermissions.push(perm)//perm.permissionName
              })
              
              tempLobData.expandRow =  false//this.isViewOnly ? true :
              this.arrayItem.push(tempLobData);
              
            this.changeLob({lob:tempData.selectedLOB},tempassignRole2,tempassignSpecialPermission);

            if (tempData.hasOwnProperty("employee") && tempData.hasOwnProperty("externalResource")) {
              if (tempData.employee === true) {
                this.agreementType = "employee";
              } else if (tempData.externalResource === true) {
                this.agreementType = "externalResource";
              }
            } else {
              this.agreementType = "employee";
            }

            let setnominated = tempData.hasOwnProperty("nominatedUser")
              ? tempData.nominatedUser
              : [];

            if (setnominated.length != 0) {
              setnominated.forEach((e) => {
                this.nominateduser[e] = true;
              });
            }

            // this.nominatedcheck(2, this.selectedLob);

            // this.arrayItem.push(tempLobData);
            }
            this.userDataFromDB.mbNo  = res.data.data[0].contactMobileNo
            this.userDataFromDB.email  = res.data.data[0].email
            this.userDataFromDB.lobData = []
            res.data.data.map((lob)=>{
              this.userDataFromDB.lobData.push(lob)
            })
            this.dsblEmplyId = true;
            this.dsblNtidId = true;
            this.dsblEmployeeName = true;

          } else {
            this.showToast(res.data.msg, this.TOST().ERROR);
          }
        } else {
          this.showToast(error.response.data.msg, this.TOST().WARNING);
        }
      });
    },
    chkplndataarry(data) {
      console.log("chkplndataarry", data);
      try {
        if (data === "" || data === "undefined" || data === null) {
          return [];
        } else {
          return data;
        }
      } catch (err) {
        console.log(err);
      }
    },

    chkplndata(data) {
      console.log("chkplndata", data);
      try {
        if (data === "" || data === "undefined" || data === null) {
          return "";
        } else {
          return data;
        }
      } catch (err) {
        console.log(err);
      }
    },
    chkplndataBoolean(data) {
      console.log("chkplndataBoolean", data);
      try {
        if (data === "" || data === "undefined" || data === null) {
          return false;
        } else {
          return data;
        }
      } catch (err) {
        console.log(err);
      }
    },
    slcttypeOfEmployee() {
      if (this.typeOfEmployee === "Service ID") {
        this.noRoleSltd = false;
      }

      if (
        this.typeOfEmployee !== "Contractual Employee" &&
        this.typeOfEmployee !== "Outsource"
      ) {
        console.log("Line 1882")
        this.disableRoleList = false;
      } else if (
        (this.selectedLob == "Marine" || this.selectedLob == "AIGC") &&
        (this.typeOfEmployee == "Contractual Employee" ||
          this.typeOfEmployee == "Outsource")
      ) {
        this.selectedRole = [];
        for (let i = 0; i < this.roleLstItms.length; i++) {
          if (this.roleLstItms[i].roleName == "CG9") {
            console.log("Line 1888")
            this.selectedRole.push(this.roleLstItms[i]._id);
            this.disableRoleList = true;
            break;
          } else {
            this.disableRoleList = false;
          }
        }
      }
    },
    chkRoleSltd(item) {
    
    let indexOfChangedData  = this.arrayItem.findIndex((elem)=>{return elem.lob == item.lob})
    this.arrayItem[indexOfChangedData].isLobDataChanged = true

      let incomingrole = this.item_obj;
      console.log(item.selectedRole, "incoming role", incomingrole, " item =>",item);
      
      let nominatedUserObj = item.filteredRoleOptions.find(function(elem) {
        return elem.roleDescription == "Nominated User";
      });
      
      if (nominatedUserObj && nominatedUserObj._id) {
        let isNominatedUserSelected = item.selectedRole.find(function(elem) {
          return elem == nominatedUserObj._id;
        });
        if (isNominatedUserSelected) {
          this.arrayItem[indexOfChangedData].isNominatedUser  = true
          this.checkboxnom = true;
          if (item.lob) {
            this.nominateduser[item.lob] = true;
          }
        } else {
          this.arrayItem[indexOfChangedData].isNominatedUser  = false
          this.checkboxnom = false;
          if (item.lob && Object.keys(this.nominateduser).includes(item.lob)) {
            // this.nominateduser[item.lob] = false;            
            delete this.nominateduser[item.lob]
          }
        }
      }
      

      if (incomingrole.isActive == "InActive") {
        this.showToast(
          "Role " +
            incomingrole.roleName +
            " " +
            incomingrole.roleId +
            " is currently In-Active",
          this.TOST().WARNING
        );

        let roleindex = item.selectedRole.findIndex(
          (e) => e == incomingrole._id
        );
        if (roleindex != -1) {
          item.selectedRole.splice(roleindex, 1);
        }
        return;
      }

      let rpos = item.selectedRole.findIndex((e) => e == incomingrole._id);
      // console.log("rpos role",rpos);
      if (rpos == -1) {
        incomingrole.permissionIds.forEach((e) => {
          item.lstOfCvrgs.map((lst) => {
            if (e == lst._id) {
              lst.permission_check = false;
              lst.sel = false;
            }
          });
        });
      } else {
        incomingrole.permissionIds.forEach((e) => {
          item.lstOfCvrgs.map((sp) => {
            if (sp._id == e) {
              sp.permission_check = true;
            }
          });
        });
      }
      if (this.typeOfEmployee !== "Service ID") {
        if (
          this.selectedRole.length === 0 ||
          this.selectedRole === undefined ||
          this.selectedRole === null
        ) {
          this.noRoleSltd = true;
        } else {
          this.noRoleSltd = false;
        }
      } else {
        this.noRoleSltd = false;
      }
    },
    closeClick() {
      // this.$router.push({ name: "UserSearch" });
      this.$router.go(-1)
    },
    checkin_SP(incomingrole){
            //@click runs before change so getting the selcted object first for commparinng with v-model of the v-list group
            this.item_obj = incomingrole
            // this.disable_special_permissions.findIndex(pid=> pid == incomingrole._id);
            return
                // sp99
            //    console.log(this.selectedRole,')--> ',incomingrole.permissionIds)
            //    incomingrole.permissionIds.filter(e=>{
            //          let possis = this.disable_special_permissions.findIndex(pid=> pid == e) 
            //          if(possis == -1){
            //               this.disable_special_permissions.push(e)
            //          }else{
            //              let Srole_poss = this.selectedRole.findIndex(r_id=> r_id == incomingrole._id)
            //              if(Srole_poss == -1){
            //                   this.disable_special_permissions.splice(possis, 1)
            //              }
            //          }
            //     }) 
            //     console.log(this.disable_special_permissions," Select-Role---> ",this.selectedRole);
        },

    dateRegex(v) {
      if (!v) return true;
      return (v) =>
        /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/.test(
          v
        ) || "Please enter date in 'dd-mm-yyyy' format";
    },

    datePickerValue(typeis, fromis) {
      //  dp99
      let valueIs =
        typeis == 1
          ? this.$refs.datePicker_1.lazyValue
          : this.$refs.datePicker_2.lazyValue;
      console.log(typeis, "typeis|valueIs", valueIs);
      if (valueIs == "Invalid date") {
        this.joiningDate = this.computedatenew("def");
      } else {
        // console.log(typeis,"ELSE typeis|valueIs",valueIs);
        this.joiningDate = this.computedatenew("txt", valueIs);
      }
    },

    datePickerValue2(typeis, fromis) {
      let valueIs =
        typeis == 1
          ? this.$refs.datePicker_1.lazyValue
          : this.$refs.datePicker_2.lazyValue;

      if (fromis == "input" && valueIs.length == 10) {
        this.joiningDate = this.computedatenew("txt", valueIs);
        console.log(typeis, "else if typeis|valueIs", valueIs);
      }

      if (fromis == "input" && valueIs.length == 0) {
        this.joiningDate = "";
      }
    },
    checkForUniqueNtid() {
      if (this.NTIDUserID) {
        let api_Is =
          "users/search-users?userid=" +
          this.NTIDUserID +
          "&lob=" +
          this.selectedLob +
          "&perPage=10" +
          "&pageNo=1";
        this.GET(6, api_Is, (res, error) => {
          try {
            if (!error) {
              console.log(
                "GET APi response|->/users/search-users",
                res.data.data
              );
              if (
                res.data &&
                res.data.data &&
                res.data.data.data &&
                res.data.data.data.length > 0
              ) {
                this.showAlert(
                  "Entered NTID already exist, it should be unique."
                );
                this.NTIDUserID = "";
              }
            } else {
              console.log(
                "GET APi error response|->/users/search-users",
                error
              );
              // this.showToast("No Result found for this NTID.", this.TOST().WARNING);
            }
          } catch (e) {
            console.log("GET APi  errorresponse|-> /users/search-users", e);
          }
        });
      }
    },
    addRow(getUserDetails) {
      // { lob: "", selectedRole: [], active: true, selectedSpecialPermissions: [], allSelctd:false }
      this.arrayItem.push({
        lob: "",
        active: true,
        selectedRole: [],
        selectedSpecialPermissions: [],
        roleOptions:[],
        filteredRoleOptions: [],
        specialPermissionOptions:[],
        allSelctd:false,
        expandRow:true, //added by gopi
        isNominatedUser:false,
        disableRoleList:false
      });
      this.insideContent= true
      this.selectContainer=true
      console.log("Addrow Check===", this.arrayItem);
      // this.arrayItemlob.push(3);
      this.approveDialog  = false
      if(getUserDetails){
        this.getUsersById(this.NTIDUserID)
      }
    },
    submitLobData(item,index){
      
      if (!this.$refs.employeeMaster.validate()){
          this.$refs.employeeMaster.validate()
          this.showToast("Please fill required fields",this.TOST().WARNING);
          return
      }

      if(!item.lob){
        this.showToast("Please select the lob.",this.TOST().WARNING);
        return
      }

      if(this.typeOfEmployee !== 'Service ID'){
          if(item.selectedRole.length===0 ||item.selectedRole===undefined|| item.selectedRole===null){
              this.showToast("Please select atleast one role.",this.TOST().WARNING);
              this.noRoleSltd=true
              return
          }
      }

      //Check special permissions
      var special_permission = [];
      // item.lstOfCvrgs.map(e=>{
      //         if(e.sel){
      //           special_permission.push(e._id)
      //         }                
      // })
      let selectedSpecialPermissions  = item.lstOfCvrgs.filter((elem)=>{ return elem.sel == true})//Surendra(07-02-2023)=>https://tataaig.atlassian.net/browse/WC-2460 //item.lstOfCvrgs.filter((elem)=>{ return item.selectedSpecialPermissions.find((permName)=>{return permName == elem._id}) })
      selectedSpecialPermissions.map(e=>{
              if(e._id){
                special_permission.push(e._id)
              }                
      })

      console.log(item.lstOfCvrgs, "< - special_permission ", special_permission);

      console.log("Object.keys(this.nominateduser).length > 0 ",Object.keys(this.nominateduser).length)

      let formData={
                active: item.active,
                name:{
                    first:this.name.first,
                    last:this.name.last
                },
                employee: this.agreementType==="employee"?true:false,
                externalResource:  this.agreementType==="externalResource"?true:false,
                typeOfEmployee: this.typeOfEmployee,
                employeeId: this.employeeID,
                ntidUserId: this.NTIDUserID,
                employeeName: this.name.first+" "+this.name.last,
                // this.employeeName
                joiningDate: this.joiningDate,
                contact: true,
                contactMobileNo: this.mbNo,
                email: this.email,
                assignRole: item.selectedRole,
                assignSpecialPermission: [...special_permission] ,
                selectedLOB: item.lob,
                isModify: this.UserObjId ? true : false,
                nominatedUser: Object.keys(this.nominateduser).length > 0 ? Object.keys(this.nominateduser) : [],//Object.keys(this.nominateduser).length > 0 && Object.keys(this.nominateduser).includes(item.lob) ? [item.lob] : [],//Object.keys(this.nominateduser).length > 0 ? Object.keys(this.nominateduser) : [],
                supervisor: (item.lob=="Travel" || item.lob=="Health") ? this.supervisor : []
            }

      console.log(this.arrayItem," Item => ",item,"formData",formData)
      //ToDo : Check nominated user and Marine CG9 validation
      let apiURL=''
      if(item._id){
        //Update Case
         apiURL='users/update-user/'+item._id//this.UserObjId
          this.PUT(6,apiURL,formData,(res,error)=>{
              if(!error){
                  console.log("Put APi response|-> onboard-user ", res)
                  this.showToast(res.data.msg,this.TOST().SUCCESS)
                 // this.$router.push('/usersearch')
                 this.isUserDataChanged = false
                this.arrayItem[index].expandRow=false;
                this.arrayItem=[...this.arrayItem];
                this.getUsersById(this.NTIDUserID)
                  
              }else{
                  console.log("Put APi error response|-> onboard-user ", error)
                  this.showToast(error.response.data.msg ,this.TOST().ERROR);
                  
              }
          })
           
      }else{
        //Create Case
        apiURL="users/onboard-user"
        formData.system = "CM"//this.user_details().selectedLOB;
        this.POST(6,apiURL,formData,(res,error)=>{
            if(!error){
                console.log("POST APi response|-> onboard-user  ", res.data.msg)
                this.showToast(res.data.msg,this.TOST().SUCCESS)
                // this.showAlert(res.data.msg)
                // this.arrayItem[index].expandRow=false;
                // this.arrayItem=[...this.arrayItem];
                this.checkForAnotherLob(item.lob)
                

                this.UserObjId=res.data.data._id
                let btnName={
                    editUser:true,
                    user_id:this.UserObjId
                }
                this.$store.commit('USER_CREATE_EDIT_DATA', btnName)
                
              
                console.log("POST APi response|-> Generate object id ", this.UserObjId);
                
                
                
                
            }else{
                console.log("POst APi error response|->onboard-user ", error)
                this.showToast(error.response.data.msg ,this.TOST().ERROR);
                this.showAlert(error.response.data.msg)
            }    
        })
    
      }

         
    },
    deleteLob(){
      console.log(this.deleteLobName)
      this.arrayItem.splice(this.arrayItem.findIndex((elem)=>{
                  return elem.lob == this.deleteLobName;}),1)
      this.deleteDialog = false
      // this.selectContainer = false
      // this.insideContent = false
      this.addFiled = true
      this.deleteLobName  = ""
                                
    },
    confirmDeleteLob(item){
      this.deleteLobName  = item.lob;
      this.deleteDialog = true
    },
    closeDeleteDialog(){
      this.deleteLobName  = ""
      this.deleteDialog = false
    },
    checkForAnotherLob(newLob){
      this.approveDialog = true
      this.newLob = newLob
    },
    closeAddAnotherLobDialog(){
      this.approveDialog = false
      this.$router.push({ name: "UserSearch" });
    },
    updateUserData(){
      if(this.arrayItem.length > 0){
          for(var i in this.arrayItem){
            if(this.arrayItem.filter((lobData)=>{
              return lobData.isLobDataChanged ? true : false
            }).length > 0 &&  (this.userDataFromDB.lobData[i].active !== this.arrayItem[i].active || this.isSpecialPermChanged(i) || this.isRoleChanged(i))){
              // let changedLob  = this.arrayItem.filter((lobData)=>{ return lobData.isLobDataChanged ? true : false })
              this.showAlert("Please save the "+this.arrayItem[i].lob+" LOB mapping to proceed with submit");
              return
            }
          }
        this.submitLobData(this.arrayItem[0])
      }else{
        this.showToast("Something went wrong, please try again.")
      }
    },
    clearData(){
      this.arrayItem  = [];
      this.typeOfEmployee = ""
      this.employeeID = ""
      this.agreementType  = null
      this.NTIDUserID = ""
      this.employeeName = ""
      this.joiningDatecalender  = false
      this.joiningDate  = null
      this.email  = ""
      this.mbNo = ""
      this.name = { first: "", last: "" }
      this.special_permission_search  = ""
      
    },
    expandCollapseRow(rowIndex){
      // if(this.arrayItem.filter((lobData)=>{
      //   return lobData.isLobDataChanged ? true : false
      // }).length > 0){
      //   let changedLob  = this.arrayItem.filter((lobData)=>{ return lobData.isLobDataChanged ? true : false })
      //   this.showAlert("Please submit data for "+changedLob[0].lob);
      //   return
      // }

      let currentExpandedIndex  = this.arrayItem.findIndex((e) => { return e.expandRow ==  true });
      // if(currentExpandedIndex == rowIndex){
      //   return
      // }
      if(this.arrayItem.filter((lobData)=>{
        return lobData.isLobDataChanged ? true : false
      }).length > 0 &&  (this.userDataFromDB.lobData[currentExpandedIndex].active !== this.arrayItem[currentExpandedIndex].active || this.isSpecialPermChanged(currentExpandedIndex) || this.isRoleChanged(currentExpandedIndex))){
        // let changedLob  = this.arrayItem.filter((lobData)=>{ return lobData.isLobDataChanged ? true : false })
        this.showAlert("Please submit data for "+this.arrayItem[currentExpandedIndex].lob);
        return
      }

      this.arrayItem.map((elem,index)=>{
        if(rowIndex == index){
          elem.expandRow  = !elem.expandRow
        }else{
          elem.expandRow  = false
        }
        
      })
    },
    isIdAvailable(item){
      return item._id ? true : false
    },
    callSearchSupervisor(indata){
      if(indata!==""){
          let reqData = "lob=" +indata//&ntidUserid=+indata
          this.GETM("SEARCH_SUPERVISOR",reqData, res=>{      
            if(res.data && res.data.data && res.data.data.length){
              let currentUserNtid = this.NTIDUserID
              this.supervisorOptions  = res.data.data.filter((elem)=>{
                return elem.ntidUserId  !== currentUserNtid
              })
              this.supervisorOptions = this.supervisorOptions.map((elem)=>{
                return {
                  ntidUserId : elem.ntidUserId,
                  employeeName : elem.employeeName,
                  userId : elem._id
                }
              })
            }
            
          },(error)=>{
            console.log("SEARCH_SUPERVISOR error",error)
                  return error;
          },true);
      }
    },
    supervisorChanged(item,index){
      if(this.supervisor.length > 3){
        this.supervisor.splice(this.supervisor.length-1,1);
        this.showToast("Supervisor cannot be more than 3", this.TOST().WARNING);
        return
      }
      this.arrayItem[index].supervisor  = {userId:this.supervisor._id,ntidUserId:this.supervisor.ntidUserId,employeeName:this.supervisor.employeeName} 
      console.log(item)
    },
    getSupervisorData(supervisorNtid){
      this.GET(6, "users/get-by-ntid/" + supervisorNtid, (res, error) => {
        if (!error) {
          console.log("supervisor data => ",res.data.data)
          if (res.data.statusCode === 200) {
            this.supervisor = res.data.data[0].supervisor
          } else {
            this.showToast(res.data.msg, this.TOST().ERROR);
          }
        } else {
          this.showToast(error.response.data.msg, this.TOST().WARNING);
        }
      });
    },
    confirmDisableAllLobs(){

      this.showAlert("Are you sure to disable "+this.NTIDUserID+" for all LOBs?", "Yes", "No", ()=>{
        console.log(this.NTIDUserID,"this.NTIDUserID")
        this.disableForAllLobs()
      })
    },
    disableForAllLobs(){
 
      this.PUTM("DISABLE_ALL_LOB",this.NTIDUserID,"",(res)=>{
        console.log("res - ",res)
        this.showAlert(res.data.msg)
      },(error)=>{
        console.log("Error - ",error)
      },true)
    },
    getItemText(item){
      return `${item.employeeName} (${item.ntidUserId})`;
    }
   
  },
  watch:{
    searchSupervisors(val){
      if(val && val.length >= 3){
        this.callSearchSupervisor(val)
      }
    },
  },

  computed: {

    whatemployeewehave() {
   
      let a = ["Full Time", "Contractual Employee", "Outsource"];
      let b = [
        "External",
        "Service ID",
        "Investigator",
        "Surveyor",
        "Other",
        "Vendor",
        "Repairer",
        "Outsource",
      ];
      if (this.agreementType == "employee") {
        return a;
      } else {
        return b;
      }
    },   

    cmputdPlcyEfctveDteFrmtdMmntjs() {
      if (this.joiningDate == "Invalid date") {
        this.joiningDate = this.computedatenew("def") ?? "";
        return this.joiningDate
          ? moment(this.joiningDate).format("DD-MM-YYYY")
          : "";
      } else {
        return this.joiningDate
          ? moment(this.joiningDate).format("DD-MM-YYYY")
          : "";
      }
      
    },
    naForServiceId() {
      //Surendra(27-05-2021):added for a bug in marine sprint 4
      return !(this.typeOfEmployee == "Service ID"); // ? false : true;
    },
    naForemployeeID() {
      if (
        this.typeOfEmployee == "Part Time" ||
        this.typeOfEmployee == "Outsource"
      ) {
        return false;
      } else {
        return true;
      }
    },
    naTypeOfEmployee() {
      if (this.agreementType == "externalResource") {
        return false;
      } else {
        return true;
      }
    },
    showHideAddLobBtn(){
      let find = this.arrayItem.find(ele=> ele.expandRow==true || !ele._id);
      return find ? false : true;
      // return !this.arrayItem.length ? true : this.arrayItem.filter((elem)=>{
      //   return !elem._id 
      // }).length == 0 ? true : false
    },
     permission_search(item) {
      console.log("permission_search => ",item)
      if (this.allSelctd) {
        let resultes = this.lstOfCvrgs.filter((item) => {
          if (!this.special_permission_search) return this.lstOfCvrgs;
          return item.permissionName
            .toLowerCase()
            .includes(this.special_permission_search.toLowerCase());
        });
        let checkBox = this.lstOfCvrgs.map((item) => {
          return item.permissionName;
        });
        return resultes.map((item) => {
          return { ...item, checkbox: checkBox };
        });
      }
      return this.lstOfCvrgs.filter((item) => {
        if (!this.special_permission_search) return this.lstOfCvrgs;
        return item.permissionName.toLowerCase().includes(this.special_permission_search.toLowerCase());
      });
    },
    filterPermissions(item,index){
            console.log("item => ",item)
            console.log("Permission list => ",this.multizone[index].SZSO)
            if(searchData){
                return this.multizone[index].SZSO.filter((elem)=>{return elem.settlementOfficeName.toLowerCase().indexOf(searchData.toLowerCase()) !== -1});
            }else{
                return this.multizone[index].SZSO
            }
        },
    computedItemsOfLOB(){
      return this.itemsOfLOB.filter((elem)=>{
        return !this.arrayItem.find((item)=>{
          return item.lob == elem
        })
      })
    }
  },
};
</script>
<style>
.disablestyle {
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  box-shadow: none !important;
}

.cust_pln_em .v-text-field .v-input__slot,
.v-text-field--outline .v-input__slot {
  min-height: 41px !important;
  display: flex !important;
  align-items: center !important;
  /* border:1px red solid; */
}

.cust_pln_em .v-input .v-label {
  font-size: 13px !important;
}
.cust_pln_em .v-input {
  font-size: 13px !important;
}
.cust_pln_em .v-input input {
  font-size: 13px !important;
}

.cstm-clr-pln-em {
  color: #646666 !important;
  margin-bottom: 5px !important;
  font-size: 13px !important;
}

.cust-rmvpd-em
  .v-text-field.v-text-field--enclosed
  > .v-input__control
  > .v-input__slot {
  padding: 0px !important;
}

.selectedRole_clr {
  background-color: #23b1a9 !important;
  color: #ffffff !important;
}

.custDsbld_emplyMstr
  .theme--light.v-text-field--solo
  > .v-input__control
  > .v-input__slot {
  background: #f7f7f7;
}
.searchtxt {
  font-weight: bold;
  color: #23b1a9;
  margin-left: 50px;
}
.btnLeft {
  margin-left: 1015px;
}
.selectContent {
  background: #1ebbd9;
  height: 50px;
}

.custom-placeholer-color ::placeholder {
  color: #f7f7f7 !important;
  opacity: 1;
}
.textColor {
  color: #f7f7f7;
}
.v-list-item--active {
  color: white !important;
}
.theme--dark.v-select .v-select__selection--disabled {
  color: #646666 !important;
  font: bold;
}
.f-group1 {
  text-align: left;
  font: normal normal bold 13px/21px Roboto;
  letter-spacing: 0px;
  color: #646666 !important;
}
.btncommonstyle {
  text-transform: capitalize;
  /* letter-spacing: 0.5px; */
  height: 31px !important;
  font-size: 11px !important;
  border-radius: 4px;
}

.cancelbtn {
  border: 1px solid #4c4c4c;
}

.dailogboxAllvcardTableBackground {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 7px #00000029;
  opacity: 1;
  font-size: 14px;
}

.vcardTextTableBackground {
  background-color: #b4b4b440 !important;
  /* box-shadow: inset 0px 3px 4px 0px #00000047; */
}

.colorbg {
  background-color: #23b1a9;
  color: #fff;
}

.descrptionSpan {
  width: 85%;
  margin-top: 21px;
  font-weight: 500;
  font-size: 17px;
  letter-spacing: 0.4px;
}

.dialogBoxCardTitle {
  background: #ffffff 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 5px 7px #00000029 !important;
  opacity: 1 !important;
  font-size: 14px !important;
}

.ContainerBGColor {
  background-color: #f7f7f7;
}

.f-group .v-input {
  margin-top: 4px;
  border-radius: 0;
}

.f-group label {
  text-align: left;
  font: normal normal bold 13px/21px Roboto;
  letter-spacing: 0px;
  color: #646666 !important;
}

.text-xsm {
  font: normal normal bold 13px/22px Roboto !important;
}

.form-row {
  border-top: #f7f7ff solid 2px;
  padding: 2em 5em;
}

.overflowNone {
  overflow: hidden !important;
}

/* viewMore code */
.dialogBoxCardTitle {
  background: #ffffff 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 5px 7px #00000029 !important;
  opacity: 1 !important;
  font-size: 14px !important;
}

.ContainerBGColor {
  background-color: #f7f7f7;
}

.tableSize {
  width: 150% !important;
}
.dragLayout {
  width: 385px;
  height: 282px;
  background: #dddddd;
  margin-left: 347px;
  margin-top: -129px;
}
.fontPragrapth {
  font-size: 20px;
}
.bdFont {
  color: #23b1a9;
}
/* .selectList{
    background: #1ebbd9;
    color: azure;

} */
</style>
