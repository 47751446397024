<template>
<v-app>
        
        <div style="background-color: #FFFFFF;" column class=" ">
          <v-form v-model="valid" ref="mapuserform">  
        <v-layout class="mt-2 ml-2">
            
                <v-flex xs3>
                    <v-layout column class="cust_pd mx-2 my-2 ">
                        <v-text class="font-weight-bold cstm-clr-pd">
                           User ID
                        </v-text>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="userID"
                            :rules="[spclCharNot]"
                            class="rounded-0"
                            counter="60"
                            maxlength="60"
                            v-on:keyup.enter="onSearch"
                            >
                        </v-text-field>
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class=" mx-2 my-2 cust_pd">
                        <v-text class="font-weight-bold cstm-clr-pd">
                           Employee ID
                        </v-text>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="employeeID"
                            :rules="[spclCharNot]"
                            class="rounded-0"
                            counter="60"
                            maxlength="60"
                            v-on:keyup.enter="onSearch"
                            >
                        </v-text-field>
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class=" mx-2 my-2 cust_pd">
                        <v-text class="font-weight-bold cstm-clr-pd">
                            Employee Name
                        </v-text>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="employeeName"
                            :rules="[Onlystring]"
                            class="rounded-0"
                            counter="60"
                            maxlength="60"
                            v-on:keyup.enter="onSearch"
                            >
                        </v-text-field>
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class="ml-3">
                      <p class="mb-2 lableFontSize">Agreement Type</p>
                      <v-select
                        placeholder="Enter"
                        solo
                        dense
                        class="forminput"
                        :items="['Employee', 'External Resource']"
                        v-model="agreementType"
                        clearable
                      >
                      </v-select>
                    </v-layout>
                  </v-flex>

                <v-flex xs3>
                    <v-layout column class="ml-3">
                      <p class="mb-2 lableFontSize">Type of Employee</p>
                      <v-select
                        placeholder="Enter"
                        solo
                        dense
                        class="forminput"
                        :items="['Full Time', 'Contractual Employee']"
                        v-model="typeOfEmployee"
                        clearable
                      >
                      </v-select>
                    </v-layout>
                  </v-flex>
            
                                            
            </v-layout>

            </v-form>
            
            

            <v-card style="max-height:150px" class="mt-2 rounded-0">
                <div class="mx-2 pa-2" style="background-color:#eee; display:flex;justify-content:space-between;margin-left:0px!important;margin-right:0px!important;"  >
                <v-layout style="flex:1" justify-end > 
                <v-btn  dark tile color="#E46A25" class="text-capitalize px-3 mx-2 my-2" small @click="onSearch()"><v-icon dark small>search</v-icon> <v-divider vertical class="mx-2"></v-divider>Search</v-btn>
                <v-btn dark  tile color="#E46A25" class="text-capitalize px-3 mx-2 my-2" small @click="cancelbtn()"><v-icon dark small>cancel</v-icon> <v-divider vertical class="mx-2"></v-divider>Cancel</v-btn>
                </v-layout>
                </div>
            </v-card>

            <v-card class="mt-4 rounded-0">
            <v-layout class="headercolor">
                <p class="cardfont" style="font-weight:700;margin-bottom:7px!important;margin-top:10px;padding-left:10px;">Search Results</p>
            </v-layout>
        <v-data-table
            :headers="headers"
            :items="GetData"
            hide-default-footer
            dense
        >
            <template v-slot:[`item.employee`]="{ item }">
                <span>{{ item.allData.employee ? "Employee" : "External Resource" }}</span>
            </template>
        </v-data-table>
        
                    <v-layout row justify-end align-center class="mx-2 headercolor" style="margin-left:0px!important;margin-right:0px!important;">
                        <v-text class="mr-1 font-weight-bold body-1" style="color:#3C3D3D; font-size:12px !important;">
                                <span class="" style="color:#E46A25;font-size:12px;">Showing {{fval}}-{{sval}} </span>out of {{totalLen }} records

                        </v-text>
                        <v-divider  class="mx-2" vertical ></v-divider>
                        <v-pagination 
                            class="cust-bnkAcc-Permission"
                            v-model="page"
                            color="#152F38"
                            flat
                            :total-visible="9"
                            @input="nextPageBtn(page)"
                            :length="pageLength"
                        ></v-pagination>
                    </v-layout>
        </v-card>

        
        </div>

        <v-card style="max-height:150px" class="mt-6 mb-4 rounded-0">
            <div class="mx-2 pa-2" style=" display:flex;justify-content:space-between;"  >
            <v-layout style="flex:1" justify-end > 
            <!-- <v-btn dark  tile color="#23B1A9" class="text-capitalize px-3 mx-2 my-2" small @click="saveDetails()"><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider>Save</v-btn> -->
            <!-- <v-btn dark tile color="#23B1A9" class="text-capitalize px-3 mx-2 my-2" small @click="submitDetails()"><v-icon dark small>arrow_forward</v-icon> <v-divider vertical class="mx-2"></v-divider>Submit</v-btn> -->
            <!-- <v-btn  dark tile color="#E46A25" class="text-capitalize px-3 mr-2 ml-2 my-2" small @click=$router.go(-1)><v-icon dark small>cancel</v-icon> <v-divider vertical class="mx-2"></v-divider> Close</v-btn> -->
            </v-layout>
            </div>
        </v-card>
        
    </v-app>
</template>

<script>
  
export default {

    created(){
        this.$store.commit("SAVE_PAGE_HEADER", "Map user to Financial Authority level");

        this.searchAll(this.page)
        // console.log("this.getEditDetails(this.$router.history.current.name) from Mixin===>",this.getEditDetails(this.$router.history.current.name).isEdit)
        // if(this.getEditDetails(this.$router.history.current.name)){
        //     this.disableAll=this.getEditDetails(this.$router.history.current.name).isEdit
            
        // }else{
        //     this.disableAll=false
        // }
    },

    

    data() {
        return {
            Level:'',
            lob:'',
            lobItems:['PA','Health'],
            fval:0,
            sval:0,
            totalLen:'',
            page: 1,
            userID:'',
            pageLength:'',
            employeeID:'',
            employeeName:'',
            agreementType:'',
            typeOfEmployee:'',
            disableAll:true,
           GetData:[
            ],
            headers:[
                
                {text: 'Sr. No.', value: 'SerialNumber',sortable: false,divider:true,class:"hdr-txt-clr-mapUsrToFA hdr-bg-clr srWidth"},
                {text: 'Employee Name', value: 'employeeName',sortable: false,divider:true,class:"hdr-txt-clr-mapUsrToFA hdr-bg-clr"},
                {text: 'User ID/NT ID', value: 'userID',sortable: false,divider:true,class:"hdr-txt-clr-mapUsrToFA hdr-bg-clr userWidth"},
                {text: 'Employee ID', value: 'employeeID',sortable: false,divider:true,class:"hdr-txt-clr-mapUsrToFA hdr-bg-clr"},
                {text: 'Agreement Type', value: 'employee',sortable: false,divider:true,class:"hdr-txt-clr-mapUsrToFA hdr-bg-clr"},
                {text: 'Type Of Employee', value: 'typeOfEmployee',sortable: false,divider:true,class:"hdr-txt-clr-mapUsrToFA hdr-bg-clr"},
                {text: 'Line of Business', value: 'lob',sortable: false,divider:true,class:"hdr-txt-clr-mapUsrToFA hdr-bg-clr"},
                {text: 'Role', value: 'role',sortable: false,divider:true,class:"hdr-txt-clr-mapUsrToFA hdr-bg-clr"},
                {text: 'FA Level', value: 'faLevel',sortable: false,divider:true,class:"hdr-txt-clr-mapUsrToFA hdr-bg-clr"}
            ],
            required(){
                return v=>v && v.length>0 || "This Field is Required"
            }
    }
    },

    methods:{
        // /^[a-zA-Z ]*$/;
        changesOnMew(employee){
            console.log(employee)
        },
        spclCharNot(v){
            if (v.length !== 0) {
                console.log("testing",/^[0-9 ]+$/.test(v))
                if(!/^(\d|\w)+$/.test(v)){
                    return "Special Characters Not Allowed"
                }else{
                    console.log("else none")
                }
            }
        },
        Onlystring(v){
            if (v.length !== 0) {
                console.log("testing",/^[0-9 ]+$/.test(v))
                if(!/^[a-zA-z]+([\s][a-zA-Z]+)*$/.test(v)){
                    return "Only Alphabets are allowed."
                }else{
                    console.log("else none")
                }
            }
        },
        cancelbtn(){
                this.$router.push('/home')

            },

        nextPageBtn(page){
            if(this.pageLength === page){
                this.lastPage = true
                console.log("this is last page",page);
            }else{
                this.lastPage = false
                console.log("this is not last page",page);
            }
            this.searchAll(page)
        },    

        onSearch(){
            console.log("search btn is working",this.page);
             if(!this.$refs.mapuserform.validate()){
               this.showToast("Please fill all fields",this.TOST().WARNING)
                console.log("%c It is a not a valid Form","color:red");
            }else{
                if(this.userid=="" && this.employeeId=="" && this.employeeName==""){
                this.showToast("Please enter search criteria.",this.TOST().WARNING);
                console.log("%c It is a valid Form","color:green");
                }
                this.searchAll(this.page)
            }
        },
        
        searchAll(page){
            let reqData =   "perPage=10&pageNo="+page
            if(this.userID){
                reqData =   reqData +   "&userid="+this.userID
            }
            if(this.employeeID){
                reqData =   reqData +   "&employeeId="+this.employeeID
            }
            if(this.employeeName){
                reqData =   reqData +   "&employeeName="+this.employeeName
            }
            if(this.agreementType){
                reqData =   reqData +   "&agreementType="+this.agreementType
            }
            if(this.typeOfEmployee){
                reqData =   reqData +   "&typeOfEmployee="+this.typeOfEmployee
            }
            this.GET(6,'users/search-users?'+reqData,
                            (res,error) => {
                                try {
                                    
                                     if(!error){
                                    console.log("response from famapping search is",res.data.data.data);
                                    this.pageLength = res.data.data.total_pages;
                                    this.totalLen = res.data.data.total
                                    var temp =  this.page*10;
                                    this.fval = temp-9;

                                    if(this.lastPage){
                                        this.sval = this.totalLen
                                        console.log("value of sval is fo",this.sval);
                                    }else{
                                         if(this.totalLen < 10){
                                            this.sval = this.totalLen
                                            console.log("this are the pageLength for searchTable",this.totalLen);
                                        }else{

                                            this.sval = temp;
                                           console.log("value of sval is",this.sval);
                                        }
                                    }
                                    console.log("this is response for search===>",res.data.data);
                                    let tempArr = res.data.data.data;
                                    console.log("temp arr",tempArr)
                                    this.GetData = tempArr.map((item,index) => {
                                                console.log('inside map',item.assignRole);
                                                var roleData = '';
                                                if(item.assignRole.length != 0){
                                                    if(item.assignRole[0].hasOwnProperty('roleName')){
                                                        roleData = item.assignRole[0].roleName
                                                    }else{
                                                        roleData= '-'
                                                    }  
                                                }else{
                                                    roleData= '-'
                                                }
                                                return{
                                                        SerialNumber: 10*(page)-9+index,
                                                        userID:item.ntidUserId,
                                                        employeeID:item.employeeId,
                                                        employeeName:item.employeeName,
                                                        agreementType:item.agreementType,
                                                        typeOfEmployee:item.typeOfEmployee,
                                                        faLevel:item.faLevel,
                                                        lob:item.selectedLOB,
                                                        role:roleData,
                                                        allData:item
                                                    }
                                                });
                                }else{
                                      console.log("their was error in search==>",error.response.data);
                                    this.showToast("Something went wrong",this.TOST().WARNING)
                                  
                                }

                                } catch (error) {
                                      console.log("their was error in catch==>",error);
                                      this.showToast("Something went wrong",this.TOST().WARNING)
                                }
                               
                            })
        }
    }

}
</script>

<style>

.cust-rmvpd-psins3 .v-text-field.v-text-field--enclosed>.v-input__control>.v-input__slot {
    padding-right: 0px !important;
}

.cust_pr2 .v-text-field .v-input__slot,
.v-text-field--outline .v-input__slot {
    min-height: 42px !important;
    display: flex !important;
    align-items: center !important;
    /* border:1px red solid; */
}

.cstm-clr-pr3{
    color:#646666 !important;
    margin-bottom: 5px !important;
    font-size: 13px !important;
}

.cust_pd1 .v-text-field .v-input__slot,
.v-text-field--outline .v-input__slot {
    min-height: 42px !important;
    display: flex !important;
    align-items: center !important;
    /* border:1px red solid; */
}

.cust-rmvpd-pr1 .v-text-field.v-text-field--enclosed>.v-input__control>.v-input__slot {
    padding: 0px !important;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
    font-size: 0.84rem !important;
}

.hdr-txt-clr-mapUsrToFA{
    color: white !important;
    font-weight: bold;
    /* padding:0px!important;
    padding: 10px !important;
    padding-left: 14px !important; */
}

.hdr-bg-clr{
  background-color: #23B1A9;
}
.headercolor{
    background-color: #eee;
}
.cardfont{
    font-size: 0.84rem !important;
    padding-right: 2px;
}
.cust-bnkAcc-pgn .v-pagination__navigation{
        box-shadow:unset !important;
        border-radius: unset !important;
        background-color:#FFFFFF !important;
        border:1px solid #DAE1E6 !important
    }
    .cust-bnkAcc-pgn .v-pagination__navigation--disabled{
        background-color:#DAE1E6 !important;
    }
    .cust-bnkAcc-pgn .v-pagination__item{
        box-shadow:unset !important;
        min-width:2rem !important;
        height:2rem !important;
        border-radius: unset !important;
        border:1px solid #DAE1E6 !important
    }
    .srWidth{
    width: 70px;
    }
    .userWidth{
    width: 250px;
    }

</style>