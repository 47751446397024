<template>

<div>
    <!-- <ProviderDetails/> -->
    <v-form v-model="icuvalid" ref="otForm">
    <v-card tile flat>
    <div class="px-4 pt-4">
                    <v-flex xs3>
                        <v-layout column class="cust_pln_Ot mx-2 mb-4">
                            <p class="font-weight-bold cstm-clr-pln-Ot">
                                Discount Percentage
                            </p>
                            <v-text-field
                                solo
                                label="Enter..."
                                v-model.trim="dscntPercntg"
                                class="rounded-0"
                                :maxlength="max05"
                                :disabled="fromview"
                                :rules="[Numeric2]"
                            >
                            </v-text-field>
                        </v-layout>
                    </v-flex>
               </div>
               </v-card>

     <!-- <v-card class="mt-3 pa-3" color="#F7F7F7">
        <label class="black--text ml-4">Investigation Charges</label></v-card> -->
        <div class="px-4 py-3" style="background-color:#F7F7F7">
                    <p class="font-weight-bold cstm-clr-pln-Ot pa-0 ma-0">Investigation Charges</p>
                </div>
                <v-divider></v-divider>
        <v-card tile>
            <div>
        <!-- <v-data-table              
                    :headers="headers"
                    :items="statusData"
                    :page.sync="page"
                    :items-per-page="statusItemsPerPage"
                    hide-default-footer
                    dense
                    @page-count="pageCount = $event"
                    height="195px"
                   
                ></v-data-table> -->
                <tableis v-if="shwDtaTbl===true"
                            :tabledata="{
                                tabledata:addCategory,
                                headersdata:headersNew,
                                dd:medConsumablesLvl4,
                                tariffshw:false,
                                roomconfig:temproomtype,
                                req:true,
                                del_endpoint:'Investigation Charges',
                                url:'tariff/get-investigations-charges'
                            }" 
                            ref="InvestigationCharges" 
                            @save="saveMedConsmechrgs" 
                ></tableis>
<!-- 
                <div class="text-center">
    <v-pagination color="#152F38" class="mt-3 float-right"
      v-model="page"
      :length="4"
      prev-icon="mdi-arrow-left"
      next-icon="mdi-arrow-right"
      border-radius="0px"
      square
    ></v-pagination>
  </div> -->
    </div>
     </v-card>

     <v-card color="#F7F7F7" class="mt-5 pa-4 overflow-y-auto" v-if="!fromview">
         <v-row class="mt-1 mr-1 float-right">
                <v-btn @click="save_medConsme_ctgry()" tile dark color="#152F38" class="text-capitalize mr-6" small>
                     <v-icon color="" small >mdi-plus-circle</v-icon>
                     <v-divider vertical class="ml-2 mr-2"></v-divider>Add Category
                 </v-btn>
                 <v-btn @click="save_ot_confg()" tile dark color="#23B1A9" class="text-capitalize ml-20" small>
                     <v-icon color="" small >check_circle</v-icon>
                     <v-divider vertical class="ml-2 mr-2"></v-divider>Save
                 </v-btn></v-row>
     </v-card>
</v-form>
</div>
  
</template>

<script>
import ProviderDetails from '../../component/Provider-Name_ID';
import tableis from '../CreateNewTariff/data_table';

export default {
    components: {ProviderDetails, tableis},
    data(){
        return{
            // page: 1,
            roomNursingvalid:false,
            dscntPercntg:'',
            max03: 3,
            max05:5,
            max10:10,
            max20:20,
            max30:30,
            max40:40,
            max50:50,
            required(){
                return v=>v && v.length>0 || "This Field is Required"
            },
            minLength(minLength){
                return v=>v && v.length>=minLength || `Must be atleast ${minLength} Characters`
            },
            maxLength(maxLength){
                return v=>v && v.length<=maxLength || `Must be less than ${maxLength} Characters`
            },
            spclChar(){
                return v=>/^[A-Za-z0-9 ]+$/.test(v) || `Special Characters Not Allowed`
            },
            alphaNumeric(){
                return v=>/^[0-9 ]+$/.test(v) || `Only Numbers are allowed.`
            },
            numberRule: v  => {
                if (!v.trim()) return true;
                if (!isNaN(parseFloat(v)) && v >0 && v <=10) return true;
                return 'Values between 1 and 10 are accepted';
            },
            decimalRule(){
                return v=>/^\d+(\.\d{1,2})?$/.test(v) || `Numeric upto two decimals.`
            },
            headers:[
                {text: 'Sr. No.',value:'status',sortable: false,divider:true}, 
                {text: 'Service Category(Level 4)',value:'status',sortable: false,divider:true},
                {text: 'Hospital Service(Level 5)', value: 'Date',sortable: false,divider:true},
                {text: 'Room Type 1', value: 'Time',sortable: false,divider:true},
                {text: 'Room Type 2', value: 'userName',sortable: false,divider:true},
                {text: 'Room Type 3',value: 'remarks',sortable: false,divider:true},  
                {text: 'Room Type 4',value: 'remarks',sortable: false,divider:true},
                {text: 'Inclusions',value: 'remarks',sortable: false,divider:true}, 
                {text: 'Exclusions',value: 'remarks',sortable: false,divider:true},   
            ],
            headersNew:[ 
                {text: 'scl4', displayval:'Service Category(Level 4)'},
                {text: 'hsl5',displayval:'Hospital Service(Level 5)'},
                // {text: 'csdc', displayval: 'csdc'},
                {text: 'Inclusions',displayval:'Inclusions'}, 
                {text: 'Exclusions',displayval:'Exclusions'},   
                {text: 'del',displayval:'Action'},
            ],
            shwDtaTbl: false,
            addCategory:[],
            medConsumablesLvl4: [],
            addRmchrgs:[],
            noOfRooms:[],
            fromview: false,

        }
        
    },
   
    created(){
        this.$store.commit("SAVE_PAGE_HEADER", "Investigations Charges - Tariff");
        // console.log(this.$store.state.createtarrifDetails,"this.$store.state.createtarrifDetails");
        this.getmedConsmLvl4()  
        this.fromview = this.$store.state.tariff_view;    
    },

    methods:{

        getmedConsmLvl4(){       
            this.medConsumablesLvl4 = [];
            this.getmedConsumeConfig(this.$store.state.createtarrifDetails._id)
            return
            this.GET(3,'tariff/get-investigations-charges?perPage=50&pageNo=1',(res,error)=>{
                if(!error){
                    if(res.data.statusCode === 200){
                        
                        console.log(this.medConsumablesLvl4);
                        // this.shwDtaTbl = true;
                        this.medConsumablesLvl4 = res.data.data.data;
                        this.getmedConsumeConfig(this.$store.state.createtarrifDetails._id)
                    }else{
                        this.showToast(res.data.msg ,this.TOST().ERROR); 
                    }
                }else{
                    this.showToast(error.response.data.msg, this.TOST().WARNING);
                }
            })
        },

        saveMedConsmechrgs(data){
            if(data.dta.length ===0){
                this.showToast("Please add atleast one category",this.TOST().WARNING);
                return
            }
            // console.log(this.tempmedConsumeChrgs ,"this.tempmedConsumeChrgs");
            // console.log(data, "saveNrsngchrgs commponent emitted ");
            if(!this.$refs.otForm.validate()){
                this.$refs.otForm.validate()
                 this.showToast("Data should be entered for all required fields.",this.TOST().WARNING);
                return
            } 
            console.log(data,"incmgDta")

            let investigationsCharges= [];
            data.dta.map((e)=>{
         
                console.log(e,"inside incoming d")

                var tempRoomType=[]
                 for (var i = 0; i < e.noOfRooms.length; i++) {
                  tempRoomType.push({
                      roomType:e.noOfRooms[i].text,
                      charges:e.noOfRooms[i].inputValue
                  })
                }

                let tempdata={                 
                    // levelId:e.scl4._id,
                    // level1:e.scl4.level1,
                    // level2:e.scl4.level2,
                    // level3:e.scl4.level3,
                    serviceCategory:e.scl4,
                    hospitalService:e.hsl5,
                    // hospitalervice:"General",
                    // inclusion: e.Inclusions,
                    exclusion:e.Exclusions,
                    // roomType:e.noOfRooms
                    roomTypes:tempRoomType 
                }
                if(e.hasOwnProperty('_id')){
                    if(e._id.length!=0){
                         tempdata._id=e._id
                    }else{
                        console.log("Id not found")
                    }
                }
                console.log("tempdata with Id/not",tempdata)
            
                investigationsCharges.push(tempdata)

                // investigationsCharges.push({
                //     levelId:e.scl4._id,
                //     level1:e.scl4.level1,
                //     level2:e.scl4.level2,
                //     level3:e.scl4.level3,
                //     serviceCategory:e.scl4.level4,
                //     hospitalService:e.hsl5,
                //     // hospitalervice:"General",
                //     inclusion: e.Inclusions,
                //     exclusion:e.Exclusions,
                //     // roomType:e.noOfRooms
                //     roomTypes:tempRoomType 
                // })
            })
            let formData={
                tariffId:this.$store.state.createtarrifDetails._id,
                discount:this.dscntPercntg,
                investigationsCharges:investigationsCharges,
            }
             console.log(formData,"formData")

             this.PUT(2,'tariff/update-investigations-charges',formData ,
                ( res, error) => {
                 if(!error){
                    console.log(res.data);
                    this.getmedConsumeConfig(this.$store.state.createtarrifDetails._id)
                    this.showToast(res.data.msg ,this.TOST().SUCCESS);
                    this.$emit('forenable', {  emitType:"InvestigationCharges", InvestigationCharges: true }); 
                }else{
                    console.log("when status is not 200", error.response);
                    this.showToast(error.response.data.msg ,this.TOST().ERROR);
                }
    
            });
        },

         save_medConsme_ctgry(){
            this.addCategory.push({
                scl4:'',
                hsl5:'',
                
                // noOfRooms:this.noOfRooms,
                inclusions:'',
                exclusions:'',
                del: ''
            })
       },

       getmedConsumeConfig(id){
    

        this.shwDtaTbl=false
        this.noOfRooms=[];
        //    this.headersNew = [];
        this.headersNew = [ 
            {text: 'scl4', displayval:'Service Category(Level 4)'},
            {text: 'hsl5',displayval:'Hospital Service (Level 5)'},
            // {text: 'Tariff' },
            {text: 'Inclusions',displayval:'Inclusions'}, 
            {text: 'Exclusions',displayval:'Exclusions'},  
            {text: 'del',displayval:'Action'}, 
        ]

           var compnt_render=new Promise((resolve,reject)=>{

                this.getTariffById(id,tempData=>{
                    console.log(tempData,"tempdata")
                    if(tempData.hasOwnProperty('roomConfiguration')){
                        let roomTyp=tempData.roomConfiguration.roomConfiguration.hasOwnProperty('roomTypes')? this.chkplndataarry(tempData.roomConfiguration.roomConfiguration.roomTypes) : ''
                        this.temproomtype = roomTyp
                        
                        console.log("roomTyp  ", roomTyp)
                        // }
                        for(let i=0;i<roomTyp.length;i++){
                            this.noOfRooms.push(
                                {
                                    displayval:roomTyp[i].roomType,
                                    text:{ [i]:roomTyp[i].roomType}, 
                                    value:roomTyp[i].roomType,
                                    inputValue:''
                                }
                            )
                        }
                        console.log(this.noOfRooms,"this.noOfRooms")
                        this.headersNew.splice(2, 0, ...this.noOfRooms);
                        console.log( this.headersNew," this.headersNew")

                        if(tempData.hasOwnProperty('InvestigationCharges')&& tempData.InvestigationCharges!=null){
                            this.dscntPercntg=tempData.InvestigationCharges.hasOwnProperty('discount')? this.chkplndata(tempData.InvestigationCharges.discount) : ''.hasOwnProperty('discount')? this.chkplndata(tempData.InvestigationCharges.discount) : ''
                            if(tempData.InvestigationCharges.investigationsCharges.length!=0){
                                this.addCategory=[]
                                // this.noOfRooms=[]
                                  var roomholder = {}
                                  var roomtypes_data = []
                                for (let i=0;i<tempData.InvestigationCharges.investigationsCharges.length;i++){
                                    console.log(i,"inside temproomType ")
                                    // var tempnoOfRooms=[]
                                    // for(let j in tempData.ICUCharges.icuCharges[i].roomType){
                                    //     console.log(j,"inside j ")
                                        
                                    //     tempnoOfRooms.push(
                                    //         {   displayval:j,
                                    //             text:j, 
                                    //             value:j,
                                    //             inputValue:tempData.ICUCharges.icuCharges[i].roomType[j]
                                    //         }
                                    //     )
                                    // }
                                    // let tempNScl4 = this.medConsumablesLvl4.filter((e)=>{
                                    //     if(e._id===tempData.InvestigationCharges.investigationsCharges[i].levelId){
                                    //         return e
                                    //     }
                                    // })

                                  
                                    roomtypes_data = []
                                   tempData.InvestigationCharges.investigationsCharges[i].roomTypes.map((e, index)=>{
                                            roomholder = {}
                                            roomholder[index] = e.charges 

                                         roomtypes_data.push(roomholder)
                                        console.log(roomholder,"newonjis ", roomtypes_data);
                                       
                                    })

                                    console.log("roomtypes_data ", ...roomtypes_data);
                                    var finalrooms =  Object.assign({},...roomtypes_data)
                                    this.addCategory.push(
                                        {   
                                            _id:tempData.InvestigationCharges.investigationsCharges[i]._id, 
                                            scl4:tempData.InvestigationCharges.investigationsCharges[i].serviceCategory, 
                                            hsl5:tempData.InvestigationCharges.investigationsCharges[i].hospitalService,
                                            Inclusions:tempData.InvestigationCharges.investigationsCharges[i].inclusion,
                                            Exclusions:tempData.InvestigationCharges.investigationsCharges[i].exclusion,
                                            tarrif:tempData.InvestigationCharges.investigationsCharges[i].tariff,
                                            ...finalrooms
                                            
                                            // ...tempData.ICUCharges.icuCharges[i].roomTypes,

                                        }
                                        
                                    )
                                }
                                // console.log(tempnoOfRooms,"tempnoOfRooms")
                                console.log(this.addCategory,"this.addCategory")
                            }
                        }
                        resolve(true)
                    }

                })

           })
           console.log("When did it ran")
            compnt_render.then(res=>{
                console.log(res,"Resolve Promise")
                this.shwDtaTbl=true
            })
           
       },

       chkplndata(data){
            console.log("chkplndata",data); 
            try{
                if (data ==="" || data === "undefined" || data === null) {
                    return ""
                } else {
                    return data;
                }

             }catch(err) {
                console.log(err)
            }
            
        },

        
        chkplndataarry(data){
            console.log("chkplndataarry",data); 
            try{
                if (data ==="" || data === "undefined" || data === null) {
                    return []
                } else {
                    return data;
                }

             }catch(err) {
                console.log(err)
            }
            
        },

        Numeric2(v){
            if (v.length !== 0) {
                console.log("testing",/^[0-9 ]+$/.test(v))
                if(!/^(100(?:\.00?)?|\d?\d(?:\.\d\d?)?)$/.test(v)){
                    return "Numeric between 0 - 100 and upto two decimals are allowed."
                }else{
                console.log("else none")  
                }
            }
        },

        save_ot_confg(){
            this.$refs.InvestigationCharges.save_click();
        }
    }
    
}
</script>

<style>

</style>