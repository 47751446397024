<template>
    <div>
    <ClaimDetailsVue :claimData="claimDetails"></ClaimDetailsVue>
    <div class="ma-5">
        <div class="aligncenter" v-if="showAddButton">
            <p class="mt-0 mb-0"></p>
            <!-- <v-btn color="#1EBBD9" dark class="btnstyle mr-3 ml-10" @click="addNewPetition()">
                <v-icon class="mr-2 iconsize font-weight-bold">mdi-plus-box-outline</v-icon>
                    <div class="vertical_line"></div>Add New Petition
            </v-btn> -->
        </div>
        <v-row v-if="searchLitigationData.length > 0">
            <v-col>
                <p class="headingtext font-weight-bold mt-2">Litigation Details <span class="orangetext">({{totalListCount}})</span></p>
            </v-col>
            <!-- <v-col align="right">
                <v-btn color="#1EBBD9" dark class="btnstyle" @click="addNewPetition()">
                    <v-icon class="mr-2 iconsize font-weight-bold">mdi-plus-box-outline</v-icon>
                    <div class="vertical_line"></div>Add New Petition
                </v-btn>
            </v-col> -->
        </v-row>
        <template>
            <v-data-table
                :headers="litigationHeaders" 
                no-data-text="No Records for Petition" 
                fixed-header 
                :items="searchLitigationData" 
                hide-default-footer 
                :single-expand="singleExpand"
                :expanded.sync="expanded"
                class="vDataTableStyling tableborder my-3">
                <template  v-slot:[`item.action`]="{ item }">
                    <div class="text-center">
                        <v-menu
                            bottom
                            offset-y
                            rounded="0"
                            nudge-left="0">
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon class="mr-2 menusize" v-bind="attrs" v-on="on">mdi-dots-vertical</v-icon>
                            </template>

                            <v-list>
                                <v-list-item
                                    style="min-height: 28px !important" 
                                    v-for="(item1, index) in menuItems"
                                    :key="index"
                                    dense
                                    :color="item1.color"
                                    class="ma-0 pa-0"
                                    @click="modify(item, index)">
                                    <v-icon small class="pa-2 pt-2 iconsize" v-text="item1.icon" :color="item1.color"></v-icon>
                                    <v-divider vertical class="mx-1"></v-divider>
                                    <v-list-item-title class="pa-1 pr-3 ma-0 mr-0" v-text="item1.title" :color="item1.color" :class="item1.name" style="font-size: 12px !important"></v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </div>
                </template>
                <template  v-slot:[`item.showPetitionDetails`]="{ item, index }">
                    <a class="textcolor font-weight-bold" @click="viewPetitiondetails(item, index)">{{item.tableShow ? 'CLOSE' : 'VIEW'}}</a>
                </template>
                <template v-slot:expanded-item="{ headers, item}" :scope="props">
                    <td class="px-1" :colspan="headers.length" v-if="item.tableShow">
                        <v-col class="pa-2">
                        <v-data-table
                            :headers="petionDetailsHeaders"
                            :items="item.petitionDetails"
                            hide-default-footer
                            no-data-text="No Records for Petion Details"
                            class="padding-0 bluish-table"
                            disable-pagination
                            >
                            <!-- <template v-slot:top>
                                <v-row class="py-2">
                                    <v-col class="page-title-small" align="right">
                                        <a class="textcolor font-weight-bold" @click="closeExpanded()">CLOSE</a>
                                    </v-col>
                                </v-row>
                            </template> -->
                            <template v-slot:[`item.dateOfFilingOfPetition`]="{ item }">
                                {{formatDate(item.dateOfFilingOfPetition)}}
                            </template>
                            <template v-slot:[`item.dateOfHearing`]="{ item }">
                                {{formatDate(item.dateOfHearing)}}
                            </template>
                            <template v-slot:[`item.plaintiff`]="{ item }">
                                <span class="stopbreaking">{{ item.plaintiff || '' }}</span>
                            </template>
                            <template v-slot:[`item.plaintiffAttorney`]="{ item }">
                                <span class="stopbreaking">{{ item.plaintiffAttorney || '' }}</span>
                            </template>
                            <template v-slot:[`item.defendantName`]="{ item }">
                                <span class="stopbreaking">{{ item.defendantName || '' }}</span>
                            </template>
                            <template v-slot:[`item.defendantAttorney`]="{ item }">
                                <span class="stopbreaking">{{ item.defendantAttorney || '' }}</span>
                            </template>
                        </v-data-table>
                        </v-col>
                    </td>
                </template>
            </v-data-table>
        </template>
        <!-- pagination -->
        <div class="text-center graybackground pdct-stUp-bnkAcc-pgn mt-3 mx-4" v-if="searchLitigationData.length > 0">
            <v-layout justify-end>
                    <div style="margin-top: 15px">
                    <span class="orangetext paginationText">
                        Showing {{ totalListCount ? pageStartIndex + 1 : 0 }} - {{ pageEndIndex }}
                    </span>
                    <span class=" mr-2 paginationText"> out of {{ totalListCount }} records </span>
                    </div>
                    <!-- @input="goNextPagePagination" -->
                    <v-pagination
                        v-model="pageNumber"
                        :length="totalPages"
                        :total-visible="pageRowCount" 
                        @input="searchLitigation"
                        color="#152F38"
                        class="navpagenumber pt-1"
                    ></v-pagination>
            </v-layout>
            <br />
        </div>
        <v-row class="mt-2">
            <v-col>
                <v-btn outlined color="#D1121B" class="btnstyle ml-3 mr-3" @click="cancel()">
                    <v-icon class="mr-2 iconsize">mdi-close-box-outline</v-icon>
                    <div class="vertical_line"></div>Cancel
                </v-btn>
            </v-col>
        </v-row>

        <!-- Audit Trail Dialog -->

        <v-dialog v-model="audit_dialog" width="800">
            <v-card>
                <v-card-title class="white--text" style="background-color: #23b1a9">
                    Audit Trail
                    <v-spacer></v-spacer>
                    <v-icon @click="audit_dialog=!audit_dialog" class="white--text">mdi-window-close</v-icon>
                </v-card-title>
                <v-layout class="flex-column px-4 pt-4 form-group-container">
                    <v-data-table :headers="audit_Header" hide-default-footer :items="audit_Data" class="vDataTableStyling w-100">
                        <template v-slot:[`item.srno`]="{ index }">
                            {{ 1 + index }}
                        </template>
                        <template v-slot:[`item.action`]="{ item }">
                            <v-btn v-if="item.action === 'Modified'" color="#FFFFFF" class="text-capitalize tablebtn"
                                @click="seeModify(item)">
                                {{ item.action }}
                                <v-icon class="tableicons">mdi-arrow-right-circle</v-icon>
                            </v-btn>
                            {{ (item.action !== "Rejected" && item.action !== "Modified") ? item.action : null }}
                        </template>
                        <template v-slot:[`item.createdAt`]="{ item }">
                            {{formatDateTime(item.createdAt)}}
                        </template>
                    </v-data-table>
                </v-layout>
                <v-layout justify-end align-center class="pb-2 px-0 mr-4">
                    <div>
                        <span style="color:orange">Showing {{ pageStartIndex1 }}-{{ pageEndIndex1 }}</span>
                        out of {{ totalListCount1 }} records
                    </div>
                    <div>
                        <v-pagination class="small-pagination" v-model="pageNumber1" :length="pageCount1" :total-visible="5"
                            @input="getAuditData" color="#152F38"></v-pagination>
                    </div>
                </v-layout>
            </v-card>
        
        </v-dialog>
        <!-- Modify dialogue -->
        <v-dialog v-model="seeModifyDialog" width="600">
            <v-card>
                <v-card-title class="white--text" style="background-color: #23b1a9">
                    <v-layout justify-space-between>
                        <div>
                            Modified Field Details
                        </div>
                    </v-layout>
                    <v-btn color="transparent" small :ripple="false" depressed @click="seeModifyDialog = false">
                        <v-icon color="white">mdi-close </v-icon>
                    </v-btn>
                </v-card-title>
                <div class="scrollable-table">
                    <v-data-table disable-pagination :headers="modifyHeaders" :items="modifyItems" hide-default-footer
                        class="pa-4">
                        <template v-slot:[`item.srNo`]="{ index }">
                            {{ 1 + index }}
                        </template>
                    </v-data-table>
                </div>
            </v-card>
        </v-dialog>
        <div>
            <Notes :openNotesPopup="openNotesPopup" @closeNotes="openNotesPopup=false" :claimNumber="claimNumber" :featureNumber="featureNumber" :lobType="lobType"/>
        </div>
        </div>
    </div>
</template>

<script>
import Notes from '../../component/Notes.vue'
import moment from 'moment';
import ClaimDetailsVue from "../../component/ClaimDetails.vue";
export default {
    components: {
        ClaimDetailsVue,
        Notes
    },
    data() {
        return {
            openNotesPopup:false,
            // Litigation Table 
            showAddButton: false,
            searchLitigationData: [],
            litigationHeaders: [
                {text: 'Action',align: 'center',value: 'action',sortable: false},
                {text: 'LOB',align: 'center',value: 'lobType',sortable: false},
                {text: 'Claim Number.',align: 'center',value: 'claimNumber',sortable: false},
                {text: 'Feature Number',align: 'center',value: 'featureNumber',sortable: false},
                {text: 'Execution Closure',align: 'center',value: 'executionClosure',sortable: false},
                {text: 'Litigation Status',align: 'center',value: 'tableLitigationStatus',sortable: false},
                {text: 'Petition Details',align: 'center',value: 'showPetitionDetails',sortable: false},
            ],
            petionDetailsHeaders: [
                {text: 'Litigation Type',align: 'center',value: 'litigationType',sortable: false, width: '9%'},
                {text: 'Case No.',align: 'center',value: 'caseNumber',sortable: false, width: '9%'},
                {text: 'Case Year',align: 'center',value: 'caseYear',sortable: false, width: '9%'},
                {text: 'Court Name',align: 'center',value: 'courtName',sortable: false, width: '15%'},
                // {text: 'Court Code',align: 'center',value: 'courtCode',sortable: false, width: '15%'},
                {text: 'Complainant Name',align: 'center',value: 'plaintiff',sortable: false, width: '15%'},
                {text: 'Complainant Attorney Name',align: 'center',value: 'plaintiffAttorney',sortable: false, width: '15%'},
                {text: 'Defendant',align: 'center',value: 'defendantName',sortable: false, width: '15%'},
                {text: 'TATA AIG Attorney Name',align: 'center',value: 'defendantAttorney',sortable: false, width: '15%'},
                {text: 'Date Of Filing Of Petition',align: 'center',value: 'dateOfFilingOfPetition',sortable: false, width: '7%'},
                {text: 'Date Of Hearing',align: 'center',value: 'dateOfHearing',sortable: false, width: '7%'},
                {text: 'Petition Status',align: 'center',value: 'litigationStatus',sortable: false},
            ],
            // For dot icon
            menuItems: [
                { title: 'VIEW', icon: 'mdi-eye', color:"color:#000000", name: 'view' },
                { title: 'MODIFY', icon: 'mdi-pencil', color:"color:#1EBBD9", name: 'modify' },
                { title: 'AUDIT TRAIL', icon: 'mdi-chart-timeline-variant', color:"color:#e46a25", name:'audit'},
                { title: 'Notes', icon: 'mdi-note-multiple', name:'notes' },
            ],
            // table related
            pageNumber: 1,
            pageStartIndex: 0,
            pageRowCount: 10,
            totalListCount: 0,
            totalPages:null,

            claimNumber: '',
            featureNumber: '',
            lobType: '',
            claimDetails: {},
            expanded: [],
            singleExpand: false,
            // audit trail
            audit_Data:[],
            audit_Header:[{text:"Sr. No.",value:"srno",sortable:false,align:"center"},
            {text:"Master ID",value:"masterId",sortable:false,align:"center"},
            {text:"Action Taken",value:"action",sortable:false,align:"center"},
            {text:"User ID",value:"userId",sortable:false,align:"center"},
            {text:"User Name",value:"userName",sortable:false,align:"center"},
            {text:"Time Stamp",value:"createdAt",sortable:false,align:"center"}],
            pageNumber1: 1,
            pageStartIndex1: 0,
            pageRowCount1: 10,
            totalListCount1: 0,
            totalPages1:null,
            pageCount1: 0,
            audit_dialog: false,
            seeModifyDialog: false,
            modifyItems: [],
            modifyHeaders: [
            {
              text: "Sr. No.",
              value: "srNo",
              align: "center",
              sortable: false,
            },
            {
              text: "Field Name",
              value: "fieldName",
              align: "center",
              sortable: false,
            },
            {
              text: "Old Value",
              value: "oldValue",
              align: "center",
              sortable: false,
            },
            {
              text: "New Value",
              value: "newValue",
              align: "center",
              sortable: false,
            },
          ],
        }
    },

    computed: {
        pageEndIndex() {
            return this.pageStartIndex + this.pageRowCount <= this.totalListCount? this.pageStartIndex + this.pageRowCount: this.totalListCount;           
        },
        pageEndIndex1() {
            return this.pageStartIndex1 + this.pageRowCount1 <= this.totalListCount1? this.pageStartIndex1 + this.pageRowCount1: this.totalListCount1;           
        },
    },

    created() {
        this.$store.commit("SAVE_PAGE_HEADER", "Litigation");
        if(Object.keys(this.$route.params).length != 0) {
            this.claimDetails = this.$route.params.claimDetails;
            this.claimNumber = this.claimDetails.claimNumber;
            this.featureNumber = this.claimDetails.featureNumber;
            this.lobType = this.claimDetails.lobType;
            this.searchLitigation();
        } else if(Object.keys(this.$store.getters.fetchDataFromOtherLOB) != 0) {
            this.claimDetails = this.$store.getters.fetchDataFromOtherLOB;
            this.claimNumber = this.claimDetails.claimNumber;
            this.featureNumber = this.claimDetails.featureNumber;
            this.lobType = this.claimDetails.lobType;
            this.searchLitigation();
        }
    },

    methods: {
        formatDate(date) {
            return date == null ? "" : moment(date).format('DD/MM/YYYY');
        },

        searchLitigation() {
            let reqData = `claimNumber=${this.claimNumber}&featureNumber=${this.featureNumber}`;
            this.showProgress();
            this.GETM("searchLitigation", reqData, (res) => {
                this.hideProgress();
                if(res.data.statusCode === 200) {
                    this.searchLitigationData = res.data.data.data;
                    this.searchLitigationData.forEach(obj=>{
                        if(obj.petitionDetails.length >=2){
                            if(obj.petitionDetails[0].litigationStatus=='CLOSED' && obj.petitionDetails[1].litigationStatus=='CLOSED'){
                                obj.tableLitigationStatus='CLOSED'
                            }else{
                                obj.tableLitigationStatus='OPEN'
                            }
                        }else{
                            obj.tableLitigationStatus=obj.petitionDetails[0].litigationStatus
                        }
                    })
                    this.showAddButton = this.searchLitigationData.length == 0;
                    this.pageRowCount = 10;
                    this.totalListCount = res.data.data.total;
                    this.totalPages = Math.ceil(res.data.data.total / this.pageRowCount);
                    this.pageStartIndex = this.pageNumber == 1 ? 0 : (this.pageNumber - 1) * this.pageRowCount;
                } else {
                    this.pageRowCount = 0;
                    this.totalListCount = 0;
                    this.totalPages = 0;
                    this.pageStartIndex =0;
                    this.showAlert(res.data.msg);
                }
            }, (error) => {
                console.log(error);
                this.hideProgress();
            }, true)
        },

        addNewPetition() {
            this.$router.push({
                name: 'createLitigation',
                params: {
                    claimDetails: {
                        lobType: this.claimDetails.lobType,
                        claimNumber: this.claimDetails.claimNumber,
                        featureNumber: this.claimDetails.featureNumber,
                        insuredName: this.claimDetails.insuredName,
                        lossDate: this.claimDetails.lossDate,
                        policynumber: this.claimDetails.policynumber,
                        claimantName: this.claimDetails.claimantName,
                        claimReserveAmount: this.claimDetails.claimReserveAmount,
                        TAT: this.claimDetails.TAT,
                    }
                }
            })
        },

        modify(item, index) {
            if(index === 0) {
                let data = {
                    viewOnly: true,
                    data: item,
                }
                this.$store.commit('SET_CURRENT_PAGE_DATA', data);
                // window.sessionStorage.setItem('SET_CURRENT_PAGE_DATA', JSON.stringify(data));
                this.$router.push({
                    name: 'createLitigation',
                    params: {
                        claimDetails: item,
                        viewOnly: true
                    }
                })
            }
            if(index === 1) {
                let data = {
                    viewOnly: false,
                    data: item,
                }
                this.$store.commit('SET_CURRENT_PAGE_DATA', data);
                // window.sessionStorage.setItem('SET_CURRENT_PAGE_DATA', JSON.stringify(data));
                this.$router.push({
                    name: 'createLitigation',
                    params: {
                        claimDetails: item,
                        viewOnly: false
                    }
                })
            }
            if(index === 2){
                this.audit_dialog=true
                this.getAuditData(1,item)
            }
            if(index === 3){
                this.openNotesPopup = true
            }
        },
        
        getAuditData(newPageNum,item){
        let reqData = "masterId="+item.litigationId+ "&perPage=" + this.itemsPerPage + "&pageNo=" + newPageNum + '&masterType=litigation';
        //"&masterId=" + this.auditTrailItem.uid + 
        this.GETM('getAudits',reqData,(res)=>{
            this.audit_Data=res.data.data.data
            this.totalListCount1 = res.data.data.total;
            this.pageCount1 = res.data.data.total_pages;
            this.pageStartIndex1 = newPageNum == 1 ? 1 : (newPageNum - 1) * this.itemsPerPage + 1;
        })
        },

        seeModify(item) {
            this.seeModifyDialog = true;
            this.modifyItems = item.modification
        },
        
        formatDateTime(dateTime) {
            return dateTime ? moment(dateTime).format("DD-MM-YYYY LT") : "";
        },

        cancel() {
            this.showConfirm("Confirmation", `Are you sure you want to ${this.viewOnly ? 'leave' : 'cancel'}?`, "Yes", "No", () => {
                this.$router.push({
                    name: 'searchLitigation',
                    params: {
                        claimDetails: {
                            claimNumber: this.claimDetails.claimNumber,
                            featureNumber: this.claimDetails.featureNumber,
                        }
                    }
                });
            }, () => {})
        },

        viewPetitiondetails(value, index) {
            console.log("value", value, index);
            this.expanded = []
            this.expanded = value === this.expanded[index] ? [] : [value];
            for(let i in this.searchLitigationData){
                if(i != index){
                    this.searchLitigationData[i].tableShow = false;
                }
            }
            this.searchLitigationData[index].tableShow = !this.searchLitigationData[index].tableShow
        },

        // closeExpanded() {
        //     this.expanded = []
        // },

        //audits
            openAuditTrail() {              
                this.auditDialog = true;
                this.getAuditDetails()
            },

            getAuditDetails(pagenum){
               this.pageNumber1 = pagenum ? pagenum : 1
                let request = 'masterId='+ this.litigationId + "&masterType=litigation&perPage="+ this.pageRowCount1 + "&pageNo=" + this.pageNumber1
                this.GET("getAuditTrailData", request, (res) => {
                    if (res.data.statusCode === 200 && res.data.data && res.data.data.data && res.data.data.data.length !== 0) {
                        this.auditsData = res.data.data.data
                        this.pageRowCount1 = 10;
                        this.totalListCount1 = res.data.data.total;
                        this.totalPages1 = Math.ceil(res.data.data.total / this.pageRowCount1);
                        this.pageStartIndex1 = this.pageNumber1 == 1 ? 0 : (this.pageNumber1 - 1) * this.pageRowCount1;
                    }
                        else{
                        this.hideProgress(); 
                        this.auditsData = [];
                        this.pageRowCount1 = 0;
                        this.totalListCount1 = 0;
                        this.totalPages1 = 0;
                        this.pageStartIndex1 = 0;
                        this.showAlert(res.data.msg);
                    }
                },(error) => {
                    this.hideProgress();
                    if (error.response.data.msg) {
                        this.showAlert(error.response.data.msg);
                    } else if (error.response.data.message.msg) {
                        this.showAlert(error.response.data.message.msg);
                    } else if (error.response.data.message) {
                        this.showAlert(error.response.data.message);
                    } else {
                        this.showAlert("Something went wrong");
                    }
                }, false, '', {claimnumber:this.storeValues.claimNumber, featurenumber:this.storeValues.featureNumber, policynumber:this.storeValues.policyNumber});
            },

             // for detailed audit trail 
            openDetailedAuditTrail(data) {
                this.detailedAuditTrailOpen = true;
                this.loadNextDetailedPage(data);
            },

            loadNextDetailedPage(data) {
                if (data.length > 0) {
                    this.detailedAuditTrailData = data;
                }else{
                    this.detailedAuditTrailData = [];
                }
            }
    }
}
</script>

<style scoped>
    .aligncenter{
        position: fixed;
        left: 45%;
        top:45%;
    }

    .borderStyle{
        border:0.5px solid #d7d7d7;
    }

    .iconsize {
        font-size: 16px !important;
        font-weight: bold;
    }
    .btnstyle{
        height: 28px !important;
        text-transform: capitalize;
        font-size: 11px !important;
        letter-spacing: 0.5px;
    }
    .modify{
        color:#20B7B0;
    }
    .descrptionSpan {
        width: 85%;
        margin-top: 21px;
        font-weight: 500;
        font-size: 17px;
        letter-spacing: 0.4px;
   }
   .blueiconstyle {
        margin-right: -12px;
        background-color: #01bbd9;
        width: 45px;
        height: 38px;
        font-size: 26px !important;
        opacity: 1;
        color: #fff !important;
        cursor: pointer !important;
    }
    .colorbg {
        background-color: #23b1a9;
        color: #fff;
    }
    .textcolor {
        color: #1a0dab;
        font-weight: 400;
    }
    .audit {
        color: #e46a25;
    }
    
    .descrptionSpan1 {
        width: 83%;
        margin-left: 53px;
        margin-top: 21px;
        margin-bottom: 14px !important;
        font-weight: bold;
        font-size: 16px;
    }
    .descriptionstyle{
        background-color: #23B1A9;
        color: #FFFFFF;
    }
    .orangetext {
        color: #e46a25;
    }

    /* .paginationText{
      font-size: 12px;
    } */

    .selectstyle {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .groupRowHeadNewLable {
        margin-top: 16px;
        background-color: #01bbd9;
        height: 35px;
    }
    .GroupNameNewLable{
        font-size: 12px;
        color: white;
        font-weight: bold;
        letter-spacing: 0.2px;
    }
    .form-checkbox-group{
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25) !important;
        border: rgba(0, 0, 0, 0.125) solid 1px;
        padding: 10px;
        display: flex;
        height: 38px;
        justify-content: flex-start;
        align-items: center;
        column-gap: 15px;
        padding-top: 26px !important;
    }
    .checkboxmargin {
        margin-top: 26px;
    }
    .tablebtn {
      background: #ffffff 0% 0% no-repeat padding-box !important;
      box-shadow: inset 0px 2px 4px #00000052 !important;
      border: 0.5px solid #c1c8cc;
      border-radius: 18px !important;
      height: 30px !important;
      text-transform: capitalize !important;
      width: 100%;
      justify-content: space-between;
      font-size: small;
    }
    .tableicons {
      color: #23b1a9 !important;
    }
    .w-100 {
        width: 100%;
    }
</style>