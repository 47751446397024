<template>
    <v-form ref="openFileForm" @submit.prevent="openFileForm">
        <v-flex class="mt-5 ml-5 mr-5">
            <v-flex >
                <v-flex class="ml-5 mr-8" :class="openFile_Menu ? 'mb-3' : 'mb-5'">
                    <v-row class="groupRowHeadNewLable mt-0">
                        <v-col align="left" class="mt-0 pt-2">
                            <v-flex class="ml-0 GroupNameNewLable">Closed File Details</v-flex>
                        </v-col>
                        <v-col align="right" class="pt-1 mt-0">
                            <v-icon class="mr-2 viconfont" dark @click="openFile_Menu = !openFile_Menu">
                                {{ openFile_Menu == true ? "mdi-minus-box" : "mdi-plus-box" }}
                            </v-icon>
                        </v-col>
                    </v-row>
                </v-flex>

               <v-layout wrap class="pa-4 form-group-container borderStyle" v-if="openFile_Menu">
                <v-flex class="form-groups">
                  <label class="lableFontSize">
                    Claim Number 
                  </label>
                  <v-text-field
                    label="Enter"
                    solo
                    dense    
                    disabled
                    v-model="viewClosedFileObj.claimNumber"               
                    class="forminput"
                     maxlength="50"
                     ></v-text-field>
                </v-flex>
                <v-flex class="form-groups">
                  <label class="lableFontSize">
                    Feature Number 
                  </label>
                  <v-text-field
                    label="Enter"
                    solo
                    dense    
                    disabled
                    v-model="viewClosedFileObj.featureNumber"               
                    class="forminput"
                     maxlength="50"
                     ></v-text-field>
                </v-flex>
                <v-flex class="form-groups">
                  <label class="lableFontSize">
                    Date of loss
                  </label>
                  <v-text-field
                    label="Enter"
                    solo
                    dense
                    v-model="viewClosedFileObj.dateOfLoss" 
                    disabled                  
                    class="forminput"
                     maxlength="50"
                     ></v-text-field>
                </v-flex>
                <v-flex class="form-groups">
                  <label class="lableFontSize">
                    Date created
                  </label>
                  <v-text-field
                    label="Enter"
                    solo
                    dense  
                    disabled
                    v-model="viewClosedFileObj.dateCreated"                 
                    class="forminput"
                     maxlength="50"
                     ></v-text-field>
                </v-flex>
                <v-flex class="form-groups">
                  <label class="lableFontSize">
                    Date reported 
                  </label>
                  <v-text-field
                    label="Enter"
                    solo
                    dense   
                    disabled
                    v-model="viewClosedFileObj.dateReported"                
                    class="forminput"
                     maxlength="50"
                     ></v-text-field>
                </v-flex>
                <!-- <v-flex class="form-groups">
                  <label class="lableFontSize">
                    Product Name 
                  </label>
                  <v-text-field
                    label="Enter"
                    solo
                    dense 
                    disabled                  
                    class="forminput"
                     maxlength="50"
                     ></v-text-field>
                </v-flex> -->
                <v-layout>
                <v-flex class="form-groups">
                  <label class="lableFontSize">
                    Date closed
                  </label>
                  <v-text-field
                    label="Enter"
                    solo
                    dense
                    v-model="viewClosedFileObj.dateClosed"  
                    disabled                 
                    class="forminput"
                     maxlength="50"
                     ></v-text-field>
                </v-flex>
                <v-flex class="form-groups">
                  <label class="lableFontSize">
                    Product Name 
                  </label>
                  <v-text-field
                    label="Enter"
                    solo
                    dense
                    v-model="viewClosedFileObj.productName"  
                    disabled                 
                    class="forminput"
                     maxlength="50"
                     ></v-text-field>
                </v-flex>
                <v-flex class="form-groups">
                  <label class="lableFontSize">
                    Insured Name 
                  </label>
                  <v-text-field
                    label="Enter"
                    solo
                    dense 
                    v-model="viewClosedFileObj.insuredName" 
                    disabled                 
                    class="forminput"
                     maxlength="50"
                     ></v-text-field>
                </v-flex>
                <v-flex class="form-groups">
                  <label class="lableFontSize">
                    Claim Type 
                  </label>
                  <v-text-field
                    label="Enter"
                    solo
                    dense 
                    v-model="viewClosedFileObj.claimType"
                    disabled                  
                    class="forminput"
                     maxlength="50"
                     ></v-text-field>
                </v-flex>
                <v-flex class="form-groups">
                  <label class="lableFontSize">
                    Member Name 
                  </label>
                  <v-text-field
                    label="Enter"
                    solo
                    dense
                    v-model="viewClosedFileObj.memberName"   
                    disabled                
                    class="forminput"
                     maxlength="50"
                     ></v-text-field>
                </v-flex>
               </v-layout>
               <v-layout>
                <v-flex class="form-groups">
                  <label class="lableFontSize">
                    Examiner Name 
                  </label>
                  <v-text-field
                    label="Enter"
                    solo
                    dense
                    v-model="viewClosedFileObj.examiner.employeeName" 
                    disabled                  
                    class="forminput"
                     maxlength="50"
                     ></v-text-field>
                </v-flex>
                <v-flex class="form-groups">
                  <label class="lableFontSize">
                    Claimed Amount Paid
                  </label>
                  <v-text-field
                    label="Enter"
                    solo
                    dense 
                    disabled
                    v-model="viewClosedFileObj.claimedAmountPaid"                  
                    class="forminput"
                     maxlength="50"
                     ></v-text-field>
                </v-flex>
                <v-flex class="form-groups">
                  <label class="lableFontSize">
                    Expense paid 
                  </label>
                  <v-text-field
                    label="Enter"
                    solo
                    dense  
                    disabled
                    v-model="viewClosedFileObj.expensePaid"                 
                    class="forminput"
                     maxlength="50"
                     ></v-text-field>
                </v-flex>
                <v-flex class="form-groups">
                  <label class="lableFontSize">
                    CFR Status 
                  </label>
                  <v-text-field
                    label="Enter"
                    solo
                    dense 
                    disabled
                    v-model="viewClosedFileObj.cfrStatus"                  
                    class="forminput"
                     maxlength="50"
                     ></v-text-field>
                </v-flex>
                <v-flex class="form-groups">
                  <label class="lableFontSize">
                    Net Score
                  </label>
                  <v-text-field
                    label="Enter"
                    solo
                    dense  
                    v-model="checkNetScore"                 
                    class="forminput"
                     maxlength="50"
                     disabled
                     ></v-text-field>
                </v-flex>
               </v-layout>
               <v-layout>
                <v-flex class="form-groups">
                  <label class="lableFontSize">
                    Review By
                  </label>
                  <v-text-field
                    label="Enter"
                    solo
                    dense  
                    v-model="viewClosedFileObj.userId"                 
                    class="forminput"
                     maxlength="50"
                     disabled
                     ></v-text-field>
                </v-flex>
                <v-flex class="form-groups">
                  <label class="lableFontSize">
                    Reviewed Date
                  </label>
                  <v-text-field
                    label="Enter"
                    solo
                    dense  
                    v-model="viewClosedFileObj.Date"                 
                    class="forminput"
                     maxlength="50"
                     disabled
                     ></v-text-field>
                </v-flex>
                <v-flex class="form-groups">
                  <label class="lableFontSize">
                    Policy Number
                  </label>
                  <v-text-field
                    label="Enter"
                    solo
                    dense  
                    v-model="viewClosedFileObj.policyNumber"                 
                    class="forminput"
                     maxlength="50"
                     disabled
                     ></v-text-field>
                </v-flex>
                <v-flex class="form-groups">
                  <label class="lableFontSize">
                    Certificate Number
                  </label>
                  <v-text-field
                    label="Enter"
                    solo
                    dense  
                    v-model="viewClosedFileObj.certificateNumber"                 
                    class="forminput"
                     maxlength="50"
                     disabled
                     ></v-text-field>
                </v-flex>
                <v-flex class="form-groups">
                  <label class="lableFontSize">
                    Claim status
                  </label>
                  <v-text-field
                    label="Enter"
                    solo
                    dense  
                    v-model="viewClosedFileObj.claimStatus"                 
                    class="forminput"
                     maxlength="50"
                     disabled
                     ></v-text-field>
                </v-flex>
               </v-layout>
                <v-layout>
                <v-flex class="form-groups">
                  <label class="lableFontSize">
                    Total Indemnity Paid
                  </label>
                  <v-text-field
                    label="Enter"
                    solo
                    dense  
                    v-model="viewClosedFileObj.indemnityPaid"                 
                    class="forminput"
                     maxlength="50"
                     disabled
                     ></v-text-field>
                </v-flex>
                <v-flex class="form-groups">
                  <label class="lableFontSize">
                    Total Expense Paid
                  </label>
                  <v-text-field
                    label="Enter"
                    solo
                    dense  
                    v-model="viewClosedFileObj.expensePaid"                 
                    class="forminput"
                     maxlength="50"
                     disabled
                     ></v-text-field>
                </v-flex>
                <v-flex class="form-groups">
                  <label class="lableFontSize">
                    Reserve Amount
                  </label>
                  <v-text-field
                    label="Enter"
                    solo
                    dense  
                    v-model="viewClosedFileObj.reserveAmount"                 
                    class="forminput"
                     maxlength="50"
                     disabled
                     ></v-text-field>
                </v-flex>
                <v-flex class="form-groups">
                  <label class="lableFontSize">
                    Sum Insured
                  </label>
                  <v-text-field
                    label="Enter"
                    solo
                    dense  
                    v-model="viewClosedFileObj.sumInsured"                 
                    class="forminput"
                     maxlength="50"
                     disabled
                     ></v-text-field>
                </v-flex>
                <v-flex class="form-groups">
                    <label class="lableFontSize">
                        Assign To
                    </label>
                    <v-text-field label="Enter" solo dense v-model="viewClosedFileObj.assignTo && viewClosedFileObj.assignTo.employeeName"
                        :readonly="true" @click="examinerDialog = true" class="forminput" maxlength="50"
                        :disabled="viewClosedFileObj.cfrStatus!=='Open'"></v-text-field>
                </v-flex>
               </v-layout>
               <v-layout>
                    <v-flex class="form-groups">
                    <label class="lableFontSize">
                        Policy Start Date
                    </label>
                    <v-text-field
                        solo
                        dense
                        v-model="viewClosedFileObj.policyStartDate" 
                        disabled                  
                        class="forminput"
                        maxlength="50"
                        ></v-text-field>
                    </v-flex>
                    <v-flex class="form-groups">
                    <label class="lableFontSize">
                        Policy End Date
                    </label>
                    <v-text-field
                        solo
                        dense
                        v-model="viewClosedFileObj.policyEndDate" 
                        disabled                  
                        class="forminput"
                        maxlength="50"
                        ></v-text-field>
                    </v-flex>
               </v-layout>
               </v-layout>
               

                <v-flex class="ml-5 mr-8" :class="fafc ? 'mb-3' : 'mb-5'">
                    <v-row class="groupRowHeadNewLable mt-0">
                        <v-col align="left" class="mt-0 pt-2">
                            <v-flex class="ml-0 GroupNameNewLable">FNOL and First Contact</v-flex>
                        </v-col>
                        <v-col align="right" class="pt-1 mt-0">
                            <v-icon class="mr-2 viconfont" dark @click="fafc = !fafc">
                                {{ fafc == true ? "mdi-minus-box" : "mdi-plus-box" }}
                            </v-icon>
                        </v-col>
                    </v-row>
                </v-flex>
                <v-flex v-if="fafc" class="pa-3 borderStyle">
                    <template>
                <v-simple-table fixed-header class="vDataTableStyling mb-8">
                <template>
                    <thead>
                    <tr>
                        <th class="text-center" width="16%">
                        <span class="leftstyle">Parameters</span>
                        </th>
                        <th class="text-center" width="10%">
                        <span class="leftstyle ml-4">Evaluation</span>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>
                        <span class="leftstyle"> Was the coverage confirmed on time ? <span class="mandatorycolor"> *</span></span>
                        </td>
                        <td class="pr-1">
                        <v-select
                            dense
                            solo
                            class="forminput reservefield selectstyle mt-4 ml-6"
                            v-model="viewClosedFileObj.fnol.coverageConfirmedOnTime"
                            :rules="rules.mandatoryrule"
                            :items="fnolArr"
                            :placeholder="viewMode ? '' : 'Select'"
                            :background-color="viewMode ? '#f5f5f5' : ''"
                            :class=" viewMode ? 'forminput textDisable font-weight-bold' : 'forminput'"
                            :disabled="viewMode">
                        </v-select>
                        </td>
                    </tr>
                    <tr>
                        <td>
                        <span class="leftstyle">Was the first contact made on time ?
                            <span class="mandatorycolor"> *</span></span>
                        </td>
                        <td class="pr-1">
                        <v-select
                            dense
                            solo
                            class="forminput reservefield selectstyle mt-4 ml-6"
                            v-model="viewClosedFileObj.fnol.firstContactMadeOnTime"
                            :rules="rules.mandatoryrule"
                            :items="fnolArr"
                            :placeholder="viewMode ? '' : 'Select'"
                            :background-color="viewMode ? '#f5f5f5' : ''"
                            :class="viewMode ? 'forminput textDisable font-weight-bold' : 'forminput'"
                            :disabled="viewMode">
                        </v-select>
                        </td>
                    </tr>
                    <tr>
                        <td>
                        <span class="leftstyle">Were initial communications to insured sent on time duly and recorded ? <span class="mandatorycolor"> *</span></span>
                        </td>
                        <td class="pr-1">
                        <v-select
                            dense
                            solo
                            class="forminput reservefield selectstyle mt-4 ml-6"
                            :rules="rules.mandatoryrule"
                            v-model="viewClosedFileObj.fnol.initialCommToInsuredSentOnTime"
                            :items="fnolArr"
                            :placeholder="viewMode ? '' : 'Select'"
                            :background-color="viewMode ? '#f5f5f5' : ''"
                            :class="viewMode ? 'forminput textDisable font-weight-bold' : 'forminput'"
                            :disabled="viewMode">
                        </v-select>
                        </td>
                    </tr>
                    <!-- <tr>
                        <td>
                        <span class="leftstyle">Recorded ? <span class="mandatorycolor"> *</span></span>
                        </td>
                        <td class="pr-1">
                        <v-select
                            dense
                            solo
                            class="forminput reservefield selectstyle mt-1 ml-6"
                            v-model="viewClosedFileObj.fnol.recorded"
                            :rules="rules.mandatoryrule"
                            :items="fnolArr"
                            :placeholder="viewMode ? '' : 'Select'"
                            :background-color="viewMode ? '#f5f5f5' : ''"
                            :class="viewMode ? 'forminput textDisable font-weight-bold' : 'forminput'"
                            :disabled="viewMode">
                        </v-select>
                        </td>
                    </tr> -->
                    <!-- TPM-205 fixes -->
                    <!-- <tr>
                        <td>
                        <span class="leftstyle">Is the file CKYC compliant ? <span class="mandatorycolor"> *</span></span>
                        </td>
                        <td class="pr-1">
                        <v-select
                            dense
                            solo
                            class="forminput reservefield selectstyle mt-4 ml-6"
                            v-model="viewClosedFileObj.fnol.ckycCompliant"
                            :rules="rules.mandatoryrule"
                            :items="fnolArr"
                            :placeholder="viewMode ? '' : 'Select'"
                            :background-color="viewMode ? '#f5f5f5' : ''"
                            :class=" viewMode ? 'forminput textDisable font-weight-bold' : 'forminput'"
                            :disabled="viewMode">
                        </v-select>
                        </td>
                    </tr> -->
                    <tr>
                        <td>
                        <span class="leftstyle">AML compliant ? <span class="mandatorycolor"> *</span></span>
                        </td>
                        <td class="pr-1">
                        <v-select
                            dense
                            solo
                            class="forminput reservefield selectstyle mt-4 ml-6"
                            v-model="viewClosedFileObj.fnol.amlCompliant"
                            :rules="rules.mandatoryrule"
                            :items="fnolArr"
                            :placeholder="viewMode ? '' : 'Select'"
                            :background-color="viewMode ? '#f5f5f5' : ''"
                            :class="viewMode ? 'forminput textDisable font-weight-bold' : 'forminput'"
                            :disabled="viewMode">
                        </v-select>
                        </td>
                    </tr>
                    <tr>
                        <td>
                        <span class="leftstyle" >Were adequate initial reserve created & approved ? <span class="mandatorycolor"> *</span></span>
                        </td>
                        <td class="pr-1">
                        <v-select
                            dense
                            solo
                            class="forminput reservefield selectstyle mt-4 ml-6"
                            v-model=" viewClosedFileObj.fnol .adequateInitialReserveCreatedApproved"
                            :rules="rules.mandatoryrule"
                            :items="fnolArr"
                            :placeholder="viewMode ? '' : 'Select'"
                            :background-color="viewMode ? '#f5f5f5' : ''"
                            :class="viewMode ? 'forminput textDisable font-weight-bold': 'forminput'"
                            :disabled="viewMode">
                        </v-select>
                        </td>
                    </tr>
                    </tbody>
                </template>
                </v-simple-table>
            </template>
            <v-layout>
                <v-flex>
                <p class="ml-10 mb-1 claimlabel">Comments: <span class="mandatorycolor">*</span></p>
                <v-textarea
                    v-model="viewClosedFileObj.fnol.comments"
                    color="black"
                    solo
                    dense
                    class="px-10 forminput"
                    rows="3"
                    :maxLength="500"
                    :placeholder="viewMode ? '' : 'Enter'"
                    :background-color="viewMode ? '#f5f5f5' : ''"
                    :class="viewMode? 'forminput textDisable font-weight-bold': 'forminput'"
                    :disabled="viewMode"
                    :rules="rules.mandatoryrule">
                </v-textarea>
                </v-flex>
            </v-layout>
                </v-flex>
                <v-flex class="ml-5 mr-8" :class="assessment ? 'mb-3' : 'mb-5'">
                    <v-row class="groupRowHeadNewLable mt-0">
                        <v-col align="left" class="mt-0 pt-2">
                            <v-flex class="ml-0 GroupNameNewLable">Assessment</v-flex>
                        </v-col>
                        <v-col align="right" class="pt-1 mt-0">
                            <v-icon class="mr-2 viconfont" dark @click="assessment = !assessment">
                                {{ assessment == true ? "mdi-minus-box" : "mdi-plus-box" }}
                            </v-icon>
                        </v-col>
                    </v-row>
                </v-flex>
                <v-flex v-if="assessment" class="pa-3 borderStyle">
                    <template>
                <v-simple-table fixed-header class="vDataTableStyling theadstyle mb-8">
                <template>
                    <thead>
                    <tr>
                        <th class="text-center" width="16%">
                        <span class="leftstyle">Parameters</span>
                        </th>
                        <th class="text-center" width="10%">
                        <span class="leftstyle ml-4">Evaluation</span>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td><span class="leftstyle"> Was the claim cause analysis done properly ? <span class="mandatorycolor"> *</span> </span></td>
                        <td class="pr-1">
                        <v-select
                            dense
                            solo
                            class="forminput reservefield selectstyle mt-4 ml-6"
                            v-model="viewClosedFileObj.assessment.claimCauseAnalysisDone"
                            :rules="rules.mandatoryrule"
                            :items="assessmentArr"
                            :placeholder="viewMode ? '' : 'Select'"
                            :background-color="viewMode ? '#f5f5f5' : ''"
                            :class=" viewMode ? 'forminput textDisable font-weight-bold' : 'forminput'"
                            :disabled="viewMode"
                        >
                        </v-select>
                        </td>
                    </tr>
                    <tr>
                        <td> <span class="leftstyle">Was the claim calculation done properly & recorded ?<span class="mandatorycolor"> *</span></span>
                        </td>
                        <td class="pr-1">
                        <v-select
                            dense
                            solo
                            class="forminput reservefield selectstyle mt-4 ml-6"
                            v-model="
                            viewClosedFileObj.assessment.claimCalculationDone"
                            :rules="rules.mandatoryrule"
                            :items="assessmentArr"
                            :placeholder="viewMode ? '' : 'Select'"
                            :background-color="viewMode ? '#f5f5f5' : ''"
                            :class="viewMode ? 'forminput textDisable font-weight-bold' : 'forminput' "
                            :disabled="viewMode">
                        </v-select>
                        </td>
                    </tr>
                    <tr>
                        <td>
                        <span class="leftstyle"> Were the adequate documents collected as needed for claim decision ? <span class="mandatorycolor"> *</span></span>
                        </td>
                        <td class="pr-1">
                        <v-select
                            dense
                            solo
                            class="forminput reservefield selectstyle mt-4 ml-6"
                            v-model="viewClosedFileObj.assessment.adequateDocCollected"
                            :rules="rules.mandatoryrule"
                            :items="assessmentArr"
                            :placeholder="viewMode ? '' : 'Select'"
                            :background-color="viewMode ? '#f5f5f5' : ''"
                            :class="viewMode ? 'forminput textDisable font-weight-bold' : 'forminput'"
                            :disabled="viewMode">
                        </v-select>
                        </td>
                    </tr>
                    <tr>
                        <td><span class="leftstyle">Were services of external investigator utilized for verification ?<span class="mandatorycolor"> *</span></span></td>
                        <td class="pr-1">
                        <v-select
                            dense
                            solo
                            class="forminput reservefield selectstyle mt-4 ml-6"
                            v-model="viewClosedFileObj.assessment.servicesOfExtInvUtilized"
                            :rules="rules.mandatoryrule"
                            :items="assessmentArr"
                            :placeholder="viewMode ? '' : 'Select'"
                            :background-color="viewMode ? '#f5f5f5' : ''"
                            :class="viewMode ? 'forminput textDisable font-weight-bold' : 'forminput' "
                            :disabled="viewMode">
                        </v-select>
                        </td>
                    </tr>
                    <!-- TPM-206 fix  -->
                    <!-- <tr>
                        <td><span class="leftstyle" >Were the documents inwarded on time as per the system ?<span class="mandatorycolor"> *</span></span></td>
                        <td class="pr-1">
                        <v-select
                            dense
                            solo
                            class="forminput reservefield selectstyle mt-4 ml-6"
                            v-model="viewClosedFileObj.assessment.docInwardedOnTime"
                            :rules="rules.mandatoryrule"
                            :items="assessmentArr"
                            :placeholder="viewMode ? '' : 'Select'"
                            :background-color="viewMode ? '#f5f5f5' : ''"
                            :class=" viewMode ? 'forminput textDisable font-weight-bold' : 'forminput'"
                            :disabled="viewMode">
                        </v-select>
                        </td>
                    </tr> -->
                    <tr>
                        <td><span class="leftstyle">Were the reserves updated on time & adequate to the amount settled ? <span class="mandatorycolor"> *</span></span></td>
                        <td class="pr-1">
                        <v-select
                            dense
                            solo
                            class="forminput reservefield selectstyle mt-4 ml-6"
                            v-model=" viewClosedFileObj.assessment.adequateToAmountSettled"
                            :rules="rules.mandatoryrule"
                            :items="assessmentArr"
                            :placeholder="viewMode ? '' : 'Select'"
                            :background-color="viewMode ? '#f5f5f5' : ''"
                            :class="viewMode ? 'forminput textDisable font-weight-bold' : 'forminput'"
                            :disabled="viewMode">
                        </v-select>
                        </td>
                    </tr>
                    <tr>
                        <td>
                        <span class="leftstyle">Were appointment of investigator done on time ?<span class="mandatorycolor"> *</span></span>
                        </td>
                        <td class="pr-1">
                        <v-select
                            dense
                            solo
                            class="forminput reservefield selectstyle mt-4 ml-6"
                            v-model="viewClosedFileObj.assessment.appointmentOfInvDoneOnTime"
                            :rules="rules.mandatoryrule"
                            :items="assessmentArr"
                            :placeholder="viewMode ? '' : 'Select'"
                            :background-color="viewMode ? '#f5f5f5' : ''"
                            :class=" viewMode ? 'forminput textDisable font-weight-bold' : 'forminput'"
                            :disabled="viewMode">
                        </v-select>
                        </td>
                    </tr>
                    <tr>
                        <td>
                        <span class="leftstyle">Were meaningful instructions were given to external investigator ? <span class="mandatorycolor"> *</span></span>
                        </td>
                        <td class="pr-1">
                        <v-select
                            dense
                            solo
                            class="forminput reservefield selectstyle mt-4 ml-6"
                            v-model="viewClosedFileObj.assessment.meaningfullInstGivenToExtInv"
                            :rules="rules.mandatoryrule"
                            :items="assessmentArr"
                            :placeholder="viewMode ? '' : 'Select'"
                            :background-color="viewMode ? '#f5f5f5' : ''"
                            :class="viewMode ? 'forminput textDisable font-weight-bold': 'forminput'"
                            :disabled="viewMode"
                        >
                        </v-select>
                        </td>
                    </tr>
                    </tbody>
                </template>
                </v-simple-table>
            </template>
            <v-layout>
                <v-flex>
                <p class="ml-10 mb-1 claimlabel">Comments: <span class="mandatorycolor">*</span></p>
                <v-textarea
                    v-model="viewClosedFileObj.assessment.comments"
                    color="black"
                    solo
                    dense
                    class="px-10 forminput"
                    rows="3"
                    :maxLength="500"
                    :placeholder="viewMode ? '' : 'Enter'"
                    :background-color="viewMode ? '#f5f5f5' : ''"
                    :class="viewMode? 'forminput textDisable font-weight-bold': 'forminput'"
                    :disabled="viewMode"
                    :rules="rules.mandatoryrule">
                </v-textarea>
                </v-flex>
            </v-layout>
                </v-flex>
                <v-flex class="ml-5 mr-8" :class="dfm ? 'mb-3' : 'mb-5'">
                    <v-row class="groupRowHeadNewLable mt-0">
                        <v-col align="left" class="mt-0 pt-2">
                            <v-flex class="ml-0 GroupNameNewLable">Documentation & File Management</v-flex>
                        </v-col>
                        <v-col align="right" class="pt-1 mt-0">
                            <v-icon class="mr-2 viconfont" dark @click="dfm = !dfm">
                                {{ dfm == true ? "mdi-minus-box" : "mdi-plus-box" }}
                            </v-icon>
                        </v-col>
                    </v-row>
                </v-flex>
                <v-flex v-if="dfm" class="pt-3 borderStyle">
                    <template>
                <v-simple-table
                fixed-header
                class="vDataTableStyling theadstyle mb-8">
                <template>
                    <thead>
                    <tr>
                        <th class="text-center" width="16%">
                        <span class="leftstyle">Parameters</span>
                        </th>
                        <th class="text-center" width="10%">
                        <span class="leftstyle ml-4">Evaluation</span>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>
                        <span class="leftstyle"> Were the documents inwarded on time as per the system ? <span class="mandatorycolor"> *</span></span>
                        </td>
                        <td class="pr-1">
                        <v-select 
                            dense 
                            solo 
                            class="forminput reservefield selectstyle mt-4 ml-6" 
                            v-model=" viewClosedFileObj.documentation.docInwardedOnTime"
                            :rules="rules.mandatoryrule"
                            :items="docsArr"
                            :placeholder="viewMode ? '' : 'Select'"
                            :background-color="viewMode ? '#f5f5f5' : ''"
                            :class="viewMode? 'forminput textDisable font-weight-bold': 'forminput'"
                            :disabled="viewMode">
                        </v-select>
                        </td>
                    </tr>
                    <!-- <tr>
                        <td>
                        <span class="leftstyle">Was the reserves updated on time and adequate to the amount settled ? <span class="mandatorycolor"> *</span> </span> </td>
                        <td class="pr-1">
                        <v-select
                            dense
                            solo
                            class="forminput reservefield selectstyle mt-1 ml-6"
                            v-model="viewClosedFileObj.documentation.reserveUpdateOnTime"
                            :rules="rules.mandatoryrule"
                            :items="docsArr"
                            :placeholder="viewMode ? '' : 'Select'"
                            :background-color="viewMode ? '#f5f5f5' : ''"
                            :class="viewMode ? 'forminput textDisable font-weight-bold': 'forminput'"
                            :disabled="viewMode">
                        </v-select>
                        </td>
                    </tr> -->
                    <tr>
                        <td> <span class="leftstyle"> Did the examiner review the claim in a timely manner ? <span class="mandatorycolor"> *</span> </span> </td>
                        <td class="pr-1">
                        <v-select
                            dense
                            solo
                            class="forminput reservefield selectstyle mt-4 ml-6"
                            v-model=" viewClosedFileObj.documentation.examinerReviewClaimTimely"
                            :rules="rules.mandatoryrule"
                            :items="docsArr"
                            :placeholder="viewMode ? '' : 'Select'"
                            :background-color="viewMode ? '#f5f5f5' : ''"
                            :class="viewMode ? 'forminput textDisable font-weight-bold' : 'forminput'"
                            :disabled="viewMode">
                        </v-select>
                        </td>
                    </tr>
                    <tr>
                        <td> <span class="leftstyle" >Were letters / communications sent in a timely manner as and when required(Follow-up / Review) ? <span class="mandatorycolor"> *</span> </span> </td>
                        <td class="pr-1">
                        <v-select
                            dense
                            solo
                            class="forminput reservefield selectstyle mt-4 ml-6"
                            v-model="viewClosedFileObj.documentation.followUp_review"
                            :rules="rules.mandatoryrule"
                            :items="docsArr"
                            :placeholder="viewMode ? '' : 'Select'"
                            :background-color="viewMode ? '#f5f5f5' : ''"
                            :class="viewMode ? 'forminput textDisable font-weight-bold' : 'forminput'"
                            :disabled="viewMode">
                        </v-select>
                        </td>
                    </tr>
                    <tr>
                        <td> <span class="leftstyle" >Is the file CKYC compliant ? <span class="mandatorycolor"> *</span></span> </td>
                        <td class="pr-1">
                        <v-select
                            dense
                            solo
                            class="forminput reservefield selectstyle mt-4 ml-6"
                            v-model="viewClosedFileObj.documentation.ckycCompliant "
                            :rules="rules.mandatoryrule"
                            :items="docsArr"
                            :placeholder="viewMode ? '' : 'Select'"
                            :background-color="viewMode ? '#f5f5f5' : ''"
                            :class="viewMode ? 'forminput textDisable font-weight-bold' : 'forminput' "
                            :disabled="viewMode">
                        </v-select>
                        </td>
                    </tr>
                    <tr>
                        <td> <span class="leftstyle" >Were any red flags brought to the notice of the supervisor on time ? <span class="mandatorycolor"> *</span></span> </td>
                        <td class="pr-1">
                        <v-select
                            dense
                            solo
                            class="forminput reservefield selectstyle mt-4 ml-6"
                            v-model="viewClosedFileObj.documentation.anyRedFlag "
                            :rules="rules.mandatoryrule"
                            :items="docsArr"
                            :placeholder="viewMode ? '' : 'Select'"
                            :background-color="viewMode ? '#f5f5f5' : ''"
                            :class="viewMode ? 'forminput textDisable font-weight-bold' : 'forminput' "
                            :disabled="viewMode">
                        </v-select>
                        </td>
                    </tr>
                    <tr>
                        <td> <span class="leftstyle" >Were the reserves updated on time & adequate to the amount settled ? <span class="mandatorycolor"> *</span></span> </td>
                        <td class="pr-1">
                        <v-select
                            dense
                            solo
                            class="forminput reservefield selectstyle mt-4 ml-6"
                            v-model="viewClosedFileObj.documentation.reserveUpdateOnTime"
                            :rules="rules.mandatoryrule"
                            :items="docsArr"
                            :placeholder="viewMode ? '' : 'Select'"
                            :background-color="viewMode ? '#f5f5f5' : ''"
                            :class=" viewMode ? 'forminput textDisable font-weight-bold' : 'forminput' "
                            :disabled="viewMode">
                        </v-select>
                        </td>
                    </tr>
                    <tr>
                        <td>
                        <span class="leftstyle">Was the payment posted within the prescribed TAT ?<span class="mandatorycolor"> *</span></span>
                        </td>
                        <td class="pr-1">
                        <v-select
                            dense
                            solo
                            class="forminput reservefield selectstyle mt-4 ml-6"
                            v-model="viewClosedFileObj.documentation.paymentPostedwithinTAT"
                            :rules="rules.mandatoryrule"
                            :items="docsArr"
                            :placeholder="viewMode ? '' : 'Select'"
                            :background-color="viewMode ? '#f5f5f5' : ''"
                            :class="viewMode? 'forminput textDisable font-weight-bold': 'forminput'"
                            :disabled="viewMode">
                        </v-select>
                        </td>
                    </tr>
                    <tr>
                        <td>
                        <span class="leftstyle">Were approval taken from Competent authority in case of denials ?<span class="mandatorycolor"> *</span></span>
                        </td>
                        <td class="pr-1">
                        <v-select
                            dense
                            solo
                            class="forminput reservefield selectstyle mt-4 ml-6"
                            v-model="viewClosedFileObj.documentation.approvalTakenfromAuthority"
                            :rules="rules.mandatoryrule"
                            :items="docsArr"
                            :placeholder="viewMode ? '' : 'Select'"
                            :background-color="viewMode ? '#f5f5f5' : ''"
                            :class="viewMode ? 'forminput textDisable font-weight-bold': 'forminput'"
                            :disabled="viewMode">
                        </v-select>
                        </td>
                    </tr>
                    <tr>
                        <td><span class="leftstyle" >Were documents uploaded in system ?<span class="mandatorycolor"  >*</span></span></td>
                        <td class="pr-1">
                        <v-select
                            dense
                            solo
                            class="forminput reservefield selectstyle mt-4 ml-6"
                            v-model="viewClosedFileObj.documentation.docUploadedInSys"
                            :rules="rules.mandatoryrule"
                            :items="docsArr"
                            :placeholder="viewMode ? '' : 'Select'"
                            :background-color="viewMode ? '#f5f5f5' : ''"
                            :class="viewMode  ? 'forminput textDisable font-weight-bold'  : 'forminput'"
                            :disabled="viewMode"
                        >
                        </v-select>
                        </td>
                    </tr>                 
                    <!-- <tr class="v-data-table__empty-wrapper" v-if="adherenceDetails.length === 0">
                                <td colspan="12">No Records For Adherence</td>
                            </tr> -->
                    </tbody>
                </template>
                </v-simple-table>
            </template>
            <v-layout>
                <v-flex>
                <p class="ml-10 mb-1 claimlabel">Comments: <span class="mandatorycolor">*</span></p>
                <v-textarea
                    v-model="viewClosedFileObj.documentation.comments"
                    color="black"
                    solo
                    dense
                    class="px-10 forminput"
                    rows="3"
                    :maxLength="500"
                    :placeholder="viewMode ? '' : 'Enter'"
                    :background-color="viewMode ? '#f5f5f5' : ''"
                    :class="viewMode? 'forminput textDisable font-weight-bold': 'forminput'"
                    :disabled="viewMode"
                    :rules="rules.mandatoryrule">
                </v-textarea>
                </v-flex>
            </v-layout>
                </v-flex>
                <v-flex class="ml-5 mr-8" :class="overallRating ? 'mb-3' : 'mb-5'">
                    <v-row class="groupRowHeadNewLable mt-0">
                        <v-col align="left" class="mt-0 pt-2">
                            <v-flex class="ml-0 GroupNameNewLable">Overall Rating</v-flex>
                        </v-col>
                        <v-col align="right" class="pt-1 mt-0">
                            <v-icon class="mr-2 viconfont" dark @click="overallRating = !overallRating">
                                {{ overallRating == true ? "mdi-minus-box" : "mdi-plus-box" }}
                            </v-icon>
                        </v-col>
                    </v-row>
                </v-flex>
                <v-flex v-if="overallRating" class="pt-3 borderStyle">
                    <template>
                <v-simple-table
                fixed-header
                class="vDataTableStyling theadstyle mb-8">
                <template>
                    <thead>
                    <tr>
                        <th class="text-center" width="16%">
                        <span class="leftstyle">Parameters</span>
                        </th>
                        <th class="text-center" width="10%">
                        <span class="leftstyle ml-4">Evaluation</span>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td><span class="leftstyle">  Was the insured informed about the settlement details  ?<span class="mandatorycolor">*</span></span>
                        </td>
                        <td class="pr-1">
                        <v-select
                            dense
                            solo
                            class="forminput reservefield selectstyle mt-4 ml-6"
                            v-model="viewClosedFileObj.overallRating.insuredInformedAboutSettlement"
                            :items="ratingArr"
                            :placeholder="viewMode ? '' : 'Select'"
                            :background-color="viewMode ? '#f5f5f5' : ''"
                            :class="viewMode  ? 'forminput textDisable font-weight-bold'  : 'forminput'"
                            :disabled="viewMode"
                            :rules="rules.mandatoryrule"
                        >
                        </v-select>
                        </td>
                    </tr>
                    <tr>
                        <td><span class="leftstyle"  >Did the overall handling of the claim meet all standards of expectations ?  <span class="mandatorycolor">*</span></span>
                        </td>
                        <td class="pr-1">
                        <v-select
                            dense
                            solo
                            class="forminput reservefield selectstyle mt-4 ml-6"
                            v-model="viewClosedFileObj.overallRating.overallhandlingofClaimMeet"
                            :items="ratingArr"
                            :placeholder="viewMode ? '' : 'Select'"
                            :background-color="viewMode ? '#f5f5f5' : ''"
                            :class="viewMode  ? 'forminput textDisable font-weight-bold'  : 'forminput'"
                            :disabled="viewMode"
                            :rules="rules.mandatoryrule"
                        >
                        </v-select>
                        </td>
                    </tr>
                    <tr>
                        <td>
                        <span class="leftstyle">Has the examiner been proactive in expediting claim processing ? <span class="mandatorycolor">*</span></span>
                        </td>
                        <td class="pr-1">
                        <v-select
                            dense
                            solo
                            class="forminput reservefield selectstyle mt-4 ml-6"
                            v-model="viewClosedFileObj.overallRating.examinerProactiveInexpeditingClaim"
                            :items="ratingArr"
                            :placeholder="viewMode ? '' : 'Select'"
                            :background-color="viewMode ? '#f5f5f5' : ''"
                            :class=" viewMode ? 'forminput textDisable font-weight-bold' : 'forminput' "
                            :disabled="viewMode"
                            :rules="rules.mandatoryrule">
                        </v-select>
                        </td>
                    </tr>
                    <tr>
                        <td><span class="leftstyle">Overall evaluation of claim for handling ?  <span class="mandatorycolor">*</span></span>
                        </td>
                        <td class="pr-1">
                        <v-select
                            dense
                            solo
                            class="forminput reservefield selectstyle mt-4 ml-6"
                            v-model="viewClosedFileObj.overallRating.overallEvaluationOfClaimFileHandling"
                            :items="ratingArr"
                            :placeholder="viewMode ? '' : 'Select'"
                            :background-color="viewMode ? '#f5f5f5' : ''"
                            :class="viewMode? 'forminput textDisable font-weight-bold': 'forminput'"
                            :disabled="viewMode"
                            :rules="rules.mandatoryrule"
                        >
                        </v-select>
                        </td>
                    </tr>
                    <!-- <tr class="v-data-table__empty-wrapper" v-if="adherenceDetails.length === 0">
                                    <td colspan="12">No Records For Adherence</td>
                                </tr> -->
                    </tbody>
                </template>
                </v-simple-table>
            </template>

            <v-layout>
                <v-flex>
                <p class="ml-10 mb-1 claimlabel">Comments: <span class="mandatorycolor">*</span></p>
                <v-textarea
                    v-model="viewClosedFileObj.overallRating.comments"
                    color="black"
                    solo
                    dense
                    class="px-10 forminput"
                    rows="3"
                    :maxLength="500"
                    :placeholder="viewMode ? '' : 'Enter'"
                    :background-color="viewMode ? '#f5f5f5' : ''"
                    :class="viewMode? 'forminput textDisable font-weight-bold': 'forminput'"
                    :disabled="viewMode"
                    :rules="rules.mandatoryrule"
                >
                </v-textarea>
                </v-flex>
            </v-layout>
                </v-flex>
            

                <!-- <v-flex v-if="adhrencemenu" class="pt-3 borderStyle">
                    <template>
                        <v-simple-table fixed-header class="vDataTableStyling theadstyle mb-8">
                        <template >
                        <thead >                
                            <tr>                       
                                <th class="text-center" width="14%"> <span class="leftstyle">Parameters</span></th>
                                <th class="text-center" width="10%"><span class="leftstyle ml-4">Evaluation</span> </th>                            
                            </tr>
                        </thead>
                        <tbody>                       
                            <tr>
                            <td ><span class="leftstyle"> First contact ? <span class="mandatorycolor">*</span></span></td>
                                <td class="pr-1">
                                    <v-select 
                                        dense  
                                        solo 
                                        class="forminput reservefield selectstyle mt-1 ml-6"
                                        v-model="viewClosedFileObj.adherenceToTat.firstContact"
                                        :items = "firstContactArr"
                                        :placeholder="viewMode? '' : 'Enter'"
                                        :background-color="viewMode? '#f5f5f5' : ''"
                                        :class="viewMode? 'forminput textDisable font-weight-bold' : 'forminput'"
                                        :disabled="viewMode"
                                        :rules="rules.mandatoryrule">
                                    </v-select>
                                </td>                                                       
                            </tr>
                            <tr>
                            <td><span class="leftstyle">Follow up reminders ? <span class="mandatorycolor">*</span></span></td>
                                <td class="pr-1">
                                    <v-select  
                                        dense  
                                        solo 
                                        class="forminput reservefield selectstyle mt-1 ml-6"
                                        v-model="viewClosedFileObj.adherenceToTat.followUpReminders"
                                        :items="followUpRemindersArr"
                                        :placeholder="viewMode? '' : 'Enter'"
                                        :background-color="viewMode? '#f5f5f5' : ''"
                                        :class="viewMode? 'forminput textDisable font-weight-bold' : 'forminput'"
                                        :disabled="viewMode"
                                        :rules="rules.mandatoryrule">
                                    </v-select>
                                </td>                      
                            </tr>
                            <tr>
                            <td><span class="leftstyle">Reason for pendency ? <span class="mandatorycolor">*</span></span></td>
                                <td class="pr-1">
                                    <v-select 
                                        dense  
                                        solo 
                                        class="forminput reservefield selectstyle mt-1 ml-6"
                                        :items="reasonForArr"
                                        v-model="viewClosedFileObj.adherenceToTat.reasonForPendency"
                                        :placeholder="viewMode? '' : 'Enter'"
                                        :background-color="viewMode? '#f5f5f5' : ''"
                                        :class="viewMode? 'forminput textDisable font-weight-bold' : 'forminput'"
                                        :disabled="viewMode"
                                        :rules="rules.mandatoryrule">
                                    </v-select>
                                </td>
                        </tr>
                            <tr>
                            <td><span class="leftstyle">Reserve ?</span></td>
                                <td class="pr-1">
                                    <v-text-field  
                                        dense 
                                        solo 
                                        class="forminput reservefield selectstyle mt-1 ml-6"
                                        v-model="viewClosedFileObj.reserve"
                                        :placeholder="viewMode? '' : 'Enter'"
                                        :background-color="viewMode? '#f5f5f5' : ''"
                                        :class="viewMode? 'forminput textDisable font-weight-bold' : 'forminput'"
                                        :disabled="viewMode">
                                    </v-text-field>
                                </td>                      
                            </tr>
                            <tr>
                            <td><span class="leftstyle">Adequacy of reserve ? <span class="mandatorycolor">*</span></span></td>
                                <td class="pr-1">
                                    <v-select  
                                        dense 
                                        solo 
                                        class="forminput reservefield selectstyle mt-1 ml-6"
                                        v-model="viewClosedFileObj.adherenceToTat.adequacyOfReserve"
                                        :items="adequecyOfReserveArr"
                                        :placeholder="viewMode? '' : 'Enter'"
                                        :background-color="viewMode? '#f5f5f5' : ''"
                                        :class="viewMode? 'forminput textDisable font-weight-bold' : 'forminput'"
                                        :disabled="viewMode"
                                        :rules="rules.mandatoryrule">
                                    </v-select>
                                </td>                     
                            </tr>
                            <tr class="v-data-table__empty-wrapper" v-if="adherenceDetails.length === 0">
                                <td colspan="12">No Records For Adherence</td>
                            </tr>
                        </tbody>
                    </template>    
                        </v-simple-table>
                    </template>
                     <v-layout>
                        <v-flex>
                            <p class="ml-10 mb-1 claimlabel">Comments: <span class="mandatorycolor">*</span></p>
                            <v-textarea
                                v-model="viewClosedFileObj.adherenceToTat.comments"
                                color="black"
                                solo
                                dense
                                class="px-10 forminput"
                                rows="3"
                                :maxLength="500"
                                :placeholder="viewMode? '' : 'Enter'"
                                :background-color="viewMode? '#f5f5f5' : ''"
                                :class="viewMode? 'forminput textDisable font-weight-bold' : 'forminput'"
                                :disabled="viewMode"
                                :rules="rules.mandatoryrule">
                            </v-textarea>
                        </v-flex>
                    </v-layout>
    
                    <div class="text-center graybackground pdct-stUp-bnkAcc-pgn mt-3" v-if="this.adherenceDetails.length !== 0">
                        <v-row>
                            <v-col cols="6"></v-col>
                            <v-col cols="6">
                                <v-row class="paginationrow">
                                    <v-spacer></v-spacer>
                                    <div style="" class="mt-4">
                                        <span class="orangetext">Showing {{ pageStartIndex }} - {{ pageEndIndex }}</span>
                                        <span> out of {{ totalListCount }} records </span>
                                    </div>
                                    <v-pagination
                                        class="mr-4 mt-1 navpagenumber"
                                        v-model="page"
                                        :length="getNoOfPages"
                                        :total-visible="5"
                                        color="#152F38"
                                        @input="NextPage"
                                    ></v-pagination>
                                </v-row>
                            </v-col>
                        </v-row>
                        <br />
                    </div>                
                </v-flex> -->

                <v-flex class="ml-5 mr-8" :class="triggerDetails ? 'mb-3' : 'mb-5'">
                    <v-row class="groupRowHeadNewLable mt-0">
                        <v-col align="left" class="mt-0 pt-2">
                            <v-flex class="ml-0 GroupNameNewLable">Trigger Details</v-flex>
                        </v-col>
                        <v-col align="right" class="pt-1 mt-0">
                            <v-icon class="mr-2 viconfont" dark @click="triggerDetails = !triggerDetails">
                                {{ triggerDetails == true ? "mdi-minus-box" : "mdi-plus-box" }}
                            </v-icon>
                        </v-col>
                    </v-row>
                </v-flex>
                <v-flex v-if="triggerDetails" class="pt-3 borderStyle">
                <v-data-table
                :items="viewClosedFileObj.triggerPoints"
                :headers="triggerDetail"
                hide-default-footer
                dense
                class="mx-5 mb-5 vDataTableStyling">
                    <template v-slot:[`item.date`]="{ item }">
                        <div>
                            {{ formatDateTime(item.date) }}
                        </div>
                    </template>
                </v-data-table>
                </v-flex>
          <!-- cancel and submit  code-->
            <v-row class="mt-9 mx-1">
                <v-col align="left" class="px-1">
                    <v-btn width="100" class="btnstyle ml-3 reset" v-if="this.viewClosedFileObj.cfrStatus !== false || this.viewClosedFileObj.cfrStatus === 'Closed' " :disabled="viewMode"  @click="clear()">
                        <v-icon class="mr-2 iconsize">mdi-backspace-outline</v-icon>
                        <div class="vertical_line"></div>Reset
                    </v-btn>
                    <v-btn  width="100" class="btnstyle ml-3 mr-3 reset" @click="cancel()">
                        <v-icon class="mr-2 iconsize">mdi-backspace-outline</v-icon>
                        <div class="vertical_line"></div>Cancel
                    </v-btn>
                </v-col>
                <v-col align="right">
                    <v-btn color="#23B1A9" width="100"  dark class="btnstyle mr-2" @click="submit(false)" :disabled="viewMode">
                        <v-icon class="mr-2 iconsize">mdi-content-save</v-icon>
                        <div class="vertical_line"></div>Save
                    </v-btn>
                    <v-btn color="#23B1A9" width="100"  dark class="btnstyle mr-2" @click="submit(true)" :disabled="viewMode">
                        <v-icon class="mr-2 iconsize">mdi-arrow-right-circle</v-icon>
                        <div class="vertical_line"></div>Submit
                    </v-btn>
                    
                </v-col>
            </v-row>
            </v-flex>
            
        </v-flex>  
        
        
        <v-row justify="center">
        <v-dialog v-model="examinerDialog" persistent max-width="40%" style="border-radius:0;max-height:80%">
            <v-card>
            <v-card-title class="pl-0 px-0 py-0 dailogboxAllvcardTableBackground vcardTextTableBackground">
                <v-row class="colorbg">
                <span class="descrptionSpan mb-2 ml-8">Re-assign To</span>
                <v-icon @click="examinerDialog = false" class="ml-4 mt-2" color="#fff">mdi-window-close</v-icon>
                </v-row>
                <v-card-text class="vcardTextTableBackground">
                <v-form ref="examinerassignref">
                    <v-autocomplete  
                    label="Select" 
                    v-model="examiner" 
                    :search-input.sync="searchExaminer" 
                    :items="examiners" 
                    item-value="employeeName" 
                    item-text="employeeName" 
                    :rules="[(v) => requiredField(v)]" 
                    @change="changeExaminer"
                    dense 
                    solo
                    class="form-control" 
                    clearable
                    ></v-autocomplete>
                    <v-layout justify-end>
                    <v-btn small color="red" outlined @click="examinerDialog=false" class="mr-3">
                        <v-icon> mdi-close-circle </v-icon>
                        <v-divider class="mx-2" vertical></v-divider>
                        <span class="subheading">Close</span>
                    </v-btn>
                    <v-btn small color="red" outlined @click="resetExaminer()" class="mr-3">
                        <v-icon> mdi-refresh </v-icon>
                        <v-divider class="mx-2" vertical></v-divider>
                        <span class="subheading">Reset</span>
                    </v-btn>
                    <v-btn @click="assignExaminer()" small color="teal white--text" class="mr-3">
                        <v-icon> mdi-plus </v-icon>
                        <v-divider class="mx-2" vertical color="white--text"></v-divider>
                        <span class="subheading">Re-Assign</span>
                    </v-btn>
                    </v-layout>
                </v-form>
                </v-card-text>
            </v-card-title>

            </v-card>
        </v-dialog>
        </v-row>
    </v-form>
</template>

<script>
import moment from "moment";
    export default {
        data(){ 
            return{
                triggerDetail:[
                    {text:"Trigger Code",value:"code"},
                    {text:"Trigger Description",value:"description"},
                    {text:"Triggered At",value:"date"},
                ],
                pratice:{},
                pageNo:'',
                validationmessage: "This field is required",
                rules: {
                    mandatoryrule: [v => !!v || this.validationmessage]
                },
                claimOpenedMenu1:false,
                claimOpenedDate: moment().format("YYYY-MM-DD"),
                viewClosedFileObj: {
                    claimNumber: "",
                    featureNumber: "",
                    dateOfLoss: "",
                    claimNumber: "",
                    claimStatus: "",
                    consumerFirstName: "",
                    consumerLastName: "",
                    consumerPhoneNumber: "",
                    productName: "",
                    examinerName: "",
                    totalIndemnityPaid: 0,
                    totalExpensePaid: 0,
                    reviewedBy: "",
                    cfrStatus: "",
                    netScore: "",
                    cfrReviewedDate: "",
                    coverage: {
                    coverEndDate: "",
                    coverGroupIndex: "",
                    coverStartDate: "",
                    nolCode: "",
                    perilName: "",
                    period: "",
                    riskCoverGroup: "",
                    riskSerialNumber: "",
                    riskSiComponent: "",
                    si: "",
                    policyStartDate: "",
                    policyEndDate: "",
                    assignTo: {"ntidUserId":"","employeeName":"","userId":"","type":""},
                    },
                    examiner: {
                    employeeName: "",
                    ntid: "",
                    type: "",
                    userId: ""
                    },
                    //fnlo menu
                    fnol: {
                        coverageConfirmedOnTime: "",
                        firstContactMadeOnTime: "",
                        initialCommToInsuredSentOnTime: "",
                        recorded: "",
                        // ckycCompliant: "", //TPM-205
                        amlCompliant: "",
                        adequateInitialReserveCreatedApproved: "",
                        comments:"",
                    },
                    //Assesment menu
                    assessment: {
                        claimCauseAnalysisDone: "",
                        claimCalculationDone: "",
                        adequateDocCollected: "",
                        servicesOfExtInvUtilized: "",
                        // docInwardedOnTime: "", //TPM-206
                        adequateToAmountSettled: "",
                        appointmentOfInvDoneOnTime: "",
                        meaningfullInstGivenToExtInv: "",
                        comments:"",
                    },
                    // Documetnation
                    documentation: {
                        docInwardedOnTime: "",
                        reserveUpdateOnTime: "",
                        examinerReviewClaimTimely: "",
                        followUp_review: "",
                        ckycCompliant: "",
                        anyRedFlag: "",
                        ckycCompliant: "",
                        paymentPostedwithinTAT: "",
                        approvalTakenfromAuthority: "",
                        docUploadedInSys: "",
                        comments:"",
                    },
                    //overall rating
                    overallRating: {
                        insuredInformedAboutSettlement: "",
                        overallhandlingofClaimMeet: "",
                        examinerProactiveInexpeditingClaim: "",
                        overallEvaluationOfClaimFileHandling: "",
                        comments:"",
                    },
                    
                    
                    totalIndemnityPaid : 0,
                    totalExpensePaid : 0,
                    litigationType : "",
                    reserveAmount : "",
                    sumInsured : "",
                    cfrStatus : "",
                    // reviewedBy: "",
                    // firstContact : "",
                    // followUpReminders : "",
                    // reasonForPendency : "",
                    // reserve : "",
                    adequecyOfReserve : "",
                    dateOfLossmenu:false,
                    dateOfLoss:null,
                    disableDateTime: false,

                    paidDateMenu:false,
                    paidDate:null,
                    disablePaidDate:false,

                    markOfDateMenu:false,
                    markOffDate:null,
                    disableMarkOffDate:false,

                    ofrDateMenu:false,
                
                    ofrDate: moment().format("YYYY-MM-DD"),

                    claimOpenedDateMenu:false,
                    claimOpenedDate:null,
                    disableClaimOpened:null,

                },

                fnolArr: ["Yes", "No", "NA"],
                assessmentArr: ["Yes", "No", "NA"],
                docsArr: ["Yes", "No", "NA"],
                ratingArr: ["Yes", "No", "NA"],
            
                viewMode:false, //for view mode
                modify:false,

                openFile_Menu:true,
                fafc:true,
                assessment:true,
                dfm:true,
                overallRating:true,
                triggerDetails:true,

            
                todaydate:moment().format('YYYY-MM-DD'),  
                
                adherenceDetails:[],
                reasonForArr:["Documents not provided by Customer","Insured not provided time","Part Availability Issue","Others"],
                examinerDialog: false,
                searchExaminer: '',
                examiners: [],
                examiner: '',
                examinerObj: {
                    id:"",
                    assignTo: {
                    ntidUserId: "",
                    employeeName: "",
                    userId: "",
                    type: ""
                    }
                },
            }
            },
    methods:{
        getFormattedDate(date) {
            if(date === "" || date === null) return "";
            return moment(date).format("DD-MM-YYYY");
        },
        formatDateAndTime(date){
            if(date && moment(date, "DD/MM/YYYY", true).isValid()) {
                return date ? moment.utc(date, "DD/MM/YYYY").format("DD-MM-YYYY HH:mm:ss") : "";
            } else {
                return date ? moment.utc(date).format("DD-MM-YYYY HH:mm:ss") : "";
            }
        },
        isNumber(evt) {
            evt = evt ? evt : window.event;
            var charCode = evt.which ? evt.which : evt.keyCode;
            if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                evt.preventDefault();
            }
            return true;
        },

        onlyAlphaNumeric(e) {
            var code = "charCode" in e ? e.charCode : e.keyCode;
            if (
                !(code > 47 && code < 58) && // numeric (0-9)
                !(code > 64 && code < 91) && // upper alpha (A-Z)
                !(code > 96 && code < 123)
            ) {
                // lower alpha (a-z)
                e.preventDefault();
            }
        },
        cancel(){
            this.showConfirm("Confirmation","Are you sure you want to leave?","Yes","No",(Yes) => {
                this.showProgress();
                this.$router.go(-1);//params:{pageNo:this.pageNo}}
                this.hideProgress();
            })
        },
        clear(){
                this.showConfirm("Confirmation","Are you sure you want to clear?","Yes","No",(Yes) => {
                    this.viewClosedFileObj.fnol.coverageConfirmedOnTime = "",
                this.viewClosedFileObj.fnol.firstContactMadeOnTime = "",
                this.viewClosedFileObj.fnol.initialCommToInsuredSentOnTime = "",
                this.viewClosedFileObj.fnol.recorded = "",
                // this.viewClosedFileObj.fnol.ckycCompliant = "", //TPM-205
                this.viewClosedFileObj.fnol.amlCompliant = "",
                this.viewClosedFileObj.fnol.adequateInitialReserveCreatedApproved ="",
                this.viewClosedFileObj.fnol.comments = "",
                this.viewClosedFileObj.assessment.claimCauseAnalysisDone = "",
                this.viewClosedFileObj.assessment.claimCalculationDone = "",
                this.viewClosedFileObj.assessment.adequateDocCollected = "",
                this.viewClosedFileObj.assessment.servicesOfExtInvUtilized = "",
                // this.viewClosedFileObj.assessment.docInwardedOnTime = "", // TPM-206
                this.viewClosedFileObj.assessment.adequateToAmountSettled = "",
                this.viewClosedFileObj.assessment.appointmentOfInvDoneOnTime ="",
                this.viewClosedFileObj.assessment.meaningfullInstGivenToExtInv ="",
                this.viewClosedFileObj.assessment.comments = "",
                this.viewClosedFileObj.documentation.docInwardedOnTime = "",
                this.viewClosedFileObj.documentation.reserveUpdateOnTime = "",
                this.viewClosedFileObj.documentation.examinerReviewClaimTimely ="",
                this.viewClosedFileObj.documentation.followUp_review = "",
                this.viewClosedFileObj.documentation.anyRedFlag = "",
                this.viewClosedFileObj.documentation.ckycCompliant = "",
                this.viewClosedFileObj.documentation.paymentPostedwithinTAT ="",
                this.viewClosedFileObj.documentation.approvalTakenfromAuthority ="",
                this.viewClosedFileObj.documentation.docUploadedInSys = "",
                this.viewClosedFileObj.documentation.comments = "",
                this.viewClosedFileObj.overallRating.insuredInformedAboutSettlement ="",
                this.viewClosedFileObj.overallRating.overallhandlingofClaimMeet ="",
                this.viewClosedFileObj.overallRating.examinerProactiveInexpeditingClaim ="",
                this.viewClosedFileObj.overallRating.overallEvaluationOfClaimFileHandling ="";
                this.viewClosedFileObj.overallRating.comments = "",
                this.$refs.closedFileForm.resetValidation();    
            })
        },
        submit(submit){
            if (this.$refs.openFileForm.validate()) {
                this.showConfirm("Confirmation",`Are you sure you want to ${submit ? 'submit' : 'save'}?`,"Yes","No",(Yes) => {
                    this.viewClosedFileObj.dateOfLoss=this.pratice.dateOfLoss
                    this.viewClosedFileObj.dateCreated=this.pratice.dateCreated
                    this.viewClosedFileObj.dateReported=this.pratice.dateReported
                    this.viewClosedFileObj.dateClosed=this.pratice.dateClosedy
                    this.viewClosedFileObj.Date=this.pratice.Date
                    this.viewClosedFileObj.policyStartDate=this.pratice.policyStartDate
                    this.viewClosedFileObj.policyEndDate=this.pratice.policyEndDate
                    this.viewClosedFileObj.cfrStatus=submit
                    // if(!this.pratice.cfrStatus){
                    //             this.viewClosedFileObj.cfrStatus=true
                    //         }
                    let requestData = this.viewClosedFileObj
                    this.PUTM("updateCfr",this.$route.params.item._id,requestData, (res) => {
                        if(res.data.statusCode == 200){
                            this.showAlert(res.data.msg, "Ok", "", () => {
                            this.$router.go(-1)//push({name:"CommonCFRSearch"}) 
                        });
                        
                        }else{
                            this.showAlert(res.data.msg)
                        }
                        this.hideProgress();
                    },(error) => {
                        this.hideProgress()
                        if(error.response.data.msg){
                            this.showAlert(error.response.data.msg);
                        }else if(error.response.data.message.msg){
                            this.showAlert(error.response.data.message.msg);
                        }else if(error.response.data.message){
                            this.showAlert(error.response.data.message);
                        }else{
                            this.showAlert("Something went wrong");
                        }
                    }, true)
                }) 
            } else {
                this.showAlert("Please enter mandatory fields");
            }                 
                
        },
        formatDateTime(date) {
            return date && date !== ""
                ? moment.utc(date).local().format("DD-MM-YYYY hh:mm A")
                : "-";
        },

        getExaminerList() {
            let request = "pageNo=1&perPage=50&active=true&role=Examiner";
            this.GETM(
                "SERVICE_PROVIDER_EXAMINER_LIST",
                request,
                (res) => {
                if (res.data && res.data.data && res.data.data.data) {
                    this.examiners = res.data.data.data;
                }
                },
                (error) => {
                console.log(error?.response)
                },
                true
            );
        },

        assignExaminer() {
            if(this.$refs.examinerassignref.validate()) {
                this.POSTM(
                "POST_REASSIGN_EXAMINER_CFR",
                this.examinerObj,
                (res) => {
                    this.hideProgress();
                    if (res.data && res.data.statusCode == 200) {
                    this.showAlert(res?.data?.msg || "Success", "", "", () => {
                        this.examinerDialog = false;
                        this.viewClosedFileObj.assignTo = this.examinerObj.assignTo
                    });
                    } else {
                    this.showAlert(res.data.msg);
                    }
                },
                (err) => {
                    this.hideProgress();
                    let msg = err?.response?.data?.msg || "Something went wrong.";
                    this.showAlert(msg);
                }, 'Travel'
                );
            } else {
                this.showAlert('Please select examiner');
            }
        },

        changeExaminer(value) {
            let newObj = this.examiners.find((d) => d.employeeName == value);
            this.examinerObj.id = this.viewClosedFileObj._id
            this.examinerObj.assignTo.ntidUserId = newObj.ntidUserId
            this.examinerObj.assignTo.employeeName = newObj.employeeName
            this.examinerObj.assignTo.userId = newObj._id
            this.examinerObj.assignTo.type = newObj.typeOfEmployee
        },

        resetExaminer() {
            this.examiner = '';
            this.$refs.examinerassignref.resetValidation();
        },
    },
        created(){
            this.$store.commit("SAVE_PAGE_HEADER", "CFR Details");
            if(Object.keys(this.$route.params).length !== 0){
            this.viewMode = this.$route.params.isView  
            this.pratice=Object.assign({},this.$route.params.item)
            console.log(this.pratice)
            console.log(this.$route.params.item)
        this.viewClosedFileObj=this.$route.params.item
        let a=this.$route.params.item
            this.viewClosedFileObj.dateOfLoss=this.getFormattedDate(a.dateOfLoss)
            this.viewClosedFileObj.dateReported=this.getFormattedDate(a.dateReported)
            this.viewClosedFileObj.dateCreated=this.getFormattedDate(a.dateCreated)
            this.viewClosedFileObj.dateClosed=this.getFormattedDate(a.dateClosed)
            this.viewClosedFileObj.Date=this.getFormattedDate(a.Date)
            this.viewClosedFileObj.policyStartDate=this.formatDateAndTime(a.policyStartDate)
            this.viewClosedFileObj.policyEndDate=this.formatDateAndTime(a.policyEndDate)
            this.viewClosedFileObj.cfrStatus=a.cfrStatus?"Closed":"Open" 
            this.getExaminerList();
            }
        },
        computed: {
                checkNetScore(){
                let total = 0, points = 0;
                for(let v in this.viewClosedFileObj.fnol){
                    if(v != "comments"){
                        if(this.viewClosedFileObj.fnol[v] == "Yes"){
                            total += 1
                            points += 1
                        }else if(this.viewClosedFileObj.fnol[v] == "No"){
                            total += 1
                        }
                    }
                }
                for(let v in this.viewClosedFileObj.assessment){
                    if(v != "comments"){
                        if(this.viewClosedFileObj.assessment[v] == "Yes"){
                            total += 1
                            points += 1
                        }else if(this.viewClosedFileObj.assessment[v] == "No"){
                            total += 1
                        }
                    }
                }
                for(let v in this.viewClosedFileObj.documentation){
                    if(v != "comments"){
                        if(this.viewClosedFileObj.documentation[v] == "Yes"){
                            total += 1
                            points += 1
                        }else if(this.viewClosedFileObj.documentation[v] == "No"){
                            total += 1
                        }
                    }
                }
                for(let v in this.viewClosedFileObj.overallRating){
                    if(v != "comments"){
                        if(this.viewClosedFileObj.overallRating[v] == "Yes"){
                            total += 1
                            points += 1
                        }else if(this.viewClosedFileObj.overallRating[v] == "No"){
                            total += 1
                        }
                    }   
                }
                //console.log("points", total ? points / total : 0)
                let x = total ? points / total : 0
                this.viewClosedFileObj.netScore=Number(x.toFixed(3))
                return  Number(x.toFixed(3));
            }
        },

       

     
     
    }
</script>

<style scoped>
    .viewClr{
        background-color: white;
        border:1px solid f5f5f5;
        color:f5f5f5;
    }
    .graybackground {
        /* background-color: #e7e7e7; */
        height: 45px;
        font-size:12px
    }

    .orangetext {
        color: #E46A25;
    }

    .colorbg{
        background-color: #23b1a9;
        color:#fff;
    }

    .descrptionSpan {
        width: 85%;
        margin-top: 21px;
        font-weight: bold;
        font-size: 18px;
        letter-spacing: 0.4px;
    }
    
    .dailogboxAllvcardTableBackground {
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 5px 7px #00000029;
        opacity: 1;
        font-size: 14px;
    }

    .vcardTextTableBackground {
        background-color: #b4b4b440 !important;
        /* box-shadow: inset 0px 3px 4px 0px #00000047; */
    }
 

    .borderStyle{
        border:0.5px solid #d7d7d7;
        margin:0 21px 3px 8px;
    }

    .btnstyle{
        height: 33px !important;
        text-transform: capitalize;
        font-size: 11px !important;
        letter-spacing: 0.5px;
    }

    .reset{
        background-color: white;
        border:1px solid #D1121B;
        color:#D1121B;
    }

    .paginationrow{
        margin-right: 10px;
        /* margin-top: 10px;  */
        align-items: center
    }

    /* .borderStyle{
        border:0.5px solid #d7d7d7;
    } */

    .theadstyle{
        width: 100%;
        padding-left: 40px;
        padding-right: 40px;
        
    }
    .selectstyle{
        width: 50%;
        /* position:static; */
        width: 276px;
        
        
    }
    .reservefield.v-text-field.v-text-field--enclosed .v-text-field__details{
        display: none !important;
    }
    .leftstyle{
        float:left;
        padding-left: 20px;
    }
    .iconsize{
        font-size: 18px !important;
    }
     .contactbtn{
        border-radius: 0;
        border: 0.5px solid #d9d9d9;
        text-transform: capitalize;
        letter-spacing: normal;
    }
.form-groups{
    max-width:290px;
    margin-right: 10px;
}
.groupRowHeadNewLable {
    margin-top: 16px;
    background-color: #01bbd9;
    height: 35px;
  }
  .GroupNameNewLable{
    font-size: 12px;
    color: white;
    font-weight: bold;
	letter-spacing: 0.2px;
  }
</style>