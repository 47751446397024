<template>
    <v-app>
        <v-container class="containerStyle" style="padding:1.5rem; margin:0; max-width:100%">
            <h3>{{top_Heading}} Service Provider Master</h3>
            <v-form ref="create_Service_Provider" lazy-validation @submit.prevent="onSubmitHandler">
                <v-expansion-panels v-model="panels" multiple>
                    <v-expansion-panel>
                        <v-expansion-panel-header color="#1EBBD9">
                             <span class="page-title-small white--text" >Service Provider Details</span>
                                <template v-slot:actions>
                                    <v-icon class="white--text">
                                    {{panels.indexOf(0) !== -1 ? "mdi-minus-box" : "mdi-plus-box"}}
                                    </v-icon>
                                </template>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-layout wrap class="pt-4 form-groups-container">
                                <v-flex class="form-group">
                                    <label class="form-label">
                                        Service Provider Code
                                    </label>
                                    <v-text-field solo dense class="form-control" disabled placeholder="Enter..." v-model="serviceProvideObj.serviceProviderId"></v-text-field>
                                </v-flex>
                                <v-flex class="form-group">
                                    <label class="form-label">Line Of Business <span class="red--text">*</span></label>
                                    <v-select multiple solo dense class="form-control" :disabled="isView" placeholder="Select" :rules="[(v)=>requiredField(v)]" :items="lob_items" v-model="serviceProvideObj.lob"></v-select>
                                </v-flex>
                                <v-flex class="form-group">
                                    <label class="form-label">Is External Service Provider <span class="red--text">*</span></label>
                                    <v-radio-group row  class="form-control" v-model="serviceProvideObj.isExternalServiceProvider" :disabled="isView" :rules="[(v)=>requiredField(v)]" @change="changeServiceProvideValues">
                                        <v-radio label="YES" value="Yes"></v-radio>
                                        <v-radio label="No" value="No"></v-radio>
                                    </v-radio-group>
                                </v-flex>
                                <v-flex class="form-group">
                                    <label class="form-label"> Service Provider Type <span class="red--text">*</span></label>
                                    <v-select solo dense class="form-control" :disabled="isView" placeholder="Select" :rules="[(v)=>requiredField(v)]" :items="serviceProvider_Items" v-model="serviceProvideObj.serviceProviderType"></v-select>
                                </v-flex>
                                <v-flex class="form-group">
                                    <label class="form-label">Name <span class="red--text">*</span></label>
                                    <v-text-field solo v-if="serviceProvideObj.isExternalServiceProvider=='Yes'||serviceProvideObj.isExternalServiceProvider==''" 
                                    :disabled="isView" :maxlength="138" dense v-model="serviceProvideObj.serviceProviderDetails.employeeName" 
                                    placeholder="Enter..." class="form-control" :rules="[(v)=>requiredField(v)]"></v-text-field>
                                    <v-autocomplete solo dense v-else class="form-control" placeholder="Enter..." :maxlength="138" :search-input.sync="getName" v-model="serviceProvideObj.serviceProviderDetails.employeeName" 
                                    :rules="[(v)=>requiredField(v)]" :disabled="isView" :items="get_name" item-text="employeeName" clearable @click:clear="clearLoginAndName(true)" @keyup="getExaminerName" @change="getLoginDetails"></v-autocomplete>
                                </v-flex>
                                <v-flex class="form-group">
                                    <label class="form-label">
                                        Login User Id 
                                    </label>
                                    <v-autocomplete solo :disabled="isView || (serviceProvideObj.isExternalServiceProvider !== 'Yes' && serviceProvideObj.serviceProviderType !== '')" :items="get_Login" item-text="employeeName" clearable @click:clear="clearLoginAndName(false)"  dense class="form-control" v-model="serviceProvideObj.serviceProviderDetails.userId" :search-input.sync="loginName" @keyup="getLoginDetails" @change="setLoginDetails" placeholder="Enter..."></v-autocomplete>
                                </v-flex>
                                <v-flex class="form-group">
                                    <label class="form-label">Availability At Multiple Location <span class="red--text">*</span></label>
                                    <v-select class="form-control" :disabled="isView" placeholder="Select" :rules="[(v)=>requiredField(v)]" solo dense :items="['Yes','No']" v-model="serviceProvideObj.availablityAtMultipleLocation">
                                        <template v-slot:append>
                                            <v-icon class="blueiconstyle" @click="multipleAddress">mdi-plus</v-icon>
                                        </template>
                                    </v-select>
                                </v-flex>
                                <v-flex class="form-group">
                        <label class="form-label">
                            Address Line 1 <span><strong class="red--text">*</strong></span>
                        </label>
                        <v-text-field
                        solo
                        dense
                        :rules="[(v)=>requiredField(v),(v)=>addressValidation(v)]"
                        v-model="serviceProvideObj.spAddress[0].spAddressLineOne"
                        class="form-control"
                        maxlength="45"
                        :disabled="isView"
                        placeholder="Enter..."
                        >
                        </v-text-field>
                    </v-flex>

                    <v-flex class="form-group">
                        <label class="form-label">
                            Address Line 2 <span><strong class="red--text">*</strong></span>
                        </label>
                        <v-text-field
                        solo
                        dense
                        v-model="serviceProvideObj.spAddress[0].spAddressLineTwo"
                        class="form-control"
                        maxlength="45"
                        :rules="[(v)=>requiredField(v),(v)=>addressValidation(v)]"
                        placeholder="Enter..."
                        :disabled="isView"
                        >
                        </v-text-field>
                    </v-flex>

                    <v-flex class="form-group">
                        <label class="form-label">
                            Address Line 3 <span><strong class="red--text">*</strong></span>
                        </label>
                        <v-text-field
                        solo
                        dense
                        v-model="serviceProvideObj.spAddress[0].spAddressLineThree"
                        class="form-control"
                        maxlength="35"
                        :rules="[(v)=>requiredField(v),(v)=>addressValidation(v)]"
                        placeholder="Enter..."
                        :disabled="isView"
                        >
                        </v-text-field>
                    </v-flex>
                                <v-flex class="form-group">
                                    <!-- <label class="form-label">Pincode <span class="red--text">*</span></label> -->
                                    <PincodeSearch
                        :isDisabled="isView" 
                        :pinCode="serviceProvideObj.spAddress[0].spPincode"
                        @on-change="change_Pincodes" />
                                    <!-- <v-text-field v-else solo dense class="form-control" placeholder="Enter..." v-model="serviceProvideObj.spAddress[0].spPincode" append-icon="mdi-menu-down"></v-text-field> -->
                                </v-flex>
                                <v-flex class="form-group">
                                    <label class="form-label">State <span class="red--text">*</span></label>
                                    <v-text-field solo dense class="form-control" :rules="[(v)=>requiredField(v)]" placeholder="Enter..." disabled v-model="serviceProvideObj.spAddress[0].spState"></v-text-field>
                                </v-flex>
                                <v-flex class="form-group">
                                    <label class="form-label">District <span class="red--text">*</span></label>
                                    <v-text-field solo dense class="form-control" :rules="[(v)=>requiredField(v)]"  placeholder="Enter..." disabled v-model="serviceProvideObj.spAddress[0].spDistrict"></v-text-field>
                                </v-flex>
                                <v-flex class="form-group">
                                    <label class="form-label">City <span class="red--text">*</span></label>
                                    <v-text-field solo dense class="form-control" :rules="[(v)=>requiredField(v)]"  placeholder="Enter..." disabled v-model="serviceProvideObj.spAddress[0].spCity"></v-text-field>
                                </v-flex>
                                <v-flex class="form-group mt-5">
                                    <p class="mb-2 claimlabel"></p>
                                    <v-checkbox
                                        label="Is PAN not available"
                                        v-model="serviceProvideObj.isPanNotAvailable"
                                        @change="checkIsPANAvailable()"
                                        class="px-2 checkboxstyle"
                                        dense
                                        solo
                                        style="font-weight: bold"
                                        color="#23B1A9"
                                        :disabled="isView"
                                    ></v-checkbox>
                                </v-flex>
                                <v-flex class="form-group">
                                    <label class="form-label">PAN Number <span class="red--text">*</span></label>
                                    <v-text-field
                                        solo
                                        dense 
                                        :disabled="isView" 
                                        v-model="serviceProvideObj.panNumber"
                                        :maxlength="10" 
                                        placeholder="Enter..."
                                        @change="serviceProvideObj.isPanNotAvailable ? {} : checkTDSType()"
                                        :rules="[(v)=>requiredField(v), (v) => validatePanNumber(v, 'PAN Number')]" 
                                        :value="computedPAN"
                                        :class="isView ? 'forminput textDisable font-weight-bold' : 'forminput'"
                                         ></v-text-field>
                                </v-flex>
                                <v-flex class="form-group">
                                    <label class="form-label">Aadhar ID (Last 4 Digits)</label>
                                    <v-text-field solo dense class="form-control" :disabled="isView" v-model="serviceProvideObj.aadharId" :rules="[ (v) => matchRegex(v, 'nums'), (v) => validateLength(v, { exact: 4 }, '', 'Field requires 4 digits.')]" :maxlength="4"
                        placeholder="Enter..."></v-text-field>
                                </v-flex>
                                
                                <v-flex class="form-group">
                        <label class="form-label">
                            Geo Location Code
                        </label>
                        <v-text-field
                        solo
                        dense
                        :rules="[(v)=>matchRegex(v,'nums')]"
                        v-model="serviceProvideObj.geoLocationCode"
                        class="form-control"
                        maxlength="50"
                        placeholder="Enter..."
                        :disabled="isView"
                        >
                        </v-text-field>
                    </v-flex>
                                <v-flex class="form-group">
                        <v-layout>
                            <v-flex>
                                <label class="form-label">
                                    STD<span class="red--text">*</span>
                                </label>
                                <v-text-field
                                    class="form-control mr-2"
                                    style="max-width:110px"
                                    solo
                                    dense
                                    :disabled="isView"
                                    v-model="serviceProvideObj.landlineNumber.std"
                                    :rules="[(v)=>matchRegex(v,'nums')]" 
                                    :maxlength="5"
                                    placeholder="..."
                                    prepend-inner-icon=""
                                    
                                    >
                                    <template v-slot:prepend-inner>
                                        <v-icon class="iconstyle">mdi-phone</v-icon>
                                    </template>
                                </v-text-field>
                            </v-flex>

                            <v-flex >
                                <label class="form-label">
                                LandLine Number<span class="red--text">*</span>
                                </label>
                                <!-- <template v-slot:activator="{ on, attrs }">  -->
                                <v-text-field
                                    placeholder="Enter..."
                                    solo
                                    dense
                                    v-model="serviceProvideObj.landlineNumber.number"
                                    :maxlength="7"
                                    :disabled="isView"
                                    :rules="[(v)=>requiredField(v),(v)=>matchRegex(v,'nums'),(v) => validateLength(v, { exact: 7 }, '', 'Field requires 7 digits.')]" 
                                              
                                    class="form-control"
                                    
                                >
                                    <!-- <template v-slot:append>
                                    <v-icon class="blueiconstyle" >mdi-phone-plus</v-icon>
                                    </template> -->
                                </v-text-field>
                            </v-flex>
                        </v-layout>
                    </v-flex>
                    <v-flex class="form-group">
                        <label class="form-label"> GST Frequency Flag <span class="red--text">*</span></label>
                        <v-select solo :rules="[(v)=>requiredField(v)]" :items="gstFrequencyFlag_items" :disabled="isView" dense class="form-control" placeholder="Select" v-model="serviceProvideObj.gstFrequencyFlag"></v-select>
                    </v-flex>
                    <v-flex class="form-group">
                                    <label class="form-label">GST Number <span v-if="serviceProvideObj.gstFrequencyFlag !='NA'" class="red--text">*</span></label>
                                    <v-text-field 
                                    solo
                                     dense
                                      class="form-control" 
                                      :disabled="isView" 
                                      placeholder="Enter..."
                                       :rules="serviceProvideObj.gstFrequencyFlag !='NA' ? [(v)=>validateGSTNumber(v),(v)=>requiredField(v)] : []" 
                                       :maxlength="15" 
                                       v-model="serviceProvideObj.gstinNumber" 
                                       :value="computedGST"
                                       @change="matchPanNo"
                                       ></v-text-field>
                                </v-flex>
                    <!-- </v-layout>
                    <v-layout wrap class="pt-4 form-groups-container"> -->
                    <v-flex class="form-group">
                        <v-layout>
                            <v-flex>
                                <label class="form-label">
                                    ISD<span class="red--text">*</span>
                                </label>
                                <v-text-field
                                    class="form-control mr-2"
                                    style="max-width:110px"
                                    solo
                                    dense
                                    v-model="serviceProvideObj.mobileNumber[0].isd"
                                    :rules="[(v)=>matchRegex(v,'nums')]" 
                                    :maxlength="5"
                                    placeholder="..."
                                    prepend-inner-icon=""
                                    :disabled="isView"
                                    >
                                    <template v-slot:prepend-inner>
                                        <v-icon class="iconstyle">mdi-phone</v-icon>
                                    </template>
                                </v-text-field>
                            </v-flex>

                            <v-flex >
                                <label class="form-label">
                                Mobile Number<span class="red--text">*</span>
                                </label>
                                <!-- <template v-slot:activator="{ on, attrs }">  -->
                                <v-text-field
                                    placeholder="Enter..."
                                    solo
                                    dense
                                    v-model="serviceProvideObj.mobileNumber[0].number"
                                    :maxlength="10"
                                    :disabled="isView"
                                    :rules="[(v)=>requiredField(v),(v)=>matchRegex(v,'nums'),(v) => validateLength(v, { exact: 10 }, '', 'Field requires 10 digits.')]" 
                                              
                                    class="form-control"
                                    
                                >
                                    <template v-slot:append>
                                    <v-icon class="blueiconstyle" @click="mobile_Dialog=!mobile_Dialog" >mdi-phone-plus</v-icon>
                                    </template>
                                </v-text-field>
                            </v-flex>
                        </v-layout>
                        </v-flex>
                            <v-flex class="form-group">
                                <label class="form-label">Email-ID <span class="red--text">*</span></label>
                                <v-text-field solo dense class="form-control" placeholder="Enter..." :disabled="isView" v-model="email_Obj[0].email" :rules="[(v)=>requiredField(v),(v) => validateEmail(v, 'Email ID')]">
                                    <template v-slot:append>
                                        <v-icon class="blueiconstyle" @click="email_Dialog=!email_Dialog">mdi-email-plus</v-icon>
                                    </template>
                                </v-text-field>
                            </v-flex>
                            <v-flex class="form-group">
                                <label class="form-label">Contact Person Name</label>
                                <v-text-field solo dense class="form-control" :maxlength="138" :disabled="isView" placeholder="Enter..." v-model="serviceProvideObj.contactPerson.name" :rules="[(v)=>matchRegex(v,'alphanum')]"></v-text-field>
                            </v-flex>
                            <v-flex class="form-group">
                                <label class="form-label">Contact Person Number</label>
                                <v-text-field solo dense class="form-control" :disabled="isView" placeholder="Enter..." v-model="serviceProvideObj.contactPerson.number"   :rules="[(v)=>matchRegex(v,'nums'),(v) => validateLength(v, { exact: 10 }, '', 'Field requires 10 digits.')]" maxlength="10"></v-text-field>
                            </v-flex>
                            <v-flex class="form-group">
                                <label class="form-label">TDS Type <span class="red--text">*</span></label>
                                <v-text-field solo dense class="form-control" placeholder="Select" :rules="[(v)=>requiredField(v)]" disabled :items="tds_items" v-model="serviceProvideObj.tdsType"></v-text-field>
                            </v-flex>

                            <v-flex class="form-group">
                                    <label class="form-label">IRDA LOB</label>
                                    <v-text-field solo dense class="form-control" :disabled="isView" v-model="serviceProvideObj.irdaLob" :rules="[ (v) => matchRegex(v, 'alphanum')]" 
                        placeholder="Enter..."></v-text-field>
                                    </v-flex>

                            <v-flex class="form-group">
                                <v-checkbox label="Differentiate TDS" class="form-control" v-model="serviceProvideObj.diffTDS" :disabled="isView"></v-checkbox>
                            </v-flex>
                            <v-flex class="form-group" v-show="serviceProvideObj.serviceProviderType=='External Surveyor'||serviceProvideObj.serviceProviderType=='In - House with License'">
                                <label class="form-label">License number <span class="red--text" v-if="serviceProvideObj.serviceProviderType=='External Surveyor'||serviceProvideObj.serviceProviderType=='In - House with License'">*</span></label>
                                <v-text-field  class="form-control" solo dense 
                                :disabled="isView" :rules="serviceProvideObj.serviceProviderType=='External Surveyor'||serviceProvideObj.serviceProviderType=='In - House with License'?[(v)=>matchRegex(v,'alphanum'),(v)=>requiredField(v)]:[]" 
                                v-model="serviceProvideObj.licenseNumber" placeholder="Enter..."></v-text-field>
                            </v-flex>
                            <v-flex class="form-group" v-show="serviceProvideObj.serviceProviderType=='External Surveyor'||serviceProvideObj.serviceProviderType=='In - House with License'">
                  <label class="form-label"
                    >License Registration date <span class="red--text" v-if="serviceProvideObj.serviceProviderType=='External Surveyor'||serviceProvideObj.serviceProviderType=='In - House with License'">*</span>
                   </label>
                  <v-menu
                    v-model="menu"
                    offset-y
                    nudge-top="20"
                    :close-on-content-click="false"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                      v-model="computedLicenseRegistrationdate"
                        placeholder="DD/MM/YYYY"
                        :disabled="isView"
                        dense
                        solo
                        readonly
                        v-on="on"
                        @focus="menu = true"
                        @blur="menu = true"
                        v-bind="attrs"
                        :rules="['External Surveyor', 'In - House with License'].includes(serviceProvideObj.serviceProviderType) ? [(v)=>requiredField(v)] : []"
                        class="form-control"
                        append-icon="mdi-menu-down"
                        prepend-inner-icon="mdi-calendar"
                      >
                      </v-text-field>
                    </template>
                    <v-date-picker
                      no-title
                      :pickerDate.sync="pickerDate"
                      :max=getEndDate
                      v-model="serviceProvideObj.licenselicenseDate"
                      @input="menu = false"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-flex>
                <v-flex class="form-group" v-show="serviceProvideObj.serviceProviderType=='External Surveyor'||serviceProvideObj.serviceProviderType=='In - House with License'">
                  <label class="form-label"
                    >License Expiry date
                   <span class="red--text" v-if="serviceProvideObj.serviceProviderType=='External Surveyor'||serviceProvideObj.serviceProviderType=='In - House with License'">*</span>
                  </label>
                  <v-menu
                    v-model="menu1"
                    offset-y
                    nudge-top="20"
                    :close-on-content-click="false"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                      v-model="computedLicenseExpirydate"
                        placeholder="DD/MM/YYYY"
                        :disabled="isView"
                        dense
                        solo
                        readonly
                        v-on="on"
                        @focus="menu1 = true"
                        @blur="menu1 = true"
                        v-bind="attrs"
                        :rules="['External Surveyor', 'In - House with License'].includes(serviceProvideObj.serviceProviderType) ? [(v)=>requiredField(v)] : []"
                        class="form-control"
                        append-icon="mdi-menu-down"
                        prepend-inner-icon="mdi-calendar"
                      >
                      </v-text-field>
                    </template>
                    <v-date-picker
                      no-title
                      :pickerDate.sync="pickerDate1"
                      :min="serviceProvideObj.licenselicenseDate"
                      v-model="serviceProvideObj.licenseExpiryDate"
                      @input="menu1 = false"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-flex>
                        
                   
                            </v-layout>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                        <v-expansion-panel-header color="#1EBBD9">
                             <span class="page-title-small white--text" >Bank Details</span>
                                <template v-slot:actions>
                                    <v-icon class="white--text">
                                    {{panels.indexOf(1) !== -1 ? "mdi-minus-box" : "mdi-plus-box"}}
                                    </v-icon>
                                </template>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-layout wrap class="pt-4 form-groups-container"> 
                                <v-flex class="form-group">
                                    <v-checkbox label="Bank Details Available" :disabled="isView" @change="checkBankDetails" v-model="serviceProvideObj.bankDetailsAvailable" class="form-control"></v-checkbox>
                                </v-flex>
                                <v-spacer></v-spacer>
                    <div class="me-3 px-2" v-if="cancelledChequeData.length > 0">
                                    <label :disabled="!isOCRUploaded " class="form-label mt-2 text-right"
                                        @click="cancelChequeView">
                                        <div style="color:#0AB417; font: normal normal medium 12px/14px Roboto;cursor:pointer">
                                            <v-icon color="#0AB417">
                                                mdi-eye
                                            </v-icon>
                                            <span class="ml-2">View Cancelled Cheque</span>
                                        </div>
                                    </label>
                                </div>

                                <div>
                    <p v-show="serviceProvideObj.bankDetailsAvailable" class="teal--text mr-8"  style="cursor:pointer" @dragover="dragoverCancel"  @drop="dropCancel" @click="browseCancel" >
                      <v-icon color="#23B1A9" class="mr-3">mdi-tray-arrow-up</v-icon>Upload Cancelled Cheque
                      <v-file-input style="display:none;" v-model="cancelledCheque" :disabled="isView"
                        id="cancelchequeupload" label="" dense solo @change="OCRUpload" 
                        accept="image/png,image/jpeg,application/msword,application/pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        /></p>
                                          
                      </div>
                    </v-layout>
                     <v-layout wrap class="pt-4 form-group-container">
                                <v-layout class="py-4 cancel-cheque-view-port" v-if="cancelledChequeFileShow && cancelledChequeData.length > 0">
                                    <v-flex xs12>
                                        <div class="close-cancell-cheque"
                                            @click="cancelledChequeFileShow = !cancelledChequeFileShow">
                                    <v-icon  color="#23B1A9"
            dark style="cursor: pointer;">mdi-close</v-icon>
                                        </div>
                                        <v-layout justify-center>
                                            <object :data="cancelledChequeData[0].fileBase ? cancelledChequeData[0].fileBase:showCheque" width="1000"
                                                height="300"></object>
                                            <!-- <img height="300" :src="investigatorMasterObj.documents.fileBase" /> -->
                                        </v-layout>
                                        <!-- <v-layout justify-center class="mt-2">
                                            <div class="cancel-cheque-success-btn">OCR was successfully completed.</div>
                                        </v-layout> -->
                                    </v-flex>
                                </v-layout>
                    </v-layout>
                            
                        
                        
                    <v-layout wrap class="pt-4 form-group-container">
                      <v-flex class="form-group" v-show="serviceProvideObj.bankDetailsAvailable">
                        <v-checkbox
                        class="form-control"
                        label="Over Written"
                        v-model="serviceProvideObj.overwritten[0].status"
                        :disabled="!cancelledChequeData.length>0 || isView || serviceProvideObj.overwritten[0].status"
                        type="checkbox"
                        @change="showPopup"
                        >
                          </v-checkbox>
                    </v-flex>
                            <v-flex class="form-group">
                                <label class="form-label">IFSC Code 
                                  <span v-if="serviceProvideObj.bankDetailsAvailable"><strong class="red--text">*</strong></span></label>
                                <v-autocomplete solo dense
                                class="form-control"
                                placeholder="Enter..."
                                :rules="serviceProvideObj.bankDetailsAvailable==true ? [(v)=>requiredField(v)]:[]"
                                v-model="serviceProvideObj.bankDetails.ifscCode"
                                :disabled="!serviceProvideObj.overwritten[0].status||isView"
                                :items="IFSCCodes"
                                item-text="ifscCode"
                                :search-input.sync="ifsc"
                                @keyup="ifscData"
                                @change="ifscchange"
                                :maxlength="11"
                                >
                                </v-autocomplete>
                            </v-flex>

                            <v-flex class="form-group">
                                <label class="form-label">Bank Name <span v-if="serviceProvideObj.bankDetailsAvailable"><strong class="red--text">*</strong></span></label>
                                <v-text-field solo dense
                                class="form-control"
                                placeholder="Enter..."
                                :rules="serviceProvideObj.bankDetailsAvailable==true ? [(v)=>requiredField(v)]:[]"
                                v-model="serviceProvideObj.bankDetails.bankName"
                                disabled
                                >
                                </v-text-field>
                            </v-flex>
                            <v-flex class="form-group">
                                <label class="form-label">Account Holder Name<span v-if="serviceProvideObj.bankDetailsAvailable"><strong class="red--text">*</strong></span></label>
                                <v-text-field solo dense
                                class="form-control"
                                placeholder="Enter..."
                                
                                v-model="serviceProvideObj.bankDetails.accountHolderName"
                                :disabled="!serviceProvideObj.overwritten[0].status||isView"
                                >
                                <!-- :rules="serviceProvideObj.bankDetailsAvailable==true ? [(v)=>requiredField(v)]:[]" -->
                                </v-text-field>
                            </v-flex>
                            <v-flex class="form-group">
                                <label class="form-label">City <span v-if="serviceProvideObj.bankDetailsAvailable"><strong class="red--text">*</strong></span></label>
                                <v-text-field solo dense
                                class="form-control"
                                placeholder="Enter..."
                                v-model="serviceProvideObj.bankDetails.city"
                                :rules="serviceProvideObj.bankDetailsAvailable==true ? [(v)=>requiredField(v)]:[]"
                                disabled
                                >
                                </v-text-field>
                            </v-flex>

                            <v-flex class="form-group">
                                <label class="form-label">Bank Address1 <span v-if="serviceProvideObj.bankDetailsAvailable"><strong class="red--text">*</strong></span></label>
                                <v-text-field solo dense
                                class="form-control"
                                placeholder="Enter..."
                                v-model="serviceProvideObj.bankDetails.addressLineOne"
                                :rules="serviceProvideObj.bankDetailsAvailable==true ? [(v)=>requiredField(v) ,(v) => matchRegex(v, 'alphanum')]:[]"
                                :disabled="!serviceProvideObj.overwritten[0].status && !editBankAddress1 || isView"
                                >
                                </v-text-field>
                            </v-flex>

                            <v-flex class="form-group">
                                <label class="form-label">Bank Address2</label>
                                <v-text-field solo dense
                                class="form-control"
                                placeholder="Enter..."
                                v-model="serviceProvideObj.bankDetails.addressLineTwo"
                                :rules="[(v) => matchRegex(v, 'alphanum')]"
                                :disabled="!serviceProvideObj.overwritten[0].status && !editBankAddress2 || isView"
                                >
                                </v-text-field>
                            </v-flex>

                            <v-flex class="form-group">
                                <label class="form-label">Bank Address3 <span v-if="serviceProvideObj.bankDetailsAvailable"><strong class="red--text">*</strong></span></label>
                                <v-text-field solo dense
                                class="form-control"
                                placeholder="Enter..."
                                v-model="serviceProvideObj.bankDetails.addressLineThree"
                                :rules="[(v) => matchRegex(v, 'alphanum')] "
                                :disabled="!serviceProvideObj.overwritten[0].status && !editBankAddress3 || isView"
                                >
                                </v-text-field>
                            </v-flex>

                            <v-flex class="form-group">
                                <label class="form-label">Branch <span v-if="serviceProvideObj.bankDetailsAvailable"><strong class="red--text">*</strong></span></label>
                                <v-text-field solo dense
                                class="form-control"
                                placeholder="Enter..."
                                v-model="serviceProvideObj.bankDetails.branch"
                                :rules="serviceProvideObj.bankDetailsAvailable==true ? [(v)=>requiredField(v)]:[]"
                                disabled
                                >
                                </v-text-field>
                            </v-flex>

                            <v-flex class="form-group">
                                <label class="form-label">District <span v-if="serviceProvideObj.bankDetailsAvailable"><strong class="red--text">*</strong></span></label>
                                <v-text-field solo dense
                                class="form-control"
                                placeholder="Enter..."
                                v-model="serviceProvideObj.bankDetails.district"
                                :rules="serviceProvideObj.bankDetailsAvailable==true ? [(v)=>requiredField(v)]:[]"
                                disabled
                                >
                                </v-text-field>
                            </v-flex>

                            <v-flex class="form-group">
                                <label class="form-label">State <span v-if="serviceProvideObj.bankDetailsAvailable"><strong class="red--text">*</strong></span></label>
                                <v-text-field solo dense
                                class="form-control"
                                placeholder="Enter..."
                                v-model="serviceProvideObj.bankDetails.state"
                                :rules="serviceProvideObj.bankDetailsAvailable==true ? [(v)=>requiredField(v)]:[]"
                                disabled
                                >
                                </v-text-field>
                            </v-flex>

                            <v-flex class="form-group">
                                <label class="form-label">Pincode <span v-if="serviceProvideObj.bankDetailsAvailable"><strong class="red--text">*</strong></span></label>

                                <v-autocomplete
                                  :items="searchPincode"
                                  :search-input.sync="bankPincode"
                                  item-text="pinCode"
                                  solo
                                  dense
                                  :rules="[(v)=>requiredField(v)]"
                                  :model-value="serviceProvideObj.bankDetails.pincode"
                                  class="form-control"
                                  maxlength="6"
                                  @keyup="fetchPinCodeFromBank"
                                  @change="(v) => fetchAddressByPincode(v)"
                                  placeholder="Start typing to search"
                                >
                                </v-autocomplete>
                            </v-flex>

                            <v-flex class="form-group">
                                <label class="form-label">Bank Account Number <span v-if="serviceProvideObj.bankDetailsAvailable"><strong class="red--text">*</strong></span></label>
                                <v-text-field solo dense
                                class="form-control"
                                placeholder="Enter..."
                                name="password"
                    value=""
                    id="password"
                    @focus="handleType"
                    @blur="handleType"
                    autocomplete="off"
                    @copy.prevent
                    @paste.prevent
                    @click.right.prevent
                                type="password"
                                v-model="serviceProvideObj.bankDetails.accountNumber"
                                :rules="serviceProvideObj.bankDetailsAvailable==true ? [(v)=>requiredField(v),(v)=>matchRegex(v,'alphanum')]:[]"
                                :disabled="!serviceProvideObj.overwritten[0].status||isView"
                                :maxlength="35"
                                >
                                </v-text-field>
                            </v-flex>

                            <v-flex class="form-group">
                                <label class="form-label">Re-Enter Bank Account Number <span v-if="serviceProvideObj.bankDetailsAvailable"><strong class="red--text">*</strong></span></label>
                                <v-text-field solo dense
                                class="form-control"
                                placeholder="Enter..."
                                :rules="serviceProvideObj.bankDetailsAvailable==true ? [(v) => requiredField(v),(v) => matchRegex(v, 'alphanum'), (v) => shouldBeSame(bankAccount, v, 'Mismatch Bank Account Number')]:[]"
                                v-model="serviceProvideObj.bankDetails.reEnterAccountNumber"
                                :maxlength="35"
                                :disabled="!serviceProvideObj.overwritten[0].status||isView"
                                @paste.prevent
                                >
                                </v-text-field>
                            </v-flex>
                            <v-flex class="form-group">
                                <v-checkbox label="Active" class="form-control" v-model="serviceProvideObj.active" :disabled="isView || !isModify"></v-checkbox>
                            </v-flex>
                            <v-flex class="form-group">
                                <v-checkbox label="WatchList" class="form-control" v-model="serviceProvideObj.isWatchlist" :disabled="isView"></v-checkbox>
                            </v-flex>
                            
                            <v-flex class="form-group">
                                <label class="form-label">Account Type <span v-if="serviceProvideObj.bankDetailsAvailable"><strong class="red--text">*</strong></span></label>
                                <v-select solo dense
                                class="form-control"
                                placeholder="Enter..."
                                :items="accountTypes"
                                v-model="serviceProvideObj.bankDetails.accountType"
                                :rules="serviceProvideObj.bankDetailsAvailable==true ? [(v)=>requiredField(v)]:[]"
                                :disabled="isView"
                                >
                                </v-select>
                            </v-flex>
                            <v-flex class="form-group" v-if="!['Recovery Agent'].includes(serviceProvideObj.serviceProviderType)">
                                <label class="form-label">IRDA Category</label>
                                <v-select class="form-control" solo dense :items="irda_items" v-model="serviceProvideObj.irdaCategory" :disabled="isView" placeholder="Select"></v-select>
                            </v-flex>
                            <v-flex class="form-group" v-if="!['Recovery Agent'].includes(serviceProvideObj.serviceProviderType)">
                                <label class="form-label">Surveyor  Category  <span v-if="!['Investigator','Recovery Agent'].includes(serviceProvideObj.serviceProviderType)" class="red--text">*</span></label>
                                <v-select class="form-control"
                                 solo 
                                 dense
                                  :items="supervisor_items" 
                                  :disabled="isView"
                                   v-model="serviceProvideObj.surveyorCategory"
                                    placeholder="Select"
                                    :rules="!['Investigator','Recovery Agent'].includes(serviceProvideObj.serviceProviderType) ? [(v)=>requiredField(v)]: []"
                                    ></v-select>
                            </v-flex>

                            <v-flex class="form-group">
                                <label class="form-label">MSME <span><strong class="red--text">*</strong></span></label>
                                <v-select solo dense
                                class="form-control"
                                placeholder="Enter..."
                                :items="msmes"
                                v-model="serviceProvideObj.msmeAvailable"
                                :rules="[(v) => (!!v || 'This field is required')]"
                                :disabled="isView"
                                >
                                </v-select>
                            </v-flex>

                            <v-flex class="form-group" v-show="serviceProvideObj.msmeAvailable=='Yes'">
                                <label class="form-label">Enterprise classification
                                   <span v-if="serviceProvideObj.msmeAvailable=='Yes'"><strong class="red--text">*</strong></span></label>
                                <v-select solo dense
                                class="form-control"
                                placeholder="Enter..."
                                v-model="serviceProvideObj.msmeDetails.enterpriseClassification"
                                :items="enterpriseclassifications"
                                :rules="serviceProvideObj.msmeAvailable=='Yes' ? [(v)=>requiredField(v)]:[]"
                                :disabled="isView"
                                >
                                </v-select>
                            </v-flex>

                            <v-flex class="form-group" v-show="serviceProvideObj.msmeAvailable=='Yes'">
                                <label class="form-label">Enterprise category <span v-if="serviceProvideObj.msmeAvailable=='Yes'"><strong class="red--text">*</strong></span></label>
                                <v-select solo dense
                                class="form-control"
                                placeholder="Enter..."
                                v-model="serviceProvideObj.msmeDetails.enterpriseCategory"
                                :items="enterprisecategorys"
                                :rules="serviceProvideObj.msmeAvailable=='Yes' ? [(v)=>requiredField(v)]:[]"
                                :disabled="isView"
                                >
                                </v-select>
                            </v-flex>

                            <v-flex class="form-group" v-show="serviceProvideObj.msmeAvailable=='Yes'">
                                <label class="form-label">UAN <span v-if="serviceProvideObj.msmeAvailable=='Yes'"><strong class="red--text">*</strong></span></label>
                                <v-text-field solo dense
                                class="form-control"
                                v-model="serviceProvideObj.msmeDetails.uan"
                                placeholder="Enter..."
                                :rules="serviceProvideObj.msmeAvailable=='Yes' ? [(v)=>requiredField(v),(v)=>matchRegex(v,'alphanum')]:[]"
                                :disabled="isView"
                                >
                                </v-text-field>
                            </v-flex>

                            
                        </v-layout>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                        <v-expansion-panel-header color="#1EBBD9">
                             <span class="page-title-small white--text" >Upload Documents</span>
                                <template v-slot:actions>
                                    <v-icon class="white--text">
                                    {{panels.indexOf(2) !== -1 ? "mdi-minus-box" : "mdi-plus-box"}}
                                    </v-icon>
                                </template>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-layout wrap class="pt-4 form-group-container">
                  <v-tabs
                    v-model="tab"
                    hide-slider
                    
                  >
                  <!-- active-class="claimProcessingTabsActive" -->
                    <v-tab
                      v-for="item in tab_items"
                      :key="item"
                      @change="documentTab=item;checkTabChanged()"
                      :class="documentTab == item ? 'claimProcessingTabsActive' :'unActive'"
                    >
                    <template v-if="serviceProvideObj.serviceProviderType=='External Surveyor'&& item =='surveyor license'">
                        <p class='mt-3'>{{item}} <span class="red--text">*</span></p>
                    </template>
                    <template v-else>{{item}}</template>
                    
                      <!-- {{ item <span class="red--text" v-if="serviceProvideObj.serviceProviderType=='External Surveyor'||serviceProvideObj.serviceProviderType=='In - House with License'">*</span>}} -->
                    </v-tab>
                  </v-tabs>
                  <v-layout class="mt-5">
                  <div >
                    <div class="neftStyle"   @dragover="dragover" @dragleave="dragleave" @drop="drop" 
                    :class="visualEffect ? 'dragbackground': ''">
                      <v-icon color="#23b1a9" class="iconfont">mdi-tray-arrow-up</v-icon>
                      <span class="ml-2 margincls font-weight-bold" >Drop Files To Upload or <a @click="browse" ><u > Browse</u></a></span>
                      <p class="margincls dlt_text mb-0">Max 5MB of PDF, Word, Excel, Mail or Image(.jpeg, .jpg, .png)</p>
                      <p class="dlt_text mt-0 ml-4" style="font-size:10px">Multiple documents can be uploaded</p>
                      
                    </div>
                    <span></span>
                            <v-file-input
                                multiple
                                v-model="all_attachments"
                                @change="selectFile"
                                accept=".doc, .docx, .csv, image/png,image/jpeg,application/msword,application/pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/csv"
                                id="SP_fileupload"
                                :disabled="isView"
                                style="display: none">
                            </v-file-input>
                            <!-- @change="selectFile" -->
                  </div>
                  <div>
                    <v-data-table
                            :headers="neftHeaders"
                            :items="documentAttachments"                            
                            hide-default-footer
                            no-data-text="No Records for Attachements"
                            class="tabelwidth vDataTableStyling">
                            <template v-slot:[`item.action`] = '{ item, index }'>            
                                <v-icon class="tableicons mr-4" color="#23B1A9" @click="viewDocument(item)">mdi-eye</v-icon>
                                <v-icon class="tableicons mr-4" color="#23B1A9" @click="downloadDoc(item)">mdi-download</v-icon>
                                <v-icon class="tableicons" color="#D1121B" @click="deleteDocument(index)" v-if="!(item.documentId )" >mdi-delete</v-icon>
                            </template>
                        </v-data-table>
                  </div>
                  </v-layout>

                </v-layout>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-form>
            <v-layout class="mt-5">
                <v-btn outlined color="red" class="btn" @click="onReset" v-show="!isView">
                   <v-icon> mdi-backspace </v-icon>
                    <v-divider vertical class="mx-2">
                      </v-divider> Reset</v-btn>
                <v-btn outlined color="red" class="ml-5 btn" @click="cancel">
                   <v-icon> mdi-backspace </v-icon>
                    <v-divider vertical class="mx-2"></v-divider> Cancel</v-btn>
                <v-spacer></v-spacer>
                <v-btn class="btn" color="teal white--text" dense solo v-show="!isView" @click="onSubmit(false)"><v-icon>mdi-arrow-right-circle</v-icon>
          <v-divider vertical class="mx-2"></v-divider>
          Submit</v-btn>
            </v-layout>
        <!-- Dialog box -->
        <v-dialog v-model="address_Dialog" width="1200">
            <v-card>
                <v-card-title class="white--text"
                    style="background-color: #23b1a9">
                    <h4>Add Multiple Address</h4><v-spacer></v-spacer><v-icon class="white--text" @click="address_Dialog=!address_Dialog">mdi-window-close</v-icon>
                </v-card-title>
                <v-card-text>
                    <v-data-table
                    hide-default-footer
                    class="mt-5 vDataTableStyling"
                    :headers="multipleAddress_headers"
                    :items="serviceProvideObj.spAddress"
                    >
                    <template v-slot:[`item.spAddressLineOne`]={item}>
                        <v-text-field solo dense class="form-control" :disabled="isView" v-model="item.spAddressLineOne" placeholder="Enter..."></v-text-field>
                    </template>
                    <template v-slot:[`item.spAddressLineTwo`]={item}>
                        <v-text-field solo dense class="form-control" :disabled="isView" v-model="item.spAddressLineTwo" placeholder="Enter..."></v-text-field>
                    </template>
                    <template v-slot:[`item.spAddressLineThree`]={item}>
                        <v-text-field solo dense class="form-control" :disabled="isView" v-model="item.spAddressLineThree" placeholder="Enter..."></v-text-field>
                    </template>
                    <template v-slot:[`item.spDistrict`]={item}>
                        <v-text-field solo dense class="form-control" v-model="item.spDistrict" placeholder="Enter..." disabled></v-text-field>
                    </template>
                    <template v-slot:[`item.spCity`]={item}>
                        <v-text-field solo dense class="form-control" v-model="item.spCity" placeholder="Enter..." disabled></v-text-field>
                    </template>
                    <template v-slot:[`item.spState`]={item}>
                        <v-text-field solo dense class="form-control" v-model="item.spState" placeholder="Enter..." disabled></v-text-field>
                    </template>
                    <template v-slot:[`item.spCountry`]={item}>
                        <v-text-field solo dense class="form-control" v-model="item.spCountry" placeholder="Enter..." disabled></v-text-field>
                    </template>
                    <template v-slot:[`item.spPincode`]={item,index}>
                        <!-- <v-autocomplete solo dense class="form-control" v-model="item.spPincode" placeholder="Enter..."
                        :items="searchPincode"
                        item-text="pinCode"
                        maxlength="6"
                        :disabled="isView"
                        :search-input.sync="serviceProvideObj.spAddress[serviceProvideObj.spAddress.indexOf(item)].spPincode"
                        @keyup="pincodeSearch(serviceProvideObj.spAddress.indexOf(item))"
                        @change="change_Pincode(serviceProvideObj.spAddress.indexOf(item))"
                        ></v-autocomplete> -->
                        <PincodeSearch
                        :isDisabled="isView" 
                        :pinCode="item.spPincode"
                        :index="index"
                        :label="false"
                        @on-change="change_Pincode" />
                    </template>
                    <template v-slot:[`item.action`]="{index}">
                        <v-icon color="red" @click="deleteSpAddress(index)">mdi-delete</v-icon>
                    </template>
                </v-data-table>
                    <v-layout class="addbtn" justify-center>
                <v-btn
                  @click="add_Address"
                  text
                  elevation="0"
                  :disabled="isView"
                  class="mt-5"
                  style="color: #23B1A9"
                  >+ Add Address</v-btn
                >
              </v-layout>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-model="email_Dialog" width="500">
                <v-card>
                    <v-card-title
                    class="white--text"
                    style="background-color: #23b1a9"
                  >
                  <h4 class="ml-5">Add Multiple Email ID</h4>
        <v-spacer></v-spacer>
        <v-icon @click="email_Dialog=!email_Dialog" class="white--text">mdi-window-close</v-icon>
                </v-card-title>
                <v-card-text class="grey lighten-2">
                    <v-data-table hide-default-footer
                    :headers="email_Headers"
                    :items="email_Obj"
                    class=" vDataTableStyling"
                    >
                   <template v-slot:[`item.email`]="{item}">
                    <v-text-field
                    solo
                    dense
                    class="form-control mt-5"
                    v-model="item.email"
                    :disabled="isView"
                    :rules="[(v) => validateEmail(v, 'Email ID')]"
                    >

                    </v-text-field>
                   </template>
                   <template v-slot:[`item.action`]="{item}">
                    <v-icon left
                      top color="#d1121b" @click="email_Delete(email_Obj.indexOf(item))" :disabled="isView">mdi-delete</v-icon>
                   </template>
                </v-data-table>
                <v-layout class="addbtn" justify-center>
                <v-btn
                
                  @click="email_Add_Row"
                  text
                  elevation="0"
                  :disabled="isView"
                  class="grey lighten-2 mt-5"
                  style="color: #23B1A9"
                  >+ Add Email ID</v-btn
                >
              </v-layout>
                </v-card-text>
                </v-card>
            </v-dialog>

    <!-- Mobile Dialog -->
    <v-dialog v-model="mobile_Dialog" width="500">
                <v-card>
                    <v-card-title
                    class="white--text"
                    style="background-color: #23b1a9"
                  >
                  <h4 class="ml-5">Add Multiple Contact Number</h4>
        <v-spacer></v-spacer>
        <v-icon @click="mobile_Dialog=!mobile_Dialog" class="white--text">mdi-window-close</v-icon>
                </v-card-title>
                <v-card-text class="grey lighten-2">
                    <v-data-table hide-default-footer
                    :headers="contact_Headers"
                    :items="serviceProvideObj.mobileNumber"
                    class=" vDataTableStyling"
                    v-if="serviceProvideObj.mobileNumber.length>0"
                    >
                    <template v-slot:[`item.isd`]="{item}">
                        <v-text-field
                    solo
                    dense
                    :maxlength="3"
                    :disabled="isView"
                    class="form-control mt-5"
                    v-model="item.isd"
                    :rules="[(v) => matchRegex(v, 'nums')]"
                    >

                    </v-text-field>
                    </template>
                   <template v-slot:[`item.number`]="{item}">
                    <v-text-field
                    solo
                    dense
                    :disabled="isView"
                    class="form-control mt-5"
                    :maxlength="10"
                    v-model="item.number"
                    :rules="[(v) => matchRegex(v, 'nums'),(v) => validateLength(v, { exact: 10 }, '', 'Field requires 10 digits.')]"
                    >

                    </v-text-field>
                   </template>
                   <template v-slot:[`item.action`]="{item}">
                    <v-icon left
                      top 
                      color="#d1121b"
                       @click="contact_Delete(serviceProvideObj.mobileNumber.indexOf(item))" 
                       :disabled="isView"
                       >mdi-delete</v-icon>
                   </template>
                </v-data-table>
                <v-layout class="addbtn" justify-center>
                <v-btn
                
                  @click="contact_Add_Row"
                  text
                  elevation="0"
                  :disabled="isView"
                  class="grey lighten-2 mt-5"
                  style="color: #23B1A9"
                  >+ Add Contact Number</v-btn>
              </v-layout>
                </v-card-text>
                </v-card>
            </v-dialog>
        </v-container>
    </v-app>
</template>
<script>
import PincodeSearch from '../../../component/PincodeSearch.vue';
import moment from 'moment'
import {removeSpecialCharacters} from '../../../utils';
export default {
    components:{
        PincodeSearch,
    },
    data(){
        return{
            top_Heading:"Create",
            panels:[0,1,2],
            isView:false,
            //service provider details
            serviceProvider_Items:['External Surveyor', 'Investigator', 'Lawyer/Legal Expert', 'Doctor', 'Frequent Payee', 'Recovery Agent', 'In - House with License', 'In - House without  License', 'SIU', 'SIU Investigator', 'Structural Engineer/expert', 'Drying, Cleaning experts', 'Chartered Engineers', 'Chartered Accountants/Tax', 'Experts', 'Forensic Science experts', 'Forensic accounting experts', 'Drone Operators', 'Other Expert'],
            availablityAtMultipleLocation_Items:['Yes', 'No'],
            address_Dialog:false,
            multipleAddress_headers:[{text:"Address line 1",value:"spAddressLineOne",align:"center",sortable: false,},
        {text:"Address Line 2",value:"spAddressLineTwo",align:"center",sortable: false},
        {text:"Address Line 3",value:"spAddressLineThree",align:"center",sortable: false},
        {text:"Pincode",value:"spPincode",align:"center",sortable: false},
        {text:"District",value:"spDistrict",align:"center",sortable: false},
        {text:"City",value:"spCity",align:"center",sortable: false},
        {text:"State",value:"spState",align:"center",sortable: false},
        {text:"Country",value:"spCountry",align:"center",sortable: false},
        {text:"Action",value:"action",align:"center",sortable: false}],
        searchPincode:[],
        pincode:null,
        mobile_Dialog:false,
        email_Headers:[{text:"Email",value:"email",align:"center"},{text:"Action",value:"action",align:"center"}],
        contact_Headers:[{text:"ISD",value:"isd",align:"center"},{text:"Contact Number",value:"number",align:"center"},{text:"Action",value:"action",align:"center", width:"107px"}],
        email_Obj:[{email:""}],
        email_Dialog:false,
        gstFrequencyFlag_items:['Monthly', 'Quarterly', 'NA', 'Monthly less than 100cr', 'Monthly more than 100cr but less than 500cr', 'Monthly more than 500cr'],
        menu:false,
        menu1:false,
        pickerDate:"",
        pickerDate1:"",
        lob_items:["Health", "Marine", "AIGC", "EW", "Travel", "PA","Home"],
        tds_items:["Association of persons (AOP)", "Body of individuals (BOI)", "Company", "Firm", "Government", "HUF (Hindu undivided family)", "Local authority", "Artificial juridical person", "Individual or Person", "Trust (AOP)", "TDS not Applicable",],
         num:0,   
            //Bank Details
            cancelledChequeFileShow: false,
            cancelledCheque:[],
            cancelledChequeData: [],
            isOCRUploaded: false,
            irdaCategory_Items:['Category A', 'Category B', 'Category C'],
            IFSCCodes:[],
            ifsc:null,
            bankAccount:0,
            accountTypes:['Saving Account','Current Account','Cash Credit'],
            pincode1:null,
            msmes:['Yes','No'],
            irda_items:['Category A', 'Category B', 'Category C'],
            supervisor_items:['License', 'Associate', 'Fellow'],
            enterpriseclassifications:['Proprietorship', 'Hindu undivided family', 'Association of persons', 'Co-operative society', 'Partnership firm', 'Company', 'Individual'],
            enterprisecategorys:['Micro', 'Small', 'Medium'],
            get_name:[],
            get_Login:[],

            //upload Documents
            tab:null,
            tab_items:['NEFT Form','KYC/CKYC','Others','surveyor license'],
            documentTab:"NEFT Form",
            visualEffect: false,
            all_attachments:[],
            neftHeaders:[{text: "Document Name",align: "start",value: "file",sortable: false},
                                {text: "Action",align: "start",value: "action",sortable: false}],
            documentAttachments:[],
            neftAttachment:[],
            otherAttachment:[],
            kycAttachment:[],
            surveyorlicenseAttachments:[],
            showCheque:"",
            getName:null,
            loginName:null,


            serviceProvideObj:{
                lob:[],
                isExternalServiceProvider:"",
                serviceProviderType:"",
                bankDetailsAvailable:false,
                availablityAtMultipleLocation:"",
                spAddress:[{spAddressLineOne:"",spAddressLineTwo:"",spAddressLineThree:"",spCity:"",spDistrict:"",spState:"",spCountry:"",spPincode:""}],
                isPanNotAvailable: false,
                panNumber:"",
                aadharId:"",
                gstinNumber:"",
                geoLocationCode:"",
                tdsType:"",
                irdaLob:"",
                diffTDS:false,
                emailID:[],
                mobileNumber:[{isd:"91",number:""}],
                landlineNumber:{std:"",number:""},
                contactPerson:{name:"",number:""},
                licenselicenseDate:null,
                licenseExpiryDate:null,
                licenseNumber:"",
                
                serviceProviderDetails:{
                    employeeName:"",
                    userId:"",
                    ntid:"",
                },
                bankDetails:{
                    ifscCode:"",
                    bankName:"",
                    accountNumber:"",
                    accountType:"",
                    addressLineOne:"",
                    addressLineTwo:"",
                    addressLineThree:"",
                    branch:"",
                    city:"",
                    district:"",
                    state:"",
                    pincode:"",
                    accountHolderName:"",
                    reEnterAccountNumber:""
                },
                documents:[],
                msmeAvailable:'No',
                msmeDetails:{
                    enterpriseClassification:"",
                    enterpriseCategory:"",
                    uan:""
                },
                active:false,
                approveRequest:true,
                approveStatus:"",
                isDeleted:false,
                isWatchlist:false,
                irdaCategory:"",
                surveyorCategory:"",
                gstFrequencyFlag:"",
                overwritten:[{status:false}],
            },
            isModify: false,

            editBankAddress1:false,
            editBankAddress2:false,
            editBankAddress3:false,
            serviceProviderUsersList: [],
            bankPincode: ''
        }
    },
    methods:{
        fetchAddressByPincode (pinCode) {
            try {
                if (pinCode.length !== 6) {
                    return;
                }

                this.pincode1 = pinCode;

                this.bankDetailsPincode()

            } catch (error) {
                console.log(error);
            }
      },

      fetchPinCodeFromBank () {
        if(this.bankPincode.length < 3) {
          return;
        }
        const reqData = "pageNo=1&perPage=100&pinCode=" + this.bankPincode
        this.GETM(
          "getPincodes",
          reqData,
          (res) => {
            this.searchPincode = res.data.data.data
            console.log(this.searchPincode)
          },(err) => {
            console.error("Issue while fetching pin codes.", err);
          })
      },
        checkIsPANAvailable() {
            this.serviceProvideObj.panNumber = '';
            this.serviceProvideObj.tdsType = this.serviceProvideObj.isPanNotAvailable ? "TDS not Applicable" : "";
            if(this.serviceProvideObj.isPanNotAvailable) {
                this.showAlert("F & A approval won't be triggered & the base rate as per PAN Number Not Available will be applied.");
            }
        },
        checkBankAddress(){
 const specialChars = `\`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~`;

  specialChars.split('').some(specialChar => {
    if (this.serviceProvideObj.bankDetails.addressLineOne.includes(specialChar)) {
      this.editBankAddress1 = true
    }
   
  });

  specialChars.split('').some(specialChar => {
    if (this.serviceProvideObj.bankDetails.addressLineTwo.includes(specialChar)) {
      this.editBankAddress2 = true
    }
  });

  specialChars.split('').some(specialChar => {
    if (this.serviceProvideObj.bankDetails.addressLineThree.includes(specialChar)) {
      this.editBankAddress3 = true
    }
  });
      },


        matchPanNo(){
            let pan = this.serviceProvideObj.panNumber.split('')
            let gst = this.serviceProvideObj.gstinNumber.split('')
            let sliceGst = gst.slice(2,12)
            
            if(this.serviceProvideObj.panNumber !== "" && this.serviceProvideObj.gstinNumber !== "" && pan.toString() !== sliceGst.toString()){
                this.showAlert("PAN Number and GST Number does not match", "" ,"", () => {return})
            }
        },
        showPopup(){
         
            this.showConfirm(
            "Confirmation",
            "Once checked cannot be unchecked. Do you want to proceed?",
            "Yes",
            "No",
            (Yes) => {
                return
            },
            () => {
                this.serviceProvideObj.overwritten[0].status=false
            }
            )
            
        },
        checkTDSType() {
            this.matchPanNo()

            if(this.serviceProvideObj.panNumber && this.serviceProvideObj.panNumber.length > 3) {
                console.log("is calling checkTDS");
                let indexItem = this.serviceProvideObj.panNumber[3];
                console.log("indexItem", indexItem);
                switch (indexItem) {
                    case 'A':
                        this.serviceProvideObj.tdsType = "Association of persons (AOP)";
                        break;
                    case 'B':
                        this.serviceProvideObj.tdsType =  "Body of individuals (BOI)";
                        break;
                    case 'C':
                        this.serviceProvideObj.tdsType = "Company";
                        break;
                    case 'F':
                        this.serviceProvideObj.tdsType = "Firm";
                        break;
                    case 'G':
                        this.serviceProvideObj.tdsType = "Government";
                        break;
                    case 'H':
                        this.serviceProvideObj.tdsType = "HUF (Hindu undivided family)";
                        break;
                    case 'L':
                        this.serviceProvideObj.tdsType = "Local authority";
                        break;
                    case 'J':
                        this.serviceProvideObj.tdsType = "Artificial juridical person";
                        break;
                    case 'P':
                        this.serviceProvideObj.tdsType = "Individual or Person";
                        break;
                    case 'T':
                        this.serviceProvideObj.tdsType = "Trust (AOP)";
                        break;
                    case 'Z':
                        this.serviceProvideObj.tdsType = "TDS not Applicable";
                        break;
                    default:
                        this.showAlert("Please enter a valid PAN Number", "OK", "", () => {
                        });
                        this.serviceProvideObj.panNumber = '';
                        this.serviceProvideObj.tdsType = '';
                        break;
                }
            }
        }, 


        cancel(data){
                  if(data == 1){
                    this.$router.push({name:'SearchServiceProvider'} );
              }else{
              this.showConfirm("Confirmation","Are you sure you want to leave?","Yes","No",(Yes) => {
                    this.showProgress();
                    this.$router.push({name:'SearchServiceProvider'} );//params:{pageNo:this.pageNo}}
                    this.hideProgress();
                })
              }
            },
            checkBankDetails(){
                if(this.serviceProvideObj.bankDetailsAvailable==true){
                 return this.showAlert("Please upload the cancelled cheque.")
                }
                if( this.serviceProvideObj.bankDetailsAvailable==false){
                    this.serviceProvideObj.bankDetails={
                    ifscCode:"",
                    bankName:"",
                    accountNumber:"",
                    accountType:"",
                    addressLineOne:"",
                    addressLineTwo:"",
                    addressLineThree:"",
                    branch:"",
                    city:"",
                    district:"",
                    state:"",
                    pincode:"",
                    accountHolderName:"",
                    reEnterAccountNumber:""
                }
                this.serviceProvideObj.msmeAvailable='No',
                this.serviceProvideObj.msmeDetails={
                    enterpriseClassification:"",
                    enterpriseCategory:"",
                    uan:""
                }
                this.serviceProvideObj.irdaCategory="",
                this.serviceProvideObj.surveyorCategory="",
                this.serviceProvideObj.overwritten=[{status:false}]
                this.pincode1=null,
            this.IFSCCodes=[],
            this.ifsc=null,
            this.bankAccount=0,
            this.cancelledChequeFileShow= false,
            this.cancelledCheque=null,
            this.cancelledChequeData= [],
            this.isOCRUploaded= false
                }
               
  },
        onReset(){
            this.showConfirm("Confirmation","Are you sure you want to Reset?","Yes","No",(Yes) => {
            this.serviceProvideObj.serviceProviderDetails={
                    employeeName:"",
                    userId:"",
                    ntid:""
                },
            this.searchPincode=[],
        this.pincode=null,
            this.pincode1=null,
            this.IFSCCodes=[],
            this.ifsc=null,
            this.tab=null,
            this.bankAccount=0,
            this.cancelledChequeFileShow= false,
            this.cancelledCheque=[],
            this.cancelledChequeData= [],
            this.isOCRUploaded= false,
            this.documentAttachments=[],
            this.neftAttachment=[],
            this.otherAttachment=[],
            this.kycAttachment=[],
            this.surveyorlicenseAttachments=[],
            this.all_attachments=[],
            this.documentTab="NEFT Form",
            this.email_Obj=[{email:""}],
            this.serviceProvideObj.lob="",
            this.serviceProvideObj.isExternalServiceProvider="",
            this.serviceProvideObj.serviceProviderType="",
            this.serviceProvideObj.bankDetailsAvailable=false,
            this.serviceProvideObj.availablityAtMultipleLocation="",
            this.serviceProvideObj.spAddress=[{spAddressLineOne:"",spAddressLineTwo:"",spAddressLineThree:"",spCity:"",spDistrict:"",spState:"",spCountry:"",spPincode:""}],
            this.serviceProvideObj.isPanNotAvailable=false,
            this.serviceProvideObj.panNumber="",
            this.serviceProvideObj.aadharId="",
            this.serviceProvideObj.gstinNumber="",
            this.serviceProvideObj.geoLocationCode="",
            this.serviceProvideObj.tdsType="",
            this.serviceProvideObj.diffTDS=false,
            this.serviceProvideObj.emailID=[],
            this.serviceProvideObj.mobileNumber=[{isd:"",number:""}],
            this.serviceProvideObj.landlineNumber={std:"",number:""},
            this.serviceProvideObj.contactPerson={name:"",number:""}
            this.serviceProvideObj.licenselicenseDate="",
            this.serviceProvideObj.licenseExpiryDate="",
            this.serviceProvideObj.licenseNumber="",
            this.serviceProvideObj.tagicLOB="",
            this.serviceProvideObj.bankDetails={
                    ifscCode:"",
                    bankName:"",
                    accountNumber:"",
                    accountType:"",
                    addressLineOne:"",
                    addressLineTwo:"",
                    addressLineThree:"",
                    branch:"",
                    city:"",
                    district:"",
                    state:"",
                    pincode:"",
                    accountHolderName:"",
                    reEnterAccountNumber:""
                }
                this.serviceProvideObj.documents=[],
                this.serviceProvideObj.msmeAvailable='No',
                this.serviceProvideObj.msmeDetails={
                    enterpriseClassification:"",
                    enterpriseCategory:"",
                    uan:""
                }
                this.serviceProvideObj.active=false,
                this.serviceProvideObj.approveRequest=false,
                this.serviceProvideObj.approveStatus="",
                this.serviceProvideObj.isDeleted=false,
                this.serviceProvideObj.isWatchlist=false,
                this.serviceProvideObj.irdaCategory="",
                this.serviceProvideObj.surveyorCategory="",
                this.serviceProvideObj.gstFrequencyFlag="",
                this.serviceProvideObj.overwritten=[{status:false}]
                const a=new Date().toISOString().split('T')[0]
   this.pickerDate=a
   this.pickerDate1=a
            })
        },
        deleteSpAddress(index){
            this.showConfirm("Confirmation","Are you sure you want to Delete?","Yes","No",(Yes)=>{
                if(this.serviceProvideObj.spAddress.length>1){
                    this.serviceProvideObj.spAddress.splice(index,1)
                }else{
                    this.serviceProvideObj.spAddress=[{spAddressLineOne:"",spAddressLineTwo:"",spAddressLineThree:"",spCity:"",spDistrict:"",spState:"",spCountry:"",spPincode:""}]
                }
                
            })
        },
        contact_Add_Row(){
            this.serviceProvideObj.mobileNumber.push({isd:"91",number:""})
        },
        contact_Delete(index){
           
            if(this.serviceProvideObj.mobileNumber.length == 1){
             return this.showAlert("Atleast one row is mandatory")
            }else{
            this.showConfirm(
          "Confirmation",
          "Are you sure you want to delete?",
          "Yes",
          "No",
          (Yes) => {
                this.serviceProvideObj.mobileNumber.splice(index,1)
          },
          (No) => {}
        )
            }
        },
        email_Add_Row(){
            this.email_Obj.push({email:""})

        },
        email_Delete(index){
            // this.email_Obj.splice(index,1)
            if(this.email_Obj.length == 1){
               return this.showAlert("Atleast one row is mandatory")
            }else{
            this.showConfirm(
          "Confirmation",
          "Are you sure you want to delete?",
          "Yes",
          "No",
          (Yes) => {
            this.email_Obj.splice(index,1)
          },
          (No) => {}
        )
            }
        },
        // pincodeSearch(index){
        //     if(this.pincode.length>3){
        //         let reqData = "pageNo=1&perPage=100&pinCode=" + this.pincode
        // this.GETM(
        //   "getPincodes",
        //   reqData,
        //   (res) => {
        //     let a=res.data.data.data
        //     this.searchPincode=a},(err) => {
        //     console.error("Issue while fetching pin codes.", err);
        //   })
        //     }
        // },
        handleType(event) {
      const { srcElement, type } = event
      const { name, value } = srcElement

      if (type === "blur" && !value) {
        this.fieldTypes[name] = "text"
      } else {
        this.fieldTypes[name] = "password"
      }
    },
        change_Pincode(data,index){
            this.serviceProvideObj.spAddress[index].spPincode=data.pinCode
            this.serviceProvideObj.spAddress[index].spCity=data.cityDistrictName
            this.serviceProvideObj.spAddress[index].spDistrict=data.cityDistrictName
            this.serviceProvideObj.spAddress[index].spState=data.stateName
            this.serviceProvideObj.spAddress[index].spCountry=data.countryName
            
        },
        change_Pincodes(data){
            this.serviceProvideObj.spAddress[0].spPincode=data.pinCode
            this.serviceProvideObj.spAddress[0].spCity=data.cityDistrictName
            this.serviceProvideObj.spAddress[0].spDistrict=data.cityDistrictName
            this.serviceProvideObj.spAddress[0].spState=data.stateName
            this.serviceProvideObj.spAddress[0].spCountry=data.countryName
        },
        add_Address(){
            this.serviceProvideObj.spAddress.push({spAddressLineOne:"",spAddressLineTwo:"",spAddressLineThree:"",spCity:"",spDistrict:"",spState:"",spCountry:"",spPincode:""})
            this.searchPincode=this.searchPincode
        },
        multipleAddress(){
            if(this.serviceProvideObj.availablityAtMultipleLocation == 'Yes'){
                this.address_Dialog=true
            }else{
                this.address_Dialog=false
            }
        },
        changeServiceProvideValues(){
            if(this.serviceProvideObj.isExternalServiceProvider=="Yes"){
               this.serviceProvider_Items=['External Surveyor', 'Investigator', 'SIU', 'SIU Investigator','Lawyer/Legal Expert', 'Doctor', 'Frequent Payee',
                 'Recovery Agent',  'Structural Engineer/expert',
                  'Drying, Cleaning experts', 'Chartered Engineers', 'Chartered Accountants/Tax',
                 'Experts', 'Forensic Science experts', 'Forensic accounting experts', 'Drone Operators', 'Other Expert']
            }else{
                this.serviceProvider_Items=[ 'Lawyer/Legal Expert', 'Doctor',
                 'Recovery Agent', 'In - House with License', 'In - House without  License', 'SIU', 'SIU Investigator','Structural Engineer/expert',
                  'Drying, Cleaning experts', 'Chartered Engineers','Chartered Accountants/Tax', 'Experts', 'Forensic Science experts',
                   'Forensic accounting experts', 'Drone Operators', 'Other Expert']
            }
        },
        dragover(event) {
                event.preventDefault();
                this.visualEffect = true;
            },
    dragleave(event) {
                this.visualEffect = false;
            },
    browse(){ //to select attachments
                document.getElementById("SP_fileupload").click()
            },

    drop(event) {
                event.preventDefault();
                this.all_attachments = event.dataTransfer.files;
                // console.log("all", this.all_attachments);
                 this.selectFile(); // Trigger the onChange event manually
                this.visualEffect = false;
            },
            selectFile(){
                if(this.all_attachments.length !== 0){
                    for(let i = 0; i < this.all_attachments.length; i++){
                        const fsize = this.all_attachments[i].size;
                        const file = Math.round(fsize / 1024);
                        let self = this
                        if (file > 5120) {
                            self.showAlert("File is too big, please select a file less than 5MB");
                        } else {
                            self.readImageM(self.all_attachments[i].name, self.all_attachments[i], (res) => { 
                              console.log(res)
                            var type = [];
                            type = self.all_attachments[i].type
                            console.log('--',self.documentTab)
                            if(self.documentTab == 'NEFT Form'){
                                if(self.neftAttachment.length < 5){
                                    self.neftAttachment.push({ 
                                        file: res.fileName,
                                        fileBase: res.base,
                                        documentType: 'NEFT Form'
                                    })
                                    self.neftAttachment = self.neftAttachment.reduce((unique, o) => {
                                        if(!unique.some(obj => obj.file === o.file)) {
                                            unique.push(o);                                        
                                        }
                                        return unique;
                                    },[]);
                                }else{
                                    self.neftAttachment.splice(0,1)
                                    self.neftAttachment.push({ 
                                        file: res.fileName,
                                        fileBase: res.base,
                                        documentType: 'NEFT Form'
                                    })
                                    self.neftAttachment = self.neftAttachment.reduce((unique, o) => {
                                        if(!unique.some(obj => obj.file === o.file)) {
                                            unique.push(o);                                        
                                        }
                                        return unique;
                                    },[]);
                                }
                                console.log('---neft', self.neftAttachment)
                            }else if(self.documentTab == 'Others'){
                                if(self.otherAttachment.length < 5){
                                    self.otherAttachment.push({ 
                                        file: res.fileName,
                                        fileBase: res.base,
                                        documentType: 'OTHERS'
                                    })
                                    self.otherAttachment = self.otherAttachment.reduce((unique, o) => {
                                        if(!unique.some(obj => obj.file === o.file)) {
                                            unique.push(o);                                        
                                        }
                                        return unique;
                                    },[]);
                                }else{
                                    self.otherAttachment.splice(0,1)
                                    self.otherAttachment.push({ 
                                        file: res.fileName,
                                        fileBase: res.base,
                                        documentType: 'OTHERS'
                                    })
                                    self.otherAttachment = self.otherAttachment.reduce((unique, o) => {
                                        if(!unique.some(obj => obj.file === o.file)) {
                                            unique.push(o);                                        
                                        }
                                        return unique;
                                    },[]);
                                }
                            }else if(self.documentTab == 'surveyor license'){
                                if(self.surveyorlicenseAttachments.length < 5){
                                    self.surveyorlicenseAttachments.push({ 
                                        file: res.fileName,
                                        fileBase: res.base,
                                        documentType: 'surveyorLicense'
                                    })
                                    self.surveyorlicenseAttachments = self.surveyorlicenseAttachments.reduce((unique, o) => {
                                        if(!unique.some(obj => obj.file === o.file)) {
                                            unique.push(o);                                        
                                        }
                                        return unique;
                                    },[]);
                                }else{
                                    self.surveyorlicenseAttachments.splice(0,1)
                                    self.surveyorlicenseAttachments.push({ 
                                        file: res.fileName,
                                        fileBase: res.base,
                                        documentType: 'surveyorLicense'
                                    })
                                    self.surveyorlicenseAttachments = self.surveyorlicenseAttachments.reduce((unique, o) => {
                                        if(!unique.some(obj => obj.file === o.file)) {
                                            unique.push(o);                                        
                                        }
                                        return unique;
                                    },[]);
                                }
                            }else{
                                if(self.kycAttachment.length < 5){
                                    self.kycAttachment.push({ 
                                        file: res.fileName,
                                        fileBase: res.base,
                                        documentType: 'KYC/CKYC'
                                    })
                                    self.kycAttachment = self.kycAttachment.reduce((unique, o) => {
                                        if(!unique.some(obj => obj.file === o.file)) {
                                            unique.push(o);                                        
                                        }
                                        return unique;
                                    },[]);
                                }else{
                                    self.kycAttachment.splice(0,1)
                                    self.kycAttachment.push({ 
                                        file: res.fileName,
                                        fileBase: res.base,
                                        documentType: 'KYC/CKYC'
                                    })
                                    self.kycAttachment = self.kycAttachment.reduce((unique, o) => {
                                        if(!unique.some(obj => obj.file === o.file)) {
                                            unique.push(o);
                                            
                                        }
                                        return unique;
                                    },[]);
                                }
                            }
                            // // Phase-2 function call starts
                            // this.getCancelledChequeData(res.base);
                            // this.manualOverwrite = false;
                            // // Phase-2 function call ends
                            this.checkTabChanged()
                        });
                    }
                }
                // this.all_attachments=[]
                }
            },
    viewDocument(itemData) {
      if (itemData.fileBase) {
        this.viewDocUsingBase(itemData.fileBase);
      } else {
        let requestData = "";
        if (itemData !== null) {
          requestData = "documentId=" + itemData.documentId + "&b64=true";
        }
        this.GETM(
          "VIEW_DOCUMENT",
          requestData,
          (res) => {
            if (res.data.statusCode === 200) {
              this.viewDocUsingBase(res.data.data);
            }
          },
          (error) => {
            console.log("Error", error);
          },
          true
        );
      }
    },
    viewDocUsingBase(base64) {
      const base64ImageData = base64;
      const contentType = base64.split(":")[1].split(";")[0];
      const byteCharacters = window.atob(
        base64ImageData.substr(`data:${contentType};base64,`.length)
      );
      const byteArrays = [];
      for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
        const slice = byteCharacters.slice(offset, offset + 1024);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
      const blob = new Blob(byteArrays, { type: contentType });
      const blobUrl = window.URL.createObjectURL(blob);
      window.open(blobUrl, "_blank");
    },

    downloadDoc(item) {
                if(item.fileBase) {
                    let base64 = item.fileBase;
                    const base64ImageData = base64
                    const contentType = base64.split(':')[1].split(';')[0];
                    const byteCharacters = window.atob(base64ImageData.substr(`data:${contentType};base64,`.length));
                    const byteArrays = [];
                    for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
                        const slice = byteCharacters.slice(offset, offset + 1024);
                        const byteNumbers = new Array(slice.length);
                    for (let i = 0; i < slice.length; i++) {
                        byteNumbers[i] = slice.charCodeAt(i);
                    }
                    const byteArray = new Uint8Array(byteNumbers);
                        byteArrays.push(byteArray);
                    }
                    const blob = new Blob(byteArrays, {type: contentType});
                    const blobUrl =  window.URL.createObjectURL(blob);

                    const element = document.createElement("a");
                    element.href = blobUrl;
                    element.download = item.file;
                    element.click();
                } else {
                    let url = this.getURL("VIEW_DOCUMENT") + "?documentId=" + item.documentId;
                    window.open(url);
                }
            },
            deleteDocument(index) {
               this.showConfirm(
          "Confirmation",
          "Are you sure you want to delete?",
          "Yes",
          "No",
          (Yes) => {
            if(this.documentTab=='NEFT Form'){
                       this.neftAttachment.splice(index, 1);
                 }
                if(this.documentTab=='Others'){
                         this.otherAttachment.splice(index, 1);
                }
                if(this.documentTab=='KYC/CKYC'){
                        this.kycAttachment.splice(index, 1);
                }
                if(this.documentTab=='surveyor license'){
                        this.surveyorlicenseAttachments.splice(index, 1);
                }
                        this.checkTabChanged()
          },
          (No) => {}
        )
                
},
clearLoginAndName(data){
    if(data){
        this.get_name=[]
        this.getName=null
        this.loginName=null
        this.get_Login=[]
        this.serviceProvideObj.serviceProviderDetails.employeeName=""
        this.serviceProvideObj.serviceProviderDetails.ntid = ""
        this.serviceProvideObj.serviceProviderDetails.userId=""
    }else{
        this.loginName=null
        this.get_Login=[]
        this.serviceProvideObj.serviceProviderDetails.ntid = ""
        this.serviceProvideObj.serviceProviderDetails.userId=""
    }
},
getExaminerName(){
    let api = this.serviceProvideObj.serviceProviderType === 'SIU' ? "SERVICE_PROVIDER_INVESTIGATOR_LIST" : "SERVICE_PROVIDER_EXAMINER_LIST"
    let reqData=""
    if(this.serviceProvideObj.isExternalServiceProvider=="Yes"){
        reqData="agreementType=External Resource&pageNo=1&perPage=50&externalResource=true&active=true&smartSearch="+this.loginName
    }else if(this.serviceProvideObj.isExternalServiceProvider=="No"){
        reqData='agreementType=External Resource&pageNo=1&perPage=50&active=true&employee=true&typeOfEmployee=Full Time&employeeName='+this.getName
    }else{
        console.log('service provider not selected')
    }
    this.GETM(api,reqData,(res)=>{
        if(this.serviceProvideObj.isExternalServiceProvider=="Yes"){
            this.get_Login=res.data.data.map((itm)=>{
                  return  itm.employeeName = `${itm.employeeName} - ${itm.employeeId}`
            })
        }else if(this.serviceProvideObj.isExternalServiceProvider=="No"){
            this.get_name=res.data.data
        }
    },(err)=>{
        console.log(err)
    },true)
},
getLoginDetails(){
    console.log(this.loginName)
    let reqData=""
    if(this.serviceProvideObj.isExternalServiceProvider=="Yes"){
        reqData="pageNo=1&perPage=50&externalResource=true&active=true&smartSearch="+this.loginName+`&system=${this.serviceProvideObj.lob}`
    }else if(this.serviceProvideObj.isExternalServiceProvider=="No"&&this.loginName==null){
        var pre=this.serviceProvideObj.serviceProviderDetails.employeeName//this.loginName  this.serviceProvideObj.serviceProviderDetails.employeeName
        reqData='pageNo=1&perPage=50&active=true&employee=true&typeOfEmployee=Full Time&employeeName='+pre+`&system=${this.serviceProvideObj.lob}`
    }
    else{
        
            var pre=this.loginName//this.serviceProvideObj.serviceProviderDetails.employeeName
        reqData='pageNo=1&perPage=50&active=true&employee=true&typeOfEmployee=Full Time&employeeName='+pre+`&system=${this.serviceProvideObj.lob}`
        
        
    }
    this.GETM('SERVICE_PROVIDER_EXAMINER_LIST',reqData,
    (res)=>{
        this.serviceProviderUsersList = res?.data?.data?.data || []
        if(this.serviceProvideObj.isExternalServiceProvider=="Yes"){
            this.get_Login=res.data.data.data.map(data=>{
                this.serviceProvideObj.serviceProviderDetails.ntid = data.ntidUserId
                return data.employeeName=`${data.employeeName} - ${data.employeeId}`
            })
        }else if(this.serviceProvideObj.isExternalServiceProvider=="No"&&this.serviceProvideObj.serviceProviderDetails.employeeName.length>0&&this.loginName==null){
            this.get_Login=res.data.data.data.map(data=>{
                if(this.serviceProvideObj.serviceProviderDetails.employeeName==data.employeeName){
                    this.serviceProvideObj.serviceProviderDetails.userId=`${data.employeeName} - ${data.employeeId}`
                    console.log("##",data.ntidUserId)
                    this.loginName=`${data.employeeName} - ${data.employeeId}`
                    this.serviceProvideObj.serviceProviderDetails.ntid = data.ntidUserId
                }
                 return data.employeeName=`${data.employeeName} - ${data.employeeId}`
            })
        }else{
            this.get_Login=res.data.data.data.map(data=>{
                this.serviceProvideObj.serviceProviderDetails.ntid = data.ntidUserId
                return data.employeeName=`${data.employeeName} - ${data.employeeId}`
            })
        }
        
    },(err)=>{
        console.log(err)
    },true)
},

setLoginDetails(val){
    if(val){
        let employeeId = val.split("-")[1].trim()
        let user = this.serviceProviderUsersList.find(item => item.employeeId === employeeId)
        // this.serviceProvideObj.serviceProviderDetails.employeeName = user.employeeName
        this.serviceProvideObj.serviceProviderDetails.ntid = user.ntidUserId
        this.serviceProvideObj.serviceProviderDetails.userId = user.employeeName

    }
},
ifscData(){
            if(this.ifsc.length>3){
              let request =  this.ifsc+ "?lob=Marine";
              this.GET_ByURLM("GET_GC_BANK",
              request,
              (res) => {
                this.IFSCCodes = [];
                this.hideProgress();
                console.log(res.data.data)
                if (res.data.data && res.data.data.length > 0) {
                  this.IFSCCodes=res.data.data
                }},
                (error) => {
                  this.IFSCCodes = [];
                  this.hideProgress();
                  console.log("error", error);
                  this.showAlert(error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message);
                }, true);
              }
            },
    ifscchange(){
      this.IFSCCodes.forEach(ifsc=>{
        if(this.serviceProvideObj.bankDetails.ifscCode==ifsc.ifscCode){
              let ifscCodeValue = ifsc
              // this.IFSCCodes=res.data.data
              this.serviceProvideObj.bankDetails.branch =ifscCodeValue.branchName
              this.serviceProvideObj.bankDetails.bankName = ifscCodeValue.financierName;
              this.serviceProvideObj.bankDetails.addressLineOne = removeSpecialCharacters(ifscCodeValue.branchAddress);
              let address2 = '';
              if (ifscCodeValue.district) {
                address2 += `${ifscCodeValue.district} `
              }
              if (ifscCodeValue.city) {
                address2 += `${ifscCodeValue.city} `
              }
              if (ifscCodeValue.pincode) {
                address2 += ifscCodeValue.pincode
              }
              this.serviceProvideObj.bankDetails.addressLineTwo = removeSpecialCharacters(address2);
              this.serviceProvideObj.bankDetails.addressLineThree = removeSpecialCharacters(ifscCodeValue.state);
              this.serviceProvideObj.bankDetails.pincode=ifscCodeValue.pincode
              this.bankPincode=ifscCodeValue.pincode
              this.pincode1=ifscCodeValue.pincode
              this.bankDetailsPincode()
        }
       
      })
      
    },


    checkTabChanged(){
      if(this.documentTab=='NEFT Form'){
        this.documentAttachments=this.neftAttachment
      }
      if(this.documentTab=='Others'){
        this.documentAttachments=this.otherAttachment
      }
      if(this.documentTab=='KYC/CKYC'){
        this.documentAttachments=this.kycAttachment
      }
      if(this.documentTab=='surveyor license'){
        this.documentAttachments=this.surveyorlicenseAttachments
      }
    },
    bankDetailsPincode(){
        let reqData = "pageNo=1&perPage=100&pinCode=" + this.pincode1
        this.GETM(
          "getPincodes",
          reqData,
          (res) => {
            let data=res.data.data.data[0]
            this.serviceProvideObj.bankDetails.city = data.cityDistrictName;
          this.serviceProvideObj.bankDetails.state  = data.stateName;
          this.serviceProvideObj.bankDetails.district  = data.cityDistrictName;
          },(err) => {
            console.error("Issue while fetching pin codes.", err);
          })
    
          
  },
  
    OCRUpload() {
        
        console.log(this.cancelledCheque)
            if (this.cancelledCheque) {
                this.cancelledChequeData=[]
                this.cancelledChequeFileShow = false; //hide view port
                this.isOCRUploaded = false; //check if OCR uploaded
                this.serviceProvideObj.overwritten[0].status = false; //check for manual entry
                // this.clearbankDetailsAvailable(); //reset bank fields
                // console.log(this.cancelledCheque)
                if (!this.cancelledCheque.name){
                    return // for while resetting the form
                }
                this.showProgress();
                
                this.readImageM(this.cancelledCheque.name, this.cancelledCheque, (res) => {
                  console.log(res)
                    let requestPayload = {
                        document: res.base,
                    };
                    this.cancelledCheque = []; //reset field value
                    this.POSTM(
                        "GET_OCR_FORMAT",
                        requestPayload,
                        (response) => {
                            this.hideProgress();
                            if (response.data.data.Message == "Uploaded Image/Document orientation is incorrect") {
                                this.showAlert(response.data.data.Message);
                                this.serviceProvideObj.overwritten[0].status = true;
                            } else if (response.data.data.Message == "Invalid Image/Document") {
                                this.showAlert(response.data.data.Message);
                                this.serviceProvideObj.overwritten[0].status = true;
                            } else if (response.data.data) {
                                let bankres = response.data.data;
                                console.log(response.data.data)
                                if (!bankres.AccountNumber || !bankres.IFSC || !bankres.PayerName) {
                                    this.isOCRUploaded = true;
                                    
                                    this.serviceProvideObj.overwritten[0].status = true;
                                    this.showAlert("Could not retrieve data from OCR either re-upload the cancelled cheque or enter the details manually");
                                } else {
                                    
                                    //temprory fix
                                    this.showProgress();
                                    let request = bankres.IFSC + "?lob=Marine";
                                    this.GET_ByURLM(
                                        "GET_GC_BANK",
                                        request,
                                        (res) => {
                                            this.IFSCCodes = [];
                                            this.hideProgress();
                                            console.log(res.data.data)
                                            if (res.data.data && res.data.data.length > 0) {
                                                let ifscCodeValue = res.data.data[0];
                                                this.IFSCCodes = res.data.data;
                                                this.serviceProvideObj.bankDetails.ifscCode = bankres.IFSC;
                                                this.serviceProvideObj.bankDetails.accountNumber=bankres.AccountNumber
                                                this.serviceProvideObj.bankDetails.accountHolderName=bankres.PayerName
                                                //this.checkBankAccount()
                                                this.serviceProvideObj.bankDetails.reEnterAccountNumber=bankres.AccountNumber
                                                this.serviceProvideObj.bankDetails.branch =ifscCodeValue.branchName

                                                this.serviceProvideObj.bankDetails.bankName = ifscCodeValue.financierName;
                                                this.serviceProvideObj.bankDetails.addressLineOne = removeSpecialCharacters(ifscCodeValue.branchAddress);
                                                let address2 = '';
                                                if (ifscCodeValue.district) {
                                                    address2 += `${ifscCodeValue.district} `
                                                }
                                                if (ifscCodeValue.city) {
                                                    address2 += `${ifscCodeValue.city} `
                                                }
                                                if (ifscCodeValue.pincode) {
                                                    address2 += ifscCodeValue.pincode
                                                }
                                                this.serviceProvideObj.bankDetails.addressLineTwo = removeSpecialCharacters(address2);
                                                this.serviceProvideObj.bankDetails.addressLineThree = ifscCodeValue.state;
                                                      this.checkBankAddress()
                                                
                                                this.serviceProvideObj.bankDetails.pincode=ifscCodeValue.pincode
                                                this.bankPincode=ifscCodeValue.pincode
                                                this.pincode1=ifscCodeValue.pincode
                                                this.serviceProvideObj.bankDetails.city =ifscCodeValue.city;
                                                this.serviceProvideObj.bankDetails.state  = removeSpecialCharacters(ifscCodeValue.state);
                                                this.serviceProvideObj.bankDetails.district  = ifscCodeValue.district;
                                                
                                                // this.bankDetailsPincode()
                                                this.showAlert("OCR uploaded successfully");
                                            }
                                        },
                                        (error) => {
                                            this.IFSCCodes = [];
                                            this.hideProgress();
                                            console.log("error", error);
                                            this.showAlert(error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message);
                                        }, true
                                    );
                                    this.cancelledChequeData.push(
                                        {
                                            file: res.fileName,
                                            fileBase: res.base,
                                            documentType: "Cancelled Cheque",   
                                        }
                                    )
                                    
                                    
                                    // this.serviceProvideObj.bankDetailsAccountNumber = bankres.AccountNumber;
                                    // this.reEnterBankAccountNumber = bankres.AccountNumber;
                                    // this.serviceProvideObj.consumerFirstName = bankres.PayerName;
                                    // this.isOCRUploaded = true;
                                }
                            } else {
                                this.showAlert("No data found from OCR");
                            }
                        },
                        (error) => {
                            this.hideProgress();
                            this.serviceProvideObj.overwritten[0].status = true;
                            this.showAlert("Something went wrong in OCR,Please enter the details manually");
                        }, true
                    );
                });
            } else {
                this.showAlert("Please upload a file.")
            }
            this.cancelledCheque=[]
        },
        cancelChequeView() {
            console.log(this.cancelledChequeData)
                this.cancelledChequeFileShow = !this.cancelledChequeFileShow;
                
        },
        dropCancel(event) {
                event.preventDefault();
                this.cancelledCheque = event.dataTransfer.files;
                // console.log("all", this.all_attachments);
                 this.OCRUpload(); // Trigger the onChange event manually
                
            },
            browseCancel(){ //to select attachments
                document.getElementById("cancelchequeupload").click()
            },
            dragoverCancel(event) {
                event.preventDefault();
                
            },
            checkBankAccount(){
        this.bankAccount=this.serviceProvideObj.bankDetails.accountNumber
        this.serviceProvideObj.bankDetails.accountNumber=""
        for(let i=0;i<=this.bankAccount.length-1;i++){
            this.serviceProvideObj.bankDetails.accountNumber+='*'
        }
    },
    viewDocumentCheque(id){
        let requestData = "documentId=" + id + "&b64=true";
        this.GETM(
          "VIEW_DOCUMENT",
          requestData,
          (res) => {
            if (res.data.statusCode === 200) {
              this.showCheque=res.data.data
            }
          },
          (error) => {
            console.log("Error", error);
          },
          true
        );
      },
    
    formatDate(date) {
      if (!date) return "";

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    convertObjToString(){
    //this.serviceProvideObj.bankDetails.accountNumber=this.bankAccount
    this.serviceProvideObj.emailID=this.email_Obj.map(g=> {return g.email})
    this.serviceProvideObj.documents=[ ...this.neftAttachment,
            ...this.otherAttachment,
            ...this.kycAttachment,...this.cancelledChequeData,...this.surveyorlicenseAttachments]
  },
    onSubmit(proceedDuplicate = false){
        
        if (!this.$refs.create_Service_Provider.validate()) {
            return this.showAlert("Please fill mandatory fields.");
        }else{
            let pan = this.serviceProvideObj.panNumber.split('')
            let gst = this.serviceProvideObj.gstinNumber.split('')
            let sliceGst = gst.slice(2,12)

            if(this.serviceProvideObj.panNumber !== "" && this.serviceProvideObj.gstinNumber !== "" && pan.toString() !== sliceGst.toString()){
                this.showAlert("PAN Number and GST Number does not match")
                return
            }
            // this.matchPanNo()
            if(this.serviceProvideObj.serviceProviderType=='External Surveyor' && this.surveyorlicenseAttachments.length== 0){
                this.showAlert("Upload Surveyor license Document")
                return
            }
            this.convertObjToString()
        if(this.serviceProvideObj.serviceProviderId){
            delete this.serviceProvideObj.createdBy
            delete this.serviceProvideObj.createdById
            delete this.serviceProvideObj.createdAt
         delete this.serviceProvideObj.updatedAt
         delete this.serviceProvideObj.__v
         this.serviceProvideObj.approveRequest=true
          this.PUTM(
          "SERVICE_PROVIDER_MASTER_UPDATE",this.serviceProvideObj._id,
          this.serviceProvideObj,
          (res) => {
            this.hideProgress();
            if (res.data && res.data.statusCode == 200) {
              this.showAlert(res?.data?.msg || "Success", "", "", () => {
                
                this.cancel(1)              
              });
               this.isView=true
            } else if(res.data?.duplicate){
                this.showConfirm("Confirmation", res.data.msg, "Proceed", "Cancel", () => {
                    this.onSubmit(true)
                });
              this.hideProgress();
            } else {
                this.showAlert(res.data.msg)
                this.hideProgress();
            }
        },
        (err) => {
            console.log(err);
            this.hideProgress();
            let msg =
              err?.response?.data?.msg || "Unable to submit claim data.";
              this.showAlert(msg);
            }, true
            );
        }else{
        this.serviceProvideObj.proceedDuplicate = proceedDuplicate 
        this.POSTM(
          "SERVICE_PROVIDER_MASTER_CREATE",
          this.serviceProvideObj,
          (res) => {
            this.hideProgress();
            if (res.data && res.data.statusCode == 200) {
              this.showAlert(res?.data?.msg || "Success", "", "", () => {
                
                this.cancel(1)                              
              });
              // this.isView=true
            }else if(res.data?.duplicate){
                this.showConfirm("Confirmation", res.data.msg, "Proceed", "Cancel", () => {
                    this.onSubmit(true)
                });
              this.hideProgress();
            } else {
                this.showAlert(res.data.msg)
                this.hideProgress();
            }
          },
          (err) => {
            console.log(err);
            this.hideProgress();
            let msg =
              err?.response?.data?.msg || "Unable to submit claim data.";
            this.showAlert(msg);
          },true
        );}
      }
  },
},
created() {
    this.$store.commit("SAVE_PAGE_HEADER", "Service Provider Master");
    if(Object.keys(this.$route.params).length != 0){
        console.log("this.$route.params.item._id",this.$route.params)
        let a=`${this.$route.params.item._id}`;
        this.GET_ByURLM('SERVICE_PROVIDER_MASTER_GET',a,(res)=>{
        this.serviceProvideObj=res.data.data
        
        this.checkBankAddress()
        
        this.serviceProvideObj.documents.forEach(e=>{
          if(e.documentType == 'NEFT Form'){
            e.file=e.fileName
           this.neftAttachment.push(e)
          }
          if(e.documentType == 'KYC/CKYC'){
            e.file=e.fileName
            this.kycAttachment.push(e)
          }
          if(e.documentType == 'surveyor license'){
            e.file=e.fileName
            this.surveyorlicenseAttachments.push(e)
          }
          
          if(e.documentType == 'OTHERS'){  
            e.file=e.fileName        
            this.otherAttachment.push(e)
          }
          if(e.documentType == 'Cancelled Cheque'){ 
            e.file=e.fileName         
            this.cancelledChequeData.push(e)
            this.viewDocumentCheque(e.documentId)
          }

        })
        if(this.serviceProvideObj.isExternalServiceProvider=="Yes"){
            let a=this.serviceProvideObj.serviceProviderDetails.userId.split(" ")
        this.loginName=a[0]
        this.getLoginDetails()
       }else{
        this.getName=this.serviceProvideObj.serviceProviderDetails.employeeName
       this.getExaminerName()
       this.loginName=this.getName
        this.getLoginDetails()
       }
       this.ifsc=this.serviceProvideObj.bankDetails.ifscCode
       this.ifscData()
       
        //this.checkBankAccount()
        this.checkTabChanged()
       this.email_Obj= this.serviceProvideObj.emailID.map(e=>{return {email:e}})
       }, () => {}, true)
       if(this.$route.params.isView){
        this.top_Heading="View"
        this.isView=true
       }else{
        this.top_Heading="Modify"
        this.isView=false
        this.isModify = true
       }
      
       
    }
},
computed:{
    computedPAN() {
            this.serviceProvideObj.panNumber = this.serviceProvideObj.panNumber ? this.serviceProvideObj.panNumber.toUpperCase() : '';
            return this.serviceProvideObj.panNumber;
        },
        computedGST() {
      this.serviceProvideObj.gstinNumber = this.serviceProvideObj.gstinNumber
        ? this.serviceProvideObj.gstinNumber.toUpperCase()
        : ""
      return this.serviceProvideObj.gstinNumber
    },
    //     computedLicenseRegistrationdate(){
    //   return this.formatDate(this.serviceProvideObj.licenselicenseDate);
    // },
    // computedLicenseExpirydate(){
    //   return this.formatDate(this.serviceProvideObj.licenseExpiryDate);
    // },
    computedLicenseRegistrationdate(){
                return this.serviceProvideObj.licenselicenseDate == null ? '' : moment(this.serviceProvideObj.licenselicenseDate).format('DD/MM/YYYY');
            },
    computedLicenseExpirydate(){
                return this.serviceProvideObj.licenseExpiryDate == null ? '' : moment(this.serviceProvideObj.licenseExpiryDate).format('DD/MM/YYYY');
            },
    getEndDate() {
     var endDate = new Date();
     return endDate.toISOString().slice(0,10)
    },
}
}
</script>

<style scoped>
.iconstyle {
  background: #f7f7f7 0% 0% no-repeat padding-box;
  border: 0.5px solid #c1c8cc !important;
  color: #fff;
  width: 29px;
  margin-left: -0.1em;
  height: 38px;
  color: #6b6b6b;
}
.blueiconstyle {
  width: 38px;
  height: 38px;
  background: #01bbd9 0% 0% no-repeat padding-box;
  margin-right: -0.5em;
  color: #f7f7f7;
}
.claimProcessingTabsActive {
  background-color: #1ebbd9;
  color: #fff !important;
}
.unActive{
  border:1px solid #ccc;
  margin: 0px 5px
}
.dragbackground{
        background-color: #e7e7e7;
}
.margincls{
        font-size:12px;
        margin-left:-12px
}
.dlt_text{
        color: rgb(145, 145, 145);
}
.tabelwidth{
  width:500px;
  margin:0px 150px
}
.neftStyle{
        height: 150px;
        width: 350px;
        border: 3px dashed #ccc;
        /* padding-top: 40px;
        padding-left: 16%; */
        display: table-cell;
        vertical-align: middle;
        text-align: center;
}
</style>