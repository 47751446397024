<template>
<v-app>

    <div style="background-color: #FFFFFF;" column class="">
        <v-form ref="form" @submit.prevent="submitDetails">
            
            <v-layout color="#FFFFFF" class="mx-2 mt-3" style="background-color: #FFFFFF;">
                 <v-flex xs3 v-if="claimDetalsScreen == true">
                    <v-layout column class="cust_pr mx-2 ">
                        <p class="font-weight-bold cstm-clr-pr">
                            Intimation Status *
                        </p>
                        <v-select
                            :items="intimationStatusItems"
                            label="Select.."
                            v-model="intimationStatus"
                            solo
                            dense
                            :disabled="isDisableFieldStatus"
                            :error-messages="intimationStatusErrors"
                            @input="$v.intimationStatus.$touch()"
                            @blur="$v.intimationStatus.$touch()"
                            class="rounded-0"
                            type="text"
                            >
                        </v-select>
                    </v-layout>
                </v-flex>
                <v-flex xs3>
                        <v-layout column class="cust_pr mx-2 ">
                            <p class="font-weight-bold cstm-clr-pr">
                                Member ID *
                            </p>
                        <v-text-field     
                            color="white"
                            cache-items
                            maxlength=50
                            hide-selected
                            label="Select.."
                            v-model="memberID"
                            solo
                            :disabled="isDisableField"
                            :error-messages="providerErrors"
                            @input="$v.memberID.$touch()"
                            @blur="$v.memberID.$touch()"
                            class="rounded-0"
                            type="text"
                            required>
                        <div slot="append" style="position:relative;left:13px;background-color:#F7F7F7;padding: 9px !important" class=""><v-icon @click="toMemberSearch">search</v-icon></div>       
                        </v-text-field>

                        </v-layout>
                </v-flex>
                <v-flex xs3 >
                    <v-layout column class=" mx-2 cust_pr">
                        <p class="font-weight-bold cstm-clr-pr">
                            Policy Number *
                        </p>
                        <v-text-field
                            autocomplete="off"
                            solo
                            label="Enter..."
                            maxlength=30
                            v-model="policyNumber"
                            class="rounded-0"
                            :disabled="isDisableField"
                            :error-messages="policyNumberError"
                            @input="$v.policyNumber.$touch()"
                            @blur="$v.policyNumber.$touch()"
                            >
                        </v-text-field>
                          <!-- :rules="[required(0,''), rules.spclChar(1,'frstnmeInd')]" -->
                    </v-layout>
                </v-flex>
                <v-flex xs3 >
                    <v-layout column class=" mx-2 cust_pr">
                        <p class="font-weight-bold cstm-clr-pr">
                            First Name *
                        </p>
                        <v-text-field
                            autocomplete="off"
                            solo
                            label="Enter..."
                            v-model="firstName"
                            maxlength=20
                            class="rounded-0"
                            :disabled="isDisableField"
                            :error-messages="firstNameError"
                            @input="$v.firstName.$touch()"
                            @blur="$v.firstName.$touch()"
                            >
                        </v-text-field>
                          
                    </v-layout>
                </v-flex>

                 <v-flex xs3>
                    <v-layout column class=" mx-2 cust_pr">
                        <p class="font-weight-bold cstm-clr-pr">
                            Last Name *
                        </p>
                        <v-text-field
                            autocomplete="off"
                            solo
                            label="Enter..."
                            v-model="lastName"
                            maxlength=20
                            class="rounded-0"
                            :disabled="isDisableField"
                            :error-messages="lastNameError"
                            @input="$v.lastName.$touch()"
                            @blur="$v.lastName.$touch()"
                            >
                        </v-text-field>
                          
                    </v-layout>
                </v-flex>

                <v-flex xs3 v-if="claimDetalsScreen == false">
                    <v-layout column class=" mx-2 cust_pr">
                        <p class="font-weight-bold cstm-clr-pr">
                            Mobile Number *
                        </p>
                        <v-text-field
                            autocomplete="off"
                            label="Enter..."
                            v-model="mobileNumber"
                            solo
                            maxlength=10
                            class="rounded-0"
                            :error-messages="mobileNumberError"
                            @input="$v.mobileNumber.$touch()"
                            @blur="$v.mobileNumber.$touch()"
                            >
                            <div slot="prepend-inner" style="position:relative;right:12px;background-color:#F7F7F7;padding: 9px !important"><v-icon dense>call</v-icon></div>
                        </v-text-field>
                    </v-layout>
                </v-flex>

            </v-layout>

            <v-layout color="#FFFFFF" class="mx-2" style="background-color: #FFFFFF;">
                <v-flex xs3 v-if="claimDetalsScreen == true">
                    <v-layout column class=" mx-2 cust_pr">
                        <p class="font-weight-bold cstm-clr-pr">
                            Mobile Number *
                        </p>
                        <v-text-field
                            autocomplete="off"
                            label="Enter..."
                            v-model="mobileNumber"
                            solo
                            maxlength=10
                            class="rounded-0"
                            :disabled="isDisableField"
                            :error-messages="mobileNumberError"
                            @input="$v.mobileNumber.$touch()"
                            @blur="$v.mobileNumber.$touch()"
                            >
                            <div slot="prepend-inner" style="position:relative;right:12px;background-color:#F7F7F7;padding: 9px !important"><v-icon dense>call</v-icon></div>
                        </v-text-field>
                    </v-layout>
                </v-flex>
                <v-flex xs3>
                    <v-layout column class=" mx-2 cust_pr">
                        <p class="font-weight-bold cstm-clr-pr">
                            E-mail
                        </p>
                        <v-text-field
                            label="Enter..."
                            maxlength=100
                            v-model="email"
                            solo
                            :disabled="isDisableField"
                            :error-messages="emailErrors"
                            @input="$v.email.$touch()"
                            @blur="$v.email.$touch()"
                            class="rounded-0"
                            >
                            <div slot="prepend-inner" style="position:relative;right:12px;background-color:#F7F7F7;padding: 9px !important"><v-icon dense>email</v-icon></div>
                        </v-text-field>
                    </v-layout>
                </v-flex>
                <v-flex xs3>
                    <v-layout column class=" mx-2  cust_pr_quick cust-rmvpd-pr-quick">
                        <p class="font-weight-bold cstm-clr-pr-quick">
                            Date of Admission(Loss) *
                        </p>
                        
                        <v-menu
                            ref="calender"
                            :close-on-content-click="false"
                            v-model="regCalenderDate"
                            :nudge-right="40"
                            lazy
                            transition="scale-transition"
                            full-width
                            max-width="290px"
                            min-width="290px" 
                            class="rounded-0"   
                            >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-bind="attrs"
                                    maxlength=10
                                    v-on="on"
                                    label="dd/mm/yyyy"
                                    :value="admissionDateTo"
                                    solo
                                    class="rounded-0"
                                    :disabled="isDisableField"
    
                                    
                                    @input="$v.admissionDate.$touch()"
                                    @blur="$v.admissionDate.$touch()" 
                                    :error-messages="admissionDateErrors"
                                    required>
                            <div  slot="prepend-inner" style="background-color:#F7F7F7;  padding: 8px !important"><span class="material-icons">insert_invitation</span></div>
                                
                                </v-text-field>
                            </template>                        
                                    <v-date-picker 
                                        v-model="admissionDate"
                                        no-title
                                        @input="regCalenderDate = false"
                                    ></v-date-picker>
                        </v-menu>

                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class=" mx-2  cust_pr_quick cust-rmvpd-pr-quick">
                        <p class="font-weight-bold cstm-clr-pr-quick">
                            Date of Discharge
                        </p>
                        
                        <v-menu
                            ref="calender"
                            :close-on-content-click="false"
                            v-model="regCalenderDate2"
                            :nudge-right="40"
                            lazy
                            transition="scale-transition"
                            full-width
                            max-width="290px"
                            min-width="290px" 
                            class="rounded-0"   
                            >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-bind="attrs"
                                    maxlength=10
                                    v-on="on"
                                    label="dd/mm/yyyy"
                                    :value="dischargeDateTo"
                                    solo
                                    :disabled="isDisableField"
                                    :error-messages="dischargeDateErrors"
                                    class="rounded-0"
                                    readonly
                                    required>
                            <div  slot="prepend-inner" style="background-color:#F7F7F7;  padding: 8px !important"><span class="material-icons">insert_invitation</span></div>
                                
                                </v-text-field>
                            </template>                        
                                    <v-date-picker 
                                        v-model="dischargeDate"
                                        no-title
                                        @input="regCalenderDate2 = false"
                                    ></v-date-picker>
                        </v-menu>

                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class=" mx-2  cust_pr_quick cust-rmvpd-pr-quick">
                        <p class="font-weight-bold cstm-clr-pr-quick">
                            Intimation Date
                        </p>
                        
                        <v-menu
                            ref="calender"
                            :close-on-content-click="false"
                            v-model="regCalenderDate3"
                            :nudge-right="40"
                            lazy
                            transition="scale-transition"
                            full-width
                            max-width="290px"
                            min-width="290px" 
                            class="rounded-0"   
                            >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-bind="attrs"
                                    maxlength=10
                                    v-on="on"
                                    id="fromDate"
                                    label="dd/mm/yyyy"
                                    :value="intimationDateTo"
                                    solo
                                    dense
                                    class="rounded-0"
                                    readonly
                                    :disabled="disableintimationDate"
                                    :error-messages="intimationDateErrors"
                                    @input="$v.intimationDate.$touch()"
                                    @blur="$v.intimationDate.$touch()"
                                    required>
                            <div  slot="prepend-inner" style="background-color:#F7F7F7;  padding: 8px !important"><span class="material-icons">insert_invitation</span></div>
                                
                                </v-text-field>
                            </template>                        
                                    <v-date-picker 
                                        v-model="intimationDate"
                                        no-title
                                        @input="regCalenderDate3 = false"
                                    ></v-date-picker>
                        </v-menu>

                    </v-layout>
                </v-flex>

                <v-flex xs3 v-if="claimDetalsScreen == false">
                    <v-layout column class="cust_pr mx-2 ">
                        <p class="font-weight-bold cstm-clr-pr">
                            Mode of Intimation
                        </p>
                        <v-select
                            :items="intimationModeItems"
                            label="Select"
                            v-model="intimationMode"
                            solo
                            dense
                            :error-messages="intimationModeError"
                            @input="$v.intimationMode.$touch()"
                            @blur="$v.intimationMode.$touch()"
                            @change="intimationTypeChange(intimationMode)"
                            class="rounded-0"
                            required
                            >
                        </v-select>
                    </v-layout>
                </v-flex>

            </v-layout>

            <v-layout color="#FFFFFF" class="mx-2" style="background-color: #FFFFFF;">
                <v-flex xs3 v-if="claimDetalsScreen == true">
                    <v-layout column class="cust_pr mx-2 ">
                        <p class="font-weight-bold cstm-clr-pr">
                            Mode of Intimation
                        </p>
                        <v-select
                            :items="intimationModeItems"
                            label="Select"
                            v-model="intimationMode"
                            solo
                            dense
                            :disabled="isDisableField"
                            :error-messages="intimationModeError"
                            @input="$v.intimationMode.$touch()"
                            @blur="$v.intimationMode.$touch()"
                            @change="intimationTypeChange(intimationMode)"
                            class="rounded-0"
                            required
                            >
                        </v-select>
                    </v-layout>
                </v-flex>
                <v-flex xs3>
                    <v-layout column class=" mx-2 cust_pr">
                        <p class="font-weight-bold cstm-clr-pr">
                            Illness *
                        </p>
                        <v-text-field
                            autocomplete="off"
                            solo
                            label="Enter..."
                            v-model="illness"
                            class="rounded-0"
                            maxlength=200
                            :disabled="isDisableField"
                            :error-messages="illnessErrors"
                            @input="$v.illness.$touch()"
                            @blur="$v.illness.$touch()"
                            required
                            >
                        </v-text-field>
                          
                    </v-layout>
                </v-flex>
                <v-flex xs3>
                    <v-layout column class=" mx-2 cust_pr">
                        <p class="font-weight-bold cstm-clr-pr">
                            Illness Description
                        </p>
                        <v-text-field
                            autocomplete="off"
                            maxlength=200
                            solo
                            label="Enter..."
                            :disabled="isDisableField"
                            v-model="illnessDescription"
                            :error-messages="illnessDescriptionErrors"
                            @input="$v.illnessDescription.$touch()"
                            @blur="$v.illnessDescription.$touch()"
                            class="rounded-0"
                            >
                        </v-text-field>
                          
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class=" mx-2 cust_pr">
                        <p class="font-weight-bold cstm-clr-pr">
                            Diagnosis
                        </p>
                        <v-text-field
                            autocomplete="off"
                            maxlength=50
                            solo
                            label="Enter..."
                            :disabled="isDisableField"
                            v-model="diagnosis"
                            :error-messages="diagnosisErrors"
                            @input="$v.diagnosis.$touch()"
                            @blur="$v.diagnosis.$touch()"
                            class="rounded-0"
                            >
                        </v-text-field>
                          
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class=" mx-2 cust_pr">
                        <p class="font-weight-bold cstm-clr-pr">
                            Treatment
                        </p>
                        <v-text-field
                            autocomplete="off"
                            solo
                            maxlength=50
                            label="Enter..."
                            :disabled="isDisableField"
                            v-model="treatment"
                            :error-messages="treatmentErrors"
                            @input="$v.treatment.$touch()"
                            @blur="$v.treatment.$touch()"
                            class="rounded-0"
                            >
                        </v-text-field>
                          
                    </v-layout>
                </v-flex>

                <v-flex xs3 v-if="claimDetalsScreen == false">
                    <v-layout column class=" mx-2 cust_pr">
                        <p class="font-weight-bold cstm-clr-pr">
                            Actual / Estimated Amount
                        </p>
                        <v-text-field
                            autocomplete="off"
                            label="Enter..."
                            v-model="actualEstimatedAmount"
                            solo
                            maxlength=20
                            :error-messages="actualEstimatedAmountErrors"
                            @input="$v.actualEstimatedAmount.$touch()"
                            @blur="$v.actualEstimatedAmount.$touch()"
                            class="rounded-0"
                            >
                            <div slot="prepend-inner" style="position:relative;right:12px;background-color:#F7F7F7;padding: 9px !important"><v-icon dense>email</v-icon></div>
                        </v-text-field>
                    </v-layout>
                </v-flex>

            </v-layout>

            <v-layout color="#FFFFFF" class="mx-2" style="background-color: #FFFFFF;">
                <v-flex xs3 v-if="claimDetalsScreen == true">
                    <v-layout column class=" mx-2 cust_pr">
                        <p class="font-weight-bold cstm-clr-pr">
                            Actual / Estimated Amount
                        </p>
                        <v-text-field
                            autocomplete="off"
                            label="Enter..."
                            v-model="actualEstimatedAmount"
                            solo
                            :disabled="isDisableField"
                            maxlength=20
                            class="rounded-0"
                            >
                            <div slot="prepend-inner" style="position:relative;right:12px;background-color:#F7F7F7;padding: 9px !important"><v-icon dense>email</v-icon></div>
                        </v-text-field>
                    </v-layout>
                </v-flex>
                <v-flex xs3>
                    <v-layout column class=" mx-2 cust_pr">
                        <p class="font-weight-bold cstm-clr-pr">
                            Intimation Type *
                        </p>
                        <v-select
                            :items="intimationTypeItems"
                            solo
                            label="Enter..."
                            v-model="intimationType"
                            class="rounded-0"
                            :disabled="claimDetalsScreen == true || claimDetalsScreen == false ? isDisableFieldNewClaim: ''"
                            :error-messages="intimationTypeErrors"
                            @input="$v.intimationType.$touch()"
                            @blur="$v.intimationType.$touch()"
                            required
                            >
                        </v-select>
                          
                    </v-layout>
                </v-flex>
                <v-flex xs3>
                    <v-layout column class=" mx-2 cust_pr">
                        <p class="font-weight-bold cstm-clr-pr">
                            Hospital name
                        </p>
                        <!-- <v-autocomplete
                            :items="hospitalNameItems"
                            label="Select.."
                            v-model="hospital_name"
                            solo
                            dense
                            class="rounded-0"
                            type="text"
                            >
                        <div slot="append" style="position:relative;left:13px;background-color:#F7F7F7;padding: 9px !important" class=""><v-icon>search</v-icon></div>       
                        </v-autocomplete> -->
                        <v-text-field
                            autocomplete="off"
                            label="Enter.."
                            maxlength=100
                            v-model="hospital_name"
                            solo
                            dense
                            class="rounded-0"
                            :disabled="isDisableField"
                            type="text"
                            >
                        <div slot="append" style="position:relative;left:13px;background-color:#F7F7F7;padding: 9px !important" class=""><v-icon @click="toHospitalSearch">search</v-icon></div>       
                        </v-text-field>
                          
                    </v-layout>
                </v-flex>
                <!-- checkbox field -->
                <!-- <v-flex xs3>
                    <v-layout column class=" mx-2 cust_pr">
                        <p class="font-weight-bold cstm-clr-pr">
                            Checkbox
                        </p>
                        <v-checkbox
                        dense
                        style="margin-top: 3px; margin-left: 16px"
                        v-model="secondOption" 
                        >
                        </v-checkbox>
                          
                    </v-layout>
                </v-flex> -->
                <v-flex xs3 >
                    <v-layout column class="mx-2 mb-5" justify-center >
                            
                        <span class="font-weight-bold cstm-clr-pr" style="color:#FFFFFF" > </span>
                        <div style="box-shadow: 2px 2px 4px #ccc;border: 1px solid #fff; padding:7px" class="mt-5">
                            <v-checkbox :disabled="isDisableField" class="ma-0 pa-0" v-model="secondOpinion"  hide-details >
                                <span slot="label" class="lbl-bgclrTDS font-weight-bold" style="font-size: 13px;">Second Opinion</span>
                            </v-checkbox>
                        </div>
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class=" mx-2 cust_pr">
                        <p class="font-weight-bold cstm-clr-pr">
                            Hospital Address Line 1
                        </p>
                        <v-text-field
                            autocomplete="off"
                            maxlength=100
                            solo
                            label="Enter..."
                            :disabled="isDisableField"
                            v-model="hospitalAddress1"
                            
                            class="rounded-0"
                            >
                        </v-text-field>
                          
                    </v-layout>
                </v-flex>

                <v-flex xs3 v-if="claimDetalsScreen == false">
                    <v-layout column class=" mx-2 cust_pr">
                        <p class="font-weight-bold cstm-clr-pr">
                            Hospital Address Line 2
                        </p>
                        <v-text-field
                            autocomplete="off"
                            maxlength=100
                            solo
                            :disabled="isDisableField"
                            label="Enter..."
                            v-model="hospitalAddress2"
                            
                            class="rounded-0"
                            >
                        </v-text-field>
                          
                    </v-layout>
                </v-flex>

            </v-layout>

            <v-layout color="#FFFFFF" class="mx-2" style="background-color: #FFFFFF;">
                <v-flex xs3 v-if="claimDetalsScreen == true">
                    <v-layout column class=" mx-2 cust_pr">
                        <p class="font-weight-bold cstm-clr-pr">
                            Hospital Address Line 2
                        </p>
                        <v-text-field
                            autocomplete="off"
                            maxlength=100
                            solo
                            label="Enter..."
                            :disabled="isDisableField"
                            v-model="hospitalAddress2"
                            
                            class="rounded-0"
                            >
                        </v-text-field>
                          
                    </v-layout>
                </v-flex>
                <v-flex xs3>
                    <v-layout column class=" mx-2 cust_pr">
                        <p class="font-weight-bold cstm-clr-pr">
                            Hospital Address Line 3
                        </p>
                        <v-text-field
                            autocomplete="off"
                            maxlength=100
                            solo
                            label="Enter..."
                            :disabled="isDisableField"
                            v-model="hospitalAddress3"
                            
                            class="rounded-0"
                            >
                        </v-text-field>
                        
                    </v-layout>
                </v-flex>
                <v-flex xs3>
                    <v-layout column class=" mx-2 cust_pr">
                        <p class="font-weight-bold cstm-clr-pr">
                            Pincode
                        </p>
                        <v-text-field
                            
                            label="Select.."
                            v-model="pincode"
                            solo
                            dense
                            class="rounded-0"
                            :disabled="isDisableField"
                            type="text"
                            :error-messages="pincodeErrors"
                            @input="$v.pincode.$touch()"
                            @blur="$v.pincode.$touch()"
                            >
                        </v-text-field>
                        <!-- <v-autocomplete
                            autocomplete="off"
                            label="Enter..."
                            :items="itemspin"
                            :search-input.sync="search"
                            v-model="pincode"
                            solosas
                            dense
                            class="rounded-0"
                            hide-no-data
                            cache-items
                            hide-selected
                            item-text="pin"
                            item-value="pin"
                            placeholder="Start typing to Search"
                            @change="grab_pincode(pincode, 'from change')"
                            return-object
                            maxlength=6                       
                            :disabled="isDisableField"
                            @input="$v.pincode.$touch()"
                            @blur="$v.pincode.$touch()"
                            >
                        </v-autocomplete> -->
                          
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class="cust_pr mx-2 ">
                        <p class="font-weight-bold cstm-clr-pr">
                            Country
                        </p>
                        <v-text-field
                            
                            autocomplete="off"
                            label="Select"
                            v-model="country"
                            solo
                            dense
                            :disabled="isDisableField"
                            class="rounded-0"
                            type="text"
                            >
                        </v-text-field>
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class="cust_pr mx-2 ">
                        <p class="font-weight-bold cstm-clr-pr">
                            State
                        </p>
                        <v-text-field
                            
                            autocomplete="off"
                            label="Select" 
                            v-model="state"
                            solo
                            dense
                            :disabled="isDisableField"
                            class="rounded-0"
                            type="text"
                            >
                        </v-text-field>
                    </v-layout>
                </v-flex>

                <v-flex xs3 v-if="claimDetalsScreen == false">
                    <v-layout column class="cust_pr mx-2 ">
                        <p class="font-weight-bold cstm-clr-pr">
                            District
                        </p>
                        <v-text-field
                            
                            autocomplete="off"
                            label="Select" 
                            v-model="district"
                            solo
                            dense
                            :disabled="isDisableField"
                            class="rounded-0"
                            type="text"
                            >
                        </v-text-field>
                    </v-layout>
                </v-flex>

            </v-layout>

            <v-layout color="#FFFFFF" class="mx-2" style="background-color: #FFFFFF;">
                <v-flex xs3 v-if="claimDetalsScreen == true">
                    <v-layout column class="cust_pr mx-2 ">
                        <p class="font-weight-bold cstm-clr-pr">
                            District
                        </p>
                        <v-text-field
                            
                            autocomplete="off"
                            label="Select"
                            v-model="district"
                            solo
                            dense
                            :disabled="isDisableField"
                            class="rounded-0"
                            type="text"
                            >
                        </v-text-field>
                    </v-layout>
                </v-flex>
                <v-flex xs3>
                    <v-layout column class="cust_pr mx-2 ">
                        <p class="font-weight-bold cstm-clr-pr">
                            City / Town / Village
                        </p>
                        <v-text-field
                            :items="ctvItems"
                            autocomplete="off"
                            label="Select"
                            v-model="CityTownVillage"
                            solo
                            dense
                            :disabled="isDisableField"
                            class="rounded-0"
                            type="text"
                            >
                        </v-text-field>
                    </v-layout>
                </v-flex>
                <v-flex xs3.5>
                    <v-layout column class=" mx-2 cust_pr">
                        <p class="font-weight-bold cstm-clr-pr">
                            Remarks
                        </p>
                        <v-text-field
                            autocomplete="off"
                            maxlength=200
                            solo
                            label="Enter..."
                            :disabled="isDisableField"
                            v-model="remarks"
                            :error-messages="remarksErrors"
                            @input="$v.remarks.$touch()"
                            @blur="$v.remarks.$touch()"
                            class="rounded-0"
                            >
                        </v-text-field>
                          
                    </v-layout>
                </v-flex>

            </v-layout>

        <v-card color="#F7F7F7" class="mx-2 pa-3 overflow-y-auto" v-if="claimDetalsScreen == false">
            <v-row class="pt-2 pb-2 mt-1 mr-2 float-right">
              <v-btn type="submit" tile dark color="#23B1A9" class="float-right text-capitalize px-3 mr-6" small>
                <v-icon color="" small >check_circle</v-icon>
                <v-divider vertical class="ml-2 mr-2"></v-divider>Submit
              </v-btn>
              <v-btn @click="$router.go(-1)" tile dark color="#E56C24" class="float-right text-capitalize px-3 mr-5" small>
                <v-icon color="" small >cancel</v-icon>
                <v-divider vertical class="ml-2 mr-2"></v-divider>Cancel
              </v-btn>
              <v-btn @click="clearDataFields" tile dark color="#E56C24" class="float-right text-capitalize px-3" small>
                <v-icon color="" small >cancel</v-icon>
                <v-divider vertical class="ml-2 mr-2"></v-divider>Clear
              </v-btn>
              <!-- <v-btn @click="$router.push('/SelectIntimation')" tile dark color="#152F38" class="float-right text-capitalize px-3" small>
                <v-icon color="" small >mdi-plus-circle</v-icon>
                <v-divider vertical class="ml-2 mr-2"></v-divider>Select Intimation
              </v-btn> -->
            </v-row>
        </v-card>

        <v-card color="#F7F7F7" class="mx-2 pa-3 overflow-y-auto" v-if="claimDetalsScreen == true">
            <v-row class="pt-2 pb-2 mt-1 mr-2 float-right">
              <v-btn @click="$router.go(-1)" tile dark color="#152F38" class="float-right text-capitalize px-3 mr-6" small>
                <v-icon color="" small >mdi-arrow-left</v-icon>
                <v-divider vertical class="ml-2 mr-2"></v-divider>Return
              </v-btn>
              <v-btn  @click="changeStatusClick" :dark="disableReopenBtn" :disabled="!disableReopenBtn" tile style="background-color:#23B1A9" class="float-right text-capitalize px-3 mr-5" small>
                <v-icon color="" small >folder_open</v-icon>
                <v-divider vertical class="ml-2 mr-2"></v-divider>Re-Open
              </v-btn>
              <v-btn @click="editEnableField" :dark="disableEditBtn" :disabled="!disableEditBtn" tile style="background-color:#23B1A9" class="float-right text-capitalize px-3 mr-5" small>
                <v-icon color="" small >edit</v-icon>
                <v-divider vertical class="ml-2 mr-2"></v-divider>Edit Details
              </v-btn>
              <v-btn @click="saveDetails" type="submit" tile :dark="disableSaveBtn" :disabled="!disableSaveBtn" style="background-color:#23B1A9" class="float-right text-capitalize px-3" small>
                <v-icon color="" small >check_circle</v-icon>
                <v-divider vertical class="ml-2 mr-2"></v-divider>Save
              </v-btn>
            </v-row>
        </v-card>

        </v-form>
    </div>                
            
</v-app>
   
</template>

<script>
import moment from 'moment';
import { required, numeric, alpha, email, alphaNum, helpers,minLength} from 'vuelidate/lib/validators';
const nameSpaces = helpers.regex('nameSpaces', /^[a-zA-Z.' ]+$/);

export default {
    created(){
        console.log("from created");
        this.$store.commit("SAVE_PAGE_HEADER", "New Claim Intimation");
        
        if(this.$route.params.claimIntiVal == true){
            console.log(this.$route.params.claimDetailsData);
            this.bindClaimDetails(this.$route.params.claimDetailsData);
        }

        console.log(this.$route.params.claimDetailsData);
        if(this.$route.params.claimDetailsData !== undefined){
        if(this.$route.params.claimDetailsData.intimationStatus == 'Created'){
           this.disableReopenBtn = false;
           this.disableEditBtn = true;
        }else{
           this.disableReopenBtn = true; 
           this.disableEditBtn = false;
        }
        }
        
        console.log(this.$route.params.allData);
        console.log(this.$route.params);
        if(this.$route.params.allData !== undefined){
            if(this.$route.params.toEdit == true){
                this.claimDetalsScreen = true;
                this.getHospitalDetails(this.$route.params.allData);
            }else{
                this.claimDetalsScreen = false;
                this.getHospitalDetails(this.$route.params.allData);
            }
        // this.getHospitalDetails(this.$route.params.allData);
        }

        console.log(this.$route.params.membSearData);
        if(this.$route.params.membSearData !== undefined){
            if(this.$route.params.toEdit == true){
                this.claimDetalsScreen = true;
                this.getMemberDetails(this.$route.params.membSearData);
            }else{
                this.claimDetalsScreen = false;
                this.getMemberDetails(this.$route.params.membSearData);
            }
        // this.getMemberDetails(this.$route.params.membSearData);
        }
        // console.log(" from created success",this.$store.state.ClaimDetailsStore);

        // this.fromStoreDetails(this.$store.state.savedClaimDetails);
        // this.memberID = this.$store.state.savedClaimDetails.memberID;
        
    },

    validations: {
        intimationStatus: {required},
       memberID: {required, alphaNum},
       policyNumber: {required, alphaNum},
       firstName: {required,nameSpaces},
       lastName: {required, nameSpaces},
       mobileNumber: {required,numeric,minLength:minLength(10)},
       email: {email},
       admissionDate: {required},
       intimationDate: {required},
       intimationMode: {required},
       illness: {required,nameSpaces},
       intimationType: {required},
       pincode: {numeric},
       illnessDescription: {nameSpaces},
       treatment: {nameSpaces},
       diagnosis: {nameSpaces},
       actualEstimatedAmount: {numeric},
    //    hospitalAddress1: {nameSpaces},
    //    hospitalAddress2: {nameSpaces},
    //    hospitalAddress3: {nameSpaces},
       remarks: {nameSpaces},
       dischargeDate: {required}
    },

    data(){
        return{
            intimationStatusItems: ['Created','Closed'],
            intimationStatus: '',
            disableEditBtn: false,
           disableReopenBtn: false,
           disableSaveBtn: false,
           isDisableField: false,
           isDisableFieldNewClaim: true,
           isDisableFieldStatus: false,
           claimDetalsScreen: this.$route.params.claimIntiVal == false ? false: this.$route.params.claimIntiVal == true ? true: false,
           intimationModeItems: ['Call center','Chatbot','Customer Portal','Email','Agent Portal','Automatic'],
           memberID: '',
           policyNumber: '',
           sltnIndItems: [],
        //    savedClaimDetails: {
        //    firstName: '',
        //    lastName: '',
        //    },
           mobileNumber: '',
           email: '', 
           firstName: '',
           lastName: '',
           regCalenderDate: false,
           regCalenderDate2: false,
           regCalenderDate3: false,
           admissionDate: '',
           dischargeDate: '',
           intimationDate: new Date().toISOString().substr(0, 10),
           disableintimationDate: true,
           intimationMode: '', 
           illness: '',
           illnessDescription: '',
           diagnosis: '',
           treatment: '',
           actualEstimatedAmount: '',
           intimationType: '',
           intimationTypeItems: [],
           hospitalNameItems: [],
           hospital_name: '',
           hospitalAddress1: '',
           secondOpinion: false,
           hospitalAddress2: '',
           hospitalAddress3: '',
           pincodeItems: [],
           pincode: '',
        //    countryItems: [],
           country: '',
           state: '',
           district: '',
           CityTownVillage: '',
           remarks: '',
           searchApi: null,
           search: '',
           isLoading: false,
           entries: [],
           member_id: [],
           getBy_id: '',
           saveDetailsData: false,
           cntryItems: [],
           stateItems: [],
           districtItems: [],
           ctvItems: [],
           pitems:[],
           s_c_d_nm:false,
           searched:false,
           tosearched:false,
           goToEdit: false,
           _idData: '',
        }
    },

    mounted(){
        // console.log(this.$route.params)
       console.log(" from mounted success",this.$store.state.ClaimDetailsStore);
       if(this.$route.params.haveDta == true){
        var net_cache = this.$store.state.ClaimDetailsStore;
        // this.memberID = net_cache.memberID,
        // this.policyNumber = net_cache.policyNumber,
        // this.firstName = net_cache.firstName,
        // this.lastName = net_cache.lastName,
        // this.mobileNumber = net_cache.mobileNumber,
        // this.email = net_cache.email,
        this.admissionDate = net_cache.admissionDate,
        this.dischargeDate = net_cache.dischargeDate,
        this.intimationMode = net_cache.intimationMode,
        this.illness = net_cache.illness,
        this.illnessDescription = net_cache.illnessDescription,
        this.diagnosis = net_cache.diagnosis,
        this.treatment = net_cache.treatment,
        this.actualEstimatedAmount = net_cache.actualEstimatedAmount,
        this.intimationTypeItems.push(net_cache.intimationType),
        this.intimationType = net_cache.intimationType,
        this.hospital_name = net_cache.hospital_name,
        this.hospitalAddress1 = net_cache.hospitalAddress1,
        this.secondOpinion = net_cache.secondOpinion,
        this.hospitalAddress2 = net_cache.hospitalAddress2,
        this.hospitalAddress3 = net_cache.hospitalAddress3,
        this.pincodeItems.push(net_cache.pincode);
        this.pincode = net_cache.pincode,
        this.cntryItems.push(net_cache.country);
        this.country = net_cache.country,
        this.stateItems.push(net_cache.state);
        this.state = net_cache.state,
        this.districtItems.push(net_cache.district);
        this.district = net_cache.district,
        this.ctvItems.push(net_cache.CityTownVillage);
        this.CityTownVillage = net_cache.CityTownVillage,
        this.remarks = net_cache.remarks
       }
       else if(this.$route.params.haveDta == false){
           this.clearDataFields();
       }

       console.log("hospital data",this.$store.state.ClaimDetailsStoreHospital)
       if(this.$route.params.hospitalDta == true){
           console.log("hospital data",this.$store.state.ClaimDetailsStoreHospital)
        var net_cache_hospital = this.$store.state.ClaimDetailsStoreHospital;
        this.memberID = net_cache_hospital.memberID;
        this.policyNumber = net_cache_hospital.policyNumber;
        this.firstName = net_cache_hospital.firstName;
        this.lastName = net_cache_hospital.lastName;
        this.mobileNumber = net_cache_hospital.mobileNumber;
        this.email = net_cache_hospital.email;
        this.admissionDate = net_cache_hospital.admissionDate;
        this.dischargeDate = net_cache_hospital.dischargeDate;
        this.intimationMode = net_cache_hospital.intimationMode;
        this.illness = net_cache_hospital.illness;
        this.illnessDescription = net_cache_hospital.illnessDescription;
        this.diagnosis = net_cache_hospital.diagnosis;
        this.treatment = net_cache_hospital.treatment;
        this.actualEstimatedAmount = net_cache_hospital.actualEstimatedAmount;
        this.intimationTypeItems.push(net_cache_hospital.intimationType);
        this.intimationType = net_cache_hospital.intimationType;
        // this.hospital_name = net_cache_hospital.hospital_name;
        // this.hospitalAddress1 = net_cache_hospital.hospitalAddress1;
        this.secondOpinion = net_cache_hospital.secondOpinion;
        // this.hospitalAddress2 = net_cache_hospital.hospitalAddress2;
        // this.hospitalAddress3 = net_cache_hospital.hospitalAddress3;
        // this.pincode = net_cache_hospital.pincode;
        // this.country = net_cache_hospital.country;
        // this.state = net_cache_hospital.state;
        // this.district = net_cache_hospital.district;
        // this.CityTownVillage = net_cache_hospital.CityTownVillage;
        this.remarks = net_cache_hospital.remarks
       }
       else if(this.$route.params.hospitalDta == false){
           this.clearDataFields();
       }

       if(this.$route.params.toEdit == true){
           console.log(this.$store.state.ClaimDetailsStoreEdit);
           var net_cache_edit = this.$store.state.ClaimDetailsStoreEdit;
        //    this.memberID = net_cache_edit.memberID;
           this.isDisableFieldStatus = true;
           this.intimationStatus = net_cache_edit.intimationStatus;
        // this.policyNumber = net_cache_edit.policyNumber;
        // this.firstName = net_cache_edit.firstName;
        // this.lastName = net_cache_edit.lastName;
        // this.mobileNumber = net_cache_edit.mobileNumber;
        // this.email = net_cache_edit.email;
        this.admissionDate = net_cache_edit.admissionDate;
        this.dischargeDate = net_cache_edit.dischargeDate;
        this.intimationMode = net_cache_edit.intimationMode;
        this.illness = net_cache_edit.illness;
        this.illnessDescription = net_cache_edit.illnessDescription;
        this.diagnosis = net_cache_edit.diagnosis;
        this.treatment = net_cache_edit.treatment;
        this.actualEstimatedAmount = net_cache_edit.actualEstimatedAmount;
        // this.intimationTypeItems.push(net_cache_edit.intimationType);
        // this.isDisableFieldNewClaim = true;
        this.intimationType = net_cache_edit.intimationType;
        // this.hospital_name = net_cache_edit.hospital_name;
        // this.hospitalAddress1 = net_cache_edit.hospitalAddress1;
        this.secondOpinion = net_cache_edit.secondOpinion;
        // this.hospitalAddress2 = net_cache_edit.hospitalAddress2;
        // this.hospitalAddress3 = net_cache_edit.hospitalAddress3;
        // this.pincode = net_cache_edit.pincode;
        // this.country = net_cache_edit.country;
        // this.state = net_cache_edit.state;
        // this.district = net_cache_edit.district;
        // this.CityTownVillage = net_cache_edit.CityTownVillage;
        this.remarks = net_cache_edit.remarks
       }

    //    this.savedClaimDetails = this.$store.state.savedClaimDetails;
    },

    // watch:{
    //    search(val){
    //   // Items have already been loaded
    //     console.log(this.itemspin.length," val is ", val);
    //     if(val == null){
    //         console.log("returned as val foun to be null")
    //         return
    //     }

    //   if(val.length >3){
    //       var vlen = val.length
    //       console.log('vlen',vlen)
    //     //  var arrlen = this.entries.length
    //     //  console.log(arrlen,'arrlen')
    //     //  var newlen =this.entries[515]
    //     //  console.log("newlen is from watcher",newlen)
    //       if(this.itemspin.length == 0 && val.length >= 3){
    //           this.callapi(val,0)
    //           return
    //       }else{
    //           console.log('itens len')
    //       }

    //       for(let i=0; i<this.itemspin.length;i++){
            
    //           var strlen = this.itemspin[i].pin.slice(0,vlen)
    //           console.log(vlen > strlen.length,"strlen is",strlen)
                
    //          if(vlen > strlen.length){
    //            console.log("data not available to search")
    //            return
    //          }
            
    //           if(strlen != val){
    //               console.log(strlen.length,"not found as ",val.length)
    //             //   this.foundis = false
    //               this.callapi(val,0)
    //              return
    //           }else{
    //             console.log(this.itemspin.length,"found true", this.itemspin[i].pin)
    //             // this.foundis = true
    //             return
    //           }
    //       }
        
    //   }else{
    //     console.log("not 3")
    //   }
     
    // },

    // },

    computed: {
        itemspin(){
            return this.pitems 
        },

        // selectItems(){
        //   return this.entries;
        // },

        intimationStatusErrors(){
            const errors = [];
            
            if (!this.$v.intimationStatus.$dirty) return errors   
            !this.$v.intimationStatus.required && errors.push('This field is required');
            return errors;
        },

        providerErrors(){
            const errors = [];
            
            if (!this.$v.memberID.$dirty) return errors   
            console.log("memberid errors", this.memberID.memberDetail);
            !this.$v.memberID.required && errors.push('This field is required');
            // !this.$v.memberID.alphaNum && errors.push('Only Alphanumeric');
            return errors;

        }, 

        policyNumberError(){
            const errors = [];
            console.log("Error is");

            if (!this.$v.policyNumber.$dirty) return errors   
            !this.$v.policyNumber.required && errors.push('This field is required');
            !this.$v.policyNumber.alphaNum && errors.push('Only Alphanumeric');
            return errors;
        },

        firstNameError(){
            const errors = [];

            if (!this.$v.firstName.$dirty) return errors   
            !this.$v.firstName.required && errors.push('This field is required');
            !this.$v.firstName.nameSpaces && errors.push('Only alphabets are allowed');
            return errors;
        },

        lastNameError(){
            const errors = [];

            if (!this.$v.lastName.$dirty) return errors   
            !this.$v.lastName.required && errors.push('This field is required');
            !this.$v.lastName.nameSpaces && errors.push('Only alphabets are allowed');
            return errors;
        },

        mobileNumberError(){
            const errors = [];

            if (!this.$v.mobileNumber.$dirty) return errors   
            !this.$v.mobileNumber.required && errors.push('This field is required');
            !this.$v.mobileNumber.numeric && errors.push('Only Numbers are allowed');
            !this.$v.mobileNumber.minLength && errors.push('Enter Valid Mobile Number');
            return errors;
        },

        emailErrors(){
                const errors = []
                if (!this.$v.email.$dirty) return errors
                // !this.$v.email.required && errors.push('This field is required')
                !this.$v.email.email && errors.push('E-mail must be valid')
                return errors 
        },

        admissionDateErrors(){
            console.log(this.admissionDate);
            let toDate = this.$v.admissionDate.$model;
            let fromDate = this.$v.dischargeDate.$model;
            const errors = [];

            if (!this.$v.admissionDate.$dirty) return errors   
            // !this.$v.admissionDate.required && errors.push('This field is required');
            if(!moment(fromDate).isAfter(toDate))
            errors.push('*Admission Date must be less than Discharge Date');
            return errors;
        },
        

        intimationDateErrors(){
            const errors = []
     
            !this.$v.intimationDate.required && errors.push('This field is required!');
            return errors
        },

        admissionDateTo() {
            console.log(this.admissionDate)
            console.log(this.admissionDate ? moment(this.admissionDate).format("DD-MM-YYYY"): '')
            return this.admissionDate ? moment(this.admissionDate).format("DD-MM-YYYY"): ''
         },

        dischargeDateTo() {
            console.log(this.dischargeDate)
            console.log(this.dischargeDate ? moment(this.dischargeDate).format("DD-MM-YYYY"): '')
            return this.dischargeDate ? moment(this.dischargeDate).format("DD-MM-YYYY"): ''
        },

        intimationDateTo() {
            console.log(this.intimationDate)
            console.log(this.intimationDate ? moment(this.intimationDate).format("DD-MM-YYYY"): '')
            return this.intimationDate ? moment(this.intimationDate).format("DD-MM-YYYY"): ''
        },

        intimationModeError(){
            const errors = []
            if (!this.$v.intimationMode.$dirty) return errors
            !this.$v.intimationMode.required && errors.push('This field is required')
            return errors 
        },

        illnessErrors(){
            const errors = []
            if (!this.$v.illness.$dirty) return errors
            !this.$v.illness.required && errors.push('This field is required')
            !this.$v.illness.nameSpaces && errors.push('Special characters & Numbers not allowed');
            return errors 
        },

        illnessDescriptionErrors(){
            const errors = []
            if (!this.$v.illnessDescription.$dirty) return errors
            !this.$v.illnessDescription.nameSpaces && errors.push('Special characters & Numbers not allowed');
            return errors 
        },

        treatmentErrors(){
            const errors = []
            if (!this.$v.treatment.$dirty) return errors
            !this.$v.treatment.nameSpaces && errors.push('Special characters & Numbers not allowed');
            return errors 
        },

        diagnosisErrors(){
            const errors = []
            if (!this.$v.diagnosis.$dirty) return errors
            !this.$v.diagnosis.nameSpaces && errors.push('Special characters & Numbers not allowed');
            return errors 
        },

        remarksErrors(){
            const errors = []
            if (!this.$v.remarks.$dirty) return errors
            !this.$v.remarks.nameSpaces && errors.push('Special characters & Numbers not allowed');
            return errors 
        },

        actualEstimatedAmountErrors(){
            const errors = []
            if (!this.$v.actualEstimatedAmount.$dirty) return errors
            !this.$v.actualEstimatedAmount.numeric && errors.push('Only Numbers are allowed');
            return errors 
        },

        intimationTypeErrors(){
            const errors = []
            if (!this.$v.intimationType.$dirty) return errors
            !this.$v.intimationType.required && errors.push('This field is required')
            return errors 
        },

        pincodeErrors(){
            const errors = []
            if (!this.$v.pincode.$dirty) return errors
            !this.$v.pincode.numeric && errors.push('Only Numbers are allowed')
            return errors 
        },

    },

    methods:{
        toMemberSearch(){
            //  console.log(item,"search result item ")
            // cs99
            // this.$store.commit("CLAIM_DETAILS_DATA",item)
            if(this.searched == false){
                var cache_state = {
                    searched: true,
                    memberID: this.memberID,
                    policyNumber: this.policyNumber,
                    firstName: this.firstName,
                    lastName: this.lastName,
                    mobileNumber: this.mobileNumber,
                    email: this.email,
                    admissionDate: this.admissionDate,
                    dischargeDate: this.dischargeDate,
                    intimationMode: this.intimationMode,
                    illness: this.illness,
                    illnessDescription: this.illnessDescription,
                    diagnosis: this.diagnosis,
                    treatment: this.treatment,
                    actualEstimatedAmount: this.actualEstimatedAmount,
                    intimationType: this.intimationType,
                    hospital_name: this.hospital_name,
                    hospitalAddress1: this.hospitalAddress1,
                    secondOpinion: this.secondOpinion,
                    hospitalAddress2: this.hospitalAddress2,
                    hospitalAddress3: this.hospitalAddress3,
                    pincode: this.pincode,
                    country: this.country,
                    state: this.state,
                    district: this.district,
                    CityTownVillage: this.CityTownVillage,
                    remarks: this.remarks
                }
                console.log(this.searched,"CACHE STATE is if", cache_state);
                this.$store.commit('CLAIM_DETAILS_DATA', cache_state);
            }else{
                 var cache_state = {
                    searched: false,
                 }
                console.log(this.searched,"CACHE STATE is else", cache_state);
                this.$store.commit('CLAIM_DETAILS_DATA', cache_state);
            }

            // return
            // this.$router.push({name: 'MemberSearch', params: {fromEdit: 'EDIT_DATA'}});
            console.log(this.claimDetalsScreen);
            if(this.claimDetalsScreen == true){
              if(this.$route.params.claimIntiVal == true){
                console.log("from edit btn");
                var on_store_details = {
                    searched: true,
                    intimationStatus: this.intimationStatus,
                    memberID: this.memberID,
                    policyNumber: this.policyNumber,
                    firstName: this.firstName,
                    lastName: this.lastName,
                    mobileNumber: this.mobileNumber,
                    email: this.email,
                    admissionDate: this.admissionDate,
                    dischargeDate: this.dischargeDate,
                    intimationMode: this.intimationMode,
                    illness: this.illness,
                    illnessDescription: this.illnessDescription,
                    diagnosis: this.diagnosis,
                    treatment: this.treatment,
                    actualEstimatedAmount: this.actualEstimatedAmount,
                    intimationType: this.intimationType,
                    hospital_name: this.hospital_name,
                    hospitalAddress1: this.hospitalAddress1,
                    secondOpinion: this.secondOpinion,
                    hospitalAddress2: this.hospitalAddress2,
                    hospitalAddress3: this.hospitalAddress3,
                    pincode: this.pincode,
                    country: this.country,
                    state: this.state,
                    district: this.district,
                    CityTownVillage: this.CityTownVillage,
                    remarks: this.remarks
                }
                console.log("CACHE STATE is if", on_store_details);
                this.$store.commit('CLAIM_DETAILS_DATA_EDIT', on_store_details);
            }  
            this.$router.push({name: 'MemberSearch', params: {fromEdit: true}});
            }else{
            this.$router.push('/MemberSearch');
            }

        },

        toHospitalSearch(){
            // this.$store.commit("SAVE_CLAIM_INTIMATION_DETAILS",this.savedClaimDetails);
            if(this.tosearched == false){
                var cache_state = {
                    searched: true,
                    memberID: this.memberID,
                    policyNumber: this.policyNumber,
                    firstName: this.firstName,
                    lastName: this.lastName,
                    mobileNumber: this.mobileNumber,
                    email: this.email,
                    admissionDate: this.admissionDate,
                    dischargeDate: this.dischargeDate,
                    intimationMode: this.intimationMode,
                    illness: this.illness,
                    illnessDescription: this.illnessDescription,
                    diagnosis: this.diagnosis,
                    treatment: this.treatment,
                    actualEstimatedAmount: this.actualEstimatedAmount,
                    intimationType: this.intimationType,
                    hospital_name: this.hospital_name,
                    hospitalAddress1: this.hospitalAddress1,
                    secondOpinion: this.secondOpinion,
                    hospitalAddress2: this.hospitalAddress2,
                    hospitalAddress3: this.hospitalAddress3,
                    pincode: this.pincode,
                    country: this.country,
                    state: this.state,
                    district: this.district,
                    CityTownVillage: this.CityTownVillage,
                    remarks: this.remarks
                }
                console.log(this.tosearched,"CACHE STATE is if", cache_state);
                this.$store.commit('CLAIM_DETAILS_DATA_HOSPITAL', cache_state);
            }else{
                 var cache_state = {
                    searched: false,
                 }
                console.log(this.tosearched,"CACHE STATE is else", cache_state);
                this.$store.commit('CLAIM_DETAILS_DATA_HOSPITAL', cache_state);
            }

        //     // return
        if(this.claimDetalsScreen == true){
            if(this.$route.params.claimIntiVal == true){
                console.log("from edit btn");
                var on_store_details = {
                    searched: true,
                    intimationStatus: this.intimationStatus,
                    memberID: this.memberID,
                    policyNumber: this.policyNumber,
                    firstName: this.firstName,
                    lastName: this.lastName,
                    mobileNumber: this.mobileNumber,
                    email: this.email,
                    admissionDate: this.admissionDate,
                    dischargeDate: this.dischargeDate,
                    intimationMode: this.intimationMode,
                    illness: this.illness,
                    illnessDescription: this.illnessDescription,
                    diagnosis: this.diagnosis,
                    treatment: this.treatment,
                    actualEstimatedAmount: this.actualEstimatedAmount,
                    intimationType: this.intimationType,
                    hospital_name: this.hospital_name,
                    hospitalAddress1: this.hospitalAddress1,
                    secondOpinion: this.secondOpinion,
                    hospitalAddress2: this.hospitalAddress2,
                    hospitalAddress3: this.hospitalAddress3,
                    pincode: this.pincode,
                    country: this.country,
                    state: this.state,
                    district: this.district,
                    CityTownVillage: this.CityTownVillage,
                    remarks: this.remarks
                }
                console.log("CACHE STATE is if", on_store_details);
                this.$store.commit('CLAIM_DETAILS_DATA_EDIT', on_store_details);
            }
            this.$router.push({name: 'HospitalSearch', params: {fromEdit: true}});
        }else{
            this.$router.push('/HospitalSearch');
        }

        
        //     this.$router.push('/HospitalSearch')
        
        },

        getHospitalDetails(data){
            console.log(data);
            console.log(data.entityName);
            
            this.hospital_name = data.entityName;
            console.log("hospital name is",this.hospital_name);
            this.hospitalAddress1 = data.allData.addressLine1;
            this.hospitalAddress2 = data.allData.addressLine2;
            this.hospitalAddress3 = data.allData.addressLine3;
            this.pincodeItems.push(data.allData.pinPostalCode);
            this.pincode = data.allData.pinPostalCode;
            this.cntryItems.push(data.allData.country);
            this.country = data.allData.country;
            this.stateItems.push(data.allData.state);
            this.state = data.allData.state;
            this.districtItems.push(data.allData.district);
            this.district = data.allData.district;
            this.ctvItems.push(data.allData.cityTownVillage);
            this.CityTownVillage = data.allData.cityTownVillage;

            this.disableEditBtn = true;
            this.disableSaveBtn = true;
        },

        intimationTypeChange(val){
            console.log(val);
            if(val == 'Automatic'){
                this.intimationTypeItems = ['System Generated'];
                this.intimationType = 'System Generated'
            }else{
                this.intimationTypeItems = ['User Entered'];
                this.intimationType = 'User Entered'
            }
        },

        // callapi(indata, ident){
        //     // return
        //     console.log("ident is ", ident);
        //     console.log("call api is called", indata);
        //      this.GET(3,'city/search-by-pincode/'+indata, res=>{
        //         // console.log("response of pincode api is  -> ", res.data.data);
                
        //         if(this.counter == 2){
        //            this.pitems = [];
        //            this.counter = 0
        //         }
        //         var pin_not_found = false
        //         if(res.data.data.length == 0){
        //             // for making state city and district non-mandatory assign pin code response is zero
        //             this.s_c_d_nm = true
        //             pin_not_found = true
        //             this.counter = ''
        //             this.state = ''
        //             this.district = ''
        //             this.CityTownVillage = ''
                    
        //             this.showToast("Pin Code not found" ,this.TOST().ERROR);

        //         }else{
        //              this.s_c_d_nm = false
        //         }
                
        //         var processed_pin =  res.data.data.map(e=>{
        //             return { pin:e.pincode.toString() ,all:e }
        //         })
        //         this.pitems.push(...processed_pin)

        //         console.log("|--==>>> ",this.pitems);
        //         if(pin_not_found){
        //             this.pitems.push({pin:indata.toString(),all:''});
        //         }

        //            if(ident == 1){
        //             console.log("FROM IDENT ONE -> 1", this.indtype.data.indata);
        //             this.pitems.map(e=>{
        //                 // console.log("e is ",e);
        //                 // && e.all.area == this.indtype.data.indata.cityTownVillage
        //                 if(e.pin == indata  && e.all.district == this.indtype.data.indata.district){
        //                     console.log(indata,"matched pin code is ",e)
        //                     this.pnCde = e
        //                 }
        //             })

        //                 console.log("what we have after pin code is found ->",this.pnCde)
        //             //    {pin:indata.toString(),all:{}};
                
        //                this.grab_pincode(this.pnCde, 'Frm CALLED API');
        //            }
        //         this.counter++
        //              console.log("call api pitems in new call api", this.pitems);
        //         // this.pitems = res.data.data.data.map
        //         // console.log("pitems is -> ", this.pitems);
        //     })

        // },
        
        // grab_pincode(indata, from){
        //     // return
        //     // gp99
        //     console.log("GRABBED PIN-CODE CALLED FROM", indata)
        //     // alert("grabbed pin-code called")
        //       this.cntryItems = [];
        //       this.cntryItems.push({ country:indata.all.country, all:indata.all.country })
        //       this.country = indata.all.country;
        //       console.log(this.cntryItems);

        //        this.districtItems = [];
        //        this.districtItems.push({district:indata.all.district, all:indata.all.district })
        //        this.district = indata.all.district;

        //        this.stateItems = [];
        //        this.stateItems.push({state:indata.all.state, all:indata.all.state})
        //        this.state = indata.all.state;

        //        this.ctvItems = []
               
        //         this.ctvItems.push({area:indata.all.area,all:indata.all.area},{area:'Other'})
        //         this.CityTownVillage = indata.all.area

        //          console.log( this.ctv ,"area is",   this.ctvItems )
        //     // alert("grabbed pin-code called")
           
        //     return
        //     console.log("grabbed pincode is --->",indata);    
        //     console.log("grabbed pincode WILL CALL DISTRICT --->",indata);         
        //     this.GET(3,'district/get-all?state='+indata.all.state , res=>{
        //         console.log('res of disctruct is ', res);
        //             if(res.status == 200){
        //                 this.districtItems =  res.data.data.data;
        //                       console.log("districtItems from response is --->", this.districtItems);   
        //                  this.districtItems.map((e,index)=>{
        //                         console.log(e.district," -> ");
        //                      if(e.district ==indata.all.district){
        //                         //  alert("matched disssss")
        //                          this.district = this.districtItems[index].district
        //                          this.getstates(indata.all.country,this.districtItems[index].state);
        //                          this.country = indata.all.country;
        //                          console.log(this.districtItems[index],"<<< --selected dis ",  this.district)
        //                      }
        //                  })
        //             }else{
        //                 console.log("something went wrong, pincode", res)
        //             }
        //     })
        // },

        getMemberDetails(resp){
           console.log(resp.data.data);
           this.memberID = resp.data.data.memberId;
           this.policyNumber = resp.data.data.policyNumber;
           this.firstName = resp.data.data.firstName;
           this.lastName = resp.data.data.lastName;
           this.email = resp.data.data.email;
           this.mobileNumber = resp.data.data.mobileNumber;

            this.disableEditBtn = true;
            this.disableSaveBtn = true;
        },

        saveDetails(){
            this.saveDetailsData = true;
            console.log("woking save", this.saveDetailsData);
        },

        editEnableField(){
            this.isDisableField = false;
            this.isDisableFieldStatus = true;
            this.disableSaveBtn = true;
            // this.isDisableFieldNewClaim = true;

            // if(this.$route.params.claimIntiVal == true){
            //     console.log("from edit btn");
            //     var on_store_details = {
            //         searched: true,
            //         intimationStatus: this.intimationStatus,
            //         memberID: this.memberID,
            //         policyNumber: this.policyNumber,
            //         firstName: this.firstName,
            //         lastName: this.lastName,
            //         mobileNumber: this.mobileNumber,
            //         email: this.email,
            //         admissionDate: this.admissionDate,
            //         dischargeDate: this.dischargeDate,
            //         intimationMode: this.intimationMode,
            //         illness: this.illness,
            //         illnessDescription: this.illnessDescription,
            //         diagnosis: this.diagnosis,
            //         treatment: this.treatment,
            //         actualEstimatedAmount: this.actualEstimatedAmount,
            //         intimationType: this.intimationType,
            //         hospital_name: this.hospital_name,
            //         hospitalAddress1: this.hospitalAddress1,
            //         secondOpinion: this.secondOpinion,
            //         hospitalAddress2: this.hospitalAddress2,
            //         hospitalAddress3: this.hospitalAddress3,
            //         pincode: this.pincode,
            //         country: this.country,
            //         state: this.state,
            //         district: this.district,
            //         CityTownVillage: this.CityTownVillage,
            //         remarks: this.remarks
            //     }
            //     console.log("CACHE STATE is if", on_store_details);
            //     this.$store.commit('CLAIM_DETAILS_DATA_EDIT', on_store_details);
            // }
            this.goToEdit = true;
        },

        bindClaimDetails(data){
           console.log(data);
           this._idData = data.id;
        //    console.log(dateTo);

           this.isDisableFieldStatus = true;
           this.isDisableField = true;
        //    this.disableintimationDate = true;
           this.intimationStatusItems.push(data.intimationStatus);
           this.intimationStatus = data.intimationStatus;
           this.memberID = data.memberId;
           this.policyNumber = data.policyNumber;
           this.firstName = data.firstName;
           this.lastName = data.lastName;
           this.mobileNumber = data.mobileNo;
           this.email = data.emailId;
        //    this.regCalenderDate = data.dateOfAdmission;
        //    this.regCalenderDate2 = data.dateOfDischarge;
        //    this.regCalenderDate3 = date;
           console.log(data.dateofAdmission);
           this.admissionDate = moment(data.dateofAdmission,'DD-MM-YYYYY').format('YYYY-MM-DD');
           this.dischargeDate = data.dateOfDischarge;
           console.log(moment(data.dateofAdmission,'DD-MM-YYYYY').format('YYYY-MM-DD'));
        //    this.disableintimationDate = '';
           this.intimationMode = data.modeOfIntimation; 
           this.illness = data.illness;
           this.illnessDescription = data.illnessDescription;
           this.diagnosis = data.diagnosis;
           this.treatment = data.treatment;
           this.actualEstimatedAmount = data.actualOrEstimatedAmount;
           this.intimationType = data.intimationType;
           this.hospital_name = data.hospitalName;
           this.hospitalAddress1 = data.hospitalAddressLine1;
           this.secondOpinion = data.secondOpinion;
           this.hospitalAddress2 = data.hospitalAddressLine2;
           this.hospitalAddress3 = data.hospitalAddressLine3;
           this.pincodeItems.push(data.pincode);
           this.pincode = data.pincode;
           this.cntryItems.push(data.country);
           this.country = data.country;
           this.stateItems.push(data.state);
           this.state = data.state;
           this.districtItems.push(data.district);
           this.district = data.district;
           this.ctvItems.push(data.cityTownVillage);
           this.CityTownVillage = data.cityTownVillage;
           this.remarks = data.remarks;

           if(data.modeOfIntimation == 'Automatic'){
                this.intimationTypeItems = ['System Generated'];
                this.intimationType = 'System Generated'
            }else{
                this.intimationTypeItems = ['User Entered'];
                this.intimationType = 'User Entered'
            }
        },

        clearDataFields(){
            this.$refs.form.reset();
            return
           this.memberID = '';
           this.policyNumber = '';
           this.firstName = '';
           this.lastName = '';
           this.mobileNumber = '';
           this.email = '';
           this.regCalenderDate = '';
           this.regCalenderDate2 = '';
           this.regCalenderDate3 = '';
           this.admissionDate = '';
           this.dischargeDate = '';
           this.disableintimationDate = '';
           this.intimationMode = ''; 
           this.illness = '';
           this.illnessDescription = '';
           this.diagnosis = '';
           this.treatment = '';
           this.actualEstimatedAmount = '';
           this.intimationType = '';
           this.hospital_name = '';
           this.hospitalAddress1 = '';
           this.secondOpinion = false;
           this.hospitalAddress2 = '';
           this.hospitalAddress3 = ''
           this.pincode = '';
           this.country = '';
           this.state = '';
           this.district = '';
           this.CityTownVillage = '';
           this.remarks = ''
        },

        changeStatusClick(){
           let tempArr = [];
           tempArr.push({
               id: this.$route.params.claimDetailsData.id,
               intimationStatus: 'Created',
           })

           this.PUT(8, 'claims-intimation/update-intimation-status',tempArr, (res, err) => {
                if(!err){
                    console.log(res.data);
                    this.showToast(res.data.msg ,this.TOST().SUCCESS);
                    this.intimationStatus = 'Created';
                    this.disableReopenBtn = false;
                    this.disableEditBtn = true;
                }else{
                    console.log(err);
                }
           })    
        },

        submitDetails(){
            console.log(this.intimationStatus);
            console.log(this.mobileNumber.toString());
            var formData = {
              'intimationStatus': 'Created', 
              'memberId': this.memberID,
              'policyNo': this.policyNumber,
              'firstName': this.firstName,
              'lastName': this.lastName,
              'mobileNo': this.mobileNumber.toString(),
              'emailId': this.email,
              'dateOfAdmissionLoss': this.admissionDate,
              'dateOfDischarge': this.dischargeDate == null ? '': this.dischargeDate,
              'intimationDate': this.intimationDate,
              'modeOfIntimation': this.intimationMode,
              'illness': this.illness,
              'illnessDescription': this.illnessDescription,
              'diagnosis': this.diagnosis,
              'treatment': this.treatment,
              'actualOrEstimatedAmount': this.actualEstimatedAmount,
              'intimationType': this.intimationType,
              'hospitalName': this.hospital_name,
              'secondOpinion': this.secondOpinion,
              'hospitalAddressLine1': this.hospitalAddress1,
              'hospitalAddressLine2': this.hospitalAddress2,
              'hospitalAddressLine3': this.hospitalAddress3,
              'pincode': this.pincode.toString(),
              'country': this.country,
              'state': this.state,
              'district': this.district,
              'cityTownVillage': this.CityTownVillage,
              'remarks': this.remarks
              
            }
            console.log(this._idData);
    
            this.$v.$touch();
            if(this.policyNumberError.length > 0 || this.illnessErrors.length > 0 || this.intimationTypeErrors.length > 0 || this.intimationModeError.length > 0 || this.emailErrors.length > 0 || this.firstNameError.length > 0 || this.lastNameError.length > 0 || this.mobileNumberError.length > 0 || this.admissionDateErrors.length > 0){
                console.log("It is not valid form");
                this.showToast("Please fill required fields",this.TOST().WARNING);
            }else if(this.saveDetailsData == true){
                let formDataPut = {
                    'intimationStatus': this.intimationStatus,
                    ...formData
                }
                console.log(formDataPut);
                console.log("save btn clicked",this.saveDetailsData,this.mobileNumber);
                this.PUT(8, 'claims-intimation/update-intimation-details/'+this._idData, formDataPut, (res, err) => {
                if(!err){
                    console.log(res.data);
                    this.showToast(res.data.msg ,this.TOST().SUCCESS);
                    // this.intimationStatus = 
                }else{
                    console.log(err);
                }
                })  
            }
            else{
                console.log("all fine");
                this.POST(8,'claims-intimation/create-new-intimation',formData ,( res, error) => {
                             if(!error){
                                    console.log(res.data.data);                 
                                    this.showToast(res.data.msg ,this.TOST().SUCCESS);
                                    this.$refs.form.reset();
                                    // this.dialogFunc = true;
                                    // this.$router.push('/search-tarrif')
                                    // this.$store.commit("SAVE_DETAILS", res.data.data);
                                    
                            }else{
                                    console.log("when status is not 200", error.response);
                                    this.showToast("Something went wrong",this.TOST().ERROR);
                            }
    
                });
            }
        },

    }

}
</script>

<style>


</style>