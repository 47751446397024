<template>
  <div>
    <v-container fluid v-if="sno == 20">
      <v-card class="m-1" tile>
      <v-layout row>
        <v-data-table
          :headers="headers"
          :items="documentsItems"
          class="padding-0 vDataTableStyling scroller"
          :items-per-page="20"
          :hide-default-footer="true"
        >
        <template v-slot:top>
          <v-layout
            justify-space-between
            align-center
            style="width: 100%"
            class="py-2"
          >
          </v-layout>
        </template>
        <template v-slot:item.viewUpload="{ item }">
          <div>
            <div
              class="neftStyle"
              @dragover="dragover"
              @dragleave="dragleave"
              @drop="drop($event, item.sno)"
              :class="visualEffect ? 'dragbackground' : ''"
            >
              <v-icon color="#23b1a9" class="iconfont"
                >mdi-tray-arrow-up</v-icon>
                <a @click="browse(item.sno)"><u> Browse</u></a>
            </div>
              <span></span>
              <v-file-input
                multiple
                v-model="all_attachments"
                @change="selectFile($event, item.sno)"
                accept=".doc, .docx, .csv, image/png,image/jpeg,application/msword,application/pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/csv"
                :id="'SP_fileupload'+item.sno"
                style="display: none"
              ></v-file-input>
            </div>
        </template>
        <template v-slot:item.link="{ item }">
          <a>{{ item.link }}</a>
          <div v-if="item.link">
            <v-icon
              class="tableicons mr-4"
              color="#23B1A9"
              @click="viewDownloadDoc(item, true)"
              >mdi-eye</v-icon
            >
            <v-icon
              class="tableicons mr-4"
              color="#23B1A9"
              @click="viewDownloadDoc(item, false)"
              >mdi-download</v-icon
            >
            <v-icon
              class="tableicons"
              color="#D1121B"
              @click="deleteDocument(item.sno)"
              v-if="!item.documentId && isInvUser"
              >mdi-delete</v-icon
            >
          </div>
        </template>
        <template v-slot:item.mandatory="{ item }">
          <v-select
            label="Select"
            :items="chooseOptionList"
            class="form-control"
            disabled
            v-model="item.mandatory"
            solo
            dense
            depressed
          >
          </v-select>
        </template>
        <template v-slot:item.invRemarks="{ item }">
          <v-text-field
            placeholder="Enter..."
            class="form-control"
            v-model="item.invRemarks"
            solo
            dense
            depressed
          />
        </template>
        <template v-slot:item.waiveOffreason="{ item, index }">
          <v-text-field
            placeholder="Enter..."
            class="form-control"
            v-model="item.waiveOffreason"
            solo
            dense
            depressed
          />
        </template>
        <template v-slot:item.verifiedAcceptedBySiuManager="{ item }">
          <v-select
            label="Select"
            :items="chooseOptionList"
            class="form-control"
            v-model="item.verifiedAcceptedBySiuManager"
            :disabled="!isSiuUser"
            solo
            dense
            depressed
          >
          </v-select>
        </template>
        <template v-slot:item.siuManagerRemarks="{ item }">
          <v-text-field
            placeholder="Enter..."
            class="form-control"
            v-model="item.siuManagerRemarks"
            :disabled="!isSiuUser"
            solo
            dense
            depressed
          />
        </template>
        <template v-slot:item.uploadedBy="{ item }">
          <span v-if="item.ntidUserId || item.name">
            {{ item.ntidUserId + '-' + item.name }}
          </span>
        </template>
        <template v-slot:item.save="{ index }">
          <v-btn
            dark
            tile
            color="#23B1A9"
            class="text-capitalize px-3 mx-2 my-2"
            small
            @click="saveData(index)"
          >Save
          </v-btn>
        </template>
        </v-data-table>
      </v-layout>
      </v-card>
    </v-container>
    <form ref="clientDocForm" target="_blank" action="http://172.20.251.20:8080/omnidocs/integration/webConfiguration.jsp" method="POST">
            <input type="hidden" name="Application" value="Matrix">
            <input type="hidden" name="OD_UID" value="-589987595936021669">
            <input type="hidden" name="sessionIndexSet" value="false">
        </form>
    <v-dialog
         v-model="messagePopup"
         persistent
         max-width="480px"
         max-height="400px"
         class=""
       >
           <v-card color="#F7F7F7" class="pb-2" tile>
             <v-card>
               <v-layout
	                justify-space-between
                    class="flex_screen_layout"
               >
                 <v-card-title
                   class="pa-0 pl-3 text-capitalize"
                   style="color:#10242B;font-size:23px"
                   >Download failed</v-card-title
                 >
 
                 <div
                   style="border-left:1px solid #C1C8CC;"
                   @click="() => { messagePopup = false }"
                 >
                   <v-icon style="cursor: pointer;" class="pa-2">close</v-icon>
                 </div>
               </v-layout>
             </v-card>
             <div class="ml-4 pt-2">
                 <p> Document Size is greater than 10MB </p>
                 <v-btn color="#1E9690" @click="$refs.clientDocForm.submit()"  outlined dark small class= "my-2">
                  <v-icon dark small>mdi-arrow-right-bold</v-icon> Redirect to OmniDocs
               </v-btn>
             </div>
           </v-card>
       </v-dialog> 
    <InvestigationFooterCard :tabno="sno" :formdata="getFormData" />
  </div>
</template>
<script>
import workerMixin from '@/mixins/workerMixin';
 import InvestigationFooterCard from './InvestigationFooterCard.vue';
 import { getDocumentsByWoritemId, getDocumentChecklist, createDocument, updateDocument } from "../apiservices/uploadInvestigationDoc";
 export default {
    mixins: [workerMixin],
    name: 'UploadInvestigationDoc',
    data() {
        return {
          APP_URL: "",
          currentRole : "",
          isInvUser:false,
          isSiuManager:false,
          isSiuUser : false,
          siuRoles: ["CG77", "CG78","CG79","CG80","CG81","CG82"],
          siuManagerRole: ["CG77", "CG78","CG79","CG80","CG81"],
          messagePopup:false,
          fileBase64: "",
          all_attachments : [],
          visualEffect: false,
          headers: [{
            text: "SR. No.",
            value: "sno",
            class: "textClass bgColor",
            divider: true,
            align: "center",
            width: 100,
            sortable: false,
          },
          {
            text: "Document Name",
            value: "documentName",
            class: "textClass bgColor",
            divider: true,
            align: "center",
            width: 125,
            sortable: false,
          },
          {
            text: "Upload File",
            value: "viewUpload",
            align: "center",
            sortable: false,
            width: 250
          },
        {
          text: "Mandatory",
          value: "mandatory",
          align: "center",
          width: 200,
          sortable: false,
        },
        {
          text: "View File",
          value: "link",
          width: 200,
          divider: true,
          align: "center",
          sortable: false,
        },
        {
          text: "INV Remarks",
          value: "invRemarks",
          sortable: false,
          align: "center",
          width: 200,
        },
        {
          text: "Waive off reason",
          value: "waiveOffreason",
          sortable: false,
          align: "center",
          width: 200,
        },
        {
          text: "Verified & Accepted by SIU Manager",
          value: "verifiedAcceptedBySiuManager",
          class: "textClass bgColor",
          divider: true,
          align: "center",
          sortable: false,
          width: 250
        },
        {
          text: "SIU Manager Remarks",
          value: "siuManagerRemarks",
          class: "textClass bgColor",
          divider: true,
          align: "center",
          width: "200px",
          sortable: false,
        },
        {
          text: "Uploaded By",
          value: "uploadedBy",
          align: "center",
          width: "200px",
          sortable: false,
        },
        {
          text: "Save",
          value: "save",
          align: "center",
          width: 100,
          sortable: false,
        },
      ],
            sno : 20,
            validationmessage: "This field is required",
            rules: {
                mandatoryrule: [(v) => !!v || this.validationmessage],
            },
            documentsItems: [],
            chooseOptionList: ['Yes', 'No'],
        }
    },
    components: {
      InvestigationFooterCard
    },
    async created() {
      this.APP_URL = process.env.VUE_APP_APP_URL;
      this.currentRole = this.fetchRoleValue();
      this.isSiuUser = this.siuRoles.some(r=> this.currentRole.indexOf(r) >= 0);
      this.isSiuManager = this.siuManagerRole.some(r=> this.currentRole.indexOf(r) >= 0);
      this.isInvUser = this.currentRole.some(e=> e === "CG83")
      await this.getDocumentChecklist();
      this.getDocumentsList();
    },
    computed: {
      emailRules2(v) {
        if (v?.length !== 0) {
          console.log("testing", /^[0-9 ]+$/.test(v));
          if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,6})+$/.test(v)) {
            return "E-mail must be valid";
          }
        }
      },
      getFormData() {
        return this.$data;
      },
      computedBucket(){
        let env = this.APP_URL?.split('-')[1]?.split('.')[0]
        if(env === 'dev' || env === 'uat' || env === 'preprod'){
          return 'marine-claims'
        }else {
          return 'marine-claims-prod'
        }
      }
  },
//   mounted() {
//     let found = this.items.find(el => el.sno == 20);
//     if(!found){
//       this.items.push({sno: 20, name: "View / Upload Documents"});
//     }
//     console.log("INDEX NUMBER 20", found);
    
//   },
  methods: {
    async getDocumentChecklist() {
      let res2 = await getDocumentChecklist();
      res2.data.forEach((element, index) => {
        this.documentsItems.push({documentName: element.documentName, sno: index+1,mandatory: 'Yes'});
      });
    },
    async getDocumentsList(){
      try{
        let res = await getDocumentsByWoritemId(this.$route.params.workitemId);
        if(res?.data?.statusCode == 200 || res.statusCode == 200) {
          this.showToast(res?.data?.msg || 'Data fetch success', this.TOST().SUCCESS);
        } else {
          return;
        }
        res.data.forEach((b)=>{
          let index = this.documentsItems.findIndex(a=>{return a.documentName == b.documentName});
          this.documentsItems[index].attachments = b.attachments;
          this.documentsItems[index].verifiedAcceptedBySiuManager = b.verifiedAcceptedBySiuManager;
          this.documentsItems[index].siuManagerRemarks = b.siuManagerRemarks;
          this.documentsItems[index].invRemarks = b.invRemarks;
          this.documentsItems[index].lob = b.lob;
          this.documentsItems[index].siuWorkitemId = b.siuWorkitemId;
          this.documentsItems[index].waiveOffreason = b.waiveOffreason;
          this.documentsItems[index].deleteStatus = b.deleteStatus;
          this.documentsItems[index].link = b.attachments[0].fileName;
          this.documentsItems[index].ntidUserId = b.ntidUserId;
          this.documentsItems[index].name = b.name;
          this.documentsItems[index]._id = b._id;
        });
        this.updateGridData();
      } catch(e){
        if(e?.response?.data?.message?.msg == 'No Data Found') {
          console.log(e.response.data.message.msg);
          return;
        }
        this.showToast(e.response?.data?.msg || e.response?.data  || e.response || e, this.TOST().ERROR);
        console.log(e);
      }
    },
    updateGridData(){
      let arrCopy = JSON.parse(JSON.stringify(this.documentsItems));
      this.documentsItems = null;
      this.documentsItems = JSON.parse(JSON.stringify(arrCopy));
    },
    dragleave(event) {
      this.visualEffect = false
    },
    browseCancel() {
      //to select attachments
    //   document.getElementById("cancelchequeupload").click()
    },
    dragover(event) {
      event.preventDefault()
      this.visualEffect = true
    },
    deleteDocument(sno) {
      this.showConfirm(
        "Confirmation",
        "Are you sure you want to Delete ?",
        "Yes",
        "No",
        (Yes) => {
            this.documentsItems.map((el,i)=>{
              if (el.sno == sno) {
                return this.markDeleteStatusOfDocument(el._id, () => {
                  delete el.link;
                  this.updateGridData();
                });
              }
            });
        });
    },
    async markDeleteStatusOfDocument (id, cb) {
      try {
        if (!id) {
          return cb();
        }

        await updateDocument({ deleteStatus: true }, id);
        cb();
      } catch (error) {
        this.showToast("Something went wrong, please try again.", this.TOST().ERROR);
        console.log(error);
      }
    },
    viewDownloadDoc(item, isView) {
      if(!item.attachments[0].fileBase) {
        this.openFile(item.attachments[0], isView);
        return;
      }
      let base64 = item.attachments[0].fileBase;
      const base64ImageData = base64;
      const contentType = base64.split(":")[1].split(";")[0]
      const byteCharacters = window.atob(base64ImageData.substr(`data:${contentType};base64,`.length));
      const byteArrays = []
      for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
        const slice = byteCharacters.slice(offset, offset + 1024);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i)
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
      const blob = new Blob(byteArrays, { type: contentType });
      const blobUrl = window.URL.createObjectURL(blob);
      if(isView){
        window.open(blobUrl, "_blank");
      } else {
        const element = document.createElement("a")
        element.href = blobUrl;
        element.download = item.documentName;
        element.click();
      }
    },
    getextension(filepath) {
      var ext = /^.+\.([^.]+)$/.exec(filepath);
      return ext == null ? "" : ext[1];
    },
    openFile(fileDetails, viewFlag) {
      console.log(fileDetails);
      const fileUrl = fileDetails.fileIs?.fileUrl || fileDetails.fileIs?.fileLink || fileDetails.fileLink || fileDetails.fileUrl;
      const documentType = fileDetails.documentType || fileDetails.document_type;
      const documentName = fileDetails.documentName || fileDetails.fileName;
      const fileKey = fileDetails.fileIs?.fileKey || fileDetails.fileKey;
      const bucket = fileDetails.fileIs?.bucket || this.computedBucket;
      const ext = this.getextension(documentName)
      if(fileDetails.documentType == 'Policy Details'){
            this.$refs.clientDocForm.submit()
      }
      let endpointExtension
      let fileName = fileDetails.from == "Inward" ? `${documentName}.${this.getextension(documentName)}` : `${documentName}.pdf`
      if(!fileDetails.from == "Inward") endpointExtension = `location=OmniDocs&omnidocsId=${fileDetails.documentId}&fileName=${documentName}`
      else endpointExtension = `location=AWS&fileKey=${fileKey}&fileName=${documentName}&bucketName=${bucket}`
      this.createWorker(documentType, ext, endpointExtension, this.authHeader(), viewFlag, documentName).then((downloadResponseMessage) => {
          this.showToast(downloadResponseMessage, this.TOST().SUCCESS)
        }).catch((error) => {
          if (error == "Failure"){
            this.showToast(error, this.TOST().ERROR);
            this.messagePopup = true
          }
          else{
            this.showToast(error, this.TOST().ERROR);
          }
        }).finally(() => {
            this.terminateWorker();
        })
    this.worker.postMessage({ url: fileUrl });
        },
    updateGridData(){
      let arrCopy = JSON.parse(JSON.stringify(this.documentsItems));
      this.documentsItems = null;
      this.documentsItems = JSON.parse(JSON.stringify(arrCopy));
    },
    drop(event, sno) {
      console.log(event,sno);
      event.preventDefault();
      this.all_attachments = event.dataTransfer.files;
      this.selectFile("", sno);
      this.visualEffect = false;
    },
    selectFile(event, sno){
      console.log("SSSSS",sno);
            const reader = new FileReader();
          reader.readAsDataURL(this.all_attachments[0]);
          reader.onload = (()=>{
            this.fileBase64 = reader.result;
            console.log(reader.result);
            let index = this.documentsItems.findIndex(el => {return el.sno == sno});
            let arr = [];
            arr.push({file: this.all_attachments[0].name, documentType: this.all_attachments[0].type, fileBase: this.fileBase64});
            this.documentsItems[index].link = this.all_attachments[0].name;
            this.documentsItems[index].attachments = arr;
            this.documentsItems[index].lob = "Health";
            this.updateGridData();
          });
          // console.log("FILENAME", index, this.all_attachments[0].name ,this.documentsItems);
          // console.log(this.fileBase64);
          // console.log("all", this.all_attachments);
    },
    computedDate(name) {
      return this[name] ? moment(this[name]).format("DD/MM/YYYY") : "";
    },
    goToPreviousTab() {
      this.sno--;
      let btnNumber = "myBtn" + this.sno;
      const elem = this.$refs[btnNumber];
      elem[0].$el.click();
    },
    goToNextTab() {
      this.sno++;
      console.log('this', this.$children.$options);
      let btnNumber = "myBtn" + this.sno;
      const elem = this.$refs[btnNumber];
      elem[0].$el.click();
    },
    switchTabs(sno, name) {
      this.sno = sno;
      this.tabName = name;
    },
    submit() {
      this.isAlertView = true;
    },
    accept() {
      this.isAlertView = true;
    },
    async saveData(index) {
      // console.log(this.fileBase64);
      // console.log(this.documentsItems);
      // console.log(this.all_attachments);
      // console.log("DDDDD",JSON.stringify(this.documentsItems));
      console.log(this.$route.params);
      if(this.isSiuManager == true){
        try{
          let formData = {
                verifiedAcceptedBySiuManager: this.documentsItems[index].verifiedAcceptedBySiuManager ?? '',
                siuManagerRemarks: this.documentsItems[index].siuManagerRemarks ?? ''
            }
          let id = this.documentsItems[index]._id
            let res = await updateDocument(formData,id);
            if(res.data.statusCode == 200){
             this.showToast(res.data.msg, this.TOST().SUCCESS);
            } else {
              this.showToast(res.data.msg, this.TOST().WARNING);
            }
            this.getDocumentsList();
            } catch(e){
        this.showToast(e.response?.data?.msg || e.response?.data  || e.response || e, this.TOST().ERROR);
        console.log(e);
      }

      }else if(this.isInvUser == true){

        try{
          if(this.documentsItems[index].mandatory == 'Yes' && this.documentsItems[index].hasOwnProperty("link") && !this.documentsItems[index].invRemarks){
            this.showToast('INV Remarks is mandatory',this.TOST().WARNING)
            return
          }
          if(this.documentsItems[index].mandatory == 'Yes' && !this.documentsItems[index].hasOwnProperty("link") && !this.documentsItems[index].waiveOffreason){
            this.showToast('Waive off reason is mandatory',this.TOST().WARNING)
            return
          }
          let payload = {...this.documentsItems[index], ...{siuWorkitemId: this.$route.params.workitemId}};
        payload.ntidUserId = this.$route.params.tabData?.ntidUserId || this.$route.params.allData.assginDetails.ntID;
        payload.name = this.$route.params.tabData?.siuUserDetails?.investigatorDetails?.name || this.$route.params.allData.assginDetails.userName;
        delete payload.sno;
        delete payload.mandatory;
        delete payload.link;
        delete payload.deleteStatus;
        if(payload._id) {
          delete payload._id;
        }
        payload.lob = "Health";
        let res = await createDocument(payload);
        if(res.data.statusCode == 200) {
          this.showToast(res.data.msg, this.TOST().SUCCESS);
        } else {
          this.showToast(res.data.msg, this.TOST().WARNING);
        }
        this.getDocumentsList();
      } catch(e){
        this.showToast(e.response?.data?.msg || e.response?.data  || e.response || e, this.TOST().ERROR);
        console.log(e);
      }
        }else{
          console.log('dont call api')
        }
          
    },
    alphaNumeric(fieldName, name, mandatory=true) {
      console.log('fieldName', fieldName, name);
      if (fieldName?.length > 0) {
        return (v) => /^[a-zA-Z0-9]+$/.test(v) || "Enter valid " + name;
      }
    },
    numericOnly() {
      return (v) => /^[0-9 ]+$/.test(v) || `Only Numbers are allowed.`;
    },
    onSubmit() {},
    browse(sno) {
      //to select attachments
      document.getElementById("SP_fileupload"+sno).click();
    },
  },
 }
</script>
<style>
.dragbackground {
  background-color: #e7e7e7;
}
.scroller{
  overflow-y: auto;
  max-height: 600px;
}
</style>