<template>
  <div>
    <!-- this toast added by gopikrishna -->
    <v-snackbar
      v-model="openToast"
      max-width="900"
      top
      color="primary"
      @close="removeFromStore"
      >{{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click.prevent="closed">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
export default {
  data() {
    return {
      //  openToast: false,
    };
  },
  computed: {
    openToast() {
      let ToastData = this.$store.getters.fetchshowToast;
      return ToastData == undefined || ToastData == null || ToastData == ""
        ? false
        : ToastData;
    },
    message() {
      return this.$store.getters.fetchToastMessage;
    },
  },
  methods: {
    closed() {
      this.$store.commit("SHOW_TOAST", {
        value: false,
        message: "",
      });
    },
  },
};
</script>

<style scoped>
</style>