<template>
  <div>
    <v-container fluid>
      <v-form ref="form">
      <v-layout row>
        <v-flex md4 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Remarks <span>*</span>
            </v-text>
            <v-textarea
              solo
              label="Enter..."
              v-model="forensicReportRequired.remarks"
              maxlength="5000"
              counter="5000"
              class="rounded-0"
              rows="3"
              aria-required="true"
              :rules="rules.remarkMandatoryrule"
            >
            </v-textarea>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-form>
    </v-container>
    <InvestigationFooterCard 
      :tabno="sno" :formdata="getFormData" 
      ref="footerCardRef"
      @validateTabForms="validateTabForms"
      :nonMandatoryFields="nonMandatoryFields" 
      :inputFieldCorrectlyFilled="true"
      :enableFieldObject="{}"
      :componentName="'forensicReportRequired'"
      :siuWorkItemId="siuWorkItemId"
      :tabFormDataId="tabFormDataId"
    />
  </div>
</template>
<script>
import InvestigationFooterCard from './InvestigationFooterCard.vue';
import {getPrePopulatedData} from '../apiservices/Investigation';
 export default {
    data() {
        return {
            sno: 18,
            lossStatesList: ['A'],
            validationmessage: "This field is required",
            rules: {
                mandatoryrule: [(v) => !!v || this.validationmessage],
                remarkMandatoryrule: [(v) => v.length > 0 || this.validationmessage]
            },
            nonMandatoryFields: [],
            forensicReportRequired: {
              isTabSelected: true,
              remarks: ''
            }
        }
    },
    components: {
      InvestigationFooterCard 
    },
computed: {
    emailRules2(v) {
      if (v.length !== 0) {
        console.log("testing", /^[0-9 ]+$/.test(v));
        if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,6})+$/.test(v)) {
          return "E-mail must be valid";
        }
      }
    },
    getFormData() {
      return {forensicReportRequired: this.$data.forensicReportRequired};
    }
  },
  props: ['prepoulatedData', "siuWorkItemId", "tabFormDataId"],
  created() {
    let allKeys = Object.keys(this.prepoulatedData);
    allKeys.forEach(key => {
      this.forensicReportRequired[key] = this.prepoulatedData[key]
    })
  },
  methods: {
    validateTabForms(){
      if(!this.$refs.form.validate()){
        this.$refs.form.validate() 
        this.showToast("Please fill all mandatory fields", this.TOST().WARNING);
        return
      }else{
        this.$refs.footerCardRef.interimSave();
      }
    },
    dragleave(event) {
      this.visualEffect = false
    },
    browseCancel() {
      //to select attachments
    //   document.getElementById("cancelchequeupload").click()
    },
    dragover(event) {
      event.preventDefault()
      this.visualEffect = true
    },
    drop(event) {
      event.preventDefault()
    //   this.selectFile() // Trigger the onChange event manually
      this.visualEffect = false
    },
    computedDate(name) {
      return this[name] ? moment(this[name]).format("DD/MM/YYYY") : "";
    },
    goToPreviousTab() {
      this.sno--;
      let btnNumber = "myBtn" + this.sno;
      const elem = this.$refs[btnNumber];
      elem[0].$el.click();
    },
    goToNextTab() {
      this.sno++;
      console.log('this', this.$children.$options);
      let btnNumber = "myBtn" + this.sno;
      const elem = this.$refs[btnNumber];
      elem[0].$el.click();
    },
    switchTabs(sno, name) {
      this.sno = sno;
      this.tabName = name;
    },
    submit() {
      this.isAlertView = true;
    },
    accept() {
      this.isAlertView = true;
    },
    saveData() {},
    numericOnly() {
      return (v) => /^[0-9 ]+$/.test(v) || `Only Numbers are allowed.`;
    },
    onSubmit() {},
    browse() {},
    selectFile() {},
  },
}
</script>