<template>
    <v-card>

        <v-toolbar dark color="#10242B">
            <v-toolbar-title>Concessional and Penal for PAN Master</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn dark text @click="closeModal">
                    Close
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>


        <div class="p-5">
            <h4 class="mb-3"> Audit Trail </h4>

            <v-data-table :headers="auditTableHeaders" no-data-text="No Records" fixed-header :items="filterModifiedFields"
                hide-default-footer disable-pagination class="vDataTableStyling">

                <template v-slot:[`item.fieldName`]="{ item }">
                    <span> {{ fieldNameFormatter(item.fieldName) }}</span>
                </template>

                <template v-slot:[`item.newValue`]="{ item }">
                    <span
                        :class="{'primary-font-color bold' : item.oldValue !== item.newValue}"
                    >
                        {{ item.newValue }}
                    </span>
                </template>

            </v-data-table>
        </div>

    </v-card>
</template>


<script>
import moment from 'moment';

export default {
    props: {
        closeModal: Function,
        auditTrailModifications: Array,
    },
    data() {
        return {
            auditTableHeaders: [
                { text: 'Field Name', align: 'left', value: 'fieldName', sortable: false, class: "bgHeader" },
                { text: 'Old Value', align: 'left', value: 'oldValue', sortable: false },
                { text: 'New Value', align: 'left', value: 'newValue', sortable: false },
            ],
            filterModifiedFields: [],
        };
    },
    methods: {
        fieldNameFormatter(fieldName) {
            /**
             * Field name in audit trails saved in format of database structure,
             * will have to modify according to UI.
             */
            return {
                'typeOfVendor': 'Type Of Vendor',
                'vendorName': 'Vendor Name',
                'panNumber': 'PAN Number',
                'panCode': "PAN Code",
                'baseTdsRate': 'Base TDS Rate',
                'penalTdsApplicable': 'Penal TDS Applicable',
                'penalTdsCode': 'Penal TDS Code',
                'penalTdsRate': 'Penal TDS Rate',
                'financialYear': 'Financial Year',
                'concessionalTdsApplicable': 'Concessional TDS Applicable',
                'concessionalTdsRate': 'Concessional TDS Rate',
                'certificateReferenceNumber': 'Certificate Reference Number',
                'certificateIssueDate': 'Certificate Issue Date',
                'concessionalTdsEffectiveStartDate': 'Concessional TDS Effective Start Date',
                'concessionalTdsEffectiveEndDate': 'Concessional TDS Effective End Date',
                'certificateReceivedDate': 'Certificate Received Date',
                'thresholdLimit': 'Threshold Limit',
                'uploadCertificate': 'Upload Certificate',
                'remarks': 'Remarks',
                'documents': 'Documents',
            }[fieldName] || fieldName;
        },
        sanitizeTableData(data) {
            /**
            * Filtering unwanted fields.
            */
            const filtered = data.filter(d => ![
                'lob',
                'uid',
                'approveStatus',
                'status',
                'updatedBy',
                'updatedById',
                'approveRequest',
                'attachments',
            ].includes(d.fieldName));

            /**
             * Formatting Date to be displayed
             */
            filtered.forEach(d => {
                if (
                    [
                        'certificateReceivedDate',
                        'concessionalTdsEffectiveEndDate',
                        'concessionalTdsEffectiveStartDate',
                        'certificateIssueDate'
                    ].includes(d.fieldName)
                ) {
                    if (d.oldValue) d.oldValue = moment(d.oldValue).format('DD/MM/YYYY');
                    if (d.newValue) d.newValue = moment(d.newValue).format('DD/MM/YYYY');
                }
            })

            this.filterModifiedFields = filtered;
        },
    },
    mounted() {
        this.sanitizeTableData(this.auditTrailModifications);
    },
    watch: {
        auditTrailModifications(data) {
            this.sanitizeTableData(data);
        }
    }
}
</script>


<style lang="scss" scoped>
.p-5 {
    padding: 2.5rem;
}

.bold {
    font-weight: bold;
}

.primary-font-color {
  color: #23b1a9;
}
</style>