<template>
    <v-app>
        <v-form ref="tableForm">
        <v-card tile class="mb-4 pt-4">
        <v-layout color="" class=" mx-2" style="">
            <v-flex xs3>
                <v-layout column class="cust_pr mx-2 ">
                    <p class="font-weight-bold cstm-clr-pr">
                        Plan
                    </p>
                    <v-select
                        :items="planItems"
                        item-text="planName"
                        item-value="_id"
                        label="Select"
                        v-model="plan"
                        solo
                        disabled
                        class="rounded-0"
                        >
                    </v-select>
                </v-layout>
            </v-flex>

             <v-flex xs3>
                <v-layout column class="cust_pr mx-2 ">
                    <p class="font-weight-bold cstm-clr-pr">
                        Deductible Amount - Per Claim
                    </p>
                    <v-text-field
                        solo
                        label="Enter..."
                        v-model="DductbleAmntPrClm"
                        :error-messages="DductbleAmntPrClmNo_chk"
                        :disabled="inputdsbld"
                        class="rounded-0"
                        :maxlength="max10"
                        counter>
                    </v-text-field>

                </v-layout>
            </v-flex>

             <v-flex xs3>
                <v-layout column class="cust_pr mx-2 ">
                    <p class="font-weight-bold cstm-clr-pr">
                        Deductible Amount - Annual Aggregate
                    </p>
                    <v-text-field
                        v-model="DductbleAmntAnulAgg"
                        label="Enter..."
                        solo      
                        class="rounded-0"
                        :error-messages="DductbleAmntAnulAgg_chk"
                        :disabled="inputdsbld"
                        :maxlength="max10"
                        counter
                    ></v-text-field>
                </v-layout>
            </v-flex>
        </v-layout>
        <v-divider class=""></v-divider>
        <v-layout color="" class="mt-4 mx-2" style="">
            <v-flex xs3>
                <v-layout column class="cust_pr mx-2 ">
                    <p class="font-weight-bold cstm-clr-pr">
                        Waiting Period Applicable
                    </p>
                   <v-select
                        :items="WtngPrdAplbleItems"
                        label="Select"
                        v-model="WtngPrdAplble"
                        :rules="[() => !!WtngPrdAplble || 'This field is required']"
                        @change="selctdWtngPrdAplble"
                        :error-messages="WtngPrdAplble_chk"
                        :disabled="inputdsbld"
                        solo
                        class="rounded-0">
                    </v-select>
                </v-layout>
            </v-flex>

             <v-flex xs3 v-if="WtngPrdAplbleshw===true">
                <v-layout column class="cust_pr mx-2 ">
                    <p class="font-weight-bold cstm-clr-pr">
                       Waiting Period(No. of days)
                    </p>
                    <v-text-field
                        solo
                        label="Enter..."
                        v-model="WtngPrdNofDys"
                        :error-messages="WtngPrdNofDys_chk"
                        :disabled="inputdsbld"
                        class="rounded-0"
                        :maxlength="max10"
                        counter>
                    </v-text-field>

                </v-layout>
            </v-flex>

             <v-flex xs3 v-if="WtngPrdAplbleshw===true">
                <v-layout column class="cust_pr mx-2 ">
                    <p class="font-weight-bold cstm-clr-pr">
                        Waiting Period Exempt for Claim Type
                    </p>
                    <v-select
                        :items="WtngPrdExmptclmTypItems"
                        label="Select"
                        v-model="WtngPrdExmptclmTyp"
                         multiple
                        :error-messages="WtngPrdExmptclmTyp_chk"
                        :disabled="inputdsbld"
                        solo
                        class="rounded-0"
                        >
                    </v-select>

                </v-layout>
            </v-flex>
        </v-layout>
        <v-divider class=""></v-divider>
        <v-layout color="" class="mt-4 mx-2" style="">
            <v-flex xs3>
                <v-layout column class="cust_pr mx-2 ">
                    <p class="font-weight-bold cstm-clr-pr" style="word-wrap: break-word;">
                        Waiting Period for Pre-Existing Condition Applicable
                    </p>
                   <v-select
                        label="Select"
                        :items="wtndPrdPreExstngCdtnItems"
                        v-model="wtndPrdPreExstngCdtn" 
                        @change="selctdwtndPrdPreExstngCdtn" 
                        :error-messages="wtndPrdPreExstngCdtn_chk" 
                        :disabled="inputdsbld"                     
                        solo
                        class="rounded-0">
                    </v-select>
                </v-layout>
            </v-flex>
            <v-flex xs3 v-if="wtndPrdPreExstngCdtnshw===true">
                <v-layout column class="cust_pr mx-2 ">
                    <p class="font-weight-bold cstm-clr-pr" style="word-wrap: break-word;">
                        Waiting Period for Pre-Existing Condition(No. of months)
                    </p>
                   <v-text-field
                        label="Select"
                        v-model="wtndPrdPreExstngCdtnMnths"
                        solo
                        :error-messages="wtndPrdPreExstngCdtnMnths_chk" 
                        :disabled="inputdsbld"
                        class="rounded-0"
                        :maxlength="max10"
                        counter>
                    </v-text-field>
                </v-layout>
            </v-flex>

            <v-flex xs3>
                <v-layout column class="cust_pr mx-2 mt-3 ">
                    <p class="font-weight-bold cstm-clr-pr mt-2">
                        Policy Clause
                    </p>
                   <v-select
                        :items="PlcyCluseItems"
                        item-text="name"
                        item-value="_id"
                        v-model="PlcyCluse"
                        label="Select"
                        multiple
                        dense
                        attach
                        :disabled="inputdsbld"
                        solo
                        class="rounded-0"
                        >
                        <!-- <template slot="item" slot-scope="data">
                            HTML that describe how select should render items when the select is open
                            {{ data.item.name }}
                        </template> --> 

                                        <template v-slot:item="{ active, item, attrs, on }">
                                            <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                                                <v-list-item-action>
                                                <v-checkbox :input-value="active"></v-checkbox>
                                                </v-list-item-action>
                                                <v-list-item-content>
                                                    <v-list-item-title>
                                                        <v-tooltip right >
                                                            <template v-slot:activator="{ on }">
                                                                <div v-on="on" >
                                                                    {{ item.name}}
                                                                </div>
                                                            </template>
                                                            <span>{{item.name }}</span>
                                                        </v-tooltip>
                                                        <!-- <span>{{ item.name }}</span> -->
                                                    </v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </template>

                        <template v-slot:selection="{ item, index }">     
                            <span   v-if="index === 0">{{ item.name |truncate(25, '...')}}</span>
                            <span   v-if="index === 1"  class="grey--text caption">(+{{ PlcyCluse.length - 1 }})</span>
                        </template>
                    </v-select>
                </v-layout>
            </v-flex>

        </v-layout>
    </v-card>
        <v-expansion-panels class="" tile accordion  :disabled="inputdsbld" >
            <v-expansion-panel >
                <v-expansion-panel-header expand-icon="mdi-menu-down" class="font-weight-bold" >
                    Specified Illness
                </v-expansion-panel-header>
                <v-divider></v-divider>
                <v-expansion-panel-content>
                    <v-card class="custDwpd-brd-rght" >
                        <v-data-table
                            
                            :headers="spcfdIllnsheaders"
                            :items="spcfdIllnsDtaNew"
                            hide-default-footer
                            show-select
                            item-key="_id"
                            v-model="spcfdIllnsselected"
                            disable-pagination
                            style=""
                            dense
                            class="rounded-0"
                            :item-class= "row_classes"
                            @item-selected="slctSpcfdIllns"
                        >

                     
                        <template v-slot:item.icdFrom="{item}" >
                            
                            <v-layout justify-space-between >
                                <div class="">{{item.icdFrom}}</div>
                                <v-icon @click="openicdsearch('icdFrom',item)" style="">search</v-icon>
                            </v-layout>
                        </template>
                        <template v-slot:item.icdTo="{item}">
                            <v-layout justify-space-between>
                                <div class="">{{item.icdTo}}</div>
                                <v-icon style="" @click="openicdsearch('icdTo',item)">search</v-icon>
                            </v-layout>
                        </template>
                        <template v-slot:item.WaitingPeriodDays="{item}" >
                            <v-layout align-center class="cust_tbl" >
                                <v-text-field
                                label="Enter..."
                                v-model="item.WaitingPeriodDays"
                                @input="IllnessWpchng(item)"
                                solo 
                                dense
                                hide-details="auto"
                                :rules="[requiredillness(item),spclCharillness(item)]"
                                class="rounded-0"
                                :maxlength="max10"
                                counter
                                ></v-text-field>
                            </v-layout>
                        </template>
                        
                        </v-data-table>
                        <!-- <v-data-table
                        v-model="spcfdIllnsselected"
                        v-bind:headers="spcfdIllnsheaders"
                        v-bind:items="spcfdIllnsDtaNew"
                        show-select
                        item-key="_id"
                        class=" rounded-0"
                         disable-pagination
                         hide-default-footer
                        style=""
                    >
                    <template slot="headers" scope="props">
                        <tr>
                        <th>
                            <v-checkbox
                            primary
                            hide-details
                            @click.native="toggleAll"
                            :input-value="props.all"
                            :indeterminate="props.indeterminate"
                            ></v-checkbox>
                        </th>
                        <th v-for="header in props.spcfdIllnsheaders" :key="header.text">
                            <v-icon>arrow_upward</v-icon>
                            {{ header.text }}
                        </th>
                        </tr>
                    </template>
                    <template slot="items" scope="props"> -->
                        <!-- :active="props.selected" @click="props.spcfdIllnsselected = !props.spcfdIllnsselected" -->
                        <!-- <tr  :class="item-key === selectedRow ? 'custom-highlight-row' : ''" >
                            <td>
                                <v-checkbox
                                    primary
                                    hide-details
                                    :input-value="props.spcfdIllnsselected"
                                ></v-checkbox>
                            </td>
                            <td>{{ props.item.name }}</td>
                            <td class="text-xs-right">{{ props.item.icdFrom }}</td>
                            <td class="text-xs-right">{{ props.item.icdTo }}</td>
                            <td class="text-xs-right">{{ props.item.WaitingPeriodDays }}</td>
                            
                        </tr>
                    </template>
                </v-data-table> -->
                    </v-card>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel >
                <v-expansion-panel-header expand-icon="mdi-menu-down" class="font-weight-bold">
                    Specified Treatment
                </v-expansion-panel-header>
                <v-expansion-panel-content >
                    <v-card class="custDwpd-brd-rght">
                     <v-data-table
                        :headers="spcfdTrtmntheaders"
                        :items="spcfdTrtmntDtaNew"
                        hide-default-footer
                        show-select
                        item-key="_id"
                        v-model="spcfdTrtmntselected"
                        disable-pagination
                        dense
                        class="rounded-0 "
                        
                        :item-class= "row_spcfdTrtmnt_classes"
                        @item-selected="slctSpcfdTrtmnt"
                        
                    >

                    <template v-slot:item.icdFrom="{item}">
                        <!-- <div class="apart">{{item.pcsfrom}}
                            <v-icon @click="openpcssearch()" style="">search</v-icon>
                        </div> -->

                        <v-layout justify-space-between >
                                <div class="">{{item.icdFrom}}</div>
                                <v-icon @click="openpcssearch('pcsFrom',item)" style="">search</v-icon>
                        </v-layout>

                    </template>
                    <template v-slot:item.icdTo="{item}">

                        <v-layout justify-space-between >
                                <div class="">{{item.icdTo}}</div>
                                <v-icon @click="openpcssearch('pcsTo',item)" style="">search</v-icon>
                        </v-layout>
                    </template>
                    <template v-slot:item.WaitingPeriodDays="{item}" >
                            <v-layout align-center class="cust_tbl" >
                                <!-- @input="trtmntWpchng(item)" -->
                                <v-text-field
                                    label="Enter..."
                                    v-model="item.WaitingPeriodDays"
                                    solo 
                                    dense
                                    hide-details="auto"
                                    :rules="[requiredspcfdTrtmnt(item),spclCharspcfdTrtmnt(item)]"
                                    class="rounded-0"
                                    :maxlength="max10"
                                    counter
                                ></v-text-field>
                            </v-layout>
                        </template>
                    </v-data-table>
                    </v-card>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>

    <v-divider class="my-4"></v-divider>
        <v-layout color="" class=" mx-2" style="">
            <v-flex xs3>
                <v-layout column class="cust_pr mx-2 ">
                    <p class="font-weight-bold cstm-clr-pr">
                        Pre-Existing disease Applicable
                    </p>
                   <v-select
                        :items="PreExstngdsseAplicbleItms"
                        label="Select"
                        v-model="PreExstngdsseAplicble"
                        @change="selctdPreExstngdsseAplicble"
                        :error-messages="PreExstngdsseAplicble_chk"
                        :disabled="inputdsbld"
                        solo
                        class="rounded-0">
                    </v-select>
                </v-layout>
            </v-flex>

             <v-flex xs3 v-if="PreExstngdsseAplicbleshw===true">
                <v-layout column class="cust_pr mx-2 ">
                    <p class="font-weight-bold cstm-clr-pr">
                      Waiting Period(No. of months)
                    </p>
                    <v-text-field
                        solo
                        label="Enter..."
                        v-model="PreExstngWtngPrdNofmnths"
                        :error-messages="PreExstngWtngPrdNofmnths_chk"
                        :disabled="inputdsbld"
                        class="rounded-0"
                        :maxlength="max10"
                        counter>
                    </v-text-field>

                </v-layout>
            </v-flex>

        </v-layout>
        <v-card tile class="mx-3 pa-3 mb-2" style="background-color: #F7F7F7; display:flex;justify-content:space-between"  >

                <v-layout style="flex:1" justify-end>
                    <v-btn dark  tile style="background-color:#E46A25" class="text-capitalize px-3 mx-2" small @click="returnTab()"><v-icon dark small>arrow_back</v-icon> <v-divider vertical class="mx-2"></v-divider>Return</v-btn>
                    <v-btn :dark="!inputdsbld" tile style="background-color:#23B1A9" class="text-capitalize px-3 mx-2" small @click="ddctWPD_save()" :disabled="inputdsbld"><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider>Save</v-btn>
                    <v-btn dark  @click="nextTab()" tile  style="background-color:#152F38" class="text-capitalize px-3 ml-2" small><v-icon dark small>arrow_forward</v-icon> <v-divider vertical class="mx-2"></v-divider> Next</v-btn>
                </v-layout>

        </v-card>
    </v-form>
        <v-dialog v-model="icsDilg" persistent max-width="650px">
                        <v-card color="#F7F7F7" class="pb-2" tile>
                            <v-card tile>
                                <v-layout justify-space-between  class="" style="flex:1;background-ox-shadow: 0px 3px 5px #00000029;">
                                    <v-layout align-center>
                                        <v-card-title class="pa-0 pl-3 text-capitalize font-weight-bold" style="color:#10242B;font-size:13px">ICD Search and Select</v-card-title>   
                                    </v-layout>
                                    <div style="border-left:1px solid #C1C8CC;" @click="close_icdFrm()">
                                        <v-icon style="cursor: pointer;" class="pa-2" >close</v-icon>
                                    </div>
                                </v-layout>
                            </v-card>
                            <v-card tile  class="ma-4 pt-3 pb-3">
                                <v-layout color="" class="mx-2" style="">
                                    <v-flex xs5>
                                        <v-layout column class="cust_pr mx-2 ">
                                            <p class="font-weight-bold cstm-clr-pr">
                                                Short description
                                            </p>
                                            <v-text-field
                                                solo
                                                label="Enter..."
                                                v-model="shrtDesc"
                                                class="rounded-0"
                                                :rules="[minLength(3)]"
                                            >
                                            </v-text-field>
                                        </v-layout>
                                    </v-flex>
                                    <v-flex xs5>
                                        <v-layout column class="cust_pr mx-2 ">
                                            <p class="font-weight-bold cstm-clr-pr">
                                                Long description
                                            </p>
                                            <v-text-field
                                                solo
                                                label="Enter..."
                                                v-model="longDesc"
                                                class="rounded-0"
                                                :rules="[minLength(3)]"
                                            >
                                            </v-text-field>
                                        </v-layout>
                                    </v-flex>

                                    
                                        <v-layout align-center>
                                            <v-btn tile dark  
                                                style="font-size:12px;background-color:#E46A25" small class="text-capitalize mx-2 px-3"
                                                @click="icdSrch_clck()">
                                                <v-icon dark small >search</v-icon > 
                                                <v-divider vertical class="mx-2"></v-divider>
                                                Search
                                            </v-btn>
                                        </v-layout>
                                       

                                   
                                </v-layout>
                                <v-card tile class="mx-4" v-if="shwicdtbl===true">
                                    <v-layout justify-space-between align-center style="border:1px solid #C1C8CC; background-color:#f7f7f7" class="ma-0 pa-0">
                                        <p class="mx-2 mt-3 font-weight-bold" style="color:#8A8A8A;font-size:13px">
                                        ICD codes
                                        </p>
                                    </v-layout>
                                    <div style="background-color: #ffffff;border: 1px solid #c1c8cc; max-height:200px; overflow-y:auto" class="">
                                       <v-radio-group v-model="radioGroup" dense class="ma-0 pa-0" hide-details>
                                            <div style="border-top: 1px solid #c1c8cc;" v-for="codes in icdCodes" :key="codes._id" class="pa-1">
                                                <v-radio
                                                    
                                                    :value="codes.icdCode"
                                                >
                                                <div slot="label" style="display: flex; justify-content:space-between;">
                                                    <div class="" style="width: 50px">{{codes.icdCode}}</div>
                                                    <div class="pl-5">
                                                        <v-tooltip left >
                                                            <template v-slot:activator="{ on }">
                                                                <div v-on="on">
                                                                    {{codes.longDescription|truncate(45, '...')}}</div>
                                                            </template>
                                                            <span>{{codes.longDescription}}</span>
                                                            
                                                        </v-tooltip>
                                                        <!-- {{codes.longDescription}} -->
                                                    </div>
                                                </div>
                                                </v-radio>
                                                
                                            </div>
                                        </v-radio-group>  
                                    </div>
                                    <v-pagination
                                        class="pdct-stUp-bnkAcc-pgn"
                                        v-model="icdPage"
                                        @input="nextPageIcdData(icdPage)"
                                        color="#152F38"
                                        flat
                                        :length="icdpagelength"
                                        :total-visible="6"
                                    ></v-pagination>
                                </v-card>


                            </v-card>

                            
                            <v-layout class="mr-2" row justify-center style="">
                                <v-btn tile dark style="font-size:12px;background-color:#D1121B"  class=" px-3 mr-3 text-capitalize" small @click="clear_icdFrm()">
                                    <v-icon color="" small >delete</v-icon>
                                    <v-divider vertical class="ml-2 mr-2 text-capitalize"></v-divider>Clear
                                </v-btn>
                                 <v-btn tile dark style="font-size:12px;background-color:#23B1A9"  class=" px-3 text-capitalize" small @click="submit_icdFrm()">
                                    <v-icon color="" small >check_circle</v-icon>
                                    <v-divider vertical class="ml-2 mr-2 text-capitalize"></v-divider>Submit
                                </v-btn>
                            </v-layout>
                            
                            
                        </v-card>
                    </v-dialog>

                    <v-dialog v-model="pcsDilg" persistent max-width="650px">
                        <v-card color="#F7F7F7" class="pb-2" tile>
                            <v-card tile>
                                <v-layout justify-space-between  class="" style="flex:1;background-ox-shadow: 0px 3px 5px #00000029;">
                                    <v-layout align-center>
                                        <v-card-title class="pa-0 pl-3 text-capitalize font-weight-bold" style="color:#10242B;font-size:13px">PCS Search and Select</v-card-title>   
                                    </v-layout>
                                    <div style="border-left:1px solid #C1C8CC;" @click="close_pcsFrm()">
                                        <v-icon style="cursor: pointer;" class="pa-2" >close</v-icon>
                                    </div>
                                </v-layout>
                            </v-card>
                            <v-card tile  class="ma-4 pt-3 pb-3">
                                <v-layout color="" class="mx-2" style="">
                                    <v-flex xs6>
                                        <v-layout column class="cust_pr mx-2 ">
                                            <p class="font-weight-bold cstm-clr-pr">
                                                Search Description
                                            </p>
                                            <v-text-field
                                                solo
                                                label="Type set of keywords"
                                                v-model="pcslongDesc"
                                                class="rounded-0"
                                                :rules="[minLength(3)]"
                                            >
                                            </v-text-field>
                                        </v-layout>
                                    </v-flex>

                                    
                                        <v-layout align-center>
                                            <v-btn tile dark  
                                                 style="font-size:12px;background-color:#E46A25" small  class="text-capitalize mx-2 px-3"
                                                @click="pcsSrch_clck()">
                                                <v-icon dark small >search</v-icon > 
                                                <v-divider vertical class="mx-2"></v-divider>
                                                Search
                                            </v-btn>
                                        </v-layout>
                                       

                                   
                                </v-layout>
                                <v-card tile class="mx-4" v-if="shwpcstbl===true">
                                    <v-layout justify-space-between align-center style="border:1px solid #C1C8CC; background-color:#f7f7f7" class="ma-0 pa-0">
                                        <p class="mx-2 mt-3 font-weight-bold" style="color:#8A8A8A;font-size:13px">
                                        PCS Codes
                                        </p>
                                    </v-layout>
                                    <div style="background-color: #ffffff;border: 1px solid #c1c8cc; max-height:200px; overflow-y:auto" class="">
                                       <v-radio-group v-model="pcs_radioGroup" dense class="ma-0 pa-0" hide-details>
                                            <div style="border-top: 1px solid #c1c8cc;" v-for="codes in pcsCodes" :key="codes._id" class="pa-1">
                                                <v-radio
                                                    
                                                    :value="codes.pcsCode"
                                                >
                                                <div slot="label" style="display: flex; justify-content:space-between;">
                                                    <div class="" style="width: 100px">{{codes.pcsCode}}</div>
                                                    <div class="pl-5">

                                                         <v-tooltip left >
                                                            <template v-slot:activator="{ on }">
                                                                <div v-on="on">
                                                                    {{codes.procedureCodeDescription|truncate(45, '...')}}</div>
                                                            </template>
                                                            <span>{{codes.procedureCodeDescription}}</span>
                                                            
                                                        </v-tooltip>
                                                      </div>  
                                                        
                                                </div>
                                                </v-radio>
                                                
                                            </div>
                                        </v-radio-group>  
                                    </div>
                                    <v-pagination
                                        class="pdct-stUp-bnkAcc-pgn"
                                        v-model="page"
                                        @input="nextPageData(page)"
                                        color="#152F38"
                                        flat
                                        :length="pagelength"
                                        :total-visible="6"
                                    ></v-pagination>
                                </v-card>


                            </v-card>

                            
                            <v-layout class="mr-2" row justify-center style="">
                                <v-btn tile dark style="font-size:12px;background-color:#D1121B" class=" px-3 mr-3 text-capitalize" small @click="clear_pcsFrm()">
                                    <v-icon color="" small >delete</v-icon>
                                    <v-divider vertical class="ml-2 mr-2 text-capitalize"></v-divider>Clear
                                </v-btn>
                                 <v-btn tile dark style="font-size:12px;background-color:#23B1A9" class=" px-3 text-capitalize" small @click="submit_pcsFrm()">
                                    <v-icon color="" small >check_circle</v-icon>
                                    <v-divider vertical class="ml-2 mr-2 text-capitalize"></v-divider>Submit
                                </v-btn>
                            </v-layout>
                            
                            
                        </v-card>
                    </v-dialog>

    </v-app>
</template>

<script>
import axios from "axios";
import moment from 'moment';
import {numeric,required,requiredIf} from 'vuelidate/lib/validators';
const requiredIfRoot = requiredIf(function () { return this.isRequired })
const requiredIfPea  = requiredIf(function () { return this.isPeaRequired })
const requiredIfWpfpec  = requiredIf(function () { return this.isWpfpecRequired })
export default {

    
    validations: {

        
            DductbleAmntPrClm:{
              numeric
            },
            DductbleAmntAnulAgg:{
              numeric  
            },
            WtngPrdAplble:{
                required
            },
            WtngPrdNofDys:{
                required :requiredIfRoot,
                numeric  

            },
            WtngPrdExmptclmTyp:{
                required :requiredIfRoot,
            },
            PreExstngdsseAplicble:{
                required
            },
            PreExstngWtngPrdNofmnths:{
                required :requiredIfPea,
                numeric 
            },
            wtndPrdPreExstngCdtn:{
                required
            },
            wtndPrdPreExstngCdtnMnths:{
                required :requiredIfWpfpec,
                numeric 
            }


    },
    


    components:{
        
    },
    filters:{
          truncate: function (text, length, suffix) {
                if (text.length > length) {
                    return text.substring(0, length) + suffix;
                } else {
                    return text;
                }
            },
    },

    created() {
        this.$store.commit("SAVE_PAGE_HEADER", "Deductible & Waiting Period Details");
        this.getSpecifiedillness()
       
       this.getpolicyclause()
    //    this.getPcsCodes()
        this.token=this.$store.getters.successToken
       this.btnName=this.$store.getters.fetchPlcyIdForPlnData.text
      
        
        if(this.btnName==="EditPlnFrPlcy"||this.btnName==="CrteNwPlnFrPlcy"||this.btnName==="EditNwRtlPln"||this.btnName==="CrteNwRtlPln"){
            this.inputdsbld=true
        }
    },

    


     computed: {

         DductbleAmntPrClmNo_chk(){
            if(this.DductbleAmntPrClm.length > 0){

             if(/^[0-9 ]+$/g.test(this.DductbleAmntPrClm) != true){
                    // this.ttl_err = true
                    return "Only numbers are allowed"
                }else{
                    // this.ttl_err = false
                    return []
                }
              }else{
                  return []
              }
        },
        DductbleAmntAnulAgg_chk(){
            if(this.DductbleAmntAnulAgg.length > 0){

             if(/^[0-9 ]+$/g.test(this.DductbleAmntAnulAgg) != true){
                    // this.ttl_err = true
                    return "Only numbers are allowed"
                }else{
                    // this.ttl_err = false
                    return []
                }
              }else{
                  return []
              }
        },

        WtngPrdAplble_chk(){
            const errors = []
                if (!this.$v.WtngPrdAplble.$dirty) return errors
                !this.$v.WtngPrdAplble.required && errors.push('This field is required')
                return errors

        },

        wtndPrdPreExstngCdtn_chk(){
            const errors = []
                if (!this.$v.wtndPrdPreExstngCdtn.$dirty) return errors
                !this.$v.wtndPrdPreExstngCdtn.required && errors.push('This field is required')
                return errors

        },

        isRequired() {
            if(this.WtngPrdAplble==="Yes"){
                return true;
            }else if (this.WtngPrdAplble==="No"){
                return false;
            }
                
        },
        isPeaRequired(){
            if(this.PreExstngdsseAplicble==="Yes"){
                return true;
            }else if (this.PreExstngdsseAplicble==="No"){
                return false;
            }

        },
        isWpfpecRequired(){
            if(this.wtndPrdPreExstngCdtn==="Yes"){
                return true;
            }else if (this.wtndPrdPreExstngCdtn==="No"){
                return false;
            }
        },
        WtngPrdNofDys_chk(){
             
            if(this.WtngPrdNofDys.length > 0){
                console.log("from if ");
                if(/^[0-9 ]+$/g.test(this.WtngPrdNofDys) != true){
                    // this.ttl_err = true
                    return "Only numbers are allowed"
                }else{
                    // this.ttl_err = false
                    return []
                }
              }else{
                 console.log("from else diurecty check on title ", !this.$v.WtngPrdNofDys.$dirty);
                const errors = []
                if (!this.$v.WtngPrdNofDys.$dirty) return errors
                !this.$v.WtngPrdNofDys.required && errors.push('This field is required')
                return errors

            }
        },
        WtngPrdExmptclmTyp_chk(){
            const errors = []
            if (!this.$v.WtngPrdExmptclmTyp.$dirty) return errors
            !this.$v.WtngPrdExmptclmTyp.required && errors.push('This field is required')
            return errors
        },

        PreExstngdsseAplicble_chk(){
            const errors = []
            if (!this.$v.PreExstngdsseAplicble.$dirty) return errors
            !this.$v.PreExstngdsseAplicble.required && errors.push('This field is required')
            return errors
        },
        PreExstngWtngPrdNofmnths_chk(){

             if(this.PreExstngWtngPrdNofmnths.length > 0){
                console.log("from if ");
                if(/^[0-9 ]+$/g.test(this.PreExstngWtngPrdNofmnths) != true){
                    // this.ttl_err = true
                    return "Only numbers are allowed"
                }else{
                    // this.ttl_err = false
                    return []
                }
              }else{
                 console.log("from else diurecty check on title ", !this.$v.PreExstngWtngPrdNofmnths.$dirty);
                const errors = []
                if (!this.$v.PreExstngWtngPrdNofmnths.$dirty) return errors
                !this.$v.PreExstngWtngPrdNofmnths.required && errors.push('This field is required')
                return errors

            }

        },
        wtndPrdPreExstngCdtnMnths_chk(){

             if(this.wtndPrdPreExstngCdtnMnths.length > 0){
                console.log("from if ");
                if(/^[0-9 ]+$/g.test(this.wtndPrdPreExstngCdtnMnths) != true){
                    // this.ttl_err = true
                    return "Only numbers are allowed"
                }else{
                    // this.ttl_err = false
                    return []
                }
              }else{
                 console.log("from else diurecty check on title ", !this.$v.wtndPrdPreExstngCdtnMnths.$dirty);
                const errors = []
                if (!this.$v.wtndPrdPreExstngCdtnMnths.$dirty) return errors
                !this.$v.wtndPrdPreExstngCdtnMnths.required && errors.push('This field is required')
                return errors

            }

        }

        
     },

    data() {
        return {
            searchbox:false,
            hlght:false,
            hlghtTrmnt:false,
            shwicdtbl:false,
            shwpcstbl:false,
            inputdsbld:false,
            planItems:[],
            plan:"",
            btnName:'',
            DductbleAmntPrClm:"",
            DductbleAmntAnulAgg:"",
            WtngPrdAplbleItems:["Yes","No"],
            WtngPrdAplble:"",
            WtngPrdAplbleshw:false,
            PreExstngdsseAplicbleshw:false,
            wtndPrdPreExstngCdtnshw:false,
            WtngPrdNofDys:"",
            WtngPrdExmptclmTyp:[],
            WtngPrdExmptclmTypItems:["Accident"],
            PlcyCluseItems:[],
            wtndPrdPreExstngCdtn:"",
            wtndPrdPreExstngCdtnMnths:"",
            PreExstngdsseAplicbleItms:["Yes","No"],
            wtndPrdPreExstngCdtnItems:["Yes","No"],
            PreExstngdsseAplicble:"",
            PreExstngWtngPrdNofmnths:"",
            PlcyCluse:[],
            icdCodes:[],
            pcsCodes:[],
            shrtDesc:"",
            longDesc:"",
            radioGroup: "",
            pcs_radioGroup:'',
            itmID:"",
            icdtxt:"",
            pcslongDesc:"",
            pcs_itmID:"",
            pcs_icdtxt:"",
            max10:10,
            max20:20,
            max30:30,
            max40:40,
            max50:50,
            selectedRow: null,
            minLength(minLength){
                return v=>v && v.length>=minLength || `Must be atleast ${minLength} Characters`
            },
            spcfdIllnsheaders:[
               
                { text: 'Specified Illness', value: 'name', class:"hdr-txt-clr", divider:true ,sortable: false},
                { text: 'ICD Code From', value: 'icdFrom', class:"hdr-txt-clr", divider:true,sortable: false },
                { text: 'ICD Code To', value: 'icdTo', class:"hdr-txt-clr", divider:true,sortable: false },
                { text: 'Waiting Period(No. of months)', value: 'WaitingPeriodDays', class:"hdr-txt-clr", divider:true,sortable: false },
            ],
            spcfdIllnsselected:[],
            spcfdIllnsDtaNew:[],
            spcfdIllnsDta:[
                {plan:"Tumors, Cysts, polyps including breast lumps (benign)"},
                {plan:"Polycystic ovarian disease "},
                {plan:"Fibromyoma "},
                {plan:"Adenomyosis "},
                {plan:"Endometriosis "},
                {plan:"Prolapsed Uterus"},
                {plan:"Non-infective arthritis "},
                {plan:"Gout and Rheumatism"},
                {plan:"Osteoporosis"},
                {plan:"Ligament, Tendon or Meniscal tear (due to injury or otherwise)"},
                {plan:"Prolapsed Inter Vertebral Disc (due to injury or otherwise) "},
                {plan:"Cholelithiasis "},
                {plan:"Pancreatitis "},
                {plan:"Fissure/fistula in anus, haemorrhoids, pilonidal sinus"},
                {plan:"Ulcer & erosion of stomach & duodenum"},
                {plan:"Gastro Esophageal Reflux Disorder (GERD)"},
                {plan:"Liver Cirrhosis "},
                {plan:"Perineal Abscesses "},
                {plan:"Perianal / Anal Abscesses "},
                {plan:"Calculus diseases of Urogenital system Example: Kidney stone, Urinary bladder stone."},
                {plan:"Benign Hyperplasia of prostate "},
                {plan:"Varicocele "},
                {plan:"Cataract "},
                {plan:" Retinal detachment "},
                {plan:"Glaucoma"},
                {plan:"Congenital Internal Diseases"}
            ],

            spcfdTrtmntheaders:[
               
                { text: 'Specified Treatment', value: 'name', class:"hdr-txt-clr", divider:true,sortable: false },
                { text: 'PCS Code From', value: 'icdFrom', class:"hdr-txt-clr", divider:true,sortable: false },
                { text: 'PCS Code To', value: 'icdTo', class:"hdr-txt-clr", divider:true,sortable: false },
                { text: 'Waiting Period(No. of months)', value: 'WaitingPeriodDays', class:"hdr-txt-clr", divider:true,sortable: false },
            ],
            spcfdTrtmntselected:[],
            spcfdTrtmntDtaNew:[],
            spcfdTrtmntDta:[
                {plan:"Adenoidectomy "},
                {plan:"Mastoidectomy "},
                {plan:"Tonsillectomy "},
                {plan:"Tympanoplasty "},
                {plan:"Surgery for nasal septum deviation "},
                {plan:"Nasal concha resection "},
                {plan:"Surgery for Turbinate hypertrophy "},
                {plan:"Hysterectomy "},
                {plan:"Joint replacement surgeries EX: Knee replacement, Hip replacement "},
                {plan:"Cholecystectomy "},
                {plan:"Hernioplasty or Herniorraphy "},
                {plan:"Surgery/procedure for Benign prostate enlargement "},
                {plan:"Surgery for Hydrocele/ Rectocele "},
                {plan:"Surgery of varicose veins and varicose ulcers "},

                
            ],


            expand_data:[
                {
                    title:'Specified Illness',
                    tabledata:[],
                },
                {
                    title:'Specified Treatment',
                    tabledata:[],
                }
            ],

            token:null,
            icsDilg:false,
            pcsDilg:false,
            page: 1,
            icdPage:1,
            pagelength:0,
            icdpagelength:0,
            
            rules: {
                required: value => !!value || 'This Field is Required.',
                
            },
            spclChar(){
                    return v=>/^[0-9]+$/.test(v) || `Only Numbers are Allowed`
            },

        };

    },

    methods: {
        IllnessWpchng(i){
             console.log(i,"item waiting period spcfdIllnsselected")
             console.log(this.spcfdIllnsselected,"this.spcfdIllnsselected")
        },

        trtmntWpchng(incdata){
             console.log(incdata,"item waiting peiod input")
             console.log(this.spcfdTrtmntselected,"before this.spcfdTrtmntselected")
            //   for(let j=0;j<incdata.length;j++){
                    for(let i=0;i<this.spcfdTrtmntselected.length;i++){
                        if(this.spcfdTrtmntselected[i]._id===incdata._id){
                            this.spcfdTrtmntselected[i].WaitingPeriodDays=incdata.WaitingPeriodDays
                        }
                    }
                // }
                console.log ( "after self.spcfdTrtmntselected======>",this.spcfdTrtmntselected)
        },
        spclCharillness(item){
            console.log(item,"item waiting peiod")
              for(let i=0;i<this.spcfdIllnsselected.length;i++){
                    if(this.spcfdIllnsselected[i]._id===item._id){
                        // console.log(item, "item-class")
                        if(item.WaitingPeriodDays!=="" && /^[0-9]+$/g.test(this.spcfdIllnsselected[i].WaitingPeriodDays) != true){
                            return "Only Numbers are Allowed";
                        }
                    } 
                }
        },
        requiredillness(item){
            console.log(item,"item waiting peiod")
              for(let i=0;i<this.spcfdIllnsselected.length;i++){
                    if(this.spcfdIllnsselected[i]._id===item._id){
                        // console.log(item, "item-class")
                        if(item.WaitingPeriodDays===""){
                            return "This Field is Required.";
                        }
                    } 
                }
        },

        spclCharspcfdTrtmnt(item){
            console.log(item,"item waiting peiod")
            for(let i=0;i<this.spcfdTrtmntselected.length;i++){
                if(this.spcfdTrtmntselected[i]._id===item._id){
                        // console.log(item, "item-class")
                    if(item.WaitingPeriodDays!=="" && /^[0-9]+$/g.test(this.spcfdTrtmntselected[i].WaitingPeriodDays) != true){
                        return "Only Numbers are Allowed";
                    }
                } 
            }
        },
        requiredspcfdTrtmnt(item){
            for(let i=0;i<this.spcfdTrtmntselected.length;i++){
                if(this.spcfdTrtmntselected[i]._id===item._id){
                        // console.log(item, "item-class")
                    if(item.WaitingPeriodDays===""){
                        return "This Field is Required.";
                    }
                } 
            }

        },

         toggleAll () {
            if (this.spcfdIllnsselected.length) this.spcfdIllnsselected = []
            else this.spcfdIllnsselected = this.items.slice()
        },

        row_classes(item){

            if(this.hlght===true){
                for(let i=0;i<this.spcfdIllnsselected.length;i++){
                    if(this.spcfdIllnsselected[i]._id===item._id){
                        console.log(item, "item-class")
                        if(item.WaitingPeriodDays===""|| /^[0-9]+$/g.test(this.spcfdIllnsselected[i].WaitingPeriodDays) != true){
                            return "cust_color";
                        }
                    } 
                }
            }
            

        },
        slctSpcfdTrtmnt(itm){
            console.log(itm,"selected")
            for(let i=0;i<this.spcfdTrtmntDtaNew.length;i++){
                if(this.spcfdTrtmntDtaNew[i]._id===itm.item._id && itm.value===false ){
                    this.spcfdTrtmntDtaNew[i].icdFrom=""
                    this.spcfdTrtmntDtaNew[i].icdTo=""
                    this.spcfdTrtmntDtaNew[i].WaitingPeriodDays=""
                }
            }
           console.log(this.spcfdTrtmntDtaNew,"spcfdTrtmntDtaNew") 
        },
        slctSpcfdIllns(itm){
            console.log(itm,"selected")
            for(let i=0;i<this.spcfdIllnsDtaNew.length;i++){
                if(this.spcfdIllnsDtaNew[i]._id===itm.item._id && itm.value===false ){
                    this.spcfdIllnsDtaNew[i].icdFrom=""
                    this.spcfdIllnsDtaNew[i].icdTo=""
                    this.spcfdIllnsDtaNew[i].WaitingPeriodDays=""
                }
            }
           console.log(this.spcfdIllnsDtaNew,"spcfdIllnsDtaNew") 

        },
        row_spcfdTrtmnt_classes(item){

             if(this.hlghtTrmnt===true){
                for(let i=0;i<this.spcfdTrtmntselected.length;i++){
                    if(this.spcfdTrtmntselected[i]._id===item._id){
                        console.log(item, "item-class")
                        if(item.WaitingPeriodDays===""|| /^[0-9]+$/g.test(this.spcfdTrtmntselected[i].WaitingPeriodDays) != true){
                            return "cust_color";
                        }
                    } 
                }
            }
            

        },

        selctdWtngPrdAplble(){
            console.log("WtngPrdAplble",this.WtngPrdAplble)
            this.WtngPrdNofDys=''
            this.WtngPrdExmptclmTyp=''
            this.$v.WtngPrdNofDys.$reset()
            this.$v.WtngPrdExmptclmTyp.$reset()
            if(this.WtngPrdAplble==="No"){
                this.WtngPrdAplbleshw=false
            }
            if(this.WtngPrdAplble==="Yes"){
                this.WtngPrdAplbleshw=true
            }
        },

        selctdPreExstngdsseAplicble(){

             console.log("WtngPrdAplble",this.PreExstngdsseAplicble)
             this.PreExstngWtngPrdNofmnths=''
            this.$v.PreExstngWtngPrdNofmnths.$reset()
            if(this.PreExstngdsseAplicble==="No"){
                this.PreExstngdsseAplicbleshw=false
            }
            if(this.PreExstngdsseAplicble==="Yes"){
                this.PreExstngdsseAplicbleshw=true
            }

        },

        selctdwtndPrdPreExstngCdtn(){
            console.log("WtngPrdAplble",this.wtndPrdPreExstngCdtn)
            this.wtndPrdPreExstngCdtnMnths=''
            this.$v.wtndPrdPreExstngCdtnMnths.$reset()
            if(this.wtndPrdPreExstngCdtn==="No"){
                this.wtndPrdPreExstngCdtnshw=false
            }
            if(this.wtndPrdPreExstngCdtn==="Yes"){
                this.wtndPrdPreExstngCdtnshw=true
            }
        },

        openicdsearch(txt,itm){
            console.log(itm)
            this.itmID=itm._id
            this.icdtxt=txt
            this.icsDilg=true
        },
        openpcssearch(txt,itm){
            this.pcs_itmID=itm._id
            this.pcs_icdtxt=txt
           this.pcsDilg=true 
        },
        close_icdFrm(){
            this.icsDilg=false
            this.clear_icdFrm()
        },
        clear_icdFrm(){
            this.shrtDesc=""
            this.longDesc=""
            this.radioGroup=""
            this.icdCodes=[]
            this.shwicdtbl=false
        },
         close_pcsFrm(){
            this.pcsDilg=false
            this.clear_pcsFrm()
        },
        clear_pcsFrm(){
            this.pcslongDesc=""
            this.pcs_radioGroup=""
            this.pcsCodes=[]
            this.shwpcstbl=false
        },

        nextTab(){
            console.log("emitted")
            this.$emit('next');
        },
        returnTab(){
            console.log("emitted")
            this.$emit('return');
        },

        ddctWPD_save(){
            // console.log("PlcyCluse",this.PlcyCluse)
            console.log("spcfdIllnsselected",this.spcfdIllnsselected)

             console.log("spcfdTrtmntselected",this.spcfdTrtmntselected)

             
            // for(let i=0;i<this.spcfdIllnsselected.length;i++){
            //     console.log("this.spcfdIllnsselected")
            //     if(this.spcfdIllnsselected[i].WaitingPeriodDays===""||this.spcfdIllnsselected[i].icdFrom===""||this.spcfdIllnsselected[i].icdTo===""){
            //         return this.cust_color(this.spcfdIllnsselected[i]._id)
            //     }
            // }

            // return
            let pass=true
            for(let j=0;j<this.spcfdIllnsselected.length;j++){
                    for(let i=0;i<this.spcfdIllnsDtaNew.length;i++){
                        if (this.spcfdIllnsselected[j]._id===this.spcfdIllnsDtaNew[i]._id){
                            if(this.spcfdIllnsselected[j].WaitingPeriodDays===""){
                                this.row_classes(this.spcfdIllnsselected[j])
                                pass= false
                                this.hlght=true
                            }
                    }
                }
            }  
            
            for(let j=0;j<this.spcfdIllnsselected.length;j++){
                for(let i=0;i<this.spcfdIllnsDtaNew.length;i++){
                    if (this.spcfdIllnsselected[j]._id===this.spcfdIllnsDtaNew[i]._id){
                        if(this.spcfdIllnsselected[j].WaitingPeriodDays!=="" && /^[0-9]+$/g.test(this.spcfdIllnsselected[j].WaitingPeriodDays) != true){
                            this.row_classes(this.spcfdIllnsselected[j])
                                pass= false
                                this.hlght=true
                        }
                    }
                }
            }


                for(let j=0;j<this.spcfdTrtmntselected.length;j++){
                    for(let i=0;i<this.spcfdTrtmntDtaNew.length;i++){
                        if (this.spcfdTrtmntselected[j]._id===this.spcfdTrtmntDtaNew[i]._id){
                            if(this.spcfdTrtmntselected[j].WaitingPeriodDays===""){
                                this.row_spcfdTrtmnt_classes(this.spcfdTrtmntselected[j])
                                pass= false
                                this.hlghtTrmnt=true
                            }
                    }
                }
            }  
            
            for(let j=0;j<this.spcfdTrtmntselected.length;j++){
                for(let i=0;i<this.spcfdTrtmntDtaNew.length;i++){
                    if (this.spcfdTrtmntselected[j]._id===this.spcfdTrtmntDtaNew[i]._id){
                        if(this.spcfdTrtmntselected[j].WaitingPeriodDays!=="" && /^[0-9]+$/g.test(this.spcfdTrtmntselected[j].WaitingPeriodDays) != true){
                            this.row_spcfdTrtmnt_classes(this.spcfdTrtmntselected[j])
                                pass= false
                                this.hlghtTrmnt=true
                        }
                    }
                }
            }


            if(pass===false){
                this.$refs.tableForm.validate();
                this.showToast('Waiting Period (No. of Months) is Required and must be Numeric  for selected values in Specified Treatment/Specified Illness ', this.TOST().WARNING);
                return
            }
            

             this.$v.$touch();
            //  if(this.$v.DductbleAmntPrClm.$invalid ||  this.$v.DductbleAmntAnulAgg.$invalid||this.$v.WtngPrdAplble.$invalid||this.$v.WtngPrdNofDys.$invalid){
            if(this.$v.$invalid){
                console.log("Form has errors",this.$v);  
                this.showToast('Please fill all required fields', this.TOST().WARNING);
                return
            }

            let self=this
            var formData ={
                DeductibleAmountPerClaim: self.DductbleAmntPrClm,
                DeductibleAmountAnnualAggregate: self.DductbleAmntAnulAgg,
                WaitingPeriodApplicable: self.WtngPrdAplble,
                WaitingPeriodExemptForClaimType: self.WtngPrdExmptclmTyp===""?[]:self.WtngPrdExmptclmTyp,
                WaitingPeriodDays: self.WtngPrdNofDys,
                WaitingPeriodForPreExisting:self.wtndPrdPreExstngCdtn,
                WaitingPeriodForPreExistingMonths: self.wtndPrdPreExstngCdtnMnths,
                policyClause: self.PlcyCluse,
                specifiedIllness: self.spcfdIllnsselected,
                specifiedTreatment:self.spcfdTrtmntselected,
                preExistingDiseaseApplicable: self.PreExstngdsseAplicble,
                WaitingPeriodMonths: self.PreExstngWtngPrdNofmnths
            }

            let apiURL=''
            if(self.btnName==="ConfgrPlnFrPlcy"){
                apiURL=self.API_Service_admin()+"group-plan/update-group-deductable-waiting/"+self.plan._id
            }else if(self.btnName==="CnfgrNwRtlPln"){
                apiURL=self.API_Service_admin()+"retail-plan/update-retail-deductable-waiting/"+self.plan._id
            }
            // this.nextbtn=false
            console.log('Basic Plan form saved data',formData)
            axios({
                method: "put",
                url:apiURL,
                headers: {
                    "Content-Type": "application/json",
                    "x-access-token": self.token,
                    // "x-access-token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI1ZmJlMDg4ZGM0NmRhODNlZTdmODcxYmIiLCJyb2xlIjoiQWRtaW5Vc2VyIiwiaWF0IjoxNjA2MzY5MzI0LCJleHAiOjE2Mzc5MDUzMjR9.iS5hZs0uImNFz-rxNaQ7CTCc6qS_hG4H0FsePZ5Sgqc",
                },
                data:formData,
                })
            .then(function (response) {
                console.log(" Create Policy for product API Response>>>>>>", response);
                self.showToast(response.data.msg ,self.TOST().SUCCESS)

            })
            .catch(function (error) {
                console.log(" Create Policy for product Error APi Response>>>>>>", error);
                self.showToast(error.response.data.msg,self.TOST().ERROR);
            });

        },

        getSpecifiedillness(){
             console.log("getSpecifiedillness ")
                let self = this;
                 self.spcfdIllnsDtaNew=[]   
                axios({
                    method: "get",
                    url:self.API_Service_MASTER()+"plan/get-specified-illness", 
                        headers: {
                            "Content-Type": "application/json", 
                            // "x-access-token": self.token,
                        }
                })
                .then(function(response) {
                    console.log('plan/get-specified-illness------->>>',response.data.data);
                    let tempdata=response.data.data
                    // self.spcfdIllnsDtaNew=response.data.data
                   tempdata.map((itm) => {
                        self.spcfdIllnsDtaNew.push({
                            _id: itm._id,
                            name:itm.specifiedIllness,
                            icdFrom:'',
                            icdTo:'',
                            WaitingPeriodDays:''
                        });
                    });
                    console.log(self.spcfdIllnsDtaNew,"self.spcfdIllnsDtaNew")
                   self.getSpecifiedTreatment()
                })
                .catch(function(error) {
                    console.log(error);
                    // self.showToast(error.response.data.msg,self.TOST().ERROR);
                });
        },
        getSpecifiedTreatment(){
            console.log("getSpecifiedTreatment")
           
                let self = this;   
                 self.spcfdTrtmntDtaNew=[]
                axios({
                    method: "get",
                    url:self.API_Service_MASTER()+"plan/get-specified-treatment", 
                        headers: {
                            "Content-Type": "application/json", 
                            // "x-access-token": self.token,
                        }
                })
                .then(function(response) {
                    console.log('get-specified-treatments------->>>',response.data.data);
                   
                    // self.spcfdTrtmntDtaNew=response.data.data

                    let tempdata=response.data.data
                    
                   tempdata.map((itm) => {
                        self.spcfdTrtmntDtaNew.push({
                            _id: itm._id,
                            name:itm.specifiedTreatment,
                            icdFrom:'',
                            icdTo:'',
                            WaitingPeriodDays:''
                        });
                    });
                    console.log(self.spcfdTrtmntDtaNew,"self.spcfdTrtmntDtaNew")
                     if(self.btnName==="EditPlnFrPlcy"||self.btnName==="ConfgrPlnFrPlcy"){
            // self.nextbtn=false
            self.getGroupPlanbyId(self.$store.getters.fetchPlcyIdForPlnData.plan_No)
        }
         if(self.btnName==="EditNwRtlPln"||self.btnName==="CnfgrNwRtlPln"){
            self.nextbtn=false
            self.getRetailPlanbyId(self.$store.getters.fetchPlcyIdForPlnData.retailPlan_Id)
        }
                   
                })
                .catch(function(error) {
                    console.log(error);
                    // self.showToast(error.response.data.msg,self.TOST().ERROR);
                });

        },
        getGroupPlanbyId(planId){
            console.log(planId," Incoming PlanId")
            let self = this;   
            axios({
                method: "get",
                url:self.API_Service_admin()+"group-plan/get-group-plan/"+planId, 
                headers: {
                    "Content-Type": "application/json", 
                    "x-access-token": self.token,
                }
            })
            .then(function(response) {
                console.log('group-plan/get-group-plan Response------->>>',response.data.data);
                let pdtDta={}
                pdtDta=response.data.data
                self.planItems.push(pdtDta)
                self.plan=self.planItems[0]
                self.DductbleAmntPrClm=pdtDta.hasOwnProperty('deductibleWaitingPeriodDetails')?pdtDta.deductibleWaitingPeriodDetails.hasOwnProperty('DeductibleAmountPerClaim')?self.chkplndata(pdtDta.deductibleWaitingPeriodDetails.DeductibleAmountPerClaim):"":""
                self.DductbleAmntAnulAgg=pdtDta.hasOwnProperty('deductibleWaitingPeriodDetails')?pdtDta.deductibleWaitingPeriodDetails.hasOwnProperty('DeductibleAmountAnnualAggregate')?self.chkplndata(pdtDta.deductibleWaitingPeriodDetails.DeductibleAmountAnnualAggregate):"":""
                
                self.WtngPrdAplble=pdtDta.hasOwnProperty('deductibleWaitingPeriodDetails')?pdtDta.deductibleWaitingPeriodDetails.hasOwnProperty('WaitingPeriodApplicable')?self.chkplndata(pdtDta.deductibleWaitingPeriodDetails.WaitingPeriodApplicable):"":""
                self.selctdWtngPrdAplble()
                self.WtngPrdNofDys=pdtDta.hasOwnProperty('deductibleWaitingPeriodDetails')?pdtDta.deductibleWaitingPeriodDetails.hasOwnProperty('WaitingPeriodDays')?self.chkplndata(pdtDta.deductibleWaitingPeriodDetails.WaitingPeriodDays):"":""
                self.WtngPrdExmptclmTyp=pdtDta.hasOwnProperty('deductibleWaitingPeriodDetails')?pdtDta.deductibleWaitingPeriodDetails.hasOwnProperty('WaitingPeriodExemptForClaimType')?self.chkplndataarry(pdtDta.deductibleWaitingPeriodDetails.WaitingPeriodExemptForClaimType ):[]:[]
                self.wtndPrdPreExstngCdtn=pdtDta.hasOwnProperty('deductibleWaitingPeriodDetails')?pdtDta.deductibleWaitingPeriodDetails.hasOwnProperty('WaitingPeriodForPreExisting')?self.chkplndata(pdtDta.deductibleWaitingPeriodDetails.WaitingPeriodForPreExisting):"":""
                self.selctdwtndPrdPreExstngCdtn()
                self.wtndPrdPreExstngCdtnMnths=pdtDta.hasOwnProperty('deductibleWaitingPeriodDetails')?pdtDta.deductibleWaitingPeriodDetails.hasOwnProperty('WaitingPeriodForPreExistingMonths')?self.chkplndata(pdtDta.deductibleWaitingPeriodDetails.WaitingPeriodForPreExistingMonths):"":""
                self.PlcyCluse=pdtDta.hasOwnProperty('deductibleWaitingPeriodDetails')?pdtDta.deductibleWaitingPeriodDetails.hasOwnProperty('policyClause')?self.chkplndataarry(pdtDta.deductibleWaitingPeriodDetails.policyClause):[]:[]
                // self.spcfdIllnsselected=pdtDta.hasOwnProperty('deductibleWaitingPeriodDetails')?pdtDta.deductibleWaitingPeriodDetails.hasOwnProperty('specifiedIllness')?self.chkplndataarry(pdtDta.deductibleWaitingPeriodDetails.specifiedIllness):[]:[]
                let tempData=pdtDta.hasOwnProperty('deductibleWaitingPeriodDetails')?pdtDta.deductibleWaitingPeriodDetails.hasOwnProperty('specifiedIllness')?self.chkplndataarry(pdtDta.deductibleWaitingPeriodDetails.specifiedIllness):[]:[]
                let tempData2=pdtDta.hasOwnProperty('deductibleWaitingPeriodDetails')?pdtDta.deductibleWaitingPeriodDetails.hasOwnProperty('specifiedTreatment')?self.chkplndataarry(pdtDta.deductibleWaitingPeriodDetails.specifiedTreatment):[]:[]
                
                console.log("spcfdIllnsDtaNew=====> _id", self.spcfdIllnsDtaNew )
                self.spcfdIllnsselected=[]
                for(let j=0;j<tempData.length;j++){
                    for(let i=0;i<self.spcfdIllnsDtaNew.length;i++){
                        console.log("temp",tempData[j])
                        console.log("real",self.spcfdIllnsDtaNew[i])
                        if(self.spcfdIllnsDtaNew[i]._id===tempData[j]._id){
                            self.spcfdIllnsDtaNew[i].WaitingPeriodDays=tempData[j].WaitingPeriodDays
                            self.spcfdIllnsDtaNew[i].icdFrom=tempData[j].icdFrom
                            self.spcfdIllnsDtaNew[i].icdTo=tempData[j].icdTo  
                            self.spcfdIllnsselected.push(self.spcfdIllnsDtaNew[i]) 
                        }
                    }
                }
                console.log ( "self.spcfdIllnsselected======>",self.spcfdIllnsselected)


                console.log("spcfdTrtmntselected=====> _id", self.spcfdTrtmntDtaNew )
                self.spcfdTrtmntselected=[]
                for(let j=0;j<tempData2.length;j++){
                    for(let i=0;i<self.spcfdTrtmntDtaNew.length;i++){
                        console.log("temp",tempData2[j])
                        console.log("real",self.spcfdTrtmntDtaNew[i])
                        if(self.spcfdTrtmntDtaNew[i]._id===tempData2[j]._id){
                            self.spcfdTrtmntDtaNew[i].WaitingPeriodDays=tempData2[j].WaitingPeriodDays
                            self.spcfdTrtmntDtaNew[i].icdFrom=tempData2[j].icdFrom
                            self.spcfdTrtmntDtaNew[i].icdTo=tempData2[j].icdTo  
                            self.spcfdTrtmntselected.push(self.spcfdTrtmntDtaNew[i]) 
                        }
                    }
                }
                console.log ( "self.spcfdTrtmntselected======>",self.spcfdTrtmntselected)


                // self.spcfdTrtmntselected=pdtDta.hasOwnProperty('deductibleWaitingPeriodDetails')?pdtDta.deductibleWaitingPeriodDetails.hasOwnProperty('specifiedTreatment')?self.chkplndata(pdtDta.deductibleWaitingPeriodDetails.specifiedTreatment):[]:[]
                self.PreExstngdsseAplicble=pdtDta.hasOwnProperty('deductibleWaitingPeriodDetails')?pdtDta.deductibleWaitingPeriodDetails.hasOwnProperty('preExistingDiseaseApplicable')?self.chkplndata(pdtDta.deductibleWaitingPeriodDetails.preExistingDiseaseApplicable):"":""
                self.selctdPreExstngdsseAplicble()
                self.PreExstngWtngPrdNofmnths=pdtDta.hasOwnProperty('deductibleWaitingPeriodDetails')?pdtDta.deductibleWaitingPeriodDetails.hasOwnProperty('WaitingPeriodMonths')?self.chkplndata(pdtDta.deductibleWaitingPeriodDetails.WaitingPeriodMonths):"":""

               // self.selctdWtngPrdAplble()
                // self.AplcableSeltd=pdtDta.hasOwnProperty('corporateEligibility')?pdtDta.corporateEligibility.hasOwnProperty('applicableFor')?self.chkplndataarry(pdtDta.corporateEligibility.applicableFor):[]:[]
                
            })
            .catch(function(error) {
                console.log(error);
                // self.showToast(error.response.data.msg,self.TOST().ERROR);
            });
        },
        chkplndata(data){
            console.log("chkplndata",data); 
            try{
                if (data ==="" || data === "undefined" || data === null) {
                    return ""
                } else {
                    return data;
                }

             }catch(err) {
                console.log(err)
            }
            
        },
        chkplndataarry(data){
            console.log("chkplndataarry",data); 
            try{
                if (data ==="" || data === "undefined" || data === null) {
                    return []
                } else {
                    return data;
                }

             }catch(err) {
                console.log(err)
            }
            
        },
        getRetailPlanbyId(planId){
            console.log(planId," Incoming PlanId")
            let self = this;   
            axios({
                method: "get",
                url:self.API_Service_admin()+"retail-plan/get-retail-plan/"+planId, 
                headers: {
                    "Content-Type": "application/json", 
                    "x-access-token": self.token,
                }
            })
            .then(function(response) {
                console.log('retail-plan/get-retail-plan Response------->>>',response.data.data);
                let pdtDta={}
                pdtDta=response.data.data
                self.planItems.push(pdtDta)
                self.plan=self.planItems[0]
                self.DductbleAmntPrClm=pdtDta.hasOwnProperty('deductibleWaitingPeriodDetails')?pdtDta.deductibleWaitingPeriodDetails.hasOwnProperty('DeductibleAmountPerClaim')?self.chkplndata(pdtDta.deductibleWaitingPeriodDetails.DeductibleAmountPerClaim):"":""
                self.DductbleAmntAnulAgg=pdtDta.hasOwnProperty('deductibleWaitingPeriodDetails')?pdtDta.deductibleWaitingPeriodDetails.hasOwnProperty('DeductibleAmountAnnualAggregate')?self.chkplndata(pdtDta.deductibleWaitingPeriodDetails.DeductibleAmountAnnualAggregate):"":""
                
                self.WtngPrdAplble=pdtDta.hasOwnProperty('deductibleWaitingPeriodDetails')?pdtDta.deductibleWaitingPeriodDetails.hasOwnProperty('WaitingPeriodApplicable')?self.chkplndata(pdtDta.deductibleWaitingPeriodDetails.WaitingPeriodApplicable):"":""
                self.selctdWtngPrdAplble()
                self.WtngPrdNofDys=pdtDta.hasOwnProperty('deductibleWaitingPeriodDetails')?pdtDta.deductibleWaitingPeriodDetails.hasOwnProperty('WaitingPeriodDays')?self.chkplndata(pdtDta.deductibleWaitingPeriodDetails.WaitingPeriodDays):"":""
                self.WtngPrdExmptclmTyp=pdtDta.hasOwnProperty('deductibleWaitingPeriodDetails')?pdtDta.deductibleWaitingPeriodDetails.hasOwnProperty('WaitingPeriodExemptForClaimType')?self.chkplndataarry(pdtDta.deductibleWaitingPeriodDetails.WaitingPeriodExemptForClaimType ):[]:[]
                self.wtndPrdPreExstngCdtn=pdtDta.hasOwnProperty('deductibleWaitingPeriodDetails')?pdtDta.deductibleWaitingPeriodDetails.hasOwnProperty('WaitingPeriodForPreExisting')?self.chkplndata(pdtDta.deductibleWaitingPeriodDetails.WaitingPeriodForPreExisting):"":""
                self.selctdwtndPrdPreExstngCdtn()
                self.wtndPrdPreExstngCdtnMnths=pdtDta.hasOwnProperty('deductibleWaitingPeriodDetails')?pdtDta.deductibleWaitingPeriodDetails.hasOwnProperty('WaitingPeriodForPreExistingMonths')?self.chkplndata(pdtDta.deductibleWaitingPeriodDetails.WaitingPeriodForPreExistingMonths):"":""
                self.PlcyCluse=pdtDta.hasOwnProperty('deductibleWaitingPeriodDetails')?pdtDta.deductibleWaitingPeriodDetails.hasOwnProperty('policyClause')?self.chkplndataarry(pdtDta.deductibleWaitingPeriodDetails.policyClause):[]:[]
                // self.spcfdIllnsselected=pdtDta.hasOwnProperty('deductibleWaitingPeriodDetails')?pdtDta.deductibleWaitingPeriodDetails.hasOwnProperty('specifiedIllness')?self.chkplndataarry(pdtDta.deductibleWaitingPeriodDetails.specifiedIllness):[]:[]
                let tempData=pdtDta.hasOwnProperty('deductibleWaitingPeriodDetails')?pdtDta.deductibleWaitingPeriodDetails.hasOwnProperty('specifiedIllness')?self.chkplndataarry(pdtDta.deductibleWaitingPeriodDetails.specifiedIllness):[]:[]
                let tempDataTrtmnt=pdtDta.hasOwnProperty('deductibleWaitingPeriodDetails')?pdtDta.deductibleWaitingPeriodDetails.hasOwnProperty('specifiedTreatment')?self.chkplndataarry(pdtDta.deductibleWaitingPeriodDetails.specifiedTreatment):[]:[]

                
                console.log("spcfdIllnsDtaNew=====> _id", self.spcfdIllnsDtaNew )
                console.log("tempData2=====> _id", tempDataTrtmnt )
                self.spcfdIllnsselected=[]
                for(let j=0;j<tempData.length;j++){
                    for(let i=0;i<self.spcfdIllnsDtaNew.length;i++){
                        console.log("temp",tempData[j])
                        console.log("real",self.spcfdIllnsDtaNew[i])
                        if(self.spcfdIllnsDtaNew[i]._id===tempData[j]._id){
                            self.spcfdIllnsDtaNew[i].WaitingPeriodDays=tempData[j].WaitingPeriodDays
                            self.spcfdIllnsDtaNew[i].icdFrom=tempData[j].icdFrom
                            self.spcfdIllnsDtaNew[i].icdTo=tempData[j].icdTo  
                            self.spcfdIllnsselected.push(self.spcfdIllnsDtaNew[i]) 
                        }
                    }
                }
                console.log ( "self.spcfdIllnsselected======>",self.spcfdIllnsselected)

                console.log("spcfdTrtmntselected=====> _id", self.spcfdTrtmntselected )
                self.spcfdTrtmntselected=[]
                for(let j=0;j<tempDataTrtmnt.length;j++){
                    for(let i=0;i<self.spcfdTrtmntDtaNew.length;i++){
                        console.log("temp",tempDataTrtmnt[j])
                        console.log("real",self.spcfdTrtmntDtaNew[i])
                        if(self.spcfdTrtmntDtaNew[i]._id===tempDataTrtmnt[j]._id){
                            self.spcfdTrtmntDtaNew[i].WaitingPeriodDays=tempDataTrtmnt[j].WaitingPeriodDays
                            self.spcfdTrtmntDtaNew[i].icdFrom=tempDataTrtmnt[j].icdFrom
                            self.spcfdTrtmntDtaNew[i].icdTo=tempDataTrtmnt[j].icdTo  
                            self.spcfdTrtmntselected.push(self.spcfdTrtmntDtaNew[i]) 
                        }
                    }
                }
                console.log ( "self.spcfdTrtmntselected======>",self.spcfdTrtmntselected)


                // self.spcfdTrtmntselected=pdtDta.hasOwnProperty('deductibleWaitingPeriodDetails')?pdtDta.deductibleWaitingPeriodDetails.hasOwnProperty('specifiedTreatment')?self.chkplndata(pdtDta.deductibleWaitingPeriodDetails.specifiedTreatment):[]:[]
                self.PreExstngdsseAplicble=pdtDta.hasOwnProperty('deductibleWaitingPeriodDetails')?pdtDta.deductibleWaitingPeriodDetails.hasOwnProperty('preExistingDiseaseApplicable')?self.chkplndata(pdtDta.deductibleWaitingPeriodDetails.preExistingDiseaseApplicable):"":""
                self.selctdPreExstngdsseAplicble()
                self.PreExstngWtngPrdNofmnths=pdtDta.hasOwnProperty('deductibleWaitingPeriodDetails')?pdtDta.deductibleWaitingPeriodDetails.hasOwnProperty('WaitingPeriodMonths')?self.chkplndata(pdtDta.deductibleWaitingPeriodDetails.WaitingPeriodMonths):"":""

               // self.selctdWtngPrdAplble()
                // self.AplcableSeltd=pdtDta.hasOwnProperty('corporateEligibility')?pdtDta.corporateEligibility.hasOwnProperty('applicableFor')?self.chkplndataarry(pdtDta.corporateEligibility.applicableFor):[]:[]
                
            })
            .catch(function(error) {
                console.log(error);
                // self.showToast(error.response.data.msg,self.TOST().ERROR);
            });
        },
        
         getpolicyclause(){
             console.log("getSpecifiedillness ")
                let self = this;   
                self.PlcyCluseItems=[]
                axios({
                    method: "get",
                    url:self.API_Service_MASTER()+"plan/get-policy-clause", 
                        headers: {
                            "Content-Type": "application/json", 
                            // "x-access-token": self.token,
                        }
                })
                .then(function(response) {
                    console.log('plan/get-policy-clause------->>>',response.data.data);
                    self.PlcyCluseItems=response.data.data
                   
                })
                .catch(function(error) {
                    console.log(error);
                    // self.showToast(error.response.data.msg,self.TOST().ERROR);
                });
        },

        getPcsCodes(){
             console.log("getPcsCodes ")
                let self = this;   
                self.pcsCodesItems=[]
                axios({
                    method: "get",
                    url:self.API_Service_MASTER()+"plan/get-pcs-codes?procedureCodeDescription=test", 
                        headers: {
                            "Content-Type": "application/json", 
                            // "x-access-token": self.token,
                        }
                })
                .then(function(response) {
                    console.log('plan/get-pcs-codes------->>>',response.data.data);
                    self.pcsCodesItems=response.data.data
                   
                })
                .catch(function(error) {
                    console.log(error);
                    // self.showToast(error.response.data.msg,self.TOST().ERROR);
                });
        },
        icdSrch(pgNo){
            console.log("icdSrch ")
            console.log("icdSrch ",pgNo)

            if((this.shrtDesc===""&& this.longDesc==="")){
                 console.log("empty")
                 this.showToast('Please enter search criteria.', this.TOST().WARNING);
                 
                return
               
            }

            if((this.longDesc.length<3 && this.shrtDesc.length<3)){
                 console.log("empty")
                 this.showToast('Must be atleast 3 Characters.', this.TOST().WARNING);
                return
               
            }

               
            let self = this;   
    
                axios({
                    method: "get",
                    url:self.API_Service_MASTER()+"plan/get-icd-codes?pageNo="+pgNo+"&perPage=10&shortDescription="+self.shrtDesc+"&longDescription="+self.longDesc, 
                        headers: {
                            "Content-Type": "application/json", 
                            // "x-access-token": self.token,
                        }
                })
                .then(function(response) {
                    console.log('Get icd codes------->>>',response.data.data);
                    self.icdCodes=response.data.data.data
                    self.icdpagelength=response.data.data.total_pages
                    if(!self.icdCodes || self.icdCodes.length ===0){
                        self.shwicdtbl=false
                        self.showToast('No Results Found', self.TOST().WARNING);
                    }else{
                        self.shwicdtbl=true
                    }
                    
                   
                })
                .catch(function(error) {
                    console.log(error);
                    // self.showToast(error.response.data.msg,self.TOST().ERROR);
                });

        },
        submit_icdFrm(){
            if(this.radioGroup===""||this.radioGroup===null||this.radioGroup===undefined){
                this.showToast('Please Select ICD code', this.TOST().WARNING);
                return
            }
            console.log(this.radioGroup,"radioGroup")
            console.log(this.itmID,"itmID")
            for(let i=0;i<=this.spcfdIllnsDtaNew.length;i++){
                if(this.spcfdIllnsDtaNew[i]._id===this.itmID){
                    if(this.icdtxt==="icdFrom"){
                        this.spcfdIllnsDtaNew[i].icdFrom= this.radioGroup
                    }else if(this.icdtxt==="icdTo"){
                        this.spcfdIllnsDtaNew[i].icdTo= this.radioGroup
                    }
                   
                   break;
                }
            }
            this.icsDilg=false
            this.clear_icdFrm()

        },

        icdSrch_clck(){
            this.icdPage=1
             this.icdSrch(this.icdPage)
            
        },

       
        pcsSrch_clck(){
            this.page=1
            this.pcsSrch(this.page)
        },
        nextPageIcdData(page){
            console.log(page,"page")
            this.icdSrch(page)

        },
        nextPageData(page){
            console.log(page,"page")
            this.pcsSrch(page)
        },
        pcsSrch(pgNo){

            console.log("pcsSrch ",pgNo)

            if((this.pcslongDesc==="")){
                console.log("empty")
                this.showToast('Please enter search criteria.', this.TOST().WARNING);
                return  
            }

            if(this.pcslongDesc.length<3){
                console.log("empty")
                this.showToast('Must be atleast 3 Characters.', this.TOST().WARNING);
                return  
            }

               
            let self = this;   
    
                axios({
                    method: "get",
                    url:self.API_Service_MASTER()+"plan/get-pcs-codes?pageNo="+pgNo+"&perPage=10&procedureCodeDescription="+this.pcslongDesc, 
                        headers: {
                            "Content-Type": "application/json", 
                            // "x-access-token": self.token,
                        }
                })
                .then(function(response) {
                    console.log('Get icd codes------->>>',response.data.data);
                    self.pcsCodes=response.data.data.data
                    self.pagelength=response.data.data.total_pages
                    if(!self.pcsCodes || self.pcsCodes.length ===0){
                        self.shwpcstbl=false
                        self.showToast('No Results Found', self.TOST().WARNING);
                    }else{
                        self.shwpcstbl=true
                    }
                    
                   
                })
                .catch(function(error) {
                    console.log(error);
                    // self.showToast(error.response.data.msg,self.TOST().ERROR);
                });

        },
         submit_pcsFrm(){
             if(this.pcs_radioGroup===""||this.pcs_radioGroup===null||this.pcs_radioGroup===undefined){
                this.showToast('Please Select PCS code', this.TOST().WARNING);
                return
            }
            console.log(this.pcs_radioGroup,"radioGroup")
            console.log(this.pcs_itmID,"itmID")
            for(let i=0;i<=this.spcfdTrtmntDtaNew.length;i++){
                if(this.spcfdTrtmntDtaNew[i]._id===this.pcs_itmID){
                    if(this.pcs_icdtxt==="pcsFrom"){
                        this.spcfdTrtmntDtaNew[i].icdFrom= this.pcs_radioGroup
                    }else if(this.pcs_icdtxt==="pcsTo"){
                        this.spcfdTrtmntDtaNew[i].icdTo= this.pcs_radioGroup
                    }
                   
                   break;
                }
            }
            this.pcsDilg=false
            this.clear_pcsFrm()

        },


    },

        

    watch: {
    
    },
    
    
}

</script>
<style>

.cust_pr .v-text-field .v-input__slot,
.v-text-field--outline .v-input__slot {
    min-height: 42px !important;
    display: flex !important;
    align-items: center !important;
    /* border:1px red solid; */
}

.cstm-clr-pr{
    color:#646666;
    margin-bottom: 5px;
    font-size: 13px !important;
}
.cust_pr .v-input {
    font-size: 13px !important;
}
.cust_pr .v-input input {
    font-size: 13px !important;
}
.cust_pr .v-input .v-label {
    font-size: 13px !important;
}

.apart{
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    align-items:flex-end;
}

.custDwpd-brd-rght .v-data-table--dense > .v-data-table__wrapper > table > thead > tr > th{
        border-right-width: thin !important;
        border-right-style: solid !important;
        border-right-color: rgba(0, 0, 0, 0.12) !important;
    } 

.custDwpd-brd-rght .v-data-table--dense > .v-data-table__wrapper > table > tbody > tr > td{
         border-right-width: thin !important;
        border-right-style: solid !important;
        border-right-color: rgba(0, 0, 0, 0.12) !important;
}


.cust_tbl .v-text-field .v-input__slot,
.v-text-field--outline .v-input__slot {
    min-height: 35px !important;
    display: flex !important;
    align-items: center !important;
    /* border:1px red solid; */
}

.cust_tbl .v-input {
    font-size: 13px !important;
}
.cust_tbl .v-input input {
    font-size: 13px !important;
}
.cust_tbl .v-input .v-label {
    font-size: 13px !important;
}

.cust_valdn .theme--light.v-data-table tbody tr.v-data-table__selected {
    background: red !important;
}
/* .custom-highlight-row{
  background-color: red !important;
} */
.cust_color{
    background-color: orange !important; 
}
</style>