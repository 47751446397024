<template>
  <div>
    <v-container fluid>
      <v-form ref="form">
      <v-layout row>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Documents details available <span>*</span>
            </v-text>
            <v-select
              :items="['Yes', 'No']"
              label="Select"
              v-model="documentVerificationDetails.isDocumentdetailsAvailable"
              :rules="rules.mandatoryrule"
              solo
              class="rounded-0"
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              ICP papers collected (Y/N/NA) <span>*</span>
            </v-text>
            <v-select
              :items="dropDownCommonData"
              label="Select"
              v-model="documentVerificationDetails.isICPpaperscollected"
              :rules="documentVerificationDetails.isDocumentdetailsAvailable !== 'No' && rules.mandatoryrule"
              solo
              class="rounded-0"
              :disabled="documentVerificationDetails.isDocumentdetailsAvailable == 'No' ? true : false"
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Complete Medical records including Death Summary / discharge
              summary & lab test reports etc(Y/N/NA) <span>*</span>
            </v-text>
            <v-select
              :items="dropDownCommonData"
              label="Select"
              v-model="documentVerificationDetails.isCompleteMedicalrecordsAvelable"
              :rules="documentVerificationDetails.isDocumentdetailsAvailable !== 'No' && rules.mandatoryrule"
              solo
              class="rounded-0"
              :disabled="documentVerificationDetails.isDocumentdetailsAvailable == 'No' ? true : false"
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Investigation reports like blood, radiological, CT Scan, MRI and
              other Lab reports (Y/N/NA) <span>*</span>
            </v-text>
            <v-select
              :items="dropDownCommonData"
              label="Select"
              v-model="documentVerificationDetails.isInvestigationReportsAvelable"
              :rules="documentVerificationDetails.isDocumentdetailsAvailable !== 'No' && rules.mandatoryrule"
              solo
              class="rounded-0"
              :disabled="documentVerificationDetails.isDocumentdetailsAvailable == 'No' ? true : false"
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Hospital registration details collected (Y/N/NA) <span>*</span>
            </v-text>
            <v-select
              :items="dropDownCommonData"
              label="Select"
              v-model="documentVerificationDetails.isHospitalRegistrationDetailsCollected"
              :rules="documentVerificationDetails.isDocumentdetailsAvailable !== 'No' && rules.mandatoryrule"
              solo
              class="rounded-0"
              :disabled="documentVerificationDetails.isDocumentdetailsAvailable == 'No' ? true : false"
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Hospital PAN/GST details collected (Y/N/NA) <span>*</span>
            </v-text>
            <v-select
              :items="dropDownCommonData"
              label="Select"
              v-model="documentVerificationDetails.isHospitalPanOrGstDetailsCollected"
              :rules="documentVerificationDetails.isDocumentdetailsAvailable !== 'No' && rules.mandatoryrule"
              solo
              class="rounded-0"
              :disabled="documentVerificationDetails.isDocumentdetailsAvailable == 'No' ? true : false"
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              IPD register/entry details collected (Y/N/NA) <span>*</span>
            </v-text>
            <v-select
              :items="dropDownCommonData"
              label="Select"
              v-model="documentVerificationDetails.isIPDregisterOrentryDetailsCollected"
              :rules="documentVerificationDetails.isDocumentdetailsAvailable !== 'No' && rules.mandatoryrule"
              solo
              class="rounded-0"
              :disabled="documentVerificationDetails.isDocumentdetailsAvailable == 'No' ? true : false"
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Medicine purchase invoice copy collected (Y/N/NA) <span>*</span>
            </v-text>
            <v-select
              :items="dropDownCommonData"
              label="Select"
              v-model="documentVerificationDetails.isMedicinePurchaseInvoiceCopyCollected"
              :rules="documentVerificationDetails.isDocumentdetailsAvailable !== 'No' && rules.mandatoryrule"
              solo
              class="rounded-0"
              :disabled="documentVerificationDetails.isDocumentdetailsAvailable == 'No' ? true : false"
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Hospital Tariff collected (Y/N/NA) <span>*</span>
            </v-text>
            <v-select
              :items="dropDownCommonData"
              label="Select"
              v-model="documentVerificationDetails.isHospitalTariffCollected"
              :rules="documentVerificationDetails.isDocumentdetailsAvailable !== 'No' && rules.mandatoryrule"
              solo
              class="rounded-0"
              :disabled="documentVerificationDetails.isDocumentdetailsAvailable == 'No' ? true : false"
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              PAC record collected (Y/N/NA) <span>*</span>
            </v-text>
            <v-select
              :items="dropDownCommonData"
              label="Select"
              v-model="documentVerificationDetails.isPACRecordCollected"
              :rules="documentVerificationDetails.isDocumentdetailsAvailable !== 'No' && rules.mandatoryrule"
              solo
              class="rounded-0"
              :disabled="documentVerificationDetails.isDocumentdetailsAvailable == 'No' ? true : false"
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Bill carbon copy/snapshot collected (Y/N/NA) <span>*</span>
            </v-text>
            <v-select
              :items="dropDownCommonData"
              label="Select"
              v-model="documentVerificationDetails.isBillcarbonCopyOrSnapshotCollected"
              :rules="documentVerificationDetails.isDocumentdetailsAvailable !== 'No' && rules.mandatoryrule"
              solo
              class="rounded-0"
              :disabled="documentVerificationDetails.isDocumentdetailsAvailable == 'No' ? true : false"
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Payment Receipts Copy/snapshot collected (Y/N/NA) <span>*</span>
            </v-text>
            <v-select
              :items="dropDownCommonData"
              label="Select"
              v-model="documentVerificationDetails.isaymentReceiptsCopyOrsnapshotCollected"
              :rules="documentVerificationDetails.isDocumentdetailsAvailable !== 'No' && rules.mandatoryrule"
              solo
              class="rounded-0"
              :disabled="documentVerificationDetails.isDocumentdetailsAvailable == 'No' ? true : false"
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Surgery Notes collected (Y/N/NA) <span>*</span>
            </v-text>
            <v-select
              :items="dropDownCommonData"
              label="Select"
              v-model="documentVerificationDetails.isSurgeryNotesCollected"
              :rules="documentVerificationDetails.isDocumentdetailsAvailable !== 'No' && rules.mandatoryrule"
              solo
              class="rounded-0"
              :disabled="documentVerificationDetails.isDocumentdetailsAvailable == 'No' ? true : false"
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              OT register/notes collected(Y/N/NA) <span>*</span>
            </v-text>
            <v-select
              :items="dropDownCommonData"
              label="Select"
              v-model="documentVerificationDetails.isOTregisterOrNotesCollected"
              :rules="documentVerificationDetails.isDocumentdetailsAvailable !== 'No' && rules.mandatoryrule"
              solo
              class="rounded-0"
              :disabled="documentVerificationDetails.isDocumentdetailsAvailable == 'No' ? true : false"
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Pathology Lab Register copy/snapshot collected (Y/N/NA) <span>*</span>
            </v-text>
            <v-select
              :items="dropDownCommonData"
              label="Select"
              v-model="documentVerificationDetails.isPathologyLabRegisterCopyOrSnapshotCollected"
              :rules="documentVerificationDetails.isDocumentdetailsAvailable !== 'No' && rules.mandatoryrule"
              solo
              class="rounded-0"
              :disabled="documentVerificationDetails.isDocumentdetailsAvailable == 'No' ? true : false"
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Radiology Lab register copy/snapshot collected (Y/N/NA) <span>*</span>
            </v-text>
            <v-select
              :items="dropDownCommonData"
              label="Select"
              v-model="documentVerificationDetails.isRadiologyLabRegisterCopyOrsnapshotCollected"
              :rules="documentVerificationDetails.isDocumentdetailsAvailable !== 'No' && rules.mandatoryrule"
              solo
              class="rounded-0"
              :disabled="documentVerificationDetails.isDocumentdetailsAvailable == 'No' ? true : false"
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Hospital Room Images collected (Y/N/NA) <span>*</span>
            </v-text>
            <v-select
              :items="dropDownCommonData"
              label="Select"
              v-model="documentVerificationDetails.isHospitalRoomImagesCollected"
              :rules="documentVerificationDetails.isDocumentdetailsAvailable !== 'No' && rules.mandatoryrule"
              solo
              class="rounded-0"
              :disabled="documentVerificationDetails.isDocumentdetailsAvailable == 'No' ? true : false"
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Accident Spot Images collected (Y/N/NA) <span>*</span>
            </v-text>
            <v-select
              :items="dropDownCommonData"
              label="Select"
              v-model="documentVerificationDetails.isAccidentSpotImagesCollected"
              :rules="documentVerificationDetails.isDocumentdetailsAvailable !== 'No' && rules.mandatoryrule"
              solo
              class="rounded-0"
              :disabled="documentVerificationDetails.isDocumentdetailsAvailable == 'No' ? true : false"
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Statement collected from Spot (Y/N/NA) <span>*</span>
            </v-text>
            <v-select
              :items="dropDownCommonData"
              label="Select"
              v-model="documentVerificationDetails.isStatementCollectedFromSpot"
              :rules="documentVerificationDetails.isDocumentdetailsAvailable !== 'No' && rules.mandatoryrule"
              solo
              class="rounded-0"
              :disabled="documentVerificationDetails.isDocumentdetailsAvailable == 'No' ? true : false"
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              CCTV images collected (Y/N/NA) <span>*</span>
            </v-text>
            <v-select
              :items="dropDownCommonData"
              label="Select"
              v-model="documentVerificationDetails.isCCTVImagesCollected"
              :rules="documentVerificationDetails.isDocumentdetailsAvailable !== 'No' && rules.mandatoryrule"
              solo
              class="rounded-0"
              :disabled="documentVerificationDetails.isDocumentdetailsAvailable == 'No' ? true : false"
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Treating doctor statement collected (Y/N/NA) <span>*</span>
            </v-text>
            <v-select
              :items="dropDownCommonData"
              label="Select"
              v-model="documentVerificationDetails.isTreatingDoctorStatementCollected"
              :rules="documentVerificationDetails.isDocumentdetailsAvailable !== 'No' && rules.mandatoryrule"
              solo
              class="rounded-0"
              :disabled="documentVerificationDetails.isDocumentdetailsAvailable == 'No' ? true : false"
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Pathologist statement collected (Y/N/NA) <span>*</span>
            </v-text>
            <v-select
              :items="dropDownCommonData"
              label="Select"
              v-model="documentVerificationDetails.isPathologistStatementCollected"
              :rules="documentVerificationDetails.isDocumentdetailsAvailable !== 'No' && rules.mandatoryrule"
              solo
              class="rounded-0"
              :disabled="documentVerificationDetails.isDocumentdetailsAvailable == 'No' ? true : false"
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Radiologist Statement collected (Y/N/NA) <span>*</span>
            </v-text>
            <v-select
              :items="dropDownCommonData"
              label="Select"
              v-model="documentVerificationDetails.isRadiologistStatementCollected"
              :rules="documentVerificationDetails.isDocumentdetailsAvailable !== 'No' && rules.mandatoryrule"
              solo
              class="rounded-0"
              :disabled="documentVerificationDetails.isDocumentdetailsAvailable == 'No' ? true : false"
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Anesthetist Statement collected (Y/N/NA) <span>*</span>
            </v-text>
            <v-select
              :items="dropDownCommonData"
              label="Select"
              v-model="documentVerificationDetails.isAnesthetistStatementCollected"
              :rules="documentVerificationDetails.isDocumentdetailsAvailable !== 'No' && rules.mandatoryrule"
              solo
              class="rounded-0"
              :disabled="documentVerificationDetails.isDocumentdetailsAvailable == 'No' ? true : false"
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Eye Witness Statement collected (Y/N/NA) <span>*</span>
            </v-text>
            <v-select
              :items="dropDownCommonData"
              label="Select"
              v-model="documentVerificationDetails.isEyeWitnessStatementCollected"
              :rules="documentVerificationDetails.isDocumentdetailsAvailable !== 'No' && rules.mandatoryrule"
              solo
              class="rounded-0"
              :disabled="documentVerificationDetails.isDocumentdetailsAvailable == 'No' ? true : false"
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Patient Statement collected (Y/N/NA) <span>*</span>
            </v-text>
            <v-select
              :items="dropDownCommonData"
              label="Select"
              v-model="documentVerificationDetails.isPatientStatementCollected"
              :rules="documentVerificationDetails.isDocumentdetailsAvailable !== 'No' && rules.mandatoryrule"
              solo
              class="rounded-0"
              :disabled="documentVerificationDetails.isDocumentdetailsAvailable == 'No' ? true : false"
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Neighbour/Relative Statement collected (Y/N/NA) <span>*</span>
            </v-text>
            <v-select
              :items="dropDownCommonData"
              label="Select"
              v-model="documentVerificationDetails.isNeighbourOrRelativeStatementCollected"
              :rules="documentVerificationDetails.isDocumentdetailsAvailable !== 'No' && rules.mandatoryrule"
              solo
              class="rounded-0"
              :disabled="documentVerificationDetails.isDocumentdetailsAvailable == 'No' ? true : false"
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Employer Statement collected (Y/N/NA) <span>*</span>
            </v-text>
            <v-select
              :items="dropDownCommonData"
              label="Select"
              v-model="documentVerificationDetails.isEmployerStatementCollected"
              :rules="documentVerificationDetails.isDocumentdetailsAvailable !== 'No' && rules.mandatoryrule"
              solo
              class="rounded-0"
              :disabled="documentVerificationDetails.isDocumentdetailsAvailable == 'No' ? true : false"
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Co-Worker Statement collected (Y/N/NA) <span>*</span>
            </v-text>
            <v-select
              :items="dropDownCommonData"
              label="Select"
              v-model="documentVerificationDetails.isCoWorkerStatementCollected"
              :rules="documentVerificationDetails.isDocumentdetailsAvailable !== 'No' && rules.mandatoryrule"
              solo
              class="rounded-0"
              :disabled="documentVerificationDetails.isDocumentdetailsAvailable == 'No' ? true : false"
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Sarpanch/Gram Pradhan Statement collected (Y/N/NA) <span>*</span>
            </v-text>
            <v-select
              :items="dropDownCommonData"
              label="Select"
              v-model="documentVerificationDetails.isSarpanchOrGramPradhanStatementCollected"
              :rules="documentVerificationDetails.isDocumentdetailsAvailable !== 'No' && rules.mandatoryrule"
              solo
              class="rounded-0"
              :disabled="documentVerificationDetails.isDocumentdetailsAvailable == 'No' ? true : false"
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Employer Leave History/Record/Certificate collected (Y/N/NA) <span>*</span>
            </v-text>
            <v-select
              :items="dropDownCommonData"
              label="Select"
              v-model="documentVerificationDetails.isEmployerLeaveHistoryOrRecordOrCertificateCollected"
              :rules="documentVerificationDetails.isDocumentdetailsAvailable !== 'No' && rules.mandatoryrule"
              solo
              class="rounded-0"
              :disabled="documentVerificationDetails.isDocumentdetailsAvailable == 'No' ? true : false"
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Salary Slip Collected(Y/N/NA) <span>*</span>
            </v-text>
            <v-select
              :items="dropDownCommonData"
              label="Select"
              v-model="documentVerificationDetails.isSalarySlipCollected"
              :rules="documentVerificationDetails.isDocumentdetailsAvailable !== 'No' && rules.mandatoryrule"
              solo
              class="rounded-0"
              :disabled="documentVerificationDetails.isDocumentdetailsAvailable == 'No' ? true : false"
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              MLC copy collected (Y/N/NA) <span>*</span>
            </v-text>
            <v-select
              :items="dropDownCommonData"
              label="Select"
              v-model="documentVerificationDetails.isMLCcopyCollected"
              :rules="documentVerificationDetails.isDocumentdetailsAvailable !== 'No' && rules.mandatoryrule"
              solo
              class="rounded-0"
              :disabled="documentVerificationDetails.isDocumentdetailsAvailable == 'No' ? true : false"
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Death Certificate collected (Y/N/NA) <span>*</span>
            </v-text>
            <v-select
              :items="dropDownCommonData"
              label="Select"
              v-model="documentVerificationDetails.isDeathCertificateCollected"
              :rules="documentVerificationDetails.isDocumentdetailsAvailable !== 'No' && rules.mandatoryrule"
              solo
              class="rounded-0"
              :disabled="documentVerificationDetails.isDocumentdetailsAvailable == 'No' ? true : false"
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Police Panchnama copy collected (Y/N/NA) <span>*</span>
            </v-text>
            <v-select
              :items="dropDownCommonData"
              label="Select"
              v-model="documentVerificationDetails.isPolicePanchnamaCopyCollected"
              :rules="documentVerificationDetails.isDocumentdetailsAvailable !== 'No' && rules.mandatoryrule"
              solo
              class="rounded-0"
              :disabled="documentVerificationDetails.isDocumentdetailsAvailable == 'No' ? true : false"
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              PMR collected (Y/N/NA) <span>*</span>
            </v-text>
            <v-select
              :items="dropDownCommonData"
              label="Select"
              v-model="documentVerificationDetails.isPMRcollected"
              :rules="documentVerificationDetails.isDocumentdetailsAvailable !== 'No' && rules.mandatoryrule"
              solo
              class="rounded-0"
              :disabled="documentVerificationDetails.isDocumentdetailsAvailable == 'No' ? true : false"
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              FIR/GDR copy collected (Y/N/NA) <span>*</span>
            </v-text>
            <v-select
              :items="dropDownCommonData"
              label="Select"
              v-model="documentVerificationDetails.isFIROrGDRCopyCollected"
              :rules="documentVerificationDetails.isDocumentdetailsAvailable !== 'No' && rules.mandatoryrule"
              solo
              class="rounded-0"
              :disabled="documentVerificationDetails.isDocumentdetailsAvailable == 'No' ? true : false"
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Police Inquest report collected (Y/N/NA) <span>*</span>
            </v-text>
            <v-select
              :items="dropDownCommonData"
              label="Select"
              v-model="documentVerificationDetails.isPoliceInquestReportCollected"
              :rules="documentVerificationDetails.isDocumentdetailsAvailable !== 'No' && rules.mandatoryrule"
              solo
              class="rounded-0"
              :disabled="documentVerificationDetails.isDocumentdetailsAvailable == 'No' ? true : false"
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Disability Certificate copy collected (Y/N/NA) <span>*</span>
            </v-text>
            <v-select
              :items="dropDownCommonData"
              label="Select"
              v-model="documentVerificationDetails.isDisabilityCertificateCopyCollected"
              :rules="documentVerificationDetails.isDocumentdetailsAvailable !== 'No' && rules.mandatoryrule"
              solo
              class="rounded-0"
              :disabled="documentVerificationDetails.isDocumentdetailsAvailable == 'No' ? true : false"
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Aganwadi Record collected (Y/N/NA) <span>*</span>
            </v-text>
            <v-select
              :items="dropDownCommonData"
              label="Select"
              v-model="documentVerificationDetails.isAganwadiRecordCollected"
              :rules="documentVerificationDetails.isDocumentdetailsAvailable !== 'No' && rules.mandatoryrule"
              solo
              class="rounded-0"
              :disabled="documentVerificationDetails.isDocumentdetailsAvailable == 'No' ? true : false"
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Newspaper cutting collected (Y/N/NA) <span>*</span>
            </v-text>
            <v-select
              :items="dropDownCommonData"
              label="Select"
              v-model="documentVerificationDetails.isNewspaperCuttingCollected"
              :rules="documentVerificationDetails.isDocumentdetailsAvailable !== 'No' && rules.mandatoryrule"
              solo
              class="rounded-0"
              :disabled="documentVerificationDetails.isDocumentdetailsAvailable == 'No' ? true : false"
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              FSL/Chemical Analysis / Viscera Report collected(Y/N/NA) <span>*</span>
            </v-text>
            <v-select
              :items="dropDownCommonData"
              label="Select"
              v-model="documentVerificationDetails.isFSLOrChemicalAnalysisOrVisceraReportCollected"
              :rules="documentVerificationDetails.isDocumentdetailsAvailable !== 'No' && rules.mandatoryrule"
              solo
              class="rounded-0"
              :disabled="documentVerificationDetails.isDocumentdetailsAvailable == 'No' ? true : false"
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Photographs of the injured part obtained ( Y/N/NA)
            </v-text>
            <v-select
              :items="dropDownCommonData"
              label="Select"
              v-model="documentVerificationDetails.isPhotographsOfInjuredPartObtained"
              solo
              class="rounded-0"
              :disabled="documentVerificationDetails.isDocumentdetailsAvailable == 'No' ? true : false"
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Videopgraphy of the physical movement of the injured part obtained
              ( Y/N/NA)
            </v-text>
            <v-select
              :items="dropDownCommonData"
              label="Select"
              v-model="documentVerificationDetails.isVideopgraphyOfphysicalMovementOfInjuredPartObtained"
              solo
              class="rounded-0"
              :disabled="documentVerificationDetails.isDocumentdetailsAvailable == 'No' ? true : false"
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Other Policy details collected (Y/N/NA) <span>*</span>
            </v-text>
            <v-select
              :items="dropDownCommonData"
              label="Select"
              v-model="documentVerificationDetails.isOherPolicyDetailsCollected"
              :rules="documentVerificationDetails.isDocumentdetailsAvailable !== 'No' && rules.mandatoryrule"
              solo
              class="rounded-0"
              :disabled="documentVerificationDetails.isDocumentdetailsAvailable == 'No' ? true : false"
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex md4 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Remarks <span>*</span>
            </v-text>
            <v-textarea
              solo
              label="Enter..."
              v-model="documentVerificationDetails.remarks"
              maxlength="5000"
              counter="5000"
              rows="2"
              class="rounded-0"
              aria-required="true"
              :rules="rules.remarkMandatoryrule"
            >
            </v-textarea>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-form>
    </v-container>
    <InvestigationFooterCard 
      :tabno="sno" :formdata="getFormData"
      ref="footerCardRef"
      @validateTabForms="validateTabForms"
      :nonMandatoryFields="nonMandatoryFields" 
      :inputFieldCorrectlyFilled="true"
      :enableFieldObject="getEnableFieldObject"
      :componentName="'documentVerificationDetails'"
      :siuWorkItemId="siuWorkItemId"
      :tabFormDataId="tabFormDataId"
    />
  </div>
</template>
<script>
 import InvestigationFooterCard from './InvestigationFooterCard.vue';
 import {getPrePopulatedData} from '../apiservices/Investigation';
 export default{
    name: 'DocumentVerification',
    components: {
      InvestigationFooterCard 
    },
    data() {
        return {
            sno: 17,
            lossStatesList: ['A'],
            rules: {
                mandatoryrule: [(v) => !!v || this.validationmessage],
                remarkMandatoryrule: [(v) => v.trim().length  || this.validationmessage]
            },
            validationmessage: "This field is required",
            dropDownCommonData: ["Yes", "No", "NA"],
            isICPpaperscollected: "",
            documentVerificationDetails: {
              isTabSelected: true,
              isDocumentdetailsAvailable:"",
              isCompleteMedicalrecordsAvelable: "",
              isInvestigationReportsAvelable: "",
              isHospitalRegistrationDetailsCollected: "",
              isHospitalPanOrGstDetailsCollected: "",
              isIPDregisterOrentryDetailsCollected: "",
              isMedicinePurchaseInvoiceCopyCollected: "",
              isHospitalTariffCollected: "",
              isPACRecordCollected: "",
              isBillcarbonCopyOrSnapshotCollected: "",
              isaymentReceiptsCopyOrsnapshotCollected: "",
              isSurgeryNotesCollected: "",
              isOTregisterOrNotesCollected: "",
              isPathologyLabRegisterCopyOrSnapshotCollected: "",
              isRadiologyLabRegisterCopyOrsnapshotCollected: "",
              isHospitalRoomImagesCollected: "",
              isAccidentSpotImagesCollected: "",
              isStatementCollectedFromSpot: "",
              isCCTVImagesCollected: "",
              isTreatingDoctorStatementCollected: "",
              isPathologistStatementCollected: "",
              isRadiologistStatementCollected: "",
              isAnesthetistStatementCollected: "",
              isEyeWitnessStatementCollected: "",
              isPatientStatementCollected: "",
              isNeighbourOrRelativeStatementCollected: "",
              isEmployerStatementCollected: "",
              isCoWorkerStatementCollected: "",
              isSarpanchOrGramPradhanStatementCollected: "",
              isEmployerLeaveHistoryOrRecordOrCertificateCollected: "",
              isSalarySlipCollected: "",
              isMLCcopyCollected: "",
              isDeathCertificateCollected: "",
              isPolicePanchnamaCopyCollected: "",
              isPMRcollected: "",
              isFIROrGDRCopyCollected: "",
              isPoliceInquestReportCollected: "",
              isDisabilityCertificateCopyCollected: "",
              isAganwadiRecordCollected: "",
              isNewspaperCuttingCollected: "",
              isFSLOrChemicalAnalysisOrVisceraReportCollected: "",
              isPhotographsOfInjuredPartObtained: "",
              isVideopgraphyOfphysicalMovementOfInjuredPartObtained: "",
              isOherPolicyDetailsCollected: "",
              remarks: "",
            },
            enableFieldsArr: [],
            nonMandatoryFields: ['isPhotographsOfInjuredPartObtained', 'isVideopgraphyOfphysicalMovementOfInjuredPartObtained']
        }
    },
    props: ['prepoulatedData', "siuWorkItemId", "tabFormDataId"],
    created() {
      let allKeys = Object.keys(this.prepoulatedData);
      allKeys.forEach(key => {
        this.documentVerificationDetails[key] = this.prepoulatedData[key]
      })
    },
    computed: {
    emailRules2(v) {
      if (v.length !== 0) {
        console.log("testing", /^[0-9 ]+$/.test(v));
        if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,6})+$/.test(v)) {
          return "E-mail must be valid";
        }
      }
    },
    getEnableFieldObject() {
      return this.enableFieldsArr = this.documentVerificationDetails.isDocumentdetailsAvailable !== 'No' ? {} : {remarks: this.remarks};
    },
    getFormData() {
      return {documentVerificationDetails :this.$data.documentVerificationDetails};
    }
  },
//   mounted() {
//     let found = this.items.find(el => el.sno == 20);
//     if(!found){
//       this.items.push({sno: 20, name: "View / Upload Documents"});
//     }
//     console.log("INDEX NUMBER 20", found);
    
//   },
  methods: {
    validateTabForms(){
      if(!this.$refs.form.validate()){
        this.$refs.form.validate() 
        this.showToast("Please fill all mandatory fields", this.TOST().WARNING);
        return
      }else{
        this.$refs.footerCardRef.interimSave();
      }
    },
    dragleave(event) {
      this.visualEffect = false
    },
    browseCancel() {
      //to select attachments
    //   document.getElementById("cancelchequeupload").click()
    },
    dragover(event) {
      event.preventDefault()
      this.visualEffect = true
    },
    drop(event) {
      event.preventDefault()
    //   this.selectFile() // Trigger the onChange event manually
      this.visualEffect = false
    },
    computedDate(name) {
      return this[name] ? moment(this[name]).format("DD/MM/YYYY") : "";
    },
    goToPreviousTab() {
      this.sno--;
      let btnNumber = "myBtn" + this.sno;
      const elem = this.$refs[btnNumber];
      elem[0].$el.click();
    },
    goToNextTab() {
      this.sno++;
      console.log('this', this.$children.$options);
      let btnNumber = "myBtn" + this.sno;
      const elem = this.$refs[btnNumber];
      elem[0].$el.click();
    },
    switchTabs(sno, name) {
      this.sno = sno;
      this.tabName = name;
    },
    submit() {
      this.isAlertView = true;
    },
    accept() {
      this.isAlertView = true;
    },
    saveData() {},
    numericOnly() {
      return (v) => /^[0-9 ]+$/.test(v) || `Only Numbers are allowed.`;
    },
    onSubmit() {},
    browse() {},
    selectFile() {},
  },
}
</script>