<template>
    <v-app>
        <v-container class="containerStyle"
      style="padding:1.5rem; margin:0; max-width:100%">
       <h3>{{heading}} Frequent Payee Master</h3>
       <v-form ref="claimForm" lazy-validation @submit.prevent="onSubmitHandler">
       <v-layout class="mt-3">
        <v-flex class="form-group">
            <label class="form-label">
                Payee Type <span><strong class="red--text">*</strong></span>
            </label>
            <v-select
            :items="payeeType"
            solo
            dense
            class="form-control"
            v-model="frequencyPayee.payeeType"
            :disabled="isView"
            placeholder="Enter Buyer Name"></v-select>
        </v-flex>

        <v-flex class="form-group" v-show="frequencyPayee.payeeType == 'Others'">
            <label class="form-label">
                Other Name <span v-if="frequencyPayee.payeeType == 'Others'"><strong class="red--text">*</strong></span>
            </label>
            <v-text-field
            solo
            dense
            v-model="frequencyPayee.otherName"
            length="50"
            class="form-control"
            :disabled="isView"
            placeholder="Enter..."></v-text-field>
        </v-flex>

        <v-flex class="form-group">
            <label class="form-label">
                Payee Name <span><strong class="red--text">*</strong></span>
            </label>
            <v-text-field
            solo
            dense
            maxlength="138"
            v-model="frequencyPayee.payeeName"
            class="form-control"
            :disabled="isView"
            placeholder="Enter..."></v-text-field>
        </v-flex>

        <v-flex class="form-group">
            <v-checkbox
            label="Active"
            v-model="frequencyPayee.active"
            :disabled="isView"
            class="form-control"></v-checkbox>
        </v-flex>

        <v-flex class="form-group">
            <v-checkbox
            label="Watchlist"
            v-model="frequencyPayee.watchList"
            :disabled="isView"
            class="form-control"></v-checkbox>
        </v-flex>
       </v-layout>
       <v-expansion-panels v-model="panels" multiple>
       <v-expansion-panel>
            <v-expansion-panel-header color="#1EBBD9">
              <span class="page-title-small white--text" >Payee Contact Information</span>
              <template v-slot:actions>
                <v-icon class="white--text">
                  {{
                    panels.indexOf(0) !== -1 ? "mdi-minus-box" : "mdi-plus-box"
                  }}
                </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content >
                <v-layout wrap class="pt-4 form-group-container">
                    <v-flex class="form-group">
                        <label class="form-label">
                            Address Line 1 <span><strong class="red--text">*</strong></span>
                        </label>
                        <v-text-field
                        solo
                        dense
                        v-model="frequencyPayee.addressLine1"
                        class="form-control"
                        maxlength="45"
                        :rules="[(v)=>requiredField(v), (v)=>addressValidation(v)]"
                        :disabled="isView"
                        placeholder="Enter..."
                        >
                        </v-text-field>
                    </v-flex>

                    <v-flex class="form-group">
                        <label class="form-label">
                            Address Line 2
                        </label>
                        <v-text-field
                        solo
                        dense
                        v-model="frequencyPayee.addressLine2"
                        class="form-control"
                        maxlength="45"
                        :rules="[(v)=>addressValidation(v)]"
                        placeholder="Enter..."
                        :disabled="isView"
                        >
                        </v-text-field>
                    </v-flex>

                    <v-flex class="form-group">
                        <label class="form-label">
                            Address Line 3
                        </label>
                        <v-text-field
                        solo
                        dense
                        v-model="frequencyPayee.addressLine3"
                        class="form-control"
                        maxlength="35"
                        :rules="[(v)=>addressValidation(v)]"
                        placeholder="Enter..."
                        :disabled="isView"
                        >
                        </v-text-field>
                    </v-flex>

                    <v-flex class="form-group">
                        <label class="form-label">
                            PinCode <span><strong class="red--text">*</strong></span>
                        </label>
                        <v-text-field
                        solo
                        dense
                        v-model="frequencyPayee.pinCode"
                        class="form-control"
                        maxlength="6"
                        placeholder="Enter..."
                        :disabled="isView"
                        >
                        </v-text-field>
                    </v-flex>

                    <v-flex class="form-group">
                        <label class="form-label">
                            City <span><strong class="red--text">*</strong></span>
                        </label>
                        <v-text-field
                        solo
                        dense
                        disabled
                        v-model="frequencyPayee.city"
                        class="form-control"
                        maxlength="50"
                        placeholder="Enter..."
                        
                        >
                        </v-text-field>
                    </v-flex>
                </v-layout>
                <v-layout wrap class="pt-4 form-group-container">
                    <v-flex class="form-group">
                        <label class="form-label">
                            District <span><strong class="red--text">*</strong></span>
                        </label>
                        <v-text-field
                        solo
                        disabled
                        dense
                        v-model="frequencyPayee.district"
                        class="form-control"
                        maxlength="50"
                        placeholder="Enter..."
                        
                        >
                        </v-text-field>
                    </v-flex>

                    <v-flex class="form-group">
                        <label class="form-label">
                            State <span><strong class="red--text">*</strong></span>
                        </label>
                        <v-text-field
                        solo
                        dense
                        disabled
                        v-model="frequencyPayee.state"
                        class="form-control"
                        maxlength="50"
                        placeholder="Enter..."
                        
                        >
                        </v-text-field>
                    </v-flex>

                    <v-flex class="form-group">
                        <label class="form-label">
                            Country <span><strong class="red--text">*</strong></span>
                        </label>
                        <v-text-field
                        solo
                        dense
                        disabled
                        v-model="frequencyPayee.country"
                        class="form-control"
                        maxlength="50"
                        placeholder="Enter..."
                        
                        >
                        </v-text-field>
                    </v-flex>

                    <v-flex class="form-group">
                        <label class="form-label">
                            PAN <span><strong class="red--text">*</strong></span>
                        </label>
                        <v-text-field
                        solo
                        dense
                        :value="computedPAN"
                        v-model="frequencyPayee.panNumber"
                        class="form-control"
                        :maxlength="10"
                        placeholder="Enter..."
                        :disabled="isView"
                        >
                        </v-text-field>
                    </v-flex>

                    <v-flex class="form-group">
                        <label class="form-label">
                            AADHAR Card No
                        </label>
                        <v-text-field
                        solo
                        dense
                        v-model="frequencyPayee.aadharId"
                        class="form-control"
                        :maxlength="12"
                        placeholder="Enter..."
                        :disabled="isView"
                        >
                        </v-text-field>
                    </v-flex>
                </v-layout>
                <v-layout wrap class="pt-4 form-group-container">
                    <v-flex class="form-group">
                        <v-checkbox
                            label="GST Applicable"
                            v-model="frequencyPayee.gstNotApplicable"
                            :disabled="isView"
                            class="form-control"></v-checkbox>
                    </v-flex>
                    <v-flex class="form-group">
                        <label class="form-label">
                            GST Number
                        </label>
                        <v-text-field
                        solo
                        dense
                        v-model="frequencyPayee.gstinNumber"
                        class="form-control"
                        maxlength="15"
                        placeholder="Enter..."
                        :disabled="isView"
                        >
                        </v-text-field>
                    </v-flex>

                    <v-flex class="form-group">
                        <v-layout>
              <v-flex >
                <label class="form-label">
                  ISD<span class="red--text">*</span></label
                >
                <v-text-field
                  class="form-control mr-2"
                  style="max-width:110px"
                  solo
                  dense
                  :disabled="isView"
                  v-model="frequencyPayee.mobileNumber[0].isd"
                  :maxlength="5"
                  placeholder="..."
                  prepend-inner-icon=""
                >
                  <template v-slot:prepend-inner>
                    <v-icon class="iconstyle">mdi-phone</v-icon>
                  </template>
                </v-text-field>
              </v-flex>

              <v-flex >
                <label class="form-label">
                  Mobile Number<span class="red--text">*</span></label
                >
                <!-- <template v-slot:activator="{ on, attrs }">  -->
                <v-text-field
                  placeholder="Enter..."
                  solo
                  dense
                  v-model="frequencyPayee.mobileNumber[0].number"
                  :maxlength="10"
                  :disabled="isView"              
                  class="form-control"
                  
                >
                  <template v-slot:append>
                    <v-icon
                      class="blueiconstyle"
                      @click="mobile_Dialog=!mobile_Dialog" 
                      >mdi-phone-plus</v-icon
                    >
                  </template>
                </v-text-field>
              </v-flex>
            </v-layout>
                    </v-flex>
      <!-- Landline number -->
                    <v-flex class="form-group">
                        <v-layout>
              <v-flex >
                <label class="form-label">
                  STD</label
                >
                <v-text-field
                  class="form-control mr-2"
                  style="max-width:110px"
                  solo
                  dense
                  v-model="frequencyPayee.landlineNumber[0].std"
                  :maxlength="5"
                  placeholder="..."
                  prepend-inner-icon=""
                  :disabled="isView"
                >
                  <template v-slot:prepend-inner>
                    <v-icon class="iconstyle">mdi-phone</v-icon>
                  </template>
                </v-text-field>
              </v-flex>

              <v-flex >
                <label class="form-label">
                  Landline Number</label
                >
                <!-- <template v-slot:activator="{ on, attrs }">  -->
                <v-text-field
                  placeholder="Enter..."
                  solo
                  dense
                  v-model="frequencyPayee.landlineNumber[0].number"
                  :maxlength="10"             
                  class="form-control"
                  :disabled="isView"
                >
                  <template v-slot:append>
                    <v-icon
                      class="blueiconstyle"
                     
                      >mdi-phone-plus</v-icon
                    >
                  </template>
                </v-text-field>
              </v-flex>
            </v-layout>
                    </v-flex>
                </v-layout>
                </v-expansion-panel-content>
                </v-expansion-panel>
        <v-expansion-panel>
            <v-expansion-panel-header color="#1EBBD9">
              <span class="page-title-small white--text" >Contact Person Information</span>
              <template v-slot:actions>
                <v-icon class="white--text">
                  {{
                    panels.indexOf(1) !== -1 ? "mdi-minus-box" : "mdi-plus-box"
                  }}
                </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content >
                <v-layout wrap class="pt-4 form-group-container">
                    <v-flex class="form-group">
                        <label class="form-label">
                            Email Id <span><strong class="red--text">*</strong></span>
                        </label>
                        <v-text-field solo dense class="form-control" placeholder="Enter..." :disabled="isView" v-model="email_Obj[0].email" :rules="[(v)=>requiredField(v),(v) => validateEmail(v, 'Email ID')]">
                            <template v-slot:append>
                                <v-icon class="blueiconstyle" @click="email_Dialog=!email_Dialog">mdi-email-plus</v-icon>
                            </template>
                        </v-text-field>
                        <!-- <v-text-field
                        solo
                        dense
                        v-model="frequencyPayee.emails.id1"
                        class="form-control"
                        maxlength="50"
                        placeholder="Enter..."
                        :disabled="isView"
                        prepend-inner-icon="mdi-email"
                        >
                        </v-text-field> -->
                    </v-flex>

                    <!-- <v-flex class="form-group">
                        <label class="form-label">
                            Email Id2 
                        </label>
                        <v-text-field
                        solo
                        dense
                        v-model="frequencyPayee.emails.id2"
                        class="form-control"
                        maxlength="50"
                        placeholder="Enter..."
                        :disabled="isView"
                        prepend-inner-icon="mdi-email"
                        >
                        </v-text-field>
                    </v-flex>

                    <v-flex class="form-group">
                        <label class="form-label">
                            Email Id3 
                        </label>
                        <v-text-field
                        solo
                        dense
                        v-model="frequencyPayee.emails.id3"
                        class="form-control"
                        maxlength="50"
                        placeholder="Enter..."
                        :disabled="isView"
                        prepend-inner-icon="mdi-email"
                        >
                        </v-text-field>
                    </v-flex>

                    <v-flex class="form-group">
                        <label class="form-label">
                            Email Id4 
                        </label>
                        <v-text-field
                        solo
                        dense
                        v-model="frequencyPayee.emails.id4"
                        class="form-control"
                        maxlength="50"
                        placeholder="Enter..."
                        :disabled="isView"
                        prepend-inner-icon="mdi-email"
                        >
                        </v-text-field>
                    </v-flex> -->

                    <v-flex class="form-group">
                        <label class="form-label">
                            Fax No.
                        </label>
                        <v-text-field
                        solo
                        dense
                        v-model="frequencyPayee.faxNumber"
                        class="form-control"
                        maxlength="50"
                        placeholder="Enter..."
                        :disabled="isView"
                        >
                        </v-text-field>
                    </v-flex>

                    <v-flex class="form-group">
                  <label class="form-label">
                    End Date
                    <span  class="red--text"
                      ><strong>*</strong></span
                    >
                  </label>
                <v-menu
                    v-model="menu"
                    offset-y
                    nudge-top="20"
                    
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="computedEndDate"
                        placeholder="DD/MM/YYYY"
                        
                        dense
                        solo
                        readonly
                        v-on="on"
                        @focus="menu = true"
                        @blur="menu = true"
                        v-bind="attrs"
                        class="form-control"
                        append-icon="mdi-menu-down"
                        prepend-inner-icon="mdi-calendar"
                        :disabled="isView"
                      >
                      </v-text-field>
                    </template>
                    <v-date-picker
                      no-title
                      :max=getEndDate
                      v-model="frequencyPayee.endDate"
                      @input="menu2 = false"
                      
                    >
                    </v-date-picker>
                  </v-menu>
                </v-flex>
                <v-flex class="form-group">
                    <label class="form-label">Category <span><strong class="red--text">*</strong></span></label>
                    <v-select
                    solo
                    dense
                    :items="categorys"
                    v-model="frequencyPayee.category"
                    class="form-control"
                    placeholder="Select"
                    :disabled="isView"
                    ></v-select>
                </v-flex>

                <v-flex class="form-group">
                        <label class="form-label">
                            Contact Personal Name <span><strong class="red--text">*</strong></span>
                        </label>
                        <v-text-field
                        solo
                        dense
                        v-model="frequencyPayee.contactPerson.name"
                        class="form-control"
                        maxlength="50"
                        placeholder="Enter..."
                        :disabled="isView"
                        >
                        </v-text-field>
                    </v-flex>

                    <v-flex class="form-group">
                      <v-layout>
              <v-flex >
                <label class="form-label">
                  ISD<span class="red--text">*</span></label
                >
                <v-text-field
                  class="form-control mr-2"
                  style="max-width:110px"
                  solo
                  dense
                  v-model="frequencyPayee.contactPerson.isdCode"
                  :maxlength="5"
                  placeholder="..."
                  prepend-inner-icon=""
                  :disabled="isView"
                >
                  <template v-slot:prepend-inner>
                    <v-icon class="iconstyle">mdi-phone</v-icon>
                  </template>
                </v-text-field>
              </v-flex>

              <v-flex >
                <label class="form-label">
                  Contact Person No<span class="red--text">*</span></label
                >
                <!-- <template v-slot:activator="{ on, attrs }">  -->
                <v-text-field
                  placeholder="Enter..."
                  solo
                  dense
                  v-model="frequencyPayee.contactPerson.number"
                  :maxlength="10"               
                  class="form-control"
                  :disabled="isView"
                >
                  <template v-slot:append>
                    <v-icon
                      class="blueiconstyle"
                     
                      >mdi-phone-plus</v-icon
                    >
                  </template>
                </v-text-field>
              </v-flex>
            </v-layout>
                    </v-flex>
                    </v-layout>
                </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
        <v-expansion-panel-header color="#1EBBD9">
              <span class="page-title-small white--text" >Bank Details</span>
              <template v-slot:actions>
                <v-icon class="white--text">
                  {{
                    panels.indexOf(2) !== -1 ? "mdi-minus-box" : "mdi-plus-box"
                  }}
                </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content >
                <v-layout wrap class="pt-4 form-group-container">
                    <v-flex class="form-group">
                        <v-checkbox
                        class="form-control"
                        label="Bank Details"
                        v-model="frequencyPayee.bank.bankDetails"
                        @change="checkBankDetails"
                        :disabled="isView"
                        >
                          </v-checkbox>
                    </v-flex>
                    <v-spacer></v-spacer>
                    <div class="me-3 px-2" v-if="cancelledChequeData.length > 0">
                                    <label :disabled="!isOCRUploaded " class="form-label mt-2 text-right"
                                        @click="cancelChequeView">
                                        <div style="color:#0AB417; font: normal normal medium 12px/14px Roboto;cursor:pointer">
                                            <v-icon color="#0AB417">
                                                mdi-eye
                                            </v-icon>
                                            <span class="ml-2">View Cancelled Cheque</span>
                                        </div>
                                    </label>
                                </div>

                                <div>
                    <p v-show="frequencyPayee.bank.bankDetails" class="teal--text mr-8" :disabled="isView" style="cursor:pointer" @dragover="dragoverCancel"  @drop="dropCancel" @click="browseCancel" >
                      <v-icon color="#23B1A9" class="mr-3">mdi-tray-arrow-up</v-icon>Upload Canceled Check
                      <v-file-input style="display:none;" v-model="cancelledCheque"
                        id="cancelchequeupload" label="" dense solo @change="OCRUpload" :disabled="isView"
                        accept="image/png,image/jpeg,application/msword,application/pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        /></p>
                                          
                      </div>
                     </v-layout>
                     <v-layout wrap class="pt-4 form-group-container">
                                <v-layout class="py-4 cancel-cheque-view-port" v-if="cancelledChequeFileShow && cancelledChequeData.length > 0">
                                    <v-flex xs12>
                                        <div class="close-cancell-cheque"
                                            @click="cancelledChequeFileShow = !cancelledChequeFileShow">Close</div>
                                        <v-layout justify-center>
                                            <object :data="cancelledChequeData[0].fileBase ? cancelledChequeData[0].fileBase:showCheque" width="1000"
                                                height="300"></object>
                                            <!-- <img height="300" :src="investigatorMasterObj.documents.fileBase" /> -->
                                        </v-layout>
                                        <!-- <v-layout justify-center class="mt-2">
                                            <div class="cancel-cheque-success-btn">OCR was successfully completed.</div>
                                        </v-layout> -->
                                    </v-flex>
                                </v-layout>
                            
                              </v-layout>
                     <v-layout wrap class="pt-4 form-group-container">
                      <v-flex class="form-group" v-show="frequencyPayee.bank.bankDetails">
                        <v-checkbox
                        class="form-control"
                        label="Over Written"
                        v-model="frequencyPayee.overwritten[0].status"
                        :disabled="!cancelledChequeData.length>0 || isView"
                        type="checkbox"
                        @click="checkoverwritten"
                        >
                          </v-checkbox>
                    </v-flex>
                            <v-flex class="form-group">
                                <label class="form-label">IFSC Code 
                                  <span v-if="frequencyPayee.bank.bankDetails"><strong class="red--text">*</strong></span></label>
                                <v-autocomplete solo dense
                                class="form-control"
                                placeholder="Enter..."
                                v-model="frequencyPayee.bank.ifscCode"
                                disabled
                                :items="IFSCCodes"
                                item-text="ifscCode"
                                :search-input.sync="ifsc"
                                @keyup="ifscData"
                                maxlength="11"
                                >
                                </v-autocomplete>
                            </v-flex>

                            <v-flex class="form-group">
                                <label class="form-label">Bank Name <span v-if="frequencyPayee.bank.bankDetails"><strong class="red--text">*</strong></span></label>
                                <v-text-field solo dense
                                class="form-control"
                                placeholder="Enter..."
                                v-model="frequencyPayee.bank.bankName"
                                disabled
                                >
                                </v-text-field>
                            </v-flex>

                            <v-flex class="form-group">
                                <label class="form-label">City <span v-if="frequencyPayee.bank.bankDetails"><strong class="red--text">*</strong></span></label>
                                <v-text-field solo dense
                                class="form-control"
                                placeholder="Enter..."
                                v-model="frequencyPayee.bank.city"
                                disabled
                                >
                                </v-text-field>
                            </v-flex>

                            <v-flex class="form-group">
                                <label class="form-label">Bank Address1 <span v-if="frequencyPayee.bank.bankDetails"><strong class="red--text">*</strong></span></label>
                                <v-text-field solo dense
                                class="form-control"
                                placeholder="Enter..."
                                v-model="frequencyPayee.bank.bankAddressLine1"
                                disabled
                                >
                                </v-text-field>
                            </v-flex>

                            <v-flex class="form-group">
                                <label class="form-label">Bank Address2 <span v-if="frequencyPayee.bank.bankDetails"><strong class="red--text">*</strong></span></label>
                                <v-text-field solo dense
                                class="form-control"
                                placeholder="Enter..."
                                v-model="frequencyPayee.bank.bankAddressLine2"
                                disabled
                                >
                                </v-text-field>
                            </v-flex>

                            <v-flex class="form-group">
                                <label class="form-label">Bank Address3 <span v-if="frequencyPayee.bank.bankDetails"><strong class="red--text">*</strong></span></label>
                                <v-text-field solo dense
                                class="form-control"
                                placeholder="Enter..."
                                v-model="frequencyPayee.bank.bankAddressLine3"
                                disabled
                                >
                                </v-text-field>
                            </v-flex>

                            <v-flex class="form-group">
                                <label class="form-label">Branch <span v-if="frequencyPayee.bank.bankDetails"><strong class="red--text">*</strong></span></label>
                                <v-text-field solo dense
                                class="form-control"
                                placeholder="Enter..."
                                v-model="frequencyPayee.bank.branch"
                                disabled
                                >
                                </v-text-field>
                            </v-flex>

                            <v-flex class="form-group">
                                <label class="form-label">District <span v-if="frequencyPayee.bank.bankDetails"><strong class="red--text">*</strong></span></label>
                                <v-text-field solo dense
                                class="form-control"
                                placeholder="Enter..."
                                v-model="frequencyPayee.bank.district"
                                disabled
                                >
                                </v-text-field>
                            </v-flex>

                            <v-flex class="form-group">
                                <label class="form-label">State <span v-if="frequencyPayee.bank.bankDetails"><strong class="red--text">*</strong></span></label>
                                <v-text-field solo dense
                                class="form-control"
                                placeholder="Enter..."
                                v-model="frequencyPayee.bank.state"
                                disabled
                                >
                                </v-text-field>
                            </v-flex>

                            <v-flex class="form-group">
                                <label class="form-label">Pincode <span v-if="frequencyPayee.bank.bankDetails"><strong class="red--text">*</strong></span></label>
                                <v-text-field solo dense
                                class="form-control"
                                placeholder="Enter..."
                                v-model="frequencyPayee.bank.pinCode"
                                disabled
                                >
                                </v-text-field>
                            </v-flex>

                            <v-flex class="form-group">
                                <label class="form-label">Bank Account Number <span v-if="frequencyPayee.bank.bankDetails"><strong class="red--text">*</strong></span></label>
                                <v-text-field solo dense
                                class="form-control"
                                placeholder="Enter..."
                                maxlength="35"
                                @change="checkBankAccount"
                                v-model="frequencyPayee.bank.accountNumber"
                                disabled
                                >
                                </v-text-field>
                            </v-flex>

                            <v-flex class="form-group">
                                <label class="form-label">Re-Enter Bank Account Number <span v-if="frequencyPayee.bank.bankDetails"><strong class="red--text">*</strong></span></label>
                                <v-text-field solo dense
                                class="form-control"
                                placeholder="Enter..."
                                v-model="frequencyPayee.bank.confirmAccountNumber"
                                disabled
                                @paste.prevent
                                >
                                </v-text-field>
                            </v-flex>

                            <v-flex class="form-group">
                                <label class="form-label">Account Type <span v-if="frequencyPayee.bank.bankDetails"><strong class="red--text">*</strong></span></label>
                                <v-select solo dense
                                class="form-control"
                                placeholder="Enter..."
                                :items="accountTypes"
                                v-model="frequencyPayee.bank.accountType"
                                :disabled="isView"
                                >
                                </v-select>
                            </v-flex>

                            <v-flex class="form-group">
                                <label class="form-label">MSME <span><strong class="red--text">*</strong></span></label>
                                <v-select solo dense
                                class="form-control"
                                placeholder="Enter..."
                                :items="msmes"
                                v-model="frequencyPayee.bank.msme"
                                disabled
                                >
                                </v-select>
                            </v-flex>

                            <v-flex class="form-group">
                                <label class="form-label">Enterprise classification
                                   <span v-if="frequencyPayee.bank.msme=='Yes'"><strong class="red--text">*</strong></span></label>
                                <v-select solo dense
                                class="form-control"
                                placeholder="Enter..."
                                v-model="frequencyPayee.bank.enterpriseClassification"
                                :items="enterpriseclassifications"
                                disabled
                                >
                                </v-select>
                            </v-flex>

                            <v-flex class="form-group">
                                <label class="form-label">Enterprise category <span v-if="frequencyPayee.bank.msme=='Yes'"><strong class="red--text">*</strong></span></label>
                                <v-select solo dense
                                class="form-control"
                                placeholder="Enter..."
                                v-model="frequencyPayee.bank.enterpriseCategory"
                                :items="enterprisecategorys"
                                disabled
                                >
                                </v-select>
                            </v-flex>

                            <v-flex class="form-group">
                                <label class="form-label">UAN <span v-if="frequencyPayee.bank.msme=='Yes'"><strong class="red--text">*</strong></span></label>
                                <v-text-field solo dense
                                class="form-control"
                                v-model="frequencyPayee.bank.uan"
                                placeholder="Enter..."
                                disabled
                                >
                                </v-text-field>
                            </v-flex>

                            <v-flex class="form-group">
                                <label class="form-label">CKYC</label>
                                <v-text-field solo dense
                                class="form-control"
                                placeholder="Enter..."
                                maxlength="20"
                                v-model="frequencyPayee.bank.cKYC"
                                :disabled="isView"
                                >
                                </v-text-field>
                            </v-flex>
                        </v-layout>
            </v-expansion-panel-content>
            </v-expansion-panel>
            
            <v-expansion-panel>
            <v-expansion-panel-header color="#1EBBD9">
              <span class="page-title-small white--text" >Upload Documents</span>
              <template v-slot:actions>
                <v-icon class="white--text">
                  {{
                    panels.indexOf(3) !== -1 ? "mdi-minus-box" : "mdi-plus-box"
                  }}
                </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content >
                <v-layout wrap class="pt-4 form-group-container">
                  <v-tabs
                    v-model="tab"
                    hide-slider
                    
                  >
                  <!-- active-class="claimProcessingTabsActive" -->
                    <v-tab
                      v-for="item in tab_items"
                      :key="item"
                      @change="documentTab=item;checkTabChanged()"
                      :class="documentTab == item ? 'claimProcessingTabsActive' :'unActive'"
                    >
                      {{ item }}
                    </v-tab>
                  </v-tabs>
                  <v-layout class="mt-5">
                  <div :disabled="isView">
                    <div class="neftStyle" :disabled="isView"  @dragover="dragover" @dragleave="dragleave" @drop="drop" 
                    :class="visualEffect ? 'dragbackground': ''">
                      <v-icon color="#23b1a9" class="iconfont">mdi-tray-arrow-up</v-icon>
                      <span class="ml-2 margincls font-weight-bold" :disabled="isView">Drop Files To Upload or <a @click="browse" ><u > Browse</u></a></span>
                      <p class="margincls dlt_text mb-0">Max 5MB of PDF, Word, Excel, Mail or Image(.jpeg, .jpg, .png)</p>
                      <p class="dlt_text mt-0 ml-4" style="font-size:10px">Multiple documents can be uploaded</p>
                      
                    </div>
                    <span></span>
                            <v-file-input
                                multiple
                                v-model="all_attachments"
                                @change="selectFile"
                                accept=".doc, .docx, .csv, image/png,image/jpeg,application/msword,application/pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/csv"
                                id="SP_fileupload"
                                :disabled="isView"
                                style="display: none">
                            </v-file-input>
                            <!-- @change="selectFile" -->
                  </div>
                  <div>
                    <v-data-table
                            :headers="neftHeaders"
                            :items="documentAttachments"                            
                            hide-default-footer
                            no-data-text="No Records for Attachements"
                            class="tabelwidth">
                            <template v-slot:[`item.action`] = '{ item, index }'>            
                                <v-icon class="tableicons mr-4" color="#23B1A9" @click="viewDocument(item)">mdi-eye</v-icon>
                                <v-icon class="tableicons mr-4" color="#23B1A9" @click="downloadDoc(item)">mdi-download</v-icon>
                                <v-icon class="tableicons" color="#D1121B" @click="deleteDocument(index)" v-if="!(item.documentId )" :disabled="isView">mdi-delete</v-icon>
                            </template>
                        </v-data-table>
                  </div>
                  </v-layout>

                </v-layout>
                </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-form>


            <v-layout class="mt-5">
                
                <v-btn outlined color="red" class="ml-5 btn" @click="cancel(false)">
                   <v-icon> mdi-backspace </v-icon>
                    <v-divider vertical class="mx-2"></v-divider> Cancel</v-btn>
                <v-spacer></v-spacer>
                <div v-if="showPDScoreDetails"  class="font-weight-bold hyperLinkColor spaceBetweenElements" @click="openPennyDropPaymentPopup">PennyDrop Payment Verification</div>
                <v-btn v-if="showPDScoreDetails"  @click="showPdDetails" dark tile color="black" class="text-capitalize px-3 mx-3 textBoxMargin" small><v-icon dark small>visibility</v-icon> <v-divider vertical class="mx-2"></v-divider>PD Details</v-btn>
                <v-btn
                  outlined
                  color="orange"
                  class="btn mr-5"
                  v-show="isView"
                  @click="openAuditTrail"
                >
                  <v-icon> mdi-chart-timeline-variant </v-icon>
                  <v-divider vertical class="mx-2"> </v-divider>Audit Trail</v-btn
                >
                <v-btn outlined color="red" class="btn mr-5"  v-show="isView" @click="reject_Dialog=!reject_Dialog">
                   <v-icon> mdi-backspace </v-icon>
                    <v-divider vertical class="mx-2">
                      </v-divider> Reject</v-btn>

                <v-btn class="btn" :class="{ 'approveButtonDisabled' : checkIsPdScoreVerify}" color="teal white--text" dense solo v-show="isView" @click="confirmationForApproval"><v-icon>mdi-arrow-right-circle</v-icon>
          <v-divider vertical class="mx-2"></v-divider>
          Approve</v-btn>
            </v-layout>

            <v-dialog v-model="reject_Dialog" width="600">
                <v-card>
                    <v-card-title class="white--text" style="background-color: #23b1a9"
                    ><v-layout style="display:flex;justify-content:center;margin-left: 170px;" >Reject
                       </v-layout>
                       <v-layout style="display:flex;justify-content:end;">
                        <v-icon class="white--text " @click="reject_Dialog=!reject_Dialog;reason=''">mdi-close</v-icon>
                      </v-layout>
                      </v-card-title>
                      <v-form ref="rejectForm" @submit="false">
                        <v-layout class="pt-4 form-group-container" >
                           
                            <v-flex class="mr-2">
                                <label class="form-label">Reason <span><strong class="red--text">*</strong></span></label>
                                <v-text-field  class="form-control" solo dense :rules="reject_Dialog ? [(v)=>requiredField(v)]:[]" v-model="reason" placeholder="Enter..."></v-text-field>
                            </v-flex>
                        </v-layout>
                      </v-form>
                        <v-card-actions style="display:flex;justify-content:center">
                          <v-btn class="btn" color="red white--text mr-0" @click="reject_Dialog=!reject_Dialog;reason=''">
                            No
                        </v-btn>

                        <v-btn class="btn" color="green white--text mr-0" @click="onSubmit">
                            Yes
                        </v-btn>
                      </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="approve_Dialog" :width="frequencyPayee.bank.confirmAccountFlag ? '80%' : '600'">
                <v-card>
                    <v-card-title class="white--text" style="background-color: #23b1a9"
                    ><v-layout style="display:flex;justify-content:center;" >Approve
                       </v-layout>
                       <!-- <v-layout style="display:flex;justify-content:end;">
                        <v-icon class="white--text " @click="approve_Dialog=!approve_Dialog;reason=''">mdi-close</v-icon>
                      </v-layout> -->
                      </v-card-title>
                      <v-card-text v-if="frequencyPayee.bank.confirmAccountFlag" class="pb-0">
                        <v-layout justify-center class="pb-2" v-if="cancelledChequeData.length > 0">
                            <object
                                :data="cancelledChequeData[0].fileBase
                          ? cancelledChequeData[0].fileBase
                          : showCheque"
                                width="100%" height="300"></object>
                            <!-- <img height="300" :src="investigatorMasterObj.documents.fileBase" /> -->
                        </v-layout>
                        <v-form ref="AccountNumberForm" style="max-width: 450px; margin:auto">
                          <v-flex>
                              <label class="form-label">Bank Account Number<span class="mandatorycolor"> *</span></label>
                              <v-text-field v-model="validateAccNo" class="form-control" solo dense :rules="[(v) => requiredField(v), (v) => shouldBeSame(frequencyPayee.bank.confirmAccountNumber, v, 'Mismatch Bank Account Number')]" label="Enter ..."></v-text-field>
                          </v-flex>
                        </v-form>
                      </v-card-text>
                      <v-card-text v-else>
                        <v-layout class="pt-4 form-group-container" justify-center>
                           
                            <h4>Are you sure want to Approve ?</h4>
                        </v-layout>
                      </v-card-text>
                        <v-card-actions style="display:flex;justify-content:center">
                          <v-btn class="btn" color="red white--text mr-0" @click="approve_Dialog=!approve_Dialog;reason=''">
                            No
                        </v-btn>

                        <v-btn class="btn" color="teal white--text mr-0" @click="onSubmit">
                            Yes, Approve
                        </v-btn>
                      </v-card-actions>
                </v-card>
            </v-dialog>
            <!-- Mobile Dialog -->
    <v-dialog v-model="mobile_Dialog" width="500">
                <v-card>
                    <v-card-title
                    class="white--text"
                    style="background-color: #23b1a9"
                  >
                  <h4 class="ml-5">Add Multiple Contact Number</h4>
        <v-spacer></v-spacer>
        <v-icon @click="mobile_Dialog=!mobile_Dialog" class="white--text">mdi-window-close</v-icon>
                </v-card-title>
                <v-card-text class="grey lighten-2">
                    <v-data-table hide-default-footer
                    :headers="contact_Headers"
                    :items="frequencyPayee.mobileNumber"
                    class=" vDataTableStyling"
                    v-if="frequencyPayee.mobileNumber.length>0"
                    >
                    <template v-slot:[`item.isd`]="{item}">
                        <v-text-field
                    solo
                    dense
                    :maxlength="3"
                    :disabled="isView"
                    class="form-control mt-5"
                    v-model="item.isd"
                    :rules="[(v) => matchRegex(v, 'nums')]"
                    >

                    </v-text-field>
                    </template>
                   <template v-slot:[`item.number`]="{item}">
                    <v-text-field
                    solo
                    dense
                    :disabled="isView"
                    class="form-control mt-5"
                    :maxlength="10"
                    v-model="item.number"
                    >

                    </v-text-field>
                   </template>
                </v-data-table>
                </v-card-text>
                </v-card>
            </v-dialog>
            <v-dialog v-model="email_Dialog" width="500">
                <v-card>
                    <v-card-title
                    class="white--text"
                    style="background-color: #23b1a9"
                  >
                  <h4 class="ml-5">Add Multiple Email ID</h4>
        <v-spacer></v-spacer>
        <v-icon @click="email_Dialog=!email_Dialog" class="white--text">mdi-window-close</v-icon>
                </v-card-title>
                <v-card-text class="grey lighten-2">
                    <v-data-table hide-default-footer
                    :headers="email_Headers"
                    :items="email_Obj"
                    class=" vDataTableStyling"
                    >
                   <template v-slot:[`item.email`]="{item}">
                    <v-text-field
                    solo
                    dense
                    class="form-control mt-5"
                    v-model="item.email"
                    :disabled="isView"
                    :rules="[(v) => validateEmail(v, 'Email ID')]"
                    >

                    </v-text-field>
                   </template>
                </v-data-table>
                </v-card-text>
                </v-card>
            </v-dialog>
    <!-- Audit Trail -->
    <audit-trail
      :dialogState="OpenAuditDialog"
      :auditTrailMaster="'frequentPayee'"
      :auditTrailItem="{ uid: auditTrailId }"
      @close-dialog="OpenAuditDialog = false"
    />
    <penny-drop-popup ref="openPennyDropPopup" :showVerifyButton="true" @verifyPdScore="updateVerifiedPdScore"></penny-drop-popup>
    <v-row justify="center">
        <v-dialog transition="scale-transition" width="200px" v-model="openPdDetailsPopup">
            <v-card class="overflow-hidden">
                <v-card tile>
                    <v-layout align-center>
                        <v-card-title class="pa-0 pl-3 text-capitalize font-weight-bold" style="color:#10242B;font-size:13px">PD Details</v-card-title>
                    </v-layout>
                </v-card>
                <v-card-text class="px-4 pt-4">
                    <div class="py-6 bg-gray rounded">
                        <ul class="list-style-none w-400">
                            <li v-for="(value, key) in pdDetails">
                                <span class="font-weight-bold"> {{ key }} </span>: {{ pdDetails[key] }}
                            </li>
                        </ul>
                    </div>
                </v-card-text>
                <v-card-actions class="justify-center pb-5">
                    <v-btn tile  dark color="#152F38" class="px-3 text-capitalize" small @click="openPdDetailsPopup = false">
                    <v-icon color="" small >cancel</v-icon>
                    <v-divider vertical class="ml-2 mr-2 text-capitalize"></v-divider>Close
                </v-btn>
                </v-card-actions>
            </v-card>                 
        </v-dialog>
    </v-row>
        </v-container>
        
    </v-app>
</template>
<script>
import moment from 'moment'
import auditTrail from '../../../../component/MasterAuditTrail.vue'
import pennyDropPopup from '../../../../components/PennyDropPopup.vue'
export default {
  components: {
    auditTrail,
    pennyDropPopup
  },
    data() {
        return{
          masterId:'',
          isPdScoreVerify: false,
          isBankDetailsAvailable: false,
          pdDetails: {
              "PdScore" : "-",
              "Remark": "-",
              "Status": "-",
          },
          openPdDetailsPopup: false,
            payeeType:['Insured','Claimant','Others'],
            categorys:['Individual','Corporate'],
            accountTypes:['Saving Account','Current Account'],
            msmes:['Yes','No'],
            neftHeaders:[{text: "Document Name",align: "start",value: "file",sortable: false,width:"200px"},
                                {text: "Action",align: "start",value: "action",sortable: false,width:"150px"},
                                { text: "Upload by", align: "start", value: "createdBy",width:"130px", sortable: false },
                                { text: "Date and Time", align: "start", value: "updatedAt",width:"130px", sortable: false },
                              ],
            enterpriseclassifications:['Proprietorship','Hindu undivided family','Association of persons','Co-operative society','Partnership firm'
        ,'Company','Individual'],
            enterprisecategorys:['Micro','Small','Medium'],
            payeetype:"",
            panels:[0,1,2,3],
            documentAttachments:[],
            menu:false,
            pincode:null,
            showCheque:"",
            msme:false,
            bankAccount:"",
            cancelledChequeFileShow: false,
            cancelledCheque:[],
            cancelledChequeData: [],
            isOCRUploaded: false,
            searchPincode:[],
            searchPincode1:[],
            tab:null,
            documentTab:"NEFT Form",
            visualEffect: false,
            all_attachments:[],
            neftAttachment:[],
            
            panAttachment:[],
            gstnAttachment:[],
            otherAttachment:[],
            kycAttachment:[],
            IFSCCodes:[],
            isView:true,
            heading:"Create",
            pincode1:null,
            ifsc:null,
            frequentDetails:[],
            reason:"",

            tab_items:['NEFT Form','PAN Card',
          'GSTN','Others','KYC/CKYC'],
          reject_Dialog:false,
          approve_Dialog:false,

    frequencyPayee:{
              payeeType:"",
              otherName:"",
              payeeName:"",
              active:false,
              watchList:false,
              addressLine1:"",
              addressLine2:"",
              addressLine3:"",
              pinCode:"",
              city:"",
              district:"",
              state:"",
              country:"",
              panNumber:"",
              aadharId:"",
              gstNotApplicable:false,
              gstinNumber:"",
              lob:["Travel"],
              landlineNumber:[{std:"",number:""}],
              mobileNumber:[{isd:"",number:""}],
              // emails:{id1:"",id2:"",id3:"",id4:""},
              emails: [{email:""}],
              faxNumber:"",
              endDate:"",
              category:"",
              contactPerson:{name:"",number:"",isdCode:""},
              bank:{
                bankDetails:false,
                ifscCode:"",
                bankName:"",
                city: "",
                bankAddressLine1: "",
                bankAddressLine2: "",
                bankAddressLine3: "",
                branch: "",
                district: "",
                state: "",
                pinCode: "",
                accountNumber: "",
                confirmAccountNumber: "",
                accountType: "",
                msme: 'No',
                enterpriseClassification: "",
                enterpriseCategory: "",
                uan: "",
                cKYC: "",
                accountHolderName: "",
                documents: []
            },
        
    
    
            approveStatus: "",
            frequentPayeeId: "",
            ntidChangedUser: "",
            nameOfChangedUser: "",
            overwritten:[{
              status:false
            }]
            },
      OpenAuditDialog: false,
      auditTrailId: null,
      validateAccNo: "",
      email_Headers:[{text:"Email",value:"email",align:"center"}],
      email_Dialog: false,
      email_Obj: [{email:""}],
            mobile_Dialog: false,
            contact_Headers:[{text:"ISD",value:"isd",align:"center",width:"100px"},{text:"Contact Number",value:"number",align:"center"}],
      }
    },
    methods:{
        formatDate(date) {
      if (!date) return "";

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    updateVerifiedPdScore(pdDetails){
      this.isPdScoreVerify = true;
      this.pdDetails = {...this.pdDetails, "PdScore": pdDetails.pdScore, "Remark" : pdDetails.remark, "Status": pdDetails.status};
      this.showAlert("PD Score Verified Successfully");
    },
    openPennyDropPaymentPopup(){
      let requestData = "lob=CM&masterId=" + this.masterId + "&masterType=Frequent Payee";
      this.$refs.openPennyDropPopup.openPopup(requestData);
    },
    showPdDetails(){
      let requestData = "lob=CM&masterId=" + this.masterId + "&masterType=Frequent Payee";
      this.GETM("GET_PENNYDROP_SCORE", requestData, (response) => {
          let pennyDropDetails = response?.data?.data ? response?.data?.data : [];
          if(pennyDropDetails.length && pennyDropDetails[0].remark){
            this.pdDetails = {...this.pdDetails, "PdScore": pennyDropDetails[0].pdScore, "Remark" : pennyDropDetails[0].remark, "Status": pennyDropDetails[0].status};
          }
          this.openPdDetailsPopup = true;
      }, (error) => {
          let errorMsg = error.response && error.response.data && error.response.data.msg ? error.response.data.msg : error.response.data.message ? error.response.data.message : "Something went wrong!";
          this.showAlert(errorMsg);
      },true);
    },
    confirmationForApproval() {
      if(this.checkIsPdScoreVerify){
        this.showAlert("Please do PennyDrop Payment Verification First");
        return;
      }
      this.approve_Dialog = !this.approve_Dialog;
    },
    checkBankAccount(){
        this.bankAccount=this.frequencyPayee.bank.accountNumber
        this.frequencyPayee.bank.accountNumber=""
        for(let i=0;i<=this.bankAccount.length-1;i++){
            this.frequencyPayee.bank.accountNumber+='*'
        }
        
    },
    checkoverwritten(){
      console.log(this.frequencyPayee.overwritten[0].status)
    },
    clearBankDetails(){
      
      this.frequencyPayee.bank.ifscCode="",
      this.frequencyPayee.bank.bankName="",
      this.frequencyPayee.bank.city= "",
      this.frequencyPayee.bank.bankAddressLine1= "",
      this.frequencyPayee.bank.bankAddressLine2= "",
      this.frequencyPayee.bank.bankAddressLine3= "",
      this.frequencyPayee.bank.branch= "",
      this.frequencyPayee.bank.district= "",
      this.frequencyPayee.bank.state= "",
      this.frequencyPayee.bank.pinCode= "",
      this.frequencyPayee.bank.accountNumber= "",
      this.frequencyPayee.bank.confirmAccountNumber= "",
      this.frequencyPayee.bank.accountType= "",
      // this.frequencyPayee.bank.msme= 'No',
      // this.frequencyPayee.bank.enterpriseClassification= "",
      // this.frequencyPayee.bank.enterpriseCategory= "",
      // this.frequencyPayee.bank.uan= "",
      // this.frequencyPayee.bank.cKYC= "",
      // this.frequencyPayee.bank.accountHolderName= "",
                
                this.cancelledChequeData=[]
                this.isOCRUploaded=false
    },
    cancelChequeView() {
                this.cancelledChequeFileShow = !this.cancelledChequeFileShow;
        },
    dragover(event) {
                event.preventDefault();
                this.visualEffect = true;
            },
    dragleave(event) {
                this.visualEffect = false;
            },
    browse(){ //to select attachments
                document.getElementById("SP_fileupload").click()
            },

    drop(event) {
                event.preventDefault();
                this.all_attachments = event.dataTransfer.files;
                // console.log("all", this.all_attachments);
                 this.selectFile(); // Trigger the onChange event manually
                this.visualEffect = false;
            },
    dropCancel(event) {
                event.preventDefault();
                this.cancelledCheque = event.dataTransfer.files;
                // console.log("all", this.all_attachments);
                 this.OCRUpload(); // Trigger the onChange event manually
                
            },
            browseCancel(){ //to select attachments
                document.getElementById("cancelchequeupload").click()
            },
            dragoverCancel(event) {
                event.preventDefault();
                
            },
            checkBankDetails(){
              if(this.frequencyPayee.bank.bankDetails == false){
                this.frequencyPayee.bank.ifscCode="",
      this.frequencyPayee.bank.bankName="",
      this.frequencyPayee.bank.city= "",
      this.frequencyPayee.bank.bankAddressLine1= "",
      this.frequencyPayee.bank.bankAddressLine2= "",
      this.frequencyPayee.bank.bankAddressLine3= "",
      this.frequencyPayee.bank.branch= "",
      this.frequencyPayee.bank.district= "",
      this.frequencyPayee.bank.state= "",
      this.frequencyPayee.bank.pinCode= "",
      this.frequencyPayee.bank.accountNumber= "",
      this.frequencyPayee.bank.confirmAccountNumber= "",
      this.frequencyPayee.bank.accountType= "",
      this.frequencyPayee.bank.msme= 'No',
      this.frequencyPayee.bank.enterpriseClassification= "",
      this.frequencyPayee.bank.enterpriseCategory= "",
      this.frequencyPayee.bank.uan= "",
      this.frequencyPayee.bank.cKYC= "",
      this.frequencyPayee.bank.accountHolderName= "",
               
                  this.frequencyPayee.overwritten[0].status=false
                
                this.cancelledChequeData=[]
                this.isOCRUploaded=false
              }
            },

            selectFile(){
                if(this.all_attachments.length !== 0){
                    const fsize = this.all_attachments[0].size;
                    const file = Math.round(fsize / 1024);
                    let self = this
                    if (file > 5120) {
                        self.showAlert("File is too big, please select a file less than 5MB");
                    } else {
                        self.readImage(self.all_attachments[0].name, self.all_attachments[0], (res) => { 
                          console.log(res)
                        var type = [];
                        type = self.all_attachments[0].type
                        console.log('--',self.documentTab)
                        if(self.documentTab == 'NEFT Form'){
                            if(self.neftAttachment.length < 5){
                                self.neftAttachment.push({ 
                                  file : res.file,
                                    fileBase: res.fileBase,
                                    documentType: 'NEFT Form'
                                })
                                self.neftAttachment = self.neftAttachment.reduce((unique, o) => {
                                    if(!unique.some(obj => obj.file === o.file)) {
                                        unique.push(o);                                        
                                    }
                                    return unique;
                                },[]);
                            }else{
                                self.neftAttachment.splice(0,1)
                                self.neftAttachment.push({ 
                                  file : res.file,
                                    fileBase: res.fileBase,
                                    documentType: 'NEFT Form'
                                })
                                self.neftAttachment = self.neftAttachment.reduce((unique, o) => {
                                    if(!unique.some(obj => obj.file === o.file)) {
                                        unique.push(o);                                        
                                    }
                                    return unique;
                                },[]);
                            }
                            console.log('---neft', self.neftAttachment)
                        }else if(self.documentTab == 'Others'){
                            if(self.otherAttachment.length < 5){
                                self.otherAttachment.push({ 
                                  file : res.file,
                                    fileBase: res.fileBase,
                                    documentType: 'OTHERS'
                                })
                                self.otherAttachment = self.otherAttachment.reduce((unique, o) => {
                                    if(!unique.some(obj => obj.file === o.file)) {
                                        unique.push(o);                                        
                                    }
                                    return unique;
                                },[]);
                            }else{
                                self.otherAttachment.splice(0,1)
                                self.otherAttachment.push({ 
                                  file : res.file,
                                    fileBase: res.fileBase,
                                    documentType: 'OTHERS'
                                })
                                self.otherAttachment = self.otherAttachment.reduce((unique, o) => {
                                    if(!unique.some(obj => obj.file === o.file)) {
                                        unique.push(o);                                        
                                    }
                                    return unique;
                                },[]);
                            }
                        }else if(self.documentTab == 'PAN Card'){
                            if(self.panAttachment.length < 5){
                                self.panAttachment.push({ 
                                  file : res.file,
                                    fileBase: res.fileBase,
                                    documentType: 'PAN Card'
                                })
                                self.panAttachment = self.panAttachment.reduce((unique, o) => {
                                    if(!unique.some(obj => obj.file === o.file)) {
                                        unique.push(o);                                        
                                    }
                                    return unique;
                                },[]);
                            }else{
                                self.panAttachment.splice(0,1)
                                self.panAttachment.push({ 
                                  file : res.file,
                                    fileBase: res.fileBase,
                                    documentType: 'PAN Card'
                                })
                                self.panAttachment = self.panAttachment.reduce((unique, o) => {
                                    if(!unique.some(obj => obj.file === o.file)) {
                                        unique.push(o);
                                        
                                    }
                                    return unique;
                                },[]);
                            }
                        }else if(self.documentTab == 'GSTN'){
                            if(self.gstnAttachment.length < 5){
                                self.gstnAttachment.push({ 
                                  file : res.file,
                                    fileBase: res.fileBase,
                                    documentType: 'GSTN'
                                })
                                self.gstnAttachment = self.gstnAttachment.reduce((unique, o) => {
                                    if(!unique.some(obj => obj.file === o.file)) {
                                        unique.push(o);                                        
                                    }
                                    return unique;
                                },[]);
                            }else{
                                self.gstnAttachment.splice(0,1)
                                self.gstnAttachment.push({ 
                                  file : res.file,
                                    fileBase: res.fileBase,
                                    documentType: 'GSTN'
                                })
                                self.gstnAttachment = self.gstnAttachment.reduce((unique, o) => {
                                    if(!unique.some(obj => obj.file === o.file)) {
                                        unique.push(o);
                                        
                                    }
                                    return unique;
                                },[]);
                            }
                        }else{
                            if(self.kycAttachment.length < 5){
                                self.kycAttachment.push({ 
                                  file : res.file,
                                    fileBase: res.fileBase,
                                    documentType: 'KYC/CKYC'
                                })
                                self.kycAttachment = self.kycAttachment.reduce((unique, o) => {
                                    if(!unique.some(obj => obj.file === o.file)) {
                                        unique.push(o);                                        
                                    }
                                    return unique;
                                },[]);
                            }else{
                                self.kycAttachment.splice(0,1)
                                self.kycAttachment.push({ 
                                  file : res.file,
                                    fileBase: res.fileBase,
                                    documentType: 'KYC/CKYC'
                                })
                                self.kycAttachment = self.kycAttachment.reduce((unique, o) => {
                                    if(!unique.some(obj => obj.file === o.file)) {
                                        unique.push(o);
                                        
                                    }
                                    return unique;
                                },[]);
                            }
                        }
                        // // Phase-2 function call starts
                        // this.getCancelledChequeData(res.base);
                        // this.manualOverwrite = false;
                        // // Phase-2 function call ends
                        });
                    }
                }
                this.checkTabChanged()
            },

      viewDocument(itemData) {
      if (itemData.fileBase) {
        this.viewDocUsingBase(itemData.fileBase);
      } else {
        let requestData = "";
        if (itemData !== null) {
          requestData = "documentId=" + itemData.documentId + "&b64=true";
        }
        this.GETM(
          "VIEW_DOCUMENT",
          requestData,
          (res) => {
            if (res.data.statusCode === 200) {
              this.viewDocUsingBase(res.data.data);
            }
          },
          (error) => {
            console.log("Error", error);
          },
          true
        );
      }
    },
    viewDocUsingBase(base64) {
      const base64ImageData = base64;
      const contentType = base64.split(":")[1].split(";")[0];
      const byteCharacters = window.atob(
        base64ImageData.substr(`data:${contentType};base64,`.length)
      );
      const byteArrays = [];
      for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
        const slice = byteCharacters.slice(offset, offset + 1024);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
      const blob = new Blob(byteArrays, { type: contentType });
      const blobUrl = window.URL.createObjectURL(blob);
      window.open(blobUrl, "_blank");
    },

    downloadDoc(item) {
                if(item.fileBase) {
                    let base64 = item.fileBase;
                    const base64ImageData = base64
                    const contentType = base64.split(':')[1].split(';')[0];
                    const byteCharacters = window.atob(base64ImageData.substr(`data:${contentType};base64,`.length));
                    const byteArrays = [];
                    for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
                        const slice = byteCharacters.slice(offset, offset + 1024);
                        const byteNumbers = new Array(slice.length);
                    for (let i = 0; i < slice.length; i++) {
                        byteNumbers[i] = slice.charCodeAt(i);
                    }
                    const byteArray = new Uint8Array(byteNumbers);
                        byteArrays.push(byteArray);
                    }
                    const blob = new Blob(byteArrays, {type: contentType});
                    const blobUrl =  window.URL.createObjectURL(blob);

                    const element = document.createElement("a");
                    element.href = blobUrl;
                    element.download = item.file;
                    element.click();
                } else {
                    let url = this.getURL("VIEW_DOCUMENT") + "?documentId=" + item.documentId;
                    window.open(url);
                }
            },

            deleteDocument(index) {
                // if(type === 'neft') {
                //     this.neftAttachments.splice(index, 1);
                // }

                // if(type === 'kyc') {
                //     this.kycAttachments.splice(index, 1);
                // }

                // if(type === 'others') {
                //     this.otherAttachments.splice(index, 1);
                // }
                // this.all_attachments = []

                if(this.documentTab=='NEFT Form'){
        this.neftAttachment.splice(index, 1);
      }
      
      if(this.documentTab=='PAN Card'){
        this.panAttachment.splice(index, 1);
      }
      if(this.documentTab=='GSTN'){
        this.gstnAttachment.splice(index, 1);
      }
      if(this.documentTab=='Others'){
      this.otherAttachment.splice(index, 1);
      }
      if(this.documentTab=='KYC/CKYC'){
      this.kycAttachment.splice(index, 1);
      }
            },


    checkTabChanged(){
      if(this.documentTab=='NEFT Form'){
        this.documentAttachments=this.neftAttachment
      }
     
      if(this.documentTab=='PAN Card'){
        this.documentAttachments=this.panAttachment
      }
      if(this.documentTab=='GSTN'){
        this.documentAttachments=this.gstnAttachment
      }
      if(this.documentTab=='Others'){
        this.documentAttachments=this.otherAttachment
      }
      if(this.documentTab=='KYC/CKYC'){
        this.documentAttachments=this.kycAttachment
      }
    },
    getFormattedDate(date) {
      if(date === "" || date === null) return "";
      return moment(date).format("YYYY-MM-DD");
    },
    checkPincode(){
      if(this.pincode.length>=3){
      let reqData = "pageNo=1&perPage=100&pinCode=" + this.pincode
        this.GETM(
          "getPincodes",
          reqData,
          (res) => {
            let a=res.data.data.data
            this.searchPincode=a
            
          },(err) => {
            console.error("Issue while fetching pin codes.", err);
          })
    }
  },
OCRUpload() {
            if (this.cancelledCheque) {
                this.cancelledChequeFileShow = false; //hide view port
                this.isOCRUploaded = false; //check if OCR uploaded
                this.frequencyPayee.overwritten[0].status = false; //check for manual entry
                this.clearBankDetails(); //reset bank fields
                if (!this.cancelledCheque.name){
                    return // for while resetting the form
                }
                this.showProgress();
                
                this.readImage(this.cancelledCheque.name, this.cancelledCheque, (res) => {
                    let requestPayload = {
                        document: res.fileBase,
                    };
                    this.cancelledCheque = []; //reset field value
                    this.POSTM(
                        "GET_OCR_FORMAT",
                        requestPayload,
                        (response) => {
                            this.hideProgress();
                            if (response.data.data.Message == "Uploaded Image/Document orientation is incorrect") {
                                this.showAlert(response.data.data.Message);
                                this.frequencyPayee.overwritten[0].status = true;
                            } else if (response.data.data.Message == "Invalid Image/Document") {
                                this.showAlert(response.data.data.Message);
                                this.frequencyPayee.overwritten[0].status = true;
                            } else if (response.data.data) {
                                let bankres = response.data.data;
                                console.log(response.data.data)
                                if (!bankres.AccountNumber || !bankres.IFSC || !bankres.PayerName) {
                                    this.isOCRUploaded = true;
                                    
                                    this.frequencyPayee.overwritten[0].status = true;
                                    this.showAlert("Could not retrieve data from OCR either re-upload the cancelled cheque or enter the details manually");
                                } else {
                                    
                                    //temprory fix
                                    this.showProgress();
                                    let request = bankres.IFSC + "?lob=Marine";
                                    this.GET_ByURLM(
                                        "GET_GC_BANK",
                                        request,
                                        (res) => {
                                            this.IFSCCodes = [];
                                            this.hideProgress();
                                            console.log(res.data.data)
                                            if (res.data.data && res.data.data.length > 0) {
                                                let ifscCodeValue = res.data.data[0];
                                                this.IFSCCodes = res.data.data;
                                                this.frequencyPayee.bank.ifscCode = bankres.IFSC;
                                                this.frequencyPayee.bank.accountNumber=bankres.AccountNumber
                                                this.checkBankAccount()
                                                this.frequencyPayee.bank.confirmAccountNumber=bankres.AccountNumber
                                                this.frequencyPayee.bank.branch =ifscCodeValue.branchName

                                                this.frequencyPayee.bank.bankName = ifscCodeValue.financierName;
                                                this.frequencyPayee.bank.bankAddressLine1 = ifscCodeValue.branchAddress;
                                                let address2 = '';
                                                if (ifscCodeValue.district) {
                                                    address2 += `${ifscCodeValue.district}, `
                                                }
                                                if (ifscCodeValue.city) {
                                                    address2 += `${ifscCodeValue.city}, `
                                                }
                                                if (ifscCodeValue.pincode) {
                                                    address2 += ifscCodeValue.pincode
                                                }
                                                this.frequencyPayee.bank.bankAddressLine2 = address2;
                                                this.frequencyPayee.bank.bankAddressLine3 = ifscCodeValue.state;
                                                this.frequencyPayee.bank.pinCode=ifscCodeValue.pincode
                                                this.pincode1=ifscCodeValue.pincode
                                                
                                                this.changePincodeAndState(1)
                                                // this.frequencyPayee.bankAddressLine2 = `${ifscCodeValue.district}, ${ifscCodeValue.city}, ${ifscCodeValue.state}`
                                            }
                                        },
                                        (error) => {
                                            this.IFSCCodes = [];
                                            this.hideProgress();
                                            console.log("error", error);
                                            this.showAlert(error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message);
                                        }, true
                                    );
                                    this.cancelledChequeData.push(
                                        {
                                            file: res.file,
                                            fileBase: res.fileBase,
                                            documentType: "Cancelled Cheque",
                                            
                                        }
                                    )
                                    
                                    // this.frequencyPayee.bankAccountNumber = bankres.AccountNumber;
                                    // this.reEnterBankAccountNumber = bankres.AccountNumber;
                                    // this.frequencyPayee.consumerFirstName = bankres.PayerName;
                                    // this.isOCRUploaded = true;
                                }
                            } else {
                                this.showAlert("No data found from OCR");
                            }
                        },
                        (error) => {
                            this.hideProgress();
                            this.frequencyPayee.overwritten[0].status = true;
                            this.showAlert("Somthing went wrong in OCR,Please enter the details manually");
                        }, true
                    );
                });
            } else {
                this.showAlert("Please upload a file.")
            }
        },
    ifscData(){
      if(this.ifsc.length>3){
      let request =  this.ifsc+ "?lob=Marine";
                                    this.GET_ByURLM(
                                        "GET_GC_BANK",
                                        request,
                                        (res) => {
                                            this.IFSCCodes = [];
                                            this.hideProgress();
                                            console.log(res.data.data)
                                            if (res.data.data && res.data.data.length > 0) {
                                                let ifscCodeValue = res.data.data[0];
                                                this.IFSCCodes=res.data.data
                                               
                                                this.frequencyPayee.bank.branch =ifscCodeValue.branchName

                                                this.frequencyPayee.bank.bankName = ifscCodeValue.financierName;
                                                this.frequencyPayee.bank.bankAddressLine1 = ifscCodeValue.branchAddress;
                                                let address2 = '';
                                                if (ifscCodeValue.district) {
                                                    address2 += `${ifscCodeValue.district}, `
                                                }
                                                if (ifscCodeValue.city) {
                                                    address2 += `${ifscCodeValue.city}, `
                                                }
                                                if (ifscCodeValue.pincode) {
                                                    address2 += ifscCodeValue.pincode
                                                }
                                                this.frequencyPayee.bank.bankAddressLine2 = address2;
                                                this.frequencyPayee.bank.bankAddressLine3 = ifscCodeValue.state;
                                                this.frequencyPayee.bank.pinCode=ifscCodeValue.pincode
                                                this.pincode1=ifscCodeValue.pincode
                                                
                                                this.changePincodeAndState(1)
                                                // this.frequencyPayee.bankAddressLine2 = `${ifscCodeValue.district}, ${ifscCodeValue.city}, ${ifscCodeValue.state}`
                                            }
                                        },
                                        (error) => {
                                            this.IFSCCodes = [];
                                            this.hideProgress();
                                            console.log("error", error);
                                            this.showAlert(error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message);
                                        }, true
                                    );
    
                                  }
                                },
  changePincodeAndState(data=0){
    
    if(data==1){
      if(this.pincode1.length>=3){
      let reqData = "pageNo=1&perPage=100&pinCode=" + this.pincode1
        this.GETM(
          "getPincodes",
          reqData,
          (res) => {
            let a=res.data.data.data
            this.searchPincode1=a
            console.log(this.searchPincode1)
            this.bankDetailsPincode()
          },(err) => {
            console.error("Issue while fetching pin codes.", err);
          }, true)
    }
    
    }else{
      let data=""
    this.searchPincode.forEach(i=>{
      
        if(i.pinCode == this.frequencyPayee.pinCode){
          console.log(i.pinCode)
          data= i
        }
      
    })
    // this.frequencyPayee.pinCode=a.pinCode
            this.frequencyPayee.city = data.pinCodeLocality;
        this.frequencyPayee.state  = data.stateName;
        this.frequencyPayee.district  = data.cityDistrictName;
        this.frequencyPayee.country=data.countryName}
    
  },
  viewDocumentCheque(id){
        let requestData = "documentId=" + id + "&b64=true";
        this.GETM(
          "VIEW_DOCUMENT",
          requestData,
          (res) => {
            if (res.data.statusCode === 200) {
              this.showCheque=res.data.data
            }
          },
          (error) => {
            console.log("Error", error);
          },
          true
        );
      },
  bankDetailsPincode(){
    let data=this.searchPincode1[0]
    console.log(this.searchPincode1[0])
       this.frequencyPayee.bank.city = data.cityDistrictName;
          this.frequencyPayee.bank.state  = data.stateName;
          this.frequencyPayee.bank.district  = data.cityDistrictName;
          
  },
 
  cancel(onSubmit){
    if(onSubmit){
      this.$router.push({name:'SearchFrequentPayeeApproval'} );
    } else {
      this.showConfirm("Confirmation","Are you sure you want to leave?","Yes","No",(Yes) => {
         
          this.$router.push({name:'SearchFrequentPayeeApproval'} );//params:{pageNo:this.pageNo}}
          
      })
    }
            },
  onSubmit(){
    if(!this.$refs?.AccountNumberForm?.validate() && this.frequencyPayee?.bank?.confirmAccountFlag && !this.reject_Dialog){
        return this.showAlert("Please fill bank account number.");
      }
        if(this.reject_Dialog){
          if(!this.$refs?.rejectForm?.validate()){
            return this.showAlert("Please fill mandatory fields.");
          }
        }

        let sendData={approveStatus:this.reject_Dialog ? "Rejected" : "Approved",
        rejectionReason:this.reason}
        this.frequentDetails.approveStatus=this.reject_Dialog ? "Rejected" : "Approved"
        this.frequentDetails.rejectionReason=this.reason
        this.frequencyPayee.approveStatus=this.reject_Dialog ? "Rejected" : "Approved"
        
      this.PUTM(
        "updateMasterApprove",this.frequentDetails._id,
        sendData,
        (res) => {
          this.hideProgress();
          if (res.data && res.data.statusCode == 200) {
            this.showAlert(res?.data?.msg || "Success", "", "", () => {
              
              // this.onReset();
              this.cancel(true)
              // this.$router.push('/Claim-processing/Child-Eduction/add-assesment-sheet');
                              
            });
            // this.isView=true
          } else {
            this.showAlert(res.data.msg);
            this.hideProgress();
          }
        },
        (err) => {
          console.log(err);
          this.hideProgress();
          let msg =
            err?.response?.data?.msg || "Unable to submit claim data.";
          this.showAlert(msg);
        },true
      );
  },
  
  openAuditTrail(){
      this.OpenAuditDialog = true
      this.auditTrailId = this.frequencyPayee.frequentPayeeId
    },
    },
    computed:{
        computedEndDate() {
       return this.formatDate(this.frequencyPayee.endDate)
    },
    getEndDate() {
     var endDate = new Date();
     return endDate.toISOString().slice(0,10)
    },
    computedPAN() {
      this.frequencyPayee.panNumber = this.frequencyPayee.panNumber ? this.frequencyPayee.panNumber.toUpperCase() : '';
      return this.frequencyPayee.panNumber;
    },
    checkIsPdScoreVerify(){
      if(this.showPDScoreDetails){
        return !this.isPdScoreVerify;
      }
        return false;
    },
    showPDScoreDetails(){
      return this.isBankDetailsAvailable ? true : false;
    }
    },
    created(){
      this.$store.commit("SAVE_PAGE_HEADER", "Frequent Payee Master Approval");
      if(Object.keys(this.$route.params).length != 0){
        let a=`${this.$route.params.item._id}`
       this.GET_ByURLM('getFrequentApprove',a,(res)=>{
        console.log(res.data.data.operationData[0],this.$route.params.isView)
        this.frequentDetails=res.data.data
        this.frequencyPayee=res.data.data.operationData[0]
        this.email_Obj= this.frequencyPayee.emails.map(e=>{return {email:e}})
        this.frequencyPayee.endDate=this.getFormattedDate(this.frequencyPayee.endDate)
        this.pincode=this.frequencyPayee.pinCode
        // this.checkPincode()
        this.popupOCR(this.frequentDetails?.operationData?.[0]?.overwritten?.[0]?.status)
        this.masterId = 'FP' + this.frequencyPayee?.frequentPayeeId;
        this.isBankDetailsAvailable =  this.frequencyPayee?.bank?.accountNumber;
        this.frequencyPayee.bank.documents.forEach(e=>{
          if(e.documentType == 'NEFT Form'){
            e.file=e.fileName
            delete e.fileName
            this.neftAttachment.push(e)
          }
          if(e.documentType == 'PAN Card'){
            e.file=e.fileName
            delete e.fileName
            this.panAttachment.push(e)
          }
          if(e.documentType == 'GSTN'){
            e.file=e.fileName
            delete e.fileName
            this.gstnAttachment.push(e)
          }
          if(e.documentType == 'OTHERS'){
            e.file=e.fileName
            delete e.fileName
            this.otherAttachment.push(e)
          }
          if(e.documentType == 'KYC/CKYC'){
            e.file=e.fileName
            delete e.fileName
            this.kycAttachment.push(e)
          }
          if(e.documentType == 'Cancelled Cheque'){
            e.file=e.fileName
            delete e.fileName
            this.cancelledChequeData.push(e)
            this.viewDocumentCheque(e.documentId)
          }
          
        })
        this.ifsc=this.frequencyPayee.bank.ifscCode
        this.ifscData()
        this.checkTabChanged()
        this.checkBankAccount()
       })
       
      }
      
      
    },
}
</script>

<style scoped>
.borderStyle{
        border:0.5px solid #d7d7d7;
        /* margin:0 21px 3px 8px; */
    }
    .btn{
 
 text-transform: capitalize;
}
.tabelwidth{
  width:550px;
  margin:0px 150px
}
.neftStyle{
        height: 150px;
        width: 350px;
        border: 3px dashed #ccc;
        /* padding-top: 40px;
        padding-left: 16%; */
        display: table-cell;
        vertical-align: middle;
        text-align: center;
    }
.dragbackground{
        background-color: #e7e7e7;
    }  
.dlt_text{
        color: rgb(145, 145, 145);
    }
.btnToggle {
  text-transform: none;
  color: #fff;
  background-color: #1ebbd9;
}
.margincls{
        font-size:12px;
        margin-left:-12px
    }
.iconstyle {
  background: #f7f7f7 0% 0% no-repeat padding-box;
  border: 0.5px solid #c1c8cc !important;
  color: #fff;
  width: 29px;
  margin-left: -0.1em;
  height: 38px;
  color: #6b6b6b;
}
.f-group-inner .v-input.input-1 {
  margin-right: 0.75em !important;
}
.cancel-cheque-view-port {
    background: #dddddd;
    margin-bottom: 30px;
    position: relative;
}
.close-cancell-cheque:hover {
    background: transparent !important;
}
.close-cancell-cheque {
    cursor: pointer;
    position: absolute;
    right: 0;
    font: normal 500 12px Roboto !important;
    color: #000000;
    text-transform: uppercase;
    padding: 0 15px;
}
.blueiconstyle {
  width: 38px;
  height: 38px;
  background: #01bbd9 0% 0% no-repeat padding-box;
  margin-right: -0.5em;
  color: #f7f7f7;
}
.claimProcessingTabsActive {
  background-color: #1ebbd9;
  color: #fff !important;
}
.uploadbr {
  text-align: center;
  height: 150px;
  max-width: 340px;
  border: 3px dashed #ccc;
  padding-top: 40px;
  /* padding-left: 16%; */
  cursor: pointer;
}
.unActive{
  border:1px solid #ccc;
  margin: 0px 5px
}

.hyperLinkColor{
    color: blue; 
    cursor:pointer
}

.spaceBetweenElements{
    padding: 8px 10px;
}
.approveButtonDisabled{
    background-color: rgba(0, 0, 0, 0.12) !important;
}
</style>