<template>
  <v-app>
    <v-container class="mainHeadBackground containerMaxWidth">
      <v-row>
        <v-btn color="white" class="buttonWidth textTransformCapitalize">
          Claim History
        </v-btn>
        <v-btn color="white" class="buttonWidth textTransformCapitalize">
          Policy Details
        </v-btn>
        <v-btn color="white" class="buttonWidth textTransformCapitalize">
          <div>
            <p class="textSplitPtag">Communication</p>
            <p>Sent</p>
          </div>
        </v-btn>
        <v-btn color="white" class="buttonWidth textTransformCapitalize">
          View SI
        </v-btn>
        <v-btn color="white" class="buttonWidth textTransformCapitalize">
          <div>
            <p class="textSplitPtag">View/Upload</p>
            <p>Documents</p>
          </div>
        </v-btn>
        <v-btn color="white" class="buttonWidth textTransformCapitalize">
          Notifications
        </v-btn>
        <v-btn color="white" class="buttonWidth textTransformCapitalize">
          Survey Details
        </v-btn>
        <v-btn color="white" class="buttonWidth textTransformCapitalize">
          Recovery Details
        </v-btn>
      </v-row>
    </v-container>

    <v-container class="containerbottomBorder divContainer containerMaxWidth">
      <v-row>
        <v-col class="firstClimeDeatilsWidth">
          <v-flex class="ClaimDetaisTitle">Claim No.</v-flex>
          <v-flex class="ClaimDetaisSubtitle">
            {{
              claimDetails.isTempClaim != true ? claimDetails.mainClaimNumber : ""
            }}
            <!-- {{
            claimDetails.claimNumber
          }} -->
          </v-flex>
        </v-col>
        <v-col class="firstClimeDeatilsWidth">
          <v-flex class="ClaimDetaisTitle">Policy No.</v-flex>
          <v-flex class="ClaimDetaisSubtitle">{{
            claimDetails.policyNumber
          }}</v-flex>
        </v-col>
        <v-col class="firstClimeDeatilsWidth">
          <v-flex class="ClaimDetaisTitle">Insured Name</v-flex>
          <v-flex class="ClaimDetaisSubtitle">
            {{ claimDetails.insuredName }}</v-flex
          >
        </v-col>
        <v-col class="firstClimeDeatilsWidth">
          <v-flex class="ClaimDetaisTitle">Date of Loss</v-flex>
          <v-flex class="ClaimDetaisSubtitle">{{
            ISOtoDDMMYY(claimDetails.dateOfLoss)
          }}</v-flex>
        </v-col>
        <v-col class="firstClimeDeatilsWidth">
          <v-flex class="ClaimDetaisTitle">Examiner</v-flex>
          <v-flex class="ClaimDetaisSubtitle">{{
            claimDetails.examiner
          }}</v-flex>
        </v-col>
        <v-col class="firstClimeDeatilsWidth">
          <v-flex class="ClaimDetaisTitle"> Settling Office</v-flex>
          <v-flex class="ClaimDetaisSubtitle">{{
            claimDetails.settlingOffice
          }}</v-flex>
        </v-col>
        <v-col class="firstClimeDeatilsWidth">
          <v-flex class="ClaimDetaisTitle">Feature No.</v-flex>
          <v-flex class="ClaimDetaisSubtitle">
            {{
              claimDetails.isTempClaim != true ? claimDetails.featureNumber : ""
            }}
            <!-- {{
            claimDetails.featureNumber
          }} -->
          </v-flex>
        </v-col>
        <v-col class="firstClimeDeatilsWidth">
          <v-flex class="ClaimDetaisTitle">Policy Status</v-flex>
          <v-flex class="ClaimDetaisSubtitle">{{
            claimDetails.policyStatus
          }}</v-flex>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="divContainer containerMaxWidth">
      <v-row>
        <!-- v-bind:style="{maxWidth: ButtonWidthChange}" -->
        <v-col class="SecondClimeDeatils">
          <v-flex class="ClaimDetaisTitle">Claim Status</v-flex>
          <v-flex class="ClaimDetaisSubtitle">{{
            claimDetails.claimStatus
          }}</v-flex>
        </v-col>
        <v-col class="SecondClimeDeatils">
          <v-flex class="ClaimDetaisTitle">Close Proximity</v-flex>
          <v-flex class="ClaimDetaisSubtitle">{{
            claimDetails.closeProximity
          }}</v-flex>
        </v-col>
        <v-col class="SecondClimeDeatils">
          <v-flex class="ClaimDetaisTitle">Indemnity</v-flex>
          <v-flex class="ClaimDetaisSubtitle">{{
            claimDetails.indemnity
          }}</v-flex>
        </v-col>
        <v-col class="SecondClimeDeatils">
          <v-flex class="ClaimDetaisTitle"> Expense</v-flex>
          <v-flex class="ClaimDetaisSubtitle">{{
            claimDetails.expense
          }}</v-flex>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="secondDivContainer containerMaxWidth">
      <v-row>
        <v-flex
          class="textTransformCapitalize subButtonListWidth cursorPoniter"
          v-bind:id="'Selected_' + index"
          v-for="(item, index) in headButtonsNames"
          :key="item.title"
          @mouseout="claimRedirectionMouseOut"
          @mouseover="claimRedirectionMouseMove(index)"
          @click="claimRedirectionFun(index, item.title)"
          :style="{
            backgroundColor: item.selected ? '#23B1A9' : '#F7F7F7',
            color: item.selected ? '#FFFFFF' : '#10242B',
          }"
        >
          {{ item.title }}
        </v-flex>
      </v-row>
    </v-container>
    <div>
      <v-container class="SummaryFirstdivContainer containerMaxWidth">
        <v-row>
          <v-flex
            color="white"
            class="textTransformCapitalize claimSummerButtonsWidth"
            v-for="item in SummerButtonsFirstrowTexts"
            :key="item"
            @click="openNewPagesRow(item)"
          >
            {{ item }}
          </v-flex>
        </v-row>
      </v-container>
      <v-container class="SummarySeconddivContainer containerMaxWidth">
        <v-row>
          <v-flex
            color="white"
            class="textTransformCapitalize claimSummerButtonsWidth"
            v-for="item in SummerButtonsSecondrowTexts"
            :key="item"
            @click="openNewPagesRow(item)"
          >
            {{ item }}
          </v-flex>
        </v-row>
      </v-container>
    </div>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      FNOLRegistrationShow: false,
      ButtonWidthChange: "169px",
      MyselectedToggle: null,
      drawer: false,
      claimDetails: {
        // claimNo: "1234567890",
        // PolicyNo: "9856747377",
        // insuredName: "ABCDD",
        // dateofLoss: "21/09/2020",
        // examiner: "Amit",
        // settlingOffice: "Ahmedabad",
        // featureNo: "A",
        // policyStatus: "Active",
        // claimStatus: "Open",
        // closeProximity: "Yes",
        // indemnity: "Reserve",
        // expense: "Reserve",
        remarks: "",
        type: "",
        claimNumber: "",
        featureNumber: "",
        examiner: "",
        insuredName: "",
        policyNumber: "",
        dateReported: "",
        dateOfLoss: "",
        externalAdjInvestigator: "",
        outStandingReserve: "",
        indeminityStatus: "",
        settlingOffice: "",
        productName: "",
        coverageDescription: "",
      },
      headButtonsNames: [
        {
          title: "FNOL/Registration",
          path: "",
        },
        {
          title: "Claim Processing",
          path: "",
        },
        {
          title: "Adjuster Appointment",
          path: "",
        },
        {
          title: "Assessment",
          path: "",
        },
        {
          title: "Payment",
          path: "",
        },
      ],
      SummerButtonsSecondrowTexts: [
        "Query",
        "RI Summary",
        "Co-Insurance Summary",
        "CFR",
        "Audit Trail",
        "Red Flag",
        "Litigation",
      ],
      SummerButtonsFirstrowTexts: [
        "Claim Summary",
        "Transaction History",
        "Feature Activity",
        "Document Checklist",
        "Notes",
        "Referral",
      ],
    };
  },
  methods: {
    openNewPagesRow(buttonText){
      this.$store.commit("SAVE_MAINCLAIMNUMBER", this.claimDetails.mainClaimNumber);
      this.$store.commit("SAVE_FEATUREMNUMBER",this.claimDetails.featureNumber);
        if (buttonText == "Transaction History") {         
         this.$router.push({ name: 'TransactionHistory', params: {} });
      }

      if (buttonText == "Audit Trail") {
        this.$router.push({ name: 'auditTrail', params: {} });
      }

    },
    claimRedirectionFun(index, buttonText) {
      console.log(index, buttonText);
      if (buttonText == "FNOL/Registration") {
        this.$store.commit("FNOL_PAGE_EDIT_CHECK", "FnolEditPage");
        this.$router.push("/fnol");
      }
    },
    claimRedirectionMouseMove(index) {
      this.headButtonsNames.map((e) => {
        return (e.selected = false);
      });
      this.headButtonsNames[index].selected = true;
      this.$forceUpdate();
    },
    claimRedirectionMouseOut() {
      this.headButtonsNames.map((e) => {
        return (e.selected = false);
      });
      this.$forceUpdate();
    },
    ISOtoDDMMYY(date) {
      if (date) {
        let splitdate = new Date(date).toISOString().substr(0, 10);
        const [year, month, day] = splitdate.split("-");
        return `${day}/${month}/${year}`;
      }
    },
   
  },
  created() {
    if(this.$store.getters.fetchclaimMainscreen_id.claimId){
         let reqClaimURL;
         if(this.$store.getters.fetchclaimMainscreen_id.claimType=="Feature Claim"){
           reqClaimURL="FETCH_FEATURECLAIM_DETAILS";
          }else{
            reqClaimURL="FETCH_CLAIM_DETAILS";
          }
        let requestData= this.$store.getters.fetchclaimMainscreen_id.claimId;
         this.showProgress("Loading, Please wait");
         this.GET_ByURL(reqClaimURL, requestData, (response) => {
              this.hideProgress();
              this.claimDetails = response.data.data.claim;
         },(error)=>{
             this.hideProgress();
            this.showAlert(error.response.data.msg);
         })
    }
  },
};
</script>

<style scoped>
.ActiveClass {
  background-color: #23b1a9;
  color: white;
}

br {
  margin-bottom: 100px;
}

.buttonWidth {
  margin-right: 10px !important;
  width: 11.5% !important;
  font-size: 11px !important;
  padding: 20px !important;
  font-weight: bold !important;
  margin-bottom: 10px;
}

.mainHeadBackground {
  margin-top: 10px;
  background-color: #01bbd9;
  padding: 27px;
  margin-right: 0px;
  padding-bottom: 18px;
}

.lineBreakDevider {
  margin-bottom: 4px !important;
}

.subButtonListWidth {
  width: 20% !important;
  font-size: 13px !important;
  min-width: 187px !important;
  color: #10242b;
  font-size: 12px !important;
  border: 0.5px solid #c1c8cc;
  border-right: none;
  padding: 11px;
  text-align: center;
  background-color: #f7f7f7;
  padding-top: 8px;
  opacity: 1;
  box-shadow: 1px -1px 1px #00000051;
  font-weight: bold;
}

.secondDivContainer {
  margin-right: 0 !important;
}

.SecondClimeDeatils {
  max-width: 169px !important;
}

.firstClimeDeatilsWidth {
  max-width: 169px !important;
}

.ClaimDetaisTitle {
  color: #10242b;
  font-size: 13px;
  font-weight: bold;
}

.ClaimDetaisSubtitle {
  color: #10242b;
  font-size: 13px;
}

.containerbottomBorder {
  border-bottom: 0.5px solid #c1c8cc;
  opacity: 1;
}

.containerbottomBorder .row {
  padding-top: 10px;
  padding-bottom: 16px;
}

.SummaryFirstdivContainer {
  margin-top: 45px;
  margin-bottom: 16px;
  margin-right: 0;
  margin-left: 12px;
  margin-right: 12px;
}

.SummarySeconddivContainer {
  margin-left: 12px;
  margin-right: 12px;
}

.claimSummerButtonsWidth {
  margin-right: 20px;
  box-shadow: 0px 2px 2px #00000051;
  border: 0.5px solid #c1c8cc;
  opacity: 1;
  text-align: center;
  height: 30px;
  padding-top: 7px;
  font-size: 11px;
  color: #10242b;
  max-width: 166px;
  cursor: pointer;
}

.textSplitPtag {
  margin-bottom: 0px;
  padding-top: 15px;
  padding-bottom: 3px;
}
</style>
