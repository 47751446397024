<template>
    <v-app>
        <v-layout style="background-color:#F7F7F7" column class="" >
            <v-form v-model="valid" @submit.prevent="srchWrkItms()" ref="form">
                <v-card class="mx-3 mb-3 mt-4" tile>
                        <v-data-table
                        :headers="headers"
                        :items="wrkItmDtaLst"
                        hide-default-footer
                        dense
                        disable-pagination>
                        <!-- <template v-slot:item.workItemID="{ item }">
                            <div @click="wrkitmidClick(item)" class="text-decoration-underline" style="color:#1976d2;cursor:pointer">{{ item.workItemID | checkdata }}</div>
                        </template>
                        <template v-slot:item.provName="{ item }">
                            <tr ><td>{{ item.provName}} </td></tr>
                        </template> -->
                        <template v-slot:item.prnNo="{ item }">
                            <tr ><td>{{ item.prnNo | truncate}} </td></tr>
                            <!-- <div style="width:330px"><p style="margin-bottom:0px">{{ item.prnNo}}</p></div> -->
                        </template>
                        <!-- <template v-slot:item.netwrkStatus="{ item }">
                            <div >{{ item.netwrkStatus| checkdata }}</div>
                        </template> -->
                    
                        <template v-slot:item.ApproverDecision="{ item }" >
                            <v-layout class=" pa-0 ma-0 py-1" justify-space-between style="flex:1">
                                <v-btn tile dark style="font-size:12px;background-color:#23B1A9;flex:1" @click="approveProduct(item)" small class="text-capitalize mr-2 "><v-icon dark small >check_circle</v-icon > <v-divider vertical class="mx-2"></v-divider>Accept</v-btn>
                                <v-btn tile dark style="font-size:12px;background-color:#D1121B;flex:1" @click=" editRmrk(item,item.remarks)" small  class="text-capitalize"><v-icon dark small >cancel</v-icon > <v-divider vertical class="mx-2"></v-divider>Reject</v-btn>
                            </v-layout>
                        </template>
                        <template v-slot:item.remarks={item} >
                            <v-layout justify-space-between align-center>
                                <v-btn tile  style="background-color:#1E9690" @click="editRmrk(item,item.remarks)" icon small dark  >
                                    <div class="mt-1">
                                        <img src="../../assets/edtRmrk.png" style="width: 22px;height: 22px"/>
                                    </div>
                                </v-btn>   
                            </v-layout>
                        </template>
                    </v-data-table>
                    <v-divider></v-divider>
                    
                    <!-- <v-layout row justify-end align-center class="ma-2" v-if="wrkItmDtaLst.length!=0">
                        <v-text class="mr-1 font-weight-bold" style="color:#3C3D3D; font-size:13px;">
                            <span class="" style="color:#E46A25;font-size:12px;">Showing {{fval}}-{{sval}} </span>out of {{totalLen}} records
                        </v-text>
                        <v-divider  class="mx-2" vertical ></v-divider>
                        <v-pagination
                            class="pdct-stUp-bnkAcc-pgn"
                            v-model="page"
                            color="#152F38"
                            flat
                            @input="nextPageData(page)"
                            :total-visible="9"
                            :length="pagelength"
                        ></v-pagination>
                    </v-layout> -->

                    <v-layout row justify-end align-center style="background-color:#f7f7f7" class="pa-2" v-if="wrkItmDtaLst.length !== 0">
                        <span class="mr-1 font-weight-bold body-1" style="color:#3C3D3D; font-size:12px !important">
                        <span class="" style="color:#E46A25;font-size:12px;">Showing {{fval}}-{{sval}} </span>out of {{totalLen}} records</span>
                        <v-divider  class="ma-2" vertical ></v-divider>
                        <v-pagination
                            class="pdct-stUp-bnkAcc-pgn"
                            v-model="pageInsti"
                            color="#152F38"
                            flat
                            @input="nextPageData(pageInsti)"
                            :length="pagelength"
                        ></v-pagination>
                    </v-layout>
                </v-card>

                <v-layout>
                    <v-dialog v-model="RmrkDilg" persistent max-width="480px" tile>
                        <v-card color="#F7F7F7" class="pb-2" tile>
                            <v-card tile>
                                <v-layout justify-space-between  class="" style="flex:1;background-ox-shadow: 0px 3px 5px #00000029;">
                                    
                                        <v-card-title class="pa-0 pl-3 text-capitalize font-weight-bold" style="color:#10242B;font-size:13px">Remarks</v-card-title>
                                        
                                    <div style="border-left:1px solid #C1C8CC;" @click="close_dwnld_dlg()">
                                        <v-icon style="cursor: pointer;" class="pa-2" >close</v-icon>
                                    </div>
                                </v-layout>
                            </v-card> 
                                <!-- <v-card class="ma-3 pa-2"> -->
                                        <v-textarea
                                        class="mx-3 mt-3"
                                        solo
                                        auto-grow
                                        v-model="rjctRmk"
                                        ></v-textarea>
                                <!-- </v-card> -->
                                <div color="" class="px-2" style="display:flex; justify-content:flex-end">
                                    <v-btn tile dark  style="font-size:12px;background-color:#E46A25" small @click="close_dwnld_dlg()"  class="text-capitalize px-3 "><v-icon dark small >cancel</v-icon > <v-divider vertical class="mx-2"></v-divider>Close</v-btn>
                                    <v-btn tile dark  style="font-size:12px;background-color:#D1121B"  @click="clear_Rmrks()"  small  class="text-capitalize px-3 mx-2"><v-icon dark small >delete </v-icon> <v-divider vertical class="mx-2"></v-divider>Clear</v-btn>
                                    <v-btn tile dark  style="font-size:12px;background-color:#23B1A9"  @click="rejectAcceptProduct('Reject')" small  class="text-capitalize px-3 mr-2"><v-icon dark small >check_circle</v-icon > <v-divider vertical class="mx-2"></v-divider>Save</v-btn>
                                </div>
                            
                        </v-card>
                    </v-dialog>
                </v-layout>
            </v-form>
        </v-layout>
    </v-app>
</template>

<script>
import axios from "axios";
import moment from 'moment';

export default {

    components:{
        
    },

    created() {
        this.$store.commit("SAVE_PAGE_HEADER", "Network Approval");
        this.token=this.$store.getters.successToken

        this.wrkitmdta(this.pageInsti)
        
        // this.getCreateProduct(this.page);
    },
    filters:{
        truncate: function (text, length, suffix) {
            if (text == "" || text == "undefined" || text == null || text == '' || text == 'Invalid Date') {
                return "-"
            }else if (text.length > length) {
                return text.substring(0, length) + suffix;
            } else {
                return text;
            }
        },
        checkdata(data){// eslint-disable-line no-mixed-spaces-and-tabs
            // console.log('Validity Data', data);
            if (data == "" || data == "undefined" || data == null || data == '' || data == 'Invalid Date') {
                return "-"
            } else {
                return data;
            }
        },
        formatDate(data){// eslint-disable-line no-mixed-spaces-and-tabs
            // console.log('Validity Data', data);

             if (data == "" || data == "undefined" || data == null || data == '' || data == 'Invalid Date') {
                return "-"
            } else {
                // var n = new Date(data);
                // return n.toLocaleString("en-IN")
                return moment(data,'YYYY-MM-DD').format('DD-MM-YYYY')
            }
        },
    

    //   truncate: function (text, length, suffix) {
    //         if (text.length > length) {
    //             return text.substring(0, length) + suffix;
    //         } else {
    //             return text;
    //         }
    //     }
    },

    computed: {
    },

    data() {
        return {
            tablerowdata:{},
            workItemId:"",
            productCode:"",
            policyNumber:"",
            pageInsti: 1,
            pagelength:0,
            fval:0,
            sval:0,
            totalLen:0,
            swap_final_count:false,
            typesearch:false,
            provDataArray:[],
            
            headers:[
                {text: 'Work Item ID', value: 'workItemID',sortable: false,divider:true, class:"hdr-tarif-approv"},
                {text: 'Provider Name', value: 'provName',sortable: false,divider:true,class:"hdr-tarif-approv"},
                {text: 'PRN No', value: 'prnNo',sortable: false,divider:true,class:"hdr-tarif-approv"},
                {text: 'Network Name', value: 'netwrkName',sortable: false,divider:true,class:"hdr-tarif-approv"},
                {text: 'Network status', value: 'netwrkStatus',sortable: false,divider:true,class:"hdr-tarif-approv"},
                {text: 'Work Item Assigned Date', value: 'workItemAssignDate',sortable: false,divider:true,class:"hdr-tarif-approv"},
                {text: 'Approver Decision', value: 'ApproverDecision',sortable: false,divider:true,class:"hdr-tarif-approv",},
                 {text: 'Remarks', value: 'remarks',sortable: false,divider:true,class:"hdr-tarif-approv"}
            ],
            wrkItmDtaLst:[],
            GetsetupData1:[
                {
                   WorkItemID:"1223",
                    ProductCode:"GRPMEDC",
                    ProductType:"Group Health",
                    ProductDescription:"Group Medicare",
                    PolicyNumber:"10029876",
                    WorkItemAssignedDate:"19-12-2019",
                    Status:"Pending for Approval ",
                    Remarks:"RejectedRejected"
                },{
                   WorkItemID:"1223",
                    ProductCode:"GRPMEDC",
                    ProductType:"Group Health",
                    ProductDescription:"Group Medicare",
                    PolicyNumber:"10029876",
                    WorkItemAssignedDate:"19-12-2019",
                    Status:"Pending for Approval ",
                    Remarks:"Rejected"
                },{
                   WorkItemID:"1223",
                    ProductCode:"GRPMEDC",
                    ProductType:"Group Health",
                    ProductDescription:"Group Medicare",
                    PolicyNumber:"10029876",
                    WorkItemAssignedDate:"19-12-2019",
                    Status:"Pending for Approval ",
                    Remarks:"Rejected"
                }
            ],

            token:null,
            valid:false,
            srchApicall:false,
            selected:[],
            itemsPerPage:10,
            size: 10,
            RmrkDilg:false,
            rjctRmk:"",
        };
    },

    methods: {
        wrkitmdta(pageNum){ 
            let self=this
            self.wrkItmDtaLst=[]
            self.GET(5,'work-items/get-network-management-work-item?perPage=10&pageNo='+ pageNum,(res,error)=>{
                if(!error){
                    if(res.data.statusCode === 200){
                        let _resp = res.data.data.list
                        let provName = []
                        let provPRN = []
                        let provdrData = []
                        for(let _data of _resp){
                            provName = []
                            provPRN = []
                            provdrData = []
                            for(let _prov of _data.networkProviderDetails){
                                provName.push(_prov.providerName)
                                provPRN.push(_prov.providerPRN)
                                provdrData.push(_prov)
                            }
                            let _table = {
                                workItemID : _data.itemId,
                                provName : provName.toString(),
                                prnNo : provPRN.toString(),
                                netwrkName : _data.name,
                                provdrData : provdrData,
                                netwrkStatus : _data.hasOwnProperty('networkStatus') ?  _data.networkStatus : '',
                                remarks: _data.hasOwnProperty('providerApprovalRemark') ?  _data.providerApprovalRemark : '',
                                workItemAssignDate : new Date(_data.updatedAt).toLocaleDateString(),
                            }
                            self.wrkItmDtaLst.push(_table)
                        }

                        // for pagination
                        self.pagelength = res.data.data.total_pages
                        self.totalLen = res.data.data.total

                        var less_enough = self.totalLen
                        var checkless_init = false
                        less_enough < 10 ? checkless_init = false : checkless_init = true

                        // if less than 15 we have second value same as total value as no pagination will occur
                        if(checkless_init){
                            // checkinit is true means the final count is more than 15 (- 1)
                            var traverse =  10 * self.pageInsti
                            self.fval = traverse - 9
                            self.swap_final_count ? self.sval =  self.totalLen : self.sval = traverse
                        }else if(self.totalLen == 0){
                            self.sval = self.fval = 0
                        
                        }else{
                            self.fval = 1
                            self.sval = self.totalLen
                        }
                    }else{
                        self.showToast(res.data.msg ,self.TOST().ERROR); 
                    }
                }else{
                    self.showToast(error.response.data.msg, self.TOST().WARNING);
                }
            })
        },

        wrkitmidClick(id){
            // if(id===""|| id=== null|| id===undefined){
            //     console.log(id," doc id")
            //     return
            // }
            // this.$store.commit('SAVE_PRODUCT_NAME', id)
            // let btnName={
            //     text:'View Work Item',
            //     visible:false
            // }
            // this.$store.commit('SAVE_PRODUCT_BTN_NAME', btnName)
            // this.$router.push('/product/createEditProduct')
        },

        approveProduct(item){
            let self = this
            let _finalArray = []
            let prevData = self.$route.params.prevProvData
            self.tablerowdata = item
            // let _finalArray = item.provdrData.filter(prov => prevData.some(tarr => tarr.providerId == prov.providerId));
            for(let _prev of prevData){
                _finalArray = []
                for(let _provider of item.provdrData){
                    if(_prev.providerId !== _provider.providerId){
                        _finalArray.push(_provider)
                    }else{
                        let ItemIndex = prevData.findIndex(event => event.providerId === _provider.providerId);
                        prevData.splice(ItemIndex, 1)
                        _finalArray.push(_provider)
                    }
                }
            }
            self.provDataArray = prevData.length !== 0 ? prevData.concat(_finalArray) : item.provdrData
            // self.provDataArray = prevData.concat(_finalArray)
            let netwrkIDStore = self.$store.state.createNetworkData._id
            let formData = {
                providerDetails: self.provDataArray
            }
            if(self.provDataArray.length !== 0){
                self.PUT(4,'network-management/add-provider-to-network/' + netwrkIDStore,formData,(res,error)=>{
                    if(!error){
                        if(res.data.statusCode === 200){
                            self.showToast(res.data.msg, self.TOST().SUCCESS);
                            self.updateProvAPI(self.$route.params.selectedTariffData ,netwrkIDStore)
                            // self.pageInsti = 1
                            // self.wrkitmdta(self.pageInsti)
                            self.rejectAcceptProduct('Approved')
                        }else{
                            self.showToast(res.data.msg ,self.TOST().ERROR); 
                        }
                    }else{
                        self.showToast(error.response.data.msg, self.TOST().WARNING);
                    }
                })
            }else{
                self.showToast('Please Add Providers to the Network', self.TOST().WARNING);
            }
            
        },
        updateProvAPI(ev,ntwrkID){
            let self = this
            let updatData = []
            for(let _props of ev){
                if(Object.keys(_props.tariff).length !== 0){
                    let _data = {
                        providerId: _props.providerId,
                        tariffDetails : {
                            tariffId: _props.tariff.value,
                            tariffName: _props.tariff.text
                        }
                    }
                    updatData.push(_data)
                }
            }
            if(updatData.length !== 0){
                self.PUT(4,'network-management/update-provider-tariff-of-network/'+ ntwrkID,updatData,(res,error)=>{})
            }
            
        },
        clear_Rmrks(){
            this.rjctRmk = ''
        },

        rejectAcceptProduct(status){
            let self = this
            let workItemId = self.tablerowdata.workItemID
            
            let formData = {}
            if(status == 'Approved'){
                formData.networkStatus = status
            }else{
                if( self.rjctRmk == ''|| self.rjctRmk == null|| self.rjctRmk == undefined){
                    self.showToast('Please enter remarks to Reject a Product', self.TOST().WARNING);
                    return
                }
                formData.providerApprovalRemark = self.rjctRmk
                formData.networkStatus = status
                formData.networkRejectStatus = self.rjctRmk
            }
            // console.log("=========(((((((((formData )))))))))=======>>",formData)

            self.PUT(5,'work-items/update-empaneled-work-item/'+ workItemId,formData,(res,error)=>{
                if(!error){
                    if(res.data.statusCode === 200){
                        self.RmrkDilg = false
                        // self.showToast(res.data.msg,self.TOST().SUCCESS)
                        self.pageInsti = 1
                        self.wrkitmdta(self.pageInsti)
                        
                    }else{
                        self.showToast(res.data.msg ,self.TOST().ERROR); 
                    }
                }else{
                    // self.showToast(error.response.data.msg, self.TOST().WARNING);
                }
            })
        },

        nextPageData(indata){
            this.pagelength == indata ? this.swap_final_count = true : this.swap_final_count = false
            this.typesearch == false ? this.wrkitmdta(this.pageInsti) : this.srchWrkItms(this.pageInsti)
        },
        
        editRmrk(item,rmkTxt){
            this.tablerowdata = item
            this.RmrkDilg = true
            this.rjctRmk = rmkTxt
        },

        close_dwnld_dlg(){
            this.RmrkDilg=false
        },
    },

    watch: {
    },
}

</script>
<style >
.cust_pd .v-text-field .v-input__slot,
.v-text-field--outline .v-input__slot {
    min-height: 42px !important;
    display: flex !important;
    align-items: center !important;
    /* border:1px red solid; */
}

.cust-rmvpd-pd .v-text-field.v-text-field--enclosed>.v-input__control>.v-input__slot {
    padding: 0px !important;;
}

.cstm-clr-psap{
    color:#646666 !important;
    margin-bottom: 5px !important;
    font-size: 13px !important;
}

.cust_pd .v-input .v-label {
    font-size: 13px !important;
}
.cust_pd .v-input {
    font-size: 13px !important;
}
.cust_pd .v-input input {
    font-size: 13px !important;
}

.title-clr-pr{
    color:#10242B;
    margin-bottom: 5px;
}

.lbl-bgclr{
    color:#152F38 !important;

}
.hdr-tarif-approv{
    color:#10242B !important;
    padding:0px!important;
    padding: 9px !important;
    padding-left: 14px !important;
}

/* .pdct-stUp-tbl table.v-table thead th{
    border-right:1px solid  #C1C8CC !important;
    background-color:#F3FBFB !important;
    color:#10242B !important;
    font-size:13px !important;
    font-family:Roboto !important;
    font-weight:700 !important;
    padding: 0px!important;
    padding-left: 9px !important;
} */
/* .pdct-stUp-tbl table.v-table thead th:last-child{
    border-right: unset !important;
    background-color:#F7F7F7 !important;
} */
/* .pdct-stUp-tbl table.v-table tbody td,
.pdct-stUp-tbl table.v-table thead tr{
    height: 40px !important;
    padding-left: 12px !important;
}
.pdct-stUp-tbl table.v-table thead tr:last-child{
    height: unset !important;
    
} */

.pdct-stUp-tbl-brdr{
    border:1px solid  #C1C8CC;
}

.pdct-stUp-td-bdrgt {
    border-right:1px solid #C1C8CC;
 }

 .pdct-stUp-bnkAcc-pgn .v-pagination__navigation{
        box-shadow:unset !important;
        border-radius: unset !important;
        background-color:#FFFFFF !important;
        border:1px solid #DAE1E6 !important;
        height: 30px !important;
        width: 30px !important;
    }
    .pdct-stUp-bnkAcc-pgn .v-pagination__navigation--disabled{
        background-color:#DAE1E6 !important;
    }
    .pdct-stUp-bnkAcc-pgn .v-pagination__item{
        box-shadow:unset !important;
        min-width:1.8rem !important;
        height:1.8rem !important;
        border-radius: unset !important;
        border:1px solid #DAE1E6 !important
    }

    .pdct-stUp-tbl .v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
    border: 1px solid  #C1C8CC !important;
}

.pdct-stUp-tbl .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
    border: 1px solid #C1C8CC !important;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
    font-size: 0.84rem !important;
}

 .v-dialog{
    overflow-y: unset !important;
}


</style>