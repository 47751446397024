<template>
  <v-app>
    <!-- <v-card class="ma-3 " tile> -->
    <div>
      <v-card class="pa-3 mb-1" style="" tile>
        <v-layout align-center class="py-2" >
            <v-flex class="text-uppercase font-weight-bold title-clr-prtbs ml-2" xs1>CFR Dashboard</v-flex>
            <v-divider vertical class="mx-4"></v-divider>
            <v-btn-toggle dense mandatory v-model="selected_lob" tile class="" style=""  @change="changeLobData">
                <div xs3 v-for="(lobData, index) in lobListAndCount" :key="index">
                  <v-badge
                    bordered
                    color="error"
                    class="badge-custom"
                    :content="lobData.total || '0'"
                    overlap
                    left
                  >
                    <v-btn
                      color="#FFFFFF"
                      light
                      @click="selected_lob = lobData"
                      :class="{ selected_button: lobData.lob === selected_lob.lob }"
                      class="textTransformCapitalize mastersButtonWidth"
                    >
                      <div class="d-flex">
                        <!-- <div class="icon-wrap pl-custom">
                          <v-icon :class="lobData.icon"></v-icon>
                        </div> -->

                        <div class="mastersDiv"></div>
                      </div>
                      <div class="text">
                        {{ lobData.lob }}
                      </div>
                    </v-btn>
                  </v-badge>
                </div>
            </v-btn-toggle>                                              
        </v-layout>
      </v-card>
    </div>
    <div v-if="showHideHealth">
      <v-layout class="pa-2 ma-3 py-3 text-center">
        <v-flex xs3>
          <div
            style="background-color: #F7F7F7; cursor:pointer;"
            class="pa-2 pr-14 "
            :class="[
              CFRbtnStatus == 'caseUnderCFR'
                ? 'activeRadioclass'
                : '',
            ]"
              @click="changeCFEStatus('caseUnderCFR')"
          >
            <div
              class="font-weight-bold"
              :class="[
                CFRbtnStatus == 'caseUnderCFR'
                  ? 'text-white-insrns'
                  : 'text-dark-insrns',
              ]"
            >
            <!-- CFRbtnStatus -->
              Cases Under CFR
            </div>
          </div>
        </v-flex>
        <v-flex xs3>
          <div
            style="background-color: #F7F7F7;  cursor:pointer"
            class="mr-4 pa-2"
            :class="[
              CFRbtnStatus == 'cfrCompletedCase'
                ? 'activeRadioclass'
                : '',
            ]"
            @click="changeCFEStatus('cfrCompletedCase')"
          >
            <div
              class="font-weight-bold"
              :class="[
                   CFRbtnStatus == 'cfrCompletedCase'
                  ? 'text-white-insrns'
                  : 'text-dark-insrns',
              ]"
            >
              CFR Completed Cases
            </div>
          </div>
        </v-flex>
        <v-flex xs3></v-flex>
        <v-flex xs3>
        </v-flex>

        <v-flex xs3>
            <div >
              <!-- v-if="this.incmdata[0] == 'CG1' || this.incmdata[0] == 'CG2'" -->
                    <v-btn
                        v-if="showactivitybutton"
                        tile
                        color="#E46A25"
                        class="text-capitalize text-white px-3 mx-2 my-3"
                        small
                        style="color: #fff;font-size:11px"
                        @click="ViewActivityDashboard()"
                    >
                        <v-icon dark small>visibility</v-icon>
                        <v-divider vertical class="mx-2"></v-divider>
                        <span style="font-size:11px">View Activity Dashboard</span>                     
                    </v-btn>
            </div>
        </v-flex>
                        <!-- v-if="this.fetchRoleValue() == 'CG1' || this.fetchRoleValue() == 'CG2'" -->

        <!-- <v-flex xs3></v-flex> -->
      </v-layout>
      <div class="mt-2 mb-2" style="height:1px; background-color:#C1C8CC"></div>

    <div v-if="valStaus==true ">  
      <v-data-table
        :headers="headers"
        :items="GetData"
        :page.sync="page"
        
        hide-default-footer
        dense
        @page-count="pageCount = $event"
      >

        <!-- 
          :items-per-page="itemsPerPage"
          <template v-slot:item.action="{ item }">
          <v-btn
            tile
            color="#fff"
            class="text-capitalize text-dark px-3 mx-2 my-2"
            small
            >
            <v-icon dark small>visibility</v-icon>
            </v-btn
          >
        </template> -->

        <template v-slot:[`item.coverageName`]="{ item}">
       {{item.coverageName}} 
      </template> 

        <template v-slot:[`item.action`]="{ item }">

          <p @click="gotoCFRaudit(item)" class="mt-2 " style="text-decoration: underline; cursor: pointer;color: blue">View CFR</p>
        </template>
         <!-- <template v-slot:item.queryId="{ item }">
          <p @click="gotoQuery(item._id)" style="text-decoration: underline; cursor: pointer;color: blue">{{
           item.queryId
          }}</p>
        </template> -->
    
      </v-data-table>
    </div>

      <div v-if="valStaus==false ">
          <v-data-table
            :headers="headers123"
            :items="GetDataComplet"
            :page.sync="page"

            hide-default-footer
            dense
            @page-count="pageCount = $event"
          >
            <!-- 
                          :items-per-page="itemsPerPage"
              <template v-slot:item.coverage="{ item ,value}">
               {{item.coverage}} 
            </template>  -->
              <template v-slot:[`item.coverageName`]="{ item }">
                <p >{{item.coverageName}}</p>
        
              </template> 
      
            <template v-slot:[`item.action`]="{ item }">

              <v-layout class="">
                <p @click="gotoCFRaudit(item)" class="mt-2 " style="text-decoration: underline; cursor: pointer;color: blue;  width: 60px;">View CFR</p>
                <p @click="goToRecovery(item)" class="mt-2 ml-4" style="text-decoration: underline; cursor: pointer;color: blue">Recovery</p>
              </v-layout>            
            </template>

        
          </v-data-table>

      </div>

      <v-divider></v-divider>

      <v-layout row justify-end align-center class="ma-1">
        <v-flex
          class="mr-1 font-weight-bold body-1"
          style="color:#3C3D3D; font-size:12px !important"
          xs3
          align-self-end
        >
          <!-- <span class="" style="color:#E46A25;font-size:12px;">Showing 1-10 </span>out of {{total}} records -->
          <span class="" style="color:#E46A25;"
            >Showing {{ fval }}-{{ sval }} </span
          >out of {{ totalLen }} records
        </v-flex>
        <v-divider class="mx-2" vertical></v-divider>
        <v-pagination
          class="cust-bnkAcc-pgn"
          v-model="page"
          color="#152F38"
          flat
          :total-visible="9"
          @input="nextPageBtn(page)"
          :length="pagelength"
        ></v-pagination>
      </v-layout>
    </div>
    <!-- Dashboard for Other than Health LOB's -->
     <v-container
      class="containerStyle"
      style="padding:1.5rem; margin:0; max-width:100%"
      v-if="showHideOtherThanHealth"
    >
    <h3>Closed File Reviews</h3>
    <v-layout class="mt-5">

      <v-btn solo outlined 
      :class="[caseUnderCFR == true ? 'activebg' : 'inactivebg' ]" 
      style="padding:25px;width:200px;border-radius:none" 
      class="createbtn px-2"
      @click="caseUnderCFR=!caseUnderCFR;cfrCompletedCase=!cfrCompletedCase;callingauxiliarapi()" >Cases Under CFR</v-btn>

      <v-btn solo outlined 
      :class="[cfrCompletedCase == true ? 'activebg' : 'inactivebg' ]" 
      style="padding:25px;width:200px;border-radius:none" 
      class="createbtn  ml-5"
      @click="cfrCompletedCase=!cfrCompletedCase;caseUnderCFR=!caseUnderCFR;callingauxiliarapi()" >CFR Completed Cases</v-btn>
    </v-layout>

    <v-layout class="mt-5">
        <div style="display:flex;align-items:center;column-gap:8px;">
            <div>
                <h4 >Closed File Review List <span class="orange--text" v-if="count">({{count}})</span></h4>
            </div>
            <v-chip small color="#1EBBD9" text-color="white" label close
                v-if="showSearchChip==true"
                @click:close="searchChipButton">
                Filter
              </v-chip>
        </div>
        <v-spacer></v-spacer>
          <v-btn depressed color="#E46A25" class="white--text mr-3" @click="exportCfr">
            <v-icon>
                mdi-file-excel
            </v-icon>
            <v-divider class="mx-4" vertical color="white"></v-divider>
            Export
          </v-btn>
        <v-btn color="#23b1a9" class="createbtn white--text " solo dense @click="dialog_search=!dialog_search">
            <v-icon >mdi-magnify</v-icon>
            <v-divider></v-divider>
            Search
        </v-btn>
        <v-btn class="mx-2 createbtn white--text" color="#e46a25" solo dense v-if="false">
            <v-icon>mdi-cloud-download</v-icon>
            <v-divider vertical class="mx-2 white--text"></v-divider>
            Download
        </v-btn>
        <v-dialog v-model="dialog_search" width="1000">
        <v-card>
                      <v-card-title
                        class="white--text"
                        style="background-color: #23b1a9"
                      >
            <h3 class="ml-5">Closed File Review Search</h3>
            <v-spacer></v-spacer>
            <v-icon @click="dialog_search=!dialog_search" class="white--text">mdi-window-close</v-icon>
        </v-card-title>
        <v-card-text class=" grey lighten-2">
            <v-layout wrap class="pt-4 form-group-container">
                <v-flex class="form-groups">
                    <v-label class="lableFontSize">
                        Claim No
                    </v-label>
                    <v-text-field
                        label="Enter"
                        solo
                        dense
                        v-model="claimNumber"          
                        class="forminput"
                        maxlength="50"
                        counter="50"
                      ></v-text-field>
                </v-flex>
                <v-flex class="form-groups">
                    <v-label class="lableFontSize">
                        Feature No.
                    </v-label>
                    <v-text-field
                        label="Enter"
                        solo
                        dense
                        v-model="featureNumber"          
                        class="forminput"
                        maxlength="50"
                        
                      ></v-text-field>
                </v-flex>
                <v-flex class="form-groups">
                    <v-label class="lableFontSize">
                        Claimant Name
                    </v-label>
                    <v-text-field
                        label="Enter"
                        solo
                        dense
                        v-model="claimantName"          
                        class="forminput"
                        maxlength="50"
                        
                      ></v-text-field>
                </v-flex>
                <v-flex class="form-groups">
                    <v-label class="lableFontSize">
                        Insured Name
                    </v-label>
                    <v-text-field
                        label="Enter"
                        solo
                        dense
                        v-model="insuredName"          
                        class="forminput"
                        maxlength="50"
                        
                      ></v-text-field>
                </v-flex>
            </v-layout>

            <v-layout  class="form-group-container">
                <!-- <v-flex class="form-groups">
                    <v-label class="lableFontSize">
                        CFR status
                    </v-label>
                    <v-text-field
                        label="Enter"
                        solo
                        dense          
                        class="forminput"
                        maxlength="50"
                        
                      ></v-text-field>
                </v-flex> -->
                <v-flex class="form-groups">
                    <v-label class="lableFontSize">
                        Examiner Name
                    </v-label>
                    <v-text-field
                        label="Enter"
                        solo
                        dense
                        v-model="examinerName"          
                        class="forminput"
                        maxlength="50"
                        
                      ></v-text-field>
                </v-flex>
                <v-flex class="form-groups">
                    <v-label class="lableFontSize">
                        Date Reported From
                    </v-label>
                    <v-menu
                        v-model="menu"
                        offset-y
                        nudge-top="20"
                        :close-on-content-click="false"
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            :value="computeddateReportedFrom"
                            placeholder="DD/MM/YYYY"
                            
                            dense
                            solo
                            readonly
                            v-on="on"
                            @focus="menu = true"
                            @blur="menu = true"
                            v-bind="attrs"
                            class="forminput"
                            append-icon="mdi-menu-down"
                            prepend-inner-icon="mdi-calendar"
                          >
                          </v-text-field>
                        </template>
                        <v-date-picker
                          no-title
                          :pickerDate.sync="pickerDate"
                          :max=getEndDate
                          v-model="dateReportedFrom"
                          @input="menu = false"
                        >
                        </v-date-picker>
                      </v-menu>
                </v-flex>
                <v-flex class="form-groups">
                    <v-label class="lableFontSize">
                        Date Reported To
                    </v-label>
                    <v-menu
                        v-model="menu1"
                        offset-y
                        nudge-top="20"
                        :close-on-content-click="false"
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            :value="computeddateReportedTo"
                            placeholder="DD/MM/YYYY"
                            
                            dense
                            solo
                            readonly
                            v-on="on"
                            @focus="menu1 = true"
                            @blur="menu1 = true"
                            v-bind="attrs"
                            class="forminput"
                            append-icon="mdi-menu-down"
                            prepend-inner-icon="mdi-calendar"
                          >
                          </v-text-field>
                        </template>
                        <v-date-picker
                          no-title
                          :pickerDate1.sync="pickerDate"
                          :max=getEndDate
                          v-model="dateReportedTo"
                          @input="menu1 = false"
                        >
                        </v-date-picker>
                      </v-menu>
                </v-flex>
                <v-flex class="form-groups">
                    <v-label class="lableFontSize">
                        Month
                    </v-label>
                    <v-select
                        solo
                        :items="monthList"
                        item-text="name"
                        item-value="value"
                        dense
                        class="form-control"
                        v-model="month"
                        placeholder="Select...">
                    </v-select>
                </v-flex>
            </v-layout>
        </v-card-text>
        <v-card-actions>
            <v-btn class="reset createbtn" style="background:white " dark @click="clear()">Reset</v-btn>
            <v-spacer></v-spacer>
            <v-btn class="createbtn cancelbtn" @click="dialog_search=!dialog_search">Cancel</v-btn>
            <v-btn color="#23b1a9" class="createbtn white--text" @click="searchCfr(); showSearchChip = true" >Search</v-btn>
        </v-card-actions>
        </v-card>
    </v-dialog>
    </v-layout>
    <v-data-table
      hide-default-footer
      :headers="table_headers"
      :items="cfr_Table"
      dense
      class="mt-5 vDataTableStyling"
    >
    <template v-slot:[`item.action`]="{ item }">
        <a class="tablebtn mr-1"  @click="viewOpenFilePage(item)">                            
            <v-icon class="tableicons">mdi-eye-outline</v-icon><span class="ml-0 viewStyle"> VIEW </span>
        </a>
    </template>         
    <template v-slot:[`item.Date`]="{ item }">
        {{item.Date?dateConvert(item.Date):"----"}}
    </template> 
    <template v-slot:[`item.turnAroundTime`]="{item}">
        {{turnAroundTime(item.createdAt, item.Date)}}
    </template>    
    <template v-slot:[`item.userId`]="{ item }">
        {{item.userId || "----"}}
    </template>    
    <template v-slot:[`item.createdAt`]="{ item }">
        {{item.createdAt?dateConvert(item.createdAt):"----"}}
    </template>         
    <template v-slot:[`item.cfrStatus`]="{ item }">
        <span :class="checkCfrStatus(item)">{{ item.cfrStatus === true ? "Closed" : 'Open'}}</span>         
    </template>
    </v-data-table>
    <div   class="pt-2">
      <v-row class="text-right px-4 align-center">
        <v-col class="mt-5">
          <p>
            <span class="result"
              >Showing {{ pageStartIndex }} - {{ pageEndIndex }}</span
            >
            out of {{ totalListCount }} records
          </p>
        </v-col>
        <v-pagination
          v-model="page"
          :length="pageCount"
          :total-visible="10"
          @input="searchCfr"
          prev-icon="mdi-arrow-left"
          next-icon="mdi-arrow-right"
          color="#152F38"
        >
        </v-pagination>
        <!--  -->
      </v-row>
    </div>

</v-container>

    <!-- </v-card> -->
  </v-app>
</template>

<script>
import moment from "moment"
export default {
  components: {},

  created() {
    this.$store.commit("SAVE_PAGE_HEADER", "CFR Dashboard");

    console.log("Success Token", this.$store.getters.successToken);
    this.token = this.$store.getters.successToken;
    this.getCFRData(this.page);
    this.getCountForLOB();
    this.getviewactivitybutton();
    this.incmdata=this.fetchRoleValue()
    console.log("this.incmdata--------->>>>", this.incmdata);
 },

  computed: {
    computeddateReportedFrom(){
      return this.formatDate(this.dateReportedFrom);
    },
    computeddateReportedTo(){
      return this.formatDate(this.dateReportedTo);
    },
    getEndDate() {
     var endDate = new Date();
     return endDate.toISOString().slice(0,10)
    },
    pageEndIndex(){
        if(!this.totalListCount){
            return 0
        }else{
            return ((this.pageStartIndex + (this.itemsPerPage)) <= this.totalListCount) ? this.pageStartIndex + (this.itemsPerPage) - 1 : this.totalListCount;
            
        }
    }
  },

  data() {
    return {
      showactivitybutton:false,
      CFRbtnStatus: 'caseUnderCFR',
      incmdata:'',
      // referredFrom: "ReferredFromPreauth",
      // for pagination AA
      swap_final_count: false,
      fval: 0,
      sval: 0,
      totalLen: "",

      fromvalue: "",
      toValu: "",
      token: null,
      page: 1,

      GetData:[],
      GetDataComplet:[],
      valStaus:true,

      // GetData1: [
      //     {
      //     claimId: "Claim ID",
      //     featureNo: "1211111"

      //   }
      // ],
      size: 10,
      pagelength:0,
      
      headers: [
         {
          text: "Claim ID",
          value: "claimId",
          sortable: true,
          divider: true,
          class: "bg-investigation-table-header-color minWidth"
        },
        {
          text: "Feature Number",
          value: "featureNumber",
          sortable: false,
          divider: true,
          class: "bg-investigation-table-header-color minWidth",
        },
        {
          text: "Insured Name",
          value: "insuredName",
          sortable: false,
          divider: true,
          class: "bg-investigation-table-header-color minWidth",
        },
        {
          text: "Coverage",
          value: "coverageName",
          sortable: false,
          divider: true,
          class: "bg-investigation-table-header-color minWidth",
        },
        {
          text: "Policy Number",
          value: "policyNumber",
          sortable: false,
          divider: true,
          class: "bg-investigation-table-header-color minWidth",
        },
        {
          text: "Product Name",
          value: "productName",
          sortable: false,
          divider: true,
          class: "bg-investigation-table-header-color minWidth",
        },
        {
          text: "Total Amount Approve",
          value: "totalAmountApproved",
          sortable: false,
          divider: true,
          class: "bg-investigation-table-header-color minWidth",
        },
        {
          text: "Hospital Name",
          value: "hospitalName",
          sortable: false,
          divider: true,
          class: "bg-investigation-table-header-color minWidth",
        },
        {
          text: "Patient Name",
          value: "patientName",
          sortable: false,
          divider: true,
          class: "bg-investigation-table-header-color minWidth",
        },
        {
          text: "Corporate Name",
          value: "corporateName",
          sortable: false,
          divider: true,
          class: "bg-investigation-table-header-color minWidth",
        },
        {
          text: "TAT",
          value: "TAT",
          sortable: false,
          divider: true,
          class: "bg-investigation-table-header-color minWidth",
        },
        {
          text: "Action",
          value: "action",
          sortable: false,
          divider: true,
          class: "bg-investigation-table-header-color minWidth",
        }

        // {text: 'Action', value: 'Action',sortable: false,divider:true, width:"40px",class:"hdr-txt-clr-txt hdr-bg-clr"}
      ],

      headers123: [
         {
          text: "Claim ID",
          value: "claimId",
          sortable: true,
          divider: true,
          class: "bg-investigation-table-header-color minWidth"
        },
        {
          text: "Feature Number",
          value: "featureNumber",
          sortable: false,
          divider: true,
          class: "bg-investigation-table-header-color minWidth",
        },
      
        {
          text: "Hospital Name",
          value: "hospitalName",
          sortable: false,
          divider: true,
          class: "bg-investigation-table-header-color minWidth",
        },
        {
          text: "Patient Name",
          value: "patientName",
          sortable: false,
          divider: true,
          class: "bg-investigation-table-header-color minWidth",
        },
        {
          text: "Policy Number",
          value: "policyNumber",
          sortable: false,
          divider: true,
          class: "bg-investigation-table-header-color minWidth",
        },
        {
          text: "Net Score",
          value: "netScore",
          sortable: false,
          divider: true,
          class: "bg-investigation-table-header-color minWidth",
        },
        {
          text: "Recovery to be Initiated",
          value: "recoveryToMade",
          sortable: false,
          divider: true,
          class: "bg-investigation-table-header-color minWidth",
        },
        {
          text: "Type of Recovery",
          value: "typeOfRecovery",
          sortable: false,
          divider: true,
          class: "bg-investigation-table-header-color minWidth",
        },
        {
          text: "Comments",
          value: "comments",
          sortable: false,
          divider: true,
          class: "bg-investigation-table-header-color minWidth",
        },


        {
          text: "Action",
          value: "action",
          sortable: false,
          divider: true,
          class: "bg-investigation-table-header-color minWidth",
        }

      ],
      // selectedLob:"Health",
      itemsOfLOB:[],
      lobListAndCount:[],
      selected_lob:{},
      showHideHealth:false,
      showHideOtherThanHealth:false,
      //Other than Health LOB
      pageCount:0,
      totalListCount: 0,
      pageStartIndex: 0,
      // pageEndIndex:0,
      page: 1,
      itemsPerPage: 10,
      pickerDate:null,
      pickerDate1:null,
      menu:false,
      menu1:false,
      dateReportedTo:"",
      dateReportedFrom:"",
      claimNumber:"",
      featureNumber:"",
      claimantName:"",
      insuredName:"",
      examinerName:"",
      month: "",
      caseUnderCFR:true,
      cfrCompletedCase:false,
      cfr_Table:[],
      btn2:false,
      count:0,
      dialog_search:false,
      table_headers:[
      {text:"Action", align: "center",width:"100px", sortable: false,value:"action"},
          {text:"Claim No", align: "center",width:"100px", sortable: false,value:"claimNumber"},
          {text:"Feature No", align: "center",width:"100px", sortable: false,value:"featureNumber"},
          {text:"Claim Status", align: "center",width:"100px", sortable: false,value:"claimStatus"},
          {text:"Policy Number", align: "center",width:"100px", sortable: false,value:"policyNumber"},
          {text:"Certificate Number", align: "center",width:"100px", sortable: false,value:"certificateNumber"},
          {text:"Product Name", align: "center",width:"100px", sortable: false,value:"productName"},
          {text:"Coverage", align: "center",width:"200px", sortable: false,value:"coverage[coverName]"},
          {text:"Claimant Name", align: "center",width:"100px", sortable: false,value:"claimantName"},
          {text:"Insured Name", align: "center",width:"200px", sortable: false,value:"insuredName"},
          {text:"Total Approved Amount", align: "center",width:"200px", sortable: false,value:"claimedAmountPaid"},
          {text:"Total Indemnity Paid", align: "center",width:"100px", sortable: false,value:"indemnityPaid"},
          {text:"Total Expense Paid", align: "center",width:"100px", sortable: false,value:"expensePaid"},
          {text:"Examiner Name", align: "center",width:"100px", sortable: false,value:"examiner.employeeName"},
          {text:"Sum Insured", align: "center",width:"100px", sortable: false,value:"sumInsured"},
          {text:"Outstanding Reserve Amount", align: "center",width:"100px", sortable: false,value:"reserveAmount"},
          {text:"Reason", align: "center",width:"200px", sortable: false,value:"reason"},
          {text:"CFR Reviewed Date", align: "center",width:"100px", sortable: false,value:"Date"},
          {text:"CFR Reviewed By", align: "center",width:"100px", sortable: false,value:"userId"},
          {text:"Turn Around Time", align: "center",width:"100px", sortable: false,value:"turnAroundTime"},
          {text:"CFR Status", align: "center",width:"100px", sortable: false,value:"cfrStatus"},
          {text:"Created At", align: "center",width:"150px", sortable: false,value:"createdAt"},
      ],
      showSearchChip: false,
      monthList: [{name: "January", value: 1},{name: "February", value: 2},{name: "March", value: 3},{name: "April", value: 4},{name: "May", value: 5},{name: "June", value: 6},{name: "July", value: 7},{name: "August", value: 8},{name: "September", value: 9},{name: "October", value: 10},{name: "November", value: 11},{name: "December", value: 12}],
      

    };
  },

  methods: {

    getviewactivitybutton(){
      let apiURL = "permission/get-permissions"
      this.GET(6, apiURL, (response, error) => {
        console.log("permissoin::::",response.data.data)
        let Apiresponse = response.data.data
        for(let i=0;i<Apiresponse.length;i++){
          if(Apiresponse[i].screenName == 'View Activity Dashboard'){
            this.showactivitybutton = true
          }
        }
      })
    },

    ViewActivityDashboard(){

      this.$router.push({
          name: "CFRviewActivity",
          params: { from: 'CFRdashboard',screen:'View Activity Dashboard' },
         
        });

    },
      changeCFEStatus(val){
          console.log(val,'ww');
          this.CFRbtnStatus = val;
        //   this.headers[1].text = val +' '+'Query ID';
          
          this.getCFRData(1);
  
      },

    gotoCFRaudit(val) {
        console.log('_idiii',val);
    //   if(this.CFRbtnStatus == 'caseUnderCFR'){
         this.$store.commit("SAVE_PAGE_HEADER", "CFR Audit Sheet");
         this.$router.push({
          name: "CFRauditSheet",
          params: { allData: val, from: 'CFRdashboard',screen:'CFR Audit Sheet' },
         
        });
    },
    

    // goToRecovery(val) {
		// 	if(val.cfrCompletedCase == 'true' ){
		// 	  let routeData =  this.$router.resolve({name: 'RecoverySummary', query: {claimId:val.claimRegistrationId} });
		// 		console.log("routerData::>",routeData)
    //         	window.open(routeData.href, '_blank');
		// 	}else{
		// 		this.showToast(`Recovery Cannot be created for caseUnderCFR claims`, this.TOST().WARNING);
		// 	}
		// },

    // AA redirect to health system------>>>>>>

    goToRecovery(val){
      console.log("val---------===>",val);
        try{
          let sellob = {
              lob:'' ,
              usr_jti:this.$store.state.Auth.user.signInUserSession.idToken.payload.jti,
              // metaVal : 'clm_Version' +'='+ val.claimRegistrationId  
              metaVal :'reco_Version' +'='+ val.claimRegistrationId
          }

          sellob.lob = 'Health'
          // let apiis = this.cognito_login()+'session/redirect?lob='+sellob.lob+'&jti='+sellob.usr_jti
          let apiis = this.cognito_login()+'session/redirect?lob='+sellob.lob+'&jti='+sellob.usr_jti+'&metadata='+sellob.metaVal
          
          console.log('apiis------>>>', apiis)
          window.location.href = apiis

          // this.onRecovery(val);
          return

        }catch(errct){
          console.log("error from catch re-direction",errct)
        }
    },
        
    async getCFRData(pageNum) {
      // this.GetData = [];
      console.log("PageNumber",pageNum)

      if (this.CFRbtnStatus=='caseUnderCFR') {
        this.valStaus=true
      } else {
        this.valStaus=false
        
      }
      var apiURL = '';
      var CFRtypeChange = this.CFRbtnStatus;


    //   if(this.responsesRecieved == true && this.responsesNotRecieved == false){
    //       apiURL = `query-management/search-query?perPage=10&pageNo=`+pageNum+`&type=${CFRtypeChange}&responsereceived=true`;
    //   }else if(this.responsesNotRecieved == true && this.responsesRecieved == false ){
    //       apiURL = `query-management/search-query?perPage=10&pageNo=`+pageNum+`&type=${CFRtypeChange}&responsenotreceived=false`;
    //   }else{
          apiURL = `cfr/get-cfr-details?perPage=10&pageNo=`+pageNum+`&type=${CFRtypeChange}`
    //   }

var self = this;
      
      await self.GET(20, apiURL, (response, error) => {
        if (!error) {
          let apiData = response.data.data.data;
          console.log('<<< === api dataaa ----===>',apiData);
          

        
          
          // self.GetData = [];

          if (self.CFRbtnStatus=='caseUnderCFR') {
            self.GetData = apiData;

            console.log('<<< ===caseUnderCFR self.GetData dataaa ----===>',self.GetData);

          
          } else {
            self.GetDataComplet = apiData
            self.GetDataComplet.forEach((data, index) => {
            // data.srNo = index + 1;
            data.srNo = 10*(pageNum)-9+index

           });
            
          }
        this.page=pageNum
          // for pagination

          self.pagelength = response.data.data.total_pages;

          self.totalLen = response.data.data.total;

          if (this.CFRStatus=='caseUnderCFR') {
            let l = self.GetData.total;
          } else {
            let l = self.GetDataComplet.total;
            
          }


          var less_enough = self.totalLen;
          var checkless_init = false;
          if (less_enough < 10) {
            checkless_init = false;
          } else {
            checkless_init = true;
          }

          // if less than 15 we have second value same as total value as no pagination will occur
          if (checkless_init) {
            // checkinit is true means the final count is more than 15 (- 1)
            var traverse = 10 * self.page;
            self.fval = traverse - 9;
            if (self.swap_final_count) {
              self.sval = self.totalLen;
            } else {
              self.sval = traverse;
            }
          } else if (self.totalLen == 0) {
            self.sval = self.fval = 0;
          } else {
            self.fval = 1;
            self.sval = self.totalLen;
          }
        } else {
            
          if (error.response.data.message.statusCode == 400) {
            // self.GetData = []
              self.fval = 0,
              self.sval= 0,
              self.totalLen= ""
              self.pagelength=0
            
          }
          self.showToast(error.response.data.message.msg,self.TOST().ERROR);

        }
      });
    },

    nextPageBtn(indata) {
      console.log("page no value", this.page);
      if (this.pagelength == indata) {
        this.swap_final_count = true;
      } else {
        this.swap_final_count = false;
      }
      this.getCFRData(this.page);
    },
    nextPage(page) {
      this.pageNumber = page;
    },

     getCountForLOB(){
      this.lobListAndCount  = []
      let reqData = "cfrStatus=false"
      this.GETM("cfrCount",reqData,(res)=>{
        console.log(res)
        this.lobListAndCount  = res.data.data
      },(err)=>{
        console.log(err)
      },true)
    },
    changeLobData(){
      console.log("selected_lob",this.selected_lob,this.lobListAndCount[this.selected_lob])
      let selectedLob = this.lobListAndCount[this.selected_lob];
      
      if(selectedLob.lob  ==  "Health"){
        this.showHideHealth = true;
        this.showHideOtherThanHealth  = false;
      }else{
        if(selectedLob && selectedLob.total == 0){
          this.showHideOtherThanHealth  = true;
          this.showHideHealth = false;
          this.searchData([], this.page)
          return
        }
        this.page = 1
        
        let lobOption = selectedLob.lob == "Travel" ? "BTA,LTA" : selectedLob.lob == "PA" ? "PA Group,PA Retail" : selectedLob.lob 
        let getData="cfrStatus=false&perPage=10&pageNo=1&lob="+lobOption
        this.GETM("cfrSearch",getData,(res)=>{
            this.cfr_Table=res.data.data.data
            this.count=res.data.data.total
            this.caseUnderCFR=true
            this.cfrCompletedCase = false
            this.searchData(res.data.data, this.page)        
        },(error) => {
            return error;
        },true)

        this.showHideOtherThanHealth  = true;
        this.showHideHealth = false;
        // this.$router.push("/commonofrsearch")
      
      }
      

    },
    //Other than Health LOB
    clear(){
        this.dateReportedTo="",
        this.dateReportedFrom="",
        this.claimNumber="",
        this.featureNumber="",
        this.claimantName="",
        this.insuredName="",
        this.examinerName="",
        this.month=""
    const a=new Date().toISOString().split('T')[0]
   this.pickerDate=a
   this.pickerDate1=a
    },
    // searchData(data){
    //     this.pageStartIndex=1
    //     this.pageEndIndex=data.total
    //     this.totalListCount=data.total
    //     this.pageCount=data.total_pages
    // },
    searchData(data, pageNumber){
      if(!data || (Array.isArray(data) && !data.length)){
        this.pageStartIndex=0
        // this.pageEndIndex=0
        this.totalListCount=0
        this.pageCount=0
        this.totalListCount=0
      }else{
        this.pageStartIndex =  pageNumber == 1 ? 1 : ((pageNumber - 1) * this.itemsPerPage) + 1;// this.pageStartIndex=1
        // this.pageEndIndex=data.total
        this.totalListCount=data.total? data.total : 0
        this.pageCount=data.total_pages ? data.total_pages : 0
        if(!this.totalListCount){
          this.pageStartIndex = 0
        }
      }
        
    },
    noOfDays(date) {
        
        const date1 = new Date(date).setHours(0,0,0,0);
        const date2 = new Date().setHours(0,0,0,0);
        
        const diffDays = parseInt((date2 - date1) / (1000 * 60 * 60 * 24), 10);
        return diffDays + 1;
    },
    turnAroundTime(createdAt, closedOn) {
      let date1 = closedOn ? new moment(closedOn) : new moment();
      let date2 = moment(createdAt);
      let diff = date1.diff(date2, "days");
      return Number(diff) + " Day(s)";
    },
    formatDate(date) {
      if (!date) return "";

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    dateConvert(a){
        return moment(a).format("DD-MM-YYYY")
    },
    checkCfrStatus(item){
             if(item.cfrStatus === true){
                    return "closeClr"
                }else{
                    return "openClr"
                }     
            },
     viewOpenFilePage(item){
                this.$router.push({
        name: "CommonCFRView",
        params: {
          isView: item.cfrStatus,
          item: item,
        },
      });

            },

        exportCfr(){
            this.dateReportedFrom=this.dateReportedFrom.length>0?moment(this.dateReportedFrom):""
            this.dateReportedTo=this.dateReportedTo.length>0?moment(this.dateReportedTo):""
            let selectedLob = this.lobListAndCount[this.selected_lob];
            let lobOption = selectedLob.lob == "Travel" ? "BTA,LTA" : selectedLob.lob == "PA" ? "PA Group,PA Retail" : selectedLob.lob 
            let reqData = `cfrStatus=${!this.caseUnderCFR}&claimNumber=${this.claimNumber}&featureNumber=${this.featureNumber}&policyNumber&productName&coverage&examiner=${this.examinerName}&memberName&insuredName=${this.insuredName}&claimantName=${this.claimantName}&lob=${lobOption}&dateReportedFrom=${this.dateReportedFrom}&dateReportedTo=${this.dateReportedTo}&month=${this.month}`
            // let reqData=`perPage=10&pageNo=`+pageNumber+"&lob="+lobOption
            // reqData+=`&ofrStatus=${!this.caseUnderCFR}&claimNumber=${this.claimNumber}&featureNumber=${this.featureNumber}&insuredName=${this.insuredName}&claimantName=${this.claimantName}&examiner=${this.examinerName}&dateReportedFrom=${this.dateReportedFrom}&dateReportedTo=${this.dateReportedTo}&month=${this.month}`
            this.GETM("cfrExport",reqData,(res)=>{
              console.log(res)
              this.viewDocument(res.data.data[0]._id)
                },(error) => {
                return error;
            },true)
            // this.dialog_search=false
            // this.clear()
        },
            
        searchCfr(pageNumber = 1){
          this.dateReportedFrom=this.dateReportedFrom.length>0?moment(this.dateReportedFrom):""
          this.dateReportedTo=this.dateReportedTo.length>0?moment(this.dateReportedTo):""
          let reqData=`cfrStatus=${!this.caseUnderCFR}&claimNumber=${this.claimNumber}&featureNumber=${this.featureNumber}&insuredName=${this.insuredName}&claimantName=${this.claimantName}&examiner=${this.examinerName}&dateReportedFrom=${this.dateReportedFrom}&dateReportedTo=${this.dateReportedTo}&month=${this.month}`
          let selectedLob = this.lobListAndCount[this.selected_lob];
          let lobOption = selectedLob.lob == "Travel" ? "BTA,LTA" : selectedLob.lob == "PA" ? "PA Group,PA Retail" : selectedLob.lob 
          reqData = reqData + "&perPage=10&pageNo=" + pageNumber +"&lob="+lobOption
          this.GETM("cfrSearch",reqData,(res)=>{
          this.cfr_Table=res.data.data.data
          this.count=res.data.data.total
          this.searchData(res.data.data, pageNumber)
          this.dialog_search=false
              },(error) => {
              return error;
          },true)
      },
      searchChipButton() {
          this.clear();
          this.searchCfr();
          this.showSearchChip = false;
      },
      callingauxiliarapi(){
        if(this.caseUnderCFR || this.cfrCompletedCase){
          let getData=`cfrStatus=${!this.caseUnderCFR}`
          let selectedLob = this.lobListAndCount[this.selected_lob];
          let lobOption = selectedLob.lob == "Travel" ? "BTA,LTA" : selectedLob.lob == "PA" ? "PA Group,PA Retail" : selectedLob.lob 
          getData = getData + "&perPage=10&pageNo=1&lob="+lobOption
          this.GETM("cfrSearch",getData,(res)=>{
          this.cfr_Table=res.data.data.data
          this.count=res.data.data.total
          this.searchData(res.data.data, this.page)
              },(error) => {
              return error;
          },true)
        
        }
      },
  },

  watch: {},
};
</script>
<style scoped>
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 0.84rem !important;
}

.minWidth{
min-width: 110px !important;
}

.activeRadioclass {
    background-color: #23b1a9 !important;
    color: #fff !important;
}

.text-white-insrns {
    color: #fff;
    font-size: 12px;
}

.text-dark-insrns{
  font-size: 12px;

}

.hdr-txt-clr-txt {
  color: #10242b !important;
  /* padding:0px!important;
    padding: 10px !important;
    padding-left: 14px !important; */
}

.hdr-bg-clr {
  background-color: #f7f7f7;
}
.cust_pr .v-text-field .v-input__slot,
.v-text-field--outline .v-input__slot {
  min-height: 38px !important;
  display: flex !important;
  align-items: center !important;
  /* border:1px red solid; */
}

.pending-work {
  justify-items: start;
}

.lay {
  direction: rtl;
  max-height: 50px;
}
.body-2 {
  font-size: 18px !important;
}
.body-3 {
  font-size: 16px !important;
}

.cstm-clr-pr {
  color: #10242b;
  margin-bottom: 5px;
}
.cust_pr .v-input {
  font-size: 13px !important;
}
.cust_pr .v-input input {
  font-size: 13px !important;
}

.cust_pr .v-input .v-label {
  font-size: 13px !important;
}

.cust-bnkAcc-pgn .v-pagination__navigation {
  box-shadow: unset !important;
  border-radius: unset !important;
  background-color: #ffffff !important;
  border: 1px solid #dae1e6 !important;
}
.cust-bnkAcc-pgn .v-pagination__navigation--disabled {
  background-color: #dae1e6 !important;
}
.cust-bnkAcc-pgn .v-pagination__item {
  box-shadow: unset !important;
  min-width: 2rem !important;
  height: 2rem !important;
  border-radius: unset !important;
  border: 1px solid #dae1e6 !important;
}
.bg-investigation-table-header-color {
  background-color: #23b1a9;
  color: #fff !important;
}
.activeInvestigationClass {
  background-color: #01bbd9 !important;
}
.hide-badge.badge-custom .v-badge__wrapper {
  display: none;
}
.badge-custom span.v-badge__wrapper span.v-badge__badge {
  height: 30px;
  font-size: 14px !important;
  width: 30px;
  border-radius: 50%;
  line-height: 1.6 !important;
}

.mastersButtonWidth .text {
  text-align: left;
  min-width: 290px;
}
.mastersButtonWidth .icon-wrap {
  height: 40px;
  width: 40px;
  display: flex;
  padding: 8px 5px;
  background: #10242b;
  border-radius: 50%;
  margin-top: 0.125em;
}
.text-xsm {
  font: normal normal bold 13px/22px Roboto !important;
}
.mastersButtonWidth i {
  margin: auto;
  color: #fff !important;
  font: normal normal bold 30px/22px Roboto;
}
.mastersButtonWidth.selected_button .icon-wrap,
.mastersButtonWidth:hover .icon-wrap {
  /* background: transparent !important; */
  background: #01bbd9 !important;
  color: #fff !important;
}
.mastersButtonWidth.selected_button,
.mastersButtonWidth:hover {
  background: #01bbd9 !important;
  color: #fff !important;
}
.v-btn--active {
  background: #01bbd9 !important;
}
.mastersButtonWidth {
  display: flex;
  background: #ffffff !important;
  align-content: stretch;
  justify-content: space-between;
  letter-spacing: 0;
  font: normal normal bold 14px/22px Roboto;
  height: 50px !important;
  width: 180px !important;
  margin: 4px;
}
.mastersButtonWidth.selected_button .mastersDiv,
.mastersButtonWidth:hover .mastersDiv {
  border-color: #ffffff !important;
}
.activebg {
  color: white ;
  background-color: #01bbd9 !important;
  
}

.inactivebg {
  color: black !important;
  background-color: white !important;
  
}
.active {
  background-color: #1ebbd9 !important;
  color: #fff !important;
}
.createbtn {
        text-transform: capitalize;
        letter-spacing: 0.4px;
        /* font-weight: 300;
        font-size: 13px !important; */
    }
.tableicons {
        font-size: 19px !important;
        font-weight:bold;
        color:rgb(85, 78, 78);
    }
.form-groups{
    max-width:220px;
    margin:0 0 0 10px
}
.openClr{
        color: #E46A25;
        background: none !important;
        font-weight: bold;
    }
    .closeClr{
        color: #0AB417;
        font-weight: bold;
        background: none !important;
    }
.reset {
        background-color: white;
        border:1px solid #D1121B;
        color:#D1121B;
    }
.cancelbtn {
        border: 1px solid #6e7f7f;
        color: #6e7f7f !important;
    }
</style>
