import axios from "axios";
import * as URL from "../common/API_Config.js";

export const getDocumentsByWoritemId = async function (workitemId) {
  const res = await axios.get(
    URL.commonModuleBaseUrl +
      `common-masterdata/siu/get-document-checklist-basedworkitemid/${workitemId}`,
    {
      headers: {
        "Content-Type": "application/json",
        system: "Health",
      },
    }
  );
  return res.data;
};

export const getDocumentChecklist = async function () {
    const res = await axios.get(
      URL.commonModuleBaseUrl +
        `common-masterdata/siu/get-document-checklist-documentName`,
      {
        headers: {
          "Content-Type": "application/json",
          system: "Health",
        },
      }
    );
    return res.data;
  };
  
  export const createDocument = async function (payload) {
    const res = await axios.post(
      URL.commonModuleBaseUrl +
        `common-masterdata/siu/create-document-checklist`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          system: "Health",
        },
      }
    );
    return res;
  };

  export const updateDocument = async function (payload, id) {
    const res = await axios.put(
      URL.commonModuleBaseUrl +
        `common-masterdata/siu/update-document-checklist/`+id,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          system: "Health",
        },
      }
    );
    return res;
  };
