<template>
   <div>
   
   <v-form ref="issearchValid">
              
              <v-card flat class="pl-3 pt-3 pb-4">
                  <v-layout class="mt-3"  wrap style="align-items:flex-end">
                    <v-flex xs3>
                        <v-layout column class="cust_pln_cd mx-2 ">
                            <p class="font-weight-bold cstm-clr-pln-cd">
                                User ID
                            </p>
                            <v-text-field    
                            v-model="userId"
                            label="Enter..."             
                            solo
                            class="rounded-0 mb-1"
                            required
                            autocomplete="off"
                            :rules="[Numeric]"
                            :disabled="!disableAll"
                            v-on:keyup.enter="searchResults"
                        ></v-text-field>
                        </v-layout>
                    </v-flex>

                    <v-flex xs3 class="mb-1">
                        <v-layout column class="cust_pln_cd mx-2 ">
                            <p class="font-weight-bold cstm-clr-pln-cd">
                                 Employee ID
                            </p>
                            <v-text-field    
                            v-model="employeeId"
                            label="Enter..."             
                            solo
                            class="rounded-0"
                            required
                            autocomplete="off"
                            :rules="[Numeric]"
                            :disabled="!disableAll"
                            v-on:keyup.enter="searchResults"
                        ></v-text-field>
                        </v-layout>
                    </v-flex>

                    <v-flex xs3 class="mb-1">
                        <v-layout column class="cust_pln_cd mx-2 ">
                            <p class="font-weight-bold cstm-clr-pln-cd">
                                Employee Name
                            </p>
                            <v-text-field    
                            v-model="employeeName"
                            label="Enter..."             
                            solo
                            class="rounded-0"
                            required
                            autocomplete="off"
                            :rules="[NumericVal]"
                            :disabled="!disableAll"
                            v-on:keyup.enter="searchResults"
                        ></v-text-field>
                        </v-layout>
                    </v-flex>

                    <!-- <v-btn tile dark color="#E9854D" class="text-capitalize px-3 ml-2 mb-3" small>
                         <v-icon color="" small >search</v-icon>
                         <v-divider vertical class="ml-2 mr-2"></v-divider>Search
                     </v-btn> -->
                  </v-layout>
            </v-card>

        <v-card color="#F7F7F7" class="mb-6 pa-3 overflow-y-auto">
            <v-row class="pt-1 pb-1 mt-1 mr-2 float-right">
                <v-btn :dark='disableAll' :disabled="!disableAll" @click="searchResults()" tile  color="#E9854D" class="text-capitalize px-3 mr-3" small>
                         <v-icon color="" small >search</v-icon>
                         <v-divider vertical class="ml-2 mr-2"></v-divider>Search
                     </v-btn>
                <v-btn @click="$router.go(-1)" tile dark color="#E9854D" class="text-capitalize px-3 ml-2" small>
                         <v-icon color="" small >cancel</v-icon>
                         <v-divider vertical class="ml-2 mr-2"></v-divider>Cancel
                </v-btn>
            </v-row>
        </v-card>
   </v-form>    

   <v-form>
        <div class="px-4 py-3" style="background-color:#F7F7F7">
            <p class="font-weight-bold cstm-clr-pln-Ot pa-0 ma-0">Search Results</p>
        </div>
        <!-- <v-divider></v-divider> -->
     <v-card tile>
            <v-data-table              
                    :headers="headers"
                    :items="statusData"
                    :page.sync="page"
                    :items-per-page="statusItemsPerPage"
                    hide-default-footer
                    dense
                    @page-count="pageCount = $event"
                    
            >       
            <!-- <template v-slot:item.faLevel="{item}" >
                    <v-select
                        flat
                        label=""
                        v-model="item.faLevel"
                        solo 
                        attach
                        :items="levels"
                        class="rounded-0"
                    ></v-select>
            </template> -->
            <template v-slot:item.approve="{item}" >
                    <v-select
                        label=""
                        v-model="item.approve"
                        solo 
                        placeholder="Select"
                        :items="levelApprove"
                        :disabled="item.isDisable"
                        @change="openPopUp(item)"
                        class="rounded-0"
                        style="font-size: 15px;position: relative;top: 12px"
                    ></v-select>
            </template>

            </v-data-table>
     </v-card>

     <v-card v-if="paginCond == true" class="pb-3 mb-6" style="background-color:#F7F7F7">
         <!-- <div class="pagin">
            <v-pagination
              width="100%"
              v-model="page"
              :length="4"
              prev-icon="mdi-arrow-left"
              next-icon="mdi-arrow-right"
              color="black"
              square
            ></v-pagination>
         </div> -->
         <v-layout row justify-end align-center style="background-color:#f7f7f7" class="pt-2 mr-2">
                <span class="mr-1 font-weight-bold body-1" style="color:#3C3D3D; font-size:12px !important">
                <span class="" style="color:#E46A25;font-size:12px;">Showing {{fval}}-{{sval}} </span>out of {{totalLen}} records</span>
                <v-divider  class="ma-2" vertical ></v-divider>
                <v-pagination 
                    class="cust-bnkAcc-Permission"
                    v-model="page"
                    color="#152F38"
                    flat
                    :total-visible="9"
                    @input="nextPageBtn(page)"
                    :length="pageLength"
                    ></v-pagination>
            </v-layout>
     </v-card>

        <v-card color="#F7F7F7" class="mb-6 pa-3 overflow-y-auto">
            <v-row class="pt-1 pb-1 mt-1 mr-2 float-right">
                <v-btn @click="() => {dialogNextBtn = true}" tile dark color="#152F38" class="text-capitalize px-3 mr-3" small>
                         <v-icon color="" small >mdi-arrow-left</v-icon>
                         <v-divider vertical class="ml-2 mr-2"></v-divider>Return
                </v-btn>
                <v-btn @click="saveDetails(remarksData)" tile :dark='disableAll' :disabled="!disableAll" color="#23B1A9" class="text-capitalize px-3 ml-2" small>
                         <v-icon color="" small >check_circle</v-icon>
                         <v-divider vertical class="ml-2 mr-2"></v-divider>Submit
                </v-btn>
            </v-row>
        </v-card>

 </v-form>

 <!-- <v-btn @click="openDialog">Open Dialog</v-btn> -->

            <v-dialog v-model="dialogFunc" persistent max-width="420px" max-height="400px" class="" >
                        <v-card color="#F7F7F7" class="pb-2" tile>
                            <v-card>
                                <v-layout justify-space-between  class="" style="flex:1;background-ox-shadow: 0px 3px 5px #00000029;">
                                    
                                        <v-card-title class="pa-1 pl-3 text-capitalize font-weight-bold" style="color:#10242B;font-size:13px">Rejection Remarks</v-card-title>
                                       
                                    <div style="border-left:1px solid #C1C8CC;" @click="closeIconFunc">
                                        <v-icon style="cursor: pointer;" class="pa-2" >close</v-icon>
                                    </div>
                                </v-layout>
                            </v-card> 
                        <v-card class="ma-4 pa-4" tile>
                                    
                        <v-layout color="#FFFFFF" class="mx-2 "  style="" justify-center>
                            <v-text-field
                            v-model="remarksField"
                            label="Enter..."             
                            solo
                            class="rounded-0 mb-1"
                            required
                            autocomplete="off"
                            :rules="[Onlystring]"
                            ></v-text-field>
                        
                    </v-layout>
                </v-card>
                <v-btn @click="clearRemark" style="position:relative;left:200px" tile dark color="#152F38" class="text-capitalize px-3 ml-2" small>
                         <v-icon color="" small >cancel</v-icon>
                         <v-divider vertical class="ml-2 mr-2"></v-divider>Clear
                </v-btn>
                <v-btn @click="saveRemarks" style="position:relative;left:208px" tile dark color="#23B1A9" class="text-capitalize px-3 ml-2" small>
                         <v-icon color="" small >check_circle</v-icon>
                         <v-divider vertical class="ml-2 mr-2"></v-divider>Save
                </v-btn>
            </v-card>
            </v-dialog>

            <!-------NEXT Button popup--->
             <v-dialog v-model="dialogNextBtn" persistent max-width="420px" max-height="400px" class="" >
                 <v-form ref="isRemarkValid" v-model="remarkForm">
                        <v-card color="#F7F7F7" class="pb-2" tile>
                            <v-card>
                                <v-layout justify-space-between  class="" style="flex:1;background-ox-shadow: 0px 3px 5px #00000029;">
                                    
                                        <v-card-title class="pa-0 pl-3 text-capitalize" style="color:#10242B;font-size:23px">CONFIRMATION</v-card-title>
                                       
                                    <div style="border-left:1px solid #C1C8CC;" @click="() => {dialogNextBtn = false}">
                                        <v-icon style="cursor: pointer;" class="pa-2" >close</v-icon>
                                    </div>
                                </v-layout>
                            </v-card> 
                        <div class="ml-4 pt-2">
                            <p>Details entered on the page will be lost if not saved</p>
                        </div>

                        <v-row class="mt-1 ml-4 pb-2">
                               <v-btn @click="() => {dialogNextBtn = false}" tile dark color="#152F38" class="text-capitalize mr-6" small>
                                   <v-icon color="" small >cancel</v-icon>
                                   <v-divider vertical class="ml-2 mr-2"></v-divider>Cancel
                               </v-btn>
                               <v-btn @click="$router.go(-1)" tile dark color="#23B1A9" class="text-capitalize mr-6" small>
                                   <v-icon color="" small >check_circle</v-icon>
                                   <v-divider vertical class="ml-2 mr-2"></v-divider>Ok
                               </v-btn>
                        </v-row>
            </v-card>
                 </v-form>
            </v-dialog>

 <!-- <FAAapproval/> -->
 
</div>
</template>

<script>
// import {alpha, helpers} from 'vuelidate/lib/validators';
// const nameSpaces = helpers.regex('nameSpaces', /^[a-zA-Z1-0' ]+$/);
import FAAapproval from './FAMappingMaster/FinancialAuthorityApprovalTbl';

export default {
   components: {FAAapproval},
   data(){
    return{
        remarkForm: false,
        isDisable: false,
        dialogNextBtn: false,
        paginCond: true,
        item_for_remarks: null,
        userId: '',
        pageLength: 0,
        employeeId: '',
        employeeName: '',
        remarksData: [],
        dataItems: [],
        remarksField: '',
        dialogFunc: false,
        page: 1,
        fval: 1,
        sval: 10,
        headers:[
            {text: 'Sr. No.',value:'Srno',sortable: false,divider:true,class:"hdr-txt-clr-Ot backColorOt"}, 
            {text: 'Employee Name',value:'employeeName',sortable: false,divider:true,class:"hdr-txt-clr-Ot backColorOt"},
            {text: 'User ID/NT ID', value: 'userId',sortable: false,divider:true,class:"hdr-txt-clr-Ot backColorOt"},
            {text: 'Employee ID', value: 'employeeId',sortable: false,divider:true,class:"hdr-txt-clr-Ot backColorOt"},
            {text: 'Line of Business', value: 'listBusiness',sortable: false,divider:true,class:"hdr-txt-clr-Ot backColorOt"},
            {text: 'Role',value: 'roleis',sortable: false,divider:true,class:"hdr-txt-clr-Ot backColorOt"},  
            {text: 'FA Level',value: 'faLevel',sortable: false,divider:true,class:"hdr-txt-clr-Ot backColorOt"},
            {text: 'Approve',value: 'approve',sortable: false,divider:true,width:"150px",class:"hdr-txt-clr-Ot backColorOt"},   
        ],
        statusData: [],
        levelApprove: ["Yes", "No"],
        fval: 0,
        sval: 0,
        totalLen: '',
        statusItemsPerPage: 10,
        page: 1,
        lastPage:false,
        approveData: '',
        prevAppoveStatus: '',
        notRemark: false,
        disableAll:true,
        errorcheck:false,
    }
   },

   created(){
       this.$store.commit("SAVE_PAGE_HEADER", "User Approval");

    //    if(this.getEditDetails(this.$router.history.current.name)){
    //         this.disableAll=this.getEditDetails(this.$router.history.current.name).isEdit
            
    //     }else{
    //         this.disableAll=false
    //     }
        // this.dataOnCreation();
        this.searchTable(this.page);

        // console.log("this.getEditDetails(this.$router.history.current.name) from Mixin===>",this.getEditDetails(this.$router.history.current.name).isEdit)
        

        

        console.log(this.statusData,"this.statusData")

   },

   methods: {
       closeIconFunc(){
             console.log(this.prevAppoveStatus);
           this.dialogFunc = false;
           var poss = this.statusData.findIndex(e => e._id == this.item_for_remarks._id);
            if(poss != -1){
                 this.statusData[poss].approve = this.prevAppoveStatus;
                 this.statusData[poss].rejectRemarks = '';
                 this.item_for_remarks = null;
                 this.prevAppoveStatus = '';
            }else{
                 console.log(this.statusData, "-1 element not found in array", this.item_for_remarks)
            }

          

          
       },

       dataOnCreation(){
           this.GET(6,'users/search-users', (res, err) => {
           if(!err){
               console.log(res.data.data.data);
               this.dataItems = res.data.data.data;
               var rejectRemarks = this.remarksField;
               this.statusData = this.dataItems.map((item,index) => {
                   console.log(item.status);
                   console.log(item.assignRole);
                   let assignRoleData = '';
                   if(item.assignRole == undefined){
                        assignRoleData = ''
                   }else{
                       assignRoleData = item.assignRole[0].roleName
                   }
                //    approveData = item.status;
                   return {
                       prevAppoveStatus: item.status == 'Approved' || item.status == 'Yes' ? 'Yes': item.status == 'Pending' ? '': item.status == 'Rejected' || item.status == 'Reject' ? 'No': '',
                       isDisable: item.approve == 'Yes' || item.approve == 'Approve' || item.status == 'Approved' ? true: false,
                       _id: item._id,
                       vtext: false,
                       Srno: index + 1,
                       employeeName: item.employeeName,
                       userId: item.ntidUserId,
                       employeeId: item.employeeId,
                       listBusiness: item.selectedLOB,
                       role: assignRoleData,
                       faLevel: item.faLevel,
                       approve: item.status == 'Approved' || item.status == 'Yes' ? 'Yes': item.status == 'Pending' ? '': item.status == 'Rejected' || item.status == 'Reject' ? 'No': '',
                       rejectRemarks: rejectRemarks
                   }
               })
           }else{
               console.log(err);
           }
        })  
       },

       saveDetails(dta){
          console.log(dta);
          console.log(this.statusData);
          var tempArr = [];



          for(var i=0; i<this.statusData.length; i++){
              if(this.statusData[i].vtext == true && this.statusData[i].approve != '' && this.statusData[i].approve.length != 0){
                  
                  tempArr.push({
                      id: this.statusData[i]._id,
                      approve: this.statusData[i].approve,
                      remark: this.statusData[i].rejectRemarks
                  });  
              }
          }
          console.log("Form data put api",tempArr);
       
        
        if(tempArr.length > 0){
            this.PUT(6, 'users/update-user-approval', tempArr, (res, err) => {
                if(!err){
                    console.log(res.data.data);
                    this.showToast(res.data.msg, this.TOST().SUCCESS);
                    this.showAlert(res.data.msg)
                    for(var i=0; i<this.statusData.length; i++){
                        this.statusData[i].vtext = false;
                    }
                    this.searchTable(this.page);
                }else{
                    console.log(err);
                    //  this.showToast(res.data.msg, this.TOST().WARNING);
                }
            })
        }else{
                this.showToast("Please Approve a user to Update", this.TOST().WARNING);
                this.showAlert("Please Approve a user to Update")
        }
        // if(this.statusData[i].vtext = false){
        //     this.showToast("NO USERS", this.TOST().WARNING);
        // }
       },
       
       openDialog(){
            this.dialogFunc = true;
       },

    //    $router.go(-1)(){
    //        $router.go(-1);
    //    },
       clearRemark(){
            this.remarksField = '';
       },

       saveRemarks(){

           console.log("errorcheck",this.errorcheck)

           if(this.errorcheck){
                this.showToast("Please Fill Required Fields",this.TOST().WARNING);
                return
           }
            // issearchValid

            if(this.remarksField.length == 0){
               this.showToast("Please fill required field.",this.TOST().WARNING) 
               this.showAlert("Please fill required field.")
               return 
            }

            console.log(this.remarksField);
            var poss = this.statusData.findIndex(e => e._id == this.item_for_remarks._id);
            if(poss != -1){
                 this.statusData[poss].rejectRemarks = this.remarksField;
                 this.item_for_remarks = null;
            }else{
                 console.log(this.statusData, "-1 element not found in array", this.item_for_remarks)
            }
            this.dialogFunc = false;
            // for(var i=0; i<this.statusData.length; i++){
            //     if(this.statusData[i].vtext == true){
            //         if(this.statusData[i].approve == 'No'){
            //             this.statusData[i].rejectRemarks = this.remarksField;
            //         }
            //     }
            // } 
            console.log(this.statusData);  
       },

    //    closeDialog(){
    //        this.dialogFunc = false;  
    //    },

       openPopUp(data){
            console.log('incomin on open popup of NO || Yes',data);
            // if(this.remarksData.length > 0){
            //     this.remarksField = '';
            // }
            for(var i=0; i<this.statusData.length; i++){
                if(this.statusData[i]._id === data._id){
                    this.statusData[i].vtext = true;
                }
            }
            if(data.approve == "No"){
                // opening remarks popup as no selected from tables drop-down
                this.dialogFunc = true;
                this.remarksField = '';
                this.item_for_remarks = data;
                this.prevAppoveStatus = data.prevAppoveStatus;
            }

            console.log("prevAppoveStatus ", this.prevAppoveStatus)

       },

        Numeric(v){
            if (v.length !== 0){
                console.log("testing",/^[0-9 ]+$/.test(v))
                if(!/^[0-9A-Za-z ]+$/.test(v)){
                    return "Only Alphanumeric."
                }else{
                console.log("else none")  
                }
            }  
       },

       NumericVal(v){
            if (v.length !== 0){
                console.log("testing",/^[0-9 ]+$/.test(v))
                if(!/^[A-Za-z ]+$/.test(v)){
                    return "Only Alphabets are allowed."
                }
            }
       },
       Onlystring(v){
            if (v.length !== 0) {
                console.log("testing",/^[0-9 ]+$/.test(v))
                if(!/^[a-zA-z]+([\s][a-zA-Z]+)*$/.test(v)){
                    this.errorcheck = true
                    return "Only Alphabets are allowed."

                }else{
                    console.log("else none")
                    this.errorcheck = false
                }
            }
        },

       Numeric2(v){
            if (v.length !== 0){
                // console.log("testing",/^[0-9 ]+$/.test(v))
                // if(!/^[A-Za-z ]+$/.test(v)){
                //     return "Only Alphabets are allowed."
                // }
                console.log("correct");
            }else{
                    return 'This Field is required'; 
                }
       },
       
       searchResults(){
           this.page = 1;
            if(!this.$refs.issearchValid.validate()){
                this.$refs.issearchValid.validate()
                 this.showToast("Data should be entered for all required fields.",this.TOST().WARNING);
                 this.showAlert("Data should be entered for all required fields.")
                return
            }  
            this.GET(6,'users/search-users?userId='+this.userId+'&employeeId='+this.employeeId+'&employeeName='+this.employeeName+'&status=Pending&perPage=10&pageNo=1', (res, err) => {
            if(!err){
               console.log(res.data.data.data);
               this.showToast(res.data.msg,this.TOST().SUCCESS); 
               this.showAlert(res.data.msg)
               this.pageLength = res.data.data.total_pages;
               this.totalLen = res.data.data.total
               var temp =  this.page*10;
               this.fval = temp-9;
               if(this.lastPage){
                    this.sval = this.totalLen
                    console.log("value of sval is fo",this.sval);
                }else{
                    if(!this.totalLen < 10){
                        this.sval = this.totalLen
                        console.log("this are the pageLength for searchTable",this.totalLen);
                     }else{

                        this.sval = temp;
                        console.log("value of sval is",this.sval);
                    }

                }
               
               this.dataItems = res.data.data.data;
               this.statusData = this.dataItems.map((item,index) => {
                //    console.log(item.assignRole[0]);
                   let newAssignRole = [];
                    if(item.hasOwnProperty('assignRole')){
                                            if(item.assignRole.length != 0){
                                                item.assignRole.map(e=>{
                                                      newAssignRole.push(e.roleName)
                                                })
                                            }
                    }
                //    if(item.assignRole !== undefined){
                //      if(item.assignRole.length == 0){
                //          newAssignRole = ''
                //      }
                //      }
                //      else if(item.assignRole == undefined){
                //          newAssignRole = ''
                //      }else{
                //          newAssignRole = item.assignRole[0].roleName
                //      }
                //    if(item.assignRole == undefined){
                //        newAssignRole = ''; 
                //    }else{
                //        newAssignRole = item.assignRole[0].roleName;
                //    }
                   return {
                       prevAppoveStatus: item.status == 'Approved' || item.status == 'Yes' ? 'Yes': item.status == 'Pending' ? '': item.status == 'Rejected' || item.status == 'Reject' ? 'No': '',
                       isDisable: item.status == 'Yes' || item.status == 'Approve' || item.status == 'Approved' ? true: false,
                        _id: item._id,
                       Srno: index + 1,
                       employeeName: item.employeeName,
                       userId: item.ntidUserId,
                       employeeId: item.employeeId,
                       listBusiness: item.selectedLOB,
                       role: newAssignRole,
                       faLevel: item.faLevel,
                       approve: item.status == 'Approved' || item.status == 'Approve' || item.status == 'Yes' ? 'Yes': item.status == 'Pending' ? '': item.status == 'Rejected' || item.status == 'No' ? 'No': '',
                        vtext:false,
                         roleis:newAssignRole.join() 
                   }
               })
            }else{
               console.log(err);
               this.showToast("Could not process your request",this.TOST().ERROR); 
               this.showAlert("Could not process your request")
               this.statusData = [];
               this.paginCond = false;
            }
            }) 
       },

        nextPageBtn(page){
            if(this.pageLength === page){
                this.lastPage = true
                console.log("this is last page",page);
            }else{
                this.lastPage = false
                console.log("this is not last page",page);
            }
            this.searchTable(page);
        },

        // onSearch(){
        //     console.log("search btn is working",this.page);
        //      if(!this.$refs.issearchValid.validate()){
        //        this.showToast("Please fill all fields",this.TOST().WARNING)
        //         console.log("%c It is a not a valid Form","color:red");
        //     }else{
        //         console.log("%c It is a valid Form","color:green");
        //         this.searchTable(this.page)
        //     }
               
        // },

        searchTable(page){
            this.GET(6,'users/search-users?userId='+this.userId+'&employeeId='+this.employeeId+'&employeeName='+this.employeeName+'&status=Pending&perPage=10&pageNo='+page,
                            (res,error) => {
                                if(!error){
                                    console.log("Res=>",res);
                                    this.pageLength = res.data.data.total_pages;
                                    this.totalLen = res.data.data.total
                                    var temp =  this.page*10;
                                    this.fval = temp-9;
                                    this.sval = temp;
                                    console.log("this is response for search===>",res.data.data);
                                    let tempArr = res.data.data.data;
                                    // this.GetData = [];
                                    var rejectRemarks = this.remarksField;
                                        this.statusData = tempArr.map((item,index) => {
                                            //  console.log(item.assignRole[0].roleName);
                                             
                                            let newAssignRole = [];
                                            // if(page){
                                        if(item.hasOwnProperty('assignRole')){
                                            if(item.assignRole.length != 0){
                                                item.assignRole.map(e=>{
                                                      newAssignRole.push(e.roleName)
                                                })
                                            }
                                        }
                                                // if(item.assignRole !== undefined){
                                                // if(item.assignRole.length == 0){
                                                //     newAssignRole = ''
                                                // }
                                                // }
                                                // else if(item.assignRole == undefined){
                                                //     newAssignRole = ''
                                                // }else{
                                                //     console.log("Roles.....")
                                                //     newAssignRole = item.assignRole[0].roleName
                                                // }
                                            // }
                                            // else{
                                            //     if(item.assignRole == undefined){
                                            //         newAssignRole = ''
                                            //     }else{
                                            //         newAssignRole = item.assignRole[0].roleName
                                            //     }
                                            // }
                                            return{
                                                  prevAppoveStatus: item.status == 'Approved' || item.status == 'Yes' ? 'Yes': item.status == 'Pending' ? '': item.status == 'Rejected' || item.status == 'Reject' ? 'No': '',
                                                  isDisable: item.status == 'Yes' || item.status == 'Approve' || item.status == 'Approved' || this.disableAll == false ? true: false,
                                                    Srno: 10*(page)-9+index,
                                                    _id: item._id,
                                                    vtext: false,
                                                    // Srno: index + 1,
                                                    employeeName: item.employeeName,
                                                    userId: item.ntidUserId,
                                                    employeeId: item.employeeId,
                                                    listBusiness: item.selectedLOB,
                                                    role: newAssignRole,
                                                    faLevel: item.faLevel,
                                                    approve: item.status == 'Approved' || item.status == 'Approve' || item.status == 'Yes' ? 'Yes': item.status == 'Pending' ? '': item.status == 'Rejected' || item.status == 'Reject' || item.status == 'No' ? 'No': '',
                                                    rejectRemarks: rejectRemarks,
                                                    roleis:newAssignRole.join()                     
                                                }
                                            });
                                    
                                    // this.showToast(res.data.data.msg,this.TOST().SUCCESS)
                                    
                                }else{
                                    this.showToast('Please enter data correctly',this.TOST().WARNING)
                                    this.showAlert('Please enter data correctly')
                                    // console.log("their was error in search==>",error.response.data);
                                }
                            })
            }
        

    },

//    validations: {
//        userId: {nameSpaces},
//        employeeId: {nameSpaces},
//        employeeName: {nameSpaces}
//     },

    computed: {

        // providerErrorsTariff(){
        //     const errors = [];
        
        //     if (!this.$v.tariffName.$dirty) return errors   
        //     !this.$v.userId.nameSpaces && errors.push("Only Alphanumeric");       
        //     !this.$v.employeeId.nameSpaces && errors.push("Only Alphanumeric");   
        //     !this.$v.employeeName.nameSpaces && errors.push("Only Alphabets are");   

        //     return errors;   
                
        // },
    }

}
</script>

<style>
.pagin{
    position: relative;
    left: 38%;
    top: 10px;
}

</style>