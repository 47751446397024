<template>
  <v-app class="iconPageHead">
    <!-- <v-flex xs12> -->
    <!-- <v-menu> -->
    <v-card class="cust_ui" tile>
      <v-card-title avatar class="px-2 pt-1 pb-1">
        <!-- <v-list-tile-avatar size="40" color="#23B1A9">
          <span class="white--text title">I</span>
        </v-list-tile-avatar> -->
        <v-main style="padding: 0">
          <span class="text-capitalize font-weight-bold" colo="#10242B"
            >{{ userDetails.first }} {{ userDetails.last }}</span
          >
          <!-- <v-list-tile-sub-title class="text-capitalize font-weight-medium caption" color="#10242B">Designation: <span class="font-weight-bold">{{ userDetails.designation }}</span></v-list-tile-sub-title>  -->
        </v-main>
      </v-card-title>
      <v-layout column class="mt-1 cust_ui" style="background-color: #152f38">
        <v-card-actions @click.stop class="mt-2 mb-1 pa-0 px-3"
          ><span style="color: #f7f7f7" class="caption font-weight-regular"
            >Role Name</span
          ></v-card-actions
        >
        <v-card-actions class="pa-0 px-3" @click.stop>
          <v-select
            solo
            label="Select"
            v-model="rolenme"
            :items="roleItems"
            flat
            hide-details
          ></v-select
        ></v-card-actions>

          <v-card-actions class="pa-0 px-3 pt-3">
          <v-btn
            color="#e46a25"
            style="border-radius: unset"
            depressed
            dark
            class="text-capitalize"
            block
            @click="$router.push('/markuseroffline')"
            ><v-icon dark left>mdi-cloud-off-outline</v-icon> User Offline</v-btn
        ></v-card-actions>
        <v-card-actions class="px-3">
          <v-btn
            color="#23B1A9"
            style="border-radius: unset"
            depressed
            dark
            class="text-capitalize"
            block
            @click="logout()"
            ><v-icon dark left>mdi-power</v-icon> Logout</v-btn
          >
        </v-card-actions>
        <!-- @click.native="logout()" -->
      </v-layout>
    </v-card>
    <!-- </v-menu> -->
    <!-- </v-flex> -->
  </v-app>
</template>

<script>
// import axios from 'axios' ;
// import moment from 'moment'
export default {
  data() {
    return {
      userDetails: {},
      rolenme: "",
      // roleItems: ["Data Entry Operator", "Manager"],
      roleItems: [],
    };
  },
  created() {
    this.userDetails = {};
    this.userDetails = this.$store.getters.fetchName;
    console.log("self.userDetails", this.userDetails);
    this.roleItems.push(this.$store.getters.fetchRole);
    if (this.roleItems.length == 1) {
      this.rolenme = this.roleItems[0];
    }
  },
  props: {},
  filters: {
    nameToInitials(fullName) {
      const namesArray = fullName.split(" ");
      if (namesArray.length === 1)
        return `${namesArray[0].charAt(0).toUpperCase()}`;
      else
        return `${namesArray[0].charAt(0).toUpperCase()}${namesArray[
          namesArray.length - 1
        ]
          .charAt(0)
          .toUpperCase()}`;
    },
  },

  methods: {
    logout() {
      this.$store.commit("LOGIN_SUCCESS", {});
      this.$router.push("/login");
    },
  },
};
</script>


<style scoped>
.cust_ui .v-text-field .v-input__slot,
.v-text-field--outline .v-input__slot {
  min-height: 42px !important;
  display: flex !important;
  align-items: center !important;
  /* border:1px red solid; */
  border-radius: unset !important;
}

.cust_ui .v-list__tile {
  padding: 0px !important;
}
.iconPageHead{
  width: 274px !important;
  height: 168px !important;
}
</style>
