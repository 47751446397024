<template>
  <div>
    <!--  form fields -->
    <v-form ref="Formvalid">
      <v-row class="mt-1">
        <v-col class="inputwidth ml-4">
          <v-flex class="mb-2 claimlabel"
            >Catastrophe ID<span class="mandatorycolor"> *</span></v-flex
          >
          <v-text-field
            placeholder="Enter..."
            :maxlength="max50"
            solo
            class="forminput"
            type="text"
            required
            v-model="CatastropheMasterObj.catastropheId"
            :rules="rulesValidation.CatastropheIDRules"
            dense
          ></v-text-field>
        </v-col>
        <v-col class="inputwidth">
          <v-flex class="mb-2 claimlabel"
            >Catastrophe Description<span class="mandatorycolor">
              *</span
            ></v-flex
          >
          <v-text-field
            placeholder="Enter..."
            maxlength="100"
            solo
            class="forminput"
            type="text"
            required
            v-model="CatastropheMasterObj.catastropheDescription"
            :rules="rulesValidation.DescriptionRules"
            dense
          ></v-text-field>
        </v-col>
        <v-col class="inputwidth">
            <!-- :rules="rulesValidation.CatastropheDeatilsRules" -->
          <v-flex class="mb-2 claimlabel">Catastrophe Detail </v-flex>
          <v-text-field
            placeholder="Enter..."
            maxlength="150"
            solo
            class="forminput"
            type="text"
            v-model="CatastropheMasterObj.catastropheDetail"
             :rules='rulesValidation.CatastropheDeatilsRules'
            dense
          ></v-text-field>

          <!-- <v-select

            :items="catastropheDetailOptions"
            placeholder="Select"
            solo
            dense
          ></v-select> -->
        </v-col>
        <v-col class="inputwidth">
          <v-flex class="mb-2 claimlabel"
            >Catastrophe Type<span class="mandatorycolor"> *</span></v-flex
          >
          <v-select
            v-model="CatastropheMasterObj.catastropheType"
            :items="catastropheTypeOptions"
            placeholder="Select"
            solo
            dense
            required
            :rules="rulesValidation.typeRules"
            clearable
            class="forminput"
          ></v-select>
        </v-col>
      </v-row>
      <v-row class="mt-2">
        <v-col class="ml-4 inputwidth unitest">
          <v-flex class="mb-2 claimlabel">
            Start Date<span class="mandatorycolor"> *</span></v-flex
          >
          <v-menu
            v-model="StartDateMenu"
            :close-on-content-click="false"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="CatastropheMasterObj.startDate"
                placeholder="dd/mm/yyyy"
                solo
                dense
                readonly
                class="CalenderStartDate forminput"
                required
             
                v-on:focus="onSFocus"
                v-bind="attrs"
                v-on="on"
              
                @keyup="
                  ChangeDateBindCalenderStart(CatastropheMasterObj.startDate)
                "
                :rules="rulesValidation.StartDateRules"
                append-icon="mdi-menu-down"
              >
                <template v-slot:prepend-inner class="mx-0 my-0">
                  <v-icon class="iconstyle">mdi-calendar</v-icon>
                </template>
              </v-text-field>
            </template>
            <v-date-picker
              :max="todayDate"
              no-title
              v-model="StartDateFormatted"
              @input="StartDateMenu = false"
              @click:date="
                CatastropheMasterObj.startDate = formatDateStartDate(
                  StartDateFormatted
                )
              "
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col class="inputwidth">
          <v-flex class="mb-2 claimlabel"
            >End Date<span class="mandatorycolor"> *</span></v-flex
          >
          <v-menu
            v-model="endDateMenu"
            :close-on-content-click="false"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :disabled="CatastropheMasterObj.startDate==''"
                type="text"
                v-model="CatastropheMasterObj.endDate"
                placeholder="dd/mm/yyyy"
                solo
                dense
                readonly
                v-bind="attrs"
                v-on="on"
                v-on:focus="onEFocus"
                class="CalenderStartDate forminput"
                required
                append-icon="mdi-menu-down"
                @keyup="ChangeDateBindCalenderEnd(CatastropheMasterObj.endDate)"
                :rules="rulesValidation.endDateRules"
              >
                <template v-slot:prepend-inner class="mx-0 my-0">
                  <v-icon class="iconstyle">mdi-calendar</v-icon>
                </template>
              </v-text-field>
            </template>
            <v-date-picker
              :min="StartDateFormatted"
              no-title
              v-model="endDateFormatted"
              @input="endDateMenu = false"
              @click:date="
                CatastropheMasterObj.endDate = formatDateEndDate(
                  endDateFormatted
                )
              "
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <!--  save add cancel clear buttons below-->
      <v-flex justify="end">
        <v-row class="btnDiv">
          <v-col align="right">
            <v-btn
             :disabled="this.CatastropheMasterObj._id!=''"
              color="#152F38"
              dark
              class="buttonscolor mr-5"
              type="submit"
              @click.prevent="addCataStopheDeatils"
              
            >
              <v-icon class="mr-2 iconFontSize"> mdi-plus </v-icon>
              <div class="vertical_line"></div>
              Create
            </v-btn>
            <v-btn
              color="#23B1A9"
              dark
              class="buttonscolor mr-5 btn"
              @click.prevent="saveCataStopheDeatils"
              :disabled="this.CatastropheMasterObj._id==''"
            >
              <v-icon class="mr-2 iconFontSize">
                mdi-checkbox-marked-circle</v-icon
              >
              <div class="vertical_line"></div>
              Save
            </v-btn>
            <v-btn
              color="#D1121B"
              dark
              class="buttonscolor mr-5"
              @click="resetForm"
            >
              <v-icon class="mr-2 iconFontSize">
                mdi-trash-can-outline
              </v-icon>
              <div class="vertical_line"></div>
              Clear
            </v-btn>
            <v-btn
              color="#E46A25"
              dark
              class="buttonscolor mr-5"
              @click="$router.go(-1)"
            >
              <v-icon class="mr-2 iconFontSize">
                mdi-close-circle
              </v-icon>
              <div class="vertical_line"></div>
              Close
            </v-btn>
          </v-col>
        </v-row>
      </v-flex>
    </v-form>
    <v-row class="searchRowBackground ml-0 mt-10">
      <v-col cols="8" class="ml-3">
        <v-text-field
          placeholder="Enter Description..."
          solo
          dense
          class="myInputTextField"
          v-model="descriptionSearch"
          @keyup="paginationSearch"
        >
          <!-- <template v-slot:append>
                    <div class="dropdownSlotImgDiv">
                        <img width="15" height="16" src="../../assets/images/dropdownMenu.png" />
                    </div>
                </template> -->
        </v-text-field>
      </v-col>
      <v-col cols="3" class="">
        <v-btn
          color="#23B1A9"
          dark
          class="buttonsWidth mr-4"
          @click="paginationSearch"
        >
          <v-icon class="searchiconFontSize">
            mdi-magnify
          </v-icon>
          <div class="vertical_line"></div>
          Search
        </v-btn>
        <v-btn
          color="#152F38"
          dark
          class="buttonsWidth"
          @click="ClearSearch()"
        >
          <v-icon class="searchiconFontSize">
            mdi-close-circle
          </v-icon>
          <div class="vertical_line"></div>
          Clear
        </v-btn>
      </v-col>
    </v-row>
    <!--  pagination and table -->
    <v-flex class="mt-6 ml-0 backgroundTable">
      <v-flex class="font-weight-bold body-1 pl-3 pt-1"> Search Results</v-flex>
      <v-simple-table class="mt-1">
        <template v-slot:default>
          <thead class="tableBackGroundColor">
            <tr>
              <th
                class="text-left"
                v-for="item in MastertableHeadOptions"
                :key="item"
              >
                {{ item }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in MasterTableData"
              :key="index"
              @click="SelectedRow(item, index)"
            >
              <td>{{ index + pageStartIndex + 1 }}</td>
              <td>{{ item.catastropheId }}</td>
              <td>{{ item.catastropheDescription }}</td>
              <td>{{ item.catastropheDetail }}</td>
              <td>{{ item.catastropheType }}</td>
              <td>{{ ISOtoDDMMYYComputed(item.startDate) }}</td>
              <td>{{ ISOtoDDMMYYComputed(item.endDate) }}</td>
            </tr>
            <tr class="v-data-table__empty-wrapper" v-if="MasterTableData.length==0">
               <td colspan="9">No data available</td>
             </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-flex>
    <!-- Showing {{pageStartIndex +1}}-{{pageEndIndex}}  -->
    <!-- paginationShowHide -->
    <v-flex justify="end" class="ml-20 mb-10" v-if="MasterTableData.length!==0">
      <v-flex class="dispayInlinePagination">
        <div class="mt-3">
          <span
            class="font-weight-bold showing"
          >
            Showing {{ pageStartIndex + 1 }}-{{ pageEndIndex }}</span
          ><span
            class="font-weight-bold outOfRecord"
          >
            out of {{ records }} records</span
          >
        </div>
        <div class="text-center">
          <v-pagination
            v-model="currentPage"
            :length="totalPages"
            next-icon="mdi-arrow-right"
            prev-icon="mdi-arrow-left"
            @input="goNextPagePagination"
            :total-visible="10"
            color="#3C3D3D"
            class="catastropheMasterPaginationButtons mt-2"
          ></v-pagination>
        </div>
      </v-flex>
    </v-flex>
    <!--  save and add dialogbox -->
    <!-- <v-row justify="center">
      <v-dialog v-model="dialogbox" persistent max-width="400">
        <v-card>
          <v-toolbar class="successDailogboxMsg">
            {{ dailoboxSubmitText }}</v-toolbar
          >
          <v-card-text>
            <v-btn
              color="#23B1A9"
              dark
              class="buttonsWidth"
              @click="dialogbox = false"
              max-height="25"
              style="margin-top: 18px; margin-left: 100px"
            >
              <v-icon
                style="font-size: 15px; margin-right: 8px; margin-top: 0px"
              >
                mdi-checkbox-marked-circle
              </v-icon>
              <div class="vertical_line"></div>
              Okay
            </v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row> -->
  </div>
</template>

<script >
import moment from "moment";
import axios from "axios";
export default {
  data() {
    return {
      max50: 50,
      todayDate: new Date().toISOString().substr(0, 10),
      paginationShowHide: true,
      dialogbox: false,
      descriptionSearch: "",
      dailoboxSubmitText: "",
      errorMessage: "This field  is required.",
      currentPage: 1,
      pageStartIndex: 0,
      pageRowCount: 10,
      records: null,
      totalPages: null,
      perPageCount: 10,
      CatastropheMasterObj: {
        catastropheId: "",
        catastropheDescription: "",
        catastropheType: "Local",
        catastropheDetail: "",
        startDate: "",
        endDate: "",
        _id: "",
        updatedAt: "",
        createdById: "",
        createdBy: "",
        createdAt: "",
      },
      StartDateMenu: false,
      endDateMenu: false,
      StartDateFormatted: null,
      endDateFormatted: null,
      options: ["Selecte", "test 1", "test2"],
      MastertableHeadOptions: ["Sr. No.",
        "Catastrophe ID *",
        "Catastrophe Description *",
        "Catastrophe Detail",
        "Catastrophe Type *",
        "Start Date *",
        "End Date *",
      ],
      catastropheTypeOptions: ["Local", "International"],
      catastropheDetailOptions: [],
      MasterTableData: [],
      Datepattern: /(((0|1)[0-9]|2[0-9]|3[0-1])\/(0[1-9]|1[0-2])\/((19|20)\d\d))$/,
      spchCharPattern:/^[A-Za-z0-9 ]+$/,
      spchCharMsg:"Special Characters Not Allowed",
      validateDatemsg:"Enter valid date format as DD/MM/YYYY.",
      rulesValidation: {
        CatastropheIDRules: [
          (v) => !!v || this.errorMessage,
          // (v) => v!="" || this.errorMessage,
            v => v && !!v.trim() ||
            'Only spaces are not allowed',
          (v) => this.spchCharPattern.test(v) || this.spchCharMsg,
        ],
        CatastropheDeatilsRules:[ 
            v => {if (v) return this.spchCharPattern.test(v)  || this.spchCharMsg;
                  else return true},
          ],
        DescriptionRules: [
          (v) => !!v || this.errorMessage,
          v => v && !!v.trim() ||
            'Only spaces are not allowed',
          (v) => this.spchCharPattern.test(v) || this.spchCharMsg,
        ],
        typeRules: [(v) => !!v || this.errorMessage],
        StartDateRules: [
          (v) => !!v || this.errorMessage,
          (v) => this.Datepattern.test(v) || this.validateDatemsg,
          (v) => !!this.pastDateCheck(v) || "Start date should be less than or equal to current date",
        ],
        endDateRules: [
          (v) => !!v || this.errorMessage,
          (v) => this.Datepattern.test(v) || this.validateDatemsg,
          // (v)=> !!this.CatastropheMasterObj.startDate || "Please Enter Start Date first",
          (v) => !!this.futureDateCheck(v) || "End date should be greater or equal to start date.",
        ],
      },
    };
  },
  computed: {
    pageEndIndex() {
      return this.pageStartIndex + this.pageRowCount <= this.records
        ? this.pageStartIndex + this.pageRowCount
        : this.records;
    },
  },
  methods: {
          //to open calender menu on tab click
          onSFocus(){
              this.StartDateMenu = true;
          },

          onEFocus(){
              this.StartDateMenu = false;
              this.endDateMenu = true;
          },

          pastDateCheck(StartDate) {
            if (StartDate !== "") {
              StartDate = moment(StartDate, "DD/MM/YYYY").toDate().getTime();
              if (new Date(StartDate).setHours(0, 0, 0, 0) <= new Date().setHours(0, 0, 0, 0)) {
                return true;
              }
              return false;
            }
          },
          futureDateCheck(endDate) {
            if (endDate !== "" && this.CatastropheMasterObj.startDate!=="") {
              endDate = moment(endDate, "DD/MM/YYYY").toDate().getTime();
            let startDate = moment(this.CatastropheMasterObj.startDate, "DD/MM/YYYY").toDate().getTime()
              if (new Date(startDate).setHours(0, 0, 0, 0) >=  new Date(endDate).setHours(0, 0, 1, 0)) {
                return false;
              }
              return true;
            }else{
              return true;
            }
          },
          paginationSearch() {
            if (this.descriptionSearch !== "") {
              this.pageStartIndex = 0;
                            let URl = this.getURL("SEARCH_CATASROPHE_DETAILS") + "perPage=" + this.perPageCount + "&catastropheDescription=" + this.descriptionSearch;
              axios.get(URl, this.authHeader()).then((response) => {
                if (response.data.data == 0) {
                  this.pageStartIndex = 0;
                  this.MasterTableData = [];
                  this.records = 0;
                  this.totalPages = 0;
                  this.pageRowCount = 0;
                  
                } else {
                  this.MasterTableData=[];
                  this.MasterTableData = response.data.data.data;
                  this.records = response.data.data.total;
                  this.totalPages = response.data.data.total_pages;
                  this.pageRowCount = this.MasterTableData.length;
                  this.pageStartIndex = (pageNumber - 1) * this.pageRowCount;
                                  }
              }).catch((error) => {
                console.log("catch of pagination GET", error);
              });
            } else {
              // this.pageOneServiceCall(1); //11/03/2021 sneha // Search output will be available only when searched.
              this.MasterTableData=[];
              }
          },
          goNextPagePagination(pageNumber) {
            if (this.descriptionSearch == "") {
              this.showProgress("Data is fetching ,please wait");
              let URl = this.getURL("SEARCH_CATASROPHE_DETAILS") + "perPage=" + this.perPageCount + "&pageNo=" + pageNumber;
              axios.get(URl, this.authHeader()).then((response) => {
                this.MasterTableData = response.data.data.data;
                this.records = response.data.data.total;
                this.totalPages = response.data.data.total_pages;
                this.pageRowCount = 10;
                this.pageStartIndex = (pageNumber - 1) * this.pageRowCount;
                this.hideProgress();
              }).catch((error) => {
                console.log("catch of pagination GET", error);
                this.hideProgress();
              });
            } else {
              this.showProgress("Data is fetching, please wait");
              this.pageStartIndex = 0;
              pageNumber = pageNumber == 1 ? 1 : pageNumber;
              let URl = this.getURL("SEARCH_CATASROPHE_DETAILS") + "perPage=" + this.perPageCount + "&pageNo=" + pageNumber + "catastropheDescription=" + this.descriptionSearch;
              axios.get(URl, this.authHeader()).then((response) => {
                this.MasterTableData = response.data.data.data;
                this.records = response.data.data.total;
                this.totalPages = response.data.data.total_pages;
                this.pageRowCount = 10;
                this.pageStartIndex = (pageNumber - 1) * this.pageRowCount;
                this.hideProgress();
              }).catch((error) => {
                console.log("catch of pagination GET", error);
                this.hideProgress();
              });
            }
          },
          addCataStopheDeatils() {
            if (this.$refs.Formvalid.validate() && this.CatastropheMasterObj._id == "") {
              this.showProgress("adding CataStophe,please wait");
              let PostData = {
                catastropheId: this.CatastropheMasterObj.catastropheId,
                catastropheDescription: this.CatastropheMasterObj.catastropheDescription,
                catastropheType: this.CatastropheMasterObj.catastropheType,
                catastropheDetail: this.CatastropheMasterObj.catastropheDetail == null ? "" : this.CatastropheMasterObj.catastropheDetail,
                startDate: this.toISOStringConvert(this.StartDateFormatted),
                endDate: this.toISOStringConvert(this.endDateFormatted),
              };
              this.POSTM("CREATE_CATASROPHE", PostData, (res) => {
                // this.pageOneServiceCall(1); //11/03/2021 sneha // Search output will be available only when searched.
                this.$refs.Formvalid.reset();
                this.CatastropheMasterObj._id="";
                this.hideProgress();
                this.dailoboxSubmitText = res.data.msg;
                this.showAlert(this.dailoboxSubmitText);
               // this.dialogbox = true;
              }, (error) => {
                this.hideProgress();
                this.CatastropheMasterObj._id="";
                this.dailoboxSubmitText = error.response.data.msg;
                this.showAlert(this.dailoboxSubmitText);
                //this.dialogbox = true;
              });
            }
          },
          saveCataStopheDeatils() {
           if (this.$refs.Formvalid.validate() && this.CatastropheMasterObj._id !== "") {
              this.showProgress("Saving CataStophe,please wait");
              let PostDataSave = {
                catastropheId: this.CatastropheMasterObj.catastropheId,
                catastropheDescription: this.CatastropheMasterObj.catastropheDescription,
                catastropheType: this.CatastropheMasterObj.catastropheType,
                catastropheDetail: this.CatastropheMasterObj.catastropheDetail == null ? "" : this.CatastropheMasterObj.catastropheDetail,
                startDate: this.toISOStringConvert(this.StartDateFormatted),
                endDate: this.toISOStringConvert(this.endDateFormatted),
              };
              this.PUTM("UPDATE_CATASROPHE", this.CatastropheMasterObj._id, PostDataSave, (res) => {
                this.MasterTableData=[];
                this.descriptionSearch = "";
                // this.pageOneServiceCall(1); //11/03/2021 sneha // Search output will be available only when searched.
                this.CatastropheMasterObj._id="";
                this.$refs.Formvalid.reset();
                this.hideProgress();
                this.dailoboxSubmitText = res.data.msg;
                this.showAlert(this.dailoboxSubmitText);
               // this.dialogbox = true;
              }, (error) => {
                this.hideProgress();
                this.CatastropheMasterObj._id="";
                this.dailoboxSubmitText = error.response.data.msg;
                this.showAlert(this.dailoboxSubmitText);
                //this.dialogbox = true;
              });
            }
          },
          SelectedRow(item, index) {
            this.showProgress();
            let URl = this.getURL("GET_CATASROPHE_DETAILS") + item._id;
            axios.get(URl, this.authHeader()).then((response) => {
              this.CatastropheMasterObj = response.data.data;
              this.CatastropheMasterObj.startDate = this.ISOtoDDMMYY(response.data.data.startDate);
              this.CatastropheMasterObj.endDate = this.ISOtoDDMMYY(response.data.data.endDate);
              this.StartDateFormatted = this.ChangeDateBindCalenderStart(this.CatastropheMasterObj.startDate);
              this.endDateFormatted = this.ChangeDateBindCalenderEnd(this.CatastropheMasterObj.endDate);
              this.hideProgress();
            }).catch((error) => {
              console.log("catch of GET", error);
              this.hideProgress();
            });
          },
          ChangeDateBindCalenderStart(date) {
            if (this.Datepattern.test(date)) {
              const [day, month, year] = date.split("/");
              this.StartDateFormatted = `${year}-${month}-${day}`;
              this.StartDateMenu = false;
              return `${year}-${month}-${day}`;
            } else {
              this.StartDateFormatted = moment().format("YYYY-MM-DD");
            }
          },
          ChangeDateBindCalenderEnd(date) {
            if (this.Datepattern.test(date)) {
              const [day, month, year] = date.split("/");
              this.endDateFormatted = `${year}-${month}-${day}`;
              this.endDateMenu = false;
              return `${year}-${month}-${day}`;
            } else {
              this.endDateFormatted = moment().format("YYYY-MM-DD");
            }
          },
          formatDateStartDate(date) {
            const [year, month, day] = date.split("-");
            return `${day}/${month}/${year}`;
          },
          formatDateEndDate(date) {
            const [year, month, day] = date.split("-");
            return `${day}/${month}/${year}`;
          },
          toISOStringConvert(dateString) {
            return new Date(dateString).toISOString();
          },
          ISOtoDDMMYY(date) {
            if (date !== undefined) {
              // let splitdate = new Date(date).toISOString().substr(0, 10);
              // const [year, month, day] = splitdate.split("-");
              // return `${day}/${month}/${year}`;
               return moment(date).format("DD/MM/YYYY")
            } else {
              return date;
            }
          },
          ISOtoDDMMYYComputed(date) {
            if (date !== undefined) {
              // let splitdate = new Date(date).toISOString().substr(0, 10);
              // const [year, month, day] = splitdate.split("-");
              // return `${day}/${month}/${year}`;
               return moment(date).format("DD/MM/YYYY")
            } else {
              return date;
            }
          },
          pageOneServiceCall(pageNumber) {
           this.showProgress("Data is fetching, please wait");
            this.descriptionSearch = "";
            this.currentPage = 1;
            let URl = this.getURL("SEARCH_CATASROPHE_DETAILS") + "perPage=" + this.perPageCount + "&pageNo=" + pageNumber;
            axios.get(URl, this.authHeader()).then((response) => {
              if (response.data.data.length !== 0) {
                this.MasterTableData = response.data.data.data;
                this.records = response.data.data.total;
                this.totalPages = response.data.data.total_pages;
                this.pageRowCount = 10;
                this.pageStartIndex = (1 - 1) * this.pageRowCount;
                              } else {
                
              }
              this.hideProgress();
            }).catch((error) => {
              console.log("catch of GET", error);
              this.hideProgress();
            });
          },
          ClearSearch(){
             this.descriptionSearch = "";
             this.MasterTableData=[];
          },
          resetForm() {
             this.CatastropheMasterObj._id="";
            this.$refs.Formvalid.reset();
          },
          resetFormObj() {
            this.CatastropheMasterObj = {
              catastropheId: "",
              catastropheDescription: "",
              catastropheType: "Local",
              catastropheDetail: "",
              startDate: "",
              endDate: "",
              _id: "",
            };
          },
    },
  mounted() {
    this.hideProgress();
    // this.pageOneServiceCall(1); //11/03/2021 sneha // Search output will be available only when searched.
  },
};
</script>
<style scoped>
.cursorPoniter{
  cursor: pointer !important;
}
.iconFontSize {
	font-size: 18px;
}
.searchiconFontSize {
	font-size: 15px;
	margin-right: 8px;
	margin-top: 0px;
}
.outOfRecord {
	color: #3c3d3d;
	font-size: 14px;
}
.showing {
	color: #e46a25;
	font-size: 14px;
}
.iconstyle {
	margin-left: -12px;
	background-color: rgb(247, 247, 247);
	width: 45px;
	height: 38px;
	font-size: 20px !important;
	border: 0.3px solid rgb(232, 235, 236);
	opacity: 1;
	/* box-shadow: rgba(0, 0, 0, 0.318) 0px 2px 2px; */
}
.dispayInlinePagination {
	display: inline-flex;
	margin-left: 55%;
}
.tableBackGroundColor {
	background-color: #23b1a9;
}
th {
	color: white !important;
	font-size: 15px !important;
	height: 35px !important;
	border: 0.5px ridge #f7f7f7;
	border-width: 1px;
	border-right: none;
	border-collapse: collapse;
}
td {
	border: 1px solid #f7f7f7;
	border-width: 2px;
	border-right: none;
	border-collapse: collapse;
	height: 35px !important;
	font-size: 15px !important;
	color: #10242b;
}
.btnDiv {
	padding-top: 5px;
	padding-bottom: 5px;
	background-color: #f7f7f7;
}
.claimlabel {
	color: #646666;
	font-size: 15px;
	font-weight: bold;
}
.inputwidth {
	max-width: 325px;
	border-radius: 0 !important;
}
.buttonscolor {
	border-radius: 0 !important;
	height: 30px !important;
	text-transform: capitalize;
	font-size: 15px;
	width: 112 !important;
}
.buttonsWidth {
	border-radius: 0 !important;
	height: 35px !important;
	text-transform: capitalize;
	font-size: 15px;
	width: 100px !important;
}
.searchRowBackground {
	background-color: #f7f7f7;
	padding-top: 3px;
	height: 70px;
}
.backgroundTable {
	background-color: #f7f7f7 !important;
}
.dropdownSlotImgDiv {
	padding-left: 13px;
	border-left: 1px solid #c1c8cc;
	height: 38px;
	background-color: #f7f7f7;
	width: 44px;
	padding-top: 10px;
}
.v-text-field .v-input__control .v-input__slot .myInputTextField {
	padding-right: 0 !important;
}
.theme--dark.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
	background-color: #c1c8cce8 !important;
	color: rgba(0, 0, 0, 0.26) !important;
}
.forminput {
	border-radius: 0;
}
</style>
