<template>
    <div class="my-3" v-if="((claimData && claimData.claimNumber) ||(otherLobData && otherLobData.claimId))">
      <v-row no-gutters>
        <v-layout class="claim-details px-3">
          <v-toolbar-title style="color: #23b1a9" class="claim-header">
            <v-row>
              <!-- <v-col><span>Claim Details</span></v-col> -->
              <v-col
                ><span class="claim-subheader red--text">
                  {{
                    `${claimDetails.claimNumber || ""}${claimDetails.featureNumber || ''}
                      | ${claimDetails.claimType || ""} 
                      | ${claimDetails.claimStatus || ""}
                      | ${claimDetails.claimSubStatus || ""}`
                  }}</span
                ></v-col
              >
            </v-row>
          </v-toolbar-title>
          <v-spacer></v-spacer>
  
          <v-toolbar-items class="sm-and-down">
            <v-btn
              class="menus_btn"
              @click="toggleDetails = !toggleDetails"
            >
              {{ toggleDetails ? "HIDE" : "SHOW" }} Details
            </v-btn>
          </v-toolbar-items>
        </v-layout>
      </v-row>
      <v-row no-gutters>
        <v-row class="pl-13 claim-row" no-gutters v-if="toggleDetails">
          <v-col cols="2">
            <label>LOB</label>
            <p>{{ claimDetails.lobType || "--------------" }}</p>
          </v-col>
          <v-col cols="2">
            <label>Policy No.</label>
            <p>{{ claimDetails.policyNumber || "--------------" }}</p>
          </v-col>
          <!-- <v-col cols="2">
            <label>Feature No.</label>
            <p>{{ claimDetails.featureNumber || "--------------" }}</p>
          </v-col>
          <v-col cols="2">
            <label>Claim No.</label>
            <p>{{ claimDetails.claimNumber || "--------------" }}</p>
          </v-col> -->
          <v-col cols="2">
            <label>Certificate Number</label>
            <p>{{ claimDetails.certificateNo || "--------------" }}</p>
          </v-col>
          <v-col cols="2">
            <label>Date of Loss</label>
            <p>{{ formatDateOnly(claimDetails.lossDate) || "--------------" }}</p>
          </v-col>
          <v-col cols="2">
            <label>Insured Name</label>
            <p>{{ claimDetails.insuredName || "--------------" }}</p>
          </v-col>
          <!-- <v-col cols="2">
            <label>Claim Status</label>
            <p>{{ claimDetails.claimStatus || "--------------" }}</p>
          </v-col> -->
  
          <v-col cols="2">
            <label>Examiner Name & NTID</label>
            <p>
              {{
                claimDetails.examiner &&
                claimDetails.examiner.employeeName &&
                claimDetails.examiner.ntidUserId
                  ? claimDetails.examiner.employeeName +
                    " - " +
                    claimDetails.examiner.ntidUserId
                  : "--------------"
              }}
            </p>
          </v-col>
          <!-- <v-col cols="2">
            <label>Examiner NTID</label>
            <p>
              {{
                claimDetails.examiner && claimDetails.examiner.ntidUserId
                  ? claimDetails.examiner.ntidUserId
                  : "--------------"
              }}
            </p>
          </v-col> -->
          <v-col cols="2">
            <label>Product Name</label>
            <p>{{ claimDetails.productName || "--------------" }}</p>
          </v-col>
          <v-col cols="2">
            <label>Cause Of Loss</label>
            <p>{{ claimDetails.lossDescription || "--------------" }}</p>
          </v-col>
          <v-col cols="2">
            <label>Reason For Suspense</label>
            <p>{{ claimDetails.reasonForSuspense || "--------------" }}</p>
          </v-col>
          <v-col cols="2">
            <label>Indemnity</label>
            <p>
              {{
                claimDetails.indemnityReserve &&
                claimDetails.indemnityReserve.amount
                  ? claimDetails.indemnityReserve.amount
                  : "--------------"
              }}
            </p>
          </v-col>
          <v-col cols="2">
            <label>Expense</label>
            <p>
              {{
                claimDetails.expenseReserve && claimDetails.expenseReserve.amount
                  ? claimDetails.expenseReserve.amount
                  : "--------------"
              }}
            </p>
          </v-col>
          <v-col cols="2">
            <label>Indemnity Status</label>
            <p>
              {{
                claimDetails.indemnityReserve &&
                claimDetails.indemnityReserve.status
                  ? claimDetails.indemnityReserve.status
                  : "--------------"
              }}
            </p>
          </v-col>
          <v-col cols="2">
            <label>Expense Status</label>
            <p>
              {{
                claimDetails.expenseReserve && claimDetails.expenseReserve.status
                  ? claimDetails.expenseReserve.status
                  : "--------------"
              }}
            </p>
          </v-col>
          <v-col cols="2">
            <label>Close Proximity</label>
            <p>{{ claimDetails.isCloseProximityClaim || "--------------" }}</p>
          </v-col>
        </v-row>
      </v-row>
    </div>
  </template>

<script>
import moment from 'moment';
export default {
  name: "ClaimDetails",
  props: {
    claimData : {
      default: {
        lobType:"",
        claimNumber: "",
        featureNumber: "",
        claimId:'',
      },
      },
  },
  data() {
    return { toggleDetails: false, claimDetails: {},otherLobData:{}};
  },
  methods: {
    formatDateOnly(date) {
      return date ? moment(date).utc().format("YYYY-MM-DD") : "";
    },
  },
  created() {
  this.otherLobData = this.$store.getters.fetchDataFromOtherLOB;
    if(this.otherLobData.lobType == 'Health') {
      this.claimDetails = this.$store.getters.fetchDataFromOtherLOB
    }else if(this.otherLobData.lobType == 'Home'){
      let claimId=this.otherLobData.claimId
      if (claimId) {
        this.GET_ByURLM(
          "HOME_GET_CLAIM",
          claimId,
          (res) => {
            console.log("%*%*claimDetails=",res.data.data)
            this.claimDetails = res?.data?.data || {};
          },
          (error) => {
            return error;
          }
        );
      }
    } else {
      let reqData = `page=1&pageLimit=100&claimNumber=${this.claimData.claimNumber}&featureNumber=${this.claimData.featureNumber}`;
      this.GETM(
        "TRAVEL_GET_CLAIM",
        reqData,
        (res) => {
          this.claimDetails = res?.data?.data?.data[0] || {};
        },
        (err) => {
          console.error("Issue while fetching claim details", err);
        }
      );      
    }
  },
};
</script>

<style scoped>
.claim-details,
.claim-row {
  background-color: #efeef4;
  border: 1px solid #00000052;
  /* padding: 12px !important; */
  margin: 0px 20px;
}
.divider-menus {
  margin-top: 5px;
}

.claim-main-row {
  width: 80%;
  margin: 0% 10%;
}

.v-application .ml-3 {
  margin: 0px 5% !important;
}
.container {
  padding: 0px 12px;
}
label {
  font: bold 14px Roboto;
  color: #10242b;
}
.claim-header {
  font: bold 16px Roboto !important;
  color: #10242b;
  padding-left: 5px;
}
.claim-subheader {
  font: bold 14px Roboto !important;
  color: red !important;
  padding-left: 5px;
  text-transform: uppercase;
}
.menus_btn {
  font: medium 12px Roboto !important;
  color: #10242b;
  padding-left: 5px;
}
.v-sheet.v-toolbar:not(.v-sheet--outlined) {
  box-shadow: 0px 0px 0px 0px rgb(0 0 0), 0px 0px 0px 0px rgb(0 0 0),
    0px 0px 0px 0px rgb(0 0 0) !important;
}
.v-btn--is-elevated {
  box-shadow: 0px 0px 0px 0px rgb(0 0 0), 0px 0px 0px 0px rgb(0 0 0),
    0px 0px 0px 0px rgb(0 0 0) !important;
}
.theme--light.v-btn.v-btn--has-bg {
  background-color: #efeef4;
  color: #23b1a9;
}
.center {
  text-align: center;
  margin-top: 22px;
}
.examiner_color {
  color: #1a0dab;
  text-transform: capitalize;
  cursor: pointer;
}
.col-sm-2 {
  padding: 0px;
}
.card:hover {
  border: 2px solid teal;
  cursor: pointer;
}
p {
  font: normal 14px Roboto !important;
  color: #10242b;
}

.descrptionSpan {
    width: 75%;
    margin-top: 21px;
    font-weight: 500;
    font-size: 17px;
    letter-spacing: 0.4px;
   }
.policydescrptionSpan {
  width: 85%;
  margin-top: 21px;
  font-weight: 500;
  font-size: 17px;
  letter-spacing: 0.4px;
}
.vcardTextTableBackground {
    background-color: #b4b4b440 !important;
    /* box-shadow: inset 0px 3px 4px 0px #00000047; */
}
/* .textcolr{
    color: #1A0DAB !important;
    font-size: 13px !important;
    font-weight: bold;
} */
.colorbg{
    background-color: #23b1a9;
    color:#fff;
}
  .messagestyle{
    border: 1px solid #C7000070;
    background-color: #FF00001C;
  }
.alertstyle{
  border: 1px solid #C7000070;
  margin: 10px 20px 1px 4px;
}
.textcolor {
    color: #1a0dab;
    font-weight: 600;
}
.assignedcolor{
  color: #7e73f1;
  font-weight: 300;
}
.headerstyle tr th{
  background-color: #f5535370 !important;
	color: rgb(189, 69, 69) !important;
	font-size: 15px !important;
	height: 40px !important;
	border-right: none;
	border-collapse: collapse;
	font-weight: bold;
	text-align: center !important;
}
</style>
