 <template>
    <v-app>
       
       <v-form v-model="valid" ref="netform">
        <v-card tile class="py-5 px-3 mt-4 " >
                

                <v-layout color="#FFFFFF" class=" mt-1 " style="background-color: #FFFFFF;">
                <v-flex xs3 class="errormsgUT ">
                    <v-layout column class="cust_pr mx-3 ">
                         <v-card class="rounded-0 pl-4 lbl-bgclr padTop-13" dense  style="height: 40px; display: flex;align-items: center;margin-top: 24px;">
                                <v-checkbox
                                    dense
                                    color = "#23b1a9"
                                    label="Active"
                                    item-text="text"
                                    item-value="value"
                                    v-model="activeBox"
                                    @click="btnChange"
                                    
                                    :rules="[rules.requiredAction]"
                                   
                                    >
                                </v-checkbox>
                            </v-card>
                        
                    </v-layout>
                    
                    
                    <!-- <div style="border: 0.5px solid #c1c8cc; padding:6px" class="cust-bnkAcc-tbl mt-8">
                        <v-checkbox class="ma-0 pa-0" v-model="activeBox" color="#23B1A9"  hide-details >
                            <span slot="label" class="lbl-bgclr font-weight-bold" style="font-size: 13px;">Active</span>
                        </v-checkbox>
                    </div> -->
                    <!-- <span slot="label" class="lbl-bgclr font-weight-bold" :rules="[rules.required]" style="font-size: 13px;color:#23B1A9 !important;">{{activeBox}}</span> -->
                </v-flex>

                
                <v-flex xs3 >
                    <v-layout column class="  mx-3 cust_pr">
                        <p class="font-weight-bold cstm-clr-pr">
                            Role ID *
                        </p>

                        <v-text-field
                            solo
                            dense
                            label="Auto Generated"
                            v-model="roleID"
                            class="rounded-0"
                            background-color="#f7f7f7"
                            disabled
                        >
                        </v-text-field>
                    </v-layout>
                </v-flex>

                  <v-flex xs3 class="errordatemsgUT1">
                    <v-layout column class=" mx-3  cust_pr">
                        <p class="font-weight-bold cstm-clr-pr">
                            LOB *
                        </p>
                        <v-select
                            :items="GetLOBlist"
                            item-text="type"
                            item-value="_id"
                            v-model="lob"
                            @change="GetPermissionListData(false,lob)"
                            label="Select"
                            return-object
                            :disabled="lopdisabled"
                            solo
                            dense    
                            :rules="[rules.requiredarr]"
                            class="rounded-0"
                           
                            >
                        </v-select>
                    </v-layout>
                </v-flex>

                <v-flex xs3 class="errordatemsgUT1">
                    <v-layout column class=" mx-3  cust_pr">
                        <p class="font-weight-bold cstm-clr-pr">
                            Role *
                        </p>
                        <v-select
                            :items="selectRole"
                            item-text="type"
                            @change="filterole(1)"
                            item-value="_id"
                            v-model="role"
                            label="Select"
                            return-object
                            solo
                            dense
                            :rules="[rules.requiredarr]"
                            class="rounded-0"
                            :disabled="!lob ? true : false"
                            >
                        </v-select>
                    </v-layout>
                </v-flex>

                <v-flex xs3 >
                    <v-layout column class="  mx-3 cust_pr">
                        <p class="font-weight-bold cstm-clr-pr">
                            Role Description *
                        </p>

                          <v-autocomplete
                            :items="ROLETYPES.Rolesdata"
                            item-text="rvalue"
                            item-value="rvalue"
                            v-model="roleDescription"
                            label="Select Description Type"
                            return-object
                            solo
                            dense
                            :rules="[rules.requiredarr(roleDescription.rvalue)]"
                            class="rounded-0"
                            :disabled="!lob"
                            >
                        </v-autocomplete>
                        <!-- <v-text-field
                            solo
                            dense
                            label="Enter..."
                            v-model="roleDescription"
                            class="rounded-0"
                            
                        >
                        </v-text-field> -->
                    </v-layout>
                </v-flex>
                
              
                </v-layout>
                
            </v-card>

        <v-card class=" cust-bnkAcc-tbl " style="background-color:#eeeeee" tile>
            <v-layout>
            <v-flex xs4 mt-3>
                <p class="font-weight-bold cstm-clr-pr-hd" style="text-align: center;">
                    PERMISSIONS AVAILABLE
                </p>
            </v-flex>

            <v-icon large>swap_horiz</v-icon>

            <v-flex xs4 mt-3>
                <p class="font-weight-bold cstm-clr-pr-hd" style="text-align: center;">
                    SELECTED LIST OF PERMISSIONS
                </p>
            </v-flex>
            </v-layout>
        </v-card>

            
<!-- 1.PERMISSIONS name Avalaible-->
            <v-card class="mb-2 cust-bnkAcc-tbl" style="border:0.5px solid #C1C8CC;" tile>
				<v-layout  style="padding:15px;">
                   
                    <v-flex xs4 ma-2>
                        <!-- <v-card class=" cust-bnkAcc-tbl" style="border:0.5px solid #C1C8CC; min-height:200px" tile>
                            
                            
                            <v-flex pa-2>
                                <v-layout column class="  mx-3 cust_pr">
                                    
                                    <v-autocomplete
                                        :items="GetPermissionList"
                                        item-text="type"
                                        item-value="_id"
                                        v-model="permissionName"
                                        label="Select"
                                        return-object
                                        cache-items
                                        hide-selected
                                        required
                                        solo
                                        dense
                                        multiple
                                        class="rounded-0 hideIcon"
                                        :rules="[rules.requiredarr]"
                                        @change="itemSelectedOnClick"
                                    >
                                    <v-icon slot="append" 
                                        dense 
                                        style="background-color:#F7F7F7; height:38px; width:40px;">
                                            search
                                    </v-icon>
                                    </v-autocomplete> 
                                </v-layout>
                                
                                
                            </v-flex>

                            <v-flex pa-1 class="mx-3  cust_pr mb-5" >   
                                  <v-layout column class="mx-3 cust_pr chgColor" justify-center >                   
                                <v-card class="rounded-0 pl-4 " v-if="hideSelectAll == true" dense  style="height: 40px; display: flex;align-items: center;">
                                        <v-checkbox class="ma-0 pa-0" v-model="allSelected"  hide-details  label="Select All" @click="selectAll" @change="selectAllChange">
                                        </v-checkbox>
                                </v-card>

                                <v-card v-for="(screen,index) in selectedPermission" :key="index" class="rounded-0 pl-4 mt-2" dense  style="height: 40px; display: flex;align-items: center;">
                                        <v-checkbox class="ma-0 pa-0" v-model="screenValues" hide-details :value="screen"  :label="screen" @change="changedValues($event,screen)">
                                        </v-checkbox>
                                </v-card>
                                  </v-layout>  
                               
                            </v-flex>

                           
                        </v-card> -->

                         <v-card style="min-height:340px;max-height:340px; overflow-y:scroll"  tile class="pa-4">

                            <v-text-field
                                label="Search"
                                solo
                                class="rounded-0 mb-3"
                                hide-details
                                v-model="permissionName"
                            >
                                <div slot="append" style="background-color:#23B1A9;padding:5.5px;cursor:pointer;position:absolute;top:0px;right:0px">
                                    <v-icon dark>search</v-icon>
                                </div>
                            </v-text-field>

                           
                            <!-- <p>{{validation_oneitem}}</p> -->
                            <p v-if="validation_oneitem===true" class="ma-0 pa-0 px-3 " style="color:#ff5252;font-size: 12px;">At least one item should be selected</p>

                            <v-layout  align-center class= "elevation-1">
                                 <!-- @change="selectAllChange" -->
                                <v-checkbox
                                    dense
                                    v-model="allSelected"
                                    @click="selectAll" 
                                  
                                    hide-details
                                    class="pa-0 ma-0 pa-2"
                                    color="#23B1A9"
                                    
                                >
                                    <span slot="label" class="font-weight-bold" style="font-size: 12px;color:#10242B">Select All</span>
                                </v-checkbox>
                            </v-layout>

                            <v-layout column wrap class="">
                                <v-flex xs2  v-for="(screen,index) in GetPermissionListFilter" :key="index" class="">
                                   <!-- @change="changedValues($event,screen, index)" -->
                                <v-checkbox
                                        v-model="screen.sel"
                                        hide-details 
                                        color="#23B1A9" 
                                        :label="screen.name"
                                        @change="selectAllChange(index)"
                                        dense
                                        class="pa-0 ma-0 elevation-1 pa-2 py-2 mt-2 font-weight-bold"
                                       
                                        >
                                        <!-- <span slot="label" class="font-weight-bold" style="font-size: 12px;word-wrap: break-word; color:#646666">{{screen.permissionName}}</span> -->
                                    </v-checkbox>
                                    </v-flex>
                            </v-layout>
                            
                        </v-card>
                    </v-flex>

    <!--2  SELECTED LIST OF PERMISSIONS-->

            <v-flex xs4 ma-2>
                        <v-card class="mb-2  cust-bnkAcc-tbl" style="min-height:340px;max-height:340px; overflow-y:scroll" tile>

                            <v-flex pa-1 class="mx-3  cust_pr mb-5" >                      
                                <v-card >
                                    <v-card v-for="(screen,index) in filterselection" :key="index" class="rounded-0 pl-4 mt-2" dense  style="height: 40px; display: flex;align-items: center;  pointer-events: none" >
                                        
                                    <v-checkbox  class="ma-0 pa-0 ml-2" color="lightgray"
                                        v-model="screen.sel" readonly hide-details style="pointer-events: visible;" ></v-checkbox>

                                        <v-card-text class="pa-0 font-weight-bold" style="color:black; width: 200px; font-size:13px; " ref="selectedScreenName" >
                                            {{ screen.name }} 
                                        </v-card-text>
                                    </v-card> 
                                </v-card>
                            </v-flex>

                          

                        </v-card>
                    </v-flex>
                    
				</v-layout>
            </v-card>
</v-form>

            
        <div>
            <v-card class="mb-2 mt-3 cust-bnkAcc-tbl" style="border:0.5px solid #C1C8CC;" tile>
				<v-layout  style="padding:15px;background-color:#F7F7F7;align-items:center; justify-center; justify-content: flex-end;">
                    <v-btn   tile color="#23B1A9" class="clearbtn text-capitalize px-3 mx-3" @click="save()" :dark="!updateBtn"
                    :disabled="updateBtn" small><v-icon dark small>check_circle</v-icon>
                    <v-divider vertical class="mx-2"></v-divider>Save</v-btn>
                    <v-btn  tile color="#23B1A9" class="savebtn text-capitalize px-3 mx-3" @click="submit()"  :dark="!createBtn" :disabled="createBtn" small><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider>Submit</v-btn>
                    <v-btn dark  tile color="#E46A25" class="cancelbtn text-capitalize px-3 mx-3" @click=$router.go(-1) small><v-icon dark small>cancel</v-icon> <v-divider vertical class="mx-2"></v-divider> Close</v-btn>
				</v-layout>
            </v-card>         
        </div>
        
    </v-app>
</template>
<script>

// import axios from "axios";

export default {
    components:{},

    created() {

        this.$store.commit("SAVE_PAGE_HEADER", "Role Creation");
        
        this.token=this.$store.getters.successToken

        this.rolebtnvalue=this.$route.params.value
         console.log('incoming params---',this.rolebtnvalue)
        
        this.editparams=this.$route.params.editValue
        console.log('incoming params for edit btn--->',this.editparams)

        if(this.$route && this.$route.params && this.$route.params.item && this.$route.params.item.lineOfBusiness){
                let lobParam    =   this.$route.params.item.lineOfBusiness
                if(lobParam =='EW'){
                    this.allroles = this.rolesforEW
                }else if(lobParam =='Marine'  || lobParam =='AIGC' || lobParam =='PE'){
                    this.allroles   =   this.marineRoles
                }else if(lobParam == "Travel" || lobParam == "PA"){
                    this.allroles  =   this.travelRoles
                }else if(lobParam =='CM'){
                    this.allroles  =   this.allrolesCM
                }else if(lobParam =='Home'){
                    this.allroles  =   this.homeRoles
                }else{
                    this.allroles = this.allrolesNEW
                }
        }
 

        if(this.editparams == true){
            this.lopdisabled = true
        }else{
            this.lopdisabled = false
        }

        this.GetLOBlistData()

       
        
        if(this.rolebtnvalue==true){
            this.createBtn=false
            this.updateBtn=true
            //   this.GetPermissionListData(false)
            console.log('incoming params createbtn----->',this.createBtn)
         }else if(this.editparams==true){
            this.rolehyperlink=this.$route.params.item.allData._id;
            console.log(this.$route.params.item.allData, ' All-DATA | incoming items from rolehyperlink--->',this.rolehyperlink)
            this.GetPermissionListData(true, this.$route.params.item.allData.lineOfBusiness)
                 this.createBtn=true 
                this.updateBtn=false
         }else{
            // this.GetPermissionListData(false)
         }
        // console.log("this.getEditDetails(this.$router.history.current.name) from Mixin===>",this.getEditDetails(this.$router.history.current.name).isEdit)
        // if(this.getEditDetails(this.$router.history.current.name)){
        //     this.disableAll=this.getEditDetails(this.$router.history.current.name).isEdit
            
        // }else{s
        //     this.disableAll=false
        // }

        // if(this.disableAll==false){
        //     this.createBtn=true 
        //     this.updateBtn=true
        // }
                

    },

    data() {
        return {
            lopdisabled:false,
            rolesforEW:[
            {
                "cgtype": "CG1",
                "rvalue": "Admin"
            },
             {
                "cgtype": "CG1",
                "rvalue": "Claims Ops"
            },
             {
                "cgtype": "CG1",
                "rvalue": "Super Admin"
            },
            {
                "cgtype": "CG1",
                "rvalue": "IT Admin"
            },
            {
                "cgtype": "CG2",
                "rvalue": "CCO"
            },
            {
                "cgtype": "CG3",
                "rvalue": "Line Head"
            },
            {
                "cgtype": "CG3",
                "rvalue": "Health & PA Claims Head"
            },
            {
                "cgtype": "CG4",
                "rvalue": "Zone Manager - (Zonal Claims Manager)"
            },
            {
                "cgtype": "CG5",
                "rvalue": "Master Maker"
            },
            {
                "cgtype": "CG6",
                "rvalue": "Normal Claims User"
            },
             {
                "cgtype": "CG7",
                "rvalue": "Call Centre"
            },
            {
                "cgtype": "CG8",
                "rvalue": "Master Checker"
            },
             {
                "cgtype": "CG9",
                "rvalue": "Contractual/Outsource (Vendor)"
            }, 
            {
                "cgtype": "CG9",
                "rvalue": "Contractual/Outsource (Repairer)"
            },
            {
                "cgtype": "CG10",
                "rvalue": "Tax Team"
            },
             {
                "cgtype": "CG11",
                "rvalue": "Others"
            },
            ],
             allroles: [],
            allrolesNEW: [{
        "cgtype": "CG1",
        "rvalue": "Admin"
    },
    {
        "cgtype": "CG2",
        "rvalue": "CCO"
    },
    {
        "cgtype": "CG3",
        "rvalue": "Health & PA Claims Head"
    },
    {
        "cgtype": "CG4",
        "rvalue": "Zone Manager"
    },
    {
        "cgtype": "CG5",
        "rvalue": "Medical Adjudicator - Supervisor"
    },
     {
        "cgtype": "CG5",
        "rvalue": "Medical Adjudicator"
    },
    {
        "cgtype": "CG5",
        "rvalue": "Quality Analyst"
    },
    {
        "cgtype": "CG5",
        "rvalue": "Claims Administrator"
    },
    {
        "cgtype": "CG5",
        "rvalue": "Lead User"
    },
    {
        "cgtype": "CG5",
        "rvalue": "Payment Supervisor"
    },
    {
        "cgtype": "CG5",
        "rvalue": "Investigation Supervisor"
    },
    {
        "cgtype": "CG5",
        "rvalue": "Enrolment Supervisor"
    },
    {
        "cgtype": "CG5",
        "rvalue": "Product Configuration Supervisor"
    },
    {
        "cgtype": "CG5",
        "rvalue": "Product Configuration Executive"
    },
    {
        "cgtype": "CG5",
        "rvalue": "TAT Supervisor"
    },
    {
        "cgtype": "CG5",
        "rvalue": "Internal CRM Supervisor"
    },
    {
        "cgtype": "CG5",
        "rvalue": "Grievance Redressal Supervisor"
    },
    {
        "cgtype": "CG5",
        "rvalue": "Approver/Authorizer"
    },
    {
        "cgtype": "CG5",
        "rvalue": "Provider Administrator"
    },
    {
        "cgtype": "CG5",
        "rvalue": "Lead User"
    },
    {
        "cgtype": "CG5",
        "rvalue": "Channel Manager"
    },
    {
        "cgtype": "CG5",
        "rvalue": "UW Manager"
    },
    {
        "cgtype": "CG5",
        "rvalue": "COPS Manager"
    },
    {
        "cgtype": "CG5",
        "rvalue": "Channel Agency"
    },
    {
        "cgtype": "CG5",
        "rvalue": "Channel Broker"
    },
     {
        "cgtype": "CG5",
        "rvalue": "DEO - Supervisor"
    },
    {
        "cgtype": "CG5",
        "rvalue": "Channel Sales"
    },
    {
        "cgtype": "CG5",
        "rvalue": "Channel Banca"
    },
    {
        "cgtype": "CG6",
        "rvalue": "Scanner Operator"
    },
    {
        "cgtype": "CG6",
        "rvalue": "Inward Assessor"
    },
    {
        "cgtype": "CG6",
        "rvalue": "Email/Fax coordinator"
    },
    {
        "cgtype": "CG6",
        "rvalue": "Enrolment team User"
    },
    {
        "cgtype": "CG6",
        "rvalue": "Health Call Centre executive"
    },
    {
        "cgtype": "CG6",
        "rvalue": "Provider Data Entry Operator"
    },
    {
        "cgtype": "CG6",
        "rvalue": "Network Executive"
    },
    {
        "cgtype": "CG6",
        "rvalue": "Reviewer"
    },
    {
        "cgtype": "CG6",
        "rvalue": "Provider Network manager"
    },
    {
        "cgtype": "CG6",
        "rvalue": "Provider Management Team"
    },
    {
        "cgtype": "CG6",
        "rvalue": "COPS team member"
    },
    {
        "cgtype": "CG6",
        "rvalue": "Channel team member"
    },
    {
        "cgtype": "CG6",
        "rvalue": "UW team member"
    },
    {
        "cgtype": "CG7",
        "rvalue": "Claims Data Entry Operator"
    },
    {
        "cgtype": "CG7",
        "rvalue": "Disease Procedure Classifier"
    },
    {
        "cgtype": "CG7",
        "rvalue": "Disease Procedure Classification"
    },
     {
        "cgtype": "CG7",
        "rvalue": "Supervisor"
    },
    {
        "cgtype": "CG7",
        "rvalue": "Commercial Adjudicator"
    },
    {
        "cgtype": "CG7",
        "rvalue": "Payment User"
    },
    {
        "cgtype": "CG8",
        "rvalue": "Common Process"
    },
    {
        "cgtype": "CG8",
        "rvalue": "Cashless ( Preauthorization Stage), Cashless ( Post Preauth) & Reimbursement"
    },
    {
        "cgtype": "CG8",
        "rvalue": "Cashless ( Preauthorization Stage), Cashless ( Post Preauth) & Reimbursement"
    },
    {
        "cgtype": "CG9",
        "rvalue": "Cashless ( Pre Stage), Cashless ( Post Preauth) & Reimbursement"
    },
    {
        "cgtype": "CG9",
        "rvalue": "Claim Approval; Denial Approval; Closures"
    },
    {
        "cgtype": "CG10",
        "rvalue": "Auditor"
    },
    {
        "cgtype": "CG11",
        "rvalue": "MIS user"
    },
    {
        "cgtype": "CG12",
        "rvalue": "Read Only User"
    },
    {
        "cgtype": "CG12",
        "rvalue": "View Only User (UW, Call Centre)"
    },
    {
        "cgtype": "CG15",
        "rvalue": "TPA user"
    },
      {
        "cgtype": "CG16",
        "rvalue": "DEO"
    },
    {
        "cgtype": "CG51",
        "rvalue": "Data entry Queue"
    },
     {
        "cgtype": "CG51",
        "rvalue": "Coding Queue"
    },
     {
        "cgtype": "CG51",
        "rvalue": "Medical Scrutiny Queue"
    },
     {
        "cgtype": "CG51",
        "rvalue": "Commercial Scrutiny Queue"
    },
     {
        "cgtype": "CG51",
        "rvalue": "Payment Queue"
    },
     {
        "cgtype": "CG51",
        "rvalue": "Internal investigation"
    },
     {
        "cgtype": "CG51",
        "rvalue": "OFR"
    },
     {
        "cgtype": "CG51",
        "rvalue": "Quick empanelment Queue"
    },
     {
        "cgtype": "CG51",
        "rvalue": "Negotiation"
    },
     {
        "cgtype": "CG51",
        "rvalue": "CFR"
    },
      {
        "cgtype": "CG51",
        "rvalue": "Internal Investigation Team"
    },
      {
        "cgtype": "CG51",
        "rvalue": "Intenal Investigation Team"
    },
      {
        "cgtype": "CG51",
        "rvalue": "External Agency"
    },
     {
        "cgtype": "CG51",
        "rvalue": "External Resource New"
    },
    {
        "cgtype": "CG9",
        "rvalue": "Repairer"
    },
    {
        "cgtype": "CG10",
        "rvalue": "Case Manager"
    },
    {
        "cgtype": "BG101",
        "rvalue": "ROLE_ADJUDICATION_BOT_APPROVER"
    },
    {
        "cgtype": "BG102",
        "rvalue": "ROLE_ADJUDICATION_BOT_SUPERVISOR"
    },
    {
        "cgtype": "BG103",
        "rvalue": "ROLE_ADJUDICATION_BOT_PROCESSOR"
    },
    
],
            ROLETYPES:{
                Rolesdata:[]
            },
        selectionarr:[],
        selectedPermission:[],
        tdsConcessional:'',
        rolebtnvalue:false,
        editparams:false,
        token:null,
        updateID:'',
        valid:false,
        updateBtn:true,
        createBtn:false,
        rolehyperlink:'',
        // showbtn:'',
        activeBox:true,
        roleID:'',
        role:'',
        roleDescription:'',
        lob:"",
        selectRole:["Admin",'CG1','CG2','CG3','CG4','CG5','CG6','CG7','CG8','CG9','CG10',
        'CG11','CG12','CG13','CG14','CG15','CG16','CG17','CG18','CG19','CG20',
        'CG21','CG22','CG23','CG24','CG25','CG26','CG27','CG28','CG29','CG30','CG31','CG32','CG33','CG34','CG35','CG36','CG37','CG38','CG39','CG30',
        'CG41','CG42','CG43','CG44','CG45','CG46','CG47','CG48','CG49','CG50', 'CG51', 'CG52', 'BG101', 'BG102', 'BG103'],

        // selectLOB:['Health','PA','Marine'],
        GetLOBlist:['Health','PA','Marine'],
        permissionName:'',

        GetPermissionList:[],
        permissionNamelist:[],
        // selectPermissionName:  [   
        //                                     {'id': 1, 'name' :'Cliams Registration'},
        //                                     {'id': 1, 'name' :'Provider Search'},
        //                                     {'id': 1, 'name' :'Provider Registration'},
        //                                 ],
        selectedScreens:[],
        SelectedPermision:Boolean,
        hideSelectAll:true,
        allSelected:false,
        screenValues:[],
        screenAvilable:[],
        newSelectedScreens:[],
        abc:'',
        disableAll:true,

        // selectPermissionName:['Provider search','Network search','Network Setup'],
        
        rules: {
                required: value => !!value || 'This Field is Required.',

                requiredAction: value => { 
                    
                    if(this.editparams==true ){
                        
                     }else {
                         return !!value || 'This Field is Required'
                     }
       
                    // if(this.rolebtnvalue==true ){
                    //     return !!value || 'This Field is Required create.'
                    // }else if(this.editparams==true ){
                        
                    // }
                },

                requiredarr:  v=>v && v.length>0 || "This Field is Required",
                
                netname: value => {
                        const pattern = /^[a-zA-Z0-9 ]+$/g 
                        // return value.length <=0  ||
                        return pattern.test(value) || 'Only alphabets required.'
                },
                
            },
        marineRoles:[
  {
    "cgtype": "Admin",
    "rvalue": "IT Admin"
  },
  {
    "cgtype": "CG1",
    "rvalue": "Claims Ops"
  },
  {
    "cgtype": "CG2",
    "rvalue": "CCO"
  },
  {
    "cgtype": "CG3",
    "rvalue": "Line Head"
  },
  {
    "cgtype": "CG4",
    "rvalue": "ZCM"
  },
  {
    "cgtype": "CG5",
    "rvalue": "Master Maker"
  },
  {
    "cgtype": "CG6",
    "rvalue": "Normal Claims User"
  },
  {
    "cgtype": "CG7",
    "rvalue": "Call Centre"
  },
  {
    "cgtype": "CG8",
    "rvalue": "Master Checker"
  },
  {
    "cgtype": "CG9",
    "rvalue": "Contractual/Outsource"
  },
  {
    "cgtype": "CG49",
    "rvalue": "Nominated User"
  },
  {
    "cgtype": "CG50",
    "rvalue": "Corporate User"
  },
  {
    "cgtype": "CG12",
    "rvalue": "Tax Team"
  },
  {
    "cgtype": "CG13",
    "rvalue": "Auditor/Product Tower/IT MIS"
  },
  {
    "cgtype": "CG14",
    "rvalue": "Claim_MIS"
  },
  {
    "cgtype": "CG15",
    "rvalue": "Banking Ops"
  },
  {
    "cgtype": "CG 48",
    "rvalue": "External surveyor"
  }
],
        /*[{
            "cgtype": "CG2",
            "rvalue": "CCO"
        },
        {
            "cgtype": "CG3",
            "rvalue": "Line Head"
        },
        {
            "cgtype": "CG4",
            "rvalue": "Zonal Claims Manager"
        },
        {
            "cgtype": "CG5",
            "rvalue": "Master Maker"
        },
        {
            "cgtype": "CG1",
            "rvalue": "Admin"
        },
        {
            "cgtype": "CG6",
            "rvalue": "Normal Claims User"
        },
        {
            "cgtype": "CG8",
            "rvalue": "Master Checker"
        },
        {
            "cgtype": "CG12",
            "rvalue": "Tax Team"
        },
        {
            "cgtype": "CG9",
            "rvalue": "Contractual/Outsource"
        },
        {
            "cgtype": "CG49",
            "rvalue": "Nominated User"
        },
        {
            "cgtype": "CG50",
            "rvalue": "Corporate User"
        },
        {
            "cgtype": "CG7",
            "rvalue": "Call Centre"
        },
        {
            "cgtype": "CG13",
            "rvalue": "Auditor/Product Tower/IT MIS"
        },
        {
            "cgtype": "CG14",
            "rvalue": "Claim_MIS"
        },
        {
            "cgtype": "CG15",
            "rvalue": "Banking Ops"
        },
        {
            "cgtype": "CG1",
            "rvalue": "Claims Ops"
        }
        ],*/
        travelRoles:[
 {
   "cgtype": "Admin",
   "rvalue": "IT Admin"
 },
 {
   "cgtype": "CG1",
   "rvalue": "Claims Ops"
 },
 {
   "cgtype": "CG2",
   "rvalue": "CCO"
 },
 {
   "cgtype": "CG3",
   "rvalue": "Line Head"
 },
 {
   "cgtype": "CG4",
   "rvalue": "ZCM"
 },
 {
   "cgtype": "CG5",
   "rvalue": "Master Maker"
 },
 {
   "cgtype": "CG6",
   "rvalue": "Normal Claims User"
 },
 {
   "cgtype": "CG7",
   "rvalue": "Call Centre"
 },
 {
   "cgtype": "CG8",
   "rvalue": "Master Checker"
 },
 {
   "cgtype": "CG9",
   "rvalue": "Contractual/Outsource"
 },
 {
   "cgtype": "CG10",
   "rvalue": "Nominated User"
 },
 {
   "cgtype": "CG11",
   "rvalue": "Corporate User"
 },
 {
   "cgtype": "CG12",
   "rvalue": "Tax Team"
 },
 {
   "cgtype": "CG13",
   "rvalue": "Auditor/Product Tower/IT MIS"
 },
 {
   "cgtype": "CG14",
   "rvalue": "Claim_MIS"
 },
 {
   "cgtype": "CG15",
   "rvalue": "Banking Ops"
 }
],
         allrolesCM: [{
                            "cgtype": "CG1",
                            "rvalue": "Claims Ops"
                        },
                        {
                            "cgtype": "CG2",
                            "rvalue": "CCO"
                        },
                        {
                            "cgtype": "CG3",
                            "rvalue": "Line Head"
                        },
                        {
                            "cgtype": "CG4",
                            "rvalue": "ZCM"
                        },
                        {
                            "cgtype": "CG5",
                            "rvalue": "Master Maker"
                        },
                        
                        {
                            "cgtype": "CG6",
                            "rvalue": "Normal Claims User"
                        },
                        {
                            "cgtype": "CG8",
                            "rvalue": "Master Checker"
                        },
                        {
                            "cgtype": "CG12",
                            "rvalue": "Tax Team"
                        },
                        {
                            "cgtype": "CG48",
                            "rvalue": "Surveyors /Contractual /External"
                        },
                        {
                            "cgtype": "CG9",
                            "rvalue": "Contractual/Outsource"
                        },
                        {
                            "cgtype": "CG49",
                            "rvalue": "Nominated User"
                        },
                        {
                            "cgtype": "CG50",
                            "rvalue": "Corporate User"
                        },
                        {
                            "cgtype": "CG7",
                            "rvalue": "Call Centre"
                        },
                        {
                            "cgtype": "CG13",
                            "rvalue": "Auditor/Product Tower/IT MIS"
                        },
                        {
                            "cgtype": "CG14",
                            "rvalue": "Claim_MIS"
                        },
                        {
                            "cgtype": "CG15",
                            "rvalue": "Banking Ops"
                        },                        
                    ],

        homeRoles:[
{
    "cgtype": "Admin",
    "rvalue": "IT Admin"
},
{
    "cgtype": "CG1",
    "rvalue": "Claims Ops"
},
{
    "cgtype": "CG2",
    "rvalue": "CCO"
},
{
    "cgtype": "CG3",
    "rvalue": "Line Head"
},
{
    "cgtype": "CG4",
    "rvalue": "ZCM"
},
{
    "cgtype": "CG5",
    "rvalue": "Master Maker"
},
{
    "cgtype": "CG6",
    "rvalue": "Normal Claims User"
},
{
    "cgtype": "CG7",
    "rvalue": "Call Centre"
},
{
    "cgtype": "CG8",
    "rvalue": "Master Checker"
},
{
    "cgtype": "CG9",
    "rvalue": "Contractual/Outsource"
},
{
    "cgtype": "CG49",
    "rvalue": "Nominated User"
},
{
    "cgtype": "CG50",
    "rvalue": "Corporate User"
},
{
    "cgtype": "CG12",
    "rvalue": "Tax Team"
},
{
    "cgtype": "CG13",
    "rvalue": "Auditor/Product Tower/IT MIS"
},
{
    "cgtype": "CG14",
    "rvalue": "Claim_MIS"
},
{
    "cgtype": "CG15",
    "rvalue": "Banking Ops"
},
{
    "cgtype": "CG48",
    "rvalue": "External surveyor"
},
{
    "cgtype": "CG51",
    "rvalue": "Designated user"
},
{
    "cgtype": "CG52",
    "rvalue": "Regional Head"
}
],
        
    }},

     computed:{

        validation_oneitem(){

            if(this.selectionarr.length == 0){
                this.hideSelectAll = true
                return true
            }else{
                 this.hideSelectAll = false
               return false 
            }
          

        },

        filterselection(){
             return this.selectionarr
        },

        GetPermissionListFilter(){

            return this.GetPermissionList.filter(item => {
                // console.log(this.permissionName, "items of screen",item)
              if(!this.permissionName) return this.GetPermissionList;
                return (  
                    item.name.toLowerCase().includes(this.permissionName.toLowerCase())
                );
            });

        }
        
    }, 

    watch:{
        
        GetPermissionListFilter(val){
            if(this.permissionName.length != 0){
                console.log("value of %c GetPermissionList is","background-color:#aabbee;color:black",val);
                this.newSelectedScreens = val;
                // if(!this.editMode){
                //     this.screenValues = []
                // }
            }else{
                this.newSelectedScreens = []
                console.log("%c value of GetPermissionList is","background-color:#aabbee;color:black",val);
            }

            var arris = this.permissionName.length!=0? this.newSelectedScreens:this.GetPermissionList;
            this.setALLis(arris);
        }
    },

    methods: {

        filterole(type, indatacg, descval){
             this.ROLETYPES.Rolesdata = []
             let comparerole = type == 1 ? this.role : indatacg
             console.log("comparerole ", comparerole)
            if(comparerole=='CG1'||comparerole=="CG2"||comparerole=="CG3"||comparerole=="CG4"){
                // alert("role C!-CG4")
                this.ROLETYPES.Rolesdata =  this.allroles.filter(e=>{
                    if(e.cgtype == comparerole){
                    return e
                    }
                })
            } else if(comparerole=='BG101'||comparerole=="BG102"||comparerole=="BG103"){
                // alert("role C!-CG4")
                this.ROLETYPES.Rolesdata =  this.allroles.filter(e=>{
                    if(e.cgtype == comparerole){
                    return e
                    }
                })
            }else{
                 this.ROLETYPES.Rolesdata =  this.allroles.filter(e=>{
                    if(e.cgtype!="CG1" && e.cgtype!="CG2" && e.cgtype!="CG3" && e.cgtype!="CG4"){
                      return e
                    }
                })
            }
            

            if(type == 2){
                type == 2 ? this.role = indatacg : null

                this.ROLETYPES.Rolesdata.forEach(element => {
                    console.log(indatacg,"ele ", element)
                        if(element.cgtype == indatacg && element.rvalue == descval){

                            type == 2 ? this.roleDescription = element : null
                        }
                });
            }
          

           

            console.log("filtered results", this.ROLETYPES)

        },

        setALLis(arris){
            
             var d = 0
                arris.map(e=>{
                        if(e.sel){
                            d++
                        }
                })
                    if(d == arris.length){
                        console.log("select all should be CHECKED", arris)
                        this.allSelected = true
                    }else{
                        this.allSelected = false
                        console.log("select all should be DIS-CHECKED", arris)
                    } 
        },

         btnChange(){
             console.log('active box ----->',this.activeBox)

        //     if(this.activeBox== false){
        //             console.log('active box ----->',this.activeBox)

                    
        //             this.createBtn=true
        //             this.updateBtn=true
        //             console.log('1st active box createbtn----->',this.createBtn)

        //         }else if(this.activeBox== true){
        //             this.createBtn=false
        //             this.updateBtn=false
        //             console.log('2nd active box createbtn----->',this.createBtn)
        //         }

         },

        selectAll(value){

            let newData = [];
            newData=this.permissionName.length!=0?this.newSelectedScreens:this.GetPermissionList;

            if(this.allSelected){   
                console.log("newData is ", newData);
                for (let i in newData){
                    if(!newData[i].sel){
                        newData[i].sel = true
                      this.selectionarr.push(newData[i]);
                    }
                }
            }else{
              
                for (let i in newData) {
                      newData[i].sel = false
                     var sposs = this.selectionarr.findIndex(e=> e.name ==  newData[i].name)
                     if(sposs != -1){
                          this.selectionarr.splice(sposs, 1)
                     }   
                }

            }

           

            // console.log("select all screens value",value);
            // this.screenValues = []
            // if(this.allSelected){
            //     // newSelectedScreens

            //     let newData = this.permissionName.length!=0 ? this.newSelectedScreens : this.GetPermissionList

            //     for(let screen in this.GetPermissionList){
            //         this.screenValues.push(this.GetPermissionList[screen])
            //         console.log("select all screens",screen);
            //     }
            //     // console.log("selected permission all",this.screenValues,this.GetPermissionList);
            //     //      this.selectedScreens = this.screenValues
            // }
        },

        //Select ALl change is now used as single selection of checkbox from the first box
        selectAllChange(ind){

            this.allSelctd = false;
            console.log(ind,"obj is checked ----> ", this.GetPermissionList[ind].sel )
        //    this.settlementofficeLstItms[ind].sel = !this.settlementofficeLstItms[ind].sel
        //    this.$forceUpdate();
            console.log("single checked ----> ",this.newSelectedScreens);

            var whentopush = null
            var whattopush = null
             if(this.permissionName.length!=0){
                 whattopush = this.newSelectedScreens[ind]
             }else{
                 whattopush = this.GetPermissionList[ind]
             }
              console.log("whattopush ", whattopush);
            
            if(whattopush.sel){
                this.selectionarr.push(whattopush)
            }else{
                var wheretosplice = this.permissionName.length!=0 ? this.newSelectedScreens : this.GetPermissionList
                var poss =  this.selectionarr.findIndex(e=> e.name == wheretosplice[ind].name)
               if(poss != -1){
                   this.selectionarr.splice(poss, 1)
               }else{
                   console.log("element not found in selection array")
               }
            }   
              console.log("selection arr checked ----> ", this.selectionarr);

            var arris = this.permissionName.length!=0? this.newSelectedScreens:this.GetPermissionList;
            this.setALLis(arris);
            //  this.abc=chvalue
            // if(this.abc===true){
            //     this.hideSelectAll=false
            //     console.log('123456',this.abc)
            // }else{
            //     this.hideSelectAll=true
            // }

            // if(chvalue){
            //    console.log("value of selectAll is ",chvalue); 
            // }else{
            //     console.log("this is false value");
            //     this.selectedScreens = []
            //     this.selectedListOfPermission = []
            // }
        },

        changedValues(e,val){
            


            console.log("this is the value for change",e,val);
            console.log("this are arrays",this.selectedPermission,"\n",val);
                        console.log("length of arrays",e.length);

            this.selectedScreens = e
            // let indFound1 = this.selectedScreens.findIndex(scrAv => scrAv.screenName === val)
            // let indFound2 = this.upadteselectedScreens.findIndex(sel => sel.screenName === val)
            // let indFound3 = this.selectedScreens.findIndex(selPer => selPer.screenName === val)
            // this.selectedScreens.splice(indFound1,1)
            // this.upadteselectedScreens.splice(indFound2,1)
            // this.selectedScreens.splice(indFound3,1)
            // // this.selectedScreens.push(val)
            // // let indFound2 = this.selectedListOfPermission.find(sel => val.map( va => sel === va))
            // // this.selectedScreens.splice(indFound,1)
            // // this.selectedListOfPermission.splice(indFound2,1)
            // console.log("this is indFound",indFound1,indFound2,indFound3);
            // this.selectedScreens = [...val]

            // if(e.length > 1){
            //                 this.hideSelectAll = true
            //             }else{
            //                 this.hideSelectAll = false
            //             }
            if(e.length>0){
                this.hideSelectAll=false
            }else{
                this.hideSelectAll=true
            }
        },

        GetLOBlistData(){
                // return
                let self=this      
                 let theheaders = this.authHeaderNew();
                 theheaders.system = this.user_details().selectedLOB;
                
                // axios({
                //     method: "get",
                //     //  url:"http://claimsdrive-health.salesdrive.app/master-data/master-data-service/api/line-of-business/get-line-of-business-list",
                //      url:self.API_Service_MASTER()+"line-of-business/get-line-of-business-list",
                    
                //     headers: theheaders
                // })
                // .then(function (response) {
                //     let apiData = response.data.data[0].lineOfBusiness

                //     console.log("API RESPONSE for ALL Network:::::",apiData);
                  
                //     // self.GetLOBlist = []
                //     self.GetLOBlist= apiData
                //     console.log(" lob api responce---->>>>>>", self.GetLOBlist);

                //     // self.showToast(response.data.msg,self.TOST().SUCCESS)
                //     console.log("lob api success msg--->>>>>>", response.data.msg);
                                       
                // })
                // .catch(function (error) {
                //     console.log("lob list Error>>>>>>", error);
                //     self.showToast(error.response.data.msg,self.TOST().ERROR);
                //     self.showAlert(error.response.data.msg);
                // });
                
                this.GET(3,"line-of-business/get-line-of-business-list",(response,error)=> {
                    if(response){
                        let apiData = response.data.data[0].lineOfBusiness

                        console.log("API RESPONSE for ALL Network:::::",apiData);
                    
                        // self.GetLOBlist = []
                        self.GetLOBlist= apiData
                        console.log(" lob api responce---->>>>>>", self.GetLOBlist);

                        // self.showToast(response.data.msg,self.TOST().SUCCESS)
                        console.log("lob api success msg--->>>>>>", response.data.msg);
                    }else{
                        console.log("lob list Error>>>>>>", error);
                        self.showToast(error.response.data.msg,self.TOST().ERROR);
                        self.showAlert(error.response.data.msg);
                    }
                    
                                       
                })

                // this.GET(6,'role/get-role-descriptions',(res, error)=>{
                //     if(res != null){
                //         if(res.data.hasOwnProperty('data')){
                //            self.allroles = res.data.data
                //         }
                //     }
                // })
        },

        GetPermissionListData(indata, lobtype){
                // indata is incoming true or false =>m  if true => edit => call get by id else false dont call het by id, new creation
                if(this.lob =='EW'){
                    this.allroles = this.rolesforEW
                }else if(this.lob =='Marine' || this.lob =='AIGC' || this.lob =='PE'){
                    this.allroles   =   this.marineRoles
                }else if(this.lob == "Travel" || this.lob == "PA"){
                    this.allroles  =   this.travelRoles
                }else if(this.lob =='CM'){
                    this.allroles  =   this.allrolesCM
                }else if(this.lob =='Home'){
                    this.allroles  =   this.homeRoles
                }else{
                    this.allroles = this.allrolesNEW
                } 
                this.ROLETYPES.Rolesdata = []
                let self=this      
                // let demodata={
                //  }
                  let theheaders = this.authHeaderNew();
                  theheaders.system = this.user_details().selectedLOB;
                // axios({
                //     method: "get",
                //     //  url:"http://claimsdrive-health.salesdrive.app/master-data/master-data-service/api/permission-screens/get-permission-screens",
                //      url:self.API_Service_USER_MGMNT()+"permission/search-permission?lineOfBusiness="+lobtype+"&isActive=Active",
                //     headers: theheaders
                //     // data:demodata,
                // })
                // .then(function (response) {
                //     let apiData = response.data.data.data

                //     console.log("API RESPONSE for Permission list:::::",apiData)
                  
                //     self.GetPermissionList = []
                //     self.GetPermissionList= apiData.map(data => {
                //                  return {name:data.permissionName  , sel:false, ...data   }
                             
                //     })
                //     console.log(" permission list api responce---->>>>>>", self.GetPermissionList);

                //     // self.showToast(response.data.msg,self.TOST().SUCCESS)
                //     console.log("permission list api success msg--->>>>>>", response.data.msg);
                //     self.selectionarr = []
                //     if(indata){
                //         self.GetDataById();
                //     }
                  
                                       
                // })
                // .catch(function (error) {
                //     console.log("permission list list Error>>>>>>", error);
                //     self.showToast(error.response.data.msg,self.TOST().ERROR);
                //     self.showAlert(error.response.data.msg);
                // });
            
                this.GET(0,self.API_Service_USER_MGMNT()+"permission/search-permission?lineOfBusiness="+lobtype+"&isActive=Active",function(response,error){
                    if(response){
                        let apiData = response.data.data.data

                    console.log("API RESPONSE for Permission list:::::",apiData)
                  
                    self.GetPermissionList = []
                    self.GetPermissionList= apiData.map(data => {
                                 return {name:data.permissionName  , sel:false, ...data   }
                             
                    })
                    console.log(" permission list api responce---->>>>>>", self.GetPermissionList);

                    // self.showToast(response.data.msg,self.TOST().SUCCESS)
                    console.log("permission list api success msg--->>>>>>", response.data.msg);
                    self.selectionarr = []
                    if(indata){
                        self.GetDataById();
                    }
                    }else{
                        console.log("permission list list Error>>>>>>", error);
                        self.showToast(error.response.data.msg,self.TOST().ERROR);
                        self.showAlert(error.response.data.msg);
                    }
                })
        },

        GetDataById(){
                console.log(" role get by id---->>>>>>");

                // alert("check permissionIds !")

                let self=this
                
                 let demodata={
                //     isActive:self.activeBox,
                //     // roleId:self.roleID,
                //     roleName:self.role,
                //     roleDescription:self.roleDescription,
                //     lineOfBusiness:self.lob,
                //     permissionIds:self.permissionName
        
                 }
                // console.log("from demodata--------->",demodata);
                  let theheaders = this.authHeaderNew();
                  theheaders.system = this.user_details().selectedLOB;
                // axios({
                //     method: "get",
                //     //  url:"http://claimsdrive-health.salesdrive.app/user-management/user-management-service/api/role/get-role-by-id/"+self.rolehyperlink,
                //      url:self.API_Service_USER_MGMNT()+"role/get-role-by-id/"+self.rolehyperlink,
                    
                //     headers:theheaders,
                //     data:demodata
                // })
                // .then(function (response) {
                //     console.log(" role get by id api responce---->>>>>>", response);
                    
                //     self.lob=response.data.data.lineOfBusiness
                //     self.filterole(2, response.data.data.roleName, response.data.data.roleDescription)
                //       self.roleID=response.data.data.roleId
                //     //   self.role=response.data.data.roleName

                //     //   self.roleDescription=response.data.data.roleDescription
                      
                //     // self.permissionName=response.data.data.permissionIds
                //     // self.selectedScreens=response.data.data.permissionIds
                //     // self.selectedPermission = response.data.data.permissionIds
                //     // self.screenValues =  response.data.data.permissionIds
                // //  return
                //     var permissionid = response.data.data.permissionIds
                //         self.GetPermissionList.map(data => {
                //             permissionid.map(e=>{
                //                     if(e._id == data._id){
                //                         console.log("data is ", data)
                //                         data.sel = true
                //                         self.selectionarr.push(data)
                //                     }
                //             })
                //         })
                //     console.log("what is selectionArr ", self.selectionarr)
                //         self.activeBox=response.data.data.isActive === 'Active' ? true : false
                //         self.filterole(2, response.data.data.roleName, response.data.data.roleDescription)

                //     console.log("get by id role id --->>>>>>", self.roleID);


                //     self.showToast(response.data.msg,self.TOST().SUCCESS)
                //     self.showAlert(response.data.msg);
                //     console.log("role create api success msg--->>>>>>", response.data.msg);
                    
                   
                // })
                // .catch(function (error) {
                //     console.log("role create Error>>>>>>", error);
                //     self.showToast(error.response.data.msg,self.TOST().ERROR);
                //     self.showAlert(error.response.data.msg);
                // });

                this.GET(0,self.API_Service_USER_MGMNT()+"role/get-role-by-id/"+self.rolehyperlink,function(response,error){
                    if(response){
                        console.log(" role get by id api responce---->>>>>>", response);
                    
                        self.lob=response.data.data.lineOfBusiness
                        self.filterole(2, response.data.data.roleName, response.data.data.roleDescription)
                        self.roleID=response.data.data.roleId

                        if(self.lob =='EW'){
                            self.allroles = self.rolesforEW
                        }else if(self.lob =='Marine'  || self.lob =='AIGC' || self.lob =='PE'){
                            self.allroles   =   self.marineRoles
                        }else if(self.lob == "Travel" || self.lob == "PA"){
                            self.allroles  =   self.travelRoles
                        }else if(self.lob =='CM'){
                            self.allroles  =   self.allrolesCM
                        }else if(self.lob =='Home'){
                            self.allroles  =   this.homeRoles
                        }else{
                            self.allroles = self.allrolesNEW
                        } 
                        //   self.role=response.data.data.roleName

                        //   self.roleDescription=response.data.data.roleDescription
                        
                        // self.permissionName=response.data.data.permissionIds
                        // self.selectedScreens=response.data.data.permissionIds
                        // self.selectedPermission = response.data.data.permissionIds
                        // self.screenValues =  response.data.data.permissionIds
                    //  return
                        var permissionid = response.data.data.permissionIds
                            self.GetPermissionList.map(data => {
                                permissionid.map(e=>{
                                        if(e._id == data._id){
                                            console.log("data is ", data)
                                            data.sel = true
                                            self.selectionarr.push(data)
                                        }
                                })
                            })
                        console.log("what is selectionArr ", self.selectionarr)
                            self.activeBox=response.data.data.isActive === 'Active' ? true : false
                            self.filterole(2, response.data.data.roleName, response.data.data.roleDescription)

                        console.log("get by id role id --->>>>>>", self.roleID);


                        self.showToast(response.data.msg,self.TOST().SUCCESS)
                        self.showAlert(response.data.msg);
                        console.log("role create api success msg--->>>>>>", response.data.msg);
                        
                    }else{
                        console.log("role create Error>>>>>>", error);
                        self.showToast(error.response.data.msg,self.TOST().ERROR);
                        self.showAlert(error.response.data.msg);
                    }
                })
            
        },

        submit(){
            
            if(this.$refs.netform.validate()){
                     if (this.hideSelectAll=== true){
                            this.showToast("Enter valid values in all the required fields.",this.TOST().WARNING);
                            self.showAlert("Enter valid values in all the required fields.");
                         return
                     }
                let self=this
    
                let demodata={
                    isActive:self.activeBox=== true ? 'Active' : 'InActive',
                    // roleId:self.roleID,
                    roleName:self.role,
                    roleDescription:self.roleDescription.rvalue,
                    lineOfBusiness:self.lob,
                    permissionIds: [ ...self.selectionarr.map(e=> e._id)],
                }

                 let theheaders = this.authHeaderNew();
                 theheaders.system = this.user_details().selectedLOB;
                console.log("from demodata--------->",demodata);
                // axios({
                //     method: "post",
                //     //  url:"http://claimsdrive-health.salesdrive.app/user-management/user-management-service/api/role/create-new-role",
                //      url:self.API_Service_USER_MGMNT()+"role/create-new-role",
                    
                //     headers: theheaders,
                //     data:demodata
                // })
                // .then(function (response) {
                //     console.log(" role api responce---->>>>>>", response);
                //     //   self.$store.commit("NETWORK_DATA",response.data.data)
                //       self.roleID=response.data.data.roleId

                //   console.log("role id --->>>>>>", self.roleID);


                //     self.showToast(response.data.msg,self.TOST().SUCCESS)
                //     self.showAlert(response.data.msg)
                //     console.log("role create api success msg--->>>>>>", response.data.msg);
                //      self.$router.push('/RoleSearch')
                   
                // })
                // .catch(function (error) {
                //     console.log("role create Error>>>>>>", error);
                //     self.showToast(error.response.data.msg,self.TOST().ERROR);
                //     self.showAlert(error.response.data.msg);
                // });
//self.API_Service_USER_MGMNT()+
                this.POST(6,"role/create-new-role",demodata,function (response,error) {
                    if(!error){
                        console.log(" role api responce---->>>>>>", response);
                        //   self.$store.commit("NETWORK_DATA",response.data.data)
                        self.roleID=response.data.data.roleId

                     console.log("role id --->>>>>>", self.roleID);

                        self.showToast(response.data.msg,self.TOST().SUCCESS)
                        self.showAlert(response.data.msg)
                        console.log("role create api success msg--->>>>>>", response.data.msg);
                        self.$router.push('/RoleSearch')
                    }else{
                        console.log("role create Error>>>>>>", error);
                        self.showToast(error.response.data.msg,self.TOST().ERROR);
                        self.showAlert(error.response.data.msg);
                    }
                })
            
            }else{
                this.$refs.netform.validate()
                this.showToast("Enter valid values in all the required fields.",this.TOST().WARNING);
                this.showAlert("Enter valid values in all the required fields.");
            }
        },

        save(id){
            
              console.log('incoming put api id is---->',this.rolehyperlink)
             if (this.$refs.netform.validate() ){
                  if (this.hideSelectAll=== true){
                            this.showToast("Enter valid values in all the required fields.",this.TOST().WARNING);
                            this.showAlert("Enter valid values in all the required fields.");
                         return
                     }

                 
                let self=this
            
                let demodata={
                    isActive:self.activeBox=== true ? 'Active' : 'InActive',
                     roleId:self.roleID,
                    roleName:self.role,
                    roleDescription:self.roleDescription.rvalue,
                    lineOfBusiness:self.lob,
                    permissionIds:[ ...self.selectionarr.map(e=> e._id)],

                }
                console.log("from demodata--------->",demodata);
                 let theheaders = this.authHeaderNew();
                 theheaders.system = this.user_details().selectedLOB;
                // axios({
                //     method: "put",
                //     // url:"http://claimsdrive-health.salesdrive.app/user-management/user-management-service/api/role/update-role-details/"+self.rolehyperlink,
                //      url:self.API_Service_USER_MGMNT()+"role/update-role-details/"+self.rolehyperlink,
                    
                //     headers:theheaders,
                //     data:demodata
                // })
                // .then(function (response) {
                //     console.log(" role update api responce---->>>>>>", response);
                        
                //     self.showToast(response.data.msg,self.TOST().SUCCESS)
                //     self.showAlert(response.data.msg)
                //     console.log("role update api success msg--->>>>>>", response.data.msg);
                //     self.$router.push('/RoleSearch')
                    
                    
                   
                // })
                // .catch(function (error) {
                //     console.log("role update Error>>>>>>", error);
                //     self.showToast(error.response.data.msg,self.TOST().ERROR);
                //     self.showAlert(error.response.data.msg);
                // });

                this.PUT(6,"role/update-role-details/"+self.rolehyperlink,demodata,function(response,error){
                    if(response){
                        console.log(" role update api responce---->>>>>>", response);
                        
                        self.showToast(response.data.msg,self.TOST().SUCCESS)
                        self.showAlert(response.data.msg)
                        console.log("role update api success msg--->>>>>>", response.data.msg);
                        self.$router.push('/RoleSearch')
                    }else{
                         console.log("role update Error>>>>>>", error);
                        self.showToast(error.response.data.msg,self.TOST().ERROR);
                        self.showAlert(error.response.data.msg);
                    }
                })
            }else{
                this.$refs.netform.validate()
                this.showToast("Enter valid values in all the required fields.",this.TOST().WARNING);
                this.showAlert("Enter valid values in all the required fields.");
            }
        },

        closebtn(){
            this.$router.push('/home')

        },
    
    }   
}

</script>
<style scoped>

.cust-rmvpd-pr .v-text-field.v-text-field--enclosed>.v-input__control>.v-input__slot {
    padding: 0px !important;;
}

.cstm-clr-pr-hd{
    font-size: 14px;
}

.hideIcon .v-input__control .v-input__slot .v-select__slot .v-input__append-inner .v-input__icon--append{
    display: none;
}

.hideIcon .v-input__control .v-input__slot{
    padding-right: 0 !important
}
.v-messages__wrapper {
    margin-top: 4px;
    position: relative;
    top: 15px;
    
}
.padTop-13  .v-input--selection-controls{
  padding-top: 13px;
}


</style>