 <template>
    <v-app>
        <v-card  tile>
            <div class="">
                <v-form v-model="valid" ref="netform">
                    <v-layout align-center class="py-5 px-3" style="background-color: #F7F7F7;">
						<v-text class="text-uppercase font-weight-bold  ml-2">Provider Search</v-text>
                        <v-divider vertical class="mx-4"></v-divider>
						<v-text class="font-weight-bold  ml-2" >Registered Entity</v-text>
                        <v-divider vertical class="mx-4"></v-divider>

                        <v-card  tile>   
                            <v-btn-toggle mandatory v-model="toggelBtn" class="" style=""  >
                                <v-btn large value="Individual" class="text-capitalize font-weight-bold px-5"
                                    @click="showIndividualData()" style="white-space: normal;"
                                :class="[showIndividual === true ? 'activebackground' : 'inactivewhitebackground']">Individual
                                </v-btn>

                                <v-btn large value="Institution" class="text-capitalize font-weight-bold px-5"
                                    @click="showInstitutionData()" style="white-space: normal;"
                                :class="[showInstitution === true ? 'activebackground' : 'inactivewhitebackground']">Institution
                                </v-btn>    
                            </v-btn-toggle>
                        </v-card>
                    </v-layout>
                    <v-divider ></v-divider>
                    <v-layout class="ma-4" style="background-color: #FFFFFF;" wrap>
                        <v-flex xs3 v-if="toggelBtn == 'Institution'" >
                            <v-layout column class="cust_pr mx-3 mb-2">
                                <p class="font-weight-bold cstm-clr-pr">ROHINI ID</p>
                                <v-text-field
                                    solo
                                    label="Enter..."
                                    v-model="addProvRohiniId"
                                    class="rounded-0"
                                    maxlength="13"
                                    :rules="[v => !!v || '',v => /^[0-9]{13}$/.test(v) || 'Invalid ROHINI ID']">
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3 v-if="toggelBtn == 'Individual'">
                            <v-layout column class="cust_pr mx-3 mb-2">
                                <p class="font-weight-bold cstm-clr-pr">First Name Filter</p>
                                <v-select
                                    :items="entityFiltArray"
                                    label="Select"
                                    v-model="addProvFirstNameFilt"
                                    solo
                                    maxlength="50"
                                    class="rounded-0">
                                </v-select>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3 v-if="toggelBtn == 'Individual'">
                            <v-layout column class="cust_pr mx-3 mb-2">
                                <p class="font-weight-bold cstm-clr-pr">First Name</p>
                                <v-text-field
                                    solo
                                    label="Enter..."
                                    v-model="addProvFirstName"
                                    class="rounded-0"
                                    maxlength="100">
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3 v-if="toggelBtn == 'Individual'">
                            <v-layout column class="cust_pr mx-3 mb-2">
                                <p class="font-weight-bold cstm-clr-pr">Last Name Filter</p>
                                <v-select
                                    :items="entityFiltArray"
                                    label="Select"
                                    v-model="addProvLastNameFilt"
                                    solo
                                    maxlength="50"
                                    class="rounded-0">
                                </v-select>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3 v-if="toggelBtn == 'Individual'">
                            <v-layout column class="cust_pr mx-3 mb-2">
                                <p class="font-weight-bold cstm-clr-pr">Last Name</p>
                                <v-text-field
                                    solo
                                    label="Enter..."
                                    v-model="addProvLastName"
                                    class="rounded-0"
                                    maxlength="100">
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                        
                        <v-flex xs3 v-if="toggelBtn == 'Institution'">
                            <v-layout column class="cust_pr mx-3 mb-2">
                                <p class="font-weight-bold cstm-clr-pr">Entity Name Filter</p>
                                <v-select
                                    :items="entityFiltArray"
                                    label="Select"
                                    v-model="addProvEntityNameFilt"
                                    solo
                                    maxlength="50"
                                    class="rounded-0">
                                </v-select>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3 v-if="toggelBtn == 'Institution'">
                            <v-layout column class="cust_pr mx-3 mb-2">
                                <p class="font-weight-bold cstm-clr-pr">Entity Name</p>
                                <v-text-field
                                    solo
                                    label="Enter..."
                                    v-model="addProvEntityName"
                                    class="rounded-0"
                                    maxlength="100">
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3 >
                            <v-layout column class="cust_pr mx-3 mb-2">
                                <p class="font-weight-bold cstm-clr-pr">PRN</p>
                                <v-text-field
                                    solo
                                    label="Enter..."
                                    v-model="addProvPRN"
                                    class="rounded-0"
                                    maxlength="10" 
                                    :rules="[v => !!v || '',v => /^(([prnPRN]){3}([0-9]){7}?)+$/g.test(v) || 'Enter valid PRN']">
                                </v-text-field>
                                    <!-- /^[A-Za-z0-9]{10}$/ === /^[A-Za-z0-9]+$/.test(v) -->
                            </v-layout>
                        </v-flex>
                        <v-flex xs3 >
                            <v-layout column class="cust_pr mx-3 mb-2">
                                <p class="font-weight-bold cstm-clr-pr">Registration Number</p>
                                <v-text-field
                                    solo
                                    label="Enter..."
                                    v-model="addProvRegNum"
                                    maxlength="50"
                                    class="rounded-0">
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3 >
                            <v-layout column class="cust_pr mx-3 mb-2">
                                <p class="font-weight-bold cstm-clr-pr">Provider Type</p>
                                <v-select
                                    :items="providerTypeArray"
                                    label="Select"
                                    v-model="addProvProvidrType"
                                    solo
                                    maxlength="20"
                                    class="rounded-0">
                                </v-select>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3 >
                            <v-layout column class="cust_pr mx-3 mb-2">
                                <p class="font-weight-bold cstm-clr-pr">PAN</p>
                                <v-text-field
                                    solo
                                    label="Enter..."
                                    v-model="addProvPan"
                                    class="rounded-0"
                                    maxlength="10"
                                    :rules="[v => !!v || '',v => /[A-Z]{5}[0-9]{4}[A-Z]{1}/.test(v) || 'Incorrect PAN']">
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3 v-if="toggelBtn == 'Individual'">
                            <v-layout column class="cust_pr mx-3 mb-2">
                                <p class="font-weight-bold cstm-clr-pr">Registered Council</p>
                                <v-select
                                    :items="selectRegisteredCouncil"
                                    label="Select"
                                    v-model="addProvRegisterCouncil"
                                    solo
                                    maxlength="50"
                                    class="rounded-0">
                                </v-select>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3 v-if="toggelBtn == 'Individual'">
                            <v-layout column class="cust_pr mx-3 mb-2">
                                <p class="font-weight-bold cstm-clr-pr">Mobile Number</p>
                                <v-text-field
                                    solo
                                    label="Enter..."
                                    v-model="addProvMobNum"
                                    maxlength="50"
                                    :rules="[v => !!v || '',v => /^[0-9]{10}$/.test(v) || 'Incorrect Mobile Number']"
                                    class="rounded-0">
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3 v-if="toggelBtn == 'Institution'">
                            <v-layout column class="cust_pr mx-3 mb-2">
                                <p class="font-weight-bold cstm-clr-pr">GSTIN</p>
                                <v-text-field
                                    solo
                                    label="Enter..."
                                    v-model="addProvGstIn"
                                    class="rounded-0"
                                    maxlength="50"
                                    :rules="[v => !!v || '',v => /^[a-zA-Z0-9]+$/.test(v) || 'Invalid GSTIN number',v => v.length == 15 || 'Invalid GSTIN number']">
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3 v-if="toggelBtn == 'Institution'">
                            <v-layout column class="cust_pr mx-3 mb-2">
                                <p class="font-weight-bold cstm-clr-pr">Provider Status</p>
                                <v-text-field
                                    solo
                                    label="Empaneled"
                                    v-model="addProvProvidrStatus"
                                    disabled
                                    background-color="#f7f7f7"
                                    class="rounded-0"
                                    maxlength="100">
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                    </v-layout>
                    <v-divider ></v-divider>

                    <v-layout class="ma-4" style="background-color: #FFFFFF;" wrap>
                        <v-flex xs3 >
                            <v-layout column class="cust_pr mx-3 ">
                                <p class="font-weight-bold cstm-clr-pr">Address Contains</p>
                                <v-text-field
                                    solo
                                    label="Enter..."
                                    v-model="addProvAddressCont"
                                    class="rounded-0"
                                    maxlength="100"
                                    :rules="[ v => !!v || '',v => v.length >= 3 || 'Enter minimum 3 characters ']">
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                        
                        <!-- <v-flex xs3>
                            <v-layout column class="cust_pr mx-3">
                                <p class="font-weight-bold cstm-clr-pr">Pincode</p>
                                <v-autocomplete
                                    v-model="addProvPincode"
                                    :items="pinItems"
                                    :search-input.sync="searchPinCode"
                                    color="white"
                                    hide-no-data
                                    cache-items
                                    item-text="pin"
                                    item-value="pin"
                                    placeholder="Start typing to Search"
                                    @change="getPincode(addProvPincode, 'from change')"
                                    :rules="[() => !!addProvPincode || 'This field is required']"
                                    return-object
                                    maxlength='6'
                                    class="rounded-0"
                                    solo
                                ></v-autocomplete>
                            </v-layout>
                        </v-flex> -->
                        <v-flex xs3 >
                            <v-layout column class="cust_pr mx-3 ">
                                <p class="font-weight-bold cstm-clr-pr">State</p>
                                <v-select
                                    :items="stateItems"
                                    label="Select"
                                    v-model="addProvState"
                                    item-text="state"
                                    item-value="state"
                                    solo
                                    class="rounded-0"
                                    maxlength="50">
                                </v-select>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3>
                            <v-layout column class="cust_pr mx-3">
                                <v-text class="font-weight-bold cstm-clr-pr">City/Town/Village</v-text>
                                <v-text-field
                                    v-model="addProvCityTown"
                                    label="Enter..."
                                    solo
                                    counter="50"
                                    maxlength="100"
                                    class="rounded-0"
                                ></v-text-field>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3 >
                            <v-layout column class="cust_pr mx-3 ">
                                <p class="font-weight-bold cstm-clr-pr">Pincode</p>
                                <v-text-field
                                    solo
                                    label="Enter..."
                                    v-model="addProvPincode"
                                    maxlength="6"
                                    class="rounded-0"
                                    :rules="[v => !!v || '',v => /^[0-9]{6}$/.test(v) || 'Incorrect Pincode']">
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                        
                        <v-flex xs3>
                            <v-layout column class="cust_pr mx-3 mt-2">
                                <p class="font-weight-bold cstm-clr-pr">District</p>
                                <v-text-field
                                    :items="districtItems"
                                    solo
                                    label="Enter..."
                                    v-model="addProvDistrict"
                                    maxlength="50"
                                    class="rounded-0">
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                        
                    </v-layout>
                </v-form>
            </div>
            <v-divider ></v-divider>
            <div>
                <v-layout class="ml-3 mt-3 mb-3"  style="align-items:center;">
                    <v-flex xs3 >
                        <v-layout column class="cust_pr mx-3 ">
                            <p class="font-weight-bold cstm-clr-pr">Ownership Type</p>
                            <v-select
                                :items="ownerItemsArray"
                                label="Select"
                                v-model="addProvOwnerType"
                                solo
                                class="rounded-0"
                                maxlength="20">
                            </v-select>
                        </v-layout>
                    </v-flex>
                    
                    <v-flex xs3 >
                        <v-layout column class="cust_pr mx-3 ">
                            <p class="font-weight-bold cstm-clr-pr">Clinical Specialities</p>
                            <v-select
                                :items="clinicalItemsArray"
                                label="Select"
                                v-model="addProvClinicSpec"
                                solo
                                class="rounded-0"
                                maxlength="20">
                            </v-select>
                        </v-layout>
                    </v-flex>
                    <v-btn tile color="#fff" @click="clearData()" class="text-capitalize px-3 mx-3" small>Clear all <v-divider vertical class="mx-2"></v-divider><v-icon dark small>close</v-icon></v-btn>
                    <v-btn dark @click="searchData(1)" tile color="#e46a25" class="text-capitalize px-3 mx-3" small><v-icon dark small>search</v-icon> <v-divider vertical class="mx-2"></v-divider>Search</v-btn>
                    <v-btn dark @click="$router.push('/NetworkManagement')" tile color="#23B1A9" class="text-capitalize px-3 mx-3" small><v-icon dark small>arrow_back</v-icon> <v-divider vertical class="mx-2"></v-divider>Return</v-btn>
                    <v-btn dark @click="$router.push('/emp-bulk-upload')" tile color="#10242b" class="text-capitalize px-3 mx-3" small><v-icon dark small>backup</v-icon> <v-divider vertical class="mx-2"></v-divider>Bulk Upload</v-btn>
                </v-layout>
            </div>
        </v-card> 

        <v-card class="mt-3 mb-3" tile>
            <v-divider></v-divider>
            <div class="disp-flex pa-3" style="justify-content:center;background-color:#f7f7f7">
                <v-text class="font-weight-bold">PROVIDER LIST</v-text>
            </div>
            <v-divider></v-divider>
            <v-data-table
                :headers="headers"
                :items="provTblData"
                hide-default-footer
                v-model="selectTableData"
                show-select
                return-object
                item-key="prn"
                @input="providerTableData"
                @item-selected="selectDataAfterCheck"
                disable-pagination>
                <template v-slot:item.data-table-select="{isSelected,select,item}">
                    <v-simple-checkbox 
                        :readonly="item.disableCheck" 
                        :disabled="item.disableCheck" 
                        color="#23B1A9" 
                        :ripple="false" 
                        :value="isSelected" 
                        @input="select($event)">
                    </v-simple-checkbox>
                </template>
                <template  v-slot:item.prn={item}>
                    <tr ><td style="color:#e46a25;cursor:pointer" @click="gotoProvSummary(item)" class="font-weight-bold" >{{ item.prn}} </td></tr>
                </template>
                <template  v-slot:item.provName={item}>
                    <tr ><td  >{{ item.provName}} </td></tr>
                </template>
                
            </v-data-table>
            <v-divider></v-divider>
            <v-layout row justify-end align-center style="background-color:#f7f7f7" class="pa-2">
                <span class="mr-1 font-weight-bold body-1" style="color:#3C3D3D; font-size:12px !important">
                <span class="" style="color:#E46A25;font-size:12px;">Showing {{fval}}-{{sval}} </span>out of {{totalLen}} records</span>
                <v-divider  class="ma-2" vertical ></v-divider>
                <v-pagination
                    class="pdct-stUp-bnkAcc-pgn"
                    v-model="pageInsti"
                    color="#152F38"
                    flat
                    @input="nextPageData(pageInsti)"
                    :length="pagelength"
                ></v-pagination>
            </v-layout>
        </v-card>
        <v-divider></v-divider>
        <v-card tile class="mb-3 pa-4" style="background-color:#F7F7F7">
            <v-layout style="justify-content: flex-end;" >
                <v-btn dark @click="$router.push('/NetworkManagement')" tile color="#23B1A9" class="text-capitalize px-3 mx-3" small><v-icon dark small>arrow_back</v-icon> <v-divider vertical class="mx-2"></v-divider>Return</v-btn>
                <v-btn dark @click="updateProvData()" tile color="#10242b" class="text-capitalize px-3 mx-3" small><v-icon dark small>update</v-icon> <v-divider vertical class="mx-2"></v-divider>Update Provider List</v-btn>
            </v-layout>
        </v-card>

        <!-- </v-flex> -->
    </v-app>
</template>
<script>

import moment from 'moment';
import axios from "axios";


export default {

    components:{
      
    },

    created() {
        this.$store.commit("SAVE_PAGE_HEADER", "Add Providers");
        this.providerTypeArray = this.instiProviderType
        this.getInstiProviderData(1)
        this.getStatesData()
        // this.getOwnershipData()
        this.getClinicalData()
        // this.getIndivProviderData(1)
    },

    computed: {
        computedTarrEffectDate(){
            return this.createTarrEffectDate ? moment(this.createTarrEffectDate).format("DD-MM-YYYY"): ''
        },
        computedTarrExpireDate(){
            return this.createTarrExpireDate ? moment(this.createTarrExpireDate).format("DD-MM-YYYY"): ''
        },
        pinItems(){
            return this.pinItemArray 
        },
    },

    data() {
        return {
            finalTableSelectdData:[],
            addProvFirstNameFilt:'',
            addProvFirstName:'',
            addProvLastNameFilt:'',
            addProvLastName:'',
            addProvRegisterCouncil:'',
            addProvMobNum:'',
            ownerItemsArray:[],
            clinicalItemsArray:[],
            toggelBtn:'Institution',
            pageInsti: 1,
            pagelength:0,
            fval:0,
            sval:0,
            totalLen:0,
            swap_final_count:false,
            typesearch:false,
            selectTableData:[],
            provTblData:[],
            headers:[
                {text: 'PRN', value: 'prn'},
                {text: 'Provider Name', value: 'provName'},
                {text: 'Provider Type', value: 'type'},
                {text: 'Status', value: 'status'},
                {text: 'Alias Name', value: 'alias'},
                {text: 'Rohini ID', value: 'rohini'},
                {text: 'Address', value: 'address'},
                {text: 'City/Town', value: 'city'},
                {text: 'State', value: 'state'},
                // {text: 'Action', value: 'action', sortable: false}
            ],
            tableData:[
                {
                    prn:'112222111',
                    provName:'Idea Hospital',
                    type:'Hospital',
                    status:'Registered',
                    alias:'XYZ',
                    rohini:'2422121289012',
                    address:'Chembur',
                    city:'Mumbai',
                    state:'Maharashtra',
                },{
                    prn:'24221212',
                    provName:'Reliance Hospital',
                    type:'Hospital',
                    status:'Registered',
                    alias:'XYZ',
                    rohini:'2422121289012',
                    address:'Chembur',
                    city:'Mumbai',
                    state:'Maharashtra',
                },{
                    prn:'24221212',
                    provName:'Airtel Hospital',
                    type:'Hospital',
                    status:'Registered',
                    alias:'XYZ',
                    rohini:'2422121289012',
                    address:'Chembur',
                    city:'Mumbai',
                    state:'Maharashtra',
                },{
                    prn:'24221212',
                    provName:'Kha Hospital',
                    type:'Hospital',
                    status:'Registered',
                    alias:'XYZ',
                    rohini:'2422121289012',
                    address:'Chembur',
                    city:'Mumbai',
                    state:'Maharashtra',
                },{
                    prn:'24221212',
                    provName:'Voda Hospital',
                    type:'Hospital',
                    status:'Registered',
                    alias:'XYZ',
                    rohini:'2422121289012',
                    address:'Chembur',
                    city:'Mumbai',
                    state:'Maharashtra',
                },
            ],
            showIndividual:false,
            showInstitution:true,
            ownerItemsArray:["Individual Proprietorship","Registered Partnership","Company - Private Limited","Company - Public Limited","Trust / Charitable","Co-Operative Society","Central Government","State Government","Local Government","Public Sector Undertaking","Any Other"],
            entityFiltArray:['Contains','Starts With','Ends With','Equals',],
            indiProviderType:['Doctor','Dentist','Physiotherapist','Homeopath','Ayurveda','Nurse','Psychotherapist','Other'],
            instiProviderType:['Hospital','Daycare Center','Nursing Home','Clinic','Rehabilitation Center','Pharmacy',
                                'Diagnostic Centre','Home Care provider','Hospice','Ambulance','Air Ambulance','Telehealth Provider',
                                'Psychiatric Facility','Other'
            ],
            selectRegisteredCouncil:[
                'Himanchal Pradesh Medical Council',
                'Jammu & Kashmir Medical Council',
                'Jharkhand Medical Council',
                'Karnataka Medical Council',
                'Travancore Cochin Medical Council',
                'Madhya Pradesh Medical Council',
                'Maharashtra Medical Council',
                'Manipur Medical Council',
                'Mizoram Medical Council',
                'Nagaland Medical Council',
                'Orissa Council of Medical Registration',
                'Punjab Medical Council',
                'Rajasthan Medical Council',
                'Sikkim Medical Council',
                'Tamil Nadu Medical Council',
                'Telangana State Medical Council',
                'Tripura State Medical Council',
                'Uttar Pradesh Medical Council',
                'Uttarakhand Medical Council',
                'West Bengal Medical Council',
                'Central Council of Indian Medicine',
                'Others'
            ],
            providerTypeArray:[],
            addProvRohiniId:'',
            addProvEntityName:'',
            addProvEntityNameFilt:'',
            addProvPRN:'',
            addProvRegNum:'',
            addProvProvidrType:'',
            addProvPan:'',
            addProvGstIn:'',
            addProvProvidrStatus:'Empaneled',
            addProvAddressCont:'',
            addProvState:'',
            addProvCityTown:'',
            addProvPincode:'',
            addProvDistrict:'',
            addProvClinicSpec:'',
            addProvOwnerType:'',

            search_area_pageno:1,
            area_page_no:1,
            counter:0,
            ctvItems:[],
            districtItems:[],
            pinItemArray:[],
            stateItems:[],

            rules: {
                required: value => !!value || 'This Field is Required.',
                minExtRefNo: value => {
                    // const pattern = /^([0-9])+$/
                    const pattern = /^[a-zA-Z0-9]+$/
                    return pattern.test(value) || 'This Field is Required.'
                },
            },
        }
    },

    methods: {
        gotoProvSummary(data){
            let _type = this.toggelBtn == 'Individual' ? 'individual' : 'institution'
            this.$router.push({name:'ProviderSummary', params: {id:_type, prn:data.providerId }}) 
        },
        getOwnershipData(){
            var self = this
            // this.GET(3,'state/get-state-all' , res=>{
            //     self.ownerItemsArray = res.data.data[0].clinicalSpecialities
            // })
        },
        getClinicalData(){
            var self = this
            this.GET(3,'clinical-specialities/get-all-clinical-specialities' , res=>{
                self.clinicalItemsArray = res.data.data[0].clinicalSpecialities
            })
        },
        getStatesData(){
            var self = this
            this.GET(3,'state/get-state-all' , res=>{
                self.stateItems = res.data.data.data
            })
        },
        nextPageData(indata){
            this.pagelength == indata ? this.swap_final_count = true : this.swap_final_count = false

            if(this.toggelBtn == 'Institution'){
                this.typesearch == false  ? this.getInstiProviderData(this.pageInsti) : this.searchData(this.pageInsti)
            }else{
                this.typesearch == false  ? this.getIndivProviderData(this.pageInsti) : this.searchData(this.pageInsti)
            }

        },
        getInstiProviderData(skip){
            let self = this
            // provider/provider-institution-all?perPage=20&pageNo=
            this.GET(2,'empanelment/get-all-empaneled-provider?registeredEntity=Institution&perPage=20&pageNo=' + skip , response=>{
                // console.log("=========((((((((( res )))))))))=======>>",response)
                let _data = response.data.data.data
                self.pagelength = response.data.data.total_pages
                self.totalLen = response.data.data.total
                self.provTblData = []

                var less_enough = self.totalLen
                var checkless_init = false
                less_enough < 20 ? checkless_init = false : checkless_init = true

                // if less than 15 we have second value same as total value as no pagination will occur
                if(checkless_init){
                    // checkinit is true means the final count is more than 15 (- 1)
                    var traverse =  20 * self.pageInsti
                    self.fval = traverse - 19
                    self.swap_final_count ? self.sval =  self.totalLen : self.sval = traverse
                }else if(self.totalLen == 0){
                    self.sval = self.fval = 0
                
                }else{
                    self.fval = 1
                    self.sval = self.totalLen
                }
                this.dataFilterForTable(_data)
            })
        },
        getIndivProviderData(skip){
            let self = this
            // provider/provider-individual-all?perPage=20&pageNo=
            
            this.GET(2,'empanelment/get-all-empaneled-provider?registeredEntity=Individual&perPage=20&pageNo=' + skip , response=>{
                let _data = response.data.data.data
                self.pagelength = response.data.data.total_pages
                self.totalLen = response.data.data.total
                self.provTblData = []

                var less_enough = self.totalLen
                var checkless_init = false
                less_enough < 20 ? checkless_init = false : checkless_init = true

                // if less than 15 we have second value same as total value as no pagination will occur
                if(checkless_init){
                    // checkinit is true means the final count is more than 15 (- 1)
                    var traverse =  20 * self.pageInsti
                    self.fval = traverse - 19
                    self.swap_final_count ? self.sval =  self.totalLen : self.sval = traverse
                }else if(self.totalLen == 0){
                    self.sval = self.fval = 0
                
                }else{
                    self.fval = 1
                    self.sval = self.totalLen
                }
                this.dataFilterForTable(_data)
            })
        },
        dataFilterForTable(data){
            let self = this
            let checkedData = []

            let netwrkIDStore = this.$store.state.createNetworkData._id
            this.GET(4,'network-management/get-network-by-id/' + netwrkIDStore,(res,error)=>{
                let respData = res.data.data.providerDetails
                if(res.data.data.hasOwnProperty('providerDetails') == true){
                    for(let _props of respData){
                        let _data = {}
                        // console.log("=========(((((((((TABLEEEE)))))))))=======>>",_props)
                        _data.prn = self.checkValidity(_props.providerPRN)
                        _data.provName = self.checkValidity(_props.providerName)
                        _data.type = self.checkValidity(_props.providerType)
                        _data.status = self.checkValidity(_props.providerStatus)
                        _data.alias = self.checkValidity('')
                        _data.rohini = self.checkValidity(_props.providerRohiniId)
                        _data.address = self.checkValidity(_props.providerAddress)
                        _data.city = self.checkValidity(_props.city)
                        _data.state = self.checkValidity('')

                        _data.providerId = self.checkValidity(_props.providerId)
                        _data.providerStatus = self.checkValidity(_props.providerStatus)
                        _data.providerRegisteredEntity = self.checkValidity(_props.providerRegisteredEntity)
                        _data.providerRegistrationNo = self.checkValidity(_props.providerRegistrationNo)
                        _data.providerMobileNumber = self.checkValidity(_props.providerMobileNumber)
                        _data.providerGSTIN = self.checkValidity(_props.providerGSTIN)
                        _data.providerPAN = self.checkValidity(_props.providerPAN)
                        _data.pincode = self.checkValidity(_props.pincode)
                        _data.district = self.checkValidity(_props.district)
                        _data.tariffId = self.checkValidity(_props.tariffId)
                        _data.tariffName = self.checkValidity(_props.tariffName)
                        _data.respData = self.checkValidity(_props)
                        _data._id = self.checkValidity(_props._id)
                        // _data.tarrifData = tariffDetails
                        _data.tarrifData = _props.hasOwnProperty('tariffDetails') ?  self.checkValidity(_props.tariffDetails) : ''

                        checkedData.push(_data)
                    }
                    self.selectTableData = [...checkedData]
                }else{
                    self.selectTableData = []
                }
                data.forEach(function(el) {
                    for(let _ev of checkedData) { 
                        _ev.providerId === el.empanelmentProvider ? el.disableCheck = true : el.disableCheck = el.disableCheck == true ? true : false
                    }
                    return el
                })
                
                for(let i = 0; i < data.length ; i++){
                    let objData = {}
                    // console.log("=========(((((((((_check)))))))))=======>>",data[i])
                    objData.prn = self.checkValidity(data[i].prn)
                    objData.provName = self.checkValidity(data[i].entityName || data[i].firstName+ ' ' +data[i].lastName)
                    objData.type = self.checkValidity(data[i].providerType)
                    objData.status = self.checkValidity(data[i].status)
                    objData.alias = self.checkValidity(data[i].aliasName)
                    objData.rohini = self.checkValidity(data[i].rohiniId)
                    objData.address = self.checkValidity(data[i].addressLine1)
                    objData.city = self.checkValidity(data[i].cityTownVillage)
                    objData.state = self.checkValidity(data[i].state)

                    objData.providerId = self.checkValidity(data[i].empanelmentProvider)
                    objData.providerStatus = self.checkValidity(data[i].status)
                    objData.providerRegisteredEntity = self.checkValidity(data[i].registeredEntity)
                    objData.providerRegistrationNo = self.checkValidity(data[i].registrationNumber)
                    objData.providerMobileNumber = self.checkValidity(data[i].telephoneNumber)
                    objData.providerGSTIN = self.checkValidity(data[i].gstin)
                    objData.providerPAN = self.checkValidity(data[i].panNumber)
                    objData.pincode = self.checkValidity(data[i].pinPostalCode)
                    objData.district = self.checkValidity(data[i].district)
                    objData.tariffId = self.checkValidity(data[i].tariffId)
                    objData.tariffName = self.checkValidity(data[i].tariffName)
                    objData._id = self.checkValidity(data[i]._id)
                    objData.respData = self.checkValidity(data[i])
                    objData.disableCheck = data[i].disableCheck == undefined ? false : data[i].disableCheck
                    // objData.tarrifData = tariffDetails
                    objData.tarrifData = data[i].hasOwnProperty('tariffDetails') ?  self.checkValidity(data[i].tariffDetails) : ''

                    self.provTblData.push(objData)
                    // console.log("=========(((((((((     provTblData     )))))))))=======>>",self.provTblData)
                }
            })
            return
        },
        checkValidity(data){
            try{
                if(data === "" || data === null || data === undefined || data === 'undefined'){
                    return '-';
                }else{
                    return data;
                }
            }catch(err){}
        },
        providerTableData(ev){
            // console.log("=========(((((((((providerTableData)))))))))=======>>",ev)
            // console.log("=========(((((((((selectTableData)))))))))=======>>",this.selectTableData)
        },
        selectDataAfterCheck(ev){
            // console.log("=========(((((((((providerTableData)))))))))=======>>",ev)
            if(this.finalTableSelectdData.length !== 0){
                if(ev.value == false){
                    let ItemIndex = this.finalTableSelectdData.findIndex(el => el.providerId === ev.item.providerId);
                    this.finalTableSelectdData.splice(ItemIndex, 1)
                }else{
                    this.finalTableSelectdData.push(ev.item)
                }
            }else{
                this.finalTableSelectdData.push(ev.item)
            }
        },
        showIndividualData() {
            this.providerTypeArray = this.indiProviderType,
            this.showIndividual = true;
            this.showInstitution = false;
            this.pageInsti = 1
            this.pagelength = 0
            this.fval = 0
            this.sval = 0
            this.totalLen = 0
            this.selectTableData = []
            this.clearFieldData()

            this.getIndivProviderData(1)
        },
        showInstitutionData() {
            this.providerTypeArray = this.instiProviderType,
            this.showIndividual = false;
            this.showInstitution = true;
            this.pageInsti = 1
            this.pagelength = 0
            this.fval = 0
            this.sval = 0
            this.totalLen = 0
            this.selectTableData = []
            this.clearFieldData()

            this.getInstiProviderData(1)            
        },
        clearData(){
            this.clearFieldData()
            this.toggelBtn == 'Institution' ? this.getInstiProviderData(1) : this.getIndivProviderData(1)
            this.$refs.netform.resetValidation()
        },
        clearFieldData(){
            this.addProvRohiniId = ''
            this.addProvEntityName = ''
            this.addProvEntityNameFilt = ''
            this.addProvPRN = ''
            this.addProvRegNum = ''
            this.addProvProvidrType = ''
            this.addProvPan = ''
            this.addProvGstIn = ''
            this.addProvProvidrStatus = ''
            this.addProvAddressCont = ''
            this.addProvState = ''
            this.addProvCityTown = ''
            this.addProvPincode = ''
            this.addProvDistrict = ''
            this.addProvClinicSpec = ''
            this.addProvOwnerType = ''
            this.addProvFirstNameFilt = ''
            this.addProvFirstName = ''
            this.addProvLastNameFilt = ''
            this.addProvLastName = ''
            this.addProvRegisterCouncil = ''
            this.addProvMobNum = ''
        },
        searchData(skip){ 
            let self = this
            let API_HEADER = ''

            if(self.toggelBtn == 'Institution'){
                if(self.addProvRohiniId == '' && self.addProvEntityName == ''  && self.addProvEntityNameFilt == '' && self.addProvPRN == ''  && self.addProvRegNum == ''
                     && self.addProvProvidrType == '' && self.addProvPan == '' && self.addProvGstIn == '' && self.addProvState == '' && self.addProvCityTown == ''
                     && self.addProvPincode == '' && self.addProvDistrict == '' && self.addProvClinicSpec == '' && self.addProvOwnerType == ''){
                        self.showToast('Please enter search criteria', self.TOST().WARNING);
                        // self.$refs.netform.validate();
                        return
                }   
            }
            if(self.toggelBtn == 'Individual'){
                if(self.addProvPRN == ''  && self.addProvFirstNameFilt == '' && self.addProvFirstName == ''  && self.addProvLastNameFilt == '' && self.addProvLastName == ''
                    && self.addProvRegisterCouncil == '' && self.addProvProvidrType == '' && self.addProvPan == '' && self.addProvState == '' && self.addProvCityTown == ''
                    && self.addProvMobNum == '' && self.addProvPincode == '' && self.addProvDistrict == '' && self.addProvClinicSpec == ''){
                        self.showToast('Please enter search criteria', self.TOST().WARNING);
                        // self.$refs.netform.validate();
                        return
                }   
            }
            
            API_HEADER = 'empanelment/get-all-empaneled-provider?registeredEntity='+ self.toggelBtn +'&firstNameFilter='+ self.addProvFirstNameFilt +'&firstName=' + self.addProvFirstName +
                        '&lastNameFilter=' + self.addProvLastNameFilt +'&lastName=' + self.addProvLastName +'&registrationNumber=' + self.addProvRegNum +
                        '&prn='+ self.addProvPRN +'&providerType=' + self.addProvProvidrType +'&mobileNumber=' + self.addProvMobNum +
                        '&panNumber=' + self.addProvPan +'&registeredCouncil=' + self.addProvRegisterCouncil +'&addressContains=' + self.addProvAddressCont +
                        '&cityTownVillage=' + self.addProvCityTown +'&district=' + self.addProvDistrict +'&state=' + self.addProvState +
                        '&pinPostalCode=' + self.addProvPincode +'&rohiniId=' + self.addProvRohiniId +'&entityName=' + self.addProvEntityName +
                        '&entityNameFilter=' + self.addProvEntityNameFilt +'&gstin=' + self.addProvGstIn +'&clinicalSpecialities=' + self.addProvClinicSpec +
                        '&ownershipType=' + self.addProvOwnerType +'&perPage=20'+'&perPage=' + skip +'&sortBy='
            this.GET(2,API_HEADER,(res,error)=>{
                if(!error){
                    if(res.data.statusCode === 200){
                        let _searchResp = res.data.data.data
                        self.pagelength = res.data.data.total_pages
                        self.totalLen = res.data.data.total

                        var less_enough = self.totalLen
                        var checkless_init = false
                        less_enough < 20 ? checkless_init = false : checkless_init = true

                        // if less than 15 we have second value same as total value as no pagination will occur
                        if(checkless_init){
                            // checkinit is true means the final count is more than 15 (- 1)
                            var traverse =  20 * self.pageInsti
                            self.fval = traverse - 19
                            self.swap_final_count ? self.sval =  self.totalLen : self.sval = traverse
                        }else if(self.totalLen == 0){
                            self.sval = self.fval = 0
                        
                        }else{
                            self.fval = 1
                            self.sval = self.totalLen
                        }
                        self.provTblData = []
                        this.dataFilterForTable(_searchResp)

                    }else{
                        this.showToast(res.data.msg ,this.TOST().ERROR); 
                    }
                }else{
                    this.showToast(error.response.data.msg, this.TOST().WARNING);
                }
            })
        },
        updateProvData(){
            // console.log("=========(((((((((finalTableSelectdData   )))))))))=======>>",this.finalTableSelectdData)
            this.$router.push({name: 'ProviderTarrifSelect',params: {providerData: this.finalTableSelectdData }});
            // this.$router.push('/provider-tarrif-select')
        },

        getPincode(indata, from){
            // console.log("getPincode+++++++++++++>>>>>", indata)
            this.GET(3,'district/get-all?state='+indata.all.state , res=>{
                // console.log("something went wrong, pincode", res)
                if(res.status == 200){
                    this.districtItems =  res.data.data.data;
                    this.districtItems.map((e,index)=>{
                        if(e.district ==indata.all.district){
                            this.addProvDistrict = this.districtItems[index].district
                            this.getstates(indata.all.country,this.districtItems[index].state);
                            // this.countryHealth = indata.all.country;
                        }
                    })
                }else{
                    console.log("something went wrong, pincode", res)
                }
            })
        },

        callapi(indata, ident){
            this.GET(3,'city/search-by-pincode/'+indata, res=>{
                if(this.counter == 2){
                   this.pinItemArray = [];
                   this.counter = 0
                }
                let _empanel = this.$store.state.empanelProviderData.empanelment
                let _storeData = _empanel !== null ? _empanel.communicationAddress : this.$store.state.empanelProviderData.provider
                // let _storeData = this.$store.state.empanelProviderData.provider
                var pin_not_found = false
                if(res.data.data.length == 0){
                    // for making state city and district non-mandatory ass pin code response is zero
                    // this.s_c_d_nm = true
                    pin_not_found = true
                    this.counter = ''
                    this.addProvState = ''
                    this.addProvDistrict = ''
                    this.addProvCityTown = ''
                    
                    this.showToast("Pin Code not found" ,this.TOST().ERROR);

                }else{
                    // this.s_c_d_nm = false
                }
                
                var processed_pin =  res.data.data.map(e=>{
                    return { pin:e.pincode.toString() ,all:e }
                })
                this.pinItemArray.push(...processed_pin)


                if(pin_not_found){
                    this.pinItemArray.push({pin:indata.toString(),all:''});
                }

                if(ident == 1){
                    this.pinItemArray.map(e=>{
                        if(e.pin == indata && e.all.area == _storeData.cityTownVillage && e.all.district == _storeData.district){
                            // console.log(indata,"matched pin code is ",e)
                            this.addProvPincode = e
                            
                        }
                    })
                    this.getPincode(this.addProvPincode, 'Frm CALLED API');
                }
                this.counter++
                console.log("call api pinItemArray in new call api", this.pinItemArray);
            })

        },

        getstates(countryincoming,stateincoming){
            var self = this
            this.GET(3,'state/get-state-byCountry/'+countryincoming, res=>{
                    self.stateItems = res.data.data
                    // console.log( self.stateItems.length," RESPONSE of state api is  -> ",  self.stateItems);
                    self.stateItems.map(e=>{
                        // console.log(stateincoming,"matching state in map",e.state)
                        if(e.state == stateincoming){
                            self.addProvState = stateincoming;
                            self.get_areas(self.addProvDistrict,0);
                        }
                    })
            })
        },

        get_areas(indata_district, ident){
            var pageno =  ident == 1 ? this.search_area_pageno : this.area_page_no;

            this.GET(3,'city/areas?state='+this.addProvState+'&district='+indata_district+'&perPage=1000&pageNo='+pageno ,(res,error)=>{
                try {
                    if(res.status == 200){
                        // console.log("RESPPPP____CITYYYYYY",res)
                        if(res.data.data.data.length == 0){
                            // console.log("City, Town, Area not found, limits reached or API reahed last page ?")
                            return
                        }
                        this.ctvItems = []
                        this.ctvItems = res.data.data.data.map(e=>{
                            return {area:e.area,all:e}
                        })
                        this.ctvItems.push({area:'Other'})

                        if(ident == 1){
                            // console.log(" stopped area's auto increment as ident from user search ", ident);
                            return
                        }
                        // console.log(" ctvItemss ", this.ctvItems.length);
                        var ctvitemlen =  this.ctvItems.length
                        var my_counter  = 0
                        var findareapromise = new Promise((resolve, reject)=>{
                            for(let i=0; i<this.ctvItems.length; i++){
                                my_counter++
                                if(this.addProvPincode.all.area == this.ctvItems[i].area){
                                    // console.log(my_counter,"no need to recall API with Page update",this.ctvItems[i].area)
                                    this.addProvCityTown = this.addProvPincode.all.area
                                    resolve("value found, dont call API")
                                    return
                                }
                                
                                if(ctvitemlen == my_counter){
                                    // console.log(this.addProvPincode.all.area, "< rejected as ?",my_counter)
                                    // console.log("i is ", i)
                                    reject("value not found, call API")
                                }
                            }
                        })

                        findareapromise.then(responseis=>{
                            // console.log( this.addProvCityTown,"do not call api area value found", this.addProvCityTown == this.addProvPincode.all.area)
                            this.ctvItems.push({area:'Other'})
                        }).catch(eis=>{
                            // console.log(eis,"area value not found call next records, increment page", this.addProvCityTown == this.addProvPincode.all.area)
                            // call same api function here with page number increment and also pass district to it
                            this.addProvCityTown = '';
                            this.area_page_no++;
                            // alert("from CATCH,value not found for AREA");
                            this.get_areas(this.addProvDistrict,0)
                        })

                    }else{
                        // console.log(" something went wrong, areas ", res);
                    }
                } catch (error) {
                        console.log("from catch -> ",error); 
                        return
                }
                    
            })
        },

    },

    watch: {
        citysearch(val){
            if(val == this.addProvCityTown){
                // console.log("matches v model");
                return
            }
            
            if(val == null){
                // console.log("returned as val foun to be null")
                return
            }

            if(val.length > 3){
                var vlen = val.length;
                // console.log('vlen is incoming string length to be compared inside the array of objects--->',vlen);
                var ctvitemlen =  this.ctvItems.length
                var my_counter  = 0
                for(let i=0; i < this.ctvItems.length;i++){
                    var strlen = this.ctvItems[i].area.slice(0,vlen)
                    // console.log(val.length ,"<- searched length |&&| strlen length is",strlen.length)
                    // console.log(val ,"<- searched |&&| str is",strlen)
                    my_counter++
                    if(strlen == val){
                        // console.log(strlen.length,"CITY found as ",val.length)
                        return
                    }

                    if(my_counter == ctvitemlen){
                        // console.log(my_counter == ctvitemlen," < ??????????????????- searched entired array value not found, now calling API with ident 1 from watch")
                        this.search_area_pageno++
                        this.get_areas(this.addProvDistrict,1)
                    }
                }
            }else{
                console.log("citysearch not 3")
            }

        },

        searchPinCode(val){
            console.log(" val is ", val);
            if(val == null){
                // console.log("returned as val foun to be null")
                return
            }

            if(val.length >3){
                var vlen = val.length
                if(this.pinItems.length == 0 && val.length >= 3){
                    this.callapi(val,0)
                    return
                }else{
                    // console.log('itens len')
                }

                for(let i=0; i<this.pinItems.length;i++){
                    var strlen = this.pinItems[i].pin.slice(0,vlen)
                        
                    if(vlen > strlen.length){
                        // console.log("data not available to search")
                        return
                    }
                    
                    if(strlen != val){
                        this.callapi(val,0)
                        return
                    }else{
                        // console.log(this.pinItems.length,"found true", this.pinItems[i].pin)
                        return
                    }
                }
            }else{
                console.log("not 3")
            }
        
        },
    },
    
    
}

</script>
<style >
.activeredbackground{
  color:white;
}

.v-btn__content { width: 100% !important; white-space: normal !important; font-size:13px !important}

.srSelected{
  background-color: #23B1A9 !important;
  color:#FFFFFF !important;
}
.disable-field-style{
    border:1px solid #0014001f;
    padding:6px;
    background-color:#f7f7f7
}
.disp-flex{
    display: flex;
}
.activebackground {
    background-color: #23B1A9 !important;
    color:rgb(247 247 247) !important;
}

.inactivewhitebackground {
    background-color: #ffffff !important;
    color:#10242B !important;
}
.theme--light
.v-data-table > 
.v-data-table__wrapper > table > tbody > tr > td, 
.theme--light.v-data-table > 
.v-data-table__wrapper > table > tbody > tr:not(:last-child) > th:not(.v-data-table__mobile-row) {
    border-bottom: thin solid rgba(0, 0, 0, 0.12);
    border-right: thin solid rgba(0, 0, 0, 0.12);
}
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
    border-bottom: thin solid rgba(0, 0, 0, 0.12);
    border-right: thin solid rgba(0, 0, 0, 0.12);
    color: black;
}

</style>