<template>
    
    <v-app>
		<v-card>
            <div>
                <v-card class="pa-3 mb-1" style="" tile>
				<v-layout align-center class="py-2" >
						
						<v-text class="text-uppercase font-weight-bold title-clr-prtbs ml-3" style="color:">Provider Search</v-text>
						
                            <v-divider vertical class="mx-4"></v-divider>

							<v-text class="font-weight-black cstm-clr-prtbs mx-5" style="color:">Registered Entity</v-text>
						
                            <v-divider vertical class="mx-6"></v-divider>
                            
                            <v-btn-toggle dense mandatory v-model="toggleBtn" tile class="mx-5" >
                                <v-btn elevation="1" outlined  value="individual" class="text-capitalize font-weight-bold  px-5"
                                    @click="showIndividualData('individual')" style="white-space: normal;"
                                :class="showIndividualTab">Individual
                                </v-btn>

                                <v-btn elevation="1" outlined value="institution" class="text-capitalize font-weight-bold   px-5"
                                    @click="showInstitutionData('institution')" style="white-space: normal;"
                                :class="showInstitutionTab">Institution
                                </v-btn>    
                            </v-btn-toggle>                 
				</v-layout>
                </v-card>
            </div>
    
            <div v-if="showIndividual">
                <v-form ref="form">
                    <v-layout class="mt-8 ml-3">
                        <v-flex xs6>
                            <v-layout column class="cust_pr_PS mx-4">
                                        <v-text class="font-weight-bold cstm-clr-pr-PS">
                                            First Name
                                        </v-text>
                                    
                                        <v-text-field
                                            solo
                                            label="Enter..."
                                            v-model="firstName"
                                            class="rounded-0"
                                            dense
                                            >
                                        </v-text-field>
                            </v-layout>
                        </v-flex>

                         <v-flex xs6>
                            <v-layout column class="cust_pr_PS mx-4  ">
                                        <v-text class="font-weight-bold cstm-clr-pr-PS">
                                            Last Name
                                        </v-text>
                                    
                                        <v-text-field
                                            solo
                                            label="Enter..."
                                            v-model="lastName"
                                            class="rounded-0"
                                            dense
                                            >
                                        </v-text-field>
                            </v-layout>
                        </v-flex>

                         <v-flex xs6>
                            <v-layout column class="cust_pr_PS mx-4  ">
                                        <v-text class="font-weight-bold cstm-clr-pr-PS">
                                            PAN
                                        </v-text>
                                    
                                        <v-text-field
                                            solo
                                            label="Enter..."
                                            v-model="panNumb"
                                            class="rounded-0"
                                            dense
                                            >
                                        </v-text-field>
                            </v-layout>
                        </v-flex>

                         <v-flex xs6>
                            <v-layout column class="cust_pr_PS mx-4  ">
                                        <v-text class="font-weight-bold cstm-clr-pr-PS">
                                            PRN
                                        </v-text>
                                    
                                        <v-text-field
                                            solo
                                            label="Enter..."
                                            v-model="prnNumb"
                                            class="rounded-0"
                                            dense
                                            >
                                        </v-text-field>
                            </v-layout>
                        </v-flex>
                    </v-layout>

                      <v-layout class="mt-8 ml-3">
                        <v-flex xs6>
                            <v-layout column class="cust_pr_PS mx-4">
                                        <v-text class="font-weight-bold cstm-clr-pr-PS">
                                            Registered Council
                                        </v-text>
                                    
                                        <v-text-field
                                            solo
                                            label="Enter..."
                                            v-model="registeredCouncil"
                                            class="rounded-0"
                                            dense
                                            >
                                        </v-text-field>
                            </v-layout>
                        </v-flex>

                         <v-flex xs6>
                            <v-layout column class="cust_pr_PS mx-4  ">
                                        <v-text class="font-weight-bold cstm-clr-pr-PS">
                                            Registration Number 
                                        </v-text>
                                    
                                        <v-text-field
                                            solo
                                            label="Enter..."
                                            v-model="registrationNumber"
                                            class="rounded-0"
                                            dense
                                            >
                                        </v-text-field>
                            </v-layout>
                        </v-flex>

                         <v-flex xs6>
                            <v-layout column class="cust_pr_PS mx-4  ">
                                        <v-text class="font-weight-bold cstm-clr-pr-PS">
                                            Provider Type
                                        </v-text>
                                    
                                        <v-select
                                            solo
                                            label="Enter..."
                                            :items="providerTypeItems"
                                            v-model="providerType"
                                            class="rounded-0"
                                            dense
                                            >
                                        </v-select>
                            </v-layout>
                        </v-flex>

                         <v-flex xs6>
                            <v-layout column class="cust_pr_PS mx-4  ">
                                        <v-text class="font-weight-bold cstm-clr-pr-PS">
                                            Mobile Number
                                        </v-text>
                                    
                                        <v-text-field
                                            solo
                                            label="Enter..."
                                            v-model="mobileNumber"
                                            class="rounded-0"
                                            dense
                                            >
                                        </v-text-field>
                            </v-layout>
                        </v-flex>
                    </v-layout>

                    <v-divider class="mb-4"></v-divider>

					<v-text class="text-uppercase font-weight-bold title-clr-prtbs ml-3 mt-4 pa-3" style="color:">ADDRESS</v-text>
                    
                     <v-layout class="mt-5 ml-3">
                        <v-flex xs6>
                            <v-layout column class="cust_pr_PS mx-4">
                                        <v-text class="font-weight-bold cstm-clr-pr-PS">
                                            Address Contains 
                                        </v-text>
                                    
                                        <v-text-field
                                            solo
                                            label="Enter..."
                                            v-model="addressContains"
                                            class="rounded-0"
                                            dense
                                            >
                                        </v-text-field>
                            </v-layout>
                        </v-flex>

                         <v-flex xs6>
                            <v-layout column class="cust_pr_PS mx-4  ">
                                        <v-text class="font-weight-bold cstm-clr-pr-PS">
                                            State
                                        </v-text>
                                    
                                        <v-select
                                            :items="stateItems"
                                            solo
                                            label="Enter..."
                                            v-model="state"
                                            class="rounded-0"
                                            dense
                                            >
                                        </v-select>
                            </v-layout>
                        </v-flex>

                       <v-flex xs6>
                            <v-layout column class="cust_pr_PS mx-4  ">
                                        <v-text class="font-weight-bold cstm-clr-pr-PS">
                                            City/Town/Village
                                        </v-text>
                                    
                                        <v-select
                                            :items="cityTownVillageItems"
                                            solo
                                            label="Enter..."
                                            v-model="cityTownVillage"
                                            class="rounded-0"
                                            dense
                                            >
                                        </v-select>
                            </v-layout>
                        </v-flex>


                         <v-flex xs6>
                            <v-layout column class="cust_pr_PS mx-4  ">
                                        <v-text class="font-weight-bold cstm-clr-pr-PS">
                                            Pincode
                                        </v-text>
                                    
                                        <v-text-field
                                            solo
                                            label="Enter..."
                                            v-model="pinCode"
                                            class="rounded-0"
                                            dense
                                            >
                                        </v-text-field>
                            </v-layout>
                        </v-flex>
                    </v-layout>

                    <v-layout class="mt-5 ml-3">
                         <v-flex xs6>
                            <v-layout column class="cust_pr_PS mx-4">
                                        <v-text class="font-weight-bold cstm-clr-pr-PS">
                                            District
                                        </v-text>
                                    
                                        <v-text-field
                                            solo
                                            label="Enter..."
                                            v-model="district"
                                            class="rounded-0"
                                            dense
                                            >
                                        </v-text-field>
                            </v-layout>
                        </v-flex>

                        <v-flex xs12>
                            <v-layout row class="mt-8 ml-2">
                            <v-btn tile  style="font-size:12px;background-color:#f7f7f7;" elevation="1" outlined small text color="black" class="text-capitalize px-3 mx-2">Clear All <v-icon dark small class="ml-3">clear</v-icon > </v-btn>
                            <v-btn tile dark  style="font-size:12px;width:120px" small color="" class="text-capitalize px-3 mx-2 ml-3">Search</v-btn>   
                            <v-btn tile style="font-size:12px;background-color:#01b4bb;" small class="ml-3 exportBtn" text color="#fff">
                                    <v-icon  small class="ml-2">cloud_download</v-icon >
                                    <v-divider vertical class="mx-2"></v-divider>Export Providers</v-btn> 
                        </v-layout>
                        </v-flex>
                        <v-flex xs6></v-flex>

                    </v-layout>
                </v-form>

                <v-card class="rounded-0 pa-5">
                    <v-data-table
                        :headers="headers"
                        :items="dataItems"
                        dense
                        :page.sync="page"
                        :items-per-page="itemsPerPage"
                        hide-default-footer
                    >
                    </v-data-table>
                    <v-divider></v-divider>
                    <v-layout row justify-end align-center class="mx-2" >
                        <v-text class="mr-1 font-weight-bold body-1" style="color:#3C3D3D; font-size:12px !important">
                                <span class="" style="color:#E46A25;font-size:12px;">Showing {{fval}}-{{sval}} </span>out of {{totalLen }} records
                        </v-text>
                        <v-divider  class="mx-2" vertical ></v-divider>
                        <v-pagination 
                            class="cust-bnkAcc"
                            v-model="page"
                            color="#152F38"
                            flat
                            :total-visible="9"
                            @input="nextPageBtn(page)"
                            :length="pageLength"
                            ></v-pagination>
                    </v-layout>
                </v-card>
            </div>


             <div v-if="showInstitution">
                <v-form ref="form">
                    <v-layout class="mt-8 ml-3">
                        <v-flex xs6>
                            <v-layout column class="cust_pr_PS mx-4">
                                        <v-text class="font-weight-bold cstm-clr-pr-PS">
                                            Entity Name
                                        </v-text>
                                    
                                        <v-text-field
                                            solo
                                            label="Enter..."
                                            v-model="firstName"
                                            class="rounded-0"
                                            dense
                                            >
                                        </v-text-field>
                            </v-layout>
                        </v-flex>

                        <v-flex xs6>
                            <v-layout column class="cust_pr_PS mx-4  ">
                                        <v-text class="font-weight-bold cstm-clr-pr-PS">
                                            Registration Number 
                                        </v-text>
                                    
                                        <v-text-field
                                            solo
                                            label="Enter..."
                                            v-model="registrationNumber"
                                            class="rounded-0"
                                            dense
                                            >
                                        </v-text-field>
                            </v-layout>
                        </v-flex>
                        
                         <v-flex xs6>
                            <v-layout column class="cust_pr_PS mx-4">
                                        <v-text class="font-weight-bold cstm-clr-pr-PS">
                                            ROHINI ID
                                        </v-text>
                                    
                                        <v-text-field
                                            solo
                                            label="Enter..."
                                            v-model="rohiniId"
                                            class="rounded-0"
                                            dense
                                            >
                                        </v-text-field>
                            </v-layout>
                        </v-flex>

                          <v-flex xs6>
                            <v-layout column class="cust_pr_PS mx-4  ">
                                        <v-text class="font-weight-bold cstm-clr-pr-PS">
                                            PRN
                                        </v-text>
                                    
                                        <v-text-field
                                            solo
                                            label="Enter..."
                                            v-model="prnNumb"
                                            class="rounded-0"
                                            dense
                                            >
                                        </v-text-field>
                            </v-layout>
                        </v-flex>
                         

                    </v-layout>

                    <v-layout class="mt-8 ml-3">

                        <v-flex xs6>
                            <v-layout column class="cust_pr_PS mx-4  ">
                                        <v-text class="font-weight-bold cstm-clr-pr-PS">
                                            Provider Type
                                        </v-text>
                                    
                                        <v-select
                                            solo
                                            label="Enter..."
                                            :items="providerTypeItems"
                                            v-model="providerType"
                                            class="rounded-0"
                                            dense
                                            >
                                        </v-select>
                            </v-layout>
                        </v-flex>

                         <v-flex xs6>
                            <v-layout column class="cust_pr_PS mx-4  ">
                                        <v-text class="font-weight-bold cstm-clr-pr-PS">
                                            PAN
                                        </v-text>
                                    
                                        <v-text-field
                                            solo
                                            label="Enter..."
                                            v-model="panNumb"
                                            class="rounded-0"
                                            dense
                                            >
                                        </v-text-field>
                            </v-layout>
                        </v-flex>

                         <v-flex xs6>
                            <v-layout column class="cust_pr_PS mx-4  ">
                                        <v-text class="font-weight-bold cstm-clr-pr-PS">
                                            GSTIN
                                        </v-text>
                                    
                                        <v-text-field
                                            solo
                                            label="Enter..."
                                            v-model="GSTIN"
                                            class="rounded-0"
                                            dense
                                            >
                                        </v-text-field>
                            </v-layout>
                        </v-flex>

                        <v-flex xs6></v-flex>

                    </v-layout>

                   

                    <v-divider class="mb-4"></v-divider>

					<v-text class="text-uppercase font-weight-bold title-clr-prtbs ml-3 mt-4 pa-3" style="color:">ADDRESS</v-text>
                    
                     <v-layout class="mt-5 ml-3">
                        <v-flex xs6>
                            <v-layout column class="cust_pr_PS mx-4">
                                        <v-text class="font-weight-bold cstm-clr-pr-PS">
                                            Address Contains 
                                        </v-text>
                                    
                                        <v-text-field
                                            solo
                                            label="Enter..."
                                            v-model="addressContains"
                                            class="rounded-0"
                                            dense
                                            >
                                        </v-text-field>
                            </v-layout>
                        </v-flex>

                         <v-flex xs6>
                            <v-layout column class="cust_pr_PS mx-4  ">
                                        <v-text class="font-weight-bold cstm-clr-pr-PS">
                                            State
                                        </v-text>
                                    
                                        <v-select
                                            :items="stateItems"
                                            solo
                                            label="Enter..."
                                            v-model="state"
                                            class="rounded-0"
                                            dense
                                            >
                                        </v-select>
                            </v-layout>
                        </v-flex>

                       <v-flex xs6>
                            <v-layout column class="cust_pr_PS mx-4  ">
                                        <v-text class="font-weight-bold cstm-clr-pr-PS">
                                            City/Town/Village
                                        </v-text>
                                    
                                        <v-select
                                            :items="cityTownVillageItems"
                                            solo
                                            label="Enter..."
                                            v-model="cityTownVillage"
                                            class="rounded-0"
                                            dense
                                            >
                                        </v-select>
                            </v-layout>
                        </v-flex>


                         <v-flex xs6>
                            <v-layout column class="cust_pr_PS mx-4  ">
                                        <v-text class="font-weight-bold cstm-clr-pr-PS">
                                            Pincode
                                        </v-text>
                                    
                                        <v-text-field
                                            solo
                                            label="Enter..."
                                            v-model="pinCode"
                                            class="rounded-0"
                                            dense
                                            >
                                        </v-text-field>
                            </v-layout>
                        </v-flex>
                    </v-layout>

                    <v-layout class="mt-5 ml-3">
                         <v-flex xs6>
                            <v-layout column class="cust_pr_PS mx-4">
                                        <v-text class="font-weight-bold cstm-clr-pr-PS">
                                            District
                                        </v-text>
                                    
                                        <v-text-field
                                            solo
                                            label="Enter..."
                                            v-model="district"
                                            class="rounded-0"
                                            dense
                                            >
                                        </v-text-field>
                            </v-layout>
                        </v-flex>

                        <v-flex xs12>
                            <v-layout row class="mt-8 ml-2">
                            <v-btn tile  style="font-size:12px;background-color:#f7f7f7;" elevation="1" outlined small text color="black" class="text-capitalize px-3 mx-2">Clear All <v-icon dark small class="ml-3">clear</v-icon > </v-btn>
                            <v-btn tile dark  style="font-size:12px;width:120px" small color="" class="text-capitalize px-3 mx-2 ml-3">Search</v-btn>   
                            <v-btn tile style="font-size:12px;background-color:#01b4bb;" small class="ml-3 exportBtn" text color="#fff">
                                    <v-icon  small class="ml-2">cloud_download</v-icon >
                                    <v-divider vertical class="mx-2"></v-divider>Export Providers</v-btn> 
                        </v-layout>
                        </v-flex>
                        <v-flex xs6></v-flex>

                    </v-layout>
                </v-form>

                <v-card class="rounded-0 pa-5">
                    <v-data-table
                        :headers="Instheaders"
                        :items="dataItems"
                        dense
                        :page.sync="page"
                        :items-per-page="itemsPerPage"
                        hide-default-footer
                    >
                    </v-data-table>
                    <v-divider></v-divider>
                    <v-layout row justify-end align-center class="mx-2" >
                        <v-text class="mr-1 font-weight-bold body-1" style="color:#3C3D3D; font-size:12px !important">
                                <span class="" style="color:#E46A25;font-size:12px;">Showing {{fval}}-{{sval}} </span>out of {{totalLen }} records
                        </v-text>
                        <v-divider  class="mx-2" vertical ></v-divider>
                        <v-pagination 
                            class="cust-bnkAcc"
                            v-model="page"
                            color="#152F38"
                            flat
                            :total-visible="9"
                            @input="nextPageBtn(page)"
                            :length="pageLength"
                            ></v-pagination>
                    </v-layout>
                </v-card>
            </div>
    
		</v-card>
    </v-app>    
</template>
          

<script>
export default {

    created(){
        this.$store.commit("SAVE_PAGE_HEADER", "Provider Search");

        this.searchProviders()
    },

    data(){
        return{

            toggleBtn:'',

            showIndividual: false,
            showInstitution: true,

            firstName:'',
            lastName:'',
            panNumb:'',
            prnNumb:'',

            registeredCouncil:'',
            registrationNumber:'',
            providerTypeItems:[],
            providerType:'',
            mobileNumber:'',

            rohiniId:'',
            GSTIN:'',

            addressContains:'',
            stateItems:[],
            state:'',
            cityTownVillageItems:[],
            cityTownVillage:'',
            pinCode:'',

            district:'',

             headers:[
                    {text: 'PRN', value: 'prn',sortable: false,divider:true,class:"hdr-txt-clrPer hdr-bg-clrPer "},
                    {text: 'Provider Name', value: 'providerName',sortable: false,divider:true,class:"hdr-txt-clrPer hdr-bg-clrPer "},
                    {text: 'Provider Type', value: 'providerType',sortable: false,divider:true,class:"hdr-txt-clrPer hdr-bg-clrPer "},
                    {text: 'Status', value: 'status',sortable: false,divider:true,class:"hdr-txt-clrPer hdr-bg-clrPer "},
                    {text: 'Registration No.', value: 'regNo',sortable: false,divider:true,class:"hdr-txt-clrPer hdr-bg-clrPer "},
                    {text: 'Council', value: 'council',sortable: false,divider:true,class:"hdr-txt-clrPer hdr-bg-clrPer "},
                    {text: 'Address', value: 'address',sortable: false,divider:true,class:"hdr-txt-clrPer hdr-bg-clrPer "},
                    {text: 'City/Town', value: 'cityTown',sortable: false,divider:true,class:"hdr-txt-clrPer hdr-bg-clrPer "},
                    {text: 'State', value: 'state',sortable: false,divider:true,class:"hdr-txt-clrPer hdr-bg-clrPer "},
                    {text: 'PinCode', value: 'pinCode',sortable: false,divider:true,class:"hdr-txt-clrPer hdr-bg-clrPer "},
                ],

            Instheaders:[
                    {text: 'PRN', value: 'prn',sortable: false,divider:true,class:"hdr-txt-clrPer hdr-bg-clrPer "},
                    {text: 'Provider Name', value: 'providerName',sortable: false,divider:true,class:"hdr-txt-clrPer hdr-bg-clrPer "},
                    {text: 'Provider Type', value: 'providerType',sortable: false,divider:true,class:"hdr-txt-clrPer hdr-bg-clrPer "},
                    {text: 'Status', value: 'status',sortable: false,divider:true,class:"hdr-txt-clrPer hdr-bg-clrPer "},
                    {text: 'Alias Name', value: 'aliasName',sortable: false,divider:true,class:"hdr-txt-clrPer hdr-bg-clrPer "},
                    {text: 'ROHINI ID', value: 'rohiniId',sortable: false,divider:true,class:"hdr-txt-clrPer hdr-bg-clrPer "},
                    {text: 'Address', value: 'address',sortable: false,divider:true,class:"hdr-txt-clrPer hdr-bg-clrPer "},
                    {text: 'City/Town', value: 'cityTown',sortable: false,divider:true,class:"hdr-txt-clrPer hdr-bg-clrPer "},
                    {text: 'State', value: 'state',sortable: false,divider:true,class:"hdr-txt-clrPer hdr-bg-clrPer "},
                    {text: 'PinCode', value: 'pinCode',sortable: false,divider:true,class:"hdr-txt-clrPer hdr-bg-clrPer "},
                ],
            // for pagination 
            fval:0,
            sval:0,
            totalLen:'',
            itemsPerPage:10,            
            page: 1,
            pageLength:'',
            lastPage:false,


        }
    },

    computed:{

        showIndividualTab(){
           return  this.showIndividual === true ? 'activebackground' : 'inactivewhitebackground'
        },

        showInstitutionTab(){
           return this.showInstitution === true ? 'activebackground' : 'inactivewhitebackground'
        }

    },

    methods:{
        showIndividualData() {
            this.showIndividual = true;
            this.showInstitution = false;
            console.log('individual dat a is------',)
            
        },

        showInstitutionData() {
            console.log('institution data is------',)            
            this.showIndividual = false;
            this.showInstitution = true;
                      
        },

        searchProviders(){
            this.GET(2,'provider/provider-individual-all?perPage=20&pageNo='+this.page,(res,error)=>{
                    if(!error){
                        console.log("provider founded are",res.data.data.data);
                        this.pageLength = res.data.data.total_pages;
                        this.totalLen = res.data.data.total
                        var temp =  this.page*10;
                        this.fval = temp-9;
                    
                        if(this.lastPage){
                            this.sval = this.totalLen
                            console.log("value of sval is fo",this.sval);
                        }else{
                            if(this.totalLen < 10){
                                this.sval = this.totalLen
                                console.log("this are the pageLength for searchTable",this.totalLen);
                            }else{

                                this.sval = temp;
                            console.log("value of sval is",this.sval);
                            }

                        }

                       let providerFound = res.data.data.data;
                        this.dataItems = providerFound.map(provider => {
                                                return{
                                                    prn : provider.prn,
                                                    providerName : provider.firstName + provider.lastName,
                                                    providerType : provider.providerType,
                                                    status : provider.status,
                                                    regNo : provider.registrationNumber,
                                                    council : provider.registeredCouncil
                                                    // address : provider.
                                                    // cityTown : 
                                                    // state : 
                                                    // pinCode : 
                                                }
                                            })
                        



                    }else{
                        console.log("their was error",error.response.data.msg);
                    }
            })
        }
    }
}
</script>

<style scoped>
.cust_pr_PS .v-input{
    font-size: 13px !important;
}

.cstm-clr-pr-PS{
    color:#646666 !important;
    margin-bottom: 5px !important;
    font-size: 13px !important;
}

.cstm-clr-pr-PS+.v-text-field >>> label{ 
    font-size: 13px;
}



.activebackground {
    background-color: #23B1A9 !important;
    color:rgb(247 247 247) !important;
}

.inactivewhitebackground {
        background-color: #ffffff !important;
    /* color: #646666 !important; */
      color:#10242B !important;

}

.title-clr-prtbs{
    color:#10242B !important;
    /* margin-bottom: 5px; */
}
.cstm-clr-prtbs{
    color:hsl(180, 1%, 40%) !important;
    /* margin-bottom: 5px !important; */
    font-size: 13px !important;
}

.exportBtn{
    text-transform: none;
}


 .hdr-txt-clrPer{
    color: white !important;
    font-weight: bold;
    /* padding:0px!important;
    padding: 10px !important;
    padding-left: 14px !important; */
    }

    .hdr-bg-clrPer{
        background-color: #23B1A9;
    }


</style>