<template>
    <v-app class="mrtop">
         <div>
           <v-flex xs12>
               <v-layout column class="mt-2 cust_pr_quick cust-rmvpd-pr-quick font-weight-bold graybackground" style="padding:10px;color:#000000;">
                    Claim Assignment / Re-assignment Audit Trail
                </v-layout>
            </v-flex>
        <v-flex id="assignReassignAuditTable" xs12>
            <v-data-table
                hide-default-footer
                :headers="assignReassignAuditHeaders"
                :items="assignReassignAuditList"
               class="vDataTableStyling">
                <template v-for="header in assignReassignAuditHeaders.filter((header) => header.hasOwnProperty('formatter'))" v-slot:[`item.${header.value}`]="{ header, value }">
                    {{ header.formatter(value) }}
                </template>
            </v-data-table>
        </v-flex>
        <div class="text-center graybackground" >
            <v-row>
                <v-col cols="6"></v-col>
                <v-col cols="6">
                    <v-row class="paginrow">
                        <v-spacer></v-spacer>
                    <div class="mr10"> 
                        <span class="orangetext"> Showing {{PageStartIndex}}-{{pageEndIndex}} </span>                         
                        <span> out of {{totalListCount}} records </span>
                    </div>                               
                    <v-pagination
                        v-model="assignReassignpage"
                        :length="getNoOfPages"
                        prev-icon="mdi-arrow-left"
                        next-icon="mdi-arrow-right"
                        color="#152F38"                    
                        @input="goToNextPage"
                    ></v-pagination>
                    </v-row>
                </v-col>
            </v-row>
        </div>
        <v-spacer></v-spacer>
         <v-layout class="closwsec" >
                  <v-row>
                    <v-col align="center" class="mt-8 mb-3">
                        
                            <v-btn
                            color="#E46A25"
                            class="buttonscolor mr-5"
                            @click="$router.go(-1)">
                            <v-icon class="mr-2" style="font-size: 19px">mdi-close-circle</v-icon>
                            <div class="vertical_line"></div>Close
                        </v-btn>
                    </v-col>
                </v-row>
          </v-layout>
        </div>
    </v-app>
</template>

<script>
import moment from 'moment';
export default {
    data(){
        return {
            totalListCount     :   0,            
            assignReassignpage :   1,           
            PageStartIndex     :   1,
            pageRowCount       :   10,
            assignReassignAuditHeaders   :   [
                {
                    text: 'Claim Number',
                    align: 'start',
                    sortable: true,
                    value: 'mainClaimNumber'
                }, 
                {
                    text: 'Feature Number',
                    align: 'start',
                    sortable: true,
                    value: 'featureNumber'
                },   
                {
                    text: 'Assigned To',
                    align: 'start',
                    sortable: false,
                    value: 'assignToName',
                },
                 {
                    text: 'Assigned To User ID',
                    align: 'start',
                    sortable: false,
                    value: 'assignToId',
                },
                 {
                    text: 'Assigned By',
                    align: 'start',
                    sortable: false,
                    value: 'assignedById',
                },
                 {
                    text: 'User ID',
                    align: 'start',
                    sortable: false,
                    value: 'userId',
                },
                {
                    text: 'Date',
                    align: 'start',
                    sortable: true,
                    value: 'date',
                    formatter: this.formatDate
                },
                {
                    text: 'Time',
                    align: 'start',
                    sortable: false,
                    value: 'time',
                    formatter: this.formatTime
                },
            ],
            assignReassignAuditList  :   []
        }
    },
    methods:{
        goToNextPage(page){
            // this.PageStartIndex =   1;
            // this.totalListCount =   this.assignReassignAuditList.length;
            var self    =   this;
            var req     =   "perPage="+this.pageRowCount+"&pageNo="+page+"&mainClaimNumber="+this.$store.getters.fetchMainClaimNumberObj+"&featureNumber="+this.$store.getters.fetchFeatureNumber;
            this.showProgress();
            this.GETM("assignReassignAudit",req,(res)=>{
                self.hideProgress();
                console.log(res);
                if(res && res.data && res.data.data && res.data.data.data && res.data.data.data.length){
                    self.assignReassignAuditList     =   res.data.data.data
                    self.totalListCount              =   res.data.data.total
                    self.PageStartIndex              =   page == 1 ? 1 : ((page - 1) * self.pageRowCount) + 1;
                }else{
                    self.showAlert("No data available.")
                    self.totalListCount              =   0;
                    self.PageStartIndex              =   0;
                    self.assignReassignAuditList     =   [];

                }
            },(err)=>{
                self.hideProgress();
                console.log(err.response);
                self.totalListCount              =   0;
                self.PageStartIndex              =   0;
                self.assignReassignAuditList     =   [];
                let msg     =   (err.response && err.response.data && err.response.data.msg) ? err.response.data.msg : "Something went wrong,please try again."
                self.showAlert(msg)
            });
        },
        closePage(){
            this.$router.go(-1);
        },
        formatDate(date){
            return date ? moment(date).format("DD-MM-YYYY") : "";
        },
        formatTime(time){
            console.log(time);
            return time ? new Date(parseInt(time)).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }) : "";
        }
    },
    computed:{
        getNoOfPages(){
            if(!this.totalListCount){
                return 0;
            }else{
                return Math.ceil(this.totalListCount / this.pageRowCount);
            }
        },
        pageEndIndex(){
            if(!this.assignReassignAuditList.length){
                return 0
            }else{
                return ((this.PageStartIndex + (this.pageRowCount)) <= this.totalListCount) ? this.PageStartIndex + (this.pageRowCount) - 1 : this.totalListCount;
                
            }
        }
    },
    mounted(){
        console.log("this.$route.params",this.$route.params,this.$store.getters.fetchMainClaimNumberObj);
     //   this.$store.getters.fetchMainClaimNumberObj;
        this.PageStartIndex    =   0
        this.goToNextPage(1);
    }
}
</script>

<style scoped>
.graybackground {
        background-color:  #F7F7F7;
    }
    .orangetext {
        color: #E46A25;
    }

    .vDataTableStyling {
        white-space: nowrap;
    }
     .buttonscolor {
        border-radius: 0 !important;
        height: 31px !important;
        color: #f5f6fa !important;
        text-transform: capitalize;
        float:right;
    }
      .paginrow{
     margin-right:10px;
     margin-top:10px;
     align-items: center;
   }
    .mr10{
  margin-right:10px;
 }
 .closwsec{
     background-color: #F7F7F7; 
     height:95px; 
     border: 0.3px solid #C1C8CC;
     margin-top:24px;
 } 
 .mrtop{
   margin-top:10px
 }
</style>