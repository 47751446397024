<template>
  <div>
   <v-container fluid>
     <v-layout row>
       <v-flex md3 sm3 xs6>
         <v-layout column class="cust_usrsrch mx-2 mt-2">
           <p class="font-weight-bold cstm-clr-pln-usrsrch">
               Product Name
           </p>
           <v-text-field
             solo
             label="Enter..."
             v-model="formData.productName"
             maxlength="20"
             counter="20"
             class="rounded-0"
             aria-required="true"
             disabled
           >
           </v-text-field>
         </v-layout>
       </v-flex>
       <v-flex md3 sm3 xs6>
         <v-layout column class="cust_usrsrch mx-2 mt-2">
           <p class="font-weight-bold cstm-clr-pln-usrsrch">
               Product Status
           </p>
           <v-text-field
             solo
             label="Enter..."
             v-model="formData.policyStatus"
             maxlength="20"
             counter="20"
             class="rounded-0"
             aria-required="true"
             disabled
           >
           </v-text-field>
         </v-layout>
       </v-flex>
       <v-flex md3 sm3 xs6>
         <v-layout column class="cust_usrsrch mx-2 mt-2">
           <p class="font-weight-bold cstm-clr-pln-usrsrch">
               Policy Type
           </p>
           <v-text-field
             solo
             label="Enter..."
             v-model="formData.policyType"
             maxlength="20"
             counter="20"
             class="rounded-0"
             aria-required="true"
             disabled
           >
           </v-text-field>
         </v-layout>
       </v-flex>
       <v-flex md3 sm3 xs6>
         <v-layout column class="cust_usrsrch mx-2 mt-2">
           <p class="font-weight-bold cstm-clr-pln-usrsrch">
               Policy Start Date
           </p>
           <v-text-field
             solo
             label="Enter..."
             v-model="formData.policyStartDate.split(' ')[0]"
             maxlength="20"
             counter="20"
             class="rounded-0"
             aria-required="true"
             disabled
           >
           </v-text-field>
         </v-layout>
       </v-flex>
       <v-flex md3 sm3 xs6>
         <v-layout column class="cust_usrsrch mx-2 mt-2">
           <p class="font-weight-bold cstm-clr-pln-usrsrch">
               Policy End Date
           </p>
           <v-text-field
             solo
             label="Enter..."
             v-model="formData.policyEndDate.split(' ')[0]"
             maxlength="20"
             counter="20"
             class="rounded-0"
             aria-required="true"
             disabled
           >
           </v-text-field>
         </v-layout>
       </v-flex>
       <v-flex md3 sm3 xs6>
         <v-layout column class="cust_usrsrch mx-2 mt-2">
           <p class="font-weight-bold cstm-clr-pln-usrsrch">
               Policy Effective Date
           </p>
           <v-text-field
             solo
             label="Enter..."
             v-model="formData.policyStartDate.split(' ')[0]"
             maxlength="20"
             counter="20"
             class="rounded-0"
             aria-required="true"
             disabled
           >
           </v-text-field>
         </v-layout>
       </v-flex>
       <v-flex md3 sm3 xs6>
         <v-layout column class="cust_usrsrch mx-2 mt-2">
           <p class="font-weight-bold cstm-clr-pln-usrsrch">
               Portability Flag
           </p>
           <v-text-field
             solo
             label="Enter..."
             v-model="formData.portableFlag"
             maxlength="20"
             counter="20"
             class="rounded-0"
             aria-required="true"
             disabled
           >
           </v-text-field>
         </v-layout>
       </v-flex>
       <v-flex md3 sm3 xs6>
         <v-layout column class="cust_usrsrch mx-2 mt-2">
           <p class="font-weight-bold cstm-clr-pln-usrsrch">
               Previous Policy Number
           </p>
           <v-text-field
             solo
             label="Enter..."
             v-model="formData.previousPolicyNumber"
             maxlength="20"
             counter="20"
             class="rounded-0"
             aria-required="true"
             disabled
           >
           </v-text-field>
         </v-layout>
       </v-flex>
       <v-flex md3 sm3 xs6>
         <v-layout column class="cust_usrsrch mx-2 mt-2">
           <p class="font-weight-bold cstm-clr-pln-usrsrch">
              Previous Insurer
           </p>
           <v-text-field
             solo
             label="Enter..."
             v-model="formData.previousInsurer"
             maxlength="20"
             counter="20"
             class="rounded-0"
             aria-required="true"
             disabled
           >
           </v-text-field>
         </v-layout>
       </v-flex>
       <v-flex md3 sm3 xs6>
         <v-layout column class="cust_usrsrch mx-2 mt-2">
           <p class="font-weight-bold cstm-clr-pln-usrsrch">
              Previous Sum Insured
           </p>
           <v-text-field
             solo
             label="Enter..."
             v-model="formData.previousSumInsured"
             maxlength="20"
             counter="20"
             class="rounded-0"
             aria-required="true"
             disabled
           >
           </v-text-field>
         </v-layout>
       </v-flex>
       <v-flex md3 sm3 xs6>
         <v-layout column class="cust_usrsrch mx-2 mt-2">
           <p class="font-weight-bold cstm-clr-pln-usrsrch">
              Previous Effective Start Date
           </p>
           <v-text-field
             solo
             label="Enter..."
             v-model="formData.previousEffectiveStartDate"
             maxlength="20"
             counter="20"
             class="rounded-0"
             aria-required="true"
             disabled
           >
           </v-text-field>
         </v-layout>
       </v-flex>
       <v-flex md3 sm3 xs6>
         <v-layout column class="cust_usrsrch mx-2 mt-2">
           <p class="font-weight-bold cstm-clr-pln-usrsrch">
              Previous Expiry Date
           </p>
           <v-text-field
             solo
             label="Enter..."
             v-model="formData.previousExpiryDate"
             maxlength="20"
             counter="20"
             class="rounded-0"
             aria-required="true"
             disabled
           >
           </v-text-field>
         </v-layout>
       </v-flex>
       <v-flex md3 sm3 xs6>
         <v-layout column class="cust_usrsrch mx-2 mt-2">
           <p class="font-weight-bold cstm-clr-pln-usrsrch">
              Loading Reason
           </p>
           <v-text-field
             solo
             label="Enter..."
             v-model="formData.loadingReason"
             maxlength="20"
             counter="20"
             class="rounded-0"
             aria-required="true"
             disabled
           >
           </v-text-field>
         </v-layout>
       </v-flex>
       <v-flex md3 sm3 xs6>
         <v-layout column class="cust_usrsrch mx-2 mt-2">
           <p class="font-weight-bold cstm-clr-pln-usrsrch">
            Producer code
           </p>
           <v-text-field
             solo
             label="Enter..."
             v-model="formData.producerCode"
             maxlength="20"
             counter="20"
             class="rounded-0"
             aria-required="true"
             disabled
           >
           </v-text-field>
         </v-layout>
       </v-flex>
       <v-flex md3 sm3 xs6>
         <v-layout column class="cust_usrsrch mx-2 mt-2">
           <p class="font-weight-bold cstm-clr-pln-usrsrch">
            Producer Name
           </p>
           <v-text-field
             solo
             label="Enter..."
             v-model="formData.producerName"
             maxlength="20"
             counter="20"
             class="rounded-0"
             aria-required="true"
             disabled
           >
           </v-text-field>
         </v-layout>
       </v-flex>
       <v-flex md3 sm3 xs6>
         <v-layout column class="cust_usrsrch mx-2 mt-5">
           <p class="font-weight-bold cstm-clr-pln-usrsrch">
              No. of years of Renewal
           </p>
           <v-text-field
             solo
             label="Enter..."
             v-model="formData.numberOfYearRenewal"
             maxlength="20"
             counter="20"
             class="rounded-0"
             aria-required="true"
             disabled
           >
           </v-text-field>
         </v-layout>
       </v-flex>
       <v-flex md3 sm3 xs6>
         <v-layout column class="cust_usrsrch mx-2 mt-5">
           <p class="font-weight-bold cstm-clr-pln-usrsrch">
             Sum Insured
           </p>
           <v-text-field
             solo
             label="Enter..."
             v-model="formData.sumInsured"
             maxlength="20"
             counter="20"
             class="rounded-0"
             aria-required="true"
             disabled
           >
           </v-text-field>
         </v-layout>
       </v-flex>
       <v-flex md3 sm3 xs6>
         <v-layout column class="cust_usrsrch mx-2 mt-2">
           <p class="font-weight-bold cstm-clr-pln-usrsrch mb-1">
              Previously covered by any other Mediclaim/Health Insurance?
           </p>
           <v-text-field
             solo
             label="Enter..."
             v-model="formData.isPreviouslyCoveredByMediclaim"
             maxlength="20"
             counter="20"
             class="rounded-0"
             aria-required="true"
             disabled
           >
           </v-text-field>
         </v-layout>
       </v-flex>
     </v-layout>
   </v-container>
   <InvestigationAccordion />
 </div>
</template>
<script>
import { getPolicyDetails , getClaimDetails } from "../apiservices/Investigation.js"
import InvestigationAccordion from "./InvestigationAccordion.vue"
export default {
   data() {
       return {
           formData: {
               productName: "",
               policyStatus: "",
               policyType: "",
               policyStartDate: "",
               policyEndDate: "",
               policyEffectiveDate: "",
               portableFlag: "",
               previousPolicyNumber: "",
               previousInsurer: "",
               previousSumInsured: "",
               previousEffectiveStartDate: "",
               previousExpiryDate: "",
               isPreviouslyCoveredByMediclaim: "",
               benefitClaimed: "",
               catastrophe: "",
               catastropheType: "",
               catastropheCode: "",
               coverages: "",
               producerCode: "",
               producerName: "",
               numberOfYearRenewal: "",
               sumInsured: ""
           }
       }
   },
   props: ['policyDetailsData', 'otherInfo'],
   components: {InvestigationAccordion},
   async mounted() {
    try{
      let res =  await getPolicyDetails(this.otherInfo);
      Object.keys(this.formData).forEach(item => {
        this.formData[item] = res[item];
      });
      let data = await getClaimDetails(this.otherInfo);
      this.formData.catastrophe =  data.catastrophe;
      this.formData.catastropheType =  data.catestrophicDetails?.catestropheType;
      this.formData.benefitClaimed = data.coverage?.coverGroupName;
      this.formData.producerName = data.producerName;
      this.formData.producerCode = data.producerCode;
    } catch(e) {
      this.showToast(e.response?.data?.msg || e.response?.data  || e.response || e, this.TOST().ERROR);
    }
   }
}
</script>