<template>
    <v-layout no-gutters class="ml-3 flex-wrap">
        <div v-for="card in masters" :key="card.icon" @click="invers(card.link)" class="master-single-card">
            <div class="center">
                <v-badge :color="card.hideCount ? 'transparent' : 'orange'" :content="card.count.toString()" overlap
                    class="mt-5">
                    <v-avatar class="bard">
                        <v-icon class="teal--text">
                            <!-- {{card.icon}} -->mdi-account-check
                        </v-icon>
                    </v-avatar>
                </v-badge>
            </div>
            <div class="masterTitle">{{ card.texttitle }}</div>
        </div>
    </v-layout>
</template>
  
<script>
export default {
    data() {
        return {
            masters: [
                {
                    texttitle: "Base Rate TDS Master",
                    httpRequest: "GET_BASE_RATE_TDS_URL",
                    link: "/tds-master/base-tds-master",
                    count: 0,
                    hideCount: true,
                },
                {
                    texttitle: "Penal Rate TDS Master",
                    httpRequest: "GET_PENAL_RATE_TDS_URL",
                    link: "/tds-master/penal-tds-master",
                    count: 0,
                    hideCount: true,
                },
                {
                    texttitle: "Concessional and Penal for PAN Master",
                    httpRequest: "GET_CONCESSIONAL_AND_PENAL_MASTER_URL",
                    link: "/tds-master/concessional-tds-master",
                    count: 0,
                    hideCount: true,
                },
            ],
        };
    },
    created() {
        // this.getMastersCount();
        this.$store.commit("SAVE_PAGE_HEADER", "TDS-Masters");
    },
    methods: {
        // getMastersCount() {
        //   this.masters.map((master) => {
        //     this.GETM(
        //       master.httpRequest,
        //       "",
        //       (res) => {
        //         master.count = 0;
        //         console.log("yiue", res.data.data);
        //         if (
        //           res.data &&
        //           res.data.data &&
        //           res.data.data.foundData &&
        //           res.data.data.foundData.length > 0
        //         ) {
        //           master.count = res.data.data.totalRecords || 0;
        //         }
        //         if (
        //           res.data &&
        //           res.data.data &&
        //           res.data.data.data &&
        //           res.data.data.data.length > 0
        //         ) {
        //           master.count = res.data.data.totalRecords || res.data.data.total;
        //         }
        //         if (res.data && res.data.data && res.data.data.length > 0) {
        //           master.count = res.data.total || 0;
        //         }
        //       },
        //       (err) => {}
        //     );
        //   });
        // },
        invers(link) {
            this.$router.push(link);
        },
    },
};
</script>
  
<style scoped>
.master-single-card:not(:last-child) {
    margin-right: 8px;
}

.master-single-card:hover {
    border-color: teal;
}

.master-single-card {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 194px;
    height: 150px;
    padding: 0 15px;
    margin-top: 8px;
    background: transparent linear-gradient(180deg, #ffffff 0%, #f0f0f0 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 4px 8px #0000000a;
    border: 2px solid #e0e0e0;
    border-radius: 4px;
}

.center {
    text-align: center;
    margin-top: 10px;
}

.headline {
    font-size: 10px !important;
    font-weight: bold;
    text-align: center;
    line-height: 20px;
}

.bard {
    border: 1px solid #555;
}

.card:hover {
    border: 2px solid teal;
    cursor: pointer;
}

.masterTitle {
    margin: 15px 0;
    word-wrap: break-word;
    font-size: 14px !important;
    text-align: center;
    font-weight: 500;
    font-style: bold;
}
</style>
