<template>
<v-app>
        <div style="background-color: #FFFFFF;" column class="py-3">
            <v-form v-model="lrvalid" ref="lrForm">
        <v-layout class=" mt-2" >
            <v-flex xs3>
                    <v-layout column class="mx-2 my-2">
                        <v-text class="text-uppercase font-weight-bold title-clr-pr">
                            Laboratory Details
                        </v-text>
                        <v-divider></v-divider>
                    </v-layout>
                </v-flex>


                <v-flex xs3>
                    <v-layout column class="cust_pd mx-2 my-2 ">
                        <v-text class="font-weight-bold cstm-clr-pd">
                            Laboratory *
                        </v-text>
                        <v-select
                            :items="items"
                            label="Select"
                            v-model="laboratory"
                            solo
                            :rules="[required()]"
                            dense
                            class="rounded-0"
                            >
                        </v-select>
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class=" mx-2 my-2 cust_pd">
                        <v-text class="font-weight-bold cstm-clr-pd">
                            Cytology *
                        </v-text>
                        <v-select
                            :items="items"
                            label="Select"
                            v-model="cytology"
                            solo
                            :rules="[required()]"
                            dense
                            class="rounded-0"
                            >
                        </v-select>
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class=" mx-2 my-2 cust_pd">
                        <v-text class="font-weight-bold cstm-clr-pd">
                            Histopathology *
                        </v-text>
                        <v-select
                            :items="items"
                            label="Select"
                            v-model="hispathology"
                            solo
                            :rules="[required()]"
                            dense
                            class="rounded-0"
                            >
                        </v-select>
                    </v-layout>
                </v-flex>
            
                <v-flex xs3>
                    <v-layout column class=" mx-2 my-2 cust_pd">
                        <v-text class="font-weight-bold cstm-clr-pd">
                            Microbiology *
                        </v-text>
                        <v-select
                            :items="items"
                            label="Select"
                            v-model="microbiology"
                            solo
                            :rules="[required()]"
                            dense
                            class="rounded-0"
                            >
                        </v-select>
                    </v-layout>
                </v-flex>
                                            
            </v-layout>
                
            <v-layout>
               
               <v-flex xs3>
                    <v-layout column class=" mx-2 my-2 cust_pd">
                        <v-text class="font-weight-bold cstm-clr-pd">
                            Biochemistry *
                        </v-text>
                        <v-select
                            :items="items"
                            label="Select"
                            v-model="biochemist"
                            solo
                            :rules="[required()]"
                            dense
                            class="rounded-0"
                            >
                        </v-select>
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class=" mx-2 my-2 cust_pd">
                        <v-text class="font-weight-bold cstm-clr-pd">
                            Hematology *
                        </v-text>
                        <v-select
                            :items="items"
                            label="Select"
                            v-model="hematology"
                            solo
                            :rules="[required()]"
                            dense
                            class="rounded-0"
                            >
                        </v-select>
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class=" mx-2 my-2 cust_pd">
                        <v-text class="font-weight-bold cstm-clr-pd">
                            Immunology *
                        </v-text>
                        <v-select
                            :items="items"
                            label="Select"
                            v-model="immunology"
                            solo
                            :rules="[required()]"
                            dense
                            class="rounded-0"
                            >
                        </v-select>
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class=" mx-2 my-2 cust_pd">
                        <v-text class="font-weight-bold cstm-clr-pd">
                            Pathology *
                        </v-text>
                        <v-select
                            :items="items"
                            label="Select"
                            v-model="pathology"
                            solo
                            :rules="[required()]"
                            dense
                            class="rounded-0"
                            >
                        </v-select>
                    </v-layout>
                </v-flex>

                <v-flex xs3></v-flex>

        </v-layout>
        <v-divider class=" mt-3"></v-divider>

        <v-layout class=" mt-3" >
            <v-flex xs3>
                    <v-layout column class="mx-2 my-2">
                        <v-text class="text-uppercase font-weight-bold title-clr-pr">
                            Radiology Details
                        </v-text>
                        <v-divider></v-divider>
                    </v-layout>
            </v-flex>

            <v-flex xs3>
                    <v-layout column class=" mx-2 my-2 cust_pd">
                        <v-text class="font-weight-bold cstm-clr-pd">
                            Radiology *
                        </v-text>
                        <v-select
                            :items="items"
                            label="Select"
                            v-model="radiology"
                            solo
                            :rules="[required()]"
                            dense
                            class="rounded-0"
                            >
                        </v-select>
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class=" mx-2 my-2 cust_pd">
                        <v-text class="font-weight-bold cstm-clr-pd">
                            Computer Tomography *
                        </v-text>
                        <v-select
                            :items="items"
                            label="Select"
                            v-model="computerTomo"
                            solo
                            :rules="[required()]"
                            dense
                            class="rounded-0"
                            >
                        </v-select>
                    </v-layout>
                </v-flex>
            
                <v-flex xs3>
                    <v-layout column class=" mx-2 my-2 cust_pd">
                        <v-text class="font-weight-bold cstm-clr-pd">
                            Nuclear Imaging *
                        </v-text>
                        <v-select
                            :items="items"
                            label="Select"
                            v-model="nuclearImage"
                            solo
                            :rules="[required()]"
                            dense
                            class="rounded-0"
                            >
                        </v-select>
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class=" mx-2 my-2 cust_pd">
                        <v-text class="font-weight-bold cstm-clr-pd">
                            Ultrasound *
                        </v-text>
                        <v-select
                            :items="items"
                            label="Select"
                            v-model="ultrasound"
                            solo
                            :rules="[required()]"
                            dense
                            class="rounded-0"
                            >
                        </v-select>
                    </v-layout>
                </v-flex>
            
        </v-layout>

        <v-layout>

            <v-flex xs3>
                    <v-layout column class=" mx-2 my-2 cust_pd">
                        <v-text class="font-weight-bold cstm-clr-pd">
                            Color Doppler *
                        </v-text>
                        <v-select
                            :items="items"
                            label="Select"
                            v-model="colorDopler"
                            solo
                            :rules="[required()]"
                            dense
                            class="rounded-0"
                            >
                        </v-select>
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class=" mx-2 my-2 cust_pd">
                        <v-text class="font-weight-bold cstm-clr-pd">
                            Magnetic Resonance Imaging *
                        </v-text>
                        <v-select
                            :items="items"
                            label="Select"
                            v-model="mri"
                            solo
                            :rules="[required()]"
                            dense
                            class="rounded-0"
                            >
                        </v-select>
                    </v-layout>
                </v-flex>
            
                <v-flex xs3>
                    <v-layout column class=" mx-2 my-2 cust_pd">
                        <v-text class="font-weight-bold cstm-clr-pd">
                            PET Scan *
                        </v-text>
                        <v-select
                            :items="items"
                            label="Select"
                            v-model="petScan"
                            solo
                            :rules="[required()]"
                            dense
                            class="rounded-0"
                            >
                        </v-select>
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class=" mx-2 my-2 cust_pd">
                        <v-text class="font-weight-bold cstm-clr-pd">
                            X-Ray *
                        </v-text>
                        <v-select
                            :items="items"
                            label="Select"
                            v-model="xray"
                            solo
                            :rules="[required()]"
                            dense
                            class="rounded-0"
                            >
                        </v-select>
                    </v-layout>
                </v-flex>

                <v-flex xs3></v-flex>
        </v-layout>

        <v-dialog v-model="confirmDialogCancel" persistent max-width="450px">
                 
                         <v-card tile color="#F7F7F7" >
                            <v-card tile>
                                <v-layout justify-space-between  class="" style="flex:1;background-ox-shadow: 0px 3px 5px #00000029;">
                             
                                    <v-card-title class="pa-0 pl-3 text-capitalize font-weight-regular" style="color:black"> 
                                       CONFIRMATION
                                    </v-card-title>
                                    <div style="border-left:1px solid #C1C8CC;" @click="close_cancelDialog()">
                                        <v-icon style="cursor: pointer;" class="pa-2" >close</v-icon>
                                    </div>
                                </v-layout>
                            </v-card>
                        <v-flex >
                           
                       
                        </v-flex>
                        <v-card-text >
                                Details saved on screen will be lost
                        </v-card-text>
                        <v-card-actions justify-start>
                          
                           
                           
                            <v-btn dark  tile color="black" @click="confirmDialogCancel = false" class="text-capitalize px-3 mx-2" small><v-icon dark small>cancel</v-icon> <v-divider vertical class="mx-2"></v-divider>Cancel</v-btn>

                            <!-- <v-btn depressed small color="secondary" class="rounded-0"  >
                                <v-icon dark right>check_circle</v-icon>
                            </v-btn> -->
                            <v-btn @click="selectedCompFromPage(arrToNavCancel[1])" dark  tile color="#23B1A9" class="text-capitalize px-3 ml-2" small><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider> OK</v-btn>

                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <v-dialog v-model="confirmDialogNext" persistent max-width="450px">
                 
                         <v-card tile color="#F7F7F7" >
                            <v-card tile>
                                <v-layout justify-space-between  class="" style="flex:1;background-ox-shadow: 0px 3px 5px #00000029;">
                             
                                    <v-card-title class="pa-0 pl-3 text-capitalize font-weight-regular" style="color:black"> 
                                       CONFIRMATION
                                    </v-card-title>
                                    <div style="border-left:1px solid #C1C8CC;" @click="close_cancelDialog()">
                                        <v-icon style="cursor: pointer;" class="pa-2" >close</v-icon>
                                    </div>
                                </v-layout>
                            </v-card>
                        <v-flex >
                           
                       
                        </v-flex>
                        <v-card-text >
                                Details entered on the page will be lost if not saved
                        </v-card-text>
                        <v-card-actions justify-start>
                          
                           
                           
                            <v-btn dark  tile color="black" @click="confirmDialogNext = false" class="text-capitalize px-3 mx-2" small><v-icon dark small>cancel</v-icon> <v-divider vertical class="mx-2"></v-divider>Cancel</v-btn>

                            <!-- <v-btn depressed small color="secondary" class="rounded-0"  >
                                <v-icon dark right>check_circle</v-icon>
                            </v-btn> -->
                            <v-btn @click="selectedCompFromPage('ProviderEmpanelmentStaffDetails')" dark  tile color="#23B1A9" class="text-capitalize px-3 ml-2" small><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider> OK</v-btn>

                        </v-card-actions>
                    </v-card>
                </v-dialog>

    <div class="mx-2 pa-5" style="background-color: #F7F7F7; display:flex;justify-content:space-between;"  >
                <v-layout style="flex:1" justify-end > 
                    <v-btn dark  tile color="#E46A25" class="text-capitalize px-3 mx-2" small @click="cancelData()"><v-icon dark small>cancel</v-icon> <v-divider vertical class="mx-2"></v-divider>Cancel</v-btn>
                    <v-btn dark  tile color="#D1121B" class="text-capitalize px-3 mx-2" small @click="LabRadioloy_Clear()"><v-icon dark small >delete</v-icon> <v-divider vertical class="mx-2"></v-divider>Clear</v-btn>
                    <v-btn dark  tile color="#23B1A9" class="text-capitalize px-3 ml-2" small @click="LabRadioloy_save()"><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider> Save</v-btn>
                    <v-btn dark  tile color="black" class="text-capitalize px-3 ml-4" small @click="nextBtn()"><v-icon dark small>arrow_forward</v-icon> <v-divider vertical class="mx-2"></v-divider> Next</v-btn>
                </v-layout>
            </div>
         </v-form>
        </div>
        
    </v-app>
</template>

<script>
  
export default {
    inject : ['selectedCompFromPage', 'arrToNavCancel'],
    created() {
        let userID = this.$store.state.empanelProviderData.empanelment._id
        let _proviType = this.$store.state.empanelProviderData.empanelment.providerType
    this.getEmpanelmentDetailsbyId(userID)
  },

    data() {
        return {
            confirmDialogCancel:false,
            confirmDialogNext:false,
            laboratory:'',
            cytology:'',
            hispathology:'',
            microbiology:'',
            biochemist:'',
            hematology:'',
            immunology:'',
            pathology:'',
            radiology:'',
            computerTomo:'',
            nuclearImage:'',
            ultrasound:'',
            colorDopler:'',
            mri:'',
            petScan:'',
            xray:'',
            items:["Inhouse","Outsourced","Not available"],
            required(){
                return v=>v && v.length>0 || "This Field is Required"
            },
            minLength(minLength){
                return v=>v && v.length>=minLength || `Must be atleast ${minLength} Characters`
            },
            maxLength(maxLength){
                return v=>v && v.length<=maxLength || `Must be less than ${maxLength} Characters`
            },
            spclChar(){
                return v=>/^[A-Za-z0-9 ]+$/.test(v) || `Special Characters Not Allowed`
            },
            alphaNumeric(){
                return v=>/^[0-9 ]+$/.test(v) || `Only Numbers are allowed.`
            },
            numberRule(){
                // if (!v.trim()) return true;
                // if (!isNaN(parseFloat(v)) && v >= 0 && v <= 999) return true;
                // return 'Number has to be between 0 and 999';
                return v=>v && v>0 || "Cannot be zero"
            },
            decimalRule(){
                return v=>/^\d+(\.\d{1,2})?$/.test(v) || `Numeric upto two decimals.`
            }

        }
        
    },
     methods: {

        LabRadioloy_Clear(){
            this.$refs.lrForm.resetValidation()

            this.laboratory=''
            this.biochemist=''
            this.cytology=''
            this.hematology=''
            this.hispathology=''
            this.immunology=''
            this.microbiology=''
            this.pathology=''
            this.radiology=''
            this.colorDopler=''
            this.computerTomo=''
            this.mri=''
            this.nuclearImage=''
            this.petScan=''
            this.ultrasound=''
            this.xray=''
        },

        LabRadioloy_save(){
            if (this.$refs.lrForm.validate()){
                let userID = this.$store.state.empanelProviderData.empanelment._id
                let _proviType = this.$store.state.empanelProviderData.empanelment.providerType
                this.showToast("Success",this.TOST().SUCCESS);
                let formData={
                    _id: userID,
                    providerType:_proviType,
                    lab: {
                        laboratory: this.laboratory,
                        biochemistry: this.biochemist,
                        cytology: this.cytology,
                        hemotology: this.hematology,
                        histopathology: this.hispathology,
                        immunology: this.immunology,
                        microbiology: this.microbiology,
                        pathology: this.pathology,
                    },
                    radio: {
                        radiology: this.radiology,
                        colorDoppler: this.colorDopler,
                        computerTomography: this.computerTomo,
                        magneticResonanceImaging: this.mri,
                        nuclearImaging: this.nuclearImage,
                        PetScan: this.petScan,
                        ultrasound: this.ultrasound,
                        xRay: this.xray,
                    }
                    
                }
                console.log(formData,"=========lrForm Data")
                let api_Is = 'empanelment/save-lab-radioalogy'
                this.PUT(2,api_Is,formData,(res,error)=>{
                     if(!error){
                        console.log("PUT APi response|-> empanelment/save-lab-radioalogy ", res)
                        this.showToast(res.data.msg,this.TOST().SUCCESS)
                        this.getEmpanelmentDetailsbyId(userID)
                          
                    }else{
                        console.log("PUT APi error response|->empanelment/save-lab-radioalogy", err)
                        this.showToast(error.response.data.msg ,this.TOST().ERROR);

                    }    
                })

            }else{
                this.$refs.lrForm.validate()
                this.showToast("Please Fill Required Fields",this.TOST().WARNING);
            }

        },

        getEmpanelmentDetailsbyId(id){
            let api_Is = 'empanelment/empanelment-details-by-id/'+id

            this.GET(2,api_Is,(res,error)=>{
                     try{
                         if(!error){
                            console.log("GET APi response|-> empanelment/empanelment-details-by-id", res.data.data)
                            // this.showToast(res.data.msg,this.TOST().SUCCESS)
                            let tempData= res.data.data
                            this.laboratory=tempData.hasOwnProperty('lab')? tempData.lab.hasOwnProperty('laboratory')? this.chkplndata(tempData.lab.laboratory) :'' :'',
                            this.biochemist=tempData.hasOwnProperty('lab')? tempData.lab.hasOwnProperty('biochemistry')? this.chkplndata(tempData.lab.biochemistry) :'' :'',
                            this.cytology=tempData.hasOwnProperty('lab')? tempData.lab.hasOwnProperty('cytology')? this.chkplndata(tempData.lab.cytology) :'' :'',
                            this.hematology=tempData.hasOwnProperty('lab')? tempData.lab.hasOwnProperty('hemotology')? this.chkplndata(tempData.lab.hemotology) :'' :'',
                            this.hispathology=tempData.hasOwnProperty('lab')? tempData.lab.hasOwnProperty('histopathology')? this.chkplndata(tempData.lab.histopathology) :'' :'',
                            this.immunology=tempData.hasOwnProperty('lab')? tempData.lab.hasOwnProperty('immunology')? this.chkplndata(tempData.lab.immunology) :'' :'',
                            this.microbiology=tempData.hasOwnProperty('lab')? tempData.lab.hasOwnProperty('microbiology')? this.chkplndata(tempData.lab.microbiology) :'' :'',
                            this.pathology=tempData.hasOwnProperty('lab')? tempData.lab.hasOwnProperty('pathology')? this.chkplndata(tempData.lab.pathology) :'' :'',


                            this.radiology=tempData.hasOwnProperty('radio')? tempData.radio.hasOwnProperty('radiology')? this.chkplndata(tempData.radio.radiology) :'' :'',
                            this.colorDopler=tempData.hasOwnProperty('radio')? tempData.radio.hasOwnProperty('colorDoppler')? this.chkplndata(tempData.radio.colorDoppler) :'' :'',
                            this.computerTomo=tempData.hasOwnProperty('radio')? tempData.radio.hasOwnProperty('computerTomography')? this.chkplndata(tempData.radio.computerTomography) :'' :'',
                            this.mri=tempData.hasOwnProperty('radio')? tempData.radio.hasOwnProperty('magneticResonanceImaging')? this.chkplndata(tempData.radio.magneticResonanceImaging) :'' :'',
                            this.nuclearImage=tempData.hasOwnProperty('radio')? tempData.radio.hasOwnProperty('nuclearImaging')? this.chkplndata(tempData.radio.nuclearImaging) :'' :'',
                            this.petScan=tempData.hasOwnProperty('radio')? tempData.radio.hasOwnProperty('PetScan')? this.chkplndata(tempData.radio.PetScan) :'' :'',
                            this.ultrasound=tempData.hasOwnProperty('radio')? tempData.radio.hasOwnProperty('ultrasound')? this.chkplndata(tempData.radio.ultrasound) :'' :'',
                            this.xray=tempData.hasOwnProperty('radio')? tempData.radio.hasOwnProperty('xRay')? this.chkplndata(tempData.radio.xRay) :'' :''
                            this.$store.commit("EMPANEL_TAB_STATUS", tempData);
                            
                          
                        }else{
                            console.log("GET APi error response|->empanelment/empanelment-details-by-id", error)
                            // this.showToast(err.response.data.msg ,this.TOST().ERROR);
                        } 
                    }catch{
                         console.log("GET APi  errorresponse|-> empanelment/empanelment-details-by-id", error)
                    }
                        
                })

        },
        chkplndataarry(data){
            console.log("chkplndataarry",data); 
            try{
                if (data ==="" || data === "undefined" || data === null) {
                    return []
                } else {
                    return data;
                }

             }catch(err) {
                console.log(err)
            }
            
        },

        chkplndata(data){
            console.log("chkplndata",data); 
            try{
                if (data ==="" || data === "undefined" || data === null) {
                    return ""
                } else {
                    return data;
                }

             }catch(err) {
                console.log(err)
            }
            
        },


        // nextBtn(){
        //     this.confirmDialogNext = true
        // },

        nextBtn(){
                console.log("%c this is data for next btn on OwnershipTab","color:green",this.$store.state.empanelmentTabStatus);
                if(this.$store.state.empanelmentTabStatus.labAndRadiologyStatus === false){
                    this.confirmDialogNext = true
                }else{
                        this.selectedCompFromPage('ProviderEmpanelmentStaffDetails')
                }
            },


        cancelData(){
            this.confirmDialogCancel = true
        },
        close_cancelDialog(){
            this.confirmDialogCancel = false
            this.confirmDialogNext = false
        },
       
    },

}
</script>

<style>

.cust-rmvpd-psins3 .v-text-field.v-text-field--enclosed>.v-input__control>.v-input__slot {
    padding-right: 0px !important;
}

.cust_pr2 .v-text-field .v-input__slot,
.v-text-field--outline .v-input__slot {
    min-height: 42px !important;
    display: flex !important;
    align-items: center !important;
    /* border:1px red solid; */
}

.cstm-clr-pr3{
    color:#646666 !important;
    margin-bottom: 5px !important;
    font-size: 13px !important;
}

.cust_pd1 .v-text-field .v-input__slot,
.v-text-field--outline .v-input__slot {
    min-height: 42px !important;
    display: flex !important;
    align-items: center !important;
    /* border:1px red solid; */
}

.cust-rmvpd-pr1 .v-text-field.v-text-field--enclosed>.v-input__control>.v-input__slot {
    padding: 0px !important;
}


</style>