<template>
  <v-app>
    <v-card class="mainCard rounded-0 pb-2">
      <v-layout class="cardLayout mx-4" row>
        <v-flex md3 sm6 xs12 v-for="i in items" :key="i.sno">
          <v-btn
            :id="'myBtn' + i.sno"
            @click="switchTabs(i.sno, i.name)"
            color="#ffffff"
            :class="{ activeTab: sno === i.sno }"
            height="3rem"
            width="-webkit-fill-available"
            elevation="1"
            class="mx-2 mt-4 font-weight-bold"
            >{{ i.name }}</v-btn
          >
        </v-flex>
      </v-layout>
    </v-card>
    <BasicDetails
      :prepoulatedData="prepoulatedData.basicDetails"
      :siuWorkItemId = "siuWorkItemId"
      :tabFormDataId="tabFormDataId"
      class="main-wrapper"
      v-if="sno == 1"
    />
    <PolicyDetails
      :prepoulatedData="prepoulatedData.policyDetails"
      :siuWorkItemId = "siuWorkItemId"
      :tabFormDataId="tabFormDataId"
      class="main-wrapper"
      v-if="sno == 2"
    />
    <PayerDetails
      :prepoulatedData="prepoulatedData.premimunPaymentOrPayerDetails"
      :siuWorkItemId = "siuWorkItemId"
      :tabFormDataId="tabFormDataId"
      class="main-wrapper"
      v-if="sno == 3"
    />
    <HospitalDetails
      :prepoulatedData="prepoulatedData.hospitalDetails"
      :siuWorkItemId = "siuWorkItemId"
      :tabFormDataId="tabFormDataId"
      class="main-wrapper"
      v-if="sno == 4"
    />
    <Hospitalization
      :prepoulatedData="prepoulatedData.hospitalizationAndAccidentDetails"
      :siuWorkItemId = "siuWorkItemId"
      :tabFormDataId="tabFormDataId"
      class="main-wrapper"
      v-if="sno == 5"
    />
    <MemberVerification
      :prepoulatedData="prepoulatedData.memberVerificationDetail"
      :siuWorkItemId = "siuWorkItemId"
      :tabFormDataId="tabFormDataId"
      class="main-wrapper"
      v-if="sno == 6"
    />
    <ExternalPathology
      :prepoulatedData="prepoulatedData.externalPathoLogyLabDetails"
      :siuWorkItemId = "siuWorkItemId"
      :tabFormDataId="tabFormDataId"
      class="main-wrapper"
      v-if="sno == 7"
    />
    <ExternalRadiology
      :prepoulatedData="prepoulatedData.externalRadiologyLabDetails"
      :siuWorkItemId = "siuWorkItemId"
      :tabFormDataId="tabFormDataId"
      class="main-wrapper"
      v-if="sno == 8"
    />
    <ExternalSonology
      :prepoulatedData="prepoulatedData.externalSonologyLabDetails"
      :siuWorkItemId = "siuWorkItemId"
      :tabFormDataId="tabFormDataId"
      class="main-wrapper"
      v-if="sno == 9"
    />
    <ExternalPharmacy
      :prepoulatedData="prepoulatedData.externalPharmacyDetails"
      :siuWorkItemId = "siuWorkItemId"
      :tabFormDataId="tabFormDataId"
      class="main-wrapper"
      v-if="sno == 10"
    />
    <EmployerVerificationDetails
      :prepoulatedData="prepoulatedData.employerVerificationDetails"
      :siuWorkItemId = "siuWorkItemId"
      :tabFormDataId="tabFormDataId"
      class="main-wrapper"
      v-if="sno == 11"
    />
    <NeighbourVerificationDetails
      :prepoulatedData="
        prepoulatedData.neighbourOrSarpanchOrVicinityVerificationDetails
      "
      :siuWorkItemId = "siuWorkItemId"
      :tabFormDataId="tabFormDataId"
      class="main-wrapper"
      v-if="sno == 12"
    />
    <SpotVerification
      :prepoulatedData="prepoulatedData.spotVerificationDetails"
      :siuWorkItemId = "siuWorkItemId"
      :tabFormDataId="tabFormDataId"
      class="main-wrapper"
      v-if="sno == 13"
    />
    <FirVerificationDetails
      :prepoulatedData="prepoulatedData.fIROrGDRVerificationDetails"
      :siuWorkItemId = "siuWorkItemId"
      :tabFormDataId="tabFormDataId"
      class="main-wrapper"
      v-if="sno == 14"
    />
    <AccidentDetails
      :prepoulatedData="prepoulatedData.accidentVehicleInvolvedDetails"
      :siuWorkItemId = "siuWorkItemId"
      :tabFormDataId="tabFormDataId"
      class="main-wrapper"
      v-if="sno == 15"
    />
    <!-- <TravelVerification :prepoulatedData="prepoulatedData" class="main-wrapper" v-if="sno == 16" /> -->
    <DocumentVerification
      :prepoulatedData="prepoulatedData.documentVerificationDetails"
      :siuWorkItemId = "siuWorkItemId"
      :tabFormDataId="tabFormDataId"
      class="main-wrapper"
      v-if="sno == 17"
    />
    <ForensicReport
      :prepoulatedData="prepoulatedData.forensicReportRequired"
      :siuWorkItemId = "siuWorkItemId"
      :tabFormDataId="tabFormDataId"
      class="main-wrapper"
      v-if="sno == 18"
    />
    <FinalObservation
      :prepoulatedData="prepoulatedData.finalObservationOfInvestigator"
      :qc1ObservationAndRemarksData="prepoulatedData.qc1ObservationAndRemarks"
      :qc2ObservationAndRemarksData="prepoulatedData.qc2ObservationAndRemarks"
      :claimExaminerObservationAndRemarksData="prepoulatedData.claimExaminerObservationAndRemarks"
      :siuWorkItemId = "siuWorkItemId"
      :tabFormDataId="tabFormDataId"
      class="main-wrapper"
      v-if="sno == 19"
    />
    <UploadInvestigationDoc
      :prepoulatedData="prepoulatedData"
      :siuWorkItemId = "siuWorkItemId"
      :tabFormDataId="tabFormDataId"
      class="main-wrapper"
      v-if="sno == 20"
    />
    <InvoiceDetails v-if="sno == 21" />
    <GeneralDetails v-if="sno == 22" />
    <!-- <SiuNotes v-if="sno == 23" /> -->
  </v-app>
</template>

<script>
import moment from "moment";
import axios from "axios";
import Hospitalization from "../components/Hospitalization.vue";
import BasicDetails from "../components/BasicDetails.vue";
import PolicyDetails from "../components/PolicyDetails.vue";
import PayerDetails from "../components/PayerDetails.vue";
import HospitalDetails from "../components/HospitalDetails.vue";
import MemberVerification from "../components/MemberVerification.vue";
import ExternalPathology from "../components/ExternalPathology.vue";
import ExternalRadiology from "../components/ExternalRadiology.vue";
import ExternalSonology from "../components/ExternalSonology.vue";
import ExternalPharmacy from "../components/ExternalPharmacy.vue";
import EmployerVerificationDetails from "../components/EmployerVerificationDetails.vue";
import NeighbourVerificationDetails from "../components/NeighbourVerificationDetails.vue";
import SpotVerification from "../components/SpotVerification.vue";
import FirVerificationDetails from "../components/FirVerificationDetails.vue";
import AccidentDetails from "../components/AccidentDetails.vue";
import TravelVerification from "../components/TravelVerification.vue";
import DocumentVerification from "../components/DocumentVerification.vue";
import ForensicReport from "../components/ForensicReport.vue";
import FinalObservation from "../components/FinalObservation.vue";
import UploadInvestigationDoc from "../components/UploadInvestigationDoc.vue";
import GeneralDetails from "../components/GeneralDetails.vue";
import SiuNotes from "../components/SiuNotes.vue";
import InvestigationFooterCard from "../components/InvestigationFooterCard.vue";
import { getPrePopulatedData } from "../apiservices/Investigation";
import InvoiceDetails from "../components/InvoiceDetails.vue";

export default {
  data() {
    return {
      numberOfErrors: 0,
      prepoulatedData: [],
      all_attachments: [],
      documentAttachments: [],
      isView: false,
      visualEffect: false,
      validationmessage: "This field is required",
      claimTypeList: ["Health-Cashless", "Health-Reimbursement", "Travel"],
      admissionList: ["c"],
      rules: {
        mandatoryrule: [(v) => !!v || this.validationmessage],
      },
      dropDownCommonData: ["Yes", "No"],
      isAlertView: false,
      tab: 1,
      temp: new Date(Date.now() - new Date().getTimezoneOffset()),
      sno: 22,
      tabName: "",
      listOfAdmittedHospital: ["India", "America"],
      items: [],
      tabKeys: [],
      staticData: [
        { sno: "1", name: "Basic Details", value: "basicDeatils" },
        {
          sno: "2",
          name: "Policy / Insured Details",
          value: "policyInsuredDetails",
        },
        {
          sno: "3",
          name: "Premium Payment / Payer Details",
          value: "premimunPaymentOrPayerDetails",
        },
        { sno: "4", name: "Hospital Details", value: "hospitalDetails" },
        {
          sno: "5",
          name: "Hospitalization / Accident Details",
          value: "hospitalizationAndAccidentDetails",
        },
        {
          sno: "6",
          name: "Member Verification Details",
          value: "memberVerificationDetail",
        },
        {
          sno: "7",
          name: "External Pathology Lab Details",
          value: "externalPathoLogyLabDetails",
        },
        {
          sno: "8",
          name: "External Radiology Lab Detials",
          value: "externalRadiologyLabDetails",
        },
        {
          sno: "9",
          name: "External Sonology Lab Details",
          value: "externalSonologyLabDetails",
        },
        {
          sno: "10",
          name: "External Pharmacy Details",
          value: "externalPharmacyDetails",
        },
        {
          sno: "11",
          name: "Employer Verification Details",
          value: "employerVerificationDetails",
        },
        {
          sno: "12",
          name: "Neighbour / Sarpanch / Vicinity / Verification Details",
          value: "neighbourOrSarpanchOrVicinityVerificationDetails",
        },
        {
          sno: "13",
          name: "Spot Verification Details",
          value: "spotVerificationDetails",
        },
        {
          sno: "14",
          name: "FIR / GDR Verification Details",
          value: "fIROrGDRVerificationDetails",
        },
        {
          sno: "15",
          name: "Accdent Vehicle Involved Details",
          value: "accidentVehicleInvolvedDetails",
        },
        {
          sno: "17",
          name: "Document Verification Details",
          value: "documentVerificationDetails",
        },
        {
          sno: "18",
          name: "Forensic Report Required",
          value: "forensicReportRequired",
        },
        { sno: "19", name: "Final Observation of the Investigator", value: "finalObservationOfInvestigator" },
        {
          sno: "20",
          name: "Investigator Documents",
          value: "viewUploadDocuments",
        },
        { sno: "21", name: "Invoice Details", value: "invoiceDetails" },
        { sno: "22", name: "General Details", value: "generalTab" },
        { sno: "23", name: "SIU Notes", value: "siuNotes" },
      ],
      required() {
        return (v) => (v && v.length > 0) || "This Field is Required";
      },
      currentRole: "",
      isSiuUser: false,
      siuRoles: ["CG77", "CG78", "CG79", "CG80", "CG81", "CG82"],
      siuWorkItemId: "",
      tabFormDataId: "",
    };
  },
  created() {
    this.prePopulateTabsData(false);
    // this.items = this.$store.state.tabs
    //   ? this.$store.state.tabs
    //   : JSON.parse(sessionStorage.getItem("tabs"));
  },
  components: {
    Hospitalization,
    BasicDetails,
    PolicyDetails,
    PayerDetails,
    HospitalDetails,
    MemberVerification,
    ExternalPathology,
    ExternalPharmacy,
    ExternalRadiology,
    ExternalSonology,
    EmployerVerificationDetails,
    NeighbourVerificationDetails,
    SpotVerification,
    FirVerificationDetails,
    AccidentDetails,
    TravelVerification,
    DocumentVerification,
    ForensicReport,
    FinalObservation,
    UploadInvestigationDoc,
    GeneralDetails,
    SiuNotes,
    InvestigationFooterCard,
    InvoiceDetails,
  },
  computed: {
    emailRules2(v) {
      if (v.length !== 0) {
        console.log("testing", /^[0-9 ]+$/.test(v));
        if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,6})+$/.test(v)) {
          return "E-mail must be valid";
        }
      }
    },
  },
  mounted() {
    this.$store.commit("SET_INVESTIGATION_FORMDATA", this.prepoulatedData);
  },
  methods: {
    async prePopulateTabsData(isSecondTime){
      this.currentRole = this.fetchRoleValue();
    this.isSiuUser = this.siuRoles.some(
      (r) => this.currentRole.indexOf(r) >= 0
    );
    if (this.isSiuUser) {
      if (this.$route.params.investigationWorkitemId) {
        this.siuWorkItemId = this.prepoulatedData.siuWorkItemId;
        try {
          let res = await getPrePopulatedData(this.$route.params.investigationWorkitemId);
          this.prepoulatedData = res;
          this.tabFormDataId = this.$route.params.investigationWorkitemId;
          if(isSecondTime){
            return;
          }
          this.getTabsDataFromObj(res);
        } catch (e) {
          this.showToast(e.response?.data?.msg || e.response?.data  || e.response || e, this.TOST().ERROR);
        }
      } else {
        this.items = [
          { sno: "22", name: "General Details", value: "generalTab" },
        ];
      }
    } else {
      this.$store.commit("SET_INVESTIGATION_FORMDATA", this.$route.params.tabData);
      this.prepoulatedData = this.$store.state.investigationFormData;
      this.siuWorkItemId = this.$route.params.tabData.siuWorkItemId;
      this.tabFormDataId = this.$route.params.tabData._id;
      if(isSecondTime){
        return;
      }
      this.getTabsDataFromObj(this.$route.params.tabData);
    }
    },
    getTabsDataFromObj(res) {
      this.tabKeys = res;
      let tabsList = [];
      let tabs = Object.keys(this.tabKeys);
      for (let i = 0; i < tabs.length; i++) {
        if (this.tabKeys[tabs[i]].isTabSelected) {
          tabsList.push(tabs[i]);
        }
      }
      this.staticData.some((el, index) => {
        if (tabsList.indexOf(el.value) >= 0) {
          this.items.push(this.staticData[index]);
        }
      });
      console.log("RESSSS", this.items);
    },
    async switchTabs(sno, name) {
      if(this.isSiuUser) {
        await this.prePopulateTabsData(true);
      } else {
        if(this.$store.state.investigationFormData) {
          this.prepoulatedData = this.$store.state.investigationFormData;
        }
      }
      this.sno = sno;
      this.tabName = name;
    },
  },
};
</script>

<style scoped>
.mainCard {
  background-color: #01bbd9;
  height: auto;
  width: 100vw;
}

.activeTab {
  /* background-color: black !important; */
  color: #01bbd9 !important;
}

.dragbackground {
  background-color: #e7e7e7;
}

.textClass {
  color: white !important;
  font-weight: bold;
  font-size: 12px !important;
}
.bgColor {
  background-color: #01bbd9;
}
.main-wrapper {
  padding: 30px;
}
</style>
