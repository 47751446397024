<template>
  <div>
    <v-container fluid>
      <v-layout row>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Country of Travel
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="countryOfTravel"
              maxlength="20"
              counter="20"
              class="rounded-0"
              aria-required="true"
              :rules="[alphaNumeric(countryOfTravel, 'Country of Travel')]"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Date of Leaving India
            </v-text>
            <v-menu
              ref="calender"
              :close-on-content-click="true"
              :nudge-right="40"
              transition="scale-transition"
              max-width="290px"
              min-width="290px"
              class="rounded-0"
            >
              <!-- :rules="typeOfEmployee !== 'Service ID'?[required()]:[()]" -->
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-bind="attrs"
                  label="Select.."
                  :value="computedDate('dateOfLeavingIndia')"
                  solo
                  class="rounded-0"
                  v-on="on"
                >
                  <div
                    slot="prepend-inner"
                    style="background-color: #f7f7f7; padding: 9px !important"
                  >
                    <v-icon>event</v-icon>
                  </div>
                </v-text-field>
              </template>
              <v-date-picker v-model="dateOfLeavingIndia" no-title>
              </v-date-picker>
            </v-menu>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Return Date
            </v-text>
            <v-menu
              ref="calender"
              :close-on-content-click="true"
              :nudge-right="40"
              transition="scale-transition"
              max-width="290px"
              min-width="290px"
              class="rounded-0"
            >
              <!-- :rules="typeOfEmployee !== 'Service ID'?[required()]:[()]" -->
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-bind="attrs"
                  label="Select.."
                  :value="computedDate('returnDate')"
                  solo
                  class="rounded-0"
                  v-on="on"
                >
                  <div
                    slot="prepend-inner"
                    style="background-color: #f7f7f7; padding: 9px !important"
                  >
                    <v-icon>event</v-icon>
                  </div>
                </v-text-field>
              </template>
              <v-date-picker v-model="returnDate" no-title> </v-date-picker>
            </v-menu>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Purpose of Visit
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="purposeOfVisit"
              maxlength="20"
              counter="20"
              class="rounded-0"
              aria-required="true"
              :rules="[alphaNumeric(purposeOfVisit, 'Purpose of Visit')]"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Passport Number
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="passportNumber"
              maxlength="20"
              counter="20"
              class="rounded-0"
              aria-required="true"
              :rules="[alphaNumeric(passportNumber, 'Passport Number')]"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Visa Validity
            </v-text>
            <v-menu
              ref="calender"
              :close-on-content-click="true"
              :nudge-right="40"
              transition="scale-transition"
              max-width="290px"
              min-width="290px"
              class="rounded-0"
            >
              <!-- :rules="typeOfEmployee !== 'Service ID'?[required()]:[()]" -->
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-bind="attrs"
                  label="Select.."
                  :value="computedDate('visaValidity')"
                  solo
                  class="rounded-0"
                  v-on="on"
                >
                  <div
                    slot="prepend-inner"
                    style="background-color: #f7f7f7; padding: 9px !important"
                  >
                    <v-icon>event</v-icon>
                  </div>
                </v-text-field>
              </template>
              <v-date-picker v-model="visaValidity" no-title> </v-date-picker>
            </v-menu>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Hospital Name
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="hospitalName"
              maxlength="20"
              counter="20"
              class="rounded-0"
              aria-required="true"
              :rules="[alphaNumeric(hospitalName, 'Hospital Name')]"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Any PED/ND (Y/N)
            </v-text>
            <v-select
              :items="lossStatesList"
              label="Select"
              v-model="anyPedNdYn"
              :rules="rules.mandatoryrule"
              solo
              item-text="state"
              item-value="state"
              class="rounded-0"
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Pre Boarding Treatment Details
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="preBoardingTreatmentDetails"
              maxlength="20"
              counter="20"
              class="rounded-0"
              aria-required="true"
              :rules="[
                alphaNumeric(
                  preBoardingTreatmentDetails,
                  'Pre Boarding Treatment Details'
                ),
              ]"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Post Return Treatment Details
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="postReturnTreatmentDetails"
              maxlength="20"
              counter="20"
              class="rounded-0"
              aria-required="true"
              :rules="[
                alphaNumeric(
                  postReturnTreatmentDetails,
                  'Post Return Treatment Details'
                ),
              ]"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Family Physician Name
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="familyPhysicianName"
              maxlength="20"
              counter="20"
              class="rounded-0"
              aria-required="true"
              :rules="[
                alphaNumeric(familyPhysicianName, 'Family Physician Name'),
              ]"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Mediclaim Policy Details
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="mediclaimPolicyDetails"
              maxlength="20"
              counter="20"
              class="rounded-0"
              aria-required="true"
              :rules="[
                alphaNumeric(
                  mediclaimPolicyDetails,
                  'Mediclaim Policy Details'
                ),
              ]"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Previous Travel Policy Details
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="previousTravelPolicyDetails"
              maxlength="20"
              counter="20"
              class="rounded-0"
              aria-required="true"
              :rules="[
                alphaNumeric(
                  previousTravelPolicyDetails,
                  'Previous Travel Policy Details'
                ),
              ]"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Details of Current Medication
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="detailsOfCurrentMedication"
              maxlength="20"
              counter="20"
              class="rounded-0"
              aria-required="true"
              :rules="[
                alphaNumeric(
                  detailsOfCurrentMedication,
                  'Details of Current Medication'
                ),
              ]"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Remarks
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="remarksTravel"
              maxlength="20"
              counter="20"
              class="rounded-0"
              aria-required="true"
              :rules="[alphaNumeric(remarksTravel, 'Remarks')]"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-container>
    <InvestigationFooterCard 
      :tabno="sno" :formdata="getFormData" 
      :nonMandatoryFields="nonMandatoryFields" 
      :inputFieldCorrectlyFilled="true"
      :enableFieldObject="{}"
    />
  </div>
</template>
<script>
 import InvestigationFooterCard from './InvestigationFooterCard.vue';
 import moment  from 'moment';
 export default {
    name: 'TravelVerfication',
    data() {
        return {
            sno: 16,
            lossStatesList: ['A'],
            validationmessage: "This field is required",
            rules: {
                mandatoryrule: [(v) => !!v || this.validationmessage],
            },
            countryOfTravel: "",
            dateOfLeavingIndia: "",
            returnDate: "",
            purposeOfVisit: "",
            passportNumber: "",
            visaValidity: "",
            hospitalName: "",
            anyPedNdYn: "",
            preBoardingTreatmentDetails: "",
            postReturnTreatmentDetails: "",
            familyPhysicianName: "",
            mediclaimPolicyDetails: "",
            previousTravelPolicyDetails: "",
            detailsOfCurrentMedication: "",
            remarksTravel: "",
            enableFieldsArr: [],
            nonMandatoryFields: []

        }
    },
    // created() {
    //   let res = getPrePopulatedData();
    //   let allKeys = Object.keys(res);
    //   allKeys.forEach(key => {
    //     this[key] = x[key]
    //   })
    // },
    components: {
      InvestigationFooterCard 
    },
  computed: {
    emailRules2(v) {
      if (v.length !== 0) {
        console.log("testing", /^[0-9 ]+$/.test(v));
        if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,6})+$/.test(v)) {
          return "E-mail must be valid";
        }
      }
    },
    
    getFormData() {
      return this.$data;
    }
  },
//   mounted() {
//     let found = this.items.find(el => el.sno == 20);
//     if(!found){
//       this.items.push({sno: 20, name: "View / Upload Documents"});
//     }
//     console.log("INDEX NUMBER 20", found);
    
//   },
  methods: {
    dragleave(event) {
      this.visualEffect = false
    },
    browseCancel() {
      //to select attachments
    //   document.getElementById("cancelchequeupload").click()
    },
    dragover(event) {
      event.preventDefault()
      this.visualEffect = true
    },
    drop(event) {
      event.preventDefault()
    //   this.selectFile() // Trigger the onChange event manually
      this.visualEffect = false
    },
    computedDate(name) {
      return this[name] ? moment(this[name]).format("DD/MM/YYYY") : "";
    },
    goToPreviousTab() {
      this.sno--;
      let btnNumber = "myBtn" + this.sno;
      const elem = this.$refs[btnNumber];
      elem[0].$el.click();
    },
    goToNextTab() {
      this.sno++;
      console.log('this', this.$children.$options);
      let btnNumber = "myBtn" + this.sno;
      const elem = this.$refs[btnNumber];
      elem[0].$el.click();
    },
    switchTabs(sno, name) {
      this.sno = sno;
      this.tabName = name;
    },
    submit() {
      this.isAlertView = true;
    },
    accept() {
      this.isAlertView = true;
    },
    saveData() {},
    alphaNumeric(fieldName, name, mandatory=true) {
      console.log('fieldName', fieldName, name);
      if (fieldName.length > 0) {
        return (v) => /^[a-zA-Z0-9]+$/.test(v) || "Enter valid " + name;
      }
    },
    numericOnly() {
      return (v) => /^[0-9 ]+$/.test(v) || `Only Numbers are allowed.`;
    },
    onSubmit() {},
    browse() {},
    selectFile() {},
  },
}
</script>