import axios from "axios";
import * as URL from "../common/API_Config.js";

export const getUserList = async function (role) {
    const res = await axios.get(
      URL.commonModuleBaseUrl +
        "common-system-service/users/get-user-list-by-rolename/" + role,
      {
        headers: {
          "Content-Type": "application/json",
          system: "CM",
        },
      }
    );
    return res;
  };

  export const getStatesList = async function (role) {
    const res = await axios.get(
      URL.commonModuleBaseUrl + "common-masterdata/state/get-state-all?countryName=INDIA",
      {
        headers: {
          "Content-Type": "application/json",
          system: "CM",
        },
      }
    );
    return res;
  };
