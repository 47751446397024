<template>
    <div style="background-color:#FFFFFF">
        <v-form v-model="icuvalid" ref="otForm">
           <v-card tile flat class="">
               <div class="px-4 pt-4">
                    <v-flex xs3>
                        <v-layout column class="cust_pln_Ot mx-2">
                            <p class="font-weight-bold cstm-clr-pln-Ot">
                                Discount Percentage
                            </p>
                            <v-text-field
                                solo
                                label="Enter..."
                                v-model.trim="dscntPercntg"
                                class="rounded-0"
                                :maxlength="max05"
                                :disabled="fromview"
                                :rules="[Numeric2]"
                            >
                            </v-text-field>
                        </v-layout>
                    </v-flex>
               </div>
                <div class="px-4 py-3"  style="background-color:#F7F7F7">
                    <p class="font-weight-bold cstm-clr-pln-Ot pa-0 ma-0">Miscellaneous Charges</p>
                </div>
                <v-divider></v-divider>
                <v-card tile>
                    <div>
                       <tableis v-if="shwDtaTbl===true"
                            :tabledata="{
                                tabledata:addCategory,
                                headersdata:headersNew,
                                dd:OTLvl4,
                                tariffshw:false,
                                roomconfig:temproomtype,
                                req:true,
                                del_endpoint:'Miscellaneous charges',
                                url:'tariff/get-miscellaneous-charges'
                            }" 
                            ref="OTchrgs" 
                            @save="saveOtChrgs" 
                          ></tableis>
                    </div>
                </v-card>
            </v-card>
            <v-layout style="background-color:#F7F7F7" justify-end class="pa-4 mt-4" v-if="!fromview">
                <v-btn tile @click="add_OT_ctgry()"  dark style="font-size:12px;background-color:#152F38"  class="text-capitalize px-3 mx-2" small><v-icon dark small>add_circle</v-icon> <v-divider vertical class="mx-2"></v-divider>Add Category</v-btn>
                <v-btn tile @click="save_ot_confg()" dark style="font-size:12px;background-color:#23B1A9" class="text-capitalize px-3 mx-2" small><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider> Save</v-btn>
            </v-layout>
        </v-form>
    </div>
</template>
<script>
import tableis from './data_table.vue'
export default {
    components:{
        tableis
    },
    created(){
        this.$store.commit("SAVE_PAGE_HEADER", "Miscellaneous Charges - Tariff");
        console.log(this.$store.state.createtarrifDetails,"this.$store.state.createtarrifDetails");
        this.getOTLvl4()
        this.fromview = this.$store.state.tariff_view;
        
    },
    data(){
        return{
            roomNursingvalid:false,
            fromview: false,
            dscntPercntg:'',
            max03:3,
             max05:5,
            max10:10,
            max20:20,
            max30:30,
            max40:40,
            max50:50,
            required(){
                return v=>v && v.length>0 || "This Field is Required"
            },
            minLength(minLength){
                return v=>v && v.length>=minLength || `Must be atleast ${minLength} Characters`
            },
            maxLength(maxLength){
                return v=>v && v.length<=maxLength || `Must be less than ${maxLength} Characters`
            },
            spclChar(){
                return v=>/^[A-Za-z0-9 ]+$/.test(v) || `Special Characters Not Allowed`
            },
            alphaNumeric(){
                return v=>/^[0-9 ]+$/.test(v) || `Only Numbers are allowed.`
            },
            numberRule: v  => {
                if (!v.trim()) return true;
                if (!isNaN(parseFloat(v)) && v >0 && v <=10) return true;
                return 'Values between 1 and 10 are accepted';
            },
            decimalRule(){
                return v=>/^\d+(\.\d{1,2})?$/.test(v) || `Numeric upto two decimals.`
            },
            headers2:[
                {text: 'Sr. No.',value:'status',sortable: false,divider:true,class:"hdr-txt-clr-Ot backColorOt"}, 
                {text: 'Service Category(Level 4)',value:'status',sortable: false,divider:true,class:"hdr-txt-clr-Ot backColorOt"},
                {text: 'Hospital Service(Level 5)', value: 'Date',sortable: false,divider:true,class:"hdr-txt-clr-Ot backColorOt"},
                {text: 'Room Type 1', value: 'Time',sortable: false,divider:true,class:"hdr-txt-clr-Ot backColorOt"},
                {text: 'Room Type 2', value: 'userName',sortable: false,divider:true,class:"hdr-txt-clr-Ot backColorOt"},
                {text: 'Room Type 3',value: 'remarks',sortable: false,divider:true,class:"hdr-txt-clr-Ot backColorOt"},  
                {text: 'Room Type 4',value: 'remarks',sortable: false,divider:true,class:"hdr-txt-clr-Ot backColorOt"},
                {text: 'Inclusions',value: 'remarks',sortable: false,divider:true,class:"hdr-txt-clr-Ot backColorOt"}, 
                {text: 'Exclusions',value: 'remarks',sortable: false,divider:true,class:"hdr-txt-clr-Ot backColorOt"},   
            ],
            headersNew:[ 
               {text: 'scl4', displayval:'Service Category(Level 4)'},
                {text: 'hsl5',displayval:'Hospital Service (Level 5)'},
                // {text: 'Tariff' },
                {text: 'Inclusions',displayval:'Inclusions'}, 
                {text: 'Exclusions',displayval:'Exclusions'},  
                {text: 'del',displayval:'Action'},
            ],
            addCategory:[],
            addRmchrgs:[],
            RmNrsngLvl4:[],
            shwDtaTbl:false,
            noOfRooms:[],


        }
    },
    methods:{
        getOTLvl4(){
            this.OTLvl4=[]
            this.getOTConfig(this.$store.state.createtarrifDetails._id)
            return
            this.GET(3,'tariff/get-miscellaneous-charges',(res,error)=>{
                if(!error){
                    if(res.data.statusCode === 200){
                        this.OTLvl4 = res.data.data.data
                        console.log(this.OTLvl4,"this.OTLvl4")
                        this.getOTConfig(this.$store.state.createtarrifDetails._id)
                    }else{
                        this.showToast(res.data.msg ,this.TOST().ERROR); 
                    }
                }else{
                    this.showToast(error.response.data.msg, this.TOST().WARNING);
                }
            })
        },
        Numeric2(v){
            if (v.length !== 0) {
                console.log("testing",/^[0-9 ]+$/.test(v))
                if(!/^(100(?:\.00?)?|\d?\d(?:\.\d\d?)?)$/.test(v)){
                    return "Numeric between 0 - 100 and upto two decimals are allowed."
                }else{
                console.log("else none")  
                }
            }
        },
        add_OT_ctgry(){
            this.addCategory.push({
                scl4:'',
                hsl5:'',
                noOfRooms:this.noOfRooms,
                inclusions:'',
                exclusions:'',
                del:''
            })

        },
        save_ot_confg(){ 
            this.$refs.OTchrgs.save_click()
        },
        saveOtChrgs(incmgDta){

             if(incmgDta.dta.length ===0){
                this.showToast("Please add atleast one category",this.TOST().WARNING);
                return
            }
            if (!this.$refs.otForm.validate()){
                this.$refs.otForm.validate()
                this.showToast("Data should be entered for all required fields.",this.TOST().WARNING);
                return
            } 
            
            console.log(incmgDta,"incmgDta")
            let miscellaneousCharges= []
            incmgDta.dta.map((e)=>{
         
                // var result = {};
                // for (var i = 0; i < e.noOfRooms.length; i++) {
                //     result[e.noOfRooms[i].text] = e.noOfRooms[i].inputValue;
                // }
                var tempRoomType=[]
                 for (var i = 0; i < e.noOfRooms.length; i++) {
                  tempRoomType.push({
                      roomType:e.noOfRooms[i].text,
                      charges:e.noOfRooms[i].inputValue
                  })
                }
                let a= {
                    // levelId:e.scl4._id,
                    // level1:e.scl4.level1,
                    // level2:e.scl4.level2,
                    // level3:e.scl4.level3,
                    serviceCategory:e.scl4,
                    hospitalService:e.hsl5,
                    inclusion: e.Inclusions,
                    exclusion:e.Exclusions,
                    roomTypes:tempRoomType
                }
                 if(e.hasOwnProperty('_id')){
                    if(e._id.length!=0){
                         a._id=e._id
                    }else{
                        console.log("Id not found")
                    }
                }
                miscellaneousCharges.push(a)
            })
           
            let formData={
                tariffId:this.$store.state.createtarrifDetails._id,
                discount:this.dscntPercntg,
                miscellaneousCharges:miscellaneousCharges,
            }
            //console.log(formData,"formData");

             this.PUT(2,'tariff/update-miscellaneous-charges',formData ,
                ( res, error) => {
                 if(!error){
                    console.log(res.data);
                    this.getOTConfig(this.$store.state.createtarrifDetails._id)
                    this.showToast(res.data.msg ,this.TOST().SUCCESS);
                    this.$emit('forenable', { emitType:"MiscellaneousCharges", MiscellaneousCharges: true }); 
                }else{
                    console.log("when status is not 200", error.response);
                    this.showToast(error.response.data.msg ,this.TOST().ERROR);
                }
    
            });
        },
         getOTConfig(id){

            this.shwDtaTbl=false
            this.noOfRooms=[]

            this.headersNew=[ 
                {text: 'scl4', displayval:'Service Category(Level 4)'},
                {text: 'hsl5',displayval:'Hospital Service (Level 5)'},
                // {text: 'Tariff' },
                {text: 'Inclusions',displayval:'Inclusions'}, 
                {text: 'Exclusions',displayval:'Exclusions'},  
                {text: 'del',displayval:'Action'}, 
            ]
            var compnt_render=new Promise((resolve,reject)=>{

                this.getTariffById(id,tempData=>{
                    console.log(tempData,"tempdata")
                    if(tempData.hasOwnProperty('roomConfiguration') && tempData.roomConfiguration!=null){
                        let roomTyp=tempData.roomConfiguration.roomConfiguration.hasOwnProperty('roomTypes')? this.chkplndataarry(tempData.roomConfiguration.roomConfiguration.roomTypes) : []
                        this.temproomtype = roomTyp
                        // if(Object.keys(temproomType).length != 0){
                        //     for (let i in temproomType ){
                        //         console.log(i,"inside temproomType ")
                        //         this.noOfRooms.push(
                        //             {
                        //                 text:temproomType[i], 
                        //                 value:temproomType[i],
                        //                 inputValue:''
                        //             }
                        //         )
                        //     }
                        // }
                        for(let i=0;i<roomTyp.length;i++){
                            this.noOfRooms.push(
                                {
                                    displayval:roomTyp[i].roomType,
                                   text:{ [i]:roomTyp[i].roomType}, 
                                    value:roomTyp[i].roomType,
                                    inputValue:''
                                }
                            )
                        }
                        console.log(this.noOfRooms,"this.noOfRooms")
                        this.headersNew.splice(2, 0, ...this.noOfRooms);
                        if(tempData.hasOwnProperty('MiscellaneousCharges')&& tempData.MiscellaneousCharges!=null){
                            this.dscntPercntg=tempData.MiscellaneousCharges.hasOwnProperty('discount')? this.chkplndata(tempData.MiscellaneousCharges.discount) : ''.hasOwnProperty('discount')? this.chkplndata(tempData.MiscellaneousCharges.discount) : ''
                            if(tempData.MiscellaneousCharges.miscellaneousCharges.length!=0){
                                this.addCategory=[]
                                // this.noOfRooms=[]
                                var roomholder = {}
                                  var roomtypes_data = []
                                for (let i=0;i<tempData.MiscellaneousCharges.miscellaneousCharges.length;i++){
                                console.log(i,"inside temproomType ")
                                // var tempnoOfRooms=[]
                                // for(let j in tempData.MiscellaneousCharges.miscellaneousCharges[i].roomType){
                                //     console.log(i,"inside temproomType ")
                                    
                                //     tempnoOfRooms.push(
                                //         {
                                //             text:j, 
                                //             value:j,
                                //             inputValue:tempData.MiscellaneousCharges.miscellaneousCharges[i].roomType[j]
                                //         }
                                //     )
                                // }
                                // let tempNScl4 = this.OTLvl4.filter((e)=>{
                                //     if(e._id===tempData.MiscellaneousCharges.miscellaneousCharges[i].levelId){
                                //         return e
                                //     }
                                // })
                                // console.log(tempNScl4,"tempNScl4")
                                 roomtypes_data = []
                                   tempData.MiscellaneousCharges.miscellaneousCharges[i].roomTypes.map((e,index)=>{
                                            roomholder = {}
                                            roomholder[index] = e.charges 

                                         roomtypes_data.push(roomholder)
                                        console.log(roomholder,"newonjis ", roomtypes_data);
                                       
                                    })

                                    console.log("roomtypes_data ", ...roomtypes_data);
                                    var finalrooms =  Object.assign({},...roomtypes_data)
                                this.addCategory.push(
                                    {   
                                        _id:tempData.MiscellaneousCharges.miscellaneousCharges[i]._id,
                                        scl4:tempData.MiscellaneousCharges.miscellaneousCharges[i].serviceCategory,
                                        hsl5:tempData.MiscellaneousCharges.miscellaneousCharges[i].hospitalService,
                                        Inclusions:tempData.MiscellaneousCharges.miscellaneousCharges[i].inclusion,
                                        Exclusions:tempData.MiscellaneousCharges.miscellaneousCharges[i].exclusion,
                                        tarrif:tempData.MiscellaneousCharges.miscellaneousCharges[i].tariff,
                                        ...finalrooms

                                    }
                                    
                                )

                            }
                            }
                            
                        }
                        resolve(true)
                    }
                })
            })
            console.log("When did it ran")
            compnt_render.then(res=>{
                console.log(res,"Resolve Promise")
                this.shwDtaTbl=true
            })

           
        },

        chkplndataStrnfy(data){
            console.log("chkplndata",data); 
            try{
                if (data ==="" || data === "undefined" || data === null) {
                    return ""
                } else {
                    return JSON.stringify(data);
                }

             }catch(err) {
                console.log(err)
            }
            
        },

        chkplndataarry(data){
            console.log("chkplndataarry",data); 
            try{
                if (data ==="" || data === "undefined" || data === null) {
                    return []
                } else {
                    return data;
                }

             }catch(err) {
                console.log(err)
            }
            
        },

        chkplndata(data){
            console.log("chkplndata",data); 
            try{
                if (data ==="" || data === "undefined" || data === null) {
                    return ""
                } else {
                    return data;
                }

             }catch(err) {
                console.log(err)
            }
            
        },
    }
}
</script>
<style>
.hdr-txt-clr-Ot{
    color:#FFFFFF!important;
    font-weight:500
}
.backColorOt{
    background-color: #23B1A9;
    
}

.cust_pln_Ot .v-text-field .v-input__slot,
.v-text-field--outline .v-input__slot {
    min-height: 41px !important;
    display: flex !important;
    align-items: center !important;
    /* border:1px red solid; */
}

.cust_pln_Ot .v-input .v-label {
    font-size: 13px !important;
}
.cust_pln_Ot.v-input {
    font-size: 13px !important;
}
.cust_pln_Ot .v-input input {
    font-size: 13px !important;
}

.cstm-clr-pln-Ot{
    color:#646666 !important;
    margin-bottom: 5px !important;
    font-size: 13px !important;
}
</style>
