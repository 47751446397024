<template>
  <div>
    <v-container fluid>
      <v-form ref="form">
      <v-layout row>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Spot Details Available ( Y/ N) *
            </v-text>
            <v-select
              :items="['Yes', 'No']"
              label="Select"
              v-model="spotVerificationDetails.isSpotDetailsAvailable"
              :rules="rules.mandatoryrule"
              solo
              item-text="state"
              item-value="state"
              class="rounded-0"
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Spot Visit done (Y/N) *
            </v-text>
            <v-select
              :items="['Yes', 'No']"
              label="Select"
              :disabled="disableAllFields"
              v-model="spotVerificationDetails.isSpotVisitdone"
              :rules="!disableAllFields && rules.mandatoryrule"
              solo
              item-text="state"
              item-value="state"
              class="rounded-0"
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Spot Latitude *
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="spotVerificationDetails.spotLatitude"
              maxlength="10"
              counter="10"
              class="rounded-0"
              aria-required="true"
              :disabled="disableAllFields"
              :rules="!disableAllFields && [required(),numericWithDecimal(spotVerificationDetails.spotLatitude)]"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Spot Longitude *
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="spotVerificationDetails.spotLongitude"
              maxlength="10"
              counter="10"
              class="rounded-0"
              aria-required="true"
              :disabled="disableAllFields"
              :rules="!disableAllFields && [numericWithDecimal(spotVerificationDetails.spotLongitude),required()]"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Important Landmark near the spot of accident *
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="spotVerificationDetails.importantLandmarkNearTheSpotofAccident"
              maxlength="30"
              counter="30"
              class="rounded-0"
              aria-required="true"
              :disabled="disableAllFields"
              :rules="!disableAllFields && [required()]"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Spot Eye Witness found (Y/N) *
            </v-text>
            <v-select
              :items="['Yes', 'No']"
              label="Select"
              v-model="spotVerificationDetails.isSpotEyeWitnessFound"
              :rules="!disableAllFields && rules.mandatoryrule"
              solo
              item-text="state"
              item-value="state"
              class="rounded-0"
              :disabled="disableAllFields"
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Spot Eye Witness Version *
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="spotVerificationDetails.spotEyeWitnessVersion"
              maxlength="500"
              counter="500"
              class="rounded-0"
              aria-required="true"
              :disabled="disableAllFields"
              :rules="!disableAllFields && [required()]"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Distance of spot from First Treating Hospital
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="spotVerificationDetails.distanceOfSpotFromFirstTreatingHospital"
              maxlength="4"
              counter="4"
              class="rounded-0"
              aria-required="true"
              :disabled="disableAllFields"
              :rules="[numericOnly(spotVerificationDetails.distanceOfSpotFromFirstTreatingHospital)]"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Distance of spot from insured residence
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="spotVerificationDetails.distanceOfSpotFromInsuredResidence"
              maxlength="4"
              counter="4"
              class="rounded-0"
              aria-required="true"
              :disabled="disableAllFields"
              :rules="[numericOnly(spotVerificationDetails.distanceOfSpotFromInsuredResidence)]"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Distance of spot from Police Station
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="spotVerificationDetails.distanceOfSpotFromPoliceStation"
              maxlength="4"
              counter="4"
              class="rounded-0"
              aria-required="true"
              :disabled="disableAllFields"
              :rules="[numericOnly(spotVerificationDetails.distanceOfSpotFromPoliceStation)]"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md4 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Remarks *
            </v-text>
            <v-textarea
              solo
              label="Enter..."
              v-model="spotVerificationDetails.remarks"
              maxlength="5000"
              counter="5000"
              rows="2"
              class="rounded-0"
              aria-required="true"
              :rules="[required()]"
            >
            </v-textarea>
          </v-layout>
        </v-flex>
      </v-layout>
      </v-form>
    </v-container>
    <InvestigationFooterCard 
      :tabno="sno" :formdata="getFormData" 
      ref="footerCardRef"
      @validateTabForms="validateTabForms"
      :nonMandatoryFields="nonMandatoryFields" 
      :inputFieldCorrectlyFilled="true"
      :enableFieldObject="getEnableFieldObject"
      :componentName="'spotVerificationDetails'"
      :siuWorkItemId="siuWorkItemId"
      :tabFormDataId="tabFormDataId"
    />
  </div>
</template>
<script>
  import InvestigationFooterCard from './InvestigationFooterCard.vue';
  import {getPrePopulatedData} from '../apiservices/Investigation';
  export default {
    name: 'SpotVerification',
    data() {
        return {
            sno: 13,
            lossStatesList: ['A'],
            validationmessage: "This field is required",
            rules: {
                mandatoryrule: [(v) => !!v || this.validationmessage],
            },
            required(){
              return (v)=> !!v || this.validationmessage
            },
            enableFieldsArr: [],
            spotVerificationDetails: {
              isTabSelected: true,
              isSpotDetailsAvailable: "",
              isSpotVisitdone: "",
              spotLatitude: "",
              spotLongitude: "",
              importantLandmarkNearTheSpotofAccident: "",
              isSpotEyeWitnessFound: "",
              spotEyeWitnessVersion: "",
              distanceOfSpotFromFirstTreatingHospital: "",
              distanceOfSpotFromInsuredResidence: "",
              distanceOfSpotFromPoliceStation: "",
              remarks: "",
            },
            nonMandatoryFields: [ 
            'distanceOfSpotFromFirstTreatingHospital',
            'distanceOfSpotFromInsuredResidence',
            'distanceOfSpotFromPoliceStation'
          ]
        }
    },
    components: {
      InvestigationFooterCard 
    },
    props: ['prepoulatedData',"siuWorkItemId","tabFormDataId"],
    created() {
      let allKeys = Object.keys(this.prepoulatedData);
      allKeys.forEach(key => {
        this.spotVerificationDetails[key] = this.prepoulatedData[key]
      })
    },
 computed: {
      disableAllFields(){
        if(this.spotVerificationDetails.isSpotDetailsAvailable == 'No'){
          this.clearAllFields()
          return true
        }else{
          return false
        }
      },
    emailRules2(v) {
      if (v.length !== 0) {
        console.log("testing", /^[0-9 ]+$/.test(v));
        if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,6})+$/.test(v)) {
          return "E-mail must be valid";
        }
      }
    },
    getEnableFieldObject() {
        return this.enableFieldsArr = this.isNeighbourOrSarpanchOrVicinityDetailsAvailable !== 'No' ? {} : {remarks: this.remarks};
    },
    getFormData() {
      return {spotVerificationDetails: this.$data.spotVerificationDetails};
    }
  },
//   mounted() {
//     let found = this.items.find(el => el.sno == 20);
//     if(!found){
//       this.items.push({sno: 20, name: "View / Upload Documents"});
//     }
//     console.log("INDEX NUMBER 20", found);
    
//   },
  methods: {
    validateTabForms(){
      if(!this.$refs.form.validate()){
        this.$refs.form.validate() 
        this.showToast("Please fill all mandatory fields", this.TOST().WARNING);
        return
      }else{
        this.$refs.footerCardRef.interimSave();
      }
    },
    clearAllFields(){
      this.spotVerificationDetails.isSpotVisitdone = '';
      this.spotVerificationDetails.spotLatitude = '';
      this.spotVerificationDetails.spotLongitude = '';
      this.spotVerificationDetails.importantLandmarkNearTheSpotofAccident = '';
      this.spotVerificationDetails.isSpotEyeWitnessFound = '';
      this.spotVerificationDetails.spotEyeWitnessVersion = '';
      this.spotVerificationDetails.distanceOfSpotFromFirstTreatingHospital = '';
      this.spotVerificationDetails.distanceOfSpotFromInsuredResidence = '';
      this.spotVerificationDetails.distanceOfSpotFromPoliceStation = '';
    },
    dragleave(event) {
      this.visualEffect = false
    },
    browseCancel() {
      //to select attachments
    //   document.getElementById("cancelchequeupload").click()
    },
    dragover(event) {
      event.preventDefault()
      this.visualEffect = true
    },
    drop(event) {
      event.preventDefault()
    //   this.selectFile() // Trigger the onChange event manually
      this.visualEffect = false
    },
    computedDate(name) {
      return this[name] ? moment(this[name]).format("DD/MM/YYYY") : "";
    },
    goToPreviousTab() {
      this.sno--;
      let btnNumber = "myBtn" + this.sno;
      const elem = this.$refs[btnNumber];
      elem[0].$el.click();
    },
    goToNextTab() {
      this.sno++;
      console.log('this', this.$children.$options);
      let btnNumber = "myBtn" + this.sno;
      const elem = this.$refs[btnNumber];
      elem[0].$el.click();
    },
    switchTabs(sno, name) {
      this.sno = sno;
      this.tabName = name;
    },
    submit() {
      this.isAlertView = true;
    },
    accept() {
      this.isAlertView = true;
    },
    saveData() {},
    alphaNumeric(fieldName, name, mandatory=true) {
      if (fieldName.length > 0) {
        return (v) => /^[a-zA-Z0-9]+$/.test(v) || "Enter valid " + name;
      }
    },
    numericOnly(val) {
      if(val.length > 0){
        return (v) => /^[0-9 ]+$/.test(v) || `Only Numbers are allowed.`;
      }
    },
    numericWithDecimal(val) {
      if(val.length >0){
        return (v) => /^[0-9 .]+$/.test(v) || `Only Numbers are allowed.`;
      }
    },
    onSubmit() {},
    browse() {},
    selectFile() {},
  },
}
</script>