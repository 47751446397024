<template>
    <v-layout no-gutters class="ml-3 flex-wrap">
        <div v-for="card in masters" :key="card.icon" @click="invers(card.link)" class="master-single-card"
            :class="{ 'without-count-alignment': card.hideCount }">
            <div class="center">
                <v-avatar class="bard" v-if="card.hideCount">
                    <v-icon class="teal--text">
                        <!-- {{card.icon}} -->mdi-account-check
                    </v-icon>
                </v-avatar>
                <v-badge color="orange" :content="card.count.toString()" overlap class="mt-5" v-else>
                    <v-avatar class="bard">
                        <v-icon class="teal--text">
                            <!-- {{card.icon}} -->mdi-account-check
                        </v-icon>
                    </v-avatar>
                </v-badge>
            </div>
            <div class="masterTitle">{{ card.texttitle }}</div>
        </div>
    </v-layout>
</template>

<script>
export default {
    data() {
        return {
            masters: [
                {
                    texttitle: "Base Rate TDS Master",
                    httpRequest: "TDS_MASTER_PENDING_COUNTS",
                    link: "tds-master-approval/base-rate",
                    modelName: "baseTds",
                    count: 0,
                },
                {
                    texttitle: "Penal Rate TDS Master",
                    httpRequest: "TDS_MASTER_PENDING_COUNTS",
                    link: "tds-master-approval/penal-rate",
                    modelName: "penalTds",
                    count: 0,
                },
                {
                    texttitle: "Concessional and Penal for PAN Master",
                    httpRequest: "TDS_MASTER_PENDING_COUNTS",
                    link: "tds-master-approval/concessional-penal",
                    modelName: "concessionalTds",
                    count: 0,
                }
            ],
        };
    },
    created() {
        this.getMastersCount();
        this.$store.commit("SAVE_PAGE_HEADER", "Master Approval");
    },
    methods: {
        getMastersCount() {
            this.masters.map((master) => {
                this.GETM(
                    master.httpRequest,
                    `tdsModel=${master.modelName}`,
                    (res) => {
                        master.count = 0;
                        if (
                            res.data &&
                            res.data.data &&
                            res.data.data.totalCount
                        ) {
                            master.count = res.data.data.totalCount || 0;
                        }
                    },
                    (err) => { },
                    "CM"
                );
            });
        },
        invers(link) {
            this.$router.push(link);
        },
    },
};
</script>

<style scoped>
.master-single-card:not(:last-child) {
    margin-right: 8px;
}

.master-single-card:hover {
    border-color: teal;
}

.master-single-card {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 194px;
    height: 150px;
    padding: 0 15px;
    margin-top: 8px;
    background: transparent linear-gradient(180deg, #ffffff 0%, #f0f0f0 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 4px 8px #0000000a;
    border: 2px solid #e0e0e0;
    border-radius: 4px;
}

.center {
    text-align: center;
    margin-top: 10px;
}

.headline {
    font-size: 10px !important;
    font-weight: bold;
    text-align: center;
    line-height: 20px;
}

.bard {
    border: 1px solid #555;
}

.card:hover {
    border: 2px solid teal;
    cursor: pointer;
}

.masterTitle {
    margin: 15px 0;
    word-wrap: break-word;
    font-size: 14px !important;
    text-align: center;
    font-weight: 500;
    font-style: bold;
}

.without-count-alignment {
    justify-content: space-evenly;
}
</style>
