<template>
  <div class="pa-5">
    <v-form>
      <div>
        <v-card class="mb-3" style="" tile>
          <v-layout align-center>
            <v-btn-toggle dense mandatory v-model="text" tile>
              <v-btn
                v-if="isSiuUser"
                value="internal"
                class="text-capitalize font-weight-bold px-5"
                @click="showInternalData()"
                style="white-space: normal"
                :class="[
                  showInternal === true
                    ? 'activebackground'
                    : 'inactivewhitebackground',
                ]"
                >Internal Notes
              </v-btn>

              <v-btn
                value="external"
                class="text-capitalize font-weight-bold px-5"
                @click="showExternalData()"
                style="white-space: normal"
                :class="[
                  showExternal === true
                    ? 'activebackground'
                    : 'inactivewhitebackground',
                ]"
                >External Notes
              </v-btn>
            </v-btn-toggle>
          </v-layout>
        </v-card>
      </div>

      <v-row no-gutters>
        <v-layout column class="">
          <v-textarea
            solo
            dense
            outlined
            v-model="remarks"
            maxlength="5000"
            counter="5000"
          >
          </v-textarea>
        </v-layout>

        <v-btn
          tile
          color="#152F38"
          dark
          class="btnstyle12 ml-3"
          @click="goToADD()"
        >
          <v-icon class="mr-2 iconsize">mdi-plus</v-icon>
          <div class="vertical_line"></div>
          ADD
        </v-btn>
      </v-row>

      <v-row
        no-gutters
        class="pa-3 mt-2"
        style="background-color: #f7f7f7; display: flex"
      >
      </v-row>
    </v-form>

    <v-card tile class="ma-1 mt-4" v-if="showInternal === true">
      <v-data-table
        class="padding-0 pa-2 mx-3 vDataTableStyling"
        :headers="headersInternal"
        :items="internalData"
        :page.sync="page"
        :items-per-page="itemsPerPage"
        :hide-default-footer="true"
      >
        <template v-slot:[`item.srNum`]="{ item }">
          {{ item.srNum }}
        </template>
        <template v-slot:[`item.userName`]="{ item }">
          {{ item.userName }}
        </template>
        <template v-slot:[`item.userRole`]="{ item }">
          {{ item.userRole }}
        </template>
        <template v-slot:[`item.internalNotes`]="{ item }">
          {{ item.notes }}
        </template>
      </v-data-table>
    </v-card>

    <v-card tile class="ma-1 mt-4" v-if="showExternal === true">
      <v-data-table
        class="padding-0 pa-2 mx-3 vDataTableStyling"
        :headers="headersExternal"
        :items="externalData"
        :page.sync="page"
        :items-per-page="itemsPerPage"
        :hide-default-footer="true"
      >
        <template v-slot:[`item.srNum`]="{ item }">
          {{ item.srNum }}
        </template>
        <template v-slot:[`item.userName`]="{ item }">
          {{ item.userName }}
        </template>
        <template v-slot:[`item.userRole`]="{ item }">
          {{ item.userRole }}
        </template>
        <template v-slot:[`item.externalNotes`]="{ item }">
          {{ item.notes }}
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import moment from 'moment';
import { getNotesData, postNotesData } from "../apiservices/Investigation";
export default {
  data() {
    return {
      currentRole : "",
      isSiuUser : false,
      siuRoles: ["CG77", "CG78","CG79","CG80","CG81","CG82"],
      remarks: "",
      showInternal: true,
      showExternal: false,

      internalData: [],
      externalData: [],

      headersInternal: [
        {
          text: "Sr.No.",
          value: "srNum",
          width: 50,
          sortable: false,
          align: "center",
        },
        {
          text: "User Name",
          value: "userName",
          width: 142,
          sortable: false,
          align: "center",
        },
        {
          text: "User Role",
          value: "userRole",
          align: "center",
          sortable: false,
          width: "150px",
        },
        {
          text: "Internal Notes",
          value: "internalNotes",
          width: "150px",
          sortable: false,
          align: "center",
        },
        {
          text: "Date & Time",
          value: "dateAndtime",
          sortable: false,
          width: 150,
          align: "center",
        },
      ],

      headersExternal: [
        {
          text: "Sr.No.",
          value: "srNum",
          width: 50,
          sortable: false,
          align: "center",
        },
        {
          text: "User Name",
          value: "userName",
          width: 142,
          sortable: false,
          align: "center",
        },
        {
          text: "User Role",
          value: "userRole",
          align: "center",
          sortable: false,
          width: "150px",
        },
        {
          text: "External Notes",
          value: "externalNotes",
          width: "150px",
          sortable: false,
          align: "center",
        },
        {
          text: "Date & Time",
          value: "dateAndtime",
          sortable: false,
          width: 150,
          align: "center",
        },
      ],
    };
  },

  created() {
    this.$store.commit("SAVE_PAGE_HEADER", "SIU Notes");
    this.currentRole = this.fetchRoleValue();
    this.isSiuUser = this.siuRoles.some(r=> this.currentRole.indexOf(r) >= 0);
    this.getExternalData();
  },

  computed: {},

  methods: {
    async goToADD() {
          let notesCategory = this.showInternal ? "Internal" : "External";
          try {
            let res = await postNotesData({
                  "userName": this.$route.params.allData.tabData?.siuUserDetails?.investigatorDetails?.name || this.$route.params.allData.assginDetails.userName,
                  "userRole": this.isSiuUser ? this.$route.params.allData.assginDetails.roleDesignation : "SIU Investigator",
                  "notesCategory": notesCategory,
                  "notes": this.remarks,
                  "ntidUserId": this.$route.params.allData.tabData?.siuUserDetails?.investigatorDetails?.ntidUserId || this.$route.params.allData.assginDetails.ntID,
                  "claimNumber": this.$route.params.claimNumber,
                  "featureNumber":this.$route.params.featureNumber,
                  "investigationTaskId": this.$route.params.allData.investigationTaskID,
                  "siuTaskId":this.$route.params.allData._id,
                  "createdDateToTakeEffect": new Date()
              });
              if(res.data.statusCode == 200){
             this.showToast(res?.data?.msg || 'Success', this.TOST().SUCCESS);
            } else {
              this.showToast(res?.data?.msg, this.TOST().WARNING);
            }
              this.showInternal ? this.getInternalData() : this.getExternalData()
              this.remarks = ""
          } catch(e) {
            this.showToast(e.response?.data?.msg || e.response?.data  || e.response || e, this.TOST().ERROR);
          }
    },
    showInternalData() {
      this.showInternal = true;
      this.showExternal = false;
      this.getInternalData();
    },
    showExternalData() {
      this.showInternal = false;
      this.showExternal = true;
      this.getExternalData();
    },

    getInternalData() {
      this.getInternalExternalDetail('Internal')
    },

    getExternalData() {
      this.getInternalExternalDetail('External');
    },

    async getInternalExternalDetail(investigatorType){
        let params = this.$route.params;
        let token = this.$store.state.Auth && this.$store.state.Auth.user ? this.$store.state.Auth.user.signInUserSession.idToken.jwtToken : ''
        let res = await getNotesData(token,`common-auxiliary/hos-and-acc-details/get-notes?claimNumber=${params.claimNumber}&siuTaskId=${ params.allData?._id }&notesCategory=${investigatorType}`);
        let getData =  res.map((item, index) => {
          return {
            srNum: index+1,
            userName: item.userName,
            userRole: item.userRole,
            notes: item.notes,
            dateAndtime: moment(item?.createdAt ? item?.createdAt : '').format("DD-MM-YYYY")
          }
        });
        if(this.showInternal){
          this.internalData = getData;
        }else{
          this.externalData = getData
        }
      },
  },
};
</script>

<style scoped>
.iconsize {
  font-size: 16px !important;
  font-weight: bold;
}
.btnstyle12 {
  height: 28px !important;
  text-transform: capitalize;
  font-size: 11px !important;
  letter-spacing: 0.5px;
}

.activebackground {
  background-color: #23b1a9 !important;
  color: rgb(247 247 247) !important;
}

.inactivewhitebackground {
  background-color: #ffffff !important;
  color: #10242b !important;
}
</style>
