 <template>
    <v-app>
        <v-card  tile>
            <div class="ma-4">
                <v-form v-model="valid" ref="netform">
                    <v-layout class="mt-2" style="background-color: #FFFFFF;">
                        <v-flex xs3 >
                            <v-layout column class="cust_pr mx-3 ">
                                <p class="font-weight-bold cstm-clr-pr">Provider Name</p>
                                <v-select
                                    label="Select"
                                    v-model="createTarrProvName"
                                    solo
                                    class="rounded-0"
                                    :rules="[rules.required]">
                                </v-select>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3 >
                            <v-layout column class="cust_pr mx-3 ">
                                <p class="font-weight-bold cstm-clr-pr">Provider Type</p>
                                <v-select
                                    label="Select"
                                    v-model="createTarrProvType"
                                    solo
                                    class="rounded-0"
                                    :rules="[rules.required]">
                                </v-select>
                            </v-layout>
                        </v-flex>
                        
                        <v-flex xs3 >
                            <v-layout column class="cust_pr mx-3 ">
                                <p class="font-weight-bold cstm-clr-pr">PRN</p>
                                <v-text-field
                                    solo
                                    label="Enter..."
                                    v-model="createTarrPRN"
                                    class="rounded-0"
                                    :rules="[rules.required]">
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                        
                        <v-flex xs3>
                            <v-layout column class="cust_pr mx-3 cust-rmvpd-pr">
                                <v-text class="font-weight-bold cstm-clr-pr">Tariff Effective date</v-text>
                                <v-menu
                                    ref="calender"
                                    :close-on-content-click="true"
                                    v-model="tarrifEffectDateMenu"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    max-width="290px"
                                    min-width="290px" 
                                    class="rounded-0">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            slot="activator"
                                            label="Select.."
                                            :value="computedTarrEffectDate"
                                            solo
                                            class="rounded-0"
                                            readonly 
                                            v-bind="attrs"
                                            v-on="on">
                                        <div  slot="prepend-inner" style="background-color:#F7F7F7;padding:6px;"><span class="material-icons">date_range</span></div></v-text-field>
                                    </template>
                                    <v-date-picker v-model="createTarrEffectDate" no-title></v-date-picker>
                                </v-menu>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3>
                            <v-layout column class="cust_pr mx-3 cust-rmvpd-pr">
                                <v-text class="font-weight-bold cstm-clr-pr">Tariff Expiration date</v-text>
                                <v-menu
                                    ref="calender"
                                    :close-on-content-click="true"
                                    v-model="tarrifExpireDateMenu"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    max-width="290px"
                                    min-width="290px" 
                                    class="rounded-0">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            slot="activator"
                                            label="Select.."
                                            :value="computedTarrExpireDate"
                                            solo
                                            class="rounded-0"
                                            readonly 
                                            v-bind="attrs"
                                            v-on="on">
                                        <div  slot="prepend-inner" style="background-color:#F7F7F7;padding:6px;"><span class="material-icons">date_range</span></div></v-text-field>
                                    </template>
                                    <v-date-picker v-model="createTarrExpireDate" no-title></v-date-picker>
                                </v-menu>
                            </v-layout>
                        </v-flex>
                        
                    </v-layout>

                    <v-layout class="mb-2" style="background-color: #FFFFFF;">
                        <v-flex xs3 >
                            <v-layout column class="cust_pr mx-3 ">
                                <p class="font-weight-bold cstm-clr-pr">Tarrif Name</p>
                                <v-text-field
                                    label="Search..."
                                    solo
                                    v-model="createTarrifName"
                                    class="rounded-0"
                                    :rules="[rules.required]">
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3></v-flex>
                        <v-flex xs3></v-flex>
                        <v-flex xs3></v-flex>
                        <v-flex xs3></v-flex>
                    </v-layout>
                </v-form>
            </div>

            <div class="" style="border:0.5px solid #C1C8CC;">
                <v-layout  style="padding:25px;background-color:#F7F7F7;align-items:center; justify-center; justify-content: flex-end;">
                    <v-btn dark  tile color="#10242b" class="text-capitalize px-3 mx-3" small><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider> Return</v-btn>
                    <v-btn dark  tile color="#23B1A9" class="text-capitalize px-3 mx-3" @click="save()" small><v-icon dark small>add_circle</v-icon> <v-divider vertical class="mx-2"></v-divider>Create New</v-btn>
                </v-layout>
            </div>
        </v-card> 
    </v-app>
</template>
<script>
import moment from 'moment';


export default {

    components:{
      
    },

    created() {

        this.$store.commit("SAVE_PAGE_HEADER", "Create New Tarrif");

    },

    computed: {
        computedTarrEffectDate(){
            return this.createTarrEffectDate ? moment(this.createTarrEffectDate).format("DD-MM-YYYY"): ''
        },
         computedTarrExpireDate(){
            return this.createTarrExpireDate ? moment(this.createTarrExpireDate).format("DD-MM-YYYY"): ''
        },
        validDateChk(){
            let todayDate = Date.now();
            let Todate= moment(this.netenddate,'YYYY-MM-DD ').toDate().getTime()
            console.log(todayDate,"todayDate")
            let CurrentDate= todayDate

            if(Todate < CurrentDate){
                return this.planStatus="Inactive"
            }else{
                return this.planStatus="Active"
            }
        }

    },


    data() {
        return {
            createTarrProvName:'',
            createTarrProvType:'',
            createTarrPRN:'',
            createTarrEffectDate:'',
            createTarrExpireDate:'',
            createTarrifName:'',
            tarrifEffectDateMenu:'',
            tarrifExpireDateMenu:'',

            rules: {
                required: value => !!value || 'This Field is Required.',
                minExtRefNo: value => {
                    // const pattern = /^([0-9])+$/
                    const pattern = /^[a-zA-Z0-9]+$/
                    return pattern.test(value) || 'This Field is Required.'
                },
            },
        }
    },

    methods: {

        save(){
            if (this.$refs.netform.validate()){
                this.showToast("Success",this.TOST().SUCCESS);
                let formData={
                    
                }
                console.log(formData,"=========Form Data")

            }else{
                this.$refs.netform.validate()
                this.showToast("Please Fill Required Fields",this.TOST().WARNING);
            }

        },


        clear(){
            if((this.networkName===''||this.networkName===undefined||this.networkName===null) 
            && (this.netstartdate===''||this.netstartdate===undefined||this.netstartdate===null)
            && (this.netenddate===''||this.netenddate===undefined||this.netenddate===null) 
            && (this.clinicalspl===''||this.clinicalspl===undefined||this.clinicalspl===null)
            && (this.remarks===''||this.remarks===undefined||this.remarks===null) 
            
            ){
                return
            }else{
                

                this.networkName=''
                this.netstartdate=''
                this.netenddate=''
                this.clinicalspl=''
                this.remarks=''
                
                this.$refs.netform.resetValidation()
                        
            }
        },
    },

    watch: {
    
    },
    
    
}

</script>
<style >
.activeredbackground{
  color:white;
}

.v-btn__content { width: 100% !important; white-space: normal !important; font-size:13px !important}

.srSelected{
  background-color: #23B1A9 !important;
  color:#FFFFFF !important;
}
.disable-field-style{
    border:1px solid #0014001f;
    padding:6px;
    background-color:#f7f7f7
}

</style>