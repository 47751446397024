<template>
    <div>
      <v-container fluid>
        <v-form ref="form">
      <v-layout row>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Vehicle details available *
            </v-text>
            <v-select
              :items="['Yes', 'No']"
              label="Select"
              v-model="accidentVehicleInvolvedDetails.isVehicledetailsAvailable"
              :rules="rule.mandatoryrule"
              solo
              item-text="state"
              item-value="state"
              class="rounded-0"
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Vehicle Registered Owner *
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="accidentVehicleInvolvedDetails.vehicleRegisteredOwner"
              maxlength="500"
              counter="500"
              class="rounded-0"
              aria-required="true"
              :disabled="disableAllFields"
              :rules="!disableAllFields && [required()]"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Type of Vehicle *
            </v-text>
            <v-select
              :items="typeOfVehicleList"
              solo
              label="Enter..."
              :rules="!disableAllFields && rule.mandatoryrule"
              v-model="accidentVehicleInvolvedDetails.typeOfVehicle"
              maxlength="20"
              counter="20"
              class="rounded-0"
              aria-required="true"
              :disabled="disableAllFields"
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Vehicle Registration Number as per FIR *
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="accidentVehicleInvolvedDetails.vehicleRegistrationNumberAsFIR"
              maxlength="50"
              counter="50"
              class="rounded-0"
              :disabled="disableAllFields"
              aria-required="true"
              :rules="!disableAllFields && [required()]"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Fast Tag/Toll History
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="accidentVehicleInvolvedDetails.fastTagOrTollHistory"
              maxlength="500"
              counter="500"
              :disabled="disableAllFields"
              class="rounded-0"
              aria-required="true"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Name of the driver as per FIR
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="accidentVehicleInvolvedDetails.nameOfDriverAsFIR"
              maxlength="100"
              counter="100"
              class="rounded-0"
              :disabled="disableAllFields"
              aria-required="true"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Driving License number
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="accidentVehicleInvolvedDetails.drivingLicenseNumber"
              maxlength="20"
              counter="20"
              :disabled="disableAllFields"
              class="rounded-0"
              aria-required="true"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Driver Google Timeline 
            </v-text>
            <v-select
              :items="['Yes', 'No']"
              label="Select"
              v-model="accidentVehicleInvolvedDetails.driverGoogleTimeline"
              solo
              :disabled="disableAllFields"
              item-text="state"
              item-value="state"
              class="rounded-0"
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Vehicle Photograph available *
            </v-text>
            <v-select
              :items="['Yes', 'No']"
              label="Select"
              v-model="accidentVehicleInvolvedDetails.isVehiclePhotographAvailable"
              :rules="!disableAllFields && rule.mandatoryrule"
              solo
              item-text="state"
              item-value="state"
              :disabled="disableAllFields"
              class="rounded-0"
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Is OD/TP claim reported
            </v-text>
            <v-select
              :items="['Yes', 'No']"
              label="Select"
              v-model="accidentVehicleInvolvedDetails.isOdOrTPClaimReported"
              solo
              item-text="state"
              :disabled="disableAllFields"
              item-value="state"
              class="rounded-0"
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Vehicle Repair Bill details
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="accidentVehicleInvolvedDetails.vehicleRepairBillDetails"
              maxlength="1000"
              counter="1000"
              class="rounded-0"
              :disabled="disableAllFields"
              aria-required="true"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Remarks *
            </v-text>
            <v-textarea
              solo
              label="Enter..."
              v-model="accidentVehicleInvolvedDetails.remarks"
              maxlength="5000"
              rows="2"
              counter="5000"
              class="rounded-0"
              aria-required="true"
              :rules="[required()]"
            >
            </v-textarea>
          </v-layout>
        </v-flex>
      </v-layout>
      </v-form>
    </v-container>
    <InvestigationFooterCard 
      :tabno="sno" :formdata="getFormData" 
      ref="footerCardRef"
      @validateTabForms="validateTabForms"
      :nonMandatoryFields="nonMandatoryFields" 
      :inputFieldCorrectlyFilled="true"
      :enableFieldObject="getEnableFieldObject"
      :componentName="'accidentVehicleInvolvedDetails'"
      :siuWorkItemId="siuWorkItemId"
      :tabFormDataId="tabFormDataId"
    />
    </div>
</template>
<script>
  import InvestigationFooterCard from './InvestigationFooterCard.vue';
  import {getPrePopulatedData} from '../apiservices/Investigation';
  export default {
    name: 'AccidentDetails',
    props: ['prepoulatedData', "siuWorkItemId", "tabFormDataId"],
    data() {
        return {
            validationmessage: "This field is required",
            sno: 15,
            enableFieldsArr: [],
            nonMandatoryFields: ['fastTagOrTollHistory', 'nameOfDriverAsFIR', 'drivingLicenseNumber','driverGoogleTimeline', 'isOdOrTPClaimReported', 'vehicleRepairBillDetails'],
            lossStatesList: ['A'],
            rule: {
                mandatoryrule: [(v) => !!v || this.validationmessage],
            },
            required(){
              return (v)=> !!v || this.validationmessage
            },
            typeOfVehicleList: ['Two-Wheelar','Commercial Vehicle', 'Private Car'],
            accidentVehicleInvolvedDetails: {
              isTabSelected: true,
              isVehicledetailsAvailable: "",
              vehicleRegisteredOwner: "",
              typeOfVehicle: "",
              vehicleRegistrationNumberAsFIR: "",
              fastTagOrTollHistory: "",
              nameOfDriverAsFIR: "",
              drivingLicenseNumber: "",
              driverGoogleTimeline: "",
              isVehiclePhotographAvailable: "",
              isOdOrTPClaimReported: "",
              vehicleRepairBillDetails: "",
              remarks: "",
            }
        }
    },
    created() {
      console.log('prepopolulated data', this.prepoulatedData);
      let allKeys = Object.keys(this.prepoulatedData);
      allKeys.forEach(key => {
        this.accidentVehicleInvolvedDetails[key] = this.prepoulatedData[key]
      })
    },
    components: {
      InvestigationFooterCard 
    },
 computed: {
  disableAllFields(){
        if(this.accidentVehicleInvolvedDetails.isVehicledetailsAvailable == 'No'){
          this.clearAllFields()
          return true
        }else{
          return false
        }
      },
    emailRules2(v) {
      if (v.length !== 0) {
        console.log("testing", /^[0-9 ]+$/.test(v));
        if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,6})+$/.test(v)) {
          return "E-mail must be valid";
        }
      }
    },
    getFormData() {
      return {accidentVehicleInvolvedDetails: this.$data.accidentVehicleInvolvedDetails};
    },
    getEnableFieldObject() {
      return this.enableFieldsArr = this.isVehicledetailsAvailable !== 'No' ? {} : {remarks: this.remarks};
    }
  },
//   mounted() {
//     let found = this.items.find(el => el.sno == 20);
//     if(!found){
//       this.items.push({sno: 20, name: "View / Upload Documents"});
//     }
//     console.log("INDEX NUMBER 20", found);
    
//   },
  methods: {
    validateTabForms(){
      if(!this.$refs.form.validate()){
        this.$refs.form.validate() 
        this.showToast("Please fill all mandatory fields", this.TOST().WARNING);
        return
      }else{
        this.$refs.footerCardRef.interimSave();
      }
    },
    clearAllFields(){
        this.accidentVehicleInvolvedDetails.vehicleRegisteredOwner = '';
        this.accidentVehicleInvolvedDetails.typeOfVehicle = '';
        this.accidentVehicleInvolvedDetails.vehicleRegistrationNumberAsFIR = '';
        this.accidentVehicleInvolvedDetails.fastTagOrTollHistory = '';
        this.accidentVehicleInvolvedDetails.nameOfDriverAsFIR = '';
        this.accidentVehicleInvolvedDetails.drivingLicenseNumber = '';
        this.accidentVehicleInvolvedDetails.driverGoogleTimeline = '';
        this.accidentVehicleInvolvedDetails.isVehiclePhotographAvailable = '';
        this.accidentVehicleInvolvedDetails.isOdOrTPClaimReported = '';
        this.accidentVehicleInvolvedDetails.vehicleRepairBillDetails = '';
    },
    dragleave(event) {
      this.visualEffect = false
    },
    browseCancel() {
      //to select attachments
    //   document.getElementById("cancelchequeupload").click()
    },
    dragover(event) {
      event.preventDefault()
      this.visualEffect = true
    },
    drop(event) {
      event.preventDefault()
    //   this.selectFile() // Trigger the onChange event manually
      this.visualEffect = false
    },
    computedDate(name) {
      return this[name] ? moment(this[name]).format("DD/MM/YYYY") : "";
    },
    goToPreviousTab() {
      this.sno--;
      let btnNumber = "myBtn" + this.sno;
      const elem = this.$refs[btnNumber];
      elem[0].$el.click();
    },
    goToNextTab() {
      this.sno++;
      console.log('this', this.$children.$options);
      let btnNumber = "myBtn" + this.sno;
      const elem = this.$refs[btnNumber];
      elem[0].$el.click();
    },
    switchTabs(sno, name) {
      this.sno = sno;
      this.tabName = name;
    },
    submit() {
      this.isAlertView = true;
    },
    accept() {
      this.isAlertView = true;
    },
    saveData() {},
    alphaNumeric(fieldName, name, mandatory=true) {
      console.log('fieldName', fieldName, name);
      if (fieldName.length > 0) {
        return (v) => /^[a-zA-Z0-9 ]+$/.test(v) || "Enter valid " + name;
      }
    },
    numericOnly() {
      return (v) => /^[0-9 ]+$/.test(v) || `Only Numbers are allowed.`;
    },
    onSubmit() {},
    browse() {},
    selectFile() {},
  },
}
</script>