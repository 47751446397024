<template>
    <!-- <v-app > -->
        <div style="background-color: #FFFFFF;" column class="">
            
            <!-- <div class=" mb-1" style=""> -->
                <v-layout>
                    <v-layout align-center class="py-2" >
                        <v-text class=" font-weight-bold title-clr-prtbs ml-2" style="color:">Empanelment Type</v-text>
                        
                        <v-divider vertical class="mx-4"></v-divider>
                        <!-- <div class="verticalline mt-2" style=""></div> -->
                        <!-- <div class="pb-4" style="height: 40px;width: 357px; border: 0.5px solid #C1C8CC;" > -->
                            
                        <v-btn-toggle dense mandatory @change="empanelTypeFunct(empanelType)" v-model="empanelType" tile class="" style="background-color:#F7F7F7;opacity:100%;"  >
                            <!-- value="indvdl"  -->
                            <v-btn  value="empanelment"  class="text-capitalize font-weight-bold  px-5" active-class="srSelected" style="white-space: normal;"
                            :class="[showNewEmp === true ? 'activebackground' : 'inactivewhitebackground']">New Empanelment
                            </v-btn>
                            <!-- value="instutn"  -->
                            <v-btn  value="renewal" class="text-capitalize font-weight-bold   px-5" active-class="srSelected" style="white-space: normal;"
                            :class="[showEmpRenewal === true ? 'activebackground' : 'inactivewhitebackground']">Empanelment Renewal
                            </v-btn>    
                        </v-btn-toggle>

                    </v-layout>
                    <v-layout class="pb-1 pr-1" style="justify-content: flex-end">
                        <div class="shadow" style="display:flex;justify-content: flex-end;">
                            <div class="profile_controls" >   
                                <div class="pa-2 ">
                                <v-layout  v-if="upload_docs" class="" justify-space-between align-center
                                    style="border:0.5px solid #C1C8CC; background-color:#fff; align-items:center;"  color="#FFFFFF" filled >
                                        <div>
                                                <v-icon class="ml-2" small>perm_media</v-icon>
                                        </div>
                                        <div><span class="px-2 font-weight-bold"  style="font-size:12px; color:#646666;">{{profilepicname|truncate(10, '...')}}</span></div>
                                        <div style="background-color:#D1121B; padding:3px" >
                                            <v-btn tile class="text-capitalize" style="height:16px;width:16px"  icon dark small @click="deleteprofile">
                                                <v-icon small>delete</v-icon>
                                            </v-btn>
                                        </div>
                                </v-layout>
                             </div>
                             <div>   
                                <input type="file"  style="opacity:0; display:none;"
                                    id="imgfiles" multiple name="img_upload"
                                    accept="image/jpeg" 
                                    @change="upload_fileis($event)">
                                <v-btn @click="profileupload()" dark tile color="#E46A25" class="text-capitalize px-3 mx-2" small>
                                    <v-icon dark small>cloud_upload</v-icon> <v-divider vertical class="mx-2"></v-divider>Upload
                                </v-btn>
                              
                            </div>
                            </div>
                          
                            <div style="background-color:rgba(0, 0, 0, 0.12);height:100px;width:1px"></div>
                            <flex class="emp-prof-img">
                                 <v-icon v-if="b64Image == ''" large>account_circle</v-icon>
                                <!-- v-else -->
                                <img style="width:100px;" v-else  :src="b64Image !== '' ? b64Image : empanelStoreData.hasOwnProperty('photoUrl') == true ? empanelStoreData.photoUrl : ''" />
                                <!-- <v-icon v-if="b64Image !== '' || this.empanelStoreData.hasOwnProperty('photoUrl') !== true" large>account_circle</v-icon>
                                <img style="width:100px;height:100px" v-else :src="b64Image !== '' ? b64Image : this.empanelStoreData.hasOwnProperty('photoUrl') == true ? this.empanelStoreData.photoUrl : '' " /> -->
                            </flex>
                        </div>
                    </v-layout>
                </v-layout>
                <!-- </div> -->
                

                <v-divider></v-divider>
                <v-form ref="form">
                    <v-layout class="mx-2 mt-2" >

                        <v-flex xs3>
                            <v-layout column class="mx-2 my-2">
                                <v-text class="text-uppercase font-weight-bold title-clr-pr">
                                    Provider Details
                                </v-text>
                                <v-divider></v-divider>
                            </v-layout>
                        </v-flex>

                        <v-flex xs3>
                            <v-layout column class="cust_pr cust-rmvpd-psins mx-2  my-2 cust_Main" >
                                <p class="font-weight-bold cstm-clr-pd">
                                Select Provider * 
                                </p>
                                <div class="d-flex">
                                    <!-- <p>{{searchedItems}}</p> -->
                                <v-autocomplete
                                    v-model="providerDetails"
                                    :items="searchedItems"
                                    :loading="isLoading"
                                    :search-input.sync="searchProviderName"
                                    color="white"
                                    item-text="name"
                                    item-value="_id"
                                    label="Provider Name..."
                                    return-object
                                    class="rounded-0 deleIcon"
                                    solo
                                    :rules="[() => !!providerDetails || 'This field is required']"
                                    @blur="fieldValidation(providerDetails,'search')"
                                    @input="fieldValidation(providerDetails,'search')">
                                    <!-- :rules="searchProvidValid" -->
                                </v-autocomplete>
                                <v-card 
                                    slot="append" style="  
                                    background-color: rgb(247, 247, 247);
                                    padding: 9px !important;
                                    cursor: pointer;
                                    height: 38px;" 
                                    @click="getProviderDetails(providerDetails._id)"
                                    class="rounded-0">
                                    <v-icon elevation="1">search</v-icon></v-card>
                                </div>
                            </v-layout>
                        </v-flex>

                        <v-flex v-if="regEntity !== 'Individual'" xs3>
                            <v-layout column class=" mx-2 my-2 cust_pd cust-rmvpd-pr">
                                <v-text class="font-weight-bold cstm-clr-pd">Registration Date</v-text>
                                <v-menu
                                    ref="calender"
                                    :close-on-content-click="true"
                                    v-model="RegDate"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    max-width="290px"
                                    min-width="290px" 
                                    class="rounded-0"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            slot="activator"
                                            label="Select.."
                                            :value="cmputdRegistrationDatejs"
                                            solo
                                            class="rounded-0"
                                            readonly 
                                            disabled
                                            background-color='#f7f7f7'
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                        <div slot="prepend-inner" style="background-color:#F7F7F7;padding:6px;"><span class="material-icons">date_range</span></div></v-text-field>
                                    </template>
                                                                
                                    <v-date-picker v-model="RegistrationDate" no-title></v-date-picker>
                                </v-menu>
                            </v-layout>
                            </v-flex>

                        <v-flex xs3>
                            <v-layout column class="cust_pr cust-rmvpd-psins mx-2 my-2  cust_Main">
                                <v-text class="font-weight-bold cstm-clr-pd">
                                    Registered Entity *
                                </v-text>
                                <v-select
                                    :items="regitems"
                                    label="Insitution"
                                    v-model="regEntity"
                                    solo
                                    disabled
                                    background-color='#f7f7f7'
                                    :rules="[() => !!regEntity || 'This field is required']"
                                    class="rounded-0 disabled"
                                    >
                                </v-select>
                            </v-layout>
                        </v-flex>

                        <v-flex xs3>
                            <v-layout column class="cust_pr cust-rmvpd-psins mx-2 my-2 cust_Main">
                                <p class="font-weight-bold cstm-clr-pr">
                                    PRN
                                </p>
                                <v-text-field
                                    solo
                                    disabled
                                    background-color='#f7f7f7'
                                    label="Enter..."
                                    v-model="provPrnNo"
                                    maxlength="10"
                                    class="rounded-0 ">
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                        <!-- INDIVIDUAL ENTITY Salutation FIELD -->
                        <v-flex v-if="regEntity == 'Individual'" xs3>
                            <v-layout column class="cust_pr cust-rmvpd-psins mx-2 my-2  cust_Main">
                                <v-text class="font-weight-bold cstm-clr-pd">Salutation *</v-text>
                                <v-select
                                    disabled
                                    background-color='#f7f7f7'
                                    :items="sltnIndItems"
                                    label="Select"
                                    v-model="salutationind"
                                    solo
                                    class="rounded-0"
                                    :rules="[() => !!salutationind || 'This field is required']"
                                    >
                                </v-select>
                            </v-layout>
                        </v-flex>
                    </v-layout>
            
                    <!-- INDIVIDUAL ENTITY FIELDS -->
                    <v-layout color="#FFFFFF" class="mx-2" style="background-color: #FFFFFF;" v-if="regEntity == 'Individual'">
                        
                        <v-flex xs3 >
                            <v-layout column class=" mx-2 cust_pr">
                                <p class="font-weight-bold cstm-clr-pr">
                                    First Name *
                                </p>
                                <v-text-field
                                    autocomplete="off"
                                    solo
                                    label="Enter..."
                                    v-model="frstnmeInd"
                                    disabled
                                    background-color='#f7f7f7'
                                    class="rounded-0"
                                    maxlength=20
                                    
                                    >
                                </v-text-field>
                                <!-- :rules="[required(0,''), rules.spclChar(1,'frstnmeInd')]" -->
                            </v-layout>
                        </v-flex>
                        <v-flex xs3 >
                            <v-layout column class=" mx-2 cust_pr">
                                <p class="font-weight-bold cstm-clr-pr">
                                    Middle Name
                                </p>
                                <v-text-field
                                    disabled
                                    background-color='#f7f7f7'
                                    solo
                                    label="Enter..."
                                    v-model="mdlnmeInd"
                                    class="rounded-0"
                                    maxlength=20
                                    
                                    >
                                </v-text-field>
                                <!-- :rules="[rules.spclChar(2,'mdlnmeInd')]" -->
                            </v-layout>
                        </v-flex>

                        <v-flex xs3>
                            <v-layout column class=" mx-2 cust_pr">
                                <p class="font-weight-bold cstm-clr-pr">
                                    Last Name *
                                </p>
                                <v-text-field
                                    disabled
                                    background-color='#f7f7f7'
                                    solo
                                    label="Enter..."
                                    v-model="lstnmeInd"
                                    class="rounded-0"
                                    maxlength=20
                                    
                                    >
                                </v-text-field>
                                <!-- :rules="[required(0,''),rules.spclChar(3,'lstnmeInd')]" -->
                            </v-layout>
                        </v-flex>

                        <v-flex xs3>
                            <v-layout column class=" mx-2 cust_pr">
                                <p class="font-weight-bold cstm-clr-pr">
                                    Gender
                                </p>
                                <v-select
                                    :items="gndrIndItem"
                                    label="Select"
                                    v-model="gndrInd"
                                    solo
                                    maxlength=11
                                    class="rounded-0"
                                    :rules="[() => !!salutationind || 'This field is required']">
                                </v-select>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3>
                            <v-layout column class=" mx-2 cust_pr">
                                <p class="font-weight-bold cstm-clr-pr">
                                External Reference No.
                                </p>
                                <v-text-field
                                    autocomplete="off"
                                    solo
                                    label="Enter..."
                                    v-model="IndExtrnlRefNo"
                                    maxlength=50
                                    class="rounded-0">
                                </v-text-field>
                            </v-layout>
                        </v-flex>

                    </v-layout>

                    <v-layout class="mx-2">

                        <v-flex v-if="regEntity !== 'Individual'" xs3>
                            <v-layout column class="cust_pr cust-rmvpd-psins mx-2 cust_Main ">
                                <v-text class="font-weight-bold cstm-clr-pd">
                                    Provider Type *
                                </v-text>
                                <v-select
                                    :items="providerTypeArry"
                                    label="Select..."
                                    v-model="providerType"
                                    background-color='#f7f7f7'
                                    solo
                                    disabled
                                    :rules="[() => !!providerType || 'This field is required']"
                                    class="rounded-0"
                                    >
                                </v-select>
                            </v-layout>
                        </v-flex>

                        <v-flex xs3>
                            <v-layout column class=" mx-2  cust_pr cust_Main">
                                <v-text class="font-weight-bold cstm-clr-pr">
                                    Status
                                </v-text>
                                <v-text-field
                                    solo
                                    label="Enter..."
                                    v-model="provStatus"
                                    disabled
                                    background-color='#f7f7f7'
                                    maxlength="100"
                                    class="rounded-0">
                                </v-text-field>
                            </v-layout>
                        </v-flex>

                        <v-flex v-if="regEntity !== 'Individual'" xs3>
                            <v-layout column class="cust_pr cust-rmvpd-psins mx-2  cust_Main">
                                <p class="font-weight-bold cstm-clr-pr">
                                    Name *
                                </p>
                                <v-text-field
                                    solo
                                    label="Enter..."
                                    disabled
                                    background-color='#f7f7f7'
                                    v-model="provName"
                                    class="rounded-0"
                                    maxlength="100"
                                    :rules="provNameValid"
                                    @input="fieldValidation(provName,'name')"
                                    @blur="fieldValidation(provName,'name')">
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                        <v-flex v-if="regEntity !== 'Individual'" xs3>
                            <v-layout column class="cust_pr cust-rmvpd-psins mx-2  ">
                                <p class="font-weight-bold cstm-clr-pr">
                                    Alias Name
                                </p>
                                <v-text-field
                                    solo
                                    label="Enter..."
                                    v-model="aliasName"
                                    maxlength="100"
                                    class="rounded-0">
                                </v-text-field>
                            </v-layout>
                        </v-flex>

                        <v-flex xs3></v-flex>
                        <v-flex v-if="regEntity == 'Individual'" xs3></v-flex>
                        <v-flex v-if="regEntity == 'Individual'" xs3></v-flex>
                        <v-flex v-if="regEntity == 'Individual'" xs3></v-flex>
                    </v-layout>
                    <v-divider class="my-3"></v-divider>

                    <!-- INDIVIDUAL ENTITY FIELDS -->
                    <v-layout v-if="regEntity == 'Individual'" color="#FFFFFF" class="mx-2 mt-3"  >
                        <v-flex xs3>
                            <v-layout column class="cust_pr mx-2 mt-3 ">
                                <p class="font-weight-bold cstm-clr-pr">Provider Type</p>
                                <v-select
                                    :items="provTypeIndiArray"
                                    label="Select"
                                    v-model="indiProviType"
                                    solo
                                    @change="ptypeis(indiProviType)"
                                    class="rounded-0"
                                    maxlength=20
                                    disabled
                                    background-color='#f7f7f7'
                                    :rules="[() => !!indiProviType || 'This field is required']">
                                </v-select>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3>
                            <v-layout column class="cust_pr mx-2 mt-3">
                                <p class="font-weight-bold cstm-clr-pr">
                                Other Provider Type
                                </p> 
                                <v-text-field
                                    :disabled='ptype_other'
                                    solo
                                    label="Enter..."
                                    filled
                                    v-model="indiProvTypeOther"
                                    class="rounded-0"
                                    maxlength=50
                                    counter="50">
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3>
                            <v-layout column class="cust_pr mx-2 mt-3 ">
                                <p class="font-weight-bold cstm-clr-pr">Qualification</p>
                                <v-select
                                    :items="indiQualificationItems"
                                    label="Select"
                                    v-model="indiQualification"
                                    @change="qualis(indiQualification)"
                                    solo
                                    
                                    class="rounded-0">
                                </v-select>
                            </v-layout>
                        </v-flex>

                        <v-flex xs3>
                            <v-layout column class="cust_pr mx-2 mt-3">
                                <p class="font-weight-bold cstm-clr-pr">
                                Other Qualification
                                </p>
                                <v-text-field
                                    :disabled='qualtype_other_dis'
                                    solo
                                    label="Enter..."
                                    v-model="indiOtherQualifi"
                                    class="rounded-0"
                                    maxlength=50
                                    counter="50">
                                </v-text-field>
                            </v-layout>
                        </v-flex>

                        <v-flex xs3>
                            <v-layout column class="cust_pr mx-2 mt-3 ">
                                <p class="font-weight-bold cstm-clr-pr">Registration No.</p>
                                <v-text-field
                                    solo
                                    label="Enter..."
                                    v-model="indiRegNo"
                                    class="rounded-0"
                                    maxlength=50
                                    counter="50">
                                </v-text-field>
                            </v-layout>
                        </v-flex> 
                    </v-layout>
                    <!-- INDIVIDUAL ENTITY FIELDS -->
                    <v-layout v-if="regEntity == 'Individual'" style="" class="mx-2" >
                        <v-flex xs3>
                            <v-layout column class="cust_pr mx-2 mt-3 ">
                                <p class="font-weight-bold cstm-clr-pr">Registered Council</p>
                                <v-select
                                    :items="indiRegisterCouncil"
                                    label="Select"
                                    v-model="indiRegCouncil"
                                    @change="countype(RgstCnlInd)"
                                    maxlength=20
                                    solo
                                    class="rounded-0">
                                </v-select>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3>
                            <v-layout column class="cust_pr mx-2 mt-3 ">
                                <p class="font-weight-bold cstm-clr-pr">Other Registered Council</p>
                                <v-text-field
                                    maxlength=50
                                    :disabled='regCouncil_other_dis'
                                    v-model="indiOtherRegCouncil"
                                    solo
                                    label="Enter..."
                                    class="rounded-0">
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3>
                            <v-layout column class=" mx-2 mt-3 cust_pr_quick cust-rmvpd-pr-quick">
                                <p class="font-weight-bold cstm-clr-pr-quick">Registration Valid Upto</p>
                                <v-menu
                                    ref="calender"
                                    :close-on-content-click="true"
                                    v-model="indiRegCalDate"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    max-width="290px"
                                    min-width="290px" 
                                    class="rounded-0"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            slot="activator"
                                            label="Select.."
                                            :value="computeIndiRegValidUpto"
                                            solo
                                            class="rounded-0"
                                            readonly 
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                        <div  slot="prepend-inner" style="background-color:#F7F7F7;  padding: 9px !important;"><span class="material-icons" >date_range</span></div></v-text-field>
                                    </template>
                                    <v-date-picker v-model="indiRegValidUpto" no-title></v-date-picker>
                                </v-menu>

                            </v-layout>
                        </v-flex>
                        <v-flex xs3>
                            <v-layout column class=" mx-2  mt-3 cust_pr_quick cust-rmvpd-pr-quick">
                                <p class="font-weight-bold cstm-clr-pr-quick">Registration Date</p>
                                <v-menu
                                    ref="calender"
                                    :close-on-content-click="true"
                                    v-model="indiRegistrCalDate"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    max-width="290px"
                                    min-width="290px" 
                                    class="rounded-0"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            slot="activator"
                                            label="Select.."
                                            :value="computeRegisDate"
                                            solo
                                            class="rounded-0"
                                            readonly 
                                            v-bind="attrs"
                                            disabled
                                            background-color='#f7f7f7'
                                            v-on="on">
                                        <div  dense slot="prepend-inner" style="background-color:#F7F7F7;  padding: 9px !important;"><span class="material-icons">date_range</span></div></v-text-field>
                                    </template>
                                    <v-date-picker v-model="indiRegisterDate" no-title></v-date-picker>
                                </v-menu>
                            </v-layout>
                        </v-flex>

                         <!-- <v-flex xs3>
                            <v-layout column class="cust_pr mx-2 ">
                                <p class="font-weight-bold cstm-clr-pr">Telephone Number</p> 
                                <v-text-field
                                    solo
                                    label="Enter..."
                                    filled
                                    v-model="indiTelefoneNum"
                                    class="rounded-0"
                                    maxlength=50
                                    >
                                </v-text-field>
                            </v-layout>
                        </v-flex> -->
                        
                        <v-flex xs3></v-flex>
                    </v-layout>
                    <!-- INDIVIDUAL ENTITY FIELDS -->
                    
                    <v-divider v-if="regEntity == 'Individual'" class="my-3"></v-divider>
                    
                    <v-layout class="mx-2">
                        <v-flex xs3>
                            <v-layout column class="mx-2 my-2">
                                <v-text v-if="regEntity == 'Individual'" class="text-uppercase font-weight-bold title-clr-pr">CLINIC/WORK ADDRESS</v-text>
                                <v-text v-else class="text-uppercase font-weight-bold title-clr-pr">HEALTH SERVICE ADDRESS</v-text>
                                <v-divider class="mb-3"></v-divider>
                            </v-layout>
                        </v-flex>

                        <v-flex xs3>
                            <v-layout column class="cust_pr cust-rmvpd-psins mx-2 mt-3 cust_Main">
                                <p class="font-weight-bold cstm-clr-pr">
                                    Address Line 1 *
                                </p>
                                <v-text-field
                                    solo
                                    label="Enter..."
                                    :disabled="regEntity === 'Institution'"
                                    :background-color="regEntity === 'Institution'? '#f7f7f7' : ''"
                                    v-model="firstAddressHealth"
                                    maxlength="100"
                                    counter="100"
                                    class="rounded-0">
                                </v-text-field>
                            </v-layout>
                        </v-flex>

                        <v-flex xs3>
                            <v-layout column class="cust_pr cust-rmvpd-psins mx-2 mt-3 cust_Main">
                                <p class="font-weight-bold cstm-clr-pr">
                                    Address Line 2
                                </p>
                                <v-text-field
                                    solo
                                    label="Enter..."
                                    :disabled="regEntity === 'Institution'"
                                    :background-color="regEntity === 'Institution'? '#f7f7f7' : ''"
                                    v-model="secondAddressHealth"
                                    maxlength="100"
                                    counter="100"
                                    class="rounded-0">
                                </v-text-field>
                            </v-layout>
                        </v-flex>

                        <v-flex xs3>
                            <v-layout column class="cust_pr cust-rmvpd-psins mx-2 mt-3 cust_Main">
                                <p class="font-weight-bold cstm-clr-pr">
                                    Pin Code *
                                </p>
                                <v-autocomplete
                                    v-model="pincodeHealth"
                                    :items="pinItems"
                                    :search-input.sync="searchPinCode"
                                    color="white"
                                    hide-no-data
                                    cache-items
                                    item-text="pin"
                                    item-value="pin"
                                    placeholder="Start typing to Search"
                                    @change="getPincode(pincodeHealth, 'from change')"
                                    :rules="[() => !!pincodeHealth || 'This field is required']"
                                    return-object
                                    :disabled="regEntity === 'Institution'"
                                    :background-color="regEntity === 'Institution'? '#f7f7f7' : ''"
                                    maxlength='6'
                                    counter="6"
                                    solo
                                ></v-autocomplete>
                            </v-layout>
                        </v-flex>

                        <v-flex xs3>
                            <v-layout column class="cust_pr cust-rmvpd-psins mx-2 mt-3 cust_Main">
                                <v-text class="font-weight-bold cstm-clr-pd">
                                    Country
                                </v-text>
                                <v-select
                                    :items="countryItems"
                                    label="Select"
                                    v-model="countryHealth"
                                    solo
                                    dense
                                    :disabled="regEntity === 'Institution'"
                                    :background-color="regEntity === 'Institution'? '#f7f7f7' : ''"
                                    item-text="country"
                                    item-value="country"
                                    maxlength='50'
                                    hide-details
                                    class="rounded-0"
                                    >
                                </v-select>
                            </v-layout>
                        </v-flex>
                    </v-layout>

                    <v-layout class="mx-2">
                        <v-flex xs3>
                            <v-layout column class="cust_pr cust-rmvpd-psins mx-2 mt-3 cust_Main">
                                <v-text class="font-weight-bold cstm-clr-pd">
                                    State *
                                </v-text>
                                <v-select
                                    :items="stateItems"
                                    label="Select"
                                    v-model="stateHealth"
                                    solo
                                    item-text="state"
                                    item-value="state"
                                    maxlength='50'
                                    :disabled="regEntity === 'Institution'"
                                    :background-color="regEntity === 'Institution'? '#f7f7f7' : ''"
                                    :rules="[() => !!stateHealth || 'This field is required']"
                                    class="rounded-0"
                                    >
                                </v-select>
                            </v-layout>
                        </v-flex>

                        <v-flex xs3>
                            <v-layout column class="cust_pr cust-rmvpd-psins mx-2 mt-3 cust_Main">
                                <v-text class="font-weight-bold cstm-clr-pd">
                                    District *
                                </v-text>
                                <v-select
                                    :items="districtItems"
                                    label="Select"
                                    v-model="districtHealth"
                                    item-text="district"
                                    item-value="district"
                                    maxlength='50'
                                    solo
                                    :disabled="regEntity === 'Institution'"
                                    :background-color="regEntity === 'Institution'? '#f7f7f7' : ''"
                                    :rules="[() => !!districtHealth || 'This field is required']"
                                    class="rounded-0"
                                    >
                                </v-select>
                            </v-layout>
                        </v-flex>

                        <v-flex xs3>
                            <v-layout column class="cust_pr cust-rmvpd-psins mx-2 mt-3 cust_Main">
                                <v-text class="font-weight-bold cstm-clr-pd">
                                    City/Town/Village *
                                </v-text>
                                <!-- :search-input.sync="citysearch" -->
                                <v-autocomplete
                                    :items="ctvItems"
                                    label="Select"
                                    v-model="cityHealth"
                                    item-text="area"
                                    item-value="area"
                                    @change="cityselected(cityHealth)"
                                    clearable
                                    solo
                                    dense
                                    class="rounded-0"
                                    :disabled="regEntity === 'Institution'"
                                    :background-color="regEntity === 'Institution'? '#f7f7f7' : ''"
                                    maxlength=50
                                    :rules="[() => !!cityHealth || 'This field is required']"
                                    counter="50">
                                </v-autocomplete>

                            </v-layout>
                        </v-flex>

                        <v-flex xs3>
                            <v-layout column class="cust_pr cust-rmvpd-psins mx-2 mt-3 cust_Main">
                                <p class="font-weight-bold cstm-clr-pr">
                                    Other
                                </p>
                                <v-text-field
                                    solo
                                    label="Enter..."
                                    :background-color="enableOthrHlth == true ? '#f7f7f7' : '' "
                                    :disabled="enableOthrHlth"
                                    v-model="otherHealth"
                                    maxlength="100"
                                    class="rounded-0">
                                </v-text-field>
                            </v-layout>
                        </v-flex>

                        <v-flex xs3 v-if="regEntity == 'Individual'" >
                            <v-layout column class="cust_pr cust-rmvpd-psins mx-2 mt-3 ">
                                <v-text class="font-weight-bold cstm-clr-pd">
                                   Area
                                </v-text>
                                <v-text-field
                                    label="Select"
                                    v-model="area"
                                    maxlength="100"
                                    solo
                                    class="rounded-0"
                                    >
                                </v-text-field>
                            </v-layout>
                        </v-flex>

                         <v-flex xs3 v-else>
                            <v-layout column class="cust_pr cust-rmvpd-psins mx-2 mt-3 ">
                                <v-text class="font-weight-bold cstm-clr-pd">
                                    Location Type *
                                </v-text>
                                <v-select
                                    :items="locationItems"
                                    label="Select"
                                    v-model="locationHealth"
                                    maxlength="10"
                                    solo
                                    :rules="[() => !!locationHealth || 'This field is required']"
                                    class="rounded-0"
                                    >
                                </v-select>
                            </v-layout>
                        </v-flex>

                    </v-layout>

                    <v-layout class="mx-2"  v-if="regEntity == 'Individual'" >
                        <v-flex xs3>
                            <v-layout column class="cust_pr cust-rmvpd-psins mx-2 mt-3 ">
                                <v-text class="font-weight-bold cstm-clr-pd">
                                    Location Type *
                                </v-text>
                                <v-select
                                    :items="locationItems"
                                    label="Select"
                                    v-model="locationHealth"
                                    maxlength="10"
                                    solo
                                    :rules="[() => !!locationHealth || 'This field is required']"
                                    class="rounded-0"
                                    >
                                </v-select>
                            </v-layout>
                        </v-flex>

                        <v-flex xs3></v-flex>
                        <v-flex xs3></v-flex>
                        <v-flex xs3></v-flex>
                        <v-flex xs3></v-flex>

                    </v-layout>
                    <v-divider class="my-3"></v-divider>

                    <v-layout class="mx-2" >
                        <v-flex xs3>
                            <v-layout column class="mx-2 my-2">
                                <v-text  v-if="regEntity == 'Individual'" class="text-uppercase font-weight-bold title-clr-pr">RESIDENTIAL ADDRESS</v-text>
                                <v-text  v-else class="text-uppercase font-weight-bold title-clr-pr">COMMUNICATION ADDRESS</v-text>
                                <v-divider class="mb-3"></v-divider>
                                <v-text class="font-weight-bold cstm-clr-pr" style="color:#FFFFFF" ></v-text>
                                <div  class="mt-2">
                                    <v-checkbox @change="commAddress(commAddrCheck)" class="ma-0 pa-0" v-model="commAddrCheck" color="#23B1A9">
                                        <span slot="label" class="lbl-bgclr" style="font-size: 13px">Address same as health service</span>
                                    </v-checkbox>
                                </div>
                            </v-layout>
                        </v-flex>

                        <v-flex xs3>
                            <v-layout column class="cust_pr cust-rmvpd-psins mx-2 my-2 cust_Main">
                                <p class="font-weight-bold cstm-clr-pr">
                                    Address Line 1 *
                                </p>
                                <v-text-field
                                    :background-color="enableCommAddrFields == true ? '#f7f7f7' : ''"
                                    solo
                                    :disabled=enableCommAddrFields
                                    label="Enter..."
                                    v-model="firstAddressComm"
                                    maxlength="100"
                                    counter="100"
                                    :rules="[() => !!firstAddressComm || 'This field is required']"
                                    class="rounded-0">
                                </v-text-field>
                            </v-layout>
                        </v-flex>

                        <v-flex xs3>
                            <v-layout column class="cust_pr cust-rmvpd-psins mx-2 mt-3 cust_Main">
                                <p class="font-weight-bold cstm-clr-pr">
                                    Address Line 2
                                </p>
                                <v-text-field
                                    :background-color="enableCommAddrFields == true ? '#f7f7f7' : ''"
                                    solo
                                    label="Enter..."
                                    :disabled=enableCommAddrFields
                                    v-model="secondAddressComm"
                                    maxlength="100"
                                    counter="100"
                                    class="rounded-0">
                                    <!-- :rules="[() => !!secondAddressComm || 'This field is required']" -->
                                </v-text-field>
                            </v-layout>
                        </v-flex>

                        <v-flex xs3>
                            <v-layout column class="cust_pr cust-rmvpd-psins mx-2 mt-3 cust_Main">
                                <p class="font-weight-bold cstm-clr-pr">
                                    Pincode *
                                </p>
                                <v-autocomplete
                                    :background-color="enableCommAddrFields == true ? '#f7f7f7' : ''"
                                    v-model="pincodeComm"
                                    :items="pinItemsComm"
                                    :search-input.sync="searchPinCodeComm"
                                    color="white"
                                    hide-no-data
                                    cache-items
                                    item-text="pin"
                                    item-value="pin"
                                    placeholder="Start typing to Search"
                                    @change="getPincodeComm(pincodeComm, 'from change')"
                                    :rules="[() => !!pincodeComm || 'This field is required']"
                                    return-object
                                    :disabled=enableCommAddrFields
                                    maxlength='6'
                                    counter="6"
                                    solo
                                ></v-autocomplete>
                                
                            </v-layout>
                        </v-flex>

                        <v-flex xs3>
                            <v-layout column class="cust_pr cust-rmvpd-psins mx-2 mt-3 cust_Main">
                                <v-text class="font-weight-bold cstm-clr-pd">
                                    Country
                                </v-text>
                                <v-select
                                    :background-color="enableCommAddrFields == true ? '#f7f7f7' : ''"
                                    :items="countryItems"
                                    label="Select"
                                    v-model="countryComm"
                                    solo
                                    dense
                                    item-text="country"
                                    item-value="country"
                                    maxlength="50"
                                    :disabled=enableCommAddrFields
                                    hide-details
                                    class="rounded-0"
                                    >
                                </v-select>
                            </v-layout>
                        </v-flex>

                        
                    </v-layout>

                    <v-layout class="mx-2">

                        <v-flex xs3>
                            <v-layout column class="cust_pr cust-rmvpd-psins mx-2 mt-3 cust_Main">
                                <v-text class="font-weight-bold cstm-clr-pd">
                                    State *
                                </v-text>
                                <v-select
                                    :background-color="enableCommAddrFields == true ? '#f7f7f7' : ''"
                                    :items="stateItemsComm"
                                    label="Select"
                                    v-model="stateComm"
                                    solo
                                    item-text="state"
                                    item-value="state"
                                    maxlength='50'
                                    :disabled=enableCommAddrFields
                                    :rules="[() => !!stateComm || 'This field is required']"
                                    class="rounded-0"
                                    >
                                </v-select>
                            </v-layout>
                        </v-flex>

                        <v-flex xs3>
                            <v-layout column class="cust_pr cust-rmvpd-psins mx-2 mt-3 cust_Main">
                                <v-text class="font-weight-bold cstm-clr-pd">
                                    District *
                                </v-text>
                                <v-select
                                    :background-color="enableCommAddrFields == true ? '#f7f7f7' : ''"
                                    :items="districtItemsComm"
                                    label="Select"
                                    v-model="districtComm"
                                    solo
                                    item-text="district"
                                    item-value="district"
                                    maxlength='50'
                                    :rules="[() => !!districtComm || 'This field is required']"
                                    :disabled=enableCommAddrFields
                                    class="rounded-0"
                                    >
                                </v-select>
                            </v-layout>
                        </v-flex>

                        <v-flex xs3>
                            <v-layout column class="cust_pr cust-rmvpd-psins mx-2 mt-3 cust_Main">
                                <v-text class="font-weight-bold cstm-clr-pd">
                                    City/Town/Village *
                                </v-text>
                                <!-- <p>{{cityComm}}</p> -->
                                <!-- :search-input.sync="citysearchComm" -->
                                <v-autocomplete
                                    :background-color="enableCommAddrFields == true ? '#f7f7f7' : ''"
                                    :items="ctvItemsComm"
                                    label="Select"
                                    v-model="cityComm"
                                    item-text="area"
                                    item-value="area"
                                    @change="cityselectedComm(cityComm)"
                                    clearable
                                    solo
                                    dense
                                    class="rounded-0"
                                    :disabled=enableCommAddrFields
                                    maxlength=50
                                    :rules="[() => !!cityComm || 'This field is required']"
                                    counter="50">
                                </v-autocomplete>
                            </v-layout>
                        </v-flex>

                        <v-flex xs3>
                            <v-layout column class="cust_pr cust-rmvpd-psins mx-2 mt-3 cust_Main">
                                <p class="font-weight-bold cstm-clr-pr">
                                    Other
                                </p>
                                <v-text-field
                                    :background-color="enableOthrHlthComm == true ? '#f7f7f7' : ''"
                                    solo
                                    label="Enter..."
                                    :disabled=enableOthrHlthComm
                                    v-model="otherComm"
                                    maxlength="100"
                                    class="rounded-0">
                                </v-text-field>
                            </v-layout>
                        </v-flex>

                        <!-- <v-flex xs3>
                            <v-layout column class="cust_pr cust-rmvpd-psins mx-2 mt-3 ">
                                <v-text class="font-weight-bold cstm-clr-pd">
                                    Location Type *
                                </v-text>
                                <v-select
                                    :items="locationItems"
                                    label="Select"
                                    v-model="locationComm"
                                    maxlength="10"
                                    solo
                                    :rules="[() => !!locationComm || 'This field is required']"
                                    class="rounded-0"
                                    >
                                </v-select>
                            </v-layout>
                        </v-flex> -->

                         <v-flex xs3 v-if="regEntity == 'Individual'" >
                            <v-layout column class="cust_pr cust-rmvpd-psins mx-2 mt-3 ">
                                <v-text class="font-weight-bold cstm-clr-pd">
                                   Area
                                </v-text>
                                <v-text-field
                                    label="Select"
                                    v-model="areaComm"
                                    maxlength="100"
                                    solo
                                    class="rounded-0"
                                    >
                                </v-text-field>
                            </v-layout>
                        </v-flex>

                         <v-flex xs3 v-else>
                            <v-layout column class="cust_pr cust-rmvpd-psins mx-2 mt-3 ">
                                <v-text class="font-weight-bold cstm-clr-pd">
                                    Location Type *
                                </v-text>
                                <v-select
                                    :items="locationItems"
                                    label="Select"
                                    v-model="locationComm"
                                    maxlength="10"
                                    solo
                                    :rules="[() => !!locationComm || 'This field is required']"
                                    class="rounded-0"
                                    >
                                </v-select>
                            </v-layout>
                        </v-flex>

                    </v-layout>

                    <v-layout  class="mx-2" v-if="regEntity == 'Individual'" >
                        <v-flex xs3>
                            <v-layout column class="cust_pr cust-rmvpd-psins mx-2 mt-3 ">
                                <v-text class="font-weight-bold cstm-clr-pd">
                                    Location Type *
                                </v-text>
                                <v-select
                                    :items="locationItems"
                                    label="Select"
                                    v-model="locationComm"
                                    maxlength="10"
                                    solo
                                    :rules="[() => !!locationHealth || 'This field is required']"
                                    class="rounded-0"
                                    >
                                </v-select>
                            </v-layout>
                        </v-flex>

                        <v-flex xs3></v-flex>
                        <v-flex xs3></v-flex>
                        <v-flex xs3></v-flex>
                        <v-flex xs3></v-flex>

                    </v-layout>

                    <v-divider class="my-3"></v-divider>

                    <v-layout  class="mx-2" v-if="regEntity == 'Institution'">
                        <v-flex xs3>
                            <v-layout column class="cust_pr cust-rmvpd-psins mx-2 my-2 ">
                                <p class="font-weight-bold cstm-clr-pr">
                                    Registration Number
                                </p>
                                <v-text-field
                                    solo
                                    label="Enter..."
                                    v-model="regNumber"
                                    maxlength="50"
                                    class="rounded-0">
                                </v-text-field>
                            </v-layout>
                        </v-flex>

                        <v-flex xs3>
                            <v-layout column class="cust_pr cust-rmvpd-psins mx-2 my-2 ">
                                <p class="font-weight-bold cstm-clr-pr">
                                    Registered Body
                                </p>
                                <v-text-field
                                    solo
                                    label="Enter..."
                                    v-model="regBody"
                                    maxlength="100"
                                    class="rounded-0">
                                </v-text-field>
                            </v-layout>
                        </v-flex>

                        <v-flex xs3>
                            <v-layout column class=" mx-2 my-2 cust_pd cust-rmvpd-pr">
                                <v-text class="font-weight-bold cstm-clr-pd">
                                    Registered Valid Upto
                                </v-text>
                            
                                <v-menu
                                    ref="calender"
                                    :close-on-content-click="true"
                                    v-model="Regvalidupto"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    max-width="290px"
                                    min-width="290px" 
                                    class="rounded-0"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            slot="activator"
                                            label="Select.."
                                            :value="cmputdRegisteredValiduptojs"
                                            solo
                                            class="rounded-0"
                                            maxlength="10"
                                            readonly 
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                        <div  slot="prepend-inner" style="background-color:#F7F7F7;  padding: 9px !important;"><span class="material-icons">date_range</span></div></v-text-field>
                                    </template>
                                    <v-date-picker v-model="RegistrationValidupto" no-title></v-date-picker>
                                </v-menu>
                            </v-layout>
                            </v-flex>

                        <v-flex xs3>
                            <v-layout column class="cust_pr cust-rmvpd-psins mx-2 my-2 ">
                                <p class="font-weight-bold cstm-clr-pr">
                                    ROHINI ID *
                                </p>
                                <v-text-field
                                    solo
                                    label="Enter..."
                                    v-model="rohiniId"
                                    class="rounded-0"
                                    maxlength="13"
                                    :rules="rohiniIdValid"
                                    @input="fieldValidation(rohiniId,'rohiniID')"
                                    @blur="fieldValidation(rohiniId,'rohiniID')">
                                </v-text-field>
                            </v-layout>
                        </v-flex>

                        <v-flex xs3></v-flex>
                    </v-layout>
                    
                    <v-layout  class="mx-2" v-if="regEntity == 'Institution'">
                        <v-flex xs3>
                            <v-layout column class="mx-2 my-2 mt-12">
                                <v-text class="font-weight-bold title-clr-pr">
                                    Latitude
                                </v-text>
                                <v-divider></v-divider>
                            </v-layout>
                        </v-flex>

                        <v-flex xs3>
                            <v-layout column class="cust_pr cust-rmvpd-psins mx-2 my-2 ">
                                <v-text class="font-weight-bold cstm-clr-pd">
                                    Degrees
                                </v-text>
                                <v-text-field
                                    solo
                                    label="Enter..."
                                    v-model="degreeLat"
                                    class="rounded-0"
                                    maxlength="20"
                                    :rules="latDegreeValid"
                                    @input="fieldValidation(degreeLat,'latDegree')">
                                </v-text-field>
                            </v-layout>
                        </v-flex>

                        <v-flex xs3>
                            <v-layout column class="cust_pr cust-rmvpd-psins mx-2 my-2 ">
                                <v-text class="font-weight-bold cstm-clr-pd">
                                    Direction
                                </v-text>
                                <v-select
                                    :items="direction"
                                    label="Select"
                                    v-model="dirLat"
                                    maxlength="20"
                                    solo
                                    dense
                                    hide-details
                                    class="rounded-0"
                                    >
                                </v-select>
                            </v-layout>
                        </v-flex>

                        <v-flex xs3>
                            <v-layout column class="cust_pr cust-rmvpd-psins mx-2 my-2 ">
                                <v-text class="font-weight-bold cstm-clr-pd">
                                    Minutes
                                </v-text>
                                <v-text-field
                                    solo
                                    label="Enter..."
                                    v-model="minLat"
                                    class="rounded-0"
                                    maxlength="20"
                                    :rules="latMinValid"
                                    @input="fieldValidation(minLat,'latMinute')">
                                </v-text-field>
                            </v-layout>
                        </v-flex>

                        <v-flex xs3>
                            <v-layout column class="cust_pr cust-rmvpd-psins mx-2 my-2 ">
                                <v-text class="font-weight-bold cstm-clr-pd">
                                    Seconds
                                </v-text>
                                <v-text-field
                                    solo
                                    label="Enter..."
                                    v-model="secLat"
                                    class="rounded-0"
                                    maxlength="20"
                                    :rules="latSecValid"
                                    @input="fieldValidation(secLat,'latSeconds')">
                                </v-text-field>
                            </v-layout>
                        </v-flex>

                    </v-layout>

                    <v-layout class="mx-2" v-if="regEntity == 'Institution'" >
                        <v-flex xs3>
                            <v-layout column class="mx-2 my-2 mt-12">
                                <v-text class="font-weight-bold title-clr-pr">
                                    Longitude
                                </v-text>
                                <v-divider></v-divider>
                            </v-layout>
                        </v-flex>

                        <v-flex xs3>
                            <v-layout column class="cust_pr cust-rmvpd-psins mx-2 my-4 ">
                                <v-text class="font-weight-bold cstm-clr-pd">
                                    Degrees
                                </v-text>
                                <v-text-field
                                    solo
                                    label="Enter..."
                                    v-model="degLong"
                                    class="rounded-0"
                                    maxlength="20"
                                    :rules="longiDegreeValid"
                                    @input="fieldValidation(degLong,'longiDegree')">
                                </v-text-field>
                            </v-layout>
                        </v-flex>

                        <v-flex xs3>
                            <v-layout column class="cust_pr cust-rmvpd-psins mx-2 my-4 ">
                                <v-text class="font-weight-bold cstm-clr-pd">
                                    Direction
                                </v-text>
                                <v-select
                                    :items="direction"
                                    label="Select"
                                    v-model="dirLong"
                                    maxlength="20"
                                    solo
                                    dense
                                    hide-details
                                    class="rounded-0"
                                    >
                                </v-select>
                            </v-layout>
                        </v-flex>

                        <v-flex xs3>
                            <v-layout column class="cust_pr cust-rmvpd-psins mx-2 my-4 ">
                                <v-text class="font-weight-bold cstm-clr-pd">
                                    Minutes
                                </v-text>
                                <v-text-field
                                    solo
                                    label="Enter..."
                                    v-model="minLong"
                                    class="rounded-0"
                                    maxlength="20"
                                    :rules="longiMinValid"
                                    @input="fieldValidation(minLong,'longiMinute')">
                                </v-text-field>
                            </v-layout>
                        </v-flex>

                        <v-flex xs3>
                            <v-layout column class="cust_pr cust-rmvpd-psins mx-2 my-4 ">
                                <v-text class="font-weight-bold cstm-clr-pd">
                                    Seconds
                                </v-text>
                                <v-text-field
                                    solo
                                    label="Enter..."
                                    v-model="secLong"
                                    class="rounded-0"
                                    maxlength="20"
                                    :rules="longiSecValid"
                                    @input="fieldValidation(secLong,'longiSeconds')">
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                    </v-layout>
                    <v-layout  class="mx-2" v-if="regEntity == 'Institution'" >
                        <v-flex xs3>
                            <v-layout column class="mx-2 my-2 mt-12">
                                <v-text class="font-weight-bold title-clr-pr">Remarks</v-text>
                                <v-divider></v-divider>
                            </v-layout>
                        </v-flex>

                        <v-flex xs12>
                            <v-layout column class="cust_pr cust-rmvpd-psins mx-2 my-2 ">
                                <p class="font-weight-bold cstm-clr-pr">Type Here</p>
                                <v-text-field
                                    solo
                                    full-width
                                    label="Enter..."
                                    maxlength="200"
                                    v-model="typeHere"
                                    class="rounded-0">
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                    </v-layout>
                </v-form>

                <v-dialog v-model="confirmDialogCancel" persistent max-width="450px">
                 
                         <v-card tile color="#F7F7F7" >
                            <v-card tile>
                                <v-layout justify-space-between  class="" style="flex:1;background-ox-shadow: 0px 3px 5px #00000029;">
                             
                                    <v-card-title class="pa-0 pl-3 text-capitalize font-weight-regular" style="color:black"> 
                                       CONFIRMATION
                                    </v-card-title>
                                    <div style="border-left:1px solid #C1C8CC;" @click="close_cancelDialog()">
                                        <v-icon style="cursor: pointer;" class="pa-2" >close</v-icon>
                                    </div>
                                </v-layout>
                            </v-card>
                        <v-flex >
                           
                       
                        </v-flex>
                        <v-card-text >
                                Details saved on screen will be lost
                        </v-card-text>
                        <v-card-actions justify-start>
                          
                           
                           
                            <v-btn dark  tile color="black" @click="confirmDialogCancel = false" class="text-capitalize px-3 mx-2" small><v-icon dark small>cancel</v-icon> <v-divider vertical class="mx-2"></v-divider>Cancel</v-btn>

                            <!-- <v-btn depressed small color="secondary" class="rounded-0"  >
                                <v-icon dark right>check_circle</v-icon>
                            </v-btn> -->
                            <v-btn @click="selectedCompFromPage(arrToNavCancel[1])" dark  tile color="#23B1A9" class="text-capitalize px-3 ml-2" small><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider> OK</v-btn>

                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <v-dialog v-model="confirmDialogNext" persistent max-width="450px">
                 
                         <v-card tile color="#F7F7F7" >
                            <v-card tile>
                                <v-layout justify-space-between  class="" style="flex:1;background-ox-shadow: 0px 3px 5px #00000029;">
                             
                                    <v-card-title class="pa-0 pl-3 text-capitalize font-weight-regular" style="color:black"> 
                                       CONFIRMATION
                                    </v-card-title>
                                    <div style="border-left:1px solid #C1C8CC;" @click="close_cancelDialog()">
                                        <v-icon style="cursor: pointer;" class="pa-2" >close</v-icon>
                                    </div>
                                </v-layout>
                            </v-card>
                        <v-flex >
                           
                       
                        </v-flex>
                        <v-card-text >
                                Details entered on the page will be lost if not saved
                        </v-card-text>
                        <v-card-actions justify-start>
                          
                           
                           
                            <v-btn dark  tile color="black" @click="confirmDialogNext = false" class="text-capitalize px-3 mx-2" small><v-icon dark small>cancel</v-icon> <v-divider vertical class="mx-2"></v-divider>Cancel</v-btn>

                            <!-- <v-btn depressed small color="secondary" class="rounded-0"  >
                                <v-icon dark right>check_circle</v-icon>
                            </v-btn> -->
                            <v-btn @click="selectedCompFromPage(nextTabsName)" dark  tile color="#23B1A9" class="text-capitalize px-3 ml-2" small><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider> OK</v-btn>

                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <div v-if="docen">
                    <docup 
                        @closedoc="docen = false"    
                        @getdocslist="doc_for_creation"
                        @deletenow="deletelocal" 
                        :setdoc="{docen:docen,provider_data:$store.state.empanelProviderData.empanelment,
                        type:this.depends, updocs:this.create_doc_arr,from:'emp' }" >
                    </docup>
                </div>

            <div class="mx-3 pa-3 mb-2" style="background-color: #F7F7F7; display:flex;justify-content:space-between"  >
                <v-layout style="flex:1" justify-end >
                    <!-- <v-btn @click="uploadBtn()" dark  tile color="#E46A25" class="text-capitalize px-3 mx-2" small><v-icon dark small>cloud_upload</v-icon> <v-divider vertical class="mx-2"></v-divider>Upload Documents</v-btn> -->
                    <v-btn  @click="uploadBtn()" dark  tile color="#152F38"   class="text-capitalize px-3 mx-2" small><v-icon dark small>cloud_upload</v-icon> <v-divider vertical class="mx-2"></v-divider> Upload Documents</v-btn>
                    <v-btn dark   @click="cancelData()" tile color="#E46A25" class="text-capitalize px-3 mx-2" small><v-icon dark small>cancel</v-icon> <v-divider vertical class="mx-2"></v-divider>Cancel</v-btn>
                    <v-btn dark  @click="clearData()" tile color="#D1121B" class="text-capitalize px-3 mx-2" small><v-icon dark small>delete</v-icon> <v-divider vertical class="mx-2"></v-divider>Clear</v-btn>
                    <v-btn @click="saveData()" dark  tile color="#23B1A9" class="text-capitalize px-3 ml-2" small><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider> Save</v-btn>
                    <v-btn dark @click="nextBtn()" tile color="black" class="text-capitalize px-3 ml-4" small><v-icon dark small >arrow_forward</v-icon> <v-divider vertical class="mx-2"></v-divider> Next</v-btn>
                </v-layout>
            </div>
        </div>
    <!-- </v-app> -->
</template>

<script>
// var fileUpload = null;
import moment from 'moment';
import docup from '../ProviderRegistrationClaims/docup'
export default {
    inject : ['selectedCompFromPage', 'arrToNavCancel'],

    components:{
        docup
    },

    created() {
        if(this.$route.params.userId === undefined){

            if(Object.keys(this.$store.state.empanelProviderData).length !== 0){
                let userId = this.$store.state.empanelProviderData.provider._id
                console.log("%c this is from params","background-color:yellow",userId)
                this.getProviderDetails(userId,'normal')
            }
             this.getCountries()
        }else{
            console.log("%c this is object from work item Queue","background-color:black;color:white","\n",this.$route.params);
            if(this.$route.params.type === "Review Empanelment"){
                console.log("%c i was in Review Empanelment","background-color:red",this.$route.params.type)
                var userId = this.$route.params.userId;
                this.getProviderDetails(userId, 'rc')
                this.$emit("forenable");
               
                console.log("%c this is from params","background-color:red",this.$route.params.userId)
            }else if(this.$route.params.type === "Provider Review" || this.$route.params.type === "Provider Approval"){
                console.log("%c i was in Provider Review","background-color:red",this.$route.params.type)
                var userId = this.$route.params.userId;
                this.getProviderDetails(userId, 'ac')
                this.$emit("forenable");
             
                console.log("%c this is from params","background-color:red",this.$route.params.userId)
            }
        }  
    },

    destroyed(){
        console.log("%c destroyed was called","background-color:red");
        this.$route.params.userId = undefined
    },

 

    computed: {
        computeRegisDate(){
            return this.indiRegisterDate ? moment(this.indiRegisterDate).format("DD-MM-YYYY"): ''
        },
        computeIndiRegValidUpto(){
            return this.indiRegValidUpto ? moment(this.indiRegValidUpto).format("DD-MM-YYYY"): ''
        },
        cmputdRegistrationDatejs(){
            return this.RegistrationDate ? moment(this.RegistrationDate).format("DD-MM-YYYY"): ''
        },
        cmputdRegisteredValiduptojs(){
            return this.RegistrationValidupto ? moment(this.RegistrationValidupto).format("DD-MM-YYYY"): ''
        },
        searchedItems () {
            return this.searchEntries;
        },
        pinItems(){
            return this.pinItemArray 
        },

        pinItemsComm(){
            return this.pinItemArrayComm 
        },

    },
       filters:{
      truncate: function (text, length, suffix) {
            if (text.length > length) {
                return text.substring(0, length) + suffix;
            } else {
                return text;
            }
        },
    },

    data() {
        return {
            profilepicname:"----",
            depends:'new',
            // Individual Provider
            // indiTelefoneNum:'',
            // indiFaxNum:'',
            // indiChildProv:'',
            // indiParentProv:'',
            // indiMapType:'',
            IndExtrnlRefNo:'',
            indiProviType:'',
            indiProvTypeOther:'', 
            ptype_other:true,
            indiQualification:'', 
            indiOtherQualifi:'', 
            qualtype_other_dis: true, 
            indiRegNo:'', 
            indiRegCouncil:'', 
            indiOtherRegCouncil:'',
            regCouncil_other_dis: true,
            indiRegValidUpto:'', 
            indiRegCalDate:'', 
            indiRegistrCalDate:'', 
            indiRegisterDate:'', 
            salutationind:'',
            frstnmeInd:'',
            mdlnmeInd:'',
            lstnmeInd:'',
            gndrInd:'',
            indiRegisterCouncil:[
                'Himanchal Pradesh Medical Council',
                'Jammu & Kashmir Medical Council',
                'Jharkhand Medical Council',
                'Karnataka Medical Council',
                'Travancore Cochin Medical Council',
                'Madhya Pradesh Medical Council',
                'Maharashtra Medical Council',
                'Manipur Medical Council',
                'Mizoram Medical Council',
                'Nagaland Medical Council',
                'Orissa Council of Medical Registration',
                'Punjab Medical Council',
                'Rajasthan Medical Council',
                'Sikkim Medical Council',
                'Tamil Nadu Medical Council',
                'Telangana State Medical Council',
                'Tripura State Medical Council',
                'Uttar Pradesh Medical Council',
                'Uttarakhand Medical Council',
                'West Bengal Medical Council',
                'Central Council of Indian Medicine',
                'Other',
            ],
            provTypeIndiArray:[ 
                'Doctor',
                'Dentist',
                'Physiotherapist',
                'Homeopath',
                'Ayurved',
                'Nurse',
                'Psychotherapist',
                'Nutritionist',
                'Dietician',
                'Other',
            ],
            
            indiQualificationItems:[
                'MBBS',
                'MD',
                'MS',
                'DM',
                'MCh',
                'DNB',
                'FRCS',
                'MRCP',
                'BDS',
                'MDS',
                'BSc (Nursing)',
                'MSc (Nursing)',
                'BPt',
                'MPt',
                'BAMS',
                'BHMS',
                'BUMS',
                'PhD',
                'Other',
            ],
            individualProvider: false,
            sltnIndItems:["Dr","Mr","Mrs","Ms","Prof."],
            gndrIndItem:["Male","Female","Other"],
            empanelStoreData:{},
            mediaFile:null,
            create_doc_arr:[],
            docen:false,
            nextTabsName: '',           
            confirmDialogCancel:false,
            confirmDialogNext:false,
            search_area_pageno:1,
            area_page_no:1,
            ctvItems:[],
            providerTypeArry:[
                'Dental',
                'Hospital',
                'Daycare Center',
                'Nursing Home',
                'Clinic',
                'Rehabilitation Center',
                'Pharmacy',
                'Diagnostic Centre',
                'Home Care provider',
                'Hospice',
                'Ambulance',
                'Air Ambulance',
                'Telehealth Provider',
                'Psychiatric Facility',
                'Doctor',
                'Dentist',
                'Physiotherapist',
                'Homeopath',
                'Ayurved',
                'Nurse',
                'Psychotherapist',
                'Nutritionist',
                'Dietician',
                'Other',
            ],
            searchDataArray:[],
            counter:0,
            s_c_d_nm:false,
            pinItemArray:[],
            pinItemArrayComm:[],
            empanelType:'',
            searchProviderName: null,
            searchPinCode:null,
            searchPinCodeComm:null,
            searchEntries: [],
            isLoading: false,
            enableCommAddrFields:false,
            items:[],
            rohiniIdValid:[],
            latDegreeValid:[],
            latMinValid:[],
            latSecValid:[],
            longiDegreeValid:[],
            longiMinValid:[],
            longiSecValid:[],
            searchProvidValid:[],
            provNameValid:[],
            showEmpRenewal:false,
            providerDetails:"",
            RegDate:'',
            RegistrationDate:'',
            regEntity:"Institution",
            latDegreeList:['0','10','20','40','80','90','100'],
            longiDegreeList:['0','10','20','40','80','90','100','120','160','180','200'],
            latMinList:['0','10','15','20','25','30','35','40','45','50','55','60','65'],
            latSecList:['0','10','15','20','25','30','35','40','45','50','55','60','65'],
            regitems:["Individual","Institution"],
            provPrnNo:"",
            providerType:'',
            provStatus:"",
            statusitems:["Active","Inactive"],
            provName:"",
            aliasName:"",
            firstAddressHealth:"",
            secondAddressHealth:"",
            pincodeHealth:"",
            countryHealth:"",
            stateHealth:"",
            districtHealth:"",
            cityHealth:"",
            otherHealth:"",
            locationHealth:"",
            area:"",
            locationItems:["Rural","Urban"],
            commAddrCheck:false,
            firstAddressComm:"",
            secondAddressComm:"",
            pincodeComm:"",
            countryComm:"",
            stateComm:"",
            districtComm:"",
            cityComm:"",
            otherComm:"",
            locationComm:"",
            areaComm:"",
            regNumber:"",
            regBody:"",
            regValid:"",
            rohiniId:"0",
            degreeLat:"",
            dirLat:"",
            direction:["North","South","East","West"],
            minLat:"",
            secLat:"",
            degLong:"",
            dirLong:"",
            minLong:"",
            secLong:"",
            typeHere:"",
            Regvalidupto:"",
            RegistrationValidupto:"",
            districtItems:[],
            countryItems:[],
            stateItems:[],
            enableOthrHlth:true,
            _providerData:{},

            ctvItemsComm:[],
            search_area_pagenoComm:1,
            area_page_noComm:1,
            searchDataArrayComm:[],
            counterComm:0,
            districtItemsComm:[],
            countryItemsComm:[],
            stateItemsComm:[],
            enableOthrHlthComm:true,
            b64Image:'',
            upload_docs:false,
            prov_id:'',
        }
    },

    methods:{
        deleteprofile(){
            this.upload_docs = false; 
            this.b64Image = ''
            this.profilepicname = "----";
            this.mediaFile =null
            
        },
        deletelocal(indata_id){
            console.log(this.create_doc_arr,"deletelocal called ", indata_id);
            var poss = this.create_doc_arr.findIndex(e => e._uid == indata_id)
            // console.log("poss is ",poss);
            this.create_doc_arr.splice(poss, 1);
        },
        doc_for_creation(docarr){
            if(docarr.length != 0){
                  this.create_doc_arr.push(docarr)
            }
        },

        upload_fileis(event){
             console.log(event.target.files[0].type.split('/')[1], '%c PHOTO_UPLOADDDDDD',"background-color:#dfe114;color:black",event.target.files[0].type)
            
            if(event.target.files[0].type.split('/')[1] == 'jpeg' || event.target.files[0].type.split('/')[1] == 'png' || event.target.files[0].type.split('/')[1] == 'png'){
                console.log(" it matches ");
            }else{
                this.showToast('Only .jpg and .png image allowed with maximum size of 2 MB', this.TOST().WARNING);      
                return
            }
           
            if(event.target.files[0].size > 2000000){      
                this.showToast('Only .jpg and .png image allowed with maximum size of 2 MB', this.TOST().WARNING);      
                return
            }

            // console.log('STOREEEEE+==========',this.$store.state.empanelProviderData.empanelment)
            this.mediaFile = event.target.files[0]
            this.profilepicname = event.target.files[0].name
            var self = this
            var picReader = new FileReader();
            picReader.readAsDataURL(this.mediaFile);
            picReader.onload = function(e){
                self.b64Image = e.target.result;
                self.upload_docs = true
                // console.log('b64Image+==========',self.b64Image)
              
                  self.$forceUpdate();
                    //    console.log(self.b64Image == '')
                    //    console.log(self.empanelStoreData.hasOwnProperty('photoUrl') !== true);
                //   console.log(self.b64Image !== '' || self.empanelStoreData.hasOwnProperty('photoUrl') !== true)
                // console.log('STOREEEEE+==========',this.$store.state.empanelProviderData.empanelment)
            }
        },
        profileupload(){
            console.log("profile file upload called ");
            document.getElementById('imgfiles').value = "";
            document.getElementById('imgfiles').click();
        },

        empanelTypeFunct(ev){
            console.log("this is tabs of Empanelment Type",ev,'\n',this.$store.state.empanelProviderData.empanelment.empanelmentType);
            if(Object.keys(this.$store.state.empanelProviderData).length !== 0){
                console.log("this is data from empanelTypeFunction",this.$store.state.empanelProviderData);
            }
            this.empanelType = ev
        },
        fieldValidation(value,fieldName){
            let nameRegex =/^[A-Za-z&_. -]+$/;
            let noRegex =/^[0-9 ]+$/;

            // console.log('(((((((((( fieldValidation ))))))))))',value)

            if(fieldName == 'rohiniID'){
                this.rohiniIdValid = []
                value === '' ? this.rohiniIdValid.push('This field is required') : ''
                value.match(/^[0-9]{13}$/) ? this.rohiniIdValid.push() : this.rohiniIdValid.push('Invalid ROHINI ID')
                // value.length !== 13 || value == 0 || value == '' ? this.rohiniIdValid.push('Invalid ROHINI ID') : this.rohiniIdValid.push() 

            }else if(fieldName == 'latDegree'){
                this.latDegreeValid = []
                value === '' ? this.latDegreeValid.push('This field is required') : ''
                value <= 90 ? this.latDegreeValid.push() : this.latDegreeValid.push('0 to 90 are accepted values')
                value.match(/^[0-9]*(\.[0-9]{0,6})?$/) ? this.latDegreeValid.push() : this.latDegreeValid.push('Please enter correct Latitude and Longitude')

            }else if(fieldName == 'latMinute'){
                this.latMinValid = []
                value === '' ? this.latMinValid.push('This field is required') : ''
                value <= 60 ? this.latMinValid.push() : this.latMinValid.push('0 to 60 are accepted values')

            }else if(fieldName == 'latSeconds'){
                this.latSecValid = []
                value === '' ? this.latSecValid.push('This field is required') : ''
                value <= 60 ? this.latSecValid.push() : this.latSecValid.push('0 to 60 are accepted values')

            }else if(fieldName == 'longiDegree'){
                this.longiDegreeValid = []
                // /^[0-9]*(\.[0-9]{0,2})?$/
                // /^\d+(\.\d{1,2})?$/
                
                value === '' ? this.longiDegreeValid.push('This field is required') : ''
                value <= 180 ? this.longiDegreeValid.push() : this.longiDegreeValid.push('0 to 180 are accepted values')
                value.match(/^[0-9]*(\.[0-9]{0,6})?$/) ? this.longiDegreeValid.push() : this.longiDegreeValid.push('Please enter correct Latitude and Longitude')

            }else if(fieldName == 'longiMinute'){
                this.longiMinValid = []
                value === '' ? this.longiMinValid.push('This field is required') : ''
                value <= 60 ? this.longiMinValid.push() : this.longiMinValid.push('0 to 60 are accepted values')

            }else if(fieldName == 'longiSeconds'){
                this.longiSecValid = []
                value === '' ? this.longiSecValid.push('This field is required') : ''
                value <= 60 ? this.longiSecValid.push() : this.longiSecValid.push('0 to 60 are accepted values')

            }else if(fieldName == 'name'){
                this.provNameValid = []
                value === '' ? this.provNameValid.push('This field is required') : ''
                value.match(nameRegex) ? this.provNameValid.push() : this.provNameValid.push('Only alphabets are allowed')

            }else if(fieldName == 'search'){
                this.searchProvidValid = []
                value === '' ? this.searchProvidValid.push('This field is required') : ''
                // value !== this.providerDetails ? this.searchProvidValid.push() : this.searchProvidValid.push('Provider already empaneled')

            }
        },

        commAddress(event){
            if(event == true){
                this.enableCommAddrFields = true
                this.enableOthrHlthComm = true
                this.firstAddressComm = this.firstAddressHealth
                this.secondAddressComm = this.secondAddressHealth
                this.pincodeComm = this.pincodeHealth
                this.countryComm = this.countryHealth
                this.stateComm = this.stateHealth
                this.districtComm = this.districtHealth
                this.cityComm = this.cityHealth
                // console.log('**********(((((((((cityComm+==========',this.cityComm)
                this.otherComm = this.otherHealth
                this.locationComm = this.locationHealth
                this.areaComm = this.area
                this.ctvItemsComm = this.ctvItems
                // if(this.cityComm == this.pincodeHealth.all.area) this.getPincodeComm(this.pincodeHealth, 'Frm CALLED API')
                if(this.cityComm == this.pincodeHealth.all.area) this.callapiComm(this.pincodeHealth.pin, 0)
                // this.getPincodeComm(this.pincodeHealth, 'Frm CALLED API')
                // this.callapiComm(this.pincodeComm.pin,2)
            }else{
                this.enableCommAddrFields = false
                // this.enableOthrHlthComm = false
                this.firstAddressComm = ''
                this.secondAddressComm = ''
                this.pincodeComm = ''
                this.countryComm = ''
                this.stateComm = ''
                this.districtComm = ''
                this.cityComm = ''
                this.otherComm = ''
                this.locationComm = ''
                this.areaComm = ''
            }
        },

        getProviderDetails(userId, intabis){
           console.log("intabais ", intabis);
            for(let _data of this.searchDataArray){
                this.prov_id == _data._id ? this._providerData = _data : ''
                // this.providerDetails == _data.entityName || this.providerDetails == _data.firstName ? this._providerData = _data : ''
            }

            // let user_id = this._providerData == undefined || this._providerData == 'undefined' ? userId : this._providerData._id
            // console.log("((((((((((((( userId___AFTERRR +++++++++++++>>>>>", user_id)
            let user_id = userId !== undefined || userId !== 'undefined' ? userId : this._providerData._id
            this.GET(2,'empanelment/empanelment-provider-by-id/' + user_id,(res,err) => {
                if(!err){
                    let _resp = res.data.data.provider
                    let _empanel = res.data.data.empanelment
                    // console.log("_providerData +++++++++++++>>>>>", this._providerData)

                    this.$store.commit('EMPANEL_PROVIDER_DATA',res.data.data)

                    if(res.data.data.provider.registeredEntity === "Individual"){
                        this.nextTabsName = 'ProviderEmpanelmentContactFacility';
                    }else {
                        this.nextTabsName = 'ProviderEmpanelmentContact';
                    }

                    if(intabis == 'rc'){

                        this.selectedCompFromPage('ProviderReview');
                    }
                    
                     if(intabis == 'ac'){
                        this.selectedCompFromPage('ApprovalContractDetails');
                    }

                   let dataEmp = res.data.data.empanelment
                    if( dataEmp === undefined || dataEmp === null || dataEmp === '' || Object.keys(dataEmp).length === 0){ 
                        let dataForEmp = {
                            mainScreenStatus: false,
                            ownershipAndContactStatus: false,
                            contactAndFacilityStatus: false,
                            specialityStatus: false,
                            qualityAndTeachingStatus: false,
                            labAndRadiologyStatus: false,
                            staffStatus: false,
                            facilitiesStatus: false,
                            paymentStatus: false,
                            providerReviewStatus: false,
                            approvalAndContractStatus: false,
                        }      
                        this.$store.commit("EMPANEL_TAB_STATUS", dataForEmp);
                        this.$emit("fordisable");
                    }else{
                        this.$store.commit("EMPANEL_TAB_STATUS", res.data.data.empanelment);
                        this.$emit("forenable");
                    }

                    try{
                        console.log("inside empanelment store we have is -> ", this.$store.state.empanelProviderData)
                        this.depends = this.$store.state.empanelProviderData.hasOwnProperty('empanelment') ? this.$store.state.empanelProviderData.empanelment == null ? 'new' : 'edit' : 'new'
                    }catch(error){
                        console.log("An error has occurred, during setting default type of document upload for empanelment", error);
                    }
                    console.log("depends ____ +++++++++++++>>>>>", this.depends);
                    let _data = _empanel !== null ? _empanel : _resp
                    this.setimage(_empanel)
                    this.getdocs(_empanel == null ? "" : _empanel)
                    this.provPrnNo = _data.hasOwnProperty('prn') == true ? this.chkplndata(_data.prn) : ''
                    this.RegistrationDate = _data.hasOwnProperty('registrationDate') == true ? this.chkplndata(_data.registrationDate) : ''
                    this.providerType = _data.hasOwnProperty('providerType') == true ? this.chkplndata(_data.providerType) : ''
                    this.provStatus = _data.hasOwnProperty('status') == true ? this.chkplndata(_data.status) : ''
                    this.provName = _data.hasOwnProperty('entityName') == true ? this.chkplndata(_data.entityName ) : _data.hasOwnProperty('firstName') == true ? this.chkplndata(_data.firstName) : ''
                    this.aliasName = _data.hasOwnProperty('aliasName') == true ? this.chkplndata(_data.aliasName) : ''
                    this.RegistrationValidupto = _data.hasOwnProperty('registrationValidUpto') == true ? this.chkplndata(_data.registrationValidUpto) : ''
                    this.regNumber = _data.hasOwnProperty('registrationNumber') == true ? this.chkplndata(_data.registrationNumber) : ''
                    this.regBody = _data.hasOwnProperty('registeredBody') == true ? this.chkplndata(_data.registeredBody) : ''
                    this.rohiniId = _data.hasOwnProperty('rohiniId') == true ? this.chkplndata(_data.rohiniId.toString()) : ''
                    this.regEntity = _data.hasOwnProperty('registeredEntity') == true ? this.chkplndata(_data.registeredEntity) : ''
                    this.prov_id = _data.hasOwnProperty('empanelmentProvider') == true ? this.chkplndata(_data.empanelmentProvider) : ''
                    // this.regEntity = 'Individual'
                    console.log("%c this is for regEntity in mainScreen","color:brown",this.regEntity,"\n",_data);
                   if(this.regEntity === 'Individual'){
                       this.indiProviType = _data.hasOwnProperty('providerType') == true ? this.chkplndata(_data.providerType) : ''
                       this.indiProvTypeOther = _data.hasOwnProperty('providerTypeOther') == true ? this.chkplndata(_data.providerTypeOther) : ''
                       this.frstnmeInd = _data.hasOwnProperty('firstName') == true ? this.chkplndata(_data.firstName) : ''
                       this.mdlnmeInd = _data.hasOwnProperty('middleName') == true ? this.chkplndata(_data.middleName) : ''
                       this.lstnmeInd = _data.hasOwnProperty('lastName') == true ? this.chkplndata(_data.lastName) : ''
                       this.salutationind = _data.hasOwnProperty('salutation') == true ? this.chkplndata(_data.salutation) : ''
                       this.gndrInd = _data.hasOwnProperty('gender') == true ? this.chkplndata(_data.gender) : ''
                       console.log("this is for externalReferenceNo. ---->====>",_data.externalReferenceNo);
                       this.IndExtrnlRefNo = _data.hasOwnProperty('externalReferenceNo') == true ? this.chkplndata(_data.externalReferenceNo) : ''
                       this.indiQualification = _data.hasOwnProperty('qualification') == true ? this.chkplndata(_data.qualification) : ''
                       this.indiOtherQualifi = _data.hasOwnProperty('qulificationOther') == true ? this.chkplndata(_data.qulificationOther) : ''
                       this.indiRegNo = _data.hasOwnProperty('registrationNumber') == true ? this.chkplndata(_data.registrationNumber) : ''
                       this.indiRegCouncil = _data.hasOwnProperty('registeredCouncil') == true ? this.chkplndata(_data.registeredCouncil) : ''
                       this.indiOtherRegCouncil = _data.hasOwnProperty('registeredCouncilOther') == true ? this.chkplndata(_data.registeredCouncilOther) : ''
                       this.indiRegValidUpto = _data.hasOwnProperty('registrationValidUpto') == true ? this.chkplndata(_data.registrationValidUpto) : ''
                       this.indiRegisterDate = _resp.hasOwnProperty('createdAt') == true ? this.chkplndata(_resp.createdAt) : ''
                       console.log("this is registration date", this.indiRegisterDate,"\n","data =>", _data,"\n","resp=>",_resp);
                    //    this.indiTelefoneNum = _data.hasOwnProperty('mobileNumber') == true ? this.chkplndata(_data.mobileNumber) : ''
                    //    this.indiFaxNum = _data.hasOwnProperty('indiFaxNum') == true ? this.chkplndata(_data.indiFaxNum) : ''
                    //    this.indiChildProv = _data.hasOwnProperty('childProvider') == true ? this.chkplndata(_data.childProvider) : ''
                    //    this.indiParentProv = _data.hasOwnProperty('indiParentProv') == true ? this.chkplndata(_data.indiParentProv) : ''
                    //    this.indiMapType = _data.hasOwnProperty('registeredEntity') == true ? this.chkplndata(_data.registeredEntity) : ''
                   }

                    this.typeHere = _data.hasOwnProperty('remarks') == true ? this.chkplndata(_data.remarks) : ''
                    this.commAddrCheck = _data.hasOwnProperty('isSameAsHealthServiceAddress') == true ? this.chkplndata(_data.isSameAsHealthServiceAddress) : false
                    
                    if(_data.hasOwnProperty('healthServiceAddress') == true){
                        this.firstAddressHealth = this.chkplndata(_data.healthServiceAddress.addressLine1)
                        this.secondAddressHealth = this.chkplndata(_data.healthServiceAddress.addressLine2)
                        this.pincodeHealth = this.chkplndata(_data.healthServiceAddress.pinPostalCode)
                        // this.districtHealth = this.chkplndata(_data.healthServiceAddress.district)
                        // this.stateHealth = this.chkplndata(_data.healthServiceAddress.state)
                        // this.countryHealth = this.chkplndata(_data.healthServiceAddress.country)
                        // this.cityHealth = this.chkplndata(_data.healthServiceAddress.cityTownVillage)
                        // console.log("healthServiceAddress ____cityTownVillage ____ +++++++++++++>>>>>",_data.healthServiceAddress.cityTownVillage )
                        this.locationComm = this.chkplndata(_data.healthServiceAddress.locationType)
                        this.otherHealth = this.chkplndata(_data.healthServiceAddress.cityOthers)
                        this.locationHealth = this.chkplndata(_data.healthServiceAddress.locationType)
                        this.area = this.chkplndata(_data.healthServiceAddress.area)
                        this.callapi(this.pincodeHealth,1)
                    }else{
                        this.firstAddressHealth = _data.hasOwnProperty('addressLine1') == true ?  this.chkplndata(_data.addressLine1) : ''
                        this.secondAddressHealth = _data.hasOwnProperty('addressLine2') == true ? this.chkplndata(_data.addressLine2) : ''
                        this.pincodeHealth = _data.hasOwnProperty('pinPostalCode') == true ? this.chkplndata(_data.pinPostalCode) : ''
                        // this.countryHealth = _data.hasOwnProperty('country') == true ? this.chkplndata(_data.country) : ''
                        // this.stateHealth = _data.hasOwnProperty('state') == true ? this.chkplndata(_data.state) : ''
                        // this.districtHealth = _data.hasOwnProperty('district') == true ? this.chkplndata(_data.district) : ''
                        this.cityHealth = _data.hasOwnProperty('cityTownVillage') == true ? this.chkplndata(_data.cityTownVillage) : ''
                        this.locationHealth = _empanel !== null  ? res.data.data.empanelment.hasOwnProperty('locationType') == true ? this.chkplndata(res.data.data.empanelment.locationType) : '' : ''
                        this.callapi(this.pincodeHealth,1)
                        // this.area = _empanel !== null  ? res.data.data.empanelment.hasOwnProperty('area') == true ? this.chkplndata(res.data.data.empanelment.area) : '' : ''
                    }
                    //  console.log("pincodeHealth ____ +++++++++++++>>>>>",this.pincodeHealth )
                    
                    this.commAddrCheck == true ? this.enableCommAddrFields = true : this.enableCommAddrFields = false
                    if(_data.hasOwnProperty('communicationAddress') == true){
                        this.firstAddressComm = this.chkplndata(_data.communicationAddress.addressLine1)
                        this.secondAddressComm = this.chkplndata(_data.communicationAddress.addressLine2)
                        this.pincodeComm = this.chkplndata(_data.communicationAddress.pinPostalCode)
                        // this.districtComm = this.chkplndata(_data.communicationAddress.district)
                        // this.stateComm = this.chkplndata(_data.communicationAddress.state)
                        // this.countryComm = this.chkplndata(_data.communicationAddress.country)
                        // this.cityComm = this.chkplndata(_data.communicationAddress.cityTownVillage)
                        // console.log("communicationAddress____cityTownVillage ____ +++++++++++++>>>>>",_data.communicationAddress.cityTownVillage )
                        this.locationComm = this.chkplndata(_data.communicationAddress.locationType)
                        this.otherComm = this.chkplndata(_data.communicationAddress.cityOthers)
                        this.areaComm = this.chkplndata(_data.communicationAddress.area)
                        this.callapiComm(this.pincodeComm,1)
                    }else{
                        this.firstAddressComm = ''
                        this.secondAddressComm = ''
                        this.pincodeComm = ''
                        this.districtComm = ''
                        this.stateComm = ''
                        this.countryComm = ''
                        this.cityComm = ''
                        this.locationComm = ''
                        this.otherComm = ''
                        this.areaComm = ''
                    }
                    if(_data.hasOwnProperty('latitude') == true){
                        this.degreeLat = this.checkZeroValid(_data.latitude.degree)
                        this.dirLat = this.checkZeroValid(_data.latitude.direction)
                        this.minLat = this.checkZeroValid(_data.latitude.minutes)
                        this.secLat = this.checkZeroValid(_data.latitude.seconds)
                    }
                    if(_data.hasOwnProperty('longitude') == true){
                        this.degLong = this.checkZeroValid(_data.longitude.degree)
                        this.dirLong = this.checkZeroValid(_data.longitude.direction)
                        this.minLong = this.checkZeroValid(_data.longitude.minutes)
                        this.secLong = this.checkZeroValid(_data.longitude.seconds)
                    }
                    
                    this.searchEntries.push(this.provName)
                    // console.log("**********((((((((  provName +++++++++++++>>>>>", this.provName)
                    this.providerDetails = this.provName
                    let empanelData = this.$store.state.empanelProviderData.empanelment
                    this.empanelStoreData = empanelData !== null ? empanelData : ''
                    
                }else{
                    // this.showToast(res.data.msg ,this.TOST().ERROR);
                }   
            });
            
        },

        setimage(_empanel){
            try {
                    if(_empanel != null || _empanel != undefined){
                        console.log("_empanel has data now", _empanel);
                        this.b64Image =  _empanel.hasOwnProperty('photoUrl') ? this.chkplndata(_empanel.photoUrl) : ''
                    }
                    this.$forceUpdate();
            } catch (error) {
                    console.log("setimage catch has an error", error)
            }
       
        },
        
        chkplndata(data){
            try{
                if (data ==="" || data === "undefined" || data === null) {
                    return ""
                } else {
                    return data;
                }

             }catch(err) {
                // console.log(err)
            }
            
        },
        checkZeroValid(data){
            try{
                if (data == "" || data == "undefined" || data == null || data == 0) {
                    return ""
                } else {
                    return data;
                }
             }catch(err) {}
        },

        getCountries(){
              this.GET(3,'country/get-country-all', res=>{
                console.log(res.status,'res of COUNTRIES is ', res);
                if(res.status == 200){
                    this.countryItems = [];
                    this.countryItems = res.data.data.data.map(e=>{
                        return { country:e.country, all:e }
                    })
                }
            })
        },

        nextBtn(){
            console.log("%c this is data for next btn on mainScreen","color:green",this.$store.state.empanelProviderData.empanelment);
            if(this.$store.state.empanelProviderData.empanelment == null) {
                this.confirmDialogNext = true
            }else{
                this.selectedCompFromPage(this.nextTabsName)
            }
        },

        cancelData(){
            this.confirmDialogCancel = true
        },
        close_cancelDialog(){
            this.confirmDialogCancel = false
            this.confirmDialogNext = false
        },

         // this is for what we will do on "cancel" button when "OK" is clicked 

        saveData(e){

          
            var picis = this.mediaFile;
              console.log("this.regEntity is ", this.regEntity);
           
            if(this.regEntity == 'Institution'){
                if(this.providerDetails === "" || this.regEntity === "" || this.providerType === "" || this.provName === "" || this.firstAddressHealth === ""
                    || this.pincodeHealth === "" || this.stateHealth === "" || this.districtHealth === "" || this.cityHealth === "" || this.locationHealth === "" 
                    || this.firstAddressComm === "" || this.pincodeComm === "" || this.stateComm === "" || this.districtComm === "" || this.cityComm === ""
                    || this.locationComm === "" || this.rohiniId === "" ){
                    this.showToast('Please fill all empty fields', this.TOST().WARNING);
                    this.$refs.form.validate();
                    return
                } 
                if(this.rohiniId.length !== 13){
                    // this.fieldValidation(this.rohiniId,'rohiniID')
                    this.rohiniIdValid.push('Invalid ROHINI ID')
                    this.showToast('Please fill all empty fields', this.TOST().WARNING);
                    this.$refs.form.validate();
                    return

                }
                if(this.latDegreeValid.length > 0 || this.latMinValid.length > 0  || this.latSecValid.length > 0
                    ||this.longiDegreeValid.length > 0 || this.longiMinValid.length > 0  || this.longiSecValid.length > 0 || this.rohiniIdValid.length > 0 ){
					this.showToast('Please fill Correct data', this.TOST().WARNING);
				    return;
                }
            }
            //  || this.secondAddressComm === ""
            if(this.regEntity == 'Individual'){
                if(this.providerDetails === "" || this.regEntity === "" || this.salutationind === "" || this.frstnmeInd === "" || this.lstnmeInd === "" || this.firstAddressHealth === ""
                    || this.pincodeHealth === "" || this.stateHealth === "" || this.districtHealth === "" || this.cityHealth === ""
                    || this.locationHealth === "" || this.firstAddressComm === "" || this.pincodeComm === "" || this.stateComm === "" 
                    || this.districtComm === "" || this.cityComm === ""|| this.locationComm === ""  ){

                    this.showToast('Please fill all empty fields', this.TOST().WARNING);
                    this.$refs.form.validate();
                    return
                } 
            }

            if(this.empanelType == 'empanelment' && this.provStatus == 'Empaneled'){
                this.showToast('New Empanelment cannot be initiated for Empaneled Provider', this.TOST().ERROR);
                return
            }
            if(this.empanelType == 'renewal' && this.provStatus == 'Registered'){
                this.showToast('Empanelment renewal cannot be initiated for Non-Empaneled Provider', this.TOST().ERROR);
                return
            }

            try {
                 var send_docs = this.create_doc_arr.map(e=>{
                        return {
                            bucket: e.bucket,
                            docType: e.docType,
                            fileKey: e.fileKey,
                            fileName: e.fileName,
                            validFrom: e.validFrom,
                            validTo: e.validTo,
                        }
                    })
            } catch(error){
                console.log("upload docs -> ", error);
            }
            
            if(this.regEntity === "Institution"){
                console.log("%c sending Institution from mainScreen","color:green");

                    var formDataUp = new FormData();
                    let _store = this.empanelStoreData !== null ? this.empanelStoreData : ''

                   
                    
                    let formData = {
                        empanelmentType : this.empanelType,
                        photo:this.mediaFile,
                        // _id : this.empanelType == 'renewal' ?  _store._id : '',
                        providerType : this.providerType,
                        empanelmentProvider : this.$store.state.empanelProviderData.provider._id,
                        registeredEntity : this.regEntity,
                        registrationDate : this.RegistrationDate,
                        prn : this.provPrnNo,
                        status : this.provStatus,
                        entityName : this.provName,
                        aliasName : this.aliasName,
                        healthServiceAddress:{ 
                            addressLine1 : this.firstAddressHealth,
                            addressLine2 : this.secondAddressHealth,
                            pinPostalCode : parseInt(this.pincodeHealth.pin),
                            district : this.districtHealth,
                            state : this.stateHealth,
                            country : this.countryHealth,
                            cityTownVillage : this.cityHealth,
                            locationType : this.locationHealth,
                            cityOthers:this.otherHealth
                        },
                        communicationAddress:{ 
                            addressLine1 : this.firstAddressComm,
                            addressLine2 : this.secondAddressComm,
                            pinPostalCode : parseInt(this.pincodeComm.pin),
                            district : this.districtComm,
                            state : this.stateComm,
                            country : this.countryComm,
                            cityTownVillage : this.cityComm,
                            locationType : this.locationComm,
                            cityOthers:this.otherComm
                        },
                        isSameAsHealthServiceAddress: this.commAddrCheck,
                        registrationNumber : this.regNumber,
                        registeredBody : this.regBody,
                        registrationValidUpto : this.RegistrationValidupto,
                        rohiniId : this.rohiniId,
                        remarks : this.typeHere,
                        latitude:{
                            degree:this.degreeLat == '' ? 0 : parseFloat(this.degreeLat),
                            direction:this.dirLat,
                            minutes:this.minLat == '' ? 0 : parseFloat(this.minLat),
                            seconds:this.secLat == '' ? 0 : parseFloat(this.secLat),
                        },
                        longitude:{
                            degree:this.degLong == '' ? 0 : parseFloat(this.degLong),
                            direction:this.dirLong,
                            minutes:this.minLong == '' ? 0 : parseFloat(this.minLong),
                            seconds:this.secLong == '' ? 0 : parseFloat(this.secLong),
                        },
                    }

                    _store.hasOwnProperty('_id') ? formData['_id'] = _store._id : ''
                    // this.empanelType == 'renewal' ? formData['_id'] = _store._id : ''

                    for ( var key in formData ) {
                        formDataUp.append(key, key == 'latitude' || key == 'longitude' || key == 'healthServiceAddress' || key == 'communicationAddress' ? JSON.stringify(formData[key]) : formData[key] );
                    }
                    console.log("***>> formDataUp --> ", formData);

                    //   uploadDocs:send_docs,
                     send_docs.length == 0 ? "" : formDataUp.append("uploadDocs", JSON.stringify(send_docs))
                    // return
                    this.POST(2,'empanelment/create-main',formDataUp,(res,error)=>{
                        if(!error){
                            if(res.data.statusCode === 200){
                                this.showToast(res.data.msg, this.TOST().SUCCESS);
                                this.$emit("forenable");
                                this.getProviderDetails(this.$store.state.empanelProviderData.provider._id);

                            }else{
                                // console.log(this.$store.state.empanelProviderData.provider._id);
                                // console.log(error);
                                this.showToast(res.data.msg ,this.TOST().ERROR); 
                            }
                        }else{
                            // console.log(this.$store.state.empanelProviderData.provider._id);
                            this.showToast(error.response.data.msg, this.TOST().WARNING);
                        }
                    })

            }else{
                    let _store = this.empanelStoreData !== null ? this.empanelStoreData : ''

                console.log("%c sending Individual from mainScreen","color:blue", _store    );

                  console.log(" photo: picis ", picis);

                      var fdindvidual = new FormData();

                    var formDataForIndi = {
                        empanelmentType : this.empanelType,
                        photo:picis,
                        // _id :  _store.hasOwnProperty('_id') ?  _store._id : '',
                        ...(_store.hasOwnProperty('_id') && {_id :  _store.hasOwnProperty('_id') ?  _store._id : '',}),
                        providerType : this.indiProviType,
                        providerTypeOther: this.indiProvTypeOther,
                        empanelmentProvider: this.$store.state.empanelProviderData.provider._id,
                        registeredEntity: this.regEntity,  
                        prn: this.provPrnNo,               
                        status: this.provStatus,              
                        salutation: this.salutationind,          
                        firstName: this.frstnmeInd,           
                        lastName: this.lstnmeInd,        
                        gender: this.gndrInd,
                        externalReferenceNo:this.IndExtrnlRefNo,
                        qualification: this.indiQualification, 
                        qualificationOther: this.indiOtherQualifi,      
                        registrationNumber: this.indiRegNo,   
                        registeredCouncil: this.indiRegCouncil,     
                        registeredCouncilOther: this.indiOtherRegCouncil,
                        registrationValidUpto: this.indiRegValidUpto,
                        
                        isSameAsHealthServiceAddress: this.commAddrCheck,              
                        healthServiceAddress:{ 
                            addressLine1 : this.firstAddressHealth,
                            addressLine2 : this.secondAddressHealth,
                            pinPostalCode : parseInt(this.pincodeHealth.pin),
                            district : this.districtHealth,
                            state : this.stateHealth,
                            country : this.countryHealth,
                            cityTownVillage : this.cityHealth,
                            locationType : this.locationHealth,
                            area : this.area,
                            cityOthers:this.otherHealth
                        },
                        communicationAddress:{ 
                            addressLine1 : this.firstAddressComm,
                            addressLine2 : this.secondAddressComm,
                            pinPostalCode : parseInt(this.pincodeComm.pin),
                            district : this.districtComm,
                            state : this.stateComm,
                            country : this.countryComm,
                            cityTownVillage : this.cityComm,
                             area : this.areaComm,
                            locationType : this.locationComm,
                            cityOthers:this.otherComm
                        },

                        
                }

                    for ( var key in formDataForIndi) {
                        fdindvidual.append(key,  key == 'healthServiceAddress' || key == 'communicationAddress' ? JSON.stringify(formDataForIndi[key]) : formDataForIndi[key]);
                    }
                    console.log("***>> fdindvidual --> ", fdindvidual);

                    //   uploadDocs:send_docs,
                     send_docs.length == 0 ? "" : fdindvidual.append("uploadDocs", JSON.stringify(send_docs))

                        console.log("fd",fdindvidual );

            // alert("now chexk console")
            // console.log("(((((empanelProviderData ____ +++++++++++++>>>>>", this.$store.state.empanelProviderData);
            // return
            this.POST(2,'empanelment/create-main',fdindvidual,(res,error)=>{
                if(!error){
                    if(res.data.statusCode === 200){
                        this.showToast(res.data.msg, this.TOST().SUCCESS);
                        this.$emit("forenable");
                        this.getProviderDetails(this.$store.state.empanelProviderData.provider._id);

                    }else{
                        // console.log(this.$store.state.empanelProviderData.provider._id);
                        // console.log(error);
                        this.showToast(res.data.msg ,this.TOST().ERROR); 
                    }
                }else{
                    // console.log(this.$store.state.empanelProviderData.provider._id);
                    this.showToast(error.response.data.msg, this.TOST().WARNING);
                }
            })

            }
            //  e.preventDefault();
        },

        clearData(){
            this.firstAddressHealth = ''
            this.secondAddressHealth = ''
            this.pincodeHealth = ''
            this.countryHealth = ''
            this.stateHealth = ''
            this.districtHealth = ''
            this.cityHealth = ''
            this.otherHealth = ''
            this.locationHealth = ''
            this.providerDetails = ''
            this.RegistrationDate = ''
            this.regEntity = ''
            this.provPrnNo = ''
            this.providerType = ''
            this.provStatus = ''
            this.provName = ''
            this.aliasName = ''
            this.RegistrationValidupto = ''
            this.regNumber = ''
            this.regBody = ''
            this.rohiniId = ''
            this.firstAddressComm = ''
            this.secondAddressComm = ''
            this.pincodeComm = ''
            this.countryComm = ''
            this.stateComm = ''
            this.districtComm = ''
            this.cityComm = ''
            this.otherComm = ''
            this.locationComm = ''
            this.areaComm = ''
            this.degreeLat = ''
            this.dirLat = ''
            this.minLat = ''
            this.secLat = ''
            this.degLong = ''
            this.dirLong = ''
            this.minLong = ''
            this.secLong = ''
            this.typeHere = ''
            

        },

        uploadBtn(){
            if(Object.keys(this.$store.state.empanelProviderData).length == 0){
                this.showToast('Please, select a provider to Upload documents ', this.TOST().WARNING);
            }else{
                this.docen = true
            }
        },

        showNewEmp() {
            this.showNewEmp = true;
            this.showEmpRenewal = false;
        },

        showInstitutionData() {
            this.showNewEmp = false;
            this.showEmpRenewal = true;           
        },

        getPincode(indata, from){
            // console.log("getPincode+++++++++++++>>>>>", indata)
            this.GET(3,'district/get-all?state='+indata.all.state , res=>{
                // console.log("something went wrong, pincode", res)
                // console.log("RESPP__NORMM+++++++++++++>>>>>", res)
                if(res.status == 200){
                    this.districtItems =  res.data.data.data;
                    this.districtItems.map((e,index)=>{
                        if(e.district ==indata.all.district){
                            this.districtHealth = this.districtItems[index].district
                            this.getstates(indata.all.country,this.districtItems[index].state);
                            this.countryHealth = indata.all.country;
                            }
                        })
                }else{
                    console.log("something went wrong, pincode", res)
                }
            })
        },

        callapi(indata, ident){
            // alert("call api called ")
            // console.log("pinPostalCode ", indata);
            this.GET(3,'city/search-by-pincode/'+indata, res=>{
                if(this.counter == 2){
                   this.pinItemArray = [];
                   this.counter = 0
                }
                let _empanel = this.$store.state.empanelProviderData.empanelment
                let _storeData = _empanel !== null ? _empanel.healthServiceAddress : this.$store.state.empanelProviderData.provider
                // let _storeData = this.$store.state.empanelProviderData.provider
                var pin_not_found = false
                if(res.data.data.length == 0){
                    // for making state city and district non-mandatory ass pin code response is zero
                    // this.s_c_d_nm = true
                    pin_not_found = true
                    this.counter = ''
                    this.stateHealth = ''
                    this.districtHealth = ''
                    this.cityHealth = ''
                    
                    this.showToast("Pin Code not found" ,this.TOST().ERROR);

                }else{
                    // this.s_c_d_nm = false
                }

                this.ctvItems = []
                
                var processed_pin =  res.data.data.map(e=>{
                    this.ctvItems.push({area:e.area,all:e})
                    return { pin:e.pincode.toString() ,all:e }
                })
                this.ctvItems.push({area:'Other'})
                this.pinItemArray.push(...processed_pin)

                if(pin_not_found) this.pinItemArray.push({pin:indata.toString(),all:''});

                if(ident == 1){
                    this.pinItemArray.map(el =>{
                        // el.pin == indata && el.all.district == _storeData.district ? this.pincodeHealth = el : ''
                        el.pin == indata && el.all.area == _storeData.cityTownVillage && el.all.district == _storeData.district ? this.pincodeHealth = el : ''
                        
                    })
                    this.getPincode(this.pincodeHealth, 'Frm CALLED API');
                }
                this.counter++
                // console.log("call api pinItemArray in new call api", this.pinItemArray);
            })

        },

        getstates(countryincoming,stateincoming){
            var self = this

            this.GET(3,'state/get-state-byCountry/'+countryincoming, res=>{
                self.stateItems = res.data.data
                // console.log( self.stateItems.length," RESPONSE of state api is  -> ",  self.stateItems);
                self.stateItems.map(e=>{
                    // console.log(stateincoming,"matching state in map",e.state)
                    if(e.state == stateincoming){
                        self.stateHealth = stateincoming;
                        self.get_areas(self.districtHealth,0);
                    }
                })
            })
        },

        get_areas(indata_district, ident){
            var pageno =  ident == 1 ? this.search_area_pageno : this.area_page_no;

            this.GET(3,'city/areas?state='+this.stateHealth+'&district='+indata_district+'&perPage=1000&pageNo='+pageno ,(res,error)=>{
                try {
                    if(res.status == 200){
                        // console.log("RESPPPP____CITYYYYYY",res)
                        if(res.data.data.data.length == 0) return

                        // this.ctvItems = []
                        // this.ctvItems = res.data.data.data.map(e=>{
                        //     return {area:e.area,all:e}
                        // })
                        // this.ctvItems.push({area:'Other'})

                        if(ident == 1) return
                       
                        // console.log(" ctvItemss ___HEHREHHEHE", this.ctvItems.length);
                        var ctvitemlen =  this.ctvItems.length
                        var my_counter  = 0
                        var findareapromise = new Promise((resolve, reject)=>{
                            for(let i=0; i<this.ctvItems.length; i++){
                                my_counter++
                                if(this.pincodeHealth.all.area == this.ctvItems[i].area){
                                    this.cityHealth = this.pincodeHealth.all.area
                                    resolve("value found, dont call API")
                                    return
                                }
                                if(ctvitemlen == my_counter) reject("value not found, call API")
                            }
                        })

                        findareapromise.then(responseis=>{
                            // console.log( this.cityHealth,"do not call api area value found", this.cityHealth == this.pincodeHealth.all.area)
                            this.ctvItems.push({area:'Other'})
                            }).catch(eis=>{
                                // console.log(eis,"area value not found call next records, increment page", this.cityHealth == this.pincodeHealth.all.area)
                                // call same api function here with page number increment and also pass district to it
                                this.cityHealth = '';
                                this.area_page_no++;
                                // alert("from CATCH,value not found for AREA");
                                this.get_areas(this.districtHealth,0)
                            })

                    }else{
                        // console.log(" something went wrong, areas ", res);
                    }
                } catch (error) {
                        console.log("from catch -> ",error); 
                        return
                }
                    
            })
        },

        cityselected(indata){
            if(indata == 'Other'){
                this.enableOthrHlth = true
            }else{
                this.enableOthrHlth = false
            }
        },

        // COMMUNICATION DETAILS PINCODE FETCH CODE
        getPincodeComm(indata, from){
            // console.log("getPincode+++++++++++++>>>>>", indata)
            // this.ctvItemsComm = []
            this.GET(3,'district/get-all?state='+indata.all.state , res=>{
                // console.log("something went wrong, pincode", res)
                if(res.status == 200){
                    this.districtItemsComm =  res.data.data.data;
                    this.districtItemsComm.map((e,index)=>{
                        if(e.district ==indata.all.district){
                            this.districtComm = this.districtItemsComm[index].district
                            this.getstatesComm(indata.all.country,this.districtItemsComm[index].state);
                            this.countryComm = indata.all.country;
                            }
                        })
                }else{
                    console.log("something went wrong, pincode", res)
                }
            })
        },

        callapiComm(indata, ident){
            this.GET(3,'city/search-by-pincode/'+indata, res=>{
                if(this.counterComm == 2){
                   this.pinItemArrayComm = [];
                   this.counterComm = 0
                }
                let _empanel = this.$store.state.empanelProviderData.empanelment
                let _storeData = _empanel !== null ? _empanel.communicationAddress : this.$store.state.empanelProviderData.provider
                var pin_not_found = false
                if(res.data.data.length == 0){
                    // for making state city and district non-mandatory ass pin code response is zero
                    // this.s_c_d_nm = true
                    pin_not_found = true
                    this.counterComm = ''
                    this.stateComm = ''
                    this.districtComm = ''
                    this.cityComm = ''
                    
                    this.showToast("Pin Code not found" ,this.TOST().ERROR);

                }else{
                    // this.s_c_d_nm = false
                }
                this.ctvItemsComm = []
                var processed_pin =  res.data.data.map(e=>{
                    this.ctvItemsComm.push({area:e.area,all:e})
                    return { pin:e.pincode.toString() ,all:e }
                })
                this.ctvItemsComm.push({area:'Other'})
                this.pinItemArrayComm.push(...processed_pin)


                if(pin_not_found) this.pinItemArrayComm.push({pin:indata.toString(),all:''});

                if(ident == 1){
                    this.pinItemArrayComm.map(el =>{
                        el.pin == indata && el.all.area == _storeData.cityTownVillage && el.all.district == _storeData.district ? this.pincodeComm = el : ''
                        
                    })
                    this.getPincodeComm(this.pincodeComm, 'Frm CALLED API');
                }
                // ident == 2 ? this.getPincodeComm(this.pincodeComm, 'Frm CALLED API') : ''
                // console.log("**********((((((((  pincodeComm 111 +++++++++++++>>>>>", this.pincodeComm)
                this.counterComm++
            })

        },

        getstatesComm(countryincoming,stateincoming){
            var self = this

            this.GET(3,'state/get-state-byCountry/'+countryincoming, res=>{
                    self.stateItemsComm = res.data.data
                    // console.log( self.stateItemsComm.length," RESPONSE of state api is  -> ",  self.stateItemsComm);
                    self.stateItemsComm.map(e=>{
                        // console.log(stateincoming,"matching state in map",e.state)
                        if(e.state == stateincoming){
                            self.stateComm = stateincoming;
                            self.get_areasComm(self.districtComm,0);
                        }
                    })
            })
        },

        get_areasComm(indata_district, ident){
            var pageno =  ident == 1 ? this.search_area_pagenoComm : this.area_page_noComm;

            this.GET(3,'city/areas?state='+this.stateComm+'&district='+indata_district+'&perPage=1000&pageNo='+pageno ,(res,error)=>{
                try {
                    if(res.status == 200){
                        // console.log("RESPPPP____CITYYYYYY",res)
                        if(res.data.data.data.length == 0) return

                        // this.ctvItemsComm = []
                        // this.ctvItemsComm = res.data.data.data.map(e=>{
                        //     return {area:e.area,all:e}
                        // })
                        // this.ctvItemsComm.push({area:'Other'})

                        if(ident == 1) return
                        // console.log(" ctvItemsComms ", this.ctvItemsComm.length);
                        var ctvitemlen =  this.ctvItemsComm.length
                        var my_counter  = 0
                        var findareapromise = new Promise((resolve, reject)=>{
                            for(let i=0; i<this.ctvItemsComm.length; i++){
                                my_counter++
                                if(this.pincodeComm.all.area == this.ctvItemsComm[i].area){
                                    // console.log(my_counter,"no need to recall API with Page update",this.ctvItemsComm[i].area)
                                    this.cityComm = this.pincodeComm.all.area
                                    resolve("value found, dont call API")
                                    return
                                }
                                
                                if(ctvitemlen == my_counter) reject("value not found, call API")
                            }
                        })

                        findareapromise.then(responseis=>{
                            // console.log( this.cityComm,"do not call api area value found", this.cityComm == this.pincodeComm.all.area)
                            this.ctvItemsComm.push({area:'Other'})
                        }).catch(eis=>{
                            // console.log(eis,"area value not found call next records, increment page", this.cityComm == this.pincodeComm.all.area)
                            // call same api function here with page number increment and also pass district to it
                            this.cityComm = '';
                            this.area_page_noComm++;
                            // alert("from CATCH,value not found for AREA");
                            this.get_areasComm(this.districtComm,0)
                        })

                    }else{
                        // console.log(" something went wrong, areas ", res);
                    }
                } catch (error) {
                        console.log("from catch -> ",error); 
                        return
                }
                    
            })
        },
        
        cityselectedComm(indata){
            // console.log("cityselectedComm___________", indata);
            indata == 'Other' ? this.enableOthrHlthComm = false : this.enableOthrHlthComm = true
        },
        
    },

    watch: {

        indiProviType(value){
                if(value === 'Other'){
                    this.ptype_other = false;
                }else{
                    this.ptype_other = true;
                    this.indiProvTypeOther = ''
                }
        },
        
        indiQualification(value){
                if(value === 'Other'){
                    this.qualtype_other_dis = false;
                }else{
                    this.qualtype_other_dis = true;
                    this.indiOtherQualifi = ''
                }
        },

        indiRegCouncil(value){
            if(value === 'Other'){
                this.regCouncil_other_dis = false;
            }else{
                this.regCouncil_other_dis = true;
                this.indiOtherRegCouncil = ''
            }
        },

        searchProviderName(val) {

            if (this.searchedItems.length > 0) return
            // Items have already been requested
            if (this.isLoading) return

            this.isLoading = true
            let provider = this.providerDetails;
            this.GET(2,'provider/search-providers-by-name/'+val,( res,err) => {
                if(!err){
                    this.searchDataArray = res.data.data.data
                    console.log(res.data.data.data);
                    const providers = []
                    for(let provider of res.data.data.data){
                        // console.log(provider.empanelmentProvider);
                        if(provider.registeredEntity === "Institution"){
                            let _data = {
                                name:provider.entityName,
                                _id:provider._id,
                            }
                            // providers.push(provider.entityName)
                            providers.push(_data)
                        }else if(provider.registeredEntity === "Individual"){
                            let _data = {
                                name:provider.firstName +' '+ provider.lastName,
                                _id:provider._id,
                            }
                            providers.push(_data)
                            // providers.push(provider.firstName)
                        }
                            
                    }
                    this.searchEntries = providers
                }else{
                    this.showToast(err.response.data.msg ,this.TOST().ERROR);
                }
            });
            this.isLoading = false

        },

        citysearch(val){
            // console.log("(((((((((((((citysearch=====)))))))))))))",val );
            if(val == this.cityHealth) return
            if(val == null) return

            if(val.length > 3){
                var vlen = val.length;
                // console.log('vlen is incoming string length to be compared inside the array of objects--->',vlen);
                var ctvitemlen =  this.ctvItems.length
                var my_counter  = 0
                for(let i=0; i < this.ctvItems.length;i++){
                    var strlen = this.ctvItems[i].area.slice(0,vlen)
                    my_counter++
                    if(strlen == val) return

                    if(my_counter == ctvitemlen){
                        // console.log(my_counter == ctvitemlen," < ??????????????????- searched entired array value not found, now calling API with ident 1 from watch")
                        this.search_area_pageno++
                        this.get_areas(this.districtHealth,1)
                    }
                }
            }else{
                console.log("citysearch not 3")
            }

        },

        searchPinCode (val){
            // console.log(this.pinItems.length," val is ", val);
            if(val == null){
                // console.log("returned as val foun to be null")
                return
            }

            if(val.length >3){
                var vlen = val.length
                if(this.pinItems.length == 0 && val.length >= 3){
                    this.callapi(val,0)
                    return
                }else{
                    // console.log('itens len')
                }

                for(let i=0; i<this.pinItems.length;i++){
                    var strlen = this.pinItems[i].pin.slice(0,vlen)
                        
                    if(vlen > strlen.length){
                        // console.log("data not available to search")
                        return
                    }
                    
                    if(strlen != val){
                        this.callapi(val,0)
                        return
                    }else{
                        // console.log(this.pinItems.length,"found true", this.pinItems[i].pin)
                        return
                    }
                }
            }else{
                console.log("not 3")
            }
        
        },

        citysearchComm(val){
            if(val == this.cityComm) return
            if(val == null) return
            if(val.length > 3){
                var vlen = val.length;
                // console.log('vlen is incoming string length to be compared inside the array of objects--->',vlen);
                var ctvitemlen =  this.ctvItemsComm.length
                var my_counter  = 0
                for(let i=0; i < this.ctvItemsComm.length;i++){
                    var strlen = this.ctvItemsComm[i].area.slice(0,vlen)
                    // console.log(val.length ,"<- searched length |&&| strlen length is",strlen.length)
                    // console.log(val ,"<- searched |&&| str is",strlen)
                    my_counter++
                    if(strlen == val) return

                    if(my_counter == ctvitemlen){
                        // console.log(my_counter == ctvitemlen," < ??????????????????- searched entired array value not found, now calling API with ident 1 from watch")
                        this.search_area_pagenoComm++
                        this.get_areasComm(this.districtComm,1)
                    }
                }
            }else{
                console.log("citysearch not 3")
            }

        },

        searchPinCodeComm (val){
            // console.log(this.pinItems.length," val is ", val);
            if(val == null){
                // console.log("returned as val foun to be null")
                return
            }

            if(val.length >3){
                var vlen = val.length
                if(this.pinItemsComm.length == 0 && val.length >= 3){
                    this.callapiComm(val,0)
                    return
                }else{
                    // console.log('itens len')
                }

                for(let i=0; i<this.pinItemsComm.length;i++){
                    var strlen = this.pinItemsComm[i].pin.slice(0,vlen)
                        
                    if(vlen > strlen.length){
                        // console.log("data not available to search")
                        return
                    }
                    
                    if(strlen != val){
                        this.callapiComm(val,0)
                        return
                    }else{
                        // console.log(this.pinItems.length,"found true", this.pinItems[i].pin)
                        return
                    }
                }
            }else{
                console.log("not 3")
            }
        
        },
    },
    
}

</script>
<style >


.profile_controls{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 6px 0px;
}

.emp-prof-img{
    display:flex;
    background-color:#f7f7f7;
    height:100px;
    width:100px;
    align-items:center;
    justify-content:center
}
.shadow{
    box-shadow:0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
}
.cust-rmvpd-psins .v-text-field.v-text-field--enclosed>.v-input__control>.v-input__slot {
    padding-right: 0px !important;
}

.cust_pr .v-text-field .v-input__slot,
.v-text-field--outline .v-input__slot {
    min-height: 42px !important;
    display: flex !important;
    align-items: center !important;
    /* border:1px red solid; */
}

.v-dialog > .v-card > .v-card__text {
    padding: 10px 24px 15px;
}

.deleIcon .v-input__control .v-input__slot .v-select__slot .v-input__append-inner .v-input__icon--append{
    display: none;
}

</style>