<template>
  <div>
    <v-row class="mt-5 ml-3">
      <v-col xs3>
        <v-btn
          color="#01BBD9"
          dark
          
          class="textTransformCapitalize aigmasterButton"
          @click="goToAIGMasters">
          <img
            src="../../assets/Master/AIGMasters.png"
            height="40"
            width="40"/>
          <div class="mastersDiv"></div>AIG Masters
        </v-btn>
      </v-col>

      <v-col xs3>
        <v-btn
          color="#01BBD9"
          dark
          class="textTransformCapitalize mastersButtonWidth"
          @click="goToCatastropheMasters">
          <img height="40" width="40" src="../../assets/Master/master.png" />
          <div class="mastersDiv"></div>Catastrophe Masters
        </v-btn>
      </v-col>

      <v-col xs3 >
        <v-btn
          color="#01BBD9"
          dark
          class="textTransformCapitalize mastersButtonWidth"
          @click="showMajorClientMaster">
          
          <v-icon class="blueiconstyle">mdi-account-circle</v-icon>
          <div class="mastersDiv"></div>
          Major Client Master
        </v-btn>
      </v-col>
      
      <v-col xs3>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";
export default {
  methods: {
    goToAIGMasters() {
      this.$router.push("/searchaigoffice");
    },

    goToCatastropheMasters() {
      this.$router.push("/masters/catastrophemasters");
    },

    showMajorClientMaster() {
       this.$router.push("/masters/clientMaster");
    },
  },
};
</script>
<style scoped>
  .blueiconstyle {
  	font-size: 33px !important;
  	color: #fff !important;
  	cursor: pointer !important;
  }
  .mastersDiv {
  	border-right: 1px solid #fff;
  	height: 40px !important;
  	opacity: 0.5 !important;
  	margin-right: 24px !important;
  	margin-left: 23px !important;
  }
  .aigmasterButton {
  	height: 77px !important;
  	width: 300px !important;
  	padding-right: 76px !important;
  }
  .mastersButtonWidth {
  	height: 77px !important;
  	width: 300px !important;
  }
</style>