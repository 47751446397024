<template>

<v-app>
     <v-form @submit.prevent="submitDetails">
        <div style="background-color: #FFFFFF;" column class="py-3">

            <v-layout class=" mt-2" >
                        <v-flex xs3>
                            <v-layout column class="mx-2 my-2">
                                <v-text class="text-uppercase font-weight-bold title-clr-pr">
                                    PROVIDER REVIEW
                                </v-text>
                                <v-divider></v-divider>
                            </v-layout>
                        </v-flex>


                        <v-flex xs5>
                            <v-layout column class="cust_pd mx-2 my-2 ">
                                <v-text class="font-weight-bold cstm-clr-pd">
                                    Reviewer Remarks
                                </v-text>
                            
                                <v-text-field
                                    solo
                                    label="Enter..."
                                    v-model="reviewerRemarks"
                                    class="rounded-0"
                                    maxlength="200"
                                    dense
                                    :error-messages="reviewerRemarksErrors"
                                    @input="$v.reviewerRemarks.$touch()"
                                    @blur="$v.reviewerRemarks.$touch()"
                                    >
                                </v-text-field>
                            </v-layout>
                        </v-flex>

                        
                        <v-flex xs3>
                            <v-layout column class="cust_pd mx-2 my-2 testingStatus">
                                <v-text class="font-weight-bold cstm-clr-pd">
                                    Provider Review Status *
                                </v-text>
                            
                                <v-select
                                    :items="reviewStatusItem"
                                    solo
                                    label="Enter..."
                                    v-model="reviewStatus"
                                    class="rounded-0 testStatusValue"
                                    data-test="select"
                                    maxlength="20"
                                    :error-messages="reviewStatusErrors"
                                    @input="$v.reviewStatus.$touch()"
                                    @blur="$v.reviewStatus.$touch()"
                                    >
                                </v-select>
                            </v-layout>
                        </v-flex>


                        <v-flex xs2></v-flex>
                    
                                                    
                    </v-layout>

                    <v-layout class=" mt-2" >
                        <v-flex xs3>
                        
                        </v-flex>


                        <v-flex xs5>
                            <v-layout column class="cust_pd mx-2 my-2 ">
                                <v-text class="font-weight-bold cstm-clr-pd">
                                    Reason
                                </v-text>
                            
                                <v-text-field
                                    solo
                                    label="Enter..."
                                    v-model="reason"
                                    class="rounded-0"
                                    maxlength="200"
                                    :error-messages="reasonErrors"
                                    @input="$v.reason.$touch()"
                                    @blur="$v.reason.$touch()"
                                    >
                                </v-text-field>
                            </v-layout>
                        </v-flex>

                        
                        <v-flex xs3></v-flex>


                        <v-flex xs2></v-flex>
                    
                                                    
                    </v-layout>

                <v-dialog v-model="confirmDialogCancel" persistent max-width="450px">
                 
                         <v-card tile color="#F7F7F7" >
                            <v-card tile>
                                <v-layout justify-space-between  class="" style="flex:1;background-ox-shadow: 0px 3px 5px #00000029;">
                             
                                    <v-card-title class="pa-0 pl-3 text-capitalize font-weight-regular" style="color:black"> 
                                       CONFIRMATION
                                    </v-card-title>
                                    <div style="border-left:1px solid #C1C8CC;" @click="close_cancelDialog()">
                                        <v-icon style="cursor: pointer;" class="pa-2" >close</v-icon>
                                    </div>
                                </v-layout>
                            </v-card>
                        <v-flex >
                           
                       
                        </v-flex>
                        <v-card-text >
                                Details saved on screen will be lost
                        </v-card-text>
                        <v-card-actions justify-start>
                          
                           
                           
                            <v-btn dark  tile color="black" @click="confirmDialogCancel = false" class="text-capitalize px-3 mx-2" small><v-icon dark small>cancel</v-icon> <v-divider vertical class="mx-2"></v-divider>Cancel</v-btn>

                            <!-- <v-btn depressed small color="secondary" class="rounded-0"  >
                                <v-icon dark right>check_circle</v-icon>
                            </v-btn> -->
                            <v-btn @click="okClick" dark  tile color="#23B1A9" class="text-capitalize px-3 ml-2" small><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider> OK</v-btn>

                        </v-card-actions>
                    </v-card>
                </v-dialog>

                
            <div class="mx-2 pa-5" style="background-color: #F7F7F7; display:flex;justify-content:space-between;"  >
                <v-layout style="flex:1" justify-end > 
                     <v-btn @click="viewdocs" dark tile color="black" class="text-capitalize px-3 ml-3" small><v-icon dark small>visibility</v-icon> <v-divider vertical class="mx-2"></v-divider>View Documents</v-btn>
                    <v-btn dark tile color="black" class="text-capitalize px-3 ml-3" small @click="sendBackBtn"><v-icon dark small>mdi-arrow-left</v-icon> <v-divider vertical class="mx-2"></v-divider>Send Back</v-btn>
                    <v-btn dark tile color="#E46A25" class="text-capitalize px-3 ml-3" small @click="cancelData()"><v-icon dark small>cancel</v-icon> <v-divider vertical class="mx-2"></v-divider>Cancel</v-btn>
                    <v-btn dark tile color="#23B1A9" class="text-capitalize px-3 ml-3 testing" small type="submit"><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider>Submit</v-btn>
                    
                </v-layout>
            </div>

            
            <v-layout class="mt-5" v-if="reviewHistory.length > 0">
                <v-card  class="rounded-0 ml-2" width="99%">
                    <v-card-title style="background-color:#23B1A9" class="pa-2 pl-3 "> 
                        <v-text style="color:#F5F5F5" class="font-weight-bold body-2">  Provider Review History </v-text> </v-card-title>
                    <v-data-table
                    :headers="headers"
                    :items="reviewHistory"
                    :page.sync="page"
                    :items-per-page="statusItemsPerPage"
                    hide-default-footer
                    dense
                    @page-count="pageCount = $event">
                    </v-data-table>
                     <v-divider></v-divider>
                    <v-layout row justify-end align-center class="ma-2">
                            <span class="mr-1 font-weight-bold" style="color:#3C3D3D; font-size:13px;">
                                <span class="" style="color:#E46A25;">Showing {{recordFrom}}-{{recordTo}} </span>out of {{totalRecords}} records
                            </span>
                            <v-divider  class="mx-2" vertical ></v-divider>
                             <v-pagination
                                class="cust-bnkAcc-pgn"
                                v-model="page"
                                color="#152F38"
                                flat
                                @input="nextPageBtn"
                                :length="pageLength"
                                :total-visible="7"
                            ></v-pagination>
                        </v-layout>
                </v-card>

            </v-layout>
            
        </div>
     </v-form>
      <div v-if="docen">
                    <docup 
                        @closedoc="docen = false"    
                      
                        :setdoc="{docen:docen,provider_data:$store.state.empanelProviderData.empanelment,
                        type:'edit', updocs:this.create_doc_arr,from:'emp' }" >
                    </docup>
        </div>
</v-app>
</template>

<script>
import { required,helpers} from 'vuelidate/lib/validators';
import moment from 'moment';
import docup from './viewdocument.vue'

const nameSpaces = helpers.regex('nameSpaces', /^[ a-zA-Z0-9!@#'":;\&?*\)/\\(+=,.-]+$/)
export default {
    inject : ['selectedCompFromPage', 'arrToNavCancel'],
    components:{
        docup
    },
   
    created(){ 
        let userID = this.$store.state.empanelProviderData.empanelment._id;
        console.log("%c this is the route value","background-color:#aaaa20;color:black",userID)
        console.log("%c this is the route value","background-color:#ffff20;color:black",this.$route.params,"\n",this.$store.state)
        this.empanelmentTypeIs = this.$store.state.empanelProviderData.empanelment.empanelmentType
        this.routeData = this.$route.params.allData
        this.nextPageBtn();
        if(Object.keys(this.$store.state.empanelProviderData).length === 0){
                this.arrToNavCancel.push('ProviderEmpanelmentMain')
        }
        if(this.$store.state.empanelmentTabStatus.providerReviewStatus === true){
            this.getReviewDetailsById(userID)
        }
    },
    data(){
        return {
            routeData:'',
            confirmDialogCancel:false,
            docen:false,
             reviewerRemarks:"",   
             reviewStatusItem:['Verified', 'Rejected'],
             reviewStatus: '',
             reason:'',
            reviewHistory:[],

            page: 1,
            recordFrom:null,
            recordTo: null,
            statusItemsPerPage: 5,
            totalRecords: null,

            empanelmentTypeIs:''
            
             

        }
    },

    validations:{
            reviewerRemarks:{nameSpaces},
            reviewStatus: {required},
            reason:{required,nameSpaces}
    },

    computed:{


        headers(){
            return [
                    {
                        text:'Date',
                        value:'date',
                        sortable: false,
                        divider:true,
                        class:"header-text"
                    },

                    {
                        text:'Reviewer Remarks',  
                        value:'reviewerRemarks',    
                        sortable: false,     
                        divider:true,   
                        class:"header-text"
                    },

                    {
                        text:'Status', 
                        value:'reviewStatus',
                        sortable: false,
                        divider:true,
                        class:"header-text"
                    },

                    {
                        text:'Reason',            
                        value:'reason' ,    
                        sortable: false,     
                        divider:true,   
                        class:"header-text"
                    }
            ]
        },

        reviewerRemarksErrors(){
             const errors = []
            
            if (!this.$v.reviewerRemarks.$dirty) return errors
            !this.$v.reviewerRemarks.nameSpaces && errors.push('Only alphabets are allowed');
            return errors;
        },



        reviewStatusErrors(){
             const errors = []
            
            if (!this.$v.reviewStatus.$dirty) return errors
            !this.$v.reviewStatus.required && errors.push('Only alphabets are allowed');
            return errors;
        },

        reasonErrors(){
             const errors = []
            if(this.reviewStatus === "Rejected" ){
                
                if (!this.$v.reason.$dirty) return errors
                !this.$v.reason.required && errors.push('This field is required');
                !this.$v.reason.nameSpaces && errors.push('Only alphabets are allowed');
                return errors;
            }else{
                 if (!this.$v.reason.$dirty) return errors
                !this.$v.reason.nameSpaces && errors.push('Only alphabets are allowed');
                return errors;
            }
            return errors;

        }


    },

    methods:{
        getReviewDetailsById(userID){
             let api_Is = 'empanelment/empanelment-details-by-id/'+userID;
            this.GET(2,api_Is,(res,error)=>{
                    if(!error){
                        console.log("this is response form getReviewDetails",res.data);
                        let reviewData = res.data.data;
                        this.$store.commit("EMPANEL_TAB_STATUS", reviewData);
                    }
            })

        },
        viewdocs(){
             this.docen = true
        }, 

        nextPageBtn(page){

            console.log("next button called whch ash get reviwe wpage ",this.$store.state.empanelProviderData.empanelment._id);

            // alert(" -- get provider review -- ");
            
            let id = this.$store.state.empanelProviderData.empanelment._id
            this.GET(2,'empanelment/get-provider-review/'+id+'?perPage='+this.statusItemsPerPage+'&pageNo='+this.page,(res,error)=>{
                    if(!error){
                            // this.showToast(res.data.msg,this.TOST().SUCCESS)
                            console.log("full response for get data",res.data);
                            console.log("%c this is data for review","color:darkblue",res.data.data.list);
                                this.pageLength = res.data.data.total_pages;
                                this.totalRecords = res.data.data.total
                                var temp =  this.page*5;
                                this.recordFrom = temp-4;
                                this.recordTo = temp;
                                      
                        let reviewData = res.data.data.list;
                        this.reviewHistory=[];
                        this.reviewHistory = reviewData.map((data)=>{
                                                    // console.log("%c this is data for reviewDaata","color:orange",data);
                                                    return{
                                                            ...(data.hasOwnProperty('date') && {date:moment(data.date, moment.ISO_8601).format("DD/MM/YYYY hh:mm:ss").substr(0,10)}),
                                                            reviewStatus: data.reviewStatus,
                                                            reason: data.reason,
                                                            reviewerRemarks:data.reviewerRemarks

                                                        }
                                                })
                }else{
                    console.log("%c their was error while fetching the pages ","color:orange",error);
                }
            })
                                                
        },

        submitDetails(){
            
            console.log("%c submitting the form","color:green");
            this.$v.$touch();
            let id = this.$store.state.empanelProviderData.empanelment._id
            if(this.$v.reviewStatus.$invalid || this.reasonErrors.length > 0 || this.$v.reviewerRemarks.$invalid){
                this.showToast("Please fill required fileds",this.TOST().WARNING)
                console.log("%c It is a not a valid Form","color:red",this.reasonErrors);
            }else{
                
                console.log("%c It is a valid Form","color:green",this.reasonErrors);
                    var  formData =  {
                            workItemId: this.routeData._id,
                            workItemType: this.routeData.type,
                            reviewerRemarks: this.reviewerRemarks,
                            reviewStatus: this.reviewStatus,
                            reason: this.reason,
                            empanelType: this.empanelmentTypeIs
                        }
                 this.PUT(2,'empanelment/create-provider-review/'+id,formData,(res,error)=>{
                        if(!error){
                            console.log("PUT APi response|-> empanelment/save-payment-details ", res)
                            // TODO: if rejected i have to push it to previous page (if asked by client)
                            // if(this.reviewStatus === 'Rejected'){
                            //     this.$router.go(-1)
                            //     console.log("%c provider is Rejected","background-color:black;color:green");
                            // }
                            this.showToast(res.data.msg,this.TOST().SUCCESS)
                            

                            let userID = this.$store.state.empanelProviderData.empanelment._id;
                            this.getReviewDetailsById(userID)
                            this.GET(2,'empanelment/get-provider-review/'+id+'?perPage='+this.statusItemsPerPage+'&pageNo='+this.page,(res,error)=>{
                                    if(!error){
                                            // this.showToast(res.data.msg,this.TOST().SUCCESS)
                                            console.log("full response for get data",res.data);
                                            console.log("%c this is data for review","color:darkblue",res.data.data.list);
                                                this.pageLength = res.data.data.total_pages;
                                                this.totalRecords = res.data.data.total
                                                var temp =  this.page*5;
                                                this.recordFrom = temp-4;
                                                this.recordTo = temp;
                                                
                                            let reviewData = res.data.data.list;
                                            this.reviewHistory=[];
                                            this.reviewHistory = reviewData.map((data)=>{
                                                                        // console.log("%c this is data for reviewDaata","color:orange",data);
                                                                        return{
                                                                                ...(data.hasOwnProperty('date') && {date:moment(data.date, moment.ISO_8601).format("DD/MM/YYYY hh:mm:ss").substr(0,10)}),
                                                                                reviewStatus: data.reviewStatus,
                                                                                reason: data.reason,
                                                                                reviewerRemarks:data.reviewerRemarks

                                                                            }
                                                                    })
                                                          
                                                        //   console.log("this is review history",this.reviewHistory);     
                                                               
                                    }else{
                                        console.log("%c was not able to fetch data from backend","color:orange",error);
                                        this.showToast(error.response.data.msg,this.TOST().ERROR);
                                    }
                            })
                            
                        }else{
                            console.log(error.response.data.msg);
                            console.log("PUT APi error response|->empanelment/save-payment-details", error)
                            this.showToast(error.response.data.msg,this.TOST().ERROR);

                        }    
                    });

                } 
               console.log(this.reviewHistory.length,'from 354')

            },


        sendBackBtn(){

            console.log("%c sending back data to workItem ","color:blue");
             let id = this.$store.state.empanelProviderData.empanelment._id
            this.$v.$touch();
            
            if(this.$v.reviewStatus.$invalid || this.reasonErrors.length > 0){
                    this.showToast("Please fill required fileds",this.TOST().WARNING)
                    console.log("%c Not valid form","color:red");
            }else{
                    console.log("%c is valid form","color:green");
            }


             var  formDataSendBack =  {
                            workItemId: this.routeData._id,
                            workItemType: this.routeData.type,
                            empanelType: this.empanelmentTypeIs,
                            reviewerRemarks: this.reviewerRemarks,
                            reviewStatus: this.reviewStatus,
                            reason: this.reason
                        }
             this.PUT(2,'empanelment/create-sendback-provider-review/'+id,formDataSendBack,(res,error)=>{
                        if(!error){
                            // this.getPaymentDetailsById(idIs);
                            console.log("PUT APi response|-> empanelment/save-payment-details ", res)
                            this.showToast(res.data.msg,this.TOST().SUCCESS)
                            
                        }else{
                            console.log(error.response.data.msg);
                            console.log("PUT APi error response|->empanelment/save-payment-details", error)
                            this.showToast(error.response.data.msg,this.TOST().ERROR);

                        }    
                    });

    
            },
        

         okClick(){
             console.log("%c OK button was clicked","background-color:#11457d;color:white",this.arrToNavCancel);
            if(this.arrToNavCancel.length === 3){
                this.selectedCompFromPage(this.arrToNavCancel[2])
            }else{
                this.selectedCompFromPage(this.arrToNavCancel[1])
            }
                 
        },

        cancelData(){
            this.confirmDialogCancel = true
        },

        close_cancelDialog(){
            this.confirmDialogCancel = false
        },



        } //methods end



}

</script>


<style>
.header-text{
    color:#10242B !important;
    font-size: 13px !important;
    /* padding:0px!important;
    padding: 10px !important;
    padding-left: 14px !important; */
}
</style>