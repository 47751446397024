<template>
  <v-app>
    <!-- <v-card class="ma-3 " tile> -->
    <div>
      <v-layout class="pa-2 ma-3 py-3 text-center">
        <v-flex xs3>
          <div
            style="background-color: #F7F7F7; cursor:pointer;"
            class="pa-2 pr-14 "
            :class="[
              CFRbtnStatus == 'caseUnderCFR'
                ? 'activeRadioclass'
                : '',
            ]"
              @click="changeCFEStatus('caseUnderCFR')"
          >
            <div
              class="font-weight-bold"
              :class="[
                CFRbtnStatus == 'caseUnderCFR'
                  ? 'text-white-insrns'
                  : 'text-dark-insrns',
              ]"
            >
            <!-- CFRbtnStatus -->
              Cases Under CFR
            </div>
          </div>
        </v-flex>
        <v-flex xs3>
          <div
            style="background-color: #F7F7F7;  cursor:pointer"
            class="mr-4 pa-2"
            :class="[
              CFRbtnStatus == 'cfrCompletedCase'
                ? 'activeRadioclass'
                : '',
            ]"
            @click="changeCFEStatus('cfrCompletedCase')"
          >
            <div
              class="font-weight-bold"
              :class="[
                   CFRbtnStatus == 'cfrCompletedCase'
                  ? 'text-white-insrns'
                  : 'text-dark-insrns',
              ]"
            >
              CFR Completed Cases
            </div>
          </div>
        </v-flex>
        
      
      </v-layout>
      <v-form v-model="formvalidation" ref="CFRForm">
        <v-layout  class="pa-2 ma-2 py-3" style="displ:flex;justify-content: center;align-items: center;">
        <v-flex xs3>
						<v-layout column class="cust_pln_em mx-2 cust-rmvpd-iphd_claims">
							<p class="font-weight-bold cstm-clr-pln-iphd_claims">From</p>
					
							<v-menu
								ref="doaCalender"
								:close-on-content-click="false"
								v-model="effectivefromCalender"
                :nudge-top="150"
								:nudge-right="150"
								lazy
								transition="scale-transition"
								full-width
								max-width="290px"
								min-width="290px"
								class="rounded-0 cstm-clr-pr-Ms"
								dense
								offset-y
							>
								<!-- :rules="rules.dateofLossRules" -->
								<template v-slot:activator="{ on, attrs }">
									<v-text-field
										label="dd-mm-yyyy"
										v-bind="attrs"
										dense
										v-on="on"
										ref="datePicker"
										:value="cmputeffectivedatefromMmntjs"
										@click="datePickerValue()"
										@input="datePickerValue2()"
										reactive
										solo
										class="rounded-0 comboxPadding labelPadd"
										maxlength="10"
										:rules="[dateRegex(effectivedatefrom)]"
									>
										<div slot="prepend-inner" style="background-color: #f7f7f7; padding: 9px !important">
											<v-icon>event</v-icon>
										</div>
									</v-text-field>
								</template>
								<v-date-picker v-model="effectivedatefrom" @change="closedatepicker" no-title> </v-date-picker>
							</v-menu>
						</v-layout>
				</v-flex>

        <v-flex xs3>
						<v-layout column class="cust_pln_em mx-2 cust-rmvpd-iphd_claims">
							<p class="font-weight-bold cstm-clr-pln-iphd_claims">To</p>
					
							<v-menu
								ref="doaCalender"
								:close-on-content-click="false"
								v-model="effectivetoCalender"
                :nudge-top="150"
								:nudge-right="150"
								lazy
								transition="scale-transition"
								full-width
								max-width="290px"
								min-width="290px"
								class="rounded-0 cstm-clr-pr-Ms"
								dense
								offset-y
							>
                
              <template v-slot:activator="{ on, attrs }">
									<v-text-field
										label="dd-mm-yyyy"
										v-bind="attrs"
										dense
										v-on="on"
										ref="datePicker"
										:value="cmputeffectivedatetoMmntjs"
									
										reactive
										solo
										class="rounded-0 comboxPadding labelPadd"
										maxlength="10"
										:rules="[dateRegex(effectivedatefrom)]"
									>
										<div slot="prepend-inner" style="background-color: #f7f7f7; padding: 9px !important">
											<v-icon>event</v-icon>
										</div>
									</v-text-field>
							</template>
								<v-date-picker v-model="effectivedateto" @change="closedatetopicker" no-title> </v-date-picker>
							</v-menu>
						</v-layout>
				</v-flex>
        
        <v-flex>
          <v-btn @click="cfrlistfilter()" style="align-self: center;" tile dark color="#23B1A9" class="text-capitalize px-3 mr-2" small>
              <v-icon color="" small >mdi-filter</v-icon>
              <v-divider vertical class="ml-2 mr-2"></v-divider>Filter
          </v-btn>

          <v-btn @click="clearfilter()" style="align-self: center;" tile dark color="#23B1A9" class="text-capitalize px-3" small>
              <v-icon color="" small >mdi-close</v-icon>
              <v-divider vertical class="ml-2 mr-2"></v-divider>Clear
          </v-btn>

        </v-flex>

    
      </v-layout>
      </v-form>
      

      
      <div class="mt-2 mb-2" style="height:1px; background-color:#C1C8CC"></div>

    <div v-if="valStaus==true ">  
      <v-data-table
        :headers="headers"
        :items="GetData"
        :page.sync="page"
        
        hide-default-footer
        dense
        @page-count="pageCount = $event"
      >

        <!-- 
          :items-per-page="itemsPerPage"
          <template v-slot:item.action="{ item }">
          <v-btn
            tile
            color="#fff"
            class="text-capitalize text-dark px-3 mx-2 my-2"
            small
            >
            <v-icon dark small>visibility</v-icon>
            </v-btn
          >
        </template> -->

        <template v-slot:item.coverageName="{ item ,value}">
       {{item.coverageName}} 
      </template> 

        <!-- <template v-slot:item.action="{ item }">

          <p @click="gotoCFRaudit(item)" class="mt-2 " style="text-decoration: underline; cursor: pointer;color: blue">View CFR</p>
        </template> -->

         <!-- <template v-slot:item.queryId="{ item }">
          <p @click="gotoQuery(item._id)" style="text-decoration: underline; cursor: pointer;color: blue">{{
           item.queryId
          }}</p>
        </template> -->
    
      </v-data-table>
    </div>

      <div v-if="valStaus==false ">
          <v-data-table
            :headers="headers123"
            :items="GetDataComplet"
            :page.sync="page"

            hide-default-footer
            dense
            @page-count="pageCount = $event"
          >
            <!-- 
                          :items-per-page="itemsPerPage"
              <template v-slot:item.coverage="{ item ,value}">
               {{item.coverage}} 
            </template>  -->
              <template v-slot:item.coverageName="{ item }">
                <p >{{item.coverageName}}</p>
        
              </template> 
      
            <!-- <template v-slot:item.action="{ item }">

              <p @click="gotoCFRaudit(item)" class="mt-2 " style="text-decoration: underline; cursor: pointer;color: blue">View CFR</p>
            </template> -->

        
          </v-data-table>

      </div>

      <v-divider></v-divider>

      <v-layout row justify-end align-center class="ma-1">
        <v-text
          class="mr-1 font-weight-bold body-1"
          style="color:#3C3D3D; font-size:12px !important"
        >
          <!-- <span class="" style="color:#E46A25;font-size:12px;">Showing 1-10 </span>out of {{total}} records -->
          <span class="" style="color:#E46A25;"
            >Showing {{ fval }}-{{ sval }} </span
          >out of {{ totalLen }} records
        </v-text>
        <v-divider class="mx-2" vertical></v-divider>
        <v-pagination
          class="cust-bnkAcc-pgn"
          v-model="page"
          color="#152F38"
          flat
          :total-visible="9"
          @input="nextPageBtn(page)"
          :length="pagelength"
        ></v-pagination>
      </v-layout>
    </div>

    <!-- </v-card> -->
  </v-app>
</template>

<script>
import axios from "axios";
import moment from "moment";

export default {

  components: {},

  created() {
    // this.$store.commit("SAVE_PAGE_HEADER", "CFR Dashboard");
    this.$store.commit("SAVE_PAGE_HEADER", "View Activity Dashboard");


    console.log("Success Token", this.$store.getters.successToken);
    this.token = this.$store.getters.successToken;
    this.getCFRData(this.page);
  },

  computed: {
    cmputeffectivedatefromMmntjs() {
			if (this.effectivedatefrom == "Invalid date") {
	
				this.effectivedatefrom = this.computedatenew("def") ?? "";
				return this.effectivedatefrom ? moment(this.effectivedatefrom).format("DD-MM-YYYY") : "";
			} else {
				return this.effectivedatefrom ? moment(this.effectivedatefrom).format("DD-MM-YYYY") : "";
			}
		},

    cmputeffectivedatetoMmntjs() {
			if (this.effectivedateto == "Invalid date") {
				this.effectivedateto = this.computedatenew("def") ?? "";
				return this.effectivedateto ? moment(this.effectivedateto).format("DD-MM-YYYY") : "";
			} else {
				return this.effectivedateto ? moment(this.effectivedateto).format("DD-MM-YYYY") : "";
			}

		},
  },

  data() {
    return {
      filterisuseflag:false,
      formvalidation:false,
      effectivedatefrom:'',
      effectivedateto:'',
      effectivetoCalender:false,
      effectivefromCalender:false,
      CFRbtnStatus: 'caseUnderCFR',
      // referredFrom: "ReferredFromPreauth",
      // for pagination AA
      swap_final_count: false,
      fval: 0,
      sval: 0,
      totalLen: "",

      fromvalue: "",
      toValu: "",
      token: null,
      page: 1,

      GetData:[],
      GetDataComplet:[],
      valStaus:true,

      // GetData1: [
      //     {
      //     claimId: "Claim ID",
      //     featureNo: "1211111"

      //   }
      // ],
      size: 10,

      
      headers: [
         {
          text: "Claim ID",
          value: "claimId",
          sortable: true,
          divider: true,
          class: "bg-investigation-table-header-color minWidth"
        },
        {
          text: "Feature Number",
          value: "featureNumber",
          sortable: false,
          divider: true,
          class: "bg-investigation-table-header-color minWidth",
        },
        {
          text: "Insured Name",
          value: "insuredName",
          sortable: false,
          divider: true,
          class: "bg-investigation-table-header-color minWidth",
        },
        {
          text: "Coverage",
          value: "coverageName",
          sortable: false,
          divider: true,
          class: "bg-investigation-table-header-color minWidth",
        },
        {
          text: "Policy Number",
          value: "policyNumber",
          sortable: false,
          divider: true,
          class: "bg-investigation-table-header-color minWidth",
        },
        {
          text: "Product Name",
          value: "productName",
          sortable: false,
          divider: true,
          class: "bg-investigation-table-header-color minWidth",
        },
        {
          text: "Total Amount Approve",
          value: "totalAmountApproved",
          sortable: false,
          divider: true,
          class: "bg-investigation-table-header-color minWidth",
        },
        {
          text: "Hospital Name",
          value: "hospitalName",
          sortable: false,
          divider: true,
          class: "bg-investigation-table-header-color minWidth",
        },
        {
          text: "Patient Name",
          value: "patientName",
          sortable: false,
          divider: true,
          class: "bg-investigation-table-header-color minWidth",
        },
        {
          text: "Corporate Name",
          value: "corporateName",
          sortable: false,
          divider: true,
          class: "bg-investigation-table-header-color minWidth",
        },
        {
          text: "TAT",
          value: "TAT",
          sortable: false,
          divider: true,
          class: "bg-investigation-table-header-color minWidth",
        },
        // {
        //   text: "Action",
        //   value: "action",
        //   sortable: false,
        //   divider: true,
        //   class: "bg-investigation-table-header-color minWidth",
        // }

        // {text: 'Action', value: 'Action',sortable: false,divider:true, width:"40px",class:"hdr-txt-clr-txt hdr-bg-clr"}
      ],

        headers123: [
         {
          text: "Claim ID",
          value: "claimId",
          sortable: true,
          divider: true,
          class: "bg-investigation-table-header-color minWidth"
        },
        {
          text: "Feature Number",
          value: "featureNumber",
          sortable: false,
          divider: true,
          class: "bg-investigation-table-header-color minWidth",
        },
      
        {
          text: "Hospital Name",
          value: "hospitalName",
          sortable: false,
          divider: true,
          class: "bg-investigation-table-header-color minWidth",
        },
        {
          text: "Patient Name",
          value: "patientName",
          sortable: false,
          divider: true,
          class: "bg-investigation-table-header-color minWidth",
        },
        {
          text: "Net Score",
          value: "netScore",
          sortable: false,
          divider: true,
          class: "bg-investigation-table-header-color minWidth",
        },
        {
          text: "Recovery to be Initiated",
          value: "recoveryToMade",
          sortable: false,
          divider: true,
          class: "bg-investigation-table-header-color minWidth",
        },
        {
          text: "Type of Recovery",
          value: "typeOfRecovery",
          sortable: false,
          divider: true,
          class: "bg-investigation-table-header-color minWidth",
        },
        {
          text: "Comments",
          value: "comments",
          sortable: false,
          divider: true,
          class: "bg-investigation-table-header-color minWidth",
        },


        // {
        //   text: "Action",
        //   value: "action",
        //   sortable: false,
        //   divider: true,
        //   class: "bg-investigation-table-header-color minWidth",
        // }

      ],

    };
  },

  methods: {

    clearfilter(){
      this.effectivedatefrom = ""
      this.effectivedateto = ""
      this.$refs.CFRForm.resetValidation()
      this.filterisuseflag = false
      this.getCFRData(1);
    },
    cfrlistfilter(){
      this.filterisuseflag = true
      console.log("CHECK BUTTON STATUS::",this.CFRbtnStatus)
      console.log("effectivedatefrom",this.effectivedatefrom)
      console.log("effectivedateto",this.effectivedateto)
      this.getCFRData(this.page,'filteron')
      
    },
    dateRegex(v) {
			console.log("date value in date picker", v);
			// let dateRegex2 = '/^([0]?[1-9]|[1|2][0-9]|[3][0|1])[./-]([0]?[1-9]|[1][0-2])[./-]([0-9]{4}|[0-9]{2})$/'
			return (v) =>
				/^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/.test(
					v
				) || "Please enter date in 'dd-mm-yyyy' format";
		},
    closedatetopicker(){
          this.effectivetoCalender = false
        },
        closedatepicker(){
            this.effectivefromCalender = false
        },
      changeCFEStatus(val){
          console.log(val,'ww');
          this.CFRbtnStatus = val;
        //   this.headers[1].text = val +' '+'Query ID';
          this.clearfilter()
          this.getCFRData(1);
  
      },

    gotoCFRaudit(val) {
        console.log('_idiii',val);
    //   if(this.CFRbtnStatus == 'caseUnderCFR'){
         this.$store.commit("SAVE_PAGE_HEADER", "CFR Audit Sheet");
         this.$router.push({
          name: "CFRauditSheet",
          params: { allData: val, from: 'CFRdashboard',screen:'CFR Audit Sheet' },
         
        });
    }, 


    async getCFRData(pageNum,filtercheck) {
      // this.GetData = [];
      console.log("PageNumber",pageNum)
      console.log("filtercheck",filtercheck)

      if (this.CFRbtnStatus=='caseUnderCFR') {
        this.valStaus=true
      } else {
        this.valStaus=false
        
      }
      var apiURL = '';
      var CFRtypeChange = this.CFRbtnStatus;

    //   if(this.responsesRecieved == true && this.responsesNotRecieved == false){
    //       apiURL = `query-management/search-query?perPage=10&pageNo=`+pageNum+`&type=${CFRtypeChange}&responsereceived=true`;
    //   }else if(this.responsesNotRecieved == true && this.responsesRecieved == false ){
    //       apiURL = `query-management/search-query?perPage=10&pageNo=`+pageNum+`&type=${CFRtypeChange}&responsenotreceived=false`;
    //   }else{

        if(filtercheck === 'filteron'){
          console.log("if call")
          apiURL = `cfr/get-cfr-details?perPage=10&pageNo=`+pageNum+`&type=${CFRtypeChange}&startDate=`+this.effectivedatefrom+`&endDate=`+this.effectivedateto

        }else{
          console.log("else call")
          apiURL = `cfr/get-cfr-details?perPage=10&pageNo=`+pageNum+`&type=${CFRtypeChange}`
        }
    //   }

      console.log("API URL",apiURL)
      
      await this.GET(20, apiURL, (response, error) => {
        if (!error) {
          let apiData = response.data.data.data;
          console.log('<<< === api dataaa ----===>',apiData);
          
          // this.GetData = [];

          if (this.CFRbtnStatus=='caseUnderCFR') {
            this.GetData = apiData;

            console.log('<<< ===caseUnderCFR this.GetData dataaa ----===>',this.GetData);

          
          } else {
            this.GetDataComplet = apiData
            this.GetDataComplet.forEach((data, index) => {
            // data.srNo = index + 1;
            data.srNo = 10*(pageNum)-9+index

           });
            
          }
          this.page=pageNum
          // for pagination

          this.pagelength = response.data.data.total_pages;

          this.totalLen = response.data.data.total;

          if (this.OFRStatus=='caseUnderCFR') {
            let l = this.GetData.total;
          } else {
            let l = this.GetDataComplet.total;
            
          }
          var less_enough = this.totalLen;
          var checkless_init = false;
          if (less_enough < 10) {
            checkless_init = false;
          } else {
            checkless_init = true;
          }

          // if less than 15 we have second value same as total value as no pagination will occur
          if (checkless_init) {
            // checkinit is true means the final count is more than 15 (- 1)
            var traverse = 10 * this.page;
            this.fval = traverse - 9;
            if (this.swap_final_count) {
              this.sval = this.totalLen;
            } else {
              this.sval = traverse;
            }
          } else if (this.totalLen == 0) {
            this.sval = this.fval = 0;
          } else {
            this.fval = 1;
            this.sval = this.totalLen;
          }
        } else {
            
          if (error.response.data.message.statusCode == 400) {
            // this.GetData = []
              this.fval = 0,
              this.sval= 0,
              this.totalLen= ""
              this.pagelength=0
            
          }
          this.showToast(error.response.data.message.msg,this.TOST().ERROR);

        }
      });
    },

    nextPageBtn(indata) {
      console.log("page no value", this.page);
      if (this.pagelength == indata) {
        this.swap_final_count = true;
      } else {
        this.swap_final_count = false;
      }
      if(this.filterisuseflag == true){
        this.getCFRData(this.page,'filteron');
      }else{
        this.getCFRData(this.page);
      }
    },


    nextPage(page) {
      this.pageNumber = page;
    }
  },

  watch: {},
};
</script>
<style>
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 0.84rem !important;
}

.minWidth{
min-width: 110px !important;
}

.activeRadioclass {
    background-color: #23b1a9 !important;
    color: #fff !important;
}

.text-white-insrns {
    color: #fff;
    font-size: 12px;
}

.text-dark-insrns{
  font-size: 12px;

}

.hdr-txt-clr-txt {
  color: #10242b !important;
  /* padding:0px!important;
    padding: 10px !important;
    padding-left: 14px !important; */
}

.hdr-bg-clr {
  background-color: #f7f7f7;
}
.cust_pr .v-text-field .v-input__slot,
.v-text-field--outline .v-input__slot {
  min-height: 38px !important;
  display: flex !important;
  align-items: center !important;
  /* border:1px red solid; */
}

.pending-work {
  justify-items: start;
}

.lay {
  direction: rtl;
  max-height: 50px;
}
.body-2 {
  font-size: 18px !important;
}
.body-3 {
  font-size: 16px !important;
}

.cstm-clr-pr {
  color: #10242b;
  margin-bottom: 5px;
}
.cust_pr .v-input {
  font-size: 13px !important;
}
.cust_pr .v-input input {
  font-size: 13px !important;
}

.cust_pr .v-input .v-label {
  font-size: 13px !important;
}

.cust-bnkAcc-pgn .v-pagination__navigation {
  box-shadow: unset !important;
  border-radius: unset !important;
  background-color: #ffffff !important;
  border: 1px solid #dae1e6 !important;
}
.cust-bnkAcc-pgn .v-pagination__navigation--disabled {
  background-color: #dae1e6 !important;
}
.cust-bnkAcc-pgn .v-pagination__item {
  box-shadow: unset !important;
  min-width: 2rem !important;
  height: 2rem !important;
  border-radius: unset !important;
  border: 1px solid #dae1e6 !important;
}
.bg-investigation-table-header-color {
  background-color: #23b1a9;
  color: #fff !important;
}
.activeInvestigationClass {
  background-color: #01bbd9 !important;
}
.cstm-clr-pln-iphd_claims {
  color: #646666 !important;
  margin-bottom: 5px !important;
  font-size: 13px !important;
}

.cstm-clr-pln-iphd_claims {
  color: #646666 !important;
  margin-bottom: 5px !important;
  font-size: 13px !important;
}
.cust-rmvpd-iphd_claims
  .v-text-field.v-text-field--enclosed
  > .v-input__control
  > .v-input__slot {
  padding: 0px !important;
}
</style>
