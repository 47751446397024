<template>
    <v-app>
        <v-container class="containerStyle" style="padding:1.5rem; margin:0; max-width:100%">
            <v-layout>
                <h3>Search Vendor Master</h3>
                
            </v-layout>
            <v-layout class="mt-8" wrap>
              <v-flex class="form-group">
          <label class="form-label">
              LOB 
          </label>
            <v-select
                v-model="lob"
                label="Select"
                :items="lobOptions"
                solo
                dense
                class="form-control"
            ></v-select>
        </v-flex>
                <v-flex class="form-group">
                    <label class="form-label">
                        Vendor ID
                    </label>
                    <v-text-field solo dense class="form-control" label="Enter..." v-model="vendorId" :rules="[(v)=>matchRegex(v,'alphanum')]"></v-text-field>
                </v-flex>
                <v-flex class="form-group">
                    <label class="form-label">
                        Vendor Type
                    </label>
                    <v-select solo dense class="form-control" :items="status_Items" label="Select" v-model="vendorType"></v-select>
                </v-flex>
                <v-flex class="form-group">
                    <label class="form-label">
                        Vendor Name
                    </label>
                    <v-text-field solo dense class="form-control" label="Enter..." v-model="vendorName"></v-text-field>
                </v-flex>
                <v-flex class="form-group">
                    <label class="form-label">
                        Vendor Contact
                    </label>
                    <v-autocomplete solo dense class="form-control" label="Enter..." v-model="vendorContact"
          :search-input.sync="vendorContactSmart" :items="vendorContactData" @keyup="smartSearch(vendorContactSmart)" item-text="vendorContact"
            prepend-inner-icon="mdi-phone"
            :rules="[(v) => matchRegex(v, 'nums'), (v) => validateLength(v, { exact: 10 }, '', 'Field requires 10 digits.')]"
            :maxlength="10"></v-autocomplete>
                </v-flex>
                <v-flex class="form-group">
                    <label class="form-label">
                        Postal Code
                    </label>
                    <PincodeSearch :pinCode="pinCode"
                    :label="false"
                        @on-change="search_Pincode" />
                </v-flex>
                
                <v-flex class="form-group">
                    <label class="form-label">
                        country
                    </label>
                    <v-select :items="country_Items" item-text="countryName" solo dense class="form-control" label="Enter..." v-model="country"></v-select>
                </v-flex>
                <v-flex class="form-group">
                    <label class="form-label">
                      State/Province
                    </label>
                    <v-autocomplete solo dense class="form-control" label="Enter..." v-model="state" @keyup="state_Search(search_State)" 
                    :items="state_Items" item-text="stateCodeName" :search-input.sync="search_State"></v-autocomplete>
                </v-flex>
                <v-flex class="form-group">
                    <label class="form-label">
                      District
                    </label>
                    <v-autocomplete solo dense class="form-control" label="Enter..." v-model="district" @keyup="district_Search(search_District)" 
                    :items="district_Items" item-text="cityDistrictName" :search-input.sync="search_District"></v-autocomplete>
                </v-flex>
                <v-flex class="form-group">
                    <label class="form-label">
                      City
                    </label>
                    <v-autocomplete solo dense class="form-control" label="Enter..." v-model="city" @keyup="city_Search(search_City)" 
                    :items="district_Items" item-text="cityDistrictName" :search-input.sync="search_City"></v-autocomplete>
                </v-flex>
                <v-flex class="form-group">
                    <label class="form-label">
                      Phone Number
                    </label>
                    <v-text-field solo dense class="form-control" label="Enter..." v-model="phoneNumber" prepend-inner-icon="mdi-phone" :rules="[(v)=>matchRegex(v,'nums'),(v) => validateLength(v, { exact: 10 }, '', 'Field requires 10 digits.')]" :maxlength="10"></v-text-field>
                </v-flex>

                <v-flex class="form-group">
          <label class="form-label">
              Status 
          </label>
            <v-select
                v-model="status"
                label="Select"
                :items="[{text:'Active',value:true},{text:'InActive',value:false}]"
                
                solo
                dense
                class="form-control"
            ></v-select>
        </v-flex>
            </v-layout>
            <v-layout wrap class="mr-7">
                <v-spacer></v-spacer>
                <v-btn class="btn cancelbtn mr-5" @click="clear">
                    <v-icon> mdi-backspace </v-icon>
                    <v-divider class="mx-4"
                vertical
                color="white"></v-divider>
                    Reset
                </v-btn>
                <v-btn depressed
      color="teal white--text " @click="searchServiceProviderData(1)">
                    <v-icon>mdi-arrow-right-circle</v-icon>
                    <v-divider class="mx-4"
                vertical
                color="white"></v-divider>
                    Search
                </v-btn>
            </v-layout>
            <v-layout class="mt-8">
                <h4 class="mr-3"><strong>Vendor Master List <span class="orange--text">({{count}})</span></strong></h4>
      <!-- <v-chip small color="#1EBBD9" text-color="white" label close
                     v-if="showSearchChip==true"
                     @click:close="clear();searchServiceProviderData(1)">Search</v-chip> -->
                     <!-- v-if="showSearchChip==true" 
                     -->
                <v-spacer></v-spacer>
                <!-- <v-btn depressed
                color="#E46A25"
                class="white--text mr-3"><v-icon>
                    mdi-file-excel
                </v-icon>
                <v-divider class="mx-4"
                vertical
                color="white"></v-divider>Export to Excel</v-btn> -->

                
            </v-layout>
           
    <!-- Main Table -->
    <v-data-table
    hide-default-footer
    :headers="main_Table_Header"
    :items="main_Table_Data"
    class="mt-5 vDataTableStyling"
    >
    <template v-slot:[`item.actions`]="{ item }">
                      <v-menu offset-y open-on-hover>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            elevation=""
                            icon
                            v-bind="attrs"
                            v-on="on"
                            style="text-align:center"
                          >
                            <v-icon>mdi mdi-dots-vertical</v-icon>
                          </v-btn>
                        </template>
                        
                        <v-list class="item-actions" >
                         <template >
                          <v-list-item
                           v-for="(actions, index) in actionOptions"
                            :key="index"
                            @click="editmaster(actions.link, index, item, item._id, actions.name,main_Table_Data.indexOf(item))"
                            :disabled="actions.name === 'MODIFY' && ['Pending for approval'].includes(item.approveStatus)"
                          >
                          
                          <!-- :disabled="actions.name === 'MODIFY' && !['', 'Saved'].includes(item.paymentStatus)" -->
                            <v-list-item-title :style="(actions.name === 'MODIFY' && ['Pending for approval'].includes(item.approveStatus)) ? 'gray' : actions.color"
                              ><v-icon small>{{ actions.icon }}</v-icon>
                               {{ actions.name }}</v-list-item-title
                            >
                          </v-list-item>
                          </template>
                        </v-list>
                      </v-menu>
                    </template>
                    <template v-slot:[`item.licenseExpiryDate`]="{item}">
                      {{dateFormat(item.licenseExpiryDate)}}
                    </template>
                    <template v-slot:[`item.active`]="{item}">
                      {{item.status ? 'Active' : 'InActive'}}
                    </template>
                    <template v-slot:[`item.contactNumber`]="{item}">
                      <template v-for="number in item.contactNumber">
                      {{number.number}}</template>
                    </template>
                    <template v-slot:[`item.approveStatus`]="{ index}">
                      {{main_Data[index].approveStatus  }}
                    </template>
    </v-data-table>
     <!-- Pagination -->
     <v-layout justify-end align-center class="pb-2 px-0 mr-4">
      <v-row>
          <v-col>
            <v-btn outlined color="red" class="btn" @click="cancel">
              <v-icon> mdi-backspace </v-icon>
              <v-divider vertical class="mx-2"></v-divider>
              Cancel
            </v-btn>
          </v-col>
        </v-row >

              <div v-if="main_Table_Data.length > 0">
                <span style="color:orange">Showing {{ pageStartIndex_Provider }}-{{ pageEndIndex_Provider }}</span>
                out of {{ totalListCount_Provider }} records
              </div>
              <div v-if="main_Table_Data.length > 0">
                <v-pagination  v-model="page_Provider" 
                :length="pageCount_Provider" 
                :total-visible="5" 
                @input="searchServiceProviderData(page_Provider)" color="#152F38"
                ></v-pagination>
              </div>
            </v-layout>
            <!-- For Audit Trail -->
<v-dialog v-model="audit_dialog" width="800">
      <v-card>
        <v-card-title class="white--text" style="background-color: #23b1a9">
          Audit Trail
          <v-spacer></v-spacer>
          <v-icon @click="audit_dialog = !audit_dialog" class="white--text"
            >mdi-window-close</v-icon
          >
        </v-card-title>
        <v-layout class="pt-4 form-group-container pr-4">
          <v-data-table
            :headers="audit_Header"
            hide-default-footer
            :items="audit_Data"
            class="vDataTableStyling w-100"
          >
            <template v-slot:[`item.srno`]="{ index }">
              {{ 1 + index }}
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-btn
                v-if="item.action === 'Modified'"
                color="#FFFFFF"
                class="text-capitalize tablebtn"
                @click="seeModify(item)"
              >
                {{ item.action }}
                <v-icon class="tableicons">mdi-arrow-right-circle</v-icon>
              </v-btn>
              {{ item.action !== "Modified" ? item.action : null }}
            </template>
            <template v-slot:[`item.createdAt`]="{ item }">
              {{ formatDateTime(item.createdAt) }}
            </template>
          </v-data-table>
        </v-layout>
        <v-layout justify-end align-center class="pb-2 px-0 mr-4">
          <div>
            <span style="color: orange"
              >Showing {{ AuditPageStartIndex }}-{{ AuditPageEndIndex }}</span
            >
            out of {{ AuditTotalListCount }} records
          </div>
          <div>
            <v-pagination
              class="small-pagination"
              v-model="AuditPage"
              :length="AuditPageCount"
              :total-visible="5"
              @input="getAuditData(AuditPage, '', true)"
              color="#152F38"
            ></v-pagination>
          </div>
        </v-layout>
      </v-card>
    </v-dialog>
    <!-- Modify dialogue -->
    <v-dialog v-model="seeModifyDialog" width="600">
      <v-card>
        <v-card-title class="white--text"
                    style="background-color: #23b1a9">
          <v-layout justify-space-between>
            <div>
              Modified Field Details
            </div>
          </v-layout>
          <v-btn color="transparent" small :ripple="false" depressed @click="seeModifyDialog = false">
            <v-icon color="white">mdi-close </v-icon>
          </v-btn>
        </v-card-title>
        <div class="scrollable-table">
        <v-data-table disable-pagination :headers="modifyHeaders" 
        :items="modifyItems" hide-default-footer class="pa-4 vDataTableStyling">
        <template v-slot:[`item.srNo`]="{ index }">
                {{ 1 + index }}
              </template>
              
                <!-- <template v-slot:[`item.oldValue`]="{item}">
                  {{item.fieldName=='License Expiry Date'?dateFormat(item.oldValue):item.oldValue}}
                </template>
                <template v-slot:[`item.newValue`]="{item}">
                  {{item.fieldName=='License Expiry Date'?dateFormat(item.newValue):item.newValue}}
                </template> -->
              
      </v-data-table>

        </div>
      </v-card>
    </v-dialog>

    <v-dialog v-model="reject_Dialog" width="600">
                <v-card>
                    <v-card-title class="white--text" style="background-color: #23b1a9"
                    ><v-layout style="display:flex;justify-content:center;margin-left: 170px;" >Reject
                       </v-layout>
                       <v-layout style="display:flex;justify-content:end;">
                        <v-icon class="white--text " @click="reject_Dialog=!reject_Dialog;reason=''">mdi-close</v-icon>
                      </v-layout>
                      </v-card-title>
                      <v-form ref="rejectForm" @submit="false">
                        <v-layout class="pt-4 form-group-container" >
                           
                            <v-flex class="mr-2">
                                <label class="form-label">Reason <span><strong class="red--text">*</strong></span></label>
                                <v-text-field  class="form-control" solo dense :rules="reject_Dialog ? [(v)=>requiredField(v)]:[]" v-model="reason" placeholder="Enter..."></v-text-field>
                            </v-flex>
                        </v-layout>
                      </v-form>
                        <v-card-actions style="display:flex;justify-content:center">
                          <v-btn class="btn" color="red white--text mr-0" @click="reject_Dialog=!reject_Dialog;reason=''">
                            No
                        </v-btn>

                        <v-btn class="btn" color="green white--text mr-0" @click="onSubmit">
                            Yes
                        </v-btn>
                      </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="approve_Dialog" width="600">
                <v-card>
                    <v-card-title class="white--text" style="background-color: #23b1a9"
                    ><v-layout style="display:flex;justify-content:center;margin-left: 170px;" >Approve
                       </v-layout>
                       <v-layout style="display:flex;justify-content:end;">
                        <v-icon class="white--text " @click="approve_Dialog=!approve_Dialog;reason=''">mdi-close</v-icon>
                      </v-layout>
                      </v-card-title>
                        <v-layout class="pt-4 form-group-container" justify-center>
                           
                            <h4>Are you sure want to Approve ?</h4>
                        </v-layout>
                        <v-card-actions style="display:flex;justify-content:center">
                          <v-btn class="btn" color="red white--text mr-0" @click="approve_Dialog=!approve_Dialog;reason=''">
                            No
                        </v-btn>

                        <v-btn class="btn" color="green white--text mr-0" @click="onSubmit">
                            Yes
                        </v-btn>
                      </v-card-actions>
                </v-card>
            </v-dialog>
    <!-- Audit Trail -->
    <audit-trail
      :dialogState="OpenAuditDialog"
      :auditTrailMaster="'vendor'"
      :auditTrailItem="{ uid: auditTrailId }"
      @close-dialog="OpenAuditDialog = false"
    />
        </v-container>
    </v-app>
</template>
<script>
import moment from 'moment'
import PincodeSearch from '../../../../component/PincodeSearch.vue';
import auditTrail from '../../../../component/MasterAuditTrail.vue'
export default{
  components:{PincodeSearch, auditTrail},
   data(){
    return{
      lob:"",
      lobOptions:[],
        search_Dialog:false,
        //Table_Header
        main_Table_Header:[{text:"Action",value:"actions",sortable:false,align:"center"},
        {text:"Master ID",value:"operationData[0].uid",sortable:false,align:"center",width:'150'},
      {text:"LOB",value:"operationData[0].lob", sortable: false, align: "center", width: "100px"},
        {text:"Vendor ID",value:"operationData[0].vendorId",sortable:false,align:"center",width:'150'},
        {text:"Vendor Type",value:"operationData[0].vendorType",sortable:false,align:"center"},
        {text:"Vendor Name",value:"operationData[0].vendorName",sortable:false,align:"center"},
        {text:"Postal Code",value:"operationData[0].pinCode",sortable:false,align:"center",width:'150'},
        {text:"Vendor Contact",value:"operationData[0].vendorContact",sortable:false,align:"center",width:'150'},
        {text:"Phone Number",value:"operationData[0].cellNumber",sortable:false,align:"center",width:'150'},//
        // {text:"Bank Name",value:"bank.bankName",sortable:false,align:"center",width:'150'},
        // {text:"IFSC Code",value:"bank.ifscCode",sortable:false,align:"center",width:'150'},
        // {text:"Bank Account No",value:"bank.accountNumber",sortable:false,align:"center",width:'150'},
        {text:"Active status",value:"active",sortable:false,align:"center"},
        {text:"Approval status",value:"approveStatus",sortable:false,align:"center"},
        {text:"Initiated By",value:"createdBy", sortable: false, align: "center",},
      {text:"Date and Time",value:"createdAt", sortable: false, align: "center",}
        ],
        main_Table_Data:[],
        audit_Data:[],
      modifyItems: [],
      main_Data:[],
      vendorContactSmart:'',
        vendorContactData:[],
        audit_Header:[{text:"Sr. No.",value:"srno",sortable:false,align:"center"},
      {text:"Master ID",value:"masterId",sortable:false,align:"center"},
      {text:"Action Taken",value:"action",sortable:false,align:"center"},
      {text:"User ID",value:"userId",sortable:false,align:"center"},
    {text:"User Name",value:"userName",sortable:false,align:"center"},
    {text:"Time Stamp",value:"createdAt",sortable:false,align:"center"}],
    modifyHeaders: [
        {
          text: "Sr. No.",
          value: "srNo",
          align: "center",
          sortable: false,
        },
        {
          text: "Field Name",
          value: "fieldName",
          align: "center",
          sortable: false,
        },
        {
          text: "Old Value",
          value: "oldValue",
          align: "center",
          sortable: false,
        },
        {
          text: "New Value",
          value: "newValue",
          align: "center",
          sortable: false,
        },
      ],
      seeModifyDialog:false,
      itemsPerPage: 10,
      totalListCount: 0,
      pageStartIndex: 1,
      pageCount: 0,
      page: 1,
        actionOptions: [
                {
                name: "VIEW",
                icon: "mdi-eye-outline",
                // link: "/childrenEducationCoverAddAssessment",
                color: "color:#23b1a9",
                iconColor: "color:#10242B",
                },
                // {
                //   name: "Approve",
                //   icon: "mdi-check-circle-outline",
                //   color: "color:#23b1a9",
                //   iconColor: "color:green",
                // },
                // {
                //   name: "Reject",
                //   icon: "mdi-cancel",
                //   color: "color:#23b1a9",
                //   iconColor: "color:red",
                // },
                {
                name: "AUDIT TRAIL",
                icon: "mdi-chart-timeline-variant",
                // link: "/modifypage",
                color: "color:orange",
                iconColor: "color:orange",
                }],
               
                audit_dialog:false,
                count:0,
                vendorId:"",
                vendorType:"",
                vendorName:"",
                vendorContact:"",
                pinCode:"",
                country:"",
                country_Items:[],
                search_State:null,
                state_Items:[],
                state:"",

                district:"",
                district_Items:[],
                search_District:null,

                city:"",
                city_Items:[],
                search_City:null,
                status_Items:['Client','Delivery Company','Manufacturer','Other','Supplier'],
                phoneNumber:"",
                status:"",
                approveStatus:"",
                pickerDate:null,
                
                 menu1:false,
                 showSearchChip:false,
                 
                //  status_Items:["Approved","Rejected",'Pending for approval'],
                 //pagination
                 itemsPerPage_Provider: 10,
                 totalListCount_Provider: 0,
                 pageStartIndex_Provider: 1,
                 page_Provider: 1,
        approve_Dialog:false,
        reject_Dialog:false,
        reason:"",
        selectedId: "",
        AuditPage: 1,
      AuditTotalListCount: 0,
      AuditPageCount: 0,
      AuditPageStartIndex: 0,
      AuditItemsPerPage: 10,
      OpenAuditDialog: false,
      auditTrailId: null,
    }
   },
   methods:{

    formatDateTime(dateTime) {
      return dateTime ? moment(dateTime).format("DD-MM-YYYY LT") : ""
    },

    cancel(data) {
      this.showConfirm(
        "Confirmation",
        "Are you sure you want to leave?",
        "Yes",
        "No",
        (Yes) => {
          this.$router.push("/master-approval"); //params:{pageNo:this.pageNo}}
        }
      );
    },

    smartSearch(value){
    if(value.length>2){
      this.vendorContactData=this.main_Table_Data
    }else{
      this.vendorContactData=[]
    }},
    search_Pincode(data){
    console.log(data)
    this.country=data.countryName
    this.state_Search(data.stateName)
    this.state=data.stateName
    this.district_Search(data.cityDistrictName)
    this.district=data.cityDistrictName
    this.search_District=data.cityDistrictName
    this.city_Search(data.cityDistrictName)
    this.city=data.cityDistrictName
    this.search_City=data.cityDistrictName
  },
  state_Search(value){
    this.GETM('getStates',`stateCodeName=${value}`,res=>{this.state_Items=res.data.data.data},err=>{console.log(err)})
  },
  district_Search(value){
    this.GETM('getCities',`cityDistrictName=${value}`,res=>{this.district_Items=res.data.data.data},err=>{console.log(err)})
  },
  city_Search(value){
    this.GETM('getCities',`cityDistrictName=${value}`,res=>{this.city_Items=res.data.data.data},err=>{console.log(err)})
  },
  get_countrys(bool){
    if(bool){}else{
      this.GETM('getCoutries','',res=>{this.country_Items=res.data.data.data},err=>{console.log(err)})
    }
    
  },
    dateFormat(date) {
      
      return date ? moment(date).format("DD-MM-YYYY") : "";
    },
    editmaster(link, index, item, id, name,a,b) {
      console.log(a)
     if(index == 0){
      this.$router.push({
        name: "VendorApproval",
        params: {
          isView: true,
          item: item,
          id:this.main_Data[a]
        },
      });
     }
    //  if(index == 1){
    //    this.selectedId = this.main_Data[a]._id;
    //   this.approve_Dialog = true;
    //  }
     
    //  if(index == 2){
    //    this.selectedId = this.main_Data[a]._id;
    //   this.reject_Dialog = true;
    //  }
    
     if(index == 1){
      this.OpenAuditDialog = true
      console.log("ITEM",item)
      this.auditTrailId = item.operationData[0].uid
      // this.audit_dialog=true
      // this.getAuditData(1,item.vendorId,false)
     }
    //  console.log(a,name,id,link,item)
    },
    getAuditData(newPageNum,id,bool){
      let reqData =""
      if(bool){
        reqData = "perPage=10" + "&pageNo=" + newPageNum + "&masterType=vendor"+"&masterId=" +this.audit_Data[0].masterId
      }else{
        reqData = "perPage=10" + "&pageNo=" + newPageNum + "&masterType=vendor"+"&masterId=" +id
      }
      //"&masterId=" + this.auditTrailItem.uid + 
      this.GETM('getAudits',reqData,(res)=>{
        this.audit_Data=res.data.data.data
        this.AuditTotalListCount = res.data.data.total;
                this.AuditPageCount = res.data.data.total_pages;
                this.AuditPageStartIndex = newPageNum == 1 ? 1 : (newPageNum - 1) * this.AuditItemsPerPage + 1;
      }, () => {}, true)
    },
    formatDates(dateTime) {
      return dateTime ? moment(dateTime).format("DD-MM-YYYY ") : "";
    },
    formatTime(dateTime) {
      return dateTime ? moment(dateTime).format("LT") : "";
    },
    seeModify(item) {
      console.log(item.modification)
      this.seeModifyDialog = true;
       this.modifyItems = item.modification
      //  .map((m, index) => {
      //    let object  = { ...m, srNo: index + 1 };
      //    if(this.dateFields.includes(m.fieldName)){
      //      object.newValue = this.formatDateOnly(m.newValue)
      //      object.oldValue = this.formatDateOnly(m.oldValue)
      //    }
      //    return object;
      //  });
    },
    formatDate(date) {
      if (!date) return "";

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    getLOB() {
        let reqData = '';
        this.showProgress();
        this.GETM("getLOB", reqData, (res) => {
            this.hideProgress();
            if(res.data.statusCode === 200) {
                this.lobOptions = res.data.data[0].lineOfBusiness;
            } else {
                this.lobOptions = [];
                this.showAlert(res.data.msg);
            }
        }, (error) => {
            console.log(error);
            this.hideProgress();
        }, true)
    },
    searchServiceProviderData(num){
        this.main_Table_Data=[]
       
      let reqData ="perPage=" + this.itemsPerPage_Provider + "&pageNo=" + num + "&approveStatus=Pending for approval&modelName=vendor";
      
      if(this.lob || this.vendorId || this.vendorType || this.vendorContact || this.vendorName || this.pinCode ||
      this.state || this.country || this.city || this.district || this.phoneNumber || this.status === true || this.status === false){
       if (this.lob) {
         reqData= reqData + "&lob=" + this.lob;
                 }
       if (this.vendorId) {
         reqData= reqData + "&vendorId=" + this.vendorId;
                 }
       if (this.vendorType) {
         reqData= reqData +"&vendorType=" +this.vendorType;
                 }
       if (this.vendorContact) {
         reqData= reqData +"&vendorContact=" +this.vendorContact;
                 }
                 if (this.vendorName) {
         reqData= reqData +"&vendorName=" +this.vendorName;
                 }
                 if (this.pinCode) {
         reqData= reqData +"&pinCode=" +this.pinCode;
                 }
                 if (this.state) {
         reqData= reqData +"&state=" +this.state;
                 }
                 if (this.country) {
         reqData= reqData +"&country=" +this.country;
                 }
                 if (this.city) {
         reqData= reqData +"&city=" +this.city;
                 }
                 if (this.district) {
         reqData= reqData +"&district=" +this.district;
                 }
                 if (this.phoneNumber) {
         reqData= reqData +"&phoneNumber=" +this.phoneNumber;
                 }
                 if (this.status) {
         reqData= reqData +"&status=" +this.status;
                 }else if(this.status === false){
                   reqData= reqData +"&status=" + this.status
                 }
         this.showSearchChip = true
 
      }else{
         reqData = `pageNo=${num}&perPage=${this.itemsPerPage_Provider}` + "&approveStatus=Pending for approval&modelName=vendor";
         this.showSearchChip = false
      }
        this.GETM("searchFrequentApprove",reqData,(res)=>{
            if(res?.data?.data.data.length>0){
                this.main_Table_Data=res.data.data.data.map((item) => ({
                ...item,
                createdAt: this.formatDateTime(item.createdAt),
              }));
                this.main_Data=res.data.data.data
        this.count=res.data.data.total
        this.totalListCount_Provider = res.data.data.total;
        this.pageStartIndex_Provider = num == 1 ? 1 : (num - 1) * this.itemsPerPage_Provider + 1;
            }else{
                this.main_Table_Data=[]
                this.count=0
        this.totalListCount_Provider = 0
                this.pageStartIndex_Provider=0
            }},(error) => {
          return error;
        },true)
        this.search_Dialog=false
    },
    clear(){
      this.showConfirm(
        "Confirmation",
        "Are you sure you want to Reset?",
        "Yes",
        "No",
        (Yes) => {
          this.lob=[]

      this.vendorId = "",
        this.vendorType = "",
        this.vendorName = "",
        this.vendorContact = "",
        this.pinCode = "",
        this.country = "",
        //this.country_Items = [],
        this.search_State = null,
        this.search_Country=null
        this.state_Items = [],
        this.state = "",

        this.district = "",
        this.district_Items = [],
        this.search_District = null,

        this.city = "",
        this.city_Items = [],
        this.search_City = null,

        this.phoneNumber = ""
        this.status = ""
        }
      );
    },
    onSubmit(){
    if(this.selectedId){
      if(this.reject_Dialog){
        if(!this.$refs?.rejectForm?.validate()){
          return this.showAlert("Please fill mandatory fields.");
        }
      }
      this.ApprovalSubmit()
      }
  
    },
    ApprovalSubmit(){
        let sendData={approveStatus:this.reject_Dialog ? "Rejected" : "Approved",
          rejectionReason:this.reason}
          
        this.PUTM(
          "updateMasterApprove",this.selectedId,
          sendData,
          (res) => {
            this.hideProgress();
            if (res.data && res.data.statusCode == 200) {
              this.showAlert(res?.data?.msg || "Success", "", "", () => {
                this.reject_Dialog=false
                this.approve_Dialog=false
                this.searchServiceProviderData(1)
              });
            } else {
              this.showAlert(res.data.msg);
              this.hideProgress();
            }
          },
          (err) => {
            console.log(err);
            this.hideProgress();
            let msg =
              err?.response?.data?.msg || "Unable to submit Vendor Master Approval data.";
            this.showAlert(msg);
          },true
        );
      },
    formatDateTime(dateTime) {
      return dateTime ? moment(dateTime).format("DD-MM-YYYY LT") : "";
    },
   },
   computed:{
    pageEndIndex() {
      if (!this.audit_Data.length) {
        return 0;
      } else {
        return this.pageStartIndex + this.itemsPerPage <= this.totalListCount ? this.pageStartIndex + this.itemsPerPage - 1 : this.totalListCount;
      }
    },
    computedlicenseExpiryDate(){
      return this.formatDate(this.licenseExpiryDate);
    },
    computedPAN() {
            this.panNumber = this.panNumber ? this.panNumber.toUpperCase() : '';
            return this.panNumber;
        },
    getEndDate() {
     var endDate = new Date();
     return endDate.toISOString().slice(0,10)
    },
    pageCount_Provider() {
      if (!this.totalListCount_Provider) {
        return 0;
      } else {
        return Math.ceil(this.totalListCount_Provider / this.itemsPerPage_Provider);
      }
    },
    pageEndIndex_Provider() {
      if (!this.main_Table_Data.length) {
        return 0;
      } else {
        return this.pageStartIndex_Provider + this.itemsPerPage_Provider <= this.totalListCount_Provider ? this.pageStartIndex_Provider + this.itemsPerPage_Provider - 1 : this.totalListCount_Provider;
      }
    },
    AuditPageEndIndex() {
      if (!this.audit_Data.length) {
        return 0
      } else {
        return this.AuditPageStartIndex + this.AuditItemsPerPage <=
          this.AuditTotalListCount
          ? this.AuditPageStartIndex + this.AuditItemsPerPage - 1
          : this.AuditTotalListCount
      }
    },
   },
   created(){
    this.$store.commit("SAVE_PAGE_HEADER", "Vendor Master Approval Search");
    let num=1
    let reqData=`pageNo=1&perPage=10&approveStatus=Pending for approval&modelName=vendor`
    this.GETM("searchFrequentApprove",reqData,(res)=>{
      if(res?.data?.data.data.length>0){
        this.main_Table_Data=res.data.data.data.map((item) => ({
                ...item,
                createdAt: this.formatDateTime(item.createdAt),
              }));
        this.main_Data=res.data.data.data
        // console.log(this.main_Table_Data)
        this.count=res.data.data.total
        this.count=res.data.data.total
        this.totalListCount_Provider = res.data.data.total;
        this.pageStartIndex_Provider = num == 1 ? 1 : (num - 1) * this.itemsPerPage_Provider + 1;
      }else{
        this.main_Table_Data=[]
                this.count=0
        this.totalListCount_Provider = 0
                this.pageStartIndex_Provider=0
      }
    },(error) => {
          return error;
        },true)
        this.get_countrys(false)
        this.getLOB()
   }
}
</script>

<style scoped>
.btnToggle {
  text-transform: none;
  color: #fff;
  background-color: #1ebbd9;
}
.form-groupss{
    max-width:200px;
    margin:0px 10px 0px 0px
}
.reset {
        background-color: white;
        border:1px solid #D1121B;
        color:#D1121B;
    }
.createbtn {
        text-transform: capitalize;
        letter-spacing: 0.4px;
        /* font-weight: 300;
        font-size: 13px !important; */
    }
.cancelbtn {
    background-color: white;
        border:1px solid #D1121B;
        color:#D1121B;
    }
    .tableicons {
  color: #23b1a9 !important;
}
.tablebtn {
  background: #ffffff 0% 0% no-repeat padding-box !important;
  box-shadow: inset 0px 2px 4px #00000052 !important;
  border: 0.5px solid #c1c8cc;
  border-radius: 18px !important;
  height: 30px !important;
  text-transform: capitalize !important;
  width: 100%;
  justify-content: space-between;
  font-size: small;
}

</style>