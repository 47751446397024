 <template>
    <v-app>
        <v-card  tile>
            <div class="ma-4">
                <v-form v-model="valid" ref="netform">
                    <v-layout class="mt-2 mb-2" style="background-color: #FFFFFF;">
                        <v-flex xs3>
                            <v-layout column class="cust_pr mx-3" >
                                <p class="font-weight-bold cstm-clr-pr">Provider Name * </p>
                                <v-autocomplete
                                    v-model="searchTarrProvName"
                                    :items="searchedItems"
                                    :loading="isLoading"
                                    :search-input.sync="searchProviderName"
                                    color="white"
                                    cache-items
                                    label="Provider Name..."
                                    class="rounded-0"
                                    return-object
                                    solo
                                    :rules="[() => !!searchTarrProvName || 'This field is required']">
                                    <div slot="append" style="background-color:#F7F7F7;padding:7px;cursor:pointer;position:absolute;top:-3px;right:-12px"><v-icon>search</v-icon></div>
                                     <!-- @click="getProviderDetails" -->
                                </v-autocomplete>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3 >
                            <v-layout column class="cust_pr mx-3 ">
                                <p class="font-weight-bold cstm-clr-pr">Tariff ID</p>
                                <v-text-field
                                    solo
                                    label="Enter..."
                                    v-model="searchTarrID"
                                    class="rounded-0"
                                    maxlength="100"
                                    :rules="rules.tarrifIdValid">
                                    <!-- Enter valid tariff ID” to be thrown -->
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3>
                            <v-layout column class="cust_pr mx-3 cust-rmvpd-pr">
                                <v-text class="font-weight-bold cstm-clr-pr">Tariff Effective date From</v-text>
                                <v-menu
                                    ref="calender"
                                    :close-on-content-click="false"
                                    v-model="searchTarrEffectDateMenuFrom"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    max-width="290px"
                                    min-width="290px" 
                                    offset-y
                                    class="rounded-0">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            slot="activator"
                                            label="Select.."
                                            :value="computedTarrEffectDateFrom"
                                            solo
                                            class="rounded-0"
                                            readonly 
                                            maxlength="10"
                                            v-bind="attrs"
                                            :rules="rules.tarrifEffectDateValidFrom"
                                            v-on="on">
                                        <div  slot="prepend-inner" style="background-color:#F7F7F7;padding:6px;"><span class="material-icons">date_range</span></div></v-text-field>
                                    </template>
                                    <v-date-picker @input="searchTarrEffectDateMenuFrom = false" @change="changeEffectDate(searchTarrEffectDateFrom,'From')" v-model="searchTarrEffectDateFrom" no-title></v-date-picker>
                                </v-menu>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3>
                            <v-layout column class="cust_pr mx-3 cust-rmvpd-pr">
                                <v-text class="font-weight-bold cstm-clr-pr">Tariff Effective date To</v-text>
                                <v-menu
                                    ref="calender"
                                    :close-on-content-click="false"
                                    v-model="searchTarrEffectDateMenuTo"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    max-width="290px"
                                    min-width="290px" 
                                    class="rounded-0">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            slot="activator"
                                            label="Select.."
                                            :value="computedTarrEffectDateTo"
                                            solo
                                            class="rounded-0"
                                            readonly 
                                            maxlength="10"
                                            v-bind="attrs"
                                            :rules="rules.tarrifEffectDateValidTo"
                                            v-on="on">
                                            <!-- this.searchTarrEffectDateFrom > this.searchTarrEffectDateTo -->
                                        <div  slot="prepend-inner" style="background-color:#F7F7F7;padding:6px;"><span class="material-icons">date_range</span></div></v-text-field>
                                    </template>
                                    <v-date-picker @input="searchTarrEffectDateMenuTo = false" @change="changeEffectDate(searchTarrEffectDateTo,'To')"  v-model="searchTarrEffectDateTo" no-title></v-date-picker>
                                </v-menu>
                            </v-layout>
                        </v-flex>
                        
                    </v-layout>

                    <v-layout class="mb-2" style="background-color: #FFFFFF;">
                        <v-flex xs3>
                            <v-layout column class="cust_pr mx-3 cust-rmvpd-pr">
                                <v-text class="font-weight-bold cstm-clr-pr">Tariff Expiration Date From</v-text>
                                <v-menu
                                    ref="calender"
                                    :close-on-content-click="false"
                                    v-model="searchTarrExpireDateMenuFrom"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    max-width="290px"
                                    min-width="290px" 
                                    class="rounded-0">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            slot="activator"
                                            label="Select.."
                                            :value="computedTarrExpireDateFrom"
                                            solo
                                            class="rounded-0"
                                            readonly 
                                            maxlength="10"
                                            v-bind="attrs"
                                            :rules="rules.tarrifExpireDateValidFrom"
                                            v-on="on">
                                        <div  slot="prepend-inner" style="background-color:#F7F7F7;padding:6px;"><span class="material-icons">date_range</span></div></v-text-field>
                                    </template>
                                    <v-date-picker @input="searchTarrExpireDateMenuFrom = false" @change="changeExpirDate(searchTarrExpireDateFrom,'From')" v-model="searchTarrExpireDateFrom" no-title></v-date-picker>
                                </v-menu>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3>
                            <v-layout column class="cust_pr mx-3 cust-rmvpd-pr">
                                <v-text class="font-weight-bold cstm-clr-pr">Tariff Expiration Date To</v-text>
                                <v-menu
                                    ref="calender"
                                    :close-on-content-click="false"
                                    v-model="searchTarrExpireDateMenuTo"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    max-width="290px"
                                    min-width="290px" 
                                    class="rounded-0">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            slot="activator"
                                            label="Select.."
                                            :value="computedTarrExpireDateTo"
                                            solo
                                            class="rounded-0"
                                            readonly 
                                            maxlength="10"
                                            :rules="rules.tarrifExpireDateValidTo"
                                            v-bind="attrs"
                                            v-on="on">
                                        <div  slot="prepend-inner" style="background-color:#F7F7F7;padding:6px;"><span class="material-icons">date_range</span></div></v-text-field>
                                    </template>
                                    <v-date-picker @input="searchTarrExpireDateMenuTo = false" @change="changeExpirDate(createTarrExpireDateTo,'To')" v-model="createTarrExpireDateTo" no-title></v-date-picker>
                                </v-menu>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3 >
                            <v-layout column class="cust_pr mx-3 ">
                                <p class="font-weight-bold cstm-clr-pr">Network</p>
                                <v-select
                                    :items="networkListArray"
                                    label="Select"
                                    v-model="searchTarrNetwrk"
                                    item-text="text"
                                    item-value="value"
                                    solo
                                    maxlength="20"
                                    class="rounded-0">
                                </v-select>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3 >
                            <v-layout column class="cust_pr mx-3 ">
                                <p class="font-weight-bold cstm-clr-pr">Tariff Status</p>
                                <v-select
                                    :items="tarrifStatusArray"
                                    label="Select"
                                    item-text="text"
                                    item-value="value"
                                    v-model="searchTarrStatus"
                                    solo
                                    maxlength="20"
                                    class="rounded-0">
                                </v-select>
                            </v-layout>
                        </v-flex>
                        
                    </v-layout>
                </v-form>
            </div>

            <div class="" style="border:0.5px solid #C1C8CC;">
                <v-layout  style="padding:25px;background-color:#F7F7F7;align-items:center; justify-center; justify-content: flex-end;">
                    <v-btn dark  tile color="#10242b" class="text-capitalize px-3 mx-3" @click="createNewTarrif()" small><v-icon dark small>add_circle</v-icon> <v-divider vertical class="mx-2"></v-divider>Create New tariff</v-btn>
                    <v-btn dark  tile color="#23B1A9" class="text-capitalize px-3 mx-3" @click="searchData()" small><v-icon dark small>search</v-icon> <v-divider vertical class="mx-2"></v-divider>Search</v-btn>
                </v-layout>
            </div>
        </v-card> 

        <div class="disp-flex align-center pa-4" style="background-color:#F7F7F7;flex-direction:row;justify-content: space-between;">
            <div class="disp-flex" style="justify-content:center">
                <p class="font-weight-bold">SEARCH RESULTS</p>
            </div>
            <div class="disp-flex" style="justify-content:center">
                <v-layout  >
                    <v-btn dark @click="excel_upload_tariff" tile color="#23B1A9" class="text-capitalize px-3 mx-3" small><v-icon dark small>mdi-file-excel-box</v-icon> <v-divider vertical class="mx-2"></v-divider>Excel Upload</v-btn>
                    <v-btn dark @click="view_tariff"  tile color="#23B1A9" class="text-capitalize px-3 mx-3" small><v-icon dark small>visibility</v-icon> <v-divider vertical class="mx-2"></v-divider>View</v-btn>
                    <v-btn dark @click="onEditTariff" tile color="#23B1A9" class="text-capitalize px-3 mx-3" small><v-icon dark small>create</v-icon> <v-divider vertical class="mx-2"></v-divider>Edit</v-btn>
                    <v-btn @click="onCloneTariff" tile color="#ffff" class="text-capitalize px-3 mx-3" small><v-icon dark small>tonality</v-icon> <v-divider vertical class="mx-2"></v-divider>Clone</v-btn>
                    <v-btn @click="onRenewTariff" dark  tile color="#e46a25" class="text-capitalize px-3 mx-3" small><v-icon dark small>update</v-icon> <v-divider vertical class="mx-2"></v-divider>Renew</v-btn>
                </v-layout>
            </div>
        </div>
        <v-divider></v-divider>
        <v-card class="mb-3" tile>
            <v-data-table
                :headers="headers"
                :items="tableData"
                hide-default-footer
                v-model="selectTableData"
                :single-select="true"
                item-key="tariffId"
                @input="tarriffTableData(selectTableData)"
                show-select
                disable-pagination>

                <template v-slot:item.data-table-select="{isSelected,select}">
                    <v-simple-checkbox color="#23B1A9" :ripple="false" :value="isSelected" @input="select($event)"></v-simple-checkbox>
                </template>
                <template  v-slot:item.tariffId={item}>
                    <tr ><td style="color:#e46a25"  class="font-weight-bold" >{{ item.tariffId}} </td></tr>
                </template>
                <template  v-slot:item.tariffEffectiveDate={item}>
                    <tr ><td>{{ new Date(item.tariffEffectiveDate).toLocaleDateString() }} </td></tr>
                </template>
                <template  v-slot:item.tariffExpirationDate={item}>
                    <tr ><td>{{ new Date(item.tariffExpirationDate).toLocaleDateString() }} </td></tr>
                </template>
               
            </v-data-table>
            <v-divider></v-divider>
            <v-layout row justify-end align-center style="background-color:#f7f7f7" class="pa-2">
                <span class="mr-1 font-weight-bold body-1" style="color:#3C3D3D; font-size:12px !important">
                <span class="" style="color:#E46A25;font-size:12px;">Showing {{fval}}-{{sval}} </span>out of {{totalLen}} records</span>
                <v-divider  class="ma-2" vertical ></v-divider>
                <v-pagination
                    class="pdct-stUp-bnkAcc-pgn"
                    v-model="pageTarrif"
                    color="#152F38"
                    flat
                    @input="nextPageData(pageTarrif)"
                    :length="pagelength"
                ></v-pagination>
            </v-layout>
        </v-card>
    </v-app>
</template>
<script>
import moment from 'moment';


export default {

    components:{
      
    },

    created() {
        this.$store.commit('ENABLE_VIEW', false);
        this.$store.commit("SAVE_PAGE_HEADER", "Tariff Search");
        this.getAllNetwork()
        this.getAllTarrifData(1)
    },

    computed: {
        computedTarrEffectDateFrom(){
            return this.searchTarrEffectDateFrom ? moment(this.searchTarrEffectDateFrom).format("DD-MM-YYYY"): ''
        },
        computedTarrEffectDateTo(){
            return this.searchTarrEffectDateTo ? moment(this.searchTarrEffectDateTo).format("DD-MM-YYYY"): ''
        },
        computedTarrExpireDateFrom(){
            return this.searchTarrExpireDateFrom ? moment(this.searchTarrExpireDateFrom).format("DD-MM-YYYY"): ''
        },
        computedTarrExpireDateTo(){
            return this.createTarrExpireDateTo ? moment(this.createTarrExpireDateTo).format("DD-MM-YYYY"): ''
        },
        searchedItems () {
            return this.searchEntries;
        },
        validDateChk(){
            let todayDate = Date.now();
            let Todate= moment(this.netenddate,'YYYY-MM-DD ').toDate().getTime()
            console.log(todayDate,"todayDate")
            let CurrentDate= todayDate

            if(Todate < CurrentDate){
                return this.planStatus="Inactive"
            }else{
                return this.planStatus="Active"
            }
        }

    },

    data() {
        return {
            // cloneCommit: false,
            pageTarrif: 1,
            pagelength:0,
            fval:0,
            sval:0,
            totalLen:0,
            swap_final_count:false,
            typesearch:false,
            searchDataArray:[],
            tarrifStatusArray:[
                {text:'Active',value:'Active'},
                {text:'In Progress',value:'In Progress'},
                {text:'Expired',value:'Expired'},
                {text:'Pending Approval',value:'Pending Approval'},
            ],
            searchEntries: [],
            networkListArray:[],
            isLoading: false,
            searchProviderName: null,
            selectTableData: [],
            headers:[
                {text: 'Tariff ID', value: 'tariffId'},
                {text: 'Tariff Name', value: 'tariffName'},
                {text: 'Provider Name', value: 'providerName'},
                {text: 'Tariff Status', value: 'status'},
                {text: 'Effective Date', value: 'tariffEffectiveDate'},
                {text: 'Expiration Date', value: 'tariffExpirationDate'},
            ],
            tableData:[],
            tableData:[],
            searchTarrProvName:'',                        
            searchTarrID:'',                        
            searchTarrEffectDateMenuFrom:false,                        
            searchTarrEffectDateFrom:'',                        
            searchTarrEffectDateMenuTo:false,                        
            searchTarrEffectDateTo:'', 
            searchTarrExpireDateMenuFrom:false, 
            searchTarrExpireDateFrom:'', 
            searchTarrExpireDateMenuTo:false, 
            createTarrExpireDateTo:'', 
            searchTarrNetwrk:'', 
            searchTarrStatus:'', 
            rules: {
                required: value => !!value || 'This Field is Required.',
                tarrifIdValid:[
                    v => !!v || '',
                    v => /^[0-9 ]+$/.test(v) || 'Enter valid tariff ID',
                    v => v.length == 6 || 'Enter valid tariff ID',
                ],
                tarrifEffectDateValidTo:[],
                tarrifEffectDateValidFrom:[],
                tarrifExpireDateValidTo:[],
                tarrifExpireDateValidFrom:[],
                
                minExtRefNo: value => {
                    // const pattern = /^([0-9])+$/
                    const pattern = /^[a-zA-Z0-9]+$/
                    return pattern.test(value) || 'This Field is Required.'
                },
            },
        }
    },

    methods: {

        view_tariff(){
            if(this.selectTableData.length != 0){
                    // this.$router.push({name: 'CreateTariffPage', params: {editDetails: this.$store.state.createtarrifDetails, editTariff: 'EDIT_TARIFF_DETAILS'}});
               this.$router.push({name: 'TarrifConfigTabs', params: {from:'view'}});
                //          console.log("what is from in params -> ", this.$route.params.from);
                // if(this.$route.params.from == 'view'){
                this.$store.commit('ENABLE_VIEW',true);
                // }
           
            }else{
                return this.showToast('No tariff was selected',this.TOST().WARNING)
            }
        },

        excel_upload_tariff(){
            if(this.selectTableData.length != 0){
            // console.log(this.$store.state.createtarrifDetails);
                if(this.$store.state.createtarrifDetails.status !== 'Expired'){
                    // this.$router.push('/tarrifbulkupload');
                    this.$router.push({name: 'ExcelBulkUploadTarrif', params: {from:'excel_upload'}});
                }else{
                    this.showToast("Expired Tariff not allowed to Upload" ,this.TOST().WARNING); 
                } 
                 
            }
            else{
                 this.showToast('No tariff was selected',this.TOST().WARNING);
            }  
        },

        onCloneTariff(){
            if(this.selectTableData.length != 0){
            console.log(this.$store.state.createtarrifDetails);
                
                 this.$router.push({name: 'CreateTariffPage', params: {cloneDetails: this.$store.state.createtarrifDetails, cloneTariff: 'CLONE_TARIFF_DETAILS'}});
            }
            else{
                 this.showToast('No tariff was selected',this.TOST().WARNING);
            }  
        },

        onRenewTariff(){
            if(this.selectTableData.length != 0){
                if(this.$store.state.createtarrifDetails.status == 'Expired' || this.$store.state.createtarrifDetails.status == 'Active'){
                    this.$router.push({name: 'CreateTariffPage', params: {renewDetails: this.$store.state.createtarrifDetails, renewTariff: 'RENEW_TARIFF_DETAILS'}});
                }else{
                    this.showToast("Only Active & Expired Tariff can be Renewed" ,this.TOST().WARNING); 
                }  

            }else{
               this.showToast('No tariff was selected',this.TOST().WARNING)
            }  
            
        },

        onEditTariff(){
            this.$store.commit('ENABLE_VIEW',false);
            if(this.selectTableData.length != 0){
                if(this.$store.state.createtarrifDetails.status !== 'Expired'){
                    this.$router.push({name: 'CreateTariffPage', params: {editDetails: this.$store.state.createtarrifDetails, editTariff: 'EDIT_TARIFF_DETAILS'}});
                }else{
                    this.showToast("Expired Tariff not allowed to Edit" ,this.TOST().WARNING); 
                }
                
            }else{
                 this.showToast('No tariff was selected',this.TOST().WARNING)
            }
        },

        nextPageData(indata){
            this.pagelength == indata ? this.swap_final_count = true : this.swap_final_count = false
            this.typesearch == false  ? this.getAllTarrifData(this.pageTarrif) : this.searchData()
        },

        getAllTarrifData(skip){
            let self = this
            this.tableData = []
            this.GET(2,'tariff/get-tariff-list?perPage=20&pageNo=' + skip,(res,error)=>{
                if(!error){
                    if(res.data.statusCode === 200){
                        self.tableData = res.data.data.data
                        self.pagelength = res.data.data.total_pages
                        self.totalLen = res.data.data.total
                        self.provTblData = []

                        var less_enough = self.totalLen
                        var checkless_init = false
                        less_enough < 20 ? checkless_init = false : checkless_init = true

                        // if less than 15 we have second value same as total value as no pagination will occur
                        if(checkless_init){
                            // checkinit is true means the final count is more than 15 (- 1)
                            var traverse =  20 * self.pageTarrif
                            self.fval = traverse - 19
                            self.swap_final_count ? self.sval =  self.totalLen : self.sval = traverse
                        }else if(self.totalLen == 0){
                            self.sval = self.fval = 0
                        
                        }else{
                            self.fval = 1
                            self.sval = self.totalLen
                        }

                    }else{
                        this.showToast(res.data.msg ,this.TOST().ERROR); 
                    }
                }else{
                    this.showToast(error.response.data.msg, this.TOST().WARNING);
                }
            })
        },
        getAllNetwork(){
            this.GET(4,'network-management/get-all-Network-Details',(res,error)=>{
                // console.log("=========((((((((([[[getAllNetwork]]])))))))))=======>>",res)
                if(!error){
                    if(res.data.statusCode === 200){
                        // this.showToast(res.data.msg, this.TOST().SUCCESS);
                        let netwrkResp = res.data.data.data
                        this.networkListArray = []
                        for(let _data of netwrkResp){
                            let _netwrk = {
                                text:_data.networkName,
                                value:_data.networkId,
                                _id:_data._id,
                            }
                            this.networkListArray.push(_netwrk)
                        }

                    }else{
                        this.showToast(res.data.msg ,this.TOST().ERROR); 
                    }
                }else{
                    // this.showToast(error.response.data.msg, this.TOST().WARNING);
                }
            })
        },
        tarriffTableData(ev){
            // console.log("=========(((((((((tarriffTableData)))))))))=======>>",ev[0])
            // this.$store.commit('SEARCH_TARRIF_DATA',ev)
            this.$store.commit("SAVE_DETAILS", ev[0]);

        },
        changeExpirDate(ev,type){
            if(type == 'To'){
                if(this.searchTarrExpireDateFrom == ''){
                    this.showToast("Please Select Tarrif Expiration date From First",this.TOST().ERROR);
                    this.createTarrExpireDateTo = ''
                    return
                }
                // this.searchTarrExpireDateFrom > this.createTarrExpireDateTo ? this.rules.tarrifExpireDateValidTo = ['To Date must be greater than From Date'] : this.rules.tarrifExpireDateValidTo = []
                if(this.searchTarrExpireDateFrom > this.createTarrExpireDateTo){
                    this.rules.tarrifExpireDateValidTo = ['To Date must be greater than From Date']
                }else{
                    this.rules.tarrifExpireDateValidFrom = []
                    this.rules.tarrifExpireDateValidTo = []
                }
            }else{
                if(this.searchTarrEffectDateTo >= this.searchTarrExpireDateFrom){
                    this.showToast("Tarrif Expiration date must be greater than Tarrif Effective date",this.TOST().ERROR);
                    this.searchTarrExpireDateFrom = ''
                    return
                }

                if(this.createTarrExpireDateTo == ''){
                    this.rules.tarrifEffectDateValidFrom = []
                }else if(this.searchTarrExpireDateFrom > this.createTarrExpireDateTo){
                    this.rules.tarrifExpireDateValidFrom = ['From Date must be smaller than To Date']
                }else{
                    this.rules.tarrifExpireDateValidFrom = []
                    this.rules.tarrifExpireDateValidTo = []
                }
                // this.createTarrExpireDateTo == '' ? this.rules.tarrifExpireDateValidFrom = [] : 
                // this.searchTarrExpireDateFrom > this.createTarrExpireDateTo ? this.rules.tarrifExpireDateValidFrom = ['From Date must be smaller than To Date'] : this.rules.tarrifExpireDateValidFrom = []
            }
        },
        changeEffectDate(ev,type){
            if(type == 'To'){
                if(this.searchTarrEffectDateFrom == ''){
                    this.showToast("Please Select Tarrif Effective date From First",this.TOST().ERROR);
                    this.searchTarrEffectDateTo = ''
                    return
                }
                // this.searchTarrEffectDateFrom > this.searchTarrEffectDateTo ? this.rules.tarrifEffectDateValidTo = ['To Date must be greater than From Date'] : this.rules.tarrifEffectDateValidTo = []
                if(this.searchTarrEffectDateFrom > this.searchTarrEffectDateTo){
                    this.rules.tarrifEffectDateValidTo = ['To Date must be greater than From Date']
                }else{
                    this.rules.tarrifEffectDateValidFrom = []
                    this.rules.tarrifEffectDateValidTo = []
                }
            }else{
                if(this.searchTarrEffectDateTo == ''){
                    this.rules.tarrifEffectDateValidFrom = []
                }else if(this.searchTarrEffectDateFrom > this.searchTarrEffectDateTo){
                    this.rules.tarrifEffectDateValidFrom = ['From Date must be smaller than To Date']
                }else{
                    this.rules.tarrifEffectDateValidFrom = []
                    this.rules.tarrifEffectDateValidTo = []
                }
                // this.searchTarrEffectDateTo == '' ? this.rules.tarrifEffectDateValidFrom = [] : 
                // this.searchTarrEffectDateFrom > this.searchTarrEffectDateTo ? this.rules.tarrifEffectDateValidFrom = ['From Date must be smaller than To Date'] : this.rules.tarrifEffectDateValidFrom = []
            }
            
        },

        getProviderDetails(){
            let _providerData = {}
           
            for(let _data of this.searchDataArray){
                if(this.searchTarrProvName == _data.entityName || this.searchTarrProvName == _data.firstName){
                    _providerData = _data
                }
            }
            
            this.GET(2,'empanelment/empanelment-provider-by-id/' + _providerData._id,(res,err) => {
                if(!err){
                    let _resp = res.data.data.provider
                    let _empanel = res.data.data.empanelment
                    this.$store.commit('EMPANEL_PROVIDER_DATA',res.data.data)
                    // this.searchEntries.push(this.provName)
                    this.searchTarrProvName = this.provName
                    let empanelData = this.$store.state.empanelProviderData.empanelment
                    // this.empanelStoreData = empanelData !== null ? empanelData : ''
                    
                }else{
                    // this.showToast(res.data.msg ,this.TOST().ERROR);
                }   
            });
            
        },
        createNewTarrif(){
            this.$router.push('/CreateTariffPage')
        },

        searchData(){
            let self = this
            this.pageInsti = 1
            this.pagelength = 0
            this.fval = 0
            this.sval = 0
            this.totalLen = 0
            this.tableData = []

            if(this.searchTarrProvName === "" ){
                this.showToast('Please fill all empty fields', this.TOST().WARNING);
                this.$refs.netform.validate();
                return
            } 
          
            if(this.rules.tarrifEffectDateValidFrom.length > 0  || this.rules.tarrifExpireDateValidFrom.length > 0 || this.rules.tarrifEffectDateValidTo.length > 0  || this.rules.tarrifExpireDateValidTo.length > 0){
                this.showToast('Please fill Correct data', this.TOST().WARNING);
                return;
            }

            let API_HEADER = 'tariff/search-tariff?providerName='+ self.searchTarrProvName +'&tariffId='+ self.searchTarrID +'&tariffEffectiveFromDate=' + self.searchTarrEffectDateFrom +
                        '&tariffEffectiveToDate=' + self.searchTarrEffectDateTo +'&tariffExpirationFromDate=' + self.searchTarrExpireDateFrom +'&tariffExpirationToDate=' + self.createTarrExpireDateTo +
                        '&providerNetwork='+ self.searchTarrNetwrk +'&tariffStatus=' + self.searchTarrStatus
            this.GET(2,API_HEADER,(res,error)=>{
                if(!error){
                    if(res.data.statusCode === 200){
                        
                        this.showToast(res.data.msg, this.TOST().SUCCESS);
                        this.tableData = res.data.data

                    }else{
                        this.showToast(res.data.msg ,this.TOST().ERROR); 
                    }
                }else{
                    this.showToast(error.response.data.msg, this.TOST().WARNING);
                }
            })
        },

    },

    watch: {
        searchProviderName(val) {
            if (this.searchedItems.length > 0) return
            // Items have already been requested
            if (this.isLoading) return

            this.isLoading = true
            let provider = this.searchTarrProvName;
            this.GET(2,'provider/search-providers-by-name/'+val,( res,err) => {
                if(!err){
                    this.searchDataArray = res.data.data.data
                    console.log(res.data.data.data);
                    const providers = []
                    for(let provider of res.data.data.data){
                        // console.log(provider.empanelmentProvider);
                        if(provider.registeredEntity === "Institution"){
                            providers.push(provider.entityName)
                        }else if(provider.registeredEntity === "Individual"){
                            providers.push(provider.firstName)
                        }
                            
                    }
                    this.searchEntries = providers
                }else{
                    this.showToast(err.response.data.msg ,this.TOST().ERROR);
                }
            });
            this.isLoading = false

        },
    },
    
    
}

</script>
<style >
.activeredbackground{
  color:white;
}

.v-btn__content { width: 100% !important; white-space: normal !important; font-size:13px !important}

.srSelected{
  background-color: #23B1A9 !important;
  color:#FFFFFF !important;
}
.disable-field-style{
    border:1px solid #0014001f;
    padding:6px;
    background-color:#f7f7f7
}
.disp-flex{
    display: flex;
}
/* .align-center{
    align-items:center;
} */

</style>