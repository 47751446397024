<template>
    <div class="mx-8 my-6">
        <v-row class="mb-2 ml-0 mr-0">
            <h3> Search for Work Item</h3>
        </v-row>


        <v-layout class="mb-0">
            <v-row>

                <v-col>
                    <label class="input-label">Type Of Vendor</label>
                    <v-select flat class="filter-select-box" outlined rule placeholder="Select" dense solo :items="vendors"
                        v-model="filterTypeOfVendor"></v-select>
                </v-col>

                <v-col>
                    <label class="input-label">Vendor Name</label>
                    <v-text-field flat class="filter-select-box" placeholder="Enter" outlined solo dense
                        v-model="filterVendorName" depressed />
                </v-col>

                <v-col>
                    <label class="input-label">PAN Number</label>
                    <v-text-field flat class="filter-select-box" placeholder="Enter" outlined solo dense
                        v-model="filterPanNumber" depressed :rules="[(v) => matchRegex(v, 'alphanum')]" maxlength="10" />
                </v-col>

                <v-col>
                    <label class="input-label">Status</label>
                    <v-select flat class="filter-select-box" rule placeholder="Select" dense solo outlined
                        :items="allStatusEnum" v-model="filterStatus"></v-select>
                </v-col>

            </v-row>
        </v-layout>

        <v-layout class="mb-0">

            <v-row>
                <v-col>
                    <v-btn class="btn" dense solo outlined color="red" @click="resetFilter">
                        <v-icon> mdi-backspace </v-icon>
                        <v-divider vertical class="mx-2 red"></v-divider>
                        Clear
                    </v-btn>
                </v-col>
                <v-col>
                    <div class="flex pull-right">

                        <v-btn class="white--text font-size-10 ml-0" color="#009688" dense solo @click="searchWorkItem">
                            <v-icon>mdi-arrow-right-circle</v-icon>
                            <v-divider vertical class="mx-2 ml-2 white"></v-divider>
                            Search
                        </v-btn>

                        <v-btn class="btnToggle orange-background font-size-10 ml-4" dense solo @click="downloadReport">
                            <v-icon>mdi-cloud-download</v-icon>
                            <v-divider vertical class="mx-2 ml-2 white"></v-divider>
                            Download
                        </v-btn>

                    </div>

                </v-col>
            </v-row>
        </v-layout>

        <v-row class="mx-0">
            <v-col class="px-0">
                <h4 class="mr-3">
                    Work Item
                    <span class="orange--text">({{ tableFilteredData.length }})</span>
                </h4>
            </v-col>
        </v-row>


        <v-data-table :headers="tableHeaders" no-data-text="No Records" fixed-header :items="tableFilteredData"
            hide-default-footer class="vDataTableStyling">

            <template #item="{ item }">


                <tr>
                    <td>{{ item.typeOfVendor }}</td>

                    <td>
                        {{ item.vendorName }}
                    </td>

                    <td>{{ item.panNumber }}</td>

                    <td>
                        <span class="textTransformCapitalize bold" :class="statusTextColor(item.status)">
                            {{ item.status }}
                        </span>
                    </td>

                    <td>

                        <!-- <v-btn plain class="bold" color="#23b1a9" v-if="item.status === 'New'">
                            Accept
                        </v-btn> -->

                        <v-btn class="bold" plain color="#ee0914" v-if="item.status === 'New'"
                            @click="openRemarkModal(item)">
                            Reject
                        </v-btn>

                    </td>
                </tr>

            </template>

        </v-data-table>

        <div class="text-center graybackground pdct-stUp-bnkAcc-pgn mt-3 mx-4" v-if="tableFilteredData.length > 0">

            <v-layout row justify-end align-center class="mx-2 mt-5"
                style="margin-left: 0px !important; margin-right: 0px !important">
                <p class="mr-1 font-weight-bold body-1 mt-4" style="color: #3c3d3d; font-size: 12px !important">
                    <span class="" style="color: #e46a25; font-size: 12px">
                        Showing {{ currentShowingRecordsStartIndex }}-{{ lastRecordLength }}
                    </span>out of {{ totalRecords }} records
                </p>
                <v-divider class="mx-2" vertical></v-divider>
                <v-pagination class="cust-bnkAcc-pgn" v-model="currentPage" color="#152F38" flat :total-visible="10"
                    :length="totalPages" @input="fetchWorkItems"></v-pagination>
            </v-layout>
        </div>


        <v-dialog v-model="isRemarkModalOpen" width="auto">
            <v-card class="w-500">
                <v-toolbar dark color="#23b1a9">
                    <v-toolbar-title class="text-subtitle-1 bold">
                        Reject
                    </v-toolbar-title>

                    <v-spacer />

                    <v-btn icon @click="closeRemarkModal">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>

                <div class="p-5 bg-grey">

                    <span class="text-subtitle-2">Remarks</span>
                    <v-textarea outlined full-width class="filter-select-box no-resize" dense
                        :rules="[(v) => requiredField(v)]" no-resize height="80" placeholder="Enter Remarks"
                        v-model="remarks">
                    </v-textarea>

                </div>

                <v-card-actions class="d-flex flex-row-reverse">
                    <v-btn elevation="1" color="red" class="ml-2 white--text" :disabled="ifNotRemark()"
                        @click="handleRejection">Reject</v-btn>
                    <v-btn elevation="1" outlined @click="closeRemarkModal"> Cancel </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </div>
</template>

<script>
import moment from "moment";

export default {
    data() {
        return {
            filterPanNumber: '',
            filterVendorName: '',
            filterTypeOfVendor: '',
            filterStatus: '',
            allStatusEnum: ["Approved", "Rejected", "Pending for approval", "New"],
            vendors: ['Hospital', 'Service Provider', "Repairer", 'Vendor'],
            tableHeaders: [
                { text: 'Vendor Type', align: 'left', value: 'vendorType', sortable: false },
                { text: 'Vendor Name', align: 'left', value: 'vendorName', sortable: false },
                { text: 'PAN number', align: 'left', value: 'panNumber', sortable: false },
                { text: 'Status', align: 'left', value: 'status', sortable: false },
                { text: 'Action', align: 'left', value: 'actionEnabled', sortable: false },
            ],
            uploadList: [],
            tableFilteredData: [],
            remarks: '',
            rejectedItem: {},
            isRemarkModalOpen: false,
            // Pagination variables
            pageSize: 10,
            currentPage: 1,
            totalPages: 1,
            currentShowingRecordsStartIndex: 0,
        }
    },
    computed: {
        lastRecordLength() {
            if (this.tableFilteredData.length < 10) {
                return this.currentShowingRecordsStartIndex + this.tableFilteredData.length;
            }
            return this.currentShowingRecordsStartIndex + this.pageSize;
        },
    },
    methods: {
        statusTextColor(status = '') {
            return {
                'new': 'approvedTextColor',
                'old': 'mandatorycolor',
            }[status.toLowerCase()] || '';
        },
        formattedDate(date) {
            return moment(new Date(date)).format('DD/MM/YYYY hh:mm a');
        },
        switchToConcessionalTdsUpload() {
            this.$router.push('master/concessional-tds-master');
        },
        resetFilter() {
            this.filterStatus = '';
            this.filterPanNumber = '';
            this.filterVendorName = '';
            this.filterTypeOfVendor = '';
            this.currentPage = 1;
            this.fetchWorkItems();
        },
        getQueryParams(download = false) {
            const queryParamsObject = {
                download,
                status: this.filterStatus,
                panNumber: this.filterPanNumber,
                vendorName: this.filterVendorName,
                typeOfVendor: this.filterTypeOfVendor,
                pageNumber: this.currentPage,
                pageSize: this.pageSize,
            };

            const params = new URLSearchParams(queryParamsObject);

            return params.toString();
        },
        openRemarkModal(item) {
            this.isRemarkModalOpen = true;
            this.rejectedItem = { ...item };
        },
        closeRemarkModal() {
            this.isRemarkModalOpen = false;
            this.rejectedItem = {};
            this.remarks = '';
        },
        ifNotRemark() {
            return this.remarks?.length < 2;
        },
        async downloadReport() {
            try {
                this.downloadFileByURL(
                    this.getURL("GET_WORK_ITEMS") + '?' + this.getQueryParams(true),
                    'Work Items.xlsx',
                    { system: 'CM' }
                );
            } catch (error) {
                console.log(error);
                this.showAlert("Something went wrong, Please try again.")
            }
        },
        polyFillProperties(existingData) {
            const polyFillProps = {};

            return existingData.map(e => ({
                ...polyFillProps,
                ...e,
            }));
        },
        filterList() {
            const filteredData = [...this.uploadList];
            this.tableFilteredData = this.polyFillProperties(filteredData);
        },
        handleRejection() {
            this.updateWorkItem(this.rejectedItem, 'rejected', this.remarks);
        },
        updateWorkItem(item, status, remark) {
            this.showProgress();

            const data = {
                status,
                typeOfVendor: item.typeOfVendor,
                panNumber: item.panNumber,
                remark
            }

            this.POSTM(
                "UPDATE_WORK_ITEM",
                data,
                (res) => {
                    this.hideProgress();
                    this.showToast(
                        res?.data?.msg || 'Work Item updated Successfully',
                        this.TOST().SUCCESS
                    );
                    this.fetchWorkItems();
                    this.closeRemarkModal();
                },
                (error) => {
                    this.hideProgress();
                    this.showToast(error?.response?.data?.msg || "Something went wrong, Please try again.", this.TOST().ERROR)
                },
                "CM"
            );
        },
        searchWorkItem () {
            this.currentPage = 1;
            this.fetchWorkItems();
        },
        async fetchWorkItems() {
            const afterAPI = () => {
                this.filterList();
                this.hideProgress();
            }

            // Starting loader here
            this.showProgress();
            this.GETM(
                "GET_WORK_ITEMS",
                this.getQueryParams(),
                (res) => {
                    this.uploadList = res?.data?.data;


                    this.totalPages = res.data.totalPages;
                    this.totalRecords = res?.data?.totalCounts;
                    this.currentShowingRecordsStartIndex = this.currentPage == 1 ? 0 : (this.currentPage - 1) * this.pageSize;
                    afterAPI();
                },
                (error) => {
                    console.log(error);
                    afterAPI();
                },
                "CM"
            );
        }
    },
    created() {
        this.$store.commit("SAVE_PAGE_HEADER", "Work Items");
        this.fetchWorkItems();
    },
}
</script>

<style lang="scss" scoped>
.no-resize .v-input__input {
    resize: none;
}

.p-5 {
    padding: 1rem;
    position: relative;
}

.font-size-12 {
    font-size: 12px;
}

.tds-input {
    margin-bottom: 2px;
}

.bg-grey {
    background-color: rgb(245, 245, 245);
}

.w-500 {
    min-width: 500px;
}

.my-5 {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.v-data-table__wrapper table thead th {
    color: black !important;
    font-size: 45px;
}

.btnToggle {
    text-transform: none;
    color: #fff;
    background-color: #1ebbd9;
}

.orange-background {
    background-color: rgb(228, 106, 37) !important;
}

.iconsize {
    font-size: 16px !important;
    font-weight: bold;
}

.tablebtn {
    background: #ffffff 0% 0% no-repeat padding-box !important;
    box-shadow: inset 0px 2px 4px #00000052 !important;
    border: 0.5px solid #c1c8cc;
    border-radius: 18px !important;
    height: 30px !important;
    text-transform: capitalize !important;
    width: 93%;
    justify-content: space-between;
    color: black !important;
    font-weight: 400 !important;
}

.tableheader {
    padding: 0;
}

.tableicons {
    font-size: 18px !important;
    color: #23b1a9 !important;
}

.approvedTextColor {
    color: #23b1a9;
}


.status-select-box {
    font-size: 11px;
    margin-bottom: 2px;
    // padding: 10px;
    // height: 12px;
}

.vs__dropdown-menu {
    text-transform: capitalize;
}

.flex {
    display: flex;
    justify-content: center;
}

.justify-between {
    justify-content: space-between;
}

.align-center {
    align-items: center;
}

.no-wrap {
    white-space: nowrap;
}

.w-200 {
    width: 200px;
}

.h-50 {
    height: 50px;
}

.w-150 {
    max-width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.more-text-icon {
    background-color: lightgray;
    color: white;
    border-radius: 2px;
    font-size: 18px;
    height: 18px;
}

.w-100 {
    width: 800px;
}

.pull-right {
    float: right;
}

.rotate-90 {
    transform: rotate(-90deg);
}

.filter-select-box {
    font-size: 12px;
    border-radius: 0;
}

.input-label {
    font-size: 10px;
    font-weight: bold;
}


.btnToggle {
    text-transform: uppercase;
    color: #fff;
    background-color: #1ebbd9;
}

.bold {
    font-weight: bold;
}
</style>