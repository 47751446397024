<template>
  <v-app>
    <v-form
      v-model="InsuredPrsnHospitlzdDetails"
      ref="InsuredPrsnHospitlzdDetails"
    >
      <v-card class="pa-3 py-5" tile>
        <v-layout align-center>
          <p
            class="pa-0 ma-0 pl-2 font-weight-bold"
            style="color:#10242B;font-size: 13px"
          >
            Empanelment Type
          </p>
          <v-divider
            v-show="!onlyFeatureclaim"
            vertical
            class="mx-3"
          ></v-divider>
          <v-btn-toggle
            v-model="empanelmentType"
            dense
            mandatory
            tile
            style="background-color:#F7F7F7;opacity:100%;"
          >
            <v-btn
              value="newClaim"
              title
              style="letter-spacing: unset"
              class="text-capitalize font-weight-bold rounded-0 px-10"
              active-class="tarrifConfgSelected"
              @click="changeClaimType('New')"
              v-show="!onlyFeatureclaim"
            >
              New Claim
            </v-btn>
            <v-btn
              value="featureClaim"
              title
              style="letter-spacing: unset"
              class="text-capitalize font-weight-bold rounded-0 px-10"
              active-class="tarrifConfgSelected"
              @click="changeClaimType('Feature')"
            >
              Feature Claim
            </v-btn>
          </v-btn-toggle>
        </v-layout>
      </v-card>
      <v-card tile class="pa-3">
        <v-layout>
          <v-flex xs3>
            <v-layout
              column
              class=" cust_pln_iphd_claims mx-2 cust-rmvpd-iphd_claims"
            >
              <p class="font-weight-bold cstm-clr-pln-iphd_claims">
                Date Reported
              </p>

              <v-menu
                ref="calender"
                :close-on-content-click="true"
                v-model="dateReportedcalender"
                :nudge-right="40"
                transition="scale-transition"
                max-width="290px"
                min-width="290px"
                class="rounded-0"
              >
                <!-- :rules="typeOfEmployee !== 'Service ID'?[required()]:[]" -->
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-bind="attrs"
                    label="Select.."
                    :value="cmputdPlcyEfctveDteFrmtdMmntjs"
                    solo
                    class="rounded-0"
                    readonly
                    v-on="on"
                    disabled
                  >
                    <div
                      slot="prepend-inner"
                      style="background-color:#F7F7F7;padding: 9px !important"
                    >
                      <v-icon>event</v-icon>
                    </div>
                  </v-text-field>
                </template>
                <v-date-picker v-model="dateReported" no-title> </v-date-picker>
              </v-menu>
            </v-layout>
          </v-flex>

          <v-flex xs3>
            <v-layout column class=" cust_pln_em mx-2 cust-rmvpd-iphd_claims">
              <p class="font-weight-bold cstm-clr-pln-iphd_claims">
                Time Reported
              </p>
              <v-menu
                ref="timeRmenu"
                :close-on-content-click="false"
                v-model="timeReportedmenu"
                :return-value.sync="timeReported"
                :nudge-right="40"
                transition="scale-transition"
                max-width="290px"
                min-width="290px"
                class="rounded-0"
              >
                <!-- :rules="typeOfEmployee !== 'Service ID'?[required()]:[]" -->
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-bind="attrs"
                    label="Select.."
                    v-model="timeReported"
                    solo
                    class="rounded-0"
                    readonly
                    v-on="on"
                    disabled
                  >
                    <div
                      slot="prepend-inner"
                      style="background-color:#F7F7F7;padding: 9px !important"
                    >
                      <v-icon>watch_later</v-icon>
                    </div>
                  </v-text-field>
                </template>
                <v-time-picker
                  v-if="timeReportedmenu"
                  v-model="timeReported"
                  full-width
                  @click:minute="$refs.timeRmenu.save(timeReported)"
                >
                </v-time-picker>
              </v-menu>
            </v-layout>
          </v-flex>

          <v-flex xs3>
            <v-layout column class="cust_pln_em mx-2 cust-rmvpd-iphd_claims">
              <p class="font-weight-bold cstm-clr-pln-iphd_claims">
                Claim Registered Date
              </p>

              <v-menu
                ref="calender"
                :close-on-content-click="true"
                v-model="claimRegisteredDateCalender"
                :nudge-right="40"
                transition="scale-transition"
                max-width="290px"
                min-width="290px"
                class="rounded-0"
                disabled
              >
                <!-- :rules="typeOfEmployee !== 'Service ID'?[required()]:[]" -->
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-bind="attrs"
                    label="Select.."
                    :value="cmputdclaimRegisteredDateMmntjs"
                    solo
                    class="rounded-0"
                    readonly
                    v-on="on"
                    disabled
                  >
                    <div
                      slot="prepend-inner"
                      style="background-color:#F7F7F7;padding: 9px !important"
                    >
                      <v-icon>event</v-icon>
                    </div>
                  </v-text-field>
                </template>
                <v-date-picker v-model="claimRegisteredDate" no-title>
                </v-date-picker>
              </v-menu>
            </v-layout>
          </v-flex>
          <!-- :class="[dsblEmployeeName===true ? 'custDsbld_emplyMstr': '']" -->
          <v-flex xs3>
            <v-layout column class="cust_pln_em mx-2 ">
              <p class="font-weight-bold cstm-clr-pln-iphd_claims">
                Claim Number
              </p>
              <v-text-field
                solo
                label="Enter..."
                v-model="claimNumber"
                class="rounded-0"
                :maxlength="max30"
                :rules="rules.alphaNumeric"
                disabled
              >
              </v-text-field>
            </v-layout>
          </v-flex>

          <v-flex xs3>
            <v-layout column class="cust_pln_em mx-2 ">
              <p class="font-weight-bold cstm-clr-pln-iphd_claims">
                Feature Number
              </p>
              <v-text-field
                solo
                label="Enter..."
                v-model="featureNumber"
                class="rounded-0"
                :maxlength="max30"
                :rules="rules.alphaNumeric"
                disabled
              >
              </v-text-field>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs3>
            <v-layout column class="cust_pln_em cust-rmvpd-iphd_claims mx-2 ">
              <p class="font-weight-bold cstm-clr-pln-iphd_claims">
                Claimed Amount
              </p>
              <v-text-field
                solo
                label="Enter..."
                v-model="claimedAmount"
                class="rounded-0"
                type="number"
                :rules="rules.numeric"
              >
                <div
                  slot="prepend-inner"
                  style="background-color:#F7F7F7;padding: 11px !important"
                >
                  <v-icon class="material-icons">mdi-currency-inr</v-icon>
                </div>
              </v-text-field>
            </v-layout>
          </v-flex>
          <v-flex xs3></v-flex>
          <v-flex xs3></v-flex>
          <v-flex xs3></v-flex>
          <v-flex xs3></v-flex>
        </v-layout>
      </v-card>

      <v-card color="#01BBD9" class=" pa-2 pl-5" tile>
        <p
          class="text-uppercase  font-weight-medium ma-0 pa-0"
          style="color:#FFFFFF; font-size:13px"
        >
          Member Details
        </p>
      </v-card>
      <v-card tile class="pa-3">
        <v-layout>
          <v-flex xs3 class="">
            <v-layout column class="cust_pln_em mx-2">
              <p class="font-weight-bold cstm-clr-pln-iphd_claims">
                Member ID
              </p>
              <v-text-field
                v-model="memberID"
                label="Enter..."
                solo
                class="rounded-0"
                :rules="rules.alphaNumeric"
                @click="visitMemberSearch"
              >
                <div
                  slot="append"
                  style="background-color:#F7F7F7;padding:8.5px;cursor:pointer;position:absolute;top:0px;right:0px"
                >
                  <v-icon>search</v-icon>
                </div>
              </v-text-field>
            </v-layout>
          </v-flex>

          <v-flex xs3>
            <v-layout column class="cust_pln_em mx-2 ">
              <p class="font-weight-bold cstm-clr-pln-iphd_claims">
                Policy Member
              </p>
              <v-text-field
                solo
                label="Enter..."
                v-model="policyMember"
                class="rounded-0"
                :maxlength="max30"
                :rules="[required()]"
                disabled
              >
              </v-text-field>
            </v-layout>
          </v-flex>
          <v-flex xs3>
            <v-layout column class="cust_pln_em mx-2 ">
              <p class="font-weight-bold cstm-clr-pln-iphd_claims">
                Certificate Number
              </p>
              <v-text-field
                solo
                label="Enter..."
                v-model="certificateNumber"
                class="rounded-0"
                :maxlength="max30"
                :rules="[required()]"
                disabled
              >
              </v-text-field>
            </v-layout>
          </v-flex>

          <v-flex xs3>
            <v-layout column class=" cust_pln_em mx-2 cust-rmvpd-iphd_claims">
              <p class="font-weight-bold cstm-clr-pln-iphd_claims">
                Date of Admission (Loss)
              </p>
              <v-menu
                ref="calender"
                :close-on-content-click="true"
                v-model="dateofAdmissionCalender"
                :nudge-right="40"
                transition="scale-transition"
                max-width="290px"
                min-width="290px"
                class="rounded-0"
              >
                <!-- :rules="typeOfEmployee !== 'Service ID'?[required()]:[]" -->
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-bind="attrs"
                    label="Select.."
                    :value="cmputddateofAdmissionMmntjs"
                    solo
                    class="rounded-0"
                    :rules="rules.dateofLossRules"
                    readonly
                    v-on="on"
                  >
                    <div
                      slot="prepend-inner"
                      style="background-color:#F7F7F7;padding: 9px !important"
                    >
                      <v-icon>event</v-icon>
                    </div>
                  </v-text-field>
                </template>
                <v-date-picker v-model="dateofAdmission" no-title>
                </v-date-picker>
              </v-menu>
            </v-layout>
          </v-flex>

          <v-flex xs3>
            <v-layout column class=" cust_pln_em mx-2 cust-rmvpd-iphd_claims">
              <p class="font-weight-bold cstm-clr-pln-iphd_claims">
                Time of Admission (Loss)
              </p>

              <v-menu
                ref="timeAmenu"
                :close-on-content-click="false"
                v-model="timeAdmissionmenu"
                :return-value.sync="timeAdmission"
                :nudge-right="40"
                transition="scale-transition"
                max-width="290px"
                min-width="290px"
                class="rounded-0"
              >
                <!-- :rules="typeOfEmployee !== 'Service ID'?[required()]:[]" -->
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-bind="attrs"
                    label="Select.."
                    v-model="timeAdmission"
                    solo
                    class="rounded-0"
                    readonly
                    v-on="on"
                    :rules="rules.required"
                  >
                    <div
                      slot="prepend-inner"
                      style="background-color:#F7F7F7;padding: 9px !important"
                    >
                      <v-icon>watch_later</v-icon>
                    </div>
                  </v-text-field>
                </template>
                <v-time-picker
                  v-if="timeAdmissionmenu"
                  v-model="timeAdmission"
                  full-width
                  @click:minute="$refs.timeAmenu.save(timeAdmission)"
                >
                </v-time-picker>
              </v-menu>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs3>
            <v-layout column class="cust_pln_em mx-2 ">
              <p class="font-weight-bold cstm-clr-pln-iphd_claims">
                Name of Corporate
              </p>
              <v-text-field
                solo
                label="Enter..."
                v-model="nameOfCorporate"
                class="rounded-0"
                :maxlength="max30"
                :rules="[required()]"
                disabled
              >
              </v-text-field>
            </v-layout>
          </v-flex>
          <v-flex xs3>
            <v-layout column class="cust_pln_em mx-2 ">
              <p class="font-weight-bold cstm-clr-pln-iphd_claims">
                Employee ID
              </p>
              <v-text-field
                solo
                label="Enter..."
                v-model="employeeID"
                class="rounded-0"
                :maxlength="max30"
                :rules="[required()]"
                disabled
              >
              </v-text-field>
            </v-layout>
          </v-flex>
          <v-flex xs3>
            <v-layout column class="cust_pln_em mx-2 ">
              <p class="font-weight-bold cstm-clr-pln-iphd_claims">
                First Name
              </p>
              <v-text-field
                solo
                label="Enter..."
                v-model="firstName"
                class="rounded-0"
                :maxlength="max30"
                :rules="[required()]"
                disabled
              >
              </v-text-field>
            </v-layout>
          </v-flex>
          <v-flex xs3>
            <v-layout column class="cust_pln_em mx-2 ">
              <p class="font-weight-bold cstm-clr-pln-iphd_claims">
                Middle Name
              </p>
              <v-text-field
                solo
                label="Enter..."
                v-model="middleName"
                class="rounded-0"
                :maxlength="max30"
                :rules="[required()]"
                disabled
              >
              </v-text-field>
            </v-layout>
          </v-flex>
          <v-flex xs3>
            <v-layout column class="cust_pln_em mx-2 ">
              <p class="font-weight-bold cstm-clr-pln-iphd_claims">
                Surname
              </p>
              <v-text-field
                solo
                label="Enter..."
                v-model="surname"
                class="rounded-0"
                :maxlength="max30"
                :rules="[required()]"
                disabled
              >
              </v-text-field>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-divider class="mb-3"></v-divider>
        <v-layout>
          <v-flex xs3>
            <v-layout column class=" cust_pln_em mx-2 cust-rmvpd-iphd_claims">
              <p class="font-weight-bold cstm-clr-pln-iphd_claims">
                Date of Birth
              </p>

              <v-menu
                ref="calender"
                :close-on-content-click="true"
                v-model="dateOfBirthcalender"
                :nudge-right="40"
                transition="scale-transition"
                max-width="290px"
                min-width="290px"
                class="rounded-0"
              >
                <!-- :rules="typeOfEmployee !== 'Service ID'?[required()]:[]" -->
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-bind="attrs"
                    label="Select.."
                    :value="cmputddateOfBirthMmntjs"
                    solo
                    class="rounded-0"
                    readonly
                    v-on="on"
                    disabled
                  >
                    <div
                      slot="prepend-inner"
                      style="background-color:#F7F7F7;padding: 9px !important"
                    >
                      <v-icon>event</v-icon>
                    </div>
                  </v-text-field>
                </template>
                <v-date-picker v-model="dateOfBirth" no-title> </v-date-picker>
              </v-menu>
            </v-layout>
          </v-flex>

          <v-flex xs3>
            <v-layout column class="cust_pln_em cust-rmvpd-iphd_claims mx-2 ">
              <p class="font-weight-bold cstm-clr-pln-iphd_claims">
                Age
              </p>
              <v-text-field
                solo
                label="Enter..."
                v-model="Age"
                class="rounded-0"
                disabled
              >
                <div
                  slot="prepend-inner"
                  style="background-color:#F7F7F7;padding: 9px !important"
                >
                  <v-icon class>event</v-icon>
                </div>
              </v-text-field>
            </v-layout>
          </v-flex>

          <v-flex xs3>
            <v-layout column class="cust_pln_em mx-2">
              <p class="font-weight-bold cstm-clr-pln-iphd_claims">
                Gender
              </p>
              <div
                class=" elevation-1"
                style="background-color:#F7F7F7;padding-top: 8px;padding-bottom: 8px; "
              >
                <v-radio-group
                  v-model="gender"
                  mandatory
                  row
                  dense
                  class="ma-0 pa-0"
                  hide-details
                  :disabled="true"
                >
                  <v-radio
                    color="#23B1A9"
                    dense
                    value="Male"
                    class="ma-0 pa-0 pl-1"
                  >
                    <template v-slot:label>
                      <div
                        class="font-weight-bold"
                        style="color:#10242B;font-size:12px"
                      >
                        Male
                      </div>
                    </template>
                  </v-radio>
                  <v-radio
                    color="#23B1A9"
                    dense
                    value="Female"
                    class="ma-0 pa-0 pl-2"
                  >
                    <template v-slot:label>
                      <div
                        class="font-weight-bold"
                        style="color:#10242B;font-size:12px"
                      >
                        Female
                      </div>
                    </template>
                  </v-radio>
                </v-radio-group>
              </div>
            </v-layout>
          </v-flex>
          <v-flex xs3>
            <v-layout column class="cust_pln_em mx-2">
              <p class="font-weight-bold cstm-clr-pln-iphd_claims">
                Relationship to Primary Insured
              </p>
              <!-- item-text="planName"
                                item-value="_id" -->
              <v-select
                :items="relationshipToPrimaryInsuredItems"
                label="Select"
                v-model="relationshipToPrimaryInsured"
                :rules="[required()]"
                solo
                dense
                class="rounded-0"
                disabled
              >
              </v-select>
            </v-layout>
          </v-flex>
          <v-flex xs3>
            <v-layout column class="cust_pln_em mx-2 ">
              <p class="font-weight-bold cstm-clr-pln-iphd_claims">
                Other
              </p>
              <v-text-field
                solo
                label="Enter..."
                v-model="otherRelationshipToPrimaryInsured"
                class="rounded-0"
                :maxlength="max30"
                :rules="[required()]"
              >
              </v-text-field>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs3>
            <v-layout column class="cust_pln_em mx-2">
              <p class="font-weight-bold cstm-clr-pln-iphd_claims">
                Occupation
              </p>
              <!-- item-text="planName"
                                item-value="_id" -->
              <v-select
                :items="OccupationItems"
                label="Select"
                v-model="Occupation"
                solo
                dense
                class="rounded-0"
              >
              </v-select>
            </v-layout>
          </v-flex>
          <v-flex xs3>
            <v-layout column class="cust_pln_em mx-2 ">
              <p class="font-weight-bold cstm-clr-pln-iphd_claims">
                Other
              </p>
              <v-text-field
                solo
                label="Enter..."
                v-model="otherOccupation"
                class="rounded-0"
                :maxlength="max30"
              >
              </v-text-field>
            </v-layout>
          </v-flex>
          <v-flex xs3>
            <v-layout column class="cust_pln_em mx-2">
              <p class="font-weight-bold cstm-clr-pln-iphd_claims">
                Tata AIG Employee
              </p>
              <!-- item-text="planName"
                                item-value="_id" -->
              <v-select
                :items="TataAIGEmployeeItems"
                label="Select"
                v-model="TataAIGEmployee"
                solo
                dense
                class="rounded-0"
              >
              </v-select>
            </v-layout>
          </v-flex>
          <v-flex xs3></v-flex>
          <v-flex xs3></v-flex>
        </v-layout>
      </v-card>

      <v-card color="#01BBD9" class=" pa-2 pl-5" tile>
        <p
          class="text-uppercase  font-weight-medium ma-0 pa-0"
          style="color:#FFFFFF; font-size:13px"
        >
          Details of Benefit Claimed
        </p>
      </v-card>
      <v-card tile class="pa-3">
        <v-layout>
          <v-flex xs3>
            <v-layout column class="cust_pln_em mx-2 ">
              <p class="font-weight-bold cstm-clr-pln-iphd_claims">
                Product Name
              </p>
              <v-text-field
                solo
                label="Enter..."
                v-model="productName"
                class="rounded-0"
                :maxlength="max30"
                disabled
              >
              </v-text-field>
            </v-layout>
          </v-flex>
          <v-flex xs3>
            <v-layout column class="cust_pln_em mx-2">
              <p class="font-weight-bold cstm-clr-pln-iphd_claims">
                Benefit Claimed
              </p>
              <!-- item-text="planName"
                                item-value="_id" -->
              <v-select
                :items="benefitClaimedItems"
                label="Select"
                v-model="benefitClaimed"
                :rules="[required()]"
                solo
                dense
                class="rounded-0"
              >
              </v-select>
            </v-layout>
          </v-flex>
          <v-flex xs3>
            <v-layout column class="cust_pln_em mx-2">
              <p class="font-weight-bold cstm-clr-pln-iphd_claims">
                Catastrophe ?
              </p>
              <!-- item-text="planName"
                                item-value="_id" -->
              <v-select
                :items="catastropheItems"
                label="Select"
                v-model="catastrophe"
                solo
                dense
                class="rounded-0"
              >
              </v-select>
            </v-layout>
          </v-flex>
          <v-flex xs3>
            <v-layout column class="cust_pln_em mx-2">
              <p class="font-weight-bold cstm-clr-pln-iphd_claims">
                Catastrophe Type ?
              </p>
              <!-- item-text="planName"
                                item-value="_id" -->
              <v-select
                :items="catastropheTypeItems"
                label="Select"
                v-model="catastropheType"
                solo
                dense
                class="rounded-0"
              >
              </v-select>
            </v-layout>
          </v-flex>

          <v-flex xs3>
            <v-layout column class="cust_pln_em mx-2 ">
              <p class="font-weight-bold cstm-clr-pln-iphd_claims">
                Catastrophe Code
              </p>
              <v-text-field
                solo
                label="Enter..."
                v-model="catastropheCode"
                class="rounded-0"
                :maxlength="max30"
              >
              </v-text-field>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-card>

      <claimsAddress
        ref="insuredDetailsAddress"
        @claimAddress_emit="Parent_claimAddress_emit"
      >
      </claimsAddress>

      <v-card tile style="max-height:150px" class="mx-3 mt-4 rounded-0">
        <div
          class="mx-2 pa-2"
          style="background-color:#eee; display:flex;justify-content:space-between;margin-left:0px!important;margin-right:0px!important;"
        >
          <v-layout style="flex:1" justify-end>
            <v-btn
              dark
              tile
              color="#152F38"
              class="text-capitalize px-3 mx-2 my-2"
              small
              @click="createFeatureClaim"
              ><v-icon dark small>add</v-icon>
              <v-divider vertical class="mx-2"></v-divider> Create Feature
              Claim</v-btn
            >
            <v-btn
              dark
              tile
              color="#23B1A9"
              class="text-capitalize px-6 mx-2 my-2"
              small
            >
              NIDB</v-btn
            >
            <v-btn
              dark
              tile
              color="#E46A25"
              class="text-capitalize px-3 mx-2 my-2"
              small
              @click="$router.push('/homePage')"
              ><v-icon dark small>cancel</v-icon>
              <v-divider vertical class="mx-2"></v-divider>Cancel</v-btn
            >
            <v-btn
              dark
              tile
              color="#D1121B"
              class="text-capitalize px-3 mx-2 my-2"
              small
              ><v-icon dark small>delete</v-icon>
              <v-divider vertical class="mx-2"></v-divider>Clear</v-btn
            >
            <v-btn
              dark
              tile
              color="#23B1A9"
              class="text-capitalize px-3 mx-2 my-2"
              small
              @click="SaveInsuredDetails"
              ><v-icon dark small>check_circle</v-icon>
              <v-divider vertical class="mx-2"></v-divider>Save</v-btn
            >
            <v-btn
              dark
              tile
              color="#152F38"
              class="text-capitalize px-3 mx-2 my-2"
              small
              ><v-icon dark small>arrow_forward</v-icon>
              <v-divider vertical class="mx-2"></v-divider> Next</v-btn
            >
          </v-layout>
        </div>
      </v-card>
    </v-form>
    <v-dialog v-model="duplicateClaim" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2">
          Potential Duplicate Identified
        </v-card-title>

        <v-card-text>
          This is a potential duplicate of claim this claim Do you want to
          continue
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="SaveInsuredDetails('fromduplicate')"
          >
            yes
          </v-btn>
          <v-btn color="primary" text @click="duplicateClaim = false">
            No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>
<script>
import moment from "moment";
import claimsAddress from "../../component/Claims_Address";
export default {
  data() {
    return {
      duplicateClaim: false,
      regClaimId: "",
      selectedClaimType: "",
      InsuredPrsnHospitlzdDetails: false,
      empanelmentType: "newClaim",
      newClaim: "",
      featureClaim: "",
      dateReportedcalender: false,
      // dummy data instead of inward assesor screen
      claimRegisteredDate: "2021-05-13",
      dateReported: "2021-05-13",
      timeReported: "03:15",
      claimRegisteredDateCalender: false,
      claimNumber: "",
      featureNumber: "",
      claimedAmount: "",
      memberID: "",
      policyMember: "",
      certificateNumber: "",
      dateofAdmissionCalender: false,
      dateofAdmission: "",
      nameOfCorporate: "",
      employeeID: "",
      firstName: "",
      middleName: "Test Mid",
      surname: "",
      dateOfBirthcalender: false,
      dateOfBirth: "",
      Age: "",
      gender: "",
      relationshipToPrimaryInsuredItems: [
        "Self",
        "Spouse",
        "Mother",
        "Father",
        "Son",
        "Daughter",
        "Other",
      ],
      relationshipToPrimaryInsured: "Other",
      otherRelationshipToPrimaryInsured: "Other",
      OccupationItems: [
        "Service",
        "Self Employed",
        "Homemaker",
        "Student",
        "Retired",
        "Other",
      ],
      Occupation: "",
      TataAIGEmployeeItems: ["Yes", "No"],
      TataAIGEmployee: "",
      otherOccupation: "",
      productName: "Test Name",
      catastropheCode: "",
      benefitClaimedItems: [
        "In-patient",
        "Domiciliary Hospitalization",
        "Organ Donor",
        "Global cover",
        "Bariatric Surgery",
        "Dental treatment",
        "AYUSH Benefit",
        "Maternity",
        "Pre-Hospitalization",
        "Post-Hospitalization",
        "Day Care Procedures",
        "Ambulance cover",
        "New Born Baby Cover",
        "First year Vaccinations",
        "Health Check-up",
        "Second Opinion",
        "Vaccination cover",
        "Hearing Aid",
        "Daily cash – insured child",
        "Daily cash – shared accommodation",
        "Prolonged hospitalization Benefit",
        "High End Diagnostics",
        "OPD Treatment",
        "OPD Treatment-Dental",
        "Emergency Air Ambulance Cover",
        "Compassionate travel",
        "Consumables Benefit",
        "Accidental Death Benefit",
        "Corporate Buffer",
      ],
      benefitClaimed: "",
      catastropheItems: ["Yes", "No"],
      catastrophe: "No",
      catastropheTypeItems: ["Local", "International"],
      catastropheType: "",
      address: "Andheri East, Mumbai, Maharashtra 400093",
      landmark: " Ackruti Trade Center",
      area: "Andheri",

      cityTownVillageItems: [],
      cityTownVillage: "Mumbai",
      district: "Mumbai",
      pincode: "400093",
      email: "",
      mbNo: "",
      addDiffClm: "addDiffClmNo",
      addDiffClmaddress: "",
      addDiffClmlandmark: "",
      addDiffClmarea: "",
      addDiffstateItems: [],
      addDiffstate: "",
      addDiffcityTownVillageItems: [],
      addDiffcityTownVillage: "",
      addDiffdistrict: "",
      addDiffpincode: "",
      altDiffClm: "altDiffClmNo",
      altemail1: "",
      altemail2: "",
      altmbNo1: "",
      altmbNo2: "",
      timeReportedmenu: false,
      timeAdmissionmenu: false,
      timeAdmission: null,
      max30: 30,
      max10: 10,
      Datepattern: /^(((0|1)[0-9]|2[0-9]|3[0-1])\/(0[1-9]|1[0-2])\/((19|20)\d\d))$/,
      onlyFeatureclaim: false,
      required() {
        return (v) => (v && v.length > 0) || "This Field is Required";
      },
      alphaNumeric() {
        return (v) => /^[0-9 ]+$/.test(v) || `Only Numbers are allowed.`;
      },
      rules: {
        insuredName: [
          (v) => !!v || this.validationmessage,
          // v => v && !!v.trim() || 'Only spaces are not allowed',
          (v) =>
            (v && v.length <= 140) ||
            "Insured Name should be less than 140 characters.",
          (v) =>
            /^(?=[A-Za-z0-9./',\\-\h])([A-Za-z0-9.'/,\\-\s\h]*)(?<=[A-Za-z0-9.'/,\\-\h])$/.test(
              v
            ) || "Insured Name is not valid", //Surendra(18-03-2021):Name was allowing special char MC-558//Surendra(01-04):Special characters allowed in start and end too
        ],

        validate: [
          (v) => !!v || "This field is required",
          (v) => /^[a-zA-Z0-9]+$/.test(v) || "It is not valid",
        ],
        required: [(v) => !!v || "This field is required"],

        numeric: [
          (v) => !!v || "This field is required",
          (v) => /^[0-9 ]+$/.test(v) || `Only Numbers are allowed.`,
        ],

        alphaNumeric: [
          (v) => !!v || "This field is required",
          (v) => /^[a-zA-Z0-9]+$/.test(v) || "It is not valid",
        ],

        // mobilerules:[
        //   v => (v && /(^[0-9]+$)/.test(v)) || 'Only Numbers are allowed',
        //   // v => (v && v.length == 10) || 'Mobile Number should be 10 digits.',
        // ],

        emailRules: [
          (v) => !!v || "E-mail is required",
          (v) =>
            /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,6})+$/.test(v) ||
            "E-mail must be valid",
        ],

        multiplemobilerules: [
          (v) => !!v || this.validationmessage,
          (v) => /^(\d{10},)*\d{10}$/.test(v) || "Mobile Numbers is not valid",
          (v) => (v && v.length < 50) || "Max Length should be 50 digits",
        ],

        dateofLossRules: [
          (v) => !!v || "This field is required",
          (v) =>
            this.Datepattern.test(v) ||
            "Enter valid date format as DD/MM/YYYY.",
        ],

        dateRule: [
          (v) => !!v || "This field is required",
          // (v) => this.Datepattern.test(v) || "Enter valid date format as DD/MM/YYYY.",
        ],

        invoiceDaterule: [
          (v) =>
            this.Datepattern.test(v) ||
            "Enter valid date format as DD/MM/YYYY.",
        ],

        expiryDateRule: [
          (v) => !!v || "This field is required",
          (v) =>
            !!this.futureDateCheck(v) ||
            "Expiry date should be greater than Inception Date",
        ],

        invoiceAmtRule: [
          (v) =>
            /^\S[0-9a-zA-Z , . _ - @ %]+\S$/.test(v) ||
            "This field is not valid",
        ],

        reportedDateRules: [
          (v) => !!v || this.validationmessage,
          (v) =>
            this.Datepattern.test(v) ||
            "Enter valid date format as DD/MM/YYYY.",
          (v) => !!this.pastDateCheck(v) || "Future dates not allowed.",
          (v) =>
            (this.dateOfLoss ? new Date(this.dateOfLoss) <= new Date(v) : "") ||
            "Reported date should more than loss date.",
        ],
      },
    };
  },
  components: {
    claimsAddress,
  },
  computed: {
    cmputdPlcyEfctveDteFrmtdMmntjs() {
      return this.dateReported
        ? moment(this.dateReported).format("DD/MM/YYYY")
        : "";
    },
    cmputdclaimRegisteredDateMmntjs() {
      return this.claimRegisteredDate
        ? moment(this.claimRegisteredDate).format("DD/MM/YYYY")
        : "";
    },
    cmputddateofAdmissionMmntjs() {
      return this.dateofAdmission
        ? moment(this.dateofAdmission).format("DD/MM/YYYY")
        : "";
    },
    cmputddateOfBirthMmntjs() {
      return this.dateOfBirth
        ? moment(this.dateOfBirth).format("DD/MM/YYYY")
        : "";
    },
  },

  mounted() {
    // checkfor registered claim id from claim regsitration screen
    if (this.$store.state.registeredClaimId) {
      // getting type of claimregistered and its id from params
      this.regClaimId = this.$store.state.registeredClaimId;
    }
    // if already claim detals of insuredprsn tab is submitted prepopulating the data
    if (this.$store.state.submittedClaimId) {
      this.setTabFieldsData();
    }
    if (this.$store.state.didMembersearch) {
      console.log("inside did membersearch", this.$store.state.didMembersearch);
      // getting membersearch data
      let memberdata = this.$store.getters.fetchClaimMembersearchData;
      console.log(
        "obj keys of member data",
        Object.keys(memberdata),
        memberdata
      );
      if (Object.keys(memberdata) !== 0) {
        //populating data
        this.memberID = memberdata.memberId;
        this.policyMember = memberdata.policyNumber;
        this.nameOfCorporate = memberdata.corporateGroupName;
        this.employeeID = memberdata.employeeId;
        this.firstName = memberdata.firstName;
        this.surname = memberdata.lastName;
        this.dateOfBirth = memberdata.dateOfBirth;
        this.certificateNumber = memberdata.certificateNumber;
        this.Age = memberdata.age;
        this.gender = memberdata.gender;
        // this.relationshipToPrimaryInsured = memberdata.relationshipToIsured;
        this.email = memberdata.email;
        this.mbNo = memberdata.mobileNumber;
      } else {
        console.log("member data is null");
      }
    }
  },
  methods: {
    createFeatureClaim() {
      this.newClaim = "No";
      this.featureClaim = "Yes";
      this.onlyFeatureclaim = true;
      this.empanelmentType = "featureClaim";
    },
    toHome() {
      //  this.$router.push({
      //   name: "homePage"
      // });
    },
    changeClaimType(type) {
      if (type == "new") {
        this.newClaim = "Yes";
        this.featureClaim = "No";
      } else {
        this.newClaim = "No";
        this.featureClaim = "Yes";
      }
    },
    setTabFieldsData() {
      let submittedClaimId = this.$store.state.submittedClaimId;
      let apiURL = `/claims-details/get-claim-registration-detail/${submittedClaimId}`;
      this.GET(8, apiURL, (res, error) => {
        if (!error) {
          console.log("data of sumbitted claim", res.data);
          // settig values
        } else {
          console.log("sumitted claim eror", error);
        }
      });
    },
    visitMemberSearch() {
      this.$store.commit("VALIDATE_MEMBER_SEARCH", true);
      this.$router.push({
        name: "MemberSearch_Claims",
        params: { page: "rmbClaim" },
      });
    },
    emailRules2(v) {
      if (v.length !== 0) {
        console.log("testing", /^[0-9 ]+$/.test(v));
        if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,6})+$/.test(v)) {
          return "E-mail must be valid";
        } else {
          console.log("else none");
        }
      }
    },
    SaveInsuredDetails(data) {
      console.log(
        this.$refs.insuredDetailsAddress.claimAddress,
        "this.$ref.insuredDetailsAddress"
      );
      this.$refs.insuredDetailsAddress.claimAddress();

      let formData = {
        claimType: "reimbursementClaim",
        newClaim: this.newClaim,
        featureClaim: this.featureClaim,
        dateReported: this.dateReported,
        timeReported: "84000000",
        claimRegisteredDate: this.claimRegisteredDate,
        claimNumber: "123445",
        featureNumber: "1122334",
        claimedAmount: this.claimedAmount,
        memberId: this.memberID,
        policyNumber: this.policyMember,
        certificateNumber: this.certificateNumber,
        dateOfAdmission: this.dateofAdmission,
        timeOfAdmission: this.timeAdmission,
        nameOfCorporate: this.nameOfCorporate,
        employeeID: this.employeeID,
        firstName: this.firstName,
        middleName: this.middleName,
        surname: this.surname,
        dateOfBirth: this.dateOfBirth,
        years: "string",
        months: "string",
        gender: this.gender,
        relationshipToPrimaryInsured: this.relationshipToPrimaryInsured,
        occupation: this.occupation,
        other: this.otherOccupation,
        tataAIGEmployee: this.TataAIGEmployee,
        productName: this.productName,
        benefitClaimed: this.benefitClaimed,
        catastrophe: this.catastrophe,
        catastropheType: this.catastropheType,
        catastropheCode: this.catastropheCode,
        address: this.$refs.insuredDetailsAddress.address,
        landmark: this.$refs.insuredDetailsAddress.landmark,
        area: this.$refs.insuredDetailsAddress.area,
        cityTownVillage: this.$refs.insuredDetailsAddress.cityTownVillage,
        district: this.$refs.insuredDetailsAddress.District,
        pinCode: this.$refs.insuredDetailsAddress.pincode,
        state: this.$refs.insuredDetailsAddress.state,
        email: this.$refs.insuredDetailsAddress.email,
        mobileNumber: this.$refs.insuredDetailsAddress.mbNo,
        alternateAddressInClaim: this.$refs.insuredDetailsAddress.addDiffClm,
        alternateAddress: this.$refs.insuredDetailsAddress.addDiffClmaddress,
        alternateLandmark: this.$refs.insuredDetailsAddress.addDiffClmlandmark,
        alternateArea: this.$refs.insuredDetailsAddress.addDiffClmarea,
        alternateCityTownVillage: this.$refs.insuredDetailsAddress
          .addDiffcityTownVillage,
        alternateDistrict: this.$refs.insuredDetailsAddress.addDiffdistrict,
        alternatePincode: this.$refs.insuredDetailsAddress.addDiffpincode,
        alternateState: this.$refs.insuredDetailsAddress.addDiffstate,
        contactDetails: this.$refs.insuredDetailsAddress.altDiffClm,
        alternateEmailOne: this.$refs.insuredDetailsAddress.altemail1,
        alternateMobileNumberOne: this.$refs.insuredDetailsAddress.altmbNo1,
        alternateEmailTwo: this.$refs.insuredDetailsAddress.altemail2,
        alternateMobileNumberTwo: this.$refs.insuredDetailsAddress.altmbNo2,
      };
      // if user want t submit duplicate claim
      if (data == "fromduplicate") {
        formData["claimStatus"] = "Yes";
        this.duplicateClaim = false;
      }
      let claimId = this.regClaimId;
      let apiURL = `/claims-details/craete-claim/${claimId}`;
      this.PUT(8, apiURL, formData, (res, error) => {
        if (!error) {
          console.log("from saved insrns", res);
          this.showToast(res.data.msg, this.TOST().SUCCESS);
          if (res.data._id) {
            this.store.state.commit("SAVE_SUBMITTED_CLAIM_TYPE", res.data._id);
          }
        } else {
          console.log("POst APi error response|->create-user", error);
          if (error.response.data.msg == "Claim already exists") {
            this.duplicateClaim = true;
          } else {
            this.showToast(error.response.data.msg, this.TOST().ERROR);
          }
        }
      });
    },
    Parent_claimAddress_emit(emittedData) {
      console.log(emittedData, "emitted data");
    },
  },
};
</script>
<style>
.delpad_CR .v-input__slot {
  padding-right: 0px !important;
}

.cust_pln_iphd_claims .v-text-field .v-input__slot,
.v-text-field--outline .v-input__slot {
  min-height: 41px !important;
  display: flex !important;
  align-items: center !important;
  /* border:1px red solid; */
}

.cust_pln_iphd_claims .v-input .v-label {
  font-size: 13px !important;
}
.cust_pln_iphd_claims .v-input {
  font-size: 13px !important;
}
.cust_pln_iphd_claims .v-input input {
  font-size: 13px !important;
}

.cstm-clr-pln-iphd_claims {
  color: #646666 !important;
  margin-bottom: 5px !important;
  font-size: 13px !important;
}

.cust-rmvpd-iphd_claims
  .v-text-field.v-text-field--enclosed
  > .v-input__control
  > .v-input__slot {
  padding: 0px !important;
}
</style>
