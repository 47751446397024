<template>
<v-app>
        <div style="background-color: #FFFFFF;" column class="py-3">

        <!-- <v-form v-model="spclvalid" ref="spclForm"> -->

            <v-form v-model="spcltyvalid" ref="spcltyForm">

        <v-layout class=" mt-2" >
            <v-flex xs4>
                    <v-layout column class="mx-2 my-2">
                        <v-text class="text-uppercase font-weight-bold title-clr-pr">
                            Speciality Details
                        </v-text>
                        <v-divider></v-divider>
                    </v-layout>
                </v-flex>


                <v-flex xs3>
                    <v-layout column class=" mx-2 my-2 cust_pd">
                        <v-text class="font-weight-bold cstm-clr-pd">
                            Speciality *
                        </v-text>
                        <!-- @change="chngSpclty()" -->
                       <v-select
                            :items="specialityItems"
                            label="Select"
                            v-model="speciality"
                            solo
                            dense
                            multiple
                            
                            class="rounded-0"
                            :rules="[required()]"
                            
                            
                            >
                             <template v-slot:prepend-item>
                                <v-list-item
                                ripple
                                @click="toggle"
                                >
                                <v-list-item-action>
                                    <v-icon :color="speciality.length > 0 ? 'indigo darken-4' : ''">
                                    {{ icon }}
                                    </v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title>
                                    Select All
                                    </v-list-item-title>
                                </v-list-item-content>
                                </v-list-item>
                                <v-divider class="mt-2"></v-divider>
                            </template>
                            <template v-slot:selection="{ item, index }">
                                   
                                    <span v-if="index === 0" >{{ item }}</span>
                                   
                                    <span
                                    v-if="index === 1"
                                    class="grey--text caption"
                                    >
                                    (+{{ speciality.length - 1 }})
                                    </span>
                             </template>
                       </v-select>
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class=" mx-2 my-2 cust_pd">
                        <v-text class="font-weight-bold cstm-clr-pd">
                            Other
                        </v-text>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="other"
                            class="rounded-0"
                            :rules="[requiredIfCompany]"
                        >

                        </v-text-field>
                    </v-layout>
                </v-flex>

                <v-flex xs3></v-flex>
                <v-flex xs3></v-flex>
                
                
                                            
            </v-layout>

             <v-dialog v-model="confirmDialogCancel" persistent max-width="450px">
                 
                         <v-card tile color="#F7F7F7" >
                            <v-card tile>
                                <v-layout justify-space-between  class="" style="flex:1;background-ox-shadow: 0px 3px 5px #00000029;">
                             
                                    <v-card-title class="pa-0 pl-3 text-capitalize font-weight-regular" style="color:black"> 
                                       CONFIRMATION
                                    </v-card-title>
                                    <div style="border-left:1px solid #C1C8CC;" @click="close_cancelDialog()">
                                        <v-icon style="cursor: pointer;" class="pa-2" >close</v-icon>
                                    </div>
                                </v-layout>
                            </v-card>
                        <v-flex >
                           
                       
                        </v-flex>
                        <v-card-text >
                                Details saved on screen will be lost
                        </v-card-text>
                        <v-card-actions justify-start>
                          
                           
                           
                            <v-btn dark  tile color="black" @click="confirmDialogCancel = false" class="text-capitalize px-3 mx-2" small><v-icon dark small>cancel</v-icon> <v-divider vertical class="mx-2"></v-divider>Cancel</v-btn>

                            <!-- <v-btn depressed small color="secondary" class="rounded-0"  >
                                <v-icon dark right>check_circle</v-icon>
                            </v-btn> -->
                            <v-btn @click="selectedCompFromPage(arrToNavCancel[1])" dark  tile color="#23B1A9" class="text-capitalize px-3 ml-2" small><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider> OK</v-btn>

                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <v-dialog v-model="confirmDialogNext" persistent max-width="450px">
                 
                         <v-card tile color="#F7F7F7" >
                            <v-card tile>
                                <v-layout justify-space-between  class="" style="flex:1;background-ox-shadow: 0px 3px 5px #00000029;">
                             
                                    <v-card-title class="pa-0 pl-3 text-capitalize font-weight-regular" style="color:black"> 
                                       CONFIRMATION
                                    </v-card-title>
                                    <div style="border-left:1px solid #C1C8CC;" @click="close_cancelDialog()">
                                        <v-icon style="cursor: pointer;" class="pa-2" >close</v-icon>
                                    </div>
                                </v-layout>
                            </v-card>
                        <v-flex >
                           
                       
                        </v-flex>
                        <v-card-text >
                                 Details entered on the page will be lost if not saved
                        </v-card-text>
                        <v-card-actions justify-start>
                          
                           
                           
                            <v-btn dark  tile color="black" @click="confirmDialogNext = false" class="text-capitalize px-3 mx-2" small><v-icon dark small>cancel</v-icon> <v-divider vertical class="mx-2"></v-divider>Cancel</v-btn>

                            <!-- <v-btn depressed small color="secondary" class="rounded-0"  >
                                <v-icon dark right>check_circle</v-icon>
                            </v-btn> -->
                            <v-btn @click="selectedCompFromPage('ProviderEmpanelmentQualityTeaching')" dark  tile color="#23B1A9" class="text-capitalize px-3 ml-2" small><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider> OK</v-btn>

                        </v-card-actions>
                    </v-card>
                </v-dialog>


    <div class="mx-2 pa-5" style="background-color: #F7F7F7; display:flex;justify-content:space-between"  >
                <v-layout style="flex:1" justify-end > 
                    <!-- <v-btn dark  tile color="#E46A25" class="text-capitalize px-3 mx-2" small><v-icon dark small>cancel</v-icon> <v-divider vertical class="mx-2"></v-divider>Cancel</v-btn>

                    <v-btn dark  tile color="#D1121B" class="text-capitalize px-3 mx-2" small><v-icon dark small>delete</v-icon> <v-divider vertical class="mx-2"></v-divider>Clear</v-btn>
                    <v-btn dark  tile color="#23B1A9" class="text-capitalize px-3 ml-2" small @click="Speciality_save()"><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider> Save</v-btn>
                    <v-btn dark  tile color="black" class="text-capitalize px-3 ml-4" small><v-icon dark small>arrow_forward</v-icon> <v-divider vertical class="mx-2"></v-divider> Next</v-btn>
                </v-layout>
            </div>
        </v-form> -->
                    <v-btn dark  tile color="#E46A25" class="text-capitalize px-3 mx-2" small @click="cancelData()"><v-icon dark small>cancel</v-icon> <v-divider vertical class="mx-2"></v-divider>Cancel</v-btn>
                    <v-btn dark  tile color="#D1121B" class="text-capitalize px-3 mx-2" small @click="spclty_Clear()"><v-icon dark small >delete</v-icon> <v-divider vertical class="mx-2"></v-divider>Clear</v-btn>
                    <v-btn dark  tile color="#23B1A9" class="text-capitalize px-3 ml-2" small @click="spclty_save()"><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider> Save</v-btn>
                    <v-btn dark  tile color="black" class="text-capitalize px-3 ml-4" small @click="nextBtn()"><v-icon dark small>arrow_forward</v-icon> <v-divider vertical class="mx-2"></v-divider> Next</v-btn>
                </v-layout>
            </div>
            </v-form>

        </div>
        
    </v-app>
</template>

<script>
export default {
    inject : ['selectedCompFromPage', 'arrToNavCancel'],
    computed: {
        requiredIfCompany() { 
            console.log(this.speciality,"this.speciality")
            this.isrequired=false
            for(let i=0;i<this.speciality.length;i++){
                if(this.speciality[i]==="Other"){
                    this.isrequired=true
                }
            }
            console.log(this.isrequired,"this.isrequired",this.other,"this.other",(this.isrequired && this.other===''))
            if(this.isrequired && this.other==='') {
                return 'This Field is Required.';
            }
                return true;
           
        },
         likesAllFruit () {
            return this.speciality.length === this.specialityItems.length
            },
        likesSomeFruit () {
            return this.speciality.length > 0 && !this.likesAllFruit
        },
        icon () {
            if (this.likesAllFruit) return 'mdi-close-box'
            if (this.likesSomeFruit) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },
    },
    created() {
        let userID = this.$store.state.empanelProviderData.empanelment._id
    this.getEmpanelmentDetailsbyId(userID)
  },

    data() {
        return {
            confirmDialogCancel:false,
            confirmDialogNext:false,
            spclvalid:false,
            // speciality:'',
            other:'',
            specialityItems:["Internal Medicine","General Surgery","Obstetrics & Gynecology","Pediatrics","Orthopedic","Gastroenterology & Hepatology","Nephrology","Urology","Endocrinology","Rheumatology","Pulmonology","Ophthalmology","Otorhinolaryngology","Cardiology","Cardiothoracic Vascular Surgery (CTVS)","Interventional Radiology","Thoracic Surgery","Neurology","Neurosurgery","Neuropsychiatry","Medical Oncology","Surgical Oncology","Radiation Oncology","Gynecological Oncology","GI Surgery","Colorectal Surgery/Proctology","Transplant Surgery","Hand Surgery","Plastic and Reconstructive Surgery","Pediatric Surgery","Neonatology","Psychiatry","Dermatology","Anesthesiology","Dental - Orthodontics","Dental - Endodontics","Dental - Oral and Maxillofacial Surgery","Dental - Periodontics","Dental - Prosthodontics","Ayurveda","Unani","Siddha","Homeopathy","Other"],
           
            // required(){
            //     return v=>v && v.length>0 || "This Field is Required"
            // },
            otherrequired(){
                return v=>v && v.length>0 || v=='Other'||"This Field is Required"
            },

            speciality:[],
            isrequired:false,
            spcltyvalid:false,
            // other:'',
            // specialityItems:["Internal Medicine","General Surgery","Obstetrics & Gynecology","Pediatrics","Orthopedic","Gastroenterology & Hepatology","Nephrology","Urology","Endocrinology","Rheumatology","Pulmonology","Ophthalmology","Otorhinolaryngology","Cardiology","Cardiothoracic Vascular Surgery (CTVS)","Interventional Radiology","Thoracic Surgery","Neurology","Neurosurgery","Neuropsychiatry","Medical Oncology","Surgical Oncology","Radiation Oncology","Gynecological Oncology","GI Surgery","Colorectal Surgery/Proctology","Transplant Surgery","Hand Surgery","Plastic and Reconstructive Surgery","Pediatric Surgery","Neonatology","Psychiatry","Dermatology","Anesthesiology","Dental - Orthodontics","Dental - Endodontics","Dental - Oral and Maxillofacial Surgery","Dental - Periodontics","Dental - Prosthodontics","Ayurveda","Unani","Siddha","Homeopathy","Other"],
            

            required(){
                return v=>v && v.length>0 || "This Field is Required"
            },
            minLength(minLength){
                return v=>v && v.length>=minLength || `Must be atleast ${minLength} Characters`
            },
            maxLength(maxLength){
                return v=>v && v.length<=maxLength || `Must be less than ${maxLength} Characters`
            },
            spclChar(){
                return v=>/^[A-Za-z0-9 ]+$/.test(v) || `Special Characters Not Allowed`
            },
            alphaNumeric(){
                return v=>/^[0-9 ]+$/.test(v) || `Only Numbers are allowed.`
            },
            numberRule: v  => {
                if (!v.trim()) return true;
                if (!isNaN(parseFloat(v)) && v >= 0 && v <100) return true;
                return 'Values between 0 and 100 are accepted';
            },
            decimalRule(){
                return v=>/^\d+(\.\d{1,2})?$/.test(v) || `Numeric upto two decimals.`
            }
 
            }
    },
    methods:{
        spclty_save(){
            // console.log(this.speciality,"speciality")
            // return
            if (this.$refs.spcltyForm.validate()){

                let userID = this.$store.state.empanelProviderData.empanelment._id
                // let _proviType = this.$store.state.empanelProviderData.empanelment.providerType

                let formData={
                    _id: userID,
                    specialties: this.speciality,
                    specialtiesOthers: this.other
                    
                }
                console.log(formData,"=========spcltyForm Data")
                let apiUrl='empanelment/save-specialties'
                this.PUT(2,apiUrl,formData,(res,error)=>{
                    if(!error){
                          console.log("Put APi response|-> empanelment/save-staff-details ", res)
                          this.showToast(res.data.msg,this.TOST().SUCCESS)
                          this.getEmpanelmentDetailsbyId(userID)
                        //   this.$router.push('/homePage');
                    }else{
                        console.log("Put APi error response|-> empanelment/save-staff-details ", error)
                        this.showToast(error.response.data.msg ,this.TOST().ERROR);
                    }
                })
            }else{
                this.$refs.spcltyForm.validate()
                this.showToast("Please Fill Required Fields",this.TOST().WARNING);
            }
        },
        spclty_Clear(){
            this.$refs.spcltyForm.resetValidation()
            this.speciality=[]
            this.other=''

        },
        toggle () {
            this.$nextTick(() => {
                if (this.likesAllFruit) {
                this.speciality = []
                } else {
                this.speciality = this.specialityItems.slice()
                }
            })
    },
        // chngSpclty(){
        //     let clearOthr=true
        //     for(let i=0;i<this.speciality.length;i++){
        //         if(this.speciality[i]==="Other"){
        //             clearOthr=false
        //         }  
        //     }
        //     console.log(clearOthr,"clearOthr")
        //     if(clearOthr){
        //             this.other=''
        //         }

        // },
        getEmpanelmentDetailsbyId(id){
            let api_Is = 'empanelment/empanelment-details-by-id/'+id
            this.GET(2,api_Is,(res,error)=>{
                     try{
                         if(!error){
                            console.log("GET APi response|-> empanelment/empanelment-details-by-id", res.data.data)
                            // this.showToast(res.data.msg,this.TOST().SUCCESS)
                            let tempData= res.data.data
                            this.speciality=tempData.hasOwnProperty('specialties')? this.chkplndataarry(tempData.specialties) : []
                            this.other=tempData.hasOwnProperty('specialtiesOthers')? this.chkplndata(tempData.specialtiesOthers) : ''
                            console.log(this.speciality,"this.speciality")
                            this.$store.commit("EMPANEL_TAB_STATUS", tempData);
                        }else{
                            console.log("GET APi error response|->empanelment/empanelment-details-by-id", error)
                            // this.showToast(err.response.data.msg ,this.TOST().ERROR);
                        } 
                    }catch{
                        console.log("GET APi  errorresponse|-> empanelment/empanelment-details-by-id", error)
                    }       
            })
        },
        chkplndataarry(data){
            console.log("chkplndataarry",data); 
            try{
                if (data ==="" || data === "undefined" || data === null) {
                    return []
                } else {
                    return data;
                }

             }catch(err) {
                console.log(err)
            }
            
        },

        chkplndata(data){
            console.log("chkplndata",data); 
            try{
                if (data ==="" || data === "undefined" || data === null) {
                    return ""
                } else {
                    return data;
                }

             }catch(err) {
                console.log(err)
            }
            
        },

        nextBtn(){
                console.log("%c this is data for next btn on OwnershipTab","color:green",this.$store.state.empanelmentTabStatus);
                if(this.$store.state.empanelmentTabStatus.specialityStatus === false){
                    this.confirmDialogNext = true
                }else{
                        this.selectedCompFromPage('ProviderEmpanelmentQualityTeaching')
                }
            },

        cancelData(){
            this.confirmDialogCancel = true
        },
        close_cancelDialog(){
            this.confirmDialogCancel = false
            this.confirmDialogNext = false
        },
    }

    // methods: {

    //     Speciality_save(){
    //         if (this.$refs.spclForm.validate()){
    //             this.showToast("Success",this.TOST().SUCCESS);
    //             let formData={
    //                 _id:"5ff4127f3af7b5cec8d52d5f",
                    
    //                 specialties:this.speciality,
    //                 specialtiesOthers:this.other,
                    
    //             }
    //             console.log(formData,"=========specialityForm Data")
    //             let apiUrl='empanelment/save-specialties'
    //             this.PUT(2,apiUrl,formData,(res,error)=>{
    //                 if(res!==null){
    //                       console.log("Put APi response|-> empanelment/save-specialties ", res)
    //                     //   this.showToast(res.data.msg,this.TOST().SUCCESS)
    //                     //   this.$router.push('/homePage');
    //                 }else if(res===null){
    //                     console.log("Put APi error response|-> empanelment/save-specialties ", err)
    //                     // this.showToast(err.response.data.msg ,this.TOST().ERROR);
    //                 }
    //             })
    //         }else{
    //             this.$refs.spclForm.validate()
    //             this.showToast("Please Fill Required Fields",this.TOST().WARNING);
    //         }

    //     }
    // },
    //     Numeric2(v){

    //         if (v.length !== 0 ) {
    //             console.log("testing",/^[0-9 ]+$/.test(v))
    //             if(this.speciality.test(v)=='Other'){
    //                 return "Only Numbers are allowed."
    //             }else{
    //             console.log("else none")
                
    //             }
    //         },

    
}
    
    
        
</script>

<style>

.cust-rmvpd-psins3 .v-text-field.v-text-field--enclosed>.v-input__control>.v-input__slot {
    padding-right: 0px !important;
}

.cust_pr2 .v-text-field .v-input__slot,
.v-text-field--outline .v-input__slot {
    min-height: 42px !important;
    display: flex !important;
    align-items: center !important;
    /* border:1px red solid; */
}

.cstm-clr-pr3{
    color:#646666 !important;
    margin-bottom: 5px !important;
    font-size: 13px !important;
}

.cust_pd1 .v-text-field .v-input__slot,
.v-text-field--outline .v-input__slot {
    min-height: 42px !important;
    display: flex !important;
    align-items: center !important;
    /* border:1px red solid; */
}

.cust-rmvpd-pr1 .v-text-field.v-text-field--enclosed>.v-input__control>.v-input__slot {
    padding: 0px !important;
}


</style>