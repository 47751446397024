<template>
   <div>
      <div class="d-flex padding-common bg-color">
         <v-text-field
            label="Claim Number"
            type="text"
            class="ml-3 mr-3 width-common"
            v-model="filters.claimNumber"
            maxlength="10"
            :rules="[numberRule(filters.claimNumber), characterLength(filters.claimNumber)]"
         ></v-text-field>
         <v-select
            label="Claim Type"
            :items="claimTypeItems"
            class="ml-3 mr-3 width-common"
            v-model="filters.claimType"
         ></v-select>
         <v-select
            label="Processing Type"
            :items="processingType"
            class="mr-3 width-common"
            v-model="filters.processingType"
         ></v-select>
         <v-autocomplete
            label="Bot Status Code"
            :items="botStatusCodesList"
            class="width-common"
            v-model="filters.botStatusCode"
            @change="handleOnStatusChange"
         ></v-autocomplete>
          <div class="width-fix pl-3">
          <v-autocomplete
            label="Bot Status Type"
            :items="botStatusTypeItems"
            v-model="filters.botStatusType"
            @change="handleOnTypeChange"
         ></v-autocomplete>
        </div>
       </div>
       <div class="d-flex padding-common bg-color">
        <button @click="filterItems" class="search-btn">Search</button>
        <button @click="clearItems" class="clear-btn">Clear</button>
         <v-col class="d-flex align-right pl-1 justify-end">
        <v-btn
          dark
          class="text-capitalize mr-4"
          color="#E46A25"
          depressed
          @click="exportToExcel"
        >
          <v-icon>file_download</v-icon
          ><v-divider class="mx-2 ml-2 white" vertical></v-divider>Export To
          Excel
        </v-btn>
      </v-col>
       </div>
       <div class="padding-common">
         <v-data-table
            :headers="headers"
            :items-per-page="itemsPerPage"
            :page-sync="page"
            :items="botFilteredItems"
            hide-default-footer
         >
           <template v-slot:item.createdAt="{ item }">
            <span>{{ formatDate(item.createdAt) }}</span>
          </template>
          <template v-slot:item.updatedAt="{ item }">
            <span>{{ formatDate(item.updatedAt) }}</span>
          </template>
           <template v-slot:item.botStatusCode="{ item }">
            <span>{{ item.botStatusCode === 0 ? '000' : item.botStatusCode }}</span>
          </template>
           <template v-slot:item.actions="{ item }">
              <v-icon
                size="small"
                class="me-2"
                v-if="item.processingType !== 'Manual'"
                @click="editItem(item)"
              >
                mdi-pencil
              </v-icon>
            </template>
         </v-data-table>
         <div class="mt-2 text-end">
          <span
                >Showing {{ botItems.length > calculatePageInterval[0] + 1 ? calculatePageInterval[0] + 1 :  botItems.length}}-{{ botItems.length > calculatePageInterval[1] ? calculatePageInterval[1] :  botItems.length}} &nbsp;</span
              >
           <span> out of {{ botItems.length }} records</span>
         </div>
         <div class="pagination-style">
          <v-pagination v-model="page" @input="changeItemList" :length="pageCount" :total-visible="7"></v-pagination>
        </div>
       </div>
        <v-row>
    </v-row>
       <v-dialog
        v-model="isEdit"
        activator="parent"
        width="auto"
        class="dialog-style"
        persistent
      >
        <v-card class="card-container">
          <div class="mt-6 pl-6 input-container">
            <label class="font-size-common">BOT Processing Type</label>
            <v-select
              solo
              label="Enter..."
              :items="processingType"
              v-model="botProcessingType"
              class="rounded-0 input-style-common"
            >
          </v-select>
          </div>
          <div class="mt-6 pl-6 input-container width-common">
            <label class="font-size-common">BOT Status Code</label>
            <v-autocomplete
              solo
              label="Enter..."
              :items="filterStatusCodeList"
              v-model="botStatus"
              class="rounded-0 input-style-common"
            >
            </v-autocomplete>
          </div>
          <div class="mt-6 pl-6 pr-6 input-container">
            <label class="font-size-common">BOT Status Type</label>
            <v-autocomplete
              solo
              label="Enter..."
              :items="filterStatusTypeList"
              v-model="botStatusType"
              class="rounded-0 input-style-common"
            >
            </v-autocomplete>
          </div>
        </v-card>
         <v-card v-if="botProcessingType === 'Manual'" class="md-12 remark-input">
            <v-col class="mt-3 ml-2 md-12">
              <label class="font-size-common">Remarks</label>
              <v-text-field
                solo
                label="Enter..."
                v-model="botRemarks"
                class="md-12"
              >
              </v-text-field>
            </v-col>
          </v-card>
         <v-card-actions class="d-flex justify-end">
             <v-btn  @click="updateBotItem()">Update</v-btn>
             <v-btn  @click="close()">Close</v-btn>
          </v-card-actions>
      </v-dialog>
   </div>
</template>
<script>
import axios from 'axios';
import moment from 'moment';
import {WE_CARE_BASE_URL} from '../../../../src/common/API_Config';
import {dateAndTimeFormat,claimTypeItems,botStatusTypeItems,botWorkItemsHeaders, processingType} from '../../../constants';
  export default {
    data () {
      return {
        filters:{
          claimNumber: '',
          claimType: '',
          botStatusCode: '',
          botStatusType: '',
          processingType: ''
        },
        processingType: processingType,
        botStatusTypeItems: botStatusTypeItems.map(item => item.type),
        claimTypeItems: claimTypeItems,
        botStatusCodesList: botStatusTypeItems.map(item => item.statusCode),
        botFilteredItems: [],
        botItems: [],
        idSelected: '',
        pageCount: 0,
        page:1,
        botStatus: '',
        botProcessingType: '',
        botStatusType: '',
        itemsPerPage: 20,
        isEdit: false,
        headers: botWorkItemsHeaders,
        botRemarks: ''
      }
    },
    async created(){
       this.getAllBotItems();
    },
    watch: {
      botStatus: function(val){
        this.selectAllBotType({statusCode: val}, 'Modal Changes');
      },
      botStatusType: function(val){
        this.selectAllBotType({type: val}, 'Modal Changes');
      }
    },
    computed:{
      calculatePageInterval() {
        return [this.itemsPerPage*(this.page-1), this.itemsPerPage*((this.page-1) + 1)]
      },
      filterStatusCodeList(){
        if(this.botProcessingType !== 'Manual'){
          return this.botStatusCodesList.filter(item=>item !== "000");
        }
        return ['000'];
      },
      filterStatusTypeList(){
         if(this.botProcessingType !== 'Manual'){
          return this.botStatusTypeItems.filter(item=>item !== 'Manual');
        }
        return ['Manual'];
      },
    },
    methods:{
     numberRule(value) {
            // parse the input value for number
          if(!value) {
              return true
          }
          if(isNaN(Number(value))) {
              return "Numeric values only"
          }
      },
      characterLength(val) {
        if(val.length !== 10 && val.length > 0){
          return 'Number Must Be Equal To 10'
        }
      },
      selectAllBotType(payload, flag){
        const filteredType = botStatusTypeItems.filter(item => payload?.statusCode === item.statusCode || payload?.type === item.type)[0];
        if(flag == 'Modal Changes') {
            this.botStatus = filteredType?.statusCode;
            this.botStatusType = filteredType?.type;
            return;
        }
        this.filters.botStatusCode = filteredType && filteredType.statusCode ? filteredType.statusCode : '';
        this.filters.botStatusType = filteredType && filteredType.type ? filteredType.type : '';
      },
      async exportToExcel() {
        try {
          await this.filterApi(true);
        } catch (e) {
          this.showToast(e.response?.data?.msg || e.response?.data  || e.response || e, this.TOST().ERROR);
        }
      },
      handleOnTypeChange(){
        this.selectAllBotType({type: this.filters.botStatusType}, '');
      },
      handleOnStatusChange(){
        this.selectAllBotType({statusCode: this.filters.botStatusCode}, '');
      },
      filterType(type){
        if(type == 'botStatus'){
          this.selectAllBotType({statusCode: this.filters.statusCode}, '');
          return;
        }
        this.selectAllBotType({type: this.filters.botStatusType}, '');
      },
      formatDate(dateTime){
        return moment(dateTime).format(dateAndTimeFormat)
      },
      setPageCount(itemLength, itemPerPage){
        if((itemLength.length / itemPerPage) % 1 == 0) {
              this.pageCount = parseInt(itemLength.length / itemPerPage);
              return;
            }
        this.pageCount = parseInt(itemLength.length / itemPerPage) + 1;
      },
      async getAllBotItems(){
        await axios.get(`${WE_CARE_BASE_URL}/claims-registration/bot-status/get-bot-status`)
          .then((res) => {
            this.botItems = res.data.data;
            this.botFilteredItems = [...this.botItems];
            this.setPageCount(this.botItems, this.itemsPerPage)
          }).catch((err) => {
            this.showToast(err && err.data && err.data.msg ? err.data.msg : "Something went wrong..")
          })
      },
      async filterApi(isDownload=false){
        const apiURL = `${WE_CARE_BASE_URL}/claims-registration/bot-status/get-bot-status?claimNumber=${this.filters.claimNumber}&claimType=${this.filters.claimType}&botStatusCode=${this.filters.botStatusCode}&botStatusType=${this.filters.botStatusType}&processingType=${this.filters.processingType}${isDownload ? '&isDownload=' + isDownload: ''}`
        await axios.get(
          apiURL)
        .then((res) => {
          if(Array.isArray(res.data.data)) {
            this.botFilteredItems = [...res.data.data] ;
            this.botItems = [...res.data.data] ;
            this.setPageCount(this.botFilteredItems, this.itemsPerPage)
          } else {
            window.open(res.data.data);
          }
        }).catch((err) => {
          this.showToast(err && err.data && err.data.msg ? err.data.msg : "Something went wrong..")
        })
      },
      async filterItems(){
        if(this.filters.claimNumber && (this.numberRule(this.filters.claimNumber) || this.characterLength(this.filters.claimNumber))){
          this.showToast("Please fill correct Claim Number", this.TOST().ERROR);
          return;
        }
        await this.filterApi(false);
      },
      clearItems() {
        const allKeys = Object.keys(this.filters);
        allKeys.forEach((key) => {
          this.filters[key] = '';
        })
        this.filterApi();
      },
      prePopulateDataOnDialog(item){
        this.botStatusType = item.botStatusType;
        this.botProcessingType = item.processingType,
        this.botStatus = item.botStatusCode;
      },
      editItem(item){
        this.isEdit = true;
        this.idSelected = item._id;
        this.prePopulateDataOnDialog(item);
      },
      close(){
        this.isEdit = false
      },
      changeItemList(){
        let [startIndex, endIndex] = this.calculatePageInterval;
        this.botFilteredItems = this.botItems.slice(startIndex, endIndex);
      },
      getPayload(){
        return {
            "botStatusType": this.botStatusType,
            "processingType": this.botProcessingType,
            "botStatusCode": this.botStatus,
            "remarks": this.botRemarks
        }
      },
      async updateBotItem(){
        if(!(this.botStatusType && this.botProcessingType && this.botStatus)){
          this.showToast('Please Enter All The Details', this.TOST().ERROR);
          return
        }
        await axios.put(
          `${WE_CARE_BASE_URL}/claims-registration/bot-status/update-bot-status/${this.idSelected}`, this.getPayload()
        ).then((data) => {
           this.filterItems();
           this.idSelected = '';
           this.close();
           this.showToast("Bot Item Updated Successfully", this.TOST().SUCCESS);
        }).catch((err) => {
          console.log(err);
        })
      }
    }
  }
</script>
<style lang="scss" scoped>
 .padding-common{
   padding: 16px;
 }
 .bg-color {
   background: #c0c0c02e;
 }
 .v-card {
    height: 550px;
    display: flex;
 }
 .input-style-common {
  width: 100%;
 }
 .card-container {
    height: 160px;
 }
 .card-container .input-container{
  width: 100%;
 }
 .dialog-style {
  background: #faf2f2;
 }
 .search-btn {
    width: 155px;
    background: $clr-orange;
    color: $clr-black;
    height: 40px;
    border-radius: 8px;
    margin: 8px 0px 0px 8px; 
 }
 .pagination-style {
  display: flex;
  justify-content: flex-end;
  align-items: center;
 }
 ::v-deep .v-dialog--active {
  background: $clr-white;
 }
 .font-size-common {
  font-size: 12px;
 }
 .clear-btn {
  color: #faf2f2;
  background-color: #ec3434;
  height: 40px;
  border-radius: 8px;
  margin: 8px 0px 0px 8px; 
  width: 155px;
 }
 .width-common {
   ::v-deep .v-input__slot {
    width: 185px;
   }
 }
 ::v-deep .v-input__slot {
  width: 350px;
 }
 .width-fix {
  width: 400px;
 }
 .remark-input {
  width: 100%;
  height: 120px;
 }
 ::v-deep .remark-input .v-input__slot {
  width: 50%;
 }
</style>
