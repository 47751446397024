<template>
  <div>
    <v-container fluid>
      <v-form ref="form">
      <v-layout row>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              FIR/GDR details available (Y/N) *
            </v-text>
            <v-select
              :items="['Yes', 'No']"
              label="Select"
              v-model="fIROrGDRVerificationDetails.fIROrgDRDetailsAvailable"
              :rules="rules.mandatoryrule"
              solo
              item-text="state"
              item-value="state"
              class="rounded-0"
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              FIR/GDR Number *
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="fIROrGDRVerificationDetails.fIROrGDRNumber"
              maxlength="20"
              counter="20"
              class="rounded-0"
              :disabled="disableAllFields"
              aria-required="true"
              :rules="!disableAllFields && [required()]"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Gist of FIR/GD *
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="fIROrGDRVerificationDetails.gistOfFIROrGD"
              maxlength="300"
              counter="300"
              class="rounded-0"
              aria-required="true"
              :disabled="disableAllFields"
              :rules="!disableAllFields && [required()]"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Whether any FIR/GD was registered on the date of accident (Yes/No) *
            </v-text>
            <v-select
              :items="['Yes','No']"
              label="Select"
              v-model="fIROrGDRVerificationDetails.whetherAnyFIROrGdRegisteredOndateOfAccident"
              :disabled="disableAllFields"
              :rules="!disableAllFields && rules.mandatoryrule"
              solo
              item-text="state"
              item-value="state"
              class="rounded-0"
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Name of the Police station *
            </v-text>
            <v-autocomplete
              :items="policeStationList"
              solo
              label="Enter to search..."
              v-model="fIROrGDRVerificationDetails.nameofPoliceStation"
              maxlength="200"
              :search-input.sync="searchPoliceStation"
              counter="200"
              item-text="policeStationName"
              item-value="policeStationName"
              class="rounded-0"
              :disabled="disableAllFields"
              :rules="!disableAllFields && [
                required(),
              ]"
              @change="policeStationChangeHandler(fIROrGDRVerificationDetails.nameofPoliceStation)"
            >
            </v-autocomplete>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Police station city/District *
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="fIROrGDRVerificationDetails.policeStationCityOrDistrict"
              maxlength="20"
              counter="20"
              class="rounded-0"
              aria-required="true"
              :disabled="disableAllFields"
              :rules="!disableAllFields && [
                required(),
              ]"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Police station State *
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="fIROrGDRVerificationDetails.policeStationState"
              maxlength="20"
              counter="20"
              class="rounded-0"
              aria-required="true"
              :disabled="disableAllFields"
              :rules="!disableAllFields && [required()]"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Police station Pin code *
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="fIROrGDRVerificationDetails.policestationPinCode"
              class="rounded-0"
              aria-required="true"
              :disabled="disableAllFields"
              :rules="!disableAllFields && rules.mandatoryrule"
            >
            </v-text-field
              >
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Date of FIR *
            </v-text>
            <v-menu
              ref="calender"
              :close-on-content-click="true"
              :nudge-right="40"
              transition="scale-transition"
              max-width="290px"
              min-width="290px"
              class="rounded-0"
            >
              <!-- :rules="typeOfEmployee !== 'Service ID'?[required()]:[()]" -->
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-bind="attrs"
                  label="Select.."
                  :value="computedDate('dateOfFIR')"
                  solo
                  class="rounded-0"
                  :rules="!disableAllFields && rules.mandatoryrule"
                  :disabled="disableAllFields"
                  v-on="on"
                >
                  <div
                    slot="prepend-inner"
                    style="background-color: #f7f7f7; padding: 9px !important"
                  >
                    <v-icon>event</v-icon>
                  </div>
                </v-text-field>
              </template>
              <v-date-picker v-model="fIROrGDRVerificationDetails.dateOfFIR" no-title @change="dateDifference()"> </v-date-picker>
            </v-menu>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Time of FIR *
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              type="time"
              :disabled="disableAllFields"
              v-model="fIROrGDRVerificationDetails.timeOfFIR"
              maxlength="20"
              counter="20"
              class="rounded-0"
              aria-required="true"
              :rules="!disableAllFields && [required()]"
            >
            </v-text-field>
          </v-layout>
          <!-- <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Time of FIR
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              type="time"
              v-model="fIROrGDRVerificationDetails.timeOfFIR"
              :disabled="fIROrGDRVerificationDetails.fIROrgDRDetailsAvailable == 'No' ? true : false"
              maxlength="20"
              counter="20"
              class="rounded-0"
              aria-required="true"
              :rules="[alphaNumeric(fIROrGDRVerificationDetails.timeOfFIR, 'Time of FIR')]"
            >
            </v-text-field>
          </v-layout> -->
        </v-flex>
        <!-- <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Time of FIR
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="fIROrGDRVerificationDetails.timeOfFIR"
              :disabled="fIROrGDRVerificationDetails.fIROrgDRDetailsAvailable == 'No' ? true : false"
              maxlength="20"
              counter="20"
              class="rounded-0"
              aria-required="true"
              :rules="[alphaNumeric(fIROrGDRVerificationDetails.timeOfFIR, 'Time of FIR')]"
            >
            </v-text-field>
          </v-layout>
        </v-flex> -->
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              IPC/MVA sections
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="fIROrGDRVerificationDetails.ipcOrMvaSections"
              :disabled="disableAllFields"
              maxlength="100"
              counter="100"
              class="rounded-0"
              aria-required="true"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Full Name of the IO
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="fIROrGDRVerificationDetails.fullNameOfIO"
              :disabled="disableAllFields"
              maxlength="200"
              counter="200"
              class="rounded-0"
              aria-required="true"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Date of accident *
            </v-text>
            <v-menu
              ref="calender"
              :close-on-content-click="true"
              :nudge-right="40"
              transition="scale-transition"
              max-width="290px"
              min-width="290px"
              class="rounded-0"
            >
              <!-- :rules="typeOfEmployee !== 'Service ID'?[required()]:[()]" -->
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-bind="attrs"
                  label="Select.."
                  :disabled="disableAllFields"
                  :value="computedDate('dateofaccident')"
                  :rules="!disableAllFields && rules.mandatoryrule"
                  solo
                  class="rounded-0"
                  v-on="on"
                >
                  <div
                    slot="prepend-inner"
                    style="background-color: #f7f7f7; padding: 9px !important"
                  >
                    <v-icon>event</v-icon>
                  </div>
                </v-text-field>
              </template>
              <v-date-picker v-model="fIROrGDRVerificationDetails.dateofaccident" no-title @change="dateDifference()">
              </v-date-picker>
            </v-menu>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Time of Accident *
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              type="time"
              :disabled="disableAllFields"
              v-model="fIROrGDRVerificationDetails.timeofAccident"
              maxlength="20"
              counter="20"
              class="rounded-0"
              aria-required="true"
              :rules="!disableAllFields && [required()]"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Place of Accident *
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              :disabled="disableAllFields"
              v-model="fIROrGDRVerificationDetails.placeOfAccident"
              maxlength="100"
              counter="100"
              class="rounded-0"
              aria-required="true"
              :rules="!disableAllFields && [required()]"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Delay in FIR (days) *
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="fIROrGDRVerificationDetails.delayInFIR"
              disabled
              maxlength="3"
              counter="3"
              class="rounded-0"
              aria-required="true"
              :rules="!disableAllFields && showDelayDate && [required(),numericDays()]"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Reasons For Delay {{ showDelayDate ? "*" : ''}}
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              :disabled="disableAllFields"
              v-model="fIROrGDRVerificationDetails.reasonsOfDelay"
              maxlength="500"
              counter="500"
              class="rounded-0"
              aria-required="true"
              :rules="!disableAllFields && showDelayDate && [required()]"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Name of the Complainant
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              
              v-model="fIROrGDRVerificationDetails.nameOfcomplainant"
              maxlength="100"
              :disabled="disableAllFields"
              counter="100"
              class="rounded-0"
              aria-required="true"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              City of the Complainant
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="fIROrGDRVerificationDetails.cityOfComplainant"
              :disabled="disableAllFields"
              maxlength="3"
              counter="3"
              class="rounded-0"
              aria-required="true"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              State of the Complainant
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="fIROrGDRVerificationDetails.stateOfComplainant"
              maxlength="3"
              counter="3"
              :disabled="disableAllFields"
              class="rounded-0"
              aria-required="true"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Mobile Number of the Complainant
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="fIROrGDRVerificationDetails.mbNumberOfComplainant"
              maxlength="10"
              :disabled="disableAllFields"
              counter="10"
              class="rounded-0"
              aria-required="true"
              :rules="[numericOnly(fIROrGDRVerificationDetails.mbNumberOfComplainant)]"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Relationship of the complainant with injured/deceased
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="fIROrGDRVerificationDetails.relationshipOfcomplainantWithInjured"
              maxlength="100"
              counter="100"
              class="rounded-0"
              :disabled="disableAllFields"
              aria-required="true"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Is the offending vehicle/s named in the FIR ( Yes/ No) *
            </v-text>
            <v-select
              :items="['Yes','No']"
              label="Select"
              v-model="fIROrGDRVerificationDetails.isOffendingVehicleNamedInFIR"
              :disabled="disableAllFields"
              :rules="!disableAllFields && rules.mandatoryrule"
              solo
              item-text="state"
              item-value="state"
              class="rounded-0"
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Registration No. of the offending vehicle/s as per the FIR
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="fIROrGDRVerificationDetails.regNoOfVehicleAFIR"
              :disabled="disableAllFields"
              maxlength="20"
              counter="20"
              class="rounded-0"
              aria-required="true"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Registration no. of other vehicles (TPV) named in the FIR
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="fIROrGDRVerificationDetails.regNoOfOtherVehiclesNamedInFIR"
              maxlength="20"
              counter="20"
              :disabled="disableAllFields"
              class="rounded-0"
              aria-required="true"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              FIR lodged against the drivers of IV/TPV/Both/ Others
            </v-text>
            <v-select
              :items="['IV','TPV','Both','Others']"
              label="Select"
              v-model="fIROrGDRVerificationDetails.FIRLodgedAgainstDrivers"
              :disabled="disableAllFields"
              solo
              item-text="state"
              item-value="state"
              class="rounded-0"
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Name of injured/deceased as per the FIR *
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="fIROrGDRVerificationDetails.nameOfInjuredOrdeceasedAsFIR"
              maxlength="100"
              counter="100"
              :disabled="disableAllFields"
              class="rounded-0"
              aria-required="true"
              :rules="!disableAllFields && [required()]"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md3 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Whether any calls made to Police/ Ambulance (100/102/108/112)
              (Y/N)
            </v-text>
            <v-select
              solo
              :items="['Yes','No']"
              label="select"
              v-model="fIROrGDRVerificationDetails.whetherCallsToPoliceOrAmbulance"
              maxlength="10"
              counter="10"
              class="rounded-0"
              :disabled="disableAllFields"
              aria-required="true"
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex md4 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Remarks *
            </v-text>
            <v-textarea
              solo
              label="Enter..."
              v-model="fIROrGDRVerificationDetails.remarks"
              maxlength="5000"
              counter="5000"
              rows="2"
              class="rounded-0"
              aria-required="true"
              :rules="[required()]"
            >
            </v-textarea>
          </v-layout>
        </v-flex>
      </v-layout>
      </v-form>
    </v-container>
    <InvestigationFooterCard 
      :tabno="sno" :formdata="getFormData" 
      ref="footerCardRef"
      @validateTabForms="validateTabForms"
      :nonMandatoryFields="nonMandatoryFields" 
      :inputFieldCorrectlyFilled="true"
      :enableFieldObject="getEnableFieldObject"
      :componentName="'fIROrGDRVerificationDetails'"
      :siuWorkItemId="siuWorkItemId"
      :tabFormDataId="tabFormDataId"
    />
  </div>
</template>
<script>
import InvestigationFooterCard from './InvestigationFooterCard';
import {getPoliceStationDetails,getAllPinCodes,getPoliceStationInfo} from '../apiservices/Investigation';

import moment from 'moment';
 export default {
    name: 'FirVerificationDetails',
    data() {
        return {
            showDelayDate:false,
            pinNumber: null,
            policeStationList:[],
            searchPoliceStation:'',
            pincodes: [],
            sno: 14,
            lossStatesList: ['A'],
            validationmessage: "This field is required",
            nonMandatoryFields: ['ipcOrMvaSections','fullNameOfIO', 'nameOfcomplainant', 'cityOfComplainant', 'stateOfComplainant', 'mbNumberOfComplainant','relationshipOfcomplainantWithInjured',
              'regNoOfVehicleAFIR','regNoOfOtherVehiclesNamedInFIR','FIRLodgedAgainstDrivers','whetherCallsToPoliceOrAmbulance'],
            rules: {
                mandatoryrule: [(v) => !!v || this.validationmessage],
            },
            required(){
              return (v)=> !!v || this.validationmessage
            },
            fIROrGDRVerificationDetails: {
              isTabSelected: true,
              fIROrgDRDetailsAvailable: "",
              fIROrGDRNumber: "",
              gistOfFIROrGD: "",
              whetherAnyFIROrGdRegisteredOndateOfAccident: "",
              nameofPoliceStation: "",
              policeStationCityOrDistrict: "",
              policeStationState: "",
              policestationPinCode: null,
              dateOfFIR: "",
              timeOfFIR: "",
              ipcOrMvaSections: "",
              fullNameOfIO: "",
              dateofaccident: "",
              timeofAccident: "",
              placeOfAccident: "",
              delayInFIR: "",
              reasonsOfDelay: "",
              nameOfcomplainant: "",
              cityOfComplainant: "",
              stateOfComplainant: "",
              mbNumberOfComplainant: "",
              relationshipOfcomplainantWithInjured: "",
              isOffendingVehicleNamedInFIR: "",
              regNoOfVehicleAFIR: "",
              regNoOfOtherVehiclesNamedInFIR: "",
              FIRLodgedAgainstDrivers: "",
              nameOfInjuredOrdeceasedAsFIR: "",
              whetherCallsToPoliceOrAmbulance: "",
              remarks: "", 
            },
           
        }
    },
    components: {
      InvestigationFooterCard,
    },
    props: ['prepoulatedData',"siuWorkItemId", "tabFormDataId"],
    async created() {
      let allKeys = Object.keys(this.prepoulatedData);
      allKeys.forEach(key => {
        this.fIROrGDRVerificationDetails[key] = this.prepoulatedData[key]
      });
      this.dateDifference();
      let res = await getPoliceStationDetails(this.fIROrGDRVerificationDetails.policestationPinCode);
      console.log("RESPONSEAPI",res);
    },
    async mounted(){
      if(this.prepoulatedData.nameofPoliceStation) {
        this.policeStationList = await getPoliceStationInfo(this.prepoulatedData.nameofPoliceStation);
        this.fIROrGDRVerificationDetails.nameofPoliceStation = this.prepoulatedData.nameofPoliceStation;
        const foundData = this.policeStationList.find((item) => item.policeStationName === this.prepoulatedData.nameofPoliceStation);
      this.fIROrGDRVerificationDetails.policeStationState = foundData.state;
      this.fIROrGDRVerificationDetails.policeStationCityOrDistrict = foundData.city;
      this.fIROrGDRVerificationDetails.policestationPinCode = Number(foundData.pinCode);
      }
    },
    watch:{
      async searchPoliceStation(value) {
        if(value === null || value == undefined || value == '' || value.length < 3){
            this.policeStationList = []
            this.fIROrGDRVerificationDetails.policeStationState = '';
            this.fIROrGDRVerificationDetails.policeStationCityOrDistrict = '';
            this.fIROrGDRVerificationDetails.policestationPinCode = '';
        }
      if (value && value.length > 2) {
        // https://claimsdrive.salesdrive.app/common-masterdata/policeStationMaster/get-police-station-data?page=1&pageLimit=10&policeStationName=abc&state=&city=&pinCode=
        this.policeStationList = await getPoliceStationInfo(value)
      }
      }
    },
  computed: {
    disableAllFields(){
        if(this.fIROrGDRVerificationDetails.fIROrgDRDetailsAvailable == 'No'){
          this.clearAllFields()
          return true
        }else{
          return false
        }
      },
    emailRules2(v) {
      if (v.length !== 0) {
        console.log("testing", /^[0-9 ]+$/.test(v));
        if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,6})+$/.test(v)) {
          return "E-mail must be valid";
        }
      }
    },
    getFormData() {
      
      // console.log('timeoffirs', moment(this.dateoFIR + ' ' + this.timeOfFIR, 'DD/MM/YYYY HH:mm'));
      return {fIROrGDRVerificationDetails: {...this.$data.fIROrGDRVerificationDetails}};
    },
    getEnableFieldObject() {
      return this.enableFieldsArr = this.fIROrgDRDetailsAvailable !== 'No' ? {} : {remarks: this.remarks};
    }
  },
//   mounted() {
//     let found = this.items.find(el => el.sno == 20);
//     if(!found){
//       this.items.push({sno: 20, name: "View / Upload Documents"});
//     }
//     console.log("INDEX NUMBER 20", found);
    
//   },
  methods: {
    dateDifference(){
      if(this.fIROrGDRVerificationDetails.dateofaccident && this.fIROrGDRVerificationDetails.dateOfFIR){
        let date1 = new Date(this.fIROrGDRVerificationDetails.dateOfFIR);
        let date2 = new Date(this.fIROrGDRVerificationDetails.dateofaccident);
        let diffInTime = date1 - date2
        let diffInDays= diffInTime / (1000 * 60 * 60 * 24)
        if(diffInDays < 0){
          diffInDays = diffInDays * -1
        }
        this.fIROrGDRVerificationDetails.delayInFIR = diffInDays >= 1 ? diffInDays : ''
        this.showDelayDate = diffInDays >= 1
      }else{
        this.showToast('Plase select Date of FIR and Date of Accident',this.TOST().WARNING)
      }
    },
    validateTabForms(){
      if(!this.$refs.form.validate()){
        this.$refs.form.validate() 
        this.showToast("Please fill all mandatory fields", this.TOST().WARNING);
        return
      }else{
        this.$refs.footerCardRef.interimSave();
      }
    },
    policeStationChangeHandler(v) {
      const foundData = this.policeStationList.find((item) => item.policeStationName === v);
      this.fIROrGDRVerificationDetails.policeStationState = foundData.state;
      this.fIROrGDRVerificationDetails.policeStationCityOrDistrict = foundData.city;
      this.fIROrGDRVerificationDetails.policestationPinCode = Number(foundData.pinCode);
    },
    clearAllFields(){
          this.fIROrGDRVerificationDetails.fIROrGDRNumber = '';
          this.fIROrGDRVerificationDetails.gistOfFIROrGD = '';
          this.fIROrGDRVerificationDetails.whetherAnyFIROrGdRegisteredOndateOfAccident = '';
          this.fIROrGDRVerificationDetails.nameofPoliceStation = '';
          this.fIROrGDRVerificationDetails.policeStationCityOrDistrict = '';
          this.fIROrGDRVerificationDetails.policeStationState = '';
          this.fIROrGDRVerificationDetails.policestationPinCode = null;
          this.fIROrGDRVerificationDetails.dateOfFIR = '';
          this.fIROrGDRVerificationDetails.timeOfFIR = '';
          this.fIROrGDRVerificationDetails.ipcOrMvaSections = '';
          this.fIROrGDRVerificationDetails.fullNameOfIO = '';
          this.fIROrGDRVerificationDetails.dateofaccident = '';
          this.fIROrGDRVerificationDetails.timeofAccident = '';
          this.fIROrGDRVerificationDetails.placeOfAccident = '';
          this.fIROrGDRVerificationDetails.delayInFIR = '';
          this.fIROrGDRVerificationDetails.reasonsOfDelay = '';
          this.fIROrGDRVerificationDetails.nameOfcomplainant = '';
          this.fIROrGDRVerificationDetails.cityOfComplainant = '';
          this.fIROrGDRVerificationDetails.stateOfComplainant = '';
          this.fIROrGDRVerificationDetails.mbNumberOfComplainant = '';
          this.fIROrGDRVerificationDetails.relationshipOfcomplainantWithInjured = '';
          this.fIROrGDRVerificationDetails.isOffendingVehicleNamedInFIR = '';
          this.fIROrGDRVerificationDetails.regNoOfVehicleAFIR = '';
          this.fIROrGDRVerificationDetails.regNoOfOtherVehiclesNamedInFIR = '';
          this.fIROrGDRVerificationDetails.FIRLodgedAgainstDrivers = '';
          this.fIROrGDRVerificationDetails.nameOfInjuredOrdeceasedAsFIR = '';
          this.fIROrGDRVerificationDetails.whetherCallsToPoliceOrAmbulance = '';
          this.pinNumber = null;
    },
    dragleave(event) {
      this.visualEffect = false
    },
    browseCancel() {
      //to select attachments
    //   document.getElementById("cancelchequeupload").click()
    },
    dragover(event) {
      event.preventDefault()
      this.visualEffect = true
    },
    drop(event) {
      event.preventDefault()
    //   this.selectFile() // Trigger the onChange event manually
      this.visualEffect = false
    },
    computedDate(name) {
      return this.fIROrGDRVerificationDetails[name] ? moment(this.fIROrGDRVerificationDetails[name]).format("DD/MM/YYYY") : "";
    },
    goToPreviousTab() {
      this.sno--;
      let btnNumber = "myBtn" + this.sno;
      const elem = this.$refs[btnNumber];
      elem[0].$el.click();
    },
    goToNextTab() {
      this.sno++;
      console.log('this', this.$children.$options);
      let btnNumber = "myBtn" + this.sno;
      const elem = this.$refs[btnNumber];
      elem[0].$el.click();
    },
    switchTabs(sno, name) {
      this.sno = sno;
      this.tabName = name;
    },
    submit() {
      this.isAlertView = true;
    },
    accept() {
      this.isAlertView = true;
    },
    saveData() {},
    alphaNumeric(fieldName, name, mandatory=true) {
      console.log('fieldName', fieldName, name);
      if (fieldName && fieldName.length > 0) {
        return (v) => /^[a-zA-Z0-9 ]+$/.test(v) || "Enter valid " + name;
      }
    },
    numericOnly(val) {
      if(val && val.length > 0){
        return (v) =>/^[1-9]\d{9}$/.test(v) || `Only Numbers are allowed.`;
      }
        },
      numericDays(){
        return (v) => /^[0-9]+$/.test(v) || `Only Numbers are allowed.`;
      },
    onSubmit() {},
    browse() {},
    selectFile() {},
  },
 }
</script>