<template>
  <div class="mx-8 my-6">
    <v-row class="mb-2 m-0">
      <h3>
        Concessional and Penal for PAN Master
      </h3>
      <v-spacer></v-spacer>
      <v-btn class="btnToggle" outlined elevation="0" @click="switchToConcessionalBulkUpload">
        <v-icon>mdi-repeat</v-icon>
        <v-divider class="mx-2 ml-2 white" vertical></v-divider>
        Switch To Bulk Upload
      </v-btn>
    </v-row>

    <v-layout class="mb-0">
      <v-row>

        <v-col>
          <h4 class="mr-3">
            Search for Concessional and Penal for PAN Master
            <span class="orange--text">({{ totalRecords }})</span>
          </h4>
        </v-col>

        <v-spacer></v-spacer>

        <v-col>
          <v-btn class="btnToggle pull-right" outlined elevation="0" @click="addRateHandler" :disabled="!hasEditAccess">
            <v-icon>mdi-plus-box-outline</v-icon>
            <v-divider class="mx-2 ml-2 white" vertical></v-divider>
            Create Concessional and Penal Master
          </v-btn>
        </v-col>

      </v-row>
    </v-layout>

    <v-layout class="mb-0">
      <v-row>

        <v-col>
          <label class="input-label">Type Of Vendor</label>
          <v-select flat class="filter-select-box" outlined rule placeholder="Select" dense solo :items="vendors"
            v-model="filterTypeOfVendor" @change=""></v-select>
        </v-col>



        <v-col>
          <label class="input-label">Approved Status</label>
          <v-select flat class="filter-select-box" rule placeholder="Select" dense solo outlined
            :items="allApprovedStatusEnum" v-model="filterApprovedStatus" @change=""></v-select>
        </v-col>

        <v-col>
          <label class="input-label">Vendor Name</label>
          <v-text-field flat class="filter-select-box" placeholder="Enter" outlined solo dense v-model="filterVendorName"
            depressed />
        </v-col>

        <v-col>
          <label class="input-label">PAN Number</label>
          <v-text-field flat class="filter-select-box" placeholder="Enter" outlined solo dense v-model="filterPanNumber"
            depressed :rules="[(v) => matchRegex(v, 'alphanum')]" maxlength="10" />
        </v-col>

        <v-col>
          <label class="input-label">Financial Year</label>
          <v-select flat class="filter-select-box" rule placeholder="Select" dense solo outlined
            :items="financialYearEnum" v-model="filterFinancialYear" @change=""></v-select>
        </v-col>

        <v-col cols="3">
          <v-row class="mt-3">
            <v-col cols="6" class="ml-0">
              <v-btn class="white--text font-size-10 ml-0" color="#009688" dense solo @click="searchFilterData">
                <v-icon>mdi-arrow-right-circle</v-icon>
                <v-divider vertical class="mx-2 ml-2 white"></v-divider>
                Search
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn class="btn" dense solo outlined color="red" @click="resetFilter">
                <v-icon> mdi-backspace </v-icon>
                <v-divider vertical class="mx-2 red"></v-divider>
                Cancel
              </v-btn>
            </v-col>
          </v-row>
        </v-col>

      </v-row>
    </v-layout>


    <v-layout>
      <v-row class="m-0">
        <h4 class="mb-4 mr-3">
          Concessional and Penal for PAN Master List
        </h4>

        <v-chip v-if="showSearchChip" small close color="#20B7B0" text-color="white" label @click:close="resetFilter">
          Search Result <span v-if="totalRecords"> ({{ totalRecords }}) </span>
        </v-chip>
      </v-row>
    </v-layout>



    <v-data-table :headers="tdsRateHeaders" no-data-text="No Records" fixed-header :items="filterRateTableData"
      hide-default-footer class="vDataTableStyling">

      <template #item="{ item }">


        <tr>
          <td>
            <v-menu offset-y open-on-click>
              <template v-slot:activator="{ on, attrs }">
                <v-btn elevation="" icon v-bind="attrs" v-on="on" style="text-align: center"><v-icon>mdi
                    mdi-dots-vertical</v-icon></v-btn>
              </template>
              <v-list class="item-actions">
                <v-list-item v-for="(itemData, index) in menuItems" :key="index"
                  @click="handleActions(itemData.link, item, itemData.name)"
                  v-if="itemData.name !== 'MODIFY' || (ifPendingForApproval(item) && hasEditAccess)"
                >
                  <v-list-item-title :style="item.approveStatus === 'pending' && itemData.name === 'MODIFY' ? 'gray'
                    : itemData.color
                    ">
                    <v-icon small :style="itemData.iconColor">mdi-{{ itemData.icon }}</v-icon>&nbsp;{{ itemData.name }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </td>
          <td>{{ item.typeOfVendor }}</td>
          <td>{{ item.vendorName }}</td>
          <td>
            {{ item.panNumber }}
          </td>
          <td>
            {{ item.baseTdsRate }}
          </td>
          <td>
            {{ item.penalTdsRate }}
          </td>

          <td>
            {{ item.concessionalTdsRate }}
          </td>

          <td>
            {{ item.certificateReferenceNumber }}
          </td>

          <td>
            <span class="textTransformCapitalize" :class="approvedStatusTextColor(item.approveStatus)"> {{
              item.approveStatus }} </span>
          </td>

          <td>
            <div class="flex justify-between">
              <span class="w-150"> {{ item.remarks }} </span>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" class="more-text-icon">mdi-dots-horizontal</v-icon>
                </template>
                <span>{{ item.remarks }}</span>
              </v-tooltip>
            </div>
          </td>
        </tr>

        <tr v-if="item.isAuditTrailOpen">
          <td :colspan="tdsRateHeaders.length">

            <div class="p-5">

              <div class="flex justify-between mb-2">
                <h4>Audit Trail</h4>
                <button @click="closeAllAuditTrails()">
                  <h4 class="mr-1">CLOSE</h4>
                </button>
              </div>
              <v-data-table :headers="auditTrailTableHeaders" no-data-text="No Records" fixed-header
                :items="auditTrailTableData" hide-default-footer class="vDataTableStyling mb-5 no-wrap">

                <template v-slot:[`item.makerName`]="{ item }">
                  <span v-if="ifCreatedOrModified(item)"> {{ item.userName }} </span>
                </template>

                <template v-slot:[`item.approverName`]="{ item }">
                  <span v-if="ifApprovedOrRejected(item)"> {{ item.userName }} </span>
                </template>


                <template v-slot:[`item.updatedAt`]="{ item }">
                  <span v-if="ifCreatedOrModified(item)"> {{ formattedDate(item.createdAt) }} </span>
                </template>

                <template v-slot:[`item.approvedAt`]="{ item }">
                  <span v-if="ifApprovedOrRejected(item)"> {{ formattedDate(item.createdAt) }} </span>
                </template>

                <!-- <template v-slot:[`item.modification.0.fieldName`]="{ item }">
                  <span> {{ getFormattedAuditTrailChangeFieldName(item) }}</span>
                </template> -->

                <!-- <template v-slot:[`item.modification.0.oldValue`]="{ item }">
                  <span class="textTransformCapitalize"> {{ item?.modification[0]?.oldValue }} </span>
                </template>

                <template v-slot:[`item.modification.0.newValue`]="{ item }">
                  <span class="textTransformCapitalize"> {{ item?.modification[0]?.newValue }} </span>
                </template> -->

                <template v-slot:[`item.status`]="{ item }">
                  <span class="textTransformCapitalize" :class="actionTextColor(item.action)"> {{
                    item.action }} </span>
                </template>

                <template v-slot:[`item.remark`]="{ item }">
                  <div v-if="item.remarkText" class="flex justify-between">
                    <span class="w-150"> {{ item.remarkText }} </span>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on" class="more-text-icon">mdi-dots-horizontal</v-icon>
                      </template>
                      <span>{{ item.remarkText }}</span>
                    </v-tooltip>
                  </div>
                </template>

                <template v-slot:[`item.action`]="{ item }">
                  <v-btn plain color="#008a83" class="bold" tile :ripple="false"
                    @click="viewAllChangesOnAuditTrail(item)">View</v-btn>
                </template>

              </v-data-table>
            </div>
          </td>

        </tr>

      </template>

    </v-data-table>

    <div class="text-center graybackground pdct-stUp-bnkAcc-pgn mt-3 mx-4" v-if="filterRateTableData.length > 0">

      <v-layout row justify-end align-center class="mx-2 mt-5"
        style="margin-left: 0px !important; margin-right: 0px !important">
        <p class="mr-1 font-weight-bold body-1 mt-4" style="color: #3c3d3d; font-size: 12px !important">
          <span class="" style="color: #e46a25; font-size: 12px">
            Showing {{ currentShowingRecordsStartIndex }}-{{ lastRecordLength }}
          </span>out of {{ totalRecords }} records
        </p>
        <v-divider class="mx-2" vertical></v-divider>
        <v-pagination
          class="cust-bnkAcc-pgn"
          v-model="currentPage"
          color="#152F38"
          flat
          :total-visible="10"
          :length="totalPages"
          @input="fetchConcessionalRates"
          ></v-pagination>
      </v-layout>
    </div>

      <concessional-form-dialog
        ref="concessional-form"
        :refresh-concessional-rates="fetchConcessionalRates"
        :base-rates="baseRates"
        :penal-rates="penalRates"
      />

      <v-dialog
        transition="dialog-bottom-transition"
        v-model="isAuditTrailViewModalOpen"
        fullscreen
        class="z-index-1000"
        persistent
      >
        <concessional-audit-trail-modal
          :close-modal="closeAuditTrailViewModal"
          :audit-trail-modifications="auditTrailModifications"
        />
      </v-dialog>

  </div>

</template>

<script>
import moment from "moment";
import ConcessionalFormDialog from './ConcessionalFormDialog.vue';
import ConcessionalAuditTrailModal from './ConcessionalAuditTrailModal.vue';

export default {
  data() {
    return {
      // isEditFormModalOpen: false,
      isAuditTrailViewModalOpen: false,
      // isFormEditing: false,
      filterApprovedStatus: '',
      filterVendorName: '',
      filterPanNumber: '',
      filterTypeOfVendor: '',
      filterFinancialYear: '',
      totalRecords: 0,
      currentPage: 1,
      pageSize: 10,
      showSearchChip: false,
      currentShowingRecordsStartIndex: 0,
      menuItems: [
        {
          name: "VIEW",
          icon: "eye-outline",
          link: "view",
          color: "color:#10242B",
          iconColor: "color:#10242B",
        },
        {
          name: "MODIFY",
          icon: "pencil",
          link: "edit",
          iconColor: "color:#10242B",
          color: "color:#10242B",
        },
        {
          name: "AUDIT TRAIL",
          icon: "chart-timeline-variant",
          link: "auditTrail",
          color: "color:orange",
          iconColor: "color:orange",
        }
      ],
      allApprovedStatusEnum: [
        "Approved",
        "Rejected",
        "Pending for approval"
      ],
      financialYearEnum: Array.from({ length: 81 }, (_, i) => i + 2000),
      auditTrailTableHeaders: [
        { text: 'NTID', align: 'left', value: 'userId', sortable: false, class: "bgHeader" },
        { text: 'Name of Maker', align: 'left', value: 'makerName', sortable: false },
        { text: 'Date And Time', align: 'left', value: 'updatedAt', sortable: false },
        { text: 'Name of Checker', align: 'left', value: 'approverName', sortable: false },
        { text: 'Date And Time', align: 'left', value: 'approvedAt', sortable: false },
        { text: 'Status', align: 'left', value: 'status', sortable: false },
        { text: 'Remark', align: 'left', value: 'remark', sortable: false },
        { text: 'Action', align: 'left', value: 'action', sortable: false },
      ],
      auditTrailTableData: [],
      tdsRateHeaders: [
        { text: 'Action', align: 'left', value: 'actionEnabled', sortable: false },
        { text: 'Type of Vendor', align: 'left', value: 'typeOfVendor', sortable: false },
        { text: 'Vendor Name', align: 'left', value: 'vendorName', sortable: true, width: 200 },
        { text: 'PAN Number', align: 'left', value: 'panNumber', sortable: true },
        { text: 'Base Rate (%)', align: 'left', value: 'baseTdsRate', sortable: true },
        { text: 'Penal Rate (%)', align: 'left', value: 'penalTdsRate', sortable: true },
        { text: 'Concessional Rate (%)', align: 'left', value: 'concessionalTdsRate', sortable: true },
        { text: 'Certificate Number', align: 'left', value: 'certificateReferenceNumber', sortable: true },
        { text: 'Approval Status', align: 'left', value: 'status', sortable: true },
        { text: 'Remark', align: 'left', value: 'remark', sortable: false, width: 200 },
      ],
      rateTableData: [],
      filterRateTableData: [],
      vendors: ['Hospital', 'Service Provider', "Repairer", 'Vendor'],
      rateStatusEnum: [
        {
          text: "Active",
          value: 'active'
        },
        {
          text: "Inactive",
          value: 'inactive',
        }
      ],
      editingRowOldData: {},
      auditTrailModifications: [],
      baseRates: [],
      penalRates: [],
    }
  },
  components: {
    ConcessionalFormDialog,
    ConcessionalAuditTrailModal
  },
  computed: {
    lastRecordLength () {
      if (this.filterRateTableData.length < 10) {
        return this.currentShowingRecordsStartIndex + this.filterRateTableData.length;
      }
      return this.currentShowingRecordsStartIndex + this.pageSize;
    },
    hasEditAccess () {
      const routeName = this?.$router?.history?.current?.name;

      return this?.$store?.state?.screens_obj?.[routeName]?.isEdit;
    }
  },
  methods: {
    approvedStatusTextColor(status = '') {
      return {
        'approved': 'approvedTextColor',
        'rejected': 'mandatorycolor',
      }[status.toLowerCase()] || '';
    },
    actionTextColor(status = '') {
      return {
        'approved': 'approvedTextColor',
        'rejected': 'mandatorycolor',
      }[status.toLowerCase()] || '';
    },
    ifCreatedOrModified(item) {
      return ['Modified', 'Created'].includes(item?.action);
    },
    ifApprovedOrRejected(item) {
      return ['Approved', 'Rejected'].includes(item?.action);
    },
    ifPendingForApproval(item) {
      return item.approveStatus !== 'Pending for approval';
    },
    applyFilter() {
      this.filterRateTableData = this.polyFillProperties(this.rateTableData);
    },
    switchToConcessionalBulkUpload() {
      this.$router.push('/tds-master/concessional-tds-master-bulk');
    },
    addApproverNameProperty (data, approverName = '') {
      return data.map(d => ({
        ...d,
        approverName
      }));
    },
    viewAllChangesOnAuditTrail(item) {
      this.auditTrailModifications = this.addApproverNameProperty(item.modification, item.approverName);
      this.isAuditTrailViewModalOpen = true;
    },
    closeAuditTrailViewModal () {
      this.isAuditTrailViewModalOpen = false;
    },
    resetFilter() {
      this.filterPanNumber = '';
      this.filterTypeOfVendor = '';
      this.filterVendorName = '';
      this.filterApprovedStatus = '';
      this.filterFinancialYear = '';
      this.showSearchChip = false;
      this.fetchConcessionalRates();
    },
    async handleActions(link, data, buttonName) {
      /**
       * If user clicks on Audit Trail,
       * so toggle Audit trail view
       * otherwise open form dialog fullscreen popup.
       */
      if (link === 'auditTrail') {
        /**
         * Calling Audit Trail API here
         */
        await this.toggleAuditTrail(data);
      } else {

        /**
         * If user clicks on edit so Allow user to edit the form.
         */
        if (link === 'edit') {
          this.$refs['concessional-form'].editForm(data);
        } else {
          this.$refs['concessional-form'].openForm(data);
        }
      }
    },
    addRateHandler () {
      this.$refs['concessional-form'].editForm();
    },
    formattedDate(date) {
      return moment(date).format('DD/MM/YYYY hh:mm a');
    },
    closeAllAuditTrails() {
      this.filterRateTableData.forEach(e => {
        e.isAuditTrailOpen = false;
      })
    },
    getAuditTrailQueryParams (masterId) {
      const queryParamsObject = {
        perPage: 10,
        pageNo: 1,
        masterId,
        masterType: 'concessionalTDS',
      }

      const params = new URLSearchParams(queryParamsObject);

      return params.toString();
    },
    async toggleAuditTrail(item) {
      /**
       * Adding property action = true,
       * for view button in audit trail view.
       */
      function polyFillAuditTrailProperties(existingData) {
        const polyFillProps = {
          action: true,
        }
        return existingData.map(e => ({
          ...polyFillProps,
          ...e,
        }));
      }

      /**
       * Preserving Current Audit Trail status.
       */
      const currentAuditTrailOpenStatus = item.isAuditTrailOpen;

      /**
       * Showing loader till Audit Trail data fetch
       */
      this.showProgress();

      /**
       * API calling in order to get the Audit Trail data.
       */
      this.GETM(
        "GET_AUDIT_TRAIL",
        this.getAuditTrailQueryParams(item.uid),
        res => {
          this.auditTrailTableData = polyFillAuditTrailProperties(res?.data?.data?.data || []);
          this.hideProgress();
        },
        err => {
          console.log(err);
          this.auditTrailTableData = [];
          this.hideProgress();
        }
      );

      /**
       * Closing audit all others audit trails
       * if opening new one.
       */
      this.closeAllAuditTrails();

      /**
       * Responsible for toggle behavior
       */
      item.isAuditTrailOpen = !currentAuditTrailOpenStatus;
    },
    // editRow(item) {
    //   this.editingRowOldData = { ...item };
    //   item.isEditing = true;
    // },
    // cancelEdit(item) {
    //   // item.tdsWithPan = this.editingRowOldData.tdsWithPan;
    //   // item.status = this.editingRowOldData.status;
    //   item.isEditing = false;
    //   this.editingRowOldData = {};
    // },
    // saveTDSRate(item) {
    //   try {
    //     // call an API to save the data
    //     console.log({ item }, "calling API to Save Data");
    //     item.isEditing = false;
    //     item.approvalStatus = 'pending';
    //   } catch (err) {
    //     this.cancelEdit(item);
    //   }
    // },
    // openAuditTrailPopup(data) {
    //   console.log({ data }, "This is the audit trail fullscreen popup");
    // },
    polyFillProperties(existingData) {
      const polyFillProps = {
        isAuditTrailOpen: false,
      }
      return existingData.map(e => ({
        ...polyFillProps,
        ...e,
      }));
    },
    getQueryParams() {
      const queryParamsObject = {
        typeOfVendor: this.filterTypeOfVendor,
        panNumber: this.filterPanNumber,
        vendorName: this.filterVendorName,
        approvalStatus: this.filterApprovedStatus,
        financialYear: this.filterFinancialYear,
        pageNumber: this.currentPage,
        pageSize: this.pageSize,
      };

      const params = new URLSearchParams(queryParamsObject);

      const queryString = params.toString();

      return queryString;
    },
    searchFilterData() {
      this.showSearchChip = true;
      this.currentPage = 1;
      this.fetchConcessionalRates();
    },
    async fetchConcessionalRates() {
      /**
       * Function declaration
       */
      const afterAPI = () => {
        this.hideProgress();
        this.applyFilter();
      }

      this.showProgress();
      this.GETM(
        "GET_CONCESSIONAL_AND_PENAL_MASTER_URL",
        this.getQueryParams(),
        (res) => {
          this.rateTableData = res?.data?.data;


          this.totalPages = res.data.totalPages;
          this.totalRecords = res?.data?.totalCounts;
          this.currentShowingRecordsStartIndex = this.currentPage == 1 ? 0 : (this.currentPage - 1) * this.pageSize;
          afterAPI();
        },
        (error) => {
          console.log(error);
          afterAPI();
        },
        "CM"
      );
    },
    fetchRates () {
      this.fetchBaseRates();
      this.fetchPenalRates();
    },
    fetchBaseRates() {
      this.GETM("GET_BASE_RATE_TDS_URL", '', (res) => {
        this.baseRates = res?.data?.data || [];
      }, (error) => {
        console.log(error);
      }, "CM");
    },
    fetchPenalRates () {
      this.GETM("GET_PENAL_RATE_TDS_URL", '', (res) => {
        this.penalRates = res?.data?.data || [];
      }, (error) => {
        console.log(error);
      }, "CM");
    }
  },
  created() {
    this.$store.commit("SAVE_PAGE_HEADER", "Concessional and Penal for Pan Master");
    this.fetchRates();
    this.fetchConcessionalRates();
  },
}
</script>

<style lang="scss" scoped>
.m-0 {
  margin: 0;
}

.z-index-1000 {
  z-index: 1000;
}

.p-2 {
  padding: 2px;
}

.bold {
  font-weight: bold;
}

.pl-0 {
  padding-left: 0;
}

.p-5 {
  padding: 1rem;
  position: relative;
}

.filter-select-box {
  font-size: 12px;
  border-radius: 0;
}


.my-5 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.v-data-table__wrapper table thead th {
  color: black !important;
  font-size: 45px;
}

.input-label {
  font-size: 10px;
  font-weight: bold;
}

.btnToggle {
  text-transform: none;
  color: #fff;
  background-color: #1ebbd9;
}

.iconsize {
  font-size: 16px !important;
  font-weight: bold;
}

.tablebtn {
  background: #ffffff 0% 0% no-repeat padding-box !important;
  box-shadow: inset 0px 2px 4px #00000052 !important;
  border: 0.5px solid #c1c8cc;
  border-radius: 18px !important;
  height: 30px !important;
  text-transform: capitalize !important;
  width: 93%;
  justify-content: space-between;
  color: black !important;
  font-weight: 400 !important;
}

.tableheader {
  padding: 0;
}

.tableicons {
  font-size: 18px !important;
  color: #23b1a9 !important;
}

.approvedTextColor {
  color: #23b1a9;
}

.status-select-box {
  max-width: 100px;
  font-size: 11px;
  text-transform: capitalize;
}

.vs__dropdown-menu {
  text-transform: capitalize;
}

.flex {
  display: flex;
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.no-wrap {
  white-space: nowrap;
}

.w-200 {
  width: 200px;
}

.w-150 {
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.more-text-icon {
  background-color: lightgray;
  color: white;
  border-radius: 2px;
  font-size: 18px;
  height: 18px;
}

.w-100 {
  width: 800px;
}

.pull-right {
  float: right;
}

.theme--light.v-btn.v-btn--disabled .v-icon {
  color: white !important;
}

.btnToggle:disabled {
  opacity: .7;
  color: white !important;
}
</style>