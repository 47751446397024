<template>
<v-app>
    <div style="background-color: #FFFFFF;" column class="py-3">
         
        <v-layout class=" mt-2 mx-2" >
            <v-flex xs3>
                    <v-layout column class="cust_cpfp cust-rmvpd-psins3 mx-2 my-2 ">
                        <p class="font-weight-bold cstm-clr-pr3">
                            Policy Number
                        </p>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="policyNumber"
                            class="rounded-0"
                            :maxlength="maxCount"
                             @input="$v.policyNumber.$touch()"
                             @blur="$v.policyNumber.$touch()"
                             :error-messages="policyNumber_chk"
                            >
                        </v-text-field>
                    </v-layout>
                </v-flex>


                <v-flex xs3>
                    <v-layout column class="cust_cpfp cust-rmvpd-psins3 mx-2 my-2 ">
                        <p class="font-weight-bold cstm-clr-pr3">
                            Corporate Group Name
                        </p>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model.trim="corporateGroupName"
                             class="rounded-0"
                             :disabled="clnDsbled"
                             :maxlength="maxCount"
                             @input="$v.corporateGroupName.$touch()"
                             @blur="$v.corporateGroupName.$touch()"
                             :error-messages="corporateGroupName_chk">
                        </v-text-field>
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class="cust_cpfp custPlcyFrPdt cust-rmvpd-psins3 mx-2 my-2 ">
                        <p class="font-weight-bold cstm-clr-pr3">
                            Policy Status
                        </p>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="policyStatus"
                             class="rounded-0"
                             disabled>
                        </v-text-field>
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class=" mx-2 mt-2 cust_cpfp cust-rmvpd-pr1">                             
                    <p class="font-weight-bold cstm-clr-pr3">
                        Policy Effective Date
                    </p>
                    <v-menu
                        ref="calender"
                        :close-on-content-click="true"
                        v-model="PlcyEfctveDtecalender"
                        :nudge-right="40"
                        transition="scale-transition"
                        max-width="290px"
                        min-width="290px" 
                        hide-details 
                        class="rounded-0"
                        :disabled="clnDsbled"
                       >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-bind="attrs"
                                label="dd/mm/yyyy"
                                :value="cmputdPlcyEfctveDteFrmtdMmntjs"
                                solo 
                                class="rounded-0"
                                readonly 
                                v-on="on"
                                @input="$v.policyEffectiveDate.$touch()"
                                @blur="$v.policyEffectiveDate.$touch()"
                                :rules="[syncdateCheck()]"
                                :error-messages="policyEffectiveDate_chk">
                                <div slot="prepend-inner" style="background-color:#F7F7F7;padding: 9px !important"><v-icon>event</v-icon></div>
                            </v-text-field>
                        </template>                     
                        <v-date-picker
                            v-model="policyEffectiveDate"
                            no-title>
                        </v-date-picker>
                    </v-menu>                
                    </v-layout>
                </v-flex>

                  <v-flex xs3>
                    <v-layout column class=" mx-2 mt-2 cust_cpfp cust-rmvpd-pr1">                             
                    <p class="font-weight-bold cstm-clr-pr3">
                        Policy Expiry Date
                    </p>
                    <v-menu
                        ref="calender"
                        :close-on-content-click="true"
                        v-model="PlcyExpryDtecalender"
                        :nudge-right="40"
                        transition="scale-transition"
                        max-width="290px"
                        min-width="290px" 
                        hide-details 
                        class="rounded-0"
                        :disabled="clnDsbled"
                       >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-bind="attrs"
                                label="dd/mm/yyyy"
                                :value="cmputdPlcyExpryDteFrmtdMmntjs"
                                solo 
                                class="rounded-0"
                                readonly 
                                v-on="on"
                                @input="$v.policyExpiryDate.$touch()"
                                @blur="$v.policyExpiryDate.$touch()"
                                :rules="[dateCheck()]"
                                :error-messages="policyExpiryDate_chk">
                                <div slot="prepend-inner" style="background-color:#F7F7F7;padding: 9px !important"><v-icon>event</v-icon></div>
                            </v-text-field>
                        </template>                     
                        <v-date-picker
                            v-model="policyExpiryDate"
                            no-title
                            @change="validDateChk"
                            >
                        </v-date-picker>
                    </v-menu>                
                    </v-layout>
                </v-flex>
        </v-layout>
            
            <v-card style="background-color:#F7F7F7" tile class="pa-2 py-4 pr-3">
                <v-layout style="flex:1" justify-end > 
                    <v-btn dark  tile style="background-color:#E46A25" class="text-capitalize px-3 mx-2" small @click="$router.push('/homePage')"><v-icon dark small>arrow_back</v-icon> <v-divider vertical class="mx-2"></v-divider>Return</v-btn>
                    <v-btn :dark="!sbmtDsbled" :disabled="sbmtDsbled" tile style="background-color:#23B1A9"  class="text-capitalize px-3 ml-2" type="submit" small @click="save_plcy()"><v-icon dark small>arrow_forward</v-icon> <v-divider vertical class="mx-2"></v-divider> Submit</v-btn>
                    <v-btn :dark="!shwCEPbtn" :disabled="shwCEPbtn" tile style="background-color:#152F38"  class="text-capitalize px-3 ml-4" small @click="createPlanFrplcy()"><v-icon dark small >add_circle</v-icon> <v-divider vertical class="mx-2"></v-divider> Create/Edit Plan</v-btn>
                </v-layout>
            </v-card>


    </div>
</v-app>
</template>

<script>
import axios from "axios";
import moment from 'moment';
import {alphaNum, required,helpers} from 'vuelidate/lib/validators';
export const numberAplphaRegex = helpers.regex('number', /^[A-Za-z0-9 ]+$/);
export default {

    validations: {
            policyNumber:{
                required,
                alphaNum  
            },

            corporateGroupName:{
                required,
                numberAplphaRegex  
            },
            policyEffectiveDate:{
                required,
            },
            policyExpiryDate:{
                required,
            }
            
    },

    created(){
        this.$store.commit("SAVE_PAGE_HEADER", "Create/Edit Policy");
        this.token=this.$store.getters.successToken
        // console.log(this.$store.getters.fetchProductCodeData,"Product Id")
        console.log(this.$store.getters.fetchPlcyFrPrdctBtnName, "Button Details")
        this.btnName=this.$store.getters.fetchPlcyFrPrdctBtnName.txt
        console.log( " this.btnName",this.btnName)
        this.getProductDetailsByID(this.$store.getters.fetchProductCodeData)
        if(this.btnName==="EditPlcyFrPrdct"){
            this.getgrpolicybyID(this.$store.getters.fetchPlcyFrPrdctBtnName.plcy_id)
        }else if(this.btnName==="ClonePlcyFrPrdct"){
             console.log(this.$store.getters.fetchGrpPlcyDtaFrCln,"==========Plan data for Clone")
            this.getgrpolicybyID(this.$store.getters.fetchPlcyFrPrdctBtnName.plcy_id)
            this.shwCEPbtn=true
        }else if(this.btnName==="CreatePlcyFrPrdct"){
            this.shwCEPbtn=true
        }
    },

    computed: {
        cmputdPlcyEfctveDteFrmtdMmntjs () {
            return this.policyEffectiveDate ? moment(this.policyEffectiveDate).format('DD/MM/YYYY') : ''
        },
        cmputdPlcyExpryDteFrmtdMmntjs () {
            return this.policyExpiryDate ? moment(this.policyExpiryDate).format('DD/MM/YYYY') : ''
        },
         validDateChk(){
            
            // let fromeDate = moment(this.productEffectFromdate,'YYYY-MM-DD').format('DD-MM-YYYY')
            // let todayDate = Date.now();
            // let Todate= moment(this.policyExpiryDate,'YYYY-MM-DD ').format('DD-MM-YYYY')
            // let CurrentDate= moment(todayDate).format("DD-MM-YYYY");
            // console.log(Todate,CurrentDate,"Todate,CurrentDate")

            //  let fromeDate = moment(this.crtEdtPdt.pdtEfftvDteFrm,'YYYY-MM-DD').toDate().getTime()
            let todayDate = Date.now();
            let Todate= moment(this.policyExpiryDate,'YYYY-MM-DD ').toDate().getTime()
            console.log(todayDate,"todayDate")
            let CurrentDate= todayDate

            if(Todate < CurrentDate){
                return this.policyStatus="Expired"
            }else{
                return this.policyStatus="Active"
            }
        },

        policyNumber_chk(){
              if(this.policyNumber.length > 0){
                console.log("from if ");
                if(/^[A-Za-z0-9 ]+$/.test(this.policyNumber) != true){
                    // this.ttl_err = true
                    return "Special Characters Not Allowed"
                }else{
                    // this.ttl_err = false
                    return []
                }
            }else{
                // console.log("from else diurecty check on title ", !this.$v.policyNumber.$dirty);
                const errors = []
                if (!this.$v.policyNumber.$dirty) return errors
                !this.$v.policyNumber.required && errors.push('This field is required')
                return errors
            }
        },
        corporateGroupName_chk(){


            const errors = []
                // if (!this.$v.pan.$dirty) return errors
                if (!this.$v.corporateGroupName.$dirty) return errors
                !this.$v.corporateGroupName.required && errors.push('This field is required')
                !this.$v.corporateGroupName.numberAplphaRegex && errors.push('Special Characters Not Allowed')
                return errors
        },
        policyEffectiveDate_chk(){
            const errors = []
            if (!this.$v.policyEffectiveDate.$dirty) return errors
            !this.$v.policyEffectiveDate.required && errors.push('This field is required')
            return errors
        },
        policyExpiryDate_chk(){
            const errors = []
            if (!this.$v.policyExpiryDate.$dirty) return errors
            !this.$v.policyExpiryDate.required && errors.push('This field is required')
            return errors
        }

    },

    data() {
        return {

            newPlcyId:null,
            policyNumber : '',
            token:null,
            corporateGroupName : '',
            policyStatus : 'Expired',
            PlcyEfctveDtecalender:false,
            PlcyExpryDtecalender:false,
            policyEffectiveDate: "",
            policyExpiryDate: "",
            valid:false,
            shwCEPbtn:false,
            sbmtDsbled:false,
            clnDsbled:false,
            pdtDta:{},
            btnName:"",
             maxCount:30,
            required(){
                return v=>v && v.length>0 || "This Field is Required"
            },
            minLength(minLength){
                return v=>v && v.length>=minLength || `Must be atleast ${minLength} Characters`
            },
            maxLength(maxLength){
                return v=>v && v.length<=maxLength || `Must be less than ${maxLength} Characters`
            },
            spclChar(){
                return v=>/^[A-Za-z0-9 ]+$/.test(v) || `Special Characters Not Allowed`
            },
            dateCheck(){
                // let todayDate = Date.now();
                let fromDate=moment(this.policyEffectiveDate,'YYYY-MM-DD ').toDate().getTime()
                let Todate= moment(this.policyExpiryDate,'YYYY-MM-DD ').toDate().getTime()
                // console.log(todayDate,"todayDate")
                // let CurrentDate= todayDate
                if(Todate < fromDate){
                    // this.showToast("The Product 'Effective date From' can not be greater than Product 'Effective date To'.",this.TOST().WARNING)
                    return "Cannot be less than 'Policy Effective Date'"
                }
            },
            syncdateCheck(){
                let fromDate=moment(this.policyEffectiveDate,'YYYY-MM-DD ').toDate().getTime()
                let pdtFromDate=moment(this.$store.getters.fetchPdtDate.fromDate,'YYYY-MM-DD ').toDate().getTime()
                // let pdtTodate= moment(this.$store.getters.fetchPdtDate.toDate,'YYYY-MM-DD ').toDate().getTime()
                if(pdtFromDate > fromDate){
                    // this.showToast("Plan Effective date and Product effective are not in sync",this.TOST().WARNING);
                    return "'Policy Effective' and 'Product Effective From' date are not in sync"
                }

            },
            

        }
    },

    methods:{
        submitDetials(){
            console.log(`policy number : ${this.policyNumber} corporateName : ${this.corporateGroupName}
            policyStatus ${this.policyStatus} date from ${this.date}`);
        },
        createPlanFrplcy(){
             console.log(this.btnName, "this.btnName")
              console.log(this.newPlcyId, "this.newPlcyId")
              console.log(this.$store.getters.fetchGrpPlcyDtaFrCln,"Plan data for Clone")
              
            if(this.btnName==="CreatePlcyFrPrdct"){
                let btnName={
                    text:'CreatePlcyFrPrdct',
                    visible:true,
                    plcy_Id:this.newPlcyId
                }
                this.$store.commit('SAVE_POLICYFORPRDCT_BTN_NAME', btnName)
                this.$router.push('/product/createditplanforpolicy');
            }

            let pdtDate={
                fromDate:this.$store.getters.fetchPdtDate.fromDate,
                toDate:this.$store.getters.fetchPdtDate.toDate,
                policyFromDate:this.policyEffectiveDate,
                policyToDate:this.policyExpiryDate
            }
            this.$store.commit('SAVE_PDT_DATE_FOR_PLAN', pdtDate)

            if(this.btnName==="EditPlcyFrPrdct"){
                this.$router.push('/product/createditplanforpolicy');
            }



            if(this.btnName==="ClonePlcyFrPrdct"){
                let self=this
                console.log(this.$store.getters.fetchGrpPlcyDtaFrCln.planDetails,"PlanDetails")
                // return
                // let demodata={
                //     planName: "",
                //     planDescription: this.$store.getters.fetchGrpPlcyDtaFrCln.planDescription,
                //     planEffectiveDate: this.$store.getters.fetchGrpPlcyDtaFrCln.planEffectiveDate,
                //     planExpiryDate: this.$store.getters.fetchGrpPlcyDtaFrCln.planExpiryDate,
                //     planstatus: "Active",
                //     planType:"clone",
                //     policyType: this.$store.getters.fetchGrpPlcyDtaFrCln.policyType,
                //     policyNumber: self.policyNumber,
                //     policyId: self.newPlcyId,
                //     externalRefNo: this.$store.getters.fetchGrpPlcyDtaFrCln.externalRefNo
                // }
                let tempdta=[]
                tempdta=this.$store.getters.fetchGrpPlcyDtaFrCln.planDetails
                
                if(!tempdta || tempdta.length ===0){
                    console.log("skip clone plan API")
                      let btnName={
                        text:'ClonePlcyFrPrdct',
                        visible:true,
                        plcy_Id:self.newPlcyId
                    }
                    self.$store.commit('SAVE_POLICYFORPRDCT_BTN_NAME', btnName)
                    self.$router.push('/product/createditplanforpolicy');

                }else{
                        console.log("clone plan API starts plan details present")
                        console.log(this.$store.getters.fetchGrpPlcyDtaFrCln.planDetails,"Plans incominfg data")
                        let demodataArr=[]
                        // demodataArr=this.$store.getters.fetchGrpPlcyDtaFrCln.planDetails

                        tempdta.map((itm) => {
                        demodataArr.push({
                            corporateEligibility:itm.corporateEligibility,
                            deductibleWaitingPeriodDetails:itm.deductibleWaitingPeriodDetails,
                            generalExclusions:itm.generalExclusions,
                            relationShipDependentInfo:itm.relationShipDependentInfo,
                            planName: "",
                            planDescription: itm.planDescription,
                            planEffectiveDate: itm.planEffectiveDate,
                            planExpiryDate: itm.planExpiryDate,
                            planstatus: itm.planstatus,
                            planType:"clone",
                            policyType: itm.policyType,
                            policyNumber: self.policyNumber,
                            policyId: self.newPlcyId,
                            externalRefNo: itm.externalRefNo
                            });
                        });

                        console.log("from demodataArr--------->",demodataArr);
                        axios({
                            method: "post",
                            url:
                                self.API_Service_admin()+"group-plan/clone-plan",
                            headers: {
                                "Content-Type": "application/json",
                                "x-access-token":self.token,
                            },
                            data:demodataArr,
                        })
                        .then(function (response) {
                            console.log("Create reporting>>>>>>", response);
                            let btnName={
                                text:'ClonePlcyFrPrdct',
                                visible:true,
                                plcy_Id:self.newPlcyId
                            }
                            self.$store.commit('SAVE_POLICYFORPRDCT_BTN_NAME', btnName)
                            self.$router.push('/product/createditplanforpolicy');
                        })
                        .catch(function (error) {
                            console.log("Create reporting Error>>>>>>", error);
                            self.showToast(error.response.data.msg,self.TOST().ERROR);
                        });
                    }
            }
              
        },

        save_plcy(){

            this.$v.$touch();

            if(this.$v.$invalid){
                console.log("Form has errors",this.$v);  
                this.showToast('Please fill all required fields', this.TOST().WARNING);
                return
            }

            // let todayDate = Date.now();
            let fromDate=moment(this.policyEffectiveDate,'YYYY-MM-DD ').toDate().getTime()
            let Todate= moment(this.policyExpiryDate,'YYYY-MM-DD ').toDate().getTime()
            let pdtFromDate=moment(this.$store.getters.fetchPdtDate.fromDate,'YYYY-MM-DD ').toDate().getTime()
            // console.log(todayDate,"todayDate")
            // let CurrentDate= todayDate
            if(pdtFromDate > fromDate){
                return this.showToast("'Policy Effective' and 'Product Effective From' date are not in sync",this.TOST().WARNING);
                // return "'Policy Effective' and 'Product Effective From' date are not in sync"
            }
            if(Todate < fromDate){
                return this.showToast("The Policy 'Effective date' can not be greater than Policy 'Expiry date.' ",this.TOST().WARNING)
            }

            if(this.btnName==="CreatePlcyFrPrdct"|| this.btnName==="ClonePlcyFrPrdct"){
                console.log("From If")
                this.crtePlcyfrpdt()
            }else if(this.btnName==="EditPlcyFrPrdct"){
                console.log("From Else")
                this.edtPlcyFrPdt()
            }

        },

        crtePlcyfrpdt(){
            // this.$v.$touch();

            // if(this.$v.$invalid){
            //     console.log("Form has errors",this.$v);  
            //     this.showToast('Please fill all required fields', this.TOST().WARNING);
            //     return
            // }
            
                let self=this
                let demodata={
                            policyNumber :self.policyNumber,
                            policyStatus: self.policyStatus,
                            corporateGroupName: self.corporateGroupName,
                            policyEffectiveDate: self.policyEffectiveDate,
                            policyExpiryDate: self.policyExpiryDate,
                            productCode: self.pdtDta.productCode,
                            productDescription: self.pdtDta.productDescription,
                            productId: self.pdtDta._id,
                        }

                        console.log("from demodata--------->",demodata);
                        axios({
                            method: "post",
                            url:
                                this.API_Service_admin()+"group-policy/create-group-policy",
                            headers: {
                                "Content-Type": "application/json",
                                "x-access-token": self.token
                                // "x-access-token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI1ZmJlMDg4ZGM0NmRhODNlZTdmODcxYmIiLCJyb2xlIjoiQWRtaW5Vc2VyIiwiaWF0IjoxNjA2MzY5MzI0LCJleHAiOjE2Mzc5MDUzMjR9.iS5hZs0uImNFz-rxNaQ7CTCc6qS_hG4H0FsePZ5Sgqc",
                            },
                            data:demodata,
                        })
                        .then(function (response) {
                            console.log(" Create Policy for product API Response>>>>>>", response);

                            // self.policyNumber=response.data.data.hasOwnProperty('policyNumber')?self.chkplcydata(response.data.data.policyNumber):""
                            self.newPlcyId=response.data.data.hasOwnProperty('_id')?self.chkplcydata(response.data.data._id):""
                            console.log(self.newPlcyId,"self.newPlcyId")
                            // self.btnName="EditPlcyFrPrdct"
                            self.sbmtDsbled=true
                            self.clnDsbled=true
                            self.showToast(response.data.msg ,self.TOST().SUCCESS)
                            self.shwCEPbtn =false
                        })
                        .catch(function (error) {
                            console.log(" Create Policy for product Error APi Response>>>>>>", error);
                            self.showToast(error.response.data.msg,self.TOST().ERROR);
                            self.shwCEPbtn =true
                        });
        },

        edtPlcyFrPdt(){
            // this.$v.$touch();

            // if(this.$v.$invalid){
            //     console.log("Form has errors",this.$v);  
            //     this.showToast('Please fill all required fields', this.TOST().WARNING);
            //     return
            // }
                let self=this
                let demodata={
                            policyNumber :self.policyNumber,
                            policyStatus: self.policyStatus,
                            corporateGroupName: self.corporateGroupName,
                            policyEffectiveDate: self.policyEffectiveDate,
                            policyExpiryDate: self.policyExpiryDate,
                            productCode: self.pdtDta.productCode,
                            productDescription: self.pdtDta.productDescription,
                            productId: self.pdtDta._id,
                        }

                        console.log("from demodata--------->",demodata);
                        axios({
                            method: "put",
                            url:
                                this.API_Service_admin()+"group-policy/update-group-policy/"+self.$store.getters.fetchPlcyFrPrdctBtnName.plcy_id,
                            headers: {
                                "Content-Type": "application/json",
                                "x-access-token": self.token
                                // "x-access-token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI1ZmJlMDg4ZGM0NmRhODNlZTdmODcxYmIiLCJyb2xlIjoiQWRtaW5Vc2VyIiwiaWF0IjoxNjA2MzY5MzI0LCJleHAiOjE2Mzc5MDUzMjR9.iS5hZs0uImNFz-rxNaQ7CTCc6qS_hG4H0FsePZ5Sgqc",
                            },
                            data:demodata,
                        })
                        .then(function (response) {
                            console.log(" Create Policy for product API Response>>>>>>", response);
                            self.showToast(response.data.msg ,self.TOST().SUCCESS)
                        })
                        .catch(function (error) {
                            console.log(" Create Policy for product Error APi Response>>>>>>", error);
                            self.showToast(error.response.data.msg,self.TOST().ERROR);
                        });

            
        },

        getProductDetailsByID(id){
            console.log("Product Id ====>",id)
                let self = this;   
                axios({
                    method: "get",
                    url:
                        this.API_Service_admin()+"product/get-product/"+id, 
                        headers: {
                            "Content-Type": "application/json", 
                            "x-access-token": self.token,
                        }
                })
                .then(function(response) {
                    console.log('Product Details for Create policy for product Response------->>>',response.data.data);
                    self.pdtDta={}
                    self.pdtDta=response.data.data
                })
                .catch(function(error) {
                    console.log(error);
                    // self.showToast(error.response.data.msg,self.TOST().ERROR);
                });

        },
        getgrpolicybyID(id){
            console.log("Policy ID",id)
            let self = this;   
                axios({
                    method: "get",
                    url:
                        this.API_Service_admin()+"group-policy/get-group-policy/"+id, 
                        headers: {
                            "Content-Type": "application/json", 
                            "x-access-token": self.token,
                        }
                })
                .then(function(response) {
                    console.log('Policy Details for Edit policy for product Response------->>>',response.data.data);
                    let tempPlcyDta=response.data.data
                    if(self.btnName==="EditPlcyFrPrdct"){
                        self.policyNumber=tempPlcyDta.hasOwnProperty('policyNumber')?self.chkplcydata(tempPlcyDta.policyNumber):""
                    }
                    self.corporateGroupName=tempPlcyDta.hasOwnProperty('corporateGroupName')?self.chkplcydata(tempPlcyDta.corporateGroupName):""
                    self.policyEffectiveDate=tempPlcyDta.hasOwnProperty('policyEffectiveDate')?self.chkplcydata(moment(tempPlcyDta.policyEffectiveDate,'YYYY-MM-DD').format('YYYY-MM-DD')):""
                    self.policyExpiryDate=tempPlcyDta.hasOwnProperty('policyExpiryDate')?self.chkplcydata(moment(tempPlcyDta.policyExpiryDate,'YYYY-MM-DD').format('YYYY-MM-DD')):""
                })
                .catch(function(error) {
                    console.log(error);
                    // self.showToast(error.response.data.msg,self.TOST().ERROR);
                });
        },
        chkplcydata(data){
            console.log("Policy sara",data); 
            try{
                if (data ==="" || data === "undefined" || data === null) {
                    return ""
                } else {
                    return data;
                }

             }catch(err) {
                console.log(err)
            }
            
        },
    },
    

    
   
    


}
</script>

<style>

.cust-rmvpd-psins3 .v-text-field.v-text-field--enclosed>.v-input__control>.v-input__slot {
    padding-right: 0px !important;
}

.cust_cpfp .v-text-field .v-input__slot,
.v-text-field--outline .v-input__slot {
    min-height: 42px !important;
    display: flex !important;
    align-items: center !important;
    /* border:1px red solid; */
}

.cust_cpfp .v-input .v-label {
    font-size: 13px !important;
}
.cust_cpfp .v-input {
    font-size: 13px !important;
}
.cust_cpfp .v-input input {
    font-size: 13px !important;
}

.cstm-clr-pr3{
    color:#646666 !important;
    margin-bottom: 5px !important;
    font-size: 13px !important;
}

.cust_cpfp .v-text-field .v-input__slot,
.v-text-field--outline .v-input__slot {
    min-height: 42px !important;
    display: flex !important;
    align-items: center !important;
    /* border:1px red solid; */
}

.cust-rmvpd-pr1 .v-text-field.v-text-field--enclosed>.v-input__control>.v-input__slot {
    padding: 0px !important;
}

.custPlcyFrPdt .theme--light.v-text-field--solo > .v-input__control > .v-input__slot {
    background: #F7F7F7;
}


</style>