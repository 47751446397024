<template>
    <v-app v-if="shwDtaTbl=true">
        <v-card tile class="">
            <v-layout justify-end align-center>
                <div style="border-left:1px solid #C1C8CC" class="pa-3">
                    <h5 class="mr-2" >Provider Name :<span style="color:#1E9690" class="text-capitalize pl-1">{{ $store.state.createtarrifDetails.providerName}} </span></h5>
                </div>

                <div style="border-left:1px solid #C1C8CC" class="pa-3">
                    <h5 class="mr-2" >Tariff ID :<span style="color:#1E9690" class="text-capitalize pl-1">{{ $store.state.createtarrifDetails.tariffId}}</span></h5>
                </div>
                <div style="border-left:1px solid #C1C8CC" class="pa-3">
                    <h5 class="mr-2" >Tariff Name :<span style="color:#1E9690" class="text-capitalize pl-1">{{ $store.state.createtarrifDetails.tariffName}}</span></h5>
                </div>
            </v-layout>
        </v-card> 
        <v-card tile>
        <table id="table-reviewCharges-1" style="width:100%">
            <thead class="backColorRvwChrgs" >
                <tr  >
                    <th  style="font-size:11px" v-for="(j,index) in headersNew"  :key="index" >{{j.displayval}}</th>
                </tr>
            </thead>
            <tbody v-for="(i,index) in reviewtarrifData" :key="index">
                <tr>
                    <td :colspan="headersNew.length-1" class="text-center font-weight-bold cust_wdth1138">
                        Room & Nursing
                    </td>
                    <td class="font-weight-bold cust_wdth156">{{i.roomAndNursingCharges.discount | checkdata }}</td>
                </tr>
                <tr>
                    <td :colspan="headersNew.length-1" class="text-center font-weight-bold cust_wdth1138">
                       Room & Nursing - Room Charges
                    </td>
                    <td>----</td>
                </tr>
                <tr v-for="(j,indx) in i.roomAndNursingCharges.roomCharges" :key="indx">
                    <td class="font-weight-medium"> {{indx+1 | checkdata}}</td>
                    <td class="font-weight-medium">{{j.serviceCategory.level4 | checkdata}}</td>
                    <td class="font-weight-medium">{{j.hospitalService | checkdata}}</td>
                    <td class="font-weight-medium">{{j.tariff | checkdata}}</td>
                    <td class="font-weight-medium" v-for="(k,indxrt) in i.roomConfiguration.roomConfiguration.roomTypes" :key="indxrt">----</td>
                    <td class="font-weight-medium wrd-break">{{j.inclusion | checkdata}}</td>
                    <td class="font-weight-medium wrd-break">{{j.exclusion | checkdata}}</td>
                    
                    <td class="font-weight-medium">----</td>
                </tr>
                <tr>
                    <td :colspan="headersNew.length-1" class="text-center font-weight-bold cust_wdth1138">
                       Room & Nursing - Nursing Charges
                    </td>
                    <td>----</td>
                </tr>
                <tr v-for="(k,indxNC) in i.roomAndNursingCharges.nursingCharges" :key="indxNC">
                    
                <td class="font-weight-medium">{{indxNC+1 | checkdata}}</td>
                <td class="font-weight-medium">{{k.serviceCategory.level4 | checkdata}}</td>
                <td class="font-weight-medium">{{k.hospitalService | checkdata}}</td>
                <td class="font-weight-medium">{{k.tariff | checkdata}}</td>
                <!-- <td class="font-weight-medium">----</td> -->
                <td class="font-weight-medium" v-for="(l,indxNCrt) in k.roomTypes" :key="indxNCrt">{{l.charges | checkdata}}</td>
                <td class="font-weight-medium wrd-break">{{k.inclusion | checkdata}}</td>
                <td class="font-weight-medium wrd-break">{{k.exclusion | checkdata}}</td>
                
                <td class="font-weight-medium">----</td>
                </tr>
                <tr>
                    <td :colspan="headersNew.length-1" class="text-center font-weight-bold cust_wdth1138">
                       ICU
                    </td>
                    <td class="font-weight-bold cust_wdth156">{{i.ICUCharges.discount | checkdata}}</td>
                </tr>
                <tr v-for="(m,indxICU) in i.ICUCharges.icuCharges" :key="indxICU">
                    <td class="font-weight-medium">{{indxICU+1 | checkdata}}</td>
                    <td class="font-weight-medium">{{m.serviceCategory.level4 | checkdata}}</td>
                    <td class="font-weight-medium">{{m.hospitalService | checkdata}}</td>
                    <td class="font-weight-medium">{{m.tariff | checkdata}}</td>
                    <!-- <td class="font-weight-medium">----</td> -->
                    <td class="font-weight-medium" v-for="(n,indxICUrt) in m.roomTypes" :key="indxICUrt">{{n.charges | checkdata}}</td>
                    
                    <td class="font-weight-medium wrd-break" >{{m.inclusion | checkdata}}</td>
                    <td class="font-weight-medium wrd-break">{{m.exclusion | checkdata}}</td>
                    <td class="font-weight-medium">----</td>
                </tr>
                <tr>
                    <td :colspan="headersNew.length-1" class="text-center font-weight-bold cust_wdth1138">
                       OT
                    </td>
                    <td class="font-weight-bold cust_wdth156">{{i.OTCharges.discount | checkdata}}</td>
                </tr>
                <tr v-for="(o,indxOT) in i.OTCharges.otCharges" :key="indxOT">
                    <td class="font-weight-medium">{{indxOT+1 | checkdata}}</td>
                    <td class="font-weight-medium">{{o.serviceCategory.level4 | checkdata}}</td>
                    <td class="font-weight-medium">{{o.hospitalService | checkdata}}</td>
                    <td class="font-weight-medium">{{o.tariff | checkdata}}</td>
                    <!-- <td class="font-weight-medium">----</td> -->
                    <td class="font-weight-medium" v-for="(p,indxOTrt) in o.roomTypes" :key="indxOTrt">{{p.charges | checkdata}}</td>
                    <td class="font-weight-medium wrd-break">{{o.inclusion | checkdata}}</td>
                    <td class="font-weight-medium wrd-break">{{o.exclusion | checkdata}}</td>
                    <td class="font-weight-medium">----</td>  
                </tr>

                <tr>
                    <td :colspan="headersNew.length-1" class="text-center font-weight-bold cust_wdth1138">
                       Med & Consumables
                    </td>
                    <td class="font-weight-bold cust_wdth156">{{i.MedAndConsumablesCharges.discount | checkdata}}</td>
                </tr>
                <tr v-for="(q,indxMC) in i.MedAndConsumablesCharges.medAndConsumablesCharges" :key="indxMC">
                    <td class="font-weight-medium">{{indxMC+1 | checkdata}}</td>
                    <td class="font-weight-medium">{{q.serviceCategory.level4 | checkdata}}</td>
                    <td class="font-weight-medium">{{q.hospitalService | checkdata}}</td>
                    <td class="font-weight-medium">{{q.tariff | checkdata}}</td>
                    <!-- <td class="font-weight-medium">----</td> -->
                    <td class="font-weight-medium" v-for="(r,indxMCrt) in q.roomTypes" :key="indxMCrt">{{r.charges | checkdata}}</td>
                    
                    <td class="font-weight-medium wrd-break">{{q.inclusion | checkdata}}</td>
                    <td class="font-weight-medium wrd-break">{{q.exclusion | checkdata}}</td>
                    <td class="font-weight-medium">----</td>  
                </tr>




            <tr>
                <td :colspan="headersNew.length-1" class="text-center font-weight-bold cust_wdth1138">Professional Fee</td>
                <td class="font-weight-bold cust_wdth156"> {{i.ProfessionalFeeCharges.discount | checkdata}}</td>
            </tr>
            <tr v-for="(s,indxPFC) in i.ProfessionalFeeCharges.professionalfeeCharges" :key="indxPFC">
                <td class="font-weight-medium">{{indxPFC+1 | checkdata}}</td>
                <td class="font-weight-medium">{{s.serviceCategory.level4 | checkdata}}</td>
                <td class="font-weight-medium">{{s.hospitalService | checkdata}}</td>
                <td class="font-weight-medium">{{s.tariff | checkdata}}</td>
                <!-- <td class="font-weight-medium">----</td> -->
                <td class="font-weight-medium" v-for="(t,indxPFCrt) in s.roomTypes" :key="indxPFCrt">{{t.charges | checkdata}}</td>
                <td class="font-weight-medium wrd-break">{{s.inclusion | checkdata}}</td>
                <td class="font-weight-medium wrd-break">{{s.exclusion | checkdata}}</td>
                
                <td class="font-weight-medium">----</td>                
            </tr>
            <tr>
                <td :colspan="headersNew.length-1" class="text-center font-weight-bold cust_wdth1138">Investigations</td>
                <td class="font-weight-bold cust_wdth156"> {{i.InvestigationCharges.discount | checkdata}}</td>
            </tr>
            <tr v-for="(u,indxIC) in i.InvestigationCharges.investigationsCharges" :key="indxIC">
                <td class="font-weight-medium">{{indxIC+1 | checkdata}}</td>
                <td class="font-weight-medium">{{u.serviceCategory.level4 | checkdata}}</td>
                <td class="font-weight-medium">{{u.hospitalService | checkdata}}</td>
                <td class="font-weight-medium">{{u.tariff | checkdata}}</td>
                <!-- <td class="font-weight-medium">----</td> -->
                <td class="font-weight-medium" v-for="(v,indxICrt) in u.roomTypes" :key="indxICrt">{{v.charges | checkdata}}</td>
                
                <td class="font-weight-medium wrd-break">{{u.inclusion | checkdata}}</td>
                <td class="font-weight-medium wrd-break">{{u.exclusion | checkdata}}</td>
                <td class="font-weight-medium">----</td>                
            </tr>
            <tr>
                <td :colspan="headersNew.length-1" class="text-center font-weight-bold cust_wdth1138">Ambulance</td>
                <td class="font-weight-bold cust_wdth156"> {{i.AmbulanceCharges.discount | checkdata}}</td>
            </tr>
            <tr v-for="(w,indxAC) in i.AmbulanceCharges.ambulanceCharges" :key="indxAC">
                <td class="font-weight-medium">{{indxAC+1 | checkdata}}</td>
                <td class="font-weight-medium">{{w.serviceCategory.level4 | checkdata}}</td>
                <td class="font-weight-medium">{{w.hospitalService | checkdata}}</td>
                <td class="font-weight-medium">{{w.tariff | checkdata}}</td>
                <!-- <td class="font-weight-medium">----</td> -->
                <td class="font-weight-medium" v-for="(x,indxACrt) in w.roomTypes" :key="indxACrt">{{x.charges | checkdata}}</td>
                
                <td class="font-weight-medium wrd-break">{{w.inclusion | checkdata}}</td>
                <td class="font-weight-medium wrd-break">{{w.exclusion | checkdata}}</td>
                <td class="font-weight-medium">----</td>                
            </tr>
            <tr>
                <td :colspan="headersNew.length-1" class="text-center font-weight-bold cust_wdth1138">Miscellaneous</td>
                <td class="font-weight-bold cust_wdth156"> {{i.MiscellaneousCharges.discount | checkdata}}</td>
            </tr>
            <tr v-for="(y,indxMsC) in i.MiscellaneousCharges.miscellaneousCharges" :key="indxMsC">
                <td class="font-weight-medium">{{indxMsC+1 | checkdata}}</td>
                <td class="font-weight-medium">{{y.serviceCategory.level4 | checkdata}}</td>
                <td class="font-weight-medium">{{y.hospitalService | checkdata}}</td>
                <td class="font-weight-medium">{{y.tariff | checkdata}}</td>
                <!-- <td class="font-weight-medium">----</td> -->
                <td class="font-weight-medium" v-for="(x,indxMsCrt) in y.roomTypes" :key="indxMsCrt">{{x.charges | checkdata}}</td>
                
                <td class="font-weight-medium wrd-break">{{y.inclusion | checkdata}}</td>
                <td class="font-weight-medium wrd-break">{{y.exclusion | checkdata}}</td>
                <td class="font-weight-medium">----</td>                
            </tr>
             <tr>
                <td :colspan="headersNew.length-1" class="text-center font-weight-bold cust_wdth1138">Packages</td>
                <td class="font-weight-bold cust_wdth156"> {{i.PackageCharges.discount | checkdata }}</td>
            </tr>
            <tr v-for="(a,indxPkgC) in i.PackageCharges.packageCharges" :key="indxPkgC">
                <td class="font-weight-medium">{{indxPkgC+1 | checkdata}}</td>
                <td class="font-weight-medium">{{a.serviceCategory.level4 | checkdata}}</td>
                <td class="font-weight-medium">{{a.hospitalService | checkdata}}</td>
                <td class="font-weight-medium">{{a.tariff | checkdata}}</td>
                <!-- <td class="font-weight-medium">----</td> -->
                <td class="font-weight-medium" v-for="(b,indxPkgCrt) in a.roomTypes" :key="indxPkgCrt">{{b.charges | checkdata}}</td>
               <!-- {{a.inclusion | checkdata}} -->
                <td class="font-weight-medium"> <span  style="cursor:pointer;color:blue;text-decoration:underline"  @click="viewInclusion(a.Inclusion)">View inclusions</span> </td>
                <td class="font-weight-medium wrd-break">{{a.exclusion | checkdata}}</td>
                <td class="font-weight-medium">----</td>                
            </tr>

            </tbody>
        </table>
        </v-card>
        <div style="background-color:#F7F7F7;display:flex;justify-content:flex-end"  class="pa-4 mt-4">
                <v-btn tile  v-if="shwApproveRejectBtn===false" @click="submitTariff"   dark style="font-size:12px;background-color:#23B1A9"  class="text-capitalize px-3 mx-2" small><v-icon dark small> check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider>Submit</v-btn>
                <v-btn tile  v-if="shwApproveRejectBtn===false"  @click="$router.push('/tarrifconfiguration')"  dark style="font-size:12px;background-color:#23B1A9" class="text-capitalize px-3 mx-2" small><v-icon dark small>create</v-icon> <v-divider vertical class="mx-2"></v-divider> Edit</v-btn>
                <v-btn v-if="shwApproveRejectBtn===true"  @click="approveProduct()" tile dark style="font-size:12px;background-color:#23B1A9;" small class="text-capitalize mr-2 "><v-icon dark small >check_circle</v-icon > <v-divider vertical class="mx-2"></v-divider>Accept</v-btn>
                <v-btn v-if="shwApproveRejectBtn===true"  @click=" editRmrk()" tile dark style="font-size:12px;background-color:#D1121B;" small  class="text-capitalize"><v-icon dark small >cancel</v-icon > <v-divider vertical class="mx-2"></v-divider>Reject</v-btn>       
        </div>

        <v-dialog v-model="inclsnsDilg" persistent max-width="650px" tile>
            <v-card color="#F7F7F7" class="" tile>
                <v-card tile>
                    <v-layout justify-space-between  class="" style="flex:1;background-ox-shadow: 0px 3px 5px #00000029;">

                        <v-card-title class="pa-0 pl-3 text-capitalize font-weight-bold" style="color:#10242B;font-size:13px">Inclusions</v-card-title>

                        <div style="border-left:1px solid #C1C8CC;" @click="close_inclsns_Dilg()">
                            <v-icon style="cursor: pointer;" class="pa-2" >close</v-icon>
                        </div>
                    </v-layout>
                    <v-data-table
                            :headers="headers"
                            :items="rsltData"
                            :page.sync="page"
                            :items-per-page="itemsPerPage"
                            hide-default-footer
                            dense
                            @page-count="pageCount = $event"
                        >
                        </v-data-table>
                        <v-divider></v-divider>
                        <v-pagination
                            class="create-srch-Pdt-typ py-1"
                            v-model="page"
                            color="#152F38"
                            flat
                            :length="pageCount"
                        ></v-pagination>
                </v-card> 
            </v-card>
        </v-dialog>

        <v-dialog v-model="RmrkDilg" persistent max-width="480px" tile>
                        <v-card color="#F7F7F7" class="pb-2" tile>
                            <v-card tile>
                                <v-layout justify-space-between  class="" style="flex:1;background-ox-shadow: 0px 3px 5px #00000029;">
                                    
                                        <v-card-title class="pa-0 pl-3 text-capitalize font-weight-bold" style="color:#10242B;font-size:13px">Remarks</v-card-title>
                                       
                                    <div style="border-left:1px solid #C1C8CC;" @click="close_dwnld_dlg()">
                                        <v-icon style="cursor: pointer;" class="pa-2" >close</v-icon>
                                    </div>
                                </v-layout>
                            </v-card> 
                                <!-- <v-card class="ma-3 pa-2"> -->
                                      <v-textarea
                                        class="mx-3 mt-3"
                                        solo
                                        auto-grow
                                        v-model="rjctRmk"
                                        ></v-textarea>
                                <!-- </v-card> -->
                                <div color="" class="px-2" style="display:flex; justify-content:flex-end">
                                    <v-btn tile dark  style="font-size:12px;background-color:#E46A25" small @click="close_dwnld_dlg()"  class="text-capitalize px-3 "><v-icon dark small >cancel</v-icon > <v-divider vertical class="mx-2"></v-divider>Close</v-btn>
                                    <v-btn tile dark  style="font-size:12px;background-color:#D1121B"  @click="clear_Rmrks()"  small  class="text-capitalize px-3 mx-2"><v-icon dark small >delete </v-icon> <v-divider vertical class="mx-2"></v-divider>Clear</v-btn>
                                    <v-btn tile dark  style="font-size:12px;background-color:#23B1A9"  @click="rejectProduct()" small  class="text-capitalize px-3 mr-2"><v-icon dark small >check_circle</v-icon > <v-divider vertical class="mx-2"></v-divider>Save</v-btn>
                                </div>
                            
                        </v-card>
                    </v-dialog>

    </v-app>  
</template>
<script>
// import ProviderDetails from '../../component/Provider-Name_ID';
export default {
    components:{
        // ProviderDetails,
    },
    filters:{
        checkdata(data){// eslint-disable-line no-mixed-spaces-and-tabs
            console.log('Validity Data', data);
            if (data ==="" || data === undefined || data === null || data=== 0) {
                return "----"
            } else {
                return data;
            }
        },
    },
    created(){
        this.$store.commit("SAVE_PAGE_HEADER", "Review Charges");
        console.log(this.$store.state.createtarrifDetails,"this.$store.state.createtarrifDetails");
       console.log(this.$route.params.showApprove,"this.$route.params.showApprove" )
       if(this.$route.params.showApprove===undefined || this.$route.params.showApprove===null){
           this.shwApproveRejectBtn=false
       }else{
           this.shwApproveRejectBtn=this.$route.params.showApprove
        }
    
        this.getIcuConfig(this.$store.state.createtarrifDetails._id)
    },
    data(){
        return{
            headersNew:[ 
                {text: 'Sr.No.', displayval:'Sr.No.'},
                {text: 'scl4', displayval:'Service Category(Level 4)'},
                {text: 'hsl5',displayval:'Hospital Service (Level 5)'},
                {text: 'Tariff',displayval:'Tariff'},
                {text: 'Inclusions',displayval:'Inclusions'}, 
                {text: 'Exclusions',displayval:'Exclusions'},
                {text: 'Discount Percentage',displayval:'Discount Percentage'}, 
                 
            ],
            noOfRooms:[],
            tarrifData:{},
            reviewtarrifData:[],
            shwDtaTbl:false,
            tarrifArry : [
                {wrap:"AmbulanceCharges", arr:"ambulanceCharges",discount:"discount"},
                {wrap:"ICUCharges", arr:"icuCharges",discount:"discount"} ,
                {wrap:"InvestigationCharges",  arr:"investigationCharges",discount:"discount"},
                {wrap:"MedAndConsumablesCharges",  arr:"medAndConsumablesCharges",discount:"discount"},
                {wrap:"MiscellaneousCharges",  arr:"miscellaneousCharges",discount:"discount"},
                {wrap:"OTCharges",  arr:"otCharges",discount:"discount"},
                {wrap:"PackageCharges",  arr:"packageCharges",discount:"discount"},
                {wrap:"ProfessionalFeeCharges",  arr:"professionalFeeCharges",discount:"discount"},
                {wrap:"roomAndNursingCharges",  arr:"nursingCharges",arr2: "roomCharges",discount:"discount"}
            ],
            inclsnsDilg:false,
            RmrkDilg:false,
            rjctRmk:"",
             headers:[
                {text: 'Level 1',value:'level1',sortable: false,divider:true,class:"hdr-txt-clrInclsn"},
                {text: 'Level 2', value: 'level2',sortable: false,divider:true,class:"hdr-txt-clrInclsn"},
                {text: 'Level 3', value: 'level3',sortable: false,divider:true,class:"hdr-txt-clrInclsn"},
                {text: 'Level 4', value: 'level4',sortable: false,divider:true,class:"hdr-txt-clrInclsn"},
                {text: 'Units',value: 'units',sortable: false,divider:true,class:"hdr-txt-clrInclsn"}, 
             

            ],
            shwApproveRejectBtn:false,
             page: 1,
            pageCount: 0,
            itemsPerPage: 5,
            rsltData:[],
            // rsltData:[
            //     {
            //         "_id": "60368acdab66aa1e940a4aaa",
            //         "level1": "Medicine & Consumables Charges",
            //         "level2": null,
            //         "level2": "charges",
            //         "level4": null,
            //         "units": 5
            //     },
            //     {
            //         "_id": "60368ad0ab66aa1e940a4aab",
            //         "level1": "OT Charges",
            //         "level2": "Room Charges",
            //         "level3": "",
            //         "level4": "",
            //         "units": 2
            //     },
            //     {
            //         "_id": "60368cf0a963391a28d0bad9",
            //         "level1": "OT Charges",
            //         "level2": "Room Charges",
            //         "level3": "",
            //         "level4": "",
            //         "units": 2
            //     },
            //       {
            //         "_id": "60368acdab66aa1e940a4aaa",
            //         "level1": "Medicine & Consumables Charges",
            //         "level2": null,
            //         "level2": "charges",
            //         "level4": null,
            //         "units": 5
            //     },
            //     {
            //         "_id": "60368ad0ab66aa1e940a4aab",
            //         "level1": "OT Charges",
            //         "level2": "Room Charges",
            //         "level3": "",
            //         "level4": "",
            //         "units": 2
            //     },
            //     {
            //         "_id": "60368cf0a963391a28d0bad9",
            //         "level1": "OT Charges",
            //         "level2": "Room Charges",
            //         "level3": "",
            //         "level4": "",
            //         "units": 2
            //     },
            //       {
            //         "_id": "60368acdab66aa1e940a4aaa",
            //         "level1": "Medicine & Consumables Charges",
            //         "level2": null,
            //         "level2": "charges",
            //         "level4": null,
            //         "units": 5
            //     },
            //     {
            //         "_id": "60368ad0ab66aa1e940a4aab",
            //         "level1": "OT Charges",
            //         "level2": "Room Charges",
            //         "level3": "",
            //         "level4": "",
            //         "units": 2
            //     },
            //     {
            //         "_id": "60368cf0a963391a28d0bad9",
            //         "level1": "OT Charges",
            //         "level2": "Room Charges",
            //         "level3": "",
            //         "level4": "",
            //         "units": 2
            //     },
            //       {
            //         "_id": "60368acdab66aa1e940a4aaa",
            //         "level1": "Medicine & Consumables Charges",
            //         "level2": null,
            //         "level2": "charges",
            //         "level4": null,
            //         "units": 5
            //     },
            //     {
            //         "_id": "60368ad0ab66aa1e940a4aab",
            //         "level1": "OT Charges",
            //         "level2": "Room Charges",
            //         "level3": "",
            //         "level4": "",
            //         "units": 2
            //     },
            //     {
            //         "_id": "60368cf0a963391a28d0bad9",
            //         "level1": "OT Charges",
            //         "level2": "Room Charges",
            //         "level3": "",
            //         "level4": "",
            //         "units": 2
            //     }
            // ],

        }
    },
    methods:{

         getIcuConfig(id){
            this.noOfRooms=[]
            var compnt_render=new Promise((resolve,reject)=>{

                this.getTariffById(id,tempData=>{
                    console.log(tempData,"tempdata")
                    // this.tarrifData.push(tempData)
                    this.tarrifData=tempData
                    if(tempData.hasOwnProperty('roomConfiguration') && tempData.roomConfiguration!=null){
                        let roomTyp=tempData.roomConfiguration.roomConfiguration.hasOwnProperty('roomTypes')? this.chkplndataarry(tempData.roomConfiguration.roomConfiguration.roomTypes) : ''
                        // this.temproomtype = roomTyp
                        console.log("roomTyp  ", roomTyp)
                        console.log(" this.tarrifData  ",  this.tarrifData)
                        for(let i=0;i<roomTyp.length;i++){
                            this.noOfRooms.push(
                                {
                                    displayval:roomTyp[i].roomType,
                                    text:roomTyp[i].roomType, 
                                    value:roomTyp[i].roomType,
                                    inputValue:''
                                }
                            )
                        }
                        console.log(this.noOfRooms,"this.noOfRooms")
                        this.headersNew.splice(4, 0, ...this.noOfRooms);
                        console.log( this.headersNew," this.headersNew")
                        
                    }
                    for(let ob in this.tarrifData){
                            console.log("ob ", ob)
                        for(let i=0; i<this.tarrifArry.length; i++){
                                console.log(this.tarrifArry[i].wrap,"ob ", ob)
                                if(ob == this.tarrifArry[i].wrap){
                                
                                    if(this.tarrifData[ob]== null){
                                
                                        this.tarrifData[ob] = {
                                        [this.tarrifArry[i].arr]:[],
                                        [this.tarrifArry[i].discount]:''
                                    }
                                
                                }
                            
                            }
                        
                        }
                        
                        }
                        console.log(this.tarrifData)
                        this.reviewtarrifData.push(this.tarrifData)
                        resolve(true)
                })
            })
            console.log("When did it ran")
            compnt_render.then(res=>{
                console.log(res,"Resolve Promise")
                this.shwDtaTbl=true
            })

           
        },

        chkplndataStrnfy(data){
            console.log("chkplndata",data); 
            try{
                if (data ==="" || data === "undefined" || data === null) {
                    return ""
                } else {
                    return JSON.stringify(data);
                }

             }catch(err) {
                console.log(err)
            }
            
        },

        chkplndataarry(data){
            console.log("chkplndataarry",data); 
            try{
                if (data ==="" || data === "undefined" || data === null) {
                    return []
                } else {
                    return data;
                }

             }catch(err) {
                console.log(err)
            }
            
        },

        chkplndata(data){
            console.log("chkplndata",data); 
            try{
                if (data ==="" || data === "undefined" || data === null) {
                    return ""
                } else {
                    return data;
                }

             }catch(err) {
                console.log(err)
            }
            
        },
        submitTariff(){
            let id=this.$store.state.createtarrifDetails._id

            this.PUT(2,'tariff/update-tariff-status/'+id,null,
                ( res, error) => {
                 if(!error){
                    console.log(res.data);
                    this.showToast(res.data.msg ,this.TOST().SUCCESS);
                }else{
                    console.log("when status is not 200", error.response);
                    this.showToast(error.response.data.msg ,this.TOST().ERROR);
                }
    
            });

        },
        viewInclusion(incmgData){
            console.log(incmgData,"incmgData")
            if(incmgData===undefined || incmgData===null||incmgData.length===0){
                this.showToast('No Inclusions Found', this.TOST().WARNING);
            }
           this.rsltData= incmgData.inclusionCharges
           console.log(this.rsltData,"this.rsltData")
             this.inclsnsDilg=true
        },
        close_inclsns_Dilg(){
            this.inclsnsDilg=false
        },
        

        close_dwnld_dlg(){
            this.RmrkDilg=false

        },
        clear_Rmrks(){
            this.rjctRmk=''
        },
        rejectProduct(){
            console.log('reject Product data rmk')
            if( this.rjctRmk===''|| this.rjctRmk===null|| this.rjctRmk===undefined){
                 this.showToast('Please enter remarks to Reject', this.TOST().WARNING);
                 return
            }
            
            let TarrifID=this.$store.state.createtarrifDetails._id
            console.log(this.$store.state.createtarrifDetails,"this.$store.state.createtarrifDetails");
            let formData = {
                remark: this.rjctRmk
            }
            this.PUT(2,'tariff/reject-tariff-setup/'+TarrifID,formData,
                ( res, error) => {
                 if(!error){
                    console.log(res.data);
                    this.showToast(res.data.msg ,this.TOST().SUCCESS);
                    this.RmrkDilg=false
                }else{
                    console.log("when status is not 200", error.response);
                    this.showToast(error.response.data.msg ,this.TOST().ERROR);
                    this.RmrkDilg=false
                }
    
            });
        },
        approveProduct(){
            console.log('approveProduct')
            let TarrifID=this.$store.state.createtarrifDetails._id
            console.log(this.$store.state.createtarrifDetails,"this.$store.state.createtarrifDetails");
            this.PUT(2,'tariff/approve-tariff-setup/'+TarrifID,null ,
                ( res, error) => {
                 if(!error){
                    console.log(res.data);
                    this.showToast(res.data.msg ,this.TOST().SUCCESS);
                }else{
                    console.log("when status is not 200", error.response);
                    this.showToast(error.response.data.msg ,this.TOST().ERROR);
                }
    
            });
        },
        editRmrk(){
            this.RmrkDilg=true
        }

    },
    
}
</script>
<style >
.backColorRvwChrgs{
    background-color: #23B1A9 !important;
    color:#FFFFFF!important;
    font-weight:500 !important;
    font-size:13px !important;
    /* padding:4px !important;  */
    /* padding-left:10px !important; */
}
.cust_brdr-top{
    border-top:1px solid #C1C8CC !important;
    border-left:1px solid #C1C8CC !important;
}
.cust_wdth300{
    width:300px !important;;
    border-right:1px solid #C1C8CC !important;
    font-size:12px !important;
    word-break: break-word !important;
    /* overflow-wrap: break-word !important; */
}
.cust_wdth1138{
    /* width:1139px !important; */
    background-color:#F7F7F7 !important;
    color:#10242B !important;
    font-size:12px !important;
    /* border-top:1px solid #C1C8CC !important; */
    word-break: break-word !important;

}
.cust_dsplyFlx{
    display:flex !important;
    border-top:1px solid #C1C8CC !important;

}
.cust_wdth156{
    /* width:156px !important; */
    font-size:12px !important;
    /* border-right:1px solid #C1C8CC !important; */
    word-break: break-word !important;
    

}
.cust_wdth90{
    min-width:90px !important;
    border-right:1px solid #C1C8CC !important;
    font-size:12px !important;
    word-break: break-word !important;
     /* overflow-wrap: break-word !important; */

}
.hdr-txt-clrInclsn{
    color:#fff !important;
    background-color: #01b4bb;

}

#table-reviewCharges-1 { 
  /* border: solid thin;  */
  border-collapse: collapse; 
}

#table-reviewCharges-1 th, 
#table-reviewCharges-1 td { 
  border: solid thin #C1C8CC;
  padding: 0.4rem 0.6rem;
}

#table-reviewCharges-1 td {
    font-size:12px !important;
     /* word-break: break-word !important; */
}

.wrd-break{
    word-break: break-word !important; 
}

</style>