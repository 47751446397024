<template>
    <v-app> 

        <indcomp   :indtype="{type:'edit',data:{indata:this.indvlData}}" @toggle-summary="toggleIndiSummary" ></indcomp>
        
      

        <div v-if="false"  style="background-color: #FFFFFF;"  class="">
            <v-layout color="#FFFFFF" class="mx-2 mt-3" style="background-color: #FFFFFF;">

                <v-flex xs3 >
                    <v-layout column class="cust_pr mx-2 mt-4 ">
                        <p class="font-weight-bold cstm-clr-pr">
                            Salutation *
                        </p>
                        <v-autocomplete
                            :items="sltnIndPsItems"
                            label="Select"
                            v-model="salutationindps"
                            solo
                            dense
                            class="rounded-0"
                            :rules="[required()]"
                            >
                        </v-autocomplete>
                    </v-layout>
                </v-flex>

               

                <v-flex xs3 >
                    <v-layout column class=" mx-2 mt-4 cust_pr">
                        <p class="font-weight-bold cstm-clr-pr">
                            First Name *
                        </p>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="frstnmeIndps"
                            class="rounded-0"
                            :rules="[required()]">
                        </v-text-field>
                    </v-layout>
                </v-flex>
                <v-flex xs3 >
                    <v-layout column class=" mx-2 mt-4 cust_pr">
                        <p class="font-weight-bold cstm-clr-pr">
                            Middle Name 
                        </p>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="mdlnmeInd"
                            class="rounded-0">
                        </v-text-field>
                    </v-layout>
                </v-flex>

                 <v-flex xs3 >
                    <v-layout column class=" mx-2 mt-4 cust_pr">
                        <p class="font-weight-bold cstm-clr-pr">
                            Last Name *
                        </p>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="lstnmeIndps"
                            class="rounded-0"
                            :rules="[required()]">
                        </v-text-field>
                    </v-layout>
                </v-flex>
                <v-flex xs3>
                    <v-layout column class=" mx-2 mt-4 cust_pr cust-rmvpd-pr">
                        <p class="font-weight-bold cstm-clr-pr">
                            Mobile Number *
                        </p>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="mbNoIndps"
                             class="rounded-0"
                             :rules="[required(),noOnly()]">
                            <div slot="prepend-inner" style="background-color:#F7F7F7;padding: 9px !important"><v-icon>smartphone</v-icon></div>
                        </v-text-field>
                        
                    </v-layout>
                </v-flex>

            </v-layout>
            

            <v-layout style="" class="mx-2" >
                <!-- <v-flex xs3>
                    <v-layout column class=" mx-2 mt-4 cust_pr">
                        <v-text class="font-weight-bold cstm-clr-pr">
                            Last Name *
                        </v-text>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="lstnmeIndps"
                             class="rounded-0">
                        </v-text-field>
                    </v-layout>
                </v-flex> -->

                
                

                

                <v-flex xs3>
                    <v-layout column class=" mx-2 cust_pr">
                        <p class="font-weight-bold cstm-clr-pr">
                           External Reference No.
                        </p>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="extrnlRfNoIndps"
                             class="rounded-0">
                        </v-text-field>
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class="cust_pr mx-2 ">
                        <p class="font-weight-bold cstm-clr-pr">
                           Provider Type
                        </p>
                        <v-autocomplete
                            :items="prvdrtyeitems"
                            label="Select"
                            v-model="prvdrTypeIndps"
                            solo
                            dense
                            class="rounded-0">
                        </v-autocomplete>
                    </v-layout>
                </v-flex>

                 <v-flex xs3>
                    <v-layout column class="cust_pr mx-2 ">
                        <p class="font-weight-bold cstm-clr-pr">
                            Other
                        </p>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="otherIndps"
                            class="rounded-0">
                        </v-text-field>
                    </v-layout>
                </v-flex>

                 <v-flex xs3>
                    <v-layout column class="cust_pr mx-2 ">
                        <p class="font-weight-bold cstm-clr-pr">
                            Registration Number
                        </p>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="RgstNops"
                            class="rounded-0">
                        </v-text-field>
                    </v-layout>
                </v-flex>
                <v-flex xs3>
                    <v-layout column class="cust_pr mx-2 ">
                        <p class="font-weight-bold cstm-clr-pr">
                            Registration Valid Upto
                        </p>
                        <!-- <v-text-field
                            solo
                            label="Enter..."
                            v-model="Rgstvlduptops"
                             class="rounded-0">
                        </v-text-field> -->

                        <v-menu
                            ref="calender"
                            :close-on-content-click="true"
                            v-model="RgstrnVldUptocalender"
                            :nudge-right="40"
                            transition="scale-transition"
                            max-width="290px"
                            min-width="290px" 
                              class="rounded-0"
                            >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-bind="attrs"
                                    v-on="on"
                                    label="Select.."
                                    :value="computedDateFormattedMomentjs"
                                    solo
                                     class="rounded-0"
                                    readonly 
                                ></v-text-field>
                            </template>                        
                                    <v-date-picker
                                        v-model="RgstrnVldUptodate"
                                        no-title   
                                    ></v-date-picker>
                        </v-menu>


                    </v-layout>
                </v-flex>
            </v-layout>

            <v-divider class="mb-3"></v-divider>

         

             <v-layout color="#FFFFFF" class="mx-2"  >
                <v-flex xs3>
                    <v-layout column class="cust_pr mx-2 mt-4 ">
                        <p class="font-weight-bold cstm-clr-pr">
                            Provider Flag
                        </p>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="prvdrFlgindps"
                             class="rounded-0">
                        </v-text-field>
                    </v-layout>
                </v-flex>

                <!-- <v-flex xs3>
                    <v-layout column class="mx-1 mt-4">
                        <v-text class="font-weight-bold cstm-clr-pr" >
                        </v-text> -->
                        <!-- <v-btn color="#23B1A9" dark class="mt-4 text-capitalize"><v-icon small left>arrow_upward</v-icon>Update Flag</v-btn> -->
                        <!-- <v-btn color="#23B1A9" dark class="mt-4 text-capitalize">
                            <div class="mt-1">
                                <img src="../../assets/update.png" style="width: 16px;height: 16px"/>
                            </div>
                            <v-divider vertical class="mx-2"></v-divider>
                        Update Flag</v-btn>
                    </v-layout>
                </v-flex> -->

                <v-flex xs3>
                    <v-layout column class="cust_pr mx-2 mt-4 ">
                        <p class="font-weight-bold cstm-clr-pr">
                            PRN
                        </p>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="prnIndps"
                             class="rounded-0">
                        </v-text-field>
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class="cust_pr mx-2 mt-4 ">
                        <p class="font-weight-bold cstm-clr-pr">
                            Status
                        </p>
                        <v-autocomplete
                            :items="statusIndpsItems"
                            label="Select"
                            v-model="statusIndps"
                            solo
                            dense
                             class="rounded-0">
                        </v-autocomplete>
                    </v-layout>
                </v-flex>

                 <v-flex xs3>
                    <v-layout column class=" mx-2 mt-4 cust_pr">
                        <p class="font-weight-bold cstm-clr-pr">
                            Gender *
                        </p>
                        <v-autocomplete
                            :items="genderindpsItems"
                            label="Select"
                            v-model="genderindps"
                            :rules="[required()]"
                            solo
                            dense
                             class="rounded-0">
                        </v-autocomplete>
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class="cust_pr cust-rmvpd-pr mx-2 mt-4 ">
                        <p class="font-weight-bold cstm-clr-pr">
                            E-mail
                        </p>
                        <v-text-field
                            solo
                            label="Enter..."
                            prepend-inner-icon="mail"
                            v-model="emailindps"
                            :rules="emailRules"
                            
                            class="pa-0 rounded-0">
                             <div slot="prepend-inner" style="background-color:#F7F7F7;padding: 9px !important"><v-icon>mail</v-icon></div>
                        </v-text-field>
                    </v-layout>
                </v-flex>

                

                

            </v-layout>


           

            <v-layout style="" class="mx-2" >
                   
                 <v-flex xs3>
                    <v-layout column class="cust_pr mx-2 ">
                        <p class="font-weight-bold cstm-clr-pr">
                           Qualification
                        </p>
                         <v-text-field
                            solo
                            label="Enter..."
                            v-model="qualificationIndps"
                             class="rounded-0">
                        </v-text-field>
                    </v-layout>
                </v-flex>
               

                <v-flex xs3>
                    <v-layout column class="cust_pr mx-2 ">
                        <p class="font-weight-bold cstm-clr-pr">
                           Other
                        </p>
                         <v-text-field
                            solo
                            label="Enter..."
                            v-model="otherIndps2"
                             class="rounded-0">
                        </v-text-field>
                    </v-layout>
                </v-flex>

                 <v-flex xs3>
                    <v-layout column class="cust_pr mx-2 ">
                        <p class="font-weight-bold cstm-clr-pr">
                            Registered Council
                        </p>
                        <v-autocomplete
                            :items="RgstrdCncilIndpsItems"
                            label="Select"
                            v-model="RgstrdCncilIndps"
                            solo
                            dense
                             class="rounded-0">
                        </v-autocomplete>
                    </v-layout>
                </v-flex>

                 <v-flex xs3>
                    <v-layout column class="cust_pr mx-2 ">
                        <p class="font-weight-bold cstm-clr-pr">
                            Other
                        </p>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="otherIndps3"
                             class="rounded-0">
                        </v-text-field>
                    </v-layout>
                </v-flex>

                <v-flex xs3></v-flex>
                
            </v-layout>
            
           
            
                <!-- status history Dialog -->
        

                 <!-- Doctor List history Dialog -->
              
                <v-layout>
                    <ErrorWarnings :visible="showScheduleForm" @close="showScheduleForm=false" :data="{error:'Provider Does not Meet Minimum bed criteria', Warnings:'Potential Duplicate Provider'}"></ErrorWarnings>
                </v-layout>
            
        </div>

        
    </v-app>
</template>

<script>
import moment from 'moment';
import indcomp from '../ProviderRegistrationClaims/Individual.vue'
import ErrorWarnings from '../../component/ErrorWarnings'
export default {

    components:{
        ErrorWarnings,
        indcomp
    },

    created(){

         console.log(this.$store.getters.fetchPrvdrData,"fetchPrvdrData")
        this.indvlData=this.$store.getters.fetchPrvdrData
    },

    mounted() {

        // this.$store.commit("SET_PAGE_TITLE", "Provider Summary");
       
        setTimeout(this.afterdata = true,200)
        // this.setfrmdata()

    },

    computed: {

         computedDateFormattedMomentjs () {
            console.log(this.RgstrnVldUptodate)
            console.log(this.RgstrnVldUptodate ? moment(this.RgstrnVldUptodate).format("DD-MM-YYYY"): '')
            return this.RgstrnVldUptodate ? moment(this.RgstrnVldUptodate).format("DD-MM-YYYY"): ''
        },

    },

    data() {
        return {
          
            afterdata:false,
            checkbox: false,
            indvlData:{},
            max: 10,
            RgstrnVldUptocalender: false,
            showScheduleForm: false,
            RgstrnVldUptodate: "",
            pageNumber: 1,
            prvdrStsHstryDilg:false,
            drLstDilg:false,
            items:['Institution', 'Individual'],
            rgstrdEntity:'Institution',
            entityNme:'',
            alsNme:'',
            prvdrtyeitems:['Others',"Doctor"],
            other:"",
            rgstNo:"",
            RgstrtnVldUpto:"",
            SrvcsPrvded:"",
            tlphnNo:"",
            adrss:"",
            prn:"",
            status:"",
            statusItems:["Active", "Inactive"],
            rohiniId:"",
            RgstrdBdy:"",
            email:"",
            fxNo:"",
            adrsLne1:"",
            adrsLne2:"",
            adrsLne3:"",
            country:"",
            cntryItems:["India","Others"],
            stateItems:["Maharashtra","Gujarat","Karnataka"],
            state:"",
            district:"",
            districtItems:["Thane", "Palghar","Raigad"],
            pnCde:"",
            ctvItems:["City","Town","Village", "Others"],
            ctv:"",
            othrAdrs:"",
            NoofBeds:"",
            dlyMdclRcrdsItems:[],
            dlyMdclRcrds:"",
            hsOtItems:[],
            hsOt:"",
            mprtcItems:[],
            mprtc:"",
            nrtcItems:[],
            nrtc:"",
            childProvider:'',
            parentProvider:'',
            mappingTypeItems:["Others"],
            mappingType:'',
            RgstnDtefectveFrmItems:["Others"],
            RgstnDtefectveFrm:'',
            RgstnDtefectveToItems:["Others"],
            RgstnDtefectveTo:'',
            rvwrRmrks:'',
            regstiondateItems:["Others"],
            regstiondate:'',
            lstDrItems:["Others"],
            lstDr:'',
            mdlnmeInd:'',
            selectedDr: [''],
            shwInstitutionps:true,
            shwIndividualps:false,
            sltnIndPsItems:["Mr.","Mrs","Dr"],
            salutationindps:'',
            frstnmeIndps:'',
            mdlnmeIndps:'',
            lstnmeIndps:'',
            gndrIndps:'',
            emailindps:'',
            mbNoIndps:'',
            extrnlRfNoIndps:'',
            prnIndps:'',
            statusIndps:"",
            statusIndpsItems:["Registered"],
            prvdrTypeIndps:'',            
            otherIndps:'',
            qualificationIndps:'',
            otherIndps2:'',
            otherIndps3:'',
            RgstNopsItems:['Others'],
            RgstNops:'',
            Rgstvlduptops:'',
            RgstrdCncilIndps:'',
            OtherIndps3:'',
            QualificationIndps2:'',
            remarksIndps:'',
            prvdrFlgindps:'',
            RgstrdCncilIndpsItems:["Others","21212"],
            genderindpsItems:["Male","Female"],
            genderindps:'',
            sltdDrdlglst:[],
            selectedDR:[],

            required(){
            return v=>v && v.length>0 || "This Field is Required"
            },
            minLength(minLength){
                return v=>v && v.length>=minLength || `Must be atleast ${minLength} Characters`
            },
            maxLength(maxLength){
                return v=>v && v.length<=maxLength || `Must be less than ${maxLength} Characters`
            },
            spclChar(){
                return v=>/^[A-Za-z0-9 ]+$/.test(v) || `Special Characters Not Allowed`
            },
            noOnly(){
                return v => /^[0-9 ]+$/.test(v) || 'Numbers Only Allowed'
            },

           emailRules: [
                    v => !!v || 'E-mail is required',
                    v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,6})+$/.test(v) || 'E-mail must be valid'
            ],

        };



    },

    methods: {

        toggleIndiSummary(id){
            console.log("%c this is form institution Summary","color:red");
            this.$emit("toggle-indiSummary", id);
        },

        rgstrdEntySlctn(value){
            console.log(value)
            if(value==="Institution"){
                this.shwInstitutionps=true
                this.shwIndividualps=false
            }else if(value==="Individual"){
                this.shwInstitutionps=false
                this.shwIndividualps=true
            }

        },

    

       

        nextPage(page) {
            this.pageNumber = page;
        },

        open_drLst(){
            this.drLstDilg=true
        },
        close_drLstFrm(){
            this.drLstDilg=false
        },
        clr_sltdDrdlglst(){
            this.sltdDrdlglst=[]
        },
        // setfrmdata(){
        //     this.salutationindps=this.indvlData.salutation
        //     this.frstnmeIndps=this.indvlData.firstName
        //     this.lstnmeIndps=this.indvlData.lastName
        //     this.mbNoIndps=this.indvlData.mobleNumber
        //     this.extrnlRfNoIndps=this.indvlData.externalReferenceNo
        //     this.prvdrTypeIndps=this.indvlData.providerType
        //     this.otherIndps=this.indvlData.providerTypeOther
        //     this.RgstNops=this.indvlData.registrationNumber
        //     this.prnIndps=this.indvlData.prn
        //     this.statusIndps=this.indvlData.status
        //      this.genderindps=this.indvlData.gender
        //      this.emailindps=this.indvlData.email
        //      this.qualificationIndps=this.indvlData.qualification
        //      this.RgstrdCncilIndps=this.indvlData.registeredCouncil
        // }

    },

    watch: {
    
    },
    
    
}

</script>
<style >

.hdr-txt-clrnew{
    color:#fff !important;
    background-color: #01b4bb;

}

.cust_pr .v-text-field .v-input__slot,
.v-text-field--outline .v-input__slot {
    min-height: 42px !important;
    display: flex !important;
    align-items: center !important;
    /* border:1px red solid; */
}

.cust-rmvpd-pr .v-text-field.v-text-field--enclosed>.v-input__control>.v-input__slot {
    padding: 0px !important;
}
/* .cust_pr .v-input__slot{
    margin-bottom: unset !important;
} */
.cstm-clr-pr{
    color:#646666 !important;
    margin-bottom: 5px !important;
    font-size: 13px !important;
}
.cust_pr .v-input{
    font-size: 13px !important;
}
.cust_pr .v-input input {
    font-size: 13px !important;
}

.cust_pr .v-input .v-label {
    font-size: 13px !important;
}

.cust-rmvpd-psins .v-text-field.v-text-field--enclosed>.v-input__control>.v-input__slot {
    padding-right: 0px !important;;
}

.title-clr-pr{
    color:#10242B;
    margin-bottom: 5px;
}

.lbl-bgclr{
    color:#152F38

}


/* width */
/* ::-webkit-scrollbar {
  width: 6px;
} */

/* Track */
/* ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #FFFFFF; 
  border-radius: 10px;
} */
 
/* Handle */
/* ::-webkit-scrollbar-thumb {
  background:#C9C9C9; 
  border-radius: 5px;
} */

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
  background: #b30000; 
} */

.dlg-tbl-brdr{
    border-right:1px solid #C1C8CC;
    border-top:1px solid #C1C8CC;
    font-size:12px
    
}

.cust-bnkAcc-pgn .v-pagination__navigation{
        box-shadow:unset !important;
        border-radius: unset !important;
        background-color:#FFFFFF !important;
        border:1px solid #DAE1E6 !important
    }
    .cust-bnkAcc-pgn .v-pagination__navigation--disabled{
        background-color:#DAE1E6 !important;
    }
    .cust-bnkAcc-pgn .v-pagination__item{
        box-shadow:unset !important;
        min-width:1.8rem !important;
        height:1.8rem !important;
        border-radius: unset !important;
        border:1px solid #DAE1E6 !important
    }
 .v-dialog{
    overflow-y: unset !important;
}


</style>