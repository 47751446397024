<template>
      <v-row>
        <v-col>
          <v-expansion-panels multiple>
            <v-expansion-panel class="accordion-style" v-for="(item,index) in accordionList" :key="index">
              <v-expansion-panel-header>{{ item }}</v-expansion-panel-header>
              <v-expansion-panel-content v-if="item == 'Member Details'">
                <MemberDetailsTab :claimDetails="claimDetails"/>
              </v-expansion-panel-content>
              <v-expansion-panel-content v-if="item == 'Insured Details'">
                <InsuredDetailsTab :claimDetails="claimDetails"/>
              </v-expansion-panel-content>
              <v-expansion-panel-content v-if="item == 'Premium Payer/Payer Details'">
                <PayerDetailsTab :claimDetails="claimDetails"/>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
  </template>
<script>
  import MemberDetailsTab from "./MemberDetailsTab"
  import PayerDetailsTab from "./PayerDetailsTab.vue"
  import InsuredDetailsTab from "./InsuredDetailsTab.vue"
  import { getClaimDetailsId } from '../apiservices/formData';
  export default {
    name: "HelloWorld",
    data() {
        return {
            accordionList: ['Member Details', 'Insured Details', 'Premium Payer/Payer Details'],
            claimDetails: {},
        }
    },
    created() {
      this.getClaimRegistrationDetails();
     },
     methods: {
      async getClaimRegistrationDetails() {
        try {
          let res = await getClaimDetailsId(this.$route.params.claimId);
          this.claimDetails = res.data;
          console.log(res);
        } catch(e) {
          this.showToast(e.response?.data?.msg || e.response?.data  || e.response || e, this.TOST().ERROR);
        }
      }
     },
    components: {
      MemberDetailsTab,
      PayerDetailsTab,
      InsuredDetailsTab
    }
  };
</script>
<style scoped>
    .v-expansion-panel{
        padding: 25px !important;
    }
    .v-expansion-panel-header {
        background: #4643431a !important;
        height: 1005 !important;
        padding: 25px !important
    }
    .v-expansion-panel-content {
        margin-top: 30px !important;
    }
</style>>