<template>
    <v-container class="containerStyle">
        <v-form id="" ref="createRepairer" @submit.prevent="updateChildRepairer" class="mt-3">
            <v-flex>
                <v-layout wrap class="pt-4 form-group-container">
                    <v-flex class="form-group">
                        <label class="form-label">LOB <span>*</span></label>
                        <v-select
                v-model="lob"
                label="Select"
                :items="lobOptions"
                :disabled="viewFormOnly"
                solo
                dense
                multiple
                class="form-control"
                
                clearable
            ></v-select>
                    </v-flex>
                    <v-flex class="form-group">
                        <label class="form-label">User ID <span>*</span></label>
                            <v-text-field
                                v-model="userName"
                                class="forminput textDisable font-weight-bold"
                                disabled
                                :background-color="'#f5f5f5'"
                                solo
                                dense
                            >
                            </v-text-field>
                        
                    </v-flex>
                    <v-flex class="form-group">
                        <label class="form-label">Parent Repairer ID <span>*</span></label>
                            <v-text-field
                                solo
                                dense
                                disabled
                                :background-color="'#f5f5f5'"
                                v-model="parentRepairerID"
                                :rules="rules.mandatoryrule"
                                @keydown.space="(event) => event.preventDefault()"
                                class="forminput textDisable font-weight-bold"
                            ></v-text-field>
                        
                    </v-flex>
                    <v-flex class="form-group">
                        <label class="form-label">Child Repairer ID <span>*</span></label>
                            <v-text-field
                                solo
                                dense
                                disabled
                                :background-color="'#f5f5f5'"
                                v-model="childRepairerID"
                                @keydown.space="(event) => event.preventDefault()"
                                :rules="rules.mandatoryrule"
                                class="forminput textDisable font-weight-bold"
                            ></v-text-field>
                        
                    </v-flex>
                    <v-flex class="form-group">
                        <label class="form-label">Child Repairer Name <span v-if="viewFormOnly">*</span><span class="mandatorycolor" v-else>*</span></label>
                            <v-text-field
                                :placeholder="viewFormOnly ? '' : 'Enter...'"
                                solo
                                dense
                                type="text"
                                v-on:keypress="isLetter($event)"
                                :disabled="viewFormOnly"
                                :background-color="viewFormOnly ? '#f5f5f5':'#FFFFFF'"
                                v-model="childRepairerName"
                                :rules="rules.mandatoryrule"
                                :maxLength="138"
                                :class="viewFormOnly ? 'forminput textDisable font-weight-bold' : 'forminput'"
                            ></v-text-field>
                        
                    </v-flex>
                    
                    <v-flex class="form-group">
                        <label class="form-label">Address Line 1 <span v-if="viewFormOnly">*</span><span class="mandatorycolor" v-else>*</span></label>
                                                    <v-text-field
                                                        :placeholder="viewFormOnly ? '' : 'Enter...'"
                                                        solo
                                                        dense
                                                        :disabled="viewFormOnly"
                                                        :background-color="viewFormOnly ? '#f5f5f5':'#FFFFFF'"
                                                        :maxLength="45"
                                                        v-model="addressLine1"
                                                        :rules="[mandatoryrule(), (v) => addressValidation(v)]"
                                                        :class="viewFormOnly ? 'forminput textDisable font-weight-bold' : 'forminput'"
                                                    ></v-text-field>
                                                
                                            </v-flex>
                                            <v-flex class="form-group">
                        <label class="form-label">Address Line 2</label>
                                                    <v-text-field
                                                        :placeholder="viewFormOnly ? '' : 'Enter...'"
                                                        solo
                                                        dense
                                                        :maxLength="45"
                                                        :disabled="viewFormOnly"
                                                        :background-color="viewFormOnly ? '#f5f5f5':'#FFFFFF'"
                                                        :rules="[(v) => addressValidation(v)]"
                                                        v-model="addressLine2"
                                                        :class="viewFormOnly ? 'forminput textDisable font-weight-bold' : 'forminput'"
                                                    ></v-text-field>
                                                
                                            </v-flex>
                                            <v-flex class="form-group">
                        <label class="form-label">Address Line 3</label>
                                                    <v-text-field
                                                        :placeholder="viewFormOnly ? '' : 'Enter...'"
                                                        solo
                                                        dense
                                                        :maxLength="35"
                                                        :disabled="viewFormOnly"
                                                        :background-color="viewFormOnly ? '#f5f5f5':'#FFFFFF'"
                                                        :rules="[(v) => addressValidation(v)]"
                                                        v-model="addressLine3"
                                                        :class="viewFormOnly ? 'forminput textDisable font-weight-bold' : 'forminput'"
                                                    ></v-text-field>
                                                
                                            </v-flex>
                                            <v-flex class="form-group">
                        <label class="form-label">Postal Code <span v-if="viewFormOnly">*</span><span class="mandatorycolor" v-else>*</span></label>
                                                    <v-autocomplete
                                                        v-model="postalCode"
                                                        :search-input.sync="searchPincode"
                                                        :class="viewFormOnly ? 'forminput textDisable font-weight-bold' : 'forminput'"
                                                        :items="pincodes"
                                                        item-text="locpincode"
                                                        item-value="locpincode"
                                                        @change="toGetCountryCity(postalCode)"
                                                        :placeholder="viewFormOnly ? '' : 'Start typing to Search'"
                                                        :maxlength="6"
                                                        :rules="rules.mandatoryrule"
                                                        :disabled="viewFormOnly"
                                                        :background-color="viewFormOnly ? '#f5f5f5':'#FFFFFF'"
                                                        solo
                                                        dense
                                                        hide-no-data
                                                        deletable-chips
                                                        clearable>
                                                    </v-autocomplete>
                                                
                                            </v-flex>
                                            <v-flex class="form-group">
                        <label class="form-label">Country <span v-if="viewFormOnly">*</span><span class="mandatorycolor" v-else>*</span></label>
                                                    <v-autocomplete
                                                        v-model="country"
                                                        :search-input.sync="searchCountry"
                                                        :class="viewFormOnly ? 'forminput textDisable font-weight-bold' : 'forminput'"
                                                        :items="countries"
                                                        item-text="countryName"
                                                        @change="getClaimantStates"
                                                        :placeholder="viewFormOnly ? '' : 'Start typing to Search'"
                                                        solo
                                                        hide-no-data
                                                        :disabled="viewFormOnly"
                                                        :background-color="viewFormOnly ? '#f5f5f5':'#FFFFFF'"
                                                        dense
                                                        :rules="rules.mandatoryrule"
                                                        deletable-chips
                                                        clearable
                                                    ></v-autocomplete>
                                                
                                            </v-flex>
                                            <v-flex class="form-group">
                        <label class="form-label">State/Province <span v-if="viewFormOnly">*</span><span class="mandatorycolor" v-else>*</span></label>
                                                    <v-autocomplete
                                                        v-model="state"
                                                        :search-input.sync="searchState"
                                                        :class="viewFormOnly ? 'forminput textDisable font-weight-bold' : 'forminput'"
                                                        :items="states"
                                                        item-text="stateCodeName"
                                                        :rules="rules.mandatoryrule"
                                                        @change="getClaimantCities"
                                                        :placeholder="viewFormOnly ? '' : 'Start typing to Search'"
                                                        solo
                                                        dense
                                                        hide-no-data
                                                        :disabled="viewFormOnly"
                                                        :background-color="viewFormOnly ? '#f5f5f5':'#FFFFFF'"
                                                        deletable-chips
                                                        clearable
                                                    >
                                                    </v-autocomplete>
                                                
                                            </v-flex>
                                            <v-flex class="form-group">
                        <label class="form-label">District</label>
                                                    <v-autocomplete
                                                        v-model="district"
                                                        :search-input.sync="searchDistrict"
                                                        :class="viewFormOnly ? 'forminput textDisable font-weight-bold' : 'forminput'"
                                                        :items="districts"
                                                        item-text="cityDistrictName"
                                                        :placeholder="viewFormOnly ? '' : 'Start typing to Search'"
                                                        solo
                                                        hide-no-data
                                                        dense
                                                        :disabled="viewFormOnly"
                                                        :background-color="viewFormOnly ? '#f5f5f5':'#FFFFFF'"
                                                        deletable-chips
                                                        clearable>
                                                    </v-autocomplete>
                                                
                                            </v-flex>
                                            <v-flex class="form-group">
                        <label class="form-label">City</label>
                                                    <v-autocomplete
                                                        v-model="city"
                                                        :search-input.sync="searchCity"
                                                        :class="viewFormOnly ? 'forminput textDisable font-weight-bold' : 'forminput'"
                                                        :items="cities"
                                                        item-text="cityDistrictName"
                                                        :placeholder="viewFormOnly ? '' : 'Start typing to Search'"
                                                        solo
                                                        :rules="rules.mandatoryrule"
                                                        @change="clearClaimantcity"
                                                        dense
                                                        deletable-chips
                                                        hide-no-data
                                                        clearable
                                                        :disabled="viewFormOnly"
                                                        :background-color="viewFormOnly ? '#f5f5f5':'#FFFFFF'"
                                                    >
                                                    </v-autocomplete>
                                                
                                            </v-flex>
                                            <v-flex class="form-group">
                        <label class="form-label">Phone Number</label>
                                                    <v-text-field
                                                        :placeholder="viewFormOnly ? '' : 'Enter...'"
                                                        solo
                                                        dense
                                                        @copy.prevent
                                                        @paste.prevent
                                                        :maxLength="10"
                                                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                                        :disabled="viewFormOnly"
                                                        :background-color="viewFormOnly ? '#f5f5f5':'#FFFFFF'"
                                                        v-model="phoneNumber"
                                                        v-on:keypress="isNumber"
                                                        :class="viewFormOnly ? 'forminput textDisable font-weight-bold' : 'forminput'">
                                                        <template v-slot:prepend-inner class="mx-0 my-0">
                                                        <v-icon class="iconstyle">mdi-phone</v-icon>
                                                        </template>
                                                    </v-text-field>
                                                
                                            </v-flex>
                                            <v-flex class="form-group">
                        <label class="form-label">Child Repairer Status <span v-if="viewFormOnly">*</span><span class="mandatorycolor" v-else>*</span></label>
                            <v-select
                                :label="viewFormOnly || checkParentStatus ? '' : 'Select'"
                                solo
                                dense
                                :items="childRepairerStatusOptions"
                                :disabled="viewFormOnly || checkParentStatus"
                                :background-color="viewFormOnly || checkParentStatus ? '#f5f5f5':'#FFFFFF'"
                                v-model="childRepairerStatus"
                                :rules="rules.mandatoryrule"
                                :class="viewFormOnly || checkParentStatus ? 'forminput textDisable font-weight-bold' : 'forminput'"
                            ></v-select>
                        
                    </v-flex>
                    <v-flex class="form-group">
                        <label class="form-label">Overall Satisfaction Rating <span v-if="viewFormOnly">*</span><span class="mandatorycolor" v-else>*</span></label>
                            <v-select
                                :label="viewFormOnly ? '' : 'Select'"
                                solo
                                dense
                                :items="overAllSatisfactionOptions"
                                :disabled="viewFormOnly"
                                :background-color="viewFormOnly ? '#f5f5f5':'#FFFFFF'"
                                v-model="overallSatisfaction"
                                :rules="rules.mandatoryrule"
                                :class="viewFormOnly ? 'forminput textDisable font-weight-bold' : 'forminput'"
                            ></v-select>
                        
                    </v-flex>
                </v-layout>
            </v-flex>
            <!-- <v-flex>
                <v-layout>
                    <v-flex xs3>
                        <v-layout column class="ml-3">
                            <p class="mb-2 claimlabel">Address</p>
                            <v-btn class="addressbtn" color="#FFFFFF" @click="openAddressDialog">
                                <span>Contact & Address</span>
                                <v-icon class="right-icon" style="color:#23B1A9">mdi-arrow-right-circle</v-icon>
                            </v-btn>
                            <span class="adressmsg mt-1 ml-3" v-if="!hideaddressMessage">{{ addressMsgRequired }}</span>
                        </v-layout>
                    </v-flex>
                    
                    <v-flex xs9></v-flex>
                </v-layout>
            </v-flex> -->
            <v-expansion-panels v-model="panels" multiple>
            <!-- Service Agrement details form -->
            <v-expansion-panel>
                    <v-expansion-panel-header color="#1EBBD9">
                            <span class="page-title-small white--text">Service Agreement</span>
                            <template v-slot:actions>
                                <v-icon class="white--text">
                                    {{ panels.indexOf(0) !== -1 ? "mdi-minus-box" : "mdi-plus-box" }}
                                </v-icon>
                            </template>
                        </v-expansion-panel-header>
                        
                        <v-expansion-panel-content>
                            <v-flex >
                <v-flex>
                    <v-layout>
                        <v-flex xs3>
                            <v-layout column class="ml-3">
                                <p class="mb-2 claimlabel">Repairer Agreement Effective Date <span v-if="viewFormOnly">*</span><span class="mandatorycolor" v-else>*</span></p>
                                <v-menu
                                    class="fnolReportedDate"
                                    :disabled="viewFormOnly"
                                    :background-color="viewFormOnly ? '#f5f5f5':'#FFFFFF'"
                                    v-model="agreementEffectiveDate"
                                    :close-on-content-click="false"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-text-field
                                            :placeholder="viewFormOnly ? '' : 'DD/MM/YYYY'"
                                            dense
                                            solo
                                            :rules="rules.mandatoryrule"
                                            readonly
                                            :disabled="viewFormOnly"
                                            :background-color="viewFormOnly ? '#f5f5f5':'#FFFFFF'"
                                            v-model="computedEffectiveDate"
                                            v-on="on"
                                            :class="viewFormOnly ? 'forminput textDisable font-weight-bold' : 'forminput'"
                                        >
                                            <template v-slot:prepend-inner class="mx-0 my-0">
                                                <v-icon class="iconstyle" >mdi-calendar</v-icon>
                                            </template>
                                        </v-text-field>
                                    </template>
                                    <v-date-picker              
                                        no-title          
                                        :disabled="viewFormOnly"
                                        :background-color="viewFormOnly ? '#f5f5f5':'#FFFFFF'"
                                        v-model="effectiveDate"      
                                        @input="agreementEffectiveDate = false">
                                    </v-date-picker>
                                </v-menu>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3>
                            <v-layout column class="ml-3">
                                <p class="mb-2 claimlabel">Repairer Agreement Expiration Date</p>
                                <v-menu
                                    class="fnolReportedDate"
                                    :disabled="viewFormOnly"
                                    :background-color="viewFormOnly ? '#f5f5f5':'#FFFFFF'"
                                    v-model="agreementExpirationDate"
                                    :close-on-content-click="false"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-text-field
                                            :placeholder="viewFormOnly ? '' : 'DD/MM/YYYY'"
                                            dense
                                            solo
                                            readonly
                                            :disabled="viewFormOnly"
                                            :background-color="viewFormOnly ? '#f5f5f5':'#FFFFFF'"
                                            v-model="computedExpirationDate"
                                            v-on="on"
                                            :class="viewFormOnly ? 'forminput textDisable font-weight-bold' : 'forminput'"
                                        >
                                            <template v-slot:prepend-inner class="mx-0 my-0">
                                                <v-icon class="iconstyle" >mdi-calendar</v-icon>
                                            </template>
                                        </v-text-field>
                                    </template>
                                    <v-date-picker              
                                        no-title          
                                        :disabled="viewFormOnly"
                                        :background-color="viewFormOnly ? '#f5f5f5':'#FFFFFF'"
                                        v-model="expirationDate"
                                        :min="new Date(new Date().setDate(new Date(effectiveDate).getDate() + 1)).toISOString().substr(0, 10)"       
                                        @input="agreementExpirationDate = false">
                                    </v-date-picker>
                                </v-menu>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3>
                            <v-layout column class="ml-3">
                                <p class="mb-2 claimlabel">Pick-Up/Delivery Option</p>
                                <v-radio-group v-model="pickupOption" row class="mt-0 font-weight-bold radiobox_border" :disabled="viewFormOnly">
                                    <v-radio
                                        class="ml-5 mt-1 claim fnolRadioButtons"
                                        color="#23B1A9"
                                        v-for="(opt,i) in pickupOptionrArray"
                                        :id="'fnolRadioButton_'+i"
                                        :key="opt.name"
                                        :label="`${opt.name}`"
                                        :value="opt.selected"
                                    >
                                    </v-radio>
                                </v-radio-group>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3>
                            <v-layout column class="ml-3">
                                <p class="mb-2 claimlabel">Trip Fee <span v-if="viewFormOnly">*</span><span class="mandatorycolor" v-else>*</span></p>
                                <v-radio-group v-model="tripFee" row class="mt-0 font-weight-bold radiobox_border" :disabled="viewFormOnly">
                                    <v-radio
                                        class="ml-5 mt-1 claim fnolRadioButtons"
                                        color="#23B1A9"
                                        v-for="(opt,i) in pickupOptionrArray"
                                        :id="'fnolRadioButton_'+i"
                                        :key="opt.name"
                                        :label="`${opt.name}`"
                                        :value="opt.selected"
                                    >
                                    </v-radio>
                                </v-radio-group>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3>
                            <v-layout column class="ml-3 mr-3" v-if="this.tripFee">
                                <p class="mb-2 claimlabel">Trip Fee : INR <span v-if="viewFormOnly">*</span><span class="mandatorycolor" v-else>*</span></p>
                                <v-text-field
                                    :placeholder="viewFormOnly ? '' : 'Enter...'"
                                    dense
                                    solo
                                    type="number"
                                    :disabled="viewFormOnly"
                                    :background-color="viewFormOnly ? '#f5f5f5':'#FFFFFF'"
                                    v-model="tripFeeInr"
                                    :rules="rules.mandatoryrule"
                                    :class="viewFormOnly ? 'forminput textDisable font-weight-bold' : 'forminput'"
                                >
                                    <template v-slot:prepend-inner class="mx-0 my-0">
                                        <v-icon class="iconstyle" >mdi-currency-inr</v-icon>
                                    </template>
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                    </v-layout>
                </v-flex>
            </v-flex>
            <v-flex >
                <v-flex>
                    <v-layout>
                        <v-flex xs3>
                            <v-layout column class="ml-3">
                                <p class="mb-2 claimlabel">Labor Rate : INR <span v-if="viewFormOnly">*</span><span class="mandatorycolor" v-else>*</span></p>
                                <v-select
                                    :label="viewFormOnly ? '' : 'Select'"
                                    solo
                                    dense
                                    :items="labourRateOptions"
                                    :disabled="viewFormOnly"
                                    :background-color="viewFormOnly ? '#f5f5f5':'#FFFFFF'"
                                    v-model="labourRateInr"
                                    :rules="rules.mandatoryrule"
                                    :class="viewFormOnly ? 'forminput textDisable font-weight-bold' : 'forminput'"
                                ></v-select>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3>
                            <v-layout column class="ml-3">
                                <p class="mb-2 claimlabel">Job Rate 1 <span v-if="viewFormOnly">*</span><span class="mandatorycolor" v-else>*</span></p>
                                <v-text-field
                                    :placeholder="viewFormOnly ? '' : 'Enter...'"
                                    solo
                                    dense
                                    type="number"
                                    :disabled="viewFormOnly"
                                    :background-color="viewFormOnly ? '#f5f5f5':'#FFFFFF'"
                                    v-model="jobRate1"
                                    :rules="rules.mandatoryrule"
                                    :class="viewFormOnly ? 'forminput textDisable font-weight-bold' : 'forminput'"
                                ></v-text-field>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3>
                            <v-layout column class="ml-3">
                                <p class="mb-2 claimlabel">Rate 2</p>
                                <v-text-field
                                    :placeholder="viewFormOnly ? '' : 'Enter...'"
                                    solo
                                    dense
                                    type="number"
                                    :disabled="viewFormOnly"
                                    :background-color="viewFormOnly ? '#f5f5f5':'#FFFFFF'"
                                    v-model="rate2"
                                    :class="viewFormOnly ? 'forminput textDisable font-weight-bold' : 'forminput'"
                                ></v-text-field>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3>
                            <v-layout column class="ml-3">
                                <p class="mb-2 claimlabel">Rate 3</p>
                                <v-text-field
                                    :placeholder="viewFormOnly ? '' : 'Enter...'"
                                    solo
                                    dense
                                    type="number"
                                    :disabled="viewFormOnly"
                                    :background-color="viewFormOnly ? '#f5f5f5':'#FFFFFF'"
                                    v-model="rate3"
                                    :class="viewFormOnly ? 'forminput textDisable font-weight-bold' : 'forminput'"
                                ></v-text-field>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3></v-flex>
                    </v-layout>
                </v-flex>
            </v-flex>
            <v-flex >
                <v-flex>
                    <v-layout>
                        <v-flex xs3>
                            <v-layout column class="ml-3">
                                <p class="mb-2 claimlabel">Last Audit Date</p>
                                <v-menu
                                    class="fnolReportedDate"
                                    :disabled="viewFormOnly"
                                    :background-color="viewFormOnly ? '#f5f5f5':'#FFFFFF'"
                                    v-model="auditDateMenu"
                                    :close-on-content-click="false"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-text-field
                                            :placeholder="viewFormOnly ? '' : 'DD/MM/YYYY'"
                                            dense
                                            solo
                                            readonly
                                            :disabled="viewFormOnly"
                                            :background-color="viewFormOnly ? '#f5f5f5':'#FFFFFF'"
                                            v-model="computedAuditDate"
                                            v-on="on"
                                            :class="viewFormOnly ? 'forminput textDisable font-weight-bold' : 'forminput'">
                                            <template v-slot:prepend-inner class="mx-0 my-0">
                                                <v-icon class="iconstyle" >mdi-calendar</v-icon>
                                            </template>
                                        </v-text-field>
                                    </template>
                                    <v-date-picker              
                                        no-title          
                                        :disabled="viewFormOnly"
                                        :background-color="viewFormOnly ? '#f5f5f5':'#FFFFFF'"
                                        v-model="auditDate"       
                                        @input="auditDateMenu = false">
                                    </v-date-picker>
                                </v-menu>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3>
                            <v-layout column class="ml-3">
                                <p class="mb-2 claimlabel">Next Audit Due Date</p>
                                <v-menu
                                    class="fnolReportedDate"
                                    :disabled="viewFormOnly"
                                    :background-color="viewFormOnly ? '#f5f5f5':'#FFFFFF'"
                                    v-model="auditDueDateMenu"
                                    :close-on-content-click="false"
                                    offset-y
                                    min-width="auto">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field
                                            :placeholder="viewFormOnly ? '' : 'DD/MM/YYYY'"
                                            dense
                                            solo
                                            readonly
                                            :disabled="viewFormOnly"
                                            :background-color="viewFormOnly ? '#f5f5f5':'#FFFFFF'"
                                            v-model="computedAuditDueDate"
                                            v-on="on"
                                            :class="viewFormOnly ? 'forminput textDisable font-weight-bold' : 'forminput'">
                                            <template v-slot:prepend-inner class="mx-0 my-0">
                                                <v-icon class="iconstyle" >mdi-calendar</v-icon>
                                            </template>
                                        </v-text-field>
                                    </template>
                                    <v-date-picker              
                                        no-title          
                                        :disabled="viewFormOnly"
                                        :background-color="viewFormOnly ? '#f5f5f5':'#FFFFFF'"
                                        v-model="auditDueDate"       
                                        @input="auditDueDateMenu = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-layout>
                        </v-flex>
                        <v-flex xs9></v-flex>
                    </v-layout>
                </v-flex>
            </v-flex>
                        </v-expansion-panel-content></v-expansion-panel>
            <!-- <v-flex class="mb-5">
                <v-row class="groupRowHeadNewLable mt-0">
                    <v-col align="left" class="mt-0 pt-2">
                        <v-flex class="ml-5 GroupNameNewLable">Service Agreement</v-flex>
                    </v-col>
                    <v-col align="right" class="pt-1 mt-0">
                        <v-icon class="mr-2 viconfont" dark @click="serviceAgreementMenu = !serviceAgreementMenu">
                            {{serviceAgreementMenu == true? "mdi-minus-box": "mdi-plus-box"}}
                        </v-icon>
                    </v-col>
                </v-row>
            </v-flex> -->
            

            <!-- Repairer Details form -->
            <v-expansion-panel>
                    <v-expansion-panel-header color="#1EBBD9">
                            <span class="page-title-small white--text">Repairer Details</span>
                            <template v-slot:actions>
                                <v-icon class="white--text">
                                    {{ panels.indexOf(1) !== -1 ? "mdi-minus-box" : "mdi-plus-box" }}
                                </v-icon>
                            </template>
                        </v-expansion-panel-header>
                        
                        <v-expansion-panel-content>
                            <v-flex >
                <v-flex>
                    <v-layout>
                        <v-flex xs3>
                            <v-layout column class="ml-3">
                                <p class="mb-2 claimlabel">Product (Type) <span v-if="viewFormOnly">*</span><span class="mandatorycolor" v-else>*</span></p>
                                <v-select
                                    :label="viewFormOnly ? '' : 'Multi Select'"
                                    solo
                                    dense
                                    multiple
                                    :disabled="viewFormOnly"
                                    :background-color="viewFormOnly ? '#f5f5f5':'#FFFFFF'"
                                    v-model="productType"
                                    :items="productTypeOptions"
                                    :rules="rules.mandatoryrule"
                                    :class="viewFormOnly ? 'forminput textDisable font-weight-bold' : 'forminput'"
                                >
                                    <template v-slot:selection="{ item, index }">
                                        <span v-if="index === 0">{{ item }}</span>
                                        <span
                                            v-if="index === 1"
                                            class="grey--text text-caption"
                                        >
                                            (+{{ productType.length - 1 }} others)
                                        </span>
                                    </template>
                                </v-select>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3>
                            <v-layout column class="ml-3">
                                <p class="mb-2 claimlabel">Authorized Brand(s) <span v-if="viewFormOnly">*</span><span class="mandatorycolor" v-else>*</span></p>
                                <v-select
                                    :label="viewFormOnly ? '' : 'Multi Select'"
                                    solo
                                    dense
                                    multiple
                                    :disabled="viewFormOnly"
                                    :background-color="viewFormOnly ? '#f5f5f5':'#FFFFFF'"
                                    v-model="authorizedBrands"
                                    :items="authorizedBrandOptions"
                                    :rules="rules.mandatoryrule"
                                    :class="viewFormOnly ? 'forminput textDisable font-weight-bold' : 'forminput'"
                                >
                                    <template v-slot:prepend-item>
                                        <v-list-item
                                            ripple
                                            @click="toggle"
                                        >
                                            <v-list-item-action>
                                                <v-icon :color="authorizedBrands.length > 0 ? 'indigo darken-4' : ''">
                                                    {{ icon }}
                                                </v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    All
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>
                                    <template v-slot:selection="{ item, index }">
                                        <span v-if="index === 0">{{ item }}</span>
                                        <span
                                            v-if="index === 1"
                                            class="grey--text text-caption"
                                            >
                                            (+{{ authorizedBrands.length - 1 }} others)
                                        </span>
                                    </template>
                                </v-select>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3>
                            <v-layout column class="ml-3">
                                <p class="mb-2 claimlabel">Repairer Warranty Period (in Days) <span v-if="viewFormOnly">*</span><span class="mandatorycolor" v-else>*</span></p>
                                <v-text-field
                                    :placeholder="viewFormOnly ? '' : 'Enter...'"
                                    solo
                                    dense
                                    type="number"
                                    :disabled="viewFormOnly"
                                    :background-color="viewFormOnly ? '#f5f5f5':'#FFFFFF'"
                                    v-model="repairerWarrantyPeriod"
                                    :rules="rules.mandatoryrule"
                                    :class="viewFormOnly ? 'forminput textDisable font-weight-bold' : 'forminput'"
                                ></v-text-field>
                            </v-layout>
                        </v-flex>
                        
                        <v-flex xs3>
                            <v-layout column class="ml-3 mr-3">
                                <p class="mb-2 claimlabel">First Name <span v-if="viewFormOnly">*</span><span class="mandatorycolor" v-else>*</span></p>
                                <v-text-field
                                    :placeholder="viewFormOnly ? '' : 'Enter...'"
                                    solo
                                    dense
                                    type="text"
                                    v-on:keypress="isLetter($event)"
                                    :disabled="viewFormOnly"
                                    :background-color="viewFormOnly ? '#f5f5f5':'#FFFFFF'"
                                    v-model="firstName"
                                    :rules="rules.mandatoryrule"
                                    :class="viewFormOnly ? 'forminput textDisable font-weight-bold' : 'forminput'"
                                ></v-text-field>
                            </v-layout>
                        </v-flex>
                    </v-layout>
                </v-flex>
            </v-flex>
            <v-flex >
                <v-flex>
                    <v-layout>
                        
                        <v-flex xs3>
                            <v-layout column class="ml-3">
                                <p class="mb-2 claimlabel">Last Name <span v-if="viewFormOnly">*</span><span class="mandatorycolor" v-else>*</span></p>
                                <v-text-field
                                    :placeholder="viewFormOnly ? '' : 'Enter...'"
                                    solo
                                    dense
                                    type="text"
                                    v-on:keypress="isLetter($event)"
                                    :disabled="viewFormOnly"
                                    :background-color="viewFormOnly ? '#f5f5f5':'#FFFFFF'"
                                    v-model="lastName"
                                    :rules="rules.mandatoryrule"
                                    :class="viewFormOnly ? 'forminput textDisable font-weight-bold' : 'forminput'"
                                ></v-text-field>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3>
                            <v-layout column class="ml-3">
                                <p class="mb-2 claimlabel">Work Phone Number <span v-if="viewFormOnly">*</span><span class="mandatorycolor" v-else>*</span></p>
                                <v-text-field
                                    :placeholder="viewFormOnly ? '' : 'Enter...'"
                                    solo
                                    dense
                                    :maxLength="10"
                                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                    :disabled="viewFormOnly"
                                    @copy.prevent
                                    @paste.prevent
                                    :background-color="viewFormOnly ? '#f5f5f5':'#FFFFFF'"
                                    v-model="workPhoneNumber"
                                    :rules="rules.mandatoryrule"
                                    v-on:keypress="isNumber"
                                    :class="viewFormOnly ? 'forminput textDisable font-weight-bold' : 'forminput'"
                                >
                                    <template v-slot:prepend-inner class="mx-0 my-0">
                                        <v-icon class="iconstyle">mdi-phone</v-icon>
                                    </template>
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3>
                            <v-layout column class="ml-3">
                                <p class="mb-2 claimlabel">Email ID <span v-if="viewFormOnly">*</span><span class="mandatorycolor" v-else>*</span></p>
                                <v-text-field
                                    :placeholder="viewFormOnly ? '' : 'Enter...'"
                                    solo
                                    dense
                                    :disabled="viewFormOnly"
                                    :background-color="viewFormOnly ? '#f5f5f5':'#FFFFFF'"
                                    v-model="emailID"
                                    :rules="rules.emailRules"
                                    :class="viewFormOnly ? 'forminput textDisable font-weight-bold' : 'forminput'"
                                >
                                    <template v-slot:prepend-inner class="mx-0 my-0">
                                        <v-icon class="iconstyle">mdi-email</v-icon>
                                    </template>
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3>
                            <v-layout column class="ml-3 mr-3">
                                <p class="mb-2 claimlabel">Cell Phone Number <span v-if="viewFormOnly">*</span><span class="mandatorycolor" v-else>*</span></p>
                                <v-text-field
                                    :placeholder="viewFormOnly ? '' : 'Enter...'"
                                    solo
                                    dense
                                    :maxLength="10"
                                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                    :disabled="viewFormOnly"
                                    @copy.prevent
                                    @paste.prevent
                                    :background-color="viewFormOnly ? '#f5f5f5':'#FFFFFF'"
                                    v-model="cellPhoneNumber"
                                    :rules="rules.mandatoryrule"
                                    v-on:keypress="isNumber"
                                    :class="viewFormOnly ? 'forminput textDisable font-weight-bold' : 'forminput'"
                                >
                                    <template v-slot:prepend-inner class="mx-0 my-0">
                                        <v-icon class="iconstyle">mdi-phone</v-icon>
                                    </template>
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                    </v-layout>
                </v-flex>
            </v-flex>
            <v-flex >
                <v-flex>
                    <v-layout>
                        <v-flex xs3>
                            <v-layout column class="ml-3">
                                <p class="mb-2 claimlabel">Communication Email ID</p>
                                <v-text-field
                                    :placeholder="viewFormOnly ? '' : 'Enter...'"
                                    solo
                                    dense
                                    :disabled="viewFormOnly"
                                    :background-color="viewFormOnly ? '#f5f5f5':'#FFFFFF'"
                                    v-model="communicationEmailID"
                                    :rules="[emailValidation(communicationEmailID)]"
                                    :class="viewFormOnly ? 'forminput textDisable font-weight-bold' : 'forminput'"
                                >
                                    <template v-slot:prepend-inner class="mx-0 my-0">
                                        <v-icon class="iconstyle">mdi-email</v-icon>
                                    </template>
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3>
                            <v-layout column class="ml-3 mr-3">
                                <p class="mb-2 claimlabel">Second Contact-Email Address</p>
                                <v-text-field
                                    :placeholder="viewFormOnly ? '' : 'Enter...'"
                                    solo
                                    dense
                                    :disabled="viewFormOnly"
                                    :background-color="viewFormOnly ? '#f5f5f5':'#FFFFFF'"
                                    v-model="secondEmailID"
                                    :rules="[emailValidation(secondEmailID)]"
                                    :class="viewFormOnly ? 'forminput textDisable font-weight-bold' : 'forminput'"
                                >
                                    <template v-slot:prepend-inner class="mx-0 my-0">
                                        <v-icon class="iconstyle">mdi-email</v-icon>
                                    </template>
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                        <v-flex xs9></v-flex>
                    </v-layout>
                </v-flex>
            </v-flex>
                        </v-expansion-panel-content></v-expansion-panel>
            <!-- <v-flex class="mb-5">
                <v-row class="groupRowHeadNewLable mt-0">
                    <v-col align="left" class="mt-0 pt-2">
                        <v-flex class="ml-5 GroupNameNewLable"></v-flex>
                    </v-col>
                    <v-col align="right" class="pt-1 mt-0">
                        <v-icon class="mr-2 viconfont" dark @click="productDetailAgreementMenu = !productDetailAgreementMenu">
                            {{productDetailAgreementMenu == true? "mdi-minus-box": "mdi-plus-box"}}
                        </v-icon>
                    </v-col>
                </v-row>
            </v-flex> -->
            

            <!-- Tax Details details form -->
            <v-expansion-panel>
                    <v-expansion-panel-header color="#1EBBD9">
                            <span class="page-title-small white--text">Tax Details</span>
                            <template v-slot:actions>
                                <v-icon class="white--text">
                                    {{ panels.indexOf(2) !== -1 ? "mdi-minus-box" : "mdi-plus-box" }}
                                </v-icon>
                            </template>
                        </v-expansion-panel-header>
                        
                        <v-expansion-panel-content>
                            <v-flex >
                <v-flex>
                    <v-layout>
                        <v-flex xs3>
                            <v-layout column class="pt-4 ml-3">
                                <p class="mb-2 claimlabel"></p>
                                <v-checkbox
                                    label="Is PAN not available"
                                    v-model="isPanNotAvailable"
                                    @change="checkIsPANAvailable()"
                                    class="checkboxstyle"
                                    dense
                                    solo
                                    :disabled="viewFormOnly"
                                    style="font-weight: bold"
                                    color="#23B1A9"
                                ></v-checkbox>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3>
                            <v-layout column class="ml-3">
                                <p class="mb-2 claimlabel">PAN Number <span :class="viewFormOnly ? '' : 'mandatorycolor'">*</span></p>
                                <v-text-field
                                    :placeholder="viewFormOnly ? '' : 'Enter...'"
                                    solo
                                    dense
                                    :disabled="viewFormOnly"
                                    :background-color="viewFormOnly ? '#f5f5f5':'#FFFFFF'"
                                    v-model="panNumber"
                                    :value="computedPAN"
                                    maxlength="10"
                                    @change="isPanNotAvailable ? {} : checkTDSType()"
                                    :rules="viewFormOnly ? [] : [v => requiredField(v), panValidation(panNumber)]"
                                    :class="viewFormOnly ? 'forminput textDisable font-weight-bold' : 'forminput'"
                                ></v-text-field>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3>
                            <v-layout column class="ml-3">
                                <p class="mb-2 claimlabel">GST Number <span :class="viewFormOnly ? '' : 'mandatorycolor'">*</span></p>
                                <v-text-field
                                    :placeholder="viewFormOnly ? '' : 'Enter...'"
                                    solo
                                    dense
                                    :disabled="viewFormOnly"
                                    :background-color="viewFormOnly ? '#f5f5f5':'#FFFFFF'"
                                    maxlength="15"
                                    v-model="gstNumber"
                                    :value="computedGST"
                                    :rules="[mandatoryrule(), gstValidation(gstNumber)]"
                                    :class="viewFormOnly ? 'forminput textDisable font-weight-bold' : 'forminput'"
                                ></v-text-field>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3>
                            <v-layout column class="ml-3">
                                <p class="mb-2 claimlabel">GST Frequency</p>
                                <v-select
                                    :label="viewFormOnly ? '' : 'Select'"
                                    solo
                                    dense
                                    :items="gstFrequencyOptions"
                                    :disabled="viewFormOnly"
                                    :background-color="viewFormOnly ? '#f5f5f5':'#FFFFFF'"
                                    v-model="gstFrequency"
                                    :class="viewFormOnly ? 'forminput textDisable font-weight-bold' : 'forminput'"
                                ></v-select>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3>
                            <v-layout column class="ml-3">
                                <p class="mb-2 claimlabel">TDS Type <span v-if="viewFormOnly">*</span><span class="mandatorycolor" v-else>*</span></p>
                                <!-- :disabled="viewFormOnly"   -->
                                <v-select
                                    solo
                                    dense
                                    :items="tdsTypeOptions"
                                    disabled
                                    background-color="#f5f5f5"
                                    v-model="tdsType"
                                    :rules="rules.mandatoryrule"
                                    class="forminput textDisable font-weight-bold"
                                ></v-select>
                            </v-layout>
                        </v-flex>
                    </v-layout>
                </v-flex>
            </v-flex>
            <v-flex >
                <v-flex>
                    <v-layout>
                        <v-flex xs3>
                            <v-layout column class="ml-3">
                                <p class="mb-2 claimlabel"></p>
                                <v-checkbox
                                    label="Differential TDS"
                                    class="px-2 checkboxstyle"
                                    dense
                                    :disabled="viewFormOnly"
                                    solo
                                    style="font-weight: bold"
                                    color="#23B1A9"
                                ></v-checkbox>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3>
                            <v-layout column class="ml-3">
                                <p class="mb-2 claimlabel">MSME <span v-if="viewFormOnly">*</span><span class="mandatorycolor" v-else>*</span></p>
                                <v-select
                                    :label="viewFormOnly ? '' : 'Select'"
                                    solo
                                    dense
                                    :items="msmeOptions"
                                    :disabled="viewFormOnly"
                                    :background-color="viewFormOnly ? '#f5f5f5':'#FFFFFF'"
                                    v-model="msme"
                                    :rules="rules.mandatoryrule"
                                    :class="viewFormOnly ? 'forminput textDisable font-weight-bold' : 'forminput'"
                                ></v-select>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3>
                            <v-layout column class="ml-3">
                                <p class="mb-2 claimlabel">Enterprise Classification</p>
                                <v-select
                                    :label="viewFormOnly ? '' : 'Select'"
                                    solo
                                    dense
                                    :disabled="viewFormOnly"
                                    :background-color="viewFormOnly ? '#f5f5f5':'#FFFFFF'"
                                    v-model="enterpriseClassification"
                                    :items="enterpriseClassificationData"
                                    :class="viewFormOnly ? 'forminput textDisable font-weight-bold' : 'forminput'"
                                ></v-select>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3>
                            <v-layout column class="ml-3">
                                <p class="mb-2 claimlabel">Enterprise Category</p>
                                <v-select
                                    :label="viewFormOnly ? '' : 'Select'"
                                    solo
                                    dense
                                    :disabled="viewFormOnly"
                                    :background-color="viewFormOnly ? '#f5f5f5':'#FFFFFF'"
                                    :items="enterpriseCategoryData"
                                    v-model="enterpriseCategory"
                                    :class="viewFormOnly ? 'forminput textDisable font-weight-bold' : 'forminput'"
                                ></v-select>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3>
                            <v-layout column class="ml-3 mr-3">
                                <p class="mb-2 claimlabel">UAN</p>
                                <v-text-field
                                    :placeholder="viewFormOnly ? '' : 'Enter...'"
                                    solo
                                    dense
                                    :disabled="viewFormOnly"
                                    :background-color="viewFormOnly ? '#f5f5f5':'#FFFFFF'"
                                    v-model="uan"
                                    :class="viewFormOnly ? 'forminput textDisable font-weight-bold' : 'forminput'"
                                ></v-text-field>
                            </v-layout>
                        </v-flex>
                    </v-layout>
                </v-flex>
            </v-flex>
                        </v-expansion-panel-content></v-expansion-panel>
            <!-- <v-flex class="mb-5">
                <v-row class="groupRowHeadNewLable mt-0">
                    <v-col align="left" class="mt-0 pt-2">
                        <v-flex class="ml-5 GroupNameNewLable"></v-flex>
                    </v-col>
                    <v-col align="right" class="pt-1 mt-0">
                        <v-icon class="mr-2 viconfont" dark @click="otherDetailAgreementMenu = !otherDetailAgreementMenu">
                            {{otherDetailAgreementMenu == true? "mdi-minus-box": "mdi-plus-box"}}
                        </v-icon>
                    </v-col>
                </v-row>
            </v-flex> -->
            

            <!-- Bank Details form -->
            <v-expansion-panel>
                    <v-expansion-panel-header color="#1EBBD9">
                            <span class="page-title-small white--text">Bank Details</span>
                            <template v-slot:actions>
                                <v-icon class="white--text">
                                    {{ panels.indexOf(3) !== -1 ? "mdi-minus-box" : "mdi-plus-box" }}
                                </v-icon>
                            </template>
                        </v-expansion-panel-header>
                        
                        <v-expansion-panel-content>
                            <v-flex >
                <v-flex>
                    <v-layout>
                        <v-flex xs3>
                            <v-layout column class="ml-3">
                                <p class="mb-2 claimlabel"></p>
                                <v-checkbox
                                    :label="`Bank Transfer`"
                                    class="px-2 checkboxstyle"
                                    dense
                                    solo
                                    :disabled="viewFormOnly || manualOverwrite"
                                    v-model="bankTransfer"
                                    style="font-weight: bold"
                                    color="#23B1A9"
                                ></v-checkbox>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3>
                            <v-layout column class="ml-3">
                                <p class="mb-2 claimlabel">IFSC Code <span class="mandatorycolor" v-if="bankTransfer && !viewFormOnly">*</span><span v-if="bankTransfer && viewFormOnly ">*</span></p>
                                <v-autocomplete
                                    v-model="ifscCode"
                                    :search-input.sync="searchIFSCCODE"
                                    :class="viewFormOnly || !manualOverwrite ? 'forminput textDisable font-weight-bold' : 'forminput'"
                                    item-text="ifscCode"
                                    item-value="ifscCode"
                                    clearable
                                    :disabled="viewFormOnly || !manualOverwrite"
                                    :background-color="viewFormOnly || !manualOverwrite ? '#f5f5f5':'#FFFFFF'"
                                    @change="getBankFields(ifscCode)"
                                    :items="bankIFSCDetailsItems"
                                    :rules="bankTransfer ? rules.mandatoryrule : []"
                                    :placeholder="viewFormOnly || !manualOverwrite ? '' : 'Start typing to Search'"
                                    :maxlength="11"
                                    hide-no-data
                                    solo
                                    dense
                                ></v-autocomplete>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3>
                            <v-layout column class="ml-3">
                                <p class="mb-2 claimlabel">Bank Name <span class="mandatorycolor" v-if="bankTransfer && !viewFormOnly">*</span><span v-if="bankTransfer && viewFormOnly ">*</span></p>
                                <v-text-field
                                    :placeholder="viewFormOnly || !manualOverwrite|| disableBankName ? '' : 'Enter...'"
                                    solo
                                    dense
                                    :disabled="viewFormOnly || !manualOverwrite || disableBankName"
                                    :background-color="disableBankName || !manualOverwrite|| viewFormOnly ? '#f5f5f5':'#FFFFFF'"
                                    v-model="bankName"
                                    :rules="bankTransfer ? rules.mandatoryrule : []"
                                    :class="disableBankName || !manualOverwrite || viewFormOnly ? 'forminput textDisable font-weight-bold' : 'forminput'"
                                ></v-text-field>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3>
                            <v-layout column class="ml-3">
                                <p class="mb-2 claimlabel">Bank Account Number <span class="mandatorycolor" v-if="bankTransfer && !viewFormOnly">*</span><span v-if="bankTransfer && viewFormOnly ">*</span></p>
                                <v-text-field
                                    :placeholder="viewFormOnly || !manualOverwrite ? '' : 'Enter...'"
                                    solo
                                    dense
                                    :disabled="viewFormOnly || !manualOverwrite"
                                    :background-color="viewFormOnly || !manualOverwrite ? '#f5f5f5':'#FFFFFF'"
                                    v-model="bankAccountNumber"
                                    :type="bankAccountNumber ? 'password' : 'text'"
                                    :maxlength="35"
                                   
                                    @copy.prevent
                                    @paste.prevent
                                    :rules="bankTransfer ? [(v) => requiredField(v),(v)=>matchRegex(v,'alphanum')] : []"
                                    :class="viewFormOnly || !manualOverwrite ? 'forminput textDisable font-weight-bold' : 'forminput'"
                                ></v-text-field>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3>
                            <v-layout column class="ml-3 mr-3">
                                <p class="mb-2 claimlabel">Re-enter Bank Account Number <span class="mandatorycolor" v-if="bankTransfer && !viewFormOnly">*</span><span v-if="bankTransfer && viewFormOnly ">*</span></p>
                                <v-text-field
                                    :placeholder="viewFormOnly || !manualOverwrite ? '' : 'Enter...'"
                                    solo
                                    dense
                                    
                                    :disabled="viewFormOnly || !manualOverwrite"
                                    :background-color="viewFormOnly || !manualOverwrite ? '#f5f5f5':'#FFFFFF'"
                                    :maxlength="35"
                                    v-model="confirmBankAccountNumber"
                                    @copy.prevent
                                    @paste.prevent
                                    :type="masterApproval ? 'password' : 'text'"
                                    :rules="bankTransfer ? [backAccountNumberValidation(confirmBankAccountNumber)] : []"
                                    :class="viewFormOnly || !manualOverwrite ? 'forminput textDisable font-weight-bold' : 'forminput'"
                                ></v-text-field>
                            </v-layout>
                        </v-flex>
                    </v-layout>
                </v-flex>
            </v-flex>
            <v-flex >
                <v-flex>
                    <v-layout>
                        <v-flex xs3>
                            <v-layout column class="ml-3">
                                <p class="mb-2 claimlabel">Account Type <span class="mandatorycolor" v-if="bankTransfer && !viewFormOnly">*</span><span v-if="bankTransfer && viewFormOnly ">*</span></p>
                                <v-select
                                    :label="viewFormOnly ? '' : 'Select'"
                                    solo
                                    dense
                                    :items="acountTypeOptions"
                                    v-model="accountType"
                                    :rules="bankTransfer ? rules.mandatoryrule : []"
                                    :disabled="viewFormOnly"
                                    :background-color="viewFormOnly ? '#f5f5f5':'#FFFFFF'"
                                    :class="viewFormOnly ? 'forminput textDisable font-weight-bold' : 'forminput'"
                                ></v-select>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3>
                            <v-layout column class="ml-3">
                                <p class="mb-2 claimlabel">Bank Address 1 <span class="mandatorycolor" v-if="bankTransfer && !viewFormOnly">*</span><span v-if="bankTransfer && viewFormOnly ">*</span></p>
                                <v-text-field
                                    :placeholder="viewFormOnly || !manualOverwrite || disableBankAdd1 ? '' : 'Enter...'"
                                    solo
                                    dense
                                    :disabled="viewFormOnly || !manualOverwrite || disableBankAdd1"
                                    :background-color="disableBankAdd1 || viewFormOnly || !manualOverwrite ? '#f5f5f5':'#FFFFFF'"
                                    v-model="bankAddress1"
                                    :rules="bankTransfer ? [(v)=>requiredField(v),(v) => addressValidation(v)] : [(v) => addressValidation(v)]"
                                    :class="viewFormOnly || !manualOverwrite || disableBankAdd1 ? 'forminput textDisable font-weight-bold' : 'forminput'"
                                ></v-text-field>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3>
                            <v-layout column class="ml-3">
                                <p class="mb-2 claimlabel">Bank Address 2 <span class="mandatorycolor" v-if="bankTransfer && !viewFormOnly">*</span><span v-if="bankTransfer && viewFormOnly ">*</span></p>
                                <v-text-field
                                    :placeholder="viewFormOnly || !manualOverwrite || disableBankAdd2 ? '' : 'Enter...'"
                                    solo
                                    dense
                                    :disabled="viewFormOnly || !manualOverwrite || disableBankAdd2"
                                    :background-color="disableBankAdd2 || viewFormOnly || !manualOverwrite ? '#f5f5f5':'#FFFFFF'"
                                    v-model="bankAddress2"
                                    :rules="bankTransfer ? [(v)=>requiredField(v),(v) => addressValidation(v)] : [(v) => addressValidation(v)]"
                                    :class="viewFormOnly || !manualOverwrite || disableBankAdd2 ? 'forminput textDisable font-weight-bold' : 'forminput'"
                                ></v-text-field>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3>
                            <v-layout column class="ml-3">
                                <p class="mb-2 claimlabel">Bank Address 3</p>
                                <v-text-field
                                    :placeholder="viewFormOnly || !manualOverwrite || disableBankAdd3 ? '' : 'Enter...'"
                                    solo
                                    dense
                                    :disabled="viewFormOnly || !manualOverwrite || disableBankAdd3"
                                    :background-color="viewFormOnly || !manualOverwrite || disableBankAdd3 ? '#f5f5f5':'#FFFFFF'"
                                    v-model="bankAddress3"
                                    :class="viewFormOnly || !manualOverwrite || disableBankAdd3 ? 'forminput textDisable font-weight-bold' : 'forminput'"
                                ></v-text-field>
                            </v-layout>
                        </v-flex>
                        <v-flex xs3>
                            <v-layout column class="ml-3 mr-3">
                                <p class="mb-2 claimlabel">Branch Name <span class="mandatorycolor" v-if="bankTransfer && !viewFormOnly">*</span><span v-if="bankTransfer && viewFormOnly ">*</span></p>
                                <v-text-field
                                    :placeholder="viewFormOnly || !manualOverwrite || disableBankBranch ? '' : 'Enter...'"
                                    solo
                                    dense
                                    :disabled="viewFormOnly || !manualOverwrite || disableBankBranch"
                                    :background-color="disableBankBranch || viewFormOnly || !manualOverwrite ? '#f5f5f5':'#FFFFFF'"
                                    v-model="branchName"
                                    :rules="bankTransfer ? rules.mandatoryrule : []"
                                    :class="viewFormOnly || !manualOverwrite || disableBankBranch ? 'forminput textDisable font-weight-bold' : 'forminput'"
                                ></v-text-field>
                            </v-layout>
                        </v-flex>
                    </v-layout>
                </v-flex>
            </v-flex>
            <v-flex  class="mb-3">
                <v-layout>
                    <v-flex xs3>
                        <v-layout column class="ml-3">
                            <p class="mb-2 claimlabel">Cancelled Cheque <span class="mandatorycolor" v-if="bankTransfer && !viewFormOnly">*</span><span v-if="bankTransfer && viewFormOnly ">*</span></p>
                            <v-btn class="addressbtn" color="#FFFFFF" :rules="rules.mandatoryrule" @click="openCancelledChequeDialog">
                                <span>Browse</span>
                                <v-icon class="right-icon" style="color:#E46A25">mdi-arrow-up-circle</v-icon>
                            </v-btn>
                            <span class="adressmsg mt-1 ml-3">{{ cancelledChequeRequired }}</span>
                        </v-layout>
                    </v-flex>
                    <v-flex xs3>
                        <v-layout column class="ml-3">
                            <p class="mb-2 claimlabel"></p>
                            <v-checkbox
                                :label="`Overwrite Manually`"
                                class="px-2 checkboxstyle"
                                dense
                                solo
                                style="font-weight: bold"
                                :disabled='viewFormOnly || cancelledChequeFileData.length < 1'
                                v-model="manualOverwrite"
                                @change="changeSubmissionType()"
                                color="#23B1A9"
                            ></v-checkbox>
                        </v-layout>
                    </v-flex>
                    <!-- <v-flex xs3 v-if="confirmAccountFlag">
                        <v-layout column class="ml-3">
                            <p class="mb-2 claimlabel">Confirm Bank Account Number</p>
                            <v-text-field
                                class="forminput"
                                dense
                                solo
                                type="password"
                                v-on:keypress="isNumber"
                                placeholder="Enter.."
                                @copy.prevent
                                @paste.prevent
                                :rules="[checkConfirmBankNo(confirmBankAccountNo)]"
                                v-model="confirmBankAccountNo"
                            ></v-text-field>
                        </v-layout>
                    </v-flex>
                    <v-flex xs6 v-if="confirmAccountFlag"></v-flex>-->

                    <v-flex xs9 v-if="!confirmAccountFlag"></v-flex> 
                </v-layout>
            </v-flex>
                        </v-expansion-panel-content></v-expansion-panel>
            <!-- <v-flex class="mb-5">
                <v-row class="groupRowHeadNewLable mt-0">
                    <v-col align="left" class="mt-0 pt-2">
                        <v-flex class="ml-5 GroupNameNewLable">Bank Details</v-flex>
                    </v-col>
                    <v-col align="right" class="pt-1 mt-0">
                        <v-icon class="mr-2 viconfont" dark @click="bankDetailAgreement = !bankDetailAgreement">
                            {{bankDetailAgreement == true? "mdi-minus-box": "mdi-plus-box"}}
                        </v-icon>
                    </v-col>
                </v-row>
            </v-flex> -->
            

            <!-- Parent Repairer Details table -->
            <v-expansion-panel>
                    <v-expansion-panel-header color="#1EBBD9">
                            <span class="page-title-small white--text">Parent Repairer Details</span>
                            <template v-slot:actions>
                                <v-icon class="white--text">
                                    {{ panels.indexOf(4) !== -1 ? "mdi-minus-box" : "mdi-plus-box" }}
                                </v-icon>
                            </template>
                        </v-expansion-panel-header>
                        
                        <v-expansion-panel-content>
                            <v-flex >
                <template>
                    <v-data-table
                        :headers="parentRepairerHeaders"
                        :items="parentRepairerMastersData"
                        no-data-text="No Records for Parent Repairers"
                        hide-default-footer
                        class="vDataTableStyling">
                        <template v-slot:[`item.repairerId`] = "{item}">
                            <v-btn color="#FFFFFF" class="tablebtn" style="width: 75%" @click="openParentWithChildRepairer(item.repairerId)">
                            {{ limitTextLength(item.repairerId) }}
                            <div class="vertical_line mr-8"></div>
                            <v-icon class="tableicons">mdi-arrow-right-circle</v-icon>
                            </v-btn>
                        </template>
                        <template v-slot:[`item.status`]="{ item }">
                            <span :class="getStatusStyle(item)">
                            {{item.repairerStatus ? 'Active' : 'Inactive'}}
                            </span>
                        </template>
                    </v-data-table>
                </template>
            </v-flex>
                        </v-expansion-panel-content></v-expansion-panel>
            <!-- <v-flex class="mb-5">
                <v-row class="groupRowHeadNewLable mt-0">
                    <v-col align="left" class="mt-0 pt-2">
                        <v-flex class="ml-5 GroupNameNewLable">Parent Repairer Details</v-flex>
                    </v-col>
                    <v-col align="right" class="pt-1 mt-0">
                        <v-icon class="mr-2 viconfont" dark @click="parentRepairerDetails = !parentRepairerDetails">
                            {{parentRepairerDetails == true? "mdi-minus-box": "mdi-plus-box"}}
                        </v-icon>
                    </v-col>
                </v-row>
            </v-flex> -->
            

            <!-- Child Repairer Details table  -->
            <v-expansion-panel>
                    <v-expansion-panel-header color="#1EBBD9">
                            <span class="page-title-small white--text">Child Repairer Details</span>
                            <template v-slot:actions>
                                <v-icon class="white--text">
                                    {{ panels.indexOf(5) !== -1 ? "mdi-minus-box" : "mdi-plus-box" }}
                                </v-icon>
                            </template>
                        </v-expansion-panel-header>
                        
                        <v-expansion-panel-content>
                            <v-flex >
                <template>
                    <v-data-table
                        :headers="childRepairerHeaders"
                        :items="childRepairerMastersPaginationData"
                        no-data-text="No Records for Child Repairers"
                        hide-default-footer
                        class="vDataTableStyling">
                        <template v-slot:[`item.repairerId`] = "{item}" >
                            <v-btn color="#FFFFFF" class="tablebtn" style="width: 75%">
                                {{ limitTextLength(item.repairerId) }}<div class="vertical_line mr-8"></div>
                                <v-icon class="tableicons">mdi-arrow-right-circle</v-icon>
                            </v-btn>
                        </template>
                        <template v-slot:[`item.status`]="{ item }">
                            <span :class="getStatusStyle(item)">{{item.repairerStatus ? 'Active' : 'Inactive'}}</span>
                        </template>
                    </v-data-table>
                    <v-layout fluid class="flexbg mb-3" v-if="this.childRepairerMastersPaginationData.length > 0">
                        <v-layout justify-end>
                            <div style="margin-top: 10px">
                                <span class="orangetext paginationText">
                                    Showing {{ totalListCount ? pageStartIndex + 1 : 0 }} - {{pageEndIndex}}
                                </span>
                                <span class="mr-2 paginationText"> out of {{ totalListCount }} records </span>
                            </div>
                            <v-pagination
                                :disabled="viewFormOnly"
                                v-model="pageNumber"
                                :length="totalPages"
                                :total-visible="pageRowCount"
                                @input="goNextPagePagination"
                                color="#152F38"
                                class="navpagenumber pt-1"
                            ></v-pagination>
                        </v-layout>
                    </v-layout>
                </template>
            </v-flex>
                        </v-expansion-panel-content></v-expansion-panel>
            <!-- <v-flex class="mb-5">
                <v-row class="groupRowHeadNewLable mt-0">
                    <v-col align="left" class="mt-0 pt-2">
                        <v-flex class="ml-5 GroupNameNewLable">Child Repairer Details</v-flex>
                    </v-col>
                    <v-col align="right" class="pt-1 mt-0">
                        <v-icon class="mr-2 viconfont" dark @click="childRepairerDetails = !childRepairerDetails">
                            {{childRepairerDetails == true? "mdi-minus-box": "mdi-plus-box"}}
                        </v-icon>
                    </v-col>
                </v-row>
            </v-flex> -->

            
        </v-expansion-panels>
            <!-- buttons -->
            <v-layout fluid class="flexbg mt-3">
                <v-layout justify-end align-center>
                    <v-layout justify-start>
                        <v-btn color="#E46A25" dark class="ml-3 buttonscolor" @click="openAttachments">
                        <v-icon class="iconsize mr-2">mdi-plus</v-icon>
                        <div class="vertical_line"></div>Attachments
                        </v-btn>

                        <!-- Phase-2 changes starts-->
                        <!-- <v-btn color="#152F38" dark class="ml-3 buttonscolor" @click="openRemarks">
                            <v-icon class="iconsize mr-2">mdi-message</v-icon>
                            <div class="vertical_line"></div>Remarks
                        </v-btn> -->
                        <!-- Phase-2 changes ends-->
                    </v-layout>
                    <v-btn color="#23B1A9" dark class="ml-3 buttonscolor" v-if="!this.viewFormOnly" type="submit">
                        <v-icon class="iconsize mr-2">mdi-check-circle</v-icon>
                        <div class="vertical_line"></div>Save
                    </v-btn>
                    <v-btn color="#E46A25" dark class="ml-3 buttonscolor" @click="clearChildRepairerWithParentForm" v-if="!this.viewFormOnly">
                        <v-icon class="iconsize mr-2">mdi-close-circle</v-icon>
                        <div class="vertical_line"></div>Clear
                    </v-btn>
                    <!-- Phase-2 changes starts-->
                    <v-btn class="ml-3 buttonscolor apprvebtn" v-if="masterApproval" @click="approve_Dialog=!approve_Dialog">
                        <v-icon class="iconsize mr-2">mdi-check-circle</v-icon>
                        <div class="vertical_line"></div>Approve
                    </v-btn>
                    <v-btn color="#D1121B" dark class="ml-3 buttonscolor" v-if="masterApproval" 
                        @click="reject_Dialog=!reject_Dialog">
                        <v-icon class="iconsize mr-2">mdi-close-circle</v-icon>
                        <div class="vertical_line"></div>Reject
                    </v-btn>
                    <v-btn color="#23B1A9" dark class="ml-3 buttonscolor" @click="openAuditTrail">
                        <v-icon class="iconsize mr-2">mdi-file-document</v-icon>
                        <div class="vertical_line"></div>Audit Trail
                    </v-btn>
                    <!-- Phase-2 changes ends-->
                    <v-btn color="#152F38" dark class="ml-3 mr-3 buttonscolor" @click="goBack">
                        <v-icon class="iconsize mr-2">mdi-arrow-left-circle</v-icon>
                        <div class="vertical_line"></div>Back
                    </v-btn>
                </v-layout>
            </v-layout>

            <!-- Address Dialog -->
            <v-row justify="center">
                <v-dialog v-model="addressDialog" persistent max-width="75%">
                    <v-card>
                        <v-card-title class="pl-0 px-0 py-0 addressPopUpHeaderStyle">
                            <v-row>
                                <span class="descrptionSpan mb-5 ml-8 mr-8">Address</span>
                                <v-icon @click="addressDialog = false" class="ml-10 mt-0">mdi-window-close</v-icon>
                            </v-row>
                            <v-card-text class="addressPopUpStyle">
                                <v-form ref="addressref" @submit.prevent="proceed">
                                    <v-flex class="backgroundWhite">
                                        <v-layout class="mt-2">
                                            
                                        </v-layout>
                                        <v-layout class="mt-2">
                                            
                                        </v-layout>
                                        <v-layout class="mt-2">
                                            
                                            <v-flex xs3>
                                                <v-layout column class="ml-3">
                                                    <p class="mb-2 claimlabel">Preferred Communication Method</p>
                                                    <v-select
                                                        :label="viewFormOnly ? '' : 'Select'"
                                                        :items="preferredComAddresses"
                                                        solo
                                                        dense
                                                        :disabled="viewFormOnly"
                                                        :background-color="viewFormOnly ? '#f5f5f5':'#FFFFFF'"
                                                        v-model="preferredCommunicationMethod"
                                                        :class="viewFormOnly ? 'forminput textDisable font-weight-bold' : 'forminput'"
                                                    ></v-select>
                                                </v-layout>
                                            </v-flex>
                                            <v-flex xs3>
                                                <v-layout column class="ml-3">
                                                    <p class="mb-2 claimlabel">Fax Number</p>
                                                    <v-text-field
                                                        :placeholder="viewFormOnly ? '' : 'Enter...'"
                                                        solo
                                                        dense
                                                        :disabled="viewFormOnly"
                                                        :background-color="viewFormOnly ? '#f5f5f5':'#FFFFFF'"
                                                        v-model="faxNumber"
                                                        :class="viewFormOnly ? 'forminput textDisable font-weight-bold' : 'forminput'"
                                                    ></v-text-field>
                                                </v-layout>
                                            </v-flex>
                                            <v-flex xs3>
                                                <v-layout column class="ml-3 mr-3">
                                                    <p class="mb-2 claimlabel">Second Contact-Fax Number</p>
                                                    <v-text-field
                                                        :placeholder="viewFormOnly ? '' : 'Enter...'"
                                                        solo
                                                        dense
                                                        :disabled="viewFormOnly"
                                                        :background-color="viewFormOnly ? '#f5f5f5':'#FFFFFF'"
                                                        v-model="secondFaxNumber"
                                                        :class="viewFormOnly ? 'forminput textDisable font-weight-bold' : 'forminput'"
                                                    ></v-text-field>
                                                </v-layout>
                                            </v-flex>
                                        </v-layout>
                                    </v-flex>
                                    <!-- Cancel & Proceed buttons -->
                                    <v-flex justify="center" class="mt-5">
                                        <v-row>
                                            <v-col align="center">
                                                <v-btn color="#23B1A9" dark class="buttonscolor mr-5" type="submit" v-if="!viewFormOnly">
                                                    <v-icon class="mr-2 iconsize">mdi-arrow-right-circle</v-icon>
                                                    <div class="vertical_line"></div>Proceed
                                                </v-btn>
                                                <v-btn
                                                    color="#E46A25"
                                                    dark
                                                    class="buttonscolor mr-5"
                                                    @click="addressDialog = false"
                                                >
                                                    <v-icon class="mr-2 iconsize" >mdi-close-circle</v-icon>
                                                    <div class="vertical_line"></div>Close
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-flex>
                                </v-form>
                            </v-card-text>
                        </v-card-title>
                    </v-card>
                </v-dialog>
            </v-row>

            <!-- Cancelled cheque dialog -->
            <v-row justify="center">
                <v-dialog v-model="cancelChequeDialog" persistent max-width="460px">
                    <v-card>
                        <v-card-title class="pl-0 px-0 py-0 dailogboxAllvcardTableBackground">
                            <v-row>
                                <span class="descrptionSpanAttach mb-5 ml-10 mr-0">Cancelled Cheque</span>
                                <v-icon @click="cancelChequeDialog = false" class="ml-1 mt-0">mdi-window-close</v-icon>
                            </v-row>
                            <v-card-text class="vcardTextTableBackground">
                                <v-flex class="backgroundWhite mx-3">
                                    <v-flex class="mb-3">
                                        <v-btn class="browsebtn mt-5 ml-4 mr-4" @click="uploadBtnClicked()" :disabled="viewFormOnly">Browse
                                            <div style="margin-left:80%"></div><v-icon class="tableicons" color="#E46A25 !important">mdi-arrow-up-circle</v-icon>
                                        </v-btn>
                                        <v-file-input
                                            multiple
                                            v-model="cancelledCheque"
                                            @change="filesSelected"
                                            :disabled="viewFormOnly"
                                            accept=".doc, .docx, .csv, image/png,image/jpeg,application/msword,application/pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/csv"
                                            id="fileupload"
                                            style="display: none">
                                        </v-file-input>
                                    </v-flex>        
                                    <template v-if="this.cancelledChequeFileData.length !== 0">
                                        <v-data-table
                                            :headers="attachmentheaders"
                                            :items="cancelledChequeFileData"
                                            hide-default-footer
                                            hide-default-header
                                            class="attachheader"
                                        >
                                            <template v-slot:[`item.icon`] = "{ item }">
                                                <span v-if="checkFileType(item.documentType) === 'pdf'"><img src="../../../assets/pdf.png" alt="pdf" height="25px"></span>
                                                <span v-if="checkFileType(item.documentType) === 'excel'"><img src="../../../assets/excel.png" alt="excel" height="25px"></span>
                                                <span v-if="checkFileType(item.documentType) === 'word'"><img src="../../../assets/word.png" alt="doc" height="25px"></span>
                                                <span v-if="checkFileType(item.documentType) === 'image'"><img src="../../../assets/imageicon.png" alt="png" height="25px"></span>
                                            </template>
                                            <template v-slot:[`item.name`]="{ item }">
                                                <span class="mr-0">{{ limitTextLength(item.file) }}</span>
                                            </template>
                                            <template v-slot:[`item.view`] = "{ item }">
                                                <v-btn color="#FFFFFF" class="tablebtn" @click="viewDocument(item)">View
                                                <div class="mr-3"></div>
                                                <v-icon class="tableicons" color="#E46A25 !important">mdi-eye</v-icon>
                                                </v-btn>
                                            </template>
                                            <template v-slot:[`item.delete`]="{ item, index }">
                                                <span v-if="!item.status"><v-icon class="closeicon" @click="deleteCancelledCheque(index)">mdi-close-circle</v-icon></span>
                                            </template>
                                        </v-data-table>
                                    </template>
                                </v-flex>
                                <v-flex justify="center" class="mt-5">
                                    <v-row class="btnDiv">
                                        <v-col align="center">
                                            <v-btn
                                                color="#23B1A9"
                                                dark
                                                class="buttonscolor mr-5"
                                                @click="cancelChequeDialog = false">
                                                <v-icon class="mr-2 iconsize" >mdi-close-circle</v-icon>
                                                <div class="vertical_line"></div>OK
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-flex>
                            </v-card-text>
                        </v-card-title>
                    </v-card>
                </v-dialog>
            </v-row>

            <!-- Attachments Dialog   -->
            <v-row justify="center">
                <v-dialog v-model="attachmentsdialog" persistent max-width="460px">
                    <v-card>
                        <v-card-title class="pl-0 px-0 py-0 dailogboxAllvcardTableBackground">
                            <v-row>
                                <span class="descrptionSpanAttach mb-5 ml-10 mr-0">Attachments <span class="attachtxt">( Multiple documents can be uploaded )</span></span>
                                <v-icon @click="attachmentsdialog = false" class="ml-1 mt-0">mdi-window-close</v-icon>
                            </v-row>
                            <v-card-text class="vcardTextTableBackground">
                                <v-flex class="backgroundWhite mx-3">
                                    <v-flex class="mb-3">
                                        <v-btn class="browsebtn mt-5 ml-4 mr-4" @click="openBrowse()" :disabled="viewFormOnly">Browse
                                            <div style="margin-left:80%"></div><v-icon class="tableicons" color="#E46A25 !important">mdi-arrow-up-circle</v-icon>
                                        </v-btn>
                                        <v-file-input
                                            multiple
                                            v-model="browse_attachments"
                                            @change="fileUploaded"
                                            accept=".doc, .docx, .csv, image/png,image/jpeg,application/msword,application/pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/csv"
                                            id="fileupload1"
                                            style="display: none">
                                        </v-file-input>
                                    </v-flex>        
                                    <template v-if="this.attachments.length !== 0">
                                        <v-data-table
                                            :headers="attachmentheaders"
                                            :items="attachments"
                                            hide-default-footer
                                            hide-default-header
                                            class="attachheader">
                                            <template v-slot:[`item.icon`] = "{ item }">
                                                <span v-if="checkFileType(item.documentType) === 'pdf'"><img src="../../../assets/pdf.png" alt="pdf" height="25px"></span>
                                                <span v-if="checkFileType(item.documentType) === 'excel'"><img src="../../../assets/excel.png" alt="excel" height="25px"></span>
                                                <span v-if="checkFileType(item.documentType) === 'word'"><img src="../../../assets/word.png" alt="doc" height="25px"></span>
                                                <span v-if="checkFileType(item.documentType) === 'image'"><img src="../../../assets/imageicon.png" alt="png" height="25px"></span>
                                            </template>
                                            <template v-slot:[`item.name`]="{ item }">
                                                <span class="mr-0">{{ limitTextLength(item.file) }}</span>
                                            </template>
                                            <template v-slot:[`item.view`] = "{ item }">
                                                <v-btn color="#FFFFFF" class="tablebtn" @click="viewDocument(item)">View
                                                <div class="mr-3"></div>
                                                <v-icon class="tableicons" color="#E46A25 !important">mdi-eye</v-icon>
                                                </v-btn>
                                            </template>
                                            <template v-slot:[`item.delete`] = "{ item, index }">
                                                <span v-if="!item.status"><v-icon class="closeicon" @click="deleteAttachments(index)">mdi-close-circle</v-icon></span>
                                            </template>
                                        </v-data-table>
                                    </template>
                                </v-flex>
                                <v-flex justify="center" class="mt-5">
                                    <v-row class="btnDiv">
                                        <v-col align="center">
                                            <v-btn
                                                color="#23B1A9"
                                                dark
                                                class="buttonscolor mr-5"
                                                @click="attachmentsdialog = false">
                                                <v-icon class="mr-2 iconsize" >mdi-close-circle</v-icon>
                                                <div class="vertical_line"></div>OK
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-flex>
                            </v-card-text>
                        </v-card-title>
                    </v-card>
                </v-dialog>
            </v-row>

            <!-- Phase-2 changes starts -->
            <!-- AUDITS -->
            <v-row justify="center">
                <v-dialog v-model="auditTrailOpen" persistent max-width="75%">
                    <v-card>
                        <v-card-title class="pl-0 px-0 py-0 addressPopUpHeaderStyle">
                            <v-row>
                                <span class="descrptionSpan mb-5 ml-8 mr-8">Repairer Audit Trail</span>
                                <v-icon @click="auditTrailOpen = false" class="ml-10 mt-0">mdi-window-close</v-icon>
                            </v-row>
                            <v-card-text class="addressPopUpStyle">
                                <v-flex class="backgroundWhite">
                                    <v-simple-table fixed-header class="vDataTableStyling mx-3 mt-2 doctable mb-0">
                                        <template>
                                            <thead>
                                                <tr>
                                                    <th class="text-center" width="8%">Sr. No.</th>
                                                    <th class="text-center" width="12%">Repairer ID</th>
                                                    <th class="text-center" width="14%">Repairer Name</th>
                                                    <th class="text-center" width="14%">Action Taken</th>
                                                    <th class="text-center" width="14%">User ID</th>
                                                    <th class="text-center" width="14%">User Name</th>
                                                    <th class="text-center" width="18%">Time Stamp</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(item, Rindex) in auditTrailData" :key="'CR'+ Rindex">
                                                    <td>{{ ( pageRowCount1 * (pageNumber1 - 1)) + Rindex + 1 }}</td>
                                                    <td>{{ item.masterId ==  "" ? "-------" : item.masterId}}</td>
                                                    <td>{{item.masterName == "" ? "-------" : item.masterName}}</td>
                                                    <td v-if="item.action == 'Modified'">
                                                        <v-btn color="#FFFFFF" class="tablebtn" @click="openDetailedAuditTrail(item.modification)">
                                                            {{item.action ==  "" ? "-------" : item.action}}
                                                            <v-icon class="tableicons">mdi-arrow-right-circle</v-icon>
                                                        </v-btn>
                                                    </td>
                                                    <td v-else>
                                                            {{item.action ==  "" ? "-------" : item.action}}
                                                    </td>
                                                    <td>{{item.userId ==  "" ? "-------" : item.userId}}</td>
                                                    <td>{{item.userName ==  "" ? "-------" : item.userName}}</td>
                                                    <td>{{item.createdAt ==  "" ? "-------" : dateFormat(item.createdAt)}}</td>
                                                </tr>
                                                <tr class="v-data-table__empty-wrapper" v-if="auditTrailData.length === 0">
                                                    <td colspan="12">No Records for Audit Trail</td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>    
                                    <v-layout fluid class="flexbg mb-2 mx-3" v-if="auditTrailData.length > 0">
                                        <v-layout justify-end>
                                            <div style="margin-top: 12px">
                                                <span class="orangetext paginationText">
                                                    Showing {{ totalListCount1 ? pageStartIndex1 + 1 : 0 }} - {{pageEndIndex1 }}
                                                </span>
                                                <span class=" mr-2 paginationText"> out of {{ totalListCount1 }} records </span>
                                            </div>
                                            <v-pagination
                                                v-model="pageNumber1"
                                                :length="totalPages1"
                                                :total-visible="pageRowCount1"
                                                @input="loadNextPage"
                                                color="#152F38"
                                                class="navpagenumber pt-1"
                                            ></v-pagination>
                                        </v-layout>
                                    </v-layout>
                                </v-flex>
                            </v-card-text>
                        </v-card-title>
                    </v-card>
                </v-dialog>
            </v-row>

            <!-- detailed audit  -->
            <v-row justify="center">
                <v-dialog v-model="detailedAuditTrailOpen" persistent max-width="75%">
                    <v-card>
                        <v-card-title class="pl-0 px-0 py-0 addressPopUpHeaderStyle">
                            <v-row>
                                <span class="descrptionSpan mb-5 ml-8 mr-8">Modified Fields Details</span>
                                <v-icon @click="detailedAuditTrailOpen = false" class="ml-10 mt-0">mdi-window-close</v-icon>
                            </v-row>
                            <v-card-text class="addressPopUpStyle">
                                <v-flex class="backgroundWhite">
                                    <v-simple-table fixed-header class="vDataTableStyling mx-3 mt-2 doctable mb-0">
                                        <template>
                                            <thead>
                                                <tr>
                                                    <th class="text-center" width="8%">Sr. No.</th>
                                                    <th class="text-center" width="14%">Field Name</th>
                                                    <th class="text-center" width="14%">Old Value</th>
                                                    <th class="text-center" width="18%">New Value</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(item, Rindex) in detailedAuditTrailData" :key="'CR'+ Rindex">
                                                    <td>{{ Rindex + 1 }}</td>
                                                    <td>{{ item.fieldName ==  "" ? "-------" : item.fieldName}}</td>
                                                    <td>{{item.oldValue ==  "" ? "-------" : item.oldValue}}</td>
                                                    <td>{{item.newValue == "" ? "-------" : item.newValue}}</td>
                                                </tr>
                                                <tr class="v-data-table__empty-wrapper" v-if="detailedAuditTrailData.length === 0">
                                                    <td colspan="12">No Records Available</td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>    
                                </v-flex>
                            </v-card-text>
                        </v-card-title>
                    </v-card>
                </v-dialog>
            </v-row>

            <!-- REMARKS -->
            <v-row justify="center">
                <v-dialog v-model="remarksdialog" persistent max-width="60%"  style="border-radius:0">
                    <v-card>
                        <v-card-title class="pl-0 px-0 py-0 dailogboxAllvcardTableBackground">
                            <v-row>
                                <span class="descrptionSpan mb-5 ml-8 mr-5">Remarks</span>
                                <v-icon @click="remarksdialog = false" class="ml-5 mt-0">mdi-window-close</v-icon>
                            </v-row>
                            <v-card-text class="vcardTextTableBackground">
                                <v-form ref="remarkform" >
                                    <v-flex class="backgroundWhite">
                                        <!-- <v-layout class="mt-2" >
                                            <v-flex xs4 v-if="!viewFormOnly || masterApproval">
                                                <v-layout column class="ml-3">
                                                    <p class="mb-2 claimlabel">Remarks Type <span class="mandatorycolor">*</span></p>
                                                    <v-select
                                                        placeholder="Select"
                                                        solo
                                                        dense
                                                        clearable
                                                        :rules="rules.mandatoryrule"
                                                        :items="remarksTypeArray"
                                                        v-model="remarkType"
                                                        class="forminput">
                                                    </v-select>
                                                </v-layout>
                                            </v-flex>
                                        </v-layout>      -->

                                        <v-layout >
                                            <v-layout column class="ml-3" v-if="!viewFormOnly || masterApproval">
                                                <p class="mb-2 claimlabel">Remarks <span class="mandatorycolor">*</span></p>
                                                <v-text-field
                                                    class="forminput mr-3"
                                                    color="black"
                                                    solo
                                                    dense
                                                    :rules="rules.mandatoryrule"
                                                    v-model="remarkText"
                                                    :maxlength="4000"
                                                    label="Enter..">
                                                </v-text-field>
                                            </v-layout>
                                        </v-layout> 
                                                
                                        <!-- <v-simple-table fixed-header class="vDataTableStyling  mx-3 mt-2 doctable mb-3">
                                            <template>
                                                <thead>
                                                    <tr>
                                                        <th class="text-center" width="7%">User ID</th>
                                                        <th class="text-center" width="9%">Date</th>
                                                        <th class="text-center" width="9%">Remark Type</th>
                                                        <th class="text-center" width="14%">Remarks</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(item, MRindex) in remarks" :key="'MR'+ MRindex">  
                                                        <td class="stopbreaking">{{item.createdById ==  "" ? "-------" : item.createdById}}</td>
                                                        <td class="stopbreaking">{{item.createdAt == "" ? "-------" : dateFormat(item.createdAt)}}</td>
                                                        <td class="stopbreaking">{{item.remarkType ==  "" ? "-------" : item.remarkType}}</td>
                                                        <td class="stopbreaking">{{item.remarkText ==  "" ? "-------" : item.remarkText}}</td>
                                                        
                                                        </tr>
                                                    <tr class="v-data-table__empty-wrapper" v-if="remarks.length ==0">
                                                        <td colspan="4">No Records for Remarks</td>
                                                    </tr>
                                                </tbody>
                                            </template>
                                        </v-simple-table> -->
                                    </v-flex>
                                    <!-- back and proceed  code-->
                                    <v-flex justify="center" class="mt-5">
                                        <v-row class="btnDiv">
                                            <v-col align="center">
                                                <v-btn color="#152F38" dark  class="buttonscolor" @click="childRepairerApprval('reject')" v-if="!viewFormOnly || masterApproval">
                                                    <!-- <v-icon class="mr-2 iconsize" >mdi-plus</v-icon>
                                                    <div class="vertical_line"></div>Add Remark -->
                                                    Submit
                                                </v-btn>
                                                <v-btn color="#E46A25" dark  class="buttonscolor ml-2" @click="clearRemark()" v-if="!viewFormOnly || masterApproval">
                                                    <!-- <v-icon class="mr-2 iconsize" >mdi-close-circle</v-icon>
                                                    <div class="vertical_line"></div> -->
                                                    Clear
                                                </v-btn>
                                                <!-- <v-btn color="#D1121B" dark class="buttonscolor ml-2"
                                                    @click="remarksdialog = false">
                                                    <v-icon class="mr-2 iconsize" >mdi-close-circle</v-icon>
                                                    <div class="vertical_line"></div>Close
                                                </v-btn> -->
                                            </v-col>
                                        </v-row>
                                    </v-flex>
                                </v-form>
                            </v-card-text>
                        </v-card-title>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="reject_Dialog" width="600">
                <v-card>
                    <v-card-title class="white--text" style="background-color: #23b1a9"
                    ><v-layout style="display:flex;justify-content:center;margin-left: 170px;" >Reject
                       </v-layout>
                       <v-layout style="display:flex;justify-content:end;">
                        <v-icon class="white--text " @click="reject_Dialog=!reject_Dialog;reason=''">mdi-close</v-icon>
                      </v-layout>
                      </v-card-title>
                      <v-form ref="rejectForm" @submit="false">
                        <v-layout class="pt-4 form-group-container" >
                           
                            <v-flex class="mr-2">
                                <label class="form-label">Reason <span><strong class="red--text">*</strong></span></label>
                                <v-text-field  class="form-control" solo dense :rules="reject_Dialog ? [(v)=>requiredField(v)]:[]" v-model="remarkText" placeholder="Enter..."></v-text-field>
                            </v-flex>
                        </v-layout>
                      </v-form>
                        <v-card-actions style="display:flex;justify-content:center">
                          <v-btn class="btn" color="red white--text mr-0" @click="reject_Dialog=!reject_Dialog;reason=''">
                            No
                        </v-btn>

                        <v-btn class="btn" color="teal white--text mr-0" @click="beforeRepairerApproval('reject')">
                            Yes, Reject
                        </v-btn>
                      </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="approve_Dialog" :width="confirmAccountFlag ? '80%' : '600'">
                <v-card>
                    <v-card-title class="white--text" style="background-color: #23b1a9"
                    ><v-layout style="display:flex;justify-content:center;" >Approve
                       </v-layout>
                       <!-- <v-layout style="display:flex;justify-content:end;">
                        <v-icon class="white--text " @click="approve_Dialog=!approve_Dialog;reason=''">mdi-close</v-icon>
                      </v-layout> -->
                      </v-card-title>
                      <v-card-text v-if="confirmAccountFlag" class="pb-0">
                        <v-layout justify-center class="pb-2" v-if="cancelledChequeFileData.length > 0">
                            <object
                                :data="cancelledChequeFileData[0].fileBase? cancelledChequeFileData[0].fileBase: showCheque"
                                width="100%" height="300"></object>
                            <!-- <img height="300" :src="investigatorMasterObj.documents.fileBase" /> -->
                        </v-layout>
                        <v-form ref="AccountNumberForm" style="max-width: 450px; margin:auto">
                          <v-flex>
                              <label class="form-label">Bank Account Number<span class="mandatorycolor"> *</span></label>
                              <v-text-field v-model="validateAccNo" class="form-control" solo dense :rules="[(v) => requiredField(v), (v) => shouldBeSame(bankAccountNumber, v, 'Mismatch Bank Account Number')]" label="Enter ..."></v-text-field>
                          </v-flex>
                        </v-form>
                      </v-card-text>
                      <v-card-text v-else>
                        <v-layout class="pt-4 form-group-container" justify-center>
                            <h4>Are you sure want to Approve ?</h4>
                        </v-layout>
                      </v-card-text>
                        <v-card-actions style="display:flex;justify-content:center">
                          <v-btn class="btn" color="red white--text mr-0" @click="approve_Dialog=!approve_Dialog;reason=''">
                            No
                        </v-btn>

                        <v-btn class="btn" color="teal white--text mr-0" @click="beforeRepairerApproval('approve')">
                            Yes, Approve
                        </v-btn>
                      </v-card-actions>
                </v-card>
            </v-dialog>
            </v-row>
            <!-- Phase-2 changes ends -->
        </v-form>
    <!-- Audit Trail -->
    <audit-trail
      :dialogState="OpenAuditDialog"
      :auditTrailMaster="'repairer'"
      :auditTrailItem="{ uid: uid }"
      @close-dialog="OpenAuditDialog = false"
    />
    </v-container>
</template>


<script>
import moment from 'moment';
import auditTrail from '../../../component/MasterAuditTrail.vue'
    export default {
  components:{auditTrail},
        data() {
            return {
                uid:'',
                panels:[0,1,2,3,4,5],
                serviceAgreementMenu: false,
                bankDetailAgreement: false,
                otherDetailAgreementMenu: false,
                productDetailAgreementMenu1: false,
                productDetailAgreementMenu: false,
                        childRepairerDetails: false,
                        parentRepairerDetails: false,

                agreementEffectiveDate:false,
                agreementExpirationDate:false,
                auditDateMenu: false,
                auditDueDateMenu: false,

                hideaddressMessage: false,
                userName: '',
                parentRepairerID: '',
                childRepairerID: '',
                childRepairerName: '',
                childRepairerStatus: 'Active',
                repairerIndicator: '',
                overallSatisfaction: '',
                clientSpecificRepair: '',
                effectiveDate: '',
                expirationDate:'',
                auditDate: '',
                auditDueDate: '',
                pickupOption: false,
                tripFee: false,
                tripFeeInr: '',
                labourRateInr: '',
                jobRate1: 0,
                rate2: 0,
                rate3: 0,
                productType: [],
                authorizedBrands: [],
                repairerWarrantyPeriod: 0,
                salutation: 'Mr',
                firstName: '',
                middleInitial: '',
                lastName : '',
                suffix: '',
                workPhoneNumber : '',
                emailID : '',
                cellPhoneNumber: '',
                communicationEmailID: '',
                secondEmailID: '',
                isPanNotAvailable: false,
                panNumber: '',
                gstNumber: '',
                gstFrequency: '',
                tdsType: '',
                differentialTds: false,
                msme: '',
                enterpriseClassification: '',
                enterpriseCategory: '',
                uan: '',
                bankTransfer: true,
                bankName: '',
                ifscCode: '',
                bankAccountNumber: '',
                confirmBankAccountNumber: '',
                accountType: '',
                bankAddress1: '',
                bankAddress2: '',
                bankAddress3: '',
                branchName: '',
                cancelledCheque: [],
                addressLine1: '',
                addressLine2: '',
                addressLine3: '',
                country: '',
                state: '',
                district: '',
                city: '',
                postalCode: '',
                phoneNumber: '',
                preferredCommunicationMethod: '',
                faxNumber: '',
                secondFaxNumber: '',

                searchIFSCCODE: "",
                bankDetails:[],

                addressRequiredStatus: false,
                cancelledChequeRequiredStatus: false,

                userObj: {},

                pickupOptionrArray: [{name: "Yes",selected: true},{name: "No",selected: false}],
                enterpriseClassificationData: ['Proprietorship', 'Association of Persons', 'Co-operative Society', 'Partnership', 'Firm', 'Company', 'Individual'],
                enterpriseCategoryData: ['Micro', 'Small', 'Medium'],
                acountTypeOptions: ['Saving Account', 'Current Account'],
                preferredComAddresses: ['Cell phone', 'Email', 'Fax', 'Office phone', 'SMS'],
                childRepairerStatusOptions: ['Active', 'Inactive'],
                overAllSatisfactionOptions: ['0 - New', '1 - Bad', '2 - Satisfactory', '3 - Fair', '4 - Good', '5 - Excellent'],
                authorizedBrandOptions: ["ACER", "ALCATEL", "ALTEC", "ANJALI", "AO SMITH", "APPLE", "APPLE I-PH", "APPLE IPOD", "AQUA SURE", "ASUS", "BAJAJ", "BEE", "BINATONE", "BIONAIRE", "BLACK AND DECKER", "BLACKBERRY", "BLAPUNKT", "BLUESTAR", "BOSCH", "BOSE", "BOYNQ", "BRAUN", "BRIDGE", "BROTHER", "CANON", "CARRIER", "CARRIER MIDEA", "CASELOGIC", "Cello", "CG", "COMPAQ", "CREATIVE", "CROMA", "CROMPTON", "CRUISE", "DAIKIN", "DELL", "DSE", "ELECTROLUX", "EPSON", "EUREKA FORBES", "EUREKAFORB", "FABER", "FLY", "FUJI", "GE", "GLEN", "GODREJ", "HAIER", "HAMILTON BEACH", "HAVELLS", "HCL", "Hindware", "HITACHI", "HP", "HTC", "HUL PUREIT", "IAUDIO", "IBALL", "IFB", "INASLA", "INTEX", "JABRA", "JAMO", "JBL", "KAFF", "KAILASH", "KELVINATOR", "Kenstar", "KENT", "KENWOOD", "KINDLE", "KITCHEN AID", "KLIPSCH", "KODAK", "LABTEC", "LENOVO", "LG", "LIV PURE", "LOGIC3", "LOGITECH", "Maharaja", "MAHARAJA WHITELINE", "MI", "MI or XIAOMI", "MICROSOFT", "MITASHI", "MORPHY RICHARDS", "MORPHYRICH", "MOSERBAER", "MOTOROLA", "NATRAJ", "NIKON", "NOKIA", "OGENERAL", "Olympus", "ONE PLUS", "ONIDA", "ONKYO", "OPPO", "OPTIMA", "Orient", "OSIM", "PADMINI", "PANASONIC", "PHILIPS", "PIGEON", "PILOT", "POLK AUDIO", "POLORID", "PREETHI", "PRESTIGE", "RACOLD", "REALME", "REDMI", "REMINGTON", "RUSSELL HOBBS", "SAMSUNG", "SANDISK", "SANSUI", "SCRATCHGAR", "SEIMENS", "SHARP", "SIEMENS", "SOLO", "SONY", "SONY ERICS", "SUNFLAME", "SYMPHONY", "TATA", "TATA SKY", "TCL", "TOSHIBA", "ULTRA", "UNIDEN", "VIDEOCON", "VIEWSONIC", "VIRGIN", "VIVO", "VOLTAS", "VOXTEL", "VU", "WHIRLPOOL", "XIAOMI", "YAMAHA"],
                productTypeOptions: ["Airconditioner", "Computer Accessories", "CRT TV/LCD TV/PLASMA", "Dishwasher", "DVD/VCD/Audio System", "Gas Stove/Burners", "KITCHEN APPLIANCES", "Laptops/Desktops", "Microwaveoven/OTG", "Phones - Fixed or Mobile", "Printer Fax Machine", "Projection Television", "Refrigerator/Chiller", "Washing Machine/Dryers"],
                labourRateOptions: ['Fixed','Rate Per hour'],
                salutationOptions: ['Dr', 'Mr', 'Mrs', 'Ms'],
                gstFrequencyOptions: ['Monthly', 'Quarterly', 'Monthly less than 100 Crore', 'Monthly more  than 100 Crore but less than 500 Crore', 'Monthly More than 500 Crore'],
                tdsTypeOptions: ["Contractors","Non Residents","Professional Services - CORP","Professional Services - NON CORP","Association of persons (AOP)", "Body of individuals (BOI)", "Company",
                                "Firm", "Government", "HUF (Hindu undivided family)", "Local authority", 
                                "Artificial juridical person", "Individual or Person","Trust (AOP)","TDS not Applicable"],
                msmeOptions: ['Yes', 'No'],
                suffixOptions: [],
                cancelledChequeFileData: [],

                addressDialog: false,
                cancelChequeDialog: false,
                attachmentsdialog: false,
                viewFormOnly: false,
                lob:[],
                lobOptions:[],
                disableBankName: false,
                disableBankAdd1: false,
                disableBankAdd2: false,
                disableBankAdd3: false,
                disableBankBranch: false,

                        pageNumber: 1,
                pageStartIndex: 0,
                pageRowCount: 10,
                totalListCount: 0,
                totalPages:null,
                parentRepairerHeaders: [
                    {
                        text: 'Parent Repairer ID',
                        align: 'start',
                        sortable: false,
                        value: 'repairerId',
                    },
                    { text: 'Repairer Name', value: 'repairerName', sortable: false },
                    { text: 'Phone Number', value: 'phoneNumber', sortable: false },
                    { text: 'City', value: 'city', sortable: false },
                    { text: 'District', value: 'district', sortable: false },
                    { text: 'State/Province', value: 'state', sortable: false },
                    { text: 'Status', value: 'status', sortable: false },
                ],
                parentRepairerMastersData: [],

                childRepairerHeaders: [
                    {
                        text: 'Child Repairer ID',
                        align: 'start',
                        sortable: false,
                        value: 'repairerId',
                    },
                    { text: 'Repairer Name', value: 'repairerName', sortable: false },
                    { text: 'Phone Number', value: 'phoneNumber', sortable: false },
                    { text: 'City', value: 'city', sortable: false },
                    { text: 'District', value: 'district', sortable: false },
                    { text: 'State/Province', value: 'state', sortable: false },
                    { text: 'Status', value: 'status', sortable: false },
                ],
                attachments : [],
                browse_attachments:[],
                filesPushed:[],
                attachmentheaders:[
                    {value: 'icon', sortable:false},
                    {value: 'name', sortable:false},
                    {value: 'view', sortable:false},
                    {value: 'delete', sortable:false}
                ],
                childRepairerMastersData: [],
                childRepairerMastersPaginationData: [],

                searchCountry: '',
                countries: [],
                pitems: [],
                searchPincode: '',
                searchState:'',
                states: [],
                searchDistrict: '',
                districts:[],
                searchCity:'',
                cities:[],

                // RULES
                validationmessage:"This field is required",
                mandatoryrule() {
                    // console.log('-------',v)
                    return (v) => !!v || this.validationmessage
                },
                rules: {
                    repairerName: [
                    v => !!v || this.validationmessage,
                    // v => v && !!v.trim() || 'Only spaces are not allowed',
                    v => (v && v.length <= 200) || 'Repairer Name should be less than 200 characters.',
                    ],
                    mandatoryrule: [
                    v => !!v || this.validationmessage
                    ],
                    validatedropdown: [
                    v => v.length > 0 || this.validationmessage
                    ],
                    emailRules: [
                    (v) => !!v || this.validationmessage,
                    (v) =>
                        (v && v.length <= 50) || "Email should be less than 50 characters.",
                    (v) => /.+@.+\..+/.test(v) || "E-mail is not valid.",
                    ],
                    corrBankNameRules: [
                    (v) => !!v || this.validationmessage,
                    (v) =>
                        (v && v.length <= 100) ||
                        "Bank Name should be less than 100 characters.",
                    (v) => /[a-zA-Z0-9\s]+$/.test(v) || "Bank Name is not valid.",
                    ],
                    corrBankAddressRules: [
                    (v) => !!v || this.validationmessage,
                    (v) =>
                        (v && v.length <= 50) ||
                        "Address should be less than 50 characters.",
                    (v) => /[a-zA-Z0-9\s.-:;,/#]+$/.test(v) || "Address is not valid.",
                    ],
                    corrBankAccNumRule: [
                    (v) => !!v || this.validationmessage,
                    (v) =>
                        (v && v.length <= 50) ||
                        "Account Number should be less than 50 characters.",
                    (v) => /[a-zA-Z0-9]+$/.test(v) || "Account Number is not valid.",
                    ],
                },

                // Phase-2 declaration starts
                _id:'',
                manualOverwrite: false,
                auditTrailOpen: false,
                auditTrailData: [],
                detailedAuditTrailOpen: false,
                detailedAuditTrailData: [],
                pageNumber1: 1,
                pageStartIndex1: 0,
                pageRowCount1: 10,
                totalListCount1: 0,
                totalPages1:null,
                confirmBankAccountNo:'',
                ApprovalData:{},
                ApprovalID:'',
                confirmAccountFlag: false,
                masterApproval:false,
                remarksdialog:false,
                remarkType:"General",
                remarkText:"",
                remarks:[],
                pagno:'',
                remarksTypeArray:["General", "Account Number", "Address"],
                OpenAuditDialog: false,
            approve_Dialog:false,
            reject_Dialog:false,
            validateAccNo: "",
            showCheque: [],
                // Phase-2 declaration starts
            }
        },

        created() {
            this.$store.commit("SAVE_PAGE_HEADER", "Repairer Master");
            let routeValue = {};
            if (Object.keys(this.$route.params).length !== 0 || Object.keys(this.$store.getters.fetchChildData).length !== 0) {
                routeValue = Object.keys(this.$route.params).length !== 0 ? this.$route.params : this.$store.getters.fetchChildData;
                let id = routeValue.id;
                this.viewFormOnly = routeValue.view;
                if(id && id !== '') {
                    this.getSingleRepairer(id)
                }
                if(routeValue.approveStatus === "Pending for approval") {
                    this.showToast("Approval Pending, you can't modify.", this.TOST().WARNING);
                    this.viewFormOnly = true;
                }
                this.pageno = routeValue.pageno
                if(routeValue.masterapprovalData){
                    this.masterApproval =  routeValue.masterApproval
                    this.ApprovalID = routeValue.masterapprovalData._id
                    this.confirmAccountFlag = routeValue.masterapprovalData.confirmAccountFlag
                    this.ApprovalData = routeValue.masterapprovalData.operationData[0]
                    if(this.ApprovalData.overwriteManually){
                        this.showAlert('OCR has been over written')
                    }
                    this.bindChildApprovalData()
                }
                this.$store.commit("RESET_CHILD_DATA", routeValue);
            } else {
                this.viewFormOnly = false;
            }
            this.getLOB()
        },

        computed: {
            // enableApprovebtn(){
            //     if(this.confirmAccountFlag) {
            //         return this.confirmBankAccountNo === this.bankAccountNumber ? false : true
            //     } else {
            //         return false;
            //     }
            // },

            computedGST() {
                this.gstNumber = this.gstNumber.toUpperCase();
            },

            computedPAN() {
                this.panNumber = this.panNumber.toUpperCase();
            },

            checkParentStatus() {
                return this.parentRepairerMastersData[0] && !this.parentRepairerMastersData[0].repairerStatus ? true : false
            },

            pincodes(){
                return this.pitems
            },

            pageEndIndex() {
                return this.pageStartIndex + this.pageRowCount <= this.totalListCount? this.pageStartIndex + this.pageRowCount: this.totalListCount;
            },

            pageEndIndex1() {
                return this.pageStartIndex1 + this.pageRowCount1 <= this.totalListCount1
                ? this.pageStartIndex1 + this.pageRowCount1
                : this.totalListCount1;
            },

            bankIFSCDetailsItems() {
                return this.bankDetails[0] && this.bankDetails[0].length > 0 ? this.bankDetails[0] : this.bankDetails
            },

            computedEffectiveDate(){
                return moment(this.effectiveDate).format('DD/MM/YYYY');
            },

            computedExpirationDate(){
                return this.expirationDate == null ? '' : moment(this.expirationDate).format('DD/MM/YYYY');
            },

            computedAuditDate(){
                return this.auditDate == null ? '' : moment(this.auditDate).format('DD/MM/YYYY');
            },

            computedAuditDueDate(){
                return this.auditDueDate == null ? '' : moment(this.auditDueDate).format('DD/MM/YYYY');
            },

            cancelledChequeRequired() {
                if(this.cancelledChequeRequiredStatus) {
                    this.cancelledChequeRequiredStatus = this.bankTransfer && this.cancelledChequeFileData.length < 1 ? false : true
                    return 'This field is required'
                } else {
                    return ''
                }
            },

            addressMsgRequired() {
                if(this.addressRequiredStatus) {
                    this.addressRequiredStatus = this.addressLine1 === '' || this.postalCode === '' || this.country === '' || this.state === '' || this.city === '' ? false : true
                    return 'This field is required'
                } else {
                    this.addressRequiredStatus = false
                    return ''
                }
            },

            selectAllBrands () {
                return this.authorizedBrands.length === this.authorizedBrandOptions.length
            },

            selectSomeBrands () {
                return this.authorizedBrands.length > 0 && !this.selectAllBrands
            },

            icon () {
                if (this.selectAllBrands) return 'mdi-close-box'
                if (this.selectSomeBrands) return 'mdi-minus-box'
                return 'mdi-checkbox-blank-outline'
            }
        },

        methods: {
        checkIsPANAvailable() {
            this.panNumber = '';
            this.tdsType = this.isPanNotAvailable ? "TDS not Applicable" : "";
            if(this.isPanNotAvailable) {
                this.showAlert("F & A approval won't be triggered & the base rate as per PAN Number Not Available will be applied.");
            }
        },
        getLOB() {
        let reqData = '';
        this.showProgress();
        this.GETM("getLOB", reqData, (res) => {
            this.hideProgress();
            if(res.data.statusCode === 200) {
                this.lobOptions = res.data.data[0].lineOfBusiness;
            } else {
                this.lobOptions = [];
                this.showAlert(res.data.msg);
            }
        }, (error) => {
            console.log(error);
            this.hideProgress();
        }, true)
    },
            // Phase-2 functionality starts
            isNumber(evt) {
                evt = (evt) ? evt : window.event;
                var charCode = (evt.which) ? evt.which : evt.keyCode;
                if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                    evt.preventDefault();
                }
                return true;
            },
            
            addressLineValidation(v){
                let addressVal = /^(?![|""-,;:~\s])[a-zA-Z0-9^<>{}`.()\/\\'_!@#$%?&*=+[\]\s]*$/;  
                if (v && !addressVal.test(v)){
                    return "Special characters are not allowed";
                }else if (v && v.length > 45){
                    return "Max length 45 is allowed";
                }else{
                    return true
                }
            },

            addressValidation(v){
                let addressVal = /^(?![|""-,;:~\s])[a-zA-Z0-9^<>{}`.()\/\\'_!@#$%?&*=+[\]\s]*$/;  
                if (v && !addressVal.test(v)){
                    return "Special characters are not allowed";
                }else if (v && v.length > 35){
                    return "Max length 35 is allowed";
                }else{
                    return true
                }
            },
            beforeRepairerApproval(value){
                if(!this.$refs?.AccountNumberForm?.validate() && this.confirmAccountFlag && !this.remarksdialog){
                    return this.showAlert("Please fill bank account number.");
                }
                    this.childRepairerApprval('approve')
            //     this.showConfirm("Confirmation",`Are you sure you want to ${value}?`,"Yes","No",(Yes)=>{
            //         this.childRepairerApprval('approve')
            // })
        },

            childRepairerApprval(value){
                let request = {}
                // request.remarkType = this.remarkType,
                request.rejectionReason = this.remarkText
                let reject = false // to check remarks for rejection
                if(value == 'approve'){
                    request.approveStatus = 'Approved'
                }else{
                    request.approveStatus = 'Rejected'
                    this.remarksdialog=false
                    // if(!request.remarkType || !request.remarkText){
                    //     reject = true
                    //     this.remarksdialog = true;
                    //     this.getRemarks();
                    // }
                }
                if(!reject){
                    this.PUT_ByURLM("updateMasterApprove", request, (res) => {
                        if (res.data.statusCode === 200) {
                            this.hideProgress();
                            this.showAlert(res.data.msg);
                            this.$router.push("/repairerapprovalsearch");

                        } else {
                            this.hideProgress();
                            this.showAlert(res.data.msg);
                        }
                    },(error) => {
                        this.hideProgress();
                        if (error.response.data.msg) {
                            this.showAlert(error.response.data.msg);
                        } else if (error.response.data.message.msg) {
                            this.showAlert(error.response.data.message.msg);
                        } else if (error.response.data.message) {
                            this.showAlert(error.response.data.message);
                        } else {
                            this.showAlert("Something went wrong");
                        }
                    },this.ApprovalID,true);
                }
            },

            bindChildApprovalData(){
                this.bankDetailAgreement = true
                this.lob=this.ApprovalData.lob
                this.userObj = this.ApprovalData && this.ApprovalData.user
                this.userName = this.ApprovalData.user && this.ApprovalData.user.employeeName + ' - ' + this.ApprovalData.user.ntid
                this.parentRepairerID = this.ApprovalData.parentRepairerId
                this.childRepairerID = this.ApprovalData.repairerId;
                this.childRepairerName = this.ApprovalData.repairerName
                this.childRepairerStatus = this.ApprovalData.repairerStatus ? 'Active' : 'Inactive'
                this.repairerIndicator = this.ApprovalData.superRepairerIndicator
                this.overallSatisfaction = this.ApprovalData.overllSatisfactionRating
                this.effectiveDate = this.ApprovalData.agreementEffectiveDate
                this.expirationDate = this.ApprovalData.agreementExpirationDate
                this.auditDate = this.ApprovalData.lastAuditDate
                this.auditDueDate = this.ApprovalData.nextAuditDate
                this.pickupOption = this.ApprovalData.pickUp
                this.tripFee = this.ApprovalData.isTripFee
                this.tripFeeInr = this.ApprovalData.tripFee
                this.labourRateInr = this.ApprovalData.laborRate
                this.jobRate1 = this.ApprovalData.jobRate1
                this.rate2 = this.ApprovalData.jobRate2
                this.rate3 = this.ApprovalData.jobRate3
                this.productType = this.ApprovalData.productType
                this.authorizedBrands = this.ApprovalData.authorizedBrand
                this.repairerWarrantyPeriod = this.ApprovalData.repairerWarrantyPeriod
                this.salutation = this.ApprovalData.salutation ? this.ApprovalData.salutation : 'Mr'
                this.firstName = this.ApprovalData. firstName
                this.middleInitial = this.ApprovalData.middleName
                this.lastName  = this.ApprovalData.lastName
                this.workPhoneNumber  = this.ApprovalData.workPhoneNumber
                this.emailID  = this.ApprovalData.emailId
                this.cellPhoneNumber = this.ApprovalData.cellPhoneNumber
                this.communicationEmailID = this.ApprovalData.commEmailId
                this.secondEmailID = this.ApprovalData.secondEmailId
                this.isPanNotAvailable = this.ApprovalData.isPanNotAvailable
                this.panNumber = this.ApprovalData.panNumber
                this.gstNumber = this.ApprovalData.gstNumber
                this.gstFrequency = this.ApprovalData.gstFrequency
                this.tdsType = this.ApprovalData.tdsType
                this.differentialTds = this.ApprovalData.differentialTds
                this.msme = this.ApprovalData.msme ? 'Yes' : 'No'
                this.enterpriseClassification = this.ApprovalData.enterpriceClassification
                this.enterpriseCategory = this.ApprovalData.enterpriceCategory
                this.uan = this.ApprovalData.uan
                this.bankTransfer = this.ApprovalData.bankTransfer
                this.bankName = this.ApprovalData.bankName
                this.ifscCode = this.ApprovalData.ifscCode
                this.bankAccountNumber = this.ApprovalData.bankAccountNumber
                this.confirmBankAccountNumber = this.ApprovalData.bankAccountNumber
                this.accountType = this.ApprovalData.bankAccountType
                this.bankAddress1 = this.ApprovalData.bankAddress1
                this.bankAddress2 = this.ApprovalData.bankAddress2
                this.bankAddress3 = this.ApprovalData.bankAddress3
                this.branchName = this.ApprovalData.branchName
                this.manualOverwrite = this.ApprovalData.overwriteManually
                if(this.ApprovalData.bankName) {
                    this.disableBankName = true
                } else {
                    this.disableBankName = false
                }
                if(this.ApprovalData.bankAddress1) {
                    this.disableBankAdd1 = true
                } else {
                    this.disableBankAdd1 = false
                }
                if(this.ApprovalData.bankAddress2) {
                    this.disableBankAdd2 = true
                } else {
                    this.disableBankAdd2 = false
                }
                if(this.ApprovalData.branchName) {
                    this.disableBankBranch = true
                } else {
                    this.disableBankBranch = false
                }
                this.cancelledChequeFileData = this.ApprovalData.cancelledCheque.filter((data) => data.fileUrl !== "");
                if(this.cancelledChequeFileData) {
                    for(let i = 0; i < this.cancelledChequeFileData.length; i++) {
                    this.cancelledChequeFileData[i]['status'] = true
                    }
                }
                if(this.cancelledChequeFileData.length>=1){
                    this.viewDocumentCheque(this.cancelledChequeFileData[0].documentId)
                }
                this.attachments = this.ApprovalData.attachments.filter((data) => data.fileUrl !== "");
                if(this.attachments) {
                    for(let i = 0; i < this.attachments.length; i++) {
                    this.attachments[i]['status'] = true
                    }
                }
                this.addressLine1 = this.ApprovalData.addressLine1;
                this.addressLine2 = this.ApprovalData.addressLine2;
                this.addressLine3 = this.ApprovalData.addressLine3;
                this.country = this.ApprovalData.country
                this.state = this.ApprovalData.state
                this.district = this.ApprovalData.district
                this.city = this.ApprovalData.city
                this.postalCode = this.ApprovalData.postalCode
                this.phoneNumber = this.ApprovalData.phoneNumber
                this.preferredCommunicationMethod = this.ApprovalData.preCommMethod
                this.faxNumber = this.ApprovalData.faxNumber
                this.secondFaxNumber = this.ApprovalData.secondFaxNumber
                if(this.ApprovalData.isChildRepairer) {
                    this.GET_ByURLM( "GET_SINGLE_REPAIRER_DETAILS", this.ApprovalData.parentRepairerId, (res) => {
                        if(res.data.statusCode === 200 && res.data.data) {
                            this.parentRepairerMastersData.push(res.data.data);
                        } else {
                            this.showAlert(res.data.msg);
                        }

                    },(error) => {
                        if (error.response.data.msg) {
                        this.showAlert(error.response.data.msg);
                        } else if (error.response.data.message.msg) {
                            this.showAlert(error.response.data.message.msg);
                        } else if (error.response.data.message) {
                            this.showAlert(error.response.data.message);
                        } else {
                            this.showAlert("Something went wrong");
                        }
                    })
                }
                this.goToNextPage(1, this.ApprovalData.parentRepairerId, this.ApprovalData.repairerId);
                if(this.ApprovalData.postalCode) {
                    let pincode = this.ApprovalData.postalCode.split("-")[0].trim();
                    this.callPincodeAPI(pincode);
                    this.toGetCountryCity(pincode);
                    this.getClaimantStates();
                    this.getClaimantCities();
                }
                if(this.ApprovalData.ifscCode) {
                    this.searchBanks(this.ApprovalData.ifscCode);
                    this.getBankFields(this.ApprovalData.ifscCode);
                }
                if(!this.isPanNotAvailable) {
                    this.checkTDSType();
                }
            },

            checkConfirmBankNo(v) {
                const accountRegex = /[a-zA-Z0-9]+$/;
                if(v && !accountRegex.test(v)) {
                    return "Account Number is not valid.";
                }
                if((v && v.length > 50)) {
                    return "Account Number should be less than 50 characters.";
                } 
                if(v && v !== this.bankAccountNumber) {
                    return 'Account Number does not match.';
                }
                return true;
            },

            getSingleRepairer(id){
                this.showProgress("Please wait!");
                this.GET_ByURLM("GET_SINGLE_REPAIRER_DETAILS", id,(res) => {
                    if(res.data.statusCode === 200 && res.data.data) {
                    this.hideProgress();
                    let repairerData = res.data.data;
                    this.uid = repairerData.uid

                    this.lob=repairerData.lob
                    this.userObj = repairerData && repairerData.user
                    this._id = repairerData._id;
                    this.userName = repairerData.user && repairerData.user.employeeName + ' - ' + repairerData.user.ntid;
                    this.parentRepairerID = repairerData.parentRepairerId
                    this.childRepairerID = repairerData.repairerId;
                    this.childRepairerName = repairerData.repairerName
                    this.childRepairerStatus = repairerData.repairerStatus ? 'Active' : 'Inactive'
                    this.repairerIndicator = repairerData.superRepairerIndicator
                    this.overallSatisfaction = repairerData.overllSatisfactionRating
                    this.effectiveDate = repairerData.agreementEffectiveDate
                    this.expirationDate = repairerData.agreementExpirationDate
                    this.auditDate = repairerData.lastAuditDate
                    this.auditDueDate = repairerData.nextAuditDate
                    this.pickupOption = repairerData.pickUp
                    this.tripFee = repairerData.isTripFee
                    this.tripFeeInr = repairerData.tripFee
                    this.labourRateInr = repairerData.laborRate
                    this.jobRate1 = repairerData.jobRate1
                    this.rate2 = repairerData.jobRate2
                    this.rate3 = repairerData.jobRate3
                    this.productType = repairerData.productType
                    this.authorizedBrands = repairerData.authorizedBrand
                    this.repairerWarrantyPeriod = repairerData.repairerWarrantyPeriod
                    this.salutation = repairerData.salutation ? repairerData.salutation : 'Mr'
                    this.firstName = repairerData. firstName
                    this.middleInitial = repairerData.middleName
                    this.lastName  = repairerData.lastName
                    this.workPhoneNumber  = repairerData.workPhoneNumber
                    this.emailID  = repairerData.emailId
                    this.cellPhoneNumber = repairerData.cellPhoneNumber
                    this.communicationEmailID = repairerData.commEmailId
                    this.secondEmailID = repairerData.secondEmailId
                    this.isPanNotAvailable = repairerData.isPanNotAvailable
                    this.panNumber = repairerData.panNumber
                    this.gstNumber = repairerData.gstNumber
                    this.gstFrequency = repairerData.gstFrequency
                    this.tdsType = repairerData.tdsType
                    this.differentialTds = repairerData.differentialTds
                    this.msme = repairerData.msme ? 'Yes' : 'No'
                    this.enterpriseClassification = repairerData.enterpriceClassification
                    this.enterpriseCategory = repairerData.enterpriceCategory
                    this.uan = repairerData.uan
                    this.bankTransfer = repairerData.bankTransfer
                    this.bankName = repairerData.bankName
                    this.ifscCode = repairerData.ifscCode
                    this.bankAccountNumber = repairerData.bankAccountNumber
                    this.confirmBankAccountNumber = repairerData.bankAccountNumber
                    this.accountType = repairerData.bankAccountType
                    this.bankAddress1 = repairerData.bankAddress1
                    this.bankAddress2 = repairerData.bankAddress2
                    this.bankAddress3 = repairerData.bankAddress3
                    this.branchName = repairerData.branchName
                    this.manualOverwrite = repairerData.overwriteManually
                    if(repairerData.bankName) {
                        this.disableBankName = true
                    } else {
                        this.disableBankName = false
                    }
                    if(repairerData.bankAddress1) {
                        this.disableBankAdd1 = true
                    } else {
                        this.disableBankAdd1 = false
                    }
                    if(repairerData.bankAddress2) {
                        this.disableBankAdd2 = true
                    } else {
                        this.disableBankAdd2 = false
                    }
                    if(repairerData.branchName) {
                        this.disableBankBranch = true
                    } else {
                        this.disableBankBranch = false
                    }
                    this.cancelledChequeFileData = repairerData.cancelledCheque.filter((data) => data.fileUrl !== "");
                    if(this.cancelledChequeFileData) {
                        for(let i = 0; i < this.cancelledChequeFileData.length; i++) {
                        this.cancelledChequeFileData[i]['status'] = true
                        }
                    }
                    this.attachments = repairerData.attachments.filter((data) => data.fileUrl !== "");
                    if(this.attachments) {
                        for(let i = 0; i < this.attachments.length; i++) {
                        this.attachments[i]['status'] = true
                        }
                    }
                    this.addressLine1 = repairerData.addressLine1;
                    this.addressLine2 = repairerData.addressLine2;
                    this.addressLine3 = repairerData.addressLine3;
                    this.country = repairerData.country
                    this.state = repairerData.state
                    this.district = repairerData.district
                    this.city = repairerData.city
                    this.postalCode = repairerData.postalCode
                    this.phoneNumber = repairerData.phoneNumber
                    this.preferredCommunicationMethod = repairerData.preCommMethod
                    this.faxNumber = repairerData.faxNumber
                    this.secondFaxNumber = repairerData.secondFaxNumber
                    if(!this.isPanNotAvailable) {
                        this.checkTDSType();
                    }
                    if(repairerData.isChildRepairer) {
                        this.GET_ByURLM( "GET_SINGLE_REPAIRER_DETAILS", repairerData.parentRepairerId,(res) => {
                            if(res.data.statusCode === 200 && res.data.data) {
                                this.parentRepairerMastersData.push(res.data.data);
                            } else {
                            this.showAlert(res.data.msg);
                            }

                        },(error) => {
                            if (error.response.data.msg) {
                            this.showAlert(error.response.data.msg);
                            } else if (error.response.data.message.msg) {
                                this.showAlert(error.response.data.message.msg);
                            } else if (error.response.data.message) {
                                this.showAlert(error.response.data.message);
                            } else {
                                this.showAlert("Something went wrong");
                            }
                        })
                    }
                        this.goToNextPage(1, repairerData.parentRepairerId, repairerData.repairerId);
                        if(repairerData.postalCode) {
                            let pincode = repairerData.postalCode.split("-")[0].trim();
                            this.callPincodeAPI(pincode);
                            this.toGetCountryCity(pincode);
                            this.getClaimantStates();
                            this.getClaimantCities();
                            this.getCountries()
                        }
                        if(repairerData.ifscCode) {
                            this.searchBanks(repairerData.ifscCode);
                            this.getBankFields(repairerData.ifscCode);
                        }
                    } else {
                        this.hideProgress();
                        this.showAlert(res.data.msg);
                    }
                },(error) => {
                    this.hideProgress();
                    if (error.response.data.msg) {
                    this.showAlert(error.response.data.msg);
                    } else if (error.response.data.message.msg) {
                        this.showAlert(error.response.data.message.msg);
                    } else if (error.response.data.message) {
                        this.showAlert(error.response.data.message);
                    } else {
                        this.showAlert("Something went wrong");
                    }
                })
            },

            getBankDetailsByIFSC(value) {
                if (value) {
                    if (value.length > 2) {
                        let request = value;
                        this.GET_ByURLM(
                            "GET_GC_BANK",
                            request,
                            (res) => {
                                this.bankDetails = [];
                                if (res.data.statusCode === 200 && res.data.data) {
                                    this.bankDetails.push(res.data.data);
                                    this.getBankFields(value);
                                } else {
                                this.showAlert(res.data.msg);
                                this.bankName="";
                                this.bankAddress1="";
                                this.branchName="";
                                this.bankAddress2="";
                                this.bankAddress3 ="";
                                this.bankAccountNumber = "";
                                this.confirmBankAccountNumber = "";
                                }
                            },
                            (error) => {
                                this.bankName="";
                                this.bankAddress1="";
                                this.branchName="";
                                this.bankAddress2="";
                                this.bankAddress3 ="";
                                this.bankAccountNumber = "";
                                this.confirmBankAccountNumber = "";
                                if (error.response.data.msg) {
                                    this.showAlert(error.response.data.msg);
                                } else if (error.response.data.message.msg) {
                                    this.showAlert(error.response.data.message.msg);
                                } else if (error.response.data.message) {
                                    this.showAlert(error.response.data.message);
                                } else {
                                    this.showAlert("Something went wrong");
                                }
                            }, true
                        );
                    }
                }
            },
            
            getCancelledChequeData(base) {
                const reqData = {
                    document: base
                };
                window.scrollTo(0, window.innerHeight / 2);
                this.showProgress();
                this.POSTM("GET_OCR_FORMAT", reqData, (res) => {
                    if(res.data.statusCode === 200) {
                        this.hideProgress();
                        if(res.data.data.StatusCode === "TaTa200") {
                            // console.log("response data", res.data.data.IFSC);
                            if(res.data.data.IFSC) {
                                this.showAlert('OCR uploaded successfullyy', "OK", "", () => {});
                                this.ifscCode = res.data.data.IFSC;
                                this.getBankDetailsByIFSC(res.data.data.IFSC);
                                this.bankAccountNumber = res.data.data.AccountNumber;
                                this.confirmBankAccountNumber = res.data.data.AccountNumber;
                            } else {
                                // console.log("Do something else here----------------");
                                this.populateOcrData(reqData);
                            }
                            
                        } else {
                            this.ifscCode = ""; 
                            this.bankName="";
                            this.bankAddress1="";
                            this.branchName="";
                            this.bankAddress2="";
                            this.bankAddress3 ="";
                            this.bankAccountNumber = "";
                            this.confirmBankAccountNumber = "";
                            this.cancelledChequeFileData = [];
                            this.showAlert(res.data.data.Message, "OK", "", () => {});
                        }
                    } else {
                        this.ifscCode = ""; 
                        this.bankName="";
                        this.bankAddress1="";
                        this.branchName="";
                        this.bankAddress2="";
                        this.bankAddress3 ="";
                        this.bankAccountNumber = "";
                        this.confirmBankAccountNumber = "";
                        this.hideProgress();
                        this.cancelledChequeFileData = [];
                        this.showAlert(res.data.msg);
                    }
                }, (error) => {
                        this.hideProgress();
                        if (error.response.data.msg) {
                            this.showAlert(error.response.data.msg);
                        } else if (error.response.data.message.msg) {
                            this.showAlert(error.response.data.message.msg);
                        } else if (error.response.data.message) {
                            this.showAlert(error.response.data.message);
                        } else {
                            this.showAlert("Something went wrong");
                        }
                }, true);
            },

            populateOcrData(reqData) {
                window.scrollTo(0, window.innerHeight / 2);
                this.showProgress("Trying to retrieve data from OCR");
                this.POSTM("GET_OCR_FORMAT", reqData, (res) => {
                    if(res.data.statusCode === 200) {
                        this.hideProgress();
                        if(res.data.data.StatusCode === "TaTa200") {
                            // console.log("response data", res.data.data.IFSC);
                            if(res.data.data.IFSC) {
                            this.showAlert('Uploaded successfully', "OK", "", () => {});
                            this.ifscCode = res.data.data.IFSC;
                            this.getBankDetailsByIFSC(res.data.data.IFSC);
                            this.bankAccountNumber = res.data.data.AccountNumber;
                            this.confirmBankAccountNumber = res.data.data.AccountNumber;
                            } else {
                                this.ifscCode = ""; 
                                this.bankName="";
                                this.bankAddress1="";
                                this.branchName="";
                                this.bankAddress2="";
                                this.bankAddress3 ="";
                                this.bankAccountNumber = "";
                                this.confirmBankAccountNumber = "";
                                this.showAlert('Could not retrieve data from OCR either reupload the cancelled cheque or enter the details manually', "OK", "", () => {});
                            }
                            
                        } else {
                            this.ifscCode = ""; 
                            this.bankName="";
                            this.bankAddress1="";
                            this.branchName="";
                            this.bankAddress2="";
                            this.bankAddress3 ="";
                            this.bankAccountNumber = "";
                            this.confirmBankAccountNumber = "";
                            this.cancelledChequeFileData = [];
                            this.showAlert(res.data.data.Message, "OK", "", () => {});
                        }
                    } else {
                        this.ifscCode = ""; 
                        this.bankName="";
                        this.bankAddress1="";
                        this.branchName="";
                        this.bankAddress2="";
                        this.bankAddress3 ="";
                        this.bankAccountNumber = "";
                        this.confirmBankAccountNumber = "";
                        this.hideProgress();
                        this.cancelledChequeFileData = [];
                        this.showAlert(res.data.msg);
                    }
                }, (error) => {
                        this.hideProgress();
                        if (error.response.data.msg) {
                            this.showAlert(error.response.data.msg);
                        } else if (error.response.data.message.msg) {
                            this.showAlert(error.response.data.message.msg);
                        } else if (error.response.data.message) {
                            this.showAlert(error.response.data.message);
                        } else {
                            this.showAlert("Something went wrong");
                        }
                }, true);
            },

            changeSubmissionType() {
                if(!this.manualOverwrite) {
                    this.deleteCancelledCheque();
                }
            },

            openAuditTrail() {
                this.OpenAuditDialog = true
                // this.auditTrailOpen = true;
                // this.loadNextPage(1);
            },

            loadNextPage(pageNumber) {
                this.pageNumber1= pageNumber ? pageNumber : 1;
                let requestData = "masterType=repairer&masterId="+ this.childRepairerID +"&perPage=" + this.pageRowCount1 + "&pageNo=" + pageNumber;
                this.showProgress("Data is searching, Please wait");
                this.GETM("getAudits",requestData,(res) => {
                    // console.log("result==============>>>>>>>>>>>", res.data);
                    if (res.data.statusCode === 200 && res.data.data && res.data.data.data) {
                        this.hideProgress(); 
                        this.auditTrailData = res.data.data.data;
                        this.pageRowCount1 = 10;
                        this.totalListCount1 = res.data.data.total;
                        this.totalPages1 = Math.ceil(res.data.data.total / this.pageRowCount1);
                        this.pageStartIndex1 = pageNumber == 1 ? 0 : (pageNumber - 1) * this.pageRowCount1;
                    }else{
                        this.hideProgress(); 
                        this.auditTrailData = [];
                        this.pageRowCount1 = 0;
                        this.totalListCount1 = 0;
                        this.totalPages1 = 0;
                        this.pageStartIndex1 = 0;
                        this.showAlert("No Data Available!");
                    }
                },(error) => {
                    this.hideProgress();
                    this.auditTrailData = [];
                    if (error.response.data.msg) {
                        this.showAlert(error.response.data.msg);
                    } else if (error.response.data.message.msg) {
                        this.showAlert(error.response.data.message.msg);
                    } else if (error.response.data.message) {
                        this.showAlert(error.response.data.message);
                    } else {
                        this.showAlert("Something went wrong");
                    }
                });
            },

            // fro detailed audit trail
            
            openDetailedAuditTrail(data) {
                this.detailedAuditTrailOpen = true;
                this.loadNextDetailedPage(data);
            },

            loadNextDetailedPage(data) {
                if (data.length > 0) {
                    this.detailedAuditTrailData = data;
                }else{
                    this.detailedAuditTrailData = [];
                }
            },

            openRemarks(){
                this.remarksdialog = true
                this.getRemarks()
            },

            addRemark(){
                if(this.$refs.remarkform.validate()){
                    this.showToast("Remarks added", this.TOST().SUCCESS)
                    this.remarksdialog = false
                }
            },

            getRemarks(){
                let req = 'masterId=' + this.childRepairerID + '&masterType=' + "repairer"
                this.GETM("masterRemarks", req, (res) => {
                    if(res.data.statusCode == 200 && res.data.data && res.data.data.length !== 0){
                        this.remarks  = res.data.data
                    }
                },(error) => {
                    this.hideProgress();
                    if(error.response.data.msg){
                        this.showAlert(error.response.data.msg);
                    }else if(error.response.data.message.msg){
                        this.showAlert(error.response.data.message.msg);
                    }else if(error.response.data.message){
                        this.showAlert(error.response.data.message);
                    }else{
                        this.showAlert("Something went wrong");
                    }
                })
            },

            clearRemark(){
                this.showConfirm("Confirmation","Are you sure you want to clear?","Yes","No",(Yes)=>{
                    this.remarkText = ""
                    this.remarkType = ""
                    this.showToast("Remarks cleared", this.TOST().SUCCESS)
                    this.$refs.remarkform.resetValidation()
                },(No)=>{})
            },

            // Phase-2 functionality ends

            isLetter(e) {
                let char = String.fromCharCode(e.keyCode);
                if(/^[A-Z a-z]+$/.test(char)) return true;
                else e.preventDefault();
            },

            panValidation(v) {
                let alphaNumericRegex = /[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}$/;
                if(!v) {
                    return 'This field is required'
                }
                if(v && !alphaNumericRegex.test(v)) {
                    return 'Please enter a valid PAN number'
                } else {
                    return true
                }
            },

            gstValidation(v) {
                let alphaNumericRegex = /[0-9]{2}[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}[A-Za-z0-9]{1}[A-Za-z]{1}[A-Za-z0-9]{1}$/;
                // if(!v) {
                //   return 'This field is required'
                // }
                if(v && !alphaNumericRegex.test(v)) {
                    return 'Please enter a valid GST number'
                } else {
                    return true
                }
            },

            backAccountNumberValidation(v) {
                const accountRegex = /[a-zA-Z0-9]+$/;
                if(!v) {
                    return this.validationmessage;
                }
                if(v && !accountRegex.test(v)) {
                    return "Account Number is not valid.";
                }

                if((v && v.length > 50)) {
                    return "Account Number should be less than 50 characters.";
                } 

                if(v && v !== this.bankAccountNumber) {
                    return 'Account Number does not match.';
                }
                return true;
            },

            emailValidation(v) {
                let emailRegex = /.+@.+\..+/
                if(v && !emailRegex.test(v)) {
                    return "E-mail is not valid."
                } else {
                    return true
                }
            },

            openAddressDialog () {
                this.addressDialog = true
                this.getCountries()
            },

            openAttachments(){
                this.attachmentsdialog = true;
            },

            openCancelledChequeDialog() {
                this.cancelChequeDialog = true
            },

            proceed() {
                this.hideaddressMessage = false;
                let regex = /^(?![|""-,;:~\s])[a-zA-Z0-9^<>{}`.()\/\\'_!@#$%?&*=+[\]\s]*$/
                if(this.$refs.addressref.validate()) {
                    this.addressDialog = false;
                } else if(!regex.test(this.addressLine1) || !regex.test(this.addressLine2) || !regex.test(this.addressLine3)){
                    this.showAlert("Special characters are not allowed");
                } else if(this.addressLine1.length > 45 || this.addressLine2.length > 45){
                    this.showAlert("Max length more than 45 not allowed");
                } else if(this.addressLine3.length > 35){
                    this.showAlert("Max length more than 35 not allowed");
                } else {
                    this.showAlert("Please enter all mandatory fields");
                }
            },

            limitTextLength(name) {
                return name.length > 15 ? name.substring(0, 16).concat('...') : name
            },

            goNextPagePagination(pageNumber) {
                this.goToNextPage(pageNumber, this.parentRepairerID, this.childRepairerID);
            },

            checkFileType(file) {
                if(file === 'image/png' ||file === 'image/jpeg') {
                    return 'image';
                }
                if(file === 'application/pdf') {
                    return 'pdf';
                }
                if(file === 'application/msword' ||file === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
                    return 'word';
                }
                if(file === 'application/vnd.ms-excel' ||file === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||file === 'text/csv') {
                    return 'excel';
                }
                return false;
            },

            toggle () {
                this.$nextTick(() => {
                    if (this.selectAllBrands) {
                    this.authorizedBrands = []
                    } else {
                    this.authorizedBrands = this.authorizedBrandOptions.slice()
                    }
                })
            },

            checkTDSType() {
                let indexItem = this.panNumber[3];
                switch (indexItem) {
                    case 'A':
                        this.tdsType = "Association of persons (AOP)";
                        break;
                    case 'B':
                        this.tdsType =  "Body of individuals (BOI)";
                        break;
                    case 'C':
                        this.tdsType = "Company";
                        break;
                    case 'F':
                        this.tdsType = "Firm";
                        break;
                    case 'G':
                        this.tdsType = "Government";
                        break;
                    case 'H':
                        this.tdsType = "HUF (Hindu undivided family)";
                        break;
                    case 'L':
                        this.tdsType = "Local authority";
                        break;
                    case 'J':
                        this.tdsType = "Artificial juridical person";
                        break;
                    case 'P':
                        this.tdsType = "Individual or Person";
                        break;
                    case 'T':
                        this.tdsType = "Trust (AOP)";
                        break;
                    case 'Z':
                        this.tdsType = "TDS not Applicable";
                        break;
                    default:
                        this.showAlert("Please enter a valid PAN Number", "OK", "", () => {
                            this.panNumber = '';
                            this.tdsType = '';
                        });
                        break;
                }
            },

            goToNextPage(pageNumber, parentRepairerId, repairerId) {
                this.pageNumber= pageNumber ? pageNumber : 1;
                let requestData = "";
                this.showProgress("Data is searching, Please wait");
                this.GETM("GET_SEARCH_REPAIRER_MASTER", requestData, (res) => {
                    if (res.data.statusCode === 200 && res.data.data && res.data.data.data) {
                        this.hideProgress(); 
                        let childData = res.data.data.data.filter((data) => data.parentRepairerId === parentRepairerId)
                        this.childRepairerMastersData = childData.filter((data) => data.repairerId !== repairerId );
                        this.pageRowCount = 10;
                        this.totalListCount = this.childRepairerMastersData.length;
                        this.totalPages = this.totalListCount > this.pageRowCount ? this.totalListCount / this.pageRowCount : 1;
                        this.pageStartIndex = pageNumber == 1 ? 0 : (pageNumber - 1) * this.pageRowCount;
                        this.childRepairerMastersPaginationData = this.childRepairerMastersData.slice(this.pageStartIndex, pageNumber * this.pageRowCount);         
                    }else{
                        this.hideProgress(); 
                        this.repairerMastersData = [];
                        this.pageRowCount = 0;
                        this.totalListCount = 0;
                        this.totalPages = 0;
                        this.pageStartIndex =0;
                        this.showAlert(res.data.msg);
                    }
                },(error) => {
                    this.hideProgress();
                    this.repairerMastersData = [];
                    if (error.response.data.msg) {
                        this.showAlert(error.response.data.msg);
                    } else if (error.response.data.message.msg) {
                        this.showAlert(error.response.data.message.msg);
                    } else if (error.response.data.message) {
                        this.showAlert(error.response.data.message);
                    } else {
                        this.showAlert("Something went wrong");
                    }
                })
            },
            
            clearChildRepairerWithParentForm() {
                this.showConfirm("Confirmation","Are you sure you want to clear?","Yes","No", (yes) => {
                    // this.parentRepairerID = '',
                    // this.childRepairerID = ''
                    this.lob=[]
                    this.childRepairerName = '',
                    this.childRepairerStatus = 'Active',
                    this.repairerIndicator = '',
                    this.overallSatisfaction = '',
                    this.clientSpecificRepair = '',
                    this.effectiveDate = new Date().toISOString(),
                    this.expirationDate = null,
                    this.auditDate = null,
                    this.auditDueDate = null,
                    this.pickupOption = false,
                    this.tripFee = false,
                    this.tripFeeInr = '',
                    this.labourRateInr = '',
                    this.jobRate1 = 0,
                    this.rate2 = 0,
                    this.rate3 = 0,
                    this.productType = '',
                    this.authorizedBrands = '',
                    this.repairerWarrantyPeriod = 0,
                    this.salutation = 'Mr',
                    this.firstName = '',
                    this.middleInitial = '',
                    this.lastName  = '',
                    this.workPhoneNumber  = '',
                    this.emailID  = '',
                    this.cellPhoneNumber = '',
                    this.communicationEmailID = '',
                    this.secondEmailID = '',
                    this.isPanNotAvailable = false,
                    this.panNumber = '',
                    this.gstNumber = '',
                    this.gstFrequency = '',
                    this.tdsType = '',
                    this.differentialTds = false,
                    this.msme = '',
                    this.enterpriseClassification = '',
                    this.enterpriseCategory = '',
                    this.uan = '',
                    this.bankTransfer = this.bankTransfer,
                    this.bankName = '',
                    this.ifscCode = '',
                    this.bankAccountNumber = '',
                    this.confirmBankAccountNumber = '',
                    this.accountType = '',
                    this.bankAddress1 = '',
                    this.bankAddress2 = '',
                    this.bankAddress3 = '',
                    this.branchName = '',
                    this.cancelledCheque = [],
                    this.addressLine1 = '',
                    this.addressLine2 = '',
                    this.addressLine3 = '',
                    this.country = 'India',
                    this.state = '',
                    this.district = '',
                    this.city = '',
                    this.postalCode = '',
                    this.phoneNumber = '',
                    this.preferredCommunicationMethod = '',
                    this.faxNumber = '',
                    this.secondFaxNumber = ''
                    this.cancelledChequeFileData = this.cancelledChequeFileData.filter((data) => data.status === true);
                    this.attachments = this.attachments.filter((data) => data.status === true);
                    this.$refs.createRepairer.resetValidation();
                    this.$refs.addressref.resetValidation();
                    this.hideaddressMessage = true;
                    this.manualOverwrite = false;
                }, (No) => {});
            },

            updateChildRepairer() {
                this.hideaddressMessage = false;
                let requestData = {
                    // repairerId: this.childRepairerID,
                    repairerName: this.childRepairerName,
                    addressLine1: this.addressLine1,
                    addressLine2: this.addressLine2,
                    addressLine3: this.addressLine3,
                    country: this.country,
                    state: this.state,
                    district: this.district,
                    city: this.city, 
                    postalCode: this.postalCode,
                    phoneNumber: this.phoneNumber,
                    preCommMethod: this.preferredCommunicationMethod,
                    repairerStatus: this.childRepairerStatus === 'Active' ? true : false,
                    faxNumber: this.faxNumber,
                    secondFaxNumber: this.secondFaxNumber,
                    superRepairerIndicator: this.repairerIndicator,
                    overllSatisfactionRating: this.overallSatisfaction,
                    agreementEffectiveDate: this.effectiveDate ? this.effectiveDate : "",
                    agreementExpirationDate: this.expirationDate ? this.expirationDate : "",
                    pickUp: this.pickupOption,
                    isTripFee: this.tripFee, 
                    tripFee: this.tripFeeInr,
                    laborRate: this.labourRateInr,
                    jobRate1: this.jobRate1,
                    jobRate2: this.rate2 || 0,
                    jobRate3: this.rate3 || 0,
                    lastAuditDate: this.auditDate ? this.auditDate : "",
                    nextAuditDate: this.auditDueDate ? this.auditDueDate : "",
                    productType: this.productType,
                    authorizedBrand: this.authorizedBrands,
                    repairerWarrantyPeriod: this.repairerWarrantyPeriod,
                    salutation: this.salutation,
                    firstName: this.firstName,
                    middleName: this.middleInitial,
                    lastName: this.lastName,
                    // suffix: this.suffix,
                    workPhoneNumber: this.workPhoneNumber,
                    emailId: this.emailID,
                    cellPhoneNumber: this.cellPhoneNumber,
                    commEmailId: this.communicationEmailID,
                    secondEmailId: this.secondEmailID,
                    isPanNotAvailable: this.isPanNotAvailable,
                    panNumber: this.panNumber,
                    gstNumber: this.gstNumber,
                    gstFrequency: this.gstFrequency,
                    tdsType: this.tdsType,
                    differentialTds: this.differentialTds,
                    msme: this.msme === 'Yes' ? true : false,
                    enterpriceClassification: this.enterpriseClassification,
                    enterpriceCategory: this.enterpriseCategory,
                    uan: this.uan,
                    bankTransfer: this.bankTransfer,
                    bankName: this.bankName,
                    ifscCode: this.ifscCode,
                    bankAddress1: this.bankAddress1,
                    bankAddress2: this.bankAddress2,
                    bankAddress3: this.bankAddress3,
                    bankAccountNumber: this.bankAccountNumber,
                    confirmBankAccountNumber: this.confirmBankAccountNumber,
                    bankAccountType: this.accountType,
                    branchName: this.branchName,
                    cancelledCheque: this.updateFile(this.cancelledChequeFileData),
                    attachments: this.updateFile(this.attachments),
                    user: {
                        ntid: this.userObj &&  this.userObj.ntid,
                        employeeName: this.userObj && this.userObj.employeeName,
                        userId: this.userObj && this.userObj.userId
                    },
                    overwriteManually: this.manualOverwrite,
                    approveStatus: 'Pending for approval',
                    remarkType: this.remarkType,
                    remarkText: this.remarkText,
                    _id: this._id
                }
                if(this.addressLine1 === '' || this.postalCode === '' || this.country === '' || this.state === '' || this.city === '') {
                    this.addressRequiredStatus = true
                } else {
                    this.addressRequiredStatus = false
                }
                if(this.bankTransfer && this.cancelledChequeFileData.length < 1) {
                    this.cancelledChequeRequiredStatus = true
                } else {
                    this.cancelledChequeRequiredStatus = false
                }

                if(this.$refs.createRepairer.validate() && !this.cancelledChequeRequiredStatus && !this.addressRequiredStatus) {
                    this.showConfirm("Confirmation", "Are you sure you want to save?", "Yes", "No", (Yes) => {
                    window.scrollTo(0, window.innerHeight / 2);
                    this.showProgress("Please wait");
                    this.PUT_ByURLM(
                        "UPDATE_SINGLE_REPAIRER",
                        requestData,
                        (res) => {
                            if(res.data.statusCode ===  200) {
                            this.hideProgress();
                            this.showAlert(res.data.msg,"OK",null ,() => {
                                this.$router.push('/master/search-repairer');
                            });
                            } else {
                            this.hideProgress();
                            this.showAlert(res.data.msg);
                            }
                        },
                        (err) => {
                            this.hideProgress();
                            console.log(err);
                        }, this.$route.params.id
                    ); 
                    }, (No) => {});
                } else {
                    this.showAlert("Please enter all mandatory fields");
                }
            },

            uploadBtnClicked(){
                document.getElementById("fileupload").click();
            },

            filesSelected (){
                if(this.cancelledCheque.length !== 0){
                    const fsize = this.cancelledCheque[0].size;
                    const file = Math.round(fsize / 1024);
                    
                    if (file > 5120) {
                        this.showAlert("File is too big, please select a file less than 5MB");
                    } else {
                    this.readImageM(this.cancelledCheque[0].name, this.cancelledCheque[0], (res) => { 
                        var type = [];
                        type = this.cancelledCheque[0].type
                        
                        if(this.cancelledChequeFileData.length < 1){
                        this.cancelledChequeFileData.push({ 
                            file : res.fileName,
                            fileBase: res.base,
                            documentType: type
                        })
                        this.cancelledChequeFileData =   
                        this.cancelledChequeFileData.reduce((unique, o) => {
                            if(!unique.some(obj => obj.file === o.file)) {
                                unique.push(o);
                                
                            }
                            return unique;
                        },[]);
                        }else{
                        //   this.showConfirm("Confirmation","Do you want to delete 1st file to replace it?","Yes","No",(Yes)=>{
                            this.cancelledChequeFileData.splice(0,1)
                            this.cancelledChequeFileData.push({ 
                            file : res.fileName,
                            fileBase: res.base,
                            documentType: type
                            })
                            this.cancelledChequeFileData =   
                            this.cancelledChequeFileData.reduce((unique, o) => {
                            if(!unique.some(obj => obj.file === o.file)) {
                                unique.push(o);
                                
                            }
                            return unique;
                            },[]);
                        //   },(No)=>{})
                        }
                        // Phase-2 function call starts
                        this.getCancelledChequeData(res.base);
                        this.manualOverwrite = false;
                        // Phase-2 function call ends
                    });
                    }
                }
                // else{
                //     this.cancelledChequeFileData = [];
                // }
            },

            openBrowse(){
                document.getElementById("fileupload1").click();
            },

            fileUploaded(){
                if(this.browse_attachments.length !== 0){
                    const fsize = this.browse_attachments[0].size;
                    const file = Math.round(fsize / 1024);
                    
                    if (file > 5120) {
                        this.showAlert("File is too big, please select a file less than 5MB");
                    } else {
                    this.readImage(this.browse_attachments[0].name, this.browse_attachments[0], (res) => { 
                        var type = [];
                        type = this.browse_attachments[0].type
                        
                        if(this.attachments.length < 50){
                        this.attachments.push({ 
                            file : res.fileName,
                            fileBase: res.base,
                            documentType: type
                        })
                        this.attachments =   
                        this.attachments.reduce((unique, o) => {
                            if(!unique.some(obj => obj.file === o.file)) {
                                unique.push(o);
                                
                            }
                            return unique;
                        },[]);
                        }else{
                        this.showConfirm("Confirmation","Do you want to delete 1st file to replace it?","Yes","No",(Yes)=>{
                            this.attachments.splice(0,1)
                            this.attachments.push({ 
                            file : res.fileName,
                            fileBase: res.base,
                            documentType: type
                            })
                            this.attachments =   
                            this.attachments.reduce((unique, o) => {
                            if(!unique.some(obj => obj.file === o.file)) {
                                unique.push(o);
                                
                            }
                            return unique;
                            },[]);
                        },(No)=>{})
                        }
                    });
                    }
                }
                // else{
                //     this.attachments = [];
                // }
            },

            updateFile(file) {
                let newDocument = file.map((fileData) => {
                    if(fileData.fileBase) {
                    return fileData
                    } else {
                    // fileData["fileBase"] = "";
                    delete fileData['status'];
                    return fileData
                    }
                });
                return newDocument;
            },

            deleteCancelledCheque(index) {
                this.cancelledChequeFileData.splice(index, 1);
                this.cancelledCheque = null;
                this.ifscCode = ""; 
                this.bankName="";
                this.bankAddress1="";
                this.branchName="";
                this.bankAddress2="";
                this.bankAddress3="";
                this.bankAccountNumber = "";
                this.confirmBankAccountNumber = "";
            },

            deleteAttachments(index) {
                this.attachments.splice(index, 1);
                this.browse_attachments = null
            },

            viewDocUsingBase(base) {
                const base64ImageData = base
                const contentType = base.split(':')[1].split(';')[0];
                const byteCharacters = window.atob(base64ImageData.substr(`data:${contentType};base64,`.length));
                const byteArrays = [];
                for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
                    const slice = byteCharacters.slice(offset, offset + 1024);
                    const byteNumbers = new Array(slice.length);
                for (let i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                    byteArrays.push(byteArray);
                }
                const blob = new Blob(byteArrays, {type: contentType});
                const blobUrl =  window.URL.createObjectURL(blob);
                window.open(blobUrl, '_blank');
            },

            viewDocument(itemData) {
                if(itemData.fileBase) {
                    this.viewDocUsingBase(itemData.fileBase)
                } else {
                    let requestData = "";
                    if(itemData !== null) {
                    requestData = "documentId=" + itemData.documentId + "&b64=true";
                    }
                    this.GETM("VIEW_DOCUMENT", requestData, (res) => {
                        if (res.data.statusCode === 200) {
                            this.viewDocUsingBase(res.data.data)
                        }else{
                            console.log("");
                        }
                    },(error) => {
                        console.log("Error", error);
                    });
                }
            },

            restoreData() {
                if(Object.keys(this.$route.params).length !== 0) {
                    let selecteditemid = {
                        view: true,
                        pincodeFunctionCall:true,
                        repairerMasterSearchObj: this.$route.params.repairerMasterSearchObj,
                        pageno:this.pageno
                    };
                    this.$router.push({
                        name: "SearchRepairer",
                        params: selecteditemid,
                    });
                } else {
                    this.$router.push('/searchrepairer');
                }
            },

            goBack() {
                if(!this.masterApproval){
                    if(this.viewFormOnly) {
                        this.restoreData();
                    } else {
                        this.showConfirm("Confirmation","Are you sure you want to leave?","Yes","No", (Yes) => {
                            this.restoreData();
                        }, (No) => {});
                    }
                }else{
                    this.showConfirm("Confirmation","Are you sure you want to leave?","Yes","No", (Yes) => {
                        this.$router.push({
                            name: "repairerApproval",
                            params: {
                                pageno:this.pageno
                            }
                        })
                    }, (No) => {});
                }
            },

            getStatusStyle(item) {
                return !item.repairerStatus ? 'inactive' : 'active'
            },

            openParentWithChildRepairer (id) {
                let selecteditemid = {
                    id,
                    view: true,
                    pincodeFunctionCall:true,
                    repairerMasterSearchObj: this.$route.params.repairerMasterSearchObj && this.$route.params.repairerMasterSearchObj
                };
                this.$router.push({
                    name: "ParentWithChildRepairer",
                    params: selecteditemid,
                });
            },

            // For address

            callPincodeAPI(indata){
                if(indata!==""){
                    if(isNaN(indata)){
                        this.returnLength = 100
                        //Surendra(16-04-2021):Added if else for MC839-not able to search city data by first three letters
                        this.reqData = "pageNo=1&perPage=100&smartSearch="+indata//Surendra(27-05-2021):Changed from cityDistrictName to smartSearch for MC-1182 where ever we have pincode, it should allow to search by city, area, pincode and district."
                    }else{
                        this.returnLength = 6
                        this.reqData = "pageNo=1&perPage=100&pinCode="+indata
                    }
                    this.GETM("getPincodes",this.reqData, res=>{

                        var pin_not_found = false
                        if(res.data.data.length == 0){
                            pin_not_found = true
                            // this.counter = ''
                            this.message ='Pin code not found'
                            this.validationbox = true;
                        }else{
                            var processed_pin =  res.data.data.data.map(e=>{
                                if(e.cityDistrictName && e.cityDistrictName !== ""){
                                    return { locpincode:e.pinCode + " - " + e.pinCodeLocality, pincodeArry:e}
                                }else{
                                    return {locpincode: e.pinCode, all:e};
                                }                  
                            })
                            this.pitems.push(...processed_pin)

                        }  
                        if(pin_not_found){
                            // this.pitems.push({locpincode:'No data Available',all:''});
                            this.pitems.push();
                        }
                    },(error)=>{
                            return error;
                    },true);
                }
            },

            toGetCountryCity(pincode){
                if(pincode){
                    let indexItem =this.pincodes.find((elem)=>{
                        return elem.locpincode == pincode;
                    })
                    if(indexItem){
                        this.country = indexItem.pincodeArry.countryName ? indexItem.pincodeArry.countryName : "" ;
                        this.state = indexItem.pincodeArry.stateName ? indexItem.pincodeArry.stateName : "" ;
                        this.city = indexItem.pincodeArry.cityDistrictName ? indexItem.pincodeArry.cityDistrictName : "";
                        this.district = indexItem.pincodeArry.cityDistrictName ? indexItem.pincodeArry.cityDistrictName : "";  
                        this.searchCountry=indexItem.pincodeArry.countryName ? indexItem.pincodeArry.countryName : ""
                        this.getClaimantStates()
                        this.getClaimantCities()
                        this.getCountries()
                    }
                }else{
                    this.country = ''
                    this.district = ''
                    this.state = ''
                    this.city = ''
                    this.pitems = []
                    this.cities = []
                    this.districts = []
                    this.states = []
                    this.getCountries()
                }
            },

            clearClaimantcity(){
                if(this.city!='OTHER'){
                    this.cityOtherName=""
                }
            },

            getClaimantStates(){
                if(this.country){
                    this.showProgress()
                    var requestData = "countryName="+this.country
                    this.GETM("getStates", requestData, (res) => {
                        this.states = res.data.data.data                        
                        this.hideProgress()
                    },(error)=>{
                        this.hideProgress()
                    return error;
                    });
                }else{
                    this.states = []
                    this.state = ''
                    this.cities = []
                    this.city = ''
                    this.districts = ''
                    this.district = []
                    this.getCountries()
                }
            },

            getClaimantCities(){
                if(this.state){
                    this.showProgress()
                    var req="stateName=" + this.state                    
                    this.GETM("getCities", req, (res) => {
                        this.cities = res.data.data.data
                        this.districts = res.data.data.data
                        var other ={
                            cityDistrictName:'OTHER'
                        }
                        if(this.cities==undefined){
                            this.cities=[]
                        }
                        this.cities.push(other)
                        this.hideProgress()
                    },(error)=>{
                        this.hideProgress()
                        return error;
                    }); 
                }else{
                    this.city = ''
                    this.district = ''
                    this.cities = []
                    this.districts = []
                }
            },

            getCountries(){
                this.GETM("getCoutries", "", (res) => {
                    this.countries = res.data.data.data;
                },(error) => {
                    return error;
                },true);
            },

            getBankFields(IFSC){
                // let indexItem = this.bankDetails[0] && this.bankDetails[0].length > 0 ? this.bankDetails[0][this.bankDetails[0].length - 1] : this.bankDetails[0];
                let indexItem = {};
                let filteredArray = [];
                let bankDetailArray = this.bankDetails[0] && this.bankDetails[0].length > 0 ? this.bankDetails[0] : this.bankDetails;
                if(IFSC){
                    filteredArray = bankDetailArray.filter((elem)=>{
                        return elem.ifscCode == IFSC;
                    })
                    
                    let filteredArrayLength = filteredArray.length;
                    indexItem = filteredArray[filteredArrayLength - 1];
                    // console.log("indexItem", filteredArray, indexItem);
                    if(indexItem){
                        this.bankName=indexItem.financierName;
                        this.bankAddress1=indexItem.branchAddress;
                        this.branchName=indexItem.branchName;
                        let address2 = '';
                        if(indexItem.district) {
                            address2 += `${indexItem.district}, `
                        }
                        if(indexItem.city) {
                            address2 += `${indexItem.city}, `
                        }
                        if(indexItem.pincode) {
                            address2 += indexItem.pincode
                        }
                        this.bankAddress2=address2;
                        this.bankAddress3=indexItem.state;
                        if(indexItem.financierName) {
                            this.disableBankName = true
                        } else {
                            this.disableBankName = false
                        }
                        if(indexItem.branchAddress) {
                            this.disableBankAdd1 = true
                        } else {
                            this.disableBankAdd1 = false
                        }
                        if(indexItem.district || indexItem.city || indexItem.pincode) {
                            this.disableBankAdd2 = true
                        } else {
                            this.disableBankAdd2 = false
                        }
                        if(indexItem.state) {
                            this.disableBankAdd3 = true
                        } else {
                            this.disableBankAdd3 = false
                        }
                        if(indexItem.branchName) {
                            this.disableBankBranch = true
                        } else {
                            this.disableBankBranch = false
                        }
                    }
                }   
                // else{
                // this.bankName="";
                // this.bankAddress1="";
                // this.branchName="";
                // this.bankAddress2="";
                // }
                // this.bankAddress3 ="";
            },

            searchBanks(value) {
                if (value) {
                    if (value.length > 2) {
                        let request = value;
                        this.GET_ByURLM(
                            "GET_GC_BANK",
                            request,
                            (res) => {
                                this.bankDetails = [];
                                if (res.data.statusCode === 200 && res.data.data) {
                                    this.bankDetails.push(res.data.data);
                                } else {
                                this.showAlert(res.data.msg);
                                }
                            },
                            (error) => {
                                this.bankDetails = [];
                                if (error.response.data.msg) {
                                    this.showAlert(error.response.data.msg);
                                } else if (error.response.data.message.msg) {
                                    this.showAlert(error.response.data.message.msg);
                                } else if (error.response.data.message) {
                                    this.showAlert(error.response.data.message);
                                } else {
                                    this.showAlert("Something went wrong");
                                }
                            }, true
                        );
                    }
                }
            },
            viewDocumentCheque(id){
                let requestData = "documentId=" + id + "&b64=true";
                this.GETM(
                "VIEW_DOCUMENT",
                requestData,
                (res) => {
                    if (res.data.statusCode === 200) {
                    this.showCheque=res.data.data
                    }
                },
                (error) => {
                    console.log("Error", error);
                },
                true
                );
            },
        },

        watch: {
            searchIFSCCODE(value) {
                this.searchBanks(value);
            },

            searchPincode(val){
                if(val !==null && val.charAt(0) === '0') {
                    this.showToast("Please enter correct pin code", this.TOST().WARNING)
                }
                if(val == null){
                    return
                }
                if(val.length > 4){
                    if(val.length > 6){
                        val = val.slice(0,6);
                    }
                    var vlen = val.length
            
                    if(this.pincodes.length == 0 && val.length > 2){
                        this.callPincodeAPI(val);
                        return
                    }
                    for(let i=0; i<this.pincodes.length;i++){
                        if(vlen > 3){
                            vlen = 3
                            var strlen = this.pincodes[i].locpincode.slice(0,vlen)
                        }else{
                            var strlen = this.pincodes[i].locpincode.slice(0,vlen)
                        } 
                        if(vlen > strlen.length){
                            return
                        }
                        if(strlen != val){
                            this.callPincodeAPI(val)
                            return
                        }else{
                            return
                        }
                    }
                }else{
                    return
                }
            },

            searchCountry(val){
                if(val && val.length > 2){
                    let req = "countryName=" + val
                    this.GETM("getCoutries", req, (res) => {
                        this.countries = res.data.data.data;
                    },(error) => {
                        return error;
                    })
                }                
            },

            searchState(val){
                if(val!==null){
                    if(val.length > 2){        
                        let req = '';
                        if(this.country){
                            req = "countryName=" + this.country
                        } else {
                            req = "stateCodeName=" + val
                        }
                        this.GETM("getStates", req, (res) => {
                            this.states = res.data.data.data
                        },(error)=>{
                            return error
                        }); 
                    }else{
                        return
                    }
                }else{
                    return;
                }
            },

            searchCity(val){
                if(val!==null){
                    if(val.length > 2){
                        this.showProgress();
                        var req = ''
                        if(this.state) {
                            req = "stateName=" + this.state
                        } else if(this.country){
                            req = "countryName=" + this.country
                        } else {
                            req = "cityDistrictName=" + val
                        }                       
                        this.GETM("getCities", req, (res) => {
                            this.cities = res.data.data.data;
                            var other ={
                                cityDistrictName:'OTHER'
                            }
                            this.cities.push(other)
                                this.hideProgress()
                        },(error)=>{
                            this.hideProgress()
                            return error;
                        }); 
                    }else{
                        this.validationbox = true;
                        this.message = 'Please enter Minimum 3 charactor to search City'
                        this.cities.push()
                        return
                    }
                }else{
                    this.cities.push()
                    return
                }                
            },

            searchDistrict(val){
                if(val!==null){
                    if(val.length > 2){
                    var req = ''
                    if(this.state) {
                        req = "stateName=" + this.state
                    } else if(this.country){
                        req = "countryName=" + this.country
                    } else {
                        req = "cityDistrictName=" + val
                    }       
                    this.GETM("getCities", req, (res) => {
                        this.districts = res.data.data.data;
                            var other ={
                            cityDistrictName:'OTHER'
                        }
                        if(this.districts==undefined){
                            this.districts=[];
                        }
                        this.districts.push(other)
                            
                    },(error)=>{
                            
                        return error;
                    }); 
                    }else{
                        return;
                    }
                }else{
                    return;
                }
            }
        }
    }
</script>

<style scoped>
    .apprvebtn{
        background-color: #23B1A9 !important;
        color:#fff;
    }   

    .nocolor{
        background-color: grey;
    }
    .containerStyle {
        margin: 0;
        padding: 0;
        max-width: 100%;
    }
    
    .viconfont{
        font-size: 26px;
    }
    .iconsize{
        font-size: 18px !important;
    }
    .addressbtn{
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 2px 2px #00000051;
        border: 0.5px solid #C1C8CC !important;
        text-transform: capitalize;
        justify-content: space-between;
        letter-spacing: 0;        
    }
    .tableicons{
        font-size: 18px !important;
        color: #23B1A9 !important;
        justify-content: right;
    }
    .radiobtn {
        background: #F7F7F7 0% 0% no-repeat padding-box;
        border: 0.5px solid #C1C8CC;
    }
    .iconstyle {
        margin-left: -12px;
        background-color: rgb(247, 247, 247);
        width: 45px;
        height: 38px;
        font-size: 20px !important;
        border: 0.3px solid rgb(232, 235, 236);
        opacity: 1;
    }
    .cancelCheque {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: inset 0px 2px 1px #00000052;
        border: 0.5px solid #C1C8CC;
        opacity: 1;
    }
    .addressPopUpStyle {
        background-color: #f7f7f7 !important;
        box-shadow: inset 0px 3px 4px 0px #00000047;
    }
    .addressPopUpHeaderStyle {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 5px 7px #00000029;
        opacity: 1;
        font-size: 14;
    }
    .descrptionSpan {
        width: 85%;
        margin-left: 53px;
        margin-top: 21px;
        margin-bottom: 14px !important;
        font-weight: bold;
        font-size: 16px;
    }
    .descrptionSpanAttach {
        width: 80%;
        margin-left: 53px;
        margin-top: 21px;
        margin-bottom: 14px !important;
        font-weight: bold;
        font-size: 16px;
    }
    .backgroundWhite{
        background: white !important;
        border: 0.5px solid #e4e0e0 !important;
    }
    .tablebtn{
        background: #FFFFFF 0% 0% no-repeat padding-box !important;
        box-shadow: inset 0px 2px 1px #00000052 !important;
        border: 0.5px solid #C1C8CC;
        border-radius: 18px !important;
        height: 30px !important;
        text-transform: capitalize !important;
        justify-content: space-between;
        font-size: 12px;
        letter-spacing: 0px;
    }
    .radiobox_border{
        border:0.5px solid rgb(206, 206, 206);
        height: 39px;
        background-color: #cacaca45;
    }
    .inactive {
        color: #E46A25;
        background: none !important;
        font-weight: bold;
    }
    .active {
        color: #1E9690;
        background: none !important;
        font-weight: bold;
    }
    .attachtxt{
        font-size: 12px;
        font-weight: 500;
    }
    .browsebtn{
        text-transform: capitalize;
        letter-spacing: 0.1px;
        text-align: left;
        font-weight: bold;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: inset 0px 2px 1px #00000052;
        border: 0.5px solid #C1C8CC;
        border-radius: 0;
        width:90%;
        height:40px !important;
    }
    .vcardTextTableBackground{
        background-color: #f7f7f7 !important;
        box-shadow: inset 0px 3px 4px 0px #00000047;
    }
    .orangetext {
        color: #E46A25;
        font-size: 17px;
    }
    .adressmsg {
        font-size: 12px; 
        color: #ff5252;
        margin-left: 12px;
    }
    .paginationText{
        font-size: 12px;
    }
    .closeicon{
        font-size: 18px;
        color: #909699;
    }
</style>