<template>
    <v-app>
        <div class="mx-2">
            
        <v-layout class=" mt-2" >
            <v-flex xs3>
                    <v-layout column class="cust_cepfp custPlanFrPlcy mx-2 my-2 ">
                        <p class="font-weight-bold cstm-clr-cepfp">
                            Policy Number
                        </p>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="policyNumber"
                             class="rounded-0"
                             disabled>
                        </v-text-field>
                    </v-layout>
                </v-flex>


                <v-flex xs3>
                    <v-layout column class="cust_cepfp custPlanFrPlcy mx-2 my-2 ">
                        <p class="font-weight-bold cstm-clr-cepfp">
                            Corporate Group Name
                        </p>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="corporateGroupName"
                             class="rounded-0"
                             disabled>
                        </v-text-field>
                    </v-layout>
                </v-flex>

                <v-flex xs3>
                    <v-layout column class="cust_cepfp custPlanFrPlcy mx-2 my-2 ">
                        <p class="font-weight-bold cstm-clr-cepfp">
                            Policy Status
                        </p>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="policyStatus"
                             class="rounded-0"
                             disabled>
                        </v-text-field>
                    </v-layout>
                </v-flex>


<!-- Policy Dates  -->
                <v-flex xs3>
                    <v-layout column class=" mx-2 mt-2 cust_cepfp custPlanFrPlcy cust-rmvpd-cepfp">                             
                    <p class="font-weight-bold cstm-clr-pr3">
                        Policy Effective Date
                    </p>

                    <v-menu
                        ref="calender"
                        :close-on-content-click="true"
                        v-model="PlcyEfctveDtecalender"
                        :nudge-right="40"
                        transition="scale-transition"
                        max-width="290px"
                        min-width="290px" 
                        hide-details 
                        class="rounded-0"
                        disabled
                       >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-bind="attrs"
                                label="Select.."
                                :value="cmputdPlcyEfctveDteFrmtdMmntjs"
                                solo
                                hide-details 
                                class="rounded-0"
                                disabled
                                readonly 
                                v-on="on">
                                <div slot="prepend-inner" style="background-color:#F7F7F7;padding: 9px !important"><v-icon>event</v-icon></div>
                            </v-text-field>
                        </template>                     
                        <v-date-picker
                            v-model="policyEffectiveDate"
                            no-title>
                        </v-date-picker>
                    </v-menu>
                

                                            
                    </v-layout>
                </v-flex>

               
               <v-flex xs3>
                    <v-layout column class=" mx-2 mt-2 cust_cepfp custPlanFrPlcy cust-rmvpd-cepfp">                             
                    <p class="font-weight-bold cstm-clr-pr3">
                        Policy Expiry Date
                    </p>

                    <v-menu
                        ref="calender"
                        :close-on-content-click="true"
                        v-model="PlcyExpryDtecalender"
                        :nudge-right="40"
                        transition="scale-transition"
                        max-width="290px"
                        min-width="290px" 
                        hide-details 
                        class="rounded-0"
                        disabled
                       >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-bind="attrs"
                                label="Select.."
                                :value="cmputdPlcyExpryDteFrmtdMmntjs"
                                solo
                                hide-details 
                                class="rounded-0"
                                disabled
                                readonly 
                                v-on="on">
                                <div slot="prepend-inner" style="background-color:#F7F7F7;padding: 9px !important"><v-icon>event</v-icon></div>
                            </v-text-field>
                        </template>                     
                        <v-date-picker
                            v-model="policyExpiryDate"
                            no-title
                            @change="stsChng">
                        </v-date-picker>
                    </v-menu> 
               

                                            
                    </v-layout>
                </v-flex>

        </v-layout>
            <v-card  tile class="cust-tbl-cepdt mx-2">
                <div  style="background-color:#23B1A9" class="pa-2 pl-3"><v-text class="font-weight-bold body-2"  style="color:#F5F5F5">Plan Name</v-text></div>
                <!-- :page.sync="page"
                    :items-per-page="itemsPerPage"
                    @page-count="pageCount = $event" -->
                <v-data-table
                    :headers="headers"
                    :items="planforPlcyData"
                    
                    hide-default-footer
                    dense
                    
                    show-select
                    item-key="_id"
                    v-model="selected"
                >
                    <template v-slot:item.planEffectiveDate={item}> 
                        <div>
                            {{item.planEffectiveDate|formatDate}}
                        </div>  
                    </template>
                    <template v-slot:item.planExpiryDate={item}> 
                        <div>
                            {{item.planExpiryDate|formatDate}}
                        </div>  
                    </template>
                    <template v-slot:item.actions="{ item }" >
                        <v-menu
                            transition="slide-x-transition"
                            top
                            left
                            :nudge-left="30"
                            :nudge-bottom="17"
                            content-class="my-menu-bnkmstr"
                            style=""
                            flat
                            allow-overflow
                        >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn   style="width: 19px;height: 19px;background-color:#1E9690" fab dark small class= "my-1" v-bind="attrs"
                                v-on="on">
                                <v-icon dark small>more_horiz</v-icon>
                            </v-btn>
                        </template>
                        <!-- class="pa-0 ma-0 cust-bnk-tile" -->
                        <v-list dense style="background-color:#FFFFFF" class="pa-0 ma-0">
                            <!-- class="pa-0 ma-0" -->
                            <v-list-item
                                dense
                                style="min-height: 28px !important"
                                v-for="(itm, index) in chkr_items"
                                :key="index"
                                @click="chkr_seltd(itm,item)" 
                                class="pa-0 ma-0">
                                <!-- class="pa-0 ma-0" style="background-color:#FFFFFF;min-width: 37px; border-right:0.5px solid #C1C8CC"  -->
                                <v-list-item-icon class="pa-1 pt-2 ma-0 mr-0" > 
                                    <!-- :color="item.color" class="pl-2 pt-1" -->
                                    <v-icon  small>{{ itm.icon }}</v-icon>
                                </v-list-item-icon>
                                <v-divider vertical class="mx-1"></v-divider>
                                <!-- class="pa-0 ma-0 pl-2 pt-1 font-weight-bold" -->
                                <v-list-item-title  class="pa-1 pr-3 ma-0 mr-0" style="font-size: 12px !important;">
                                    {{ itm.title }}
                                </v-list-item-title>

                            </v-list-item>
                        </v-list>
                        </v-menu>
                    </template>
                </v-data-table>
                <v-divider></v-divider>
               
            </v-card>
            <v-card style="background-color:#F7F7F7" tile class="mx-2" v-if="planforPlcyData.length != 0">
                 <v-layout row justify-end align-center class="mx-2" >
                            <span class="mr-1 font-weight-bold" style="color:#3C3D3D; font-size:13px;">
                                <span class="" style="color:#E46A25;">Showing {{fromvalue}}-{{toValu}} </span>out of {{totalCount}} records
                            </span>
                            <v-divider  class="ma-2" vertical ></v-divider>
                             <v-pagination
                                class="cust-bnkAcc-pgn"
                                v-model="page"
                                color="#152F38"
                                flat
                                :length="pageCount"
                                @input="nextPageData(page)"
                            ></v-pagination>
                </v-layout>

            </v-card>
            
            <v-card style="background-color:#F7F7F7" tile class="my-4 pa-2 py-4 mx-2">

                <v-layout style="flex:1" justify-end>
                    <v-btn dark  tile  style="background-color:#23B1A9" class="text-capitalize px-3 mr-3" small @click="cnfgPlanfrPlcy()"><v-icon dark small >add_circle</v-icon> <v-divider vertical class="mx-2"></v-divider> Create New Plan</v-btn>

                    <v-btn dark  tile  style="background-color:#E46A25" class="text-capitalize px-3 mr-3" small @click="$router.push('/homePage')"><v-icon dark small>arrow_back</v-icon> <v-divider vertical class="mx-2"></v-divider>Return</v-btn>
                   
                    
                    <!-- <v-btn dark  tile color="#23B1A9" class="text-capitalize px-3 mr-3" small @click.stop="showScheduleForm=true"><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider> Save</v-btn> -->
                    <v-btn dark tile style="font-size:12px;background-color:#152F38" small class="text-capitalize px-3 mr-2" @click="sendForApprvl()"><v-icon dark small >arrow_forward</v-icon> <v-divider vertical class="mx-2"></v-divider>Send For Approval</v-btn>
                </v-layout>

            </v-card>

            <v-dialog v-model="dlteDilg" persistent max-width="420px">
                        <v-card color="#F7F7F7" class="pb-2" tile>
                            <v-card tile>
                                <v-layout justify-space-between  class="" style="flex:1;background-ox-shadow: 0px 3px 5px #00000029;">
                                    <v-layout align-center>
                                        <v-card-title class="pa-0 pl-3 py-2 text-capitalize font-weight-bold text-uppercase" style="color:#10242B;font-size:13px">Confirmation</v-card-title>   
                                    </v-layout>
                                    <div style="border-left:1px solid #C1C8CC;" @click="close_dltePlan()">
                                        <v-icon style="cursor: pointer;" class="pa-3" >close</v-icon>
                                    </div>
                                </v-layout>
                            </v-card>
                            <v-card tile  class="pa-4 py-6">
                               <p class="pa-0 ma-0 font-weight-bold" style="font-size:13px">Are you sure you want to delete ?</p>
                            </v-card>

                            
                            <v-layout class="ml-4 pt-4 pb-2" row style="">
                                <v-btn tile dark style="font-size:12px;background-color:#152F38" class=" px-3 mr-3 text-capitalize" small @click="close_dltePlan()">
                                    <v-icon color="" small >close</v-icon>
                                    <v-divider vertical class="ml-2 mr-2 text-capitalize"></v-divider>Cancel
                                </v-btn>
                                 <v-btn tile dark style="font-size:12px;background-color:#23B1A9" class=" px-8 text-capitalize" small @click="deletePlan(plnIdfrDlte)">
                                    <v-icon color="" small >check_circle</v-icon>
                                    <v-divider vertical class="ml-2 mr-2 text-capitalize"></v-divider>OK
                                </v-btn>
                            </v-layout>
                            
                            
                        </v-card>
            </v-dialog>
        </div>
    </v-app>
</template>

<script>
  import moment from 'moment';
  import axios from "axios";

export default {

    components:{
        
    },

    created() {

        this.$store.commit("SAVE_PAGE_HEADER", "Create Edit Plan");
        this.token=this.$store.getters.successToken
        console.log( "Policy ID",this.$store.getters.fetchPlcyFrPrdctBtnName.plcy_id)
        this.incmg_PlcyId=this.$store.getters.fetchPlcyFrPrdctBtnName.plcy_id
        this.getgrpolicybyID(this.$store.getters.fetchPlcyFrPrdctBtnName.plcy_id)

        this.getGrpPlnDetails(this.page,this.incmg_PlcyId)

    },
    filters:{

        truncate: function (text, length, suffix) {
            if (text.length > length) {
                return text.substring(0, length) + suffix;
            } else {
                return text;
            }
        },
        formatDate(data){// eslint-disable-line no-mixed-spaces-and-tabs
           // console.log('Validity Data', data);

            if (data == "" || data == "undefined" || data == null || data == '' || data == 'Invalid Date') {
               return "-"
           } else {
            //    var n = new Date(data);
            //    return n.toLocaleString("en-IN")
            return moment(data,'YYYY-MM-DD').format('DD-MM-YYYY')

           }
        },
    },

   computed: {
      cmputdPlcyEfctveDteFrmtdMmntjs () {
        return this.policyEffectiveDate ? moment(this.policyEffectiveDate).format('DD/MM/YYYY') : ''
      },
        cmputdPlcyExpryDteFrmtdMmntjs () {
        return this.policyExpiryDate ? moment(this.policyExpiryDate).format('DD/MM/YYYY') : ''
      },
      stsChng(){
            
            // let fromeDate = moment(this.productEffectFromdate,'YYYY-MM-DD').format('DD-MM-YYYY')
            // let todayDate = Date.now();
            // let Todate= moment(this.policyExpiryDate,'YYYY-MM-DD ').format('DD-MM-YYYY')
            // let CurrentDate= moment(todayDate).format("DD-MM-YYYY");
            // console.log(Todate,CurrentDate,"Todate,CurrentDate")

            //  let fromeDate = moment(this.crtEdtPdt.pdtEfftvDteFrm,'YYYY-MM-DD').toDate().getTime()
            let todayDate = Date.now();
            let Todate= moment(this.policyExpiryDate,'YYYY-MM-DD ').toDate().getTime()
            console.log(todayDate,"todayDate")
            let CurrentDate= todayDate

            if(Todate < CurrentDate){
                return this.policyStatus="Expired"
            }else{
                return this.policyStatus="Active"
            }
        }

    },

    data() {
        return{
        

            pdtStatus:["Active", "InActive"],
            selected:[],
            itemsPerPage: 10,
            totalCount:0,
            page: 1,
            crntPgNo:0,
            pageCount: 4,
            token:null,
            fromvalue:'',
            toValu:'',
            policyId:"",
            headers:[
                
                {text: 'Plan Name', value: 'planName',sortable: false,divider:true,class:"hdr-txt-clr-cepdt"},
                {text: 'Plan Description', value: 'planDescription',sortable: false,divider:true,class:"hdr-txt-clr-cepdt"},
                {text: 'Plan Effective Date', value: 'planEffectiveDate',sortable: false,divider:true,class:"hdr-txt-clr-cepdt"},
                {text: 'Plan Expiry Date', value: 'planExpiryDate',sortable: false,divider:true,class:"hdr-txt-clr-cepdt"},
                {text: 'Plan Status', value: 'planstatus',sortable: false,divider:true,class:"hdr-txt-clr-cepdt"},
                {text: 'Policy Type', value: 'policyType',sortable: false,divider:true,class:"hdr-txt-clr-cepdt"},
                {text: 'External Reference No.', value: 'externalRefNo',sortable: false,divider:true,class:"hdr-txt-clr-cepdt"},
                {text: 'Action', value: 'actions',sortable: false,divider:true,class:"hdr-txt-clr-cepdt hdr-bg-clr-cepdt", width:"55px"}

            ],

            chkr_items: [
                { title: 'Delete', icon:'delete',color:"#646666"},
                { title: 'Edit', icon:'check_circle',color:"#1E9690" },
                { title: 'Configure' , icon:'settings',color:"#D1121B"},
                
            ],
            planforPlcyData:[],
            GetBnkMstrHstryDta:[
                {
                    PlanName:"MEDC",
                    PlanDescription:"Medicare - Plan A",
                    PlanEffectiveDate:"13/1/2019",
                    PlanExpiryDate:"13/1/2020",
                    PlanStatus:"Active",
                    PolicyType:"Retail Health",
                    ExternalRefrenceNo:"- - - - - -"
                },{
                    PlanName:"MEDC",
                    PlanDescription:"Medicare - Plan A",
                    PlanEffectiveDate:"13/1/2019",
                    PlanExpiryDate:"13/1/2020",
                    PlanStatus:"Active",
                    PolicyType:"Retail Health",
                    ExternalRefrenceNo:"- - - - - -"
                },{
                    PlanName:"MEDC",
                    PlanDescription:"Medicare - Plan A",
                    PlanEffectiveDate:"13/1/2019",
                    PlanExpiryDate:"13/1/2020",
                    PlanStatus:"Active",
                    PolicyType:"Retail Health",
                    ExternalRefrenceNo:"- - - - - -"
                },{
                    PlanName:"MEDC",
                    PlanDescription:"Medicare - Plan A",
                    PlanEffectiveDate:"13/1/2019",
                    PlanExpiryDate:"13/1/2020",
                    PlanStatus:"Active",
                    PolicyType:"Retail Health",
                    ExternalRefrenceNo:"- - - - - -"
                },{
                    PlanName:"MEDC",
                    PlanDescription:"Medicare - Plan A",
                    PlanEffectiveDate:"13/1/2019",
                    PlanExpiryDate:"13/1/2020",
                    PlanStatus:"Active",
                    PolicyType:"Retail Health",
                    ExternalRefrenceNo:"- - - - - -"
                },{
                    PlanName:"MEDC",
                    PlanDescription:"Medicare - Plan A",
                    PlanEffectiveDate:"13/1/2019",
                    PlanExpiryDate:"13/1/2020",
                    PlanStatus:"Active",
                    PolicyType:"Retail Health",
                    ExternalRefrenceNo:"- - - - - -"
                },{
                    PlanName:"MEDC",
                    PlanDescription:"Medicare - Plan A",
                    PlanEffectiveDate:"13/1/2019",
                    PlanExpiryDate:"13/1/2020",
                    PlanStatus:"Active",
                    PolicyType:"Retail Health",
                    ExternalRefrenceNo:"- - - - - -"
                },{
                    PlanName:"MEDC",
                    PlanDescription:"Medicare - Plan A",
                    PlanEffectiveDate:"13/1/2019",
                    PlanExpiryDate:"13/1/2020",
                    PlanStatus:"Active",
                    PolicyType:"Retail Health",
                    ExternalRefrenceNo:"- - - - - -"
                },{
                    PlanName:"MEDC",
                    PlanDescription:"Medicare - Plan A",
                    PlanEffectiveDate:"13/1/2019",
                    PlanExpiryDate:"13/1/2020",
                    PlanStatus:"Active",
                    PolicyType:"Retail Health",
                    ExternalRefrenceNo:"- - - - - -"
                },{
                    PlanName:"MEDC",
                    PlanDescription:"Medicare - Plan A",
                    PlanEffectiveDate:"13/1/2019",
                    PlanExpiryDate:"13/1/2020",
                    PlanStatus:"Active",
                    PolicyType:"Retail Health",
                    ExternalRefrenceNo:"- - - - - -"
                },
                
            ],

            policyEffectiveDate:"",
            policyNumber:'',
            corporateGroupName:"",
            policyStatus:"",
            policyExpiryDate:"",
            PlcyEfctveDtecalender:false,
            PlcyExpryDtecalender:false,
            productCode:"",
            productId:"",
            productDescription:"",
            dlteDilg:false,
            plnIdfrDlte:"",
            

            

        }




    },

    methods: {

        cnfgPlanfrPlcy(){
            console.log("self.policyId", this.policyId)
            console.log("self.policyId", this.policyNumber)
             let btnName={
                text:'CrteNwPlnFrPlcy',
                visible:true,
                plcy_Id:this.policyId,
                plcy_No:this.policyNumber,
                plan_No:null,
                plan_Typ:"group",
                retailPlan_Id:null,
                retailPlan_productCode:null,
                retailPlan_productDescription:null,
                retailPlan_productId:null
            }

            

            this.$store.commit('SAVE_PLCYID_FOR_PLAN', btnName)
            this.$router.push('/product/productconfiguration');
        },
        chkr_seltd(itm,item){
            console.log("menu item clciked",itm);
            console.log("item clciked",item);
            if(itm.title=="Edit"){
                console.log("item clciked Edit",item);
                let btnName={
                text:'EditPlnFrPlcy',
                visible:true,
                plcy_Id:item.policyId,
                plcy_No:item.policyNumber,
                plan_No:item._id,
                plan_Typ:"group",
                retailPlan_Id:null,
                 retailPlan_productCode:null,
                retailPlan_productDescription:null,
                retailPlan_productId:null
            }
            this.$store.commit('SAVE_PLCYID_FOR_PLAN', btnName)
            this.$router.push('/product/productconfiguration');

            }else if(itm.title=="Configure"){
                console.log("item clciked Configure",item);
                if(item.planName===""||item.planName===null||item.planName===undefined){
                    this.showToast("Please Add Plan Details First",this.TOST().WARNING);
                    return
                }
                let btnName={
                text:'ConfgrPlnFrPlcy',
                visible:true,
                plcy_Id:item.policyId,
                plcy_No:item.policyNumber,
                plan_No:item._id,
                plan_Typ:"group",
                retailPlan_Id:null, 
                retailPlan_productCode:null,
                retailPlan_productDescription:null,
                retailPlan_productId:null
            }
            this.$store.commit('SAVE_PLCYID_FOR_PLAN', btnName)
            this.$router.push('/product/productconfiguration');
                //  this.$router.push('/product/productconfiguration');
            }else if(itm.title=="Delete"){
                console.log("item clciked Delete",item);
                console.log("item clciked",item);
                this.dlteDilg=true
                this.plnIdfrDlte=item._id
                // this.deletePlan(item._id)
            }
            
        },
        deletePlan(planId){
            this.dlteDilg=false
            console.log(planId,"incoming planId")
            let self=this
               axios({
                    method: "delete",
                    url:
                        self.API_Service_admin()+"group-plan/delete-group-plan/"+planId, 
                        headers: {
                            "Content-Type": "application/json", 
                            "x-access-token": self.token,
                        }
                })
                .then(function(response) {
                    console.log('roup-plan/delete-group-plan------->>>',response.data.data);
                    self.showToast("Plan has been deleted successfully" ,self.TOST().SUCCESS)
                    self.page=1
                    self.getGrpPlnDetails(self.page,this.incmg_PlcyId)
                    
                })
                .catch(function(error) {
                    console.log(error);
                    // self.showToast(error.response.data.msg,self.TOST().ERROR);
                });


        },
        nextPageData(pgNo){
            console.log(pgNo,"=======>pgNo")
            console.log(this.crntPgNo,"=======>crntPgNo")
            if(this.crntPgNo===pgNo){
                console.log("Current page data already showing")
                return
            }
            this.getGrpPlnDetails(pgNo,this.incmg_PlcyId)
        },

        getgrpolicybyID(id){
            console.log("Policy ID",id)
            let self = this;   
                axios({
                    method: "get",
                    url:
                        self.API_Service_admin()+"group-policy/get-group-policy/"+id, 
                        headers: {
                            "Content-Type": "application/json", 
                            "x-access-token": self.token,
                        }
                })
                .then(function(response) {
                    console.log('Policy Details for Edit plan for policy------->>>',response.data.data);
                    let tempPlcyDta=response.data.data
                    self.policyNumber=tempPlcyDta.hasOwnProperty('policyNumber')?self.chkplcydata(tempPlcyDta.policyNumber):""
                    self.corporateGroupName=tempPlcyDta.hasOwnProperty('corporateGroupName')?self.chkplcydata(tempPlcyDta.corporateGroupName):""
                    self.policyEffectiveDate=tempPlcyDta.hasOwnProperty('policyEffectiveDate')?self.chkplcydata(tempPlcyDta.policyEffectiveDate):""
                    self.policyExpiryDate=tempPlcyDta.hasOwnProperty('policyExpiryDate')?self.chkplcydata(tempPlcyDta.policyExpiryDate):""
                    self.policyId=tempPlcyDta.hasOwnProperty('_id')?self.chkplcydata(tempPlcyDta._id):""
                    self.productCode=tempPlcyDta.hasOwnProperty('productCode')?self.chkplcydata(tempPlcyDta.productCode):""
                    self.productId=tempPlcyDta.hasOwnProperty('productId')?self.chkplcydata(tempPlcyDta.productId):""
                    self.productDescription=tempPlcyDta.hasOwnProperty('productDescription')?self.chkplcydata(tempPlcyDta.productDescription):""
                })
                .catch(function(error) {
                    console.log(error);
                    // self.showToast(error.response.data.msg,self.TOST().ERROR);
                });
        },

        chkplcydata(data){
            console.log("Policy sara",data); 
            try{
                if (data ==="" || data === "undefined" || data === null) {
                    return ""
                } else {
                    return data;
                }

             }catch(err) {
                console.log(err)
            }
            
        },

        getGrpPlnDetails(pgNo,plcyId){

            let self = this;   
            self.planforPlcyData=[]
                axios({
                    method: "get",
                    url:
                        self.API_Service_admin()+"group-plan/group-plan-all?perPage="+self.itemsPerPage+"&pageNo="+pgNo+"&policyId="+plcyId, 
                        headers: {
                            "Content-Type": "application/json", 
                            "x-access-token": self.token,
                        }
                })
                .then(function(response) {
                    console.log('Group paln Details------->>>',response.data.data.data);
                    // self.planforPlcyData=response.data.data.data
                    if(!response.data.data.data || response.data.data.data.length == 0){
                        console.log("Array is either empty or does not exist")
                        // self.showToast("No Data Found",self.TOST().WARNING);
                        return
                    }
                    let apiData=[]
                    apiData=response.data.data.data
                    self.pageCount=response.data.data.total_pages
                    self.totalCount=response.data.data.total
                     for (let i = 0; i < apiData.length; i++){
                        let status= self.validDateChk(apiData[i].planExpiryDate )
                        console.log(status)
                        let productdata = {
                            externalRefNo:apiData[i].externalRefNo,
                            planDescription:apiData[i].planDescription,
                            planEffectiveDate:apiData[i].planEffectiveDate,
                            planExpiryDate:apiData[i].planExpiryDate,
                            planName: apiData[i].planName,
                            planstatus: status==="Active"?"Active":"Inactive",
                            policyId:apiData[i].policyId,
                            policyNumber:apiData[i].policyNumber,
                            policyType:apiData[i].policyType,
                            updatedAt:apiData[i].updatedAt,
                            createdAt:apiData[i].createdAt,
                            __v:apiData[i].__v,
                            _id:apiData[i]._id

                        }
                        self.planforPlcyData.push(productdata);
                    }
                    self.crntPgNo=pgNo
                    console.log( self.crntPgNo,"=======> self.crntPgNo")

                    const start = pgNo * self.itemsPerPage - self.itemsPerPage,
                    end = start + self.itemsPerPage;
                    console.log(start,end,"start End")
                     if(self.totalCount===0){
                            self.fromvalue=0
                        }else{
                            self.fromvalue=start+1;
                        }
                        
                        if(self.totalCount <self.itemsPerPage){
                            self.toValu=self.totalCount
                        }else if(self.totalCount<end){
                            self.toValu=self.totalCount
                        }else{
                            self.toValu=end
                        }
                })
                .catch(function(error) {
                    console.log(error);
                    // self.showToast(error.response.data.msg,self.TOST().ERROR);
                });

        },
        validDateChk(todte){
                // console.log(todte,"incoming date")
            if(todte===null||todte===undefined|| todte===''){
                    return "Inactive"
            }else{

                // let d = new Date();
                
                // let Todate= moment(todte,'YYYY-MM-DD').format('DD-MM-YYYY')
                // let CurrentDate= moment(d,'YYYY-MM-DD').format("DD-MM-YYYY");
                // console.log(Todate,CurrentDate,"Todate,CurrentDate")

                let d = Date.now();
                
                let Todate= moment(todte,'YYYY-MM-DD').toDate().getTime()
                let CurrentDate= d
                if(Todate===null||Todate===undefined|| Todate===''||Todate==="Invalid" ){
                    return "Inactive"
                }else if(Todate < CurrentDate ){
                    return "Inactive"
                }else if (Todate>CurrentDate){
                    return "Active"
                }


            }
        },
        close_dltePlan(){
            this.dlteDilg=false

        },

        sendForApprvl(){

            console.log("selected", this.selected)
            console.log("self.productCode,self.productId", this.productCode, this.productId)

            if(this.selected.length===0||!this.selected.length){
                this.showToast("Please Select Plan/Plans First",this.TOST().WARNING);
                return
            }
            for(let i=0;i<this.selected.length;i++){
                console.log("loop", i)
                 if(this.selected[i].planName===""||this.selected[i].planName===null||this.selected[i].planName===undefined){
                    this.showToast("Please Add Plan Details First",this.TOST().WARNING);
                    return
                }
            }
            let self=this
            let wrkItemDta = [];
            self.selected.map((itm) => {
                wrkItemDta.push({
                   externalRefNo:itm.externalRefNo,
                   planName:itm.planName,
                   planDescription:itm.planDescription,
                   code:self.productCode,
                   docId:self.productId,
                   productId:self.productId,
                    productCode:self.productCode,
                    planId:itm._id,
                    productDescription:self.productDescription,
                    policyNumber:itm.policyNumber,
                    productType:"group-plan"

                });
            });

            console.log("wrkItemDta",wrkItemDta)
            axios({
                method: "post",
                url:
                    self.API_Service_TASK_MGMNT()+"work-items/multiple-create",
                headers: {
                    "Content-Type": "application/json",
                    "x-access-token": self.token
                    // "x-access-token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI1ZmJlMDg4ZGM0NmRhODNlZTdmODcxYmIiLCJyb2xlIjoiQWRtaW5Vc2VyIiwiaWF0IjoxNjA2MzY5MzI0LCJleHAiOjE2Mzc5MDUzMjR9.iS5hZs0uImNFz-rxNaQ7CTCc6qS_hG4H0FsePZ5Sgqc",
                },
                data:wrkItemDta,
            })
            .then(function (response) {
                console.log(" work-items/multiple-create API Response>>>>>>", response);
                 self.showToast(response.data.msg ,self.TOST().SUCCESS)
                self.selected=[]
            })
            .catch(function (error) {
                console.log(" work-items/multiple-create Error APi Response>>>>>>", error);
                // self.showToast(error.response.data.msg,self.TOST().ERROR);

            });

        },
        
        


    },

   

    watch: {
    
    },
    
    
}

</script>
<style >

.cust-rmvpd-psins2 .v-text-field.v-text-field--enclosed>.v-input__control>.v-input__slot {
    padding-right: 0px !important;
}

.cust_cepfp .v-text-field .v-input__slot,
.v-text-field--outline .v-input__slot {
    min-height: 42px !important;
    display: flex !important;
    align-items: center !important;
    /* border:1px red solid; */
}

.cstm-clr-cepfp{
    color:#646666 !important;
    margin-bottom: 5px !important;
    font-size: 13px !important;
}


.cust_cepfp .v-text-field .v-input__slot,
.v-text-field--outline .v-input__slot {
    min-height: 42px !important;
    display: flex !important;
    align-items: center !important;
    /* border:1px red solid; */
}

.cust-rmvpd-cepfp .v-text-field.v-text-field--enclosed>.v-input__control>.v-input__slot {
    padding: 0px !important;;
}

.cstm-clr-cepdt{
    color:#646666 !important;
    margin-bottom: 5px !important;
    font-size: 13px !important;
}

.cust_cepfp .v-input .v-label {
    font-size: 13px !important;
}
.cust_cepfp .v-input {
    font-size: 13px !important;
}
.cust_cepfp .v-input input {
    font-size: 13px !important;
}

.title-clr-pr{
    color:#10242B;
    margin-bottom: 5px;
}

.lbl-bgclr{
    color:#152F38 !important;

}
.hdr-txt-clr-cepdt{
    color:#10242B !important;
    padding:0px!important;
    padding-left: 14px !important;
}

.hdr-bg-clr-cepdt{
    background-color: #f7f7f7!important;
}
/* .pdct-stUp-tbl table.v-table thead th{
    border-right:1px solid  #C1C8CC !important;
    background-color:#F3FBFB !important;
    color:#10242B !important;
    font-size:13px !important;
    font-family:Roboto !important;
    font-weight:700 !important;
    padding: 0px!important;
    padding-left: 9px !important;
} */
/* .pdct-stUp-tbl table.v-table thead th:last-child{
    border-right: unset !important;
    background-color:#F7F7F7 !important;
} */
/* .pdct-stUp-tbl table.v-table tbody td,
.pdct-stUp-tbl table.v-table thead tr{
    height: 40px !important;
    padding-left: 12px !important;
}
.pdct-stUp-tbl table.v-table thead tr:last-child{
    height: unset !important;
    
} */

.cust-tbl-cepdt .v-data-table > .v-data-table__wrapper > table > tbody > tr > td:last-child{
     background-color: #f7f7f7 !important;
}

.pdct-stUp-tbl-brdr{
    border:1px solid  #C1C8CC;
}

.pdct-stUp-td-bdrgt {
    border-right:1px solid #C1C8CC;
 }

 .pdct-stUp-bnkAcc-pgn .v-pagination__navigation{
        box-shadow:unset !important;
        border-radius: unset !important;
        background-color:#FFFFFF !important;
        border:1px solid #DAE1E6 !important;
        height: 30px !important;
        width: 30px !important;
    }
    .pdct-stUp-bnkAcc-pgn .v-pagination__navigation--disabled{
        background-color:#DAE1E6 !important;
    }
    .pdct-stUp-bnkAcc-pgn .v-pagination__item{
        box-shadow:unset !important;
        min-width:1.8rem !important;
        height:1.8rem !important;
        border-radius: unset !important;
        border:1px solid #DAE1E6 !important
    }

    .pdct-stUp-tbl .v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
    border: 1px solid  #C1C8CC !important;
}

.pdct-stUp-tbl .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
    border: 1px solid #C1C8CC !important;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
    font-size: 0.84rem !important;
}

.custPlanFrPlcy .theme--light.v-text-field--solo > .v-input__control > .v-input__slot {
    background: #F7F7F7;
}

</style>