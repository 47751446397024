<template>
  <v-flex>
    <v-flex class="flexStyle">
      <v-row class="rowHeadDeatils">
        <v-flex>
          <div class="font-weight-bold">Policy Number</div>
          <div>{{ PolicyDeatils.proposalDtls.policyNumber }}</div>
        </v-flex>
        <v-flex>
          <div class="font-weight-bold">Certificate Number</div>
          <div>{{ PolicyDeatils.proposalDtls.certificateNumber }}</div>
        </v-flex>
        <v-flex>
          <div class="font-weight-bold">Branch Office Code & Text</div>
          <div>{{ PolicyDeatils.proposalDtls.txtBranchOfficeCode == undefined ? "" : (PolicyDeatils.proposalDtls.txtBranchOfficeCode  +"-"+ PolicyDeatils.proposalDtls.txtOfficeName)}} </div>
        </v-flex>
        <v-flex>
          <div class="font-weight-bold">Sub-Branch Office Code & Text</div>
          <div>{{ PolicyDeatils.locationDtls.subBranchOfficeCode == undefined ? "" : (PolicyDeatils.locationDtls.subBranchOfficeCode +"-"+ PolicyDeatils.locationDtls.subBranchOfficeName) }}</div>
        </v-flex>
        <v-flex>
          <div class="font-weight-bold">Priority Customer</div>
          <div>{{ PolicyDeatils.proposalDtls.priorityCustomer }}</div>
        </v-flex>
        <v-flex>
          <div class="font-weight-bold">Period of Insurance</div>
          <div>{{ PolicyDeatils.proposalDtls.periodOfInsurance }}</div>
        </v-flex>
      </v-row>
      <div class="horizontal_line"></div>
      <v-row class="rowHeadDeatils">
        <v-flex>
          <div class="font-weight-bold">Policy Start/Inception Date</div>
          <div>
            {{ ISOtoDDMMYY(PolicyDeatils.proposalDtls.policyStartInceptionDate) }}
          </div>
        </v-flex>
        <v-flex>
          <div class="font-weight-bold">Policy Start Time</div>
          <div>{{ PolicyDeatils.proposalDtls.policyStartTime }}</div>
        </v-flex>
        <v-flex>
          <div class="font-weight-bold">Policy Expiry Date</div>
          <div>
            {{ ISOtoDDMMYY(PolicyDeatils.proposalDtls.policyExpiryDate) }}
          </div>
        </v-flex>
        <v-flex>
          <div class="font-weight-bold">Policy Expiry Time</div>
          <div>{{ PolicyDeatils.proposalDtls.policyExpiryTime }}</div>
        </v-flex>
        <v-flex>
          <div class="font-weight-bold">Policy Status</div>
          <div>{{ PolicyDeatils.proposalDtls.statusOfPolicy }}</div>
        </v-flex>
        <v-flex>
          <div class="font-weight-bold">Product</div>
          <div>{{PolicyDeatils.proposalDtls.txtProductName}}</div>
        </v-flex>
      </v-row>
      <div class="horizontal_line"></div>
      <v-row class="rowHeadDeatils">
        <v-flex>
          <div class="font-weight-bold">Sub Product</div>
          <div></div>
        </v-flex>
        <v-flex>
          <div class="font-weight-bold">Insured Name</div>
          <div class="textOverlap">{{ PolicyDeatils.proposalDtls.insuredName }}</div>
        </v-flex>
        <v-flex>
          <div class="font-weight-bold">Address Line 1</div>
          <div class="textOverlap">{{ PolicyDeatils.proposalDtls.addressLine1 }}</div>
        </v-flex>
        <v-flex>
          <div class="font-weight-bold">Address Line 2</div>
          <div class="textOverlap">{{ PolicyDeatils.proposalDtls.addressLine2 }}</div>
        </v-flex>
        <v-flex>
          <div class="font-weight-bold">Address Line 3</div>
          <div class="textOverlap">{{ PolicyDeatils.proposalDtls.addressLine3 }}</div>
        </v-flex>
        <v-flex>
          <div class="font-weight-bold">Landmark</div>
          <div class="textOverlap"></div>
        </v-flex>
      </v-row>
      <div class="horizontal_line"></div>
      <v-row class="rowHeadDeatils">
        <v-flex>
          <div class="font-weight-bold">City/Tehsil/Taluka/Mandal/Block</div>
          <div class="textOverlap">{{ PolicyDeatils.proposalDtls.cityDistrict }}</div>
        </v-flex>
        <v-flex>
          <div class="font-weight-bold">District</div>
          <div class="textOverlap">{{ PolicyDeatils.proposalDtls.cityDistrict }}</div>
        </v-flex>
        <v-flex>
          <div class="font-weight-bold">State</div>
          <div class="textOverlap">{{ PolicyDeatils.proposalDtls.state }}</div>
        </v-flex>
        <v-flex>
          <div class="font-weight-bold">Pin Code/Post Office</div>
          <div>{{ PolicyDeatils.proposalDtls.pincode }}</div>
        </v-flex>
        <v-flex>
          <div class="font-weight-bold">Country</div>
          <div class="textOverlap">{{ PolicyDeatils.proposalDtls.countryName }}</div>
        </v-flex>
        <v-flex>
          <div class="font-weight-bold">Policy Issuance Office</div>
          <div class="textOverlap">{{ PolicyDeatils.proposalDtls.policyIssuanceOffice }}</div>
        </v-flex>
      </v-row>
      <div class="horizontal_line"></div>
      <v-row class="rowHeadDeatils">
        <v-flex>
          <div class="font-weight-bold">Business Type</div>
          <div>{{ PolicyDeatils.proposalDtls.txtBusinessType }}</div>
        </v-flex>
        <v-flex>
          <div class="font-weight-bold">Currency</div>
          <div>{{ PolicyDeatils.proposalDtls.currencyType }}</div>
        </v-flex>
        <v-flex>
          <div class="font-weight-bold">Major Line Code & Text</div>
          <div>{{ PolicyDeatils.proposalDtls.MajorLineCdName }}</div>
        </v-flex>
        <v-flex>
          <div class="font-weight-bold">AIG Multinational Treaty</div>
          <div class="textOverlap">{{PolicyDeatils.proposalDtls.txtActiveFlag}}</div>
        </v-flex>
        <v-flex>
          <div class="font-weight-bold">FAC Reinsurance Claim Control</div>
          <div class="textOverlap">{{ PolicyDeatils.proposalDtls.facriControl }}</div>
        </v-flex>
        <v-flex>
          <div class="font-weight-bold">Interest Insured</div>
          <div class="textOverlap">{{ PolicyDeatils.proposalDtls.interestInsured  }}</div>
        </v-flex>
      </v-row>
      <div class="horizontal_line"></div>
    </v-flex>
    <!-- Hypothecator Details code -->
    <v-flex>
      <v-row class="hyperRowHead mt-0">
        <v-col align="left">
          <v-flex class="ml-2 lableFontSize">Hypothecator Details</v-flex>
        </v-col>
        <v-col align="right" class="pt-2">
          <v-icon
            class="mr-2 fontSizeCls"
            :color="hypothecatorMenu == true ? '#E46A25' : '#23B1A9'"
            dark
            @click="pluseMinusHypothecator">
            {{ hypothecatorMenu == true ? "mdi-minus-box" : "mdi-plus-box" }}
          </v-icon>
        </v-col>
      </v-row>
    </v-flex>
    <v-flex class="mt-6 ml-0" v-if="hypothecatorMenu">
      <v-simple-table class="ml-6 mt-7 mb-6 tableStyle" height="200">
        <template v-slot:default>
          <thead class="tableBackGroundColor">
            <tr>
              <th
                class="text-left"
                v-for="item in tableHeadOptions"
                :key="item">
                {{ item }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in PolicyDeatils.financierDtls"
              :key="index"
              @click="SelectedRow(item, index)"
              class="trClass">
              <td>{{ index + 1 }}</td>
              <td>{{ item.hypothicatorCode }}</td>
              <td>{{ item.hypothicatorName }}</td>
              <td>{{ item.hypothicatorAddress }}</td>
            </tr>
             <tr class="v-data-table__empty-wrapper" v-if="PolicyDeatils.financierDtls.length==0">
               <td colspan="9">No data available</td>
             </tr>
          </tbody>
        </template>
        
      </v-simple-table>
      
    </v-flex>
    <!-- Producer Details code -->
    <v-flex>
      <v-row class="hyperRowHead">
        <v-col align="left"><v-flex class="ml-2 lableFontSize">Producer Details</v-flex></v-col>
        <v-col align="right" class="pt-2">
          <v-icon
            class="mr-2 fontSizeCls"
            :color="ProductMenu == true ? '#E46A25' : '#23B1A9'"
            dark
            @click="pluseMinusProduct">{{ ProductMenu == true ? "mdi-minus-box" : "mdi-plus-box" }}
          </v-icon>
        </v-col>
      </v-row>
      <v-flex class="mt-4 flexStyle" v-if="ProductMenu">
        <v-row class="rowHeadDeatils">
          <v-flex>
            <div class="font-weight-bold">Producer Code</div>
            <div>{{ PolicyDeatils.proposalDtls.producerCode }}</div>
          </v-flex>
          <v-flex>
            <div class="font-weight-bold">Producer Name</div>
            <div class="textOverlap">{{ PolicyDeatils.proposalDtls.producerName }}</div>
          </v-flex>
          <v-flex>
            <div class="font-weight-bold">Landline Number</div>
            <div></div>
          </v-flex>
          <v-flex class="mr-3">
            <div class="font-weight-bold">Email Id</div>
            <div class="textOverlap">{{ PolicyDeatils.proposalDtls.producerEmailId }}</div>
          </v-flex>
          <v-flex>
            <div class="font-weight-bold">Mobile Number</div>
            <div >{{ PolicyDeatils.proposalDtls.producerMobileNumber }}</div>
          </v-flex>
          <v-flex>
            <div class="font-weight-bold">Office Number</div>
            <div></div>
          </v-flex>
        </v-row>
        <div class="horizontal_line"></div>
        <v-row class="rowHeadDeatils">
          <v-flex>
            <div class="font-weight-bold">Address</div>
            <div class="textOverlap">{{ PolicyDeatils.proposalDtls.producerAddress }}</div>
          </v-flex>
        </v-row>
      </v-flex>
    </v-flex>
    <!-- CBC Details code -->
    <v-flex>
      <v-row class="hyperRowHead">
        <v-col align="left">
          <v-flex class="ml-2 lableFontSize">CBC Details</v-flex>
        </v-col>
        <v-col align="right" class="pt-2">
          <v-icon
            class="mr-2 fontSizeCls"
            :color="CBCMenu == true ? '#E46A25' : '#23B1A9'"
            dark
            @click="pluseMinusCBC">
            {{ CBCMenu == true ? "mdi-minus-box" : "mdi-plus-box" }}
          </v-icon>
        </v-col>
      </v-row>
      <v-flex
        class="mt-4 flexStyle"
        v-if="CBCMenu">
        <v-row class="rowHeadDeatils">
          <v-flex>
            <div class="font-weight-bold">Receipt Number</div>
            <div>{{ PolicyDeatils.receiptDtls.receiptNumber }}</div>
          </v-flex>
          <v-flex>
            <div class="font-weight-bold">Receipt Date</div>
            <div>{{  ISOtoDDMMYY(PolicyDeatils.receiptDtls.receiptDate) }}</div>
          </v-flex>
          <v-flex>
            <div class="font-weight-bold">Collection Mode</div>
            <div>{{ PolicyDeatils.receiptDtls.collectionMode }}</div>
          </v-flex>
          <v-flex>
            <div class="font-weight-bold">Premium Amount</div>
            <div>{{ PolicyDeatils.proposalDtls.premiumAmount }}</div>
          </v-flex>
          <v-flex>
            <div class="font-weight-bold">Collection Status</div>
            <div>{{ PolicyDeatils.receiptDtls.collectionStatus }}</div>
          </v-flex>
          <v-flex>
            <div class="font-weight-bold">Instrument Number</div>
            <div>{{ PolicyDeatils.receiptDtls.instrumentNumber }}</div>
          </v-flex>
        </v-row>
        <div class="horizontal_line"></div>

        <v-row class="rowHeadDeatils">
          <v-flex>
            <div class="font-weight-bold">Drawn On</div>
            <div>{{ PolicyDeatils.receiptDtls.drawnOn }}</div>
          </v-flex>
          <v-flex>
            <div class="font-weight-bold">Branch Name</div>
            <div class="textOverlap">{{ PolicyDeatils.receiptDtls.branchName }}</div>
          </v-flex>
          <v-flex>
            <div class="font-weight-bold">Date Of Realization</div>
            <div>
              {{ ISOtoDDMMYY(PolicyDeatils.receiptDtls.dateOfRealization) }}
            </div>
          </v-flex>
          <v-flex>
            <div class="font-weight-bold">Status Of Policy</div>
            <div>{{ PolicyDeatils.proposalDtls.statusOfPolicy }}</div>
          </v-flex>
          <v-flex>
            <div class="font-weight-bold">Cheque Received Date At TAGIC</div>
            <div>
              {{ ISOtoDDMMYY(PolicyDeatils.receiptDtls.chequeReceivedDateTagic) }}
            </div>
          </v-flex>
          <v-flex>
            <div class="font-weight-bold"></div>
            <div></div>
          </v-flex>
        </v-row>
      </v-flex>
    </v-flex>
    <!-- Document Details code -->
    <v-flex>
      <v-row class="hyperRowHead">
        <v-col align="left">
          <v-flex class="ml-2 lableFontSize">Document Details</v-flex>
        </v-col>
        <v-col align="right" class="pt-2">
          <v-icon
            class="mr-2 fontSizeCls"
            
            :color="DocumentMenu == true ? '#E46A25' : '#23B1A9'"
            dark
            @click="pluseMinusDocument">
            {{ DocumentMenu == true ? "mdi-minus-box" : "mdi-plus-box" }}
          </v-icon>
        </v-col>
      </v-row>
      <v-flex
        class="mt-4 flexStyle"
        v-if="DocumentMenu">
        <v-row class="rowHeadDeatils">
          <!-- <v-flex v-for="(item, index) in DocumentsArray" :key="index">
            <div class="documentUnderLine">{{ item }}</div>
          </v-flex> -->
          <v-flex style="width: 12%">
            <div class="documentUnderLine" @click="policySchedule">
              Policy Schedule
            </div>
          </v-flex>
          <v-flex style="width: 15%">
            <div class="documentUnderLine" @click="endorsementSchedule">
              Endorsement Schedule
            </div>
          </v-flex>
          <v-flex style="width: 12%">
            <div class="documentUnderLine" @click="policyCancellationSchedule">
              Policy Cancellation Letter
            </div>
          </v-flex>
          <v-flex> </v-flex>
          <v-flex> </v-flex>
          <v-flex> </v-flex>
        </v-row>
      </v-flex>
    </v-flex>
    <v-flex
      justify="end"
      class="mt-5"
      style="background-color: #f7f7f7; padding: 20px; margin-bottom: 48px"
    >
      <v-row class="btnDiv">
        <v-col align="right">
          <v-btn
            color="#152F38"
            dark
            class="buttonscolor mr-5"
            @click="goBack"
          >
            <v-icon class="mr-2" style="font-size: 16px">
              mdi-arrow-left-circle
            </v-icon>
            <div class="vertical_line"></div>
            Back
          </v-btn>
        </v-col>
      </v-row>
    </v-flex>
  </v-flex>
</template>

<script>
import moment from 'moment'
export default {
  data() {
    return {
      fnolClaimDetails:{},
      hypothecatorMenu: false,
      ProductMenu: false,
      CBCMenu: false,
      DocumentMenu: false,
      PolicyDeatils: {
        proposalDtls:{},
        receiptDtls: {},
        marineDtls: {},
        locationDtls: {},
        financierDtls: [], 
        storageDetails: [{}],
        coinsuranceDetails: [{}],
        RIDetails: [{}],
        policyFeatchRequest:{}
      },
      tableHeadOptions: [
        "Sr. No.",
        "Hypothecator Code",
        "Hypothecator Name",
        "Hypothecator Address",
      ],
      DocumentsArray: [
        "Policy Schedule",
        "Endorsement Schedule",
        "Policy Cancellation Letter",
      ],
      MasterTableData: [],
    };
  },
  methods: {
    ISOtoDDMMYY(date) {
      if (date) {
        return moment(date).format("DD/MM/YYYY")
      }else{
        return date;
      }
    },
    policySchedule() {
      PolicyDeatils;
    },
    endorsementSchedule() {
      PolicyDeatils;
    },
    policyCancellationSchedule() {
      PolicyDeatils;
    },
    pluseMinusHypothecator() {
      this.hypothecatorMenu = !this.hypothecatorMenu;
    },
    pluseMinusProduct() {
      this.ProductMenu = !this.ProductMenu;
    },
    pluseMinusCBC() {
      this.CBCMenu = !this.CBCMenu;
    },
    pluseMinusDocument() {
      this.DocumentMenu = !this.DocumentMenu;
    },
    goBack(){
      delete this.policyFeatchRequest.proceedSearch;
      this.policyFeatchRequest.claimDetails= this.fnolClaimDetails;
     
    }
  },
  created() {    
    if(Object.keys(this.$route.params).length !==0){
    let requestData = {
      policyNo: this.$route.params.policyNo,
      insuredName: "",
      insuredId: "",
      certificateNo: "",
      applicationNo: "",
      eMarinePolicyNo: "",
      lrNumber: "",
      invoiceNumber: "",
      lossTime:this.$route.params.lossTime=="" ? "": this.$route.params.lossTime,
      dateOfLoss:(this.$route.params.dateOfLoss=="" || this.$route.params.dateOfLoss==undefined) ? "" : this.$route.params.dateOfLoss,
      proceedSearch: true
		  };
      this.policyFeatchRequest=requestData;
      this.fnolClaimDetails=this.$route.params.claimDetails;
       this.showProgress("Data is Fetching please Wait");
      this.POST_ByURLM("GET_POLICYSEARCH",requestData,(res) => {
         console.log(res);
           if(res.data && res.data.data && res.data.data.data && res.data.data.data[0].proposalDtls){
              let finalResponse=res.data.data.data[0]
              this.PolicyDeatils = res.data.data.data[0];
              this.PolicyDeatils.proposalDtls=finalResponse.proposalDtls[0];
              if(finalResponse.receiptDtls.length!==0){
              this.PolicyDeatils.receiptDtls=finalResponse.receiptDtls[0];
              }
              if(finalResponse.locationDtls.length!==0){
              this.PolicyDeatils.locationDtls= finalResponse.locationDtls[0];
              }
              if(finalResponse.marineDtls.length!==0){
              this.PolicyDeatils.marineDtls= finalResponse.marineDtls[0];
              }
              this.PolicyDeatils.financierDtls= finalResponse.financierDtls;
              this.hideProgress();
           }else{
              this.hideProgress();
              this.showAlert(res.data.msg);
           }
      }, 
      (error) => {
        this.hideProgress();
        console.log(error);
          this.showAlert(error.response.data.msg);
      }
    );
    }
  },
};
</script>


<style scoped>
    .tableStyle{
      width: 60%;
    }
    .flexStyle{
      background-color: #f7f7f7; 
      padding: 20px;
    }
    .trClass{
      box-shadow: 0px 2px 2px #00000051;
    }

    .fontSizeCls{
      font-size: 26px
    }

    .textOverlap{
      overflow-wrap: break-word;
    }
    .buttonscolor {
      border-radius: 0 !important;
      height: 30px !important;
      text-transform: capitalize;
      font-size: 15px;
      width: 110px !important;
    }
    .documentUnderLine {
      color: #1a0dab;
      text-decoration: underline;
      font-weight: bold;
      font-size: 15px;
      cursor: pointer;
    }
    .hyperRowHead {
      box-shadow: 0px 2px 2px #00000051;
      border: 2px solid #f7f7f7;
      border-left: none;
      border-right: none;
      margin-top: 16px;
      background-color: white;
      height: 43px;
    }

    .horizontal_line {
      border-bottom: 2px solid #eeeeee;
      height: 14px;
      margin-right: 9px;
    }
    .backgroundTable {
      background-color: #f7f7f7 !important;
    }
    .rowHeadDeatils {
      text-align: left;
      width: 100%;
      font-size: 13px;
      margin-bottom: 13px;
      margin-top: 18px;
    }
    .rowHeadDeatils .flex {
      width: 16%;
    }

    .tableBackGroundColor {
      background-color: #01bbd9;
    }

    th {
      color: white !important;
      font-size: 15px !important;
      height: 35px !important;
      border: 0.5px ridge #f7f7f7;
      border-width: 1px;
      border-left: none;
      border-collapse: collapse;
    }

    td {
      border: 1px solid #f7f7f7;
      border-width: 2px;
      border-left: none;
      border-collapse: collapse;
      height: 35px !important;
      font-size: 15px !important;
      color: #10242b;
      background-color: white !important;
    }
</style>