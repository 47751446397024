<template>
    <v-app>
        
       
            <v-card class="ma-3 " tile >
                
                <!-- <v-card class="" tile style="background-color:#23B1A9;">               -->
                    <v-layout class="pa-2" align-center justify-space-between style="background-color:#23B1A9;" >
                    
                                <div class="">
                                    <v-text class="font-weight-bold "  style="color:#F5F5F5"
                                    >Hi {{logged_in_name}}, Welcome To Claimsdrive!
                                    </v-text>
                                </div>
                    
                                <div class="">
                                    <v-text class=" font-weight-regular h5"  style="color:#F5F5F5;">Pending Work Items : <span class="font-weight-bold ">{{pendingWork}}</span>
                                    </v-text>
                                </div>
                    
                    <!-- <v-flex class="lay">
                            
                                <div class="ml-3 pa-3 " style="direction: ltr;color:#FFFFFF; max-width: 180px; ">
                                    <v-select
                                        :items="WorkItem"
                                        item-text="text"
                                        item-value="value"
                                        v-model="WorkItem"
                                        label="Work Item Bucket"
                                        style="color:#FFFFFF;"
                                        dark
                                        solo
                                        
                                    ></v-select>
                                </div>
                        
                    </v-flex> -->
                            <v-flex xs2 class="text-capitalize "  >

                                    <v-select
                                        hide-details
                                        :items="WI"
                                        item-text="text"
                                        item-value="value"
                                        v-model="workItem"
                                        label="Work Item Bucket"
                                        style=""
                                        dark
                                        solo  
                                        class="rounded-0"
                                        dense 
                                    ></v-select>
                        </v-flex>
                    </v-layout>
                <!-- </v-card> -->


                <!-- <v-flex justify-space-around row wrap style="display:">
                    <div  style="background-color:#23B1A9" class="pa-2 pl-3">
                        <v-text class="font-weight-bold body-2"  style="color:#F5F5F5"
                        >Hi {{logged_in_name}}, Welcome To CLAIMS!
                        </v-text>

                        <v-text class="font-weight-bold body-2"  style="color:#F5F5F5; margin:25%;"
                        >Pending Work Items : {{pendingWork}}

                        </v-text>

                        
                        <v-select
                            :items="WorkItem"
                            item-text="text"
                            item-value="value"
                            v-model="WorkItem"
                            label="Work Item Bucket"
                            dark
                            solo
                            style="width:200px"
            
                        ></v-select>
                        
                    </div>
                </v-flex> -->

                <!-- <v-data-table
                            class="bnkAcc-tbl-brdr ma-0 pa-0"
                            :headers="headers"
                            :items="GetData"
                            v-bind:pagination.sync="pagination"
                            hide-actions
                            hide-default-footer
                            dense
                            item-key="_id"
                            @page-count="pageCount = $event"

                        > -->
                
                
                
                <v-data-table
                    :headers="headers"
                    :items="GetData"
                    :page.sync="page"
                    :items-per-page="itemsPerPage"
                    hide-default-footer
                    dense
                    @page-count="pageCount = $event"
                >
                
                
                <template v-slot:[`item.newdate`]="{ item }">
                    <div >{{ item.newdate | checkdata}}</div>
                </template>
                <template v-slot:[`item.all.itemId`]="{ item }">
                    <div >{{ item.all.itemId| checkdata }}</div>
                </template>
                <template v-slot:[`item.all.name`]="{ item }">
                    <div >{{ item.all.name | checkdata}}</div>
                </template>
                <template v-slot:[`item.newduedate`]="{ item }">
                    <div >{{ item.newduedate | checkdata}}</div>
                </template>
                <template v-slot:[`item.all.pendingFrom`]="{ item }">
                    <div >{{ item.all.pendingFrom | checkdata}}</div>
                </template>
                

                <template v-slot:item.all.itemId="{ item }">
                    <!-- to="/CreateEditProduct" -->
                    <div @click="wrkitmidClick(item.all.docId)" class="text-decoration-underline" style="color:#1976d2;cursor:pointer">{{ item.all.itemId | checkdata }}</div>
                </template> 
                    <!-- <template v-slot:item.Action >
                    <template v-slot:item.all.itemId={item}>
                         <div class="apart">{{item.pcsfrom}}
                            <v-icon @click="openpcssearch()" style="">search</v-icon>
                        </div> -->
                        <!-- <tr>
                            <td style="" @click="$router.push({name: 'ProviderValidation', params: {institution_id:item.all.docId }})" >
                                <span style="text-decoration:underline; cursor:pointer;" > {{item.all.itemId}} </span>
                            </td>
                        </tr>
                    </template> -->
                    <!-- <template v-slot:item.all.itemId = {item} >
                        <tr >
                            <td style="">
                                <span> ?? {{props.itemId }}  </span>
                            </td>
                        </tr>
                    </template> -->

                    <!-- <template v-slot:item.Action >
                        <tr >
                            <td style="background-color:#F7F7F7 !important;">
                        
                            <v-btn color="#1E9690" class="" style="" @click="editBnkAcc(props.item)" icon small tile block dark >
                                    <v-icon dark small>arrow_forward</v-icon>
                            </v-btn>
                        
                        </td>
                        </tr>
                    </template> -->
                             
                            
                </v-data-table>

                        <v-divider></v-divider>


                        <v-layout row justify-end align-center class="ma-1">
                            <v-text class="mr-1 font-weight-bold body-1" style="color:#3C3D3D; font-size:12px !important">
                                    <!-- <span class="" style="color:#E46A25;font-size:12px;">Showing 1-10 </span>out of {{total}} records -->
                                    <span class="" style="color:#E46A25;">Showing {{fval}}-{{sval}} </span>out of {{totalLen }} records

                            </v-text>
                            <v-divider  class="mx-2" vertical ></v-divider>
                            <v-pagination 
                                class="cust-bnkAcc-pgn"
                                v-model="page"
                                color="#152F38"
                                flat
                                :total-visible="9"
                                @input="nextPageBtn(page)"
                                :length="pagelength"
                                ></v-pagination>
                        </v-layout>
            
            </v-card>
    </v-app>
</template>

<script>
import axios from "axios";

export default {

    components:{
        
    },

    created() {
        this.$store.commit("SAVE_PAGE_HEADER", "Home");

        console.log("Success Token", this.$store.getters.successToken)
        this.token=this.$store.getters.successToken
        
        this.GetHomePageData(this.page)
    },

     computed: {

     
     },

     filters:{
    //   truncate: function (text, length, suffix) {
    //         if (text.length > length) {
    //             return text.substring(0, length) + suffix;
    //         } else {
    //             return text;
    //         }
    //     },
        checkdata(data){
            // console.log('Validity Data', data);
            if (data ==='' || data ===undefined || data === null || data=== 0 || data === 'Invalid Date' ) {
                return '-'
            } else {
                return data;
            }
        },
        // formatDate(data){// eslint-disable-line no-mixed-spaces-and-tabs
        //     // console.log('Validity Data', data);

        //      if (data === "" || data === "undefined" || data === null || data ==='Invalid Date') {
        //         return "-"
        //     } else {
        //         var n = new Date(data);
        //         return n.toLocaleString("en-IN")
        //     }
        // },
    },

    data() {
        return {

            // for pagination AA
            swap_final_count:false,
            fval:0,
            sval:0,
            totalLen:'',
            
            fromvalue:"",
            toValu:"",
            token:null,
            page: 1,
            pendingWork: 10,
            logged_in_name:'Abhishek',
            GetData:[],
            GetData1:[
                {
                    ReceivedDate:"13/Mar/2019",
                    WorkItem:"Provider  Registration Validation",
                    ProviderName:"------",
                    DueDate:"14/Mar/2019",
                    PendingFrom:"2 Days"
                },{
                    ReceivedDate:"13/Mar/2019",
                    WorkItem:"Provider  Registration Validation",
                    ProviderName:"------",
                    DueDate:"14/Mar/2019",
                    PendingFrom:"2 Days"
                },{
                    ReceivedDate:"13/Mar/2019",
                    WorkItem:"Provider  Registration Validation",
                    ProviderName:"------",
                    DueDate:"14/Mar/2019",
                    PendingFrom:"2 Days"
                },{
                    ReceivedDate:"13/Mar/2019",
                    WorkItem:"Provider  Registration Validation",
                    ProviderName:"------",
                    DueDate:"14/Mar/2019",
                    PendingFrom:"2 Days"
                },{
                    ReceivedDate:"13/Mar/2019",
                    WorkItem:"Provider  Registration Validation",
                    ProviderName:"------",
                    DueDate:"14/Mar/2019",
                    PendingFrom:"2 Days"
                },{
                    ReceivedDate:"13/Mar/2019",
                    WorkItem:"Provider  Registration Validation",
                    ProviderName:"------",
                    DueDate:"14/Mar/2019",
                    PendingFrom:"2 Days"
                },{
                    ReceivedDate:"13/Mar/2019",
                    WorkItem:"Provider  Registration Validation",
                    ProviderName:"------",
                    DueDate:"14/Mar/2019",
                    PendingFrom:"2 Days"
                },{
                    ReceivedDate:"13/Mar/2019",
                    WorkItem:"Provider  Registration Validation",
                    ProviderName:"------",
                    DueDate:"14/Mar/2019",
                    PendingFrom:"2 Days"
                },{
                    ReceivedDate:"13/Mar/2019",
                    WorkItem:"Provider  Registration Validation",
                    ProviderName:"------",
                    DueDate:"14/Mar/2019",
                    PendingFrom:"2 Days"
                },{
                    ReceivedDate:"13/Mar/2019",
                    WorkItem:"Provider  Registration Validation",
                    ProviderName:"------",
                    DueDate:"14/Mar/2019",
                    PendingFrom:"2 Days"
                },{
                    ReceivedDate:"13/Mar/2019",
                    WorkItem:"Provider  Registration Validation",
                    ProviderName:"------",
                    DueDate:"14/Mar/2019",
                    PendingFrom:"2 Days"
                },{
                    ReceivedDate:"13/Mar/2019",
                    WorkItem:"Provider  Registration Validation",
                    ProviderName:"------",
                    DueDate:"14/Mar/2019",
                    PendingFrom:"2 Days"
                }
            ],
            size: 10,
            headers:[
                
                {text: 'Received Date', value: 'newdate',sortable: false,divider:true,class:"hdr-txt-clr-txt"},
                {text: 'Work Item', value: 'all.itemId',sortable: false,divider:true,class:"hdr-txt-clr-txt"},
                {text: 'Provider Name', value: 'all.name',sortable: false,divider:true,class:"hdr-txt-clr-txt"},
                {text: 'Due Date', value: 'newduedate',sortable: false,divider:true,class:"hdr-txt-clr-txt"},
                {text: 'Pending From', value: 'all.pendingFrom',sortable: false,divider:true,class:"hdr-txt-clr-txt"},
                // {text: 'Action', value: 'Action',sortable: false,divider:true, width:"40px",class:"hdr-txt-clr-txt hdr-bg-clr"}

            ],
            WI:[ 
                    {text:'select'},
                    {text:'xyz'}
            ],

        };

    },

    methods: {

        
         wrkitmidClick(id){

            console.log(id," doc id", this.$store.getters.fetchRole, "Role")
            
           
            if(id===""|| id=== null|| id===undefined){
                 console.log(id," doc id")
                return
            }
        if(this.$store.getters.fetchRole === 'AdminUser'){
            this.$store.commit('SAVE_PRODUCT_NAME', id)
            let btnName={
                           text:'View Work Item',
                             visible:false
                         }
                         this.$store.commit('SAVE_PRODUCT_BTN_NAME', btnName)


            this.$router.push('/product/createEditProduct')
            
        }else{
            if(this.$store.getters.fetchRole === 'SuperUser'){

              this.$router.push({name: 'ProviderValidation', params: {institution_id:id}})
        }else if(this.$store.getters.fetchRole === 'Reviewer'){

              this.$router.push({name: 'ProviderValidation', params: {institution_id:id}})
        }
        else{
            this.$router.push('/homePage')
        }}
            
        },

        nextPageBtn(indata){
            console.log('page no value',this.page)
            if(this.pagelength == indata){
                this.swap_final_count = true
            }else{
                this.swap_final_count = false
            }
            this.GetHomePageData(this.page)
        },

        GetHomePageData(pageNum) {
        
                 console.log("wrkitem home page no ---->",pageNum)
                
                    let self = this;   
                    //  console.log('api------>')
                    

                //     let apiURL=''
                // if(sortBy1===''||sortBy1===undefined||sortBy1===null){
                //     apiURL=self.API_Service_provider()+"work-items/get-for-provider-registration?"
                // }else{
                //     apiURL=self.API_Service_provider()+"work-items/get-for-provider-registration?"
                // }
                    
                    axios({
                    method: "get",  
                        url:self.API_Service_TASK_MGMNT()+"work-items/assigned?perPage=10&pageNo="+pageNum,            

                            headers: {
                                "Content-Type": "application/json", 
                                "x-access-token": self.token,
                            },
                            
                        })
                        .then(function(response) {
                                       
                            let apiData = response.data.data.list

                            console.log("API RESPONSE for ALL Provider home:::::",apiData);
                        
                        self.GetData = []
                        // self.GetData= apiData
                            self.GetData= apiData.map(e=>{ 
                                
                                
     
                                //      console.log("API home roleName:::::",e.assignToName);

                                //  return { newdate: e.createdAt.split('T')[0],newduedate:e.dueDate.split('T')[0], all:e,  }
                                
                                //     console.log("API next condition roleName:::::",e.assignToName);
                                
                                


                                if(e.assignToName=="Admin User"){ 
                                     console.log("API home roleName:::::",e.assignToName);
                                            return { newdate: e.createdAt.split('T')[0],newduedate: e.dueDate, all:e,  }
                                }else{
                                    console.log("API next condition roleName:::::",e.assignToName);
                                    return { newdate: e.createdAt.split('T')[0],newduedate: e.dueDate.split('T')[0], all:e,  }                                }
                                }

                                // if(e.assignToName=="Super User"){ 
                                //      console.log("API home roleName:::::",e.assignToName);

                                //  return { newdate: e.createdAt.split('T')[0],newduedate: e.dueDate.split('T')[0], all:e,  }
                                // }else{
                                //     console.log("API next condition roleName:::::",e.assignToName);
                                // return { newdate: e.createdAt.split('T')[0],newduedate: e.dueDate.split('T')[0], all:e,  }
                                // }
                                // }
                                
                            )
                        console.log("API RESPONSE for home:::::",self.GetData);
                // for pagination
                    
                    self.pagelength=response.data.data.total_pages
                    console.log(self.page,' <-- page  |  next btn, pg length is ----->',self.pagelength)

                     self.totalLen=response.data.data.total

                      let l = self.GetData.total
                
                
                    console.log( 10*self.page,'search skip simulation ');

                      var less_enough = self.totalLen
                        var checkless_init = false
                        if(less_enough < 10){
                            checkless_init = false
                        }else{
                            checkless_init = true
                        }

                        // if less than 15 we have second value same as total value as no pagination will occur
                        if(checkless_init){
                            
                                // checkinit is true means the final count is more than 15 (- 1)
                                var traverse =  10*self.page
                                self.fval = traverse-9
                                if(self.swap_final_count){
                                    self.sval =  self.totalLen
                                }else{
                                    self.sval = traverse
                                }
                                console.log('fval------>',self.fval)
                                console.log('sval------>',self.sval)

                        }else if(self.totalLen == 0){
                            
                          self.sval = self.fval = 0
                        
                       }else{
                             self.fval = 1
                             self.sval = self.totalLen
                        }

                    console.log('total data  ----->',self.totalLen)
    
                        
                                console.log(" table DATA is---->>::",self.GetData);
                                })
                                .catch(function(error) {
                                    console.log(error);
                                    self.showToast(error.response.data.msg,self.TOST().ERROR);
                                });
             
            },

        nextPage(page) {
            this.pageNumber = page;
        },
        editBnkAcc(item){
                console.log("item", item)
        }
    },

    watch: {
    
    },

        

}

</script>
<style >
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
    font-size: 0.84rem !important;
}

.hdr-txt-clr-txt{
    color:#10242B !important;
    /* padding:0px!important;
    padding: 10px !important;
    padding-left: 14px !important; */
}

.hdr-bg-clr{
    background-color: #f7f7f7;
}
.cust_pr .v-text-field .v-input__slot,
.v-text-field--outline .v-input__slot {
    
    min-height: 38px !important;
    display: flex !important;
    align-items: center !important;
    /* border:1px red solid; */
}

.pending-work{
    justify-items: start;
}

.lay{
        direction: rtl;
        max-height: 50px;

}
.body-2{
        font-size:18px !important;

}
.body-3{
        font-size:16px !important;

}

.cstm-clr-pr{
    color:#10242B;
    margin-bottom: 5px;
}
.cust_pr .v-input{
    font-size: 13px !important;
}
.cust_pr .v-input input {
    font-size: 13px !important;
}

.cust_pr .v-input .v-label {
    font-size: 13px !important;
}


 .cust-bnkAcc-pgn .v-pagination__navigation{
        box-shadow:unset !important;
        border-radius: unset !important;
        background-color:#FFFFFF !important;
        border:1px solid #DAE1E6 !important
    }
    .cust-bnkAcc-pgn .v-pagination__navigation--disabled{
        background-color:#DAE1E6 !important;
    }
    .cust-bnkAcc-pgn .v-pagination__item{
        box-shadow:unset !important;
        min-width:2rem !important;
        height:2rem !important;
        border-radius: unset !important;
        border:1px solid #DAE1E6 !important
    }   
</style>