<template>
    <v-app>
       
        <div style="background-color: #FFFFFF;" column :class="{ 'masterfixins': !isNonSrvcPrvdr, 'masterfixinsnon': isNonSrvcPrvdr}">
            <v-layout color="#FFFFFF" class="mx-2 mt-3" style="background-color: #FFFFFF;">
                <v-flex xs3 >
                    <v-layout column class="mx-2  cust_pr" justify-center >
                       <!-- {{indtype.type}} -->
                        <v-text class="font-weight-bold cstm-clr-pr" style="color:#FFFFFF" > </v-text>
                        <div style="background-color: #f7f7f7;border: 1px solid #c1c8cc;" class="pa-2 mt-5" >
                            <v-checkbox class="ma-0 pa-0" v-model="isNonSrvcPrvdr" hide-details :disabled="indtype.type==='validate'" >
                                <span slot="label" class="lbl-bgclr font-weight-bold " style="font-size: 13px" :style="{opacity:indtype.type==='validate'?0.5:1}"> Is Non-Service Provider</span>
                            </v-checkbox>
                        </div>
                    </v-layout>
                </v-flex>
<!-- 
                 <v-flex xs3 >
                    <v-layout column class=" mx-2 cust_pr">
                        <p class="font-weight-bold cstm-clr-pr">
                            Entity Name *
                        </p>
                        <v-text-field
                            :error-messages="datareq"
                            solo
                            label="TEST REQUIRED IF"
                            v-model="mydatais"
                            class="rounded-0">
                        </v-text-field>
                    </v-layout>
                </v-flex>

                <v-btn @click="setstateofrequired = !setstateofrequired" > toogle required </v-btn>
                 <v-btn @click="newsave" > on save  </v-btn> -->

                <v-flex xs3 >
                    <v-layout column class=" mx-2 cust_pr">
                        <p class="font-weight-bold cstm-clr-pr">
                            Entity Name *
                        </p>
                        <v-text-field
                            autocomplete="off"
                            :error-messages="enreq"
                            solo
                            label="Enter..."
                            v-model="entityNme"
                            class="rounded-0"
                            @input="$v.entityNme.$touch()"
                            @blur="$v.entityNme.$touch()"
                            maxlength=100
                            counter="100">
                        </v-text-field>
                    </v-layout>
                </v-flex>


                <v-flex xs3 >
                    <v-layout column class="cust_pr mx-2">
                        <p class="font-weight-bold cstm-clr-pr">
                            Alias Name
                        </p>
                        <v-text-field
                            autocomplete="off"  
                            solo
                            label="Enter..."
                            v-model="alsNme"
                            class="rounded-0"
                            :error-messages="alreq"
                            @input="$v.alsNme.$touch()"
                            @blur="$v.alsNme.$touch()"
                            maxlength=100
                            counter="100">
                             >
                        </v-text-field>
                    </v-layout>
                </v-flex>

                <v-flex xs3 v-if="isNonSrvcPrvdr===true">
                    <v-layout column class="cust_pr  cust-rmvpd-pr mx-2 ">
                        <p class="font-weight-bold cstm-clr-pr">
                            Telephone Number 
                        </p>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="isNonSrvcPrvdrtlphnNo"
                            :error-messages="isNonSrvcPrvdrtlphnNoError"
                            class="rounded-0"
                            maxlength=10
                            counter="10"
                            @input="$v.isNonSrvcPrvdrtlphnNo.$touch()"
                            @blur="$v.isNonSrvcPrvdrtlphnNo.$touch()">
                            <div slot="prepend-inner" style="background-color:#F7F7F7;padding: 9px !important"><v-icon>local_phone</v-icon></div>
                        </v-text-field>
                    </v-layout>
                </v-flex>

                <v-flex xs3 v-if="isNonSrvcPrvdr===true">
                    <v-layout column class="cust_pr cust-rmvpd-pr mx-2 ">
                        <p class="font-weight-bold cstm-clr-pr">
                            E-mail
                        </p>
                        <v-text-field
                            solo
                            label="Enter..."
                            prepend-inner-icon="mail"
                            v-model="isNonSrvcPrvdremail"
                            class="rounded-0"
                            maxlength=50
                            counter="50"
                            :error-messages="nonservicereq"
                            @input="$v.isNonSrvcPrvdremail.$touch()"
                            @blur="$v.isNonSrvcPrvdremail.$touch()">
                             <div slot="prepend-inner" style="background-color:#F7F7F7;padding: 9px !important"><v-icon>mail</v-icon></div>
                        </v-text-field>
                    </v-layout>
                </v-flex>
                <v-flex xs3 v-if="isNonSrvcPrvdr===false">
                    <v-layout column class="cust_pr mx-2 ">
                        <p class="font-weight-bold cstm-clr-pr">Provider Type *</p>
                        <v-select
                            :items="ptypeis"
                            label="Select"
                            v-model="providerType"
                            solo
                            dense
                            @change="ptypechange(providerType)"
                            :error-messages="providerTypereq"
                            @input="$v.providerType.$touch()"
                            @blur="$v.providerType.$touch()"
                            class="rounded-0"
                            maxlength=20
                            counter="20">
                        </v-select>
                    </v-layout>
                </v-flex>
                <v-flex xs3 v-if="isNonSrvcPrvdr===false">
                    <v-layout column class="cust_pr mx-2 ">
                        <p class="font-weight-bold cstm-clr-pr">
                            Other Provider Type
                        </p>
                        <v-text-field
                           :disabled="!ptype_"
                            solo
                            label="Enter..."
                            v-model="other_pt"
                            class="rounded-0"
                            maxlength=50
                            counter="50">

                        </v-text-field>
                    </v-layout>
                </v-flex>
                

            </v-layout>
            <v-layout style="" class="mx-2" v-if="isNonSrvcPrvdr===false" >
                
                

                <v-flex xs3>
                    <v-layout column class="cust_pr mx-2 ">
                        <p class="font-weight-bold cstm-clr-pr">
                        Registration Number 
                        </p>
                        <v-text-field
                            autocomplete="off"
                            solo
                            label="Enter..."
                            v-model="rgstNo"
                            class="rounded-0"
                            maxlength=50
                            counter="50">

                        </v-text-field>
                    </v-layout>
                </v-flex>

                <!-- <v-flex xs3>
                    <v-layout column class="mx-1 my-2">
                        <v-text class="font-weight-bold cstm-clr-pr" >
                        </v-text> -->
                        <!-- <v-btn color="#23B1A9" dark class="mt-4"><v-icon small left>arrow_upward</v-icon>Update</v-btn> -->
                        <!-- <v-btn color="#23B1A9" dark class="mt-4 text-capitalize">
                            <div class="mt-1">
                                <img src="../../assets/update.png" style="width: 16px;height: 16px"/>
                            </div>
                            <v-divider vertical class="mx-2"></v-divider>
                            Upload
                        </v-btn>
                    </v-layout>
                </v-flex> -->


            <v-flex xs3>
                    <v-layout column class=" mx-2  cust_pr_quick cust-rmvpd-pr-quick">
                        <p class="font-weight-bold cstm-clr-pr-quick">
                            Registration Valid Upto
                        </p>
                        
                        <v-menu
                            ref="calender"
                            :close-on-content-click="false"
                            v-model="RgstrnVldUptoInstncalender"
                            :nudge-right="40"
                            lazy
                            transition="scale-transition"
                            full-width
                            max-width="290px"
                            min-width="290px" 
                            class="rounded-0"
                            
                            >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-bind="attrs"
                                    v-on="on"
                                    id="fromDate"
                                    label="dd/mm/yyyy"
                                    :value="cmputdRgstrnVldUptoInstdteMmntjs"
                                    solo
                                    class="rounded-0"
                                    readonly
                                    required>
                            <div  slot="prepend-inner"  style="background-color:#F7F7F7;  padding: 8px !important"><span class="material-icons small">date_range</span></div>
                                
                                </v-text-field>
                            </template>                        
                                    <v-date-picker 
                                        v-model="RgstrnVldUptoInstdate"
                                        no-title
                                        @input="RgstrnVldUptoInstncalender = false"
                                    ></v-date-picker>
                        </v-menu>

                    </v-layout>
                </v-flex>


                <!-- <v-flex xs3>
                    <v-layout column class="cust_pr mx-2 ">
                        <p class="font-weight-bold cstm-clr-pr">
                            Registration Valid Upto
                        </p>
                        <v-menu
                            ref="calender"
                            :close-on-content-click="false"
                            v-model="RgstrnVldUptoInstncalender"
                            :nudge-right="40"
                            full-width
                            transition="scale-transition"
                            max-width="290px"
                            min-width="290px" 
                            class="rounded-0"
                            lazy

                            >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-bind="attrs"
                                    v-on="on"
                                    slot="activator"
                                    label="dd/mm/yyyy"
                                    :value="cmputdRgstrnVldUptoInstdteMmntjs"
                                    solo
                                    class="rounded-0"
                                    readonly
                                    maxlength=10
                                    counter="10"
                                    
                                >
                                
                                </v-text-field>
                            </template>                     
                            <v-date-picker
                                v-model="RgstrnVldUptoInstdate"
                                no-title
                                @input="RgstrnVldUptoInstncalender = false"
                                
                            ></v-date-picker>
                        </v-menu>


                    </v-layout>
                </v-flex> -->


                <v-flex xs3>
                    <v-layout column class="cust_pr mx-2 ">
                        <p class="font-weight-bold cstm-clr-pr">
                            Registered Body
                        </p>
                        <v-text-field
                            autocomplete="off"
                            solo
                            label="Enter..."
                            v-model="RgstrdBdy"
                            class="rounded-0"
                            maxlength=100
                            counter="100">
                              
                        </v-text-field>
                    </v-layout>
                </v-flex>

                 <v-flex xs3>
                    <v-layout column class="cust_pr mx-2 ">
                        <p class="font-weight-bold cstm-clr-pr">
                            Services Provided
                        </p>
                        <v-select
                            multiple
                            :items="prvdrtyeitems"
                            label="Select"
                            v-model="SrvcsPrvded"
                            solo
                            @input="spchange($event)"
                            dense
                            class="rounded-0"
                            maxlength=10>
                             <template v-slot:selection="{ item, index }">
                                   
                                    <span v-if="index === 0" >{{ item }}</span>
                                   
                                    <span
                                    v-if="index === 1"
                                    class="grey--text caption"
                                    >
                                    (+{{ SrvcsPrvded.length - 1 }} others)
                                    </span>
                             </template>
                        </v-select>
                    </v-layout>
                </v-flex>
                <v-flex xs3>
                    <v-layout column class="cust_pr mx-2 ">
                        <p class="font-weight-bold cstm-clr-pr">
                            Other Services Provided
                        </p>
                        <v-text-field
                            :disabled='!sp_other'
                            solo
                            label="Enter..."
                            v-model="otherservicesprovided"
                            class="rounded-0"
                            maxlength=100
                            counter="100">
                            >
                        </v-text-field>
                    </v-layout>
                </v-flex>
            </v-layout>
            <v-layout color="#FFFFFF" class="mx-2" v-if="isNonSrvcPrvdr===true" >
                <v-flex xs3>
                    <v-layout column class="cust_pr mx-2 mb-3">
                        <p class="font-weight-bold cstm-clr-pr">
                            Fax Number
                        </p>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="isNonSrvcPrvdrfxNo"
                            class="rounded-0"
                            maxlength=20
                            counter="20"
                            :error-messages="isNonSrvcPrvdrfxNoError"
                            @input="$v.isNonSrvcPrvdrfxNo.$touch()"
                            @blur="$v.isNonSrvcPrvdrfxNo.$touch()">
                        </v-text-field>
                    </v-layout>
                </v-flex>
                 <v-flex xs3>
                    <v-layout column class="cust_pr custPrn_Instn mx-2 ">
                        <p class="font-weight-bold cstm-clr-pr">
                            PRN
                        </p>
                        <v-text-field
                            solo
                            label="Auto Generated on Save"
                            v-model="isNonSrvcPrvdrprn"
                            class="rounded-0"
                            disabled
                            maxlength=10
                            counter="10">
                        </v-text-field>
                    </v-layout>
                </v-flex>
                <v-flex xs3>
                <v-layout column class="cust_pr custPrn_Instn mx-2 ">
                        <p class="font-weight-bold cstm-clr-pr">
                            Status
                        </p>
                        <v-text-field
                            label="Auto Generated on Save"
                            disabled
                            v-model="isNonSrvcPrvdrstatus"
                            solo
                            dense
                            class="rounded-0"
                            maxlength=100
                            counter="100">
                            
                        </v-text-field>
                    </v-layout>
                </v-flex>

                <v-flex xs3></v-flex>
                <v-flex xs3></v-flex>

            </v-layout>
            <v-divider class="mb-6"></v-divider>

            <v-layout color="#FFFFFF" class="mx-2" v-if="isNonSrvcPrvdr===false"  >
              
                <v-flex xs3>
                    <v-layout column class="cust_pr  cust-rmvpd-pr mx-2 ">
                        <p class="font-weight-bold cstm-clr-pr">
                            Telephone Number 
                        </p>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="tlphnNo"
                            class="rounded-0"
                            :error-messages="tlphnNoErrors"
                            @input="$v.tlphnNo.$touch()"
                            @blur="$v.tlphnNo.$touch()"
                            maxlength=10
                            counter="10">
                            <div slot="prepend-inner"   style="background-color:#F7F7F7;padding: 9px !important"><v-icon dense>local_phone</v-icon></div>
                        </v-text-field>
                    </v-layout>
                </v-flex>

                

                <v-flex xs3>
                    <v-layout column class="cust_pr custPrn_Instn mx-2 ">
                        <p class="font-weight-bold cstm-clr-pr">
                            PRN
                        </p>
                        <v-text-field
                            solo
                            label="Auto Generated on Save"
                            v-model="prn"
                             class="rounded-0"
                             disabled
                            maxlength=10
                            counter="10">
                        </v-text-field>
                    </v-layout>
                </v-flex>

                 <v-flex xs3>
                    <v-layout column class="cust_pr custPrn_Instn mx-2 ">
                        <p class="font-weight-bold cstm-clr-pr">
                            Status
                        </p>
                        <v-text-field
                            disabled
                            label="Auto Generated on Save"
                            v-model="status"
                            solo
                            dense
                            class="rounded-0"
                            maxlength=100
                            counter="100">
                        </v-text-field>
                    </v-layout>
                </v-flex>

         



                

                <v-flex xs3>
                    <v-layout column class="cust_pr mx-2 ">
                        <p class="font-weight-bold cstm-clr-pr">
                            PAN
                        </p>
                        <v-text-field
                         :error-messages="panreq"
                            solo
                            label="Enter..."
                            v-model="pan"
                            class="rounded-0"
                            maxlength=10
                            counter="10">
                        </v-text-field>
                    </v-layout>
                </v-flex>

                 <v-flex xs3>
                    <v-layout column class="cust_pr mx-2 ">
                        <p class="font-weight-bold cstm-clr-pr">
                            ROHINI ID
                        </p>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="rohiniId"
                            :error-messages="rohiniIDErrors"
                            @input="$v.rohiniId.$touch()"
                            @blur="$v.rohiniId.$touch()"
                            class="rounded-0"
                            maxlength=13
                            counter="13">
                        </v-text-field>
                    </v-layout>
                </v-flex>
               
                
            </v-layout>

            
            <v-layout style="" class="mx-2"  v-if="isNonSrvcPrvdr===false" >

               

                 <v-flex xs3>
                    <v-layout column class="cust_pr cust-rmvpd-pr mx-2 ">
                        <p class="font-weight-bold cstm-clr-pr">
                            E-mail
                        </p>
                        <v-text-field
                            solo
                            label="Enter..."
                            prepend-inner-icon="mail"
                            v-model="email"
                            class="rounded-0"
                            :error-messages="emailErrors"
                            @input="$v.email.$touch()"
                            @blur="$v.email.$touch()"
                            maxlength=50
                            counter="50">
                             <div slot="prepend-inner" style="background-color:#F7F7F7;padding: 9px !important"><v-icon dense>mail</v-icon></div>
                        </v-text-field>
                    </v-layout>
                </v-flex>
               
                

                <v-flex xs3>
                    <v-layout column class="cust_pr mx-2 mb-3">
                        <p class="font-weight-bold cstm-clr-pr">
                            Fax Number
                        </p>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="fxNo"
                            class="rounded-0"
                            :error-messages="fxNoErrors"
                            @input="$v.fxNo.$touch()"
                            @blur="$v.fxNo.$touch()"
                            maxlength=20
                            counter="20">
                        </v-text-field>
                    </v-layout>
                </v-flex>
                <v-flex xs3></v-flex>
                <v-flex xs3></v-flex>
                <v-flex xs3></v-flex>
                
            </v-layout>


            <v-layout class="mx-2" v-if="(indtype.type==='validate' && this.$store.getters.fetchRole === 'Reviewer')||(indtype.type==='validate' && this.$store.getters.fetchRole === 'SuperUser')">
                       <!-- <v-flex xs3 >
                    <v-layout column class="mx-2  cust_pr" justify-center >
                        <span class="font-weight-bold cstm-clr-pr" style="color:#FFFFFF" ></span>
                        
                            <v-checkbox class="ma-0 pa-0" v-model="childprovider" @click="dsblPP()" hide-details >
                                <span slot="label" class="lbl-bgclr font-weight-bold " style="font-size: 13px">Child Provider</span>
                            </v-checkbox>
                      
                    </v-layout>
                </v-flex> -->

                <v-flex xs3 >
                    <v-layout column class="mx-2 mb-5" justify-center >
                       
                        <span class="font-weight-bold cstm-clr-pr" style="color:#FFFFFF" > </span>
                        <div style="background-color: #f7f7f7;border: 1px solid #c1c8cc; padding:7px" class="mt-5">
                            <v-checkbox class="ma-0 pa-0" v-model="childprovider" @click="dsblPP()" hide-details >
                                <span slot="label" class="lbl-bgclr font-weight-bold" style="font-size: 13px;">Child Provider</span>
                            </v-checkbox>
                        </div>
                    </v-layout>
                </v-flex> 

                    <v-flex xs3>
                    <v-layout column class="cust_pr mx-2 ">
                        <p class="font-weight-bold cstm-clr-pr">
                            Parent Provider 
                        </p>
                        <v-autocomplete
                            v-model="parentprovider"
                            :items="itemsSearch"
                            :loading="isLoading"
                            :search-input.sync="searchApi"
                            cache-items
                            hide-selected
                            item-value="_id"
                            item-text="entityName"
                            label="Select"
                            return-object
                            solo
                            :error-messages="prtreq"
                            @change="dsbleMT()"
                            dense
                            :disabled="disablePP"
                            class="rounded-0"
                            :maxlength="max100"
                            counter>
                            
                        </v-autocomplete>
                        <!-- <p>{{disablePP}}</p> -->
                    </v-layout>
                </v-flex>

                
                 <v-flex xs3>
                     <!-- mt99 -->
                    <v-layout column class="cust_pr mx-2 ">
                        <p class="font-weight-bold cstm-clr-pr">
                            Mapping Type
                        </p>
                        <v-select
                           
                            label="Select"
                            :error-messages="mapreq"
                            solo
                            dense
                            :items="mappingtype"
                            v-model="mappingtypeis"
                            class="rounded-0"
                            :disabled="disableMt"
                            maxlength=10>
                        </v-select>
                    </v-layout>
                </v-flex>
                <v-flex xs3></v-flex>
                <v-flex xs3></v-flex>
            </v-layout>

            
            <v-divider class="mb-6" v-if="isNonSrvcPrvdr===false" ></v-divider>

            <v-layout color="#FFFFFF" class="mx-2"  >
                <v-flex xs3>
                <v-layout column class="mx-2">
                    <p class="text-uppercase font-weight-bold title-clr-pr">
                        Address
                    </p>
                    <v-divider></v-divider>
                </v-layout>
                </v-flex>
                <v-flex xs3>
                <v-layout column class="cust_pr mx-2 ">
                    <p class="font-weight-bold cstm-clr-pr">
                        Address Line 1 *
                    </p>
                    <v-text-field
                        :error-messages="adonereq"
                        solo
                        label="Enter..."
                        v-model="adrsLne1"
                        class="rounded-0"
                        maxlength=100
                        counter="100"
                        @input="$v.adrsLne1.$touch()"
                        @blur="$v.adrsLne1.$touch()"
                        >
                    </v-text-field>
                </v-layout>
                </v-flex>
                <v-flex xs3>
                <v-layout column class="cust_pr mx-2 ">
                    <p class="font-weight-bold cstm-clr-pr">
                        Address Line 2 *
                    </p>
                    <v-text-field
                       :error-messages="adtworeq"
                        solo
                        label="Enter..."
                        v-model="adrsLne2"
                        class="rounded-0"
                        maxlength=100
                        counter="100"
                        @input="$v.adrsLne2.$touch()"
                        @blur="$v.adrsLne2.$touch()">
                    </v-text-field>
                </v-layout>
                </v-flex>
                <v-flex xs3>
                <v-layout column class="cust_pr mx-2 ">
                    <p class="font-weight-bold cstm-clr-pr">
                        Address Line 3
                    </p>
                    <v-text-field
                        solo
                        label="Enter..."
                        v-model="adrsLne3"
                        class="rounded-0"
                        maxlength=100
                        counter="100">
                          
                    </v-text-field>
                </v-layout>
                </v-flex>

                <v-flex xs3>
                <v-layout column class="cust_pr mx-2 ">
                    <p class="font-weight-bold cstm-clr-pr">
                        Pin Code *
                    </p>
                    <div class="ac" >
                      <v-autocomplete
                        :error-messages="pinreq"
                        v-model="pnCde"
                        :items="itemspin"
                        :search-input.sync="search"
                        color="white"
                        hide-no-data
                        cache-items
                        hide-selected
                        :item-text="pin"
                        :item-value="pin"
                        placeholder="Start typing to Search"
                        @change="grab_pincode(pnCde, 'from change')"
                        return-object
                        maxlength=6
                        counter="6"
                        solo
                        @input="$v.pnCde.$touch()"
                        @blur="$v.pnCde.$touch()"
                      ></v-autocomplete>
                    </div>
                    <!-- return-object -->
                </v-layout>
                </v-flex>

                
            </v-layout>

            <v-layout style="" class="mx-2" >
               <v-flex xs3>
                <v-layout column class="cust_pr mx-2 ">
                    <p class="font-weight-bold cstm-clr-pr">
                        Country *
                    </p>
                    <v-select
                       :error-messages="countryreq"
                        :items="cntryItems"
                        label="Select"
                        readonly
                        v-model="country"
                        item-text="country"
                        item-value="country"
                        solo
                        dense
                        class="rounded-0"
                        maxlength=50
                        counter="50"
                        @input="$v.country.$touch()"
                        @blur="$v.country.$touch()">
                    </v-select>
                </v-layout>
                </v-flex>
               
               
                <v-flex xs3 >
                <v-layout column class="cust_pr mx-2" >
                    <p class="font-weight-bold cstm-clr-pr">
                        State *
                    </p>
                    <v-select
                        readonly
                       :error-messages="statereq"
                        :items="stateItems"
                        label="Select State"
                        v-model="state"
                        solo
                        dense
                        item-text="state"
                        item-value="state"
                        maxlength=50
                        counter="50"
                       
                        class="rounded-0"
                        @input="$v.state.$touch()"
                        @blur="$v.state.$touch()">
                    </v-select>
                       <!-- @change="getpin_forstate" -->
                </v-layout>
                </v-flex>
                <v-flex xs3>
                <v-layout column class="cust_pr mx-2 ">
                    <p class="font-weight-bold cstm-clr-pr">
                        District *
                    </p>
                    <v-select
                    readonly
                        :error-messages="disreq"
                        :items="districtItems"
                        item-text="district"
                        item-value="district"
                        label="Select"
                        v-model="district"
                        solo
                        dense
                        @change="dis_selected"
                        class="rounded-0"
                        maxlength=50
                        counter="50"
                        @input="$v.district.$touch()"
                        @blur="$v.district.$touch()">
                    </v-select>
                </v-layout>
                </v-flex>
            
                <v-flex xs3>
                <v-layout column class="cust_pr mx-2 ">
                    <p class="font-weight-bold cstm-clr-pr">
                        City/Town/Village *
                    </p>
                    <v-autocomplete
                        :items="ctvItems"
                        label="Select"
                        v-model="ctv"
                        :search-input.sync="citysearch"
                        item-text="area"
                        item-value="area"
                        @change="cityselected(ctv)"
                        clearable
                       
                        solo
                        dense
                        class="rounded-0"
                        maxlength=50
                        counter="50"
                        :error-messages="ctvErrors"
                        @input="$v.ctv.$touch()"
                        @blur="$v.ctv.$touch()"
                    >
                    </v-autocomplete>
                </v-layout>
                </v-flex>
                <v-flex xs3>
                <v-layout column class="cust_pr mx-2 mb-3">
                    <p class="font-weight-bold cstm-clr-pr">
                       Other City/Town/Village 
                    </p>
                    <v-text-field
                        :disabled="!citydis"
                        :error-messages="othercity"
                         @input="$v.othrAdrs.$touch()"
                        @blur="$v.othrAdrs.$touch()"
                        solo
                        label="Enter..."
                        v-model="othrAdrs"
                        class="rounded-0"
                        maxlength=100
                        counter="100">
                    </v-text-field>
                </v-layout>
                </v-flex>
                
            </v-layout>
             <v-divider class="mb-6"></v-divider>

            <v-layout color="#FFFFFF" class="mx-2"  style="" v-if="isNonSrvcPrvdr===false">
                <v-flex xs3  v-if="providerType==='Hospital'||providerType==='Daycare Center'||providerType==='Nursing Home'">
                    <v-layout column class="mx-2">
                        <p class="text-uppercase font-weight-bold title-clr-pr">
                            Validation Details
                        </p>
                        <v-divider v-if="providerType==='Hospital'||providerType==='Daycare Center'||providerType==='Nursing Home'"></v-divider>
                    </v-layout>
                </v-flex>
                <v-flex xs3 v-if="providerType==='Hospital'||providerType==='Daycare Center'||providerType==='Nursing Home'">
                    <v-layout column class="cust_pr mx-2 ">
                        <p class="font-weight-bold cstm-clr-pr">
                            No. of Beds
                        </p>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="noOfBeds"
                            class="rounded-0"
                            :error-messages="noOfBeds_chk"
                            @blur="$v.noOfBeds.$touch()"
                            @change="$v.noOfBeds.$touch()"
                            :rules="[numberRule]"
                            maxlength=5
                            counter="5">
                        </v-text-field>
                    </v-layout>
                </v-flex>
                <v-flex xs3 v-if="providerType==='Hospital'||providerType==='Daycare Center'||providerType==='Nursing Home'">
                    <v-layout column class="cust_pr mx-2 ">
                        <p class="font-weight-bold cstm-clr-pr">
                            Daily Medical Records
                        </p>
                        <v-select
                            :items="dlyMdclRcrdsItems"
                            label="Select"
                            v-model="dailyMedicalRecords"
                            solo
                            dense
                            class="rounded-0"
                            :error-messages="dailyMedicalRecords_chk"
                            @blur="$v.dailyMedicalRecords.$touch()"
                            @change="$v.dailyMedicalRecords.$touch()"
                            maxlength=10
                            counter="10">
                        </v-select>
                    </v-layout>
                </v-flex>
                <v-flex xs3 v-if="providerType==='Hospital'||providerType==='Daycare Center'||providerType==='Nursing Home'">
                    <v-layout column class="cust_pr mx-2 ">
                        <p class="font-weight-bold cstm-clr-pr">
                            Has Operation Theatre
                        </p>
                        <v-select
                            :items="hsOtItems"
                            label="Select"
                            v-model="hasOperationTheatre"
                            solo
                             class="rounded-0"
                             :error-messages="hasOperationTheatre_chk"
                             @blur="$v.hasOperationTheatre.$touch()"
                            @change="$v.hasOperationTheatre.$touch()"
                        
                            maxlength=3
                            counter="3">
                        </v-select>
                    </v-layout>
                </v-flex>
                <v-flex xs3 v-if="providerType==='Hospital'||providerType==='Daycare Center'||providerType==='Nursing Home'">
                    <v-layout column class="cust_pr mx-2 ">
                        <p class="font-weight-bold cstm-clr-pr">
                            Nursing Round The Clock
                        </p>
                    
                        <v-select
                            :items="nrtcItems"
                            label="Select"
                            v-model="nursingRoundTheClock"
                            solo
                            dense
                            style="width: 235px"
                            class="rounded-0"
                            :error-messages="nursingRoundTheClock_chk"
                             @blur="$v.nursingRoundTheClock.$touch()"
                            @change="$v.nursingRoundTheClock.$touch()"
                            maxlength=3
                            counter="3"
                             >
                        </v-select>
                        
                    </v-layout>
                </v-flex>
                
            </v-layout>

            <v-layout class="mx-2" v-if="isNonSrvcPrvdr===false" >
                
                
               <v-flex xs3 v-if="providerType==='Hospital'||providerType==='Daycare Center'||providerType==='Nursing Home'">
                   
                    <v-layout column class="cust_pr mx-2 ">
                        <p class="font-weight-bold cstm-clr-pr">
                            Medical Practitioner Round The Clock
                        </p>
                        <v-select
                            :items="mprtcItems"
                            label="Select"
                            v-model="medicalPractionerRoundTheClock"
                            solo
                            dense
                             class="rounded-0"
                            :error-messages="medicalPractionerRoundTheClock_chk"
                             @blur="$v.medicalPractionerRoundTheClock.$touch()"
                            @change="$v.medicalPractionerRoundTheClock.$touch()"
                            maxlength=3
                            counter="3">
                        </v-select>
                    </v-layout>
                </v-flex>

                
                <v-flex xs3></v-flex>
                <v-flex xs3></v-flex>
                <v-flex xs3></v-flex>
                <v-flex xs3></v-flex>
               
               
                      

              
                           
                <!-- <v-card class="ma-3 pa-2" tile>
                                    
                        <v-layout color="#FFFFFF" class="mx-2 mt-4"  style="">
                
                    </v-layout>


                    <v-layout color="#FFFFFF" class="mx-2 mt-4"  style="">
                        
                  
                    </v-layout>                    

                    <v-layout class="ma-3 pa-2" justify-space-between style="background-color: #F7F7F7"  >
                        <v-layout style="flex:1" justify-center>
                            <v-btn dark  tile color="#23B1A9" class="text-capitalize px-3 mx-2" small ><v-icon dark small>mdi-content-save</v-icon><v-divider vertical class="mx-2"></v-divider>Save</v-btn>
                        </v-layout>
                    </v-layout>
                </v-card> -->
          
                <!-- <v-flex xs3></v-flex>
                <v-flex xs3></v-flex>
                <v-flex xs3></v-flex> -->
                 
            </v-layout>
             

            <v-layout v-if="indtype.type==='validate'" class="mx-2">
                 <v-flex xs3>
                     <!-- :class="[vldDsbled === true ? 'custPrn_Instn' : '']" -->
                     <!-- :class="[vldDsbled === true ? 'custPrn_Instn' : '']" -->
                     <!-- :disabled="vldDsbled" -->
                        <v-layout column class="cust_pr mx-2 " >
                        <p class="font-weight-bold cstm-clr-pr">
                            Registration date effective from
                        </p>
                        
                        <v-menu
                            ref="calender"
                            :close-on-content-click="true"
                            v-model="regDatefrom"
                            :nudge-right="40"
                           
                            transition="scale-transition"
                            
                            max-width="290px"
                            min-width="290px" 
                            class="rounded-0"
                            
                            >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    slot="activator"
                                    label="Select.."
                                    :value="efffrom"
                                    solo
                                     class="rounded-0"
                                    readonly 
                                    v-bind="attrs"
                                    v-on="on"
                                     maxlength=10
                                     
                                ></v-text-field>
                            </template>
                                                        
                                    <v-date-picker
                                        v-model="effectivefrom"
                                        no-title
                                        
                                    ></v-date-picker>
                        </v-menu>
                        </v-layout>
                    </v-flex>


                 <v-flex xs3>
                     <!-- :class="[vldDsbled === true ? 'custPrn_Instn' : '']" -->
                     <!-- :disabled="vldDsbled" -->
                        <v-layout column class="cust_pr mx-2 " >
                        <p class="font-weight-bold cstm-clr-pr">
                            Registration effective date to
                        </p>
                    
                        <v-menu
                            ref="calender"
                            :close-on-content-click="true"
                            v-model="RegDateTo"
                            :nudge-right="40"
                            
                            transition="scale-transition"
                            
                            max-width="290px"
                            min-width="290px" 
                              class="rounded-0"
                            >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    slot="activator"
                                    label="Select.."
                                    :value="effto"
                                    solo
                                     class="rounded-0"
                                    readonly 
                                    v-bind="attrs"
                                    v-on="on"
                                    maxlength=50
                                    
                                ></v-text-field>
                            </template>
                                                        
                                    <v-date-picker
                                        v-model="effectiveto"
                                        no-title
                                        
                                    ></v-date-picker>
                        </v-menu>
                    </v-layout>
                    </v-flex>
                    
               
                <!-- <v-flex xs3>
                    <v-layout column class="cust_pr mx-2 ">
                        <p class="font-weight-bold cstm-clr-pr">
                            Reviewer Remarks   
                        </p>
                        <v-textarea
                            solo
                            label="Enter..."
                            v-model="reviewRemarks"
                            class="rounded-0"
                            auto-grow
                            maxlength=200
                            counter="200">
                        </v-textarea>
                    </v-layout>
                </v-flex> -->

                    <v-flex xs3>
                    <v-layout column class="cust_pr mx-2 " :class="[vldDsbled === true ? 'custPrn_Instn' : '']">
                        <p class="font-weight-bold cstm-clr-pr">
                            Registration date
                        </p>
                    
                        <v-menu
                            ref="calender"
                            :close-on-content-click="true"
                            v-model="RegDate"
                            :nudge-right="40"
                            :disabled="vldDsbled"
                            transition="scale-transition"
                            
                            max-width="290px"
                            min-width="290px" 
                            class="rounded-0"
                            >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    slot="activator"
                                    label="Select.."
                                    :value="cmputdRjs"
                                    solo
                                     class="rounded-0"
                                    readonly 
                                    v-bind="attrs"
                                    v-on="on"
                                     maxlength=10
                                ></v-text-field>
                            </template>
                                                        
                                    <v-date-picker
                                        v-model="R"
                                        no-title
                                        
                                    ></v-date-picker>
                        </v-menu>
                    </v-layout>
                    </v-flex>

                    <v-flex xs6>
                        <!-- :class="[vldDsbled === true ? 'custPrn_Instn' : '']" -->
                        <!-- :disabled="vldDsbled" -->
                        <v-layout column class="cust_pr mx-2 " >
                            <p class="font-weight-bold cstm-clr-pr">
                                Reviewer Remarks
                            </p>
                            <v-textarea
                                 
                                solo
                                label="Enter..."
                                v-model="reviewRemarks"
                                class="rounded-0"
                                auto-grow
                                :maxlength="max200"
                                counter>
                            </v-textarea>
                        </v-layout>
                    </v-flex>

                    <!-- <v-flex xs3></v-flex> -->
            </v-layout>

            <!-- <h1>{{docspool.length != 0}} | {{docspool.length}}</h1> -->
              

             <!-- v-if="indtype.type == 'edit'" -->
                <div v-if="isNonSrvcPrvdr===false">

                    <v-flex class="xs6 mb-4"  v-if="indtype.type!=='validate'"    >
                            <v-layout column class="cust_pr mx-4 mb-2" v-if="docson_are.length != 0" >
                            <span class="font-weight-bold cstm-clr-pr">
                                List of treating doctors
                            </span>
                                    <v-card tile>
                                        <v-layout v-if="false"  justify-space-between align-center style="border:1px solid #C1C8CC; background-color:#FFFFFF" class="pa-2">
                                            <span class="mx-2" style="color:#8A8A8A;font-size:13px">
                                            Select
                                            </span>
                                        </v-layout>
                                        <div v-if="enabledocslist"  style="background-color: #f7f7f7;border: 1px solid #c1c8cc; max-height:280px; overflow:scroll;" class="">
                                            <div  v-for="(doc, index) in docspool" :key="index" >
                                                <div v-if="doc.sel_doc" style="border-top: 1px solid #c1c8cc" class="pa-1">
                                                    <!-- <v-checkbox v-model="doc.sel_doc"  hide-details class="pa-0 ma-0"> -->
                                                        <span   slot="label" class="pl-2 lbl-bgclr font-weight-bold body-1 pt-1" style="font-size: 13px"> {{doc.firstName+' '+doc.lastName}} </span>
                                                    <!-- </v-checkbox> -->
                                                </div>
                                            </div>
                                        </div>
                                    </v-card>
                            </v-layout>
                    </v-flex>    
                </div>
            
            <v-layout class="ma-3 pa-2"  style="justify-content: flex-end; background-color: #F7F7F7" v-if="isNonSrvcPrvdr===false" >
                
                <v-flex xs3 >
                    <v-layout  justify-end style="align-items: center;" >
                        <v-btn  @click="docten = true"  v-if="indtype.type === 'edit'" dark  tile color="#E46A25" class="text-capitalize px-3 mx-2" small><v-icon dark small>backup</v-icon> <v-divider vertical class="mx-2"></v-divider> Upload Doctors List</v-btn>
                        <v-btn  @click="doclistedit = true" v-if="indtype.type === 'edit'" tile light small color="#fff" class="text-capitalize px-3 mx-2"><v-icon dark small >edit</v-icon> <v-divider class="mx-2" vertical></v-divider>Edit Doctors List</v-btn>
                        <!-- v-if="indtype.type!=='validate'" -->
                        <v-btn   @click="opendocs" dark  tile color="#152F38" 
                        class="text-capitalize px-3 mx-2" small>
                        <v-icon dark small>cloud_upload</v-icon>
                        <v-divider vertical class="mx-2"></v-divider>Upload Documents</v-btn>

                        <v-btn dark  tile color="#E46A25" v-if="indtype.type == 'new'" class="text-capitalize px-3  mx-2" small to="/HomePage"><v-icon dark small >cancel</v-icon> <v-divider vertical class="mx-2"></v-divider>Cancel</v-btn>

                        <v-btn dark tile color="#D1121B" v-if="indtype.type == 'new'" class="text-capitalize px-3  mx-2" small @click="clearData"><v-icon dark small>delete</v-icon> <v-divider vertical class="mx-2"></v-divider>Clear</v-btn>

                        <v-btn v-if="indtype.type === 'edit'" dark tile  small color="success" @click="updateFlag"  class=" text-capitalize"><v-icon small left>arrow_upward</v-icon>Update Flag</v-btn>


                        <v-btn @click="nowsaveit" v-if="indtype.type!=='validate'||  indtype.type == 'edit'" dark tile color="#23B1A9" class="text-capitalize px-3  mx-2 " small><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider> Save</v-btn>
                            <!-- indtype.type =='validate' ||  -->
                        <v-btn dark  v-if="indtype.type =='edit'" tile style="font-size:12px" small color="#152F38" class="text-capitalize px-3 mx-2" @click="open_statushstry()"><v-icon dark small >find_in_page </v-icon> <v-divider vertical class="mx-2"></v-divider>Status History</v-btn>

                        <v-btn dark  v-if="(indtype.type==='validate' && this.$store.getters.fetchRole === 'Reviewer')||(indtype.type==='validate' && this.$store.getters.fetchRole === 'SuperUser')" tile color="#111212" class="text-capitalize px-3 mx-2" small @click="sndBckInstitution()"><v-icon dark small>mdi-arrow-left-circle</v-icon><v-divider vertical class="mx-2"></v-divider>Send Back</v-btn>

                        <v-btn dark v-if="(indtype.type==='validate' && this.$store.getters.fetchRole === 'Reviewer')||(indtype.type==='validate' && this.$store.getters.fetchRole === 'SuperUser')"  tile color="#E46A25" class="text-capitalize px-3  mx-2" small @click="VldteInstitution()" ><v-icon dark small>mdi-checkbox-marked-circle</v-icon> <v-divider vertical class="mx-2"></v-divider>Validate</v-btn>

                        <v-btn dark v-if="(indtype.type==='validate' && this.$store.getters.fetchRole === 'Reviewer')||(indtype.type==='validate' && this.$store.getters.fetchRole === 'SuperUser')" tile color="#D1121B" class="text-capitalize px-3  mx-2" small @click="dclneInstitution()"><v-icon dark small>mdi-timer</v-icon> <v-divider vertical class="mx-2"></v-divider>Decline</v-btn>

                        <v-btn dark v-if="(indtype.type==='validate' && this.$store.getters.fetchRole === 'Reviewer')||(indtype.type==='validate' && this.$store.getters.fetchRole === 'SuperUser')" tile color="#23B1A9" class="text-capitalize px-3  ml-2 " small @click="extInstitution()"><v-icon dark small style="transform: rotate(45deg);">mdi-arrow-left-circle</v-icon> <v-divider vertical class="mx-2"></v-divider> Exit</v-btn>

                </v-layout>
                </v-flex>
              
            </v-layout>

            <!--Buttons for Non Service Provider-->
            <v-layout class="ma-3 pa-2" justify-end style="background-color: #F7F7F7; align-items: center;" v-if="isNonSrvcPrvdr===true" >
                  <!-- <v-btn   @click="opendocs" dark  tile color="#152F38"   class="text-capitalize px-3 mx-2" small><v-icon dark small>cloud_upload</v-icon> <v-divider vertical class="mx-2"></v-divider> Upload</v-btn> -->
                <v-btn v-if="indtype.type === 'edit'" dark tile  small color="success" @click="updateFlag"   class=" text-capitalize"><v-icon small left>arrow_upward</v-icon>Update Flag</v-btn>
                <v-btn dark  tile color="#E46A25" class="text-capitalize px-3  mx-2" small to="/HomePage"><v-icon dark small>cancel</v-icon> <v-divider vertical class="mx-2"></v-divider>Cancel</v-btn>
                <v-btn dark tile color="#D1121B" class="text-capitalize px-3  mx-2" small @click="isNonServProviderClear"><v-icon dark small>delete</v-icon> <v-divider vertical class="mx-2"></v-divider>Clear</v-btn>
                <v-btn dark tile color="#23B1A9" class="text-capitalize px-3  ml-2" @click="isNonServProviderSave"  small><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider> Save</v-btn>
                <v-btn dark  v-if="indtype.type =='validate' || indtype.type =='edit'" tile style="font-size:12px" small color="#152F38" class="text-capitalize px-3 mx-2" @click="open_statushstry()"><v-icon dark small >find_in_page </v-icon> <v-divider vertical class="mx-2"></v-divider>Status History</v-btn>

            </v-layout>


            <!-- <h4> {{docen}} </h4> -->
            <div v-if="docen">
                <docup @closedoc="docen = false"  @getdocslist="doc_for_creation"
                @deletenow="deletelocal" 
                :setdoc="{docen:docen,provider_data: this.indtype.data.indata, type:this.indtype.type, updocs:this.create_doc_arr,from:'pro' }" ></docup>
            </div>

            <div v-if="docten">
                <doctorup  @closedoctor="docten = false" :setdoct="{docen:docten,provider_data:this.indtype.data.indata}"  ></doctorup>
            </div>
             <!-- <v-btn  @click="docen = true" > enable doc </v-btn> -->
        </div>

            <!-- Status History for Edit  -->
            <v-layout >
                    <v-dialog v-model="prvdrStsHstryDilg" persistent max-width="720px" style="overflow-x: hidden;">
                        <v-card tile color="#F7F7F7" class="pb-2">
                            <v-card tile>
                                <v-layout justify-space-between  class="" style="flex:1;background-ox-shadow: 0px 3px 5px #00000029;">
                                    <v-layout align-center>
                                        <v-card-title class="pa-0 pl-3 text-capitalize font-weight-bold" style="color:#10242B;font-size:13px">Provider Status History</v-card-title>
                                        <v-divider vertical inset class="mx-2"></v-divider>
                                        <span class=" text-capitalize font-weight-bold" style="font-size:13px"><span style="color:#909699">{{indtype.type === 'edit' ? indtype.data.indata.entityName : ''}} </span>- Status History</span>
                                    </v-layout>
                                    <div style="border-left:1px solid #C1C8CC;" @click="close_hstryFrm()">
                                        <v-icon style="cursor: pointer;" class="pa-2" >close</v-icon>
                                    </div>
                                </v-layout>
                            </v-card>

                            
              
                <v-data-table
                    :headers="headers"
                    :items="statusHisData"
                    :page.sync="page"
                    :items-per-page="statusItemsPerPage"
                    hide-default-footer
                    dense
                    @page-count="pageCount = $event"
                    height="195px"
                >
                <!-- <template v-slot:item.bulkUploadType="{ item }">
                    <div >{{ item.bulkUploadType | checkdata }}</div>
                </template>
                <template v-slot:item.dateOfUpload="{ item }">
                    <div >{{ item.dateOfUpload| checkdata |formatDate}}</div>
                </template>
                <template v-slot:item.FileName="{ item }">
                    <div >{{ item.fileName | checkdata}}</div>
                </template>
                <template v-slot:item.noOfEntries="{ item }">
                    <div >{{ item.noOfEntries| checkdata }}</div>
                </template>
                <template v-slot:item.noOfEntriesProcessed="{ item }">
                    <div >{{ item.noOfEntriesProcessed | checkdata}}</div>
                </template>
                <template v-slot:item.noOfEntriesFailed="{ item }">
                    <div >{{ item.noOfEntriesFailed | checkdata}}</div>
                </template> -->
                    <!-- <template v-slot:item.fileLink="{ item }" >
                         <v-btn color="#1E9690" @click="dwnldFaildRcrds(item.fileLink)" icon outlined dark small class= "my-1">
                            <v-icon dark small>get_app</v-icon>
                        </v-btn>
                    </template> -->
                   
                </v-data-table>
                <v-divider></v-divider>
                <v-layout row justify-end align-center class="ma-2">
                            <span class="mr-1 font-weight-bold" style="color:#3C3D3D; font-size:13px;">
                                <span class="" style="color:#E46A25;">Showing {{recordFrom}}-{{recordTo}} </span>out of {{totalRecords}} records
                            </span>
                            <v-divider  class="mx-2" vertical ></v-divider>
                             <v-pagination
                                class="cust-bnkAcc-pgn"
                                v-model="page"
                                color="#152F38"
                                value="page"
                                flat
                                @input=" nextPageBtn(page)"
                                :length="pageLength"
                            ></v-pagination>
                        </v-layout>
             
                         <v-layout class="mr-2" row justify-center style="">
                                <v-btn tile  dark color="#152F38" class=" px-3 text-capitalize" small @click="close_hstryFrm()">
                                    <v-icon color="" small >cancel</v-icon>
                                    <v-divider vertical class="ml-2 mr-2 text-capitalize"></v-divider>Return
                                </v-btn>
                            </v-layout>
                            
                        </v-card>
                    </v-dialog>
                </v-layout>

          <v-layout>
                <v-dialog v-model="doclistedit" persistent max-width="720px" style="overflow-x: hidden;">
                        <v-card dense tile color="#F7F7F7" class="pb-2 ">

                            <v-card tile>
                                <v-layout justify-space-between  class="" style="flex:1;background-ox-shadow: 0px 3px 5px #00000029;">
                                    <v-layout align-center>
                                        <v-card-title class="pa-0 pl-3 text-capitalize font-weight-bold"
                                        style="color:#10242B;font-size:13px">Hospital Doctors List</v-card-title>
                                        <v-divider vertical inset class="mx-2"></v-divider>
                                    </v-layout>
                                 
                                    <div style="border-left:1px solid #C1C8CC;" @click="closeeditdoc" >
                                        <v-icon style="cursor: pointer;" class="pa-2" >close</v-icon>
                                    </div>
                                </v-layout>
                            </v-card>

                <v-card class="elevation-2 mx-3 my-3" >
                       <v-layout column class="cust_pr mx-4 mb-2">
                        <span class="font-weight-bold cstm-clr-pr">
                            List of treating doctors
                        </span>
                                <v-card tile>
                                    <v-layout   justify-space-between align-center style="border:1px solid #C1C8CC; background-color:#FFFFFF" class="pa-2">
                                        <span class="mx-2" style="color:#8A8A8A;font-size:13px">
                                        Select
                                        </span>
                                    </v-layout>
                                    <div v-if="doclistedit"  style="background-color: #f7f7f7;border: 1px solid #c1c8cc; max-height:280px; overflow:scroll;" class="">
                                        
                                        <div  v-for="(doc, index) in docspool" :key="index" style="border-top: 1px solid #c1c8cc" class="pa-1">
                                            <v-checkbox v-model="doc.sel_doc"  hide-details class="pa-0 ma-0">
                                                <span slot="label" class="pl-2 lbl-bgclr font-weight-bold body-1 pt-1" style="font-size: 13px">{{index+1}} ) {{doc.firstName+' '+doc.lastName}} </span>
                                            </v-checkbox>
                                        </div>
                             
                                    </div>
                                </v-card>
                            </v-layout>
                </v-card>
        
                <v-layout justify-end>
                    <v-btn @click="closeeditdoc" color="#e46a25" class="ml-2 rounded-0" > close </v-btn>
                    <v-btn @click="clearall_doclist" color="#d1121b" class="ml-2 rounded-0">clear</v-btn>
                    <v-btn  @click="update_doctors_list"  color="#23b1a9" class="ml-2 mr-3 rounded-0" >save</v-btn>
                </v-layout>
            </v-card >
              </v-dialog>
          </v-layout>


               <v-layout>
                    <v-dialog v-model="drLstDilg" persistent max-width="700px">
                        <v-card tile color="#F7F7F7" class="pb-2">
                            <v-card tile>
                                <v-layout justify-space-between  class="" style="flex:1;background-ox-shadow: 0px 3px 5px #00000029;">
                             
                                    <v-card-title class="pa-0 pl-3 text-capitalize font-weight-bold" style="color:#10242B;font-size:13px">Hospital Doctors List</v-card-title>
                                    <div style="border-left:1px solid #C1C8CC;" @click="close_drLstFrm()">
                                        <v-icon style="cursor: pointer;" class="pa-2" >close</v-icon>
                                    </div>
                                </v-layout>
                            </v-card>

                            <v-layout justify-end align-center class="mt-2 mx-3">
                                    <v-btn tile dark color="#23B1A9" class="text-capitalize px-3" small>
                                        <div class="mt-1">
                                            <img src="../../assets/update.png" style="width: 16px;height: 16px"/>
                                        </div>
                                        <v-divider vertical class="ml-2 mr-2"></v-divider>Upload Excel
                                    </v-btn>   
                            </v-layout>

                   
                            <v-layout class="mr-2" row justify-center style="">
                                
                                <v-btn tile dark color="#E46A25" class="text-capitalize px-3" small @click="close_drLstFrm()">
                                    <v-icon color="" small >cancel</v-icon>
                                    <v-divider vertical class="ml-2 mr-2"></v-divider>Close
                                </v-btn>
                                <v-btn tile dark color="#D1121B" class="text-capitalize px-3" small @click="clr_sltdDrdlglst()">
                                    <v-icon color="" small >delete</v-icon>
                                    <v-divider vertical class="ml-2 mr-2"></v-divider>Clear
                                </v-btn>
                                <v-btn tile dark color="#23B1A9" class="text-capitalize px-3" small @click="close_drLstFrm()">
                                    <v-icon color="" small >check_circle</v-icon>
                                    <v-divider vertical class="ml-2 mr-2"></v-divider>Save
                                </v-btn>
                            </v-layout>
                            
                        </v-card>
                    </v-dialog>
                </v-layout>

                <v-layout>
                    <v-dialog v-model="vldExtDilg" persistent max-width="420px">
                        <v-card tile color="#F7F7F7" class="pb-2">
                            <v-card tile>
                                <v-layout justify-space-between  class="" style="flex:1;background-ox-shadow: 0px 3px 5px #00000029;">
                             
                                    <v-card-title class="pa-0 pl-3 text-capitalize font-weight-bold" style="color:#10242B;font-size:13px">Confirm</v-card-title>
                                    <div style="border-left:1px solid #C1C8CC;" @click="close_ExtFrm()">
                                        <v-icon style="cursor: pointer;" class="pa-2" >close</v-icon>
                                    </div>
                                </v-layout>
                            </v-card>

                            <v-card tile class="ma-4 pa-3">
                                <v-layout justify-center>
                                     <p class="text-xs-center ma-0 pa-0">Details saved on screen will be lost</p>
                                </v-layout> 
                   
                                <v-layout class="mr-2 mt-4" row justify-center style="">
                                   
                                    <v-btn tile dark color="#23B1A9" class="text-capitalize mx-3" small @click="$router.go(-1)">
                                        <v-icon color="" small >check_circle</v-icon>
                                        <v-divider vertical class="ml-2 mr-2"></v-divider>Ok
                                    </v-btn>
                                    <v-btn tile dark   color="#E46A25" class="text-capitalize px-3" small @click="close_ExtFrm()">
                                        <v-icon color="" small > cancel</v-icon>
                                        <v-divider vertical class="ml-2 mr-2"></v-divider>Cancel
                                    </v-btn>
                                </v-layout>
                            </v-card>
                            
                        </v-card>
                    </v-dialog>
                </v-layout>

                <!-- Duplicate Pan Error Dialog  -->
                 <v-layout>
                    <v-dialog v-model="panErrorDialog" persistent max-width="360px">
                        <v-card tile color="#F7F7F7" >
                            <v-card tile>
                                <v-layout justify-end  class="" style="flex:1;background-ox-shadow: 0px 3px 5px #00000029;">
                             
                                    <!-- <v-card-title class="pa-0 pl-3 text-capitalize font-weight-bold" style="color:#10242B;font-size:13px"> 
                                        Provider with same PAN number already exists
                                    </v-card-title> -->
                                    <div style="border-left:1px solid #C1C8CC;" @click="close_panErrorDialog()">
                                        <v-icon style="cursor: pointer; height:15px" class="pa-2" >close</v-icon>
                                    </div>
                                </v-layout>
                            </v-card>

                            <v-card tile>
                              
                                <v-card-title class="headline rounded-0">
                                
                                    <v-alert
                                    color="red"
                                    dense
                                    border="left"
                                    elevation="2"
                                    colored-border 
                                    class="vaIcon"
                                    icon="mdi-information"
                                    
                                    >
                                    <v-card-text class="pa-0">
                                    <h4>WARNING</h4>
                                   <h5>Provider with same PAN number already exists</h5>
                                </v-card-text>
                                    </v-alert>
                                 <div class="d-flex" justify-space-between style="250px" duplicatePanData  v-for="(panData,index) of duplicatePanData" :key="index" :id="panDupliErrorId=panData._id">
                                    <div>
                                        <p class="ma-0 ml-2 font-weight-bold" style="font-size:14px; height: 20px">Entity Name</p>
                                        <span class="ml-2" style="font-size: 13px; color: orange; cursor: pointer;"  @click="panErrorMsg">{{panData.entityName}}</span>
                                    </div>
                                   <v-divider vertical inset class="mx-3" ></v-divider>
                                    <div>
                                            <h5 class="ma-0 ml-3" style="font-size:13px; height: 20px">PAN</h5>
                                            <span class=" ma-0 ml-3" style="font-size: 13px;">{{panData.panNumber}}</span>
                                    </div>
                                </div> 

                            </v-card-title>
                                
                            </v-card>
                            
                        </v-card>
                    </v-dialog>
                </v-layout>


        <!-- Duplicate Provider Error Dialog  -->
                <v-layout>     
            <v-dialog v-model="errDialog" persistent max-width="380">
                <v-card class="my-0">
                    <v-card tile>
                            <v-layout justify-end class="" style="flex:1;background-ox-shadow: 0px 3px 5px #00000029; ">
                            
                                <div  style="border-left:1px solid #C1C8CC;" @click="errForDupProvider('No')">
                                    <v-icon style="cursor: pointer; height:15px" class="pa-2" >close</v-icon>
                                </div>
                            </v-layout>
                        </v-card>

                <v-card-title class="headline rounded-0">
                                
                        <v-alert
                        color="red"
                        dense
                        border="left"
                        elevation="2"
                        colored-border 
                        class="vaIcon"
                        icon="mdi-information"
                        style="width:400px">
                            <v-card-text class="pa-0">
                                <h4>WARNING</h4>
                            <h5>Potential Duplicate Provider</h5>
                            </v-card-text>
                        </v-alert>
                               

                  <v-card 
                v-scroll.self="onScroll"
                class="overflow-x-auto my-0"
                max-height="400"
                width="400px">               
                    <div class="d-flex" justify-space-between style="width:300px"  v-for="(data,index) of duplicateData" :key="index" :id="proiderDupliErrorId=data._id">
                            <div>
                                <p class="ma-0 ml-2" style="font-size:15px; height: 20px">Entity Name</p>
                                <span class="ml-2" style="font-size: 13px; width:200px; color: orange; cursor: pointer;"  @click="providerDupliErrorMsg">{{data.entityName}}</span>
                            </div>                                                                                      
                        <v-divider vertical inset class="mx-4" ></v-divider>
                            <div>
                                    <p class="ma-0 ml-3" style="font-size:15px; height: 20px">Alias Name</p>
                                    <span class=" ma-0 ml-3" style="font-size: 13px; width:200px;">{{data.aliasName !== ''? data.aliasName : '----'}}</span>
                            </div>   
                                        
                    </div>  
                  </v-card>          
                                 
                    </v-card-title>
                                
                            <v-card-actions>

                               

                                <!-- <v-btn dark tile color="#23B1A9" class="text-capitalize px-3  ml-2" small @click="errForDupProvider('Yes')" >
                                    Yes
                                </v-btn> -->
                                <v-btn tile dark color="#E46A25" class="text-capitalize px-3 ml-4" small @click="errForDupProvider('No')">
                                    <v-icon color="" small >cancel</v-icon>
                                    <v-divider vertical class="ml-2 mr-2"></v-divider>Cancel
                                </v-btn>

                                <v-btn tile dark color="#23B1A9" class="text-capitalize px-3" small @click="errForDupProvider('Yes')">
                                    <v-icon color="" small >check_circle</v-icon>
                                    <v-divider vertical class="ml-2 mr-2"></v-divider>Continue
                                </v-btn>
                                <!-- <v-btn dark tile color="#D1121B" class="text-capitalize px-3  mx-2 rounded-0" small @click="errForDupProvider('No')" >
                                    No
                                </v-btn> -->
                                 
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-layout>

                 <!-- provider update flag  -->
                 <v-layout>     
                    <v-dialog v-model="updateFlagStatus"   max-width="80%">
                        <v-card class="mt-4 " height="600px" >
                        <v-card tile>
                                <v-layout justify-end class="" style="flex:1;background-ox-shadow: 0px 3px 5px #00000029; ">
                             
                                    <div  style="border-left:1px solid #C1C8CC;" @click="updateFlagStatus=false">
                                        <v-icon style="cursor: pointer; height:15px" class="pa-2" >close</v-icon>
                                    </div>
                                </v-layout>
                            </v-card>
                        <v-form ref="updateForm" class="pa-5">
                            <v-layout class=" mt-4" >
                                <v-flex xs3>
                                    <v-layout column class="mx-2 my-2">
                                        <v-text class="text-uppercase font-weight-bold title-clr-pr-ACD">
                                            Provider Flag Update
                                        </v-text>
                                        <v-divider></v-divider>
                                    </v-layout>
                                </v-flex>

                                <v-flex xs3>
                                <v-layout column class="cust_pdTDS mx-2 my-2 ">
                                    <v-text class="font-weight-bold cstm-clr-pd">
                                       PRN
                                    </v-text>
                                
                                    <v-text-field
                                        solo
                                        disabled
                                        label="Enter..."
                                        v-model="updatePRN"
                                        class="rounded-0"
                                        maxlength="10"
                                        dense
                                        >
                                    </v-text-field>
                                </v-layout>
                                </v-flex>

                                <v-flex xs3>
                                    <v-layout column class="cust_pdTDS mx-2 my-2 ">
                                        <v-text class="font-weight-bold cstm-clr-pd">
                                            Provider Name
                                        </v-text>
                                    
                                        <v-text-field
                                            solo
                                            disabled
                                            label="Enter..."
                                            v-model="updateProviderName"
                                            class="rounded-0"
                                            maxlength="10"
                                            dense
                                            >
                                        </v-text-field>
                                    </v-layout>
                                </v-flex>

                                <v-flex xs3>
                                    <v-layout column class="cust_pdTDS mx-2 my-2 ">
                                        <v-text class="font-weight-bold cstm-clr-pd">
                                            Provider Status
                                        </v-text>
                                    
                                        <v-text-field
                                            solo
                                            disabled
                                            label="Enter..."
                                            v-model="updateProStatus"
                                            class="rounded-0"
                                            maxlength="10"
                                            dense
                                            >
                                        </v-text-field>
                                    </v-layout>
                                </v-flex>

                                

                                
                            </v-layout>


                            <v-layout class=" mt-2" >
                                <v-flex xs3>
                                    <v-layout column class="mx-2 my-2">
                                        <v-text class="text-uppercase font-weight-bold title-clr-pr-ACD">
                                            Provider Flag 
                                        </v-text>
                                        <v-divider></v-divider>
                                    </v-layout>
                                </v-flex>

                            <v-flex xs3>
                                <v-layout column class="mx-2 my-2">
                                    <v-checkbox
                                                dense
                                                :disabled="forIsNonServiceProvider"
                                                color = "#23b1a9" 
                                                style="margin-top: 3px; margin-left: 16px"
                                                label="Platinum" 
                                                v-model="platinum" 
                                                >
                                    </v-checkbox>

                                    <v-checkbox
                                                dense
                                                :disabled="forIsNonServiceProvider"
                                                color = "#23b1a9" 
                                                style="margin-top: 3px; margin-left: 16px"
                                                label="Gold" 
                                                v-model="gold" 
                                                >
                                    </v-checkbox>


                                    <v-checkbox
                                                dense
                                                :disabled="forIsNonServiceProvider"
                                                color = "#23b1a9" 
                                                style="margin-top: 3px; margin-left: 16px"
                                                label="Silver" 
                                                v-model="silver" 
                                                >
                                    </v-checkbox>
                                    
                                    <v-checkbox
                                                dense
                                                :disabled="forIsNonServiceProvider"
                                                color = "#23b1a9" 
                                                style="margin-top: 3px; margin-left: 16px"
                                                label="Verified" 
                                                v-model="verifiedFlag"
                                                >
                                    </v-checkbox>
                                                
                                    <v-checkbox
                                                dense
                                                :disabled="forIsNonServiceProvider"
                                                color = "#23b1a9" 
                                                style="margin-top: 3px; margin-left: 16px"
                                                label="Under Observation" 
                                                v-model="observationFlag" 
                                                >
                                    </v-checkbox>

                                    <v-checkbox
                                                disabled
                                                dense
                                                
                                                color = "#23b1a9" 
                                                style="margin-top: 3px; margin-left: 16px"
                                                label="Non-Service Provider" 
                                                v-model="updateFlagNonService" 
                                                >
                                    </v-checkbox>

                                </v-layout>
                            </v-flex>

                                <v-flex xs6>
                                    <v-layout column class="cust_pdTDS mx-2 my-2 ">
                                        <v-text class="font-weight-bold cstm-clr-pd">
                                            Remarks*
                                        </v-text>
                                    
                                        <v-text-field
                                            solo 
                                            label="Enter..."
                                            v-model="updateRemarks"
                                            class="rounded-0"
                                            maxlength="200"
                                            dense
                                            
                                            :rules="[nameRulesUpdateFlag(updateRemarks)]"
                                            >
                                        </v-text-field>
                                    </v-layout>
                                </v-flex>  
                            </v-layout>

                            <div class="mx-2 pa-5" style="background-color: #F7F7F7; display:flex;justify-content:space-between;"  >
                                <v-layout style="flex:1" justify-end > 
                                    <v-btn dark tile color="#23B1A9" class="text-capitalize px-3 ml-3 testing" :disabled="forIsNonServiceProvider" small @click="submitUpdateFlag"><v-icon dark small>mdi-arrow-up-circle</v-icon> <v-divider vertical class="mx-2"></v-divider>Update</v-btn>
                                    <v-btn dark tile color="#E46A25" class="text-capitalize px-3 ml-3" small  @click="updateFlagStatus=false"><v-icon dark small>cancel</v-icon> <v-divider vertical class="mx-2"></v-divider>Cancel</v-btn>
                                </v-layout>
                            </div>
                        </v-form>
                        </v-card>
                    </v-dialog>
                </v-layout>
         
    </v-app>
</template>

<script>
// import ProviderSummaryTabs from '../ProviderSummmaryClaims/ProviderSummaryTabs.vue'
// import InstitutionSummary from '../ProviderSummmaryClaims/InstitutionSummary.vue'
import moment from 'moment';

import docup from './docup.vue';

import doctorup from './doctorup.vue';

const {required, minLength, alpha, email, helpers, requiredIf,numeric } = require('vuelidate/lib/validators/')

export const numberRegex = helpers.regex('number', /^([A-Z]){5}([0-9]){4}([A-Z]){1}?$/);
export const alphaRegex = helpers.regex('alpha', /^[a-zA-Z.' ]+$/);
// export const teleRegex = helpers.regex('telenumber',  /^[+0-9-]+$/);
//  const requiredIfRoot = 
const reqqq = requiredIf(function(){ return this.sscomp })
    function rohiniIdValue(value){
        return(value.length !== 13) ? false : true;
    }

export default {

    components:{
        docup,
        doctorup,
        
    },

    created() {
        if(this.dataFromRoute){

            console.log("from route",this.dataFromRoute);
            this.entityNme = this.dataFromRoute.data.entityName
            // this.mbNoInd = this.dataFromRoute.data.mobileNumber
            this.prn = this.dataFromRoute.data.prnNumber
            this.pan = this.dataFromRoute.data.pan
            this.providerType = this.dataFromRoute.data.providerType
            this.rgstNo = this.dataFromRoute.data.registrationNumber
            this.rohiniId = this.dataFromRoute.data.rohiniId
            // this.pnCde = this.dataFromRoute.data.pincode
            
        }
        
         if(this.indtype.type == 'edit'){
            this.$store.commit("SAVE_PAGE_HEADER", "Provider Institution Summary");
         }else if(this.indtype.type == 'new'){
            this.$store.commit("SAVE_PAGE_HEADER", "Provider Institution Registration");
         }else if(this.indtype.type == 'validate'){
            this.$store.commit("SAVE_PAGE_HEADER", "Provider Institution Validation");
         }
        if(this.indtype.type == 'edit'){
            this.vldDsbled=true
        }else{
            this.vldDsbled=false
        }
        // this.getcountries();
        if(this.indtype.type == 'edit'||this.indtype.type == "validate"){
            
           this.isNonServiceProSetData();
           this.setformdata();
            this.getdocspool(1);
        }
         console.log("%c got the flag selected =>","background-color:#dddd45;color:black",this.$store.getters.fetchPrvdrData.flagDetails );
        // let gotFlag = this.$store.getters.fetchPrvdrData.flagDetails

        // Object.keys(gotFlag).forEach(data => {
        //        console.log(gotFlag[data]);    
            
        // });
        this.platinum = false
        this.gold = false
        this.silver = false
        this.verifiedFlag = false
        this.observationFlag = false
        this.updateFlagNonService = this.isNonSrvcPrvdr
        this.forIsNonServiceProvider = this.isNonSrvcPrvdr

    },

    mounted(){
       console.log("from mounted props is (Institution)-> ",this.indtype.data);
       this.setdata = this.indtype.data
        console.log("SET DATA is -> ", this.setdata );
    },

    watch:{
        page(newPage){
        this.nextPageBtn(newPage);
         },

    citysearch(val){

        

        if(val == this.ctv){
             console.log("matches v model");
             return
        }

        // return
        
        console.log("CITY VAL is ", val);
         if(val == null){
            console.log("returned as val foun to be null")
            return
         }

         if(val.length > 3){

            var vlen = val.length;
            console.log('vlen is incoming string length to be compared inside the array of objects--->',vlen);
              var ctvitemlen =  this.ctvItems.length
              var my_counter  = 0
            for(let i=0; i<this.ctvItems.length;i++){
            
                    var strlen = this.ctvItems[i].area.slice(0,vlen)
                    console.log(val.length ,"<- searched length |&&| strlen length is",strlen.length)
                      console.log(val ,"<- searched |&&| str is",strlen)
                        
                    // if(vlen > strlen.length){
                    //     console.log("data not available to search in CITY")
                    //     return
                    // }
                    my_counter++
                    if(strlen == val){
                        console.log(strlen.length,"CITY found as ",val.length)
                        //   this.foundis = false
                        return
                     
                    }

                    if(my_counter == ctvitemlen){
                        console.log(my_counter == ctvitemlen," < ??????????????????- searched entired array value not found, now calling API with ident 1 from watch")
                         this.search_area_pageno++
                        this.get_areas(this.district,1)
                    }
                    
                    // if(strlen != val){
                    //     console.log(strlen.length,"CITY not found as ",val.length)
                    //     //   this.foundis = false
                    //     this.search_area_pageno++
                    //     this.get_areas(this.district,1)
                    //     return
                    // }else{
                    //     console.log(this.ctvItems.length,"CITY found true", this.ctvItems[i].area)
                    //     // this.foundis = true
                    //     return
                    // }
          }


         }else{
              console.log("citysearch not 3")
         }

    },

    search (val){
      // Items have already been loaded
        console.log(this.itemspin.length," val is ", val);
        if(val == null){
            console.log("returned as val foun to be null")
            return
        }

      if(val.length >3){
          var vlen = val.length
          console.log('vlen',vlen)
        //  var arrlen = this.entries.length
        //  console.log(arrlen,'arrlen')
        //  var newlen =this.entries[515]
        //  console.log("newlen is from watcher",newlen)
          if(this.itemspin.length == 0 && val.length >= 3){
              this.callapi(val,0)
              return
          }else{
              console.log('itens len')
          }

          for(let i=0; i<this.itemspin.length;i++){
            
              var strlen = this.itemspin[i].pin.slice(0,vlen)
              console.log(vlen > strlen.length,"strlen is",strlen)
                
             if(vlen > strlen.length){
               console.log("data not available to search")
               return
             }
            
              if(strlen != val){
                  console.log(strlen.length,"not found as ",val.length)
                //   this.foundis = false
                  this.callapi(val,0)
                 return
              }else{
                console.log(this.itemspin.length,"found true", this.itemspin[i].pin)
                // this.foundis = true
                return
              }
          }
        
      }else{
        console.log("not 3")
      }
     
    },

    searchApi(val){
            console.log(val,"Search APIIIIIiiiiiiii");
            if (val===null) return

            // Items have already been requested
            if (this.isLoading) return

            this.isLoading = true
            console.log(val);
            
            this.GET(2,'provider/search-providers-by-name/'+val,
                   ( res,err) => {
                    if(!err){

                        console.log("this is response",res);
                        const providers = []
                        for(let provider of res.data.data.data){
                                console.log(provider.empanelmentProvider);
                                if(provider.registeredEntity === "Institution"){
                                    providers.push({entityName:provider.entityName,_id:provider._id})
                                }else if(provider.registeredEntity === "Individual"){
                                    providers.push({entityName:provider.firstName,_id:provider._id})
                                }
                                
                        }
                        this.entries = providers
                    }else{
                        this.showToast(err.response.data.msg ,this.TOST().ERROR);
                    }
                    });
                    this.isLoading = false

             }

  },

    computed: {
        

        itemsSearch () {
            return this.entries;
        },

        isreq_othercity(){
            
            if(this.ctv == 'Other'){
                // this.citydis = true
                return true
            }else{
                return false
                // this.citydis = false
            }
        },
      
         isRequired_parent(){
              console.log('from computed childprovider', this.childprovider)
            if(this.childprovider){
                console.log('true childprovider', this.childprovider)
                // this.disablePP=false
                return true
            }else{
                console.log('false childprovider', this.childprovider)
                // this.disablePP=true
                return false
            }     
        },

        isRequired_mapping(){
            
            if(this.parentprovider.length == 0){
                // this.disableMt=false
                return false
            }else{
                // this.disableMt=true
                return true
            }  
        },

         isRequired(){
              console.log('from computed s_c_d_nm', this.s_c_d_nm)
            if(this.s_c_d_nm){
                console.log('true s_c_d_nm', this.s_c_d_nm)
                return false
            }else{
                console.log('false s_c_d_nm', this.s_c_d_nm)
                return true
            }
                
        },

        itemspin(){
            return this.pitems 
        },

        effto(){

            var fromis = moment(this.effectivefrom, 'YYYY-MM-DD').toDate().getTime();
            var tois =  moment(this.effectiveto, 'YYYY-MM-DD').toDate().getTime();
            // console.log(fromis,"<--from is | to is -> ",tois);
              console.log(fromis > tois,"condition ")
            if(fromis > tois){
                console.log("condotion found true should show toast");
                 this.showToast('Registration Effective To’ date must be after ‘Registration Effective From’ date', this.TOST().WARNING);
              
                return
            }

            return this.effectiveto ? moment(this.effectiveto).format("DD-MM-YYYY"): ''
        },

        efffrom(){
            return this.effectivefrom ? moment(this.effectivefrom).format("DD-MM-YYYY"): ''
        },

        cmputdRgstrnVldUptoInstdteMmntjs(){
            return this.RgstrnVldUptoInstdate ? moment(this.RgstrnVldUptoInstdate).format("DD-MM-YYYY"): ''
        },
        cmputdRjs(){
            return this.R ? moment(this.R).format("DD-MM-YYYY"): ''
        },
        fxNoErrors(){
            const errors = [];
            if (!this.$v.fxNo.$dirty) return errors
            !this.$v.fxNo.numeric && errors.push('Only Numbers are Allowed')
            return errors
        },

        emailErrors(){
            const errors = [];
            if (!this.$v.email.$dirty) return errors
            !this.$v.email.email && errors.push('E-mail must be valid')
            return errors
        },

        tlphnNoErrors(){
            const errors = [];
            // const value = this.$v.tlphnNo.$model
            if (!this.$v.tlphnNo.$dirty) return errors
            !this.$v.tlphnNo.numeric && errors.push('Only Numbers are Allowed');
            return errors
        },

        rohiniIDErrors(){
            const errors = [];
            const value = this.$v.rohiniId.$model
            
                // errors.push('Invalid ROHINI ID')
            if (!this.$v.rohiniId.$dirty) return errors
            !this.$v.rohiniId.numeric && errors.push('Only Numbers are Allowed');
            if(value.length > 1){

                !this.$v.rohiniId.rohiniIdValue && errors.push('Invalid ROHINI ID');
            }
            return errors
            
        },

        isNonSrvcPrvdrtlphnNoError(){
           
            const errors = [];
            if (!this.$v.isNonSrvcPrvdrtlphnNo.$dirty) return errors
            !this.$v.isNonSrvcPrvdrtlphnNo.numeric && errors.push('Only Numbers are Allowed')
            return errors
        },

        prtreq(){
                const errors = [];
                if (!this.$v.parentprovider.$dirty) return errors
                !this.$v.parentprovider.required && errors.push('This field is required',this.$v.parentprovider.required)
                return errors
        },

        mapreq(){
            console.log("mapping req of maytype required ");
                const errors = [];
                if (!this.$v.mappingtypeis.$dirty) return errors
                !this.$v.mappingtypeis.required && errors.push('This field is required')
                return errors
        },

        countryreq(){
                const errors = []
                if (!this.$v.country.$dirty) return errors
                !this.$v.country.required && errors.push('This field is required')
                return errors
        },
        statereq(){
                const errors = []
                if (!this.$v.state.$dirty) return errors
                !this.$v.state.required && errors.push('This field is required')
                return errors
        },
        disreq(){
                const errors = []
                if (!this.$v.district.$dirty) return errors
                !this.$v.district.required && errors.push('This field is required')
                return errors
        },
        enreq(){
              const errors = []
                if (!this.$v.entityNme.$dirty) return errors
                !this.$v.entityNme.required && errors.push('This field is required')
                !this.$v.entityNme.alphaRegex && errors.push('Only alphabets are allowed')
                return errors   
        },
        pinreq(){
             const errors = []
                if (!this.$v.pnCde.$dirty) return errors
                !this.$v.pnCde.required && errors.push('This field is required')
                return errors 
        },
        alreq(){
            const errors = []
            if (!this.$v.alsNme.$dirty) return errors
            // !this.$v.alsNme.required && errors.push('This field is required')
            !this.$v.alsNme.alphaRegex && errors.push('Only alphabets are allowed')
            return errors   
        },
        adonereq(){
                const errors = []
                if (!this.$v.adrsLne1.$dirty) return errors
                !this.$v.adrsLne1.required && errors.push('This field is required')
                return errors 
        },
        adtworeq(){
                const errors = []
                if (!this.$v.adrsLne2.$dirty) return errors
                !this.$v.adrsLne2.required && errors.push('This field is required')
                return errors 
        },
        nonservicereq(){
                const errors = []
                if (!this.$v.isNonSrvcPrvdremail.$dirty) return errors
                // !this.$v.isNonSrvcPrvdremail.required && errors.push('This field is required')
                !this.$v.isNonSrvcPrvdremail.email && errors.push('E-mail must be valid')
                return errors 
        },

        providerTypereq(){
                 const errors = []
                if (!this.$v.providerType.$dirty) return errors
                !this.$v.providerType.required && errors.push('This field is required')
                return errors 
        },

        isNonSrvcPrvdrfxNoError(){
            const errors = [];
            if (!this.$v.isNonSrvcPrvdrfxNo.$dirty) return errors
            !this.$v.isNonSrvcPrvdrfxNo.numeric && errors.push('Only Numbers are Allowed')
            return errors
        },

        panreq(){
             const errors = []
                // if (!this.$v.pan.$dirty) return errors
                !this.$v.pan.numberRegex && errors.push('Pan format is Invalid')
                return errors 
        },  

        ctvErrors(){
                const errors = []
                if (!this.$v.pan.$dirty) return errors
                !this.$v.ctv.required && errors.push('This field is required')
                return errors 
        },

        othercity(){
            console.log(this.$v.othrAdrs)
                const errors = []
                if (!this.$v.othrAdrs.$dirty) return errors
                !this.$v.othrAdrs.required && errors.push('This field is required')
                return errors
        },

        noOfBeds_chk(){

             if(this.noOfBeds.length > 0){
                console.log("from if ");
                if(/^[0-9 ]+$/g.test(this.noOfBeds) != true){
                    // this.ttl_err = true
                    return "Only numbers are allowed"
                }else{
                    // this.ttl_err = false
                    return []
                }
              }else{
                 console.log("from else diurecty check on title ", !this.$v.noOfBeds.$dirty);
                const errors = []
                if (!this.$v.noOfBeds.$dirty) return errors
                !this.$v.noOfBeds.required && errors.push('This field is required')
                return errors

            }

        },

        isVldtnDtls(){
            console.log("inside isVldtnDtls function")
            if(this.indtype.type==='validate'){

                if(this.providerType==='Hospital'||this.providerType==='Daycare Center'||this.providerType==='Nursing Home'){
                    console.log("inside if isVldtnDtls function")
                    console.log(this.RgstrdBdy,this.rgstNo)
                    // if((this.RgstrdBdy==="" && this.rgstNo==="")||(this.RgstrdBdy===undefined  && this.rgstNo===undefined)||(this.RgstrdBdy===null  && this.rgstNo===null)){
                    if(this.RgstrdBdy==="" || this.rgstNo===""||this.RgstrdBdy===undefined  || this.rgstNo===undefined || this.RgstrdBdy===null || this.rgstNo===null){
                        console.log("inside if isVldtnDtls this.RgstrdBdy==='' this.rgstNo==='' cehck function")
                        return true;
                    }else{
                        console.log("inside else isVldtnDtls this.RgstrdBdy==='' this.rgstNo==='' cehck function")
                        return false;
                    }
                    
                }
            }else{ 
                console.log("inside else isVldtnDtls function")
                return false;
            }

        },
        dailyMedicalRecords_chk(){
            const errors = []
            if (!this.$v.dailyMedicalRecords.$dirty) return errors
            !this.$v.dailyMedicalRecords.required && errors.push('This field is required')
            return errors 
        },
        hasOperationTheatre_chk(){
            const errors = []
            if (!this.$v.hasOperationTheatre.$dirty) return errors
            !this.$v.hasOperationTheatre.required && errors.push('This field is required')
            return errors 
        },
        medicalPractionerRoundTheClock_chk(){
            const errors = []
            if (!this.$v.medicalPractionerRoundTheClock.$dirty) return errors
            !this.$v.medicalPractionerRoundTheClock.required && errors.push('This field is required')
            return errors 
        },
        nursingRoundTheClock_chk(){
            const errors = []
            if (!this.$v.nursingRoundTheClock.$dirty) return errors
            !this.$v.nursingRoundTheClock.required && errors.push('This field is required')
            return errors 
        },

        numberRule( ){

            if(this.providerType==='Hospital'||this.providerType==='Daycare Center'||this.providerType==='Nursing Home'){
                console.log("inside if isVldtnDtls function")
                console.log(this.RgstrdBdy,this.rgstNo)
                // if((this.RgstrdBdy==="" && this.rgstNo==="")||(this.RgstrdBdy===undefined  && this.rgstNo===undefined)||(this.RgstrdBdy===null  && this.rgstNo===null)){
                    if(this.RgstrdBdy==="" || this.rgstNo===""||this.RgstrdBdy===undefined  || this.rgstNo===undefined || this.RgstrdBdy===null || this.rgstNo===null){
                    // if (!v.trim()) return true;
                    if(parseInt(this.noOfBeds)<15){
                        // this.showToast('Provider does not meet minimum bed criteria', this.TOST().WARNING);
                        return 'Provider does not meet minimum bed criteria'
                    }
                    
                }
                
            }

        },



    },

     validations:{
        //v99
        // other:{required},
        //double checl isnonserprovider email
        alsNme:{ alphaRegex },
        fxNo:{numeric},
        email: {email},
        tlphnNo: {numeric},
        rohiniId:{numeric,rohiniIdValue},
        entityNme:{ required, alphaRegex },
        pan:{numberRegex},
        ctv:{required},
        adrsLne1:{ required },
        adrsLne2:{ required },
        pnCde:{ required },
        providerType:{ required },
       
        isNonSrvcPrvdremail:{email}, 
        isNonSrvcPrvdrtlphnNo: {numeric},
        isNonSrvcPrvdrfxNo: {numeric},
    
        district:{ required:requiredIf(function () { return this.isRequired })},
        state:{ required:requiredIf(function () { return this.isRequired }) },
        country:{ required :requiredIf(function () { return this.isRequired })},
        othrAdrs:{ required :requiredIf(function () { return this.isreq_othercity } )},
        ctv:{required},

        parentprovider:{ required :requiredIf(function () { return this.isRequired_parent })},
        mappingtypeis:{ required :requiredIf(function () { return this.isRequired_mapping }) },
        noOfBeds:{
            numeric,
            required :requiredIf(function () { return this.isVldtnDtls})
        },
        dailyMedicalRecords:{
            required :requiredIf(function () { return this.isVldtnDtls})
        },
        hasOperationTheatre:{
            required :requiredIf(function () { return this.isVldtnDtls})
        },
        medicalPractionerRoundTheClock:{
            required :requiredIf(function () { return this.isVldtnDtls})
        },
        nursingRoundTheClock:{
            required :requiredIf(function () { return this.isVldtnDtls})
        },

        
        
        // other_qual:{required},
        // otherptype:{required},
        // mdlnmeInd:{ alpha },
     },

    props:{
        indtype:{
            required:true,
            type:Object
        },
        dataFromRoute:{
            type:Object
        }
    },

    data() {
        return {
        tempObj:{
            pin:'400092',
        },
        forIsNonServiceProvider:false,
        updateFlagStatus:false,
        updatePRN:'',
        updateProviderName:'',
        updateProStatus:'',
        platinum:Boolean,
        gold:Boolean,
        silver:Boolean,
        verifiedFlag:Boolean,
        observationFlag:Boolean,
        updateFlagNonService:Boolean,
        updateRemarks:'',
        dupPage:1,
        vldDsbled:false,
        // maintains selcted doctors list for list of treating doctors
        docson_are:[],
        disablePP:false,
        disableMt:false,
        create_doc_arr:[],
            // pagination variables 
        providerDupliEntityName:'',
        providerDupliAliasName:'',
        proiderDupliErrorId:'',
        panErrorDialog:false,
        panEntityName: '',
        panDupliErrorId: '',
        panNumberErr:'',
        errDialog: false,
        isOverrideForError : false,

        entries: [],
        isLoading: false,
        searchApi: null,
        // pagination variables 
        pageLength:0,
        page:1,
        setdata:{},
        // pageCount:0,
        pageCount:0,
        statusItemsPerPage: 5,
        totalRecords: null,
        recordFrom:null,
        recordTo: null,
        statusHisData: [],
        // totalRecords: null,    

        // itemsPerPage:0,
        // pageCount:0,
        // page:0,
        updated_doc_list:[],
        tableItems : [],
        
        prvdrStsHstryDilg:false,
        headers:[
            {text: 'Status',value:'status',sortable: false,divider:true,class:"hdr-txt-clrnew "},
            {text: 'Date', value: 'Date',sortable: true,divider:true,class:"hdr-txt-clrnew"},
            {text: 'Time', value: 'Time',sortable: false,divider:true,class:"hdr-txt-clrnew"},
            {text: 'User', value: 'userName',sortable: false,divider:true,class:"hdr-txt-clrnew"},
            {text: 'Remarks',value: 'remarks',sortable: false,divider:true,class:"hdr-txt-clrnew"},   
         ],

        doclistedit:false,
            childprovider:false,
            parentprovider:'',
            mappingtype:[
                "Owned",
                "Managed",
                "Franchise",
            ],
            mappingtypeis:'',

            docspool:[],
            enabledocslist:false,
            effectivefrom:'',
            effectiveto:'',
            R:'',
            RegDate:false,
            RegDateTo:false,
            regDatefrom:false,
            medicalPractionerRoundTheClock:'',
            dailyMedicalRecords:'',
            nursingRoundTheClock:'',
            hasOperationTheatre:'',

            citydis:false,
            doclistedit:false,
            ptype_:false,
            sp_other:false,
            setstateofrequired:true,
            mydatais:'',
            s_c_d_nm:false,
            drLstDilg:false,
            docen:false,
            docten:false,
            otherservicesprovided:'',
            other_pt:'',
            search_area_pageno:1,
            area_page_no:1,
            nextis:1,
            foundis:false,
            search:'',
            citysearch:'',
            pitems:[],
            isNonSrvcPrvdr: false,
            items:['Institution', 'Individual'],
            rgstrdEntity:'Institution',
            entityNme:'',
            alsNme:'',
            ptypeis:[
                'Hospital',
                'Daycare Center',
                'Dental',
                'Nursing Home',
                'Clinic',
                'Rehabilitation Center',
                'Pharmacy',
                'Diagnostic Centre',
                'Home Care provider',
                'Hospice',
                'Ambulance',
                'Air Ambulance',
                'Telehealth Provider',
                'Psychiatric Facility',
                'Other',],
            prvdrtyeitems:[
                'Internal Medicine',
                'General Surgery',
                'Obstetrics & Gynecology',
                'Paediatrics',
                'Orthopaedic',
                'Gastroenterology & Hepatology',
                'Nephrology',
                'Urology',
                'Endocrinology',
                'Rheumatology',
                'Pulmonology',
                'Ophthalmology',
                'Otorhinolaryngology',
                'Cardiology',
                'Cardiothoracic Vascular Surgery (CTVS)',
                'Interventional Radiology',
                'Thoracic Surgery',
                'Neurology',
                'Neurosurgery',
                'Neuropsychiatry',
                'Medical Oncology',
                'Surgical Oncology',
                'Radiation Oncology',
                'Gynaecological Oncology',
                'GI Surgery',
                'Colorectal Surgery/Proctology',
                'Transplant Surgery',
                'Hand Surgery',
                'Plastic and Reconstructive Surgery',
                'Paediatric Surgery',
                'Neonatology',
                'Psychiatry',
                'Dermatology',
                'Anaesthesiology',
                'Dental - Orthodontics',
                'Dental - Endodontics',
                'Dental - Oral and Maxillofacial Surgery',
                'Dental - Periodontics',
                'Dental - Prosthodontics',
                'Ayurveda',
                'Unani',
                'Siddha',
                'Homeopathy',
                'Other'
            ],
            providerType:"",
            provider_Id:'',
            reviewRemarks:'',
            other:"",
            rgstNo:"",
            RgstrtnVldUpto:"",
            RgstrnVldUptoInstncalender: false,
            RgstrnVldUptoInstdate: "",
            SrvcsPrvded:"",
            tlphnNo:"",
            isNonSrvcPrvdrtlphnNo:"",
            pan:"",
            prn:"",
            isNonSrvcPrvdrprn:"",
            status:"",
            isNonSrvcPrvdrstatus:"",
            rohiniId:"",
            RgstrdBdy:"",
            email:"",
            isNonSrvcPrvdremail:'',
            fxNo:"",
            isNonSrvcPrvdrfxNo:"",
            adrsLne1:"",
            adrsLne2:"",
            adrsLne3:"",
            country:"",
            cntryItems:["Others"],
            stateItems:[],
            state:"",
            district:"",
            districtItems:[],
            pnCde:"400092",
            ctvItems:["Other"],
            ctv:"",
            othrAdrs:"",
            noOfBeds:"",
            dlyMdclRcrdsItems:['Yes','No'],
            dlyMdclRcrds:"",
            hsOtItems:['Yes','No'],
            hsOt:"",
            mprtcItems:['Yes','No'],
            mprtc:"",
            nrtcItems:['Yes','No'],
            nrtc:"",
            shwInstitution:true,
            shwIndividual:false,
            salutationind:'',
            sltnIndItems:["Mr.","Mrs"],
            frstnmeInd:'',
            mdlnmeInd:'',
            lstnmeInd:'',
            gndrInd:'',
            mbNoInd:'',
            extrnlRfNoInd:'',
            prnInd:'',
            statusInd:"",
            statusIndItems:["Active", "Inactive"],
            prvdrTypeInd:'',            
            otherInd:'',
            qualificationInd:'',
            otherInd2:'',
            RgstNoItems:['Others'],
            // RgstNo:'',
            RgstCnlInd:'',
            OtherInd3:'',
            QualificationInd2:'',
            remarksInd:'',
            counter:0,
            max05:5,
            max10:10,
            max20:20,
            max30:30,
            max40:40,
            max50:50,
            max100:100,
            max200:200,
            vldExtDilg:false,
            pid:'',
            duplicateData: [],
            duplicatePanData: []
            // numberRule: v  => {
            //     if (!v.trim()) return true;
            //     if (!isNaN(parseFloat(v)) && v >= 0 && v >=15) return true;
            //     return 'Provider does not meet minimum bed criteria';
            // },
        };
    },

    methods:{

        nameRulesUpdateFlag(v){
                if(v.length > 0){
                    return v => /^[a-zA-Z ]+$/.test(v) || 'Only alphabets are allowed'
                }
        },

        submitUpdateFlag(){
            this.$refs.updateForm.validate()
            if(!this.$refs.updateForm.validate()){
                    console.log("not valid form");
                    this.showToast("Please fill all fields",this.TOST().WARNING)
            }else{
                let id =  this.indtype.data.indata._id
                let formDataUpdate =  {
                                        prn :  this.updatePRN,
                                        providerName : this.updateProviderName,
                                        providerStatus : this.updateProStatus,
                                        Platinum : this.platinum,
                                        Gold : this.gold,
                                        Silver : this.silver,
                                        Verified : this.verifiedFlag ,
                                        underObservation : this.observationFlag,
                                        nonServiceProvider : this.updateFlagNonService,
                                        remarks : this.updateRemarks,
                                    }   
                
                this.PUT(2,'provider/update-flag/'+id, formDataUpdate, (res,error)=>{
                                        if(!error) {
                                                console.log("response data for update flag",res);
                                                this.showToast(res.data.msg,this.TOST().SUCCESS)
                                        }else{
                                            console.log("error response",error.response);
                                            this.showToast(error.response.data.msg,this.TOST().ERROR)
                                        }
                   })

            }
                


        },


        updateFlag(){
            console.log("update flag was clicked");
            this.updateFlagStatus = true
        },




        panErrorMsg(){ 
            if(this.indtype.type === 'edit'){
                console.log("%c This is edit error for provider","color: violet");
                this.$emit('toggle-summary', this.panDupliErrorId)
            }else{
                this.$router.push({name: 'ProviderSummary', params: {id: 'institution',  prn: this.panDupliErrorId }});
            }                     
        },

    //    This is for click on entity name  for Duplicate provider Dialog
        providerDupliErrorMsg(){
            console.log("%c this for provider duplication", "color:red");
            if(this.indtype.type === 'edit'){
                console.log("%c This is edit error for provider","color: violet",this.proiderDupliErrorId);
                this.$emit('toggle-summary', this.proiderDupliErrorId)
            }else{
                console.log("%c This is edit error for provider from else","color: brown",this.proiderDupliErrorId);
                this.$router.push({name: 'ProviderSummary', params: {id: 'institution',  prn: this.proiderDupliErrorId }});
            }
        },

    //    This is for Yes No button  for Duplicate provider Dialog
        errForDupProvider(ans){
            if(ans == 'Yes'){
                this.errDialog = false
                this.isOverrideForError = true
            }else{
                this.isOverrideForError = false
                this.errDialog = false
            }
           
        },

       

        deletelocal(indata_id){

            console.log(this.create_doc_arr,"deletelocal called ", indata_id);
            var poss = this.create_doc_arr.findIndex(e => e._uid == indata_id)
            console.log("poss is ",poss);
            this.create_doc_arr.splice(poss, 1);

        },

        doc_for_creation(docarr){

            if(docarr.length != 0){


                  this.create_doc_arr.push(docarr)

            }

        },

        nextPageBtn(page){
            
            // this.pageCount = page;
            var tra =  this.page*5;
            this.recordFrom = tra-4;
            this.recordTo = tra;
            this.open_statushstry();
        },

        closeeditdoc(){
            // console.log("close edit of edit docs list")
            // alert("close doc edit");
            
            var sel_doc_list = []
        
            for(let i =0; i<this.docspool.length; i++){
                if(this.docspool[i].sel_doc == true){
                     console.log("if true", this.docspool[i]._id)
                    sel_doc_list.push(this.docspool[i]._id)
                }
            }

            if(sel_doc_list.length == 0){
               
                this.showToast("Restoring original state of selected doctors, as none was selected and close" ,this.TOST().WARNING);
                this.getdocspool(2)
                // return
            }
          
            this.doclistedit = false


        },

        clearall_doclist(){
               
                for(let i=0; i<this.docspool.length; i++){
                    this.docspool[i].sel_doc =  false
                }

             this.$forceUpdate();
             console.log("on clear done ?",   this.docspool)

        },

        update_doctors_list(){

            var sel_doc_list = []
            this.docspool.map(e=>{
                console.log("e.sel_doc", e.sel_doc)
                if(e.sel_doc == true){
                     console.log("if true", e._id)
                    sel_doc_list.push(e._id)
                }
            })

            if(sel_doc_list.length == 0){
                this.showToast("Please, select atleast one doctor to continue" ,this.TOST().WARNING);
                return
            }


            var formData = {
                
                "_id": this.indtype.data.indata._id,
                "doctorsList": [...sel_doc_list]
                
            }

            console.log("now wid clear function, fd of selected docs list ",formData);

            // return

            // /provider/update-doctors-list
            this.PUT(2,'provider/update-doctors-list', formData, (res, error)=>{
                    console.log("PUT APi response from UPDATE DOCS list|-> provider/update-doctors-list ", res);
                    if(res != null){
                        // alert("updated doc list")
                        this.showToast(res.data.msg ,this.TOST().SUCCESS);
                    }else{
                        // console.log("when status is not 200", res);
                        this.showToast(error.response.data.msg ,this.TOST().ERROR); 
                    }
                
                    this.provider_institution_updated()

                    
            })

        },

        provider_institution_updated(){

         
             
               this.GET(2,'provider/get-provider-institution/'+this.indtype.data.indata._id, response=>{
          
                  console.log("response  institution latest data for docs list >>>>>>", response.data.data.doctorsList);
                  this.updated_doc_list = response.data.data.doctorsList
                 
                  console.log("the this.updated_doc_listt >>>>>>",  this.updated_doc_list);
                  this.getdocspool(2)
                  //  self.showToast(response.data.msg,self.TOST().SUCCESS)
                })
               

        

        },

        getdocspool(ident){
            // dp
                console.log("get docs ident is ", ident);
              this.GET(2,'provider/doctors-list', res=>{
                    console.log("GETTING POOL OF DOCSTORS  => ", res.data);
                  try{
                       
                        this.docspool = res.data.data
                        this.docspool.map(e=>{
                                e.sel_doc = false
                        })
                          console.log("all docs -> ",  this.docspool);
                        var doclist_from = []
                      doclist_from =  ident == 1 ? this.indtype.data.indata.doctorsList : this.updated_doc_list
                    console.log("updated all docs are length  -> ",  this.updated_doc_list);
                    if(this.updated_doc_list.length == 0 || this.updated_doc_list == null || this.updated_doc_list == undefined){
                      console.log("updated all docs  -> ",  this.updated_doc_list);
                          doclist_from =  this.indtype.data.indata.doctorsList 

                    }
                    this.docson_are = []
                      console.log(ident,"doclist from is =>", doclist_from);
                        for(let i=0; i< doclist_from.length; i++){

                          this.docspool.map(e=>{
                                
                                // console.log("bEFORE what we have here for sel_doc ",e)
                                if(e._id == doclist_from[i]){
                                    // console.log("matched items",doclist_from[i] == e._id);
                                    e.sel_doc = true;
                                    this.docson_are.push(e._id);

                                }
                                //  console.log("AFTER what we have here for sel_doc ",e)
                            })
                            // this.indtype.data.indata.doctorsList

                        }
                        // console.log("all docs is after calling getdocs res -> ",  this.docspool);
                        this.enabledocslist = true
                        // gd
                   } catch (error) {
                       console.log("in get docs error occurred",error)
                   }
                       
                    
                                   
             })

        },

        cityselected(indata){

            if(indata == 'Other'){
                this.citydis = true
            }else{
                this.citydis = false
            }

        },
        // newsave(){
        //       this.$v.$touch();
        //     console.log("new save called experimental",this.$v);
        // },
        spchange(indata){
            console.log("indata this.SrvcsPrvded ",this.SrvcsPrvded)

            this.SrvcsPrvded.map(e=>{
                if(e == 'Other'){
                    this.sp_other = true
                }else{
                    this.sp_other = false
                }
            })

               this.SrvcsPrvded.map(e=>{
                
                     if(e == 'Other'){
                            this.sp_other = true
                      }    
               })



               if(this.SrvcsPrvded.length == 0){
                   this.sp_other = false
               }

               if(this.sp_other == false){
                   this.otherservicesprovided = ''
               }
            

             console.log("after  this.SrvcsPrvded",  this.SrvcsPrvded)
            

           

        },

        opendocs(){
              
                this.docen = true
            //   this.indtype.data.indata._id
        },

        chkplcydata(data){
            console.log("Policy sara",data); 
            try{
                if (data ==="" || data === "undefined" || data === null) {
                    return ""
                } else {
                    return data;
                }

             }catch(err) {
                console.log(err)
            }
            
        },

        setformdata(){
            
            console.log("ind type indata is -> in setformdatra caalled ",this.indtype.data.indata)
            
            this.provider_Id = this.indtype.data.indata._id
            console.log("PROVIDDER ID is ",this.provider_Id);
            this.updatePRN = this.chkplcydata(this.indtype.data.indata.prn)
            this.updateProviderName = this.chkplcydata(this.indtype.data.indata.entityName)
            this.updateProStatus = this.chkplcydata(this.indtype.data.indata.status)
             this.entityNme = this.chkplcydata(this.indtype.data.indata.entityName)
             this.alsNme = this.chkplcydata(this.indtype.data.indata.aliasName)
             this.providerType = this.chkplcydata(this.indtype.data.indata.providerType)
             this.other_pt = this.chkplcydata(this.indtype.data.indata.providerTypeOther)
             this.rgstNo = this.chkplcydata(this.indtype.data.indata.registrationNumber)
             this.SrvcsPrvded = this.chkplcydata(this.indtype.data.indata.servicesProvided)
             this.otherservicesprovided = this.chkplcydata(this.indtype.data.indata.servicesProvidedOther)
             this.tlphnNo = this.chkplcydata(this.indtype.data.indata.telephoneNumber === 0 ? "" : this.indtype.data.indata.telephoneNumber)
             this.prn = this.chkplcydata(this.indtype.data.indata.prn)
             this.pan = this.chkplcydata(this.indtype.data.indata.panNumber)
             this.rohiniId = this.chkplcydata(this.indtype.data.indata.rohiniId === 0 ? '' : this.indtype.data.indata.rohiniId)
             this.RgstrdBdy = this.chkplcydata(this.indtype.data.indata.registeredBody)
             this.status = this.chkplcydata(this.indtype.data.indata.status)
             this.email = this.chkplcydata(this.indtype.data.indata.email)
             this.fxNo  = this.chkplcydata(this.indtype.data.indata.faxNumber === 0 ? "" : this.indtype.data.indata.faxNumber)
       
             this.adrsLne1 = this.chkplcydata(this.indtype.data.indata.addressLine1)
             this.adrsLne2 =this.chkplcydata( this.indtype.data.indata.addressLine2)
             this.adrsLne3 = this.chkplcydata(this.indtype.data.indata.addressLine3)
             
            //  this.state = this.indtype.data.indata.state
            //  this.district = this.indtype.data.indata.district
            //  this.ctv = this.indtype.data.indata.cityTownVillage
             this.othrAdrs = this.chkplcydata(this.indtype.data.indata.cityOthers)


            //  this.noOfBeds = this.indtype.data.indata.hasOwnProperty('validationDetails')? this.indtype.data.indata.validationDetails.hasOwnProperty('noOfBeds')?this.chkplcydata(this.indtype.data.indata.validationDetails.noOfBeds) :'' :''
            //  this.noOfBeds = this.chkplcydata(this.indtype.data.indata.validationDetails.noOfBeds === 0 ? "" : this.indtype.data.indata.validationDetails.noOfBeds)
            this.noOfBeds=this.chkplcydata( this.indtype.data.indata.noOfBeds=== 0 ? "":this.indtype.data.indata.noOfBeds)
             this.RgstrnVldUptoInstdate = this.chkplcydata(this.indtype.data.indata.registrationValidUpto) ===  '' ? '': moment(this.indtype.data.indata.registrationValidUpto,'YYYY-MM-DD').format('YYYY-MM-DD')
             this.R = this.chkplcydata(this.indtype.data.indata.registrationDate) ===  '' ? '': moment(this.indtype.data.indata.registrationDate,'YYYY-MM-DD').format('YYYY-MM-DD')
             this.effectivefrom = this.chkplcydata(this.indtype.data.indata.registrationDateEffectiveFrom) ===  '' ? '': moment(this.indtype.data.indata.registrationDateEffectiveFrom,'YYYY-MM-DD').format('YYYY-MM-DD')
             this.effectiveto = this.chkplcydata(this.indtype.data.indata.registrationDateEffectiveTo) ===  '' ? '': moment(this.indtype.data.indata.registrationDateEffectiveTo,'YYYY-MM-DD').format('YYYY-MM-DD')
             this.medicalPractionerRoundTheClock =this.chkplcydata( this.indtype.data.indata.medicalPractionerRoundTheClock)
             this.dailyMedicalRecords = this.chkplcydata(this.indtype.data.indata.dailyMedicalRecords)
             this.nursingRoundTheClock  = this.chkplcydata(this.indtype.data.indata.nursingRoundTheClock)
             this.hasOperationTheatre = this.chkplcydata(this.indtype.data.indata.hasOperationTheatre)
             console.log( this.status," this.status")
             if(this.indtype.type==='validate'|| this.status==='Registered'){
               console.log("parent provider id ", this.indtype.data.indata.parentProvider,this.childprovider);
                 this.childprovider=this.chkplcydata(this.indtype.data.indata.childProvider)
                 if(this.childprovider===true){
                  
                    console.log("parent provider id ", this.indtype.data.indata.parentProvider);
                    this.get_single_provider(this.indtype.data.indata.parentProvider._id);
                    this.mappingtypeis=this.chkplcydata(this.indtype.data.indata.mappingType)
                }

             }
            //  this.provider_Id = this.indtype.data.indata._id

            //     console.log("PROVIDDER ID is ",this.provider_Id);
            //     // this.pid = this.indtype.data.indata._id
            // console.log("PROVIDDER ID is =====> ",this.pid);

            //  isNonservicePro 
            // this.isNonSrvcPrvdr = this.indtype.data.indata.isNonServiceProvider
            // console.log("this is nonService provider" + this.isNonSrvcPrvdr);
            // this.isNonSrvcPrvdremail =  this.indtype.data.indata.email
            // this.isNonSrvcPrvdrtlphnNo =  this.indtype.data.indata.telephoneNumber
            // this.isNonSrvcPrvdrfxNo =  this.indtype.data.indata.faxNumber
            // this.pnCde = this.indtype.data.indata.pinPostalCode
            
             this.callapi(this.indtype.data.indata.pinPostalCode,1)
            //  this.pnCde = 
            //  this.grab_pincode(this.indtype.data.indata.pinPostalCode)
            //  this.country = this.indtype.data.indata.country
            //  this.state = this.indtype.data.indata.state
            //  this.district = this.indtype.data.indata.district
            //  this.ctv = this.indtype.data.indata.cityTownVillage

        },
        
        get_single_provider(indata_institution_id){
            let self= this
            self.GET(2,'provider/get-provider-institution/'+indata_institution_id, (res,err)=>{
                
                    if(res!==null){
                          console.log(">>>>>response institution latest data for homepage work item to product validation", res)
                            if(!res.data.data.hasOwnProperty('entityName')){
                                this.showToast("Entity Name not found",this.TOST().ERROR); 
                                return
                            }

                           self.GET(2,'provider/search-providers-by-name/'+res.data.data.entityName,( res,err) => {
                               console.log("search by providers res --> ", res.data);
                                if(!err){

                                    console.log("self is response",res);
                                    const providers = []
                                    for(let provider of res.data.data.data){
                                            console.log(provider.empanelmentProvider);
                                            if(provider.registeredEntity === "Institution"){
                                                providers.push({entityName:provider.entityName,_id:provider._id})
                                            }else if(provider.registeredEntity === "Individual"){
                                                providers.push({entityName:provider.firstName,_id:provider._id})
                                            } 
                                    }
                                    self.entries = providers
                                    self.entries.map(e=>{
                                        if (e._id===indata_institution_id){
                                            self.parentprovider=e
                                        }

                                    })
                                    console.log(self.parentprovider,"self.parentprovider")
                                }else{
                                    self.showToast(err.response.data.msg ,self.TOST().ERROR);
                                }
                            });
                            // this.searchApi(res.data.data.entityName)
                        //   this.parentprovider={entityName:res.data.data.entityName,_id:res.data.data._id}
                        //   console.log(this.parentprovider,"this.parentprovider")
                        //   this.showToast(res.data.msg,this.TOST().SUCCESS)
                    
                    }else if(res===null){
                        console.log("POST APi error response|-> >>>>>response institution latest data for homepage work item to product validation", err)
                        // this.showToast(err.response.data.msg ,this.TOST().ERROR);

                    }
            })
         },

        ptypechange(indata){

            if(indata == 'Other'){
                this.ptype_ = true
            }else{
                this.ptype_ = false
            }

        },

        isNonServiceProSetData(){
        // this.isNonSrvcPrvdr = this.indtype.data.indata.isNonServiceProvider
        // // this.isNonSrvcPrvdrstatus =   this.indtype.data.indata.status
        // this.entityNme =   this.indtype.data.indata.entityName
        // this.alsNme =   this.indtype.data.indata.entityName
        // this.isNonSrvcPrvdremail =   this.indtype.data.indata.entityName
        // this.isNonSrvcPrvdrtlphnNo =   this.indtype.data.indata.entityName
        // this.isNonSrvcPrvdrfxNo =   this.indtype.data.indata.entityName
        // this.adrsLne1 =   this.indtype.data.indata.entityName
        // this.adrsLne2 =   this.indtype.data.indata.entityName
        // this.adrsLne3 =   this.indtype.data.indata.entityName
        // this.ctv =   this.indtype.data.indata.entityName
        // this.othrAdrs =   this.indtype.data.indata.entityName
        // this.district =   this.indtype.data.indata.entityName
        // this.state =   this.indtype.data.indata.entityName
        // this.country =   this.indtype.data.indata.entityName
        console.log("%c setting isNonserviceprovider","color:orange",this.indtype.data.indata.isNonServiceProvider);
        this.isNonSrvcPrvdr = this.indtype.data.indata.isNonServiceProvider
        this.isNonSrvcPrvdremail =  this.indtype.data.indata.email
        this.isNonSrvcPrvdrtlphnNo =  this.chkplcydata(this.indtype.data.indata.telephoneNumber === 0 ? "" : this.indtype.data.indata.telephoneNumber)
        this.isNonSrvcPrvdrfxNo =  this.chkplcydata(this.indtype.data.indata.faxNumber === 0 ? "" : this.indtype.data.indata.faxNumber)
        // this.callapi(this.indtype.data.indata.pinPostalCode,1)
        this.isNonSrvcPrvdrstatus = this.indtype.data.indata.status
        this.updateProStatus = this.indtype.data.indata.status
     

        },
        
        isNonServProviderSave(){
            console.log("%c I am is nonService provider","color:green");
            //    console.log(this.indtype,"***************** is working", this.setdata);
            // console.log(this.indtype.data.indata._id);
            if(this.indtype.type != 'new'){
                  var my_id = this.indtype.data.indata._id
            }
          
          
            this.$v.$touch();
            
            console.log("save is working for isNonServiceProvider", this.isNonSrvcPrvdr);
             if(this.$v.othrAdrs.$invalid || this.$v.isNonSrvcPrvdrtlphnNo.$invalid ||  this.$v.isNonSrvcPrvdrfxNo.$invalid || 
             this.$v.country.$invalid || this.$v.state.$invalid ||this.$v.district.$invalid || 
             this.$v.adrsLne1.$invalid || this.$v.adrsLne2.$invalid ||  this.$v.alsNme.$invalid ||
              this.$v.isNonSrvcPrvdremail.$invalid || this.$v.entityNme.$invalid){
                console.log("Form has errors");  
                this.showToast('Please fill all required fields', this.TOST().WARNING);
                  console.log(" this.$v in if", this.$v);
                return
            }else{
                // console.log("this is the other field", this.$v.ctv.$model());
                console.log('proceed');
                  console.log(" this.$v ", this.$v);
            } 

            console.log("will hit api now ");
             
                // fd99
                // console.log(this.indtype.data.indata._id);
                // var idNonSerPro = this.indtype.data.indata._id;
              if(this.indtype.type != 'new'){
                var isNonServProPutFormData = {
                    "_id": my_id,
                    "isNonServiceProvider": this.isNonSrvcPrvdr,
                    "registeredEntity": "Institution",
                    "status": this.isNonSrvcPrvdrstatus,
                    "entityName": this.entityNme,
                    "aliasName": this.alsNme,
                    "email": this.isNonSrvcPrvdremail,
                    "telephoneNumber":(this.isNonSrvcPrvdrtlphnNo === 0 || this.isNonSrvcPrvdrtlphnNo === '')  ? "" : parseInt(this.isNonSrvcPrvdrtlphnNo),
                    "faxNumber": (this.isNonSrvcPrvdrtlphnNo === 0 || this.isNonSrvcPrvdrtlphnNo === '')  ? "" : parseInt(this.isNonSrvcPrvdrtlphnNo),
                    "addressLine1": this.adrsLne1,
                    "addressLine2": this.adrsLne2,
                    "addressLine3": this.adrsLne3,
                    "pinPostalCode":  parseInt(this.pnCde.pin) ,
                    "cityTownVillage": this.ctv,
                    "cityOthers": this.othrAdrs,
                    "district": this.district,
                    "state": this.state,
                    "country": this.country,
                    "isOverride": this.isOverrideForError,
                }
              }



                var isNonServProFormData = {
                    "uploadDocs": this.create_doc_arr,
                    "isNonServiceProvider": this.isNonSrvcPrvdr,
                    "registeredEntity": "Institution",
                    "status": this.isNonSrvcPrvdrstatus,
                    "entityName": this.entityNme,
                    "aliasName": this.alsNme,
                    "email": this.isNonSrvcPrvdremail,
                    "telephoneNumber":(this.isNonSrvcPrvdrtlphnNo === 0 || this.isNonSrvcPrvdrtlphnNo === '')  ? "" : parseInt(this.isNonSrvcPrvdrtlphnNo),
                    "faxNumber": (this.isNonSrvcPrvdrfxNo === 0 || this.isNonSrvcPrvdrfxNo === '') ? "" : parseInt(this.isNonSrvcPrvdrfxNo),
                    "addressLine1": this.adrsLne1,
                    "addressLine2": this.adrsLne2,
                    "addressLine3": this.adrsLne3,
                    "pinPostalCode": parseInt(this.pnCde.pin) ,
                    "cityTownVillage": this.ctv,
                    "cityOthers": this.othrAdrs,
                    "district": this.district,
                    "state": this.state,
                    "country": this.country,
                    "isOverride": this.isOverrideForError,
                }



                    console.log("stopped simulation");
                   

             let api_Is = this.indtype.type == 'new' ? 'provider/create-provider-institution' : 'provider/edit-provider-institution'
                console.log(api_Is);
                console.log(this.indtype.type);

                if(api_Is != 'provider/edit-provider-institution'){
                    
                    this.POST(2,api_Is,isNonServProFormData,(res,error)=>{
                       if(!error) {
                            if(res.data.statusCode == 200){
                            this.showToast(res.data.msg ,this.TOST().SUCCESS);
                            this.$router.push('/ProviderSearch')
                        }else{
                            console.log("when status is not 200", res);
                            this.showToast(res.data.msg ,this.TOST().ERROR); 
                        }
                    }else{
                        console.log(error);
                        this.showToast(error.response.data.msg,this.TOST().ERROR); 
                        if(error.response.data.msg === "Potential Duplicate Provider"){
                            console.log("%c Potential Duplicate Provider for POST of IsNonService Provider ------>","color:red",error.response.data.msg);
                            console.log(error.response.data.data);
                            let dupArr = error.response.data.data;
                            for(let dupData of dupArr ){
                                console.log(dupData);
                                this.duplicateData.push(dupData);
                            }
                            this.errDialog = true
                        }
                    }
    
                        console.log("APi response from |-> provider/create-provider-institution ", res);
                    })
                }else{
                    console.log("Requesting put api.........");
                    this.PUT(2,api_Is,isNonServProPutFormData,(res,error)=>{
                       if(!error){ 
                           if(res.data.statusCode == 200){
                            this.showToast(res.data.msg ,this.TOST().SUCCESS);
                            this.$router.push('/ProviderSearch')

                        }else{
                            console.log("when status is not 200", res);
                            this.showToast(res.data.msg ,this.TOST().WARNING); 
                        }
                    }else{
                        console.log(error.response.data);
                        this.showToast(error.response.data.msg,this.TOST().ERROR);
                        if(error.response.data.msg == "Potential Duplicate Provider"){
                            console.log("%c Potential Duplicate Provider from put of IsNonServiceProvider","color:darkblue");
                            console.log(error.response.data.data);
                            let dupArr = error.response.data.data;
                            for(let dupData of dupArr ){
                                console.log(dupData);
                                this.duplicateData.push(dupData);
                            }
                            this.errDialog = true
                        } 
                    }
    
                        console.log("APi response from |-> provider/edit-provider-institution ", res);
                    })
                }
        },
        

        nowsaveit(){
            console.log("this is date ---->", typeof(this.RgstrnVldUptoInstdate));
            console.log("%c this is the value of isNonServiceProvider","color:green",this.isNonSrvcPrvdr);
           console.log("%c nowsaveit is working", "color:red",this.$v);
           this.$v.$touch();
   
            // console.log(" this.$v ", this.$v);
            // if(this.$v.mappingtypeis.$invalid || this.$v.parentprovider.$invalid){
            //     console.log("Form has errors");  
            //     this.showToast('Please fill all required fields mapping and parent provider fileds', this.TOST().WARNING);
            //       console.log(" this.$v in if", this.$v);
            //     return
            // }else{
            //     console.log('proceed as parent and mapping is not manadatory');
            //       console.log(" this.$v ", this.$v);
            // } 

            // return
           
            if(this.$v.othrAdrs.$invalid || this.$v.pan.$invalid || this.$v.country.$invalid || this.$v.ctv.$invalid || this.$v.state.$invalid ||this.$v.district.$invalid || this.$v.adrsLne1.$invalid || this.$v.adrsLne2.$invalid ||  this.$v.alsNme.$invalid || this.$v.isNonSrvcPrvdremail.$invalid || this.$v.entityNme.$invalid || this.$v.providerType.$invalid){
                console.log("Form has errors");  
                this.showToast('Please fill all required fields', this.TOST().WARNING);
                // console.log(" this.$v in if", this.$v);
                return
            }else{
                console.log('proceed');
                  console.log(" this.$v ", this.$v);
            } 

            // return
              

              let reqPin = parseInt(this.pnCde.pin);
              console.log(reqPin);
                // fd99
                var formData = {
                    "uploadDocs": this.create_doc_arr,
                    "isNonServiceProvider": this.isNonSrvcPrvdr,
                    "isOverride": this.isOverrideForError,
                    "registeredEntity": "Institution",
                    "status": "",
                    "entityName": this.entityNme,
                    "aliasName": this.alsNme,
                    "panNumber": this.pan,
                    "providerType": this.providerType,
                    "providerTypeOther": this.other_pt == ''?'':this.other_pt,
                    "rohiniId": this.rohiniId  === ''? 0 : parseInt(this.rohiniId),
                    "registrationNumber": this.rgstNo,
                    "registeredBody": this.RgstrdBdy,
                    "registrationValidUpto": this.RgstrnVldUptoInstdate,
                    // === null ? "" : this.RgstrnVldUptoInstdate,
                    "email": this.email,
                    "telephoneNumber": this.tlphnNo === ""? 0 : parseInt(this.tlphnNo),
                    "faxNumber": this.fxNo,
                    "servicesProvided": [...this.SrvcsPrvded],
                    "servicesProvidedOther": this.otherservicesprovided == '' ? '':this.otherservicesprovided,
                    "addressLine1": this.adrsLne1,
                    "addressLine2": this.adrsLne2,
                    "addressLine3": this.adrsLne3,
                    "pinPostalCode": reqPin ,
                    "cityTownVillage": this.ctv,
                    "cityOthers": this.othrAdrs,
                    "district": this.district,
                    "state": this.state,
                    "country": this.country,
                    "gstin": "string",
                    // "validationDetails":null
                    "validationDetails": {
                        "noOfBeds": this.noOfBeds === ""? 0 : parseInt(this.noOfBeds),
                        "medicalPractionerRoundTheClock": this.medicalPractionerRoundTheClock,
                        "dailyMedicalRecords": this.dailyMedicalRecords,
                        "nursingRoundTheClock": this.nursingRoundTheClock ,
                        "hasOperationTheatre":  this.hasOperationTheatre
                    }
                }

                    console.log("this is for post",formData);

                    console.log("stopped simulation")
                    // console.log("%c this is provider Id", "color:red", this.provider_Id);
                   
                   
                var putFormData = {
                   
                    "_id":this.provider_Id,
                    "isNonServiceProvider": this.isNonSrvcPrvdr,
                    "isOverride": this.isOverrideForError,
                    "registeredEntity": "Institution",
                    "status": "",
                    "entityName": this.entityNme,
                    "aliasName": this.alsNme,
                    "panNumber": this.pan,
                    "providerType": this.providerType,
                    "providerTypeOther": this.other_pt == ''?'':this.other_pt,
                    "rohiniId": this.rohiniId  === '' ? 0 : parseInt(this.rohiniId),
                    "registrationNumber": this.rgstNo,
                    "registeredBody": this.RgstrdBdy,
                    "registrationValidUpto": this.RgstrnVldUptoInstdate,    
                    "email": this.email,
                    "telephoneNumber": this.tlphnNo === ""? 0 : parseInt(this.tlphnNo),
                    "faxNumber": this.fxNo === ""? 0 : parseInt(this.fxNo),
                    "servicesProvided": [...this.SrvcsPrvded],
                    "servicesProvidedOther": this.otherservicesprovided == '' ? '':this.otherservicesprovided,
                    "addressLine1": this.adrsLne1,
                    "addressLine2": this.adrsLne2,
                    "addressLine3": this.adrsLne3,
                    "pinPostalCode": reqPin ,
                    "cityTownVillage": this.ctv,
                    "cityOthers": this.othrAdrs,
                    "district": this.district,
                    "state": this.state,
                    "country": this.country,
                    "gstin": "string",
                    // "validationDetails":null
                    "validationDetails": {
                        "noOfBeds": this.noOfBeds === ""? 0 : parseInt(this.noOfBeds),
                        "medicalPractionerRoundTheClock": this.medicalPractionerRoundTheClock,
                        "dailyMedicalRecords": this.dailyMedicalRecords,
                        "nursingRoundTheClock": this.nursingRoundTheClock ,
                        "hasOperationTheatre":  this.hasOperationTheatre
                    }
                }

                    console.log("this is for put",putFormData);

                    // console.log("putFormData ", putFormData)
                // return
                    // {
                    //     "noOfBeds": 0,
                    //     "medicalPractionerRoundTheClock": "string",
                    //     "dailyMedicalRecords": "string",
                    //     "nursingRoundTheClock": "string",
                    //     "hasOperationTheatre": "string"
                    // }

                 console.log(putFormData," <- formdata put | stopped simulation | post formdata -> ", formData);

                

                console.log(this.indtype.type);
                var api_Is = this.indtype.type == 'new' ? 'provider/create-provider-institution' : 'provider/edit-provider-institution'
                console.log(api_Is);
                console.log(this.indtype.type);
                var self = this

                if(api_Is != 'provider/edit-provider-institution'){
                    
                    this.POST(2,api_Is,formData,(res,error)=>{
                        if(!error){
                            if(res.data.statusCode == 200){
                            self.showToast(res.data.msg ,self.TOST().SUCCESS);
                             self.$router.push('/ProviderSearch')
                        }else{
                            console.log("when status is not 200", res);
                            self.showToast(res.data.msg ,self.TOST().ERROR); 
                        }
                    }else{
                        console.log(error);
                        this.showToast(error.response.data.msg,this.TOST().ERROR);
                        if(error.response.data.msg == "Potential Duplicate Provider"){
                            console.log("%c Potential Duplicate Provider", "color:green");
                            // this.providerDupliEntityName = error.response.data.data.entityName
                            // this.providerDupliAliasName = error.response.data.data.aliasName
                            // this.proiderDupliErrorId  = error.response.data.data._id
                            console.log(error.response.data.data);
                            let dupArr = error.response.data.data;
                            for(let dupData of dupArr ){
                                console.log(dupData);
                                this.duplicateData.push(dupData);
                            }
                            console.log("%c this is from page data", "color:blue", this.duplicateData);
                            this.errDialog = true
                        }
                        if(error.response.data.msg == "Provider with same PAN number already exists"){
                            console.log("Provider with same PAN number already exists");
                            console.log("%c this is pan error data from POST","color:red",error.response.data);
                             let dupPanArr = error.response.data.data;
                            console.log("%c this is the object of data","color:green",dupPanArr);
                            for(let dupPanData of dupPanArr){
                                console.log("%c this is duplicate pan data","color: darkblue",dupPanData);
                                this.duplicatePanData.push(dupPanData);
                            }
                            this.panErrorDialog = true
                        }
                    }
    
                        console.log("APi response from |-> provider/create-provider-institution ", res);
                    })
                }else{
                    console.log("Requesting put api.........");
                    this.PUT(2,api_Is,putFormData,(res,error)=>{
                       if(!error){
                            if(res.data.statusCode == 200){
                            self.showToast(res.data.msg ,self.TOST().SUCCESS);
                             self.$router.push('/ProviderSearch')
                        }else{
                            console.log("when status is not 200", res);
                            self.showToast(res.data.msg ,self.TOST().ERROR); 
                        }
                    }else{
                        console.log(error);
                        this.showToast(error.response.data.msg,this.TOST().ERROR);
                        if(error.response.data.msg == "Potential Duplicate Provider"){
                            console.log("Potential Duplicate Provider");
                            console.log("%c this is data for error in response","color:blue",error.response.data.data);
                            let dupArrInEdit = error.response.data.data;
                            console.log("%c this is the object of data","color:green",dupArrInEdit);
                            for(let dupDataEdit of dupArrInEdit){
                                console.log(dupDataEdit);
                                this.duplicateData.push(dupDataEdit);
                            }
                            this.errDialog = true
                        }
                        if(error.response.data.msg == "Provider with same PAN number already exists"){
                            console.log("Provider with same PAN number already exists");
                            console.log("%c this is pan error data from PUT","color:red",error.response.data);
                             let dupPanArr = error.response.data.data;
                            console.log("%c this is the object of data","color:green",dupPanArr);
                            for(let dupPanData of dupPanArr){
                                console.log("%c this is duplicate pan data","color: darkblue",dupPanData);
                                this.duplicatePanData.push(dupPanData);
                            }
                            this.panErrorDialog = true
                        } 
                    }
    
                        console.log("APi response from |-> provider/edit-provider-institution ", res);
                    })
                }

               

        },


        VldteInstitution(){
            this.$v.$touch();

            if(this.$v.noOfBeds.$invalid||this.$v.dailyMedicalRecords.$invalid||this.$v.hasOperationTheatre.$invalid||this.$v.medicalPractionerRoundTheClock.$invalid||this.$v.nursingRoundTheClock.$invalid){
                console.log("Form has errors");  
                this.showToast('Validation Details or Registration details is required for provider validation', this.TOST().WARNING);
                  console.log(" this.$v in if", this.$v);
                return
            }else{
                console.log('proceed as parent and mapping is not manadatory');
                  console.log(" this.$v ", this.$v);
            } 

            if(this.$v.mappingtypeis.$invalid || this.$v.parentprovider.$invalid){
                this.showToast('Parent provider & Mapping Type is required', this.TOST().WARNING);
                console.log(" this.$v in if", this.$v);
                return
            }

            if(this.providerType==='Hospital'||this.providerType==='Daycare Center'||this.providerType==='Nursing Home'){
                console.log("inside if isVldtnDtls function")
                console.log(this.RgstrdBdy,this.rgstNo)
                // if((this.RgstrdBdy==="" && this.rgstNo==="")||(this.RgstrdBdy===undefined  && this.rgstNo===undefined)||(this.RgstrdBdy===null  && this.rgstNo===null)){
                if(this.RgstrdBdy==="" || this.rgstNo===""||this.RgstrdBdy===undefined  || this.rgstNo===undefined || this.RgstrdBdy===null || this.rgstNo===null){
                    if(parseInt(this.noOfBeds)<15){
                        this.showToast('Provider does not meet minimum bed criteria', this.TOST().WARNING);
                        return
                    }
                }
                
            }

            var formData = {
                    "_id":this.provider_Id,
                    // "isNonServiceProvider": this.isNonSrvcPrvdr,
                    "isOverride": true,
                    "registeredEntity": "Institution",
                    "status": "",
                    "entityName": this.entityNme,
                    "aliasName": this.alsNme,
                    "panNumber": this.pan,
                    "providerType": this.providerType,
                    "providerTypeOther": this.other_pt == ''?'':this.other_pt,
                    "childProvider": this.childprovider,
                    "parentProvider": this.parentprovider._id,
                    "mappingType": this.mappingtypeis,
                    "rohiniId": this.rohiniId  === ""? 0 : parseInt(this.rohiniId),
                    "registrationNumber": this.rgstNo,
                    "registeredBody": this.RgstrdBdy,
                    "registrationValidUpto":this.RgstrnVldUptoInstdate ,
                    "email": this.email,
                    "telephoneNumber": this.tlphnNo === ""? 0 : parseInt(this.tlphnNo),
                    "faxNumber": this.fxNo === ""? 0 : parseInt(this.fxNo),
                    "servicesProvided": [...this.SrvcsPrvded],
                    "servicesProvidedOther": this.otherservicesprovided == '' ? '':this.otherservicesprovided,
                    "addressLine1": this.adrsLne1,
                    "addressLine2": this.adrsLne2,
                    "addressLine3": this.adrsLne3,
                    "pinPostalCode":parseInt(this.pnCde.pin),
                    "cityTownVillage": this.ctv,
                    "cityOthers": this.othrAdrs,
                    "district": this.district,
                    "state": this.state,
                    "country": this.country,
                    "gstin": "string",
                    // "validationDetails":null
                    "validationDetails": {
                        "noOfBeds": this.noOfBeds === ""? 0 : parseInt(this.noOfBeds),
                        "medicalPractionerRoundTheClock": this.medicalPractionerRoundTheClock,
                        "dailyMedicalRecords": this.dailyMedicalRecords,
                        "nursingRoundTheClock": this.nursingRoundTheClock ,
                        "hasOperationTheatre":  this.hasOperationTheatre,
                        "registrationDateEffectiveFrom": this.effectivefrom,
                        "registrationDateEffectiveTo": this.effectiveto
                    }
                }

                console.log("stopped simulation",formData)

                this.POST(2,'provider/validate-institution', formData, (res,err)=>{
                    if(res!==null){
                          console.log("POST APi response|-> provider/send-back-institution ", res)
                          this.showToast(res.data.msg,this.TOST().SUCCESS)
                          this.$router.push('/homePage');
                    }else if(res===null){
                        console.log("POST APi error response|-> provider/send-back-institution ", err)
                        this.showToast(err.response.data.msg ,this.TOST().ERROR);

                    }
                })

        },

        sndBckInstitution(){

            if(this.reviewRemarks===""||this.reviewRemarks===null||this.reviewRemarks===undefined){
                this.showToast('Please enter Reviewer remarks to send back work item', this.TOST().WARNING);
                return

            }

            var formData = {
                "reviewerRemarks": this.reviewRemarks
            }

                console.log("stopped simulation")
                this.PUT(2,'provider/send-back-institution/'+this.provider_Id, formData, (res,err)=>{
                    if(res!==null){
                          console.log("POST APi response|-> provider/send-back-institution ", res)
                          this.showToast(res.data.msg,this.TOST().SUCCESS)
                    }else if(res===null){
                        console.log("POST APi error response|-> provider/send-back-institution ", err)
                        this.showToast(err.response.data.msg ,this.TOST().ERROR);

                    }
              
                })
               

        },

        dclneInstitution(){

            if(this.reviewRemarks===""||this.reviewRemarks===null||this.reviewRemarks===undefined){
                this.showToast('Please enter Reviewer remarks to decline provider', this.TOST().WARNING);
                return

            }

            var formData = {
                "reviewerRemarks": this.reviewRemarks
            }

                console.log("stopped simulation")
                this.PUT(2,'provider/decline-institution/'+this.provider_Id, formData, (res,err)=>{
                if(res!==null){
                    console.log("POST APi response|-> provider/decline-institution ", res)
                    this.showToast(res.data.msg,this.TOST().SUCCESS)
                }else if(res===null){
                    console.log("POST APi error response|-> provider/send-back-institution ", err)
                    this.showToast(err.response.data.msg ,this.TOST().ERROR);

                }
                })
               

        },
        dsblPP(){
            if(this.childprovider){
                console.log('true childprovider', this.childprovider)
                this.disablePP=false
                // this.disableMt=true
                this.disableMt=false
                this.parentprovider=''
                this.mappingtypeis=''
                // return true
            }else{
                console.log('false childprovider', this.childprovider)
                this.disablePP=true
                this.disableMt=true
                this.parentprovider=''
                this.mappingtypeis=''
                // return false
            }     

        },
        dsbleMT(){
            this.mappingtypeis=''
             if(this.parentprovider.length == 0){
                // this.disableMt=false
                this.disableMt=true
                // return false
            }else{
                this.disableMt=false
                // return true
            }

        },


        getcountries(){
            // return   
              this.GET(3,'country/get-country-all', res=>{
                console.log(res.status,'res of COUNTRIES is ', res);
                    if(res.status == 200){
                        this.cntryItems = [];
                        this.cntryItems = res.data.data.data.map(e=>{
                            return { country:e.country, all:e }
                        })
                    }

            })
        },
        
        grab_pincode(indata, from){
            // return
            // gp99
            console.log("GRABBED PIN-CODE CALLED FROM", indata)
            // alert("grabbed pin-code called")
              this.cntryItems = [];
              this.cntryItems.push({ country:indata.all.country, all:indata.all.country })
              this.country = indata.all.country;
               this.districtItems = [];
               this.districtItems.push({district:indata.all.district, all:indata.all.district })
               this.district = indata.all.district;

               this.stateItems = [];
               this.stateItems.push({state:indata.all.state, all:indata.all.state})
               this.state = indata.all.state;

               this.ctvItems = []
               
                this.ctvItems.push({area:indata.all.area,all:indata.all.area},{area:'Other'})
                this.ctv = indata.all.area

                 console.log( this.ctv ,"area is",   this.ctvItems )
            // alert("grabbed pin-code called")
           
            return
            console.log("grabbed pincode is --->",indata);    
            console.log("grabbed pincode WILL CALL DISTRICT --->",indata);         
            this.GET(3,'district/get-all?state='+indata.all.state , res=>{
                console.log('res of disctruct is ', res);
                    if(res.status == 200){
                        this.districtItems =  res.data.data.data;
                              console.log("districtItems from response is --->", this.districtItems);   
                         this.districtItems.map((e,index)=>{
                                console.log(e.district," -> ");
                             if(e.district ==indata.all.district){
                                //  alert("matched disssss")
                                 this.district = this.districtItems[index].district
                                 this.getstates(indata.all.country,this.districtItems[index].state);
                                 this.country = indata.all.country;
                                 console.log(this.districtItems[index],"<<< --selected dis ",  this.district)
                             }
                         })
                    }else{
                        console.log("something went wrong, pincode", res)
                    }
            })
        },

        dis_selected(){

            // clear pin code and 

        },

        callapi(indata, ident){
            // return
            console.log("ident is ", ident);
            console.log("call api is called", indata);
             this.GET(3,'city/search-by-pincode/'+indata, res=>{
                console.log("response of pincode api is  -> ", res.data.data);
                
                if(this.counter == 2){
                   this.pitems = [];
                   this.counter = 0
                }
                var pin_not_found = false
                if(res.data.data.length == 0){
                    // for making state city and district non-mandatory assign pin code response is zero
                    this.s_c_d_nm = true
                    pin_not_found = true
                    this.counter = ''
                    this.state = ''
                    this.district = ''
                    this.ctv = ''
                    
                    this.showToast("Pin Code not found" ,this.TOST().ERROR);

                }else{
                     this.s_c_d_nm = false
                }
                
                var processed_pin =  res.data.data.map(e=>{
                    return { pin:e.pincode.toString() ,all:e }
                })
                this.pitems.push(...processed_pin)

                console.log("|--==>>> ",this.pitems);
                if(pin_not_found){
                    this.pitems.push({pin:indata.toString(),all:''});
                }

                   if(ident == 1){
                    console.log("FROM IDENT ONE -> 1", this.indtype.data.indata);
                    this.pitems.map(e=>{
                        // console.log("e is ",e);
                        // && e.all.area == this.indtype.data.indata.cityTownVillage
                        if(e.pin == indata  && e.all.district == this.indtype.data.indata.district){
                            console.log(indata,"matched pin code is ",e)
                            this.pnCde = e
                        }
                    })

                        console.log("what we have after pin code is found ->",this.pnCde)
                    //    {pin:indata.toString(),all:{}};
                
                       this.grab_pincode(this.pnCde, 'Frm CALLED API');
                   }
                this.counter++
                     console.log("call api pitems in new call api", this.pitems);
                // this.pitems = res.data.data.data.map
                // console.log("pitems is -> ", this.pitems);
            })

        },

        getstates(countryincoming,stateincoming){
            // alert("get sstates called ??????")
                console.log("coiuntry is", countryincoming);
                console.log("incoming state is ", stateincoming)
                var self = this

            this.GET(3,'state/get-state-byCountry/'+countryincoming, res=>{
                
                //    if(res.status == 200){ 
                     self.stateItems = res.data.data
                    console.log( self.stateItems.length," RESPONSE of state api is  -> ",  self.stateItems);
                       self.stateItems.map(e=>{
                           console.log(stateincoming,"matching state in map",e.state)
                           if(e.state == stateincoming){
                               console.log(e,"MATCHED STATE FOUND",stateincoming)
                                self.state = stateincoming;
                                  console.log("vmodel of state is   -> ", self.state);
                                self.get_areas(self.district,0);
                              
                           }
                            // return {state: e.state, all:e}
                       })

                       
                //    }else{
                //        console.log(" something went wrong, state ", res);
                //    }
            })

        },

        get_areas(indata_district, ident){
            
            console.log("IDENT is -> ", ident);

            console.log(ident,"get areas called from state", indata_district)
            // aa99  
            //when recalling send this.district into it on page number incrementation
            console.log(this.pnCde.pin, " <--  pincode | Search area page no is -> ",this.search_area_pageno);
            console.log("area page no is -> ",this.area_page_no);
             var pageno =  ident == 1 ? this.search_area_pageno : this.area_page_no;
             console.log("pageno -> ",pageno);

                            // '/city/search-by-pincode/{pincode}' 
                            // +'&district='+indata_district+'&perPage=1000&pageNo='+pageno 
              this.GET(3,'city/search-by-pincode/'+this.pnCde.pin,(res,error)=>{
                        try {
                                
                                console.log("response of areas api is  -> ", res.data);
                               
                                if(res.status == 200){
                                    if(res.data.data.length == 0){
                                        console.log("City, Town, Area not found, limits reached or API reahed last page ?")
                                        return
                                    }
                                     this.ctvItems = []
                                    this.ctvItems = res.data.data.map(e=>{
                                        return {area:e.area,all:e}
                                    })
                                    this.ctvItems.push({area:'Other'})

                                    if(ident == 1){
                                        console.log(" stopped area's auto increment as ident from user search ", ident);
                                        
                                        return
                                    }
                                    console.log(" ctvItemss ", this.ctvItems.length);
                                    var ctvitemlen =  this.ctvItems.length
                                    var my_counter = 0
                                    var findareapromise = new Promise((resolve, reject)=>{
                                        // fix99
                                      if(this.indtype.type == 'validate' ||  this.indtype.type =='edit'){
                                        //   alert("as not from new")
                                           if(this.indtype.data.indata.cityTownVillage != ""){
                                                this.ctvItems.unshift({area:this.indtype.data.indata.cityTownVillage})
                                           }
                                          
                                           this.ctv = this.indtype.data.indata.cityTownVillage
                                            if(this.indtype.data.indata.cityTownVillage == 'Other'){
                                                this.citydis = true
                                            }else{
                                                 this.citydis = false
                                            }
                                           resolve("value found, dont call API, FROM EDIT OR VALIDATE")
                                           return

                                      }
                                      for(let i=0; i<this.ctvItems.length; i++){
                                            my_counter++
                                            if(this.pnCde.all.area == this.ctvItems[i].area){
                                                console.log(my_counter,"no need to recall API with Page update",this.ctvItems[i].area)
                                                this.ctv = this.pnCde.all.area
                                                resolve("value found, dont call API")
                                                return
                                            }
                                          
                                            if(ctvitemlen == my_counter){
                                                console.log(this.pnCde.all.area, "< rejected as ?",my_counter)
                                                console.log("i is ", i)
                                                reject("value not found, call API")
                                            }
                                               
                                            
                                        }
                                    })

                                    findareapromise.then(responseis=>{

                                        console.log( this.ctv,"do not call api area value found", this.ctv == this.pnCde.all.area)
                                        this.ctvItems.push({area:'Other'})
                                    }).catch(eis=>{
                                         console.log(eis,"area value not found call next records, increment page", this.ctv == this.pnCde.all.area)
                                        // call same api function here with page number increment and also pass district to it
                                        this.ctv = '';
                                        this.area_page_no++;
                                        // alert("from CATCH,value not found for AREA");
                                        this.get_areas(this.district,0)
                                    })

                                }else{
                                    console.log(" something went wrong, areas ", res);
                                }
                        } catch (error) {
                               console.log("from catch -> ",error); 
                               return
                        }
                      
              })
        },

        getpin_forstate(ident){
            this.GET(3,'city/pincodes?perPage=500&pageNo='+this.nextis+'&state=MAHARASHTRA', res=>{
                console.log("response of pincode api is  -> ", res.data);
                this.pitems = res.data.data.data;
                console.log("pitems is -> ", this.pitems);
            })
        },

         open_drLst(){
            this.drLstDilg=true
        },
        close_drLstFrm(){
            this.drLstDilg=false
        },
        close_ExtFrm(){
            this.vldExtDilg=false
        },
        close_panErrorDialog(){
            this.panErrorDialog=false
        },


        rgstrdEntySlctn(value){
            console.log(value)
            if(value==="Institution"){
                this.shwInstitution=true
                this.shwIndividual=false
            }else if(value==="Individual"){
                this.shwInstitution=false
                this.shwIndividual=true
            }

        },

         open_statushstry(){

            console.log(this.indtype.data.indata,"--> provider is for status history API ", this.indtype.data.indata._id);
               
               this.GET(2,'provider/history-by-provider/'+this.indtype.data.indata._id+'?perPage='+this.statusItemsPerPage+'&pageNo='+this.page, res=>{
                   console.log("history by provider -> ",res)
                   var sData = res.data.data.data;
                   this.pageLength = res.data.data.total_pages;
                   console.log(this.pageCount);
                    this.totalRecords = res.data.data.total
                    let tra =  this.page*5;
                    this.recordFrom = tra-4;
                    this.recordTo = tra

                   var statusData = [];
                   for(var data of sData){
                       
                       data.Date = moment(data.createdAt, moment.ISO_8601).format("DD/MM/YYY hh:mm:ss").substr(0,10);
                        data.Time = moment(data.createdAt, moment.ISO_8601).format("DD/MM/YYY hh:mm:ss").substr(13,15);
                        
                        statusData.push(data)
                   }
                   this.statusHisData = statusData;

                //    this.statusHisData = res.data.data.data
                //    var time = res.data.data.data[0].createdAt
                //     var time2 = moment(time, moment.ISO_8601).format("DD/MM/YYY hh:mm:ss");
                //     console.log(time2);
                    this.prvdrStsHstryDilg=true
               })
           
        },

        close_hstryFrm(){
            this.prvdrStsHstryDilg=false
        },

        extInstitution(){
            this.vldExtDilg=true
        },

        clearData(){
            console.log("clear is working");
            this.$v.$reset();
            this.entityNme = ''
            this.alsNme = ''
            this.pan = ''
            this.providerType = ''
            this.other_pt = ''
            this.rohiniId = ''
            this.rgstNo = ''
            this.RgstrdBdy = ''
            this.email = ''
            this.tlphnNo = ''
            this.fxNo = ''
            this.SrvcsPrvded = []
            this.otherservicesprovided = ''
            this.adrsLne1 = ''
            this.adrsLne2 = ''
            this.adrsLne3 = ''
            this.pnCde = ''
            this.ctv = ''
            this.othrAdrs = ''
            this.district = ''
            this.state = ''
            this.country = ''
            // "gstin": "string",
            // "validationDetails":null
            this.RgstrnVldUptoInstdate=''

            this.noOfBeds = ''
            this.medicalPractionerRoundTheClock = ''
            this.dailyMedicalRecords = ''
            this.nursingRoundTheClock  = ''
            this.hasOperationTheatre = ''
                    
        },

        isNonServProviderClear(){
            this.$v.$reset();
            this.isNonSrvcPrvdrstatus = ''
            this.entityNme = ''
            this.alsNme = ''
            this.isNonSrvcPrvdremail = ''
            this.isNonSrvcPrvdrtlphnNo = ''
            this.isNonSrvcPrvdrfxNo = ''
            this.adrsLne1 = ''
            this.adrsLne2 = ''
            this.adrsLne3 = ''
            this.pnCde = ''
            this.ctv = ''
            this.othrAdrs = ''
            this.district = ''
            this.state = ''
            this.country  = ''
        }
    }
}

</script>
<style >

.masterfixins{
    height: 820px; 
    background-color: rgb(255, 255, 255);
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.masterfixinsnon{
    height:560px; 
    background-color: rgb(255, 255, 255);
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.cust_pr .v-text-field .v-input__slot,
.v-text-field--outline .v-input__slot {
    min-height: 42px !important;
    display: flex !important;
    align-items: center !important;
    /* border:1px red solid; */
}

.cust-rmvpd-pr .v-text-field.v-text-field--enclosed>.v-input__control>.v-input__slot {
    padding: 0px !important;;
}
/* .cust_pr .v-input__slot{
    margin-bottom: unset !important;
} */
.cstm-clr-pr{
    color:#646666;
    margin-bottom: 5px;
    font-size: 13px !important;
    
}
.cust_pr .v-input {
    font-size: 13px !important;
}
.cust_pr .v-input input {
    font-size: 13px !important;
}
.cust_pr .v-input .v-label {
    font-size: 13px !important;
}

.title-clr-pr{
    color:#10242B;
    margin-bottom: 5px;
}

.lbl-bgclr{
    color:#152F38

}

.custPrn_Instn .theme--light.v-text-field--solo > .v-input__control > .v-input__slot {
    background: #F7F7F7;
    
}


.hdr-txt-clrnewtabel{
    color:#fff !important;
    background-color: #01b4bb;
    

}

.vaIcon i{
    margin-top: 4px;
}



</style>