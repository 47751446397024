<template>
    <div>
    <ClaimDetailsVue :claimData="claimDetails"></ClaimDetailsVue>
        <v-form ref="searchrecoveryRef" @submit.prevent="getRecovery()" class="mx-5 pt-2" v-if="!disableSearch">
            <v-row no-gutters class="mt-2">
                <v-col class="ml-3 mr-3">
                    <p class="claimlabel mb-2">LOB <span class="mandatorycolor">*</span></p>
                    <v-select
                        v-model="searchObj.lobType"
                        label="Select"
                        :items="lobOptions"
                        :rules="[mandatoryrule()]"
                        solo
                        dense
                        class="forminput"
                        clearable
                    ></v-select>
                </v-col>
                <v-col class="mr-3">
                    <p class="claimlabel mb-2">Claim Number </p>
                    <v-text-field
                        v-model="searchObj.claimNumber"
                        placeholder="Enter"
                        solo
                        dense
                        class="forminput"
                        clearable
                    ></v-text-field>
                </v-col>
                <v-col class="mr-3">
                    <p class="claimlabel mb-2">Feature Number </p>
                    <v-text-field
                        v-model="searchObj.featureNumber"
                        placeholder="Enter"
                        solo
                        dense
                        class="forminput"
                        clearable
                    ></v-text-field>
                </v-col>
                <v-col class="mr-3"></v-col>
                <v-col class="mr-3"></v-col>
            </v-row>
            <v-row class="mt-2" no-gutters>
                <v-col>
                    <v-btn outlined color="#D1121B" class="btnstyle mr-3" @click="cancel()">
                        <v-icon class="mr-2 iconsize">mdi-close-box-outline</v-icon>
                        <div class="vertical_line"></div>Cancel
                    </v-btn>
                    <v-btn outlined color="#D1121B" class="btnstyle mr-3" @click="reset()">
                        <v-icon class="mr-2 iconsize">mdi-close-box-outline</v-icon>
                        <div class="vertical_line"></div>Reset
                    </v-btn>
                </v-col>
                <v-col align="right">
                    <v-btn color="#23B1A9"  dark class="btnstyle" type="submit">
                        <v-icon class="mr-2 iconsize">mdi-magnify</v-icon>
                        <div class="vertical_line"></div>search
                    </v-btn>
                </v-col>
            </v-row>
        </v-form>
        <!-- <div class="aligncenter" v-if="recovery_tableData.length == 0">
            <p class="mt-0 mb-0"></p>
            <v-btn
            color="#1EBBD9"
            dark
            class="btnstyle mr-3 ml-10"
            @click="opencreateRecovery()"
            >
            <v-icon class="mr-2 iconsize">mdi-plus-box-outline</v-icon>
            <div class="vertical_line"></div>
            Add New Recovery
            </v-btn>
        </div> -->
        <v-row class="mt-4 ml-4">
            <p class="fontStyle font-weight-bold mb-4 mt-6 ml-0">
            Recovery Details
            <span class="orangetext">({{ totalListCount }})</span>
            </p>
            <!-- <v-col align="right" class="mb-3 mr-1">
                <v-btn
                    color="#1EBBD9"
                    dark
                    class="btnstyle mr-2"
                    @click="opencreateRecovery()"
                >
                    <v-icon class="mr-2 iconsize">mdi-plus-box-outline</v-icon>
                    <div class="vertical_line"></div>
                    Add New Recovery
                </v-btn>
            </v-col> -->
        </v-row>
        <!-- Table -->
        <template>
            <v-data-table
                :headers="recoveryheaders"
                :items="recovery_tableData"
                hide-default-footer
                class="vDataTableStyling tableborder mx-3 mb-3 mt-0"
                no-data-text="No Records for Recovery Receipts"
            >
                <template v-slot:[`item.action`]="{ item }">
                    <v-menu
                    transition="slide-x-transition"
                    bottom
                    right
                    :nudge-left="0"
                    content-class="my-menu-bnkmstr"
                    flat
                    allow-overflow
                    >
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on">mdi-dots-vertical</v-icon
                        >{{ item.action }}
                    </template>
                    <v-list v-if="['Collected', 'INACTIVE', 'CONFIRMED'].includes(item.receiptStatus)">
                        <v-list-item
                        dense
                        style="min-height: 28px !important"
                        v-for="(itm, index) in chkr_items"
                        :key="index"
                        @click="actionClick(itm, item, index)"
                        class="pa-0 ma-0"
                        :color="itm.color"
                        :disabled="itm.title === 'VOID' && ['Collected', 'INACTIVE', 'CONFIRMED'].includes(item.receiptStatus)"
                        >
                        <v-list-item-icon class="pa-2 pt-2 ma-0 mr-0">
                            <v-icon
                            small
                            :color="
                                itm.title === 'VOID' && ['Collected', 'INACTIVE', 'CONFIRMED'].includes(item.receiptStatus)
                                ? '#e5e5e5'
                                : itm.color
                            "
                            >mdi-{{ itm.icon }}</v-icon
                            >
                        </v-list-item-icon>
                        <v-divider vertical class="mx-1"></v-divider>
                        <v-list-item-title
                            class="pa-1 pr-3 ma-0 mr-0"
                            :class="itm.name"
                            style="font-size: 12px !important"
                        >
                            {{ itm.title }}
                        </v-list-item-title>
                        </v-list-item>
                    </v-list>
                    <v-list v-else>
                        <v-list-item
                        dense
                        style="min-height: 28px !important"
                        v-for="(itm, index) in chkr_items1"
                        :key="index"
                        @click="actionClick(itm, item, index)"
                        class="pa-0 ma-0"
                        :color="itm.color"
                        :disabled="itm.title === 'VOID' && !['Active'].includes(item.receiptStatus)"
                        >
                        <v-list-item-icon class="pa-2 pt-2 ma-0 mr-0">
                            <v-icon
                            small
                            :color="
                                itm.title === 'VOID' && !['Active'].includes(item.receiptStatus)
                                ? '#dfb1b1'
                                : itm.color
                            "
                            >mdi-{{ itm.icon }}</v-icon
                            >
                        </v-list-item-icon>
                        <v-divider vertical class="mx-1"></v-divider>
                        <v-list-item-title
                            class="pa-1 pr-3 ma-0 mr-0"
                            :class="itm.name"
                            style="font-size: 12px !important"
                        >
                            {{ itm.title }}
                        </v-list-item-title>
                        </v-list-item>
                    </v-list>
                    </v-menu>
                </template>
            </v-data-table>
            <div
            class="text-center graybackground pdct-stUp-bnkAcc-pgn mt-3 mx-3 mb-2"
            v-if="this.recovery_tableData.length !== 0"
            >
            <v-row>
                <v-col cols="4"></v-col>
                <v-col cols="8">
                <v-row class="paginationrow">
                    <v-spacer></v-spacer>
                    <div class="mt-4">
                    <span class="orangetext">
                        Showing {{ pageStartIndex }} - {{ pageEndIndex }}</span
                    >
                    <span> out of {{ totalListCount }} records </span>
                    </div>
                    <v-pagination
                    class="mr-0 mt-1 navpagenumber"
                    v-model="page"
                    :length="getNoOfPages"
                    @input="getRecovery"
                    :total-visible="5"
                    color="#152F38"
                    ></v-pagination>
                </v-row>
                </v-col>
            </v-row>
            <br />
            </div>
        </template>
        <!-- Void Dialog -->
        <v-row justify="center">
        <v-dialog
            v-model="void_dialog"
            persistent
            max-width="40%"
            style="border-radius: 0"
        >
            <v-card>
            <v-card-title class="pl-0 px-0 py-0 dailogboxAllvcardTableBackground">
                <v-row class="colorbg">
                <span class="descrptionSpan mb-2 ml-8 mr-0">Void Recovery</span>
                <v-icon
                    @click="void_dialog = false"
                    class="mt-2 ml-n8"
                    color="#fff"
                    >mdi-window-close</v-icon
                >
                </v-row>
                <v-card-text class="vcardTextTableBackground pl-2">
                <v-form ref="voidForm" @submit.prevent="submitVoid()">
                    <v-layout>
                    <v-flex>
                        <v-layout column class="ml-3 mt-4">
                        <p class="mb-2 claimlabel">
                            Void Reason <span class="mandatorycolor">*</span>
                        </p>
                        <v-select
                            label="Select"
                            solo
                            dense
                            :items="reasonArray"
                            v-model="voidReason"
                            :rules="[mandatoryrule()]"
                            class="forminput"
                        ></v-select>
                        </v-layout>
                    </v-flex>
                    <!-- <v-flex></v-flex> -->
                    </v-layout>
                    <v-row style="background-color: #fff">
                    <v-col align="right">
                        <v-btn
                        class="btnstyle cancelbtn mr-3"
                        @click="void_dialog = false"
                        >
                        Cancel
                        </v-btn>

                        <v-btn
                        color="#D1121B"
                        dark
                        class="btnstyle mr-2"
                        type="submit"
                        >
                        Yes, Void Payment
                        </v-btn>
                    </v-col>
                    </v-row>
                </v-form>
                </v-card-text>
            </v-card-title>
            </v-card>
        </v-dialog>
        </v-row>
    </div>
</template>

<script>
import ClaimDetailsVue from "../../component/ClaimDetails.vue";
    export default {
        components: {
            ClaimDetailsVue,
        },
        data() {
            return {
                validationmessage: "This field is required",

                mandatoryrule() {
                    return (v) => !!v || this.validationmessage;
                },
                recovery_tableData: [],
                recoveryheaders: [
                    { text: "Action", align: "start", value: "action", sortable: false },
                    {
                        text: "LOB",
                        align: "start",
                        value: "lobType",
                        sortable: false,
                    },
                    {text: "Policy number", align: "start", value: "policyNo", sortable: false },
                    {
                    text: "Claim Number",
                    align: "start",
                    value: "claimNumber",
                    sortable: false,
                    },
                    {
                    text: "Feature Number",
                    align: "start",
                    value: "featureNumber",
                    sortable: false,
                    },
                    {
                    text: "Recovery Reference ID",
                    align: "start",
                    value: "referenceNumber",
                    sortable: false,
                    },
                    {
                    text: "Insured Name",
                    align: "start",
                    value: "insuredName",
                    sortable: false,
                    },
                    {
                    text: "Instrument number",
                    align: "start",
                    value: "InstrumentReferenceNumber",
                    sortable: false,
                    },
                    {
                    text: "Vendor ID",
                    align: "start",
                    value: "gcVendorId",
                    sortable: false,
                    },
                    {
                    text: "Type of Recovery",
                    align: "start",
                    value: "typeofRecovery",
                    sortable: false,
                    },
                    {
                    text: "Recovery Head",
                    align: "start",
                    value: "recoveryHead",
                    sortable: false,
                    },
                    {
                    text: "Recovery Agent",
                    align: "start",
                    value: "recoveryAgent",
                    sortable: false,
                    },
                    {
                    text: "Salvage Buyer GST Number",
                    align: "start",
                    value: "salvageBuyerGSTNumber",
                    sortable: false,
                    },
                    {
                    text: "Salvage Buyer State",
                    align: "start",
                    value: "salvageBuyerState",
                    sortable: false,
                    },
                ],
                chkr_items: [
                    { title: "VIEW", icon: "eye", color: "black", name: "view" },
                    {
                    title: "VOID",
                    icon: "close-circle-outline",
                    color: "#D1121B",
                    name: "void",
                    },
                    // {title: "MODIFY", icon: "pencil", color: "black", name: "modify"
                    // }
                ],
                chkr_items1: [
                    { title: "VIEW", icon: "eye", color: "black", name: "view" },
                    {
                    title: "VOID",
                    icon: "close-circle-outline",
                    color: "#D1121B",
                    name: "void",
                    },
                    // {title: "MODIFY", icon: "pencil", color: "black", name: "modify"
                    // }
                ],
                page: 1,
                pageStartIndex: 1,
                pageRowCount: 10,
                totalListCount: 0,
                void_dialog: false,
                reasonArray: [
                    "Error in payment",
                    "NEFT Return",
                    "Stale Cheque",
                    "Cancel rewrite of policy",
                ],
                voidReason: "",
                searchObj: {
                    lobType: '',
                    claimNumber: '',
                    featureNumber: '',
                },
                lobOptions: [],
                lob: '',
                claimDetails: {},
                disableSearch:false,
            }
        },

        created() {
            this.getLOB();
            if(Object.keys(this.$route.params).length != 0) {
            } else if(Object.keys(this.$store.getters.fetchDataFromOtherLOB) != 0) {
                this.claimDetails = this.$store.getters.fetchDataFromOtherLOB;
                this.searchObj.lobType = this.$store.getters.fetchDataFromOtherLOB.lobType;
                this.searchObj.claimNumber = this.$store.getters.fetchDataFromOtherLOB.claimNumber;
                this.searchObj.featureNumber = this.$store.getters.fetchDataFromOtherLOB.featureNumber;
                // this.getRecovery();
                if(this.searchObj.lobType=="Home") this.disableSearch=true
            }
            this.$store.commit("SAVE_PAGE_HEADER", "Recovery");
        },

        mounted() {
            if(this.searchObj?.lobType !== ""){
                this.getRecovery()
            }
        },

        computed: {
            getNoOfPages() {
                if (!this.totalListCount) {
                    return 0;
                } else {
                    return Math.ceil(this.totalListCount / this.pageRowCount);
                }
            },
            pageEndIndex() {
                if (!this.totalListCount) {
                    return 0;
                } else {
                    return this.pageStartIndex + this.pageRowCount <= this.totalListCount
                    ? this.pageStartIndex + this.pageRowCount - 1
                    : this.totalListCount;
                }
            },
        },
        watch:{
            $route:{
                handler:(to,from)=>{
                    if(Object.keys(to.params).length != 0){
                        console.log("1234",to,from)
                        
                        this.$store.commit("SET_OTHER_LOB_DATA", {})
                    }
                },
                immediate:true
            }
            
        },

        methods: {

            getLOB() {
                let reqData = '';
                this.showProgress();
                this.GETM("getLOB", reqData, (res) => {
                    this.hideProgress();
                    if(res.data.statusCode === 200) {
                        this.lobOptions = res.data.data[0].lineOfBusiness;
                    } else {
                        this.lobOptions = [];
                        this.showAlert(res.data.msg);
                    }
                }, (error) => {
                    console.log(error);
                    this.hideProgress();
                }, true)
            },

            actionClick(itm, item, index) {
                console.log("ute", item, itm, index);
                if (index == 0) {
                let data = {
                    viewMode: true,
                    data: item,
                }
                this.$store.commit('SET_CURRENT_PAGE_DATA', data);
                // window.sessionStorage.setItem('SET_CURRENT_PAGE_DATA', JSON.stringify(data));
                    this.$router.push({
                        name: 'Recovery',
                        params: {
                            item: {
                                ...item,
                                viewMode: true,
                            }
                        }
                    });
                }
                if (index == 1) {
                    this.void_dialog = true;
                    this.referenceId = item.referenceNumber;
                    this.recoveryId = item.recoveryId;
                    this.voidReason = "";
                    this.lob = item.lobType;
                }
                if(index===2){
                    let data = {
                    viewMode: false,
                    isModify:true,
                    data: item,
                }
                this.$store.commit('SET_CURRENT_PAGE_DATA', data);
                    this.$router.push({
                        name: 'Recovery',
                        params: {
                            item: {
                                ...item,
                                viewMode: false,
                                isModify:true,
                            }
                        }
                    });
                }
                // console.log('====', this.receipts_details)
            },

            getRecovery(pageno = 1) {
                if(this.$refs?.searchrecoveryRef?.validate() || this.searchObj.lobType=="Home") {
                    let LOB = this.searchObj.lobType;
                    // if(this.searchObj.lobType === 'Travel'){
                    //     LOB = "BTA,LTA"
                    // } else if(this.searchObj.lobType === 'PA'){
                    //     LOB = "PA Group,PA Retail"
                    // }
                    let reqData = `page=${pageno}&pageLimit=10&lobType=${LOB}`;
                    if(this.searchObj.claimNumber) {
                        reqData += `&claimNumber=${this.searchObj.claimNumber}`;
                    }
                    if(this.searchObj.featureNumber) {
                        reqData += `&featureNumber=${this.searchObj.featureNumber}`;
                    }
                    this.page = pageno ? pageno : 1;
                    this.showProgress("Searching, Please wait");
                    this.GETM(
                        "getRecoverys",
                        reqData,
                        (res) => {
                        if (
                            res.data.statusCode == 200 &&
                            res.data.data &&
                            res.data.data.data &&
                            res.data.data.data.length !== 0
                        ) {
                            this.recovery_tableData = res.data.data.data;
                            this.totalListCount = res.data.data.total;
                            this.pageStartIndex =
                            this.page == 1 ? 1 : (this.page - 1) * this.pageRowCount;
                        } else {
                            // this.showAlert(res.data.msg);
                            this.recovery_tableData = [];
                            this.totalListCount = 0;
                        }
                        this.hideProgress();
                        },
                        true,
                        (error) => {
                        this.hideProgress();
                        if (error.response.data.msg) {
                            this.showAlert(error.response.data.msg);
                        } else if (error.response.data.message.msg) {
                            this.showAlert(error.response.data.message.msg);
                        } else if (error.response.data.message) {
                            this.showAlert(error.response.data.message);
                        } else {
                            this.showAlert("Something went wrong");
                        }
                        },
                        true,
                    );
                } else {
                    this.showAlert('Please enter mandatory field');
                }
            },
            submitVoid() {
                let requestData = {
                    recoveryId: this.recoveryId,
                    referenceNumber: this.referenceId,
                    remarks: this.voidReason,
                    lobType: this.lob,
                };
                if (this.$refs.voidForm.validate()) {
                    this.PUTM(
                    "voidRecovery",
                    '',
                    requestData,
                    (res) => {
                        this.void_dialog = false;
                        this.showAlert(res.data.msg);
                        this.getRecovery();
                    },
                    (error) => {
                        this.hideProgress();
                        if (error.response.data.msg) {
                        this.showAlert(error.response.data.msg);
                        } else if (error.response.data.message.msg) {
                        this.showAlert(error.response.data.message.msg);
                        } else if (error.response.data.message) {
                        this.showAlert(error.response.data.message);
                        } else {
                        this.showAlert("Something went wrong");
                        }
                    },true);
                } else {
                    this.showAlert("Please enter void reason");
                }
            },

            cancel() {
                this.showConfirm("Confirmation", `Are you sure you want to leave?`, "Yes", "No", () => {
                    this.$router.push({
                        name: 'Home',
                    });
                }, () => {})
            },

            reset() {
                    this.showConfirm("Confirmation", `Are you sure you want to reset?`, "Yes", "No", () => {
                        this.searchObj = {
                            lobType: '',
                            claimNumber: '',
                            featureNumber: '',
                        }
                        this.recovery_tableData = [];
                        this.$refs.searchrecoveryRef.resetValidation()
                    }, () => {})
                },
            }
    }
</script>

<style scoped>
    .orangetext {
        color: #e46a25;
    }
</style>