<template>
    <v-app>
        <div style="background-color:#FFFFFF; border-top:1px solid #C1C8CC; display:flex">
            <v-flex xs3 style="background-color:#FFFFFF; border-right:1px solid #C1C8CC; word-break: break-word;" >
                 <v-layout column class=" px-4 py-4 " >
                     <p class="font-weight-bold cstm-clr-prtbsvw">
                        Registered Entity *
                     </p>
                      <p class="font-weight-regular fnt-sz-13">
                       {{indvlData.registeredEntity|checkdata}}
                     </p>
                 </v-layout>
             </v-flex>
             <v-flex xs3 style="background-color:#FFFFFF; border-right:1px solid #C1C8CC; word-break: break-word;">
                 <v-layout column class=" px-4 py-4 " >
                     <p class="font-weight-bold cstm-clr-prtbsvw">
                        Salutation *
                     </p>
                      <p class="font-weight-regular fnt-sz-13">
                       {{indvlData.salutation|checkdata}}
                     </p>
                     
                 </v-layout>
             </v-flex>
             <v-flex xs3 style="background-color:#FFFFFF; border-right:1px solid #C1C8CC; word-break: break-word;">
                 <v-layout column class=" px-4 py-4 " >
                     <p class="font-weight-bold cstm-clr-prtbsvw">
                        First Name *
                     </p>
                      <p class="font-weight-regular fnt-sz-13">
                        {{indvlData.firstName|checkdata}}
                     </p>
                     
                 </v-layout>
             </v-flex>

            <v-flex xs3 style="background-color:#FFFFFF; border-right:1px solid #C1C8CC; word-break: break-word;">
                 <v-layout column class=" px-4 py-4 " >
                     <p class="font-weight-bold cstm-clr-prtbsvw">
                        Middle Name
                     </p>
                      <p class="font-weight-regular fnt-sz-13">
                      {{indvlData.middleName|checkdata}}
                     </p>
                     
                 </v-layout>
             </v-flex>

             <v-flex xs3 style="background-color:#FFFFFF; border-right:1px solid #C1C8CC; word-break: break-word;">
                 <v-layout column class=" px-4 py-4 " >
                     <p class="font-weight-bold cstm-clr-prtbsvw">
                        Last Name *
                     </p>
                      <p class="font-weight-regular fnt-sz-13">
                      {{indvlData.lastName|checkdata}}
                     </p>
                     
                 </v-layout>
             </v-flex>
             <v-flex xs3 style="background-color:#FFFFFF; border-right:1px solid #C1C8CC; word-break: break-word;">
                 <v-layout column class=" px-4 py-4 " >
                     <p class="font-weight-bold cstm-clr-prtbsvw">
                        Mobile Number
                     </p>
                      <p class="font-weight-regular fnt-sz-13">
                     {{indvlData.mobileNumber|checkdata}}
                     </p>
                     
                 </v-layout>
             </v-flex>
             <v-flex xs3 style="background-color:#FFFFFF;border-right:1px solid #C1C8CC; word-break: break-word;">
                 <v-layout column class=" px-4 py-4 " >
                     <p class="font-weight-bold cstm-clr-prtbsvw">
                        External Reference No.
                     </p>
                      <p class="font-weight-regular fnt-sz-13">
                        {{indvlData.externalReferenceNo|checkdata}}
                     </p>
                     
                 </v-layout>
             </v-flex>
             <!-- <v-flex xs3 style="background-color:#FFFFFF;">
                 <v-layout column class=" px-4 py-4 " >
                     <p class="font-weight-bold cstm-clr-prtbsvw">
                        Provider Type
                     </p>
                      <p class="font-weight-regular fnt-sz-13">
                       {{indvlData.providerType|checkdata}}
                     </p>
                     
                 </v-layout>
             </v-flex> -->
        </div>

        <div style="background-color:#FFFFFF; border-top:1px solid #C1C8CC;; display:flex">

            <v-flex xs3 style="background-color:#FFFFFF;border-right:1px solid #C1C8CC; word-break: break-word;">   
                 <v-layout column class=" px-4 py-4 " >
                     <p class="font-weight-bold cstm-clr-prtbsvw">
                        Provider Type
                     </p>
                      <p class="font-weight-regular fnt-sz-13">
                       {{indvlData.providerType|checkdata}}
                     </p>
                     
                 </v-layout>
             </v-flex>


            <v-flex xs3 style="background-color:#FFFFFF;border-right:1px solid #C1C8CC; word-break: break-word;">   
                 <v-layout column class=" px-4 py-4 " >
                     <p class="font-weight-bold cstm-clr-prtbsvw">
                        Other
                     </p>
                      <p class="font-weight-regular fnt-sz-13">
                       {{indvlData.providerTypeOther|checkdata}}
                     </p>
                     
                 </v-layout>
             </v-flex>



            <v-flex xs3 style="background-color:#FFFFFF; border-right:1px solid #C1C8CC; word-break: break-word;" >
                 <v-layout column class=" px-4 py-4 " >
                     <p class="font-weight-bold cstm-clr-prtbsvw">
                         Registration Number
                     </p>
                      <p class="font-weight-regular fnt-sz-13">
                       {{indvlData.registrationNumber|checkdata}}
                     </p>
                 </v-layout>
             </v-flex>

             <v-flex xs3 style="background-color:#FFFFFF; border-right:1px solid #C1C8CC; word-break: break-word;">
                 <v-layout column class=" px-4 py-4 " >
                     <p class="font-weight-bold cstm-clr-prtbsvw">
                         Provider Flag
                     </p>
                      <p class="font-weight-regular fnt-sz-13">
                        {{ this.providerFlagIs | checkdata}}
                     </p>
                     
                 </v-layout>
             </v-flex>
             <v-flex xs3 style="background-color:#FFFFFF; border-right:1px solid #C1C8CC; word-break: break-word;">
                 <v-layout column class=" px-4 py-4 " >
                     <p class="font-weight-bold cstm-clr-prtbsvw">
                        Registration Valid Upto
                     </p>
                      <p class="font-weight-regular fnt-sz-13">
                        {{(indvlData.registrationValidUpto)| checkDate | checkdata}}
                      
                     </p>
                     
                 </v-layout>
             </v-flex>
             <v-flex xs3 style="background-color:#FFFFFF; border-right:1px solid #C1C8CC; word-break: break-word;">
                 <v-layout column class=" px-4 py-4 " >
                     <p class="font-weight-bold cstm-clr-prtbsvw">
                        PRN
                     </p>
                      <p class="font-weight-regular fnt-sz-13">
                        {{indvlData.prn|checkdata}}
                     </p>
                     
                 </v-layout>
             </v-flex>
             <v-flex xs3 style="background-color:#FFFFFF; border-right:1px solid #C1C8CC; word-break: break-word;">
                 <v-layout column class=" px-4 py-4 " >
                     <p class="font-weight-bold cstm-clr-prtbsvw">
                        Status
                     </p>
                      <p class="font-weight-regular fnt-sz-13">
                        {{indvlData.status|checkdata}}
                     </p>
                     
                 </v-layout>
             </v-flex>
             
            
        </div>

        <div style="background-color:#FFFFFF; border-top:1px solid #C1C8CC;display:flex">
            <v-flex xs3 style="background-color:#FFFFFF;border-right:1px solid #C1C8CC; word-break: break-word;">
                 <v-layout column class=" px-4 py-4 " >
                     <p class="font-weight-bold cstm-clr-prtbsvw">
                        Gender
                     </p>
                      <p class="font-weight-regular fnt-sz-13">
                        {{indvlData.gender|checkdata}}
                     </p>
                     
                 </v-layout>
             </v-flex>

            <v-flex xs3 style="background-color:#FFFFFF; border-right:1px solid #C1C8CC; word-break: break-word;">
                 <v-layout column class=" px-4 py-4 " >
                     <p class="font-weight-bold cstm-clr-prtbsvw">
                        E-mail
                     </p>
                      <p class="font-weight-regular fnt-sz-13">
                        {{indvlData.email|checkdata}}
                        
                     </p>
                     
                 </v-layout>
             </v-flex>


            <!-- <v-flex xs3 style="background-color:#FFFFFF; border-right:1px solid #C1C8CC; word-break: break-word;">
                 <v-layout column class=" px-4 py-4 " >
                     <p class="font-weight-bold cstm-clr-prtbsvw">
                        Status
                     </p>
                      <p class="font-weight-regular fnt-sz-13">
                        {{indvlData.status|checkdata}}
                     </p>
                     
                 </v-layout>
             </v-flex>
             <v-flex xs3 style="background-color:#FFFFFF;border-right:1px solid #C1C8CC; word-break: break-word;">
                 <v-layout column class=" px-4 py-4 " >
                     <p class="font-weight-bold cstm-clr-prtbsvw">
                        Gender
                     </p>
                      <p class="font-weight-regular fnt-sz-13">
                        {{indvlData.gender|checkdata}}
                     </p>
                     
                 </v-layout>
             </v-flex> -->
            
            <v-flex xs3 style="background-color:#FFFFFF; border-right:1px solid #C1C8CC; word-break: break-word;" >
                 <v-layout column class=" px-4 py-4 " >
                     <p class="font-weight-bold cstm-clr-prtbsvw">
                        Qualification
                     </p>
                      <p class="font-weight-regular fnt-sz-13">
                        {{indvlData.qualification|checkdata}}
                     </p>
                 </v-layout>
             </v-flex>

            <v-flex xs3 style="background-color:#FFFFFF; border-right:1px solid #C1C8CC;word-break: break-word;" >
                    <v-layout column class=" px-4 py-4 " >
                        <p class="font-weight-bold cstm-clr-prtbsvw">
                            Other
                        </p>
                        <p class="font-weight-regular fnt-sz-13">
                            {{indvlData.qulificationOther|checkdata}}
                        </p>
                    </v-layout>
                </v-flex>



             <v-flex xs3 style="background-color:#FFFFFF; border-right:1px solid #C1C8CC;word-break: break-word;">
                 <v-layout column class=" px-4 py-4 " >
                     <p class="font-weight-bold cstm-clr-prtbsvw">
                        Registered Council
                     </p>
                      <p class="font-weight-regular fnt-sz-13">
                      {{indvlData.registeredCouncil|checkdata}}   
                     </p>
                     
                 </v-layout>
             </v-flex>

               <v-flex xs3 style="background-color:#FFFFFF; border-right:1px solid #C1C8CC;word-break: break-word;" >
                 <v-layout column class=" px-4 py-4 " >
                    
                 </v-layout>
             </v-flex>

               <v-flex xs3 style="background-color:#FFFFFF; border-right:1px solid #C1C8CC;word-break: break-word;" >
                 <v-layout column class=" px-4 py-4 " >
                    
                 </v-layout>
             </v-flex>

             <!-- <v-flex xs3 style="background-color:#FFFFFF; border-right:1px solid #C1C8CC;word-break: break-word;">
                 <v-layout column class=" px-4 py-4 " >
                     <p class="font-weight-bold cstm-clr-prtbsvw">
                        Other
                     </p>
                      <p class="font-weight-regular fnt-sz-13">
                      {{indvlData.registeredCouncilOther|checkdata}}   
                     </p>
                     
                 </v-layout>
             </v-flex> -->
             
            
        </div>

        <div style="background-color:#FFFFFF; border-top:1px solid #C1C8CC; border-bottom:1px solid #C1C8CC;display:flex">
            <v-flex xs3 style="background-color:#FFFFFF;border-right:1px solid #C1C8CC;word-break: break-word;">
                 <v-layout column class=" px-4 py-4 " >
                     <p class="font-weight-bold cstm-clr-prtbsvw">
                        Other
                     </p>
                      <p class="font-weight-regular fnt-sz-13">
                        {{indvlData.registeredCouncilOther|checkdata}}
                     </p>
                     
                 </v-layout>
             </v-flex>

            <v-flex xs3 style="background-color:#FFFFFF; border-right:1px solid #C1C8CC;word-break: break-word;">
                 <v-layout column class=" px-4 py-4 " >
                     <p class="font-weight-bold cstm-clr-prtbsvw">
                       Remarks
                     </p>
                      <p class="font-weight-regular fnt-sz-13">
                        {{indvlData.remarks|checkdata}}
                        
                     </p>
                     
                 </v-layout>
             </v-flex>


            <v-flex xs3 style="background-color:#FFFFFF; border-right:1px solid #C1C8CC;word-break: break-word;">
                 <v-layout column class=" px-4 py-4 " >
                    
                     
                 </v-layout>
             </v-flex>
             <v-flex xs3 style="background-color:#FFFFFF;border-right:1px solid #C1C8CC;word-break: break-word;">
                 <v-layout column class=" px-4 py-4 " >
                     
                     
                 </v-layout>
             </v-flex>
            
            <v-flex xs3 style="background-color:#FFFFFF; border-right:1px solid #C1C8CC;word-break: break-word;" >
                 <v-layout column class=" px-4 py-4 " >
                    
                 </v-layout>
             </v-flex>

            <v-flex xs3 style="background-color:#FFFFFF; border-right:1px solid #C1C8CC;word-break: break-word;" >
                    <v-layout column class=" px-4 py-4 " >
                       
                    </v-layout>
                </v-flex>



             <v-flex xs3 style="background-color:#FFFFFF; border-right:1px solid #C1C8CC;word-break: break-word;">
                 <v-layout column class=" px-4 py-4 " >
                    
                     
                 </v-layout>
             </v-flex>

             
             
            
        </div>

        <!-- <v-card tile class="ma-3 mt-4" color="#F7F7F7">
            <div style="display:flex; justify-content:flex-end" class="pa-5">
                <v-btn tile dark color="#23B1A9" class="text-capitalize px-3" small @click="editSummary()">
                    <v-icon color="" small >edit</v-icon>
                    <v-divider vertical class="ml-2 mr-2"></v-divider>Edit
                </v-btn>
            </div>
        </v-card> -->
        
    </v-app>
    
</template>

<script>
import moment from 'moment';
// import IndividualSummary from './IndividualSummary.vue'
export default {

    components:{
        // IndividualSummary
        
    },

    created() {

        // this.$store.commit("SET_PAGE_TITLE", "Provider Summary");
        console.log(this.$store.getters.fetchPrvdrData,"fetchPrvdrData")
        this.indvlData=this.$store.getters.fetchPrvdrData
        console.log(this.indvlData);
        let flagData = this.$store.getters.fetchPrvdrData.flagDetails 
        let gotFlag = this.getKeyByValue(flagData) 
        console.log("%c got the flag selected =>","background-color:#dddd45;color:black",flagData);
        console.log("%c got the flag selected =>","background-color:#dddd20;color:black",gotFlag);
        if(gotFlag.length<=1){ 
            this.providerFlagIs = gotFlag[0];
            console.log("%c this is for flagArray","background-color:#dddd20;color:black",gotFlag[0]);
       }else{
           this.providerFlagIs = gotFlag;
       }
        

    },

    filters:{

      truncate: function (text, length, suffix) {
            if (text.length > length) {
                return text.substring(0, length) + suffix;
            } else {
                return text;
            }
        },
        checkdata(data){// eslint-disable-line no-mixed-spaces-and-tabs
            // console.log('Validity Data', data);
            if (data == "" || data == "undefined" || data == null || data == '' || data == 'Invalid Date') {
                return "-"
            } else {
                return data;
            }
        },
        checkDate(data){
            if (data == "" || data == "undefined" || data == null || data == '' || data == 'Invalid Date') {
                return "-"
            } else {
                return moment(data, 'YYYY-MM-DD').format("DD-MM-YYYY");
            }
        }
    },

    computed: {

       

    },

    
    data() {
        return {
            indvlData:{}
        };



    },

    methods: {
            getKeyByValue(flagData) {
                return Object.keys(flagData).filter(key => flagData[key] === true);
                },
            editSummary(){
                this.$router.push('/indform');
            }
        
    },

    watch: {
    
    },
    
    
}

</script>
<style >
.cstm-clr-prtbsvw{
    color:#10242B !important;
    margin-bottom: 5px !important;
    font-size: 13px !important;
}
.fnt-sz-13{
    font-size: 13px !important;
}
</style>