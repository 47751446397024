import axios from "axios";
import * as URL from "../common/API_Config.js";

export const getInvestigatorsList = async function (invType, name, state, city) {
    const res = await axios.get(
      URL.commonModuleBaseUrl +
        "common-masterdata/serviceProvider/get-all-serviceProvider?active=true&lob=Health&serviceProviderType=SIU Investigator&investigatgatorType="+invType + "&serviceProviderName=" + name + "&spState=" + state + "&spCity=" + city,
      {
        headers: {
          "Content-Type": "application/json",
          system: "CM",
        },
      }
    );
    return res;
  };

  export const assignInvestigationAppointment = async function (payload) {
    const res = await axios.post(
      URL.commonModuleBaseUrl +
        "common-auxiliary/hos-and-acc-details/insert-investigation-details",
        payload,
      {
        headers: {
          "Content-Type": "application/json",
          system: "Health",
        },
      }
    );
    return res;
  };

  function appendArgs(args) {
      let argString = "?";
      Object.keys(args).forEach((element) => {
        argString += element + "=" + args[element] + "&";
      });
      return argString;
  }