<template>
    <v-app>
        <div style="background-color: #FFFFFF;" column class="py-3">
            <v-form ref="resetCountForm" @submit.prevent="resetCount">
                <v-row>
                    <v-flex xs1> </v-flex>

                    <v-flex xs3>
                        <v-layout column class="ml-4 mr-3">
                            <p class="mb-2  claimlabel">
                                Line Of Bussiness
                                <span class="mandatorycolor"> *</span>
                            </p>

                            <v-select 
                                placeholder="Select" 
                                v-model="selectedLob" 
                                :items="lobOptions" 
                                item-text="lob"
                                :return-object="true"
                                :rules="[mandatory(selectedLob)]" 
                                solo 
                                dense 
                                class="forminput" 
                            ></v-select>
                        </v-layout>
                    </v-flex>
                    <v-spacer></v-spacer>
                    <v-flex xs3>
                    <v-layout column class="ml-4">
                        <p class="mb-2 claimlabel">
                            ID(s)<span class="mandatorycolor"> *</span>
                        </p>
                        <v-text-field 
                            placeholder="Enter..." 
                            solo 
                            dense 
                            v-model="id" 
                            :rules="[mandatory(),alphaNum(id)]" maxlength="200" class="forminput"></v-text-field>
                    </v-layout>
                </v-flex>
                <v-flex xs1> </v-flex>
                    <v-flex xs3 >  
                        <p></p>
                        <v-layout class="text-center">
                            <p></p>
                            <v-btn color="#23B1A9" class="buttonscolor mr-5" type="submit" :disabled="!id.length">
                                <v-icon class="mr-2" style="font-size: 19px">mdi-arrow-right-circle</v-icon>
                                <div class="vertical_line"></div>Reset
                            </v-btn>
                        </v-layout>
                    </v-flex>
                    <v-flex xs1></v-flex>
                </v-row>
            </v-form>
        </div>
    </v-app>
</template>
<script>
import moment from 'moment';
  
export default {
    data(){
        return {
            id:"",
            lobOptions:[{"lob":"Health","url":"HEALTH_RESETCOUNT"},{"lob":"Marine","url":"MARINE_RESETCOUNT"},{"lob":"AIGC","url":"MARINE_RESETCOUNT"},{"lob":"PE","url":"MARINE_RESETCOUNT"},{"lob":"Extended Warranty","url":"EW_RESETCOUNT"}, {"lob":"Travel","url":"TRAVEL_RESET_COUNT"}, {"lob":"PA","url":"TRAVEL_RESET_COUNT"}],
            selectedLob:{"lob":"Marine","url":"MARINE_RESETCOUNT"}
        }
    },
    methods:{
        resetCount(){
            console.log("id = ",this.id,this.$refs.resetCountForm.validate())
            this.showProgress()
            if(this.id.includes(",,")){
                this.id =   this.id.replaceAll(",,",",")
            }
            if(this.id[this.id.length - 1] == ","){
                this.id = this.id.substring(0,this.id.length - 1)
            }
            if(this.id[0] == ","){
                this.id = this.id.substring(1,this.id.length)
            }
            
            let idList  =   this.id.split(",")
            this.POSTM(this.selectedLob.url,{"id":idList},(res)=>{
                this.hideProgress()
                console.log("res ",res)
                this.showAlert(res.data.msg)
                
            },(err)=>{
                this.hideProgress()
                console.log("err - ",err)
            },true)
        },
        mandatory(v) {
            return v => !!v || 'This Field is required'
        },
        alphaNum(v){
                 console.log(v)
                 if (v !== undefined) {
                    if (v.length !== 0) {
                        if (!/^[0-9,]+$/.test(v)) {
                            return "This field is not valid"
                        } else {
                            return true;
                        }
                    } else {
                    return true;
                    }
                } else {
                    return true;
                }
            },
    },
    created(){
        this.$store.commit("SAVE_PAGE_HEADER", "Reset GC Sync Failure Count");
        console.log("Test Retry count")
    }
}
</script>

<style>

.cust_pdPerSearch .v-input .v-label {
    font-size: 13px !important;
}
.cust_pdPerSearch .v-input {
    font-size: 13px !important;
}
.cust_pdPerSearch .v-input input {
    font-size: 13px !important;
}


.cust_pr_tds .v-input .v-label {
    font-size: 13px !important;
    
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
    font-size: 0.84rem !important;
}

.hdr-txt-clrPer{
    color: white !important;
    font-weight: bold;
    /* padding:0px!important;
    padding: 10px !important;
    padding-left: 14px !important; */
}

.hdr-bg-clrPer{
  background-color: #23B1A9;
}
.headercolor{
    background-color: #eee;
}
.cardfont{
    font-size: 0.84rem !important;
    padding-right: 2px;
}
.srWidth{
    width: 70px;
}


 .cust-bnkAcc-Permission .v-pagination__navigation{
        box-shadow:unset !important;
        border-radius: unset !important;
        background-color:#FFFFFF !important;
        border:1px solid #DAE1E6 !important
    }
    .cust-bnkAcc-Permission .v-pagination__navigation--disabled{
        background-color:#DAE1E6 !important;
    }
    .cust-bnkAcc-Permission .v-pagination__item{
        box-shadow:unset !important;
        min-width:2rem !important;
        height:2rem !important;
        border-radius: unset !important;
        border:1px solid #DAE1E6 !important
    }  
    .rightside{
        margin: 10px;
        margin-left: 70%;
    }

</style>