<template>
  <div class="ma-3 mt-8">
    <v-form
      ref="createForm"
      lazy-validation
      @submit.prevent="courtOnSubmitHandler"
    >
      <v-expansion-panels v-model="panels" multiple>
        <v-expansion-panel>
          <v-expansion-panel-header color="#1EBBD9">
            <span class="page-title-small white--text">Court Details</span>
            <template v-slot:actions>
              <v-icon class="white--text">
                {{
                  panels.indexOf(0) !== -1 ? "mdi-minus-box" : "mdi-plus-box"
                }}
              </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row class="mt-3">
              <v-col>
                <label class="form-label"
                  >Line Of Business
                  <span class="red--text">*</span>
                </label>
                <v-select
                  multiple
                  solo
                  dense
                  class="form-control"
                  :disabled="isView"
                  placeholder="Select"
                  :rules="[(v) => requiredField(v)]"
                  :items="lob_items"
                  v-model="formData.lob"
                ></v-select>
              </v-col>
              <v-col>
                <label class="form-label ml-1"
                  >Court Type
                  <span class="red--text"><strong> * </strong></span>
                </label>
                <v-select
                  class="form-control"
                  v-model="formData.courtType"
                  placeholder="Select"
                  :disabled="isView"
                  :items="courtTypeItems"
                  :rules="[(v) => requiredField(v)]"
                  dense
                  solo
                  required
                ></v-select>
              </v-col>
              <v-col>
                <label class="form-label"
                  >Court Name
                  <span class="red--text"><strong> * </strong></span>
                </label>
                <v-text-field
                  placeholder="Enter..."
                  class="form-control"
                  solo
                  :disabled="isView"
                  dense
                  :maxlength="100"
                  v-model="formData.courtName"
                  depressed
                  :rules="[
                    (v) => requiredField(v),
                    (v) => matchRegex(v, 'alphanum'),
                    (v) =>
                      validateLength(
                        v,
                        { min: 5 },
                        '',
                        'Field requires 5 digits.'
                      ),
                  ]"
                />
              </v-col>
              <v-col>
                <label class="form-label"
                  >Address Line 1
                  <span class="red--text"><strong> * </strong></span>
                </label>
                <v-text-field
                  placeholder="Enter..."
                  class="form-control"
                  solo
                  :disabled="isView"
                  dense
                  v-model="formData.addressLine1"
                  depressed
                  :maxlength="45"
                  :rules="[
                    (v) => requiredField(v),
                    (v) => addressValidation(v)
                  ]"
                />
              </v-col>
              <v-col>
                <label class="form-label"
                  >Address Line 2
                  <span class="red--text"><strong> * </strong></span></label
                >
                <v-text-field
                  placeholder="Enter..."
                  class="form-control"
                  solo
                  :disabled="isView"
                  dense
                  :maxlength="45"
                  v-model="formData.addressLine2"
                  depressed
                  :rules="[
                    (v) => requiredField(v),
                    (v) => addressValidation(v)
                  ]"
                />
              </v-col>
              <v-col>
                <label class="form-label"
                  >Address Line 3
                  <span class="red--text"><strong> * </strong></span></label
                >
                <v-text-field
                  placeholder="Enter..."
                  class="form-control"
                  solo
                  dense
                  :maxlength="35"
                  :disabled="isView"
                  v-model="formData.addressLine3"
                  depressed
                  :rules="[
                    (v) => requiredField(v),
                    (v) => addressValidation(v)
                  ]"
                />
              </v-col>
            </v-row>
            <v-row>
              <!-- <v-col>
                <label class="form-label"
                  >Pin Code
                  <span class="red--text"><strong> * </strong></span></label
                >
                <v-text-field
                  placeholder="Enter..."
                  class="form-control"
                  solo
                  dense
                  v-model="formData.pinCode"
                  depressed
                  :rules="[(v) => requiredField(v)]"
                />
              </v-col> -->
              <v-col>
                <PincodeSearch
                  :pinCode="formData.pinCode"
                  @on-change="getPincodes"
                  :isDisabled="isView"
                  :pincodeRequired="true"
                />
              </v-col>
              <v-col>
                <label class="form-label"
                  >City
                  <span class="red--text"><strong> * </strong></span></label
                >
                <v-text-field
                  placeholder="Enter..."
                  class="form-control"
                  solo
                  disabled
                  dense
                  v-model="formData.city"
                  depressed
                  :rules="[(v) => requiredField(v)]"
                />
              </v-col>
              <v-col>
                <label class="form-label"
                  >District
                  <span class="red--text"><strong> * </strong></span></label
                >
                <v-text-field
                  placeholder="Enter..."
                  class="form-control"
                  solo
                  disabled
                  dense
                  v-model="formData.district"
                  depressed
                  :rules="[(v) => requiredField(v)]"
                />
              </v-col>
              <v-col>
                <label class="form-label"
                  >State
                  <span class="red--text"><strong> * </strong></span></label
                >
                <v-text-field
                  placeholder="Enter..."
                  class="form-control"
                  solo
                  dense
                  disabled
                  v-model="formData.state"
                  depressed
                  :rules="[(v) => requiredField(v)]"
                />
              </v-col>

              <v-col>
                <label class="form-label">Contact Details </label>
                <v-text-field
                  placeholder="Enter..."
                  class="form-control"
                  maxlength="10"
                  :disabled="isView"
                  solo
                  dense
                  v-model="formData.contactDetails"
                  depressed
                  :rules="[(v) => matchRegex(v, 'nums')]"
                />
              </v-col>
            </v-row>
            <v-row style="max-width: 61.3%">
              <v-col>
                <label class="form-label">Email ID </label>
                <v-text-field
                  placeholder="Enter..."
                  class="form-control"
                  maxlength="50"
                  :disabled="isView"
                  solo
                  dense
                  v-model="formData.emailID"
                  depressed
                  :rules="[(v) => emailValidation(v)]"
                />
              </v-col>
              <v-col>
                <v-checkbox
                  class="form-control"
                  v-model="formData.isActive"
                  dense
                  label="Active"
                  :disabled="isView || !isModify"
                >
                </v-checkbox>
              </v-col>
              <v-col>
                <v-checkbox
                  class="form-control"
                  v-model="formData.isWatchList"
                  dense
                  label="Watch List"
                  :disabled="isView"
                >
                </v-checkbox>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header color="#1EBBD9">
            <span class="page-title-small white--text">Bank Details</span>
            <template v-slot:actions>
              <v-icon class="white--text">
                {{
                  panels.indexOf(1) !== -1 ? "mdi-minus-box" : "mdi-plus-box"
                }}
              </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-layout wrap class="pt-4 form-group-container">
              <v-flex class="form-group">
                <v-checkbox
                  class="form-control"
                  label="Bank Details Available"
                  v-model="formData.isBankDetailsAvailable"
                  @change="checkBankDetails(formData.isBankDetailsAvailable)"
                  :disabled="isView"
                >
                </v-checkbox>
              </v-flex>
              <v-spacer></v-spacer>
              <div class="me-3 px-2 mb-2" v-if="cancelledChequeData.length > 0">
                <label
                  :disabled="!isOCRUploaded"
                  class="form-label mt-2 text-right"
                  @click="cancelChequeView"
                >
                  <div class="CancelledCheque">
                    <v-icon color="#0AB417"> mdi-eye </v-icon>
                    <span class="ml-2">View Cancelled Cheque</span>
                  </div>
                </label>
              </div>

              <div>
                <p
                  v-show="formData.isBankDetailsAvailable"
                  :class="isView ? 'mr-8 grey--text' : 'teal--text mr-8'"
                  :disabled="isView"
                  style="cursor: pointer"
                  @dragover="dragoverCancel"
                  @drop="dropCancel"
                  @click="browseCancel"
                >
                  <v-icon :color="isView ? '#9E9E9E' : '#23B1A9'" class="mr-3"
                    >mdi-tray-arrow-up</v-icon
                  >Upload Cancelled Cheque
                  <v-file-input
                    style="display: none"
                    v-model="cancelledCheque"
                    id="cancelchequeupload"
                    label=""
                    dense
                    solo
                    @change="OCRUpload"
                    :disabled="isView"
                    accept="image/png,image/jpeg,application/msword,application/pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  />
                </p>
              </div>
            </v-layout>
            <v-layout wrap class="pt-4 form-group-container">
              <v-layout
                class="py-4 cancel-cheque-view-port"
                v-if="cancelledChequeFileShow && cancelledChequeData.length > 0"
              >
                <v-flex xs12>
                  <div
                    class="close-cancell-cheque"
                    @click="cancelledChequeFileShow = !cancelledChequeFileShow"
                  >
                    Close
                  </div>
                  <v-layout justify-center>
                    <object
                      :data="
                        cancelledChequeData[0].fileBase
                          ? cancelledChequeData[0].fileBase
                          : showCheque
                      "
                      width="1000"
                      height="300"
                    ></object>
                    <!-- <img height="300" :src="investigatorMasterObj.documents.fileBase" /> -->
                  </v-layout>
                  <!-- <v-layout justify-center class="mt-2">
                                            <div class="cancel-cheque-success-btn">OCR was successfully completed.</div>
                                        </v-layout> -->
                </v-flex>
              </v-layout>
            </v-layout>

            <v-layout wrap class="pt-4 form-group-container">
              <v-flex
                class="form-group mb-2"
                v-if="formData.isBankDetailsAvailable"
              >
                <v-checkbox
                  class="form-control"
                  v-model="formData.isOverWritten"
                  :disabled="
                    isView ||
                    (!confirmDisable
                      ? !cancelledChequeData.length > 0
                      : confirmDisable)
                  "
                  @click="overWrittingChange"
                  dense
                  label="OCR Overwritten"
                >
                </v-checkbox>
              </v-flex>
              <v-flex class="form-group">
                <label class="form-label ml-1"
                  >IFSC Code
                  <span v-if="formData.isBankDetailsAvailable" class="red--text"
                    >*</span
                  ></label
                >
                <v-autocomplete
                  class="form-control"
                  v-model="formData.ifscCode"
                  placeholder="Select"
                  :items="IFSCCodes"
                  :disabled="
                    (bankDetailsDisable && !formData.isOverWritten) || isView
                  "
                  item-text="ifscCode"
                  dense
                  :maxlength="11"
                  solo
                  required
                  :rules="
                    formData.isBankDetailsAvailable
                      ? [(v) => requiredField(v)]
                      : []
                  "
                  :search-input.sync="ifsc"
                  @keyup="ifscData"
                  @change="ifscchange"
                ></v-autocomplete>
              </v-flex>
              <v-flex class="form-group">
                <label class="form-label"
                  >Bank Name
                  <span v-if="formData.isBankDetailsAvailable" class="red--text"
                    >*</span
                  ></label
                >
                <v-text-field
                  placeholder="Enter..."
                  class="form-control"
                  disabled
                  solo
                  dense
                  :rules="
                    formData.isBankDetailsAvailable
                      ? [(v) => requiredField(v)]
                      : []
                  "
                  v-model="formData.bankName"
                  depressed
                />
              </v-flex>
              <v-flex class="form-group">
                <label class="form-label"
                  >Account Holder Name
                  <span v-if="formData.isBankDetailsAvailable" class="red--text"
                    >*</span
                  ></label
                >
                <v-text-field
                  placeholder="Enter..."
                  class="form-control"
                  solo
                  dense
                  v-model="formData.accountHolderName"
                  :rules="
                    formData.isBankDetailsAvailable
                      ? [(v) => requiredField(v)]
                      : []
                  "
                  :disabled="
                    (bankDetailsDisable && !formData.isOverWritten) || isView
                  "
                  depressed
                />
              </v-flex>

              <v-flex class="form-group">
                <label class="form-label"
                  >City
                  <span v-if="formData.isBankDetailsAvailable" class="red--text"
                    >*</span
                  ></label
                >
                <v-text-field
                  placeholder="Enter..."
                  class="form-control"
                  solo
                  disabled
                  dense
                  v-model="formData.bankCity"
                  :rules="
                    formData.isBankDetailsAvailable
                      ? [(v) => requiredField(v)]
                      : []
                  "
                  depressed
                />
              </v-flex>
              <v-flex class="form-group">
                <label class="form-label"
                  >Bank Address Line 1
                  <span v-if="formData.isBankDetailsAvailable" class="red--text"
                    >*</span
                  ></label
                >
                <v-text-field
                  placeholder="Enter..."
                  class="form-control"
                  solo
                  dense
                  :rules="
                    formData.isBankDetailsAvailable
                      ? [(v) => requiredField(v) ,(v) => matchRegex(v, 'alphanum')]
                      : []
                  "
                   :disabled=" (bankDetailsDisable && !formData.isOverWritten) && !editBankAddress1 || isView"
                  v-model="formData.bankAddressLine1"
                  depressed
                />
              </v-flex>
              <v-flex class="form-group">
                <label class="form-label"
                  >Bank Address Line 2
                  </label
                >
                <v-text-field
                  placeholder="Enter..."
                  class="form-control"
                  solo
                  dense
                  :disabled=" (bankDetailsDisable && !formData.isOverWritten) && !editBankAddress2 || isView"
                  :rules="[(v) => matchRegex(v, 'alphanum')]"
                  v-model="formData.bankAddressLine2"
                  depressed
                />
              </v-flex>
              <v-flex class="form-group">
                <label class="form-label">Bank Address Line 3</label>
                <v-text-field
                  placeholder="Enter..."
                  class="form-control"
                  solo
                  :rules="[(v) => matchRegex(v, 'alphanum')] "
                  dense
                  :disabled=" (bankDetailsDisable && !formData.isOverWritten) && !editBankAddress3|| isView"
                  v-model="formData.bankAddressLine3"
                  depressed
                />
              </v-flex>
              <v-flex class="form-group">
                <label class="form-label"
                  >Branch
                  <span v-if="formData.isBankDetailsAvailable" class="red--text"
                    >*</span
                  ></label
                >
                <v-text-field
                  placeholder="Enter..."
                  class="form-control"
                  solo
                  dense
                  :rules="
                    formData.isBankDetailsAvailable
                      ? [(v) => requiredField(v)]
                      : []
                  "
                  v-model="formData.bankBranch"
                  depressed
                  disabled
                />
              </v-flex>
              <v-flex class="form-group">
                <label class="form-label"
                  >District
                  <span v-if="formData.isBankDetailsAvailable" class="red--text"
                    >*</span
                  ></label
                >
                <v-text-field
                  placeholder="Enter..."
                  class="form-control"
                  solo
                  dense
                  :rules="
                    formData.isBankDetailsAvailable
                      ? [(v) => requiredField(v)]
                      : []
                  "
                  v-model="formData.bankDistrict"
                  depressed
                  disabled
                />
              </v-flex>
              <v-flex class="form-group">
                <label class="form-label"
                  >State
                  <span v-if="formData.isBankDetailsAvailable" class="red--text"
                    >*</span
                  ></label
                >
                <v-text-field
                  placeholder="Enter..."
                  class="form-control"
                  solo
                  disabled
                  dense
                  :rules="
                    formData.isBankDetailsAvailable
                      ? [(v) => requiredField(v)]
                      : []
                  "
                  v-model="formData.bankState"
                  depressed
                />
              </v-flex>
              <v-flex class="form-group">
                <label class="form-label"
                  >Pin Code
                  <span v-if="formData.isBankDetailsAvailable" class="red--text"
                    >*</span
                  ></label
                >
                <v-text-field
                  placeholder="Enter..."
                  class="form-control"
                  solo
                  dense
                  :maxlength="6"
                  :rules="
                    formData.isBankDetailsAvailable
                      ? [(v) => requiredField(v)]
                      : []
                  "
                  v-model="formData.bankPincode"
                  depressed
                  disabled
                />
              </v-flex>
              <v-flex class="form-group">
                <label class="form-label"
                  >Bank Account Number
                  <span v-if="formData.isBankDetailsAvailable" class="red--text"
                    >*</span
                  ></label
                >
                <v-text-field
                  placeholder="Enter..."
                  class="form-control"
                  solo
                  type="password"
                  dense
                  :maxlength="35"
                  name="password"
                    value=""
                    id="password"
                    @focus="handleType"
                    @blur="handleType"
                    autocomplete="off"
                    @copy.prevent
                    @paste.prevent
                    @click.right.prevent
                  :rules="
                    formData.isBankDetailsAvailable
                      ? [(v) => requiredField(v),(v)=>matchRegex(v,'alphanum')]
                      : []
                  "
                  :disabled="
                    (bankDetailsDisable && !formData.isOverWritten) || isView
                  "
                  v-model="formData.bankAccountNumber"
                  depressed
                />
              </v-flex>
              <v-flex class="form-group">
                <label class="form-label"
                  >Re-enter Bank Account Number
                  <span v-if="formData.isBankDetailsAvailable" class="red--text"
                    >*</span
                  ></label
                >
                <v-text-field
                  placeholder="Enter..."
                  class="form-control"
                  solo
                  dense
                  v-model="formData.ReEnterBankAccountNumber"
                  :maxlength="35"
                  :rules="
                    formData.isBankDetailsAvailable
                      ? [
                          (v) => requiredField(v),
                          (v) => matchRegex(v, 'alphanum'),
                          (v) => shouldBeSame(v, bankAccount),
                        ]
                      : []
                  "
                  :disabled="
                    (bankDetailsDisable && !formData.isOverWritten) || isView
                  "
                  depressed
                />
              </v-flex>
              <v-flex class="form-group">
                <label class="form-label ml-1"
                  >Account Type<span
                    v-if="formData.isBankDetailsAvailable"
                    class="red--text"
                    >*</span
                  ></label
                >
                <v-select
                  class="form-control"
                  v-model="formData.AccountType"
                  placeholder="Select"
                  :rules="
                    formData.isBankDetailsAvailable
                      ? [(v) => requiredField(v)]
                      : []
                  "
                  :disabled="isView"
                  :items="['Savings', 'Current', 'Cash/credit']"
                  dense
                  solo
                  required
                ></v-select>
              </v-flex>
            </v-layout>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <!-- // upload Documents -->
        <v-expansion-panel>
          <v-expansion-panel-header color="#1EBBD9">
            <span class="page-title-small white--text">Upload Documents</span>
            <template v-slot:actions>
              <v-icon class="white--text">
                {{
                  panels.indexOf(2) !== -1 ? "mdi-minus-box" : "mdi-plus-box"
                }}
              </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-layout wrap class="pt-4 form-group-container">
              <v-tabs v-model="tab" hide-slider>
                <!-- active-class="claimProcessingTabsActive" -->
                <v-tab
                  v-for="item in tab_items"
                  :key="item"
                  @change="
                    documentTab = item
                    checkTabChanged()
                  "
                  :class="
                    documentTab == item
                      ? 'claimProcessingTabsActive'
                      : 'unActive'
                  "
                >
                  {{ item }}
                </v-tab>
              </v-tabs>
              <v-layout class="mt-5">
                <div :disabled="isView">
                  <div
                    class="neftStyle"
                    :disabled="isView"
                    @dragover="dragover"
                    @dragleave="dragleave"
                    @drop="drop"
                    :class="visualEffect ? 'dragbackground' : ''"
                  >
                    <v-icon color="#23b1a9" class="iconfont"
                      >mdi-tray-arrow-up</v-icon
                    >
                    <span
                      class="ml-2 margincls font-weight-bold"
                      :disabled="isView"
                      >Drop Files To Upload or
                      <a @click="browse"><u> Browse</u></a></span
                    >
                    <p class="margincls dlt_text mb-0">
                      Max 5MB of PDF, Word, Excel, Mail or Image(.jpeg, .jpg,
                      .png)
                    </p>
                    <p class="dlt_text mt-0 ml-4" style="font-size: 10px">
                      Multiple documents can be uploaded
                    </p>
                  </div>
                  <span></span>
                  <v-file-input
                    multiple
                    v-model="all_attachments"
                    @change="selectFile"
                    accept=".doc, .docx, .csv, image/png,image/jpeg,application/msword,application/pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/csv"
                    id="SP_fileupload"
                    :disabled="isView"
                    style="display: none"
                  >
                  </v-file-input>
                  <!-- @change="selectFile" -->
                </div>
                <div>
                  <v-data-table
                    :headers="neftHeaders"
                    :items="documentAttachments"
                    hide-default-footer
                    no-data-text="No Records for Attachements"
                    class="tabelwidth vDataTableStyling"
                  >
                    <template v-slot:[`item.file`]="{ item }">
                      <template v-if="item.documentId">
                        {{ item.fileName }}
                      </template>
                      <template v-else>
                        {{ item.file }}
                      </template>
                    </template>
                    <template v-slot:[`item.action`]="{ item, index }">
                      <v-icon
                        class="tableicons mr-4"
                        color="#23B1A9"
                        @click="viewDocument(item)"
                        >mdi-eye</v-icon
                      >
                      <v-icon
                        class="tableicons mr-4"
                        color="#23B1A9"
                        @click="downloadDoc(item)"
                        >mdi-download</v-icon
                      >
                      <v-icon
                        class="tableicons"
                        color="#D1121B"
                        @click="deleteDocument(index)"
                        v-if="!item.documentId"
                        :disabled="isView"
                        >mdi-delete</v-icon
                      >
                    </template>
                    <template v-slot:[`item.createdBy`]>
                      {{
                        courtDetailsForUpdate.updatedBy ||
                        courtDetailsForUpdate.createdBy
                      }}
                    </template>
                    <template v-slot:[`item.updatedAt`]>
                      {{ dateFormat(courtDetailsForUpdate.updatedAt) }}
                    </template>
                  </v-data-table>
                </div>
              </v-layout>
            </v-layout>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-form>
    <v-layout wrap class="mt-5" v-if="!$route.params.fromApproval">
      <v-layout class="mt-0">
        <v-btn
          v-if="isView === false"
          type="button"
          color="red"
          outlined
          class=""
          @click="onReset"
        >
          <v-icon> mdi-backspace </v-icon>

          <v-divider class="mx-2 ml-5" vertical></v-divider>
          <span class="subheading">Reset</span>
        </v-btn>
        <v-btn
          type="button"
          color="red"
          outlined
          class="ml-3 mx-2"
          @click="onCancel(true)"
        >
          <v-icon> mdi-backspace </v-icon>

          <v-divider class="mx-2 ml-5" vertical></v-divider>
          <span class="subheading">Cancel</span>
        </v-btn>
        <v-spacer></v-spacer>

        <v-btn
          v-if="isView === false"
          type="submit"
          color="teal white--text"
          class=""
          @click="onSubmit"
        >
          <v-icon> mdi-arrow-right-circle </v-icon>

          <v-divider class="mx-2" vertical color="white--text"></v-divider>
          <span class="subheading">Submit</span>
        </v-btn>
      </v-layout>
    </v-layout>
    <v-layout wrap class="mt-5" v-if="$route.params.fromApproval">
      <v-layout class="mt-0">
        <v-btn
          type="button"
          color="red"
          outlined
          class="ml-3 mx-2"
          @click="onCancel(true)"
        >
          <v-icon> mdi-backspace </v-icon>

          <v-divider class="mx-2 ml-5" vertical></v-divider>
          <span class="subheading">Cancel</span>
        </v-btn>
        <v-spacer></v-spacer>

        <v-btn
          outlined
          color="orange"
          class="btn mr-5"
          v-show="isView"
          @click="openAuditTrail"
        >
          <v-icon> mdi-chart-timeline-variant </v-icon>
          <v-divider vertical class="mx-2"> </v-divider>Audit Trail</v-btn
        >

        <v-btn
          outlined
          color="red"
          class="btn mr-5"
          v-show="isView"
          @click="reject_Dialog = !reject_Dialog"
        >
          <v-icon> mdi-backspace </v-icon>
          <v-divider vertical class="mx-2"> </v-divider> Reject</v-btn
        >

        <v-btn
          class="btn"
          color="teal white--text"
          dense
          solo
          v-show="isView"
          @click="approve_Dialog = !approve_Dialog"
          ><v-icon>mdi-arrow-right-circle</v-icon>
          <v-divider vertical class="mx-2"></v-divider>
          Approve</v-btn
        >
      </v-layout>
    </v-layout>

    <v-dialog v-model="reject_Dialog" width="600">
      <v-card>
        <v-card-title class="white--text" style="background-color: #23b1a9"
          ><v-layout
            style="display: flex; justify-content: center; margin-left: 170px"
            >Reject
          </v-layout>
          <v-layout style="display: flex; justify-content: end">
            <v-icon
              class="white--text"
              @click="
                reject_Dialog = !reject_Dialog
                reason = ''
              "
              >mdi-close</v-icon
            >
          </v-layout>
        </v-card-title>
        <v-form ref="rejectForm" @submit="false">
          <v-layout class="pt-4 form-group-container">
            <v-flex class="mr-2">
              <label class="form-label"
                >Reason <span><strong class="red--text">*</strong></span></label
              >
              <v-text-field
                class="form-control"
                solo
                dense
                :rules="reject_Dialog ? [(v) => requiredField(v)] : []"
                v-model="reason"
                placeholder="Enter..."
              ></v-text-field>
            </v-flex>
          </v-layout>
        </v-form>
        <v-card-actions style="display: flex; justify-content: center">
          <v-btn
            class="btn"
            color="red white--text mr-0"
            @click="
              reject_Dialog = !reject_Dialog
              reason = ''
            "
          >
            No
          </v-btn>

          <v-btn
            class="btn"
            color="teal white--text mr-0"
            @click="submitApproval"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="approve_Dialog"
      :width="formData.confirmAccountFlag ? '80%' : '600'"
    >
      <v-card>
        <v-card-title class="white--text" style="background-color: #23b1a9"
          ><v-layout style="display: flex; justify-content: center"
            >Approval
          </v-layout>
          <!-- <v-layout style="display:flex;justify-content:end;" width="20">
                        <v-icon class="white--text " @click="approve_Dialog=!approve_Dialog;reason=''">mdi-close</v-icon>
                      </v-layout> -->
        </v-card-title>
        <v-card-text v-if="formData.confirmAccountFlag" class="pb-0">
          <v-layout
            justify-center
            class="pb-2"
            v-if="cancelledChequeData.length > 0"
          >
            <object
              :data="
                cancelledChequeData[0].fileBase
                  ? cancelledChequeData[0].fileBase
                  : showCheque
              "
              width="100%"
              height="300"
            ></object>
            <!-- <img height="300" :src="investigatorMasterObj.documents.fileBase" /> -->
          </v-layout>
          <v-form
            ref="AccountNumberForm"
            style="max-width: 450px; margin: auto"
          >
            <v-flex>
              <label class="form-label"
                >Bank Account Number<span class="mandatorycolor">
                  *</span
                ></label
              >
              <v-text-field
                v-model="validateAccNo"
                class="form-control"
                solo
                dense
                :rules="[
                  (v) => requiredField(v),
                  (v) =>
                    shouldBeSame(
                      formData.ReEnterBankAccountNumber,
                      v,
                      'Mismatch Bank Account Number'
                    ),
                ]"
                label="Enter ..."
              ></v-text-field>
            </v-flex>
          </v-form>
        </v-card-text>
        <v-card-text v-else>
          <v-layout class="pt-4 form-group-container justify-center my-5">
            <h4>Are u sure Want to Approve ?</h4>
          </v-layout>
        </v-card-text>
        <v-card-actions style="display: flex; justify-content: center">
          <v-btn
            class="btn"
            color="red white--text mr-0"
            @click="
              approve_Dialog = !approve_Dialog
              reason = ''
            "
          >
            No
          </v-btn>

          <v-btn
            class="btn"
            color="teal white--text mr-0"
            @click="submitApproval"
          >
            Yes, Approve
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Audit Trail -->
    <audit-trail
      :dialogState="OpenAuditDialog"
      :auditTrailMaster="'court'"
      :auditTrailItem="{ uid: auditTrailId }"
      @close-dialog="OpenAuditDialog = false"
    />
  </div>
</template>

<script>
import PincodeSearch from "../../../component/PincodeSearch.vue"
import auditTrail from "../../../component/MasterAuditTrail.vue"
import moment from "moment"
export default {
  data() {
    return {
      showCheque: "",
      courtTypeItems: [
        "High Court",
        "Supreme Court",
        "District Consumer Forum",
        "State Consumer Forum",
        "NCDRC",
        "E.C.C",
        "PLA",
        "Ombudsman",
        "Civil Court",
        "Other",
      ],
      reject_Dialog: false,
      reason: "",
      approve_Dialog: false,
      courtDetailsForUpdate: {},
      tab: 0,
      panels: [0, 1, 2],
      isView: false,
      cancelledChequeData: [],
      ifscCode: [],
      isOCRUploaded: false,
      cancelledChequeFileShow: false,
      cancelledCheque: [],
      bankAccount: "",
      bankDetailsDisable: true,
      otherAttachment: [],
      modifyItems: [],
      ifsc: null,
      lob_items: ["Health", "Marine", "AIGC", "EW", "Travel", "PA", "Home"],
      initialFormData: {
        //court Detail
        lob: [],
        courtType: "",
        courtName: "",
        pinCode: "",
        city: "",
        district: "",
        state: "",
        addressLine1: "",
        addressLine2: "",
        addressLine3: "",
        contactDetails: "",
        emailID: "",
        //Bank Details
        isBankDetailsAvailable: false,
        ifscCode: "",
        bankName: "",
        accountHolderName: "",
        isOverWritten: false,
        bankCity: "",
        bankAddressLine1: "",
        bankAddressLine2: "",
        bankAddressLine3: "",
        bankBranch: "",
        bankDistrict: "",
        bankState: "",
        bankPincode: "",
        bankAccountNumber: "",
        ReEnterBankAccountNumber: "",
        AccountType: "",
        isActive: false,
        isWatchList: false,
      },
      formData: {},
      documentAttachments: [],
      confirmDisable: false,
      neftAttachment: [],
      all_attachments: [],
      IFSCCodes: [],
      tab_items: ["NEFT Form", "Others"],
      neftHeaders: [
        {
          text: "Document Name",
          align: "start",
          width: "250px",
          value: "file",
          sortable: false,
        },
        {
          text: "Action",
          align: "start",
          value: "action",
          sortable: false,
          width: "150px",
        },
        {
          text: "Upload by",
          align: "start",
          value: "createdBy",
          width: "130px",
          sortable: false,
        },
        {
          text: "Date and Time",
          align: "start",
          value: "updatedAt",
          width: "130px",
          sortable: false,
        },
      ],
      documentTab: "NEFT Form",
      visualEffect: false,
      isModify: false,
      OpenAuditDialog: false,
      auditTrailId: null,
      validateAccNo: "",

      editBankAddress1:false,
            editBankAddress2:false,
            editBankAddress3:false
    }
  },
  methods: {

    checkBankAddress(){
 const specialChars = `\`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~`;

  specialChars.split('').some(specialChar => {
    if (this.formData.bankAddressLine1.includes(specialChar)) {
      this.editBankAddress1 = true
    }
   
  });

  specialChars.split('').some(specialChar => {
    if (this.formData.bankAddressLine2.includes(specialChar)) {
      this.editBankAddress2 = true
    }
  });

  specialChars.split('').some(specialChar => {
    if (this.formData.bankAddressLine3.includes(specialChar)) {
      this.editBankAddress3 = true
    }
  });
      },

    ifscData() {
      if (this.ifsc.length > 3) {
        let request = this.ifsc + "?lob=Marine"
        this.GET_ByURLM(
          "GET_GC_BANK",
          request,
          (res) => {
            this.IFSCCodes = []
            this.hideProgress()
            console.log(res.data.data)
            if (res?.data?.data?.length > 0) {
              this.IFSCCodes = res.data.data
            }
          },
          (error) => {
            this.IFSCCodes = []
            this.hideProgress()
            console.log("error", error)
            this.showAlert(
              error.response &&
                error.response.data &&
                error.response.data.message
                ? error.response.data.message
                : error.message
            )
          },
          true
        )
      }
    },
    ifscchange() {
      this.IFSCCodes.forEach((ifsc) => {
        if (this.formData.ifscCode == ifsc.ifscCode) {
          let ifscCodeValue = ifsc
          this.formData.accountHolderName = ""
          this.formData.bankAccountNumber = ""
          this.formData.ReEnterBankAccountNumber = ""
          this.formData.bankBranch = ifscCodeValue.branchName
          this.formData.bankName = ifscCodeValue.financierName
          this.formData.bankAddressLine1 = ifscCodeValue.branchAddress
          let address2 = ""
          if (ifscCodeValue.district) {
            address2 += `${ifscCodeValue.district}, `
          }
          if (ifscCodeValue.city) {
            address2 += `${ifscCodeValue.city}, `
          }
          if (ifscCodeValue.pincode) {
            address2 += ifscCodeValue.pincode
          }
          this.formData.bankAddressLine2 = address2
          this.formData.bankAddressLine3 = ifscCodeValue.state
          this.formData.bankPincode = ifscCodeValue.pincode
          this.getDistrictState(ifscCodeValue.pincode)
        }
      })
    },
    confirmReset() {
      this.formData = { ...this.initialFormData }
      this.documentAttachments = []
      this.neftAttachment = []
      this.otherAttachment = []
      this.cancelledChequeData = []
    },
    onReset() {
      this.showConfirm(
        "Confirmation",
        "Are you sure you want to Reset?",
        "Yes",
        "No",
        (Yes) => {
          this.confirmReset()
          this.cancelledCheque = null
        },
        (No) => {}
      )
    },
    getPincodes(value) {
      this.formData.pinCode = value.pinCode
      this.formData.city = value.pinCodeLocality
      this.formData.district = value.cityDistrictName //cityDistrictName
      this.formData.state = value.stateName
    },
    overWrittingChange(value) {
      if (value) {
        this.showConfirm(
          "Confirmation",
          "Once checked cannot be unchecked. Do you want to proceed?",
          "Yes",
          "No",
          (Yes) => {
            return (this.confirmDisable = true)
          },
          () => {
            this.formData.isOverWritten = false
          }
        )
      }
    },

    onCancel(data) {
      if (data) {
        this.showConfirm(
          "Confirmation",
          "Are you sure you want to leave?",
          "Yes",
          "No",
          (Yes) => {
            this.showProgress()
            if (this.$route.params.fromApproval)
              this.$router.push({ name: "SearchCourtApprovals" })
            else this.$router.push({ name: "SearchCourtMaster" }) //params:{pageNo:this.pageNo}}
            this.hideProgress()
          }
        )
      } else {
        if (this.$route.params.fromApproval)
          this.$router.push({ name: "SearchCourtApprovals" })
        else this.$router.push("/master/search-court")
      }
    },
    checkBankDetails(value) {
      if (value) {
        this.showAlert("Please Upload Cancelled Cheque")
      } else {
        this.cancelledChequeData = []
        this.formData.ifscCode = ""
        this.formData.bankName = ""
        this.formData.accountHolderName = ""
        this.formData.isOverWritten = false
        this.formData.bankCity = ""
        this.formData.bankAddressLine1 = ""
        this.formData.bankAddressLine2 = ""
        this.formData.bankAddressLine3 = ""
        this.formData.bankBranch = ""
        this.formData.bankDistrict = ""
        this.formData.bankState = ""
        this.formData.bankPincode = ""
        this.formData.bankAccountNumber = ""
        this.formData.ReEnterBankAccountNumber = ""
        this.formData.AccountType = ""
        this.cancelledCheque = null
        this.bankAccount = ""
      }
    },
    clearBankDetails() {},
    seeModify(item) {
      console.log(item.modification)
      this.seeModifyDialog = true
      this.modifyItems = item.modification.map((m, index) => {
        let object = { ...m, srNo: index + 1 }
        return object
      })
    },
    getPayload() {
      let data = this.formData
      let docs = []
      // if (Object.keys(this.$route.params).length != 0) {
      //   docs = [
      //     ...this.cancelledChequeData,
      //     ...this.otherAttachment,
      //     ...this.neftAttachment,
      //   ]
      // } else {
      //   let cancelledcheque = this.cancelledChequeData.map((value) => {
      //     let { fileName, ...rest } = value
      //     return { ...rest, file: fileName }
      //   })
      //   let other = this.otherAttachment.map((value) => {
      //     let { fileName, ...rest } = value
      //     return { ...rest, file: fileName }
      //   })
      //   let neft = this.neftAttachment.map((value) => {
      //     let { fileName, ...rest } = value
      //     return { ...rest, file: fileName }
      //   })
      //   docs = [...cancelledcheque, ...other, ...neft]
      // }
      docs = [
        ...this.cancelledChequeData,
        ...this.otherAttachment,
        ...this.neftAttachment,
      ]

      return {
        lob: data.lob,
        court: {
          type: data.courtType,
          name: data.courtName,
          city: data.city,
          addressLine1: data.addressLine1,
          addressLine2: data.addressLine2,
          addressLine3: data.addressLine3,
          district: data.district,
          state: data.district,
          pinCode: data.pinCode,
          contactDetails: data.contactDetails,
          emailID: data.emailID,
          active: data.isActive,
        },
        approveRequest: true,
        bank: {
          accountHolderName: data.accountHolderName,
          bankDetails: data.isBankDetailsAvailable,
          bankName: data.bankName,
          ifscCode: data.ifscCode,
          documents: [...docs],
          city: data.bankCity,
          bankAddressLine1: data.bankAddressLine1,
          bankAddressLine2: data.bankAddressLine2,
          bankAddressLine3: data.bankAddressLine3,
          branch: data.bankBranch,
          district: data.bankDistrict,
          state: data.bankState,
          pinCode: data.bankPincode,
          accountNumber: this.bankAccount,
          confirmAccountNumber: this.bankAccount,
          accountType: data.AccountType,
          watchList: data.isWatchList,
        },
        overwritten: [{ status: data.isOverWritten }],
      }
    },
    updateCourtDetails(payload) {
      console.log(
        "1234",
        JSON.stringify(payload) == JSON.stringify(this.courtDetailsForUpdate)
      )
      console.log(
        "2",
        JSON.stringify(payload),
        JSON.stringify(this.courtDetailsForUpdate)
      )
      let updateId = this.$route.params.item._id
      this.PUT_ByURLM(
        "PUT_UPDATE_COURT",
        payload,
        (res) => {
          this.showAlert(res?.data?.msg || "Success", "", "", () => {
            this.onCancel(false)
          })
          if (res.data.statusCode === 200) {
            console.log("999", res.data)
          }
        },
        (error) => {
          console.log("Error", error)
          // this.showAlert(res.data.msg)
        },
        updateId,
        true
      )
    },
    createCourtDetails(payload) {
      this.POSTM(
        "POST_CREATE_COURT",
        payload,
        (res) => {
          this.showAlert(res?.data?.msg || "Success", "", "", () => {
            // this.onReset();
            // this.cancel(1)
          })
          if (res.data.statusCode === 200) {
            console.log("999", res.data)
            this.onCancel(false)
          }
        },
        (error) => {
          console.log("Error", error)
          // this.showAlert(res.data.msg)
        },
        true
      )
    },
    submitCourtDetails() {
      let payload = this.getPayload()
      console.log("888", payload)

      if (Object.keys(this.$route.params).length != 0) {
        this.updateCourtDetails(payload)
      } else {
        this.createCourtDetails(payload)
      }
    },
    onSubmit() {
      if (!this.$refs.createForm.validate()) {
        return this.showAlert("Please fill the mandatory fields")
      } else {
        this.checkDepulicateRecord()
      }
    },
    submitApproval() {
      if (
        !this.$refs?.AccountNumberForm?.validate() &&
        this.formData.confirmAccountFlag &&
        !this.reject_Dialog
      ) {
        return this.showAlert("Please fill bank account number.")
      }
      if (this.reject_Dialog) {
        if (!this.$refs?.rejectForm?.validate()) {
          return this.showAlert("Please fill mandatory fields.")
        }
      }
      let sendData = {
        approveStatus: this.reject_Dialog ? "Rejected" : "Approved",
        court: this.formData,
        rejectionReason: this.reason,
      }
      // this.courtDetails.approveStatus=this.reject_Dialog ? "Rejected" : "Approved"
      // this.courtDetails.rejectionReason=this.reason
      // this.frequencyPayee.approveStatus=this.reject_Dialog ? "Rejected" : "Approved"
      this.reject_Dialog = false
      this.approve_Dialog = false

      this.PUTM(
        "updateMasterApprove",
        this.$route.params.item._id,
        sendData,
        (res) => {
          this.hideProgress()
          if (res.data && res.data.statusCode == 200) {
            this.showAlert(res?.data?.msg || "Success", "", "", () => {
              this.onCancel(false)
            })
          } else {
            this.showAlert(res.data.msg)
            this.hideProgress()
          }
        },
        (err) => {
          console.log(err)
          this.hideProgress()
          let msg =
            err?.response?.data?.msg ||
            "Unable to submit court Master Approval."
          this.showAlert(msg)
        },
        true
      )
    },
    dragleave(event) {
      this.visualEffect = false
    },
    browseCancel() {
      //to select attachments
      document.getElementById("cancelchequeupload").click()
    },
    dragover(event) {
      event.preventDefault()
      this.visualEffect = true
    },
    getDistrictState(pinCode) {
      if (pinCode === "") return
      let reqData = "pageNo=1&perPage=100&pinCode=" + pinCode
      this.GETM(
        "getPincodes",
        reqData,
        (res) => {
          if (res?.data?.data?.data) {
            let result = res.data.data.data[0]
            this.formData.bankPincode = result.pinCode
            this.formData.bankCity = result.cityDistrictName
            this.formData.bankState = result.stateName
            this.formData.bankDistrict = result.cityDistrictName
          }
        },
        (err) => {
          console.error("Issue while fetching pin codes.", err)
        }
      )
    },
    viewDocUsingBase(base64) {
      const base64ImageData = base64
      const contentType = base64.split(":")[1].split(";")[0]
      const byteCharacters = window.atob(
        base64ImageData.substr(`data:${contentType};base64,`.length)
      )
      const byteArrays = []
      for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
        const slice = byteCharacters.slice(offset, offset + 1024)
        const byteNumbers = new Array(slice.length)
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i)
        }
        const byteArray = new Uint8Array(byteNumbers)
        byteArrays.push(byteArray)
      }
      const blob = new Blob(byteArrays, { type: contentType })
      const blobUrl = window.URL.createObjectURL(blob)
      window.open(blobUrl, "_blank")
    },
    viewDocument(itemData) {
      if (itemData.fileBase) {
        this.viewDocUsingBase(itemData.fileBase)
      } else {
        let requestData = ""
        if (itemData !== null) {
          requestData = "documentId=" + itemData.documentId + "&b64=true"
        }
        this.GETM(
          "VIEW_DOCUMENT",
          requestData,
          (res) => {
            if (res.data.statusCode === 200) {
              this.viewDocUsingBase(res.data.data)
            }
          },
          (error) => {
            console.log("Error", error)
          },
          true
        )
      }
    },
    dropCancel(event) {
      event.preventDefault()
      this.cancelledCheque = event.dataTransfer.files
      // console.log("all", this.all_attachments);
      this.OCRUpload() // Trigger the onChange event manually
    },
    dragoverCancel(event) {
      event.preventDefault()
    },
    cancelChequeView() {
      this.cancelledChequeFileShow = !this.cancelledChequeFileShow
    },
    downloadDoc(item) {
      if (item.fileBase) {
        let base64 = item.fileBase
        const base64ImageData = base64
        const contentType = base64.split(":")[1].split(";")[0]
        const byteCharacters = window.atob(
          base64ImageData.substr(`data:${contentType};base64,`.length)
        )
        const byteArrays = []
        for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
          const slice = byteCharacters.slice(offset, offset + 1024)
          const byteNumbers = new Array(slice.length)
          for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i)
          }
          const byteArray = new Uint8Array(byteNumbers)
          byteArrays.push(byteArray)
        }
        const blob = new Blob(byteArrays, { type: contentType })
        const blobUrl = window.URL.createObjectURL(blob)

        const element = document.createElement("a")
        element.href = blobUrl
        element.download = item.file
        element.click()
      } else {
        let url =
          this.getURL("VIEW_DOCUMENT") + "?documentId=" + item.documentId
        window.open(url)
      }
    },
    handleType(event) {
      const { srcElement, type } = event
      const { name, value } = srcElement

      if (type === "blur" && !value) {
        this.fieldTypes[name] = "text"
      } else {
        this.fieldTypes[name] = "password"
      }
    },
    OCRUpload() {
      if (this.cancelledCheque) {
        console.log("4444", this.cancelledCheque)
        this.cancelledChequeData = []
        this.cancelledChequeFileShow = false //hide view port
        this.isOCRUploaded = false //check if OCR uploaded
        this.formData.isOverWritten = false //check for manual entry
        this.clearBankDetails() //reset bank fields
        if (!this.cancelledCheque.name) {
          return // for while resetting the form
        }
        this.showProgress()

        this.readImageM(
          this.cancelledCheque.name,
          this.cancelledCheque,
          (res) => {
            let requestPayload = {
              document: res.base,
            }
            this.cancelledCheque = [] //reset field value
            this.POSTM(
              "GET_OCR_FORMAT",
              requestPayload,
              (response) => {
                this.hideProgress()
                if (
                  response.data.data.Message ==
                  "Uploaded Image/Document orientation is incorrect"
                ) {
                  this.showAlert(response.data.data.Message)
                  this.formData.isOverWritten = true
                } else if (
                  response.data.data.Message == "Invalid Image/Document"
                ) {
                  this.showAlert(response.data.data.Message)
                  this.formData.isOverWritten = true
                } else if (response.data.data) {
                  let bankres = response.data.data
                  console.log(response.data.data)
                  if (
                    !bankres.AccountNumber ||
                    !bankres.IFSC ||
                    !bankres.PayerName
                  ) {
                    this.isOCRUploaded = true

                    this.formData.isOverWritten = true
                    this.showAlert(
                      "Could not retrieve data from OCR either re-upload the cancelled cheque or enter the details manually"
                    )
                  } else {
                    //temprory fix
                    this.showProgress()
                    let request = bankres.IFSC + "?lob=Marine"
                    this.GET_ByURLM(
                      "GET_GC_BANK",
                      request,
                      (res) => {
                        this.IFSCCodes = []
                        this.hideProgress()
                        console.log(res.data.data)
                        if (res?.data?.data?.length > 0) {
                          let ifscCodeValue = res.data.data[0]
                          console.log("9999", bankres)
                          console.log("33333", ifscCodeValue)
                          this.IFSCCodes = res.data.data
                          this.formData.ifscCode = bankres.IFSC
                          this.formData.bankAccountNumber =
                            bankres.AccountNumber
                          //this.checkBankAccount()
                          this.formData.ReEnterBankAccountNumber =
                            bankres.AccountNumber
                          this.formData.bankBranch = ifscCodeValue.branchName

                          this.formData.bankName = ifscCodeValue.financierName
                          this.formData.bankAddressLine1 =
                            ifscCodeValue.branchAddress
                          let address2 = ""
                          if (ifscCodeValue.district) {
                            address2 += `${ifscCodeValue.district}, `
                          }
                          if (ifscCodeValue.city) {
                            address2 += `${ifscCodeValue.city}, `
                          }
                          if (ifscCodeValue.pincode) {
                            address2 += ifscCodeValue.pincode
                          }
                          this.formData.bankAddressLine2 = address2
                          this.formData.bankAddressLine3 = ifscCodeValue.state
                          this.checkBankAddress()

                          this.formData.bankPincode = ifscCodeValue.pincode
                          this.formData.bankState = ifscCodeValue.state
                          this.getDistrictState(ifscCodeValue.pincode)
                          this.showAlert("OCR uploaded successfully")

                          // this.pincode1=ifscCodeValue.pincode

                          // this.changePincodeAndState(1)
                          // this.formDataAddressLine2 = `${ifscCodeValue.district}, ${ifscCodeValue.city}, ${ifscCodeValue.state}`
                        }
                      },
                      (error) => {
                        this.IFSCCodes = []
                        this.hideProgress()
                        console.log("error", error)
                        this.showAlert(
                          error.response &&
                            error.response.data &&
                            error.response.data.message
                            ? error.response.data.message
                            : error.message
                        )
                      },
                      true
                    )

                    this.cancelledChequeData.push({
                      file: res.fileName,
                      fileBase: res.base,
                      documentType: "Cancelled Cheque",
                    })

                    // this.formData.AccountNumber = bankres.AccountNumber;
                    // this.reEnterBankAccountNumber = bankres.AccountNumber;
                    this.formData.accountHolderName = bankres.PayerName
                    this.isOCRUploaded = true
                  }
                } else {
                  this.showAlert("No data found from OCR")
                }
              },
              (error) => {
                this.hideProgress()
                this.formData.isOverWritten = true
                this.showAlert(
                  "Something went wrong in OCR,Please enter the details manually"
                )
              },
              true
            )
          }
        )
      } else {
        this.showAlert("Please upload a file.")
      }
    },
    emailValidation(value) {
      let regex = new RegExp(/^[a-zA-Z0-9@. ]*$/)
      if (value && !regex.test(value)) {
        // if (field != "") return `field is invalid.`;
        return "Invalid email id"
      }
      return this.validateEmail(value, "", "Invalid email id")
    },
    checkBankAccount() {
      this.bankAccount = this.formData.bankAccountNumber
      this.formData.bankAccountNumber = ""
      for (let i = 0; i <= this.bankAccount?.length - 1; i++) {
        this.formData.bankAccountNumber += "*"
      }
    },
    deleteDocument(index) {
      // if(type === 'neft') {
      //     this.neftAttachments.splice(index, 1);
      // }

      // if(type === 'kyc') {
      //     this.kycAttachments.splice(index, 1);
      // }

      // if(type === 'others') {
      //     this.otherAttachments.splice(index, 1);
      // }
      // this.all_attachments = []
      this.showConfirm(
        "Confirmation",
        "Are you sure you want to Delete ?",
        "Yes",
        "No",
        (Yes) => {
          if (this.documentTab == "NEFT Form") {
            this.neftAttachment.splice(index, 1)
          }

          if (this.documentTab == "Others") {
            this.otherAttachment.splice(index, 1)
          }
          if (this.documentTab == "NEFT Form") {
            this.documentAttachments = this.neftAttachment
          }
          if (this.documentTab == "Others") {
            this.documentAttachments = this.otherAttachment
          }
          this.all_attachments = ""
        }
      )
    },
    browse() {
      //to select attachments
      document.getElementById("SP_fileupload").click()
    },

    drop(event) {
      event.preventDefault()
      this.all_attachments = event.dataTransfer.files
      // console.log("all", this.all_attachments);
      this.selectFile() // Trigger the onChange event manually
      this.visualEffect = false
    },

    checkTabChanged() {
      if (this.documentTab == "NEFT Form") {
        this.documentAttachments = this.neftAttachment
      }
      if (this.documentTab == "Others") {
        this.documentAttachments = this.otherAttachment
      }
    },
    bankAddressValidation(value) {
      var regex = /^[A-Za-z0-9 ]+$/
      var isValid = regex.test(value)
      if (!isValid) {
        return "Special characters are not allowed"
      } else return true
    },
    selectFile() {
      if (this.all_attachments.length !== 0) {
        const fsize = this.all_attachments[0].size
        const file = Math.round(fsize / 1024)
        let self = this
        if (file > 5120) {
          self.showAlert("File is too big, please select a file less than 5MB")
        } else {
          self.readImageM(
            self.all_attachments[0].name,
            self.all_attachments[0],
            (res) => {
              console.log(res)
              var type = []
              type = self.all_attachments[0].type
              console.log("--", self.documentTab)
              if (self.documentTab == "NEFT Form") {
                if (
                  !self.neftAttachment.find(
                    (iorta) => iorta.file == this.all_attachments[0].name
                  )
                ) {
                  self.neftAttachment.push({
                    file: res.fileName,
                    fileBase: res.base,
                    documentType: "NEFT Form",
                  })
                }
              } else {
                if (
                  !self.otherAttachment.find(
                    (iorta) => iorta.file == this.all_attachments[0].name
                  )
                ) {
                  self.otherAttachment.push({
                    file: res.fileName,
                    fileBase: res.base,
                    documentType: "OTHERS",
                  })
                }
              }
              this.all_attachments = []
              console.log(self.otherAttachment)
              // // Phase-2 function call starts
              // this.getCancelledChequeData(res.base);
              // this.manualOverwrite = false;
              // // Phase-2 function call ends
            }
          )
        }
      }

      this.checkTabChanged()
    },
    getModifyDoc(documents) {
      let result = documents.map((doc) => {
        let { fileName, ...rest } = doc
        rest.file = fileName
        return rest
      })
      return result
    },
    checkDepulicateRecord() {
      this.showProgress()
      let reqData = "page=1&pageLimit=10"
      reqData =
        reqData +
        "&courtType=" +
        this.formData.courtType +
        "&courtName=" +
        this.formData.courtName
      this.GETM(
        "GET_SEARCH_COURT",
        reqData,
        (res) => {
          this.hideProgress()
          if (
            res.data.statusCode === 200 &&
            res.data.msg === "court has been fetched successfully"
          ) {
            this.showAlert(
              "Record already exist with same court Name and court type",
              "",
              "",
              () => {
                return this.submitCourtDetails()
              }
            )
          }
          this.submitCourtDetails()
        },
        (err) => {
          this.hideProgress()
        }
      )
    },
    viewDocumentCheque(id) {
      let requestData = "documentId=" + id + "&b64=true"
      this.GETM(
        "VIEW_DOCUMENT",
        requestData,
        (res) => {
          if (res.data.statusCode === 200) {
            this.showCheque = res.data.data
          }
        },
        (error) => {
          console.log("Error", error)
        },
        true
      )
    },
    populateFields(result) {
      console.log("%%%", result)
      //court Detail
      // this.formData = result
      this.formData.lob = result.lob
      this.formData.courtType = result.court.type
      this.formData.courtName = result.court.name
      this.formData.pinCode = result.court.pinCode
      this.formData.city = result.court.city
      this.formData.district = result.court.district
      this.formData.state = result.court.state
      this.formData.addressLine1 = result.court.addressLine1
      this.formData.addressLine2 = result.court.addressLine2
      this.formData.addressLine3 = result.court.addressLine3
      this.formData.contactDetails = result.court.contactDetails
      this.formData.emailID = result.court.emailID
      this.getDistrictState(result.bank.bankPincode)

      //Bank Details
      this.IFSCCodes.push(result.bank.ifscCode)
      this.formData.isBankDetailsAvailable = result.bank.bankDetails
      this.formData.bankName = result.bank.bankName
      this.formData.accountHolderName = result.bank.accountHolderName
      this.formData.bankCity = result.bank.city
      this.formData.bankAddressLine1 = result.bank.bankAddressLine1
      this.formData.bankAddressLine2 = result.bank.bankAddressLine2
      this.formData.bankAddressLine3 = result.bank.bankAddressLine3
      this.checkBankAddress()
      this.formData.ifscCode = result.bank.ifscCode
      this.formData.bankBranch = result.bank.branch
      this.formData.bankDistrict = result.bank.district
      this.formData.bankState = result.bank.state
      this.formData.bankPincode = result.bank.pinCode
      this.formData.bankAccountNumber = result.bank.accountNumber //
      this.formData.ReEnterBankAccountNumber = result.bank.confirmAccountNumber
      this.bankAccount = result.bank.accountNumber
      this.formData.AccountType = result.bank.accountType
      this.formData.isActive = result.court.active
      this.formData.isWatchList = result.bank.watchList
      this.formData.isOverWritten = result.overwritten[0].status
      if (!this.$route.params.fromApproval)
        this.formData.isOverWritten = result.overwritten[0]?.status
      this.auditTrailId = result.uid || null
      this.formData.isOverWritten = result?.overwritten?.[0]?.status || false
      this.formData.confirmAccountFlag =
        result?.bank?.confirmAccountFlag || false
      this.popupOCR(this.formData.isOverWritten)
      // let docs = this.getModifyDoc(result.bank.documents)
      let docs = result.bank.documents
      // this.cancelledChequeData = docs.filter(
      //   (val) =>{ val.documentType === "Cancelled Cheque"
      //   this.viewDocumentCheque(val.documentId)
      // }
      // )

      // this.otherAttachment = docs.filter((val) => val.documentType === "OTHERS")
      // let neft = docs.filter((val) => val.documentType === "NEFT Form")
      docs.map((data) => {
        if (data.documentType == "Cancelled Cheque") {
          this.cancelledChequeData.push(data)
          this.viewDocumentCheque(data.documentId)
        }
        if (data.documentType == "OTHERS") {
          this.otherAttachment.push(data)
        }
        if (data.documentType == "NEFT Form") {
          this.neftAttachment.push(data)
        }
      })
      this.documentAttachments = this.neftAttachment

      //this.checkBankAccount()
    },
    getApprovalCourt(item) {
      let requestData = item._id
      this.GET_ByURLM(
        "getFrequentApprove",
        requestData,
        (res) => {
          // this.showAlert(err)
          if (res.data && res.data.data) {
            this.courtDetailsForUpdate = res.data.data
            let result = res.data.data.operationData[0]
            this.populateFields(result)
          }
        },
        (err) => {
          this.showAlert(err)
          console.log(err)
        },
        true
      )
    },
    getCourt(item) {
      //GET_COURT
      console.log("%%", item)
      let requestData = item._id
      this.GET_ByURLM(
        "GET_COURT",
        requestData,
        (res) => {
          // this.showAlert(err)
          if (res?.data?.data) {
            let result = res.data.data
            this.courtDetailsForUpdate = result
            this.populateFields(result)
          }
        },
        (err) => {
          this.showAlert(err)
          console.log(err)
        },
        true
      )
    },
    dateFormat(dateTime) {
      return dateTime
        ? moment(dateTime).utcOffset("+05:30").format("DD-MM-YYYY HH:mm")
        : ""
    },
    openAuditTrail() {
      this.OpenAuditDialog = true
    },
  },
  components: {
    PincodeSearch,
    auditTrail,
  },
  created() {
    this.formData = { ...this.initialFormData }
    if (Object.keys(this.$route.params).length != 0) {
      //isView
      this.isView = this.$route.params.isView
      this.isModify = !this.isView
      let result = this.$route.params.item
      console.log("%%%%", result, this.$route.params.fromApproval)
      if (this.$route.params.fromApproval) {
        this.getApprovalCourt(result)
      } else {
        this.getCourt(result)
      }
      this.$store.commit("SAVE_PAGE_HEADER", "Court Master")
    }
  },
}
</script>

<style scoped>
.controlfield {
  box-shadow: 0px 1px 3px rgb(0 0 0 / 25%) !important;
  border: rgba(0, 0, 0, 0.125) solid 1px;
  padding: 6px;
}
.claimProcessingTabsActive {
  background-color: #1ebbd9;
  color: #fff !important;
}
.unActive {
  border: 1px solid #ccc;
  margin: 0px 5px;
}
.dragbackground {
  background-color: #e7e7e7;
}
.dlt_text {
  color: rgb(145, 145, 145);
}

.tabelwidth {
  width: 665px;
  margin: 0px 150px;
}
.neftStyle {
  height: 150px;
  width: 350px;
  border: 3px dashed #ccc;
  /* padding-top: 40px;
        padding-left: 16%; */
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
.margincls {
  font-size: 12px;
  margin-left: -12px;
}
.CancelledCheque {
  color: #0ab417;
  font: normal normal medium 12px/14px Roboto;
  cursor: pointer;
}
</style>
