<template>
    <v-app>
        <div class="">
        <v-form v-model="valid"  @submit.prevent="saveAndCreate()" ref="form">
            <v-layout class="mt-4 mx-2">
                <v-flex xs3 >
                    <v-layout column class=" mx-2 cust_celcy">
                        <p class="font-weight-bold cstm-clr-ceplcy">
                            Product Code
                        </p>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="crtEdtPdt.pdtCd"
                             class="rounded-0"
                             disabled
                             >
                        </v-text-field>
                    </v-layout>
                </v-flex>

                 <v-flex xs3 >
                    <v-layout column class=" mx-2 cust_celcy">
                        <p class="font-weight-bold cstm-clr-ceplcy">
                            Product Description
                        </p>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="crtEdtPdt.pdtDsc"
                             class="rounded-0"
                             disabled
                             >
                        </v-text-field>
                    </v-layout>
                </v-flex>

                 <v-flex xs3 >
                    <v-layout column class=" mx-2 cust_celcy">
                        <p class="font-weight-bold cstm-clr-ceplcy">
                            Policy Number
                        </p>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="crtEdtPdt.plcyNo"
                            :error-messages="plcyNo_chk"
                            class="rounded-0"
                            :maxlength="max30"
                            counter
                        >
                        </v-text-field>
                    </v-layout>
                </v-flex>

                <v-flex xs3 >
                    <v-layout column class=" mx-2 cust_celcy">
                        <p class="font-weight-bold cstm-clr-ceplcy">
                           Corporate Group Name
                        </p>
                        <v-text-field
                            solo
                            label="Enter..."
                            v-model="crtEdtPdt.plcyCrpGrpNme"
                            class="rounded-0"
                            :maxlength="max30"
                            counter
                             
                        >
                        </v-text-field>
                    </v-layout>
                </v-flex>


               
            </v-layout>
            <v-layout class="mx-2">

                <v-flex xs3>
                    <v-layout column class="cust_celcy mx-2 ">
                        <p class="font-weight-bold cstm-clr-ceplcy">
                            Policy Effective Date
                        </p>
                     
                        <v-menu
                            ref="calender"
                            :close-on-content-click="true"
                            v-model="crtEdtPdt.plcyEfftvDtecalender"
                            :nudge-right="40"
                           
                            transition="scale-transition"
                            
                            max-width="290px"
                            min-width="290px" 
                              class="rounded-0"
                            >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    slot="activator"
                                    label="Select.."
                                    :value="cmputdplcyEfftvDteMmntjs"
                                    solo
                                     class="rounded-0"
                                    readonly 
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                                                        
                                    <v-date-picker
                                        v-model="crtEdtPdt.plcyEfftvDte"
                                        no-title
                                        
                                    ></v-date-picker>
                        </v-menu>


                    </v-layout>
                </v-flex>


                <v-flex xs3>
                    <v-layout column class="cust_celcy mx-2 ">
                        <p class="font-weight-bold cstm-clr-ceplcy">
                           Policy Expiry Date
                        </p>
                     
                        <v-menu
                            ref="calender"
                            :close-on-content-click="true"
                            v-model="crtEdtPdt.plcyExpryDtecalender"
                            :nudge-right="40"
                           
                            transition="scale-transition"
                            
                            max-width="290px"
                            min-width="290px" 
                              class="rounded-0"
                            >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    slot="activator"
                                    label="Select.."
                                    :value="cmputdplcyExpryDteMmntjs"
                                    solo
                                     class="rounded-0"
                                    readonly 
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                                                        
                                    <v-date-picker
                                        v-model="crtEdtPdt.plcyExpryDte"
                                        no-title
                                        
                                    ></v-date-picker>
                        </v-menu>


                    </v-layout>
                </v-flex>
                 
                <v-flex xs3></v-flex>
                <v-flex xs3></v-flex>
            </v-layout>


             <v-card style="background-color:#F7F7F7" tile class="mb-3 pa-2 py-4">

                <v-layout style="flex:1" justify-end>
                    <v-btn dark  tile style="background-color:#E46A25"  class="text-capitalize px-3 mr-3" small @click="searchPlcy()"><v-icon dark small>search</v-icon> <v-divider vertical class="mx-2"></v-divider>Search</v-btn>
                    <v-btn dark tile  small style="background-color:#152F38;font-size:12px" class="text-capitalize px-3 mr-3"  @click="$router.push('/homePage')">
                        <div class="">
                            <img src="../../assets/return.png" style="width: 16px;height: 16px"/>
                        </div> <v-divider vertical class="mx-2"></v-divider>Return</v-btn> 
                    <v-btn dark tile style="font-size:12px;background-color:#23B1A9" small  class="text-capitalize px-3 mr-3" @click="createPlcyFrPdct()" ><v-icon dark small >add_circle </v-icon> <v-divider vertical class="mx-2"></v-divider>Create Policy</v-btn>
                     <v-btn tile 
                         :dark="!clonebtn" 
                         style="font-size:12px;color:white;background-color:#23B1A9" small  
                         class="text-capitalize  px-3"
                         :disabled="clonebtn" @click="clonePlcyData(selected[0])">
                                        <v-icon dark small >edit</v-icon > 
                                        <v-divider vertical class="mx-2"></v-divider>
                                        Clone
                            </v-btn>
                </v-layout>

            </v-card>
            </v-form>


        <div v-if="plcyfrPdtSrch===true">   <!-- cust-tbl-ceplcy -->
            <v-card  tile class="cust-tbl-ceplcy mx-2">
                <!-- @page-count="pageCount = $event"
                        :page.sync="page"
                        :items-per-page="itemsPerPage"
                     -->
                <div  style="background-color:#23B1A9" class="pa-2 pl-3"><v-text class="font-weight-bold body-2"  style="color:#F5F5F5">Search Results</v-text></div>
                <v-data-table
                    :headers="headers"
                    :items="getPlcyfrPdtDta"
                   
                    hide-default-footer
                    dense
                    disable-pagination
                    :single-select="true"
                    show-select
                    item-key="policyNumber"
                    v-model="selected"
                >

                <template v-slot:item.policyNumber={item}> 
                    <div class="" @click="sndPlcyDtafrEdt(item)" style="text-decoration:underline;color:#1976d2;cursor:pointer">
                        {{item.policyNumber}}
                    </div>  
                </template>
                 <template v-slot:item.policyEffectiveDate={item}> 
                    <div>
                        {{item.policyEffectiveDate|formatDate}}
                    </div>  
                </template>
                 <template v-slot:item.policyExpiryDate={item}> 
                    <div>
                        {{item.policyExpiryDate|formatDate}}
                    </div>  
                </template>
                </v-data-table>
                <v-divider></v-divider>
               
            </v-card>
            <v-card style="background-color:#F7F7F7" tile class="mx-2">
                 <v-layout row justify-end align-center class="mx-2" >
                            <span class="mr-1 font-weight-bold" style="color:#3C3D3D; font-size:13px;">
                                <span class="" style="color:#E46A25;">Showing {{fromvalue}}-{{toValu}} </span>out of {{totalCount}} records
                            </span>
                            <v-divider  class="ma-2" vertical ></v-divider>
                             <v-pagination
                                class="cust-bnkAcc-pgn"
                                v-model="page"
                                color="#152F38"
                                flat
                                @input="nextPageData(page)"
                                :length="pageCount"
                            ></v-pagination>
                </v-layout>

            </v-card>
        </div>
         <v-card  v-if="rsltnotfound===true" class="mx-6 mt-1"  tile >
            <v-layout align-center justify-center row style="" class="pa-3">
                <v-text class="font-weight-bold cstm-clr-pr">
                    No Search Results Found
                </v-text>

            </v-layout>
        </v-card>
        </div>
    </v-app>
</template>

<script>
import axios from "axios";
import moment from 'moment';
// const { required, minLength, alpha } = require('vuelidate/lib/validators/')
import {alphaNum } from 'vuelidate/lib/validators'
export default {

    validations: {

        crtEdtPdt:{
            plcyNo:{
             alphaNum
            }
        }

    },

    components:{
        
    },

    created() {

        this.$store.commit("SAVE_PAGE_HEADER", "Policy Search");
        this.token=this.$store.getters.successToken
        // this.crtEdtPdt.pdtCd=this.$store.getters.fetchProductCodeData
        this.getProductDetailsByID(this.$store.getters.fetchProductCodeData)

    },
    filters:{

      truncate: function (text, length, suffix) {
            if (text.length > length) {
                return text.substring(0, length) + suffix;
            } else {
                return text;
            }
        },
        formatDate(data){// eslint-disable-line no-mixed-spaces-and-tabs
            // console.log('Validity Data', data);

             if (data == "" || data == "undefined" || data == null || data == '' || data == 'Invalid Date') {
                return "-"
            } else {
                // var n = new Date(data);
                // return n.toLocaleString("en-IN")
                return moment(data,'YYYY-MM-DD').format('DD-MM-YYYY')
            }
        },
    },

    computed: {

        cmputdplcyEfftvDteMmntjs(){
            return this.crtEdtPdt.plcyEfftvDte ? moment(this.crtEdtPdt.plcyEfftvDte).format("DD-MM-YYYY"): ''
        },
        cmputdplcyExpryDteMmntjs(){
            return this.crtEdtPdt.plcyExpryDte ? moment(this.crtEdtPdt.plcyExpryDte).format("DD-MM-YYYY"): ''

        },

        clonebtn() {
        
            if(this.selected.length===0){
                return true
            }else{
                return false
            }
        },
        plcyNo_chk(){
            if(this.crtEdtPdt.plcyNo.length > 0){

             if(/^[A-Za-z0-9 ]+$/g.test(this.crtEdtPdt.plcyNo) != true){
                    // this.ttl_err = true
                    return "Special Characters Not Allowed"
                }else{
                    // this.ttl_err = false
                    return []
                }
              }else{
                return []
              }
        },
    },
    data() {
        return{
            crtEdtPdt:{
                pdtCd:"",
                pdtDsc:"",
                plcyEfftvDte:"",
                plcyExpryDte:"",
                plcyNo:"",
                plcyCrpGrpNme:"",
                plcyEfftvDtecalender:false,
                plcyExpryDtecalender:false
            },
            valid:false,
            plcyfrPdtSrch:false,
            rsltnotfound:false,
            pdtStatus:["Active", "InActive"],
            itemsPerPage: 10,
            page: 1,
            max30:30,
            token:null,
            pageCount: 0,
            totalCount:0,
            crntPgNo:0,
            fromvalue:"",
            toValu:"",
            selected:[],
            headers:[
                
                {text: 'Policy Number', value: 'policyNumber',sortable: false,divider:true,class:"hdr-txt-clr-cepdt"},
                {text: 'Product Description', value: 'productDescription',sortable: false,divider:true,class:"hdr-txt-clr-cepdt"},
                {text: 'Policy Effective Date', value: 'policyEffectiveDate',sortable: false,divider:true,class:"hdr-txt-clr-cepdt"},
                {text: 'Policy Expiry Date', value: 'policyExpiryDate',sortable: false,divider:true,class:"hdr-txt-clr-cepdt"},
                {text: 'Policy Status', value: 'policyStatus',sortable: false,divider:true,class:"hdr-txt-clr-cepdt"},
            ],
            chkr_items: [
                { title: 'Delete', icon:'delete',color:"#646666"},
                { title: 'Edit', icon:'check_circle',color:"#1E9690" },
                { title: 'Configure' , icon:'settings',color:"#D1121B"},
                
            ],
            getPlcyfrPdtDta:[],
            GetBnkMstrHstryDta:[
                {
                    PlanName:"1002876",
                    PlanDescription:"Medicare - Plan A",
                    PlanEffectiveDate:"13/1/2019",
                    PlanExpiryDate:"13/1/2020",
                    PlanStatus:"Active",
                    PolicyType:"Retail Health",
                    ExternalRefrenceNo:"- - - - - -"
                },{
                    PlanName:"1002877",
                    PlanDescription:"Medicare - Plan A",
                    PlanEffectiveDate:"13/1/2019",
                    PlanExpiryDate:"13/1/2020",
                    PlanStatus:"Active",
                    PolicyType:"Retail Health",
                    ExternalRefrenceNo:"- - - - - -"
                },{
                    PlanName:"1002878",
                    PlanDescription:"Medicare - Plan A",
                    PlanEffectiveDate:"13/1/2019",
                    PlanExpiryDate:"13/1/2020",
                    PlanStatus:"Active",
                    PolicyType:"Retail Health",
                    ExternalRefrenceNo:"- - - - - -"
                },{
                    PlanName:"1002879",
                    PlanDescription:"Medicare - Plan A",
                    PlanEffectiveDate:"13/1/2019",
                    PlanExpiryDate:"13/1/2020",
                    PlanStatus:"Active",
                    PolicyType:"Retail Health",
                    ExternalRefrenceNo:"- - - - - -"
                },{
                    PlanName:"1002880",
                    PlanDescription:"Medicare - Plan A",
                    PlanEffectiveDate:"13/1/2019",
                    PlanExpiryDate:"13/1/2020",
                    PlanStatus:"Active",
                    PolicyType:"Retail Health",
                    ExternalRefrenceNo:"- - - - - -"
                },{
                    PlanName:"1002881",
                    PlanDescription:"Medicare - Plan A",
                    PlanEffectiveDate:"13/1/2019",
                    PlanExpiryDate:"13/1/2020",
                    PlanStatus:"Active",
                    PolicyType:"Retail Health",
                    ExternalRefrenceNo:"- - - - - -"
                },{
                    PlanName:"1002882",
                    PlanDescription:"Medicare - Plan A",
                    PlanEffectiveDate:"13/1/2019",
                    PlanExpiryDate:"13/1/2020",
                    PlanStatus:"Active",
                    PolicyType:"Retail Health",
                    ExternalRefrenceNo:"- - - - - -"
                },{
                    PlanName:"1002883",
                    PlanDescription:"Medicare - Plan A",
                    PlanEffectiveDate:"13/1/2019",
                    PlanExpiryDate:"13/1/2020",
                    PlanStatus:"Active",
                    PolicyType:"Retail Health",
                    ExternalRefrenceNo:"- - - - - -"
                },{
                    PlanName:"1002884",
                    PlanDescription:"Medicare - Plan A",
                    PlanEffectiveDate:"13/1/2019",
                    PlanExpiryDate:"13/1/2020",
                    PlanStatus:"Active",
                    PolicyType:"Retail Health",
                    ExternalRefrenceNo:"- - - - - -"
                },{
                    PlanName:"1002885",
                    PlanDescription:"Medicare - Plan A",
                    PlanEffectiveDate:"13/1/2019",
                    PlanExpiryDate:"13/1/2020",
                    PlanStatus:"Active",
                    PolicyType:"Retail Health",
                    ExternalRefrenceNo:"- - - - - -"
                },
                
            ],

            required(){
                return v=>v && v.length>0 || "This Field is Required"
            },
            minLength(minLength){
                return v=>v && v.length>=minLength || `Must be atleast ${minLength} Characters`
            },
            maxLength(maxLength){
                return v=>v && v.length<=maxLength || `Must be less than ${maxLength} Characters`
            },
            spclChar(){
                return v=>/^[A-Za-z0-9 ]+$/.test(v) || `Special Characters Not Allowed`
            },
           

        }
    },

    methods: {

        nextPageData(pgNo){
            console.log(pgNo,"=======>pgNo")
            console.log(this.crntPgNo,"=======>crntPgNo")
            if(this.crntPgNo===pgNo){
                console.log("Current page data already showing")
                return
            }
           this.plcyfrpdtsrch(pgNo)
        },

        searchPlcy(){
            this.page=1
            this.plcyfrpdtsrch(this.page)
        },

        editBnkAcc(item){
            console.log("item", item)
        },
        chkr_seltd(itm,item){
            console.log("item clciked",itm);
            console.log("item clciked",item);
        },
        clonePlcyData(e){
            console.log('selected data for clone',e)

            
            // let self=this
                // self.showLoader('Loading', true);
                // let demodata={
                //     planName: "",
                //     planDescription: e.planDescription,
                //     planEffectiveDate: e.planEffectiveDate,
                //     planExpiryDate: e.planExpiryDate,
                //     planstatus: "Active",
                //     planType:"clone",
                //     policyType: e.policyType,
                //     policyNumber: e.policyNumber,
                //     policyId: e._id,
                //     externalRefNo: e.externalRefNo
                // }
               
                // let demodataArr=[]
                // demodataArr.push(demodata)
                //  console.log("from demodataArr--------->",demodataArr);
                // axios({
                //     method: "post",
                //     url:
                //         self.API_Service_admin()+"group-plan/clone-plan",
                //     headers: {
                //         "Content-Type": "application/json",
                //         "x-access-token":self.token,
                //     },
                //     data:demodataArr,
                // })
                // .then(function (response) {
                //     console.log("Create reporting>>>>>>", response);
                //     let btnName={
                //         text:'ClonePlcyFrPrdct',
                //         visible:true,
                //         plcy_Id:e._id
                //     }
                // self.$store.commit('SAVE_POLICYFORPRDCT_BTN_NAME', btnName)
                // self.$router.push('/product/createditpolicyforproduct');
                    
                // })
                // .catch(function (error) {
                //     console.log("Create reporting Error>>>>>>", error);
                //     self.showToast(error.response.data.msg,self.TOST().ERROR);
                // });
            // Clone group plan
            let btnName={
                text:'ClonePlcyFrPrdct',
                visible:true,
                plcy_Id:e._id
            }
            this.$store.commit('SAVE_POLICYFORPRDCT_BTN_NAME', btnName)
            this.$store.commit('GRP_PLCY_FOR_CLONE', e)
            this.$router.push('/product/createditpolicyforproduct');

        },

        createPlcyFrPdct(){
            
            // this.$store.commit('SAVE_PRODUCT_NAME', this.productCode)
            let btnName={
                text:'CreatePlcyFrPrdct',
                visible:true,
                plcy_Id:null
            }
            this.$store.commit('SAVE_POLICYFORPRDCT_BTN_NAME', btnName)
            this.$router.push('/product/createditpolicyforproduct');
        },

        getProductDetailsByID(id){
            console.log("ID",id)
                let self = this;   
                axios({
                    method: "get",
                    url:
                        self.API_Service_admin()+"product/get-product/"+id, 
                        headers: {
                            "Content-Type": "application/json", 
                            "x-access-token": self.token,
                        }
                })
                .then(function(response) {
                    console.log('Product Details Response------->>>',response.data.data);
                    let pdtDta={}
                    pdtDta=response.data.data
          
                    self.crtEdtPdt.pdtCd=pdtDta.hasOwnProperty('productCode')?self.chkplcydata(pdtDta.productCode):""
                    self.crtEdtPdt.pdtDsc=pdtDta.hasOwnProperty('productDescription')?self.chkplcydata(pdtDta.productDescription):""
                    // self.crtEdtPdt.plcyEfftvDte= pdtDta.hasOwnProperty('productEffectiveFrom')?self.chkplcydata(pdtDta.productEffectiveFrom):""
                    // self.crtEdtPdt.plcyExpryDte=pdtDta.hasOwnProperty('productEffectiveTo')?self.chkplcydata(pdtDta.productEffectiveTo):""
                })
                .catch(function(error) {
                    console.log(error);
                    // self.showToast(error.response.data.msg,self.TOST().ERROR);
                });

        },

        chkplcydata(data){
            console.log("Policy sara",data); 
            try{
                if (data ==="" || data === "undefined" || data === null) {
                    return ""
                } else {
                    return data;
                }

             }catch(err) {
                console.log(err)
            }
            
        },

        chkplndataarry(data){
            console.log("chkplndataarry",data); 
            try{
                if (data ==="" || data === "undefined" || data === null) {
                    return []
                } else {
                    return data;
                }

             }catch(err) {
                console.log(err)
            }
            
        },

        plcyfrpdtsrch(pgNo){
            this.$v.$touch();
            console.log("Form has errors",this.$v); 
             if(this.$v.crtEdtPdt.plcyNo.$invalid ){
                      
                    this.showToast('Please fill all required fields', this.TOST().WARNING);
                    return
             }


                let self = this;  
                    self.getPlcyfrPdtDta = [] 
                    axios({
                    method: "get",
                    
                    url:
                        // self.API_Service_admin()+"group-policy/search-group-policy/?productCode="+self.crtEdtPdt.pdtCd+"&productDescription="+self.crtEdtPdt.pdtDsc, 
                        self.API_Service_admin()+"group-policy/search-group-policy/?perPage="+self.itemsPerPage+"&pageNo="+pgNo+"&productCode="+self.crtEdtPdt.pdtCd+"&productDescription="+self.crtEdtPdt.pdtDsc+"&policyNumber="+self.crtEdtPdt.plcyNo+"&corporateGroupName="+self.crtEdtPdt.plcyCrpGrpNme+"&policyEffectiveDate="+self.crtEdtPdt.plcyEfftvDte+"&policyExpiryDate="+self.crtEdtPdt.plcyExpryDte,
                        headers: {
                                "Content-Type": "application/json", 
                                "x-access-token": self.token,
                            }
                        })
                .then(function(response) {
                    
                    console.log('get search-group-policy data response------->>>',response.data);
                    self.showToast(response.data.msg ,self.TOST().SUCCESS)    
                    // self.getPlcyfrPdtDta=response.data.data
                    let apiData=[]
                    apiData=response.data.data.data
                    self.pageCount=response.data.data.total_pages
                    self.totalCount=response.data.data.total
                    for (let i = 0; i < apiData.length; i++){
                        let status= self.validDateChk(apiData[i].policyExpiryDate )
                        console.log(status)
                        let productdata = {
                            corporateGroupName: apiData[i].hasOwnProperty('corporateGroupName')?self.chkplcydata(apiData[i].corporateGroupName):"",
                            createdAt: apiData[i].hasOwnProperty('createdAt')?self.chkplcydata(apiData[i].createdAt):"",
                            externalRefNo:apiData[i].hasOwnProperty('externalRefNo')?self.chkplcydata(apiData[i].externalRefNo):"",
                            planDescription:apiData[i].hasOwnProperty('planDescription')?self.chkplcydata(apiData[i].planDescription):"",
                            planEffectiveDate:apiData[i].hasOwnProperty('planEffectiveDate')?self.chkplcydata(apiData[i].planEffectiveDate):"",
                            planExpiryDate: apiData[i].hasOwnProperty('planExpiryDate')?self.chkplcydata(apiData[i].planExpiryDate):"",
                            policyEffectiveDate:apiData[i].hasOwnProperty('policyEffectiveDate')?self.chkplcydata(apiData[i].policyEffectiveDate):"",
                            policyExpiryDate:apiData[i].hasOwnProperty('policyExpiryDate')?self.chkplcydata(apiData[i].policyExpiryDate):"",
                            policyNumber:apiData[i].hasOwnProperty('policyNumber')?self.chkplcydata(apiData[i].policyNumber):"",
                            policyStatus: status==="Active"?"Active":"Inactive",
                            policyType: apiData[i].hasOwnProperty('policyType')?self.chkplcydata(apiData[i].policyType):"",
                            productCode: apiData[i].hasOwnProperty('productCode')?self.chkplcydata(apiData[i].productCode):"",
                            productDescription:  apiData[i].hasOwnProperty('productDescription')?self.chkplcydata(apiData[i].productDescription):"",
                            productId:apiData[i].hasOwnProperty('productId')?self.chkplcydata(apiData[i].productId):"",
                            updatedAt:apiData[i].hasOwnProperty('updatedAt')?self.chkplcydata(apiData[i].updatedAt):"",
                            __v: apiData[i].hasOwnProperty('__v')?self.chkplcydata(apiData[i].__v):"",
                            _id:apiData[i].hasOwnProperty('_id')?self.chkplcydata(apiData[i]._id):"",
                            planDetails:apiData[i].hasOwnProperty('planDetails')?self.chkplndataarry(apiData[i].planDetails):[],

                        }
                        self.getPlcyfrPdtDta.push(productdata);
                    }
                    self.plcyfrPdtSrch=true
                    self.rsltnotfound=false
                    self.crntPgNo=pgNo
                    console.log( self.crntPgNo,"=======> self.crntPgNo")

                    const start = pgNo * self.itemsPerPage - self.itemsPerPage,
                    end = start + self.itemsPerPage;
                    console.log(start,end,"start End")
                     if(self.totalCount===0){
                            self.fromvalue=0
                        }else{
                            self.fromvalue=start+1;
                        }
                        
                        if(self.totalCount <self.itemsPerPage){
                            self.toValu=self.totalCount
                        }else if(self.totalCount<end){
                            self.toValu=self.totalCount
                        }else{
                            self.toValu=end
                        }
                })
                .catch(function(error) {
                    console.log(error);
                    self.rsltnotfound=true
                    self.plcyfrPdtSrch=false
                    // self.showToast(error.response.data.msg,self.TOST().ERROR);

                });
        },

        validDateChk(todte){
                // console.log(todte,"incoming date")
            if(todte===null||todte===undefined|| todte===''){
                    return "Inactive"
            }else{

                 let d = Date.now();
                
                let Todate= moment(todte,'YYYY-MM-DD').toDate().getTime()
                let CurrentDate= d
                // console.log(Todate,CurrentDate,"Todate,CurrentDate")
                if(Todate===null||Todate===undefined|| Todate===''||Todate==="Invalid" ){
                    return "Inactive"
                }else if(Todate < CurrentDate ){
                    return "Inactive"
                }else if (Todate>CurrentDate){
                    return "Active"
                }


            }
        },


        
        sndPlcyDtafrEdt(d){
            console.log('Policy code data item--->',d)
            
             let btnName={
                text:'EditPlcyFrPrdct',
                visible:true,
                plcy_Id:d._id
            }
            this.$store.commit('SAVE_POLICYFORPRDCT_BTN_NAME', btnName)
            // return
            this.$router.push('/product/createditpolicyforproduct')
        },
        


    },

    watch: {
    
    },
    
    
}

</script>
<style >
.cust_celcy .v-text-field .v-input__slot,
.v-text-field--outline .v-input__slot {
    min-height: 42px !important;
    display: flex !important;
    align-items: center !important;
    /* border:1px red solid; */
}

.cust-rmvpd-pd .v-text-field.v-text-field--enclosed>.v-input__control>.v-input__slot {
    padding: 0px !important;;
}

.cstm-clr-ceplcy{
    color:#646666 !important;
    margin-bottom: 5px !important;
    font-size: 13px !important;
}

.cust_celcy .v-input .v-label {
    font-size: 13px !important;
}
.cust_celcy .v-input {
    font-size: 13px !important;
}
.cust_celcy .v-input input {
    font-size: 13px !important;
}

.title-clr-pr{
    color:#10242B;
    margin-bottom: 5px;
}

.lbl-bgclr{
    color:#152F38 !important;

}
.hdr-txt-clr-cepdt{
    color:#10242B !important;
    padding:0px!important;
    padding-left: 14px !important;
}

.hdr-bg-clr-cepdt{
    background-color: #f7f7f7!important;
}
/* .pdct-stUp-tbl table.v-table thead th{
    border-right:1px solid  #C1C8CC !important;
    background-color:#F3FBFB !important;
    color:#10242B !important;
    font-size:13px !important;
    font-family:Roboto !important;
    font-weight:700 !important;
    padding: 0px!important;
    padding-left: 9px !important;
} */
/* .pdct-stUp-tbl table.v-table thead th:last-child{
    border-right: unset !important;
    background-color:#F7F7F7 !important;
} */
/* .pdct-stUp-tbl table.v-table tbody td,
.pdct-stUp-tbl table.v-table thead tr{
    height: 40px !important;
    padding-left: 12px !important;
}
.pdct-stUp-tbl table.v-table thead tr:last-child{
    height: unset !important;
    
} */

/* .cust-tbl-cepdt .v-data-table > .v-data-table__wrapper > table > tbody > tr > td:last-child{
     background-color: #f7f7f7 !important;
} */

.pdct-stUp-tbl-brdr{
    border:1px solid  #C1C8CC;
}

.pdct-stUp-td-bdrgt {
    border-right:1px solid #C1C8CC;
 }

 .pdct-stUp-bnkAcc-pgn .v-pagination__navigation{
        box-shadow:unset !important;
        border-radius: unset !important;
        background-color:#FFFFFF !important;
        border:1px solid #DAE1E6 !important;
        height: 30px !important;
        width: 30px !important;
    }
    .pdct-stUp-bnkAcc-pgn .v-pagination__navigation--disabled{
        background-color:#DAE1E6 !important;
    }
    .pdct-stUp-bnkAcc-pgn .v-pagination__item{
        box-shadow:unset !important;
        min-width:1.8rem !important;
        height:1.8rem !important;
        border-radius: unset !important;
        border:1px solid #DAE1E6 !important
    }
/* 
    .pdct-stUp-tbl .v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
    border: 1px solid  #C1C8CC !important;
}

.pdct-stUp-tbl .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
    border: 1px solid #C1C8CC !important;
} */

.cust-tbl-ceplcy .v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
    font-size: 0.84rem !important;
}

  .cust-tbl-ceplcy .v-data-table--dense > .v-data-table__wrapper > table > thead > tr > th{
        border-right-width: thin !important;
        border-right-style: solid !important;
        border-right-color: rgba(0, 0, 0, 0.12) !important;
    } 

    .cust-tbl-ceplcy .v-data-table--dense > .v-data-table__wrapper > table > tbody > tr > td{
        border-right-width: thin !important;
        border-right-style: solid !important;
        border-right-color: rgba(0, 0, 0, 0.12) !important;
    }


</style>