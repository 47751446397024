<template>
  <div class="mx-8 my-6">
    <v-row class="mb-2 ml-0">
      <h3> Penal Rate TDS Master </h3>
      <v-spacer></v-spacer>
    </v-row>

    <v-layout class="mb-0">
      <v-row>

        <v-col>
          <h4 class="mr-3">
            Penal Rate TDS Master List
            <span class="orange--text">({{ filterRateTableData.length || 0 }})</span>
          </h4>
        </v-col>

        <v-spacer></v-spacer>

        <v-col cols="2">
          <v-select class="form-control" rule placeholder="Select" dense solo :items="vendors" v-model="selectedVendor"
            @change="fetchPenalTDSRates"></v-select>
        </v-col>

      </v-row>
    </v-layout>


    <v-data-table :headers="tdsRateHeaders" no-data-text="No Records" fixed-header :items="filterRateTableData"
      hide-default-footer class="vDataTableStyling">

      <template #item="{ item }">


        <tr>
          <td>{{ item.typeOfVendor }}</td>
          <td>
            <v-tooltip right>
              <template v-slot:activator="{ on }">
                <div v-on="on">{{ item.penalCode }}</div>
              </template>
              <span> {{ item.penalCode === 'ANL' ? 'Aadhar Not Linked' : 'Income Tax Not Filed' }}</span>
            </v-tooltip>
          </td>

          <td>
            <v-text-field v-if="item.isEditing" class="font-size-12 tds-input"
              :rules="[(v) => requiredField(v), v => validateMinMaxValue(v, 1, 100)]" solo dense
              v-mask="'##'"
              v-model="item.multiplicationFactor" flat outlined :hide-details="true">

            </v-text-field>
            <span v-else>
              {{ item.multiplicationFactor }}
            </span>
          </td>

          <td>
            <v-text-field v-if="item.isEditing" class="font-size-12 tds-input"
              :rules="[(v) => requiredNumberField(v), v => validateMinMaxValue(v, 0, 100)]" solo dense
              v-model="item.penalTDSRate" depressed flat outlined :hide-details="true" />
            <span v-else> {{ item.penalTDSRate }}</span>

          </td>

          <td>
            <div class="flex">
              <v-select v-if="item.isEditing" :disabled="!item.isEditing" solo class="status-select-box" outlined dense placeholder="Select"
                :items="rateStatusEnum" v-model="item.status" :hide-details="true" flat></v-select>
              <span v-else>{{ item.status ? 'Active' : 'Inactive' }}</span>
            </div>
          </td>

          <td>
            <span class="textTransformCapitalize" :class="approvedStatusTextColor(item.approveStatus)"> {{
              item.approveStatus }} </span>
          </td>

          <td>
            <div v-if="item.remark" class="flex justify-between">
              <span class="w-150"> {{ item.remark }} </span>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" class="more-text-icon">mdi-dots-horizontal</v-icon>
                </template>
                <span>{{ item.remark }}</span>
              </v-tooltip>
            </div>
          </td>

          <td>
            <div class="flex" v-if="item.isEditing">
              <button @click="saveTDSRate(item)">
                <v-icon medium class="approvedTextColor">mdi-check</v-icon>
              </button>
              <button @click="cancelEdit(item)">
                <v-icon medium class="approvedTextColor">mdi-close</v-icon>
              </button>
            </div>
            <div class="flex" v-else>
              <button v-if="ifPendingForApproval(item) && hasEditAccess" @click="editRow(item)">
                <v-icon medium>mdi-pencil-outline</v-icon>
              </button>
              <button @click="toggleAuditTrail(item)">
                <v-icon style="color:orange">mdi-chart-timeline-variant</v-icon>
              </button>
            </div>
          </td>
        </tr>

        <tr v-if="item.isAuditTrailOpen">
          <td :colspan="tdsRateHeaders.length">

            <div class="p-5">

              <div class="flex justify-between mb-2">
                <h4>Audit Trail</h4>
                <button @click="closeAllAuditTrails()">
                  <h4 class="mr-1">CLOSE</h4>
                </button>
              </div>
              <v-data-table :headers="auditTrailTableHeaders" no-data-text="No Records" fixed-header
                :items="auditTrailTableData" hide-default-footer class="vDataTableStyling mb-5 no-wrap bg-blue">

                <template v-slot:[`item.createdAt`]="{ item }">
                  <span> {{ formattedDate(item.createdAt) }} </span>
                </template>

                <template v-slot:[`item.makerName`]="{ item }">
                  <span v-if="ifCreatedOrModified(item)"> {{ item.userName }} </span>
                </template>

                <template v-slot:[`item.approverName`]="{ item }">
                  <span v-if="ifApprovedOrRejected(item)"> {{ item.userName }} </span>
                </template>

                <template v-slot:[`item.approvedAt`]="{ item }">
                  <span> {{ formattedDate(item.approvedAt) }} </span>
                </template>

                <template v-slot:[`item.modification.0.fieldName`]="{ item }">
                  <span> {{ getFormattedAuditTrailChangeFieldName(item) }}</span>
                </template>

                <template v-slot:[`item.modification.0.oldValue`]="{ item }">
                  <span class="textTransformCapitalize"> {{ getFormattedAuditTrailOldValue(item) }} </span>
                </template>

                <template v-slot:[`item.modification.0.newValue`]="{ item }">
                  <span class="textTransformCapitalize"> {{ getFormattedAuditTrailNewValue(item) }} </span>
                </template>

                <template v-slot:[`item.status`]="{ item }">
                  <span class="textTransformCapitalize" :class="actionTextColor(item.action)"> {{
                    item.action }} </span>
                </template>

                <template v-slot:[`item.remark`]="{ item }">
                  <div v-if="item.remarkText">

                    <div class="flex justify-between w-200">
                      <span class="w-150"> {{ item.remarkText }} </span>
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-icon v-on="on" class="more-text-icon">mdi-dots-horizontal</v-icon>
                        </template>
                        <span>{{ item.remarkText }}</span>
                      </v-tooltip>
                    </div>
                  </div>
                </template>

              </v-data-table>
            </div>
          </td>

        </tr>

      </template>

    </v-data-table>

  </div>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      auditTrailTableHeaders: [
        { text: 'NTID', align: 'left', value: 'userId', sortable: false, class: "bgHeader" },
        { text: 'Name of Maker', align: 'left', value: 'makerName', sortable: false },
        { text: 'Date And Time', align: 'left', value: 'createdAt', sortable: false },
        { text: 'Field Name', align: 'left', value: 'modification.0.fieldName', sortable: false },
        { text: 'Old Value', align: 'left', value: 'modification.0.oldValue', sortable: false },
        { text: 'New Value', align: 'left', value: 'modification.0.newValue', sortable: false },
        { text: 'Name of Checker', align: 'left', value: 'approverName', sortable: false },
        { text: 'Date And Time', align: 'left', value: 'approvedAt', sortable: false },
        { text: 'Status', align: 'left', value: 'status', sortable: false },
        { text: 'Remark', align: 'left', value: 'remark', sortable: false },
      ],
      auditTrailTableData: [],
      tdsRateHeaders: [
        { text: 'Type of Vendor', align: 'left', value: 'typeOfVendor', sortable: false },
        { text: 'Penal Code', align: 'left', value: 'penalCode', sortable: true },
        { text: 'Multiplication factor', align: 'left', value: 'multiplicationFactor', sortable: false, width: 100 },
        { text: 'TDS Rate', align: 'left', value: 'penalTDSRate', sortable: false },
        { text: 'Status', align: 'left', value: 'action', sortable: false },
        { text: 'Approval Status', align: 'left', value: 'approveStatus', sortable: false },
        { text: 'Remark', align: 'left', value: 'remark', sortable: false, width: 300 },
        { text: 'Action', align: 'left', value: 'actionEnabled', sortable: false },
      ],
      rateTableData: [],
      filterRateTableData: [],
      menuItems: [],
      vendors: ['Hospital', 'Service Provider', "Repairer", 'Vendor'],
      selectedVendor: 'Hospital',
      rateStatusEnum: [
        {
          text: "Active",
          value: true
        },
        {
          text: "Inactive",
          value: false,
        }
      ],
      editingRowOldData: {},
    }
  },
  computed: {
    hasEditAccess () {
      const routeName = this?.$router?.history?.current?.name;

      return this?.$store?.state?.screens_obj?.[routeName]?.isEdit;
    }
  },
  methods: {
    removeDecimal (c = '', item) {
      const charArr = c.split('');

      if (charArr.pop() === '.') {
        item.penalTDSRate = charArr.join('');
      }
    },
    dynamicMask(val) {
      return '##.##';
    },
    filterVendor() {
      const filteredData = this.rateTableData.filter(e => e.typeOfVendor === this.selectedVendor);
      this.filterRateTableData = this.polyFillProperties(filteredData);
    },
    approvedStatusTextColor(status = '') {
      return {
        'approved': 'approvedTextColor',
        'rejected': 'mandatorycolor',
      }[status.toLowerCase()] || '';
    },
    ifCreatedOrModified(item) {
      return ['Modified', 'Created'].includes(item?.action);
    },
    ifApprovedOrRejected(item) {
      return ['Approved', 'Rejected'].includes(item?.action);
    },
    actionTextColor(status = '') {
      return {
        'approved': 'approvedTextColor',
        'rejected': 'mandatorycolor',
      }[status.toLowerCase()] || '';
    },
    getFormattedAuditTrailOldValue (item) {
      let val = '';

      function ifStatusValue (v) {
        if ([true, 'true'].includes(v)) {
          return 'Active';
        } else if ([false, 'false'].includes(v)) {
          return 'Inactive';
        }
        return v;
      }

      item?.modification.forEach(f => {
        val += `${val ? ',' : ''} ${ifStatusValue(f.oldValue)}`;
      });

      return val;
    },
    getFormattedAuditTrailNewValue (item) {
      let val = '';

      function ifStatusValue (v) {
        if ([true, 'true'].includes(v)) {
          return 'Active';
        } else if ([false, 'false'].includes(v)) {
          return 'Inactive';
        }
        return v;
      }

      item?.modification.forEach(f => {
        val += `${val ? ',' : ''} ${ifStatusValue(f.newValue)}`;
      });

      return val;
    },
    getFormattedAuditTrailChangeFieldName(item) {
      let val = '';

      const fieldNameMapper = {
        'multiplicationFactor': 'Multiplication Factor',
        'penalTDSRate': 'Penal TDS Rate',
        'status': 'Status',
      };

      item?.modification.forEach(f => {
        val += `${val ? ',' : ''} ${fieldNameMapper[f.fieldName]}`;
      });

      return val;
    },
    formattedDate(date) {
      return moment(date).format('DD/MM/YYYY hh:mm a');
    },
    closeAllAuditTrails() {
      this.filterRateTableData.forEach(e => {
        e.isAuditTrailOpen = false;
      })
    },
    ifPendingForApproval(item) {
      return item.approveStatus !== 'Pending for approval';
    },
    getAuditTrailQueryParams(masterId) {
      const queryParamsObject = {
        perPage: 10,
        pageNo: 1,
        masterId,
        masterType: 'penalTDS',
      }

      const params = new URLSearchParams(queryParamsObject);

      return params.toString();
    },
    toggleAuditTrail(item) {
      const currentStatus = item.isAuditTrailOpen;

      this.showProgress();
      this.GETM(
        "GET_AUDIT_TRAIL",
        this.getAuditTrailQueryParams(item.uid),
        res => {
          this.auditTrailTableData = res?.data?.data?.data || [];
          this.hideProgress();
        },
        err => {
          console.log(err);
          this.auditTrailTableData = [];
          this.hideProgress();
        }
      );

      this.closeAllAuditTrails();
      item.isAuditTrailOpen = !currentStatus;
    },
    editRow(item) {
      this.editingRowOldData = { ...item };
      item.isEditing = true;
      this.closeAllAuditTrails();
    },
    cancelEdit(item) {
      item.penalTDSRate = this.editingRowOldData.penalTDSRate;
      item.multiplicationFactor = this.editingRowOldData.multiplicationFactor;
      item.status = this.editingRowOldData.status;
      item.isEditing = false;
      this.editingRowOldData = {};
    },
    detectChangeValues(item) {
      /**
       * Extracting old values.
       */
      const {
        multiplicationFactor: oldMultiplicationFactor,
        penalTDSRate: oldPenalTDSRate,
        status: oldStatus,
      } = this.editingRowOldData;

      /**
       * New Values from arguments.
       */
      const {
        multiplicationFactor: newMultiplicationFactor,
        penalTDSRate: newPenalTDSRate,
        status: newStatus,
      } = item;

      /**
       * Comparing old values & new values here,
       * not using === because its a user input values can be numeric or string.
       */
      if (
        oldStatus != newStatus ||
        oldMultiplicationFactor != newMultiplicationFactor ||
        oldPenalTDSRate != newPenalTDSRate
      ) {
        return true;
      }
    },
    validateMinMaxValue (value, min, max) {
      if (min <= Number(value) && Number(value) <= max && value.length < 6) {
        return true;
      }
      return false
    },
    validateFieldsValues (item) {
      if (
        this.validateMinMaxValue(item.multiplicationFactor, 1, 100) &&
        this.validateMinMaxValue(item.penalTDSRate, 0, 100)
      ) {
        return true;
      }
    },
    saveTDSRate(item) {
      const valuesChanged = this.detectChangeValues(item);
      const isValidated = this.validateFieldsValues(item);

      if (!valuesChanged || !isValidated) {

        /**
         * If user has not changed something (rate with pan, rate without pan, status)
         * do not call API, as it will be difficult to track on backend.
         */
        if (!valuesChanged) {
          this.cancelEdit(item);
        } else if (!isValidated) {
          this.showToast('Please enter correct values', this.TOST().ERROR);
        }
        return;
      }

      const data = {
        typeOfVendor: item.typeOfVendor,
        penalCode: item.penalCode,
        multiplicationFactor: item.multiplicationFactor,
        penalTDSRate: item.penalTDSRate,
        status: item.status,
        remark: '',
        uid: item.uid,
      };

      this.PUTM("UPDATE_PENAL_RATE_TDS_URL", item._id, data, (res) => {
        // item.isEditing = false;
        // item.approvalStatus = 'pending';
        this.showToast(
          res?.data?.msg || "Updated Successfully",
          this.TOST().SUCCESS
        );
        /**
         * Fetch new updated rates
         */
        this.fetchPenalTDSRates();
      }, (error) => {
        console.log(error);
        /**
         * Cancel edit and display old values.
         */
        this.cancelEdit(item);
        this.showToast(
          error?.response?.data?.message || "Something went wrong, Please try again.",
          this.TOST().ERROR
        );
      }, "CM");

      item.isEditing = false;
      item.approvalStatus = 'pending';
    },
    polyFillProperties(existingData) {
      const polyFillProps = {
        isAuditTrailOpen: false,
        isEditing: false,
      }
      return existingData.map(e => ({
        ...polyFillProps,
        ...e,
      }));
    },
    async fetchPenalTDSRates() {
      const afterAPI = (data = []) => {
        this.rateTableData = data;
        this.filterVendor();
        this.hideProgress();
      }
      this.showProgress();
      this.GETM("GET_PENAL_RATE_TDS_URL", `typeOfVendor=${this.selectedVendor}`, (res) => {
        afterAPI.bind(this)(res?.data?.data);
      }, (error) => {
        console.log(error);
        afterAPI();
      }, "CM");
    }
  },
  created() {
    this.$store.commit("SAVE_PAGE_HEADER", "Penal Rate TDS Master");
    this.fetchPenalTDSRates();
  },
}
</script>

<style lang="scss" scoped>
.p-5 {
  padding: 1rem;
  position: relative;
}

.my-5 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.font-size-12 {
  font-size: 12px;
}

.tds-input {
  margin-bottom: 2px;
}

.v-data-table__wrapper table thead th {
  color: black !important;
  font-size: 45px;
}

.btnToggle {
  text-transform: none;
  color: #fff;
  background-color: #1ebbd9;
}

.iconsize {
  font-size: 16px !important;
  font-weight: bold;
}

.tablebtn {
  background: #ffffff 0% 0% no-repeat padding-box !important;
  box-shadow: inset 0px 2px 4px #00000052 !important;
  border: 0.5px solid #c1c8cc;
  border-radius: 18px !important;
  height: 30px !important;
  text-transform: capitalize !important;
  width: 93%;
  justify-content: space-between;
  color: black !important;
  font-weight: 400 !important;
}

.tableheader {
  padding: 0;
}

.tableicons {
  font-size: 18px !important;
  color: #23b1a9 !important;
}

.approvedTextColor {
  color: #23b1a9;
}

.status-select-box {
  max-width: 100px;
  font-size: 11px;
  text-transform: capitalize;
  margin-bottom: 2px;
}

.vs__dropdown-menu {
  text-transform: capitalize;
}

.flex {
  display: flex;
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.no-wrap {
  white-space: nowrap;
}

.w-200 {
  width: 200px;
}

.w-150 {
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.more-text-icon {
  background-color: lightgray;
  color: white;
  border-radius: 2px;
  font-size: 18px;
  height: 18px;
}

.w-100 {
  width: 800px;
}
</style>