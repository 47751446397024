<template>
    <div style="background-color:#FFFFFF">
        <v-flex xs3 class="pl-5 pt-4" >
            <v-layout column class="cust_pln_TM" >
                <p class="font-weight-bold cstm-clr-pln-TM">
                   Provider Name
                </p>
                <p class="font-weight-bold text-capitalize pa-2" style="color:#1E9690;font-size:13px;border:1px solid #C1C8CC;background-color:#F7F7F7">{{roomCng.providerName}}</p>
            </v-layout>
        </v-flex>
            <v-form v-model="roomCnfgvalid" ref="roomCnfgForm">
                <v-card tile flat class="px-4 pt-2" >
                        <v-layout class="" align-center wrap style="" >

                        <!-- <v-flex xs3>
                            <v-layout column class="cust_pln_TM mx-2">
                                <p class="font-weight-bold cstm-clr-pln-TM">
                                    No Of Room Types
                                </p>
                                <v-text-field
                                    solo
                                    label="Enter..."
                                    v-model.trim="roomCng.noOfRoomTyp"
                                    class="rounded-0"
                                    :maxlength="max05"
                                    :disabled="fromview"
                                    :rules="[required(),numberRule]"
                                    @input="shwRoomTyp(roomCng.noOfRoomTyp)"
                                >
                                </v-text-field>
                            </v-layout>
                        </v-flex> -->

                        <v-flex xs3>
                            <v-layout column class="cust_pln_TM mx-2">
                                <p class="font-weight-bold cstm-clr-pln-TM">
                                    No Of Room Types
                                </p>
                                <v-select
                                :items="noOfRoomTypItms"
                                label="Select"
                                v-model="roomCng.noOfRoomTyp"
                                :rules="[required()]"
                                @change="shwRoomTyp(roomCng.noOfRoomTyp)"
                                solo
                                dense
                                class="rounded-0">
                            </v-select>
                            </v-layout>
                        </v-flex>
                        
                        <v-flex xs3   v-for="(room, index) in noOfRooms" :key="index">
                            <v-layout column class="cust_pln_TM mx-2">
                                <p class="font-weight-bold cstm-clr-pln-TM">
                                    Room Type {{index+1}}
                                </p>
                                <v-text-field
                                    solo
                                    label="Enter..."
                                    v-model.trim="room.roomType"
                                    class="rounded-0"
                                    :maxlength="max20"
                                    :disabled="fromview"
                                    :rules="[required()]"
                                >
                                </v-text-field>
                            </v-layout>
                        </v-flex>
                

                    </v-layout>

            </v-card>
           
            <v-layout style="background-color:#F7F7F7" justify-end class="pa-4">
                <v-btn tile v-if="!fromview" @click="save_rm_confg()" dark style="font-size:12px;background-color:#23B1A9" class="text-capitalize px-3 mx-2" small><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider> Save</v-btn>
                <v-btn tile   @click.native="jump('/search-tarrif')" dark style="font-size:12px;background-color:#152F38"  class="text-capitalize px-3 mx-2" small><v-icon dark small>arrow_back</v-icon> <v-divider vertical class="mx-2"></v-divider>Return</v-btn>
            </v-layout>
        </v-form>
        <v-dialog v-model="confirmDialogCancel" persistent max-width="450px">
                         <v-card tile color="#F7F7F7" >
                            <v-card tile>
                                <v-layout justify-space-between  class="" style="flex:1;background-ox-shadow: 0px 3px 5px #00000029;">
                             
                                    <v-card-title class="pa-0 pl-3 text-capitalize font-weight-regular" style="color:black"> 
                                       CONFIRMATION
                                    </v-card-title>
                                    <div style="border-left:1px solid #C1C8CC;" @click="close_cancelDialog()">
                                        <v-icon style="cursor: pointer;" class="pa-2" >close</v-icon>
                                    </div>
                                </v-layout>
                            </v-card>
                        <v-flex >
                           
                       
                        </v-flex>
                        <v-card-text >
                                Details saved on screen will be lost
                        </v-card-text>
                        <v-card-actions justify-start>
                            <v-btn dark  tile color="black" @click="close_cancelDialog()" class="text-capitalize px-3 mx-2" small><v-icon dark small>cancel</v-icon> <v-divider vertical class="mx-2"></v-divider>Cancel</v-btn>
                            <v-btn  dark  @click="okClick" tile color="#23B1A9" class="text-capitalize px-3 ml-2" small><v-icon dark small>check_circle</v-icon> <v-divider vertical class="mx-2"></v-divider> OK</v-btn>
                        </v-card-actions>
                    </v-card>
            </v-dialog>
    </div>
</template>
<script>
export default {
    created() {
        this.$store.commit("SAVE_PAGE_HEADER", "Room Configuration");
          this.fromview = this.$store.state.tariff_view;
        //selectTarrifData
        console.log(this.$store.state.selectTarrifData,"----data search");
        console.log(this.$store.state.createtarrifDetails,"this.$store.state.createtarrifDetails");
        this.getRoomConfig(this.$store.state.createtarrifDetails._id)
        // this.getRoomConfig("60267c8dee9bd4310e146425")
        this.roomCng.providerName=this.$store.state.createtarrifDetails.providerName
     
    },
    data(){
        return{
            fromview:false,
            roomCnfgvalid:false,
            fromview: false,
            roomCng:{
                noOfRoomTyp:'',
                providerName:''

            },
            confirmDialogCancel:false,
            tempNOfRooms:'',
            roomType:{},
            noOfRooms:[],
            max05:5,
            max10:10,
            max20:20,
            max30:30,
            max40:40,
            max50:50,
            noOfRoomTypItms:["1","2","3","4","5","6","7","8","9","10"],
            required(){
                return v=>v && v.length>0 || "This Field is Required"
            },
            minLength(minLength){
                return v=>v && v.length>=minLength || `Must be atleast ${minLength} Characters`
            },
            maxLength(maxLength){
                return v=>v && v.length<=maxLength || `Must be less than ${maxLength} Characters`
            },
            spclChar(){
                return v=>/^[A-Za-z0-9 ]+$/.test(v) || `Special Characters Not Allowed`
            },
            alphaNumeric(){
                return v=>/^[0-9 ]+$/.test(v) || `Only Numbers are allowed.`
            },
            numberRule: v  => {
                if (!v.trim()) return true;
                if (!isNaN(parseFloat(v)) && v >0 && v <=10) return true;
                return 'Values between 1 and 10 are accepted';
            },
            decimalRule(){
                return v=>/^\d+(\.\d{1,2})?$/.test(v) || `Numeric upto two decimals.`
            }

        }
    },
    methods:{
        okClick(){
            this.noOfRooms=[]
            this.tempNOfRooms=""
            this.$refs.roomCnfgForm.resetValidation()
            for(let i=0; i<parseInt(this.roomCng.noOfRoomTyp);i++){
                this.noOfRooms.push({
                    roomType:''
                })
            }
            
            this.confirmDialogCancel = false
        },
        close_cancelDialog(){
            this.roomCng.noOfRoomTyp=this.tempNOfRooms
            this.confirmDialogCancel = false
        },
        Numeric2(v){
            if (v.length !== 0) {
                console.log("testing",/^[0-9 ]+$/.test(v))
                if(!/^[0-9 ]+$/.test(v)){
                    return "Only Numbers are allowed."
                }else{
                console.log("else none")  
                }
            }
        },
        shwRoomTyp(indata){

            
            // this.noOfRooms=[]
            console.log("indata",parseInt(indata)) 
           
            if(parseInt(indata)>10){
                this.showToast('Max 10 Rooms Allowed', this.TOST().WARNING);
                return
            }
            
            if(this.tempNOfRooms!=""){
                if(parseInt(this.tempNOfRooms)<parseInt(indata)){
                    let addNewNoOfRooms=""
                    let tempnewRoomTyps=[]
                    let duplicatenoOfRooms=[]
                    this.noOfRooms.filter((room)=>{
                        if(room.hasOwnProperty("_id")){
                            duplicatenoOfRooms.push(room)
                        }
                    })
                    addNewNoOfRooms=parseInt(indata)-parseInt(this.tempNOfRooms)
                    for(let i=0; i<addNewNoOfRooms;i++){
                        tempnewRoomTyps.push({
                            roomType:''
                        })
                    }
                    this.noOfRooms=[]
                    this.noOfRooms=[...duplicatenoOfRooms,...tempnewRoomTyps]

                }else{
                    // this.delayNoOfRooms()
                    this.confirmDialogCancel=true
                    

                }
                console.log("finak this.noOfRooms",this.noOfRooms) 
            }else{

                
             if(!isNaN(indata)){
                 this.noOfRooms=[]
                let temprooms=parseInt(indata)
                for(let i=0; i<temprooms;i++){
                    this.noOfRooms.push({
                        roomType:''
                    })
                }
                console.log("this.noOfRooms",this.noOfRooms) 
                console.log("final rooms array", JSON.stringify(this.noOfRooms)) 
            }

            }
            

           
           

        },
        delayNoOfRooms(){
            let self=this
            
                setTimeout(function(){ 
                    console.log("timer started")
                    if(self.roomCng.noOfRoomTyp==""){
                        return
                    }
                    self.confirmDialogCancel=true
                }, 3000);

        },

        save_rm_confg(){
             console.log("on save final rooms array", JSON.stringify(this.noOfRooms)) 
            
             if (!this.$refs.roomCnfgForm.validate()){
                this.$refs.roomCnfgForm.validate()
                this.showToast("Please Fill Required Fields",this.TOST().WARNING);
                return
            } 
            // this.noOfRooms.map((e, index)=>{
      			
            // var stris = 'roomType'+(index+1)
            // console.log(stris)
            // this.roomType[stris] = e.roomTyp
            // })
  		    // console.log("nimap's obj", this.roomType);
              let demodataArr=[]
              this.noOfRooms.map((itm)=>{
                  demodataArr.push({
                      roomType:itm.roomType
                  })
              })
             let formData = {
                tariffId: this.$store.state.createtarrifDetails._id,
                roomConfiguration:{
                    numberOfRooms:this.roomCng.noOfRoomTyp,
                    // roomType:this.roomType
                   roomTypes: demodataArr
                    
                }
            }
            console.log("formData",formData);
            
            this.PUT(2,'tariff/update-tariff-room-configuration',formData ,
                ( res, error) => {
                 if(!error){
                    console.log(res.data);
                    this.showToast(res.data.msg ,this.TOST().SUCCESS);
                    // this.$emit("forenable");
                    this.$emit('forenable', {  emitType:"roomConfiguration", roomConfiguration: true }); 
                }else{
                    console.log("when status is not 200", error.response);
                    this.showToast(error.response.data.msg ,this.TOST().ERROR);
                }
    
            });
        },
         getRoomConfig(id){
            this.getTariffById(id,tempData=>{
                console.log(tempData,"tempdata")
                this.noOfRooms=[]
                if(tempData.hasOwnProperty('roomConfiguration') && tempData.roomConfiguration!=null){
                
                    this.roomCng.noOfRoomTyp=tempData.roomConfiguration.roomConfiguration.hasOwnProperty('numberOfRooms')? this.chkplndataStrnfy(tempData.roomConfiguration.roomConfiguration.numberOfRooms) : ''
                    this.tempNOfRooms=tempData.roomConfiguration.roomConfiguration.hasOwnProperty('numberOfRooms')? this.chkplndataStrnfy(tempData.roomConfiguration.roomConfiguration.numberOfRooms) : ''
                    this.noOfRooms=tempData.roomConfiguration.roomConfiguration.hasOwnProperty('roomTypes')? this.chkplndataarry(tempData.roomConfiguration.roomConfiguration.roomTypes) : []
                    // if(Object.keys(temproomType).length != 0){
                    //     for (let i in temproomType ){
                    //         console.log(i,"inside temproomType ")
                    //         this.noOfRooms.push({ roomTyp:temproomType[i]})
                    //     }
                    // }
                    // for(let i=0;i<temproomType.length;i++){
                    //         this.noOfRooms.push(
                    //             {
                    //                 roomType:temproomType[i].roomType
                    //             }
                    //         )
                    //     }
                    console.log(this.noOfRooms,"this.noOfRooms")
                }
                if(tempData.roomConfiguration!=null){
                     let tabsdata={
                        emitType:"all",
                    }
                    let tabskeys=[
                    "roomConfiguration",
                    "AmbulanceCharges",
                    "ICUCharges",
                    "InvestigationCharges",
                    "MedAndConsumablesCharges",
                    "MiscellaneousCharges",
                    "MultiplePackagesDiscount",
                    "OTCharges",
                    "PackageCharges",
                    "ProfessionalFeeCharges",
                    "roomAndNursingCharges"
                ]
                    // for(let i in tempData){
                        // let checkarr = []
                        tabskeys.forEach(element => {
                           
                            if(tempData[element] == null || Object.keys(tempData[element]).length == 0){
                                tabsdata[element.toString()] = false
                                // checkarr.push(element) 
                            }else{
                                tabsdata[element.toString()] = true
                                //   console.log('--------->',  tabsdata[element])
                            }
                        });
                       
                    this.$emit("forenable", tabsdata);
                    
                }else{
                    this.$emit("fordisable");
                }
            })
        },

        chkplndataStrnfy(data){
            console.log("chkplndata",data); 
            try{
                if (data ==="" || data === "undefined" || data === null) {
                    return ""
                } else {
                    return JSON.stringify(data);
                }

             }catch(err) {
                console.log(err)
            }
            
        },

        chkplndataarry(data){
            console.log("chkplndataarry",data); 
            try{
                if (data ==="" || data === "undefined" || data === null) {
                    return []
                } else {
                    return data;
                }

             }catch(err) {
                console.log(err)
            }
            
        },

        chkplndata(data){
            console.log("chkplndata",data); 
            try{
                if (data ==="" || data === "undefined" || data === null) {
                    return ""
                } else {
                    return data;
                }

             }catch(err) {
                console.log(err)
            }
            
        },

    }

   
}
</script>
<style>

.cust_pln_TM .v-text-field .v-input__slot,
.v-text-field--outline .v-input__slot {
    min-height: 41px !important;
    /* display: flex !important;
    align-items: center !important; */
    /* border:1px red solid; */
}

.cust_pln_TM .v-input .v-label {
    font-size: 13px !important;
}
.cust_pln_TM .v-input {
    font-size: 13px !important;
}
.cust_pln_TM .v-input input {
    font-size: 13px !important;
}

.cstm-clr-pln-TM{
    color:#646666 !important;
    margin-bottom: 5px !important;
    font-size: 13px !important;
}

</style>