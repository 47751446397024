<template>
<div class="row">
    <div class="col mxhgt centerit" style=" flex-direction:column; " >
        <h3 class="txtcnt" >Logging out now or try to login</h3>
      
            <div class="centerit" >   <router-link to="/login">Login</router-link>
          </div>
    </div>
</div>


</template>
<script>
export default {
    created(){
        this.$store.commit("SAVE_PAGE_HEADER", "");
        console.log("created called logout ");
        this.showLoader('Loading', true);
        this.$router.push('/login')

    },
    
    mounted: function(){
        
    }
}
</script>

<style> 
.mxhgt{ min-height:200px }
    .centerit{ display:flex; flex-direction:row; justify-content:center; align-items:center; }
    .txtcnt{
        text-align:center;
    }
</style>