<template>
    <v-container class="containerStyle">
        <v-form id="repiarer" ref="createRepairer" @submit.prevent="createRepairerSubmit" class="mt-3">
            <v-flex>
                <v-layout wrap class="pt-4 form-group-container">
                    <v-flex class="form-group">
          <label class="form-label">
              LOB <span><strong class="red--text">*</strong></span>
          </label>
            <v-select
                v-model="lob"
                label="Select"
                :items="lobOptions"
                :rules="[(v)=>requiredField(v)]"
                solo
                dense
                multiple
                class="form-control"
                clearable
                @change="populateUserId(lob)"
            ></v-select>
        </v-flex>
                    <v-flex class="form-group">
                        <label class="form-label">User ID <span class="mandatorycolor">*</span></label>
                            <v-select
                                v-model="userName"
                                class="form-control"
                                :items="allUsers"
                                item-text="userId" 
                                 item-value="ntidUserId" 
                                 placeholder="Enter..."
                                solo
                                dense
                                clearable
                                >
                            </v-select>
                        
                    </v-flex>
                    <v-flex class="form-group">
                        <label class="form-label">Repairer ID <span class="mandatorycolor">*</span></label>
                            <v-text-field
                                placeholder="Enter..."
                                solo
                                dense
                                v-model="repairerID"
                                :rules="rules.mandatoryrule"
                                v-on:keypress="onlyAlphaNumeric"
                                @keydown.space="(event) => event.preventDefault()"
                                class="form-control"
                            ></v-text-field>
                        
                    </v-flex>
                    <v-flex class="form-group">
                        <label class="form-label">Repairer Name <span class="mandatorycolor">*</span></label>
                            <v-text-field
                                placeholder="Enter..."
                                solo
                                dense
                                type="text"
                                v-on:keypress="isLetter($event)"
                                v-model="repairerName"
                                :rules="rules.repairerName"
                                :maxLength="138"
                                class="form-control"
                            ></v-text-field>
                        
                    </v-flex>
                    
                    
                    <v-flex class="form-group">
                        <label class="form-label">Address Line 1 <span class="mandatorycolor">*</span></label>
                                                    <v-text-field
                                                        placeholder="Enter..."
                                                        solo
                                                        dense
                                                        v-model="addressLine1"
                                                        class="form-control"
                                                        :maxLength="45"
                                                        :rules="[mandatoryrule(), (v) => addressValidation(v)]"
                                                    ></v-text-field>
                                                
                                            </v-flex>
                                            <v-flex class="form-group">
                        <label class="form-label">Address Line 2</label>
                            <v-text-field
                            placeholder="Enter..."
                            solo
                            dense
                            v-model="addressLine2"
                            class="form-control"
                            :rules="[(v) => addressValidation(v)]"
                            :maxLength="45"></v-text-field>
                        
                    </v-flex>
                    <v-flex class="form-group">
                        <label class="form-label">Address Line 3</label>
                                                    <v-text-field
                                                        placeholder="Enter..."
                                                        solo
                                                        dense
                                                        v-model="addressLine3"
                                                        class="form-control"
                                                        :rules="[(v) => addressValidation(v)]"  
                                                        :maxLength="35" ></v-text-field>
                                                
                                            </v-flex>
                                            <v-flex class="form-group">
                        <label class="form-label">Postal Code <span class="mandatorycolor">*</span></label>
                                                    <v-autocomplete
                                                        v-model="postalCode"
                                                        :search-input.sync="searchPincode"
                                                        class="form-control"
                                                        :items="pincodes"
                                                        item-text="locpincode"
                                                        item-value="locpincode"
                                                        @change="toGetCountryCity(postalCode)"
                                                        placeholder="Start typing to Search"
                                                        :maxLength="6"
                                                        :rules="rules.mandatoryrule"
                                                        v-on:keypress="isNumber"
                                                        solo
                                                        dense
                                                        hide-no-data
                                                        deletable-chips
                                                        clearable>
                                                    </v-autocomplete>
                                                
                                            </v-flex>
                                            <v-flex class="form-group">
                        <label class="form-label">Country <span :class="IsDisabledafterpincode ? '' : 'mandatorycolor'">*</span></label>
                                                    <v-autocomplete
                                                        v-model="country"
                                                        :search-input.sync="searchCountry"
                                                        class="form-control"
                                                        :items="countries"
                                                        item-text="countryName"
                                                        @change="getClaimantStates"
                                                        placeholder="Start typing to Search"
                                                        solo
                                                        hide-no-data
                                                        dense
                                                        :rules="rules.mandatoryrule"
                                                        deletable-chips
                                                        clearable
                                                        :disabled = 'IsDisabledafterpincode'
                                                        :background-color="IsDisabledafterpincode ? '#f5f5f5' : '#FFFFFF'"
                                                        :class="IsDisabledafterpincode ? 'form-control textDisable font-weight-bold' : 'form-control'">
                                                    </v-autocomplete>
                                                
                                            </v-flex>
                                            <v-flex class="form-group">
                        <label class="form-label">State/Province <span :class="IsDisabledafterpincode ? '' : 'mandatorycolor'">*</span></label>
                                                    <v-autocomplete
                                                        v-model="state"
                                                        :search-input.sync="searchState"
                                                        class="form-control"
                                                        :items="states"
                                                        item-text="stateCodeName"
                                                        item-value="stateCodeName"
                                                        :rules="rules.mandatoryrule"
                                                        @change="getClaimantCities"
                                                        placeholder="Start typing to Search"
                                                        solo
                                                        hide-no-data
                                                        dense
                                                        deletable-chips
                                                        clearable
                                                        :disabled = 'IsDisabledafterpincode'
                                                        :background-color="IsDisabledafterpincode ? '#f5f5f5' : '#FFFFFF'"
                                                        :class="IsDisabledafterpincode ? 'form-control textDisable font-weight-bold' : 'form-control'">
                                                    </v-autocomplete>
                                                
                                            </v-flex>
                                            <v-flex class="form-group">
                        <label class="form-label">District</label>
                                                    <v-autocomplete
                                                        v-model="district"
                                                        :search-input.sync="searchDistrict"
                                                        class="form-control"
                                                        :items="districts"
                                                        item-text="cityDistrictName"
                                                        item-value="cityDistrictName"
                                                        placeholder="Start typing to Search"
                                                        solo
                                                        dense
                                                        hide-no-data
                                                        deletable-chips
                                                        clearable
                                                        :disabled = 'IsDisabledafterpincode'
                                                        :background-color="IsDisabledafterpincode ? '#f5f5f5' : '#FFFFFF'"
                                                        :class="IsDisabledafterpincode ? 'form-control textDisable font-weight-bold' : 'form-control'">
                                                    </v-autocomplete>
                                                
                                            </v-flex>
                                            <v-flex class="form-group">
                        <label class="form-label">City <span :class="IsDisabledafterpincode ? '' : 'mandatorycolor'">*</span></label>
                                                    <v-autocomplete
                                                        v-model="city"
                                                        :search-input.sync="searchCity"
                                                        class="form-control"
                                                        :items="cities"
                                                        item-text="cityDistrictName"
                                                        item-value="cityDistrictName"
                                                        placeholder="Start typing to Search"
                                                        solo
                                                        :rules="rules.mandatoryrule"
                                                        @change="clearClaimantcity"
                                                        dense
                                                        hide-no-data
                                                        deletable-chips
                                                        clearable
                                                        :disabled='IsDisabledafterpincode'
                                                        :background-color="IsDisabledafterpincode ? '#f5f5f5' : '#FFFFFF'"
                                                        :class="IsDisabledafterpincode ? 'form-control textDisable font-weight-bold' : 'form-control'">
                                                    </v-autocomplete>
                                                
                                            </v-flex>
                                            <v-flex class="form-group">
                        <label class="form-label">Phone Number</label>
                                                    <v-text-field
                                                        placeholder="Enter..."
                                                        solo
                                                        @copy.prevent
                                                        @paste.prevent
                                                        dense
                                                        :maxLength=10
                                                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                                        v-model="phoneNumber"
                                                        :rules="[(v)=>matchRegex(v,'nums'),(v) => validateLength(v, { exact: 10 }, '', 'Field requires 10 digits.')]"
                                                        v-on:keypress="isNumber"
                                                        class="form-control">
                                                        <template v-slot:prepend-inner class="mx-0 my-0">
                                                            <v-icon class="iconstyle">mdi-phone</v-icon>
                                                        </template>
                                                    </v-text-field>
                                                
                                            </v-flex>
                                            <v-flex class="form-group">
                        <label class="form-label">Repairer Status <span class="mandatorycolor">*</span></label>
                            <v-select
                                label="Select"
                                solo
                                dense
                                :items="repairerStatus"
                                v-model="defaultRepairerStatus"
                                :rules="rules.mandatoryrule"
                                class="form-control"
                                disabled
                            ></v-select>
                    </v-flex>
                        
                            <v-flex class="form-group">
                        <label class="form-label">Portal Access <span class="mandatorycolor">*</span></label>
                            <v-select
                                label="Select"
                                solo
                                dense
                                :items="['Yes', 'No']"
                                v-model="computedPortalAccess"
                                :rules="[(v)=>requiredField(v)]"
                                class="form-control"
                            ></v-select>

                    </v-flex>
                    <v-flex class="form-group">
                        <label class="form-label">Overall Satisfaction Rating <span class="mandatorycolor">*</span></label>
                            <v-select
                                label="Select"
                                solo
                                dense
                                :items="overAllSatisfactionOptions"
                                v-model="overallSatisfaction"
                                :rules="rules.mandatoryrule"
                                class="form-control"
                            ></v-select>
                        
                    </v-flex>
                    
                    <!-- <v-flex xs12></v-flex> -->
                </v-layout>
            </v-flex>
            <v-expansion-panels v-model="panels" multiple>
                <!-- Service Agrement details form -->
                <v-expansion-panel>
                    <v-expansion-panel-header color="#1EBBD9">
                            <span class="page-title-small white--text">Service Agreement</span>
                            <template v-slot:actions>
                                <v-icon class="white--text">
                                    {{ panels.indexOf(0) !== -1 ? "mdi-minus-box" : "mdi-plus-box" }}
                                </v-icon>
                            </template>
                        </v-expansion-panel-header>
                        
                        <v-expansion-panel-content>
                            <v-layout wrap class="pt-4 form-group-container">
                                <v-flex class="form-group">
                                    <label class="form-label">
                                        Repairer Agreement Initiation  Date <span class="red--text"><strong> * </strong></span>
                                    </label>
                                    <v-menu
                                    class="agreementEffectiveDate"
                                    v-model="agreementEffectiveDate"
                                    :close-on-content-click="false"
                                    offset-y
                                    min-width="auto">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field
                                            placeholder="DD/MM/YYYY"
                                            dense
                                            solo
                                            :rules="rules.mandatoryrule"
                                            readonly
                                            v-model="computedEffectiveDate"
                                            v-on="on"
                                            class="form-control"
                                            prepend-inner-icon="mdi-calendar"
                                            >
                                        </v-text-field>
                                    </template>
                                    <v-date-picker              
                                        no-title          
                                        v-model="effectiveDate" 
                                        @input="agreementEffectiveDate = false"
                                    >
                                    </v-date-picker>
                                </v-menu>
                                </v-flex>
                                <v-flex class="form-group">
                                    <label class="form-label">
                                        Repairer Agreement Expiration Date
                                    </label>
                                    <v-menu
                                    class="agreementExpirationDate"
                                    v-model="agreementExpirationDate"
                                    :close-on-content-click="false"
                                    offset-y
                                    min-width="auto">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field
                                            placeholder="DD/MM/YYYY"
                                            dense
                                            solo
                                            
                                            readonly
                                            v-model="computedExpirationDate"
                                            v-on="on"
                                            class="form-control"
                                            prepend-inner-icon="mdi-calendar"
                                            >
                                        </v-text-field>
                                    </template>
                                    <v-date-picker              
                                        no-title  
                                        :min="effectiveDate"        
                                        v-model="expirationDate" 
                                        @input="agreementExpirationDate = false"
                                    >
                                    </v-date-picker>
                                </v-menu>
                                </v-flex>

                                <v-flex class="form-group">
                                    <label class="form-label">
                                        Pick-Up/Delivery Option 
                                    </label>
                                    <v-radio-group v-model="pickupOption" row class="mt-0 font-weight-bold form-control">
                                        <v-radio
                                            class="ml-5 mt-1 claim fnolRadioButtons"
                                            color="#23B1A9"
                                            v-for="(opt,i) in pickupOptionrArray"
                                            :id="'fnolRadioButton_'+i"
                                            :key="opt.name"
                                            :label="`${opt.name}`"
                                            :value="opt.selected"
                                        >
                                        </v-radio>
                                    </v-radio-group>
                                </v-flex>

                                <v-flex class="form-group">
                                    <label class="form-label">
                                        Trip Fee <span class="red--text"><strong> * </strong></span>
                                    </label>
                                    <v-radio-group v-model="tripFee" row class="mt-0 font-weight-bold form-control">
                                    <v-radio
                                        class="ml-5 mt-1 claim fnolRadioButtons"
                                        color="#23B1A9"
                                        v-for="(opt,i) in pickupOptionrArray"
                                        :id="'fnolRadioButton_'+i"
                                        :key="opt.name"
                                        :label="`${opt.name}`"
                                        :value="opt.selected"
                                    >
                                    </v-radio>
                                </v-radio-group>
                                </v-flex>
                                <v-flex class=" form-group" v-if="this.tripFee">
                                    <label class="form-label">
                                        Trip Fee : INR <span class="red--text"><strong> * </strong></span>
                                    </label>
                                    <v-text-field
                                    placeholder="Enter..."
                                    dense
                                    solo
                                    type="number"
                                    v-model="tripFeeInr"
                                    :rules="rules.mandatoryrule"
                                    class="form-control"
                                    prepend-inner-icon="mdi-currency-rupee">
                                </v-text-field>
                                </v-flex>

                                <v-flex class=" form-group">
                                    <label class="form-label">
                                        Labor Rate : INR <span class="red--text"><strong> * </strong></span>
                                    </label>
                                    <v-select
                                    label="Select"
                                    solo
                                    dense
                                    :items="labourRateOptions"
                                    v-model="labourRateInr"
                                    :rules="rules.mandatoryrule"
                                    class="form-control"
                                ></v-select>
                                </v-flex>

                                <v-flex class=" form-group">
                                    <label class="form-label">
                                        Job Rate 1 <span class="red--text"><strong> * </strong></span>
                                    </label>
                                    <v-text-field
                                    placeholder="Enter..."
                                    solo
                                    dense
                                    type="number"
                                    v-model="jobRate1"
                                    :rules="[(v)=>checkNegativeValue(v),(v)=>requiredField(v)]"
                                    class="form-control"
                                ></v-text-field>
                                </v-flex>

                                <v-flex class=" form-group">
                                    <label class="form-label">
                                        Rate 2
                                    </label>
                                    <v-text-field
                                    placeholder="Enter..."
                                    solo
                                    dense
                                    :rules="[(v)=>checkNegativeValue(v)]"
                                    type="number"
                                    v-model="rate2"
                                    class="form-control"
                                ></v-text-field>
                                </v-flex>

                                <v-flex class=" form-group">
                                    <label class="form-label">
                                        Rate 3
                                    </label>
                                    <v-text-field
                                    placeholder="Enter..."
                                    solo
                                    :rules="[(v)=>checkNegativeValue(v)]"
                                    dense
                                    type="number"
                                    v-model="rate3"
                                    class="form-control"
                                ></v-text-field>
                                </v-flex>

                                <v-flex class=" form-group">
                                    <label class="form-label">
                                        Last Audit Date
                                    </label>
                                    <v-menu
                                    class="fnolReportedDate"
                                    v-model="auditDateMenu"
                                    :close-on-content-click="false"
                                    offset-y
                                    min-width="auto">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field
                                            placeholder="DD/MM/YYYY"
                                            dense
                                            solo
                                            readonly
                                            v-model="computedAuditDate"
                                            v-on="on"
                                            class="form-control">
                                            <template v-slot:prepend-inner class="mx-0 my-0">
                                                <v-icon class="iconstyle" >mdi-calendar</v-icon>
                                            </template>
                                        </v-text-field>
                                    </template>
                                    <v-date-picker              
                                        no-title          
                                        v-model="auditDate"       
                                        @input="auditDateMenu = false">
                                    </v-date-picker>
                                </v-menu>
                                </v-flex>

                                <v-flex class=" form-group">
                                    <label class="form-label">
                                        Next Audit Due Date
                                    </label>
                                    <v-menu
                                    class="fnolReportedDate"
                                    v-model="auditDueDateMenu"
                                    :close-on-content-click="false"
                                    offset-y
                                    min-width="auto">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field
                                            placeholder="DD/MM/YYYY"
                                            dense
                                            solo
                                            readonly
                                            v-model="computedAuditDueDate"
                                            v-on="on"
                                            class="form-control">
                                            <template v-slot:prepend-inner class="mx-0 my-0">
                                                <v-icon class="iconstyle" >mdi-calendar</v-icon>
                                            </template>
                                        </v-text-field>
                                    </template>
                                    <v-date-picker              
                                        no-title          
                                        v-model="auditDueDate"       
                                        @input="auditDueDateMenu = false">
                                    </v-date-picker>
                                </v-menu>
                                </v-flex>
                            </v-layout>
                        </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                    <v-expansion-panel-header color="#1EBBD9">
                        <span class="page-title-small white--text">Repairer Details</span>
                        <template v-slot:actions>
                            <v-icon class="white--text">
                                {{ panels.indexOf(1) !== -1 ? "mdi-minus-box" : "mdi-plus-box" }}
                            </v-icon>
                        </template>
                    </v-expansion-panel-header>
                    
                    <v-expansion-panel-content>
                        <v-layout wrap class="pt-4 form-group-container">

                            <v-flex class=" form-group">
                                <label class="form-label">
                                    Product (Type) <span class="red--text"><strong> * </strong></span>
                                </label>
                                <v-select
                                    label="Multi Select"
                                    solo
                                    dense
                                    multiple
                                    v-model="productType"
                                    :items="productTypeOptions"
                                    :rules="rules.validatedropdown"
                                    class="form-control"
                                >
                                    <template v-slot:selection="{ item, index }">
                                        <span v-if="index === 0">{{ item }}</span>
                                        <span
                                        v-if="index === 1"
                                        class="grey--text text-caption"
                                        >
                                            (+{{ productType.length - 1 }} others)
                                        </span>
                                    </template>
                                </v-select>
                            </v-flex>

                            <v-flex class=" form-group">
                                <label class="form-label">
                                    Authorized Brand(s) <span class="red--text"><strong> * </strong></span>
                                </label>
                                <v-select
                                    label="Multi Select"
                                    solo
                                    dense
                                    multiple
                                    v-model="authorizedBrands"
                                    :items="authorizedBrandOptions"
                                    :rules="rules.validatedropdown"
                                    class="form-control"
                                >
                                    <template v-slot:prepend-item>
                                        <v-list-item
                                            ripple
                                            @click="toggle"
                                        >
                                            <v-list-item-action>
                                                <v-icon :color="authorizedBrands.length > 0 ? 'indigo darken-4' : ''">
                                                {{ icon }}
                                                </v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    All
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>
                                    <template v-slot:selection="{ item, index }">
                                        <span v-if="index === 0">{{ item }}</span>
                                        <span
                                            v-if="index === 1"
                                            class="grey--text text-caption"
                                        >
                                            (+{{ authorizedBrands.length - 1 }} others)
                                        </span>
                                    </template>
                                </v-select>
                            </v-flex>

                            <v-flex class=" form-group">
                                <label class="form-label">
                                    Repairer Warranty Period (in Days) <span class="red--text"><strong> * </strong></span>
                                </label>
                                <v-text-field
                                    placeholder="Enter..."
                                    solo
                                    dense
                                    type="number"
                                    v-model="repairerWarrantyPeriod"
                                    :rules="rules.mandatoryrule"
                                    class="form-control"
                                ></v-text-field>
                            </v-flex>

                            <!-- <v-flex class=" form-group">
                                <label class="form-label">
                                    Salutation <span class="red--text"><strong> * </strong></span>
                                </label>
                                <v-select
                                    label="Select"
                                    solo
                                    dense
                                    :items="salutationOptions"
                                    v-model="salutation"
                                    class="form-control"
                                ></v-select>
                            </v-flex> -->

                            <v-flex class=" form-group">
                                <label class="form-label">
                                    First Name <span class="red--text"><strong> * </strong></span>
                                </label>
                                <v-text-field
                                    placeholder="Enter..."
                                    solo
                                    dense
                                    type="text"
                                    v-on:keypress="isLetter($event)"
                                    v-model="firstName"
                                    :rules="rules.mandatoryrule"
                                    class="form-control"
                                ></v-text-field>
                            </v-flex>

                            <!-- <v-flex class=" form-group">
                                <label class="form-label">
                                    Middle Initial 
                                </label>
                                <v-text-field
                                    placeholder="Enter..."
                                    solo
                                    dense
                                    type="text"
                                    v-on:keypress="isLetter($event)"
                                    v-model="middleInitial"
                                    class="form-control"
                                ></v-text-field>
                            </v-flex> -->

                            <v-flex class=" form-group">
                                <label class="form-label">
                                    Last Name <span class="red--text"><strong> * </strong></span>
                                </label>
                                <v-text-field
                                    placeholder="Enter..."
                                    solo
                                    dense
                                    type="text"
                                    v-on:keypress="isLetter($event)"
                                    v-model="lastName"
                                    :rules="rules.mandatoryrule"
                                    class="form-control"
                                ></v-text-field>
                            </v-flex>

                            <v-flex class=" form-group">
                                <label class="form-label">
                                    Work Phone Number <span class="red--text"><strong> * </strong></span>
                                </label>
                                <v-text-field
                                    placeholder="Enter..."
                                    solo
                                    dense
                                    :rules="[(v)=>requiredField(v),(v)=>matchRegex(v,'nums'),(v) => validateLength(v, { exact: 10 }, '', 'Field requires 10 digits.')]"
                                    @copy.prevent
                                    @paste.prevent
                                    :maxLength="10"
                                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                    v-on:keypress="isNumber"
                                    v-model="workPhoneNumber"
                                    class="form-control"
                                    prepend-inner-icon="mdi-phone"
                                >
                                </v-text-field>
                            </v-flex>

                            <v-flex class=" form-group">
                                    <label class="form-label">
                                        Email ID <span class="red--text"><strong> * </strong></span>
                                    </label>
                                    <v-text-field
                                    placeholder="Enter..."
                                    solo
                                    dense
                                    v-model="emailID"
                                    :rules="rules.emailRules"
                                    class="form-control"
                                    prepend-inner-icon="mdi-email"
                                >
                                </v-text-field>
                                </v-flex>

                                <v-flex class=" form-group">
                                    <label class="form-label">
                                        Cell Phone Number <span class="red--text"><strong> * </strong></span>
                                    </label>
                                    <v-text-field
                                    placeholder="Enter..."
                                    solo
                                    dense
                                    :maxLength="10"
                                    @copy.prevent
                                    @paste.prevent
                                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                    v-model="cellPhoneNumber"
                                    :rules="[(v)=>requiredField(v),(v)=>matchRegex(v,'nums'),(v) => validateLength(v, { exact: 10 }, '', 'Field requires 10 digits.')]"
                                    v-on:keypress="isNumber"
                                    class="form-control"
                                    prepend-inner-icon="mdi-phone"
                                    >
                                    </v-text-field>
                                </v-flex>

                                <v-flex class=" form-group">
                                    <label class="form-label">
                                        Communication Email ID <span class="red--text"><strong> * </strong></span>
                                    </label>
                                    <v-text-field
                                    placeholder="Enter..."
                                    solo
                                    dense
                                    v-model="communicationEmailID"
                                    :rules="[emailValidation(communicationEmailID),(v)=>requiredField(v)]"
                                    class="form-control"
                                    prepend-inner-icon="mdi-email"
                                    >
                                    </v-text-field>
                                </v-flex>

                                <v-flex class=" form-group">
                                    <label class="form-label">
                                        Second Contact-Email Address
                                    </label>
                                    <v-text-field
                                    placeholder="Enter..."
                                    solo
                                    dense
                                    v-model="secondEmailID"
                                    :rules="[emailValidation(secondEmailID)]"
                                    class="form-control"
                                    prepend-inner-icon="mdi-email"
                                    >
                                    </v-text-field>
                                </v-flex>
                        </v-layout>
                    </v-expansion-panel-content>
                </v-expansion-panel>

                <!-- Tax Details -->
                <v-expansion-panel>
                    <v-expansion-panel-header color="#1EBBD9">
                        <span class="page-title-small white--text">Tax Details</span>
                        <template v-slot:actions>
                            <v-icon class="white--text">
                                {{ panels.indexOf(2) !== -1 ? "mdi-minus-box" : "mdi-plus-box" }}
                            </v-icon>
                        </template>
                    </v-expansion-panel-header>
                    
                    <v-expansion-panel-content>
                        <v-layout wrap class="pt-4 form-group-container">
                            <v-flex class=" form-group">
                                <label class="form-label">
                                </label>
                                <v-checkbox
                                    label="Is PAN not available"
                                    v-model="isPanNotAvailable"
                                    @change="checkIsPANAvailable()"
                                    class="px-2 checkboxstyle"
                                    dense
                                    solo
                                    style="font-weight: bold"
                                    color="#23B1A9"
                                ></v-checkbox>
                            </v-flex>
                            <v-flex class=" form-group">
                                <label class="form-label">
                                    PAN Number <span class="red--text"><strong> * </strong></span>
                                </label>
                                <v-text-field
                                placeholder="Enter..."
                                solo
                                dense
                                v-model="panNumber"
                                :value="computedPAN"
                                maxlength="10"
                                @change="isPanNotAvailable ? {} : checkTDSType()"
                                :rules="[v => requiredField(v), panValidation(panNumber), v => matchPanAndGst(v, gstNumber)]"
                                class="forminput"
                                ></v-text-field>
                            </v-flex>

                            <v-flex class=" form-group">
                                <label class="form-label">
                                    GST Number <span class="red--text"><strong> * </strong></span>
                                </label>
                                <v-text-field
                                placeholder="Enter..."
                                solo
                                dense
                                maxlength="15"
                                v-model="gstNumber"
                                :value="computedGST"
                                :rules="[mandatoryrule(), gstValidation(gstNumber), v => matchPanAndGst(panNumber, v)]"
                                class="form-control"
                                ></v-text-field>
                            </v-flex>

                            <v-flex class=" form-group">
                                <label class="form-label">
                                    GST Frequency
                                </label>
                                <v-select
                                label="Select"
                                solo
                                dense
                                :items="gstFrequencyOptions"
                                v-model="gstFrequency"
                                class="form-control"
                                ></v-select>
                            </v-flex>

                            <v-flex class=" form-group">
                                <label class="form-label">
                                    TDS Type <span class="red--text"><strong> * </strong></span>
                                </label>
                                <v-select
                                solo
                                dense
                                :items="tdsTypeOptions"
                                v-model="tdsType"
                                :rules="rules.mandatoryrule"
                                disabled
                                background-color="#f5f5f5"
                                class="form-control textDisable font-weight-bold"
                                ></v-select>
                            </v-flex>

                            <v-flex class=" form-group">
                                <v-checkbox
                                label="Differential TDS"
                                class="px-2 checkboxstyle"
                                dense
                                solo
                                v-model="differentialTds"
                                style="font-weight: bold"
                                color="#23B1A9"
                                ></v-checkbox>
                            </v-flex>

                            <v-flex class=" form-group">
                                <label class="form-label">
                                    MSME <span class="red--text"><strong> * </strong></span>
                                </label>
                                <v-select
                                    label="Select"
                                    solo
                                    dense
                                    :items="msmeOptions"
                                    v-model="msme"
                                    :rules="rules.mandatoryrule"
                                    class="form-control"
                                ></v-select>
                            </v-flex>

                            <v-flex class=" form-group">
                                <label class="form-label">
                                    Enterprise Classification <span v-if="msme=='Yes'" class="red--text">*</span>
                                </label>
                                <v-select
                                    label="Select"
                                    solo
                                    dense
                                    :rules="msme=='Yes'?[(v)=>requiredField(v)]:[]"
                                    :items="enterpriseClassificationData"
                                    v-model="enterpriseClassification"
                                    class="form-control"
                                ></v-select>
                            </v-flex>

                            <v-flex class=" form-group">
                                <label class="form-label">
                                    Enterprise Category <span v-if="msme=='Yes'" class="red--text">*</span>
                                </label>
                                <v-select
                                    label="Select"
                                    solo
                                    dense
                                    :rules="msme=='Yes'?[(v)=>requiredField(v)]:[]"
                                    :items="enterpriseCategoryData"
                                    v-model="enterpriseCategory"
                                    class="form-control"
                                ></v-select>
                            </v-flex>

                            <v-flex class=" form-group">
                                <label class="form-label">
                                    UAN <span v-if="msme=='Yes'" class="red--text">*</span>
                                </label>
                                <v-text-field
                                    placeholder="Enter..."
                                    solo
                                    dense
                                    :rules="msme=='Yes'?[(v)=>requiredField(v)]:[]"
                                    v-model="uan"
                                    class="form-control"
                                ></v-text-field>
                            </v-flex>
                        </v-layout>
                    </v-expansion-panel-content>
                </v-expansion-panel>

                <!-- Bank Details details form -->
                <v-expansion-panel>
                    <v-expansion-panel-header color="#1EBBD9">
                        <span class="page-title-small white--text">Bank Details</span>
                        <template v-slot:actions>
                            <v-icon class="white--text">
                                {{ panels.indexOf(2) !== -1 ? "mdi-minus-box" : "mdi-plus-box" }}
                            </v-icon>
                        </template>
                    </v-expansion-panel-header>
                    
                    <v-expansion-panel-content>
                        <v-layout wrap class="pt-4 form-group-container">
                            <v-flex class=" form-group">
                                <v-checkbox
                                    :label="`Bank Transfer`"
                                    class="form-control"
                                    dense
                                    solo
                                    style="font-weight: bold"
                                    @change="showPopUp"
                                    v-model="bankTransfer"
                                    color="#23B1A9"
                                ></v-checkbox>
                            </v-flex>

                            <v-flex class=" form-group">
                                <label class="form-label">
                                    IFSC Code <span class="red--text" v-if="bankTransfer"><strong> * </strong></span>
                                </label>
                                <v-autocomplete
                                    v-model="ifscCode"
                                    :search-input.sync="searchIFSCCODE"
                                    :class="manualOverwrite ? 'form-control' : 'form-control textDisable font-weight-bold'"
                                    item-text="ifscCode"
                                    item-value="ifscCode"
                                    clearable
                                    @change="getBankFields(ifscCode)"
                                    :items="bankIFSCDetailsItems"
                                    :rules="bankTransfer ? rules.mandatoryrule : []"
                                    hide-no-data
                                    :placeholder="manualOverwrite ? 'Start typing to Search' : ''"
                                    :maxlength="11"
                                    solo
                                    dense
                                    :disabled="!manualOverwrite"
                                    :background-color="manualOverwrite ? '#FFFFFF':'#f5f5f5'"
                                ></v-autocomplete>
                            </v-flex>

                            <v-flex class=" form-group">
                                <label class="form-label">
                                    Bank Name <span class="red--text" v-if="bankTransfer"><strong> * </strong></span>
                                </label>
                                <v-text-field
                                    :placeholder="disableBankName || !manualOverwrite ? '' : 'Enter...'"
                                    solo
                                    dense
                                    v-model="bankName"
                                    :disabled="disableBankName || !manualOverwrite"
                                    :background-color="disableBankName || !manualOverwrite ? '#f5f5f5':'#FFFFFF'"
                                    :rules="bankTransfer ? rules.mandatoryrule : []"
                                    :class="disableBankName || !manualOverwrite ? 'form-control textDisable font-weight-bold' : 'form-control'"
                                ></v-text-field>
                            </v-flex>

                            <v-flex class=" form-group">
                                <label class="form-label">
                                    Bank Account Number <span class="red--text" v-if="bankTransfer"><strong> * </strong></span>
                                </label>
                                <v-text-field
                                    :placeholder="manualOverwrite ? 'Enter...' : ''"
                                    solo
                                    dense
                                    v-model="bankAccountNumber"
                                    :type="bankAccountNumber ? 'password' : 'text'"
                                    :maxlength="35"
                                    
                                    @copy.prevent
                                    @paste.prevent
                                    :disabled="!manualOverwrite"
                                    :rules="bankTransfer ? [(v) => requiredField(v),(v)=>matchRegex(v,'alphanum')] : []"
                                    :class="manualOverwrite ? 'form-control' : 'form-control textDisable font-weight-bold'"
                                    :background-color="manualOverwrite ? '#FFFFFF':'#f5f5f5'"
                                ></v-text-field>
                            </v-flex>

                            <v-flex class=" form-group">
                                <label class="form-label">
                                    Re-enter Bank Account Number <span class="red--text" v-if="bankTransfer"><strong> * </strong></span>
                                </label>
                                <v-text-field
                                    :placeholder="manualOverwrite ? 'Enter...' : ''"
                                    solo
                                    dense
                                    
                                    @copy.prevent
                                    @paste.prevent
                                    v-model="confirmBankAccountNumber"
                                    :rules="bankTransfer ? [backAccountNumberValidation(confirmBankAccountNumber)] : []"
                                    :disabled="!manualOverwrite"
                                    :class="manualOverwrite ? 'form-control' : 'form-control textDisable font-weight-bold'"
                                    :background-color="manualOverwrite ? '#FFFFFF':'#f5f5f5'"
                                    :maxLength="35"
                                ></v-text-field>
                            </v-flex>

                            <v-flex class=" form-group">
                                <label class="form-label">
                                    Account Type <span class="red--text" v-if="bankTransfer"><strong> * </strong></span>
                                </label>
                                <v-select
                                label="Select"
                                solo
                                dense
                                :items="acountTypeOptions"
                                v-model="accountType"
                                :rules="bankTransfer ? rules.mandatoryrule : []"
                                class="form-control"
                                ></v-select>
                            </v-flex>

                            <v-flex class=" form-group">
                                <label class="form-label">
                                    Bank Address 1 
                                    <span class="red--text" v-if="bankTransfer"><strong> * </strong></span>
                                </label>
                                <v-text-field
                                :placeholder="disableBankAdd1 || !manualOverwrite ? '' : 'Enter...'"
                                solo
                                dense
                                v-model="bankAddress1"
                                :disabled="!editBankAddress1 "
                                :rules="bankTransfer ? [(v)=>requiredField(v),(v) => addressValidation(v)] : [(v) => addressValidation(v)]"
                                :background-color="disableBankAdd1 || !manualOverwrite || editBankAddress1 ? '#f5f5f5':'#FFFFFF'"
                                :class="disableBankAdd1 || !manualOverwrite ? 'form-control textDisable font-weight-bold' : 'form-control'"
                                ></v-text-field>
                            </v-flex>
                            

                            <v-flex class=" form-group">
                                <label class="form-label">
                                    Bank Address 2 
                                </label>
                                <v-text-field
                                :placeholder="disableBankAdd2 || !manualOverwrite ? '' : 'Enter...'"
                                solo
                                dense
                                v-model="bankAddress2"
                                :disabled="!editBankAddress2 "
                                :background-color="disableBankAdd2 || !manualOverwrite || editBankAddress1 ? '#f5f5f5':'#FFFFFF'"
                                :rules="[(v) => matchRegex(v, 'alphanum')]"
                                :class="disableBankAdd2 || !manualOverwrite ? 'form-control textDisable font-weight-bold' : 'form-control'"
                                ></v-text-field>
                            </v-flex>

                            <v-flex class=" form-group">
                                <label class="form-label">
                                    Bank Address 3
                                </label>
                                <v-text-field
                                :placeholder="disableBankAdd3 || !manualOverwrite ? '' : 'Enter...'"
                                :rules="[(v) => matchRegex(v, 'alphanum')] "
                                solo
                                dense
                                v-model="bankAddress3"
                                :disabled="!editBankAddress3 "
                                :class="disableBankAdd3 || !manualOverwrite ? 'form-control textDisable font-weight-bold' : 'form-control'"
                                :background-color="disableBankAdd3 || !manualOverwrite ? '#f5f5f5' : '#FFFFFF'"
                                ></v-text-field>
                            </v-flex>
                            
                            <v-flex class=" form-group">
                                <label class="form-label">
                                    Branch Name <span class="red--text" v-if="bankTransfer"><strong> * </strong></span>
                                </label>
                                <v-text-field
                                :placeholder="disableBankBranch || !manualOverwrite ? '' : 'Enter...'"
                                solo
                                dense
                                v-model="branchName"
                                :disabled="disableBankBranch || !manualOverwrite"
                                :background-color="disableBankBranch || !manualOverwrite ? '#f5f5f5':'#FFFFFF'"
                                :rules="bankTransfer ? rules.mandatoryrule : []"
                                :class="disableBankBranch || !manualOverwrite ? 'form-control textDisable font-weight-bold' : 'form-control'"
                                ></v-text-field>
                            </v-flex>

                            <v-flex class=" form-group">
                                <v-layout column class="ml-3">
                                    <p class="mb-2 claimlabel">Cancelled Cheque <span class="mandatorycolor" v-if="bankTransfer">*</span></p>
                                    <v-btn class="addressbtn" color="#FFFFFF" :rules="rules.mandatoryrule" @click="openCancelledChequeDialog">
                                        <span>Browse</span>
                                        <v-icon class="right-icon" style="color:#E46A25">mdi-arrow-up-circle</v-icon>
                                    </v-btn>
                                    <span style="font-size: 12px; color: #ff5252" class="mt-1 ml-3">{{ cancelledChequeRequired }}</span>
                                </v-layout>
                            </v-flex>

                            <v-flex class=" form-group">
                                <v-checkbox
                                    :label="`Overwrite Manually`"
                                    class="px-2 checkboxstyle"
                                    dense
                                    solo
                                    style="font-weight: bold"
                                    v-model="manualOverwrite"
                                    @change="changeSubmissionType()"
                                    color="#23B1A9"
                                    :disabled="cancelledChequeFileData.length < 1 || manualOverwrite"
                                ></v-checkbox>
                            </v-flex>
                        </v-layout>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
            <v-layout fluid class="flexbg mt-3 mb-3">
                <v-layout justify-end align-center>
                    <v-layout justify-start>
                        <v-btn color="#E46A25" dark class="ml-3 buttonscolor" @click="openAttachments">
                            <v-icon class="iconsize mr-2">mdi-plus</v-icon>
                            <div class="vertical_line"></div>
                            Attachments
                        </v-btn>
                        <!-- Phase-2 changes starts-->
                        <v-btn color="#152F38" dark class="ml-3 buttonscolor" @click="openRemarks">
                            <v-icon class="iconsize mr-2">mdi-message</v-icon>
                            <div class="vertical_line"></div>
                            Remarks
                        </v-btn>
                        <!-- Phase-2 changes ends-->
                    </v-layout>
                    <v-btn color="#23B1A9" dark class="ml-3 buttonscolor" type="submit">
                        <v-icon class="iconsize mr-2">mdi-check-circle</v-icon>
                        <div class="vertical_line"></div>
                        Save
                    </v-btn>
                    <v-btn color="#E46A25" dark class="ml-3 buttonscolor" @click="clearRepairerForm">
                        <v-icon class="iconsize mr-2">mdi-close-circle</v-icon>
                        <div class="vertical_line"></div>
                        Clear
                    </v-btn>
                    <v-btn color="#152F38" dark class="ml-3 mr-3 buttonscolor" @click="goBack">
                        <v-icon class="iconsize mr-2">mdi-arrow-left-circle</v-icon>
                        <div class="vertical_line"></div>
                        Back
                    </v-btn>
                </v-layout>
            </v-layout>
            <!-- Address dialog  -->
            <v-row justify="center">
                <v-dialog v-model="addressDialog" persistent max-width="75%">
                    <v-card>
                        <v-card-title class="pl-0 px-0 py-0 addressPopUpHeaderStyle">
                            <v-row>
                                <span class="descrptionSpan mb-5 ml-8 mr-8">Address</span>
                                <v-icon @click="addressDialog = false" class="ml-10 mt-0">mdi-window-close</v-icon>
                            </v-row>
                            <v-card-text class="addressPopUpStyle">
                                <v-form id="adreessId" ref="addressref" @submit.prevent="proceed">
                                    <v-flex class="backgroundWhite">
                                        <v-layout class="mt-2">
                                            
                                            
                                            
                                            
                                        </v-layout>
                                        <v-layout class="mt-2">
                                           
                                        </v-layout>
                                        <v-layout class="mt-2">
                                            
                                            <!-- <v-flex xs3>
                                                <v-layout column class="ml-3">
                                                    <p class="mb-2 claimlabel">Preferred Communication Method</p>
                                                    <v-select
                                                        label="Select"
                                                        :items="preferredComAddresses"
                                                        solo
                                                        dense
                                                        v-model="preferredCommunicationMethod"
                                                        class="form-control"
                                                    ></v-select>
                                                </v-layout>
                                            </v-flex> -->
                                            <!-- <v-flex xs3>
                                                <v-layout column class="ml-3">
                                                    <p class="mb-2 claimlabel">Fax Number</p>
                                                    <v-text-field
                                                        placeholder="Enter..."
                                                        solo
                                                        dense
                                                        v-model="faxNumber"
                                                        class="form-control"
                                                    ></v-text-field>
                                                </v-layout>
                                            </v-flex> -->
                                            <!-- <v-flex xs3>
                                                <v-layout column class="ml-3 mr-3">
                                                    <p class="mb-2 claimlabel">Second Contact-Fax Number</p>
                                                    <v-text-field
                                                        placeholder="Enter..."
                                                        solo
                                                        dense
                                                        v-model="secondFaxNumber"
                                                        class="form-control"
                                                    ></v-text-field>
                                                </v-layout>
                                            </v-flex> -->
                                        </v-layout>
                                    </v-flex>
                                    <!-- Cancel & Proceed buttons -->
                                    <v-flex justify="center" class="mt-5">
                                        <v-row>
                                            <v-col align="center">
                                                <v-btn color="#23B1A9" dark class="buttonscolor mr-5" type="submit">
                                                    <v-icon class="mr-2 iconsize">mdi-arrow-right-circle</v-icon>
                                                    <div class="vertical_line"></div>Proceed
                                                </v-btn>
                                                <v-btn
                                                    color="#E46A25"
                                                    dark
                                                    class="buttonscolor mr-5"
                                                    @click="addressDialog = false"
                                                >
                                                    <v-icon class="mr-2 iconsize" >mdi-close-circle</v-icon>
                                                    <div class="vertical_line"></div>Close
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-flex>
                                </v-form>
                            </v-card-text>
                        </v-card-title>
                    </v-card>
                </v-dialog>
            </v-row>
            <!-- Cancelled cheque dialog -->
            <v-row justify="center">
                <v-dialog v-model="cancelChequeDialog" persistent max-width="460px">
                    <v-card>
                        <v-card-title class="pl-0 px-0 py-0 dailogboxAllvcardTableBackground">
                            <v-row>
                                <span class="descrptionSpanAttach mb-5 ml-10 mr-0">Cancelled Cheque</span>
                                <v-icon @click="cancelChequeDialog = false" class="ml-1 mt-0">mdi-window-close</v-icon>
                            </v-row>
                            <v-card-text class="vcardTextTableBackground">
                                <v-flex class="backgroundWhite mx-3">
                                    <v-flex class="mb-3">
                                        <v-btn class="browsebtn mt-5 ml-4 mr-4" @click="uploadBtnClicked()">Browse
                                        <div style="margin-left:80%"></div><v-icon class="tableicons" color="#E46A25 !important">mdi-arrow-up-circle</v-icon>
                                        </v-btn>
                                        <v-file-input
                                        multiple
                                        v-model="cancelledCheque"
                                        @change="filesSelected"
                                        accept=".doc, .docx, .csv, image/png,image/jpeg,application/msword,application/pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/csv"
                                        id="fileupload"
                                        style="display: none">
                                        </v-file-input>
                                    </v-flex>        
                                    <template v-if="this.cancelledChequeFileData.length !== 0">
                                        <v-data-table
                                        :headers="attachmentheaders"
                                        :items="cancelledChequeFileData"
                                        hide-default-footer
                                        hide-default-header
                                        class="attachheader"
                                        >
                                        <template v-slot:[`item.icon`] = "{ item }">
                                            <span v-if="checkFileType(item.documentType) === 'pdf'"><img src="../../../assets/pdf1.png" alt="pdf" height="25px"></span>
                                            <span v-if="checkFileType(item.documentType) === 'excel'"><img src="../../../assets/excel.png" alt="excel" height="25px"></span>
                                            <span v-if="checkFileType(item.documentType) === 'word'"><img src="../../../assets/word.png" alt="doc" height="25px"></span>
                                            <span v-if="checkFileType(item.documentType) === 'image'"><img src="../../../assets/imageicon.png" alt="png" height="25px"></span>
                                        </template>
                                        <template v-slot:[`item.name`]="{ item }">
                                            <span class="mr-0">{{ limitTextLength(item.file) }}</span>
                                        </template>
                                        <template v-slot:[`item.view`] = "{ item }">
                                            <v-btn color="#FFFFFF" class="tablebtn" @click="viewDocument(item.fileBase)">View
                                            <div class="mr-3"></div>
                                            <v-icon class="tableicons" color="#E46A25 !important">mdi-eye</v-icon>
                                            </v-btn>
                                        </template>
                                        <template v-slot:[`item.delete`]="{ index }">
                                            <span><v-icon class="closeicon" @click="deleteCancelledCheque(index)">mdi-close-circle</v-icon></span>
                                        </template>
                                        </v-data-table>
                                    </template>
                                </v-flex>
                                <v-flex justify="center" class="mt-5">
                                    <v-row class="btnDiv">
                                        <v-col align="center">
                                            <v-btn
                                                color="#23B1A9"
                                                dark
                                                class="buttonscolor mr-5"
                                                @click="cancelChequeDialog = false">
                                                <v-icon class="mr-2 iconsize" >mdi-close-circle</v-icon>
                                                <div class="vertical_line"></div>OK
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-flex>
                            </v-card-text>
                        </v-card-title>
                    </v-card>
                </v-dialog>
            </v-row>
            <!-- Attachments Dialog   -->
            <v-row justify="center">
                <v-dialog v-model="attachmentsdialog" persistent max-width="460px">
                    <v-card>
                        <v-card-title class="pl-0 px-0 py-0 dailogboxAllvcardTableBackground">
                            <v-row>
                                <span class="descrptionSpanAttach mb-5 ml-10 mr-0">Attachments <span class="attachtxt">( Multiple documents can be uploaded )</span></span>
                                <v-icon @click="attachmentsdialog = false" class="ml-1 mt-0">mdi-window-close</v-icon>
                            </v-row>
                            <v-card-text class="vcardTextTableBackground">
                                <v-flex class="backgroundWhite mx-3">
                                    <v-flex class="mb-3">
                                        <v-btn class="browsebtn mt-5 ml-4 mr-4" @click="openBrowse()">Browse
                                            <div style="margin-left:80%"></div><v-icon class="tableicons" color="#E46A25 !important">mdi-arrow-up-circle</v-icon>
                                        </v-btn>
                                        <v-file-input
                                            multiple
                                            v-model="browse_attachments"
                                            @change="fileUploaded"
                                            accept=".doc, .docx, .csv, image/png,image/jpeg,application/msword,application/pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/csv"
                                            id="fileupload1"
                                            style="display: none"
                                        ></v-file-input>
                                    </v-flex>        
                                    <template v-if="this.attachments.length !== 0">
                                        <v-data-table
                                        :headers="attachmentheaders"
                                        :items="attachments"
                                        hide-default-footer
                                        hide-default-header
                                        class="attachheader"
                                        >
                                        <template v-slot:[`item.icon`] = "{ item }">
                                            <span v-if="checkFileType(item.documentType) === 'pdf'"><img src="../../../assets/pdf1.png" alt="pdf" height="25px"></span>
                                            <span v-if="checkFileType(item.documentType) === 'excel'"><img src="../../../assets/excel.png" alt="excel" height="25px"></span>
                                            <span v-if="checkFileType(item.documentType) === 'word'"><img src="../../../assets/word.png" alt="doc" height="25px"></span>
                                            <span v-if="checkFileType(item.documentType) === 'image'"><img src="../../../assets/imageicon.png" alt="png" height="25px"></span>
                                        </template>
                                        <template v-slot:[`item.name`]="{ item }">
                                            <span class="mr-0">{{ limitTextLength(item.file) }}</span>
                                        </template>
                                        <template v-slot:[`item.view`] = "{ item }">
                                            <v-btn color="#FFFFFF" class="tablebtn" @click="viewDocument(item.fileBase)">View
                                            <div class="mr-3"></div>
                                            <v-icon class="tableicons" color="#E46A25 !important">mdi-eye</v-icon>
                                            </v-btn>
                                        </template>
                                        <template v-slot:[`item.delete`] = "{ index }">
                                            <span><v-icon class="closeicon" @click="deleteAttachments(index)">mdi-close-circle</v-icon></span>
                                        </template>
                                        </v-data-table>
                                    </template>
                                </v-flex>
                                <v-flex justify="center" class="mt-5">
                                    <v-row class="btnDiv">
                                        <v-col align="center">
                                            <v-btn
                                                color="#23B1A9"
                                                dark
                                                class="buttonscolor mr-5"
                                                @click="attachmentsdialog = false">
                                                <v-icon class="mr-2 iconsize" >mdi-close-circle</v-icon>
                                                <div class="vertical_line"></div>OK
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-flex>
                            </v-card-text>
                        </v-card-title>
                    </v-card>
                </v-dialog>
            </v-row>
            <!-- Phase-2 changes starts -->
            <!-- Remarks dialog  -->
            <v-row justify="center">
                <v-dialog v-model="remarksdialog" persistent max-width="60%"  style="border-radius:0">
                    <v-card>
                        <v-card-title class="pl-0 px-0 py-0 dailogboxAllvcardTableBackground">
                            <v-row>
                                <span class="descrptionSpan mb-5 ml-8 mr-5">Remarks</span>
                                <v-icon @click="remarksdialog = false" class="ml-5 mt-0">mdi-window-close</v-icon>
                            </v-row>
                            <v-card-text class="vcardTextTableBackground">
                                    <v-form ref="remarkform" >
                                        <v-flex class="backgroundWhite">
                                        <v-layout class="mt-2" >
                                            <v-flex xs4>
                                                <v-layout column class="ml-3">
                                                    <p class="mb-2 claimlabel">Remarks Type <span class="mandatorycolor">*</span></p>
                                                    <v-select
                                                        placeholder="Select"
                                                        solo
                                                        dense
                                                        clearable
                                                        :rules="rules.mandatoryrule"
                                                        :items="remarksTypeArray"
                                                        v-model="remarkType"
                                                        class="form-control">
                                                    </v-select>
                                                </v-layout>
                                            </v-flex>
                                        </v-layout>     
                                                <v-layout >
                                                <v-layout column class="ml-3">
                                                    <p class="mb-2 claimlabel">Remarks <span class="mandatorycolor">*</span></p>
                                                        <v-text-field
                                                            class="form-control mr-3"
                                                            color="black"
                                                            solo
                                                            dense
                                                            :rules="rules.mandatoryrule"
                                                            v-model="remarkText"
                                                            :maxlength="4000"
                                                            label="Enter..">
                                                        </v-text-field>
                                                    </v-layout>
                                                </v-layout> 
                                                
                                                <!-- <v-simple-table fixed-header class="vDataTableStyling  mx-3 mt-2 doctable mb-3">
                                                    <template>
                                                    <thead>
                                                        <tr>
                                                            <th class="text-center" width="7%">User ID</th>
                                                            <th class="text-center" width="9%">Date</th>
                                                            <th class="text-center" width="9%">Remark Type</th>
                                                            <th class="text-center" width="14%">Remarks</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr >  -->
                                                            <!-- v-for="(item, PRindex) in POremarksDetails" :key="'PR'+ PRindex" -->
                                                            <!-- <td>{{ item.ntid ==  "" ? "-------" : item.ntid}}</td>
                                                            <td>{{item.date == "" ? "-------" : getDate(item)}}</td>
                                                            <td>{{item.remarkType ==  "" ? "-------" : item.remarkType}}</td>
                                                            <td>{{item.remarkText ==  "" ? "-------" : item.remarkText}}</td> -->
                                                            <!-- <td></td>
                                                            <td></td>
                                                            <td></td> -->
                                                        <!-- </tr>
                                                        <tr class="v-data-table__empty-wrapper" >
                                                            <td colspan="4">No Records for Remarks</td>
                                                        </tr>
                                                    </tbody>
                                                    </template>
                                            </v-simple-table> -->
                                                                                    
                                        </v-flex>

                                        <!-- back and proceed  code-->
                                        <v-flex justify="center" class="mt-5">
                                            <v-row class="btnDiv">
                                                <v-col align="center">
                                                    <v-btn color="#152F38" dark  class="buttonscolor mr-5" @click="addRemark()">
                                                        <v-icon class="mr-2 iconsize" >mdi-plus</v-icon>
                                                        <div class="vertical_line"></div>Add Remark
                                                    </v-btn>
                                                    <v-btn color="#E46A25" dark  class="buttonscolor mr-5" @click="clearRemark()">
                                                        <v-icon class="mr-2 iconsize" >mdi-close-circle</v-icon>
                                                        <div class="vertical_line"></div>Clear
                                                    </v-btn>
                                                    <v-btn color="#D1121B" dark class="buttonscolor mr-5"
                                                        @click="remarksdialog = false">
                                                        <v-icon class="mr-2 iconsize" >mdi-close-circle</v-icon>
                                                        <div class="vertical_line"></div>Close
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-flex>
                                    </v-form>
                            </v-card-text>
                        </v-card-title>
                    </v-card>
                </v-dialog>
            </v-row>
            <!-- Phase-2 changes starts -->
        </v-form>
    </v-container>
</template>


<script>
import moment from 'moment'
    export default {
        data() {
            return {
        panels: [0,1,2,3],
                serviceAgreementMenu: false,
                bankDetailAgreement: false,
                otherDetailAgreementMenu: false,
                productDetailAgreementMenu1: false,
                productDetailAgreementMenu: false,

                agreementEffectiveDate:false,
                agreementExpirationDate:false,
                auditDateMenu: false,
                auditDueDateMenu: false,

                hideaddressMessage: false,

                addressDialog: false,
                cancelChequeDialog: false,
                attachmentsdialog: false,

                disableBankName: false,
                disableBankAdd1: false,
                disableBankAdd2: false,
                disableBankAdd3: false,
                disableBankBranch: false,

                postalErrorMsg: '',

                userName: '',
                repairerID: '',
                repairerName: '',
                defaultRepairerStatus: 'Inactive',
                portalAccess: false,
                repairerIndicator: '',
                overallSatisfaction: '',
                effectiveDate: new Date().toISOString(),
                expirationDate: null,
                auditDate: null,
                auditDueDate: null,
                pickupOption: false,
                tripFee: false,
                tripFeeInr: '',
                labourRateInr: '',
                jobRate1: 0,
                rate2: 0,
                rate3: 0,
                productType: [],
                authorizedBrands: [],
                repairerWarrantyPeriod: 0,
                salutation: '',
                firstName: '',
                //middleInitial: '',
                lastName : '',
                workPhoneNumber : '',
                emailID : '',
                cellPhoneNumber: '',
                communicationEmailID: '',
                secondEmailID: '',
                isPanNotAvailable: false,
                panNumber: '',
                gstNumber: '',
                gstFrequency: '',
                tdsType: '',
                differentialTds: false,
                msme: '',
                enterpriseClassification: '',
                enterpriseCategory: '',
                uan: '',
                bankTransfer: false,
                bankName: '',
                ifscCode: '',
                bankAccountNumber: '',
                confirmBankAccountNumber: '',
                accountType: '',
                bankAddress1: '',
                bankAddress2: '',
                bankAddress3: '',
                branchName: '',
                cancelledCheque: [],
                addressLine1: '',
                addressLine2: '',
                addressLine3: '',
                country: 'INDIA',
                state: '',
                district: '',
                city: '',
                postalCode: '',
                lobOptions:[],
                lob:[],
                phoneNumber: '',
                preferredCommunicationMethod: '',
                faxNumber: '',
                secondFaxNumber: '',

                allUsers: [],
                allUsersDetails: [],
                userObj: [],

                portalAccessOps:[{text:'Yes',value:true},{text:'No',value:false}],
                pickupOptionrArray: [{name: "Yes",selected: true},{name: "No",selected: false}],
                enterpriseClassificationData: ['Proprietorship', 'Association of Persons', 'Co-operative Society', 'Partnership', 'Firm', 'Company', 'Individual'],
                enterpriseCategoryData: ['Micro', 'Small', 'Medium'],
                preferredComAddresses: ['Cell phone', 'Email', 'Fax', 'Office phone', 'SMS'],
                repairerStatus: ['Active', 'Inactive'],
                overAllSatisfactionOptions: ['0 - New', '1 - Bad', '2 - Satisfactory', '3 - Fair', '4 - Good', '5 - Excellent'],
                authorizedBrandOptions: ["ACER", "ALCATEL", "ALTEC", "ANJALI", "AO SMITH", "APPLE", "APPLE I-PH", "APPLE IPOD", "AQUA SURE", "ASUS", "BAJAJ", "BEE", "BINATONE", "BIONAIRE", "BLACK AND DECKER", "BLACKBERRY", "BLAPUNKT", "BLUESTAR", "BOSCH", "BOSE", "BOYNQ", "BRAUN", "BRIDGE", "BROTHER", "CANON", "CARRIER", "CARRIER MIDEA", "CASELOGIC", "Cello", "CG", "COMPAQ", "CREATIVE", "CROMA", "CROMPTON", "CRUISE", "DAIKIN", "DELL", "DSE", "ELECTROLUX", "EPSON", "EUREKA FORBES", "EUREKAFORB", "FABER", "FLY", "FUJI", "GE", "GLEN", "GODREJ", "HAIER", "HAMILTON BEACH", "HAVELLS", "HCL", "Hindware", "HITACHI", "HP", "HTC", "HUL PUREIT", "IAUDIO", "IBALL", "IFB", "INASLA", "INTEX", "JABRA", "JAMO", "JBL", "KAFF", "KAILASH", "KELVINATOR", "Kenstar", "KENT", "KENWOOD", "KINDLE", "KITCHEN AID", "KLIPSCH", "KODAK", "LABTEC", "LENOVO", "LG", "LIV PURE", "LOGIC3", "LOGITECH", "Maharaja", "MAHARAJA WHITELINE", "MI", "MI or XIAOMI", "MICROSOFT", "MITASHI", "MORPHY RICHARDS", "MORPHYRICH", "MOSERBAER", "MOTOROLA", "NATRAJ", "NIKON", "NOKIA", "OGENERAL", "Olympus", "ONE PLUS", "ONIDA", "ONKYO", "OPPO", "OPTIMA", "Orient", "OSIM", "PADMINI", "PANASONIC", "PHILIPS", "PIGEON", "PILOT", "POLK AUDIO", "POLORID", "PREETHI", "PRESTIGE", "RACOLD", "REALME", "REDMI", "REMINGTON", "RUSSELL HOBBS", "SAMSUNG", "SANDISK", "SANSUI", "SCRATCHGAR", "SEIMENS", "SHARP", "SIEMENS", "SOLO", "SONY", "SONY ERICS", "SUNFLAME", "SYMPHONY", "TATA", "TATA SKY", "TCL", "TOSHIBA", "ULTRA", "UNIDEN", "VIDEOCON", "VIEWSONIC", "VIRGIN", "VIVO", "VOLTAS", "VOXTEL", "VU", "WHIRLPOOL", "XIAOMI", "YAMAHA"],
                productTypeOptions: ["Airconditioner", "Computer Accessories", "CRT TV/LCD TV/PLASMA", "Dishwasher", "DVD/VCD/Audio System", "Gas Stove/Burners", "KITCHEN APPLIANCES", "Laptops/Desktops", "Microwaveoven/OTG", "Phones - Fixed or Mobile", "Printer Fax Machine", "Projection Television", "Refrigerator/Chiller", "Washing Machine/Dryers"],
                labourRateOptions: ['Fixed','Rate Per hour'],
                salutationOptions: ['Dr', 'Mr', 'Mrs', 'Ms'],
                gstFrequencyOptions: ['Monthly', 'Quarterly', 'Monthly less than 100 Crore', 'Monthly more  than 100 Crore but less than 500 Crore', 'Monthly More than 500 Crore'],
                tdsTypeOptions: ["Contractors","Non Residents","Professional Services - CORP","Professional Services - NON CORP","Association of persons (AOP)", "Body of individuals (BOI)", "Company",
                                "Firm", "Government", "HUF (Hindu undivided family)", "Local authority", 
                                "Artificial juridical person", "Individual or Person","Trust (AOP)","TDS not Applicable"],
                msmeOptions: ['Yes', 'No'],
                acountTypeOptions: ['Saving Account', 'Current Account'],
                cancelledChequeFileData: [],

                searchCountry: '',
                countries: [],

                pitems: [],
                searchPincode: '',

                searchState:'',
                states: [],

                searchDistrict: '',
                districts:[],

                searchCity:'',
                cities:[],
                // searchUsers: '',

                attachments : [],
                browse_attachments:[],
                filesPushed:[],
                attachmentheaders:[
                {value: 'icon', sortable:false},
                {value: 'name', sortable:false},
                {value: 'view', sortable:false},
                {value: 'delete', sortable:false}
                ],

                searchIFSCCODE: "",
                bankDetails:[],

                addressRequiredStatus: false,
                cancelledChequeRequiredStatus: false,

                // RULES
                validationmessage:"This field is required",
                mandatoryrule() {
                    // console.log('-------',v)
                    return (v) => !!v || this.validationmessage
                },
                rules: {
                    repairerName: [
                        v => !!v || this.validationmessage,
                        // v => v && !!v.trim() || 'Only spaces are not allowed',
                        v => (v && v.length <= 200) || 'Repairer Name should be less than 200 characters.',
                    ],
                    postalCode: [
                        v => !!v || this.validationmessage,
                        // v => v && !!v.trim() || 'Only spaces are not allowed',
                        v => (v && /^[0-9]+$/.test(v) && v.length === 6 && v[0] != 0) || 'Please enter correct pin code.',
                    ],
                    mandatoryrule: [
                        v => !!v || this.validationmessage
                    ],
                    validatedropdown: [
                        v => v.length > 0 || this.validationmessage
                    ],
                    cancelledChequeValidation: [
                        v => !!v || this.validationmessage
                    ],
                    emailRules: [
                        (v) => !!v || this.validationmessage,
                        (v) =>
                        (v && v.length <= 50) || "Email should be less than 50 characters.",
                        (v) => /.+@.+\..+/.test(v) || "E-mail is not valid.",
                    ],
                    corrBankNameRules: [
                        (v) => !!v || this.validationmessage,
                        (v) =>
                        (v && v.length <= 100) ||
                        "Bank Name should be less than 100 characters.",
                        (v) => /[a-zA-Z0-9\s]+$/.test(v) || "Bank Name is not valid.",
                    ],
                    corrBankAddressRules: [
                        (v) => !!v || this.validationmessage,
                        (v) =>
                        (v && v.length <= 50) ||
                        "Address should be less than 50 characters.",
                        (v) => /[a-zA-Z0-9\s.-:;,/#]+$/.test(v) || "Address is not valid.",
                    ],
                    corrBankAccNumRule: [
                        (v) => !!v || this.validationmessage,
                        (v) =>
                        (v && v.length <= 50) ||
                        "Account Number should be less than 50 characters.",
                        (v) => /[a-zA-Z0-9]+$/.test(v) || "Account Number is not valid.",
                    ],
            
                },
                // Phase-2 declaration starts
                manualOverwrite: false,
                remarksdialog:false,
                remarkType:"General",
                remarkText:"",
                remarks:[],
                remarksTypeArray:["General", "Account Number", "Address"],
                IsDisabledafterpincode:false,
                // Phase-2 declaration ends

            editBankAddress1:false,
            editBankAddress2:false,
            editBankAddress3:false
            }
        },
        methods: {

            checkIsPANAvailable() {
                this.panNumber = '';
                this.tdsType = this.isPanNotAvailable ? "TDS not Applicable" : "";
                if(this.isPanNotAvailable) {
                    this.showAlert("F & A approval won't be triggered & the base rate as per PAN Number Not Available will be applied.");
                }
            },

            checkBankAddress(){
 const specialChars = `\`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~`;

  specialChars.split('').some(specialChar => {
    if (this.bankAddress1.includes(specialChar)) {
      this.editBankAddress1 = true
    }
   
  });

  specialChars.split('').some(specialChar => {
    if (this.bankAddress2.includes(specialChar)) {
      this.editBankAddress2 = true
    }
  });

  specialChars.split('').some(specialChar => {
    if (this.bankAddress3.includes(specialChar)) {
      this.editBankAddress3 = true
    }
  });
      },
        
            showPopUp(){
                if(!this.bankTransfer) {
                    console.log("###")
                    this.ifscCode = "";
                    this.bankName="";
                    this.bankAddress1="";
                    this.branchName="";
                    this.bankAddress2="";
                    this.bankAddress3 ="";
                    this.bankAccountNumber = "";
                    this.confirmBankAccountNumber = "";
                    this.bankAccountNumber = "";
                    this.confirmBankAccountNumber = "";
                }
                if(this.bankTransfer == true) return this.showAlert("Please upload the cancelled cheque.")
            },
            populateUserId(item){
        // console.log("ITEMS",item)
    //    this.vendorNames()
    this.searchUsers()
      },

            isNumber(evt) {
                evt = (evt) ? evt : window.event;
                var charCode = (evt.which) ? evt.which : evt.keyCode;
                if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                    evt.preventDefault();
                }
                return true;
            },

            onlyAlphaNumeric(e){
                var code = ('charCode' in e) ? e.charCode : e.keyCode;
                if (
                    !(code > 47 && code < 58) && // numeric (0-9)
                    !(code > 64 && code < 91) && // upper alpha (A-Z)
                    !(code > 96 && code < 123)) { // lower alpha (a-z)
                    e.preventDefault();
                }
            },

            isLetter(e) {
                let char = String.fromCharCode(e.keyCode);
                if(/^[A-Z a-z]$/.test(char)) return true;
                else e.preventDefault();
            },

            panValidation(v) {
                let alphaNumericRegex = /[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}$/;
                if(!v) {
                    return 'This field is required'
                }
                if(v && !alphaNumericRegex.test(v)) {
                    return 'Please enter a valid PAN number'
                } else {
                    return true
                }
            },

            gstValidation(v) {
                let alphaNumericRegex = /[0-9]{2}[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}[A-Za-z0-9]{1}[A-Za-z]{1}[A-Za-z0-9]{1}$/;
                // if(!v) {
                //   return 'This field is required'
                // }
                if(v && !alphaNumericRegex.test(v)) {
                    return 'Invalid GST number'
                } else {
                    return true
                }
            },

            backAccountNumberValidation(v) {
                        
                const accountRegex = /[a-zA-Z0-9]+$/;
                if(!v) {
                    return this.validationmessage;
                }
                if(v && !accountRegex.test(v)) {
                    return "Account Number is not valid.";
                }

                if((v && v.length > 50)) {
                    return "Account Number should be less than 50 characters.";
                } 

                if(v && v !== this.bankAccountNumber) {
                    return 'Account Number does not match.';
                }
                return true;
            },

            emailValidation(v) {
                let emailRegex = /.+@.+\..+/
                if(v && !emailRegex.test(v)) {
                    return "E-mail is not valid."
                } else {
                    return true
                }
            },

            addressLineValidation(v){
                let addressVal = /^(?![|""-,;:~\s])[a-zA-Z0-9^<>{}`.()\/\\'_!@#$%?&*=+[\]\s]*$/;  
                if (v && !addressVal.test(v)){
                    return "Special characters are not allowed";
                }else if (v && v.length > 45){
                    return "Max length 45 is allowed";
                }else{
                    return true
                }
            },

            addressValidation(v){
                let addressVal = /^(?![|""-,;:~\s])[a-zA-Z0-9^<>{}`.()\/\\'_!@#$%?&*=+[\]\s]*$/;  
                if (v && !addressVal.test(v)){
                    return "Special characters are not allowed";
                }else if (v && v.length > 35){
                    return "Max length 35 is allowed";
                }else{
                    return true
                }
            },

            openAddressDialog () {
                this.addressDialog = true
                this.getCountries()
            },

            openAttachments(){
                this.attachmentsdialog = true;
            },

            openCancelledChequeDialog() {
                this.cancelChequeDialog = true
            },

            limitTextLength(name) {
                return name.length > 15 ? name.substring(0, 16).concat('...') : name
            },

            checkFileType(file) {
                if(
                    file === 'image/png' ||
                    file === 'image/jpeg'
                ) {
                    return 'image';
                }
                if(
                    file === 'application/pdf'
                ) {
                    return 'pdf';
                }
                if(
                    file === 'application/msword' ||
                    file === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                ) {
                    return 'word';
                }
                if(
                    file === 'application/vnd.ms-excel' ||
                    file === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                    file === 'text/csv'
                ) {
                    return 'excel';
                }
                return false;
            },

            toggle () {
                this.$nextTick(() => {
                if (this.selectAllBrands) {
                    this.authorizedBrands = []
                } else {
                    this.authorizedBrands = this.authorizedBrandOptions.slice()
                }
                })
            },

            checkTDSType() {
                let indexItem = this.panNumber[3];
                switch (indexItem) {
                    case 'A':
                        this.tdsType = "Association of persons (AOP)";
                        break;
                    case 'B':
                        this.tdsType =  "Body of individuals (BOI)";
                        break;
                    case 'C':
                        this.tdsType = "Company";
                        break;
                    case 'F':
                        this.tdsType = "Firm";
                        break;
                    case 'G':
                        this.tdsType = "Government";
                        break;
                    case 'H':
                        this.tdsType = "HUF (Hindu undivided family)";
                        break;
                    case 'L':
                        this.tdsType = "Local authority";
                        break;
                    case 'J':
                        this.tdsType = "Artificial juridical person";
                        break;
                    case 'P':
                        this.tdsType = "Individual or Person";
                        break;
                    case 'T':
                        this.tdsType = "Trust (AOP)";
                        break;
                    case 'Z':
                        this.tdsType = "TDS not Applicable";
                        break;
                    default:
                        this.showAlert("Please enter a valid PAN Number", "OK", "", () => {
                            this.panNumber = '';
                            this.tdsType = '';
                        });
                        break;
                }
            },
            getLOB() {
        let reqData = '';
        this.showProgress();
        this.GETM("getLOB", reqData, (res) => {
            this.hideProgress();
            if(res.data.statusCode === 200) {
                this.lobOptions = res.data.data[0].lineOfBusiness;
            } else {
                this.lobOptions = [];
                this.showAlert(res.data.msg);
            }
        }, (error) => {
            console.log(error);
            this.hideProgress();
        }, true)
    },

            createRepairerSubmit() {
                this.hideaddressMessage = false;
                this.userObj = this.allUsersDetails.find(data => data.ntidUserId === this.userName);
                let reqData = {
                    lob:this.lob,
                    repairerId: this.repairerID,
                    repairerName: this.repairerName,
                    addressLine1: this.addressLine1,
                    addressLine2: this.addressLine2,
                    addressLine3: this.addressLine3,
                    country: this.country,
                    state: this.state,
                    district: this.district,
                    city: this.city, 
                    postalCode: this.postalCode,
                    phoneNumber: this.phoneNumber,
                    preCommMethod: this.preferredCommunicationMethod,
                    repairerStatus: this.defaultRepairerStatus=='Active'?true:false ,
                    portalAccess: this.portalAccess,
                    faxNumber: this.faxNumber,
                    secondFaxNumber: this.secondFaxNumber,
                    superRepairerIndicator: this.repairerIndicator,
                    overllSatisfactionRating: this.overallSatisfaction,
                    agreementEffectiveDate: this.effectiveDate ? this.effectiveDate : "",
                    agreementExpirationDate: this.expirationDate ? this.expirationDate : "",
                    pickUp: this.pickupOption,
                    isTripFee: this.tripFee, 
                    tripFee: this.tripFeeInr,
                    laborRate: this.labourRateInr,
                    jobRate1: this.jobRate1,
                    jobRate2: this.rate2 || 0,
                    jobRate3: this.rate3 || 0,
                    lastAuditDate: this.auditDate ? this.auditDate : "",
                    nextAuditDate: this.auditDueDate ? this.auditDueDate : "",
                    productType: this.productType,
                    authorizedBrand: this.authorizedBrands,
                    repairerWarrantyPeriod: this.repairerWarrantyPeriod,
                    salutation: this.salutation,
                    firstName: this.firstName,
                    middleName: this.middleInitial,
                    lastName: this.lastName,
                    workPhoneNumber: this.workPhoneNumber,
                    emailId: this.emailID,
                    cellPhoneNumber: this.cellPhoneNumber,
                    commEmailId: this.communicationEmailID,
                    secondEmailId: this.secondEmailID,
                    isPanNotAvailable: this.isPanNotAvailable,
                    panNumber: this.panNumber,
                    gstNumber: this.gstNumber,
                    gstFrequency: this.gstFrequency,
                    tdsType: this.tdsType,
                    differentialTds: this.differentialTds,
                    msme: this.msme === 'Yes' ? true : false,
                    enterpriceClassification: this.enterpriseClassification,
                    enterpriceCategory: this.enterpriseCategory,
                    uan: this.uan,
                    bankTransfer: this.bankTransfer,
                    bankName: this.bankName,
                    ifscCode: this.ifscCode,
                    bankAddress1: this.bankAddress1,
                    bankAddress2: this.bankAddress2,
                    bankAddress3: this.bankAddress3,
                    bankAccountNumber: this.bankAccountNumber,
                    confirmBankAccountNumber: this.confirmBankAccountNumber,
                    bankAccountType: this.accountType,
                    branchName: this.branchName,
                    cancelledCheque: this.cancelledChequeFileData,
                    attachments: this.attachments,
                    user: {
                        ntid: this.userObj && this.userObj.ntidUserId,
                        employeeName: this.userObj && this.userName,
                        userId: this.userObj && this.userObj._id
                    },
                    overwriteManually: this.manualOverwrite,
                    approveStatus: 'Pending for approval',
                    remarkType: this.remarkType,
                    remarkText: this.remarkText,
                }
                if(this.addressLine1 === '' || this.postalCode === '' || this.country === '' || this.state === '' || this.city === '') {
                    this.addressRequiredStatus = true
                } else {
                    this.addressRequiredStatus = false
                }
                if(this.bankTransfer && this.cancelledChequeFileData.length < 1) {
                    this.cancelledChequeRequiredStatus = true
                } else {
                    this.cancelledChequeRequiredStatus = false
                }
                if(this.$refs.createRepairer.validate() && !this.cancelledChequeRequiredStatus && !this.addressRequiredStatus) {
                this.showConfirm("Confirmation", "Are you sure you want to save?", "Yes", "No", (Yes) => {
                    window.scrollTo(0, window.innerHeight / 2);
                    this.showProgress("Please wait");
                    this.POSTM("POST_REPAIRER_MASTER", reqData, (res) => {
                        if(res.data.statusCode === 200) {
                            this.hideProgress();
                            this.showAlert(res.data.msg, "OK", "", () => {
                            this.$router.push('/master/search-repairer');
                            });
                        } else {
                            this.hideProgress();
                            this.showAlert(res.data.msg);
                        }
                    }, (error) => {
                        this.hideProgress();
                        if (error.response.data.msg) {
                            this.showAlert(error.response.data.msg);
                        } else if (error.response.data.message.msg) {
                            this.showAlert(error.response.data.message.msg);
                        } else if (error.response.data.message) {
                            this.showAlert(error.response.data.message);
                        } else {
                            this.showAlert("Something went wrong");
                        }
                    },true);
                }, (No) => {});
                } else {
                    this.showAlert("Please enter all mandatory fields");
                }
            },

            proceed() {
                this.hideaddressMessage = false;
                let regex = /^(?![|""-,;:~\s])[a-zA-Z0-9^<>{}`.()\/\\'_!@#$%?&*=+[\]\s]*$/
                if(this.$refs.addressref.validate()) {
                    this.addressDialog = false;
                } else if(!regex.test(this.addressLine1) || !regex.test(this.addressLine2) || !regex.test(this.addressLine3)){
                    this.showAlert("Special characters are not allowed");
                } else if(this.addressLine1.length > 45 || this.addressLine2.length > 45){
                    this.showAlert("Max length more than 45 not allowed");
                } else if(this.addressLine3.length > 35){
                    this.showAlert("Max length more than 35 not allowed");
                } else {
                    this.showAlert("Please enter all mandatory fields");
                }
            },

            uploadBtnClicked(){
                document.getElementById("fileupload").click();
            },
            checkNegativeValue(v){
                return (v<0)?"This Field is Invalid":true
            },

            filesSelected (){
                if(this.cancelledCheque.length !== 0){
                    const fsize = this.cancelledCheque[0].size;
                    const file = Math.round(fsize / 1024);
                    
                    if (file > 5120) {
                        this.showAlert("File is too big, please select a file less than 5MB");
                    } else {
                        this.readImageM(this.cancelledCheque[0].name, this.cancelledCheque[0], (res) => { 
                        var type = [];
                        type = this.cancelledCheque[0].type
                        
                        if(this.cancelledChequeFileData.length < 1){
                            this.cancelledChequeFileData.push({ 
                                file : res.fileName,
                                fileBase: res.base,
                                documentType: type
                            })
                            this.cancelledChequeFileData =   
                            this.cancelledChequeFileData.reduce((unique, o) => {
                            if(!unique.some(obj => obj.file === o.file)) {
                                unique.push(o);
                                
                            }
                            return unique;
                            },[]);
                        }else{
                        //   this.showConfirm("Confirmation","Do you want to delete 1st file to replace it?","Yes","No",(Yes)=>{
                            this.cancelledChequeFileData.splice(0,1)
                            this.cancelledChequeFileData.push({ 
                                file : res.fileName,
                                fileBase: res.base,
                                documentType: type
                            })
                            this.cancelledChequeFileData =   
                            this.cancelledChequeFileData.reduce((unique, o) => {
                                if(!unique.some(obj => obj.file === o.file)) {
                                    unique.push(o);
                                    
                                }
                                return unique;
                            },[]);
                        //   },(No)=>{})
                        }
                        
                        // Phase-2 function call starts
                        this.getCancelledChequeData(res.base);
                        this.manualOverwrite = false;
                        // Phase-2 function call ends

                        });
                    }
                }
                // else{
                //     this.cancelledChequeFileData = [];
                // }
            },

            openBrowse(){
                document.getElementById("fileupload1").click();
            },

            fileUploaded(){
                if(this.browse_attachments.length !== 0){
                    const fsize = this.browse_attachments[0].size;
                    const file = Math.round(fsize / 1024);
                        
                    if (file > 5120) {
                        this.showAlert("File is too big, please select a file less than 5MB");
                    } else {
                        this.readImageM(this.browse_attachments[0].name, this.browse_attachments[0], (res) => { 
                            var type = [];
                            type = this.browse_attachments[0].type
                            
                            if(this.attachments.length < 50){
                                this.attachments.push({ 
                                file : res.fileName,
                                fileBase: res.base,
                                documentType: type
                                })
                                this.attachments =   
                                this.attachments.reduce((unique, o) => {
                                if(!unique.some(obj => obj.file === o.file)) {
                                    unique.push(o);
                                    
                                }
                                return unique;
                                },[]);
                            }else{
                                this.showConfirm("Confirmation","Do you want to delete 1st file to replace it?","Yes","No",(Yes)=>{
                                    this.attachments.splice(0,1)
                                    this.attachments.push({ 
                                        file : res.fileName,
                                        fileBase: res.base,
                                        documentType: type
                                    })
                                    this.attachments =   
                                    this.attachments.reduce((unique, o) => {
                                        if(!unique.some(obj => obj.file === o.file)) {
                                            unique.push(o);
                                            
                                        }
                                        return unique;
                                    },[]);
                                },(No)=>{})
                            }
                        });
                    }
                }
                // else{
                //     this.attachments = [];
                // }
            },

            deleteCancelledCheque(index) {
                this.cancelledChequeFileData.splice(index, 1);
                this.cancelledCheque = null;
                this.ifscCode = ""; 
                this.bankName="";
                this.bankAddress1="";
                this.branchName="";
                this.bankAddress2="";
                this.bankAddress3="";
                this.bankAccountNumber = "";
                this.confirmBankAccountNumber = "";
            },

            deleteAttachments(index) {
                this.attachments.splice(index, 1);
                this.browse_attachments = null
            },

            viewDocument(base64) {
                const base64ImageData = base64
                const contentType = base64.split(':')[1].split(';')[0];
                const byteCharacters = window.atob(base64ImageData.substr(`data:${contentType};base64,`.length));
                const byteArrays = [];
                for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
                    const slice = byteCharacters.slice(offset, offset + 1024);
                    const byteNumbers = new Array(slice.length);
                for (let i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                    byteArrays.push(byteArray);
                }
                const blob = new Blob(byteArrays, {type: contentType});
                const blobUrl =  window.URL.createObjectURL(blob);
                window.open(blobUrl, '_blank');
            },

            clearRepairerForm() {
                let self = this;
                this.showConfirm("Confirmation","Are you sure you want to clear?","Yes","No", (yes) => {
                    this.userName = '';
                    self.lob=[],
                    self.repairerID = '',
                    self.repairerName = '',
                     self.defaultRepairerStatus = 'Active',
                     self.portalAccess = false
                    self.repairerIndicator = '',
                    self.overallSatisfaction = '',
                    self.effectiveDate = new Date().toISOString(),
                    self.expirationDate = null,
                    self.auditDate = null,
                    self.auditDueDate = null,
                    self.pickupOption = false,
                    self.tripFee = false,
                    self.tripFeeInr = '',
                    self.labourRateInr = '',
                    self.jobRate1 = 0,
                    self.rate2 = 0,
                    self.rate3 = 0,
                    self.productType = '',
                    self.authorizedBrands = '',
                    self.repairerWarrantyPeriod = 0,
                    self.salutation = '',
                    self.firstName = '',
                    self.middleInitial = '',
                    self.lastName  = '',
                    self.workPhoneNumber  = '',
                    self.emailID  = '',
                    self.cellPhoneNumber = '',
                    self.communicationEmailID = '',
                    self.secondEmailID = '',
                    self.isPanNotAvailable = false,
                    self.panNumber = '',
                    self.gstNumber = '',
                    self.gstFrequency = '',
                    self.tdsType = '',
                    self.differentialTds = false,
                    self.msme = '',
                    self.enterpriseClassification = '',
                    self.enterpriseCategory = '',
                    self.uan = '',
                    self.bankTransfer = false,
                    self.bankName = '',
                    self.ifscCode = '',
                    self.bankAccountNumber = '',
                    self.confirmBankAccountNumber = '',
                    self.accountType = '',
                    self.bankAddress1 = '',
                    self.bankAddress2 = '',
                    self.bankAddress3 = '',
                    self.branchName = '',
                    self.cancelledCheque = null,
                    self.addressLine1 = '',
                    self.addressLine2 = '',
                    self.addressLine3 = '',
                    self.country = 'INDIA',
                    self.state = '',
                    self.district = '',
                    self.city = '',
                    self.postalCode = '',
                    self.phoneNumber = '',
                    self.preferredCommunicationMethod = '',
                    self.faxNumber = '',
                    self.secondFaxNumber = ''
                    self.cancelledChequeFileData = [];
                    self.attachments = []
                    self.$refs.createRepairer.resetValidation();
                    self.$refs.addressref.resetValidation();
                    self.hideaddressMessage = true;
                    this.manualOverwrite = false;
                }, (No) => {});
            },

            goBack() {
                this.showConfirm("Confirmation","Are you sure you want to leave?","Yes","No", (Yes) => {
                    this.$router.push('/master/search-repairer');
                }, (No) => {});
            },

            // for address
            callPincodeAPI(indata){
                if(indata!==""){
                if(isNaN(indata)){
                    this.returnLength = 100
                    //Surendra(16-04-2021):Added if else for MC839-not able to search city data by first three letters
                    this.reqData = "pageNo=1&perPage=100&smartSearch="+indata//Surendra(27-05-2021):Changed from cityDistrictName to smartSearch for MC-1182 where ever we have pincode, it should allow to search by city, area, pincode and district."
                }else{
                    this.returnLength = 6
                    this.reqData = "pageNo=1&perPage=100&pinCode="+indata
                }
                    this.GETM("getPincodes",this.reqData, res=>{
                        var pin_not_found = false
                        if(res.data.data.length == 0){
                            pin_not_found = true
                            this.message ='Pin code not found'
                            this.validationbox = true;
                        }else{
                            var processed_pin =  res.data.data.data.map(e=>{
                                if(e.cityDistrictName && e.cityDistrictName !== ""){
                                    return { locpincode:e.pinCode + " - " + e.pinCodeLocality, pincodeArry:e}
                                }else{
                                    return {locpincode: e.pinCode, all:e};
                                }                  
                            })
                            this.pitems.push(...processed_pin)
                        }  
                        if(pin_not_found){
                            this.pitems.push();
                        }
                    },(error)=>{
                            return error;
                    },true);
                }
            },

            toGetCountryCity(pincode){
                if(pincode){
                    let indexItem =this.pincodes.find((elem)=>{
                        return elem.locpincode == pincode;
                    })
                    if(indexItem){
                        this.country = indexItem.pincodeArry.countryName ? indexItem.pincodeArry.countryName : "" ;
                        this.state = indexItem.pincodeArry.stateName ? indexItem.pincodeArry.stateName : "" ;
                        this.city = indexItem.pincodeArry.cityDistrictName ? indexItem.pincodeArry.cityDistrictName : "";
                        this.district = indexItem.pincodeArry.cityDistrictName ? indexItem.pincodeArry.cityDistrictName : "";  
                        this.getClaimantStates();
                        this.getClaimantCities();
                        this.getCountries()
                        this.IsDisabledafterpincode = true
                    }
                }else{
                    this.pitems = []
                    this.country = ''
                    this.district = ''
                    this.districts = []
                    this.state = ''
                    this.states = []
                    this.city = ''
                    this.cities = []
                    this.getCountries()
                    this.IsDisabledafterpincode = false
                }
            },

            clearClaimantcity(){
                if(this.city!='OTHER'){
                this.cityOtherName=""
                }
            },

            getClaimantStates(){
                if(this.country){
                    this.showProgress();
                    var requestData = "countryName=" + this.country
                    this.GETM("getStates", requestData, (res) => {
                        this.states = res.data.data.data
                        this.hideProgress()
                    },(error)=>{
                        this.hideProgress();
                        return error;
                    });
                }else{
                    this.city = ''
                    this.district = ''
                    this.state = ''
                    this.cities = []
                    this.districts = []
                    this.states = []
                    this.getCountries()
                }
            },

            getClaimantCities(){
                if(this.state){
                    this.showProgress();
                    var req="stateName="+this.state                    
                    this.GETM("getCities", req, (res) => {
                        this.cities = res.data.data.data
                        this.districts = res.data.data.data                        
                            this.hideProgress()
                    },(error)=>{
                            this.hideProgress()
                        return error;
                    }); 
                }else{
                    this.city = ''
                    this.district = ''
                    this.cities = []
                    this.districts = []
                }             
            },

            getCountries(){
                this.GETM("getCoutries", "", (res) => {
                    this.countries = res.data.data.data;
                },(error) => {
                    return error;
                });
            },

            getBankFields(IFSC){
                // let indexItem = this.bankDetails[0] && this.bankDetails[0].length > 0 ? this.bankDetails[0][this.bankDetails[0].length - 1] : this.bankDetails[0];
                // if(IFSC){
                //   const indexItem = bankDetailArray.find((elem)=>{
                //     return elem.ifscCode == IFSC;
                //   })
                let indexItem = {};
                let filteredArray = [];
                let bankDetailArray = this.bankDetails[0] && this.bankDetails[0].length > 0 ? this.bankDetails[0] : this.bankDetails;
                if(IFSC){
                    filteredArray = bankDetailArray.filter((elem)=>{
                        return elem.ifscCode == IFSC;
                    })
                    
                    let filteredArrayLength = filteredArray.length;
                    indexItem = filteredArray[filteredArrayLength - 1];
                    // console.log("indexItem", filteredArray, indexItem);
                    if(indexItem){
                        this.bankName=indexItem.financierName;
                        this.bankAddress1=indexItem.branchAddress;
                        this.branchName=indexItem.branchName;
                        let address2 = '';
                        if(indexItem.district) {
                            address2 += `${indexItem.district}, `
                        }
                        if(indexItem.city) {
                            address2 += `${indexItem.city}, `
                        }
                        if(indexItem.pincode) {
                            address2 += indexItem.pincode
                        }
                        this.bankAddress2=address2;
                        this.bankAddress3=indexItem.state;

                        
                        if(indexItem.financierName) {
                        this.disableBankName = true
                        } else {
                        this.disableBankName = false
                        }
                        if(indexItem.branchAddress) {
                        this.disableBankAdd1 = true
                        } else {
                            this.disableBankAdd1 = false
                        }
                        if(indexItem.district || indexItem.city || indexItem.pincode) {
                            this.disableBankAdd2 = true
                        } else {
                            this.disableBankAdd2 = false
                        }
                        if(indexItem.state) {
                            this.disableBankAdd3 = true
                        } else {
                            this.disableBankAdd3 = false
                        }
                        if(indexItem.branchName) {
                            this.disableBankBranch = true
                        } else {
                            this.disableBankBranch = false
                        }
                        this.checkBankAddress()
                    }
                }
                // else{
                //   this.bankName="";
                //   this.bankAddress1="";
                //   this.branchName="";
                //   this.bankAddress2="";
                // }
                // this.bankAddress3 ="";

            },

            // Phase-2 functionality starts
            getBankDetailsByIFSC(value) {
                if (value) {
                    if (value.length > 2) {
                        let request = value;
                        this.GET_ByURLM(
                            "GET_GC_BANK",
                            request,
                            (res) => {
                            this.bankDetails = [];
                            if (res.data.statusCode === 200 && res.data.data) {
                                this.bankDetails.push(res.data.data);
                                this.getBankFields(value);
                            } else {
                                this.showAlert(res.data.msg);
                                this.bankName="";
                                this.bankAddress1="";
                                this.branchName="";
                                this.bankAddress2="";
                                this.bankAddress3 ="";
                                this.bankAccountNumber = "";
                                this.confirmBankAccountNumber = "";
                            }
                            },
                            (error) => {
                                this.bankName="";
                                this.bankAddress1="";
                                this.branchName="";
                                this.bankAddress2="";
                                this.bankAddress3 ="";
                                this.bankAccountNumber = "";
                                this.confirmBankAccountNumber = "";
                                if (error.response.data.msg) {
                                this.showAlert(error.response.data.msg);
                                } else if (error.response.data.message.msg) {
                                    this.showAlert(error.response.data.message.msg);
                                } else if (error.response.data.message) {
                                    this.showAlert(error.response.data.message);
                                } else {
                                    this.showAlert("Something went wrong");
                                }
                            }, true
                        );
                    }
                }
            },

            getCancelledChequeData(base) {
                window.scrollTo(0, window.innerHeight / 2);
                const reqData = {
                    document: base
                };
                this.showProgress();
                this.POSTM("GET_OCR_FORMAT", reqData, (res) => {
                    if(res.data.statusCode === 200) {
                        this.hideProgress();
                        if(res.data.data.StatusCode === "TaTa200") {
                            // console.log("response data", res.data.data.IFSC);
                            if(res.data.data.IFSC) {
                                this.showAlert('OCR uploaded successfully', "OK", "", () => {});
                                this.ifscCode = res.data.data.IFSC;
                                this.getBankDetailsByIFSC(res.data.data.IFSC);
                                this.bankAccountNumber = res.data.data.AccountNumber;
                                this.confirmBankAccountNumber = res.data.data.AccountNumber;
                            } else {
                                // console.log("Do something else here----------------");
                                this.populateOcrData(reqData);
                            }
                            
                        } else {
                            this.ifscCode = ""; 
                            this.bankName="";
                            this.bankAddress1="";
                            this.branchName="";
                            this.bankAddress2="";
                            this.bankAddress3 ="";
                            this.bankAccountNumber = "";
                            this.confirmBankAccountNumber = "";
                            this.cancelledChequeFileData = [];
                            this.showAlert(res.data.data.Message, "OK", "", () => {});
                        }
                    } else {
                        this.ifscCode = ""; 
                        this.bankName="";
                        this.bankAddress1="";
                        this.branchName="";
                        this.bankAddress2="";
                        this.bankAddress3 ="";
                        this.bankAccountNumber = "";
                        this.confirmBankAccountNumber = "";
                        this.hideProgress();
                        this.cancelledChequeFileData = [];
                        this.showAlert(res.data.msg);
                    }
                }, (error) => {
                        this.hideProgress();
                        this.showAlert(
                          error.response &&
                            error.response.data &&
                            error.response.data.message
                            ? error.response.data.message
                            : error.message
                        )
                }, true);
            },

            populateOcrData(reqData) {
                window.scrollTo(0, window.innerHeight / 2);
                this.showProgress("Trying to retrieve data from OCR");
                this.POSTM("getChequeDetailsOCR", reqData, (res) => {
                    if(res.data.statusCode === 200) {
                        this.hideProgress();
                        if(res.data.data.StatusCode === "TaTa200") {
                            // console.log("response data", res.data.data.IFSC);
                            if(res.data.data.IFSC) {
                            this.showAlert('Uploaded successfully', "OK", "", () => {});
                            this.ifscCode = res.data.data.IFSC;
                            this.getBankDetailsByIFSC(res.data.data.IFSC);
                            this.bankAccountNumber = res.data.data.AccountNumber;
                            this.confirmBankAccountNumber = res.data.data.AccountNumber;

                            } else {
                                this.ifscCode = ""; 
                                this.bankName="";
                                this.bankAddress1="";
                                this.branchName="";
                                this.bankAddress2="";
                                this.bankAddress3 ="";
                                this.bankAccountNumber = "";
                                this.confirmBankAccountNumber = "";
                                this.showAlert('Could not retrieve data from OCR either reupload the cancelled cheque or enter the details manually', "OK", "", () => {});
                            }
                            
                        } else {
                            this.ifscCode = ""; 
                            this.bankName="";
                            this.bankAddress1="";
                            this.branchName="";
                            this.bankAddress2="";
                            this.bankAddress3 ="";
                            this.bankAccountNumber = "";
                            this.confirmBankAccountNumber = "";
                            this.cancelledChequeFileData = [];
                            this.showAlert(res.data.data.Message, "OK", "", () => {});
                        }
                    } else {
                        this.ifscCode = ""; 
                        this.bankName="";
                        this.bankAddress1="";
                        this.branchName="";
                        this.bankAddress2="";
                        this.bankAddress3 ="";
                        this.bankAccountNumber = "";
                        this.confirmBankAccountNumber = "";
                        this.hideProgress();
                        this.cancelledChequeFileData = [];
                        this.showAlert(res.data.msg);
                    }
                }, (error) => {
                        this.hideProgress();
                        if (error.response.data.msg) {
                            this.showAlert(error.response.data.msg);
                        } else if (error.response.data.message.msg) {
                            this.showAlert(error.response.data.message.msg);
                        } else if (error.response.data.message) {
                            this.showAlert(error.response.data.message);
                        } else {
                            this.showAlert("Something went wrong");
                        }
                }, true);
            },

            changeSubmissionType() {

                if(!this.manualOverwrite) {
                this.deleteCancelledCheque();

                }else{
                this.showConfirm('Confirmation','Once checked cannot be unchecked. Do you want to proceed?','Yes','No',(Yes)=>{ },()=>this.manualOverwrite=false)

                }
            },

            openRemarks(){
                this.remarksdialog = true
            },

            addRemark(){
                if(this.$refs.remarkform.validate()){
                    this.showToast("Remarks added", this.TOST().SUCCESS)
                    this.remarksdialog = false
                }
            },

            clearRemark(){
                this.showConfirm("Confirmation","Are you sure you want to clear?","Yes","No",(Yes)=>{
                    this.remarkText = ""
                    this.remarkType = ""
                    this.showToast("Remarks cleared", this.TOST().SUCCESS)
                    this.$refs.remarkform.resetValidation()

                },(No)=>{})
            },
            // Phase-2 functionality ends

            searchUsers() {
                let requestData = "";
                // let repArr = repairer ? repairer.split(' - ') : [];
                // if(repArr.length > 0){
                //     requestData = "smartSearch=" + repArr[0];
                // }
                // this.showProgress('Please wait');
                this.GETM("GET_REPAIRER_MASTER_USER",
                requestData + `lob=${this.lob.map((item) => item)}`,
                (res) => {
                    if (res.data.statusCode === 200 && res.data.data) {
                        this.hideProgress();
                        this.allUsersDetails = res.data.data;
                        res.data.data.map((data) => {
                            if(data.employeeName && data.ntidUserId){
                                this.allUsers.push({
                                    userId: data.employeeName + " - " + data.ntidUserId,
                                    ntidUserId : data.ntidUserId
                                })
                            }
                        })
                    }else{
                        this.hideProgress();
                        this.showAlert("No Data Available!");
                    } 
                },(error) => {
                    this.hideProgress();
                    this.repairerMastersData = [];
                    if (error.response.data.msg) {
                    this.showAlert(error.response.data.msg);
                    } else if (error.response.data.message.msg) {
                        this.showAlert(error.response.data.message.msg);
                    } else if (error.response.data.message) {
                        this.showAlert(error.response.data.message);
                    } else {
                        this.showAlert("Something went wrong");
                    }
                }, true)
            },
        },
        created(){
            console.log("HIIIT")
            this.getLOB()
            this.getCountries()
            this.searchCountry='INDIA'
            this.$store.commit("SAVE_PAGE_HEADER", "Repairer Master");
        },

        watch: {
            searchIFSCCODE(value) {
                if (value) {
                    if (value.length > 2) {
                        let request = value;
                        this.GET_ByURLM("GET_GC_BANK",request,(res) => {
                            this.bankDetails = [];
                            if (res.data.statusCode === 200 && res.data.data) {
                                this.bankDetails.push(res.data.data);
                                // console.log("ifsc >>>>>>>", res.data.data);
                            } else {
                            this.showAlert(res.data.msg);
                            }
                        },(error) => {
                            this.bankDetails = [];
                            if (error.response.data.msg) {
                                this.showAlert(error.response.data.msg);
                            } else if (error.response.data.message.msg) {
                                this.showAlert(error.response.data.message.msg);
                            } else if (error.response.data.message) {
                                this.showAlert(error.response.data.message);
                            } else {
                                this.showAlert("Something went wrong");
                            }
                        }, true);
                    }
                }
            },

            searchPincode(val){
                if(val !==null && val.charAt(0) === '0') {
                    this.showToast("Please enter correct pin code", this.TOST().WARNING)
                }
                if(val == null){
                    return
                }
                if(val.length > 4){
                    if(val.length > 6){
                        val = val.slice(0,6);
                    }
                    var vlen = val.length
                    if(this.pincodes.length == 0 && val.length > 2){
                        this.callPincodeAPI(val);
                        return
                    }
                    for(let i=0; i<this.pincodes.length;i++){
                        if(vlen > 3){
                            vlen = 3
                            var strlen = this.pincodes[i].locpincode.slice(0,vlen)
                        }else{
                            var strlen = this.pincodes[i].locpincode.slice(0,vlen)
                        } 
                        if(vlen > strlen.length){
                            return
                        }
                        if(strlen != val){
                            this.callPincodeAPI(val)
                            return
                        }else{
                            return
                        }
                    }
                }else{
                    return
                }
            },

            searchCountry(val) {
                if(val && val.length > 3){
                    let req = "countryName=" + val
                    this.GETM("getCoutries", req, (res) => {
                        this.countries = res.data.data.data;
                    },(error) => {
                        return error;
                    })
                }
            },

            searchState(val){
                if(val!==null){
                    if(val.length > 3){    
                        let req = '';
                        if(this.country){
                            req = "countryName=" + this.country
                        } else {
                            req = "stateCodeName=" + val
                        }                        
                        this.GETM("getStates", req, (res) => {
                        this.states = res.data.data.data
                        },(error)=>{
                            return error;
                        }); 
                    }else{
                        return;
                    }
                }else{
                    return;
                }
            },

            searchCity(val){
                if(val!==null){
                    if(val.length > 2){
                        var req = ''
                        if(this.state) {
                            req = "stateName=" + this.state
                        } else if(this.country){
                            req = "countryName=" + this.country
                        } else {
                            req = "cityDistrictName=" + val
                        }                    
                        this.GETM("getCities", req, (res) => {
                            this.cities = res.data.data.data;
                                var other ={
                                cityDistrictName:'OTHER'
                            }
                            this.cities.push(other)
                                this.hideProgress();
                        },(error)=>{
                                this.hideProgress();
                            return error;
                        }); 
                    }else{
                        this.validationbox = true;
                        this.message = 'Please enter Minimum 3 charactor to search City';
                        this.cities.push();
                        return;
                    }
                }else{
                    this.cities.push();
                    return;
                }
            },

            searchDistrict(val){
                if(val!==null){
                    if(val.length > 2){
                        var req = ''
                        if(this.state) {
                            req = "stateName=" + this.state
                        } else if(this.country){
                            req = "countryName=" + this.country
                        } else {
                            req = "cityDistrictName=" + val
                        }                         
                        this.GETM("getCities", req, (res) => {
                            this.districts = res.data.data.data
                            var other ={
                                cityDistrictName:'OTHER'
                            }
                            if(this.districts==undefined){
                                this.districts=[]
                            }
                            this.districts.push(other)                                
                        },(error)=>{                            
                            return error
                        }); 
                    }else{
                        return;
                    }
                }else{
                    return;
                }
            }
        },

        computed: {
            computedGST() {
                this.gstNumber = this.gstNumber.toUpperCase();
            },

            computedPAN() {
                this.panNumber = this.panNumber.toUpperCase();
            },

            pincodes(){
                return this.pitems
            },

            bankIFSCDetailsItems() {
                return this.bankDetails[0] && this.bankDetails[0].length > 0 ? this.bankDetails[0] : this.bankDetails
            },

            computedEffectiveDate(){
                return moment(this.effectiveDate).format('DD/MM/YYYY');
            },

            computedExpirationDate(){
                return this.expirationDate == null ? '' : moment(this.expirationDate).format('DD/MM/YYYY');
            },

            computedAuditDate(){
                return this.auditDate == null ? '' : moment(this.auditDate).format('DD/MM/YYYY');
            },

            computedAuditDueDate(){
                return this.auditDueDate == null ? '' : moment(this.auditDueDate).format('DD/MM/YYYY');
            },

            cancelledChequeRequired() {
                if(this.cancelledChequeRequiredStatus) {
                this.cancelledChequeRequiredStatus = this.bankTransfer && this.cancelledChequeFileData.length < 1 ? false : true
                return 'This field is required'
                } else {
                return ''
                }
            },

            addressMsgRequired() {
                if(this.addressRequiredStatus) {
                this.addressRequiredStatus = this.addressLine1 === '' || this.postalCode === '' || this.country === '' || this.state === '' || this.city === '' ? false : true
                return 'This field is required'
                } else {
                this.addressRequiredStatus = false
                return ''
                }
            },

            selectAllBrands () {
                return this.authorizedBrands.length === this.authorizedBrandOptions.length
            },

            selectSomeBrands () {
                return this.authorizedBrands.length > 0 && !this.selectAllBrands
            },

            icon () {
                if (this.selectAllBrands) return 'mdi-close-box'
                if (this.selectSomeBrands) return 'mdi-minus-box'
                return 'mdi-checkbox-blank-outline'
            },
            computedPortalAccess: {
                get() {
                    return this.portalAccess ? 'Yes' : 'No'
                },
                set(newVal) {
                    this.portalAccess = (newVal === 'Yes')
                }
            },
        }
    }
</script>

<style scoped>
    .containerStyle {
        margin: 0;
        padding: 0;
        max-width: 100%;
    }

    .viconfont{
        font-size: 26px;
    }
    .iconsize{
        font-size: 18px !important;
    }
    .addressbtn{
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 2px 2px #00000051;
        border: 0.5px solid #C1C8CC !important;
        text-transform: capitalize;
        justify-content: space-between;
    }
    .tableicons{
        font-size: 18px !important;
        color: #23B1A9 !important;
    }
    .radiobtn {
        background: #F7F7F7 0% 0% no-repeat padding-box;
        border: 0.5px solid #C1C8CC;
    }
    .iconstyle {
        margin-left: -12px;
        background-color: rgb(247, 247, 247);
        width: 45px;
        height: 38px;
        font-size: 20px !important;
        border: 0.3px solid rgb(232, 235, 236);
        opacity: 1;
    }
    .cancelCheque {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: inset 0px 2px 1px #00000052;
        border: 0.5px solid #C1C8CC;
        opacity: 1;
    }
    .addressPopUpStyle {
        background-color: #f7f7f7 !important;
        box-shadow: inset 0px 3px 4px 0px #00000047;
    }
    .addressPopUpHeaderStyle {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 5px 7px #00000029;
        opacity: 1;
        font-size: 14;
    }
    .descrptionSpan {
        width: 85%;
        margin-left: 53px;
        margin-top: 21px;
        margin-bottom: 14px !important;
        font-weight: bold;
        font-size: 16px;
    }
    .descrptionSpanAttach {
        width: 80%;
        margin-left: 53px;
        margin-top: 21px;
        margin-bottom: 14px !important;
        font-weight: bold;
        font-size: 16px;
    }
    .backgroundWhite{
        background: white !important;
        border: 0.5px solid #e4e0e0 !important;
    }
    .radiobox_border{
        border:0.5px solid rgb(206, 206, 206);
        height: 39px;
        background-color: #cacaca45;
    }
    .attachtxt{
        font-size: 12px;
        font-weight: 500;
    }
    .browsebtn{
        text-transform: capitalize;
        letter-spacing: 0.1px;
        text-align: left;
        font-weight: bold;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: inset 0px 2px 1px #00000052;
        border: 0.5px solid #C1C8CC;
        border-radius: 0;
        width:90%;
        height:40px !important;
    }
    .vcardTextTableBackground{
        background-color: #f7f7f7 !important;
        box-shadow: inset 0px 3px 4px 0px #00000047;
    }
    .tablebtn{
        background: #FFFFFF 0% 0% no-repeat padding-box !important;
        box-shadow: inset 0px 2px 1px #00000052 !important;
        border: 0.5px solid #C1C8CC;
        border-radius: 18px !important;
        height: 30px !important;
        text-transform: capitalize !important;
        justify-content: space-between;
    }
    .closeicon{
        font-size: 18px;
        color: #909699;
    }
</style>