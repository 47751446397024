<template>
  <v-snackbar
    top
    dark
    v-model="snackbar.visible"
    :timeout="snackbar.timeout"
    :color="snackbar.color"
    tile
    :multi-line="snackbar.multiline === true"
    class="pt-0">
    <v-layout justify-space-between align-center>
      {{ snackbar.text }}
      <v-btn tile dark small left @click="closeSnackbar" :color="snackbar.color" class="ml-16">Close</v-btn>
    </v-layout>
      
  </v-snackbar>
</template>
<script>
  import { mapMutations } from 'vuex'

  export default {
    mounted() {
      console.log('Color from store :  =>  ', this.$store.state.snackbar.color);
    },
    computed: {
      snackbar () {
        return this.$store.state.snackbar
      }
    },
    methods: {
      ...mapMutations(['closeSnackbar'])
    }
   }
</script>
