<template>
    <v-dialog v-model="showClaimDocuments" max-width="1200">
        <v-card>
            <v-container>
                <v-row>
                    <v-col class="d-flex align-left pl-1 justify-start">
                        <v-card-title>
                          <div>Claim Documents</div>
                        <v-spacer></v-spacer>
                      </v-card-title>
                    </v-col>
                <v-col class="d-flex align-right pl-1 justify-end">
                  <v-btn
                    dark
                    class="text-capitalize ml-2"
                    color="#E46A25"
                    depressed
                    @click="handleClose()"
                  >
                    <v-icon>close</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <div>
                <!-- <GenericViewDocument 
                    :microsServiceBaseUrl="commonModuleBaseUrl" 
                    :headers="headers"
                    :featureNumber="getFeatureNumber()"
                    :otherData="otherData"
                    :passToken="getToken()"
                /> -->
            </div>
        </v-container>
          </v-card>
    </v-dialog>
</template>
<script>
import { commonModuleBaseUrl, claimsModuleBaseURL } from '../common/API_Config';
// import GenericViewDocument from '@tataaig/genericviewdocument';
export default {
  name: "SiuClaimDocuments",
  data() {
    return {
        commonModuleBaseUrl: commonModuleBaseUrl,
        showClaimDocuments: true,
        headers:[
                {text: 'Sr. No.', value: 'serialNo',sortable: false},
                {text: 'User Name', value: 'userName',sortable: false},
                {text: 'Documents Type', value: 'documentType',sortable: false},
                {text: 'Document Name', value: 'documentName',sortable: false},
                {text: 'Date and Time', value: 'dateOfDoc',sortable: true},
                {text: 'Photocopy', value: 'photocopy',sortable: true},
                {text: 'View', value: 'action',sortable: false,width:'150'},
            ],
    otherData: {},
    }
},
props: ["siuWorkItemId"],
components: {
        // GenericViewDocument,
    },
async created() {
    console.log("LOG1", this.$route.params);
    this.otherData['_idReq'] = this.$route.params.claimId;
    this.otherData.registeredClaimId = this.$route.params.claimId;
    this.otherData.claimTypeFrClaimMainScr = this.$route.params.allData.claimType;
    this.otherData.tataAigEmp = this.$route.params.allData.tataAIGEmployee;
    this.otherData.DOCID = { claimId: this.$route.params.claimNumber,
                             inwardId: this.$route.params.allData.inwardId
                            }
    this.inwardIdRecived = this.$route.params.allData.inwardId;
    this.otherData.endPoints = {
            siteBaseUrl: claimsModuleBaseURL,
            cashless : 'claims-registration/pre-auth-inwards/get-claim-document-list',
            callTataAigAPI: 'claims-registration/claim-note/view-claim-notes/',
            getClaimRegistrationDetails: 'claims-registration/claims-details/get-claim-registration-detail/',
            tariffDocument: 'provider-management/provider/docs-by-provider/',
            getQueryDocs: 'investigation/query-management/queryresponse-by-claimid/',
        }
},
methods: {
    getFeatureNumber() {
        return this.$route.params.featureNumber;
    },
    getToken(){
        let token = this.$store.state.Auth && this.$store.state.Auth.user ? this.$store.state.Auth.user.signInUserSession.idToken.jwtToken : '';
        return token;
    },
  handleClose() {
    this.$emit('showHide', false);
  }
},
unmounted() {
    this.handleClose();
}
}
</script>