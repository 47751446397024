<template>
  <div>
    <v-container fluid>
      <v-layout row>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Proposer Name
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.proposerName"
              maxlength="100"
              counter="100"
              class="rounded-0"
              aria-required="true"
              :rules="[alphaNumeric(formData.proposerName, 'Proposer Name')]"
              disabled
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Premium Reciept Date
            </v-text>
            <v-menu
              ref="calender"
              :close-on-content-click="true"
              :nudge-right="40"
              transition="scale-transition"
              max-width="290px"
              min-width="290px"
              class="rounded-0"
            >
              <!-- :rules="typeOfEmployee !== 'Service ID'?[required()]:[()]" -->
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-bind="attrs"
                  label="Select.."
                  :value="computedDate('premiumRecieptDate')"
                  solo
                  class="rounded-0"
                  v-on="on"
                >
                  <div
                    slot="prepend-inner"
                    style="background-color: #f7f7f7; padding: 9px !important"
                  >
                    <v-icon>event</v-icon>
                  </div>
                </v-text-field>
              </template>
              <v-date-picker v-model="formData.premiumRecieptDate" no-title>
              </v-date-picker>
            </v-menu>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Premium Collection Mode
            </v-text>
            <v-select
              solo
              :items="premCollectionModeList"
              label="Enter..."
              v-model="formData.premiumCollectionMode"
              class="rounded-0"
              aria-required="true"
              :rules="[
                alphaNumeric(formData.premiumCollectionMode, 'Premium Collection Mode'),
              ]"
            >
            </v-select>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Policy Date Of Realization
            </v-text>
            <v-menu
              ref="calender"
              :close-on-content-click="true"
              :nudge-right="40"
              transition="scale-transition"
              max-width="290px"
              min-width="290px"
              class="rounded-0"
            >
              <!-- :rules="typeOfEmployee !== 'Service ID'?[required()]:[()]" -->
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-bind="attrs"
                  label="Select.."
                  :value="computedDate('policyDateOfRealization')"
                  solo
                  class="rounded-0"
                  v-on="on"
                >
                  <div
                    slot="prepend-inner"
                    style="background-color: #f7f7f7; padding: 9px !important"
                  >
                    <v-icon>event</v-icon>
                  </div>
                </v-text-field>
              </template>
              <v-date-picker v-model="formData.policyDateOfRealization" no-title>
              </v-date-picker>
            </v-menu>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Policy Collection Status
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.policyCollectionStatus"
              maxlength="20"
              counter="20"
              class="rounded-0"
              aria-required="true"
              readonly
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Premium Payer Details
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="formData.premiumPayerDetails"
              maxlength="200"
              counter="200"
              class="rounded-0"
              aria-required="true"
              :rules="[
                alphaNumeric(formData.premiumPayerDetails, 'Premium Payer Details'),
              ]"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-container>
    <InvestigationFooterCard :tabno="sno" :formdata="getFormData" :removeSaveButton="true"/>
  </div>
</template>
<script>
import InvestigationFooterCard from './InvestigationFooterCard.vue';
import { getClaimDetailsId } from '../apiservices/formData';
import moment from 'moment';
export default {
    name: 'PayerDetails',
    data() {
        return {
            premCollectionModeList:["Cheque","Cash","NEFT","Online"],
            sno: 3,
            rules: {
                mandatoryrule: [(v) => !!v || this.validationmessage],
            },
            validationmessage: "This field is required",
            formData:{
              proposerName: '',// Anurag
              premiumCollectionMode: '', // Anurag
              policyCollectionStatus: '',// Anurag
              premiumPayerDetails: '',// Anurag,
              premiumRecieptDate: '', // Anurag,
              policyDateOfRealization: '' // Anurag
            }
        }
    },
    props: ['prepoulatedData'],
    created() {
      this.getPayerDetails();
    },
    components: {
      InvestigationFooterCard 
    },
    computed:{
      getFormData() {
        return this.$data;
      }
    },
    methods: {
      async getPayerDetails(){
        try{
          let res = await getClaimDetailsId(this.$route.params.claimId);
          this.formData.policyCollectionStatus = res.data.claimsDetail.policyStatus;
          this.formData.proposerName = res.data.claimsDetail.proposerName;
          this.formData.premiumPayerDetails = res.data.claimsDetail.proposerName;
          // res = res.data[0];
          //   Object.keys(this.$data.formData).forEach(item => {
          //     this.formData[item] = res[item];
          //   });
        } catch(e){
          this.showToast(e.response?.data?.msg || e.response?.data  || e.response || e, this.TOST().ERROR);
        }
      },
    // getClaimRegistrationDetails() {
    //   axios.get('https://claimsdrive-health.salesdrive.app/claims-registration/claims-details/get-claim-registration-detail/63a43bc57d929019cdf14328',
    //   {headers:{
    //           "Content-Type": "application/json",
    //           "Authorization": this.$store.state.Auth && this.$store.state.Auth.user ? this.$store.state.Auth.user.signInUserSession.idToken.jwtToken : ''//this.$store.state.Auth.user.signInUserSession.idToken.jwtToken
    //         }}
    //   )
    //    .then((data) => { 
    //     console.log('shivam', data.data.data.claimsDetail);
    //     let claimDetail = data.data.data.claimsDetail;
    //     this.claimId = claimDetail.claimNumber;
    //     this.policyNumber = claimDetail.policyNumber;
    //     this.claimType = claimDetail.claimType;
    //     this.claimStatus = claimDetail.claimStatus;
    //     this.claimSubStatus = claimDetail.claimSubStatus;
    //     this.claimRegistrationDate = claimDetail.claimRegisteredDate;
    //     this.reservedAmount = claimDetail.reservedAmount;
    //     this.productName = claimDetail.productName;
    //     this.policyStatus = claimDetail.policyStatus;
    //     this.policyType = claimDetail.policyType;
    //     this.policyStartDate = claimDetail.policyStartDate;
    //     this.policyEndDate = claimDetail.policyEndDate;
    //     this.nameOfCorporate = claimDetail.nameOfCorporate;
    //     this.employeeId = claimDetail.employeeID;
    //     this.memberId = claimDetail.memberId;
    //     this.memberStatus = claimDetail.memberStatus;
    //     this.memberFirstName = claimDetail.firstName;
    //     this.memberMiddleName = claimDetail.middleName;
    //     this.memberLastName = claimDetail.lastName ? claimDetail.lastName : '' ;
    //     this.memberDateOfBirth = claimDetail.dateOfBirth;
    //     this.memberGender =  claimDetail.gender;
    //     this.relationshipToPrimaryInsured = claimDetail.relationshipToPrimaryInsured;
    //     this.memberOccupation = claimDetail.occupation;
    //     this.memberJoiningDate = claimDetail.joiningDate;
    //     this.memberLeavingDate = claimDetail.leavingDate;
    //     this.benefitClaimed = data.data.data.patientAdmissionDetails.benefitClaimed;
    //    }).catch((err) => {
    //     console.log(err);
    //    })
    // },
    computedDate(name) {
      return this.formData[name] ? moment(this.formData[name]).format("DD/MM/YYYY") : "";
    },
    goToPreviousTab() {
      this.sno--;
      let btnNumber = "myBtn" + this.sno;
      const elem = this.$refs[btnNumber];
      elem[0].$el.click();
    },
    goToNextTab() {
      this.sno++;
      let btnNumber = "myBtn" + this.sno;
      const elem = this.$refs[btnNumber];
      elem[0].$el.click();
    },
    switchTabs(sno, name) {
      console.log('sno', sno, name);
      this.sno = sno;
      this.tabName = name;
    },
    submit() {
      this.isAlertView = true;
    },
    accept() {
      this.isAlertView = true;
    },
    saveData() {},
    alphaNumeric(fieldName, name, mandatory=true) {
      console.log('fieldName', fieldName, name);
      if (fieldName?.length > 0) {
        return (v) => /^[a-zA-Z0-9]+$/.test(v) || "Enter valid " + name;
      }
    },
    numericOnly() {
      return (v) => /^[0-9 ]+$/.test(v) || `Only Numbers are allowed.`;
    },
    onSubmit() {},
    browse() {},
    selectFile() {},
  },
}
</script>