<template>
  <div>
    <v-container fluid >
      <v-layout row>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Claim Number
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="basicDetails.claimNumber"
              maxlength="20"
              counter="20"
              class="rounded-0"
              aria-required="true"
              disabled
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Policy Number
            </v-text>
            <v-text-field
              disbaled
              solo
              label="Enter..."
              v-model="basicDetails.policyNumber"
              maxlength="30"
              counter="30"
              class="rounded-0"
              aria-required="true"
              disabled
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Claim Type
            </v-text>
            <v-text-field
              label="Enter..."
              v-model="basicDetails.claimType"
              :rules="rules.mandatoryrule"
              solo
              class="rounded-0"
              disabled
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Investigator Name
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="basicDetails.investigatorName"
              maxlength="30"
              counter="30"
              class="rounded-0"
              disabled
              aria-required="true"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Investigator ID
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="basicDetails.investigatorID"
              maxlength="50"
              counter="50"
              class="rounded-0"
              disabled
              aria-required="true"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Claimed Amount
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="basicDetails.claimedAmount"
              maxlength="20"
              counter="20"
              class="rounded-0"
              disabled
              aria-required="true"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <!-- <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Claim Reserve Amount
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="reservedAmount"
              maxlength="20"
              counter="20"
              class="rounded-0"
              disabled
              aria-required="true"
              :rules="[
                alphaNumeric(reservedAmount, 'Claimed Reserve Amount'),
              ]"
            >
            </v-text-field>
          </v-layout>
        </v-flex> -->
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Loss Date
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="basicDetails.dateOfAdmission"
              class="rounded-0"
              disabled
              aria-required="true"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Claim Registration Date
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="basicDetails.claimRegistrationDate"
              class="rounded-0"
              disabled
              aria-required="true"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Date Of Referal
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="basicDetails.dateOfReferal"
              class="rounded-0"
              disabled
              aria-required="true"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Date Of Appointment
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="basicDetails.dateOfAppointment"
              class="rounded-0"
              disabled
              aria-required="true"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <!-- To be added later -->
        <!-- <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Intimation Date
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="basicDetails.intimationDateTime"
              class="rounded-0"
              disabled
              aria-required="true"
            >
            </v-text-field>
          </v-layout>
        </v-flex> -->
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Loss State
            </v-text>
            <v-text-field
              label="Enter..."
              v-model="basicDetails.state"
              solo
              class="rounded-0"
              disabled
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Loss City
            </v-text>
            <v-text-field
              v-model="basicDetails.cityTownVillage"
              solo
              class="rounded-0"
               label="Enter..."
              disabled
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Loss PIN Code
            </v-text>
            <v-text-field
              v-model="basicDetails.pinCode"
              label="Enter..."
              solo
              class="rounded-0"
              disabled
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              SIU Zone
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="basicDetails.siuZone"
              maxlength="20"
              counter="20"
              class="rounded-0"
              disabled
              aria-required="true"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Claim Status
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="basicDetails.claimStatus"
              maxlength="100"
              counter="100"
              class="rounded-0"
              disabled
              aria-required="true"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
        <v-flex md2 sm3 xs6 :align-self-end="true">
          <v-layout column class="cust_usrsrch mx-2 mt-2">
            <v-text class="font-weight-bold cstm-clr-pln-usrsrch">
              Claim Sub Status
            </v-text>
            <v-text-field
              solo
              label="Enter..."
              v-model="basicDetails.claimSubStatus"
              maxlength="100"
              counter="100"
              class="rounded-0"
              disabled
              aria-required="true"
            >
            </v-text-field>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-container>
    <InvestigationFooterCard 
      :componentName="'basicDetails'"
      :siuWorkItemId="siuWorkItemId"
      :tabFormDataId="tabFormDataId" :tabno="sno" :formdata="getFormData"
      :removeSaveButton="true" />
  </div>
</template>
<script>
 import InvestigationFooterCard from './InvestigationFooterCard.vue';
 import { getClaimDetails, getClaimDetailsId } from '../apiservices/formData';
 import { getWoritemDetailsById } from "../apiservices/Workitem";
 import {getPrePopulatedData, getAllPinCodes} from '../apiservices/Investigation';
 import axios from 'axios'; 
 import moment from 'moment';
 export default{
  name: 'BasicDetails',
  components: {
      InvestigationFooterCard 
  },
  props: ['prepoulatedData', "siuWorkItemId", "tabFormDataId"],
  data() {
    return {
      menu2:false,
      documentsItems: [{
        sno: "1", docName: "Claim Form"
      }],
      validationmessage: "This field is required",
      claimTypeList: ["Cashless", "Reimbursement", "Travel"],
      admissionList: ['c'],
      rules: {
        mandatoryrule: [(v) => !!v || this.validationmessage],
      },
      dropDownCommonData: ["Yes", "No"],
      isAlertView: false,
      tab: 1,
      temp: new Date(Date.now() - new Date().getTimezoneOffset()),
      sno: 1,
      tabName: "",
      items: [],
      basicDetails :{
      intimationDateTime:'',
      claimNumber: "",
      policyNumber: "",
      claimType: "",
      investigatorName: "",// from work item
      investigatorID: "",// from item
      claimedAmount: "",
      reservedAmount: "",
      dateOfAdmission: "",
      claimRegistrationDate: "",
      dateOfReferal: "",// from work item
      dateOfAppointment: "", // from work item
      dateOfFinalReport: "",// from work item
      state: "",
      cityTownVillage: "",
      pinCode: "",
      // examinerName: "",
      siuStateManager: "", // from work item
      siuZone: "", // from work item
      claimStatus: "",
      claimSubStatus: "",
      //-----------------------------------//
      lossStatesList: ["A"],
      lossDate: "",
    },
      textRuleTime: [
                v => !!v || 'This Field is Required',
                v => /^([0-9]{2}\:)[0-9]{2}/g.test(v) || 'Enter valid Time',
            ],
      required() {
        return (v) => (v && v.length > 0) || "This Field is Required";
      },
    };
  },
  created() {
    this.getClaimRegistrationDetails();
    this.getInvestigationDetails(this.$route.params.allData.siuInvestigationTaskID);
    this.viewMoreDetails(this.$route.params.allData._id)
    this.basicDetails.siuZone = this.$route.params.allData.zone ?? '';
    this.basicDetails.dateOfAppointment = moment(this.$route.params.allData.createdAt).format("DD/MM/YYYY") ?? '';
    // this.getClaimDetails();
  },
  computed: {
    emailRules2(v) {
      if (v.length !== 0) {
        if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,6})+$/.test(v)) {
          return "E-mail must be valid";
        }
      }
    },
    getFormData() {
      return { basicDetails: {...this.$data.basicDetails, isTabSelected: true}};
    }
  },
  methods:{
    alphaNumeric(fieldName, name, mandatory=true) {
      console.log('fieldName', fieldName, name);
      if (fieldName.length > 0) {
        return (v) => /^[a-zA-Z0-9]+$/.test(v) || "Enter valid " + name;
      }
    },
    computedDate(name) {
      return this[name] ? moment(this[name]).format("DD/MM/YYYY") : "";
    },
    async getAddressData(val){
      if(val){
      let res = await getAllPinCodes(val)
     this.basicDetails.cityTownVillage = res[0].cityDistrictName ?? '';
     this.basicDetails.state = res[0].stateName ?? '';
      }
    },
    async viewMoreDetails(id) {
      try {
        let res = await getWoritemDetailsById(id);
        let workItemDetail = res.data.data[0];
        this.basicDetails.claimedAmount = workItemDetail.claimedAmount ?? '';
        this.basicDetails.dateOfReferal = moment(workItemDetail.referredDateAndTime).format("DD/MM/YYYY") ?? '';

      } catch (e) {
        this.showToast(e.response?.data?.msg || e.response?.data  || e.response || e, this.TOST().ERROR);
      }
    },
    async getInvestigationDetails(id){
      let res = await getPrePopulatedData(id);
      this.basicDetails.investigatorName = res.siuUserDetails.investigatorDetails.name ?? '';
      this.basicDetails.investigatorID = res.siuUserDetails.investigatorDetails.ntidUserId ?? '';
    },
    async getClaimRegistrationDetails() {
      let data;
      try{
        data = await getClaimDetailsId(this.$route.params.claimId);
      } catch(e) {
        this.showToast(e.response?.data?.msg || e.response?.data  || e.response || e, this.TOST().ERROR);
        try{
          data = await getClaimDetails(this.$route.params.claimNumber, this.$route.params.memberId, this.$route.params.policyNumber, this.$route.params.featureNumber);
        } catch(e){
          this.showToast(e.response?.data?.msg || e.response?.data  || e.response || e, this.TOST().ERROR);
        }
      }
        let claimDetail = data.data.claimsDetail;
        let allDetail = data.data;
        this.basicDetails.claimNumber = claimDetail.claimNumber + claimDetail.featureNumber;
        this.basicDetails.policyNumber = claimDetail.policyNumber;
        this.basicDetails.claimType = claimDetail.claimType;
        this.basicDetails.claimStatus = claimDetail.claimStatus;
        this.basicDetails.claimSubStatus = claimDetail.claimSubStatus;
        this.basicDetails.reservedAmount = claimDetail.reservedAmount;
        this.basicDetails.pinCode = allDetail.providerDetails.pinPostalCode ?? null;
        this.basicDetails.dateOfAdmission = moment(claimDetail.dateOfAdmission).format("DD/MM/YYYY") ?? '';
        if(claimDetail.hasOwnProperty("claimRegisteredDate")){
          this.basicDetails.claimRegistrationDate = moment(claimDetail.claimRegisteredDate).format("DD/MM/YYYY") ?? '';
        }else{
          this.basicDetails.claimRegistrationDate = moment(allDetail.patientAdmissionDetails.claimRegisteredDate).format("DD/MM/YYYY") ?? '';
        }
        this.getAddressData(this.basicDetails.pinCode)
    },
  }
 }
</script>