<template>

<div>
    <!-- <ProviderDetails/> -->
    <v-form v-model="icuvalid" ref="otForm">
    <v-card tile flat>
    <div class="px-4 pt-4">
                    <v-flex xs3>
                        <v-layout column class="cust_pln_Ot mx-2 mb-4">
                            <p class="font-weight-bold cstm-clr-pln-Ot">
                                Discount Percentage
                            </p>
                            <v-text-field
                                solo
                                label="Enter..."
                                v-model.trim="dscntPercntg"
                                class="rounded-0"
                                :maxlength="max05"
                                :disabled="fromview"
                                :rules="[dscntPrcnt]"
                            >
                            </v-text-field>
                        </v-layout>
                    </v-flex>
               </div>
               </v-card>

     <v-card class="mt-3 pa-3" color="#F7F7F7">
        <label class="black--text ml-4">Packages Charges</label></v-card>
        <v-card color="#F7F7F7" class="pb-5 overflow-y-auto">
        <!-- <v-data-table              
                    :headers="headers"
                    :items="statusData"
                    :page.sync="page"
                    :items-per-page="statusItemsPerPage"
                    hide-default-footer
                    dense
                    @page-count="pageCount = $event"
                    height="195px"
                   
                ></v-data-table> -->
                <tableis v-if="shwDtaTbl===true"
                            :tabledata="{
                                maxlength:{ m1:100, m2:200,  },
                                tabledata:addCategory,
                                headersdata:headersNew,
                                dd:medConsumablesLvl4,
                                tariffshw:false,
                                roomconfig:temproomtype,
                                req:true,
                                radio:true,
                                inc:false,
                                del_endpoint:'Packages Charges',
                                url:'tariff/get-package-charges'
                            }" 
                            ref="PackageCharges" 
                            v-model="currentRadio"
                            @radiodataDel="afterDelLen"
                            @radiodataUnCheck="UnchkData"
                            @radioIncData="radiodata"
                            @save="saveMedConsmechrgs"
                ></tableis>
                <!-- <div class="text-center">
    <v-pagination color="#152F38" class="mt-3 float-right"
      v-model="page"
      :length="4"
      prev-icon="mdi-arrow-left"
      next-icon="mdi-arrow-right"
      border-radius="0px"
      square
    ></v-pagination>
  </div> -->
     </v-card>

     <v-card color="#F7F7F7" class="mt-8 pa-5 overflow-y-auto" v-if="!fromview">
         <v-row class="mt-1 mr-1 float-right">
                <!-- <v-btn :disabled="!isDisablePackage" @click="() => {errDialogCloseBx = true}" tile dark color="#23B1A9" class="text-capitalize mr-6" small>
                     <v-icon dark color="" small >mdi-plus-circle</v-icon>
                     <v-divider vertical class="ml-2 mr-2"></v-divider>Configure Multi Package Discount
                 </v-btn> -->
                 <v-btn :dark="isDisablePackage" :disabled="!isDisablePackage" tile style="background-color:#23B1A9" class="text-capitalize px-3 mx-2" small @click="() => {errDialogCloseBx = true}">
                     <v-icon dark small>settings</v-icon> <v-divider vertical class="mx-2"></v-divider>Configure Multi Package Discount</v-btn>
                 <v-btn @click="save_medConsme_ctgry()" tile dark color="#152F38" class="text-capitalize px-3 mx-2" small>
                     <v-icon color="" small >mdi-plus-circle</v-icon>
                     <v-divider vertical class="mx-2"></v-divider>Add Category
                 </v-btn>
                 <!-- <v-btn v-show="isDisable" @click="dialogFunc()" tile dark color="#23B1A9" class="text-capitalize mr-6" small>
                     <v-icon color="" small >edit</v-icon>
                     <v-divider vertical class="ml-2 mr-2"></v-divider>Tag/Add Inclusion(s)
                 </v-btn> -->
                 <v-btn :dark="isDisable" :disabled="!isDisable" tile style="background-color:#23B1A9"
                 class="text-capitalize px-3 mx-2" small @click="dialogFunc()">
                     <v-icon dark small>edit</v-icon> <v-divider vertical class="mx-2"></v-divider>Tag/Add Inclusion(s)</v-btn>
                 <v-btn @click="save_ot_confg()" tile dark color="#23B1A9" class="text-capitalize px-3 mx-2" small>
                     <v-icon color="" small >check_circle</v-icon>
                     <v-divider vertical class="mx-2"></v-divider>Save
                 </v-btn>
                 <v-btn @click="reviewCharges" tile dark color="#152F38" class="text-capitalize px-3 mx-2" small>
                     <v-icon color="" small >check_circle</v-icon>
                     <v-divider vertical class="mx-2"></v-divider>Review
                 </v-btn>
                 </v-row>
     </v-card>
</v-form>

    <!---Tag inclusions popup---->
    <!-- <v-layout>      -->
    <v-dialog v-model="otherdialog" persistent max-width="820px">
        <v-toolbar style="height: 50px" class="px-5">
           <v-toolbar-title style="font-size: 16px">Tag Inclusions</v-toolbar-title>
           <v-spacer></v-spacer>     
           <v-divider class="mr-4" vertical></v-divider> 
               <v-icon @click="() => {otherdialog = false}">close</v-icon>     
        </v-toolbar>

        <v-card max-height="550px"  class="overflow-x-auto pa-5 pb-6" flat color="#F7F7F7">
          <v-form v-model="levelfrom" ref="inclusionForm" class="p-5" color="#F7F7F7">
              <div class="mt-1 ml-8">
              <v-row>
              <v-flex xs4 class="mr-7">
                  <label class="mb-7">Level 4</label>
                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs"  v-on="on">
                            <v-text-field disabled class="mt-2" v-model="level5" label="Enter..." solo ></v-text-field>
                        </div>
                    </template>
                        <span>{{level5}}</span>
                    </v-tooltip>
              </v-flex>
              
              <v-flex xs4>
                  <label>Level 5</label>
                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs"  v-on="on">
                        <v-text-field disabled class="mt-2" v-model="level4Inc" label="Enter..." solo ></v-text-field>
                        </div>
                    </template>
                        <span>{{level4Inc}}</span>
                    </v-tooltip>
              </v-flex></v-row>
              <v-divider class="mb-7"></v-divider>

              <v-row style="align-items: center;" >
                  <v-flex xs4 class="mr-7">
                    <label class="mb-7">Level 1*</label>
                    <v-tooltip left :disabled="selectField1.length ===0">
                    <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs"  v-on="on">
                    <v-select 
                      @change="refreshothers(0)"
                      v-model="selectField1"
                      :rules="[v => !!v || 'This field is required']"
                      :items="selectItems1"
                      label="Select"
                      solo
                      >
                    </v-select>
                    </div>
                    </template>
                        <span>{{selectField1}}</span>
                    </v-tooltip>

                  </v-flex>
                  <v-flex xs4 class="mr-7">
                      <label class="mb-7">Level 2</label>
                      <!-- <pre> {{itemspin}} </pre> -->
                    <v-tooltip left :disabled="activeonly.length ===0">
                    <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs"  v-on="on">
                    <v-autocomplete
                        v-model="activeonly"
                        :items="itemspin"
                        color="white"
                        hide-no-data
                        hide-selected
                        item-text="pin"
                        item-value="pin"
                        placeholder="Level 2"
                        solo
                      ></v-autocomplete>
                        </div>
                    </template>
                        <span>{{activeonly}}</span>
                    </v-tooltip>
                      <!-- <v-text-field
                           @input="searchlevel2"
                            solo
                            dense
                            v-model="activeonly"
                            @click="tgdd = !tgdd"
                        >
                        </v-text-field> -->
                        <!-- <v-card 

                            v-if="tgdd"  class="pa-2 ripple"  
                            style="position:absolute; top:201px; z-index:10 !important; width:250px; max-height:205px; overflow-y:scroll;" >
                            <div  v-for="(data, index) in itemspin" :key="index"  >
                                          
                                <v-layout style="align-items:center; padding: 5px 0px;" @click="onClickOutside(data)" >

                                <v-list-tile-content>
                                <v-list-tile-title>
                                   
                                    {{data}}
                                </v-list-tile-title>
                                </v-list-tile-content>
                         </v-layout>
                       
                   
                  
                            </div>
                          </v-card> -->
                    <!-- <v-select
                        v-model="selectField2"
                        :items="[1,2,3,4,5]"
                       
                        color="red"
                       
                        label="Public APIs"
                        return-object
                    >
                      <v-text-field  class="mt-2" v-model="selectField2" label="Enter..." solo ></v-text-field>
                    </v-select> -->
                    <!-- <vue-autosuggest 
                                class="beauty"
                                :suggestions="[{data:['Frodo', 'Samwise', 'Gandalf', 'Galadriel', 'Faramir', 'Éowyn']}]"
                                :input-props="{id:'autosuggest__input', placeholder:'Search Level 2'}"
                                @input="search"
                                @selected="selectHandler"
                            >
                            
                                    
                                    <template slot-scope="{suggestion}">
                                        <span class="my-suggestion-item">{{suggestion.item}}</span>
                                    </template>
                                    </vue-autosuggest> -->
                                    <!-- <vue-autosuggest :suggestions="filteredOptions" :on-selected="onSelected" :limit="10" :input-props="inputProps"></vue-autosuggest> -->
                      <!-- <v-autocomplete class="mt-2" :items="itemspin"
                      :search-input.sync="searchlevel2"
                   
                      item-text="level2"
                      item-value="_id"
                      v-model="selectField2" label="Select"  solo></v-autocomplete> -->
                  </v-flex>
              </v-row>
              <v-row class="mt-4" style="align-items: center;">
                  <v-flex xs4 class="mr-7">
                      <label class="mb-7">Level 3 </label>
                      <!-- <pre>{{lvl3only.length}}</pre> -->
                      <!-- :disabled="lvl3only.length === 0" -->
                    <v-tooltip left :disabled="lvl3only.length === 0" >
                    <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs"  v-on="on">
                    <v-autocomplete
                        v-model="lvl3only"
                        :items="level3data"
                        color="white"
                        hide-no-data
                        hide-selected
                        item-text="level3"
                        item-value="_id"
                        placeholder="Level 3"
                        solo
                    ></v-autocomplete>
                    </div>
                    </template>
                        <span>{{lvl3only}}</span>
                    </v-tooltip>
                        <!-- <v-text-field
                            @input="searchlevel3"
                            solo
                            dense
                            v-model="lvl3only.level3"
                            @click="lvl3tgdd = !lvl3tgdd"
                        >
                        </v-text-field>
                      
                        <v-card 
                           
                            v-if="lvl3tgdd"  class="pa-2 ripple"  
                            style="position:absolute; top:290px; z-index:10 !important; width:251px; max-height:205px; overflow-y:scroll;" >
                            <div  v-for="(data, index) in level3data" :key="index"  >
                                         
                         <v-layout style="align-items:center; padding: 5px 0px;" @click="levelthreeset(data)" >
                                <v-list-tile-content>
                                    <v-list-tile-title>
                                      
                                        {{data.level3}}
                                    </v-list-tile-title>
                                </v-list-tile-content>
                         </v-layout>
                       
                            </div>
                          </v-card> -->
                      <!-- <v-autocomplete class="mt-2"
                       @input="searchlevel(selectField3,'lvl3')"
                      :items="selectItems3"
                      v-model="selectField3"
                      label="Select"
                      solo></v-autocomplete> -->
                  </v-flex>
                  <v-flex xs4 class="mr-7">
                      <label class="mb-7">Level 4</label>
                        <v-tooltip left :disabled="lvl4only.length ===0">
                        <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs"  v-on="on">
                        <v-autocomplete
                            v-model="lvl4only"
                            :items="level4data"
                            color="white"
                            hide-no-data
                            hide-selected
                            item-text="level4"
                            item-value="_id"
                            placeholder="Level 4"
                            solo
                        ></v-autocomplete>
                        </div>
                        </template>
                            <span >{{lvl4only}}</span>
                        </v-tooltip>
                        <!-- <v-text-field
                            @input="searchlevel4"
                            solo
                            dense
                            v-model="lvl4only.level4"
                            @click="lvl4tgdd = !lvl4tgdd"
                        >
                        </v-text-field>
                       
                        <v-card 
                           
                            v-if="lvl4tgdd"  class="pa-2 ripple"  
                            style="position:absolute; top:290px; z-index:10 !important; width:251px; max-height:205px; overflow-y:scroll;" >
                            <div  v-for="(data, index) in level4data" :key="index"  >
                                         
                         <v-layout style="align-items:center; padding: 5px 0px;" @click="levelfourset(data)" >
                                <v-list-tile-content>
                                    <v-list-tile-title>
                                        {{data.level4}}
                                    </v-list-tile-title>
                                </v-list-tile-content>
                         </v-layout>
                   
                            </div>
                          </v-card> -->
                  </v-flex>
              </v-row>
              <v-row>
                  <v-flex xs4 class="mr-5 mb-3">
                      <label>Units</label>
                      <v-text-field class="mt-2" :rules="[Numeric3]" maxlength="5" v-model="units" label="Enter..." solo ></v-text-field>
                  </v-flex>
              </v-row>

              </div>


          <label class="ml-5">Inclusions</label>
          
         
          <v-data-table              
                    :headers="headersNew2"
                    :items="statusData2"
                    :page.sync="page"
                    :items-per-page="statusItemsPerPage"
                    hide-default-footer
                    dense
                    @page-count="pageCount = $event"
                    height="195px"
                    class="ml-5 mr-5 mt-4"
                    show-select
                    :single-select="true"
                    item-key="_id"
                    v-model="currentInclsn"
                    @input="delInclusionInput(currentInclsn)"
                >
                
                <!-- <template v-slot:item.bulkUploadType="{ item }">
                    <div >{{ item.bulkUploadType | checkdata }}</div>
                </template>
                <template v-slot:item.dateOfUpload="{ item }">
                    <div >{{ item.dateOfUpload| checkdata |formatDate}}</div>
                </template>
                <template v-slot:item.FileName="{ item }">
                    <div >{{ item.fileName | checkdata}}</div>
                </template>
                <template v-slot:item.noOfEntries="{ item }">
                    <div >{{ item.noOfEntries| checkdata }}</div>
                </template>
                <template v-slot:item.noOfEntriesProcessed="{ item }">
                    <div >{{ item.noOfEntriesProcessed | checkdata}}</div>
                </template>
                <template v-slot:item.noOfEntriesFailed="{ item }">
                    <div >{{ item.noOfEntriesFailed | checkdata}}</div>
                </template> -->
                    <!-- <template v-slot:item.fileLink="{ item }" >
                         <v-btn color="#1E9690" @click="dwnldFaildRcrds(item.fileLink)" icon outlined dark small class= "my-1">
                            <v-icon dark small>get_app</v-icon>
                        </v-btn>
                    </template> -->
         <!---- ---------------------------------------------------- -->
                     <!-- <v-flex xs3>
                    <v-layout column class="cust_pr cust-rmvpd-pr mx-2 ">
                        <p class="font-weight-bold cstm-clr-pr">
                            E-mail
                        </p>
                        <v-text-field
                            solo
                            label="Enter..."
                            prepend-inner-icon="mail"
                            v-model="email"
                            class="rounded-0"
                            :error-messages="emailErrors"
                            @input="$v.email.$touch()"
                            @blur="$v.email.$touch()"
                            maxlength=50
                            counter="50">
                             <div slot="prepend-inner" style="background-color:#F7F7F7;padding: 9px !important"><v-icon dense>mail</v-icon></div>
                        </v-text-field>
                    </v-layout>
                </v-flex> -->
                   
                </v-data-table>
                    <!-- <span>{{buttonstatus}} | selected table => {{currentInclsn}}</span> -->
                <v-row class="ml-3 mt-8">
                    <v-btn @click="otherdialog = false" tile dark color="#E46A25" class="text-capitalize px-3 mx-2" small>
                         <v-icon color="" small >check_circle</v-icon>
                         <v-divider vertical class="mx-2"></v-divider>Cancel
                     </v-btn>
                     <v-btn :dark="!dis_add" :disabled="dis_add" @click="addInclusion()" tile style="background-color:#152F38" class="text-capitalize px-3 mx-2" small>
                         <v-icon color="" small >check_circle</v-icon>
                         <v-divider vertical class="mx-2"></v-divider>Add Inclusion
                     </v-btn>
                      <v-btn  :dark="!buttonstatus"  :disabled="buttonstatus"  @click="prepopulate" tile  style="background-color:#23B1A9" class="text-capitalize px-3 mx-2" small>
                         <v-icon color="" small >edit</v-icon>
                         <v-divider vertical class="mx-2"></v-divider>Edit Inclusion
                     </v-btn> 
                     <!-- dd99 -->
                      <v-btn  :dark="!buttonstatus"   :disabled="buttonstatus"
                               @click="delInclusion(currentInclsn)" tile  style="background-color:#D1121B" class="text-capitalize px-3 mx-2" small>
                         <v-icon color="" small >delete</v-icon>
                         <v-divider vertical class="mx-2"></v-divider>Delete Inclusion
                     </v-btn> 
                     <v-btn @click="saveinc" tile dark color="#23B1A9" class="text-capitalize px-3 mx-2" small>
                         <v-icon color="" small >check_circle</v-icon>
                         <v-divider vertical class="mx-2"></v-divider>Save
                     </v-btn>
                     <!-- <v-btn @click="saveinc" >save now</v-btn> -->
              </v-row>
              </v-form>

                </v-card>
    </v-dialog>
<!-- </v-layout> -->


<!-----Package discount popup --->
<v-layout>     
    <v-dialog v-model="errDialogCloseBx" persistent max-width="820px">

        <v-toolbar style="height: 50px">
      <v-toolbar-title style="font-size: 16px" class="pl-3">Configure Multi Package Discount</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-divider class="mr-4" vertical></v-divider>     
          <v-icon @click="() => {errDialogCloseBx = false}">close</v-icon>     
  </v-toolbar>

    <v-card max-height="500px" v-scroll.self="onScroll" class="overflow-x-auto pa-8" color="#F7F7F7">
      <v-form ref="discountForm" class="pl-2 pr-2 bg-grey">
    <v-card color="#FFFFFF" class="pa-5">
      
          <div class="mb-1">
              <h4 class="text-uppercase mb-3">Discount for 2 package selection</h4>
              <v-flex xs5 class="mr-7">
                  <label class="mb-7">Second Package Discount %</label>
                  <v-text-field :maxlength="max04" :rules="[Numeric2(level22),Numeric5(level22)]" class="mt-2" v-model="level22" label="Enter..." solo></v-text-field>
              </v-flex>
          </div>

          <v-divider class="mb-7"></v-divider>
  
          <div class="mb-1">
          <h4 class="text-uppercase mb-3">Discount for 3 package selection</h4>
          <v-row class="ml-1">
              <v-flex xs5 class="mr-7">
                  <label class="mb-7">Second Package Discount %</label>
                  <v-text-field :maxlength="max04" :rules="[Numeric2(level32),Numeric5(level32)]" class="mt-2" v-model="level32" label="Enter..." solo ></v-text-field>
              </v-flex>
              <v-flex xs5>
                  <label>Third Package Discount %</label>
                  <v-text-field :maxlength="max04" :rules="[Numeric2(level33),Numeric5(level33)]" class="mt-2" v-model="level33" label="Enter..." solo ></v-text-field>
              </v-flex>
          </v-row></div>

          <v-divider class="mb-7"></v-divider>

          <div>
          <h4 class="text-uppercase mb-3">Discount for 4 package selection</h4>
          <v-row class="ml-1">
              <v-flex xs5 class="mr-7">
                  <label class="mb-7">Second Package Discount %</label>
                  <v-text-field :maxlength="max04" :rules="[Numeric2(level42),Numeric5(level42)]" class="mt-2" v-model="level42" label="Enter..." solo ></v-text-field>
              </v-flex>
              <v-flex xs5>
                  <label>Third Package Discount %</label>
                  <v-text-field :maxlength="max04" :rules="[Numeric2(level43),Numeric5(level43)]" class="mt-2" v-model="level43" label="Enter..." solo ></v-text-field>
              </v-flex>
          </v-row>
          <v-flex xs5 class="ml-1">
                  <label>Fourth Package Discount %</label>
                  <v-text-field :maxlength="max04" :rules="[Numeric2(level44),Numeric5(level44)]" class="mt-2" v-model="level44" label="Enter..." solo ></v-text-field>
              </v-flex>
          </div>

     
   
      </v-card>

       <v-row class="ml-3 mt-2 pb-5 float-right">
  <v-btn @click="savePacDiscnt" tile dark color="#23B1A9" class="text-capitalize px-3 mx-2" small>
       <v-icon color="" small >check_circle</v-icon>
       <v-divider vertical class="ml-2 mr-2"></v-divider>Save
   </v-btn>
   <v-btn @click="errDialogClose" tile dark color="#152F38" class="text-capitalize px-3 mx-2" small>
       <v-icon color="" small >mdi-arrow-left</v-icon>
       <v-divider vertical class="ml-2 mr-2"></v-divider>Return
   </v-btn>
 
   </v-row> 
    

 </v-form>
      </v-card>
    </v-dialog>
    </v-layout>


</div>

  
</template>

<script>
import ProviderDetails from '../../component/Provider-Name_ID';
import tableis from '../CreateNewTariff/data_table';

// import { VueAutosuggest } from 'vue-autosuggest';

// import {helpers} from 'vuelidate/lib/validators';
// const nameSpaces = helpers.regex('nameSpaces', /^[1-0]/);

export default {
   components: {ProviderDetails, 
   tableis, 
//    VueAutosuggest
   }, 

//    validations: {nameSpaces},

    data(){
        return{
           
            radioDataObj: {},
            fromview: false,
            disablePackageArr: [],
            packageDiscData: [],
            dis_add:false,
            softedit:false,
            levelfrom:false,
            errDialogCloseBx: false,
            activeonly:"",
            lvl3only:"",
            lvl4only:"",
            tgdd:false,
            lvl3tgdd:false,
            lvl4tgdd:false,

             selected: '',
        options: [{
          data: ['Frodo', 'Samwise', 'Gandalf', 'Galadriel', 'Faramir', 'Éowyn', 'Peregrine Took', 'Boromir', 'Legolas', 'Gimli', 'Gollum', 'Beren', 'Saruman', 'Sauron', 'Théoden']
        }],
        filteredOptions: [],
        inputProps: {
          id: "autosuggest__input",
          onInputChange: this.onInputChange,
          placeholder: "Type 'e'"
        },
        limit: 10,
            errDialog: false,
            otherdialog: false,
            level5: [],
            level4Inc: [],
            _id: '',
          headersNew2:[
            // {text: '',value:'status',sortable: false,divider:true,class:"hdr-txt-clrnew "}, 
            {text: 'Level 1',value:'level1',sortable: false,divider:true,class:"hdr-txt-clrnew "},
            {text: 'Level 2', value: 'level2',sortable: false,divider:true,class:"hdr-txt-clrnew"},
            {text: 'Level 3', value: 'level3',sortable: false,divider:true,class:"hdr-txt-clrnew"},
            {text: 'Level 4', value: 'level4',sortable: false,divider:true,class:"hdr-txt-clrnew"},
            {text: 'Units',value: 'units',sortable: false,divider:true,class:"hdr-txt-clrnew"},   
         ],
         statusData: [
           {text: 'Level 1',value:'status',sortable: false,divider:true,class:"hdr-txt-clrnew "},
           {text: 'Level 1',value:'status',sortable: false,divider:true,class:"hdr-txt-clrnew "},
           {text: 'Level 1',value:'status',sortable: false,divider:true,class:"hdr-txt-clrnew "},
           {text: 'Level 1',value:'status',sortable: false,divider:true,class:"hdr-txt-clrnew "}
         ],

         requiredRules: [v => !!v || 'Level 1 is required'],
         statusData2:[],
         selectItems1: ['OT Charges','Medicine & Consumables Charges',
         'Professional fees Charges','Investigation Charges','Ambulance Charges',
         'Miscellaneous Charges','Package Charges','Room & Nursing Charges',
         'ICU Charges'],
         selectItems2: [],
         selectItems3: [],
         selectItems4: [],
         selectField1: '',
         selectField2: '',
         selectField3:'',
         selectField4: '',
         units: '',
         statusItemsPerPage: 6,
            // page: 1,
            roomNursingvalid:false,
            dscntPercntg:'',
            max10:10,
            max20:20,
            max30:30,
            max40:40,
            max50:50,
            max03: 3,
            max04: 5,
            max05:5,
            // requiredis(v){
            //     return v=>v && v.length>0 || "This Field is Required"
            // },
             required(){
                return v=>v && v.length>0 || "This Field is Required"
            },
            // requiredis(){
            //     return v=>v && v.length>0 || "This Field is Required"
            // },
            minLength(minLength){
                return v=>v && v.length>=minLength || `Must be atleast ${minLength} Characters`
            },
            maxLength(maxLength){
                return v=>v && v.length<=maxLength || `Must be less than ${maxLength} Characters`
            },
            spclChar(){
                return v=>/^[A-Za-z0-9 ]+$/.test(v) || `Special Characters Not Allowed`
            },
            alphaNumeric(){
                return v=>/^[0-9 ]+$/.test(v) || `Only Numbers are allowed.`
            },
            numberRule: v  => {
                if (!v.trim()) return true;
                if (!isNaN(parseFloat(v)) && v >0 && v <=10) return true;
                return 'Values between 1 and 10 are accepted';
            },
            decimalRule(){
                return v=>/^\d+(\.\d{1,2})?$/.test(v) || `Numeric upto two decimals.`
            },
            headers:[
                {text: 'Sr. No.',value:'status',sortable: false,divider:true}, 
                {text: 'Service Category(Level 4)',value:'status',sortable: false,divider:true},
                {text: 'Hospital Service(Level 5)', value: 'Date',sortable: false,divider:true},
                {text: 'Room Type 1', value: 'Time',sortable: false,divider:true},
                {text: 'Room Type 2', value: 'userName',sortable: false,divider:true},
                {text: 'Room Type 3',value: 'remarks',sortable: false,divider:true},  
                {text: 'Room Type 4',value: 'remarks',sortable: false,divider:true},
                {text: 'Exclusions',value: 'remarks',sortable: false,divider:true},   
            ],
            headersNew:[ 
                {text: 'scl4', displayval:'Service Category(Level 4)'},
                {text: 'hsl5',displayval:'Hospital Service(Level 5)'},
                // {text: 'csdc', displayval: 'csdc'},
                // {text: 'Inclusions',displayval:'Inclusions'}, 
                {text: 'Exclusions',displayval:'Exclusions'},   
                {text: 'del',displayval:'Action'},
            ],
            shwDtaTbl: false,
            addCategory:[],
            medConsumablesLvl4: [],
            addRmchrgs:[],
            noOfRooms:[],
            addCategoryArr: [],
            currentInclsn:[],
            editflag:false,
            isDisable: false,
            isDisablePackage: false,
            currentRadio: '',
            level22: '',
            level32: '',
            level33: '',
            level42: '',
            level43: '',
            level44: '',
            radioDataselect: '',
            delRow: ''

        
        
        }
    },

    watch:{

       

      
    

    },

    computed: {

        buttonstatus(){
            if(this.currentInclsn.length == 0){
                return true
            }else{
                return false
            } 
        },

        itemspin(){
             console.log('this.selectItems2 ', this.selectItems2);
            return this.selectItems2 
        },

        level3data(){
               console.log('this.selectItems3 ', this.selectItems3)
            // console.log('new mapp is ', [...new Map(this.selectItems3.map(item => [item['level3'], item])).values()])
            //  return [...new Map(this.selectItems3.map(item => [item['level3'], item])).values()]
            return this.selectItems3 
        },

        level4data(){
            console.log('this.selectItems4 ', this.selectItems4)
            //   return [...new Map(this.selectItems4.map(item => [item['level4'], item])).values()]
            return this.selectItems4
        },
        // providerErrorsTariff(){
        //     const errors = [];
        
        //     if (!this.$v.tariffName.$dirty) return errors   
        //     !this.$v.tariffName.nameSpaces && errors.push("Only Numbers are allowed");       
               

        //     return errors;   
                
        // },
        
    },

    created(){
        this.$store.commit("SAVE_PAGE_HEADER", "Packages Charges - Tariff");
        // console.log(this.$store.state.createtarrifDetails,"this.$store.state.createtarrifDetails");
        this.getmedConsmLvl4();
        this.fromview = this.$store.state.tariff_view;
        console.log(this.currentRadio.length);
        console.log(this.packageDiscData);
        
    },

    

     methods:{

        

         prepopulate(){

             console.log(" v-model of table currentInclsn ",this.currentInclsn)
            //  editInclusion
            var indata = this.currentInclsn;
            this.selectField1 = indata[0].level1;
            this.units = indata[0].units
                console.log('indata is ', indata)

                
               this.selectFields(1, '', 'lvl2', done=>{
                     var posslvltwo = this.selectItems2.findIndex(e=> e ==  indata[0].level2)
                            this.activeonly =  this.selectItems2[posslvltwo]

                     var posslvlthree = this.selectItems3.findIndex(e=> e ==  indata[0].level3)
                            this.lvl3only = this.selectItems3[posslvlthree]

                     var posslvlfour =  this.selectItems4.findIndex(e=> e ==  indata[0].level4)
                            this.lvl4only = this.selectItems4[posslvlfour]

                    if(indata[0].status == false){
                        this.editflag = false;
                        this.softedit = true;
                    }else{
                        this.editflag = true;
                        this.softedit = true
                    } 
                    console.log('this.editflag is ', this.editflag);
            //           indata[0].level2;
            //           indata[0].level2;
            //           indata[0].level2;
             });

         },

         refreshothers(identis){
            //  this.selectField1 = ''
            if(identis == 0){
                this.lvl3only = ''
                this.lvl4only = ''
                this.activeonly =''
                
                this.selectFields(0,'','lvl2',"");
            }else{
                 this.units = ''
                 this.selectField1 = ''
                 this.lvl3only = ''
                 this.lvl4only = ''
                 this.activeonly =''
                 this.$refs.inclusionForm.resetValidation()
                 
                //  this.$refs.inclusionForm.resetValidation()

            }
             

            
         },

        levelfourset(indata){

              console.log(indata)
              this.lvl4tgdd = false;
                // console.log(JSON.parse(indata),"indata is ",JSON.parse(indata) ==  '{"isTrusted":true}')
                // if(JSON.stringify(indata) ==  '{"isTrusted":true}' ) return
               this.lvl4only = indata
        },

        levelthreeset(indata){

              console.log(indata)
              this.lvl3tgdd = false;
                // console.log(JSON.parse(indata),"indata is ",JSON.parse(indata) ==  '{"isTrusted":true}')
                // if(JSON.stringify(indata) ==  '{"isTrusted":true}' ) return
               this.lvl3only = indata
        },

        onClickOutside(indata){
               console.log(indata)
               this.tgdd = false;
                // console.log(JSON.parse(indata),"indata is ",JSON.parse(indata) ==  '{"isTrusted":true}')
                // if(JSON.stringify(indata) ==  '{"isTrusted":true}' ) return
               this.activeonly = indata
          
        },

        searchlevel2(val){
            console.log("val is", val)
            this.query_data(val, 'lvl2');
        },

        searchlevel3(val){
            this.query_data3(val, 'lvl3');
        },

        searchlevel4(val){
            this.query_data4(val, 'lvl4');
        },

        //  selectHandler(){
        //      alert("something is selected")
        //  },

      query_data4(val, typeis){
               // Items have already been loaded
        if(val == null){
            console.log("returned as val foun to be null")
            return
        }

      if(val.length >3){
          var vlen = val.length
          console.log('vlen',vlen)
            
        //  var arrlen = this.entries.length
        //  console.log(arrlen,'arrlen')
        //  var newlen =this.entries[515]
        //  console.log("newlen is from watcher",newlen)
          if(this.level4data.length == 0 && val.length >= 3){
              this.selectFields(0,val,typeis,"");
              return
          }else{
              console.log('itens len')
          }

          for(let i=0; i<this.level4data.length;i++){
            
              var strlen = this.level4data[i].level4.slice(0,vlen)
              console.log(vlen > strlen.length,"strlen is",strlen)
                
             if(vlen > strlen.length){
               console.log("data not available to search")
               return
             }
            
              if(strlen != val){
                  console.log(strlen.length,"not found as ",val.length)
                //   this.foundis = false
                  this.selectFields(0,val,typeis,"");
                 return
              }else{
                console.log(this.level4data.length,"found true", this.level4data[i].level4)
                // this.foundis = true
                return
              }
          }
        
      }else{
        console.log("not 3 has been completed")
      }
     
    },

     query_data3(val, typeis){
               // Items have already been loaded
        if(val == null){
            console.log("returned as val foun to be null")
            return
        }

      if(val.length >3){
          var vlen = val.length
          console.log('vlen',vlen)
            
           

        //  var arrlen = this.entries.length
        //  console.log(arrlen,'arrlen')
        //  var newlen =this.entries[515]
        //  console.log("newlen is from watcher",newlen)
          if(this.level3data.length == 0 && val.length >= 3){
              this.selectFields(0,val,typeis,"");
              return
          }else{
              console.log('itens len')
          }

          for(let i=0; i<this.level3data.length;i++){
            
              var strlen = this.level3data[i].level2.slice(0,vlen)
              console.log(vlen > strlen.length,"strlen is",strlen)
                
             if(vlen > strlen.length){
               console.log("data not available to search")
               return
             }
            
              if(strlen != val){
                  console.log(strlen.length,"not found as ",val.length)
                //   this.foundis = false
                  this.selectFields(0,val,typeis,"");
                 return
              }else{
                console.log(this.level3data.length,"found true", this.level3data[i].level2)
                // this.foundis = true
                return
              }
          }
        
      }else{
        console.log("not 3 has been completed")
      }
     
    },

    query_data(val, typeis){

        console.log(this.selectField1,"itemspin",this.itemspin)
               // Items have already been loaded
        if(val == null){
            console.log("returned as val foun to be null")
            return
        }

      if(val.length >3){
          var vlen = val.length
          console.log('vlen',vlen)
            
        //    this.itemspin.filter(e=>{
        //        if()
        //        return e == val
        //    })

        //  var arrlen = this.entries.length
        //  console.log(arrlen,'arrlen')
        //  var newlen =this.entries[515]
        //  console.log("newlen is from watcher",newlen)
          if(this.itemspin.length == 0 && val.length >= 3){
              this.selectFields(0,val,typeis,"");
              return
          }else{
              console.log('itens len')
          }

          for(let i=0; i<this.itemspin.length;i++){
            
              var strlen = this.itemspin[i].slice(0,vlen)
              console.log(vlen > strlen.length,"strlen is",strlen)
                
             if(vlen > strlen.length){
               console.log("data not available to search")
               return
             }
            
              if(strlen != val){
                  console.log(strlen.length,"not found as ",val.length)
                //   this.foundis = false
                  this.selectFields(0,val,typeis,"");
                 return
              }else{
                console.log(this.itemspin.length,"found true", this.itemspin[i].level2)
                // this.foundis = true
                return
              }
          }
        
      }else{
        console.log("not 3 has been completed")
      }
     
    },

         delInclusionInput(indata){
             console.log('indata is ', indata[0]);
             if(this.currentInclsn.length == 0){
                 this.dis_add = false
             }else{
                 this.dis_add = true
             }
            //  this.selectField1 = indata[0].level1;
            // this.selectField1
            //  sel99
         },

         reviewCharges(){
           this.$router.push({name: 'ReviewCharges', params: {showApprove:false}})

         },

        //---if check Enable Button
        radiodata(data){
             console.log("emitted value is ", data);
             this.radioDataObj = data;
            //  console.log("emitted is ", data.length);

            // alert("value emitted")
           if(data){
              this.isDisable = true;
           }        
           this.radioDataselect = data.inCdta;

        },
        //---if Uncheck Disable Button
        UnchkData(val){
            if(val){
                this.isDisable = false;
            }
        },

        afterDelLen(ind){
            console.log(ind);
            this.isDisable = false;
            console.log(this.addCategory);
            console.log(this.addCategory.length);
            if(this.addCategory.length == 1){
                this.isDisablePackage = false;
                console.log("disable");
            }else{
                console.log("Enable");
                this.isDisablePackage = true;
                this.level22 = '';
                this.level32 = '';
                this.level33 = '';
                this.level42 = '';
                this.level43 = '';
                this.level44 = '';
            }
            
            // else {
            //     this.isDisablePackage = true;
            // }
            console.log(this.addCategory.length);
            // var delAfterSave = this.disablePackageArr;
            // console.log(this.disablePackageArr);
            // console.log(this.disablePackageArr.length);
            // if(this.addCategory.length < 3){
            //     this.isDisablePackage = false;
            // }else{
            //     this.isDisablePackage = true;
            // }
        }, 
        
        errDialogClose(){
            this.errDialogCloseBx = false;
            this.level22 = '';
            this.level32 = '';
            this.level33 = '';
            this.level42 = '';
            this.level43 = '';
            this.level44 = '';
        },

         dialogFunc(){
            console.log(this.radioDataObj);
           
            this.otherdialog = true;
            this.level5 = this.radioDataObj.inCdta.scl4.level4;
            this.level4Inc = this.radioDataObj.inCdta.hsl5;
            this.currentInclsn = []
           
            // this.$refs.inclusionForm.resetValidation();
            // var categoryLoopArr = this.addCategoryArr;
            // for(let i = 0; i < categoryLoopArr.length; i++){
            //     this.level5 = categoryLoopArr[i].scl4.level4;
            //     this.level4Inc = categoryLoopArr[i].hsl5;
            // }

            // this.refreshothers(1);
            // re-bind here
             this.GET(2,'tariff/get-inclusion-for-package/?packageId='+this.radioDataselect._id+'&perPage=100&pageNo=1', (res, error)=>{

                         console.log("response is -> ", res.data.data);

                           this.statusData2 = [];
                           for(var i = 0;i < res.data.data.length; i++){
                                console.log("res.data in for loop -> ", res.data.data[i]);
                               this.statusData2.push({
                            _id: res.data.data[i].inclusionCharges._id,
                            level1: res.data.data[i].inclusionCharges.level1,
                            level2: res.data.data[i].inclusionCharges.level2,
                            level3: res.data.data[i].inclusionCharges.level3,
                            level4: res.data.data[i].inclusionCharges.level4,
                            units: res.data.data[i].inclusionCharges.units,
                            status: true,
                        })
                           }

                              console.log("statusData2 is -> ", this.statusData2);
                            this.$refs.inclusionForm.resetValidation()

                     })

        },


        addInclusion(){
            // if(this.selectField1 === ''){
            //     console.log("Something wrong!");
            //     this.showToast("level1 field is required",this.TOST().WARNING);
            //     return
            // }
            console.log("this.$refs.inclusionForm.validate() ", this.$refs.inclusionForm.validate());

            if(!this.$refs.inclusionForm.validate()){
                this.$refs.inclusionForm.validate()
                this.showToast("Please enter all required fields.",this.TOST().WARNING);
                return
            } 
            console.log("after validate reached ");
            // return
            
            this.statusData2.push({
                _id: Date.now().toString(36) + Math.random().toString(36).substr(2),
                level1: this.selectField1,
                level2: this.activeonly,
                level3: this.lvl3only ,
                
                level4: this.lvl4only,
                units: this.units,
                status: false,
            })

            this.refreshothers(1);
                // rf99
            console.log("statusData2 ", this.statusData2);
            // this.selectField1 = '';
            // this.selectField2 = '';
            // this.selectField3 = '';
            // this.selectField4 = '';       
           
           
        },

        editInclusion(){
            console.log("edit working", this.currentInclsn);
            var putData = {
                id: this.currentInclsn[0]._id,
                packageId: this.radioDataselect._id,
                level1: this.selectField1,
                level2: this.activeonly,
                level3: this.lvl3only ,
                level4: this.lvl4only,
                units: this.units,           
            }
            // var id = this.currentInclsn._id;
             console.log("putData is ", putData);
            

            this.PUT(2, 'tariff/update-inclusion-for-package/', putData, (res, err) => {
                if(!err){
                    this.editflag = false;
                       this.showToast(res.data.msg,this.TOST().SUCCESS);
                       this.getupdated_inclusions()
                        this.refreshothers(1);
                    // console.log('response of put inclusions is ', res);
                    // this.selectField1 = this.currentInclsn.level1;
                }
            })
            // this.selectField1 = this.currentInclsn.level1;
            // this.selectItems2 = this.currentInclsn.level2;
            // this.selectItems3 = this.currentInclsn.level3;
            // this.selectItems4 = this.currentInclsn.level4;
            // this.units = this.currentInclsn.units;

        },


        delInclusion(indata){
            // Two type of delete functionality implemnted
            // 1) soft delete => i.e when the recorded is added but not in database, just in the dom
            // 2) hard delete => i.e when the recorded is present in database and requires API
            // this.currentInclsn.splice(this.currentInclsn._id)
            // var deldata = {
            //         id: this.currentInclsn[0]._id,
            //         packageId: this.radioDataselect._id
            //     }
            console.log(indata, " what's indata argument value | del working", this.currentInclsn[0]._id)
            if(this.currentInclsn.length == 0){
                this.showToast("Please select an Inclusion to delete",this.TOST().WARNING); 
                return
            }
            if(this.currentInclsn[0].status == false){
                var delindex = this.statusData2.findIndex(e=> e._id == this.currentInclsn[0]._id)
                if(delindex != -1){
                  this.statusData2.splice(delindex,1 ); 
                  this.currentInclsn = []
                  this.showToast("An Inclusion has been deleted",this.TOST().SUCCESS);
                  
                }else{
                     this.showToast("The Inclusion to be deleted is not found",this.TOST().WARNING); 
                }
                 console.log( " what's current INClusions ", this.currentInclsn[0]._id)
                return
            }

            // return
      
            this.DELETE_FORMDATA(2, 'tariff/remove-inclusion-for-package?packageId='+this.radioDataselect._id+'&id='+this.currentInclsn[0]._id , (res, err) => {
                if(!err){
                    console.log(" delete success msg ", res.data.msg);

                    this.showToast(res.data.msg,this.TOST().SUCCESS);
                    // this.statusData2.splice(delindex,1 ); 
                    // this.currentInclsn = []
                    let preserve_record = []
                   this.statusData2.map(e=>{
                        !e.status ? preserve_record.push(e) : null
                    })
                    this.getupdated_inclusions('del_server', preserve_record);
                }
            })
        },

        saveinc(){
             console.log("save inclusion", this.statusData2);
            // fs99
             if(this.editflag && this.softedit){
                 this.editInclusion();
                 return
             }
            //  if(this.deleteflag){
            //      this.delInclusion();
            //      return
            //  }
            // return
            // console.log(this.statusData2);
            var tbdatArr = [];
           
            for(var i=0; i<this.statusData2.length; i++){
                    // sv99
               
                if(this.statusData2[i].status == false){
                     var tableData = {
                        // packageId: this.statusData2[i]._id,
                        // inclusionCharges: ,
                        level1: this.statusData2[i].level1,
                        level2: this.statusData2[i].level2,
                        level3: this.statusData2[i].level3,
                        level4: this.statusData2[i].level4,
                        units: this.statusData2[i].units,
                    }; 
                    tbdatArr.push(tableData);
                }               
            }  
            
            if(this.softedit){
                console.warn("BEFORE tbdatArr --> ", tbdatArr);
                console.warn(this.softedit,' <- softedit | currentInclsn -> ',this.currentInclsn);

                this.statusData2.filter(e=>{
                        if(e._id == this.currentInclsn[0]._id){
                             tbdatArr = [];
                            console.warn("found matched is ", );
                            tbdatArr.push({ 
                                            level1: this.selectField1,
                                            level2: this.activeonly,
                                            level3: this.lvl3only ,
                                            level4: this.lvl4only,
                                            units: this.units })

                        }
                })
                // this.currentInclsn 
            }
              
            // inject edited data for non-database object and re-add it into an array
         console.log(this.radioDataselect, " UIdentifier |AFTER tbdatArr --> ", tbdatArr);
        
         if(tbdatArr.length == 0){
              this.showToast('Please add an Inclusion',this.TOST().ERROR);
              return
         }

         if(this.statusData2.length == 0){
            this.showToast('Please add an Inclusion',this.TOST().ERROR);
         }else{
             var fd = {
                "packageId": this.radioDataselect._id,
                "uniqueIdentifier": this.radioDataselect.uniqueIdentifier ,
                "inclusionCharges": tbdatArr
            }
            console.log(this.$refs.inclusionForm," formData is ", fd);
            // return
            // if(!this.$refs.inclusionForm.validate()){
            //     this.$refs.inclusionForm.validate()
            //     this.showToast("Please enter all required fields.",this.TOST().WARNING);
            //     return
            // } 
            console.log("proceeded");
        //    if(this.selectField1 === ''){
        //         console.log("Something wrong!");
        //         this.showToast("Data should be entered for all required fields",this.TOST().ERROR);
        //         return
        //     }
        
          this.POST(2, 'tariff/add-inclusion-for-package',fd, (res, err) => {
                 if(!err){
                     console.log(res);
                     this.showToast(res.data.msg,this.TOST().SUCCESS);
                     this.softedit = false;
                     this.getupdated_inclusions()
                    //  this.statusData2 = [];
                     this.refreshothers(1);
                    //   this.statusData2.push({
                    //         _id: this._id,
                    //         level1: this.selectField1,
                    //         level2: this.selectField2,
                    //         level3: this.selectField3,
                    //         level4: this.selectField4,
                    //         units: this.units,
                    //         status: false,
                    //     })
                    //  alert("successfully added inclusions")
                 }else{
                    console.log("AN ERROR HAS OCCURED", error.response);
                    this.showToast(err.response.data.msg ,this.TOST().ERROR);
                }
             })
            
         }  
        },

        getupdated_inclusions(operation, preserve_record){

                  this.GET(2,'tariff/get-inclusion-for-package/?packageId='+this.radioDataselect._id+'&perPage=100&pageNo=1', (res, error)=>{

                        try{
                            if(!error){
                            console.log("response is -> ", res.data.data);

                            this.statusData2 = [];
                            for(var i = 0;i < res.data.data.length; i++){
                                    console.log("res.data in for loop -> ", res.data.data[i]);
                                this.statusData2.push({
                                _id: res.data.data[i].inclusionCharges._id,
                                level1: res.data.data[i].inclusionCharges.level1,
                                level2: res.data.data[i].inclusionCharges.level2,
                                level3: res.data.data[i].inclusionCharges.level3,
                                level4: res.data.data[i].inclusionCharges.level4,
                                units: res.data.data[i].inclusionCharges.units,
                                status: true,
                            })
                            }

                            this.currentInclsn = []

                            if(operation=='del_server'){
                                 this.statusData2.push(...preserve_record)
                            }

                                console.log("statusData2 is -> ", this.statusData2);
                            }
                        }catch(e){
                             console.log("error occurred while getting inclusions -> ",e);
                        }
                           

                     })

        },

        savePacDiscnt(v){
            if(this.$refs.discountForm.validate()){
            // if(!/^[0-9 ]+$/.test(v)){
            //     this.showToast("Only numbers are allowed", this.TOST().ERROR);
            // }
                
            console.log(this.$store.state.createtarrifDetails.tariffId);
            // console.log(this.radioDataselect.inCdta.scl4._id);
            console.log(this.$store.state.createtarrifDetails._id);
            var tariffId = this.$store.state.createtarrifDetails._id;
            // var newId = this.radioDataselect._id;
            var disData = {
                tariffId: tariffId,
                // packageId: newId,
                configMultiPackage: {
                discountForTwoPackage: {
                    secondPackageDiscount: this.level22
                },
                discountForThreePackage: {
                    secondPackageDiscount: this.level32 ,
                    thirdPackageDiscount: this.level33 
                },
                discountForFourPackage: {
                    secondPackageDiscount: this.level42 ,
                    thirdPackageDiscount: this.level43 ,
                    forthPackageDiscount: this.level44 ,
                }
                }
            };
            try {
                    this.PUT(2, 'tariff/update-multiple-package', disData, (res, err) => {
                        if(!err){
                            console.log(res);
                            this.packageDiscData = res;
                            this.showToast("Multiple Package Discount saved successfully",this.TOST().SUCCESS);
                            this.errDialogCloseBx = false;
                        }else{
                            console.log(err);
                        }
                    })
            } catch (error) {
                    console.log(error);
                    this.showToast(res.data.msg,this.TOST().ERROR);
            }

            }else{
                this.showToast("Please fill required fields",this.TOST().WARNING);
            }
     
            
        },

        selectFields(ident,inval,intypeis,cb){
            // alert("Select Field is called ");
            // sl99
            // console.log(this.selectField1, "  type is ", intypeis, " inval is ",inval);
               console.log(this.selectField1,'<- select 1');
            // var inclusionData = this.selectItems1;
            var pageis = 'perPage='+200+'&pageNo='+1
            
            var start_cb = false
            if(ident == 1){
                start_cb = true
            }
             var arris = []
            var getdata = new Promise((resolve, reject)=>{

                if(this.selectField1 == 'OT Charges'){
                    this.selectItems2 = [];
                    this.selectItems3 = [];
                    this.selectItems4 = [];
                //    var build_api = intypeis == 'lvl2' ? 'tariff/get-ot-charges?level2='+inval : intypeis == 'lvl3' ? 'tariff/get-ot-charges?level3='+inval : intypeis == 'lvl4' ? 'tariff/get-ot-charges?searchString='+inval : ''
                //    console.log(" build_api ", build_api);

                    var build_api = intypeis == 'lvl2' ? 'tariff/get-ot-charges?' : intypeis == 'lvl3' ? 'tariff/get-ot-charges?' : intypeis == 'lvl4' ? 'tariff/get-ot-charges?' : ''
                    
                    this.GET(3,build_api+pageis, (res, err) => {
                        if(!err){
                            // console.log(res.data.data.data); 
                           // this.selectItems2 = [];
                           
                            var newDataRes = res.data.data.data;
                            for(var i=0; i< newDataRes.length; i++){
                                // if(intypeis == 'lvl2'){
                                //     arris.push(newDataRes[i].level2);
                                // }else if(intypeis == 'lvl3'){
                                //     arris.push(newDataRes[i]);
                                // }else if(intypeis == 'lvl4'){
                                //     arris.push(newDataRes[i]);
                                // }
                                
                                this.selectItems2.push(newDataRes[i].level2);
                                // console.log(newDataRes[i].level2);
                                this.selectItems3.push(newDataRes[i].level3);
                                // console.log(newDataRes[i].level3);
                                this.selectItems4.push(newDataRes[i].level4);
                                // console.log(newDataRes[i].level4);
                                // this._id = newDataRes[i]._id;
                            }

                        // if(intypeis == 'lvl2'){
                        //     this.selectItems2 = [...new Set(arris)];
                        // }else if(intypeis == 'lvl3'){
                        //     this.selectItems3 = arris;
                        // }else{
                        //     this.selectItems4 = arris;
                        // }
                
                        if(start_cb){
                                resolve()
                            }
                        }
                    }) 
                }else if(this.selectField1 == 'Medicine & Consumables Charges'){
                    this.selectItems2 = [];
                    this.selectItems3 = [];
                    this.selectItems4 = [];

                //    var build_api = intypeis == 'lvl2' ? 'tariff/get-med-and-consumables-charges?level2='+inval : intypeis == 'lvl3' ? 'tariff/get-med-and-consumables-charges?level3='+inval : intypeis == 'lvl4' ? 'tariff/get-med-and-consumables-charges?searchString='+inval : ''
                //    console.log(" build_api ", build_api);

                   var build_api = 'tariff/get-med-and-consumables-charges?' 
                   console.log(" build_api ", build_api);

                    // 'tariff/get-med-and-consumables-charges'
                    this.GET(3, build_api+pageis , (res, err) => {
                        if(!err){
                            console.log(res.data.data.data);
                           // this.selectItems2 = [];
                            var newDataRes = res.data.data.data;
                            for(var i=0; i< newDataRes.length; i++){

                                this.selectItems2.push(newDataRes[i].level2);
                                // console.log(newDataRes[i].level2);
                                this.selectItems3.push(newDataRes[i].level3);
                                // console.log(newDataRes[i].level3);
                                this.selectItems4.push(newDataRes[i].level4);

                                //  console.log(newDataRes[i].level2);
                                // if(intypeis == 'lvl2'){
                                //     arris.push(newDataRes[i].level2);
                                // }else if(intypeis == 'lvl3'){
                                //     arris.push(newDataRes[i]);
                                // }else if(intypeis == 'lvl4'){
                                //     arris.push(newDataRes[i]);
                                // }
                             
                                this._id = newDataRes[i]._id + 3;
                            }

                            // if(intypeis == 'lvl2'){
                            //     this.selectItems2 = [...new Set(arris)];
                            // }else if(intypeis == 'lvl3'){
                            //     this.selectItems3 = arris;
                            // }else{
                            //     this.selectItems4 = arris;
                            // }
                             if(start_cb){
                                resolve()
                             }
                        }
                    })
                }else if(this.selectField1 == 'Professional fees Charges'){
                    this.selectItems2 = [];
                    this.selectItems3 = [];
                    this.selectItems4 = [];

                //    var build_api = intypeis == 'lvl2' ? 'tariff/get-professional-fee-charges?level2='+inval : intypeis == 'lvl3' ? 'tariff/get-professional-fee-charges?level3='+inval : intypeis == 'lvl4' ? 'tariff/get-professional-fee-charges?searchString='+inval : ''
                //    console.log(" build_api ", build_api);

                   var build_api =  'tariff/get-professional-fee-charges?'
                   console.log(" build_api ", build_api);
                    // tariff/get-professional-fee-charges
                    this.GET(3,build_api+pageis , (res, err) => {
                        if(!err){
                            console.log(res.data.data.data);
                           // this.selectItems2 = [];
                            var newDataRes = res.data.data.data;
                            for(var i=0; i< newDataRes.length; i++){

                                this.selectItems2.push(newDataRes[i].level2);
                                // console.log(newDataRes[i].level2);
                                this.selectItems3.push(newDataRes[i].level3);
                                // console.log(newDataRes[i].level3);
                                this.selectItems4.push(newDataRes[i].level4);
                                // if(intypeis == 'lvl2'){
                                //     arris.push(newDataRes[i].level2);
                                // }else if(intypeis == 'lvl3'){
                                //     arris.push(newDataRes[i]);
                                // }else if(intypeis == 'lvl4'){
                                //     arris.push(newDataRes[i]);
                                // }
                                // this.selectItems2.push(newDataRes[i].level2);
                                //  console.log(newDataRes[i].level2);
                                // this.selectItems3.push(newDataRes[i].level3);
                                // this.selectItems4.push(newDataRes[i].level4);
                                this._id = newDataRes[i]._id + 3;
                            }

                            // if(intypeis == 'lvl2'){
                            //     this.selectItems2 = [...new Set(arris)];
                            // }else if(intypeis == 'lvl3'){
                            //     this.selectItems3 = arris;
                            // }else{
                            //     this.selectItems4 = arris;
                            // }

                            if(start_cb){
                                resolve()
                            }
                        }
                    })
                }else if(this.selectField1 == 'Investigation Charges'){
                    this.selectItems2 = [];
                    this.selectItems3 = [];
                    this.selectItems4 = [];

                //    var build_api = intypeis == 'lvl2' ? 'tariff/get-investigations-charges?level2='+inval : intypeis == 'lvl3' ? 'tariff/get-investigations-charges?level3='+inval : intypeis == 'lvl4' ? 'tariff/get-investigations-charges?searchString='+inval : ''
                //    console.log(" build_api ", build_api);

                    var build_api = 'tariff/get-investigations-charges?'
                   console.log(" build_api ", build_api);
                    // tariff/get-investigations-charges | 'tariff/get-investigations-charges?perPage=50&pageNo=1'
                    this.GET(3,build_api+pageis, (res, err) => {
                        if(!err){
                            console.log(res.data.data.data);
                           // this.selectItems2 = [];
                            var newDataRes = res.data.data.data;
                            for(var i=0; i< newDataRes.length; i++){
                                // this.selectItems2.push(newDataRes[i].level2);
                                // if(intypeis == 'lvl2'){
                                //     arris.push(newDataRes[i].level2);
                                // }else if(intypeis == 'lvl3'){
                                //     arris.push(newDataRes[i]);
                                // }else if(intypeis == 'lvl4'){
                                //     arris.push(newDataRes[i]);
                                // }
                                this.selectItems2.push(newDataRes[i].level2);
                                // console.log(newDataRes[i].level2);
                                this.selectItems3.push(newDataRes[i].level3);
                                // console.log(newDataRes[i].level3);
                                this.selectItems4.push(newDataRes[i].level4);
                                //  console.log(newDataRes[i].level2);
                                // this.selectItems3.push(newDataRes[i].level3);
                                // this.selectItems4.push(newDataRes[i].level4);
                                this._id = newDataRes[i]._id + 3;
                            }

                            // if(intypeis == 'lvl2'){
                            //     this.selectItems2 = [...new Set(arris)];
                            // }else if(intypeis == 'lvl3'){
                            //     this.selectItems3 = arris;
                            // }else{
                            //     this.selectItems4 = arris;
                            // }

                            if(start_cb){
                                resolve()
                            }

                        }
                    })
                }else if(this.selectField1 == 'Ambulance Charges'){
                    this.selectItems2 = [];
                    this.selectItems3 = [];
                    this.selectItems4 = [];

                //    var build_api = intypeis == 'lvl2' ? 'tariff/get-ambulance-charges?level2='+inval : intypeis == 'lvl3' ? 'tariff/get-ambulance-charges?level3='+inval : intypeis == 'lvl4' ? 'tariff/get-ambulance-charges?searchString='+inval : ''
                //    console.log(" build_api ", build_api);

                     var build_api ='tariff/get-ambulance-charges?'
                   console.log(" build_api ", build_api);
                  // tariff/get-ambulance-charges?perPage=50&pageNo=1
                    this.GET(3,build_api+pageis, (res, err)=>{
                        if(!err){
                            console.log(res.data.data.data);
                           // this.selectItems2 = [];
                            var newDataRes = res.data.data.data;
                            for(var i=0; i< newDataRes.length; i++){

                                this.selectItems2.push(newDataRes[i].level2);
                                // console.log(newDataRes[i].level2);
                                this.selectItems3.push(newDataRes[i].level3);
                                // console.log(newDataRes[i].level3);
                                this.selectItems4.push(newDataRes[i].level4);
                                // if(intypeis == 'lvl2'){
                                //     arris.push(newDataRes[i].level2);
                                // }else if(intypeis == 'lvl3'){
                                //     arris.push(newDataRes[i]);
                                // }else if(intypeis == 'lvl4'){
                                //     arris.push(newDataRes[i]);
                                // }
                                // this.selectItems2.push(newDataRes[i].level2);
                                //  console.log(newDataRes[i].level2);
                                // this.selectItems3.push(newDataRes[i].level3);
                                // this.selectItems4.push(newDataRes[i].level4);
                                this._id = newDataRes[i]._id + 3;
                            }

                            // if(intypeis == 'lvl2'){
                            //     this.selectItems2 = [...new Set(arris)];
                            // }else if(intypeis == 'lvl3'){
                            //     this.selectItems3 = arris;
                            // }else{
                            //     this.selectItems4 = arris;
                            // }

                            if(start_cb){
                                resolve()
                            }

                        }
                    })
                }else if(this.selectField1 == 'Miscellaneous Charges'){
                    this.selectItems2 = [];
                    this.selectItems3 = [];
                    this.selectItems4 = [];

                //    var build_api = intypeis == 'lvl2' ? 'tariff/get-miscellaneous-charges?level2='+inval : intypeis == 'lvl3' ? 'tariff/get-miscellaneous-charges?level3='+inval : intypeis == 'lvl4' ? 'tariff/get-miscellaneous-charges?searchString='+inval : ''
                //    console.log(" build_api ", build_api);

                     var build_api = 'tariff/get-miscellaneous-charges?'
                   console.log(" build_api ", build_api);

                    this.GET(3,build_api+pageis , (res, err) =>{
                        if(!err){
                            //console.log(res.data.data.data);
                           // this.selectItems2 = [];
                            var newDataRes = res.data.data.data;
                            for(var i=0; i< newDataRes.length; i++){

                                this.selectItems2.push(newDataRes[i].level2);
                                // console.log(newDataRes[i].level2);
                                this.selectItems3.push(newDataRes[i].level3);
                                // console.log(newDataRes[i].level3);
                                this.selectItems4.push(newDataRes[i].level4);
                                // if(intypeis == 'lvl2'){
                                //     arris.push(newDataRes[i].level2);
                                // }else if(intypeis == 'lvl3'){
                                //     arris.push(newDataRes[i]);
                                // }else if(intypeis == 'lvl4'){
                                //     arris.push(newDataRes[i]);
                                // }
                                // this.selectItems2.push(newDataRes[i].level2);
                                //  console.log(newDataRes[i].level2);
                                // this.selectItems3.push(newDataRes[i].level3);
                                // this.selectItems4.push(newDataRes[i].level4);
                                this._id = newDataRes[i]._id + 3;
                            }

                            // if(intypeis == 'lvl2'){
                            //     this.selectItems2 = [...new Set(arris)];
                            // }else if(intypeis == 'lvl3'){
                            //     this.selectItems3 = arris;
                            // }else{
                            //     this.selectItems4 = arris;
                            // }

                            if(start_cb){
                                resolve()
                            }

                        }
                    })
                }else if(this.selectField1 == 'Package Charges'){
                    this.selectItems2 = [];
                    this.selectItems3 = [];
                    this.selectItems4 = [];

                // var build_api = intypeis == 'lvl2' ? 'tariff/get-package-charges?level2='+inval : intypeis == 'lvl3' ? 'tariff/get-package-charges?level3='+inval : intypeis == 'lvl4' ? 'tariff/get-package-charges?searchString='+inval : ''
                // console.log(" build_api ", build_api);

                  var build_api = 'tariff/get-package-charges?'
                console.log(" build_api ", build_api);
                // tariff/get-package-charges?perPage=50&pageNo=1
                    this.GET(3,build_api+pageis , (res, err) =>{
                        if(!err){
                            console.log(res.data.data.data);
                           // this.selectItems2 = [];
                            var newDataRes = res.data.data.data;
                            for(var i=0; i< newDataRes.length; i++){

                                this.selectItems2.push(newDataRes[i].level2);
                                // console.log(newDataRes[i].level2);
                                this.selectItems3.push(newDataRes[i].level3);
                                // console.log(newDataRes[i].level3);
                                this.selectItems4.push(newDataRes[i].level4);
                                // if(intypeis == 'lvl2'){
                                //     arris.push(newDataRes[i].level2);
                                // }else if(intypeis == 'lvl3'){
                                //     arris.push(newDataRes[i]);
                                // }else if(intypeis == 'lvl4'){
                                //     arris.push(newDataRes[i]);
                                // }
                                // this.selectItems2.push(newDataRes[i].level2);
                                //  console.log(newDataRes[i].level2);
                                // this.selectItems3.push(newDataRes[i].level3);
                                // this.selectItems4.push(newDataRes[i].level4);
                                this._id = newDataRes[i]._id + 3;
                            }

                            // if(intypeis == 'lvl2'){
                            //     this.selectItems2 = [...new Set(arris)];
                            // }else if(intypeis == 'lvl3'){
                            //     this.selectItems2 = arris;
                            // }else{
                            //     this.selectItems4 = arris;
                            // }

                            if(start_cb){
                                resolve()
                            }

                        }
                    })
                }else if(this.selectField1 == 'Room & Nursing Charges'){
                    this.selectItems2 = [];
                    this.selectItems3 = [];
                    this.selectItems4 = [];

                // var build_api = intypeis == 'lvl2' ? 'room-and-nursing-charges/get-room-and-nursing-charges?level2='+inval : intypeis == 'lvl3' ? 'room-and-nursing-charges/get-room-and-nursing-charges?level3='+inval : intypeis == 'lvl4' ? 'room-and-nursing-charges/get-room-and-nursing-charges?searchString='+inval : ''
                // console.log(" build_api ", build_api);

                var build_api = intypeis == 'lvl2' ? 'room-and-nursing-charges/get-room-and-nursing-charges?' : intypeis == 'lvl3' ? 'room-and-nursing-charges/get-room-and-nursing-charges?' : intypeis == 'lvl4' ? 'room-and-nursing-charges/get-room-and-nursing-charges?' : ''
                console.log(" build_api ", build_api);
                // room-and-nursing-charges/get-room-and-nursing-charges

                    this.GET(3,build_api+pageis , (res, err) =>{
                        if(!err){
                            //console.log(res.data.data.data);
                           // this.selectItems2 = [];
                            var newDataRes = res.data.data.data;
                            for(var i=0; i< newDataRes.length; i++){

                                this.selectItems2.push(newDataRes[i].level2);
                                // console.log(newDataRes[i].level2);
                                this.selectItems3.push(newDataRes[i].level3);
                                // console.log(newDataRes[i].level3);
                                this.selectItems4.push(newDataRes[i].level4);
                                // if(intypeis == 'lvl2'){
                                //     arris.push(newDataRes[i].level2);
                                // }else if(intypeis == 'lvl3'){
                                //     arris.push(newDataRes[i]);
                                // }else if(intypeis == 'lvl4'){
                                //     arris.push(newDataRes[i]);
                                // }
                                // this.selectItems2.push(newDataRes[i].level2);
                                //  console.log(newDataRes[i].level2);
                                // this.selectItems3.push(newDataRes[i].level3);
                                // this.selectItems4.push(newDataRes[i].level4);
                                this._id = newDataRes[i]._id + 3;
                            }

                            // if(intypeis == 'lvl2'){
                            //     this.selectItems2 = [...new Set(arris)];
                            // }else if(intypeis == 'lvl3'){
                            //     this.selectItems2 = arris;
                            // }else{
                            //     this.selectItems4 = arris;
                            // }

                            if(start_cb){
                                resolve()
                            }

                        }
                    })
                }else if(this.selectField1 == 'ICU Charges'){
                    this.selectItems2 = [];
                    this.selectItems3 = [];
                    this.selectItems4 = [];
                    
                
                var build_api = intypeis == 'lvl2' ? 'tariff/get-icu-charges?' : intypeis == 'lvl3' ? 'tariff/get-icu-charges?' : intypeis == 'lvl4' ? 'tariff/get-icu-charges?' : ''
                console.log(" build_api ", build_api);

                    this.GET(3,build_api+pageis, (res, err) =>{
                        if(!err){
                            console.log(res.data.data.data);
                           // this.selectItems2 = [];
                            var newDataRes = res.data.data.data;
                            for(var i=0; i< newDataRes.length; i++){

                                this.selectItems2.push(newDataRes[i].level2);
                                // console.log(newDataRes[i].level2);
                                this.selectItems3.push(newDataRes[i].level3);
                                // console.log(newDataRes[i].level3);
                                this.selectItems4.push(newDataRes[i].level4);
                                // if(intypeis == 'lvl2'){
                                //     arris.push(newDataRes[i].level2);
                                // }else if(intypeis == 'lvl3'){
                                //     arris.push(newDataRes[i]);
                                // }else if(intypeis == 'lvl4'){
                                //     arris.push(newDataRes[i]);
                                // }
                                // this.selectItems2.push(newDataRes[i].level2);
                                //  console.log(newDataRes[i].level2);
                                // this.selectItems3.push(newDataRes[i].level3);
                                // this.selectItems4.push(newDataRes[i].level4);
                                this._id = newDataRes[i]._id + 3;
                            }

                            // if(intypeis == 'lvl2'){
                            //     this.selectItems2 = [...new Set(arris)];
                            // }else if(intypeis == 'lvl3'){
                            //     this.selectItems2 = arris;
                            // }else{
                            //     this.selectItems4 = arris;
                            // }

                            if(start_cb){
                                resolve()
                            }

                        }
                    })
                }

            })
            
            getdata.then(res=>{
                   console.log("as promised resolved, starting callback",this.selectItems4);
                    if(start_cb){
                        cb(true);
                    } 
            }).catch(error=>{
                  console.log('error occurred while re-populating or getting',error);
            })
              
            
            
             

        },

         getmedConsmLvl4(){       
            this.medConsumablesLvl4 = [];
            this.getmedConsumeConfig(this.$store.state.createtarrifDetails._id)
            return

            try {
                this.GET(3,'/tariff/get-package-charges?perPage=50&pageNo=1',(res,error)=>{
                    if(!error){
                        if(res.data.statusCode === 200){
                            
                            console.log(this.medConsumablesLvl4);
                            // this.shwDtaTbl = true;
                            this.medConsumablesLvl4 = res.data.data.data;
                            this.getmedConsumeConfig(this.$store.state.createtarrifDetails._id)
                            console.log(this.addCategory);
                        }else{
                            this.showToast(res.data.msg ,this.TOST().ERROR); 
                        }
                    }else{
                        this.showToast(error.response.data.msg, this.TOST().WARNING);
                    }
                });
            } catch (error) {
                    console.log('error occurred in getting package charges ');  
            }
          
            // console.log(this.currentRadio);
        },
        
        saveMedConsmechrgs(data){
            if(data.dta.length ===0){
                this.showToast("Please add atleast one category",this.TOST().WARNING);
                return
            }
            // console.log(this.tempmedConsumeChrgs ,"this.tempmedConsumeChrgs");
            // console.log(data, "saveNrsngchrgs commponent emitted ");
            if(!this.$refs.otForm.validate()){
                this.$refs.otForm.validate()
                 this.showToast("Data should be entered for all required fields.",this.TOST().WARNING);
                return
            } 
            console.log(data,"incmgDta")

            let packageCharges= [];
            data.dta.map((e)=>{
         
                console.log(e,"inside incoming d")

                var tempRoomType=[]
                 for (var i = 0; i < e.noOfRooms.length; i++) {
                  tempRoomType.push({
                      roomType:e.noOfRooms[i].text,
                      charges:e.noOfRooms[i].inputValue
                  })
                }
                let tempdata={
                   
                    // levelId:e.scl4._id,
                    // level1:e.scl4.level1,
                    // level2:e.scl4.level2,
                    // level3:e.scl4.level3,
                    uniqueIdentifier:e.uniqueIdentifier,
                    serviceCategory:e.scl4,
                    hospitalService:e.hsl5,
                    // hospitalervice:"General",
                    // inclusion: e.Inclusions,
                    exclusion:e.Exclusions,
                    // roomType:e.noOfRooms
                    roomTypes:tempRoomType 
                }

                if(e.hasOwnProperty('_id')){
                    if(e._id.length!=0){
                         tempdata._id=e._id
                    }else{
                        console.log("Id not found")
                    }
                }
                console.log("tempdata with Id/not",tempdata)
            
                packageCharges.push(tempdata)
            })
            let formData={
                tariffId:this.$store.state.createtarrifDetails._id,
                discount:this.dscntPercntg,
                packageCharges:packageCharges,
            }
             console.log(formData,"formData")

            try {
                this.PUT(2,'tariff/update-package-charges',formData ,
                        ( res, error) => {
                        if(!error){
                            console.log(res.data);
                            // alert("calling get after save");
                            this.showToast(res.data.msg ,this.TOST().SUCCESS);
                            this.$emit('forenable',{emitType:"PackageCharges",PackageCharges:true}); 
                            this.getmedConsmLvl4();
                            // this.getmedConsumeConfig(this.$store.state.createtarrifDetails._id)

                        }else{
                            console.log("when status is not 200", error.response);
                            this.showToast(error.response.data.msg ,this.TOST().ERROR);
                        }
                });
            } catch (error) {
                 console.log("error occurred in udate package charges ", error);
            }

          
        },
       
       save_medConsme_ctgry(){
            this.addCategory.push({
                scl4:'',
                hsl5:'',
                
                // noOfRooms:this.noOfRooms,
                // inclusions:'',
                exclusions:'',
                del: '',
               
            })
            if(this.addCategory.length > 1){
                this.isDisablePackage = true;
            }
       },

       packageDiscountData(data){
           console.log(data.MultiplePackagesDiscount);
           if(data.MultiplePackagesDiscount !== null){
                var dataPackage = data.MultiplePackagesDiscount.configMultiPackage;
                this.level22 = data.MultiplePackagesDiscount.configMultiPackage.discountForTwoPackage.secondPackageDiscount;
                console.log(this.level22);
                this.level32 = dataPackage.discountForThreePackage.secondPackageDiscount;
                console.log(this.level32);
                this.level33 = dataPackage.discountForThreePackage.thirdPackageDiscount;
                this.level42 = dataPackage.discountForFourPackage.secondPackageDiscount;
                this.level43 = dataPackage.discountForFourPackage.thirdPackageDiscount;
                this.level44 = dataPackage.discountForFourPackage.forthPackageDiscount;
           }
   
            //    for(var item in dataPackage){
            //        console.log(item);
                //    for(var nextItem in item){
                //        console.log(nextItem);
                //    }
               
            //    if(data.MultiplePackagesDiscount !== null){
                   
                // var packageDiscData = data.MultiplePackagesDiscount.configMultiPackage;
                // console.log(packageDiscData);
                
            
           
           
       },

       getmedConsumeConfig(id){
            this.shwDtaTbl=false
           this.noOfRooms=[];
        //    this.headersNew = [];
        this.headersNew = [ 
                {text: 'scl4', displayval:'Service Category(Level 4)'},
                {text: 'hsl5',displayval:'Hospital Service(Level 5)'},
               
                {text: 'Exclusions',displayval:'Exclusions'},   
                {text: 'del',displayval:'Action'},
            ]

           var compnt_render=new Promise((resolve,reject)=>{

                this.getTariffById(id,tempData=>{
                    console.log(tempData,"tempdata")

                    this.packageDiscountData(tempData);
                    
                    if(tempData.hasOwnProperty('roomConfiguration')){
                        let roomTyp=tempData.roomConfiguration.roomConfiguration.hasOwnProperty('roomTypes')? this.chkplndataarry(tempData.roomConfiguration.roomConfiguration.roomTypes) : ''
                        this.temproomtype = roomTyp
                        
                        console.log("roomTyp  ", roomTyp)
                        // }
                        for(let i=0;i<roomTyp.length;i++){
                            this.noOfRooms.push(
                                {
                                    displayval:roomTyp[i].roomType,
                                    text:{ [i]:roomTyp[i].roomType}, 
                                    value:roomTyp[i].roomType,
                                    inputValue:''
                                }
                            )
                        }
                        console.log(this.noOfRooms,"this.noOfRooms")
                        this.headersNew.splice(2, 0, ...this.noOfRooms);
                        console.log( this.headersNew," this.headersNew")

                        if(tempData.hasOwnProperty('PackageCharges')&& tempData.PackageCharges!=null){
                            this.dscntPercntg=tempData.PackageCharges.hasOwnProperty('discount')? this.chkplndata(tempData.PackageCharges.discount) : ''.hasOwnProperty('discount')? this.chkplndata(tempData.PackageCharges.discount) : ''
                            if(tempData.PackageCharges.packageCharges.length!=0){
                                this.addCategory=[]
                                // this.noOfRooms=[]
                                  var roomholder = {}
                                  var roomtypes_data = []
                                for (let i=0;i<tempData.PackageCharges.packageCharges.length;i++){
                                    console.log(i,"inside temproomType ")
                                    // var tempnoOfRooms=[]
                                    // for(let j in tempData.ICUCharges.icuCharges[i].roomType){
                                    //     console.log(j,"inside j ")
                                        
                                    //     tempnoOfRooms.push(
                                    //         {   displayval:j,
                                    //             text:j, 
                                    //             value:j,
                                    //             inputValue:tempData.ICUCharges.icuCharges[i].roomType[j]
                                    //         }
                                    //     )
                                    // }
                                    // let tempNScl4 = this.medConsumablesLvl4.filter((e)=>{
                                    //     if(e._id===tempData.PackageCharges.packageCharges[i].levelId){
                                    //         return e
                                    //     }
                                    // })

                                  
                                    roomtypes_data = []
                                   tempData.PackageCharges.packageCharges[i].roomTypes.map((e,index)=>{
                                            roomholder = {}
                                            roomholder[index] = e.charges 

                                         roomtypes_data.push(roomholder)
                                        console.log(roomholder,"newonjis ", roomtypes_data);
                                       
                                    })

                                    console.log("roomtypes_data ", ...roomtypes_data);
                                    var finalrooms =  Object.assign({},...roomtypes_data)
                                    this.addCategory.push(
                                        {
                                           uniqueIdentifier:tempData.PackageCharges.packageCharges[i].uniqueIdentifier,
                                            _id:tempData.PackageCharges.packageCharges[i]._id,
                                            scl4:tempData.PackageCharges.packageCharges[i].serviceCategory,
                                            hsl5:tempData.PackageCharges.packageCharges[i].hospitalService,
                                            // Inclusions:tempData.PackageCharges.packageCharges[i].inclusion,
                                            Exclusions:tempData.PackageCharges.packageCharges[i].exclusion,
                                            tarrif:tempData.PackageCharges.packageCharges[i].tariff,
                                            ...finalrooms
                                            
                                            // ...tempData.ICUCharges.icuCharges[i].roomTypes,

                                        }
                                        
                                    )
                                }
                                // console.log(tempnoOfRooms,"tempnoOfRooms")
                                console.log(this.addCategory,"this.addCategory");
                                
                                this.addCategoryArr = this.addCategory;
                                console.log(this.addCategoryArr.length);
                                console.log(this.addCategory.length);
                                if(this.addCategory.length > 1){
                                    this.isDisablePackage = true;
                                }
                                // for(let i = 0; i < this.addCategory.length; i++){
                                //     this.level5 = this.addCategory[i].scl4.level4;
                                //     this.level4Inc = this.addCategory[i].hsl5;
                                // }
                                // var newCategory = this.addCategory.map((item) => {
                                //     return item.scl4.level4;
                                // })
                                // var newDataMap = newCategory.toString();
                                // console.log(newDataMap);
                                // this.level5 = newDataMap;
                                
                            }
                        }
                        resolve(true)
                    }

                })

           })
           console.log("When did it ran")
            compnt_render.then(res=>{
                console.log(res,"Resolve Promise")
                this.shwDtaTbl=true
            })
           
       },

       chkplndata(data){
            console.log("chkplndata",data); 
            try{
                if (data ==="" || data === "undefined" || data === null) {
                    return ""
                } else {
                    return data;
                }

             }catch(err) {
                console.log(err)
            }
            
        },

        
        chkplndataarry(data){
            console.log("chkplndataarry",data); 
            try{
                if (data ==="" || data === "undefined" || data === null) {
                    return []
                } else {
                    return data;
                }

             }catch(err) {
                console.log(err)
            }
            
        },

        Numeric3(v){
            if (v.length !== 0) {
                console.log("testing",/^[0-9 ]+$/.test(v))
                if(!/^[0-9 ]+$/.test(v)){
                    return "Only Numbers are allowed."
                }else{
                    console.log("else none") 
                }
            }
        },
       Numeric2(v){
            if (v.length !== 0) {
                console.log("testing",/^[0-9 ]+$/.test(v))
                if(!/^[0-9. ]+$/.test(v)){
                    return "Only Numbers are allowed."
                }else if(!/^\d+(\.\d{1,2})?$/.test(v)){
                    return "Numeric upto two decimals are allowed."
                }else{
                console.log("else none")  
                }
            }
        },
        dscntPrcnt(v){
            if (v.length !== 0) {
                console.log("testing",/^[0-9 ]+$/.test(v))
                if(!/^(100(?:\.00?)?|\d?\d(?:\.\d\d?)?)$/.test(v)){
                    return "Numeric between 0 - 100 and upto two decimals are allowed."
                }else{
                console.log("else none")  
                }
            }

        },
        Numeric5(v){
            if(parseInt(v)>100){
                return v => !v || 'Only values between 0 to 100 are accepted'
            }
        },
        save_ot_confg(){
            console.log(this.addCategory.length);
            console.log(this.addCategory);
            this.disablePackageArr = this.addCategory;
            // if (!this.$refs.otForm.validate()){
            //     this.$refs.otForm.validate()
            //     this.showToast("Please Fill Required Fields",this.TOST().WARNING);
            //     return
            // } 
            this.$refs.PackageCharges.save_click();
            this.isDisable = false;
            // this.$router.push('/reviewCharges')

        }
     }
}
</script>

<style>

.beauty input {
    background-color: #fff;
    padding: 5px 10px;
    margin-top:13px;
    border-radius: 3px;
    box-shadow:0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%) ;
}

.beauty > .autosuggest__results-container{
    background-color: #fff;
}

</style>