<template>
    <v-app>
        <v-container class="containerStyle" style="padding:1.5rem; margin:0; max-width:100%">
            <v-layout>
                <h3>Search Service Provider Master</h3>
                <v-spacer></v-spacer>
                <v-btn class="btnToggle"
                @click="createMaster"
                        outlined
                        elevation="0">
                        <v-icon>mdi-plus-box-outline</v-icon>
                        <v-divider></v-divider>
                        Create Service Provider
                </v-btn>
            </v-layout>
            <v-layout class="mt-8">
                <h4 class="mr-3"><strong>Service provider Master <span class="orange--text">({{count}})</span></strong></h4>
      <v-chip small color="#1EBBD9" text-color="white" label close
                     v-if="showSearchChip==true"
                     @click:close="clear();searchServiceProviderData(1)">Search</v-chip>
                     <!-- v-if="showSearchChip==true" 
                     -->
                <v-spacer></v-spacer>
                <!-- <v-btn depressed
                color="#E46A25"
                class="white--text mr-3"><v-icon>
                    mdi-file-excel
                </v-icon>
                <v-divider class="mx-4"
                vertical
                color="white"></v-divider>Export to Excel</v-btn> -->

              </v-layout>
              <v-form
              width="1150">
              <v-card>
                <!-- <v-card-title class="white--text"
                style="background-color: #23b1a9">
                <h4>Search Service Provider</h4>
                <v-spacer></v-spacer>
                    <v-icon @click="search_Dialog=!search_Dialog" class="white--text">mdi-window-close</v-icon>
                </v-card-title> -->
                <v-card-text class="white lighten-2">
                    <v-layout  class="pt-4 form-groups-container">
                        <v-flex class="form-group">
                            <label class="form-label">Line Of Business</label>
                            <v-select
                            solo
                            :items="lob_Items"
                            dense
                            class="form-control"
                            v-model="lob"
                            placeholder="Select...">
                        </v-select>
                        </v-flex>
                        <v-flex class="form-group">
                            <label class="form-label">Service Provider Code</label>
                            <!-- <v-text-field 
                              solo 
                              dense
                               class="form-control"
                                v-model="serviceProviderCode" 
                                :rules="[(v)=>matchRegex(v,'alphanum')]"
                                 placeholder="Enter..."
                                 ></v-text-field> -->
                       
                            <v-autocomplete
              :search-input.sync="searchserviceProviderId"
              :maxlength="138"
              selection
              class="forminput"
              placeholder="Start typing to Search"
              solo
              dense
              :items="serviceProviderIdData"
              item-text="serviceProviderId"
              item-value="serviceProviderId"
              deletable-chips
              v-model="serviceProviderCode"
              :rules="[(v) => matchRegex(v,'alphanum')]"
              @keyup="getServiceCode(searchserviceProviderId)"
            >
              </v-autocomplete>
                       
                          </v-flex>
                        <v-flex class="form-group">
                            <label class="form-label">Service Provider Name</label>
                            <!-- <v-text-field solo dense class="form-control" :rules="[(v)=>matchRegex(v,'alphanum')]" v-model="serviceProviderName" placeholder="Enter..."></v-text-field> -->
                          
                            <v-autocomplete
              :search-input.sync="searchreassignAllExaminer"
              :maxlength="138"
              class="forminput"
              placeholder="Start typing to Search"
              solo
              dense
              :items="Examineritems"
              item-text="serviceProviderDetail"
              item-value="serviceProviderDetails.employeeName"
              v-model="serviceProviderName"
              clearable 
              @click:clear="$nextTick(() => clearData())"
            >
              </v-autocomplete>
                          </v-flex>
                        <v-flex class="form-group">
                            <label class="form-label">Bank Name</label>
                            <!-- <v-text-field solo dense class="form-control" :rules="[(v)=>matchRegex(v,'alpha')]" v-model="bankName" placeholder="Enter..."></v-text-field> -->
                       
                            <v-autocomplete
              :search-input.sync="searchserviceProviderbankName"
              :maxlength="138"
              selection
              class="forminput"
              placeholder="Start typing to Search"
              solo
              dense
              :items="serviceProviderbankData"
              item-text="bankDetails.bankName"
              item-value="bankDetails.bankName"
              deletable-chips
               v-model="bankName"
               :rules="[(v) => matchRegex(v,'alphanum')]"
              clearable
              @click:clear="$nextTick(() => bankName='')"
              @keyup="getBank(searchserviceProviderbankName)"
              ></v-autocomplete>

                          </v-flex>
                        <v-flex class="form-group">
                            <label class="form-label">PAN</label>
                            <!-- <v-text-field solo dense class="form-control" :rules="[(v) => validatePanNumber(v, 'PAN Number')]" :value="computedPAN" v-model="panNumber" placeholder="Enter..."></v-text-field> -->
                        
                            <v-autocomplete
              :search-input.sync="searchserviceProviderpanNumber"
              :maxlength="138"
              selection
              class="forminput"
              placeholder="Start typing to Search"
              solo
              dense
              :items="serviceProviderpanData"
              item-text="panNumber"
              item-value="panNumber"
              deletable-chips
              v-model="panNumber"
              clearable
              @click:clear="$nextTick(() => panNumber='')"
              @keyup="getPanNumber(searchserviceProviderpanNumber)"
              >
              </v-autocomplete>
                          </v-flex>
                    </v-layout>

                        <v-layout  class="pt-4 form-groups-container">
                        <v-flex class="form-group">
                            <label class="form-label">GST Number</label>
                            <!-- <v-text-field solo dense class="form-control" :rules="[(v)=>matchRegex(v,'alphanum')]" v-model="gstinNumber" placeholder="Enter..."></v-text-field> -->
                        
                            <v-autocomplete
              :search-input.sync="searchserviceProvidergstinNumber"
              :maxlength="138"
              selection
              class="forminput"
              placeholder="Start typing to Search"
              solo
              dense
              :items="serviceProvidergstData"
              item-text="gstinNumber"
              item-value="gstinNumber"
              deletable-chips
              v-model="gstinNumber"
              :rules="[(v) => matchRegex(v,'alphanum')]"
              clearable
              @click:clear="$nextTick(() => gstinNumber='')"
              @keyup="getGstNumber(searchserviceProvidergstinNumber)"
              ></v-autocomplete>

                          </v-flex>
                        <v-flex class="form-group">
                            <label class="form-label">City</label>
                            <!-- <v-text-field solo dense class="form-control" :rules="[(v)=>matchRegex(v,'alpha')]" v-model="spCity" placeholder="Enter..."></v-text-field> -->
                        
                            <v-autocomplete
              :search-input.sync="searchserviceProvidercity"
              :maxlength="138"
              class="forminput"
              placeholder="Start typing to Search"
              solo
              dense
              :items="serviceProvidercityData"
              item-text="spAddress[0].spCity"
              item-value="spAddress[0].spCity"
              deletable-chips
              v-model="spCity"
              clearable
              @click:clear="$nextTick(() => spCity='')"
              @keyup="getCity(searchserviceProvidercity)"
              ></v-autocomplete>

                          </v-flex>
                        <v-flex class="form-group">
                            <label class="form-label">PinCode</label>
                            <!-- <v-text-field solo dense class="form-control" :rules="[(v)=>matchRegex(v,'nums')]" v-model="spPincode" placeholder="Enter..."></v-text-field> -->
                        
                            <v-autocomplete
              :search-input.sync="searchserviceProviderpincode"
              :maxlength="138"
              selection
              class="forminput"
              placeholder="Start typing to Search"
              solo
              dense
              :items="serviceProviderpincodeData"
              item-text="spAddress[0].spPincode"
              item-value="spAddress[0].spPincode"
              deletable-chips
              v-model="spPincode"
              :rules="[(v) => matchRegex(v,'nums')]"
              clearable
              @keyup="getPincode(searchserviceProviderpincode)"
              >
            </v-autocomplete>

                          </v-flex>
                        <v-flex class="form-group">
                            <label class="form-label">Service Provider Type</label>
                            <v-select 
                            solo 
                            dense
                             :items="serviceProviderItems" 
                             class="form-control"
                              v-model="serviceProviderType"
                               placeholder="select"
                               ></v-select>
                        </v-flex>
                        <v-flex class="form-group">
                            <label class="form-label">Licence Expiry Date</label>
                            <v-menu
                                v-model="menu1"
                                offset-y
                                nudge-top="20"
                                :close-on-content-click="false"
                                min-width="auto"
                                 >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        :value="computedlicenseExpiryDate"
                                        placeholder="DD/MM/YYYY"
                                        dense
                                        solo
                                        readonly
                                        v-on="on"
                                        @focus="menu1 = true"
                                        @blur="menu1 = true"
                                        v-bind="attrs"
                                        class="form-control"
                                        append-icon="mdi-menu-down"
                                        prepend-inner-icon="mdi-calendar"
                                        >
                                     </v-text-field>
                                </template>
                                <v-date-picker
                                    no-title
                                    :pickerDate.sync="pickerDate"
                                    v-model="licenseExpiryDate"
                                    @input="menu1 = false"
                                >
                                </v-date-picker>
                            </v-menu>
                        </v-flex>
                    </v-layout>
                        <v-layout wrap class="pt-4 form-groups-container">
                        <v-flex class="form-group">
                            <label class="form-label">Status</label>
                            <v-select solo dense class="form-control" :items="status_Items" v-model="approveStatus" placeholder="select"></v-select>
                        </v-flex>
                        <v-flex class="form-group">
                            <label class="form-label">Mobile Number</label>
                            <v-text-field solo dense class="form-control" :maxlength="10" v-model="mobileNumber" :rules="[(v)=>matchRegex(v,'nums'),(v) => validateLength(v, { exact: 10 }, '', 'Field requires 10 digits.')]" placeholder="select"></v-text-field>
                        </v-flex>
                        <v-flex class="form-group">
                            <label class="form-label">Is External Service Provider</label>
                            <v-radio-group row solo dense class="form-control mt-0" v-model="isExternalServiceProvider">
                                <v-radio label="YES" value="Yes">YES</v-radio>
                                <v-radio label="NO" value="No">NO</v-radio>
                            </v-radio-group>
                        </v-flex>
                        <v-flex class="form-groupss">
                            <label class="form-label">Active/Inactive Status</label>
                            <v-radio-group row solo dense class="form-control mt-0" v-model="active" >
                                <v-radio label="Active" value="true">YES</v-radio>
                                <v-radio label="Inactive" value="false">NO</v-radio>
                            </v-radio-group>
                        </v-flex>
                    </v-layout>
                </v-card-text>
                <v-card-actions >
                  <!-- <v-btn class="reset createbtn" style="background:white" dark @click="clear()">Reset</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn class="createbtn cancelbtn" @click="search_Dialog=!search_Dialog;clear()">Cancel</v-btn>
                  <v-btn color="#23b1a9" class="createbtn white--text" @click="searchServiceProviderData(1)">Search</v-btn> -->
                </v-card-actions>
              </v-card>
            </v-form>
            <v-row class="mt-2 mb-4">
        <v-flex justify="end">
          <v-row class="btnDiv">
            <v-col>
                <v-btn outlined color="red" class="btn" @click="cancel"  v-if="!Display_search_table">
                  <v-icon> mdi-backspace </v-icon>
                  <v-divider vertical class="mx-2"></v-divider> 
                  Cancel
                </v-btn>
            </v-col>
            <v-col align="right">
            <v-btn
            class="create-roe-resetbtn text-capitalize  ml-8 mr-3 btnStyle"
            color="white"
            @click="clear()"
          >
            <v-icon class="" color="">mdi-backspace</v-icon>
            <v-divider class="mx-2" vertical color=""></v-divider>
            Reset
          </v-btn>

          <v-btn
            elevation=""
            color="#23B1A9"
            dark
            class="btnStyle mr-6  text-capitalize"
            type="submit"
            @click="searchServiceProviderData(1)"
          >
            <v-icon class="mr-2 createfont">mdi-magnify</v-icon>
            <div class="vertical_line"></div>
            Search
          </v-btn>
                 
            </v-col>
          </v-row>
        </v-flex>
      </v-row>
            <!-- <v-btn depressed align-end
            color="teal white--text" @click="search_Dialog=!search_Dialog"> 
            <v-icon>mdi-arrow-right-circle</v-icon>
            <v-divider class="mx-4"
            vertical
            color="white" ></v-divider>
              Search</v-btn> -->
              
    <!-- Main Table -->
    <v-data-table
    fixed-header
    height="500px"
    hide-default-footer
    :headers="main_Table_Header"
    :items="main_Table_Data"
    class="mt-5 vDataTableStyling"
    v-if="Display_search_table"
    
    >
    <template v-slot:[`item.actions`]="{ item }">
                      <v-menu offset-y open-on-hover>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            elevation=""
                            icon
                            v-bind="attrs"
                            v-on="on"
                            style="text-align:center"
                          >
                            <v-icon>mdi mdi-dots-vertical</v-icon>
                          </v-btn>
                        </template>
                        
                        <v-list class="item-actions" >
                         <template >
                          <v-list-item
                           v-for="(actions, index) in actionOptions"
                            :key="index"
                            @click="editmaster(actions.link, index, item, item._id, actions.name)"
                          >
                          <!-- :disabled="actions.name === 'MODIFY' && ['Pending for approval'].includes(item.approveStatus)" -->
                          <!-- :style="(actions.name === 'MODIFY' && ['Pending for approval'].includes(item.approveStatus)) ? 'gray' : actions.color" -->
                          <!-- :disabled="actions.name === 'MODIFY' && !['', 'Saved'].includes(item.paymentStatus)" -->
                            <v-list-item-title :style="actions.color"
                              ><v-icon small>{{ actions.icon }}</v-icon>
                               {{ actions.name }}</v-list-item-title
                            >
                          </v-list-item>
                          </template>
                        </v-list>
                      </v-menu>
                    </template>
                    <template v-slot:[`item.licenseExpiryDate`]="{item}">
                      {{dateFormat(item.licenseExpiryDate)}}
                    </template>
                    <template v-slot:[`item.active`]="{item}">
                      {{item.active ? 'Active' : 'Inactive'}}
                    </template>
                    <template v-slot:[`item.mobileNumber`]="{item}">
                      <template v-for="number in item.mobileNumber">
                      {{number.number}}</template>
                    </template>
    </v-data-table>
     <!-- Pagination -->
     <v-layout justify-end align-center class="pb-2 px-0 mr-4"  v-if="Display_search_table">
      <v-row>
    <v-col>
      <v-btn outlined color="red" class="btn" @click="cancel">
        <v-icon> mdi-backspace </v-icon>
        <v-divider vertical class="mx-2"></v-divider> 
        Cancel
      </v-btn>
    </v-col>
  </v-row>
              <div>
                <span style="color:orange">Showing {{ pageStartIndex_Provider }}-{{ pageEndIndex_Provider }}</span>
                out of {{ totalListCount_Provider }} records
              </div>
              <div>
                <v-pagination  v-model="page_Provider" 
                :length="pageCount_Provider" 
                :total-visible="5" 
                @input="searchServiceProviderData(page_Provider)" color="#152F38"
                ></v-pagination>
              </div>
              
            </v-layout>
            <!-- For Audit Trail -->
<v-dialog v-model="audit_dialog" width="800">
      <v-card>
        <v-card-title class="white--text" style="background-color: #23b1a9">
          Audit Trail
          <v-spacer></v-spacer>
          <v-icon @click="audit_dialog = !audit_dialog" class="white--text"
            >mdi-window-close</v-icon
          >
        </v-card-title>
        <v-layout class="pt-4 form-group-container pr-4">
          <v-data-table
            :headers="audit_Header"
            hide-default-footer
            :items="audit_Data"
            class="vDataTableStyling w-100"
          >
            <template v-slot:[`item.srno`]="{ index }">
              {{ 1 + index }}
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-btn
                v-if="item.action === 'Modified'"
                color="#FFFFFF"
                class="text-capitalize tablebtn"
                @click="seeModify(item)"
              >
                {{ item.action }}
                <v-icon class="tableicons">mdi-arrow-right-circle</v-icon>
              </v-btn>
              {{ item.action !== "Modified" ? item.action : null }}
            </template>
            <template v-slot:[`item.createdAt`]="{ item }">
              {{ formatDateTime(item.createdAt) }}
            </template>
          </v-data-table>
        </v-layout>
        <v-layout justify-end align-center class="pb-2 px-0 mr-4">
          <div>
            <span style="color: orange"
              >Showing {{ AuditPageStartIndex }}-{{  }}</span
            >
            out of {{ AuditTotalListCount }} records
          </div>
          <div>
            <v-pagination
              class="small-pagination"
              v-model="AuditPage"
              :length="AuditPageCount"
              :total-visible="5"
              @input="getAuditData(AuditPage, '', true)"
              color="#152F38"
            ></v-pagination>
          </div>
        </v-layout>
      </v-card>
    </v-dialog>
    <!-- Modify dialogue -->
    <v-dialog v-model="seeModifyDialog" width="600">
      <v-card>
        <v-card-title class="white--text"
                    style="background-color: #23b1a9">
          <v-layout justify-space-between>
            <div>
              Modified Field Details
            </div>
          </v-layout>
          <v-btn color="transparent" small :ripple="false" depressed @click="seeModifyDialog = false">
            <v-icon color="white">mdi-close </v-icon>
          </v-btn>
        </v-card-title>
        <div class="scrollable-table">
        <v-data-table disable-pagination :headers="modifyHeaders" 
        :items="modifyItems" hide-default-footer class="pa-4 vDataTableStyling">
        <template v-slot:[`item.srNo`]="{ index }">
                {{ 1 + index }}
              </template>
              
                <!-- <template v-slot:[`item.oldValue`]="{item}">
                  {{item.fieldName=='License Expiry Date'?dateFormat(item.oldValue):item.oldValue}}
                </template>
                <template v-slot:[`item.newValue`]="{item}">
                  {{item.fieldName=='License Expiry Date'?dateFormat(item.newValue):item.newValue}}
                </template> -->
              
      </v-data-table>

        </div>
      </v-card>
    </v-dialog>
    <!-- Audit Trail -->
    <audit-trail
      :dialogState="OpenAuditDialog"
      :auditTrailMaster="'serviceProvider'"
      :auditTrailItem="{ uid: auditTrailId }"
      @close-dialog="OpenAuditDialog = false"
    />
        </v-container>
    </v-app>
</template>
<script>
import moment from 'moment'
import auditTrail from '../../../component/MasterAuditTrail.vue'
export default{
    components:{
        auditTrail,
    },
    
   data(){
    return{
        search_Dialog:false,
        Display_search_table:false,
        //Table_Header
        main_Table_Header:[{text:"Action",value:"actions",sortable:false,align:"center"},
        {text:"Master ID",value:"serviceProviderId",sortable:false,align:"center"},
        {text:"LOB",value:"lob",sortable:false,align:"center", width: 100},
        {text:"Name",value:"serviceProviderDetails.employeeName",sortable:false,align:"center"},
        {text:"Line Of Business",value:"lob",sortable:false,align:"center"},
        {text:"Is External Service Provider",value:"isExternalServiceProvider",sortable:false,align:"center"},
        {text:"Service Provider Type",value:"serviceProviderType",sortable:false,align:"center"},
        {text:"PAN No",value:"panNumber",sortable:false,align:"center"},
        {text:"GST No",value:"gstinNumber",sortable:false,align:"center"},
        {text:"Bank Name",value:"bankDetails.bankName",sortable:false,align:"center"},
        {text:"License Expiry Date",value:"licenseExpiryDate",sortable:false,align:"center",width:"110px"},
        {text:"Mobile Number",value:"mobileNumber",sortable:false,align:"center"},
        {text:"Is External Service Provider",value:"isExternalServiceProvider",sortable:false,align:"center"},
        {text:"Active status",value:"active",sortable:false,align:"center"},
        {text:"City",value:"spAddress[0].spCity",sortable:false,align:"center"},
        {text:"State",value:"spAddress[0].spState",sortable:false,align:"center"},
        {text:"District",value:"spAddress[0].spDistrict",sortable:false,align:"center"},
        {text:"Pincode",value:"spAddress[0].spPincode",sortable:false,align:"center"},
        {text:"Approved/Pending",value:"approveStatus",sortable:false,align:"center"},
        {text: "F & A Status",value: "panApprovalStatus",sortable: false, align: 'center' }],
        main_Table_Data:[],
        audit_Data:[],
      modifyItems: [],
      audit_Header:[{text:"Sr. No.",value:"srno",sortable:false,align:"center"},
      {text:"Master ID",value:"masterId",sortable:false,align:"center"},
      {text:"Action Taken",value:"action",sortable:false,align:"center"},
      {text:"User ID",value:"userId",sortable:false,align:"center"},
    {text:"User Name",value:"userName",sortable:false,align:"center"},
    {text:"Time Stamp",value:"createdAt",sortable:false,align:"center"}],
    modifyHeaders: [
        {
          text: "Sr. No.",
          value: "srNo",
          align: "center",
          sortable: false,
        },
        {
          text: "Field Name",
          value: "fieldName",
          align: "center",
          sortable: false,
        },
        {
          text: "Old Value",
          value: "oldValue",
          align: "center",
          sortable: false,
        },
        {
          text: "New Value",
          value: "newValue",
          align: "center",
          sortable: false,
        },
      ],
      seeModifyDialog:false,
      itemsPerPage: 10,
      totalListCount: 0,
      pageStartIndex: 1,
      pageCount: 0,
      page: 1,
        actionOptions: [
                {
                name: "VIEW",
                icon: "mdi-eye-outline",
                // link: "/childrenEducationCoverAddAssessment",
                color: "color:#23b1a9",
                iconColor: "color:#10242B",
                },
                {
                name: "MODIFY",
                icon: "mdi-pencil",
                // link: "/modifypage",
                iconColor: "color:#10242B",
                color: "color:#23b1a9",
                },
                {
                name: "AUDIT TRAIL",
                icon: "mdi-chart-timeline-variant",
                // link: "/modifypage",
                color: "color:orange",
                iconColor: "color:orange",
                }],
               
                audit_dialog:false,
                count:0,
                panNumber:"",
                spCity:"",
                serviceProviderName:"",
                spPincode:"",
                approveStatus:"",
                serviceProviderType:"",
                lob:"",
                active:"",
                isExternalServiceProvider:"",
                gstinNumber:"",
                bankName:"",
                mobileNumber:"",
                licenseExpiryDate:"",
                serviceProviderCode:"",
                pickerDate:null,
                serviceProviderItems:['External Surveyor', 'Investigator', 'Lawyer/Legal Expert', 'Doctor', 
                'Frequent Payee', 'Recovery Agent', 'In - House with License', 'In - House without  License', 
                'SIU', 'Structural Engineer/expert', 'Drying, Cleaning experts', 'Chartered Engineers', 
                'Chartered Accountants/Tax', 'Experts', 'Forensic Science experts',
                 'Forensic accounting experts', 'Drone Operators', 'Other Expert'],
                 menu1:false,
                 showSearchChip:false,
                 lob_Items:['Health','Marine','AIGC','EW','Travel','PA','Home'],
                 status_Items:["Approved","Rejected",'Pending for approval'],
                 //pagination
                 itemsPerPage_Provider: 10,
                 totalListCount_Provider: 0,
                 pageStartIndex_Provider: 1,
                 page_Provider: 1,

                  
                  searchserviceProviderId:"",
                  serviceProviderIdData:[],
                  searchreassignAllExaminer: "",
                  Examineritems: [],
                  searchserviceProviderbankName:"",
                  serviceProviderbankData:[],
                  searchserviceProviderpanNumber:"",
                  serviceProviderpanData:[],
                  searchserviceProvidergstinNumber:"",
                  serviceProvidergstData:[],
                  searchserviceProvidercity:"",
                  serviceProvidercityData:[],
                  searchserviceProviderpincode:"",
                  serviceProviderpincodeData:[],
      AuditPage: 1,
      AuditTotalListCount: 0,
      AuditPageCount: 0,
      AuditPageStartIndex: 0,
      AuditItemsPerPage: 10,
      OpenAuditDialog: false,
      auditTrailId: null,
    }
   },

   watch : {
    searchreassignAllExaminer(value) {
      if (value) {
        let  val =  (value.split("-")[0]).trim()
        if (val.length > 2) {
          let requestData ="&serviceProviderName="+val;
          this.GETM(
            "SERVICE_PROVIDER_MASTER_SEARCH",
            requestData,
            (res) => {
              this.Examineritems = [];
              if (res.data.statusCode == 200) {

                  let name = res.data.data.data
          this.Examineritems = name.map((n) => {
            let firstName = n.serviceProviderDetails.employeeName +"-" + n.spAddress[0].spCity + "-" + n.panNumber
            n.serviceProviderDetail = firstName 
            return n
          })
              	// this.Examineritems = processed_pin.filter(x=>x.locpincode)
                console.log(" ddddddddddddddd ", this.Examineritems)
            
					
              }
            },
            (error) => {
              this.Examineritems = [];
            },
          );
        }
      }else{
        this.Examineritems = [];
      }
      // if(value.length > 2){
      //     console.log("this.main_Table_Data",[...this.main_Table_Data])
      //     let name = [...this.main_Table_Data]
      //     this.Examineritems = name.map((n) => {
      //       let firstName = n.serviceProviderDetails.employeeName +"-" + n.spAddress[0].spCity + "-" + n.panNumber
      //       n.serviceProviderDetail = firstName 
      //       return n
      //     })
      //     // this.Examineritems = this.main_Table_Data 
      //   }else{
      //     this.Examineritems = []
      //   }
    },

   },
   methods:{
    clearData(){
       this.panNumber=""
       this.gstinNumber=""
       this.spCity=""
    },

    getServiceCode(code){
        // if(serviceProviderName){
        //  let indexTtem = this.Examineritems.find((elem) => {
        //     return elem.serviceProviderDetails.employeeName == (serviceProviderName.split("-")[0]).trim();
        //   });
        //   console.log(indexTtem)
        //    //this.serviceProviderbankData.push(indexTtem) 
        //    this.serviceProviderpanData.push(indexTtem)
        //    this.serviceProvidergstData.push(indexTtem)
        //    this.serviceProvidercityData.push(indexTtem)
        //    //this.serviceProviderpincodeData.push(indexTtem) 
        //   if(indexTtem){
        //     this.serviceProviderObj.gstinNumber=indexTtem.gstinNumber;
        //     this.serviceProviderObj.panNumber=indexTtem.panNumber;
        //    // this.serviceProviderObj.spCity=indexTtem.bankDetails.spCity; 
        //     if(indexTtem.spAddress.length>0){
        //       this.serviceProviderObj.spCity = indexTtem.spAddress[0].spCity
              
        //     }
        //     // this.serviceProviderObj.bankName=indexTtem.bankDetails.bankName; 
        //     // this.serviceProviderObj.spPincode=indexTtem.bankDetails.spPincode
        //   }
        // }
        if(code.length > 2){
          this.serviceProviderIdData = this.main_Table_Data
        }else{
          this.serviceProviderIdData = []
        }
    },

    //   searchserviceProviderId(value){
    //    if (value) {
    //     if (value.length > 2) {
    //       let requestData = "&pageNo=1"+"&perPage=50&"+"&serviceProviderId="+value;
    //       this.GET(
    //         "SERVICE_PROVIDER_MASTER_SEARCH",
    //         requestData,
    //         (res) => {
    //           this.serviceProviderIdData = [];
    //           if (res.data.data && res.data.data.data) {
    //             this.serviceProviderIdData = res.data.data.data;
    //           }
    //         },
    //         (error) => {
    //           this.serviceProviderIdData = [];
    //         },
    //       );
    //     }
    //   }
    // },

    
    getBank(value){
      //   if (value) {
      //   if (value.length > 2) {
      //     let requestData = "&pageNo=1"+"&perPage=50&"+"&bankName="+value;
      //     this.GET(
      //       "SERVICE_PROVIDER_MASTER_SEARCH",
      //       requestData,
      //       (res) => {
      //         this.serviceProviderbankData = [];
      //         if (res.data.data && res.data.data.data) {
      //           this.serviceProviderbankData = res.data.data.data;
      //         }
      //       },
      //       (error) => {
      //         this.serviceProviderbankData = [];
      //       },
      //     );
      //   }
      // }

      if(value.length > 2){
          this.serviceProviderbankData = this.main_Table_Data
        }else{
          this.serviceProviderbankData = []
        }
    },

    getPanNumber(value){
      // if (value) {
      //   if (value.length > 2) {
      //     let requestData = "&pageNo=1"+"&perPage=50&"+"&panNumber="+value;
      //     this.GET(
      //       "SERVICE_PROVIDER_MASTER_SEARCH",
      //       requestData,
      //       (res) => {
      //         this.serviceProviderpanData = [];
      //         if (res.data.data && res.data.data.data) {
      //           this.serviceProviderpanData = res.data.data.data;
      //         }
      //       },
      //       (error) => {
      //         this.serviceProviderpanData = [];
      //       },
      //     );
      //   }
      // }
      if(value.length > 2){
          this.serviceProviderpanData = this.main_Table_Data
        }else{
          this.serviceProviderpanData = []
        }
    },
    // panNumberRule(v) {
    //     if (v && !this.panNumberPattern.test(v)) {
    //       return "Enter valid PAN number";
    //     } else {
    //       return true;
    //     }
    //   },

    getGstNumber(value){
      // if (value) {
      //   if (value.length > 2) {
      //     let requestData = "&pageNo=1"+"&perPage=50&"+"&gstinNumber="+value;
      //     this.GET(
      //       "SERVICE_PROVIDER_MASTER_SEARCH",
      //       requestData,
      //       (res) => {
      //         this.serviceProvidergstData = [];
      //         if (res.data.data && res.data.data.data) {
      //           this.serviceProvidergstData = res.data.data.data;
      //         }
      //       },
      //       (error) => {
      //         this.serviceProvidergstData = [];
      //       },
      //     );
      //   }
      // }
      if(value.length > 2){
          this.serviceProvidergstData = this.main_Table_Data
        }else{
          this.serviceProvidergstData = []
        }
    },

    getCity(value){
      // if (value) {
      //   if (value.length > 2) {
      //     let requestData = "&pageNo=1"+"&perPage=50&"+"&spCity="+value;
      //     this.GET(
      //       "SERVICE_PROVIDER_MASTER_SEARCH",
      //       requestData,
      //       (res) => {
      //         this.serviceProvidercityData = [];
      //         if (res.data.data && res.data.data.data) {
      //           this.serviceProvidercityData = res.data.data.data;
      //         }
      //       },
      //       (error) => {
      //         this.serviceProvidercityData = [];
      //       },
      //     );
      //   }
      // }
      if(value.length > 2){
        console.log("this.main_Table_Data.spAddress",this.main_Table_Data)
          this.serviceProvidercityData = this.main_Table_Data
        }else{
          this.serviceProvidercityData = []
        }
    },

    getPincode(value){
      // if (value) {
      //   if (value.length > 2) {
      //     let requestData = "&pageNo=1"+"&perPage=50"+"&spPincode="+value;
      //     this.GET(
      //       "SERVICE_PROVIDER_MASTER_SEARCH",
      //       requestData,
      //       (res) => {
      //         this.serviceProviderpincodeData = [];
      //         if (res.data.data && res.data.data.data) {
      //           this.serviceProviderpincodeData = res.data.data.data;
      //         }
      //       },
      //       (error) => {
      //         this.serviceProviderpincodeData = [];
      //       },
      //     );
      //   }
      // }
      if(value.length > 2){
          this.serviceProviderpincodeData = this.main_Table_Data
        }else{
          this.serviceProviderpincodeData = []
        }
    },

    cancel(data) {
    this.showConfirm(
      "Confirmation",
      "Are you sure you want to leave?",
      "Yes",
      "No",
      (Yes) => {
        this.$router.push({ name: "Master" }); //params:{pageNo:this.pageNo}}
      }
    );
  },
    dateFormat(date) {
      
      return date ? moment(date).format("DD-MM-YYYY") : "";
    },
    editmaster(link, index, item, id, name,a) {
     if(index == 0){
      this.$router.push({
        name: "CreateServiceProvider",
        params: {
          isView: true,
          item: item,
        },
      });
     }
     if(index == 1){
      if(this.checkPermission()){
          return this.showAlert("You don't have permission to create or modify record.")
      }
      if (item.approveStatus == "Pending for approval") {
          this.showAlert('Approval for this record is pending');
      } else if (item.panApprovalStatus == "Pending for approval") {
          this.showAlert("Master record pending with F & A team for approval and hence master record is not allowed for editing.");
      } else {
        this.$router.push({
          name: "CreateServiceProvider",
          params: {
            isView: false,
            item: item,
            
          },
        });
      }
     }
     if(index == 2){
      this.OpenAuditDialog = true
      this.auditTrailId = item.serviceProviderId
      // this.audit_dialog=true
      // this.getAuditData(1,item.serviceProviderId,false)
     }
    //  console.log(a,name,id,link,item)
    },
    getAuditData(newPageNum,id,bool){
      let reqData =""
      if(bool){
        reqData = "perPage=10" + "&pageNo=" + newPageNum + "&masterType=serviceProvider"+"&masterId=" +this.audit_Data[0].masterId
      }else{
        reqData = "perPage=10" + "&pageNo=" + newPageNum + "&masterType=serviceProvider"+"&masterId=" +id
      }
      //"&masterId=" + this.auditTrailItem.uid + 
      this.GETM('getAudits',reqData,(res)=>{
        this.audit_Data=res.data.data.data
        this.AuditTotalListCount = res.data.data.total;
                this.AuditPageCount = res.data.data.total_pages;
                this.AuditPageStartIndex = newPageNum == 1 ? 1 : (newPageNum - 1) * this.AuditItemsPerPage + 1;
      }, () => {}, true)
    },
    formatDateTime(dateTime) {
      return dateTime ? moment(dateTime).format("DD-MM-YYYY LT") : "";
    },
    seeModify(item) {
      console.log(item.modification)
      this.seeModifyDialog = true;
       this.modifyItems = item.modification
      //  .map((m, index) => {
      //    let object  = { ...m, srNo: index + 1 };
      //    if(this.dateFields.includes(m.fieldName)){
      //      object.newValue = this.formatDateOnly(m.newValue)
      //      object.oldValue = this.formatDateOnly(m.oldValue)
      //    }
      //    return object;
      //  });
    },
    formatDate(date) {
      if (!date) return "";

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    searchServiceProviderData(num){
      this.Display_search_table=true
        
        // let reqData=""
        // if(this.panNumber.length>0||this.spCity.length>0||this.serviceProviderName.length>0||
        // this.spPincode.length>0||this.approveStatus.length>0||this.serviceProviderType.length>0||
        // this.lob.length>0||this.active.length>0
        // ||this.isExternalServiceProvider.length>0||this.gstinNumber.length>0||this.bankName.length>0||
        // this.mobileNumber.length>0||this.licenseExpiryDate.length>0||this.serviceProviderCode.length>0){
        //     reqData=`panNumber=${this.panNumber}&spCity=${this.spCity}
        // &serviceProviderName=${this.serviceProviderName}&spPincode=${this.spPincode}
        // &approveStatus=${this.approveStatus}&serviceProviderType=${this.serviceProviderType}
        // &lob=${this.lob}&active=${this.active}&isExternalServiceProvider=${this.isExternalServiceProvider}
        // &gstinNumber=${this.gstinNumber}&bankName=${this.bankName}&mobileNumber=${this.mobileNumber}
        // &licenseExpiryDate=${this.licenseExpiryDate}&serviceProviderCode=${this.serviceProviderCode}
        // &page=${num}&pageLimit=${this.itemsPerPage_Provider}`
        // this.showSearchChip=true
        // }else{
        //     reqData=`page=${num}&pageLimit=${this.itemsPerPage_Provider}`
        //     this.showSearchChip=false
        // }

        let reqData ="page=" + num + "&pageLimit=" + this.itemsPerPage_Provider;
      
     if(this.panNumber || this.spCity || this.serviceProviderName || this.spPincode || this.approveStatus ||
     this.serviceProviderType || this.lob || this.active || this.isExternalServiceProvider || this.gstinNumber ||
     this.bankName || this.mobileNumber || this.licenseExpiryDate || this.serviceProviderCode){
      if (this.panNumber) {
        reqData= reqData + "&panNumber=" + this.panNumber;
                }
      if (this.spCity) {
        reqData= reqData + "&spCity=" + this.spCity;
                }
      if (this.serviceProviderName) {
        reqData= reqData +"&serviceProviderName=" +this.serviceProviderName;
                }
      if (this.spPincode) {
        reqData= reqData +"&spPincode=" +this.spPincode;
                }
                if (this.approveStatus) {
        reqData= reqData +"&approveStatus=" +this.approveStatus;
                }
                if (this.serviceProviderType) {
        reqData= reqData +"&serviceProviderType=" +this.serviceProviderType;
                }
                if (this.lob) {
        reqData= reqData +"&lob=" +this.lob;
                }
                if (this.active) {
        reqData= reqData +"&active=" +this.active;
                }
                if (this.isExternalServiceProvider) {
        reqData= reqData +"&isExternalServiceProvider=" +this.isExternalServiceProvider;
                }
                if (this.gstinNumber) {
        reqData= reqData +"&gstinNumber=" +this.gstinNumber;
                }
                if (this.bankName) {
        reqData= reqData +"&bankName=" +this.bankName;
                }
                if (this.mobileNumber) {
        reqData= reqData +"&mobileNumber=" +this.mobileNumber;
                }
                if (this.licenseExpiryDate) {
        reqData= reqData +"&licenseExpiryDate=" +this.licenseExpiryDate;
                }
                if (this.serviceProviderCode) {
        reqData= reqData +"&serviceProviderCode=" +this.serviceProviderCode;
                }
        this.showSearchChip=true
     }else{
      let reqData ="page=" + num + "&pageLimit=" + this.itemsPerPage_Provider;
            this.showSearchChip=false
     }
        
        this.GETM("SERVICE_PROVIDER_MASTER_SEARCH",reqData,(res)=>{
          if(res.data.statusCode === 200){
            this.main_Table_Data=res.data.data.data
            this.count=res.data.data.total
            this.totalListCount_Provider = res.data.data.total;
            this.pageStartIndex_Provider = num == 1 ? 1 : (num - 1) * this.itemsPerPage_Provider + 1;
          } else {
            if(res.data.msg) this.showAlert("No Data Found");
            this.main_Table_Data= []
            this.count=0
            this.totalListCount_Provider = 0;
            this.pageStartIndex_Provider = 1;
          }
        
        
    },(error) => {
          return error;
        },true)
        this.search_Dialog=false
    },
    clear(){
       this.panNumber=""
       this.spCity=""
       this.serviceProviderName=""
       this.spPincode=""
       this.approveStatus=""
       this.serviceProviderType=""
       this.lob=""
       this.active=""
       this.isExternalServiceProvider=""
       this.gstinNumber=""
       this.bankName=""
       this.mobileNumber=""
       this.licenseExpiryDate=""
       this.serviceProviderCode=""
       const a=new Date().toISOString().split('T')[0]
   this.pickerDate=a
    },
    createMaster() {
      if(this.checkPermission()){
          return this.showAlert("You don't have permission to create or modify record.")
      }
      this.$router.push({name:'CreateServiceProvider'})
    }
   },
   computed:{
    pageEndIndex() {
      if (!this.audit_Data.length) {
        return 0;
      } else {
        return this.pageStartIndex + this.itemsPerPage <= this.totalListCount ? this.pageStartIndex + this.itemsPerPage - 1 : this.totalListCount;
      }
    },
    computedlicenseExpiryDate(){
      return this.formatDate(this.licenseExpiryDate);
    },
    computedPAN() {
            this.panNumber = this.panNumber ? this.panNumber.toUpperCase() : '';
            return this.panNumber;
        },
    getEndDate() {
     var endDate = new Date();
     return endDate.toISOString().slice(0,10)
    },
    pageCount_Provider() {
      if (!this.totalListCount_Provider) {
        return 0;
      } else {
        return Math.ceil(this.totalListCount_Provider / this.itemsPerPage_Provider);
      }
    },
    pageEndIndex_Provider() {
      if (!this.main_Table_Data) {
        return 0;
      } else {
        return this.pageStartIndex_Provider + this.itemsPerPage_Provider <= this.totalListCount_Provider ? this.pageStartIndex_Provider + this.itemsPerPage_Provider - 1 : this.totalListCount_Provider;
      }
    },
   },
   created(){
    this.$store.commit("SAVE_PAGE_HEADER", "Service Provider Master Search");
    let num=1
    // this.GETM("SERVICE_PROVIDER_MASTER_SEARCH","",(res)=>{
    //     this.main_Table_Data=res.data.data.data
    //     this.count=res.data.data.total
    //     this.count=res.data.data.total
    //     this.totalListCount_Provider = res.data.data.total;
    //     this.pageStartIndex_Provider = num == 1 ? 1 : (num - 1) * this.itemsPerPage_Provider + 1;
    // },(error) => {
    //       return error;
    //     },true)
    this.searchServiceProviderData(1)
   }
}
</script>

<style scoped>

.create-roe-resetbtn {
  color: #d1121b;
  border: 1px solid #d1121b !important;
}
.btnToggle {
  text-transform: none;
  color: #fff;
  background-color: #1ebbd9;
}
.form-groupss{
    max-width:200px;
    margin:0px 10px 0px 0px
}
.reset {
        background-color: white;
        border:1px solid #D1121B;
        color:#D1121B;
    }
.createbtn {
        text-transform: capitalize;
        letter-spacing: 0.4px;
        /* font-weight: 300;
        font-size: 13px !important; */
    }
.cancelbtn {
    background-color: white;
        border:1px solid #D1121B;
        color:#D1121B;
    }
    .tableicons {
  color: #23b1a9 !important;
}
.tablebtn {
  background: #ffffff 0% 0% no-repeat padding-box !important;
  box-shadow: inset 0px 2px 4px #00000052 !important;
  border: 0.5px solid #c1c8cc;
  border-radius: 18px !important;
  height: 30px !important;
  text-transform: capitalize !important;
  width: 100%;
  justify-content: space-between;
  font-size: small;
}

</style>