var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"background-color":"#FFFFFF"}},[_c('v-form',{ref:"icuForm",model:{value:(_vm.icuvalid),callback:function ($$v) {_vm.icuvalid=$$v},expression:"icuvalid"}},[_c('v-card',{attrs:{"tile":"","flat":""}},[_c('div',{staticClass:"px-4 pt-4"},[_c('v-flex',{attrs:{"xs3":""}},[_c('v-layout',{staticClass:"cust_pln_Icu mx-2",attrs:{"column":""}},[_c('p',{staticClass:"font-weight-bold cstm-clr-pln-Icu"},[_vm._v(" Discount Percentage ")]),_c('v-text-field',{staticClass:"rounded-0",attrs:{"solo":"","label":"Enter...","maxlength":_vm.max05,"disabled":_vm.fromview,"rules":[_vm.Numeric2]},model:{value:(_vm.dscntPercntg),callback:function ($$v) {_vm.dscntPercntg=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"dscntPercntg"}})],1)],1)],1),_c('div',{staticClass:"px-4 py-3",staticStyle:{"background-color":"#F7F7F7"}},[_c('p',{staticClass:"font-weight-bold cstm-clr-pln-Icu pa-0 ma-0"},[_vm._v("ICU Charges")])]),_c('v-divider'),_c('v-card',{attrs:{"tile":""}},[_c('div',[(_vm.shwDtaTbl===true)?_c('tableis',{ref:"Icuchrgs",attrs:{"tabledata":{
                            tabledata:_vm.addCategory,
                            headersdata:_vm.headersNew,
                            dd:_vm.icuLvl4,
                            tariffshw:false,
                            roomconfig:_vm.temproomtype,
                            req:true,
                            del_endpoint:'Icu charges',
                            url:'tariff/get-icu-charges'
                        }},on:{"save":_vm.saveIcuchrgs}}):_vm._e()],1)])],1),(!_vm.fromview)?_c('v-layout',{staticClass:"pa-4 mt-4",staticStyle:{"background-color":"#F7F7F7"},attrs:{"justify-end":""}},[_c('v-btn',{staticClass:"text-capitalize px-3 mx-2",staticStyle:{"font-size":"12px","background-color":"#152F38"},attrs:{"tile":"","dark":"","small":""},on:{"click":function($event){return _vm.add_Icu_ctgry()}}},[_c('v-icon',{attrs:{"dark":"","small":""}},[_vm._v("add_circle")]),_vm._v(" "),_c('v-divider',{staticClass:"mx-2",attrs:{"vertical":""}}),_vm._v("Add Category")],1),_c('v-btn',{staticClass:"text-capitalize px-3 mx-2",staticStyle:{"font-size":"12px","background-color":"#23B1A9"},attrs:{"tile":"","dark":"","small":""},on:{"click":function($event){return _vm.save_icu_confg()}}},[_c('v-icon',{attrs:{"dark":"","small":""}},[_vm._v("check_circle")]),_vm._v(" "),_c('v-divider',{staticClass:"mx-2",attrs:{"vertical":""}}),_vm._v(" Save")],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }