<template>
  <div id="concessionalPenal">
    <v-container class="container-style">
      <v-row class="header-alignment">
            <h3>Concessional and Penal for PAN Master</h3>
            <v-spacer></v-spacer>
            <v-badge color="orange" :content="bulkCount.toString()" overlap>
              <v-btn class="btn-toggle pull-right" outlined elevation="0" @click="switchToConcessionalPenalBulkUpload">
                <v-icon>mdi-repeat</v-icon>
                <v-divider class="mx-2 ml-2 white" vertical></v-divider>
                Switch to Bulk Upload
              </v-btn>
            </v-badge>
        </v-row>
      <v-layout no-gutters class="flex-wrap">
        <div
          v-for="card in masters"
          :key="card.textTitle"
          class="master-single-card"
          @click="changeClickedTab(card.textTitle)"
          :class="{ 'selected-tab-outline': card.textTitle == selectedTab }"
        >
          <div class="master-title">{{ card.textTitle }}</div>
          <sup
            ><span
              class="data-count"
              >{{ card.count }}</span
            ></sup
          >
        </div>
      </v-layout>
      <v-layout class="mt-6">
        <h4 class="mr-3">
          Concessional and Penal for PAN Master
          <span class="orange--text">({{ currentTabRateCount }})</span>
        </h4>
      </v-layout>
      <!-- Main Table -->
      <v-data-table
        hide-default-footer
        :headers="concessionalTableHeader"
        class="mt-4 vDataTableStyling"
        :items="concessionalPenalList"
        :page.sync="page"
        :items-per-page="itemsPerPage"
        must-sort
      >
        <template v-slot:[`item.action`]="{ item , index }">
          <template>
            <v-btn
              icon
              style="text-align: center"
              @click="setDialogBoxData(item, index, true)"
              ><v-icon>mdi-eye-outline</v-icon></v-btn
            >
          </template>
          <template>
            <v-row justify="center" class="">
              <v-dialog
                v-model="item.dialogBox"
                fullscreen
                :scrim="false"
                transition="dialog-bottom-transition"
                persistent
              >
                <v-card>
                  <v-toolbar class="dialog-navbar">
                    <div>Concessional and Penal for PAN Master</div>
                    <v-spacer></v-spacer>
                    <button @click="openDialogBox(item, false)">Close</button>
                  </v-toolbar>
                  <div class="dialog-content">
                    <div class="d-flex py-3 justify-space-between align-center">
                      <div class="approve-heading">
                        Approve / Reject Concessional and Penal Rate
                      </div>
                      <div>
                        <v-btn
                          outlined
                          color="red"
                          class="btn mr-5"
                          @click="reject_Dialog = true"
                          :disabled="!hasEditAccess"
                        >
                          <v-icon> mdi-backspace </v-icon>
                          <v-divider vertical class="mx-2"> </v-divider>
                          Reject</v-btn
                        >
                        <v-dialog v-model="reject_Dialog" v-if="selctedDataDialogId == item._id" width="600">
                          <v-card>
                            <v-card-title
                              class="white--text"
                              style="background-color: #23b1a9"
                              ><v-layout
                                style="
                                  display: flex;
                                  justify-content: center;
                                  margin-left: 170px;
                                "
                                >Reject
                              </v-layout>
                              <v-layout
                                style="display: flex; justify-content: end"
                              >
                                <v-icon
                                  class="white--text"
                                  @click="
                                    reject_Dialog = false;
                                    reason = '';
                                  "
                                  >mdi-close</v-icon
                                >
                              </v-layout>
                            </v-card-title>
                            <v-card-text>
                              <v-layout class="form-group-container justify-center my-3">
                                <h4>Are you sure you want to reject ?</h4>
                              </v-layout>
                            </v-card-text>
                            <v-form ref="rejectForm" @submit="false">
                              <v-layout class="form-group-container">
                                <v-flex class="mr-2">
                                  <label class="form-label">Reason</label>
                                  <v-text-field
                                    class="form-control"
                                    solo
                                    dense
                                    :rules="
                                      reject_Dialog
                                        ? [(v) => requiredField(v)]
                                        : []
                                    "
                                    v-model="reason"
                                    placeholder="Enter..."
                                  ></v-text-field>
                                </v-flex>
                              </v-layout>
                            </v-form>
                            <v-card-actions
                              style="display: flex; justify-content: center"
                            >
                              <v-btn
                                class="btn"
                                color="red white--text mr-0"
                                @click="
                                  reject_Dialog = false;
                                  reason = '';
                                "
                              >
                                No
                              </v-btn>

                              <v-btn
                                class="btn"
                                color="green white--text mr-0"
                                @click="submitApproval(item)"
                              >
                                Yes
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                        <v-btn
                          class="btn"
                          color="teal white--text"
                          dense
                          solo
                          @click="approve_Dialog = !approve_Dialog"
                          :disabled="!hasEditAccess"
                          ><v-icon>mdi-arrow-right-circle</v-icon>
                          <v-divider vertical class="mx-2"></v-divider>
                          Approve</v-btn
                        >

                        <v-dialog v-model="approve_Dialog" v-if="selctedDataDialogId == item._id" width="600">
                          <v-card>
                            <v-card-title
                              class="white--text"
                              style="background-color: #23b1a9"
                              ><v-layout
                                style="display: flex; justify-content: center"
                                >Approval
                              </v-layout>
                            </v-card-title>
                            <v-card-text>
                              <v-layout
                                class="form-group-container justify-center my-3"
                              >
                                <h4>Are you sure want to approve ?</h4>
                              </v-layout>
                            </v-card-text>
                            <v-card-actions
                              style="display: flex; justify-content: center"
                              class="bottom-alignment"
                            >
                              <v-btn
                                class="btn"
                                color="red white--text mr-0"
                                @click="
                                  approve_Dialog = !approve_Dialog;
                                "
                              >
                                No
                              </v-btn>

                              <v-btn
                                class="btn"
                                color="teal white--text mr-0"
                                @click="submitApproval(item)"
                              >
                                Yes, Approve
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </div>
                    </div>
                    <div class="dialog-table">
                      <v-data-table
                        hide-default-footer
                        :headers="concessionalDialogTableHeader"
                        :items="concessionalDialogTableList"
                        class="mt-2 vDataTableStyling"
                        disable-pagination
                      >
                        <template v-slot:[`item.fieldName`]="{item}">
                          <span> {{ fieldNameFormatter(item.fieldName) }}</span>
                        </template>
                        <template v-slot:[`item.newValue`]='{item}'>
                          <td class="text-left" :class="{'data-difference' : item.oldValue != item.newValue}">{{item.newValue}}</td>
                        </template>
                      </v-data-table>
                    </div>
                  </div>
                </v-card>
              </v-dialog>
            </v-row>
          </template>
        </template> 
        </v-data-table>
        <v-layout
        justify-end
        align-center
        class="py-2"
      >
      <div>
          <span style="color: orange"
            >Showing {{ pageStartIndex }}-{{ pageEndIndex }}</span
          >
          out of {{ totalListCount }} records
        </div>
        <div>
          <v-pagination
            v-model="page"
            :length="pageCount"
            :total-visible="5"
            @input="fetchSelectedTabData"
            color="#152F38"
          ></v-pagination>
        </div>
        
      </v-layout>
        </v-container
  ></div>
</template>
<script>
import moment  from 'moment';

export default {
  data() {
    return {
      notifications: false,
      sound: true,
      widgets: false,
      concessionalTableHeader: [
        { text: "Action", value: "action", sortable: false, align: "left" },
        {
          text: "Type of Vendor",
          value: "typeOfVendor",
          sortable: false,
          align: "left",
        },
        {
          text: "Vendor Name",
          value: "vendorName",
          align: "left",
        },
        {
          text: "PAN Number",
          value: "panNumber",
          align: "left",
        },
        {
          text: "Penal Rate",
          value: "penalTdsRate",
          align: "left",
        },
        {
          text: "Concessional Rate",
          value: "concessionalTdsRate",
          align: "left",
        },
        {
          text: "Certificate Number",
          value: "certificateReferenceNumber",
          align: "left",
        },
        {
          text: "Status",
          value: "approveStatus",
          align: "left",
        },
        {
          text: "Initiated by",
          value: "initiatedBy",
          align: "left",
        },
      ],
      concessionalDialogTableHeader: [
        {
          text: "Field Name",
          value: "fieldName",
          sortable: false,
          align: "left",
        },
        {
          text: "Old Value",
          value: "oldValue",
          sortable: false,
          align: "left",
        },
        {
          text: "New Value",
          value: "newValue",
          sortable: false,
          align: "left",
        },
      ],
      concessionalPenalList: [],
      concessionalDialogTableList: [],
      masters: [
        {
          textTitle: "Hospital",
          count: 0
        },
        {
          textTitle: "Service Provider",
          count: 0,
        },
        {
          textTitle: "Repairer",
          count: 0,
        },
        {
          textTitle: "Vendor",
          count: 0,
        },
      ],
      bulkCount: 0,
      totalListCount: 0,
      reject_Dialog: false,
      approve_Dialog: false,
      reason: "",
      selectedTab: "Hospital",
      fetchedData : [],
      selctedDataDialogId : "",
      pageStartIndex: 0,
      page: 1,
      itemsPerPage: 10,
    };
  },
  created() {
    this.$store.commit(
      "SAVE_PAGE_HEADER",
      "Concessional and Penal for PAN Master"
    );
    this.fetchSelectedTabData();
    this.getApprovalCounts();
  },
  methods: {
    fieldNameFormatter(fieldName) {
      /**
       * Field name in audit trails saved in format of database structure,
       * will have to modify according to UI.
       */
      return {
        'lob': 'LOB',
        'typeOfVendor': 'Type Of Vendor',
        'vendorName': 'Vendor Name',
        'panNumber': 'PAN Number',
        'panCode': "PAN Code",
        'baseTdsRate': 'Base TDS Rate',
        'penalTdsApplicable': 'Penal TDS Applicable',
        'penalTdsCode': 'Penal TDS Code',
        'penalTdsRate': 'Penal TDS Rate',
        'financialYear': 'Financial Year',
        'concessionalTdsApplicable': 'Concessional TDS Applicable',
        'concessionalTdsRate': 'Concessional TDS Rate',
        'certificateReferenceNumber': 'Certificate Reference Number',
        'certificateIssueDate': 'Certificate Issue Date',
        'concessionalTdsEffectiveStartDate': 'Concessional TDS Effective Start Date',
        'concessionalTdsEffectiveEndDate': 'Concessional TDS Effective End Date',
        'certificateReceivedDate': 'Certificate Received Date',
        'thresholdLimit': 'Threshold Limit',
        'uploadCertificate': 'Upload Certificate',
        'remarks': 'Remarks',
        'documents': 'Documents',
      }[fieldName] || fieldName;
    },
    sanitizeAuditTrailTableData(data) {
      /**
      * Filtering unwanted fields.
      */
      const filtered = data.filter(d => ![
          'lob',
          'uid',
          'remarks',
          'approveStatus',
          'status',
          'updatedBy',
          'updatedById',
          'approveRequest',
          'attachments',
      ].includes(d.fieldName));

      /**
       * Formatting Date to be displayed
       */
      filtered.forEach(d => {
        if (
            [
              'certificateReceivedDate',
              'concessionalTdsEffectiveEndDate',
              'concessionalTdsEffectiveStartDate',
              'certificateIssueDate'
            ].includes(d.fieldName)
        ) {
          if (d.oldValue) d.oldValue = moment(d.oldValue).format('DD/MM/YYYY');
          if (d.newValue) d.newValue = moment(d.newValue).format('DD/MM/YYYY');
        }
      })


      return [ ...filtered ];
    },
    changeClickedTab(selectedTabName) {
      this.selectedTab = selectedTabName;
      this.concessionalPenalList = [];
      this.totalListCount = 0;
      this.fetchSelectedTabData();
    },
    fetchSelectedTabData() {
      this.showProgress();
      let newPageNum = this.page
      let reqData = `pageNo=${this.page}&perPage=10&approveStatus=Pending for approval&modelName=concessionalPenalTds&vendorType=${this.selectedTab}`;
      this.GETM(
        "searchFrequentApprove",
        reqData,
        (res) => {
          if (
            res &&
            res.data &&
            res.data.data &&
            res.data.data.data &&
            res.data.data.data.length > 0
          ) {
            let result = res.data.data.data;
            this.concessionalPenalList = result.map((item) => ({
              ...item.operationData[0],
              approveStatus: item.approveStatus,
              initiatedBy: item.initiatedBy,
              _id: item._id,
              dialogBox: false,   
            }));
            this.fetchedData = result;
            this.totalListCount = res.data.data.total;
            this.pageStartIndex = (newPageNum - 1) * this.itemsPerPage + 1
          } else {
            // this.showAlert("No Data Found.", "", "", () => {
              this.concessionalPenalList = [];
              this.totalListCount = 0;
              this.pageStartIndex = 0
            // });
          }
          this.hideProgress();
        },
        (err) => {
          this.hideProgress();
        },
        true
      );
    },
    submitApproval(item) {
      if (this.$refs.rejectForm && !this.$refs.rejectForm.validate()) {
        return this.showAlert("Please fill mandatory fields.");
      } else {
        if (item?._id) {
          let sendData = {
            approveStatus: this.reject_Dialog ? "Rejected" : "Approved",
            rejectionReason: this.reason,
          };
          this.PUTM(
            "updateMasterApprove",
            item._id,
            sendData,
            (res) => {
              this.hideProgress();
              this.reject_Dialog = false;
              this.approve_Dialog = false;
              if (res.data && res.data.statusCode == 200) {
                this.showAlert(res?.data?.msg || "Success", "", "", () => {
                  this.fetchSelectedTabData();
                  this.reason = "";
                });
              } else {
                this.showAlert(res.data.msg);
                this.hideProgress();
              }
            },
            (err) => {
              console.log(err);
              this.hideProgress();
              let msg =
                err?.response?.data?.msg ||
                "Unable to submit Service Provider Approval.";
              this.showAlert(msg);
            },
            true
          );
        }
      }
    },
    setDialogBoxData(item, index, value){
      this.concessionalDialogTableList = this.sanitizeAuditTrailTableData(this.fetchedData[index].modifications);
      this.selctedDataDialogId = item._id;
      this.openDialogBox(item, value)
    },
    openDialogBox(item, value){
      item.dialogBox = value;
    },
    switchToConcessionalPenalBulkUpload(){
      this.$router.push('/tds-master-approval/bulk-upload/concessional-penal')
    },
    getApprovalCounts() {
      /**
       * Calling an API
       */
      this.GETM(
        'TDS_MASTER_PENDING_COUNTS',
        'tdsModel=concessionalTds',
        (res) => {
          if (res?.data?.data) {
            this.masters.forEach(m => {
              if (res.data.data[m.textTitle]) {
                m.count = res.data.data[m.textTitle];
              }
            });

            if (res.data?.data?.bulkCount) {
              this.bulkCount = res.data.data.bulkCount;
            }
          }
        },
        console.error,
        "CM"
      );
    }
  },
  computed:{
    pageCount() {
      if (!this.totalListCount) {
        return 0
      } else {
        return Math.ceil(this.totalListCount / this.itemsPerPage)
      }
    },
    pageEndIndex() {
      if (!this.concessionalPenalList.length) {
        return 0
      } else {
        return this.pageStartIndex + this.itemsPerPage <= this.totalListCount
          ? this.pageStartIndex + this.itemsPerPage - 1
          : this.totalListCount
      }
    },
    hasEditAccess () {
      const routeName = this?.$router?.history?.current?.name;

      return this?.$store?.state?.screens_obj?.[routeName]?.isEdit;
    },
    currentTabRateCount() {
      this.masters.find(m => {
        if (m === this.selectedTab) {
          return m.count;
        }
      });

      return 0;
    }
  }
};
</script>
  <style scoped>
#concessionalPenal .container-style {
  margin: 0;
  padding: 1.5rem;
  max-width: 100%;
}
.master-single-card:not(:last-child) {
  margin-right: 14px;
}
.master-single-card:hover {
  border-color: teal;
}
.master-single-card {
  cursor: pointer;
  width: fit-content;
  height: 75px;
  padding: 0 50px;
  margin-top: 14px;
  background: transparent linear-gradient(180deg, #ffffff 0%, #f0f0f0 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 0px 4px 8px #0000000a;
  border: 2px solid #e0e0e0;
  border-radius: 4px;
  display: flex;
  align-items: center;
}
.master-title {
  word-wrap: break-word;
  font-size: 16px !important;
  text-align: center;
  font-weight: 500;
  font-style: bold;
}
.selected-tab-outline {
  border-color: teal;
}
.data-count {
  color: white;
  border-radius: 4px;
  margin-left: 5px;
  padding: 1px 4px;
  font-size: 12px;
  font-weight: 600;
  background-color: #ff9800 !important;
  border-color: #ff9800 !important;
}
.show-count {
  visibility: hidden;
}
.dialog-bottom-transition-enter-active,
.dialog-bottom-transition-leave-active {
  transition: transform 0.2s ease-in-out;
}
.dialog-navbar {
  height: 48px !important;
  margin-top: 0px;
  color: #ffffff;
  font-weight: 600;
  background-color: rgb(16, 36, 43) !important;
  border-color: rgb(16, 36, 43);
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12) !important;
}
.dialog-content {
  margin: 10px 30px 10px 80px;
}
.approve-heading {
  font-size: 18px;
  font-weight: 500;
}
.bottom-alignment{
  padding-bottom: 20px !important;
}
.data-difference{
  color: #23b1a9;
  font-weight: 500;
}
.btn-toggle {
    text-transform: none;
    color: #fff;
    background-color: #1ebbd9;
}
.header-alignment{
  margin: 0px !important;
}
</style>
  