
<template>
    <v-app>
        <v-main   >
            
            <v-container :style="{  maxWidth:!$router.history.current.fullPath == '/login' ? '1400px': 'none' , padding:'0px'}">

                <header-sale-admin v-if="$store.state.reboot_screens && $store.state.Auth.user"  ></header-sale-admin>
                <!-- <header-Sale-Admin-Login v-if="showMenu===false"></header-Sale-Admin-Login> -->
                <snackbar-store ></snackbar-store>
                <!-- <showAlert></showAlert> -->
                <loader></loader>
                <router-view style="z-index: 1;"></router-view>
                
            </v-container>
            
        </v-main>

        <!-- MARINE ROOT ROUTER-VIEW /HEADER/SIDEMENU -->
        <!-- <v-main  v-if="!Environment_TYPE" >
          <v-container class="vappwidth">
            <header-sale-admin vif="$store.state.reboot_screens" ></header-sale-admin> -->
            <!-- <headerMenuBarLeft v-if="showMenu === true"></headerMenuBarLeft> -->
            <!-- <loginpage-header v-if="showMenu === false"></loginpage-header>
             <snackbar-store ></snackbar-store>
            <showProgress></showProgress>
            <router-view></router-view> -->
            <!-- <toast></toast> -->
            <!-- <confirm></confirm> -->
            <!-- <showAlert></showAlert> -->
            <!-- <floating-buttons v-if="this.$route.name == 'Registrationpage'"></floating-buttons> -->
          <!-- </v-container> -->
        <!-- </v-main> -->
   </v-app>
</template>

<script>
// import headerSaleAdminLogin from './component/header_salesAdmin_login.vue'
import headerSaleAdmin from './component/Header-SalesAdmin.vue'
import snackbarStore from './Page/snackbars.vue'
import Loader from './component/loader'

// MARINE imports from APP.vue
import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);
import authdata from "./app/auth"
import headerMenuBarLeft from "./components/headerMenuBarLeft";
import loginpageHeader from "./components/loginPageHeader";
import showProgress from "./components/showProgress";
import FloatingButtons from './components/floatingButtons.vue';
import toast from "./components/showToast"
import { addAuthorizationToken } from './app/interceptor';
// import confirm from "./components/showConfirm"
// import showAlert from "./components/showAlert"

import VueMask from 'v-mask';
Vue.use(VueMask);

export default {
  name: 'App',
  data: () => ({
    route:''
  }),
  created(){

    addAuthorizationToken.call(this);
    // let seeuser = false
    // if(authdata.auth.hasOwnProperty('signInUserSession')){
    //     seeuser = true
    // }
       if(!this.$store.state.reboot_screens){
      //  alert("as false")
        this.$store.commit('SET_SCREEN_REBOOT', false)
    //     console.log(this.$router.history.current.fullPath == '/login','fullpath is',this.$router.history.current.fullPath)
    // console.log(!this.$router.history.current.fullPath == '/login',"<<<<<--NEWNEW | $store.state.reboot_screens-->", this.$store.state.reboot_screens)
      let ais = this.$router.history.current.fullPath == '/login'

      // console.log(ais)
      // console.log(!ais)
        if(!ais){
          let beforecache = window.location.pathname == '/loginis'
          console.log(window.location.pathname,"beforecache",beforecache)
          if(!beforecache){
                  this.check_cache(res=>{
                      console.log("check cache", res)
                      if(res){
                          
                                  // alert("nexticking now")
                                    this.$nextTick(() => {
                                      // Okay, now that everything is destroyed, lets build it up again
                                      this.$store.commit('SET_SCREEN_REBOOT', true)
                                    });
                              
                      }
                  })
          }else{

                let data = '', decodedComponent = '', parsedData = {}, path = '';
                if(window.location.search.includes('[')) {
                  data = window.location.search.split('[').pop().split(']');
                  decodedComponent = decodeURIComponent(data[0]);
                  parsedData = JSON.parse(decodedComponent);
                }
                if(parsedData) {
                  if(parsedData.hasOwnProperty('path')) {
                    path = parsedData.path;
                  }
                  this.$store.commit('SET_OTHER_LOB_DATA', parsedData);
                }
                this.$store.commit('SET_SCREEN_REBOOT', true)
                // console.log('new data is =>>>>>>>', data, decodedComponent, parsedData);
                // console.log("No need to check cache as redirecting")
          }
          
        }  
     }

  },
  components: {  
    // headerSaleAdminLogin, 
    headerSaleAdmin,  
    snackbarStore,    
    Loader,  
    
  //MARINE COMPONENTS
    headerMenuBarLeft,
    loginpageHeader,
    showProgress,
    FloatingButtons,
    toast
  },

      watch: {

            rebootcheck(val){

                console.log(this.$store.state.reboot_screens,"rebootcheck watch", val)
              
            },

            '$route':function(to, from) {

                

                console.log("watcherr Router",this.$router.history)
                let current_routes = this.$router.history.current;
                this.route = this.$router.history.current.fullPath
                this.commit('LINKS',current_routes)
                console.log(current_routes,'CURRENT ROUTES');

            //   let adminitis = this.$store.getters.getAgentDetails
            // //   console.log('to is =>', to.fullPath );
            //   if(adminitis.product_sd_code === '01'){
            //         if(to.fullPath == '/bancaDash'){
            //             this.$router.push(from.fullPath);
            //             return
            //         }
            //   }
            //   else if(adminitis.product_sd_code === '04'){
            //        if(to.fullPath == '/maindashboard'){
            //             this.$router.push(from.fullPath);
            //             return
            //         }
            //   }else {
            //     //   return
            //   }
            // else if(){

            //   }else{

            //   }
                

                // const el = this.$refs.header
                // dom.scrollToElement(el,{})

                // if(current_routes.fullPath !== '/LoginView') {
                //     if(current_routes.fullPath === '/RegistrationPage') {
                //         this.$router.push(current_routes.fullPath);
                //     }
                //     else if(this.checkAuthen() === false) {
                //         // if (pageLoadeRoute.route_name !== "changePasscode") {
                //         this.headerVisible = false;
                //         this.$router.push('/LoginView');
                //         // } else {
                //         //     this.$router.push(pageLoadeRoute.path);
                //         // }
                //     }
                // }

                


                // if(current_routes.fullPath === '/idealactivityLeadUpload') {
                //     if(this.checkAuthen() === true) {
                //         this.headerVisible = true;
                //     } else {
                //         // if (pageLoadeRoute.route_name !== "changePasscode") {
                //         this.headerVisible = false;
                //         this.$router.push('/LoginView');
                //         // } else {
                //         //     this.$router.push(pageLoadeRoute.path);
                //         // }
                //     }
                // } else if(current_routes.fullPath === '/LoginView') {
                //     this.headerVisible = false;
                // } 
                // HIDE LOADER WHEN ROUTE CHANGE
                // this.showLoader('', false);
            },
            route: function(latest,old){
                console.log("NEW ROUTE : ",latest, " OLD TOUTE : ",old);

              this.$store.commit("SETCURRENT_PAGE",this.$store.state.screens_obj[this.$route.name]);

               if(old == '/NetworkManagement' && this.$store.state.NetworkSearch.searched && latest != '/NetworkSearch'){
                  // alert("probably from browser back button hit appeared !");
                    var cache_state = {
                        searched: false,
                    }
                   this.$store.commit('NS_store', cache_state);
               }else{
                 console.log("network stored state ", this.$store.state.NetworkSearch)
               }

              //   if(latest == '/notused'){

              //     console.log(latest,"IFdashboard-> ",localStorage.getItem('firstLoad'))
              //     if(window.localStorage){
                  
              //       if(!localStorage.getItem('firstLoad')){
              //         localStorage['firstLoad'] = true;
              //         window.location.reload();
              //       }  
              //       else{
              //         localStorage.removeItem('firstLoad');
              //       }
              //     }
                   
              //  }else{
              //       console.log(latest,"ELSEdashboard-> ",window.location)
              //  }
                // if(old === '/idealactivityLeadUpload' ){
                //     if(latest === '/LoginView'){
                //         if(this.checkAuthen() === true){
                //             this.headerVisible = true;
                //             this.$router.push('/idealactivityLeadUpload')
                //         }
                //     }
                // }
            }
        },

  computed:{

    rebootcheck(){

        return this.$store.state.reboot_screens

    },

    // Environment_TYPE(){

    //     if(this.$store.state.lob_type == 'Health'){
    //         return true
    //     }else if(this.$store.state.lob_type == 'Marine'){
    //         return false
    //     }

    // },

    showMenu() {
     if(this.$route.name !== 'login'){
      return true
     }else{
       this.hideProgress();
      return false
     }
    
    },

   
    // tokenCheck() {
    //   if (this.$store.getters.fetchToken == "" || this.$store.getters.fetchToken == null ) {
    //     this.$router.push("/login");
    //     this.hideProgress();
    //   }
    // },

     // MARINE COMPUTED FOR headers,

    // showMenu() {
    //   if (this.$route.name !== "Login") {
    //     return true;
    //   } else {
    //      this.hideProgress();
    //       return false;
        
    //   }
    // },



  },
};
</script>




<style scoped>
.vappwidth{
 max-width: 100%;
 padding: 0px ;
}
</style>





